import { CardHeader } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useState } from 'react'

const ctrlClickableCardHeaderPropTypes = {
  /**
   * Fired when the component is activated either with a mouse/tap or by using the Enter or Space key.
   */
  onClick: PropTypes.func,
  /**
   * Fired when the component is activated either with a mouse/tap or by using the Enter or Space key, but handled first by outer components.
   * Allows accessing event props before event processing in CardHeader component.
   */
  onClickCapture: PropTypes.func,
}

/**
 * Wraps the CardHeader component to allow accessing ctrlKey and metaKey prop on click.
 * @param {PropTypes.InferProps<typeof ctrlClickableCardHeaderPropTypes>} props
 */
const CtrlClickableCardHeader = ({ onClick, onClickCapture, ...props }) => {
  const [capturedEvent, setCapturedEvent] = useState()
  return (
    <CardHeader
      {...props}
      onClickCapture={(e) => {
        setCapturedEvent(e)
        onClickCapture?.(e)
      }}
      onClick={(e) => {
        if (isNil(e?.ctrlKey) && isNil(e?.metaKey)) {
          e.ctrlKey = capturedEvent?.ctrlKey
          e.metaKey = capturedEvent?.metaKey
        }
        onClick?.(e)
      }}
    />
  )
}

CtrlClickableCardHeader.propTypes = ctrlClickableCardHeaderPropTypes

export default CtrlClickableCardHeader
