import get from 'lodash.get'

export const Answer = Object.freeze({
  YES: 'yes',
  NO: 'no',
  INDETERMINABLE: 'indeterminable',
})

/**
 * Evaluates an aspect of a condition.
 * If neither `min` nor `max` is defined, all questions of the aspect must be answered as specified by `result`.
 * @param {object} answers the current answers
 * @param {{keys: string[][], result: 'yes' | 'no' | 'indeterminable', min?: number, max?: number}} conditionAspect the condition aspect
 */
function isConditionAspectFulfilled(answers, { keys, result, min, max }) {
  const defaultedMin = min ?? (max === undefined ? keys.length : 0)
  const defaultedMax = max ?? keys.length

  const fulfilledCount = keys.filter((key) => get(answers, key) === result).length

  return fulfilledCount >= defaultedMin && fulfilledCount <= defaultedMax
}

/**
 * Evaluates a condition.
 * @param {object} answers all current answers
 * @param {{keys: string[][], result: 'yes' | 'no' | 'indeterminable', min?: number, max?: number}[]} condition the condition
 */
export function evaluateCondition(answers, condition = []) {
  return condition.every((conditionAspect) => isConditionAspectFulfilled(answers, conditionAspect))
}

/**
 * @param {string[]} questionKeyPath
 * @param {object} answers
 */
function isQuestionAnswered(questionKeyPath, answers) {
  return Object.values(Answer).includes(get(answers, questionKeyPath))
}

/**
 * Returns the key path of the step with which to proceed after the given question considering the current `answers`.
 * If the question identified by `questionKeyPath` is not answered yet, nothing will be returned.
 * @param {string[]} questionKeyPath the full key identifying the question
 * @param {object} answers all current answers
 * @param {{key: string[], condition?: {keys: string[][], result: 'yes' | 'no' | 'indeterminable', min?: number, max?: number}[]}[]} proceedOptions the proceed options of the question as defined by `proceedWith` in the question structure
 * @returns {string[] | undefined} the key path identifying the step with which to continue after the given question
 */
export function getKeyPathToProceedWith(questionKeyPath, answers, proceedOptions) {
  if (!isQuestionAnswered(questionKeyPath, answers)) {
    return
  }

  for (const { key, condition } of proceedOptions) {
    if (evaluateCondition(answers, condition)) {
      return key
    }
  }
}

/**
 * Returns all types of info messages that should be displayed for the given question considering the current `answers`.
 * If the question identified by `questionKeyPath` is not answered yet, nothing will be returned.
 * @param {string[]} questionKeyPath the full key identifying the question
 * @param {object} answers all current answers
 * @param {{type: string, condition?: {keys: string[][], result: 'yes' | 'no' | 'indeterminable', min?: number, max?: number}[]}[]} infoMessages the info messages of the question as defined by `infos` in the question structure
 * @returns {string[] | undefined} types of info messages which should be displayed for the given question
 */
export function getInfoMessageTypesToDisplay(questionKeyPath, answers, infoMessages) {
  if (!infoMessages || !isQuestionAnswered(questionKeyPath, answers)) {
    return
  }

  return infoMessages
    .filter(({ condition }) => evaluateCondition(answers, condition))
    .map(({ type }) => type)
}
