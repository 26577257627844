import { FlexBoxAlignItems } from '@fioneer/ui5-webcomponents-react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useExternalIdTypeConfigValuesWithTranslation } from 'hooks/services/properties/external-ids/useExternalIdTypeConfigValuesWithTranslation'
import { toCamelCaseFromSentenceCase } from 'lib/format/caseConversion'

const usePropertiesColumnDefinitions = () => {
  const { t } = useTranslation()

  const externalIdTypeConfigValues = useExternalIdTypeConfigValuesWithTranslation()

  const staticColumnDefinitions = useMemo(
    () => [
      {
        title: t('pages.properties.table.property.label'),
        columnKey: 'description',
        sortFieldName: 'description',
        popinText: t('pages.properties.table.property.label'),
        isSelectableForHiding: false,
      },
      {
        title: t('pages.properties.table.street.label'),
        columnKey: 'street',
        sortFieldName: 'street_name',
        minWidth: 1400,
        demandPopin: true,
        popinText: t('pages.properties.table.street.label'),
      },
      {
        title: t('pages.properties.table.city.label'),
        columnKey: 'city',
        sortFieldName: 'city_name',
        minWidth: 1200,
        demandPopin: true,
        popinText: t('pages.properties.table.city.label'),
      },
      {
        title: t('pages.properties.table.country.label'),
        columnKey: 'country',
        sortFieldName: 'country_name',
        minWidth: 1100,
        demandPopin: true,
        popinText: t('pages.properties.table.country.label'),
      },
      {
        title: t('pages.properties.table.zip-code.label'),
        columnKey: 'zipCode',
        sortFieldName: 'postal_code',
        alignment: FlexBoxAlignItems.End,
        minWidth: 1000,
        demandPopin: true,
        popinText: t('pages.properties.table.zip-code.label'),
      },
      {
        title: t('pages.properties.table.property-type.label'),
        columnKey: 'typeName',
        sortFieldName: 'type_name',
        minWidth: 900,
        demandPopin: true,
        popinText: t('pages.properties.table.property-type.label'),
      },
      {
        title: t('pages.properties.table.construction-year.label'),
        columnKey: 'constructionYear',
        minWidth: 800,
        demandPopin: true,
        popinText: t('pages.properties.table.construction-year.label'),
        isVisible: false,
      },
      {
        title: t('pages.property-create.details.market-value'),
        columnKey: 'marketValue',
        minWidth: 800,
        demandPopin: true,
        alignment: FlexBoxAlignItems.End,
        sortingDisabled: true,
        popinText: t('pages.property-create.details.market-value'),
        isVisible: false,
      },
      {
        title: t('pages.property.valuation.classification.mortgage-lending-value'),
        columnKey: 'mortgageLendingValue',
        minWidth: 800,
        demandPopin: true,
        alignment: FlexBoxAlignItems.End,
        sortingDisabled: true,
        popinText: t('pages.property.valuation.classification.mortgage-lending-value'),
        isVisible: false,
      },
      {
        title: t('pages.property-overview.market'),
        columnKey: 'market',
        minWidth: 700,
        demandPopin: true,
        popinText: t('pages.property-overview.market'),
      },
      {
        title: t('pages.properties.header.status.label.financing-status'),
        columnKey: 'financingStatus',
        minWidth: 700,
        demandPopin: true,
        popinText: t('pages.properties.header.status.label.financing-status'),
      },
      {
        title: t('pages.properties.header.status.label.cag-status'),
        columnKey: 'cagStatus',
        minWidth: 700,
        demandPopin: true,
        popinText: t('pages.properties.header.status.label.cag-status'),
        isVisible: false,
      },
      {
        title: t('pages.property-create.basic-information.deal-id'),
        columnKey: 'dealId',
        minWidth: 700,
        demandPopin: true,
        popinText: t('pages.property-create.basic-information.deal-id'),
      },
      {
        title: t('pages.properties.created-by'),
        columnKey: 'createdBy',
        minWidth: 500,
        demandPopin: true,
        popinText: t('pages.properties.created-by'),
        isVisible: false,
      },
      {
        title: t('pages.properties.created-at'),
        columnKey: 'createdAt',
        sortFieldName: 'creation_date_time',
        minWidth: 500,
        demandPopin: true,
        popinText: t('pages.properties.created-at'),
        isVisible: false,
      },
    ],
    [t],
  )

  const dynamicColumnDefinitions = useMemo(
    () =>
      externalIdTypeConfigValues
        .filter(({ display }) => display)
        .map(({ translation, type, description }) => ({
          title: translation ?? description,
          columnKey: toCamelCaseFromSentenceCase(type),
          minWidth: 500,
          demandPopin: true,
          popinText: translation ?? description,
          isVisible: true,
        })),
    [externalIdTypeConfigValues],
  )

  const mapOtherColumnFields = (colDefinitions) =>
    colDefinitions.map(
      ({
        isVisible = true,
        isSelectableForHiding = true,
        sortFieldName = false,
        ...otherColumnProperties
      }) => ({
        isVisible,
        isSelectableForHiding,
        sortFieldName,
        ...otherColumnProperties,
      }),
    )

  const [tableColumns, setTableColumns] = useState()

  useEffect(() => {
    const columnDefinitions = [...staticColumnDefinitions, ...dynamicColumnDefinitions]
    setTableColumns(mapOtherColumnFields(columnDefinitions))
  }, [dynamicColumnDefinitions, staticColumnDefinitions])

  return [tableColumns, setTableColumns]
}

export default usePropertiesColumnDefinitions
