import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const TemplateEntryType = {
  Section: 'SECTION',
  Subsection: 'SUBSECTION',
  Assessment: 'ASSESSMENT',
}

const emptyArray = []

/**
 * @param {import('./CustomizeDecisionPaperTemplateTable').Template} templateData
 */
const useMapTemplateDataToTableData = (templateData) => {
  const { t } = useTranslation('translation', {
    keyPrefix:
      'components.business-events-and-tasks.decision-papers.template-customization-dialog.table.types',
  })

  /** @param {import('./CustomizeDecisionPaperTemplateTable').Tile[]} tiles */
  const mapTiles = useCallback(
    (tiles) =>
      tiles.map((tile) => ({
        name: tile.name,
        type: tile.type ? t(tile.type) : t('NOT_AVAILABLE'),
        typecode: tile.type,
      })),
    [t],
  )

  return useMemo(
    () =>
      templateData?.sections?.map((section) => ({
        name: section.name,
        type: t(TemplateEntryType.Section),
        subRows: [
          ...section.subsections.map((subsection) => {
            const assessmentEntry = subsection.hideAssessment
              ? []
              : [
                  {
                    name: t(TemplateEntryType.Assessment),
                    type: t(TemplateEntryType.Assessment),
                    typecode: TemplateEntryType.Assessment,
                  },
                ]
            return {
              name: subsection.name,
              type: t(TemplateEntryType.Subsection),
              subRows: [...mapTiles(subsection.tiles), ...assessmentEntry],
            }
          }),
          ...mapTiles(section.tiles),
        ],
      })) ?? emptyArray,
    [mapTiles, t, templateData?.sections],
  )
}

export default useMapTemplateDataToTableData
