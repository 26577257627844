import {
  FlexBox,
  FlexBoxJustifyContent,
  Label,
  Text,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrearsApprovalChecklist/shared/ArrearsApprovalChecklistDisplayCardViewInfoItemView.module.css'

const DecisionPaperDisplayCardViewInfoItemView = ({
  label,
  isFirstItem,
  value,
  wrappingType = WrappingType.None,
}) => (
  <FlexBox
    fitContainer
    justifyContent={FlexBoxJustifyContent.SpaceBetween}
    className={styles['displayCardItem'].concat(isFirstItem ? ` ${styles['firstItem']}` : '')}
  >
    <Label wrappingType={wrappingType} className={styles['displayCardLabel']}>
      {label}
    </Label>
    <div className={styles['displayCardTextBox']}>
      <Text>{value}</Text>
    </div>
  </FlexBox>
)

DecisionPaperDisplayCardViewInfoItemView.propTypes = {
  label: PropTypes.string,
  isFirstItem: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
  wrappingType: PropTypes.string,
}

export default DecisionPaperDisplayCardViewInfoItemView
