import { ValueState } from '@fioneer/ui5-webcomponents-react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import determinePropertyValueStateBasedOnStatus from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/asset-list/shared/determinePropertyValueState'

// A helper hook to add a status to property object
// adds information to each property object
export const useEnrichPropertyWithStatus = (properties = []) => {
  //the status in translation files are used to translate the system status that we received from backend to English.
  const { t: tHeaderStatus } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.properties.header.status',
  })
  const { t: tHeaderStatusText } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.properties.header.status.text',
  })

  const checkPropertyStatus = (property) =>
    !_.isEmpty(property?.systemStatus) && Array.isArray(property?.systemStatus)

  const systemStatusInLocalTranslation = (property) =>
    checkPropertyStatus(property) ? property.systemStatus[0].toLowerCase().replace(' ', '-') : ''

  return properties.map((property) => {
    const systemStatusInStandardTranslation = tHeaderStatus(
      systemStatusInLocalTranslation(property),
    )
    const statusTranslationText = tHeaderStatusText(
      systemStatusInStandardTranslation?.toLowerCase(),
    )
    return {
      ...property,
      propertyStatus: {
        text: checkPropertyStatus ? statusTranslationText : null,
        valueState: checkPropertyStatus
          ? determinePropertyValueStateBasedOnStatus(systemStatusInStandardTranslation)
          : ValueState.None,
      },
    }
  })
}
