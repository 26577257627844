export const ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES = {
  DEAL_SELECTION_SUBSECTION: 'DEAL_SELECTION_SUBSECTION',
  INVOLVED_PARTIES_SUBSECTION: 'INVOLVED_PARTIES_SUBSECTION',
  FINANCING_STRUCTURE_AND_TERMS_SUBSECTION: 'FINANCING_STRUCTURE_AND_TERMS_SUBSECTION',
  COVENANTS_AND_RATIOS_SUBSECTION: 'COVENANTS_AND_RATIOS_SUBSECTION',
  FINANCIAL_RATIOS_BANK_STANDARD: 'FINANCIAL_RATIOS_BANK_STANDARD',
  PROPERTY_PORTFOLIO_SUBSECTION: 'PROPERTY_PORTFOLIO_SUBSECTION',
  TENANCY_RENT_ROLL: 'TENANCY_RENT_ROLL',
  CASH_FLOW_ANALYSIS: 'CASH_FLOW_ANALYSIS',
}
