import { useTranslation } from 'react-i18next'
import {
  ArrearStatusCell,
  ArrearTypeCell,
  ArrearAmountCell,
  ArrearOverpaymentCell,
  ArrearApprovedAmountCell,
  ArrearBookingDateCell,
  ArrearDaysOverdue,
  ArrearCurrentClosedCommitmentCell,
  ArrearApprovedUntilCell,
  ArrearClassificationCell,
  ArrearOutstandingCell,
  ArrearAvailableCell,
} from 'components/domains/business-partners/tile/arrears/current-arrears/CurrentArrearsTableCells'
import { renderAnalyticalTableCell } from 'components/ui/tables/analytical/AnalyticalTableCell'
import { useCustomizableCurrencyFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'

const useCurrentArrearsTableColumns = () => {
  const { t: tBusinessPartnerArrearsTable } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.current-arrears.table',
  })

  const formatCurrency = useCustomizableCurrencyFormatter()

  const { format: formatDate } = useShortDateFormatter()
  const formatDays = (days) =>
    days === 1
      ? tBusinessPartnerArrearsTable('units.day', {
          days,
        })
      : tBusinessPartnerArrearsTable('units.days', {
          days,
        })
  const defaultDisables = {
    disableResizing: false,
    disableDragAndDrop: false,
    disableGlobalFilter: true,
    disableGroupBy: true,
  }

  const columnTranslations = {
    arrears: tBusinessPartnerArrearsTable('columns.arrears'),
    status: tBusinessPartnerArrearsTable('columns.status'),
    approvedUntil: tBusinessPartnerArrearsTable('columns.approved-until'),
    classification: tBusinessPartnerArrearsTable('columns.arrears-classification'),
    arrearAmount: tBusinessPartnerArrearsTable('columns.arrear-amount'),
    bookingDate: tBusinessPartnerArrearsTable('columns.booking-date'),
    daysOverdue: tBusinessPartnerArrearsTable('columns.days-overdue'),
    overpayment: tBusinessPartnerArrearsTable('columns.overpayment'),
    approvedAmount: tBusinessPartnerArrearsTable('columns.approved-amount'),
    commitment: tBusinessPartnerArrearsTable('columns.commitment'),
    outstanding: tBusinessPartnerArrearsTable('columns.outstanding-own-share'),
    available: tBusinessPartnerArrearsTable('columns.available-own-share'),
  }

  const tableHelpers = {
    formatCurrency,
    formatDate,
    formatDays,
  }

  return [
    {
      Header: columnTranslations.arrears,
      id: 'arrears',
      accessor: 'rowData.arrear.type.name',
      Cell: renderAnalyticalTableCell(ArrearTypeCell),
      minWidth: 350,
      ...defaultDisables,
    },
    {
      Header: columnTranslations.status,
      id: 'status',
      accessor: 'rowData.arrear.approvalStatus',
      Cell: renderAnalyticalTableCell(ArrearStatusCell),
      minWidth: 150,
      ...defaultDisables,
    },
    {
      Header: columnTranslations.arrearAmount,
      id: 'arrearAmount',
      accessor: 'rowData.arrear.headquarterArrearAmount',
      Cell: renderAnalyticalTableCell(ArrearAmountCell, tableHelpers),
      hAlign: 'End',
      minWidth: 300,
      ...defaultDisables,
    },
    {
      Header: columnTranslations.bookingDate,
      id: 'bookingDate',
      accessor: 'rowData.arrear.bookingDate',
      Cell: renderAnalyticalTableCell(ArrearBookingDateCell, tableHelpers),
      hAlign: 'End',
      minWidth: 100,
      ...defaultDisables,
    },
    {
      Header: columnTranslations.daysOverdue,
      id: 'daysOverdue',
      accessor: 'rowData.arrear.daysOverdue',
      Cell: renderAnalyticalTableCell(ArrearDaysOverdue, tableHelpers),
      hAlign: 'End',
      ...defaultDisables,
    },
    {
      Header: columnTranslations.approvedAmount,
      Cell: renderAnalyticalTableCell(ArrearApprovedAmountCell, tableHelpers),
      id: 'approvedAmount',
      accessor: 'rowData.arrearApprovalItem.approvalAmount',
      hAlign: 'End',
      minWidth: 200,
      ...defaultDisables,
    },
    {
      Header: columnTranslations.approvedUntil,
      id: 'approvedUntil',
      Cell: renderAnalyticalTableCell(ArrearApprovedUntilCell, tableHelpers),
      ...defaultDisables,
      minWidth: 100,
      hAlign: 'End',
    },
    {
      Header: columnTranslations.classification,
      id: 'classification',
      Cell: renderAnalyticalTableCell(ArrearClassificationCell),
      minWidth: 350,
      ...defaultDisables,
    },
    {
      Header: columnTranslations.overpayment,
      Cell: renderAnalyticalTableCell(ArrearOverpaymentCell, tableHelpers),
      id: 'overpayment',
      accessor: 'rowData.arrear.headquarterOverpaymentAmount',
      hAlign: 'End',
      minWidth: 200,
      ...defaultDisables,
    },
    {
      Header: columnTranslations.commitment,
      Cell: renderAnalyticalTableCell(ArrearCurrentClosedCommitmentCell, tableHelpers),
      id: 'currentClosedCommitment',
      hAlign: 'End',
      minWidth: 300,
      ...defaultDisables,
    },
    {
      Header: columnTranslations.outstanding,
      Cell: renderAnalyticalTableCell(ArrearOutstandingCell, tableHelpers),
      id: 'outstanding',
      hAlign: 'End',
      minWidth: 200,
      ...defaultDisables,
    },
    {
      Header: columnTranslations.available,
      Cell: renderAnalyticalTableCell(ArrearAvailableCell, tableHelpers),
      id: 'available',
      hAlign: 'End',
      minWidth: 200,
      ...defaultDisables,
    },
  ]
}
export default useCurrentArrearsTableColumns
