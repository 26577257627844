import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateDecisionPaperSubsectionComment = (mutationOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(async ({ eventId, version, subsectionId, comment }) => {
    const { data } = await post({
      path: `/events/${eventId}/decision-paper/versions/${version}/subsections/${subsectionId}/comments`,
      body: {
        comment,
      },
    })
    return data
  }, mutationOptions)
}

export default useCreateDecisionPaperSubsectionComment
