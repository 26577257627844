import '@ui5/webcomponents-fiori/dist/illustrations/sapIllus-Spot-UnableToLoad.js'
import '@ui5/webcomponents-fiori/dist/illustrations/NoTasks'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import CreatePropertyEventAction from 'components/domains/properties/header-actions/CreatePropertyEventAction'
import ConfigurableComponentsPage from 'components/ui/configurable-components-page/ConfigurableComponentsPage'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { availableDynamicPagesEnum } from 'hooks/page-config/useGetPageConfig'
import { PropertyContext } from 'routes/properties/PropertyContext'
import PropertyPage from 'routes/properties/PropertyPage'

const PropertyInsurancesDynamic = ({
  propertyDescription,
  breadcrumbs,
  status,
  additionalStatuses,
}) => {
  const { property } = useContext(PropertyContext)
  const allowedOperations = property.allowed_operations?.allowed_operations ?? []
  const subtitle = <EntityTypeAndIdWithClipboard id={property.id} />

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="property-details-user-favorite-icon"
        entityId={property.uuid}
        entityType={cwpEntityTypes.PROPERTY}
      />
    ),
    [property.uuid],
  )

  return (
    <ConfigurableComponentsPage
      pageData={{ property, allowedOperations }}
      pageCode={availableDynamicPagesEnum.PropertiesInsurancesCollaterals}
      renderContent={({ children }) => (
        <PropertyPage
          pageTitle={propertyDescription}
          id={property.id}
          subtitle={subtitle}
          breadcrumbs={breadcrumbs}
          status={status}
          additionalStatuses={additionalStatuses}
          actions={
            <>
              <CreatePropertyEventAction />, {markFavoriteAction}
            </>
          }
        >
          {children}
        </PropertyPage>
      )}
    />
  )
}

PropertyInsurancesDynamic.propTypes = {
  propertyDescription: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ),
  status: PropTypes.shape({
    text: PropTypes.string.isRequired,
    valueState: PropTypes.string,
  }),
  additionalStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string.isRequired,
      valueState: PropTypes.string,
    }),
  ),
}

export default PropertyInsurancesDynamic
