import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { decisionProcessMinutesDecisionStatus } from 'api/decision-process/decisionProcessMinutesApi'
import DecisionStageMinutesApprovalHeaderButtons from 'components/domains/business-events-and-tasks/decision-stage/minutes/DecisionStageMinutesApprovalHeaderButtons'
import DecisionStageMinutesButtons from 'components/domains/business-events-and-tasks/decision-stage/minutes/DecisionStageMinutesButtons'
import DecisionStageMinutesCard from 'components/domains/business-events-and-tasks/decision-stage/minutes/DecisionStageMinutesCard'
import DecisionStageMinutesInformationCard from 'components/domains/business-events-and-tasks/decision-stage/minutes/DecisionStageMinutesInformationCard'
import styles from 'components/domains/business-events-and-tasks/decision-stage/minutes/DecisionStageMinutesPage.module.css'
import DecisionStageMinutesVotersTable from 'components/domains/business-events-and-tasks/decision-stage/minutes/DecisionStageMinutesVotersTable'
import Card from 'components/ui/card/Card'
import CWPLayout from 'components/ui/layout/CWPLayout'

const DecisionStageMinutesPage = ({ decisionStageMinutes, decisionStageId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.minutes',
  })

  const {
    info: { decisionStatus, decisionDate, minutes, lastUpdatedAt, lastUpdatedBy },
  } = decisionStageMinutes

  return (
    <CWPLayout>
      <CWPLayout.Full>
        <FlexBox
          alignItems={FlexBoxAlignItems.Center}
          direction={FlexBoxDirection.Row}
          justifyContent={FlexBoxJustifyContent.SpaceBetween}
          className={styles.header}
        >
          <Title level={TitleLevel.H4} className={styles.headerText}>
            {t('title')}
          </Title>
          <FlexBox
            alignItems={FlexBoxAlignItems.Center}
            direction={FlexBoxDirection.Row}
            justifyContent={FlexBoxJustifyContent.End}
            className={styles.gapTiny}
          >
            <DecisionStageMinutesApprovalHeaderButtons
              decisionStageId={decisionStageId}
              decisionStatus={decisionStatus}
            />
            <DecisionStageMinutesButtons
              decisionStageId={decisionStageId}
              decisionStatus={decisionStatus}
            />
          </FlexBox>
        </FlexBox>
      </CWPLayout.Full>
      <DecisionStageMinutesInformationCard
        decisionStatus={decisionStatus}
        decisionDate={decisionDate}
      />
      <CWPLayout.Full>
        <Card>
          <DecisionStageMinutesVotersTable
            decisionStageId={decisionStageId}
            decisionStatus={decisionStatus}
          />
        </Card>
      </CWPLayout.Full>
      <CWPLayout.Full>
        <DecisionStageMinutesCard
          decisionStatus={decisionStatus}
          decisionStageId={decisionStageId}
          minutes={minutes}
          lastUpdatedAt={lastUpdatedAt}
          lastUpdatedBy={lastUpdatedBy}
        />
      </CWPLayout.Full>
    </CWPLayout>
  )
}

DecisionStageMinutesPage.propTypes = {
  decisionStageId: PropTypes.string.isRequired,
  decisionStageMinutes: PropTypes.shape({
    info: PropTypes.shape({
      decisionStatus: PropTypes.oneOf(Object.values(decisionProcessMinutesDecisionStatus))
        .isRequired,
      decisionDate: PropTypes.string,
      minutes: PropTypes.string.isRequired,
      lastUpdatedAt: PropTypes.string,
      lastUpdatedBy: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default DecisionStageMinutesPage
