import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { forwardRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useBusinessPartnerForbearanceStatus from 'components/domains/business-partners/useBusinessPartnerForbearanceStatus'
import useBusinessPartnerRiskMonitoringStatus from 'components/domains/business-partners/useBusinessPartnerRiskMonitoringStatus'
import useBusinessPartnerStatus from 'components/domains/business-partners/useBusinessPartnerStatus'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import Header from 'components/ui/page/Header'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

const propTypes = {
  /**
   * Breadcrumbs beneath the BP search element.
   *
   * Adds the initial `businesspartners/:id`, both as breadcrumbs and also prepends the `href`s with them
   */
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      href: PropTypes.string,
    }).isRequired,
  ).isRequired,
}

/** @typedef {Parameters<typeof Header>[0]} headerProps */
/** @typedef {PropTypes.InferProps<typeof propTypes>} props */

/**
 * Header for BP Detail pages
 */
const BusinessPartnerHeader = forwardRef(
  /** @param {Omit<headerProps, keyof props | 'title' | 'id' | 'breadcrumbs' | 'subtitle' | 'status'> & props} props */ (
    { breadcrumbs, ...props },
    _ref,
  ) => {
    const { fullName = '', id = '' } = useContext(BusinessPartnerContext) ?? {
      fullName: '',
      id: '',
    }
    const { t } = useTranslation()

    const fullBreadcrumbs = [
      { text: t('navigation.item.title.business-partners'), href: '/business-partners' },
      { text: fullName, href: `/business-partners/${id}` },
      ...breadcrumbs.map(({ text, href }) => ({
        text,
        href: isNil(href) ? '' : `/business-partners/${id}/${href}`,
      })),
    ]

    const subtitle = <EntityTypeAndIdWithClipboard id={id ?? ''} />

    const businessPartnerStatus = useBusinessPartnerStatus()
    const { data: riskMonitoringStatus } = useBusinessPartnerRiskMonitoringStatus(id)
    const { data: forbearanceStatus } = useBusinessPartnerForbearanceStatus(id)

    return (
      <Header
        {...props}
        title={fullName}
        id={id}
        breadcrumbs={fullBreadcrumbs}
        subtitle={subtitle}
        status={riskMonitoringStatus}
        additionalStatuses={[forbearanceStatus, businessPartnerStatus]}
      />
    )
  },
)

BusinessPartnerHeader.displayName = 'BusinessPartnerHeader'

BusinessPartnerHeader.propTypes = propTypes

export default BusinessPartnerHeader
