import {
  MessageStrip,
  Text,
  Title,
  TitleLevel,
  Label,
  Link,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DecisionPaperTable from 'components/domains/business-events-and-tasks/events/creation/DecisionPaperTable'
import DealWorkingVersionEventCreationTemplateGroup from 'components/domains/deals/header-actions/working-version-event-create/DealWorkingVersionEventCreationTemplateGroup'
import styles from 'components/domains/deals/header-actions/working-version-event-create/WorkingVersionDealEventSecondStageDialogBody.module.css'
import LabeledSwitch from 'components/ui/input/LabeledSwitch'
import paths from 'routes/paths'

const WorkingVersionDealEventSecondStageDialogBody = ({
  deal,
  event,
  handleTemplateChange,
  handleSwitchClicked,
  onImportEventChange,
  decisionPaperTemplateCode,
  displayTable,
  eventIdToImportDataFrom,
  setEventIdToImportDataFrom,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dialog.event-creation.deal-working-version',
  })
  return (
    <>
      <Title level={TitleLevel.H4}>{t('set-decision-paper')}</Title>
      <MessageStrip className={styles.messageStrip}>
        <span>
          {t('message-strip.part-1')}
          <>&nbsp;</>
          <Link
            href={`/${paths.deals}/${deal.displayId}?working-version=true`}
            target="_blank"
            rel="noreferrer"
          >
            <b>{t('working-version-deal', { dealName: deal.name })}</b>
          </Link>
          <>&nbsp;</>
          {t('message-strip.part-2', { displayId: deal.displayId })}
        </span>
      </MessageStrip>
      <Label showColon required>
        {t('select.decision-paper-template')}
      </Label>
      <DealWorkingVersionEventCreationTemplateGroup
        onChange={handleTemplateChange}
        templateCode={decisionPaperTemplateCode}
        eventCode={event.eventType}
        entityId={event.entityId}
        entityType={event.entityType}
      />
      {decisionPaperTemplateCode !== null && (
        <>
          <Text className={styles.textPadding}>{t('import-data')}</Text>
          <LabeledSwitch
            onChange={handleSwitchClicked}
            checked={displayTable}
            checkedText={t('synchronization.on')}
            uncheckedText={t('synchronization.off')}
            isLoading={false}
          />
          {displayTable && (
            <DecisionPaperTable
              entityId={event.entityId}
              onChange={onImportEventChange}
              selectedDecisionPaper={eventIdToImportDataFrom}
              setSelectedDecisionPaper={setEventIdToImportDataFrom}
              isEventCreation
            />
          )}
        </>
      )}
    </>
  )
}

WorkingVersionDealEventSecondStageDialogBody.propTypes = {
  handleTemplateChange: PropTypes.func.isRequired,
  handleSwitchClicked: PropTypes.func.isRequired,
  onImportEventChange: PropTypes.func.isRequired,
  setEventIdToImportDataFrom: PropTypes.func.isRequired,
  decisionPaperTemplateCode: PropTypes.string,
  eventIdToImportDataFrom: PropTypes.string,
  displayTable: PropTypes.bool,
  deal: PropTypes.shape({
    displayId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  event: PropTypes.shape({
    entityType: PropTypes.string.isRequired,
    entityId: PropTypes.string.isRequired,
    eventType: PropTypes.string.isRequired,
  }),
}

export default WorkingVersionDealEventSecondStageDialogBody
