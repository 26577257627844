import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ApprovalActionCell,
  ApprovalApprovedUntilDateCell,
  ApprovalCommentCell,
  ApprovalMoneyCell,
  ArrearCell,
  ArrearClassificationCell,
} from 'components/domains/business-partners/tile/arrears/current-approvals/ArrearApprovalTableCells'
import { renderAnalyticalTableCell } from 'components/ui/tables/analytical/AnalyticalTableCell'
import { useCustomizableCurrencyFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'

const useCurrentApprovalsTableColumns = ({
  isEditable = true,
  isStartApprovalProcess = false,
  arrearApprovalEditFunctions,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.in-approval',
  })
  const { t: tValueState } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.in-approval.value-state',
  })

  const formatCurrency = useCustomizableCurrencyFormatter()
  const { format: formatDate, localePattern, parse } = useShortDateFormatter()

  const tableHelpers = useMemo(
    () => ({
      formatCurrency,
      formatDate,
      localePattern,
      parse,
      tValueState,
      isEditable,
      isStartApprovalProcess,
      ...arrearApprovalEditFunctions,
    }),
    [
      arrearApprovalEditFunctions,
      formatCurrency,
      formatDate,
      isEditable,
      isStartApprovalProcess,
      localePattern,
      parse,
      tValueState,
    ],
  )

  const defaultDisables = useMemo(
    () => ({
      disableResizing: false,
      disableDragAndDrop: false,
      disableGlobalFilter: true,
      disableGroupBy: true,
    }),
    [],
  )

  const actionColumn = useMemo(
    () =>
      isStartApprovalProcess
        ? []
        : [
            {
              Header: '',
              id: 'action-cell',
              Cell: renderAnalyticalTableCell(ApprovalActionCell, tableHelpers),
              width: 160,
              ...defaultDisables,
            },
          ],
    [defaultDisables, isStartApprovalProcess, tableHelpers],
  )

  const columns = useMemo(
    () => [
      {
        Header: t('arrears'),
        id: 'arrears',
        accessor: 'rowData.approvalItem.arrearType',
        Cell: renderAnalyticalTableCell(ArrearCell, tableHelpers),
        minWidth: 350,
        ...defaultDisables,
      },
      {
        Header: t('approval-amount'),
        Cell: renderAnalyticalTableCell(ApprovalMoneyCell, tableHelpers),
        id: 'approval-amount',
        accessor: 'sumData.approvedAmount',
        hAlign: 'End',
        minWidth: 200,
        ...defaultDisables,
      },
      {
        Header: t('approved-until'),
        id: 'approved-until',
        accessor: 'rowData.approvedUntil',
        Cell: renderAnalyticalTableCell(ApprovalApprovedUntilDateCell, tableHelpers),
        hAlign: 'End',
        minWidth: 100,
        ...defaultDisables,
      },
      {
        Header: t('arrears-classification'),
        id: 'arrears-classification',
        accessor: 'rowData.approvalItem.classification.code',
        Cell: renderAnalyticalTableCell(ArrearClassificationCell, tableHelpers),
        minWidth: 350,
        ...defaultDisables,
      },
      {
        Header: t('comment'),
        id: 'comment',
        accessor: 'rowData.comment',
        Cell: renderAnalyticalTableCell(ApprovalCommentCell, tableHelpers),
        ...defaultDisables,
      },
      ...actionColumn,
    ],
    [actionColumn, defaultDisables, t, tableHelpers],
  )

  return useMemo(() => ({ tableColumns: columns }), [columns])
}
export default useCurrentApprovalsTableColumns
