import isNil from 'lodash.isnil'
import sortBy from 'lodash.sortby'
import {
  groupRentalUnitsBySegmentUsageTypeCode,
  tenancyMeasurementUnitsPC,
} from 'hooks/services/business-partners/tenancy/tenancyCalculationUtils'

const getAllValuesWithMeasurementUnitPC = (rentalUnits) =>
  rentalUnits
    .map((rentalUnit) => rentalUnit.area)
    .filter((area) => tenancyMeasurementUnitsPC.includes(area.measurementUnit))
    .map((area) => area.value)

export const calculateTenancyTotalRentedAreaPieces = (rentalUnits, totalNumberOfPieces) => {
  const sum = getAllValuesWithMeasurementUnitPC(rentalUnits).reduce(
    (partialSum, a) => partialSum + a,
    0,
  )
  let share = NaN
  if (!isNil(totalNumberOfPieces)) {
    share = totalNumberOfPieces ? sum / totalNumberOfPieces : 0
  }

  return {
    value: sum,
    unit: undefined,
    share,
  }
}

export const calculateTenancyTotalRentedAreaPiecesPerSegmentUsageType = (rentalUnits) => {
  const groupedRentalUnits = groupRentalUnitsBySegmentUsageTypeCode(rentalUnits)

  const areaPiecesPerSegementUsageType = []
  Object.entries(groupedRentalUnits).forEach((rentalUnitsBySegement) => {
    const segmentName = rentalUnitsBySegement[1][0].segmentUsageTypeName
    const rentalUnitsForSegment = rentalUnitsBySegement[1]

    areaPiecesPerSegementUsageType.push({
      value: getAllValuesWithMeasurementUnitPC(rentalUnitsForSegment).reduce(
        (partialSum, a) => partialSum + a,
        0,
      ),
      share: 0,
      type: segmentName,
    })
  })

  return sortBy(areaPiecesPerSegementUsageType, (item) => item.type)
}
