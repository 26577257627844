import { BusyIndicator, BusyIndicatorSize } from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { useTranslation } from 'react-i18next'
import Dialog from 'components/ui/dialog/Dialog'

const PropertyRentRollExcelExportLoadingScreen = ({ isVisible }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.properties.rent-roll.excel-export.loading-screen',
  })

  return (
    <Dialog open={isVisible} headerText={t('title')}>
      <BusyIndicator
        active
        text={t('text')}
        size={BusyIndicatorSize.Medium}
        style={{ textAlign: 'left', display: 'block' }}
      />
    </Dialog>
  )
}

PropertyRentRollExcelExportLoadingScreen.propTypes = {
  isVisible: PropType.bool.isRequired,
}

export default PropertyRentRollExcelExportLoadingScreen
