import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  Input,
  Label,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/rent-roll/working-version/PropertyRentRollWorkingVersionHeader.module.css'
import { ValueStateMessage } from 'components/domains/properties/rent-roll/working-version/ValueStateMessage'
import { getHeaderWorkingVersionFieldId } from 'components/domains/properties/rent-roll/working-version/utils/rentRollWorkingVersionMappers'
import CardHeaderWithButtons from 'components/ui/card/CardHeaderWithButtons'
import DatePickerWithoutMinWidth from 'components/ui/date-picker/DatePickerWithoutMinWidth'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const PropertyRentRollWorkingVersionHeader = ({
  children,
  headerValues,
  handleOnChange,
  properties,
}) => {
  const { localePattern, parse } = useShortDateFormatter()
  const { t } = useTranslation()

  const { creator, key_date: keyDate, description } = headerValues
  const maxDescriptionCharacters = 50

  const renderCreatorField = () => (
    <FlexBox className={styles.creatorWrapper}>
      <Label showColon>{t('pages.property.rent-roll.header.creator')}</Label>
      <Input
        id={getHeaderWorkingVersionFieldId('creator')}
        readonly
        value={creator.value}
        valueState={creator.valueState}
        valueStateMessage={<ValueStateMessage name={creator.valueStateMessage} />}
      />
    </FlexBox>
  )

  const renderKeyDateField = () => (
    <FlexBox alignItems={FlexBoxAlignItems.Center} className={styles.keyDateWrapper}>
      <Label showColon required>
        {t('pages.property.rent-roll.header.key-date')}
      </Label>
      <DatePickerWithoutMinWidth
        key={`key-date-${JSON.stringify(keyDate)}`}
        id={getHeaderWorkingVersionFieldId('key_date')}
        onChange={(event) => {
          const dateValue = event.detail.valid
            ? parse(event.detail.value, localePattern)
            : event.detail.value

          handleOnChange('key_date', dateValue)
        }}
        placeholder={localePattern}
        formatPattern={localePattern}
        value={keyDate.value ?? ''}
        valueState={keyDate.valueState}
        valueStateMessage={<ValueStateMessage name={keyDate.valueStateMessage} />}
        className={styles.datePicker}
      />
    </FlexBox>
  )

  const renderDescriptionField = () => (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.descriptionWrapper} fitContainer>
      <FlexBox>
        <Label showColon required>
          {t('pages.property.rent-roll.header.description')}
        </Label>
      </FlexBox>
      <FlexBox>
        <Input
          id={getHeaderWorkingVersionFieldId('description')}
          onChange={(event) => handleOnChange('description', event.target.value)}
          value={description.value}
          valueState={description.valueState}
          valueStateMessage={<ValueStateMessage name={description.valueStateMessage} />}
          maxlength={maxDescriptionCharacters}
        />
      </FlexBox>
    </FlexBox>
  )

  const renderPropertiesField = () => {
    if (!properties) {
      return <></>
    }
    const propertiesToDisplay = properties?.map(
      (property) => `${property.description} (${property.id})`,
    )
    return (
      <FlexBox className={styles.propertiesWrapper}>
        <Label showColon required>
          {t('pages.property.rent-roll.header.properties')}
        </Label>
        <Input
          id={getHeaderWorkingVersionFieldId('properties')}
          value={propertiesToDisplay.join(', ')}
          readonly
        />
      </FlexBox>
    )
  }

  return (
    <>
      <CardHeaderWithButtons title={t('pages.property.rent-roll.header.title')}>
        {children}
      </CardHeaderWithButtons>
      <div>
        <FlexBox direction={FlexBoxDirection.Column} className={styles.headerContentWrapper}>
          <FlexBox>
            {renderCreatorField()}
            {renderKeyDateField()}
          </FlexBox>
          {renderPropertiesField()}
          <FlexBox>{renderDescriptionField()}</FlexBox>
        </FlexBox>
      </div>
    </>
  )
}

const validationFieldPropTypeString = PropTypes.shape({
  value: PropTypes.string,
  valueState: PropTypes.oneOf(['None', 'Error', 'Warning']),
  valueStateMessage: PropTypes.string,
})

PropertyRentRollWorkingVersionHeader.propTypes = {
  children: PropTypes.array,
  headerValues: PropTypes.shape({
    key_date: validationFieldPropTypeString,
    description: validationFieldPropTypeString,
    creator: validationFieldPropTypeString,
  }).isRequired,
  handleOnChange: PropTypes.func.isRequired,
  properties: PropTypes.arrayOf(PropTypes.object),
}
export default PropertyRentRollWorkingVersionHeader
