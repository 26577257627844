import {
  Label,
  ObjectStatus,
  TableGrowingMode,
  TableRowType,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropType from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getObjectStatusForDecisionStageStatus } from 'api/decision-process/decisionProcessApi'
import { getObjectStatusforDecisionStageMinutesStatus } from 'api/decision-process/decisionProcessMinutesApi'
import BusinessObjectLink from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import styles from 'components/domains/business-events-and-tasks/events/decision-stage/UserDecisionStageVotesCardTable.module.css'
import Entity from 'components/ui/data/Entity'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import paths from 'routes/paths'

const views = {
  condition: 'condition',
  minute: 'minute',
}

const hyphen = '-'

const UserDecisionStageVotesCardTable = ({
  voterData,
  noDataText,
  onLoadMore,
  shownColumns,
  additionalActions,
  additionalActionsNextToTitle,
  tableTitle,
  approvalView,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.homepage.my-decisions.table',
  })

  const navigate = useNavigate()
  const { t: tWithoutPrefix } = useTranslation()
  const { format } = useShortDateFormatter()

  const possibleColumns = useMemo(
    () => ({
      decisionPaper: {
        title: t('decision-paper'),
        columnKey: 'decisionPaper',
      },
      approvals: {
        title: t('approvals'),
        columnKey: 'approvals',
      },
      entityRef: {
        title: t('entity-ref'),
        columnKey: 'entityRef',
      },
      event: {
        title: t('business-event'),
        columnKey: 'event',
      },
      decisionStage: {
        title: t('decision-stage'),
        columnKey: 'decisionStage',
      },
      decisionDate: {
        title: t('decision-date'),
        columnKey: 'decisionDate',
      },
      approvalDate: {
        title: t('approval-date'),
        columnKey: 'approvalDate',
      },
      stageStatus: {
        title: t('stage-status'),
        columnKey: 'stageStatus',
      },
      approvalStatus: {
        title: t('approval-status'),
        columnKey: 'approvalStatus',
      },
    }),
    [t],
  )

  const tableColumns = useMemo(
    () => shownColumns.map((columnKey) => possibleColumns[columnKey]),
    [possibleColumns, shownColumns],
  )

  const displayStageStatus = useCallback(
    (status) => {
      const { translationKey: statusTranslationKey, objectStatus } =
        getObjectStatusForDecisionStageStatus(status)
      return (
        <ObjectStatus inverted state={objectStatus}>
          {tWithoutPrefix(statusTranslationKey)}
        </ObjectStatus>
      )
    },
    [tWithoutPrefix],
  )

  const displayApprovalStatus = useCallback(
    (status) => {
      const { translationKey: statusTranslationKey, objectStatus } =
        getObjectStatusforDecisionStageMinutesStatus(status)
      return (
        <ObjectStatus inverted state={objectStatus}>
          {tWithoutPrefix(statusTranslationKey)}
        </ObjectStatus>
      )
    },
    [tWithoutPrefix],
  )

  const handleRowClick = useCallback(
    ({ detail: { row: clickedTableRow } }) => {
      const stageId = clickedTableRow.getAttribute('data-stage-id')
      const eventId = clickedTableRow.getAttribute('data-event-id')
      const voterId = clickedTableRow.getAttribute('data-voter-id')
      switch (approvalView) {
        case views.condition: {
          navigate(
            `/${paths.businessEventsAndTasks}/business-events/${eventId}/decision-stages/${stageId}/conditions/approval/voters/${voterId}`,
          )
          break
        }
        case views.minute: {
          navigate(
            `/${paths.businessEventsAndTasks}/business-events/${eventId}/decision-stages/${stageId}/minutes/approval/voters/${voterId}`,
          )
          break
        }
        default: {
          navigate(
            `/${paths.businessEventsAndTasks}/business-events/${eventId}/decision-stages/${stageId}/approval/voters/${voterId}`,
          )
        }
      }
    },
    [approvalView, navigate],
  )

  const approvalsRowName = useMemo(() => {
    if (approvalView === views.minute) {
      return t('minute-row-name')
    }
    return t('condition-row-name')
  }, [approvalView, t])

  const approvalsRowLink = useCallback(
    ({ eventId, stageId, voterId }) => {
      if (approvalView === views.minute) {
        return `/${paths.businessEventsAndTasks}/business-events/${eventId}/decision-stages/${stageId}/minutes/approval/voters/${voterId}`
      }
      return `/${paths.businessEventsAndTasks}/business-events/${eventId}/decision-stages/${stageId}/conditions/approval/voters/${voterId}`
    },
    [approvalView],
  )

  const decisionDateDisplay = useCallback(
    (minuteDecisionDate, conditionDecisionDate) => {
      if (approvalView === views.minute) {
        return format(minuteDecisionDate) ?? hyphen
      }
      return format(conditionDecisionDate) ?? hyphen
    },
    [approvalView, format],
  )

  const tableData = useMemo(
    () =>
      voterData.records.map(
        ({
          voterId,
          event: {
            displayId,
            id: eventId,
            name: eventName,
            entityRef: { entityId, entityType },
          },
          decisionStage: { id: stageId, name: stageName, decisionDate, status },
          decisionPaper,
          minute,
          condition,
        }) => ({
          rowKey: `stage-table-${stageId}`,
          rowProperties: {
            type: TableRowType.Active,
            'data-stage-id': stageId,
            'data-event-id': eventId,
            'data-voter-id': voterId,
          },
          decisionPaper: {
            cellComponent: (
              <Entity
                name={decisionPaper?.name}
                id={t('version', { version: decisionPaper?.version })}
                link={`/${paths.businessEventsAndTasks}/business-events/${eventId}/decision-stages/${stageId}/approval/voters/${voterId}`}
              />
            ),
          },
          approvals: {
            cellComponent: (
              <Entity
                name={approvalsRowName}
                link={approvalsRowLink({ eventId, stageId, voterId })}
              />
            ),
          },
          entityRef: {
            cellComponent: <BusinessObjectLink entityId={entityId} entityType={entityType} />,
          },
          event: {
            cellComponent: (
              <Entity
                name={eventName}
                id={displayId}
                link={`/${paths.businessEventsAndTasks}/business-events/${eventId}`}
                openInNewTab
              />
            ),
          },
          decisionStage: {
            cellComponent: (
              <Entity
                name={stageName}
                link={`/${paths.businessEventsAndTasks}/business-events/${eventId}/decision-stages/${stageId}`}
                openInNewTab
              />
            ),
          },
          decisionDate: {
            cellComponent: (
              <Label>
                <Label>{decisionDate ? format(decisionDate) : hyphen}</Label>
              </Label>
            ),
          },
          approvalDate: {
            cellComponent: (
              <Label>{decisionDateDisplay(minute?.decisionDate, condition?.decisionDate)}</Label>
            ),
          },
          stageStatus: {
            cellComponent: <Label>{displayStageStatus(status)}</Label>,
          },
          approvalStatus: {
            cellComponent: (
              <Label>
                {displayApprovalStatus(isNil(minute?.status) ? condition?.status : minute?.status)}
              </Label>
            ),
          },
        }),
      ),
    [
      voterData.records,
      t,
      approvalsRowName,
      approvalsRowLink,
      format,
      decisionDateDisplay,
      displayStageStatus,
      displayApprovalStatus,
    ],
  )

  const renderContent = useMemo(
    () => (
      <div className={styles.tableWrapper}>
        <SortedTable
          toolbarConfig={{
            title: tableTitle,
            showColumnSelection: false,
            additionalActions: [additionalActions],
            additionalActionsNextToTitle: [additionalActionsNextToTitle],
          }}
          columnDefinitions={tableColumns}
          tableData={tableData}
          additionalTableProperties={{
            stickyColumnHeader: true,
            className: styles.tableProperties,
            onRowClick: handleRowClick,
          }}
          noDataText={noDataText}
          paginationConfig={{
            growing: tableData.length < voterData.total ? TableGrowingMode.Button : undefined,
            growingButtonText: t('button.load-more'),
            growingButtonSubtext: `[${tableData.length} / ${voterData.total}]`,
            totalNumberOfItems: voterData.total,
            loadMore: onLoadMore,
          }}
        />
      </div>
    ),
    [
      additionalActions,
      additionalActionsNextToTitle,
      voterData.total,
      handleRowClick,
      noDataText,
      onLoadMore,
      t,
      tableColumns,
      tableData,
      tableTitle,
    ],
  )

  return renderContent
}

UserDecisionStageVotesCardTable.propTypes = {
  voterData: PropType.shape({
    records: PropType.arrayOf(
      PropType.shape({
        event: PropType.shape({
          id: PropType.string.isRequired,
          displayId: PropType.string.isRequired,
          name: PropType.string.isRequired,
          entityRef: PropType.shape({
            entityId: PropType.string.isRequired,
            entityType: PropType.string.isRequired,
          }).isRequired,
        }).isRequired,
        decisionStage: PropType.shape({
          id: PropType.string.isRequired,
          name: PropType.string.isRequired,
          status: PropType.string.isRequired,
          decisionDate: PropType.string,
        }).isRequired,
        decisionPaper: PropType.shape({
          id: PropType.string.isRequired,
          name: PropType.string.isRequired,
          version: PropType.string.isRequired,
        }),
        minute: PropType.shape({
          id: PropType.string.isRequired,
          status: PropType.string.isRequired,
        }),
        condition: PropType.shape({
          id: PropType.string.isRequired,
          status: PropType.string.isRequired,
        }),
      }),
    ),
  }),
  shownColumns: PropType.arrayOf(
    PropType.oneOf([
      'event',
      'entityRef',
      'decisionDate',
      'approvalDate',
      'decisionStage',
      'stageStatus',
      'decisionPaper',
      'approvals',
      'approvalStatus',
    ]),
  ),
  // eslint-disable-next-line react/forbid-prop-types
  additionalActions: PropType.object,
  // eslint-disable-next-line react/forbid-prop-types
  additionalActionsNextToTitle: PropType.object,
  noDataText: PropType.string.isRequired,
  onLoadMore: PropType.func.isRequired,
  tableTitle: PropType.string.isRequired,
  approvalView: PropType.string,
}

export default UserDecisionStageVotesCardTable
