import { FlexBox } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CloseableMessageStrip from 'components/ui/feedback/CloseableMessageStrip'
import styles from 'components/ui/feedback/ExistingPropertyInfoMessage.module.css'
import { EXISTING_PROPERTY_INDICATOR } from 'utils/propertyAddressCheck'

/**
 * move to property-domain | https://fioneer.atlassian.net/browse/CWP-13200
 */
const ExistingPropertyMessageBox = ({ infoMessage, className }) => {
  const { t } = useTranslation()
  let innerChild

  switch (infoMessage) {
    case EXISTING_PROPERTY_INDICATOR.SAME_STREET: {
      innerChild = (
        <CloseableMessageStrip design="Information">
          {t('components.ui.feedback.dialog.property.same-street.info')}
        </CloseableMessageStrip>
      )
      break
    }
    case EXISTING_PROPERTY_INDICATOR.SAME_ADDRESS: {
      innerChild = (
        <CloseableMessageStrip design="Information">
          {t('components.ui.feedback.dialog.property.same-address.info')}
        </CloseableMessageStrip>
      )
      break
    }
    default:
      break
  }

  return (
    <React.Fragment>
      {innerChild ? (
        <FlexBox id="create-property-info-message" className={styles[className]}>
          {innerChild}
        </FlexBox>
      ) : null}
    </React.Fragment>
  )
}

ExistingPropertyMessageBox.propTypes = {
  infoMessage: PropTypes.string.isRequired,
  className: PropTypes.any,
}

export default ExistingPropertyMessageBox
