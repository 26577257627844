import {
  FlexBox,
  FlexBoxDirection,
  Link,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperTileListItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListItem'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/working-version/general-information/WorkingVersionDetailsTile.module.css'
import { ErrorDataUnavailableInCell } from 'components/domains/business-events-and-tasks/decision-paper/tiles/working-version/general-information/shared/components/ui/errors/ErrorDataUnavailableInCell'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/working-version/general-information/shared/i18n/useI18n'

const PLACEHOLDER = '-'

const WorkingVersionDetailsTile = ({ tileId }) => {
  const { data: { dealId, live, workingVersion, adjustments } = {} } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId],
  )

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.working-version-details',
  })

  const { format: formatDate } = useShortDateFormatter()
  const getValueOrPlaceholder = (value) => value ?? PLACEHOLDER
  const getLinkOrPlaceholder = (value, href) =>
    value ? (
      <Link target={'_blank'} href={href} className={styles.link}>
        {value}
      </Link>
    ) : (
      PLACEHOLDER
    )

  return (
    <>
      <FlexBox direction={FlexBoxDirection.Column} className={styles.cardContentWrapper}>
        <DecisionPaperTileListItem
          label={t('label.working-version-name')}
          value={getLinkOrPlaceholder(
            workingVersion?.name,
            `/deals/${dealId}?working-version=true`,
          )}
        />
        <DecisionPaperTileListItem
          label={t('label.live-version-name')}
          value={getLinkOrPlaceholder(live?.name, `/deals/${dealId}`)}
        />
        <DecisionPaperTileListItem
          label={t('label.deal-id')}
          value={getValueOrPlaceholder(dealId)}
        />
        <DecisionPaperTileListItem
          label={t('label.created-on')}
          value={getValueOrPlaceholder(formatDate(workingVersion?.createdAt))}
        />
        <DecisionPaperTileListItem
          label={t('label.created-by')}
          value={
            workingVersion?.isCreatedByError ? (
              <ErrorDataUnavailableInCell />
            ) : (
              getValueOrPlaceholder(workingVersion?.createdBy)
            )
          }
        />
        <DecisionPaperTileListItem
          label={t('label.adjustment-type')}
          value={getValueOrPlaceholder(adjustments?.types?.join(', '))}
        />
      </FlexBox>
      <FlexBox direction={FlexBoxDirection.Column} className={styles.cardSubContentWrapper}>
        <Title level={TitleLevel.H5} className={styles.subContentTitle}>
          {t('label.comment')}
        </Title>
        <div>{getValueOrPlaceholder(adjustments?.comment)}</div>
      </FlexBox>
    </>
  )
}

WorkingVersionDetailsTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default WorkingVersionDetailsTile
