import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import RichTextTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/RichTextTile'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/non-ref-credit-decision/involved-parties/manual-checksheet/NonRefCreditDecisionCheckSheetTableCell.module.css'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const NonRefCreditDecisionCheckSheetTableCell = ({
  question,
  value,
  customAnswerTranslationPrefix,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix:
      customAnswerTranslationPrefix ??
      'tiles.non-ref-credit-decision.involved-parties.checksheet.table.cell',
  })

  const { format: formatDate } = useShortDateFormatter()

  const renderDefault = () => (!isNil(value) ? t(value) : t('empty-value'))

  const renderDatePicker = () => (!isNil(value) ? formatDate(value) : t('empty-value'))

  const renderRTE = () =>
    !isNil(value) ? (
      <RichTextTile
        isEditMode={false}
        isPdfView={true}
        currentContent={value}
        className={styles.richTextTile}
      />
    ) : (
      t('empty-value')
    )

  switch (question) {
    case 'Q20':
      return renderDatePicker()
    case 'Comment':
      return renderRTE()

    default:
      return renderDefault()
  }
}

NonRefCreditDecisionCheckSheetTableCell.propTypes = {
  question: PropTypes.string.isRequired,
  customAnswerTranslationPrefix: PropTypes.string,
  value: PropTypes.string,
}

export default NonRefCreditDecisionCheckSheetTableCell
