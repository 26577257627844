import { createSlice } from '@reduxjs/toolkit'
import sharedActions from 'redux/slices/markets/marketProxiesSharedActions'

export const marketMicroProxiesSlice = createSlice({
  name: 'market-micro-proxies',
  initialState: sharedActions.initialState,
  reducers: sharedActions.actions,
})

export const {
  setProxies,
  startEditing,
  endEditing,
  cancelEditing,
  setOverrideSettings,
  setThreshold,
  setThresholdType,
  setDirection,
  setMonitoring,
} = marketMicroProxiesSlice.actions

export default marketMicroProxiesSlice.reducer
