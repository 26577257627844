import { Title, TitleLevel } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { Fragment, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { createDealName } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/utils/createDealSelectOptions'
import useAnnualReviewDealOverviewSelectedDeal from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/utils/useAnnualReviewDealOverviewSelectedDeal'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/wrapper/MultipleDealSelectionPdfViewWrapper.module.css'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'

const MultipleDealSelectionPdfViewWrapper = ({
  tileId,
  contentCreator,
  isPdfView,
  dealSelectionTileCode = availableAutomaticTiles.annualReviewDealSelection,
}) => {
  const { t: tDecisionPaper } = useTranslation('decisionPaper')
  const { selectedDealIndex } = useAnnualReviewDealOverviewSelectedDeal(
    tileId,
    dealSelectionTileCode,
  )
  const { dealsData = [] } =
    useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId].data) ?? {}

  const callContentCreator = useCallback(
    ({ selectedDealIndex: internalSelectedDealIndex }) =>
      contentCreator({ isPdfView, selectedDealIndex: internalSelectedDealIndex, tileId }),
    [contentCreator, isPdfView, tileId],
  )

  if (isPdfView) {
    if (isEmpty(dealsData)) {
      return tDecisionPaper('tiles.deal-selection.empty')
    }
    return dealsData.map((dealData, currentDealIndex) => {
      const dealName = createDealName({
        nonExternalString: '',
        externalString: tDecisionPaper('tiles.deal-selection.external-suffix'),
        entityTranslation: tDecisionPaper('entity-types.DEAL'),
        dealName: dealData.dealName ?? dealData.name,
        dealDisplayId: dealData.dealDisplayId ?? dealData.dealId,
        isExternal: dealData.isExternal,
        isWorkingVersion: dealData?.isWorkingVersion,
        workingVersionLabel: tDecisionPaper(
          'tiles.annual-review.deal-overview.deal-version.working-version',
        ),
      })
      return (
        <Fragment key={`deal-pdf-view-${tileId}-${dealData.dealDisplayId}-${currentDealIndex}`}>
          <Title level={TitleLevel.H2} className={styles.dealName}>
            {dealName}
          </Title>
          {callContentCreator({ selectedDealIndex: currentDealIndex })}
        </Fragment>
      )
    })
  }
  return callContentCreator({ selectedDealIndex })
}

MultipleDealSelectionPdfViewWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  contentCreator: PropTypes.func.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  dealSelectionTileCode: PropTypes.string,
}

export default MultipleDealSelectionPdfViewWrapper
