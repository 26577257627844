import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/pd-rating/BusinessPartnerRatingsOverviewTile.module.css'
import RiskIndicatorCheckExternalRatingsOverview from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/pd-rating/RiskIndicatorCheckExternalRatingsOverview'
import RiskIndicatorCheckInternalRatingsOverview from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/pd-rating/RiskIndicatorCheckInternalRatingsOverview'

const BusinessPartnerRatingsOverviewTile = ({ tileId }) => {
  const {
    data: { ratings },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { internal = [], external = [] } = ratings

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.card}>
      <RiskIndicatorCheckInternalRatingsOverview internalRatings={internal} />
      <div className={styles.separator} />
      <RiskIndicatorCheckExternalRatingsOverview externalRatings={external} />
    </FlexBox>
  )
}

BusinessPartnerRatingsOverviewTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default BusinessPartnerRatingsOverviewTile
