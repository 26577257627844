import React, { lazy, Suspense, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import {
  FINANCING_CUSTOM_FIELDS_READ,
  FINANCING_TRANCHE_ACCOUNTING_READ,
  FINANCING_TRANCHE_ADDITIONAL_INTEREST_INFORMATION_READ,
  FINANCING_TRANCHE_AMORTIZATION_CONDITIONS_READ,
  FINANCING_TRANCHE_FEE_READ,
  FINANCING_TRANCHE_INTEREST_CONDITIONS_READ,
  FINANCING_TRANCHE_INTEREST_RATE_HEDGING_REQUIREMENT_READ,
  FINANCING_TRANCHE_OPTIONS_READ,
  FINANCING_TRANCHE_PRICING_READ,
  FINANCING_TRANCHE_REMOVE,
} from 'api/deals/financing/allowedOperationsConstants'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { useDealWorkingVersionToolbarButtons } from 'components/domains/deals/deal-adjustment/useDealWorkingVersionToolbarButtons'
import TrancheDetailsOpenInCmlButton from 'components/domains/deals/financing/cml/TrancheDetailsOpenInCmlButton'
import TrancheDelete from 'components/domains/deals/financing/tranche-delete/TrancheDelete'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import LazyLoadingFallbackCard from 'components/ui/card/LazyLoadingFallbackCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useTranche from 'hooks/services/deals/financing/useTranche'
import useTrancheByDisplayId from 'hooks/services/deals/financing/useTrancheByDisplayId'
import useSyndicationsExistingBusiness from 'hooks/services/deals/syndication/useSyndicationsExistingBusiness'
import useDealMini from 'hooks/services/deals/useDealMini'
import lazyRetry from 'lazyRetry'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'
import { DATA_SOURCES, DEAL_STATUS_RANKS } from 'routes/deals/financing/financingConstants'
import NewAndExistingBusinessSwitch from 'routes/deals/financing/trancheDetails/financing-header/NewAndExistingBusinessSwitch'
import paths from 'routes/paths'

const TrancheGeneralInformationCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "TrancheGeneralInformationCard" */ 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/TrancheGeneralInformationCard'
      ),
    'TrancheGeneralInformationCard',
  ),
)
const TranchePricingCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "TranchePricingCard" */ 'routes/deals/financing/trancheDetails/cards/pricing-card/TranchePricingCard'
      ),
    'TranchePricingCard',
  ),
)
const InterestConditionCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "InterestConditionCard" */ 'routes/deals/financing/trancheDetails/cards/interest-conditions/InterestConditionsCard'
      ),
    'InterestConditionCard',
  ),
)
const AdditionalInterestInformationCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AdditionalInterestInformationCard" */ 'routes/deals/financing/trancheDetails/cards/additional-interest-information-card/AdditionalInterestInformationCard'
      ),
    'AdditionalInterestInformationCard',
  ),
)
const TrancheFeesCardTable = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "TrancheFeesCardTable" */ 'routes/deals/financing/trancheDetails/cards/tranche-fees/TrancheFeesCard'
      ),
    'TrancheFeesCardTable',
  ),
)
const AmortizationCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AmortizationCard" */ 'routes/deals/financing/trancheDetails/cards/amortization-card/AmortizationCard'
      ),
    'AmortizationCard',
  ),
)
const AccountingCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AccountingCard" */ 'routes/deals/financing/trancheDetails/cards/accounting-card/AccountingCard'
      ),
    'AccountingCard',
  ),
)
const CustomerSpecificAttributesCardLoader = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "CustomerSpecificAttributesCardLoader" */ 'routes/deals/financing/trancheDetails/cards/customer-specific-attributes-card/CustomerSpecificAttributesCardLoader'
      ),
    'CustomerSpecificAttributesCardLoader',
  ),
)
const BasicAgreementsCustomFieldsCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "BasicAgreementsCustomFieldsCard" */ 'routes/deals/financing/trancheDetails/cards/customer-specific-attributes-card/BasicAgreementsCustomFieldsCard'
      ),
    'BasicAgreementsCustomFieldsCard',
  ),
)
const InterestRateHedgingRequirementsCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "InterestRateHedgingRequirementsCard" */ 'routes/deals/financing/trancheDetails/cards/interest-rate-hedging-requirements-card/InterestRateHedgingRequirementsCard'
      ),
    'InterestRateHedgingRequirementsCard',
  ),
)
const CustomerOptionsCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "CustomerOptionsCard" */ 'routes/deals/financing/trancheDetails/cards/customer-options-card/CustomerOptionsCard'
      ),
    'CustomerOptionsCard',
  ),
)

const DealFinancingTrancheDetails = () => {
  const { deal, financingAllowedOperations: allowedOperations } = useContext(DealContext)
  const { manageWorkingVersionButton } = useDealWorkingVersionToolbarButtons()
  const { t } = useTranslation()
  const { trancheDisplayId } = useParams()

  // NOTE: This page is used for both drawdowns and tranches, therefore we need to check the pathname
  const { pathname } = useLocation()
  const isDrawdown = pathname.includes('drawdowns')

  const hasPricingReadPermission = allowedOperations.includes(FINANCING_TRANCHE_PRICING_READ)
  const hasFeeReadPermission = allowedOperations.includes(FINANCING_TRANCHE_FEE_READ)
  const hasInterestConditionsReadPermission = allowedOperations.includes(
    FINANCING_TRANCHE_INTEREST_CONDITIONS_READ,
  )
  const hasAmortizationConditionsReadPermission = allowedOperations.includes(
    FINANCING_TRANCHE_AMORTIZATION_CONDITIONS_READ,
  )
  const hasAdditionalInterestInformationReadPermission = allowedOperations.includes(
    FINANCING_TRANCHE_ADDITIONAL_INTEREST_INFORMATION_READ,
  )
  const hasInterestRateHedgingRequirementReadPermission = allowedOperations.includes(
    FINANCING_TRANCHE_INTEREST_RATE_HEDGING_REQUIREMENT_READ,
  )
  const hasAccountingReadPermission = allowedOperations.includes(FINANCING_TRANCHE_ACCOUNTING_READ)

  const hasCustomerSpecificAttributesReadPermission = allowedOperations.includes(
    FINANCING_CUSTOM_FIELDS_READ,
  )

  const hasFinancingTrancheOptionsReadPermission = allowedOperations.includes(
    FINANCING_TRANCHE_OPTIONS_READ,
  )

  // TODO: Tiles should be revealed again one by one, after they are adjusted
  const [searchParams] = useSearchParams()
  const isExistingBusinessViewSet =
    searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS

  const {
    data: resolvedTrancheData,
    isLoading: isLoadingDisplayNameResolution,
    isError: isErrorDisplayNameResolution,
  } = useTrancheByDisplayId(deal.dealUuid, trancheDisplayId)

  const {
    data: baseTrancheData = {},
    isLoading: isLoadingTranche,
    isError: isErrorTranche,
    isFetching: isFetchingTranche,
  } = useTranche(
    deal.dealUuid,
    isExistingBusinessViewSet ? trancheDisplayId : resolvedTrancheData.trancheId,
    undefined,
    false,
  )

  const {
    data: existingBusinessSyndicationData,
    isFetching: isFetchingSyndicationExistingBusiness,
  } = useSyndicationsExistingBusiness(deal.dealUuid, { enabled: isExistingBusinessViewSet })

  const ownShareExistingBusiness = useMemo(() => {
    if (!isExistingBusinessViewSet) return {}
    let ownShare

    if (isDrawdown) {
      const currentTrancheInSyndicationData =
        existingBusinessSyndicationData?.tranches?.find(({ drawDowns = [] } = {}) =>
          drawDowns.find(({ id } = {}) => id === trancheDisplayId),
        ) ?? {}
      ;({ ownShare } =
        currentTrancheInSyndicationData?.drawDowns?.find(
          ({ id } = {}) => id === trancheDisplayId,
        ) ?? {})
    } else {
      ;({ ownShare } =
        existingBusinessSyndicationData?.tranches?.find(({ id } = {}) => id === trancheDisplayId) ??
        {})
    }

    return {
      ownShare: {
        commitment: ownShare?.amount?.amountOriginalCurrency?.amount,
        available: ownShare?.availableAmount?.amountOriginalCurrency?.amount,
        outstanding: ownShare?.outstandingAmount?.amountOriginalCurrency?.amount,
        currency: ownShare?.amount?.amountOriginalCurrency?.currency,
      },
      ownShareHeadquarter: {
        commitment: ownShare?.amount?.amountHeadQuarterCurrency?.amount,
        available: ownShare?.availableAmount?.amountHeadQuarterCurrency?.amount,
        outstanding: ownShare?.outstandingAmount?.amountHeadQuarterCurrency?.amount,
        currency: ownShare?.amount?.amountHeadQuarterCurrency?.currency,
      },
      ownCommitmentShare: ownShare?.amountShare,
    }
  }, [existingBusinessSyndicationData, isDrawdown, isExistingBusinessViewSet, trancheDisplayId])

  const trancheData = useMemo(
    () => ({ ...baseTrancheData, ownShareExistingBusiness }),
    [baseTrancheData, ownShareExistingBusiness],
  )

  const isWorkingVersion = useMemo(
    () => WorkingVersionType.WORKING_VERSION === deal.workingVersion,
    [deal],
  )

  const workingVersionSuffix = useMemo(
    () => (isWorkingVersion ? '?working-version=true' : ''),
    [isWorkingVersion],
  )

  const breadcrumbs = useMemo(
    () => [
      {
        text: t('pages.deals.breadcrumbs.financing'),
        href: `/${paths.deals}/${deal.displayId}/financing${workingVersionSuffix}`,
      },
      {
        text: `${
          !isDrawdown
            ? t('pages.deals.breadcrumbs.tranche')
            : t('pages.deals.tranches.details.drawdown')
        }: ${trancheData?.trancheName || trancheData?.externalContractId?.[0] || '...'}`,
      },
    ],
    [
      deal.displayId,
      t,
      trancheData?.trancheName,
      trancheData?.externalContractId,
      isDrawdown,
      workingVersionSuffix,
    ],
  )
  const { data: dealData = {} } = useDealMini(deal.dealUuid)

  const shouldShowNewAndExistingBusinessSwitch =
    dealData?.rank >= DEAL_STATUS_RANKS.SIGNED && dealData?.rank < DEAL_STATUS_RANKS.DEAD

  const trancheDetailsOpenInCmlAction = useMemo(() => {
    const externalContractId = trancheData?.externalContractId?.[0] ?? ''
    return (
      isExistingBusinessViewSet && (
        <TrancheDetailsOpenInCmlButton
          key={'open-in-cml-button'}
          externalContractId={externalContractId}
        />
      )
    )
  }, [isExistingBusinessViewSet, trancheData?.externalContractId])

  const newAndExistingBusinessAction = useMemo(
    () =>
      shouldShowNewAndExistingBusinessSwitch && (
        <NewAndExistingBusinessSwitch
          key={'new-and-existing-business-switch'}
          isNewBusinessEnabled={!!trancheData?.displayId}
          isExistingBusinessEnabled={trancheData?.externalContractId?.length === 1}
          trancheData={trancheData}
          dealDisplayId={deal.displayId}
        />
      ),
    [deal.displayId, shouldShowNewAndExistingBusinessSwitch, trancheData],
  )

  const deleteTrancheAction = useMemo(
    () =>
      !shouldShowNewAndExistingBusinessSwitch &&
      allowedOperations.includes(FINANCING_TRANCHE_REMOVE) && (
        <TrancheDelete key={'delete-tranche-button'} />
      ),
    [allowedOperations, shouldShowNewAndExistingBusinessSwitch],
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={deal.dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [deal.dealUuid],
  )

  const headerActions = useMemo(
    () =>
      createSegmentedHeaderActions(
        [manageWorkingVersionButton],
        [trancheDetailsOpenInCmlAction, newAndExistingBusinessAction, deleteTrancheAction],
        [<CreateDealEventAction key="create-event-action" />, markFavoriteAction],
      ),
    [
      deleteTrancheAction,
      manageWorkingVersionButton,
      markFavoriteAction,
      newAndExistingBusinessAction,
      trancheDetailsOpenInCmlAction,
    ],
  )

  return (
    <DealDetailsWrapper childBreadcrumbs={breadcrumbs} actions={headerActions}>
      <LoadingStateWrapper
        isLoading={isLoadingTranche || isLoadingDisplayNameResolution}
        isError={isErrorTranche || isErrorDisplayNameResolution}
      >
        <CWPLayout sixColumns>
          <CWPLayout.Half>
            <Suspense
              fallback={
                <LazyLoadingFallbackCard data-testid="TrancheGeneralInformationCard-fallback" />
              }
            >
              <TrancheGeneralInformationCard
                tranche={trancheData}
                isFetching={isFetchingTranche || isFetchingSyndicationExistingBusiness}
                isExistingBusinessView={isExistingBusinessViewSet}
              />
            </Suspense>
          </CWPLayout.Half>
          {!isExistingBusinessViewSet && hasPricingReadPermission && (
            <CWPLayout.Half>
              <Suspense
                fallback={<LazyLoadingFallbackCard data-testid="TranchePricingCard-fallback" />}
              >
                <TranchePricingCard />
              </Suspense>
            </CWPLayout.Half>
          )}

          {hasInterestConditionsReadPermission && (
            <CWPLayout.Full>
              <Suspense
                fallback={<LazyLoadingFallbackCard data-testid="InterestConditionCard-fallback" />}
              >
                <InterestConditionCard />
              </Suspense>
            </CWPLayout.Full>
          )}
          {hasAdditionalInterestInformationReadPermission && (
            <CWPLayout.Half>
              <Suspense
                fallback={
                  <LazyLoadingFallbackCard data-testid="AdditionalInterestInformationCard-fallback" />
                }
              >
                <AdditionalInterestInformationCard />
              </Suspense>
            </CWPLayout.Half>
          )}

          {hasFeeReadPermission && (
            <CWPLayout.Full>
              <Suspense
                fallback={<LazyLoadingFallbackCard data-testid="TrancheFeesCardTable-fallback" />}
              >
                <TrancheFeesCardTable />
              </Suspense>
            </CWPLayout.Full>
          )}

          {hasAmortizationConditionsReadPermission && (
            <CWPLayout.Full>
              <Suspense
                fallback={<LazyLoadingFallbackCard data-testid="AmortizationCard-fallback" />}
              >
                <AmortizationCard />
              </Suspense>
            </CWPLayout.Full>
          )}
          {hasAccountingReadPermission && (
            <CWPLayout.Half>
              <Suspense
                fallback={<LazyLoadingFallbackCard data-testid="AccountingCard-fallback" />}
              >
                <AccountingCard />
              </Suspense>
            </CWPLayout.Half>
          )}

          {hasCustomerSpecificAttributesReadPermission && !isExistingBusinessViewSet && (
            <CWPLayout.Half>
              <Suspense
                fallback={
                  <LazyLoadingFallbackCard data-testid="CustomerSpecificAttributesCardLoader-fallback" />
                }
              >
                <CustomerSpecificAttributesCardLoader />
              </Suspense>
            </CWPLayout.Half>
          )}

          {hasCustomerSpecificAttributesReadPermission && isExistingBusinessViewSet && (
            <CWPLayout.Half>
              <Suspense
                fallback={
                  <LazyLoadingFallbackCard data-testid="BasicAgreementsCustomFieldsCard-fallback" />
                }
              >
                <BasicAgreementsCustomFieldsCard />
              </Suspense>
            </CWPLayout.Half>
          )}

          {/*// TODO: Tiles should be revealed again one by one, after they are adjusted*/}
          {!isExistingBusinessViewSet && (
            <>
              {hasInterestRateHedgingRequirementReadPermission && (
                <CWPLayout.Half>
                  <Suspense
                    fallback={
                      <LazyLoadingFallbackCard data-testid="InterestRateHedgingRequirementsCard-fallback" />
                    }
                  >
                    <InterestRateHedgingRequirementsCard />
                  </Suspense>
                </CWPLayout.Half>
              )}

              {hasFinancingTrancheOptionsReadPermission && (
                <CWPLayout.Full>
                  <Suspense
                    fallback={
                      <LazyLoadingFallbackCard data-testid="CustomerOptionsCard-fallback" />
                    }
                  >
                    <CustomerOptionsCard />
                  </Suspense>
                </CWPLayout.Full>
              )}
            </>
          )}
        </CWPLayout>
      </LoadingStateWrapper>
    </DealDetailsWrapper>
  )
}

export default DealFinancingTrancheDetails
