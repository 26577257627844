import { useMemo } from 'react'
import { conditionTypes } from 'api/conditions/conditions'
import { useInfiniteRequest, useRequest } from 'hooks/services/baseService'
import { useInfiniteRequestsWithTotalMemo, useCamelizedResponse } from 'hooks/services/queryHelper'

const responseKeyMap = {
  [conditionTypes.external]: 'external_conditions',
  [conditionTypes.internal]: 'internal_conditions',
}

const defaultEmptyFilter = {}
const defaultEmptySorting = {}

export const useConditionsForEntityByType = ({ conditionType, entityId, entityType }, options) => {
  const queryParams = new URLSearchParams()

  queryParams.append('entity_type', entityType)
  queryParams.append('entity_id', entityId)

  return useCamelizedResponse(
    useRequest({
      path: `/conditions/${conditionType}?${queryParams.toString()}`,
      translated: true,
      useCache: true,
      keys: ['conditions', conditionType, entityType, entityId],
      options: {
        ...options,
      },
    }),
  )
}

const useConditions = (
  conditionType,
  { entityId, entityType },
  {
    searchFilter,
    type,
    visibilities,
    externalConditionId,
    assignee,
    externalAssignee,
    covenantCheck,
    status,
    statusTypes,
    creationDateFrom,
    creationDateUntil,
    references,
    approvalLevel,
  } = defaultEmptyFilter,
  { sortBy = 'condition_type', orderBy = 'asc' } = defaultEmptySorting,
  options,
) => {
  const queryParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams()

    urlSearchParams.append('entity_type', entityType)
    urlSearchParams.append('entity_id', entityId)

    const sortParam =
      typeof sortBy === 'string' &&
      sortBy !== 'condition_name' &&
      conditionType === conditionTypes.external
        ? [sortBy, 'condition_name']
        : sortBy
    urlSearchParams.append('sort_by', sortParam)

    urlSearchParams.append('order_by', orderBy)

    if (searchFilter) {
      urlSearchParams.append('search_filter', searchFilter)
    }
    if (type) {
      urlSearchParams.append('type', type)
    }
    if (visibilities && visibilities.length > 0) {
      urlSearchParams.append('visibilities', visibilities.join(','))
    }
    if (conditionType === conditionTypes.external && !!externalConditionId) {
      urlSearchParams.append('condition_uuid', externalConditionId)
    }
    if (assignee) {
      urlSearchParams.append('assignee', assignee)
    }
    if (externalAssignee) {
      urlSearchParams.append('external_assignee', externalAssignee)
    }
    if (covenantCheck) {
      urlSearchParams.append('covenant_check_id', covenantCheck)
    }
    if (status && status.length > 0) {
      urlSearchParams.append('status', status.join(','))
    }
    if (statusTypes && statusTypes.length > 0) {
      urlSearchParams.append('status_types', statusTypes.join(','))
    }
    if (creationDateFrom) {
      urlSearchParams.append('creation_date_from', creationDateFrom)
    }
    if (creationDateUntil) {
      urlSearchParams.append('creation_date_until', creationDateUntil)
    }
    if (approvalLevel) {
      urlSearchParams.append('approval_level', approvalLevel)
    }
    if (references) {
      urlSearchParams.append('references_entity_ids', references.entityIds)
      urlSearchParams.append('references_entity_type', references.entityType)
    }
    return urlSearchParams
  }, [
    entityType,
    entityId,
    sortBy,
    orderBy,
    searchFilter,
    type,
    visibilities,
    conditionType,
    externalConditionId,
    assignee,
    externalAssignee,
    covenantCheck,
    status,
    statusTypes,
    creationDateFrom,
    creationDateUntil,
    approvalLevel,
    references,
  ])

  const response = useInfiniteRequest({
    path: `/conditions/${conditionType}`,
    queryParams,
    translated: true,
    useCache: true,
    keys: ['conditions', conditionType, entityType, entityId],
    options: {
      retry: 1,
      ...options,
    },
  })

  return useInfiniteRequestsWithTotalMemo(response, responseKeyMap[conditionType])
}

export default useConditions
