import camelize from 'camelize'
import { useRequest } from 'hooks/services/baseService'

const useUserProfile = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
  const response = useRequest({
    path: `/user-profile?default_timezone=${timeZone}`,
    keys: ['user-profile'],
  })
  return { ...response, data: camelize(response.data) }
}

export default useUserProfile
