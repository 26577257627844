import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'

const badGatewayStatusCode = 502

const notAllNamesCouldBeResolvedCode = 'NOT_ALL_VOTER_NAMES_COULD_BE_RESOLVED'

const useOverarchingVoteError = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'decision-stage.vote-options.overarching.error',
  })
  const showMessageBox = useShowMessageBox()

  const onError = useCallback(
    async ({ response }) => {
      if (response.status === badGatewayStatusCode) {
        const responseBody = await response.json()
        if (responseBody.code === notAllNamesCouldBeResolvedCode) {
          showMessageBox({
            type: MessageBoxTypes.Error,
            children: t('not-all-names-could-be-resolved'),
          })
          return
        }
      }
      showMessageBox({
        type: MessageBoxTypes.Error,
        children: t('generic'),
      })
    },
    [showMessageBox, t],
  )

  return useMemo(
    () => ({
      onError,
    }),
    [onError],
  )
}

export default useOverarchingVoteError
