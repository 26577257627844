import { createSlice } from '@reduxjs/toolkit'
import closeRequirementCommentDialogImport from 'redux/slices/conditions/actions/requirements-table/closeRequirementCommentDialog'
import closeRequirementReminderDialogImport from 'redux/slices/conditions/actions/requirements-table/closeRequirementReminderDialog'
import openRequirementCommentDialogImport from 'redux/slices/conditions/actions/requirements-table/openRequirementCommentDialog'
import openRequirementReminderDialogImport from 'redux/slices/conditions/actions/requirements-table/openRequirementReminderDialog'
import requirementConditionChangedImport from 'redux/slices/conditions/actions/requirements-table/requirementConditionChanged'
import requirementDocumentTypeChangedImport from 'redux/slices/conditions/actions/requirements-table/requirementDocumentTypeChanged'
import requirementDueDateChangedImport from 'redux/slices/conditions/actions/requirements-table/requirementDueDateChanged'
import setSelectedRequirementRowsImport from 'redux/slices/conditions/actions/requirements-table/setSelectedRequirementRows'
import startRequirementAddModeImport from 'redux/slices/conditions/actions/requirements-table/startRequirementAddMode'
import startRequirementEditModeImport from 'redux/slices/conditions/actions/requirements-table/startRequirementEditMode'
import stopRequirementAddModeImport from 'redux/slices/conditions/actions/requirements-table/stopRequirementAddMode'
import assigneeChanged from 'redux/slices/conditions/actions/shared/assigneeChanged'
import descriptionChanged from 'redux/slices/conditions/actions/shared/descriptionChanged'
import externalAssigneeChanged from 'redux/slices/conditions/actions/shared/externalAssigneeChanged'
import highlightRow from 'redux/slices/conditions/actions/shared/highlightRow'
import nameChangedImport from 'redux/slices/conditions/actions/shared/nameChanged'
import nameOnBlurImport from 'redux/slices/conditions/actions/shared/nameOnBlur'
import refNumberChangedImport from 'redux/slices/conditions/actions/shared/refNumberChanged'
import resetRowHasError from 'redux/slices/conditions/actions/shared/resetRowHasError'
import rowHasError from 'redux/slices/conditions/actions/shared/rowHasError'
import statusChanged from 'redux/slices/conditions/actions/shared/statusChanged'

const initialState = {
  errorRows: {},
  rowsHaveAnError: false,
  highlightRows: {},
  selectedRows: {
    selectedRowIds: {},
    selectedRequirementIds: {},
  },
  editedRow: {},
  isRequirementAddMode: false,
  commentDialog: {
    isOpen: false,
  },
  reminderDialog: {
    isOpen: false,
  },
}

export const requirementsTableSlice = createSlice({
  name: 'requirements-table',
  initialState,
  reducers: {
    requirementNameChanged: nameChangedImport,
    requirementNameOnBlur: nameOnBlurImport,
    requirementRefNumberChanged: refNumberChangedImport,
    requirementRowHasError: rowHasError,
    resetRequirementRowHasError: resetRowHasError,
    highlightRequirementRow: highlightRow,
    startRequirementEditMode: startRequirementEditModeImport,
    setSelectedRequirementRows: setSelectedRequirementRowsImport,
    requirementDescriptionChanged: descriptionChanged,
    requirementAssigneeChanged: assigneeChanged,
    requirementExternalAssigneeChanged: externalAssigneeChanged,
    requirementConditionChanged: requirementConditionChangedImport,
    requirementDocumentTypeChanged: requirementDocumentTypeChangedImport,
    requirementDueDateChanged: requirementDueDateChangedImport,
    requirementStatusChanged: statusChanged,
    startRequirementAddMode: startRequirementAddModeImport,
    stopRequirementAddMode: stopRequirementAddModeImport,
    closeRequirementCommentDialog: closeRequirementCommentDialogImport,
    openRequirementCommentDialog: openRequirementCommentDialogImport,
    closeRequirementReminderDialog: closeRequirementReminderDialogImport,
    openRequirementReminderDialog: openRequirementReminderDialogImport,
    stopRequirementEditMode: (state) => {
      state.editedRow = {}
    },
    reset: () => initialState,
  },
})

export const {
  requirementNameChanged,
  requirementNameOnBlur,
  requirementRefNumberChanged,
  requirementRowHasError,
  resetRequirementRowHasError,
  reset,
  highlightRequirementRow,
  startRequirementEditMode,
  stopRequirementEditMode,
  setSelectedRequirementRows,
  requirementDescriptionChanged,
  requirementAssigneeChanged,
  requirementExternalAssigneeChanged,
  requirementConditionChanged,
  requirementDocumentTypeChanged,
  requirementDueDateChanged,
  requirementStatusChanged,
  startRequirementAddMode,
  stopRequirementAddMode,
  closeRequirementCommentDialog,
  openRequirementCommentDialog,
  closeRequirementReminderDialog,
  openRequirementReminderDialog,
} = requirementsTableSlice.actions

export default requirementsTableSlice.reducer
