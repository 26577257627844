import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import BusinessPartnerRolesPopover from 'components/domains/business-partners/roles/BusinessPartnerRolesPopover'

const getSingleBackEndRoleName = (roles) => roles[0]?.backEndRoles[0]?.name ?? ''
const getBackendRolesLengthOrZero = (role) => role.backEndRoles?.length ?? 0
const getTotalAmountOfRoles = (roles) =>
  roles.reduce((total, role) => total + getBackendRolesLengthOrZero(role), 0)

const BusinessPartnerRolesView = ({ roles }) => {
  const amountOfRoles = getTotalAmountOfRoles(roles)

  if (amountOfRoles === 0) {
    return ''
  } else if (amountOfRoles === 1) {
    return <Text>{getSingleBackEndRoleName(roles)}</Text>
  } else {
    return <BusinessPartnerRolesPopover roles={roles} amountOfRoles={amountOfRoles} />
  }
}

BusinessPartnerRolesView.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      backEndRoles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
}

export default BusinessPartnerRolesView
