import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useFinanceReportsbyBpId = (bpId) =>
  useCamelizedResponse(
    useRequest({
      path: `/businesspartners/${bpId}/finance-reports`,
      options: { enabled: !!bpId },
    }),
  )

export default useFinanceReportsbyBpId
