import { DatePicker, Label } from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

/**
 *  @typedef {PropType.InferProps<typeof ValuationsRequestsTableDateCell.propTypes>} ValuationsRequestsTableDateCell
 */
const ValuationsRequestsTableDateCell = ({ isEditing, date, onChange, maxDate }) => {
  const { format, localePattern, parse } = useShortDateFormatter()
  const handleOnDateChange = useCallback(
    ({ target: { liveValue: valuationDate } }) => {
      const isoValuationDate = parse(valuationDate, localePattern)
      onChange(isoValuationDate)
    },
    [localePattern, onChange, parse],
  )
  const formattedDate = useMemo(() => (date ? format(date) : '-'), [date, format])

  return isEditing ? (
    <DatePicker
      id={'edit-valuation-date-day-picker'}
      onChange={handleOnDateChange}
      formatPattern={localePattern}
      value={date}
      max-date={maxDate}
    />
  ) : (
    <Label>{formattedDate}</Label>
  )
}

ValuationsRequestsTableDateCell.propTypes = {
  date: PropType.oneOfType([PropType.instanceOf(Date), PropType.string]),
  isEditing: PropType.bool.isRequired,
  onChange: PropType.func.isRequired,
  maxDate: PropType.string,
}

export default ValuationsRequestsTableDateCell
