import { Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSelect from 'components/ui/select/LoadingSelect'

const VotersTableTypeCell = ({
  voteTypeName,
  voteTypeCode,
  isEditMode,
  onTypeChange,
  showEmptyOption = false,
  eventId,
  decisionStageId,
  loadingHook,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.voters-table.cells',
  })

  // This is a hack that the select was not able to render the valueState after the valueState was deleted
  // and came back again. Therefore I am setting a key which get increased when the documentType changes. With
  // this the react component is considered new which leads to the error not occur.
  const [internalKey, setInternalKey] = useState(0)
  const [selectedVoteTypeCode, setSelectedVoteTypeCode] = useState(voteTypeCode)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setInternalKey((key) => key + 1)
  }, [hasError])

  const onChangeInternal = useCallback(
    (_event, newCode) => {
      setHasError(!newCode)
      setSelectedVoteTypeCode(newCode)
      onTypeChange(newCode)
    },
    [onTypeChange],
  )

  const onBlur = useCallback(() => {
    setHasError(!selectedVoteTypeCode)
    setInternalKey((key) => key + 1)
  }, [selectedVoteTypeCode])

  if (isEditMode) {
    return (
      <LoadingSelect
        key={`approver-type-select-${internalKey}`}
        showEmptyOption={showEmptyOption}
        emptyOptionText={t('select-type')}
        selectedKey={selectedVoteTypeCode}
        loadingHook={loadingHook}
        loadingHookParams={{ eventId, decisionStageId }}
        onChange={onChangeInternal}
        optionKeyName="code"
        optionDisplayName="name"
        selectionName="voteTypes"
        onBlur={onBlur}
        valueState={hasError ? ValueState.Error : ValueState.None}
        valueStateMessage={<Text wrapping>{t('select-type.value-state.error')}</Text>}
      />
    )
  }

  return <Text>{voteTypeName}</Text>
}

VotersTableTypeCell.propTypes = {
  voteTypeCode: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  showEmptyOption: PropTypes.bool,
  onTypeChange: PropTypes.func.isRequired,
  voteTypeName: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  decisionStageId: PropTypes.string.isRequired,
  loadingHook: PropTypes.func.isRequired,
}

export default VotersTableTypeCell
