import { useQuery } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * Returns all tegova ratings for properties
 * The Tegova Rating structure is as follows:
 *
 * @example
 * {
 *   "property_uuid": "1555ffe2-ef9d-0000-add1-e7826700003d",
 *   "tegova_rating_uuid": "1555ffe2-ef9d-0000-add1-e7826700003c",
 *   "valid_from_date": "2026-01-01",
 *   "key_date": "2026-04-04",
 *   "market": {
 *       "market": 3
 *       "market_national": {
 *          "market_national": 5
 *          "market_national_acts_of_god": 10
 *          "market_national_overall_economic_development": 9
 *          "market_national_pltmcondition": 2
 *          "market_national_property_market": 3
 *          "market_national_socio_demo_graphic_development": 9
 *        }
 *       "market_regional": {
 *          "market_regional": 5
 *          "market_regional_acts_of_god": 10
 *          "market_regional_economic_situation_and_attractiveness": 9
 *          "market_regional_property_market": 3
 *          "market_regional_socio_demo_graphic_development": 9
 *        }
 *   },
 *   "location": {
 *       "location": 6
 *       "location_acts_of_god": 6
 *       "location_image_quarter": 6
 *       "location_property_type": 6
 *       "quality_of_local_supply_facilities": 6
 *       "quality_of_transportation": 6
 *   },
 *   "property": {
 *       "property": 3
 *        "architecture": 3
 *       "ecological_sustainability": 3
 *       "fitout": 3
 *       "plot_situation": 3
 *       "profitability_of_building_concept": 3
 *       "structural_condition": 3
 *   },
 *   "cashflow": {
 *       "cashflow": 2
 *       "letting_prospects": 2
 *       "recoverable_non_recoverable_expenses": 2
 *       "rental_growth_potential": 2
 *       "tenant_situation": 2
 *       "usability_by_third_parties": 2
 *       "vacancy_situation": 2
 *   },
 *   "total": 1,
 *   "development_risk_and_chances": {
 *       "development_risk_and_chances": 4
 *       "construction_and_costs": 4
 *       "letting_and_selling": 4
 *       "planning_and_permits": 4
 *   },
 *   "sales_prospects": 10
 * }
 *@returns {{isLoading: boolean, isError: boolean, data: tegovaRatings}}
 */

export const useTegovaRating = (propertyUuids, queryOptions = {}) => {
  const { post } = useAccessTokenRequest()
  return useCamelizedResponse(
    useQuery({
      queryKey: ['properties', propertyUuids, 'tegova-rating'],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        const { data } = await post({
          path: '/properties/tegova-rating',
          body: {
            property_uuids: propertyUuids,
          },
        })
        return data
      },
      ...queryOptions,
    }),
  )
}
