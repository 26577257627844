import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultipleCovenantCommentsByCovenantUuid = ({
  covenantUuids,
  linkedEntityType,
  options = {},
}) => {
  const queryParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams()

    urlSearchParams.append('linked_entity_type', linkedEntityType)

    return urlSearchParams
  }, [linkedEntityType])

  // HINT: `queryParams.size` seems to return `undefined` even if there are params
  const additionalParamsString = [...queryParams.keys()].length ? `&${queryParams.toString()}` : ''

  const responses = useRequestsMemo(
    useRequests({
      requests: covenantUuids.map((covenantUuid) => ({
        path: `/covenants/comments?linked_entity_id=${covenantUuid}${additionalParamsString}`,
        keys: ['covenants', 'comments', linkedEntityType, covenantUuid],
      })),
      translated: true,
      useCache: true,
      options: { enabled: !isEmpty(covenantUuids), ...options },
    }),
  )

  return useMemo(
    () =>
      responses.map((response, index) => ({
        ...response,
        data: {
          covenantUuid: covenantUuids[index],
          ...camelize(response.data),
        },
      })),
    [covenantUuids, responses],
  )
}

export default useMultipleCovenantCommentsByCovenantUuid
