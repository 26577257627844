import { MessageStrip, MessageStripDesign, Link, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/monitoring/PropertyMonitoringMessage.module.css'
import paths from 'routes/paths'

const PropertyMonitoringProxyMessage = ({ pageData }) => {
  const { market: assignedMarket } = pageData
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property-monitoring' })

  return (
    <MessageStrip design={MessageStripDesign.Information} className={styles.proxyMessageStrip}>
      <Text>
        {t('proxies.message')}{' '}
        <Link
          target="_blank"
          href={`/${paths.markets}/${assignedMarket.id}#market-overview-manager`}
        >
          {t('proxies.link')}
        </Link>
      </Text>
    </MessageStrip>
  )
}

PropertyMonitoringProxyMessage.propTypes = {
  pageData: PropTypes.shape({
    market: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default PropertyMonitoringProxyMessage
