import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useStatusTranslations = () => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'components.deals.covenants.monitoring-items-card',
  })
  /**
   * @enum
   */
  const statusEnum = useMemo(
    () => ({
      ACTIVE: t('status.active'),
      PLANNED: t('status.planned'),
      POSTPONED: t('status.postponed'),
      CLOSED: t('status.closed'),
    }),
    [t],
  )
  const getTranslationForStatus = (status) => statusEnum[status] ?? ''

  return { getTranslationForStatus, statusEnum }
}

export default useStatusTranslations
