/**
 * DISCLAIMER:
 * This component functions effectively under the constraint that the CWP
 * does not decide to discard the current handling of only 2 decimal places.
 * Should there be a change at some point and the CWP only displays 1 or 3 decimal places, for example, this
 * component will inevitably break, as new edge cases will be introduced.
 * The same can apply when adding new languages that have different rules for decimal and thousands separators.
 * Furthermore, number entries such as exponential notation are not supported.
 */

/**
 * Removes numeric characters from the input value, leaving only non-numeric symbols intact.
 * For example, given the input value '1,000.00', the function will return ',.'.
 * The Regex pattern [^0-9] matches any character that is not a digit.
 */
const extractNonNumericSymbols = (inputString) => {
  const nonNumericRegex = /[^0-9]/g
  const nonNumericSymbols = inputString.replace(nonNumericRegex, '')
  return inputString.replace(new RegExp('[' + nonNumericSymbols + ']', 'g'), '')
}

/**
 * Marks the placement of a new decimal. Since the CWP assumes that there are only 2 decimal places, this logic can be used without further ado.
 * If we encounter '1,000', for example, it's interpreted as a thousand since it has more than 2 numbers after a separator. We can remove the comma set the number to 1000.
 * However, if we have '10,00' for example, we can assume that the comma serves as a decimal separator, indicating a value of 10.00.
 */
const getLengthAfterDecimalOrThousandSeparator = ({ inputValue, separators }) => {
  if (inputValue.includes(separators?.decimalSeparator)) {
    return inputValue.split(separators?.decimalSeparator)[1].length
  }

  if (inputValue.includes(separators?.thousandsSeparator)) {
    return inputValue.split(separators?.thousandsSeparator)[1].length
  }

  return 0
}

/**
 * This regular expression /[^0-9-]/g is a pattern that matches any character that is not a digit (0-9) or a hyphen (-).
 * It's used to remove all non-numeric symbols from the input value.
 */
const nonNumericHyphenRegex = /[^0-9-]/g
const removeNonNumericSymbols = (inputValue) => inputValue.replace(nonNumericHyphenRegex, '')

/**
 * This function is used to place the separators in the correct place.
 * Since this function is only being called when the decimal separator is already set in the correct place or if there are no separators at all, we know where to split the number and where to place the decimal separator.
 */
const placeSeparators = ({ inputValue, separators, numberFormatter, parseNumber }) => {
  const inputValueTillDecimal = inputValue.split(separators?.decimalSeparator)[0]
  const inputValueAfterDecimal = inputValue.split(separators?.decimalSeparator)[1]

  const formattedValueTillDecimal = numberFormatter?.format(
    parseFloat(parseNumber(inputValueTillDecimal)),
  )

  if (inputValueAfterDecimal) {
    return `${formattedValueTillDecimal}${separators?.decimalSeparator}${inputValueAfterDecimal}`
  }

  return formattedValueTillDecimal
}

export const validateNumericFormat = ({ inputValue, separators, numberFormatter, parseNumber }) => {
  const setSeparators = (value) =>
    placeSeparators({ inputValue: value, separators, numberFormatter, parseNumber })

  const nonNumericSymbols = extractNonNumericSymbols(inputValue)
  const firstSymbol = nonNumericSymbols[0] === '-' ? nonNumericSymbols[1] : nonNumericSymbols[0]

  /**
   * If there is no symbol, we can assume that a number was pasted and just place the separators.
   * We can assume that it's a number since we checked for a valid input before calling any function in this file.
   */
  if (!firstSymbol) {
    return placeSeparators({ inputValue, separators, numberFormatter, parseNumber })
  }

  /*
   * When the inputValue contains only one non-numeric symbol, we determine whether it's a decimal or a thousand separator.
   * If it's a decimal separator, we verify if there are more than two digits after it.
   * Since the CWP assumes that there are only 2 decimal places, we can remove the decimal separator and set the number to the correct value.
   * If it's a thousand separator, we replace it with a decimal separator.
   */
  if (
    nonNumericSymbols.length === 1 ||
    (nonNumericSymbols.length === 2 && nonNumericSymbols[0] === '-')
  ) {
    if (getLengthAfterDecimalOrThousandSeparator({ inputValue, separators }) > 2) {
      return setSeparators(removeNonNumericSymbols(inputValue))
    } else {
      const decimalSeparator = separators?.decimalSeparator || '.'
      const periodOrCommaRegex = /[.,]/g
      return setSeparators(inputValue.replace(periodOrCommaRegex, decimalSeparator))
    }
  }

  /*
   * When the first symbol matches the last one, we conclude that there's no decimal separator present.
   * In this scenario, we replace the thousand separator with a decimal separator, and vice versa.
   * This works because we checked for possible other scenarios before reaching this point.
   */
  if (firstSymbol === nonNumericSymbols[nonNumericSymbols.length - 1]) {
    if (firstSymbol === separators?.thousandsSeparator) {
      return inputValue.replace(
        new RegExp(`\\${separators?.thousandsSeparator}`, 'g'),
        separators?.decimalSeparator,
      )
    } else if (firstSymbol === separators?.decimalSeparator) {
      return inputValue.replace(
        new RegExp(`\\${separators?.decimalSeparator}`, 'g'),
        separators?.thousandsSeparator,
      )
    }
  } else {
    /*
     * When the first symbol differs from the last one, it indicates the presence of a decimal separator.
     * If the first symbol is a thousand separator, the decimal separator is already set in the correct place and we can just set the thousand separators.
     * If the first symbol is a decimal separator, we remove all decimal separators and are left with the thousand separator which is the correct decimal separator. Afterwards we set the correct thousand separator.
     */
    if (firstSymbol === separators?.thousandsSeparator) {
      return setSeparators(inputValue)
    } else if (firstSymbol === separators?.decimalSeparator) {
      const temp = inputValue.replace(new RegExp(`\\${separators?.decimalSeparator}`, 'g'), '')
      return setSeparators(
        temp.replace(
          new RegExp(`\\${separators?.thousandsSeparator}`, 'g'),
          separators?.decimalSeparator,
        ),
      )
    }
  }

  return inputValue
}
