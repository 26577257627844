const sortColumnsByValues = (value1, value2) => {
  if (value1 > value2) return 1
  else if (value1 < value2) return -1
  else return 0
}
/**
 *  Sorting functions for the rent roll working version table.
 * There are multiple value types, which can be sorted:
 * - strings --> sortString
 * - stringified numbers --> sortNumber
 * - stringified dates --> sortDate
 */
export const sortString = (row1, row2, columnId) => {
  const row1Value = row1.original[columnId].value
  const row2Value = row2.original[columnId].value
  return sortColumnsByValues(row1Value, row2Value)
}
export const sortNumber = (row1, row2, columnId) => {
  const row1Value = parseFloat(row1.original[columnId].value)
  const row2Value = parseFloat(row2.original[columnId].value)
  return sortColumnsByValues(row1Value, row2Value)
}
export const sortDate = (row1, row2, columnId) => {
  const row1Value = new Date(row1.original[columnId].value).getTime()
  const row2Value = new Date(row2.original[columnId].value).getTime()
  return sortColumnsByValues(row1Value, row2Value)
}
/**
 * This function accesses the display-name of a selected option inside a select component
 * and sorts by that value.
 */
export const sortSelectOptionsByDisplayValue = (row1, row2, columnId) => {
  const row1Key = row1.original[columnId].value
  const row2Key = row2.original[columnId].value
  const row1Value =
    row1.original[columnId].cellComponent.props.options.find((option) => option.key === row1Key)
      ?.display_name ?? ''
  const row2Value =
    row2.original[columnId].cellComponent.props.options.find((option) => option.key === row2Key)
      ?.display_name ?? ''
  return sortColumnsByValues(row1Value, row2Value)
}
