import { useCallback, useContext, useMemo } from 'react'
import { businessEventsAllowedOperations } from 'api/events/eventAllowedOperations'
import { entityTypes } from 'api/events/status'
import CreateEventAction from 'components/domains/business-events-and-tasks/events/dialog/CreateEventAction'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import CreateWorkingVersionDealEventDialog from 'components/domains/deals/header-actions/working-version-event-create/CreateWorkingVersionDealEventDialog'
import useEventsAllowedOperations from 'hooks/services/business-events-and-tasks/events/useEventsAllowedOperations'
import { DealContext } from 'routes/deals/DealContext'

/**
 * Secondary button to create Deal events from the header of Deal pages.
 * @returns {JSX.Element | false} Create Deal Event Action or false
 */
const CreateDealEventAction = () => {
  const {
    deal: { dealUuid, displayId, name, workingVersion },
  } = useContext(DealContext)
  const { data: allowedEventsOperationsData } = useEventsAllowedOperations()
  const isAllowedToCreateEvent = !!allowedEventsOperationsData?.allowed_operations.includes(
    businessEventsAllowedOperations.createEvent,
  )

  const isWorkingVersion = useMemo(
    () => workingVersion === WorkingVersionType.WORKING_VERSION,
    [workingVersion],
  )

  const renderWorkingVersionDialog = useCallback(
    ({ isOpen, setIsOpen, preselectedEntity }) =>
      isOpen && (
        <CreateWorkingVersionDealEventDialog
          setIsOpen={setIsOpen}
          preselectedEntity={preselectedEntity}
        />
      ),
    [],
  )

  return useMemo(
    () =>
      isAllowedToCreateEvent &&
      !!dealUuid && (
        <CreateEventAction
          entity={{
            entityType: entityTypes.deal,
            entityDisplayId: displayId,
            entityName: name,
            entityId: dealUuid,
            isWorkingVersion,
          }}
          renderCustomDialog={isWorkingVersion ? renderWorkingVersionDialog : undefined}
        />
      ),
    [
      isAllowedToCreateEvent,
      dealUuid,
      displayId,
      name,
      isWorkingVersion,
      renderWorkingVersionDialog,
    ],
  )
}

export default CreateDealEventAction
