import isNil from 'lodash.isnil'
import {
  ROW_TYPE_PRODUCT_CLASS,
  TABLE_ROW_DEFAULT_HEIGHT,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/constants'

const mapProductClasses = (t, productClasses, rootRowId, unitMemberBpId, headquarterCurrency) => {
  if (!productClasses) {
    return []
  }

  const existingPipelineColumns = (importedExistingValues, importedPipelineValues) => ({
    source: [
      t('pages.business-partner.authority-level-calculator.table.columns.source.existing'),
      t('pages.business-partner.authority-level-calculator.table.columns.source.pipeline'),
    ],
    amount: [
      !isNil(importedExistingValues?.amount?.amount)
        ? {
            amount: importedExistingValues?.amount?.amount,
            currency: importedExistingValues?.amount?.currency,
          }
        : undefined,
      !isNil(importedPipelineValues?.amount?.amount)
        ? {
            amount: importedPipelineValues?.amount?.amount,
            currency: importedPipelineValues?.amount?.currency,
          }
        : undefined,
    ],
    pdRegulatory: [importedExistingValues?.pdRegulatory, importedPipelineValues?.pdRegulatory],
    pdRegulatoryClass: [
      importedExistingValues?.pdRegulatoryClass,
      importedPipelineValues?.pdRegulatoryClass,
    ],
    lgdRegulatory: [importedExistingValues?.lgdRegulatory, importedPipelineValues?.lgdRegulatory],
  })

  const adjustedColumns = (adjustedValues) => ({
    adjustedAmount: {
      amount: adjustedValues?.adjustedAmount?.amount,
      currency: adjustedValues?.adjustedAmount?.currency ?? headquarterCurrency,
    },
    adjustedAmountInAlcCurrency: {
      amount: adjustedValues?.adjustedAmountInAlcCurrency?.amount,
      currency: adjustedValues?.adjustedAmountInAlcCurrency?.currency,
    },
    adjustedPdRegulatory: adjustedValues?.adjustedPdRegulatory,
    adjustedLgdRegulatory: adjustedValues?.adjustedLgdRegulatory,
  })

  const calculatedColumns = (calculatedValues) => ({
    pdCalculation: calculatedValues?.pdCalculation,
    pdRegulatoryClassCalculation: calculatedValues?.pdCalculationClass,
    lgdCalculation: calculatedValues?.lgdCalculation,
    elUnweighted: !isNil(calculatedValues?.elUnweighted?.amount)
      ? {
          amount: calculatedValues?.elUnweighted?.amount,
          currency: calculatedValues?.elUnweighted?.currency,
        }
      : undefined,
    elWeighted: !isNil(calculatedValues?.elWeighted?.amount)
      ? {
          amount: calculatedValues?.elWeighted?.amount,
          currency: calculatedValues?.elWeighted?.currency,
        }
      : undefined,
    elBps: calculatedValues?.elRate,
    creditAuthorityLimit: !isNil(calculatedValues?.creditAuthorityLimit?.amount)
      ? {
          amount: calculatedValues?.creditAuthorityLimit?.amount,
          currency: calculatedValues?.creditAuthorityLimit?.currency,
        }
      : undefined,
    elClass: calculatedValues?.elClass,
  })

  return productClasses.map((productClass) => ({
    rowHeight: TABLE_ROW_DEFAULT_HEIGHT,
    rowType: ROW_TYPE_PRODUCT_CLASS,
    rootRowId: rootRowId,
    unitMemberBpId,
    productClassId: productClass?.productClassId,
    guarantorBpId: productClass?.guarantorBpId,
    isInEditMode: false,
    isManuallyAdded: productClass?.isManuallyAdded,

    businessPartnerProductClass: {
      name: productClass?.productClassName,
      id: productClass?.productClassId,
    },
    guarantor: { name: productClass?.guarantorBpFullName, id: productClass?.guarantorBpId },
    ...existingPipelineColumns(
      productClass?.importedExistingValues,
      productClass?.importedPipelineValues,
    ),
    ...adjustedColumns(productClass?.adjustedValues),
    ...calculatedColumns(productClass?.calculatedValues),
  }))
}

export default mapProductClasses
