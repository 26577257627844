import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useHeadquarterCurrency = () =>
  useCamelizedResponse(
    useRequest({
      path: '/financing-structures/configurations/category/HEADQUARTER_CURRENCY', // pragma: allowlist secret
      translated: true,
      useCache: true,
      keys: ['financing-structures', 'configuration', 'headquarter-currency'],
    }),
  )

export default useHeadquarterCurrency
