import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const RETRIES = 3

export const useMarketMicroProxies = ({ marketId }) => {
  const params = new URLSearchParams()
  params.append('market_id', marketId)

  return useCamelizedResponse(
    useRequest({
      path: `/property-monitoring/proxies/micros?${params.toString()}`,
      useCache: true,
      keys: ['property-monitoring', 'proxies', 'micros', marketId],
      options: {
        retry: (failureCount, error) => {
          if (isNotFoundError(error) || isMissingPermissionError(error)) {
            return false
          }
          return failureCount < RETRIES
        },
      },
    }),
  )
}
