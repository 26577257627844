import { FlexBox, FlexBoxAlignItems, Grid, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/ui/card/EditCardItem.module.css'

/**
 * Component to render a label and an edit component inside an EditCardView
 *
 * @param label
 * @param editComponent
 * @param isMandatory defaults to false
 */
const EditCardItem = ({ label, editComponent, isMandatory = false }) => (
  <Grid position="Left" defaultIndent="XL0 L0 M0 S0" defaultSpan="XL6 L6 M6 S6">
    <FlexBox className={styles['editModeLabelWrapper']} alignItems={FlexBoxAlignItems.Center}>
      {label ? <Label required={isMandatory}>{label}</Label> : <div />}
    </FlexBox>
    <FlexBox className={styles['editModeInputWrapper']}>{editComponent}</FlexBox>
  </Grid>
)

EditCardItem.propTypes = {
  label: PropTypes.string,
  editComponent: PropTypes.element.isRequired,
  isMandatory: PropTypes.bool,
}

export default EditCardItem
