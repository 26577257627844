import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import SmallLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/loading/SmallLoadingWrapper'
import useStaffMemberByObjectIdOrEmail from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/staff-member/useStaffMemberByObjectIdOrEmail'

/**
 * Shows the full name of a user or the display name of a service principal identified by an object id.
 * A small loading indicator is displayed, while the object id is being resolved.
 * The original object id is displayed instead of the full name, if there is an error during resolution.
 *
 * Note: The object id can also be an email address. See useStaffMemberByObjectIdOrEmail for details.
 */
const StaffMemberByObjectIdOrEmail = ({ objectIdOrEmail }) => {
  const { t } = useTranslation('decisionPaper', { keyPrefix: 'tiles.kpi-chart-tile' })
  const {
    data: staffMember,
    isFetching: isStaffMemberFetching,
    isError: isStaffMemberError,
  } = useStaffMemberByObjectIdOrEmail({ objectIdOrEmail })

  const renderStaffMember = useCallback(
    () => staffMember?.fullName ?? `${objectIdOrEmail} (${t('components.ui.user.unknown-user')})`,
    [staffMember?.fullName, objectIdOrEmail, t],
  )

  return (
    <SmallLoadingWrapper
      isLoading={isStaffMemberFetching}
      isError={isStaffMemberError}
      error={`${objectIdOrEmail} (${t('components.ui.user.unknown-user')})`}
      renderContent={renderStaffMember}
    />
  )
}

StaffMemberByObjectIdOrEmail.propTypes = {
  objectIdOrEmail: PropTypes.string.isRequired,
}

export default StaffMemberByObjectIdOrEmail
