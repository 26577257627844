import { max } from 'lodash'
import isEmpty from 'lodash.isempty'
import omit from 'lodash.omit'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import paths from 'routes/paths'

/**
 * @enum
 */
const RowHeight = {
  Single: 40,
  Double: 60,
  Triple: 80,
}

/**
 * @enum
 */
const MandatorySyndicationTypes = {
  Ratio: '1',
  Amount: '2',
  None: '3',
}

const currenciesAreEqual = (amount = {}) =>
  !amount.secondaryAmount?.currency ||
  amount.primaryAmount?.currency === amount.secondaryAmount.currency

const calculateRowHeight = (amounts, financialProductLines, mandatorySyndication = {}) => {
  const amountShareLines = (currenciesAreEqual(amounts.amount) ? 1 : 2) + !!amounts.amountShare
  const outstandingShareLines =
    (currenciesAreEqual(amounts.outstandingAmount) ? 1 : 2) + !!amounts.outstandingAmountShare
  // every key of mandatory syndication is displayed + one extra row when the currencies are not equal
  const mandatorySyndicationLines =
    (currenciesAreEqual(mandatorySyndication?.syndicationAmount) ? 0 : 1) +
      Object.keys(mandatorySyndication)?.length || 0

  switch (
    max([amountShareLines, outstandingShareLines, mandatorySyndicationLines, financialProductLines])
  ) {
    case 1:
      return RowHeight.Single
    case 2:
      return RowHeight.Double
    default:
      return RowHeight.Triple
  }
}

const useMultiExistingBusinessSyndicationStructureTableData = ({
  syndicationData,
  allBusinessPartnersData,
  dealDisplayId,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.existing-business-syndication-structure.table',
  })

  // When the currencies on deal level are not equal, every subrow will have that original currency too
  // --> The display of the headquarter currency is not needed
  const onlyUseOriginalCurrency = useMemo(
    () =>
      syndicationData
        ? syndicationData.totalShare?.amount?.amountOriginalCurrency?.currency !==
          syndicationData.totalShare?.amount?.amountHeadQuarterCurrency?.currency
        : false,
    [syndicationData],
  )

  const mapAmounts = useCallback(
    (share = {}) =>
      onlyUseOriginalCurrency
        ? {
            ...share,
            amount: { primaryAmount: share?.amount?.amountOriginalCurrency },
            outstandingAmount: { primaryAmount: share?.outstandingAmount?.amountOriginalCurrency },
          }
        : {
            ...share,
            amount: {
              primaryAmount: share?.amount?.amountHeadQuarterCurrency,
              secondaryAmount: share?.amount?.amountOriginalCurrency,
            },
            outstandingAmount: {
              primaryAmount: share?.outstandingAmount?.amountHeadQuarterCurrency,
              secondaryAmount: share?.outstandingAmount?.amountOriginalCurrency,
            },
          },
    [onlyUseOriginalCurrency],
  )

  const mapSyndicationAmount = useCallback(
    (syndicationAmount = {}) =>
      onlyUseOriginalCurrency
        ? {
            primaryAmount: syndicationAmount?.amountOriginalCurrency,
          }
        : {
            primaryAmount: syndicationAmount?.amountHeadQuarterCurrency,
            secondaryAmount: syndicationAmount?.amountOriginalCurrency,
          },
    [onlyUseOriginalCurrency],
  )

  const mapMandatorySyndication = useCallback(
    (mandatorySyndication = {}) => {
      if (
        !mandatorySyndication?.syndicationType ||
        mandatorySyndication.syndicationType === MandatorySyndicationTypes.None
      ) {
        return
      }
      switch (mandatorySyndication.syndicationType) {
        case MandatorySyndicationTypes.Ratio:
          return {
            quota: {
              value: mandatorySyndication?.syndicationQuota,
              unit: mandatorySyndication?.syndicationQuotaUnit,
            },
            syndicateUntil: mandatorySyndication?.endDate,
          }
        case MandatorySyndicationTypes.Amount:
          return {
            syndicationAmount: mapSyndicationAmount(mandatorySyndication?.syndicationAmount),
            syndicateUntil: mandatorySyndication?.endDate,
          }
      }
      return
    },
    [mapSyndicationAmount],
  )

  const mapSubRows = useCallback(
    (data = {}) => {
      const amounts = mapAmounts(data?.ownShare)
      const ownShareRow = {
        ...amounts,
        rowHeight: calculateRowHeight(amounts, 1),
        isTotalOrOwnShare: true,
        title: t('own-share-row.title'),
      }
      const syndications =
        data?.syndications?.map((syndication) => ({
          rank: syndication?.rank,
          role: syndication?.role,
          businessPartner: {
            businessPartnerId: syndication?.bpId,
            businessPartnerName: allBusinessPartnersData?.find(
              (businessPartner) => businessPartner?.id === syndication?.bpId,
            )?.fullName,
          },
          isBusinessPartner: true,
          ...mapAmounts(syndication?.syndicationAmount),
          rowHeight: calculateRowHeight(mapAmounts(syndication?.syndicationAmount), 2),
        })) ?? []
      return [ownShareRow, ...syndications]
    },
    [allBusinessPartnersData, mapAmounts, t],
  )

  const mapDrawingData = useCallback(
    (drawingData = []) =>
      drawingData.map((data) => {
        const subRows = [...mapSubRows(data)]
        const amounts = mapAmounts(data?.totalShare)
        const mandatorySyndication = mapMandatorySyndication(data?.mandatorySyndication)
        return {
          ...amounts,
          mandatorySyndication,
          isTrancheOrDrawing: true,
          title: t('drawing-row.title'),
          href: `/${paths.deals}/${dealDisplayId}/financing/drawdowns/${data.id}/details?dataSource=existingBusiness`,
          linkTitle: data.id,
          rowHeight: calculateRowHeight(amounts, 1, mandatorySyndication),
          subRows,
        }
      }),
    [mapSubRows, mapAmounts, mapMandatorySyndication, t, dealDisplayId],
  )

  const mapTrancheData = useCallback(
    (trancheData = []) =>
      trancheData.map((data) => {
        const subRows = [...mapSubRows(data), ...mapDrawingData(data?.drawDowns)]
        const amounts = mapAmounts(data?.totalShare)
        const mandatorySyndication = mapMandatorySyndication(data?.mandatorySyndication)
        return {
          ...amounts,
          mandatorySyndication,
          isTrancheOrDrawing: true,
          title: t('tranche-row.title'),
          href: `/${paths.deals}/${dealDisplayId}/financing/tranches/${data.id}/details?dataSource=existingBusiness`,
          linkTitle: data.id,
          rowHeight: calculateRowHeight(amounts, 1, mandatorySyndication),
          subRows,
        }
      }),
    [mapSubRows, mapDrawingData, mapAmounts, mapMandatorySyndication, t, dealDisplayId],
  )

  const mapDealData = useCallback(
    (dealData = {}) => {
      const subRows = [...mapSubRows(dealData), ...mapTrancheData(dealData?.tranches)]
      const amounts = mapAmounts(dealData?.totalShare)
      const mandatorySyndication = mapMandatorySyndication(dealData?.mandatorySyndication)
      return [
        {
          ...amounts,
          mandatorySyndication,
          isTotalOrOwnShare: true,
          title: t('total-row.title'),
          rowHeight: calculateRowHeight(amounts, 1, mandatorySyndication),
          subRows,
        },
      ]
    },
    [mapAmounts, mapMandatorySyndication, mapSubRows, mapTrancheData, t],
  )

  return useMemo(
    () => (isEmpty(omit(syndicationData, 'dealUuid')) ? [] : mapDealData(syndicationData)),
    [mapDealData, syndicationData],
  )
}
export default useMultiExistingBusinessSyndicationStructureTableData
