import { Label, Text } from '@fioneer/ui5-webcomponents-react'
import find from 'lodash.find'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from 'components/domains/markets/user-favorite/MarketsUserFavoritesAndRecentVisitsCard.module.css'
import useGetAndEnrichFavoritesAndRecentVisitsWithMarkets from 'components/domains/markets/user-favorite/useGetAndEnrichFavoritesAndRecentVisitsWithMarkets'
import UserFavoritesAndRecentVisitsCard from 'components/ui/user-favorite/UserFavoritesAndRecentsCard'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import paths from 'routes/paths'

const columnNames = {
  NAME: 'market-name',
  TYPE: 'market-type',
  DESCRIPTION: 'market-description',
  COUNTRY: 'market-country',
}

const renderHightlightedTextInfo = (info) => (
  <Label>
    <Text wrapping className={styles.marketName}>
      {info}
    </Text>
  </Label>
)
const renderTextInfo = (info) => <Text>{info}</Text>
const renderIfFound = (notFound, component) => (notFound ? null : component)

const renderMarketInfoIfFound = (info, notFound, highlighted = false) =>
  renderIfFound(notFound, highlighted ? renderHightlightedTextInfo(info) : renderTextInfo(info))

const mapDataToTableRow = ({ info, marketType, area, notFound, ...props }) => ({
  [columnNames.NAME]: renderMarketInfoIfFound(info?.name, notFound, true),
  [columnNames.TYPE]: renderMarketInfoIfFound(marketType?.value, notFound),
  [columnNames.DESCRIPTION]: renderMarketInfoIfFound(info?.description, notFound),
  [columnNames.COUNTRY]: renderMarketInfoIfFound(area?.country?.name, notFound),
  ...props,
})

const mapDataToTableRows = (data) => data?.map(mapDataToTableRow) ?? []

const MarketsUserFavoritesAndRecentVisitsCard = () => {
  const { isLoading, isError, data } = useGetAndEnrichFavoritesAndRecentVisitsWithMarkets()
  const navigate = useNavigate()

  const marketsUserFavoritesAndRecentVisitsData = useMemo(() => mapDataToTableRows(data), [data])
  const columnNameValues = useMemo(() => Object.values(columnNames), [])
  const handleOnHeaderClick = useCallback(() => navigate(`/${paths.markets}`), [navigate])
  const handleOnRowClick = useCallback(
    (marketId) => {
      const foundMarket = find(data, (item) => item.id === marketId)

      if (foundMarket) {
        navigate(`/${paths.markets}/${foundMarket.id}`)
      }
    },
    [data, navigate],
  )

  return (
    <UserFavoritesAndRecentVisitsCard
      entityType={cwpEntityTypes.MARKET}
      title="market-title"
      subtitle="market-subtitle"
      isLoading={isLoading}
      isError={isError}
      rowsData={marketsUserFavoritesAndRecentVisitsData}
      columnNames={columnNameValues}
      handleOnHeaderClick={handleOnHeaderClick}
      handleOnRowClick={handleOnRowClick}
    />
  )
}

export default MarketsUserFavoritesAndRecentVisitsCard
