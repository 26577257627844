import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const DecisionStageApprovalUnknownButton = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'decision-stage.vote-options.unknown',
  })

  return <Button design={ButtonDesign.Transparent}>{t('action')}</Button>
}

DecisionStageApprovalUnknownButton.propTypes = {
  eventId: PropTypes.string.isRequired,
  decisionStageId: PropTypes.string.isRequired,
  decisionStageVoterId: PropTypes.string.isRequired,
}

export default DecisionStageApprovalUnknownButton
