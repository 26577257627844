import uniq from 'lodash.uniq'

const brandingFranchiseRole = 'branding-franchise'

const getExternalBpsWithBrandingFranchiseRole = (rentalUnits) =>
  rentalUnits
    .flatMap((rentalUnit) => rentalUnit.externalBusinessPartners)
    .filter((bp) => bp.functionType === brandingFranchiseRole)
    .map((bp) => bp.id)

export const calculateTenancyBrandingFranchiseProvider = (rentalUnits) =>
  uniq(getExternalBpsWithBrandingFranchiseRole(rentalUnits))
