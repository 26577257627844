import { Icon } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/ui/tables/analytical/CollapseExpandAction.module.css'

export const collapseExpandActionType = {
  collapseAll: 'collapseAll',
  expandAll: 'expandAll',
}

const iconName = {
  collapseAll: 'collapse-all',
  expandAll: 'expand-all',
}

const className = {
  collapseAll: styles.collapseIcon,
  expandAll: styles.expandIcon,
}

const CollapseExpandAction = ({ type, onClick, disabled }) => (
  <Icon
    className={className[type]}
    name={iconName[type]}
    interactive
    onClick={onClick}
    disabled={disabled}
  />
)

CollapseExpandAction.propTypes = {
  type: PropTypes.oneOf(['collapseAll', 'expandAll']).isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default CollapseExpandAction
