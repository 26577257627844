import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import DecisionStageConditionsPage from 'components/domains/business-events-and-tasks/decision-stage/conditions/DecisionStageConditionsPage'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useDecisionStageConditions from 'hooks/services/business-events-and-tasks/decision-process/stages/conditions/useDecisionStageConditions'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionStageConditionsPageLoadingWrapper = ({ decisionStageId }) => {
  const {
    event: { id: eventId },
  } = useContext(BusinessEventsAndTasksContext)
  const {
    isError,
    isLoading,
    data: decisionStageConditions,
  } = useDecisionStageConditions({ eventId, decisionStageId })

  const renderContent = useCallback(
    () => (
      <DecisionStageConditionsPage
        decisionStageConditions={decisionStageConditions}
        decisionStageId={decisionStageId}
      />
    ),
    [decisionStageId, decisionStageConditions],
  )
  return (
    <LoadingStateWrapper isLoading={isLoading} isError={isError} renderContent={renderContent} />
  )
}

DecisionStageConditionsPageLoadingWrapper.propTypes = {
  decisionStageId: PropTypes.string.isRequired,
}

export default DecisionStageConditionsPageLoadingWrapper
