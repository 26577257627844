import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultipleStaffMemberByIds = ({ dealUserIds, options = {} }) => {
  const requests = dealUserIds.map((item) => {
    const params = new URLSearchParams()
    const idsString = Array.from(new Set(item?.userIds ?? [])).join(',')

    params.append('searchType', 'IDS')
    params.append('q', idsString)

    return {
      path: `/users?${params.toString()}`,
      keys: ['users', 'IDS', idsString, 'deal', item?.dealUuid],
      options: {
        enabled: !isEmpty(item?.userIds),
        ...options,
      },
    }
  })

  const responses = useRequestsMemo(
    useRequests({
      requests,
      translated: true,
      useCache: true,
    }),
  )

  return useMemo(
    () =>
      responses.map((result, index) => ({
        ...result,
        data: {
          ...dealUserIds[index],
          ...camelize(result.data),
        },
      })),
    [dealUserIds, responses],
  )
}

export default useMultipleStaffMemberByIds
