import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import { adjustmentTypesListConfigSchema } from 'hooks/services/deals/deal-adjustment/dealAdjustment.schema'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * @param requestOptions
 * @returns {{data: AdjustmentTypeListConfigDTO, isLoading: boolean, isError: boolean }}
 */
export const useAdjustmentTypesConfig = (requestOptions = {}) => {
  const response = useCamelizedResponse(
    useRequest({
      ...requestOptions,
      path: `/deals/configurations/adjustments/adjustment-types`,
      translated: true,
      useCache: true,
      keys: ['deals', 'configurations', 'adjustment-types'],
    }),
  )
  const data = useMemo(
    () => adjustmentTypesListConfigSchema.nullish().parse(response.data),
    [response.data],
  )
  return useMemo(() => ({ ...response, data }), [response, data])
}
