import cloneDeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { covenantPermissions } from 'api/deals/covenantPermissions'
import usePeriodicalMonitoringInformationData from 'components/domains/business-partners/periodical-checks/monitoring-information/usePeriodicalMonitoringInformationData'
import NextPeriodicalCheckDisplay from 'components/domains/business-partners/periodical-checks/next-periodical-check/NextPeriodicalCheckDisplay'
import NextPeriodicalCheckEdit from 'components/domains/business-partners/periodical-checks/next-periodical-check/NextPeriodicalCheckEdit'
import useEnrichDueDate from 'components/domains/business-partners/periodical-checks/next-periodical-check/useEnrichDueDate'
import useUpdateNextPeriodicalCheck from 'components/domains/business-partners/periodical-checks/next-periodical-check/useUpdateNextPeriodicalCheck'
import useValidateNextPeriodicalCheck from 'components/domains/business-partners/periodical-checks/next-periodical-check/useValidateNextPeriodicalCheck'
import { CardHeaderLastEditedText } from 'components/domains/deals/card/CardHeaderLastEditedText'
import Card from 'components/ui/card/Card'
import { ToggleEditMode } from 'components/ui/card/CardHeaderWithEditMode'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { CovenantContext } from 'routes/business-partners/CovenantContext'

const PLANNED_STATUS = 'PLANNED'

const NextPeriodicalCheckCard = ({ covenantUuid, covenantDefinition }) => {
  const { allowedOperations: covenantAllowedOperations = [] } = useContext(CovenantContext) ?? {}
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-partners.periodical-checks.monitoring-card',
  })
  const [isEditMode, setIsEditMode] = useState(false)
  const [formState, setFormState] = useState()
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [isFormStateInitialized, setIsFormStateInititalized] = useState(false)

  const { monitoringItem, monitoringInformation, isLoading, isError } =
    usePeriodicalMonitoringInformationData({
      covenantUuid,
      covenantDefinition,
    })
  const { enrichDueDate } = useEnrichDueDate()
  const isMonitoringItemEditable =
    !isEmpty(monitoringItem) && monitoringItem?.status === PLANNED_STATUS
  const isAllowedToUpdate = covenantAllowedOperations?.includes(
    covenantPermissions.updatePeriodicalCheckMonitoringInformation,
  )

  const updateMonitoringItem = useUpdateNextPeriodicalCheck({
    covenantUuid,
    covenantCheckUuid: monitoringItem?.linkedCovenantCheck?.covenantCheckUuid,
    onSuccess: () => {
      setIsEditMode(false)
      setIsFormStateInititalized(false)
    },
    setIsSaveLoading,
  })
  const onSave = () => {
    setIsSaveLoading(true)
    updateMonitoringItem.mutate({
      covenantUuid,
      covenantCheckUuid: monitoringItem?.linkedCovenantCheck?.covenantCheckUuid,
      covenantMonitoringItemUuid: monitoringItem?.covenantMonitoringItemUuid,
      dueDate: enrichDueDate({
        dueDate: formState?.dueDate,
        hasDocumentRequest: covenantDefinition?.withDocumentsRequest,
        monitoringInformation,
      }),
    })
  }

  useEffect(() => {
    if (!isLoading && !isError && !isSaveLoading && !isFormStateInitialized) {
      setFormState(cloneDeep(monitoringItem))
      setIsFormStateInititalized(true)
    }
  }, [isError, isFormStateInitialized, isLoading, isSaveLoading, monitoringItem])

  const isFormStateValid = useValidateNextPeriodicalCheck({
    monitoringItem: formState,
    covenantDefinition,
  })

  const checkSaveDisabled = () => isEqual(monitoringItem, formState) || !isFormStateValid

  const onCancelChanges = () => {
    setIsEditMode(false)
    setIsFormStateInititalized(false)
  }

  return (
    <Card
      header={
        <CardHeaderLastEditedText
          title={t('next-periodical-check')}
          email={monitoringItem?.lastUpdatedBy}
          timestamp={monitoringItem?.lastUpdatedAt}
          actions={
            !isLoading &&
            !isError &&
            isAllowedToUpdate &&
            isMonitoringItemEditable && (
              <ToggleEditMode
                isEditMode={isEditMode}
                onToggle={() => setIsEditMode(true)}
                onSave={onSave}
                onCancel={onCancelChanges}
                buttonIdPrefix="periodical-check-next-periodical-check"
                checkSaveDisabled={checkSaveDisabled}
                isSaveLoading={isSaveLoading}
              />
            )
          }
        />
      }
    >
      <RequestStateResolver
        center
        isLoading={isLoading}
        isError={isError}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        renderContent={() =>
          isEditMode ? (
            <NextPeriodicalCheckEdit
              formState={formState}
              updateFormState={setFormState}
              configurationForCovenant={covenantDefinition}
            />
          ) : (
            <NextPeriodicalCheckDisplay
              monitoringItem={monitoringItem}
              configurationForCovenant={covenantDefinition}
            />
          )
        }
      />
    </Card>
  )
}

NextPeriodicalCheckCard.propTypes = {
  covenantUuid: PropTypes.string,
  covenantDefinition: PropTypes.shape({
    withDocumentsRequest: PropTypes.bool,
  }),
}

export default NextPeriodicalCheckCard
