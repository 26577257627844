import { filterSelectedRowsForNotAvailableRows } from 'redux/slices/conditions/actions/helper/conditionsActionHelper'

const setSelectedRows = (state, { payload: selectedRows }) => {
  state.selectedRows = filterSelectedRowsForNotAvailableRows({
    selectedRows,
    conditions: state.conditions,
  })
}

export default setSelectedRows
