import PropTypes from 'prop-types'
import { useCallback } from 'react'
import MultiplePortfolioFinancedAssetsInformationTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/financed-assets-information/MultiplePortfolioFinancedAssetsInformationTile'
import MultipleDealSelectionPdfViewWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/wrapper/MultipleDealSelectionPdfViewWrapper'

const MultiplePortfolioFinancedAssetsInformationTileSelectionWrapper = ({
  tileId,
  isPdfView,
  showConfirmationForLinks,
}) => {
  const contentCreator = useCallback(
    ({ selectedDealIndex }) => (
      <MultiplePortfolioFinancedAssetsInformationTile
        tileId={tileId}
        selectedDealIndex={selectedDealIndex}
        isPdfView={isPdfView}
        showConfirmationForLinks={showConfirmationForLinks}
      />
    ),
    [isPdfView, tileId, showConfirmationForLinks],
  )
  return (
    <MultipleDealSelectionPdfViewWrapper
      tileId={tileId}
      contentCreator={contentCreator}
      isPdfView={isPdfView}
    />
  )
}

MultiplePortfolioFinancedAssetsInformationTileSelectionWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
}

export default MultiplePortfolioFinancedAssetsInformationTileSelectionWrapper
