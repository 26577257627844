import PropTypes from 'prop-types'
import { useCallback } from 'react'
import MultiCurrentRentTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/current-rent/MultiCurrentRentTile'
import MultipleDealSelectionPdfViewWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/wrapper/MultipleDealSelectionPdfViewWrapper'
import { TenantColorProvider } from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/TenantColorContext'

const MultiCurrentRentTileDealSelectionWrapper = ({ tileId, isPdfView }) => {
  const contentCreator = useCallback(
    ({ selectedDealIndex }) => (
      <TenantColorProvider>
        <MultiCurrentRentTile
          tileId={tileId}
          selectedDealIndex={selectedDealIndex}
          isPdfView={isPdfView}
        />
      </TenantColorProvider>
    ),
    [isPdfView, tileId],
  )
  return (
    <MultipleDealSelectionPdfViewWrapper
      tileId={tileId}
      contentCreator={contentCreator}
      isPdfView={isPdfView}
    />
  )
}

MultiCurrentRentTileDealSelectionWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool,
}

export default MultiCurrentRentTileDealSelectionWrapper
