import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/deal-cashflow/results/PeriodSelect.module.css'

export const periodValues = {
  years: 'YEAR',
  quarters: 'QUARTER',
  months: 'MONTH',
}

const PeriodSelect = ({ value, onChange, availablePeriods = [] }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.cashflow.details.results',
  })

  const setPeriod = (event) => {
    onChange(event.detail.selectedOption.value)
  }

  const isOptionDisabled = (period) => !availablePeriods.includes(period)
  const isOptionSelected = (period) => value === period

  return (
    <Select
      onChange={setPeriod}
      id="period-select"
      className={styles.select}
      disabled={!availablePeriods.length}
    >
      <Option value={periodValues.years} selected={isOptionSelected(periodValues.years)}>
        {t('years')}
      </Option>
      <Option
        value={periodValues.quarters}
        selected={isOptionSelected(periodValues.quarters)}
        disabled={isOptionDisabled(periodValues.quarters)}
      >
        {t('quarters')}
      </Option>
      <Option
        value={periodValues.months}
        selected={isOptionSelected(value === periodValues.months)}
        disabled={isOptionDisabled(periodValues.months)}
      >
        {t('months')}
      </Option>
    </Select>
  )
}

PeriodSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  availablePeriods: PropTypes.array.isRequired,
}

export default PeriodSelect
