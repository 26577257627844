import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useEditKeyInternalContacts = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()

  return useMutation(
    ({ businessPartnerId, keyInternalContacts }) =>
      patch({
        path: `/businesspartners/${businessPartnerId}/key-internal-contacts`,
        body: {
          key_internal_contacts: keyInternalContacts,
        },
      }),
    mutationOptions,
  )
}

export default useEditKeyInternalContacts
