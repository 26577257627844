import { FlexBox } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { getButtonForDecisionStageConditionsVoterVoteOption } from 'api/decision-process/decisionProcessConditionsApi'
import styles from 'components/domains/business-events-and-tasks/decision-stage/conditions/approval/DecisionStageConditionsApprovalVoterHeaderButtons.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useDecisionStageConditionsApprovalVoterVoteOptions from 'hooks/services/business-events-and-tasks/decision-process/stages/conditions/approval/useDecisionStageConditionsApprovalVoterVoteOptions'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionStageConditionsApprovalVoterHeaderButtons = ({ decisionStageConditionsVoterId }) => {
  const {
    event: { id: eventId },
  } = useContext(BusinessEventsAndTasksContext)
  const { decisionStageId } = useParams()

  const { isLoading, isError, data } = useDecisionStageConditionsApprovalVoterVoteOptions(
    {
      eventId,
      decisionStageId,
      decisionStageConditionsVoterId,
    },
    { enabled: !!decisionStageConditionsVoterId },
  )

  const renderButtons = useCallback(
    () => (
      <FlexBox className={styles.buttonWrapper}>
        {data.voteOptions.map(({ code }) => {
          const ButtonComponent = getButtonForDecisionStageConditionsVoterVoteOption(code)
          return (
            <ButtonComponent
              key={`vote-option-${code}`}
              eventId={eventId}
              decisionStageId={decisionStageId}
              decisionStageConditionsVoterId={decisionStageConditionsVoterId}
            />
          )
        })}
      </FlexBox>
    ),
    [data, decisionStageId, decisionStageConditionsVoterId, eventId],
  )

  return (
    <SmallLoadingWrapper
      isLoading={!decisionStageConditionsVoterId || isLoading}
      isError={isError}
      error=""
      renderContent={renderButtons}
    />
  )
}

DecisionStageConditionsApprovalVoterHeaderButtons.propTypes = {
  decisionStageConditionsVoterId: PropTypes.string,
}

export default DecisionStageConditionsApprovalVoterHeaderButtons
