import PropTypes from 'prop-types'
import React from 'react'
import { isNotFoundError } from 'api/requests'
import LoadingMultiComboBox from 'components/ui/multi-combo-box/LoadingMultiComboBox'
import useMarketTypes from 'hooks/services/markets/useMarketTypes'

const FilterMarketTypesComboBox = React.forwardRef(
  ({ selectedMarketTypes, onSelectionChange, propertyType, ...props }, _ref) => {
    const {
      isError,
      isLoading,
      error,
      data: marketTypesData,
    } = useMarketTypes({ propertyType, includeDeprecated: true })

    const marketTypesDataMapping = () => {
      if (isLoading || isError) {
        if (error && isNotFoundError(error)) {
          return { isLoading: false, isError: false, data: [] }
        }
        return { isLoading, isError, data: undefined }
      }
      return {
        data: marketTypesData.market_types.map(({ code, value }) => ({ code, displayName: value })),
        isLoading: false,
        isError: false,
      }
    }

    return (
      <LoadingMultiComboBox
        {...props}
        id="filter-market-type-multi-combo-box"
        useLoader={marketTypesDataMapping}
        onSelectionChange={onSelectionChange}
        selected={selectedMarketTypes}
      />
    )
  },
)

FilterMarketTypesComboBox.displayName = 'FilterMarketTypesComboBox'

FilterMarketTypesComboBox.propTypes = {
  selectedMarketTypes: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onSelectionChange: PropTypes.func.isRequired,
  propertyType: PropTypes.string,
}

export default FilterMarketTypesComboBox
