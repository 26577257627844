import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useDecisionStages from 'hooks/services/business-events-and-tasks/decision-process/stages/useDecisionStages'

const sorting = { sortBy: 'decision_date', orderBy: 'asc' }

const useCheckSheetDecisionOverview = ({ id: eventId }, tileId) => {
  const {
    isLoading: isDecisionStagesLoading,
    isError: isDecisionStagesError,
    data: decisionStagesData,
    error: decisionStagesError,
  } = useDecisionStages({ eventId }, sorting)

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isDecisionStagesLoading],
    errorValues: [isDecisionStagesError],
    errorDetails: [decisionStagesError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }
    return {
      isLoading: false,
      isError: false,
      data: {
        decisionStages: decisionStagesData?.decisionStages || [],
        sourceRender: {
          eventId,
        },
      },
    }
  }, [isSomeValueError, isSomeValueLoading, error, decisionStagesData, eventId])
}

export default useCheckSheetDecisionOverview
