import { IllustratedMessage } from '@fioneer/ui5-webcomponents-react'
import camelize from 'camelize'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { propertyAllowedOperations } from 'api/property/propertyAllowedOperations'
import PropertyValuationResultsHistoryChart from 'components/domains/properties/valuation/PropertyValuationResultsHistoryChart'
import PropertyValuationResultsHistoryChartHeader from 'components/domains/properties/valuation/PropertyValuationResultsHistoryChartHeader'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useCwpPropertyValuations from 'hooks/services/properties/valuations/useCwpPropertyValuations'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyValuationsHistory = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property.valuation.results' })

  const { property } = useContext(PropertyContext)
  const camelizedProperty = useMemo(() => camelize(property), [property])
  const allowedOperations = camelizedProperty?.allowedOperations?.allowedOperations

  const propertyUuid = camelizedProperty?.uuid

  const {
    data: { cwpValuations: valuationsData = [] } = {},
    isFetching: isPropertyValuationsLoading,
    isError: isPropertyValuationsError,
  } = useCwpPropertyValuations({
    propertyUuid,
  })

  const renderChart = () => (
    <Card header={<PropertyValuationResultsHistoryChartHeader valuationsData={valuationsData} />}>
      <PropertyValuationResultsHistoryChart valuationsData={valuationsData} />
    </Card>
  )

  return !allowedOperations?.includes(propertyAllowedOperations.propertyValuationRead) ? (
    <Card>
      <IllustratedMessage
        size="Spot"
        name="UnableToLoad"
        titleText={t('permission-error.title')}
        subtitleText={t('permission-error.subtitle')}
      />
    </Card>
  ) : (
    <RequestStateResolver
      isLoading={isPropertyValuationsLoading}
      isError={isPropertyValuationsError}
      renderContent={renderChart}
      center={true}
      errorToDisplay={
        <Card>
          <ErrorDataUnavailableInContent />
        </Card>
      }
    />
  )
}

export default PropertyValuationsHistory
