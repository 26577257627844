import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionStageVoteOptions } from 'api/decision-process/decisionProcessApi'
import DecisionStageApprovalApproveWithConditionsDialog from 'components/domains/business-events-and-tasks/decision-stage/approval/shared/DecisionStageApprovalApproveWithConditionsDialog'
import SemanticButton, { SemanticButtonDesign } from 'components/ui/button/SemanticButton'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import { emptyContent } from 'components/ui/rich-text-editor/CWPCKEditor'
import useIndividualVoteCheck from 'hooks/services/business-events-and-tasks/decision-process/stages/error-checks/useIndividualVoteCheck'
import useEditDecisionStageVoter from 'hooks/services/business-events-and-tasks/decision-process/stages/useEditDecisionStageVoter'

const DecisionStageApprovalVoterApproveWithConditionsButton = ({
  decisionStageId,
  eventId,
  decisionStageVoterId,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'decision-stage.vote-options.voter.approve-with-conditions',
  })
  const [currentCondition, setCurrentCondition] = useState(emptyContent)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const showMessageBox = useShowMessageBox()
  const { mutate: editDecisionStageVote } = useEditDecisionStageVoter()

  const queriesToInvalidate = useMemo(() => ['events', eventId, 'decision-stages'], [eventId])

  const linkTo = useMemo(
    () =>
      `/business-events-and-tasks/business-events/${eventId}/decision-stages/${decisionStageId}`,
    [decisionStageId, eventId],
  )

  const { onSuccess } = useIndividualVoteCheck({
    successText: t('success.text'),
    successTitle: t('success.title'),
    queriesToInvalidate,
    linkTo,
  })

  const onApproveWithConditionsError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('error'),
    })
  }, [showMessageBox, t])

  const onApproveConfirm = useCallback(() => {
    editDecisionStageVote(
      {
        decisionStageId,
        eventId,
        id: decisionStageVoterId,
        voteStatusCode: decisionStageVoteOptions.approveWithConditions,
        condition: currentCondition,
      },
      { onSuccess, onError: onApproveWithConditionsError },
    )
  }, [
    editDecisionStageVote,
    decisionStageId,
    eventId,
    decisionStageVoterId,
    currentCondition,
    onSuccess,
    onApproveWithConditionsError,
  ])

  const onApproveWithConditions = useCallback(() => {
    setIsConfirmDialogOpen(true)
  }, [])

  return (
    <>
      <SemanticButton design={SemanticButtonDesign.Warning} onClick={onApproveWithConditions}>
        {t('action')}
      </SemanticButton>
      <DecisionStageApprovalApproveWithConditionsDialog
        currentCondition={currentCondition}
        setCurrentCondition={setCurrentCondition}
        isDialogOpen={isConfirmDialogOpen}
        setIsDialogOpen={setIsConfirmDialogOpen}
        onApproveWithConditions={onApproveConfirm}
      />
    </>
  )
}

DecisionStageApprovalVoterApproveWithConditionsButton.propTypes = {
  eventId: PropTypes.string.isRequired,
  decisionStageId: PropTypes.string.isRequired,
  decisionStageVoterId: PropTypes.string.isRequired,
}

export default DecisionStageApprovalVoterApproveWithConditionsButton
