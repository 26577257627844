import { useMutation, useQueryClient } from '@tanstack/react-query'
import camelize from 'camelize'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteDocumentVersion = ({ onSuccess: onSuccessCallback, ...mutationOptions }) => {
  const { delete: deleteDocumentVersion } = useAccessTokenRequest()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ documentId, versionGuid }) =>
      deleteDocumentVersion({
        path: `/documents/${documentId}/versions/${versionGuid}`,
      }),
    ...mutationOptions,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['documents', variables.documentId])
      onSuccessCallback?.(camelize(data), variables, context)
    },
  })
}

export default useDeleteDocumentVersion
