import { IllustratedMessage, IllustrationMessageType } from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { useTranslation } from 'react-i18next'

/**
 * Error component when data is unvailable or could not be loaded in a content area, e.g., of a page or card.
 *
 * @param {object} props
 * @param {string?} [props.titleText] is the title translation text, defaults to "Data is currently not available."
 * @param {string?} [props.subtitleText] is the subtitle translation text, defaults to "Please try again later. If the error persists, contact your system administrator."
 * @param {boolean} [props.showImage] to enable the rendering of the error image, defaults to "true"
 */

const ErrorDataUnavailableInContent = ({
  titleText = null,
  subtitleText = null,
  showImage = true,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.error.data-unavailable.content',
  })

  const name = showImage ? IllustrationMessageType.UnableToLoad : IllustrationMessageType.Base

  const titleTextToDisplay = titleText ?? t('title-text')
  const subtitleTextToDisplay = subtitleText ?? t('subtitle-text')

  return (
    <IllustratedMessage
      name={name}
      titleText={titleTextToDisplay}
      subtitleText={subtitleTextToDisplay}
    />
  )
}

ErrorDataUnavailableInContent.propTypes = {
  titleText: PropType.string,
  subtitleText: PropType.string,
  showImage: PropType.bool,
}

export { ErrorDataUnavailableInContent }
