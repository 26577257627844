import compact from 'lodash.compact'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'

const extractDealIdTrancheIdPairs = ({ multipleTranchesByBusinessPartnerData }) => {
  const dealIdTrancheIdPairsByBusinessPartner = {}
  multipleTranchesByBusinessPartnerData?.forEach((data) => {
    if (!isNil(data.businessPartnerId)) {
      dealIdTrancheIdPairsByBusinessPartner[data.businessPartnerId] = []

      data.tranches?.forEach((tranche) =>
        dealIdTrancheIdPairsByBusinessPartner[data.businessPartnerId].push({
          // externalContractId is the trancheId for CML tranches
          trancheId: tranche.externalContractId[0],
          dealId: tranche.dealId,
        }),
      )
    }
  })

  const allDealIdTrancheIdPairs = isEmpty(dealIdTrancheIdPairsByBusinessPartner)
    ? []
    : compact(
        Object.values(dealIdTrancheIdPairsByBusinessPartner)
          .flat()
          .filter(({ dealId }) => !isNil(dealId)),
      )

  return { dealIdTrancheIdPairsByBusinessPartner, allDealIdTrancheIdPairs }
}

export default extractDealIdTrancheIdPairs
