import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import isEqual from 'lodash.isequal'
import { useMemo } from 'react'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useDealProperties from 'hooks/services/deals/properties/useDealProperties'
import useDealMini from 'hooks/services/deals/useDealMini'
import useMultiplePropertyCustomFieldsByPropertyUuids from 'hooks/services/properties/customfield/useMultiplePropertyCustomFieldsByPropertyUuids'
import usePropertyCustomFieldConfig from 'hooks/services/properties/customfield/usePropertyCustomFieldConfig'
import useCurrentMultiPropertyKpis from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpis'
import useCurrentMultiPropertyKpisList from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpisList'
import usePropertiesKpis, {
  getFirstKeyDatePropertiesKpis,
} from 'hooks/services/properties/kpis/usePropertiesKpis'
import { useTegovaRating } from 'hooks/services/properties/rating/useTegovaRating'
import { useEnrichPropertyWithStatus } from 'hooks/services/properties/useEnrichPropertyWithStatus'
import usePropertyDetailedUuids from 'hooks/services/properties/usePropertyDetailedUuids'
import useMultiPropertyValuationsDecisionPaper from 'hooks/services/properties/valuations/useMultiPropertyValuationsDecisionPaper'

const ENTITY_TYPE = 'PROPERTY'

const GREEN_DATA_KEYS = [
  'YY_QUALIFIED_FOR_GREEN_BOND',
  'YY_PBB_GREEN_FW_LOAN_ELIGIB',
  'YY_PBB_GREEN_BOND_USAGE',
  'YY_PBB_GREEN_SCORE',
  'YY_EPC_CLASS',
  'YY_CO2_EMISSION',
  'YY_EU_TAXONOMY_ELIGIBLE',
]

const GREEN_DATA_CLASS_KEY = 'YY_EPC_CLASS'

export const useAssetValuationOverview = ({ entityRef: { entityId: dealUuid } }, _, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isFetchingDealUuid,
    isError: isErrorDealUuid,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    data: { dealProperties = [] } = {},
    isFetching: dealPropertiesIsFetching,
    isError: dealPropertiesIsError,
  } = useDealProperties({
    dealUuid: dealUuidByTileCode,
  })

  const isPropertyRequestEnabled = useMemo(
    () => !dealPropertiesIsFetching && !dealPropertiesIsError && !isEmpty(dealProperties),
    [dealProperties, dealPropertiesIsError, dealPropertiesIsFetching],
  )

  const propertyUuids = useMemo(
    () => (isPropertyRequestEnabled ? dealProperties.map((property) => property.propertyUuid) : []),
    [dealProperties, isPropertyRequestEnabled],
  )

  const allocatedLoanAmountHeadquarterSum = dealProperties.reduce(
    (acc, { allocatedLoanAmountHeadquarter }) =>
      acc + (allocatedLoanAmountHeadquarter?.number || 0),
    0,
  )

  const dealResult = useDealMini(dealUuidByTileCode)
  const dealId = useMemo(
    () => (!dealResult.isFetching && !dealResult.isError ? dealResult.data?.dealId : undefined),
    [dealResult.data?.dealId, dealResult.isError, dealResult.isFetching],
  )

  const {
    data: { data: { properties: propertiesSnakeCase = [] } = {} } = {},
    isError: propertiesIsError,
    isFetching: propertiesIsFetching,
  } = usePropertyDetailedUuids(
    propertyUuids,
    {
      enabled: isPropertyRequestEnabled,
    },
    true,
  )

  const properties = camelize(propertiesSnakeCase)
  const propertiesWithStatus = useEnrichPropertyWithStatus(properties)

  const {
    data: multiPropertyKpis,
    isFetching: multiPropertyKpisIsFetching,
    isError: multiPropertyKpisIsError,
  } = useCurrentMultiPropertyKpis(
    propertyUuids,
    {
      withCityGranularity: true,
      withCountryGranularity: true,
      withPropertyTypeGranularity: true,
    },
    { enabled: !isEmpty(properties) },
  )
  const keyDatesListOrUndefined = useMemo(
    () => (multiPropertyKpis?.keyDate ? [multiPropertyKpis?.keyDate] : undefined),
    [multiPropertyKpis],
  )

  const {
    data: multiKeyDatePropertiesKpis,
    isFetching: propertiesKpisIsFetching,
    isError: propertiesKpisIsError,
  } = usePropertiesKpis(
    propertyUuids,
    {
      keyDates: keyDatesListOrUndefined,
    },
    { enabled: !isEmpty(properties) && !!keyDatesListOrUndefined },
  )
  const propertiesKpis = useMemo(
    () => getFirstKeyDatePropertiesKpis(multiKeyDatePropertiesKpis),
    [multiKeyDatePropertiesKpis],
  )

  const {
    data: { valuations = [], sum: valuationSum = {} } = {},
    isFetching: valuationsIsFetching,
    isError: valuationsIsError,
  } = useMultiPropertyValuationsDecisionPaper(propertyUuids, ['sum'], undefined, {
    enabled: isPropertyRequestEnabled,
  })

  const {
    data: tegovaRatings,
    isFetching: tegovaRatingsIsFetching,
    isError: tegovaRatingsIsError,
  } = useTegovaRating(propertyUuids, { enabled: isPropertyRequestEnabled })

  const {
    data: multiCustomFields = [],
    isFetching: customFieldsIsFetching,
    isError: customFieldsIsError,
  } = useMultiplePropertyCustomFieldsByPropertyUuids({ propertyUuids })

  const {
    data: { customFieldMetadata = [] } = {},
    isFetching: metadataIsFetching,
    isError: metadataIsError,
  } = usePropertyCustomFieldConfig(ENTITY_TYPE)

  const propertiesWithSubPortfolioKpis = useMemo(() => {
    const propertiesMappedWithSubPortfolio = []
    const subPortfolioSet = new Set()
    dealProperties.forEach((property) => subPortfolioSet.add(property?.subPortfolio))

    subPortfolioSet?.forEach((subPortfolio) => {
      const propertyUuidList = dealProperties
        .filter((property) => property?.subPortfolio === subPortfolio)
        .map((property) => property.propertyUuid)
      propertiesMappedWithSubPortfolio.push({
        propertyUuidList,
        subPortfolio,
      })
    })
    return propertiesMappedWithSubPortfolio
  }, [dealProperties])

  const requestBody = useMemo(
    () =>
      propertiesWithSubPortfolioKpis.map((propertiesWithSubPortfolio) => ({
        propertyUuids: propertiesWithSubPortfolio.propertyUuidList,
        keyDates: keyDatesListOrUndefined,
      })) || [],
    [keyDatesListOrUndefined, propertiesWithSubPortfolioKpis],
  )
  const subPortfolioKpisQueryResultsIsEnabled =
    isPropertyRequestEnabled && !!keyDatesListOrUndefined
  const subPortfolioKpisQueryResults = useCurrentMultiPropertyKpisList(requestBody, {
    enabled: subPortfolioKpisQueryResultsIsEnabled,
  })

  const subPortfolioKpis = useMemo(() => {
    const subPortfolioWithKpis = []
    for (let i = 0; i < subPortfolioKpisQueryResults.data?.length; i++) {
      propertiesWithSubPortfolioKpis.forEach((propertiesWithSubPortfolio) => {
        if (
          isEqual(
            propertiesWithSubPortfolio.propertyUuidList,
            subPortfolioKpisQueryResults.data[i]?.data?.propertyUuids,
          )
        ) {
          subPortfolioWithKpis.push({
            ...propertiesWithSubPortfolio,
            kpis: {
              subPortfolio: propertiesWithSubPortfolio.subPortfolio,
              ...subPortfolioKpisQueryResults.data[i]?.data?.keyDateToMultiPropertyRentRollKpis?.[0]
                ?.kpis,
            },
          })
        }
      })
    }
    return subPortfolioWithKpis
  }, [propertiesWithSubPortfolioKpis, subPortfolioKpisQueryResults.data])

  const tableData = propertiesWithStatus.map(({ uuid, ...property }) => {
    const dealProperty = dealProperties.find(({ propertyUuid }) => uuid === propertyUuid)
    const tegovaRating = tegovaRatings?.tegovaRatings?.find(
      ({ propertyUuid }) => uuid === propertyUuid,
    )

    const annualRentalIncome = property?.actualRentAmount?.number
    const valuation = valuations?.find(({ uuid: propertyUuid }) => propertyUuid === uuid)
    const marketValue = valuation?.marketValue?.valueAmount?.number

    const multiplier = annualRentalIncome && marketValue ? marketValue / annualRentalIncome : 0
    const initialYield = multiplier ? 1 / multiplier : 0

    const customFields =
      multiCustomFields
        .find(({ data: { propertyUuid } = {} }) => propertyUuid === uuid)
        ?.data?.customFields?.filter(({ key }) => GREEN_DATA_KEYS.includes(key)) ?? []

    const customFieldClassEnumValues = customFieldMetadata.find(
      ({ key }) => key === GREEN_DATA_CLASS_KEY,
    )?.enumValues

    return {
      ...property,
      ...dealProperty,
      allocatedLoanAmountHeadquarterSum,
      kpis: propertiesKpis?.find(({ propertyUuid }) => propertyUuid === uuid) || {},
      valuations: valuation ?? {},
      tegovaRating: tegovaRating?.total ?? 0,
      keyData: { multiplier, initialYield },
      greenData: { customFields, customFieldClassEnumValues },
    }
  })

  const sortedTableData = useMemo(
    () =>
      tableData.sort(
        (a, b) =>
          (b.valuations?.marketValue?.valueAmount?.number || 0) -
            (a.valuations?.marketValue?.valueAmount?.number || 0) ||
          a.description.localeCompare(b.description),
      ),
    [tableData],
  )

  const sourcePathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?working-version=true' : ''),
    [isWorkingVersionDataTile],
  )

  return useMemo(() => {
    const isLoading =
      isFetchingDealUuid ||
      dealPropertiesIsFetching ||
      dealResult.isFetching ||
      propertiesIsFetching ||
      propertiesKpisIsFetching ||
      valuationsIsFetching ||
      multiPropertyKpisIsFetching ||
      tegovaRatingsIsFetching ||
      subPortfolioKpisQueryResults.isFetching ||
      customFieldsIsFetching ||
      metadataIsFetching

    const isError =
      isErrorDealUuid ||
      dealPropertiesIsError ||
      dealResult.isError ||
      propertiesIsError ||
      propertiesKpisIsError ||
      valuationsIsError ||
      multiPropertyKpisIsError ||
      tegovaRatingsIsError ||
      subPortfolioKpisQueryResults.isError ||
      customFieldsIsError ||
      metadataIsError

    const data = {
      tableData: sortedTableData,
      multiPropertyKpis,
      valuationSum,
      subPortfolioKpis,
      properties,
      sourceRender: { dealId, sourcePathSuffix },
      noProperties: false,
    }

    if (!dealPropertiesIsFetching && !dealPropertiesIsError && isEmpty(dealProperties)) {
      return {
        isLoading: false,
        isError: false,
        data: {
          noProperties: true,
        },
      }
    }

    return {
      isLoading,
      isError,
      data: !isLoading && !isError ? data : undefined,
    }
  }, [
    isFetchingDealUuid,
    dealPropertiesIsFetching,
    dealResult.isFetching,
    dealResult.isError,
    propertiesIsFetching,
    propertiesKpisIsFetching,
    valuationsIsFetching,
    multiPropertyKpisIsFetching,
    tegovaRatingsIsFetching,
    subPortfolioKpisQueryResults.isFetching,
    subPortfolioKpisQueryResults.isError,
    customFieldsIsFetching,
    metadataIsFetching,
    isErrorDealUuid,
    dealPropertiesIsError,
    propertiesIsError,
    propertiesKpisIsError,
    valuationsIsError,
    multiPropertyKpisIsError,
    tegovaRatingsIsError,
    customFieldsIsError,
    metadataIsError,
    sortedTableData,
    multiPropertyKpis,
    valuationSum,
    subPortfolioKpis,
    properties,
    dealId,
    sourcePathSuffix,
    dealProperties,
  ])
}
