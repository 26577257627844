import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultipleCovenantMonitoringItemsByEntityId = ({
  mainEntityIds,
  mainEntityType,
  options = {},
}) => {
  const [queryParams] = useSearchParams()
  queryParams.append('main_entity_type', mainEntityType)

  const responses = useRequestsMemo(
    useRequests({
      requests: mainEntityIds.map((mainEntityId) => ({
        path: `/covenants/monitoring-information/items?main_entity_id=${mainEntityId}&${queryParams.toString()}`,
        keys: ['covenants', 'monitoring', 'items', mainEntityType, mainEntityId],
      })),
      translated: true,
      useCache: true,
      options: { enabled: !isEmpty(mainEntityIds) && !!mainEntityType, ...options },
    }),
  )

  return useMemo(
    () =>
      responses.map((response, index) => ({
        ...response,
        data: {
          [mainEntityType]: mainEntityIds[index],
          ...camelize(response.data),
        },
      })),
    [mainEntityIds, mainEntityType, responses],
  )
}

export default useMultipleCovenantMonitoringItemsByEntityId
