import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import ConditionAssigneeDisplayV1 from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/assignee/ConditionAssigneeDisplayV1'
import { conditionRowHasError } from 'redux/slices/conditions/conditionsTableSlice'

const ConditionsTableAssigneeCellV1 = ({ value: { id: conditionId, assignee: assigneeId } }) => {
  const dispatch = useDispatch()

  const onRequestError = useCallback(() => {
    dispatch(conditionRowHasError({ id: conditionId, error: { hasError: true } }))
  }, [dispatch, conditionId])

  return <ConditionAssigneeDisplayV1 assigneeId={assigneeId} onRequestError={onRequestError} />
}

ConditionsTableAssigneeCellV1.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assignee: PropTypes.string,
  }).isRequired,
}
export default ConditionsTableAssigneeCellV1
