import isEmpty from 'lodash.isempty'
import { calculateSyndicationRowHeight } from 'routes/deals/syndication/syndicationStructure/utils/backendToTableStructure/calculateSyndicationRowHeight'
import { ROW_TYPE_TRANCHE } from 'routes/deals/syndication/syndicationStructure/utils/constants'
import { convertDecimalToBps } from 'routes/deals/syndication/syndicationStructure/utils/convertBps'

const mapOwnShareSyndicationShares = (syndicationShares, rootRowId) =>
  syndicationShares.map((syndicationShare) => {
    const feesWithCustomerMargin = (
      isEmpty(syndicationShare.customerMargin) ? [] : [syndicationShare.customerMargin]
    ).concat(syndicationShare.syndicationFees ?? [])

    const rowHeight = calculateSyndicationRowHeight(feesWithCustomerMargin.length, syndicationShare)

    return {
      rootRowId,
      partyRoleTranche: {
        value: syndicationShare.name,
        trancheDisplayId: syndicationShare.trancheDisplayId,
      },
      commitment: {
        value: syndicationShare.amountTotal,
        valueHeadquarter: syndicationShare.amountTotalHeadquarter,
        share: syndicationShare.amountShare,
      },
      consortiumRank: {
        value: syndicationShare.consortiumRank,
      },
      type: feesWithCustomerMargin?.map((syndicationShare) => ({
        code: syndicationShare.feeTypeCode,
        value: syndicationShare.feeTypeShortText,
        feeId: syndicationShare.feeId,
      })),
      amountPA: feesWithCustomerMargin?.map((syndicationShare) => ({
        value: syndicationShare.amountPerAnnum,
        valueHeadquarter: syndicationShare.amountPerAnnumHeadquarter,
        feeId: syndicationShare.feeId,
      })),
      amountTerm: feesWithCustomerMargin?.map((syndicationShare) => ({
        value: syndicationShare.amountTerm,
        valueHeadquarter: syndicationShare.amountTermHeadquarter,
        feeId: syndicationShare.feeId,
      })),
      rate: feesWithCustomerMargin?.map((syndicationShare) => ({
        value: convertDecimalToBps(syndicationShare.rate),
        feeId: syndicationShare.feeId,
      })),
      rowHeight,
      trancheId: syndicationShare.trancheId,
      isSpacingForHQCurrencyNeeded:
        syndicationShare.amountTotalHeadquarter?.currency !==
        syndicationShare.amountTotal?.currency,
      rowType: ROW_TYPE_TRANCHE,
    }
  })

export default mapOwnShareSyndicationShares
