import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useDealCollateralInformation from 'hooks/services/deals/collateral/useDealCollateralInformation'
import useDeal from 'hooks/services/deals/useDeal'

const useStandardCollateralPackage = ({ entityRef: { entityId: dealUuid } }, tileId, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
    errors: dealUuidErrors,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    data: dealCollateralInformation,
    isFetching: collateralIsFetching,
    error: collateralError,
    isError: collateralIsError,
  } = useDealCollateralInformation({ dealUuid: dealUuidByTileCode })

  const {
    data: dealData,
    isFetching: isDealFetching,
    isError: isDealError,
    error: dealError,
  } = useDeal(dealUuidByTileCode)

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isDealUuidFetching, collateralIsFetching, isDealFetching],
    errorValues: [isDealUuidError, collateralIsError, isDealError],
    errorDetails: [...dealUuidErrors, collateralError, dealError],
    tileId,
  })
  const sourcePathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?dataSource=newBusiness&working-version=true' : ''),
    [isWorkingVersionDataTile],
  )

  return useMemo(() => {
    const data =
      dealCollateralInformation && dealData && !isSomeValueError && !isSomeValueLoading
        ? {
            ...dealCollateralInformation,
            sourceRender: { dealDisplayId: dealData?.dealId, sourcePathSuffix },
          }
        : undefined

    return {
      isLoading: isSomeValueLoading,
      isError: isSomeValueError,
      error,
      data,
    }
  }, [
    dealCollateralInformation,
    dealData,
    isSomeValueError,
    isSomeValueLoading,
    sourcePathSuffix,
    error,
  ])
}

export default useStandardCollateralPackage
