import { Dialog, Bar, Button, Icon, Title } from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { createPortal } from 'react-dom'
import 'components/ui/card/Card.css'
import 'components/domains/properties/PropertyCards.css'
import { useTranslation } from 'react-i18next'

/**
 * use ui-components dialog | https://fioneer.atlassian.net/browse/CWP-13200
 */
const PropertyInfoCardOverwriteTypeDialog = ({ isOpen, onCancel, onConfirm }) => {
  const { t } = useTranslation('translation')

  return (
    isOpen &&
    createPortal(
      <Dialog
        open={isOpen}
        header={
          <Bar
            startContent={
              <>
                <Icon style={{ color: '#0F5DE9' }} name="sap-icon://sys-help-2" />
                <Title>{t('components.property-info-card.dialog.title')}</Title>
              </>
            }
          />
        }
        footer={
          <Bar
            endContent={
              <>
                <Button
                  id={'acceptPropertyTypeChangeButton'}
                  design="Emphasized"
                  onClick={onConfirm}
                >
                  {t('buttons.confirm')}
                </Button>
                <Button id={'declinePropertyTypeChangeButton'} onClick={onCancel}>
                  {t('buttons.cancel')}
                </Button>
              </>
            }
          />
        }
      >
        {t('components.property-info-card.dialog.text')}
      </Dialog>,
      document.body,
    )
  )
}

PropertyInfoCardOverwriteTypeDialog.propTypes = {
  isOpen: PropType.bool.isRequired,
  onCancel: PropType.func.isRequired,
  onConfirm: PropType.func.isRequired,
}

export default PropertyInfoCardOverwriteTypeDialog
