import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useCentralDataAllowedOperations = (options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/central-data/allowed-operations`,
      useCache: true,
      keys: ['central-data', 'allowed-operations'],
      options,
    }),
  )

export default useCentralDataAllowedOperations
