import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import { collateralAgreementsAllowedOperationsSchema } from 'hooks/services/collaterals/collateralAgreementsAllowedOperationsSchema'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useCollateralAgreementsAllowedOperations = () => {
  const response = useCamelizedResponse(
    useRequest({
      path: '/collateral-agreements/allowed-operations',
      useCache: true,
      keys: ['collateral-agreements', 'permissions'],
    }),
  )

  const data = useMemo(
    () =>
      response.data ? collateralAgreementsAllowedOperationsSchema.parse(response.data) : undefined,
    [response.data],
  )

  if (!data) return response

  return { ...response, data }
}

export default useCollateralAgreementsAllowedOperations
