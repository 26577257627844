import { FlexBoxAlignItems, Modals } from '@fioneer/ui5-webcomponents-react'
import { DateTime } from 'luxon'
import { useContext, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { covenantPermissions } from 'api/deals/covenantPermissions'
import CovenantsTable from 'components/domains/deals/covenants/covenants-table/CovenantsTable'
import styles from 'components/domains/deals/covenants/covenants-table/CovenantsTableCard.module.css'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import { useCovenantsWithFrontendPagination } from 'hooks/services/deals/covenants/useCovenants'
import { useInfiniteLoading } from 'hooks/services/useInfiniteLoading'
import { CovenantContext } from 'routes/business-partners/CovenantContext'
import { DealContext } from 'routes/deals/DealContext'

const setInitialSorting = (queryParams) => {
  if (queryParams.get('orderField') && queryParams.get('orderDirection')) {
    return {
      orderField: queryParams.get('orderField'),
      orderDirection: queryParams.get('orderDirection'),
    }
  }
  return {}
}

const getColumnDefinitions = (t) => [
  {
    title: t('covenant'),
    columnKey: 'covenant',
    isSelectableForHiding: false,
  },
  {
    title: t('type'),
    columnKey: 'type',
  },
  {
    title: t('reference-entity'),
    columnKey: 'reference-entity',
    sortingDisabled: true,
  },
  {
    title: t('monitoring-reference-type'),
    columnKey: 'monitoring-reference-type',
    isVisible: false,
  },
  {
    title: t('monitoring-entity'),
    columnKey: 'monitoring-reference',
    isVisible: false,
    sortingDisabled: true,
  },
  {
    title: t('status'),
    columnKey: 'status',
  },
  {
    title: t('test-date'),
    columnKey: 'test-date',
    isVisible: false,
  },
  {
    title: t('delivery-date'),
    columnKey: 'target-delivery-date',
    alignment: FlexBoxAlignItems.End,
  },
  {
    title: t('check-cycle'),
    columnKey: 'check-cycle',
  },
  {
    title: t('last-checked'),
    columnKey: 'last-checked',
    isVisible: false,
  },
  {
    title: t('soft-limit'),
    columnKey: 'soft-limit',
    alignment: FlexBoxAlignItems.End,
  },
  {
    title: t('hard-limit'),
    columnKey: 'hard-limit',
    alignment: FlexBoxAlignItems.End,
  },
  {
    title: t('loan-sizing'),
    columnKey: 'loan-sizing',
    alignment: FlexBoxAlignItems.End,
    isVisible: false,
    sortingDisabled: true,
  },
  {
    title: '',
    columnKey: 'arrow',
    sortingDisabled: true,
    alignment: FlexBoxAlignItems.End,
    isSelectableForHiding: false,
  },
]

const CovenantsTableCard = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.covenants' })
  const { deal } = useContext(DealContext)
  const { allowedOperations: covenantAllowedOperations = [] } = useContext(CovenantContext)
  const isAllowedToReadCovenants = covenantAllowedOperations?.includes(
    covenantPermissions.readContractCovenant,
  )

  const showToast = Modals.useShowToast()

  const [columnDefinitions, setColumnDefinitions] = useState(getColumnDefinitions(t))
  const [queryParams, setQueryParams] = useSearchParams()
  const [sorting, setSorting] = useState(setInitialSorting(queryParams))
  const onSortingChanged = (orderField, orderDirection) => {
    setSorting({ orderDirection, orderField })
    queryParams.set('orderField', orderField)
    queryParams.set('orderDirection', orderDirection)
    setQueryParams(queryParams)
  }

  const validAt = useMemo(() => DateTime.now().toISODate(), [])
  const paginationDelta = 50
  const {
    isLoading: isLoadingCovenants,
    isError: isErrorCovenants,
    data: {
      covenants = [],
      pagination = {
        offset: 0,
        limit: 0,
        total: 0,
      },
    },
    loadMore: loadMoreCovenants,
    isMaximumResultSizeExceeded,
  } = useInfiniteLoading({
    requestHook: useCovenantsWithFrontendPagination,
    requestHookArgs: {
      mainEntityId: deal.dealUuid,
      mainEntityType: mainEntityTypes.DEAL,
      validAt: validAt,
      sort: sorting,
    },
    dataListElement: 'covenants',
    paginationDelta: paginationDelta,
  })

  useEffect(() => {
    if (!isLoadingCovenants && isMaximumResultSizeExceeded) {
      showToast({
        duration: 10000,
        children: t('maximum-size-exceeded', {
          limit: pagination.total,
        }),
      })
    }
  }, [isLoadingCovenants, isMaximumResultSizeExceeded, pagination.total, showToast, t])

  return (
    <Card>
      <div className={styles['table-wrapper']}>
        <RequestStateResolver
          isLoading={isLoadingCovenants}
          isError={isErrorCovenants}
          errorToDisplay={<ErrorDataUnavailableInContent />}
          renderContent={() => (
            <CovenantsTable
              columnDefinitions={columnDefinitions}
              saveCurrentColumnDefinitions={setColumnDefinitions}
              covenantsData={covenants}
              orderField={sorting.orderField}
              orderDirection={sorting.orderDirection}
              pagination={pagination}
              onSortingChanged={onSortingChanged}
              isAllowedToReadCovenants={isAllowedToReadCovenants}
              loadMore={loadMoreCovenants}
            />
          )}
        />
      </div>
    </Card>
  )
}

export default CovenantsTableCard
