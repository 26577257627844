import { ValueState } from '@fioneer/ui5-webcomponents-react'

export const STATUS_TYPE_CODES = Object.freeze({
  OPEN: '10',
  ACTIVE: '20',
  CLOSED: '30',
})

export const CRITICALITY_STATUS_TYPE_CODES = Object.freeze({
  RESOLVED: '0',
  FACILITY_IN_FOCUS: '20',
  WATCHLIST: '40',
  RESTRUCTURING: '60',
  IMPAIRMENT: '80',
})

export const riskIndicatorStatusMapping = Object.freeze({
  STATUS: {
    [STATUS_TYPE_CODES.OPEN]: {
      valueState: ValueState.Information,
    },
    [STATUS_TYPE_CODES.ACTIVE]: {
      valueState: ValueState.Success,
    },
    [STATUS_TYPE_CODES.CLOSED]: {
      valueState: ValueState.None,
    },
  },
  CRITICALITY: {
    [CRITICALITY_STATUS_TYPE_CODES.IMPAIRMENT]: {
      valueState: ValueState.Error,
      order: 80,
    },
    [CRITICALITY_STATUS_TYPE_CODES.FACILITY_IN_FOCUS]: {
      valueState: ValueState.None,
      order: 20,
    },
    [CRITICALITY_STATUS_TYPE_CODES.WATCHLIST]: {
      valueState: ValueState.Information,
      order: 40,
    },
    [CRITICALITY_STATUS_TYPE_CODES.RESTRUCTURING]: {
      valueState: ValueState.Warning,
      order: 60,
    },
    [CRITICALITY_STATUS_TYPE_CODES.RESOLVED]: {
      valueState: ValueState.Success,
      order: 0,
    },
  },
})
