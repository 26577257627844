import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  assignee: null,
  entityId: '',
  entityType: '',
  eventName: '',
  eventCode: '',
  eventDescription: '',
  eventDueDate: null,
  tasks: [],
  defaultTasks: [],
  decisionPaperTemplateCode: null,
  customizedDecisionPaperTemplateStructure: null,
  lastSelectedSaveStatus: '',
  eventIdToImportDataFrom: undefined,
}

export const eventCreationSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setAssignee: (state, { payload }) => {
      state.assignee = payload
    },
    setEntityType: (state, { payload }) => {
      state.entityType = payload
    },
    setEntityId: (state, { payload }) => {
      state.entityId = payload
    },
    setEventName: (state, { payload }) => {
      state.eventName = payload
    },
    setEventDescription: (state, { payload }) => {
      state.eventDescription = payload
    },
    setEventCode: (state, { payload }) => {
      state.eventCode = payload
    },
    setEventDueDate: (state, { payload }) => {
      state.eventDueDate = payload
    },
    setTasks: (state, { payload }) => {
      state.tasks = payload
    },
    setDefaultTasks: (state, { payload }) => {
      state.defaultTasks = payload
    },
    editTask: (state, { payload }) => {
      state.tasks = state.tasks.map((task) =>
        task.id === payload.id ? { ...task, ...payload } : task,
      )
    },
    addTask: (state, { payload }) => {
      state.tasks = [...state.tasks, { ...payload, id: state.tasks.length }]
    },
    deleteTask: (state, { payload }) => {
      const newTasks = []
      state.tasks.forEach((task) => {
        if (task.id !== payload.id) {
          newTasks.push({ ...task, id: newTasks.length })
        }
      })
      state.tasks = newTasks
    },
    setDecisionPaperTemplateCode: (state, { payload }) => {
      state.decisionPaperTemplateCode = payload
    },
    setCustomizedDecisionPaperTemplateStructure: (state, { payload }) => {
      state.customizedDecisionPaperTemplateStructure = payload
    },
    setLastSelectedSaveStatus: (state, { payload }) => {
      state.lastSelectedSaveStatus = payload
    },
    setEventIdToImportDataFrom: (state, { payload }) => {
      state.eventIdToImportDataFrom = payload
    },
    resetEventCreation: () => initialState,
  },
})

export const {
  setAssignee,
  setEntityId,
  setEntityType,
  setEventDescription,
  setEventDueDate,
  setEventName,
  setEventCode,
  resetEventCreation,
  setTasks,
  setDefaultTasks,
  editTask,
  setTaskId,
  copyTask,
  addTask,
  deleteTask,
  setDecisionPaperTemplateCode,
  setCustomizedDecisionPaperTemplateStructure,
  setEventIdToImportDataFrom,
  setLastSelectedSaveStatus,
} = eventCreationSlice.actions

export default eventCreationSlice.reducer
