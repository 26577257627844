import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useGetCondition = (conditionType, { conditionId }, options) =>
  useCamelizedResponse(
    useRequest({
      path: `/conditions/${conditionType}/${conditionId}`,
      translated: true,
      useCache: true,
      keys: ['conditions', conditionType, conditionId],
      options: {
        ...options,
      },
    }),
  )

export default useGetCondition
