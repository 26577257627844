import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useTaskComments = (eventId, taskId, { offset, limit } = {}) => {
  const queryParams = new URLSearchParams()

  const cacheKeys = ['events', eventId, 'tasks', taskId, 'comments']

  if (limit) {
    queryParams.append('limit', limit)
    queryParams.append('offset', offset ? offset : 0)
    cacheKeys.push(`${limit}-${offset ? offset : 0}`)
  }

  return useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/tasks/${taskId}/comments?${queryParams.toString()}`,
      useCache: true,
      keys: cacheKeys,
    }),
  )
}
