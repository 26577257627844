import find from 'lodash.find'
import uniq from 'lodash.uniq'
import { useMemo } from 'react'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useMarketsByIds from 'hooks/services/markets/useMarketsByIds'
import useCombinedUserFavoritesAndRecentVisits from 'hooks/services/user-profile/useCombinedUserFavoritesAndRecentVisits'

const getEntityIds = (items) => items?.map((item) => item.entityId) ?? []
const getUniqueIds = (items) => uniq(getEntityIds(items))
const someAreLoading = (requests) => requests.some((req) => req.isLoading)

const mapMarketToFavoriteAndRecentVisit = (favsAndRecVisits = [], markets = []) =>
  favsAndRecVisits.map((favAndRecVisit) => {
    const foundMarket = find(markets, (market) => market.id === favAndRecVisit.entityId)

    if (!foundMarket) {
      return {
        ...favAndRecVisit,
        notFound: true,
      }
    }

    return {
      ...favAndRecVisit,
      ...foundMarket,
      notFound: false,
    }
  })

const useGetAndEnrichFavoritesAndRecentVisitsWithMarkets = () => {
  const getMarketfavsAndRecVisits = useCombinedUserFavoritesAndRecentVisits({
    entityType: cwpEntityTypes.MARKET,
  })
  const unqiqueMarketIds = getUniqueIds(getMarketfavsAndRecVisits.data)
  const getAllMarkets = useMarketsByIds(unqiqueMarketIds)
  const markets = useMemo(
    () => getAllMarkets.filter(({ data }) => !!data).map(({ data }) => data),
    [getAllMarkets],
  )

  const isLoading = getMarketfavsAndRecVisits.isLoading || someAreLoading(getAllMarkets)
  const isError = getMarketfavsAndRecVisits.isError
  const data =
    isLoading || isError
      ? undefined
      : mapMarketToFavoriteAndRecentVisit(getMarketfavsAndRecVisits.data, markets)

  return {
    isLoading,
    isError,
    data,
  }
}

export default useGetAndEnrichFavoritesAndRecentVisitsWithMarkets
