import { Input } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import useAnalyticalTableIndexHelper from 'hooks/services/conditions/tables/useAnalyticalTableIndexHelper'
import { conditionRefNumberChanged } from 'redux/slices/conditions/conditionCreationSlice'

const ConditionCreationRefNrCell = ({
  row: {
    id,
    index,
    depth,
    original: { refNumber: currentRefNumber },
  },
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix:
      'components.conditions.overview.dialog.external-condition-creation.table.ref-number-cell',
  })
  const dispatch = useDispatch()
  const inputRef = useRef()

  const { conditionIndex } = useAnalyticalTableIndexHelper({
    id,
    index,
    depth,
  })

  const placeholderText = t('placeholder')

  const onInput = useCallback(
    ({ target: { value: refNumber } }) => {
      dispatch(conditionRefNumberChanged({ refNumber, conditionIndex }))
    },
    [dispatch, conditionIndex],
  )

  // This is a hack for the React Table. It seems like react table listens to the space event and stops
  // the propagation of this event to other components like the input. With this the event is not handed to
  // other components but the input which solves the behaviour for us.
  const onKeyDown = useCallback((event) => {
    if (event.code === 'Space' || event.code === 'ArrowRight' || event.code === 'ArrowLeft') {
      event.stopPropagation()
    }
  }, [])

  return (
    <Input
      ref={inputRef}
      onInput={onInput}
      onKeyDown={onKeyDown}
      placeholder={placeholderText}
      value={currentRefNumber}
    />
  )
}

ConditionCreationRefNrCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    depth: PropTypes.number.isRequired,
    original: PropTypes.shape({
      refNumber: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default ConditionCreationRefNrCell
