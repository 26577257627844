import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DecisionPaperTileListItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListItem'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import {
  useCustomizableCurrencyFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const NO_DATA_PLACEHOLDER = '-'

const MultipleFactSheetValuationTile = ({ tileId, selectedDealIndex }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.fact-sheet-valuation',
  })
  const formatMoney = useCustomizableCurrencyFormatter()
  const { format: formatDate } = useShortDateFormatter()

  const { data: { multipleValuationInformation = [], hasNoProperties, sourcePaths } = {} } =
    useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const valuationInformation = useMemo(
    () => multipleValuationInformation?.[selectedDealIndex],
    [multipleValuationInformation, selectedDealIndex],
  )

  useEffect(() => {
    dispatch(
      setSourceRender({ tileId, sourceRender: { path: `${sourcePaths?.[selectedDealIndex]}` } }),
    )
  }, [dispatch, selectedDealIndex, sourcePaths, tileId])

  const getMoneyDisplayValue = useCallback(
    (valuation) => {
      const { number, currency } = valuation?.value ?? {}
      if (number && currency) {
        return formatMoney(number, currency)
      }
      return NO_DATA_PLACEHOLDER
    },
    [formatMoney],
  )

  const getTimespan = useCallback(
    (dates) => {
      if (dates?.length === 1) {
        return `${formatDate(dates[0])}`
      } else if (dates?.length > 1) {
        const sortedDates = [...dates].sort((a, b) => new Date(a) - new Date(b))
        const formattedDates = sortedDates.map((date) => formatDate(date))
        return `${formattedDates[0]} - ${formattedDates.pop()}`
      }
      return NO_DATA_PLACEHOLDER
    },
    [formatDate],
  )
  const [marketValueDates, mortgageLendingDates, purchasePriceDates] = useMemo(
    () => [
      valuationInformation?.marketValue?.dates,
      valuationInformation?.mortgageLendingValue?.dates,
      valuationInformation?.purchasePrice?.dates,
    ],
    [valuationInformation],
  )
  const memoizedDisplayValues = useMemo(
    () => ({
      marketValue: {
        value: getMoneyDisplayValue(valuationInformation?.marketValue),
        keyDates: getTimespan(marketValueDates?.keyDates),
        reportDates: getTimespan(marketValueDates?.reportDates),
      },
      mortgageLendingValue: {
        value: getMoneyDisplayValue(valuationInformation?.mortgageLendingValue),
        keyDates: getTimespan(mortgageLendingDates?.keyDates),
        reportDates: getTimespan(mortgageLendingDates?.reportDates),
      },
      purchasePrice: {
        value: getMoneyDisplayValue(valuationInformation?.purchasePrice),
        keyDates: getTimespan(purchasePriceDates?.keyDates),
        reportDates: getTimespan(purchasePriceDates?.reportDates),
      },
    }),
    [
      valuationInformation,
      marketValueDates,
      mortgageLendingDates,
      purchasePriceDates,
      getMoneyDisplayValue,
      getTimespan,
    ],
  )
  if (hasNoProperties) {
    return <NoPropertyAssignedToDealInfo />
  }
  return (
    <>
      <DecisionPaperTileListItem
        label={t('market-value')}
        value={memoizedDisplayValues.marketValue.value}
        isFirstItem
      />
      <DecisionPaperTileListItem
        label={t('key-date')}
        value={memoizedDisplayValues.marketValue.keyDates}
      />
      <DecisionPaperTileListItem
        label={t('report-date')}
        value={memoizedDisplayValues.marketValue.reportDates}
      />
      <DecisionPaperTileListSeparator />
      <DecisionPaperTileListItem
        label={t('mortgage-lending-value')}
        value={memoizedDisplayValues.mortgageLendingValue.value}
        isFirstItem
      />
      <DecisionPaperTileListItem
        label={t('key-date')}
        value={memoizedDisplayValues.mortgageLendingValue.keyDates}
      />
      <DecisionPaperTileListItem
        label={t('report-date')}
        value={memoizedDisplayValues.mortgageLendingValue.reportDates}
      />
      <DecisionPaperTileListSeparator />
      <DecisionPaperTileListItem
        label={t('purchase-price')}
        value={memoizedDisplayValues.purchasePrice.value}
        isFirstItem
      />
      <DecisionPaperTileListItem
        label={t('key-date')}
        value={memoizedDisplayValues.purchasePrice.keyDates}
      />
      <DecisionPaperTileListItem
        label={t('report-date')}
        value={memoizedDisplayValues.purchasePrice.reportDates}
      />
    </>
  )
}

MultipleFactSheetValuationTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultipleFactSheetValuationTile
