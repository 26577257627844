export const centralDataAllowedOperations = {
  readCustomAssessment: 'custom-assessments-read',
  updateCustomAssessment: 'custom-assessments-update',
}

export const centralDataAllowedOperationsAll = [
  centralDataAllowedOperations.readCustomAssessment,
  centralDataAllowedOperations.updateCustomAssessment,
]

export const neededOperationsForCustomAssessmentRead = [
  centralDataAllowedOperations.readCustomAssessment,
]

export const neededOperationsForCustomAssessmentUpdate = [
  centralDataAllowedOperations.updateCustomAssessment,
]
