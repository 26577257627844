import isNil from 'lodash.isnil'

const initialState = {
  isEditing: false,
  hasUnsavedChanges: false,
  errorState: {},
  hasErrors: false,
  initialProxies: [],
  proxies: [],
}

const proxyFields = ['direction', 'threshold', 'thresholdType', 'isMonitored']

const initializeErrorState = (state) => {
  state.errorState = {}
  state.proxies.forEach(({ key }) => {
    state.errorState[key] = Object.fromEntries(
      proxyFields.map((proxyField) => [
        proxyField,
        {
          touched: false,
          hasError: false,
        },
      ]),
    )
  })
}

const updateErrorState = (state) => {
  const proxies = state.proxies
  let hasErrors = false
  proxies.forEach(({ isOverride, key, overrideProxy }) => {
    const isMonitored = overrideProxy?.isMonitored ?? false
    proxyFields.forEach((propertyKey) => {
      const propHasError =
        isOverride &&
        isMonitored &&
        (!overrideProxy || isNil(overrideProxy[propertyKey]) || overrideProxy[propertyKey] === '')
      state.errorState[key][propertyKey].hasError = propHasError
      hasErrors ||= propHasError
    })
  })
  state.hasErrors = hasErrors
}

const updateChangedState = (state) => {
  const hasChanges = state.proxies.some(({ isOverride, overrideProxy }, index) => {
    const initialProxy = state.initialProxies[index]
    const initialOverride = initialProxy.overrideProxy
    if (!isOverride && !initialProxy.isOverride) {
      return false
    }
    const isDifferentOverride = isOverride !== initialProxy.isOverride
    const isDifferentValues = proxyFields.some(
      (proxyField) => overrideProxy?.[proxyField] !== initialOverride?.[proxyField],
    )
    return isDifferentOverride || isDifferentValues
  })
  state.hasUnsavedChanges = hasChanges
}

const actions = {
  setProxies: (state, { payload: proxies }) => {
    state.proxies = proxies
    initializeErrorState(state)
  },
  startEditing: (state) => {
    state.initialProxies = state.proxies
    state.hasUnsavedChanges = false
    state.isEditing = true
    initializeErrorState(state)
    updateErrorState(state)
  },
  endEditing: (state) => {
    state.hasUnsavedChanges = false
    state.isEditing = false
    initializeErrorState(state)
    updateErrorState(state)
  },
  cancelEditing: (state) => {
    state.proxies = state.initialProxies
    state.hasUnsavedChanges = false
    state.isEditing = false
    initializeErrorState(state)
  },
  setOverrideSettings: (state, { payload: { key, value } }) => {
    const proxySettings = state.proxies.find(({ key: kpiKey }) => kpiKey === key)
    if (isNil(proxySettings)) {
      return
    }
    proxySettings.isOverride = value
    // initialize with default monitored value
    if (value && isNil(proxySettings.overrideProxy)) {
      proxySettings.overrideProxy = {
        isMonitored: true,
      }
    }
    updateChangedState(state)
    updateErrorState(state)
  },
  setThreshold: (state, { payload: { key, value } }) => {
    const proxySettings = state.proxies.find(({ key: kpiKey }) => kpiKey === key)
    if (isNil(proxySettings) || isNil(proxySettings.overrideProxy)) {
      return
    }
    proxySettings.overrideProxy.threshold = value
    state.errorState[key].threshold.touched = true
    updateChangedState(state)
    updateErrorState(state)
  },
  setThresholdType: (state, { payload: { key, value } }) => {
    const proxySettings = state.proxies.find(({ key: kpiKey }) => kpiKey === key)
    if (isNil(proxySettings) || isNil(proxySettings.overrideProxy)) {
      return
    }
    proxySettings.overrideProxy.thresholdType = value
    state.errorState[key].thresholdType.touched = true
    updateChangedState(state)
    updateErrorState(state)
  },
  setDirection: (state, { payload: { key, value } }) => {
    const proxySettings = state.proxies.find(({ key: kpiKey }) => kpiKey === key)
    if (isNil(proxySettings) || isNil(proxySettings.overrideProxy)) {
      return
    }
    proxySettings.overrideProxy.direction = value
    state.errorState[key].direction.touched = true
    updateChangedState(state)
    updateErrorState(state)
  },
  setMonitoring: (state, { payload: { key, value } }) => {
    const proxySettings = state.proxies.find(({ key: kpiKey }) => kpiKey === key)
    if (isNil(proxySettings) || isNil(proxySettings.overrideProxy)) {
      return
    }
    proxySettings.overrideProxy.isMonitored = value
    state.errorState[key].isMonitored.touched = true
    updateChangedState(state)
    updateErrorState(state)
  },
}

export default {
  initialState,
  actions,
}
