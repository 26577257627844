import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useAggregatedCollateralAgreements from 'hooks/services/collaterals/useAggregatedCollateralAgreements'
import useDeal from 'hooks/services/deals/useDeal'

const useCollateralAgreements = ({ entityRef: { entityId: dealUuid } }, tileId, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
    errors: dealUuidErrors,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    data: dealData,
    isFetching: isDealFetching,
    isError: isDealError,
    error: dealError,
  } = useDeal(dealUuidByTileCode)

  const {
    aggregatedCollateralAgreements,
    isFetching: isCagFetching,
    isError: isCagError,
    errors: cagErrors,
  } = useAggregatedCollateralAgreements({ dealUuid: dealUuidByTileCode })

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isDealUuidFetching, isDealFetching, isCagFetching],
    errorValues: [isDealUuidError, isDealError, isCagError],
    errorDetails: [...dealUuidErrors, ...cagErrors, dealError],
    tileId,
  })

  const sourcePathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?dataSource=newBusiness&working-version=true' : ''),
    [isWorkingVersionDataTile],
  )
  return useMemo(() => {
    if (isSomeValueLoading || isSomeValueError) {
      return {
        isLoading: isSomeValueLoading,
        isError: isSomeValueError,
        error,
      }
    }
    return {
      isLoading: false,
      isError: false,
      data: {
        ...aggregatedCollateralAgreements,
        sourceRender: { dealDisplayId: dealData?.dealId, sourcePathSuffix },
      },
    }
  }, [
    isSomeValueLoading,
    isSomeValueError,
    aggregatedCollateralAgreements,
    dealData?.dealId,
    sourcePathSuffix,
    error,
  ])
}

export default useCollateralAgreements
