import {
  FlexBoxJustifyContent,
  Icon,
  Label,
  ObjectStatus,
  TableGrowingMode,
  TableRowType,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/covenants/covenants-table/CovenantsTable.module.css'
import useCheckCycleTranslations from 'components/domains/deals/covenants/covenants-table/useCheckCycleTranslations'
import LimitCell from 'components/domains/deals/covenants/shared/LimitCell'
import { useGetAndAppendWorkingVersionSearchParam } from 'components/domains/deals/useGetAndAppendWorkingVersionSearchParam'
import ReferenceEntities from 'components/ui/data/ReferenceEntities'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { DealContext } from 'routes/deals/DealContext'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import paths from 'routes/paths'

export const covenantLimitProps = PropTypes.shape({
  value: PropTypes.number,
  unit: PropTypes.string,
  currencyCode: PropTypes.string,
})

const covenantProps = PropTypes.shape({
  covenantUuid: PropTypes.string,
  covenantDefinition: PropTypes.shape({}),
  status: PropTypes.string,
  currencyCode: PropTypes.string,
  referenceEntity: PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
  }),
  softLimit: covenantLimitProps,
  hardLimit: covenantLimitProps,
  loanSizing: PropTypes.shape({
    value: PropTypes.number,
  }),
})

const covenantsTableProps = {
  columnDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      columnKey: PropTypes.string.isRequired,
      sortingDisabled: PropTypes.bool,
      additionalColumnCellProperties: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        style: PropTypes.object,
        className: PropTypes.string,
        waitForDefine: PropTypes.bool,
      }),
      renderColumnContent: PropTypes.func,
      title: PropTypes.string,
      onClick: PropTypes.func,
      wrapText: PropTypes.bool,
      alignment: PropTypes.oneOf([
        FlexBoxJustifyContent.Start,
        FlexBoxJustifyContent.Center,
        FlexBoxJustifyContent.End,
      ]),
      filter: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  saveCurrentColumnDefinitions: PropTypes.func,
  covenantsData: PropTypes.arrayOf(covenantProps).isRequired,
  orderField: PropTypes.string,
  orderDirection: PropTypes.string,
  onSortingChanged: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
  isAllowedToReadCovenants: PropTypes.bool.isRequired,
  loadMore: PropTypes.func,
}

export const columnKeyToCovenantDataMap = {
  covenant: 'covenantDefinition.name',
  type: 'covenantDefinition.type',
  'reference-entity': 'referenceEntity.type',
  'monitoring-reference-type': 'monitoringReferenceEntity.type',
  'monitoring-reference': 'monitoringReferenceEntity.id',
  'test-date': 'dueDate.keyDate',
  'target-delivery-date': 'dueDate.targetDeliveryDate',
  'last-checked': 'lastChecked',
  status: 'status',
  'check-cycle': 'checkCycleOrder',
  'soft-limit': 'softLimit.value',
  'hard-limit': 'hardLimit.value',
}

const CovenantsTable = ({
  columnDefinitions,
  saveCurrentColumnDefinitions,
  covenantsData,
  orderField,
  orderDirection,
  onSortingChanged,
  pagination,
  isAllowedToReadCovenants,
  loadMore,
}) => {
  const {
    deal: { displayId: dealDisplayId },
  } = useContext(DealContext)
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.covenants' })
  const { t: tNoPrefix } = useTranslation('translation')
  const { format: formatDate } = useShortDateFormatter()
  const { getFormattedCheckCycle } = useCheckCycleTranslations()

  const { appendWorkingVersionSearchParam } = useGetAndAppendWorkingVersionSearchParam()
  const showMoreButton = pagination.limit < pagination.total
  const growingMode = showMoreButton ? TableGrowingMode.Button : TableGrowingMode.None
  const growingButtonText = tNoPrefix('components.ui.tables.sorted-tables.growing-button-text')
  const growingButtonSubtext = `[ ${pagination.limit} / ${pagination.total} ]`

  const MonitoringReferenceTypeTranslations = {
    DEAL: tNoPrefix('pages.deals.table.deal'),
    BUSINESSPARTNER: t('business-partner'),
  }

  const tableData = covenantsData?.map(
    ({
      covenantUuid,
      covenantDefinition = {},
      status,
      currencyCode,
      referenceEntity = {},
      monitoringReferenceEntity = {},
      checkCycle = {},
      lastChecked = '',
      dueDate = {},
      softLimit = {},
      hardLimit = {},
      loanSizing = {},
    }) => ({
      rowKey: `${covenantUuid}`,
      rowProperties: {
        navigationUrl: isAllowedToReadCovenants
          ? appendWorkingVersionSearchParam(
              `/${paths.deals}/${dealDisplayId}/${dealDetailPaths.covenants}/${covenantUuid}`,
            )
          : undefined,
        type: isAllowedToReadCovenants ? TableRowType.Active : TableRowType.Inactive,
        'covenant-uuid': covenantUuid,
      },
      covenant: {
        value: `${covenantDefinition.name} ${covenantUuid}`, // so it is possible to filter for both
        cellComponent: (
          <Label>
            <b>{covenantDefinition.name}</b>
          </Label>
        ),
      },
      type: {
        value: `${covenantDefinition.type}`,
        cellComponent: (
          <Label>
            {covenantDefinition.type === 'FINANCIAL' && <b> {t('financial')}</b>}
            {covenantDefinition.type === 'NON_FINANCIAL' && <b> {t('non-financial')}</b>}
          </Label>
        ),
      },
      'reference-entity': {
        value: referenceEntity.ids,
        cellComponent: <ReferenceEntities type={referenceEntity.type} ids={referenceEntity.ids} />,
      },
      status: {
        value: status,
        cellComponent: (
          <ObjectStatus inverted state={status === 'ACTIVE' ? ValueState.Success : ValueState.None}>
            <div className={styles.capitalize}>{status}</div>
          </ObjectStatus>
        ),
      },
      'monitoring-reference-type': {
        cellComponent: MonitoringReferenceTypeTranslations[monitoringReferenceEntity?.type],
      },
      'monitoring-reference': {
        value: monitoringReferenceEntity?.id,
        cellComponent: (
          <ReferenceEntities
            type={monitoringReferenceEntity?.type} //can be DEAL or BUSINESSPARTNER
            ids={[monitoringReferenceEntity?.id]}
          />
        ),
      },
      'test-date': {
        cellComponent: formatDate(dueDate?.keyDate),
      },
      'target-delivery-date': {
        cellComponent: formatDate(dueDate?.targetDeliveryDate),
      },
      'check-cycle': {
        cellComponent: getFormattedCheckCycle({
          number: checkCycle?.number,
          cycle: checkCycle?.cycle,
        }),
      },
      'last-checked': {
        cellComponent: formatDate(lastChecked),
      },
      'soft-limit': {
        value: softLimit?.value,
        cellComponent: (
          <LimitCell
            unit={softLimit?.unit}
            value={softLimit?.value}
            currencyCode={softLimit?.currencyCode}
            threshold={softLimit?.threshold}
          />
        ),
      },
      'hard-limit': {
        value: hardLimit?.value,
        cellComponent: (
          <LimitCell
            unit={hardLimit?.unit}
            value={hardLimit?.value}
            currencyCode={hardLimit?.currencyCode}
            threshold={hardLimit?.threshold}
          />
        ),
      },
      'loan-sizing': {
        value: loanSizing?.value,
        cellComponent: (
          <LimitCell
            unit={covenantDefinition?.unit}
            value={loanSizing?.value}
            currencyCode={currencyCode}
            threshold={loanSizing?.threshold}
          />
        ),
      },
      arrow: {
        cellComponent: isAllowedToReadCovenants && <Icon name="slim-arrow-right" />,
      },
    }),
  )

  const customOrderCallback = (columnKey, attributeOrderDirection) => {
    const newOrderField = columnKeyToCovenantDataMap[columnKey]
    onSortingChanged(newOrderField, attributeOrderDirection)
  }

  const sortingColumnKey = Object.keys(columnKeyToCovenantDataMap).find(
    (key) => columnKeyToCovenantDataMap[key] === orderField,
  )

  const additionalTableProperties = () => ({
    className: styles['inner-table'],
  })

  return (
    <div
      className={`${styles['table-wrapper']} ${
        !isAllowedToReadCovenants && styles['default-cursor']
      }`}
    >
      <SortedTable
        columnDefinitions={columnDefinitions}
        saveCurrentColumnDefinitions={saveCurrentColumnDefinitions}
        customOrderCallback={customOrderCallback}
        tableData={tableData}
        toolbarConfig={{
          title: t('subtitle'),
          sorting: {
            columnKey: sortingColumnKey,
            isSortingAscending: orderDirection === 'asc',
          },
        }}
        noDataText={tNoPrefix('components.table.no-data')}
        paginationConfig={{
          growing: growingMode,
          growingButtonText: growingButtonText,
          growingButtonSubtext: growingButtonSubtext,
          totalNumberOfItems: pagination?.total,
          loadMore: loadMore,
        }}
        additionalTableProperties={additionalTableProperties()}
      />
    </div>
  )
}

CovenantsTable.propTypes = covenantsTableProps
export default CovenantsTable
