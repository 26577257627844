import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Icon,
  Popover,
  PopoverHorizontalAlign,
  PopoverPlacementType,
  Title,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useId, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { eventStatus as eventStatusEnum, taskStatus as taskStatusEnum } from 'api/events/status'
import styles from 'components/domains/business-events-and-tasks/common/DueDateWarningIcon.module.css'
import {
  dueDateWarningLevel,
  useGetDueDateWarningInfo,
} from 'components/domains/business-events-and-tasks/common/useGetDueDateWarningInfo'

const translationKeyPrefix = 'components.business-events-and-tasks.common.due-date-warning'

const dueDateWarningIconName = {
  [dueDateWarningLevel.approaching]: 'alert',
  [dueDateWarningLevel.reached]: 'error',
}

const isEventClosedState = (eventStatus) =>
  eventStatus === eventStatusEnum.completed || eventStatus === eventStatusEnum.aborted

const isTaskClosedState = (taskStatus) =>
  taskStatus === taskStatusEnum.completed || taskStatus === taskStatusEnum.aborted

/**
 *  @typedef {PropTypes.InferProps<typeof DueDateWarningIcon.propTypes>} DueDateWarningIcon
 */
const DueDateWarningIcon = ({ dueDate, className, eventStatus, taskStatus }) => {
  const idSuffix = useId()
  const id = useMemo(() => `due-date-warning-icon-${idSuffix}`, [idSuffix])
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const { warningLevel, days } = useGetDueDateWarningInfo(new Date(), dueDate)
  const onIconMouseEnter = useCallback(() => setIsPopoverOpen(true), [setIsPopoverOpen])
  const onIcenMouseLeave = useCallback(() => setIsPopoverOpen(false), [setIsPopoverOpen])
  const iconClassName = useMemo(
    () => [className, styles[dueDateWarningIconName[warningLevel]]].filter((a) => a).join(' '),
    [className, warningLevel],
  )
  const { t } = useTranslation('translation', {
    keyPrefix: translationKeyPrefix,
  })

  if (
    isEventClosedState(eventStatus) ||
    isTaskClosedState(taskStatus) ||
    warningLevel === dueDateWarningLevel.none
  ) {
    return
  }

  return (
    <>
      <Icon
        id={id}
        name={dueDateWarningIconName[warningLevel]}
        className={iconClassName}
        onMouseEnter={onIconMouseEnter}
        onMouseLeave={onIcenMouseLeave}
      />
      {createPortal(
        <Popover
          opener={id}
          header={
            <FlexBox
              direction={FlexBoxDirection.Row}
              justifyContent={FlexBoxJustifyContent.Start}
              alignItems={FlexBoxAlignItems.Center}
              className={styles['popover-title']}
            >
              <Icon
                name={dueDateWarningIconName[warningLevel]}
                className={styles[dueDateWarningIconName[warningLevel]]}
              />
              <Title className={styles['popover-title']}>{t(`title.${warningLevel}`)}</Title>
            </FlexBox>
          }
          placementType={PopoverPlacementType.Bottom}
          horizontalAlign={PopoverHorizontalAlign.Center}
          open={isPopoverOpen}
        >
          {t(`message.${warningLevel}`, { count: days })}
        </Popover>,
        document.body,
      )}
    </>
  )
}

DueDateWarningIcon.propTypes = {
  dueDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  className: PropTypes.string,
  eventStatus: PropTypes.oneOf(Object.values(eventStatusEnum)),
  taskStatus: PropTypes.oneOf(Object.values(taskStatusEnum)),
}

export default DueDateWarningIcon
