import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDiscardMinutesDecision = (mutationOptions) => {
  const { delete: deleteCall } = useAccessTokenRequest()
  return useMutation(
    ({ decisionStageId, eventId }) =>
      deleteCall({
        path: `/events/${eventId}/decision-stages/${decisionStageId}/minutes/decision`,
      }),
    mutationOptions,
  )
}

export default useDiscardMinutesDecision
