import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import CreateWorkingVersionDealEventDialogFirstStage from 'components/domains/deals/header-actions/working-version-event-create/CreateWorkingVersionDealEventDialogFirstStage'
import CreateWorkingVersionDealEventDialogSecondStage from 'components/domains/deals/header-actions/working-version-event-create/CreateWorkingVersionDealEventDialogSecondStage'
import useHandleWorkingVersionEventCreation from 'components/domains/deals/header-actions/working-version-event-create/useHandleWorkingVersionEventCreation'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import useCreateDealWorkingVersionEvent from 'hooks/services/deals/deal-adjustment/events/useCreateDealWorkingVersionEvent'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'
import paths from 'routes/paths'

const CreateWorkingVersionDealEventDialog = ({ setIsOpen, preselectedEntity }) => {
  const [isFirstStageOpen, setIsFirstStageOpen] = useState(true)
  const [isSecondStageOpen, setIsSecondStageOpen] = useState(false)
  const [isConfirmBoxOpen, setIsConfirmBoxOpen] = useState(false)
  const [isPublishing, setIsPublishing] = useState(false)
  const [firstStageResult, setFirstStageResult] = useState({})
  const [isErrorWindowWithDetailsOpen, setIsErrorWindowWithDetailsOpen] = useState(false)
  const [errorDetails, setErrorDetails] = useState(undefined)

  const { t } = useTranslation('translation', {
    keyPrefix: 'dialog.event-creation.deal-working-version',
  })

  // setIsOpen(false) destroys the dialog. When the user opens the dialog again afterwards, the state is reset correctly.
  // That's why it is important, that the isOpen states of the individual levels are maintained correctly at all time.
  useEffect(() => {
    if (!isFirstStageOpen && !isSecondStageOpen && !isConfirmBoxOpen) {
      setIsOpen(false)
    }
  }, [isConfirmBoxOpen, isFirstStageOpen, isSecondStageOpen, setIsOpen])

  const createEvent = useCreateDealWorkingVersionEvent({
    onSuccess: ({ data: { event: { id } = {} } = {} } = {}) => {
      id && window.open(`/${paths.businessEventsAndTasks}/${paths.businessEvents}/${id}`, '_blank')
      setIsOpen(false)
    },
    onError: async (error) => {
      const errorMessageString = await formatHookError(error)
      setErrorDetails(errorMessageString)
      setIsErrorWindowWithDetailsOpen(true)
    },
  })

  const handleContinue = ({ selectedEntityType, selectedEntityId, selectedEventType }) => {
    setFirstStageResult({
      entityType: selectedEntityType,
      entityId: selectedEntityId,
      eventType: selectedEventType,
    })
    setIsFirstStageOpen(false)
    setIsSecondStageOpen(true)
  }

  const handleCreate = useHandleWorkingVersionEventCreation({
    createEventMutation: createEvent,
    setIsPublishing,
    setIsSecondStageOpen,
    firstStageResult,
    setIsConfirmBoxOpen,
  })

  return (
    <>
      <CreateWorkingVersionDealEventDialogFirstStage
        isOpen={isFirstStageOpen}
        setIsOpen={setIsFirstStageOpen}
        preselectedEntity={preselectedEntity}
        handleContinue={handleContinue}
      />
      {(isSecondStageOpen || isConfirmBoxOpen) && (
        <CreateWorkingVersionDealEventDialogSecondStage
          isOpen={isSecondStageOpen}
          setIsOpen={setIsSecondStageOpen}
          deal={{
            displayId: preselectedEntity.entityDisplayId,
            name: preselectedEntity.entityName,
          }}
          event={firstStageResult}
          handleCreate={handleCreate}
          isPublishing={isPublishing}
        />
      )}
      {createPortal(
        <ErrorMessageBoxWithExpandableDetails
          messageSummary={t('publish.unexpected-error-message')}
          messageDetails={errorDetails}
          isOpen={isErrorWindowWithDetailsOpen}
          onClose={() => {
            setIsPublishing(false)
            setIsErrorWindowWithDetailsOpen(false)
          }}
        />,
        document.body,
      )}
    </>
  )
}

CreateWorkingVersionDealEventDialog.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  preselectedEntity: PropTypes.shape({
    entityDisplayId: PropTypes.string.isRequired,
    entityName: PropTypes.string.isRequired,
    entityId: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
    isWorkingVersion: PropTypes.bool.isRequired,
  }).isRequired,
}

export default CreateWorkingVersionDealEventDialog
