import {
  BusyIndicator,
  BusyIndicatorSize,
  Icon,
  Modals,
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  FlexBoxAlignItems,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { isNotFoundError } from 'api/requests'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import useUserFavoriteCreate from 'hooks/services/user-profile/useUserFavoriteCreate'
import useUserFavoriteDelete from 'hooks/services/user-profile/useUserFavoriteDelete'
import useUserFavoriteGet, {
  getUserFavoriteGetKey,
} from 'hooks/services/user-profile/useUserFavoriteGet'
import { getUserFavoritesGetKey } from 'hooks/services/user-profile/useUserFavoritesGet'

const someRequestInState = (state, requests) => requests.some((request) => request?.[state])
const MAX_AMOUNT_ERRORS = 3
const errorCountBelowMaxAmountAndNotIsNotFound = (count, error) =>
  !isNotFoundError(error) && count <= MAX_AMOUNT_ERRORS

/**
 * User favorite icon
 *
 * @param entityId {string} cwp entity id, e.g., dealUuid or propertyUuid
 * @param entityType {string} cwp entity type, i.e., DEAL, MARKET, PROPERTY
 * @param refetchFavorites {boolean} indicates whether the get favorites query should be invalidated, default value is true
 */
const UserFavoriteIcon = ({ entityId, entityType, triggerRefetech = true }) => {
  /**
   * Toast and messagebox hooks
   */
  const showToast = Modals.useShowToast()
  const { t } = useTranslation('translation')
  const renderToast = useCallback((msg) => showToast({ children: msg }), [showToast])

  const showMessageBox = useShowMessageBox()

  const renderErrorMessageBoxWhenNotNotFoundError = (error, errorText) => {
    if (!isNotFoundError(error)) {
      showMessageBox(
        {
          type: MessageBoxTypes.Error,
          titleText: t('favorite-error-title'),
          children: errorText,
          actions: [MessageBoxActions.Close],
        },
        document.body,
      )
    }
  }

  /**
   * Get user favorite
   */
  const getFavoriteQueryOptions = {
    retry: (failureCount, error) =>
      // enable retry only for other errors than 404 (not found) and when failure below threshold count
      errorCountBelowMaxAmountAndNotIsNotFound(failureCount, error),
  }
  const getFavorite = useUserFavoriteGet({ entityId }, getFavoriteQueryOptions)
  const favoriteDoesNotExist = getFavorite.isError ? isNotFoundError(getFavorite.error) : false
  const favoriteDoesExist = !favoriteDoesNotExist && !isNil(getFavorite.data)

  /**
   * Create and delete user favorite
   */
  const queryClient = useQueryClient()
  const invalidateGetFavoriteQuery = useCallback(() => {
    const invalidateFavoriteGetQueryOptions = {
      queryKey: getUserFavoriteGetKey(entityId),
      exact: true,
    }
    return queryClient.invalidateQueries(invalidateFavoriteGetQueryOptions)
  }, [entityId, queryClient])

  const invalidateGetFavoritesQuery = useCallback(() => {
    const invalidateFavoriteGetQueryOptions = {
      queryKey: getUserFavoritesGetKey(entityType),
      exact: true,
    }
    return queryClient.invalidateQueries(invalidateFavoriteGetQueryOptions)
  }, [entityType, queryClient])

  // always refetch get query after create or delete
  const mutateFavoriteQueryOptions = {
    onSettled: () => {
      invalidateGetFavoriteQuery()

      if (triggerRefetech) {
        invalidateGetFavoritesQuery()
      }
    },
  }
  const createFavorite = useUserFavoriteCreate({
    ...mutateFavoriteQueryOptions,
    onSuccess: () => renderToast(t('favorited')),
    onError: (error) => renderErrorMessageBoxWhenNotNotFoundError(error, t('favorited-error-text')),
  })
  const deleteFavorite = useUserFavoriteDelete({
    ...mutateFavoriteQueryOptions,
    onSuccess: () => renderToast(t('unfavorited')),
    onError: (error) =>
      renderErrorMessageBoxWhenNotNotFoundError(error, t('unfavorited-error-text')),
  })

  const createOrDeleteFavorite = useCallback(() => {
    if (favoriteDoesNotExist) {
      createFavorite.mutate({ entityId, entityType })
    } else if (favoriteDoesExist) {
      const userFavorite = getFavorite.data
      deleteFavorite.mutate(userFavorite.id)
    }
  }, [
    getFavorite.data,
    favoriteDoesNotExist,
    favoriteDoesExist,
    createFavorite,
    entityId,
    entityType,
    deleteFavorite,
  ])

  /**
   * Render busy indicator when loading
   */
  const requests = [getFavorite, createFavorite, deleteFavorite]
  if (someRequestInState('isLoading', requests)) {
    return (
      <FlexBox
        direction={FlexBoxDirection.Column}
        justifyContent={FlexBoxJustifyContent.Center}
        alignItems={FlexBoxAlignItems.Center}
      >
        <BusyIndicator size={BusyIndicatorSize.Small} active delay={0} />
      </FlexBox>
    )
  }

  /**
   * Render favorite icon
   */
  const iconToRender = favoriteDoesNotExist ? 'unfavorite' : 'favorite'
  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      justifyContent={FlexBoxJustifyContent.Center}
      alignItems={FlexBoxAlignItems.Center}
    >
      <Icon name={iconToRender} onClick={createOrDeleteFavorite} interactive={true} />
    </FlexBox>
  )
}

UserFavoriteIcon.propTypes = {
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.oneOf(['DEAL', 'PROPERTY', 'MARKET', 'BUSINESS_PARTNER']).isRequired,
  triggerRefetech: PropTypes.bool,
}

export default UserFavoriteIcon
