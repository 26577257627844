import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import FormattedTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/rich-text-editor/FormattedTextView'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/borrower/BorrowerOverviewTile.module.css'

const AnnualReviewDealOverviewBorrowerOverviewAppreciation = ({ tileId, selectedDealIndex }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.bp-borrower-overview.appreciation',
  })
  const { data: tileData } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const { multipleAppreciation } = tileData ?? {}

  const appreciation = multipleAppreciation?.[selectedDealIndex]

  const content = useMemo(() => {
    if (isEmpty(appreciation)) {
      return (
        <FlexBox
          className={styles.listCardEmptyContent}
          alignItems={FlexBoxAlignItems.Center}
          justifyContent={FlexBoxJustifyContent.Center}
        >
          {t('empty')}
        </FlexBox>
      )
    }
    return <FormattedTextView formattedText={appreciation} />
  }, [appreciation, t])

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Text className={styles.listCardTitle}>{t('title')}</Text>
      {content}
    </FlexBox>
  )
}

AnnualReviewDealOverviewBorrowerOverviewAppreciation.propTypes = {
  tileId: PropTypes.string.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default AnnualReviewDealOverviewBorrowerOverviewAppreciation
