import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * Get a staff members by their ids
 */
const useStaffMemberByIds = (ids, options) => {
  const params = new URLSearchParams()
  const idsString = Array.from(new Set(ids ?? [])).join(',')
  params.append('searchType', 'IDS')
  params.append('q', idsString)
  const result = useCamelizedResponse(
    useRequest({
      path: `/users?${params.toString()}`,
      keys: ['users', 'IDS', idsString],
      options: {
        enabled: !!idsString,
        ...options,
      },
    }),
  )
  return useMemo(() => ({ ...result, data: result.data?.staffMembers }), [result])
}

export default useStaffMemberByIds
