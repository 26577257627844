import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useAssessments = (businessPartnerId, options = {}, requestOptions = {}) => {
  const response = useCamelizedResponse(
    useRequest({
      path: `/businesspartners/${businessPartnerId}/assessments`,
      keys: ['businesspartners', businessPartnerId, 'assessments'],
      options,
      requestOptions,
    }),
  )
  const returnValue = { ...response }
  if (response.data?.AllowedOperations) {
    returnValue.data.allowedOperations = [...response.data.AllowedOperations]
    delete returnValue.data.AllowedOperations
  }
  return returnValue
}
