import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useUnassignPropertyToMarket = ({ onSuccess, onError }) => {
  const { delete: deleteCall } = useAccessTokenRequest()
  return useMutation(
    async ({ propertyId, marketId }) => {
      const { data } = await deleteCall({
        path: `/markets/${marketId}/properties/${propertyId}`,
      })
      return data
    },
    { onSuccess, onError },
  )
}
