import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * Returns all external ids for a property
 * @returns {{isLoading: boolean, isError: boolean, data: ExternalIds}}
 */
export const usePropertyExternalIds = ({ propertyUuid } = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/properties/${propertyUuid}/external-ids`,
      translated: true,
      keys: ['properties', propertyUuid, 'external-ids'],
    }),
  )
