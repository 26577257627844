import { DynamicPage } from '@fioneer/ui5-webcomponents-react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import PropertyAddressCard from 'components/domains/properties/general-information/address/PropertyAddressCard'
import PropertyExternalIdCard from 'components/domains/properties/general-information/external-ids/PropertyExternalIdCard'
import PropertyInfoCard from 'components/domains/properties/general-information/info/PropertyInfoCard'
import PropertyKeyInternalContactsCard from 'components/domains/properties/overview/keyInternalContacts/PropertyKeyInternalContactsCard'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Header from 'components/ui/page/Header'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyInformationWithoutNeed2Know = () => {
  const { t } = useTranslation()
  const { property } = useContext(PropertyContext)

  const subtitle = <EntityTypeAndIdWithClipboard id={property.id} />

  const generateBreadcrumbs = () => {
    if (!property?.id) return []

    const propertiesLink = '/properties'
    return [
      { text: t('pages.properties.title'), href: propertiesLink },
      { text: property.description },
    ]
  }

  return (
    <DynamicPage
      headerTitle={
        <Header
          title={property.description}
          id={property.id}
          subtitle={subtitle}
          breadcrumbs={generateBreadcrumbs()}
        />
      }
      showHideHeaderButton={false}
    >
      <CWPLayout>
        <PropertyInfoCard allowUserToEdit={false} />
        <PropertyAddressCard allowUserToEdit={false} />
        <PropertyExternalIdCard />
        <PropertyKeyInternalContactsCard allowUserToEdit={false} />
      </CWPLayout>
    </DynamicPage>
  )
}

export default PropertyInformationWithoutNeed2Know
