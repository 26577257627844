import { FlexBox, FlexBoxJustifyContent, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import useFormatPercentageOrValueWithUnit from 'components/domains/properties/monitoring/useFormatPercentageOrValueWithUnit'
import { SETTINGS } from 'components/ui/charts/kpi-chart/kpiChartUtil'

const KpiChartStartEndValueLabel = ({ kpiUnit, startValue, endValue }) => {
  const formatPercentageOrValueWithUnit = useFormatPercentageOrValueWithUnit({
    numberFormatterProps: {
      notation: 'compact',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      trailingZeroDisplay: 'stripIfInteger',
    },
  })
  const getDisplayValue = (value) =>
    formatPercentageOrValueWithUnit({
      value: +value,
      unit: kpiUnit,
    })

  if (startValue === undefined) {
    return <></>
  }

  if (endValue === undefined) {
    return (
      <FlexBox justifyContent={FlexBoxJustifyContent.Start}>
        <Text
          style={{
            textAlign: 'center',
            color: startValue >= 0 ? SETTINGS.GREEN : SETTINGS.RED,
          }}
        >
          {getDisplayValue(startValue)}
        </Text>
      </FlexBox>
    )
  }

  return (
    <FlexBox style={{ width: '100%' }} justifyContent={FlexBoxJustifyContent.SpaceBetween}>
      <Text style={{ color: startValue >= 0 ? SETTINGS.GREEN : SETTINGS.RED }}>
        {getDisplayValue(startValue)}
      </Text>
      <Text
        style={{
          color: endValue >= 0 ? SETTINGS.GREEN : SETTINGS.RED,
        }}
      >
        {getDisplayValue(endValue)}
      </Text>
    </FlexBox>
  )
}

KpiChartStartEndValueLabel.propTypes = {
  kpiUnit: PropTypes.string.isRequired,
  startValue: PropTypes.number,
  endValue: PropTypes.number,
}

export default KpiChartStartEndValueLabel
