export const HYPHEN_PLACEHOLDER = '-'
export const CELLS_IN_MULTI_COLUMN = 3

export const VIEW_OPTION_PERIODS = {
  ANNUALLY: 'annually',
  MONTHLY: 'monthly',
}

export const VIEW_OPTION_KPI_TYPE = {
  CONTRACTED_RENT: 'contracted_rent',
  CURRENT_RET: 'current_rent',
}
