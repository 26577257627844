import { FlexBox, FlexBoxDirection, Label, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-selection/AnnualReviewDealOverviewPropertySelect.module.css'

const AnnualReviewPropertySelectPdfView = ({ id, description }) => {
  const { t } = useTranslation('decisionPaper')
  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.annualReviewSelectionWrapper}>
      <Label showColon>{t('app.entity.type.name.property')}</Label>
      <Text>{`${description ?? '-'} (${id ?? '-'})`}</Text>
    </FlexBox>
  )
}

AnnualReviewPropertySelectPdfView.propTypes = {
  id: PropTypes.string,
  description: PropTypes.string,
}

export default AnnualReviewPropertySelectPdfView
