import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useDealHeaders = (dealId, options = { enabled: !!dealId }) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/${dealId}/headers`,
      translated: true,
      useCache: true,
      keys: ['deals', dealId, 'headers'],
      options,
    }),
  )
