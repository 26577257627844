import _ from 'lodash'
import { useRequest } from 'hooks/services/baseService'

const mapGeolocationValues = (geoLocations) => ({
  latitude: `${geoLocations?.latitude}`,
  longitude: `${geoLocations?.longitude}`,
})
/**
 * Returns the geolocation value for a property.
 * Example given for response data:
 * {
 *  latitude: "10.12341243",
 *  longitude: "40.1231123",
 * }
 *
 * @returns {{isLoading: boolean, isError: boolean, data: geolocation}}
 *
 */
export const useGeolocation = ({ propertyUuid }) => {
  const request = useRequest({
    path: `/properties/${propertyUuid}/address/geocode`,
    keys: ['properties', propertyUuid, 'address', 'geocode'],
  })
  if (!request.data || _.isEqual(request.data, {})) {
    return { ...request, data: undefined }
  }
  return { ...request, data: mapGeolocationValues(request.data) }
}

export const DEFAULT_GEOLOCATION = {
  latitude: '0',
  longitude: '0',
}
