import { useCallback } from 'react'
import useCreateBusinessPartnerRating from 'hooks/services/business-partners/ratings/useCreateBusinessPartnerRating'
import useDeleteBusinessPartnerRating from 'hooks/services/business-partners/ratings/useDeleteBusinessPartnerRating'
import useUpdateBusinessPartnerRating from 'hooks/services/business-partners/ratings/useUpdateBusinessPartnerRating'

/**
 * @param {object} param
 * @param {number} param.businessPartner
 * @param {function} param.onError
 * @param {function} param.onCreateSuccess
 * @param {function} param.onUpdateSuccess
 */
export const useSaveRatings = ({
  businessPartnerId,
  onError,
  onCreateSuccess,
  onUpdateSuccess,
}) => {
  const { mutateAsync: createRatingAsync } = useCreateBusinessPartnerRating({
    businessPartnerId,
    onSuccess: onCreateSuccess,
    onError,
  })
  const { mutate: updateRating } = useUpdateBusinessPartnerRating({
    businessPartnerId,
    onSuccess: onUpdateSuccess,
    onError,
  })
  const { mutateAsync: deleteRatingAsync } = useDeleteBusinessPartnerRating({
    businessPartnerId,
    onError,
  })

  const saveRating = useCallback(
    async (ratingToSave) => {
      const isSimpleUpdate =
        ratingToSave.methodId === ratingToSave.original.methodId &&
        ratingToSave.validTo === ratingToSave.original.validTo
      if (isSimpleUpdate) {
        updateRating(ratingToSave)
      } else {
        // since methodId and validTo are are used to identify the rating we cannot change them directly
        // so instead we have to delete the old rating and create a new one
        try {
          await deleteRatingAsync({
            methodId: ratingToSave.original.methodId,
            validTo: ratingToSave.original.validTo,
          })
          await createRatingAsync({
            methodId: ratingToSave.methodId,
            validTo: ratingToSave.validTo,
            pdPercentage: ratingToSave.pdPercentage,
            ratingGrade: ratingToSave.ratingGrade,
            validFrom: ratingToSave.validFrom,
          })
        } catch (e) {
          onError(e)
        }
      }
    },
    [updateRating, deleteRatingAsync, createRatingAsync, onError],
  )

  return saveRating
}
