import { FlexBox, FlexBoxDirection, Label, Text } from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { ErrorDataUnavailableInCell } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/errors/ErrorDataUnavailableInCell'
import SmallLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/loading/SmallLoadingWrapper'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/MoneyCell.module.css'
import {
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'

const propTypes = {
  /**
   * The amount and currency to be shown.
   *
   * The currency must be valid / existing.
   */
  primary: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }),
  /**
   * The second monetary value to be shown in grayish font.
   *
   * Usually the primary value converted to headquarter currency.
   *
   * The currency must be valid / existing.
   */
  secondary: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }),
  /**
   * The percentage value to be shown.
   *
   * The value will be multiplied by 100.
   *
   * A label should be provided (usually "Share" or "Rate"), but is not mandatory.
   */
  percentage: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string,
  }),
  /**
   * Options to modify how the Cell will look like.
   *
   * - `isBold` Display the monetary values (!= the percentage) in bold font. Defaults to false.
   * - `forceAlignRight` Display the cell's content on the right. Defaults to false.
   * - `maximumFractionDigits` Defines the maximum digits of the percentage value. Defaults to 2.
   * - `minimumFractionDigits` Defines the minimum digits of the percentage value. Defaults to 2.
   */
  options: PropTypes.shape({
    isBold: PropTypes.bool,
    forceAlignRight: PropTypes.bool,
    maximumFractionDigits: PropTypes.number,
    minimumFractionDigits: PropTypes.number,
  }),
  /** Defines whether a loading spinner is shown. */
  isLoading: PropTypes.bool,
  /** Defines whether a loading spinner is shown. */
  isFetching: PropTypes.bool,
  /** Defines whether an error is shown. */
  isError: PropTypes.bool,
  /** Allows to define a custom error component. Defaults to undefined so that `<ErrorDataUnavailableInCell />` is shown. */
  errorToDisplay: PropTypes.node,
  /** Defines additional styling to the outmost div of the cell's content. */
  className: PropTypes.string,
}

/**
 * Shared Money Cell for usage within the Responsive and the Analytical Table.
 *
 * It covers multiple use cases related to monetary values with these values converted to headquarter currency and/or a share/rate.
 *
 * @typedef Props
 * @property {Object} primary Information about the amount and currency to be shown.
 * @property {Number} primary.amount The amount.
 * @property {String} primary.currency The currency, must be a valid currency.
 * @property {Object} secondary Information about the second monetary value to be shown in grayish font. Usually the primary value converted to headquarter currency.
 * @property {Number} secondary.amount The amount in headquarter currency.
 * @property {String} secondary.currency The headquarter currency.
 * @property {Object} percentage Information about the percentage value.
 * @property {Number} percentage.value The percentage, will be multiplied by 100.
 * @property {String} percentage.label The label for the percentage, usually "Share" or "Rate".
 * @property {Object} options Options to modify how the Cell will look like.
 * @property {Boolean} options.isBold Display the monetary values (!= the percentage) in bold font. Defaults to false.
 * @property {Boolean} options.forceAlignRight Display the cell's content on the right. Defaults to false.
 * @property {Number} options.maximumFractionDigits Defines the maximum digits of the percentage value. Defaults to 2.
 * @property {Number} options.minimumFractionDigits Defines the minimum digits of the percentage value. Defaults to 2.
 * @property {Boolean} isLoading Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} isFetching Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} isError Defines whether an error is shown.
 * @property {React.ReactNode} errorToDisplay Allows to define a custom error component. Defaults to undefined so that <ErrorDataUnavailableInCell /> is shown.
 * @property {String} className Defines additional styling to the outmost div of the cell's content.
 */

/**
 * @param {Props} props Note for Storybook: cf. code for complete JSDoc
 */
const MoneyCell = ({
  primary: { amount: primaryAmount, currency: primaryCurrency } = {},
  primary,
  secondary: { amount: secondaryAmount, currency: secondaryCurrency } = {},
  percentage: { value: percentageValue, label: percentageLabel } = {},
  options: {
    isBold = false,
    forceAlignRight = false,
    maximumFractionDigits: maximumPercentageFractionDigits = 2,
    minimumFractionDigits: minimumPercentageFractionDigits = 2,
  } = {},
  isLoading = false,
  isFetching = false,
  isError = false,
  className,
  errorToDisplay,
}) => {
  const formatMoney = useCustomizableCurrencyFormatter()
  const formatFraction = useNumberFormatter({
    maximumFractionDigits: maximumPercentageFractionDigits,
    minimumFractionDigits: minimumPercentageFractionDigits,
    style: 'percent',
  })

  const renderContent = () => {
    if (isNil(primary)) {
      return <div />
    }

    return (
      <FlexBox
        direction={FlexBoxDirection.Column}
        className={compact([
          styles.contentWrapper,
          forceAlignRight && styles.forceAlignRight,
          className,
        ]).join(' ')}
      >
        <FlexBox direction={FlexBoxDirection.Column}>
          <span className={compact([isBold && styles.textBold]).join(' ')}>
            {formatMoney(primaryAmount, primaryCurrency, {
              currencyDisplay: 'code',
            })}
          </span>
          {!isNil(secondaryCurrency) && primaryCurrency !== secondaryCurrency && (
            <span className={compact([styles.textLabelColor, isBold && styles.textBold]).join(' ')}>
              {formatMoney(secondaryAmount, secondaryCurrency, {
                currencyDisplay: 'code',
              })}
            </span>
          )}
        </FlexBox>
        {!isNil(percentageValue) && (
          <span>
            {percentageLabel && (
              <>
                <Label showColon>{percentageLabel}</Label>&nbsp;
              </>
            )}
            <Text>{formatFraction(percentageValue)}</Text>
          </span>
        )}
      </FlexBox>
    )
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading || isFetching}
      isError={isError}
      error={errorToDisplay ?? <ErrorDataUnavailableInCell />}
      renderContent={renderContent}
    />
  )
}

MoneyCell.propTypes = propTypes

export default MoneyCell
