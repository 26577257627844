import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessObjectLink, {
  BusinessObjectLinkCustomRender,
} from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import Entity from 'components/ui/data/Entity'

const BusinessEventTableCell = ({
  rowId,
  eventName,
  entityId,
  entityType,
  eventDisplayId,
  errorCallback,
}) => {
  const { t } = useTranslation()

  const handleReferenceError = useCallback(() => errorCallback?.({ rowId }), [errorCallback, rowId])

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <div>
        <Entity name={eventName} /> {`(${eventDisplayId})`}
      </div>
      <FlexBox direction={FlexBoxDirection.Row}>
        {`${t('events.business-object')}`}
        <>&nbsp;</>
        <BusinessObjectLink
          entityId={entityId}
          entityType={entityType}
          errorCallback={handleReferenceError}
          customRender={BusinessObjectLinkCustomRender.singleLine}
        />
      </FlexBox>
      {`${t('events.business-object-type')} ${t(`events.entity-type.${entityType}`)}`}
    </FlexBox>
  )
}

BusinessEventTableCell.propTypes = {
  rowId: PropTypes.string,
  eventName: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  eventDisplayId: PropTypes.string.isRequired,
  errorCallback: PropTypes.func,
  resetErrorCallback: PropTypes.func,
}

export default BusinessEventTableCell
