import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'

const EMPTY = ''

const CovenantSelect = ({ value, onChange, options = [], ...props }) => (
  <Select onChange={(event) => onChange(event.detail.selectedOption.value)} {...props}>
    <Option value={EMPTY} selected={isNil(value)}>
      {EMPTY}
    </Option>
    {options.map(({ covenantUuid, name }) => (
      <Option key={covenantUuid} value={covenantUuid} selected={value === covenantUuid}>
        {`${name}`}
      </Option>
    ))}
  </Select>
)

CovenantSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({ covenantUuid: PropTypes.string, name: PropTypes.string }),
  ),
}

export default CovenantSelect
