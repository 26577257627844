import { Button, ButtonDesign, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { conditionsStatusTypes } from 'api/conditions/conditions'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import useDeleteConditions from 'hooks/services/conditions/useDeleteConditions'
import { reset } from 'redux/slices/conditions/conditionsTableSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const atLeastOneConditionInStatusDone = (conditions) =>
  Object.values(conditions).some(({ status }) => status === conditionsStatusTypes.done)

const DeleteSelectedConditionsButton = ({ disabled, conditionType }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.buttons.delete-external-condition',
  })
  const {
    entityRef: { entityType, entityId },
  } = useContext(ConditionsContext)
  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { mutate: deleteConditions } = useDeleteConditions()

  const selectedConditionIds = useSelector(
    (state) => state.conditions.conditionsTable.selectedRows.selectedConditionIds,
  )

  const numberOfSelectedConditions = useMemo(
    () => Object.keys(selectedConditionIds).length,
    [selectedConditionIds],
  )

  const onDeleteSuccess = useCallback(() => {
    queryClient.invalidateQueries(['conditions', conditionType, entityType, entityId])
    showToast({ children: t('success') })
    dispatch(reset())
  }, [queryClient, conditionType, entityType, entityId, showToast, t, dispatch])

  const onDeleteError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('error'),
    })
  }, [showMessageBox, t])

  const deleteSelectedConditions = useCallback(() => {
    const conditionIds = Object.keys(selectedConditionIds)
    deleteConditions(
      { conditionType: conditionType, conditionIds },
      { onSuccess: onDeleteSuccess, onError: onDeleteError },
    )
  }, [deleteConditions, selectedConditionIds, onDeleteSuccess, onDeleteError, conditionType])

  const onDeleteButtonClick = useCallback(() => {
    showMessageBox({
      titleText: tNoPrefix('buttons.delete'),
      type: MessageBoxTypes.Warning,
      children: t('confirmation', { numberOfSelectedConditions }),
      actions: [
        <Button
          key={'button_confirm'}
          design={ButtonDesign.Emphasized}
          onClick={deleteSelectedConditions}
        >
          {tNoPrefix('buttons.delete')}
        </Button>,
        <Button
          key="button_cancel"
          data-action={MessageBoxActions.Cancel}
          design={ButtonDesign.Transparent}
        >
          {tNoPrefix('buttons.cancel')}
        </Button>,
      ],
      draggable: true,
      resizable: true,
    })
  }, [showMessageBox, t, tNoPrefix, deleteSelectedConditions, numberOfSelectedConditions])

  const isDeleteButtonDisabled = useMemo(() => {
    if (
      disabled ||
      isEmpty(selectedConditionIds) ||
      atLeastOneConditionInStatusDone(selectedConditionIds)
    ) {
      return true
    }

    return false
  }, [disabled, selectedConditionIds])

  return (
    <>
      <Button
        disabled={isDeleteButtonDisabled}
        design={ButtonDesign.Transparent}
        onClick={onDeleteButtonClick}
      >
        {tNoPrefix('buttons.delete')}
      </Button>
    </>
  )
}

DeleteSelectedConditionsButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  conditionType: PropTypes.oneOf(['internal', 'external']).isRequired,
}

export default DeleteSelectedConditionsButton
