import {
  FlexBoxAlignItems,
  Icon,
  ObjectStatus,
  TableRowType,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import DealLink from 'components/domains/business-events-and-tasks/DealLink'
import { BusinessTypeCell } from 'components/domains/deals/collaterals/details/ReceivablesCells'
import CurrencyCell from 'components/domains/deals/deal-cashflow/results/CurrencyCell'
import EntityCell from 'components/ui/tables/cells/EntityCell'
import StatusCell from 'components/ui/tables/cells/StatusCell'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'
import {
  TrancheSearchTableColumns,
  TrancheSearchTableMode,
} from 'routes/deals/financing/shared-components/tranches-search-dialog/TrancheSearchTableEnums'
import styles from 'routes/deals/financing/shared-components/tranches-search-dialog/TranchesSearchTable.module.css'
import paths from 'routes/paths'

export const useTrancheSearchTableData = ({
  deal,
  selectedTrancheIds = [],
  disabledTrancheIds = [],
  isAllowedToReadTranche,
  mode,
  onSelectionChanged,
}) => {
  const navigate = useNavigate()

  return (tranches) =>
    tranches.map(
      ({
        trancheId: newBusinessTrancheId,
        displayId,
        productStatus,
        trancheName,
        dealId,
        totalCommitment,
        dataSource,
        externalContractId,
        receivableId,
      } = {}) => {
        const trancheLinkId =
          dataSource === DATA_SOURCES.EXISTING_BUSINESS ? externalContractId?.[0] : displayId
        const trancheId =
          dataSource === DATA_SOURCES.EXISTING_BUSINESS
            ? externalContractId?.[0]
            : newBusinessTrancheId
        const tranchesLink = `/${paths.deals}/${deal.dealId}/${dealDetailPaths.financing}/tranches/${trancheLinkId}?dataSource=${dataSource}`
        return {
          rowKey: `tranche-table-row-${trancheId}`,
          rowProperties: {
            onClick: ({ ctrlKey, metaKey } = {}) => {
              if (!isAllowedToReadTranche) return
              if (mode !== TrancheSearchTableMode.NavigateOnClick) {
                mode === TrancheSearchTableMode.MultiSelect &&
                  onSelectionChanged(
                    {
                      detail: {
                        selectedRows: [
                          {
                            dataset: {
                              dealId,
                              dealDisplayId: deal?.dealId,
                              dealStatus: deal?.status,
                              dealName: deal?.name,
                              trancheId,
                              dataSource,
                              receivableId,
                              trancheName,
                              displayId,
                            },
                          },
                        ],
                      },
                    },
                    true,
                  )
                return
              }

              const shouldOpenInNewTab = !!ctrlKey || !!metaKey
              if (shouldOpenInNewTab && !!deal) {
                window.open(tranchesLink, '_blank')
              } else {
                navigate(tranchesLink)
              }

              // Note for SingleSelect/MultiSelect:
              // Selected rows are passed directly from/to
              // SortedTable->DealSearchDialog via onSelectionChanged callback
            },
            selected: selectedTrancheIds.includes(trancheId),
            className: disabledTrancheIds.includes(trancheId) ? styles.disabledRow : '',
            type:
              isAllowedToReadTranche && mode !== TrancheSearchTableMode.MultiSelect
                ? TableRowType.Active
                : TableRowType.Inactive,
            'data-deal-id': dealId,
            'data-deal-display-id': deal?.dealId,
            'data-deal-status': deal?.status,
            'data-deal-name': deal?.name,
            'data-tranche-id': trancheId,
            'data-data-source': dataSource,
            'data-receivable-id': receivableId,
            'data-tranche-name': trancheName,
            'data-display-id': displayId,
          },
          [TrancheSearchTableColumns.TRANCHE]: {
            value: trancheName,
            cellComponent: (
              <EntityCell
                name={trancheName}
                id={trancheLinkId}
                link={mode !== TrancheSearchTableMode.NavigateOnClick && tranchesLink}
                options={{ isNameBold: true, openInNewTab: true }}
              />
            ),
          },
          [TrancheSearchTableColumns.DEAL]: {
            value: dealId,
            cellComponent: <DealLink dealUuid={dealId} />,
          },
          [TrancheSearchTableColumns.TRANCHE_STATUS]: {
            value: productStatus?.status,
            cellComponent: productStatus?.translation && (
              <StatusCell
                isLoading={false}
                isError={false}
                statusValue={productStatus.translation}
                statusState={ValueState.Information}
              />
            ),
          },
          [TrancheSearchTableColumns.DEAL_STATUS]: {
            value: deal?.status,
            cellComponent: deal && (
              <ObjectStatus inverted state={deal.status ? ValueState.Information : ValueState.None}>
                {deal.status}
              </ObjectStatus>
            ),
          },
          [TrancheSearchTableColumns.BUSINESS_TYPE]: {
            value: dataSource,
            cellComponent: <BusinessTypeCell dataSource={dataSource} isEdit={false} />,
          },
          [TrancheSearchTableColumns.TOTAL_COMMITMENT]: {
            value: totalCommitment.value,
            cellComponent: (
              <CurrencyCell
                cell={{
                  value: { number: totalCommitment.amount, currency: totalCommitment.currency },
                }}
              />
            ),
          },
          arrow: {
            cellComponent: isAllowedToReadTranche && <Icon name="slim-arrow-right" />,
          },
        }
      },
    )
}

export const useTrancheSearchColumnDefinitions = ({ mode, columnsRestrictedTo = null } = {}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.financing.tranche-search-dialog',
  })

  const columnDefinitions = [
    {
      title: t('columns.tranche'),
      columnKey: TrancheSearchTableColumns.TRANCHE,
      isSelectableForHiding: false,
      sortingDisabled: true,
    },
    {
      title: t('columns.tranche-status'),
      columnKey: TrancheSearchTableColumns.TRANCHE_STATUS,
      sortingDisabled: true,
    },
    {
      title: t('columns.deal'),
      columnKey: TrancheSearchTableColumns.DEAL,
      sortingDisabled: true,
    },
    {
      title: t('columns.deal-status'),
      columnKey: TrancheSearchTableColumns.DEAL_STATUS,
      sortingDisabled: true,
    },
    {
      title: t('columns.data-source'),
      columnKey: TrancheSearchTableColumns.BUSINESS_TYPE,
      sortingDisabled: true,
    },
    {
      title: t('columns.total-commitment'),
      columnKey: TrancheSearchTableColumns.TOTAL_COMMITMENT,
      sortingDisabled: true,
    },
  ]
  if (mode === TrancheSearchTableMode.NavigateOnClick)
    columnDefinitions.push({
      title: '',
      columnKey: 'arrow',
      sortingDisabled: true,
      alignment: FlexBoxAlignItems.End,
      isSelectableForHiding: false,
    })
  if (columnsRestrictedTo !== null) {
    return columnDefinitions.filter((column) => columnsRestrictedTo.includes(column.columnKey))
  }
  return columnDefinitions
}
