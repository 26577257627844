import { ComboBox, ComboBoxItem, Label, ValueState } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useCurrencies from 'hooks/services/collaterals/useCurrencies'
import styles from 'routes/deals/collaterals/creation/CollateralAgreementCreationDialog.module.css'
import CustomFormItem from 'routes/deals/collaterals/creation/CustomFormItem'

const propTypes = {
  currencyCode: PropTypes.string.isRequired,
  setCurrencyCode: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
}

// This component is implicitly tested by CollateralAgreementCreationDialog.unit.test.jsx
/** @param {PropTypes.InferProps<typeof propTypes>} props */
const CagCurrencyFormValue = ({ currencyCode, setCurrencyCode, disabled, required }) => {
  const { t } = useTranslation()
  const [valueState, setValueState] = useState(ValueState.None)

  useEffect(() => {
    if (currencyCode) {
      setValueState(ValueState.None)
    }
  }, [currencyCode])

  const handleValueStateChange = (value) => {
    if (isEmpty(value)) {
      setValueState(ValueState.Error)
    } else {
      setValueState(ValueState.None)
    }
  }

  const {
    data: { currencies = [] } = {},
    isLoading: isCurrenciesLoading,
    isError: isCurrenciesError,
  } = useCurrencies()

  return (
    <CustomFormItem className={styles.columnItem}>
      <Label showColon for="cag-currency-combobox" required={required}>
        {t('pages.deals.detail.collaterals.fields.currency')}
      </Label>
      <ComboBox
        id="cag-currency-combobox"
        value={
          (isCurrenciesError && t('components.loading-combo-box.loading')) ||
          (isCurrenciesLoading && t('components.loading-combo-box.loading')) ||
          currencyCode
        }
        onFocus={(event) => event.stopPropagation()}
        onChange={(event) => {
          handleValueStateChange(event.target.value)
          setCurrencyCode(event.target.value)
        }}
        onBlur={(event) => {
          handleValueStateChange(event.target.value)
        }}
        valueState={valueState}
        valueStateMessage={
          <span>
            {t('form.validation.select', {
              label: t('pages.deals.detail.collaterals.fields.currency'),
            })}
          </span>
        }
        disabled={disabled}
      >
        {currencies.map(({ id }) => (
          <ComboBoxItem text={id} key={id} />
        ))}
      </ComboBox>
    </CustomFormItem>
  )
}

CagCurrencyFormValue.propTypes = propTypes

export default CagCurrencyFormValue
