import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/EventHistoryTile.module.css'
import EventOverviewTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/events/EventOverviewTable'

const EventHistoryTile = ({ tileId, showConfirmationForLinks }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.event.event-history',
  })

  const { data } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  return (
    <EventOverviewTable
      events={data.events}
      maximumNumberOfEvents={data.total}
      shownColumns={['businessEventWithoutType', 'creationDate', 'closingDate', 'status']}
      noDataText={t('no-data')}
      className={styles['table-wrapper']}
      showConfirmationForLinks={showConfirmationForLinks}
    />
  )
}
EventHistoryTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
}

export default EventHistoryTile
