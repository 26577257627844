import z from 'zod'

export const SECTIONS = Object.freeze({
  impairmentCheck: 'impairmentCheck',
  riskProvisioning: 'riskProvisioning',
  stageThreeRequirement: 'stageThreeRequirement',
})

export const QUESTIONS = Object.freeze({
  [SECTIONS.impairmentCheck]: Object.freeze({
    impairmentTriggerOccurred: 'impairmentTriggerOccurred',
    qualitativeImpairmentTestDone: 'qualitativeImpairmentTestDone',
    impairmentComment: 'impairmentComment',
  }),
  [SECTIONS.riskProvisioning]: Object.freeze({
    currency: 'currency',
    sllpTotal: 'sllpTotal',
    stageThreeTotal: 'stageThreeTotal',
    changeInProvisioningRequired: 'changeInProvisioningRequired',
    riskProvisioningComment: 'riskProvisioningComment',
  }),
  [SECTIONS.stageThreeRequirement]: Object.freeze({
    stageThreeNotYetIdentified: 'stageThreeNotYetIdentified',
    noStageThreeRequirement: 'noStageThreeRequirement',
  }),
})

const impairmentCheckSchema = z.object({
  [SECTIONS.impairmentCheck]: z.object({
    impairmentTriggerOccurred: z.string(),
    qualitativeImpairmentTestDone: z.string(),
    impairmentComment: z.string(),
  }),
  [SECTIONS.riskProvisioning]: z.object({
    currency: z.string(),
    sllpTotal: z.number().nullable(),
    stageThreeTotal: z.number().nullable(),
    changeInProvisioningRequired: z.string(),
    riskProvisioningComment: z.string(),
  }),
  [SECTIONS.stageThreeRequirement]: z.object({
    stageThreeNotYetIdentified: z.string(),
    noStageThreeRequirement: z.string(),
  }),
})

/** @typedef {import('zod').TypeOf<typeof impairmentCheckSchema>} ImpairmentCheckSchema */

/** @satisfies {ImpairmentCheckSchema} */
export const emptyCheckSheet = Object.freeze({
  [SECTIONS.impairmentCheck]: Object.freeze({
    impairmentTriggerOccurred: '',
    qualitativeImpairmentTestDone: '',
    impairmentComment: '',
  }),
  [SECTIONS.riskProvisioning]: Object.freeze({
    currency: '',
    sllpTotal: null,
    stageThreeTotal: null,
    changeInProvisioningRequired: '',
    riskProvisioningComment: '',
  }),
  [SECTIONS.stageThreeRequirement]: Object.freeze({
    stageThreeNotYetIdentified: '',
    noStageThreeRequirement: '',
  }),
})

export default impairmentCheckSchema
