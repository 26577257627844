import { useMemo } from 'react'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useMultipleTenancyOverview from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useMultipleTenancyOverview'

const useAnnualReviewTenancyRentRollTenancyOverview = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { selectedDeal, dealsData } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const { selectedDealUuid, selectedDealDisplayId } = selectedDeal ?? {}

  const dealUuids = dealsData?.map((dealData) => dealData.dealUuid)

  const {
    data: multipleTenancyOverviewData,
    isLoading: isMultipleTenancyOverviewLoading,
    isError: isMultipleTenancyOverviewError,
    errorTenancyOverview,
  } = useMultipleTenancyOverview({ dealUuids })

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isAnnualReviewDealsLoading, isMultipleTenancyOverviewLoading],
    errorValues: [isAnnualReviewDealsError, isMultipleTenancyOverviewError],
    errorDetails: [annualReviewDealsError, errorTenancyOverview],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        multipleTenancyOverviewData,
        sourceRender: {
          ...multipleTenancyOverviewData?.tenancyOverviewPerDealUuid?.[selectedDealUuid]
            ?.sourceRender,
          businessPartnerId,
          dealDisplayId: selectedDealDisplayId,
        },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealsData,
    multipleTenancyOverviewData,
    selectedDealUuid,
    businessPartnerId,
    selectedDealDisplayId,
    error,
  ])
}

export default useAnnualReviewTenancyRentRollTenancyOverview
