import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import {
  useDealUuidByTileCode,
  WORKING_VERSION_TILE_WITH_LIVE_VARIANT_PREFIX,
} from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useTranchesWithSubEntities from 'hooks/services/deals/financing/useTranchesWithSubEntities'
import useGetMilestones from 'hooks/services/deals/milestones/useGetMilestones'
import useGetPricing from 'hooks/services/deals/pricing/useGetPricing'
import useDeal from 'hooks/services/deals/useDeal'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'

const useFinancingTerms = ({ entityRef: { entityId: dealUuid } }, tileId, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isFetchingDealUuid,
    isError: isErrorDealUuid,
    errors: errorDealUuid,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    isFetching: isFetchingDeal,
    isError: isErrorDeal,
    error: errorDeal,
    data: dealData,
  } = useDeal(dealUuidByTileCode)

  const dealDisplayId = dealData?.dealId

  const {
    data: pricingData,
    isFetching: isFetchingPricingData,
    isError: isErrorPricingData,
    error: errorPricingData,
  } = useGetPricing({ dealUuid: dealUuidByTileCode })

  const {
    data: milestonesData,
    isFetching: isFetchingMilestonesData,
    isError: isErrorMilestonesData,
    error: errorMilestonesData,
  } = useGetMilestones({ dealUuid: dealUuidByTileCode })

  const isWorkingVersionLiveTile = tileCode?.startsWith(
    WORKING_VERSION_TILE_WITH_LIVE_VARIANT_PREFIX,
  )

  const dataSource = useMemo(
    () =>
      isWorkingVersionDataTile || !isWorkingVersionLiveTile
        ? DATA_SOURCES.NEW_BUSINESS
        : DATA_SOURCES.EXISTING_BUSINESS,
    [isWorkingVersionDataTile, isWorkingVersionLiveTile],
  )

  const {
    data: trancheData,
    isFetching: isFetchingTrancheData,
    isError: isErrorTrancheData,
    error: errorTrancheData,
  } = useTranchesWithSubEntities({ dealUuid: dealUuidByTileCode, dataSource })

  const financingPathSuffix = useMemo(() => {
    if (isWorkingVersionDataTile) {
      return '?dataSource=newBusiness&working-version=true'
    } else if (!isWorkingVersionLiveTile) {
      return '?dataSource=newBusiness'
    }
    return ''
  }, [isWorkingVersionDataTile, isWorkingVersionLiveTile])

  const generalInformationPathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?working-version=true' : ''),
    [isWorkingVersionDataTile],
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isFetchingDealUuid,
      isFetchingDeal,
      isFetchingPricingData,
      isFetchingMilestonesData,
      isFetchingTrancheData,
    ],
    errorValues: [
      isErrorDealUuid,
      isErrorDeal,
      isErrorPricingData,
      isErrorMilestonesData,
      isErrorTrancheData,
    ],
    errorDetails: [
      ...errorDealUuid,
      errorDeal,
      errorPricingData,
      errorMilestonesData,
      errorTrancheData,
    ],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error, data: undefined }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false, data: undefined }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealData: dealData ?? {},
        trancheData: trancheData ?? {},
        pricingData: pricingData?.values ?? {},
        pricingCustomFields: pricingData?.meta?.custom_fields ?? {},
        milestonesData: milestonesData?.milestones ?? [],
        sourceRender: { dealDisplayId, financingPathSuffix, generalInformationPathSuffix },
      },
    }
  }, [
    dealData,
    dealDisplayId,
    error,
    financingPathSuffix,
    generalInformationPathSuffix,
    isSomeValueError,
    isSomeValueLoading,
    milestonesData?.milestones,
    pricingData?.meta?.custom_fields,
    pricingData?.values,
    trancheData,
  ])
}

export default useFinancingTerms
