import {
  Button,
  Title,
  Bar,
  Dialog,
  Icon,
  FlexBox,
  FlexBoxDirection,
  RadioButton,
  Label,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CreateRentRollWorkingVersionLoadingScreen from 'components/domains/properties/rent-roll/working-version/CreateRentRollWorkingVersionLoadingScreen'
import { ExcelUploadContext } from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollExcelUploadContext'
import 'components/domains/properties/rent-roll/working-version/excel-upload/ExcelUpload.css'
import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js'
import { findFirstContentRow } from 'components/domains/properties/rent-roll/working-version/excel-upload/commonRentRollExcelUploadFunctions'
import { useDeleteRentRollWorkingVersionColumnMapping } from 'hooks/services/properties/rent-roll/working-version/excel-upload/useDeleteRentRollWorkingVersionColumnMapping'

const APPLY = 'apply'
const REVIEW = 'review'
const DISCARD = 'discard'
const MAPPINGFROMSCRATCH = 'mappingFromScratch'
const MAPPINGFROMEXISTINGSOURCE = 'mappingFromExistingSource'

const PropertyRentRollExcelColumnMappingExistsDialog = ({
  propertyUuids,
  initiateExcelToTableMapping,
  handleAcceptMappingAndContinue,
}) => {
  const { t: tExcelUpload } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.excel-upload',
  })
  const queryClient = useQueryClient()
  const excelUploadContext = useContext(ExcelUploadContext)
  const {
    isMappingExistsDialogOpen,
    closeMappingExistsDialog,
    openAdjustMappingDialog,
    openChooseHeaderRowDialog,
    inputExcelData,
    rowNumberHeader,
    defaultRowNumberHeader,
  } = excelUploadContext
  const [mappingChoice, setMappingChoice] = useState(APPLY)

  const deleteRrwvColumnMapping = useDeleteRentRollWorkingVersionColumnMapping({
    onSuccess: () => {
      queryClient.removeQueries(['column_mapping', ...propertyUuids])
      closeMappingExistsDialog()
      const rowNumberFirstContentRow = findFirstContentRow(defaultRowNumberHeader, inputExcelData)
      initiateExcelToTableMapping({
        headerRow: inputExcelData[defaultRowNumberHeader],
        nextRow: inputExcelData[rowNumberFirstContentRow],
        mode: MAPPINGFROMSCRATCH,
      })
      openChooseHeaderRowDialog()
    },
    onError: (error) => {
      // TODO open expandable CMS error dialog
      closeMappingExistsDialog()
      console.error(error)
    },
  })

  const handleApply = () => {
    closeMappingExistsDialog()
    handleAcceptMappingAndContinue()
  }

  const handleReview = () => {
    closeMappingExistsDialog()
    const rowNumberFirstContentRow = findFirstContentRow(rowNumberHeader, inputExcelData)
    initiateExcelToTableMapping({
      headerRow:
        inputExcelData.length > rowNumberHeader ? inputExcelData[rowNumberHeader] : new Map(),
      nextRow:
        inputExcelData.length > rowNumberFirstContentRow
          ? inputExcelData[rowNumberFirstContentRow]
          : new Map(),
      mode: MAPPINGFROMEXISTINGSOURCE,
    })
    openAdjustMappingDialog()
  }

  const handleDiscard = () => {
    deleteRrwvColumnMapping.mutate({ propertyUuids: propertyUuids })
  }

  const handleMappingChoice = () => {
    switch (mappingChoice) {
      case APPLY:
        handleApply()
        break
      case REVIEW:
        handleReview()
        break
      case DISCARD:
        handleDiscard()
        break
      default:
        console.error('mappingChoice ' + mappingChoice + ' has no handle function.')
    }
  }

  return (
    <>
      {deleteRrwvColumnMapping.isLoading ? (
        <CreateRentRollWorkingVersionLoadingScreen isLoading={deleteRrwvColumnMapping.isLoading} />
      ) : (
        <Dialog
          open={isMappingExistsDialogOpen}
          onAfterClose={closeMappingExistsDialog}
          header={
            <Bar
              startContent={
                <>
                  <Icon className="excel-upload-information-icon" name="sap-icon://information" />
                  <Title>{tExcelUpload('column-mapping-exists-dialog.title')}</Title>
                </>
              }
            />
          }
          footer={
            <Bar
              endContent={
                <>
                  <Button
                    design="Emphasized"
                    data-testid={'accept-button'}
                    onClick={handleMappingChoice}
                  >
                    {tExcelUpload('accept-button')}
                  </Button>
                  <Button data-testid={'abort-button'} onClick={closeMappingExistsDialog}>
                    {tExcelUpload('abort-button')}
                  </Button>
                </>
              }
            />
          }
        >
          <FlexBox>{tExcelUpload('column-mapping-exists-dialog.text') + '\n'}</FlexBox>
          <FlexBox direction={FlexBoxDirection.Column}>
            <FlexBox style={{ marginTop: '5px' }} direction={FlexBoxDirection.Row}>
              <RadioButton
                name="chooseMappingOperation"
                id="radio-button-apply"
                data-testid="radio-button-apply"
                text=""
                value={APPLY}
                checked={mappingChoice === APPLY}
                onChange={(event) => setMappingChoice(event.target.value)}
              />
              <Label
                for="radio-button-apply"
                onClick={() => setMappingChoice(APPLY)}
                style={{ cursor: 'pointer' }}
              >
                <b>{tExcelUpload('column-mapping-exists-dialog.apply-title')}</b>
                <br />
                {tExcelUpload('column-mapping-exists-dialog.apply')}
              </Label>
            </FlexBox>
            <FlexBox style={{ marginTop: '5px' }} direction={FlexBoxDirection.Row}>
              <RadioButton
                name="chooseMappingOperation"
                id="radio-button-review"
                data-testid="radio-button-review"
                text=""
                value={REVIEW}
                checked={mappingChoice === REVIEW}
                onChange={(event) => setMappingChoice(event.target.value)}
              />
              <Label
                for="radio-button-review"
                onClick={() => setMappingChoice(REVIEW)}
                style={{ cursor: 'pointer' }}
              >
                <b>{tExcelUpload('column-mapping-exists-dialog.review-title')}</b>
                <br />
                {tExcelUpload('column-mapping-exists-dialog.review')}
              </Label>
            </FlexBox>
            <FlexBox style={{ marginTop: '5px' }} direction={FlexBoxDirection.Row}>
              <RadioButton
                name="chooseMappingOperation"
                id="radio-button-discard"
                data-testid="radio-button-discard"
                text=""
                value={DISCARD}
                checked={mappingChoice === DISCARD}
                onChange={(event) => setMappingChoice(event.target.value)}
              />
              <Label
                for="radio-button-discard"
                onClick={() => setMappingChoice(DISCARD)}
                style={{ cursor: 'pointer' }}
              >
                <b>{tExcelUpload('column-mapping-exists-dialog.discard-title')}</b>
                <br />
                {tExcelUpload('column-mapping-exists-dialog.discard')}
              </Label>
            </FlexBox>
          </FlexBox>
        </Dialog>
      )}
    </>
  )
}
PropertyRentRollExcelColumnMappingExistsDialog.propTypes = {
  propertyUuids: PropTypes.array.isRequired,
  initiateExcelToTableMapping: PropTypes.func.isRequired,
  handleAcceptMappingAndContinue: PropTypes.func.isRequired,
}
export default PropertyRentRollExcelColumnMappingExistsDialog
