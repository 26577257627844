import { FlexBoxAlignItems } from '@fioneer/ui5-webcomponents-react'
import { filterTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/sorted-tables/useFilters'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'

const useCovenantChecksTableColumnDefinitions = ({
  t,
  entityType,
  statusEnum,
  breachEnumns,
  monitoringReferenceTypeEnumns,
}) => {
  const dealColumn =
    entityType === cwpEntityTypes.BUSINESS_PARTNER
      ? [
          {
            title: t('column.deals'),
            popinText: t('column.deals'),
            columnKey: 'deals',
            minWidth: 900,
            demandPopin: true,
            sortingDisabled: true,
          },
        ]
      : []

  return [
    {
      title: t('column.monitoring-reference-type'),
      popinText: t('column.monitoring-reference-type'),
      columnKey: 'monitoring-reference-type',
      isVisible: false,
      filter: filterTypes.OF_ENUM_TYPE,
      additionalFilterOptions: { enumValues: monitoringReferenceTypeEnumns },
      minWidth: 900,
      demandPopin: true,
    },
    {
      title: t('column.monitoring-reference'),
      popinText: t('column.monitoring-reference'),
      columnKey: 'monitoring-reference',
      isVisible: false,
      minWidth: 900,
      demandPopin: true,
    },
    ...dealColumn,
    {
      title: t('column.covenants'),
      popinText: t('column.covenants'),
      columnKey: 'covenants',
      minWidth: 900,
      demandPopin: true,
      sortingDisabled: true,
    },
    {
      title: t('column.monitoring-event'),
      popinText: t('column.monitoring-event'),
      columnKey: 'monitoring-event',
      minWidth: 700,
      demandPopin: true,
      sortingDisabled: true,
    },
    {
      title: t('column.key-date'),
      popinText: t('column.key-date'),
      columnKey: 'key-date',
      filter: filterTypes.BETWEEN_DATES,
      alignment: FlexBoxAlignItems.End,
      minWidth: 700,
      demandPopin: true,
    },
    {
      title: t('column.status'),
      popinText: t('column.status'),
      columnKey: 'status',
      filter: filterTypes.OF_ENUM_TYPE,
      additionalFilterOptions: { enumValues: statusEnum },
      minWidth: 700,
      demandPopin: true,
    },
    {
      title: t('column.first-target-delivery-date'),
      popinText: t('column.first-target-delivery-date'),
      columnKey: 'first-target-delivery-date',
      filter: filterTypes.BETWEEN_DATES,
      alignment: FlexBoxAlignItems.End,
      minWidth: 600,
      demandPopin: true,
    },
    {
      title: t('column.breach'),
      popinText: t('column.breach'),
      columnKey: 'breach',
      filter: filterTypes.OF_ENUM_TYPE,
      additionalFilterOptions: { enumValues: breachEnumns },
      minWidth: 600,
      demandPopin: true,
    },
    {
      title: '',
      columnKey: 'arrow',
      sortingDisabled: true,
      alignment: FlexBoxAlignItems.End,
      isSelectableForHiding: false,
    },
  ]
}

export default useCovenantChecksTableColumnDefinitions
