import PropertyDetails from 'routes/properties/PropertyDetails'
import PropertySearchPage from 'routes/properties/PropertySearchPage'
import { PropertyWrapper } from 'routes/properties/PropertyWrapper'
import PropertyPortfolioDetails from 'routes/properties/portfolio/PropertyPortfolioDetails'
import PropertyPortfolioWrapper from 'routes/properties/portfolio/PropertyPortfolioWrapper'

export const paths = {
  list: '',
  dashboard: ':propertyId/*',
  portfolio: 'portfolio/*',
}

const PropertiesRoutes = [
  { path: paths.list, index: true, element: <PropertySearchPage /> },
  {
    path: paths.portfolio,
    element: <PropertyPortfolioWrapper />,
    children: [
      {
        path: '*',
        element: <PropertyPortfolioDetails />,
        index: true,
      },
    ],
  },
  {
    path: paths.dashboard,
    element: <PropertyWrapper />,
    children: [
      {
        path: '*',
        element: <PropertyDetails />,
        index: true,
      },
    ],
  },
]

export default PropertiesRoutes
