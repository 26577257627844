import {
  IllustratedMessage,
  IllustrationMessageType,
  Title,
} from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { conditionsEntityTypes } from 'api/conditions/conditions'
import { covenantPermissions } from 'api/deals/covenantPermissions'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import CheckDocumentsCard from 'components/domains/deals/covenant-check-detail/check-documents/CheckDocumentsCard'
import CheckedCovenantsCard from 'components/domains/deals/covenant-check-detail/checked-covenants/CheckedCovenantsCard'
import CloseCovenantCheckButton from 'components/domains/deals/covenant-check-detail/close-covenant-check/CloseCovenantCheckButton'
import CovenantCommentCard from 'components/domains/deals/covenant-check-detail/comment/CovenantCommentCard'
import HistoricalMonitoringEventsCard from 'components/domains/deals/covenant-check-detail/historical-events/HistoricalMonitoringEventsCard'
import CovenantCheckMessageStrips from 'components/domains/deals/covenant-check-detail/message-stripes/CovenantCheckMessageStrips'
import MonitoringEventCard from 'components/domains/deals/covenant-check-detail/monitoring-event/MonitoringEventCard'
import CovenantCheckMonitoringRequirementsCard from 'components/domains/deals/covenant-check-detail/monitoring-requirements/CovenantCheckMonitoringRequirementsCard'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Link from 'components/ui/link/Link'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import Page from 'components/ui/page/Page'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useConditionsAllowedOperations from 'hooks/services/conditions/useConditionsAllowedOperations'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import { useCovenantAllowedOperations } from 'hooks/services/deals/covenants/useCovenantAllowedOperations'
import useCovenantCheckActions from 'hooks/services/deals/covenants/useCovenantCheckActions'
import useCovenantCheckById from 'hooks/services/deals/covenants/useCovenantCheckById'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'
import useCalculateEditButtonState, {
  editButtonStateEnum,
} from 'routes/deals/covenant-checks/useCalculateEditButtonState'
import paths from 'routes/paths'

const COVENANT_CHECK = 'COVENANT_CHECK'

const BusinessPartnerCovenantCheckPage = () => {
  const { id, fullName } = useContext(BusinessPartnerContext)
  const { t } = useTranslation()
  const { covenantCheckUuid } = useParams()

  const {
    isFetching: isFetchingActions,
    isError: isErrorActions,
    data: { isEditable } = {},
  } = useCovenantCheckActions({ covenantCheckUuid })

  const {
    isLoading: isLoadingAllowedOperationsCovenant,
    isError: isErrorAllowedOperationsCovenant,
    data: { allowedOperations: covenantAllowedOperations = [] } = {},
  } = useCovenantAllowedOperations()

  const isAllowedToRead = covenantAllowedOperations?.includes(
    covenantPermissions.readCovenantContractMonitoring,
  )
  const isAllowedToUpdate = covenantAllowedOperations?.includes(
    covenantPermissions.updateCovenantContractMonitoring,
  )

  const {
    isLoading: isLoadingConditionsAllowedOperations,
    data: conditionsAllowedOperationsData = {},
  } = useConditionsAllowedOperations()

  const {
    isFetching: isFetchingCovenantCheck,
    isError: isErrorCovenantCheck,
    data: {
      name,
      monitoringReferenceEntity: { id: monitoringId, type: monitoringType, status } = {},
      linkedEventId,
    } = {},
  } = useCovenantCheckById(covenantCheckUuid)

  const editButtonState = useCalculateEditButtonState({
    isEditable,
    status,
    isAllowedToEdit: isAllowedToUpdate,
  })

  const referenceEntity = {
    id: id,
    name: fullName,
    documentType: DocumentTypes.BusinessPartner,
    entityType: cwpEntityTypes.BUSINESS_PARTNER,
  }

  const isCovenantCheckInBp =
    monitoringType === mainEntityTypes.BUSINESSPARTNER && monitoringId === id

  const breadcrumbName = name && isCovenantCheckInBp ? name : covenantCheckUuid
  const generateBreadcrumbs = () => {
    if (id) {
      return [
        {
          text: t('navigation.item.title.business-partner-covenants'),
          href: `covenants`,
        },
        { text: breadcrumbName },
      ]
    }
    return []
  }

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [id],
  )

  const renderContent = () =>
    id ? (
      <RequestStateResolver
        isLoading={
          isFetchingCovenantCheck ||
          isLoadingConditionsAllowedOperations ||
          isLoadingAllowedOperationsCovenant ||
          isFetchingActions
        }
        isError={isErrorCovenantCheck || isErrorAllowedOperationsCovenant || isErrorActions}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        center
        renderContent={() =>
          isCovenantCheckInBp ? (
            <ConditionsContext.Provider
              value={{
                entityRef: {
                  entityId: id,
                  entityDisplayId: id,
                  entityType: conditionsEntityTypes.businessPartner,
                },
                allowedOperations: conditionsAllowedOperationsData.allowedOperations,
              }}
            >
              <CWPLayout>
                <CWPLayout.Full>
                  <CovenantCheckMessageStrips
                    checkName={t(
                      'components.deals.covenants.covenant-checks.message-strips.covenant-check',
                    )}
                    covenantCheckUuid={covenantCheckUuid}
                    eventId={linkedEventId}
                  />
                </CWPLayout.Full>
                {isAllowedToRead && (
                  <CWPLayout.Full>
                    <CheckedCovenantsCard
                      covenantCheckUuid={covenantCheckUuid}
                      entityType={cwpEntityTypes.BUSINESS_PARTNER}
                      editButtonState={editButtonState}
                    />
                  </CWPLayout.Full>
                )}
                <CWPLayout.Full>
                  <CovenantCheckMonitoringRequirementsCard covenantCheckUuid={covenantCheckUuid} />
                </CWPLayout.Full>
                <CWPLayout.Half>
                  <MonitoringEventCard covenantCheckUuid={covenantCheckUuid} />
                </CWPLayout.Half>
                <CWPLayout.Half>
                  <CovenantCommentCard
                    linkedEntityType={COVENANT_CHECK}
                    linkedEntityId={covenantCheckUuid}
                    isEditEnabled={editButtonState === editButtonStateEnum.VISIBLE}
                  />
                </CWPLayout.Half>
                <CWPLayout.Full>
                  <HistoricalMonitoringEventsCard covenantCheckUuid={covenantCheckUuid} />
                </CWPLayout.Full>
                <CWPLayout.Half>
                  <CheckDocumentsCard
                    referenceEntity={referenceEntity}
                    covenantCheckUuid={covenantCheckUuid}
                  />
                </CWPLayout.Half>
              </CWPLayout>
            </ConditionsContext.Provider>
          ) : (
            <IllustratedMessage
              name={IllustrationMessageType.NoData}
              titleText={t('components.cards.empty.title')}
              subtitleText={t('components.cards.empty.subtitle')}
            />
          )
        }
      />
    ) : (
      <div id="not-found-tab">
        <Title>{t('pages.business-partner.not-found')}</Title>
        <Link href={`/${paths.businessPartners}`}>{t('pages.business-partner.search')}</Link>
      </div>
    )

  return (
    <Page
      isError={false}
      isLoading={false}
      renderHeaderTitle={() => (
        <BusinessPartnerHeader
          actions={[
            <CloseCovenantCheckButton
              key="close-covenant-check"
              covenantCheckUuid={covenantCheckUuid}
              buttonText={t('pages.deals.covenants.covenant-check.close-covenant-button')}
              confirmationText={t('pages.deals.covenants.covenant-check.close-confirmation')}
              successText={t('pages.deals.covenants.covenant-check.close-success')}
            />,
            <CreateBusinessPartnerEventAction key="create-event-action" />,
            markFavoriteAction,
          ]}
          breadcrumbs={generateBreadcrumbs()}
        />
      )}
      renderContent={renderContent}
    />
  )
}

BusinessPartnerCovenantCheckPage.propTypes = {}

export default BusinessPartnerCovenantCheckPage
