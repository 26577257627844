import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

/**
 * provides a common x-axis config for time based charts
 * that can be used as props for recharts' `XAxis` component
 *
 * @typedef {object} xAxisConfig
 * @property {number[]} ticks
 * @property {function} tickFormatter
 *
 * @param {object[]} data
 * @returns {xAxisConfig}
 */
const useHistoricalChartXAxisTickConfig = (data) => {
  const { format: formatDate } = useShortDateFormatter()
  return useMemo(() => {
    const firstRentRollDateMillis = data.at(0)?.date
    const lastRentRollDateMillis = data.at(-1)?.date
    let yearTicks = []

    if (data.length > 1) {
      const firstRentRollDate = DateTime.fromMillis(firstRentRollDateMillis)
      const lastRentRollDate = DateTime.fromMillis(lastRentRollDateMillis)
      const yearsToShow = []

      for (let year = firstRentRollDate.year + 1; year <= lastRentRollDate.year; year++) {
        yearsToShow.push(year)
      }

      yearTicks = yearsToShow.map((year) => DateTime.fromObject({ year }).toMillis())
    }

    const ticks = (() => {
      if (data.length === 0) return []
      if (data.length === 1) return [firstRentRollDateMillis]
      return [firstRentRollDateMillis, ...yearTicks, lastRentRollDateMillis]
    })()

    const tickFormatter = (value) => {
      const valueAsDate = new Date(value)
      return yearTicks.includes(value)
        ? `${valueAsDate.getFullYear()}`
        : formatDate(valueAsDate.toISOString())
    }

    return { ticks, tickFormatter }
  }, [data, formatDate])
}

export default useHistoricalChartXAxisTickConfig
