const conditionApprovalLevelChanged = (
  state,
  { payload: { conditionIndex, approvalLevel: newApprovalLevel } },
) => {
  if (!state.conditions[conditionIndex]) {
    return
  }
  state.conditions[conditionIndex].approvalLevel = newApprovalLevel
}

export default conditionApprovalLevelChanged
