import { DatePicker, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/i18n/useI18n'

const PropertyValuationResultsDateInputCell = ({ date, onChange, isAddMode, maxDate }) => {
  const { localePattern, parse, format: formatDate } = useShortDateFormatter()

  const handleBlur = useCallback(() => {
    onChange(date)
  }, [date, onChange])

  const handleChange = useCallback(
    (event) => {
      const newValue = parse(event?.target?.value, localePattern) ?? null
      onChange(newValue)
    },
    [localePattern, onChange, parse],
  )

  if (isAddMode) {
    return (
      <DatePicker
        value={date}
        maxDate={maxDate}
        formatPattern={localePattern}
        placeholder={localePattern}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    )
  }

  if (!date) {
    return null
  }

  return <Text>{formatDate(date)}</Text>
}

PropertyValuationResultsDateInputCell.propTypes = {
  date: PropTypes.string,
  maxDate: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isAddMode: PropTypes.bool.isRequired,
  valueState: PropTypes.string,
  valueStateMessage: PropTypes.elementType,
}

export default PropertyValuationResultsDateInputCell
