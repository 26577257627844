import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useExternalCreateReservationTrigger = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ dealUuid, reservationUuid }) => {
      await post({
        path: `/pbb-custom-service/deals/${dealUuid}/limit-management/create-reservation`,
        body: {
          credit_reservation_uuid: reservationUuid,
        },
      })
    },
    { onSuccess, onError },
  )
}

export default useExternalCreateReservationTrigger
