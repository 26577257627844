import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-results/MultipleFactSheetValuationResultsV1.module.css'
import AnnualReviewDealOverviewPropertySelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-selection/AnnualReviewDealOverviewPropertySelect'
import AnnualReviewPropertySelectPdfView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-selection/AnnualReviewPropertySelectPdfView'
import useAnnualReviewDealOverviewSelectedProperty from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/utils/useAnnualReviewDealOverviewSelectedProperty'
import { ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/constants'
import PropertyValuationResultsTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/valuation-results/PropertyValuationResultsTable'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const legacyValuationClassification = {
  marketValue: 'Market Value',
  mortgageLendingValue: 'Mortgage Lending Value',
  other: 'Other',
}

const MultipleFactSheetValuationResultsV1 = ({ tileId, selectedDealIndex, isPdfView = false }) => {
  const dispatch = useDispatch()

  const { data: { multipleArrayPropertyUuidsData = [], propertyValuationsByArrayData = [] } = {} } =
    useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { propertiesData } = useMemo(
    () => ({
      propertiesData: multipleArrayPropertyUuidsData?.[selectedDealIndex]?.data?.properties,
    }),
    [multipleArrayPropertyUuidsData, selectedDealIndex],
  )

  const { selectedPropertyUuid, selectedPropertyDisplayId, selectedPropertyName } =
    useAnnualReviewDealOverviewSelectedProperty({
      propertiesData,
      propertyMetadataTileCode:
        ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES.PROPERTY_PORTFOLIO_SUBSECTION,
    })

  useEffect(() => {
    dispatch(
      setSourceRender({
        tileId,
        sourceRender: { propertyId: selectedPropertyDisplayId },
      }),
    ) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPropertyDisplayId])

  const renderContent = useCallback(
    (currentProperty) => {
      const valuationsData = propertyValuationsByArrayData.find(
        (propertyValuations) => propertyValuations?.propertyUuid[0] === currentProperty?.uuid,
      )
      const valuationsWithoutClassificationOther = valuationsData?.valuations?.filter(
        (valuation) => valuation.classification !== legacyValuationClassification.other,
      )

      return (
        <div key={currentProperty?.uuid}>
          {isPdfView ? (
            <AnnualReviewPropertySelectPdfView {...currentProperty} />
          ) : (
            <AnnualReviewDealOverviewPropertySelect
              propertiesData={propertiesData}
              propertyMetadataTileCode={
                ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES.PROPERTY_PORTFOLIO_SUBSECTION
              }
            />
          )}
          <DecisionPaperTileListSeparator />
          <div className={styles.spacingWrapper}>
            {selectedPropertyUuid && valuationsWithoutClassificationOther && (
              <PropertyValuationResultsTable
                valuationResults={valuationsWithoutClassificationOther}
                isDecisionPaperPdfView={isPdfView}
              />
            )}
          </div>
        </div>
      )
    },
    [isPdfView, propertiesData, propertyValuationsByArrayData, selectedPropertyUuid],
  )

  return isPdfView
    ? propertiesData?.map((property) => renderContent(property))
    : renderContent({
        uuid: selectedPropertyUuid,
        id: selectedPropertyDisplayId,
        description: selectedPropertyName,
      })
}

MultipleFactSheetValuationResultsV1.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultipleFactSheetValuationResultsV1
