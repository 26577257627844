import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import React from 'react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/kpis/chart/KpiUnit.module.css'
import {
  useCustomizableCurrencyFormatter,
  usePercentageFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const EMPTY_VALUE = <>-</>

const KpiUnit = ({ unit, value }) => {
  const formatCurrency = useCustomizableCurrencyFormatter()
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  // Guard
  if (isNil(value)) return EMPTY_VALUE

  // Format per unit type
  switch (unit?.type) {
    case 'CURRENCY':
      return (
        <span className={styles.noWrap}>
          {formatCurrency(value, unit.code, {
            currencyDisplay: 'code',
          })}
        </span>
      )
    case 'PERCENT':
      return <span>{formatPercentage(value / 100)}</span>
    default:
      return <span className={styles.noWrap}>{value}</span>
  }
}

KpiUnit.propTypes = {
  unit: PropTypes.shape({
    type: PropTypes.string,
    code: PropTypes.string,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default KpiUnit
