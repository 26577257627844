import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ReferencesSelectionComponent, {
  ReferencesSelectionComponentRenderDirections,
} from 'components/domains/conditions/reference-selection/ReferencesSelectionComponent'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import useAnalyticalTableIndexHelper from 'hooks/services/conditions/tables/useAnalyticalTableIndexHelper'
import useGetDocumentTypes from 'hooks/services/documents/useGetDocumentTypes'
import {
  conditionReferencesChanged,
  conditionPossibleDocumentsChanged,
} from 'redux/slices/conditions/conditionCreationSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const ConditionCreationReferencesCell = ({
  row: {
    id,
    index,
    depth,
    original: { references },
  },
}) => {
  const {
    entityRef: { entityId: refEntityId, entityType: refEntityType },
  } = useContext(ConditionsContext)
  const { t: tDocumentType } = useTranslation('translation', {
    keyPrefix:
      'components.conditions.overview.dialog.external-condition-creation.table.document-type-cell',
  })
  const dispatch = useDispatch()
  const [displayedReferences, setDisplayedReferences] = useState(references)

  const documentTypesEntityRefs = useMemo(() => {
    const defaultRefs = { type: DocumentTypes.Requirement, id: 'no-id' }

    switch (refEntityType) {
      case DocumentTypes.BusinessPartner:
        return [defaultRefs, { type: refEntityType, id: refEntityId }]

      case DocumentTypes.Deal:
        if (
          references?.entityType &&
          references?.entityType !== refEntityType &&
          !isNil(references.entityIds) &&
          references.entityIds.length > 0
        ) {
          const mappedEntities = references.entityIds.map((entityId) => ({
            type: references.entityType,
            id: entityId,
          }))
          return [defaultRefs, ...mappedEntities]
        }

        return [defaultRefs, { type: refEntityType, id: refEntityId }]
    }
  }, [refEntityId, refEntityType, references])

  const { data: documentTypes } = useGetDocumentTypes({ entityRefs: documentTypesEntityRefs })

  const { isConditionsCell, conditionIndex } = useAnalyticalTableIndexHelper({
    id,
    index,
    depth,
  })

  useEffect(() => {
    if (!isConditionsCell) {
      return
    }
    dispatch(
      conditionPossibleDocumentsChanged({
        conditionIndex,
        possibleDocumentTypes: documentTypes?.documentTypes ?? [],
        fallbackDocumentTypeText: tDocumentType('fallback'),
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTypes?.documentTypes])

  const onReferencesChanged = useCallback(
    (newReferences) => {
      dispatch(
        conditionReferencesChanged({
          conditionIndex,
          references: {
            entityIds: newReferences?.entityIds,
            entityType: newReferences?.entityType,
          },
        }),
      )
      setDisplayedReferences({
        entityIds: newReferences?.entityIds ?? [],
        entityType: newReferences?.entityType,
      })
    },
    [dispatch, conditionIndex],
  )

  if (!isConditionsCell) {
    return
  }

  return (
    <ReferencesSelectionComponent
      references={displayedReferences}
      onReferencesChanged={onReferencesChanged}
      renderDirection={ReferencesSelectionComponentRenderDirections.horizontal}
    />
  )
}

ConditionCreationReferencesCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    depth: PropTypes.number.isRequired,
    original: PropTypes.shape({
      references: PropTypes.shape({
        entityIds: PropTypes.arrayOf(PropTypes.string),
        entityType: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
}

export default ConditionCreationReferencesCell
