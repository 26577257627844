import {
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
  Label,
  TableCell,
  TableColumn,
  TableRow,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperTileTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileTable'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants-and-ratios/CovenantsRatiosFinancialRatiosTile.module.css'
import CashFlowScenarioNameTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants-and-ratios/shared/CashFlowScenarioNameTile'
import { useNumberFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants-and-ratios/shared/useI18n'

const CovenantsRatiosFinancialRatiosTile = ({ tileId, isPdfView }) => {
  const {
    data: { dealId, tileMetaData, tableData },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.covenants-ratios.financial-ratios',
  })
  const formatPercentage = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })

  return (
    <>
      <CashFlowScenarioNameTile
        metaData={tileMetaData?.metaData}
        dealId={dealId}
        showMessageStrip={false}
        isPdfView={isPdfView}
      />
      {tableData?.length === 0 ? (
        <IllustratedMessage
          name={IllustrationMessageType.NoEntries}
          size={IllustrationMessageSize.Scene}
        />
      ) : (
        <div className={styles.tile}>
          <DecisionPaperTileTable
            showColumnBorders={true}
            noDataText={t('no-data')}
            columns={
              <>
                <TableColumn>
                  <Label>{t('parameter')}</Label>
                </TableColumn>
                <TableColumn>
                  <Label>{t('tranche-bracket')}</Label>
                </TableColumn>
                <TableColumn>
                  <Label>{t('day-1')}</Label>
                </TableColumn>
                <TableColumn>
                  <Label>{t('maturity')}</Label>
                </TableColumn>
              </>
            }
          >
            {(tableData ?? []).map(({ parameter, trancheBracket, dayOne, maturity }, index) => (
              <TableRow key={`${trancheBracket}-${parameter}-${index}`}>
                <TableCell data-column="parameter">
                  <Text>{parameter ?? ''}</Text>
                </TableCell>
                <TableCell data-column="tranche-bracket">
                  <Text>{trancheBracket}</Text>
                </TableCell>
                <TableCell data-column="day-one">
                  <Text>{formatPercentage(dayOne / 100)}</Text>
                </TableCell>
                <TableCell data-column="maturity">
                  <Text>{formatPercentage(maturity / 100)}</Text>
                </TableCell>
              </TableRow>
            ))}
          </DecisionPaperTileTable>
        </div>
      )}
    </>
  )
}

CovenantsRatiosFinancialRatiosTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default CovenantsRatiosFinancialRatiosTile
