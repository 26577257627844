import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useApplicationTypes = (options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/configurations/application-types`,
      translated: true,
      useCache: true,
      keys: ['deals', 'configurations', 'application-types'],
      ...options,
    }),
  )
