import isEmpty from 'lodash.isempty'
import mapMeasure from 'components/domains/business-partners/tile/risk-monitoring/forbearance/measures/mapper/mapMeasure'

/**
 * @param {import('hooks/services/risk-monitoring/forbearance/measures/forbearanceMeasuresSchema').ForbearanceMeasures} backendData
 * @param {object} staffMembers
 */
const mapMeasuresBackendData = (backendData, staffMembers) => {
  if (!isEmpty(backendData)) {
    const mappedData = backendData.forbearanceMeasures.map((measure) =>
      mapMeasure(measure, staffMembers),
    )
    return { tableData: mappedData, nrOfMeasures: mappedData.length }
  }
  return { tableData: [], nrOfMeasures: 0 }
}

export default mapMeasuresBackendData
