import PropTypes from 'prop-types'
import React from 'react'
import LoadingDropdown from 'components/ui/dropdown/LoadingDropdown'
import useMarketCountries from 'hooks/services/markets/useMarketCountries'

const FilterCountryCodeSelect = React.forwardRef(({ currentCountry, handleOnChange }, _ref) => {
  const { isError, isLoading, data: marketCountriesData } = useMarketCountries()

  const marketCountries = () => {
    if (isLoading || isError) return { isLoading, isError, data: undefined }

    return {
      data: marketCountriesData.countries.map(({ code, name }) => ({ code, displayName: name })),
      isLoading: false,
      isError: false,
    }
  }

  return (
    <LoadingDropdown
      id="filter-market-countries-select"
      value={currentCountry}
      onChange={handleOnChange}
      useLoader={marketCountries}
      initialDropdownValue={''}
    />
  )
})

FilterCountryCodeSelect.displayName = 'FilterCountryCodeSelect'

FilterCountryCodeSelect.propTypes = {
  currentCountry: PropTypes.string,
  onKeyDown: PropTypes.func,
  handleOnChange: PropTypes.func,
}

export default FilterCountryCodeSelect
