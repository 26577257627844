import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import FactSheetValuationFactorAndScenarios from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/FactSheetValuationFactorAndScenarios'
import useFactSheetValuationFactorOptions from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-factor/useFactSheetValuationFactorOptions'

const FactSheetValuationFactor = ({
  tileId,
  currentContent,
  onChange,
  isEditMode,
  isPdfView,
  setIsSaveEnabled,
  setHasContentChanges,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.fact-sheet-valuation-factor',
  })
  const selectOptions = useFactSheetValuationFactorOptions({ isEditMode })
  const rowAmount = 4
  const columns = [{ label: t('column.factor') }, { label: t('column.value'), alignRight: true }]
  return (
    <FactSheetValuationFactorAndScenarios
      tileId={tileId}
      currentContent={currentContent}
      onChange={onChange}
      isEditMode={isEditMode}
      isPdfView={isPdfView}
      setIsSaveEnabled={setIsSaveEnabled}
      setHasContentChanges={setHasContentChanges}
      rowAmount={rowAmount}
      selectOptions={selectOptions}
      columns={columns}
    />
  )
}

FactSheetValuationFactor.propTypes = {
  tileId: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  currentContent: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  setIsSaveEnabled: PropTypes.func.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
}
export default FactSheetValuationFactor
