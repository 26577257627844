import uniq from 'lodash.uniq'

const extractTenantIds = (responses) =>
  uniq(
    responses
      .map(({ data }) =>
        data.keyDateToMultiPropertyRentRollKpis?.[0]?.kpis
          ? data.keyDateToMultiPropertyRentRollKpis[0]?.kpis?.tenantRentRollKpis?.map(
              (tenantRentRollKpi) => tenantRentRollKpi?.tenant,
            )
          : undefined,
      )
      .flat(),
    // some testdata might have nullish or empty ("") tenant ids
  ).filter(Boolean)

export default extractTenantIds
