import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import documentPermissions from 'api/documents/DocumentPermissions'
import MarketDocumentsTable from 'components/domains/markets/detail/cards/MarketDocumentsTable'
import styles from 'components/domains/markets/detail/cards/MarketReportsCard.module.css'
import Card from 'components/ui/card/Card'
import DocumentFileDrop from 'components/ui/document/DocumentFileDrop'
import useCanFileDrop from 'components/ui/document/useCanFileDrop'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useDocumentsAllowedOperations from 'hooks/services/documents/useDocumentsAllowedOperations'
import useMarketReports from 'hooks/services/markets/useMarketReports'

const MarketReportsCard = ({ marketId, marketName }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.markets.detail.reports' })
  const { data: documentRoot, isLoading, isError } = useMarketReports({ marketId })

  const {
    data: allowedOperationsData,
    isLoading: isLoadingAllowedOperations,
    isError: isAllowedOperationsError,
  } = useDocumentsAllowedOperations()
  const hasDocumentReadPermission =
    allowedOperationsData?.allowedOperations.includes(documentPermissions.Read) ?? false
  const hasDocumentUploadPermission =
    allowedOperationsData?.allowedOperations.includes(documentPermissions.Create) ?? false

  const [files, setFiles] = useState(undefined)
  const handleOnDrop = (newFiles) => {
    setFiles(newFiles)
  }

  const { isOver, canDrop, dropRef } = useCanFileDrop()

  const toastRef = useRef(null)

  if (!hasDocumentReadPermission) return <></>
  return (
    <Card>
      <LoadingStateWrapper
        isLoading={isLoading || isLoadingAllowedOperations}
        isError={isError || isAllowedOperationsError}
        renderContent={() => (
          <div ref={dropRef} className={styles.content}>
            <div>
              <MarketDocumentsTable
                allowUploadDocument={hasDocumentUploadPermission}
                uploadInitialState={{ files }}
                marketId={marketId}
                marketName={marketName}
                documents={documentRoot}
                headerTitle={t('report', { count: 2 })}
                displayedColumns={['name', 'keyDate', 'download']}
                onUploadSuccess={() => setFiles(undefined)}
              />
            </div>
            {hasDocumentUploadPermission && (
              <DocumentFileDrop
                onDrop={handleOnDrop}
                isOver={isOver}
                canDrop={canDrop}
                ref={toastRef}
              />
            )}
          </div>
        )}
      />
    </Card>
  )
}

MarketReportsCard.propTypes = {
  marketId: PropTypes.string.isRequired,
  marketName: PropTypes.string.isRequired,
}

export default MarketReportsCard
