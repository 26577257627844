import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { queryParameterNames } from 'routes/deals/DealRoutes'

/**
 * @callback NavigateToWorkingVersionCallback
 * @param {'LIVE'|'WORKING_VERSION'} value - Version to switch to
 * @param {boolean} [invalidateDealHeaders] - When true, all queries for deals will be refetched while switching
 * @returns {void}
 */

/**
 * @param {object} params
 * @param {boolean} [params.displayToast] - If a toast should be displayed after switching the working version
 * @param {import('@fioneer/ui5-webcomponents-react').ToastPropTypes} [params.toastProps] - Props passed to the toast
 * @param {HTMLElement} [params.toastContainer] - Container of the toast, document body by default
 * @returns {NavigateToWorkingVersionCallback} - Callback to switch version
 */
const useNavigateToWorkingVersion = ({
  displayToast = false,
  toastProps = {},
  toastContainer,
} = {}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.deal-adjustment.switched-working-version',
  })
  const showToast = Modals.useShowToast()

  return (value = WorkingVersionType.LIVE, invalidateDealHeaders = false) => {
    if (invalidateDealHeaders) {
      queryClient.invalidateQueries(['deals'], { refetchActive: true })
    }
    const search = new URLSearchParams(window.location.search)
    if (value === WorkingVersionType.WORKING_VERSION) {
      search.set(queryParameterNames.workingVersion, 'true')
    } else {
      search.delete(queryParameterNames.workingVersion)
    }
    // HINT: search.size always returns 0
    navigate({ search: [...search.keys()].length ? `?${search.toString()}` : '' })
    if (displayToast) {
      showToast({ children: t(value), ...toastProps }, toastContainer)
    }
  }
}

export default useNavigateToWorkingVersion
