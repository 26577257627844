import {
  CustomListItem,
  Label,
  Link,
  LinkDesign,
  List,
  Popover,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { useState, useId } from 'react'
import { createPortal } from 'react-dom'
import styles from 'components/domains/deals/covenants/covenants-table/ReferencePopover.module.css'
import Entity from 'components/ui/data/Entity'

const ReferencePopover = ({
  linkText,
  listItems = [],
  linkBasePath,
  linkSuffix = '',
  errorText,
  disableLink = false,
  shouldDisplayEntityId = false,
}) => {
  const [popoverIsOpen, setPopoverIsOpen] = useState(false)
  // required since other popover can be rendered in helperDialog + table with the same id
  const uniqueId = useId()
  const allIds = listItems.map(({ uuid, id }) => uuid + id)
  const popoverId = `open-popover-${allIds.toString()}-${uniqueId}`

  return (
    <>
      <Link
        design={LinkDesign.Emphasized}
        data-testid="openPopover"
        id={popoverId}
        onClick={(e) => {
          setPopoverIsOpen(true)
          // required to prevent the click event from being propagated to the parent element (especially ui5 table)
          e.stopPropagation()
        }}
      >
        {`${linkText} (${listItems.length})`}
      </Link>
      {createPortal(
        <Popover
          id="popover"
          opener={popoverId}
          open={popoverIsOpen}
          onAfterClose={() => {
            setPopoverIsOpen(false)
          }}
          className={styles.popover}
        >
          <List>
            {listItems.map(({ id, displayId, name, individualLinkBasePath }, index) => {
              if (!id && !disableLink) {
                return (
                  <CustomListItem key={index}>
                    <Label className={styles.errorLabel} wrappingType={WrappingType.Normal}>
                      {errorText}
                    </Label>
                  </CustomListItem>
                )
              }

              const basePath = individualLinkBasePath || linkBasePath
              let link = `${basePath}/${id}`
              if (linkSuffix) {
                link = `${link}${linkSuffix}`
              }
              if (disableLink || !basePath) {
                link = ''
              }

              return (
                <CustomListItem key={index}>
                  <Entity
                    id={shouldDisplayEntityId ? displayId || id : null}
                    name={name}
                    link={link}
                    openInNewTab={true}
                  />
                </CustomListItem>
              )
            })}
          </List>
        </Popover>,
        document.body,
      )}
    </>
  )
}

ReferencePopover.propTypes = {
  linkText: PropTypes.string.isRequired,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      individualLinkBasePath: PropTypes.string,
    }),
  ).isRequired,
  linkBasePath: PropTypes.string.isRequired,
  linkSuffix: PropTypes.string,
  errorText: PropTypes.string.isRequired,
  disableLink: PropTypes.bool,
  shouldDisplayEntityId: PropTypes.bool,
}

export default ReferencePopover
