import { Icon, ListMode, SelectDialog, StandardListItem } from '@fioneer/ui5-webcomponents-react'
import find from 'lodash.find'
import { PropTypes } from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import styles from 'components/domains/properties/modular-table/toolbar/ModularToolbarColumnVisibleSettings.module.css'

const ModularToolbarColumnVisibleSettings = ({ columns, setColumns }) => {
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false)
  const [fieldNameSearchText, setFieldNameSearchText] = useState('')

  const onConfirmColumnVisibilitySetting = ({ detail }) => {
    setColumns(
      columns.map(({ columnKey, isSelectableForHiding, ...restColumn }) => ({
        columnKey,
        isSelectableForHiding,
        ...restColumn,
        isVisible: isSelectableForHiding
          ? !!find(detail.selectedItems, ['dataset.id', columnKey])?.selected
          : restColumn.isVisible,
      })),
    )
  }

  const columnsInSelectDialog = columns.filter(({ title, isSelectableForHiding }) => {
    const columnInFieldNameSearch =
      title && title.toLowerCase().includes(fieldNameSearchText.toLowerCase())

    return isSelectableForHiding && (!fieldNameSearchText || columnInFieldNameSearch)
  })

  return (
    !!columns?.length && (
      <div>
        <Icon
          name="action-settings"
          className={styles.settingsIcon}
          interactive
          onClick={() => setIsSettingsDialogOpen(true)}
        />
        {createPortal(
          <SelectDialog
            className={styles.selectDialog}
            open={isSettingsDialogOpen}
            mode={ListMode.MultiSelect}
            showClearButton={true}
            onConfirm={onConfirmColumnVisibilitySetting}
            onAfterClose={() => {
              setIsSettingsDialogOpen(false)
            }}
            rememberSelections={true}
            onSearchInput={(event) => {
              setFieldNameSearchText(event.target.value)
            }}
            onSearchReset={() => setFieldNameSearchText('')}
          >
            {columnsInSelectDialog.map(({ columnKey, title, isVisible }) => (
              <StandardListItem key={columnKey} data-id={columnKey} selected={isVisible}>
                {title}
              </StandardListItem>
            ))}
          </SelectDialog>,
          document.body,
        )}
      </div>
    )
  )
}

ModularToolbarColumnVisibleSettings.propTypes = {
  columns: PropTypes.array,
  setColumns: PropTypes.func,
}

export default ModularToolbarColumnVisibleSettings
