import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import AnnualReviewDealOverviewPropertySelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-selection/AnnualReviewDealOverviewPropertySelect'
import AnnualReviewPropertySelectPdfView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-selection/AnnualReviewPropertySelectPdfView'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/occupancy/TenancyOverviewOccupancyTile.module.css'
import TenancyOverviewVacancyOverTimeChartWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/occupancy/TenancyOverviewVacancyOverTimeChartWrapper'
import {
  useAreaMeasurementUnitFormatter,
  useNumberFormatter,
  usePercentageFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared/hooks/i18n/useI18n'
import useAnnualReviewDealOverviewSelectedProperty from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/utils/useAnnualReviewDealOverviewSelectedProperty'
import { ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/constants'
import CardHeaderWithMetrics from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/card/CardHeaderWithMetrics'
import { UsageTypeColorProvider } from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/UsageTypeColorContext'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const TenancyOverviewVacancyOverTimeTile = ({ tileId, selectedDealIndex, isPdfView }) => {
  const dispatch = useDispatch()

  const {
    data: { propertyRentRollOverviewByDealUuid, dealsData },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const selectedDealUuid = dealsData[selectedDealIndex]?.dealUuid

  const dealData =
    Object.values(propertyRentRollOverviewByDealUuid).find(
      ({ dealUuid }) => dealUuid === selectedDealUuid,
    ) ?? {}

  const { properties } = dealData

  const { selectedPropertyUuid, selectedPropertyDisplayId, selectedPropertyName } =
    useAnnualReviewDealOverviewSelectedProperty({
      propertiesData: properties,
      propertyMetadataTileCode: ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES.TENANCY_RENT_ROLL,
    })

  useEffect(() => {
    dispatch(setSourceRender({ tileId, sourceRender: { propertyId: selectedPropertyDisplayId } }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPropertyDisplayId])

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.property.rent-roll.overview.vacancy-over-time',
  })

  const formatArea = useAreaMeasurementUnitFormatter()
  const formatPercentage = usePercentageFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const formatNumber = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: 'compact',
  })

  const setValueOrUndefined = (value) => (value ? value : undefined)

  const renderContent = useCallback(
    (currentProperty) => {
      const currentPropertyUuid = currentProperty?.uuid
      const propertyData =
        dealData.propertyKpis?.find(({ propertyUuid }) => propertyUuid === currentPropertyUuid)
          ?.keyDateToRentRollKpis?.[0] ?? {}

      const propertyKpiData = { keyDate: propertyData.keyDate, ...propertyData.kpis }

      const historicalPropertyKpiData = dealData.historicalPropertyKpis?.find(
        ({ propertyUuid }) => propertyUuid === currentPropertyUuid,
      )?.keyDateToRentRollKpis

      const primaryMetric = {
        label: t('vacancy-rate-area'),
        value: propertyKpiData?.vacancySurface?.percent
          ? formatNumber(propertyKpiData.vacancySurface.percent * 100)
          : undefined,
        unit: '%',
      }

      const areaValue = (value, unit) => formatNumber(value) + ' ' + formatArea(unit)
      const vacantAreaValue = propertyKpiData?.vacancySurface?.value
        ? areaValue(
            propertyKpiData.vacancySurface.value,
            propertyKpiData?.vacancySurface?.measurementUnit,
          )
        : undefined
      const vacancyRate = propertyKpiData?.totalNumberOfUnits
        ? formatPercentage(
            propertyKpiData?.vacancyNumberOfUnits / propertyKpiData?.totalNumberOfUnits,
          )
        : undefined

      const secondaryMetrics = [
        { label: t('vacant-area'), value: vacantAreaValue },
        { label: t('vacancy-rate-number'), value: vacancyRate },
        {
          label: t('vacant-number'),
          value: setValueOrUndefined(propertyKpiData?.vacancyNumberOfUnits),
        },
      ]

      return (
        <div key={currentProperty?.uuid}>
          {isPdfView ? (
            <AnnualReviewPropertySelectPdfView {...currentProperty} />
          ) : (
            <AnnualReviewDealOverviewPropertySelect
              propertiesData={properties}
              propertyMetadataTileCode={
                ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES.TENANCY_RENT_ROLL
              }
            />
          )}
          <DecisionPaperTileListSeparator />
          <div className={styles.marginTop}>
            <CardHeaderWithMetrics
              title={t('title')}
              primaryMetric={primaryMetric}
              secondaryMetrics={secondaryMetrics}
              referenceDate={propertyKpiData?.keyDate}
              isLoading={false}
              isError={false}
              isOnDecisionPaper={true}
            />
          </div>
          <DecisionPaperTileListSeparator />
          <UsageTypeColorProvider>
            <TenancyOverviewVacancyOverTimeChartWrapper
              historicalPropertyKpiData={historicalPropertyKpiData}
            />
          </UsageTypeColorProvider>
        </div>
      )
    },
    [
      dealData.historicalPropertyKpis,
      dealData.propertyKpis,
      formatArea,
      formatNumber,
      formatPercentage,
      isPdfView,
      properties,
      t,
    ],
  )

  return isPdfView
    ? properties?.map((property) => renderContent(property))
    : renderContent({
        uuid: selectedPropertyUuid,
        id: selectedPropertyDisplayId,
        description: selectedPropertyName,
      })
}

TenancyOverviewVacancyOverTimeTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default TenancyOverviewVacancyOverTimeTile
