import {
  GCC_UNIT_ID,
  REVIEW_UNIT_ID,
  STANDALONE_BP_UNIT_ID,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/constants'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useAuthorityLevelCalculatorList = (businessPartnerId) => {
  const result = useCamelizedResponse(
    useRequest({
      path: `/pbb-custom-service/businesspartners/${businessPartnerId}/authority-level-calculator/list`,
      translated: true,
      keys: ['businesspartners', businessPartnerId, 'authority-level-calculator', 'list'],
      options: {
        enabled: !!businessPartnerId,
      },
    }),
  )

  const gcc = result?.data?.alcDropdown?.filter((unit) => unit?.unitTypeId === GCC_UNIT_ID) ?? []
  const review =
    result?.data?.alcDropdown?.filter((unit) => unit?.unitTypeId === REVIEW_UNIT_ID) ?? []
  const standalone =
    result?.data?.alcDropdown?.filter((unit) => unit?.unitTypeId === STANDALONE_BP_UNIT_ID) ?? []

  return {
    ...result,
    data: {
      gcc,
      review,
      standalone,
    },
  }
}

export default useAuthorityLevelCalculatorList
