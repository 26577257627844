import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useRequirementsCellEditMode = ({ requirementId }) => {
  const editedRowRequirementId = useSelector(
    (state) => state.conditions.requirementsTable.editedRow.requirementId,
  )
  const isEditModeForCurrentRow = useMemo(
    () => editedRowRequirementId === requirementId,
    [editedRowRequirementId, requirementId],
  )

  const isEditModeForAnyRow = useMemo(() => !!editedRowRequirementId, [editedRowRequirementId])

  return useMemo(
    () => ({
      editedRowRequirementId,
      isEditModeForCurrentRow,
      isEditModeForAnyRow,
    }),
    [editedRowRequirementId, isEditModeForCurrentRow, isEditModeForAnyRow],
  )
}

export default useRequirementsCellEditMode
