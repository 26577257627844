import { Button } from '@fioneer/ui5-webcomponents-react'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CashFlowScenarioSelectDialog from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/with-meta-data/cash-flow/CashFlowScenarioSelectDialog'
import { editButtonStateEnum } from 'routes/deals/covenant-checks/useCalculateEditButtonState'

const CashFlowScenarioCardHeaderSelectAction = ({
  dealUuid,
  linkedCashflowScenarioUuid,
  cashFlowScenario,
  setCashFlowScenario,
  handleSaveSelectedScenario,
  selectButtonState,
}) => {
  const { t: tCashFlow } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.checked-covenants.cash-flow-scenario',
  })

  const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false)

  const onSelectButtonClick = useCallback(
    () => setIsSelectDialogOpen(true),
    [setIsSelectDialogOpen],
  )

  const selectButton = selectButtonState !== editButtonStateEnum.HIDDEN && (
    <Button
      key="select-linked-cash-flow-scenario-button"
      id="select-linked-cash-flow-scenario-button"
      slot="action"
      onClick={onSelectButtonClick}
      disabled={selectButtonState === editButtonStateEnum.DISABLED}
    >
      {tCashFlow('select')}
    </Button>
  )

  const lastSelectedScenario = { selectedCashFlowScenarioUuid: linkedCashflowScenarioUuid }

  if (isSelectDialogOpen) {
    return (
      <CashFlowScenarioSelectDialog
        isSelectDialogOpen={isSelectDialogOpen}
        setIsSelectDialogOpen={setIsSelectDialogOpen}
        handleSaveSelectedScenario={handleSaveSelectedScenario}
        dealUuid={dealUuid}
        cashFlowScenarioContent={cashFlowScenario}
        setCashFlowScenarioContent={setCashFlowScenario}
        hasChanges={
          !isEqual(cashFlowScenario?.selectedCashFlowScenarioUuid, linkedCashflowScenarioUuid)
        }
        lastSelectedScenario={lastSelectedScenario}
        tCashFlow={tCashFlow}
      />
    )
  }
  return selectButton
}

CashFlowScenarioCardHeaderSelectAction.propTypes = {
  dealUuid: PropTypes.string,
  linkedCashflowScenarioUuid: PropTypes.string,
  cashFlowScenario: PropTypes.shape({
    selectedCashFlowScenarioUuid: PropTypes.string,
    selectedCashFlowScenarioName: PropTypes.string,
  }),
  setCashFlowScenario: PropTypes.func,
  handleSaveSelectedScenario: PropTypes.func,
  selectButtonState: PropTypes.oneOf(Object.values(editButtonStateEnum)),
}

export default CashFlowScenarioCardHeaderSelectAction
