import { Input } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { assetValuationOverviewFilters } from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/toolbar/assetValuationOverviewPropTypes'

const TextInput = ({ value, selectedFilters, setSelectedFilters, columnKey }) => (
  <Input
    value={value || ''}
    onInput={(e) => {
      setSelectedFilters(
        selectedFilters.map((filter) =>
          filter.columnKey === columnKey ? { ...filter, value: e.target.value } : filter,
        ),
      )
    }}
    style={{ margin: '0' }}
  />
)

TextInput.propTypes = {
  value: PropTypes.string,
  selectedFilters: assetValuationOverviewFilters.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  columnKey: PropTypes.string.isRequired,
}

export default TextInput
