import { ObjectPageSection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PropertyPortfolioRentRollDetailsTab from 'components/domains/properties/portfolio/rent-roll/PropertyPortfolioRentRollDetailsTab'
import PropertyPortfolioRentRollAnalysisTab from 'components/domains/properties/portfolio/rent-roll/analysis/PropertyPortfolioRentRollAnalysisTab'
import PropertyPortfolioRentRollOverviewTab from 'components/domains/properties/portfolio/rent-roll/overview/PropertyPortfolioRentRollOverviewTab'
import PropertyPortfolioSegmentsTab from 'components/domains/properties/portfolio/rent-roll/segments/PropertyPortfolioSegmentsTab'
import TabPage from 'components/ui/page/TabPage'

const PropertyPortfolioRentRoll = ({ header }) => {
  const { t: tRentRollTabs } = useTranslation('translation', {
    keyPrefix: 'pages.properties.rent-roll.tabs',
  })

  const sections = {
    overview: 'overview',
    segments: 'segments',
    details: 'details',
    analysis: 'analysis',
  }

  return (
    <TabPage headerTitle={header} defaultSectionId={sections.overview} includeUrlQuery>
      <ObjectPageSection
        key={sections.overview}
        id={sections.overview}
        titleText={tRentRollTabs(sections.overview)}
      >
        <PropertyPortfolioRentRollOverviewTab />
      </ObjectPageSection>
      <ObjectPageSection
        key={sections.segments}
        id={sections.segments}
        titleText={tRentRollTabs(sections.segments)}
      >
        <PropertyPortfolioSegmentsTab />
      </ObjectPageSection>
      <ObjectPageSection
        key={sections.details}
        id={sections.details}
        titleText={tRentRollTabs(sections.details)}
      >
        <PropertyPortfolioRentRollDetailsTab />
      </ObjectPageSection>
      <ObjectPageSection
        key={sections.analysis}
        id={sections.analysis}
        titleText={tRentRollTabs(sections.analysis)}
      >
        <PropertyPortfolioRentRollAnalysisTab />
      </ObjectPageSection>
    </TabPage>
  )
}

PropertyPortfolioRentRoll.propTypes = {
  header: PropTypes.node.isRequired,
}

export default PropertyPortfolioRentRoll
