import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'

/**
 * Returns all generic valuation create method codes, which can be used for crud operations on valuations
 * The codes structure is as follows:
 * {
 *   "valuation_create_method_codes": [
 *        {
 *           "creation_method_code": "MARKET_VALUE",
 *           "key": "Z910000010",
 *           "display_name": "Erster Marktwert"
 *        },
 *        {
 *           "creation_method_code": "PURCHASE_PRICE",
 *           "key": "Z910000006",
 *           "display_name": "Anschaffungswert"
 *        },
 *        {
 *           "creation_method_code": "MORTGAGE_LENDING_VALUE",
 *           "key": "Z910000014",
 *           "display_name": "Erster BLW (Orig.)"
 *        }
 *   ]
 * }
}
 * @returns {{isLoading: boolean, isError: boolean, data: ValuationCreateMethodCodes}}
 *
 */
const emptyArray = []

export const useGenericValuationCreateMethodCodes = (options) => {
  const { data, ...response } = useRequest({
    path: `/properties/generic-valuation-create-method-codes`,
    translated: true,
    options,
  })

  return useMemo(
    () => ({
      ...response,
      data: data?.valuation_create_method_codes ?? emptyArray,
    }),
    [data?.valuation_create_method_codes, response],
  )
}
