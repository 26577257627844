import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  ResponsiveGridLayout,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/borrower/BorrowerOverviewTile.module.css'
import SponsorGuarantorOverviewLabeledValue from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/SponsorGuarantorOverviewLabeledValue'
import {
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const SponsorGuarantorOverviewFinancialIndicators = ({
  businessPartner: { financialIndicators },
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.bp-sponsor-guarantor-overview.financial-indicators',
  })
  const formatCurrency = useCustomizableCurrencyFormatter()
  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const { format: formatDate } = useShortDateFormatter()

  const content = useMemo(() => {
    if (isNil(financialIndicators)) {
      return (
        <FlexBox
          className={styles.listCardEmptyContent}
          alignItems={FlexBoxAlignItems.Center}
          justifyContent={FlexBoxJustifyContent.Center}
        >
          {t('empty')}
        </FlexBox>
      )
    }
    return (
      <ResponsiveGridLayout columnsXL={2} columnsL={2} columnsM={2} columnsS={2}>
        <FlexBox direction={FlexBoxDirection.Column}>
          {!isNil(financialIndicators.keyDate) && (
            <SponsorGuarantorOverviewLabeledValue
              label={t('key-date')}
              value={formatDate(financialIndicators.keyDate)}
            />
          )}
          {!isNil(financialIndicators.turnover?.value) && (
            <SponsorGuarantorOverviewLabeledValue
              label={financialIndicators.turnover.label}
              value={formatCurrency(
                financialIndicators.turnover.value.value,
                financialIndicators.turnover.value.unit,
              )}
            />
          )}
          {!isNil(financialIndicators.ebitda?.value) && (
            <SponsorGuarantorOverviewLabeledValue
              label={financialIndicators.ebitda.label}
              value={formatCurrency(
                financialIndicators.ebitda.value.value,
                financialIndicators.ebitda.value.unit,
              )}
            />
          )}
          {!isNil(financialIndicators.netResult?.value) && (
            <SponsorGuarantorOverviewLabeledValue
              label={financialIndicators.netResult.label}
              value={formatCurrency(
                financialIndicators.netResult.value.value,
                financialIndicators.netResult.value.unit,
              )}
            />
          )}
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column}>
          {!isNil(financialIndicators.totalAssets?.value) && (
            <SponsorGuarantorOverviewLabeledValue
              label={financialIndicators.totalAssets.label}
              value={formatCurrency(
                financialIndicators.totalAssets.value.value,
                financialIndicators.totalAssets.value.unit,
              )}
            />
          )}
          {!isNil(financialIndicators.equityCapital?.value) && (
            <SponsorGuarantorOverviewLabeledValue
              label={financialIndicators.equityCapital.label}
              value={formatCurrency(
                financialIndicators.equityCapital.value.value,
                financialIndicators.equityCapital.value.unit,
              )}
            />
          )}
          {!isNil(financialIndicators.equityRatio?.value) && (
            <SponsorGuarantorOverviewLabeledValue
              label={financialIndicators.equityRatio.label}
              value={`${formatNumber(financialIndicators.equityRatio.value.value)} ${
                financialIndicators.equityRatio.value.unit
              }`}
            />
          )}
        </FlexBox>
      </ResponsiveGridLayout>
    )
  }, [financialIndicators, formatCurrency, formatDate, formatNumber, t])

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.listCard}>
      <Text className={styles.listCardTitle}>{t('title')}</Text>
      {content}
    </FlexBox>
  )
}

SponsorGuarantorOverviewFinancialIndicators.propTypes = {
  businessPartner: PropTypes.shape({
    financialIndicators: PropTypes.shape({
      keyDate: PropTypes.string,
      turnover: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.shape({
          value: PropTypes.number,
          unit: PropTypes.string,
        }),
      }),
      ebitda: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.shape({
          value: PropTypes.number,
          unit: PropTypes.string,
        }),
      }),
      netResult: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.shape({
          value: PropTypes.number,
          unit: PropTypes.string,
        }),
      }),
      totalAssets: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.shape({
          value: PropTypes.number,
          unit: PropTypes.string,
        }),
      }),
      equityCapital: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.shape({
          value: PropTypes.number,
          unit: PropTypes.string,
        }),
      }),
      equityRatio: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.shape({
          value: PropTypes.number,
          unit: PropTypes.string,
        }),
      }),
    }),
  }),
}

export default SponsorGuarantorOverviewFinancialIndicators
