import find from 'lodash.find'
import isEmpty from 'lodash.isempty'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useEnrichPropertyWithStatus } from 'hooks/services/properties/useEnrichPropertyWithStatus'
import usePropertyUuids from 'hooks/services/properties/usePropertyUuids'
import useCombinedUserFavoritesAndRecentVisits from 'hooks/services/user-profile/useCombinedUserFavoritesAndRecentVisits'
import { getUniqueIds } from 'utils/userFavoriteUtils'

const mapPropertyToFavoriteAndRecentVisit = (favsAndRecVisits = [], properties = []) =>
  favsAndRecVisits.map((favAndRecVisit) => {
    const foundProperty = find(properties, (property) => property.uuid === favAndRecVisit.entityId)
    if (!foundProperty) {
      return {
        ...favAndRecVisit,
        notFound: true,
      }
    }

    const {
      id,
      uuid,
      description,
      address: { cityName, countryName },
      typeName,
      propertyStatus,
    } = foundProperty

    return {
      ...favAndRecVisit,
      id,
      uuid,
      description,
      cityName,
      countryName,
      typeName,
      propertyStatus,
      notFound: false,
    }
  })

const useGetAndEnrichFavoritesAndRecentVisitsWithProperties = () => {
  const propertyFavsAndRecVisitsResult = useCombinedUserFavoritesAndRecentVisits({
    entityType: cwpEntityTypes.PROPERTY,
  })
  const uniquePropertyIds = getUniqueIds(propertyFavsAndRecVisitsResult.data)
  const propertiesResult = usePropertyUuids(
    uniquePropertyIds,
    { enabled: !isEmpty(uniquePropertyIds) },
    true,
  )
  const properties = propertiesResult?.data?.data?.properties
  const propertiesWithStatus = useEnrichPropertyWithStatus(properties ?? [])

  const isLoading =
    propertyFavsAndRecVisitsResult.isLoading ||
    (propertiesResult.isLoading && !isEmpty(uniquePropertyIds))
  const isError = propertyFavsAndRecVisitsResult.isError
  const data =
    isLoading || isError
      ? undefined
      : mapPropertyToFavoriteAndRecentVisit(
          propertyFavsAndRecVisitsResult.data,
          propertiesWithStatus,
        )

  return { isLoading, isError, data }
}

export default useGetAndEnrichFavoritesAndRecentVisitsWithProperties
