import { ValueState } from '@fioneer/ui5-webcomponents-react'

export const RISK_INDICATOR_TYPE_CODE_CATEGORIES = Object.freeze({
  STATUS: 'RISK_INDICATOR_STATUS',
  CRITICALITY: 'RISK_INDICATOR_CRITICALITY',
  REFERENCE_TYPE: 'RISK_INDICATOR_REFERENCE_TYPE',
  INDICATOR_TYPE: 'RISK_INDICATOR_TYPE',
  INDICATOR_GROUP_FILTER_EVENT_TYPE: 'FILTER_EVENT_TYPE',
  RISK_INDICATOR_CRITICALITY_RESOLVED: 'RISK_INDICATOR_CRITICALITY_RESOLVED',
})

export const VALUE_TYPE_CODES = Object.freeze({
  DATE: 'DATE',
  DATE_TIME: 'DATE_TIME',
  STRING: 'STRING',
  BOOLEAN: 'BOOLEAN',
  CURRENCY: 'CURRENCY',
  MEASUREMENT: 'MEASUREMENT',
  ENUM: 'ENUM',
  PERCENTAGE: 'PERCENTAGE',
  NUMBER: 'NUMBER',
  INTEGER: 'INTEGER',
})

export const REFERENCE_TYPE_CODES = Object.freeze({
  DEAL: 'Deal',
  PROPERTY: 'Property',
  BUSINESS_PARTNER: 'BusinessPartner',
})

export const STATUS_TYPE_CODES = Object.freeze({
  OPEN: '10',
  ACTIVE: '20',
  CLOSED: '30',
})

export const CRITICALITY_STATUS_TYPE_CODES = Object.freeze({
  RESOLVED: '0',
  FACILITY_IN_FOCUS: '20',
  WATCHLIST: '40',
  RESTRUCTURING: '60',
  IMPAIRMENT: '80',
})

export const riskIndicatorStatusMapping = Object.freeze({
  STATUS: {
    [STATUS_TYPE_CODES.OPEN]: {
      valueState: ValueState.Information,
    },
    [STATUS_TYPE_CODES.ACTIVE]: {
      valueState: ValueState.Success,
    },
    [STATUS_TYPE_CODES.CLOSED]: {
      valueState: ValueState.None,
    },
  },
  CRITICALITY: {
    [CRITICALITY_STATUS_TYPE_CODES.IMPAIRMENT]: {
      valueState: ValueState.Error,
      order: 80,
    },
    [CRITICALITY_STATUS_TYPE_CODES.FACILITY_IN_FOCUS]: {
      valueState: ValueState.None,
      order: 20,
    },
    [CRITICALITY_STATUS_TYPE_CODES.WATCHLIST]: {
      valueState: ValueState.Information,
      order: 40,
    },
    [CRITICALITY_STATUS_TYPE_CODES.RESTRUCTURING]: {
      valueState: ValueState.Warning,
      order: 60,
    },
    [CRITICALITY_STATUS_TYPE_CODES.RESOLVED]: {
      valueState: ValueState.Success,
      order: 0,
    },
  },
})
