import {
  DynamicPage,
  Button,
  Label,
  Icon,
  TableRowType,
  ButtonDesign,
  IllustrationMessageType,
  IllustrationMessageSize,
  WrappingType,
  FlexBoxAlignItems,
  DynamicPageHeader,
  TableGrowingMode,
  CheckBox,
} from '@fioneer/ui5-webcomponents-react'
import _ from 'lodash'
import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { marketAllowedOperations } from 'api/markets/marketAllowedOperations'
import { isMissingPermissionError } from 'api/requests'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import DocumentUploadDialog from 'components/domains/documents/DocumentUploadDialog'
import MarketsCreationDialog from 'components/domains/markets/MarketsCreationDialog'
import MarketsOverviewFilterBar from 'components/domains/markets/filterbar/MarketsOverviewFilterBar'
import Card from 'components/ui/card/Card'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import useNavigate from 'components/ui/link/useNavigate'
import Header from 'components/ui/page/Header'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import '@ui5/webcomponents-fiori/dist/illustrations/NoData.js'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import useCtrlOrMetaKeyPressed from 'components/useCtrlOrMetaPressed'
import useMarkets from 'hooks/services/markets/useMarkets'
import useMarketsAllowedOperations from 'hooks/services/markets/useMarketsAllowedOperations'
import styles from 'routes/markets/MarketsOverview.module.css'
import paths from 'routes/paths'

const defaultSelectedMarkets = {}

const calculateMarketTypes = (queryValue) => {
  if (queryValue === null) return []
  return queryValue?.split(',')
}

const calculateFilterOptions = (queryParams) => ({
  searchFilter: queryParams.get('search_filter'),
  marketTypes: calculateMarketTypes(queryParams.get('market_types')),
  marketCountry: queryParams.get('market_country'),
})

const marketObjectToMarketArray = (selectedMarketsObject) =>
  Object.entries(selectedMarketsObject).map(([id, name]) => ({
    id,
    name,
    type: DocumentTypes.Market,
  }))

const MarketsOverview = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.markets.overview' })
  const { t: tNoPrefix } = useTranslation()

  const [queryParams, updateQueryParams] = useSearchParams()
  const [sorting, setSorting] = useState({ sortBy: 'market_name', orderBy: 'asc' })
  const [selectedMarkets, setSelectedMarkets] = useState(defaultSelectedMarkets)
  const [isDocumentUploadDialogOpen, setIsDocumentUploadDialogOpen] = useState(false)

  const initialMarketTypes = useMemo(
    () => queryParams.get('market_types')?.split(',') ?? [],
    [queryParams],
  )

  const {
    isLoading,
    isError,
    isFetchingNextPage,
    error,
    data: marketsData,
    fetchNextPage,
    hasNextPage,
  } = useMarkets(sorting, calculateFilterOptions(queryParams))

  const {
    isLoading: isAllowedOperationsLoading,
    isError: isAllowedOperationsError,
    data: allowedOperationsData,
  } = useMarketsAllowedOperations()
  const navigate = useNavigate()
  const [isCreationDialogOpen, setIsCreationDialogOpen] = useState(false)

  const onFilter = ({ search_filter, market_types, market_country }) => {
    const trimmedSearchFilter = search_filter.trim().toLowerCase()
    updateQueryParams(
      _.pickBy({
        search_filter: trimmedSearchFilter,
        market_types: market_types?.join(','),
        market_country,
      }),
    )
  }

  const customOrderCallback = (columnKey, orderBy) => {
    setSorting({ sortBy: columnKey, orderBy })
  }

  const customOrderFunction = (tableData) => tableData

  const onMarketCreationButtonClicked = () => {
    setIsCreationDialogOpen(true)
  }

  const displayMarkets = allowedOperationsData?.allowed_operations.includes(
    marketAllowedOperations.viewMarket,
  )
  const displayCreateButton =
    displayMarkets &&
    allowedOperationsData?.allowed_operations.includes(marketAllowedOperations.createMarket)

  const headerButtons = (
    <>
      {displayCreateButton && (
        <Button
          key="create-market"
          id="create-market"
          design={ButtonDesign.Emphasized}
          onClick={onMarketCreationButtonClicked}
        >
          {t('button.create')}
        </Button>
      )}
    </>
  )

  const breadcrumbs = [
    {
      text: t('breadcrumb.title'),
    },
  ]

  const headerTitle = (
    <Header title={t('title')} actions={headerButtons} breadcrumbs={breadcrumbs} />
  )

  const renderFilterBar = () => (
    <DynamicPageHeader className={styles['dynamic-page-header']}>
      <MarketsOverviewFilterBar
        onGo={onFilter}
        searchFilter={calculateFilterOptions(queryParams).searchFilter ?? ''}
        marketTypes={initialMarketTypes}
        marketCountry={calculateFilterOptions(queryParams).marketCountry ?? ''}
      />
    </DynamicPageHeader>
  )

  const deselectAllCurrentlyRenderedMarkets = () => {
    setSelectedMarkets((currentState) => {
      const newState = { ...currentState }
      marketsData.markets.forEach(({ id: marketId }) => {
        delete newState[marketId]
      })
      return newState
    })
  }

  const selectAllCurrentlyRenderedMarkets = () => {
    setSelectedMarkets((currentState) => {
      const newState = { ...currentState }
      marketsData.markets.forEach(({ id: marketId, info: { name: marketName } }) => {
        newState[marketId] = marketName
      })
      return newState
    })
  }

  const handleSelectAllMarketsCheckboxChanged = ({ target: { checked: isCheckboxChecked } }) => {
    isCheckboxChecked ? selectAllCurrentlyRenderedMarkets() : deselectAllCurrentlyRenderedMarkets()
  }

  const handleSelectSingleMarketCheckboxChanged =
    ({ marketId, marketName }) =>
    ({ target: { checked: isCheckboxChecked } }) => {
      if (isCheckboxChecked) {
        setSelectedMarkets((oldState) => {
          const newState = { ...oldState }
          newState[marketId] = marketName
          return newState
        })
        return
      }

      setSelectedMarkets((oldState) => {
        const newState = { ...oldState }
        delete newState[marketId]
        return newState
      })
    }

  const onUploadDocumentButtonClicked = () => {
    setIsDocumentUploadDialogOpen(true)
  }

  const isCtrlOrMetaPressed = useCtrlOrMetaKeyPressed()

  const handleRowClick = (url, target) => {
    navigate(url, { target: target ? '_blank' : undefined })
  }

  const additionalTableProperties = {
    onRowClick: ({ detail: { row: clickedTableRow } }) => {
      const marketId = clickedTableRow.getAttribute('data-market-id')
      handleRowClick(`/${paths.markets}/${marketId}`, isCtrlOrMetaPressed)
    },
    busyDelay: 0,
    busy: isFetchingNextPage,
  }

  const renderMarketsOverviewPage = () => {
    if (!displayMarkets)
      return (
        <CenteredIllustratedMessage
          name={IllustrationMessageType.UnableToLoad}
          size={IllustrationMessageSize.Spot}
          titleText={tNoPrefix('app.permission-error.title')}
          subtitleText={tNoPrefix('app.permission-error.subtitle')}
        />
      )

    const areCurrentlyShownMarketsAllSelected = () =>
      marketsData.markets.every(({ id: searchMarketId }) => selectedMarkets[searchMarketId])

    const isSelectAllMarketsCheckboxChecked =
      marketsData.markets.length > 0 && areCurrentlyShownMarketsAllSelected()

    const marketsColumns = [
      {
        renderColumnContent: () => (
          <CheckBox
            disabled={marketsData.markets.length === 0}
            checked={isSelectAllMarketsCheckboxChecked}
            onChange={handleSelectAllMarketsCheckboxChanged}
          />
        ),
        columnKey: 'checkbox',
        sortingDisabled: true,
        isSelectableForHiding: false,
        className: styles.tableCheckboxColumn,
      },
      {
        title: t('table.title-row.name'),
        columnKey: 'market_name', // columnKeys reflect sortBy key needed in the backend
      },
      {
        title: t('table.title-row.type'),
        columnKey: 'market_type',
        minWidth: 1440,
        demandPopin: true,
        popinText: t('table.title-row.type'),
      },
      {
        title: t('table.title-row.description'),
        columnKey: 'market_description',
        minWidth: 860,
        demandPopin: true,
        popinText: t('table.title-row.description'),
        style: { width: '550px' },
      },
      {
        title: t('table.title-row.country'),
        columnKey: 'market_country',
        minWidth: 1280,
        demandPopin: true,
        popinText: t('table.title-row.country'),
      },
      {
        title: t('table.title-row.properties'),
        columnKey: 'market_assigned_properties_count',
        minWidth: 1000,
        demandPopin: true,
        popinText: t('table.title-row.properties'),
        alignment: FlexBoxAlignItems.End,
      },
      {
        title: '',
        columnKey: 'arrow',
        sortingDisabled: true,
        isSelectableForHiding: false,
        alignment: FlexBoxAlignItems.End,
      },
    ]

    const mappedTableData = marketsData.markets.map(
      ({
        id: marketId,
        info: { name: marketName, description },
        area: {
          country: { name: countryName },
        },
        marketType: { value: translatedMarketType },
        properties,
      }) => ({
        rowKey: `market-overview-${marketId}`,
        rowProperties: {
          type: TableRowType.Active,
          'data-market-id': marketId,
        },
        checkbox: {
          cellComponent: (
            <CheckBox
              checked={!!selectedMarkets[marketId]}
              onChange={handleSelectSingleMarketCheckboxChanged({ marketId, marketName })}
            />
          ),
        },
        market_name: {
          value: marketName,
          cellComponent: (
            <Label>
              <b>{marketName}</b>
            </Label>
          ),
        },
        market_type: {
          value: translatedMarketType,
          cellComponent: <Label>{translatedMarketType}</Label>,
        },
        market_country: {
          value: countryName,
          cellComponent: <Label>{countryName}</Label>,
        },
        market_assigned_properties_count: {
          value: properties.length,
          cellComponent: (
            <Label className={styles.propertiesCountColumn}>{properties.length}</Label>
          ),
        },
        market_description: {
          value: description,
          cellComponent: <Label wrappingType={WrappingType.Normal}>{description}</Label>,
        },
        arrow: {
          cellComponent: <Icon name="slim-arrow-right" />,
        },
      }),
    )

    const calculateNoDataText = () => {
      const filters = calculateFilterOptions(queryParams)
      if (
        filters.searchFilter !== null ||
        filters.marketTypes.length !== 0 ||
        filters.marketCountry !== null
      ) {
        return t('empty-filter')
      }

      return t('empty')
    }

    const uploadDocumentButton = (
      <Button
        key="markets-overview-upload-document-button"
        disabled={Object.keys(selectedMarkets).length === 0}
        onClick={onUploadDocumentButtonClicked}
      >
        {t('table.toolbar.button.reports')}
      </Button>
    )

    return (
      <>
        {renderFilterBar()}
        <div className={styles['table-wrapper']}>
          <SortedTable
            columnDefinitions={marketsColumns}
            tableData={mappedTableData}
            additionalTableProperties={additionalTableProperties}
            customOrderFunction={customOrderFunction}
            customOrderCallback={customOrderCallback}
            toolbarConfig={{
              title: t('table.toolbar.title'),
              sorting: {
                columnKey: sorting.sortBy,
                isSortingAscending: sorting.orderBy === 'asc',
              },
              additionalActions: [uploadDocumentButton],
            }}
            noDataText={calculateNoDataText()}
            paginationConfig={{
              growing: hasNextPage ? TableGrowingMode.Button : undefined,
              growingButtonText: tNoPrefix(
                'components.ui.tables.sorted-tables.growing-button-text',
              ),
              growingButtonSubtext: `[${mappedTableData.length} / ${marketsData.total}]`,
              totalNumberOfItems: marketsData.total,
              loadMore: () => fetchNextPage(),
            }}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <DynamicPage
        id="market-overview-dynamic-page"
        showHideHeaderButton={false}
        headerContentPinnable={false}
        headerTitle={headerTitle}
      >
        <Card>
          <LoadingStateWrapper
            isError={(isError && !isMissingPermissionError(error)) || isAllowedOperationsError}
            isLoading={isLoading || isAllowedOperationsLoading}
            renderContent={renderMarketsOverviewPage}
            errorTitle={t('error.title')}
            errorDescription={t('error.description')}
            errorDetails={t('error.details')}
          />
        </Card>
      </DynamicPage>
      <MarketsCreationDialog isOpen={isCreationDialogOpen} setIsOpen={setIsCreationDialogOpen} />
      <DocumentUploadDialog
        isOpen={isDocumentUploadDialogOpen}
        setIsOpen={setIsDocumentUploadDialogOpen}
        initialState={{ selectedEntities: marketObjectToMarketArray(selectedMarkets) }}
        type={DocumentTypes.Market}
      />
    </>
  )
}

export default MarketsOverview
