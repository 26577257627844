import { Icon, TableRowType, Text } from '@fioneer/ui5-webcomponents-react'
import isEqual from 'lodash.isequal'
import uniqBy from 'lodash.uniqby'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import BreachCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/covenants-ratios/shared/BreachCell'
import StatusCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/covenants-ratios/shared/StatusCell'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/covenants-ratios/shared/domains/deals/covenants/covenant-checks-table/CovenantChecksTable.module.css'
import Entity from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/data/Entity'
import ReferenceEntitiesView, {
  entitiesQuantity,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/data/ReferenceEntitiesView'
import { ReferenceEntityType } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/data/ReferenceEntityType'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import paths from 'routes/paths'

export const covenantChecksBreachMap = new Map()
covenantChecksBreachMap.set(true, 'YES')
covenantChecksBreachMap.set(false, 'NO')

const useCovenantChecksTableData = ({
  data,
  allDealsData,
  allEventsData,
  isAllowedToReadCovenants = true,
  isCovenantCheckClickable,
}) => {
  const { format: formatDate } = useShortDateFormatter()
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.deals.covenants.monitoring.covenant-checks',
  })
  const allDealUuidsToDealIds = useMemo(
    () =>
      allDealsData?.reduce(
        (accumulator, { dealUuid, dealId, name }) => ({
          ...accumulator,
          [dealUuid]: { dealId: dealId, name: name },
        }),
        {},
      ) || {},
    [allDealsData],
  )

  const allEventsObject = allEventsData?.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {})

  const getCovenantsReferenceEntitiesProps = (covenants) =>
    covenants.length === 1
      ? {
          quantity: entitiesQuantity.single,
          link:
            isAllowedToReadCovenants &&
            `/${paths.deals}/${allDealUuidsToDealIds[covenants[0].mainEntity.id]?.dealId}/${
              dealDetailPaths.covenants
            }/${covenants[0].covenantUuid}`,
          name: covenants[0].name,
        }
      : {
          quantity: entitiesQuantity.multiple,
          listItems: covenants.map((covenant) => ({
            id: covenant.covenantUuid,
            name: covenant.name,
            individualLinkBasePath: `/${paths.deals}/${
              allDealUuidsToDealIds[covenant.mainEntity.id]?.dealId
            }/${dealDetailPaths.covenants}`,
          })),
          linkBasePath: '',
          linkText: t('link.covenants'),
          disableLink: !isAllowedToReadCovenants,
          shouldDisplayEntityId: false,
        }

  const getDealsReferenceEntitiesProps = (covenantCheckDeals) =>
    covenantCheckDeals.length === 1
      ? {
          quantity: entitiesQuantity.single,
          id: covenantCheckDeals[0].dealId,
          link: isAllowedToReadCovenants && `/${paths.deals}/${covenantCheckDeals[0].dealId}`,
          name: covenantCheckDeals[0].name,
        }
      : {
          quantity: entitiesQuantity.multiple,
          listItems: covenantCheckDeals.map((deal) => ({
            id: deal.dealId,
            name: deal.name,
          })),
          linkBasePath: `/${paths.deals}`,
          linkText: t('link.deals'),
          disableLink: !isAllowedToReadCovenants,
          shouldDisplayEntityId: false,
        }

  const getMonitoringReferenceEntitiesProps = (monitoringReference) => ({
    quantity: entitiesQuantity.single,
    id: monitoringReference?.dealId,
    link: isAllowedToReadCovenants && `/${paths.deals}/${monitoringReference?.dealId}`,
    name: monitoringReference?.name,
  })

  const getCovenantCheckEventProps = (event) => ({
    name: event?.info?.name,
    displayId: event?.displayId,
  })

  return data.map(
    ({
      covenantCheckUuid,
      breach,
      covenants,
      keyDate,
      firstTargetDeliveryDate,
      name,
      status,
      monitoringReferenceEntity,
      linkedEventId,
    }) => {
      const covenantsReferenceEntitiesProps = getCovenantsReferenceEntitiesProps(covenants)
      const covenantCheckDeals = uniqBy(
        covenants.map((covenant) => ({
          ...allDealUuidsToDealIds[covenant.mainEntity.id],
        })),
        'dealId',
      ).filter((covenantCheckDeal) => !isEqual(covenantCheckDeal, {}))

      const dealsReferenceEntitiesProps = getDealsReferenceEntitiesProps(covenantCheckDeals)
      const covenantCheckEntityProps = {
        name: name,
        link: undefined,
        openInNewTab: undefined,
      }
      if (isCovenantCheckClickable) {
        covenantCheckEntityProps.openInNewTab = true
        switch (monitoringReferenceEntity.type) {
          case ReferenceEntityType.Deal:
            covenantCheckEntityProps.link = `/${paths.deals}/${
              allDealUuidsToDealIds[monitoringReferenceEntity.id]?.dealId
            }/${dealDetailPaths.covenants}/${dealDetailPaths.covenantChecks}/${covenantCheckUuid}`
            break
          case ReferenceEntityType.BusinessPartner:
            covenantCheckEntityProps.link = `/${paths.businessPartners}/${monitoringReferenceEntity.id}/covenants/covenant-checks/${covenantCheckUuid}`
            break
        }
      }

      const covenantCheckReferenceEntityProps = getMonitoringReferenceEntitiesProps(
        allDealUuidsToDealIds?.[monitoringReferenceEntity?.id],
      )
      const covenantCheckEventProps = getCovenantCheckEventProps(allEventsObject?.[linkedEventId])

      return {
        rowKey: covenantCheckUuid,
        rowProperties: {
          className: breach ? styles.markLeftBorder : undefined,
          type:
            isAllowedToReadCovenants && !isCovenantCheckClickable
              ? TableRowType.Active
              : TableRowType.Inactive,
          'covenant-check-uuid': covenantCheckUuid,
          'monitoring-reference-entity-type': monitoringReferenceEntity.type,
          'monitoring-entity-display-id':
            monitoringReferenceEntity.type === ReferenceEntityType.Deal
              ? allDealUuidsToDealIds[monitoringReferenceEntity.id]?.dealId
              : monitoringReferenceEntity.id,
        },
        'monitoring-reference-type': {
          value: t(`enum.${monitoringReferenceEntity?.type}`),
          cellComponent: <span>{t(`enum.${monitoringReferenceEntity?.type}`)}</span>,
        },
        'monitoring-reference': {
          value: monitoringReferenceEntity?.id,
          cellComponent: <ReferenceEntitiesView {...covenantCheckReferenceEntityProps} />,
        },
        deals: {
          cellComponent: covenantCheckDeals.length ? (
            <ReferenceEntitiesView {...dealsReferenceEntitiesProps} />
          ) : (
            <></>
          ),
        },
        covenants: {
          value: covenants.map(({ name: covenantName }) => covenantName).join(' '),
          cellComponent: covenants.length ? (
            <ReferenceEntitiesView {...covenantsReferenceEntitiesProps} />
          ) : (
            <></>
          ),
        },
        'monitoring-event': {
          value: linkedEventId,
          cellComponent: (
            <Entity
              name={covenantCheckEventProps?.name}
              id={covenantCheckEventProps?.displayId}
              link={`/${paths.businessEventsAndTasks}/${paths.businessEvents}/${linkedEventId}`}
              openInNewTab={true}
            />
          ),
        },
        'key-date': {
          value: keyDate,
          cellComponent: formatDate(keyDate),
        },
        status: {
          value: t(`enum.${status}`),
          cellComponent: <StatusCell status={status} />,
        },
        'first-target-delivery-date': {
          value: firstTargetDeliveryDate,
          cellComponent: formatDate(firstTargetDeliveryDate),
        },
        breach: {
          value: t(`enum.${covenantChecksBreachMap.get(breach)}`),
          cellComponent: (
            <BreachCell
              breach={covenantChecksBreachMap.get(breach)}
              popoverContent={
                <>
                  <Text className={styles.breachText}>{t('breach.text')}</Text>
                  <br />
                  <br />
                  <Text>{t('breach.details-text')}</Text>
                </>
              }
            />
          ),
        },
        arrow: {
          cellComponent: isAllowedToReadCovenants && <Icon name="slim-arrow-right" />,
        },
      }
    },
  )
}

export default useCovenantChecksTableData
