import { MessageStrip, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { eventStatus as eventStatusEnum, taskStatus as taskStatusEnum } from 'api/events/status'
import {
  dueDateWarningLevel,
  useGetDueDateWarningInfo,
} from 'components/domains/business-events-and-tasks/common/useGetDueDateWarningInfo'

const isEventClosedState = (eventStatus) =>
  eventStatus === eventStatusEnum.completed || eventStatus === eventStatusEnum.aborted

const isTaskClosedState = (taskStatus) =>
  taskStatus === taskStatusEnum.completed || taskStatus === taskStatusEnum.aborted

/**
 *  @typedef {PropTypes.InferProps<typeof DueDateWarningMessageStrip.propTypes>} DueDateWarningMessageStrip
 */
const DueDateWarningMessageStrip = ({ dueDate, className, eventStatus, taskStatus }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.common.due-date-warning',
  })
  const { warningLevel, days } = useGetDueDateWarningInfo(new Date(), dueDate)

  if (
    isEventClosedState(eventStatus) ||
    isTaskClosedState(taskStatus) ||
    warningLevel === dueDateWarningLevel.none
  ) {
    return
  }
  const design =
    warningLevel === dueDateWarningLevel.approaching
      ? MessageStripDesign.Warning
      : MessageStripDesign.Negative
  return (
    <MessageStrip design={design} hideCloseButton className={className}>
      {t(`message.${warningLevel}`, { count: days })}
    </MessageStrip>
  )
}

DueDateWarningMessageStrip.propTypes = {
  dueDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  className: PropTypes.string,
  eventStatus: PropTypes.oneOf(Object.values(eventStatusEnum)),
  taskStatus: PropTypes.oneOf(Object.values(taskStatusEnum)),
}

export default DueDateWarningMessageStrip
