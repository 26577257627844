import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import {
  ROW_TYPE_LENDERS,
  ROW_TYPE_MONETARY_VALUE,
  DEFAULT_DISPLAY_ROW_HEIGHT,
  DEFAULT_DISPLAY_TEXT_GAP,
  DEFAULT_DISPLAY_ENTITY_TEXT_HEIGHT,
  DEFAULT_DISPLAY_CELL_PADDING,
  ROW_TYPE_GREEN_SCORE,
  PROPERTY_PBB_GREEN_SCORE_SORT_MAX,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/constants'
import { mapGreenScoreRowValue } from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/property-and-tenancy/mapGreenScoreRowValue'

const rowKeys = [
  'lettableArea',
  'vacancyRate',
  'annualizedContractedRent',
  'tenantsShareGreater20Percent',
  'waultBreak',
  'waultExpiry',
  'greenScoreMax',
  'greenScoreMin',
]

const retrieveTenantsWithShareGreater20Percent = (kpis) => {
  const limit = 0.2
  const annualizedCurrentRent = kpis?.annualizedCurrentRent?.number
  return compact(
    kpis?.tenantRentRollKpis?.map((tenantRentRollKpi) => {
      const share = tenantRentRollKpi?.annualizedCurrentRent?.number / annualizedCurrentRent
      if (share > limit) {
        return { tenantId: tenantRentRollKpi?.tenant, share }
      } else {
        return undefined
      }
    }) ?? [],
  )
}

const mapRowType = (rowKey) => {
  switch (rowKey) {
    case 'annualizedContractedRent':
      return ROW_TYPE_MONETARY_VALUE
    case 'tenantsShareGreater20Percent':
      return ROW_TYPE_LENDERS
    case 'greenScoreMax':
    case 'greenScoreMin':
      return ROW_TYPE_GREEN_SCORE
  }
}

const mapRowData = ({
  rowKey,
  responses,
  getBusinessPartnerFullName,
  multiplePropertyCustomFieldsByPropertyUuidsData,
  formatters: { formatYears, formatPercentages, formatNumbers, formatAreaUnit },
}) => {
  const createRow = (createObjectFunction) =>
    responses
      ?.map(({ data, isLoading, isFetching, isError }) =>
        createObjectFunction(data, { isLoading, isFetching, isError }),
      )
      .reduce((prev, curr) => Object.assign(prev, curr), {})

  switch (rowKey) {
    case 'lettableArea':
      return createRow(({ dealUuid, keyDateToMultiPropertyRentRollKpis }, requestStates) => {
        const { value, measurementUnit } =
          keyDateToMultiPropertyRentRollKpis?.[0]?.kpis?.totalAreaSurface ?? {}
        return {
          [dealUuid]: {
            value: `${formatNumbers(value)} ${formatAreaUnit(measurementUnit)}`,
            ...requestStates,
          },
        }
      })
    case 'vacancyRate':
      return createRow(({ dealUuid, keyDateToMultiPropertyRentRollKpis }, requestStates) => {
        const { percent: percentage } =
          keyDateToMultiPropertyRentRollKpis?.[0]?.kpis?.vacancySurface ?? {}
        return {
          [dealUuid]: {
            value: formatPercentages(percentage),
            ...requestStates,
          },
        }
      })
    case 'annualizedContractedRent':
      return createRow(({ dealUuid, keyDateToMultiPropertyRentRollKpis }, requestStates) => {
        const { number: amount, currency } =
          keyDateToMultiPropertyRentRollKpis?.[0]?.kpis?.annualizedContractedRent ?? {}
        return {
          [dealUuid]: {
            value: { amount, currency },
            ...requestStates,
          },
        }
      })
    case 'tenantsShareGreater20Percent':
      return createRow(({ dealUuid, keyDateToMultiPropertyRentRollKpis }, requestStates) => {
        const tenantsPerDealWithShareGreater20Percent = keyDateToMultiPropertyRentRollKpis?.[0]
          ?.kpis
          ? retrieveTenantsWithShareGreater20Percent(keyDateToMultiPropertyRentRollKpis[0]?.kpis)
          : []
        return {
          [dealUuid]: {
            value: {
              data: {
                // TODO: CWP-11196: these are not lenders, but the LenderCell is used
                lenders: tenantsPerDealWithShareGreater20Percent?.map((tenant) => ({
                  businessPartnerFullName: getBusinessPartnerFullName(tenant?.tenantId),
                  businessPartnerId: tenant?.tenantId,
                  amountShare: tenant?.share,
                })),
              },
              ...requestStates,
              // isLoading is enriched by the state of the BP name resolution,
              // otherwise the share is shown without the name for a few milliseconds
              isLoading:
                requestStates?.isLoading ||
                tenantsPerDealWithShareGreater20Percent?.some((tenant) =>
                  isNil(getBusinessPartnerFullName(tenant?.tenantId)),
                ),
            },
          },
        }
      })
    case 'waultBreak':
      return createRow(({ dealUuid, keyDateToMultiPropertyRentRollKpis }, requestStates) => {
        const { waultToBreakInYears } = keyDateToMultiPropertyRentRollKpis?.[0]?.kpis ?? {}
        return {
          [dealUuid]: {
            value: formatYears(waultToBreakInYears),
            ...requestStates,
          },
        }
      })
    case 'waultExpiry':
      return createRow(({ dealUuid, keyDateToMultiPropertyRentRollKpis }, requestStates) => {
        const { waultToExpiryInYears } = keyDateToMultiPropertyRentRollKpis?.[0]?.kpis ?? {}
        return {
          [dealUuid]: {
            value: formatYears(waultToExpiryInYears),
            ...requestStates,
          },
        }
      })
    case 'greenScoreMax':
      return createRow(({ dealUuid }, requestStates) => ({
        [dealUuid]: {
          value: mapGreenScoreRowValue(
            dealUuid,
            multiplePropertyCustomFieldsByPropertyUuidsData,
            PROPERTY_PBB_GREEN_SCORE_SORT_MAX,
          ),
          ...requestStates,
        },
      }))
    case 'greenScoreMin':
      return createRow(({ dealUuid }, requestStates) => ({
        [dealUuid]: {
          value: mapGreenScoreRowValue(dealUuid, multiplePropertyCustomFieldsByPropertyUuidsData),
          ...requestStates,
        },
      }))
  }
}

const mapRowHeight = (rowKey, tenantsRowCount, hasGreenScoreProperty) => {
  const calculateMultiItemCellHeight = (itemsCount) => {
    const height = (DEFAULT_DISPLAY_ENTITY_TEXT_HEIGHT + DEFAULT_DISPLAY_TEXT_GAP / 2) * itemsCount
    const gap = DEFAULT_DISPLAY_TEXT_GAP * (itemsCount - 1)
    const calHeight = height + gap + DEFAULT_DISPLAY_CELL_PADDING
    return itemsCount > 0 ? calHeight : DEFAULT_DISPLAY_ROW_HEIGHT
  }

  switch (rowKey) {
    case 'tenantsShareGreater20Percent':
      return {
        rowHeight: calculateMultiItemCellHeight(tenantsRowCount),
      }

    case 'greenScoreMax':
    case 'greenScoreMin':
      return {
        rowHeight: hasGreenScoreProperty?.some((hasGreenScore) => hasGreenScore)
          ? DEFAULT_DISPLAY_ENTITY_TEXT_HEIGHT + DEFAULT_DISPLAY_CELL_PADDING
          : DEFAULT_DISPLAY_ROW_HEIGHT,
      }

    default:
      return { rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT }
  }
}

const mapMultiPropertyData = ({
  multiPropertyKpisResponses,
  getBusinessPartnerFullName,
  multiplePropertyCustomFieldsByPropertyUuidsData,
  formatters,
  t: tPrefix,
}) => {
  const tenantsRowCount = Math.max(
    ...multiPropertyKpisResponses.map((e) =>
      e?.data?.keyDateToMultiPropertyRentRollKpis?.[0]?.kpis
        ? retrieveTenantsWithShareGreater20Percent(
            e?.data?.keyDateToMultiPropertyRentRollKpis[0]?.kpis,
          ).length
        : 1,
    ),
  )

  const hasGreenScoreProperty = multiPropertyKpisResponses
    ?.map(({ data }) => data?.dealUuid)
    ?.map(
      (dealUuid) =>
        mapGreenScoreRowValue(dealUuid, multiplePropertyCustomFieldsByPropertyUuidsData)
          ?.customField?.value !== undefined,
    )

  return rowKeys.map((rowKey) => {
    const rowData = mapRowData({
      rowKey,
      responses: multiPropertyKpisResponses,
      getBusinessPartnerFullName,
      multiplePropertyCustomFieldsByPropertyUuidsData,
      formatters,
    })

    return {
      kpi: tPrefix(`rows.section.property-tenancy.${rowKey}`),
      rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT,
      ...mapRowHeight(rowKey, tenantsRowCount, hasGreenScoreProperty),
      ...rowData,
      rowType: mapRowType(rowKey),
      rowHasError: Object.values(rowData).some((column) => column.isError),
    }
  })
}

export default mapMultiPropertyData
