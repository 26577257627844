import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

const ConditionAssigneeDisplay = ({ assignee }) => {
  if (!assignee) {
    return <Text>{'-'}</Text>
  }

  return <Text>{assignee}</Text>
}

ConditionAssigneeDisplay.propTypes = {
  assignee: PropTypes.string,
}

export default ConditionAssigneeDisplay
