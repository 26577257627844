import { FlexBox, FlexBoxJustifyContent, Label, Text } from '@fioneer/ui5-webcomponents-react'
import { bool, number, oneOfType, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/portfolio/overview/PropertyPortfolioInformationCard.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'

export const PropertyPortfolioOverviewCardItem = ({
  id,
  label,
  value,
  isLoading = false,
  isError = false,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.properties.portfolio.overview',
  })
  const childId = id || `property-portfolio-${label}`

  return (
    <FlexBox
      className={styles['show-item']}
      fitContainer
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
    >
      <Label for={childId} showColon={false}>
        {label}
      </Label>
      <SmallLoadingWrapper
        id={`${childId}-loader`}
        isLoading={isLoading}
        isError={isError}
        error={t('error')}
        showErrorInHover
        renderContent={() => <Text id={childId}>{value || t('empty-value')}</Text>}
      />
    </FlexBox>
  )
}

PropertyPortfolioOverviewCardItem.propTypes = {
  id: string,
  label: string.isRequired,
  value: oneOfType([string, number, bool]),
  isLoading: bool,
  isError: bool,
}
