import downloadImage from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/rich-text-editor/downloadImage'

function InjectAccessTokenPlugin(editor) {
  const conversionFunction = (event, data, conversionApi) => {
    if (event.name !== 'attribute:src:imageInline' && event.name !== 'attribute:src:imageBlock') {
      return
    }

    if (!data.attributeKey || data.attributeKey !== 'src') {
      return
    }

    if (!conversionApi.consumable.consume(data.item, event.name)) {
      return
    }

    const viewWriter = conversionApi.writer
    const viewElement = conversionApi.mapper.toViewElement(data.item)

    if (!viewElement) {
      return
    }

    let img = viewElement

    // Logic if the img is part of the span / figure
    if (viewElement.name !== 'img') {
      const tmp = viewElement.getChild(0)
      if (!tmp || !tmp.is('element') || tmp.name !== 'img') {
        return
      }
      img = tmp
    }

    if (data.attributeNewValue && typeof data.attributeNewValue === 'string') {
      const imageUrl = data.attributeNewValue
      editor.editing.view.change(() => {
        viewWriter.setAttribute('data-url', imageUrl, img)
      })
      downloadImage({ imageUrl })
        .then((imageData) => {
          editor.editing.view.change(() => {
            viewWriter.setAttribute('src', imageData, img)
          })
        })
        .catch(() => {
          editor.editing.view.change(() => {
            viewWriter.setAttribute('src', 'error', img)
          })
        })
    }
  }

  editor.conversion.for('editingDowncast').add((dispatcher) => {
    dispatcher.on('attribute:src:imageBlock', conversionFunction)
    dispatcher.on('attribute:src:imageInline', conversionFunction)
  })
}

export default InjectAccessTokenPlugin
