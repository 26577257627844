import { Icon, SortItem, ViewSettingsDialog } from '@fioneer/ui5-webcomponents-react'
import find from 'lodash.find'
import { PropTypes } from 'prop-types'
import { useRef } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import styles from 'components/domains/properties/modular-table/toolbar/ModularToolbarColumnSortSettingsOnSearchParams.module.css'

const SORT_ASCENDING_SIGN = '+'
const SORT_DESCENDING_SIGN = '-'

const getOrderSymbol = (sortDescending) =>
  sortDescending ? SORT_DESCENDING_SIGN : SORT_ASCENDING_SIGN
const getSortString = (sortBy, orderSymbol) => (sortBy ? `${orderSymbol}${sortBy}` : '')
const getSortFieldName = (columns, title) =>
  find(columns, (column) => column.title === title)?.sortFieldName ?? ''

const ModularToolbarColumnSortSettingsOnSearchParams = ({ columns }) => {
  const sortingDialogRef = useRef()
  const { t } = useTranslation('translation', { keyPrefix: 'components.ui.tables.toolbar' })

  const [query, setQuery] = useSearchParams()
  const sort = query.get('sort')

  const sortColumnSelected = sort ? sort.substring(1) : ''
  const sortDescending = sort ? sort.charAt(0) === SORT_DESCENDING_SIGN : false

  const handleUpdateSorting = (event) => {
    const sortByColumnKey = getSortFieldName(columns, event.detail.sortBy)
    const orderSymbol = getOrderSymbol(event.detail.sortDescending)
    query.set('sort', getSortString(sortByColumnKey, orderSymbol))
    setQuery(query)
  }

  const sortableColumns = columns.filter((column) => column.sortFieldName)

  return (
    <>
      <Icon
        id="basic-table-column-sort-settings-icon"
        className={styles.sortIcon}
        name="sort"
        interactive
        onClick={() => sortingDialogRef.current.show()}
      />
      {createPortal(
        <ViewSettingsDialog
          id="basic-table-column-sort-settings-dialog"
          className={styles.dialog}
          title={t('sort.title')}
          sortDescending={sortDescending}
          ref={sortingDialogRef}
          sortItems={sortableColumns.map(({ sortFieldName, title }) => (
            <SortItem
              text={title}
              key={sortFieldName}
              selected={sortColumnSelected === sortFieldName}
            />
          ))}
          onConfirm={handleUpdateSorting}
        />,
        document.body,
      )}
    </>
  )
}

ModularToolbarColumnSortSettingsOnSearchParams.propTypes = {
  columns: PropTypes.array,
}

export default ModularToolbarColumnSortSettingsOnSearchParams
