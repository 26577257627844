import isNil from 'lodash.isnil'
import { DateTime } from 'luxon'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const MAX_PERCENT_VALUE = 10_000
const MIN_PERCENT_VALUE = -10_000

const PERCENT = 'PERCENT'

const useValidateHelpers = (formState) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.limit-card.validation',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const requiredText = tNoPrefix('form.validation.required')
  const { localePattern } = useShortDateFormatter()

  const checkLimitValue = useCallback(
    (limitValue, unit) => {
      if (isNil(limitValue)) return requiredText
      if (unit !== PERCENT) return undefined

      if (limitValue > MAX_PERCENT_VALUE) {
        return tNoPrefix('form.validation.max', { maxValue: MAX_PERCENT_VALUE })
      }
      if (limitValue < MIN_PERCENT_VALUE) {
        return tNoPrefix('form.validation.min', { minValue: MIN_PERCENT_VALUE })
      }

      return undefined
    },
    [requiredText, tNoPrefix],
  )

  const checkValidFromAbsolute = useCallback(
    (validFrom, index) => {
      if (!validFrom) return requiredText

      const validFromDate = DateTime.fromISO(validFrom)

      // DateTime.fromIso accepts multiple ISO-formats e.g. 2020-01 so the length must be verified
      if (validFrom.length !== 10) return t('invalid-date-format', { pattern: localePattern })
      if (!validFromDate.isValid) return t('invalid-date-format', { pattern: localePattern })

      // check current row
      const currentValidTo = DateTime.fromISO(formState.absoluteLimits?.[index]?.validTo)
      const diff = currentValidTo.diff(validFromDate, ['days']).days
      if (diff < 0) {
        return t('valid-from-before-valid-to')
      }

      // check previous row
      if (index === 0) return
      const previousValidTo = DateTime.fromISO(formState.absoluteLimits?.[index - 1]?.validTo)
      const diffWithPrevious = validFromDate.diff(previousValidTo, ['days']).days
      if (diffWithPrevious !== 1) {
        return t('gap-free-non-overlapping')
      }
      return undefined
    },
    [formState.absoluteLimits, localePattern, requiredText, t],
  )

  const checkValidToAbsolute = useCallback(
    (validTo, index) => {
      if (!validTo) return requiredText

      const validToDate = DateTime.fromISO(validTo)

      // DateTime.fromIso accepts multiple ISO-formats e.g. 2020-01 so the length must be verified
      if (validTo.length !== 10) return t('invalid-date-format', { pattern: localePattern })
      if (!validToDate.isValid) return t('invalid-date-format', { pattern: localePattern })

      // check current row
      const currentValidFrom = DateTime.fromISO(formState.absoluteLimits?.[index]?.validFrom)
      const diffRow = validToDate.diff(currentValidFrom, ['days']).days
      if (diffRow < 0) {
        return t('valid-to-after-valid-from')
      }

      // check next row
      const nextValidFrom = formState.absoluteLimits?.[index + 1]?.validFrom
      if (!nextValidFrom) return
      const nextValidFromDate = DateTime.fromISO(nextValidFrom)
      const diffWithNext = nextValidFromDate.diff(validToDate, ['days']).days
      if (diffWithNext !== 1) {
        return t('gap-free-non-overlapping')
      }
      return undefined
    },
    [formState.absoluteLimits, localePattern, requiredText, t],
  )

  const checkValidFromRelative = useCallback(
    (validFrom, index) => {
      if (!validFrom) return requiredText
      const currentValidTo = formState.relativeLimits?.[index]?.validTo
      if (currentValidTo - validFrom < 0) {
        return t('valid-from-before-valid-to')
      }

      if (index === 0) return
      const previousValidTo = formState.relativeLimits?.[index - 1]?.validTo
      if (validFrom - previousValidTo !== 1) {
        return t('gap-free-non-overlapping')
      }
      return undefined
    },
    [formState.relativeLimits, requiredText, t],
  )

  const checkValidToRelative = useCallback(
    (validTo, index) => {
      if (!validTo) return requiredText

      const currentValidFrom = formState.relativeLimits?.[index]?.validFrom
      if (validTo - currentValidFrom < 0) {
        return t('valid-to-after-valid-from')
      }

      const nextValidFrom = formState.relativeLimits?.[index + 1]?.validFrom
      if (!nextValidFrom) return
      if (nextValidFrom - validTo !== 1) {
        return t('gap-free-non-overlapping')
      }
      return undefined
    },
    [formState.relativeLimits, requiredText, t],
  )

  const checkInternallyInvalidInputs = useCallback(
    (isValidNumberInput) => {
      if (isValidNumberInput === undefined || isValidNumberInput) {
        return undefined
      }
      return tNoPrefix('form.validation.number')
    },
    [tNoPrefix],
  )

  return {
    checkValidFromAbsolute,
    checkValidToAbsolute,
    checkValidToRelative,
    checkValidFromRelative,
    checkLimitValue,
    checkInternallyInvalidInputs,
  }
}

export default useValidateHelpers
