import { useMutation } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

export const useUpdatePropertyInsurance = ({ onSuccess = () => {}, onError = () => {} }) => {
  const { patch } = useAccessTokenRequest()
  const { parse, localePattern } = useShortDateFormatter()
  const dateToIsoFormat = (dateString) =>
    DateTime.fromISO(dateString).isValid ? dateString : parse(dateString, localePattern)

  return useMutation(
    async ({ propertyUuid, insurance }) => {
      const { data } = await patch({
        path: `/properties/${propertyUuid}/insurance`,
        body: snakecaseKeys({
          ...insurance,
          insuranceStartDate: dateToIsoFormat(insurance.insuranceStartDate),
          insuranceExpiryDate: dateToIsoFormat(insurance.insuranceExpiryDate),
        }),
      })
      return data
    },
    { onSuccess, onError },
  )
}
