import { Select, Option, ValueState, Text } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/covenants/monitoring-information-card/RelativeDateComparisonEdit.module.css'
import useDirectionTranslation, {
  Direction,
} from 'components/domains/deals/covenants/shared/useDirectionTranslation'
import useTemporalUnitTranslation, {
  temporalUnitEnum,
} from 'components/domains/deals/covenants/shared/useTemporalUnitTranslation'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'

const isIntegerAboveZero = (value) => Number.isInteger(Number(value)) && Number(value) >= 0

const RelativeDateComparisonEdit = ({
  value = {},
  referenceDateTranslation,
  isRequired = false,
  onChange,
  isDirectionSelectDisabled = false,
}) => {
  const { t } = useTranslation('translation')
  const amountToAddRef = useRef()

  const { amountToAdd, temporalUnit, direction } = value ?? {}

  const { getTemporalUnitTranslation } = useTemporalUnitTranslation()
  const { getDirectionTranslation } = useDirectionTranslation()

  const onChangeInternal = (changedValue) => {
    onChange({
      amountToAdd,
      temporalUnit,
      direction,
      ...changedValue,
    })
  }

  const isAmountToAddValid = useMemo(() => {
    if (isRequired) return !isNil(amountToAdd) && isIntegerAboveZero(amountToAdd)
    if (isNil(amountToAdd)) return true
    return isIntegerAboveZero(amountToAdd)
  }, [amountToAdd, isRequired])

  const isDirectionInvalid = isRequired && !direction
  const isTemporalUnitInvalid = isRequired && !temporalUnit

  if (!isNil(amountToAddRef?.current?.value) && !isNil(amountToAdd)) {
    amountToAddRef.current.value = amountToAdd
  }

  return (
    <div className={styles.container}>
      <div className={styles.firstChild}>
        <FormattedNumberInput
          id="amount-to-add-input"
          value={amountToAdd}
          ref={amountToAddRef}
          maximumFractionDigits={0}
          minimumFractionDigits={0}
          valueState={isAmountToAddValid ? ValueState.None : ValueState.Error}
          valueStateMessage={
            <Text>
              {!isIntegerAboveZero(amountToAdd)
                ? t('form.validation.min', { minValue: 0 })
                : t('form.validation.required')}
            </Text>
          }
          onChange={(val) => {
            if (amountToAddRef.current?.isValid) {
              onChangeInternal({ amountToAdd: val })
            }
          }}
          onBlur={(event) => {
            if (!amountToAddRef.current?.isValid) {
              onChangeInternal({ amountToAdd: event.target.value })
            }
          }}
          className={styles.amountInput}
        />

        <Select
          id="temporal-unit-select"
          valueState={isTemporalUnitInvalid ? ValueState.Error : ValueState.None}
          valueStateMessage={<Text>{t('form.validation.required')}</Text>}
          onChange={(event) =>
            onChangeInternal({ temporalUnit: event.detail.selectedOption.value })
          }
        >
          {Object.keys(temporalUnitEnum).map((temporalUnitOption) => (
            <Option
              key={temporalUnitOption}
              value={temporalUnitOption}
              selected={temporalUnitOption === temporalUnit}
            >
              {getTemporalUnitTranslation(temporalUnitOption)}
            </Option>
          ))}
        </Select>

        <Select
          id="direction-select"
          valueState={isDirectionInvalid ? ValueState.Error : ValueState.None}
          valueStateMessage={<Text>{t('form.validation.required')}</Text>}
          onChange={(event) => onChangeInternal({ direction: event.detail.selectedOption.value })}
          disabled={isDirectionSelectDisabled}
        >
          {Object.keys(Direction).map((dir) => (
            <Option key={dir} value={dir} selected={dir === direction}>
              {getDirectionTranslation(dir)}
            </Option>
          ))}
        </Select>
      </div>
      <div className={styles.noWrap}>{referenceDateTranslation}</div>
    </div>
  )
}

RelativeDateComparisonEdit.propTypes = {
  value: PropTypes.shape({
    amount: PropTypes.number,
    temporalUnit: PropTypes.string,
    direction: PropTypes.string,
  }),
  referenceDateTranslation: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  isDirectionSelectDisabled: PropTypes.bool,
}

export default RelativeDateComparisonEdit
