import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const getUserFavoriteGetKey = (entityId) => ['user-favorite', entityId]

const useUserFavoriteGet = ({ entityId }, queryOptions = {}) => {
  const response = useRequest({
    path: `/user-profile/favorite/${entityId}`,
    keys: getUserFavoriteGetKey(entityId),
    options: queryOptions,
  })
  return useCamelizedResponse(response)
}

export default useUserFavoriteGet
