import {
  CustomListItem,
  FlexBox,
  FlexBoxJustifyContent,
  Label,
  ListItemType,
  Text,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListItem.module.css'

const DecisionPaperTileListItem = ({
  label,
  value,
  additionalValue,
  isFirstItem,
  textValueIsBold = false,
  additionalTextValueIsNeutralColor = false,
}) => {
  const textBoldOrEmpty = textValueIsBold ? styles.textBold : ''
  const additionalTextNeutralColorOrEmpty = additionalTextValueIsNeutralColor
    ? styles.additionalTextNeutralColor
    : styles.additionalText
  return (
    <CustomListItem type={ListItemType.Inactive} className={styles.customListItem}>
      <FlexBox
        fitContainer
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        className={styles.rowWrapper.concat(isFirstItem ? ` ${styles.firstItem}` : '')}
      >
        <Label wrappingType={WrappingType.Normal} className={styles.rowLabel}>
          {label}
        </Label>
        <div className={styles.rowValue}>
          <Text className={textBoldOrEmpty}>{value}</Text>
          {additionalValue && (
            <Text className={additionalTextNeutralColorOrEmpty}>{additionalValue}</Text>
          )}
        </div>
      </FlexBox>
    </CustomListItem>
  )
}

DecisionPaperTileListItem.propTypes = {
  label: PropTypes.string,
  isFirstItem: PropTypes.bool,
  textValueIsBold: PropTypes.bool,
  additionalTextValueIsNeutralColor: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
  additionalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
}

export default DecisionPaperTileListItem
