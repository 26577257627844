import { useTranslation } from 'react-i18next'
import PropertyRentRollOverviewRentalIncomeOverTimeCardContent from 'components/domains/properties/rent-roll/overview/rental-income-over-time/PropertyRentRollOverviewRentalIncomeOverTimeCardContent'
import Card from 'components/ui/card/Card'
import CardHeaderWithMetrics from 'components/ui/card/CardHeaderWithMetrics'
import {
  useNumberFormatter,
  useCustomizableCurrencyFormatter,
  useAreaMeasurementUnitFormatter,
  useAreaPiecesUnit,
} from 'hooks/i18n/useI18n'
import useCurrentRentRollOverviewPropertyKpis from 'hooks/services/properties/kpis/useCurrentRentRollOverviewPropertyKpis'

const PropertyRentRollOverviewRentalIncomeOverTimeCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.rental-income-over-time',
  })

  const {
    isLoading: isLoadingPropertyKpis,
    isError: isErrorPropertyKpis,
    data: propertyKpiData,
  } = useCurrentRentRollOverviewPropertyKpis()

  const formatArea = useAreaMeasurementUnitFormatter()
  const formatCurrency = useCustomizableCurrencyFormatter({ notation: 'compact' })
  const formatNumber = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: 'compact',
  })

  const setValueOrUndefined = (value) => (value ? formatNumber(value) : undefined)
  const setUnitOrUndefined = (unit) => (unit ? formatArea(unit) : '-')
  const setCurrencyOrUndefined = (value, currency) =>
    value && currency ? formatCurrency(value, currency) : undefined

  const primaryMetric = {
    label: t('current-rent'),
    value: setValueOrUndefined(propertyKpiData?.annualizedCurrentRent?.number),
    unit: propertyKpiData?.annualizedCurrentRent?.currency,
  }

  const unit = setUnitOrUndefined(propertyKpiData?.totalAreaSurface?.measurementUnit)
  const secondaryMetrics = [
    {
      label: t('contracted-rent'),
      value: setCurrencyOrUndefined(
        propertyKpiData?.annualizedContractedRent?.number,
        propertyKpiData?.annualizedContractedRent?.currency,
      ),
    },
    {
      label: t('current-rent-per-uom') + unit,
      value: setCurrencyOrUndefined(
        propertyKpiData?.annualizedCurrentRentPerUom?.number,
        propertyKpiData?.annualizedCurrentRentPerUom?.currency,
      ),
    },
    {
      label: t('current-rent-per-uom') + useAreaPiecesUnit(),
      value: setCurrencyOrUndefined(
        propertyKpiData?.annualizedCurrentRentPerPieces?.number,
        propertyKpiData?.annualizedCurrentRentPerPieces?.currency,
      ),
    },
    {
      label: t('market-rent-per-uom') + unit + t('incl-vacancy'),
      value: setCurrencyOrUndefined(
        propertyKpiData?.totalMarketRentPerUom?.number,
        propertyKpiData?.totalMarketRentPerUom?.currency,
      ),
    },
    {
      label: t('market-rent-per-uom') + useAreaPiecesUnit() + t('incl-vacancy'),
      value: setCurrencyOrUndefined(
        propertyKpiData?.totalMarketRentPerPieces?.number,
        propertyKpiData?.totalMarketRentPerPieces?.currency,
      ),
    },
  ]

  return (
    <Card
      header={
        <CardHeaderWithMetrics
          title={t('title')}
          primaryMetric={primaryMetric}
          secondaryMetrics={secondaryMetrics}
          referenceDate={propertyKpiData?.keyDate}
          isLoading={isLoadingPropertyKpis}
          isError={isErrorPropertyKpis}
        />
      }
    >
      <PropertyRentRollOverviewRentalIncomeOverTimeCardContent />
    </Card>
  )
}

export default PropertyRentRollOverviewRentalIncomeOverTimeCard
