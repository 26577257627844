import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionStageStatus } from 'api/decision-process/decisionProcessApi'

export const useDecisionStageDecisionPaperName = ({
  decisionPaperVersion,
  decisionStageStatus: decisionStatus,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.business-events-and-tasks.decision-stage',
  })

  const decisionStatusPlannedOrDiscarded = useMemo(
    () =>
      [decisionStageStatus.planned, decisionStageStatus.inProgressRequestDiscarded].includes(
        decisionStatus,
      ),
    [decisionStatus],
  )

  const decisionPaperLinkTranslationKey = useMemo(
    () =>
      decisionStatusPlannedOrDiscarded
        ? 'linked-decision-paper.planned'
        : 'linked-decision-paper.fixed',
    [decisionStatusPlannedOrDiscarded],
  )

  if (!decisionPaperVersion || !decisionStatus) {
    return ''
  }

  return t(decisionPaperLinkTranslationKey, {
    decisionPaperVersion: decisionPaperVersion,
  })
}
