import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionsMassEditOperations } from 'api/conditions/conditions'
import styles from 'components/domains/conditions/dialogs/mass-editing/ConditionsMassEditVisibilitySelect.module.css'
import MassEditOperationSelect from 'components/domains/conditions/dialogs/mass-editing/MassEditOperationSelect'
import LoadingMultiComboBox from 'components/ui/multi-combo-box/LoadingMultiComboBox'
import useVisibilities from 'hooks/services/conditions/config/useVisibilities'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const ConditionsMassEditVisibilitySelect = ({ onChange, disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.dialogs.mass-edit',
  })

  const {
    entityRef: { entityType },
  } = useContext(ConditionsContext)

  const [showInputField, setShowInputField] = useState(false)
  const [selectedVisibilities, setSelectedVisibilities] = useState([])

  const handleSelectedOperationChanged = useCallback(
    (selection) => {
      if (selection === conditionsMassEditOperations.keep) {
        setShowInputField(false)
        onChange({ operation: conditionsMassEditOperations.keep })
        return
      }
      onChange({ operation: conditionsMassEditOperations.replace, value: selectedVisibilities })
      setShowInputField(true)
    },
    [selectedVisibilities, onChange],
  )

  const handleSelectedVisibilityChanged = useCallback(
    ({ detail: { items } }) => {
      const selection = items.map((item) => item.getAttribute('data-value'))
      setSelectedVisibilities(selection)
      onChange({ operation: conditionsMassEditOperations.replace, value: selection })
    },
    [onChange],
  )

  const hookInput = useMemo(() => ({ entityType }), [entityType])

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label>{t('labels.visibility')}</Label>
      <MassEditOperationSelect
        idPrefix={'conditions-visibility'}
        selectableOperations={[
          conditionsMassEditOperations.keep,
          conditionsMassEditOperations.replace,
        ]}
        onChange={handleSelectedOperationChanged}
        disabled={disabled}
      />
      {showInputField && (
        <LoadingMultiComboBox
          className={styles.visibilitiesSelect}
          selected={selectedVisibilities}
          optionDisplayName={'name'}
          selectionName={'visibilities'}
          useLoader={useVisibilities}
          input={hookInput}
          onSelectionChange={handleSelectedVisibilityChanged}
          disabled={disabled}
        />
      )}
    </FlexBox>
  )
}

ConditionsMassEditVisibilitySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default ConditionsMassEditVisibilitySelect
