import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import LoadingButton from 'components/ui/button/LoadingButton'

const dialogButtonPropTypes = {
  /**
   * Defines the accessible ARIA name of the component.
   */
  accessibleName: PropTypes.string,
  /**
   * Receives id(or many ids) of the elements that label the component.
   */
  accessibleNameRef: PropTypes.string,
  /**
   * Defines whether the component is disabled. A disabled component can't be pressed or focused, and it is not in the tab chain.
   */
  disabled: PropTypes.bool,
  /**
   * Defines the text of the component.
   *
   * **Note:** Although this slot accepts HTML Elements, it is strongly recommended that you only use text in order to preserve the intended design.
   */
  children: PropTypes.node.isRequired,
  /**
   * Fired when the component is activated either with a mouse/tap or by using the Enter or Space key.
   *
   * **Note:** The event will not be fired if the `disabled` property is set to `true`.
   */
  onClick: PropTypes.func,
}

/**
 * @param {string} displayName
 * @param {UI5React.ButtonDesign} design
 */
const createDialogButton = (displayName, design) => {
  /**
   * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<PropTypes.InferProps<typeof dialogButtonPropTypes>> & React.RefAttributes<UI5React.ButtonDomRef>>}
   */
  const DialogButton = forwardRef(({ children, ...props }, forwardedButtonRef) => (
    <LoadingButton
      isLoading={false}
      isError={false}
      {...props}
      ref={forwardedButtonRef}
      design={design}
      submits={false}
      tooltip=""
      icon=""
      renderContent={() => children}
    />
  ))

  DialogButton.displayName = displayName
  DialogButton.propTypes = dialogButtonPropTypes

  return DialogButton
}

export { createDialogButton, dialogButtonPropTypes }
