import { ValueState } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

const createOrIncrementValidationResultSummary = (column, rowNumber, message, state, summary) => {
  const resultEntryExistForMessage = message in summary[state]
  if (resultEntryExistForMessage) {
    const resultEntry = summary[state][message]
    resultEntry['amount'] += 1
    resultEntry['rowNumbers'] = rowNumber
      ? [...resultEntry['rowNumbers'], rowNumber]
      : [...resultEntry['rowNumbers']]
  } else {
    const newValidation = {}
    newValidation['column'] = column
    newValidation['amount'] = 1
    newValidation['rowNumbers'] = rowNumber ? [rowNumber] : []
    summary[state][message] = newValidation
  }
  return summary
}

const summarizeValidationResultsRow = (row, summary) =>
  Object.keys(row).reduce((summary, column) => {
    const { valueState, valueStateMessage } = row[column]
    if (valueState === ValueState.Error || valueState === ValueState.Warning) {
      return createOrIncrementValidationResultSummary(
        column,
        row['row_number'],
        valueStateMessage,
        valueState === ValueState.Error ? 'error' : 'warning',
        summary,
      )
    } else {
      return summary
    }
  }, summary)

const summarizeValidationResults = (rows) =>
  rows.reduce((summary, row) => summarizeValidationResultsRow(row, summary), {
    error: {},
    warning: {},
  })

const getTotalAmountOfInconsistencyState = (summaryByState) =>
  summaryByState
    ? Object.keys(summaryByState).reduce(
        (totalAmount, message) => totalAmount + summaryByState[message]['amount'],
        0,
      )
    : 0

const getTotalAmountOfInconsistencies = (summary) => ({
  totalErrors: getTotalAmountOfInconsistencyState(summary['error']),
  totalWarnings: getTotalAmountOfInconsistencyState(summary['warning']),
})

const PropertyRentRollWorkingVersionValidationResultsCalculation = ({
  headerValues,
  rentalUnitsValues,
  auxiliaryFieldValues,
  children,
}) => {
  const summarizedValidationResults = summarizeValidationResults([
    auxiliaryFieldValues,
    headerValues,
    ...rentalUnitsValues,
  ])

  const totalAmountOfInconsistencies = getTotalAmountOfInconsistencies(summarizedValidationResults)

  return children(summarizedValidationResults, totalAmountOfInconsistencies)
}

PropertyRentRollWorkingVersionValidationResultsCalculation.propTypes = {
  headerValues: PropTypes.object,
  rentalUnitsValues: PropTypes.array,
  auxiliaryFieldValues: PropTypes.object,
}

export default PropertyRentRollWorkingVersionValidationResultsCalculation
