import {
  FlexBox,
  FlexBoxDirection,
  Link,
  LinkDesign,
  Popover,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/roles/BusinessPartnerRolesPopover.module.css'

const BusinessPartnerRolesPopover = ({ roles, amountOfRoles }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-partner.roles',
  })
  const [popoverIsOpen, setPopoverIsOpen] = useState(false)
  const popoverId = `open-popover-${JSON.stringify(roles.map((role) => role.name))}`
  const summaryText = `${t('roles-title')} (${amountOfRoles})`
  const rolesWithBackendRoles = roles.filter(
    (role) => !isNil(role?.backEndRoles) && role.backEndRoles.length > 0,
  )

  const isNotLastItem = (items, itemIdx) => items.length - 1 !== itemIdx

  return (
    <>
      <Link
        id={popoverId}
        design={LinkDesign.Emphasized}
        onClick={() => {
          setPopoverIsOpen(true)
        }}
      >
        {summaryText}
      </Link>
      {createPortal(
        <Popover
          id="popover"
          opener={popoverId}
          open={popoverIsOpen}
          onAfterClose={() => {
            setPopoverIsOpen(false)
          }}
          className={styles.popover}
        >
          <FlexBox direction={FlexBoxDirection.Column}>
            <Text className={styles.popoverSummaryText}>{summaryText}</Text>
            <div className={styles.divider} />
            {rolesWithBackendRoles.map(({ name, backEndRoles }, roleIdx) => (
              <div key={`bp-roles-popover-${roleIdx}`}>
                <div className={styles.roleContainer}>
                  <Text className={styles.roleName}>{t(`${name}`)}</Text>
                  <FlexBox direction={FlexBoxDirection.Column}>
                    {backEndRoles.map((backEndRole, backEndRoleIdx) => (
                      <Text
                        key={`bp-roles-popover-${roleIdx}-${backEndRoleIdx}`}
                        className={styles.backEndRoleName}
                      >
                        {backEndRole.name}
                      </Text>
                    ))}
                  </FlexBox>
                </div>
                {isNotLastItem(rolesWithBackendRoles, roleIdx) && (
                  <div className={styles.divider} />
                )}
              </div>
            ))}
          </FlexBox>
        </Popover>,
        document.body,
      )}
    </>
  )
}

BusinessPartnerRolesPopover.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      backEndRoles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  amountOfRoles: PropTypes.number.isRequired,
}

export default BusinessPartnerRolesPopover
