import {
  Text,
  Label,
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  FlexBoxAlignItems,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { decisionPaperSynchronizationStatus } from 'api/decision-paper/decisionPaperApi'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import styles from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperPopovers.module.css'

const DecisionPaperVersionInformation = ({
  headerText,
  lastEditedOn,
  majorVersionLastSyncedOn,
  minorVersionLastSyncedOn,
  lastFrozenOn,
  synchronizationStatus,
  styleForDialog,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper.working-version-popover',
  })

  const { format: formatDateAndTime } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
  })

  const displayMajorVersionLastSyncDate =
    majorVersionLastSyncedOn && synchronizationStatus !== decisionPaperSynchronizationStatus.live

  const displayMinorVersionLastSyncDate =
    minorVersionLastSyncedOn && synchronizationStatus !== decisionPaperSynchronizationStatus.live

  const className = styleForDialog ? '' : `${styles.header}`

  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      alignItems={FlexBoxAlignItems.Start}
      className={className}
    >
      {headerText && (
        <FlexBox alignItems={FlexBoxAlignItems.Center}>
          <Text>{headerText}</Text>
        </FlexBox>
      )}
      {lastEditedOn && (
        <FlexBox alignItems={FlexBoxAlignItems.Center}>
          <Label>{t('last-edited') + formatDateAndTime(lastEditedOn)}</Label>
        </FlexBox>
      )}
      {lastFrozenOn && (
        <FlexBox alignItems={FlexBoxAlignItems.Center}>
          <Label>{t('last-frozen') + formatDateAndTime(lastFrozenOn)}</Label>
        </FlexBox>
      )}
      {displayMajorVersionLastSyncDate && (
        <FlexBox alignItems={FlexBoxAlignItems.Center}>
          <Label>{t('last-synced') + formatDateAndTime(majorVersionLastSyncedOn)}</Label>
        </FlexBox>
      )}
      {displayMinorVersionLastSyncDate && (
        <FlexBox alignItems={FlexBoxAlignItems.Center}>
          <Label>
            {t('last-synced-minor-version') + formatDateAndTime(minorVersionLastSyncedOn)}
          </Label>
        </FlexBox>
      )}
    </FlexBox>
  )
}

DecisionPaperVersionInformation.propTypes = {
  headerText: PropTypes.string,
  majorVersionLastSyncedOn: PropTypes.string,
  minorVersionLastSyncedOn: PropTypes.string,
  lastFrozenOn: PropTypes.string,
  lastEditedOn: PropTypes.string,
  synchronizationStatus: PropTypes.string,
  styleForDialog: PropTypes.bool,
}

export default DecisionPaperVersionInformation
