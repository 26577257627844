import {
  Button,
  ButtonDesign,
  MessageBox,
  MessageBoxTypes,
  Modals,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import LabeledSwitch from 'components/ui/input/LabeledSwitch'
import useChineseWall from 'hooks/services/deals/chinese-wall/useChineseWall'
import useUpdateChineseWall from 'hooks/services/deals/chinese-wall/useUpdateChineseWall'

const ChineseWallToggle = ({ dealUuid, isAllowedToUpdateDeal }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { data: chineseWall, isLoading, isError } = useChineseWall(dealUuid)
  const [isConfirmDialogOpen, setIsDialogOpen] = useState(false)

  const { mutate: updateChineseWall } = useUpdateChineseWall()

  const onSuccess = () => {
    setIsDialogOpen(false)

    Modals.showToast({
      children: (
        <Text>
          {chineseWall
            ? t('pages.deals.overview.deactivate-chinese-wall-success')
            : t('pages.deals.overview.activate-chinese-wall-success')}
        </Text>
      ),
    })
    queryClient.invalidateQueries(['deals', dealUuid, 'chinese-wall'])
  }

  const onActivationConfirm = () => {
    updateChineseWall({ dealUuid, chineseWall: true }, { onSuccess })
  }

  const onDeactivationConfirm = () => {
    updateChineseWall({ dealUuid, chineseWall: false }, { onSuccess })
  }

  if (isLoading || isError) {
    return undefined
  }

  return (
    <>
      <LabeledSwitch
        disabled={!isAllowedToUpdateDeal}
        checkedText={t('pages.deals.overview.chinese-wall-on')}
        uncheckedText={t('pages.deals.overview.chinese-wall-off')}
        isLoading={isLoading}
        onChange={() => {
          setIsDialogOpen(true)
        }}
        checked={chineseWall}
      />
      {createPortal(
        <MessageBox
          id="chinese-wall-confirmation-dialog"
          titleText={t('components.confirmation-dialog.title')}
          type={MessageBoxTypes.Confirm}
          open={isConfirmDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          actions={[
            <Button
              key="confirm"
              design={ButtonDesign.Emphasized}
              onClick={() => (chineseWall ? onDeactivationConfirm() : onActivationConfirm())}
            >
              {t('buttons.confirm')}
            </Button>,
            <Button key="cancel">{t('buttons.cancel')}</Button>,
          ]}
        >
          {chineseWall
            ? t('pages.deals.overview.deactivate-chinese-wall-confirmation')
            : t('pages.deals.overview.activate-chinese-wall-confirmation')}
        </MessageBox>,
        document.body,
      )}
    </>
  )
}

ChineseWallToggle.propTypes = {
  dealUuid: PropTypes.string,
  isAllowedToUpdateDeal: PropTypes.bool,
}

export default ChineseWallToggle
