// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YXBdv84TlqGwnedxldjE {
  padding-top: 16px;
  padding-bottom: 8px;
  color: var(--sapInformativeColor);
  text-decoration: none;
}

.YhON6n4RiHF1f0TUlb9c {
  margin-top: 12px;
  white-space: pre-wrap;
  width: 100ch;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/dialog/MessageBoxWithExpandableDetailsShowDetails.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,iCAAiC;EACjC,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,YAAY;AACd","sourcesContent":[".toggle-message-details-link {\n  padding-top: 16px;\n  padding-bottom: 8px;\n  color: var(--sapInformativeColor);\n  text-decoration: none;\n}\n\n.message-details {\n  margin-top: 12px;\n  white-space: pre-wrap;\n  width: 100ch;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle-message-details-link": `YXBdv84TlqGwnedxldjE`,
	"message-details": `YhON6n4RiHF1f0TUlb9c`
};
export default ___CSS_LOADER_EXPORT___;
