import {
  Text,
  IllustratedMessage,
  IllustrationMessageType,
  Link,
  IllustrationMessageSize,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import '@ui5/webcomponents-fiori/dist/illustrations/ErrorScreen.js'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/errors/DefaultErrorPage.module.css'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'

const propTypes = {
  error: PropTypes.object,
  errorInfo: PropTypes.shape({
    componentStack: PropTypes.any,
  }),
  titleText: PropTypes.string,
  subtitleText: PropTypes.string,
  showDetails: PropTypes.bool,
  Wrapper: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.string]),
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const DefaultErrorPage = ({
  error,
  errorInfo,
  titleText = null,
  subtitleText = null,
  showDetails = true,
  Wrapper = 'div',
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.error.page' })
  const showMessageBox = useShowMessageBox()

  const renderErrorDetails = useCallback(
    () => (
      <>
        <Text wrapping={true} renderWhitespace={true}>
          {error && error.toString()}
          &nbsp;
        </Text>
        <pre>{errorInfo?.componentStack ?? ''}</pre>
      </>
    ),
    [error, errorInfo],
  )

  const openDetails = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: renderErrorDetails(),
      className: styles.errorDetails,
    })
  }, [renderErrorDetails, showMessageBox])

  const renderContent = useCallback(
    () => (
      <IllustratedMessage
        name={IllustrationMessageType.UnableToLoad}
        titleText={titleText ?? t('title')}
        subtitleText={subtitleText ?? t('subtitle')}
        size={IllustrationMessageSize.Dialog}
      >
        {showDetails && (
          <Link className={styles['toggle-message-details-link']} onClick={openDetails}>
            {t('buttons.show-details')}
          </Link>
        )}
      </IllustratedMessage>
    ),
    [openDetails, showDetails, subtitleText, t, titleText],
  )

  return <Wrapper className={styles.wrapper}>{renderContent()}</Wrapper>
}
DefaultErrorPage.propTypes = propTypes

export default DefaultErrorPage
