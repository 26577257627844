import {
  DynamicPageTitle,
  Title,
  Label,
  Breadcrumbs,
  BreadcrumbsItem,
  ObjectStatus,
  TitleLevel,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { isEmpty } from 'lodash'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from 'components/ui/page/Header.module.css'
import StatusDetailsPopover from 'components/ui/page/StatusDetailsPopover'

const propTypes = {
  title: PropTypes.string.isRequired,
  /** will be rendered instead of the id if it is defined */
  subtitle: PropTypes.node,
  id: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      href: PropTypes.string,
    }).isRequired,
  ),
  status: PropTypes.shape({
    /** Only used when showing the additional status popover */
    label: PropTypes.string,
    text: PropTypes.string.isRequired,
    valueState: PropTypes.oneOf(Object.values(ValueState)),
  }),
  additionalStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      valueState: PropTypes.oneOf(Object.values(ValueState)),
      label: PropTypes.string,
    }).isRequired,
  ),
  actions: PropTypes.node,
  className: PropTypes.string,
}

/**
 * Header component
 *
 * has been tested to be used with TabPage and DynamicPage.
 * Please use TabPage over ObjectPage to get deep-linkable tabs
 */
const Header = React.forwardRef(
  /**
   * @typedef {object} overrides
   * @property {Parameters<typeof DynamicPageTitle>[0]['actions']} actions
   * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
   */ (
    { title, id, subtitle, breadcrumbs = [], actions, status, additionalStatuses, className },
    _ref,
  ) => {
    const navigate = useNavigate()

    const hasBreadcrumbs = !!breadcrumbs?.length

    if (hasBreadcrumbs) delete breadcrumbs.at(-1)?.href

    return (
      <DynamicPageTitle
        id={styles.header}
        header={<Title level={TitleLevel.H1}>{title}</Title>}
        breadcrumbs={
          hasBreadcrumbs && (
            <Breadcrumbs
              onItemClick={(e) => {
                e.preventDefault()
                const href = e.detail.item.href
                if (href) navigate(e.detail.item.href)
              }}
              className={styles.breadcrumbs}
            >
              {breadcrumbs.map(({ text, href }, idx) => (
                <BreadcrumbsItem key={href ?? idx} href={href ?? undefined}>
                  {text}
                </BreadcrumbsItem>
              ))}
            </Breadcrumbs>
          )
        }
        subHeader={subtitle ?? <Label className={styles.headerId}>{id}</Label>}
        actions={actions}
        className={[className, hasBreadcrumbs ? styles.hasBreadcrumbs : styles.noBreadcrumbs].join(
          ' ',
        )}
      >
        {status?.text && (
          <ObjectStatus
            inverted
            state={status.valueState ?? ValueState.None}
            className={styles['header-status']}
          >
            {status.text}
          </ObjectStatus>
        )}
        {!isEmpty(additionalStatuses) && (
          <StatusDetailsPopover listOfStatuses={compact([status, ...(additionalStatuses ?? [])])} />
        )}
      </DynamicPageTitle>
    )
  },
)

Header.displayName = 'Header'
Header.propTypes = propTypes

export default Header
