import { Label, WrappingType } from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import {
  QuestionLayout,
  ShrinkValue,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/forbearanceAndUtpQuestionareSchema'
import questionStyle from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/shared/checksheet/Question.module.css'

const propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  layout: PropTypes.oneOf(Object.values(QuestionLayout)),
  isRequired: PropTypes.bool,
  shrinkValue: PropTypes.number,
  wrappingType: PropTypes.string,
}
/**
 * @typedef {object} overrides
 * @property {(newValue: string) => void} [onChange]
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 */
const Question = ({
  isEditMode,
  isRequired,
  label,
  children,
  layout,
  shrinkValue = ShrinkValue.NONE,
  wrappingType = WrappingType.Normal,
}) => {
  const getLayout = () => {
    switch (layout) {
      case QuestionLayout.ROW:
        return questionStyle.wrapperRow
      case QuestionLayout.COLUMN:
        return questionStyle.wrapperColumn
      default:
        return ''
    }
  }

  const getShrink = () => {
    switch (shrinkValue) {
      case ShrinkValue.NONE:
        return questionStyle.shrinkValueNone
      case ShrinkValue.MEDIUM:
        return questionStyle.shrinkValueMedium
      case ShrinkValue.LARGE:
        return questionStyle.shrinkValueLarge
      default:
        return ''
    }
  }

  return (
    <div
      className={compact([
        questionStyle.wrapper,
        getLayout(),
        isEditMode && questionStyle.editMode,
      ]).join(' ')}
    >
      <Label
        required={isEditMode && isRequired}
        className={questionStyle.label}
        wrappingType={wrappingType}
      >
        <Trans>
          <div>{label}</div>
        </Trans>
      </Label>
      <div className={getShrink()}>{children}</div>
    </div>
  )
}

Question.propTypes = propTypes

export default Question
