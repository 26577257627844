import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useAddTaskWatcher = (options) => {
  const { post } = useAccessTokenRequest()
  return useMutation(async ({ eventId, taskId, userId }) => {
    const { data } = await post({
      path: `/events/${eventId}/tasks/${taskId}/watchers`,
      body: {
        user_id: userId,
      },
    })
    return data
  }, options)
}
export default useAddTaskWatcher
