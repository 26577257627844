import {
  Button,
  ButtonDesign,
  Modals,
  ResponsiveGridLayout,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  conditionsEntityTypes,
  conditionsMassEditOperations,
  requirementStatusTypes,
} from 'api/conditions/conditions'
import ConditionsMassEditAssigneeSelect from 'components/domains/conditions/dialogs/mass-editing/ConditionsMassEditAssigneeSelect'
import ConditionsMassEditDealExternalAssigneeSelect from 'components/domains/conditions/dialogs/mass-editing/ConditionsMassEditDealExternalAssigneeSelect'
import ConditionsMassEditRefSelect from 'components/domains/conditions/dialogs/mass-editing/ConditionsMassEditRefSelect'
import RequirementsMassEditConditionSelect from 'components/domains/conditions/dialogs/requirements/mass-editing/RequirementsMassEditConditionSelect'
import RequirementsMassEditDocumentTypeSelectWrapper from 'components/domains/conditions/dialogs/requirements/mass-editing/RequirementsMassEditDocumentTypeSelectWrapper'
import RequirementsMassEditDueDateSelect from 'components/domains/conditions/dialogs/requirements/mass-editing/RequirementsMassEditDueDateSelect'
import RequirementsMassEditStatusSelect from 'components/domains/conditions/dialogs/requirements/mass-editing/RequirementsMassEditStatusSelect'
import { calculateIsDocumentTypeSelectDisabled } from 'components/domains/conditions/dialogs/requirements/mass-editing/calculateIsDocumentTypeSelectDisabled'
import Dialog, { DialogPrimaryButton, DialogSecondaryButton } from 'components/ui/dialog/Dialog'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import useMassUpdateRequirements from 'hooks/services/conditions/requirements/useMassUpdateRequirements'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const selectionFields = {
  condition: 'condition',
  ref: 'ref',
  documentType: 'documentType',
  assignee: 'assignee',
  externalAssignee: 'externalAssignee',
  status: 'status',
  dueDate: 'dueDate',
}

const RequirementsMassEditDialog = ({
  selectedRequirementIds,
  selectedBusinessObjectRefs,
  open,
  setIsOpen,
}) => {
  const { t: tNoPrefix } = useTranslation('translation')
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.dialogs.mass-edit',
  })
  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()
  const { mutate: updateRequirements, isLoading } = useMassUpdateRequirements()

  const {
    entityRef: { entityType, entityId },
  } = useContext(ConditionsContext)
  const [fieldsToChange, setFieldsToChange] = useState({})
  const [showStatusWarning, setShowStatusWarning] = useState(false)

  const handleSaveSuccess = useCallback(() => {
    setIsOpen(false)
    queryClient.invalidateQueries(['conditions', 'external', entityType, entityId])
    showToast({ children: t('save.success') })
  }, [setIsOpen, queryClient, entityType, entityId, showToast, t])

  const handleSaveError = useCallback(
    ({ retryAction }) => {
      showMessageBox({
        type: MessageBoxTypes.Error,
        titleText: t('save.error.title'),
        children: t('save.error.description'),
        actions: [
          <Button
            key="save-error-retry-button"
            data-action={MessageBoxActions.Retry}
            design={ButtonDesign.Transparent}
          >
            {tNoPrefix('buttons.try-again')}
          </Button>,
          <Button
            key="save-error-cancel-button"
            data-action={MessageBoxActions.Cancel}
            design={ButtonDesign.Transparent}
          >
            {tNoPrefix('buttons.cancel')}
          </Button>,
        ],
        onClose: ({ detail: { action } }) => {
          if (action !== MessageBoxActions.Retry) {
            return
          }
          retryAction()
        },
      })
    },
    [showMessageBox, t, tNoPrefix],
  )

  const setDialogClosed = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const getChangedValue = useCallback(
    (field) => {
      if (isNil(fieldsToChange[field])) {
        return undefined
      }
      const { operation, value } = fieldsToChange[field]
      switch (operation) {
        case conditionsMassEditOperations.clear:
          return null
        case conditionsMassEditOperations.replace:
          return value
        default:
          return undefined
      }
    },
    [fieldsToChange],
  )

  const save = useCallback(() => {
    const statusObject = getChangedValue(selectionFields.status)
    updateRequirements(
      {
        requirementIds: selectedRequirementIds,
        editedFields: {
          condition: getChangedValue(selectionFields.condition)?.id,
          refNumber: getChangedValue(selectionFields.ref),
          documentType: getChangedValue(selectionFields.documentType),
          assignee: getChangedValue(selectionFields.assignee),
          externalAssignee: getChangedValue(selectionFields.externalAssignee),
          dueDate: getChangedValue(selectionFields.dueDate),
          statusCode: statusObject?.code,
        },
      },
      {
        onSuccess: handleSaveSuccess,
        onError: () => handleSaveError({ retryAction: save }),
      },
    )
  }, [
    selectedRequirementIds,
    getChangedValue,
    updateRequirements,
    handleSaveSuccess,
    handleSaveError,
  ])

  const openStatusWarning = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Confirm,
      titleText: tNoPrefix('buttons.save'),
      children: t('status.warning.description'),
      actions: [
        <Button
          key="status-warning-confirm-button"
          data-action={MessageBoxActions.Yes}
          design={ButtonDesign.Emphasized}
        >
          {tNoPrefix('buttons.save')}
        </Button>,
        <Button
          key="status-warning-cancel-button"
          data-action={MessageBoxActions.Cancel}
          design={ButtonDesign.Transparent}
        >
          {tNoPrefix('buttons.cancel')}
        </Button>,
      ],
      emphasizedAction: MessageBoxActions.Retry,
      onClose: ({ detail: { action } }) => {
        if (action !== MessageBoxActions.Yes) {
          return
        }
        save()
      },
    })
  }, [showMessageBox, save, t, tNoPrefix])

  const handleSaveButtonClicked = useCallback(() => {
    if (showStatusWarning) {
      openStatusWarning()
      return
    }
    save()
  }, [showStatusWarning, openStatusWarning, save])

  const onConditionChange = useCallback(
    (changedItem) => {
      const isDocumentTypeDisabled = calculateIsDocumentTypeSelectDisabled(
        selectedBusinessObjectRefs,
        changedItem,
      )
      const documentTypeChanges = isDocumentTypeDisabled
        ? { [selectionFields.documentType]: { operation: conditionsMassEditOperations.keep } }
        : {}
      setFieldsToChange({
        ...fieldsToChange,
        ...documentTypeChanges,
        [selectionFields.condition]: changedItem,
      })
    },
    [fieldsToChange, selectedBusinessObjectRefs],
  )

  const getSelectionChangedAction = useCallback(
    (selectionField) => (changedItem) => {
      if (selectionField === selectionFields.condition) {
        onConditionChange(changedItem)
      } else {
        setFieldsToChange({ ...fieldsToChange, [selectionField]: changedItem })
      }
    },
    [onConditionChange, fieldsToChange],
  )

  const handleStatusSelectionChanged = useCallback(
    (changeItem) => {
      setShowStatusWarning(changeItem.value?.type === requirementStatusTypes.done)
      getSelectionChangedAction(selectionFields.status)(changeItem)
    },
    [getSelectionChangedAction],
  )

  const hasChanges = useMemo(
    () =>
      !isEmpty(fieldsToChange) &&
      !Object.values(fieldsToChange).every(
        ({ operation }) => operation === conditionsMassEditOperations.keep,
      ),
    [fieldsToChange],
  )

  const isSaveButtonDisabled = useMemo(() => {
    if (!hasChanges) {
      return true
    }
    const conditionChange = fieldsToChange[selectionFields.condition]
    if (isNil(conditionChange)) {
      return false
    }
    const { operation, value } = conditionChange
    return operation === conditionsMassEditOperations.replace && isNil(value)
  }, [fieldsToChange, hasChanges])

  const businessObjectRefs = useMemo(() => {
    const conditionChange = fieldsToChange[selectionFields.condition]
    const isReplaceCondition = conditionChange?.operation === conditionsMassEditOperations.replace
    const replaceConditionBusinessObjectRef =
      fieldsToChange[selectionFields.condition]?.value?.businessObjectRef

    if (isReplaceCondition) {
      return replaceConditionBusinessObjectRef
        ? [replaceConditionBusinessObjectRef]
        : [{ entityType, entityId }]
    }
    return selectedBusinessObjectRefs
  }, [fieldsToChange, selectedBusinessObjectRefs, entityType, entityId])

  const isDocumentTypeSelectionDisabled = useMemo(
    () =>
      calculateIsDocumentTypeSelectDisabled(
        businessObjectRefs,
        fieldsToChange[selectionFields.condition],
      ),
    [businessObjectRefs, fieldsToChange],
  )

  const onAfterClose = useCallback(() => {
    setDialogClosed(false)
  }, [setDialogClosed])

  const onBeforeClose = useCallback(
    (event) => {
      if (isLoading) {
        event.preventDefault()
      }
    },
    [isLoading],
  )

  return (
    <Dialog
      open={open}
      onAfterClose={onAfterClose}
      onBeforeClose={onBeforeClose}
      headerText={t('header.text', { numberOfSelectedRequirements: selectedRequirementIds.length })}
      primaryButton={
        <DialogPrimaryButton
          onClick={handleSaveButtonClicked}
          disabled={isSaveButtonDisabled}
          isLoading={isLoading}
        >
          {tNoPrefix('buttons.save')}
        </DialogPrimaryButton>
      }
      closeButton={
        <DialogSecondaryButton onClick={setDialogClosed} disabled={isLoading}>
          {tNoPrefix('buttons.close')}
        </DialogSecondaryButton>
      }
    >
      <ResponsiveGridLayout columnsXL={1} columnsL={1} columnsM={1} columnsS={1} rowGap="8px">
        <RequirementsMassEditConditionSelect
          onChange={getSelectionChangedAction(selectionFields.condition)}
          disabled={isLoading}
        />
        <ConditionsMassEditRefSelect
          onChange={getSelectionChangedAction(selectionFields.ref)}
          disabled={isLoading}
        />
        <RequirementsMassEditDocumentTypeSelectWrapper
          forceKeepValues={isDocumentTypeSelectionDisabled}
          onChange={getSelectionChangedAction(selectionFields.documentType)}
          disabled={isLoading}
        />
        <ConditionsMassEditAssigneeSelect
          onChange={getSelectionChangedAction(selectionFields.assignee)}
          disabled={isLoading}
        />
        {entityType === conditionsEntityTypes.deal && (
          <ConditionsMassEditDealExternalAssigneeSelect
            onChange={getSelectionChangedAction(selectionFields.externalAssignee)}
            disabled={isLoading}
          />
        )}
        <RequirementsMassEditStatusSelect
          onChange={handleStatusSelectionChanged}
          disabled={isLoading}
        />
        <RequirementsMassEditDueDateSelect
          onChange={getSelectionChangedAction(selectionFields.dueDate)}
          disabled={isLoading}
        />
      </ResponsiveGridLayout>
    </Dialog>
  )
}

RequirementsMassEditDialog.propTypes = {
  selectedRequirementIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedBusinessObjectRefs: PropTypes.arrayOf(
    PropTypes.shape({
      entityId: PropTypes.string.isRequired,
      entityType: PropTypes.string.isRequired,
    }),
  ).isRequired,
  open: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default RequirementsMassEditDialog
