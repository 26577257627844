import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import {
  GCC_UNIT_ID,
  REVIEW_UNIT_ID,
  STANDALONE_BP_UNIT_ID,
} from 'hooks/services/business-partners/unit-overview/constants'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const REQUEST_RETRIES = 3

const useBusinessPartnerUnitOverviewUnits = (businessPartnerId) => {
  const result = useCamelizedResponse(
    useRequest({
      path: `/businesspartners/${businessPartnerId}/unit-overview/units`,
      translated: false,
      keys: ['businesspartners', businessPartnerId, 'unit-overview', 'unit-overview-units'],
      options: {
        enabled: !!businessPartnerId,
        retry: (failureCount, error) => {
          if (isNotFoundError(error) || isMissingPermissionError(error)) {
            return false
          }
          if (failureCount < REQUEST_RETRIES) {
            return true
          }
          return false
        },
      },
    }),
  )

  const gcc = result?.data?.units?.filter((unit) => unit?.id === GCC_UNIT_ID) ?? []
  const review = result?.data?.units?.filter((unit) => unit?.id === REVIEW_UNIT_ID) ?? []
  const standalone = result?.data?.units?.filter((unit) => unit?.id === STANDALONE_BP_UNIT_ID) ?? []

  return {
    ...result,
    data: {
      gcc,
      review,
      standalone,
    },
  }
}

export default useBusinessPartnerUnitOverviewUnits
