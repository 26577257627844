import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'

const resolve = (object, path) =>
  path?.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), object)

const sortArray = (array, sortCriteria) => {
  array.sort((a, b) => {
    let result = 0
    for (const entry of sortCriteria) {
      const { orderField, orderDirection } = entry
      const fieldA = resolve(a, orderField)
      const fieldB = resolve(b, orderField)
      if (typeof fieldA === 'string') {
        const comparison = fieldA.localeCompare(fieldB)
        if (comparison !== 0) {
          result = orderDirection === 'desc' ? -comparison : comparison
          break
        }
      } else {
        const comparison = fieldA - fieldB
        if (comparison !== 0) {
          result = orderDirection === 'desc' ? -comparison : comparison
          break
        }
      }
    }
    return result
  })
  return array
}

/**
 * Converts an unpaginated response to a paginated response.
 *
 * @param fetchResult Result of previous hook
 * @param dataKey Attribute under which the data is
 * @param initialSort Array of sort criteria
 * @param sort {orderField, orderDirection} One sort criteria, usually provided by SortedTable
 * @param offset Offset
 * @param limit Limit
 * @returns {{data: {pagination: {total: (*|number), offset: (*|number), limit: (*|number)}}, isMaximumResultSizeExceeded: boolean}}
 */
export const useFrontendPagination = ({
  fetchResult = {},
  dataKey = '',
  initialSort = [],
  sort = {},
  pagination: { offset, limit } = {},
}) => {
  const isMaximumResultSizeExceeded = !(fetchResult?.data?.isComplete ?? true)
  const allItems = fetchResult?.data?.[dataKey] ?? null
  const areItemsAvailable = allItems !== null

  let paginatedItems = useMemo(() => {
    if (areItemsAvailable) {
      if (isEmpty(sort)) {
        return sortArray(allItems, initialSort)
      } else {
        return sortArray(allItems, [sort])
      }
    }
    return []
  }, [allItems, areItemsAvailable, initialSort, sort])

  // Paginate Items (Frontend) from all items
  if (areItemsAvailable && offset !== undefined && limit !== undefined) {
    paginatedItems = allItems.slice(offset, offset + limit)
  }

  // Set Pagination defaults
  const pagination = {
    offset: offset ?? 0,
    limit: areItemsAvailable ? paginatedItems.length : 0,
    total: areItemsAvailable ? allItems.length : 0,
  }

  return {
    ...fetchResult,
    data: {
      [dataKey]: camelize(paginatedItems),
      pagination,
    },
    isMaximumResultSizeExceeded: isMaximumResultSizeExceeded,
  }
}
