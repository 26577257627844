import { mapForbearanceStatus } from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/borrower/mapForbearanceStatus'
import { mapFpoResult } from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/borrower/mapFpoResult'
import { mapNextAnnualReview } from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/borrower/mapNextAnnualReview'
import { mapRiskMonitoringStatus } from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/borrower/mapRiskMonitoringStatus'
import {
  ROW_TYPE_STATUS,
  ROW_TYPE_BUSINESS_PARTNER,
  ROW_TYPE_DATE,
  DEFAULT_DISPLAY_ROW_HEIGHT,
  DEFAULT_DISPLAY_ENTITY_TEXT_HEIGHT,
  DEFAULT_DISPLAY_CELL_PADDING,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/constants'

const rowKeys = [
  'businessPartner',
  'riskMonitoringStatus',
  'forbearanceStatus',
  'fpoResult',
  'nextAnnualReview',
]

const mapRowData = ({
  rowKey,
  deals,
  multipleForbearanceStatusesResponses,
  multipleCovenantMonitoringItems,
  multipleBusinessPartnerCustomFields,
  businessPartnerCustomFieldConfigMetadata,
}) => {
  const createRow = (createObjectFunction) =>
    deals?.map(createObjectFunction).reduce((prev, curr) => Object.assign(prev, curr), {})

  switch (rowKey) {
    case 'businessPartner':
      return createRow(({ kpiDeal: { dealUuid, bpId, bpName } }) => ({
        [dealUuid]: {
          value: {
            businessPartnerId: bpId,
            businessPartnerName: bpName,
          },
        },
      }))

    case 'forbearanceStatus':
      return createRow(({ kpiDeal: { dealUuid } }) => ({
        [dealUuid]: {
          value: mapForbearanceStatus(dealUuid, multipleForbearanceStatusesResponses),
        },
      }))

    case 'nextAnnualReview':
      return createRow(({ kpiDeal: { dealUuid, bpId } }) => ({
        [dealUuid]: {
          value: mapNextAnnualReview(bpId, multipleCovenantMonitoringItems),
        },
      }))

    case 'riskMonitoringStatus':
      return createRow(({ kpiDeal: { dealUuid } }, requestStates) => ({
        [dealUuid]: {
          value: mapRiskMonitoringStatus(
            dealUuid,
            multipleBusinessPartnerCustomFields,
            businessPartnerCustomFieldConfigMetadata,
          ),
          ...requestStates,
        },
      }))

    case 'fpoResult':
      return createRow(({ kpiDeal: { dealUuid } }, requestStates) => ({
        [dealUuid]: {
          value: mapFpoResult(
            dealUuid,
            multipleBusinessPartnerCustomFields,
            businessPartnerCustomFieldConfigMetadata,
          ),
          ...requestStates,
        },
      }))
  }
}

const mapRowType = (rowKey) => {
  switch (rowKey) {
    case 'businessPartner':
      return ROW_TYPE_BUSINESS_PARTNER

    case 'forbearanceStatus':
    case 'riskMonitoringStatus':
    case 'fpoResult':
      return ROW_TYPE_STATUS

    case 'nextAnnualReview':
      return ROW_TYPE_DATE
  }
}

const mapRowHeight = (rowKey) => {
  switch (rowKey) {
    case 'businessPartner':
      return { rowHeight: DEFAULT_DISPLAY_ENTITY_TEXT_HEIGHT + DEFAULT_DISPLAY_CELL_PADDING }

    default:
      return { rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT }
  }
}

const mapBorrowerData = ({
  deals,
  multipleForbearanceStatusesResponses,
  multipleCovenantMonitoringItems,
  multipleBusinessPartnerCustomFields,
  businessPartnerCustomFieldConfigMetadata,
  t,
}) =>
  rowKeys.map((rowKey) => {
    const rowData = mapRowData({
      rowKey,
      deals,
      multipleForbearanceStatusesResponses,
      multipleCovenantMonitoringItems,
      multipleBusinessPartnerCustomFields,
      businessPartnerCustomFieldConfigMetadata,
    })

    return {
      kpi: t(`rows.section.borrower.${rowKey}`),
      ...mapRowHeight(rowKey),
      ...rowData,
      rowType: mapRowType(rowKey),
      rowHasError: Object.values(rowData).some((column) => column.isError),
    }
  })

export default mapBorrowerData
