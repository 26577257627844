import '@ui5/webcomponents-fiori/dist/illustrations/NoData.js'
import {
  FlexBox,
  FlexBoxDirection,
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonCard.module.css'
import PropertyRentRollComparisonTable from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonTable'
import RentRollKeyDatesDropdown from 'components/domains/properties/rent-roll/comparison/RentRollKeyDatesDropdown'
import Card from 'components/ui/card/Card'
import CardHeaderWithButtons from 'components/ui/card/CardHeaderWithButtons'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useSegments } from 'hooks/services/properties/segments/useSegments'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyRentRollComparisonCard = () => {
  const { t: tComparison } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.comparison',
  })

  const { selectedCurrency } = useSelector((state) => state.properties.commonCurrency)
  const { property } = useContext(PropertyContext)

  const {
    isLoading: isLoadingSegments,
    isError: isErrorSegments,
    data: segmentsData,
  } = useSegments({
    propertyUuid: property.uuid,
    preferredCurrency: selectedCurrency ?? property?.currency_code,
  })
  const segments = segmentsData?.segments ?? []

  return (
    <Card
      header={
        <CardHeaderWithButtons title={tComparison('title')}>
          <RentRollKeyDatesDropdown propertyUuids={[property.uuid]} />
        </CardHeaderWithButtons>
      }
    >
      <RequestStateResolver
        isLoading={isLoadingSegments}
        isError={isErrorSegments}
        center={true}
        renderContent={() => (
          <FlexBox direction={FlexBoxDirection.Column} className={styles.tablePadding}>
            <PropertyRentRollComparisonTable property={property} segments={segments} />
          </FlexBox>
        )}
        renderCustomErrorScreen={() => (
          <IllustratedMessage
            name={IllustrationMessageType.NoData}
            titleText={tComparison('error.title')}
            subtitleText={tComparison('error.subtitle')}
            size={IllustrationMessageSize.Spot}
          />
        )}
      />
    </Card>
  )
}
export default PropertyRentRollComparisonCard
