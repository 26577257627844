import isNil from 'lodash.isnil'
import { DEFAULT_DISPLAY_TEXT_HEIGHT } from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/business-partners/constants'
import {
  ROW_TYPE_DATE,
  ROW_TYPE_MONETARY_VALUE,
  DEFAULT_DISPLAY_ROW_HEIGHT,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/constants'

const rowKeys = ['commitmentOwnShare', 'outstandingOwnShare', 'earliestMaturityDate']

const getEarliestMaturityDate = (tranches) => {
  const maturityDates = tranches?.map(({ maturity }) => maturity).filter((date) => !!date) || []
  return [...maturityDates].sort((a, b) => new Date(a) - new Date(b))?.[0]
}

// this process is necessary, because deals with tranches of mixed currencies
// (e.g., tranche 1 is in EUR, tranche 2 is in USD) only return a headquarter summary
const getTrancheSummaryData = (summary, summaryHeadquarter) => {
  let primary
  let secondary

  if (!isNil(summary) && isNil(summaryHeadquarter)) {
    primary = summary
  } else if (isNil(summary) && !isNil(summaryHeadquarter)) {
    primary = summaryHeadquarter
  } else {
    primary = summary
    secondary = summaryHeadquarter
  }
  return { primary, secondary }
}

const mapRowData = ({ rowKey, responses }) => {
  const createRow = (createObjectFunction) =>
    responses
      ?.map(({ data, isLoading, isFetching, isError }) =>
        createObjectFunction(data, { isLoading, isFetching, isError }),
      )
      .reduce((prev, curr) => Object.assign(prev, curr), {})

  switch (rowKey) {
    case 'commitmentOwnShare':
      return createRow(({ dealUuid, summary, summaryHeadquarter }, requestStates) => {
        const { primary, secondary } = getTrancheSummaryData(summary, summaryHeadquarter)
        return {
          [dealUuid]: {
            value: {
              amount: primary?.ownCommitment,
              currency: primary?.currency,
              amountHeadquarter: secondary?.ownCommitment,
              currencyHeadquarter: secondary?.currency,
            },
            ...requestStates,
          },
        }
      })
    case 'outstandingOwnShare':
      return createRow(({ dealUuid, summary, summaryHeadquarter }, requestStates) => {
        const { primary, secondary } = getTrancheSummaryData(summary, summaryHeadquarter)
        return {
          [dealUuid]: {
            value: {
              amount: primary?.outstanding,
              currency: primary?.currency,
              amountHeadquarter: secondary?.outstanding,
              currencyHeadquarter: secondary?.currency,
            },
            ...requestStates,
          },
        }
      })
    case 'earliestMaturityDate':
      return createRow(({ dealUuid, tranches }, requestStates) => ({
        [dealUuid]: {
          value: getEarliestMaturityDate(tranches),
          ...requestStates,
        },
      }))
  }
}

const mapRowType = (rowKey) => {
  switch (rowKey) {
    case 'commitmentOwnShare':
    case 'outstandingOwnShare':
      return ROW_TYPE_MONETARY_VALUE
    case 'earliestMaturityDate':
      return ROW_TYPE_DATE
  }
}

const mapRowHeight = (rowKey, rowData) => {
  const hasOneEntryWithDifferentHeadquarterCurrency = (data) =>
    Object.values(data).some(
      ({ value }) =>
        value.currency !== value.currencyHeadquarter && !isNil(value.currencyHeadquarter),
    )

  switch (rowKey) {
    case 'commitmentOwnShare':
    case 'outstandingOwnShare':
      return {
        rowHeight: hasOneEntryWithDifferentHeadquarterCurrency(rowData)
          ? DEFAULT_DISPLAY_ROW_HEIGHT + DEFAULT_DISPLAY_TEXT_HEIGHT
          : DEFAULT_DISPLAY_ROW_HEIGHT,
      }

    default:
      return { rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT }
  }
}

const mapMultipleDealTrancheResponses = ({ multipleDealTrancheResponses, t }) =>
  rowKeys.map((rowKey) => {
    const rowData = mapRowData({
      rowKey,
      responses: multipleDealTrancheResponses,
    })

    return {
      kpi: t(`rows.section.loan.${rowKey}`),
      ...mapRowHeight(rowKey, rowData),
      ...rowData,
      rowType: mapRowType(rowKey),
      rowHasError: Object.values(rowData).some((column) => column.isError),
    }
  })

export default mapMultipleDealTrancheResponses
