import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/authority-level-calculator/SharedAuthorityLevelCalculatorHeader.module.css'
import DecisionStageLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/decision-stage-leave-confirmation/DecisionStageLeaveConfirmationLink'

const SharedAuthorityLevelCalculatorHeader = ({
  businessPartnerId,
  businessPartnerName,
  isStandaloneBusinessPartner = false,
  showConfirmationForLinks = false,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.shared.authority-level-calculator.header',
  })

  const [nameLabel, idLabel] = isStandaloneBusinessPartner
    ? [t('standaloneBpLabel'), t('standaloneBpId')]
    : [t('gccUnitLabel'), t('gccUnitId')]

  return (
    <FlexBox className={styles.headerWrapper} direction={FlexBoxDirection.Column}>
      <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween}>
        <Label>{nameLabel}</Label>
        <DecisionStageLeaveConfirmationLink
          name={businessPartnerName}
          link={`/business-partners/${businessPartnerId}`}
          showConfirmationForLinks={showConfirmationForLinks}
        />
      </FlexBox>
      <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween}>
        <Label>{idLabel}</Label>
        <Text>{businessPartnerId}</Text>
      </FlexBox>
    </FlexBox>
  )
}

SharedAuthorityLevelCalculatorHeader.propTypes = {
  businessPartnerName: PropTypes.string,
  businessPartnerId: PropTypes.string,
  isStandaloneBusinessPartner: PropTypes.bool,
  showConfirmationForLinks: PropTypes.bool,
}

export default SharedAuthorityLevelCalculatorHeader
