import {
  Button,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  Modals,
  PopoverPlacementType,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/CancelPopoverContent.module.css'

export const CancelPopoverContent = ({ onCancel, contentText, confirmText }) => {
  const { t } = useTranslation()
  return (
    <FlexBox alignItems={FlexBoxAlignItems.Center} direction={FlexBoxDirection.Column} fitContainer>
      <Text className={styles.text}>
        {contentText ?? t('components.ui.card.display-edit-card.button.discard-message')}
      </Text>
      <Button onClick={onCancel} className={styles.action}>
        {confirmText ?? t('components.ui.card.display-edit-card.button.discard')}
      </Button>
    </FlexBox>
  )
}

CancelPopoverContent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  contentText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  confirmText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}

const useCancelPopover = ({
  onConfirmCancel,
  opener,
  contentText,
  confirmText,
  container,
  placementType = PopoverPlacementType.Top,
  ...popoverProps
}) => {
  const popoverRef = useRef()
  const showPopover = Modals.useShowPopover()
  const close = () => popoverRef.current.close()
  const handleCancel = useCallback(
    (evt) => {
      setTimeout(() => {
        close()
        onConfirmCancel(evt)
      })
    },
    [onConfirmCancel],
  )
  const show = useCallback(() => {
    popoverRef.current = showPopover(
      {
        opener,
        placementType,
        children: (
          <CancelPopoverContent
            onCancel={handleCancel}
            contentText={contentText}
            confirmText={confirmText}
          />
        ),
        ...popoverProps,
      },
      container,
    )
  }, [
    confirmText,
    container,
    contentText,
    handleCancel,
    opener,
    placementType,
    popoverProps,
    showPopover,
  ])
  return {
    show,
    close,
    ref: popoverRef.current?.ref,
  }
}

export default useCancelPopover
