import { TextAlign, VerticalAlign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import UnitOverviewTableCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/UnitOverviewTableCell'

const useUnitOverviewTableColumnDefinitions = (deals) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.unit-overview.table.columns',
  })

  const getDefaultColumnDefinition = ({
    columnKey,
    accessor,
    minWidth = 100,
    hAlign = TextAlign.Left,
    isVisible = true,
    isSelectableForHiding = true,
    title,
  }) => ({
    Header: <Trans>{title ?? columnKey}</Trans>,
    title: (title ?? columnKey).replace('<br/>', ' '),
    sortByLabel: (title ?? columnKey).replace('<br/>', ' '),
    accessor: accessor ?? columnKey,
    hAlign,
    isVisible,
    minWidth,
    columnKey,
    isSelectableForHiding,
  })

  const tableColumns = [
    getDefaultColumnDefinition({
      columnKey: t('kpis'),
      accessor: 'kpi',
      minWidth: 350,
      isSelectableForHiding: false,
    }),
    ...(deals || []).map((deal) =>
      getDefaultColumnDefinition({ columnKey: deal.id, title: deal.name, minWidth: 200 }),
    ),
  ].map((element) => ({
    disableDragAndDrop: true,
    disableResizing: false,
    Cell: UnitOverviewTableCell,
    vAlign: VerticalAlign.Middle,
    ...element,
  }))

  return useMemo(() => (deals ? tableColumns : []), [deals, tableColumns])
}

useUnitOverviewTableColumnDefinitions.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default useUnitOverviewTableColumnDefinitions
