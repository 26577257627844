import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  ObjectStatus,
  ResponsiveGridLayout,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DecisionPaperLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/links/DecisionPaperLeaveConfirmationLink'
import BorrowerOverviewLabeledValue from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/borrower/BorrowerOverviewLabeledValue'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/SponsorGuarantorOverviewTile.module.css'
import determineValueStateBasedOnAssessmentCategory from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/shared/determineValueStateBasedOnAssessmentCategory'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const SponsorGuarantorOverviewInformation = ({
  businessPartner: { information },
  showConfirmationForLinks,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.bp-sponsor-guarantor-overview.information',
  })
  const { format } = useShortDateFormatter()

  const foundationDateDisplayValue = useMemo(() => {
    if (isNil(information?.foundationDate)) {
      return undefined
    }
    return format(information?.foundationDate)
  }, [format, information])

  const businessPartnerNameValue = useMemo(() => {
    if (isEmpty(information?.name)) {
      return undefined
    }
    if (isEmpty(information.businessPartnerId)) {
      return information.name
    }
    return (
      <DecisionPaperLeaveConfirmationLink
        name={information.name}
        link={`/business-partners/${information.businessPartnerId}`}
        showConfirmationForLinks={showConfirmationForLinks}
      />
    )
  }, [information?.businessPartnerId, information?.name, showConfirmationForLinks])

  const content = useMemo(() => {
    if (isNil(information)) {
      return (
        <FlexBox
          className={styles.listCardEmptyContent}
          alignItems={FlexBoxAlignItems.Center}
          justifyContent={FlexBoxJustifyContent.Center}
        >
          {t('empty')}
        </FlexBox>
      )
    }

    return (
      <ResponsiveGridLayout columnsXL={2} columnsL={2} columnsM={2} columnsS={2}>
        <FlexBox direction={FlexBoxDirection.Column}>
          {!isEmpty(information?.name) && (
            <BorrowerOverviewLabeledValue
              label={t('business-partner-name')}
              value={businessPartnerNameValue}
            />
          )}
          {!isEmpty(information?.businessPartnerId) && (
            <BorrowerOverviewLabeledValue
              label={t('business-partner-id')}
              value={information.businessPartnerId}
            />
          )}
          {!isEmpty(information?.businessPartnerRole) && (
            <BorrowerOverviewLabeledValue
              label={t('role')}
              value={information.businessPartnerRole}
            />
          )}
          {!isEmpty(information?.legalForm) && (
            <BorrowerOverviewLabeledValue label={t('legal-form')} value={information.legalForm} />
          )}
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column}>
          {!isNil(foundationDateDisplayValue) && (
            <BorrowerOverviewLabeledValue
              label={t('foundation-date')}
              value={foundationDateDisplayValue}
            />
          )}
          {!isNil(information?.industryInformation?.sectorId) && (
            <BorrowerOverviewLabeledValue
              label={t('industry-code')}
              value={`${information.industryInformation.sectorId} - ${information.industryInformation.keyDescription}`}
            />
          )}
          {!isNil(information?.overallQualityAssessment?.category) && (
            <BorrowerOverviewLabeledValue
              label={t('overall-quality-assessment')}
              value={
                <ObjectStatus
                  inverted
                  state={determineValueStateBasedOnAssessmentCategory(
                    information?.overallQualityAssessment?.category,
                  )}
                >
                  {`${information?.overallQualityAssessment?.level}-${information?.overallQualityAssessment?.levelDisplayValue}`}
                </ObjectStatus>
              }
            />
          )}
        </FlexBox>
      </ResponsiveGridLayout>
    )
  }, [businessPartnerNameValue, foundationDateDisplayValue, information, t])

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.listCard}>
      <Text className={styles.listCardTitle}>{t('title')}</Text>
      {content}
    </FlexBox>
  )
}

SponsorGuarantorOverviewInformation.propTypes = {
  showConfirmationForLinks: PropTypes.bool.isRequired,
  businessPartner: PropTypes.shape({
    information: PropTypes.shape({
      foundationDate: PropTypes.string,
      name: PropTypes.string,
      businessPartnerId: PropTypes.string,
      legalForm: PropTypes.string,
      businessPartnerRole: PropTypes.string,
      industryInformation: PropTypes.shape({
        sectorId: PropTypes.string,
        keyDescription: PropTypes.string,
      }),
      overallQualityAssessment: PropTypes.shape({
        category: PropTypes.number,
        level: PropTypes.number,
        levelDisplayValue: PropTypes.string,
      }),
    }),
  }),
}

export default SponsorGuarantorOverviewInformation
