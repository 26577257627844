import { useQuery } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const OK = 200

const getMultiKpisPerSegment = (segmentRentRollKpis) => {
  if (isEmpty(segmentRentRollKpis?.key_date_to_segment_rent_roll_kpis)) {
    return []
  }
  return segmentRentRollKpis.key_date_to_segment_rent_roll_kpis.map((segmentRentRollKpi) => ({
    key_date: segmentRentRollKpi?.key_date,
    kpis: segmentRentRollKpi?.kpis,
  }))
}

const useMultiSegmentKpis = (
  propertyUuid,
  segmentUuids,
  { currency, areaUnitOfMeasurement, keyDates, withTenantGranularity = false } = {},
) => {
  const { post } = useAccessTokenRequest()
  const { data, isLoading, isError } = useQuery({
    queryKey: [
      'segment-kpis',
      propertyUuid,
      ...segmentUuids,
      currency,
      areaUnitOfMeasurement,
      keyDates,
      withTenantGranularity,
    ],
    refetchOnWindowFocus: false,
    queryFn: () =>
      post({
        path: `/properties/kpis/segment`,
        body: {
          property_uuid: propertyUuid,
          segment_uuids: segmentUuids,
          currency: currency,
          area_unit_of_measurement: areaUnitOfMeasurement,
          key_dates: keyDates,
          with_tenant_granularity: withTenantGranularity,
        },
      }),
    retry: 0,
  })
  if (isLoading || isError) return { isLoading, isError, data: null }
  return {
    data: getMultiKpisPerSegment(data?.data),
    isError: data?.status !== OK,
    isLoading: false,
  }
}

export default useMultiSegmentKpis
