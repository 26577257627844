import { useMutation } from '@tanstack/react-query'
import isNil from 'lodash.isnil'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const formatSegment = (segment) => {
  const getStringOrUndefinedIfNotSet = (attribute) => (attribute ? attribute : undefined)
  const getNumberOrUndefinedIfNotSet = (attribute) =>
    attribute && !isNaN(attribute) ? Number(attribute) : undefined
  const getMoneyOrUndefinedIfNotSet = (value, currency) =>
    !isNil(value) && currency && !isNaN(value)
      ? {
          number: Number(value),
          currency: currency,
        }
      : undefined

  return {
    uuid: getStringOrUndefinedIfNotSet(segment?.uuid),
    name: getStringOrUndefinedIfNotSet(segment?.name),
    description: getStringOrUndefinedIfNotSet(segment?.description),
    usage_type_code: getStringOrUndefinedIfNotSet(segment?.usage_type_code),
    market_rent_amount: getMoneyOrUndefinedIfNotSet(
      segment?.market_rent_amount?.number,
      segment?.market_rent_amount?.currency,
    ),
    vacancy_cost_amount: getMoneyOrUndefinedIfNotSet(
      segment?.vacancy_cost_amount?.number,
      segment?.vacancy_cost_amount?.currency,
    ),
    total_area: getNumberOrUndefinedIfNotSet(segment?.total_area),
    area_measure_unit_code: getStringOrUndefinedIfNotSet(segment?.area_measure_unit_code),
  }
}

export const useCreateUpdateOrDeleteSegments = (options) => {
  const { put } = useAccessTokenRequest()

  return useMutation(async ({ propertyUuidsToSegments }) => {
    const body = Object.entries(propertyUuidsToSegments).reduce((acc, [propertyUuid, segments]) => {
      acc[propertyUuid] = segments.map(formatSegment)
      return acc
    }, {})
    const { data } = await put({
      path: `/properties/segments`,
      body: { property_uuids_to_segments: body },
    })
    return data
  }, options)
}
