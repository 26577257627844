export const validBreachRules = {
  LIMIT_LESS_THAN_RESULT: 'LIMIT_LESS_THAN_RESULT',
  LIMIT_GREATER_THAN_RESULT: 'LIMIT_GREATER_THAN_RESULT',
  LIMIT_LESS_EQUAL_THAN_RESULT: 'LIMIT_LESS_EQUAL_THAN_RESULT',
  LIMIT_GREATER_EQUAL_THAN_RESULT: 'LIMIT_GREATER_EQUAL_THAN_RESULT',
}

export const breachOptions = {
  NO: 'NO',
  YES: 'YES',
  SOFT: 'SOFT',
  HARD: 'HARD',
}
