import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateDealWorkingVersionEvent = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ dealUuid, body }) => {
      const response = await post({
        path: `/deals/working-versions/${dealUuid}/events`,
        body: snakecaseKeys(body, { deep: true }),
      })
      return { ...response, data: camelize(response?.data) }
    },
    { onSuccess, onError },
  )
}

export default useCreateDealWorkingVersionEvent
