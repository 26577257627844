import orderBy from 'lodash.orderby'
import { useRequest } from 'hooks/services/baseService'

const sortedCountries = (result) => orderBy(result.data.countries, ['name'])

export const useCountries = () => {
  const result = useRequest({
    path: '/businesspartners/value-helps/countries',
    translated: true,
    keys: ['countries'],
  })

  const data = result.data && { countries: sortedCountries(result) }

  return {
    ...result,
    data,
  }
}
