import snakeCase from 'snakecase-keys'
import { decisionPaperTileTypes } from 'api/decision-paper/decisionPaperApi'

export const mapTileDataToSaveCall = (tilesData, imagesData, isMinorVersion) => {
  const tilesDataArray = Object.entries(tilesData)
  const tilesImagesArray = Object.entries(imagesData)
  const tileDataAutomaticTilesArray = tilesDataArray.filter(
    ([_tileId, { type, isEditableInMinorVersion }]) =>
      (!isMinorVersion || isEditableInMinorVersion) &&
      (type === decisionPaperTileTypes.automatic ||
        type === decisionPaperTileTypes.automaticWithMetaData),
  )

  const data = tileDataAutomaticTilesArray.reduce(
    (tileResult, [tileId, tileData]) => ({
      ...tileResult,
      [tileId]: {
        version: tileData?.version || '1',
        data: snakeCase(tileData?.data || {}),
        image_data: imagesData[tileId]?.data,
      },
    }),
    {},
  )
  const loadingDataTileIds = tileDataAutomaticTilesArray
    .filter(([_tileId, tileData]) => tileData.isLoading)
    .map(([tileId]) => tileId)

  const errorDataTileIds = tileDataAutomaticTilesArray
    .filter(([_tileId, tileData]) => tileData.isError)
    .map(([tileId]) => tileId)

  const loadingImagesTileIds = tilesImagesArray
    .filter(([_tileId, tileData]) => tileData.isLoading)
    .map(([tileId]) => tileId)

  const errorImagesTileIds = tilesImagesArray
    .filter(([_tileId, tileData]) => tileData.isError)
    .map(([tileId]) => tileId)

  const loadingTileIds = Array.from(new Set(loadingDataTileIds.concat(loadingImagesTileIds)))
  const errorTileIds = Array.from(new Set(errorDataTileIds.concat(errorImagesTileIds)))

  const hasLoadingTiles = loadingTileIds.length > 0
  const hasErrorTiles = errorTileIds.length > 0
  return { data, loadingTileIds, errorTileIds, hasLoadingTiles, hasErrorTiles }
}

export const errorTypes = {
  technical: 'technical',
  tilesLoading: 'tiles-loading',
  tilesError: 'tiles-error',
}
