import compact from 'lodash.compact'
import { useMemo } from 'react'
import useMultipleFinanceReports from 'components/domains/business-partners/tile/exposure/useMultipleFinanceReports'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationships'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const JOINT_ACCOUNT_CODE = 'YJR080'

const useTotalDebtBundesbankTile = ({ entityRef: { entityId: businessPartnerId } }, tileId) => {
  const {
    data: businessPartnerRelationships,
    isLoading: isBusinessPartnerRelationshipsLoading,
    isError: isBusinessPartnerRelationshipsError,
    error: businessPartnerRelationshipsError,
  } = useBusinessPartnerRelationships(businessPartnerId)

  const gktoUnitRelationship = businessPartnerRelationships?.unitRelationships.find(
    (unitRelationship) => unitRelationship.id === JOINT_ACCOUNT_CODE,
  )
  const gktoRelationships = gktoUnitRelationship?.relationships.find(
    (relationship) => relationship.head.id === businessPartnerId,
  )

  // gktoMembers are borrowers
  const borrowersData = compact(
    [
      {
        borrowerId: businessPartnerId,
      },
    ].concat(
      gktoRelationships?.members.map((member) => ({
        borrowerId: member.id,
      })),
    ),
  )

  const borrowerIds = borrowersData.map((borrower) => borrower.borrowerId)

  const {
    data: multipleFinanceReportsData = [],
    isLoading: isMultipleFinanceReportsLoading,
    isError: isMultipleFinanceReportsError,
  } = useCombinedQueryResults(useMultipleFinanceReports({ businessPartnerIds: borrowerIds }), {
    forceDataReturn: true,
  })

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isBusinessPartnerRelationshipsLoading, isMultipleFinanceReportsLoading],
    errorValues: [isBusinessPartnerRelationshipsError, isMultipleFinanceReportsError],
    errorDetails: [businessPartnerRelationshipsError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        sourceRender: { businessPartnerId },
        businessPartnerId,
        multipleFinanceReportsData,
      },
    }
  }, [isSomeValueLoading, isSomeValueError, businessPartnerId, multipleFinanceReportsData, error])
}

export default useTotalDebtBundesbankTile
