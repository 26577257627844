import { DateTime } from 'luxon'

/**
 *
 * check if entered date exists and is a valid date, and format of entered date is local pattern
 * @param {string} date
 * @param {string} localePattern
 * @returns {boolean}
 */

export const isInvalidLocalDate = (date, localePattern) => {
  if (!date) {
    return true
  }
  const parsedIsoDate = DateTime.fromISO(date).setLocale(localePattern)
  const parsedDateInLocalFormat = DateTime.fromFormat(date, localePattern)
  if (parsedIsoDate.isValid || parsedDateInLocalFormat.isValid) {
    return false
  }
  return true
}
