import { FlexBox, FlexBoxAlignItems, FlexBoxJustifyContent } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import styles from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonDeviation.module.css'
import { COMPARABLE_STATUS } from 'components/domains/properties/rent-roll/comparison/constants'
import Labeled from 'components/ui/data/Labeled'
import { getYearOrYears } from 'utils/YearUnitUtil'

export const YEAR_UNIT = 'year'
const PropertyRentRollComparisonDeviation = ({
  comparableStatus,
  label,
  primaryValue,
  comparisonValue,
  deviationFactor = 1,
  unit,
  formatter,
  showStandardValue = true,
  fractionDigit = 2,
}) => {
  const getUnit = useCallback(
    (value) => (unit === YEAR_UNIT ? getYearOrYears(value) : unit),
    [unit],
  )

  const SIGNS = {
    POSITIVE: '+',
    NEGATIVE: '-',
    NEUTRAL: '+/-',
  }

  const primary = primaryValue || 0
  const comparison = comparisonValue || 0

  const isZero = (value) => Math.sign(value) === 0

  const realDeviation = primary - comparison
  const fixedDeviation = (realDeviation * deviationFactor).toFixed(fractionDigit)

  const prefixSymbol = useCallback(() => {
    if (isZero(fixedDeviation)) {
      return SIGNS.NEUTRAL
    } else if (fixedDeviation > 0) {
      return SIGNS.POSITIVE
    } else if (fixedDeviation < 0) {
      return SIGNS.NEGATIVE
    }
  }, [SIGNS.NEGATIVE, SIGNS.NEUTRAL, SIGNS.POSITIVE, fixedDeviation])

  const deviationStyle = useCallback(() => {
    if (isZero(fixedDeviation)) {
      return styles.neutralDeviationText
    } else if (fixedDeviation > 0) {
      return styles.greenDeviationText
    } else if (fixedDeviation < 0) {
      return styles.redDeviationText
    }
  }, [fixedDeviation])

  const formatNumber = useCallback((value) => formatter(Math.abs(value)), [formatter])
  const renderDeviation = useCallback(() => {
    const renderPostfix = (value) => (value ? ` ${value}` : '')
    return (
      <span className={deviationStyle()}>
        {`${prefixSymbol()} ${formatNumber(realDeviation)}${renderPostfix(getUnit(realDeviation))}`}
      </span>
    )
  }, [deviationStyle, formatNumber, getUnit, prefixSymbol, realDeviation])
  const renderStandardValue = () => (
    <>
      {formatNumber(primary)}
      {unit && ' '}
      {unit && getUnit(primary)}
    </>
  )

  return (
    <FlexBox
      className={styles.propertyRentRollComparisonDeviation}
      justifyContent={FlexBoxJustifyContent.Start}
      alignItems={FlexBoxAlignItems.Start}
    >
      {showStandardValue && (
        <div className={styles.propertyRentRollComparisonDeviationLabel}>
          {label && (
            <Labeled label={label} showColon>
              {renderStandardValue()}
            </Labeled>
          )}
          {!label && renderStandardValue()}
        </div>
      )}
      {comparableStatus !== COMPARABLE_STATUS.NOT_COMPARABLE && <div>{renderDeviation()}</div>}
    </FlexBox>
  )
}
PropertyRentRollComparisonDeviation.propTypes = {
  comparableStatus: PropTypes.string.isRequired,
  label: PropTypes.string,
  primaryValue: PropTypes.number,
  comparisonValue: PropTypes.number,
  deviationFactor: PropTypes.number,
  unit: PropTypes.string,
  formatter: PropTypes.func.isRequired,
  showStandardValue: PropTypes.bool.isRequired,
  fractionDigit: PropTypes.number,
}
export default PropertyRentRollComparisonDeviation
