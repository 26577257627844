import { useMemo } from 'react'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useMultipleAssetValuationOverview from 'hooks/services/pbb-custom-service/useMultipleAssetValuationOverview'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const useAnnualReviewAssetValuationOverview = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { selectedDeal, dealsData, dealUuids = [] } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const { selectedDealDisplayId } = selectedDeal ?? {}

  const {
    data: assetValuationOverviewData = [],
    isLoading: isMultipleAssetValuationOverviewLoading,
    isError: isMultipleAssetValuationOverviewError,
    error: multipleAssetValuationOverviewError,
  } = useCombinedQueryResults(
    useMultipleAssetValuationOverview({
      dealUuids,
    }),
    { forceDataReturn: true },
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isAnnualReviewDealsLoading, isMultipleAssetValuationOverviewLoading],
    errorValues: [isAnnualReviewDealsError, isMultipleAssetValuationOverviewError],
    errorDetails: [annualReviewDealsError, multipleAssetValuationOverviewError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        assetValuationOverviewData,
        sourceRender: {
          businessPartnerId,
          dealDisplayId: selectedDealDisplayId,
        },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealsData,
    assetValuationOverviewData,
    businessPartnerId,
    selectedDealDisplayId,
    error,
  ])
}

export default useAnnualReviewAssetValuationOverview
