import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateDecisionPaperSubsectionComment = (mutationOptions) => {
  const { delete: deleteComment } = useAccessTokenRequest()

  return useMutation(async ({ eventId, version, subsectionId, commentId }) => {
    const { data } = await deleteComment({
      path: `/events/${eventId}/decision-paper/versions/${version}/subsections/${subsectionId}/comments/${commentId}`,
      keys: [
        'events',
        eventId,
        'decision-paper',
        'versions',
        version,
        'subsections',
        subsectionId,
        'comments',
        commentId,
      ],
    })
    return data
  }, mutationOptions)
}

export default useCreateDecisionPaperSubsectionComment
