import { LinkDesign, Link, MultiInput, Token, Icon, Label } from '@fioneer/ui5-webcomponents-react'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import documentPermissions from 'api/documents/DocumentPermissions'
import styles from 'components/domains/deals/DealDocumentSelection.module.css'
import DocumentDownload from 'components/domains/documents/DocumentDownload'
import DocumentSelectDialog from 'components/domains/documents/DocumentSelectDialog'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useDocumentsAllowedOperations from 'hooks/services/documents/useDocumentsAllowedOperations'
import useDocumentsFlat from 'hooks/services/documents/useDocumentsFlat'
import { getVersionFileName } from 'routes/documents/document-versions/DocumentVersionsTable'

const DealDocumentSelection = ({
  dealId,
  dealUuid,
  dealName,
  isReadOnly = false,
  filterForEntityRef = null,
  onSelectedDocumentsChanged = () => {}, // ({allDocIDsToBeLinked: [], allDocIDsToBeUnlinked: []})=>{...}
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.covenants' })
  const [isDocumentSelectionOpen, setIsDocumentSelectionOpen] = useState(false)

  //fetch all current deal documents
  const {
    data: { documents: allDealDocuments },
    isLoading: isDocumentsLoading,
    isError: isDocumentsError,
  } = useDocumentsFlat({
    entityId: dealUuid,
    entityType: DocumentTypes.Deal,
  })

  //fetch document permissions
  const {
    data: allowedDocumentOperationsData,
    isLoading: isLoadingAllowedDocumentOperations,
    isError: isAllowedOperationsDocumentError,
  } = useDocumentsAllowedOperations()
  const hasDocumentUpdatePermission =
    allowedDocumentOperationsData?.allowedOperations.includes(documentPermissions.Update) ?? false

  // filter current deal documents
  const filterDocuments = (documents, filter) =>
    documents.filter((doc) => {
      const entityRefs = doc['entityRefs'] ?? []
      const matchingEntityRefs = entityRefs.filter(
        (ref) => filter.type === ref.type && filter.id === ref.id,
      )
      return matchingEntityRefs.length > 0
    })
  const documentsCurrentlyLinked = useMemo(
    () =>
      filterForEntityRef ? filterDocuments(allDealDocuments, filterForEntityRef) : allDealDocuments,
    [allDealDocuments],
  )

  // Documents state for edit mode
  const [documentsEditedShouldBeLinked, setDocumentsEditedShouldBeLinked] =
    useState(documentsCurrentlyLinked)

  // Notify parent component about changed document selection
  const [lastParentNotification, setLastParentNotification] = useState(null)
  const notifyParentSelectedDocumentsChanged = () => {
    if (isReadOnly) return

    //calculate all ADD and DELETE operations
    const docIDsBeforeEdit = documentsCurrentlyLinked.map((d) => d.id)
    const docIDsAfterEdit = documentsEditedShouldBeLinked.map((d) => d.id)
    const docIDsToBeAdded = docIDsAfterEdit.filter(
      (docIdAfter) => !docIDsBeforeEdit.includes(docIdAfter),
    )
    const docIDsToBeRemoved = docIDsBeforeEdit.filter(
      (docIdBefore) => !docIDsAfterEdit.includes(docIdBefore),
    )

    //notify parent component
    const nextParentNotification = {
      allDocIDsToBeLinked: docIDsToBeAdded,
      allDocIDsToBeUnlinked: docIDsToBeRemoved,
    }
    if (!isEqual(nextParentNotification, lastParentNotification)) {
      //break infinite render loop
      setLastParentNotification(nextParentNotification)
      onSelectedDocumentsChanged(nextParentNotification)
    }
  }
  useEffect(notifyParentSelectedDocumentsChanged, [
    documentsCurrentlyLinked,
    documentsEditedShouldBeLinked,
    isReadOnly,
  ])

  //handle token-x: document is removed through click on token-x
  const onEditRemoveDocument = (deleteEvent) => {
    const deleteDocumentId = deleteEvent.detail.token.dataset.documentId
    setDocumentsEditedShouldBeLinked(
      [...documentsEditedShouldBeLinked].filter((doc) => doc.id !== deleteDocumentId),
    )
  }

  //handle value helper popup: new document was added
  const handleValueHelperDocumentWasAdded = async (documentId) => {
    const newlySelectedDoc = allDealDocuments.filter((doc) => doc.id === documentId)
    if (newlySelectedDoc?.length > 0) {
      const newDoc = { ...newlySelectedDoc[0] }
      const newDocumentsEditedShouldBeLinked = [...documentsEditedShouldBeLinked]
      newDocumentsEditedShouldBeLinked.push(newDoc)
      setDocumentsEditedShouldBeLinked(newDocumentsEditedShouldBeLinked)
    }
    setIsDocumentSelectionOpen(false)
  }

  const renderDocumentFieldReadOnly = () => {
    const documentLinks =
      documentsCurrentlyLinked?.map((doc) => (
        <React.Fragment key={doc.id}>
          <div className={styles.covenantDocumentLinkName}>
            <Link
              design={LinkDesign.Default}
              data-testid="openDocumentLink"
              target="_blank"
              href={`/documents/${doc.id}`}
            >
              {doc.name}
            </Link>
          </div>
          <div>
            <DocumentDownload
              documentId={doc.id}
              type="icon"
              fileName={getVersionFileName(
                doc.name,
                doc.versions?.find((version) => version.isWorkVersion).versionExt,
              )}
              documentVersionGuid={doc.versions?.find((version) => version.isWorkVersion).guid}
            />
          </div>
        </React.Fragment>
      )) ?? []

    return (
      <div>
        {documentsCurrentlyLinked?.length > 0 ? (
          <div className={styles.covenantDocumentLinkWrapper}>{documentLinks}</div>
        ) : (
          <span>{t('empty-value')}</span>
        )}
      </div>
    )
  }

  const renderDocumentFieldEdit = () => (
    <>
      <MultiInput
        maxlength={0}
        onTokenDelete={onEditRemoveDocument}
        className={styles.covenantDocumentMultiInput}
        tokens={
          <>
            {documentsEditedShouldBeLinked.map((doc) => (
              <Token key={doc.id} text={`${doc.name}`} data-document-id={doc.id} />
            ))}
          </>
        }
        icon={
          <Icon
            name="value-help"
            interactive
            onClick={() => {
              setIsDocumentSelectionOpen(true)
            }}
          />
        }
      />
      <DocumentSelectDialog
        open={isDocumentSelectionOpen}
        onClose={() => {
          setIsDocumentSelectionOpen(false)
        }}
        onChange={handleValueHelperDocumentWasAdded}
        entity={{
          id: dealUuid,
          displayId: dealId,
          name: dealName,
          type: DocumentTypes.Deal,
        }}
      />
    </>
  )

  return (
    <SmallLoadingWrapper
      isLoading={isDocumentsLoading || isLoadingAllowedDocumentOperations}
      isError={isDocumentsError || isAllowedOperationsDocumentError}
      error={<Label className={styles.errorLabel}>{t('data-error')}</Label>}
      renderContent={() => {
        if (isReadOnly || !hasDocumentUpdatePermission) {
          return renderDocumentFieldReadOnly()
        } else {
          return renderDocumentFieldEdit()
        }
      }}
    />
  )
}

DealDocumentSelection.propTypes = {
  dealId: PropTypes.string.isRequired,
  dealUuid: PropTypes.string.isRequired,
  dealName: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
  filterForEntityRef: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(DocumentTypes)),
    id: PropTypes.string,
  }),
  handleChangedDocument: PropTypes.func,
  onSelectedDocumentsChanged: PropTypes.func, // ({allDocIDsToBeLinked: [], allDocIDsToBeUnlinked: []})=>{...}
}

export default DealDocumentSelection
