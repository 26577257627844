import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const usePublishTaskComment = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ eventId, taskId, comment }) => {
      const { data } = await post({
        path: `/events/${eventId}/tasks/${taskId}/comments`,
        body: {
          comment: comment,
        },
      })
      return data
    },
    { onSuccess, onError },
  )
}
