import PropTypes from 'prop-types'
import { useContext } from 'react'
import ConfigurableComponentsPage from 'components/ui/configurable-components-page/ConfigurableComponentsPage'
import TabPage from 'components/ui/page/TabPage'
import { availableDynamicPagesEnum } from 'hooks/page-config/useGetPageConfig'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyRentRollDynamic = ({ header }) => {
  const propertyContext = useContext(PropertyContext)

  return (
    <>
      <ConfigurableComponentsPage
        pageData={propertyContext}
        pageCode={availableDynamicPagesEnum.PropertiesRentRoll}
        renderContent={({ children, pageConfig }) => (
          <TabPage headerContent={header} defaultSectionId={pageConfig.defaultTab}>
            {children}
          </TabPage>
        )}
      />
    </>
  )
}
PropertyRentRollDynamic.propTypes = {
  header: PropTypes.node.isRequired,
}
export default PropertyRentRollDynamic
