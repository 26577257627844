import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import '@ui5/webcomponents-icons/dist/AllIcons.js'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import usePropertyBulkDeleteValuation from 'hooks/services/properties/valuations/usePropertyBulkDeleteValuation'

const ValuationRequestDeleteMultipleButton = ({ valuationRequestUuids, ...props }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.valuation.requests.table.multiple-delete',
  })
  const { t: tNoPrefix } = useTranslation()
  const { mutate: deleteValuationRequests } = usePropertyBulkDeleteValuation()
  const queryClient = useQueryClient()
  const showMessageBox = useShowMessageBox()

  const onDeleteSuccess = useCallback(() => {
    queryClient.invalidateQueries(['properties', 'valuation-requests'])
  }, [queryClient])

  const onDeleteError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('confirmation-message.error'),
    })
  }, [showMessageBox, t])

  const handleOnDeleteClick = useCallback(() => {
    deleteValuationRequests(
      { requestUuids: valuationRequestUuids },
      { onSuccess: onDeleteSuccess, onError: onDeleteError },
    )
  }, [deleteValuationRequests, onDeleteError, onDeleteSuccess, valuationRequestUuids])

  const handleOnButtonClick = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Warning,
      titleText: t('confirmation-message.title'),
      children: t('confirmation-message.description', {
        numberOfValuationRequests: valuationRequestUuids.length,
      }),
      actions: [
        <Button
          key="delete-multiple-confirm-button"
          data-action={MessageBoxActions.Yes}
          design={ButtonDesign.Emphasized}
          onClick={handleOnDeleteClick}
        >
          {tNoPrefix('buttons.delete')}
        </Button>,
        MessageBoxActions.Cancel,
      ],
    })
  }, [handleOnDeleteClick, showMessageBox, t, tNoPrefix, valuationRequestUuids.length])

  return (
    <Button {...props} design={ButtonDesign.Transparent} onClick={handleOnButtonClick}>
      {tNoPrefix('buttons.delete')}
    </Button>
  )
}

ValuationRequestDeleteMultipleButton.propTypes = {
  valuationRequestUuids: PropTypes.arrayOf(PropTypes.string),
  propertyUuids: PropTypes.arrayOf(PropTypes.string),
}

export default ValuationRequestDeleteMultipleButton
