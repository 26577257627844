import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import CreateWorkingVersionDialog from 'components/domains/deals/deal-adjustment/create/CreateWorkingVersionDialog'
import { DealContext } from 'routes/deals/DealContext'

/**
 * Secondary button to create a working version of a deal
 * @returns {JSX.Element | false} Create Deal Event Action or false
 */
const CreateDealWorkingVersionButton = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.deal-adjustment.modal.create',
  })

  const dialogRef = useRef()

  const {
    deal: { dealUuid },
  } = useContext(DealContext)

  return useMemo(
    () =>
      !!dealUuid && (
        <>
          <Button
            id="create-working-version"
            design={ButtonDesign.Default}
            onClick={() => {
              dialogRef.current?.show?.()
            }}
          >
            {t('create-button-text')}
          </Button>
          <CreateWorkingVersionDialog dealUuid={dealUuid} ref={dialogRef} />
        </>
      ),
    [dealUuid, dialogRef, t],
  )
}

export default CreateDealWorkingVersionButton
