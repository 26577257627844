import { IllustratedMessage } from '@fioneer/ui5-webcomponents-react'
import camelize from 'camelize'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { propertyAllowedOperations } from 'api/property/propertyAllowedOperations'
import styles from 'components/domains/properties/valuation/PropertyValuationsRequests.module.css'
import {
  areFiltersApplied,
  filterKeys,
  getRequestParametersForValuationRequests,
} from 'components/domains/properties/valuation/ValuationRequestsUtils'
import ValuationRequestsTable, {
  allColumns,
} from 'components/domains/properties/valuation/table/ValuationRequestsTable'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import usePropertyValuationReasons from 'hooks/services/properties/valuations/usePropertyValuationReasons'
import usePropertyValuationRequests from 'hooks/services/properties/valuations/usePropertyValuationRequests'
import { PropertyContext } from 'routes/properties/PropertyContext'

const valuationRequestsTableViewColumns = [
  allColumns.report,
  allColumns.reason,
  allColumns.type,
  allColumns.businessEvent,
  allColumns.valuer,
  allColumns.dueDate,
  allColumns.status,
  allColumns.note,
  allColumns.reportDate,
  allColumns.editRow,
]

const valuationRequestsTableEditColumns = [
  allColumns.report,
  allColumns.reason,
  allColumns.type,
  allColumns.businessEvent,
  allColumns.valuer,
  allColumns.dueDate,
  allColumns.status,
  allColumns.note,
  allColumns.reportDate,
  allColumns.editRow,
]

const ascending = 'asc'

const PropertyValuationsRequests = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property.valuation.requests' })
  const { property } = useContext(PropertyContext)
  const camelizedProperty = useMemo(() => camelize(property), [property])
  const allowedOperations = camelizedProperty?.allowedOperations?.allowedOperations

  const [sorting, setSorting] = useState({ sortBy: filterKeys.status, orderBy: ascending })
  const [filters, setFilters] = useState([])
  const [searchParam, setSearchParam] = useState('')

  const {
    isLoading: isReasonsLoading,
    isError: isReasonsError,
    data: valuationReasonsData,
  } = usePropertyValuationReasons()

  const propertyUuid = camelizedProperty?.uuid
  const propertyDisplayId = camelizedProperty?.id
  const propertyName = camelizedProperty?.description
  const propertyAddress = camelizedProperty?.address

  const requestParameters = useMemo(
    () => getRequestParametersForValuationRequests(filters, searchParam),
    [filters, searchParam],
  )

  const requestSorting = useMemo(
    () => ({
      sortBy: sorting.sortBy === filterKeys.status ? filterKeys.infoStatus : sorting.sortBy,
      orderBy: sorting.orderBy,
    }),
    [sorting],
  )

  const {
    data: valuationData,
    isLoading: isRequestsLoading,
    isError: isRequestsError,
    fetchNextPage,
  } = usePropertyValuationRequests(requestSorting, requestParameters, {
    propertyId: propertyUuid,
  })

  const noDataText = useMemo(() => {
    if (areFiltersApplied(filters) || searchParam) return t('table.no-data-with-filters')
    return t('table.no-data')
  }, [filters, searchParam, t])

  const renderContent = useCallback(() => {
    if (!allowedOperations?.includes(propertyAllowedOperations.propertyValuationRead)) {
      return (
        <IllustratedMessage
          size="Spot"
          name="UnableToLoad"
          titleText={t('permission-error.title')}
          subtitleText={t('permission-error.subtitle')}
        />
      )
    }

    const valuationRequestsTableData = valuationData.valuationRequests.map((valuationRequest) => ({
      ...valuationRequest,
      property: {
        description: propertyName,
        id: propertyDisplayId,
        uuid: propertyUuid,
        address: propertyAddress,
        allowedOperations: { allowedOperations },
      },
    }))

    const properties = [
      { propertyUuid, propertyName, propertyDisplayId, propertyAddress, allowedOperations },
    ]
    return (
      <div className={styles.card}>
        <ValuationRequestsTable
          valuationRequests={valuationRequestsTableData}
          sortBy={sorting.sortBy}
          orderBy={sorting.orderBy}
          filters={filters}
          onSortingChanged={setSorting}
          setFilters={setFilters}
          searchParam={searchParam}
          setSearchParam={setSearchParam}
          valuationReasons={valuationReasonsData}
          viewColumns={valuationRequestsTableViewColumns}
          editColumns={valuationRequestsTableEditColumns}
          noDataText={noDataText}
          properties={properties}
          onLoadMore={fetchNextPage}
          totalValuationRequestAmount={valuationData.size ?? 0}
        />
      </div>
    )
  }, [
    allowedOperations,
    sorting,
    filters,
    searchParam,
    propertyAddress,
    propertyDisplayId,
    propertyName,
    propertyUuid,
    valuationData?.valuationRequests,
    valuationData?.size,
    valuationReasonsData,
    fetchNextPage,
    noDataText,
    t,
  ])

  // can not return the Card here because valuationData.size is not available until loaded, using a state here also doesn't work
  return (
    <Card>
      <RequestStateResolver
        isLoading={isRequestsLoading || isReasonsLoading}
        isError={isRequestsError || isReasonsError}
        renderContent={renderContent}
        center
        errorToDisplay={<ErrorDataUnavailableInContent />}
      />
    </Card>
  )
}

export default PropertyValuationsRequests
