import { useMutation } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const formatDateInHeader = ({ key_date, ...header }, formatDate) => ({
  ...header,
  key_date: key_date ? formatDate(key_date) : null,
})

const formatDateInRentalUnits = (rentalUnits, formatDate) =>
  rentalUnits.map(
    ({
      lease_break_date,
      lease_expiry_date,
      lease_start_date,
      rent_start_date,
      ...rental_unit
    }) => ({
      // use expiry date as break date, when it is not set
      lease_break_date: lease_break_date
        ? formatDate(lease_break_date)
        : formatDate(lease_expiry_date),
      lease_expiry_date: lease_expiry_date ? formatDate(lease_expiry_date) : null,
      lease_start_date: lease_start_date ? formatDate(lease_start_date) : null,
      rent_start_date: rent_start_date ? formatDate(rent_start_date) : null,
      ...rental_unit,
    }),
  )

const dateInIsoFormat = (dateStr) => DateTime.fromISO(dateStr).isValid

export const useCreateRentRoll = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  const { parse, localePattern } = useShortDateFormatter()

  const formatDateToIso = (dateStr) =>
    dateInIsoFormat(dateStr) ? dateStr : parse(dateStr, localePattern)

  return useMutation(
    async (rentRollWorkingVersion) => {
      const { propertyUuids, uuid, header, rentalUnits } = rentRollWorkingVersion
      const formattedHeader = formatDateInHeader(header, formatDateToIso)
      const formattedRentalUnits = formatDateInRentalUnits(rentalUnits, formatDateToIso)

      const { data } = await post({
        path: `/properties/rent-roll`,
        body: {
          uuid: uuid,
          property_uuids: propertyUuids,
          header: formattedHeader,
          rental_units: formattedRentalUnits,
        },
      })
      return data
    },
    { onSuccess, onError },
  )
}

export default useCreateRentRoll
