import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { calculateTenancyTotalContractedRentPerSegmentUsageType } from 'hooks/services/business-partners/tenancy/calculateTenancyTotalContractedRent'
import { calculateTenancyTotalRentedAreaPerSegmentUsageType } from 'hooks/services/business-partners/tenancy/calculateTenancyTotalRentedArea'
import { calculateTenancyTotalRentedAreaPiecesPerSegmentUsageType } from 'hooks/services/business-partners/tenancy/calculateTenancyTotalRentedAreaPieces'
import {
  calculateTenancyWaultPerSegmentUsageType,
  calculateTenancyWault,
} from 'hooks/services/business-partners/tenancy/calculateTenancyWault'
import { tenancyHeaderInformationValueType } from 'hooks/services/business-partners/tenancy/tenancyHeaderInformationValueType'
import { useUtilizationCodes } from 'hooks/services/properties/useUtilizationCodes'

const vacantType = 'vacant'
const selfType = 'self'

const getSum = (arr) => arr?.map((item) => item.value).reduce((partialSum, a) => partialSum + a, 0)

export const useTenantBottomHeaderInformation = (
  rentalUnitsByFinancingStatusActive,
  rentalUnitsByFinancingStatusPipeline,
) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.tenancy.as-tenant',
  })

  /* total contracted rent */
  const totalContractedRentActive = calculateTenancyTotalContractedRentPerSegmentUsageType(
    rentalUnitsByFinancingStatusActive,
  )
  const totalContractedRentPipeline = calculateTenancyTotalContractedRentPerSegmentUsageType(
    rentalUnitsByFinancingStatusPipeline,
  )

  /* total rented area */
  const totalRentedAreaActive = calculateTenancyTotalRentedAreaPerSegmentUsageType(
    rentalUnitsByFinancingStatusActive,
  )
  const totalRentedAreaPipeline = calculateTenancyTotalRentedAreaPerSegmentUsageType(
    rentalUnitsByFinancingStatusPipeline,
  )

  /* total rented area (pieces)*/
  const totalRentedAreaPiecesActive = calculateTenancyTotalRentedAreaPiecesPerSegmentUsageType(
    rentalUnitsByFinancingStatusActive,
  )
  const totalRentedAreaPiecesPipeline = calculateTenancyTotalRentedAreaPiecesPerSegmentUsageType(
    rentalUnitsByFinancingStatusPipeline,
  )

  /* WAULT */

  const utilizationCodes = useUtilizationCodes()

  const vacantUtilizationKeys = utilizationCodes?.data?.utilization_codes
    ?.filter((element) => element.type === vacantType)
    ?.map((item) => item.key)
  const selfUtilizationKeys = utilizationCodes?.data?.utilization_codes
    ?.filter((element) => element.type === selfType)
    ?.map((item) => item.key)

  const waultActiveBySegmentUsageType = calculateTenancyWaultPerSegmentUsageType(
    rentalUnitsByFinancingStatusActive,
    vacantUtilizationKeys,
    selfUtilizationKeys,
  )

  const waultActive = calculateTenancyWault(
    rentalUnitsByFinancingStatusActive,
    rentalUnitsByFinancingStatusActive,
    vacantUtilizationKeys,
    selfUtilizationKeys,
  )

  const waultPipelineBySegmentUsageType = calculateTenancyWaultPerSegmentUsageType(
    rentalUnitsByFinancingStatusPipeline,
    vacantUtilizationKeys,
    selfUtilizationKeys,
  )

  const waultPipeline = calculateTenancyWault(
    rentalUnitsByFinancingStatusPipeline,
    rentalUnitsByFinancingStatusPipeline,
    vacantUtilizationKeys,
    selfUtilizationKeys,
  )

  return useMemo(
    () => [
      {
        caption: {
          kpiName: t('total-contracted-rent'),
        },
        activeDeal: {
          kpiValue: getSum(totalContractedRentActive),
          kpiValueType: tenancyHeaderInformationValueType.currency,
          segmentUsageTypes: totalContractedRentActive,
        },
        pipelineDeal: {
          kpiValue: getSum(totalContractedRentPipeline),
          kpiValueType: tenancyHeaderInformationValueType.currency,
          segmentUsageTypes: totalContractedRentPipeline,
        },
      },
      {
        caption: {
          kpiName: t('total-rental-pieces'),
        },
        activeDeal: {
          kpiValue: getSum(totalRentedAreaPiecesActive),
          kpiValueType: tenancyHeaderInformationValueType.pieces,
          segmentUsageTypes: totalRentedAreaPiecesActive,
        },
        pipelineDeal: {
          kpiValue: getSum(totalRentedAreaPiecesPipeline),
          kpiValueType: tenancyHeaderInformationValueType.pieces,
          segmentUsageTypes: totalRentedAreaPiecesPipeline,
        },
      },
      {
        caption: {
          kpiName: t('total-rented-area'),
        },
        activeDeal: {
          kpiValue: getSum(totalRentedAreaActive),
          kpiValueType: tenancyHeaderInformationValueType.area,
          segmentUsageTypes: totalRentedAreaActive,
        },
        pipelineDeal: {
          kpiValue: getSum(totalRentedAreaPipeline),
          kpiValueType: tenancyHeaderInformationValueType.area,
          segmentUsageTypes: totalRentedAreaPipeline,
        },
      },
      {
        caption: {
          kpiName: t('wault'),
        },
        activeDeal: {
          kpiValue: waultActive.value,
          kpiValueType: tenancyHeaderInformationValueType.years,
          segmentUsageTypes: waultActiveBySegmentUsageType,
        },
        pipelineDeal: {
          kpiValue: waultPipeline.value,
          kpiValueType: tenancyHeaderInformationValueType.years,
          segmentUsageTypes: waultPipelineBySegmentUsageType,
        },
      },
    ],
    [
      t,
      totalContractedRentActive,
      totalContractedRentPipeline,
      totalRentedAreaActive,
      totalRentedAreaPiecesActive,
      totalRentedAreaPiecesPipeline,
      totalRentedAreaPipeline,
      waultActive,
      waultActiveBySegmentUsageType,
      waultPipeline,
      waultPipelineBySegmentUsageType,
    ],
  )
}
