import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CashFlowScenarioNameTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/CashFlowScenarioNameTile'
import ErrorScreen from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/DealCashFlowErrorScreen'
import { DealCashFlowVacancyCard } from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/DealCashFlowVacancyCard'

const CashFlowVacancyProjectionTile = ({ tileId, isPdfView }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.cash-flow',
  })

  const {
    data: { periodYearsCalculatedKpis, dealId, tileMetaData, cashflowScenarioPeriodNotFound },
    isLoading,
    isError,
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  if (cashflowScenarioPeriodNotFound) {
    return (
      <ErrorScreen title={t('loading.error.title')} description={t('loading.error.description')} />
    )
  }

  return (
    <>
      <CashFlowScenarioNameTile
        metaData={tileMetaData?.metaData}
        dealId={dealId}
        showMessageStrip={false}
        isPdfView={isPdfView}
      />
      <DealCashFlowVacancyCard
        calculatedKpis={periodYearsCalculatedKpis}
        isCard={false}
        metaData={tileMetaData?.metaData}
        showTitle={false}
        isLoading={isLoading}
        isError={isError}
      />
    </>
  )
}

CashFlowVacancyProjectionTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealId: PropTypes.string,
}

export default CashFlowVacancyProjectionTile
