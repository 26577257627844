import { useTranslation } from 'react-i18next'
import {
  DATA_TYPES,
  isEmptyOrNil,
} from 'components/domains/properties/multi-property-upload/MultiPropertyUpload'
import useMultiPropertyUploadColumns from 'hooks/services/properties/multi-property-upload/useMultiPropertyUploadColumns'

const ERROR_TYPES = {
  UNEQUAL_HEADER_LENGTHS: 'validation.unequal-header-lengths',
  MISSING_MANDATORY_COLUMN: 'validation.missing-mandatory-column',
  MISSING_MANDATORY_DATA: 'validation.missing-mandatory-data',
  INVALID_ENUMERATION: 'validation.invalid-enumeration',
  INVALID_NUMERIC: 'validation.invalid-numeric',
  INVALID_DATE_YEAR: 'validation.invalid-date-year',
}

/**
 * React hook that validates the uploaded Excel file with the properties for the following things:
 * - Mandatory columns
 * - Mandatory data filled
 * - Valid numbers
 * - Valid date-years
 * - Valid enumeration ids
 * It ignores unsupported columns and data which is not given in the `columnsDefinition` provided by the `useMultiPropertyUploadColumns` hook.
 *
 * @returns {{
 *   isLoading: boolean,
 *   isError: boolean,
 *   validateData: (data: Array<Array<any>>) => Array<{
 *     type: string,
 *     data: {
 *       column: string,
 *       row: string,
 *       header: string,
 *       value: any,
 *     },
 *   }>
 * }}
 * An object with the following properties:
 * - `isLoading`: A boolean indicating whether the columns are loaded
 * - `isError`: A boolean indicating whether an error occurred during the columnDefinitions hook
 * - `validateData`: A function that takes an array of arrays representing the Excel data and returns an array of objects representing the validation errors. Each object has the following properties:
 *   - `type`: A string representing the type of the validation error.
 *   - `data`: An object containing the details of the validation error. The keys of this object are:
 *     - `column`: The index of the column where the validation error occurred in the excel file.
 *     - `row`: The row index where the validation error occurred in the excel file.
 *     - `header`: The header string of the column where the validation error occurred.
 *     - `value`: The value that caused the validation error.
 * }}
 */
const useMultiPropertyUploadValidation = () => {
  const DEFAULT_ERROR_VALUE = '-'

  const { t: tMultiPropertyUpload } = useTranslation('translation', {
    keyPrefix: 'pages.property-upload',
  })

  const { isLoading, isError, data: columnDefinitions } = useMultiPropertyUploadColumns()

  const getColumnDefinitionDisplayName = (columnDefinition) =>
    `${columnDefinition.de}/${columnDefinition.en}`

  const getUnsupportedColumnIndexes = (columnDisplayNamesFromExcel) => {
    const unsupportedColumnIndexes = []
    columnDisplayNamesFromExcel.forEach((column, index) => {
      if (
        !columnDefinitions.find(
          (columnDefinition) => getColumnDefinitionDisplayName(columnDefinition) === column,
        )
      ) {
        unsupportedColumnIndexes.push(index)
      }
    })
    return unsupportedColumnIndexes
  }

  const getExcelLetterByColumnIndex = (columnIndex) => {
    const moduloAndDivisor = 26
    const remainderAddition = 65
    let letter = ''
    while (columnIndex > 0) {
      const remainder = (columnIndex - 1) % moduloAndDivisor
      letter = String.fromCharCode(remainderAddition + remainder) + letter
      columnIndex = Math.floor((columnIndex - remainder) / moduloAndDivisor)
    }
    return letter
  }

  const isValidYear = (year) => /^\d{4}$/.test(year)

  const checkMissingMandatoryColumns = (errorsPassedByReference, columnDisplayNamesFromExcel) => {
    const missingColumns = []
    columnDefinitions.forEach((columnDefinition) => {
      const columnDefinitionDisplayName = getColumnDefinitionDisplayName(columnDefinition)
      if (
        columnDefinition.mandatory &&
        !columnDisplayNamesFromExcel.includes(columnDefinitionDisplayName)
      ) {
        missingColumns.push(columnDefinitionDisplayName)
      }
    })
    if (missingColumns.length) {
      missingColumns.forEach((missingColumn) => {
        errorsPassedByReference.push({
          type: ERROR_TYPES.MISSING_MANDATORY_COLUMN,
          data: {
            column: DEFAULT_ERROR_VALUE,
            row: 1,
            header: missingColumn,
            value: DEFAULT_ERROR_VALUE,
          },
        })
      })
    }
  }

  const checkMissingMandatoryData = (
    errorsPassedByReference,
    columnDefinition,
    { value, rowIndex, columnIndex, columnHeaderString },
  ) => {
    if (columnDefinition.mandatory && isEmptyOrNil(value)) {
      errorsPassedByReference.push({
        type: ERROR_TYPES.MISSING_MANDATORY_DATA,
        data: {
          column: getExcelLetterByColumnIndex(columnIndex),
          row: rowIndex,
          header: columnHeaderString,
          value: DEFAULT_ERROR_VALUE,
        },
      })
    }
  }

  const checkInvalidEnumerationValue = (
    errorsPassedByReference,
    columnDefinition,
    { value, rowIndex, columnIndex, columnHeaderString },
  ) => {
    if (
      !isEmptyOrNil(value) &&
      columnDefinition.enumeration &&
      !columnDefinition.enumeration.map((enumObj) => enumObj.key).includes(String(value))
    ) {
      errorsPassedByReference.push({
        type: ERROR_TYPES.INVALID_ENUMERATION,
        data: {
          column: getExcelLetterByColumnIndex(columnIndex),
          row: rowIndex,
          header: columnHeaderString,
          value: value,
        },
      })
    }
  }

  const checkInvalidNumericValue = (
    errorsPassedByReference,
    columnDefinition,
    { value, rowIndex, columnIndex, columnHeaderString },
  ) => {
    if (columnDefinition.type === DATA_TYPES.NUMERIC && !isEmptyOrNil(value) && isNaN(value)) {
      errorsPassedByReference.push({
        type: ERROR_TYPES.INVALID_NUMERIC,
        data: {
          column: getExcelLetterByColumnIndex(columnIndex),
          row: rowIndex,
          header: columnHeaderString,
          value: value,
        },
      })
      return true
    }
    return false
  }

  const checkInvalidDateYearValue = (
    errorsPassedByReference,
    columnDefinition,
    { value, rowIndex, columnIndex, columnHeaderString },
  ) => {
    if (
      columnDefinition.type === DATA_TYPES.DATE_YEAR &&
      !isEmptyOrNil(value) &&
      !isValidYear(value)
    ) {
      errorsPassedByReference.push({
        type: ERROR_TYPES.INVALID_DATE_YEAR,
        data: {
          column: getExcelLetterByColumnIndex(columnIndex),
          row: rowIndex,
          header: columnHeaderString,
          value: value,
        },
      })
    }
  }

  const checkColumnHeaderRowLength = (
    errorsPassedByReference,
    germanColumnHeaderRow,
    englishColumnHeaderRow,
  ) => {
    if (germanColumnHeaderRow.length !== englishColumnHeaderRow.length) {
      errorsPassedByReference.push({
        type: ERROR_TYPES.UNEQUAL_HEADER_LENGTHS,
        data: {
          column: DEFAULT_ERROR_VALUE,
          row: tMultiPropertyUpload('x-and-y', {
            x: 1,
            y: 2,
          }),
          header: DEFAULT_ERROR_VALUE,
          value: DEFAULT_ERROR_VALUE,
        },
      })
    }
  }

  return {
    isLoading,
    isError,
    validateData: (data) => {
      const errorsPassedByReference = []
      const germanColumnHeaderRow = Object.values(data[0])
      const englishColumnHeaderRow = Object.values(data[1])
      checkColumnHeaderRowLength(
        errorsPassedByReference,
        germanColumnHeaderRow,
        englishColumnHeaderRow,
      )
      if (errorsPassedByReference.length > 0) {
        return errorsPassedByReference
      }
      const germanAndEnglishColumnHeaderRow = germanColumnHeaderRow.map(
        (germanColumndHeaderRow, index) =>
          `${germanColumndHeaderRow}/${englishColumnHeaderRow[index]}`,
      )
      checkMissingMandatoryColumns(errorsPassedByReference, germanAndEnglishColumnHeaderRow)
      const unsupportedColumnIndexes = getUnsupportedColumnIndexes(germanAndEnglishColumnHeaderRow)
      const dataWithoutHeader = data.slice(2)
      for (let rowIndex = 0; rowIndex < dataWithoutHeader.length; rowIndex++) {
        const row = dataWithoutHeader[rowIndex]
        for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
          if (unsupportedColumnIndexes.includes(columnIndex)) {
            continue
          }
          const excelColumnHeaderString = germanAndEnglishColumnHeaderRow[columnIndex]
          const excelRowIndexAddition = 3
          const excelColumnIndexRowAddition = 1
          const excelCellData = {
            value: row[columnIndex],
            rowIndex: rowIndex + excelRowIndexAddition,
            columnIndex: columnIndex + excelColumnIndexRowAddition,
            columnHeaderString: germanAndEnglishColumnHeaderRow[columnIndex],
          }
          const columnDefinition = columnDefinitions.find(
            (column) => getColumnDefinitionDisplayName(column) === excelColumnHeaderString,
          )
          checkMissingMandatoryData(errorsPassedByReference, columnDefinition, excelCellData)
          checkInvalidEnumerationValue(errorsPassedByReference, columnDefinition, excelCellData)
          checkInvalidNumericValue(errorsPassedByReference, columnDefinition, excelCellData)
          checkInvalidDateYearValue(errorsPassedByReference, columnDefinition, excelCellData)
        }
      }
      return errorsPassedByReference
    },
  }
}

export default useMultiPropertyUploadValidation
