import { MessageBox, MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import SelectDecisionPaperTemplateDialog, {
  okButtonTypes,
} from 'components/domains/business-events-and-tasks/decision-paper/dialogs/SelectDecisionPaperTemplateDialog'
import useCreateDecisionPaperVersion from 'hooks/services/business-events-and-tasks/decision-papers/useCreateDecisionPaperVersion'
import { resetTiles } from 'redux/slices/decision-paper/tilesOverviewSlice'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const EditDecisionPaperDialog = ({
  isOpen,
  setIsOpen,
  templateCode,
  existingDecisionPaperTemplate,
}) => {
  const event = useContext(BusinessEventsAndTasksContext).event
  const { id: eventId } = event
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.edit-dialog',
  })
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const queryClient = useQueryClient()
  const createDecisionPaperVersionMutation = useCreateDecisionPaperVersion()
  const showToast = Modals.useShowToast()
  const dispatch = useDispatch()

  const handleOnCreate = (
    selectedDecisionPaperTemplateCode,
    selectedDecisionPaper,
    customizedDecisionPaperTemplateStructure = null,
  ) => {
    createDecisionPaperVersionMutation.mutate(
      {
        eventId,
        templateCode: selectedDecisionPaperTemplateCode,
        eventIdToImportDataFrom: selectedDecisionPaper,
        templateStructure: customizedDecisionPaperTemplateStructure,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['events', eventId])
          setIsOpen(false)
          showToast({ children: t('success') })
          dispatch(resetTiles())
        },
        onError: () => {
          setIsErrorDialogOpen(true)
        },
      },
    )
  }

  const handleOnCancel = () => {
    setIsOpen(false)
  }

  const handleOnCloseErrorDialog = () => {
    setIsErrorDialogOpen(false)
  }

  return (
    <>
      <MessageBox
        open={isErrorDialogOpen}
        onClose={handleOnCloseErrorDialog}
        type={MessageBoxTypes.Error}
      >
        {t('error')}
      </MessageBox>
      {isOpen && (
        <SelectDecisionPaperTemplateDialog
          title={t('title')}
          isOpen={isOpen}
          templateCode={templateCode}
          okButtonType={okButtonTypes.edit}
          onOk={handleOnCreate}
          onCancel={handleOnCancel}
          existingDecisionPaperTemplate={existingDecisionPaperTemplate}
        />
      )}
    </>
  )
}

EditDecisionPaperDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  templateCode: PropTypes.string.isRequired,
  existingDecisionPaperTemplate: PropTypes.shape({
    code: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        subsections: PropTypes.arrayOf(
          PropTypes.shape({
            code: PropTypes.string.isRequired,
            tiles: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string.isRequired })),
          }),
        ),
        tiles: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string.isRequired })),
      }),
    ),
  }),
}

export default EditDecisionPaperDialog
