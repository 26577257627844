import { cloneDeep, isEqual } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setResetPropertiesPagination } from 'redux/slices/properties/resetPropertiesPaginationSlice'

const DEFAULT_PAGINATION_DELTA = 50
/**
 * Enables "load more" pagination on another existing underlying request hook. The requestHookArgs are passed through to the underlying hook
 * and typically contain filter and sort order information.
 * The underlying hook provides data chunk by chunk. The useInfiniteLoading Hook aggregates this data and provides the data loaded so far
 * to the calls. Additionally it provides pagination information stating offset, limit and total.
 * The concrete property names for the list with data and the pagination information can be configured via dataListElement, paginationKey,
 * offsetKey, limitKey and totalKey.
 * When the filter options or sort order changes, useInfiniteLoading resets the pagination and starts from scratch. The hook resets
 * the pagination, whenever the requestHookArgs passed to the underlying hook change.
 * To initiate loading the next chunk, call the function loadMore(), e.g. from an event handler.
 *
 * @deprecated Please use useInfiniteRequest from baseService!
 * @param requestHook The underlying request Hook: It must accept a pagination object (with offset and limit), that indicates which chunk to load. It must return an object containing a pagination object (with offset, limit and total)
 * @param requestHookArgs: Arguments passed to the underlying request hook. Typically filter and sort order information.
 * @param dataListElement: Name of the array element in the request Hook response object that contains the returned list items
 * @param paginationDelta: How many more elements should be requested when loading the next chunk
 * @param paginationKey: Name of the pagination object in the underlying hook request as well as the hook response
 * @param offsetKey: Name of the offset attribute in the pagination object in the underlying hook request as well as the hook response
 * @param limitKey: Name of the limit attribute in the pagination object in the underlying hook request as well as the hook response
 * @param totalKey: Name of the total attribute in the pagination object in the underlying hook response
 */
export const useInfiniteLoading = ({
  requestHook,
  requestHookArgs,
  dataListElement,
  paginationDelta = DEFAULT_PAGINATION_DELTA,
  paginationKey = 'pagination',
  offsetKey = 'offset',
  limitKey = 'limit',
  totalKey = 'total',
  useCache = true,
}) => {
  const prevResponsePagination = useRef({})
  const prevRequestHookArgs = useRef(requestHookArgs)
  const initialRequestPagination = useMemo(() => {
    const result = {}
    result[offsetKey] = 0
    result[limitKey] = paginationDelta
    return result
  }, [limitKey, paginationDelta, offsetKey])
  const [requestPagination, setRequestPagination] = useState(initialRequestPagination)

  const initialData = useMemo(() => {
    const result = {}
    result[dataListElement] = []
    result[paginationKey] = {}
    return result
  }, [dataListElement, paginationKey])

  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [data, setData] = useState(initialData)

  const resetPropertiesPagination = useSelector(
    (state) => state.properties.resetPropertiesPagination.resetPropertiesPagination,
  )

  const dispatch = useDispatch()

  const {
    isLoading: moreDataIsLoading,
    isError: moreDataIsError,
    isStale,
    data: loadMoreData,
    ...otherAttributes
  } = requestHook({
    ...requestHookArgs,
    [paginationKey]: requestPagination,
  })

  const moreDataIsStale = useCache ? isStale : false

  const loadMore = () => {
    const newRequestPagination = {
      [offsetKey]: prevResponsePagination.current[offsetKey] + paginationDelta,
      [limitKey]: paginationDelta,
    }
    setRequestPagination(newRequestPagination)
  }

  const handleNextDataChunk = () => {
    const mergeLoadedDataChunkWithPreviousData = () => {
      //find part of (earlier fetched) data which is not overlapped by (freshly fetched) loadMoreData
      const preserveDataLength = loadMoreData[paginationKey].offset - data[paginationKey].offset

      //construct updatedData from [1](pre-overlap) data appended with [2](freshly fetched) loadMoreData
      const dataDataListElementsToPreserve = data[dataListElement].slice(0, preserveDataLength)
      const updatedDataListElement = [
        ...dataDataListElementsToPreserve,
        ...loadMoreData[dataListElement],
      ]
      const updatedData = cloneDeep(data)
      updatedData[dataListElement] = updatedDataListElement

      // calculate the aggregated pagination information
      updatedData[paginationKey][offsetKey] = 0
      updatedData[paginationKey][limitKey] = updatedDataListElement.length
      updatedData[paginationKey][totalKey] = loadMoreData[paginationKey][totalKey]

      return updatedData
    }

    if (
      !moreDataIsLoading &&
      !moreDataIsError &&
      !moreDataIsStale &&
      !isEqual(loadMoreData[paginationKey], prevResponsePagination.current)
    ) {
      prevResponsePagination.current = cloneDeep(loadMoreData[paginationKey])
      const updatedData = mergeLoadedDataChunkWithPreviousData()
      setData(updatedData)
      setIsLoading(false)
    }

    if (moreDataIsError) {
      setIsError(true)
      setIsLoading(false)
    }
  }

  useEffect(handleNextDataChunk, [
    limitKey,
    data,
    dataListElement,
    loadMoreData,
    moreDataIsError,
    moreDataIsLoading,
    moreDataIsStale,
    paginationKey,
    offsetKey,
    totalKey,
  ])

  const resetPagination = useCallback(() => {
    prevResponsePagination.current = {}
    setData(initialData)
    setIsLoading(true)
    setIsError(false)
    setRequestPagination(initialRequestPagination)
  }, [initialData, initialRequestPagination])

  const resetPaginationOnRequestHookArgsChange = () => {
    if (!isEqual(requestHookArgs, prevRequestHookArgs.current)) {
      prevRequestHookArgs.current = cloneDeep(requestHookArgs)
      resetPagination()
    }
  }

  useEffect(resetPaginationOnRequestHookArgsChange, [
    initialData,
    initialRequestPagination,
    requestHookArgs,
    resetPagination,
  ])

  useEffect(() => {
    if (resetPropertiesPagination) {
      dispatch(setResetPropertiesPagination(false))
      resetPagination()
    }
  }, [resetPropertiesPagination, resetPagination, dispatch])

  return {
    isLoading,
    isError,
    data,
    loadMore,
    ...otherAttributes,
  }
}
