import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/leveraged-transaction/debt-per-ebitda-comment/DebtPerEBITDACommentDisplay.module.css'
import DebtPerEBITDACommentInputRow from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/leveraged-transaction/debt-per-ebitda-comment/DebtPerEBITDACommentInputRow'
import DebtPerEBITDACommentSection from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/leveraged-transaction/debt-per-ebitda-comment/DebtPerEBITDACommentSection'
import {
  useNumberFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const DebtPerEBITDACommentDisplay = ({ comment }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.nbcs.leveraged-transaction.Q11-comment',
  })
  const formatNumber = useNumberFormatter()
  const { format: formatDate } = useShortDateFormatter()

  return (
    <DebtPerEBITDACommentSection heading={t('heading')}>
      <DebtPerEBITDACommentInputRow label={t('key-date.label')}>
        <span className={styles.prefix}>{t('key-date.prefix')}</span>
        <span>{formatDate(comment?.keyDate)}</span>
      </DebtPerEBITDACommentInputRow>
      <DebtPerEBITDACommentInputRow label={t('total-debt.label')}>
        <span className={styles.prefix} />
        <span>{formatNumber(comment?.totalDebt)}</span>
        <span className={styles.suffix}>{comment?.totalDebtCurrencyCode}</span>
      </DebtPerEBITDACommentInputRow>
      <DebtPerEBITDACommentInputRow label={t('ebitda.label')}>
        <span className={styles.prefix} />
        <span>{formatNumber(comment?.ebitda)}</span>
        <span className={styles.suffix}>{comment?.ebitdaCurrencyCode}</span>
      </DebtPerEBITDACommentInputRow>
    </DebtPerEBITDACommentSection>
  )
}

DebtPerEBITDACommentDisplay.propTypes = {
  comment: PropTypes.shape({
    keyDate: PropTypes.string,
    totalDebt: PropTypes.number,
    totalDebtCurrencyCode: PropTypes.string,
    ebitda: PropTypes.number,
    ebitdaCurrencyCode: PropTypes.string,
  }),
}

export default DebtPerEBITDACommentDisplay
