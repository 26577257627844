import sortBy from 'lodash.sortby'
import uniq from 'lodash.uniq'
import { groupRentalUnitsBySegmentUsageTypeCode } from 'hooks/services/business-partners/tenancy/tenancyCalculationUtils'

const getProperties = (rentalUnits) => rentalUnits.map((rentalUnit) => rentalUnit.propertyUuid)

const getDeals = (properties, propertyDeals) => {
  const deals = []
  Object.entries(propertyDeals).forEach(([key, val]) => {
    if (properties.includes(key)) {
      deals.push(val)
    }
  })
  return deals.flat()
}

export const getPropertyAndDealCountBySegmentUsageType = (rentalUnits, propertyDeals) => {
  const perSegmentUsageType = {
    properties: [],
    deals: [],
  }
  const groupedRentalUnits = groupRentalUnitsBySegmentUsageTypeCode(rentalUnits)

  Object.entries(groupedRentalUnits).forEach((rentalUnitsBySegement) => {
    const rentalUnitsForSegment = rentalUnitsBySegement[1]
    const segmentName = rentalUnitsBySegement[1][0].segmentUsageTypeName

    const properties = uniq(getProperties(rentalUnitsForSegment))
    perSegmentUsageType.properties.push({
      value: properties.length,
      unit: undefined,
      type: segmentName,
    })

    const deals = uniq(getDeals(properties, propertyDeals))

    perSegmentUsageType.deals.push({
      value: deals.length,
      unit: undefined,
      type: segmentName,
    })
  })

  perSegmentUsageType.properties = sortBy(perSegmentUsageType.properties, (item) => item.type)
  perSegmentUsageType.deals = sortBy(perSegmentUsageType.deals, (item) => item.type)

  return perSegmentUsageType
}
