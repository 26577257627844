import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { resetTiles } from 'redux/slices/decision-paper/tilesOverviewSlice'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionPaperBackToCurrentButton = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper.button',
  })
  const {
    event: { id: eventId },
  } = useContext(BusinessEventsAndTasksContext)

  const [, setQueryParams] = useSearchParams()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const handleOnButtonClick = useCallback(() => {
    dispatch(resetTiles())
    setQueryParams()
    queryClient.invalidateQueries(['events', eventId, 'decision-paper', 'versions'])
  }, [dispatch, eventId, queryClient, setQueryParams])

  return (
    <Button design={ButtonDesign.Emphasized} onClick={handleOnButtonClick}>
      {t('back-to-current-version')}
    </Button>
  )
}

export default DecisionPaperBackToCurrentButton
