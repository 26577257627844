import uniq from 'lodash.uniq'
import { useMemo } from 'react'
import { ReferenceEntityType } from 'components/ui/data/ReferenceEntityType'
import { useMultipleDealsByUuidMini } from 'hooks/services/deals/useMultipleDealsByUuidMini'
import { useCamelizedResponse, useCombinedQueryResults } from 'hooks/services/queryHelper'

const useCovenantCheckDealIdsByUuids = ({ covenantData }) => {
  const allDealUuids = useMemo(
    () =>
      covenantData.reduce((accumulator, { monitoringReferenceEntity, covenants }) => {
        if (monitoringReferenceEntity.type === ReferenceEntityType.Deal) {
          accumulator.push(monitoringReferenceEntity.id)
        }
        covenants.forEach((covenant) => {
          if (covenant?.mainEntity?.type === ReferenceEntityType.Deal) {
            accumulator.push(covenant.mainEntity.id)
          }
        })
        return uniq(accumulator)
      }, []),
    [covenantData],
  )
  return useCamelizedResponse(useCombinedQueryResults(useMultipleDealsByUuidMini(allDealUuids)))
}

export default useCovenantCheckDealIdsByUuids
