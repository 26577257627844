import mapBorrowerData from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/borrower/mapBorrowerData'
import {
  ROW_TYPE_KPI,
  DEFAULT_DISPLAY_ROW_HEIGHT,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/constants'
import mapUnitDealData from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/deal/mapUnitDealData'
import mapFinancialRatioData from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/financial-ratios/mapFinancialRatioData'
import mapMultipleDealPricingResponses from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/loan/mapMultipleDealPricingResponses'
import mapMultipleDealTrancheResponses from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/loan/mapMultipleDealTrancheResponses'
import mapMultiPropertyData from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/property-and-tenancy/mapMultiPropertyData'
import mapMultipleAggregatedDealPortfolios from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/property-and-tenancy/mapMultipleAggregatedDealPortfolios'

const mapUnitOverviewData = ({
  unitOverviewData,
  multipleDealDetailsResponses,
  multipleAggregatedDealPortfolioResponses,
  complianceWithRiskStrategyResponses,
  lendersResponses,
  multipleSyndicationsExistingBusinessResponses,
  customerBusinessPartnerIdResponse,
  getSyndicationBusinessPartnerFullName,
  multipleDealTrancheResponses,
  multipleDealsPricingResponses,
  getCountryName,
  getPropertyTypeName,
  multiPropertyKpisResponses,
  formatters,
  getBusinessPartnerFullName,
  multipleKpisResponses,
  multipleForbearanceStatusesResponses,
  multipleCovenantMonitoringItems,
  multipleBusinessPartnerCustomFields,
  multiplePropertyCustomFieldsByPropertyUuidsData,
  businessPartnerCustomFieldConfigMetadata,
  t,
}) => {
  const { unitDeals: deals } = unitOverviewData
  const mappedOverviewData = [
    {
      kpi: t('rows.section.deal'),
      subRows: [
        ...mapUnitDealData({
          deals,
          multipleDealDetailsResponses,
          complianceWithRiskStrategyResponses,
          lendersResponses,
          multipleSyndicationsExistingBusinessResponses,
          customerBusinessPartnerIdResponse,
          getSyndicationBusinessPartnerFullName,
          t,
        }),
      ],
      rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT,
      isSectionHeader: true,
      columnType: ROW_TYPE_KPI,
      rowType: ROW_TYPE_KPI,
    },
    {
      kpi: t('rows.section.borrower'),
      subRows: [
        ...mapBorrowerData({
          deals,
          multipleForbearanceStatusesResponses,
          multipleCovenantMonitoringItems,
          multipleBusinessPartnerCustomFields,
          businessPartnerCustomFieldConfigMetadata,
          t,
        }),
      ],
      rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT,
      isSectionHeader: true,
      columnType: ROW_TYPE_KPI,
      rowType: ROW_TYPE_KPI,
    },
    {
      kpi: t('rows.section.loan'),
      subRows: [
        ...mapMultipleDealTrancheResponses({
          multipleDealTrancheResponses,
          t,
        }),
        ...mapMultipleDealPricingResponses({ multipleDealsPricingResponses, t }),
      ],
      rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT,
      isSectionHeader: true,
      columnType: ROW_TYPE_KPI,
      rowType: ROW_TYPE_KPI,
    },
    {
      kpi: t('rows.section.property-tenancy'),
      subRows: [
        ...mapMultipleAggregatedDealPortfolios({
          multipleAggregatedDealPortfolioResponses,
          getCountryName,
          getPropertyTypeName,
          deals,
          t,
        }),
        ...mapMultiPropertyData({
          multiPropertyKpisResponses,
          getBusinessPartnerFullName,
          multiplePropertyCustomFieldsByPropertyUuidsData,
          formatters,
          t,
        }),
      ],
      rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT,
      isSectionHeader: true,
      columnType: ROW_TYPE_KPI,
      rowType: ROW_TYPE_KPI,
    },
    {
      kpi: t('rows.section.financial-ratios'),
      subRows: [...mapFinancialRatioData({ multipleKpisResponses, t })],
      rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT,
      isSectionHeader: true,
      columnType: ROW_TYPE_KPI,
      rowType: ROW_TYPE_KPI,
    },
  ]
  return mappedOverviewData
}

export default mapUnitOverviewData
