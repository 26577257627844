import { useLayoutEffect, useMemo, useState } from 'react'

/**
 * Calculates row wrapper styles for analytical table rows.
 * The problem with just using wrappers around the analytical table rows
 * is that row level virtualization is always used.
 * The way that is done by creating a scrolling container around
 * the table rows, set to a fixed height of at least the calculated height
 * of rowHeight * number of rows.
 *
 * Then, to avoid creating all rows and rendering them, the viewport area
 * is calculated (the part of the scroll container currently visible in the
 * browser). This viewport corresponds to a set of rows, which are then
 * created and absolutely positioned inside the scroll container.
 * When the viewport is scrolled, those rows are re-calculated and rendered
 * if needed.
 *
 * Example: Row height is 50px, 200 rows are to be displayed. Scroll container is
 * 10.000 px high and the viewport 1.000 px. That means at the beginning, the
 * first 20 of the 200 rows would actually be visible. The analtyical table then,
 * as a performance optimization, renders only the first 20 rows and positions them.
 * The first row is 0px from the top, second one 50px and so on.
 * When the viewport is scrolled (now at position 1500px from the top), only rows
 * 31-50 are visible inside the viewport. In this case, rows 1-30 and 51-200 are not
 * rendered at all, and row 31 is positioned 1500px from the top, row 32 1550px
 * form the top and so on.
 * This unfortounately gets more complicated in the general case, as row height can
 * be variable (which means the table re-computes the scroll container height as
 * the container is scrolled), and the analytical table supports expandable
 * sections and data trees, making the actual positioning and height calculation
 * dependend on the internal table state and dynamic component heights.
 *
 * This means that for our implementation, it would be very brittle to try to
 * exactly replicate the height and positioning calculations the analytical
 * table uses internally. Instead we sync the relevant positioning styles
 * to the row styles by registering mutation observers on the actual
 * table rows and listening to the styles attribute only.
 *
 * Usage of this hook:
 * The hook takes no arguments but returns a function ref setter called `setWrapper`.
 * This setter has to be called with the actual html ref of the row rapper element.
 * Styles are then captured from the containing native row and provided in the
 * `wrapperStyles` output value. These wrapper styles should then be merged
 * with other custom styles the user wants to set (usually row height) and applied
 * to the wrapper component. Extra care also has to be taken to then overriding
 * the css values of the table row to disable positioning of the original, native
 * analytical table row.
 * This can be done for example by creating a class for the row wrapper
 * ```css
 * .rowWrapper {
 *   position: absolute;
 *   top: 0;
 *   width: 100%;
 * }
 * .rowWrapper > [role="row"] {
 *    transform: none !important;
 *    top: unset !important;
 *    left: unset !important;
 * }
 * ```
 *
 * complete example for a potential wrapper row:
 * ```jsx
 * const RowWrapperComponent = ({children,height}) => {
 *   const { setWrapper, wrapperStyles } useAnalyticalTableWrapperStyles()
 *   const computedStyles = useMemo(() => ({ ...wrapperStyles, height }))
 *   return (
 *     <div style={computedStyles} className="rowWrapper" ref={setRef}>
 *       {children}
 *     </div>
 *   )
 * }
 * ```
 *
 * @return {{setWrapper: import('react').Dispatch<import('react').SetStateAction<HTMLElement | null>>, wrapperStyles: CSSStyleDeclaration}}
 */
const useAnalyticalTableWrapperStyles = () => {
  const [wrapperElement, setWrapperElement] = useState(null)
  const [transformStyle, setTransformStyle] = useState(null)
  const [rowElement, setRowElement] = useState(null)

  useLayoutEffect(() => {
    if (!wrapperElement) {
      return
    }
    const previousRowElement = wrapperElement.querySelector('[role="row"]')
    const wrapperMutationObservert = new MutationObserver(() => {
      const newRowElement = wrapperElement.querySelector('[role="row"]')
      if (previousRowElement !== newRowElement) {
        setRowElement(newRowElement)
      }
    })
    wrapperMutationObservert.observe(wrapperElement, { childList: true })
    setRowElement(wrapperElement.querySelector('[role="row"]'))
    return () => wrapperMutationObservert.disconnect()
  }, [wrapperElement])

  useLayoutEffect(() => {
    /** @type {HTMLElement | null} */
    if (!rowElement) {
      return
    }

    setTransformStyle(rowElement?.style.transform)
    const rowMutationObserver = new MutationObserver(() => {
      setTransformStyle(rowElement?.style.transform)
    })
    rowMutationObserver.observe(rowElement, { attributeFilter: ['style'], attributes: true })
    return () => {
      rowMutationObserver.disconnect()
    }
  }, [rowElement])

  return useMemo(
    () => ({
      setWrapper: setWrapperElement,
      wrapperStyles: transformStyle ? { transform: transformStyle } : {},
    }),
    [transformStyle],
  )
}

export default useAnalyticalTableWrapperStyles
