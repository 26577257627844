import PropTypes from 'prop-types'
import { useMemo } from 'react'
import OpsMemoTranchePricingListItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/fields/OpsMemoTranchePricingListItem'
import TextEllipsis from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/fields/shared/TextEllipsis'
import { TranchePricingCustomFieldTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/fields/shared/TranchePricingCustomFieldTypes'
import {
  useBooleanToTextFormatter,
  useCustomizableCurrencyFormatter,
  usePercentageFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

export const OpsMemoTranchePricingCustomFieldListItem = ({ customField }) => {
  const { value, type, categoryShortText, currencyCode } = customField

  const formatBoolean = useBooleanToTextFormatter()
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formatCurrency = useCustomizableCurrencyFormatter()

  const formattedValue = useMemo(() => {
    switch (type) {
      case TranchePricingCustomFieldTypes.String:
        return <TextEllipsis content={value} />
      case TranchePricingCustomFieldTypes.Bool:
        return formatBoolean(value)
      case TranchePricingCustomFieldTypes.Percentage:
        return formatPercentage(value)
      case TranchePricingCustomFieldTypes.Currency:
        return formatCurrency(value, currencyCode)
      default:
        return value
    }
  }, [type, value, formatBoolean, formatPercentage, formatCurrency, currencyCode])

  return (
    <OpsMemoTranchePricingListItem
      key={categoryShortText}
      label={categoryShortText}
      value={formattedValue}
    />
  )
}

OpsMemoTranchePricingCustomFieldListItem.propTypes = {
  customField: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    type: PropTypes.string,
    categoryShortText: PropTypes.string,
    currencyCode: PropTypes.string,
  }),
}
