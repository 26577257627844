import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import DecisionProcessAlcBooleanField from 'components/domains/business-events-and-tasks/decision-process/alc/helper/DecisionProcessAlcBooleanField'
import DecisionProcessLabeledLoadingContent from 'components/domains/business-events-and-tasks/decision-process/alc/helper/DecisionProcessLabeledLoadingContent'
import useGetPricing from 'hooks/services/deals/pricing/useGetPricing'

const DecisionProcessAlcEpFullCost = ({ dealUuid }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.overview.alc-info.dialog',
  })
  const dealPricingResponse = useGetPricing({ dealUuid })
  const renderEpFullCost = useCallback(
    ({ values: { economic_profit_amount_full_cost, economic_profit_amount_direct_cost } }) => {
      const fullCost = economic_profit_amount_full_cost?.number ?? 0
      const directCost = economic_profit_amount_direct_cost?.number ?? 0
      return <DecisionProcessAlcBooleanField value={fullCost < 0 && directCost > 0} />
    },
    [],
  )
  return (
    <DecisionProcessLabeledLoadingContent
      response={dealPricingResponse}
      renderCallback={renderEpFullCost}
      label={t('fields.ep-full-cost')}
    />
  )
}

DecisionProcessAlcEpFullCost.propTypes = {
  dealUuid: PropTypes.string.isRequired,
}

export default DecisionProcessAlcEpFullCost
