import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import AssigneeFilter from 'components/domains/business-events-and-tasks/events/filterbar/AssigneeFilter'
import { filters } from 'components/ui/tables/toolbar/propTypes'

const TasksTableAssigneeCustomFilter = ({ selectedFilters, setSelectedFilters, columnKey }) => {
  const selectedAssignee = useMemo(
    () => selectedFilters.find((filter) => filter.columnKey === columnKey)?.value,
    [columnKey, selectedFilters],
  )

  const handleOnAssigneeChange = useCallback(
    (assignee) => {
      setSelectedFilters(
        selectedFilters.map((filter) =>
          filter.columnKey === columnKey ? { ...filter, value: assignee } : filter,
        ),
      )
    },
    [columnKey, selectedFilters, setSelectedFilters],
  )

  return <AssigneeFilter onAssigneeChange={handleOnAssigneeChange} assigneeId={selectedAssignee} />
}

TasksTableAssigneeCustomFilter.propTypes = {
  selectedFilters: filters.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  columnKey: PropTypes.string.isRequired,
}

export default TasksTableAssigneeCustomFilter
