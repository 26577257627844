import isNil from 'lodash.isnil'
import orderBy from 'lodash.orderby'
import sortBy from 'lodash.sortby'

const reduceFiscalFigureYears = ({ fiscalYears, noOfYears }) => {
  if (isNil(noOfYears)) {
    return fiscalYears
  }
  const yearsToShow = Object.keys(fiscalYears)
    .sort((a, b) => b - a)
    .slice(0, noOfYears)
  return yearsToShow.reduce((prev, year) => ({ ...prev, [year]: fiscalYears[year] }), {})
}

const buildSections = ({ fiscalFigureGroups, fiscalFigureTypes, fiscalYearsToShow }) =>
  Object.entries(fiscalFigureGroups).map(
    ([key, { display_name: groupName, ordinal_position: groupPosition }]) => ({
      description: groupName,
      isSectionHeader: true,
      ordinal_position: groupPosition,
      sectionData: sortBy(
        Object.entries(fiscalFigureTypes).filter(
          ([_, { ['group']: fiscalFigureGroup }]) => fiscalFigureGroup === key,
        ),
        ([_, { ordinal_position }]) => ordinal_position,
      ).map(([type, { display_name: name, format, importance }]) => {
        const result = {
          description: name,
          importance,
        }
        Object.entries(fiscalYearsToShow).forEach(
          ([year, { ['fiscal_figures']: fiscalFigures }]) => {
            result[year] = { ...fiscalFigures[type], format }
          },
        )
        return result
      }),
    }),
  )

const buildTableDataWithImportance = ({ sections, minimumImportanceLevel }) => {
  const tableData = sections
    .filter(
      ({ sectionData }) =>
        sectionData.filter(({ importance }) => importance >= minimumImportanceLevel).length > 0,
    )
    .map(({ description, isSectionHeader, ordinal_position, sectionData }) => [
      {
        description,
        isSectionHeader,
        ordinal_position,
      },
      ...sectionData.filter(({ importance }) => importance >= minimumImportanceLevel),
    ])

  const filterTableDataForValue = tableData.map((section) => {
    const filteredSection = section.filter((item) => {
      if (item.isSectionHeader) {
        return true
      } else {
        return Object.values(item).some(
          (val) => val && val.value !== undefined && val.value !== null,
        )
      }
    })

    return filteredSection
  })

  const filteredTable = filterTableDataForValue.filter((index) => index.length > 1)

  return orderBy(filteredTable, ['ordinal_position']).flat()
}

export const mapFiscalDataForFinancialInformationTable = ({
  fiscalYears,
  fiscalFigureGroups,
  fiscalFigureTypes,
  minimumImportanceLevel = 0,
  noOfYears,
}) => {
  const fiscalYearsToShow = reduceFiscalFigureYears({ fiscalYears, noOfYears })

  const sections = buildSections({ fiscalFigureGroups, fiscalFigureTypes, fiscalYearsToShow })

  return buildTableDataWithImportance({ sections, minimumImportanceLevel })
}
