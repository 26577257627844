import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { entityTypes } from 'api/events/status'
import styles from 'components/domains/business-events-and-tasks/BusinessObjectLink.module.css'
import LinkWithId from 'components/domains/business-events-and-tasks/LinkWithId'
import Entity from 'components/ui/data/Entity'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import paths from 'routes/paths'

const BusinessPartnerLink = ({
  businessPartnerId,
  customRender,
  errorCallback,
  CustomComponent,
  customComponentProperties,
}) => {
  const { t: t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.business-object-link',
  })
  const {
    data: businessPartners,
    isLoading,
    isError,
  } = useBusinessPartnerMiniByIds([businessPartnerId])

  useEffect(() => {
    if (isError) {
      errorCallback?.({ hasError: true, type: 'BUSINESS_PARTNER' })
    }
  }, [isError, errorCallback])

  const errorLabel = <div className={styles.errorLabel}>{t('error.description')}</div>

  // TODO: Remove inline style when there is a solution regarding to ui5 components override styling
  const renderContent = () => {
    const businessPartnerName = businessPartners.businessPartnerMinis[0]?.fullName
    const link = `/${paths.businessPartners}/${businessPartnerId}`

    if (CustomComponent) {
      return (
        <CustomComponent
          {...customComponentProperties}
          entityType={entityTypes.businesspartner}
          name={businessPartnerName}
          id={businessPartnerId}
          link={link}
        />
      )
    }

    switch (customRender) {
      case 'SINGLE_LINE':
        return <LinkWithId href={link} name={businessPartnerName} id={businessPartnerId} />
      case 'CLIPBOARD':
        return (
          <EntityTypeAndIdWithClipboard
            entityType={entityTypes.businesspartner}
            name={businessPartnerName}
            id={businessPartnerId}
          />
        )
      default:
        return <Entity name={businessPartnerName} link={link} id={businessPartnerId} openInNewTab />
    }
  }

  if (isError && CustomComponent) {
    return <CustomComponent {...customComponentProperties} hasError />
  }

  return (
    <SmallLoadingWrapper
      isError={isError}
      isLoading={isLoading}
      error={errorLabel}
      renderContent={renderContent}
    />
  )
}

BusinessPartnerLink.propTypes = {
  businessPartnerId: PropTypes.string.isRequired,
  customRender: PropTypes.oneOf(['CLIPBOARD', 'SINGLE_LINE']),
  errorCallback: PropTypes.func,
  CustomComponent: PropTypes.func,
  customComponentProperties: PropTypes.object,
}

export default BusinessPartnerLink
