import PropTypes from 'prop-types'
import { useCallback, useState, forwardRef } from 'react'
import ReferenceMultiSelectionInput from 'components/domains/conditions/reference-selection/ReferenceMultiSelectionInput'
import DealSearchDialog from 'components/domains/deals/deal-search/DealSearchDialog'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useReferenceNames from 'hooks/services/conditions/reference-selection/useReferenceNames'

const DealMultiInput = forwardRef(
  ({ selectedReferences = [], onDeleteReferences, onClearSelections, handleOnChange }, _ref) => {
    const [isHelperDialogOpen, setIsHelperDialogOpen] = useState(false)

    const { resolvedReferences } = useReferenceNames({
      references: selectedReferences,
    })

    const mappedReferencesToSearchDialog = resolvedReferences?.map(
      ({ entityName, entityId, displayId }) => ({
        dealUuid: entityId,
        dealId: displayId,
        dealName: entityName,
      }),
    )

    const onClickReferenceSelection = useCallback(() => {
      setIsHelperDialogOpen(true)
    }, [])

    const onClearSelection = useCallback(() => {
      onClearSelections(cwpEntityTypes.DEAL)
    }, [onClearSelections])

    const handleSelectedDeals = useCallback(
      (selectedDeals) => {
        const dealIds = selectedDeals.map(({ dealUuid }) => ({
          entityId: dealUuid,
          entityType: cwpEntityTypes.DEAL,
        }))
        handleOnChange(dealIds)
      },
      [handleOnChange],
    )

    const handleSearchClose = useCallback(() => {
      setIsHelperDialogOpen(false)
    }, [])

    return (
      <>
        <ReferenceMultiSelectionInput
          selectedReferences={selectedReferences}
          onClear={onClearSelection}
          onDeleteReference={onDeleteReferences}
          onClickValueHelperIcon={onClickReferenceSelection}
          displayPlaceholderText={false}
          resolveNames
          showValueHelperIcon
        />
        {isHelperDialogOpen && (
          <DealSearchDialog
            isOpen={isHelperDialogOpen}
            setIsOpen={setIsHelperDialogOpen}
            onClose={handleSearchClose}
            onSelected={handleSelectedDeals}
            isMultiSelect={true}
            initiallySelectedDeals={mappedReferencesToSearchDialog}
          />
        )}
      </>
    )
  },
)

DealMultiInput.displayName = 'DealMultiInput'

DealMultiInput.propTypes = {
  selectedReferences: PropTypes.arrayOf(
    PropTypes.shape({
      entityId: PropTypes.string.isRequired,
      entityType: PropTypes.string.isRequired,
    }),
  ),
  onDeleteReferences: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  onClearSelections: PropTypes.func.isRequired,
}

export default DealMultiInput
