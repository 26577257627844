const conditionRefNumberChanged = (
  state,
  { payload: { conditionIndex, refNumber: newRefNumber } },
) => {
  if (!state.conditions[conditionIndex]) {
    return
  }
  state.conditions[conditionIndex].refNumber = newRefNumber
}

export default conditionRefNumberChanged
