import PropTypes from 'prop-types'
import { useCallback } from 'react'
import MultiRentalIncomeOverTimeTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/rental-income-over-time/MultiRentalIncomeOverTimeTile'
import MultipleDealSelectionPdfViewWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/wrapper/MultipleDealSelectionPdfViewWrapper'
import { UsageTypeColorProvider } from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/UsageTypeColorContext'

const MultiRentalIncomeOverTimeTileDealSelectionWrapper = ({ tileId, isPdfView }) => {
  const contentCreator = useCallback(
    ({ selectedDealIndex }) => (
      <UsageTypeColorProvider>
        <MultiRentalIncomeOverTimeTile
          tileId={tileId}
          selectedDealIndex={selectedDealIndex}
          isPdfView={isPdfView}
        />
      </UsageTypeColorProvider>
    ),
    [isPdfView, tileId],
  )
  return (
    <MultipleDealSelectionPdfViewWrapper
      tileId={tileId}
      contentCreator={contentCreator}
      isPdfView={isPdfView}
    />
  )
}

MultiRentalIncomeOverTimeTileDealSelectionWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool,
}

export default MultiRentalIncomeOverTimeTileDealSelectionWrapper
