import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { useMemo } from 'react'
import { assessmentTypes } from 'components/domains/business-partners/tile/assessments/assessmentTypes'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import { useTextualAssessments } from 'hooks/services/business-partners/assessments/useTextualAssessments'
import useLeadingAuthorityLevelCalculator from 'hooks/services/business-partners/authority-level-calculator/exec-summary/useLeadingAuthorityLevelCalculator'

const useGamStatement = ({ entityRef: { entityId: dealUuid } }, tileId, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode } = {},
    isFetching: isFetchingDealUuid,
    isError: isErrorDealUuid,
    errors: dealUuidErrors,
  } = useDealUuidByTileCode({
    dealUuid,
    tileCode,
  })

  const {
    data: authorityLevelCalculatorData,
    isFetching: isFetchingAuthorityLevelCalculatorData,
    isError: isErrorAuthorityLevelCalculatorData,
    error: authorityLevelCalculatorError,
  } = useLeadingAuthorityLevelCalculator(dealUuidByTileCode)

  const businessPartnerId = authorityLevelCalculatorData?.unitHead?.unitHeadBpId

  // each following hook is disabled when BpId is undefined
  const businessPartnerIdIsPresent = useMemo(() => !isNil(businessPartnerId), [businessPartnerId])

  const {
    data: textualAssessmentData,
    isFetching: isTextualAssessmentFetching,
    isError: isTextualAssessmentError,
    error: textualAssessementError,
  } = useTextualAssessments(
    { businessPartnerId, type: assessmentTypes.BackOffice },
    { enabled: businessPartnerIdIsPresent },
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isFetchingDealUuid,
      isFetchingAuthorityLevelCalculatorData,
      isTextualAssessmentFetching,
    ],
    errorValues: [isErrorDealUuid, isErrorAuthorityLevelCalculatorData, isTextualAssessmentError],
    errorDetails: [...dealUuidErrors, authorityLevelCalculatorError, textualAssessementError],
    tileId,
  })

  const backofficeAssessment = useMemo(() => {
    if (isEmpty(textualAssessmentData?.availableVersions)) {
      return undefined
    }
    const { availableVersions } = textualAssessmentData
    // Sort descending by version number to return the content with highest version
    const latestAssessment = [...availableVersions].sort(
      ({ version: firstVersion }, { version: secondVersion }) => secondVersion - firstVersion,
    )[0]
    return latestAssessment?.content
  }, [textualAssessmentData])

  return useMemo(() => {
    if (isSomeValueError || isSomeValueLoading) {
      return {
        isLoading: isSomeValueLoading,
        isError: isSomeValueError,
        error,
      }
    }

    return {
      isLoading: isSomeValueLoading,
      isError: isSomeValueError,
      data: {
        sourceRender: { businessPartnerId },
        backofficeAssessment,
      },
    }
  }, [backofficeAssessment, businessPartnerId, error, isSomeValueError, isSomeValueLoading])
}

export default useGamStatement
