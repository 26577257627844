import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Icon,
  Label,
  MessageStrip,
  MessageStripDesign,
  ObjectStatus,
  SegmentedButton,
  SegmentedButtonItem,
  TableGrowingMode,
  TableRowType,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import classNames from 'classnames'
import PropType from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getObjectStatusForEventStatus } from 'api/events/status'
import BusinessObjectLink from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import DueDateWarningIcon from 'components/domains/business-events-and-tasks/common/DueDateWarningIcon'
import AssigneeTableCell from 'components/domains/business-events-and-tasks/events/AssigneeTableCell'
import BusinessEventTableCell from 'components/domains/business-events-and-tasks/events/BusinessEventTableCell'
import BusinessEventWithoutTypeTableCell from 'components/domains/business-events-and-tasks/events/BusinessEventWithoutTypeTableCell'
import CurrentDecisionStageTableCell from 'components/domains/business-events-and-tasks/events/CurrentDecisionStageTableCell'
import EventOverviewEditMultipleButton from 'components/domains/business-events-and-tasks/events/EventOverviewEditMultipleButton'
import styles from 'components/domains/business-events-and-tasks/events/EventOverviewTable.module.css'
import EventOverviewTableCheckbox from 'components/domains/business-events-and-tasks/events/EventOverviewTableCheckbox'
import EventOverviewTableMultiEditDialog from 'components/domains/business-events-and-tasks/events/EventOverviewTableMultiEditDialog'
import EventOverviewTableSelectAllCheckbox from 'components/domains/business-events-and-tasks/events/EventOverviewTableSelectAllCheckbox'
import useMultipleEventsCommentButtonAndDialog from 'components/domains/business-events-and-tasks/events/useMultipleEventsCommentButtonAndDialog'
import EventWatchersButton from 'components/domains/business-events-and-tasks/events/watchers/EventWatchersButton'
import useNavigate from 'components/ui/link/useNavigate'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import useCtrlOrMetaKeyPressed from 'components/useCtrlOrMetaPressed'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useDecisionPaperLinkConfirmationDialog from 'hooks/services/business-events-and-tasks/decision-papers/useDecisionPaperLinkConfirmationDialog'
import { eventsRowHasError, resetEventsRowErrors } from 'redux/slices/events/eventOverviewSlice'
import paths from 'routes/paths'

const columnKeyToEventDataMap = {
  businessEvent: 'combined_event_name',
  businessObjectType: 'entity_type',
  assignee: 'info.assignee',
  originalDueDate: 'info.original_due_date',
  currentDueDate: 'info.current_due_date',
  status: 'status',
}

const emptyValue = ''

const EventOverviewTable = ({
  events,
  onSortingChanged,
  sortBy,
  orderBy,
  onLoadMore,
  maximumNumberOfEvents,
  maximumNumberOfMyEvents,
  shownColumns,
  noDataText,
  currentUserId,
  myEventsLoading,
  hasToolbarActions = true,
  className,
  showConfirmationForLinks = false,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.event-overview.table' })
  const [isEditMultipleDialogOpen, setIsEditMultipleDialogOpen] = useState(false)
  const { t: tWithoutPrefix } = useTranslation()
  const [queryParams, setQueryParams] = useSearchParams()
  const { format } = useShortDateFormatter()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const tableErrorRows = useSelector((state) => state.events.eventOverview.errorRows)
  const showErrorStrip = useSelector((state) => state.events.eventOverview.rowsHaveError)
  const { commentMultipleButton, commentMultipleDialog } = useMultipleEventsCommentButtonAndDialog()

  const handleRowError = useCallback(
    ({ rowId }) => {
      dispatch(eventsRowHasError({ id: rowId }))
    },
    [dispatch],
  )
  const handleRowErrorReset = useCallback(
    ({ rowId }) => {
      dispatch(resetEventsRowErrors({ ids: [rowId] }))
    },
    [dispatch],
  )

  // clean up old errors for events not shown anymore
  useEffect(() => {
    const eventIds = new Set(events.map(({ id }) => id))
    const idsToBeRemoved = Object.keys(tableErrorRows).filter((eventId) => !eventIds.has(eventId))
    if (idsToBeRemoved.length > 0) {
      dispatch(resetEventsRowErrors({ ids: idsToBeRemoved }))
    }
  }, [dispatch, events, tableErrorRows])

  const possibleEventColumns = useMemo(
    () => ({
      checkbox: {
        renderColumnContent: () => <EventOverviewTableSelectAllCheckbox events={events} />,
        columnKey: 'checkbox',
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
      businessEvent: {
        title: t('business-event'),
        columnKey: 'businessEvent',
      },
      businessEventWithoutType: {
        title: t('business-event'),
        columnKey: 'businessEventWithoutType',
      },
      businessObjectType: {
        title: t('business-object-type'),
        columnKey: 'businessObjectType',
      },
      businessObject: {
        title: t('business-object'),
        columnKey: 'businessObject',
        sortingDisabled: true,
      },
      assignee: {
        title: t('assignee'),
        columnKey: 'assignee',
        sortingDisabled: true,
        demandPopin: true,
        minWidth: 1300,
        popinText: t('assignee'),
      },
      originalDueDate: {
        title: t('due-date.original'),
        columnKey: 'originalDueDate',
        demandPopin: true,
        minWidth: 1150,
        popinText: t('due-date.original'),
        alignment: FlexBoxJustifyContent.End,
      },
      currentDueDate: {
        title: t('due-date.current'),
        columnKey: 'currentDueDate',
        demandPopin: true,
        minWidth: 1150,
        popinText: t('due-date.current'),
        alignment: FlexBoxJustifyContent.End,
      },
      creationDate: {
        title: t('creation-date'),
        columnKey: 'creationDate',
        alignment: FlexBoxJustifyContent.End,
      },
      closingDate: {
        title: t('closing-date'),
        columnKey: 'closingDate',
        alignment: FlexBoxJustifyContent.End,
      },
      currentDecisionStage: {
        title: t('current-decision-stage'),
        columnKey: 'currentDecisionStage',
        demandPopin: true,
        minWidth: 1150,
        popinText: t('current-decision-stage'),
      },
      status: {
        title: t('status'),
        columnKey: 'status',
        demandPopin: true,
        minWidth: 900,
        popinText: t('status'),
      },
      watchers: {
        title: '',
        columnKey: 'watchers',
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
      arrow: { title: '', columnKey: 'arrow', sortingDisabled: true, isSelectableForHiding: false },
    }),
    [events, t],
  )
  const eventColumns = useMemo(
    () => shownColumns.map((columnKey) => possibleEventColumns[columnKey]),
    [possibleEventColumns, shownColumns],
  )

  const displayClosingDate = useCallback(
    (closingDate, currentDueDate) => {
      if (closingDate) return <Text>{format(closingDate)}</Text>

      if (currentDueDate)
        return (
          <FlexBox direction={FlexBoxDirection.Column} className={styles.dateAlignment}>
            <Text>{format(currentDueDate)}</Text>
            <Text>{t('forecasted')}</Text>
          </FlexBox>
        )

      return emptyValue
    },
    [format, t],
  )

  const displayStatus = useCallback(
    (status) => {
      const { translationKey: statusTranslationKey, objectStatus } =
        getObjectStatusForEventStatus(status)
      return (
        <ObjectStatus inverted state={objectStatus}>
          {tWithoutPrefix(statusTranslationKey)}
        </ObjectStatus>
      )
    },
    [tWithoutPrefix],
  )
  const tableData = useMemo(
    () =>
      events.map(
        ({
          id,
          displayId,
          status,
          info: { name, assignee, originalDueDate, currentDueDate, creationDate, closingDate },
          entityRef: { entityType, entityId },
        }) => ({
          rowKey: `events-table-${id}`,
          rowProperties: {
            type: TableRowType.Active,
            'data-event-id': id,
          },
          rowClassName: classNames({
            [styles.highlightErrorRow]: tableErrorRows[id],
          }),
          checkbox: {
            cellComponent: <EventOverviewTableCheckbox eventId={id} status={status} />,
          },
          businessEvent: {
            cellComponent: (
              <BusinessEventTableCell
                rowId={id}
                eventName={name}
                entityId={entityId}
                entityType={entityType}
                eventDisplayId={displayId}
                errorCallback={handleRowError}
              />
            ),
          },
          businessEventWithoutType: {
            cellComponent: (
              <BusinessEventWithoutTypeTableCell
                eventName={name}
                eventId={id}
                eventDisplayId={displayId}
                showConfirmationForLinks={showConfirmationForLinks}
              />
            ),
          },
          businessObjectType: {
            cellComponent: <Label>{tWithoutPrefix(`events.entity-type.${entityType}`)}</Label>,
          },
          businessObject: {
            cellComponent: <BusinessObjectLink entityId={entityId} entityType={entityType} />,
          },
          assignee: {
            cellComponent: assignee ? (
              <AssigneeTableCell
                assigneeId={assignee}
                rowId={id}
                errorCallback={handleRowError}
                resetErrorCallback={handleRowErrorReset}
              />
            ) : (
              <Label>{emptyValue}</Label>
            ),
          },
          originalDueDate: {
            cellComponent: (
              <div className={styles.dateAlignment}>
                <Text>{originalDueDate ? format(originalDueDate) : emptyValue}</Text>
              </div>
            ),
          },
          currentDueDate: {
            cellComponent: (
              <div className={styles.dateAlignment}>
                <Text>{currentDueDate ? format(currentDueDate) : emptyValue}</Text>
                <DueDateWarningIcon
                  className={styles['due-date-warning-icon']}
                  dueDate={currentDueDate}
                  eventStatus={status}
                />
              </div>
            ),
          },
          creationDate: {
            cellComponent: (
              <div className={styles.dateAlignment}>
                <Text>{creationDate ? format(creationDate) : emptyValue}</Text>
              </div>
            ),
          },
          closingDate: {
            cellComponent: displayClosingDate(closingDate, currentDueDate),
          },
          currentDecisionStage: {
            cellComponent: id ? (
              <CurrentDecisionStageTableCell eventId={id} />
            ) : (
              <Label>{emptyValue}</Label>
            ),
          },
          status: {
            cellComponent: <Label>{displayStatus(status)}</Label>,
          },
          watchers: {
            cellComponent: <EventWatchersButton eventId={id} />,
          },
          arrow: {
            cellComponent: <Icon name="slim-arrow-right" />,
          },
        }),
      ),
    [
      displayClosingDate,
      displayStatus,
      events,
      format,
      handleRowError,
      handleRowErrorReset,
      showConfirmationForLinks,
      tWithoutPrefix,
      tableErrorRows,
    ],
  )

  const onEditMultipleClicked = useCallback(() => {
    setIsEditMultipleDialogOpen(true)
  }, [])

  const editMultipleButton = useMemo(
    () => (
      <EventOverviewEditMultipleButton
        key="event-overview-edit-multiple-button"
        onClick={onEditMultipleClicked}
      />
    ),
    [onEditMultipleClicked],
  )

  const customOrderFunction = (disorderedTableData) => disorderedTableData

  const customOrderCallback = (columnKey, newOrderBy) => {
    const newSortBy = columnKeyToEventDataMap[columnKey]
    onSortingChanged(newSortBy, newOrderBy)
  }

  const sortingColumnKey = Object.keys(columnKeyToEventDataMap).find(
    (key) => columnKeyToEventDataMap[key] === sortBy,
  )

  const onConfirm = useCallback((eventId) => {
    const eventHref = `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}`

    window.open(eventHref, '_blank')
  }, [])

  const { showConfirmationDialog } = useDecisionPaperLinkConfirmationDialog({
    onConfirm: onConfirm,
  })

  const isCtrlOrMetaPressed = useCtrlOrMetaKeyPressed()

  const handleRowClick = (url, target) => {
    navigate(url, { target: target ? '_blank' : undefined })
  }

  const additionalTableProperties = {
    onRowClick: ({ detail: { row: clickedTableRow } }) => {
      const eventId = clickedTableRow.getAttribute('data-event-id')

      showConfirmationForLinks
        ? showConfirmationDialog(eventId)
        : handleRowClick(
            `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}`,
            isCtrlOrMetaPressed,
          )
    },
  }

  // delete and append here because set with ...queryParams resets all existing params
  const handleAllEventsViewSwitch = useCallback(() => {
    queryParams.delete('assigneeId')
    queryParams.append('assigneeId', '')
    setQueryParams(queryParams)
  }, [queryParams, setQueryParams])

  const handleMyEventsViewSwitch = useCallback(() => {
    queryParams.delete('assigneeId')
    queryParams.append('assigneeId', currentUserId)
    setQueryParams(queryParams)
  }, [queryParams, setQueryParams, currentUserId])

  const currentAssignee = queryParams.get('assigneeId')
  const myEventView = currentAssignee === currentUserId
  const currentEventNumber = myEventView ? maximumNumberOfMyEvents : maximumNumberOfEvents

  const segmentButton = useMemo(
    () => (
      <SegmentedButton key="event-overview-segment-switch-button">
        <SegmentedButtonItem pressed={!myEventView} onClick={handleAllEventsViewSwitch}>
          {t('buttons.all-events', { numberOfEvents: maximumNumberOfEvents })}
        </SegmentedButtonItem>
        <SegmentedButtonItem
          pressed={myEventView}
          disabled={myEventsLoading}
          onClick={handleMyEventsViewSwitch}
        >
          {t('buttons.my-events', { numberOfEvents: maximumNumberOfMyEvents })}
        </SegmentedButtonItem>
      </SegmentedButton>
    ),
    [
      maximumNumberOfEvents,
      maximumNumberOfMyEvents,
      t,
      handleAllEventsViewSwitch,
      handleMyEventsViewSwitch,
      myEventView,
      myEventsLoading,
    ],
  )

  const toolbarConfigWithActions = useMemo(
    () => ({
      title: t('toolbar.title'),
      dontShowNumberOfEntries: true,
      sorting: {
        columnKey: sortingColumnKey,
        isSortingAscending: orderBy === 'asc',
      },
      additionalActions: [commentMultipleButton, editMultipleButton],
      additionalActionsNextToTitle: [segmentButton],
    }),
    [t, sortingColumnKey, orderBy, editMultipleButton, commentMultipleButton, segmentButton],
  )

  const toolbarConfigWithoutActions = useMemo(
    () => ({
      title: t('toolbar.title'),
      showColumnSelection: false,
    }),
    [t],
  )

  return (
    <div className={className}>
      {showErrorStrip && (
        <MessageStrip
          hideCloseButton
          design={MessageStripDesign.Negative}
          className={styles.messageStrip}
        >
          {t('error.table-has-error')}
        </MessageStrip>
      )}
      <SortedTable
        columnDefinitions={eventColumns}
        tableData={tableData}
        customOrderFunction={customOrderFunction}
        customOrderCallback={customOrderCallback}
        additionalTableProperties={additionalTableProperties}
        toolbarConfig={hasToolbarActions ? toolbarConfigWithActions : toolbarConfigWithoutActions}
        noDataText={noDataText}
        paginationConfig={{
          growing: tableData.length < currentEventNumber ? TableGrowingMode.Button : undefined,
          growingButtonText: t('button.load-more'),
          growingButtonSubtext: `[${tableData.length} / ${currentEventNumber}]`,
          totalNumberOfItems: currentEventNumber,
          loadMore: onLoadMore,
        }}
      />
      {isEditMultipleDialogOpen && (
        <EventOverviewTableMultiEditDialog
          isOpen={isEditMultipleDialogOpen}
          setIsOpen={setIsEditMultipleDialogOpen}
        />
      )}
      {commentMultipleDialog}
    </div>
  )
}

EventOverviewTable.propTypes = {
  events: PropType.array.isRequired,
  onSortingChanged: PropType.func,
  sortBy: PropType.string.isRequired,
  orderBy: PropType.oneOf(['asc', 'desc']),
  onLoadMore: PropType.func,
  maximumNumberOfEvents: PropType.number.isRequired,
  maximumNumberOfMyEvents: PropType.number,
  shownColumns: PropType.arrayOf(
    PropType.oneOf([
      'checkbox',
      'businessEvent',
      'businessEventWithoutType',
      'businessObjectType',
      'businessObject',
      'assignee',
      'creationDate',
      'closingDate',
      'originalDueDate',
      'currentDueDate',
      'currentDecisionStage',
      'status',
      'watchers',
      'arrow',
    ]),
  ),
  noDataText: PropType.string.isRequired,
  currentUserId: PropType.string,
  myEventsLoading: PropType.bool.isRequired,
  hasToolbarActions: PropType.bool,
  className: PropType.string,
  showConfirmationForLinks: PropType.bool,
}

export default EventOverviewTable
