import { useMemo } from 'react'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useMultipleTenancyOverviewExpiringLeases from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useMultipleTenancyOverviewExpiringLeases'

const useAnnualReviewTenancyRentRollExpiringLeases = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { selectedDeal, dealsData } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const { selectedDealUuid, selectedDealDisplayId } = selectedDeal ?? {}
  const dealUuids = dealsData?.map((dealData) => dealData.dealUuid)

  const {
    data: tenancyOverviewExpiringLeasesPerDealUuidData,
    isLoading: isTenancyOverviewExpiringLeasesLoading,
    isError: isTenancyOverviewExpiringLeasesError,
    errorTenancyOverviewExpiringLeases,
  } = useMultipleTenancyOverviewExpiringLeases({
    dealUuids,
  })

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isAnnualReviewDealsLoading, isTenancyOverviewExpiringLeasesLoading],
    errorValues: [isAnnualReviewDealsError, isTenancyOverviewExpiringLeasesError],
    errorDetails: [annualReviewDealsError, errorTenancyOverviewExpiringLeases],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        tenancyOverviewExpiringLeasesPerDealUuidData,
        sourceRender: {
          ...tenancyOverviewExpiringLeasesPerDealUuidData?.[selectedDealUuid]?.sourceRender,
          businessPartnerId,
          dealDisplayId: selectedDealDisplayId,
        },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealsData,
    tenancyOverviewExpiringLeasesPerDealUuidData,
    selectedDealUuid,
    businessPartnerId,
    selectedDealDisplayId,
    error,
  ])
}

export default useAnnualReviewTenancyRentRollExpiringLeases
