import isNil from 'lodash.isnil'
import { DateTime } from 'luxon'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfigContext } from 'hooks/config/useConfig'
import useFinancialIndicatorsHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/financial-indicators/useFinancialIndicatorsHelper'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import {
  useMultipleAssessmentsAggregated,
  useMultipleBusinessPartnerRatingsAggregated,
  useMultipleBusinessPartnersByIdAggregated,
  useMultipleFiscalYearsAggregated,
  useMultipleTextualAssessmentsAggregated,
} from 'hooks/services/business-events-and-tasks/decision-papers/tiles/gcc-involved-parties/sponsor-guarantor/useInvolvedPartiesAggregatedQueries'
import { useInvolvedPartiesGetGuarantors } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/gcc-involved-parties/sponsor-guarantor/useInvolvedPartiesGetGuarantors'
import { DEFAULT_RATING_AGENCY_PRIORITY } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useTenantDetails'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useBusinessPartnerRelationshipUnitTypes from 'hooks/services/business-partners/config/useBusinessPartnerRelationshipUnitTypes'
import useBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationships'
import useDealMini from 'hooks/services/deals/useDealMini'

const language = 'en-US'

const sortBusinessPartnersInfo = (businessPartnersInfo) =>
  businessPartnersInfo.sort(
    ({ information: { name: nameA } }, { information: { name: nameB } }) => {
      if (nameA > nameB) {
        return 1
      }
      if (nameA < nameB) {
        return -1
      }
      return 0
    },
  )

const getRelationshipHeadsIds = (relationships = [], borrowerBpId) => {
  const relationshipHeads = []
  relationships.forEach(({ members = [], head = {} }) => {
    if (members.find((member) => member.id === borrowerBpId)) {
      relationshipHeads.push(head.id)
    }
  })
  return relationshipHeads
}

const getCurrentAppreciationContent = ({ availableVersions } = {}) => {
  if ((availableVersions ?? []).length === 0) {
    return undefined
  }
  let largestVersion = availableVersions[0].version
  let assessmentContent = availableVersions[0].content
  for (const assessmentVersion of availableVersions) {
    if (assessmentVersion.version > largestVersion) {
      assessmentContent = assessmentVersion.content
    }
    largestVersion = assessmentVersion.version
  }
  return assessmentContent
}

const getLatestFiscalData = (fiscalYearsData) => {
  if (isNil(fiscalYearsData?.fiscal_years)) {
    return undefined
  }
  const fiscalYears = fiscalYearsData.fiscal_years
  let latestYear = 0
  Object.keys(fiscalYearsData.fiscal_years).forEach((yearString) => {
    const year = +yearString
    if (year > latestYear) {
      latestYear = year
    }
  })
  return fiscalYears[String(latestYear)]
}

const getInternalRating = (ratingsData) =>
  (ratingsData?.internal ?? []).find((rating) => rating.isActive)

export const useSponsorGuarantorOverview = (
  { entityRef: { entityId: dealUuid } },
  tileId,
  tileCode,
) => {
  const { t } = useTranslation('translation', {
    keyPrefix:
      'components.business-events-and-tasks.decision-paper.tiles.bp-sponsor-guarantor-overview',
  })

  const {
    businessPartner: { sapRelationshipIds: { sponsor: sponsorRelationshipId } = {} } = {},
    ratingAgencyPriority = DEFAULT_RATING_AGENCY_PRIORITY,
  } = useContext(ConfigContext)

  const {
    isError: isErrorFinancialIndicatorHelper,
    isLoading: isLoadingFinancialIndicatorHelper,
    getFinancialIndicators,
  } = useFinancialIndicatorsHelper(tileCode)

  const {
    data: { dealUuid: dealUuidByTileCode } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
    errors: dealUuidErrors,
  } = useDealUuidByTileCode({
    dealUuid,
    tileCode,
  })

  const {
    isFetching: isDealFetching,
    isError: isDealError,
    error: dealError,
    data: dealData,
  } = useDealMini(dealUuidByTileCode)
  const { borrowerBpId } = dealData ?? {}

  const isBorrowerBpIdPresent = !isNil(borrowerBpId)
  const {
    isFetching: isUnitRelationshipsFetching,
    isError: isUnitRelationshipsError,
    error: unitRelationshipsError,
    data: { unitRelationships } = {},
  } = useBusinessPartnerRelationships(borrowerBpId, { enabled: isBorrowerBpIdPresent })

  const {
    isFetching: isFetchingUnitRelationshipTypesConfig,
    isError: isErrorUnitRelationshipTypesConfig,
    error: unitRelationshipTypesConfigError,
    data: { unitTypes: unitRelationshipTypesConfig = [] } = {},
  } = useBusinessPartnerRelationshipUnitTypes()

  const {
    isFetching: isGuarantorPartnersFetching,
    isError: isGuarantorPartnersError,
    errors: guarantorPartnersErrors,
    data: guarantorPartnersData,
  } = useInvolvedPartiesGetGuarantors({ dealUuid: dealUuidByTileCode })

  const guarantorBpIds = useMemo(
    () => (guarantorPartnersData ?? []).map(({ partyId }) => partyId),
    [guarantorPartnersData],
  )
  const sponsorRelationship = useMemo(
    () => (unitRelationships ?? []).find(({ id }) => id === sponsorRelationshipId),
    [sponsorRelationshipId, unitRelationships],
  )
  const sponsorBpIds = getRelationshipHeadsIds(sponsorRelationship?.relationships, borrowerBpId)
  const allBpIds = useMemo(
    () => Array.from(new Set([...sponsorBpIds, ...guarantorBpIds])),
    [guarantorBpIds, sponsorBpIds],
  )
  const isAllBpIdsPresent =
    !isNil(unitRelationships) && !isNil(guarantorPartnersData) && allBpIds.length > 0

  const {
    isFetching: isAllBusinessPartnersFetching,
    isError: isAllBusinessPartnersError,
    errors: allBusinessPartnersErrors,
    data: allBusinessPartners = {},
  } = useMultipleBusinessPartnersByIdAggregated({
    businessPartnerIds: allBpIds,
    options: {
      enabled: isAllBpIdsPresent,
      language,
    },
  })

  const {
    isFetching: isAggregatedFiscalYearsFetching,
    isError: isAggregatedFiscalYearsError,
    data: aggregatedFiscalYearsData,
    errors: aggregatedFiscalYearsErrors,
  } = useMultipleFiscalYearsAggregated({
    businessPartnerIds: allBpIds,
    options: { enabled: isAllBpIdsPresent },
  })

  const {
    isFetching: isAggregatedTextualAssessmentsFetching,
    isError: isAggregatedTextualAssessmentsError,
    data: aggregatedTextualAssessmentsData,
    errors: aggregatedTextualAssessmentsErrors,
  } = useMultipleTextualAssessmentsAggregated({
    businessPartnerIds: allBpIds,
    type: 'description',
    options: { enabled: isAllBpIdsPresent },
  })

  const {
    isFetching: isAggregatedAssessmentsFetching,
    isError: isAggregatedAssessmentsError,
    errors: aggregatedAssessmentsErrors,
    data: aggregatedAssessmentsData,
  } = useMultipleAssessmentsAggregated({
    businessPartnerIds: allBpIds,
    options: { enabled: isAllBpIdsPresent },
  })

  const {
    isFetching: isAggregatedRatingsFetching,
    isError: isAggregatedRatingsError,
    data: aggregatedRatingsData,
    errors: aggregatedRatingsErrors,
  } = useMultipleBusinessPartnerRatingsAggregated({
    businessPartnerIds: allBpIds,
    options: { enabled: isAllBpIdsPresent },
  })

  const guarantorRoleName = useMemo(
    () => guarantorPartnersData?.[0]?.businessPartnerFunctionName,
    [guarantorPartnersData],
  )
  const { name: sponsorRoleName } = useMemo(
    () => unitRelationshipTypesConfig.find(({ id }) => id === sponsorRelationshipId) ?? {},
    [sponsorRelationshipId, unitRelationshipTypesConfig],
  )

  const sortExternalRatingsByAgencyPriority = useCallback(
    (a, b) =>
      (ratingAgencyPriority.includes(a.agency.id)
        ? ratingAgencyPriority.indexOf(a.agency.id)
        : ratingAgencyPriority.length) -
      (ratingAgencyPriority.includes(b.agency.id)
        ? ratingAgencyPriority.indexOf(b.agency.id)
        : ratingAgencyPriority.length),
    [ratingAgencyPriority],
  )

  const getExternalRating = useCallback(
    (ratingsData) => {
      const today = DateTime.now()
      return (ratingsData?.external ?? [])
        .filter((rating) => {
          const validFromDate = DateTime.fromISO(rating.validFrom)
          const validToDate = DateTime.fromISO(rating.validTo)
          return validFromDate <= today && validToDate >= today
        })
        .slice()
        .sort(sortExternalRatingsByAgencyPriority)?.[0]
    },
    [sortExternalRatingsByAgencyPriority],
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isDealUuidFetching,
      isDealFetching,
      isUnitRelationshipsFetching,
      isFetchingUnitRelationshipTypesConfig,
      isGuarantorPartnersFetching,
      isAllBusinessPartnersFetching,
      isAggregatedFiscalYearsFetching,
      isAggregatedTextualAssessmentsFetching,
      isAggregatedAssessmentsFetching,
      isAggregatedRatingsFetching,
      isLoadingFinancialIndicatorHelper,
    ],
    errorValues: [
      isDealUuidError,
      isDealError,
      isUnitRelationshipsError,
      isErrorUnitRelationshipTypesConfig,
      isGuarantorPartnersError,
      isAllBusinessPartnersError,
      isAggregatedFiscalYearsError,
      isAggregatedTextualAssessmentsError,
      isAggregatedAssessmentsError,
      isAggregatedRatingsError,
    ],
    errorDetails: [
      ...dealUuidErrors,
      dealError,
      unitRelationshipsError,
      unitRelationshipTypesConfigError,
      ...(guarantorPartnersErrors ?? []),
      ...(allBusinessPartnersErrors ?? []),
      ...(aggregatedFiscalYearsErrors ?? []),
      ...(aggregatedTextualAssessmentsErrors ?? []),
      ...(aggregatedAssessmentsErrors ?? []),
      ...(aggregatedRatingsErrors ?? []),
    ],
    tileId,
  })

  const categorizedBusinessPartners = useMemo(() => {
    const categorizedTileInfo = {
      guarantors: [],
      sponsors: [],
      guarantorsAndSponsors: [],
    }

    for (const businessPartnerId of allBpIds) {
      /** @type {string} */
      const businessPartner = allBusinessPartners[businessPartnerId]
      if (isNil(businessPartner)) {
        break
      }
      const isGuarantor = guarantorBpIds.includes(businessPartnerId)
      const isSponsor = sponsorBpIds.includes(businessPartnerId)
      const businessPartnerRole = [
        ...(isSponsor ? [sponsorRoleName] : []),
        ...(isGuarantor ? [guarantorRoleName] : []),
      ].join('/')

      const assessmentData = aggregatedAssessmentsData?.[businessPartnerId]
      const appreciation = getCurrentAppreciationContent(
        aggregatedTextualAssessmentsData?.[businessPartnerId],
      )
      const fiscalData = getLatestFiscalData(aggregatedFiscalYearsData?.[businessPartnerId])
      const ratingData = aggregatedRatingsData?.[businessPartnerId]
      const internalRating = getInternalRating(ratingData)
      const externalRating = getExternalRating(ratingData)

      const businessPartnerTileInfo = {
        information: {
          businessPartnerId,
          name: businessPartner.fullName,
          legalForm: businessPartner.legalForm,
          foundationDate: businessPartner.foundationDate,
          industryInformation: businessPartner.industry,
          overallQualityAssessment: {
            category: assessmentData?.assessments?.[0]?.category,
            level: assessmentData?.assessments?.[0]?.level,
            levelDisplayValue: assessmentData?.assessments?.[0]?.levelDisplayValue,
          },
          businessPartnerRole,
        },
        address: isNil(businessPartner.address)
          ? undefined
          : {
              streetName: businessPartner.address.streetName,
              postalCode: businessPartner.address.postalCode,
              city: businessPartner.address.city,
              region: businessPartner.address.region,
              country: businessPartner.address.country,
            },
        internalRating: isNil(internalRating)
          ? undefined
          : {
              class: internalRating.ratingClass,
              method: internalRating.method?.name,
              validFrom: internalRating.validFrom,
            },
        externalRating: isNil(externalRating)
          ? undefined
          : {
              grade: externalRating.grade,
              agency: externalRating.agency?.name,
              validFrom: externalRating.validFrom,
            },
        financialIndicators: getFinancialIndicators(fiscalData),
        appreciation,
      }

      if (isGuarantor && isSponsor) {
        categorizedTileInfo.guarantorsAndSponsors.push(businessPartnerTileInfo)
      } else if (isGuarantor) {
        categorizedTileInfo.guarantors.push(businessPartnerTileInfo)
      } else if (isSponsor) {
        categorizedTileInfo.sponsors.push(businessPartnerTileInfo)
      }
    }

    const sponsors = sortBusinessPartnersInfo(categorizedTileInfo.sponsors)
    const guarantorsAndSponsors = sortBusinessPartnersInfo(
      categorizedTileInfo.guarantorsAndSponsors,
    )
    const guarantors = sortBusinessPartnersInfo(categorizedTileInfo.guarantors)

    return {
      sponsors,
      guarantorsAndSponsors,
      guarantors,
      isHidden:
        sponsors.length === 0 && guarantorsAndSponsors.length === 0 && guarantors.length === 0,
    }
  }, [
    aggregatedAssessmentsData,
    aggregatedFiscalYearsData,
    aggregatedRatingsData,
    aggregatedTextualAssessmentsData,
    allBpIds,
    allBusinessPartners,
    getExternalRating,
    guarantorBpIds,
    guarantorRoleName,
    sponsorBpIds,
    sponsorRoleName,
    getFinancialIndicators,
  ])

  return useMemo(() => {
    if (isErrorFinancialIndicatorHelper) {
      return {
        isLoading: isSomeValueLoading,
        isError: true,
        error: {
          isTileConfigError: isErrorFinancialIndicatorHelper,
          customErrorMessage: t('error.config'),
        },
      }
    }
    if (isSomeValueLoading || isSomeValueError) {
      return { isLoading: isSomeValueLoading, isError: isSomeValueError, error }
    }

    return {
      isLoading: false,
      isError: false,
      data: categorizedBusinessPartners,
    }
  }, [
    categorizedBusinessPartners,
    error,
    isSomeValueError,
    isSomeValueLoading,
    isErrorFinancialIndicatorHelper,
    t,
  ])
}
