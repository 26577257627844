import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import { ADDED } from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsUtils'
import { useRefreshSegments } from 'hooks/services/properties/segments/useRefreshSegments'

export const useSegmentsTableSaveHandler = ({
  propertyUuids,
  segmentsInEdit,
  createUpdateOrDeleteSegments,
  queryClient,
  setInEditMode,
  setIsMutating,
  setHasUnsavedChanges,
}) => {
  const { t } = useTranslation()

  const refreshSegments = useRefreshSegments(queryClient, propertyUuids, setIsMutating)

  const showToast = Modals.useShowToast()

  return () => {
    setIsMutating(true)
    const segmentsToCreateOrUpdate = segmentsInEdit.map((segment) => {
      if (segment.uuid.startsWith(ADDED)) {
        return {
          ...segment,
          uuid: undefined,
        }
      } else {
        return segment
      }
    })
    const initialPropertyUuidsToSegments = propertyUuids.reduce((acc, propertyUuid) => {
      acc[propertyUuid] = []
      return acc
    }, {})
    const propertyUuidsToSegments = segmentsToCreateOrUpdate.reduce((acc, segment) => {
      const { property_uuid, ...rest } = segment
      if (!acc[property_uuid]) {
        acc[property_uuid] = []
      }
      acc[property_uuid].push(rest)
      return acc
    }, initialPropertyUuidsToSegments)
    return createUpdateOrDeleteSegments(
      {
        propertyUuidsToSegments: propertyUuidsToSegments,
      },
      {
        onSuccess: () => {
          refreshSegments()
          setInEditMode(false)
          setHasUnsavedChanges(false)
          showToast({ children: t('pages.property.rent-roll.segments.edit.success') })
        },
        onError: () => {
          refreshSegments()
        },
      },
    )
  }
}
