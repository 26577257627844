import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * Returns all traceability allowed operations
 * The allowed operations structure is as follows:
 * {
 *   "allowed_operations": [
 *      'Change_Log_Read',
 *      [...]
 *   ]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: allowedOperations}}
 */

export const useChangeLogsPermissions = () =>
  useCamelizedResponse(
    useRequest({
      path: '/traceability/allowed-operations',
      useCache: true,
      keys: ['traceability', 'permissions'],
    }),
  )
