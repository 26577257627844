import isEmpty from 'lodash.isempty'
import orderBy from 'lodash.orderby'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import VersionsHistoryButton from 'components/ui/button/VersionsHistoryButton'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useManualTileVersions from 'hooks/services/business-events-and-tasks/decision-papers/tiles/manual/useManualTileVersions'
import {
  addVersions,
  showTileVersion,
  showCurrentTileVersion,
} from 'redux/slices/decision-paper/tilesOverviewSlice'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const VersionsHistoryButtonLoadingWrapper = ({ tileId }) => {
  const { t: t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.tiles.versions',
  })

  const dispatch = useDispatch()

  const {
    event: { id: eventId },
  } = useContext(BusinessEventsAndTasksContext)

  const {
    isLoading,
    isError,
    data: versionsHistoryData,
  } = useManualTileVersions({ eventId, tileId })

  useEffect(() => {
    if (versionsHistoryData) {
      dispatch(addVersions({ tileId, versions: versionsHistoryData.availableVersions }))
    }
  }, [versionsHistoryData, dispatch, tileId])

  const versions = useMemo(() => {
    if (isLoading || isError || isEmpty(versionsHistoryData?.availableVersions)) {
      return []
    }
    const sortedVersions = orderBy(
      versionsHistoryData.availableVersions,
      ['lastUpdatedAt'],
      ['desc'],
    )
    return sortedVersions.map(({ version, lastUpdatedAt, lastUpdatedBy }) => {
      const isCurrent = version === sortedVersions[0].version
      return {
        version,
        lastUpdatedBy,
        lastUpdatedAt,
        isCurrent,
        onItemClicked: () =>
          isCurrent
            ? dispatch(showCurrentTileVersion(tileId))
            : dispatch(showTileVersion({ tileId, displayedVersion: version })),
      }
    })
  }, [isError, isLoading, versionsHistoryData?.availableVersions, dispatch, tileId])

  const renderContent = useCallback(() => {
    if (versions.length === 0) {
      return
    }
    return (
      <VersionsHistoryButton versions={versions} buttonId={`versions-history-button-${tileId}`} />
    )
  }, [tileId, versions])

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      error={t('loading.error')}
      renderContent={renderContent}
    />
  )
}

VersionsHistoryButtonLoadingWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  currentTileVersion: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default VersionsHistoryButtonLoadingWrapper
