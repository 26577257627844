import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import uniq from 'lodash.uniq'
import { useCallback, useMemo } from 'react'
import {
  buildRowsDataRecursive,
  isSecondLevelHierarchy,
  trimRowsDeep,
  isFinancialProduct,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/businesspartner/shared/ArrearsUtil'
import { NO_DEAL } from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/businesspartner/shared/constants'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useMultipleDealsByUuidMini } from 'hooks/services/deals/useMultipleDealsByUuidMini'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'
import {
  APPROVAL_EVENT_STATUS,
  ARREAR_APPROVAL_STATUS,
  ARREARS_HEADQUARTER_CURRENCY,
  ENTITY_TYPES,
  OVERPAYMENT,
} from 'routes/business-partners/arrears/constants'
import { useBusinessPartnerArrears } from 'routes/business-partners/arrears/useBusinessPartnerArrears'
import set from 'utils/set'

const findApprovalItemByApprovalAndArrear = (approval, arrear) =>
  approval.arrearApprovalItems.find(
    (approvalItem) => approvalItem.arrearExternalId === arrear.externalId,
  )

const isArrearApproved = (arrear) =>
  arrear.approvalStatusList?.includes(ARREAR_APPROVAL_STATUS.APPROVED) ||
  arrear.approvalStatusList?.includes(ARREAR_APPROVAL_STATUS.PARTIALLY_APPROVED)

export const ROWHEIGHT_QUAD_CURRENCY = 98
export const ROWHEIGHT_DUAL_CURRENCY = 58
export const ROWHEIGHT_SINGLE_CURRENCY = 32

/**
 * -----------
 * This hook takes the neccessary data from the BusinessPartnerArrearsContext and transforms it
 * into table data rows in a tree structure with the calculated sums for the money values.
 *
 * The tree structures is as follows:
 *  -> Deal
 *    -> n Tranches
 *      -> (Up to 4 Arrears)
 *      -> n Drawings
 *        -> (Up to 4 Arrears)
 *  -> Deal
 *    -> n Tranches
 *      -> (Up to 4 Arrears)
 *      -> n Drawings
 *        -> (Up to 4 Arrears)
 *  -> Deal
 *    ...and so on
 */
const useCurrentArrearsTableData = ({ entityRef: { entityId } }, tileId) => {
  const {
    arrears,
    productHierarchy: productHierarchyFromContext,
    arrearApprovals: arrearApprovalsFromContext,
    arrearApprovalEvents: approvalEventsFromContext,
    products: productsFromContext,
    isLoading: isLoadingContext,
    isError: isErrorContext,
  } = useBusinessPartnerArrears(entityId)

  const findApprovalEventByApproval = (approval) =>
    approvalEventsFromContext.find((approvalEvent) => approvalEvent?.id === approval.eventId)

  const completedApprovals = arrearApprovalsFromContext?.filter((approval) => {
    const approvalEvent = findApprovalEventByApproval(approval)
    return approvalEvent?.status === APPROVAL_EVENT_STATUS.COMPLETED
  })

  const findCompletedApprovalByApprovedArrear = useCallback(
    (approvedArrear) =>
      completedApprovals.find(
        (approval) => !!findApprovalItemByApprovalAndArrear(approval, approvedArrear),
      ),
    [completedApprovals],
  )

  const findApprovedItemByArrearOrNull = useCallback(
    (arrear) => {
      if (!isArrearApproved(arrear)) return null

      const completedArrearApproval = findCompletedApprovalByApprovedArrear(arrear)
      if (!completedArrearApproval) return null

      return findApprovalItemByApprovalAndArrear(completedArrearApproval, arrear)
    },
    [findCompletedApprovalByApprovedArrear],
  )

  const findArrearsByRow = useCallback(
    ({ entityId: rowEntityId, secondaryEntityId, entityType }) =>
      arrears.filter(
        (arrear) =>
          (arrear.product.id === rowEntityId || arrear.product.id === secondaryEntityId) &&
          arrear.product.type.code === entityType,
      ),
    [arrears],
  )

  const extendArrearWithDaysOverdue = (arrear) => {
    const ONE_SECOND_IN_MS = 1000
    const ONE_HOUR_IN_SECONDS = 3600
    const ONE_DAY_IN_HOURS = 24
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const bookingDate = new Date(arrear.bookingDate)
    bookingDate.setHours(0, 0, 0, 0)
    const daysOverdue = Math.round(
      (today.getTime() - bookingDate.getTime()) /
        (ONE_SECOND_IN_MS * ONE_HOUR_IN_SECONDS * ONE_DAY_IN_HOURS),
    )
    return {
      ...arrear,
      daysOverdue,
    }
  }

  const extendRowDataWithArrearAndApprovalItem = useCallback(
    (row) => {
      const arrearsByRow = findArrearsByRow(row)
      arrearsByRow.forEach((arrear) => {
        const arrearApprovalItemOrNull = findApprovedItemByArrearOrNull(arrear)
        const arrearRow = {
          entityId: arrear.externalId,
          entityType: ENTITY_TYPES.ARREAR,
          rowData: {
            arrear: extendArrearWithDaysOverdue(arrear),
            arrearApprovalItem: arrearApprovalItemOrNull,
            product: {},
          },
          sumData: {},
          subRows: [],
        }
        row.subRows.push(arrearRow)
      })
    },
    [findApprovedItemByArrearOrNull, findArrearsByRow],
  )

  const getArrearCurrenciesFromSubRowsRecursive = useCallback((subRows, arrearCurrencies = []) => {
    subRows.forEach((subRow) => {
      if (subRow.entityType === ENTITY_TYPES.ARREAR) {
        arrearCurrencies.push(subRow.rowData.arrear.currency)
      }
      getArrearCurrenciesFromSubRowsRecursive(subRow.subRows, arrearCurrencies)
    })
    return arrearCurrencies
  }, [])

  const sumArrearDataHeadquarterAndOriginalRecursive = useCallback(
    (
      subRows,
      originalCurrency,
      arrearSumData = {
        arrearAmount: {
          headquarterSum: 0,
          originalSum: 0,
          originalCurrency: originalCurrency,
        },
        arrearAmountOwnShare: {
          headquarterSum: 0,
          originalSum: 0,
          originalCurrency: originalCurrency,
        },
        overpaymentAmount: {
          headquarterSum: 0,
          originalSum: 0,
          originalCurrency: originalCurrency,
        },
      },
    ) => {
      subRows.forEach((subRow) => {
        if (subRow.entityType === ENTITY_TYPES.ARREAR) {
          arrearSumData.arrearAmount.headquarterSum +=
            subRow.rowData.arrear.headquarterArrearAmount ?? 0
          arrearSumData.arrearAmount.originalSum += subRow.rowData.arrear.arrearAmount ?? 0
          arrearSumData.overpaymentAmount.headquarterSum +=
            subRow.rowData.arrear.headquarterOverpaymentAmount ?? 0
          arrearSumData.overpaymentAmount.originalSum +=
            subRow.rowData.arrear.overpaymentAmount ?? 0
          arrearSumData.arrearAmountOwnShare.headquarterSum +=
            subRow.rowData.arrear.headquarterOwnShare ?? 0
          arrearSumData.arrearAmountOwnShare.originalSum += subRow.rowData.arrear.ownShare ?? 0
          if (subRow.rowData.arrear.headquarterOwnShare !== null) {
            arrearSumData.arrearAmountOwnShare.hasOwnShareSum = true
          }
        }
        sumArrearDataHeadquarterAndOriginalRecursive(
          subRow.subRows,
          originalCurrency,
          arrearSumData,
        )
      })
      return arrearSumData
    },
    [],
  )

  const sumArrearDataHeadquarterOnlyRecursive = useCallback(
    (
      subRows,
      arrearSumData = {
        arrearAmount: {
          headquarterSum: 0,
          originalSum: null,
        },
        arrearAmountOwnShare: {
          headquarterSum: 0,
          originalSum: null,
        },
        overpaymentAmount: {
          headquarterSum: 0,
          originalSum: null,
        },
      },
    ) => {
      subRows.forEach((subRow) => {
        if (subRow.entityType === ENTITY_TYPES.ARREAR) {
          arrearSumData.arrearAmount.headquarterSum +=
            subRow.rowData.arrear.headquarterArrearAmount ?? 0
          arrearSumData.arrearAmountOwnShare.headquarterSum +=
            subRow.rowData.arrear.headquarterOwnShare ?? 0
          arrearSumData.overpaymentAmount.headquarterSum +=
            subRow.rowData.arrear.headquarterOverpaymentAmount ?? 0
          if (subRow.rowData.arrear.headquarterOwnShare !== null) {
            arrearSumData.arrearAmountOwnShare.hasOwnShareSum = true
          }
        }
        sumArrearDataHeadquarterOnlyRecursive(subRow.subRows, arrearSumData)
      })
      return arrearSumData
    },
    [],
  )

  const getCurrenciesForSubRowsRecursive = (row, getCurrenciesFn) => {
    const currencies = getCurrenciesFn(row.subRows)
    return uniq(currencies)
  }

  const isConstantCurrencyAndNotHeadquarter = (uniqueCurrencies) =>
    uniqueCurrencies.length === 1 && uniqueCurrencies[0] !== ARREARS_HEADQUARTER_CURRENCY

  const sumArrearDataRecursive = useCallback(
    (rowsData) => {
      rowsData.forEach((row) => {
        if (row.subRows.length > 0) {
          const uniqueCurrencies = getCurrenciesForSubRowsRecursive(
            row,
            getArrearCurrenciesFromSubRowsRecursive,
          )
          if (isConstantCurrencyAndNotHeadquarter(uniqueCurrencies)) {
            set(
              row,
              'sumData.arrear',
              sumArrearDataHeadquarterAndOriginalRecursive(row.subRows, uniqueCurrencies[0]),
            )
          } else {
            set(row, 'sumData.arrear', sumArrearDataHeadquarterOnlyRecursive(row.subRows))
          }
          sumArrearDataRecursive(row.subRows)
        }
      })
    },
    [
      getArrearCurrenciesFromSubRowsRecursive,
      sumArrearDataHeadquarterAndOriginalRecursive,
      sumArrearDataHeadquarterOnlyRecursive,
    ],
  )

  const sumArrearApprovalItemDataHeadquarterOnlyRecursive = useCallback(
    (
      subRows,
      arrearApprovalItemSumData = {
        approvalAmount: {
          headquarterSum: 0,
        },
      },
    ) => {
      subRows.forEach((subRow) => {
        if (subRow.entityType === ENTITY_TYPES.ARREAR) {
          arrearApprovalItemSumData.approvalAmount.headquarterSum +=
            subRow.rowData.arrearApprovalItem?.approvalAmount ?? 0
        }
        sumArrearApprovalItemDataHeadquarterOnlyRecursive(subRow.subRows, arrearApprovalItemSumData)
      })
      return arrearApprovalItemSumData
    },
    [],
  )

  const sumArrearApprovalItemDataHeadquarterAndOriginalRecursive = useCallback(
    (
      subRows,
      originalCurrency,
      arrearApprovalItemSumData = {
        approvalAmount: {
          headquarterSum: 0,
          originalSum: 0,
          originalCurrency: originalCurrency,
        },
      },
    ) => {
      subRows.forEach((subRow) => {
        if (subRow.entityType === ENTITY_TYPES.ARREAR) {
          arrearApprovalItemSumData.approvalAmount.headquarterSum +=
            subRow.rowData.arrearApprovalItem?.approvalAmount ?? 0
          arrearApprovalItemSumData.approvalAmount.originalSum +=
            subRow.rowData.arrearApprovalItem?.foreignApprovalAmount ?? 0
        }
        sumArrearApprovalItemDataHeadquarterAndOriginalRecursive(
          subRow.subRows,
          originalCurrency,
          arrearApprovalItemSumData,
        )
      })
      return arrearApprovalItemSumData
    },
    [],
  )

  const sumArrearApprovalItemDataRecursive = useCallback(
    (rowsData) => {
      rowsData.forEach((row) => {
        if (row.subRows.length > 0) {
          const uniqueCurrencies = getCurrenciesForSubRowsRecursive(
            row,
            getArrearCurrenciesFromSubRowsRecursive,
          )
          if (isConstantCurrencyAndNotHeadquarter(uniqueCurrencies)) {
            set(
              row,
              'sumData.arrearApprovalItem',
              sumArrearApprovalItemDataHeadquarterAndOriginalRecursive(
                row.subRows,
                uniqueCurrencies[0],
              ),
            )
          } else {
            set(
              row,
              'sumData.arrearApprovalItem',
              sumArrearApprovalItemDataHeadquarterOnlyRecursive(row.subRows),
            )
          }
          sumArrearApprovalItemDataRecursive(row.subRows)
        }
      })
    },
    [
      getArrearCurrenciesFromSubRowsRecursive,
      sumArrearApprovalItemDataHeadquarterAndOriginalRecursive,
      sumArrearApprovalItemDataHeadquarterOnlyRecursive,
    ],
  )

  const getProductDataCurrenciesFromSubRowsRecursive = useCallback(
    (subRows, currencyAccessor, currencies = []) => {
      subRows.forEach((subRow) => {
        if (isSecondLevelHierarchy(subRow)) {
          currencies.push(subRow.rowData.product?.[currencyAccessor])
        }
        getProductDataCurrenciesFromSubRowsRecursive(subRow.subRows, currencies)
      })
      return currencies
    },
    [],
  )

  const sumProductDataHeadquarterAndOriginalRecursive = useCallback(
    (
      subRows,
      originalCurrency,
      accessors,
      productSumData = {
        headquarterSum: 0,
        originalSum: 0,
        originalCurrency: originalCurrency,
      },
      isOwnSharePart,
    ) => {
      subRows.forEach((subRow) => {
        if (isSecondLevelHierarchy(subRow)) {
          productSumData.headquarterSum += subRow.rowData.product?.[accessors.headquarterField] ?? 0
          productSumData.originalSum += subRow.rowData.product?.[accessors.originalField] ?? 0
          if (subRow.rowData.product?.[accessors.headquarterField] !== null && isOwnSharePart) {
            productSumData.hasOwnShareSum = true
          }
        }
        sumProductDataHeadquarterAndOriginalRecursive(
          subRow.subRows,
          originalCurrency,
          accessors,
          productSumData,
          isOwnSharePart,
        )
      })
      return productSumData
    },
    [],
  )

  const sumProductDataHeadquarterOnlyRecursive = useCallback(
    (
      subRows,
      accessors,
      productSumData = {
        headquarterSum: 0,
        originalSum: null,
      },
      isOwnSharePart,
    ) => {
      subRows.forEach((subRow) => {
        if (isSecondLevelHierarchy(subRow)) {
          productSumData.headquarterSum += subRow.rowData.product?.[accessors.headquarterField] ?? 0
          if (subRow.rowData.product?.[accessors.headquarterField] !== null && isOwnSharePart) {
            productSumData.hasOwnShareSum = true
          }
        }
        sumProductDataHeadquarterOnlyRecursive(
          subRow.subRows,
          accessors,
          productSumData,
          isOwnSharePart,
        )
      })
      return productSumData
    },
    [],
  )

  const sumProductDataRecursive = useCallback(
    (rowsData, accessors, isOwnSharePart) => {
      rowsData.forEach((row) => {
        if (row.entityType === ENTITY_TYPES.DEAL) {
          const uniqueCurrencies = getCurrenciesForSubRowsRecursive(row, (rows) =>
            getProductDataCurrenciesFromSubRowsRecursive(rows, accessors.currency),
          )
          if (isConstantCurrencyAndNotHeadquarter(uniqueCurrencies)) {
            set(
              row,
              accessors.sumField,
              sumProductDataHeadquarterAndOriginalRecursive(
                row.subRows,
                uniqueCurrencies[0],
                accessors,
                isOwnSharePart,
              ),
            )
          } else {
            set(
              row,
              accessors.sumField,
              sumProductDataHeadquarterOnlyRecursive(row.subRows, accessors, isOwnSharePart),
            )
          }
          sumProductDataRecursive(row.subRows)
        }
      })
    },
    [
      getProductDataCurrenciesFromSubRowsRecursive,
      sumProductDataHeadquarterAndOriginalRecursive,
      sumProductDataHeadquarterOnlyRecursive,
    ],
  )

  const sumRowsData = useCallback(
    (rowsData) => {
      sumArrearDataRecursive(rowsData)
      sumArrearApprovalItemDataRecursive(rowsData)
      sumProductDataRecursive(rowsData, {
        currency: 'currentClosedCommitmentCurrency',
        sumField: 'sumData.product.currentClosedCommitment',
        headquarterField: 'headquarterCurrentClosedCommitment',
        originalField: 'currentClosedCommitment',
      })
      sumProductDataRecursive(rowsData, {
        currency: 'ownShareCurrency',
        sumField: 'sumData.product.currentClosedCommitmentOwnShare',
        headquarterField: 'headquarterCurrentClosedCommitmentOwnShare',
        originalField: 'currentClosedCommitmentOwnShare',
        isOwnSharePart: true,
      })
      sumProductDataRecursive(rowsData, {
        currency: 'ownShareCurrency',
        sumField: 'sumData.product.outstandingOwnShare',
        headquarterField: 'headquarterOutstandingOwnShare',
        originalField: 'outstandingOwnShare',
      })
      sumProductDataRecursive(rowsData, {
        currency: 'ownShareCurrency',
        sumField: 'sumData.product.availableOwnShare',
        headquarterField: 'headquarterAvailableOwnShare',
        originalField: 'availableOwnShare',
      })
    },
    [sumArrearApprovalItemDataRecursive, sumArrearDataRecursive, sumProductDataRecursive],
  )

  const isDealOrMultiStatus = (row) =>
    row.entityType === ENTITY_TYPES.DEAL || row.rowData.arrear?.approvalStatusList?.length > 1

  const checkAndSetRowHeight = useCallback((row) => {
    const isArrearRow = row.entityType === ENTITY_TYPES.ARREAR

    // Helper functions for checkAndSetRowHeight
    const hasArrearAmountOwnShare = (_row) => {
      if (isArrearRow) {
        return !isNil(row.rowData.arrear?.ownShare)
      } else {
        return !!row.sumData.arrear?.arrearAmountOwnShare?.hasOwnShareSum
      }
    }
    const hasCommitmentOwnShare = (_row) => {
      if (isFinancialProduct(_row.entityType)) {
        return !isNil(_row.rowData.product?.currentClosedCommitmentOwnShare)
      } else if (_row.entityType === ENTITY_TYPES.DEAL) {
        return !!row.sumData.product?.currentClosedCommitmentOwnShare?.hasOwnShareSum
      }
      return false
    }

    const isArrearForeignCurrency = isArrearRow
      ? row.rowData.arrear?.currency !== ARREARS_HEADQUARTER_CURRENCY
      : !isNil(row.sumData.arrear?.arrearAmount?.originalSum)
    const isCommitmentForeignCurrency =
      !isArrearRow && !isNil(row.sumData.product?.currentClosedCommitment?.originalSum)

    const hasOwnShare = hasArrearAmountOwnShare(row) || hasCommitmentOwnShare(row)
    const isForeignCurrency = isArrearForeignCurrency || isCommitmentForeignCurrency

    if (hasOwnShare && isForeignCurrency) {
      row.rowHeight = ROWHEIGHT_QUAD_CURRENCY
    } else if (isForeignCurrency || hasOwnShare || isDealOrMultiStatus(row)) {
      row.rowHeight = ROWHEIGHT_DUAL_CURRENCY
    } else {
      row.rowHeight = ROWHEIGHT_SINGLE_CURRENCY
    }
  }, [])

  const extendRowsWithRowHeight = useCallback(
    (rowsData) => {
      rowsData.forEach((row) => {
        checkAndSetRowHeight(row)
        extendRowsWithRowHeight(row.subRows)
      })
    },
    [checkAndSetRowHeight],
  )

  const shouldHaveSubRowsOrIsArrear = (row) =>
    !!row.subRows.length || row.entityType === ENTITY_TYPES.ARREAR

  const shouldNotBeOverpayment = (row) =>
    row.entityType !== ENTITY_TYPES.ARREAR || row.rowData.arrear.type.code !== OVERPAYMENT

  const tableData = useMemo(() => {
    const rowsData = buildRowsDataRecursive({
      businessPartnerId: entityId,
      products: productsFromContext,
      productHierarchy: productHierarchyFromContext,
      extendRowsWithAdditionalData: extendRowDataWithArrearAndApprovalItem,
    })
    sumRowsData(rowsData)
    extendRowsWithRowHeight(rowsData)
    const trimmedRowsWithOverpayments = trimRowsDeep(rowsData, shouldHaveSubRowsOrIsArrear)
    return trimRowsDeep(trimmedRowsWithOverpayments, shouldNotBeOverpayment)
  }, [
    entityId,
    extendRowDataWithArrearAndApprovalItem,
    extendRowsWithRowHeight,
    productHierarchyFromContext,
    productsFromContext,
    sumRowsData,
  ])

  const allDealUuids = useMemo(
    () => uniq(compact(tableData.map((row) => row.dealId)).filter((dealId) => dealId !== NO_DEAL)),
    [tableData],
  )

  const {
    data: allDealsData = [],
    isLoading: isMultipleDealsByUuidMiniLoading,
    isError: isMultipleDealsByUuidMiniError,
  } = useCombinedQueryResults(
    useMultipleDealsByUuidMini(allDealUuids, {
      enabled: allDealUuids.length > 0,
    }),
    { forceDataReturn: true },
  )

  const tableDataWithDealInfo = useMemo(
    () =>
      tableData.map((row) => {
        const { dealId, name } =
          allDealsData.filter(Boolean).find(({ dealUuid }) => dealUuid === row?.dealId) ?? {}
        return dealId
          ? {
              dealDisplayId: dealId,
              dealName: name,
              ...row,
            }
          : row
      }),
    [allDealsData, tableData],
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isLoadingContext, isMultipleDealsByUuidMiniLoading && allDealUuids.length > 0],
    errorValues: [isErrorContext, isMultipleDealsByUuidMiniError],
    errorDetails: [],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: isSomeValueLoading,
      isError: isSomeValueError,
      data: {
        tableData: tableDataWithDealInfo,
        arrearsCount: arrears.length,
        sourceRender: {
          businessPartnerId: entityId,
        },
      },
    }
  }, [arrears.length, entityId, error, isSomeValueError, isSomeValueLoading, tableDataWithDealInfo])
}

export default useCurrentArrearsTableData
