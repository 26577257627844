const startRequirementAddMode = (state) => {
  const initialValues = {
    name: undefined,
    condition: {},
    refNumber: undefined,
    assignee: undefined,
    documentType: undefined,
    status: {},
    description: undefined,
  }
  state.isRequirementAddMode = true
  state.editedRow = {
    initialValues: initialValues,
    currentValues: initialValues,
    changedFields: {},
    errorFields: { name: true, condition: true, documentType: true },
  }
}

export default startRequirementAddMode
