import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUnassignPropertiesToMarket = ({ onSuccess, onError }) => {
  const { delete: deleteCall } = useAccessTokenRequest()
  return useMutation(
    async ({ propertyIds, marketId }) => {
      let filterProperties = ''
      propertyIds.map((propertyId) => {
        filterProperties = filterProperties.concat(`&property_id=${propertyId}`)
      })

      const { data } = await deleteCall({
        path: `/markets/${marketId}/properties?${filterProperties.slice(1)}`,
      })
      return data
    },
    { onSuccess, onError },
  )
}

export default useUnassignPropertiesToMarket
