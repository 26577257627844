import { FileUploader } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DocumentIcon from 'components/domains/documents/DocumentIcon'
import styles from 'components/domains/documents/DocumentUpload.module.css'

const DocumentUpload = ({ fileName, fileType, fileSize, onHandleFileUpload, multiple = false }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.document-upload' })

  return (
    <div className={styles.background}>
      <div className={styles.flexbox}>
        <DocumentIcon format={fileType} className={styles.icon} />
        <div className={styles.documentInformation}>
          <div className={styles.titleFont}>{fileName}</div>
          <div className={styles.fileInformation}>
            {`${t('file-format')}: ${fileType} · ${t('file-size')}: ${fileSize}`}
          </div>
        </div>
      </div>
      <div className={styles.changeFile}>
        <FileUploader
          hideInput
          onChange={onHandleFileUpload}
          multiple={multiple}
          onClick={(e) => e.stopPropagation()} // HINT: I don't know why this is needed, but I don't care (only if rendering in the header...)
        >
          {t('change-file')}
        </FileUploader>
      </div>
    </div>
  )
}

export default DocumentUpload

DocumentUpload.propTypes = {
  initialState: PropTypes.shape({
    files: PropTypes.any,
  }),
  fileName: PropTypes.string,
  fileType: PropTypes.string.isRequired,
  fileSize: PropTypes.string.isRequired,
  file: PropTypes.any,
  multiple: PropTypes.bool,
  onHandleFileUpload: PropTypes.func,
}
