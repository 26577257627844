import PropTypes from 'prop-types'
import { useInfiniteRequest, useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const dealFilterPropTypes = PropTypes.shape({
  dealId: PropTypes.string,
  dealName: PropTypes.string,
  accountManager: PropTypes.string,
  businessSegments: PropTypes.string,
  dealTypes: PropTypes.string,
  originationPlatform: PropTypes.string,
  originationTeam: PropTypes.string,
  status: PropTypes.string,
  borrowerId: PropTypes.string,
  propertyUuids: PropTypes.arrayOf(PropTypes.string),
  workingVersion: PropTypes.string,
})

const DEFAULT_PAGINATION_DELTA = 50

const buildDealSearchQueryParams = ({
  filter: {
    dealId,
    dealName,
    accountManager,
    businessSegments,
    dealTypes,
    originationPlatform,
    originationTeam,
    status,
    borrowerId,
    propertyUuids,
    workingVersion,
  } = {},
  sort = {},
  pagination: { offset, limit } = {},
}) => {
  const queryParams = new URLSearchParams()

  if (sort.orderField && sort.orderDirection) {
    queryParams.append('order_direction', sort.orderDirection.toUpperCase())
    queryParams.append('order_field', sort.orderField.toUpperCase())
  }
  if (offset) {
    queryParams.append('offset', offset)
  }
  if (limit) {
    queryParams.append('limit', limit)
  }

  if (dealId) {
    queryParams.append('deal_id', dealId)
  }
  if (dealName) {
    queryParams.append('name', dealName)
  }
  if (accountManager) {
    queryParams.append('account_manager_user_id', accountManager)
  }
  if (businessSegments) {
    queryParams.append('business_segment', businessSegments)
  }
  if (dealTypes) {
    queryParams.append('deal_type', dealTypes)
  }
  if (originationPlatform) {
    queryParams.append('origination_platform', originationPlatform)
  }
  if (originationTeam) {
    queryParams.append('origination_team', originationTeam)
  }
  if (status) {
    queryParams.append('status', status)
  }
  if (borrowerId) {
    queryParams.append('borrower_bp_id', borrowerId)
  }
  if (propertyUuids?.length) {
    propertyUuids.forEach((propertyUuid) => {
      if (propertyUuid) {
        queryParams.append('property_uuid', propertyUuid)
      }
    })
  }
  if (workingVersion) {
    queryParams.append('working_version', workingVersion)
  }
  return queryParams
}

const useDeals = ({ filter = {}, sort = {}, pagination = {}, queryOptions = {} }) => {
  const queryParams = buildDealSearchQueryParams({ filter, sort, pagination })
  const result = useRequest({
    path: `/deals?${queryParams.toString()}`,
    translated: true,
    keys: ['deals', queryParams.toString()],
    options: queryOptions,
  })

  return useCamelizedResponse(result)
}

export const useDealsInfiniteRequest = ({
  filter = {},
  sort = {},
  paginationDelta = DEFAULT_PAGINATION_DELTA,
  queryOptions = {},
}) => {
  const queryParams = buildDealSearchQueryParams({ filter, sort })
  const result = useInfiniteRequest({
    path: `/deals`,
    queryParams,
    translated: true,
    limit: paginationDelta,
    keys: ['deals', queryParams.toString()],
    options: queryOptions,
  })
  return useCamelizedResponse(result)
}

export default useDeals
