import PropTypes from 'prop-types'
import TableWrappingTextCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/generic/TableWrappingTextCell'

const ConditionsTableTypeCell = ({ value: { typeName } }) => (
  <TableWrappingTextCell value={typeName} />
)

ConditionsTableTypeCell.propTypes = {
  value: PropTypes.shape({
    conditionId: PropTypes.string.isRequired,
    typeName: PropTypes.string.isRequired,
    typeCode: PropTypes.string.isRequired,
  }).isRequired,
  conditionType: PropTypes.string.isRequired,
}

export default ConditionsTableTypeCell
