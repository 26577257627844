import {
  DATA_TYPES,
  isEmptyOrNil,
} from 'components/domains/properties/multi-property-upload/MultiPropertyUpload'
import useMultiPropertyUploadColumns from 'hooks/services/properties/multi-property-upload/useMultiPropertyUploadColumns'
import set from 'utils/set'

/**
 * React hook that maps the given Excel rows to the property create payload, which is then consumed by the backend.
 * The objects are mapped through the accessors of the column definition given by the `useMultiPropertyUploadColumns` hook.
 * In this case, the German translated rows are used (has no deep reasoning).
 * Percentage values are multiplied by 100 and date-years are parsed to LocalDate year-01-01 to be accepted by the backend.
 *
 * @param {Array<PropertyExcelRow>} rows The Excel rows to map to the property create payload.
 * @returns {{
 *   isLoading: boolean,
 *   isError: boolean,
 *   data: Array<Property>
 * }}
 */
const useMultiPropertyUploadMapper = () => {
  const { isLoading, isError, data: columnDefinitions } = useMultiPropertyUploadColumns()

  const assertAndMapPercentage = (dataPassedByReference, columnDefinition) => {
    if (columnDefinition.type === DATA_TYPES.PERCENTAGE) {
      dataPassedByReference.mappedValue = dataPassedByReference.value * 100
    }
  }

  const assertAndMapDateYear = (dataPassedByReference, columnDefinition) => {
    if (columnDefinition.type === DATA_TYPES.DATE_YEAR) {
      dataPassedByReference.mappedValue = `${dataPassedByReference.value}-01-01`
    }
  }

  const assertAndMapBoolean = (dataPassedByReference, columnDefinition) => {
    if (columnDefinition.type === DATA_TYPES.BOOLEAN) {
      dataPassedByReference.mappedValue = Boolean(dataPassedByReference.value)
    }
  }

  return {
    isLoading,
    isError,
    mapRows: (excelContent) => {
      const germanHeaderRow = excelContent[0]
      const dataRows = excelContent.slice(2)
      const mappedProperties = []
      dataRows.forEach((row) => {
        const mappedProperty = {}
        for (let i = 0; i < germanHeaderRow.length; i++) {
          const header = germanHeaderRow[i]
          const data = {
            value: row[i],
            mappedValue: row[i],
          }
          const columnDefinition = columnDefinitions.find((c) => c.de === header)
          if (!columnDefinition || isEmptyOrNil(data.value)) {
            continue
          }
          assertAndMapPercentage(data, columnDefinition)
          assertAndMapDateYear(data, columnDefinition)
          assertAndMapBoolean(data, columnDefinition)
          set(mappedProperty, columnDefinition.accessor, data.mappedValue)
        }
        mappedProperties.push(mappedProperty)
      })
      return mappedProperties
    },
  }
}
export default useMultiPropertyUploadMapper
