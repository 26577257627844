import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useBoolOptions = () => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.forbearance-and-utp-questionare.options.bool-options',
  })

  return useMemo(
    () => [
      {
        key: '-',
        value: '-',
        displayValue: t('empty-value'),
      },
      {
        key: 'yes',
        value: 'yes',
        displayValue: t('yes'),
      },
      {
        key: 'no',
        value: 'no',
        displayValue: t('no'),
      },
    ],
    [t],
  )
}

export default useBoolOptions
