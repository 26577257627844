// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dQcM0Mx0a1F213Ri8Zxx {
  width: 100%;
}

.xSIZCVnGNFsJVuMAWTTl {
  padding-bottom: 16px;
  border-top: 1px solid #d9d9d9;
  margin-top: 10px;
}

.WBRoPUzBi0FQZjKlvBoR {
  color: var(--sapGroup_TitleTextColor);
  padding-left: 16px;
  padding-bottom: 16px;
}

.FDexBsxw2AzDkYfaXHAZ {
  color: var(--sapGroup_TitleTextColor);
  font-weight: bold;
  padding-left: 16px;
  padding-bottom: 8px;
}

.viMOlrswbP1xLK1290wZ {
  padding-top: 16px;
  padding-bottom: 16px;

  & ui5-input,
  & ui5-textarea,
  & ui5-select,
  & ui5-date-picker,
  & ui5-daterange-picker,
  & ui5-checkbox,
  & ui5-combobox,
  & ui5-multi-combobox {
    margin: var(--_ui5_input_margin_top_bottom) 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/card/EditCardView.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,oBAAoB;EACpB,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;EACE,qCAAqC;EACrC,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,qCAAqC;EACrC,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;;EAEpB;;;;;;;;IAQE,6CAA6C;EAC/C;AACF","sourcesContent":[".datePicker {\n  width: 100%;\n}\n\n.edit-title-separator {\n  padding-bottom: 16px;\n  border-top: 1px solid #d9d9d9;\n  margin-top: 10px;\n}\n\n.displayAndEditSubtitle {\n  color: var(--sapGroup_TitleTextColor);\n  padding-left: 16px;\n  padding-bottom: 16px;\n}\n\n.displayAndEditTitle {\n  color: var(--sapGroup_TitleTextColor);\n  font-weight: bold;\n  padding-left: 16px;\n  padding-bottom: 8px;\n}\n\n.card-edit-mode-wrapper {\n  padding-top: 16px;\n  padding-bottom: 16px;\n\n  & ui5-input,\n  & ui5-textarea,\n  & ui5-select,\n  & ui5-date-picker,\n  & ui5-daterange-picker,\n  & ui5-checkbox,\n  & ui5-combobox,\n  & ui5-multi-combobox {\n    margin: var(--_ui5_input_margin_top_bottom) 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"datePicker": `dQcM0Mx0a1F213Ri8Zxx`,
	"edit-title-separator": `xSIZCVnGNFsJVuMAWTTl`,
	"displayAndEditSubtitle": `WBRoPUzBi0FQZjKlvBoR`,
	"displayAndEditTitle": `FDexBsxw2AzDkYfaXHAZ`,
	"card-edit-mode-wrapper": `viMOlrswbP1xLK1290wZ`
};
export default ___CSS_LOADER_EXPORT___;
