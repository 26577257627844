import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import TableWrappingTextCell from 'components/domains/conditions/overview/table-cells/generic/TableWrappingTextCell'
import EditableDatePickerComponent from 'components/ui/input/EditableDatePickerComponent'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useRequirementsCellAddMode from 'hooks/services/conditions/edit-mode/useRequirementsCellAddMode'
import useRequirementsCellEditMode from 'hooks/services/conditions/edit-mode/useRequirementsCellEditMode'
import { requirementDueDateChanged } from 'redux/slices/conditions/requirementsTableSlice'

const RequirementsTableDueDateSubcomponent = ({ dueDate, requirementId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.subcomponents.due-date',
  })
  const { format: formatDate, localePattern } = useShortDateFormatter()
  const dispatch = useDispatch()
  const { isAddModeForCurrentRow } = useRequirementsCellAddMode({
    requirementId,
  })
  const { isEditModeForCurrentRow } = useRequirementsCellEditMode({ requirementId })
  const isCurrentRowInAddOrEditMode = isAddModeForCurrentRow || isEditModeForCurrentRow

  const onChangeDueDate = useCallback(
    ({ target: { liveValue: newDueDate } }) => {
      dispatch(requirementDueDateChanged({ dueDate: newDueDate }))
    },
    [dispatch],
  )
  const onFocus = useCallback((event) => {
    event.stopPropagation()
  }, [])

  const viewModeComponent = <TableWrappingTextCell value={formatDate(dueDate)} />
  const editModeComponent = (
    <EditableDatePickerComponent
      onChange={onChangeDueDate}
      onFocus={onFocus}
      value={dueDate}
      editable={true}
      placeholder={localePattern}
      formatPattern={localePattern}
    />
  )

  return (
    <div>
      <Label>{t('label')}</Label>
      <div>{isCurrentRowInAddOrEditMode ? editModeComponent : viewModeComponent}</div>
    </div>
  )
}

RequirementsTableDueDateSubcomponent.propTypes = {
  dueDate: PropTypes.string,
  requirementId: PropTypes.string,
}

export default RequirementsTableDueDateSubcomponent
