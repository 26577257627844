import { FilterGroupItem, Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DashboardFilterBar from 'components/ui/page/DashboardFilterBar'
import useDealMini from 'hooks/services/deals/useDealMini'
import DealInput from 'routes/deals/collaterals/DealInput'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'

export const TrancheFilterKeys = {
  Deal: 'deal_id',
  BusinessType: 'business_type',
}

const filterOptions = {
  [TrancheFilterKeys.Deal]: { visibleOnBar: true },
  [TrancheFilterKeys.BusinessType]: { visibleOnBar: true },
}

const businessTypeOptions = [
  [DATA_SOURCES.EXISTING_BUSINESS, 'existing-business'],
  [DATA_SOURCES.NEW_BUSINESS, 'new-business'],
]

const defaultVisibleFilters = Object.keys(filterOptions) //default is to show all filters

/**
 * TrancheFilterBar
 *
 * @param isOpen: bool // external info used to reset to initial filter values (optional)
 * @param initialValues: {[key in TrancheFilterKeys]: string }
 * @param visibleFilters: null | Array<TrancheFilterKeys>
 * @param readOnlyFilters: null | Array<TrancheFilterKeys>
 * @param onGo: Func
 * @returns {JSX.Element}
 * @constructor
 */
export const TrancheFilterBar = ({
  isOpen,
  initialValues,
  visibleFilters = defaultVisibleFilters,
  readOnlyFilters = [],
  onGo: onGoExternal = () => {},
  additionalFilterBarProperties = {},
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.financing.tranche-search-dialog',
  })

  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false)

  //set and reset filter params when dialog opens/closes
  const [filterParams, setFilterParams] = useState(initialValues ?? {})
  useEffect(() => {
    if (initialValues !== undefined) setFilterParams(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const dealId = filterParams[TrancheFilterKeys.Deal]
  const { data: deal } = useDealMini(dealId)

  const onGo = useCallback(() => {
    onGoExternal({ ...filterParams })
  }, [filterParams, onGoExternal])

  const updateFilterParams = useCallback(
    (key, value) => {
      const newValues = {
        ...filterParams,
        [key]: value,
      }
      if (
        newValues[TrancheFilterKeys.Deal] &&
        newValues[TrancheFilterKeys.BusinessType] &&
        newValues[TrancheFilterKeys.Deal] !== filterParams[TrancheFilterKeys.Deal]
      ) {
        onGoExternal(newValues)
      }
      setFilterParams((previousValue) => ({
        ...previousValue,
        [key]: value,
      }))
    },
    [filterParams, onGoExternal],
  )

  const handleKeyDown = (event) => {
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && !isFilterDialogOpen) {
      event.stopPropagation()
      onGo()
    }
  }

  const handleDealChange = useCallback(
    (newDeal) => {
      updateFilterParams(TrancheFilterKeys.Deal, newDeal?.id)
    },
    [updateFilterParams],
  )
  const handleBusinessTypeChange = useCallback(
    ({
      detail: {
        selectedOption: { value: businessType },
      },
    }) => {
      updateFilterParams(TrancheFilterKeys.BusinessType, businessType)
    },
    [updateFilterParams],
  )

  const getActiveFiltersCount = () =>
    filterParams ? Object.entries(filterParams).filter(([, value]) => !!value).length : 0

  const isVisibleFilter = (key) => visibleFilters?.includes(key) ?? true
  const isActiveFilter = (key) => Object.keys(filterParams).includes(key) && isVisibleFilter(key)

  const buildFilters = () => {
    const allFilterDefinitions = [
      <FilterGroupItem
        required
        label={t('filter.deal')}
        key={TrancheFilterKeys.Deal}
        active={isActiveFilter(TrancheFilterKeys.Deal)}
      >
        <DealInput
          value={deal && { id: deal.dealUuid, displayId: deal.dealId, name: deal.name }}
          isAllowedToEdit
          onKeyDown={handleKeyDown}
          onChange={handleDealChange}
          initialSearch={deal?.name}
          disabled={readOnlyFilters.includes(TrancheFilterKeys.Deal)}
        />
      </FilterGroupItem>,

      <FilterGroupItem
        required
        label={t('filter.data-source')}
        key={TrancheFilterKeys.BusinessType}
        active={isActiveFilter(TrancheFilterKeys.BusinessType)}
      >
        <Select
          onKeyDown={handleKeyDown}
          disabled={readOnlyFilters.includes(TrancheFilterKeys.BusinessType)}
          onChange={handleBusinessTypeChange}
          value={filterParams[TrancheFilterKeys.BusinessType]}
        >
          {[
            <Option key={-1} value={''} selected={!filterParams[TrancheFilterKeys.BusinessType]} />,
            ...businessTypeOptions.map(([key, translationKey]) => (
              <Option
                key={`entity-type-${key}`}
                value={key}
                selected={filterParams[TrancheFilterKeys.BusinessType] === key}
              >
                {t(`filter.data-source.${translationKey}`)}
              </Option>
            )),
          ]}
        </Select>
      </FilterGroupItem>,
    ]

    // calculate available filters
    return allFilterDefinitions.filter((filter) => isVisibleFilter(filter.key))
  }

  return (
    <DashboardFilterBar
      filterOptions={filterOptions}
      activeFiltersCount={getActiveFiltersCount()}
      onGo={onGo}
      setDialogOpen={setIsFilterDialogOpen}
      additionalProperties={additionalFilterBarProperties}
    >
      {buildFilters()}
    </DashboardFilterBar>
  )
}

TrancheFilterBar.propTypes = {
  isOpen: PropTypes.bool,
  initialValues: PropTypes.object,
  visibleFilters: PropTypes.arrayOf(PropTypes.oneOf(Object.values(TrancheFilterKeys))),
  readOnlyFilters: PropTypes.arrayOf(PropTypes.oneOf(Object.values(TrancheFilterKeys))),
  onGo: PropTypes.func,
  additionalFilterBarProperties: PropTypes.object,
}
