import { BusyIndicator, BusyIndicatorSize } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/ui/loading/RequestStateResolver.module.css'

const propTypes = {
  /** state of request */
  isLoading: PropTypes.bool.isRequired,
  /** state of request */
  isError: PropTypes.bool.isRequired,
  /** triggered when isLoading and isError are false */
  renderContent: PropTypes.func.isRequired,
  /** shown when isError is true */
  errorToDisplay: PropTypes.node.isRequired,
  busyIndicatorSize: PropTypes.oneOf([BusyIndicatorSize.Medium, BusyIndicatorSize.Small]),
  /** is the translated text to be displayed underneath the busy indicator, defaults to null */
  busyIndicatorText: PropTypes.string,
  /** indicates whether the busy indicator and error should render centered in the full width and height of the parent container */
  center: PropTypes.bool,
}

/**
 * Helper component for resolving request states in a consistent way.
 *
 * @typedef {object} overrides
 * @property {('Small'|'Medium')?} [overrides.busyIndicatorSize]  defaults to 'Medium'
 * @param {Omit<PropTypes.InferProps<propTypes>, keyof overrides> & overrides} props
 */
const RequestStateResolver = ({
  isLoading,
  isError,
  errorToDisplay,
  renderContent,
  busyIndicatorSize,
  busyIndicatorText = null,
  center = false,
}) => {
  if (isLoading)
    return (
      <div className={center ? styles.center : ''}>
        <BusyIndicator
          active
          text={busyIndicatorText ?? ''}
          size={busyIndicatorSize ?? BusyIndicatorSize.Medium}
        />
      </div>
    )
  if (isError) return <div className={center ? styles.center : ''}>{errorToDisplay}</div>

  return renderContent()
}

RequestStateResolver.propTypes = propTypes

export { RequestStateResolver }
