import { useFormMapped } from 'hooks/form/useFormMapped'
import { useStaffMemberSelf } from 'hooks/services/business-partners/staff-members/useStaffMemberSelf'
import { useBusinessSegments } from 'hooks/services/deals/configurations/useBusinessSegments'

const staffMemberAccountManagerMixins = {
  relation: { internalDealTeamRelation: 'ACCOUNT_MANAGER' },
  leader: true,
}

export const useGeneralInformationDefaults = () => {
  const { data: currentUser } = useStaffMemberSelf()
  const { data: businessSegments = [] } = useBusinessSegments()
  const { setValue: setDealProperty } = useFormMapped()
  return () => {
    const defaultBusinessSegment = businessSegments[0] ?? {}
    setDealProperty('dealTeam.staffMembers', [
      { staffMember: currentUser ?? {}, ...staffMemberAccountManagerMixins },
    ])
    setDealProperty('businessSegmentCode', defaultBusinessSegment.code)
    setDealProperty('tranche.productType', defaultBusinessSegment.loanProductType)
  }
}
