import { useRequest } from 'hooks/services/baseService'

/**
 * Returns all property area measure unit codes and their localized display name
 * The Typecodes structure is as follows:
 * // TODO: Add example
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: AreaMeasureUnitCodes}}
 *
 */
export const useAreaMeasureUnitCodes = (options = {}) =>
  useRequest({
    path: `/properties/measure-unit-codes?type=area`,
    translated: true,
    options,
  })

export const unitCodeIsPieces = (unitCode) => unitCode === 'PC' || unitCode === 'ST'

export const useAreaMeasureUnitPiecesCode = () => {
  const { data: areaMeasureUnitCodesData } = useAreaMeasureUnitCodes()

  const piecesUnit = areaMeasureUnitCodesData?.measure_unit_codes?.find((code) =>
    unitCodeIsPieces(code.key),
  )?.key

  return piecesUnit
}
