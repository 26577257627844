import {
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { decisionStageStatus as possibleDecisionStageStatus } from 'api/decision-process/decisionProcessApi'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import useDecisionStageName from 'components/domains/business-events-and-tasks/common/useDecisionStageName'
import renderDecisionPaper from 'components/domains/business-events-and-tasks/decision-paper/functions/renderDecisionPaper'
import DecisionPaperReduxStateLoaderWrapper from 'components/domains/business-events-and-tasks/decision-paper/redux-loader/DecisionPaperReduxStateLoaderWrapper'
import DecisionStageApprovalCardView from 'components/domains/business-events-and-tasks/decision-stage/approval/DecisionStageApprovalCardView'
import DecisionStageApprovalVoterHeader from 'components/domains/business-events-and-tasks/decision-stage/approval/voter/DecisionStageApprovalVoterHeader'
import useDecisionStageApproval from 'hooks/services/business-events-and-tasks/decision-process/stages/approval/useDecisionStageApproval'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import DecisionPaperContext from 'routes/business-events-and-tasks/decision-paper/DecisionPaperContext'
import DecisionPaperPage from 'routes/business-events-and-tasks/decision-paper/DecisionPaperPage'
import DecisionPaperAttachmentsDialog from 'routes/business-events-and-tasks/decision-paper/attachments/DecisionPaperAttachmentsDialog'
import { getLatestDecisionPaperSyncAndFreezeDates } from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperLastSyncDateUtils'

const DecisionStageApproval = () => {
  const {
    event: { id: eventId },
    allowedOperations,
  } = useContext(BusinessEventsAndTasksContext)
  const { decisionStageId, voterId } = useParams()
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-stage.approval',
  })
  const [isAttachmentsDialogOpen, setIsAttachmentsDialogOpen] = useState(false)

  const hasVoteUpdatePermission = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.updateDecisionStageVote),
    [allowedOperations],
  )
  const {
    isLoading,
    isError,
    data: decisionStageApprovalData,
  } = useDecisionStageApproval(
    { eventId, decisionStageId, voterId },
    { enabled: hasVoteUpdatePermission },
  )

  const decisionStageName = useDecisionStageName({
    name: decisionStageApprovalData?.decisionStageData.info.name,
    decisionStageType: decisionStageApprovalData?.decisionStageData.info.decisionType,
  })

  const decisionStageInWrongStatus =
    decisionStageApprovalData?.decisionStageData.info.decisionStatus ===
      possibleDecisionStageStatus.planned ||
    decisionStageApprovalData?.decisionStageData.info.decisionStatus ===
      possibleDecisionStageStatus.inProgressRequestDiscarded

  const userIsNotAVoter = !decisionStageApprovalData?.userVote

  const hideVoterPage = decisionStageInWrongStatus || userIsNotAVoter || !hasVoteUpdatePermission

  const decisionPaperVersion = useMemo(
    () => decisionStageApprovalData?.decisionStageData.decisionPaper?.version,
    [decisionStageApprovalData?.decisionStageData.decisionPaper?.version],
  )

  const decisionPaperData = useMemo(
    () => decisionStageApprovalData?.decisionPaperData,
    [decisionStageApprovalData?.decisionPaperData],
  )

  const { latestMajorVersionSyncDate, latestMinorVersionSyncDate, frozenDate } = useMemo(() => {
    if (!decisionStageApprovalData?.decisionPaperData) return {}

    return getLatestDecisionPaperSyncAndFreezeDates(
      decisionStageApprovalData?.decisionPaperData?.availableVersions,
      decisionPaperVersion,
    )
  }, [decisionPaperVersion, decisionStageApprovalData])

  const { content, isSingleSectionAndSubsection } = useMemo(() => {
    if (isLoading || isError) {
      return { content: <div />, isSingleSectionAndSubsection: true }
    }
    if (decisionStageInWrongStatus) {
      return {
        content: (
          <IllustratedMessage
            titleText={t('wrong-status.title')}
            subtitleText={t('wrong-status.subtitle')}
            name={IllustrationMessageType.NoTasks}
            size={IllustrationMessageSize.Scene}
          />
        ),
        isSingleSectionAndSubsection: true,
      }
    }
    if (userIsNotAVoter) {
      return {
        content: (
          <IllustratedMessage
            titleText={t('wrong-user.title')}
            subtitleText={t('wrong-user.subtitle')}
            name={IllustrationMessageType.NoTasks}
            size={IllustrationMessageSize.Scene}
          />
        ),
        isSingleSectionAndSubsection: true,
      }
    }
    return renderDecisionPaper({
      decisionPaper: decisionStageApprovalData.decisionPaperData,
      showConfirmationForLinks: true,
    })
  }, [
    isLoading,
    isError,
    decisionStageApprovalData,
    decisionStageInWrongStatus,
    userIsNotAVoter,
    t,
  ])

  if (!hasVoteUpdatePermission) {
    return (
      <IllustratedMessage
        titleText={tNoPrefix('app.permission-error.title')}
        subtitleText={tNoPrefix('app.permission-error.subtitle')}
        name={IllustrationMessageType.UnableToLoad}
        size={IllustrationMessageSize.Spot}
      />
    )
  }

  const headerTitle = (
    <DecisionStageApprovalVoterHeader
      voterId={voterId}
      hideVoterPage={hideVoterPage}
      decisionStageVoterId={decisionStageApprovalData?.userVote?.id}
      voterStatusCode={decisionStageApprovalData?.userVote?.status.code}
      decisionPaperVersion={decisionStageApprovalData?.decisionStageData.decisionPaper?.version}
      decisionStageId={decisionStageApprovalData?.decisionStageData.id}
      setIsAttachmentsDialogOpen={setIsAttachmentsDialogOpen}
    />
  )
  const headerContent = !hideVoterPage && (
    <DecisionStageApprovalCardView
      decisionPaperVersion={decisionStageApprovalData.decisionStageData.decisionPaper.version}
      frozenOn={frozenDate}
      majorVersionSyncDate={latestMajorVersionSyncDate}
      minorVersionSyncDate={latestMinorVersionSyncDate}
      decisionStageName={decisionStageName}
      decisionStageStatus={decisionStageApprovalData.decisionStageData.info.decisionStatus}
      voterStatus={decisionStageApprovalData.userVote.status.code}
      declineReason={decisionStageApprovalData.userVote.declineReason}
      decisionDate={decisionStageApprovalData.decisionStageData.info.decisionDate}
      templateName={decisionStageApprovalData.decisionPaperData.name}
      isTemplateCustomized={decisionStageApprovalData.decisionPaperData.isCustomized}
    />
  )

  return (
    <DecisionPaperContext.Provider value={{ decisionPaper: decisionPaperData }}>
      <DecisionPaperPage
        isLoading={isLoading}
        isError={isError}
        isEmpty={isLoading || isError || hideVoterPage}
        headerTitle={headerTitle}
        headerContent={headerContent}
        displayTabBar={!isSingleSectionAndSubsection}
      >
        {content}
      </DecisionPaperPage>
      {!hideVoterPage && (
        <DecisionPaperReduxStateLoaderWrapper
          decisionPaper={decisionStageApprovalData.decisionPaperData}
        />
      )}
      <DecisionPaperAttachmentsDialog
        decisionPaperVersion={decisionPaperVersion}
        isOpen={isAttachmentsDialogOpen}
        setIsOpen={setIsAttachmentsDialogOpen}
      />
    </DecisionPaperContext.Provider>
  )
}

DecisionStageApproval.propTypes = {}

export default DecisionStageApproval
