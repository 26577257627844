import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { propertyAllowedOperations } from 'api/property/propertyAllowedOperations'
import PropertyPortfolioValuationsRequestsCard from 'components/domains/properties/portfolio/valuation-requests/PropertyPortfolioValuationsRequestsCard'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import Page from 'components/ui/page/Page'
import { PropertyPortfolioContext } from 'routes/properties/portfolio/PropertyPortfolioContext'

const PropertyPortfolioValuationRequests = ({ header }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.properties' })
  const { allowedOperations } = useContext(PropertyPortfolioContext)
  const hasPropertyValuationReadPermission = useMemo(
    () => allowedOperations.includes(propertyAllowedOperations.propertyValuationRead),
    [allowedOperations],
  )

  if (!hasPropertyValuationReadPermission)
    return (
      <CenteredIllustratedMessage
        name="UnableToLoad"
        size="Spot"
        titleText={t('permissions.error.title')}
        subtitleText={t('permissions.error.sub-title')}
      />
    )

  return (
    <Page
      isLoading={false}
      isError={false}
      renderHeaderTitle={() => header}
      renderContent={() => <PropertyPortfolioValuationsRequestsCard />}
    />
  )
}

PropertyPortfolioValuationRequests.propTypes = {
  header: PropTypes.node.isRequired,
}

export default PropertyPortfolioValuationRequests
