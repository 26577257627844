import { Label, Link } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import styles from 'components/domains/properties/properties-search/table/PropertyMarketName.module.css'
import useMarket from 'hooks/services/markets/useMarket'

const PropertyMarketName = ({ marketId }) => {
  const { isLoading, isError, error, data: market } = useMarket(marketId)
  const { t } = useTranslation()

  const renderErrorLabel = (value) => (
    <Label className={styles.errorLabel} wrappingType="Normal">
      {value}
    </Label>
  )

  if (isLoading) return <></>
  if (isError && isMissingPermissionError(error))
    return renderErrorLabel(t('pages.properties.access-denied'))
  if (isError && isNotFoundError(error)) return renderErrorLabel(t('pages.properties.data-error'))
  if (isError) return renderErrorLabel(t('pages.properties.service-error'))

  const marketNotExist = !market || !Object.keys(market).length

  if (marketNotExist) return renderErrorLabel(t('pages.properties.data-error'))

  const marketName = market.info.name
  const marketUrl = `/markets/${market.id}`

  const handleOnClick = (e) => {
    e.stopPropagation()
  }

  return (
    <Link href={marketUrl} target="_blank" rel="noreferrer" onClick={handleOnClick}>
      <b>{marketName}</b>
    </Link>
  )
}

PropertyMarketName.propTypes = {
  marketId: PropTypes.string.isRequired,
}

export default PropertyMarketName
