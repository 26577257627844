import {
  Button,
  ButtonDesign,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import usePropertyDeleteValuation from 'hooks/services/properties/valuations/usePropertyDeleteValuation'

const propTypes = {
  disabled: PropTypes.bool,
  valuationRequestId: PropTypes.string.isRequired,
  propertyId: PropTypes.string.isRequired,
  children: PropTypes.node,
  onDeleteSuccess: PropTypes.func,
}

/**
 *  @param {PropTypes.InferProps<typeof propTypes>} props
 */
const ValuationRequestsTableDeleteButton = ({
  valuationRequestId,
  propertyId,
  disabled,
  children,
  onDeleteSuccess,
  ...props
}) => {
  const showMessageBox = Modals.useShowMessageBox()
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property.valuation.requests' })
  const { t: tNoPrefix } = useTranslation()

  const { mutate } = usePropertyDeleteValuation({
    onSuccess: () => {
      onDeleteSuccess?.()
    },
    onError: () => {
      showMessageBox({
        id: 'delete-error',
        type: MessageBoxTypes.Error,
        titleText: t('delete-error.title'),
        actions: [MessageBoxActions.Retry, MessageBoxActions.Cancel],
        emphasizedAction: MessageBoxActions.Retry,
        onClose: ({ detail: { action } }) => {
          if (action !== MessageBoxActions.Retry) {
            return
          }
          mutate({ propertyUuid: propertyId, valuationRequestId })
        },
      })
    },
  })

  const handleDeleteRow = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Warning,
      titleText: t('table.delete.confirmation-message.title'),
      children: t('table.delete.confirmation-message.description'),
      actions: [
        <Button
          key="delete-confirm-button"
          id="delete-confirm-button"
          data-action={MessageBoxActions.Yes}
          design={ButtonDesign.Emphasized}
        >
          {tNoPrefix('buttons.delete')}
        </Button>,
        MessageBoxActions.Cancel,
      ],
      onClose: ({ detail: { action } }) => {
        if (action === MessageBoxActions.Yes) {
          mutate({ propertyUuid: propertyId, valuationRequestId })
        }
      },
      id: 'delete-confirm',
    })
  }, [mutate, showMessageBox, t, tNoPrefix, propertyId, valuationRequestId])

  return (
    <Button
      disabled={!!disabled}
      design={ButtonDesign.Transparent}
      onClick={handleDeleteRow}
      icon="delete"
      {...props}
    >
      {children}
    </Button>
  )
}

ValuationRequestsTableDeleteButton.propTypes = propTypes

export default ValuationRequestsTableDeleteButton
