import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/RichTextTile.module.css'
import { toolbarConfigWithImages } from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/shared/CWPCKEditorToolbarConfigurations'
import CollapsibleRichTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/shared/CollapsibleRichTextView'
import FormattedTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/shared/FormattedTextView'
import CWPCKEditor from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/shared/editor/CWPCKEditor'

const DEFAULT_COLLAPSIBLE_CHARACTER_LIMIT = 500

const RichTextTile = ({
  isEditMode,
  isPdfView,
  isPdfViewCollapsible = false,
  collapsibleCharacterLimit = DEFAULT_COLLAPSIBLE_CHARACTER_LIMIT,
  currentContent,
  onChange,
  maxCharacterAmount = undefined,
  ...otherProps
}) => {
  const content = currentContent ?? ''

  if (isEditMode && !isPdfView) {
    return (
      <div className={styles.editor}>
        <CWPCKEditor
          setCurrentContent={onChange}
          currentContent={content}
          toolbarConfig={toolbarConfigWithImages}
          maxCharacterAmount={maxCharacterAmount}
          {...otherProps}
        />
      </div>
    )
  }

  const cleanedOtherProps = { ...otherProps }
  delete cleanedOtherProps.setIsDataUploading

  if (isPdfViewCollapsible) {
    return (
      <CollapsibleRichTextView
        text={content}
        characterLimit={collapsibleCharacterLimit}
        {...cleanedOtherProps}
      />
    )
  }
  return <FormattedTextView formattedText={content} {...cleanedOtherProps} />
}

RichTextTile.propTypes = {
  currentContent: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  isPdfViewCollapsible: PropTypes.bool,
  collapsibleCharacterLimit: PropTypes.number,
  maxCharacterAmount: PropTypes.number,
}

export default RichTextTile
