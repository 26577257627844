import PropTypes from 'prop-types'
import {
  decisionStageStatus as possibleDecisionStageStatus,
  decisionStageTypes,
} from 'api/decision-process/decisionProcessApi'
import DecisionStageInformationCard from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageInformationCard'
import DecisionStageTitle from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageTitle'
import DecisionStageVotersTableLoadingWrapper from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageVotersTableLoadingWrapper'
import Card from 'components/ui/card/Card'
import CWPLayout from 'components/ui/layout/CWPLayout'

const DecisionStageDetailPage = ({ decisionStage }) => {
  const {
    id: decisionStageId,
    info: {
      name: decisionStageName,
      decisionStatus: decisionStageStatus,
      decisionType: decisionStageType,
    },
    decisionPaper,
  } = decisionStage

  return (
    <CWPLayout>
      <CWPLayout.Full>
        <DecisionStageTitle
          decisionStageId={decisionStageId}
          decisionStageStatus={decisionStageStatus}
          decisionStageName={decisionStageName}
          decisionStageType={decisionStageType}
          decisionPaperVersion={decisionPaper?.version}
        />
      </CWPLayout.Full>
      <DecisionStageInformationCard decisionStage={decisionStage} />
      <CWPLayout.Full>
        <Card>
          <DecisionStageVotersTableLoadingWrapper
            decisionStageId={decisionStageId}
            decisionStageStatus={decisionStageStatus}
          />
        </Card>
      </CWPLayout.Full>
    </CWPLayout>
  )
}

DecisionStageDetailPage.propTypes = {
  decisionStage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    info: PropTypes.shape({
      name: PropTypes.string,
      decisionStatus: PropTypes.oneOf(Object.values(possibleDecisionStageStatus)).isRequired,
      decisionType: PropTypes.oneOf(Object.values(decisionStageTypes)).isRequired,
    }),
    decisionPaper: PropTypes.shape({
      version: PropTypes.string.isRequired,
    }),
  }),
}

export default DecisionStageDetailPage
