import { Link, LinkDesign, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import DecisionStageLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/decision-stage-leave-confirmation/DecisionStageLeaveConfirmationLink'
import styles from 'routes/deals/syndication/syndicationStructure/uiElements/SyndicationBusinessPartnerView.module.css'

const OpsMemoSyndicationBusinessPartnerView = ({
  businessPartnerId,
  showLink = true,
  showConfirmationForLinks = false,
  businessPartnerResolvedNames,
}) => {
  const fullName =
    businessPartnerResolvedNames.find(
      (resolvedBusinessPartner) => resolvedBusinessPartner.id === businessPartnerId,
    )?.name || ''

  const linkElement = () => {
    if (showLink && showConfirmationForLinks) {
      return (
        <DecisionStageLeaveConfirmationLink
          name={fullName}
          link={`/business-partners/${businessPartnerId}`}
        />
      )
    } else if (showLink) {
      return (
        <Link
          design={LinkDesign.Emphasized}
          href={`/business-partners/${businessPartnerId}`}
          target="_blank"
          wrappingType="Normal"
        >
          {fullName}
        </Link>
      )
    }
  }

  return (
    <div className={styles.counterpartyCell}>
      {linkElement()}
      <Text
        wrapping={false}
        className={`${styles.textWithOverflowHandling} ${!showLink ? styles.textBold : ''}`}
      >
        {showLink ? businessPartnerId : fullName}
      </Text>
    </div>
  )
}

export default OpsMemoSyndicationBusinessPartnerView

OpsMemoSyndicationBusinessPartnerView.propTypes = {
  businessPartnerId: PropTypes.string.isRequired,
  showLink: PropTypes.bool,
  showConfirmationForLinks: PropTypes.bool,
  businessPartnerResolvedNames: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
}
