import { useSelector } from 'react-redux'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'

const useAnnualReviewDealOverviewSelectedDeal = (
  tileId,
  dealSelectionTileCode = availableAutomaticTiles.annualReviewDealSelection,
) => {
  const { dealsData } =
    useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId].data) ?? {}

  //  if no metadata is set, always use the first deal of a business partner as a fallback
  const {
    dealUuid: preSelectedDealUuid,
    dealName: preSelectedDealName,
    dealDisplayId: preSelectedDealDisplayId,
  } = dealsData?.[0] ?? {}

  const dealMetadata = useSelector(
    (state) => state.decisionPaper.tilesOverview.tileMetadata?.[dealSelectionTileCode],
  ) ?? {
    selectedDealUuid: preSelectedDealUuid,
    selectedDealName: preSelectedDealName,
    selectedDealDisplayId: preSelectedDealDisplayId,
  }

  const dealIndex = dealsData?.map((deal) => deal?.dealUuid).indexOf(dealMetadata?.selectedDealUuid)

  return { ...dealMetadata, selectedDealIndex: dealIndex }
}

export default useAnnualReviewDealOverviewSelectedDeal
