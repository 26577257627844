import { IllustratedMessage, IllustrationMessageType } from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { covenantPermissions } from 'api/deals/covenantPermissions'
import CashFlowScenarioCard from 'components/domains/deals/covenant-check-detail/cash-flow-scenario/CashFlowScenarioCard'
import CheckDocumentsCard from 'components/domains/deals/covenant-check-detail/check-documents/CheckDocumentsCard'
import CheckedCovenantsCard from 'components/domains/deals/covenant-check-detail/checked-covenants/CheckedCovenantsCard'
import CloseCovenantCheckButton from 'components/domains/deals/covenant-check-detail/close-covenant-check/CloseCovenantCheckButton'
import CovenantCommentCard from 'components/domains/deals/covenant-check-detail/comment/CovenantCommentCard'
import HistoricalMonitoringEventsCard from 'components/domains/deals/covenant-check-detail/historical-events/HistoricalMonitoringEventsCard'
import CovenantCheckMessageStrips from 'components/domains/deals/covenant-check-detail/message-stripes/CovenantCheckMessageStrips'
import MonitoringEventCard from 'components/domains/deals/covenant-check-detail/monitoring-event/MonitoringEventCard'
import CovenantCheckMonitoringRequirementsCard from 'components/domains/deals/covenant-check-detail/monitoring-requirements/CovenantCheckMonitoringRequirementsCard'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import CWPLayout from 'components/ui/layout/CWPLayout'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import useCovenantCheckActions from 'hooks/services/deals/covenants/useCovenantCheckActions'
import useCovenantCheckById from 'hooks/services/deals/covenants/useCovenantCheckById'
import { CovenantContext } from 'routes/business-partners/CovenantContext'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'
import useCalculateEditButtonState, {
  editButtonStateEnum,
} from 'routes/deals/covenant-checks/useCalculateEditButtonState'
import paths from 'routes/paths'

const COVENANT_CHECK = 'COVENANT_CHECK'

const CovenantCheckDetails = () => {
  const { t } = useTranslation('translation')
  const { deal: { displayId, dealUuid, name: dealName } = {} } = useContext(DealContext)
  const { covenantCheckUuid } = useParams()
  const { allowedOperations: covenantAllowedOperations = [] } = useContext(CovenantContext)

  const isAllowedToEdit = covenantAllowedOperations.includes(
    covenantPermissions.updateCovenantContractMonitoring,
  )

  const {
    isFetching: isFetchingCheck,
    isError: isErrorCheck,
    data: { name, status, monitoringReferenceEntity: { id, type } = {}, linkedEventId } = {},
  } = useCovenantCheckById(covenantCheckUuid)

  const {
    isFetching: isFetchingActions,
    isError: isErrorActions,
    data: { isEditable } = {},
  } = useCovenantCheckActions({ covenantCheckUuid })

  const editButtonState = useCalculateEditButtonState({
    isEditable,
    status,
    isAllowedToEdit,
  })

  const isFetching = isFetchingCheck || isFetchingActions
  const isError = isErrorCheck || isErrorActions

  const referenceEntity = {
    id: dealUuid,
    name: dealName,
    displayId: displayId,
    documentType: DocumentTypes.Deal,
    entityType: cwpEntityTypes.DEAL,
  }

  const isCovenantCheckInDeal = dealUuid === id && type === mainEntityTypes.DEAL

  const breadcrumbName = name && isCovenantCheckInDeal ? name : covenantCheckUuid

  const breadcrumbs = useMemo(
    () => [
      {
        text: t('pages.deals.covenants.subtitle'),
        href: `/${paths.deals}/${displayId}/covenants`,
      },
      {
        text: breadcrumbName,
      },
    ],
    [t, displayId, breadcrumbName],
  )

  return (
    <DealDetailsWrapper
      childBreadcrumbs={breadcrumbs}
      actions={[
        <CloseCovenantCheckButton
          key="close-covenant-check"
          covenantCheckUuid={covenantCheckUuid}
          buttonText={t('pages.deals.covenants.covenant-check.close-covenant-button')}
          confirmationText={t('pages.deals.covenants.covenant-check.close-confirmation')}
          successText={t('pages.deals.covenants.covenant-check.close-success')}
        />,
      ]}
      showWVLink={false}
    >
      <RequestStateResolver
        isLoading={isFetching}
        isError={isError}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        center
        renderContent={() =>
          isCovenantCheckInDeal ? (
            <CWPLayout>
              <CWPLayout.Full>
                <CovenantCheckMessageStrips
                  checkName={t(
                    'components.deals.covenants.covenant-checks.message-strips.covenant-check',
                  )}
                  covenantCheckUuid={covenantCheckUuid}
                  eventId={linkedEventId}
                />
              </CWPLayout.Full>
              <CWPLayout.Full>
                <CheckedCovenantsCard
                  covenantCheckUuid={covenantCheckUuid}
                  entityType={cwpEntityTypes.DEAL}
                  editButtonState={editButtonState}
                />
              </CWPLayout.Full>
              <CWPLayout.Half>
                <MonitoringEventCard covenantCheckUuid={covenantCheckUuid} />
              </CWPLayout.Half>
              <CWPLayout.Half>
                <CashFlowScenarioCard
                  covenantCheckUuid={covenantCheckUuid}
                  selectButtonState={editButtonState}
                />
              </CWPLayout.Half>
              <CWPLayout.Full>
                <HistoricalMonitoringEventsCard covenantCheckUuid={covenantCheckUuid} />
              </CWPLayout.Full>
              <CWPLayout.Full>
                <CovenantCheckMonitoringRequirementsCard covenantCheckUuid={covenantCheckUuid} />
              </CWPLayout.Full>
              <CWPLayout.Full>
                <CovenantCommentCard
                  linkedEntityType={COVENANT_CHECK}
                  linkedEntityId={covenantCheckUuid}
                  isEditEnabled={editButtonState === editButtonStateEnum.VISIBLE}
                />
              </CWPLayout.Full>
              <CWPLayout.Half>
                <CheckDocumentsCard
                  referenceEntity={referenceEntity}
                  covenantCheckUuid={covenantCheckUuid}
                />
              </CWPLayout.Half>
            </CWPLayout>
          ) : (
            <IllustratedMessage
              name={IllustrationMessageType.NoData}
              titleText={t('components.cards.empty.title')}
              subtitleText={t('components.cards.empty.subtitle')}
            />
          )
        }
      />
    </DealDetailsWrapper>
  )
}

CovenantCheckDetails.propTypes = {}

export default CovenantCheckDetails
