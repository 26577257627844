import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const patchDocumentReferencesOperations = { add: 'ADD', remove: 'REMOVE' }

const isValidOperation = (operation) =>
  Object.values(patchDocumentReferencesOperations).includes(operation)

const usePatchDocumentReferences = ({ onSuccess: _onSuccess, onError } = {}) => {
  const { patch } = useAccessTokenRequest()
  const queryClient = useQueryClient()

  return useMutation(
    /**
     *
     * @param {object} [props]
     * @param {string[]} [props.entityRefs]
     * @returns
     */
    async ({ documentId, entityRefs, operation }) => {
      if (!entityRefs?.length) return
      if (!isValidOperation(operation)) return

      await patch({
        path: `/documents/${documentId}/entity-refs`,
        body: {
          entity_refs: entityRefs,
          op: operation,
        },
      })

      return documentId
    },
    {
      onSuccess: (documentId) => {
        queryClient.invalidateQueries(['documents', documentId])
        _onSuccess?.()
      },
      onError,
    },
  )
}

export default usePatchDocumentReferences
