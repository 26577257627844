import { TableGrowingMode } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/periodical-checks/checks/table/MonitoringItemsTable.module.css'
import SortedTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/periodical-checks/checks/table/shared/sorted-tables/SortedTable'
import useMonitoringItemsColumnDefinitions from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/periodical-checks/checks/table/useMonitoringItemsColumnDefinitions'
import useMonitoringItemsTableData from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/periodical-checks/checks/table/useMonitoringItemsTableData'
import useMonitoringItemsTableEnums from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/periodical-checks/checks/table/useMonitoringItemsTableEnums'
import paths from 'routes/paths'

const columnKeyToMonitoringItemDataMap = {
  'monitoring-item': 'name',
  'covenant-check': 'covenantCheckName',
  status: 'statusOrder',
  'check-date': 'dueDate.checkDate',
  'target-delivery-date': 'dueDate.targetDeliveryDate',
  breach: 'breach',
}

const MonitoringItemsTable = ({
  monitoringItems = [],
  linkedEvents = [],
  orderField,
  orderDirection,
  onSortingChanged,
  pagination = {},
  isPeriodicalCheck,
  hasDocumentRequirements,
  covenantCheckName,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'components.deals.covenants.monitoring-items-card',
  })
  const { t: tNoPrefix } = useTranslation('decisionPaper')
  const navigate = useNavigate()
  const showMoreButton = pagination.limit < pagination.total
  const growingMode = showMoreButton ? TableGrowingMode.Button : TableGrowingMode.None
  const growingButtonText = tNoPrefix('components.ui.tables.sorted-tables.growing-button-text')
  const growingButtonSubtext = `[ ${pagination.limit} / ${pagination.total} ]`

  const customOrderCallback = (columnKey, attributeOrderDirection) => {
    const newOrderField = columnKeyToMonitoringItemDataMap[columnKey]
    onSortingChanged(newOrderField, attributeOrderDirection)
  }

  const sortingColumnKey = Object.keys(columnKeyToMonitoringItemDataMap).find(
    (key) => columnKeyToMonitoringItemDataMap[key] === orderField,
  )

  const { breachEnum, statusEnum } = useMonitoringItemsTableEnums({ monitoringItems })
  const columnDefinitions = useMonitoringItemsColumnDefinitions({
    isPeriodicalCheck,
    hasDocumentRequirements,
    statusEnum,
    breachEnum,
    covenantCheckName,
  })
  const tableData = useMonitoringItemsTableData({
    monitoringItems,
    linkedEvents,
    isPeriodicalCheck,
  })

  const handleOnRowClick = ({ detail: { row: clickedTableRow } }) => {
    if (!isPeriodicalCheck) return
    const monitoringReferenceEntityId = clickedTableRow.getAttribute(
      'linked-covenant-check-monitoring-reference-uuid',
    )
    const linkedCovenantCheckUuid = clickedTableRow.getAttribute(
      'linked-covenant-check-covenant-check-uuid',
    )
    navigate(
      `/${paths.businessPartners}/${monitoringReferenceEntityId}/periodical-checks/${linkedCovenantCheckUuid}`,
    )
  }

  return (
    <div className={isPeriodicalCheck ? '' : styles.defaultCursor}>
      <SortedTable
        columnDefinitions={columnDefinitions}
        noDataText={tNoPrefix('components.table.no-data')}
        tableData={tableData}
        customOrderFunction={(data) => data}
        customOrderCallback={customOrderCallback}
        toolbarConfig={{
          title: isPeriodicalCheck
            ? t('periodical-checks.title', { covenantCheckName })
            : t('title'),
          sorting: {
            columnKey: sortingColumnKey,
            isSortingAscending: orderDirection === 'asc',
          },
        }}
        paginationConfig={{
          growing: growingMode,
          growingButtonText: growingButtonText,
          growingButtonSubtext: growingButtonSubtext,
          totalNumberOfItems: pagination?.total,
        }}
        additionalTableProperties={{ onRowClick: handleOnRowClick, className: styles.innerTable }}
      />
    </div>
  )
}

MonitoringItemsTable.propTypes = {
  monitoringItems: PropTypes.arrayOf(PropTypes.shape({})),
  linkedEvents: PropTypes.arrayOf(PropTypes.shape({})),
  orderField: PropTypes.string,
  orderDirection: PropTypes.string,
  onSortingChanged: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    total: PropTypes.number,
  }),
  loadMore: PropTypes.func,
  isPeriodicalCheck: PropTypes.bool,
  hasDocumentRequirements: PropTypes.bool,
  covenantCheckName: PropTypes.string,
}

export default MonitoringItemsTable
