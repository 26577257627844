import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  imagesAreLoading,
  imagesHaveError,
  imagesLoaded,
  initializeImages,
} from 'redux/slices/decision-paper/imagesForTilesSlice'

const useReduxTileImageLoaderEffects = ({
  tileId,
  isDefaultLoader,
  isLoading,
  isError,
  data,
  additionalConditionForImagesLoaded = true,
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (!isDefaultLoader) {
      dispatch(initializeImages(tileId))
    }
  }, [tileId, isDefaultLoader, dispatch])

  useEffect(() => {
    if (isLoading && !isDefaultLoader) {
      dispatch(imagesAreLoading(tileId))
    }
  }, [isLoading, isDefaultLoader, dispatch, tileId])

  useEffect(() => {
    if (isError && !isDefaultLoader) {
      dispatch(imagesHaveError({ id: tileId, error: {} }))
    }
  }, [isError, isDefaultLoader, dispatch, tileId])

  useEffect(() => {
    if (data && !isLoading && !isDefaultLoader && additionalConditionForImagesLoaded) {
      dispatch(imagesLoaded({ id: tileId, data }))
    }
  }, [data, isDefaultLoader, isLoading, dispatch, tileId, additionalConditionForImagesLoaded])
}

export default useReduxTileImageLoaderEffects
