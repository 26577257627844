import isNil from 'lodash.isnil'
import { DateTime } from 'luxon'

export const isDateValid = (date) => {
  // DateTime.fromISO accepts multiple ISO-formats e.g. 2020-01
  const dateRegex = /^\d{4}-\d{2}-\d{2}/
  if (!dateRegex.test(date)) return false

  const parsedNewDeliveryDate = DateTime.fromISO(date)
  return parsedNewDeliveryDate.isValid
}

const useValidateMonitoringInformation = ({ monitoringInformation, covenantDefinition }) => {
  const validateBaseData = ({ amountToAdd, direction } = {}, isRequired) =>
    isRequired
      ? !isNil(amountToAdd) && amountToAdd >= 0 && !!direction
      : !isNil(amountToAdd) && amountToAdd >= 0

  const { withDocumentRequest } = covenantDefinition ?? {}
  const {
    covenantMonitoringUuid,
    firstDueDate: { keyDate } = {},
    checkCycle = {},
    checkRequired,
  } = monitoringInformation ?? {}
  let { firstDueDate: { targetDeliveryDateBaseData = {}, checkDateBaseData = {} } = {} } =
    monitoringInformation ?? {}
  if (!targetDeliveryDateBaseData) targetDeliveryDateBaseData = {}
  if (!checkDateBaseData) checkDateBaseData = {}

  if (!checkRequired) return !!covenantMonitoringUuid

  const isKeyDateValid = isDateValid(keyDate)
  const isTargetDeliveryDateBaseDataValid =
    validateBaseData(targetDeliveryDateBaseData, true) || !withDocumentRequest
  const isCheckDateBaseDataValid = validateBaseData(checkDateBaseData, true)
  const isCheckCycleValid = Boolean(checkCycle?.number) && Boolean(checkCycle?.cycle)

  return (
    isKeyDateValid &&
    isTargetDeliveryDateBaseDataValid &&
    isCheckDateBaseDataValid &&
    isCheckCycleValid
  )
}

export default useValidateMonitoringInformation
