import isEqual from 'lodash.isequal'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionTypes } from 'api/conditions/conditions'
import { ConditionTablesGeneralPropTypes } from 'components/domains/conditions/overview/ConditionTablePropTypes'
import ConditionsTable from 'components/domains/conditions/overview/ConditionsTable'
import ConditionsTableToolbar from 'components/domains/conditions/overview/ConditionsTableToolbar'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useConditionTableColumns from 'hooks/services/conditions/tables/useConditionTableColumns'
import useConditions from 'hooks/services/conditions/useConditions'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const allColumns = [
  'name',
  'refNumber',
  'type',
  'approvalLevel',
  'assignee',
  // Cell that is never displayed
  'creationDate',
  'status',
  'watcher',
  'actions',
]

const subcomponentHeight = 132

const defaultHiddenColumns = ['creationDate']

const shownSubcomponents = {
  description: true,
  comment: true,
  creationDate: true,
}

const ConditionsInternalTable = ({
  totalNumberOfInternalConditions,
  totalNumberOfExternalConditions,
  switchActiveConditionSelection,
  currentlySelectedConditionType,
}) => {
  const {
    entityRef: { entityId, entityType },
  } = useContext(ConditionsContext)
  const { t } = useTranslation('translation', { keyPrefix: 'components.conditions.internal.table' })

  const {
    columnSelection,
    setColumnSelection,
    filters,
    setFilters,
    sortableColumns,
    sorting,
    onUpdateSorting,
    searchParam,
    setSearchParam,
    mappedBackendFilters,
  } = useConditionTableColumns({
    shownColumns: allColumns,
    defaultHiddenColumns,
    conditionType: conditionTypes.internal,
    entityType,
  })

  const {
    isLoading,
    isError,
    data: internalConditionsResult,
    fetchNextPage,
  } = useConditions(conditionTypes.internal, { entityId, entityType }, mappedBackendFilters, {
    sortBy: sorting.sortingKey,
    orderBy: sorting.sortDescending ? 'desc' : 'asc',
  })

  const isFilterOrSearchApplied = useMemo(
    () =>
      filters.filter(({ value, emptyValue }) => !!value && !isEqual(value, emptyValue)).length !==
        0 || searchParam !== '',
    [filters, searchParam],
  )

  const renderInternalConditionsTable = useCallback(
    () => (
      <div>
        <ConditionsTableToolbar
          totalNumberOfInternalConditions={
            internalConditionsResult?.total ?? totalNumberOfInternalConditions
          }
          totalNumberOfExternalConditions={totalNumberOfExternalConditions}
          switchActiveConditionSelection={switchActiveConditionSelection}
          currentlySelectedConditionType={currentlySelectedConditionType}
          columnSelection={columnSelection}
          setColumnSelection={setColumnSelection}
          filters={filters}
          setFilters={setFilters}
          searchParam={searchParam}
          onUpdateSearchParam={setSearchParam}
          sorting={{
            columnKey: sorting.sortBy,
            sortBy: sorting.sortingKey,
            isSortingAscending: !sorting.sortDescending,
            sortableColumns,
            onUpdateSorting,
          }}
          mappedBackendFilters={mappedBackendFilters}
        />
        <ConditionsTable
          isLoading={isLoading}
          conditions={internalConditionsResult?.internalConditions}
          columns={columnSelection}
          isFilterOrSearchApplied={isFilterOrSearchApplied}
          fetchNextPage={fetchNextPage}
          conditionType={conditionTypes.internal}
          shownSubcomponents={shownSubcomponents}
          subcomponentHeight={subcomponentHeight}
        />
      </div>
    ),
    [
      totalNumberOfInternalConditions,
      totalNumberOfExternalConditions,
      switchActiveConditionSelection,
      currentlySelectedConditionType,
      internalConditionsResult,
      columnSelection,
      isFilterOrSearchApplied,
      setColumnSelection,
      filters,
      setFilters,
      searchParam,
      setSearchParam,
      sortableColumns,
      sorting,
      onUpdateSorting,
      isLoading,
      fetchNextPage,
      mappedBackendFilters,
    ],
  )
  return (
    <RequestStateResolver
      isLoading={false}
      isError={isError}
      renderContent={renderInternalConditionsTable}
      errorToDisplay={
        <ErrorDataUnavailableInContent
          titleText={t('error.title')}
          subtitleText={t('error.description')}
        />
      }
    />
  )
}

ConditionsInternalTable.propTypes = ConditionTablesGeneralPropTypes

export default ConditionsInternalTable
