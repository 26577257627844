import {
  AnalyticalTableScaleWidthMode,
  Button,
  ButtonDesign,
  IllustratedMessage,
  IllustrationMessageType,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/general-information/rating/PropertyRatingTable.module.css'
import PropertyRatingTableCell from 'components/domains/properties/general-information/rating/PropertyRatingTableCell'
import mapRatingDevelopmentRisksChances from 'components/domains/properties/general-information/rating/utils/mapRatingDevelopmentRisksChances'
import mapRatingSalesProspects from 'components/domains/properties/general-information/rating/utils/mapRatingSalesProspects'
import mapRatingTotal from 'components/domains/properties/general-information/rating/utils/mapRatingTotal'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'

const PropertyRatingTable = ({ criteriaClasses }) => {
  const { t: tCriteria } = useTranslation('translation', {
    keyPrefix: 'pages.property.rating.criteria-class',
  })

  const tableRef = useRef(null)

  const criteriaClassData = useMemo(
    () =>
      criteriaClasses
        ? [
            ...mapRatingSalesProspects(criteriaClasses),
            ...mapRatingTotal(criteriaClasses),
            ...mapRatingDevelopmentRisksChances(criteriaClasses),
          ]
        : [],
    [criteriaClasses],
  )

  const filterCriteriaClassData = (unfilteredCriteriaClassData) => {
    let countCriteriaClass = 0
    const filteredCriteriaClassData = unfilteredCriteriaClassData
      .filter((data) => data?.rating)
      .map((entry) => {
        countCriteriaClass += 1
        if (entry?.subRows) {
          const result = filterCriteriaClassData(entry.subRows)
          countCriteriaClass += result.countCriteriaClass
          entry.subRows = result.filteredCriteriaClassData
        }
        return entry
      })
    return { countCriteriaClass, filteredCriteriaClassData }
  }

  const tableData = () => {
    const unfilteredCriteriaClassData = [...criteriaClassData]
    return filterCriteriaClassData(unfilteredCriteriaClassData)
  }

  const columnDefinitions = useMemo(() => {
    const name = ({ cell }) => {
      const criteriaClassName = cell?.row?.values?.criteriaClass
      const textStyle = criteriaClassName === 'salesProspects' ? styles.textBold : ''
      const nameTranslationEntry = criteriaClassName
        ? criteriaClassName.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase())
        : null
      return nameTranslationEntry ? (
        <Text className={textStyle}>{tCriteria(nameTranslationEntry)}</Text>
      ) : null
    }
    const ratingStatus = ({ cell }) => {
      const rating = cell?.row?.values?.rating
      return <PropertyRatingTableCell rating={rating} />
    }
    const defaultDisables = {
      disableDragAndDrop: true,
      disableGlobalFilter: true,
      disableFilters: true,
      disableSortBy: true,
      disableGroupBy: true,
    }
    return [
      {
        Header: tCriteria('column-criteria-class-name'),
        accessor: 'criteriaClass',
        Cell: name,
        ...defaultDisables,
      },
      {
        Header: tCriteria('column-rating'),
        accessor: 'rating',
        width: 400,
        minWidth: 200,
        Cell: ratingStatus,
        ...defaultDisables,
      },
    ]
  }, [tCriteria])

  const expandOrCollapseSubRows = (isExpanded) => {
    tableRef?.current?.toggleAllRowsExpanded(isExpanded)
  }

  const additionalTableActions = [
    <Button
      className={styles.collapseIcon}
      design={ButtonDesign.Transparent}
      icon="collapse-all"
      key={'collapse-subrows'}
      onClick={() => expandOrCollapseSubRows(false)}
    />,
    <Button
      className={styles.expandIcon}
      design={ButtonDesign.Transparent}
      icon="expand-all"
      key={'expand-subrows'}
      onClick={() => expandOrCollapseSubRows(true)}
    />,
  ]
  return (
    <AnalyticalTableWithToolbar
      className={styles.tableBottom}
      title={tCriteria('title')}
      nrOfEntries={tableData().countCriteriaClass}
      additionalActions={additionalTableActions}
      data={tableData().filteredCriteriaClassData}
      columns={columnDefinitions}
      isTreeTable={true}
      visibleRows={tableData().countCriteriaClass}
      scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
      NoDataComponent={() => (
        <IllustratedMessage
          name={IllustrationMessageType.NoEntries}
          subtitleText={tCriteria('no-data')}
        />
      )}
      ref={tableRef}
    />
  )
}

PropertyRatingTable.propTypes = {
  criteriaClasses: PropTypes.shape({
    market: PropTypes.shape({
      market: PropTypes.number,
      marketNational: PropTypes.object,
      marketRegional: PropTypes.object,
    }),
    location: PropTypes.object,
    property: PropTypes.object,
    cashflow: PropTypes.object,
    total: PropTypes.number,
    developmentRiskAndChances: PropTypes.object,
    salesProspects: PropTypes.number,
  }),
}

export default PropertyRatingTable
