import { Button, ButtonDesign, ObjectPageSection } from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { covenantPermissions } from 'api/deals/covenantPermissions'
import CreateCovenantDialog from 'components/domains/deals/covenants/create-dialog/CreateCovenantDialog'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { useDealWorkingVersionToolbarButtons } from 'components/domains/deals/deal-adjustment/useDealWorkingVersionToolbarButtons'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import { useCovenantAllowedOperations } from 'hooks/services/deals/covenants/useCovenantAllowedOperations'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'
import DealCovenantsMonitoring from 'routes/deals/covenants/DealCovenantsMonitoring'
import DealCovenantsOverview from 'routes/deals/covenants/DealCovenantsOverview'
import DealCovenantsResults from 'routes/deals/covenants/DealCovenantsResults'

const DealCovenantsTabs = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.covenants' })
  const {
    deal: { dealUuid, workingVersion },
  } = useContext(DealContext)
  const { toggleWorkingVersionButton, manageWorkingVersionButton } =
    useDealWorkingVersionToolbarButtons()
  const isWorkingVersion = useMemo(
    () => WorkingVersionType.WORKING_VERSION === workingVersion,
    [workingVersion],
  )
  const location = useLocation()

  const [isCreationDialogOpen, setIsCreationDialogOpen] = useState(false)

  const { data: { allowedOperations: covenantAllowedOperations = [] } = {} } =
    useCovenantAllowedOperations('', {
      mainEntityType: mainEntityTypes.DEAL,
      mainEntityId: dealUuid,
    })

  const isAllowedToCreateCovenant = covenantAllowedOperations?.includes(
    covenantPermissions.createContractCovenant,
  )

  const createCovenantAction = useMemo(
    () =>
      isAllowedToCreateCovenant && (
        <Button
          id="create-covenant"
          design={ButtonDesign.Emphasized}
          onClick={() => setIsCreationDialogOpen(true)}
          key="create-covenant"
        >
          {t('create-covenant')}
        </Button>
      ),
    [isAllowedToCreateCovenant, t],
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [dealUuid],
  )

  const isOverviewTab = useMemo(
    () => location.hash.substring(1) === 'overview' || location.hash === '',
    [location.hash],
  )

  const headerActions = useMemo(
    () =>
      createSegmentedHeaderActions(
        [isOverviewTab && toggleWorkingVersionButton, manageWorkingVersionButton],
        [createCovenantAction],
        [<CreateDealEventAction key="create-event-action" />, markFavoriteAction],
      ),
    [
      createCovenantAction,
      isOverviewTab,
      manageWorkingVersionButton,
      markFavoriteAction,
      toggleWorkingVersionButton,
    ],
  )

  const text = useMemo(
    () => ({
      breadcrumb: t('subtitle'),
      overviewTab: t('tab.overview'),
      monitoringTab: t('tab.monitoring'),
      resultsTab: t('tab.results'),
    }),
    [t],
  )

  const renderPageContent = () => {
    const childBreadcrumbs = { text: text.breadcrumb }
    if (isWorkingVersion) {
      return (
        <DealDetailsWrapper
          isTabPage={false}
          childBreadcrumbs={childBreadcrumbs}
          additionalProperties={{ includeUrlQuery: true }}
          actions={headerActions}
        >
          <DealCovenantsOverview />
        </DealDetailsWrapper>
      )
    } else {
      return (
        <DealDetailsWrapper
          isTabPage={true}
          childBreadcrumbs={childBreadcrumbs}
          additionalProperties={{ includeUrlQuery: true }}
          actions={headerActions}
          showWVLink={isOverviewTab}
        >
          <ObjectPageSection id="overview" titleText={text.overviewTab}>
            <DealCovenantsOverview />
          </ObjectPageSection>
          <ObjectPageSection id="monitoring" titleText={text.monitoringTab}>
            <DealCovenantsMonitoring />
          </ObjectPageSection>
          <ObjectPageSection id="results" titleText={text.resultsTab}>
            <DealCovenantsResults />
          </ObjectPageSection>
        </DealDetailsWrapper>
      )
    }
  }

  return (
    <>
      {renderPageContent()}
      <CreateCovenantDialog isOpen={isCreationDialogOpen} setIsOpen={setIsCreationDialogOpen} />
    </>
  )
}

export default DealCovenantsTabs
