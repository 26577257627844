import isNil from 'lodash.isnil'
import { useTranslation } from 'react-i18next'

const useCheckCycleTranslations = () => {
  const { t } = useTranslation('translation')

  const CheckCycleTranslations = {
    MONTHLY: t('pages.deals.covenants.check-cycle.monthly'),
    QUARTERLY: t('pages.deals.covenants.check-cycle.quarterly'),
    BI_ANNUALLY: t('pages.deals.covenants.check-cycle.biannually'),
    ANNUALLY: t('pages.deals.covenants.check-cycle.annually'),
  }

  const getFormattedCheckCycle = ({ number, cycle }) => {
    if (isNil(number) || isNil(cycle)) return ''

    return t('pages.deals.covenants.check-cycle.check-cycle-pattern', {
      amount: number,
      cycle: CheckCycleTranslations[cycle],
    })
  }

  return { getFormattedCheckCycle, CheckCycleTranslations }
}

export default useCheckCycleTranslations
