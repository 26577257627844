import uniq from 'lodash.uniq'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  EmptyCell,
  PropertyCell,
} from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonCells'
import { renderAnalyticalTableCell } from 'components/ui/tables/analytical/AnalyticalTableCell'
import { usePortfolioSegments } from 'hooks/services/properties/portfolio/usePortfolioSegments'
import { useRentRollComparisonColumns } from 'hooks/services/properties/rent-roll/comparison/useRentRollComparisonColumns'
import { PropertyPortfolioContext } from 'routes/properties/portfolio/PropertyPortfolioContext'

const getFilterEnumns = (list) =>
  list.reduce((acc, curr) => {
    acc[curr] = curr
    return acc
  }, {})

export const usePortfolioRentRollAnalysisColumns = () => {
  const { properties } = useContext(PropertyPortfolioContext)
  const propertyUuids = properties.map((property) => property.uuid)

  const {
    isLoading: isLoading,
    isError: isError,
    data: segmentsData,
  } = usePortfolioSegments({
    propertyUuids,
  })

  const segments =
    segmentsData?.map((segmentsByProperty) => segmentsByProperty.segments).flat() ?? []

  const { t: tAnalysis } = useTranslation('translation', {
    keyPrefix: 'pages.properties.portfolio.rent-roll.analysis',
  })

  const ACCESSORS = {
    PROPERTY: 'property.name',
    PROPERTY_TYPE: 'property.typeName',
  }

  const additionalData = {
    t: tAnalysis,
  }
  const defaultDisables = {
    disableDragAndDrop: true,
    disableGlobalFilter: true,
  }

  const columnDefinitions = useRentRollComparisonColumns(tAnalysis('uom'), segments)
  const relevantPropertyTypes = uniq(properties.map((property) => property.type_name))

  if (isLoading || isError) {
    return {
      isLoading: isLoading,
      isError: isError,
      data: [],
    }
  }

  return {
    isLoading: false,
    isError: false,
    data: [
      columnDefinitions[0],
      {
        id: ACCESSORS.PROPERTY,
        accessor: ACCESSORS.PROPERTY,
        minWidth: 250,
        Header: tAnalysis('property'),
        filterLabel: tAnalysis('property'),
        sortByLabel: tAnalysis('property'),
        Cell: renderAnalyticalTableCell(PropertyCell, additionalData),
        filterType: 'CONTAINS',
        Aggregated: renderAnalyticalTableCell(PropertyCell, additionalData, true),
        ...defaultDisables,
      },
      {
        hide: true,
        id: ACCESSORS.PROPERTY_TYPE,
        accessor: ACCESSORS.PROPERTY_TYPE,
        Header: tAnalysis('property-type'),
        filterLabel: tAnalysis('property-type'),
        sortByLabel: tAnalysis('property-type'),
        Cell: renderAnalyticalTableCell(EmptyCell, additionalData),
        filterType: 'OF_ENUM_TYPE',
        additionalFilterOptions: {
          enumValues: getFilterEnumns(relevantPropertyTypes),
        },
        Aggregated: renderAnalyticalTableCell(EmptyCell, additionalData, true),
        ...defaultDisables,
      },
      ...columnDefinitions.slice(1),
    ],
  }
}
