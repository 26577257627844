import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { getValuationData } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/fact-sheet/useFactSheetValuation'
import useMultipleDealProperties from 'hooks/services/deals/properties/useMultipleDealProperties'
import useDeals from 'hooks/services/deals/useDeals'
import { useMultiPropertyViewByPropertyUuidsArrays } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuidsArrays'
import useMultipleArrayPropertyUuids from 'hooks/services/properties/useMultipleArrayPropertyUuids'
import useMultiArrayPropertyValuations from 'hooks/services/properties/valuations/useMultiArrayPropertyValuations'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const useRiskIndicatorDealSpecificsValuation = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { deals: dealsData = [] } = {},
    isLoading: isDealsLoading,
    isError: isDealsError,
    error: dealsError,
  } = useDeals({ filter: { borrowerId: businessPartnerId } }) || {}

  const mappedDealUuids = useMemo(() => dealsData?.map((deal) => deal.dealUuid), [dealsData])

  // get an array of property uuids for each deal
  const {
    data: multipleDealPropertiesData,
    isLoading: isMultipleDealPropertiesLoading,
    isError: isMultipleDealPropertiesError,
    error: multipleDealPropertiesError,
  } = useCombinedQueryResults(
    useMultipleDealProperties({
      dealUuids: mappedDealUuids,
    }) ?? {},
  )

  const isFollowUpRequestEnabled = useMemo(
    () =>
      !isMultipleDealPropertiesLoading &&
      !isMultipleDealPropertiesError &&
      multipleDealPropertiesData &&
      multipleDealPropertiesData.some((property) => property.dealProperties?.length > 0),
    [isMultipleDealPropertiesError, isMultipleDealPropertiesLoading, multipleDealPropertiesData],
  )

  // create an array of arrays of property uuids related to a deal id
  const propertyUuidsArrays = useMemo(
    () =>
      isFollowUpRequestEnabled
        ? multipleDealPropertiesData.map((dealProperty) =>
            dealProperty.dealProperties.map((property) => property.propertyUuid),
          )
        : [],
    [isFollowUpRequestEnabled, multipleDealPropertiesData],
  )

  // array version of usePropertyUuids:
  // get an array of responses for each properties array (related to a deal id),
  // so one entry per deal on the highest array level
  const {
    data: multipleArrayPropertyUuidsData,
    isLoading: isMultipleArrayPropertyUuidsLoading,
    isError: isMultipleArrayPropertyUuidsError,
  } = useMultipleArrayPropertyUuids({
    propertyUuidsArrays,
    options: { enabled: isFollowUpRequestEnabled },
  })

  // array version of useMultiPropertyViewByPropertyUuids:
  // in case of multiple properties per array, fetch also the portfolio uuid used in the source link
  const {
    data: multiPropertyViewByPropertyUuidsArraysData,
    isLoading: isMultiPropertyViewByPropertyUuidsArraysLoading,
    isError: isMultiPropertyViewByPropertyUuidsArraysError,
  } = useMultiPropertyViewByPropertyUuidsArrays({
    propertyUuidsArrays,
    options: { enabled: isFollowUpRequestEnabled },
  })

  // array version of useMultiPropertyValuations:
  // get an array of responses for each properties array (related to a deal id),
  // so one entry per deal on the highest array level
  const {
    data: multiArrayPropertyValuationsData,
    isLoading: isMultiArrayPropertyValuationsLoading,
    isError: isMultiArrayPropertyValuationsError,
  } = useMultiArrayPropertyValuations({
    propertyUuidsArrays,
    includeAggregates: ['SUM'],
    preferredCurrency: undefined,
    options: {
      enabled: isFollowUpRequestEnabled,
    },
  })

  const valuationInformationArray = useMemo(
    () =>
      mappedDealUuids.map((dealUuid, index) =>
        getValuationData(multiArrayPropertyValuationsData?.[index]?.data),
      ),
    [mappedDealUuids, multiArrayPropertyValuationsData],
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isDealsLoading,
      isMultipleDealPropertiesLoading,
      isMultipleArrayPropertyUuidsLoading,
      isMultiPropertyViewByPropertyUuidsArraysLoading,
      isMultiArrayPropertyValuationsLoading,
    ],
    errorValues: [
      isDealsError,
      isMultipleDealPropertiesError,
      isMultipleArrayPropertyUuidsError,
      isMultiPropertyViewByPropertyUuidsArraysError,
      isMultiArrayPropertyValuationsError,
    ],
    errorDetails: [dealsError, multipleDealPropertiesError],
    tileId,
  })

  const sourcePaths = useMemo(
    () =>
      mappedDealUuids.map((dealUuid, index) => {
        const propertiesArrayData = multipleArrayPropertyUuidsData?.[index]?.data?.properties
        const multiPropertiesArrayViewUuid =
          multiPropertyViewByPropertyUuidsArraysData?.[index]?.data?.uuid
        return propertiesArrayData?.length > 1
          ? `/properties/portfolio/overview?portfolio-view-id=${multiPropertiesArrayViewUuid}`
          : `/properties/${propertiesArrayData?.[0]?.id}/valuation`
      }),
    [mappedDealUuids, multiPropertyViewByPropertyUuidsArraysData, multipleArrayPropertyUuidsData],
  )

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        valuationInformationArray,
        hasNoProperties: !isFollowUpRequestEnabled,
        sourcePaths,
        sourceRender: { path: `${sourcePaths[0]}` },
      },
    }
  }, [
    dealsData,
    error,
    isFollowUpRequestEnabled,
    isSomeValueError,
    isSomeValueLoading,
    sourcePaths,
    valuationInformationArray,
  ])
}

export default useRiskIndicatorDealSpecificsValuation
