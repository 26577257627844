import {
  Button,
  ButtonDesign,
  Modals,
  MessageBoxTypes,
  MessageBoxActions,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useCreateCashflowScenarioSession from 'hooks/services/pbb-custom-service/useCreateCashflowScenarioSession'

const DealCashflowDetailsStartButton = ({ dealUuid, cashflowScenarioUuid }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.detail.cashflow' })

  const showToast = Modals.useShowToast()
  const showMessageBox = useShowMessageBox()
  const showErrorMessageBox = useShowErrorMessageBox()

  const { mutate: createCashflowScenarioSession } = useCreateCashflowScenarioSession({
    onSuccess: () => {
      showToast({
        duration: 5000,
        children: t('details.toast.scenario-creation-triggered'),
      })
    },
    onError: async (error) => {
      const { errors: [errorResponse] = [] } = await error.response.json()
      showErrorMessageBox({ message: t('dialog.error.create.text'), error: errorResponse })
    },
  })

  return (
    <>
      <Button
        id="start-cashflow-scenario"
        design={ButtonDesign.Transparent}
        onClick={() =>
          showMessageBox(
            {
              type: MessageBoxTypes.Information,
              titleText: t('dialog.start.title'),
              children: t('dialog.start.text'),
              actions: [
                <Button
                  key="button-confirm"
                  design={ButtonDesign.Emphasized}
                  onClick={() => createCashflowScenarioSession({ dealUuid, cashflowScenarioUuid })}
                >
                  {t('dialog.start.button.start')}
                </Button>,
                MessageBoxActions.Cancel,
              ],
            },
            document.body,
          )
        }
      >
        {t('button.start-cashflow-tool-session')}
      </Button>
    </>
  )
}

DealCashflowDetailsStartButton.propTypes = {
  dealUuid: PropTypes.string.isRequired,
  cashflowScenarioUuid: PropTypes.string.isRequired,
}

export default DealCashflowDetailsStartButton
