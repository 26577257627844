import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrearsApprovalChecklist/ArrearsApprovallChecklistTileView.module.css'
import { CHECKLIST_ITEMS } from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrearsApprovalChecklist/constant'
import DecisionPaperDisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrearsApprovalChecklist/shared/ArrearsApprovalChecklistDisplayCardViewInfoItemView'
import DecisionPaperDisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrearsApprovalChecklist/shared/ArrearsApprovalChecklistDisplayCardViewItemView'

const ArrearsApprovalChecklistTileView = ({ content }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.arrears-approval-checklist',
  })

  const checklistItemView = (labelKey, value, isFirstItem) => {
    const getItemValue = () => (value ? t(`checklist-item.value.${value.toLowerCase()}`) : '-')
    return (
      <DecisionPaperDisplayCardViewItemView key={`${labelKey}-view`}>
        <DecisionPaperDisplayCardViewInfoItemView
          id={labelKey}
          label={t(labelKey)}
          value={getItemValue()}
          isFirstItem={isFirstItem}
        />
      </DecisionPaperDisplayCardViewItemView>
    )
  }

  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      alignItems={FlexBoxAlignItems.Stretch}
      className={styles.displayFlexBox}
    >
      {CHECKLIST_ITEMS.map(({ labelKey, checkListItem, isFirstItem }) =>
        checklistItemView(labelKey, content[checkListItem], isFirstItem),
      )}
    </FlexBox>
  )
}

ArrearsApprovalChecklistTileView.propTypes = {
  content: PropTypes.shape({
    ratingUpdateNecessary: PropTypes.string,
    accountManagerOneInformed: PropTypes.string,
    syndicationPartnerInformed: PropTypes.string,
    guarantorInformed: PropTypes.string,
  }),
}

export default ArrearsApprovalChecklistTileView
