const FPO_RESULT = 'FPO_RESULT'

export const mapFpoResult = (dealUuid, rowResponses, businessPartnerCustomFieldConfigMetadata) => {
  const response = rowResponses?.find(({ data }) => data?.dealUuid === dealUuid)
  const result = response?.data?.customFields?.find((field) => field.key === FPO_RESULT)

  const fpoResultEnums = businessPartnerCustomFieldConfigMetadata?.find(
    (field) => field.key === FPO_RESULT,
  )

  const displayValue = fpoResultEnums?.enumValues?.find(
    (enumValue) => enumValue.code === result?.value,
  )

  return result?.value ? displayValue?.displayName : null
}
