import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Text,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useNumberFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'

const testId = (id) =>
  process.env.NODE_ENV !== 'production' ? { 'data-testid': `check-results-card-header__${id}` } : {}

const NO_CONTENT_PLACEHOLDER = '-'

const CheckResultsCardHeader = ({ headerAction, name, unit, value, ...props }) => {
  const formatNumber = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: 'compact',
  })

  return (
    <div {...props}>
      <FlexBox
        direction={FlexBoxDirection.Row}
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        alignItems={FlexBoxAlignItems.Center}
      >
        <Text {...testId('name-and-unit')}>
          {name ? `${name} | ${unit}` : NO_CONTENT_PLACEHOLDER}
        </Text>
        <div>{headerAction}</div>
      </FlexBox>
      <FlexBox
        direction={FlexBoxDirection.Row}
        alignItems={FlexBoxAlignItems.Baseline}
        {...testId('result-value')}
      >
        <Title level={TitleLevel.H1}>{value ? formatNumber(value) : NO_CONTENT_PLACEHOLDER}</Title>
      </FlexBox>
    </div>
  )
}

CheckResultsCardHeader.propTypes = {
  headerAction: PropTypes.oneOfType([() => null, PropTypes.element]),
  name: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.number,
}

export default CheckResultsCardHeader
