import { Button, ButtonDesign, Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import find from 'lodash.find'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingComboBox from 'components/ui/combobox/LoadingComboBox'
import useDecisionPaperTemplates from 'hooks/services/business-events-and-tasks/decision-papers/useDecisionPaperTemplates'

export const noSelectionValueCode = 'decision-paper-template-no-selection'

const emptyObject = {}

const DecisionPaperTemplateDropdown = ({
  id,
  initialValue = noSelectionValueCode,
  currentTemplateCode,
  currentTemplateName,
  customizedTemplateCodes = emptyObject,
  eventCode,
  entityType,
  entityId,
  onChange,
  required,
  displayNeutralField = true,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.template-selection',
  })

  const {
    isLoading,
    isError,
    data: decisionPaperTemplatesData,
  } = useDecisionPaperTemplates({ eventCode, entityType, entityId })

  const decisionPaperTemplates = decisionPaperTemplatesData?.decisionPaperTemplates

  const [selectedDecisionPaperTemplate, setSelectedDecisionPaperTemplate] = useState(initialValue)
  const [selectedDecisionPaperTemplateHasError, setSelectedDecisionPaperTemplateHasError] =
    useState(false)

  useEffect(() => {
    setSelectedDecisionPaperTemplateHasError(false)
  }, [initialValue])

  useEffect(() => {
    if (decisionPaperTemplates?.length === 1) {
      const templateCode = decisionPaperTemplates[0].code
      setSelectedDecisionPaperTemplate(templateCode)
      onChange(templateCode)
    }
  }, [decisionPaperTemplates, onChange])

  const decisionPaperTemplatesLoaderForLoadingDropdown = useCallback(() => {
    if (isLoading || isError) {
      return {
        isLoading,
        isError,
        data: undefined,
      }
    }

    if (!decisionPaperTemplates || decisionPaperTemplates.length === 0) {
      return {
        isLoading: false,
        isError: false,
        data: [{ code: noSelectionValueCode, displayName: t('empty') }],
      }
    }

    if (!displayNeutralField) {
      return {
        isLoading: false,
        isError: false,
        data: [
          ...decisionPaperTemplates.map(({ code, name }) => ({
            code,
            displayName: customizedTemplateCodes[code] ? t('customized', { name }) : name,
          })),
        ],
      }
    }

    return {
      isLoading: false,
      isError: false,
      data: [
        ...(isNil(currentTemplateCode) ||
        decisionPaperTemplates.some((template) => template.code === currentTemplateCode)
          ? []
          : [
              {
                code: currentTemplateCode,
                displayName: customizedTemplateCodes[currentTemplateCode]
                  ? t('customized', { name: currentTemplateName })
                  : currentTemplateName,
              },
            ]),
        ...decisionPaperTemplates.map(({ code, name }) => ({
          code,
          displayName: customizedTemplateCodes[code] ? t('customized', { name }) : name,
        })),
      ],
    }
  }, [
    customizedTemplateCodes,
    decisionPaperTemplates,
    displayNeutralField,
    currentTemplateCode,
    currentTemplateName,
    isError,
    isLoading,
    t,
  ])

  const isNoSelectionErrorForTemplateCode = useCallback(
    (selectedTemplateCode) =>
      required &&
      (selectedTemplateCode === noSelectionValueCode || selectedTemplateCode === '') &&
      !isError &&
      !isLoading &&
      !!decisionPaperTemplates &&
      decisionPaperTemplates.length !== 0,
    [decisionPaperTemplates, isError, isLoading, required],
  )

  const handleOnTemplateSelectionBlur = (selectedTemplateCode) => {
    if (!selectedTemplateCode) {
      return
    }
    if (isNoSelectionErrorForTemplateCode(selectedTemplateCode)) {
      setSelectedDecisionPaperTemplateHasError(true)
    }
  }

  const handleOnTemplateSelectionChanged = useCallback(
    (input) => {
      if (input?.code === undefined) {
        return
      }
      const { code } = input
      if (isNoSelectionErrorForTemplateCode(code)) {
        setSelectedDecisionPaperTemplateHasError(true)
        setSelectedDecisionPaperTemplate(code)
        onChange(noSelectionValueCode)
        return
      }
      setSelectedDecisionPaperTemplateHasError(false)
      setSelectedDecisionPaperTemplate(code)
      onChange(code)
    },
    [isNoSelectionErrorForTemplateCode, onChange],
  )

  const onClearButtonClicked = useCallback(() => {
    handleOnTemplateSelectionChanged({ code: '' })
  }, [handleOnTemplateSelectionChanged])

  const loadingDropdownKey = useMemo(() => {
    const customizedCodes = Array.from(Object.keys(customizedTemplateCodes)).join('-')
    return `DecisionPaperTemplateDropdown-${customizedCodes}`
  }, [customizedTemplateCodes])

  const selectedDecisionPaperTemplateName = useMemo(() => {
    const name = find(decisionPaperTemplates, { code: selectedDecisionPaperTemplate })?.name
    if (!selectedDecisionPaperTemplate || !name) {
      return ''
    }
    return customizedTemplateCodes[selectedDecisionPaperTemplate]
      ? t('customized', { name: selectedDecisionPaperTemplate })
      : name
  }, [customizedTemplateCodes, decisionPaperTemplates, selectedDecisionPaperTemplate, t])

  /**
   * key has to change when the customized template code changes,
   * otherwise the changes are not displayed until the select
   * receives focus again. Ugly hack, but I currently see no way
   * around it.
   */
  return (
    <LoadingComboBox
      key={loadingDropdownKey}
      id={id}
      value={{
        code: selectedDecisionPaperTemplate,
        displayName: selectedDecisionPaperTemplateName,
      }}
      onChange={handleOnTemplateSelectionChanged}
      onBlur={handleOnTemplateSelectionBlur}
      useLoader={decisionPaperTemplatesLoaderForLoadingDropdown}
      errorText={t('loading.error')}
      loadingText={t('loading')}
      valueState={
        required && selectedDecisionPaperTemplateHasError ? ValueState.Error : ValueState.None
      }
      valueStateMessage={<Text>{t('error.empty')}</Text>}
      icon={
        selectedDecisionPaperTemplate && (
          <Button icon="decline" design={ButtonDesign.Transparent} onClick={onClearButtonClicked} />
        )
      }
      placeholder={t('placeholder')}
    />
  )
}

DecisionPaperTemplateDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  currentTemplateCode: PropTypes.string,
  currentTemplateName: PropTypes.string,
  customizedTemplateCodes: PropTypes.objectOf(PropTypes.bool),
  eventCode: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  displayNeutralField: PropTypes.bool,
}

export default DecisionPaperTemplateDropdown
