import { FlexBox, TableRow, TableRowType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React from 'react'
import SaveAndCancelButton from 'components/domains/deals/tables/SaveAndCancelButton'
import { kpiColumnDefinitionShape, kpiRowDataShape } from 'components/domains/kpis/KpiPropTypes'
import TableCell from 'components/ui/tables/cells/TableCell'

const KpiAdjustmentTableEditRow = ({
  rowData,
  columnDefinition,
  noChanges = true,
  onSave,
  onCancel,
}) => {
  const alignContent = (content, alignment) =>
    alignment ? <FlexBox justifyContent={alignment}>{content}</FlexBox> : content

  return (
    <TableRow key={rowData.rowKey} type={TableRowType.Inactive}>
      {columnDefinition.map((column) => (
        <TableCell key={`${rowData.rowKey}-${column.columnKey}`}>
          {alignContent(rowData[column.columnKey].cellContentEditMode, column.alignment)}
        </TableCell>
      ))}
      <TableCell>
        <SaveAndCancelButton
          id={`${rowData.rowKey}-saveCancelButtons`}
          saveDisabled={!rowData.isValid || noChanges}
          onSave={onSave}
          onCancel={onCancel}
          isLoading={false}
          showCancelPopover={true}
        />
      </TableCell>
    </TableRow>
  )
}

KpiAdjustmentTableEditRow.propTypes = {
  rowData: kpiRowDataShape,
  columnDefinition: PropTypes.arrayOf(kpiColumnDefinitionShape),
  noChanges: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
}

export default KpiAdjustmentTableEditRow
