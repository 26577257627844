import { Label, Table, TableCell, TableColumn, TableRow } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/general-information/valuation/PropertyValuationTable.module.css'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import MoneyCell from 'components/ui/tables/cells/MoneyCell'

const PropertyValuationTable = ({ isEditMode, valuations = [], handleOnChange } = {}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.general-information.valuation.table',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const formattedNumberInputRef = useRef()

  const renderSubsectionRow = (valuation) => {
    const translationToken =
      {
        ['MARKET_VALUE']: 'subsection.market-value',
        ['MORTGAGE_LENDING_VALUE']: 'subsection.mortgage-lending-value',
        ['PURCHASE_PRICE']: 'subsection.pricing',
        ['OTHER']: 'subsection.others',
      }[valuation.calculationMethodCategory] || null
    const translation = translationToken ? t(translationToken) : valuation.calculationMethodCategory
    return (
      <TableRow className={styles.subRow} key={`valuation-row-${translationToken}`}>
        <TableCell>
          <Label className={styles.subRowTitle}>{translation}</Label>
        </TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
    )
  }

  const renderTableRow = ({
    currentValue: { valueAmount } = {},
    externalMethodName,
    isEditable,
    cwpCode,
  } = {}) => {
    const translationToken =
      {
        ['MARKET_VALUE']: 'valuation-type.market-value',
        ['MORTGAGE_LENDING_VALUE']: 'valuation-type.mortgage-lending-value',
        ['REINSTATEMENT_COST']: 'valuation-type.reinstatement-cost',
        ['PURCHASE_PRICE']: 'valuation-type.purchase-price',
        ['OTHER']: 'valuation-type.others',
        ['PRELIMINARY_MARKET_VALUE']: 'valuation-type.preliminary-market-value',
        ['SELL_OFF_MARKET_VALUE']: 'valuation-type.sell-off-market-value',
        ['CURRENT_STATE_MARKET_VALUE']: 'valuation-type.current-state-market-value',
        ['PRELIMINARY_MORTGAGE_LENDING_VALUE']: 'valuation-type.preliminary-mortgage-lending-value',
        ['SELL_OFF_MORTGAGE_LENDING_VALUE']: 'valuation-type.sell-off-mortgage-lending-value',
        ['CURRENT_STATE_MORTGAGE_LENDING_VALUE']:
          'valuation-type.current-state-mortgage-lending-value',
      }[cwpCode] || null
    const translation = translationToken ? t(translationToken) : cwpCode
    return (
      <TableRow key={`valuation-row-${translationToken}`}>
        <TableCell>
          <Label>{translation}</Label>
        </TableCell>
        <TableCell>
          {isEditMode ? (
            <FormattedNumberInput
              {...(!isEditable && { inert: '' })}
              className={styles.numberInput}
              readonly={!isEditable}
              value={valueAmount.number}
              onChange={(parsedValue) => {
                const validationFunction = () => formattedNumberInputRef?.current?.isValid
                handleOnChange(cwpCode, parsedValue, undefined, undefined, validationFunction)
              }}
              icon={
                (isEditable || valueAmount.number) && (
                  <Label id={valueAmount.currency}>{valueAmount.currency}</Label>
                )
              }
              ref={formattedNumberInputRef}
            />
          ) : (
            <MoneyCell
              className={styles.moneyCell}
              primary={{ amount: valueAmount.number, currency: valueAmount.currency }}
            />
          )}
        </TableCell>
        <TableCell>
          <Label>{externalMethodName}</Label>
        </TableCell>
      </TableRow>
    )
  }

  const renderTableRows = () =>
    valuations.map((valuation) =>
      valuation.isSubsection ? renderSubsectionRow(valuation) : renderTableRow(valuation),
    )

  return (
    <div className={isEditMode ? styles.tableStyleEdit : styles.tableStyleRead}>
      <Table
        columns={
          <>
            <TableColumn>{t('valuation')}</TableColumn>
            <TableColumn>
              <div className={styles.valueColumn}>{t('value')}</div>
            </TableColumn>
            <TableColumn>{t('valuation-type')}</TableColumn>
          </>
        }
        noDataText={tNoPrefix('components.table.no-data')}
      >
        {renderTableRows()}
      </Table>
    </div>
  )
}

const valueAmountPropType = PropTypes.shape({
  number: PropTypes.number,
  currency: PropTypes.string,
})

const valuationValuePropType = PropTypes.shape({
  valuationId: PropTypes.string,
  externalCalculationMethodCode: PropTypes.string,
  externalCalculationMethodName: PropTypes.string,
  classification: PropTypes.string,
  regulatoryRelevance: PropTypes.string,
  valueAmount: valueAmountPropType.isRequired,
  validFrom: PropTypes.string,
  validTo: PropTypes.string,
  keyDate: PropTypes.string,
  isValidConversion: PropTypes.bool,
})

const valuationsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    classification: PropTypes.string,
    isSubsection: PropTypes.bool,
    cwpCode: PropTypes.string,
    currentValue: valuationValuePropType,
    historicalValues: PropTypes.arrayOf(valuationValuePropType),
    externalMethodCode: PropTypes.string,
    isEditable: PropTypes.bool,
  }),
)

PropertyValuationTable.propTypes = {
  isEditMode: PropTypes.bool,
  valuations: valuationsPropType,
  handleOnChange: PropTypes.func,
}

export default PropertyValuationTable
