export const CONDITIONS_CELL_WIDTHS = Object.freeze({
  ACTIONS_DISPLAY: 50,
  ACTIONS_EDIT_ENGLISH: 162,
  ACTIONS_EDIT_GERMAN: 220,
  WATCHER: 80,
})

export const REQUIREMENTS_CELL_WIDTHS = Object.freeze({
  ACTIONS_DISPLAY: 50,
  ACTIONS_EDIT_ENGLISH: 138,
  ACTIONS_EDIT_GERMAN: 196,
  WATCHER: 85,
})
