import isEmpty from 'lodash.isempty'
import {
  NEW_ROOT_ROW_ID_PREFIX,
  ROW_TYPE_COUNTERPARTY,
  SUBSECTION_TYPE_CODE_OWN_SHARE,
  SUBSECTION_TYPE_CODE_TOTAL,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/constants'
import { syndicationTypeCodesForTable } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/syndicationTypeCodes'

const removeTotalSection = (result) => {
  const totalSectionHeader = result.find(
    (row) => row.subSectionTypeCode === SUBSECTION_TYPE_CODE_TOTAL,
  )
  const totalSectionHeaderIndex = result.indexOf(totalSectionHeader)
  const amountOfTotalSectionRows = 2

  result.splice(totalSectionHeaderIndex, amountOfTotalSectionRows)
}

const shouldHideTotalSection = (backendTableData, editCounterparties) => {
  const areCounterpartiesPresent = backendTableData.some(
    (row) => row.rowType === ROW_TYPE_COUNTERPARTY,
  )
  const isInEditMode = !isEmpty(editCounterparties)
  return !isInEditMode && !areCounterpartiesPresent
}

const removeOwnShareSection = (result) => {
  const ownShareSectionHeader = result.find(
    (row) => row.subSectionTypeCode === SUBSECTION_TYPE_CODE_OWN_SHARE,
  )
  if (ownShareSectionHeader) {
    const ownShareSectionHeaderIndex = result.indexOf(ownShareSectionHeader)
    const amountOfTotalSectionRows = 2

    result.splice(ownShareSectionHeaderIndex, amountOfTotalSectionRows)
  }
}

const shouldHideOwnShareSection = (backendTableData) => {
  const areCounterpartiesPresent = backendTableData.some(
    (row) => row.rowType === ROW_TYPE_COUNTERPARTY,
  )
  return !areCounterpartiesPresent
}

const mergeBackendAndEditData = (backendTableData, editCounterparties) => {
  const result = [].concat(backendTableData)

  editCounterparties.forEach((editCounterparty) => {
    const isNewRow = (rowId) => rowId?.startsWith(NEW_ROOT_ROW_ID_PREFIX)

    const { rootRowId } = editCounterparty
    if (isNewRow(rootRowId)) {
      const subSectionRow = result.find(
        (entry) =>
          entry.subSectionTypeCode === editCounterparty.syndicationTypeCode &&
          entry.isSectionHeader,
      )
      const indexOfSubsectionRow = result.indexOf(subSectionRow)
      result.splice(indexOfSubsectionRow + 1, 0, editCounterparty)
    } else {
      const originalRow = result.find((entry) => entry.rootRowId === rootRowId)
      const indexOfOriginalRow = result.indexOf(originalRow)
      result.splice(indexOfOriginalRow, 1, editCounterparty)
    }
  })

  syndicationTypeCodesForTable.forEach((syndicationTypeCode) => {
    if (
      !result.some(
        (entry) => entry.syndicationTypeCode === syndicationTypeCode && !entry.isSectionHeader,
      )
    ) {
      const headerRowWithoutEntries = result.find(
        (entry) => entry.subSectionTypeCode === syndicationTypeCode && entry.isSectionHeader,
      )
      if (headerRowWithoutEntries) {
        const indexOfHeaderRowWithoutEntries = result.indexOf(headerRowWithoutEntries)
        result.splice(indexOfHeaderRowWithoutEntries, 1)
      }
    }
  })

  if (shouldHideTotalSection(backendTableData, editCounterparties)) {
    removeTotalSection(result)
  }

  if (shouldHideOwnShareSection(backendTableData)) {
    removeOwnShareSection(result)
  }

  return result
}

export default mergeBackendAndEditData
