import { FilterGroupItem, Input } from '@fioneer/ui5-webcomponents-react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FilterMarketTypesComboBox from 'components/domains/markets/filterbar//FilterMarketTypesComboBox'
import FilterCountryCodeSelect from 'components/domains/markets/filterbar/FilterCountryCodeSelect'
import DashboardFilterBar from 'components/ui/page/DashboardFilterBar'
import countActiveFilters from 'components/ui/page/countActiveFilters'

const filterOptions = {
  searchFilter: { visibleOnBar: true },
  marketTypes: { visibleOnBar: true },
  marketCountry: { visibleOnBar: true },
}

const MarketsOverviewFilterBar = ({ searchFilter, marketTypes, marketCountry, onGo }) => {
  const initialValues = {
    search_filter: searchFilter,
    market_types: marketTypes,
    market_country: marketCountry,
  }

  const [dialogOpen, setDialogOpen] = useState(false)
  const [filterParams, setFilterParams] = useState(initialValues)
  const { t } = useTranslation()

  const onEnter = ({ code }) => {
    if ((code === 'Enter' || code === 'NumpadEnter') && !dialogOpen) {
      onGo(filterParams)
    }
  }

  const handleSearchFilterChange = ({ target: { value: newSearchFilter } }) => {
    setFilterParams({ ...filterParams, search_filter: newSearchFilter })
  }

  const handleMarketTypeChange = ({ detail: { items: newMarketTypes } }) => {
    const filteredMarketTypes = newMarketTypes
      .map((element) => element.dataset.value)
      .filter(_.negate(_.isNil))
    setFilterParams({ ...filterParams, market_types: filteredMarketTypes })
  }

  const handleMarketCountryChange = (newMarketCountry) => {
    const market_country = newMarketCountry
    setFilterParams({
      ...filterParams,
      market_country,
    })
  }

  const filters = [
    <FilterGroupItem
      label={t('pages.markets.overview-search.filter.search-filter.label')}
      key="searchFilter"
    >
      <Input
        id="market-overview.dashboard.search-filter"
        value={filterParams.search_filter}
        onInput={handleSearchFilterChange}
        onKeyDown={onEnter}
        showClearIcon
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={t('pages.markets.overview-search.filter.market-type.label')}
      key="marketTypes"
    >
      <FilterMarketTypesComboBox
        selectedMarketTypes={filterParams.market_types}
        onSelectionChange={handleMarketTypeChange}
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={t('pages.markets.overview-search.filter.market-country.label')}
      key="marketCountry"
    >
      <FilterCountryCodeSelect
        currentCountry={filterParams.market_country}
        handleOnChange={handleMarketCountryChange}
      />
    </FilterGroupItem>,
  ]

  return (
    <DashboardFilterBar
      filterOptions={filterOptions}
      setDialogOpen={setDialogOpen}
      activeFiltersCount={countActiveFilters(filterParams)}
      onGo={() => onGo(filterParams)}
    >
      {filters}
    </DashboardFilterBar>
  )
}

MarketsOverviewFilterBar.propTypes = {
  searchFilter: PropTypes.string.isRequired,
  marketTypes: PropTypes.arrayOf(PropTypes.string.isRequired),
  marketCountry: PropTypes.string.isRequired,
  onGo: PropTypes.func.isRequired,
}

export default MarketsOverviewFilterBar
