import isEmpty from 'lodash.isempty'
import { useCallback, useMemo } from 'react'
import { entityTypes, eventStatus } from 'api/events/status'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useDecisionStages from 'hooks/services/business-events-and-tasks/decision-process/stages/useDecisionStages'
import { useEvents } from 'hooks/services/business-events-and-tasks/events/useEvents'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useTranchesWithSubEntities from 'hooks/services/deals/financing/useTranchesWithSubEntities'
import { useMemberRoleTypeCodes } from 'hooks/services/deals/syndication/configGetters/useMemberRoleTypeCodes'
import useDealMini from 'hooks/services/deals/useDealMini'

const CREDIT_DECISION_CODE = 'CREDIT_DECISION'
const APPROVED_CODE = 'APPROVED'

const useOpsMemoTrancheDetails = ({ entityRef: { entityId: dealUuid } }, tileId, tileCode) => {
  const {
    data: eventData,
    isLoading: isEventsLoading,
    isError: isEventsError,
  } = useEvents(
    {},
    {
      entityId: dealUuid,
      entityTypes: entityTypes.deal,
      status: [eventStatus.ongoing, eventStatus.completed],
    },
  )

  const creditDecisionEvents =
    eventData?.events?.filter((event) => event?.info?.code === CREDIT_DECISION_CODE) ?? []

  const lastCreatedCreditDecisionEvent = creditDecisionEvents?.reduce(
    (a, b) => (new Date(a?.info?.creationDate) > new Date(b?.info?.creationDate) ? a : b),
    0,
  )
  const creditDecisionEventId = lastCreatedCreditDecisionEvent?.id

  const {
    data: { decisionStages: decisionStageData } = {},
    isLoading: isLoadingDecisionStages,
    isError: isErrorDecisionStages,
  } = useDecisionStages({
    eventId: creditDecisionEventId,
  })

  const getApprovedDate = useCallback(() => {
    if (isEmpty(decisionStageData)) {
      return undefined
    }
    const isEveryStageApproved = decisionStageData.every(
      (stage) => stage?.info?.decisionStatus === APPROVED_CODE,
    )
    if (!isEveryStageApproved) {
      return undefined
    }

    return decisionStageData.reduce(
      (a, b) => (new Date(a?.info?.decisionDate) > new Date(b?.info?.decisionDate) ? a : b),
      0,
    )?.info?.decisionDate
  }, [decisionStageData])

  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
    errors: dealUuidErrors,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    data: dealData,
    isFetching: isFetchingDealData,
    isError: isErrorDealData,
    error: dealError,
  } = useDealMini(dealUuidByTileCode)

  const {
    data: tranchesData,
    embeddedOptions: tranchesEmbeddedOptionsData,
    isFetching: isFetchingTranchesData,
    isError: isErrorTranchesData,
    error: tranchesError,
  } = useTranchesWithSubEntities({ dealUuid: dealUuidByTileCode })

  const borrowerIds = useMemo(() => {
    const ids = []

    if (dealData?.borrowerBpId) {
      ids.push(dealData.borrowerBpId)
    }

    if (tranchesData?.tranches) {
      ids.push(
        ...tranchesData.tranches
          .filter((tranche) => tranche?.borrowerId)
          .map((tranche) => tranche?.borrowerId),
      )
    }

    return ids
  }, [dealData?.borrowerBpId, tranchesData?.tranches])

  const {
    data: businessPartnerMiniData,
    isFetching: isFetchingBusinessPartnerMiniData,
    isError: isErrorBusinessPartnerMiniData,
    error: businessPartnerMiniError,
  } = useBusinessPartnerMiniByIds(borrowerIds)

  const {
    data: { typeCodes: memberRoleTypeCodes },
    isFetching: isFetchingMemberRoleTypeCodes,
    isError: isErrorMemberRoleTypeCodes,
    error: memberRoleTypeCodesError,
  } = useMemberRoleTypeCodes()

  const sourcePathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?dataSource=newBusiness&working-version=true' : ''),
    [isWorkingVersionDataTile],
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isEventsLoading,
      isLoadingDecisionStages && !!creditDecisionEventId,
      isDealUuidFetching,
      isFetchingDealData,
      isFetchingTranchesData,
      isFetchingBusinessPartnerMiniData,
      isFetchingMemberRoleTypeCodes,
    ],
    errorValues: [
      isEventsError,
      isErrorDecisionStages,
      isDealUuidError,
      isErrorDealData,
      isErrorTranchesData,
      isErrorBusinessPartnerMiniData,
      isErrorMemberRoleTypeCodes,
    ],
    errorDetails: [
      ...dealUuidErrors,
      dealError,
      tranchesError,
      businessPartnerMiniError,
      memberRoleTypeCodesError,
    ],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error, data: undefined }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false, data: undefined }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        sourceRender: { dealId: dealData.dealId, sourcePathSuffix },
        dealData: dealData || {},
        creditDecisionApprovedDate: getApprovedDate(),
        tranchesData: tranchesData || {},
        tranchesEmbeddedOptionsData: JSON.stringify(tranchesEmbeddedOptionsData || []),
        businessPartnersData: businessPartnerMiniData?.businessPartnerMinis || [],
        memberRoleTypeCodesData: memberRoleTypeCodes || [],
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealData,
    sourcePathSuffix,
    getApprovedDate,
    tranchesData,
    tranchesEmbeddedOptionsData,
    businessPartnerMiniData?.businessPartnerMinis,
    memberRoleTypeCodes,
    error,
  ])
}

export default useOpsMemoTrancheDetails
