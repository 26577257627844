import { CheckBox } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { selectEvent, deselectEvent } from 'redux/slices/events/eventOverviewSlice'

const EventOverviewTableCheckbox = ({ eventId, status }) => {
  const dispatch = useDispatch()

  const selected = !isNil(
    useSelector((state) => state.events.eventOverview.selectedRows.selectedEventIds[eventId]),
  )

  return (
    <CheckBox
      checked={selected}
      onChange={({ target: { checked } }) =>
        checked ? dispatch(selectEvent({ eventId, status })) : dispatch(deselectEvent(eventId))
      }
    />
  )
}

EventOverviewTableCheckbox.propTypes = {
  eventId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
}

export default EventOverviewTableCheckbox
