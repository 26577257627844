import isNil from 'lodash.isnil'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'components/domains/markets/detail/cards/MarketMacroProxiesTable.module.css'

import {
  marketProxyColumn,
  useMarketProxyColumns,
  useMarketProxyTableData,
} from 'components/domains/markets/detail/cards/helper/useMarketProxyColumns'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import {
  setOverrideSettings,
  setMonitoring,
  setThreshold,
  setThresholdType,
  setDirection,
} from 'redux/slices/markets/marketMacroProxiesSlice'

const MarketMacroProxiesTable = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.markets.detail.manager.market-proxies.table',
  })
  const dispatch = useDispatch()

  const proxiesState = useSelector((state) => state.markets.marketMacroProxies)
  const proxyColumns = useMarketProxyColumns()

  const hasProxies = useMemo(
    () =>
      !isNil(proxiesState) &&
      proxiesState.proxies.some(
        ({ defaultProxy, overrideProxy }) => !isNil(defaultProxy) || !isNil(overrideProxy),
      ),
    [proxiesState],
  )

  const onChange = useCallback(
    ({ column, key, value }) => {
      if (column === marketProxyColumn.setting) {
        dispatch(setOverrideSettings({ key, value }))
      }
      if (column === marketProxyColumn.monitoring) {
        dispatch(setMonitoring({ key, value }))
      }
      if (column === marketProxyColumn.thresholdValue) {
        dispatch(setThreshold({ key, value }))
      }
      if (column === marketProxyColumn.thresholdType) {
        dispatch(setThresholdType({ key, value }))
      }
      if (column === marketProxyColumn.direction) {
        dispatch(setDirection({ key, value }))
      }
    },
    [dispatch],
  )

  const tableData = useMarketProxyTableData({
    proxiesState,
    hasProxies,
    isEditing: proxiesState.isEditing,
    onChange,
  })

  return (
    <SortedTable
      columnDefinitions={proxyColumns}
      tableData={tableData}
      additionalTableProperties={{
        stickyColumnHeader: true,
        className: styles.tableProperties,
      }}
      toolbarConfig={{
        showColumnSelection: false,
        title: t('toolbar.title.macro'),
      }}
      noDataText={t('no-data.macro')}
    />
  )
}

MarketMacroProxiesTable.propTypes = {}

export default MarketMacroProxiesTable
