import { isConflictError, isMissingPermissionError, isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const RETRIES = 3

const useAuthorityLevelCalculator = (businessPartnerId, unitHeadBpId, unitTypeId) =>
  useCamelizedResponse(
    useRequest({
      path: `/pbb-custom-service/businesspartners/${businessPartnerId}/authority-level-calculator?unitHeadBpId=${unitHeadBpId}&unitTypeId=${unitTypeId}`,
      translated: true,
      keys: [
        'businesspartners',
        businessPartnerId,
        'authority-level-calculator',
        'unitHead',
        unitHeadBpId,
        'unitType',
        unitTypeId,
      ],
      options: {
        enabled: !!unitTypeId,
        retry: (failureCount, error) =>
          !(
            isMissingPermissionError(error) ||
            failureCount >= RETRIES ||
            isConflictError(error) ||
            isNotFoundError(error)
          ),
      },
    }),
  )

export default useAuthorityLevelCalculator
