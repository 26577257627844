import find from 'lodash.find'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionTypes, conditionsEntityTypes } from 'api/conditions/conditions'
import ConditionsTableAssigneeCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/conditions/ConditionsTableAssigneeCell'
import ConditionsTableAssigneeCellV1 from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/conditions/ConditionsTableAssigneeCellV1'
import ConditionsTableCategoryCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/conditions/ConditionsTableCategoryCell'
import ConditionsTableExternalVisibilitiesCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/conditions/ConditionsTableExternalVisibilitiesCell'
import ConditionsTableInternalApprovalLevelCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/conditions/ConditionsTableInternalApprovalLevelCell'
import ConditionsTableNameCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/conditions/ConditionsTableNameCell'
import ConditionsTableRefNumberCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/conditions/ConditionsTableRefNumberCell'
import ConditionsTableReferencesDecisionPaperCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/conditions/ConditionsTableReferencesDecisionPaperCell'
import ConditionsTableStatusCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/conditions/ConditionsTableStatusCell'
import ConditionsTableStatusCellV1 from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/conditions/ConditionsTableStatusCellV1'
import ConditionsTableTypeCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/conditions/ConditionsTableTypeCell'
import { TILE_VERSION as tileVersion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'
import { availableFilterTypes } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/conditions/shared/ConditionsTableToolbarFilterDialog'
import useApprovalLevels from 'hooks/services/conditions/config/useApprovalLevels'
import useExternalStatus from 'hooks/services/conditions/config/useExternalStatus'
import useExternalTypes from 'hooks/services/conditions/config/useExternalTypes'
import useInternalStatus from 'hooks/services/conditions/config/useInternalStatus'
import useInternalTypes from 'hooks/services/conditions/config/useInternalTypes'
import useVisibilities from 'hooks/services/conditions/config/useVisibilities'

const cellDefaults = {
  disableDragAndDrop: true,
  disableGlobalFilter: true,
  disableFilters: true,
  disableSortBy: true,
  disableGroupBy: true,
  isSelectableForHiding: true,
  sortingDisabled: false,
}

const defaultSorting = {
  [conditionTypes.external]: {
    sortDescending: false,
    sortingKey: [
      'condition_status',
      'condition_type',
      'condition_category',
      'condition_ref_number',
      'condition_name',
    ],
  },
  [conditionTypes.internal]: {
    sortBy: 'type',
    sortDescending: false,
    sortingKey: 'condition_type',
  },
}

const calculateInitialColumnSelection = (columns, defaultHiddenColumns) =>
  columns.map((column) => {
    const preparedColumn = {
      ...column,
      isVisible: true,
      columnKey: column.id,
      title: column.Header,
    }
    if (defaultHiddenColumns.includes(column.id)) {
      return { ...preparedColumn, isVisible: false }
    }
    return preparedColumn
  })

const filterColumnsBasedOnEntityType =
  (entityType) =>
  ({ onlyVisibleForEntityTypes } = {}) => {
    if (!onlyVisibleForEntityTypes) {
      return true
    }
    return onlyVisibleForEntityTypes.includes(entityType)
  }

const emptyArray = []

const useDecisionPaperConditionTableColumns = ({
  shownColumns,
  defaultHiddenColumns = emptyArray,
  conditionType = conditionTypes.external,
  entityType,
  tileVersion: providedTileVersion,
}) => {
  const { t } = useTranslation('decisionPaper', { keyPrefix: 'components.conditions.table' })
  const { format: formatIsoDate } = useShortDateFormatter()

  const buildDefaultLoadingHookParams = useCallback(
    () => ({ entityType, includeDeprecated: true }),
    [entityType],
  )

  const allColumns = useMemo(
    () => ({
      name: {
        ...cellDefaults,
        Header: t('columns.name'),
        accessor: ({ id: conditionId, info: { name } }) => ({ name, conditionId }),
        id: 'name',
        minWidth: 240,
        sortingKey: 'condition_name',
        Cell: ConditionsTableNameCell,
        filter:
          conditionType === conditionTypes.external
            ? {
                type: availableFilterTypes.condition,
                filterKey: 'externalConditionId',
                label: t('filter.condition'),
              }
            : undefined,
      },
      category: {
        ...cellDefaults,
        Header: t('columns.category'),
        accessor: ({ id: conditionId, info: { category } }) => ({ category, conditionId }),
        id: 'category',
        sortingKey: 'condition_category',
        Cell: ConditionsTableCategoryCell,
      },
      type: {
        ...cellDefaults,
        Header: t('columns.type'),
        accessor: ({
          id: conditionId,
          info: {
            type: { name: typeName, code: typeCode },
          },
        }) => ({ typeName, typeCode, conditionId }),
        id: 'type',
        filter: {
          type: availableFilterTypes.loadingDropdownSingleSelect,
          useLoadingHook:
            conditionType === conditionTypes.internal ? useInternalTypes : useExternalTypes,
          buildLoadingHookParams: buildDefaultLoadingHookParams,
          selectionName: 'types',
          filterKey: 'type',
        },
        sortingKey: 'condition_type',
        Cell: ConditionsTableTypeCell,
      },
      refNumber: {
        ...cellDefaults,
        Header: t('columns.ref-number'),
        accessor: ({ id: conditionId, info: { refNumber } }) => ({ refNumber, conditionId }),
        id: 'refNumber',
        sortingKey: 'condition_ref_number',
        Cell: ConditionsTableRefNumberCell,
      },
      approvalLevel: {
        ...cellDefaults,
        Header: t('columns.approval-level'),
        accessor: ({ id: conditionId, info: { approvalLevel } }) => ({
          approvalLevelName: approvalLevel?.name,
          approvalLevelCode: approvalLevel?.code,
          conditionId,
        }),
        id: 'approvalLevel',
        filter: {
          type: availableFilterTypes.loadingDropdownSingleSelect,
          useLoadingHook: useApprovalLevels,
          buildLoadingHookParams: buildDefaultLoadingHookParams,
          selectionName: 'approvalLevels',
          filterKey: 'approvalLevel',
        },
        sortingKey: 'condition_approval_level',
        Cell: ConditionsTableInternalApprovalLevelCell,
      },
      // This is a custom cell just for the decision paper since loading the entityNames differs for the decision paper and the
      // live external conditions on the conditions page
      referencesDecisionPaper: {
        ...cellDefaults,
        Header:
          providedTileVersion === tileVersion.V1 ? t('columns.reference') : t('columns.references'),
        accessor: ({ id, references, businessObjectRef }) => ({
          id,
          references,
          businessObjectRef,
        }),
        id: 'referencesDecisionPaper',
        Cell: ConditionsTableReferencesDecisionPaperCell,
        filter: {
          type: availableFilterTypes.referencesMultiSelect,
          filterKey: 'references',
        },
        sortingDisabled: true,
      },
      visibilities: {
        ...cellDefaults,
        Header: t('columns.visibilities'),
        accessor: ({ id: conditionId, info: { visibilities } }) => ({ visibilities, conditionId }),
        id: 'visibilities',
        filter: {
          type: availableFilterTypes.loadingDropdownMultiComboBox,
          useLoadingHook: useVisibilities,
          buildLoadingHookParams: buildDefaultLoadingHookParams,
          selectionName: 'visibilities',
          filterKey: 'visibilities',
        },
        Cell: ConditionsTableExternalVisibilitiesCell,
        sortingDisabled: true,
        minWidth: 120,
      },
      assignee: {
        ...cellDefaults,
        Header: t('columns.assignee'),
        accessor: ({ id, info: { assignee } }) => ({ id, assignee }),
        id: 'assignee',
        filter: {
          type: availableFilterTypes.assignee,
          filterKey: 'assignee',
        },
        minWidth: 90,
        Cell:
          providedTileVersion === tileVersion.V1
            ? ConditionsTableAssigneeCellV1
            : ConditionsTableAssigneeCell,
        sortingDisabled: true,
      },
      externalAssignee: {
        ...cellDefaults,
        Header: t('columns.external-assignee'),
        id: 'externalAssignee',
        onlyVisibleForEntityTypes: [conditionsEntityTypes.deal],
        filter: {
          type: availableFilterTypes.externalAssignee,
          filterKey: 'externalAssignee',
        },
        minWidth: 90,
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
      creationDate: {
        ...cellDefaults,
        Header: t('columns.creation-date'),
        accessor: ({ info: { creationDate } }) => formatIsoDate(creationDate),
        id: 'creationDate',
        filter: {
          type: availableFilterTypes.betweenDates,
          filterKey: 'creationDate',
        },
        sortingKey: 'condition_creation_date',
        isSelectableForHiding: false,
      },
      status: {
        ...cellDefaults,
        Header: t('columns.status'),
        accessor: ({ status: { type, name, code }, requirements, id: conditionId }) => ({
          status: { type, name, code },
          requirements,
          conditionId,
        }),
        id: 'status',
        filter: {
          type: availableFilterTypes.loadingDropdownMultiComboBox,
          useLoadingHook:
            conditionType === conditionTypes.internal ? useInternalStatus : useExternalStatus,
          buildLoadingHookParams: buildDefaultLoadingHookParams,
          selectionName: 'status',
          filterKey: 'status',
        },
        minWidth: 120,
        sortingKey: 'condition_status',
        Cell:
          providedTileVersion === tileVersion.V1
            ? ConditionsTableStatusCellV1
            : ConditionsTableStatusCell,
      },
    }),
    [t, conditionType, buildDefaultLoadingHookParams, providedTileVersion, formatIsoDate],
  )

  const columns = useMemo(
    () =>
      shownColumns
        .map((columnKey) => allColumns[columnKey])
        .filter(filterColumnsBasedOnEntityType(entityType)),
    [allColumns, entityType, shownColumns],
  )

  const [columnSelection, setColumnSelection] = useState(
    calculateInitialColumnSelection(columns, defaultHiddenColumns),
  )

  const [searchParam, setSearchParam] = useState('')
  const [sorting, setSorting] = useState(defaultSorting[conditionType])
  const sortableColumns = useMemo(
    () => columnSelection.filter(({ sortingDisabled }) => !sortingDisabled),
    [columnSelection],
  )

  const onUpdateSorting = useCallback(
    ({ sortBy, sortDescending }) => {
      const selectedColumn = find(sortableColumns, ['title', sortBy])
      setSorting({
        sortBy: selectedColumn?.columnKey ?? defaultSorting[conditionType].sortBy,
        sortDescending,
        sortingKey: selectedColumn?.sortingKey ?? defaultSorting[conditionType].sortingKey,
      })
    },
    [sortableColumns, conditionType],
  )

  return useMemo(
    () => ({
      columns,
      columnSelection,
      setColumnSelection,
      sortableColumns,
      sorting,
      onUpdateSorting,
      searchParam,
      setSearchParam,
    }),
    [
      columns,
      columnSelection,
      setColumnSelection,
      sorting,
      onUpdateSorting,
      sortableColumns,
      searchParam,
      setSearchParam,
    ],
  )
}

export default useDecisionPaperConditionTableColumns
