export const getPublishedRentRollContentDefinitions = (t) => [
  {
    contentKey: 'occupancy_status',
    title: t('pages.rent-roll.table.label.occupancy'),
  },
  {
    contentKey: 'tenant',
    title: t('pages.rent-roll.table.column.tenant'),
  },
  {
    contentKey: 'name',
    title: t('pages.rent-roll.table.column.rental-unit'),
  },
  {
    contentKey: 'segment_name',
    title: t('pages.rent-roll.table.column.segment'),
  },
  {
    contentKey: 'segment_usage_type_name',
    title: t('pages.rent-roll.table.label.usage-type'),
  },
  {
    contentKey: 'area',
    title: t('pages.rent-roll.table.column.area'),
    className: 'property-rent-roll-table-col-right',
  },
  {
    contentKey: 'rent_contracted_year',
    title: t('pages.rent-roll.table.column.annulized-contracted-rent'),
    className: 'property-rent-roll-table-col-right',
  },
  {
    contentKey: 'rent_current_year',
    title: t('pages.rent-roll.table.column.annualized-current-rent'),
    className: 'property-rent-roll-table-col-right',
  },
  {
    contentKey: 'rent_start_date',
    title: t('pages.rent-roll.table.column.rent-start'),
  },
  {
    contentKey: 'lease_start_date',
    title: t('pages.rent-roll.table.label.lease-start'),
  },
  {
    contentKey: 'lease_break_date',
    title: t('pages.rent-roll.table.label.lease-break'),
  },
  {
    contentKey: 'lease_expiry_date',
    title: t('pages.rent-roll.table.label.lease-expiry'),
  },
  {
    contentKey: 'no_lease_expiry',
    title: t('pages.rent-roll.table.label.no-lease-expiry'),
  },
  {
    contentKey: 'anonymous_tenant',
    title: t('pages.rent-roll.table.label.anonymous-tenant'),
  },
  {
    contentKey: 'branding_franchise',
    title: t('pages.rent-roll.table.label.branding-franchise.name'),
  },
  {
    contentKey: 'number_of_units',
    title: t('pages.rent-roll.table.label.number-of-units'),
    className: 'property-rent-roll-table-col-right',
  },
  {
    contentKey: 'rent_arrears',
    title: t('pages.rent-roll.table.label.rent-arrear'),
    className: 'property-rent-roll-table-col-right',
  },
  {
    contentKey: 'rent_dispute',
    title: t('pages.rent-roll.table.label.rent-dispute'),
  },
  {
    contentKey: 'lease_comment',
    title: t('pages.rent-roll.table.label.comment'),
  },
]
