import {
  CustomListItem,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  ListItemType,
  Text,
  Title,
  TitleLevel,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/gam-statement/shared/EmptyCard.module.css'

const EmptyCardContent = ({ title, subtitle }) => {
  const { t } = useTranslation('decisionPaper', { keyPrefix: 'components.ui.card.empty-card' })

  const renderedTitle = title ?? t('title')
  const renderedSubtitle = subtitle ?? t('subtitle')

  return (
    <CustomListItem type={ListItemType.Inactive}>
      <FlexBox
        alignItems={FlexBoxAlignItems.Center}
        fitContainer
        className={styles['cwp-empty-card-wrapper']}
        direction={FlexBoxDirection.Column}
      >
        {renderedTitle && (
          <Title
            level={TitleLevel.H5}
            wrappingType={WrappingType.Normal}
            style={{ textAlign: 'center' }}
          >
            {renderedTitle}
          </Title>
        )}
        {renderedSubtitle && (
          <Text
            className={styles['cwp-empty-card-subtitle']}
            wrapping={true}
            style={{ textAlign: 'center' }}
          >
            {renderedSubtitle}
          </Text>
        )}
      </FlexBox>
    </CustomListItem>
  )
}

EmptyCardContent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default EmptyCardContent
