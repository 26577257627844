import { Tree, TreeItem } from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import get from 'lodash.get'
import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import EmptyNewBusinessCheckContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/EmptyNewBusinessCheckContent'
import NewBusinessCheckQuestion from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/NewBusinessCheckQuestion'
import { NewBusinessCheckResultMessageStrip } from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/NewBusinessCheckResultMessageStrip'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/QuestionnaireTile.module.css'
import {
  Answer,
  getInfoMessageTypesToDisplay,
  getKeyPathToProceedWith,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/questionnaireStructure'
import { useQuestionnaireHelpers } from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/useQuestionnaireHelpers'

const propTypes = {
  tileId: PropTypes.string.isRequired,
  currentContent: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
  questionnaireStructure: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  availableEndings: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  questionTranslationPrefix: PropTypes.string.isRequired,
}

/**
 * @typedef {object} overrides
 * @property {Parameters<typeof useQuestionnaireHelpers>[0]["baseStructure"]} questionnaireStructure
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 */
const QuestionnaireTile = ({
  tileId,
  currentContent: currentContentStringified,
  onChange,
  isEditMode,
  isPdfView,
  setHasContentChanges,
  questionnaireStructure,
  availableEndings,
  questionTranslationPrefix,
}) => {
  const { answers: currentAnswers, comments: currentComments } = useMemo(
    () =>
      currentContentStringified && typeof currentContentStringified === 'string'
        ? JSON.parse(currentContentStringified)
        : {},
    [currentContentStringified],
  )

  const {
    buildResults,
    calculateEnabledQuestions,
    hasAnswerStructureNestedValue,
    handleOnChange,
    handleCommentChange,
  } = useQuestionnaireHelpers({
    currentAnswers,
    currentComments,
    baseStructure: questionnaireStructure,
    possibleEndings: availableEndings,
    onChange,
  })

  const [enabledQuestions, setEnabledQuestions] = useState(buildResults(questionnaireStructure))
  const availableEndingsKeys = useMemo(
    () => availableEndings.map(({ key }) => key),
    [availableEndings],
  )
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: questionTranslationPrefix,
  })
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const showEditMode = useMemo(() => !isPdfView && isEditMode, [isEditMode, isPdfView])

  useEffect(() => {
    setEnabledQuestions(calculateEnabledQuestions(currentAnswers, questionnaireStructure))
  }, [calculateEnabledQuestions, currentAnswers, questionnaireStructure])

  useEffect(() => {
    const hasContentChanges =
      !(isNil(currentContentStringified) && isNil(tileStatus?.data?.data)) &&
      !isEqual(tileStatus?.data?.data, currentContentStringified)
    setHasContentChanges(hasContentChanges)
  }, [currentContentStringified, setHasContentChanges, tileStatus?.data?.data])

  /**
   * @param {Parameters<typeof getKeyPathToProceedWith>[2]} proceedWith
   * @param {Parameters<typeof getKeyPathToProceedWith>[0]} keyPath
   */
  const resultStripBelowQuestionInEdit = (proceedWith, keyPath) => {
    const proceedWithKeyPath = getKeyPathToProceedWith(keyPath, currentAnswers, proceedWith)
    if (isEditMode && proceedWithKeyPath) {
      return availableEndings.find((item) => isEqual(item.key, proceedWithKeyPath[0]))
    }
  }

  /**
   * @param {typeof questionnaireStructure} data
   * @param {number} level
   * @param {string[]} keyPath
   */
  const renderTree = (data, level, keyPath) =>
    data.map((child, index) => {
      const keyPathWithChild = [...keyPath, child.key]

      const hasChildren = child?.children?.length
      if (hasChildren) {
        const structureForTreeExpansion = isEditMode ? enabledQuestions : currentAnswers
        const isRelevantQuestionBelowNode = hasAnswerStructureNestedValue(
          get(structureForTreeExpansion, keyPathWithChild),
          isEditMode ? [true, false] : Object.values(Answer),
        )

        if (!isEditMode && !isRelevantQuestionBelowNode) {
          return
        }

        return (
          <TreeItem
            expanded={isRelevantQuestionBelowNode}
            className={compact([
              styles.node,
              index === 0 && styles.firstNodeAtLevel,
              data.length - 1 === index && styles.lastNodeAtLevel,
            ]).join(' ')}
            text={t(keyPathWithChild.join('.'))}
            key={keyPathWithChild.join()}
          >
            {child?.hint && (
              <TreeItem
                className={styles.internalNodeHint}
                text={t(`${keyPathWithChild.join('.')}.hint`)}
              />
            )}
            {renderTree(child?.children ?? [], level + 1, keyPathWithChild)}
          </TreeItem>
        )
      }

      const answer = get(currentAnswers, keyPathWithChild)
      if (isEditMode || !isNil(answer)) {
        return (
          <NewBusinessCheckQuestion
            key={keyPathWithChild.join('-')}
            question={child}
            questionResult={answer}
            keyPath={keyPathWithChild}
            showEditMode={showEditMode}
            questionIsDisabled={!get(enabledQuestions, keyPathWithChild)}
            resultMessageStrip={resultStripBelowQuestionInEdit(child.proceedWith, keyPathWithChild)}
            infoMessageTypes={getInfoMessageTypesToDisplay(
              keyPathWithChild,
              currentAnswers,
              child?.infos ?? [],
            )}
            t={t}
            handleOnChange={handleOnChange}
            questionComment={get(currentComments, keyPathWithChild)}
            handleCommentChange={handleCommentChange}
            className={compact([
              styles.node,
              styles.leafNode,
              level > 1 && styles.nestedLeafNode,
              index === 0 && styles.firstNodeAtLevel,
              data.length - 1 === index && styles.lastNodeAtLevel,
            ]).join(' ')}
          />
        )
      }
    })

  const getActiveEnding = () =>
    Object.entries(enabledQuestions).find(
      ([key, value]) => availableEndingsKeys.includes(key) && value === true,
    )?.[0]

  if (!isEditMode && !currentAnswers) {
    return <EmptyNewBusinessCheckContent />
  }

  return (
    <>
      <NewBusinessCheckResultMessageStrip
        activeEnding={getActiveEnding()}
        possibleEndings={availableEndings}
        isEditMode={isEditMode}
        t={t}
      />
      <Tree className={styles.tree}>{renderTree(questionnaireStructure, 1, [])}</Tree>
    </>
  )
}

QuestionnaireTile.propTypes = propTypes

export default QuestionnaireTile
