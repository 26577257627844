import { ValueState } from '@fioneer/ui5-webcomponents-react'
import {
  ACTIVE,
  APPROVED,
  OBSOLETE,
  OUTDATED,
  PROPOSED,
} from 'components/domains/business-partners/tile/risk-monitoring/forbearance/constants'

const resolveLifecycleStatusCodeToValueState = (statusCode) => {
  // TODO: CWP-12911: Might be that the codes are not correct
  switch (statusCode) {
    case PROPOSED:
      return ValueState.Information
    case APPROVED:
      return ValueState.Information
    case ACTIVE:
      return ValueState.Success
    case OUTDATED:
      return ValueState.None
    case OBSOLETE:
      return ValueState.None
    default:
      return ValueState.None
  }
}

export default resolveLifecycleStatusCodeToValueState
