import {
  Input,
  ResponsiveGridLayout,
  Text,
  Title,
  TitleLevel,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BusinessPartnerAutocompleteInput from 'components/domains/deals/creation-dialog/BusinessPartnerAutocompleteInput'
import styles from 'components/domains/deals/creation-dialog/DealCreationDialog.module.css'
import Labeled from 'components/ui/data/Labeled'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { standardMappings, useFormMapped } from 'hooks/form/useFormMapped'
import { useFormValidations } from 'hooks/form/useFormValidations'
import { useFormattedNumberParser, useNumberFormatter } from 'hooks/i18n/useI18n'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import { useBusinessPartnerAllowedOperations } from 'hooks/services/business-partners/useBusinessPartnerAllowedOperations'
import { useIsBorrower } from 'hooks/services/deals/borrower/useIsBorrower'
import useRoleConfig, { RoleConfig } from 'hooks/services/deals/configurations/useRoleConfig'
import { useCurrencyCodes as useFinancingCurrencyCodes } from 'hooks/services/deals/financing/configGetters/useCurrencyCodes'
import { useLoanTypeCodes } from 'hooks/services/deals/financing/configGetters/useLoanTypeCodes'

const formatBusinessPartner = (businessPartner) => {
  const fullName = businessPartner?.fullName
  if (!fullName) {
    return ''
  }
  const id = businessPartner?.bpId
  return id ? `${fullName} (${id})` : fullName
}

const getBusinessPartnerSearchString = (value) => {
  const matches = value.match(/^(.+) \(.+\)$/)
  return matches?.[1] ?? value
}

export const BORROWER_NAME_MAX_LENGTH = 40

const DealCreationCounterpartyStep = ({ enabled = true, wizardStep }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.dialog.counterparty-and-financing',
  })
  const { required, maxSafeMonetary, positive } = useFormValidations({ enabled })
  const { register, control, getValues } = useFormMapped()
  const { bpId: borrowerId, fullName: borrowerName = '' } = getValues('counterParty.borrower') || {}
  const { bpId: sponsorId, fullName: sponsorName = '' } =
    getValues('counterParty.sponsor')?.[0] || {}
  const { data: sponsor } = useBusinessPartnerById(sponsorId)
  const { data: borrower } = useBusinessPartnerById(borrowerId)
  const { data: isBorrower } = useIsBorrower(borrowerId)
  const { data: [prospectBorrowerRole] = [] } = useRoleConfig(RoleConfig.BORROWER)
  const { data: businessPartnerAllowedOperationsData = {} } = useBusinessPartnerAllowedOperations()
  const formatNumber = useNumberFormatter({ maximumFractionDigits: 2, minimumFractionDigits: 2 })
  const parseNumber = useFormattedNumberParser()
  const totalCommitment = getValues('tranche.totalCommitment.number')

  const { data: sponsorRoles = [] } = useRoleConfig(RoleConfig.SPONSOR)

  const isAllowedToUpdateBusinessPartner =
    !!businessPartnerAllowedOperationsData.allowedOperations?.includes(
      'BUSINESS_PARTNER_UPDATE_NAME',
    )

  const linkingPermission = 'UNIT_RELATIONSHIP_CREATE'
  const isAllowedToCreateSponsorRelationship =
    !!sponsor?.allowedOperations?.includes(linkingPermission)
  const isAllowedToCreateBorrowerRelationship = !!(
    (borrowerId && borrower?.allowedOperations.includes(linkingPermission)) ||
    (!borrowerId && borrowerName)
  )

  const isAllowedToLinkSponsor = !!(
    isAllowedToCreateSponsorRelationship && isAllowedToCreateBorrowerRelationship
  )

  const sponsorStateMessage =
    sponsorName && (!isAllowedToUpdateBusinessPartner || !isAllowedToLinkSponsor)
      ? t('sponsor.update')
      : null
  const sponsorInvalidMessage = (isInvalid) => (isInvalid ? t('sponsor.required') : null)

  const calculateSponsorValueState = (isInvalid) => {
    if (sponsorStateMessage) return ValueState.Information
    if (isInvalid) return ValueState.Error
    return undefined
  }

  let borrowerStateMessage = null

  if (!borrowerId && borrowerName && !isAllowedToUpdateBusinessPartner) {
    borrowerStateMessage = t('borrower.missing-permission')
  } else if (borrowerName && !borrowerId) {
    borrowerStateMessage = t('borrower.create-new')
  } else if (borrowerName && !isBorrower) {
    borrowerStateMessage = t('borrower.change-role')
  }

  const calculateBorrowerValueState = (isInvalid) => {
    if (borrowerStateMessage) return ValueState.Information
    if (isInvalid) return ValueState.Error
    return undefined
  }

  return (
    <ResponsiveGridLayout
      columnsS={1}
      columnsM={2}
      columnsL={2}
      columnsXL={2}
      columnGap="1rem"
      rowGap="1rem"
      className="deal-creation-dialog-step"
    >
      <div style={{ gridColumn: 'span 2' }}>
        <Title level={TitleLevel.H4}>{t('title', { wizardStep })}</Title>
      </div>
      <div>
        <Labeled label={t('sponsor')} vertical showColon required>
          <Controller
            control={control}
            name="counterParty.sponsor"
            rules={{
              validate: {
                required: required('0'),
                sponsorName: required('0.fullName'),
              },
            }}
            render={({
              field: { onChange, onBlur, value, name },
              fieldState: { invalid, error },
            }) => (
              <BusinessPartnerAutocompleteInput
                id="deal-create-sponsor"
                defaultValue={formatBusinessPartner(value?.[0])}
                suggestionItemProps={({ name: fullName, id: bpId }) => ({
                  text: formatBusinessPartner({ fullName, bpId }),
                })}
                additionalSearchParams={{ roles: sponsorRoles }}
                getSearchString={getBusinessPartnerSearchString}
                onBusinessPartnerSelected={(id, { name: fullName }) => {
                  onChange([{ bpId: id, fullName }])
                }}
                valueForUnknown={() => ({})}
                valueStateMessage={
                  <Text wrapping>
                    {sponsorStateMessage || sponsorInvalidMessage(invalid) || error?.message}
                  </Text>
                }
                valueState={calculateSponsorValueState(invalid)}
                onBlur={onBlur}
                name={name}
                noTypeahead
              />
            )}
          />
        </Labeled>
      </div>
      <div>
        <Labeled label={t('borrower')} vertical showColon required>
          <Controller
            control={control}
            name="counterParty.borrower"
            rules={{
              validate: {
                required: required(),
                borrowerName: required('fullName'),
              },
            }}
            render={({
              field: { onChange, onBlur, value, name },
              fieldState: { invalid, error },
            }) => (
              <BusinessPartnerAutocompleteInput
                id="deal-create-borrower"
                defaultValue={formatBusinessPartner(value)}
                suggestionItemProps={({ name: fullName, id }) => ({
                  text: formatBusinessPartner({ fullName, bpId: id }),
                })}
                getSearchString={getBusinessPartnerSearchString}
                onBusinessPartnerSelected={(id, { name: fullName, orgName1 }) =>
                  // after creating a bp: only orgName1 is set; after selecting an existing bp: only name is set
                  onChange({ bpId: id, fullName: fullName || orgName1 })
                }
                valueForUnknown={(text) => ({ name: text })}
                valueStateMessage={<Text wrapping>{borrowerStateMessage || error?.message}</Text>}
                valueState={calculateBorrowerValueState(invalid)}
                withCreateOption
                createDialogOptions={{
                  title: t('deal-borrower-create'),
                  creationRole: prospectBorrowerRole,
                }}
                required
                onBlur={onBlur}
                name={name}
                maxLength={BORROWER_NAME_MAX_LENGTH}
              />
            )}
          />
        </Labeled>
      </div>
      <input type="hidden" {...register('tranche.trancheName', standardMappings.NativeInput)} />
      <div>
        <Labeled label={t('loan-type')} vertical showColon required>
          <LoadingSelect
            id="deal-create-loan-type"
            loadingHook={useLoanTypeCodes}
            selectionName={'loanTypeCodes'}
            className={styles.fullWidthField}
            {...register('tranche.loanType', {
              ...standardMappings.LoadingSelect,
              validate: {
                required: required(),
              },
            })}
          />
        </Labeled>
      </div>
      <ResponsiveGridLayout
        columnsS={5}
        columnsM={5}
        columnsL={5}
        columnsXL={5}
        columnGap="1rem"
        rowGap="1rem"
      >
        <div style={{ gridColumn: 'span 3' }}>
          <Labeled label={t('commitment')} vertical showColon required>
            <Controller
              control={control}
              name="tranche.totalCommitment.number"
              rules={{
                validate: {
                  required: required(),
                  maxSafeMonetary: maxSafeMonetary(),
                  positive,
                },
              }}
              render={({ field: { onBlur, name } }) => (
                <Input
                  id="deal-create-commitment-number"
                  required
                  className={styles.fullWidthField}
                  {...register(name, {
                    setValueAs: (v) => (v === '' ? '' : Math.round(parseNumber(v) * 100) / 100),
                    displayAs: (v) => (typeof v === 'number' ? formatNumber(v) : ''),
                  })}
                  onBlur={(event) => {
                    if (totalCommitment) {
                      event.target.value = formatNumber(totalCommitment)
                    }
                    onBlur()
                  }}
                />
              )}
            />
          </Labeled>
        </div>
        <div style={{ gridColumn: 'span 2' }}>
          <Labeled label={t('currency')} vertical showColon required>
            <LoadingSelect
              id="deal-create-commitment-currency"
              loadingHook={useFinancingCurrencyCodes}
              selectionName="currencyCodes"
              optionKeyName="key"
              optionDisplayName="display_name"
              className={styles.fullWidthField}
              {...register('tranche.totalCommitment.currencyCode', {
                ...standardMappings.LoadingSelect,
                validate: {
                  required: required(),
                },
              })}
            />
          </Labeled>
        </div>
      </ResponsiveGridLayout>
    </ResponsiveGridLayout>
  )
}

DealCreationCounterpartyStep.propTypes = {
  enabled: PropTypes.bool,
  wizardStep: PropTypes.number.isRequired,
}

export default DealCreationCounterpartyStep
