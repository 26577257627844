import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { monitoringInformationShape } from 'components/domains/business-partners/periodical-checks/monitoring-information/PeriodicalMonitoringPropTypes'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import useCheckCycleTranslations from 'components/domains/deals/covenants/covenants-table/useCheckCycleTranslations'
import RelativeDateComparisonView from 'components/domains/deals/covenants/monitoring-information-card/RelativeDateComparisonView'
import { useBooleanToTextFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'

const PeriodicalMonitoringInformationDisplay = ({
  monitoringInformation: {
    checkRequired,
    firstDueDate: {
      keyDate: monitoringKeyDate,
      targetDeliveryDateBaseData: monitoringTargetDeliveryDateBaseData,
      checkDateBaseData: monitoringCheckDateBaseData,
    } = {},
    checkCycle = {},
  } = {},
  configurationForCovenant,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-partners.periodical-checks.monitoring-card',
  })
  const formatBoolean = useBooleanToTextFormatter()
  const { format: formatDate } = useShortDateFormatter()

  const { getFormattedCheckCycle } = useCheckCycleTranslations()

  const { withDocumentsRequest } = configurationForCovenant

  return (
    <CardSection>
      <CardShowItem
        id="check-required"
        label={t('check-required')}
        value={formatBoolean(!!checkRequired)}
      />
      {!!checkRequired && (
        <>
          <CardShowItem
            id="monitoring-key-date"
            label={t('first-key-date-test-date')}
            value={formatDate(monitoringKeyDate)}
          />
          {withDocumentsRequest && (
            <CardShowItem
              id="monitoring-target-delivery-date"
              label={t('target-delivery-date')}
              value={
                <RelativeDateComparisonView
                  amount={monitoringTargetDeliveryDateBaseData?.amountToAdd}
                  temporalUnit={monitoringTargetDeliveryDateBaseData?.temporalUnit}
                  direction={monitoringTargetDeliveryDateBaseData?.direction}
                  referenceDateTranslation={t('key-date')}
                />
              }
            />
          )}
          <CardShowItem
            id="monitoring-check-due-date"
            label={t('check-due-date')}
            value={
              <RelativeDateComparisonView
                amount={monitoringCheckDateBaseData?.amountToAdd}
                temporalUnit={monitoringCheckDateBaseData?.temporalUnit}
                direction={monitoringCheckDateBaseData?.direction}
                referenceDateTranslation={
                  withDocumentsRequest ? t('target-delivery-date') : t('key-date-test-date')
                }
              />
            }
          />
          <CardShowItem
            id="check-cycle"
            label={t('check-cycle')}
            value={getFormattedCheckCycle({ number: checkCycle?.number, cycle: checkCycle?.cycle })}
          />
        </>
      )}
    </CardSection>
  )
}

PeriodicalMonitoringInformationDisplay.propTypes = {
  monitoringInformation: monitoringInformationShape,
  configurationForCovenant: PropTypes.shape({
    withDocumentsRequest: PropTypes.bool,
  }),
}

export default PeriodicalMonitoringInformationDisplay
