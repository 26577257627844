import {
  Button,
  ButtonDesign,
  DatePicker,
  Icon,
  Input,
  Modals,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import { DateTime } from 'luxon'
// used for type inference
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { Rating } from 'components/domains/business-partners/tile/general-information/allowedOperations'
import styles from 'components/domains/business-partners/tile/ratings/BusinessPartnerInternalRatingsTile.module.css'
import DeleteRatingButton from 'components/domains/business-partners/tile/ratings/DeleteRatingButton'
import OpenRatingIcon from 'components/domains/business-partners/tile/ratings/OpenRatingIcon'
import applyTemplate from 'components/domains/business-partners/tile/ratings/applyTemplate'
import { internalRatings } from 'components/domains/business-partners/tile/ratings/propTypes'
import { validateRating } from 'components/domains/business-partners/tile/ratings/ratingValidation'
import useBusinessPartnerInternalRatingsColumns from 'components/domains/business-partners/tile/ratings/useBusinessPartnerInternalRatingsColumns'
import Card from 'components/ui/card/Card'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import LoadingDropdown from 'components/ui/dropdown/LoadingDropdown'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'
import {
  useBooleanToTextFormatter,
  useNumberFormatter,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import useCreateBusinessPartnerRating from 'hooks/services/business-partners/ratings/useCreateBusinessPartnerRating'
import useDeleteBusinessPartnerRating from 'hooks/services/business-partners/ratings/useDeleteBusinessPartnerRating'
import { useRatingProcedures } from 'hooks/services/business-partners/ratings/useRatingProcedures'
import { useSaveRatings } from 'hooks/services/business-partners/ratings/useSaveRatings'
import { useErrorMessageBoxWithExpandableDetails } from 'hooks/services/useErrorMessageBoxWithExpandableDetails'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

const emptyRating = {
  ratingKey: undefined,
  original: {
    methodId: undefined,
    validTo: undefined,
  },
  ratingGrade: '',
  pdPercentage: '',
  methodId: '',
  validFrom: '',
  validTo: '',
  customAttributes: {},
}

const emptyOption = {
  code: '',
  displayName: '',
}

const newLineKey = 'new-line'

const buildKey = (methodId, validTo) => `${methodId}${validTo}`

const containsDigitsOnly = (value) => /^\d+$/g.test(value)

const propTypes = {
  ratings: internalRatings.isRequired,
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const BusinessPartnerInternalRatingsTile = ({ ratings }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner.ratings' })
  const { t: translationWithoutPrefix } = useTranslation()
  const showToast = Modals.useShowToast()
  const { format: dateFormat, localePattern } = useShortDateFormatter()
  const toISODate = (value) => DateTime.fromFormat(value, localePattern).toISODate()
  const formatBoolean = useBooleanToTextFormatter()
  const rateFormat = useNumberFormatter({
    maximumFractionDigits: 4,
    minimumFractionDigits: 4,
    style: 'percent',
  })
  const businessPartner = useContext(BusinessPartnerContext)
  const {
    data: ratingData,
    isLoading: isLoadingRating,
    isError: isErrorRating,
  } = useRatingProcedures()
  //This is needed since there are inconsistencies in s4
  const formatToBooleanValues = (value) => {
    switch (value) {
      case 'X':
        return translationWithoutPrefix(`formatters.boolean.yes`)
      case '':
        return translationWithoutPrefix(`formatters.boolean.no`)
      default:
        return null
    }
  }
  const [editRating, setEditRating] = useState({})
  const [isAdding, setIsAdding] = useState(false)
  const isEditing = useMemo(() => !!editRating.ratingKey, [editRating])

  const { isErrorMessageOpen, onErrorMessageBoxClose, errorDetails, setError } =
    useErrorMessageBoxWithExpandableDetails()

  const handleCreateSuccess = useCallback(() => {
    setIsAdding(false)
    showToast({ children: translationWithoutPrefix('toast.changes-saved') })
    setEditRating(emptyRating)
  }, [setIsAdding, showToast, translationWithoutPrefix])

  const handleUpdateSuccess = useCallback(() => {
    showToast({ children: translationWithoutPrefix('toast.changes-saved') })
    setEditRating(emptyRating)
  }, [setEditRating, showToast, translationWithoutPrefix])

  const { mutate: createRating } = useCreateBusinessPartnerRating({
    businessPartnerId: businessPartner.id,
    onSuccess: handleCreateSuccess,
    onError: setError,
  })
  const { mutate: deleteRating } = useDeleteBusinessPartnerRating({
    businessPartnerId: businessPartner.id,
    onError: setError,
  })

  const saveRating = useSaveRatings({
    businessPartnerId: businessPartner.id,
    onError: setError,
    onCreateSuccess: handleCreateSuccess,
    onUpdateSuccess: handleUpdateSuccess,
  })

  const modifiableRatingOptions = useMemo(
    () =>
      ratingData?.ratingProcedures
        ?.filter(({ modifiable, internal }) => modifiable && internal)
        ?.map(({ methodId, methodTxt }) => ({
          code: methodId,
          displayName: methodTxt,
        })) ?? [],
    [ratingData],
  )

  const loadRatings = useCallback(
    () => ({
      isLoading: isLoadingRating,
      isError: isErrorRating,
      data: [emptyOption, ...modifiableRatingOptions],
    }),
    [modifiableRatingOptions, isLoadingRating, isErrorRating],
  )

  // TODO Technical Debt:
  //  Workaround to retrieve linkToBpInRatingSystemTemplate from backend;
  //  should be configured in frontend instead
  const { data: businessPartnerFull } = useBusinessPartnerById(businessPartner.id)
  const todayPlusThreeMonths = DateTime.now().plus({ months: 3 }).toISODate()

  const handleEdit = (newEditRating) => {
    setEditRating(newEditRating)
  }

  const handleChange = (field, value) => {
    setEditRating((prev) => ({
      ...prev,
      // avoid setting null values - for date fields 'undefined' would be shown to the user
      [field]: value === null ? emptyRating[field] : value,
    }))
  }

  const handleCancel = () => {
    setIsAdding(false)
    setEditRating(emptyRating)
  }

  const { isValid = false, errors: validationErrors = {} } = useMemo(() => {
    if (!editRating) return { isValid: false, errors: {} }

    let existingRatings = ratings.filter(({ method }) => method.id === editRating.methodId)
    if (!isAdding) {
      // exclude the current rating from the list. otherwise we would always get a date conflict
      // with the rating we are currently editing
      existingRatings = existingRatings.filter(
        ({ method, validTo }) => buildKey(method.id, validTo) !== editRating.ratingKey,
      )
    }
    const validationResult = validateRating({ existingRatings, newRating: editRating })
    return validationResult
  }, [ratings, isAdding, editRating])

  const columnDefinitions = useBusinessPartnerInternalRatingsColumns()

  const handleRatingGradeInputBlur = () => {
    if (!containsDigitsOnly(editRating.ratingGrade)) return

    // applying a leading zero is required by S4
    const ratingGrade = parseInt(editRating.ratingGrade)
    if (ratingGrade < 10 && ratingGrade > 0) {
      setEditRating((prev) => ({
        ...prev,
        ratingGrade: `0${ratingGrade}`,
      }))
    }
  }

  const hasPermissionRatingCreate = !!businessPartner?.allowedOperations.includes(Rating.create)
  const hasPermissionRatingWrite = !!businessPartner?.allowedOperations.includes(Rating.write)
  const hasPermissionRatingDelete = !!businessPartner?.allowedOperations.includes(Rating.delete)

  const buildRow = ({
    ratingClass,
    rate,
    method,
    validFrom,
    validTo,
    customAttributes,
    ratingKey,
  }) => {
    const rowKey = ratingKey ?? buildKey(method.id, validTo)
    const isNewRating = rowKey === newLineKey
    const canEdit = !isEditing && modifiableRatingOptions.some(({ code }) => code === method.id)
    const isEditingRow = editRating.ratingKey === rowKey
    const overwritten =
      typeof customAttributes?.OverwriteFlag === 'boolean'
        ? formatBoolean(customAttributes?.OverwriteFlag)
        : null
    const letterGrade = customAttributes?.PDLetterGrade
    const status = customAttributes?.RatingStatus
    const linkToVrRating = businessPartnerFull
      ? applyTemplate(businessPartnerFull.linkToRatingInRatingSystemTemplate, {
          businessPartnerId: businessPartner.id,
          ratingId: customAttributes?.RatingID,
        })
      : ''
    const ratingInheritedRaw = customAttributes?.ratingInherited
    const ratingInherited = formatToBooleanValues(customAttributes?.ratingInherited)
    const ratingInheritedFrom = customAttributes?.ratingInheritedFrom

    const shouldDisplayActions = canEdit && !ratingInheritedRaw
    return {
      rowKey,
      ratingClass: {
        value: ratingClass,
        cellComponent: isEditingRow ? (
          <Input
            value={editRating.ratingGrade}
            onInput={(e) => handleChange('ratingGrade', e.target.value)}
            onBlur={handleRatingGradeInputBlur}
            valueState={validationErrors?.ratingGrade ? ValueState.Error : ValueState.None}
            valueStateMessage={
              validationErrors?.ratingGrade ? (
                <Text>{validationErrors?.ratingGrade}</Text>
              ) : undefined
            }
          />
        ) : (
          ratingClass
        ),
      },
      rate: {
        value: rate,
        cellComponent: isEditingRow ? (
          <Input
            value={editRating.pdPercentage}
            onChange={(e) => handleChange('pdPercentage', e.target.value)}
            valueState={validationErrors?.pdPercentage ? ValueState.Error : ValueState.None}
            valueStateMessage={
              validationErrors?.pdPercentage ? (
                <Text>{validationErrors?.pdPercentage}</Text>
              ) : undefined
            }
          />
        ) : (
          rateFormat(rate)
        ),
      },
      letterGrade: {
        value: letterGrade,
        cellComponent: letterGrade,
      },
      method: {
        value: method.name,
        cellComponent: isEditingRow ? (
          <LoadingDropdown
            id="rating-method"
            useLoader={loadRatings}
            value={editRating.methodId}
            onChange={(value) => handleChange('methodId', value)}
            valueState={validationErrors?.methodId ? ValueState.Error : ValueState.None}
            valueStateMessage={
              validationErrors?.methodId ? <Text>{validationErrors?.methodId}</Text> : undefined
            }
          />
        ) : (
          method.name
        ),
      },
      status: {
        value: status,
        cellComponent: status,
      },
      validFrom: {
        value: validFrom, // use date in format YYYY-MM-DD for sorting
        cellComponent: isEditingRow ? (
          <DatePicker
            value={editRating.validFrom}
            formatPattern={localePattern}
            onChange={(e) => handleChange('validFrom', toISODate(e.detail.value))}
            valueState={validationErrors?.validFrom ? ValueState.Error : ValueState.None}
            valueStateMessage={
              validationErrors?.validFrom ? <Text>{validationErrors?.validFrom}</Text> : undefined
            }
          />
        ) : (
          dateFormat(validFrom)
        ),
        filter: filterTypes.BETWEEN_DATES,
      },
      validTo: {
        value: validTo, // use date in format YYYY-MM-DD for sorting
        cellComponent: isEditingRow ? (
          <DatePicker
            value={editRating.validTo}
            formatPattern={localePattern}
            onChange={(e) => handleChange('validTo', toISODate(e.detail.value))}
            valueState={validationErrors?.validTo ? ValueState.Error : ValueState.None}
            valueStateMessage={
              validationErrors?.validTo ? <Text>{validationErrors?.validTo}</Text> : undefined
            }
          />
        ) : (
          dateFormat(validTo)
        ),
        filter: filterTypes.BETWEEN_DATES,
      },
      buttons: {
        cellComponent: (
          <div className={styles.buttons}>
            {shouldDisplayActions && hasPermissionRatingWrite && (
              <Icon
                className={styles.action}
                name="edit"
                data-testid="edit-rating"
                interactive
                onClick={() =>
                  handleEdit({
                    ratingKey: rowKey,
                    original: { methodId: method.id, validTo },
                    ratingGrade: ratingClass,
                    pdPercentage: rate,
                    methodId: method.id,
                    validFrom,
                    validTo,
                  })
                }
              />
            )}
            {shouldDisplayActions && hasPermissionRatingDelete && (
              <DeleteRatingButton
                className={styles.action}
                methodId={method.id}
                validTo={validTo}
                onDelete={deleteRating}
                data-testid="delete-rating"
              />
            )}
            {isEditingRow && (
              <Button
                className={styles.action}
                design={ButtonDesign.Emphasized}
                data-testid="save-rating"
                onClick={() => {
                  if (isNewRating) {
                    createRating(editRating)
                  } else {
                    saveRating(editRating)
                  }
                }}
                disabled={!isValid}
              >
                {translationWithoutPrefix('buttons.save')}
              </Button>
            )}
            {isEditingRow && (
              <Button
                className={styles.action}
                data-testid="cancel-edit-rating"
                design={ButtonDesign.Transparent}
                onClick={handleCancel}
              >
                {translationWithoutPrefix('buttons.cancel')}
              </Button>
            )}
            {!isEditingRow && linkToVrRating && (
              <OpenRatingIcon className={styles.action} to={linkToVrRating} />
            )}
          </div>
        ),
      },
      overwritten: {
        value: overwritten,
        cellComponent: overwritten,
      },
      ratingInherited: {
        value: ratingInherited,
        cellComponent: ratingInherited,
      },
      ratingInheritedFrom: {
        value: ratingInheritedFrom,
        cellComponent: ratingInheritedFrom,
      },
    }
  }

  const tableData = isAdding
    ? [
        buildRow({
          method: { name: '' },
          customAttributes: emptyRating.customAttributes,
          ratingClass: emptyRating.ratingClass,
          rate: emptyRating.rate,
          validFrom: emptyRating.validFrom,
          validTo: todayPlusThreeMonths,
          ratingKey: newLineKey,
        }),
        ...ratings.map((rating) => buildRow(rating)),
      ]
    : ratings.map((rating) => buildRow(rating))

  const addAction = hasPermissionRatingCreate && (
    <Button
      onClick={() => {
        setEditRating({
          ...emptyRating,
          method: { id: emptyRating.methodId },
          validTo: todayPlusThreeMonths,
          ratingKey: newLineKey,
        })
        setIsAdding(true)
      }}
      disabled={isEditing}
      design={ButtonDesign.Transparent}
      data-testid="add-rating"
    >
      {translationWithoutPrefix('buttons.add')}
    </Button>
  )

  return (
    <>
      <Card className={styles.card}>
        <div className={styles.tableWrapper}>
          <SortedTable
            columnDefinitions={columnDefinitions}
            tableData={tableData}
            toolbarConfig={{
              sorting: { columnKey: 'validFrom', isSortingAscending: false },
              title: t('internal'),
              additionalActions: compact([addAction]),
            }}
            noDataText={t('internal.no-data')}
          />
        </div>
      </Card>
      {createPortal(
        <>
          {isErrorMessageOpen && (
            <ErrorMessageBoxWithExpandableDetails
              messageSummary={translationWithoutPrefix('components.cards.save-error')}
              messageDetails={errorDetails}
              isOpen={isErrorMessageOpen}
              onClose={onErrorMessageBoxClose}
            />
          )}
        </>,
        document.body,
      )}
    </>
  )
}

BusinessPartnerInternalRatingsTile.propTypes = propTypes

export default BusinessPartnerInternalRatingsTile
