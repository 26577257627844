import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useBankCustomerAccountDetails = ({ bcaIdOrExternalAccountNumber, options = {} }) =>
  useCamelizedResponse(
    useRequest({
      path: `/financing-structures/bank-customer-accounts/${bcaIdOrExternalAccountNumber}`,
      keys: ['bank-customer-accounts', bcaIdOrExternalAccountNumber],
      options: { enabled: !!bcaIdOrExternalAccountNumber, ...options },
      translated: true,
    }),
  )

export default useBankCustomerAccountDetails
