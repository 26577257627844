import { FlexBox, FlexBoxDirection, Grid, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PercentageField from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/fields/PercentageField'
import TextField from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/fields/TextField'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/OpsMemoTrancheDetailsCard.module.css'

const OpsMemoTrancheAccountingCard = ({ tranche }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.ops-memo-tranche-details.accounting',
  })

  return (
    <Grid defaultSpan="XL6 L6 M6 S12" className={styles.card}>
      <FlexBox direction={FlexBoxDirection.Column}>
        <Text className={styles.cardHeaderTitle}>{t('accounting')}</Text>

        <TextField
          label={t('business-model-criterion')}
          value={tranche?.accounting?.businessModelCriterionShortText}
          isFirstItem={true}
        />
        <TextField
          label={t('benchmark-test-result')}
          value={tranche?.accounting?.benchmarkTestResultShortText}
        />
        <TextField
          label={t('contractual-cash-flow-criterion')}
          value={tranche?.accounting?.contractualCashFlowCriterionShortText}
        />
        <TextField
          label={t('valuation-class')}
          value={tranche?.accounting?.valuationClassShortText}
        />
        <PercentageField
          label={t('part-to-be-syndicated')}
          value={tranche?.accounting?.partToBeSyndicated}
        />
      </FlexBox>
    </Grid>
  )
}

OpsMemoTrancheAccountingCard.propTypes = {
  tranche: PropTypes.shape({
    accounting: PropTypes.shape({
      businessModelCriterionShortText: PropTypes.string,
      benchmarkTestResultShortText: PropTypes.string,
      contractualCashFlowCriterionShortText: PropTypes.string,
      valuationClassShortText: PropTypes.string,
      partToBeSyndicated: PropTypes.number,
    }),
  }),
}

export default OpsMemoTrancheAccountingCard
