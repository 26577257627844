import {
  CheckBox,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  TextAlign,
  VerticalAlign,
} from '@fioneer/ui5-webcomponents-react'
import get from 'lodash.get'
import sortBy from 'lodash.sortby'
import uniqBy from 'lodash.uniqby'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import useCreateCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/useCreateCell'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/cover-pool/CoverPoolAdjustmentsTile.module.css'
import { filterTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/ui/tables/sorted-tables/useFilters'
import Labeled from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/data/Labeled'
import { useBooleanToTextFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'
import paths from 'routes/paths'

const columnKeyPrefix = 'tiles.cover-pool-adjustments.table'

const CheckboxCell = ({
  onRowSelect,
  isEditMode,
  selectedRows,
  isCheckedOverwrite = null,
  row,
}) => {
  const propertyUuid = row?.original?.property?.propertyUuid
  const checked = isCheckedOverwrite ?? selectedRows?.includes(propertyUuid)

  return (
    <FlexBox alignItems={FlexBoxAlignItems.Center} justifyContent={FlexBoxJustifyContent.Center}>
      <CheckBox
        checked={checked}
        onChange={(e) => {
          onRowSelect(e, propertyUuid)
        }}
        readonly={!isEditMode}
        disabled={!isEditMode}
      />
    </FlexBox>
  )
}
CheckboxCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      property: PropTypes.shape({ propertyUuid: PropTypes.string.isRequired }),
    }),
  }),
  selectedRows: PropTypes.arrayOf(PropTypes.string),
  isEditMode: PropTypes.bool.isRequired,
  isCheckedOverwrite: PropTypes.bool,
  onRowSelect: PropTypes.func.isRequired,
}

const AddressCell = ({ address, t }) => (
  <FlexBox
    direction={FlexBoxDirection.Column}
    alignItems={FlexBoxAlignItems.Start}
    justifyContent={FlexBoxJustifyContent.Center}
    className={styles.addressCell}
  >
    <span>{address?.countryName}</span>
    <Labeled label={t('address.postal-code')} showColon>
      {address?.postalCode}
    </Labeled>
    <Labeled label={t('address.city')} showColon>
      {address?.cityName}
    </Labeled>
    <Labeled label={t('address.street')} showColon>
      {address?.streetName}
    </Labeled>
    <Labeled label={t('address.house-no')} showColon>
      {address?.houseId}
    </Labeled>
  </FlexBox>
)
AddressCell.propTypes = {
  address: PropTypes.shape({
    countryName: PropTypes.string,
    postalCode: PropTypes.string,
    cityName: PropTypes.string,
    streetName: PropTypes.string,
    houseId: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
}

const getDefaultColumnDefinition = (columnKey, t) => ({
  Header: () => <Trans ns="decisionPaper" i18nKey={`${columnKeyPrefix}.${columnKey}-label`} />,
  title: t(`${columnKey}-label`).replace('<br/>', ' '),
  sortByLabel: t(`${columnKey}-label`).replace('<br/>', ' '),
  filterLabel: t(`${columnKey}-label`).replace('<br/>', ' '),
  accessor: columnKey,
})

const useCoverPoolAdjustmentsTableColumns = ({
  tableData = [],
  isEditMode = false,
  onRowSelect,
  onAllRowsSelect,
  selectedRows,
  allRowsSelected,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.cover-pool-adjustments.table',
  })
  const { t: toNoPrefix } = useTranslation('decisionPaper')
  const formatBoolean = useBooleanToTextFormatter()

  const extractCodesFromProperty = (codeAccessor, nameAccessor) =>
    sortBy(
      uniqBy(
        tableData
          ?.map((property) => {
            const countryCode = get(property, codeAccessor, null)
            const countryName = get(property, nameAccessor, null)
            if (countryCode && countryName) {
              return {
                key: countryCode,
                displayName: countryName,
              }
            } else {
              return null
            }
          })
          .filter((countryCode) => countryCode !== null),
        'key',
      ),
      'displayName',
    )

  const relatedCountryCodes = extractCodesFromProperty('address.countryCode', 'address.countryName')

  const getEnumValuesFromCodes = (codes) => {
    const enumValues = {}
    codes.forEach((code) => {
      if (code.key && code.displayName) {
        enumValues[code.key] = code.displayName
      }
    })
    return enumValues
  }

  const renderCheckBoxCell = useCallback(
    (props) => (
      <CheckboxCell
        onRowSelect={onRowSelect}
        isEditMode={isEditMode}
        selectedRows={selectedRows}
        {...props}
      />
    ),
    [isEditMode, onRowSelect, selectedRows],
  )

  const renderAddressCell = useCallback((props) => <AddressCell t={t} {...props} />, [t])

  const renderCheckBoxHeader = useCallback(
    () => (
      <FlexBox
        direction={FlexBoxDirection.Row}
        justifyContent={FlexBoxJustifyContent.End}
        alignItems={FlexBoxAlignItems.Center}
      >
        <Trans ns="decisionPaper" i18nKey={`${columnKeyPrefix}.coverPoolAdjustmentChecked-label`} />
        <CheckboxCell
          isEditMode={isEditMode}
          onRowSelect={onAllRowsSelect}
          selectedRows={selectedRows}
          isCheckedOverwrite={allRowsSelected}
        />
      </FlexBox>
    ),
    [allRowsSelected, isEditMode, onAllRowsSelect, selectedRows],
  )

  const columns = useMemo(
    () => ({
      property: {
        minWidth: 250,
        accessor: 'property.name',
        filterType: filterTypes.CONTAINS,
        sortByLabel: t('propertyName-label'),
        filterLabel: t('propertyName-label'),
        sortType: (row1, row2) => {
          const propertyName1 = row1?.original?.property?.name ?? ''
          const propertyName2 = row2?.original?.property?.name ?? ''
          return propertyName1.localeCompare(propertyName2)
        },
        Cell: ({ row }) => {
          const { displayId, name } = row?.original?.property ?? {}
          const link = displayId ? `/${paths.properties}/${displayId}` : undefined
          return useCreateCell('entity', {
            name,
            link,
            id: displayId,
            options: {
              openInNewTab: true,
            },
          })()
        },
      },
      propertyId: {
        accessor: 'property.displayId',
        filterType: 'CONTAINS',
        sortByLabel: t('propertyId-label'),
        filterLabel: t('propertyId-label'),
        hide: true,
      },
      address: {
        minWidth: 250,
        accessor: 'property.address',
        Cell: ({ row }) => {
          const address = row?.original?.address ?? {}
          return renderAddressCell({ address })
        },
        disableSortBy: true,
        disableFilters: true,
      },
      country: {
        accessor: 'address.countryName',
        filterType: 'OF_ENUM_TYPE',
        filterLabel: t('countryName-label'),
        additionalFilterOptions: {
          enumValues: getEnumValuesFromCodes(relatedCountryCodes),
        },
        hide: true,
        disableSortBy: true,
      },
      city: {
        accessor: 'address.cityName',
        filterType: 'CONTAINS',
        filterLabel: t('cityName-label'),
        hide: true,
        disableSortBy: true,
      },
      marketValue: {
        minWidth: 150,
        accessor: 'marketValue.number',
        hAlign: TextAlign.Right,
        filterType: filterTypes.BETWEEN_NUMBERS,
        Cell: ({ row }) => {
          const { number, currency, share } = row?.original?.marketValue ?? {}
          return useCreateCell('money', {
            primary: { amount: number, currency },
            percentage: { value: share, label: t('share-label') },
          })()
        },
      },
      mortgageLendingValue: {
        minWidth: 150,
        accessor: 'mortgageLendingValue.number',
        hAlign: TextAlign.Right,
        filterType: filterTypes.BETWEEN_NUMBERS,
        Cell: ({ row }) => {
          const { number, currency, share } = row?.original?.mortgageLendingValue ?? {}
          return useCreateCell('money', {
            primary: { amount: number, currency },
            percentage: { value: share, label: t('share-label') },
          })()
        },
      },
      coverPoolAdjustmentChecked: {
        Header: renderCheckBoxHeader,
        minWidth: 200,
        hAlign: TextAlign.Right,
        Cell: renderCheckBoxCell,
        filterType: filterTypes.OF_ENUM_TYPE,
        accessor: ({ coverPoolAdjustmentChecked }) =>
          toNoPrefix(`${formatBoolean(coverPoolAdjustmentChecked)}`),
        id: 'coverPoolAdjustmentChecked',
        additionalFilterOptions: {
          enumValues: [true, false].map((value) => toNoPrefix(`${formatBoolean(value)}`)),
        },
        disableSortBy: !isEditMode,
        disableFilters: !isEditMode,
      },
    }),
    [
      formatBoolean,
      isEditMode,
      relatedCountryCodes,
      renderAddressCell,
      renderCheckBoxCell,
      renderCheckBoxHeader,
      t,
      toNoPrefix,
    ],
  )

  return useMemo(
    () =>
      Object.keys(columns).map((columnKey) => ({
        ...getDefaultColumnDefinition(columnKey, t),
        disableDragAndDrop: true,
        disableResizing: false,
        vAlign: VerticalAlign.Middle,
        ...columns[columnKey],
      })),
    [columns, t],
  )
}

export default useCoverPoolAdjustmentsTableColumns
