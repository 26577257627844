import {
  FlexBox,
  FlexBoxDirection,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DealCashflowResultChartCardHeader } from 'components/domains/deals/deal-cashflow/result-charts/DealCashflowResultChartCardHeader'
import styles from 'components/domains/deals/deal-cashflow/result-charts/chart-components/DealCashflowChartWrapper.module.css'
import Card from 'components/ui/card/Card'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'

const DealCashflowChartWrapper = ({
  isCard,
  isLoading,
  isError,
  isSelectedData,
  isMetaData,
  headerProps,
  children,
}) => {
  const { t: tCashFlow } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-paper.tiles.cash-flow',
  })

  const emptyInfoContent = useMemo(
    () => (
      <EmptyCardContent
        illustrationName={IllustrationMessageType.UnableToLoad}
        size={IllustrationMessageSize.Scene}
      />
    ),
    [],
  )
  const emptyContent = useMemo(() => {
    const noSelectionCardContent = (
      <EmptyCardContent
        illustrationName={IllustrationMessageType.UnableToLoad}
        size={IllustrationMessageSize.Scene}
        title={tCashFlow('no-scenario.selected.title')}
        subtitle={tCashFlow('no-scenario.selected.subtitle')}
      />
    )
    return isMetaData ? emptyInfoContent : noSelectionCardContent
  }, [emptyInfoContent, isMetaData, tCashFlow])

  return isCard ? (
    <Card header={<DealCashflowResultChartCardHeader {...headerProps} />}>
      <div className={isSelectedData ? styles.chartWrapper : ''}>
        <LoadingStateWrapper isLoading={isLoading} isError={isError}>
          {isSelectedData ? children : emptyInfoContent}
        </LoadingStateWrapper>
      </div>
    </Card>
  ) : (
    <FlexBox direction={FlexBoxDirection.Column}>
      <DealCashflowResultChartCardHeader {...headerProps} />
      <div className={styles.chartWrapper}>{isSelectedData ? children : emptyContent}</div>
    </FlexBox>
  )
}

DealCashflowChartWrapper.propTypes = {
  isCard: PropTypes.bool,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  isSelectedData: PropTypes.bool.isRequired,
  isMetaData: PropTypes.bool,
  headerProps: PropTypes.object.isRequired,
  children: PropTypes.element,
}

export default DealCashflowChartWrapper
