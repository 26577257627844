import { MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { eventErrorCodes } from 'api/events/eventsRequestErrors'
import BusinessEventsAndTasksMassEditDialog from 'components/domains/business-events-and-tasks/mass-edit/BusinessEventsAndTasksMassEditDialog'
import useMassEditEvents from 'hooks/services/business-events-and-tasks/events/useMassEditEvents'
import { reset } from 'redux/slices/events/eventOverviewSlice'

const EventOverviewTableMultiEditDialog = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.event-overview.table' })
  const showMessageBox = Modals.useShowMessageBox()
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()
  const { mutate: massEditEvents, isLoading: isLoadingMassEditEvents } = useMassEditEvents()
  const dispatch = useDispatch()

  const selectedEvents = useSelector(
    (state) => state.events.eventOverview.selectedRows.selectedEventIds,
  )

  const mappedEventsForMassEdit = useMemo(
    () => Object.entries(selectedEvents).map(([id, { status }]) => ({ id, status })),
    [selectedEvents],
  )

  const onSaveSuccess = useCallback(() => {
    queryClient.invalidateQueries(['events'])
    setIsOpen(false)
    showToast({ children: t('mass-edit.success') })
    dispatch(reset())
  }, [setIsOpen, showToast, queryClient, t, dispatch])
  const onSaveError = useCallback(
    async ({ response }) => {
      try {
        const responseBody = await response.json()
        if (responseBody.code === eventErrorCodes.updateWithOpenMandatoryTasksNotPossibleError) {
          showMessageBox({
            type: MessageBoxTypes.Error,
            children: t('mass-edit.error.open-mandatory-tasks'),
            draggable: true,
            resizable: true,
          })
          return
        }
        throw new Error('Unknown response code')
      } catch (_catchedError) {
        showMessageBox({
          type: MessageBoxTypes.Error,
          children: t('mass-edit.error'),
          draggable: true,
          resizable: true,
        })
      }
    },
    [showMessageBox, t],
  )

  const onAfterClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const onSave = useCallback(
    (editedFields) => {
      massEditEvents(
        { eventIds: mappedEventsForMassEdit.map(({ id }) => id), editedFields },
        { onSuccess: onSaveSuccess, onError: onSaveError },
      )
    },
    [massEditEvents, mappedEventsForMassEdit, onSaveSuccess, onSaveError],
  )

  return (
    <BusinessEventsAndTasksMassEditDialog
      eventsOrTasks={mappedEventsForMassEdit}
      isOpen={isOpen}
      isLoading={isLoadingMassEditEvents}
      onAfterClose={onAfterClose}
      onSave={onSave}
      isEventList={true}
    />
  )
}

EventOverviewTableMultiEditDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default EventOverviewTableMultiEditDialog
