import { useMemo } from 'react'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useDealProperties from 'hooks/services/deals/properties/useDealProperties'
import usePropertyIdForAssignedMarket from 'hooks/services/markets/usePropertyIdForAssignedMarket'
import { useMultiPropertyViewByPropertyUuids } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuids'
import usePropertyUuids from 'hooks/services/properties/usePropertyUuids'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const getPropertyInformationData = (propertyInformationResult, assignedMarketData) => {
  if (!propertyInformationResult?.properties) {
    return undefined
  }
  return propertyInformationResult.properties.map((property) => {
    const propertyMarket =
      assignedMarketData?.markets?.find((market) =>
        market?.properties?.find(
          ({ id: marketPropertyUuid }) => marketPropertyUuid === property.uuid,
        ),
      ) ?? {}

    return {
      uuid: property.uuid,
      name: property.description,
      id: property.id,
      type: property.typeName,
      address: {
        country: property.address.countryName,
        postalCode: property.address.postalCode,
        city: property.address.cityName,
        street: property.address.streetName,
        houseId: property.address.houseId,
      },
      market: {
        name: propertyMarket?.info?.name,
        id: propertyMarket?.id,
      },
    }
  })
}

const usePortfolioFinancedAssetsInformation = (
  { entityRef: { entityId: dealUuid } },
  _,
  tileCode,
) => {
  const {
    data: { dealUuid: dealUuidByTileCode } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({ dealUuid, tileCode })

  const dealPropertiesResult = useDealProperties({
    dealUuid: dealUuidByTileCode,
  })

  const isFollowUpRequestEnabled = useMemo(
    () =>
      !dealPropertiesResult.isFetching &&
      !dealPropertiesResult.isError &&
      !!dealPropertiesResult?.data &&
      dealPropertiesResult?.data?.dealProperties?.length > 0,
    [dealPropertiesResult],
  )

  const propertyUuids = useMemo(
    () =>
      isFollowUpRequestEnabled
        ? dealPropertiesResult.data.dealProperties.map((property) => property.propertyUuid)
        : [],
    [dealPropertiesResult, isFollowUpRequestEnabled],
  )

  const propertyInformationResult = useCamelizedResponse(
    usePropertyUuids(propertyUuids, {
      enabled: isFollowUpRequestEnabled,
    }),
  )

  const isMultiProperty = useMemo(
    () => propertyInformationResult?.data?.data?.properties?.length > 1,
    [propertyInformationResult],
  )
  const multiPropertyViewUuidResponse = useMultiPropertyViewByPropertyUuids(propertyUuids, {
    enabled: isFollowUpRequestEnabled && isMultiProperty,
  })
  const multiPropertyViewUuid = useMemo(
    () => multiPropertyViewUuidResponse?.data?.data?.uuid,
    [multiPropertyViewUuidResponse],
  )

  const {
    data: assignedMarketData,
    isFetching: isAssignedMarketLoading,
    isError: isAssignedMarketError,
  } = useCamelizedResponse(
    usePropertyIdForAssignedMarket(propertyUuids, {
      enabled: isFollowUpRequestEnabled,
    }),
  )

  const sourcePath = useMemo(
    () =>
      isMultiProperty
        ? `/properties/portfolio/overview?portfolio-view-id=${multiPropertyViewUuid}`
        : `/properties/${propertyInformationResult?.data?.data?.properties?.[0]?.id}`,
    [isMultiProperty, multiPropertyViewUuid, propertyInformationResult],
  )
  return useMemo(() => {
    const isLoading =
      isDealUuidFetching ||
      dealPropertiesResult.isFetching ||
      propertyInformationResult.isFetching ||
      isAssignedMarketLoading ||
      (isMultiProperty && multiPropertyViewUuidResponse.isFetching)

    const isError =
      isDealUuidError ||
      dealPropertiesResult.isError ||
      propertyInformationResult.isError ||
      isAssignedMarketError ||
      (isMultiProperty && multiPropertyViewUuidResponse.isError)

    const propertyInformation = {
      data: getPropertyInformationData(propertyInformationResult?.data?.data, assignedMarketData),
      isLoading,
      isError,
    }
    const noProperties =
      !isDealUuidFetching &&
      !isDealUuidError &&
      !dealPropertiesResult.isFetching &&
      !dealPropertiesResult.isError &&
      (!dealPropertiesResult?.data?.dealProperties ||
        dealPropertiesResult?.data?.dealProperties?.length === 0)

    const data = {
      propertyInformation,
      sourceRender: { path: sourcePath },
      noProperties,
    }
    return {
      isLoading,
      isError,
      data,
    }
  }, [
    isDealUuidFetching,
    dealPropertiesResult.isFetching,
    dealPropertiesResult.isError,
    dealPropertiesResult?.data?.dealProperties,
    propertyInformationResult.isFetching,
    propertyInformationResult.isError,
    propertyInformationResult?.data?.data,
    isAssignedMarketLoading,
    isMultiProperty,
    multiPropertyViewUuidResponse.isFetching,
    multiPropertyViewUuidResponse.isError,
    isDealUuidError,
    isAssignedMarketError,
    assignedMarketData,
    sourcePath,
  ])
}

export default usePortfolioFinancedAssetsInformation
