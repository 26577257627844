import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/deal-uses-and-sources/table-cells/NameCell.module.css'

const NameCell = ({ value: { name, isSectionHeader, isEmptyCell } }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.deal-uses-and-sources.row',
  })
  if (isEmptyCell) {
    return
  }
  if (isSectionHeader) {
    return (
      <Text wrapping className={styles.bold}>
        {t('total')}
      </Text>
    )
  }
  return <Text wrapping>{name}</Text>
}

NameCell.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string,
    isSectionHeader: PropTypes.bool,
    isEmptyCell: PropTypes.bool,
  }).isRequired,
}

export default NameCell
