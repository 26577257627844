import PropTypes from 'prop-types'
import ConditionsTableInternalApprovalLevelEditCell from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableInternalApprovalLevelEditCell'
import TableWrappingTextCell from 'components/domains/conditions/overview/table-cells/generic/TableWrappingTextCell'
import useConditionsCellEditMode from 'hooks/services/conditions/edit-mode/useConditionsCellEditMode'

const ConditionsTableInternalApprovalCell = ({
  value: { approvalLevelName, approvalLevelCode, conditionId },
}) => {
  const { isEditModeForCurrentRow } = useConditionsCellEditMode({
    conditionId,
  })

  if (isEditModeForCurrentRow) {
    return (
      <ConditionsTableInternalApprovalLevelEditCell
        approvalLevelCode={approvalLevelCode}
        approvalLevelName={approvalLevelName}
      />
    )
  }
  return <TableWrappingTextCell value={approvalLevelName} />
}

ConditionsTableInternalApprovalCell.propTypes = {
  value: PropTypes.shape({
    conditionId: PropTypes.string.isRequired,
    approvalLevelName: PropTypes.string,
    approvalLevelCode: PropTypes.string,
  }).isRequired,
}

export default ConditionsTableInternalApprovalCell
