import { FlexBox, FlexBoxDirection, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/RiskMonitoringDealSelectionTile.module.css'
import CloseableMessageStrip from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/deal-specifics/shared/ui/feedback/CloseableMessageStrip'
import LoadingSelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/deal-specifics/shared/ui/select/LoadingSelect'
import { setTileMetadata } from 'redux/slices/decision-paper/tilesOverviewSlice'

const RiskMonitoringDealSelectionTile = ({ tileId, isPdfView }) => {
  const dispatch = useDispatch()
  const { t: tNoPrefix } = useTranslation('decisionPaper')

  const { dealsData } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId].data)
  const {
    dealUuid: preSelectedDealUuid,
    name: preSelectedDealName,
    dealId: preSelectedDealId,
  } = dealsData?.[0] ?? {}

  // although this is a hook, eslint still recognizes this as a false usage within useEffect directly below this block
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dealMetadata = useSelector(
    (state) =>
      state.decisionPaper.tilesOverview.tileMetadata?.[
        availableAutomaticTiles.monitoredRiskIndicatorDealSpecificsDealSelection
      ],
  ) ?? {
    selectedDealUuid: preSelectedDealUuid,
    selectedDealName: preSelectedDealName,
    selectedDealId: preSelectedDealId,
  }

  useEffect(() => {
    if (isNil(dealMetadata)) {
      dispatch(
        setTileMetadata({
          tileCode: availableAutomaticTiles.monitoredRiskIndicatorDealSpecificsDealSelection,
          metadata: dealMetadata,
        }),
      )
    }
  }, [dispatch, dealsData, dealMetadata])

  const handleOnChange = useCallback(
    (_event, selectedDealUuid) => {
      const { name: selectedDealName, dealId: selectedDealId } =
        dealsData.find((deal) => deal?.dealUuid === selectedDealUuid) ?? {}

      dispatch(
        setTileMetadata({
          tileCode: availableAutomaticTiles.monitoredRiskIndicatorDealSpecificsDealSelection,
          metadata: { selectedDealUuid, selectedDealName, selectedDealId },
        }),
      )
    },
    [dealsData, dispatch],
  )

  const loadingHook = useCallback(
    () => ({
      isLoading: false,
      isError: false,
      data:
        dealsData?.map((deal) => ({
          key: deal?.dealUuid,
          name: `${deal?.name} (${tNoPrefix('entity-types.DEAL')} ${deal?.dealId})`,
        })) ?? [],
    }),
    [dealsData, tNoPrefix],
  )

  const renderDealSelect = useMemo(() => {
    if (isEmpty(dealsData)) {
      return (
        <CloseableMessageStrip design={MessageStripDesign.Information} hideCloseButton={true}>
          {tNoPrefix('components.business-partner.associated-deals.no-deals')}
        </CloseableMessageStrip>
      )
    }
    if (isPdfView) {
      return (
        <CloseableMessageStrip design={MessageStripDesign.Information} hideCloseButton={true}>
          {tNoPrefix('tiles.deal-selection.pdf-view')}
        </CloseableMessageStrip>
      )
    }
    return (
      <LoadingSelect
        id="riskMonitoringDeal-loadingSelect"
        className={styles.riskMonitoringDealSelect}
        loadingHook={loadingHook}
        selectedKey={dealMetadata?.selectedDealUuid}
        showEmptyOption={isNil(dealsData)}
        optionDisplayName="name"
        optionKeyName="key"
        onChange={handleOnChange}
      />
    )
  }, [dealMetadata?.selectedDealUuid, dealsData, handleOnChange, isPdfView, loadingHook, tNoPrefix])

  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      className={styles.riskMonitoringDealSelectionWrapper}
    >
      {renderDealSelect}
    </FlexBox>
  )
}

RiskMonitoringDealSelectionTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default RiskMonitoringDealSelectionTile
