import { first } from 'lodash'
import sortBy from 'lodash.sortby'
import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import AnnualReviewDealOverviewPropertySelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-selection/AnnualReviewDealOverviewPropertySelect'
import AnnualReviewPropertySelectPdfView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-selection/AnnualReviewPropertySelectPdfView'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/current-rent/MultiCurrentRentTile.module.css'
import PropertyRentRollOverviewPrimaryTenant from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/current-rent/components/PropertyRentRollOverviewPrimaryTenant'
import PropertyRentRollOverviewPrimaryTenantUnit from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/current-rent/components/PropertyRentRollOverviewPrimaryTenantUnit'
import usePropertyTenantsPieChartData from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/current-rent/hooks/usePropertyTenantsPieChartData'
import { useCurrencyFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared/hooks/i18n/useI18n'
import useAnnualizedCurrentRentPrimaryMetric from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared/useAnnualizedCurrentRentPrimaryMetric'
import useAnnualReviewDealOverviewSelectedProperty from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/utils/useAnnualReviewDealOverviewSelectedProperty'
import { ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/constants'
import CardHeaderWithMetrics from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/card/CardHeaderWithMetrics'
import DonutChart from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/ui/DonutChart'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/tegovaRating/shared/EmptyCardContent'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const MAX_DISPLAYED_TENANTS = 4

// This can be seen as a partial violation of the rule-of-hooks, however, since we always know at runtime how many properties we want to render,
// so hook order/amount between renders is equal, this is a safe (and necessary) exception.
const getHookData = ({ parameters, hook }) => hook(parameters)

const useCurrentRentChartData = ({ propertyKpiData, businessPartnerData }) => {
  const {
    data: currentRentChartDataWithTenantNames,
    isLoading: isLoadingChartData,
    isError: isErrorChartData,
    isEmpty: hasEmptyRentRollData,
  } = usePropertyTenantsPieChartData({
    propertyKpiData,
    businessPartnerData,
    getMetricFromTenantKpi: (tenantKpi) => tenantKpi.annualizedCurrentRent.number,
    maxDisplayedTenants: MAX_DISPLAYED_TENANTS,
  })

  const isLoading = isLoadingChartData
  const isError = isErrorChartData

  const totalPropertyAnnualizedCurrentRent = propertyKpiData?.annualizedCurrentRent?.number ?? 0
  const currency = propertyKpiData?.annualizedCurrentRent?.currency

  return {
    data: currentRentChartDataWithTenantNames,
    currency,
    isLoading,
    isError,
    isEmpty: hasEmptyRentRollData,
    isInvalidData: totalPropertyAnnualizedCurrentRent === 0,
  }
}

const MultiCurrentRentTile = ({ tileId, selectedDealIndex, isPdfView = false }) => {
  const dispatch = useDispatch()

  const {
    data: {
      dealsData,
      propertyRentRollOverviewByDealUuid,
      businessPartnerData,
      multipleBusinessPartnerRelationshipsDataByPrimaryTenantId,
    },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const selectedDealUuid = dealsData[selectedDealIndex]?.dealUuid

  const dealData =
    Object.values(propertyRentRollOverviewByDealUuid).find(
      ({ dealUuid }) => dealUuid === selectedDealUuid,
    ) ?? {}

  const { properties } = dealData

  const { selectedPropertyUuid, selectedPropertyDisplayId, selectedPropertyName } =
    useAnnualReviewDealOverviewSelectedProperty({
      propertiesData: properties,
      propertyMetadataTileCode: ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES.TENANCY_RENT_ROLL,
    })

  useEffect(() => {
    dispatch(
      setSourceRender({
        tileId,
        sourceRender: { propertyId: selectedPropertyDisplayId },
      }),
    )
  }, [dispatch, selectedPropertyDisplayId, tileId])

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.property.rent-roll.overview.current-rent',
  })

  const renderContent = useCallback(
    (currentProperty) => {
      const currentPropertyUuid = currentProperty?.uuid

      const propertyData =
        dealData.propertyKpis?.find(({ propertyUuid }) => propertyUuid === currentPropertyUuid)
          ?.keyDateToRentRollKpis?.[0] ?? {}

      const propertyKpiData = { keyDate: propertyData.keyDate, ...propertyData.kpis }

      const keyDate = propertyKpiData?.keyDate

      const getPrimaryTenantId = () => {
        const sortedTenantKPIs = sortBy(
          propertyKpiData?.tenantRentRollKpis,
          (item) => -item.annualizedCurrentRent.number,
        )
        return first(sortedTenantKPIs)?.tenant
      }

      const primaryTenantId = getPrimaryTenantId()

      const primaryMetric = getHookData({
        parameters: propertyKpiData,
        hook: useAnnualizedCurrentRentPrimaryMetric,
      })

      const secondaryMetrics = [
        {
          label: t('primary-tenant'),
          value: primaryTenantId ? (
            <PropertyRentRollOverviewPrimaryTenant
              businessPartnerData={businessPartnerData}
              primaryTenantId={primaryTenantId}
            />
          ) : undefined,
        },
        {
          label: t('tenant-unit'),
          value: primaryTenantId ? (
            <PropertyRentRollOverviewPrimaryTenantUnit
              primaryTenantId={primaryTenantId}
              multipleBusinessPartnerRelationshipsDataByPrimaryTenantId={
                multipleBusinessPartnerRelationshipsDataByPrimaryTenantId
              }
            />
          ) : undefined,
        },
      ]

      const {
        data: currentRentChartData,
        currency,
        isCurrentRentChartDataLoading,
        isCurrentRentChartDataError,
        isEmpty,
        isInvalidData,
      } = getHookData({
        parameters: { propertyKpiData, businessPartnerData },
        hook: useCurrentRentChartData,
      })

      const formatTooltipRent = getHookData({
        parameters: { currency },
        hook: useCurrencyFormatter,
      })

      // handle an edge case where the rent of all properties is zero, which would
      // result in an empty chart. In this case we simply show nothing.
      const shouldHideChart = !isEmpty && isInvalidData

      if (shouldHideChart) return null

      return (
        <div key={currentProperty?.uuid}>
          {isPdfView ? (
            <AnnualReviewPropertySelectPdfView {...currentProperty} />
          ) : (
            <AnnualReviewDealOverviewPropertySelect
              propertiesData={properties}
              propertyMetadataTileCode={
                ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES.TENANCY_RENT_ROLL
              }
            />
          )}
          <DecisionPaperTileListSeparator />
          <div className={styles.cardHeader}>
            <CardHeaderWithMetrics
              title={t('title')}
              primaryMetric={primaryMetric}
              secondaryMetrics={secondaryMetrics}
              referenceDate={keyDate}
              isLoading={isCurrentRentChartDataLoading}
              isError={isCurrentRentChartDataError}
            />
          </div>
          <div className={styles.chartContainer}>
            {isEmpty ? (
              <EmptyCardContent
                title={t('pie-chart.empty.title')}
                subtitle={t('pie-chart.empty.subtitle')}
              />
            ) : (
              <DonutChart data={currentRentChartData} tooltipValueFormatter={formatTooltipRent} />
            )}
          </div>
        </div>
      )
    },
    [
      businessPartnerData,
      dealData.propertyKpis,
      isPdfView,
      multipleBusinessPartnerRelationshipsDataByPrimaryTenantId,
      properties,
      t,
    ],
  )

  return isPdfView
    ? properties?.map((property) => renderContent(property))
    : renderContent({
        uuid: selectedPropertyUuid,
        id: selectedPropertyDisplayId,
        description: selectedPropertyName,
      })
}

MultiCurrentRentTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultiCurrentRentTile
