import { MessageBox, MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectDecisionPaperTemplateDialog, {
  okButtonTypes,
} from 'components/domains/business-events-and-tasks/decision-paper/dialogs/SelectDecisionPaperTemplateDialog'
import useCreateDecisionPaper from 'hooks/services/business-events-and-tasks/decision-papers/useCreateDecisionPaper'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const AddDecisionPaperDialog = ({ isOpen, setIsOpen }) => {
  const event = useContext(BusinessEventsAndTasksContext).event
  const { id: eventId } = event
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.add-dialog',
  })
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const queryClient = useQueryClient()
  const createDecisionPaperMutation = useCreateDecisionPaper()
  const showToast = Modals.useShowToast()

  const handleOnCreate = (
    selectedDecisionPaperTemplateCode,
    selectedDecisionPaper,
    custmoizedDecisionPaperTemplateStructure = null,
  ) => {
    createDecisionPaperMutation.mutate(
      {
        eventId,
        templateCode: selectedDecisionPaperTemplateCode,
        eventIdToImportDataFrom: selectedDecisionPaper,
        templateStructure: custmoizedDecisionPaperTemplateStructure,
      },
      {
        onSuccess: () => {
          setIsOpen(false)
          queryClient.invalidateQueries(['events', eventId])
          showToast({ children: t('success') })
        },
        onError: () => {
          setIsErrorDialogOpen(true)
        },
      },
    )
  }

  const handleOnCancel = () => {
    setIsOpen(false)
  }

  const handleOnCloseErrorDialog = () => {
    setIsErrorDialogOpen(false)
  }

  return (
    <>
      <MessageBox
        open={isErrorDialogOpen}
        onClose={handleOnCloseErrorDialog}
        type={MessageBoxTypes.Error}
      >
        {t('error')}
      </MessageBox>
      {isOpen && (
        <SelectDecisionPaperTemplateDialog
          title={t('title')}
          isOpen={isOpen}
          okButtonType={okButtonTypes.create}
          onOk={handleOnCreate}
          onCancel={handleOnCancel}
        />
      )}
    </>
  )
}

AddDecisionPaperDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default AddDecisionPaperDialog
