import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useUpdateMarketMacroProxies = (mutateOptions) => {
  const { post } = useAccessTokenRequest()

  return useMutation(
    ({ marketId, macroProxies }) =>
      post({
        path: `/property-monitoring/proxies/macros`,
        body: snakecaseKeys({
          marketId,
          overrideMacroProxySettings: macroProxies,
        }),
      }),
    mutateOptions,
  )
}
