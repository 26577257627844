import isNil from 'lodash.isnil'
import { useMemo } from 'react'
import { entityTypes } from 'api/conditions/conditions'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useMultipleCustomAssessmentVersions from 'hooks/services/central-data/custom-assessments/useMultipleCustomAssessmentVersions'
import { useDealHeaders } from 'hooks/services/deals/useDealHeaders'

const useMultipleConstructionAssessment = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { selectedDeal, dealsData } = {},
    isFetching: isAnnualReviewDealsFetching,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const { selectedDealDisplayId, selectedDealUuid } = selectedDeal ?? {}
  const dealUuids = dealsData?.map((dealData) => dealData.dealUuid)
  const {
    data: { live: { dealUuid: liveDealUuid } = {} } = {},
    isFetching: isFetchingLiveDealUuid,
    isError: isErrorLiveDealUuid,
    error: liveDealUuidError,
  } = useDealHeaders(selectedDealDisplayId)

  const isLiveDeal = liveDealUuid === selectedDealUuid

  const {
    data: multipleConstructionAssessmentData,
    isMultipleCustomAssessmentVersionsLoading,
    isMultipleCustomAssessmentVersionsError,
    error: multipleCustomAssessmentsVersionsError,
  } = useMultipleCustomAssessmentVersions({
    uuids: dealUuids,
    assessmentCode: 'CONSTRUCTION_ASSESSMENT',
    entityType: entityTypes.deal,
  })

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isMultipleCustomAssessmentVersionsLoading,
      isAnnualReviewDealsFetching,
      isFetchingLiveDealUuid,
    ],
    errorValues: [
      isMultipleCustomAssessmentVersionsError,
      isAnnualReviewDealsError,
      isErrorLiveDealUuid,
    ],
    errorDetails: [
      annualReviewDealsError,
      multipleCustomAssessmentsVersionsError,
      liveDealUuidError,
    ],
    tileId,
  })

  const sourcePathSuffix = useMemo(
    () => (!isNil(isLiveDeal) && !isLiveDeal ? '?working-version=true' : ''),
    [isLiveDeal],
  )

  return useMemo(() => {
    if (isSomeValueLoading || isSomeValueError) {
      return { isLoading: isSomeValueLoading, isError: isSomeValueError, error, data: undefined }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        multipleConstructionAssessment: multipleConstructionAssessmentData.map(
          (construction, index) => ({
            constructionAssessment: construction,
            dealUuid: dealUuids[index],
          }),
        ),
        sourceRender: {
          dealDisplayId: selectedDealDisplayId,
          sourcePathSuffix,
        },
        dealsData,
      },
    }
  }, [
    isSomeValueLoading,
    isSomeValueError,
    multipleConstructionAssessmentData,
    selectedDealDisplayId,
    sourcePathSuffix,
    dealsData,
    error,
    dealUuids,
  ])
}

export default useMultipleConstructionAssessment
