import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { multiPropertyValuations } from 'api/property/valuation/valuations'
import DisplayCardView, { hideOptions } from 'components/ui/card/DisplayCardView'
import '@ui5/webcomponents-fiori/dist/illustrations/sapIllus-Spot-UnableToLoad.js'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import useMultiPropertyValuations from 'hooks/services/properties/valuations/useMultiPropertyValuations'
import paths from 'routes/paths'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyValuationSummaryCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property-overview.valuation-summary-tile',
  })
  const formatMoney = useCustomizableCurrencyFormatter()
  const {
    property: { id: propertyId, uuid: propertyUuid },
  } = useContext(PropertyContext)
  const navigate = useNavigate()

  const {
    isLoading: isLoading,
    isError: isError,
    data: valuationsData,
  } = useMultiPropertyValuations([propertyUuid])

  const handleHeaderClick = useCallback(() => {
    navigate(`/${paths.properties}/${propertyId}/valuation`)
  }, [navigate, propertyId])

  const emptyContent = useMemo(
    () => ({ title: t('empty.title'), subtitle: t('empty.subtitle') }),
    [t],
  )
  const errorContent = useMemo(
    () => ({ title: t('error.title'), subtitle: t('error.subtitle') }),
    [t],
  )

  const getValuationValue = useCallback(
    (valuationType) => {
      if (isLoading || isError) {
        return ''
      }

      const propertyValuations = valuationsData.valuations[propertyUuid]
      if (!propertyValuations) {
        return ''
      }

      const propertyValuation = propertyValuations[valuationType]
      if (!propertyValuation) {
        return ''
      }

      return formatMoney(
        propertyValuation.value_amount.number,
        propertyValuation.value_amount.currency,
      )
    },
    [isLoading, isError, valuationsData, formatMoney, propertyUuid],
  )

  const fieldDefinitions = useMemo(
    () => [
      {
        label: t('market-value'),
        value: getValuationValue(multiPropertyValuations.marketValue),
        hideOption: hideOptions.hideWhenEmpty,
      },
      {
        label: t('mortgage-lending-value'),
        value: getValuationValue(multiPropertyValuations.mortgageValue),
        hideOption: hideOptions.hideWhenEmpty,
      },
      {
        label: t('purchase-price'),
        value: getValuationValue(multiPropertyValuations.purchasePrice),
        hideOption: hideOptions.hideWhenEmpty,
      },
    ],
    [getValuationValue, t],
  )

  return (
    <DisplayCardView
      cardHeaderTitle={t('title')}
      emptyContent={emptyContent}
      isError={isError}
      errorContent={errorContent}
      fieldDefinitions={fieldDefinitions}
      isEditable={false}
      isLoading={isLoading}
      onHeaderClick={handleHeaderClick}
      setEditMode={() => {}}
    />
  )
}

export default PropertyValuationSummaryCard
