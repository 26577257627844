import {
  FlexBox,
  Table,
  TableCell,
  TableGrowingMode,
  TableRow,
} from '@fioneer/ui5-webcomponents-react'
import find from 'lodash.find'
import orderBy from 'lodash.orderby'
import pick from 'lodash.pick'
import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import SortedTablePropTypes from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/tables/sorted-tables/SortedTablePropTypes'
import createColumns from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/tables/sorted-tables/createColumns'
import useFilterTypes from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/tables/sorted-tables/useFilters'
import TablesToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/tables/toolbar/TablesToolbar'

/**
SortedTable: A Table that has built in functionality for sorting/searching, by keeping flexibility for
custom columns, cells and rows in regards to functionality and styling.

Required arguments:
- columnDefinitions: An array of objects containing the definitions of the columns.
    - columnKey: A unique string identifying the column (needed for sorting and matching of tableData to the columns)
    - sortingDisabled: An optional boolean flag whether sorting is disabled for this row, defaults to false
    - renderColumnContent: A function to render the Column Cell at the top of the Table. Either renderColumnContent or title has to be set.
      If both are set, renderColumnContent will take precedence.
    - title: A simple text that will be rendered in the column Cell at the top of the table. Either renderColumnContent or title has to be set.
      If both are set, renderColumnContent will take precedence.
    - onClick: Optional onClick handler that will be called if the column was clicked.
    - wrapText: Flag whether to wrap the title label text
    - alignment: Optional alignment for the column
    - filter: FilterType, if the column is filterable.
      - for date filters the value has to be localized to make comparison possible
    - additionalFilterOptions: Optional if filter type needs more information
      - enumValues: EnumValues needed for Filter Type OF_ENUM_TYPE to show in Select of FilterDialog
    - filterLabelText: Optional string to override label text in filter dialog
    - ...additionalProperties: All additional keys in the object will be rendered as properties on the <TableColumn>
  - tableData: An array containing the actual rows of the table. Each item in the array represents one row. Each item needs to have the following structure:
    - rowKey: A unique string identifying the row
    - rowProperties: Optional additional properties to render with the corresponding <TableRow>
    - [columnKey]: For each column a key matching the columnKey is expected representing the content of the specific cell in the specific row. The value of that key is
      an object with the following structure:
        - cellComponent: The component to render within the <TableCell>
        - value: The plaintext string / number / value on which sorting will happen. Required, if the column should be sortable or searchable.
    Set this property to specify the total number of items. If not specified, the length of tableData will be used instead.
  - initialSortingColumnKey: The columnKey of the column to sort initially.
  - initialSortingAscending: An optional boolean whether on first sort or on first click on a column sorting should happen ascending or descending.
      If true: sorting will be ascending initially. If false: Sorting will be descending initially. Defaults to true --> ascending sorting
  - renderEmptyTableComponent: When tableData is an empty array, this function will be called.
  - additionalTableProperties: Optional additional table properties that will be rendered for the <Table>
  - customOrderFunction: Optional ordering function If you don't want the default behaviour of sorting by value. This function gets called with three arguments:
    - tableData: The tableData handed in and which shall be sorted. Make sure to create a copy when sorting instead of sorting this array directly.
    - sortingKey: The column key for which sorting shall happen
    - order: can be either 'asc' or 'desc'
  - customOrderCallback: Optional function that will be called when sorting / clicking on a column happens.
    - sortingKey: The column key for which sorting should happen
    - order: Can be either 'asc' or 'desc'
  - toolbarConfig: Optional configuration for a table toolbar that makes the table searchable. If not present, the toolbar will not be rendered.
    For the data to be searchable, the 'value' key must be present in 'tableData'.
    - title: Title of the toolbar
    - style: Optional toolbar style
    - sorting: Optional sorting config. if present, sorting will be activated
      - columnKey
      - isSortingAscending
    - searching: whether the toolbar contains a search field,
    - showColumnSelection: whether the toolbar shows the column selection,
    - additionalActions: optional toolbar actions next to the filters (right alligned)
    - additionalActionsNextToTitle: optional toolbar actions next to the title (left alligned)
  - paginationConfig: Optional configuration to support pagination
    - growing: Set to TableGrowingMode.Button to show a "More" Button below the table. Default is TableGrowingMode.None
    - growingButtonText: Text to show in the first line of the "More" Button. Should be set to translated "More" or "Mehr"
    - growingButtonSubtext: Text to show in the second line of the "More" Button. Set for example to "[ <currentlyVisible> / <total> ]"
    - totalNumberOfItems: Total number of items matching the filter. Shown in the table header, even if not all items are shown due to pagination.
    - loadMore: Callback to load the next chunk of items from the backend. The callback takes no arguments.
  - maximumRowsShown: Limits the number of rows that gets displayed
*/
const SortedTable = ({
  columnDefinitions,
  tableData,
  renderEmptyTableComponent,
  additionalTableProperties,
  customOrderFunction,
  customOrderCallback,
  toolbarConfig,
  paginationConfig = {
    growing: TableGrowingMode.None,
    loadMore: () => {},
  },
  maximumRowsShown,
  noDataText,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix:
      'decision-paper.tiles.risk-monitoring.risk-indicators-description.components.tables.sorted-table',
  })
  const { isSortingAscending = true, columnKey: sortingKey } = toolbarConfig?.sorting || {}
  const [sorting, setSorting] = useState({
    columnKey: sortingKey,
    isSortingAscending,
    sortingKey: `${sortingKey}.value`,
  })

  const { filterForType } = useFilterTypes()
  const [searchParam, setSearchParam] = useState('')
  const [columnSelection, setColumnSelection] = useState(
    columnDefinitions.map(
      ({ columnKey, title, isVisible = true, isSelectableForHiding = true }) => ({
        columnKey,
        title,
        isVisible,
        isSelectableForHiding,
      }),
    ),
  )
  const [filters, setFilters] = useState(
    columnDefinitions
      .filter(({ filter }) => !!filter)
      .map(({ filter, title, columnKey, filterLabelText, additionalFilterOptions }) => ({
        value: '',
        ...(typeof filter === 'string' ? filterForType(filter) : filter),
        columnKey,
        label: filterLabelText ?? title,
        additionalFilterOptions,
      })),
  )

  const sortedTableData = useMemo(() => {
    const rowContainsSearchParam = (row) =>
      columnDefinitions.some(({ columnKey }) =>
        String(row[columnKey].value).toLowerCase().includes(searchParam.toLowerCase()),
      )

    const rowMatchesFilters = (row) =>
      filters.every(
        ({ columnKey, filterFunction, value, emptyValue, additionalFilterOptions }) =>
          !value ||
          JSON.stringify(value) === JSON.stringify(emptyValue) ||
          filterFunction({ searchValue: value, cell: row[columnKey], additionalFilterOptions }),
      )

    const sortDirection = sorting.isSortingAscending ? 'asc' : 'desc'

    return (
      customOrderFunction && typeof customOrderFunction === 'function'
        ? customOrderFunction([...tableData], sorting.columnKey, sortDirection)
        : orderBy([...tableData], sorting.sortingKey, sortDirection)
    )
      .filter((currentRow) => !searchParam || rowContainsSearchParam(currentRow))
      .filter((currentRow) => !filters || rowMatchesFilters(currentRow))
      .slice(0, maximumRowsShown)
  }, [
    columnDefinitions,
    customOrderFunction,
    filters,
    maximumRowsShown,
    searchParam,
    sorting.columnKey,
    sorting.isSortingAscending,
    sorting.sortingKey,
    tableData,
  ])

  if (tableData.length === 0 && typeof renderEmptyTableComponent === 'function') {
    return renderEmptyTableComponent()
  }

  const noDataSearchFilterText = () => {
    const isFilterSet = filters.some(({ value }) => !!value)

    if (isFilterSet && searchParam) {
      return t('no-data-search-and-filter')
    }
    if (isFilterSet) {
      return t('no-data-filter')
    }
    if (searchParam) {
      return t('no-data-search')
    }
    return noDataText
  }

  const onUpdateSorting = ({ sortBy, sortDescending }) => {
    const selectedColumn = find(columnDefinitions, ['title', sortBy])
    if (selectedColumn.sortingDisabled) return
    if (customOrderCallback && typeof customOrderCallback === 'function') {
      customOrderCallback(selectedColumn.columnKey, sortDescending ? 'desc' : 'asc')
    }
    setSorting({
      columnKey: selectedColumn.columnKey,
      isSortingAscending: !sortDescending,
      sortingKey: `${selectedColumn.columnKey}.value`,
    })
  }

  const alignContent = (content, alignment) =>
    alignment ? <FlexBox justifyContent={alignment}>{content}</FlexBox> : content

  const toolbarAdditionalProperties = pick(toolbarConfig, ['style', 'className'])

  const renderToolbar = () => {
    const { title, searching, sorting: sortingConfig, additionalActions } = toolbarConfig
    if (sortingConfig) {
      const { columnKey, isSortingAscending: sortAscending } = sorting
      toolbarAdditionalProperties.sorting = {
        columnKey,
        isSortingAscending: sortAscending,
        onUpdateSorting,
        sortableColumns: columnDefinitions
          .filter(({ sortingDisabled }) => !sortingDisabled)
          .map((column) => ({
            columnKey: column.columnKey,
            title: column.title,
          })),
      }
    }
    if (filters.length > 0) {
      toolbarAdditionalProperties.filtering = { filters, setFilters }
    }
    if (searching) {
      toolbarAdditionalProperties.searching = {
        searchParam,
        onUpdateSearchParam: (newSearchParam) => {
          setSearchParam(newSearchParam)
        },
      }
    }

    toolbarAdditionalProperties.additionalActions = additionalActions
    toolbarAdditionalProperties.style = { marginBottom: 0, ...toolbarAdditionalProperties.style }

    const calculateTotalNumberOfItems = () => {
      if (toolbarConfig.dontShowNumberOfEntries) return
      const isPaginated = paginationConfig?.totalNumberOfItems !== undefined
      return isPaginated ? paginationConfig?.totalNumberOfItems : sortedTableData.length
    }

    return (
      <TablesToolbar
        nrOfEntries={calculateTotalNumberOfItems()}
        title={title}
        columnSelection={columnSelection}
        setColumnSelection={setColumnSelection}
        showColumnSelection={toolbarConfig.showColumnSelection}
        additionalActionsNextToTitle={toolbarConfig.additionalActionsNextToTitle}
        {...toolbarAdditionalProperties}
      />
    )
  }

  const visibleColumns = columnDefinitions.filter(
    ({ columnKey }) => find(columnSelection, ['columnKey', columnKey])?.isVisible,
  )
  const sortedColumnns = columnSelection
    .map(({ columnKey }) => ({ ...find(visibleColumns, ['columnKey', columnKey]) }))
    .filter((value) => Object.keys(value).length !== 0)

  return (
    <div className="sorted-table">
      {toolbarConfig && renderToolbar()}
      <Table
        columns={createColumns(sortedColumnns)}
        growing={paginationConfig.growing}
        growingButtonText={paginationConfig.growingButtonText}
        growingButtonSubtext={paginationConfig.growingButtonSubtext}
        hideNoData={!noDataText}
        noDataText={noDataSearchFilterText()}
        onLoadMore={paginationConfig.loadMore}
        {...additionalTableProperties}
      >
        {sortedTableData.map((currentRow) => (
          <TableRow
            className={currentRow.rowClassName}
            key={`${currentRow.rowKey}`}
            data-id={currentRow.rowKey}
            {...currentRow.rowProperties}
          >
            {sortedColumnns.map(({ columnKey, alignment, additionalColumnCellProperties }) => (
              <TableCell
                key={`${currentRow.tableKey}-${columnKey}`}
                {...additionalColumnCellProperties}
              >
                {alignContent(currentRow[columnKey].cellComponent, alignment)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
    </div>
  )
}

SortedTable.propTypes = SortedTablePropTypes

export default SortedTable
