import { useMemo } from 'react'
import { useCommonCurrencyCodes } from 'hooks/services/properties/useCommonCurrencyCodes'
import { useCurrencyCodes } from 'hooks/services/properties/useCurrencyCodes'
import { useRentRoll } from 'hooks/services/properties/useRentRoll'

export default function usePropertyRentRollCurrencyButtonCurrencies(propertyUuid) {
  const {
    isLoading: isLoadingCurrencyCodesFromCMS,
    isError: isErrorCurrencyCodesFromCMS,
    data: currencyCodesFromCMS,
  } = useCurrencyCodes()

  // It is possible to configure common currencies in the property service.
  // The reason for this is that you don't necessarily want to display all currencies in an html element, but only the "most used" ones.
  const {
    isLoading: isLoadingCommonCurrencyCodesFromCWP,
    isError: isErrorCommonCurrencyCodesFromCWP,
    data: commonCurrencyCodesFromCWP,
  } = useCommonCurrencyCodes()

  const { isLoading: isLoadingRentRoll, data: rentRollData } = useRentRoll(propertyUuid)

  const validCommonCurrencies = useMemo(() => {
    if (!currencyCodesFromCMS || !commonCurrencyCodesFromCWP) {
      return []
    }

    const availableCurrencyCodes = currencyCodesFromCMS.currency_codes.map(
      (currencyCode) => currencyCode.key,
    )
    const commonCurrencyCodes = commonCurrencyCodesFromCWP.common_currencies.map(
      (currencyCode) => currencyCode.currency_code,
    )

    return commonCurrencyCodes.filter((currencyCode) =>
      availableCurrencyCodes.includes(currencyCode),
    )
  }, [currencyCodesFromCMS, commonCurrencyCodesFromCWP])

  const rentRollCurrencies = useMemo(
    () =>
      rentRollData?.rental_units
        ?.map(
          (rentalUnit) =>
            rentalUnit?.rent_contracted_year?.currency ||
            rentalUnit?.rent_current_year?.currency ||
            rentalUnit?.rent_contracted_year_in_head_quarter_currency?.currency,
        )
        ?.filter((currency) => currency !== null) || [],
    [rentRollData],
  )

  const menuButtonCurrencies = useMemo(
    () => [...new Set([...validCommonCurrencies, ...rentRollCurrencies])],
    [validCommonCurrencies, rentRollCurrencies],
  )

  return {
    isLoading:
      isLoadingCommonCurrencyCodesFromCWP || isLoadingCurrencyCodesFromCMS || isLoadingRentRoll,
    isError: isErrorCommonCurrencyCodesFromCWP || isErrorCurrencyCodesFromCMS,
    data: menuButtonCurrencies,
  }
}
