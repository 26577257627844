import {
  Button,
  FileUploader,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  IllustratedMessage,
  Label,
  Table,
  TableCell,
  TableColumn,
  TableRow,
} from '@fioneer/ui5-webcomponents-react'
import { findIndex, update } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropertyImage from 'components/domains/properties/general-information/image/PropertyImage'
import PropertyImageEditInput from 'components/domains/properties/general-information/image/edit/PropertyImageEditInput'
import LoadingButton from 'components/ui/button/LoadingButton'
import { useNumberFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'
import useStaffMemberByIds from 'hooks/services/business-partners/staff-members/useStaffMemberByIds'

const ONE_MILLION = 1_000_000

const PropertyImageEditTable = ({
  images,
  editStatuses,
  onImageDescriptionChange,
  onToggleEdit,
  isMoveDownDisabled,
  isMoveUpDisabled,
  onMoveDown,
  onMoveUp,
  onSelectionChange,
  onOpenDeleteDialog,
  onResetImageDescription,
  onUploadImage,
  isUploading,
}) => {
  const acceptedImages = '.jpeg,.jpg,.png,.gif'

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.general-information.image.edit',
  })
  const formatNumber = useNumberFormatter()
  const { format: formatDate } = useShortDateFormatter()
  const getUploadedByIds = (images) =>
    images.map((image) => {
      if (image?.meta?.uploaded_by) {
        return image.meta.uploaded_by
      }
    })

  const {
    data: staffMembers = [],
    isLoading: isLoadingStaffMembers,
    isError: isErrorStaffMembers,
  } = useStaffMemberByIds(getUploadedByIds(images))

  const [changedImageDescriptions, setChangedImageDescriptions] = useState([])

  const updateChangedImageDescription = (uuid, description) => {
    setChangedImageDescriptions((prev) => {
      const changedImageDescription = {
        uuid: uuid,
        description: description,
      }
      const index = findIndex(prev, ['uuid', uuid])
      if (index !== -1) {
        return update(prev, index, () => changedImageDescription)
      }
      return [...prev, changedImageDescription]
    })
  }

  useEffect(() => {
    // If the images prop is updated with new data, update the state accordingly
    const updatedDescriptions = images.map((image) => ({
      uuid: image?.uuid,
      description: image?.description,
    }))
    updatedDescriptions.forEach((updatedDescription) =>
      updateChangedImageDescription(updatedDescription.uuid, updatedDescription.description),
    )
  }, [images])

  const handleOnChangeDescription = (image, newDescription) =>
    updateChangedImageDescription(image.uuid, newDescription)

  const getUploadedByName = (uploadedById) => {
    if (isLoadingStaffMembers || isErrorStaffMembers) {
      return t('unknown')
    }
    const staffMember = staffMembers.find((staffMember) => staffMember.id === uploadedById)
    return staffMember?.fullName
  }

  const getImageDescription = (currentImage) => currentImage.description

  const getEditStatus = (image) => {
    const imageEditStatus = editStatuses.find((status) => status === image?.uuid)
    if (imageEditStatus) {
      return true
    }
    return false
  }

  const renderFileUploadButton = () => (
    <FileUploader
      hideInput
      disabled={isUploading}
      className={'property-image-upload'}
      accept={acceptedImages}
      onChange={(e) => onUploadImage(e)}
    >
      <LoadingButton
        id="property-image-upload-button"
        isLoading={isUploading}
        isError={false}
        design="Transparent"
        renderContent={() => t('upload')}
        disabled={isUploading}
      />
    </FileUploader>
  )

  const renderNoImages = () => (
    <IllustratedMessage
      className="property-image-no-data"
      name="NoData"
      titleText={t('no-images-title')}
      subtitle={
        <FlexBox direction="Column" justifyContent="Center" alignItems="Center">
          <Label className="property-image-no-data-sub-title">{t('no-images-sub-title')}</Label>
          {renderFileUploadButton()}
        </FlexBox>
      }
    />
  )

  const formatFileSize = (size) => {
    if (size > ONE_MILLION) {
      return formatNumber(size / ONE_MILLION) + ' MB'
    }
    return formatNumber(size / 1000) + ' KB'
  }

  const renderImageMeta = (image) => (
    <div className="property-image-row-cell">
      <FlexBox direction="Column" justifyContent="Center" alignItems="Start">
        <PropertyImageEditInput
          imageDescription={getImageDescription(image)}
          imageUrl={image?.url}
          inEdit={getEditStatus(image)}
          onChange={(newDescription) => handleOnChangeDescription(image, newDescription)}
        />
        <span className="property-image-meta">
          {t('uploaded-by') + ' '}
          {getUploadedByName(image?.meta?.uploaded_by)}
          {' • ' + t('uploaded-on') + ' ' + formatDate(image?.meta?.uploaded_at)}
          {' • ' + t('file-size') + ' ' + formatFileSize(image?.meta?.size)}
          {' • ' + t('format') + ' ' + image?.meta?.format}
        </span>
      </FlexBox>
    </div>
  )

  const renderEditOrRenameButton = (image) => {
    if (!getEditStatus(image)) {
      return (
        <Button
          className="property-image-edit-button"
          design="Transparent"
          icon="edit"
          onClick={() => onToggleEdit(image)}
        />
      )
    } else {
      return (
        <Button
          className="property-image-edit-button"
          design="Transparent"
          onClick={() => {
            onToggleEdit(image)
            onImageDescriptionChange(
              image,
              changedImageDescriptions.find((changedImage) => changedImage.uuid === image.uuid)
                ?.description,
            )
          }}
        >
          {t('rename')}
        </Button>
      )
    }
  }

  const renderDeleteOrResetButton = (image) => {
    if (!getEditStatus(image)) {
      return (
        <Button
          className="property-image-delete-or-reset-button"
          design="Transparent"
          icon="delete"
          onClick={() => onOpenDeleteDialog(image)}
        />
      )
    } else {
      return (
        <Button
          className="property-image-delete-or-reset-button"
          design="Transparent"
          onClick={() => onResetImageDescription(image)}
        >
          {t('cancel')}
        </Button>
      )
    }
  }

  const renderImageRow = (image) => (
    <TableRow key={image?.uuid}>
      <TableCell style={{ display: 'none' }}>{image?.uuid}</TableCell>
      <TableCell>
        <FlexBox className="property-image-row-cell" justifyContent="Center" alignItems="Center">
          <PropertyImage
            imageUuid={image?.image_uuid}
            imageTitle={image.meta.name}
            isThumbnail={true}
          />
        </FlexBox>
      </TableCell>
      <TableCell>{renderImageMeta(image)}</TableCell>
      <TableCell>
        <div className="property-image-row-cell">
          <FlexBox direction="Row" justifyContent="End" alignItems="Center">
            {renderEditOrRenameButton(image)}
            {renderDeleteOrResetButton(image)}
          </FlexBox>
        </div>
      </TableCell>
    </TableRow>
  )

  const renderImagesRows = () =>
    images
      .sort((a, b) => a.property_image_order - b.property_image_order)
      .map((imageData) => renderImageRow(imageData))

  if (images.length === 0) {
    return renderNoImages()
  } else {
    return (
      <Table
        onSelectionChange={(e) => onSelectionChange(e)}
        mode="SingleSelect"
        columns={
          <>
            <TableColumn>
              <FlexBox
                direction={FlexBoxDirection.Column}
                justifyContent={FlexBoxJustifyContent.Center}
                alignItems={FlexBoxAlignItems.Center}
              >
                {renderFileUploadButton()}
              </FlexBox>
            </TableColumn>
            <TableColumn />
            <TableColumn>
              <FlexBox direction="Row" justifyContent="End" alignItems="Center">
                <Button
                  id="property-image-edit-move-up"
                  disabled={isMoveUpDisabled}
                  design="Transparent"
                  icon="slim-arrow-up"
                  onClick={() => (isMoveUpDisabled ? (() => {})() : onMoveUp())}
                />
                <Button
                  id="property-image-edit-move-down"
                  disabled={isMoveDownDisabled}
                  icon="slim-arrow-down"
                  design="Transparent"
                  onClick={() => (isMoveDownDisabled ? (() => {})() : onMoveDown())}
                />
              </FlexBox>
            </TableColumn>
          </>
        }
      >
        {renderImagesRows()}
      </Table>
    )
  }
}
PropertyImageEditTable.propTypes = {
  images: PropTypes.array.isRequired,
  editStatuses: PropTypes.array.isRequired,
  onImageDescriptionChange: PropTypes.func.isRequired,
  onToggleEdit: PropTypes.func.isRequired,
  isMoveDownDisabled: PropTypes.bool.isRequired,
  isMoveUpDisabled: PropTypes.bool.isRequired,
  onMoveDown: PropTypes.func.isRequired,
  onMoveUp: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onOpenDeleteDialog: PropTypes.func.isRequired,
  onResetImageDescription: PropTypes.func.isRequired,
  onUploadImage: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
}

export default PropertyImageEditTable
