import { useMemo } from 'react'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useSyndicationIntention from 'hooks/services/deals/syndication/useSyndicationIntention'
import useDealMini from 'hooks/services/deals/useDealMini'

const useOpsMemoSyndicationPostSale = ({ entityRef: { entityId: dealUuid } }, _, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    data: { dealId: dealDisplayId } = {},
    isFetching: isFetchingDeal,
    isError: isErrorDeal,
  } = useDealMini(dealUuidByTileCode)

  const {
    data: syndicationIntentionData,
    isFetching: isFetchingSyndication,
    isError: isErrorSyndication,
  } = useSyndicationIntention(dealUuidByTileCode)

  const sourcePathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?dataSource=newBusiness&working-version=true' : ''),
    [isWorkingVersionDataTile],
  )

  return useMemo(() => {
    const isLoading = isDealUuidFetching || isFetchingDeal || isFetchingSyndication
    const isError = isDealUuidError || isErrorDeal || isErrorSyndication

    if (isLoading || isError) {
      return { isLoading, isError, data: undefined }
    }
    const data = {
      syndicationIntentionPostSale: syndicationIntentionData?.syndicationPostSale ?? {},
      sourceRender: { dealDisplayId, sourcePathSuffix },
    }

    return {
      isLoading: false,
      isError: false,
      data,
    }
  }, [
    dealDisplayId,
    isDealUuidError,
    isDealUuidFetching,
    isErrorDeal,
    isErrorSyndication,
    isFetchingDeal,
    isFetchingSyndication,
    sourcePathSuffix,
    syndicationIntentionData?.syndicationPostSale,
  ])
}

export default useOpsMemoSyndicationPostSale
