import { AnalyticalTableSelectionMode } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { conditionTypes, conditionsEntityTypes } from 'api/conditions/conditions'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/external/ExternalConditionsTileViewMode.module.css'
import DecisionPaperConditionsTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/DecisionPaperConditionsTable'
import DecisionPaperConditionsTileToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/DecisionPaperConditionsTileToolbar'
import useDecisionPaperConditionTableColumns from 'hooks/services/business-events-and-tasks/decision-papers/tiles/conditions/useDecisionPaperConditionTableColumns'
import useDecisionPaperLinkConfirmationDialog from 'hooks/services/business-events-and-tasks/decision-papers/useDecisionPaperLinkConfirmationDialog'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import paths from 'routes/paths'

const noop = () => {}

// Copied from 'hooks/services/conditions/tables/useConditionFilterQuery'
const conditionFilterPrefixes = {
  conditions: 'con_',
  requirements: 'req_',
}

// Copied from 'hooks/services/conditions/tables/useConditionFilterQuery'
const conditionServiceTypes = {
  conditions: 'conditions',
  requirements: 'requirements',
}

const ExternalConditionsTileViewMode = ({
  tileId,
  shownColumns,
  showConfirmationForLinks,
  isPdfView,
  shownSubcomponents,
  subcomponentHeight,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.external-conditions',
  })
  const {
    data: {
      selectedConditions,
      statusValues,
      sourceRender: { displayId },
    },
    version: tileVersion,
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const { event: businessEvent } = useContext(BusinessEventsAndTasksContext)
  const entityType = businessEvent?.entityRef?.entityType
  const entityId = businessEvent?.entityRef?.entityId

  const linkBasePath = useMemo(() => {
    if (entityType === conditionsEntityTypes.deal) {
      return `/${paths.deals}/${displayId}/${dealDetailPaths.conditions}`
    }
    if (entityType === conditionsEntityTypes.businessPartner) {
      return `/${paths.businessPartners}/${entityId}/conditions`
    }
    return ''
  }, [entityType, entityId, displayId])

  const showRequirements = useCallback(
    (event) => {
      const search = `${conditionFilterPrefixes.requirements}externalConditionId=${event.detail.row.original.id}`
      const hash = `${conditionServiceTypes.requirements}`
      const link = `${linkBasePath}?${search}#${hash}`
      window.open(link, '_blank')
    },
    [linkBasePath],
  )

  const { showConfirmationDialog } = useDecisionPaperLinkConfirmationDialog({
    onConfirm: showRequirements,
  })

  const { columnSelection } = useDecisionPaperConditionTableColumns({
    shownColumns,
    conditionType: conditionTypes.external,
    entityType,
    tileVersion,
  })

  if (isPdfView) {
    columnSelection.forEach((column) => {
      delete column.minWidth
      delete column.width
    })
  }

  return (
    <div className={styles.tableWrapper}>
      <DecisionPaperConditionsTileToolbar numberOfConditions={selectedConditions.length} />
      <DecisionPaperConditionsTable
        isLoading={false}
        conditions={selectedConditions}
        columns={columnSelection}
        isFilterOrSearchApplied={false}
        fetchNextPage={noop}
        conditionType={conditionTypes.external}
        statusValues={statusValues}
        selectionMode={AnalyticalTableSelectionMode.None}
        emptyText={t('empty')}
        onRowClick={showConfirmationForLinks ? showConfirmationDialog : showRequirements}
        alwaysShowDescription={isPdfView}
        shownSubcomponents={shownSubcomponents}
        subcomponentHeight={subcomponentHeight}
        isPdfView={isPdfView}
      />
    </div>
  )
}

ExternalConditionsTileViewMode.propTypes = {
  tileId: PropTypes.string.isRequired,
  shownColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  shownSubcomponents: PropTypes.shape({
    externalAssignee: PropTypes.bool,
    description: PropTypes.bool,
  }),
  subcomponentHeight: PropTypes.number.isRequired,
}

export default ExternalConditionsTileViewMode
