import { Icon, FlexBox } from '@fioneer/ui5-webcomponents-react'

import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, Outlet } from 'react-router-dom'
import ScrollToTop from 'ScrollToTop'
import ErrorBoundary from 'components/ui/errors/ErrorBoundary'
import styles from 'components/ui/navigation/NavigationL1.module.css'
import { ConfigContext } from 'hooks/config/useConfig'
import useEntitlementsAllowedOperations from 'hooks/services/entitlements/useEntitlementsAllowedOperations'
import paths from 'routes/paths'

/**
 * Renders a L1 Navigation Bar on the left. Displays icons, and a tooltip on hover.
 */
const NavigationL1 = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'navigation.item.title' })
  const config = useContext(ConfigContext)

  const renderItems = (items) =>
    items
      .filter(({ condition }) => condition ?? true)
      .map(({ path, translationToken, icon }) => (
        <NavLink
          key={path}
          className={['sapUiContentPadding', styles.menuItem].join(' ')}
          to={path}
          end={path === ''}
          title={t(translationToken)}
        >
          <Icon name={icon} className={styles.l1NavIcon} />
        </NavLink>
      ))

  /**
   * Icons at the top of the navigation
   */
  const top = [
    {
      path: paths.home,
      icon: 'home',
      translationToken: 'home',
    },
    {
      path: paths.businessEventsAndTasks,
      icon: 'task',
      translationToken: 'business-events-and-tasks',
    },
    { path: paths.businessPartners, icon: 'company-view', translationToken: 'business-partners' },
    { path: paths.deals, icon: 'insurance-house', translationToken: 'deals' },
    { path: paths.properties, icon: 'addresses', translationToken: 'properties' },
    { path: paths.markets, icon: 'map-2', translationToken: 'market-overview' },
  ]

  /**
   * Icons at the bottom of the navigation
   */
  const { data: entitlementsAllowedOperationsData } = useEntitlementsAllowedOperations()
  const bottom = [
    {
      path: paths.entitlements,
      icon: 'badge',
      translationToken: 'entitlements',
      condition: entitlementsAllowedOperationsData?.allowed_operations?.length > 0,
    },
    {
      path: paths.userProfile,
      icon: 'customer',
      translationToken: 'user-profile',
    },
  ]

  return (
    <>
      <ScrollToTop />
      <FlexBox direction="row" justifyContent="Start">
        <nav className={styles.shellbarContainer}>
          <div className={styles.topItemsContainer}>
            <br className={styles.break} />
            <div>
              <img className={styles.companyLogo} src={config?.companyLogo} alt="company logo" />
              <hr className={styles.separator} />
              <div className={styles.topIconsContainer}>{renderItems(top)}</div>
              <hr className={styles.separator} />
            </div>
          </div>
          <div className={styles.bottomItemsContainer}>{renderItems(bottom)}</div>
        </nav>
        <main className={[styles.contentArea, 'sapScrollBar'].join(' ')}>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </main>
      </FlexBox>
    </>
  )
}

export default NavigationL1
