import { Modals } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import BusinessPartnersForEntityTable from 'components/domains/business-partners/entity-management/BusinessPartnersForEntityTable'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'

const BusinessPartsForEntityCardHooksPropType = PropTypes.shape({
  getBusinessPartnerSuggestions: PropTypes.func.isRequired,
  useLinkBusinessPartnerWithEntity: PropTypes.func.isRequired,
  useUnlinkBusinessPartnerFromEntity: PropTypes.func.isRequired,
  useUpdateBusinessPartner: PropTypes.func.isRequired,
  useBusinesspartnersById: PropTypes.func.isRequired,
  useBusinessPartnerFunctions: PropTypes.func.isRequired,
  useBusinessPartnersForEntity: PropTypes.func.isRequired,
})

const propTypes = {
  tTable: PropTypes.func.isRequired,
  tCard: PropTypes.func.isRequired,
  renderMessageStrip: PropTypes.func,
  partnerFunctionMode: PropTypes.string.isRequired,
  hooks: BusinessPartsForEntityCardHooksPropType.isRequired,
  entityUuid: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  invalidateFunctionMode: PropTypes.func.isRequired,
  getPartnerIds: PropTypes.func,
}

/**
 * Table to manage business partners that are linked to an entity.
 *
 *  @param {PropTypes.InferProps<typeof propTypes>} props
 */
const BusinessPartnersForEntityCard = ({
  tTable,
  tCard,
  partnerFunctionMode,
  renderMessageStrip = () => undefined,
  hooks,
  entityUuid,
  isEditable,
  invalidateFunctionMode,
  getPartnerIds,
}) => {
  const { t } = useTranslation()

  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false)
  const [saveErrorDetails, setSaveErrorDetails] = useState(undefined)

  const {
    getBusinessPartnerSuggestions,
    useBusinessPartnersForEntity,
    useLinkBusinessPartnerWithEntity,
    useUnlinkBusinessPartnerFromEntity,
    useUpdateBusinessPartner,
    useBusinesspartnersById,
    useBusinessPartnerFunctions,
  } = hooks

  const { data, isLoading, isError } = useBusinessPartnersForEntity(entityUuid, partnerFunctionMode)

  const rowKey = (partner) => partner.party_id + '_' + partner.business_partner_function_code

  const partnersOnEntity = data?.business_partners
    ? [
        ...data.business_partners.map((businessPartner) => ({
          rowKey: rowKey(businessPartner),
          ...businessPartner,
        })),
      ]
    : []

  const showToast = Modals.useShowToast()

  const asyncSetSaveErrorDetails = async (error) =>
    setSaveErrorDetails(await formatHookError(error))

  const saveRow = useLinkBusinessPartnerWithEntity({
    onSuccess: () => {
      invalidateFunctionMode()
      showToast({
        children: tTable('saved'),
      })
    },
    onError: (error) => {
      setIsErrorMessageOpen(true)
      asyncSetSaveErrorDetails(error)
    },
  })

  const handleSaveRow = (partnerWithFunction) => {
    saveRow.mutate({
      entityUuid,
      partnerWithFunction: { ...partnerWithFunction },
    })
  }

  const deleteRow = useUnlinkBusinessPartnerFromEntity({
    onSuccess: () => {
      invalidateFunctionMode()
      showToast({
        children: tTable('deleted'),
      })
    },
    onError: (error) => {
      setIsErrorMessageOpen(true)
      asyncSetSaveErrorDetails(error)
    },
  })

  const handleDeleteRow = (removedRowKey) => {
    const partnerToDelete = partnersOnEntity.find((partner) => partner.rowKey === removedRowKey)
    deleteRow.mutate({
      entityUuid,
      partnerId: partnerToDelete.party_id,
      functionCode: partnerToDelete.business_partner_function_code,
    })
  }

  const updateRow = useUpdateBusinessPartner({
    onSuccess: () => {
      invalidateFunctionMode()
      showToast({
        children: tTable('updated'),
      })
    },
    onError: (error) => {
      setIsErrorMessageOpen(true)
      asyncSetSaveErrorDetails(error)
    },
  })

  const handleUpdateRow = (updatedRowKey, partnerWithFunction) => {
    const partnerToDelete = partnersOnEntity.find((partner) => partner.rowKey === updatedRowKey)

    updateRow.mutate({
      entityUuid,
      partnerToPost: { ...partnerWithFunction },
      partnerIdToDelete: partnerToDelete.party_id,
      functionCodeToDelete: partnerToDelete.business_partner_function_code,
    })
  }

  return (
    <>
      <BusinessPartnersForEntityTable
        cardTitle={tCard('title')}
        businessPartnersOnEntity={partnersOnEntity}
        isLoading={isLoading}
        isError={isError}
        renderMessageStrip={renderMessageStrip}
        handleDeleteRow={handleDeleteRow}
        handleSaveRow={handleSaveRow}
        handleUpdateRow={handleUpdateRow}
        tPartnerCardTable={tTable}
        partnerFunctionMode={partnerFunctionMode}
        userIsAllowedToEdit={isEditable}
        hooks={{
          useBusinessPartnerFunctions,
          useBusinessPartnersByIds: useBusinesspartnersById,
          getBusinessPartnerSuggestions,
        }}
        getPartnerIds={getPartnerIds}
      />
      {createPortal(
        <>
          {isErrorMessageOpen && (
            <ErrorMessageBoxWithExpandableDetails
              messageSummary={t('components.cards.save-error')}
              messageDetails={saveErrorDetails}
              isOpen={isErrorMessageOpen}
              onClose={() => {
                setIsErrorMessageOpen(false)
              }}
            />
          )}
        </>,
        document.body,
      )}
    </>
  )
}

BusinessPartnersForEntityCard.propTypes = propTypes

export default BusinessPartnersForEntityCard
