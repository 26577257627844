import { TextAlign } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AnnualizedContractedRentCell from 'components/domains/business-partners/tile/tenancy/as-branding-or-franchise-provider/AnnualizedContractedRentCell'
import styles from 'components/domains/business-partners/tile/tenancy/as-branding-or-franchise-provider/AsBrandingOrFranchiseProviderCard.module.css'
import DealCell from 'components/domains/business-partners/tile/tenancy/as-branding-or-franchise-provider/DealCell'
import { DescriptionCell } from 'components/domains/business-partners/tile/tenancy/as-branding-or-franchise-provider/DescriptionCell'
import RentedAreaCell from 'components/domains/business-partners/tile/tenancy/as-branding-or-franchise-provider/RentedAreaCell'
import {
  compareNodesByNumberAttribute,
  compareNodesByStringAttribute,
} from 'components/domains/business-partners/tile/tenancy/compareNodesByAttribute'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'

/**
 * can be used to filter column definitions if not all columns should be displayed
 */
export const asBrandingOrFranchiseProviderTableColumnIds = {
  description: 'description',
  city: 'city',
  deal: 'deal',
  rentedArea: 'rentedArea',
  annualizedContractedRent: 'annualizedContractedRent',
}

export const useAsBrandingOrFranchiseProviderCardTableColumns = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.tenancy.as-branding-franchise-provider',
  })

  const columns = useMemo(
    () => [
      {
        Header: t('columns.description'),
        accessor: (row) => row.description || '',
        id: asBrandingOrFranchiseProviderTableColumnIds.description,
        Cell: DescriptionCell,
        className: styles.descriptionCell,
        sortType: (row1, row2) =>
          compareNodesByStringAttribute(row1, row2, (row) => row?.original?.description),
        filterType: filterTypes.CONTAINS,
      },
      {
        Header: t('columns.city'),
        accessor: (row) => row.city ?? '',
        id: asBrandingOrFranchiseProviderTableColumnIds.city,
        sortType: (row1, row2) =>
          compareNodesByStringAttribute(row1, row2, (row) => row?.original?.city || ''),
        filterType: filterTypes.CONTAINS,
      },
      {
        Header: t('columns.deal'),
        id: asBrandingOrFranchiseProviderTableColumnIds.deal,
        accessor: (row) => row.propertyUuid ?? '',
        Cell: (row) => (row.value ? <DealCell propertyUuid={row.value} /> : null),
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: t('columns.rented-area'),
        hAlign: TextAlign.End,
        id: asBrandingOrFranchiseProviderTableColumnIds.rentedArea,
        accessor: (row) => row.rentedArea.value ?? '',
        Cell: (state) => (
          <RentedAreaCell
            measurementUnit={state.row.original.rentedArea.measurementUnit}
            rentedArea={state.row.original.rentedArea.value}
            share={state.row.original.areaShare}
            unit={state.row.original.areaPieces}
          />
        ),
        filterType: filterTypes.BETWEEN_NUMBERS,
        sortType: (row1, row2) => {
          const getValue = (row) => row?.original?.rentedArea?.value || 0
          return compareNodesByNumberAttribute(row1, row2, getValue)
        },
      },
      {
        Header: () => (
          <div>
            {t('columns.annualized-contracted-rent')
              .split('\n')
              .map((part) => (
                <div key={part}>{part}</div>
              ))}
          </div>
        ),
        filterLabel: t('columns.annualized-contracted-rent'),
        sortByLabel: t('columns.annualized-contracted-rent'),
        hAlign: TextAlign.End,
        id: asBrandingOrFranchiseProviderTableColumnIds.annualizedContractedRent,
        accessor: (row) => row.annualizedContractedRent.value ?? '',
        Cell: (state) => (
          <AnnualizedContractedRentCell
            currency={state.row.original.annualizedContractedRent.currency}
            value={state.row.original.annualizedContractedRent.value}
            share={state.row.original.annualizedContractedRentShare}
          />
        ),
        sortType: (row1, row2) => {
          const getValue = (row) => row?.original?.annualizedContractedRent?.value || 0
          return compareNodesByNumberAttribute(row1, row2, getValue)
        },
        filterType: filterTypes.BETWEEN_NUMBERS,
      },
    ],
    [t],
  )
  return { columns }
}
