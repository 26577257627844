import { Button, ButtonDesign, FlexBox } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import compact from 'lodash.compact'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'
import Card from 'components/ui/card/Card'
import CardHeaderWithButtons from 'components/ui/card/CardHeaderWithButtons'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import withStandardComponentIndicator from 'components/ui/standard-component-indicator/withStandardComponentIndicator'
import DisplayAndEditTable from 'components/ui/tables/display-and-edit-table/DisplayAndEditTable'
import { defaultPopoverStaticConfig } from 'components/ui/tables/display-and-edit-table/DisplayAndEditTablePopover'
import DisplayAndEditTableToolbar from 'components/ui/tables/toolbar/DisplayAndEditTableToolbar'
import styles from 'components/ui/tables/toolbar/DisplayAndEditTableToolbar.module.css'

/** CardWithDisplayAndEditTable
 * A card with an Add-button in the header, showing a table. The user can, if allowed, add new rows to the table and save them,
 * as well as edit and delete existing rows. Buttons to edit, delete, save and add rows are supplied by the component.
 * Parameters:
 *  - cardTitle: Text shown in the header of the card
 *  - subTitle: Subtitle shown in the header of the card
 *  - columnDefinitions: array of objects in the form { columnKey, title, alignment }
 *  - tableData: Supplies information to the component on what to render, when the row is in edit or read mode. array of objects in the form
 *      { rowKey: <unique_key>,
 *        <columnKey_1>:
 *          {
 *            cellContentEditMode: <e.g. text>,
 *            cellContentReadMode: <e.g. Input field>
 *            cellReadModeProps: Optional object with props to pass to the table-cell in read mode
 *             cellEditModeProps: Optional object with props to pass to the table-cell in edit mode
 *          }
 *        <columnKey_2>: {...},
 *        ...
 *      }
 *  - newRow: Object specifying on what to show when pressing the addRow button. newRow does not necessarily show the same components as an existing row in edit mode.
 *      Difference to tableData: no cellContentReadMode field required. For example some fields may not be editable once they are set, while the user wants to be able
 *      to manipulate them when initially creating the row. Object is of the following form:
 *        { rowKey: "rowKeyNewRow" (ALWAYS has to be this exact string)
 *          <columnKey_1>:
 *            { cellContentEditMode: <e.g. Inputfield> }
 *          ...
 *        }
 *  - isError, isLoading, isFetching: Information from the used hook passed on to the loadingWrapper
 *  - userIsAllowedToEdit: defaults to false, defines whether the user sees buttons to manipulate data in the table
 *  - handleDeleteRow, handleSaveRow: functions to be called when deleting or saving. The functions are always called with the argument <rowKey>
 *  - popoverStaticConfig: when pressing the delete or cancelEdit button, the user is asked to confirm the delete/dismiss the changes.
 *      The config tells the popover, what message and button-text to show. Structure is shown in defaultPopoverStaticConfig (see below)
 *  - checkIsValidReturnErrorMessage: function (default: no error), called with arg <rowKey>, when clicking save on a row in edit mode.
 *      if the function returns { isError: true, errorMessage: <someErrorMessage> }, the save will not be carried out. instead a popover displaying
 *      the provided errormessage is shown.
 *  - additionalHeader: additional header which is optional, used for displaying additional information sub-section above card header section
 */

const CardWithDisplayAndEditTable = ({
  cardTitle,
  subTitle,
  nrOfEntries,
  toolbarTitle,
  tableData,
  newRow,
  isLoading,
  cardType,
  isError,
  columnDefinitions,
  userIsAllowedToEdit = false,
  handleDeleteRow,
  handleSaveRow,
  handleCancelEditRow,
  popoverStaticConfig = defaultPopoverStaticConfig,
  checkIsValidReturnErrorMessage = () => ({ isError: false }),
  customActionElements = [],
  customCardButtonTitle,
  customAddFunction,
  customIsAddRow,
  customSetIsAddRow = () => {},
  additionalHeader,
  className,
  headerClassName,
  toolbarClassName,
  titleClassName,
  additionalTableStyles,
  actionCellStyles,
  isAllowedToCreate,
  isWritingToBackend,
  titleActions,
  cardHeaderMessageStrip,
  collapseActionsWhenSize,
}) => {
  const [isAddRow, setIsAddRow] = useState(false)

  const originalAndCustomSetIsAddRow = useCallback(
    (_isAddRow) => {
      setIsAddRow(_isAddRow)
      customSetIsAddRow(_isAddRow)
    },
    [customSetIsAddRow],
  )

  const allTableData = useMemo(
    () => (isAddRow || !!customIsAddRow ? [newRow, ...tableData] : [...tableData]),
    [isAddRow, customIsAddRow, newRow, tableData],
  )

  const isCreateAllowed = useMemo(() => {
    if (isAllowedToCreate === undefined) return userIsAllowedToEdit
    return isAllowedToCreate
  }, [isAllowedToCreate, userIsAllowedToEdit])

  // NOTE: In compliance with the design requirements, the additional card header
  // (e.g., with subtitle for last edited info or title actions) is only rendered if needed!
  // Otherwise, the new table toolbar component is used as a default to match the table designs.
  const hasAdditionalCardHeader = useMemo(
    () => !isEmpty(subTitle) || additionalHeader || titleActions,
    [additionalHeader, subTitle, titleActions],
  )

  const addButton = useMemo(
    () => (
      <Button
        key={'addPartnerButton'}
        design={ButtonDesign.Transparent}
        disabled={isAddRow || !!customIsAddRow}
        onClick={() => {
          customAddFunction === undefined ? originalAndCustomSetIsAddRow(true) : customAddFunction()
        }}
      >
        {customCardButtonTitle || t('buttons.add')}
      </Button>
    ),
    [
      isAddRow,
      customIsAddRow,
      customCardButtonTitle,
      customAddFunction,
      originalAndCustomSetIsAddRow,
    ],
  )

  const cardHeader = useMemo(
    () => (
      <>
        {additionalHeader ? (
          additionalHeader
        ) : (
          <CardHeaderWithButtons
            title={cardTitle}
            subTitle={subTitle}
            titleActions={titleActions}
            messageStrip={cardHeaderMessageStrip}
            className={headerClassName}
            titleClassName={titleClassName}
          />
        )}
      </>
    ),
    [
      additionalHeader,
      cardHeaderMessageStrip,
      cardTitle,
      headerClassName,
      subTitle,
      titleActions,
      titleClassName,
    ],
  )

  const renderContent = useMemo(
    () => (
      <>
        <DisplayAndEditTableToolbar
          title={toolbarTitle ?? cardTitle}
          nrOfEntries={nrOfEntries}
          messageStrip={!hasAdditionalCardHeader && cardHeaderMessageStrip}
          className={[
            hasAdditionalCardHeader ? styles.additionalCardHeaderToolbar : '',
            toolbarClassName ?? '',
          ].join(' ')}
          toolbarActions={compact([isCreateAllowed && addButton, ...customActionElements])}
        />
        <FlexBox fitContainer>
          <DisplayAndEditTable
            columnDefinitions={columnDefinitions}
            tableData={allTableData}
            userIsAllowedToEdit={userIsAllowedToEdit}
            cancelAddRow={() => originalAndCustomSetIsAddRow(false) && customAddFunction(false)}
            handleDeleteRow={handleDeleteRow}
            cardType={cardType}
            handleSaveRow={handleSaveRow}
            handleCancelEditRow={handleCancelEditRow}
            popoverStaticConfig={popoverStaticConfig}
            checkIsValidReturnErrorMessage={checkIsValidReturnErrorMessage}
            additionalTableStyles={additionalTableStyles}
            actionCellStyles={actionCellStyles}
            isWritingToBackend={isWritingToBackend}
            collapseActionsWhenSize={collapseActionsWhenSize}
          />
        </FlexBox>
      </>
    ),
    [
      actionCellStyles,
      addButton,
      additionalTableStyles,
      allTableData,
      cardHeaderMessageStrip,
      cardTitle,
      cardType,
      checkIsValidReturnErrorMessage,
      collapseActionsWhenSize,
      columnDefinitions,
      customActionElements,
      customAddFunction,
      handleCancelEditRow,
      handleDeleteRow,
      handleSaveRow,
      hasAdditionalCardHeader,
      isCreateAllowed,
      isWritingToBackend,
      nrOfEntries,
      originalAndCustomSetIsAddRow,
      popoverStaticConfig,
      toolbarClassName,
      toolbarTitle,
      userIsAllowedToEdit,
    ],
  )

  return (
    <Card header={hasAdditionalCardHeader && cardHeader} className={className}>
      <RequestStateResolver
        isError={isError}
        isLoading={isLoading}
        center={true}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        renderContent={() => renderContent}
      />
    </Card>
  )
}
CardWithDisplayAndEditTable.displayName = 'CardWithDisplayAndEditTable'
CardWithDisplayAndEditTable.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  nrOfEntries: PropTypes.number,
  toolbarTitle: PropTypes.string,
  userIsAllowedToEdit: PropTypes.bool,
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  newRow: PropTypes.object.isRequired,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  columnDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      columnKey: PropTypes.string.isRequired,
      title: PropTypes.string,
    }),
  ).isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
  handleSaveRow: PropTypes.func.isRequired,
  handleCancelEditRow: PropTypes.func.isRequired,
  popoverStaticConfig: PropTypes.object,
  checkIsValidReturnErrorMessage: PropTypes.func,
  cardType: PropTypes.string,
  customActionElements: PropTypes.arrayOf(PropTypes.element),
  customCardButtonTitle: PropTypes.string,
  customAddFunction: PropTypes.func,
  customIsAddRow: PropTypes.bool,
  customSetIsAddRow: PropTypes.func,
  additionalHeader: PropTypes.element,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  toolbarClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  additionalTableStyles: PropTypes.string,
  isAllowedToCreate: PropTypes.bool,
  isWritingToBackend: PropTypes.bool,
  actionCellStyles: PropTypes.shape({
    editModeCell: PropTypes.string,
    readModeCell: PropTypes.string,
    editModeButtons: PropTypes.string,
    readModeButtons: PropTypes.string,
  }),
  titleActions: PropTypes.arrayOf(PropTypes.element),
  cardHeaderMessageStrip: PropTypes.node,
  collapseActionsWhenSize: PropTypes.number,
}
export default withStandardComponentIndicator(CardWithDisplayAndEditTable)
