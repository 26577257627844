import {
  AnalyticalTable,
  AnalyticalTableScaleWidthMode,
  AnalyticalTableSelectionMode,
  FlexBox,
  FlexBoxDirection,
  MessageStrip,
  MessageStripDesign,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { conditionTypes, conditionsEntityTypes } from 'api/conditions/conditions'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/DecisionPaperConditionsTable.module.css'
import DecisionPaperConditionsTableEmpty from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/DecisionPaperConditionsTableEmpty'
import ConditionsTableDealExternalAssigneeSubcomponent from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/conditions/ConditionsTableDealExternalAssigneeSubcomponent'
import ConditionsTableDescriptionSubcomponent from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/conditions/ConditionsTableDescriptionSubcomponent'
import tableSubComponentStyles from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/view/TableSubcomponent.module.css'

import useTableHeightHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/conditions/useTableHeightHelper'

import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const rowHeight = 80
const defaultSubcomponentHeight = 172
const maximumHeight = 1200

const defaultShownSubcomponents = {
  description: true,
  externalAssignee: true,
}

const DecisionPaperConditionsTable = ({
  isLoading,
  conditions,
  columns,
  fetchNextPage,
  conditionType,
  shownSubcomponents = defaultShownSubcomponents,
  selectionMode,
  emptyText,
  onRowClick,
  alwaysShowDescription = false,
  subcomponentHeight = defaultSubcomponentHeight,
  isPdfView = false,
  ...additionalTableOptions
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'components.conditions.table',
  })
  const tableRef = useRef()
  const { event } = useContext(BusinessEventsAndTasksContext)

  const showErrorStrip = useSelector((state) => state.conditions.conditionsTable.rowsHaveAnError)
  const conditionErrorRows = useSelector((state) => state.conditions.conditionsTable.errorRows)
  const conditionHighlightRows = useSelector(
    (state) => state.conditions.conditionsTable.highlightRows,
  )

  const sortedConditions = useMemo(() => {
    const newConditions = []
    const oldConditions = []
    conditions?.forEach((condition) => {
      if (conditionHighlightRows[condition.id]) {
        newConditions.push(condition)
      } else {
        oldConditions.push(condition)
      }
    })
    return [...newConditions, ...oldConditions]
  }, [conditionHighlightRows, conditions])

  const renderSubRow = ({
    original: {
      id: conditionId,
      info: { description, externalAssignee },
    },
  }) => (
    <FlexBox
      direction={FlexBoxDirection.Column}
      className={tableSubComponentStyles.tableSubcomponentFlexBox}
    >
      {!!shownSubcomponents.externalAssignee &&
        event?.entityRef?.entityType === conditionsEntityTypes.deal && (
          <ConditionsTableDealExternalAssigneeSubcomponent
            conditionId={conditionId}
            externalAssignee={externalAssignee}
          />
        )}
      {!!shownSubcomponents.description && (
        <ConditionsTableDescriptionSubcomponent
          description={description}
          conditionId={conditionId}
        />
      )}
    </FlexBox>
  )

  const highlightField = useCallback(
    ({ id: conditionId }) => {
      if (conditionHighlightRows[conditionId]) {
        return ValueState.Information
      }
      if (conditionErrorRows[conditionId]) {
        return ValueState.Error
      }
      return ValueState.None
    },
    [conditionErrorRows, conditionHighlightRows],
  )

  const noDataComponent = useCallback(
    () => DecisionPaperConditionsTableEmpty({ overrideText: emptyText }),
    [emptyText],
  )

  const { onExpandChange, tableHeight } = useTableHeightHelper({
    rowHeight,
    subcomponentHeight,
    maximumHeight,
    dataLength: sortedConditions?.length,
  })

  const onLoadMore = useCallback(() => fetchNextPage(), [fetchNextPage])

  const useCustomTableHooks = useCallback(
    (hooks) => {
      hooks.useFinalInstance.push(onExpandChange)
    },
    [onExpandChange],
  )

  return (
    <div className={styles.tableWrapper}>
      {showErrorStrip && (
        <MessageStrip
          hideCloseButton
          design={MessageStripDesign.Negative}
          className={styles.messageStrip}
        >
          {t('error.rows-have-error')}
        </MessageStrip>
      )}
      <AnalyticalTable
        className={styles.table}
        tableInstance={tableRef}
        columns={columns}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Smart}
        minRows={1}
        rowHeight={80}
        tableHeight={tableHeight}
        selectionMode={selectionMode}
        onRowClick={onRowClick}
        headerRowHeight={40}
        groupable={false}
        filterable={false}
        sortable={false}
        data={sortedConditions}
        loading={isLoading}
        showOverlay={isLoading}
        isTreeTable
        infiniteScroll
        infiniteScrollThreshold={10}
        onLoadMore={onLoadMore}
        renderRowSubComponent={renderSubRow}
        NoDataComponent={noDataComponent}
        reactTableOptions={{
          autoResetHiddenColumns: false,
          autoResetSelectedRows: false,
          conditionType,
          isPdfView,
          ...additionalTableOptions,
        }}
        withRowHighlight
        highlightField={highlightField}
        alwaysShowSubComponent={alwaysShowDescription}
        tableHooks={[useCustomTableHooks]}
      />
    </div>
  )
}

DecisionPaperConditionsTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.shape({
        type: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      isVisible: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  isFilterOrSearchApplied: PropTypes.bool.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  conditionType: PropTypes.oneOf(Object.values(conditionTypes)).isRequired,
  emptyText: PropTypes.string,
  selectionMode: PropTypes.oneOf(Object.values(AnalyticalTableSelectionMode)),
  onRowClick: PropTypes.func,
  alwaysShowDescription: PropTypes.bool,
  onConditionSelectionChanged: PropTypes.func,
  shownSubcomponents: PropTypes.shape({
    description: PropTypes.bool,
    externalAssignee: PropTypes.bool,
  }),
  subcomponentHeight: PropTypes.number,
  isPdfView: PropTypes.bool,
}

export default DecisionPaperConditionsTable
