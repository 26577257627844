import { Grid } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/links/DecisionPaperLeaveConfirmationLink'
import GeneralInformationComplianceWithRiskStrategyField from 'components/domains/business-events-and-tasks/decision-paper/tiles/general-information/fields/GeneralInformationComplianceWithRiskStrategyField'
import GeneralInformationSponsorsField from 'components/domains/business-events-and-tasks/decision-paper/tiles/general-information/fields/GeneralInformationSponsorsField'
import GeneralInformationUnderwriterField from 'components/domains/business-events-and-tasks/decision-paper/tiles/general-information/fields/GeneralInformationUnderwriterField'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/general-information/shared/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/general-information/shared/DisplayCardViewItemView'
import { useCustomizableCurrencyFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/general-information/shared/useI18n'
import ALCDataMissingMessageStrip from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ALCDataMissingMessageStrip'
import ComplianceFieldMissingMessageStrip from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ComplianceFieldMissingMessageStrip'
import paths from 'routes/paths'

const dash = '-'

const DecisionPaperGeneralInformationTile = ({ tileId, showConfirmationForLinks, isPdfView }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.general-information.tile',
  })
  const formatMoney = useCustomizableCurrencyFormatter()
  const { data } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const {
    authorityLevel,
    borrower,
    deal,
    dealRiskConformity,
    sponsors,
    staffMembers: { accountManager, loanAccountManager, underwriter } = {},
    accountManagerId,
  } = data ?? {}

  const borrowerValue = useMemo(() => {
    if (!borrower?.id) {
      return dash
    }
    const link = `/${paths.businessPartners}/${borrower?.id}`
    return (
      <DecisionPaperLeaveConfirmationLink
        link={link}
        name={borrower?.fullName}
        id={borrower?.id}
        showConfirmationForLinks={showConfirmationForLinks}
      />
    )
  }, [borrower?.fullName, borrower?.id, showConfirmationForLinks])

  const reviewUnitValue = useMemo(() => {
    if (!authorityLevel?.unit?.unitHeadBpId || !authorityLevel?.unit?.unitHeadBpFullName) {
      return dash
    }
    const link = `/${paths.businessPartners}/${authorityLevel?.unit?.unitHeadBpId}`
    return (
      <DecisionPaperLeaveConfirmationLink
        link={link}
        name={authorityLevel?.unit?.unitHeadBpFullName}
        showConfirmationForLinks={showConfirmationForLinks}
      />
    )
  }, [
    authorityLevel?.unit?.unitHeadBpFullName,
    authorityLevel?.unit?.unitHeadBpId,
    showConfirmationForLinks,
  ])

  const creditAuthorityLimitValue = useMemo(() => {
    const unitLevel = authorityLevel?.creditAuthorityLimit?.unitLevel
    if (!unitLevel || isNil(unitLevel.amount) || isNil(unitLevel.currency)) {
      return dash
    }
    return formatMoney(unitLevel.amount, unitLevel.currency)
  }, [authorityLevel?.creditAuthorityLimit?.unitLevel, formatMoney])

  const borrowerCreditAuthorityLimitValue = useMemo(() => {
    const unitMemberLevel = authorityLevel?.creditAuthorityLimit?.unitMemberLevel
    if (!unitMemberLevel || isNil(unitMemberLevel.amount) || isNil(unitMemberLevel.currency)) {
      return dash
    }
    return formatMoney(unitMemberLevel.amount, unitMemberLevel.currency)
  }, [authorityLevel?.creditAuthorityLimit?.unitMemberLevel, formatMoney])

  const getAccountManagerFieldValue = (name, userId) => {
    if (!name) {
      return userId ?? dash
    }
    return name
  }

  return (
    <>
      <ComplianceFieldMissingMessageStrip compliance={dealRiskConformity?.compliance} />
      {!authorityLevel && <ALCDataMissingMessageStrip />}
      <Grid defaultSpan="XL6 L6 M6 S12">
        <div>
          <DisplayCardViewItemView>
            <DisplayCardViewInfoItemView
              label={t('fields.deal-name')}
              isFirstItem={true}
              value={deal?.name || dash}
            />
          </DisplayCardViewItemView>
          <DisplayCardViewItemView>
            <DisplayCardViewInfoItemView
              label={t('fields.deal-type')}
              value={deal?.dealTypeName || dash}
            />
          </DisplayCardViewItemView>
          <DisplayCardViewItemView>
            <DisplayCardViewInfoItemView
              label={t('fields.application-type')}
              value={deal?.applicationTypeName || dash}
            />
          </DisplayCardViewItemView>
          <DisplayCardViewItemView>
            <DisplayCardViewInfoItemView
              label={t('fields.account-manager')}
              value={getAccountManagerFieldValue(accountManager?.fullName, accountManagerId)}
            />
          </DisplayCardViewItemView>
          <GeneralInformationUnderwriterField
            underwriter={underwriter}
            loanAccountManager={loanAccountManager}
          />
          <DisplayCardViewItemView>
            <DisplayCardViewInfoItemView
              label={t('fields.origination-team')}
              value={deal?.originationTeamName || dash}
            />
          </DisplayCardViewItemView>
          <DisplayCardViewItemView>
            <DisplayCardViewInfoItemView
              label={t('fields.sponsors.label')}
              value={
                <GeneralInformationSponsorsField
                  showConfirmationForLinks={showConfirmationForLinks}
                  sponsors={sponsors}
                  isPdfView={isPdfView}
                />
              }
            />
          </DisplayCardViewItemView>
        </div>
        <div>
          <DisplayCardViewItemView>
            <DisplayCardViewInfoItemView
              isFirstItem={true}
              label={t('fields.borrower')}
              value={borrowerValue}
            />
          </DisplayCardViewItemView>
          <DisplayCardViewItemView>
            <DisplayCardViewInfoItemView label={t('fields.review-unit')} value={reviewUnitValue} />
          </DisplayCardViewItemView>
          <DisplayCardViewItemView>
            <DisplayCardViewInfoItemView
              label={t('fields.total-el-class')}
              value={authorityLevel?.expectedLossClass?.unitLevel || dash}
            />
          </DisplayCardViewItemView>
          <DisplayCardViewItemView>
            <DisplayCardViewInfoItemView
              label={t('fields.total-credit-authority-limit')}
              value={creditAuthorityLimitValue}
            />
          </DisplayCardViewItemView>
          <DisplayCardViewItemView>
            <DisplayCardViewInfoItemView
              label={t('fields.borrower-el-class')}
              value={authorityLevel?.expectedLossClass?.unitMemberLevel || dash}
            />
          </DisplayCardViewItemView>
          <DisplayCardViewItemView>
            <DisplayCardViewInfoItemView
              label={t('fields.borrower-credit-authority-limit')}
              value={borrowerCreditAuthorityLimitValue}
            />
          </DisplayCardViewItemView>
          <GeneralInformationComplianceWithRiskStrategyField
            dealRiskConformity={dealRiskConformity}
          />
        </div>
      </Grid>
    </>
  )
}

DecisionPaperGeneralInformationTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool,
}

export default DecisionPaperGeneralInformationTile
