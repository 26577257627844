import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/routes/deals/syndication/syndicationIntention/SyndicationIntentionCard.module.css'

const usePeriodFields = ({ name, isShownInDisplay, periodReference }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.syndications.intention-card',
  })

  const label = t('post-sale-period')

  const renderDisplayValue = (period, referenceDateShortText) => {
    if (!period || !referenceDateShortText) {
      return null
    }

    return `${period} ${t('reference-date.period-months-from')} ${referenceDateShortText}`
  }

  return {
    label,
    name,
    value: (
      <div className={styles.alignTextRight}>
        {renderDisplayValue(periodReference?.period, periodReference?.referenceDateShortText)}
      </div>
    ),

    isShownInDisplay: !!isShownInDisplay,
    isShownInEdit: false,
  }
}

usePeriodFields.defaultProps = {
  name: PropTypes.string.isRequired,
  isShownInDisplay: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  periodReference: PropTypes.object,
}

export default usePeriodFields
