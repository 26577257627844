import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/toolbar/CovenantsOfCheckFilterDialogFilterItem.module.css'

const FilterDialogFilterItem = ({ children, label }) => (
  <FlexBox direction={FlexBoxDirection.Column} className={styles.filterItem}>
    <Label showColon>{label}</Label>
    {children}
  </FlexBox>
)

FilterDialogFilterItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
}

export default FilterDialogFilterItem
