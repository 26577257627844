import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Dialog, { DialogPrimaryButton, DialogSize } from 'components/ui/dialog/Dialog'

const PropertyImageEditDialog = ({ showDeleteDialog, setShowDeleteDialog, onDelete }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.general-information.image.edit',
  })

  return (
    <Dialog
      open={showDeleteDialog}
      onAfterClose={() => setShowDeleteDialog(false)}
      size={DialogSize.S}
      primaryButton={
        <DialogPrimaryButton
          onClick={() => {
            onDelete()
            setShowDeleteDialog(false)
          }}
        >
          {t('ok')}
        </DialogPrimaryButton>
      }
      headerText={t('dialog-header')}
    >
      {t('dialog-content')}
    </Dialog>
  )
}
PropertyImageEditDialog.propTypes = {
  showDeleteDialog: PropTypes.bool.isRequired,
  setShowDeleteDialog: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}
export default PropertyImageEditDialog
