import isNil from 'lodash.isnil'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useDealMini from 'hooks/services/deals/useDealMini'

const useDrawdownBorrowerInformation = ({ entityRef: { entityId: dealUuid } }, tileId) => {
  const {
    isLoading: isDealLoading,
    isError: isDealError,
    data: dealData,
    error: dealError,
  } = useDealMini(dealUuid)

  const borrowerBpIdIsPresent = !isNil(dealData?.borrowerBpId)
  // business partner mini by ids is disabled when array is empty
  const {
    isLoading: isBorrowerLoading,
    isError: isBorrowerError,
    data: borrowerData,
    error: borrowerError,
  } = useBusinessPartnerMiniByIds(borrowerBpIdIsPresent ? [dealData?.borrowerBpId] : [])

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isDealLoading, borrowerBpIdIsPresent && isBorrowerLoading],
    errorValues: [isDealError, isBorrowerError],
    errorDetails: [dealError, borrowerError],
    tileId,
  })

  if (isSomeValueLoading || isSomeValueError) {
    return {
      isError: isSomeValueError,
      isLoading: isSomeValueLoading,
      error,
    }
  }

  return {
    isError: false,
    isLoading: false,
    data: {
      borrowerName: borrowerData?.businessPartnerMinis?.[0]?.fullName,
      borrowerBpId: dealData?.borrowerBpId,
      sourceRender: { dealDisplayId: dealData?.dealId },
    },
  }
}

export default useDrawdownBorrowerInformation
