import { useMemo } from 'react'

const emptyAggregatedCollateralAgreements = {
  cagAggregatedTotalValueInHeadQuarterCurrency: {
    value: 0,
    currency: '',
  },
  cagAggregatedTotalNominalValueInHeadQuarterCurrency: {
    value: 0,
    currency: '',
  },
  cagClusters: {},
}

const useAggregateOpsMemoCollateralAgreements = (collateralAgreementsData) => {
  const calculateTotalValues = (aggregatedCollateralAgreements, currentCollateralAgreement) => ({
    ...aggregatedCollateralAgreements,
    cagAggregatedTotalValueInHeadQuarterCurrency: {
      currency: currentCollateralAgreement.cagValueHq?.currency,
      value:
        aggregatedCollateralAgreements.cagAggregatedTotalValueInHeadQuarterCurrency.value +
        (currentCollateralAgreement.cagValueHq?.value ?? 0),
    },
    cagAggregatedTotalNominalValueInHeadQuarterCurrency: {
      currency: currentCollateralAgreement.nominalValueHq?.currency,
      value:
        aggregatedCollateralAgreements.cagAggregatedTotalNominalValueInHeadQuarterCurrency.value +
        (currentCollateralAgreement.nominalValueHq?.value ?? 0),
    },
  })

  /**
   * @param {Cluster} collateralAgreementsCagCluster
   * @param {CAG} currentCollateralAgreement
   */
  const getCagSpecificValues = (collateralAgreementsCagCluster, currentCollateralAgreement) => ({
    cagCluster: {
      ...currentCollateralAgreement.cagCluster,
    },
    cagAggregatedValueInHeadQuarterCurrency: {
      currency: currentCollateralAgreement.cagValueHq?.currency,
      value:
        (collateralAgreementsCagCluster?.cagAggregatedValueInHeadQuarterCurrency.value ?? 0) +
        (currentCollateralAgreement.cagValueHq?.value ?? 0),
    },
    cagAggregatedNominalValueInHeadQuarterCurrency: {
      currency: currentCollateralAgreement.nominalValueHq?.currency,
      value:
        (collateralAgreementsCagCluster?.cagAggregatedNominalValueInHeadQuarterCurrency.value ??
          0) + (currentCollateralAgreement.nominalValueHq?.value ?? 0),
    },
  })

  /**
   * @param {CagType} collateralAgreementsCagType
   * @param {CAG} currentCollateralAgreement
   */
  const getCagTypeSpecificValues = (collateralAgreementsCagType, currentCollateralAgreement) => ({
    cagType: {
      ...currentCollateralAgreement.cagType,
    },
    cagAggregatedValueInHeadQuarterCurrency: {
      currency: currentCollateralAgreement.cagValueHq?.currency,
      value:
        (collateralAgreementsCagType?.cagAggregatedValueInHeadQuarterCurrency.value ?? 0) +
        (currentCollateralAgreement.cagValueHq?.value ?? 0),
    },
    cagAggregatedNominalValueInHeadQuarterCurrency: {
      currency: currentCollateralAgreement.nominalValueHq?.currency,
      value:
        (collateralAgreementsCagType?.cagAggregatedNominalValueInHeadQuarterCurrency.value ?? 0) +
        (currentCollateralAgreement.nominalValueHq?.value ?? 0),
    },
  })

  /**
   * @param {CAG} currentCollateralAgreement
   */
  const getAgreementSpecificValues = (currentCollateralAgreement) => ({
    id: currentCollateralAgreement.id,
    displayId: currentCollateralAgreement.displayId,
    cagCluster: currentCollateralAgreement.cagCluster,
    cagType: currentCollateralAgreement.cagType,
    name: currentCollateralAgreement.name,
    systemStatus: currentCollateralAgreement.systemStatus,
    cagValue: currentCollateralAgreement.cagValue,
    cagValueInHeadQuarterCurrency: currentCollateralAgreement.cagValueHq,
    cagNominalValue: currentCollateralAgreement.nominalValue,
    cagNominalValueInHeadQuarterCurrency: currentCollateralAgreement.nominalValueHq,
    externalLandCharge: currentCollateralAgreement.extLandCharge,
    newBusinessReceivables: currentCollateralAgreement.newBusinessReceivables,
    existingBusinessReceivables: currentCollateralAgreement.existingBusinessReceivables,
    assetsCount: currentCollateralAgreement.assetsCount,
  })

  return useMemo(() => {
    if (!collateralAgreementsData) {
      return
    }

    return collateralAgreementsData?.collateralAgreements?.reduce(
      (aggregatedCollateralAgreements, currentCollateralAgreement) => {
        const currentCagCluster = currentCollateralAgreement.cagCluster.id
        const currentCagType = currentCollateralAgreement.cagType.id

        return {
          ...calculateTotalValues(aggregatedCollateralAgreements, currentCollateralAgreement),
          cagClusters: {
            ...aggregatedCollateralAgreements.cagClusters,
            [currentCagCluster]: {
              ...getCagSpecificValues(
                aggregatedCollateralAgreements.cagClusters[currentCagCluster],
                currentCollateralAgreement,
              ),
              cagTypes: {
                ...(aggregatedCollateralAgreements.cagClusters[currentCagCluster]?.cagTypes ?? {}),
                [currentCagType]: {
                  ...getCagTypeSpecificValues(
                    aggregatedCollateralAgreements.cagClusters[currentCagCluster]?.cagTypes[
                      currentCagType
                    ],
                    currentCollateralAgreement,
                  ),
                  agreements: [
                    ...(aggregatedCollateralAgreements.cagClusters[currentCagCluster]?.cagTypes[
                      currentCagType
                    ]?.agreements ?? []),
                    getAgreementSpecificValues(currentCollateralAgreement),
                  ],
                },
              },
            },
          },
        }
      },
      emptyAggregatedCollateralAgreements,
    )
  }, [collateralAgreementsData])
}

export default useAggregateOpsMemoCollateralAgreements
