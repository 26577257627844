import { saveAs } from 'file-saver'
import { useCallback, useMemo } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

const useExportValuationInformation = () => {
  const { get } = useAccessTokenRequest()
  const downloadFunction = useCallback(
    ({ propertyUuid }, { onError, onSuccess }) => {
      get({
        path: `/properties/${propertyUuid}/valuations/export`,
        headers: { Accept: mimeType },
      })
        .then(({ data, fileName }) => {
          saveAs(data, fileName)
          onSuccess(data, fileName)
        })
        .catch((error) => {
          onError(error)
        })
    },
    [get],
  )
  return useMemo(
    () => ({
      download: downloadFunction,
    }),
    [downloadFunction],
  )
}

export default useExportValuationInformation
