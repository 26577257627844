import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import IntegratedVersionedDisplayAndEditCard from 'components/ui/card/integrated-versioned-display-and-edit-card/IntegratedVersionedDisplayAndEditCard'
import useCovenantComments from 'hooks/services/deals/covenants/comment/useCovenantComments'
import useCovenantCommentsUpdate from 'hooks/services/deals/covenants/comment/useCovenantCommentsUpdate'

const CovenantCommentCard = ({ linkedEntityType, linkedEntityId, isEditEnabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.comment-card',
  })

  return (
    <IntegratedVersionedDisplayAndEditCard
      getContentVersionsHook={useCovenantComments}
      getContentVersionsHookParameters={{ linkedEntityType, linkedEntityId }}
      putContentUpdateHook={useCovenantCommentsUpdate}
      putContentUpdateHookParameters={{ linkedEntityType, linkedEntityId }}
      cardTitle={t('title')}
      hasEditPermission={isEditEnabled}
    />
  )
}

CovenantCommentCard.propTypes = {
  linkedEntityType: PropTypes.string.isRequired,
  linkedEntityId: PropTypes.string.isRequired,
  isEditEnabled: PropTypes.bool,
}

export default CovenantCommentCard
