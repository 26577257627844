import { Link } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionStageLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-information/shared/DecisionStageLeaveConfirmationLink'
import paths from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-information/shared/paths'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-information/shared/ui/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-information/shared/ui/DisplayCardViewItemView'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-information/shared/ui/EmptyCardContent'
import LoadingStateWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-information/shared/ui/LoadingStateWrapper'

const PropertyInformationSectionLine = ({ label, value, ...props }) => (
  <DisplayCardViewItemView>
    <DisplayCardViewInfoItemView label={label} value={value ? value : ''} {...props} />
  </DisplayCardViewItemView>
)

PropertyInformationSectionLine.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
}

const PropertyInformationSection = ({
  tileId,
  showConfirmationForLinks,
  selectedProperty,
  isPdfView,
}) => {
  const { t: tDecisionPaperTiles } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles',
  })
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.financed-asset-information',
  })

  const { data: decisionPaper } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId],
  )
  const { isLoading, isError, data: reduxPropertyData } = decisionPaper.propertyInformation ?? {}
  const propertyData =
    selectedProperty && Array.isArray(reduxPropertyData)
      ? reduxPropertyData.find((property) => property.uuid === selectedProperty)
      : reduxPropertyData

  const renderContent = () => {
    if (!propertyData) {
      return <EmptyCardContent />
    }

    const propertyNameLink = showConfirmationForLinks ? (
      <DecisionStageLeaveConfirmationLink
        name={propertyData.name}
        link={`/${paths.properties}/${propertyData.id}`}
      />
    ) : (
      <Link href={`/${paths.properties}/${propertyData.id}`} target="_blank">
        {propertyData.name}
      </Link>
    )

    const marketNameLink = showConfirmationForLinks ? (
      <DecisionStageLeaveConfirmationLink
        name={propertyData?.market?.name ?? '-'}
        link={`/${paths.markets}/${propertyData?.market?.id}`}
      />
    ) : (
      <Link href={`/${paths.markets}/${propertyData?.market?.id}`} target="_blank">
        {propertyData?.market?.name ?? '-'}
      </Link>
    )

    return (
      <>
        <PropertyInformationSectionLine
          label={t('label.property-name')}
          value={!isPdfView ? propertyNameLink : propertyData.name}
          isFirstItem
        />
        <PropertyInformationSectionLine label={t('label.property-id')} value={propertyData.id} />
        <PropertyInformationSectionLine
          label={t('label.property-type')}
          value={propertyData.type}
        />
        <PropertyInformationSectionLine
          label={t('label.country')}
          value={propertyData.address.country}
        />
        <PropertyInformationSectionLine
          label={t('label.postal-code')}
          value={propertyData.address.postalCode}
        />
        <PropertyInformationSectionLine label={t('label.city')} value={propertyData.address.city} />
        <PropertyInformationSectionLine
          label={t('label.street')}
          value={propertyData.address.street}
        />
        <PropertyInformationSectionLine
          label={t('label.house-id')}
          value={propertyData.address.houseId}
        />
        <PropertyInformationSectionLine
          label={t('label.market')}
          value={!isPdfView ? marketNameLink : propertyData?.market?.name}
        />
      </>
    )
  }

  return (
    <LoadingStateWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderContent}
      loadingTitle={tDecisionPaperTiles('loading')}
      errorTitle={tDecisionPaperTiles('loading.error')}
      errorDescription={tDecisionPaperTiles('loading.error.description')}
    />
  )
}

PropertyInformationSection.propTypes = {
  tileId: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
  selectedProperty: PropTypes.string,
  isPdfView: PropTypes.bool.isRequired,
}

export default PropertyInformationSection
