import isNil from 'lodash.isnil'
import { useCallback } from 'react'

export const useCollateralAgreementsTableColumnSort = () => {
  const getRowSortResult = useCallback(
    ({
      row1,
      row2,
      descending,
      accessor,
      comparator,
      secondLevel: { comparator: secondLevelComparator, reducer, initialValue } = {},
    }) => {
      const row1TopLevelSortValue = row1.original.topLevelSortValue
      const row2TopLevelSortValue = row2.original.topLevelSortValue

      const isTopLevel = !isNil(row1TopLevelSortValue) && !isNil(row2TopLevelSortValue)
      if (isTopLevel) {
        if (row1TopLevelSortValue === row2TopLevelSortValue) return { topLevelResult: 0 }
        const result = row1TopLevelSortValue > row2TopLevelSortValue ? 1 : -1
        return { topLevelResult: descending ? result : result * -1 } // invert with descending order to keep the top level sort order fixed
      }

      const isSecondLevel =
        row1.originalSubRows?.length &&
        row2.originalSubRows?.length &&
        typeof secondLevelComparator === 'function' &&
        typeof reducer === 'function'
      if (isSecondLevel) {
        const row1ReducesSubRows = row1.originalSubRows.reduce(reducer, initialValue)
        const row2ReducesSubRows = row2.originalSubRows.reduce(reducer, initialValue)
        return { secondLevelResult: secondLevelComparator(row1ReducesSubRows, row2ReducesSubRows) }
      }

      // compare row values via accessor - could be any level beneath top level
      const row1Value = accessor(row1)
      const row2Value = accessor(row2)
      if (row1Value === row2Value) return { thirdLevelResult: 0 }
      return { thirdLevelResult: comparator(row1Value, row2Value) }
    },
    [],
  )

  const extractSortResult = useCallback(
    ({ topLevelResult, secondLevelResult, thirdLevelResult }) => {
      if (typeof topLevelResult !== 'undefined') return topLevelResult
      if (typeof secondLevelResult !== 'undefined') return secondLevelResult
      return thirdLevelResult
    },
    [],
  )

  const sortByNumber = useCallback(
    (row1, row2, columnId, descending) => {
      const sortResult = getRowSortResult({
        row1,
        row2,
        descending,
        accessor: (row) => row.original[columnId] ?? 0,
        comparator: (a, b) => a - b,
        secondLevel: {
          comparator: (a, b) => a - b,
          reducer: (aggregate, subRow) => aggregate + (subRow[columnId] ?? 0),
          initialValue: 0,
        },
      })
      return extractSortResult(sortResult)
    },
    [getRowSortResult, extractSortResult],
  )

  const sortByCagValueInHeadQuarterCurrency = useCallback(
    (row1, row2, columnId, descending) => {
      const sortResult = getRowSortResult({
        row1,
        row2,
        descending,
        accessor: (row) => row.original[columnId].cagValueInHeadQuarterCurrency.value,
        comparator: (a, b) => a - b,
      })
      return extractSortResult(sortResult)
    },
    [getRowSortResult, extractSortResult],
  )

  const sortByType = useCallback(
    (row1, row2, columnId, descending) => {
      const sortResult = getRowSortResult({
        row1,
        row2,
        descending,
        accessor: (row) => row.original[columnId].type,
        comparator: (a, b) => a.localeCompare(b),
        secondLevel: {
          comparator: (a, b) => a.localeCompare(b),
          reducer: (aggregate, subRow) =>
            `${aggregate} ${subRow[columnId].type}`
              .trim()
              .split(' ')
              .sort((a, b) => a.localeCompare(b))
              .join(' '),
          initialValue: '',
        },
      })
      return extractSortResult(sortResult)
    },
    [getRowSortResult, extractSortResult],
  )

  const sortByStringValue = useCallback(
    (row1, row2, columnId, descending) => {
      const sortResult = getRowSortResult({
        row1,
        row2,
        descending,
        accessor: (row) => row.original[columnId].value,
        comparator: (a, b) => a.localeCompare(b),
        secondLevel: {
          comparator: (a, b) => a.localeCompare(b),
          reducer: (aggregate, subRow) =>
            `${aggregate} ${subRow[columnId].value}`
              .trim()
              .split(' ')
              .sort((a, b) => a.localeCompare(b))
              .join(' '),
          initialValue: '',
        },
      })
      return extractSortResult(sortResult)
    },
    [getRowSortResult, extractSortResult],
  )

  const sortByExternalLandCharge = useCallback(
    (row1, row2, columnId, descending) => {
      const sortResult = getRowSortResult({
        row1,
        row2,
        descending,
        accessor: (row) => row.original[columnId],
        comparator: (a, b) => a - b,
        secondLevel: {
          comparator: (a, b) => a.localeCompare(b),
          reducer: (aggregate, subRow) =>
            `${aggregate} ${subRow[columnId]}`
              .trim()
              .split(' ')
              .sort((a, b) => a.localeCompare(b))
              .join(' '),
          initialValue: '',
        },
      })
      return extractSortResult(sortResult)
    },
    [getRowSortResult, extractSortResult],
  )

  return {
    sortByCagValueInHeadQuarterCurrency,
    sortByExternalLandCharge,
    sortByNumber,
    sortByStringValue,
    sortByType,
  }
}
