import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
const hyphen = '-'

const ConditionsTableExternalVisibilitiesCell = ({ value: { visibilities } }) => {
  const reducedVisibilities = useMemo(
    () =>
      visibilities.reduce((currentVisibilityString, { name: visibilityName }, currentIndex) => {
        if (currentIndex === 0) {
          return visibilityName
        }
        return `${currentVisibilityString} • ${visibilityName}`
      }, hyphen),
    [visibilities],
  )

  return <Text wrapping>{reducedVisibilities}</Text>
}

ConditionsTableExternalVisibilitiesCell.propTypes = {
  value: PropTypes.shape({
    conditionId: PropTypes.string.isRequired,
    visibilities: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
}

export default ConditionsTableExternalVisibilitiesCell
