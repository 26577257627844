import { FlexBox } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { conditionsEntityTypes, conditionTypes } from 'api/conditions/conditions'
import {
  neededOperationsForConditionDelete,
  neededOperationsForConditionsCreate,
  neededOperationsForConditionEdit,
} from 'api/conditions/conditionsAllowedOperations'
import { hasUserRequiredOperations } from 'api/helper'
import CreateExternalConditionButton from 'components/domains/conditions/overview/buttons/external-conditions/CreateExternalConditionButton'
import styles from 'components/domains/conditions/overview/buttons/external-conditions/ExternalConditionsToolbarButtons.module.css'
import TransferExternalConditionButton from 'components/domains/conditions/overview/buttons/external-conditions/TransferExternalConditionButton'
import ConditionsMassEditButton from 'components/domains/conditions/overview/buttons/generic/ConditionsMassEditButton'
import CopySelectedConditionsButton from 'components/domains/conditions/overview/buttons/generic/CopySelectedConditionsButton'
import DeleteSelectedConditionsButton from 'components/domains/conditions/overview/buttons/generic/DeleteSelectedConditionsButton'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const ExternalConditionsToolbarButtons = ({ disabled }) => {
  const {
    allowedOperations,
    entityRef: { entityType },
  } = useContext(ConditionsContext)

  const hasUserConditionCreatePermission = useMemo(
    () => hasUserRequiredOperations(neededOperationsForConditionsCreate, allowedOperations),
    [allowedOperations],
  )

  const externalConditionAddButton = useMemo(() => {
    if (!hasUserConditionCreatePermission) {
      return
    }
    return <CreateExternalConditionButton disabled={disabled} />
  }, [hasUserConditionCreatePermission, disabled])

  const hasUserConditionDeletePermission = useMemo(
    () => hasUserRequiredOperations(neededOperationsForConditionDelete, allowedOperations),
    [allowedOperations],
  )

  const externalConditionDeleteButton = useMemo(() => {
    if (!hasUserConditionDeletePermission) {
      return
    }
    return (
      <DeleteSelectedConditionsButton disabled={disabled} conditionType={conditionTypes.external} />
    )
  }, [hasUserConditionDeletePermission, disabled])

  const externalConditionDuplicateButton = useMemo(() => {
    if (entityType !== conditionsEntityTypes.deal) {
      return
    }
    return <TransferExternalConditionButton disabled={disabled} />
  }, [disabled, entityType])

  const externalConditionCopyButton = useMemo(() => {
    if (!hasUserConditionCreatePermission) {
      return
    }
    return (
      <CopySelectedConditionsButton disabled={disabled} conditionType={conditionTypes.external} />
    )
  }, [hasUserConditionCreatePermission, disabled])

  const hasUserConditionEditPermission = useMemo(
    () => hasUserRequiredOperations(neededOperationsForConditionEdit, allowedOperations),
    [allowedOperations],
  )

  const externalConditionsMassEditButton = useMemo(() => {
    if (!hasUserConditionEditPermission) {
      return
    }
    return <ConditionsMassEditButton disabled={disabled} conditionType={conditionTypes.external} />
  }, [hasUserConditionEditPermission, disabled])

  return (
    <FlexBox className={styles.buttons}>
      {externalConditionAddButton}
      {externalConditionsMassEditButton}
      {externalConditionCopyButton}
      {externalConditionDuplicateButton}
      {externalConditionDeleteButton}
    </FlexBox>
  )
}

ExternalConditionsToolbarButtons.propTypes = {
  disabled: PropTypes.bool.isRequired,
}

export default ExternalConditionsToolbarButtons
