import {
  Bar,
  BarDesign,
  Button,
  ButtonDesign,
  Dialog,
  Icon,
  IconDesign,
  Label,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import dialogStyles from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/toolbar/FilterDialog.module.css'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/toolbar/FilterDialogView.module.css'
import toolbarStyles from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/toolbar/TablesToolbar.module.css'
import useAnalyticalTableDialogFocusFix from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/analytical-table/useAnalyticalTableDialogFocusFix'

const FilterDialogView = ({
  isOpen,
  setIsOpen,
  onSubmit,
  onCancel,
  onReset,
  isNoFilterEntered,
  hasInvalidInputs,
  children,
  disabled,
}) => {
  const dialogRef = useRef()
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'components.ui.tables.toolbar.filter',
  })

  useAnalyticalTableDialogFocusFix({ dialogRef })

  const onIconClick = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  const onAfterClose = useCallback(() => setIsOpen(false), [setIsOpen])

  if (disabled) {
    return (
      <Icon
        className={[styles.filterIconDisabled, toolbarStyles.filterIcon].join(' ')}
        name="filter"
        design={IconDesign.NonInteractive}
      />
    )
  }

  return (
    <>
      <Icon className={toolbarStyles.filterIcon} name="filter" interactive onClick={onIconClick} />
      {createPortal(
        <Dialog
          ref={dialogRef}
          className={dialogStyles.filterDialog}
          draggable
          footer={
            <Bar
              design={BarDesign.Footer}
              endContent={
                <>
                  <Button
                    design={ButtonDesign.Emphasized}
                    disabled={hasInvalidInputs}
                    onClick={onSubmit}
                  >
                    {t('submit')}
                  </Button>
                  <Button design={ButtonDesign.Transparent} onClick={onCancel}>
                    {t('cancel')}
                  </Button>
                </>
              }
            />
          }
          header={
            <Bar
              design={BarDesign.Header}
              startContent={<Label>{t('header')}</Label>}
              endContent={
                <Button
                  design={ButtonDesign.Transparent}
                  onClick={onReset}
                  disabled={isNoFilterEntered}
                >
                  {t('reset')}
                </Button>
              }
            />
          }
          open={isOpen}
          onAfterClose={onAfterClose}
        >
          {children}
        </Dialog>,
        document.body,
      )}
    </>
  )
}

FilterDialogView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  isNoFilterEntered: PropTypes.bool.isRequired,
  hasInvalidInputs: PropTypes.bool,
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
}

export default FilterDialogView
