import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * Returns all available page ids
 * The response body structure is as follows:
 * {
 *   "pageIds": ['page-id', ...]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: pageIds}}
 */
const useChangeLogPageIds = ({ entityType }) => {
  const pathPrefix = '/traceability/configurations/page-ids'
  const keys = ['traceability', 'configuration', 'page-keys']
  const queryParams = new URLSearchParams()
  if (entityType) {
    queryParams.set('entity_type', entityType)
    keys.push(entityType)
  }
  const path = entityType ? `${pathPrefix}?${queryParams.toString()}` : pathPrefix

  return useCamelizedResponse(
    useRequest({
      path,
      keys,
      translated: true,
      useCache: false,
    }),
  )
}
export default useChangeLogPageIds
