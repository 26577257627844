import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import HandoverCoverPoolStatusTileContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/handover/general-deal-information/HandoverCoverPoolStatusTileContent'
import HandoverCoverPoolStatusTileContentEdit from 'components/domains/business-events-and-tasks/decision-paper/tiles/handover/general-deal-information/HandoverCoverPoolStatusTileContentEdit'

const HandoverCoverPoolStatusTile = ({
  tileId,
  currentContent,
  onChange,
  isEditMode,
  setHasContentChanges,
}) => {
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const initialContent = useMemo(
    () => ({
      coverpoolStatus: undefined,
      comment: '',
    }),
    [],
  )

  const content = useMemo(() => currentContent ?? initialContent, [currentContent, initialContent])

  useEffect(() => {
    const hasContentChanges =
      !(isNil(currentContent) && isNil(tileStatus?.data?.data)) &&
      !isEqual(tileStatus?.data?.data, currentContent)
    setHasContentChanges(hasContentChanges)
  }, [currentContent, setHasContentChanges, tileStatus?.data?.data])

  return isEditMode ? (
    <HandoverCoverPoolStatusTileContentEdit onChange={onChange} content={content} />
  ) : (
    <HandoverCoverPoolStatusTileContent content={content} />
  )
}

HandoverCoverPoolStatusTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  currentContent: PropTypes.shape({
    coverpoolStatus: PropTypes.string,
    comment: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
}

export default HandoverCoverPoolStatusTile
