import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/leveraged-transaction/debt-per-ebitda-comment/DebtPerEBITDACommentInputRow.module.css'

const DebtPerEBITDACommentInputRow = ({ label, children }) => (
  <FlexBox
    direction={FlexBoxDirection.Row}
    alignItems={FlexBoxAlignItems.Center}
    fitContainer
    justifyContent={FlexBoxJustifyContent.SpaceBetween}
  >
    <Label>{label}</Label>
    <div className={styles.inputColumn}>{children}</div>
  </FlexBox>
)

DebtPerEBITDACommentInputRow.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default DebtPerEBITDACommentInputRow
