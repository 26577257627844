import flattenDocuments from 'hooks/services/documents/flattenDocuments'
import useDocuments from 'hooks/services/documents/useDocuments'

/**
 * @param {object} params
 * @param {string} [params.entityId]
 * @param {string} [params.entityType]
 */
const useDocumentsFlat = ({ entityId, entityType }) => {
  const response = useDocuments({ entityId, entityType })
  return { ...response, data: { documents: flattenDocuments(response.data) } }
}

export default useDocumentsFlat
