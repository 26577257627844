import { ResponsiveGridLayout, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/properties/monitoring/settings/dialog/DialogGroup.module.css'

const DialogGroup = ({ children, headerText }) => (
  <ResponsiveGridLayout columnsS={1} columnsL={1} columnsM={1} columnsXL={1} rowGap={'16px'}>
    {headerText && <Text className={styles.dialogGroupHeader}>{headerText}</Text>}
    {children}
  </ResponsiveGridLayout>
)

DialogGroup.propTypes = {
  headerText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}

export default DialogGroup
