import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxJustifyContent,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHref } from 'react-router-dom'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import EditAndDeleteTableCell from 'components/ui/tables/sorted-tables/EditAndDeleteTableCell'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

export const possibleActions = {
  showEditCells: 'showEditCells',
  showRemindVoteButton: 'showRemindVoteButton',
  showToApprovalButton: 'showToApprovalButton',
}

const VotersTableActionCell = ({
  voterCancelButtonId,
  isEditModeForCurrentRow,
  isEditButtonDisabled,
  isDeleteButtonDisabled,
  onSaveCancelButtonClicked,
  onSaveButtonClicked,
  isSaveButtonDisabled,
  onEditButtonClicked,
  onDeleteButtonClicked,
  showActionButtons,
  voterId,
  decisionStageId,
  toApprovalHref,
  useRemindVoterMutationHook,
  renderedAction,
}) => {
  const {
    event: { id: eventId },
    allowedOperations,
  } = useContext(BusinessEventsAndTasksContext)
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.voters-table.cells.actions',
  })
  const approvalHref = useHref(toApprovalHref)
  const showMessageBox = Modals.useShowMessageBox()
  const showToast = Modals.useShowToast()
  const { mutate: remindVoter } = useRemindVoterMutationHook()

  const onClickToApprovalButton = useCallback(() => {
    window.open(approvalHref, '_blank')
  }, [approvalHref])

  const onRemindSuccess = useCallback(() => {
    showToast({ children: t('remind-vote.success') }, document.body)
  }, [showToast, t])
  const onRemindError = useCallback(() => {
    showMessageBox(
      {
        type: MessageBoxTypes.Error,
        children: t('remind-vote.error'),
        draggable: true,
        resizable: true,
      },
      document.body,
    )
  }, [showMessageBox, t])

  const onRemindConfirm = useCallback(() => {
    remindVoter(
      { eventId, decisionStageId, voterId },
      { onSuccess: onRemindSuccess, onError: onRemindError },
    )
  }, [eventId, decisionStageId, voterId, onRemindSuccess, onRemindError, remindVoter])

  const onRemindVoteButtonClicked = useCallback(() => {
    showMessageBox(
      {
        type: MessageBoxTypes.Confirm,
        titleText: t('remind-vote'),
        children: t('remind-vote.confirmation.text'),
        actions: [
          <Button
            key="button-remind-vote"
            design={ButtonDesign.Emphasized}
            onClick={onRemindConfirm}
          >
            {t('remind-vote')}
          </Button>,
          MessageBoxActions.Cancel,
        ],
        draggable: true,
        resizable: true,
      },
      document.body,
    )
  }, [showMessageBox, onRemindConfirm, t])

  if (renderedAction === possibleActions.showEditCells) {
    return (
      <FlexBox justifyContent={FlexBoxJustifyContent.End}>
        <EditAndDeleteTableCell
          cancelButtonId={voterCancelButtonId}
          isEditModeForCurrentRow={isEditModeForCurrentRow}
          isEditButtonDisabled={isEditButtonDisabled}
          isDeleteButtonDisabled={isDeleteButtonDisabled}
          onCancelButtonClicked={onSaveCancelButtonClicked}
          onSaveButtonClicked={onSaveButtonClicked}
          isSaveButtonDisabled={isSaveButtonDisabled}
          onEditButtonClicked={onEditButtonClicked}
          onDeleteButtonClicked={onDeleteButtonClicked}
          showEditButton={showActionButtons}
          showDeleteButton={showActionButtons}
        />
      </FlexBox>
    )
  }

  if (
    renderedAction === possibleActions.showRemindVoteButton &&
    allowedOperations.includes(businessEventAllowedOperations.updateDecisionStage)
  ) {
    return (
      <FlexBox justifyContent={FlexBoxJustifyContent.End}>
        <Button onClick={onRemindVoteButtonClicked}>{t('remind-vote')}</Button>
      </FlexBox>
    )
  }

  if (renderedAction === possibleActions.showToApprovalButton) {
    return (
      <FlexBox justifyContent={FlexBoxJustifyContent.End}>
        <Button onClick={onClickToApprovalButton}>{t('to-approval')}</Button>
      </FlexBox>
    )
  }
}

VotersTableActionCell.propTypes = {
  voterCancelButtonId: PropTypes.string.isRequired,
  isEditModeForCurrentRow: PropTypes.bool.isRequired,
  isEditButtonDisabled: PropTypes.bool.isRequired,
  isDeleteButtonDisabled: PropTypes.bool.isRequired,
  onSaveCancelButtonClicked: PropTypes.func.isRequired,
  onSaveButtonClicked: PropTypes.func.isRequired,
  isSaveButtonDisabled: PropTypes.bool.isRequired,
  onEditButtonClicked: PropTypes.func.isRequired,
  onDeleteButtonClicked: PropTypes.func.isRequired,
  showActionButtons: PropTypes.bool.isRequired,
  voterId: PropTypes.string.isRequired,
  decisionStageId: PropTypes.string.isRequired,
  toApprovalHref: PropTypes.string.isRequired,
  useRemindVoterMutationHook: PropTypes.func.isRequired,
  renderedAction: PropTypes.oneOf(Object.values(possibleActions)),
}

export default VotersTableActionCell
