export const ENTITIES = {
  DEAL: 'deal',
  COVENANT: 'covenant',
  CASHFLOW_SCENARIO: 'cashflow-scenario',
  CASHFLOW_SCENARIO_INPUT_PARAMETERS: 'cashflow-scenario-input-parameters',
  CASHFLOW_SCENARIO_RESULTS_AND_USED_PARAMETERS: 'cashflow-scenario-results-and-used-parameters',
  DOCUMENT: 'document',
  EXTERNAL_CONDITION: 'external-condition',
  INTERNAL_CONDITION: 'internal-condition',
  REQUIREMENT: 'requirement',
  TRANCHE: 'tranche',
  ACCOUNTING: 'accounting',
  ADDITIONAL_INTEREST_INFORMATION: 'additional-interest-information',
  INTEREST_CONDITION_ITEM: 'interest-condition-item',
  AMORTIZATION_CONDITION_ITEM: 'amortization-condition-item',
  FEE: 'fee',
  INTEREST_RATE_HEDGING_REQUIREMENT: 'interest-rate-hedging-requirement',
  PRICING: 'pricing',
  FINANCING_SOURCES_ITEM: 'financing-sources-item',
  PROPERTY: 'property',
  BUSINESS_PARTNER: 'business-partner',
}

export const ENTITY_ID_TYPES = {
  TECHNICAL: 'technical',
  DISPLAY: 'display',
}

export const ENTITY_LINK_TYPES_MAPPING = {
  [ENTITIES.DEAL]: ENTITY_ID_TYPES.DISPLAY,
  [ENTITIES.COVENANT]: ENTITY_ID_TYPES.TECHNICAL,
  [ENTITIES.TRANCHE]: ENTITY_ID_TYPES.DISPLAY,
  [ENTITIES.CASHFLOW_SCENARIO]: ENTITY_ID_TYPES.TECHNICAL,
  [ENTITIES.DOCUMENT]: ENTITY_ID_TYPES.TECHNICAL,
  [ENTITIES.EXTERNAL_CONDITION]: ENTITY_ID_TYPES.TECHNICAL,
  [ENTITIES.INTERNAL_CONDITION]: ENTITY_ID_TYPES.TECHNICAL,
  [ENTITIES.REQUIREMENT]: ENTITY_ID_TYPES.TECHNICAL,
}

export const CHILD_TO_PARENT_ENTITY_MAPPING = {
  // Cash Flow
  [ENTITIES.CASHFLOW_SCENARIO_INPUT_PARAMETERS]: ENTITIES.CASHFLOW_SCENARIO,
  [ENTITIES.CASHFLOW_SCENARIO_RESULTS_AND_USED_PARAMETERS]: ENTITIES.CASHFLOW_SCENARIO,

  // Tranches
  [ENTITIES.ACCOUNTING]: ENTITIES.TRANCHE,
  [ENTITIES.ADDITIONAL_INTEREST_INFORMATION]: ENTITIES.TRANCHE,
  [ENTITIES.INTEREST_CONDITION_ITEM]: ENTITIES.TRANCHE,
  [ENTITIES.AMORTIZATION_CONDITION_ITEM]: ENTITIES.TRANCHE,
  [ENTITIES.FEE]: ENTITIES.TRANCHE,
  [ENTITIES.INTEREST_RATE_HEDGING_REQUIREMENT]: ENTITIES.TRANCHE,
  [ENTITIES.PRICING]: ENTITIES.TRANCHE,
}

export const LINK_TYPES = {
  PAGE: 'page',
  ENTITY: 'entity',
}

// Field changes where the technical id is resolved same as for child entities
export const ENTITY_CHILD_FIELD_MAPPING = {
  [ENTITIES.REQUIREMENT]: ['comment', 'reminder'],
  [ENTITIES.EXTERNAL_CONDITION]: ['comment'],
  [ENTITIES.INTERNAL_CONDITION]: ['comment'],
}

export const ACTIONS = {
  ADD_REQUIREMENT: 'REQUIREMENT_ADDED',
}

// Map to reference entity relationships for specific actions
export const ENTITY_TYPE_PARENT_ACTIONS = {
  [ENTITIES.REQUIREMENT]: {
    actions: {
      [ACTIONS.ADD_REQUIREMENT]: {
        parent: ENTITIES.EXTERNAL_CONDITION,
      },
    },
  },
}
