import compact from 'lodash.compact'
import uniq from 'lodash.uniq'
import { useMemo } from 'react'
import { ReferenceEntityType } from 'components/ui/data/ReferenceEntityType'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useEventsMiniByIdList from 'hooks/services/business-events-and-tasks/events/useEventsMiniByIdList'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import useMultipleCovenantChecks from 'hooks/services/deals/covenants/checks/useMultipleCovenantChecks'
import { useMultipleDealsByUuidMini } from 'hooks/services/deals/useMultipleDealsByUuidMini'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const useAnnualReviewMultipleCovenantChecks = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { dealsData, dealUuids, selectedDeal } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const { selectedDealDisplayId } = selectedDeal ?? {}

  const {
    data: multipleCovenantChecksData = [],
    isLoading: isMultipleCovenantChecksLoading,
    isError: isMultipleCovenantChecksError,
  } = useCombinedQueryResults(
    useMultipleCovenantChecks({
      mainEntityIds: dealUuids,
      mainEntityType: mainEntityTypes.DEAL,
      monitoringReferenceEntityType: mainEntityTypes.DEAL,
    }),
    { forceDataReturn: false },
  )

  const allCovenantCheckDealUuids = useMemo(
    () =>
      uniq(
        multipleCovenantChecksData.flatMap(({ covenantChecks: covenantData = [] } = {}) =>
          covenantData.reduce((accumulator, { monitoringReferenceEntity, covenants = [] } = {}) => {
            if (monitoringReferenceEntity?.type === ReferenceEntityType.Deal) {
              accumulator.push(monitoringReferenceEntity.id)
            }
            covenants?.forEach((covenant) => {
              if (covenant?.mainEntity?.type === ReferenceEntityType.Deal) {
                accumulator.push(covenant?.mainEntity.id)
              }
            })
            return accumulator
          }, []),
        ),
      ),
    [multipleCovenantChecksData],
  )

  const {
    data: multipleDealsByUuidMiniData = [],
    isLoading: isMultipleDealsByUuidMiniLoading,
    isError: isMultipleDealsByUuidMiniError,
  } = useCombinedQueryResults(
    useMultipleDealsByUuidMini(allCovenantCheckDealUuids, {
      enabled: allCovenantCheckDealUuids.length > 0,
    }),
    {
      forceDataReturn: false,
    },
  )

  const allCovenantCheckEventIds = useMemo(
    () =>
      uniq(
        multipleCovenantChecksData.flatMap(({ covenantChecks = [] } = {}) =>
          compact(covenantChecks.map(({ linkedEventId } = {}) => linkedEventId)),
        ),
      ),
    [multipleCovenantChecksData],
  )

  const {
    data: multipleEventsData = [],
    isLoading: isEventsLoading,
    isError: isEventsError,
  } = useEventsMiniByIdList({ eventIds: allCovenantCheckEventIds })

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isAnnualReviewDealsLoading,
      isMultipleCovenantChecksLoading,
      isMultipleDealsByUuidMiniLoading,
      isEventsLoading,
    ],
    errorValues: [
      isAnnualReviewDealsError,
      isMultipleCovenantChecksError,
      isMultipleDealsByUuidMiniError,
      isEventsError,
    ],
    errorDetails: [annualReviewDealsError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        multipleCovenantChecksData,
        multipleDealsByUuidMiniData,
        multipleEventsData,
        sourceRender: {
          dealDisplayId: selectedDealDisplayId,
        },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealsData,
    multipleCovenantChecksData,
    multipleDealsByUuidMiniData,
    multipleEventsData,
    selectedDealDisplayId,
    error,
  ])
}

export default useAnnualReviewMultipleCovenantChecks
