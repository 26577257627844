import PropTypes from 'prop-types'
import { useCallback } from 'react'
import DisbursementTileDisplayOrEditItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/disbursement/fields/DisbursementTileDisplayOrEditItem'
import TextAreaWithMaxCharacterRestriction from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/portolio-analysis/fields/shared/TextAreaWithMaxCharacterRestriction'

const PortfolioAnalysisTileTextField = ({
  label,
  value,
  isEditMode,
  onChange,
  isFirstItem,
  maxLength,
  growing,
  growingMaxLines,
}) => {
  const renderEditComponent = useCallback(
    () => (
      <TextAreaWithMaxCharacterRestriction
        value={value}
        onInput={onChange}
        maxLength={maxLength}
        growing={growing}
        growingMaxLines={growingMaxLines}
      />
    ),
    [value, onChange, maxLength, growing, growingMaxLines],
  )

  return (
    <DisbursementTileDisplayOrEditItem
      label={label}
      value={value}
      renderEditComponent={renderEditComponent}
      isEditMode={isEditMode}
      isFirstItem={isFirstItem}
    />
  )
}

PortfolioAnalysisTileTextField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isEditMode: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isFirstItem: PropTypes.bool,
  maxLength: PropTypes.number.isRequired,
  growing: PropTypes.bool,
  growingMaxLines: PropTypes.number,
}

export default PortfolioAnalysisTileTextField
