import { BusyIndicatorSize, Label, WrappingType } from '@fioneer/ui5-webcomponents-react'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import RichTextTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/RichTextTile'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import useCheckCycleTranslations from 'components/domains/deals/covenants/covenants-table/useCheckCycleTranslations'
import {
  covenantCheckPropTypes,
  monitoringInformationPropTypes,
} from 'components/domains/deals/covenants/monitoring-information-card/MonitoringInformationPropTypes'
import styles from 'components/domains/deals/covenants/monitoring-information-card/MonitoringInformationView.module.css'
import RelativeDateComparisonView from 'components/domains/deals/covenants/monitoring-information-card/RelativeDateComparisonView'
import useBreachRuleTranslations from 'components/domains/deals/covenants/shared/useBreachRuleTranslations'
import Entity from 'components/ui/data/Entity'
import ReferenceEntities from 'components/ui/data/ReferenceEntities'
import { ReferenceEntityType } from 'components/ui/data/ReferenceEntityType'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useEvent from 'hooks/services/business-events-and-tasks/events/useEvent'
import { DealContext } from 'routes/deals/DealContext'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import paths from 'routes/paths'

const MonitoringInformationView = ({
  monitoringInformation: {
    monitoringReferenceEntity = {},
    firstDueDate: {
      keyDate: monitoringKeyDate,
      targetDeliveryDateBaseData: monitoringDeliveryDateBaseData,
      checkDateBaseData: monitoringCheckDateBaseData,
    } = {},
    checkCycle = {},
    lastChecked,
    breachRule,
    comment,
  } = {},
  covenantCheck: {
    covenantCheckUuid,
    name: covenantCheckName,
    keyDate: covenantCheckKeyDate,
    firstTargetDeliveryDate: covenantCheckFirstDeliveryDate,
    linkedEventId,
  } = {},
}) => {
  const { deal: { displayId } = {} } = useContext(DealContext)

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.monitoring-card',
  })
  const { t: tNoPrefix } = useTranslation('translation')

  const { format: formatDate } = useShortDateFormatter()
  const { getFormattedCheckCycle } = useCheckCycleTranslations()
  const { getTranslationForBreachRule } = useBreachRuleTranslations()

  const MonitoringReferenceTypeTranslations = {
    DEAL: tNoPrefix('pages.deals.table.deal'),
    BUSINESSPARTNER: tNoPrefix('pages.deals.covenants.business-partner'),
  }

  const {
    isLoading: isLoadingEvent,
    isFetching: isFetchingEvent,
    isError: isErrorEvent,
    data: linkedEvent,
  } = useEvent(linkedEventId, {
    enabled: !!linkedEventId,
    retry: 1,
  })

  const covenantCheckInformation = {}
  switch (monitoringReferenceEntity.type) {
    case ReferenceEntityType.Deal:
      covenantCheckInformation.link = `/${paths.deals}/${displayId}/${dealDetailPaths.covenants}/${dealDetailPaths.covenantChecks}/${covenantCheckUuid}`
      covenantCheckInformation.openInNewTab = false
      break
    case ReferenceEntityType.BusinessPartner:
      covenantCheckInformation.link = `/${paths.businessPartners}/${monitoringReferenceEntity.id}/covenants/covenant-checks/${covenantCheckUuid}`
      covenantCheckInformation.openInNewTab = true
      break
  }
  return (
    <>
      <CardSection title={t('basic-information')} id="basic-information">
        <CardShowItem
          label={tNoPrefix('pages.deals.covenants.monitoring-reference-type')}
          value={MonitoringReferenceTypeTranslations[monitoringReferenceEntity.type]}
        />
        <CardShowItem
          label={tNoPrefix('pages.deals.covenants.monitoring-entity')}
          value={
            <ReferenceEntities
              type={monitoringReferenceEntity.type} //can be DEAL or BUSINESSPARTNER
              ids={[monitoringReferenceEntity.id]}
              entityClassName={styles.entityAlignmentRight}
            />
          }
        />
        <CardShowItem label={t('first-key-date')} value={formatDate(monitoringKeyDate)} />
        <CardShowItem
          label={tNoPrefix('pages.deals.covenants.delivery-date')}
          value={
            <RelativeDateComparisonView
              amount={monitoringDeliveryDateBaseData?.amountToAdd}
              temporalUnit={monitoringDeliveryDateBaseData?.temporalUnit}
              direction={monitoringDeliveryDateBaseData?.direction}
              referenceDateTranslation={t('key-date-plain')}
            />
          }
        />
        <CardShowItem
          label={t('check-date')}
          value={
            <RelativeDateComparisonView
              amount={monitoringCheckDateBaseData?.amountToAdd}
              temporalUnit={monitoringCheckDateBaseData?.temporalUnit}
              direction={monitoringCheckDateBaseData?.direction}
              referenceDateTranslation={tNoPrefix('pages.deals.covenants.delivery-date')}
            />
          }
        />
        <CardShowItem
          id="check-cycle-view"
          label={tNoPrefix('pages.deals.covenants.check-cycle')}
          value={getFormattedCheckCycle({ number: checkCycle?.number, cycle: checkCycle?.cycle })}
        />
        <CardShowItem
          label={tNoPrefix('pages.deals.covenants.last-checked')}
          value={formatDate(lastChecked)}
        />
        <CardShowItem label={t('breach-if')} value={getTranslationForBreachRule(breachRule)} />
      </CardSection>
      <CardSection title={t('next-covenant-check')} id="next-covenant-check">
        <CardShowItem
          label={t('covenant-check')}
          value={
            covenantCheckUuid && (
              <Entity
                className={`${styles.alignmentRight} ${styles.entitySpacing}`}
                name={covenantCheckName}
                link={covenantCheckInformation.link}
                openInNewTab={covenantCheckInformation.openInNewTab}
              />
            )
          }
        />
        <CardShowItem
          label={t('monitoring-event')}
          value={
            linkedEventId && (
              <RequestStateResolver
                isLoading={isLoadingEvent && isFetchingEvent}
                isError={isErrorEvent}
                loadingSize={BusyIndicatorSize.Small}
                errorToDisplay={
                  <Label className={styles.errorLabel} wrappingType={WrappingType.Normal}>
                    {tNoPrefix('pages.deals.covenants.data-error')}
                  </Label>
                }
                renderContent={() => (
                  <Entity
                    className={styles.alignmentRight}
                    name={linkedEvent?.info?.name}
                    id={linkedEvent?.displayId}
                    link={`/${paths.businessEventsAndTasks}/${paths.businessEvents}/${linkedEventId}`}
                    openInNewTab={true}
                  />
                )}
              />
            )
          }
        />
        <CardShowItem label={t('key-date')} value={formatDate(covenantCheckKeyDate)} />
        <CardShowItem
          label={t('first-delivery-date')}
          value={formatDate(covenantCheckFirstDeliveryDate)}
        />
      </CardSection>
      <CardSection title={t('comment')} id="comment">
        <div className={styles.richTextWrapper}>
          <RichTextTile
            isEditMode={false}
            currentContent={comment ?? ''}
            isPdfView={false}
            isPdfViewCollapsible={true}
            onChange={() => {}}
          />
        </div>
      </CardSection>
    </>
  )
}

MonitoringInformationView.propTypes = {
  monitoringInformation: monitoringInformationPropTypes,
  covenantCheck: covenantCheckPropTypes,
}

export default MonitoringInformationView
