import { isNumber } from 'lodash'
import { propertyMonitoringThresholdType } from 'api/property/monitoring/propertyMonitoringConfiguration'
import { useNumberFormatter, usePercentageFormatter } from 'hooks/i18n/useI18n'

const useFormatPercentageOrValueWithUnit = ({
  numberFormatterProps = { maximumFractionDigits: 2 },
} = {}) => {
  const formatNumber = useNumberFormatter(numberFormatterProps)
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  return ({ value, type, unit } = {}) => {
    if (isNumber(value) && !isNaN(value)) {
      if (type === propertyMonitoringThresholdType.relative || unit === '%') {
        return formatPercentage(value / 100)
      }
      return `${formatNumber(value)} ${unit}`
    }
    return '-'
  }
}

export default useFormatPercentageOrValueWithUnit
