import _ from 'lodash'

export const EXCEL_FIELDS_TO_OBTAIN_MAPPINGS_FOR = [
  { primary: 'tenant_name', secondary: 'tenant_id' },
  { primary: 'branding_franchise_partner_name', secondary: 'branding_franchise_partner_id' },
  { primary: 'anonymous_tenant' },
  { primary: 'rental_unit_area_uom_id' },
  { primary: 'rental_unit_currency_id' },
  { primary: 'occupancy_status_id' },
  { primary: 'segment_usage_type_id' },
  { primary: 'property_uuid' },
]

const mapToString = (value) => (value || value === false ? value.toString() : '')

const getPropertyRentRollWorkingVersionExcelLocalValueMapping = (
  rentalUnitsWithOriginalExcelData,
) => {
  const finalMappings = []
  EXCEL_FIELDS_TO_OBTAIN_MAPPINGS_FOR.forEach((field) => {
    const { primary: fieldName, secondary: secondaryField } = field
    const intermediateMappings = new Map()
    const secondaryIntermediateMappings = new Map()
    const originalValuesWithAmbiguousMapping = []
    rentalUnitsWithOriginalExcelData
      .filter(
        (row) =>
          _.has(row, ['original_excel_content', fieldName]) &&
          !!row['original_excel_content'][fieldName],
      )
      .forEach((row) => {
        const originalValue = row['original_excel_content'][fieldName]
        const targetValue = mapToString(row[fieldName])
        const secondaryTargetValue = secondaryField && mapToString(row[secondaryField])
        if (
          !(
            intermediateMappings.has(originalValue) ||
            originalValuesWithAmbiguousMapping.includes(originalValue)
          )
        ) {
          //original value has not shown up yet, mapping can be constructed
          intermediateMappings.set(originalValue, targetValue)
          secondaryTargetValue &&
            secondaryIntermediateMappings.set(originalValue, secondaryTargetValue)
        }
        if (
          intermediateMappings.has(originalValue) &&
          intermediateMappings.get(originalValue) !== targetValue
        ) {
          //the same original value has been mapped to different target values
          //remove mapping rule and declare as ambiguous
          originalValuesWithAmbiguousMapping.push(originalValue)
          intermediateMappings.delete(originalValue)
          secondaryTargetValue && secondaryIntermediateMappings.delete(originalValue)
        }
      })
    intermediateMappings.forEach((targetValue, originalValue, _map) => {
      finalMappings.push({
        field_name: fieldName,
        match_type: 'identical',
        original_value: originalValue,
        target_value: targetValue,
      })
    })
    secondaryIntermediateMappings.forEach((targetValue, originalValue, _map) => {
      finalMappings.push({
        field_name: secondaryField,
        match_type: 'identical',
        original_value: originalValue,
        target_value: targetValue,
      })
    })
  })

  return { field_mapping_rules: [...finalMappings] }
}

export default getPropertyRentRollWorkingVersionExcelLocalValueMapping
