import { ValueState } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import { ACTIVE } from 'components/domains/business-partners/tile/risk-monitoring/forbearance/constants'
import resolveLifecycleStatusCodeToValueState from 'components/domains/business-partners/tile/risk-monitoring/forbearance/helper/resolveLifecycleStatusCodeToValueState'
import useForbearanceStatuses from 'hooks/services/risk-monitoring/forbearance/statuses/useForbearanceStatuses'

/**
 * @param {string} businessPartnerId
 */
const useBusinessPartnerForbearanceStatus = (businessPartnerId) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.business-partner.risk-monitoring.forbearance',
  })
  const { data, isLoading, isError } = useForbearanceStatuses({ businessPartnerId })

  const activeForbearanceStatuses =
    data?.forbearanceStatuses?.filter((status) => status.lifecycleStatusTypeCode === ACTIVE) ?? []

  if (activeForbearanceStatuses.length > 1) {
    // eslint-disable-next-line no-console
    console.error(
      `Multiple active forbearance statuses for business partner ${businessPartnerId}. The first one will be used.`,
    )
  }

  const activeForbearanceStatus = activeForbearanceStatuses[0]

  if (!activeForbearanceStatus) {
    return {
      isLoading,
      isError,
      data: {
        label: t('status'),
        text: t('no-forbearance'),
        valueState: ValueState.Success,
      },
    }
  }

  return {
    isLoading,
    isError,
    data: {
      label: t('status'),
      text: activeForbearanceStatus.forbearanceStatusShortText,
      valueState: resolveLifecycleStatusCodeToValueState(
        activeForbearanceStatus.forbearanceStatusTypeCode,
      ),
    },
  }
}

export default useBusinessPartnerForbearanceStatus
