import { TableColumn, FlexBox, Text } from '@fioneer/ui5-webcomponents-react'

const alignContent = (content, alignment) =>
  alignment ? <FlexBox justifyContent={alignment}>{content}</FlexBox> : content
/**
 * Creates the TableColumns out of the columnDefinitions for a Table
 * @param columnDefinitions array of colum definitions
 *    columnKey
 *    sortingDisabled
 *    renderColumnContent
 *    title
 *    onClick
 *    wrapText
 *    alignment
 */
const createColumns = (columnDefinitions) =>
  columnDefinitions.map(
    ({
      columnKey,
      renderColumnContent,
      title,
      onClick: _onClick,
      sortingDisabled: _sortingDisabled,
      wrapText,
      alignment,
      ...additionalColumnProperties
    }) => {
      const renderColumn = () => {
        if (renderColumnContent) {
          return renderColumnContent()
        }
        return <Text wrapping={wrapText}>{title}</Text>
      }
      return (
        <TableColumn key={columnKey} {...additionalColumnProperties}>
          {alignContent(renderColumn(), alignment)}
        </TableColumn>
      )
    },
  )

export default createColumns
