export const ARREARS_HEADQUARTER_CURRENCY = 'EUR'

export const ENTITY_TYPES = Object.freeze({
  DEAL: 'DEAL',
  TRANCHE: 'TRANCHE',
  DRAWING: 'DRAWING',
  ARREAR: 'ARREAR',
  APPROVALITEM: 'APPROVAL_ITEM',
  NON_LOAN: 'NON_LOAN',
  BCA: 'BCA',
})

export const ARREAR_APPROVAL_STATUS = Object.freeze({
  APPROVED: 'APPROVED',
  NOT_APPROVED: 'NOT_APPROVED',
  PARTIALLY_APPROVED: 'PARTIALLY_APPROVED',
  PARTIALLY_IN_APPROVAL: 'PARTIALLY_IN_APPROVAL',
  IN_APPROVAL: 'IN_APPROVAL',
})

export const APPROVAL_EVENT_STATUS = Object.freeze({
  COMPLETED: 'COMPLETED',
})

export const EXTENDING_STATES = Object.freeze({
  INITIAL: 'INITIAL',
  CONVERTING: 'CONVERTING',
  CONVERTED: 'CONVERTED',
  SETTING: 'SETTING',
  SET: 'SET',
  ERROR: 'ERROR',
})

export const NO_DEAL = 'NO_DEAL'
