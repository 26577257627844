import { TableMode } from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import orderBy from 'lodash.orderby'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  valuationClassification,
  valuationClassificationTranslated,
} from 'api/property/valuation/valuationRequests'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-results/PropertyValuationResultsTable.module.css'
import useMapPropertyValuationResultsTableData from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-results/useMapPropertyValuationResultsTableData'
import usePropertyValuationResultsTableColumns from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-results/usePropertyValuationResultsTableColumns'
import SortedTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/sorted-tables/SortedTable'

const PropertyValuationResultsTable = ({ valuationResults, isDecisionPaperPdfView = false }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.valuation-results.pages.property.valuation.results',
  })
  const { t: tNoPrefix } = useTranslation('decisionPaper')

  const flatValuationResultsWithoutOther = compact(
    valuationResults
      ?.filter(({ cwpCode } = {}) => cwpCode !== valuationClassification.other)
      ?.flatMap(({ historicalValues } = {}) => historicalValues),
  )

  const methodEnum = useMemo(
    () =>
      Object.fromEntries(
        flatValuationResultsWithoutOther.map(({ externalCalculationMethodName: method }) => [
          method,
          method,
        ]),
      ),
    [flatValuationResultsWithoutOther],
  )
  const valuationTypeEnum = useMemo(
    () =>
      Object.fromEntries(
        flatValuationResultsWithoutOther.map(({ classification: valuationType }) => [
          valuationType,
          tNoPrefix(valuationClassificationTranslated?.[valuationType]),
        ]),
      ),
    [flatValuationResultsWithoutOther, tNoPrefix],
  )

  const { columnDefinitions } = usePropertyValuationResultsTableColumns({
    valuationTypeEnum,
    methodEnum,
  })

  const { tableData } = useMapPropertyValuationResultsTableData({
    valuationResults: flatValuationResultsWithoutOther,
  })

  const sortedTableData = orderBy(tableData, ['validFrom.value', 'method.value'], ['desc', 'asc'])

  return (
    <SortedTable
      columnDefinitions={columnDefinitions}
      tableData={sortedTableData}
      toolbarConfig={{
        title: t('table.toolbar.title'),
        showColumnSelection: !isDecisionPaperPdfView,
      }}
      additionalTableProperties={{
        stickyColumnHeader: true,
        className: isDecisionPaperPdfView ? styles.valuationsTablePdfView : styles.valuationsTable,
        mode: TableMode.None,
      }}
      noDataText={t('table.no-data')}
    />
  )
}

PropertyValuationResultsTable.propTypes = {
  valuationResults: PropTypes.arrayOf(
    PropTypes.shape({
      historicalValues: PropTypes.arrayOf(
        PropTypes.shape({
          externalCalculationMethodName: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  isDecisionPaperPdfView: PropTypes.bool,
}

export default PropertyValuationResultsTable
