import { useRequest } from 'hooks/services/baseService'

/**
 * It is possible to configure common currencies in the property service.
 * The reason for this is that you don't necessarily want to display all currencies in an html element, but only the "most used" ones.
 * The structure is as follows:
 * {
 *  "common_currency_codes": [
 *      {
 *          "currency_code": "EUR"
 *      },
 *      {
 *          "currency_code": "USD"
 *      }
 *  ]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: CurrencyCodes}}
 *
 */
export const useCommonCurrencyCodes = () =>
  useRequest({
    path: `/properties/common-currencies`,
    translated: true,
  })
