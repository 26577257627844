import dayjs from 'dayjs'
import { useMemo } from 'react'

const dueDateApproachingThresholdDays = 7
const dueDateReachedThresholdDays = 0

export const dueDateWarningLevel = {
  none: 'none',
  approaching: 'approaching',
  reached: 'reached',
}

/**
 * @param {number} diffDays the number of days until the due date
 */
const getDueDateWarningLevel = (diffDays) => {
  if (
    diffDays === undefined ||
    diffDays === null ||
    isNaN(diffDays) ||
    diffDays > dueDateApproachingThresholdDays
  ) {
    return dueDateWarningLevel.none
  }
  if (diffDays > dueDateReachedThresholdDays) {
    return dueDateWarningLevel.approaching
  }
  return dueDateWarningLevel.reached
}

/**
 * @param {Date | string | undefined} dueDate
 * @return {{warningLevel: number, days: number}}
 */
export function useGetDueDateWarningInfo(currentDate, dueDate) {
  const nowJs = dayjs(currentDate).startOf('day')
  const dueDateJs = dayjs(new Date(dueDate)).startOf('day')
  const diffDays = dueDate && dueDateJs.diff(nowJs, 'day')

  return useMemo(() => {
    const warningLevel = getDueDateWarningLevel(diffDays)
    const days =
      warningLevel === dueDateWarningLevel.reached
        ? dueDateReachedThresholdDays - diffDays
        : diffDays
    return { warningLevel, days }
  }, [diffDays])
}
