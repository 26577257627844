import { CardHeader } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/sponsor/AsSponsorCard.module.css'
import AsSponsorCardItemSeparator from 'components/domains/business-partners/tile/sponsor/AsSponsorCardItemSeparator'
import AsSponsorCardOverview from 'components/domains/business-partners/tile/sponsor/AsSponsorCardOverview'
import AsSponsorCardTable from 'components/domains/business-partners/tile/sponsor/AsSponsorCardTable'
import Card from 'components/ui/card/Card'

const AsSponsorCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.sponsor',
  })

  return (
    <Card header={<CardHeader titleText={t('card.title')} />} className="cwp-card">
      <AsSponsorCardOverview />
      <AsSponsorCardItemSeparator />
      <div className={styles.tableWrapper}>
        <AsSponsorCardTable />
      </div>
    </Card>
  )
}

export default AsSponsorCard
