import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TableDescriptionSubcomponent from 'components/domains/conditions/overview/table-cells/view/TableDescriptionSubcomponent'
import useRequirementsCellAddMode from 'hooks/services/conditions/edit-mode/useRequirementsCellAddMode'
import useRequirementsCellEditMode from 'hooks/services/conditions/edit-mode/useRequirementsCellEditMode'
import { requirementDescriptionChanged } from 'redux/slices/conditions/requirementsTableSlice'

const RequirementsTableDescriptionSubcomponent = ({ requirementId, description }) => {
  const { isAddModeForCurrentRow } = useRequirementsCellAddMode({
    requirementId,
  })
  const { isEditModeForCurrentRow } = useRequirementsCellEditMode({
    requirementId,
  })
  const isEditMode = useMemo(
    () => isAddModeForCurrentRow || isEditModeForCurrentRow,
    [isAddModeForCurrentRow, isEditModeForCurrentRow],
  )

  const dispatch = useDispatch()

  const onInput = useCallback(
    (newDescription) => {
      dispatch(requirementDescriptionChanged({ description: newDescription }))
    },
    [dispatch],
  )

  const currentEditDescription = useSelector(
    (state) => state.conditions.requirementsTable.editedRow.currentValues?.description,
  )

  return (
    <TableDescriptionSubcomponent
      onInput={onInput}
      description={isEditMode ? currentEditDescription : description}
      isEditMode={isEditMode}
    />
  )
}

RequirementsTableDescriptionSubcomponent.propTypes = {
  description: PropTypes.string,
  requirementId: PropTypes.string,
}

export default RequirementsTableDescriptionSubcomponent
