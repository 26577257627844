import {
  Button,
  ButtonDesign,
  FlexBox,
  Modals,
  ToolbarSeparator,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import uniq from 'lodash/uniq'
import PropTypes from 'prop-types'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import {
  unstable_useBlocker as useBlocker,
  useBeforeUnload,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import {
  brandingFranchisePartnerExpandedContentKey,
  getEmptyRentalUnit,
  tenantExpandedContentKey,
} from 'components/domains/properties/getPropertyRentRollWorkingVersionReferenceData'
import PropertyRentRollWorkingVersionDelete from 'components/domains/properties/rent-roll/working-version/PropertyRentRollWorkingVersionDelete'
import PropertyRentRollWorkingVersionHeader from 'components/domains/properties/rent-roll/working-version/PropertyRentRollWorkingVersionHeader'
import PropertyRentRollWorkingVersionPageCreateSegmentsButton from 'components/domains/properties/rent-roll/working-version/PropertyRentRollWorkingVersionPageCreateSegmentsButton'
import PropertyRentRollWorkingVersionPublish from 'components/domains/properties/rent-roll/working-version/PropertyRentRollWorkingVersionPublish'
import PropertyRentRollWorkingVersionUnsavedChangesDialog from 'components/domains/properties/rent-roll/working-version/PropertyRentRollWorkingVersionUnsavedChangesDialog'
import PropertyRentRollLiveKpisCards from 'components/domains/properties/rent-roll/working-version/live-kpis/PropertyRentRollLiveKpisCards'
import PropertyRentalUnitsWorkingVersionTable from 'components/domains/properties/rent-roll/working-version/rental-units-table/PropertyRentalUnitsWorkingVersionTable'
import {
  appendValidationFieldsToRentalUnit,
  getHeaderValues,
  getRentalUnitsValues,
  getRentalUnitWorkingVersionRowKey,
} from 'components/domains/properties/rent-roll/working-version/utils/rentRollWorkingVersionMappers'
import PropertyRentRollWorkingVersionValidationResultsSummary from 'components/domains/properties/rent-roll/working-version/validation-results-summary/PropertyRentRollWorkingVersionValidationResultsSummary'
import LoadingButton from 'components/ui/button/LoadingButton'
import Card from 'components/ui/card/Card'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useRentRollWorkingVersionKpis from 'hooks/services/properties/kpis/useRentRollWorkingVersionKpis'
import { useDeriveAndSaveExcelValueMappingRules } from 'hooks/services/properties/rent-roll/working-version/excel-upload/useDeriveAndSaveExcelValueMappingRules'
import { useCwpBusinessPartnerRoles } from 'hooks/services/properties/rent-roll/working-version/useCwpBusinessPartnerRoles'
import useGetAllRentRollWorkingVersionOptions from 'hooks/services/properties/rent-roll/working-version/useGetAllRentRollWorkingVersionOptions'
import { useValidateRentRollWorkingVersion } from 'hooks/services/properties/rent-roll/working-version/useValidateRentRollWorkingVersion'
import useMultiplePropertiesSegments from 'hooks/services/properties/segments/useMultiplePropertiesSegments'
import useUpdateRentRollWorkingVersion from 'hooks/services/properties/useUpdateRentRollWorkingVersion'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'
import { PropertyContext } from 'routes/properties/PropertyContext'
import { PropertyPortfolioContext } from 'routes/properties/portfolio/PropertyPortfolioContext'

const emptyRentalUnit = getEmptyRentalUnit()

const getBusinessPartnerIdsFromRentalUnits = (rentalUnits) =>
  uniq(
    rentalUnits
      .map((rentalUnit) => [
        rentalUnit.tenant_id?.value,
        rentalUnit.branding_franchise_partner_id?.value,
      ])
      .flat()
      .filter(Boolean),
  )

const PropertyRentRollWorkingVersionPage = ({ propertyUuids, rentRollWorkingVersion }) => {
  const {
    header,
    rental_units: rentalUnits,
    auxiliary_fields: auxiliaryFields,
    uuid,
  } = rentRollWorkingVersion
  const businessPartnerIdsOfInitialWorkingVersion =
    getBusinessPartnerIdsFromRentalUnits(rentalUnits)

  const { data: existingBusinessPartners = {}, isLoading: isLoadingBusinessPartners } =
    useBusinessPartnerMiniByIds(businessPartnerIdsOfInitialWorkingVersion)

  const propertyContext = useContext(PropertyContext)
  const propertyPortfolioContext = useContext(PropertyPortfolioContext)
  const isNoRowSelectedState = useState(true)
  const [_isNoRowSelected, setIsNoRowSelected] = isNoRowSelectedState

  const isMultiProperty = !!propertyPortfolioContext

  const rentalUnitTableRef = useRef(null)
  const showToast = Modals.useShowToast()

  const { t } = useTranslation('translation')

  // If combined filters are applied, only filtered rows can be (de-)selected.
  // This causes the behavior, that selections are additive and more rows are selected than there should be.
  // To prevent this, all filters besides the selection are removed, the rows selected and the filters added back again.
  const filterRowsAndSelectFilteredRows = async (rowsToFilterBy) => {
    // Allows previously expanded rows with subcomponents to be expanded in their height when rendering them again after filtering
    delete rentalUnitTableRef?.current?.state?.subComponentsHeight
    const selectedRowKeys = rowsToFilterBy.map((row) => row.rowKey)
    const rowKeyFilter = { id: 'rowKey', value: selectedRowKeys.join(',') }
    let otherFilters = []
    await rentalUnitTableRef?.current?.setAllFilters((oldFilters) => {
      otherFilters = oldFilters?.filter((filter) => filter.id !== 'rowKey')
      return [rowKeyFilter]
    })
    await rentalUnitTableRef.current.toggleAllRowsSelected(true)
    rentalUnitTableRef?.current?.setAllFilters([...otherFilters, rowKeyFilter])
  }

  const selectAndFilterRowsByRowNumbers = async (rowNumbers) => {
    const selectedRows = rowNumbers
      .map((rowNumber) =>
        rentalUnitTableRef?.current?.data.find(
          (rentalUnit) => rentalUnit.rowKey === getRentalUnitWorkingVersionRowKey(rowNumber),
        ),
      )
      .filter(Boolean)
    setIsNoRowSelected(!selectedRows.length)
    if (rentalUnitTableRef?.current) {
      await rentalUnitTableRef.current.toggleAllRowsSelected(false)
      filterRowsAndSelectFilteredRows(selectedRows)
    }
  }

  const getAllowedOperations = (context) => context?.allowedOperations ?? []
  const allowedOperations = getAllowedOperations(
    isMultiProperty ? propertyPortfolioContext : propertyContext,
  )
  const isAllowedPropertyRentRollUpdate = allowedOperations.includes('PropertyRentroll_Update')
  const isAllowedPropertyRentRollWvUpdate = allowedOperations.includes('PropertyRentrollWv_Update')

  const {
    isLoading: isLoadingSegments,
    isError: isErrorSegments,
    data: segmentsData,
  } = useMultiplePropertiesSegments(propertyUuids)

  const segments = useMemo(
    () => (!isLoadingSegments && !isErrorSegments ? segmentsData?.segments : []),
    [isErrorSegments, isLoadingSegments, segmentsData?.segments],
  )

  const [headerValues, setHeaderValues] = useState(header)
  const [rentalUnitsValues, setRentalUnitsValues] = useState(rentalUnits)
  const [auxilirayFieldValues, setAuxiliaryFieldValues] = useState(auxiliaryFields)
  const [isSaveChangesLoading, setIsSaveChangesLoading] = useState(false)

  const {
    data: dataKpis,
    isError: isErrorKpis,
    isLoading: isLoadingKpis,
  } = useRentRollWorkingVersionKpis({
    header: getHeaderValues(headerValues),
    rental_units: getRentalUnitsValues(rentalUnitsValues),
    uuid,
    property_uuids: propertyUuids,
  })

  const liveKpis = !isErrorKpis && !isLoadingKpis ? dataKpis : {}

  const {
    validateRentalUnit,
    validateRentalUnits,
    validateHeader,
    validateAuxiliaryFields,
    getErrorsExist,
  } = useValidateRentRollWorkingVersion({
    header: headerValues,
    rental_units: rentalUnitsValues,
    auxiliary_fields: auxiliaryFields,
    property_uuids: propertyUuids,
  })

  const allOptions = useGetAllRentRollWorkingVersionOptions()
  const businessPartnerRoles = useCwpBusinessPartnerRoles()

  const getBusinessPartnerById = useCallback(
    (businessPartnerId) =>
      businessPartnerId
        ? existingBusinessPartners?.businessPartnerMinis?.find(
            (existingBusinessPartner) => existingBusinessPartner.id === businessPartnerId,
          )
        : undefined,
    [existingBusinessPartners],
  )

  const addBusinessPartnerInfoToRentalUnits = useCallback(
    (rentalUnitsWithoutBpInfos) =>
      rentalUnitsWithoutBpInfos.map((unit) => {
        const tenantId = unit['tenant_id'].value
        const businessPartnerTenant = getBusinessPartnerById(tenantId)
        const brandingFranchisePartnerId = unit['branding_franchise_partner_id'].value
        const businessPartnerBrandingFranchise = getBusinessPartnerById(brandingFranchisePartnerId)
        return {
          ...unit,
          [tenantExpandedContentKey]: { value: businessPartnerTenant },
          [brandingFranchisePartnerExpandedContentKey]: { value: businessPartnerBrandingFranchise },
        }
      }),
    [getBusinessPartnerById],
  )

  useEffect(() => {
    if (
      !allOptions.optionsAreLoading &&
      (!isLoadingBusinessPartners || !businessPartnerIdsOfInitialWorkingVersion.length) &&
      !businessPartnerRoles.isLoading
    ) {
      setRentalUnitsValues((oldRentalUnits) => {
        const rentalUnitsWithBps = addBusinessPartnerInfoToRentalUnits(oldRentalUnits)
        return validateRentalUnits(rentalUnitsWithBps)
      })
    }
    // Revalidate rental units, when all of the necessary data for validations are acquired
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOptions.optionsAreLoading, isLoadingBusinessPartners, businessPartnerRoles.isLoading])

  const [rentRollWorkingVersionModified, setRentRollWorkingVersionModified] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [errorDialogText, setErrorDialogText] = useState('')

  const queryClient = useQueryClient()

  const navigationBlocker = useBlocker(rentRollWorkingVersionModified && !isDeleting)
  const handleBeforeUnload = useCallback(
    (event) => {
      if (rentRollWorkingVersionModified) {
        // cross-browser way to intercept page unload, see:
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#browser_compatibility
        event.preventDefault()
        return (event.returnValue = '')
      }
    },
    [rentRollWorkingVersionModified],
  )

  useBeforeUnload(handleBeforeUnload)

  const saveRentRollWorkingVersion = useUpdateRentRollWorkingVersion({
    onSuccess: ({ property_uuids, header: updatedHeader }) => {
      queryClient.invalidateQueries(['rent-roll-working-version', ...property_uuids])
      showToast({ children: t('toast.changes-saved') })

      setRentRollWorkingVersionModified(false)
      if (updatedHeader.creator !== headerValues.creator.value) {
        setHeaderValues((previousHeaderValues) => ({
          ...previousHeaderValues,
          creator: {
            value: updatedHeader.creator,
            valueState: ValueState.None,
            valueStateMessage: '',
          },
        }))
      }
    },
    onSettled: () => {
      setIsSaveChangesLoading(false)
    },
  })

  const deriveAndSaveExcelValueMappingRules = useDeriveAndSaveExcelValueMappingRules({
    onSuccess: () =>
      queryClient.invalidateQueries([
        ...propertyUuids,
        'rent-roll',
        'working-version',
        'local-value-mapping',
      ]),
  })

  const { pathname, search } = useLocation()
  const navigate = useNavigate()
  const routeToRentRoll = pathname.replace('/working-version', `${search}#details`)

  const handleOnChangeHeaderValue = (key, value) => {
    const valueObj = {
      value: value,
      valueState: ValueState.None,
      valueStateMessage: '',
    }

    const validatedHeader = validateHeader({
      ...headerValues,
      [key]: valueObj,
    })

    setHeaderValues(validatedHeader)

    // dependent validation rules exist in rental units and must therefore be revalidated on updated state
    if (['key_date'].includes(key)) {
      setRentalUnitsValues((ruValues) => validateRentalUnits(ruValues, { header: validatedHeader }))
    }

    setRentRollWorkingVersionModified(true)
  }

  const updateRentalUnitsBulk = useCallback(
    (rentalUnitValues, updatedRentalUnits) => {
      const rentalUnitsIncludingUpdated = rentalUnitValues.map((rentalUnit) => {
        const updatedRentalUnit = updatedRentalUnits.find(
          (updatedUnit) => rentalUnit.row_number === updatedUnit.row_number,
        )
        return updatedRentalUnit || rentalUnit
      })
      return validateRentalUnits(rentalUnitsIncludingUpdated, {
        rental_units: rentalUnitsIncludingUpdated,
      })
    },
    [validateRentalUnits],
  )

  const getValueObject = (value, isInternallyValid) => ({
    value: value,
    valueState: ValueState.None,
    valueStateMessage: '',
    isInternallyValid,
  })
  const updateRentalUnit = (rentalUnitValues, rowKey, changes, isInternallyValid) => ({
    ...rentalUnitValues.find((row) => getRentalUnitWorkingVersionRowKey(row.row_number) === rowKey),
    ...Object.entries(changes).reduce(
      (updates, [contentKey, value]) =>
        Object.assign(updates, { [contentKey]: getValueObject(value, isInternallyValid) }),
      {},
    ),
  })

  const updateRentalUnits = (rentalUnitValues, rowKey, contentKey, value, isInternallyValid) => {
    const updatedRentalUnit = updateRentalUnit(
      rentalUnitValues,
      rowKey,
      { [contentKey]: value },
      isInternallyValid,
    )
    const validatedRentalUnit = validateRentalUnit(updatedRentalUnit)
    const updatedRentalUnits = rentalUnitValues.map((rentalUnit) =>
      getRentalUnitWorkingVersionRowKey(rentalUnit.row_number) === rowKey
        ? { ...validatedRentalUnit }
        : rentalUnit,
    )

    // uom and rental unit ID validation rules depend on other rows and requires revalidation on updated state
    if (
      ['rental_unit_area_uom_id', 'property_uuid', 'rental_unit_name', 'segment_uuid'].includes(
        contentKey,
      )
    ) {
      return validateRentalUnits(updatedRentalUnits, {
        rental_units: updatedRentalUnits,
      })
    }
    return updatedRentalUnits
  }

  const getSegmentByUuid = (segmentUuid) => segments.find((segment) => segment.uuid === segmentUuid)

  const handleSegmentUuidChange = (rowKey, value) => {
    const segment = getSegmentByUuid(value)
    if (segment) {
      setRentalUnitsValues((rentalUnitValues) =>
        updateRentalUnits(
          rentalUnitValues,
          rowKey,
          'segment_usage_type_id',
          segment.usage_type_code,
        ),
      )
    }
  }

  /**
   * Automatically select the correct segment when the usage type of a rental unit is changed and a segment exists.
   * Also takes the multi property case into consideration (segment does not fit to the property of the rental unit -> segment is not set)
   */
  const handleSegmentUsageTypeIdChange = (rowKey, value) => {
    const rentalUnit = rentalUnitsValues.find(
      (row) => getRentalUnitWorkingVersionRowKey(row.row_number) === rowKey,
    )
    const segmentsByUsageTypeIdAndProperty = segments.filter(
      (segment) =>
        segment.usage_type_code === value &&
        segment.property_uuid === rentalUnit?.property_uuid?.value,
    )
    let segmentUuid = null
    if (segmentsByUsageTypeIdAndProperty.length === 1) {
      const segmentPropertyUuid = segmentsByUsageTypeIdAndProperty[0].property_uuid
      const propertyAndSegmentMatch =
        !!segmentPropertyUuid &&
        rentalUnit?.property_uuid?.value === segmentsByUsageTypeIdAndProperty[0].property_uuid
      segmentUuid = propertyAndSegmentMatch ? segmentsByUsageTypeIdAndProperty[0].uuid : null
    }
    setRentalUnitsValues((rentalUnitValues) =>
      updateRentalUnits(rentalUnitValues, rowKey, 'segment_uuid', segmentUuid),
    )
  }

  /**
   * Reset the segment UUID when the property UUID was changed
   */
  const handlePropertyUuidChange = (rowKey) => {
    setRentalUnitsValues((rentalUnitValues) =>
      updateRentalUnits(rentalUnitValues, rowKey, 'segment_uuid', null),
    )
  }

  const handleSegmentRelatedChanges = (rowKey, contentKey, value) => {
    if (contentKey === 'segment_uuid') {
      handleSegmentUuidChange(rowKey, value)
    } else if (contentKey === 'segment_usage_type_id') {
      handleSegmentUsageTypeIdChange(rowKey, value)
    } else if (contentKey === 'property_uuid') {
      handlePropertyUuidChange(rowKey)
    }
  }
  const handleOnChangeRentalUnitValue = (rowKey, contentKey, value, isInternallyValid) => {
    handleSegmentRelatedChanges(rowKey, contentKey, value)
    setRentalUnitsValues((rentalUnitValues) =>
      updateRentalUnits(rentalUnitValues, rowKey, contentKey, value, isInternallyValid),
    )

    setRentRollWorkingVersionModified(true)
  }

  /**
   * When the mass edit update including segment changes OR property changes is executed, the segment uuid cannot simply be written to the rental unit.
   * In the multi property case, every property has it's own segments. Whenever the segments property uuid does not match the property uuid of the
   * corresponding row, the segment_uuid must be set to null for that row.
   */
  const updateRentalUnitWithMassEditSegmentOrPropertyChanges = (rowKey, changesToApply) => {
    const rentalUnit = rentalUnitsValues.find(
      (row) => getRentalUnitWorkingVersionRowKey(row.row_number) === rowKey,
    )
    // Get the segment that is applied on the updated row (either from changesToApply or the current segment of the rental unit)
    const updatedSegment = segments.find(
      (segment) => segment.uuid === changesToApply['segment_uuid'],
    )
    const currentSegment = segments.find(
      (segment) => segment.uuid === rentalUnit['segment_uuid']?.value,
    )
    const segmentPropertyUuid = updatedSegment?.property_uuid || currentSegment?.property_uuid

    // Get the property UUID that is applied on the updated row
    const propertyUuid = changesToApply['property_uuid'] || rentalUnit['property_uuid']?.value

    // Compare the updated propertyUuid with the updated segment. Reset the segment if they do not match.
    const propertyAndSegmentMatch = !!segmentPropertyUuid && propertyUuid === segmentPropertyUuid
    if (!propertyAndSegmentMatch) {
      return updateRentalUnit(rentalUnitsValues, rowKey, { ...changesToApply, segment_uuid: null })
    }
    return updateRentalUnit(rentalUnitsValues, rowKey, changesToApply)
  }

  const handleMassEditRentalUnitValues = ({ rowKeys, changeInstructions }) => {
    const changesToApply = changeInstructions.reduce(
      (changes, changeInstruction) =>
        Object.assign(changes, {
          [changeInstruction.contentKey]: changeInstruction.value,
        }),
      {},
    )
    const includesSegmentChanges = Object.keys(changesToApply).includes('segment_uuid')
    const includesPropertyChanges = Object.keys(changesToApply).includes('property_uuid')
    const updatedRentalUnits = rowKeys.map((rowKey) =>
      includesSegmentChanges || includesPropertyChanges
        ? updateRentalUnitWithMassEditSegmentOrPropertyChanges(rowKey, changesToApply)
        : updateRentalUnit(rentalUnitsValues, rowKey, changesToApply),
    )
    setRentalUnitsValues((previousValues) =>
      updateRentalUnitsBulk(previousValues, updatedRentalUnits),
    )
    setRentRollWorkingVersionModified(true)
  }

  const handleOnSegmentCreate = () => {
    queryClient.invalidateQueries(['segments', ...propertyUuids])
  }

  const getNewAuxiliaryFields = ({ rentalUnitsLength }) => ({
    ...auxilirayFieldValues,
    rental_units_length: {
      ...auxilirayFieldValues.rental_units_length,
      value: rentalUnitsLength,
    },
  })

  const handleAddTableRow = () => {
    const rentalUnitsAreEmpty = rentalUnitsValues.length === 0
    const maxRowNumber = Math.max(...rentalUnitsValues.map((row) => row.row_number))

    const rowNumber = rentalUnitsAreEmpty ? 1 : maxRowNumber + 1
    const newRentalUnit = isMultiProperty
      ? { ...emptyRentalUnit }
      : { ...emptyRentalUnit, property_uuid: propertyUuids?.[0] }
    setRentalUnitsValues((newRentalUnitsValues) => [
      ...newRentalUnitsValues,
      validateRentalUnit({
        ...appendValidationFieldsToRentalUnit(newRentalUnit),
        row_number: rowNumber,
      }),
    ])

    rentalUnitTableRef?.current?.setAllFilters([])
    setRentRollWorkingVersionModified(true)

    const newAuxiliaryFieldValues = getNewAuxiliaryFields({
      rentalUnitsLength: rentalUnitsValues.length + 1,
    })
    const validatedAuxiliaryFieldValues = validateAuxiliaryFields(newAuxiliaryFieldValues)
    setAuxiliaryFieldValues(validatedAuxiliaryFieldValues)
  }

  const handleDeleteTableRows = (rowNumberArray) => {
    if (rowNumberArray.length > 0) {
      setRentalUnitsValues([
        ...rentalUnitsValues.filter(
          (rentalUnit) => !rowNumberArray.includes(rentalUnit.row_number),
        ),
      ])
      setRentRollWorkingVersionModified(true)

      const newAuxiliaryFieldValues = getNewAuxiliaryFields({
        rentalUnitsLength: rentalUnitsValues.length - rowNumberArray.length,
      })
      const validatedAuxiliaryFieldValues = validateAuxiliaryFields(newAuxiliaryFieldValues)
      setAuxiliaryFieldValues(validatedAuxiliaryFieldValues)
    }
  }

  const handleSaveChanges = async () => {
    if (rentRollWorkingVersionModified) {
      const rentRollWorkingVersionToSave = {
        property_uuids: propertyUuids,
        uuid: uuid,
        header: getHeaderValues(headerValues),
        rental_units: getRentalUnitsValues(rentalUnitsValues),
      }
      setIsSaveChangesLoading(true)
      await Promise.all([
        saveRentRollWorkingVersion.mutateAsync({ ...rentRollWorkingVersionToSave }),
        deriveAndSaveExcelValueMappingRules.mutateAsync({
          rentRollWorkingVersion: rentRollWorkingVersionToSave,
        }),
      ])
    }
  }

  const handleCancel = () => {
    navigate(routeToRentRoll)
  }

  const renderSaveButton = () => (
    <LoadingButton
      id="rent-roll-working-version-save-changes-button"
      disabled={!rentRollWorkingVersionModified}
      onClick={() => handleSaveChanges()}
      design={ButtonDesign.Default}
      isLoading={isSaveChangesLoading}
      renderContent={() => <>{t('pages.property.rent-roll.button.save-changes')}</>}
    />
  )

  const renderAddSegmentsButton = () => (
    <PropertyRentRollWorkingVersionPageCreateSegmentsButton
      propertyUuids={propertyUuids}
      rentalUnitsValues={rentalUnitsValues}
      setRentalUnitsValues={setRentalUnitsValues}
      updateRentalUnits={updateRentalUnits}
    />
  )

  const renderCancelButton = () => (
    <Button
      id="rent-roll-working-version-cancel-button"
      onClick={() => handleCancel()}
      design={ButtonDesign.Transparent}
    >
      {t('buttons.cancel')}
    </Button>
  )

  const setErrorDialog = async (status, error) => {
    setIsErrorDialogOpen(status)
    const errorDetails = await formatHookError(error)
    setErrorDialogText(errorDetails)
  }

  const renderHeader = () => (
    <PropertyRentRollWorkingVersionHeader
      headerValues={headerValues}
      handleOnChange={handleOnChangeHeaderValue}
      properties={propertyPortfolioContext?.properties}
    >
      <PropertyRentRollWorkingVersionValidationResultsSummary
        headerValues={headerValues}
        rentalUnitsValues={rentalUnitsValues}
        auxiliaryFieldValues={auxilirayFieldValues}
        selectRows={selectAndFilterRowsByRowNumbers}
      />
      <ToolbarSeparator />
      {renderAddSegmentsButton()}
      <ToolbarSeparator />
      {isAllowedPropertyRentRollUpdate && (
        <PropertyRentRollWorkingVersionPublish
          segments={segments}
          rentRollWorkingVersion={{
            uuid: uuid,
            propertyUuids: propertyUuids,
            header: getHeaderValues(headerValues),
            rentalUnits: getRentalUnitsValues(rentalUnitsValues),
          }}
          handleSaveChanges={handleSaveChanges}
          setErrorDialog={setErrorDialog}
          disableButton={getErrorsExist()}
        />
      )}
      {renderSaveButton()}
      <PropertyRentRollWorkingVersionDelete
        propertyUuids={propertyUuids}
        setIsDeleting={setIsDeleting}
        isDeleting={isDeleting}
      />
      <ToolbarSeparator />
      {renderCancelButton()}
    </PropertyRentRollWorkingVersionHeader>
  )
  const renderTable = () => (
    <PropertyRentalUnitsWorkingVersionTable
      ref={rentalUnitTableRef}
      segments={{ segments: segments, isLoading: isLoadingSegments }}
      rentalUnitsValues={rentalUnitsValues}
      handleOnChange={handleOnChangeRentalUnitValue}
      handleAdd={handleAddTableRow}
      handleMassEdit={handleMassEditRentalUnitValues}
      handleDeleteRows={handleDeleteTableRows}
      onSegmentCreate={handleOnSegmentCreate}
      isMultiProperty={isMultiProperty}
      isNoRowSelectedState={isNoRowSelectedState}
    />
  )

  const renderCard = () => (
    <Card>
      {renderHeader()}
      {renderTable()}
    </Card>
  )

  return isAllowedPropertyRentRollWvUpdate ? (
    <>
      <PropertyRentRollLiveKpisCards
        kpis={liveKpis}
        isErrorLiveKpis={isErrorKpis}
        isLoadingLiveKpis={isLoadingKpis}
        propertyUuids={propertyUuids}
      />
      <FlexBox height="100%" width="100%" direction="Column" style={{ gap: '2em' }}>
        {renderCard()}
        {isErrorDialogOpen &&
          createPortal(
            <ErrorMessageBoxWithExpandableDetails
              messageSummary={t('pages.property.rent-roll.working-version.publish.error.title')}
              messageDetails={errorDialogText}
              isOpen={isErrorDialogOpen}
              onClose={() => setIsErrorDialogOpen(false)}
            />,
            document.body,
          )}
      </FlexBox>
      <PropertyRentRollWorkingVersionUnsavedChangesDialog
        isOpen={navigationBlocker.state === 'blocked'}
        onProceed={navigationBlocker.proceed}
        onCancel={navigationBlocker.reset}
        saveChanges={handleSaveChanges}
      />
    </>
  ) : (
    <CenteredIllustratedMessage
      name="UnableToLoad"
      size="Spot"
      titleText={t('components.cards.not-allowed.title')}
      subtitleText={t('components.cards.not-allowed.subtitle')}
    />
  )
}

const validationFieldPropTypeString = PropTypes.shape({
  value: PropTypes.string,
  valueState: PropTypes.oneOf(['None', 'Error', 'Warning']),
  valueStateMessage: PropTypes.string,
})

const validationFieldPropTypeNumber = PropTypes.shape({
  value: PropTypes.number,
  valueState: PropTypes.oneOf(['None', 'Error', 'Warning']),
  valueStateMessage: PropTypes.string,
})

PropertyRentRollWorkingVersionPage.propTypes = {
  propertyUuids: PropTypes.arrayOf(PropTypes.string.isRequired),
  rentRollWorkingVersion: PropTypes.shape({
    uuid: PropTypes.string,
    header: PropTypes.shape({
      keyDate: validationFieldPropTypeString,
      description: validationFieldPropTypeString,
      creator: validationFieldPropTypeString,
    }),
    auxiliary_fields: PropTypes.shape({
      rental_units_length: validationFieldPropTypeNumber,
    }),
    rental_units: PropTypes.arrayOf(
      PropTypes.shape({
        tenant_name: validationFieldPropTypeString,
        tenant_id: validationFieldPropTypeString,
        rental_unit_name: validationFieldPropTypeString,
        rental_unit_id: validationFieldPropTypeString,
        segment_usage_type: validationFieldPropTypeString,
        segment_name: validationFieldPropTypeString,
        rental_unit_area: validationFieldPropTypeNumber,
        rental_unit_area_uom: validationFieldPropTypeString,
        contracted_rent: validationFieldPropTypeNumber,
        rent_currency: validationFieldPropTypeString,
        rent_start_date: validationFieldPropTypeString,
        lease_expiry_date: validationFieldPropTypeString,
        lease_break_date: validationFieldPropTypeString,
        occupancy_status: validationFieldPropTypeString,
        lease_start_date: validationFieldPropTypeString,
      }),
    ),
  }),
}
export default PropertyRentRollWorkingVersionPage
