import { useCallback, useState } from 'react'

const emptyValuationRequest = {
  id: '',
  reason: '',
  propertyId: '',
  valuationType: '',
  dueDate: '',
  status: '',
  note: '',
  reportDate: '',
  valuer: '',
  valuationReport: {
    report: null,
    operation: '',
  },
}

const useValuationRequestsTableForm = () => {
  const [editValuation, setEditValuation] = useState(emptyValuationRequest)
  const [isDirty, setIsDirty] = useState(false)

  const reset = useCallback((valuationRequest) => {
    setEditValuation(
      valuationRequest
        ? {
            ...valuationRequest,
            valuationReport: {
              report: null,
              operation: '',
            },
          }
        : emptyValuationRequest,
    )
    setIsDirty(false)
  }, [])

  const patchValue = useCallback(
    (partialValue) => {
      setEditValuation({ ...editValuation, ...partialValue })
      setIsDirty(true)
    },
    [editValuation],
  )

  return {
    editValuation,
    isDirty,
    reset,
    patchValue,
  }
}

export default useValuationRequestsTableForm
