import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'

const requirementsDocumentTypeChanged = (state, { payload: { documentType: newDocumentType } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }

  state.editedRow.currentValues.documentType = newDocumentType
  if (isNil(state.editedRow.currentValues.documentType)) {
    state.editedRow.errorFields.documentType = true
  } else {
    delete state.editedRow.errorFields.documentType
  }

  if (newDocumentType === state.editedRow.initialValues.documentType) {
    delete state.editedRow.changedFields.documentType
    return
  }
  state.editedRow.changedFields.documentType = true
}

export default requirementsDocumentTypeChanged
