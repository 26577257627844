import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import DualUnitValue from 'components/domains/business-partners/dual-unit-value/DualUnitValue'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'

/**
 * Component to display a currency along an alternative currency (e.g. primaryCurrency: 'EUR', secondaryCurrency: 'USD')
 *
 * @param primaryValue the primary value
 * @param primaryCurrency the primary currency (e.g. 'EUR')
 * @param secondaryValue the optional secondary value
 * @param secondaryCurrency the optional secondary currency (e.g. 'EUR')
 * @param className a class name to add to the outermost element
 */
const DualCurrency = ({
  primaryValue,
  primaryCurrency,
  secondaryValue,
  secondaryCurrency,
  className,
}) => {
  const formatMoney = useCustomizableCurrencyFormatter()
  return (
    <DualUnitValue
      primaryValueWithUnit={formatMoney(primaryValue, primaryCurrency, {
        currencyDisplay: 'code',
      })}
      secondaryValueWithUnit={
        isNil(secondaryValue)
          ? undefined
          : formatMoney(secondaryValue, secondaryCurrency, {
              currencyDisplay: 'code',
            })
      }
      className={className}
    />
  )
}

DualCurrency.propTypes = {
  primaryValue: PropTypes.number.isRequired,
  primaryCurrency: PropTypes.string.isRequired,
  secondaryValue: PropTypes.number,
  secondaryCurrency: PropTypes.string,
  className: PropTypes.string,
}

export default DualCurrency
