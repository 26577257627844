import { MessageStrip, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import { isUndefined } from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/non-ref-credit-decision/involved-parties/manual-checksheet/NonRefCreditDecisionManualCheckSheetResultMessageStrip.module.css'
import { nonRefCreditDecisionManualQuestionnaireSchema } from 'components/domains/business-events-and-tasks/decision-paper/tiles/non-ref-credit-decision/involved-parties/manual-checksheet/nonRefCreditDecisionManualQuestionnaireSchema'

const NonRefCreditDecisionManualCheckSheetResultMessageStrip = ({
  id,
  selectedBorrowerId,
  currentData,
  isEditMode,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.non-ref-credit-decision.involved-parties.checksheet.message-strip',
  })

  const currentBorrowerData =
    currentData?.find((borrower) => borrower.borrowerId === selectedBorrowerId) ?? {}
  const { success: isCompletedQuestionnaire } =
    nonRefCreditDecisionManualQuestionnaireSchema.safeParse(currentBorrowerData.answers || {})

  return (
    selectedBorrowerId &&
    !isEditMode &&
    !isUndefined(currentBorrowerData) && (
      <div className={styles.messageStripWrapper}>
        <MessageStrip
          id={id}
          design={
            isCompletedQuestionnaire ? MessageStripDesign.Positive : MessageStripDesign.Warning
          }
          hideCloseButton
        >
          <span>{isCompletedQuestionnaire ? t('complete') : t('not-complete')}</span>
        </MessageStrip>
      </div>
    )
  )
}

NonRefCreditDecisionManualCheckSheetResultMessageStrip.propTypes = {
  id: PropTypes.string.isRequired,
  selectedBorrowerId: PropTypes.string,
  currentData: PropTypes.arrayOf(
    PropTypes.shape({
      borrowerId: PropTypes.string.isRequired,
      borrowerName: PropTypes.string,
      answers: PropTypes.shape({}),
    }),
  ),
  isEditMode: PropTypes.bool,
}

export default NonRefCreditDecisionManualCheckSheetResultMessageStrip
