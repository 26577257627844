export const brandingFranchisePartnerExpandedContentKey = 'branding_franchise_partner_expanded'
export const tenantExpandedContentKey = 'tenant_expanded'

const emptyRentalUnit = {
  property_id: null,
  property_uuid: null,
  tenant_id: null,
  tenant_name: null,
  rental_unit_id: null,
  rental_unit_name: null,
  anonymous_tenant: false,
  rent_roll_id: null,
  rental_unit_currency_id: null,
  segment_uuid: null,
  segment_usage_type_id: null,
  rental_unit_area: null,
  rental_unit_area_uom_id: null,
  occupancy_status_id: null,
  current_net_rent: null,
  no_lease_expiry: false,
  comment: null,
  branding_franchise_partner_name: null,
  branding_franchise_partner_id: null,
  number_of_units: null,
  rent_arrears: null,
  rent_dispute: null,
  auxiliary_column1: null,
  auxiliary_column2: null,
  auxiliary_column3: null,
  auxiliary_column4: null,
  rent_contracted_year: null,
  lease_start_date: null,
  rent_start_date: null,
  lease_break_date: null,
  lease_expiry_date: null,
  [tenantExpandedContentKey]: undefined,
  [brandingFranchisePartnerExpandedContentKey]: undefined,
}

export const getEmptyRentRollWorkingVersion = () => ({
  property_uuid: '',
  header: {
    key_date: null,
    description: null,
    creator: null,
  },
  rental_units: [],
})

export const getEmptyRentalUnit = () => emptyRentalUnit

export const getPropertyRentRollHeaderContentDefinitions = (t) => [
  {
    title: t('key-date'),
    contentKey: 'key_date',
    dataType: 'String',
  },
  {
    title: t('description'),
    contentKey: 'description',
    dataType: 'String',
  },
  {
    title: t('creator'),
    contentKey: 'creator',
    dataType: 'String',
  },
]

export const getPropertyRentRollAuxiliaryFieldsContentDefinitions = (t) => [
  {
    title: t('rental-units-length'),
    contentKey: 'rental_units_length',
    dataType: 'Number',
  },
]

const getAdditionalColumnMappingContentDefinitions = (t) => [
  {
    title: t('rent-contracted-year.label'),
    contentKey: 'rent_contracted_month',
    dataType: 'Boolean',
  },
  {
    title: t('rent-current-year.label'),
    contentKey: 'current_net_rent_month',
    dataType: 'Boolean',
  },
]

const getPropertyRentalUnitsTableContentDefinitions = (t, isMultiProperty = false) => {
  const multiPropertyContentDefinitions = isMultiProperty
    ? [
        {
          title: t('property.label'),
          contentKey: 'property_uuid',
          dataType: 'String',
        },
        {
          title: t('property-id.label'),
          contentKey: 'property_id',
          dataType: 'String',
        },
      ]
    : []
  return [
    ...multiPropertyContentDefinitions,
    {
      title: t('occupancy-status.label'),
      contentKey: 'occupancy_status_id',
      dataType: 'String',
    },
    {
      title: t('tenant-name.label'),
      contentKey: 'tenant_name',
      dataType: 'String',
    },
    {
      title: t('tenant-id.label'),
      contentKey: 'tenant_id',
      dataType: 'String',
    },
    {
      title: t('rental-unit-name.label'),
      contentKey: 'rental_unit_name',
      dataType: 'String',
    },
    {
      title: t('rental-unit-id.label'),
      contentKey: 'rental_unit_id',
      dataType: 'String',
    },
    {
      title: t('segment-usage-type.label'),
      contentKey: 'segment_usage_type_id',
      dataType: 'String',
    },
    {
      title: t('segment.label'),
      contentKey: 'segment_uuid',
      dataType: 'String',
    },
    {
      title: t('rental-unit-area.label'),
      contentKey: 'rental_unit_area',
      dataType: 'Decimal',
    },
    {
      title: t('rental-unit-area-unit-of-measure.label'),
      contentKey: 'rental_unit_area_uom_id',
      dataType: 'String',
    },
    {
      title: t('rent-contracted-year.label'),
      contentKey: 'rent_contracted_year',
      dataType: 'Decimal',
    },
    {
      title: t('rent-contracted-year-currency.label'),
      contentKey: 'rental_unit_currency_id',
      dataType: 'String',
    },
    {
      title: t('rent-current-year.label'),
      contentKey: 'current_net_rent',
      dataType: 'Decimal',
    },
    {
      title: t('rent-start-date.label'),
      contentKey: 'rent_start_date',
      dataType: 'Date',
    },
    {
      title: t('lease-start-date.label'),
      contentKey: 'lease_start_date',
      dataType: 'Date',
    },
    {
      title: t('lease-expiry-date.label'),
      contentKey: 'lease_expiry_date',
      dataType: 'Date',
    },
    {
      title: t('lease-break-date.label'),
      contentKey: 'lease_break_date',
      dataType: 'Date',
    },
    {
      title: t('no-lease-expiry.label'),
      contentKey: 'no_lease_expiry',
      dataType: 'Boolean',
    },
    {
      title: t('anonymous-tenant.label'),
      contentKey: 'anonymous_tenant',
      dataType: 'Boolean',
    },
    {
      title: t('branding-franchise-name.label'),
      contentKey: 'branding_franchise_partner_name',
      dataType: 'String',
    },
    {
      title: t('branding-franchise-id.label'),
      contentKey: 'branding_franchise_partner_id',
      dataType: 'String',
    },
    {
      title: t('number-of-units.label'),
      contentKey: 'number_of_units',
      dataType: 'Number',
    },
    {
      title: t('rent-arrears.label'),
      contentKey: 'rent_arrears',
      dataType: 'Decimal',
    },
    {
      title: t('rent-dispute.label'),
      contentKey: 'rent_dispute',
      dataType: 'Boolean',
    },
    {
      title: t('lease-comment.label'),
      contentKey: 'comment',
      dataType: 'String',
    },
    {
      title: t('auxiliary-column1.label'),
      contentKey: 'auxiliary_column1',
      dataType: 'String',
    },
    {
      title: t('auxiliary-column2.label'),
      contentKey: 'auxiliary_column2',
      dataType: 'String',
    },
    {
      title: t('auxiliary-column3.label'),
      contentKey: 'auxiliary_column3',
      dataType: 'String',
    },
    {
      title: t('auxiliary-column4.label'),
      contentKey: 'auxiliary_column4',
      dataType: 'String',
    },
  ]
}

/**
 *
 * @param {Array} tAllMappingLanguages up to two language tokens, by which the titles shall be translated
 * @param {Boolean} isMultiProperty
 * @returns {Array} columnDefinitions
 */
export const getExcelUploadContentDefinitions = (tAllMappingLanguages, isMultiProperty) => {
  const accumulateDifferentLanguageTitlesFromContentDefinitions = (
    contentDefinitions,
    accumulator,
  ) => {
    contentDefinitions.forEach((definition) => {
      const indexOfContentDefinition = accumulator.findIndex(
        (columnDefinition) => columnDefinition.contentKey === definition.contentKey,
      )
      if (indexOfContentDefinition < 0) {
        accumulator.push(definition)
      } else {
        accumulator[indexOfContentDefinition] = {
          ...accumulator[indexOfContentDefinition],
          secondTitle: definition.title,
        }
      }
    })
  }
  const columnDefinitions = tAllMappingLanguages.reduce((accumulator, t) => {
    accumulateDifferentLanguageTitlesFromContentDefinitions(
      getPropertyRentalUnitsTableContentDefinitions(t, isMultiProperty),
      accumulator,
    )
    accumulateDifferentLanguageTitlesFromContentDefinitions(
      getAdditionalColumnMappingContentDefinitions(t),
      accumulator,
    )
    return accumulator
  }, [])
  return columnDefinitions
}

export const getPropertyRentalUnitsTableContentDefinitionsWithoutIDFields = (t) =>
  getPropertyRentalUnitsTableContentDefinitions(t).filter(
    (contentDef) =>
      contentDef.contentKey !== 'tenant_id' && contentDef.contentKey !== 'rental_unit_id',
  )

export default getPropertyRentalUnitsTableContentDefinitions
