import { Link } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import useConditionsCellEditMode from 'hooks/services/conditions/edit-mode/useConditionsCellEditMode'
import { openConditionCommentDialog } from 'redux/slices/conditions/conditionsTableSlice'

const ConditionsTableCommentSubcomponent = ({ numberOfComments, conditionId, conditionType }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ui.comment.buttons',
  })
  const dispatch = useDispatch()
  const { isEditModeForAnyRow } = useConditionsCellEditMode({
    conditionId,
  })

  const onCommentButtonClicked = useCallback(() => {
    dispatch(openConditionCommentDialog({ conditionId, conditionType }))
  }, [conditionId, conditionType, dispatch])

  return (
    <Link onClick={onCommentButtonClicked} disabled={isEditModeForAnyRow}>
      {t('open-dialog', { commentCount: numberOfComments })}
    </Link>
  )
}

ConditionsTableCommentSubcomponent.propTypes = {
  numberOfComments: PropTypes.number.isRequired,
  conditionId: PropTypes.string.isRequired,
  conditionType: PropTypes.string.isRequired,
}

export default ConditionsTableCommentSubcomponent
