import { useMutation, useQueryClient } from '@tanstack/react-query'
import camelize from 'camelize'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const removeDataPrefixFromBase64Content = (readerResult) =>
  readerResult.result.replace('data:', '').replace(/^.+,/, '')

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = (error) => reject(error)
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(removeDataPrefixFromBase64Content(reader))
    }
  })

export const useDocumentVersionUpload = ({
  onSuccess: onSuccessCallback = () => {},
  documentId,
  ...mutateOptions
} = {}) => {
  const { post } = useAccessTokenRequest()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ comment, file, version }) => {
      const content = await toBase64(file)
      const { data } = await post({
        path: `/documents/versions`,
        body: {
          comment,
          document_base64: content,
          document_id: documentId,
          file_name: file.name,
          version,
        },
      })

      return { data: camelize(data) }
    },
    onSuccess: ({ data }, ...rest) => {
      queryClient.invalidateQueries(['documents', documentId])
      onSuccessCallback(data, ...rest)
    },
    ...mutateOptions,
  })
}
