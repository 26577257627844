import conditionRowHasError from 'redux/slices/conditions/actions/condition-creation/conditionRowHasError'
import requirementRowHasError from 'redux/slices/conditions/actions/condition-creation/requirementRowHasError'
import {
  increaseAllSelectionsBelowConditionIndex,
  increaseConditionIndexOfCurrentErrorRowsAfterConditionIndex,
} from 'redux/slices/conditions/actions/helper/conditionsActionHelper'
import { nextDuplicatedName } from 'utils/nextDuplicatedName'

const copyCondition = (state, { payload: conditionIndex }) => {
  if (!state.conditions[conditionIndex]) {
    return
  }
  const newCondition = {
    ...state.conditions[conditionIndex],
    requirements: [...state.conditions[conditionIndex].requirements],
  }
  const conditionNames = state.conditions.map(({ name }) => name)
  newCondition.name = nextDuplicatedName(conditionNames, newCondition.name)
  state.conditions.splice(conditionIndex, 0, newCondition)
  state.selectedRows = increaseAllSelectionsBelowConditionIndex({
    selectedRows: state.selectedRows,
    conditionIndex,
  })

  state.errorRows = increaseConditionIndexOfCurrentErrorRowsAfterConditionIndex({
    errorRows: state.errorRows,
    conditionIndex,
  })
  if (newCondition.name === '') {
    conditionRowHasError(state, {
      payload: { conditionIndex, field: 'name', error: { warning: false } },
    })
  }
  if (!newCondition.type) {
    conditionRowHasError(state, {
      payload: { conditionIndex, field: 'type', error: { warning: false } },
    })
  }
  newCondition.requirements.forEach(({ name, documentType }, requirementIndex) => {
    if (name === '') {
      requirementRowHasError(state, {
        payload: { conditionIndex, requirementIndex, field: 'name', error: { warning: false } },
      })
    }
    if (!documentType) {
      requirementRowHasError(state, {
        payload: {
          conditionIndex,
          requirementIndex,
          field: 'documentType',
          error: { warning: false },
        },
      })
      return
    }
    if (!newCondition.possibleDocumentTypes.includes(documentType)) {
      requirementRowHasError(state, {
        payload: {
          conditionIndex,
          requirementIndex,
          field: 'documentType',
          error: { warning: true },
        },
      })
    }
  })
}

export default copyCondition
