import { Link, LinkDesign, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MessageBoxWithExpandableDetails from 'components/ui/dialog/MessageBoxWithExpandableDetails'
import CloseableMessageStrip from 'components/ui/feedback/CloseableMessageStrip'

/**
 * Ui5CustomEvent<LinkDomRef, LinkClickEventDetail>
 * @typedef {import('@fioneer/ui5-webcomponents-react')} MessageStripDomRef
 * @typedef {import('@fioneer/ui5-webcomponents-react').LinkPropTypes['onClick']} OnShowDetailsHandler
 * @typedef {import('@fioneer/ui5-webcomponents-react').MessageStripPropTypes} MessageStripPropTypes
 *
 * @typedef {object} CustomProps
 * @property {import('react').ReactElement} [message]
 * @property {string} [messageBoxSummary]
 * @property {string | import('react').ReactNode} [messageBoxDetails]
 * @property {OnShowDetailsHandler} [onShowDetails]
 *
 * @param {Omit<MessageStripPropTypes, keyof CustomProps> & CustomProps} props
 **/
const ErrorMessageStripWithDetails = ({
  message,
  messageBoxSummary,
  messageBoxDetails,
  onShowDetails,
  design = MessageStripDesign.Warning,
  ...messageStripProps
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.ui.errors' })
  const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false)
  const closeMessageBox = useCallback(() => {
    setIsMessageBoxOpen(false)
  }, [])

  const showErrorDetails = useCallback(
    (event) => {
      onShowDetails?.(event)
      setIsMessageBoxOpen(true)
    },
    [onShowDetails],
  )
  return (
    <>
      <CloseableMessageStrip design={design} {...messageStripProps}>
        {message}{' '}
        <Link design={LinkDesign.Default} onClick={showErrorDetails}>
          {t('learn-more')}
        </Link>
      </CloseableMessageStrip>
      <MessageBoxWithExpandableDetails
        isOpen={isMessageBoxOpen}
        onClose={closeMessageBox}
        messageSummary={messageBoxSummary}
        messageDetails={messageBoxDetails}
      />
    </>
  )
}

ErrorMessageStripWithDetails.propTypes = {
  message: PropTypes.elementType.isRequired,
  messageBoxSummary: PropTypes.string.isRequired,
  messageBoxDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onShowDetails: PropTypes.func,
  design: PropTypes.oneOf(Object.values(MessageStripDesign)),
}

export default ErrorMessageStripWithDetails
