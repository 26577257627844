import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const RETRIES = 3

export const useMarketMacroProxies = ({ marketId }) => {
  const params = new URLSearchParams([['market_id', marketId]])

  return useCamelizedResponse(
    useRequest({
      path: `/property-monitoring/proxies/macros?${params}`,
      useCache: true,
      keys: ['property-monitoring', 'proxies', 'macros', marketId],
      options: {
        retry: (failureCount, error) => {
          if (isNotFoundError(error) || isMissingPermissionError(error)) {
            return false
          }
          return failureCount < RETRIES
        },
      },
    }),
  )
}
