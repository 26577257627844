import isNil from 'lodash.isnil'
import uniq from 'lodash.uniq'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FurtherDetailsPieChartsCard, {
  chartsWithValuations,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/FurtherDetailsPieChartsCard'
import {
  TenantColorProvider,
  useTenantColors,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/TenantColorContext'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/card/EmptyCardContent'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const MultipleFurtherDetailsGranularityCityOrCountryTile = ({
  tileId,
  isPdfView,
  selectedDealIndex,
}) => {
  const dispatch = useDispatch()

  const {
    data: {
      multiplePropertyGranularityPieChartData = [],
      sourcePaths = [],
      sourceRender,
      dealsData,
    },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const selectedDealDisplayId = dealsData[selectedDealIndex]?.dealDisplayId

  useEffect(() => {
    dispatch(setSourceRender({ tileId, sourceRender: { path: sourcePaths?.[selectedDealIndex] } }))
  }, [dispatch, selectedDealDisplayId, selectedDealIndex, sourcePaths, tileId])

  useEffect(() => {
    if (isNil(sourceRender)) {
      dispatch(
        setSourceRender({ tileId, sourceRender: { path: sourcePaths?.[selectedDealIndex] } }),
      )
    }
  }, [dispatch, selectedDealDisplayId, selectedDealIndex, sourcePaths, sourceRender, tileId])

  const {
    multiPropertyKpisByGranularity,
    multiPropertyValuationsByGranularity,
    properties = [],
    isMultiProperty,
    noProperties,
  } = useMemo(() => {
    const propertyGranularityPieChartData =
      multiplePropertyGranularityPieChartData?.[selectedDealIndex] ?? {}

    return {
      multiPropertyKpisByGranularity:
        propertyGranularityPieChartData?.multiPropertyKpisByGranularity
          ?.keyDateToMultiPropertyRentRollKpis?.[0]?.kpis,
      multiPropertyValuationsByGranularity:
        propertyGranularityPieChartData?.multiPropertyValuationsByGranularity,
      isMultiProperty: propertyGranularityPieChartData?.isMultiProperty,
      properties: propertyGranularityPieChartData?.properties,
      noProperties: !propertyGranularityPieChartData?.properties,
    }
  }, [multiplePropertyGranularityPieChartData, selectedDealIndex])

  const hasPropertiesInDifferentCountries = useMemo(
    () => uniq(properties?.map((property) => property.address.countryCode)).length > 1,
    [properties],
  )

  const valueCategory = hasPropertiesInDifferentCountries ? 'country' : 'city'
  const nameCategory = hasPropertiesInDifferentCountries ? 'countryName' : 'city'

  const getRentRollKpis = (category) => (multiPropertyKpis) =>
    multiPropertyKpis?.[`${category}RentRollKpis`] ?? []

  const getValuations = (category) => (multiPropertyValuations) =>
    multiPropertyValuations?.granularities?.[category] ?? []

  const getName = (category) => (rentRollKpi) => rentRollKpi?.[category]

  if (noProperties) {
    return <NoPropertyAssignedToDealInfo />
  }
  if (!isMultiProperty) {
    return <EmptyCardContent />
  }
  return (
    <FurtherDetailsPieChartsCard
      colorProvider={TenantColorProvider}
      useColors={useTenantColors}
      getRentRollKpis={getRentRollKpis(valueCategory)}
      getValuations={getValuations(valueCategory)}
      getNameFromKpi={getName(nameCategory)}
      granularityValues={{ multiPropertyKpisByGranularity, multiPropertyValuationsByGranularity }}
      chartOptions={chartsWithValuations}
      isPdfView={isPdfView}
    />
  )
}

MultipleFurtherDetailsGranularityCityOrCountryTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultipleFurtherDetailsGranularityCityOrCountryTile
