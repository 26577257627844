import { Button } from '@fioneer/ui5-webcomponents-react'
import isEqual from 'lodash.isequal'
import PropType from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMultiPropertyViewByPropertyUuids } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuids'
import useRentRollWorkingVersions from 'hooks/services/properties/useRentRollWorkingVersions'
import { PORTFOLIO_VIEW_QUERY_PARAM } from 'routes/properties/portfolio/PropertyPortfolioWrapper'

const PropertyRentRollWorkingVersionEdit = ({ properties }) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const navigate = useNavigate()
  const propertyUuids = properties?.map((property) => property.uuid).sort() ?? []
  const assertSinglePropertyPage = propertyUuids.length === 1

  const routeToPortfolioWorkingVersionPrefix = '/properties/portfolio/rent-roll/working-version?'

  const routeToWorkingVersionPrefix = pathname + '/working-version'
  const routeToWorkingVersionSuffix = propertyUuids.length > 1 ? search : ''
  const [routeToWorkingVersion, setRouteToWorkingVersion] = useState(
    routeToWorkingVersionPrefix + routeToWorkingVersionSuffix,
  )

  const { data: rentRollWorkingVersionsReturned } = useRentRollWorkingVersions(propertyUuids)

  const rentRollWorkingVersions = rentRollWorkingVersionsReturned ?? []
  const propertyUuidsInWorkingVersions = rentRollWorkingVersions?.[0]?.property_uuids?.sort() ?? []

  // there can be only one working version for a set of properties
  const assertWorkingVersionsExistAndDistinct = rentRollWorkingVersions.length === 1
  const assertPropertyUuidsAreEqual = isEqual(propertyUuids, propertyUuidsInWorkingVersions)
  const assertMultiPropertyWorkingVersion = propertyUuidsInWorkingVersions.length > 1

  const { data: multiPropertyView, refetch: refetchMultiPropertyView } =
    useMultiPropertyViewByPropertyUuids(propertyUuidsInWorkingVersions, { enabled: false })

  const getMultiPropertyWorkingVersionRoute = (multiPropertyViewUuid) => {
    const searchParams = new URLSearchParams()
    searchParams.append(PORTFOLIO_VIEW_QUERY_PARAM, multiPropertyViewUuid)
    return searchParams.toString()
  }

  const getMultiPropertyViewUuid = (mpv) => mpv.data.uuid

  useEffect(() => {
    if (assertWorkingVersionsExistAndDistinct && assertPropertyUuidsAreEqual) {
      setIsButtonDisabled(false)
    }
  }, [assertWorkingVersionsExistAndDistinct, assertPropertyUuidsAreEqual])

  useEffect(() => {
    if (assertMultiPropertyWorkingVersion) {
      refetchMultiPropertyView()
    }
  }, [assertMultiPropertyWorkingVersion, refetchMultiPropertyView])

  useEffect(() => {
    if (assertSinglePropertyPage && assertMultiPropertyWorkingVersion && multiPropertyView) {
      const qs = getMultiPropertyWorkingVersionRoute(getMultiPropertyViewUuid(multiPropertyView))
      setRouteToWorkingVersion(routeToPortfolioWorkingVersionPrefix + qs)
      setIsButtonDisabled(false)
    }
  }, [assertMultiPropertyWorkingVersion, assertSinglePropertyPage, multiPropertyView])

  const handleEditRentRollWorkingVersion = () => {
    assertMultiPropertyWorkingVersion
      ? window.open(routeToWorkingVersion, '_blank')
      : navigate(routeToWorkingVersion)
  }

  return (
    <Button
      id="rent-roll-working-version-edit-button"
      onClick={() => handleEditRentRollWorkingVersion()}
      disabled={isButtonDisabled}
    >
      {t('pages.rent-roll-working-version.edit-button')}
    </Button>
  )
}

PropertyRentRollWorkingVersionEdit.propTypes = {
  properties: PropType.array.isRequired,
}

export default PropertyRentRollWorkingVersionEdit
