import {
  Icon,
  IconDesign,
  Input,
  Label,
  Option,
  Select,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { propertyMonitoringCriticalMovementDirection } from 'api/property/monitoring/propertyMonitoringConfiguration'
import { criticalMovementIconName } from 'components/domains/markets/detail/cards/helper/marketProxiesHelper'

const hyphen = '-'
const directions = [
  propertyMonitoringCriticalMovementDirection.up,
  propertyMonitoringCriticalMovementDirection.down,
]

const MarketProxiesTableDirectionCell = ({ isEditing, onChange, proxySetting, errorState }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.markets.detail.manager.market-proxies',
  })
  const isOverride = proxySetting.isOverride
  const defaultMovementDirection = proxySetting.defaultProxy?.direction
  const isMonitored = proxySetting.overrideProxy?.isMonitored ?? true
  const movementDirection =
    isOverride && proxySetting.overrideProxy
      ? proxySetting.overrideProxy.direction
      : defaultMovementDirection

  const handleSelectionChange = useCallback(
    ({ target: { selectedOption } }) => {
      onChange(proxySetting.key, selectedOption.getAttribute('data-direction'))
    },
    [onChange, proxySetting.key],
  )

  const directionOptions = useMemo(() => {
    const options = directions.map((direction) => (
      <Option key={direction} selected={direction === movementDirection} data-direction={direction}>
        {t(`table.direction.${direction}`)}
      </Option>
    ))
    if (isNil(movementDirection)) {
      return [<Option key={'empty-option'} selected />, ...options]
    }
    return options
  }, [movementDirection, t])

  if (!isEditing) {
    return movementDirection ? (
      <Icon design={IconDesign.Neutral} name={criticalMovementIconName[movementDirection]} />
    ) : (
      <Text>{hyphen}</Text>
    )
  }

  if (!isOverride || !isMonitored) {
    return (
      <Input
        readonly
        value={defaultMovementDirection ? t(`table.direction.${defaultMovementDirection}`) : hyphen}
      />
    )
  }

  const valueState = errorState.hasError && errorState.touched ? ValueState.Error : ValueState.None
  return (
    <>
      <Select
        valueState={valueState}
        valueStateMessage={<Text>{t('error-messages.direction')}</Text>}
        onBlur={handleSelectionChange}
        onChange={handleSelectionChange}
      >
        {directionOptions}
      </Select>
      <br />
      <Label showColon>{t('default-value')}</Label>{' '}
      <Text>
        {defaultMovementDirection ? t(`table.direction.${defaultMovementDirection}`) : hyphen}
      </Text>
    </>
  )
}

MarketProxiesTableDirectionCell.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  proxySetting: PropTypes.shape({
    key: PropTypes.string.isRequired,
    isOverride: PropTypes.bool.isRequired,
    defaultProxy: PropTypes.shape({
      direction: PropTypes.oneOf(Object.values(propertyMonitoringCriticalMovementDirection)),
    }),
    overrideProxy: PropTypes.shape({
      direction: PropTypes.oneOf(Object.values(propertyMonitoringCriticalMovementDirection)),
      isMonitored: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  errorState: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
  }).isRequired,
}

export default MarketProxiesTableDirectionCell
