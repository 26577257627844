import { useMemo } from 'react'

const equity = 'Equity'

const reduceTotalAmount = (
  result,
  {
    headquarter: { value: headquarterValue = 0 } = {},
    original: { value: originalValue = 0 } = {},
  },
) => ({
  headquarter: result.headquarter + headquarterValue,
  original: result.original + originalValue,
})

const useEquityCalculation = ({
  mappedTranchesValues,
  mappedSyndicationValues,
  mappedOtherSources,
  totalSourcesAmount,
  totalSourcesAmountHeadquarter,
  headquarterCurrency,
  availableCurrencies,
  areAllCurrenciesTheSame,
}) => {
  const totalSourcesAmountForEquity = useMemo(() => {
    const initialValue = {
      original: 0,
      headquarter: 0,
    }
    let result = {
      original: 0,
      headquarter: 0,
    }
    if (mappedTranchesValues) {
      result = {
        original: result.original + mappedTranchesValues.original.value,
        headquarter: result.headquarter + mappedTranchesValues.headquarter.value,
      }
    }

    const syndicationSum =
      mappedSyndicationValues?.reduce(reduceTotalAmount, initialValue) || initialValue
    const otherSourcesSum =
      mappedOtherSources?.reduce(reduceTotalAmount, initialValue) || initialValue
    result = {
      original: result.original + syndicationSum.original + otherSourcesSum.original,
      headquarter: result.headquarter + syndicationSum.headquarter + otherSourcesSum.headquarter,
    }

    return result
  }, [mappedTranchesValues, mappedSyndicationValues, mappedOtherSources])

  const mappedEquity = useMemo(() => {
    const resultOriginal = {
      value: totalSourcesAmount - totalSourcesAmountForEquity.original,
      currency: availableCurrencies[0],
    }
    const resultHeadquarter = {
      value: totalSourcesAmountHeadquarter - totalSourcesAmountForEquity.headquarter,
      currency: headquarterCurrency,
    }

    if (areAllCurrenciesTheSame) {
      return {
        name: equity,
        original: resultOriginal,
        headquarter: resultOriginal,
      }
    }
    return {
      name: equity,
      original: resultHeadquarter,
      headquarter: resultHeadquarter,
    }
  }, [
    totalSourcesAmount,
    totalSourcesAmountForEquity.original,
    totalSourcesAmountForEquity.headquarter,
    availableCurrencies,
    totalSourcesAmountHeadquarter,
    headquarterCurrency,
    areAllCurrenciesTheSame,
  ])

  return useMemo(
    () => ({
      mappedEquity,
    }),
    [mappedEquity],
  )
}

export default useEquityCalculation
