import { Button, Dialog, Title, Bar, FlexBox, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ExcelFileUploaderButton from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollExcelFileUploaderButton'
import { ExcelUploadContext } from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollExcelUploadContext'

const testId = (id) => (process.env.NODE_ENV !== 'production' ? { 'data-testid': id } : {})

/**
 * use ui-components dialog | https://fioneer.atlassian.net/browse/CWP-13200
 */
const PropertyRentRollExcelConfirmOverwriteDialog = ({ handleUploadFile }) => {
  const { t: tExcelUpload } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.excel-upload',
  })

  const excelUploadContext = useContext(ExcelUploadContext)
  const { isConfirmOverwriteDialogOpen, closeConfirmOverwriteDialog, droppedFileToUpload } =
    excelUploadContext

  const uploadDroppedFile = useCallback(
    () => handleUploadFile(droppedFileToUpload),
    [droppedFileToUpload, handleUploadFile],
  )

  return (
    <Dialog
      open={isConfirmOverwriteDialogOpen}
      onAfterClose={closeConfirmOverwriteDialog}
      header={
        <Bar>
          <Title>{tExcelUpload('overwrite-dialog.title')}</Title>
        </Bar>
      }
      footer={
        <Bar
          endContent={
            <>
              <FlexBox>
                <FlexBox style={{ paddingRight: '5px' }}>
                  {droppedFileToUpload ? (
                    <Button
                      {...testId('confirm-upload-dropped-file-button')}
                      design={ButtonDesign.Emphasized}
                      onClick={uploadDroppedFile}
                    >
                      {tExcelUpload('accept-button')}
                    </Button>
                  ) : (
                    <ExcelFileUploaderButton
                      handleUploadFile={handleUploadFile}
                      uploadButtonText={tExcelUpload('accept-button')}
                    />
                  )}
                </FlexBox>
                <FlexBox>
                  <Button
                    {...testId('close-confirm-overwrite-Dialog')}
                    onClick={closeConfirmOverwriteDialog}
                  >
                    {tExcelUpload('abort-button')}
                  </Button>
                </FlexBox>
              </FlexBox>
            </>
          }
        />
      }
    >
      {tExcelUpload('overwrite-dialog.body')}
    </Dialog>
  )
}
PropertyRentRollExcelConfirmOverwriteDialog.propTypes = {
  handleUploadFile: PropTypes.func.isRequired,
}
export default PropertyRentRollExcelConfirmOverwriteDialog
