import { MessageBox, MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import DecisionPaperTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTile'
import DecisionPaperManualTileHeader from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/DecisionPaperManualTileHeader'
import RichTextTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/RichTextTile'
import { useUpdateDecisionPaperSubsectionAssessment } from 'hooks/services/business-events-and-tasks/decision-papers/assessments/useUpdateDecisionPaperSubsectionAssessment'
import useIsDecisionPaperTileExpanded from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useIsDecisionPaperTileExpanded'
import useTextEditorCurrentContent from 'hooks/text-editor/useTextEditorCurrentContent'
import {
  collapseTile,
  expandTile,
  showCurrentTileVersion,
} from 'redux/slices/decision-paper/tilesOverviewSlice'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const columnSpan = 2
const emptyAssessmentText = '<p><br></p>'

const DecisionPaperSubsectionAssessmentTile = ({
  tileId,
  subsectionId,
  assessmentText,
  lastUpdatedBy,
  lastUpdatedAt,
  isPdfView,
  isEditEnabled,
  currentVersion,
  additionalEditActions,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.subsections.assessment',
  })

  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const { id: eventId } = useContext(BusinessEventsAndTasksContext).event

  const hasValue = useMemo(() => assessmentText !== '', [assessmentText])

  const [isEditMode, setIsEditMode] = useState(false)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [isDataUploading, setIsDataUploading] = useState(false)

  const { currentContent, setCurrentContent, hasChanges } =
    useTextEditorCurrentContent(assessmentText)

  const { isExpanded: isTileExpanded } = useIsDecisionPaperTileExpanded({
    tileId,
    isPdfView,
    isDefaultExpanded: hasValue,
  })
  const isExpanded = useMemo(
    () => (isTileExpanded && hasValue) || isEditMode,
    [isTileExpanded, hasValue, isEditMode],
  )

  const setCurrentAssessmentContent = useCallback(
    (currentAssessmentContent) => {
      setCurrentContent(
        currentAssessmentContent === emptyAssessmentText ? '' : currentAssessmentContent,
      )
    },
    [setCurrentContent],
  )

  const { mutate: updateDecisionPaperSubsectionAssessment } =
    useUpdateDecisionPaperSubsectionAssessment()

  const tileStatus = useMemo(
    () => ({
      isLoading: false,
      isError: false,
      data: {
        lastUpdatedBy,
        lastUpdatedAt,
      },
    }),
    [lastUpdatedBy, lastUpdatedAt],
  )

  const collapse = useCallback(() => {
    dispatch(collapseTile(tileId))
  }, [dispatch, tileId])

  const expand = useCallback(() => {
    dispatch(expandTile(tileId))
  }, [dispatch, tileId])

  const updateAssessment = useCallback(
    () =>
      updateDecisionPaperSubsectionAssessment(
        {
          eventId,
          subsectionId,
          text: currentContent === '' ? null : currentContent,
        },
        {
          onSuccess: () => {
            setIsEditMode(false)
            queryClient.invalidateQueries([
              'events',
              eventId,
              'decision-paper',
              'versions',
              'current',
              'subsections',
              subsectionId,
              'assessment',
            ])
            dispatch(showCurrentTileVersion(tileId))
            if (currentContent === '') {
              collapse()
              return
            }
            expand()
          },
          onError: () => {
            setIsErrorDialogOpen(true)
          },
        },
      ),
    [
      updateDecisionPaperSubsectionAssessment,
      eventId,
      subsectionId,
      queryClient,
      collapse,
      expand,
      currentContent,
      dispatch,
      tileId,
    ],
  )

  const handleErrorDialogClose = useCallback(() => setIsErrorDialogOpen(false), [])

  const handleEditClicked = useCallback(() => setIsEditMode(true), [])
  const handleSaveClicked = useCallback(() => {
    updateAssessment()
  }, [updateAssessment])
  const handleCancelClicked = useCallback(() => {
    setIsEditMode(false)
    setCurrentContent(assessmentText)
  }, [assessmentText, setCurrentContent])

  const header = useMemo(
    () =>
      tileStatus && (
        <DecisionPaperManualTileHeader
          titleText={t('title')}
          tileStatus={tileStatus}
          isPdfView={isPdfView}
          isExpanded={isExpanded}
          isEditMode={isEditMode}
          isEditEnabled={isEditEnabled}
          onSaveClicked={handleSaveClicked}
          onCancelClicked={handleCancelClicked}
          onEditClicked={handleEditClicked}
          showLastEdited={isEditEnabled}
          hasChanges={hasChanges}
          allowCollapse={hasValue}
          showSource={false}
          onCollapse={collapse}
          onExpand={expand}
          currentVersion={currentVersion}
          additionalEditActions={additionalEditActions}
          isSaveEnabled={!isDataUploading}
        />
      ),
    [
      tileStatus,
      t,
      isPdfView,
      isExpanded,
      isEditMode,
      isEditEnabled,
      handleSaveClicked,
      handleCancelClicked,
      handleEditClicked,
      hasChanges,
      hasValue,
      collapse,
      expand,
      currentVersion,
      additionalEditActions,
      isDataUploading,
    ],
  )

  return (
    <DecisionPaperTile columnSpan={columnSpan} header={header} isExpanded={isExpanded}>
      <RichTextTile
        isEditMode={isEditMode}
        isPdfView={isPdfView}
        currentContent={currentContent}
        onChange={setCurrentAssessmentContent}
        setIsDataUploading={setIsDataUploading}
      />
      {isErrorDialogOpen &&
        createPortal(
          <MessageBox
            type={MessageBoxTypes.Error}
            open={isErrorDialogOpen}
            onClose={handleErrorDialogClose}
          >
            {t('update.error.description')}
          </MessageBox>,
          document.body,
        )}
    </DecisionPaperTile>
  )
}

DecisionPaperSubsectionAssessmentTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  subsectionId: PropTypes.string.isRequired,
  assessmentText: PropTypes.string,
  lastUpdatedBy: PropTypes.string,
  lastUpdatedAt: PropTypes.string,
  isPdfView: PropTypes.bool.isRequired,
  isEditEnabled: PropTypes.bool.isRequired,
  currentVersion: PropTypes.string.isRequired,
  additionalEditActions: PropTypes.array,
}

export default DecisionPaperSubsectionAssessmentTile
