import {
  Button,
  ButtonDesign,
  ObjectStatus,
  TableRowType,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/decision-overview/CheckSheetDecisionProcessOverviewTable.module.css'
import DecisionProcessOverviewTableStatusCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/decision-overview/shared/DecisionProcessOverviewTableStatusCell'
import DecisionStageDecisionPaperLink from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/decision-overview/shared/DecisionStageDecisionPaperLink'
import DecisionStageName from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/decision-overview/shared/DecisionStageName'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/decision-overview/shared/MessageBox'
import {
  decisionProcessConditionsDecisionStatus,
  getObjectStatusforDecisionStageConditionsStatus,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/decision-overview/shared/decisionProcessConditionsApi'
import {
  decisionProcessMinutesDecisionStatus,
  getObjectStatusforDecisionStageMinutesStatus,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/decision-overview/shared/decisionProcessMinutesApi'
import SortedTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/decision-overview/shared/table/SortedTable'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/decision-overview/shared/useI18n'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import paths from 'routes/paths'

const hyphen = '-'

const CheckSheetDecisionProcessOverviewTable = ({ decisionStages, showConfirmationForLinks }) => {
  const {
    event: { id: eventId },
  } = useContext(BusinessEventsAndTasksContext)
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.check-sheet.decision-overview',
  })
  const { t: tNoPrefix } = useTranslation('decisionPaper')
  const { t: tDecisionProcess } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.decision-process.overview.table',
  })
  const { t: tDecisionPaperCofirmation } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.decision-stage.approval-view',
  })

  const confirmMessageText = tDecisionPaperCofirmation('confirmation.text')
  const confirmMessageTitle = tDecisionPaperCofirmation('confirmation.text')
  const confirmButtonText = tDecisionPaperCofirmation('confirmation.button')
  const showMessageBox = useShowMessageBox()
  const { format: formatDate } = useShortDateFormatter()

  const columns = useMemo(
    () => [
      {
        title: tDecisionProcess('columns.name'),
        columnKey: 'name',
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
      {
        title: tDecisionProcess('columns.linkedDecisionPaper'),
        columnKey: 'linkedDecisionPaper',
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
      {
        title: tDecisionProcess('columns.decisionStatus'),
        columnKey: 'decisionStatus',
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
      {
        title: tDecisionProcess('columns.decisionDate'),
        columnKey: 'decisionDate',
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
      {
        title: tDecisionProcess('columns.conditions'),
        columnKey: 'conditions',
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
      {
        title: tDecisionProcess('columns.minutes'),
        columnKey: 'minutes',
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
    ],
    [tDecisionProcess],
  )

  const tableData = useMemo(
    () =>
      decisionStages.map(
        ({
          id,
          info: { name, decisionType, decisionStatus, decisionDate },
          decisionPaper,
          conditions: { decisionStatus: conditionsDecisionStatus },
          minutes: { decisionStatus: minutesDecisionStatus },
        }) => {
          const conditionObjectStatus = conditionsDecisionStatus
            ? getObjectStatusforDecisionStageConditionsStatus(conditionsDecisionStatus)
            : undefined
          const minutesObjectStatus = minutesDecisionStatus
            ? getObjectStatusforDecisionStageMinutesStatus(minutesDecisionStatus)
            : undefined
          return {
            rowKey: `decision-stage-${id}`,
            rowProperties: { type: TableRowType.Active, 'data-decision-stage-id': id },
            name: {
              cellComponent: <DecisionStageName name={name} decisionStageType={decisionType} />,
            },
            linkedDecisionPaper: {
              cellComponent: decisionPaper ? (
                <DecisionStageDecisionPaperLink
                  eventId={eventId}
                  decisionStageStatus={decisionStatus}
                  decisionPaperVersion={decisionPaper.version}
                  showConfirmationForLinks={showConfirmationForLinks}
                />
              ) : (
                <Text>{hyphen}</Text>
              ),
            },
            decisionStatus: {
              cellComponent: (
                <DecisionProcessOverviewTableStatusCell stageStatus={decisionStatus} />
              ),
            },
            decisionDate: {
              cellComponent: <Text>{decisionDate ? formatDate(decisionDate) : hyphen}</Text>,
            },
            conditions: {
              cellComponent: conditionObjectStatus ? (
                <ObjectStatus inverted state={conditionObjectStatus.objectStatus}>
                  {tNoPrefix(conditionObjectStatus.translationKey)}
                </ObjectStatus>
              ) : (
                <Text>{hyphen}</Text>
              ),
            },
            minutes: {
              cellComponent: minutesObjectStatus ? (
                <ObjectStatus inverted state={minutesObjectStatus.objectStatus}>
                  {tNoPrefix(minutesObjectStatus.translationKey)}
                </ObjectStatus>
              ) : (
                <Text>{hyphen}</Text>
              ),
            },
          }
        },
      ),
    [decisionStages, eventId, formatDate, showConfirmationForLinks, tNoPrefix],
  )

  const navigateToDecisionStage = useCallback(
    ({ decisionStageId }) => {
      const link = `/${paths.businessEventsAndTasks}/business-events/${eventId}/decision-stages/${decisionStageId}`
      window.open(link, '_blank')
    },
    [eventId],
  )

  const onLinkConfirm = useCallback(
    ({ decisionStageId }) =>
      () => {
        navigateToDecisionStage({ decisionStageId })
      },
    [navigateToDecisionStage],
  )

  const additionalTableProperties = useMemo(
    () => ({
      onRowClick: ({ detail: { row: clickedTableRow } }) => {
        const decisionStageId = clickedTableRow.getAttribute('data-decision-stage-id')
        if (showConfirmationForLinks) {
          showMessageBox({
            type: MessageBoxTypes.Confirm,
            titleText: confirmMessageTitle,
            children: confirmMessageText,
            actions: [
              <Button
                key="button-confirm"
                design={ButtonDesign.Emphasized}
                onClick={onLinkConfirm({ decisionStageId })}
              >
                {confirmButtonText}
              </Button>,
              MessageBoxActions.Cancel,
            ],
          })
          return
        }
        navigateToDecisionStage({ decisionStageId })
      },
    }),
    [
      confirmButtonText,
      confirmMessageTitle,
      confirmMessageText,
      navigateToDecisionStage,
      onLinkConfirm,
      showConfirmationForLinks,
      showMessageBox,
    ],
  )

  return (
    <div className={styles.tableWrapper}>
      <SortedTable
        columnDefinitions={columns}
        tableData={tableData}
        additionalTableProperties={additionalTableProperties}
        toolbarConfig={{
          title: tDecisionProcess('title'),
        }}
        noDataText={t('empty')}
      />
    </div>
  )
}

CheckSheetDecisionProcessOverviewTable.propTypes = {
  decisionStages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      info: PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        decisionType: PropTypes.string.isRequired,
        decisionStatus: PropTypes.string.isRequired,
        decisionDate: PropTypes.string,
      }),
      conditions: PropTypes.shape({
        decisionStatus: PropTypes.oneOf(Object.values(decisionProcessConditionsDecisionStatus)),
      }).isRequired,
      minutes: PropTypes.shape({
        decisionStatus: PropTypes.oneOf(Object.values(decisionProcessMinutesDecisionStatus)),
      }).isRequired,
    }),
  ).isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
}

export default CheckSheetDecisionProcessOverviewTable
