import isNil from 'lodash.isnil'

const bpsMultiplierDivider = 10000

export const convertBpsToDecimal = (value) =>
  // BPS need to be divided by 10000 to have a number (usually) between 0 and 1.
  // To avoid floating point errors and to have one fraction digit, we limit the fraction digits to 4+1
  // eslint-disable-next-line no-magic-numbers
  value ? Number(value / bpsMultiplierDivider).toFixed(5) : undefined

export const convertDecimalToBps = (value) => {
  if (value === '') {
    return ''
  }
  return !isNil(value) ? Number(value * bpsMultiplierDivider).toFixed(1) : undefined
}
