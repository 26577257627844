import {
  BusyIndicatorSize,
  DatePicker,
  Input,
  Label,
  ValueState,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import cloneDeep from 'lodash.clonedeep'
import merge from 'lodash.merge'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import RichTextTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/RichTextTile'
import { CardEditItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import CheckCycleInputSelect from 'components/domains/deals/covenants/monitoring-information-card/CheckCycleInputSelect'
import {
  covenantCheckPropTypes,
  covenantDefinitionShape,
  monitoringInformationPropTypes,
} from 'components/domains/deals/covenants/monitoring-information-card/MonitoringInformationPropTypes'
import styles from 'components/domains/deals/covenants/monitoring-information-card/MonitoringInformationView.module.css'
import MonitoringReferenceInput from 'components/domains/deals/covenants/monitoring-information-card/MonitoringReferenceInput'
import MonitoringReferenceTypeSelect from 'components/domains/deals/covenants/monitoring-information-card/MonitoringReferenceTypeSelect'
import RelativeDateComparisonEdit from 'components/domains/deals/covenants/monitoring-information-card/RelativeDateComparisonEdit'
import useBreachRuleTranslations from 'components/domains/deals/covenants/shared/useBreachRuleTranslations'
import { ReferenceEntityType } from 'components/ui/data/ReferenceEntityType'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useEvent from 'hooks/services/business-events-and-tasks/events/useEvent'
import useTextEditorCurrentContent from 'hooks/text-editor/useTextEditorCurrentContent'
import { DealContext } from 'routes/deals/DealContext'

const MonitoringInformationEdit = ({
  formState = {},
  covenantCheck: {
    covenantCheckUuid,
    name: covenantCheckName,
    keyDate: covenantCheckKeyDate,
    firstTargetDeliveryDate: covenantCheckFirstDeliveryDate,
    linkedEventId,
  } = {},
  updateFormState,
  commentMaxLength,
  setIsCommentLengthValid,
  configuration,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.monitoring-card',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const { deal: { dealUuid } = {} } = useContext(DealContext)

  const { localePattern, format: formatDate, parse: parseDate } = useShortDateFormatter()

  const { getTranslationForBreachRule } = useBreachRuleTranslations()

  const {
    monitoringReferenceEntity = {},
    firstDueDate: {
      keyDate: monitoringKeyDate,
      targetDeliveryDateBaseData: monitoringDeliveryDateBaseData,
      checkDateBaseData: monitoringCheckDateBaseData,
    } = {},
    checkCycle = {},
    lastChecked,
    breachRule,
    comment,
  } = formState

  const { checkCycle: { modifiable: isCheckCycleModifiable } = {}, existingCheckCycles } =
    configuration ?? { checkCycle: { modifiable: true } }

  const {
    isLoading: isLoadingEvent,
    isFetching: isFetchingEvent,
    isError: isErrorEvent,
    data: linkedEvent,
  } = useEvent(linkedEventId, {
    enabled: !!linkedEventId,
    retry: 1,
  })

  const { currentContent } = useTextEditorCurrentContent(comment)

  const onValueChange = (value) => {
    const oldFormState = cloneDeep(formState)
    const newFormState = merge(oldFormState, value)
    updateFormState(newFormState)
  }

  return (
    <>
      <CardSection title={t('basic-information')} id="basic-information">
        <CardEditItem
          label={tNoPrefix('pages.deals.covenants.monitoring-reference-type')}
          required
          value={
            <MonitoringReferenceTypeSelect
              value={monitoringReferenceEntity?.type}
              onChange={(type) =>
                onValueChange({
                  monitoringReferenceEntity: {
                    type,
                    id: type === ReferenceEntityType.Deal ? dealUuid : '',
                  },
                })
              }
            />
          }
        />
        <CardEditItem
          label={tNoPrefix('pages.deals.covenants.monitoring-entity')}
          required
          value={
            <MonitoringReferenceInput
              type={monitoringReferenceEntity?.type}
              id={monitoringReferenceEntity?.id}
              onChange={(referenceId) =>
                onValueChange({ monitoringReferenceEntity: { id: referenceId } })
              }
            />
          }
        />
        <CardEditItem
          label={t('first-key-date')}
          required
          value={
            <DatePicker
              id="first-key-date"
              value={monitoringKeyDate}
              valueState={!monitoringKeyDate ? ValueState.Error : ValueState.None}
              formatPattern={localePattern}
              onChange={(event) =>
                onValueChange({
                  firstDueDate: {
                    keyDate: parseDate(event.target.value, localePattern) ?? event.target.value,
                  },
                })
              }
            />
          }
        />
        <CardEditItem
          required
          label={tNoPrefix('pages.deals.covenants.delivery-date')}
          value={
            <RelativeDateComparisonEdit
              value={monitoringDeliveryDateBaseData}
              referenceDateTranslation={t('key-date-plain')}
              isRequired
              onChange={(data) => {
                onValueChange({ firstDueDate: { targetDeliveryDateBaseData: data } })
              }}
            />
          }
        />
        <CardEditItem
          label={t('check-date')}
          value={
            <RelativeDateComparisonEdit
              value={monitoringCheckDateBaseData}
              referenceDateTranslation={tNoPrefix('pages.deals.covenants.delivery-date')}
              onChange={(data) => {
                onValueChange({ firstDueDate: { checkDateBaseData: data } })
              }}
              isDirectionSelectDisabled
            />
          }
        />
        <CardEditItem
          required
          label={tNoPrefix('pages.deals.covenants.check-cycle')}
          id="check-cycle"
          value={
            <CheckCycleInputSelect
              value={checkCycle}
              onChange={(value) => onValueChange({ checkCycle: value })}
              isModifiable={isCheckCycleModifiable}
              selectableCheckCycles={existingCheckCycles}
            />
          }
        />
        <CardEditItem
          label={tNoPrefix('pages.deals.covenants.last-checked')}
          value={<Input id="last-checked" readonly value={formatDate(lastChecked)} />}
        />
        <CardEditItem
          label={t('breach-if')}
          value={<Input id="breach-if" readonly value={getTranslationForBreachRule(breachRule)} />}
        />
      </CardSection>
      <CardSection title={t('next-covenant-check')} id="next-covenant-check">
        <CardEditItem
          label={t('covenant-check')}
          value={
            <Input
              id="covenant-check"
              readonly
              value={covenantCheckUuid ? `${covenantCheckName} (${covenantCheckUuid})` : ''}
            />
          }
        />
        <CardEditItem
          label={t('monitoring-event')}
          value={
            <RequestStateResolver
              loadingSize={BusyIndicatorSize.Small}
              isLoading={isLoadingEvent && isFetchingEvent}
              isError={isErrorEvent}
              errorToDisplay={
                <Label className={styles.errorLabel} wrappingType={WrappingType.Normal}>
                  {tNoPrefix('pages.deals.covenants.data-error')}
                </Label>
              }
              renderContent={() => (
                <Input
                  readonly
                  id="monitoring-event"
                  value={
                    linkedEventId && linkedEvent.id
                      ? `${linkedEvent.info?.name} (${linkedEvent.displayId})`
                      : ''
                  }
                />
              )}
            />
          }
        />
        <CardEditItem
          label={t('key-date')}
          value={
            <Input id="covenant-check-key-date" readonly value={formatDate(covenantCheckKeyDate)} />
          }
        />
        <CardEditItem
          label={t('first-delivery-date')}
          value={
            <Input
              id="covenant-check-first-delivery-date"
              readonly
              value={formatDate(covenantCheckFirstDeliveryDate)}
            />
          }
        />
      </CardSection>
      <CardSection title={t('comment')}>
        <RichTextTile
          id="comment"
          isEditMode
          maxCharacterAmount={commentMaxLength}
          onUsedCharactersChanged={(usedCharactersExcludingHTML) => {
            setIsCommentLengthValid(usedCharactersExcludingHTML <= commentMaxLength)
          }}
          currentContent={currentContent}
          onChange={(value) => onValueChange({ comment: value })}
          isPdfView={false}
        />
      </CardSection>
    </>
  )
}

MonitoringInformationEdit.propTypes = {
  formState: monitoringInformationPropTypes,
  covenantCheck: covenantCheckPropTypes,
  updateFormState: PropTypes.func.isRequired,
  commentMaxLength: PropTypes.number,
  setIsCommentLengthValid: PropTypes.func.isRequired,
  configuration: covenantDefinitionShape,
}

export default MonitoringInformationEdit
