import isNil from 'lodash.isnil'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDecisionStages = ({ eventId }, { sortBy, orderBy } = {}) => {
  const queryParams = sortBy ? `?sort_by=${sortBy}&order_by=${orderBy}` : ''

  return useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/decision-stages${queryParams}`,
      useCache: true,
      keys: ['events', eventId, 'decision-stages'],
      options: { enabled: !isNil(eventId) },
    }),
  )
}
export default useDecisionStages
