import isNil from 'lodash.isnil'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Card from 'components/ui/card/Card'
import CardHeaderWithMetrics from 'components/ui/card/CardHeaderWithMetrics'
import { useNumberFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'
import usePropertyRentRollComparisonKpis from 'hooks/services/properties/kpis/comparison/usePropertyRentRollComparisonKpis'
import usePropertyRentRollComparisonMetric from 'hooks/services/properties/kpis/comparison/usePropertyRentRollComparisonMetric'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyRentRollComparisonVacancyCard = () => {
  const PERCENTAGE_UNIT = '%'
  const { t: tComparison } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.comparison',
  })

  const formatNumber = useNumberFormatter({
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  const { property } = useContext(PropertyContext)

  const { format: formatDate } = useShortDateFormatter()

  const {
    isLoading,
    isError,
    currentRentRollKpis,
    comparisonRentRollKpis,
    currentKeyDate,
    comparisonKeyDate,
  } = usePropertyRentRollComparisonKpis(property)

  const findVacancyPercent = (kpis) => kpis?.vacancySurface?.percent

  const formatVacancyPercent = (kpis) => {
    const vacancyPercent = findVacancyPercent(kpis)
    return !isNil(vacancyPercent) ? formatNumber(kpis?.vacancySurface?.percent * 100) : null
  }

  const formatSecondaryMetricValue = (value, unit) => (value ? `${value}${unit}` : null)

  const primaryMetric = {
    label: formatDate(currentKeyDate),
    value: formatVacancyPercent(currentRentRollKpis),
    unit: PERCENTAGE_UNIT,
  }

  const secondaryMetric = {
    label: formatDate(comparisonKeyDate),
    value: formatSecondaryMetricValue(formatVacancyPercent(comparisonRentRollKpis), '%'),
  }

  const comparisonMetric = usePropertyRentRollComparisonMetric({
    isLoading,
    isError,
    formatter: formatNumber,
    primaryValue: findVacancyPercent(currentRentRollKpis) * 100,
    comparisonValue: findVacancyPercent(comparisonRentRollKpis) * 100,
    unit: PERCENTAGE_UNIT,
  })

  const isInComparison = comparisonKeyDate && comparisonKeyDate !== currentKeyDate

  const secondaryMetrics = isInComparison ? [secondaryMetric, comparisonMetric] : [secondaryMetric]

  return (
    <Card>
      <CardHeaderWithMetrics
        title={tComparison('vacancy')}
        primaryMetric={primaryMetric}
        secondaryMetrics={secondaryMetrics}
        showReferenceDate={false}
        isLoading={isLoading}
        isError={isError}
      />
    </Card>
  )
}
export default PropertyRentRollComparisonVacancyCard
