import TenantUnitTile from 'components/domains/business-partners/tile/tenancy/TenantUnitTile'

const TenantUnitTiles = ({ relationshipData }) => {
  const tenantRelationships = relationshipData.unitRelationships?.find(
    (relationshipCategory) => relationshipCategory.type === 'tenant',
  )

  // tile information for every tenant unit
  const tileInformationArray =
    tenantRelationships?.relationships.map((relationship) => ({
      head: relationship.head,
      members: relationship.members.map(({ id, fullName, address }) => ({
        id,
        fullName,
        country: address?.country?.name,
      })),
      accountManager: relationship.accountManager,
    })) ?? []

  return tileInformationArray.map(({ members, head, accountManager }) => (
    <TenantUnitTile
      key={head.id}
      tenantUnitHead={head}
      members={members}
      accountManager={accountManager}
    />
  ))
}

export default TenantUnitTiles
