import { useQuery } from '@tanstack/react-query'
import camelize from 'camelize'
import { responseCodes } from 'api/requests'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import useRentRollWorkingVersionDateFormatter from 'hooks/services/properties/useRentRollWorkingVersionDateFormatter'

/**
 * Returns rent roll working version kpis
 * The structure is as follows:
 * {
 *  "total_area_surface": {
 *   "value": 0,
 *   "measurement_unit": "string"
 *  },
 *  "annualized_current_rent": {
 *   "number": 0,
 *   "currency": "string"
 *  },
 *  "vacancy_surface": {
 *   "value": 0,
 *   "measurement_unit": "string",
 *   "percent": 0
 *  },
 *  "wault_to_break_in_years": 0
 * }
 *
 * @returns {{isLoading: boolean, isError: boolean, data: rentRollWorkingVersionKpis}}
 */

const useRentRollWorkingVersionKpis = (rentRollWorkingVersion) => {
  const dateFormatter = useRentRollWorkingVersionDateFormatter()

  const { post } = useAccessTokenRequest()
  const { data, isLoading, isError } = useQuery({
    queryKey: [
      'rent-roll-working-version-kpis',
      rentRollWorkingVersion.header?.key_date,
      ...rentRollWorkingVersion.rental_units.sort((a, b) => a.row_number - b.row_number),
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const { header, rental_units } = rentRollWorkingVersion
      const formattedHeader = dateFormatter.formatDateInHeader(header)
      const formattedRentalUnits = dateFormatter.formatDateInRentalUnits(rental_units)
      return post({
        path: '/properties/rent-roll/working-version/kpis',
        body: {
          property_uuids: rentRollWorkingVersion.property_uuids,
          rent_roll_working_version: {
            ...rentRollWorkingVersion,
            header: formattedHeader,
            rental_units: formattedRentalUnits,
          },
        },
      })
    },
    retry: 0,
  })
  if (isLoading || isError) {
    return { isLoading, isError, data: [] }
  }
  return {
    data: camelize(data?.data) || [],
    isError: data?.status !== responseCodes.OK,
    isLoading: false,
  }
}

export default useRentRollWorkingVersionKpis
