import isEmpty from 'lodash.isempty'
import isEqual from 'lodash.isequal'
import { useCallback, useMemo } from 'react'
import { eventStatus } from 'api/events/status'

export const useCurrentApprovalsTableDataHelper = (
  arrears,
  arrearApprovals,
  arrearApprovalEvents,
  isStartApprovalProcess,
) => {
  const latestApproval = useMemo(() => arrearApprovals?.at(0), [arrearApprovals])

  const latestApprovalItems = useMemo(
    () => latestApproval?.arrearApprovalItems ?? [],
    [latestApproval?.arrearApprovalItems],
  )

  const getLatestOngoingApproval = useCallback(() => {
    const correspondingEvent = arrearApprovalEvents?.find(
      (event) => event.id === latestApproval?.eventId,
    )
    if (correspondingEvent?.status === eventStatus.ongoing) {
      return latestApproval
    }
  }, [arrearApprovalEvents, latestApproval])

  const latestOngoingApproval = useMemo(
    () => getLatestOngoingApproval(),
    [getLatestOngoingApproval],
  )

  const unfilteredOngoingApprovalItems = useMemo(
    () => latestOngoingApproval?.arrearApprovalItems ?? [],
    [latestOngoingApproval],
  )

  const filteredOngoingApprovalItems = useMemo(() => {
    const currentArrearsExternalIds = arrears.map(({ externalId }) => externalId)

    return unfilteredOngoingApprovalItems.filter(({ arrearExternalId }) =>
      currentArrearsExternalIds.includes(arrearExternalId),
    )
  }, [arrears, unfilteredOngoingApprovalItems])

  const ongoingApprovalItems = useMemo(
    () => (isStartApprovalProcess ? filteredOngoingApprovalItems : unfilteredOngoingApprovalItems),
    [filteredOngoingApprovalItems, isStartApprovalProcess, unfilteredOngoingApprovalItems],
  )

  const isNotInOngoingApprovalItems = useCallback(
    (externalId) => !ongoingApprovalItems.find((item) => item.arrearExternalId === externalId),
    [ongoingApprovalItems],
  )

  const arrearsWithoutApprovalItems = arrears.filter(({ externalId }) =>
    isNotInOngoingApprovalItems(externalId),
  )

  const createApprovalItems = useCallback(() => {
    if (isEmpty(arrearsWithoutApprovalItems)) {
      return []
    }
    const newApprovalItems = []
    const getFirstExistingApprovalItemInSameProduct = (arrear) =>
      ongoingApprovalItems.find(({ product }) => isEqual(product, arrear.product))

    arrearsWithoutApprovalItems.forEach((arrear) => {
      const approvalItem = {
        arrearExternalId: arrear.externalId,
        approvalAmount: arrear.arrearAmount,
        product: { ...arrear.product },
        approvedUntil: getFirstExistingApprovalItemInSameProduct(arrear)?.approvedUntil ?? '',
        classification: !isEmpty(getFirstExistingApprovalItemInSameProduct(arrear))
          ? { ...getFirstExistingApprovalItemInSameProduct(arrear)?.classification }
          : '',
        comment: getFirstExistingApprovalItemInSameProduct(arrear)?.comment ?? '',
      }
      newApprovalItems.push(approvalItem)
    })

    return newApprovalItems
  }, [arrearsWithoutApprovalItems, ongoingApprovalItems])

  return useMemo(
    () =>
      isStartApprovalProcess
        ? [...ongoingApprovalItems, ...createApprovalItems()]
        : latestApprovalItems,
    [isStartApprovalProcess, ongoingApprovalItems, createApprovalItems, latestApprovalItems],
  )
}
