import { WrappingType } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/links/DecisionPaperLeaveConfirmationLink'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/shared/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/shared/DisplayCardViewItemView'
import {
  useBooleanToTextFormatter,
  useCustomizableCurrencyFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/shared/useI18n'
import ALCDataMissingMessageStrip from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ALCDataMissingMessageStrip'
import ComplianceFieldMissingMessageStrip from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ComplianceFieldMissingMessageStrip'
import paths from 'routes/paths'

const dash = '-'

const CheckSheetBasicCreditAuthorityLevelTile = ({ tileId, showConfirmationForLinks }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.check-sheet.basic-credit-authority-level',
  })

  const {
    data: {
      authorityLevelName,
      applicationType,
      reviewUnit,
      totalCreditAuthorityLimit,
      totalExpectedLossClass,
      borrower,
      borrowerCreditAuthorityLimit,
      borrowerExpectedLossClass,
      largeExposureLimitFlag,
      largeExposureThresholdReporting,
      largeExposureLimit,
      complianceWithRiskStrategy,
      amlRiskClassification,
      isOrgankredit,
      isBusinessErrorForAlcData,
      isFullCostNegative,
      isDirectCostNegative,
      isLowerCostNegative,
    },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const formatBoolean = useBooleanToTextFormatter()
  const formatMoney = useCustomizableCurrencyFormatter()

  const reviewUnitValue = useMemo(() => {
    if (!reviewUnit?.bpId || !reviewUnit?.bpName) {
      return dash
    }
    const link = `/${paths.businessPartners}/${reviewUnit.bpId}`
    return (
      <DecisionPaperLeaveConfirmationLink
        link={link}
        name={reviewUnit.bpName}
        showConfirmationForLinks={showConfirmationForLinks}
      />
    )
  }, [reviewUnit?.bpName, reviewUnit?.bpId, showConfirmationForLinks])

  const borrowerValue = useMemo(() => {
    if (!borrower?.id || !borrower?.name) {
      return dash
    }
    const link = `/${paths.businessPartners}/${borrower.id}`
    return (
      <DecisionPaperLeaveConfirmationLink
        link={link}
        name={borrower.name}
        showConfirmationForLinks={showConfirmationForLinks}
      />
    )
  }, [borrower?.name, borrower?.id, showConfirmationForLinks])

  return (
    <>
      <ComplianceFieldMissingMessageStrip compliance={complianceWithRiskStrategy} />
      {isBusinessErrorForAlcData && <ALCDataMissingMessageStrip />}
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.recommended-authority-level')}
          value={authorityLevelName || dash}
          isFirstItem
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.application-type')}
          value={applicationType || dash}
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView label={t('fields.review-unit')} value={reviewUnitValue} />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.total-credit-authority-limit')}
          value={
            totalCreditAuthorityLimit
              ? formatMoney(totalCreditAuthorityLimit.value, totalCreditAuthorityLimit.currency)
              : dash
          }
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.total-el-class')}
          value={totalExpectedLossClass || dash}
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView label={t('fields.borrower')} value={borrowerValue} />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.borrower-credit-authority-limit')}
          value={
            borrowerCreditAuthorityLimit
              ? formatMoney(
                  borrowerCreditAuthorityLimit.value,
                  borrowerCreditAuthorityLimit.currency,
                )
              : dash
          }
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.borrower-el-class')}
          value={borrowerExpectedLossClass || dash}
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.large-exposure')}
          value={!isNil(largeExposureLimitFlag) ? formatBoolean(largeExposureLimitFlag) : dash}
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.threshold-large-exposure')}
          value={
            largeExposureThresholdReporting
              ? formatMoney(
                  largeExposureThresholdReporting.value,
                  largeExposureThresholdReporting.currency,
                )
              : dash
          }
          wrappingType={WrappingType.Normal}
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.maximum-limit-large-exposure')}
          value={
            largeExposureLimit
              ? formatMoney(largeExposureLimit.value, largeExposureLimit.currency)
              : dash
          }
          wrappingType={WrappingType.Normal}
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.compliance-risk-strategy')}
          value={
            !isNil(complianceWithRiskStrategy) ? formatBoolean(complianceWithRiskStrategy) : dash
          }
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.aml-risk-classification')}
          value={amlRiskClassification || dash}
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.organkredit')}
          value={!isNil(isOrgankredit) ? formatBoolean(isOrgankredit) : dash}
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.ep-full-cost-negative')}
          value={!isNil(isFullCostNegative) ? formatBoolean(isFullCostNegative) : dash}
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.ep-direct-cost-negative')}
          value={!isNil(isDirectCostNegative) ? formatBoolean(isDirectCostNegative) : dash}
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.ep-lower-cost-negative')}
          value={!isNil(isLowerCostNegative) ? formatBoolean(isLowerCostNegative) : dash}
        />
      </DisplayCardViewItemView>
    </>
  )
}

CheckSheetBasicCreditAuthorityLevelTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
}

export default CheckSheetBasicCreditAuthorityLevelTile
