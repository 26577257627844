import camelize from 'camelize'

export const EXISTING_PROPERTY_INDICATOR = {
  NONE: 'none',
  SAME_ADDRESS: 'same_address',
  SAME_STREET: 'same_street',
}

const isValidAttributeList = (attributeList) =>
  attributeList.every((attribute) => attribute && attribute !== '')

export const generatePropertyInfoMessage = (
  postalCode,
  countryCode,
  streetName,
  cityName,
  houseNumber,
  propertiesWithSameAddress,
) => {
  const mappedPropertyAddressess = camelize(
    propertiesWithSameAddress.map((property) => property.address),
  )

  // cityName is omitted in address check, since there are correct values with duplicated name, for example: Tirana, Tirana
  // but other props are included, since API returns all subsets of address filtrr object, and we need exact matches
  const propertiesWithExactStreetMatch =
    isValidAttributeList([postalCode, countryCode, streetName, cityName]) &&
    mappedPropertyAddressess.filter(
      (property) =>
        property.countryCode === countryCode &&
        property.postalCode === postalCode &&
        property.streetName === streetName,
    )

  if (propertiesWithExactStreetMatch.length) {
    return isValidAttributeList([houseNumber]) &&
      propertiesWithExactStreetMatch.find(
        (propertyObject) => houseNumber === propertyObject.houseId,
      )
      ? EXISTING_PROPERTY_INDICATOR.SAME_ADDRESS
      : EXISTING_PROPERTY_INDICATOR.SAME_STREET
  } else return EXISTING_PROPERTY_INDICATOR.NONE
}

export const generatePropertyFilterObject = (key, value, addressFilter) => {
  switch (key) {
    case 'address': {
      return {
        country: value.countryCode,
        zipCode: value.postcode || value.postalCode,
        city: value.city || value.cityName,
        street: value.road || value.name || value.streetName,
        houseId: value.houseId || value.houseNumber,
      }
    }
    case 'houseNumber': {
      return {
        ...addressFilter,
        ['houseId']: value,
      }
    }
    case 'postalCode': {
      return {
        ...addressFilter,
        ['zipCode']: value,
      }
    }
    case 'countryCode': {
      return {
        ...addressFilter,
        ['country']: value,
      }
    }
    case 'cityName': {
      return {
        ...addressFilter,
        ['city']: value,
      }
    }
    case 'streetName': {
      return {
        ...addressFilter,
        ['street']: value,
      }
    }
    default:
      return {
        ...addressFilter,
        [key]: value,
      }
  }
}
