import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDecisionStageApprovalVoterVoteOptions = (
  { eventId, decisionStageId, decisionStageVoterId },
  options,
) =>
  useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/decision-stages/${decisionStageId}/voters/${decisionStageVoterId}/vote-options`,
      useCache: true,
      keys: [
        'events',
        eventId,
        'decision-stages',
        decisionStageId,
        'voters',
        decisionStageVoterId,
        'vote-options',
      ],
      options,
    }),
  )

export default useDecisionStageApprovalVoterVoteOptions
