import { ButtonDesign, MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useShowDealErrorCodeMessageBox from 'components/domains/deals/creation-dialog/useShowDealErrorCodeMessageBox'
import EditDealAdjustmentDialogContent from 'components/domains/deals/deal-adjustment/edit/EditDealAdjustmentDialogContent'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import LoadingButton from 'components/ui/button/LoadingButton'
import Dialog from 'components/ui/dialog/Dialog'
import useCreateDealAdjustmentWorkingVersion from 'hooks/services/deals/deal-adjustment/useCreateDealAdjustmentWorkingVersion'
import useNavigateToWorkingVersion from 'routes/deals/useNavigateToWorkingVersion'

const CreateWorkingVersionDialog = forwardRef(
  (
    { dealUuid, i18nPrefix = 'components.deals.deal-adjustment.modal.create', ...props },
    forwardedRef,
  ) => {
    const dialogRef = useRef()
    useImperativeHandle(forwardedRef, () => dialogRef.current)
    const { t } = useTranslation('translation', {
      keyPrefix: i18nPrefix,
    })
    const form = useForm({
      mode: 'onChange',
      defaultValues: {},
    })
    const {
      handleSubmit,
      formState: { isValid },
    } = form

    const showToast = Modals.useShowToast()
    const showDealErrorCodeMessageBox = useShowDealErrorCodeMessageBox()

    const switchToWorkingVersion = useNavigateToWorkingVersion()

    const [isCreationLoading, setIsCreationLoading] = useState(false)
    const createWorkingVersion = useCreateDealAdjustmentWorkingVersion({
      onSuccess: (response) => {
        // show any warnings
        if (response.data.warnings?.length) {
          showDealErrorCodeMessageBox({
            warnings: response.data.warnings,
            type: MessageBoxTypes.Warning,
          })
        }

        // success actions
        showToast({
          duration: 5000,
          children: t('saved'),
        })
        switchToWorkingVersion(WorkingVersionType.WORKING_VERSION, true)

        // close
        setIsCreationLoading(false)
        dialogRef.current?.close?.()
      },
      onError: async (error) => {
        const response = await error?.response?.json()
        const { errors: [errorResponse] = [] } = response || {}
        showDealErrorCodeMessageBox({
          warnings: [errorResponse],
          fallbackWarningMessage: t('error'),
          type: MessageBoxTypes.Error,
        })

        setIsCreationLoading(false)
        dialogRef.current?.close?.()
      },
    })

    const onSubmit = useCallback(
      (adjustmentData) => {
        setIsCreationLoading(true)
        createWorkingVersion.mutate({
          dealUuid: dealUuid,
          adjustment: adjustmentData,
        })
      },
      [dealUuid, createWorkingVersion],
    )

    const primaryButton = useMemo(
      () => (
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          isLoading={isCreationLoading}
          disabled={!isValid}
          design={ButtonDesign.Emphasized}
          renderContent={() => t('submit')}
        />
      ),
      [handleSubmit, onSubmit, isCreationLoading, isValid, t],
    )

    return (
      <Dialog headerText={t('title')} {...props} ref={dialogRef} primaryButton={primaryButton}>
        <FormProvider {...form}>
          <EditDealAdjustmentDialogContent readonly={isCreationLoading} />
        </FormProvider>
      </Dialog>
    )
  },
)

CreateWorkingVersionDialog.displayName = 'CreateWorkingVersionDialog'
CreateWorkingVersionDialog.propTypes = {
  dealUuid: PropTypes.string.isRequired,
  i18nPrefix: PropTypes.string,
}

export default CreateWorkingVersionDialog
