import groupBy from 'lodash.groupby'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import pickBy from 'lodash.pickby'
import { useTranslation } from 'react-i18next'
import { read, utils } from 'xlsx'
import { useValuationCreateMethodCodes } from 'hooks/services/properties/valuations/useValuationCreateMethodCodes'
import { parseExcelDate } from 'utils/excelUtils'

const excelColumnHeaders = {
  method: 'Valuation Method',
  value: 'Result',
  keyDate: 'Key Date',
  validFrom: 'Valid From',
  validTo: 'Valid To',
}

const excelColumnKeys = {
  method: 'A',
  value: 'B',
  keyDate: 'C',
  validFrom: 'D',
  validTo: 'E',
}

const mandatoryFields = [
  excelColumnKeys.method,
  excelColumnKeys.value,
  excelColumnKeys.keyDate,
  excelColumnKeys.validFrom,
]

const useReadPropertyValuationExcel = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.valuation.results.upload',
  })

  const { isFetching, isError, data: valuationCreateMethodCodes } = useValuationCreateMethodCodes()

  const isValidHeader = (row) =>
    Object.values(row).length === Object.values(excelColumnKeys).length &&
    row[excelColumnKeys.method] === excelColumnHeaders.method &&
    row[excelColumnKeys.value] === excelColumnHeaders.value &&
    row[excelColumnKeys.keyDate] === excelColumnHeaders.keyDate &&
    row[excelColumnKeys.validFrom] === excelColumnHeaders.validFrom &&
    row[excelColumnKeys.validTo] === excelColumnHeaders.validTo

  const isValidRow = (row) =>
    Object.values(row).length <= Object.values(excelColumnKeys).length &&
    !mandatoryFields.some((mandatoryField) => isNil(row[mandatoryField]))

  const rowToValuationResult = ({ row, currencyCode }) => ({
    calculationMethodCode: row[excelColumnKeys.method],
    valueAmount: {
      number: row[excelColumnKeys.value],
      currency: currencyCode,
    },
    keyDate: parseExcelDate(row[excelColumnKeys.keyDate]),
    validFrom: parseExcelDate(row[excelColumnKeys.validFrom]),
    validTo: row[excelColumnKeys.validTo]
      ? parseExcelDate(row[excelColumnKeys.validTo])
      : undefined,
  })

  const isValidValuationResult = ({ calculationMethodCode, keyDate, validFrom, validTo }) => {
    const isUnknownCalculationMethodCode = () =>
      valuationCreateMethodCodes.every(({ key }) => key !== calculationMethodCode)
    const hasInvalidDate = (dates) => dates.some((date) => !isNil(date) && isNaN(date.getTime()))

    if (isUnknownCalculationMethodCode()) {
      return false
    }
    if (hasInvalidDate([keyDate, validFrom, validTo])) {
      return false
    }
    return true
  }

  const containsDuplicate = (valuationResults) => {
    const resultsGroupedByRelevantProperties = groupBy(
      valuationResults.map(
        ({ calculationMethodCode, validFrom, keyDate }) =>
          `${calculationMethodCode}|${validFrom}|${keyDate}`,
      ),
    )
    const duplicateValues = pickBy(resultsGroupedByRelevantProperties, (value) => value.length > 1)
    return !isEmpty(duplicateValues)
  }

  const readExcelContent = ({ excelContent, currencyCode }) => {
    const valuationResults = []
    excelContent.forEach((row, index) => {
      const error = new Error(t('error.invalid.with-row', { row: index + 1 }))
      if (index === 0) {
        if (!isValidHeader(row)) {
          throw error
        }
        return
      }
      if (!isValidRow(row)) {
        throw error
      }
      const valuationResult = rowToValuationResult({ row, currencyCode })
      if (!isValidValuationResult(valuationResult)) {
        throw error
      }
      valuationResults.push(valuationResult)
    })
    if (containsDuplicate(valuationResults)) {
      throw new Error(t('error.invalid'))
    }
    return valuationResults
  }

  const readPropertyValuationExcel = ({
    loadEvent,
    currencyCode,
    onUploadSuccess,
    onUploadError,
  }) => {
    try {
      const bufferArray = loadEvent?.target.result
      const workBook = read(bufferArray, { type: 'buffer' })
      const workSheetName = workBook.SheetNames[0]
      const workSheet = workBook.Sheets[workSheetName]
      const excelContent = utils.sheet_to_json(workSheet, {
        header: 'A',
        range: 0,
      })
      const loadedConditions = readExcelContent({ excelContent, currencyCode, t })
      if (isEmpty(loadedConditions)) {
        onUploadError(t('error.invalid'))
        return
      }
      onUploadSuccess(loadedConditions)
    } catch (error) {
      onUploadError(error?.message)
    }
  }

  return {
    isFetching,
    isError,
    data: ({ loadEvent, currencyCode, onUploadSuccess, onUploadError }) =>
      readPropertyValuationExcel({
        loadEvent,
        currencyCode,
        onUploadSuccess,
        onUploadError,
      }),
  }
}

export default useReadPropertyValuationExcel
