import '@ui5/webcomponents-icons/dist/AllIcons.js'
import { Label, Grid, Popover, ObjectStatus, ValueState } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import 'components/domains/properties/PropertyCards.css'
import { propertyMonitoringStatus } from 'api/property/monitoring/propertyMonitoringConfiguration'
import PropertyMonitoringMacroKpiChartCell from 'components/domains/properties/monitoring/PropertyMonitoringMacroKpiChartCell'
import useFormatPercentageOrValueWithUnit from 'components/domains/properties/monitoring/useFormatPercentageOrValueWithUnit'
import Card from 'components/ui/card/Card'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import usePropertyKpis from 'hooks/services/properties/kpis/usePropertyKpis'

const MACRO_PROXY_NAME = 'macroproxy'

const criticalMovementIconName = {
  DOWN: 'trend-down',
  UP: 'trend-up',
}

const PropertyMonitoringProxiesMacro = ({ pageData }) => {
  const {
    macroProxies,
    marketId,
    propertyUuid,
    baselineDate,
    monitoringStatus,
    hasNoMonitoringData = false,
  } = pageData

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.property-monitoring-proxies',
  })
  const [isWarningPopoverOpen, setIsWarningPopoverOpen] = useState('')
  const formatPercentageOrValueWithUnit = useFormatPercentageOrValueWithUnit()
  const {
    isError: isKpiError,
    data: kpiData,
    isLoading: isKpiLoading,
  } = usePropertyKpis(propertyUuid, true)

  const proxyColumns = [
    {
      title: t('table.name'),
      columnKey: 'name',
    },
    {
      title: t('table.value-at-fixing'),
      columnKey: 'valueAtFixing',
    },
    {
      title: t('table.current-value'),
      columnKey: 'currentValue',
    },
    {
      title: t('table.threshold-value'),
      columnKey: 'thresholdValue',
    },
    {
      title: t('table.threshold-type'),
      columnKey: 'thresholdType',
    },
    {
      title: t('table.critical-movement'),
      columnKey: 'criticalMovement',
      wrapText: true,
    },
    {
      title: t('table.kpi-chart'),
      columnKey: 'kpiChart',
      sortingDisabled: true,
    },
  ]

  const getKpiChartCellComponent = useCallback(
    ({
      proxyName,
      kpiName,
      threshold,
      thresholdType,
      valueAtFixing,
      criticalMovementDirection,
    }) => {
      if (proxyName === MACRO_PROXY_NAME) {
        return (
          <PropertyMonitoringMacroKpiChartCell
            kpiName={kpiName}
            marketId={marketId}
            threshold={+threshold}
            thresholdType={thresholdType}
            valueAtFixing={+valueAtFixing}
            baselineDate={baselineDate}
            criticalMovementDirection={criticalMovementDirection}
          />
        )
      }
    },
    [baselineDate, isKpiError, isKpiLoading, kpiData, marketId],
  )

  const mapProxyDataToTableData = useCallback(
    (proxyName, proxyData = []) =>
      Object.entries(proxyData).map(
        ([
          kpiName,
          {
            name,
            current_value: currentValue,
            value_at_fixing: valueAtFixing,
            threshold,
            threshold_type: thresholdType,
            is_threshold_breached: thresholdBreach,
            critical_movement: criticalMovementDirection,
            unit,
          },
        ]) => ({
          rowKey: `proxy-table-row-${proxyName}-${kpiName}`,
          name: {
            value: name,
            cellComponent: <Label>{name}</Label>,
          },
          currentValue: {
            value: parseFloat(currentValue),
            cellComponent: (
              <Label>{formatPercentageOrValueWithUnit({ value: +currentValue, unit })}</Label>
            ),
          },
          valueAtFixing: {
            value: parseFloat(valueAtFixing),
            cellComponent: (
              <Label>{formatPercentageOrValueWithUnit({ value: +valueAtFixing, unit })}</Label>
            ),
          },
          thresholdValue: {
            value: parseFloat(threshold),
            cellComponent: (
              <Label>
                {formatPercentageOrValueWithUnit({
                  value: +threshold,
                  type: thresholdType,
                  unit,
                })}
                {thresholdBreach && (
                  <>
                    <ObjectStatus
                      style={{ verticalAlign: 'baseline' }}
                      id={`${proxyName}-${kpiName}`}
                      onMouseEnter={() => {
                        setIsWarningPopoverOpen(`${proxyName}-${kpiName}`)
                      }}
                      onMouseLeave={() => {
                        setIsWarningPopoverOpen('')
                      }}
                      showDefaultIcon
                      state={ValueState.Warning}
                    />
                    {createPortal(
                      <Popover
                        opener={`${proxyName}-${kpiName}`}
                        open={isWarningPopoverOpen === `${proxyName}-${kpiName}`}
                      >
                        {t('table.threshold-value.on-hover')}
                      </Popover>,
                      document.body,
                    )}
                  </>
                )}
              </Label>
            ),
          },

          thresholdType: {
            value: thresholdType,
            cellComponent: (
              <Label>{t(`table.threshold-type.${thresholdType.toLowerCase()}`)}</Label>
            ),
          },
          criticalMovement: {
            value: criticalMovementDirection,
            cellComponent: <ui5-icon name={criticalMovementIconName[criticalMovementDirection]} />,
          },
          kpiChart: {
            value: '',
            cellComponent: getKpiChartCellComponent({
              proxyName,
              kpiName,
              threshold,
              thresholdType,
              valueAtFixing,
              criticalMovementDirection,
            }),
          },
        }),
      ),
    [formatPercentageOrValueWithUnit, getKpiChartCellComponent, isWarningPopoverOpen, t],
  )

  const macroProxyTableData = useMemo(
    () => mapProxyDataToTableData(MACRO_PROXY_NAME, macroProxies),
    [macroProxies, mapProxyDataToTableData],
  )
  const isInactiveOrHasNoMonitoringData = useMemo(
    () => monitoringStatus === propertyMonitoringStatus.inactive || hasNoMonitoringData,
    [hasNoMonitoringData, monitoringStatus],
  )

  const noDataTextMacro = useMemo(() => {
    if (isInactiveOrHasNoMonitoringData) {
      return t('table.proxies.inactive')
    }
    return marketId ? t('table.macro.no-data') : t('table.macro.nomarket.empty')
  }, [isInactiveOrHasNoMonitoringData, marketId, t])

  return (
    <Card>
      <Grid defaultSpan="XL12 L12 M12 S12" style={{ padding: '10px' }}>
        <SortedTable
          columnDefinitions={proxyColumns}
          tableData={macroProxyTableData}
          toolbarConfig={{
            dontShowNumberOfEntries: true,
            title: t('table.macro.toolbar.title'),
            sorting: {
              columnKey: 'name',
            },
          }}
          noDataText={noDataTextMacro}
        />
      </Grid>
    </Card>
  )
}

PropertyMonitoringProxiesMacro.propTypes = {
  pageData: PropTypes.shape({
    macroProxies: PropTypes.object,
    marketId: PropTypes.string,
    propertyUuid: PropTypes.string,
    baselineDate: PropTypes.string,
    monitoringStatus: PropTypes.string,
    hasNoMonitoringData: PropTypes.bool,
  }),
}

export default PropertyMonitoringProxiesMacro
