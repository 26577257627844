const startRequirementEditMode = (state, { payload: { requirementId, requirement } }) => {
  const filteredValues = {
    name: requirement.info.name,
    condition: {
      id: requirement.condition.id,
      name: requirement.condition.info.name,
      references: requirement.condition.references,
    },
    refNumber: requirement.info.refNumber ?? '',
    assignee: requirement.info.assignee ?? '',
    documentType: requirement.info.documentType,
    status: requirement.status,
    description: requirement.info.description ?? '',
  }
  state.editedRow = {
    requirementId,
    initialValues: filteredValues,
    currentValues: filteredValues,
    changedFields: {},
    errorFields: {},
  }
}

export default startRequirementEditMode
