import { IllustratedMessage } from '@fioneer/ui5-webcomponents-react'
import camelize from 'camelize'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { propertyAllowedOperations } from 'api/property/propertyAllowedOperations'
import styles from 'components/domains/properties/valuation/PropertyValuationsResults.module.css'
import PropertyValuationResultsTable from 'components/domains/properties/valuation/valuation-results/PropertyValuationResultsTable'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useCwpPropertyValuations from 'hooks/services/properties/valuations/useCwpPropertyValuations'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyValuationsResults = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property.valuation.results' })

  const { property } = useContext(PropertyContext)
  const camelizedProperty = useMemo(() => camelize(property), [property])
  const allowedOperations = camelizedProperty?.allowedOperations?.allowedOperations

  const propertyUuid = camelizedProperty?.uuid
  const propertyCurrencyCode = camelizedProperty?.currencyCode

  const {
    data: { cwpValuations: valuationsData = [] } = {},
    isFetching: isPropertyValuationsLoading,
    isError: isPropertyValuationsError,
  } = useCwpPropertyValuations({
    propertyUuid,
  })

  const renderTable = () => (
    <div className={styles.valuationResultsCardWrapper}>
      <PropertyValuationResultsTable
        valuationResults={valuationsData}
        propertyUuid={propertyUuid}
        propertyCurrencyCode={propertyCurrencyCode}
        allowedOperations={allowedOperations}
      />
    </div>
  )

  return !allowedOperations?.includes(propertyAllowedOperations.propertyValuationRead) ? (
    <Card>
      <IllustratedMessage
        size="Spot"
        name="UnableToLoad"
        titleText={t('permission-error.title')}
        subtitleText={t('permission-error.subtitle')}
      />
    </Card>
  ) : (
    <Card>
      <RequestStateResolver
        isLoading={isPropertyValuationsLoading}
        isError={isPropertyValuationsError}
        renderContent={renderTable}
        center={true}
        errorToDisplay={<ErrorDataUnavailableInContent />}
      />
    </Card>
  )
}

export default PropertyValuationsResults
