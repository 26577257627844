import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import { useUpdateArrearApprovalsByBusinessPartnerId } from 'hooks/services/arrears/useUpdateArrearApprovalsByBusinessPartnerId'
import { BUSINESS_PARTNER } from 'routes/business-partners/arrears/useBusinessPartnerArrears'

export const useUpdateAllApprovalItems = ({ businessPartnerId, onFinish }) => {
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const showErrorMessageBox = useShowErrorMessageBox()

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.current-arrears.table',
  })

  const onSaveError = async (error) => {
    const errorResponseJson = await error.response.json()
    showErrorMessageBox({ message: t('message-box.error'), error: errorResponseJson })
    onFinish()
  }

  const updateArrearApprovals = useUpdateArrearApprovalsByBusinessPartnerId({
    onSuccess: () => {
      showToast({
        children: t('message-box.toast.process-started'),
      })
      queryClient.invalidateQueries(['arrears', businessPartnerId])
      queryClient.invalidateQueries(['arrears', 'approvals', businessPartnerId])
      queryClient.invalidateQueries(['events', BUSINESS_PARTNER, businessPartnerId])
      onFinish()
    },
    onError: onSaveError,
  })

  const updateAllApprovalItems = (allApprovalItems) =>
    updateArrearApprovals.mutate({
      businessPartnerId: businessPartnerId,
      approvalItems: allApprovalItems,
    })

  return { updateAllApprovalItems }
}
