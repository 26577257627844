import { TableColumn, Text, FlexBoxAlignItems } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { alignTableContent } from 'components/domains/properties/modular-table/ModularTable'

const ModularTableColumn = ({
  columnKey,
  alignment = FlexBoxAlignItems.Start,
  title,
  wrapText,
  customComponent = {},
  ...additionalProps
}) => {
  const renderColumn = () => {
    if (Object.keys(customComponent).length) {
      return customComponent
    }
    return <Text wrapping={wrapText}>{title}</Text>
  }
  return (
    <TableColumn key={columnKey} {...additionalProps}>
      {alignTableContent(renderColumn(), alignment)}
    </TableColumn>
  )
}

ModularTableColumn.propTypes = {
  columnKey: PropTypes.string,
  alignment: PropTypes.string,
  title: PropTypes.string,
  wrapText: PropTypes.bool,
  customComponent: PropTypes.node,
  minWidth: PropTypes.number,
  demandPopin: PropTypes.bool,
  popinText: PropTypes.string,
}

export default ModularTableColumn
