import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useEditTileMetaData = (mutationOptions) => {
  const { put } = useAccessTokenRequest()
  return useMutation(
    ({ eventId, tileId, metaData }) =>
      put({
        path: `/events/${eventId}/decision-paper/versions/current/tiles/${tileId}/meta-data`,
        body: {
          meta_data: metaData,
        },
      }),
    mutationOptions,
  )
}

export default useEditTileMetaData
