import { useQueries } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse, useCombinedQueryResults } from 'hooks/services/queryHelper'

export const getPropertyPartnersKeys = (propertyUuid, specification) => [
  'properties',
  propertyUuid,
  'businesspartners',
  specification,
]

/** * Returns all businesspartners belonging to property
 * parameter specification can be: undefined, "external", "internal"
 * example data: 
 * {
 *  "business_partners": [
        {
            "business_partner_function_code": "010003",
            "business_partner_function_name": "Account Holder",
            "party_id": "1000002"
        }
    ]
  }
 * @returns {{isLoading: boolean, isError: boolean, data: business_partners}}
 */
export const usePropertyPartners = (
  propertyUuid,
  specification,
  translated = true,
  isThrottled = true,
) => {
  const path = specification
    ? `/properties/${propertyUuid}/businesspartners/${specification}`
    : `/properties/${propertyUuid}/businesspartners`
  return useRequest({
    path: `${path}`,
    keys: getPropertyPartnersKeys(propertyUuid, specification),
    translated,
    requestOptions: {
      isThrottled,
    },
  })
}

export const usePartnersOfMultipleProperties = (
  { propertyUuids = [], specification },
  options = {},
) => {
  const { get } = useAccessTokenRequest()

  const queries = propertyUuids.map((propertyUuid) => ({
    queryKey: getPropertyPartnersKeys(propertyUuid, specification),
    queryFn: async () =>
      get({
        path: specification
          ? `/properties/${propertyUuid}/businesspartners/${specification}`
          : `/properties/${propertyUuid}/businesspartners`,
      }),
    retry: 0,
    ...options,
  }))
  const responses = useQueries({ queries })
  return useCamelizedResponse(useCombinedQueryResults(responses))
}
