const duplicateEntryBaseNameExp = RegExp('(.*)?(_\\d*)$')

/**
 * Duplicates an entry given a list of all entries currently available.
 * Uses a logic to append or increment an index after the 'base' name of
 * the entry.
 * Numbers are added to the base name after this pattern: <baseName>_<index>
 *
 * As discussed with alex, 'gaps' in sequences of existing names
 * are not to be filled. Examples:
 *
 * ```
 * nextDuplicatedName(['A', 'A_1', 'A_3','B_4'], 'A_1') // => 'A_4'
 * ```
 */
export const nextDuplicatedName = (
  /** @type {string[]} */ existingNames,
  /** @type {string} */ currentName,
) => {
  if (currentName === '') {
    return '_1'
  }
  const baseNameResult = duplicateEntryBaseNameExp.exec(currentName)
  const baseName = baseNameResult ? baseNameResult[1] : currentName
  const duplicateEntryExp = RegExp(`^${baseName}_(\\d*)$`)

  const maxIndex = existingNames.reduce((currentMax, existingName) => {
    if (existingName === baseName) {
      return currentMax
    }
    const duplicateMatch = duplicateEntryExp.exec(existingName)
    if (!duplicateMatch) {
      return currentMax
    }
    return Math.max(currentMax, +duplicateMatch[1])
  }, 0)

  return `${baseName}_${maxIndex + 1}`
}
