import {
  BusyIndicatorSize,
  IllustrationMessageSize,
  IllustrationMessageType,
  ObjectPageSection,
} from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import DealChangeOverviewSearch from 'components/domains/deals/change-overview/DealChangeOverviewSearch'
import DealAdjustmentHistory from 'components/domains/deals/change-overview/adjustments-history-card/DealAdjustmentHistory'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { useDealWorkingVersionToolbarButtons } from 'components/domains/deals/deal-adjustment/useDealWorkingVersionToolbarButtons'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useChangeLogsPermissions } from 'hooks/services/traceability-of-changes/useChangeLogsPermissions'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'

const DealChangeOverview = () => {
  const {
    deal: { dealUuid, workingVersion },
  } = useContext(DealContext)
  const { toggleWorkingVersionButton, manageWorkingVersionButton } =
    useDealWorkingVersionToolbarButtons()
  const { t: tDealChangeOverview } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.change-overview',
  })
  const location = useLocation()
  const { t } = useTranslation('translation')

  const {
    isLoading: isLoadingPermissions,
    isError: isErrorPermissions,
    data: changeLogPermissions,
  } = useChangeLogsPermissions()
  const allowedOperations = changeLogPermissions?.allowedOperations ?? []
  const isAllowedToReadChangeLogs = allowedOperations.includes('Change_Log_Read')

  const isWorkingVersion = useMemo(
    () => WorkingVersionType.WORKING_VERSION === workingVersion,
    [workingVersion],
  )

  const noPermissionMessage = (
    <CenteredIllustratedMessage
      name={IllustrationMessageType.UnableToLoad}
      size={IllustrationMessageSize.Spot}
      titleText={t('app.permission-error.title')}
      subtitleText={t('app.permission-error.subtitle')}
    />
  )

  const breadcrumb = { text: tDealChangeOverview('title') }

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [dealUuid],
  )

  const isAdjustmentsTab = useMemo(() => location.hash === '#adjustments', [location.hash])

  const headerActions = useMemo(
    () =>
      createSegmentedHeaderActions(
        [!isAdjustmentsTab && toggleWorkingVersionButton, manageWorkingVersionButton],
        [<CreateDealEventAction key="create-event-action" />, markFavoriteAction],
      ),
    [isAdjustmentsTab, manageWorkingVersionButton, markFavoriteAction, toggleWorkingVersionButton],
  )

  const renderOverviewContent = () => (
    <RequestStateResolver
      center
      busyIndicatorSize={BusyIndicatorSize.Medium}
      errorToDisplay={<ErrorDataUnavailableInContent />}
      isLoading={isLoadingPermissions}
      isError={isErrorPermissions}
      renderContent={() => {
        if (!isAllowedToReadChangeLogs) {
          return noPermissionMessage
        }
        return <DealChangeOverviewSearch />
      }}
    />
  )

  const renderPageContent = () => {
    if (isWorkingVersion) {
      return (
        <DealDetailsWrapper
          childBreadcrumbs={breadcrumb}
          actions={headerActions}
          additionalProperties={{ includeUrlQuery: true }}
        >
          {renderOverviewContent()}
        </DealDetailsWrapper>
      )
    } else {
      return (
        <DealDetailsWrapper
          isTabPage
          childBreadcrumbs={breadcrumb}
          actions={headerActions}
          additionalProperties={{ includeUrlQuery: true, defaultSectionId: 'changes' }}
        >
          <ObjectPageSection id="changes" titleText={tDealChangeOverview('tabs.changes')}>
            {renderOverviewContent()}
          </ObjectPageSection>
          <ObjectPageSection id="adjustments" titleText={tDealChangeOverview('tabs.adjustments')}>
            <DealAdjustmentHistory />
          </ObjectPageSection>
        </DealDetailsWrapper>
      )
    }
  }

  return renderPageContent()
}

export default DealChangeOverview
