import { combineReducers } from '@reduxjs/toolkit'
import imagesForTilesReducer from 'redux/slices/decision-paper/imagesForTilesSlice'
import tilesOverviewReducer from 'redux/slices/decision-paper/tilesOverviewSlice'

const decisionPaperReducer = combineReducers({
  imagesForTiles: imagesForTilesReducer,
  tilesOverview: tilesOverviewReducer,
})

export default decisionPaperReducer
