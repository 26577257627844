import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import LinkWithIdAndConfirmation from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-information/shared/LinkWithIdAndConfirmation'

const DecisionStageLeaveConfirmationLink = ({ name, link, showConfirmationForLinks = true }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.events.components.decision-stage.approval-view',
  })

  return (
    <LinkWithIdAndConfirmation
      name={name}
      link={link}
      confirmMessageText={t('confirmation.text')}
      confirmMessageTitle={t('confirmation.title')}
      confirmButtonText={t('confirmation.button')}
      showConfirmationForLinks={showConfirmationForLinks}
    />
  )
}

DecisionStageLeaveConfirmationLink.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool,
}

export default DecisionStageLeaveConfirmationLink
