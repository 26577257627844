import { compact } from 'lodash'
import { useMemo } from 'react'
import {
  ACTIVE,
  APPROVED,
  PROPOSED,
} from 'components/domains/business-partners/tile/risk-monitoring/forbearance/constants'
import mapMeasuresBackendData from 'components/domains/business-partners/tile/risk-monitoring/forbearance/measures/mapper/mapMeasuresBackendData'
import mapUserUuidsFromForbearanceMeasures from 'components/domains/business-partners/tile/risk-monitoring/forbearance/measures/mapper/mapUserUuidsFromForbearanceMeasures'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useMultipleStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useMultipleStaffMemberByObjectIdOrEmail'
import useDealMini from 'hooks/services/deals/useDealMini'
import useForbearanceMeasures from 'hooks/services/risk-monitoring/forbearance/measures/useForbearanceMeasures'

/**
 * @param {object} params
 * @param {object} params.entityRef
 * @param {string} [params.entityRef.entityId]
 */
const useForbearanceMeasuresDecisionPaper = ({ entityRef: { entityId, entityType } }) => {
  const {
    data: { borrowerBpId } = {},
    isLoading: isDealLoading,
    isError: isDealError,
  } = useDealMini(entityType === cwpEntityTypes.DEAL ? entityId : undefined)

  const businessPartnerId = entityType === cwpEntityTypes.DEAL ? borrowerBpId : entityId

  const {
    data,
    isLoading: isForbearanceMeasuresLoading,
    isError: isForbearanceMeasuresError,
  } = useForbearanceMeasures({
    businessPartnerId,
  })
  const uuids = mapUserUuidsFromForbearanceMeasures(data)

  const {
    data: staffMembers,
    isSomeLoading: isStaffMemberLoading,
    isSomeError: isStaffMemberError,
  } = useMultipleStaffMemberByObjectIdOrEmail({ objectIdOrEmailAddressList: uuids })

  const { tableData, nrOfMeasures } = mapMeasuresBackendData(data, staffMembers)

  const isProposedApprovedOrActive = ({ status: { statusValue } }) =>
    statusValue === PROPOSED || statusValue === ACTIVE || statusValue === APPROVED

  const filteredRowsByProposedOrActiveStatus = useMemo(() => {
    const validRows = tableData?.filter((row) => isProposedApprovedOrActive(row))
    return validRows.map((row) => ({
      ...row,
      subRows: compact(row?.subRows?.filter((subRow) => isProposedApprovedOrActive(subRow))),
    }))
  }, [tableData])

  return {
    data: {
      tableData: filteredRowsByProposedOrActiveStatus,
      nrOfMeasures,
      sourceRender: {
        businessPartnerId,
      },
    },
    isLoading: isDealLoading || isForbearanceMeasuresLoading || isStaffMemberLoading,
    isError: isDealError || isForbearanceMeasuresError || isStaffMemberError,
  }
}

export default useForbearanceMeasuresDecisionPaper
