import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import usePatchDocumentReferences, {
  patchDocumentReferencesOperations,
} from 'hooks/services/documents/usePatchDocumentReferences'

const useCopyDocumentsForRequirements = () => {
  const { t: tNoPrefix } = useTranslation()
  const { mutate: mutateDocumentReferences } = usePatchDocumentReferences()
  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()

  const onCopyError = useCallback(() => {
    showMessageBox({ type: MessageBoxTypes.Error, children: tNoPrefix('error.default') })
  }, [showMessageBox, tNoPrefix])

  const onCopySuccess = useCallback(
    ({ selectedRequirementIds }) =>
      () => {
        selectedRequirementIds.forEach((requirementId) => {
          queryClient.invalidateQueries(['documents', DocumentTypes.Requirement, requirementId])
        })
        showToast({ children: tNoPrefix('toast.changes-saved') })
      },
    [queryClient, showToast, tNoPrefix],
  )

  const handleCopy = useCallback(
    ({ documentId, selectedRequirementIds }) =>
      () => {
        const entityRefs = selectedRequirementIds.map((requirementId) => ({
          type: DocumentTypes.Requirement,
          id: requirementId,
        }))
        mutateDocumentReferences(
          {
            entityRefs,
            operation: patchDocumentReferencesOperations.add,
            documentId,
          },
          {
            onSuccess: onCopySuccess({ selectedRequirementIds }),
            onError: onCopyError,
          },
        )
      },
    [mutateDocumentReferences, onCopyError, onCopySuccess],
  )

  return useMemo(() => ({ handleCopy }), [handleCopy])
}

export default useCopyDocumentsForRequirements
