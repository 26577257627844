import { Button, ButtonDesign, Modals, Text } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'components/domains/properties/valuation/valuation-results/PropertyValuationResultsTable.module.css'
import CancelButtonWithPopover from 'components/ui/button/CancelButtonWithPopover'
import LoadingButton from 'components/ui/button/LoadingButton'
import MessageBoxWithExpandableDetailsShowDetails from 'components/ui/dialog/MessageBoxWithExpandableDetailsShowDetails'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import ActionsCell from 'components/ui/tables/cells/ActionsCell'
import { usePropertyValuationCreation } from 'hooks/services/properties/valuations/creation/usePropertyValuationCreation'
import {
  setHighlightedValuations,
  stopValuationResultsAddMode,
} from 'redux/slices/properties/valuationResultsTableSlice'

const emptyObject = {}

const PropertyValuationResultsTableActionsCell = ({ isAddMode, propertyUuid }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.valuation.results.table',
  })
  const { t: tNoPrefix } = useTranslation()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()
  const { mutate: createValuation, isLoading: isValuationSaveLoading } =
    usePropertyValuationCreation({
      onSuccess: (data) => {
        queryClient.invalidateQueries(['properties', propertyUuid, 'cwp-valuations'])
        showToast({ children: t('save-confirmation.success') })
        dispatch(setHighlightedValuations(data?.valuationId ? [data.valuationId] : []))
        dispatch(stopValuationResultsAddMode())
      },
      onError: async (error) => {
        const errorMessage = await error.response.json()
        showMessageBox({
          type: MessageBoxTypes.Error,
          className: styles.errorMessageBox,
          children: (
            <>
              <Text>{t('save-confirmation.error')}</Text>{' '}
              <MessageBoxWithExpandableDetailsShowDetails messageDetails={errorMessage.message} />
            </>
          ),
        })
      },
    })

  const hasErrors = useSelector((state) => {
    const errorObject = state.properties.valuationResults.editedRow?.errorFields ?? emptyObject
    return Object.keys(errorObject).length > 0
  })

  const hasChanges = useSelector((state) => {
    const errorObject = state.properties.valuationResults.editedRow?.changedFields ?? emptyObject
    return Object.keys(errorObject).length > 0
  })

  const currentValues = useSelector(
    (state) => state.properties.valuationResults.editedRow?.currentValues,
  )

  const handleValuationSave = useCallback(() => {
    if (isNil(currentValues)) {
      return
    }
    createValuation({
      propertyId: propertyUuid,
      calculationMethodCode: currentValues.calculationMethodCode,
      value: currentValues.valueAmount?.value,
      currency: currentValues.valueAmount?.currency,
      keyDate: currentValues.keyDate,
      validFrom: currentValues.validFrom,
      validTo: currentValues.validTo,
    })
  }, [createValuation, currentValues, propertyUuid])

  const handleSaveClick = useCallback(() => {
    showMessageBox({
      actions: [
        <Button
          key="add-button"
          data-action={MessageBoxActions.OK}
          design={ButtonDesign.Emphasized}
        >
          {tNoPrefix('buttons.add')}
        </Button>,
        MessageBoxActions.Cancel,
      ],
      children: t('save-confirmation.message'),
      emphasizedAction: MessageBoxActions.OK,
      initialFocus: MessageBoxActions.OK,
      titleText: t('save-confirmation.title'),
      onClose: ({ detail: { action } }) => {
        if (action === MessageBoxActions.OK) {
          handleValuationSave()
        }
      },
      type: MessageBoxTypes.Confirm,
    })
  }, [handleValuationSave, showMessageBox, t, tNoPrefix])

  const handleCancelClick = useCallback(() => {
    dispatch(stopValuationResultsAddMode())
  }, [dispatch])

  const actions = useMemo(
    () => [
      <LoadingButton
        key="save-action"
        isLoading={isValuationSaveLoading}
        design={ButtonDesign.Emphasized}
        onClick={handleSaveClick}
        disabled={hasErrors}
        renderContent={() => tNoPrefix('buttons.save')}
      />,
      <CancelButtonWithPopover
        key="cancel-action"
        design={ButtonDesign.Transparent}
        showPopover={hasChanges}
        onCancelClicked={handleCancelClick}
      >
        {tNoPrefix('buttons.cancel')}
      </CancelButtonWithPopover>,
    ],
    [handleCancelClick, handleSaveClick, hasChanges, hasErrors, isValuationSaveLoading, tNoPrefix],
  )

  if (!isAddMode) {
    return
  }

  return <ActionsCell actions={actions} />
}

PropertyValuationResultsTableActionsCell.propTypes = {
  isAddMode: PropTypes.bool.isRequired,
  propertyUuid: PropTypes.string,
}

export default PropertyValuationResultsTableActionsCell
