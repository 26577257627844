import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { MapContainer } from 'react-leaflet'
import 'components/ui/map/LeafletPopupStyle.css'
import { EditControl } from 'react-leaflet-draw'
import styles from 'components/ui/map/Leaflet.module.css'
import {
  getBounds,
  getBoundingBoxCenter,
  mapGeometryPolygonObjectArrayToPolygonArrayArray,
  mapPolygonLatLngArrayToPolygonArrayArray,
  polygonsToCoordArray,
} from 'components/ui/map/MapPolygonUtils'
import LeafletTileLayerWrapper from 'components/ui/map/internal/LeafletTileLayerWrapper'

const DEFAULT_ZOOM_LEVEL = 15
const SHOW_MAP_ZOOM_LEVEL = 2

const EditableLeafletMapWithPolygonAndPropertyMarkers = ({
  color,
  polygon = [[[]]],
  markers,
  isEditMode,
  newPolygonPoints,
  propertyData,
  propertyIsLoading,
  propertyIsError,
  setNewPolygonPoints,
  uploadedPolygon,
  center,
  setCenter,
  bounds,
  setBounds,
  className,
}) => {
  useEffect(() => {
    const setInitialPolygonPoints = () => {
      if (polygon && polygon[0][0].length !== 0) {
        setNewPolygonPoints(polygon)
        const coordArray = polygonsToCoordArray(polygon)
        setCenter(getBoundingBoxCenter(coordArray))
        setBounds(getBounds(coordArray))
      }
    }
    polygon && setNewPolygonPoints && setInitialPolygonPoints()
  }, [polygon, setNewPolygonPoints, setCenter, setBounds])

  const isEditingEnabled = useMemo(() => {
    if (uploadedPolygon && uploadedPolygon.length === 1 && uploadedPolygon[0].length === 1)
      return true

    if (!uploadedPolygon && polygon && polygon.length === 1 && polygon[0].length === 1) return true

    return false
  }, [uploadedPolygon, polygon])

  let zoomLevel
  polygon[0][0].length === 0 ? (zoomLevel = SHOW_MAP_ZOOM_LEVEL) : (zoomLevel = DEFAULT_ZOOM_LEVEL)

  const handleOnCreated = (e) => {
    const latLngs = e.layer._latlngs.slice()
    const mappedPointArray = mapPolygonLatLngArrayToPolygonArrayArray(latLngs[0])
    setNewPolygonPoints(mappedPointArray)
  }

  const handleOnDelete = (event) => {
    event.layers.eachLayer(() => {
      setNewPolygonPoints([])
      return
    })
  }

  const handleOnEdit = (event) => {
    event.layers.eachLayer((layer) => {
      const geoJSON = layer.toGeoJSON()
      const pointArray = []
      pointArray.push(...geoJSON['geometry']['coordinates'])
      const mappedPointArray = mapGeometryPolygonObjectArrayToPolygonArrayArray(pointArray[0])
      setNewPolygonPoints(mappedPointArray)
    })
  }

  const editControl = (isDrawingAllowed, isEditingAllowed) => (
    <EditControl
      position="topright"
      onCreated={handleOnCreated}
      onEdited={handleOnEdit}
      onDeleted={handleOnDelete}
      draw={{
        // excluding the following draw features:
        polygon: isDrawingAllowed,
        polyline: false,
        rectangle: false,
        circle: false,
        marker: false,
        circlemarker: false,
      }}
      edit={{ edit: isEditingAllowed }}
    />
  )

  return (
    <MapContainer
      className={className || styles.leafletContainer}
      center={center}
      scrollWheelZoom={true}
    >
      <LeafletTileLayerWrapper
        color={color}
        polygon={polygon}
        markers={markers}
        center={center}
        propertyData={propertyData}
        propertyIsLoading={propertyIsLoading}
        propertyIsError={propertyIsError}
        bounds={bounds}
        zoom={zoomLevel}
        isEditMode={isEditMode}
        newPolygonPoints={newPolygonPoints}
        isEditingPolygonEnabled={isEditingEnabled}
        editControl={editControl}
        uploadedPolygon={uploadedPolygon}
      />
    </MapContainer>
  )
}

EditableLeafletMapWithPolygonAndPropertyMarkers.propTypes = {
  color: PropTypes.object,
  polygon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  initialZoomLevel: PropTypes.number,
  propertyData: PropTypes.shape({
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
        financingStatusCode: PropTypes.string,
      }),
    ),
  }).isRequired,
  propertyIsLoading: PropTypes.bool,
  propertyIsError: PropTypes.bool,
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      address: PropTypes.exact({
        country: PropTypes.string,
        street: PropTypes.string,
        houseNumber: PropTypes.string,
        zipCode: PropTypes.string,
        city: PropTypes.string,
      }).isRequired,
      propertyType: PropTypes.string,
      location: PropTypes.exact({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      }),
    }),
  ),
  isEditMode: PropTypes.bool.isRequired,
  newPolygonPoints: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
      ),
    ),
  ).isRequired,
  setNewPolygonPoints: PropTypes.func.isRequired,
  uploadedPolygon: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
      ),
    ),
  ),
  center: PropTypes.array.isRequired,
  setCenter: PropTypes.func.isRequired,
  bounds: PropTypes.array.isRequired,
  setBounds: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default EditableLeafletMapWithPolygonAndPropertyMarkers
