import {
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'

const NoPropertyAssignedToDealInfo = () => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.property',
  })

  return (
    <IllustratedMessage
      name={IllustrationMessageType.NoTasks}
      titleText={t('no-property-linked-to-deal')}
      size={IllustrationMessageSize.Scene}
      subtitleText=" "
    />
  )
}

export default NoPropertyAssignedToDealInfo
