import PropTypes from 'prop-types'
import { useCallback, useState, forwardRef } from 'react'
import BusinessObjectSelectionHelperDialog from 'components/domains/conditions/business-object/BusinessObjectSelectionHelperDialog'
import BusinessObjectSelectionInput from 'components/domains/conditions/business-object/BusinessObjectSelectionInput'

//delete me?
const EventBusinessObjectFilter = forwardRef(
  ({ businessObjectName, onBusinessObjectChange, disabled = false }, _ref) => {
    const [isHelperDialogOpen, setIsHelperDialogOpen] = useState(false)

    const onCancelBusinessObjectSelection = useCallback(() => {
      setIsHelperDialogOpen(false)
    }, [])

    const onClickBusinessObjectSelection = useCallback(() => {
      setIsHelperDialogOpen(true)
    }, [])

    const onSelectionChanged = useCallback(
      ({ entityId, entityType, entityName }) => {
        onBusinessObjectChange({ entityId, entityType, entityName })
        setIsHelperDialogOpen(false)
      },
      [onBusinessObjectChange],
    )

    const onClear = useCallback(() => {
      onBusinessObjectChange('')
    }, [onBusinessObjectChange])

    return (
      <>
        <BusinessObjectSelectionInput
          name={businessObjectName}
          disabled={disabled}
          onClick={onClickBusinessObjectSelection}
          onClear={onClear}
        />
        <BusinessObjectSelectionHelperDialog
          isOpen={isHelperDialogOpen}
          onCancel={onCancelBusinessObjectSelection}
          onSelect={onSelectionChanged}
          addDealSearch={true}
        />
      </>
    )
  },
)

EventBusinessObjectFilter.displayName = 'EventBusinessObjectFilter'

EventBusinessObjectFilter.propTypes = {
  businessObjectName: PropTypes.string,
  onBusinessObjectChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default EventBusinessObjectFilter
