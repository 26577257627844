import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const OpsMemoSyndicationTrancheSelect = ({
  tranches,
  syndicationTranche,
  setSyndicationTranche,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.syndication-structure',
  })

  const allTrancheIds = useMemo(() => tranches.map((tranche) => tranche.trancheId), [tranches])

  return (
    <Select
      id="select-syndicationTranche"
      onChange={(event) => {
        const id = event?.detail?.selectedOption?.dataset?.id
        if (!id) {
          setSyndicationTranche(allTrancheIds)
        } else {
          setSyndicationTranche([id])
        }
      }}
    >
      <Option key={allTrancheIds} data-id={''}>
        {t('select-all')}
      </Option>
      {tranches.map((tranche) => (
        <Option
          key={tranche?.trancheId}
          data-id={tranche?.trancheId}
          selected={syndicationTranche.length === 1 && syndicationTranche[0] === tranche?.trancheId}
        >
          {tranche.trancheName}
        </Option>
      ))}
    </Select>
  )
}

OpsMemoSyndicationTrancheSelect.propTypes = {
  tranches: PropTypes.arrayOf(
    PropTypes.shape({
      trancheId: PropTypes.string,
      trancheName: PropTypes.string,
    }),
  ).isRequired,
  syndicationTranche: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSyndicationTranche: PropTypes.func.isRequired,
}

export default OpsMemoSyndicationTrancheSelect
