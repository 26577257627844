import { CardHeader } from '@fioneer/ui5-webcomponents-react'
import { countBy } from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/portfolio/overview/PropertyPortfolioInformationCard.module.css'
import { PropertyPortfolioOverviewCardItem } from 'components/domains/properties/portfolio/overview/PropertyPortfolioOverviewCardItem'
import Card from 'components/ui/card/Card'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useTypecodes } from 'hooks/services/properties/useTypecodes'

const getDisplayNameByKey = (list, selection) =>
  (list ?? []).find(({ key }) => key === selection)?.display_name

const PropertyPortfolioInformationCard = ({ properties, className = '' }) => {
  const { t: tInfo } = useTranslation('translation', {
    keyPrefix: 'pages.properties.portfolio.overview.information',
  })
  const { t } = useTranslation()
  const {
    data: typecodeData,
    isLoading: typeCodesLoading,
    isError: typeCodesError,
  } = useTypecodes()

  // CWP-16174: Improve performance and decrease load on backend system for Deal -> Financed objects page
  // Different tiles on the Deal -> Financed objects page require similar valuations and kpi data.
  // There are slightly different requirements for each tile with respect to needed aggregations and granularities.
  // We now request the same superset of valuation and kpi data at each location, so the result is just one single request
  // A further improvement idea might be: Move the valuation and kpi data to a context.
  // Look for similar comments like this to find other consolidated hook usages.
  // Some examples are:  DealPropertiesHeader, DealPropertiesTable, PropertyPortfolioInformationCard,
  // PropertyPortfolioOverviewTableCard and usePropertyPortfolioOverviewPieChartCardData

  const [countries, mainTypeCode] = useMemo(() => {
    const countryNameSet = new Set()
    const typeCodes = []
    properties.forEach((property) => {
      countryNameSet.add(property?.address?.country_name)
      typeCodes.push(property.type_code)
    })
    const typeCode = Object.entries(countBy(typeCodes)).sort((a, b) => b[1] - a[1])?.[0]?.[0]
    return [Array.from(countryNameSet), typeCode]
  }, [properties])

  const mainTypeCodeName = useMemo(
    () => getDisplayNameByKey(typecodeData?.typecodes, mainTypeCode),
    [mainTypeCode, typecodeData?.typecodes],
  )
  return (
    <Card
      header={<CardHeader titleText={tInfo('title')} />}
      className={`${styles.card} ${className}`}
    >
      <div className={styles.cardContentWrapper}>
        <LoadingStateWrapper
          loadingDescription={t('pages.properties.portfolio.overview.card-loading')}
          isLoading={typeCodesLoading}
          isError={false}
        >
          <form>
            <PropertyPortfolioOverviewCardItem
              label={tInfo('label.properties-count')}
              value={properties.length}
            />
            <PropertyPortfolioOverviewCardItem
              label={tInfo('label.main-property-type')}
              value={mainTypeCodeName}
              isError={typeCodesError}
            />
            <PropertyPortfolioOverviewCardItem
              label={tInfo('label.countries')}
              value={countries?.join(', ')}
            />
          </form>
        </LoadingStateWrapper>
      </div>
    </Card>
  )
}

PropertyPortfolioInformationCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  properties: PropTypes.array.isRequired,
  className: PropTypes.string,
}

export default PropertyPortfolioInformationCard
