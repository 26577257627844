import { FlexBox, FlexBoxAlignItems, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import DecisionPaperSubsectionCommentsButton from 'components/domains/business-events-and-tasks/decision-paper/comments/DecisionPaperSubsectionCommentButton'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const allowedOperationsForCommentButton = [
  businessEventAllowedOperations.readEvent,
  businessEventAllowedOperations.readDecisionPaper,
  businessEventAllowedOperations.updateDecisionPaper,
]

const DecisionPaperSubsectionCommentWrapper = ({
  version,
  subsectionId,
  hideCommentButton = false,
}) => {
  const { allowedOperations } = useContext(BusinessEventsAndTasksContext)

  const displayCommentButton =
    allowedOperationsForCommentButton.every((allowedOperation) =>
      allowedOperations.includes(allowedOperation),
    ) && !hideCommentButton

  return (
    <>
      <FlexBox direction={FlexBoxDirection.Column} alignItems={FlexBoxAlignItems.End}>
        {displayCommentButton && (
          <DecisionPaperSubsectionCommentsButton version={version} subsectionId={subsectionId} />
        )}
      </FlexBox>
    </>
  )
}

DecisionPaperSubsectionCommentWrapper.propTypes = {
  version: PropTypes.string.isRequired,
  subsectionId: PropTypes.string.isRequired,
  hideCommentButton: PropTypes.bool,
}

export default DecisionPaperSubsectionCommentWrapper
