import {
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { neededOperationsForDecisionPaperRead } from 'api/events/eventAllowedOperations'
import { hasUserRequiredOperations } from 'api/helper'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionPaperPdfExportPermissionWrapper = ({ children }) => {
  const { t: tNoPrefix } = useTranslation()
  const { allowedOperations } = useContext(BusinessEventsAndTasksContext)

  if (!hasUserRequiredOperations(neededOperationsForDecisionPaperRead, allowedOperations)) {
    return (
      <IllustratedMessage
        size={IllustrationMessageSize.Spot}
        name={IllustrationMessageType.UnableToLoad}
        titleText={tNoPrefix('app.permission-error.title')}
        subtitleText={tNoPrefix('app.permission-error.subtitle')}
      />
    )
  }
  return children
}

DecisionPaperPdfExportPermissionWrapper.propTypes = {
  children: PropTypes.any,
}

export default DecisionPaperPdfExportPermissionWrapper
