import { Button } from '@fioneer/ui5-webcomponents-react'
import { ticks as ticksCalculation } from 'd3-array'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import KpiChartStartEndValueLabel from 'components/ui/charts/kpi-chart/KpiChartStartEndValueLabel'
import KpiChartTooltip from 'components/ui/charts/kpi-chart/KpiChartTooltip'
import {
  calculateZoomAreaWidth,
  calculateZoomTimestamp,
  enrichKpiDataWithValidityDateTimeStamp,
  isBaseLineValueOrClosestToBaseLineValue,
  SETTINGS,
} from 'components/ui/charts/kpi-chart/kpiChartUtil'
import { useNumberFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'

const KpiChart = ({
  kpiName,
  kpiData,
  kpiUnit,
  augmented,
  threshold,
  thresholdLabel,
  baselineDate,
  intervalMonths,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.ui.charts.kpi-chart' })
  const formatCompactNumber = useNumberFormatter({
    notation: 'compact',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    trailingZeroDisplay: 'stripIfInteger',
  })
  const { format } = useShortDateFormatter()

  const chartRef = useRef(null)
  const containerRef = useRef(null)
  const [chartWidth, setChartWidth] = useState(SETTINGS.MIN_WIDTH)
  const [chartHeight, setChartHeight] = useState(SETTINGS.MIN_HEIGHT)
  const [containerHeight, setContainerHeight] = useState(0)
  const [isZoomSelectionInProgress, setIsZoomSelectionInProgress] = useState(false)
  const [showZoomOutButton, setShowZoomOutButton] = useState(false)
  const [zoomAreaSelection, setZoomAreaSelection] = useState(SETTINGS.DEFAULT_ZOOM)
  const [zoomedData, setZoomedData] = useState(
    enrichKpiDataWithValidityDateTimeStamp(kpiData, baselineDate),
  )

  const calculateInitialUnixTimestamps = useCallback(() => {
    if (!intervalMonths) return

    const todaysDateUnixTimestamp = new Date()
    const startDateUnixTimestamp = new Date()
    startDateUnixTimestamp.setMonth(startDateUnixTimestamp.getMonth() - intervalMonths)

    return {
      start: startDateUnixTimestamp.getTime(),
      end: todaysDateUnixTimestamp.getTime(),
    }
  }, [intervalMonths])

  const [augmentedZoomedStartEndUnixTimestamps, setAugmentedZoomedStartUnixTimestamps] = useState(
    calculateInitialUnixTimestamps(),
  )

  const handleOnMouseDown = (e) => {
    if (augmented) {
      setIsZoomSelectionInProgress(true)
      if (e) {
        if (e.chartX < 0) {
          setZoomAreaSelection({ x1: 0, x2: 0 })
          return
        }
        setZoomAreaSelection({ x1: e.chartX, x2: e.chartX })
      }
    }
  }

  const handleOnMouseMove = (e) => {
    if (isZoomSelectionInProgress) {
      if (e.chartX) {
        if (e.chartX < 0) {
          setZoomAreaSelection(({ x1 }) => ({ x1: 0, x2: x1 }))
        }
        setZoomAreaSelection(({ x1, x2 }) => {
          if (x1 < e.chartX) {
            return { x1, x2: e.chartX }
          }
          return { x1: e.chartX, x2 }
        })
      }
    }
  }

  const handleOnMouseUp = () => {
    if (isZoomSelectionInProgress) {
      // Zooming has ended now
      setIsZoomSelectionInProgress(false)
      setZoomAreaSelection(SETTINGS.DEFAULT_ZOOM)

      const { x1: startZoomPoint, x2: endZoomPoint } = zoomAreaSelection

      const zoomChartWidth = Math.abs(startZoomPoint - endZoomPoint)
      if (zoomChartWidth < 10) return // clicks and small areas should not zoom in

      // The YAxis width needs to be removed for correct index calulations
      const normalizedStartZoomPoint = startZoomPoint - SETTINGS.YAXIS_WIDTH
      const normalizedEndZoomPoint = endZoomPoint - SETTINGS.YAXIS_WIDTH
      const normalizedChartWidth = chartWidth - SETTINGS.YAXIS_WIDTH

      const zoomedStartDateUnixTimestamp = calculateZoomTimestamp(
        normalizedStartZoomPoint,
        normalizedChartWidth,
        augmentedZoomedStartEndUnixTimestamps,
      )

      const zoomedEndDateUnixTimestamp = calculateZoomTimestamp(
        normalizedEndZoomPoint,
        normalizedChartWidth,
        augmentedZoomedStartEndUnixTimestamps,
      )

      setZoomedData(
        enrichKpiDataWithValidityDateTimeStamp(kpiData, baselineDate).filter(
          (dataPoint) =>
            dataPoint.timestamp >= zoomedStartDateUnixTimestamp &&
            dataPoint.timestamp <= zoomedEndDateUnixTimestamp,
        ),
      )

      setAugmentedZoomedStartUnixTimestamps({
        start: zoomedStartDateUnixTimestamp,
        end: zoomedEndDateUnixTimestamp,
      })
      setShowZoomOutButton(true)
    }
  }

  const resetZoom = useCallback(() => {
    setIsZoomSelectionInProgress(false)
    setShowZoomOutButton(false)
    setZoomedData(enrichKpiDataWithValidityDateTimeStamp(kpiData, baselineDate))
    setAugmentedZoomedStartUnixTimestamps(calculateInitialUnixTimestamps())
  }, [kpiData, baselineDate, calculateInitialUnixTimestamps])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (chartRef.current && chartRef.current.current) {
      const chartRect = chartRef.current.current.getBoundingClientRect()
      const width = Math.round(chartRect.width)
      const height = Math.round(chartRect.height)
      if (width > 1 && height > 1) {
        setChartWidth(width)
        setChartHeight(height)
      }
    }
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect()
      const height = Math.round(containerRect.height)
      if (height > SETTINGS.MAX_HEIGHT) {
        setContainerHeight(SETTINGS.MAX_HEIGHT)
      } else if (height > 0) {
        setContainerHeight(height)
      }
    }
  })

  useEffect(() => {
    resetZoom()
  }, [kpiData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAugmentedZoomedStartUnixTimestamps(calculateInitialUnixTimestamps())
  }, [intervalMonths, calculateInitialUnixTimestamps])

  const CustomizedBaselineDot = ({ index, fill, dataKey: _dataKey, ...props }) => {
    if (isBaseLineValueOrClosestToBaseLineValue({ index, baselineDate, data: zoomedData })) {
      return (
        <circle
          {...props}
          stroke={fill === '#fff' ? SETTINGS.BASELINE_COLOR : '#fff'}
          fill={fill === '#fff' ? '#fff' : SETTINGS.BASELINE_COLOR}
        />
      )
    }
    return <circle fill={fill} {...props} />
  }

  CustomizedBaselineDot.propTypes = {
    index: PropTypes.number,
    fill: PropTypes.any,
    dataKey: PropTypes.any,
  }

  const yAxisTickCount = useMemo(() => {
    if (zoomedData.length === 0) {
      if (threshold) {
        return 1
      }
      return 0
    }
    return SETTINGS.DEFAULT_Y_TICK_COUNT
  }, [zoomedData, threshold])

  const yAxisTicks = useMemo(() => {
    if (zoomedData.length === 0) {
      if (threshold) {
        return [threshold]
      }
      return []
    }

    let maxValue = Number.MIN_SAFE_INTEGER
    let minValue = Number.MAX_SAFE_INTEGER

    zoomedData.forEach(({ value }) => {
      if (value > maxValue) {
        maxValue = value
      }
      if (value < minValue) {
        minValue = value
      }
    })

    if (maxValue < threshold) {
      maxValue = threshold
    }

    if (minValue > threshold) {
      minValue = threshold
    }

    const tenPercent = 0.1
    const deltaBetweenMaxAndMinValue = (maxValue - minValue) * tenPercent

    const tickCalculation = ticksCalculation(
      minValue - deltaBetweenMaxAndMinValue,
      maxValue + deltaBetweenMaxAndMinValue,
      yAxisTickCount - 1,
    )
    return tickCalculation
  }, [zoomedData, yAxisTickCount, threshold])

  return (
    <div
      style={{
        width: augmented ? '100%' : '99%',
        height: containerHeight > SETTINGS.MAX_HEIGHT && !augmented ? SETTINGS.MAX_HEIGHT : '90%',
      }}
      ref={containerRef}
      data-testid={'kpi-chart-' + kpiName}
    >
      {showZoomOutButton && (
        <Button
          onClick={resetZoom}
          icon="zoom-out"
          style={{ position: 'absolute', top: 79, right: 32 }}
          data-testid="zoom-out-btn"
        />
      )}
      {!augmented && (
        <KpiChartStartEndValueLabel
          kpiUnit={kpiUnit}
          startValue={zoomedData[0]?.value}
          endValue={kpiData.length <= 1 ? null : zoomedData[zoomedData.length - 1].value}
        />
      )}
      <ResponsiveContainer
        minWidth={SETTINGS.MIN_WIDTH}
        minHeight={SETTINGS.MIN_HEIGHT}
        maxHeight={augmented ? '99%' : SETTINGS.MAX_HEIGHT}
        ref={chartRef}
      >
        <LineChart
          data={zoomedData}
          onMouseDown={handleOnMouseDown}
          onMouseMove={handleOnMouseMove}
          onMouseUp={handleOnMouseUp}
        >
          <XAxis
            dataKey="timestamp"
            tickLine={true}
            interval="preserveStartEnd"
            allowDecimals={false}
            height={36}
            tickCount={SETTINGS.DEFAULT_X_TICK_COUNT}
            type="number"
            tickFormatter={(date) => `${format(DateTime.fromMillis(date).toISODate())} `} // empty character added so that the tick at the end does not get cut off
            domain={() => {
              if (augmented)
                return [
                  augmentedZoomedStartEndUnixTimestamps.start,
                  augmentedZoomedStartEndUnixTimestamps.end,
                ]
              const today = new Date()
              const threeYearsAgo = new Date()
              // eslint-disable-next-line no-magic-numbers
              threeYearsAgo.setFullYear(today.getFullYear() - 3)
              return [threeYearsAgo.valueOf(), today.valueOf()]
            }}
          />
          <YAxis
            hide={!augmented}
            axisLine={!augmented}
            width={SETTINGS.YAXIS_WIDTH}
            domain={[yAxisTicks[0], yAxisTicks[yAxisTicks.length - 1]]}
            tickLine={!augmented}
            allowDecimals={false}
            interval={0}
            padding={{ bottom: 10 }}
            ticks={yAxisTicks}
            tickCount={yAxisTicks.length}
            tickFormatter={(value) => formatCompactNumber(value)}
            type="number"
          />
          {augmented && (
            <>
              <Tooltip
                content={<KpiChartTooltip kpiUnit={kpiUnit} />}
                wrapperStyle={{
                  backgroundColor: 'white',
                }}
              />
              <svg>
                <rect
                  x={zoomAreaSelection.x1}
                  y={0}
                  width={calculateZoomAreaWidth(zoomAreaSelection)}
                  height={chartHeight}
                  style={{ fill: '#ccc', stroke: '#999', strokeWidth: 2, opacity: 0.3 }}
                  data-testid="zoom-area-selection"
                />
              </svg>
            </>
          )}
          {augmented && <CartesianGrid vertical={false} stroke="#E5E5E5" />}
          {threshold && (
            <>
              <ReferenceLine
                ifOverflow="extendDomain"
                strokeDasharray={SETTINGS.STROKE_DASH}
                stroke="black"
                y={threshold}
                id={`reference-line-${kpiName}-y-reference-value`}
              >
                {augmented && <Label position="insideBottomLeft">{thresholdLabel}</Label>}
              </ReferenceLine>
              <Line
                name={t('threshold')}
                dataKey="non-existing"
                stroke="black"
                strokeDasharray={SETTINGS.STROKE_DASH}
              />
            </>
          )}
          {baselineDate && (
            <>
              <ReferenceLine
                ifOverflow="hidden"
                strokeDasharray={SETTINGS.STROKE_DASH}
                stroke={SETTINGS.BASELINE_COLOR}
                x={new Date(baselineDate).getTime()}
                id={`reference-line-${kpiName}-x-reference-value`}
              >
                {augmented && <Label position="insideTopRight">{format(baselineDate)}</Label>}
              </ReferenceLine>
              <Line
                name={t('baseline-date')}
                dataKey="non-existing"
                stroke={SETTINGS.BASELINE_COLOR}
                strokeDasharray={SETTINGS.STROKE_DASH}
              />
            </>
          )}
          {augmented && (baselineDate || threshold) && (
            <Legend
              layout="horizontal"
              align="center"
              verticalAlign="bottom"
              iconType="plainline"
            />
          )}
          <ReferenceLine y={0} id={`reference-line-${kpiName}-y0`} />
          <Line
            name={kpiName}
            type="stepAfter"
            isAnimationActive={false}
            dataKey="value"
            activeDot={augmented && <CustomizedBaselineDot />}
            dot={<CustomizedBaselineDot />}
            strokeWidth={1.5}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

KpiChart.propTypes = {
  kpiName: PropTypes.string.isRequired,
  kpiData: PropTypes.arrayOf(
    PropTypes.shape({
      validity_date: PropTypes.string.isRequired,
      receipt_date: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  kpiUnit: PropTypes.string.isRequired,
  threshold: PropTypes.number,
  thresholdLabel: PropTypes.string,
  baselineDate: PropTypes.string,
  baselineValue: PropTypes.number,
  augmented: PropTypes.bool.isRequired,
  intervalMonths: PropTypes.number,
}

export default KpiChart
