import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import { ListItemType, SuggestionItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import useEventEntityNameSuggestions from 'hooks/services/business-events-and-tasks/events/useEventEntityNameSuggestions'

const EventEntityRefNameSearchSuggestions = ({
  entityType,
  loadingText,
  errorText,
  emptyResultsText,
  currentSearchValue,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.entity-suggestions',
  })
  const {
    isLoading,
    isError,
    data: entitySuggestions,
  } = useEventEntityNameSuggestions(entityType, currentSearchValue)

  if (isLoading) {
    return (
      <SuggestionItem key="suggestion-loading" text={loadingText} type={ListItemType.Inactive} />
    )
  }
  if (isError) {
    return <SuggestionItem key="suggestion-error" text={errorText} type={ListItemType.Inactive} />
  }
  if (entitySuggestions.entitySuggestions.length === 0) {
    return (
      <SuggestionItem key="suggestion-empty" text={emptyResultsText} type={ListItemType.Inactive} />
    )
  }
  return entitySuggestions.entitySuggestions.map(
    ({ entityId, entityDisplayId, entityName, isWorkingVersion }) => {
      const text = isWorkingVersion
        ? t('working-version', { entityName, entityDisplayId })
        : t('non-working-version', { entityName, entityDisplayId })
      return (
        <SuggestionItem
          key={`suggestion-${entityId}`}
          text={text}
          data-entity-id={entityId}
          data-is-working-version={isWorkingVersion}
        />
      )
    },
  )
}

EventEntityRefNameSearchSuggestions.propTypes = {
  entityType: PropTypes.string.isRequired,
  loadingText: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
  emptyResultsText: PropTypes.string.isRequired,
  currentSearchValue: PropTypes.string.isRequired,
}

export default EventEntityRefNameSearchSuggestions
