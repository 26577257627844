import { Button, ButtonDesign, MessageBoxTypes } from '@fioneer/ui5-webcomponents-react/dist'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import MessageBox from 'components/ui/message-box/MessageBox'

const DecisionPaperSynchronizationDialogs = ({ isConfirmationDialogOpen, onConfirm, onCancel }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper',
  })
  return (
    <>
      {isConfirmationDialogOpen && (
        <MessageBox
          type={MessageBoxTypes.Confirm}
          open={isConfirmationDialogOpen}
          actions={[
            <Button key="confirm" design={ButtonDesign.Emphasized} onClick={onConfirm}>
              {tNoPrefix('buttons.confirm')}
            </Button>,
            <Button key="cancel" onClick={onCancel}>
              {tNoPrefix('buttons.cancel')}
            </Button>,
          ]}
          onClose={onCancel}
        >
          <p>{t('synchronization.confirmation.line-1')}</p>
          <p>{t('synchronization.confirmation.line-2')}</p>
        </MessageBox>
      )}
    </>
  )
}

DecisionPaperSynchronizationDialogs.propTypes = {
  isConfirmationDialogOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default DecisionPaperSynchronizationDialogs
