import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useUpdateMarketMicroProxies = (mutateOptions) => {
  const { post } = useAccessTokenRequest()

  return useMutation(
    ({ marketId, microProxies }) =>
      post({
        path: `/property-monitoring/proxies/micros`,
        body: snakecaseKeys({
          marketId,
          overrideMicroProxySettings: microProxies,
        }),
      }),
    mutateOptions,
  )
}
