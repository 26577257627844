import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useEditRequirementReminder = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(
    async ({ requirementId, reminderId, reminder }) =>
      patch({
        path: `/conditions/external/requirements/${requirementId}/reminders/${reminderId}`,
        body: {
          comment: reminder.comment,
          method: {
            code: reminder.method,
          },
          date: reminder.date,
        },
      }),
    mutationOptions,
  )
}

export default useEditRequirementReminder
