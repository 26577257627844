import compact from 'lodash.compact'
import uniq from 'lodash.uniq'

const extractBpIdsFromMultipleSyndicationsExistingBusinessData = (
  multipleSyndicationsExistingBusinessData,
) =>
  uniq(
    compact(
      multipleSyndicationsExistingBusinessData.flatMap(({ syndications, tranches } = {}) => {
        const syndicationsBpIds = syndications?.flatMap(({ bpId } = {}) => bpId)
        const tranchesSyndicationsBpIds = tranches?.flatMap(
          ({ syndications: tranchesSyndications } = {}) =>
            tranchesSyndications?.flatMap(({ bpId } = {}) => bpId),
        )
        return (syndicationsBpIds ?? []).concat(tranchesSyndicationsBpIds)
      }),
    ),
  ) ?? []

export default extractBpIdsFromMultipleSyndicationsExistingBusinessData
