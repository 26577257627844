import isEmpty from 'lodash.isempty'
import usePropertiesKpis from 'hooks/services/properties/kpis/usePropertiesKpis'

const getCurrentKpisPerProperty = (propertyRentRollKpis) =>
  propertyRentRollKpis
    ?.map((propertyRentRollKpi) => {
      if (isEmpty(propertyRentRollKpi?.keyDateToRentRollKpis)) {
        return null
      }
      return {
        propertyUuid: propertyRentRollKpi.propertyUuid,
        keyDate: propertyRentRollKpi.keyDateToRentRollKpis[0]?.keyDate,
        ...propertyRentRollKpi.keyDateToRentRollKpis[0]?.kpis,
      }
    })
    .filter((kpi) => kpi !== null) ?? []

const useCurrentPropertiesKpis = (
  propertyUuids,
  { currency, areaUnitOfMeasurement, withTenantGranularity = false } = {},
  queryOptions = {},
) => {
  const { data, isError, isLoading } = usePropertiesKpis(
    propertyUuids,
    { keyDates: null, currency, areaUnitOfMeasurement, withTenantGranularity },
    queryOptions,
  )

  return {
    data: getCurrentKpisPerProperty(data),
    isError,
    isLoading,
  }
}

export default useCurrentPropertiesKpis
