import * as UI5React from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import ErrorBoundary from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/pd-rating/shared/ui/errors/ErrorBoundary'

const propTypes = {
  children: PropTypes.node,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
}

/**
 * A wrapping component for the UI5 Card, in order to have error boundaries on card level,
 * instead of the default page/tab level.
 * This acts as a drop in replacement for any prior usage of the UI5 Card.
 *
 * @typedef {Parameters<typeof UI5React.Card>[0]} cardProps
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof cardProps> & cardProps} props
 */
const Card = ({ children, header, ...cardProps }) => (
  <ErrorBoundary fallbackProps={{ Wrapper: UI5React.Card }}>
    <UI5React.Card header={header} {...cardProps}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </UI5React.Card>
  </ErrorBoundary>
)

Card.propTypes = propTypes

export default Card
