import camelize from 'camelize'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/FinancingTermsTile.module.css'
import DateField from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/fields/DateField'
import MoneyPercentageField from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/fields/MoneyPercentageField'
import PercentageField from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/fields/PercentageField'
import TextField from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/fields/TextField'

const EXPECTED_SIGNED_DATE_TYPE = 'EXP_SIGN'
const EXCLUDE_OTHER_FEES = ['Upfront Fee', 'Arrangement Fee', 'Commitment Fee', 'Prepayment Fee']

const FinancingTermsPricingTile = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.financing-terms.pricing',
  })
  const tileState = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const trancheData = tileState?.data?.trancheData
  const pricingData = camelize(tileState?.data?.pricingData)
  const pricingCustomFields = camelize(tileState?.data?.pricingCustomFields)
  const milestoneData = tileState?.data?.milestoneData

  const differenceBetweenDatesInYears = (startDate, endDate) => {
    const dateStart = dayjs(startDate)
    const dateEnd = dayjs(endDate)
    const differenceInYears = dateEnd.diff(dateStart, 'year', true)
    return differenceInYears
  }

  const renderEconomicProfitAmountDirectCost = () =>
    pricingData?.economicProfitFromCustomerDerivative && (
      <MoneyPercentageField
        label={t('economic-profit-from-customer-derivative')}
        amount={pricingData?.economicProfitFromCustomerDerivative?.number}
        currency={pricingData?.economicProfitFromCustomerDerivative?.currencyCode}
        percentage={pricingData?.economicProfitMarginDirectCost}
      />
    )

  const renderDirectCost = () =>
    pricingData?.economicProfitAmountFullCost?.number <= 0 && (
      <MoneyPercentageField
        label={t('direct-cost')}
        amount={pricingData?.economicProfitAmountDirectCost?.number}
        currency={pricingData?.economicProfitAmountDirectCost?.currencyCode}
        percentage={pricingData?.economicProfitMarginDirectCost}
      />
    )

  const renderLowerCost = () =>
    (pricingData?.economicProfitAmountFullCost?.number > 0 ||
      pricingData?.economicProfitAmountDirectCost?.number <= 0) && (
      <MoneyPercentageField
        label={t('lower-cost')}
        amount={pricingData?.economicProfitAmountLowerCost?.number}
        currency={pricingData?.economicProfitAmountLowerCost?.currencyCode}
        percentage={pricingData?.economicProfitMarginLowerCost}
      />
    )

  const renderClosingDate = () => {
    const expectedSignedDate = milestoneData?.find(
      (milestone) => milestone.typeCode === EXPECTED_SIGNED_DATE_TYPE,
    )

    return (
      expectedSignedDate && (
        <DateField label={t('closing-date')} value={expectedSignedDate.targetDate} />
      )
    )
  }

  const renderPercentageField = (label, value) => <PercentageField label={t(label)} value={value} />

  const renderUpfrontArrangementFee = () => {
    let amount = null
    let currency = null
    const percentage = null
    const differenceInYearsErrors = []
    const tranches = trancheData?.tranches

    if (tranches?.length > 0) {
      for (const tranche of trancheData.tranches) {
        if (tranche.maturityDate && tranche.firstDrawdownDate) {
          const maturityDate = new Date(tranche.maturityDate)
          const firstDrawdownDate = new Date(tranche.firstDrawdownDate)
          const differenceInYears = differenceBetweenDatesInYears(firstDrawdownDate, maturityDate)

          // only Upfront or Arrangement fees
          const fees = tranche.fees.filter(
            (fee) => fee.feeTypeCode === 'UpfrontFee' || fee.feeTypeCode === 'ArrangementFee',
          )

          let trancheFeesTotalAmount = null
          for (const fee of fees) {
            const isHeadquarter =
              fee?.amountTotal?.currency !== fee?.amountTotalHeadquarter?.currency

            switch (fee.paymentFrequencyType) {
              case 'Once':
                if (isHeadquarter) {
                  trancheFeesTotalAmount += fee?.amountTotalHeadquarter?.amount
                  currency = fee?.amountTotalHeadquarter?.currency
                } else {
                  trancheFeesTotalAmount += fee?.amountTotal?.amount
                  currency = fee?.amountTotal?.currency
                }
                break
              case 'Monthly':
                if (isHeadquarter) {
                  trancheFeesTotalAmount +=
                    // eslint-disable-next-line no-magic-numbers
                    fee?.amountTotalHeadquarter?.amount * 12 * differenceInYears
                  currency = fee?.amountTotalHeadquarter?.currency
                } else {
                  // eslint-disable-next-line no-magic-numbers
                  trancheFeesTotalAmount += fee?.amountTotal?.amount * 12 * differenceInYears
                  currency = fee?.amountTotal?.currency
                }
                break
              case 'Quarterly':
                if (isHeadquarter) {
                  trancheFeesTotalAmount +=
                    // eslint-disable-next-line no-magic-numbers
                    fee?.amountTotalHeadquarter?.amount * 4 * differenceInYears
                  currency = fee?.amountTotalHeadquarter?.currency
                } else {
                  // eslint-disable-next-line no-magic-numbers
                  trancheFeesTotalAmount += fee?.amountTotal?.amount * 4 * differenceInYears
                  currency = fee?.amountTotal?.currency
                }
                break
              case 'Half-Yearly':
                if (isHeadquarter) {
                  trancheFeesTotalAmount +=
                    fee?.amountTotalHeadquarter?.amount * 2 * differenceInYears
                  currency = fee?.amountTotalHeadquarter?.currency
                } else {
                  trancheFeesTotalAmount += fee?.amountTotal?.amount * 2 * differenceInYears
                  currency = fee?.amountTotal?.currency
                }
                break
              case 'Yearly':
                if (isHeadquarter) {
                  trancheFeesTotalAmount +=
                    fee?.amountTotalHeadquarter?.amount * 1 * differenceInYears
                  currency = fee?.amountTotalHeadquarter?.currency
                } else {
                  trancheFeesTotalAmount += fee?.amountTotal?.amount * 1 * differenceInYears
                  currency = fee?.amountTotal?.currency
                }
                break
              default:
                break
            }
          }

          amount += trancheFeesTotalAmount
        } else {
          if (tranche.id) {
            differenceInYearsErrors.push(tranche.id)
          }
        }
      }
    }

    return (
      <MoneyPercentageField
        label={t('upfront-arrangement-fee')}
        amount={amount}
        currency={currency}
        percentage={percentage}
        hasError={differenceInYearsErrors.length > 0}
      />
    )
  }

  const renderOtherFees = () => {
    const tranches = trancheData?.tranches

    if (tranches?.length > 0) {
      const allUniqueTranchesFees = tranches
        ?.flatMap((tranche) => tranche?.fees, [])
        .map((fee) => fee?.feeTypeShortText)
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((fee) => !EXCLUDE_OTHER_FEES.includes(fee))
        .sort((a, b) => a.localeCompare(b))

      return (
        <TextField
          label={t('other-fees')}
          value={allUniqueTranchesFees.length > 0 ? allUniqueTranchesFees.join(', ') : '-'}
        />
      )
    }
  }

  return (
    <>
      <PercentageField
        label={t('roe-cap')}
        value={pricingData?.roeCapFullCost}
        isFirstItem={true}
      />
      <MoneyPercentageField
        label={t('full-cost')}
        amount={pricingData?.economicProfitAmountFullCost?.number}
        currency={pricingData?.economicProfitAmountFullCost?.currencyCode}
        percentage={pricingData?.economicProfitMarginFullCost}
      />
      {renderEconomicProfitAmountDirectCost()}
      {renderDirectCost()}
      {renderLowerCost()}
      {renderClosingDate()}
      {renderPercentageField('risk-return-ratio', pricingCustomFields?.riskReturnRatio)}
      {renderPercentageField('profit-center-rrr', pricingCustomFields?.profitCenterRrrPlanRate)}
      {renderPercentageField(
        'margin-difference-to-rrr',
        pricingCustomFields?.marginDifferenceToRrrPlanRate,
      )}
      {renderPercentageField('net-revenue-margin', pricingData?.netRevenueMargin)}
      {renderPercentageField('average-risk-weight', pricingCustomFields?.averageRiskWeight)}
      {renderUpfrontArrangementFee()}
      {renderOtherFees()}
      <div className={styles.spacer} />
    </>
  )
}

FinancingTermsPricingTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default FinancingTermsPricingTile
