import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import MultipleDealSelectionPdfViewWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/wrapper/MultipleDealSelectionPdfViewWrapper'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import { TILE_VERSION } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import RiskMonitoringDealSpecificsValuationHistoryV1 from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/deal-specifics/RiskMonitoringDealSpecificsValuationHistoryV1'
import RiskMonitoringDealSpecificsValuationHistoryV2 from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/deal-specifics/RiskMonitoringDealSpecificsValuationHistoryV2'

const RiskMonitoringDealSpecificsValuationHistorySelectionWrapper = ({ tileId, isPdfView }) => {
  const { version } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const contentCreator = useCallback(
    ({ selectedDealIndex }) => {
      switch (version) {
        case TILE_VERSION.V1:
          return (
            <RiskMonitoringDealSpecificsValuationHistoryV1
              tileId={tileId}
              selectedDealIndex={selectedDealIndex}
              isPdfView={isPdfView}
            />
          )
        default:
          return (
            <RiskMonitoringDealSpecificsValuationHistoryV2
              tileId={tileId}
              selectedDealIndex={selectedDealIndex}
              isPdfView={isPdfView}
            />
          )
      }
    },
    [isPdfView, tileId, version],
  )
  return (
    <MultipleDealSelectionPdfViewWrapper
      tileId={tileId}
      contentCreator={contentCreator}
      isPdfView={isPdfView}
      dealSelectionTileCode={
        availableAutomaticTiles.monitoredRiskIndicatorDealSpecificsDealSelection
      }
    />
  )
}

RiskMonitoringDealSpecificsValuationHistorySelectionWrapper.propTypes = {
  tileId: PropTypes.string,
  isPdfView: PropTypes.bool,
}

export default RiskMonitoringDealSpecificsValuationHistorySelectionWrapper
