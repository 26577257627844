import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import { useCallback, useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import PropertyExternalIdTableConfig from 'components/domains/properties/general-information/external-ids/PropertyExternalIdTableConfig'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import CardWithDisplayAndEditTable from 'components/ui/tables/display-and-edit-table/CardWithDisplayAndEditTable'
import { rowKeyNewRow } from 'components/ui/tables/display-and-edit-table/constants'
import { ConfigContext } from 'hooks/config/useConfig'
import { useCreatePropertyExternalId } from 'hooks/services/properties/external-ids/useCreatePropertyExternalId'
import { useDeletePropertyExternalId } from 'hooks/services/properties/external-ids/useDeletePropertyExternalId'
import { useExternalIdTypeConfigValues } from 'hooks/services/properties/external-ids/useExternalIdTypeConfigValues'
import { useExternalIdTypes } from 'hooks/services/properties/external-ids/useExternalIdTypes'
import { usePropertyExternalIds } from 'hooks/services/properties/external-ids/usePropertyExternalIds'
import { useUpdatePropertyExternalId } from 'hooks/services/properties/external-ids/useUpdatePropertyExternalId'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyExternalIdCard = () => {
  const {
    property: {
      uuid: propertyUuid,
      allowed_operations: { allowed_operations: allowedOperations = [] } = {},
    },
  } = useContext(PropertyContext)

  const isAllowedToEditExternalId = allowedOperations?.includes('PropertyExternalId_Update')

  const config = useContext(ConfigContext)

  const { t: tExternalIdTable } = useTranslation('translation', {
    keyPrefix: 'pages.property.general-information.external-id',
  })

  const {
    isLoading: isLoadingExternalIdTypes,
    isError: isErrorExternalIdTypes,
    data: { externalIdTypes = [] } = {},
  } = useExternalIdTypes()

  const {
    isLoading: isLoadingExternalIds,
    isError: isErrorExternalIds,
    data: { externalIds = [] } = {},
  } = usePropertyExternalIds({ propertyUuid })

  const {
    isLoading: isLoadingExternalIdTypesConfigValues,
    isError: isErrorExternalIdTypesConfigValues,
    data: { externalIdTypeConfigValues = [] } = {},
  } = useExternalIdTypeConfigValues()

  const isLoading =
    isLoadingExternalIdTypes || isLoadingExternalIds || isLoadingExternalIdTypesConfigValues
  const isError = isErrorExternalIdTypes || isErrorExternalIds || isErrorExternalIdTypesConfigValues

  const filterExternalIdsByEnv = useCallback(() => {
    const environment = config?.properties?.externalid?.environment
    if (!environment) {
      return externalIds ?? []
    }
    return externalIds?.filter((id) => id.extDescription === environment) ?? []
  }, [config, externalIds])

  const [editRows, setEditRows] = useState([])
  const findEditRow = (rowKey) => ({ ...editRows.find((editRow) => editRow.rowKey === rowKey) })

  const getNewEditRow = ({ rowKey, fieldName, value, oldRow }) => {
    const newRow = oldRow ? { ...oldRow } : { rowKey }
    newRow[fieldName] = value
    return newRow
  }

  const updateEditRow = (rowKey, fieldName, value) => {
    if (isEmpty(findEditRow(rowKey))) {
      const newEditRow = getNewEditRow({ rowKey, fieldName, value })
      setEditRows([...editRows, newEditRow])
    } else {
      const editRowsUpdated = editRows.map((oldRow) => {
        if (oldRow.rowKey !== rowKey) {
          return { ...oldRow }
        }
        return getNewEditRow({ rowKey, fieldName, value, oldRow })
      })
      setEditRows([...editRowsUpdated])
    }
  }

  const getCurrentFieldValue = ({ rowKey, originalValue, fieldName }) => {
    const editRow = findEditRow(rowKey)
    return fieldName in editRow ? editRow[fieldName] : originalValue
  }

  const checkIfAnythingChanged = (rowKey, row) => {
    let changeDetected = false
    const editRow = findEditRow(rowKey)
    Object.entries(editRow)?.forEach(([key, value]) => {
      if (key !== 'rowKey' && row[key] !== value) {
        changeDetected = true
      }
    })
    return changeDetected
  }

  const checkIsRowValid = (rowKey, row) => {
    let isValid = true
    if (!checkIfAnythingChanged(rowKey, row)) {
      return false
    }
    const fieldsNotEmpty = ['id', 'extCode']
    fieldsNotEmpty.forEach((fieldName) => {
      if (!getCurrentFieldValue({ rowKey, originalValue: row[fieldName], fieldName })) {
        isValid = false
      }
    })
    return isValid
  }

  const { tableData, newRow, columnDefinitions } = PropertyExternalIdTableConfig({
    externalIdTypes,
    externalIds: filterExternalIdsByEnv(),
    getCurrentFieldValue,
    checkIfAnythingChanged,
    checkIsRowValid,
    updateEditRow,
    tExternalIdTable,
  })

  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()
  const onExternalIdChangeSuccess = useCallback(
    ({ successText }) => {
      showToast({
        children: successText,
      })
      queryClient.invalidateQueries(['properties', propertyUuid, 'external-ids'])
    },
    [propertyUuid, queryClient, showToast],
  )

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [errorDialogText, setErrorDialogText] = useState('')

  const onExternalChangeError = async (error) => {
    setIsErrorDialogOpen(true)
    const errorDetails = await formatHookError(error)
    setErrorDialogText(errorDetails)
  }

  const mutationBehavior = useCallback(
    (successText) => ({
      onSuccess: () => onExternalIdChangeSuccess({ successText }),
      onError: (error) => onExternalChangeError(error),
    }),
    [onExternalIdChangeSuccess],
  )

  const createExternalId = useCreatePropertyExternalId(
    mutationBehavior(tExternalIdTable('created')),
  )
  const updateExternalId = useUpdatePropertyExternalId(
    mutationBehavior(tExternalIdTable('updated')),
  )
  const deleteExternalId = useDeletePropertyExternalId(
    mutationBehavior(tExternalIdTable('deleted')),
  )

  const getExternalIdTypeInCMS = (externalIdTypeKey) =>
    externalIdTypeConfigValues.find((type) => type?.code === externalIdTypeKey)?.type

  const removeChangeFromEditRows = (rowKey) => {
    setEditRows([...editRows.filter((editRow) => editRow.rowKey !== rowKey)])
  }
  const handleCancelEdit = (rowKey) => {
    removeChangeFromEditRows(rowKey)
  }

  const handleSaveRow = (rowKey) => {
    const editedRow = findEditRow(rowKey)
    const originalRow = {
      ...tableData.find((tableRow) => tableRow.rowKey === rowKey)?.originalRowData,
    }
    const externalIdToSave = {
      ...originalRow,
      ...editedRow,
    }
    //external id type name is identifier for request body
    if (externalIdToSave?.extCode) {
      externalIdToSave['type'] = getExternalIdTypeInCMS(externalIdToSave.extCode)
    }

    if (rowKey === rowKeyNewRow) {
      createExternalId.mutate({
        propertyUuid: propertyUuid,
        createdExternalId: externalIdToSave,
      })
    } else {
      updateExternalId.mutate({
        propertyUuid: propertyUuid,
        originalId: originalRow.id,
        originalTypeCode: originalRow.extCode,
        updatedExternalId: externalIdToSave,
      })
    }
    removeChangeFromEditRows(rowKey)
  }

  const handleDeleteRow = (rowKey) => {
    const externalId = {
      ...tableData.find((tableRow) => tableRow.rowKey === rowKey)?.originalRowData,
    }
    deleteExternalId.mutate({
      propertyUuid: propertyUuid,
      externalIdToDelete: externalId,
    })
  }

  return (
    <>
      <CardWithDisplayAndEditTable
        cardTitle={tExternalIdTable('title')}
        userIsAllowedToEdit={isAllowedToEditExternalId}
        tableData={tableData}
        columnDefinitions={columnDefinitions}
        newRow={newRow}
        handleSaveRow={handleSaveRow}
        handleDeleteRow={handleDeleteRow}
        handleCancelEditRow={handleCancelEdit}
        isLoading={isLoading}
        isError={isError}
      />
      {createPortal(
        <>
          {isErrorDialogOpen && (
            <ErrorMessageBoxWithExpandableDetails
              messageSummary={tExternalIdTable('message-box.error')}
              messageDetails={errorDialogText}
              isOpen={isErrorDialogOpen}
              onClose={() => setIsErrorDialogOpen(false)}
            />
          )}
        </>,
        document.body,
      )}
    </>
  )
}

export default PropertyExternalIdCard
