import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import ArrearsApprovalCheckListTileEdit from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrearsApprovalChecklist/ArrearsApprovalChecklistTileEdit'
import ArrearsApprovalChecklistTileView from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrearsApprovalChecklist/ArrearsApprovalChecklistTileView'

const ArrearsApprovalChecklistTile = ({
  tileId,
  currentContent,
  onChange,
  isEditMode,
  setHasContentChanges,
}) => {
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const initialContent = useMemo(
    () => ({
      ratingUpdateNecessary: '',
      accountManagerOneInformed: '',
      syndicationPartnerInformed: '',
      guarantorInformed: '',
    }),
    [],
  )
  const content = useMemo(() => currentContent ?? initialContent, [currentContent, initialContent])

  useEffect(() => {
    const emptyCurrentContent =
      isNil(currentContent) || Object.values(currentContent).every((value) => value === '')

    const hasContentChanges =
      !(emptyCurrentContent && isNil(tileStatus?.data?.data)) &&
      !isEqual(currentContent, tileStatus?.data?.data)
    setHasContentChanges(hasContentChanges)
  }, [content, currentContent, setHasContentChanges, tileStatus?.data?.data])

  return isEditMode ? (
    <ArrearsApprovalCheckListTileEdit onChange={onChange} content={content} />
  ) : (
    <ArrearsApprovalChecklistTileView content={content} />
  )
}

ArrearsApprovalChecklistTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  currentContent: PropTypes.shape({
    ratingUpdateNecessary: PropTypes.string,
    accountManagerOneInformed: PropTypes.string,
    syndicationPartnerInformed: PropTypes.string,
    guarantorInformed: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
}

export default ArrearsApprovalChecklistTile
