import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import maxBy from 'lodash.maxby'
import uniqBy from 'lodash.uniqby'
import { useMemo } from 'react'
import { propertyLinkedDealCodes } from 'api/property/linked-deal/propertyLinkedDeal'
import {
  mapDealKpisToDealProperties,
  mapPropertyKpisToPropertyDeals,
} from 'components/domains/business-partners/tile/tenancy/mapRentalUnitKpis'
import useDealsForProperties from 'hooks/services/deals/useDealsForProperties'
import usePropertyFinancingStatus from 'hooks/services/properties/usePropertyFinancingStatus'
import usePropertyUuids from 'hooks/services/properties/usePropertyUuids'
import { useRentalUnits } from 'hooks/services/properties/useRentalUnits'
import { useUtilizationCodes } from 'hooks/services/properties/useUtilizationCodes'

const acceptedFinancingStatus = [
  propertyLinkedDealCodes.linkedToActiveDeal,
  propertyLinkedDealCodes.linkedToPipelineDeal,
]

const vacantType = 'vacant'
const selfType = 'self'

/**
 * @typedef {{matchingProperties: any[], matchingDeals: any[], matchingRentalUnits: any[]}} FinancingStatusData
 * @typedef {{
 *   dealProperties: Record<string, string[]>,
 *   propertyDeals: Record<string, string[]>,
 *   rentalUnitsData: any,
 *   dataByFinancingStatus: Record<string, FinancingStatusData>,
 *   utilization: {vacantUtilizationKeys: string[], selfUtilizationKeys: string[]},
 * }} TenancyCardData
 *
 * @param {string} businessPartnerId
 * @return {{isLoading: boolean, isFetching: boolean, isError: boolean, data: TenancyCardData}}
 */
export const useTenancyCardData = ({ businessPartnerId }) => {
  const {
    isFetching: isRentalUnitsFetching,
    isLoading: isRentalUnitsLoading,
    isError: isRentalUnitsError,
    data: rentalUnitsData,
  } = useRentalUnits(businessPartnerId, 'tenant')

  const propertyUuids = useMemo(() => {
    if (isNil(rentalUnitsData?.rentalUnits)) {
      return []
    }
    const allPropertyUuids = rentalUnitsData.rentalUnits.map(
      (rentalUnit) => rentalUnit.propertyUuid,
    )
    return Array.from(new Set(allPropertyUuids))
  }, [rentalUnitsData?.rentalUnits])

  const { data: financingStatusData } = usePropertyFinancingStatus()

  const {
    data: propertiesData,
    isFetching: isPropertiesFetching,
    isLoading: isPropertiesLoading,
    isError: isPropertiesError,
  } = usePropertyUuids(propertyUuids, { enabled: !isNil(rentalUnitsData) }, true)

  const properties = useMemo(
    () => propertiesData?.data?.properties ?? [],
    [propertiesData?.data?.properties],
  )

  const filteredProperties = useMemo(
    () =>
      properties.filter((property) =>
        acceptedFinancingStatus.includes(property.financingStatusCode),
      ),
    [properties],
  )

  const filteredPropertyUuids = filteredProperties.map((property) => property.uuid)
  const filteredRentalUnitsData = useMemo(() => {
    if (isNil(rentalUnitsData?.rentalUnits)) {
      return undefined
    }

    // filter rental units to only include those that reference properties with accepted
    // financing status
    const rentalUnitsWithAcceptedFinancingStatus = rentalUnitsData.rentalUnits.filter(
      ({ propertyUuid }) => filteredPropertyUuids.includes(propertyUuid),
    )

    // Latest key date can only be null when rentalUnitsWithAcceptedFinancingStatus is empty
    // in which case filtering in the later step will return an empty array either way, so
    // no problem with comparing null values can arise.
    const latestKeyDate = maxBy(
      rentalUnitsWithAcceptedFinancingStatus,
      ({ keyDate }) => keyDate,
    )?.keyDate

    const rentalUnitsWithAcceptedLeaseExpiryDate = rentalUnitsWithAcceptedFinancingStatus.filter(
      ({ leaseExpiryDate, noLeaseExpiry }) => noLeaseExpiry || leaseExpiryDate > latestKeyDate,
    )

    return {
      ...rentalUnitsData,
      latestKeyDate,
      rentalUnits: rentalUnitsWithAcceptedLeaseExpiryDate,
    }
  }, [filteredPropertyUuids, rentalUnitsData])

  const {
    data: utilizationCodesData,
    isFetching: isUtilizationFetching,
    isLoading: isUtilizationLoading,
    isError: isutilizationCodesError,
  } = useUtilizationCodes()

  const vacantUtilizationKeys = useMemo(
    () =>
      utilizationCodesData?.utilization_codes
        ?.filter((element) => element.type === vacantType)
        ?.map((item) => item.key) ?? [],
    [utilizationCodesData?.utilization_codes],
  )
  const selfUtilizationKeys = useMemo(
    () =>
      utilizationCodesData?.utilization_codes
        ?.filter((element) => element.type === selfType)
        ?.map((item) => item.key) ?? [],
    [utilizationCodesData?.utilization_codes],
  )

  const {
    data: dealsData,
    isError: isDealsError,
    isFetching: isDealsFetching,
    isLoading: isDealsLoading,
  } = useDealsForProperties(
    { propertyUuids: filteredPropertyUuids },
    { enabled: !isNil(propertiesData?.data?.properties) },
  )

  const isFetching =
    isRentalUnitsFetching || isPropertiesFetching || isDealsFetching || isUtilizationFetching
  const isLoading =
    isRentalUnitsLoading || isPropertiesLoading || isDealsLoading || isUtilizationLoading
  const isError = isRentalUnitsError || isPropertiesError || isDealsError || isutilizationCodesError

  const data = useMemo(() => {
    if (isNil(filteredRentalUnitsData) || isEmpty(dealsData)) {
      return undefined
    }
    const dealProperties = mapDealKpisToDealProperties(filteredRentalUnitsData.dealKpis)
    const propertyDeals = mapPropertyKpisToPropertyDeals(filteredRentalUnitsData.propertyKpis)
    const financingStatusMap = Object.fromEntries(
      acceptedFinancingStatus.map((financingStatusCode) => {
        const rentalUnitsPropertyIds = new Set(
          filteredRentalUnitsData.rentalUnits.map(({ propertyUuid }) => propertyUuid),
        )
        const matchingProperties = properties.filter(
          ({ financingStatusCode: propertyFinancingStatus, uuid }) =>
            propertyFinancingStatus === financingStatusCode && rentalUnitsPropertyIds.has(uuid),
        )
        const matchingPropertyIds = matchingProperties.map(({ uuid }) => uuid)
        const matchingDealIds = new Set(
          matchingPropertyIds.flatMap((propertyUuid) => propertyDeals[propertyUuid] ?? []),
        )
        const flattenedDeals = uniqBy(dealsData.flat(), 'dealUuid')
        const matchingDeals = flattenedDeals.filter(({ dealUuid }) => matchingDealIds.has(dealUuid))
        const matchingRentalUnits = filteredRentalUnitsData.rentalUnits.filter(({ propertyUuid }) =>
          matchingPropertyIds.includes(propertyUuid),
        )
        return [
          financingStatusCode,
          {
            matchingProperties,
            matchingDeals,
            matchingRentalUnits,
          },
        ]
      }),
    )
    return {
      dealProperties,
      propertyDeals,
      rentalUnitsData: filteredRentalUnitsData,
      dataByFinancingStatus: financingStatusMap,
      utilization: {
        vacantUtilizationKeys,
        selfUtilizationKeys,
      },
      financingStatus: {
        active: {
          code: propertyLinkedDealCodes.linkedToActiveDeal,
          displayName:
            financingStatusData?.financingStatusCodes.find(
              (element) => element.key === propertyLinkedDealCodes.linkedToActiveDeal,
            )?.displayName ?? propertyLinkedDealCodes.linkedToActiveDeal,
        },
        pipeline: {
          code: propertyLinkedDealCodes.linkedToPipelineDeal,
          displayName:
            financingStatusData?.financingStatusCodes.find(
              (element) => element.key === propertyLinkedDealCodes.linkedToPipelineDeal,
            )?.displayName ?? propertyLinkedDealCodes.linkedToPipelineDeal,
        },
      },
    }
  }, [
    dealsData,
    filteredRentalUnitsData,
    financingStatusData,
    properties,
    selfUtilizationKeys,
    vacantUtilizationKeys,
  ])

  if (isLoading || isFetching || isError) {
    return {
      isFetching: isFetching && !isError,
      isLoading: isLoading && !isError,
      isError,
    }
  }

  return {
    isFetching,
    isLoading,
    isError,
    data,
  }
}
