import { Title, TitleLevel } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { Fragment, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/appendix/wrapper/MultipleBorrowerSelectionPdfViewWrapper.module.css'
import { createBorrowerName } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/utils/createBorrowerSelectOptions'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'

const MultipleBorrowerSelectionPdfViewWrapper = ({ tileId, contentCreator, isPdfView }) => {
  const { t: tDecisionPaper } = useTranslation('decisionPaper')
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const borrowersMetadata = useSelector(
    (state) =>
      state.decisionPaper.tilesOverview.tileMetadata?.[
        availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsBorrowerSelection
      ],
  )
  const { allBorrowers } = borrowersMetadata ?? {}

  const isBorrowerSelectionDropdownTileUsedInDecisionPaper = useSelector((state) =>
    Object.keys(state.decisionPaper.tilesOverview.tileMetadata).includes(
      availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsBorrowerSelection,
    ),
  )

  const { selectedBorrowerId } = isBorrowerSelectionDropdownTileUsedInDecisionPaper
    ? borrowersMetadata ?? {}
    : {
        selectedBorrowerId: tileStatus?.data?.businessPartnerId,
      }

  const callContentCreator = useCallback(
    ({ selectedBorrowerId: internalSelectedBorrowerId }) =>
      contentCreator({ isPdfView, selectedBorrowerId: internalSelectedBorrowerId, tileId }),
    [contentCreator, isPdfView, tileId],
  )

  if (isPdfView) {
    if (isEmpty(borrowersMetadata)) {
      return tDecisionPaper('tiles.borrower-selection.empty')
    }
    return allBorrowers.map((borrowerData) => {
      const borrowerName = createBorrowerName({
        entityTranslation: tDecisionPaper('entity-types.BORROWER'),
        bpName: borrowerData.name,
        bpId: borrowerData.id,
      })
      return (
        <Fragment key={`borrower-pdf-view-${tileId}-${borrowerData.id}`}>
          <Title level={TitleLevel.H2} className={styles.borrowerName}>
            {borrowerName}
          </Title>
          {callContentCreator({ selectedBorrowerId: borrowerData.id })}
        </Fragment>
      )
    })
  }
  return callContentCreator({ selectedBorrowerId: selectedBorrowerId })
}

MultipleBorrowerSelectionPdfViewWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  contentCreator: PropTypes.func.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default MultipleBorrowerSelectionPdfViewWrapper
