import PropTypes from 'prop-types'

export const kpiRowDataShape = PropTypes.shape({
  rowKey: PropTypes.string,
  isEditable: PropTypes.bool,
  isDeletable: PropTypes.bool,
})

export const kpiColumnDefinitionShape = PropTypes.shape({
  title: PropTypes.string,
  columnKey: PropTypes.string,
})
