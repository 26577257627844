import { writeFile, utils } from 'xlsx'
import { getPublishedRentRollContentDefinitions } from 'components/domains/properties/getPropertyRentRollReferenceData'

function createHeaderRow(t) {
  const headerByRentRollKey = Object.fromEntries(
    getPublishedRentRollContentDefinitions(t).map(({ contentKey, title }) => [contentKey, title]),
  )

  return [
    t('components.properties.rent-roll.excel-export.table.column.property-name'),
    t('components.properties.rent-roll.excel-export.table.column.property-id'),
    t('components.properties.rent-roll.excel-export.table.column.key-date'),
    headerByRentRollKey['name'],
    headerByRentRollKey['occupancy_status'],
    headerByRentRollKey['tenant'],
    t('components.properties.rent-roll.excel-export.table.column.tenant-id'),
    headerByRentRollKey['segment_name'],
    headerByRentRollKey['segment_usage_type_name'],
    headerByRentRollKey['area'],
    t('components.properties.rent-roll.excel-export.table.column.area-uom'),
    headerByRentRollKey['rent_contracted_year'],
    headerByRentRollKey['rent_current_year'],
    t('components.properties.rent-roll.excel-export.table.column.currency'),
    headerByRentRollKey['rent_start_date'],
    headerByRentRollKey['lease_start_date'],
    headerByRentRollKey['lease_expiry_date'],
    headerByRentRollKey['lease_break_date'],
    headerByRentRollKey['no_lease_expiry'],
    headerByRentRollKey['rent_arrears'],
    t('components.properties.rent-roll.excel-export.table.column.rent-arrear-currency'),
    headerByRentRollKey['branding_franchise'],
    t('components.properties.rent-roll.excel-export.table.column.branding-franchise-id'),
    headerByRentRollKey['lease_comment'],
    headerByRentRollKey['rent_dispute'],
    headerByRentRollKey['anonymous_tenant'],
    headerByRentRollKey['number_of_units'],
  ]
}

function cellOf(value, opts) {
  if (!value) return

  return {
    ...opts,
    v: value,
  }
}

function numberCellOf(value) {
  return cellOf(value, {
    t: 'n',
    z: '#,##0.00',
  })
}

function dateCellOf(value) {
  return cellOf(value, {
    t: 'd',
  })
}

function humanReadableBooleanOf(value, t) {
  return value ? t('formatters.boolean.yes') : t('formatters.boolean.no')
}

function toRentalUnitRow(ru, t, utilizationCodeTranslator, areaMeasurementUnitFormatter) {
  return [
    ru.property_name,
    ru.property_id,
    dateCellOf(ru.rent_roll_key_date),
    ru.name,
    utilizationCodeTranslator(ru.occupancy_status),
    ru.tenant?.name,
    ru.tenant?.id,
    ru.segment_name,
    ru.segment_usage_type_name,
    numberCellOf(ru.area?.value),
    ru.area?.value ? areaMeasurementUnitFormatter(ru.area?.measurement_unit) : undefined,
    numberCellOf(ru.rent_contracted_year?.number),
    numberCellOf(ru.rent_current_year?.number),
    // currency for rent contracted year / rent current year is equal
    ru.rent_current_year?.number ? ru.rent_current_year?.currency : undefined,
    dateCellOf(ru.rent_start_date),
    dateCellOf(ru.lease_start_date),
    dateCellOf(ru.lease_expiry_date),
    dateCellOf(ru.lease_break_date),
    humanReadableBooleanOf(ru.no_lease_expiry, t),
    numberCellOf(ru.rent_arrears?.number),
    ru.rent_arrears?.number ? ru.rent_arrears?.currency : undefined,
    ru.branding_franchise?.name,
    ru.branding_franchise?.id,
    ru.lease_comment,
    humanReadableBooleanOf(ru.rent_dispute, t),
    humanReadableBooleanOf(ru.anonymous_tenant, t),
    ru.number_of_units ?? undefined,
  ]
}

function createUtilizationCodeTranslator(utilizationCodes) {
  return (occupancyStatus) =>
    utilizationCodes?.find(({ key }) => key === occupancyStatus)?.display_name ?? occupancyStatus
}

export default function createRentRollExcelExport({
  rentalUnits,
  fileName,
  t,
  utilizationCodes,
  areaMeasurementUnitFormatter,
}) {
  const workbook = utils.book_new()
  utils.book_append_sheet(
    workbook,
    utils.aoa_to_sheet([
      createHeaderRow(t),
      ...rentalUnits.map((rentalUnit) =>
        toRentalUnitRow(
          rentalUnit,
          t,
          createUtilizationCodeTranslator(utilizationCodes),
          areaMeasurementUnitFormatter,
        ),
      ),
    ]),
  )
  writeFile(workbook, fileName)
}
