import { useTranslation } from 'react-i18next'
import { BORROWER_NAME_MAX_LENGTH } from 'components/domains/deals/creation-dialog/counterparty-step/DealCreationCounterpartyStep'
import { useFormMapped } from 'hooks/form/useFormMapped'

export const useCounterpartyDefaults = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.dialog.counterparty-and-financing',
  })
  const { getValues, setValue } = useFormMapped()
  return () => {
    const { name: dealName } = getValues() ?? {}
    if (dealName) {
      const defaultBorrowerName = `${t('borrower.default', { dealName })}`
      setValue('counterParty.borrower', {
        fullName: defaultBorrowerName.slice(0, BORROWER_NAME_MAX_LENGTH),
      })
      setValue('tranche.trancheName', t('tranche-name.default'))
    }
  }
}
