import { useEffect, useRef } from 'react'
import { useConfig } from 'hooks/config/useConfig'

export const useFavIcon = () => {
  const { data: config, isLoading, isError } = useConfig()

  const isConfigSucessfullyLoaded = !isLoading && !isError
  const isFavIconConfigured = config?.favIcon !== undefined
  const isCompanyLogoConfigured = config?.companyLogo !== undefined

  const faviconTagRef = useRef(null)

  useEffect(() => {
    const linkEl =
      document.querySelector("link[id~='fav-icon']") ||
      document.head.appendChild(document.createElement('link'))

    faviconTagRef.current = linkEl
  }, [])

  useEffect(() => {
    // If the config is not loaded, we do nothing
    if (!isConfigSucessfullyLoaded) {
      return
    }

    // If the favIcon is configured, we use it
    if (isFavIconConfigured) {
      faviconTagRef.current.setAttribute('href', config?.favIcon)
      return
    }

    // If the favIcon is not configured, we fallback to the companyLogo
    if (isCompanyLogoConfigured) {
      faviconTagRef.current.setAttribute('href', config?.companyLogo)
      return
    }
  }, [
    config?.companyLogo,
    config?.favIcon,
    isCompanyLogoConfigured,
    isConfigSucessfullyLoaded,
    isFavIconConfigured,
  ])
}
