import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Input,
  InputType,
  Label,
  Link,
  Option,
  Select,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DealLink from 'components/domains/business-events-and-tasks/DealLink'
import { isFinancialProduct } from 'components/domains/business-partners/tile/arrears/ArrearsUtil'
import styles from 'components/domains/business-partners/tile/arrears/current-approvals/ArrearApprovalTableCells.module.css'
import {
  getApprovalAmountValueStateObj,
  getClassificationValueStateObj,
  getDateValueStateObj,
  isArrearClassificationTypeDisabled,
  getApprovalAmountValueOrZero,
} from 'components/domains/business-partners/tile/arrears/current-approvals/getArrearAppovalTableCellValueState'
import {
  getRowKey,
  isDualCurrencyRow,
} from 'components/domains/business-partners/tile/arrears/current-approvals/useCurrentApprovalsTableData'
import LoadingButton from 'components/ui/button/LoadingButton'
import DatePickerWithoutMinWidth from 'components/ui/date-picker/DatePickerWithoutMinWidth'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import AnalyticalTableCell from 'components/ui/tables/analytical/AnalyticalTableCell'
import { useArrearClassifications } from 'hooks/services/arrears/useArrearClassifications'
import useCurrencyConversion from 'hooks/services/central-data/useCurrencyConversion'
import { selectors } from 'redux/slices/arrears/arrearApprovalSlice'
import {
  ENTITY_TYPES,
  ARREARS_HEADQUARTER_CURRENCY,
  NO_DEAL,
} from 'routes/business-partners/arrears/constants'

const SPACE = <span>&nbsp;</span>

const TODAY = new Date().toISOString().substring(0, 10)

const showValueStateMessageOrEmpty = (message) => message && <Text>{message}</Text>

const CURRENCY_CONVERSION_IDS = {
  fromHeadquarter: 'fromHeadquarter',
  toHeadquarter: 'toHeadquarter',
}

const isRowInEditMode = (rowKey, productRowInEdit) =>
  productRowInEdit?.rowKey === rowKey ||
  productRowInEdit?.approvalItemRowKeys?.some((subRowKey) => subRowKey === rowKey)

const isEditModeOrStartApprovalProcess = (rowKey, productRowInEdit, isStartApprovalProcess) =>
  isRowInEditMode(rowKey, productRowInEdit) || isStartApprovalProcess

const HorizontallyAligned = ({ children }) => (
  <FlexBox
    className={styles.noMarginTopAndFullHeight}
    direction={FlexBoxDirection.Row}
    alignItems={FlexBoxAlignItems.Center}
  >
    {children}
  </FlexBox>
)

HorizontallyAligned.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
}

const VerticallyCentered = ({ children }) => (
  <FlexBox
    className={styles.noMarginTopAndFullHeight}
    direction={FlexBoxDirection.Column}
    justifyContent={FlexBoxAlignItems.Center}
  >
    {children}
  </FlexBox>
)

VerticallyCentered.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
}

const LabelWithEntityIdLink = ({ label, entityId, entityLink }) => (
  <FlexBox className={styles.noMarginTopAndFullHeight} alignItems={FlexBoxAlignItems.Center}>
    <Label showColon>{label}</Label>
    {SPACE}
    <Link href={entityLink} target={'_blank'}>
      {entityId}
    </Link>
  </FlexBox>
)
LabelWithEntityIdLink.propTypes = {
  label: PropTypes.string,
  entityId: PropTypes.string,
  entityLink: PropTypes.string,
}

const LabelWithEntityIdInput = ({ label, entityId }) => (
  <VerticallyCentered>
    <HorizontallyAligned>
      <Label showColon>{label}</Label>
      {SPACE}
      <Input readonly value={entityId} />
    </HorizontallyAligned>
  </VerticallyCentered>
)

LabelWithEntityIdInput.propTypes = {
  label: PropTypes.string,
  entityId: PropTypes.string,
}

const getNonLoanLink = (dealId, businessPartnerId, dealDisplayId) =>
  dealId === NO_DEAL
    ? `/business-partners/${businessPartnerId}/financial-product-overview`
    : `/deals/${dealDisplayId}/financing?dataSource=existingBusiness`

const getBankCustomerAccountLink = (businessPartnerId, dealId, dealDisplayId, entityId) =>
  dealId === NO_DEAL
    ? `/business-partners/${businessPartnerId}/financial-product-overview`
    : `/deals/${dealDisplayId}/financing/bank-customer-accounts/${entityId}`

const getProductLinkPath = (businessPartnerId, dealId, dealDisplayId, entityType, entityId) => {
  if (entityType === ENTITY_TYPES.TRANCHE) {
    return `/deals/${dealDisplayId}/financing/tranches/${entityId}/details?dataSource=existingBusiness`
  } else if (entityType === ENTITY_TYPES.DRAWING) {
    return `/deals/${dealDisplayId}/financing/drawdowns/${entityId}/details?dataSource=existingBusiness`
  } else if (entityType === ENTITY_TYPES.NON_LOAN) {
    return getNonLoanLink(dealId, businessPartnerId, dealDisplayId)
  } else if (entityType === ENTITY_TYPES.BCA) {
    return getBankCustomerAccountLink(businessPartnerId, dealId, dealDisplayId, entityId)
  }
}

const useCurrentEditValueAndValueState = (row, field, isStartApprovalProcess) => {
  const currentEditValue = useSelector((state) =>
    selectors.getEditRowValue({
      state,
      isStartApprovalProcess,
      rowKey: getRowKey(row),
      field,
    }),
  )

  const currentEditValueState = useSelector((state) =>
    selectors.getEditRowValueState({
      state,
      isStartApprovalProcess,
      rowKey: getRowKey(row),
      field,
    }),
  )

  return {
    currentEditValue,
    currentEditValueState,
  }
}

const BoldLabel = ({ label }) => (
  <VerticallyCentered>
    <Label style={{ fontWeight: 'bold' }}>{label}</Label>
  </VerticallyCentered>
)
BoldLabel.propTypes = {
  label: PropTypes.string,
}

const ArrearCellContent = ({
  businessPartnerId,
  entityId,
  entityType,
  dealId,
  dealDisplayId,
  isEditMode,
}) => {
  const { t: entityTypeTranslate } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.current-arrears.table.entity-types',
  })
  const linkPath = getProductLinkPath(
    businessPartnerId,
    dealId,
    dealDisplayId,
    entityType,
    entityId,
  )
  const entityTypeTranslation = entityTypeTranslate(entityType.toLowerCase().replaceAll('_', '-'))

  if (isEditMode) {
    return <LabelWithEntityIdInput label={entityTypeTranslation} entityId={entityId} />
  }
  return (
    <LabelWithEntityIdLink
      label={entityTypeTranslation}
      entityLink={linkPath}
      entityId={entityId}
    />
  )
}

ArrearCellContent.propTypes = {
  businessPartnerId: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  dealId: PropTypes.string,
  dealDisplayId: PropTypes.string,
  isEditMode: PropTypes.bool.isRequired,
  isStartApprovalProcess: PropTypes.bool,
}

export const ArrearCell = (
  <AnalyticalTableCell>
    {(
      _tableData,
      { isStartApprovalProcess },
      {
        cell: {
          row: { original: row },
        },
      },
    ) => {
      const { t: entityTypeTranslate } = useTranslation('translation', {
        keyPrefix: 'pages.business-partner.arrears.current-arrears.table.entity-types',
      })
      const productRowInEdit = useSelector((state) => selectors.getProductRowInEdit(state))
      const businessPartnerId = row.businessPartnerId
      const dealId = row.dealId
      const entityId = row.entityId
      const secondaryEntityId = row.secondaryEntityId
      const entityType = row.entityType
      const dealDisplayId = row.rowData?.product?.dealDisplayId
      const rowKey = getRowKey(row)
      const isEditMode = isEditModeOrStartApprovalProcess(
        rowKey,
        productRowInEdit,
        isStartApprovalProcess,
      )

      switch (entityType) {
        case ENTITY_TYPES.DEAL:
          return entityId === NO_DEAL ? (
            <BoldLabel label={entityTypeTranslate('no-deal')} />
          ) : (
            <DealLink dealUuid={entityId} customRender={'SINGLE_LINE'} />
          )
        case ENTITY_TYPES.TRANCHE:
          return (
            <ArrearCellContent
              businessPartnerId={businessPartnerId}
              entityId={entityId}
              entityType={ENTITY_TYPES.TRANCHE}
              dealId={dealId}
              dealDisplayId={dealDisplayId}
              isEditMode={isEditMode}
            />
          )
        case ENTITY_TYPES.DRAWING:
          return (
            <ArrearCellContent
              businessPartnerId={businessPartnerId}
              entityId={entityId}
              entityType={ENTITY_TYPES.DRAWING}
              dealId={dealId}
              dealDisplayId={dealDisplayId}
              isEditMode={isEditMode}
            />
          )
        case ENTITY_TYPES.NON_LOAN:
          return (
            <ArrearCellContent
              businessPartnerId={businessPartnerId}
              entityId={secondaryEntityId ?? entityId}
              entityType={ENTITY_TYPES.NON_LOAN}
              dealId={dealId}
              dealDisplayId={dealDisplayId}
              isEditMode={isEditMode}
            />
          )
        case ENTITY_TYPES.BCA:
          return (
            <ArrearCellContent
              businessPartnerId={businessPartnerId}
              entityId={secondaryEntityId}
              entityType={ENTITY_TYPES.BCA}
              dealId={dealId}
              dealDisplayId={dealDisplayId}
              isEditMode={isEditMode}
            />
          )
        case ENTITY_TYPES.APPROVALITEM:
          if (isEditMode) {
            return (
              <HorizontallyAligned>
                <Input readonly value={row.rowData.approvalItem.arrearType.name} />
              </HorizontallyAligned>
            )
          } else {
            return (
              <HorizontallyAligned>{row.rowData.approvalItem.arrearType.name}</HorizontallyAligned>
            )
          }
      }
    }}
  </AnalyticalTableCell>
)

const MoneyCell = ({ row, formatCurrency, isSectionHeader }) => {
  const valueHeadquarterCurrency = row.sumData.approvedAmount
  const arrearCurrency = row.rowData.arrearCurrency

  const [isConverted, setIsConverted] = useState(!isDualCurrencyRow(row))
  const [valueInArrearCurrency, setValueInArrearCurrency] = useState(null)

  const { mutate: convertCurrency } = useCurrencyConversion({
    onSuccess: (response) => {
      const convertedValue = response?.data?.currencyConversionValues?.[0]?.value
      setValueInArrearCurrency(convertedValue)
      setIsConverted(true)
    },
  })

  const convertToArrearCurrencyPayload = useMemo(
    () => ({
      keyDate: null,
      currencyConversionValues: [
        {
          id: CURRENCY_CONVERSION_IDS.fromHeadquarter,
          value: valueHeadquarterCurrency,
          sourceCurrency: ARREARS_HEADQUARTER_CURRENCY,
          targetCurrency: arrearCurrency,
        },
      ],
    }),
    [arrearCurrency, valueHeadquarterCurrency],
  )

  useEffect(() => {
    if (!isConverted) {
      convertCurrency(convertToArrearCurrencyPayload)
    }
  }, [convertCurrency, convertToArrearCurrencyPayload, isConverted])

  return (
    !isNil(valueHeadquarterCurrency) && (
      <VerticallyCentered>
        {
          <Text
            className={
              isSectionHeader
                ? `${styles.textAlignRight} sapFontBoldFamily sapTextColor`
                : `${styles.textAlignRight} sapTextColor`
            }
          >
            {formatCurrency(valueHeadquarterCurrency, ARREARS_HEADQUARTER_CURRENCY)}
          </Text>
        }
        {isDualCurrencyRow(row) && isConverted && (
          <Text className={`${styles.textAlignRight} ${styles.tinyMarginTop} sapNeutralColor`}>
            {formatCurrency(valueInArrearCurrency, row.rowData.arrearCurrency)}
          </Text>
        )}
      </VerticallyCentered>
    )
  )
}

MoneyCell.propTypes = {
  row: PropTypes.shape({
    rowData: PropTypes.shape({
      arrearCurrency: PropTypes.string,
    }),
    sumData: PropTypes.shape({
      approvedAmount: PropTypes.number,
    }),
  }),
  formatCurrency: PropTypes.func,
  isSectionHeader: PropTypes.bool,
}

const MoneyInput = ({
  row,
  updateAndValidateEditRow,
  tValueState,
  correctSummedAmountForParentRow,
  isStartApprovalProcess,
}) => {
  const field = 'approvalAmount'
  const headquarterArrearAmount = row.rowData?.approvalItem?.headquarterArrearAmount ?? 0
  const arrearType = row.rowData?.approvalItem?.arrearType?.code
  const arrearCurrency = row.rowData?.arrearCurrency

  const { currentEditValue, currentEditValueState } = useCurrentEditValueAndValueState(
    row,
    field,
    isStartApprovalProcess,
  )

  const currentApprovalAmountValue = getApprovalAmountValueOrZero(
    currentEditValue,
    row.sumData.approvedAmount,
  )

  const currentApprovalAmountValueState =
    currentEditValueState ?? row.rowData.approvalItem?.approvalAmount?.valueState

  const approvalAmountValueStateMsg = getApprovalAmountValueStateObj(
    currentApprovalAmountValue,
    headquarterArrearAmount ?? 0,
    arrearType,
    tValueState,
  )?.message

  const [arrearCurrencyValue, setArrearCurrencyValue] = useState(null)

  const setConvertedCurrencyOnSuccess = (response) => {
    const conversion = response?.data?.currencyConversionValues?.[0]
    if (conversion.id === CURRENCY_CONVERSION_IDS.fromHeadquarter) {
      setArrearCurrencyValue(conversion.value)
    }
    if (conversion.id === CURRENCY_CONVERSION_IDS.toHeadquarter) {
      correctSummedAmountForParentRow(row, currentApprovalAmountValue, conversion.value ?? 0)
      updateAndValidateEditRow(
        row,
        field,
        conversion.value,
        getApprovalAmountValueStateObj(
          conversion.value,
          headquarterArrearAmount,
          arrearType,
          tValueState,
        ).valueState,
      )
    }
  }

  const { mutate: convertCurrency } = useCurrencyConversion({
    onSuccess: (response) => setConvertedCurrencyOnSuccess(response),
  })

  const convertToArrearCurrencyPayload = useCallback(
    (value) => ({
      keyDate: null,
      currencyConversionValues: [
        {
          id: CURRENCY_CONVERSION_IDS.fromHeadquarter,
          value: value,
          sourceCurrency: ARREARS_HEADQUARTER_CURRENCY,
          targetCurrency: arrearCurrency,
        },
      ],
    }),
    [arrearCurrency],
  )
  const [isInitial, setIsInitial] = useState(true)
  useEffect(() => {
    if (isInitial || row.entityType !== ENTITY_TYPES.APPROVALITEM) {
      convertCurrency(convertToArrearCurrencyPayload(currentApprovalAmountValue))
      setIsInitial(false)
    }
  }, [
    arrearCurrency,
    convertCurrency,
    convertToArrearCurrencyPayload,
    currentApprovalAmountValue,
    isInitial,
    row.entityType,
  ])

  const convertToHeadquarterCurrencyPayload = useCallback(
    (parsedValue) => ({
      keyDate: null,
      currencyConversionValues: [
        {
          id: CURRENCY_CONVERSION_IDS.toHeadquarter,
          value: parsedValue,
          sourceCurrency: arrearCurrency,
          targetCurrency: ARREARS_HEADQUARTER_CURRENCY,
        },
      ],
    }),
    [arrearCurrency],
  )

  const convertToHeadquarterCurrencyWhenChanged = useCallback(
    (parsedValue) => {
      if (parsedValue !== arrearCurrencyValue) {
        setArrearCurrencyValue(parsedValue)
        convertCurrency(convertToHeadquarterCurrencyPayload(parsedValue))
      }
    },
    [arrearCurrencyValue, convertCurrency, convertToHeadquarterCurrencyPayload],
  )

  const convertToArrearCurrencyAndUpdateEditRowsWhenChanged = useCallback(
    (parsedValue) => {
      if (parsedValue !== currentApprovalAmountValue) {
        correctSummedAmountForParentRow(row, currentApprovalAmountValue, parsedValue ?? 0)
        convertCurrency(convertToArrearCurrencyPayload(parsedValue))
        updateAndValidateEditRow(
          row,
          field,
          parsedValue,
          getApprovalAmountValueStateObj(
            parsedValue,
            headquarterArrearAmount,
            arrearType,
            tValueState,
          ).valueState,
        )
      }
    },
    [
      arrearType,
      convertCurrency,
      convertToArrearCurrencyPayload,
      correctSummedAmountForParentRow,
      currentApprovalAmountValue,
      headquarterArrearAmount,
      row,
      tValueState,
      updateAndValidateEditRow,
    ],
  )

  const renderSecondaryInput = () => {
    if (isDualCurrencyRow(row)) {
      return (
        <FormattedNumberInput
          id={`${row.entityId}-approvalAmount-secondary`}
          value={arrearCurrencyValue}
          className={`${styles.tinyMarginTop} ${styles.fullWidth}`}
          valueState={currentApprovalAmountValueState}
          valueStateMessage={showValueStateMessageOrEmpty(approvalAmountValueStateMsg)}
          readonly={row.entityType !== ENTITY_TYPES.APPROVALITEM}
          onChange={(parsedValue) => convertToHeadquarterCurrencyWhenChanged(parsedValue)}
          icon={<Label>{row.rowData.arrearCurrency}</Label>}
        />
      )
    }
  }
  return (
    <VerticallyCentered>
      <FormattedNumberInput
        id={`${row.entityId}-approvalAmount`}
        value={currentApprovalAmountValue}
        className={styles.fullWidth}
        valueState={currentApprovalAmountValueState}
        valueStateMessage={showValueStateMessageOrEmpty(approvalAmountValueStateMsg)}
        readonly={row.entityType !== ENTITY_TYPES.APPROVALITEM}
        onChange={(parsedValue) => convertToArrearCurrencyAndUpdateEditRowsWhenChanged(parsedValue)}
        icon={<Label>{ARREARS_HEADQUARTER_CURRENCY}</Label>}
      />
      {renderSecondaryInput()}
    </VerticallyCentered>
  )
}
MoneyInput.propTypes = {
  row: PropTypes.shape({
    entityId: PropTypes.string,
    entityType: PropTypes.string,
    sumData: PropTypes.shape({
      approvedAmount: PropTypes.number,
    }),
    rowData: PropTypes.shape({
      arrearCurrency: PropTypes.string,
      approvalItem: PropTypes.shape({
        approvalAmount: PropTypes.shape({
          valueState: PropTypes.string,
        }),
        arrearType: PropTypes.shape({
          code: PropTypes.string,
        }),
        headquarterArrearAmount: PropTypes.number,
      }),
    }),
  }),
  updateAndValidateEditRow: PropTypes.func,
  tValueState: PropTypes.func,
  correctSummedAmountForParentRow: PropTypes.func,
  isStartApprovalProcess: PropTypes.bool,
}

export const ApprovalMoneyCell = (
  <AnalyticalTableCell alignRight>
    {(
      _tableData,
      {
        formatCurrency,
        tValueState,
        updateAndValidateEditRow,
        correctSummedAmountForParentRow,
        isStartApprovalProcess,
      },
      {
        cell: {
          row: { original: row },
        },
      },
    ) => {
      const productRowInEdit = useSelector((state) => selectors.getProductRowInEdit(state))
      const rowKey = getRowKey(row)
      const isEditMode = isEditModeOrStartApprovalProcess(
        rowKey,
        productRowInEdit,
        isStartApprovalProcess,
      )

      if (isEditMode) {
        return (
          <MoneyInput
            row={row}
            updateAndValidateEditRow={updateAndValidateEditRow}
            tValueState={tValueState}
            correctSummedAmountForParentRow={correctSummedAmountForParentRow}
            isStartApprovalProcess={isStartApprovalProcess}
          />
        )
      }

      if (row.entityType === ENTITY_TYPES.APPROVALITEM) {
        return (
          <>
            <MoneyCell isSectionHeader={false} row={row} formatCurrency={formatCurrency} />
          </>
        )
      } else {
        return (
          <>
            <MoneyCell
              isSectionHeader={row.isSectionHeader}
              row={row}
              formatCurrency={formatCurrency}
            />
          </>
        )
      }
    }}
  </AnalyticalTableCell>
)

export const ApprovalApprovedUntilDateCell = (
  <AnalyticalTableCell alignRight>
    {(
      _tableData,
      {
        localePattern,
        formatDate,
        parse,
        tValueState,
        updateAndValidateEditRow,
        isStartApprovalProcess,
        hasDirectApprovalItems,
      },
      {
        cell: {
          row: { original: row },
        },
      },
    ) => {
      const productRowInEdit = useSelector((state) => selectors.getProductRowInEdit(state))

      const field = 'approvedUntil'
      const approvedUntilObj = row.rowData.approvedUntil
      const rowKey = getRowKey(row)

      const { currentEditValue, currentEditValueState } = useCurrentEditValueAndValueState(
        row,
        field,
        isStartApprovalProcess,
      )

      const currentApprovedUntilValue = currentEditValue ?? approvedUntilObj.value
      const currentApprovedUntilValueState = currentEditValueState ?? approvedUntilObj.valueState

      const approvedUntilValueStateMsg = getDateValueStateObj(
        currentApprovedUntilValue,
        localePattern,
        parse,
        tValueState,
      )?.message

      if (hasDirectApprovalItems(row) && isFinancialProduct(row.entityType)) {
        const isEditMode = isEditModeOrStartApprovalProcess(
          rowKey,
          productRowInEdit,
          isStartApprovalProcess,
        )
        if (isEditMode) {
          return (
            <VerticallyCentered>
              <DatePickerWithoutMinWidth
                id={`${row.entityId}-${field}`}
                className={styles.fullWidth}
                placeholder={localePattern}
                formatPattern={localePattern}
                value={currentApprovedUntilValue}
                valueState={currentApprovedUntilValueState}
                valueStateMessage={showValueStateMessageOrEmpty(approvedUntilValueStateMsg)}
                minDate={formatDate(TODAY)}
                onChange={(event) => {
                  updateAndValidateEditRow(
                    row,
                    field,
                    event.detail.value,
                    getDateValueStateObj(event.detail.value, localePattern, parse, tValueState)
                      .valueState,
                  )
                }}
              />
            </VerticallyCentered>
          )
        } else {
          return (
            <VerticallyCentered>
              {approvedUntilObj?.value && (
                <Text className={styles.textAlignRight}>{formatDate(approvedUntilObj.value)}</Text>
              )}
            </VerticallyCentered>
          )
        }
      }
    }}
  </AnalyticalTableCell>
)

export const ArrearClassificationCell = (
  <AnalyticalTableCell>
    {(
      _tableData,
      { tValueState, updateAndValidateEditRow, isStartApprovalProcess, hasDirectApprovalItems },
      {
        cell: {
          row: { original: row },
        },
      },
    ) => {
      const productRowInEdit = useSelector((state) => selectors.getProductRowInEdit(state))
      const field = 'classificationCode'
      const rowKey = getRowKey(row)
      const arrearClassifications = useArrearClassifications()?.data?.arrearClassifications ?? []

      const { currentEditValue, currentEditValueState } = useCurrentEditValueAndValueState(
        row,
        field,
        isStartApprovalProcess,
      )

      let currentClassificationCode
      switch (currentEditValue) {
        case '':
          currentClassificationCode = 'emptyOption'
          break
        case undefined:
          currentClassificationCode = row.rowData.classificationCode.value
          break
        default:
          currentClassificationCode = arrearClassifications.find(
            (classification) => classification.code === currentEditValue,
          )?.code
          break
      }

      const currentClassificationValueState =
        currentEditValueState ?? row.rowData.classificationCode.valueState

      const classificationValueStateMsg = getClassificationValueStateObj(
        row,
        currentClassificationCode,
        tValueState,
      )?.message

      if (hasDirectApprovalItems(row) && isFinancialProduct(row.entityType)) {
        const isEditMode = isEditModeOrStartApprovalProcess(
          rowKey,
          productRowInEdit,
          isStartApprovalProcess,
        )
        if (isEditMode) {
          return (
            <VerticallyCentered>
              <Select
                id={`${row.entityId}-arrearClassification`}
                onChange={(event) => {
                  updateAndValidateEditRow(
                    row,
                    field,
                    event.detail.selectedOption.dataset.id,
                    getClassificationValueStateObj(
                      row,
                      event.detail.selectedOption.dataset.id,
                      tValueState,
                    ).valueState,
                  )
                }}
                valueState={currentClassificationValueState}
                valueStateMessage={showValueStateMessageOrEmpty(classificationValueStateMsg)}
              >
                {[
                  <Option
                    key={'emptyOption'}
                    id={'emptyOption_' + rowKey}
                    data-id={'emptyOption'}
                    selected={!currentEditValue}
                  >
                    {''}
                  </Option>,
                  ...arrearClassifications.map((arrearClassification) => (
                    <Option
                      key={arrearClassification.code}
                      id={arrearClassification.code + '_' + rowKey}
                      data-id={arrearClassification.code}
                      selected={currentClassificationCode === arrearClassification.code}
                      disabled={isArrearClassificationTypeDisabled(row, arrearClassification.code)}
                    >
                      {arrearClassification.name}
                    </Option>
                  )),
                ]}
              </Select>
            </VerticallyCentered>
          )
        } else {
          return (
            <FlexBox
              className={styles.noMarginTopAndFullHeight}
              alignItems={FlexBoxAlignItems.Center}
            >
              {row.rowData.classificationName ?? ''}
            </FlexBox>
          )
        }
      }
    }}
  </AnalyticalTableCell>
)

export const ApprovalCommentCell = (
  <AnalyticalTableCell>
    {(
      _tableData,
      { updateAndValidateEditRow, isStartApprovalProcess, hasDirectApprovalItems },
      {
        cell: {
          row: { original: row },
        },
      },
    ) => {
      const productRowInEdit = useSelector((state) => selectors.getProductRowInEdit(state))

      const field = 'comment'
      const rowKey = getRowKey(row)

      const { currentEditValue } = useCurrentEditValueAndValueState(
        row,
        field,
        isStartApprovalProcess,
      )

      const currentComment = currentEditValue ?? row.rowData.comment.value

      if (hasDirectApprovalItems(row) && isFinancialProduct(row.entityType)) {
        const isEditMode = isEditModeOrStartApprovalProcess(
          rowKey,
          productRowInEdit,
          isStartApprovalProcess,
        )
        if (isEditMode) {
          return (
            <VerticallyCentered>
              <Input
                id={`${row.entityId}-${field}`}
                value={currentComment}
                type={InputType.Text}
                maxlength="100"
                onChange={(event) =>
                  updateAndValidateEditRow(row, field, event.target.value, ValueState.None)
                }
              />
            </VerticallyCentered>
          )
        } else {
          return <VerticallyCentered>{row.rowData.comment.value}</VerticallyCentered>
        }
      }
    }}
  </AnalyticalTableCell>
)

export const ApprovalActionCell = (
  <AnalyticalTableCell>
    {(
      _tableData,
      {
        toggleEditRowAndSubRows,
        cancelAllEditRows,
        handleSaveRowAndSubrows,
        isSaveEnabled,
        isUpdatingApprovalItems,
        isEditable,
        isStartApprovalProcess,
        hasDirectApprovalItems,
      },
      {
        cell: {
          row: { original: row },
        },
      },
    ) => {
      const productRowInEdit = useSelector((state) => selectors.getProductRowInEdit(state))

      const rowKey = getRowKey(row)
      const showActionCell = hasDirectApprovalItems(row) && isEditable
      const isInEdit = useSelector((state) =>
        selectors.getEditRow({
          state,
          isStartApprovalProcess,
          rowKey: getRowKey(row),
        }),
      )

      const renderSaveAndCancelOrEditButton = useCallback(() => {
        if (isRowInEditMode(rowKey, productRowInEdit)) {
          return (
            <VerticallyCentered>
              <HorizontallyAligned>
                <LoadingButton
                  id={`${row.entityId}-save`}
                  style={{ marginRight: '8px' }}
                  className={styles.halfWidth}
                  design={ButtonDesign.Emphasized}
                  onClick={() => handleSaveRowAndSubrows(row)}
                  renderContent={() => t('buttons.save')}
                  disabled={!isSaveEnabled(row) || !isInEdit}
                  isLoading={isUpdatingApprovalItems}
                />
                <Button
                  id={`${row.entityId}-cancel`}
                  design={ButtonDesign.Transparent}
                  onClick={() => cancelAllEditRows()}
                  disabled={isUpdatingApprovalItems}
                >
                  {t('buttons.cancel')}
                </Button>
              </HorizontallyAligned>
            </VerticallyCentered>
          )
        }
        return (
          <VerticallyCentered>
            <FlexBox className={styles.fullWidth} justifyContent={FlexBoxJustifyContent.End}>
              <Button
                id={`${row.entityId}-edit`}
                design={ButtonDesign.Transparent}
                icon="edit"
                onClick={() => toggleEditRowAndSubRows(row)}
              />
            </FlexBox>
          </VerticallyCentered>
        )
      }, [
        cancelAllEditRows,
        handleSaveRowAndSubrows,
        isInEdit,
        isSaveEnabled,
        isUpdatingApprovalItems,
        productRowInEdit,
        row,
        rowKey,
        toggleEditRowAndSubRows,
      ])

      return showActionCell && renderSaveAndCancelOrEditButton()
    }}
  </AnalyticalTableCell>
)
