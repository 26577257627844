import { Title, TitleLevel } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import stylesTooltip from 'components/domains/kpis/chart/KpiTooltip.module.css'
import KpiUnit from 'components/domains/kpis/chart/KpiUnit'
import StaffMemberByObjectIdOrEmail from 'components/ui/staff-member/StaffMemberByObjectIdOrEmail'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const EMPTY_VALUE = '-'

const KpiTimeSeriesTooltip = ({ active, payload = [], label, unit, detailed = false }) => {
  const { t } = useTranslation('translation')
  const { format: formatDate } = useShortDateFormatter()

  if (active && payload && payload.length) {
    const { value, updatedBy, calculatedValue, directAdjustedValue, comment } = payload[0].payload
    const createdAtLabelAsIsoDate = label ? new Date(label)?.toISOString() : EMPTY_VALUE
    return (
      <div className={stylesTooltip.tooltip}>
        <Title className={stylesTooltip.title} level={TitleLevel.H6}>
          <b>{formatDate(createdAtLabelAsIsoDate)}</b>
        </Title>
        {detailed ? (
          <>
            <CardShowItem
              label={t('components.kpis.chart.tooltip.calculated-value')}
              value={<KpiUnit value={calculatedValue} unit={unit} />}
            />
            <CardShowItem
              label={t('components.kpis.chart.tooltip.adjusted-value')}
              value={<KpiUnit value={directAdjustedValue} unit={unit} />}
            />
            <CardShowItem label={t('components.kpis.chart.tooltip.comment')} value={comment} />
          </>
        ) : (
          <CardShowItem
            label={t('components.kpis.chart.tooltip.value')}
            value={<KpiUnit value={value} unit={unit} />}
          />
        )}
        {updatedBy && (
          <CardShowItem
            label={t('components.kpis.chart.tooltip.updated-by')}
            value={updatedBy && <StaffMemberByObjectIdOrEmail objectIdOrEmail={updatedBy} />}
          />
        )}
      </div>
    )
  }
  return null
}

KpiTimeSeriesTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        value: PropTypes.number,
        updatedBy: PropTypes.string,
      }),
    }),
  ),
  label: PropTypes.string,
  unit: PropTypes.shape({
    type: PropTypes.string,
    code: PropTypes.string,
  }),
  detailed: PropTypes.bool,
}

export default KpiTimeSeriesTooltip
