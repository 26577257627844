import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { conditionsMassEditOperations } from 'api/conditions/conditions'
import DocumentTypeInput from 'components/domains/documents/DocumentTypeInput'
import DocumentTypes, { allowedTypes } from 'components/domains/documents/DocumentTypes'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const RequirementsMassEditDocumentTypeSelect = ({ onChange, disabled }) => {
  const { t: tOperations } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.dialogs.mass-edit.operations',
  })

  const value = tOperations(conditionsMassEditOperations.keep)

  const {
    entityRef: { entityType: contextEntityType, entityId: contextEntityId },
  } = useContext(ConditionsContext)

  const selectedRequirementIds = useSelector(
    (state) => state.conditions.requirementsTable.selectedRows.selectedRequirementIds,
  )

  const mapReferencesToEntities = useCallback(
    (references) =>
      references?.entityIds?.map((id) => ({
        id,
        type: references?.entityType,
      })) ?? [
        {
          id: contextEntityId,
          type: contextEntityType,
        },
      ],
    [contextEntityId, contextEntityType],
  )

  const selectedEntities = useMemo(
    () =>
      Object.entries(selectedRequirementIds).flatMap(([selectedRequirementId, { references }]) => [
        ...mapReferencesToEntities(references),
        {
          id: selectedRequirementId,
          type: DocumentTypes.Requirement,
        },
      ]),
    [mapReferencesToEntities, selectedRequirementIds],
  )

  const documentEntityReferenceTypes = useMemo(() => {
    const filteredEntities = selectedEntities.filter(
      (entity) => entity.type !== DocumentTypes.Requirement,
    )

    switch (contextEntityType) {
      case DocumentTypes.BusinessPartner:
        if (filteredEntities.every((entity) => entity.type === DocumentTypes.BusinessPartner)) {
          return selectedEntities.some((entity) => entity.id === contextEntityId)
            ? [...selectedEntities]
            : [{ id: contextEntityId, type: contextEntityType }, ...selectedEntities]
        }
        return [
          { type: contextEntityType, id: contextEntityId },
          ...selectedEntities.filter(
            (entity) =>
              entity.type === DocumentTypes.Requirement ||
              entity.type === DocumentTypes.BusinessPartner,
          ),
        ]

      case DocumentTypes.Deal:
        if (
          filteredEntities.every(
            (entity) => entity.type === DocumentTypes.Deal && filteredEntities.length > 0,
          )
        ) {
          return selectedEntities
        }

        if (filteredEntities.length === 0) {
          return [{ id: contextEntityId, type: contextEntityType }]
        }

        return selectedEntities.filter((entity) => allowedTypes.includes(entity.type))

      default:
        return selectedEntities
    }
  }, [contextEntityId, contextEntityType, selectedEntities])

  const handleChange = useCallback(
    (selection) => {
      if (selection === conditionsMassEditOperations.keep) {
        onChange({ operation: conditionsMassEditOperations.keep })
        return
      }
      onChange({ operation: conditionsMassEditOperations.replace, value: selection })
    },
    [onChange],
  )

  const toBeIgnoredValues = useMemo(() => [value], [value])

  return (
    <DocumentTypeInput
      value={value}
      toBeIgnoredValues={toBeIgnoredValues}
      onChange={handleChange}
      entityRefs={documentEntityReferenceTypes}
      emptyOptionText={value}
      disabled={disabled}
    />
  )
}

RequirementsMassEditDocumentTypeSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default RequirementsMassEditDocumentTypeSelect
