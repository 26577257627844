import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DocumentsTable from 'components/domains/documents/documents-table/DocumentsTable'
import mapDocumentsForTable from 'components/domains/documents/documents-table/mapDocumentsForTable'
import {
  labelNewDocuments,
  labelReferencedDocuments,
} from 'components/domains/markets/detail/cards/MarketDocumentTableFunctions'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useDocumentsTableColumns } from 'hooks/services/documents/documents-table/useDocumentsTableColumnDefinitions'

const DealDocumentsTable = ({
  documentRoot,
  uploadedDocumentIds,
  isLoading,
  isError,
  handleOnDrop,
  hasDocumentUploadPermission,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.documents',
  })

  const labeledDocuments = useMemo(
    () => labelNewDocuments(documentRoot, uploadedDocumentIds),
    [documentRoot, uploadedDocumentIds],
  )

  const referencedDocuments = useMemo(
    () => labelReferencedDocuments(labeledDocuments),
    [labeledDocuments],
  )

  const preparedDocumentRoot = useMemo(
    () => mapDocumentsForTable(referencedDocuments),
    [referencedDocuments],
  )

  const { columns, formatDate } = useDocumentsTableColumns()

  return (
    <Card id="document-card">
      <RequestStateResolver
        isLoading={isLoading}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        isError={isError}
        renderContent={() => (
          <DocumentsTable
            columns={columns}
            folders={preparedDocumentRoot.subFolders}
            title={t('title')}
            formatDate={formatDate}
            handleOnDrop={handleOnDrop}
            hasDocumentUploadPermission={hasDocumentUploadPermission}
          />
        )}
      />
    </Card>
  )
}

DealDocumentsTable.propTypes = {
  documentRoot: PropTypes.object.isRequired,
  uploadedDocumentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  handleOnDrop: PropTypes.func.isRequired,
  hasDocumentUploadPermission: PropTypes.bool.isRequired,
}

export default DealDocumentsTable
