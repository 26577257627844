import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import CovenantCheckSelectionTable from 'components/domains/conditions/covenant-check-selection/CovenantCheckSelectionTable'
import { ReferenceEntityType } from 'components/ui/data/ReferenceEntityType'
import Dialog, { DialogSecondaryButton, DialogSize } from 'components/ui/dialog/Dialog'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useAnalyticalTableDialogFocusFix from 'hooks/analytical-table/useAnalyticalTableDialogFocusFix'
import useCovenantChecks from 'hooks/services/deals/covenants/checks/useCovenantChecks'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const EntityTypeMap = {
  [cwpEntityTypes.DEAL]: ReferenceEntityType.Deal,
  [cwpEntityTypes.BUSINESS_PARTNER]: ReferenceEntityType.BusinessPartner,
}

/**
 * @typedef {{covenantCheckUuid: string, name: string, linkedEventId: string, breach: boolean}} CovenantCheck
 * @typedef Params
 * @property {boolean} isOpen
 * @property {Function} onCancel
 * @property {({covenantCheck: CovenantCheck}) => void} onSelect
 *
 * @param {Params} params
 * @returns {import('react').ReactElement}
 */
const CovenantCheckSelectionHelperDialog = ({ isOpen, onCancel, onSelect }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.covenant-check-selection',
  })
  const dialogRef = useRef()

  const {
    entityRef: { entityId, entityType },
  } = useContext(ConditionsContext)

  const extraBusinessPartnerQueryCheck =
    EntityTypeMap[entityType] === ReferenceEntityType.BusinessPartner

  const {
    data: covenantChecksData,
    isFetching,
    isError,
  } = useCovenantChecks({ mainEntityId: entityId, mainEntityType: EntityTypeMap[entityType] })

  const {
    data: covenantChecksDataForExtraBusinessPartnerQuery,
    isFetching: isFetchingExtraBusinessPartnerQuery,
    isError: isErrorExtraBusinessPartnerQuery,
  } = useCovenantChecks({
    monitoringReferenceEntityId: entityId,
    mainEntityType: ReferenceEntityType.Deal,
    monitoringReferenceEntityType: EntityTypeMap[entityType],
    options: { enabled: extraBusinessPartnerQueryCheck },
  })

  const covenantChecks = useMemo(() => {
    if (extraBusinessPartnerQueryCheck) {
      return [
        ...(covenantChecksData?.covenantChecks || []),
        ...(covenantChecksDataForExtraBusinessPartnerQuery?.covenantChecks || []),
      ]
    } else {
      return [...(covenantChecksData?.covenantChecks || [])]
    }
  }, [
    covenantChecksData?.covenantChecks,
    extraBusinessPartnerQueryCheck,
    covenantChecksDataForExtraBusinessPartnerQuery?.covenantChecks,
  ])

  const handleCloseButtonClick = useCallback(() => {
    const dialog = dialogRef.current
    if (dialog) {
      dialog.close()
    }
  }, [])

  useAnalyticalTableDialogFocusFix({ dialogRef })

  const renderTable = useCallback(
    () => (
      <CovenantCheckSelectionTable
        covenantChecks={covenantChecks || []}
        onSelectTableRow={onSelect}
        mainEntityType={entityType}
      />
    ),
    [covenantChecks, entityType, onSelect],
  )

  return (
    <Dialog
      open={isOpen}
      onAfterClose={onCancel}
      size={DialogSize.XL}
      headerText={t('title')}
      ref={dialogRef}
      closeButton={
        <DialogSecondaryButton onClick={handleCloseButtonClick}>
          {tNoPrefix('buttons.close')}
        </DialogSecondaryButton>
      }
    >
      {isOpen && (
        <RequestStateResolver
          isError={isError || isErrorExtraBusinessPartnerQuery}
          isLoading={isFetching || isFetchingExtraBusinessPartnerQuery}
          errorToDisplay={<ErrorDataUnavailableInContent />}
          renderContent={renderTable}
          center
        />
      )}
    </Dialog>
  )
}

CovenantCheckSelectionHelperDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default CovenantCheckSelectionHelperDialog
