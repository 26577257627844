import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import EventsAndTasksCommentMultipleButton from 'components/domains/business-events-and-tasks/common/comments/EventsAndTasksCommentMultipleButton'
import EventsAndTasksCommentMultipleDialog from 'components/domains/business-events-and-tasks/common/comments/EventsAndTasksCommentMultipleDialog'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import { usePublishTasksComment } from 'hooks/services/business-events-and-tasks/tasks/usePublishTasksComment'

const useMultipleTasksCommentButtonAndDialog = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.comment-multiple',
  })
  const [isCommentMultipleDialogOpen, setIsCommentMultipleDialogOpen] = useState(false)
  const [commentValue, setCommentValue] = useState('')
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const showMessageBox = useShowMessageBox()

  const selectedTasks = useSelector((state) => state.tasks.taskTable.selectedRows.selectedTaskIds)
  const selectedTaskIds = useMemo(() => Object.keys(selectedTasks), [selectedTasks])

  const { mutate: mutateSaveTasksComments, isLoading } = usePublishTasksComment({
    onSuccess: () => {
      setIsCommentMultipleDialogOpen(false)
      setCommentValue('')
      showToast({ children: t('save.success.tasks', { numberOfTasks: selectedTaskIds.length }) })

      queryClient.invalidateQueries({
        queryKey: ['events'],
        predicate: (query) => {
          // check for `/events/*/tasks/taskId`
          const [key1, _, key3, key4, key5] = query.queryKey
          return (
            key1 === 'events' &&
            key3 === 'tasks' &&
            key5 === 'comments' &&
            selectedTaskIds.includes(key4)
          )
        },
      })
    },
    onError: () => {
      showMessageBox({
        type: MessageBoxTypes.Error,
        titleText: t('save.error.title'),
        children: t('save.error.description'),
      })
    },
  })

  const onSaveClicked = useCallback(() => {
    mutateSaveTasksComments({ taskIds: selectedTaskIds, comment: commentValue })
  }, [commentValue, mutateSaveTasksComments, selectedTaskIds])

  const onCommentMultipleClicked = useCallback(() => {
    setIsCommentMultipleDialogOpen(true)
  }, [])
  const commentMultipleButton = useMemo(
    () => (
      <EventsAndTasksCommentMultipleButton
        key="task-table-edit-multiple-button"
        selectedEntities={selectedTaskIds}
        onClick={onCommentMultipleClicked}
      />
    ),
    [onCommentMultipleClicked, selectedTaskIds],
  )

  const commentMultipleDialog = useMemo(
    () =>
      isCommentMultipleDialogOpen && (
        <EventsAndTasksCommentMultipleDialog
          isDialogOpen={isCommentMultipleDialogOpen}
          setIsDialogOpen={setIsCommentMultipleDialogOpen}
          onSaveClicked={onSaveClicked}
          commentValue={commentValue}
          setCommentValue={setCommentValue}
          isSaveLoading={isLoading}
        />
      ),
    [commentValue, isCommentMultipleDialogOpen, isLoading, onSaveClicked],
  )

  return useMemo(
    () => ({ commentMultipleButton, commentMultipleDialog }),
    [commentMultipleButton, commentMultipleDialog],
  )
}

export default useMultipleTasksCommentButtonAndDialog
