// Copied from components/domains/conditions/overview/ConditionsTableToolbarFilterDialog
const availableFilterTypes = {
  betweenDates: 'BETWEEN_DATES',
  assignee: 'ASSIGNEE',
  condition: 'CONDITION',
  externalAssignee: 'EXTERNAL_ASSIGNEE',
  loadingDropdownSingleSelect: 'LOADING_DROPDOWN_SINGLE_SELECT',
  loadingDropdownMultiComboBox: 'LOADING_DROPDOWN_MULTI_COMBO_BOX',
  covenantCheck: 'COVENANT_CHECK',
  referencesMultiSelect: 'REFERENCES_MULTI_SELECT',
}

// Copied from components/ui/tables/sorted-tables/useFilters
const filterTypesEmptyValues = {
  BETWEEN_DATES: { lowerBound: '', upperBound: '' },
  BETWEEN_NUMBERS: { lowerBound: '', upperBound: '' },
  CONTAINS: '',
  OF_ENUM_TYPE: '',
  MULTISELECT_ENUM: [],
  MULTISELECT_ENUM_IN_LIST: [],
  CUSTOM: 'FILTER_NOT_APPLIED',
}

// Copied from components/domains/conditions/overview/ConditionsTableToolbarFilterDialog
export const filterTypes = {
  [availableFilterTypes.betweenDates]: {
    emptyValue: filterTypesEmptyValues.BETWEEN_DATES,
  },
  [availableFilterTypes.condition]: {
    emptyValue: '',
  },
  [availableFilterTypes.assignee]: {
    emptyValue: '',
  },
  [availableFilterTypes.externalAssignee]: {
    emptyValue: '',
  },
  [availableFilterTypes.loadingDropdownSingleSelect]: {
    emptyValue: '',
  },
  [availableFilterTypes.loadingDropdownMultiComboBox]: {
    emptyValue: [],
  },
  [availableFilterTypes.covenantCheck]: {
    emptyValue: undefined,
  },
  [availableFilterTypes.referencesMultiSelect]: {
    emptyValue: undefined,
  },
}
