import { BusyIndicatorSize, ObjectStatus } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getObjectStatusForEventStatus } from 'api/events/status'
import DecisionStageStatus from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageStatus'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import CreateMonitoringEventButton from 'components/domains/deals/covenant-check-detail/monitoring-event/CreateMonitoringEventButton'
import styles from 'components/domains/deals/covenant-check-detail/monitoring-event/MonitoringEventCard.module.css'
import SynchronizationStatus from 'components/domains/deals/covenant-check-detail/monitoring-event/SynchronizationStatus'
import Card from 'components/ui/card/Card'
import CardHeaderWithButtons from 'components/ui/card/CardHeaderWithButtons'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import Entity from 'components/ui/data/Entity'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import { ErrorDataUnavailableInTable } from 'components/ui/errors/ErrorDataUnavailableInTable'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useEvent from 'hooks/services/business-events-and-tasks/events/useEvent'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'
import useCovenantCheckById from 'hooks/services/deals/covenants/useCovenantCheckById'
import paths from 'routes/paths'

const MonitoringEventCard = ({ covenantCheckUuid }) => {
  const { t: tWithoutPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.monitoring-event-card',
  })
  const { format: formatDate } = useShortDateFormatter()

  const {
    data: { linkedEventId, status: covenantCheckStatus } = {},
    isFetching: isFetchingCovenantCheck,
    isError: isErrorCovenantCheck,
  } = useCovenantCheckById(covenantCheckUuid)

  const {
    data: event,
    isFetching: isFetchingEvent,
    isError: isErrorEvent,
  } = useEvent(linkedEventId, {
    enabled: !!linkedEventId,
  })

  const {
    data: eventAssignee,
    isFetching: isFetchingAssignee,
    isError: isErrorAssignee,
  } = useStaffMemberById(event?.info?.assignee)

  const [isLoading, isError] = useMemo(
    () => [isFetchingEvent || isFetchingCovenantCheck, isErrorEvent || isErrorCovenantCheck],
    [isFetchingEvent, isFetchingCovenantCheck, isErrorEvent, isErrorCovenantCheck],
  )

  const noLinkedEvent = useMemo(
    () => !isFetchingCovenantCheck && !linkedEventId,
    [isFetchingCovenantCheck, linkedEventId],
  )

  const renderEventStatus = (status) => {
    const { translationKey: statusTranslationKey, objectStatus } =
      getObjectStatusForEventStatus(status)
    return (
      <ObjectStatus inverted state={objectStatus}>
        {tWithoutPrefix(statusTranslationKey)}
      </ObjectStatus>
    )
  }

  return (
    <Card
      header={
        <CardHeaderWithButtons title={t('title')}>
          <CreateMonitoringEventButton
            covenantCheckUuid={covenantCheckUuid}
            covenantCheckStatus={covenantCheckStatus}
            linkedEvent={event}
          />
        </CardHeaderWithButtons>
      }
    >
      {noLinkedEvent ? (
        <EmptyCardContent
          title={tWithoutPrefix('components.cards.empty.title')}
          subtitle={tWithoutPrefix('components.cards.empty.subtitle')}
        />
      ) : (
        <RequestStateResolver
          isLoading={isLoading}
          isError={isError}
          errorToDisplay={<ErrorDataUnavailableInTable />}
          center
          renderContent={() => (
            <>
              <CardSection title={t('basic-information')}>
                <CardShowItem
                  id="business-event"
                  label={t('label.business-event')}
                  value={
                    <Entity
                      id={event?.displayId}
                      name={event?.info?.name}
                      link={`/${paths.businessEventsAndTasks}/${paths.businessEvents}/${event?.id}`}
                      openInNewTab
                      className={styles.entity}
                    />
                  }
                />
                <CardShowItem
                  id="creation-date"
                  label={t('label.creation-date')}
                  value={formatDate(event?.info?.creationDate)}
                />
                <CardShowItem
                  id="current-due-date"
                  label={t('label.current-due-date')}
                  value={formatDate(event?.info?.currentDueDate)}
                />
                <CardShowItem
                  id="original-due-date"
                  label={t('label.original-due-date')}
                  value={formatDate(event?.info?.originalDueDate)}
                />
                <CardShowItem
                  id="assignee"
                  label={t('label.assignee')}
                  value={
                    <RequestStateResolver
                      isLoading={isFetchingAssignee}
                      isError={isErrorAssignee}
                      errorToDisplay={<ErrorDataUnavailableInCell />}
                      busyIndicatorSize={BusyIndicatorSize.Small}
                      renderContent={() => eventAssignee?.fullName ?? '-'}
                    />
                  }
                />
              </CardSection>
              <CardSection title={t('statuses')}>
                <CardShowItem
                  id="business-event-status"
                  label={t('label.business-event-status')}
                  value={renderEventStatus(event?.status)}
                />
                <CardShowItem
                  id="decision-status"
                  label={t('label.decision-status')}
                  value={<DecisionStageStatus eventId={linkedEventId} />}
                />
                <CardShowItem
                  id="sync-status"
                  label={t('label.sync-status')}
                  value={<SynchronizationStatus eventId={linkedEventId} />}
                />
              </CardSection>
            </>
          )}
        />
      )}
    </Card>
  )
}

MonitoringEventCard.propTypes = {
  covenantCheckUuid: PropTypes.string.isRequired,
}

export default MonitoringEventCard
