import PropTypes from 'prop-types'
import Link from 'components/ui/link/Link'

const propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  head: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  bpId: PropTypes.string.isRequired,
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const Unit = ({ id, type, head, bpId }) => (
  <Link target="_blank" href={`/business-partners/${bpId}/relationships#${id}`}>
    <b>
      {type}:&nbsp;{head.name}
    </b>
  </Link>
)

Unit.propTypes = propTypes

export default Unit
