import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultipleFinanceReports = ({ businessPartnerIds, options = {} }) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: businessPartnerIds.map((businessPartnerId) => ({
        path: `/businesspartners/${businessPartnerId}/finance-reports`,
        keys: ['businesspartners', businessPartnerId, 'finance-reports'],
      })),
      translated: true,
      useCache: true,
      options: { ...options, enabled: !isEmpty(businessPartnerIds) },
    }),
  )

  return useMemo(
    () =>
      responses.map((result, index) => ({
        ...result,
        data: { businessPartnerId: businessPartnerIds[index], ...camelize(result.data) },
      })),
    [businessPartnerIds, responses],
  )
}

export default useMultipleFinanceReports
