import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useEditTaskComment = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(
    async ({ eventId, taskId, commentId, comment }) =>
      patch({
        path: `/events/${eventId}/tasks/${taskId}/comments/${commentId}`,
        body: {
          comment,
        },
      }),
    mutationOptions,
  )
}
