import { MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/tasks/watchers/TasksWatchersButton.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import WatcherButton from 'components/ui/watcher/WatcherButton'
import useAddTaskWatcher from 'hooks/services/business-events-and-tasks/tasks/useAddTaskWatcher'
import useDeleteTaskWatcher from 'hooks/services/business-events-and-tasks/tasks/useDeleteTaskWatcher'
import useTaskWatchers from 'hooks/services/business-events-and-tasks/tasks/useTaskWatchers'
import { useStaffMemberSelf } from 'hooks/services/business-partners/staff-members/useStaffMemberSelf'

const TasksWatchersButton = ({ eventId, taskId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.tasks.watchers',
  })

  const {
    isLoading: isStaffMemberLoading,
    isError: isStaffMemberError,
    data: ownUser,
  } = useStaffMemberSelf()

  const {
    isLoading: isWatcherLoading,
    isError: isWatcherError,
    data: watchersData,
  } = useTaskWatchers({ eventId, taskId })

  const { mutate: addTaskWatcher } = useAddTaskWatcher()
  const { mutate: deleteTaskWatcher } = useDeleteTaskWatcher()
  const showMessageBox = Modals.useShowMessageBox()
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()
  const errorLabel = useMemo(
    () => <div className={styles.errorLabel}>{t('error.data-unavailable')}</div>,
    [t],
  )
  const onError = useCallback(() => {
    showMessageBox({
      children: t('error.text'),
      type: MessageBoxTypes.Error,
      draggable: true,
      resizable: true,
    })
  }, [showMessageBox, t])
  const onSuccess = useCallback(
    ({ successText }) => {
      showToast({ children: successText })
      queryClient.invalidateQueries(['events', 'tasks'])
      queryClient.invalidateQueries(['events', eventId, 'tasks'])
    },
    [queryClient, showToast, eventId],
  )

  const removeWatcher = useCallback(
    (watcherId) => {
      deleteTaskWatcher(
        { eventId, taskId, watcherId },
        {
          onSuccess: () => {
            onSuccess({ successText: t('unsubscribe.success') })
          },
          onError: onError,
        },
      )
    },
    [onError, onSuccess, t, deleteTaskWatcher, eventId, taskId],
  )

  const addWatcher = useCallback(
    (userId) => {
      addTaskWatcher(
        { eventId, taskId, userId },
        {
          onSuccess: () => {
            onSuccess({ successText: t('subscribe.success') })
          },
          onError: onError,
        },
      )
    },
    [onError, onSuccess, t, addTaskWatcher, eventId, taskId],
  )

  const renderContent = useCallback(
    () => (
      <WatcherButton
        id={`task-watchers-${taskId}`}
        watchers={watchersData.watchers}
        userId={ownUser.id}
        onUserSubscribe={addWatcher}
        onUserUnsubscribe={removeWatcher}
      />
    ),
    [taskId, watchersData?.watchers, ownUser?.id, addWatcher, removeWatcher],
  )

  return (
    <SmallLoadingWrapper
      isLoading={isStaffMemberLoading || isWatcherLoading}
      isError={isStaffMemberError || isWatcherError}
      error={errorLabel}
      renderContent={renderContent}
    />
  )
}

TasksWatchersButton.propTypes = {
  eventId: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
}

export default TasksWatchersButton
