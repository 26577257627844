import MarketDetail from 'routes/markets/MarketDetail'
import MarketsOverview from 'routes/markets/MarketsOverview'

export const paths = {
  overview: '',
  detail: ':marketId/*',
}

const MarketsRoutes = [
  { path: paths.overview, index: true, element: <MarketsOverview /> },
  {
    path: paths.detail,
    element: <MarketDetail />,
  },
]

export default MarketsRoutes
