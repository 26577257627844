import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useCashflowScenarioInputParametersById = ({ dealUuid, cashflowId }, options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/cashflow-scenarios/${cashflowId}/cashflow/input-parameters`,
      translated: true,
      useCache: true,
      keys: ['deals', dealUuid, 'cashflow-scenarios', cashflowId, 'cashflow', 'input-parameters'],
      options: { ...options, enabled: !!dealUuid && !!cashflowId },
    }),
  )

export default useCashflowScenarioInputParametersById
