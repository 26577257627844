import { useTranslation } from 'react-i18next'
import { sapBusinessPartnerRoles } from 'api/property/sapBusinessPartnerRoles'
import { useSapBusinessPartnerRoles } from 'hooks/services/properties/useSapBusinessPartnerRoles'

/**
 * @returns {string|undefined}
 */
const getFirstCreationRoleForType = ({ type, availableRoles = [] }) => {
  switch (type) {
    case 'brand-franchise-provider':
      return availableRoles.find(
        ({ role, isWriteCode }) =>
          role === sapBusinessPartnerRoles.brandingFranchiseProviderRole && isWriteCode,
      )?.businessPartnerRoleCode

    case 'tenant':
      return availableRoles.find(
        ({ role, isWriteCode }) => role === sapBusinessPartnerRoles.tenantRole && isWriteCode,
      )?.businessPartnerRoleCode

    default:
      return undefined
  }
}

const useBusinessPartnerCreateDialogOptions = (type) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.rent-roll-working-version.dialog-create',
  })
  const { data: { configSapBusinessPartnerRoles = [] } = {} } = useSapBusinessPartnerRoles()

  return {
    title: t('title', { type: t(type) }) ?? '',
    creationRole: getFirstCreationRoleForType({
      type,
      availableRoles: configSapBusinessPartnerRoles,
    }),
  }
}

export default useBusinessPartnerCreateDialogOptions
