import { DatePicker } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DebtPerEBITDACommentCurrencySelector, {
  DEBT_PER_EBITDA_COMMENT_CURRENCY_SELECTOR_DEFAULT_CURRENCY_CODE,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/leveraged-transaction/debt-per-ebitda-comment/DebtPerEBITDACommentCurrencySelector'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/leveraged-transaction/debt-per-ebitda-comment/DebtPerEBITDACommentEdit.module.css'
import DebtPerEBITDACommentInputRow from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/leveraged-transaction/debt-per-ebitda-comment/DebtPerEBITDACommentInputRow'
import DebtPerEBITDACommentSection from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/leveraged-transaction/debt-per-ebitda-comment/DebtPerEBITDACommentSection'
import FormattedNumberInput from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/pd-rating/shared/ui/input/FormattedNumberInput'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const DebtPerEBITDACommentEdit = ({ keyPath, comment, disabled, handleCommentChange }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.nbcs.leveraged-transaction.Q11-comment',
  })
  const { localePattern, parse } = useShortDateFormatter()

  const onChange = (updatedPartialComment) => {
    handleCommentChange(keyPath, {
      totalDebtCurrencyCode: DEBT_PER_EBITDA_COMMENT_CURRENCY_SELECTOR_DEFAULT_CURRENCY_CODE,
      ebitdaCurrencyCode: DEBT_PER_EBITDA_COMMENT_CURRENCY_SELECTOR_DEFAULT_CURRENCY_CODE,
      ...comment,
      ...updatedPartialComment,
    })
  }

  const onKeyDateChange = (event) => {
    onChange({ keyDate: parse(event?.target?.value, localePattern) ?? undefined })
  }

  return (
    <DebtPerEBITDACommentSection heading={t('heading')}>
      <DebtPerEBITDACommentInputRow label={t('key-date.label')}>
        <span className={styles.prefix}>{t('key-date.prefix')}</span>
        <DatePicker
          value={comment?.keyDate}
          formatPattern={localePattern}
          placeholder={localePattern}
          disabled={disabled}
          onChange={onKeyDateChange}
          className={styles.datePicker}
        />
      </DebtPerEBITDACommentInputRow>
      <DebtPerEBITDACommentInputRow label={t('total-debt.label')}>
        <span className={styles.prefix} />
        <FormattedNumberInput
          value={comment?.totalDebt}
          disabled={disabled}
          onChange={(totalDebt) => onChange({ totalDebt })}
          className={styles.numberInput}
        />
        <DebtPerEBITDACommentCurrencySelector
          selectedCurrencyCode={comment?.totalDebtCurrencyCode}
          disabled={disabled}
          onChange={(totalDebtCurrencyCode) => onChange({ totalDebtCurrencyCode })}
          className={styles.suffix}
        />
      </DebtPerEBITDACommentInputRow>
      <DebtPerEBITDACommentInputRow label={t('ebitda.label')}>
        <span className={styles.prefix} />
        <FormattedNumberInput
          value={comment?.ebitda}
          disabled={disabled}
          onChange={(ebitda) => onChange({ ebitda })}
          className={styles.numberInput}
        />
        <DebtPerEBITDACommentCurrencySelector
          selectedCurrencyCode={comment?.ebitdaCurrencyCode}
          disabled={disabled}
          onChange={(ebitdaCurrencyCode) => onChange({ ebitdaCurrencyCode })}
          className={styles.suffix}
        />
      </DebtPerEBITDACommentInputRow>
    </DebtPerEBITDACommentSection>
  )
}

DebtPerEBITDACommentEdit.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.string).isRequired,
  comment: PropTypes.shape({
    keyDate: PropTypes.string,
    totalDebt: PropTypes.number,
    totalDebtCurrencyCode: PropTypes.string,
    ebitda: PropTypes.number,
    ebitdaCurrencyCode: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  handleCommentChange: PropTypes.func.isRequired,
}

export default DebtPerEBITDACommentEdit
