import orderBy from 'lodash.orderby'
import { useRequest } from 'hooks/services/baseService'

const sortedRegions = (result) => orderBy(result.data.regions, ['name'])

export const useRegions = (country) => {
  const result = useRequest({
    path: `/businesspartners/value-helps/regions?country=${country}`,
    translated: true,
    keys: ['regions', country],
    options: {
      enabled: !!country,
    },
  })

  const data = result.data && { regions: sortedRegions(result) }

  return {
    ...result,
    data,
  }
}
