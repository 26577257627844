import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Option,
  Select,
  TextArea,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/waiver/WaiverImpactClassificationTileContentEdit.module.css'
import {
  KEY_RISK_IMPACT,
  NO_OR_LIMITED_RISK_IMPACT,
  SIGNIFICANT_RISK_IMPACT,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/waiver/constants'

const EMPTY_OPTION = ''

const WaiverImpactClassificationTileContentEdit = ({ onChange, content }) => {
  const textAreaRef = useRef(null)

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.waiver',
  })

  const handleOnClassificationSelectChange = useCallback(
    ({
      detail: {
        selectedOption: { value: newValue },
      },
    }) => {
      onChange({ ...content, classification: newValue })
    },
    [content, onChange],
  )

  const handleOnReasonChange = useCallback(
    ({ target: { value: newValue } }) => {
      onChange({ ...content, reason: newValue })
    },
    [content, onChange],
  )

  const ClassificationSelect = () => (
    <Select onChange={handleOnClassificationSelectChange} className={styles.editComponent}>
      <Option value={EMPTY_OPTION} selected={isNil(content.classification)}>
        {EMPTY_OPTION}
      </Option>
      <Option
        value={NO_OR_LIMITED_RISK_IMPACT}
        selected={content.classification === NO_OR_LIMITED_RISK_IMPACT}
      >
        {t('classification.type.no-or-limited-risk-impact')}
      </Option>
      <Option
        value={SIGNIFICANT_RISK_IMPACT}
        selected={content.classification === SIGNIFICANT_RISK_IMPACT}
      >
        {t('classification.type.significant-risk-impact')}
      </Option>
      <Option value={KEY_RISK_IMPACT} selected={content.classification === KEY_RISK_IMPACT}>
        {t('classification.type.key-risk-impact')}
      </Option>
    </Select>
  )

  // Fixes error "Cannot read properties of null (reading 'querySelector')" from ui5's TextArea._onfocusout
  // In this case no, shadow-root is available so undefined is used as fallback instead and checked by lodash.isNil
  // Otherwise, functionality is the same.
  useEffect(() => {
    if (typeof textAreaRef.current?._onfocusout === 'function') {
      textAreaRef.current._onfocusout = (e) => {
        const eTarget = e?.relatedTarget
        const focusedOutToValueStateMessage =
          eTarget?.shadowRoot?.querySelector('.ui5-valuestatemessage-root') ?? undefined
        textAreaRef.current.focused = false
        if (!isNil(focusedOutToValueStateMessage)) {
          textAreaRef.current._openValueStateMsgPopover = false
        }
      }
    }
  }, [textAreaRef?.current?._onfocusout])

  const ReasonFieldTextArea = () => (
    <TextArea
      ref={textAreaRef}
      className={styles.editComponent}
      value={content.reason}
      onChange={handleOnReasonChange}
      maxlength={500}
    />
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.wrapper}>
      <FlexBox
        direction={FlexBoxDirection.Row}
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        alignItems={FlexBoxAlignItems.Center}
        className={styles.row}
      >
        <Label>{t('classification.label')}</Label>
        <ClassificationSelect />
      </FlexBox>
      <FlexBox
        direction={FlexBoxDirection.Row}
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        alignItems={FlexBoxAlignItems.Center}
        className={styles.row}
      >
        <Label>{t('reason.label')}</Label>
        <ReasonFieldTextArea />
      </FlexBox>
    </FlexBox>
  )
}

WaiverImpactClassificationTileContentEdit.propTypes = {
  onChange: PropTypes.func.isRequired,
  content: PropTypes.shape({
    classification: PropTypes.string,
    reason: PropTypes.string,
  }),
}

export default WaiverImpactClassificationTileContentEdit
