import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import ConditionSelectionHelperDialog from 'components/domains/conditions/condition-selection/ConditionSelectionHelperDialog'
import ConditionSelectionInput from 'components/domains/conditions/condition-selection/ConditionSelectionInput'
import useGetCondition from 'hooks/services/conditions/useGetCondition'

const ConditionSelectionFilter = ({ value, setSelectedFilters, columnKey }) => {
  const [isHelperDialogOpen, setIsHelperDialogOpen] = useState(false)
  const [conditionName, setConditionName] = useState('')

  const { data: conditionResponse } = useGetCondition(
    'external',
    { conditionId: value },
    { enabled: !!value },
  )
  const handleConditionSelectionClicked = useCallback(() => setIsHelperDialogOpen(true), [])
  const handleCancelConditionSelection = useCallback(() => setIsHelperDialogOpen(false), [])

  const handleOnClear = useCallback(() => {
    setSelectedFilters((currentlySelectedFilters) =>
      currentlySelectedFilters.map((filter) => {
        if (filter.columnKey !== columnKey) {
          return filter
        }
        return { ...filter, value: '' }
      }),
    )
  }, [columnKey, setSelectedFilters])

  const handleSelectionChanged = ({ condition }) => {
    if (isNil(condition)) {
      handleOnClear()
      return
    }
    setConditionName(condition.info.name)
    setIsHelperDialogOpen(false)
    setSelectedFilters((currentlySelectedFilters) =>
      currentlySelectedFilters.map((filter) => {
        if (filter.columnKey !== columnKey) {
          return filter
        }
        return { ...filter, value: condition.id }
      }),
    )
  }

  useEffect(() => {
    if (conditionResponse?.info.name) {
      setConditionName(conditionResponse?.info.name)
    }
  }, [conditionResponse?.info.name])

  return (
    <>
      <ConditionSelectionInput
        name={conditionName}
        onClick={handleConditionSelectionClicked}
        onClear={handleOnClear}
        hasError={false}
      />
      {isHelperDialogOpen && (
        <ConditionSelectionHelperDialog
          isOpen={isHelperDialogOpen}
          onCancel={handleCancelConditionSelection}
          onSelect={handleSelectionChanged}
        />
      )}
    </>
  )
}

ConditionSelectionFilter.propTypes = {
  value: PropTypes.string.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  columnKey: PropTypes.string.isRequired,
}

export default ConditionSelectionFilter
