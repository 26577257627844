import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxAlignItems,
  Label,
  Option,
  Select,
  Title,
  TableColumn,
  TableRow,
  TableCell,
  Grid,
  IllustrationMessageType,
  TitleLevel,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DecisionPaperLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/links/DecisionPaperLeaveConfirmationLink'
import DecisionPaperTileListItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListItem'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import DecisionPaperTileTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileTable'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenant-details/TenantDetailsTile.module.css'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenant-details/shared/TenantDetailsEmptyCardContent'
import Entity from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenant-details/shared/TenantDetailsEntity'
import {
  useTenantDetailsAreaMeasurementUnitFormatter,
  useTenantDetailsCustomizableCurrencyFormatter,
  useTenantDetailsNumberFormatter,
  useTenantDetailsShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenant-details/shared/i18n/useTenantDetailsI18n'
import FormattedTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenant-details/shared/rich-text-editor/TenantDetailsFormattedTextView'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'
import paths from 'routes/paths'

const selectFieldId = 'TenantDetailsTile__tenantSelector'

const NO_DATA_PLACEHOLDER = '–'

const getValueOrPlaceholder = (value) => value ?? NO_DATA_PLACEHOLDER

const MultipleTenantDetailsTile = ({
  tileId,
  isPdfView,
  showConfirmationForLinks,
  selectedDealIndex,
}) => {
  const { data: { multipleTenantDetails, tenantSourceRenders = [] } = {} } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId],
  )
  const { tenantDetails } = multipleTenantDetails?.[selectedDealIndex] ?? {}
  const sourceRender = useMemo(
    () => tenantSourceRenders?.[selectedDealIndex] ?? {},
    [selectedDealIndex, tenantSourceRenders],
  )
  const tenants = tenantDetails
  const dispatch = useDispatch()

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.tenant-details',
  })

  const formatNumber = useTenantDetailsNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formatCurrency = useTenantDetailsCustomizableCurrencyFormatter()
  const formatAreaUnit = useTenantDetailsAreaMeasurementUnitFormatter()
  const { format: formatDate } = useTenantDetailsShortDateFormatter()

  const tenantsArray = useMemo(
    () =>
      Object.values(tenants ?? {})
        .slice()
        .sort(({ sortIndex: sortA }, { sortIndex: sortB }) => sortA - sortB),
    [tenants],
  )

  const [selectedTenant, setSelectedTenant] = useState(() => tenantsArray[0])

  useEffect(() => {
    setSelectedTenant(tenantsArray[0])
  }, [tenantsArray])

  const onTenantChange = useCallback(
    (event) => {
      const tenantId = event?.detail?.selectedOption?.dataset?.id ?? 0
      dispatch(
        setSourceRender({ tileId, sourceRender: { ...sourceRender, businessPartnerId: tenantId } }),
      )
      setSelectedTenant(tenants[tenantId])
    },
    [dispatch, sourceRender, tenants, tileId],
  )

  if (tenantsArray.length === 0)
    return (
      <EmptyCardContent
        illustrationName={IllustrationMessageType.NoEntries}
        title={t('no-properties.title')}
        subtitle={t('no-properties.subtitle')}
      />
    )

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      {!isPdfView && (
        <FlexBox
          direction={FlexBoxDirection.Column}
          alignItems={FlexBoxAlignItems.Start}
          className={styles.cardSection}
        >
          <Label for={selectFieldId}>{t('tenant')}</Label>
          <Select id={selectFieldId} className={styles.select} onChange={onTenantChange}>
            {tenantsArray.map((tenant) => (
              <Option
                key={tenant.id}
                additionalText={tenant.id}
                data-id={tenant.id}
                selected={selectedTenant === tenant}
              >
                {tenant.name}
              </Option>
            ))}
          </Select>
        </FlexBox>
      )}

      {tenantsArray.map(
        (tenant) =>
          (isPdfView || tenant === selectedTenant) && (
            <Fragment key={tenant.id}>
              <div>
                {isPdfView && (
                  <>
                    <DecisionPaperTileListSeparator />
                    <Title level={TitleLevel.H3} className={styles.pdfViewTenantTitle}>
                      {tenant.name}
                    </Title>
                  </>
                )}
                <DecisionPaperTileListSeparator />
                <Grid defaultSpan="XL6 L6 M6 S6">
                  <div>
                    <DecisionPaperTileListItem
                      isFirstItem
                      label={t('business-partner-name')}
                      value={
                        isPdfView ? (
                          tenant.name
                        ) : (
                          <DecisionPaperLeaveConfirmationLink
                            link={`/${paths.businessPartners}/${tenant.id}`}
                            name={tenant.name}
                            showConfirmationForLinks={showConfirmationForLinks}
                          />
                        )
                      }
                    />
                    <DecisionPaperTileListItem label={t('business-partner-id')} value={tenant.id} />
                  </div>
                  <div>
                    <DecisionPaperTileListItem
                      isFirstItem
                      label={t('industry-code')}
                      value={getValueOrPlaceholder(tenant.industryCode)}
                    />
                    <DecisionPaperTileListItem
                      label={t('tenant-unit')}
                      value={getValueOrPlaceholder(tenant.tenantUnit)}
                    />
                  </div>
                </Grid>
                <DecisionPaperTileListSeparator />
                <Grid defaultSpan="XL6 L6 M6 S6">
                  <div>
                    <DecisionPaperTileListItem
                      isFirstItem
                      label={t('internal-rating.class')}
                      value={getValueOrPlaceholder(tenant.internalRating?.class)}
                    />
                    <DecisionPaperTileListItem
                      label={t('internal-rating.method')}
                      value={getValueOrPlaceholder(tenant.internalRating?.method)}
                    />
                    <DecisionPaperTileListItem
                      label={t('internal-rating.valid-from')}
                      value={getValueOrPlaceholder(formatDate(tenant.internalRating?.validFrom))}
                    />
                  </div>
                  <div>
                    <DecisionPaperTileListItem
                      isFirstItem
                      label={t('external-rating.grade')}
                      value={getValueOrPlaceholder(tenant.externalRating?.grade)}
                    />
                    <DecisionPaperTileListItem
                      label={t('external-rating.agency')}
                      value={getValueOrPlaceholder(tenant.externalRating?.agency)}
                    />
                    <DecisionPaperTileListItem
                      label={t('external-rating.valid-from')}
                      value={getValueOrPlaceholder(formatDate(tenant.externalRating?.validFrom))}
                    />
                  </div>
                </Grid>
                <DecisionPaperTileListSeparator />
                <Grid defaultSpan="XL6 L6 M6 S6">
                  <div>
                    <DecisionPaperTileListItem
                      isFirstItem
                      label={t('rented-area-sum-deal')}
                      value={getValueOrPlaceholder(
                        tenant.rentedArea &&
                          `${formatNumber(tenant.rentedArea.value)} ${formatAreaUnit(
                            tenant.rentedArea.measurement_unit,
                          )}`,
                      )}
                    />
                    <DecisionPaperTileListItem
                      label={t('annualized-current-rent-deal')}
                      value={getValueOrPlaceholder(
                        tenant.annualizedCurrentRent &&
                          formatCurrency(
                            tenant.annualizedCurrentRent.number,
                            tenant.annualizedCurrentRent.currency,
                          ),
                      )}
                    />
                  </div>
                </Grid>
                <DecisionPaperTileListSeparator />
              </div>

              <div className={styles.cardSection}>
                <Title level={TitleLevel.H4} className={styles.subheading}>
                  {t('appreciation')}
                </Title>
                {tenant?.appreciation ? (
                  <FormattedTextView formattedText={tenant?.appreciation} />
                ) : (
                  <EmptyCardContent />
                )}
              </div>

              <DecisionPaperTileListSeparator />

              <div className={styles.cardSection}>
                <Title level={TitleLevel.H4} className={styles.subheading}>
                  {t('properties', { count: tenant?.properties?.length ?? 0 })}
                </Title>
                <DecisionPaperTileTable
                  className={styles.propertiesTable}
                  columns={
                    <>
                      <TableColumn>
                        <Label>{t('property.name')}</Label>
                      </TableColumn>
                      <TableColumn>
                        <Label>{t('property.city')}</Label>
                      </TableColumn>
                      <TableColumn>
                        <Label>{t('property.country')}</Label>
                      </TableColumn>
                      <TableColumn>
                        <Label>{t('property.rented-area')}</Label>
                      </TableColumn>
                      <TableColumn>
                        <Label>{t('property.annualized-current-rent')}</Label>
                      </TableColumn>
                    </>
                  }
                >
                  {(tenant?.properties ?? []).map((property) => (
                    <TableRow key={property.id}>
                      <TableCell>
                        {!isPdfView ? (
                          <Entity
                            id={property.id}
                            name={property.name}
                            link={`/properties/${property.id}`}
                            className={styles.entity}
                            openInNewTab
                          />
                        ) : (
                          <>
                            <Label>{property.name}</Label>
                            <br />
                            <Text>{property.id}</Text>
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        <Label>{getValueOrPlaceholder(property.city)}</Label>
                      </TableCell>
                      <TableCell>
                        <Label>{getValueOrPlaceholder(property.country)}</Label>
                      </TableCell>
                      <TableCell>
                        <Label>
                          {getValueOrPlaceholder(
                            property.rentedArea &&
                              `${formatNumber(property.rentedArea.value)} ${formatAreaUnit(
                                property.rentedArea.measurement_unit,
                              )}`,
                          )}
                        </Label>
                      </TableCell>
                      <TableCell>
                        <Label>
                          {getValueOrPlaceholder(
                            property.annualizedCurrentRent &&
                              formatCurrency(
                                property.annualizedCurrentRent.number,
                                property.annualizedCurrentRent.currency,
                              ),
                          )}
                        </Label>
                      </TableCell>
                    </TableRow>
                  ))}
                </DecisionPaperTileTable>
              </div>
            </Fragment>
          ),
      )}
    </FlexBox>
  )
}

MultipleTenantDetailsTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultipleTenantDetailsTile
