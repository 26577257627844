import propTypes from 'prop-types'
import renderDisplayModeCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/renderDisplayModeCell'
import renderEditModeCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/renderEditModeCell'
import {
  useNumberFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const CustomerOptionsTableCell = ({ props, isEditMode }) => {
  const { format: formatDate } = useShortDateFormatter()
  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  if (isEditMode) {
    return renderEditModeCell(props)
  } else {
    return renderDisplayModeCell(props, formatDate, formatNumber)
  }
}

export default CustomerOptionsTableCell

CustomerOptionsTableCell.propTypes = {
  props: propTypes.object.isRequired,
  isEditMode: propTypes.bool.isRequired,
}
