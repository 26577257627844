import { AnalyticalTable, TextAlign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/details/deal-structure-tile/DrawdownDealStructureTile.module.css'
import CommitmentCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/details/deal-structure-tile/cells/CommitmentCell'
import PartyCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/details/deal-structure-tile/cells/PartyCell'

const DrawdownDealStructureTile = ({ tileId, showConfirmationForLinks }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.drawdown-deal-structure',
  })

  const { data: tileData } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const columns = useMemo(
    () => [
      {
        Header: t('columns.party'),
        accessor: 'partyRoleTranche',
        Cell: (cellProps) =>
          PartyCell({
            ...cellProps,
            dealDisplayId: tileData?.sourceRender?.dealDisplayId,
            showConfirmationForLinks,
            businessPartnerNamesById: tileData?.businessPartnerNamesById,
          }),
      },
      {
        Header: t('columns.commitment'),
        accessor: 'commitment',
        hAlign: TextAlign.Right,
        Cell: CommitmentCell,
      },
    ],
    [
      showConfirmationForLinks,
      t,
      tileData?.businessPartnerNamesById,
      tileData?.sourceRender?.dealDisplayId,
    ],
  )

  return (
    <AnalyticalTable
      className={styles.table}
      minRows={0}
      columns={columns}
      data={tileData?.tableData ?? []}
      filterable={false}
    />
  )
}

DrawdownDealStructureTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
}

export default DrawdownDealStructureTile
