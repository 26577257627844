import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-history/PropertyValuationResultsHistoryLineChartChildren.module.css'
import Card from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/card/Card'
import Labeled from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/data/Labeled'
import { valuationClassificationAbbreviationsTranslated } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/domains/property/valuation/valuationRequests'
import {
  useCustomizableCurrencyFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const EMPTY_VALUE = '-'

const PropertyValuationResultsHistoryLineChartTooltip = ({
  keyDate,
  currency,
  valuationValues = [],
}) => {
  const { t } = useTranslation('decisionPaper')
  const { format: formatDate } = useShortDateFormatter()
  const formatMoney = useCustomizableCurrencyFormatter()

  return (
    <Card>
      <FlexBox
        direction={FlexBoxDirection.Column}
        justifyContent={FlexBoxJustifyContent.Start}
        className={styles.tooltip}
      >
        <Title level={TitleLevel.H6} className={'sapTextColor sapFontBoldFamily'}>
          {keyDate ? formatDate(keyDate) : EMPTY_VALUE}
        </Title>
        <FlexBox direction={FlexBoxDirection.Column} className={styles.tooltipFlexWrapper}>
          {valuationValues?.map(([valuationTypeCode, value], index) => (
            <Labeled
              key={index}
              showColon
              label={t(valuationClassificationAbbreviationsTranslated?.[valuationTypeCode])}
            >
              {value ? formatMoney(value, currency) : EMPTY_VALUE}
            </Labeled>
          ))}
        </FlexBox>
      </FlexBox>
    </Card>
  )
}

PropertyValuationResultsHistoryLineChartTooltip.propTypes = {
  valuationValues: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ),
  keyDate: PropTypes.string,
  currency: PropTypes.string,
}

export default PropertyValuationResultsHistoryLineChartTooltip
