import { IllustratedMessage, IllustrationMessageSize } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { isNotFoundError } from 'api/requests'
import AssignableMarketsFilterBar from 'components/domains/markets/AssignableMarketsFilterBar'
import AssignableMarketsTable from 'components/domains/markets/AssignableMarketsTable'
import Dialog from 'components/ui/dialog/Dialog'
import ErrorScreen from 'components/ui/screens/ErrorScreen'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useCompatibleMarkets from 'hooks/services/markets/useCompatibleMarkets'
import useMarketTypes from 'hooks/services/markets/useMarketTypes'
import { useTypecodes } from 'hooks/services/properties/useTypecodes'

const AssignableMarketsPropertyCreationDialog = ({
  isOpen,
  setIsOpen,
  propertyTypeCode,
  countryCode,
  latitude,
  longitude,
  onChange,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.markets.overview.assignable-markets-dialog',
  })
  const initialValues = {
    searchFilter: '',
    marketTypes: [],
  }
  const [filterParams, setFilterParams] = useState(initialValues)

  // HINT: We don't care about the loading / error state of the typecodes here,
  //       as we're falling back on the propertyTypeCode if the typecodes are not available
  const { data: { typecodes: propertyTypeCodes = [] } = {} } = useTypecodes()
  const propertyTypeDisplayName =
    propertyTypeCodes?.find((typeCode) => typeCode.key === propertyTypeCode)?.display_name ||
    propertyTypeCode

  const {
    isLoading: isMarketTypesLoading,
    isError: isMarketTypesError,
    error: marketTypesError,
  } = useMarketTypes({
    propertyType: propertyTypeCode,
    includeDeprecated: false,
  })
  const propertyTypeHasNoMarketTypesConfigured =
    isMarketTypesError && isNotFoundError(marketTypesError)

  const {
    isError,
    isLoading,
    data: compatibleMarketsData,
    error,
    fetchNextPage,
  } = useCompatibleMarkets({
    searchFilter: filterParams.searchFilter,
    marketTypes: filterParams.marketTypes,
    propertyCountry: countryCode,
    propertyType: propertyTypeCode,
    propertyLat: latitude,
    propertyLng: longitude,
    propertyId: null,
  })

  const handleOnRowClicked = useCallback(
    (marketId, marketName) => {
      onChange({ marketId, marketName })
      setIsOpen(false)
    },
    [onChange, setIsOpen],
  )

  const renderCustomErrorScreen = useCallback(() => {
    if (isOpen && propertyTypeHasNoMarketTypesConfigured) {
      return (
        <IllustratedMessage
          name="NoData"
          size={IllustrationMessageSize.Spot}
          titleText={t('no-market-types-configured.title')}
          subtitleText={t('no-market-types-configured.description', {
            propertyType: propertyTypeDisplayName,
          })}
        />
      )
    }
    if (isOpen && !countryCode) {
      return (
        <IllustratedMessage
          name="NoData"
          titleText={t('no-country-code.title')}
          subtitleText={t('no-country-code.description')}
        />
      )
    }
    if (isOpen && error && isNotFoundError(error)) {
      return <IllustratedMessage name="NoData" titleText={t('empty')} />
    }

    return <ErrorScreen title={t('error.title')} description={t('error.description')} />
  }, [
    countryCode,
    error,
    isOpen,
    propertyTypeDisplayName,
    propertyTypeHasNoMarketTypesConfigured,
    t,
  ])

  const handleOnGo = useCallback((filters) => {
    setFilterParams(filters)
  }, [])

  const calculateNoDataText = useMemo(() => {
    if (filterParams.searchFilter !== '' || filterParams.marketTypes.length !== 0) {
      return t('no-filtered-data')
    }
    return t('no-data')
  }, [filterParams, t])

  const renderContent = useCallback(
    () => (
      <AssignableMarketsTable
        markets={compatibleMarketsData}
        onLoadMore={fetchNextPage}
        noDataText={calculateNoDataText}
        handleRowClick={handleOnRowClicked}
      />
    ),
    [calculateNoDataText, compatibleMarketsData, fetchNextPage, handleOnRowClicked],
  )

  const handleAfterClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  return (
    <Dialog open={isOpen} headerText={t('title')} onAfterClose={handleAfterClose}>
      <AssignableMarketsFilterBar
        filterParams={filterParams}
        onGo={handleOnGo}
        propertyType={propertyTypeCode}
        propertyTypeDisplayName={propertyTypeDisplayName}
        hasNoMarketTypes={propertyTypeHasNoMarketTypesConfigured}
      />
      <LoadingStateWrapper
        isError={isError || isMarketTypesError}
        isLoading={isLoading || isMarketTypesLoading}
        renderContent={renderContent}
        renderCustomErrorScreen={renderCustomErrorScreen}
      />
    </Dialog>
  )
}

AssignableMarketsPropertyCreationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  propertyTypeCode: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default AssignableMarketsPropertyCreationDialog
