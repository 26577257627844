import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useFinalResultOptions = () => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.forbearance-and-utp-questionare.options.final-result',
  })

  return useMemo(
    () => [
      {
        key: '-',
        value: '-',
        displayValue: t('empty-value'),
      },
      {
        key: 'forbearancePerforming',
        value: 'forbearancePerforming',
        displayValue: t('forbearance-performing'),
      },
      {
        key: 'forbearanceNonPerforming',
        value: 'forbearanceNonPerforming',
        displayValue: t('forbearance-non-performing'),
      },
      {
        key: 'noForbearance',
        value: 'noForbearance',
        displayValue: t('no-forbearance'),
      },
    ],
    [t],
  )
}

export default useFinalResultOptions
