import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateCashflowScenario = ({ onSuccess, onError }) => {
  const { patch } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, cashflowScenarioUuid, data }) => {
      await patch({
        path: `/deals/${dealUuid}/cashflow-scenarios/${cashflowScenarioUuid}`,
        body: snakecaseKeys(data, { deep: true }),
      })
    },
    { onSuccess, onError },
  )
}

export default useUpdateCashflowScenario
