import uniq from 'lodash.uniq'
import { useMemo } from 'react'
import { ARREARS_IN_APPROVAL_ENTITY_TYPES } from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/constants'
import { NO_DEAL } from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/shared/constants'
import { useMultipleDealsByUuidMini } from 'hooks/services/deals/useMultipleDealsByUuidMini'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'
import { useBusinessPartnerArrears } from 'routes/business-partners/arrears/useBusinessPartnerArrears'

const getProductHierarchyDeals = (products) => {
  if (!products || products.length === 0) {
    return []
  }
  const directDealIds = products
    .filter(
      ({ productId, productTypeCode }) =>
        productId !== NO_DEAL && productTypeCode === ARREARS_IN_APPROVAL_ENTITY_TYPES.DEAL,
    )
    .map(({ productId }) => productId)
  return [...directDealIds]
}

const useArrearsInformation = ({ entityRef: { entityId: businessPartnerId } } = {}) => {
  const {
    isLoading: isArrearsLoading,
    isError: isArrearsError,
    products,
    arrears,
    productHierarchy,
    arrearApprovals,
    arrearApprovalEvents,
    arrearClassifications,
  } = useBusinessPartnerArrears(businessPartnerId)

  const allDealUuids = uniq(getProductHierarchyDeals(productHierarchy))

  const isDealsEnabled = allDealUuids.length > 0
  const {
    data: allDealsData = [],
    isLoading: isDealsLoading,
    isError: isDealsError,
  } = useCombinedQueryResults(
    useMultipleDealsByUuidMini(allDealUuids, {
      enabled: isDealsEnabled,
    }),
    { forceDataReturn: true },
  )

  const isLoading = isArrearsLoading || (isDealsLoading && isDealsEnabled)
  const isError = isArrearsError || isDealsError

  return useMemo(
    () => ({
      isLoading,
      isError,
      data:
        !isLoading && !isError
          ? {
              sourceRender: { businessPartnerId },
              products,
              arrears,
              productHierarchy,
              arrearApprovals,
              arrearApprovalEvents,
              arrearClassifications,
              allDealsData,
            }
          : undefined,
    }),
    [
      allDealsData,
      arrearApprovalEvents,
      arrearApprovals,
      arrearClassifications,
      arrears,
      businessPartnerId,
      isError,
      isLoading,
      productHierarchy,
      products,
    ],
  )
}

export default useArrearsInformation
