import PropTypes from 'prop-types'
import styles from 'components/domains/business-partners/tile/general-information/keyInternalContact/KeyInternalContactEditItem.module.css'

export const KeyInternalContactEditItem = ({ children }) => (
  <div className={styles.form}>{children}</div>
)

KeyInternalContactEditItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}
