import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import WatcherButtonLoadingWrapper from 'components/ui/watcher/WatcherButtonLoadingWrapper'
import useAddRequirementWatcher from 'hooks/services/conditions/requirements/useAddRequirementWatcher'
import useDeleteRequirementWatcher from 'hooks/services/conditions/requirements/useDeleteRequirementWatcher'
import useAddConditionWatcher from 'hooks/services/conditions/useAddConditionWatcher'
import useDeleteConditionWatcher from 'hooks/services/conditions/useDeleteConditionWatcher'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

export const TableWatcherCellType = {
  internalCondition: 'internal',
  externalCondition: 'external',
  requirement: 'requirement',
}

const TableWatcherCell = ({ value: { conditionId, requirementId, watcherType, watchers } }) => {
  const {
    entityRef: { entityType, entityId },
  } = useContext(ConditionsContext)
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.columns.watcher',
  })

  const { mutate: addRequirementWatcherMutate } = useAddRequirementWatcher()
  const { mutate: addConditionWatcherMutate } = useAddConditionWatcher()
  const { mutate: removeConditionWatcherMutate } = useDeleteConditionWatcher()
  const { mutate: removeRequirementWatcherMutate } = useDeleteRequirementWatcher()
  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()

  const requirementOrConditionId = useMemo(
    () => (watcherType === TableWatcherCellType.requirement ? requirementId : conditionId),
    [conditionId, requirementId, watcherType],
  )

  const onError = useCallback(() => {
    showMessageBox({
      children: t('error.text'),
      type: MessageBoxTypes.Error,
    })
  }, [showMessageBox, t])

  const onSuccess = useCallback(
    (toastText, queriesToInvalidate) => {
      showToast({ children: toastText })
      queryClient.invalidateQueries(queriesToInvalidate)
    },
    [queryClient, showToast],
  )

  const removeWatcher = useCallback(
    (watcherId) => {
      if (watcherType === TableWatcherCellType.requirement) {
        removeRequirementWatcherMutate(
          { conditionId, requirementId, watcherId },
          {
            onSuccess: () => {
              onSuccess(t('unsubscribe.requirement.success'), [
                'conditions',
                'external',
                entityType,
                entityId,
                'requirements',
              ])
            },
            onError: onError,
          },
        )
        return
      }
      removeConditionWatcherMutate(
        { conditionType: watcherType, conditionId, watcherId },
        {
          onSuccess: () => {
            onSuccess(t('unsubscribe.condition.success'), [
              'conditions',
              watcherType,
              entityType,
              entityId,
            ])
          },
          onError: onError,
        },
      )
    },
    [
      conditionId,
      entityId,
      entityType,
      onError,
      onSuccess,
      removeConditionWatcherMutate,
      removeRequirementWatcherMutate,
      requirementId,
      t,
      watcherType,
    ],
  )

  const addWatcher = useCallback(
    (userId) => {
      if (watcherType === TableWatcherCellType.requirement) {
        addRequirementWatcherMutate(
          { conditionId, requirementId, userId },
          {
            onSuccess: () => {
              onSuccess(t('subscribe.requirement.success'), [
                'conditions',
                'external',
                entityType,
                entityId,
                'requirements',
              ])
            },
            onError: onError,
          },
        )
        return
      }
      addConditionWatcherMutate(
        { conditionType: watcherType, conditionId, userId },
        {
          onSuccess: () => {
            onSuccess(t('subscribe.condition.success'), [
              'conditions',
              watcherType,
              entityType,
              entityId,
            ])
          },
          onError: onError,
        },
      )
    },
    [
      addConditionWatcherMutate,
      addRequirementWatcherMutate,
      conditionId,
      entityId,
      entityType,
      onError,
      onSuccess,
      requirementId,
      t,
      watcherType,
    ],
  )

  return (
    <WatcherButtonLoadingWrapper
      watchers={watchers}
      id={`watcher-button-${requirementOrConditionId}`}
      onUserSubscribe={addWatcher}
      onUserUnsubscribe={removeWatcher}
    />
  )
}

TableWatcherCell.propTypes = {
  value: PropTypes.shape({
    watchers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        userId: PropTypes.string.isRequired,
      }),
    ).isRequired,
    conditionId: PropTypes.string.isRequired,
    requirementId: PropTypes.string,
    watcherType: PropTypes.oneOf(Object.values(TableWatcherCellType)),
  }).isRequired,
}

export default TableWatcherCell
