import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import TenancyOverviewTileTableWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/TenancyOverviewTileTableWrapper'

export const timePeriodOptions = ['yearly', 'monthly']

/**
 * V1 and V2 of TenancyOverviewTile are the same,
 * but the subcomponent TenancyOverviewTable has different V1, V2 implementations
 */
const TenancyOverviewTileV1V2 = ({ tileId, isPdfView }) => {
  const {
    data: { tenancyOverview },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const [selectedTimePeriod, setSelectedTimePeriod] = useState(timePeriodOptions[0])

  const onTimePeriodChange = useCallback((event) => {
    const nextTimePeriod = event?.detail?.selectedOption?.dataset?.id
    if (nextTimePeriod) {
      setSelectedTimePeriod(nextTimePeriod)
    }
  }, [])

  return !isPdfView ? (
    <TenancyOverviewTileTableWrapper
      tileId={tileId}
      tenancyOverview={tenancyOverview}
      timePeriod={selectedTimePeriod}
      onTimePeriodChange={onTimePeriodChange}
      isPdfView={isPdfView}
    />
  ) : (
    timePeriodOptions.map((option) => (
      <TenancyOverviewTileTableWrapper
        tileId={tileId}
        key={`tenancy-overview-tile-pdf-view-${option}`}
        tenancyOverview={tenancyOverview}
        timePeriod={option}
        onTimePeriodChange={() => {}}
        isPdfView={isPdfView}
      />
    ))
  )
}

TenancyOverviewTileV1V2.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default TenancyOverviewTileV1V2
