import { isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const REQUEST_RETRIES = 3

const useDealsWithTranchesAndDrawdownsByBorrower = (borrowerId) =>
  useCamelizedResponse(
    useRequest({
      path: `/financing-structures/business-partners/${borrowerId}/deals`,
      translated: true,
      keys: ['businesspartners', borrowerId, 'deals'],
      options: {
        retry: (failureCount, error) => {
          if (isNotFoundError(error)) {
            return false
          }
          return failureCount < REQUEST_RETRIES
        },
      },
    }),
  )

export default useDealsWithTranchesAndDrawdownsByBorrower
