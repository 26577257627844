import { TABLE_ROW_HEIGHT } from 'components/domains/business-partners/tile/risk-monitoring/forbearance/constants'
import resolveLifecycleStatusCodeToValueState from 'components/domains/business-partners/tile/risk-monitoring/forbearance/helper/resolveLifecycleStatusCodeToValueState'
import paths from 'routes/paths'

/**
 * @param {object} params
 * @param {import('hooks/services/risk-monitoring/forbearance/measures/forbearanceMeasuresSchema').ForbearanceMeasureVersion} params.measureVersion
 * @param {string} params.measureShortText
 * @param {string} params.forbearanceMeasureId
 * @param {object} staffMembers
 */
const mapMeasureVersion = (
  { measureVersion, measureShortText, forbearanceMeasureId },
  staffMembers,
) => ({
  forbearanceMeasureId,
  forbearanceMeasureVersionId: measureVersion.id,
  forbearanceMeasureVersion: `${measureShortText} ${measureVersion.version}`,
  status: {
    statusValue: measureVersion.lifecycleStatusTypeShortText,
    statusCode: measureVersion.lifecycleStatusTypeCode,
    statusState: resolveLifecycleStatusCodeToValueState(measureVersion.lifecycleStatusTypeCode),
  },
  customerSigningDate: measureVersion.customerSigningDate,
  startDate: measureVersion.startDate,
  endDate: measureVersion.endDate,
  createdOn: measureVersion?.createdOn?.createdOn,
  createdBy: staffMembers?.[measureVersion?.createdOn?.createdBy]?.fullName,
  comment: measureVersion.comment,
  ...(measureVersion?.currentBusinessEvent && {
    currentBusinessEvent: {
      id: measureVersion?.currentBusinessEvent?.displayId,
      name: measureVersion?.currentBusinessEvent?.name,
      link: `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${measureVersion?.currentBusinessEvent?.id}`,
    },
  }),
  rowHeight: TABLE_ROW_HEIGHT,
})

export default mapMeasureVersion
