import { Select, Option, ValueState, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import { useFormMapped } from 'hooks/form/useFormMapped'
import { useFormValidations } from 'hooks/form/useFormValidations'
import { useCurrencyCodes } from 'hooks/services/properties/useCurrencyCodes'

const CurrencySelect = ({ disabled = false, registerName, ...selectProps }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.covenants' })
  const { control, watch } = useFormMapped()
  const { required } = useFormValidations()

  const { isLoading, isError, data: { currency_codes = [] } = {} } = useCurrencyCodes()

  const selectedValue = watch(registerName)
  const isSelected = (keyToCheck) => {
    if (selectedValue) {
      return selectedValue === keyToCheck
    } else {
      return keyToCheck
    }
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      error={t('data-error')}
      renderContent={() => (
        <Controller
          control={control}
          name={registerName}
          rules={{
            validate: { required: required() },
          }}
          render={({
            field: { onChange, onBlur, name, value },
            fieldState: { invalid, error },
          }) => (
            <Select
              {...selectProps}
              id="currency-select"
              disabled={disabled}
              name={name}
              onChange={async (event) => {
                await onChange(event.detail.selectedOption.value)
                await onBlur()
              }}
              value={value}
              valueState={invalid ? ValueState.Error : ValueState.None}
              valueStateMessage={<Text wrapping>{error?.message}</Text>}
              onBlur={onBlur}
            >
              <Option key={'empty'} value={''} selected={isSelected('')}>
                {''}
              </Option>
              {currency_codes.map(({ key, display_name }) => (
                <Option key={key} value={key} selected={isSelected(key)}>
                  {display_name}
                </Option>
              ))}
            </Select>
          )}
        />
      )}
    />
  )
}

CurrencySelect.propTypes = {
  registerName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

export default CurrencySelect
