import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/covenants/monitoring-items-card-deal/MonitoringItemsForDealTable.module.css'
import BreachCell from 'components/domains/deals/covenants/shared/BreachCell'
import LimitCell from 'components/domains/deals/covenants/shared/LimitCell'
import StatusCell from 'components/domains/deals/covenants/shared/StatusCell'
import Entity from 'components/ui/data/Entity'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

/**
 * This file contains more than one React component.
 * Although this is not best practice, creating a file for each cell is much more inefficient and adds unnecessary complexity in this case.
 */

const entityPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  link: PropTypes.string,
  openInNewTab: PropTypes.bool,
})

const limitPropTypes = PropTypes.shape({
  value: PropTypes.number,
  unit: PropTypes.string,
  currencyCode: PropTypes.string,
})

const dataPropTypes = PropTypes.shape({
  monitoringItem: entityPropTypes,
  covenantCheck: entityPropTypes,
  status: PropTypes.shape({ value: PropTypes.string }),
  covenant: entityPropTypes,
  targetDeliveryDate: PropTypes.string,
  softLimit: limitPropTypes,
  hardLimit: limitPropTypes,
  result: limitPropTypes,
  breach: PropTypes.shape({
    value: PropTypes.string,
    resultOfBreach: PropTypes.string,
  }),
})

const rowPropTypes = {
  data: PropTypes.arrayOf(dataPropTypes),
  row: PropTypes.shape({
    groupByID: PropTypes.string,
    // groupByVal is related to the grouped column and therefore flexible
    // eslint-disable-next-line react/forbid-prop-types
    groupByVal: PropTypes.any,
    original: dataPropTypes,
  }),
}

export const MonitoringItemCell = ({ row }) => <EntityCell value={row.original?.monitoringItem} />
MonitoringItemCell.propTypes = rowPropTypes
export const CovenantCheckCell = ({ row, data }) => {
  const value =
    row.groupByID === 'covenant-check'
      ? data.find((dataRow) => dataRow.covenantCheck.nameWithId === row.groupByVal)?.covenantCheck
      : row.original?.covenantCheck
  return <EntityCell value={value} />
}
CovenantCheckCell.propTypes = rowPropTypes
export const CovenantCell = ({ row, data }) => {
  const value =
    row.groupByID === 'covenant'
      ? data.find((dataRow) => dataRow.covenant.nameWithId === row.groupByVal)?.covenant
      : row.original?.covenant
  return <EntityCell value={value} />
}
CovenantCell.propTypes = rowPropTypes

const EntityCell = ({ value } = {}) =>
  value?.id ? (
    <Entity
      name={value?.name}
      link={value?.link}
      openInNewTab={value?.openInNewTab}
      className={styles.entityCell}
      isLinkBold={false}
    />
  ) : (
    <></>
  )

EntityCell.propTypes = {
  value: entityPropTypes,
}

export const MonitoringItemsStatusCell = ({ row }) =>
  row.groupByID === 'status' ? (
    <StatusCell status={row.groupByVal} />
  ) : (
    <>{row.original?.status?.value && <StatusCell status={row.original?.status?.value} />}</>
  )
MonitoringItemsStatusCell.propTypes = rowPropTypes

export const TargetDeliveryDateCell = ({ row }) => {
  const { format: formatDate } = useShortDateFormatter()
  const date =
    row.groupByID === 'target-delivery-date' ? row.groupByVal : row.original?.targetDeliveryDate
  return <span className={styles.rightAlignedCell}>{formatDate(date)}</span>
}
TargetDeliveryDateCell.propTypes = rowPropTypes

export const SoftLimitCell = ({ row }) => (
  <MonitoringItemsLimitCell value={row.original?.softLimit} />
)
SoftLimitCell.propTypes = rowPropTypes

export const HardLimitCell = ({ row }) => (
  <MonitoringItemsLimitCell value={row.original?.hardLimit} />
)
HardLimitCell.propTypes = rowPropTypes

export const ResultCell = ({ row }) => <MonitoringItemsLimitCell value={row.original?.result} />
ResultCell.propTypes = rowPropTypes

const MonitoringItemsLimitCell = ({ value } = {}) =>
  value?.value && (
    <div className={styles.rightAlignedCell}>
      <LimitCell unit={value?.unit} currencyCode={value?.currencyCode} value={value?.value} />
    </div>
  )
MonitoringItemsLimitCell.propTypes = {
  value: limitPropTypes,
}

export const MonitoringItemsBreachCell = ({ row }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.results.monitoring-items-card.table',
  })
  const breach = row.groupByID === 'breach' ? row.groupByVal : row.original?.breach.value
  return (
    breach && (
      <BreachCell
        breach={breach}
        popoverContent={
          <Text className={styles.errorText}>
            {t('result-of-breach', {
              result: row.original?.breach?.resultOfBreach,
            })}
          </Text>
        }
      />
    )
  )
}
MonitoringItemsBreachCell.propTypes = rowPropTypes
