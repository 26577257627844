import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Card from 'components/ui/card/Card'
import CardHeaderWithMetrics from 'components/ui/card/CardHeaderWithMetrics'
import {
  useAreaMeasurementUnitFormatter,
  useNumberFormatter,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'
import usePropertyRentRollComparisonKpis from 'hooks/services/properties/kpis/comparison/usePropertyRentRollComparisonKpis'
import usePropertyRentRollComparisonMetric from 'hooks/services/properties/kpis/comparison/usePropertyRentRollComparisonMetric'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyRentRollComparisonCurrentRentPerUomCard = () => {
  const { t: tComparison } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.comparison',
  })

  const { property } = useContext(PropertyContext)

  const { format: formatDate } = useShortDateFormatter()

  const {
    isLoading,
    isError,
    currentRentRollKpis,
    comparisonRentRollKpis,
    currentKeyDate,
    comparisonKeyDate,
  } = usePropertyRentRollComparisonKpis(property)

  const formatNumber = useNumberFormatter({
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  const formatUOM = useAreaMeasurementUnitFormatter()

  const findAreaOfMeasurement = (kpis) =>
    kpis?.totalAreaSurface?.measurementUnit ?? property.areaMeasureUnitCode

  const getFormattedUOM = (kpis) => {
    const uomCode = findAreaOfMeasurement(kpis)
    return uomCode ? formatUOM(uomCode) : null
  }

  const findTotalCurrentRentPerUom = (kpis) => kpis?.annualizedCurrentRentPerUom

  const findTotalCurrentRentPerUomNumber = (kpis) => {
    const totalCurrentRentPerUom = findTotalCurrentRentPerUom(kpis)
    return totalCurrentRentPerUom?.number ?? null
  }

  const findTotalCurrentRentPerUomCurrency = (kpis) => {
    const totalCurrentRentPerUom = findTotalCurrentRentPerUom(kpis)
    return findTotalCurrentRentPerUomNumber(kpis) ? totalCurrentRentPerUom.currency : null
  }

  const formatTotalCurrentRentPerUom = (kpis) => {
    const totalCurrentRentPerUomNumber = findTotalCurrentRentPerUomNumber(kpis)
    return totalCurrentRentPerUomNumber ? formatNumber(totalCurrentRentPerUomNumber) : null
  }

  const formatSecondaryMetricValue = (value, unit) => (value && unit ? `${value} ${unit}` : null)

  const primaryMetric = {
    label: formatDate(currentKeyDate),
    value: formatTotalCurrentRentPerUom(currentRentRollKpis),
    unit: findTotalCurrentRentPerUomCurrency(currentRentRollKpis),
  }

  const secondaryMetric = {
    label: formatDate(comparisonKeyDate),
    value: formatSecondaryMetricValue(
      formatTotalCurrentRentPerUom(comparisonRentRollKpis),
      findTotalCurrentRentPerUomCurrency(comparisonRentRollKpis),
    ),
  }

  const comparisonMetric = usePropertyRentRollComparisonMetric({
    isLoading,
    isError,
    formatter: formatNumber,
    primaryValue: findTotalCurrentRentPerUomNumber(currentRentRollKpis),
    comparisonValue: findTotalCurrentRentPerUomNumber(comparisonRentRollKpis),
    unit:
      findTotalCurrentRentPerUomCurrency(currentRentRollKpis) ??
      findTotalCurrentRentPerUomCurrency(comparisonRentRollKpis),
  })

  const isInComparison = comparisonKeyDate && comparisonKeyDate !== currentKeyDate

  const secondaryMetrics = isInComparison ? [secondaryMetric, comparisonMetric] : [secondaryMetric]

  return (
    <Card>
      <CardHeaderWithMetrics
        title={tComparison('annualized-current-rent-per-uom', {
          uom: getFormattedUOM(currentRentRollKpis),
        })}
        primaryMetric={primaryMetric}
        secondaryMetrics={secondaryMetrics}
        showReferenceDate={false}
        isLoading={isLoading}
        isError={isError}
      />
    </Card>
  )
}
export default PropertyRentRollComparisonCurrentRentPerUomCard
