import isEmpty from 'lodash.isempty'

const externalAssigneeChanged = (state, { payload: { externalAssignee: newExternalAssignee } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }
  state.editedRow.currentValues.externalAssignee = newExternalAssignee
  if (newExternalAssignee === state.editedRow.initialValues.externalAssignee) {
    delete state.editedRow.changedFields.externalAssignee
    return
  }
  state.editedRow.changedFields.externalAssignee = true
}

export default externalAssigneeChanged
