import { useMemo } from 'react'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import { useOriginationTeams } from 'hooks/services/deals/configurations/useOriginationTeams'
import useDeal from 'hooks/services/deals/useDeal'

const useOpsMemoDealTeamInformation = ({ entityRef: { entityId: dealUuid } }, _, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    data: dealData,
    isFetching: isFetchingDeal,
    isError: isErrorDeal,
  } = useDeal(dealUuidByTileCode)

  const dealDisplayId = useMemo(
    () => (!isFetchingDeal && !isErrorDeal ? dealData?.dealId : undefined),
    [dealData?.dealId, isErrorDeal, isFetchingDeal],
  )

  const { originationTeamCode, businessSegmentCode } = dealData || {}

  const {
    isFetching: isFetchingOriginationTeams,
    isError: isErrorOriginationTeams,
    data: dataOriginationTeamsData = [],
  } = useOriginationTeams(businessSegmentCode, {
    enabled: !!originationTeamCode && !!businessSegmentCode,
  })

  return useMemo(() => {
    const originationTeam = dataOriginationTeamsData.find(
      ({ code }) => code === originationTeamCode,
    )

    const isLoading = isDealUuidFetching || isFetchingDeal || isFetchingOriginationTeams
    const isError = isDealUuidError || isErrorDeal || isErrorOriginationTeams

    if (isLoading || isError) {
      return { isLoading, isError, data: undefined }
    }
    const sourcePathSuffix = isWorkingVersionDataTile ? '?working-version=true' : ''

    const data = {
      dealInformationData: originationTeam || {},
      sourceRender: { dealDisplayId, sourcePathSuffix },
    }
    return {
      isLoading: false,
      isError: false,
      data,
    }
  }, [
    dataOriginationTeamsData,
    dealDisplayId,
    isDealUuidError,
    isDealUuidFetching,
    isErrorDeal,
    isErrorOriginationTeams,
    isFetchingDeal,
    isFetchingOriginationTeams,
    isWorkingVersionDataTile,
    originationTeamCode,
  ])
}

export default useOpsMemoDealTeamInformation
