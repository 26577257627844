import { CheckBox, Input } from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CardEditItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import BreachSelect from 'components/domains/deals/covenant-check-detail/checked-covenants/BreachSelect'
import { useBreachTranslation } from 'components/domains/deals/covenants/shared/useBreachTranslations'
import useStatusTranslations from 'components/domains/deals/covenants/shared/useStatusTranslations'
import DatePickerWithoutMinWidth from 'components/ui/date-picker/DatePickerWithoutMinWidth'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const PeriodicalCheckGeneralInformationEdit = ({
  periodicalCheck,
  monitoringItem,
  formValues,
  hasDocumentRequirements,
  showBreach,
  onChange,
  disabledInputs,
  invalidInputs,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.periodical-checks.general-information.label',
  })
  const { localePattern } = useShortDateFormatter()
  const { getTranslationForStatus } = useStatusTranslations()
  const { getTranslationForBreach } = useBreachTranslation()

  return (
    <CardSection>
      <CardEditItem
        label={t('periodical-check-name')}
        value={<Input readonly value={periodicalCheck?.name} />}
      />
      <CardEditItem
        label={t('periodical-check-type')}
        value={<Input readonly value={monitoringItem?.covenant?.name} />}
      />
      <CardEditItem
        label={t('status')}
        value={<Input readonly value={getTranslationForStatus(monitoringItem?.status)} />}
      />
      <CardEditItem
        label={t('check-due-date')}
        value={
          <DatePickerWithoutMinWidth
            readonly
            formatPattern={localePattern}
            value={monitoringItem?.dueDate?.checkDate}
          />
        }
      />
      {hasDocumentRequirements && (
        <CardEditItem
          label={t('target-delivery-date')}
          value={
            <DatePickerWithoutMinWidth
              readonly
              formatPattern={localePattern}
              value={monitoringItem?.dueDate?.targetDeliveryDate}
            />
          }
        />
      )}
      {hasDocumentRequirements && (
        <CardEditItem
          label={t('actual-delivery-date')}
          value={
            <DatePickerWithoutMinWidth
              formatPattern={localePattern}
              value={formValues?.actualDeliveryDate}
              readonly={disabledInputs?.actualDeliveryDate}
              onChange={(event) =>
                onChange({ actualDeliveryDate: event.target.value }, event.detail.valid)
              }
              valueState={
                invalidInputs.includes('actualDeliveryDate') ? ValueState.Error : ValueState.None
              }
            />
          }
        />
      )}
      {hasDocumentRequirements && (
        <CardEditItem
          label={t('new-target-delivery-date')}
          value={
            <DatePickerWithoutMinWidth
              formatPattern={localePattern}
              value={formValues?.newTargetDeliveryDate}
              readonly={disabledInputs?.newTargetDeliveryDate}
              onChange={(event) =>
                onChange({ newTargetDeliveryDate: event.target.value }, event.detail.valid)
              }
              valueState={
                invalidInputs.includes('newTargetDeliveryDate') ? ValueState.Error : ValueState.None
              }
            />
          }
        />
      )}
      {!hasDocumentRequirements && (
        <CardEditItem
          label={t('new-check-due-date')}
          value={
            <DatePickerWithoutMinWidth
              formatPattern={localePattern}
              value={formValues?.newCheckDueDate}
              readonly={disabledInputs?.newCheckDueDate}
              onChange={(event) => {
                onChange({ newCheckDueDate: event.target.value }, event.detail.valid)
              }}
              valueState={
                invalidInputs.includes('newCheckDueDate') ? ValueState.Error : ValueState.None
              }
            />
          }
        />
      )}
      {showBreach && (
        <CardEditItem
          label={t('breach')}
          value={
            formValues?.breach === 'POSTPONED' ? (
              <Input
                readonly={!!disabledInputs?.breach}
                value={getTranslationForBreach(formValues?.breach)}
              />
            ) : (
              <BreachSelect
                disabled={!!disabledInputs?.breach}
                onChange={(breach) => onChange({ breach }, true)}
                value={formValues?.breach}
              />
            )
          }
        />
      )}
      <CardEditItem
        label={t('waived')}
        value={
          <>
            <CheckBox
              disabled={disabledInputs?.waived}
              onChange={() => onChange({ waived: !formValues?.waived }, true)}
              checked={!!formValues?.waived}
            />
          </>
        }
      />
    </CardSection>
  )
}

const formValuesPropTypes = PropTypes.shape({
  actualDeliveryDate: PropTypes.string,
  newTargetDeliveryDate: PropTypes.string,
  newCheckDueDate: PropTypes.string,
  breach: PropTypes.string,
  waived: PropTypes.bool,
})

PeriodicalCheckGeneralInformationEdit.propTypes = {
  periodicalCheck: PropTypes.shape({ name: PropTypes.string }),
  monitoringItem: PropTypes.shape({
    covenant: PropTypes.shape({
      name: PropTypes.string,
    }),
    dueDate: PropTypes.shape({
      checkDate: PropTypes.string,
      targetDeliveryDate: PropTypes.string,
    }),
    status: PropTypes.string,
    resultOfBreach: PropTypes.shape({ name: PropTypes.string }),
  }),
  formValues: formValuesPropTypes,
  hasDocumentRequirements: PropTypes.bool,
  showBreach: PropTypes.bool,
  onChange: PropTypes.func,
  disabledInputs: PropTypes.shape({
    actualDeliveryDate: PropTypes.bool,
    newTargetDeliveryDate: PropTypes.bool,
    newCheckDueDate: PropTypes.bool,
    breach: PropTypes.bool,
    waived: PropTypes.bool,
  }),
  invalidInputs: PropTypes.arrayOf(PropTypes.string),
}

export default PeriodicalCheckGeneralInformationEdit
