import {
  FlexBox,
  FlexBoxDirection,
  Input,
  Label,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const SegmentDescriptionInput = ({ description, setDescription }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.rent-roll-working-version.segment-creation',
  })

  const handleOnInput = (e) => {
    const description = e.target.value
    setDescription(description)
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label>{t('description')}</Label>
      <Input
        id="segment-description-input"
        valueState={ValueState.None}
        value={description}
        onInput={handleOnInput}
        type="Text"
      />
    </FlexBox>
  )
}
SegmentDescriptionInput.propTypes = {
  description: PropTypes.string,
  setDescription: PropTypes.func.isRequired,
}
export default SegmentDescriptionInput
