import { Modals, ObjectStatus } from '@fioneer/ui5-webcomponents-react'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import determineValueStateBasedOnAssessmentCategory from 'components/domains/business-partners/tile/assessments/determineValueStateBasedOnAssessmentCategory'
import { Assessment } from 'components/domains/business-partners/tile/general-information/allowedOperations'
import DisplayAndEditCard from 'components/ui/card/DisplayAndEditCard'
import EditCardItem from 'components/ui/card/EditCardItem'
import LoadingDropdown from 'components/ui/dropdown/LoadingDropdown'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { useAssessmentLevels } from 'hooks/services/business-partners/assessments/useAssessmentLevels'
import useAssessmentUpdate from 'hooks/services/business-partners/assessments/useAssessmentUpdate'
import { useAssessments } from 'hooks/services/business-partners/assessments/useAssessments'
import useStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useStaffMemberByObjectIdOrEmail'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

const OverallAssessmentTile = () => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.assessments.overall-assessment',
  })
  const showToast = Modals.useShowToast()

  const [newLevel, setNewLevel] = useState()
  const [saveHookIsSuccess, setSaveHookIsSuccess] = useState(false)
  const [saveHookIsError, setSaveHookIsError] = useState(false)
  const { id: businessPartnerId } = useContext(BusinessPartnerContext)
  const mutation = useAssessmentUpdate({
    businessPartnerId,
    onError: () => {
      setSaveHookIsError(true)
    },
    onSuccess: () => {
      showToast({ children: tNoPrefix('toast.changes-saved') })
      setSaveHookIsSuccess(true)
    },
  })
  const { format: formatDateAndTime } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
  })
  const { isLoading, isError, data: assessmentData } = useAssessments(businessPartnerId)
  const assessment = assessmentData?.assessments[0]
  const allowedOperations = assessmentData?.allowedOperations ?? []

  const hasEditPermission = allowedOperations.includes(Assessment.write)

  const {
    data: staffMember,
    isInitialLoading: isInitialLoadingChangedBy,
    isError: isErrorChangedBy,
  } = useStaffMemberByObjectIdOrEmail({ objectIdOrEmail: assessment?.changedBy })
  const changedBy = staffMember?.fullName

  const fieldDefinitions = [
    {
      name: 'overallAssessment',
      label: t('title'),
      isShownInDisplay: true,
      isShownInEdit: true,
      value: assessment ? (
        <ObjectStatus
          inverted
          state={determineValueStateBasedOnAssessmentCategory(assessment?.category)}
        >
          {assessment.levelDisplayValue}
        </ObjectStatus>
      ) : (
        ''
      ),
      renderCustomEditComponent: ({ handleOnChange }) => (
        <div className="sapUiTinyMarginTop">
          <EditCardItem
            label={t('title')}
            isMandatory={true}
            editComponent={
              <LoadingDropdown
                id="business-partner-assessment"
                value={String(assessment?.level ?? '')}
                onChange={(selectedLevel) => {
                  const isFocussed = false
                  const isMandatory = true
                  handleOnChange('assessment', selectedLevel, isFocussed, isMandatory)
                  setNewLevel(selectedLevel)
                }}
                useLoader={useAssessmentLevels}
                initialDropdownValue=""
              />
            }
          />
        </div>
      ),
    },
  ]

  const handleSave = () => {
    setSaveHookIsError(false)
    setSaveHookIsSuccess(false)
    mutation.mutate(Number(newLevel))
  }

  return (
    <DisplayAndEditCard
      isLoading={isLoading || isInitialLoadingChangedBy}
      isError={isError || isErrorChangedBy}
      cardHeaderTitle={t('title')}
      cardHeaderSubtitle={
        assessment?.changedAt && changedBy
          ? t('sub-title', {
              date: formatDateAndTime(assessment.changedAt),
              name: changedBy,
            })
          : ''
      }
      fieldDefinitions={fieldDefinitions}
      saveChanges={handleSave}
      saveHookIsError={saveHookIsError}
      saveHookIsSuccess={saveHookIsSuccess}
      saveHookErrorSummary={undefined}
      isEditable={hasEditPermission}
      isEmpty={assessment?.level === undefined}
    />
  )
}

export default OverallAssessmentTile
