import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const useDealErrorCodeTranslation = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.dialog.message.warning' })

  const getTextByCode = useCallback(
    (code, subCode) => {
      const combinedCode = [code, subCode].filter(Boolean).join('__')
      return {
        /**
         * Deal Creation
         */
        DL_SPONSOR_BORROWER_LINKING_ERROR: t('link-sponsor-borrower'),
        DL_CREATE_TRANCHE_ERROR: t('create-tranche'),
        DL_CREATE_PROPERTY_ERROR: t('create-property'),
        DL_DUPLICATE_DEAL_TEAM_RELATION_ERROR: t('duplicate-deal-team-relation'),
        DL_MISSING_ALLOWED_OPERATIONS_FOR_LINKING_SPONSOR: t('missing-allowed-operations'),
        DL_CONFLICT_CLEARANCE_MESSAGE_ERROR: t('conflict-clearance'),

        /**
         * Deal Adjustment - CREATE
         */
        DL_ADJ_CREATE_DEAL_SERVICE_ERROR: t('deal-adjustment.create.deal-service-error'),
        DL_ADJ_CREATE_EXTERNAL_SERVICE_ERROR__DL_ADJ_FINANCE_SERVICE_ERROR: t(
          'deal-adjustment.create.finance-structure-service-error',
        ),
        DL_ADJ_CREATE_EXTERNAL_SERVICE_ERROR__DL_ADJ_COVENANTS_SERVICE_ERROR: t(
          'deal-adjustment.create.covenant-service-error',
        ),

        /**
         * Deal Adjustment - DELETE
         */
        DL_ADJ_DELETE_DEAL_SERVICE_ERROR: t('deal-adjustment.delete.deal-service-error'),
        DL_ADJ_DELETE_EXTERNAL_SERVICE_ERROR__DL_ADJ_FINANCE_SERVICE_ERROR: t(
          'deal-adjustment.delete.finance-structure-service-error',
        ),
        DL_ADJ_DELETE_EXTERNAL_SERVICE_ERROR__DL_ADJ_COVENANTS_SERVICE_ERROR: t(
          'deal-adjustment.delete.covenant-service-error',
        ),
        DL_ADJ_DELETE_EXTERNAL_SERVICE_ERROR__DL_ADJ_EVENTS_SERVICE_ERROR: t(
          'deal-adjustment.delete.event-service-error',
        ),

        /**
         * Deal Adjustment - PUBLISH
         */
        DL_ADJ_PUBLISH_DEAL_SERVICE_ERROR: t('deal-adjustment.publish.deal-service-error'),
        DL_ADJ_PUBLISH_EXTERNAL_SERVICE_ERROR__DL_ADJ_FINANCE_SERVICE_ERROR: t(
          'deal-adjustment.publish.finance-structure-service-error',
        ),
        DL_ADJ_PUBLISH_EXTERNAL_SERVICE_ERROR__DL_ADJ_COVENANTS_SERVICE_ERROR: t(
          'deal-adjustment.publish.covenant-service-error',
        ),
        DL_ADJ_PUBLISH_EXTERNAL_SERVICE_ERROR__DL_ADJ_EXTERNAL_CONDITIONS_SERVICE_ERROR: t(
          'deal-adjustment.publish.external.condition-service-error',
        ),
        DL_ADJ_PUBLISH_EXTERNAL_SERVICE_ERROR__DL_ADJ_INTERNAL_CONDITIONS_SERVICE_ERROR: t(
          'deal-adjustment.publish.internal.condition-service-error',
        ),
        DL_ADJ_PUBLISH_EXTERNAL_SERVICE_ERROR__DL_ADJ_EVENTS_SERVICE_ERROR: t(
          'deal-adjustment.publish.event-service-error',
        ),
      }[combinedCode]
    },
    [t],
  )

  return getTextByCode
}

export default useDealErrorCodeTranslation
