import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import useRoleConfig, { RoleConfig } from 'hooks/services/deals/configurations/useRoleConfig'

export const useIsBorrower = (businessPartnerId, options) => {
  const {
    data: { roles = [] } = {},
    isLoading: isLoadingBusinessPartnerRoles,
    isError: isErrorBusinessPartnerRoles,
  } = useBusinessPartnerById(businessPartnerId)
  const {
    data: borrowerRolesConfig = [],
    isLoading: isLoadingBorrowerRolesConfiguration,
    isError: isErrorBorrowerRolesConfiguration,
  } = useRoleConfig(RoleConfig.BORROWER_GROUP, options)
  const isLoading = isLoadingBusinessPartnerRoles || isLoadingBorrowerRolesConfiguration
  const isError = isErrorBusinessPartnerRoles || isErrorBorrowerRolesConfiguration

  const technicalBusinessPartnerRoles = roles.flatMap((role) => role.backEndRoles)
  const isBorrower = technicalBusinessPartnerRoles.some(({ id }) =>
    borrowerRolesConfig.includes(id),
  )

  return { isLoading, isError, data: isBorrower }
}
