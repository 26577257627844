import {
  CheckBox,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Icon,
  IconDesign,
  StandardListItem,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import ToolbarColumnSelectDialog from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/ui/tables/toolbar/ToolbarColumnSelectDialog'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/ui/tables/toolbar/ToolbarColumnSelection.module.css'
import {
  mergeItemsWithFixedPositionItems,
  moveItemDown,
  moveItemUp,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/ui/tables/toolbar/arrayHelpers'
import { columnSelectionPropTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/ui/tables/toolbar/propTypes'

const isNotSelectableForHiding = ({ isSelectableForHiding }) => !isSelectableForHiding

const ToolbarColumnSelection = ({ columnSelection = [], setColumnSelection, disabled }) => {
  const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false)
  const [fieldNameSearchText, setFieldNameSearchText] = useState('')
  const [focusedItem, setFocusedItem] = useState(null)
  const [sortableItems, setSortableItems] = useState(
    columnSelection.filter(({ isSelectableForHiding }) => isSelectableForHiding),
  )
  const fixedItems = useMemo(
    () =>
      columnSelection.filter(isNotSelectableForHiding).map((item) => ({
        ...item,
        fixedPosition: columnSelection.findIndex(({ columnKey }) => columnKey === item.columnKey),
      })),
    [columnSelection],
  )

  const columnSelectionHandler = useCallback(
    (columnKey) => () => {
      setFocusedItem(columnKey)
    },
    [],
  )

  const toggleCheckBoxHandler = useCallback(
    (columnKey) => () => {
      setSortableItems(
        sortableItems.map((item) => ({
          ...item,
          isVisible: columnKey === item.columnKey ? !item.isVisible : item.isVisible,
        })),
      )
    },
    [sortableItems],
  )

  const columns = useMemo(
    () =>
      sortableItems
        .filter(({ title }) => {
          const columnInFieldNameSearch =
            title && title.toLowerCase().includes(fieldNameSearchText.toLowerCase())

          return !fieldNameSearchText || columnInFieldNameSearch
        })
        .map(({ columnKey, title, isVisible }) => (
          <StandardListItem
            key={columnKey}
            data-id={columnKey}
            selected={focusedItem === columnKey}
            onClick={columnSelectionHandler(columnKey)}
          >
            <FlexBox
              direction={FlexBoxDirection.Row}
              justifyContent={FlexBoxJustifyContent.Start}
              alignItems={FlexBoxAlignItems.Center}
            >
              <CheckBox checked={isVisible} onClick={toggleCheckBoxHandler(columnKey)} />
              <Text>{title}</Text>
            </FlexBox>
          </StandardListItem>
        )),
    [
      sortableItems,
      fieldNameSearchText,
      focusedItem,
      columnSelectionHandler,
      toggleCheckBoxHandler,
    ],
  )

  const onConfirmColumnSelection = useCallback(() => {
    const newColumnSelection = mergeItemsWithFixedPositionItems({
      items: sortableItems,
      fixedPositionItems: fixedItems,
    })
    setColumnSelection(newColumnSelection.filter(({ isVisible }) => !!isVisible))
  }, [fixedItems, setColumnSelection, sortableItems])

  const onIconClicked = useCallback(() => setIsSelectDialogOpen(true), [])

  const onSearchReset = useCallback(() => setFieldNameSearchText(''), [])
  const onSearchInput = useCallback((newNameSearchText) => {
    setFieldNameSearchText(newNameSearchText)
  }, [])
  const onAfterClose = useCallback(() => {
    setIsSelectDialogOpen(false)
  }, [])

  const clearSelection = () =>
    setSortableItems(
      sortableItems.map((item) => ({
        ...item,
        isVisible: false,
      })),
    )

  const findIndexWithColumnKey = ({ items, columnKey }) =>
    items.findIndex((item) => item.columnKey === columnKey)

  const handleMoveItemUp = useCallback(() => {
    const indexToMove = findIndexWithColumnKey({ items: sortableItems, columnKey: focusedItem })
    setSortableItems(moveItemUp({ indexToMove, items: sortableItems }))
  }, [sortableItems, focusedItem])

  const handleMoveItemDown = useCallback(() => {
    const indexToMove = findIndexWithColumnKey({ items: sortableItems, columnKey: focusedItem })
    setSortableItems(moveItemDown({ indexToMove, items: sortableItems }))
  }, [sortableItems, focusedItem])

  if (columnSelection && columnSelection.length > 0) {
    if (disabled) {
      return (
        <div>
          <Icon
            name="action-settings"
            className={styles.settingsIconDisabled}
            design={IconDesign.NonInteractive}
          />
        </div>
      )
    }
    return (
      <div>
        <Icon
          name="action-settings"
          className={styles.settingsIcon}
          interactive
          onClick={onIconClicked}
        />
        {createPortal(
          <ToolbarColumnSelectDialog
            open={isSelectDialogOpen}
            onConfirm={onConfirmColumnSelection}
            onAfterClose={onAfterClose}
            onSearchInput={onSearchInput}
            onSearchReset={onSearchReset}
            onMoveUp={handleMoveItemUp}
            onMoveDown={handleMoveItemDown}
            onHandleClear={clearSelection}
          >
            {columns}
          </ToolbarColumnSelectDialog>,
          document.body,
        )}
      </div>
    )
  }
}

ToolbarColumnSelection.propTypes = {
  columnSelection: columnSelectionPropTypes,
  setColumnSelection: PropTypes.func,
  disabled: PropTypes.bool,
}

export default ToolbarColumnSelection
