import { ObjectStatus } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getObjectStatusForDecisionStageStatus } from 'api/decision-process/decisionProcessApi'

const DecisionProcessOverviewTableStatusCell = ({ stageStatus }) => {
  const { t: tNoPrefix } = useTranslation()
  const objectStatus = useMemo(
    () => getObjectStatusForDecisionStageStatus(stageStatus),
    [stageStatus],
  )

  return (
    <ObjectStatus inverted state={objectStatus.objectStatus}>
      {tNoPrefix(objectStatus.translationKey)}
    </ObjectStatus>
  )
}

DecisionProcessOverviewTableStatusCell.propTypes = {
  stageStatus: PropTypes.string.isRequired,
}

export default DecisionProcessOverviewTableStatusCell
