import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useRequestDecision = (mutationOptions) => {
  const { put } = useAccessTokenRequest()
  return useMutation(
    ({ decisionStageId, eventId, decisionPaperVersion }) =>
      put({
        path: `/events/${eventId}/decision-stages/${decisionStageId}/decision`,
        body: snakecaseKeys({
          decisionPaperVersion,
        }),
      }),
    mutationOptions,
  )
}

export default useRequestDecision
