import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DisplayCardView from 'components/ui/card/DisplayCardView'
import { useCustomizableCurrencyFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'

const useFormat = () => {
  const formatCurrency = useCustomizableCurrencyFormatter()
  const { format: formatDate } = useShortDateFormatter()

  return (value, unit) => {
    if (unit === 'date') return formatDate(value)
    return formatCurrency(value, unit)
  }
}

/**
 * The BusinessExposureTile expects an array with sections. Each section is an array of objects with a label and a value.
 * If the array is multiple sections long, the sections are separated by a separation line.
 *
 *
 * Example without separation:
 * ```js
 * const dataSectionOne = [
 *   { label: 'total-exposure', value: 42142142 },
 *   { label: 'Net Future Exposure', value: 42142142 },
 * ]
 * const sections = [dataSectionOne]
 * ```
 *
 *
 * Example with separation:
 * ```js
 * const dataSectionOne = [
 *   { label: 'total-exposure', value: 42142142 },
 *   { label: 'Net Future Exposure', value: 42142142 },
 * ]
 * const dataSectionTwo = [
 *   { label: 'total-exposure', value: 42142142 },
 *   { label: 'Net Future Exposure', value: 42142142 },
 * ]
 * const sections = [dataSectionOne, dataSectionTwo]
 * ```
 */
/**
 * @typedef {object} Props
 * @property {string} title
 * @property {Array<Section>} sections
 *
 * @typedef {object} Section
 * @property {string} label
 * @property {string | number} value
 * @property {string} unit `'date'` => `value` is formatted as date; otherwise it's formatted as currency with this `unit`
 */
/** @type {React.FC<Props>} */
const BusinessExposureTile = ({
  title,
  sections,
  isLoading = false,
  isError = false,
  conflictErrorTitle,
  conflictErrorText,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner' })
  const format = useFormat()

  const fieldDefinitions = useMemo(
    () =>
      sections
        .filter((section) => !isEmpty(section))
        .map((sectionItems) =>
          sectionItems.map(({ label, value, unit }) => ({ label, value: format(value, unit) })),
        )
        .flatMap((section) => [{ isSectionTitle: true }, ...section])
        .slice(1), // HINT: remove first section title
    [format, sections],
  )

  const hasNoData = sections.every((section) =>
    section.every((field) => isNil(field.value) || isNil(field.label)),
  )

  return (
    <DisplayCardView
      isLoading={isLoading}
      isError={isError}
      cardHeaderTitle={title}
      fieldDefinitions={fieldDefinitions}
      isEditable={false}
      isEmpty={hasNoData}
      emptyContent={{ title: t('no-data-title'), subtitle: t('no-data-text') }}
      setEditMode={() => {}}
      conflictErrorTitle={conflictErrorTitle}
      conflictErrorText={conflictErrorText}
    />
  )
}

BusinessExposureTile.propTypes = {
  title: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.string,
        unit: PropTypes.string,
      }),
    ),
  ).isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  conflictErrorTitle: PropTypes.string,
  conflictErrorText: PropTypes.string,
}

export default BusinessExposureTile
