import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import styles from 'components/ui/tables/cells/DateCell.module.css'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const propTypes = {
  /** The date value to format and display. */
  date: PropTypes.string,
  /**
   * Options to modify how the Cell will look like.
   *
   * - `isBold` Display the resulting text in bold font. Defaults to false.
   */
  options: PropTypes.shape({
    isBold: PropTypes.bool,
  }),
  /** Defines whether a loading spinner is shown. */
  isLoading: PropTypes.bool,
  /** Defines whether a loading spinner is shown. */
  isFetching: PropTypes.bool,
  /** Defines whether an error is shown. */
  isError: PropTypes.bool,
  /** Allows to define a custom error component. Defaults to undefined so that `<ErrorDataUnavailableInCell />` is shown. */
  errorToDisplay: PropTypes.node,
  /** Defines additional styling to the outmost div of the cell's content. */
  className: PropTypes.string,
}

/**
 * Shared Date Cell for usage within the Responsive and Analytical Table.
 *
 * The format depends on the language setting.
 *
 * @typedef Props
 * @property {String} date The status value to display as the component's label.
 * @property {Object} options Options to modify how the Cell will look like.
 * @property {Boolean} options.isBold Display the date in bold font. Defaults to false.
 * @property {Boolean} isLoading Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} isFetching Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} isError Defines whether an error is shown.
 * @property {React.ReactNode} errorToDisplay Allows to define a custom error component. Defaults to undefined so that <ErrorDataUnavailableInCell /> is shown.
 * @property {String} className Defines additional styling to the outmost div of the cell's content.
 */

/**
 * @param {Props} props Note for Storybook: cf. code for complete JSDoc
 */
const DateCell = ({
  date,
  options: { isBold = false } = {},
  isLoading = false,
  isFetching = false,
  isError = false,
  className,
  errorToDisplay,
}) => {
  const { format: formatDate } = useShortDateFormatter()

  const renderContent = () => {
    if (isNil(date)) {
      return <div />
    }
    return (
      <div className={compact([isBold ? styles.textBold : '', className]).join(' ')}>
        {formatDate(date)}
      </div>
    )
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading || isFetching}
      isError={isError}
      error={errorToDisplay ?? <ErrorDataUnavailableInCell />}
      renderContent={renderContent}
    />
  )
}

DateCell.propTypes = propTypes

export default DateCell
