import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxDirection,
  Modals,
  PopoverPlacementType,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/tables/TableRowActionButtons.module.css'

const tableRowActionsProps = {
  isEditable: PropTypes.bool,
  isEditDisabled: PropTypes.bool,
  isDeletable: PropTypes.bool,
  isDeleteDisabled: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  showDeletePopover: PropTypes.bool,
  id: PropTypes.string,
}

const DeletePopoverContent = ({ onDeleteClicked }) => {
  const { t } = useTranslation('translation')

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Text wrapping className={styles.deletePopoverText}>
        {t('display-and-edit-table.popover-static-config.delete.message')}
      </Text>
      <Button onClick={onDeleteClicked}>
        {t('display-and-edit-table.popover-static-config.delete.button')}
      </Button>
    </FlexBox>
  )
}

DeletePopoverContent.propTypes = {
  onDeleteClicked: PropTypes.func.isRequired,
}

const TableRowActionButtons = ({
  isEditable = false,
  isEditDisabled = false,
  isDeletable = false,
  isDeleteDisabled = false,
  onEdit = () => {},
  onDelete = () => {},
  showDeletePopover = false,
  id = '',
  ...props
}) => {
  const popoverRef = useRef()
  const showPopover = Modals.useShowPopover()

  const buttonIdPrefix = id ? `${id}-` : id
  const deleteButtonId = `${buttonIdPrefix}delete-button`
  const editButtonId = `${buttonIdPrefix}edit-button`

  const handleOnDelete = () => {
    if (showDeletePopover) {
      popoverRef.current = showPopover({
        opener: deleteButtonId,
        placementType: PopoverPlacementType.Top,
        children: (
          <DeletePopoverContent
            onDeleteClicked={() => {
              popoverRef.current.close()
              onDelete()
            }}
          />
        ),
      })
      return
    }
    onDelete()
  }

  return (
    <div {...props} className={styles.container}>
      {isEditable && (
        <Button
          id={editButtonId}
          design={ButtonDesign.Transparent}
          icon="edit"
          onClick={onEdit}
          disabled={isEditDisabled}
        />
      )}
      {isDeletable && (
        <Button
          id={deleteButtonId}
          design={ButtonDesign.Transparent}
          icon="delete"
          onClick={handleOnDelete}
          disabled={isDeleteDisabled}
        />
      )}
    </div>
  )
}

TableRowActionButtons.propTypes = tableRowActionsProps

export default TableRowActionButtons
