import { Text } from '@fioneer/ui5-webcomponents-react'
import { func, string } from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'

export const DisplayValueOfCodeText = ({ code, loadingHook = () => {}, valueKey = 'name' }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.table' })
  const { data: configurations = [], isLoading, isError } = loadingHook()

  const renderDisplayValue = () => {
    const configuration = configurations.find((item) => item.code === code) ?? {}
    return <Text>{configuration[valueKey]}</Text>
  }

  return (
    <SmallLoadingWrapper
      error={t('error')}
      isLoading={isLoading}
      isError={isError}
      renderContent={renderDisplayValue}
    />
  )
}

DisplayValueOfCodeText.propTypes = {
  code: string,
  valueKey: string,
  loadingHook: func.isRequired,
}
