import { FlexBox, FlexBoxDirection, Label, Link } from '@fioneer/ui5-webcomponents-react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/financing-structure/syndication/MultiExistingBusinessSyndicationStructureTableCells.module.css'
import AnalyticalTableCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/analytical/AnalyticalTableCell'
import EntityCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/EntityCell'
import MoneyCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/MoneyCell'
import TextCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/TextCell'
import paths from 'routes/paths'

export const MultiExistingBusinessSyndicationFinancialProductCell = (
  <AnalyticalTableCell className={styles.analyticalTableCell}>
    {(
      _tableData,
      _additionalData,
      {
        cell: {
          row: { original: row },
        },
      },
    ) => {
      if (row.isTotalOrOwnShare) {
        return <TextCell text={row.title} />
      } else if (row.isTrancheOrDrawing) {
        return (
          <span>
            <Label>{`${row.title}:`}</Label>
            <span> </span>
            <Link target="blank" href={row.href}>
              {row.linkTitle}
            </Link>
          </span>
        )
      }
      const { businessPartnerName, businessPartnerId } = row?.businessPartner ?? {}
      return (
        <EntityCell
          options={{ openInNewTab: true }}
          name={businessPartnerName}
          id={businessPartnerId}
          link={`/${paths.businessPartners}/${row?.bpId}`}
          isFetching={false}
          isError={false}
        />
      )
    }}
  </AnalyticalTableCell>
)
export const MultiExistingBusinessSyndicationFinancialRoleCell = (
  <AnalyticalTableCell className={styles.analyticalTableCell}>
    {(
      _tableData,
      { financialRolesData = {} },
      {
        cell: {
          row: { original: row },
        },
      },
    ) => (
      <TextCell
        text={financialRolesData?.find(({ code }) => code === row?.role)?.shortText ?? row?.role}
      />
    )}
  </AnalyticalTableCell>
)
export const MultiExistingBusinessSyndicationAuthorizedCommitmentCell = (
  <AnalyticalTableCell className={styles.analyticalTableCell}>
    {(
      _tableData,
      { t },
      {
        cell: {
          row: { original: row },
        },
      },
    ) => (
      <MoneyCell
        primary={row?.amount?.primaryAmount}
        secondary={row?.amount?.secondaryAmount}
        percentage={{ value: row?.amountShare, label: t('share.label') }}
      />
    )}
  </AnalyticalTableCell>
)
export const MultiExistingBusinessSyndicationOutstandingCell = (
  <AnalyticalTableCell className={styles.analyticalTableCell}>
    {(
      _tableData,
      { t },
      {
        cell: {
          row: { original: row },
        },
      },
    ) => (
      <MoneyCell
        primary={row?.outstandingAmount?.primaryAmount}
        secondary={row?.outstandingAmount?.secondaryAmount}
        percentage={{ value: row?.outstandingAmountShare, label: t('share.label') }}
      />
    )}
  </AnalyticalTableCell>
)
export const MultiExistingBusinessSyndicationRankCell = (
  <AnalyticalTableCell className={styles.analyticalTableCell}>
    {(
      _tableData,
      _additionalData,
      {
        cell: {
          row: { original: row },
        },
      },
    ) => <TextCell text={row?.rank} />}
  </AnalyticalTableCell>
)
export const MultiExistingBusinessSyndicationMandatorySyndicationCell = (
  <AnalyticalTableCell className={styles.analyticalTableCell}>
    {(
      _tableData,
      { formatMoney, formatNumber, formatDate, t },
      {
        cell: {
          row: { original: row },
        },
      },
    ) => {
      if (!row?.mandatorySyndication) {
        return <></>
      }
      const renderQuota = (quota) => (
        <div>
          <Label showColon>{t('quota.label')}</Label>&nbsp;
          {quota?.value && `${formatNumber(quota.value)}${quota?.unit || '%'}`}
        </div>
      )
      const renderAmount = (primaryAmount = {}, secondaryAmount = {}) => (
        <FlexBox direction={FlexBoxDirection.Row} className={styles.rightAlignCellContent}>
          <Label showColon>{t('syndicationAmount.label')}</Label>&nbsp;
          <FlexBox direction={FlexBoxDirection.Column}>
            <span>{formatMoney(primaryAmount.amount, primaryAmount.currency)}</span>
            {secondaryAmount.currency && primaryAmount.currency !== secondaryAmount.currency && (
              <span className={styles.textLabelColor}>
                {formatMoney(secondaryAmount.amount, secondaryAmount.currency)}
              </span>
            )}
          </FlexBox>
        </FlexBox>
      )
      const renderSyndicateUntil = (syndicateUntil) => (
        <div>
          <Label showColon>{t('syndicateUntil.label')}</Label>&nbsp;
          <span>{formatDate(syndicateUntil)}</span>
        </div>
      )
      const mandatorySyndicationKeys = Object.keys(row.mandatorySyndication)
      return (
        <FlexBox direction={FlexBoxDirection.Column} className={styles.contentWrapper}>
          {mandatorySyndicationKeys.includes('quota') &&
            renderQuota(row.mandatorySyndication.quota)}
          {mandatorySyndicationKeys.includes('syndicationAmount') &&
            renderAmount(
              row.mandatorySyndication.syndicationAmount?.primaryAmount,
              row.mandatorySyndication.syndicationAmount?.secondaryAmount,
            )}
          {mandatorySyndicationKeys.includes('syndicateUntil') &&
            renderSyndicateUntil(row.mandatorySyndication.syndicateUntil)}
        </FlexBox>
      )
    }}
  </AnalyticalTableCell>
)
