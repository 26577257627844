import { ValueState } from '@fioneer/ui5-webcomponents-react'
import get from 'lodash.get'
import sum from 'lodash.sum'
import uniq from 'lodash.uniq'
import { useContext, useMemo, useCallback } from 'react'
import {
  buildRowsDataRecursive,
  trimRowsDeep,
} from 'components/domains/business-partners/tile/arrears/ArrearsUtil'
import {
  getApprovalAmountValueState,
  getClassificationValueState,
  getDateValueState,
} from 'components/domains/business-partners/tile/arrears/current-approvals/getArrearAppovalTableCellValueState'
import { useCurrentApprovalsTableDataHelper } from 'components/domains/business-partners/tile/arrears/current-approvals/useCurrentApprovalsTableDataHelper'
import { useRecursivelyExtractApprovalItems } from 'components/domains/business-partners/tile/arrears/current-approvals/useRecursivelyExtractApprovalItems'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { BusinessPartnerArrearsContext } from 'routes/business-partners/arrears/BusinessPartnerArrearsContext'
import {
  ARREARS_HEADQUARTER_CURRENCY,
  ARREAR_APPROVAL_STATUS,
  ENTITY_TYPES,
  OVERPAYMENT,
} from 'routes/business-partners/arrears/constants'
import set from 'utils/set'

export const getRowKey = (row) => `${row.entityId}-${row.entityType}`
const PLACE_HOLDER = '-'
export const ROWHEIGHT_DUALCURRENCY = 80
export const ROWHEIGHT_READMODE = 58
export const isDualCurrencyRow = (row) =>
  row.rowData.arrearCurrency && row.rowData.arrearCurrency !== ARREARS_HEADQUARTER_CURRENCY

const useCurrentApprovalsTableData = (isStartApprovalProcess) => {
  const {
    businessPartnerId,
    arrears,
    productHierarchy,
    arrearApprovals,
    arrearApprovalEvents,
    products,
    arrearClassifications,
    isLoading,
    isError,
  } = useContext(BusinessPartnerArrearsContext)

  const { parse, localePattern } = useShortDateFormatter()

  const shouldHaveSubRowsOrIsApprovalItem = (row) =>
    !!row.subRows?.length || !!row.rowData?.approvalItem

  const shouldNotBeOverpayment = (row) =>
    row.entityType !== ENTITY_TYPES.APPROVALITEM ||
    row.rowData.approvalItem.arrearType.code !== OVERPAYMENT

  const approvalItems = useCurrentApprovalsTableDataHelper(
    arrears,
    arrearApprovals,
    arrearApprovalEvents,
    isStartApprovalProcess,
  )

  const findApprovalItemsByRow = useCallback(
    ({ entityId, secondaryEntityId, entityType }) =>
      approvalItems.filter(
        (approvalItem) =>
          (approvalItem.product.id === entityId || approvalItem.product.id === secondaryEntityId) &&
          approvalItem.product.type.code === entityType,
      ),
    [approvalItems],
  )

  const findArrearCurrencyByApprovalItem = useCallback(
    ({ arrearExternalId }) =>
      arrears.find((arrear) => arrear.externalId === arrearExternalId)?.currency,
    [arrears],
  )

  const findArrearApprovalStatusListByApprovalItem = useCallback(
    ({ arrearExternalId }) =>
      arrears.find((arrear) => arrear.externalId === arrearExternalId)?.approvalStatusList ?? [],
    [arrears],
  )

  const getArrearsAmountByProduct = useCallback(
    (productRow) => {
      const arrearAmountsOfProduct = arrears
        .filter(
          (arrear) =>
            arrear.product.id === productRow.entityId ||
            arrear.product.id === productRow.secondaryEntityId,
        )
        .map((arrear) => arrear.headquarterArrearAmount ?? 0)
      return sum(arrearAmountsOfProduct)
    },
    [arrears],
  )

  const getInitialApprovalAmount = useCallback(
    (approvalItem, headquarterArrearAmount, currency) => {
      if (isStartApprovalProcess) {
        const arrearApprovalStatusList = findArrearApprovalStatusListByApprovalItem(approvalItem)
        if (
          arrearApprovalStatusList.includes(ARREAR_APPROVAL_STATUS.IN_APPROVAL) ||
          arrearApprovalStatusList.includes(ARREAR_APPROVAL_STATUS.PARTIALLY_IN_APPROVAL)
        ) {
          return approvalItem.approvalAmount
        } else if (currency !== ARREARS_HEADQUARTER_CURRENCY) {
          const factor = 1.1
          return headquarterArrearAmount * factor
        } else {
          return headquarterArrearAmount
        }
      } else {
        return approvalItem.approvalAmount
      }
    },
    [findArrearApprovalStatusListByApprovalItem, isStartApprovalProcess],
  )

  const findArrearByApprovalItem = useCallback(
    ({ arrearExternalId }) =>
      arrears.find((arrear) => arrear.externalId === arrearExternalId) ?? {},
    [arrears],
  )

  const extendRowDataWithArrearAndApprovalItem = useCallback(
    (row) => {
      const arrearsAmountByProduct = getArrearsAmountByProduct(row)
      const approvalItemsByRow = findApprovalItemsByRow(row)
      approvalItemsByRow.forEach((approvalItem, index) => {
        const {
          type: arrearType = {
            code: PLACE_HOLDER,
            name: PLACE_HOLDER,
          },
          headquarterArrearAmount = undefined,
          arrearAmount = undefined,
          currency = undefined,
        } = findArrearByApprovalItem(approvalItem)
        const initialApprovalAmount = getInitialApprovalAmount(
          approvalItem,
          headquarterArrearAmount,
          currency,
        )
        const subRow = {
          entityId:
            approvalItem.id ?? `${index}-arrearExternalId-${approvalItem?.arrearExternalId}`,
          entityType: ENTITY_TYPES.APPROVALITEM,
          rowData: {
            approvalItem: {
              ...approvalItem,
              approvalAmount: {
                value: initialApprovalAmount,
                valueState: getApprovalAmountValueState(
                  initialApprovalAmount,
                  headquarterArrearAmount,
                  arrearType.code,
                ),
              },
              approvedUntil: {
                value: approvalItem.approvedUntil,
                valueState: getDateValueState(approvalItem.approvedUntil, localePattern, parse),
              },
              classification: {
                value: { ...approvalItem.classification },
                valueState: getClassificationValueState(
                  arrearsAmountByProduct,
                  arrearType.code,
                  approvalItem.classification.code,
                ),
              },
              comment: {
                value: approvalItem.comment,
                valueState: ValueState.None,
              },
              arrearType: arrearType,
              headquarterArrearAmount: headquarterArrearAmount,
              arrearAmount: arrearAmount,
              arrearsAmountByProduct: arrearsAmountByProduct,
            },
          },
          sumData: {},
          subRows: [],
        }
        row.subRows.push(subRow)
      })
    },
    [
      findApprovalItemsByRow,
      findArrearByApprovalItem,
      getArrearsAmountByProduct,
      getInitialApprovalAmount,
      localePattern,
      parse,
    ],
  )

  const extendRowWithArrearCurrency = useCallback(
    (row) => {
      row.subRows?.forEach((subRow) => extendRowWithArrearCurrency(subRow))
      if (row.entityType === ENTITY_TYPES.APPROVALITEM) {
        const arrearCurrency = findArrearCurrencyByApprovalItem(row.rowData.approvalItem)
        set(row, 'rowData.arrearCurrency', arrearCurrency)
        return
      }
      const arrearCurrenciesFromSubRows = uniq(
        row.subRows.map((subRow) => subRow.rowData.arrearCurrency),
      )

      if (arrearCurrenciesFromSubRows.length === 1) {
        set(row, 'rowData.arrearCurrency', arrearCurrenciesFromSubRows[0])
      } else {
        set(row, 'rowData.arrearCurrency', ARREARS_HEADQUARTER_CURRENCY)
      }
    },
    [findArrearCurrencyByApprovalItem],
  )

  const extendRowsWithArrearCurrency = useCallback(
    (rows) => {
      rows.forEach((row) => extendRowWithArrearCurrency(row))
    },
    [extendRowWithArrearCurrency],
  )

  const extendRowWithRowHeight = useCallback((row) => {
    if (isDualCurrencyRow(row)) {
      row['rowHeight'] = ROWHEIGHT_DUALCURRENCY
    } else {
      row['rowHeight'] = ROWHEIGHT_READMODE
    }
    row.subRows.forEach((subRow) => extendRowWithRowHeight(subRow))
  }, [])

  const extendRowsWithRowHeight = useCallback(
    (rows) => {
      rows.forEach((row) => extendRowWithRowHeight(row))
    },
    [extendRowWithRowHeight],
  )

  const calculateSummedApprovalAmount = useCallback((row) => {
    row.subRows?.forEach((subRow) => calculateSummedApprovalAmount(subRow))

    const approvalAmountDirect = row.rowData?.approvalItem?.approvalAmount?.value

    const approvalAmountSumFromSubrows = row.subRows?.reduce((acc, cur) => {
      const currentAmount = cur.sumData?.approvedAmount ?? 0
      return currentAmount + acc
    }, 0)

    set(row, 'sumData.approvedAmount', approvalAmountDirect ?? approvalAmountSumFromSubrows)
  }, [])

  const calculateValueFromSubRows = useCallback(
    (row, sourceAccessor, targetAccessor, defaultVal) => {
      row.subRows?.forEach((subRow) =>
        calculateValueFromSubRows(subRow, sourceAccessor, targetAccessor, defaultVal),
      )
      const valueDirect = get(row.rowData, sourceAccessor)
      const valueFromFirstSubRow = get(row.subRows?.at(0), targetAccessor) ?? defaultVal
      set(row, targetAccessor, valueDirect ?? valueFromFirstSubRow)
    },
    [],
  )

  const calculateClassificationValueStateFromSubRows = useCallback(
    (row, sourceAccessor, targetAccessor, defaultVal) => {
      row.subRows?.forEach((subRow) =>
        calculateClassificationValueStateFromSubRows(
          subRow,
          sourceAccessor,
          targetAccessor,
          defaultVal,
        ),
      )
      const valueDirect = get(row.rowData, sourceAccessor)
      const valueStateFromAllSubApprovalItems = row.subRows
        ?.map(
          (subRow) =>
            subRow.entityType === ENTITY_TYPES.APPROVALITEM && get(subRow, targetAccessor),
        )
        .filter(Boolean)

      const valueState = valueStateFromAllSubApprovalItems.every(
        (state) => state === ValueState.None,
      )
        ? ValueState.None
        : ValueState.Error
      set(row, targetAccessor, valueDirect ?? valueState)
    },
    [],
  )

  const extendRowsWithValue = useCallback(
    (rows, sourceAccessor, targetAccessor, defaultVal) => {
      rows.forEach((row) =>
        calculateValueFromSubRows(row, sourceAccessor, targetAccessor, defaultVal),
      )
    },
    [calculateValueFromSubRows],
  )

  const extendRowsWithClassificationValueState = useCallback(
    (rows, sourceAccessor, targetAccessor, defaultVal) => {
      rows.forEach((row) =>
        calculateClassificationValueStateFromSubRows(
          row,
          sourceAccessor,
          targetAccessor,
          defaultVal,
        ),
      )
    },
    [calculateClassificationValueStateFromSubRows],
  )

  const extendRowsWithApprovalSumAmount = useCallback(
    (rows) => rows.forEach((row) => calculateSummedApprovalAmount(row)),
    [calculateSummedApprovalAmount],
  )

  const getClassificationNameByCode = useCallback(
    (classificationCode) => {
      if (Array.isArray(arrearClassifications?.arrearClassifications)) {
        return arrearClassifications.arrearClassifications.find(
          (classification) => classification.code === classificationCode,
        )?.name
      }
      return null
    },
    [arrearClassifications?.arrearClassifications],
  )

  const extendApprovalItemsWithClassificationTranslation = useCallback(
    (row) => {
      row.subRows?.forEach((subRow) => extendApprovalItemsWithClassificationTranslation(subRow))
      const arrearClassificationCode = row.rowData?.classificationCode

      const classificationTranslated = getClassificationNameByCode(arrearClassificationCode.value)
      if (arrearClassificationCode) {
        set(
          row,
          'rowData.classificationName',
          classificationTranslated ?? arrearClassificationCode.value,
        )
      }
    },
    [getClassificationNameByCode],
  )

  const extendRowsWithClassificationTranslation = useCallback(
    (rows) => rows.forEach((row) => extendApprovalItemsWithClassificationTranslation(row)),
    [extendApprovalItemsWithClassificationTranslation],
  )

  const buildRowsData = useCallback(() => {
    const rowsData = buildRowsDataRecursive({
      businessPartnerId,
      products,
      productHierarchy,
      extendRowsWithAdditionalData: extendRowDataWithArrearAndApprovalItem,
    })
    extendRowsWithValue(
      rowsData,
      'approvalItem.approvedUntil.value',
      'rowData.approvedUntil.value',
      '',
    )
    extendRowsWithValue(rowsData, 'approvalItem.comment', 'rowData.comment', '')

    extendRowsWithValue(
      rowsData,
      'approvalItem.classification.value.code',
      'rowData.classificationCode.value',
      '',
    )
    extendRowsWithValue(
      rowsData,
      'approvalItem.arrearsAmountByProduct',
      'rowData.arrearsAmountByProduct',
      ValueState.None,
    )
    extendRowsWithValue(
      rowsData,
      'approvalItem.approvedUntil.valueState',
      'rowData.approvedUntil.valueState',
      ValueState.None,
    )
    extendRowsWithClassificationValueState(
      rowsData,
      'approvalItem.classification.valueState',
      'rowData.classificationCode.valueState',
      ValueState.None,
    )
    extendRowsWithArrearCurrency(rowsData)
    extendRowsWithClassificationTranslation(rowsData)
    extendRowsWithApprovalSumAmount(rowsData)
    extendRowsWithRowHeight(rowsData)
    const trimmedRowsWithOverpayments = trimRowsDeep(rowsData, shouldHaveSubRowsOrIsApprovalItem)
    const trimmedRowsWithoutOverpayments = trimRowsDeep(
      trimmedRowsWithOverpayments,
      shouldNotBeOverpayment,
    )
    return trimmedRowsWithoutOverpayments
  }, [
    extendRowDataWithArrearAndApprovalItem,
    extendRowsWithApprovalSumAmount,
    extendRowsWithArrearCurrency,
    extendRowsWithClassificationTranslation,
    extendRowsWithClassificationValueState,
    extendRowsWithRowHeight,
    extendRowsWithValue,
    productHierarchy,
    products,
    businessPartnerId,
  ])

  const data = useMemo(() => buildRowsData(), [buildRowsData])
  const datacount = useRecursivelyExtractApprovalItems(data).length

  const result = {
    isLoading,
    isError,
    data: [],
  }

  if (isLoading || isError) {
    return result
  } else {
    return {
      ...result,
      data,
      datacount,
    }
  }
}

export default useCurrentApprovalsTableData
