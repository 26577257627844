import { IllustrationMessageSize, IllustrationMessageType } from '@fioneer/ui5-webcomponents-react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useTenantColors } from 'components/domains/properties/rent-roll/overview/chart-colors/TenantColorContext'
import styles from 'components/domains/properties/rent-roll/overview/wault/PropertyRentRollOverviewWaultCardContent.module.css'
import PropertyRentRollOverviewWaultChart from 'components/domains/properties/rent-roll/overview/wault/PropertyRentRollOverviewWaultChart'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import usePropertyTenantsChartData from 'hooks/services/properties/kpis/charts/usePropertyTenantsChartData'
import useCurrentRentRollOverviewPropertyKpis from 'hooks/services/properties/kpis/useCurrentRentRollOverviewPropertyKpis'

export const MAX_DISPLAYED_TENANTS = 4

const useWaultChartData = (propertyKpiData) => {
  const propertyAnnualizedCurrentRent = propertyKpiData?.annualizedCurrentRent?.number ?? 0
  const rentRollKeyDate = propertyKpiData?.keyDate

  const { getColorForId, getGroupedColor } = useTenantColors()

  const getTenantRentShare = useCallback(
    (tenantKpi) =>
      (tenantKpi?.annualizedCurrentRent?.number ?? 0) / propertyAnnualizedCurrentRent || 0,
    [propertyAnnualizedCurrentRent],
  )

  const groupedTenantsKpisToChartDataPoint = useCallback(
    (tenantKpis) => {
      const sumOfGroupedTenantsRentShare = tenantKpis.reduce(
        (sumTenantsMetric, tenant) => sumTenantsMetric + getTenantRentShare(tenant),
        0,
      )

      const groupedTenantsWeightedWaultBreak = tenantKpis.reduce(
        (currentWeightedWaultBreak, tenant) =>
          currentWeightedWaultBreak +
          (tenant.waultToBreakInYears * getTenantRentShare(tenant)) / sumOfGroupedTenantsRentShare,
        0,
      )

      const groupedTenantsWeightedWaultExpiry = tenantKpis.reduce(
        (currentWeightedWaultBreak, tenant) =>
          currentWeightedWaultBreak +
          (tenant.waultToExpiryInYears * getTenantRentShare(tenant)) / sumOfGroupedTenantsRentShare,
        0,
      )

      return {
        rentShare: sumOfGroupedTenantsRentShare,
        waultBreak: groupedTenantsWeightedWaultBreak,
        waultExpiry: groupedTenantsWeightedWaultExpiry,
        color: getGroupedColor(),
      }
    },
    [getGroupedColor, getTenantRentShare],
  )

  const tenantKpiToChartDataPoint = useCallback(
    (tenantKpi) => ({
      rentShare: getTenantRentShare(tenantKpi),
      waultBreak: tenantKpi.waultToBreakInYears,
      waultExpiry: tenantKpi.waultToExpiryInYears,
      tenantId: tenantKpi.tenant,
      color: getColorForId(tenantKpi.tenant),
    }),
    [getColorForId, getTenantRentShare],
  )

  const {
    data: chartData,
    isLoading,
    isError,
    isEmpty,
  } = usePropertyTenantsChartData({
    propertyKpiData,
    getMetricFromTenantKpi: getTenantRentShare,
    tenantKpiToChartDataPoint,
    groupedTenantsKpisToChartDataPoint,
    maxDisplayedTenants: MAX_DISPLAYED_TENANTS,
  })

  return {
    data: {
      data: chartData,
      referenceDate: rentRollKeyDate,
    },
    isLoading,
    isError,
    isEmpty,
  }
}

const PropertyRentRollOverviewWaultCardContent = () => {
  const { t: tWaultChart } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.wault.chart',
  })

  const {
    isLoading: isLoadingPropertyKpis,
    isError: isErrorPropertyKpis,
    data: propertyKpiData,
  } = useCurrentRentRollOverviewPropertyKpis()

  const {
    data: chartData,
    isLoading: isLoadingChartData,
    isError: isErrorChartData,
    isEmpty,
  } = useWaultChartData(propertyKpiData)
  const isLoading = isLoadingPropertyKpis || isLoadingChartData
  const isError = isErrorPropertyKpis || isErrorChartData

  return (
    <div className={styles.chartContainer}>
      <LoadingStateWrapper
        isLoading={isLoading}
        isError={isError}
        errorTitle={tWaultChart('error.title')}
        errorDescription={tWaultChart('error.subtitle')}
        errorDetails=" "
        loadingDescription={tWaultChart('loading')}
      >
        {isEmpty ? (
          <EmptyCardContent
            title={tWaultChart('empty.title')}
            subtitle={tWaultChart('empty.subtitle')}
            illustrationName={IllustrationMessageType.UnableToLoad}
            size={IllustrationMessageSize.Scene}
          />
        ) : (
          <PropertyRentRollOverviewWaultChart {...chartData} />
        )}
      </LoadingStateWrapper>
    </div>
  )
}

export default PropertyRentRollOverviewWaultCardContent
