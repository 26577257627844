import isEmpty from 'lodash.isempty'

const valuationMethodChanged = (state, { payload: { calculationMethodCode: newMethodCode } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }
  state.editedRow.currentValues.calculationMethodCode = newMethodCode
  state.editedRow.touchedFields.calculationMethodCode = true

  if (newMethodCode) {
    delete state.editedRow.errorFields.calculationMethodCode
  } else {
    state.editedRow.errorFields.calculationMethodCode = true
  }

  if (newMethodCode === state.editedRow.initialValues.calculationMethodCode) {
    delete state.editedRow.changedFields.calculationMethodCode
    return
  }

  state.editedRow.changedFields.calculationMethodCode = true
}

export default valuationMethodChanged
