import {
  BusyIndicator,
  BusyIndicatorSize,
  Button,
  ButtonDesign,
  MenuItem,
  Menu,
  MessageBoxTypes,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { conditionTypes } from 'api/conditions/conditions'
import ConditionCreationDialog from 'components/domains/conditions/dialogs/condition-creation/ConditionCreationDialog'
import styles from 'components/domains/conditions/overview/buttons/external-conditions/CreateExternalConditionButton.module.css'
import { readExcel } from 'components/domains/conditions/overview/buttons/external-conditions/readExternalConditionsFromExcel'
import Dialog from 'components/ui/dialog/Dialog'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useExternalTypes from 'hooks/services/conditions/config/useExternalTypes'
import {
  addCondition,
  addConditions,
  openCreationDialog,
  openStandardListItemDialog,
  reset,
} from 'redux/slices/conditions/conditionCreationSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const buttonId = 'external-conditions-add-button'

const fromStandardListIdentifier = 'FromStandardList'
const manualListIdentifier = 'manual'
const uploadIdentifier = 'upload'

const CreateExternalConditionButton = ({ disabled }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.buttons.create-external-condition',
  })
  const dispatch = useDispatch()
  const showMessageBox = useShowMessageBox()
  const fileInputRef = useRef(null)

  const {
    entityRef: { entityType },
  } = useContext(ConditionsContext)

  const {
    data: externalTypes,
    isError: isExternalTypesError,
    isLoading: isExternalTypesLoading,
  } = useExternalTypes({
    translated: false,
    entityType,
    includeDeprecated: false,
  })

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isLoadingDialogOpen, setIsLoadingDialogOpen] = useState(false)

  const showErrorMessage = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      titleText: t('error.title'),
      children: t('error.description'),
      actions: [
        <Button key="error-buttons-ok" design={ButtonDesign.Emphasized}>
          {tNoPrefix('buttons.ok')}
        </Button>,
      ],
    })
  }, [showMessageBox, t, tNoPrefix])

  const onAddButtonClick = useCallback(() => {
    setIsMenuOpen(true)
  }, [])

  const closeAddButtonMenu = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  const onAddManualItemClicked = useCallback(() => {
    dispatch(addCondition({ expanded: true, requirements: [{}] }))
    dispatch(openCreationDialog())
  }, [dispatch])

  const onAddStandardItemClicked = useCallback(() => {
    dispatch(openStandardListItemDialog())
  }, [dispatch])

  const onMenuItemClick = useCallback(
    ({ detail: { item: pressedMenuItem } }) => {
      setIsMenuOpen(false)
      const pressedIdentifier = pressedMenuItem.getAttribute('data-button-id')
      switch (pressedIdentifier) {
        case fromStandardListIdentifier:
          onAddStandardItemClicked()
          break
        case manualListIdentifier:
          onAddManualItemClicked()
          break
        case uploadIdentifier:
          fileInputRef.current.click()
      }
    },
    [onAddStandardItemClicked, onAddManualItemClicked],
  )

  const onUploadError = useCallback(() => {
    setIsLoadingDialogOpen(false)
    dispatch(reset())
    showMessageBox({
      type: MessageBoxTypes.Error,
      titleText: t('error.title'),
      children: t('upload.excel.format.error.description'),
      actions: [
        <Button key="error-buttons-ok" design={ButtonDesign.Emphasized}>
          {tNoPrefix('buttons.ok')}
        </Button>,
      ],
    })
  }, [dispatch, showMessageBox, t, tNoPrefix])

  const onUploadSuccess = useCallback(
    (loadedConditions) => {
      setIsLoadingDialogOpen(false)
      dispatch(addConditions(loadedConditions))
      dispatch(openCreationDialog())
    },
    [dispatch],
  )

  const handleUpload = useCallback(
    (event) => {
      if (isExternalTypesError) {
        setIsLoadingDialogOpen(false)
        showErrorMessage()
        return
      }
      setIsLoadingDialogOpen(true)
      const file = event.target.files[0]
      const input = event.target
      input.value = null
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file)
      fileReader.onload = (loadEvent) =>
        readExcel({ loadEvent, externalTypes, onUploadSuccess, onUploadError })
    },
    [isExternalTypesError, externalTypes, onUploadError, onUploadSuccess, showErrorMessage],
  )

  const loadingDialogContent = useMemo(
    () => (
      <BusyIndicator
        active
        text={t('upload.loading')}
        title={' '}
        size={BusyIndicatorSize.Medium}
        style={{ textAlign: 'center', display: 'block' }}
      />
    ),
    [t],
  )

  return (
    <>
      <Button
        id={buttonId}
        onClick={onAddButtonClick}
        icon="slim-arrow-down"
        iconEnd
        disabled={disabled}
      >
        {tNoPrefix('buttons.add')}
      </Button>
      {isMenuOpen && (
        <Menu
          opener={buttonId}
          open={isMenuOpen}
          onAfterClose={closeAddButtonMenu}
          onItemClick={onMenuItemClick}
        >
          <MenuItem text={t('from-standard-list')} data-button-id={fromStandardListIdentifier} />
          <MenuItem text={t('manual')} data-button-id={manualListIdentifier} />
          <MenuItem
            text={t('upload')}
            data-button-id={uploadIdentifier}
            disabled={isExternalTypesLoading}
          />
        </Menu>
      )}
      <ConditionCreationDialog conditionType={conditionTypes.external} />
      <input
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={handleUpload}
        ref={fileInputRef}
        className={styles.fileInput}
      />
      <Dialog open={isLoadingDialogOpen} headerText={''}>
        {loadingDialogContent}
      </Dialog>
    </>
  )
}

CreateExternalConditionButton.propTypes = {
  disabled: PropTypes.bool,
}

export default CreateExternalConditionButton
