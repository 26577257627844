import PropTypes from 'prop-types'
import z from 'zod'
import { chargeBaseSchema } from 'hooks/services/collaterals/chargesSchema'
import { receivablesBaseSchema } from 'hooks/services/collaterals/receivablesSchema'

const portionSchema = z.object({
  portionUuid: z.string(),
  portionId: z.string(),
  portionValueAmount: z.object({ value: z.number(), currency: z.string() }).nullish(),
  portionValueAmountHq: z.object({ value: z.number(), currency: z.string() }).nullish(),
  portionValuePercent: z.number(),
  businessPartners: z.array(
    z.object({
      id: z.string(),
      displayId: z.string(),
      function: z.object({ id: z.string(), name: z.string() }),
    }),
  ),
})

const collateralAgreementBaseSchema = receivablesBaseSchema.extend({
  allowedOperations: z.string().array().nullish(),
  assetsCount: z.number().int(),
  businessPartners: z.any(),
  cagCluster: z.object({ id: z.string(), name: z.string() }),
  cagType: z.object({ id: z.string(), name: z.string() }),
  cagValue: z.object({ value: z.number(), currency: z.string() }).nullish(),
  cagValueHq: z.object({ value: z.number(), currency: z.string() }).nullish(),
  charges: z.array(chargeBaseSchema).nullish(),
  displayId: z.string(),
  endDate: z.coerce.date().nullish(),
  extLandCharge: z.boolean(),
  guaranteeRate: z.number().nullish(),
  id: z.string().uuid(),
  jurisdictionCountry: z.object({ id: z.string(), name: z.string() }),
  lastValuationDate: z.coerce.date().nullish(),
  lendingRate: z.number().nullish(),
  name: z.string(),
  nominalValue: z.object({ value: z.number(), currency: z.string() }).nullish(),
  nominalValueHq: z.object({ value: z.number(), currency: z.string() }).nullish(),
  originalProtectionValue: z.object({ value: z.number(), currency: z.string() }).nullish(),
  originalProtectionValueHq: z.object({ value: z.number(), currency: z.string() }).nullish(),
  portions: portionSchema.array(),
  signingDate: z.coerce.date().nullish(),
  startDate: z.coerce.date().nullish(),
  systemStatus: z.object({ id: z.string(), name: z.string() }),
  terminationRightType: z.string().nullish(),
  customFields: z
    .array(z.object({ key: z.string(), value: z.string() }))
    .transform((fieldList) => Object.fromEntries(fieldList.map(({ key, value }) => [key, value]))),
  specialArrangements: z.array(
    z.object({
      displayId: z.string(),
      id: z.string(),
      portionId: z.string(),
      type: z.object({ id: z.string(), name: z.string() }),
    }),
  ),
})

const receivablePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  financialProductId: PropTypes.string.isRequired,
  cagId: PropTypes.string.isRequired,
  portionId: PropTypes.number.isRequired,
  priority: PropTypes.number.isRequired,
  ranking: PropTypes.number.isRequired,
}).isRequired

const existingBusinessReceivablePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  financialProductId: PropTypes.string.isRequired,
  cagId: PropTypes.string.isRequired,
  companyCode: PropTypes.string,
  contractNumber: PropTypes.string,
  productType: PropTypes.string,
  portionId: PropTypes.number.isRequired,
  priority: PropTypes.number.isRequired,
  ranking: PropTypes.number.isRequired,
}).isRequired

export const cagPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  displayId: PropTypes.string.isRequired,
  cagCluster: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  cagType: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  cagValue: PropTypes.shape({
    value: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }),
  cagValueHq: PropTypes.shape({
    value: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }),
  nominalValue: PropTypes.shape({
    value: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }),
  nominalValueHq: PropTypes.shape({
    value: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }),
  newBusinessReceivables: PropTypes.arrayOf(receivablePropTypes),
  existingBusinessReceivables: PropTypes.arrayOf(existingBusinessReceivablePropTypes),
  lastValuationDate: PropTypes.instanceOf(Date),
  signingDate: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  originalProtectionValue: PropTypes.shape({
    value: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }),
  originalProtectionValueHq: PropTypes.shape({
    value: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }),
  guaranteeRate: PropTypes.number,
  lendingRate: PropTypes.number,
  terminationRightType: PropTypes.string,
  jurisdictionCountry: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  customFields: PropTypes.shape({
    sub_type: PropTypes.string,
    unlmtd_ch: PropTypes.string,
    opd: PropTypes.string,
    enf_type: PropTypes.string,
    ek_rel: PropTypes.string,
    cov_rel: PropTypes.string,
  }).isRequired,
  businessPartners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      function: PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
      displayId: PropTypes.string.isRequired,
    }),
  ),
  portions: PropTypes.arrayOf(
    PropTypes.shape({
      portionUuid: PropTypes.string.isRequired,
      portionId: PropTypes.string.isRequired,
    }).isRequired,
  ),
  systemStatus: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  allowedOperations: PropTypes.arrayOf(PropTypes.string),
}).isRequired

const collateralAgreementsAssetsDetailsBaseSchema = z.object({
  collateralAgreements: z.array(
    z.object({
      id: z.string().uuid(),
      displayId: z.string(),
      name: z.string(),
      status: z.object({ id: z.string(), name: z.string() }),
      cagCluster: z.object({ id: z.string(), name: z.string() }),
      cagType: z.object({ id: z.string(), name: z.string() }),
      cagValue: z.object({ value: z.number(), currency: z.string() }).nullish(),
      cagValueHq: z.object({ value: z.number(), currency: z.string() }).nullish(),
      nominalValue: z.object({ value: z.number(), currency: z.string() }).nullish(),
      nominalValueHq: z.object({ value: z.number(), currency: z.string() }).nullish(),
      rank: z.number(),
      sequence: z.number(),
    }),
  ),
})

const collateralAgreementSearchBaseSchema = receivablesBaseSchema.extend({
  id: z.string().uuid(),
  displayId: z.string(),
  cagCluster: z.object({ id: z.string(), name: z.string() }),
  cagType: z.object({ id: z.string(), name: z.string() }),
  name: z.string(),
  cagValue: z.object({ value: z.number(), currency: z.string() }).nullish(),
  cagValueHq: z.object({ value: z.number(), currency: z.string() }).nullish(),
  nominalValue: z.object({ value: z.number(), currency: z.string() }).nullish(),
  nominalValueHq: z.object({ value: z.number(), currency: z.string() }).nullish(),
  assetsCount: z.number().int(),
  extLandCharge: z.boolean(),
  systemStatus: z.object({ id: z.string(), name: z.string() }),
  businessPartners: z.any(),
  allowedOperations: z.string().array().nullish(),
})

const collateralAgreementValidationSchema = z.object({
  lastValuationDate: z.coerce.date().nullish(),
  cagValue: z.object({ value: z.number(), currency: z.string() }).nullish(),
  signingDate: z.coerce.date().nullish(),
  startDate: z.coerce.date().nullish(),
  endDate: z.coerce.date().nullish(),
  originalProtectionValue: z.object({ value: z.number(), currency: z.string() }).nullish(),
  customFields: z
    .array(z.object({ key: z.string(), value: z.string() }))
    .transform((fieldList) => Object.fromEntries(fieldList.map(({ key, value }) => [key, value]))),
  cagCluster: z.object({ id: z.string(), name: z.string() }),
})
export const collateralAgreementSearchSchema = z
  .object({ collateralAgreements: z.array(collateralAgreementSearchBaseSchema) })
  .optional()

/** @typedef {import('zod').TypeOf<typeof collateralAgreementBaseSchema>} CollateralAgreement */
/** @typedef {import('zod').TypeOf<typeof collateralAgreementSearchBaseSchema>} CollateralAgreementSearch */

const collateralAgreementSchema = collateralAgreementBaseSchema.optional()
export const collateralAgreementsSchema = z
  .object({
    collateralAgreements: z.array(collateralAgreementSchema),
  })
  .optional()

/** @typedef {import('zod').TypeOf<typeof collateralAgreementValidation>} CollateralAgreementValidation */
const collateralAgreementValidation = collateralAgreementValidationSchema.optional()

const collateralAgreementsDetailsTableSchema =
  collateralAgreementsAssetsDetailsBaseSchema.optional()

export {
  collateralAgreementValidation,
  collateralAgreementSchema,
  collateralAgreementsDetailsTableSchema,
}
