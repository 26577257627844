import { Label, WrappingType, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/GeneralInformationTile.module.css'
import Card from 'components/ui/card/Card'
import ClickableCardHeader from 'components/ui/card/ClickableCardHeader'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'

const GeneralInformationTile = ({
  streetName = '',
  postalCode = '',
  city = '',
  country = '',
  showLink = true,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.general-information',
  })
  const header = (
    <ClickableCardHeader titleText={t('title')} href={showLink ? './general-information' : ''} />
  )
  const rowsWithValues = [
    { label: t('address.street'), value: streetName },
    { label: t('city-and-postal-code'), value: `${postalCode} ${city}` },
    { label: t('country'), value: country },
  ].filter(({ value }) => value?.trim().length > 0)

  return (
    <Card header={header} className={styles.businessPartnerTile}>
      {rowsWithValues.length > 0 ? (
        <div className={styles.businessPartnerTileDataGrid}>
          {rowsWithValues.map(({ label, value }) => (
            <Fragment key={label}>
              <Label wrappingType={WrappingType.Normal}>{label}</Label>
              <Text wrapping={true} className={styles.businessPartnerTileDataGridValueCell}>
                {value}
              </Text>
            </Fragment>
          ))}
        </div>
      ) : (
        <EmptyCardContent />
      )}
    </Card>
  )
}

GeneralInformationTile.propTypes = {
  fullName: PropTypes.string,
  id: PropTypes.string,
  streetName: PropTypes.string,
  houseNumber: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  showLink: PropTypes.bool,
}

export default GeneralInformationTile
