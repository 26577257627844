import { Table } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileTable.module.css'

/**
 * custom table component for data tables in decision paper tiles
 * with vertical lines between columns and custom cell spacing and alignment
 */
const DecisionPaperTileTable = ({
  className = '',
  children,
  showColumnBorders = true,
  ...otherProps
}) => {
  const { t } = useTranslation('decisionPaper')
  const noDataText = otherProps.noDataText ?? t('components.table.no-data')
  return (
    <div className={styles.wrapper}>
      <Table
        {...otherProps}
        className={`${styles.table} ${className}`}
        data-show-column-borders={showColumnBorders}
        noDataText={noDataText}
      >
        {children}
      </Table>
    </div>
  )
}

DecisionPaperTileTable.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  showColumnBorders: PropTypes.bool,
  noDataText: PropTypes.string,
}

export default DecisionPaperTileTable
