import { Card } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { kpisAllowedOperations } from 'api/kpis/kpisAllowedOperations'
import ConfigurableComponentsPage from 'components/ui/configurable-components-page/ConfigurableComponentsPage'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { availableDynamicPagesEnum } from 'hooks/page-config/useGetPageConfig'
import useKpis from 'hooks/services/kpis/useKpis'
import KpiPermissionWrapper from 'routes/kpis/KpiPermissionWrapper'
import { PropertyContext } from 'routes/properties/PropertyContext'
import PropertyPage from 'routes/properties/PropertyPage'

const PropertyKpiDetailsPage = ({
  propertyDescription,
  breadcrumbs = [],
  status,
  additionalStatuses,
}) => {
  const { property } = useContext(PropertyContext)

  const {
    data: { kpis = [] } = {},
    isError,
    isFetching,
  } = useKpis(cwpEntityTypes.PROPERTY, property.uuid)

  const subtitle = <EntityTypeAndIdWithClipboard id={property.id} />

  const { kpiId } = useParams()
  const kpi = kpis.find(({ id }) => id === kpiId)

  const childBreadcrumb = {
    text: kpi?.name ?? kpiId,
  }

  const additionalBreadcrumbs = breadcrumbs.map((breadCrumb) => ({ ...breadCrumb }))
  additionalBreadcrumbs.push(childBreadcrumb)

  return (
    <ConfigurableComponentsPage
      pageCode={availableDynamicPagesEnum.PropertiesKeyPerformanceIndicatorsDetails}
      pageData={{ kpiId }}
      renderContent={({ children }) => (
        <PropertyPage
          pageTitle={propertyDescription}
          id={property.id}
          subtitle={subtitle}
          breadcrumbs={additionalBreadcrumbs}
          status={status}
          additionalStatuses={additionalStatuses}
        >
          <KpiPermissionWrapper requiredOperation={kpisAllowedOperations.readKpi}>
            <RequestStateResolver
              isLoading={isFetching}
              isError={isError}
              errorToDisplay={
                <Card>
                  <ErrorDataUnavailableInContent />
                </Card>
              }
              renderContent={() => children}
            />
          </KpiPermissionWrapper>
        </PropertyPage>
      )}
    />
  )
}

PropertyKpiDetailsPage.propTypes = {
  propertyDescription: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ),
  status: PropTypes.shape({
    text: PropTypes.string.isRequired,
    valueState: PropTypes.string,
  }),
  additionalStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string.isRequired,
      valueState: PropTypes.string,
    }),
  ),
}

export default PropertyKpiDetailsPage
