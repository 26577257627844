import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useCovenantBreaches from 'hooks/services/deals/covenants/useCovenantBreaches'

const EMPTY = ''

const ResultOfBreachSelect = ({ value, onChange, ...props }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.covenants' })
  const { data: { breaches = [] } = {}, isLoading, isError } = useCovenantBreaches()
  const breachesWithoutDeprecated = breaches.filter(({ deprecated }) => !deprecated)
  const deprecatedBreaches = breaches.filter(({ deprecated }) => !!deprecated)

  useEffect(() => {
    if (
      !isEmpty(
        deprecatedBreaches.find(
          ({ configCovenantBreachResultUuid }) => configCovenantBreachResultUuid === value,
        ),
      )
    ) {
      // whenever a deprecated breach is set, it will be changed to EMPTY
      onChange(EMPTY)
    }
  }, [deprecatedBreaches, onChange, value])

  const isSelected = (key) => value === key

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      error={t('data-error')}
      renderContent={() => (
        <Select
          id="breach-select"
          onChange={(event) => {
            onChange(event.detail.selectedOption.value)
          }}
          value={value}
          {...props}
        >
          <Option key={'empty'} value={EMPTY} selected={isSelected(EMPTY)}>
            {EMPTY}
          </Option>
          {breachesWithoutDeprecated.map(({ configCovenantBreachResultUuid, name }) => (
            <Option
              key={configCovenantBreachResultUuid}
              value={configCovenantBreachResultUuid}
              selected={isSelected(configCovenantBreachResultUuid)}
            >
              {name}
            </Option>
          ))}
        </Select>
      )}
    />
  )
}

ResultOfBreachSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default ResultOfBreachSelect
