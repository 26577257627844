import { ButtonDesign, ObjectPageSection } from '@fioneer/ui5-webcomponents-react'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import useDecisionStageName from 'components/domains/business-events-and-tasks/common/useDecisionStageName'
import DecisionStageDetailPage from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageDetailPage'
import DecisionStageEmptyPage from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageEmptyPage'
import DecisionStageConditionsPageLoadingWrapper from 'components/domains/business-events-and-tasks/decision-stage/conditions/DecisionStageConditionsPageLoadingWrapper'
import DecisionStageMinutesPageLoadingWrapper from 'components/domains/business-events-and-tasks/decision-stage/minutes/DecisionStageMinutesPageLoadingWrapper'
import LoadingButton from 'components/ui/button/LoadingButton'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useDecisionStage from 'hooks/services/business-events-and-tasks/decision-process/stages/useDecisionStage'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import BusinessEventsAndTasksPage from 'routes/business-events-and-tasks/BusinessEventsAndTasksPage'
import paths from 'routes/paths'

const stageDetailKey = 'stage-detail'
const conditionsKey = 'conditions'
const minutesKey = 'minutes'

const DecisionStage = () => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-stage',
  })

  const {
    allowedOperations,
    event: {
      id: eventId,
      info: { name: eventName },
    },
  } = useContext(BusinessEventsAndTasksContext)

  const { decisionStageId } = useParams()
  const {
    isError: isDecisionStageError,
    isLoading: isDecisionStageLoading,
    data: decisionStageData,
  } = useDecisionStage({ eventId, decisionStageId })

  const decisionStageDisplayName = useDecisionStageName({
    name: decisionStageData?.info.name ?? '',
    decisionStageType: decisionStageData?.info.decisionType ?? '',
  })

  const breadcrumbs = useMemo(
    () => [
      {
        text: tNoPrefix('breadcrumbs.business-events-and-tasks'),
        href: `/${paths.businessEventsAndTasks}`,
      },
      {
        text: eventName,
        href: `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}`,
      },
      {
        text: tNoPrefix('breadcrumbs.business-events-and-tasks.decision-process'),
        href: `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}/decision-process`,
      },
      {
        text: decisionStageData ? decisionStageDisplayName : '',
      },
    ],
    [decisionStageData, decisionStageDisplayName, eventName, eventId, tNoPrefix],
  )

  const handleExportClick = useCallback(() => {
    const exportQueryParams = new URLSearchParams()
    exportQueryParams.append('event_id', eventId)
    exportQueryParams.append('stage_id', decisionStageId)
    window.open(`/${paths.decisionStagePdfExport}?${exportQueryParams.toString()}`)
  }, [eventId, decisionStageId])

  const decisionStageReadAllowed = useMemo(
    () => allowedOperations?.includes(businessEventAllowedOperations.readDecisionStage),
    [allowedOperations],
  )

  const printButton = useMemo(
    () => (
      <LoadingButton
        icon={'print'}
        design={ButtonDesign.Default}
        onClick={handleExportClick}
        isError={isDecisionStageError || !decisionStageReadAllowed}
        isLoading={isDecisionStageLoading}
        renderContent={() => tNoPrefix('buttons.print')}
      />
    ),
    [
      decisionStageReadAllowed,
      handleExportClick,
      isDecisionStageError,
      isDecisionStageLoading,
      tNoPrefix,
    ],
  )

  const hasDecisionStageMinutes = decisionStageData?.minutes.hasMinutes
  const hasDecisionStageConditions = decisionStageData?.conditions.hasConditions

  const isEmptyPage =
    !decisionStageReadAllowed ||
    isDecisionStageError ||
    (!hasDecisionStageMinutes && !hasDecisionStageConditions)

  const renderedDecisionStagePage = useMemo(() => {
    if (isDecisionStageError || !decisionStageReadAllowed) {
      return <DecisionStageEmptyPage isPermissionError={!decisionStageReadAllowed} />
    }
    if (hasDecisionStageMinutes || hasDecisionStageConditions) {
      const objectPages = [
        <ObjectPageSection key={stageDetailKey} id={stageDetailKey} titleText={t('tabs.stage')}>
          <DecisionStageDetailPage decisionStage={decisionStageData} />
        </ObjectPageSection>,
      ]
      if (hasDecisionStageConditions) {
        objectPages.push(
          <ObjectPageSection
            key={conditionsKey}
            id={conditionsKey}
            titleText={t('tabs.conditions')}
          >
            <DecisionStageConditionsPageLoadingWrapper decisionStageId={decisionStageData.id} />
          </ObjectPageSection>,
        )
      }
      if (hasDecisionStageMinutes) {
        objectPages.push(
          <ObjectPageSection key={minutesKey} id={minutesKey} titleText={t('tabs.minutes')}>
            <DecisionStageMinutesPageLoadingWrapper decisionStageId={decisionStageData.id} />
          </ObjectPageSection>,
        )
      }
      return objectPages
    }

    return <DecisionStageDetailPage decisionStage={decisionStageData} />
  }, [
    decisionStageData,
    decisionStageReadAllowed,
    t,
    hasDecisionStageConditions,
    hasDecisionStageMinutes,
    isDecisionStageError,
  ])

  const renderDecisionStagePage = useCallback(
    () => renderedDecisionStagePage,
    [renderedDecisionStagePage],
  )

  // The TabPage requires that ObjectPageSection is a direct child of the TabPage,
  // therefore I have to seperate it from the LoadingStateWrapper
  return (
    <BusinessEventsAndTasksPage
      breadcrumbs={breadcrumbs}
      isEmpty={isEmptyPage}
      defaultSectionId={stageDetailKey}
      actions={printButton}
    >
      {isDecisionStageLoading && (
        <LoadingStateWrapper
          renderContent={renderDecisionStagePage}
          isLoading={isDecisionStageLoading}
          isError={false}
        />
      )}
      {!isDecisionStageLoading && renderedDecisionStagePage}
    </BusinessEventsAndTasksPage>
  )
}

export default DecisionStage
