import { useMemo } from 'react'
import { isNotFoundError } from 'api/requests'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useDealProperties from 'hooks/services/deals/properties/useDealProperties'
import usePropertyRentRollOverviewExpiringLeasesChartData from 'hooks/services/properties/kpis/charts/usePropertyRentRollOverviewExpiringLeasesChartData'
import useAnnualizedCurrentRentPrimaryMetric from 'hooks/services/properties/kpis/useAnnualizedCurrentRentPrimaryMetric'
import useCurrentMultiPropertyKpis from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpis'
import { useMultiPropertyViewByPropertyUuids } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuids'
import { useProperty } from 'hooks/services/properties/useProperty'

const useTenancyOverviewExpiringLeases = ({ entityRef: { entityId: dealUuid } }, _, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({ dealUuid, tileCode })

  const dealPropertiesResult = useDealProperties({
    dealUuid: dealUuidByTileCode,
  })

  const isPropertyRequestEnabled = useMemo(
    () =>
      !dealPropertiesResult.isFetching &&
      !dealPropertiesResult.isError &&
      !!dealPropertiesResult?.data &&
      dealPropertiesResult?.data?.dealProperties?.length > 0,
    [dealPropertiesResult],
  )

  const propertyUuidList = useMemo(
    () =>
      isPropertyRequestEnabled
        ? dealPropertiesResult.data.dealProperties?.map((property) => property.propertyUuid)
        : [],
    [dealPropertiesResult, isPropertyRequestEnabled],
  )

  const isMultiProperty = propertyUuidList.length > 1

  const currentPropertiesKpis = useCurrentMultiPropertyKpis(
    propertyUuidList,
    { withTenantGranularity: true },
    { enabled: isPropertyRequestEnabled },
  )

  const primaryMetric = useAnnualizedCurrentRentPrimaryMetric(currentPropertiesKpis.data)
  const keyDate = currentPropertiesKpis.data?.keyDate

  const { currentRent, expiringLeases, currency, isEmpty, todayXAxisPosition } =
    usePropertyRentRollOverviewExpiringLeasesChartData(currentPropertiesKpis.data)

  const propertyResults = useProperty(propertyUuidList[0], true, {
    enabled: isPropertyRequestEnabled && !isMultiProperty && !!propertyUuidList[0],
  })

  const propertyId = useMemo(() => propertyResults?.data?.id, [propertyResults])

  const multiPropertyViewResponse = useMultiPropertyViewByPropertyUuids(propertyUuidList, {
    enabled: isMultiProperty,
  })
  const multiPropertyViewId = useMemo(
    () => multiPropertyViewResponse?.data?.data?.uuid,
    [multiPropertyViewResponse],
  )

  const sourcePath = useMemo(
    () =>
      isMultiProperty
        ? `/properties/portfolio/rent-roll?portfolio-view-id=${multiPropertyViewId}`
        : `/properties/${propertyId}/rent-roll`,
    [isMultiProperty, multiPropertyViewId, propertyId],
  )

  return useMemo(() => {
    const isLoading =
      isDealUuidFetching ||
      dealPropertiesResult.isFetching ||
      propertyResults.isFetching ||
      currentPropertiesKpis.isFetching ||
      multiPropertyViewResponse.isFetching

    const isError =
      isDealUuidError ||
      dealPropertiesResult.isError ||
      (!isMultiProperty && propertyResults.isError && !isNotFoundError(propertyResults.error)) ||
      currentPropertiesKpis.isError ||
      (isMultiProperty && multiPropertyViewResponse.isError)

    if (isLoading || isError) {
      return {
        isLoading,
        isError,
        data: undefined,
      }
    }
    if (
      !dealPropertiesResult.isLoading &&
      !dealPropertiesResult.isError &&
      (!dealPropertiesResult.data?.dealProperties ||
        dealPropertiesResult.data?.dealProperties?.length === 0)
    ) {
      return {
        isLoading: false,
        isError: false,
        data: {
          noProperties: true,
        },
      }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        sourceRender: { path: `${sourcePath}` },
        noProperties: false,
        expiringLeasesCardHeaderInfo: { primaryMetric, keyDate },
        expiringLeasesChartInfo: {
          currentRent,
          expiringLeases,
          currency,
          isEmpty,
          todayXAxisPosition,
        },
      },
    }
  }, [
    isDealUuidFetching,
    dealPropertiesResult.isFetching,
    dealPropertiesResult.isError,
    dealPropertiesResult.isLoading,
    dealPropertiesResult.data?.dealProperties,
    isMultiProperty,
    propertyResults.isFetching,
    propertyResults.isError,
    propertyResults.error,
    currentPropertiesKpis.isFetching,
    currentPropertiesKpis.isError,
    multiPropertyViewResponse.isFetching,
    multiPropertyViewResponse.isError,
    isDealUuidError,
    sourcePath,
    primaryMetric,
    keyDate,
    currentRent,
    expiringLeases,
    currency,
    isEmpty,
    todayXAxisPosition,
  ])
}

export default useTenancyOverviewExpiringLeases
