import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionsMassEditOperations } from 'api/conditions/conditions'
import LoadingDropdown from 'components/ui/dropdown/LoadingDropdown'
import useInternalTypes from 'hooks/services/conditions/config/useInternalTypes'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const ConditionsMassEditInternalTypeSelect = ({ onChange, disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.dialogs.mass-edit',
  })

  const {
    entityRef: { entityType },
  } = useContext(ConditionsContext)

  const { isLoading, isError, data: typesData } = useInternalTypes({ entityType })

  const keepSelection = useMemo(
    () => ({
      code: conditionsMassEditOperations.keep,
      displayName: t(`operations.${conditionsMassEditOperations.keep}`),
    }),
    [t],
  )

  const loader = useCallback(
    () => ({
      isError,
      isLoading,
      data: [
        keepSelection,
        ...(typesData
          ? typesData.types.map(({ code, name }) => ({ code, displayName: name }))
          : []),
      ],
    }),
    [isLoading, isError, typesData, keepSelection],
  )

  const handleChange = useCallback(
    (selection) => {
      if (selection === conditionsMassEditOperations.keep) {
        onChange({ operation: conditionsMassEditOperations.keep })
        return
      }
      onChange({ operation: conditionsMassEditOperations.replace, value: selection })
    },
    [onChange],
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label>{t('labels.type')}</Label>
      <LoadingDropdown
        id={'conditions-mass-edit-type-select'}
        useLoader={loader}
        onChange={handleChange}
        value={keepSelection.code}
        disabled={disabled}
      />
    </FlexBox>
  )
}

ConditionsMassEditInternalTypeSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default ConditionsMassEditInternalTypeSelect
