import { useRequest } from 'hooks/services/baseService'

/**
 * Returns all segments by properte uuid
 * @returns {{isLoading: boolean, isError: boolean, data: Segments}}
 */
export const useSegments = ({ propertyUuid, preferredCurrency = null }, options) => {
  const queryParams = new URLSearchParams()
  if (preferredCurrency) queryParams.append('preferredCurrency', preferredCurrency)
  let path = `/properties/${propertyUuid}/segments`
  const queryParamsAsString = queryParams.toString()
  if (queryParamsAsString) {
    path = path + `?${queryParams.toString()}`
  }
  const request = useRequest({
    path,
    translated: true,
    keys: ['segments', propertyUuid, preferredCurrency],
    options,
  })
  return request
}
