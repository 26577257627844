import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import AnnualReviewDealOverviewPropertySelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-selection/AnnualReviewDealOverviewPropertySelect'
import AnnualReviewPropertySelectPdfView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-selection/AnnualReviewPropertySelectPdfView'
import TenancyOverviewOccupancyChartWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/occupancy/TenancyOverviewOccupancyChartWrapper'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/occupancy/TenancyOverviewOccupancyTile.module.css'
import {
  useAreaMeasurementUnitFormatter,
  useNumberFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared/hooks/i18n/useI18n'
import useAnnualReviewDealOverviewSelectedProperty from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/utils/useAnnualReviewDealOverviewSelectedProperty'
import { ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/constants'
import CardHeaderWithMetrics from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/card/CardHeaderWithMetrics'
import { TenantColorProvider } from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/TenantColorContext'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const getOccupancyCardHeaderData = (data, formatNumber, formatAreaUnit) => {
  const { value: totalAreaValue, measurementUnit: areaUnit } = data?.totalAreaSurface ?? {}
  const referenceDate = data?.keyDate
  const vacancyAreaValue = data?.vacancySurface?.value
  const occupancyRate = (totalAreaValue - vacancyAreaValue) / totalAreaValue

  const formattedOccupancyRatePercentage = Number.isFinite(occupancyRate)
    ? formatNumber(occupancyRate * 100)
    : ''

  const formattedArea =
    totalAreaValue && areaUnit && `${formatNumber(totalAreaValue)} ${formatAreaUnit(areaUnit)}`

  return {
    occupancyRate: occupancyRate ? formattedOccupancyRatePercentage : undefined,
    referenceDate: referenceDate,
    area: totalAreaValue ? formattedArea : undefined,
  }
}

const TenancyOverviewOccupancyTile = ({ tileId, selectedDealIndex, isPdfView }) => {
  const dispatch = useDispatch()
  const formatNumber = useNumberFormatter({ maximumFractionDigits: 2, minimumFractionDigits: 2 })
  const formatAreaUnit = useAreaMeasurementUnitFormatter()

  const {
    data: { propertyRentRollOverviewByDealUuid, businessPartnerData, dealsData },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const selectedDealUuid = dealsData[selectedDealIndex]?.dealUuid

  const dealData =
    Object.values(propertyRentRollOverviewByDealUuid).find(
      ({ dealUuid }) => dealUuid === selectedDealUuid,
    ) ?? {}

  const { properties } = dealData

  const { selectedPropertyUuid, selectedPropertyDisplayId, selectedPropertyName } =
    useAnnualReviewDealOverviewSelectedProperty({
      propertiesData: properties,
      propertyMetadataTileCode: ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES.TENANCY_RENT_ROLL,
    })

  useEffect(() => {
    dispatch(setSourceRender({ tileId, sourceRender: { propertyId: selectedPropertyDisplayId } }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPropertyDisplayId])

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.property.rent-roll.overview.occupancy',
  })

  const renderContent = useCallback(
    (currentProperty) => {
      const currentPropertyUuid = currentProperty?.uuid

      const propertyData =
        dealData.propertyKpis?.find(({ propertyUuid }) => propertyUuid === currentPropertyUuid)
          ?.keyDateToRentRollKpis?.[0] ?? {}

      const propertyKpiData = { keyDate: propertyData.keyDate, ...propertyData.kpis }

      const { occupancyRate, referenceDate, area } = getOccupancyCardHeaderData(
        propertyKpiData,
        formatNumber,
        formatAreaUnit,
      )

      return (
        <div key={currentProperty?.uuid}>
          {isPdfView ? (
            <AnnualReviewPropertySelectPdfView {...currentProperty} />
          ) : (
            <AnnualReviewDealOverviewPropertySelect
              propertiesData={properties}
              propertyMetadataTileCode={
                ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES.TENANCY_RENT_ROLL
              }
            />
          )}
          <DecisionPaperTileListSeparator />
          <div className={styles.marginTop}>
            <CardHeaderWithMetrics
              title={t('title')}
              primaryMetric={{
                label: t('subtitle'),
                value: occupancyRate,
                unit: '%',
              }}
              secondaryMetrics={[
                {
                  label: t('area'),
                  value: area,
                },
              ]}
              referenceDate={referenceDate}
              isOnDecisionPaper={true}
            />
          </div>
          <DecisionPaperTileListSeparator />
          <TenantColorProvider>
            <div className={styles.spacingWrapper}>
              <TenancyOverviewOccupancyChartWrapper
                propertyKpiData={propertyKpiData}
                businessPartnerData={businessPartnerData}
              />
            </div>
          </TenantColorProvider>
        </div>
      )
    },
    [
      businessPartnerData,
      dealData.propertyKpis,
      formatAreaUnit,
      formatNumber,
      isPdfView,
      properties,
      t,
    ],
  )

  return isPdfView
    ? properties?.map((property) => renderContent(property))
    : renderContent({
        uuid: selectedPropertyUuid,
        id: selectedPropertyDisplayId,
        description: selectedPropertyName,
      })
}

TenancyOverviewOccupancyTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default TenancyOverviewOccupancyTile
