import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import snakecaseKeys from 'snakecase-keys'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import PropertyValuationResultsHistoryChart from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-history/PropertyValuationResultsHistoryChart'
import PropertyValuationResultsHistoryChartHeader from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-history/PropertyValuationResultsHistoryChartHeader'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import RiskMonitoringDealSpecificsPropertySelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/deal-specifics/RiskMonitoringDealSpecificsPropertySelect'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/deal-specifics/RiskMonitoringDealSpecificsValuationHistoryV2.module.css'
import MonitoredRiskIndicatorPropertySelectPdfView from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/deal-specifics/shared/MonitoredRiskIndicatorPropertySelectPdfView'
import { setSourceRender, setTileMetadata } from 'redux/slices/decision-paper/tilesOverviewSlice'

const RiskMonitoringDealSpecificsValuationHistoryV2 = ({
  tileId,
  selectedDealIndex,
  isPdfView = false,
}) => {
  const tileData = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const { multipleArrayPropertyUuidsData = [], multiplePropertyValuationsData = [] } = useMemo(
    () => tileData?.data ?? {},
    [tileData?.data],
  )

  const dispatch = useDispatch()

  // Freezing forces camelization onto all object keys, which breaks some re-used data access code.
  // Since the original data is snake case, this solves freezing issues.
  const propertiesData = useMemo(
    () =>
      snakecaseKeys(multipleArrayPropertyUuidsData?.[selectedDealIndex]?.data?.properties ?? []),
    [multipleArrayPropertyUuidsData, selectedDealIndex],
  )

  const {
    uuid: preSelectedPropertyUuid,
    description: preSelectedPropertyName,
    id: preSelectedPropertyId,
  } = propertiesData?.[0] ?? {}

  // although this is a hook, eslint still recognizes this as a false usage within useEffect
  const propertyMetadata = useSelector(
    (state) =>
      state.decisionPaper.tilesOverview.tileMetadata?.[
        availableAutomaticTiles.monitoredRiskIndicatorDealSpecificsValuationHistory
      ],
  ) ?? {
    selectedPropertyUuid: preSelectedPropertyUuid,
    selectedPropertyName: preSelectedPropertyName,
    selectedPropertyId: preSelectedPropertyId,
  }

  const [currentPropertyMetadata, setCurrentPropertyMetadata] = useState(propertyMetadata)

  // if a different deal is selected, we also need to reset the property metadata,
  // since it is not directly updated by its wn select onChange
  useEffect(() => {
    const { uuid, description, id } = propertiesData?.[0] ?? {}

    setCurrentPropertyMetadata({
      selectedPropertyUuid: uuid,
      selectedPropertyName: description,
      selectedPropertyId: id,
    })
  }, [propertiesData])

  useEffect(() => {
    dispatch(
      setSourceRender({
        tileId,
        sourceRender: { propertyId: currentPropertyMetadata?.selectedPropertyId },
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPropertyMetadata?.selectedPropertyId])

  const handleOnChange = useCallback(
    (_event, selectedPropertyUuid) => {
      const { description: selectedPropertyName, id: selectedPropertyId } =
        propertiesData?.find((property) => property?.uuid === selectedPropertyUuid) ?? {}

      setCurrentPropertyMetadata({ selectedPropertyUuid, selectedPropertyName, selectedPropertyId })
      dispatch(
        setTileMetadata({
          tileCode: availableAutomaticTiles.monitoredRiskIndicatorDealSpecificsValuationHistory,
          metadata: { selectedPropertyUuid, selectedPropertyName, selectedPropertyId },
        }),
      )
    },
    [dispatch, propertiesData],
  )

  const renderContent = useCallback(
    (currentProperty) => {
      const { uuid: currentPropertyUuid } = currentProperty ?? {}

      const selectedValuationsData = multiplePropertyValuationsData?.find(
        (property) => property.propertyUuid[0] === currentPropertyUuid,
      )?.cwpValuations

      return (
        <div key={currentProperty?.uuid}>
          {isPdfView ? (
            <MonitoredRiskIndicatorPropertySelectPdfView {...currentProperty} />
          ) : (
            <div className={styles.riskMonitoringPropertySelectionWrapper}>
              <RiskMonitoringDealSpecificsPropertySelect
                propertyMetadata={currentPropertyMetadata}
                propertiesData={propertiesData}
                handleOnChange={handleOnChange}
                isPdfView={isPdfView}
              />
            </div>
          )}
          <DecisionPaperTileListSeparator />
          <PropertyValuationResultsHistoryChartHeader valuationsData={selectedValuationsData} />
          <DecisionPaperTileListSeparator />
          <div className={styles.spacingWrapper}>
            {!!currentProperty?.uuid && (
              <PropertyValuationResultsHistoryChart valuationsData={selectedValuationsData} />
            )}
          </div>
        </div>
      )
    },
    [
      currentPropertyMetadata,
      handleOnChange,
      isPdfView,
      multiplePropertyValuationsData,
      propertiesData,
    ],
  )

  return isPdfView
    ? propertiesData?.map((property) => renderContent(property))
    : renderContent({
        uuid: currentPropertyMetadata?.selectedPropertyUuid,
        id: currentPropertyMetadata?.selectedPropertyId,
        description: currentPropertyMetadata?.selectedPropertyName,
      })
}

RiskMonitoringDealSpecificsValuationHistoryV2.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default RiskMonitoringDealSpecificsValuationHistoryV2
