import { useMemo } from 'react'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useMultipleAggregatedCollateralAgreements from 'hooks/services/collaterals/useMultipleAggregatedCollateralAgreements'

const useCollateralAgreementsByDealMetaData = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { selectedDeal, dealsData, dealUuids } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const { selectedDealDisplayId } = selectedDeal ?? {}

  const {
    data: multiDealCollateralAgreements,
    isLoading: multiCollateralIsLoading,
    isError: multiCollateralIsError,
    error: multiCollateralError,
  } = useMultipleAggregatedCollateralAgreements({
    dealUuids,
  })

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isAnnualReviewDealsLoading, multiCollateralIsLoading],
    errorValues: [isAnnualReviewDealsError, multiCollateralIsError],
    errorDetails: [annualReviewDealsError, multiCollateralError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        multiDealCollateralAgreements,
        sourceRender: {
          dealDisplayId: selectedDealDisplayId,
        },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealsData,
    multiDealCollateralAgreements,
    selectedDealDisplayId,
    error,
  ])
}

export default useCollateralAgreementsByDealMetaData
