import { Input, Label, Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { propertyMonitoringThresholdType } from 'api/property/monitoring/propertyMonitoringConfiguration'
import styles from 'components/domains/markets/detail/cards/cells/proxies/MarketProxiesTableThresholdCell.module.css'
import useFormatPercentageOrValueWithUnit from 'components/domains/properties/monitoring/useFormatPercentageOrValueWithUnit'
import NumberInput from 'components/ui/input/NumberInput'
import { useFormattedNumberParser } from 'hooks/i18n/useI18n'

const MarketProxiesTableThresholdCell = ({ isEditing, onChange, proxySetting, errorState }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.markets.detail.manager.market-proxies',
  })
  const parseNumber = useFormattedNumberParser()
  const formatThreshold = useFormatPercentageOrValueWithUnit()
  const unit = proxySetting.unit
  const isOverride = proxySetting.isOverride
  const defaultThresholdValue = proxySetting.defaultProxy?.threshold
  const defaultThresholdType = proxySetting.defaultProxy?.thresholdType
  const isMonitored = proxySetting.overrideProxy?.isMonitored ?? true
  const thresholdValue =
    isOverride && proxySetting.overrideProxy
      ? proxySetting.overrideProxy?.threshold
      : defaultThresholdValue
  const thresholdType =
    isOverride && proxySetting.overrideProxy
      ? proxySetting.overrideProxy.thresholdType
      : defaultThresholdType

  const handleThresholdChange = useCallback(
    ({ target: { value } }) => {
      onChange(proxySetting.key, value)
    },
    [onChange, proxySetting.key],
  )

  const unitText = thresholdType === propertyMonitoringThresholdType.relative ? '%' : unit

  if (!isEditing) {
    return (
      <Text>
        {formatThreshold({
          value: parseNumber(thresholdValue),
          type: thresholdType,
          unit,
        })}
      </Text>
    )
  }

  if (!isOverride || !isMonitored) {
    return (
      <div className={styles.thresholdWrapper}>
        <Input value={thresholdValue ?? '-'} readonly />
        <Text>{unitText}</Text>
      </div>
    )
  }

  const valueState = errorState.hasError && errorState.touched ? ValueState.Error : ValueState.None

  return (
    <>
      <div className={styles.thresholdWrapper}>
        <NumberInput
          onBlur={handleThresholdChange}
          valueState={valueState}
          valueStateMessage={<Text>{t('error-messages.threshold-value')}</Text>}
          value={thresholdValue ?? ''}
        />
        <Text>{unitText}</Text>
      </div>
      <Label showColon>{t('default-value')}</Label>{' '}
      <Text>
        {formatThreshold({
          value: parseNumber(defaultThresholdValue),
          type: defaultThresholdType,
          unit,
        })}
      </Text>
    </>
  )
}

MarketProxiesTableThresholdCell.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  proxySetting: PropTypes.shape({
    key: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    isOverride: PropTypes.bool.isRequired,
    defaultProxy: PropTypes.shape({
      threshold: PropTypes.string,
      thresholdType: PropTypes.string,
    }),
    overrideProxy: PropTypes.shape({
      threshold: PropTypes.string,
      thresholdType: PropTypes.string,
      isMonitored: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  errorState: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
  }).isRequired,
}

export default MarketProxiesTableThresholdCell
