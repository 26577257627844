import { Button, ButtonDesign, Icon, IconDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import useAnalyticalTableIndexHelper from 'hooks/services/conditions/tables/useAnalyticalTableIndexHelper'
import { copyCondition } from 'redux/slices/conditions/conditionCreationSlice'

const ConditionCreationCopyCell = ({ row: { id, index, depth } }) => {
  const dispatch = useDispatch()
  const { isConditionsCell, conditionIndex } = useAnalyticalTableIndexHelper({
    id,
    index,
    depth,
  })
  const onItemClick = useCallback(() => {
    dispatch(copyCondition(conditionIndex))
  }, [conditionIndex, dispatch])

  if (!isConditionsCell) {
    return
  }

  return (
    <Button design={ButtonDesign.Transparent} onClick={onItemClick}>
      <Icon name="duplicate" design={IconDesign.Neutral} />
    </Button>
  )
}

ConditionCreationCopyCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    depth: PropTypes.number.isRequired,
    original: PropTypes.shape({
      assignee: PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
}

export default ConditionCreationCopyCell
