import { ButtonDesign, ResponsiveGridLayout } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from 'components/domains/business-events-and-tasks/events/dialog/EventCreationDialog.module.css'
import EventCreationEntityForm from 'components/domains/business-events-and-tasks/events/dialog/shared/EventCreationEntityForm'
import EventCreationEventTemplates from 'components/domains/business-events-and-tasks/events/dialog/shared/EventCreationEventTemplates'
import LoadingButton from 'components/ui/button/LoadingButton'
import Dialog, { DialogSize } from 'components/ui/dialog/Dialog'
import useGetEventDefaultAssignees from 'hooks/services/business-events-and-tasks/events/useGetEventDefaultAssignees'

import paths from 'routes/paths'

const EventCreationDialog = ({ isOpen, setIsOpen, preselectedEntity, createInNewTab = false }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dialog.event-creation' })
  const navigate = useNavigate()
  const [selectedEntityType, setSelectedEntityType] = useState('')
  const [selectedEntityId, setSelectedEntityId] = useState()
  const [isSelectedEntityWorkingVersion, setIsSelectedEntityWorkingVersion] = useState(false)
  const [selectedEventType, setSelectedEventType] = useState('')
  const [isCreateButtonClicked, setIsCreateButtonClicked] = useState(false)
  const { isLoading: isDefaultAssigneesLoading } = useGetEventDefaultAssignees(
    { eventCode: selectedEventType, entityType: selectedEntityType, entityId: selectedEntityId },
    { enabled: isCreateButtonClicked },
  )
  useEffect(() => {
    if (preselectedEntity) {
      setSelectedEntityId(preselectedEntity.entityId)
      setSelectedEntityType(preselectedEntity.entityType)
    }
  }, [preselectedEntity])

  useEffect(() => {
    if (isCreateButtonClicked && !isDefaultAssigneesLoading) {
      const createPath = `/${paths.businessEventsAndTasks}/${paths.businessEvents}/create?entity_type=${selectedEntityType}&entity_id=${selectedEntityId}&event_type=${selectedEventType}`
      if (createInNewTab) {
        setIsOpen(false)
        window.open(createPath, '_blank')
      } else {
        navigate(createPath)
      }
    }
  }, [
    createInNewTab,
    isCreateButtonClicked,
    isDefaultAssigneesLoading,
    navigate,
    selectedEntityId,
    selectedEntityType,
    selectedEventType,
    setIsOpen,
  ])

  const handleCreate = () => {
    setIsCreateButtonClicked(true)
  }

  const isCreateButtonDisabled = !selectedEntityType || !selectedEntityId || !selectedEventType
  const renderPrimaryButtonContent = useCallback(() => t('button.create'), [t])

  return (
    <Dialog
      open={isOpen}
      size={DialogSize.L}
      headerText={t('title')}
      onAfterClose={() => setIsOpen(false)}
      primaryButton={
        <LoadingButton
          isLoading={isCreateButtonClicked}
          onClick={handleCreate}
          design={ButtonDesign.Emphasized}
          disabled={isCreateButtonDisabled}
          renderContent={renderPrimaryButtonContent}
        />
      }
    >
      <ResponsiveGridLayout
        columnsXL={1}
        columnsL={1}
        columnsM={1}
        columnsS={1}
        columnGap="1rem"
        rowGap="16px"
        {...(isCreateButtonClicked && { inert: '' })}
        className={isCreateButtonClicked ? styles.disabled : ''}
      >
        <EventCreationEntityForm
          setSelectedEntityId={setSelectedEntityId}
          setSelectedEventType={setSelectedEventType}
          selectedEntityType={selectedEntityType}
          setSelectedEntityType={setSelectedEntityType}
          preselectedEntity={preselectedEntity}
          setIsSelectedEntityWorkingVersion={setIsSelectedEntityWorkingVersion}
        />
        <div className={styles['label']}>{t('event-template')}</div>
        <EventCreationEventTemplates
          selectedEntityId={selectedEntityId || ''}
          selectedEntityType={selectedEntityType}
          isSelectedEntityWorkingVersion={isSelectedEntityWorkingVersion}
          setSelectedEventType={setSelectedEventType}
        />
      </ResponsiveGridLayout>
    </Dialog>
  )
}

EventCreationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  preselectedEntity: PropTypes.shape({
    entityDisplayId: PropTypes.string,
    entityName: PropTypes.string,
    entityId: PropTypes.string,
    entityType: PropTypes.string,
    isWorkingVersion: PropTypes.bool,
  }),
  createInNewTab: PropTypes.bool,
}

export default EventCreationDialog
