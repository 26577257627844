import { ObjectStatus, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const EMPTY_STRING = ''

const PropertyStatusCell = ({ statusCode, objectStatusMap }) => {
  const { t } = useTranslation()

  return objectStatusMap[statusCode] ? (
    <ObjectStatus inverted state={objectStatusMap[statusCode].valueState}>
      {t(objectStatusMap[statusCode].text)}
    </ObjectStatus>
  ) : (
    <Text>{EMPTY_STRING}</Text>
  )
}

PropertyStatusCell.propTypes = {
  statusCode: PropTypes.string,
  objectStatusMap: PropTypes.shape({
    valueState: PropTypes.string,
    text: PropTypes.string,
  }),
}

export default PropertyStatusCell
