import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { Fragment, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Text,
  XAxis,
  YAxis,
} from 'recharts'
import styles from 'components/domains/properties/rent-roll/overview/wault/PropertyRentRollOverviewWaultChart.module.css'
import sharedChartStyles from 'components/ui/charts/shared-chart-styles.module.css'
import { useNumberFormatter } from 'hooks/i18n/useI18n'
import useChartAnimation from 'hooks/services/properties/kpis/charts/useChartAnimation'

const MIN_BAR_WIDTH = 15
const BAR_SCALE_FACTOR = 250
const WAULT_BREAK_LABEL_MIN_BAR_LENGTH = 0.1
export const Y_AXIS_LABEL_DEFAULT_MAX_WIDTH = 120
const Y_AXIS_LABEL_RECHARTS_DEFAULT_WIDTH = 60
const Y_AXIS_LABEL_OFFSET = 8

const measureRenderedLabelWidth = (labelText) => {
  let textContainer = document.createElement('div')

  textContainer.style.fontSize = '12px'
  textContainer.style.fontFamily = '"72"'
  textContainer.style.whiteSpace = 'nowrap'
  textContainer.style.position = 'absolute'
  textContainer.style.left = -1000
  textContainer.style.top = -1000
  textContainer.textContent = labelText

  document.body.appendChild(textContainer)

  const width = Math.ceil(textContainer.getBoundingClientRect().width)

  document.body.removeChild(textContainer)
  textContainer = null

  return width
}

const getBarSize = (rentShare) => Math.max(MIN_BAR_WIDTH, rentShare * BAR_SCALE_FACTOR)

const useWaultChartXAxisConfig = ({ maxVaultValue, referenceDate: referenceDateISO }) =>
  useMemo(() => {
    const referenceDate = DateTime.fromISO(referenceDateISO)

    const toAxisValue = (dateTime) => dateTime.diff(referenceDate, 'years').toObject().years

    const xAxisEndDate = referenceDate.plus({ years: maxVaultValue + 1 }).startOf('year')

    const xAxisEndValue = toAxisValue(xAxisEndDate)

    const domain = [0, xAxisEndValue ?? Math.ceil(maxVaultValue)]

    const ticks = []
    const tickLabels = []

    for (let year = xAxisEndDate.year; year >= referenceDate.year; year--) {
      const firstDayOfYear = DateTime.fromObject({ year })
      if (firstDayOfYear >= referenceDate) {
        ticks.push(firstDayOfYear.diff(referenceDate, 'years').years)
        tickLabels.push(`${year}`)
      }
    }

    tickLabels.sort((a, b) => a.localeCompare(b))
    ticks.sort((a, b) => a - b)

    const tickFormatter = (value) => tickLabels[ticks.indexOf(value)]

    let todayValue = undefined
    const today = DateTime.now()
    if (today > referenceDate && today <= xAxisEndDate) {
      todayValue = toAxisValue(today)
    }

    return { domain, ticks, tickFormatter, todayValue }
  }, [maxVaultValue, referenceDateISO])

const customLabelListComponentPropTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offset: PropTypes.number,
  formatter: PropTypes.func,
}

/**
 * Due to the limitation of recharts that different data points in a data set
 * can not have variable bar widths, but only different fields in the same data
 * point, we actually only provide one data point to the chart.
 *
 * Therefore the default y-axis labels from recharts' YAXis component don't work.
 * Hence, we need to fake the axis labels by putting another label on the individual bars.
 * By default, these always take the width of the corresponding bar, so the labels
 * would overflow the chart container.
 *
 * We add custom Label component with custom positioning and a fixed width to prevent this.
 */
const createWaultChartYAxisLabel = (maxWidth) => {
  const WaultChartYAxisLabel = ({ x, y, height, value }) => {
    const labelWidth = Math.min(x, maxWidth)
    return (
      <Text
        x={labelWidth}
        y={y + height / 2}
        height={height}
        width={labelWidth}
        textAnchor="end"
        verticalAnchor="middle"
        className={styles.yAxisLabel}
      >
        {value}
      </Text>
    )
  }

  WaultChartYAxisLabel.propTypes = customLabelListComponentPropTypes

  return WaultChartYAxisLabel
}

/**
 * Custom bar label that uses all available space to the right of the bar.
 *
 * By default, the label width would be equal to the bar width, which makes
 * the label wrap when the bar length is small, even though the label is
 * displayed outside of the bar.
 */
const WaultChartExpiryBarLabel = ({ x, y, height, width, value, offset = 0, formatter }) => (
  <Text
    x={x + width + offset}
    y={y + height / 2}
    height={height}
    width={80}
    textAnchor="start"
    verticalAnchor="middle"
    className={styles.barLabelExpiry}
  >
    {formatter(value)}
  </Text>
)

WaultChartExpiryBarLabel.propTypes = customLabelListComponentPropTypes

/**
 * custom bar label that uses all available space inside of the bar and
 * respect the padding (offset) inside the bar.
 *
 * By default, the label would sometimes overflow the bar as the offset
 * is not incorporated in the calculation of the label width.
 */
const WaultChartBreakBarLabel = ({ x, y, height, width, value, offset = 0, formatter }) => (
  <Text
    x={x + width - offset}
    y={y + height / 2}
    height={height}
    width={width - offset * 2}
    textAnchor="end"
    verticalAnchor="middle"
    className={styles.barLabelBreak}
  >
    {formatter(value)}
  </Text>
)

WaultChartBreakBarLabel.propTypes = customLabelListComponentPropTypes

const PropertyRentRollOverviewWaultChart = ({ data, referenceDate, disableAnimation = false }) => {
  const { t: tWault } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.wault',
  })

  const yAxisLabelMaxWidth = useMemo(() => {
    const yAxisLabelWords = data.flatMap(({ name }) => (name || '').split(' '))
    const longestWord = yAxisLabelWords.sort((a, b) => b.length - a.length).at(0)
    if (!longestWord) return 0
    const longestLabelWidth = measureRenderedLabelWidth(longestWord)
    return Math.max(longestLabelWidth, Y_AXIS_LABEL_DEFAULT_MAX_WIDTH)
  }, [data])

  const WaultChartYAxisLabel = useMemo(
    () => createWaultChartYAxisLabel(yAxisLabelMaxWidth),
    [yAxisLabelMaxWidth],
  )

  const animationProps = useChartAnimation(!disableAnimation)

  const formatYear = useNumberFormatter({
    style: 'unit',
    unit: 'year',
    unitDisplay: 'long',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const maxVaultValue = useMemo(
    () =>
      data.reduce(
        (maxValue, { waultBreak, waultExpiry }) => Math.max(maxValue, waultBreak, waultExpiry),
        0,
      ),
    [data],
  )

  const shouldShowWaultBreakLabel = useCallback(
    (entry) => entry.waultBreak / maxVaultValue >= WAULT_BREAK_LABEL_MIN_BAR_LENGTH,
    [maxVaultValue],
  )

  const xAxisConfig = useWaultChartXAxisConfig({
    maxVaultValue,
    referenceDate,
  })

  const legendContent = useMemo(
    () => [
      {
        type: 'square',
        value: tWault('break'),
        id: 'legendWaultBreak',
      },
      {
        type: 'square',
        value: tWault('expiry'),
        id: 'legendWaultExpiry',
      },
    ],
    [tWault],
  )

  return (
    <ResponsiveContainer width="100%" aspect={2.1} maxHeight={400}>
      <BarChart
        className={`${styles.waultChart} ${sharedChartStyles.cwpChart}`}
        data={[data]}
        layout="vertical"
        barGap={10}
        margin={{
          top: 0,
          right: 80,
          left: yAxisLabelMaxWidth - Y_AXIS_LABEL_RECHARTS_DEFAULT_WIDTH + Y_AXIS_LABEL_OFFSET,
          bottom: 0,
        }}
      >
        <XAxis
          type="number"
          axisLine={false}
          tickLine={false}
          domain={xAxisConfig.domain}
          ticks={xAxisConfig.ticks}
          tickFormatter={xAxisConfig.tickFormatter}
        />

        {/* we need 2 y-axes so that the waultBreak and waultExpiry bars 
            can be rendered on top of each other, but the second axis
            is visually hidden */}
        <YAxis type="category" dataKey="name" axisLine={false} tickLine={false} yAxisId={0} />
        <YAxis type="category" dataKey="name" yAxisId={1} hide />

        {/* extend tick lines on the x-axis to the full chart height */}
        <CartesianGrid horizontal={false} />

        <Legend payload={legendContent} />

        {data.map((entry, index) => (
          <Fragment key={entry.name}>
            <Bar
              id={`bar_waultExpiry_${index}`}
              dataKey={`${index}.waultExpiry`}
              yAxisId={0}
              barSize={getBarSize(entry.rentShare)}
              minPointSize={1}
              {...animationProps}
            >
              <Cell fill={entry.color} />
              {/* waultExpiry value label */}
              <LabelList formatter={formatYear} offset={8} content={WaultChartExpiryBarLabel} />
            </Bar>

            {/* the waultBreak bar comes after the waultExpiry bar
                so that it is rendered on top of it */}
            <Bar
              id={`bar_waultBreak_${index}`}
              dataKey={`${index}.waultBreak`}
              yAxisId={1}
              barSize={getBarSize(entry.rentShare)}
              minPointSize={1}
              {...animationProps}
            >
              <Cell fill={entry.color} />
              {/* waultBreak value label */}

              {shouldShowWaultBreakLabel(entry) && (
                <LabelList formatter={formatYear} offset={8} content={WaultChartBreakBarLabel} />
              )}

              {/* "fake" y-axis label */}
              <LabelList dataKey={`${index}.name`} position="left" content={WaultChartYAxisLabel} />
            </Bar>
          </Fragment>
        ))}

        {xAxisConfig.todayValue !== undefined && (
          /* the vertical "today" indicator line must be rendered after the chart bars
              so that it's always displayed on top of them */
          <ReferenceLine x={xAxisConfig.todayValue} className={styles.todayLine} />
        )}

        {/* the vertically striped appearance of the waultExpiry bars and legend shape 
            can not be configured in recharts directly, so we add an SVG mask to the markup
            and apply it to the elements via CSS */}
        <defs>
          <pattern id="striped-bg-pattern" patternUnits="userSpaceOnUse" width="1.5" height="1.5">
            <line x1="0" y="0" x2="0" y2="1.5" stroke="#FFF" strokeWidth="1" />
          </pattern>

          <mask id="striped-bg-mask">
            <rect fill="url(#striped-bg-pattern)" x="0" y="0" width="100%" height="100%" />
          </mask>
        </defs>

        <style>
          {`
            [class*="waultChart"] .legend-item-1 .recharts-surface,
            .recharts-rectangle[id^="bar_waultExpiry"] {
              mask: url('#striped-bg-mask')
            }
        `}
        </style>
      </BarChart>
    </ResponsiveContainer>
  )
}

PropertyRentRollOverviewWaultChart.propTypes = {
  referenceDate: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      rentShare: PropTypes.number.isRequired,
      waultBreak: PropTypes.number.isRequired,
      waultExpiry: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ).isRequired,
  disableAnimation: PropTypes.bool,
}

export default PropertyRentRollOverviewWaultChart
