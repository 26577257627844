import camelize from 'camelize'
import { useMemo } from 'react'
import { useInfiniteRequest } from 'hooks/services/baseService'

export const useEvents = (
  { sortBy, orderBy } = {},
  {
    searchValue,
    assigneeId,
    currentDueDateRange,
    originalDueDateRange,
    status,
    entityType,
    entityId,
    eventCode,
  } = {},
  { limit } = {},
  options,
) => {
  const queryParams = new URLSearchParams()

  const cacheKeys = [
    'events',
    entityType,
    entityId,
    `sortBy-${sortBy}-orderBy-${orderBy}`,
    searchValue,
    assigneeId,
    limit,
    currentDueDateRange,
    originalDueDateRange,
    status,
    eventCode,
  ]

  if (entityType) {
    queryParams.append('entity_type', entityType)
  }
  if (sortBy) {
    queryParams.append('sort_by', sortBy)
    queryParams.append('order_by', orderBy)
  }
  if (searchValue) {
    queryParams.append('search_filter', searchValue)
  }
  if (assigneeId) {
    queryParams.append('assignee', assigneeId)
  }
  if (currentDueDateRange) {
    const currentDueDateFrom = currentDueDateRange.split(' - ')[0]
    const currentDueDateUntil = currentDueDateRange.split(' - ')[1]
    queryParams.append('current_due_date_from', currentDueDateFrom)
    queryParams.append('current_due_date_until', currentDueDateUntil)
  }
  if (originalDueDateRange) {
    const originalDueDateFrom = originalDueDateRange.split(' - ')[0]
    const originalDueDateUntil = originalDueDateRange.split(' - ')[1]
    queryParams.append('original_due_date_from', originalDueDateFrom)
    queryParams.append('original_due_date_until', originalDueDateUntil)
  }
  if (status) {
    status.forEach((singleStatus) => queryParams.append('status', singleStatus))
  }
  if (eventCode) {
    queryParams.append('event_code', eventCode)
  }

  if (entityId) {
    queryParams.append('entity_id', entityId)
  }

  const result = useInfiniteRequest({
    path: `/events`,
    queryParams: queryParams,
    useCache: true,
    keys: cacheKeys,
    limit: limit,
    options,
  })
  const processedResponse = useMemo(() => {
    if (!result.data) return undefined
    return camelize(
      result.data.pages.reduce((acc, { events, total }) => ({
        events: [...acc.events, ...events],
        total,
      })),
    )
  }, [result])

  return { ...result, data: processedResponse }
}
