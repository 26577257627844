import { useMemo } from 'react'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useMultipleFinancingTermsExistingBusiness from 'hooks/services/business-events-and-tasks/decision-papers/tiles/financing-terms/useMultipleFinancingTermsExistingBusiness'

const useAnnualReviewMultipleFinancingTerms = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { dealsData, dealUuids, selectedDeal: { selectedDealDisplayId } = {} } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const {
    data: multipleFinancingTermsDataPerDealUuid,
    isLoading: isMultipleFinancingTermsLoading,
    isError: isMultipleFinancingTermsError,
    error: multipleFinancingTermsError,
  } = useMultipleFinancingTermsExistingBusiness(dealUuids, tileId) ?? {}

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isAnnualReviewDealsLoading, isMultipleFinancingTermsLoading],
    errorValues: [isAnnualReviewDealsError, isMultipleFinancingTermsError],
    errorDetails: [annualReviewDealsError, multipleFinancingTermsError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        multipleFinancingTermsDataPerDealUuid,
        sourceRender: {
          dealDisplayId: selectedDealDisplayId,
        },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealsData,
    multipleFinancingTermsDataPerDealUuid,
    selectedDealDisplayId,
    error,
  ])
}

export default useAnnualReviewMultipleFinancingTerms
