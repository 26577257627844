import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  Table,
  TableCell,
  TableColumn,
  TableRow,
} from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/OpsMemoCollateralsLoanRelationTile.module.css'
import DecisionPaperLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/DecisionPaperLeaveConfirmationLink'
import { DATA_SOURCES } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/financingConstants'
import Entity from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/ui/data/Entity'

const OpsMemoCollateralsLoanRelationTile = ({ tileId, showConfirmationForLinks = false }) => {
  const {
    data: {
      collateralAgreementsData: { collateralAgreements = [] } = {},
      tranchesData = [],
      sourceRender: { dealDisplayId },
    },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.collaterals.collaterals-loan-relation',
  })
  const mapRelationElement = (cag, businessReceivable, isExistingBusiness = false) => {
    const trancheInfo = tranchesData.find(
      (tranche) => tranche?.trancheId === businessReceivable?.financialProductId,
    )
    if (!isNil(trancheInfo)) {
      return {
        cag: { name: cag?.name, id: cag?.id, displayId: cag?.displayId },
        tranche: {
          name: trancheInfo?.trancheName,
          id: trancheInfo?.displayId,
          isExistingBusiness,
        },
      }
    }
  }

  const mappedRelations = compact(
    collateralAgreements.flatMap((cag) =>
      cag.existingBusinessReceivables
        .map((businessReceivable) => mapRelationElement(cag, businessReceivable, true))
        .concat(
          cag.newBusinessReceivables.map((businessReceivable) =>
            mapRelationElement(cag, businessReceivable, false),
          ),
        ),
    ),
  )

  const renderEntityElement = (id, name, link) => {
    if (showConfirmationForLinks) {
      return (
        <DecisionPaperLeaveConfirmationLink
          name={name}
          id={id}
          link={link}
          showConfirmationForLinks
        />
      )
    }
    return <Entity name={name} id={id} link={link} openInNewTab={true} />
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.collateralsLoanRelationWrapper}>
      <Table
        id="collateralsLoanRelationTable"
        noDataText={t('property.no-data')}
        className={styles.collateralsLoanRelationTable}
        columns={
          <>
            <TableColumn>
              <FlexBox justifyContent={FlexBoxAlignItems.Start}>{t('cag-label')}</FlexBox>
            </TableColumn>
            <TableColumn demandPopin minWidth={300} popinText={t('tranche-label')}>
              <FlexBox justifyContent={FlexBoxAlignItems.Start}>{t('tranche-label')}</FlexBox>
            </TableColumn>
          </>
        }
      >
        {mappedRelations.map((relation, index) => {
          if (!isNil(relation)) {
            const { cag, tranche } = relation
            const cagLink = `/deals/${dealDisplayId}/collaterals/${cag.id}`
            const dataSourceQueryParam = `dataSource=${
              tranche.isExistingBusiness
                ? DATA_SOURCES.EXISTING_BUSINESS
                : DATA_SOURCES.NEW_BUSINESS
            }`
            const trancheLink = `/deals/${dealDisplayId}/financing/tranches/${tranche.id}?${dataSourceQueryParam}`
            return (
              <TableRow key={index}>
                <TableCell>
                  <FlexBox
                    justifyContent={FlexBoxAlignItems.Start}
                    direction={FlexBoxDirection.Column}
                  >
                    {renderEntityElement(cag.displayId, cag.name, cagLink)}
                  </FlexBox>
                </TableCell>
                <TableCell>
                  <FlexBox
                    justifyContent={FlexBoxAlignItems.Start}
                    direction={FlexBoxDirection.Column}
                  >
                    {renderEntityElement(tranche.id, tranche.name, trancheLink)}
                  </FlexBox>
                </TableCell>
              </TableRow>
            )
          }
        })}
      </Table>
    </FlexBox>
  )
}

OpsMemoCollateralsLoanRelationTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool,
}

export default OpsMemoCollateralsLoanRelationTile
