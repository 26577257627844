import { useInfiniteRequest } from 'hooks/services/baseService'
import { useInfiniteRequestsWithTotalMemo } from 'hooks/services/queryHelper'

const useEntityChangeLogs = (
  {
    relatedEntityType,
    relatedEntityId,
    entityTypes,
    pageIds,
    sorting: { field: sortField, direction: sortDirection } = {},
    pagination: { limit } = {},
  },
  options,
) => {
  const queryParams = new URLSearchParams()
  if (sortField) {
    queryParams.append('sort_field', sortField)
  }
  if (sortDirection) {
    queryParams.append('sort_direction', sortDirection)
  }
  if (entityTypes) {
    queryParams.append('entity_type', entityTypes.join(','))
  }
  if (pageIds) {
    queryParams.append('page_id', pageIds.join(','))
  }
  const response = useInfiniteRequest({
    path: `/traceability/change-logs/${relatedEntityType}/${relatedEntityId}`,
    queryParams: queryParams,
    translated: true,
    keys: ['traceability', 'change-logs', relatedEntityType, relatedEntityId],
    limit,
    options: {
      staleTime: 1,
      ...options,
    },
  })

  return useInfiniteRequestsWithTotalMemo(response, 'change_logs', 'pagination.total')
}

export default useEntityChangeLogs
