export const PROPOSED = 'Proposed'
export const APPROVED = 'Approved'
export const ACTIVE = 'Active'
export const OUTDATED = 'Outdated'

export const OBSOLETE = 'Obsolete'
export const PERFORMING = 'Performing'
export const NON_PERFORMING = 'NonPerforming'
export const NO_FORBEARANCE = 'NoForbearance'

export const TABLE_TEXT_DEFAULT_HEIGHT = 16
export const TABLE_TEXT_DEFAULT_PADDING = 8

const NUMBER_OF_TEXT_LINES_IN_ROW = 2

export const TABLE_ROW_HEIGHT =
  TABLE_TEXT_DEFAULT_HEIGHT * NUMBER_OF_TEXT_LINES_IN_ROW + TABLE_TEXT_DEFAULT_PADDING * 2

export const TABLE_HEADER_ROW_HEIGHT =
  TABLE_TEXT_DEFAULT_HEIGHT * NUMBER_OF_TEXT_LINES_IN_ROW + TABLE_TEXT_DEFAULT_PADDING * 2
