import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useLimitManagementConfigurations = (options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: '/deals/configurations/limit-management',
      translated: true,
      keys: ['deals', 'configurations', 'limit-management'],
      ...options,
    }),
  )
