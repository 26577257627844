import { Button } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState, useEffect, useRef, useCallback } from 'react'
import { LineChart, ResponsiveContainer } from 'recharts'
import SETTINGS from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/charts/GenericChartSettings'
import {
  calculateZoomTimestamp,
  calculateZoomAreaWidth,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/charts/kpi-chart/kpiChartUtil'

/**
 * Generates chart that displays a linechart with children given by parameter lineChartChildren with data given by param data
 * @param lineChartData array with data used to set up the chart, must be sorted by date
 * @param lineChartChildren children rendered in lineChart
 * @param additionalLineChartArguments arguments that are passed into lineChartChildren
 * @param renderChildrenOutsideLineChart children rendered outside of line chart
 * @param customChartSettings overwrites for the default chart settings
 */
const GenericChart = ({
  lineChartData,
  lineChartChildren,
  additionalLineChartArguments,
  renderChildrenOutsideLineChart,
  customChartSettings = {},
}) => {
  const CHART_SETTINGS = {
    DEFAULT_ZOOM: customChartSettings?.DEFAULT_ZOOM ?? SETTINGS.DEFAULT_ZOOM,
    ASPECT: customChartSettings?.ASPECT ?? SETTINGS.ASPECT,
    MIN_WIDTH: customChartSettings?.MIN_WIDTH ?? SETTINGS.MIN_WIDTH,
    MIN_HEIGHT: customChartSettings?.MIN_HEIGHT ?? SETTINGS.MIN_HEIGHT,
    MAX_HEIGHT: customChartSettings?.MAX_HEIGHT ?? SETTINGS.MAX_HEIGHT,
    GREEN: customChartSettings?.GREEN ?? SETTINGS.GREEN,
    RED: customChartSettings?.RED ?? SETTINGS.RED,
    YAXIS_WIDTH: customChartSettings?.YAXIS_WIDTH ?? SETTINGS.YAXIS_WIDTH,
    STROKE_DASH: customChartSettings?.STROKE_DASH ?? SETTINGS.STROKE_DASH,
    BASELINE_COLOR: customChartSettings?.BASELINE_COLOR ?? SETTINGS.BASELINE_COLOR,
  }

  const chartRef = useRef(null)
  const containerRef = useRef(null)
  const [chartWidth, setChartWidth] = useState(CHART_SETTINGS.MIN_WIDTH)
  const [chartHeight, setChartHeight] = useState(CHART_SETTINGS.MIN_HEIGHT)
  const [containerHeight, setContainerHeight] = useState(0)
  const [isZoomSelectionInProgress, setIsZoomSelectionInProgress] = useState(false)
  const [showZoomOutButton, setShowZoomOutButton] = useState(false)
  const [zoomAreaSelection, setZoomAreaSelection] = useState(CHART_SETTINGS.DEFAULT_ZOOM)
  const [zoomedData, setZoomedData] = useState(lineChartData)

  const calculateInitialUnixTimestamps = () => {
    if (!lineChartData || lineChartData.length === 0) return

    const difference = Math.round(
      (lineChartData[lineChartData.length - 1].dateUnixTimestamp -
        lineChartData[0].dateUnixTimestamp) /
        10,
    )
    return {
      start: lineChartData[0].dateUnixTimestamp - difference,
      end: lineChartData[lineChartData.length - 1].dateUnixTimestamp + difference,
    }
  }

  const [zoomedStartEndUnixTimestamps, setZoomedStartUnixTimestamps] = useState(
    calculateInitialUnixTimestamps(),
  )

  const handleOnMouseDown = (e) => {
    setIsZoomSelectionInProgress(true)
    if (e) {
      if (e.chartX < 0) {
        setZoomAreaSelection({ x1: 0, x2: 0 })
        return
      }
      setZoomAreaSelection({ x1: e.chartX, x2: e.chartX })
    }
  }

  const handleOnMouseMove = (e) => {
    if (isZoomSelectionInProgress) {
      if (e && e.chartX) {
        setZoomAreaSelection(({ x1, x2 }) => {
          if (x1 < e.chartX) {
            return { x1, x2: e.chartX }
          }
          return { x1: e.chartX, x2 }
        })
      }
    }
  }

  const handleOnMouseUp = () => {
    if (isZoomSelectionInProgress) {
      setIsZoomSelectionInProgress(false)
      setZoomAreaSelection(CHART_SETTINGS.DEFAULT_ZOOM)

      const { x1: startZoomPoint, x2: endZoomPoint } = zoomAreaSelection

      const zoomChartWidth = Math.abs(startZoomPoint - endZoomPoint)
      if (zoomChartWidth < 10) return // clicks and small areas should not zoom in

      // The YAxis width needs to be removed for correct index calulations
      const normalizedStartZoomPoint = startZoomPoint - CHART_SETTINGS.YAXIS_WIDTH
      const normalizedEndZoomPoint = endZoomPoint - CHART_SETTINGS.YAXIS_WIDTH
      const normalizedChartWidth = chartWidth - CHART_SETTINGS.YAXIS_WIDTH

      const zoomedStartDateUnixTimestamp = calculateZoomTimestamp(
        normalizedStartZoomPoint,
        normalizedChartWidth,
        zoomedStartEndUnixTimestamps,
      )

      const zoomedEndDateUnixTimestamp = calculateZoomTimestamp(
        normalizedEndZoomPoint,
        normalizedChartWidth,
        zoomedStartEndUnixTimestamps,
      )

      setZoomedData(
        lineChartData.filter(
          (dataPoint) =>
            dataPoint.dateUnixTimestamp >= zoomedStartDateUnixTimestamp &&
            dataPoint.dateUnixTimestamp <= zoomedEndDateUnixTimestamp,
        ),
      )
      setZoomedStartUnixTimestamps({
        start: zoomedStartDateUnixTimestamp,
        end: zoomedEndDateUnixTimestamp,
      })
      setShowZoomOutButton(true)
    }
  }

  const resetZoom = useCallback(() => {
    setIsZoomSelectionInProgress(false)
    setShowZoomOutButton(false)
    setZoomedData(lineChartData)
    setZoomedStartUnixTimestamps(calculateInitialUnixTimestamps())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineChartData])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (chartRef.current && chartRef.current.current) {
      const chartRect = chartRef.current.current.getBoundingClientRect()
      const width = Math.round(chartRect.width)
      const height = Math.round(chartRect.height)
      if (width > 1 && height > 1) {
        setChartWidth(width)
        setChartHeight(height)
      }
    }
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect()
      const height = Math.round(containerRect.height)
      if (height > (customChartSettings?.MAX_HEIGHT ?? CHART_SETTINGS.MAX_HEIGHT)) {
        setContainerHeight(CHART_SETTINGS.MAX_HEIGHT)
      } else if (height > 0) {
        setContainerHeight(height)
      }
    }
  })

  useEffect(() => {
    resetZoom()
  }, [lineChartData]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      style={{
        width: '100%',
        height: containerHeight > CHART_SETTINGS.MAX_HEIGHT ? CHART_SETTINGS.MAX_HEIGHT : '90%',
        position: 'relative',
      }}
      ref={containerRef}
    >
      {showZoomOutButton && (
        <Button
          onClick={resetZoom}
          icon="zoom-out"
          style={{ position: 'absolute', top: -36, right: 16 }}
          data-testid="zoom-out-btn"
        />
      )}
      {renderChildrenOutsideLineChart()}
      <ResponsiveContainer
        aspect={CHART_SETTINGS.ASPECT}
        minWidth={CHART_SETTINGS.MIN_WIDTH}
        minHeight={CHART_SETTINGS.MIN_HEIGHT}
        maxHeight={CHART_SETTINGS.MAX_HEIGHT}
        ref={chartRef}
      >
        <LineChart
          data={zoomedData}
          onMouseDown={handleOnMouseDown}
          onMouseMove={handleOnMouseMove}
          onMouseUp={handleOnMouseUp}
        >
          {lineChartChildren({
            additionalLineChartArguments,
            zoomedStartEndUnixTimestamps,
          })}
          <svg>
            <rect
              x={zoomAreaSelection.x1}
              y={0}
              width={calculateZoomAreaWidth(zoomAreaSelection)}
              height={chartHeight}
              style={{ fill: '#ccc', stroke: '#999', strokeWidth: 2, opacity: 0.3 }}
              data-testid="zoom-area-selection"
            />
          </svg>
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

GenericChart.propTypes = {
  lineChartData: PropTypes.arrayOf(
    PropTypes.shape({
      dateUnixTimestamp: PropTypes.number.isRequired,
    }),
  ).isRequired,
  lineChartChildren: PropTypes.func.isRequired,
  renderChildrenOutsideLineChart: PropTypes.func.isRequired,
  additionalLineChartArguments: PropTypes.object,
  customChartSettings: PropTypes.object,
}

export default GenericChart
