import { TextAlign, VerticalAlign } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import OpsMemoSyndicationStructureTableCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/OpsMemoSyndicationStructureTableCell'

const useOpsMemoSyndicationStructureTableColumnDefinitions = ({
  dealDisplayId,
  showConfirmationForLinks = false,
  tileVersion,
  businessPartnerResolvedNames,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.syndication-structure',
  })
  return useMemo(
    () =>
      [
        {
          Header: t('partyRoleTranche-label'),
          accessor: 'partyRoleTranche',
          minWidth: 250,
        },
        {
          Header: t('commitment-label'),
          accessor: 'commitment',
          hAlign: TextAlign.Right,
          width: 180,
        },
        {
          Header: t('consortiumRank-label'),
          accessor: 'consortiumRank',
          hAlign: TextAlign.Right,
          width: 150,
        },
        {
          Header: t('type-label'),
          accessor: 'type',
          width: 180,
        },
        {
          Header: t('rate-label'),
          accessor: 'rate',
          hAlign: TextAlign.Right,
          width: 160,
        },
        {
          Header: t('amountTerm-label'),
          accessor: 'amountTerm',
          hAlign: TextAlign.Right,
          width: 180,
        },
        {
          Header: t('frequency-label'),
          accessor: 'frequency',
          hAlign: TextAlign.Right,
          width: 120,
        },
        {
          Header: t('paymentDate-label'),
          accessor: 'paymentDate',
          hAlign: TextAlign.Right,
          width: 160,
        },
        {
          Header: t('signingDate-label'),
          accessor: 'signingDate',
          hAlign: TextAlign.Right,
          width: 160,
        },
      ].map((element) => ({
        disableDragAndDrop: true,
        disableResizing: false,
        Cell: (cellProps) =>
          OpsMemoSyndicationStructureTableCell({
            ...cellProps,
            dealDisplayId,
            showConfirmationForLinks,
            tileVersion,
            businessPartnerResolvedNames,
          }),
        vAlign: VerticalAlign.Middle,
        ...element,
      })),
    [businessPartnerResolvedNames, dealDisplayId, showConfirmationForLinks, t, tileVersion],
  )
}

export default useOpsMemoSyndicationStructureTableColumnDefinitions
