import isEmpty from 'lodash.isempty'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { conditionTypes } from 'api/conditions/conditions'
import { isMissingPermissionError } from 'api/requests'
import ConditionsTileToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/ConditionsTileToolbar'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/external/ExternalConditionsTileEditMode.module.css'
import MissingPermissionErrorTileContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/generic/MissingPermissionErrorTileContent'
import ConditionsTable from 'components/domains/conditions/overview/ConditionsTable'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useConditionTableColumns from 'hooks/services/conditions/tables/useConditionTableColumns'
import useConditions from 'hooks/services/conditions/useConditions'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const emptyObject = {}

const ExternalConditionsTileEditMode = ({
  metaData,
  onMetaDataChange,
  shownColumns,
  shownSubcomponents,
  subcomponentHeight,
}) => {
  const { event } = useContext(BusinessEventsAndTasksContext)
  const entityId = event?.entityRef?.entityId
  const entityType = event?.entityRef?.entityType

  const { selectedConditions = emptyObject } = metaData || emptyObject
  const {
    columnSelection,
    filters,
    setFilters,
    sortableColumns,
    sorting,
    onUpdateSorting,
    searchParam,
    setSearchParam,
    mappedBackendFilters,
  } = useConditionTableColumns({
    shownColumns,
    conditionType: conditionTypes.external,
    entityType,
  })

  const isFilterOrSearchApplied = useMemo(() => {
    const appliedFilters = filters.filter(
      ({ value, emptyValue }) => !!value && !isEqual(value, emptyValue),
    )
    return !isEmpty(appliedFilters) || searchParam !== ''
  }, [filters, searchParam])

  const {
    isLoading,
    isError,
    error,
    data: externalConditionsResult,
    fetchNextPage,
  } = useConditions(conditionTypes.external, { entityId, entityType }, mappedBackendFilters, {
    sortBy: sorting.sortingKey,
    orderBy: sorting.sortDescending ? 'desc' : 'asc',
  })

  const onConditionSelectionChanged = useCallback(
    (selection) => {
      const changedMetaData = isEmpty(selection) ? null : { selectedConditions: selection }
      onMetaDataChange(changedMetaData)
    },
    [onMetaDataChange],
  )

  const renderExternalConditionsTable = useCallback(
    () => (
      <div className={styles.tableWrapper}>
        <ConditionsTileToolbar
          numberOfConditions={externalConditionsResult?.total ?? 0}
          filters={filters}
          setFilters={setFilters}
          searchParam={searchParam}
          onUpdateSearchParam={setSearchParam}
          sorting={{
            columnKey: sorting.sortBy,
            sortBy: sorting.sortingKey,
            isSortingAscending: !sorting.sortDescending,
            sortableColumns,
            onUpdateSorting,
          }}
          isEditMode
        />
        <ConditionsTable
          isLoading={isLoading}
          conditions={externalConditionsResult?.externalConditions}
          columns={columnSelection}
          isFilterOrSearchApplied={isFilterOrSearchApplied}
          fetchNextPage={fetchNextPage}
          conditionType={conditionTypes.external}
          onConditionSelectionChanged={onConditionSelectionChanged}
          selectedConditions={selectedConditions}
          shownSubcomponents={shownSubcomponents}
          subcomponentHeight={subcomponentHeight}
        />
      </div>
    ),
    [
      externalConditionsResult?.total,
      externalConditionsResult?.externalConditions,
      filters,
      setFilters,
      searchParam,
      setSearchParam,
      sorting.sortBy,
      sorting.sortingKey,
      sorting.sortDescending,
      sortableColumns,
      onUpdateSorting,
      isLoading,
      columnSelection,
      isFilterOrSearchApplied,
      fetchNextPage,
      onConditionSelectionChanged,
      selectedConditions,
      shownSubcomponents,
      subcomponentHeight,
    ],
  )

  if (isError && isMissingPermissionError(error)) {
    return <MissingPermissionErrorTileContent />
  }

  return (
    <RequestStateResolver
      isLoading={false}
      isError={isError}
      renderContent={renderExternalConditionsTable}
      errorToDisplay={<ErrorDataUnavailableInContent />}
    />
  )
}

ExternalConditionsTileEditMode.propTypes = {
  tileId: PropTypes.string.isRequired,
  metaData: PropTypes.shape({}),
  onMetaDataChange: PropTypes.func.isRequired,
  shownColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  shownSubcomponents: PropTypes.shape({
    externalAssignee: PropTypes.bool,
    description: PropTypes.bool,
  }),
  subcomponentHeight: PropTypes.number.isRequired,
}

export default ExternalConditionsTileEditMode
