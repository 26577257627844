import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const usePropertyFinancingStatus = () =>
  useCamelizedResponse(
    useRequest({
      path: `/properties/financing-status-codes`,
      useCache: true,
      translated: true,
      keys: ['properties', 'financing-status'],
    }),
  )

export default usePropertyFinancingStatus
