import { useMemo } from 'react'
import useDecisionPaperVersion from 'hooks/services/business-events-and-tasks/decision-papers/useDecisionPaperVersion'
import useDecisionStage from 'hooks/services/business-events-and-tasks/decision-process/stages/useDecisionStage'
import useDecisionStageVoters from 'hooks/services/business-events-and-tasks/decision-process/stages/useDecisionStageVoters'
import { useStaffMemberSelf } from 'hooks/services/business-partners/staff-members/useStaffMemberSelf'

const useDecisionStageApproval = (
  { eventId, decisionStageId, voterId },
  options = { enabled: true },
) => {
  const {
    isLoading: isDecisionStageLoading,
    isError: isDecisionStageError,
    data: decisionStageData,
  } = useDecisionStage({ eventId, decisionStageId }, options)

  const {
    isLoading: isStaffMemberSelfLoading,
    isError: isStaffMemberSelfError,
    data: staffMember,
  } = useStaffMemberSelf(options)

  const {
    isLoading: isDecisionStageVotersLoading,
    isError: isDecisionStageVotersError,
    data: decisionStageVoters,
  } = useDecisionStageVoters({ eventId, decisionStageId }, options)

  const isDecisionPaperRequestEnabled =
    !isDecisionStageLoading && !isDecisionStageError && !!decisionStageData?.decisionPaper?.version

  const {
    isLoading: isDecisionPaperLoading,
    isError: isDecisionPaperError,
    data: decisionPaperData,
  } = useDecisionPaperVersion(
    { eventId, versionNumber: decisionStageData?.decisionPaper?.version },
    {
      enabled: isDecisionPaperRequestEnabled && options.enabled,
    },
  )

  return useMemo(() => {
    const isLoading =
      isDecisionStageLoading ||
      isStaffMemberSelfLoading ||
      isDecisionStageVotersLoading ||
      (isDecisionPaperRequestEnabled && isDecisionPaperLoading)
    const isError =
      isDecisionStageError ||
      isStaffMemberSelfError ||
      isDecisionStageVotersError ||
      (isDecisionPaperRequestEnabled && isDecisionPaperError)
    return {
      isLoading,
      isError,
      data:
        !isLoading && !isError
          ? {
              decisionStageData,
              decisionPaperData,
              staffMember,
              decisionStageVoters,
              userVote: decisionStageVoters.voters.find(
                ({ userId, id }) => userId === staffMember.id && voterId === id,
              ),
            }
          : undefined,
    }
  }, [
    isDecisionStageLoading,
    isStaffMemberSelfLoading,
    isDecisionStageVotersLoading,
    isDecisionPaperRequestEnabled,
    isDecisionPaperLoading,
    isDecisionStageError,
    isStaffMemberSelfError,
    isDecisionStageVotersError,
    isDecisionPaperError,
    decisionStageData,
    decisionPaperData,
    staffMember,
    decisionStageVoters,
    voterId,
  ])
}

export default useDecisionStageApproval
