import { Text, WrappingType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { propertyMonitoringPermissions } from 'api/property/monitoring/propertyMonitoringConfiguration'
import DialogGroup from 'components/domains/properties/monitoring/settings/dialog/DialogGroup'
import LabeledSwitch from 'components/ui/input/LabeledSwitch'
import TextAreaWithMaxCharacterRestriction from 'components/ui/input/TextAreaWithMaxCharacterRestriction'

const noteMaxLength = 255

const PropertyMonitoringSettingsSwitch = ({
  monitoringActivatedState,
  onMonitoringActivatedStateChange,
  monitoringAutomatedState,
  onMonitoringAutomatedStateChange,
  isPropertyAssignedToMarket,
  note,
  onNoteChanged,
  monitoringPermissions,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dialog.monitoring-settings' })
  const hasActivateDeactivatePermissions = monitoringPermissions.includes(
    propertyMonitoringPermissions.activateDeactivate,
  )
  return (
    <DialogGroup headerText={t('header')}>
      <Text wrapping-type={WrappingType.Normal}>{t('property-relevant')}</Text>
      <LabeledSwitch
        checked={monitoringActivatedState}
        onChange={onMonitoringActivatedStateChange}
        disabled={!hasActivateDeactivatePermissions}
        isLoading={false}
      />
      <TextAreaWithMaxCharacterRestriction
        value={note}
        onInput={onNoteChanged}
        maxLength={noteMaxLength}
        label={t('note')}
        readOnly={!hasActivateDeactivatePermissions}
      />
      {monitoringActivatedState && (
        <Text wrapping-type={WrappingType.Normal}>{t('automated')}</Text>
      )}
      {monitoringActivatedState && (
        <LabeledSwitch
          checked={monitoringAutomatedState}
          onChange={onMonitoringAutomatedStateChange}
          disabled={!isPropertyAssignedToMarket}
          isLoading={false}
        />
      )}
    </DialogGroup>
  )
}

PropertyMonitoringSettingsSwitch.propTypes = {
  monitoringActivatedState: PropTypes.bool.isRequired,
  onMonitoringActivatedStateChange: PropTypes.func.isRequired,
  monitoringAutomatedState: PropTypes.bool.isRequired,
  onMonitoringAutomatedStateChange: PropTypes.func.isRequired,
  isPropertyAssignedToMarket: PropTypes.bool.isRequired,
  note: PropTypes.string,
  onNoteChanged: PropTypes.func.isRequired,
  monitoringPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default PropertyMonitoringSettingsSwitch
