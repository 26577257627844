import camelize from 'camelize'
import { useMemo } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useInfiniteRequest } from 'hooks/services/baseService'

export const PAGE_LIMIT = 50

/**
 * @returns {Omit<import('@tanstack/react-query').UseInfiniteQueryResult, 'data'> & {data: Array<any>}} the result of the useInfiniteQuery hook
 */
export const usePropertiesValuationRequests = ({
  propertyUuids,
  filter: { reason, valuationType, status, reportDateFrom, reportDateUntil, valuer, search } = {},
  sort: { sortBy, orderBy } = {},
  pagination: { limit } = {},
  options,
}) => {
  const cacheKeys = [
    'properties',
    'valuation-requests',
    ...propertyUuids,
    `sortBy-${sortBy}-orderBy-${orderBy}`,
    reason,
    valuationType,
    status,
    reportDateFrom,
    reportDateUntil,
    valuer,
    search,
    limit,
  ]

  const queryParams = useMemo(() => {
    const temporaryQueryParams = new URLSearchParams()

    if (sortBy) {
      temporaryQueryParams.append('sort_by', sortBy)
    }
    if (orderBy) {
      temporaryQueryParams.append('order_by', orderBy)
    }

    if (status) {
      temporaryQueryParams.append('status', status)
    }

    if (reason) {
      temporaryQueryParams.append('reason', reason)
    }

    if (valuationType) {
      temporaryQueryParams.append('valuation_type', valuationType)
    }

    if (reportDateFrom) {
      temporaryQueryParams.append('report_date_from', reportDateFrom)
    }

    if (reportDateUntil) {
      temporaryQueryParams.append('report_date_until', reportDateUntil)
    }

    if (valuer) {
      temporaryQueryParams.append('valuer', valuer)
    }

    if (search) {
      temporaryQueryParams.append('search', search)
    }

    return temporaryQueryParams
  }, [
    sortBy,
    orderBy,
    status,
    reason,
    valuationType,
    reportDateFrom,
    reportDateUntil,
    valuer,
    search,
  ])

  const { post } = useAccessTokenRequest()

  const result = useInfiniteRequest({
    path: `/properties/valuation-requests`,
    queryParams: queryParams,
    keys: cacheKeys,
    limit: limit,
    options,
    requestFn: post,
    requestOptions: {
      body: {
        property_uuids: propertyUuids,
      },
    },
  })

  const processedData = useMemo(() => {
    if (!result.data) return undefined
    return camelize(
      result.data.pages.reduce(
        (acc, { valuation_requests: valuationRequests, pagination: { total } }) => ({
          valuationRequests: [...acc.valuationRequests, ...valuationRequests],
          total,
        }),
        { valuationRequests: [], pagination: { total: 0 } },
      ),
    )
  }, [result])
  return useMemo(() => ({ ...result, data: processedData }), [processedData, result])
}
