import PropTypes from 'prop-types'
import { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ChangelogDialogContext } from 'components/domains/conditions/dialogs/changelog/ChangelogDialogContext'
import styles from 'components/domains/conditions/dialogs/changelog/GenericConditionRequirementChangelogDialog.module.css'
import ConditionRequirementChangelogTable from 'components/domains/conditions/dialogs/changelog/table/ConditionRequirementChangelogTable'
import { ENTITIES } from 'components/domains/deals/change-overview/constants'
import Dialog, { DialogSecondaryButton, DialogSize } from 'components/ui/dialog/Dialog'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'

const GenericConditionRequirementChangelogDialog = ({
  hideNameForEntityId,
  relatedEntityType,
  relatedEntityId,
  relatedEntityDisplayId,
  changeLogData,
  isOpen,
  onAfterClose,
  totalNumberOfItems,
  isLoading,
  isFetching,
  isError,
  loadMore,
}) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.dialog.changelog',
  })
  const dialogRef = useRef()

  const relatedEntityContextValue = useMemo(
    () => ({
      hideNameForEntityId,
      relatedEntityType,
      relatedEntityId,
      relatedEntityDisplayId,
    }),
    [hideNameForEntityId, relatedEntityDisplayId, relatedEntityId, relatedEntityType],
  )

  const onDialogCloseButtonClick = useCallback(() => {
    dialogRef.current?.close()
  }, [])

  const renderContent = useCallback(
    () => (
      <ConditionRequirementChangelogTable
        title={t('title')}
        isFetching={isFetching}
        changeLogData={changeLogData}
        loadMore={loadMore}
        totalNumberOfItems={totalNumberOfItems}
      />
    ),
    [changeLogData, isFetching, loadMore, t, totalNumberOfItems],
  )

  return (
    <ChangelogDialogContext.Provider value={relatedEntityContextValue}>
      <Dialog
        ref={dialogRef}
        className={styles.changelogDialog}
        open={isOpen}
        onAfterClose={onAfterClose}
        headerText={t('title')}
        size={DialogSize.L}
        closeButton={
          <DialogSecondaryButton onClick={onDialogCloseButtonClick}>
            {tNoPrefix('buttons.close')}
          </DialogSecondaryButton>
        }
      >
        <RequestStateResolver
          isLoading={isLoading}
          isError={isError}
          center
          errorToDisplay={<ErrorDataUnavailableInContent />}
          renderContent={renderContent}
        />
      </Dialog>
    </ChangelogDialogContext.Provider>
  )
}

GenericConditionRequirementChangelogDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAfterClose: PropTypes.func.isRequired,
  changeLogData: PropTypes.arrayOf(
    PropTypes.shape({
      entityId: PropTypes.string.isRequired,
      entityType: PropTypes.string.isRequired,
      action: PropTypes.string.isRequired,
      changedFields: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  isFetching: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  totalNumberOfItems: PropTypes.number,
  hideNameForEntityId: PropTypes.string,
  relatedEntityType: PropTypes.oneOf([ENTITIES.DEAL, ENTITIES.BUSINESS_PARTNER]),
  relatedEntityId: PropTypes.string.isRequired,
  relatedEntityDisplayId: PropTypes.string.isRequired,
}

export default GenericConditionRequirementChangelogDialog
