import { useQuery } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const OK = 200

const useMultiPropertiesKpis = (
  propertyUuids,
  { currency, areaUnitOfMeasurement, withTenantGranularity = false, keyDates } = {},
  queryOptions = {},
) => {
  const mappedPropertyUuids = propertyUuids.map((uuid) => ({
    property_uuid: uuid,
    key_dates: keyDates,
  }))
  const { post } = useAccessTokenRequest()
  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: [
      'properties-kpis',
      mappedPropertyUuids,
      currency,
      areaUnitOfMeasurement,
      keyDates,
      withTenantGranularity,
    ],
    refetchOnWindowFocus: false,
    queryFn: () =>
      post({
        path: `/properties/kpis/property`,
        body: {
          property_uuid_to_key_dates: mappedPropertyUuids,
          currency: currency,
          area_unit_of_measurement: areaUnitOfMeasurement,
          with_tenant_granularity: withTenantGranularity,
        },
      }),
    retry: 0,
    ...queryOptions,
  })
  if (isLoading || isFetching || isError) return { isLoading, isFetching, isError, data: [] }
  return {
    data: data?.data?.property_rent_roll_kpis || [],
    isError: data?.status !== OK,
    isLoading: false,
    isFetching: false,
  }
}

export default useMultiPropertiesKpis
