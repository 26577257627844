import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/waiver/WaiverImpactClassificationTileContent.module.css'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/waiver/shared/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/waiver/shared/DisplayCardViewItemView'

const PLACE_HOLDER = '-'

const WaiverImpactClassificationTileContent = ({ content }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.waiver',
  })

  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      alignItems={FlexBoxAlignItems.Stretch}
      className={styles.displayFlexBox}
    >
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          id="classification"
          label={t('classification.label')}
          value={
            content.classification
              ? t(`classification.type.${content.classification}`)
              : PLACE_HOLDER
          }
          isFirstItem
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          id="reason"
          label={t('reason.label')}
          value={content.reason ?? PLACE_HOLDER}
        />
      </DisplayCardViewItemView>
    </FlexBox>
  )
}

WaiverImpactClassificationTileContent.propTypes = {
  content: PropTypes.shape({
    classification: PropTypes.string,
    reason: PropTypes.string,
  }),
}
export default WaiverImpactClassificationTileContent
