import {
  FlexBoxAlignItems,
  Input,
  FlexBox,
  Label,
  ObjectStatus,
  Text,
  ValueState,
  FlexBoxDirection,
  TextArea,
  WrappingType,
  DateTimePicker,
  FlexBoxJustifyContent,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/limit-check/reservations/DealReservationCard.module.css'
import ReservationWarningPopover, {
  ReservationWarningPopoverType,
} from 'components/domains/deals/limit-check/reservations/ReservationWarningPopover'
import { useExternalTypeCodes } from 'components/domains/deals/limit-check/reservations/external-type/useExternalTypeCodes'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { useNumberFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'
import { useCountries } from 'hooks/services/business-partners/config/useCountries'
import { useCountryCodes } from 'hooks/services/properties/useCountryCodes'
import { useCurrencyCodes } from 'hooks/services/properties/useCurrencyCodes'
import { convertToNumberIfValid } from 'routes/deals/financing/utils/convertToNumberIfValid'

const syncStatusMapper = {
  FAILED: {
    code: 'failed',
    style: ValueState.Error,
  },
  PENDING: {
    code: 'pending',
    style: ValueState.Information,
  },
  RESERVED: {
    code: 'reserved',
    style: ValueState.Success,
  },
}

const SPACE = <>&nbsp;</>
const HYPHEN_PLACEHOLDER = '-'
const EMPTY_STRING = ''

const TextField = ({ value }) => <Text>{!isNil(value) ? value : HYPHEN_PLACEHOLDER}</Text>

TextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const BasicInput = ({ value, disabled }) => <Input disabled={disabled} value={value} />

BasicInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
}

const SelectInput = ({
  hook,
  selectedKey = EMPTY_STRING,
  selectionName,
  optionKeyName,
  optionDisplayName,
  valueStateMessage,
  isErrorState,
  onChange,
}) => (
  <LoadingSelect
    id={`${selectionName}_loading_select`}
    className={`${styles.fullWidth} ${styles.selectMinWidth}`}
    loadingHook={hook}
    selectionName={selectionName}
    optionKeyName={optionKeyName}
    optionDisplayName={optionDisplayName}
    selectedKey={selectedKey}
    valueStateMessage={valueStateMessage}
    isErrorState={isErrorState}
    onChange={onChange}
  />
)

SelectInput.propTypes = {
  hook: PropTypes.func.isRequired,
  selectedKey: PropTypes.string,
  selectionName: PropTypes.string.isRequired,
  optionKeyName: PropTypes.string,
  optionDisplayName: PropTypes.string,
  isErrorState: PropTypes.bool,
  valueStateMessage: PropTypes.element,
  onChange: PropTypes.func.isRequired,
}

const TextAreaInput = ({ onChange }) => (
  <FlexBox className={styles.fullWidth}>
    <TextArea id={'comment_input'} rows={3} maxlength={100} onChange={onChange} />
  </FlexBox>
)

TextAreaInput.propTypes = {
  onChange: PropTypes.func.isRequired,
}

const DateField = ({ label, value, minDate, isErrorState, valueStateMessage, onChange }) => {
  const { localePattern: localeDateTimePattern } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
  })

  const onChangeHandler = (e) => {
    const date = DateTime.fromFormat(e.detail.value, localeDateTimePattern)
    date.isValid ? onChange(date.toJSDate().toISOString()) : onChange(EMPTY_STRING)
  }

  return (
    <FlexBox
      alignItems={FlexBoxAlignItems.Center}
      justifyContent={FlexBoxJustifyContent.End}
      className={styles.gap}
    >
      <Label wrappingType={WrappingType.None}>{label}</Label>
      <DateTimePicker
        className={styles.fullWidth}
        value={value}
        minDate={minDate}
        formatPattern={localeDateTimePattern}
        onChange={onChangeHandler}
        valueState={isErrorState ? ValueState.Error : ValueState.None}
        valueStateMessage={valueStateMessage}
      />
    </FlexBox>
  )
}

DateField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  minDate: PropTypes.string,
  isErrorState: PropTypes.bool,
  valueStateMessage: PropTypes.element,
  onChange: PropTypes.func.isRequired,
}

const TextFieldWithLabel = ({ value, label, hasWarning }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.limit-check.reservations',
  })

  return (
    <FlexBox alignItems={FlexBoxAlignItems.Center} className={styles.rowHeight}>
      <FlexBox className={styles.gap}>
        <Label wrappingType={WrappingType.Normal}>{label}</Label>
        <TextField value={value} />
      </FlexBox>
      {hasWarning && (
        <ReservationWarningPopover
          type={ReservationWarningPopoverType.Warning}
          content={t('warnings.outdated-reservation')}
          title={t('label.outdated-reservation')}
        />
      )}
    </FlexBox>
  )
}

TextFieldWithLabel.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  hasWarning: PropTypes.bool,
}

const InputWithLabel = ({
  value,
  label,
  disabled,
  direction = FlexBoxDirection.Row,
  alignment = FlexBoxAlignItems.Center,
}) => (
  <FlexBox direction={direction} alignItems={alignment} className={styles.gap}>
    <Label wrappingType={WrappingType.None}>{label}</Label>
    <BasicInput value={value} disabled={disabled} />
  </FlexBox>
)

InputWithLabel.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  direction: PropTypes.string,
  alignment: PropTypes.string,
}

const SelectInputWithLabel = ({
  value,
  label,
  hook,
  selectionName,
  optionDisplayName,
  valueStateMessage,
  isErrorState,
  direction = FlexBoxDirection.Row,
  alignment = FlexBoxAlignItems.Center,
  required = false,
  onChange,
}) => (
  <FlexBox direction={direction} alignItems={alignment} className={styles.fullWidth}>
    <Label wrappingType={WrappingType.None} required={required}>
      {label}
    </Label>
    {direction === FlexBoxDirection.Row && SPACE}
    <SelectInput
      hook={hook}
      selectedKey={value}
      selectionName={selectionName}
      optionDisplayName={optionDisplayName}
      isErrorState={isErrorState}
      valueStateMessage={valueStateMessage}
      onChange={onChange}
    />
  </FlexBox>
)

SelectInputWithLabel.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  hook: PropTypes.func.isRequired,
  selectionName: PropTypes.string.isRequired,
  optionDisplayName: PropTypes.string,
  isErrorState: PropTypes.bool,
  valueStateMessage: PropTypes.element,
  direction: PropTypes.string,
  alignment: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

const StatusField = ({ status, label, externalStatusMessage }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.limit-check.reservations',
  })

  return (
    <FlexBox
      direction={FlexBoxDirection.Row}
      alignItems={FlexBoxAlignItems.Center}
      className={styles.gap}
    >
      <Label wrappingType={WrappingType.Normal}>{label}</Label>
      <ObjectStatus inverted state={status?.style ?? ValueState.None}>
        {status?.code ? t(`label.status.${status.code}`) : HYPHEN_PLACEHOLDER}
      </ObjectStatus>
      {status?.code === 'failed' && (
        <ReservationWarningPopover
          type={ReservationWarningPopoverType.Error}
          content={externalStatusMessage}
          title={t('label.external-status')}
        />
      )}
    </FlexBox>
  )
}

StatusField.propTypes = {
  status: PropTypes.shape({
    style: PropTypes.string,
    code: PropTypes.string,
  }),
  label: PropTypes.string,
  externalStatusMessage: PropTypes.string,
}

const NumberInput = ({ value, onChange, isErrorState, valueStateMessage }) => (
  <FormattedNumberInput
    value={convertToNumberIfValid(value)}
    className={styles.hAlignRight}
    onChange={onChange}
    valueState={isErrorState ? ValueState.Error : ValueState.None}
    valueStateMessage={valueStateMessage}
  />
)

NumberInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  isErrorState: PropTypes.bool,
  valueStateMessage: PropTypes.element,
}

const AmountAndCurrencyInput = ({ onChange, isValid }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.limit-check.reservations',
  })

  return (
    <FlexBox alignItems={FlexBoxAlignItems.Center} className={styles.gap}>
      <FlexBox direction={FlexBoxDirection.Column} className={styles.twoThirdsWidth}>
        <NumberInput
          onChange={(value) => onChange('number', value)}
          isErrorState={!isValid('number')}
          valueStateMessage={<span>{t('validation.amount.required')}</span>}
        />
      </FlexBox>
      <FlexBox direction={FlexBoxDirection.Column} className={styles.oneThirdWidth}>
        <SelectInput
          hook={useCurrencyCodes}
          selectionName={'currency_codes'}
          isErrorState={!isValid('currencyCode')}
          valueStateMessage={<span>{t('validation.currency-code.required')}</span>}
          onChange={(e) => onChange('currencyCode', e.detail.selectedOption.value)}
        />
      </FlexBox>
    </FlexBox>
  )
}

AmountAndCurrencyInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  isValid: PropTypes.func,
}

export const useDealReservationTableConfig = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.limit-check.reservations',
  })
  const { data: { countries: countryCodesConfig = [] } = {} } = useCountries()
  const { format: formatDateAndTime } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
  })
  const formatNumber = useNumberFormatter({ minimumFractionDigits: 2 })
  const customColumnStyle = `${styles.fullWidth} ${styles.gap}`

  const getCountryName = (countryCode) =>
    countryCodesConfig.find(({ id }) => id === countryCode)?.name

  const renderReservationIdViewCell = (reservationId, status, externalStatusMessage) => (
    <FlexBox
      direction={FlexBoxDirection.Column}
      justifyContent={FlexBoxJustifyContent.Center}
      className={customColumnStyle}
    >
      <TextField value={reservationId} />
      <StatusField
        status={syncStatusMapper[status]}
        label={t('label.synchronization')}
        externalStatusMessage={externalStatusMessage}
      />
    </FlexBox>
  )

  const renderReservationIdEditCell = (reservationId = '<Reservation ID>', status = '<Status>') => (
    <FlexBox direction={FlexBoxDirection.Column} className={customColumnStyle}>
      <BasicInput value={reservationId} disabled />
      <InputWithLabel
        value={
          syncStatusMapper[status] ? t(`label.status.${syncStatusMapper[status].code}`) : status
        }
        label={t('label.synchronization')}
        disabled
      />
    </FlexBox>
  )

  const renderBusinessSegmentViewCell = (businessSegmentCode, productTypeCode) => (
    <FlexBox direction={FlexBoxDirection.Column} className={customColumnStyle}>
      <TextField value={businessSegmentCode} />
      <TextFieldWithLabel value={productTypeCode} label={t('label.productType')} />
    </FlexBox>
  )

  const renderBusinessSegmentEditCell = (onChange, isValid, businessSegmentCode) => (
    <FlexBox direction={FlexBoxDirection.Column} className={customColumnStyle}>
      <BasicInput value={businessSegmentCode} disabled />
      <SelectInputWithLabel
        label={t('label.productType')}
        hook={useExternalTypeCodes}
        selectionName={'productTypes'}
        optionDisplayName={'key'}
        isErrorState={!isValid('externalProductTypeCode')}
        valueStateMessage={<span>{t('validation.product-type.required')}</span>}
        onChange={(e) => onChange('externalProductTypeCode', e.detail.selectedOption.value)}
      />
    </FlexBox>
  )

  const renderMainPropertyTypeViewCell = (mainPropertyTypeCode, countryCode) => (
    <FlexBox direction={FlexBoxDirection.Column} className={customColumnStyle}>
      <TextField value={mainPropertyTypeCode} />
      <TextFieldWithLabel value={getCountryName(countryCode)} label={t('label.country')} />
    </FlexBox>
  )

  const renderMainPropertyTypeEditCell = (onChange, isValid) => (
    <FlexBox direction={FlexBoxDirection.Column} className={customColumnStyle}>
      <SelectInput
        hook={useExternalTypeCodes}
        selectionName={'propertyTypes'}
        optionDisplayName={'key'}
        isErrorState={!isValid('externalMainPropertyTypeCode')}
        valueStateMessage={<span>{t('validation.property-type.required')}</span>}
        onChange={(e) => {
          onChange('externalMainPropertyTypeCode', e.detail.selectedOption.value)
        }}
      />
      <SelectInputWithLabel
        label={t('label.country')}
        hook={useCountryCodes}
        selectionName={'country_codes'}
        isErrorState={!isValid('countryCode')}
        valueStateMessage={<span>{t('validation.country.required')}</span>}
        onChange={(e) => onChange('countryCode', e.detail.selectedOption.value)}
      />
    </FlexBox>
  )

  const renderAmountViewCell = (amount, currencyCode, startTimestamp, endTimestamp) => {
    const hasWarning = DateTime.now().toISO() > endTimestamp

    return (
      <FlexBox
        direction={FlexBoxDirection.Column}
        className={customColumnStyle}
        alignItems={FlexBoxAlignItems.End}
      >
        <TextField value={`${formatNumber(amount)} ${currencyCode}`} />
        <TextFieldWithLabel
          value={formatDateAndTime(startTimestamp)}
          label={t('label.start-date')}
        />
        <TextFieldWithLabel
          value={formatDateAndTime(endTimestamp)}
          label={t('label.end-date')}
          hasWarning={hasWarning}
        />
      </FlexBox>
    )
  }

  const renderAmountEditCell = (onChange, isValid, getValue, getInitialValues) => (
    <FlexBox direction={FlexBoxDirection.Column} className={customColumnStyle}>
      <AmountAndCurrencyInput onChange={onChange} isValid={isValid} />
      <DateField
        value={formatDateAndTime(getValue('startTimestamp'))}
        label={t('label.start-date')}
        onChange={(value) => {
          onChange('startTimestamp', value)
          onChange('endTimestamp', EMPTY_STRING)
        }}
        minDate={formatDateAndTime(getInitialValues().startTimestamp)}
        isErrorState={!isValid('startTimestamp')}
        valueStateMessage={<span>{t('validation.start-date.required')}</span>}
      />
      <DateField
        value={formatDateAndTime(getValue('endTimestamp'))}
        label={t('label.end-date')}
        onChange={(value) => onChange('endTimestamp', value)}
        minDate={formatDateAndTime(getInitialValues().endTimestamp)}
        isErrorState={!isValid('endTimestamp')}
        valueStateMessage={<span>{t('validation.end-date.required')}</span>}
      />
    </FlexBox>
  )

  const renderCommentViewCell = (comment) => <TextField value={comment} />
  const renderCommentEditCell = (onChange) => (
    <TextAreaInput onChange={(e) => onChange('comment', e.target.value)} />
  )

  const columnDefinitions = [
    {
      columnKey: 'reservationId',
      title: t('column.reservation-id'),
      hasPopin: false,
      style: styles.widerColumnWidth,
    },
    {
      columnKey: 'businessSegment',
      title: t('column.business-segment'),
      minWidth: 320,
      hasPopin: true,
      style: styles.widerColumnWidth,
    },
    {
      columnKey: 'propertyType',
      title: t('column.main-property-type'),
      minWidth: 320,
      hasPopin: true,
      style: styles.shorterColumnWidth,
    },
    {
      columnKey: 'reservedAmount',
      title: t('column.reserved-amount'),
      minWidth: 320,
      hasPopin: true,
      style: styles.shorterColumnWidth,
      alignment: 'End',
    },
    {
      columnKey: 'comment',
      title: t('column.comment'),
      minWidth: 320,
      hasPopin: true,
      style: styles.widerColumnWidth,
    },
  ]

  return {
    columnDefinitions,
    InputWithLabel,
    SelectInputWithLabel,
    getCountryName,
    renderReservationIdViewCell,
    renderReservationIdEditCell,
    renderBusinessSegmentViewCell,
    renderBusinessSegmentEditCell,
    renderMainPropertyTypeViewCell,
    renderMainPropertyTypeEditCell,
    renderAmountViewCell,
    renderAmountEditCell,
    renderCommentViewCell,
    renderCommentEditCell,
  }
}
