import { useCallback } from 'react'
import { useMultipleDealTranches } from 'hooks/services/business-partners/unit-overview/loan/useMultipleDealTranches'
import useMultipleCollateralAgreementsByTrancheIds from 'hooks/services/collaterals/useMultipleCollateralAgreementsByTrancheIds'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'

const useMultipleAggregatedCollateralAgreements = ({ dealUuids }) => {
  const multipleDealTrancheResponses = useMultipleDealTranches(
    dealUuids,
    {},
    DATA_SOURCES.EXISTING_BUSINESS,
  )
  const tranches = multipleDealTrancheResponses?.map(({ data }) => data?.tranches).flat()
  const isTrancheError = multipleDealTrancheResponses?.some(({ isError }) => isError)
  const isTrancheLoading = multipleDealTrancheResponses?.some(({ isLoading }) => isLoading)
  const trancheError = multipleDealTrancheResponses?.find(({ isError }) => isError)?.error

  const multipleCollateralAgreementsByTrancheIds = useMultipleCollateralAgreementsByTrancheIds(
    multipleDealTrancheResponses,
    { showNewBusiness: false },
  )

  const isCagError = multipleCollateralAgreementsByTrancheIds?.some(({ isError }) => isError)
  const isCagLoading = multipleCollateralAgreementsByTrancheIds?.some(({ isLoading }) => isLoading)
  const cagError = multipleCollateralAgreementsByTrancheIds?.find(({ isError }) => isError)?.error

  const isError = isTrancheError || isCagError
  const isLoading = (isCagLoading && tranches && tranches?.length > 0) || isTrancheLoading
  const errors = isError ? [trancheError, cagError] : []

  const transformResult = useCallback((collateralAgreementsData) => {
    const emptyAggregatedCollateralAgreements = {
      cagAggregatedTotalValueInHeadQuarterCurrency: {
        value: 0,
        currency: '',
      },
      cagAggregatedTotalNominalValueInHeadQuarterCurrency: {
        value: 0,
        currency: '',
      },
      cagClusters: {},
    }

    const getHqValuesForAggregation = (collateralAgreement) => {
      if (collateralAgreement.extLandCharge) return { cagValueHqToAdd: 0, nominalValueHqToAdd: 0 }

      return {
        cagValueHqToAdd: collateralAgreement.cagValueHq?.value ?? 0,
        nominalValueHqToAdd: collateralAgreement.nominalValueHq?.value ?? 0,
      }
    }

    const calculateTotalValues = (aggregatedCollateralAgreements, currentCollateralAgreement) => {
      const { cagValueHqToAdd, nominalValueHqToAdd } = getHqValuesForAggregation(
        currentCollateralAgreement,
      )

      return {
        ...aggregatedCollateralAgreements,
        cagAggregatedTotalValueInHeadQuarterCurrency: {
          currency: currentCollateralAgreement.cagValueHq?.currency,
          value:
            aggregatedCollateralAgreements.cagAggregatedTotalValueInHeadQuarterCurrency.value +
            cagValueHqToAdd,
        },
        cagAggregatedTotalNominalValueInHeadQuarterCurrency: {
          currency: currentCollateralAgreement.nominalValueHq?.currency,
          value:
            aggregatedCollateralAgreements.cagAggregatedTotalNominalValueInHeadQuarterCurrency
              .value + nominalValueHqToAdd,
        },
      }
    }

    const getCagSpecificValues = (collateralAgreementsCagCluster, currentCollateralAgreement) => {
      const { cagValueHqToAdd, nominalValueHqToAdd } = getHqValuesForAggregation(
        currentCollateralAgreement,
      )

      return {
        cagCluster: {
          ...currentCollateralAgreement.cagCluster,
        },
        cagAggregatedValueInHeadQuarterCurrency: {
          currency: currentCollateralAgreement.cagValueHq?.currency,
          value:
            (collateralAgreementsCagCluster?.cagAggregatedValueInHeadQuarterCurrency?.value ?? 0) +
            cagValueHqToAdd,
        },
        cagAggregatedNominalValueInHeadQuarterCurrency: {
          currency: currentCollateralAgreement.nominalValueHq?.currency,
          value:
            (collateralAgreementsCagCluster?.cagAggregatedNominalValueInHeadQuarterCurrency
              ?.value ?? 0) + nominalValueHqToAdd,
        },
      }
    }

    const getCagTypeSpecificValues = (collateralAgreementsCagType, currentCollateralAgreement) => {
      const { cagValueHqToAdd, nominalValueHqToAdd } = getHqValuesForAggregation(
        currentCollateralAgreement,
      )
      return {
        cagType: {
          ...currentCollateralAgreement.cagType,
        },
        cagAggregatedValueInHeadQuarterCurrency: {
          currency: currentCollateralAgreement.cagValueHq?.currency,
          value:
            (collateralAgreementsCagType?.cagAggregatedValueInHeadQuarterCurrency?.value ?? 0) +
            cagValueHqToAdd,
        },
        cagAggregatedNominalValueInHeadQuarterCurrency: {
          currency: currentCollateralAgreement.nominalValueHq?.currency,
          value:
            (collateralAgreementsCagType?.cagAggregatedNominalValueInHeadQuarterCurrency?.value ??
              0) + nominalValueHqToAdd,
        },
      }
    }

    const getAgreementSpecificValues = (currentCollateralAgreement) => ({
      id: currentCollateralAgreement.id,
      displayId: currentCollateralAgreement.displayId,
      cagCluster: currentCollateralAgreement.cagCluster,
      cagType: currentCollateralAgreement.cagType,
      name: currentCollateralAgreement.name,
      systemStatus: currentCollateralAgreement.systemStatus,
      cagValue: currentCollateralAgreement.cagValue,
      cagValueInHeadQuarterCurrency: currentCollateralAgreement.cagValueHq,
      cagNominalValue: currentCollateralAgreement.nominalValue,
      cagNominalValueInHeadQuarterCurrency: currentCollateralAgreement.nominalValueHq,
      externalLandCharge: currentCollateralAgreement.extLandCharge,
      newBusinessReceivables: currentCollateralAgreement.newBusinessReceivables,
      existingBusinessReceivables: currentCollateralAgreement.existingBusinessReceivables,
      assetsCount: currentCollateralAgreement.assetsCount,
    })

    if (!collateralAgreementsData) {
      return
    }

    return collateralAgreementsData?.collateralAgreements?.reduce(
      (aggregatedCollateralAgreements, currentCollateralAgreement) => {
        const currentCagCluster = currentCollateralAgreement.cagCluster.id
        const currentCagType = currentCollateralAgreement.cagType.id

        return {
          ...calculateTotalValues(aggregatedCollateralAgreements, currentCollateralAgreement),
          cagClusters: {
            ...aggregatedCollateralAgreements.cagClusters,
            [currentCagCluster]: {
              ...getCagSpecificValues(
                aggregatedCollateralAgreements.cagClusters[currentCagCluster],
                currentCollateralAgreement,
              ),
              cagTypes: {
                ...(aggregatedCollateralAgreements.cagClusters[currentCagCluster]?.cagTypes ?? {}),
                [currentCagType]: {
                  ...getCagTypeSpecificValues(
                    aggregatedCollateralAgreements.cagClusters[currentCagCluster]?.cagTypes[
                      currentCagType
                    ],
                    currentCollateralAgreement,
                  ),
                  agreements: [
                    ...(aggregatedCollateralAgreements.cagClusters[currentCagCluster]?.cagTypes[
                      currentCagType
                    ]?.agreements ?? []),
                    getAgreementSpecificValues(currentCollateralAgreement),
                  ],
                },
              },
            },
          },
        }
      },
      emptyAggregatedCollateralAgreements,
    )
  }, [])

  const result = multipleCollateralAgreementsByTrancheIds?.map(
    ({ data: collateralAgreementsData }) => ({
      dealUuid: collateralAgreementsData?.dealUuid,
      ...transformResult(collateralAgreementsData?.data),
    }),
  )

  return {
    data: result,
    isLoading,
    isError,
    errors,
  }
}

export default useMultipleAggregatedCollateralAgreements
