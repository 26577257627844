import { FlexBox, MessageStrip } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/portfolio/overview/PropertyPortfolioOverviewMapCard.module.css'
import Card from 'components/ui/card/Card'
import EditableLeafletMapWithPolygonAndPropertyMarkers from 'components/ui/map/EditableLeafletMapWithPolygonAndPropertyMarker'
import { getBoundingBoxCenter, getBounds } from 'components/ui/map/MapPolygonUtils'

const DEFAULT_MAP_PADDING = 0.06
const blueOptions = { color: 'blue' }

const PropertyPortfolioOverviewMapCard = ({
  header,
  properties,
  propertyData,
  isLoadingProperties,
  isErrorProperties,
  mapPadding = DEFAULT_MAP_PADDING,
  renderError = () => <div />,
  className,
  style,
}) => {
  const { t } = useTranslation()

  const isValidGeoLocation = (location) =>
    !isNaN(location?.lat) && !isNaN(location?.lng) && (location?.lat !== 0 || location?.lng !== 0)

  const locations = properties
    .map((it) => ({
      id: it.id,
      uuid: it.uuid,
      name: it.description,
      propertyType: it['type_name'],
      address: {
        houseNumber: it.address?.house_id,
        street: it.address?.street_name,
        city: it.address?.city_name,
        zipCode: it.address?.postal_code,
        country: it.address?.country_name,
      },
      location: {
        lat: parseFloat(it['geo_location']?.latitude),
        lng: parseFloat(it['geo_location']?.longitude),
      },
      creationDate: it.system_administrative_data.creation_date_time,
      linkedDeal: it.financing_status_code,
    }))
    .filter(({ location }) => isValidGeoLocation(location))

  if (locations.length < 1) {
    return <Card header={header}>{renderError()}</Card>
  }
  const missingLocations = properties.length - locations.length

  const bounds = getBounds(
    locations.map(({ location }) => [location.lat, location.lng]),
    false,
    mapPadding,
  )
  const center = getBoundingBoxCenter(bounds)

  return (
    <Card header={header}>
      <FlexBox direction="Column" style={style}>
        {missingLocations > 0 && (
          <MessageStrip design="Negative" hideCloseButton className={styles.messageStrip}>
            {t('pages.properties.portfolio.overview.locations.warning', {
              count: missingLocations,
              total: properties.length,
            })}
          </MessageStrip>
        )}
        <EditableLeafletMapWithPolygonAndPropertyMarkers
          color={blueOptions}
          markers={locations}
          isEditMode={false}
          newPolygonPoints={[]}
          setNewPolygonPoints={() => {}}
          uploadedPolygon={[]}
          propertyData={propertyData}
          isLoading={isLoadingProperties}
          isError={isErrorProperties}
          center={center}
          setCenter={() => {}}
          bounds={bounds}
          setBounds={() => {}}
          className={className}
        />
      </FlexBox>
    </Card>
  )
}

PropertyPortfolioOverviewMapCard.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      uuid: PropTypes.string,
      description: PropTypes.string,
      type_name: PropTypes.string,
      address: PropTypes.object,
      geo_location: PropTypes.shape({
        latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      linkedDeal: PropTypes.string,
      creationDate: PropTypes.string,
    }),
  ).isRequired,
  propertyData: PropTypes.shape({
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
        name: PropTypes.string,
        address: PropTypes.shape({
          country: PropTypes.string,
          street: PropTypes.string,
          houseNumber: PropTypes.string,
          zipCode: PropTypes.string,
          city: PropTypes.string,
        }),
        propertyType: PropTypes.string,
        location: PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        linkedDeal: PropTypes.string,
        creationDate: PropTypes.string,
      }),
    ),
  }),
  isLoadingProperties: PropTypes.bool,
  isErrorProperties: PropTypes.bool,
  header: PropTypes.node,
  mapPadding: PropTypes.number,
  renderError: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default PropertyPortfolioOverviewMapCard
