import { ObjectPageSection, ObjectPageSubSection } from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CustomAssessments from 'components/domains/custom-assessments/CustomAssessments'
import { useDealWorkingVersionToolbarButtons } from 'components/domains/deals/deal-adjustment/useDealWorkingVersionToolbarButtons'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useCustomAssessmentsHelper from 'hooks/services/central-data/custom-assessments/useCustomAssessmentsHelper'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'
import DealProperties from 'routes/deals/properties/DealProperties'
import DealPropertiesAppreciation from 'routes/deals/properties/DealPropertiesAppreciation'

const page = 'FINANCED_OBJECTS'

const DealPropertiesTabs = () => {
  const {
    deal: { dealUuid },
  } = useContext(DealContext)
  const { toggleWorkingVersionButton, manageWorkingVersionButton } =
    useDealWorkingVersionToolbarButtons()
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.detail.properties' })

  const {
    isFetching: isCustomAssessmentsFetching,
    isError: isCustomAssessmentsError,
    showCustomAssessments,
  } = useCustomAssessmentsHelper({
    entityType: cwpEntityTypes.DEAL,
    page,
  })

  const text = useMemo(
    () => ({
      breadcrumb: t('title'),
      overviewTab: t('overview.tab'),
      appreciationTab: t('appreciation.tab'),
    }),
    [t],
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [dealUuid],
  )

  const headerActions = createSegmentedHeaderActions(
    [toggleWorkingVersionButton, manageWorkingVersionButton],
    [<CreateDealEventAction key="create-event-action" />, markFavoriteAction],
  )

  const standardAssessments = useMemo(() => <DealPropertiesAppreciation />, [])

  const showCustomAssessmentsSubsection = useMemo(
    () => isCustomAssessmentsFetching || isCustomAssessmentsError || showCustomAssessments,
    [isCustomAssessmentsFetching, isCustomAssessmentsError, showCustomAssessments],
  )

  const assessmentsTab = useMemo(() => {
    if (showCustomAssessmentsSubsection) {
      return (
        <ObjectPageSection id="appreciation" titleText={text.appreciationTab}>
          <ObjectPageSubSection
            titleText={t('appreciation.tab.subsections.standard-assessments')}
            id="standard-assessments"
          >
            {standardAssessments}
          </ObjectPageSubSection>
          <ObjectPageSubSection
            titleText={t('appreciation.tab.subsections.custom-assessments')}
            id="custom-assessments"
          >
            {<CustomAssessments entityId={dealUuid} entityType={cwpEntityTypes.DEAL} page={page} />}
          </ObjectPageSubSection>
        </ObjectPageSection>
      )
    }
    return (
      <ObjectPageSection id="appreciation" titleText={text.appreciationTab}>
        {standardAssessments}
      </ObjectPageSection>
    )
  }, [dealUuid, showCustomAssessmentsSubsection, standardAssessments, t, text.appreciationTab])

  return (
    <DealDetailsWrapper
      childBreadcrumbs={{ text: text.breadcrumb }}
      isTabPage={true}
      additionalProperties={{ includeUrlQuery: true }}
      actions={headerActions}
    >
      <ObjectPageSection id="overview" titleText={text.overviewTab}>
        <DealProperties />
      </ObjectPageSection>
      {assessmentsTab}
    </DealDetailsWrapper>
  )
}

export default DealPropertiesTabs
