const SETTINGS = {
  DEFAULT_ZOOM: { x1: 0, x2: 0 },
  ASPECT: 1.1,
  MIN_WIDTH: 235,
  MIN_HEIGHT: 144,
  MAX_HEIGHT: 500,
  GREEN: '#198244',
  RED: '#dc0c0f',
  YAXIS_WIDTH: 90,
  STROKE_DASH: '5 4',
  BASELINE_COLOR: 'orange',
}

export default SETTINGS
