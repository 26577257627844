/**
 * Returns the new cursor position after the input value has been updated.
 * @param {number} oldPos - The old cursor position.
 * @param {string} inputValue - The input value before the update.
 * @param {string} formattedInputValue - The input value after the update.
 * @returns {number} The new cursor position.
 */
export const getDealCashFlowNewCursorPosition = (
  oldPos,
  inputValue,
  inputValueWithEvaluatedShorthands,
  formattedInputValue,
  thousandsSeparator,
) => {
  // checks whether the input value contains a shorthand notation
  if (/[bmkBMK]/.test(inputValue)) {
    return formattedInputValue.length
  }

  const countSeparators = (value, separator) => value.split(separator).length - 1

  const oldSeparatorsBeforeCursor = countSeparators(inputValue.slice(0, oldPos), thousandsSeparator)
  const newSeparatorsBeforeCursor = countSeparators(
    formattedInputValue.slice(0, oldPos),
    thousandsSeparator,
  )
  const differenceSeparators = newSeparatorsBeforeCursor - oldSeparatorsBeforeCursor

  return oldPos + differenceSeparators
}
