export const MAX_COLLAPSED_COMMENT_LENGTH = 200
export const MAX_COMMENT_LENGTH = 1000

export const DEAL_STATUS_RANKS = Object.freeze({
  CREDIT_APPROVED: 50,
  SIGNED: 100,
  DEAD: 999,
})

export const DATA_SOURCES = Object.freeze({
  NEW_BUSINESS: 'newBusiness',
  EXISTING_BUSINESS: 'existingBusiness',
})

export const HTTP_STATUS_CODES = Object.freeze({
  CONFLICT: 409,
  BAD_REQUEST: 400,
  BAD_GATEWAY: 502,
})

export const CUSTOM_FIELD_TYPES = Object.freeze({
  DATE: 'DATE',
  DATE_TIME: 'DATE_TIME',
  STRING: 'STRING',
  BOOLEAN: 'BOOLEAN',
  CURRENCY: 'CURRENCY',
  MEASUREMENT: 'MEASUREMENT',
  ENUM: 'ENUM',
  PERCENTAGE: 'PERCENTAGE',
  NUMBER: 'NUMBER',
  INTEGER: 'INTEGER',
})
