import {
  FlexBox,
  FlexBoxDirection,
  ValueState,
  VerticalAlign,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import DealCashflowInputParametersParameterSelect from 'components/domains/deals/deal-cashflow/input-parameters/DealCashflowInputParametersParameterSelect'
import DealCashflowInputParametersTableButtons from 'components/domains/deals/deal-cashflow/input-parameters/DealCashflowInputParametersTableButtons'
import DealCashflowInputParametersUnitSelect from 'components/domains/deals/deal-cashflow/input-parameters/DealCashflowInputParametersUnitSelect'
import DealCashflowInputParametersValueInput from 'components/domains/deals/deal-cashflow/input-parameters/DealCashflowInputParametersValueInput'
import styles from 'components/domains/deals/deal-cashflow/input-parameters/useCashflowInputParametersColumns.module.css'
import { useCashflowParametersColumns } from 'components/domains/deals/deal-cashflow/parameters/useCashflowParametersColumns'

const parameterEditCellProps = {
  cell: PropTypes.object.isRequired,
  setEditData: PropTypes.func.isRequired,
}
const ParameterEditCell = ({ cell, setEditData }) => (
  <FlexBox direction={FlexBoxDirection.Column} className={styles.parameterCell}>
    {cell?.value.map((parameter, index) => (
      <DealCashflowInputParametersParameterSelect
        key={index}
        parameter={parameter}
        index={index}
        cell={cell}
        setEditData={setEditData}
      />
    ))}
  </FlexBox>
)
ParameterEditCell.propTypes = parameterEditCellProps

const parameterValueEditCellProps = {
  cell: PropTypes.object.isRequired,
  valueState: PropTypes.oneOf(Object.values(ValueState)),
  setEditData: PropTypes.func.isRequired,
}
const ParameterValueEditCell = ({ cell, valueState, setEditData }) => (
  <div className={styles.parameterValueCell}>
    {cell?.value?.length === 0 ? (
      <DealCashflowInputParametersTableButtons
        className={styles.tableButtons}
        cell={cell}
        setEditData={setEditData}
        showAdd
      />
    ) : (
      cell?.value.map((parameter, index) => (
        <Fragment key={index}>
          <DealCashflowInputParametersValueInput
            className={styles.parameterValueInput}
            cell={cell}
            setEditData={setEditData}
            parameter={parameter}
            valueState={valueState}
          />
          <DealCashflowInputParametersUnitSelect
            className={styles.parameterUnitSelect}
            cell={cell}
            setEditData={setEditData}
            parameter={parameter}
          />
          <DealCashflowInputParametersTableButtons
            className={`${styles.tableButtons} ${
              parameter.isSaveLoading && styles.tableButtonsDisabled
            }`}
            cell={cell}
            parameter={parameter}
            setEditData={setEditData}
            showAdd={index === 0}
          />
        </Fragment>
      ))
    )}
  </div>
)
ParameterValueEditCell.propTypes = parameterValueEditCellProps

const useCashflowInputParametersColumnsEditMode = (setEditData, valueState) => {
  const columnDefinitions = useCashflowParametersColumns()

  return columnDefinitions.map((columnDefinition) => {
    switch (columnDefinition.accessor) {
      case 'parameter':
        return {
          ...columnDefinition,
          vAlign: VerticalAlign.Middle,
          Cell: (cellProps) => (
            <ParameterEditCell cell={cellProps.cell} setEditData={setEditData} />
          ),
        }
      case 'value':
        return {
          ...columnDefinition,
          minWidth: 350,
          vAlign: VerticalAlign.Middle,
          Cell: (cellProps) => (
            <ParameterValueEditCell
              cell={cellProps.cell}
              valueState={valueState}
              setEditData={setEditData}
            />
          ),
        }
      default:
        return columnDefinition
    }
  })
}

export { useCashflowInputParametersColumnsEditMode }
