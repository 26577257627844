import { FlexBox, FlexBoxDirection, ObjectStatus, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getObjectStatusForEventStatus } from 'api/events/status'
import styles from 'components/domains/properties/monitoring/market-valuation/table-cells/BusinessEventCell.module.css'
import Entity from 'components/ui/data/Entity'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useEventMini from 'hooks/services/business-events-and-tasks/events/useEventMini'
import paths from 'routes/paths'

const BusinessEventCell = ({ eventId }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.property-monitoring.market-valuation-confirmation.table.columns.event',
  })

  const { isFetching, isError, data: event } = useEventMini(eventId)

  const eventLink = useMemo(
    () => `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}`,
    [eventId],
  )
  const { translationKey: statusTranslationKey, objectStatus } = useMemo(
    () => getObjectStatusForEventStatus(event?.status),
    [event?.status],
  )

  const errorText = useMemo(
    () => <Text className={styles.errorText}>{t('error.description')}</Text>,
    [t],
  )

  const renderContent = useCallback(
    () => (
      <FlexBox direction={FlexBoxDirection.Column}>
        <Entity name={event?.info?.name} link={eventLink} openInNewTab />
        <Text className={styles.businessPartnerCellItem}>{event?.displayId}</Text>
        <ObjectStatus className={styles.businessPartnerCellItem} inverted state={objectStatus}>
          {tNoPrefix(statusTranslationKey)}
        </ObjectStatus>
      </FlexBox>
    ),
    [event, eventLink, objectStatus, statusTranslationKey, tNoPrefix],
  )

  return (
    <SmallLoadingWrapper
      isLoading={isFetching}
      isError={isError}
      renderContent={renderContent}
      error={errorText}
    />
  )
}

BusinessEventCell.propTypes = {
  eventId: PropTypes.string.isRequired,
}

export default BusinessEventCell
