import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DecisionProcessSelectSetupDialog from 'components/domains/business-events-and-tasks/decision-process/setups/DecisionProcessSelectSetupDialog'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useDecisionSetupTemplates from 'hooks/services/business-events-and-tasks/decision-process/templates/useDecisionSetupTemplates'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionProcessSelectSetupButton = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.select-setup',
  })

  const {
    event: {
      info: { code: eventCode },
    },
  } = useContext(BusinessEventsAndTasksContext)

  const {
    isLoading,
    isError,
    data: decisionSetupTemplateData,
  } = useDecisionSetupTemplates({ eventCode })

  const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false)

  const handleSelectButtonClicked = useCallback(() => {
    setIsSelectDialogOpen(true)
  }, [])

  const renderContent = useCallback(() => {
    const { decisionSetupTemplates } = decisionSetupTemplateData

    if (decisionSetupTemplates && decisionSetupTemplates.length > 0) {
      return (
        <>
          <Button design={ButtonDesign.Emphasized} onClick={handleSelectButtonClicked}>
            {t('button.text')}
          </Button>
          {isSelectDialogOpen && (
            <DecisionProcessSelectSetupDialog
              isOpen={isSelectDialogOpen}
              setIsOpen={setIsSelectDialogOpen}
              decisionSetupTemplates={decisionSetupTemplates}
            />
          )}
        </>
      )
    }

    return <></>
  }, [decisionSetupTemplateData, handleSelectButtonClicked, t, isSelectDialogOpen])

  return (
    <LoadingStateWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderContent}
      errorTitle={t('loading.error.title')}
      errorDescription={t('loading.error.description')}
      loadingTitle={t('loading.title')}
      loadingDescription={t('loading.description')}
    />
  )
}

export default DecisionProcessSelectSetupButton
