import {
  AnalyticalTableVisibleRowCountMode,
  Icon,
  IllustratedMessage,
  ToolbarSeparator,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { getPublishedRentRollContentDefinitions } from 'components/domains/properties/getPropertyRentRollReferenceData'
import styles from 'components/domains/properties/rent-roll/details/table/PropertyRentRollDetailsTable.module.css'
import PropertyRentRollDetailsTableCellComponent from 'components/domains/properties/rent-roll/details/table/PropertyRentRollDetailsTableCellComponent'
import { PropertyRentRollSubComponent } from 'components/domains/properties/rent-roll/details/table/PropertyRentRollSubComponent'
import PropertyRentRollExcelExporter from 'components/domains/properties/rent-roll/excel-export/PropertyRentRollExcelExporter'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import { useRentRollColumns } from 'hooks/services/properties/rent-roll/published-version/useRentRollColumns'
import { useExpandRentalUnitsWithBusinessPartners } from 'hooks/services/properties/useExpandRentalUnitsWithBusinessPartners'
import { useUtilizationCodes } from 'hooks/services/properties/useUtilizationCodes'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyRentRollDetailsTable = ({ rentalUnits }) => {
  const { t } = useTranslation()
  const tableRef = useRef(null)

  const {
    isLoading: isLoadingUtilizationCodes,
    isError: isErrorUtilizationCodes,
    data: utilizationCodes,
  } = useUtilizationCodes()

  const { isLoading: isLoadingExpandRentalUnits, data: rentalUnitsExpanded } =
    useExpandRentalUnitsWithBusinessPartners(rentalUnits)
  const { property } = useContext(PropertyContext)

  const getRentalUnits = () => rentalUnitsExpanded ?? []
  const columnDefinitions = useRentRollColumns()

  const getOccupancyStatusCodes = () => {
    if (isLoadingUtilizationCodes || isErrorUtilizationCodes) {
      return []
    }
    return utilizationCodes.utilization_codes
  }

  const getValueHelp = () => ({
    occupancyStatusCodes: getOccupancyStatusCodes(),
    isErrorOccupancyStatus: isErrorUtilizationCodes,
  })

  const expandOrCollapseSubRows = (isExpanded) => {
    tableRef?.current?.toggleAllRowsExpanded(isExpanded)
  }

  const additionalTableActions = [
    <Icon
      className={styles.collapseIcon}
      key={'collapse-subrows'}
      name="collapse-all"
      onClick={() => expandOrCollapseSubRows(false)}
    />,
    <Icon
      className={styles.expandIcon}
      key={'expand-subrows'}
      name="expand-all"
      onClick={() => expandOrCollapseSubRows(true)}
    />,
    <ToolbarSeparator key={'export-rent-roll-separator'} />,
    <PropertyRentRollExcelExporter properties={[property]} key={'export-rent-roll'}>
      {({ onClick }) => (
        <Icon className={styles.exportIcon} name="excel-attachment" interactive onClick={onClick} />
      )}
    </PropertyRentRollExcelExporter>,
  ]

  const renderRowSubComponent = (row) => <PropertyRentRollSubComponent row={row} />

  const getTableRowData = (rentalUnit) =>
    Object.fromEntries(
      getPublishedRentRollContentDefinitions(t).map(({ contentKey }) => [
        contentKey,
        {
          value: rentalUnit[contentKey],
          cellComponent: (
            <PropertyRentRollDetailsTableCellComponent
              rentalUnit={rentalUnit}
              contentKey={contentKey}
              valueHelp={getValueHelp()}
            />
          ),
        },
      ]),
    )

  const getTableData = () =>
    getRentalUnits().map((rentalUnit) => ({
      rowKey: rentalUnit.uuid,
      ...getTableRowData(rentalUnit),
    }))

  const rentRollVisibleRows = 10
  // Needs to be in sync with the specified rowHeight in the module.css file!
  const rowHeight = 90
  return (
    <AnalyticalTableWithToolbar
      className={styles['rent-roll-published-version-table']}
      loading={isLoadingExpandRentalUnits || isLoadingUtilizationCodes}
      title={t('pages.properties.rent-roll.table.toolbar.title')}
      columns={columnDefinitions}
      data={getTableData()}
      tableHeight={rentRollVisibleRows * rowHeight}
      visibleRowCountMode={AnalyticalTableVisibleRowCountMode.Fixed}
      visibleRows={rentRollVisibleRows}
      disableColumnPopover={true}
      renderRowSubComponent={useCallback((row) => renderRowSubComponent(row), [])}
      subRowsKey="subRows"
      NoDataComponent={() => <IllustratedMessage name="NoEntries" />}
      headerRowHeight={40}
      rowHeight={rowHeight}
      ref={tableRef}
      additionalActions={additionalTableActions}
    />
  )
}

PropertyRentRollDetailsTable.propTypes = {
  rentalUnits: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default PropertyRentRollDetailsTable
