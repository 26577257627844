import {
  Button,
  ButtonDesign,
  Label,
  Toolbar,
  ToolbarDesign,
  ToolbarSpacer,
  ToolbarStyle,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { conditionTypes } from 'api/conditions/conditions'
import tableToolBarStyles from 'components/ui/tables/toolbar/TablesToolbar.module.css'
import { getConditionIndexesOfSelectedRows } from 'redux/slices/conditions/actions/helper/conditionsActionHelper'
import {
  addCondition,
  addRequirement,
  deleteSelectedRows,
} from 'redux/slices/conditions/conditionCreationSlice'

const ConditionCreationToolbar = ({ conditionType }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.dialog.external-condition-creation.table.toolbar',
  })
  const dispatch = useDispatch()
  const numberOfConditions = useSelector(
    (state) => state.conditions.conditionsCreation.conditions.length,
  )
  const selectedRows = useSelector((state) => state.conditions.conditionsCreation.selectedRows)

  const onAddConditionClicked = useCallback(() => {
    dispatch(addCondition({}))
  }, [dispatch])

  const isDeleteButtonDisabled = useMemo(
    () => Object.keys(selectedRows).length === 0,
    [selectedRows],
  )

  const isAddRequirementButtonDisabled = useMemo(
    () => getConditionIndexesOfSelectedRows(selectedRows).length === 0,
    [selectedRows],
  )

  const onDeleteClicked = useCallback(() => {
    dispatch(deleteSelectedRows())
  }, [dispatch])

  const onAddRequirementsClicked = useCallback(() => {
    dispatch(addRequirement())
  }, [dispatch])

  return (
    <Toolbar
      design={ToolbarDesign.Auto}
      toolbarStyle={ToolbarStyle.Clear}
      className={tableToolBarStyles.toolbar}
    >
      <Label className={tableToolBarStyles.titleLabel}>{t('title', { numberOfConditions })}</Label>
      <ToolbarSpacer />
      <Button onClick={onAddConditionClicked} design={ButtonDesign.Default}>
        {t('button.add-condition')}
      </Button>
      {conditionType === conditionTypes.external && (
        <Button
          disabled={isAddRequirementButtonDisabled}
          onClick={onAddRequirementsClicked}
          design={ButtonDesign.Transparent}
        >
          {t('button.add-requirement')}
        </Button>
      )}
      <Button
        disabled={isDeleteButtonDisabled}
        design={ButtonDesign.Transparent}
        onClick={onDeleteClicked}
      >
        {tNoPrefix('buttons.delete')}
      </Button>
    </Toolbar>
  )
}

ConditionCreationToolbar.propTypes = {
  conditionType: PropTypes.oneOf(Object.values(conditionTypes)),
}

export default ConditionCreationToolbar
