const openRequirementReminderDialog = (
  state,
  { payload: { requirementId, conditionId, requirementStatus } },
) => {
  state.reminderDialog = {
    isOpen: true,
    requirementId,
    conditionId,
    requirementStatus,
  }
}

export default openRequirementReminderDialog
