import * as UI5React from '@fioneer/ui5-webcomponents-react'
import { useModalsContext } from '@fioneer/ui5-webcomponents-react/dist/internal/ModalsContext'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import { createRef, forwardRef, useRef, useImperativeHandle, useCallback, useId } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/dialog/Dialog.module.css'
import DialogPrimaryButton from 'components/ui/dialog/DialogPrimaryButton'
import DialogSecondaryButton from 'components/ui/dialog/DialogSecondaryButton'

const DialogSize = Object.freeze({
  S: 'S',
  M: 'M',
  L: 'L',
  XL: 'XL',
})

const dialogPropTypes = {
  /**
   * Defines the accessible name of the component.
   */
  accessibleName: PropTypes.string,
  /**
   * Defines the IDs of the elements that label the component.
   */
  accessibleNameRef: PropTypes.string,
  /**
   * Allows setting a custom role. Available options are:
   *
   * *   `Dialog`
   * *   `None`
   * *   `AlertDialog`
   */
  accessibleRole: PropTypes.oneOf(Object.values(UI5React.PopupAccessibleRole)),
  /**
   * Defines the content of the Popup.
   */
  children: PropTypes.node.isRequired,
  /**
   * CSS Class Name which will be appended to the most outer element of a component.
   * Use this prop carefully, overwriting CSS rules might break the component.
   */
  className: PropTypes.string,
  /**
   * Custom close button in the dialog footer.
   *
   * ⚠️ The Dialog already has a default close button,
   * and normally you shouldn't (need to) customize it:
   *
   * - the close label shall be consistent throughout the app
   * - if you want to implement a custom close behavior, e.g. a "confirm"
   *   popover, use `onBeforeClose` (Dialogs can also be closed by pressing
   *   the "Esc" key)
   *
   * If you have another legitimate use case to customize the close button,
   * use a `<DialogSecondaryButton>` for it.
   */
  closeButton: PropTypes.node,
  /**
   * CSS Class Name which will be appended to the header of a component.
   */
  headerClassName: PropTypes.string,
  /**
   * CSS Class Name which will be appended to the header title of a component.
   */
  headerTitleClassName: PropTypes.string,
  /**
   * Defines the header text.
   */
  headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /**
   * Defines the ID of the HTML Element, which will get the initial focus.
   */
  initialFocus: PropTypes.string,
  /**
   * Custom content to be placed on the left side of the Dialog footer
   */
  leftFooterContent: PropTypes.node,
  /**
   * Indicates if the element is open
   */
  open: PropTypes.bool,
  /**
   * Determines where the Dialog will be mounted. Defaults to the `<body>` element.
   */
  parentElement: PropTypes.instanceOf(HTMLElement),
  /**
   * Defines if the focus should be returned to the previously focused element, when the popup closes.
   */
  preventFocusRestore: PropTypes.bool,
  /**
   * The dialog footer button to trigger the dialog's primary action.
   * Use the `<DialogPrimaryButton>` component for the intended design:
   *
   * ```jsx
   * import Dialog, { DialogPrimaryButton } from 'components/ui/dialog/Dialog'
   * ```
   */
  primaryButton: PropTypes.node,
  /**
   * Custom content to be placed on the right side of the Dialog header
   */
  rightHeaderContent: PropTypes.node,
  /**
   * The dialog footer button to trigger the dialog's secondary action.
   * Use the `<DialogSecondaryButton>` component for the intended design:
   *
   * ```jsx
   * import Dialog, { DialogSecondaryButton } from 'components/ui/dialog/Dialog'
   * ```
   */
  secondaryButton: PropTypes.node,
  /**
   * Determines the initial width of the dialog before resizing.
   *
   * Use `DialogSize` to set a value.
   *
   * ```jsx
   * import Dialog, { DialogSize } from 'components/ui/dialog/Dialog'
   * ```
   *
   * Options:
   * - `DialogSize.S`: 320px
   * - `DialogSize.M`: 640px (default)
   * - `DialogSize.L`: 960px
   * - `DialogSize.XL`': 1280px
   */
  size: PropTypes.oneOf(Object.values(DialogSize)),
  /**
   * Fired after the component is closed. **This event does not bubble.**
   */
  onAfterClose: PropTypes.func,
  /**
   * Fired after the component is opened. **This event does not bubble.**
   */
  onAfterOpen: PropTypes.func,
  /**
   * Fired before the component is closed. This event can be cancelled, which will prevent the popup from closing. **This event does not bubble.**
   */
  onBeforeClose: PropTypes.func,
  /**
   * Fired before the component is opened. This event can be cancelled, which will prevent the popup from opening. **This event does not bubble.**
   */
  onBeforeOpen: PropTypes.func,
}

/**
 * @typedef {PropTypes.InferProps<typeof dialogPropTypes>} DialogProps
 * @typedef {UI5React.DialogDomRef} DialogRef
 */

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<DialogProps> & React.RefAttributes<DialogRef>>}
 */
const Dialog = forwardRef(
  (
    {
      children,
      headerText,
      className = '',
      headerClassName = '',
      headerTitleClassName = '',
      size = DialogSize.M,
      parentElement = document.body,
      primaryButton = null,
      secondaryButton = null,
      closeButton: customCloseButton,
      leftFooterContent = null,
      rightHeaderContent = null,
      ...otherProps
    },
    forwardedDialogRef,
  ) => {
    const internalDialogRef = useRef(null)
    useImperativeHandle(forwardedDialogRef, () => internalDialogRef.current)

    const { t } = useTranslation()

    const onCloseButtonClick = useCallback(() => {
      const dialog = internalDialogRef.current
      if (dialog) {
        dialog.close()
      }
    }, [internalDialogRef])

    const closeButton = customCloseButton ?? (
      <DialogSecondaryButton onClick={onCloseButtonClick}>
        {t('buttons.cancel')}
      </DialogSecondaryButton>
    )

    const dialogHeader = (
      <UI5React.Bar
        design={UI5React.BarDesign.Header}
        className={`${styles.header} ${headerClassName}`.trim()}
        startContent={
          <UI5React.Title className={compact([styles.title, headerTitleClassName]).join(' ')}>
            {headerText}
          </UI5React.Title>
        }
        endContent={rightHeaderContent}
      />
    )

    const dialogFooter = (
      <UI5React.Bar
        design={UI5React.BarDesign.Footer}
        className={styles.footer}
        startContent={leftFooterContent}
        endContent={
          <>
            {secondaryButton}
            {primaryButton}
            {closeButton}
          </>
        }
      />
    )

    const dialog = (
      <UI5React.Dialog
        {...otherProps}
        ref={internalDialogRef}
        className={`${styles.dialog} ${styles[`dialogSize${size}`]} ${className}`}
        draggable
        resizable
        state={UI5React.ValueState.None}
        stretch={false}
        header={dialogHeader}
        footer={dialogFooter}
      >
        {children}
      </UI5React.Dialog>
    )

    return parentElement ? createPortal(dialog, parentElement) : dialog
  },
)

Dialog.displayName = 'Dialog'

Dialog.propTypes = dialogPropTypes

/**
 * @returns {(props: DialogProps, container?: HTMLElement) => { ref: DialogRef, close: () => void }}
 */
const useShowDialog = () => {
  const { setModal } = useModalsContext()
  const id = useId()

  return useCallback(
    (props, container) => {
      const ref = createRef()

      if (!setModal) {
        throw new Error(
          'useShowDialog must be used in a component that is a child of the UI5 ThemeProvider',
        )
      }

      setModal({
        type: 'set',
        payload: {
          Component: Dialog,
          props: {
            ...props,
            open: true,
            onAfterClose: (event) => {
              if (typeof props.onAfterClose === 'function') {
                props.onAfterClose(event)
              }
              setModal({
                type: 'reset',
                payload: { id },
              })
            },
            parentElement: null,
          },
          ref,
          container,
          id,
        },
      })

      const close = () => {
        ref.current?.close()
      }

      return {
        ref,
        close,
      }
    },
    [id, setModal],
  )
}

export { DialogPrimaryButton, DialogSecondaryButton, DialogSize, useShowDialog }

export default Dialog
