import { TextArea } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import styles from 'components/domains/properties/valuation/table/table-cells/edit/ValuationRequestsTableNoteEdit.module.css'

const ValuationRequestsNoteMaxLength = 255

/**
 *  @typedef {PropType.InferProps<typeof ValuationsRequestsTableNoteEdit.propTypes>} ValuationsRequestsTableNoteCell
 */
const ValuationsRequestsTableNoteEdit = ({ note, onChange, disabled }) => {
  const handleOnNoteChange = useCallback(
    ({ target: { value: noteInput } }) => {
      onChange(noteInput)
    },
    [onChange],
  )
  return (
    <TextArea
      id="edit-note-text-area"
      className={styles.noteTextArea}
      maxlength={ValuationRequestsNoteMaxLength}
      value={note}
      onInput={handleOnNoteChange}
      disabled={disabled}
    />
  )
}

ValuationsRequestsTableNoteEdit.propTypes = {
  note: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default ValuationsRequestsTableNoteEdit
