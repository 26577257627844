import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js'
import {
  FlexBox,
  FlexBoxDirection,
  MultiComboBox,
  MultiComboBoxItem,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/deal-adjustment/edit/EditDealAdjustmentDialogContent.module.css'
import Labeled from 'components/ui/data/Labeled'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import TextAreaWithMaxCharacterRestriction from 'components/ui/input/TextAreaWithMaxCharacterRestriction'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useFormMapped } from 'hooks/form/useFormMapped'
import { useFormValidations } from 'hooks/form/useFormValidations'
import { useAdjustmentTypesConfig } from 'hooks/services/deals/deal-adjustment/useAdjustmentTypesConfig'

const CREATE_WORKING_VERSION_COMMENT_MAX_LENGTH = 5000

const EditDealAdjustmentDialogContent = ({ additionalFormElement = null, readonly = false }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.deal-adjustment.modal',
  })
  const { t: tNoPrefix } = useTranslation()
  const {
    register,
    control,
    formState: { isSubmitting },
  } = useFormMapped()
  const { required, minLength, maxLength } = useFormValidations()
  const {
    data: { adjustmentTypes } = {},
    isLoading: isAdjustmentTypesLoading,
    isError: isAdjustmentTypesError,
  } = useAdjustmentTypesConfig()
  const deprecatedSuffix = ` (${tNoPrefix('components.ui.input.deprecated')})`
  const adjustmentTypesWithMarkedDeprecated = useMemo(() => {
    if (adjustmentTypes === undefined) return null
    else
      return adjustmentTypes.map((adjustmentType) => ({
        displayName:
          adjustmentType.displayName + (adjustmentType.deprecated ? deprecatedSuffix : ''),
        code: adjustmentType.code,
        deprecated: adjustmentType.deprecated,
      }))
  }, [adjustmentTypes, t])
  const content = (
    <>
      {additionalFormElement}
      <FlexBox direction={FlexBoxDirection.Column} className={styles.gap}>
        <Labeled label={t('types')} vertical showColon required={!readonly}>
          <Controller
            control={control}
            name="types"
            rules={{
              validate: {
                required: required(),
                minLength: minLength(1),
              },
            }}
            render={({
              field: { onChange, onBlur, name, value, disabled, ref },
              fieldState: { invalid, error },
            }) => (
              <MultiComboBox
                ref={ref}
                id="deal-adjustment-types"
                style={{ width: '100%' }}
                name={name}
                disabled={readonly || disabled || isSubmitting}
                valueState={invalid ? 'Error' : undefined}
                valueStateMessage={<Text wrapping>{error?.message}</Text>}
                onBlur={onBlur}
                onSelectionChange={async (evt) => {
                  await onChange(evt?.detail?.items?.map?.((type) => type.dataset.key))
                  await onBlur()
                }}
                readonly={readonly}
              >
                {adjustmentTypesWithMarkedDeprecated?.map(({ code, displayName }) => (
                  <MultiComboBoxItem
                    key={code}
                    text={displayName}
                    selected={value?.includes?.(code)}
                    data-key={code}
                  />
                ))}
              </MultiComboBox>
            )}
          />
        </Labeled>
        <Labeled
          label={t('comment')}
          vertical
          showColon
          required={!readonly}
          className={styles.commentLabelGap}
        >
          <TextAreaWithMaxCharacterRestriction
            id={`edit-deal-adjustment-comment`}
            maxLength={CREATE_WORKING_VERSION_COMMENT_MAX_LENGTH}
            rows="9"
            growing="false"
            disabled={readonly || isSubmitting}
            {...register('comment', {
              validate: {
                required: required(),
                minLength: minLength(1),
                maxLength: maxLength(CREATE_WORKING_VERSION_COMMENT_MAX_LENGTH),
              },
            })}
            readOnly={readonly}
          />
        </Labeled>
      </FlexBox>
    </>
  )

  return (
    <RequestStateResolver
      center
      errorToDisplay={<ErrorDataUnavailableInContent />}
      isLoading={isAdjustmentTypesLoading}
      isError={isAdjustmentTypesError}
      renderContent={() => content}
    />
  )
}

EditDealAdjustmentDialogContent.propTypes = {
  additionalFormElement: PropTypes.element,
  i18nPrefix: PropTypes.string,
  readonly: PropTypes.bool,
}

export default EditDealAdjustmentDialogContent
