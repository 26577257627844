import { createSlice } from '@reduxjs/toolkit'
import addConditionImport from 'redux/slices/conditions/actions/condition-creation/addCondition'
import addConditionsImport from 'redux/slices/conditions/actions/condition-creation/addConditions'
import addRequirementImport from 'redux/slices/conditions/actions/condition-creation/addRequirement'
import conditionApprovalLevelChangedImport from 'redux/slices/conditions/actions/condition-creation/conditionApprovalLevelChanged'
import conditionAssigneeChangedImport from 'redux/slices/conditions/actions/condition-creation/conditionAssigneeChanged'
import conditionNameChangedImport from 'redux/slices/conditions/actions/condition-creation/conditionNameChanged'
import conditionPossibleDocumentsChangedImport from 'redux/slices/conditions/actions/condition-creation/conditionPossibleDocumentsChanged'
import conditionRefNumberChangedImport from 'redux/slices/conditions/actions/condition-creation/conditionRefNumberChanged'
import conditionReferencesChangedImport from 'redux/slices/conditions/actions/condition-creation/conditionReferencesChanged'
import conditionRowHasErrorImport from 'redux/slices/conditions/actions/condition-creation/conditionRowHasError'
import conditionTypeChangedImport from 'redux/slices/conditions/actions/condition-creation/conditionTypeChanged'
import copyConditionImport from 'redux/slices/conditions/actions/condition-creation/copyCondition'
import deleteSelectedRowsImport from 'redux/slices/conditions/actions/condition-creation/deleteSelectedRows'
import requirementAssigneeChangedImport from 'redux/slices/conditions/actions/condition-creation/requirementAssigneeChanged'
import requirementDocumentTypeChangedImport from 'redux/slices/conditions/actions/condition-creation/requirementDocumentTypeChanged'
import requirementNameChangedImport from 'redux/slices/conditions/actions/condition-creation/requirementNameChanged'
import requirementRowHasErrorImport from 'redux/slices/conditions/actions/condition-creation/requirementRowHasError'
import selectAllRowsBelongingToConditionImport from 'redux/slices/conditions/actions/condition-creation/selectAllRowsBelongingToCondition'
import setAllRowsExpandedImport from 'redux/slices/conditions/actions/condition-creation/setAllRowsExpanded'
import setConditionRowsShowErrorToTrueImport from 'redux/slices/conditions/actions/condition-creation/setConditionRowsShowErrorToTrue'
import setFocusElementImport from 'redux/slices/conditions/actions/condition-creation/setFocusElement'
import setRequirementRowsShowErrorToTrueImport from 'redux/slices/conditions/actions/condition-creation/setRequirementRowsShowErrorToTrue'
import setRowExpandedImport from 'redux/slices/conditions/actions/condition-creation/setRowExpanded'
import setSelectedRowsImport from 'redux/slices/conditions/actions/condition-creation/setSelectedRows'

const initialState = {
  isStandardListItemSelectionDialogOpen: false,
  isCreationDialogOpen: false,
  conditions: [],
  selectedRows: {},
  errorRows: {},
  focusElement: {},
}

export const externalConditionCreationSlice = createSlice({
  name: 'external-conditions-creation',
  initialState,
  reducers: {
    closeStandardListItemDialog: (state) => {
      state.isStandardListItemSelectionDialogOpen = false
    },
    openStandardListItemDialog: (state) => {
      state.isStandardListItemSelectionDialogOpen = true
    },
    closeCreationDialog: (state) => {
      state.isCreationDialogOpen = false
    },
    openCreationDialog: (state) => {
      state.isCreationDialogOpen = true
    },
    addCondition: addConditionImport,
    deleteSelectedRows: deleteSelectedRowsImport,
    addConditions: addConditionsImport,
    conditionNameChanged: conditionNameChangedImport,
    requirementNameChanged: requirementNameChangedImport,
    conditionTypeChanged: conditionTypeChangedImport,
    conditionAssigneeChanged: conditionAssigneeChangedImport,
    conditionApprovalLevelChanged: conditionApprovalLevelChangedImport,
    conditionRefNumberChanged: conditionRefNumberChangedImport,
    requirementDocumentTypeChanged: requirementDocumentTypeChangedImport,
    requirementAssigneeChanged: requirementAssigneeChangedImport,
    conditionReferencesChanged: conditionReferencesChangedImport,
    conditionRowHasError: conditionRowHasErrorImport,
    requirementRowHasError: requirementRowHasErrorImport,
    setConditionRowsShowErrorToTrue: setConditionRowsShowErrorToTrueImport,
    setRequirementRowsShowErrorToTrue: setRequirementRowsShowErrorToTrueImport,
    conditionPossibleDocumentsChanged: conditionPossibleDocumentsChangedImport,
    addRequirement: addRequirementImport,
    copyCondition: copyConditionImport,
    setFocusElement: setFocusElementImport,
    setSelectedRows: setSelectedRowsImport,
    selectAllRowsBelongingToCondition: selectAllRowsBelongingToConditionImport,
    setRowExpanded: setRowExpandedImport,
    setAllRowsExpanded: setAllRowsExpandedImport,
    toggleRowExpanded: (state, { payload: conditionIndex }) => {
      if (!state.conditions[conditionIndex]) {
        return
      }
      state.conditions[conditionIndex].expanded = !state.conditions[conditionIndex].expanded
    },
    deleteCondition: (state, { payload: indexToDelete }) => {
      state.conditions = state.conditions.filter((_condition, index) => index !== indexToDelete)
    },
    reset: () => initialState,
  },
})

export const {
  closeStandardListItemDialog,
  openStandardListItemDialog,
  closeCreationDialog,
  openCreationDialog,
  addCondition,
  deleteSelectedRows,
  addConditions,
  deleteCondition,
  conditionNameChanged,
  copyCondition,
  conditionAssigneeChanged,
  conditionApprovalLevelChanged,
  conditionRefNumberChanged,
  requirementNameChanged,
  selectAllRowsBelongingToCondition,
  conditionPossibleDocumentsChanged,
  setAllRowsExpanded,
  requirementDocumentTypeChanged,
  conditionTypeChanged,
  conditionReferencesChanged,
  requirementAssigneeChanged,
  toggleRowExpanded,
  setSelectedRows,
  addRequirement,
  conditionRowHasError,
  requirementRowHasError,
  setConditionRowsShowErrorToTrue,
  setRequirementRowsShowErrorToTrue,
  setFocusElement,
  setRowExpanded,
  reset,
} = externalConditionCreationSlice.actions

export default externalConditionCreationSlice.reducer
