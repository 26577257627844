import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultiplePropertyCustomFieldsByPropertyUuids = ({ propertyUuids, options = {} }) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: propertyUuids.map((propertyUuid) => ({
        path: `/properties/${propertyUuid}/custom-fields`,
        keys: ['properties', propertyUuid, 'custom-fields'],
      })),
      translated: true,
      options: { ...options, enabled: !isEmpty(propertyUuids) },
    }),
  )

  return useMemo(
    () => ({
      isLoading: responses?.some(({ isLoading }) => isLoading),
      isError: responses?.some(({ isError }) => isError),
      isFetching: responses?.some(({ isFetching }) => isFetching),
      data: responses.map((response, index) => ({
        ...response,
        data: {
          propertyUuid: propertyUuids[index],
          ...camelize(response.data),
        },
      })),
    }),
    [propertyUuids, responses],
  )
}

export default useMultiplePropertyCustomFieldsByPropertyUuids
