import { FlexBox, FlexBoxAlignItems, FlexBoxWrap, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import RequirementsTableAttachmentCard from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableAttachmentCard'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import getDocumentsFromRoot from 'components/domains/documents/documents-table/getDocumentsFromRoot'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useDocuments from 'hooks/services/documents/useDocuments'

const RequirementsTableSubcomponentAttachments = ({
  requirementId,
  showDeleteDocumentButton = true,
}) => {
  const {
    data: documentsData,
    isLoading,
    isError,
  } = useDocuments({ entityId: requirementId, entityType: DocumentTypes.Requirement })

  const attachments = useMemo(
    () => !isLoading && !isError && getDocumentsFromRoot(documentsData),
    [isLoading, isError, documentsData],
  )

  const renderedAttachments = useCallback(
    () =>
      attachments.map((attachment) => (
        <RequirementsTableAttachmentCard
          key={attachment.id}
          requirementId={requirementId}
          documentType={attachment.type}
          documentName={attachment.name}
          showDeleteDocumentButton={showDeleteDocumentButton}
          documentId={attachment.id}
          fileExtension={attachment.fileExtension}
          keyDate={attachment.keyDate}
          versions={attachment.versions}
        />
      )),
    [attachments, requirementId, showDeleteDocumentButton],
  )

  const renderedContent = useCallback(
    () => (
      <FlexBox alignItems={FlexBoxAlignItems.Start} wrap={FlexBoxWrap.Wrap}>
        {attachments.length === 0 ? <Text /> : renderedAttachments()}
      </FlexBox>
    ),
    [attachments, renderedAttachments],
  )

  return (
    <LoadingStateWrapper isLoading={isLoading} isError={isError} renderContent={renderedContent} />
  )
}

RequirementsTableSubcomponentAttachments.propTypes = {
  requirementId: PropTypes.string.isRequired,
  showDeleteDocumentButton: PropTypes.bool,
}

export default RequirementsTableSubcomponentAttachments
