import {
  ResponsiveGridLayout,
  Input,
  FlexBox,
  FlexBoxDirection,
  Label,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { calculateExternalIdAndTypeFormErrors } from 'components/domains/markets/helper/calculateExternalIdAndTypeFormErrors'
import styles from 'components/domains/markets/panels/MarketExternalIdTypesPanel.module.css'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import useMarketConfigurationExternalIdTypes from 'hooks/services/markets/configuration/useMarketConfigurationExternalIdTypes'
import {
  setMarketExternalId,
  setMarketExternalIdType,
} from 'redux/slices/markets/marketCreationSlice'

const MarketExternalIdTypesPanel = () => {
  const { marketExternalId, marketExternalIdType } = useSelector(
    (state) => state.markets.marketCreation,
  )
  const [formErrors, setFormErrors] = useState({})
  const { t } = useTranslation('translation', { keyPrefix: 'pages.markets.overview.create-dialog' })
  const dispatch = useDispatch()

  const onExternalIdTypeChanged = useCallback(
    (_event, selectedExternalIdType) => {
      dispatch(setMarketExternalIdType(selectedExternalIdType))
      calculateExternalIdAndTypeFormErrors({
        newMarketExternalId: marketExternalId,
        newMarketExternalIdType: selectedExternalIdType,
        setFormErrors,
      })
    },
    [dispatch, marketExternalId],
  )

  const onExternalIdInput = useCallback(
    ({ target: { value: newExternalId } }) => {
      dispatch(setMarketExternalId(newExternalId))
      calculateExternalIdAndTypeFormErrors({
        newMarketExternalId: newExternalId,
        newMarketExternalIdType: marketExternalIdType,
        setFormErrors,
      })
    },
    [dispatch, marketExternalIdType],
  )

  const onExternalIdBlur = useCallback(
    ({ target: { value: newExternalId } }) => {
      calculateExternalIdAndTypeFormErrors({
        newMarketExternalId: newExternalId,
        newMarketExternalIdType: marketExternalIdType,
        setFormErrors,
      })
    },
    [marketExternalIdType],
  )

  return (
    <>
      <ResponsiveGridLayout
        columnsXL={2}
        columnsL={2}
        columnsM={2}
        columnsS={2}
        columnGap="1rem"
        rowGap="0rem"
        className={styles.marketExternalIdWrapper}
      >
        <FlexBox direction={FlexBoxDirection.Column}>
          <Label showColon>{t('external-id-type-panel.external-id')}</Label>
          <Input
            id={'market-external-id'}
            value={marketExternalId}
            onInput={onExternalIdInput}
            onBlur={onExternalIdBlur}
            valueState={formErrors.marketExternalId ? ValueState.Error : ValueState.None}
            className={styles.marketExternalIdInput}
            valueStateMessage={<Text>{t('external-id.error')}</Text>}
          />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column}>
          <Label showColon>{t('external-id-type-panel.external-id-type')}</Label>
          <LoadingSelect
            selectedKey={marketExternalIdType}
            loadingHook={useMarketConfigurationExternalIdTypes}
            selectionName="externalIdTypes"
            optionKeyName="code"
            optionDisplayName="code"
            id="filter-market-external-id-type-select"
            onChange={onExternalIdTypeChanged}
            valueState={formErrors.marketExternalIdType ? ValueState.Error : ValueState.None}
            className={styles.marketExternalIdInput}
            valueStateMessage={<Text>{t('external-id-type.error')}</Text>}
          />
        </FlexBox>
      </ResponsiveGridLayout>
    </>
  )
}

export default MarketExternalIdTypesPanel
