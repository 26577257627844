export const CUSTOMER_OPTION_ROW_TYPES = {
  CLUSTER: 'CLUSTER',
  OPTION: 'OPTION',
  OPTION_ATTRIBUTE: 'OPTION_ATTRIBUTE',
}

export const OPTION_ATTRIBUTE_DATA_TYPES = {
  DATE: 'date',
  ENUM: 'enum',
  TEXT: 'text',
  INT: 'int',
  FLOAT: 'float',
}

export const CUSTOMER_OPTIONS_COLUMN_KEYS = {
  CLUSTER_OPTION_ATTRIBUTE: 'clusterOptionAttribute',
  VALUE: 'value',
}

export const DEFAULT_DISPLAY_ROW_HEIGHT = 30
export const DEFAULT_EDIT_ROW_HEIGHT = 40

const NUMBER_OF_ITEMS_IN_DEFAULT_ROW = 2
export const TABLE_TEXT_DEFAULT_HEIGHT = 16
export const TABLE_TEXT_DEFAULT_MARGIN = 8
export const TABLE_HEADER_ROW_HEIGHT =
  TABLE_TEXT_DEFAULT_HEIGHT * NUMBER_OF_ITEMS_IN_DEFAULT_ROW + TABLE_TEXT_DEFAULT_MARGIN * 2
