import { get, groupBy } from 'lodash'
import compact from 'lodash.compact'
import { useEffect, useMemo, useState } from 'react'
import { multiPropertyValuations } from 'api/property/valuation/valuations'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useMultiPropertyImagesDownload } from 'hooks/services/business-events-and-tasks/decision-papers/useMultiPropertyImagesDownload'
import useMultipleDealProperties from 'hooks/services/deals/properties/useMultipleDealProperties'
import { usePropertiesImages } from 'hooks/services/properties/images/usePropertyImages'
import useMultiArrayPropertyValuations from 'hooks/services/properties/valuations/useMultiArrayPropertyValuations'
import { useCamelizedResponse, useCombinedQueryResults } from 'hooks/services/queryHelper'

const DEFAULT_PROPERTY_IMAGE_ORDER = 0
const DEFAULT_PROPERTY_IMAGE_META = 'Unknown'

const blobToBase64 = (blob) =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data)
    }
  })

const sortImagesGroupedByProperty = (images) =>
  Object.values(groupBy(images, 'propertyUuid'))
    .map((propertyImages) =>
      [...propertyImages].sort((a, b) => a.propertyImageOrder - b.propertyImageOrder),
    )
    .flat()

const getPropertiesInOrder = (valuationResult) => {
  const propertiesWithMarketValue = Object.entries(valuationResult?.data?.valuations || {}).map(
    ([key, value]) => ({
      property: key,
      marketValue: value?.[multiPropertyValuations.marketValue]?.value_amount?.number,
    }),
  )
  return propertiesWithMarketValue.sort(({ marketValue: a }, { marketValue: b }) => {
    const aIsNaN = isNaN(a)
    const bIsNaN = isNaN(b)
    if (aIsNaN && bIsNaN) {
      return 0
    } else if (aIsNaN) {
      return 1
    } else if (bIsNaN) {
      return -1
    }
    return Number(b) - Number(a)
  })
}

const sortImagesByPropertyMarketValue = (images, valuationResult) => {
  if (!images?.length > 0) {
    return images
  }
  const groupedImages = groupBy(images, 'propertyUuid')
  const propertiesInOrder = getPropertiesInOrder(valuationResult)
  return propertiesInOrder.flatMap((property) => groupedImages[property.property])
}

const useMultiplePortfolioFinancedAssetsInformationImages = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { dealUuids } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const [multipleParsedImages, setMultipleParsedImages] = useState({})

  // get an array of property uuids for each deal
  const {
    data: multipleDealPropertiesData,
    isLoading: isMultipleDealPropertiesLoading,
    isError: isMultipleDealPropertiesError,
  } = useCombinedQueryResults(
    useMultipleDealProperties({
      dealUuids,
    }) ?? {},
    { forceDataReturn: false },
  )

  const isFollowUpRequestEnabled = useMemo(
    () =>
      !isMultipleDealPropertiesLoading &&
      !isMultipleDealPropertiesError &&
      multipleDealPropertiesData &&
      multipleDealPropertiesData.some((property) => property.dealProperties?.length > 0),
    [isMultipleDealPropertiesError, isMultipleDealPropertiesLoading, multipleDealPropertiesData],
  )

  // create an array of arrays of property uuids related to a deal id
  const multiplePropertyUuidLists = useMemo(
    () =>
      isFollowUpRequestEnabled
        ? multipleDealPropertiesData?.map((dealProperty) =>
            dealProperty.dealProperties.map((property) => property.propertyUuid),
          )
        : [],
    [isFollowUpRequestEnabled, multipleDealPropertiesData],
  )

  // array version of useMultiPropertyValuations:
  // get an array of responses for each properties array (related to a deal id),
  // so one entry per deal on the highest array level
  const {
    data: multiArrayPropertyValuationsData,
    isLoading: isMultiArrayPropertyValuationsLoading,
    isError: isMultiArrayPropertyValuationsError,
  } = useMultiArrayPropertyValuations({
    propertyUuidsArrays: multiplePropertyUuidLists,
    includeAggregates: [],
    preferredCurrency: undefined,
    options: {
      enabled: isFollowUpRequestEnabled,
    },
  })

  const flatMultiplePropertyUuidLists = multiplePropertyUuidLists.flat()

  const {
    data: flatImageInformationData,
    isLoading: isPropertiesImagesLoading,
    isError: isPropertiesImagesError,
  } = useCamelizedResponse(
    usePropertiesImages(flatMultiplePropertyUuidLists, { enabled: isFollowUpRequestEnabled }),
  )

  // re-map image information back to multiplePropertyUuidLists structure
  // based on the list index of the flat property uuid lists and the corresponding flat image response list
  const multipleImageInformationData = useMemo(
    () =>
      multiplePropertyUuidLists.map((propertyUuidList) =>
        compact(
          propertyUuidList?.flatMap((propertyUuid) => {
            const flatListIndex = flatMultiplePropertyUuidLists.findIndex(
              (flatListUuid) => flatListUuid === propertyUuid,
            )
            const imageData =
              flatListIndex >= 0 ? flatImageInformationData?.[flatListIndex] : undefined
            return imageData?.length
              ? imageData.map((imageInfo) => ({
                  ...imageInfo,
                  propertyUuid,
                  noImage: false,
                }))
              : [
                  {
                    propertyUuid,
                    propertyImageOrder: DEFAULT_PROPERTY_IMAGE_ORDER,
                    noImage: true,
                  },
                ]
          }),
        ),
      ),
    [flatImageInformationData, flatMultiplePropertyUuidLists, multiplePropertyUuidLists],
  )

  const enableImageDownload =
    isFollowUpRequestEnabled &&
    multipleImageInformationData &&
    multipleImageInformationData?.length > 0

  const multipleDownloadableImages = useMemo(
    () =>
      multipleImageInformationData?.map((ImageInformationData) =>
        ImageInformationData.filter((imageInfo) => !imageInfo.noImage),
      ),
    [multipleImageInformationData],
  )
  const multipleNonDownloadableImages = useMemo(
    () =>
      multipleImageInformationData?.map((ImageInformationData) =>
        ImageInformationData.filter((imageInfo) => imageInfo.noImage),
      ),
    [multipleImageInformationData],
  )

  const flatMultipleDownloadableImages = multipleDownloadableImages.flat()

  const {
    data: flatImageDownloadData,
    isLoading: isMultiPropertyImagesDownloadLoading,
    isError: isMultiPropertyImagesDownloadError,
  } = useMultiPropertyImagesDownload({
    images: flatMultipleDownloadableImages || [],
    options: { enabled: enableImageDownload },
  })

  // re-map image information back to multipleImageDownloadData structure (which is the same as multiplePropertyUuidLists)
  // based on the list index of the flat image uuid lists and the corresponding flat image response list
  const multipleImageDownloadData = useMemo(
    () =>
      multipleImageInformationData.map((downloadableImages) =>
        compact(
          downloadableImages?.flatMap(({ imageUuid }) => {
            const flatListIndex = flatMultipleDownloadableImages.findIndex(
              (flatDownloadableImages) => flatDownloadableImages?.imageUuid === imageUuid,
            )
            return flatListIndex >= 0 ? flatImageDownloadData?.[flatListIndex] : undefined
          }),
        ),
      ),
    [flatImageDownloadData, flatMultipleDownloadableImages, multipleImageInformationData],
  )

  const multiplePreparedImageData = useMemo(
    () =>
      multipleDownloadableImages?.map((downloadableImages, imageDownloadIndex) => {
        const images = downloadableImages?.map((image, downloadableIndex) => ({
          imageTitle: get(image, 'meta.name', DEFAULT_PROPERTY_IMAGE_META),
          imageDescription: get(image, 'description', DEFAULT_PROPERTY_IMAGE_META),
          propertyImageOrder: get(image, 'propertyImageOrder', DEFAULT_PROPERTY_IMAGE_ORDER),
          propertyUuid: get(image, 'propertyUuid', DEFAULT_PROPERTY_IMAGE_META),
          imageData: multipleImageDownloadData?.[imageDownloadIndex]?.[downloadableIndex],
        }))

        return sortImagesGroupedByProperty(images)
      }) ?? [],
    [multipleDownloadableImages, multipleImageDownloadData],
  )

  // Apparently, React needs promised data to be saved in a state rather than directly returned.
  // Async/Await the response makes no difference.
  // This then needs to be updated using useEffect, in order to get the resolved promise.
  // However, since we need to go one level up (per deal), useEffect cannot use all of its deps,
  // since this seems to trigger a slow but steady infinite loop and eventually crash the application
  useEffect(() => {
    if (isMultiPropertyImagesDownloadLoading) {
      return
    }
    multiplePreparedImageData?.map((preparedImageData, preparedImageIndex) => {
      Promise.all(
        preparedImageData?.map(({ imageData }) =>
          // Browsers like Chrome need Promises to be rejected with an error thrown, for the actual application,
          // this just results in undefined being returned, which is what we want, if no image data is present
          imageData ? blobToBase64(imageData) : Promise.reject(new Error()).catch((_e) => {}),
        ),
      )
        .then((images) => {
          setMultipleParsedImages((prev) => ({ ...prev, ...{ [preparedImageIndex]: images } }))
        })
        // Catch so that we don't have unhandled promise rejections.
        .catch(() => {})
    }) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMultiPropertyImagesDownloadLoading])

  const multiplePropertyImages = Object.values(multipleParsedImages).map(
    (potentiallyParsedImages, parsedImageIndex) => [
      ...multiplePreparedImageData[parsedImageIndex].map((image, index) => ({
        ...image,
        imageData: potentiallyParsedImages?.[index],
      })),
      ...multipleNonDownloadableImages[parsedImageIndex],
    ],
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isAnnualReviewDealsLoading,
      isMultipleDealPropertiesLoading,
      isPropertiesImagesLoading,
      isMultiArrayPropertyValuationsLoading,
      isMultiPropertyImagesDownloadLoading,
    ],
    errorValues: [
      isAnnualReviewDealsError,
      isMultipleDealPropertiesError,
      isPropertiesImagesError,
      isMultiArrayPropertyValuationsError,
      isMultiPropertyImagesDownloadError,
    ],
    errorDetails: [annualReviewDealsError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: multiplePropertyImages?.map((imageResult, index) =>
        sortImagesByPropertyMarketValue(imageResult, multiArrayPropertyValuationsData?.[index]),
      ),
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    multiplePropertyImages,
    error,
    multiArrayPropertyValuationsData,
  ])
}

export default useMultiplePortfolioFinancedAssetsInformationImages
