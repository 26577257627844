import { useMemo } from 'react'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useSyndication from 'hooks/services/deals/syndication/useSyndication'

const declinedStatusType = '10'
const pendingStatusType = '20'

const calculateValuesForSyndicationPartner = (syndication) =>
  syndication?.counterparties
    .filter(
      ({ statusTypeCode }) =>
        statusTypeCode !== declinedStatusType && statusTypeCode !== pendingStatusType,
    )
    .map(({ amountTotal, amountTotalHeadquarter, businessPartnerId }) => {
      if (!amountTotalHeadquarter && !amountTotal) {
        return { businessPartnerId }
      }
      const headquarterValue = amountTotalHeadquarter || amountTotal
      return {
        businessPartnerId,
        original: {
          currency: amountTotal.currency,
          value: amountTotal.amount,
        },
        headquarter: {
          currency: headquarterValue.currency,
          value: headquarterValue.amount,
        },
      }
    })

const useSyndicationPartnerCalculation = ({ dealUuid }) => {
  const {
    data: syndication,
    isFetching: isSyndicationFetching,
    isError: isSyndicationError,
    error: syndicationError,
  } = useSyndication(dealUuid, { enabled: !!dealUuid })

  const mappedSyndicationValuesWithoutBPResolving = useMemo(
    () => calculateValuesForSyndicationPartner(syndication),
    [syndication],
  )

  const businessPartnersForSyndication = useMemo(
    () =>
      mappedSyndicationValuesWithoutBPResolving?.map(({ businessPartnerId }) => businessPartnerId),
    [mappedSyndicationValuesWithoutBPResolving],
  )

  const isBusinessPartnersEnabled =
    !!businessPartnersForSyndication && businessPartnersForSyndication.length > 0
  const {
    data: resolvedBusinessPartnersForSyndication,
    isFetching: isBusinessPartnerForSyndicationFetching,
    isError: isBusinessPartnerForSyndicationError,
    error: resolvedBusinessPartnersForSyndicationError,
  } = useBusinessPartnerMiniByIds(businessPartnersForSyndication, {
    enabled: isBusinessPartnersEnabled,
  })

  const mappedSyndicationValues = useMemo(
    () =>
      mappedSyndicationValuesWithoutBPResolving?.map((currentSyndication, index) => ({
        ...currentSyndication,
        name: resolvedBusinessPartnersForSyndication?.businessPartnerMinis?.[index]?.fullName,
      })),
    [mappedSyndicationValuesWithoutBPResolving, resolvedBusinessPartnersForSyndication],
  )

  return useMemo(
    () => ({
      isSyndicationFetching: isSyndicationFetching || isBusinessPartnerForSyndicationFetching,
      isSyndicationError: isSyndicationError || isBusinessPartnerForSyndicationError,
      syndicationError,
      resolvedBusinessPartnersForSyndicationError,
      mappedSyndicationValues,
    }),
    [
      isSyndicationFetching,
      isBusinessPartnerForSyndicationFetching,
      isSyndicationError,
      isBusinessPartnerForSyndicationError,
      syndicationError,
      resolvedBusinessPartnersForSyndicationError,
      mappedSyndicationValues,
    ],
  )
}

export default useSyndicationPartnerCalculation
