import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useRentalUnits = (businessPartnerId, role, options) => {
  const queryParams = new URLSearchParams()
  queryParams.append('business_partner_id', businessPartnerId)
  queryParams.append('role', role)

  return useCamelizedResponse(
    useRequest({
      path: `/rental_units?${queryParams.toString()}`,
      keys: ['rental_units', businessPartnerId, role],
      useCache: true,
      options,
    }),
  )
}
