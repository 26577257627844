import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/assignee/ConditionAssigneeDisplay.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'

const ConditionAssigneeDisplayV1 = ({ onRequestError, assigneeId }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.conditions.table.columns.assignee',
  })

  // This data loading is a necessary evil due to our inabillity to change frozen decision papers
  // Do not remove
  const { data: assigneeUser, isLoading, isError, error } = useStaffMemberById(assigneeId)

  useEffect(() => {
    if (isError) {
      onRequestError(error)
    }
  }, [error, isError, onRequestError])

  const errorComponent = useMemo(
    () => (
      <Text wrapping className={styles.error}>
        {t('error')}
      </Text>
    ),
    [t],
  )
  const renderContent = useCallback(
    () => <Text wrapping>{assigneeUser.fullName}</Text>,
    [assigneeUser],
  )

  if (!assigneeId) {
    return <Text>{'-'}</Text>
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderContent}
      error={errorComponent}
    />
  )
}

ConditionAssigneeDisplayV1.propTypes = {
  onRequestError: PropTypes.func.isRequired,
  assigneeId: PropTypes.string,
}

export default ConditionAssigneeDisplayV1
