import isNil from 'lodash.isnil'

/**
 * Normalizes strings, so they do not contain any diatrical marks (e.g. à => a, é => e).
 * 1. Split the diacritical marks from the characters
 * 2. Remove the unicode character range U+0300 - U+036F (https://en.wikipedia.org/wiki/Combining_Diacritical_Marks)
 *
 * @param {string} stringValue
 * @returns string without diacritics
 */
const removeAccentsFromString = (string) => string?.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

const removeLettersFromString = (string) => String(string).replace(/[A-Z]/gi, '')
const isEmptyOrUndefinedOrNull = (value) => value === '' || value === null || value === undefined
export const dealCashFlowFilterTypes = {
  BETWEEN_DATES: 'BETWEEN_DATES',
  BETWEEN_NUMBERS: 'BETWEEN_NUMBERS',
  CONTAINS: 'CONTAINS',
  MULTI_OBJECT_CONTAINS: 'MULTI_OBJECT_CONTAINS',
  OF_ENUM_TYPE: 'OF_ENUM_TYPE',
  MULTISELECT_ENUM: 'MULTISELECT_ENUM',
  MULTISELECT_ENUM_IN_LIST: 'MULTISELECT_ENUM_IN_LIST',
  CUSTOM: 'CUSTOM',
}

export const dealCashFlowFilterTypesEmptyValues = {
  BETWEEN_DATES: { lowerBound: '', upperBound: '' },
  BETWEEN_NUMBERS: { lowerBound: '', upperBound: '' },
  CONTAINS: '',
  OF_ENUM_TYPE: '',
  MULTISELECT_ENUM: [],
  MULTISELECT_ENUM_IN_LIST: [],
  CUSTOM: 'FILTER_NOT_APPLIED',
}

//overrides are only available for BETWEEN_DATES filter type
const useFilters = ({ overrides = {} } = {}) => {
  const overrideBetweenDates = overrides[dealCashFlowFilterTypes.BETWEEN_DATES] ?? {}
  const filters = {
    // All date values are expected to be strings in the locale format
    // Before calculating diffs, the dates will be transformed to iso format
    BETWEEN_DATES: {
      emptyValue: dealCashFlowFilterTypesEmptyValues.BETWEEN_DATES,
      type: dealCashFlowFilterTypes.BETWEEN_DATES,
      filterFunction: ({ searchValue, cell }) => {
        const dateString = cell?.value
        if (!dateString) {
          return false
        }
        const { lowerBound, upperBound } = searchValue || {}
        return (
          (!lowerBound || dateString >= lowerBound) && (!upperBound || dateString <= upperBound)
        )
      },
      ...overrideBetweenDates,
    },
    BETWEEN_NUMBERS: {
      emptyValue: dealCashFlowFilterTypesEmptyValues.BETWEEN_NUMBERS,
      type: dealCashFlowFilterTypes.BETWEEN_NUMBERS,
      filterFunction: ({ searchValue, cell }) => {
        if (isNil(cell?.value)) {
          return false
        }
        const number = Number(removeLettersFromString(cell?.value))
        const { lowerBound = Number.NEGATIVE_INFINITY, upperBound = Number.POSITIVE_INFINITY } =
          searchValue || {}
        const lowerBoundNumber = isEmptyOrUndefinedOrNull(lowerBound)
          ? Number.NEGATIVE_INFINITY
          : Number(lowerBound)
        const upperBoundNumber = isEmptyOrUndefinedOrNull(upperBound)
          ? Number.POSITIVE_INFINITY
          : Number(upperBound)
        return number >= lowerBoundNumber && number <= upperBoundNumber
      },
    },
    CONTAINS: {
      emptyValue: dealCashFlowFilterTypesEmptyValues.CONTAINS,
      type: dealCashFlowFilterTypes.CONTAINS,
      filterFunction: ({ searchValue, cell }) => {
        const text = cell?.value
        return (
          typeof searchValue === 'string' &&
          typeof text === 'string' &&
          removeAccentsFromString(text)
            ?.toLowerCase()
            .includes(removeAccentsFromString(searchValue)?.toLowerCase())
        )
      },
    },
    MULTI_OBJECT_CONTAINS: {
      emptyValue: dealCashFlowFilterTypesEmptyValues.CONTAINS,
      type: dealCashFlowFilterTypes.CONTAINS,
      filterFunction: ({ searchValue, cell }) => {
        const text = cell?.value?.filterBy
        return (
          typeof searchValue === 'string' &&
          typeof text === 'string' &&
          removeAccentsFromString(text)
            ?.toLowerCase()
            .includes(removeAccentsFromString(searchValue)?.toLowerCase())
        )
      },
    },
    OF_ENUM_TYPE: {
      emptyValue: dealCashFlowFilterTypesEmptyValues.OF_ENUM_TYPE,
      type: dealCashFlowFilterTypes.OF_ENUM_TYPE,
      filterFunction: ({ searchValue, cell, additionalFilterOptions }) => {
        const text = cell?.value
        const filterEnumKey = additionalFilterOptions?.enumValues[searchValue]
        return (
          typeof searchValue === 'string' &&
          searchValue !== '' &&
          typeof text === 'string' &&
          text?.toLowerCase() === filterEnumKey?.toLowerCase()
        )
      },
    },
    MULTISELECT_ENUM: {
      emptyValue: dealCashFlowFilterTypesEmptyValues.MULTISELECT_ENUM,
      type: dealCashFlowFilterTypes.MULTISELECT_ENUM,
      filterFunction: ({ searchValue: listOfSearchValues, cell }) => {
        const value = typeof cell?.value === 'boolean' ? String(cell?.value) : cell?.value
        return (
          (listOfSearchValues?.length && listOfSearchValues.includes(value)) ||
          !listOfSearchValues?.length
        )
      },
    },
    MULTISELECT_ENUM_IN_LIST: {
      emptyValue: dealCashFlowFilterTypesEmptyValues.MULTISELECT_ENUM_IN_LIST,
      type: dealCashFlowFilterTypes.MULTISELECT_ENUM_IN_LIST,
      filterFunction: ({ searchValue: listOfSearchValues, cell }) => {
        const values = Array.isArray(cell?.value) ? cell?.value.map((value) => String(value)) : []
        return (
          (listOfSearchValues?.length &&
            values.some((value) => listOfSearchValues.includes(value))) ||
          !listOfSearchValues?.length
        )
      },
    },
    CUSTOM: {
      emptyValue: dealCashFlowFilterTypesEmptyValues.CUSTOM,
      type: dealCashFlowFilterTypes.CUSTOM,
      filterFunction: ({ searchValue, cell, additionalFilterOptions }) =>
        additionalFilterOptions.customFilterFunction({ searchValue, cell }),
    },
  }

  return { ...filters, filterForType: (type) => filters[type] }
}

export default useFilters
