import { TextAlign, VerticalAlign } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CollateralAgreementsTableCell,
  NameCellWithLinkedEntityAndDisplayId,
  renderBooleanCell,
  renderCagCell,
  renderValueCell,
  StatusColumnCell,
} from 'routes/deals/collaterals/CollateralAgreementsTableCell'

const useCollateralsPropertiesConfig = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property-overview.insurances.collateral-agreement',
  })

  const tableConfig = useMemo(
    () => [
      {
        columnDefinition: {
          Header: t('cag-label'),
          accessor: 'cag',
          minWidth: 250,
          renderCell: ({ cell }) =>
            cell?.value?.isHeader ? (
              <StatusColumnCell cell={{ ...cell, value: cell.value.value }} t={t} enabled={false} />
            ) : (
              <NameCellWithLinkedEntityAndDisplayId cell={cell} path="/collaterals" />
            ),
        },
      },
      {
        columnDefinition: {
          Header: t('cag-cluster-label'),
          accessor: 'cagCluster',
          minWidth: 150,
          renderCell: ({ cell }) => renderCagCell({ cell }),
        },
      },
      {
        columnDefinition: {
          Header: t('cag-type-label'),
          accessor: 'cagType',
          minWidth: 150,
          renderCell: ({ cell }) => renderCagCell({ cell }),
        },
      },
      {
        columnDefinition: {
          Header: t('cag-value-label'),
          accessor: 'cagValue',
          minWidth: 120,
          renderCell: renderValueCell,
          hAlign: TextAlign.Right,
        },
      },
      {
        columnDefinition: {
          Header: t('nominal-value-label'),
          accessor: 'nominalValue',
          minWidth: 120,
          renderCell: renderValueCell,
          hAlign: TextAlign.Right,
        },
      },
      {
        columnDefinition: {
          Header: t('external-land-charge'),
          accessor: 'externalLandCharge',
          minWidth: 150,
          renderCell: renderBooleanCell,
        },
      },
      {
        columnDefinition: {
          Header: t('no-of-fin-products'),
          accessor: 'noOfFinProducts',
          minWidth: 150,
          renderCell: ({ cell }) => renderCagCell({ cell }),
          hAlign: TextAlign.Right,
        },
      },
      {
        columnDefinition: {
          Header: t('no-of-assets'),
          accessor: 'noOfAssets',
          minWidth: 100,
          renderCell: ({ cell }) => renderCagCell({ cell }),
          hAlign: TextAlign.Right,
        },
      },
    ],
    [t],
  )

  const columnDefinitions = useMemo(
    () =>
      tableConfig.map(({ columnDefinition }) => ({
        disableDragAndDrop: true,
        disableResizing: false,
        Cell: (cellProps) => (
          <CollateralAgreementsTableCell {...cellProps} renderCell={columnDefinition.renderCell} />
        ),
        ...columnDefinition,
        vAlign: VerticalAlign.Middle,
      })),
    [tableConfig],
  )
  return {
    columnDefinitions,
  }
}

export default useCollateralsPropertiesConfig
