import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const DealAdjustmentAllowedOperations = {
  CREATE: 'CREATE', // not the same as permissions, also checks other preconditions
  DELETE: 'DELETE',
  PUBLISH: 'PUBLISH',
}

// useAllowedDealAdjustmentWorkingVersion
// The backend checks both permissions and any preconditions on the deal (e.g. status, already existing working copy, etc )
const useAllowedDealAdjustmentWorkingVersion = (dealUuid) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/working-version/allowed-operations`,
      translated: true,
      useCache: true,
      keys: ['deals', dealUuid, 'working-version', 'allowed-operations'],
    }),
  )

export default useAllowedDealAdjustmentWorkingVersion
