import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import BoolQuestion from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/impairment-check/BoolQuestion'
import CommentQuestion from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/impairment-check/CommentQuestion'
import CurrencyQuestion from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/impairment-check/CurrencyQuestion'
import NumberQuestion from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/impairment-check/NumberQuestion'
import Section from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/impairment-check/Section'
import impairmentCheckSchema, {
  QUESTIONS,
  emptyCheckSheet,
  SECTIONS,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/impairment-check/impairmentCheckSchema'

const propTypes = {
  currentContent: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  isPdfView: PropTypes.bool,
  setHasContentChanges: PropTypes.func.isRequired,
}

/**
 * @typedef {object} overrides
 * @property {(newContent: string) => void} onChange
 * @property {(hasChanges: boolean) => void} setHasContentChanges
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 */
const ImpairmentCheckTile = ({
  currentContent,
  onChange,
  isEditMode,
  isPdfView,
  setHasContentChanges,
}) => {
  const content = useMemo(() => {
    const parsedContent = impairmentCheckSchema
      .optional()
      .safeParse(currentContent && JSON.parse(currentContent))

    if (!parsedContent.success) {
      // eslint-disable-next-line no-console
      console.error(
        "Couldn't parse the previous check sheet. Resetting to an empty one",
        parsedContent.error,
      )
      return emptyCheckSheet
    }

    return parsedContent.data ?? emptyCheckSheet
  }, [currentContent])

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.risk-monitoring.impairment-check',
  })

  /**
   * @typedef {import('components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/impairment-check/impairmentCheckSchema').ImpairmentCheckSchema} ImpairmentCheckSchema
   */
  /**
   * @template {keyof ImpairmentCheckSchema} Section
   * @template {keyof ImpairmentCheckSchema[Section]} Label
   * @param {object} params
   * @param {Section} params.section
   * @param {Label} params.label
   * @param {ImpairmentCheckSchema[Section][Label]} params.value
   */
  const handleChange = ({ section, label, value }) => {
    const newContent = { ...content, [section]: { ...content[section], [label]: value } }
    onChange(JSON.stringify(newContent))
    setHasContentChanges(true)
  }

  return (
    <div>
      <Section label={t(SECTIONS.impairmentCheck)}>
        <BoolQuestion
          label={t(QUESTIONS[SECTIONS.impairmentCheck].impairmentTriggerOccurred)}
          value={content[SECTIONS.impairmentCheck].impairmentTriggerOccurred}
          onChange={(newValue) =>
            handleChange({
              section: SECTIONS.impairmentCheck,
              label: QUESTIONS[SECTIONS.impairmentCheck].impairmentTriggerOccurred,
              value: newValue,
            })
          }
          isEditMode={!!isEditMode}
        />
        <BoolQuestion
          label={t(QUESTIONS[SECTIONS.impairmentCheck].qualitativeImpairmentTestDone)}
          value={content[SECTIONS.impairmentCheck].qualitativeImpairmentTestDone}
          onChange={(newValue) =>
            handleChange({
              section: SECTIONS.impairmentCheck,
              label: QUESTIONS[SECTIONS.impairmentCheck].qualitativeImpairmentTestDone,
              value: newValue,
            })
          }
          isEditMode={!!isEditMode}
        />
        <CommentQuestion
          label={t(QUESTIONS[SECTIONS.impairmentCheck].impairmentComment)}
          isPdfView={isPdfView}
          isEditMode={!!isEditMode}
          value={content[SECTIONS.impairmentCheck].impairmentComment}
          onChange={(newContent) =>
            handleChange({
              section: SECTIONS.impairmentCheck,
              label: QUESTIONS[SECTIONS.impairmentCheck].impairmentComment,
              value: newContent,
            })
          }
        />
      </Section>
      <Section label={t(SECTIONS.riskProvisioning)}>
        {isEditMode && (
          <CurrencyQuestion
            label={t(QUESTIONS[SECTIONS.riskProvisioning].currency)}
            value={content[SECTIONS.riskProvisioning].currency}
            onChange={(newValue) =>
              handleChange({
                section: SECTIONS.riskProvisioning,
                label: QUESTIONS[SECTIONS.riskProvisioning].currency,
                value: newValue,
              })
            }
            isEditMode={!!isEditMode}
          />
        )}
        <NumberQuestion
          label={t(QUESTIONS[SECTIONS.riskProvisioning].sllpTotal)}
          value={content[SECTIONS.riskProvisioning].sllpTotal}
          currency={content[SECTIONS.riskProvisioning].currency}
          onChange={(newValue) =>
            handleChange({
              section: SECTIONS.riskProvisioning,
              label: QUESTIONS[SECTIONS.riskProvisioning].sllpTotal,
              value: newValue,
            })
          }
          isEditMode={!!isEditMode}
        />
        <NumberQuestion
          label={t(QUESTIONS[SECTIONS.riskProvisioning].stageThreeTotal)}
          value={content[SECTIONS.riskProvisioning].stageThreeTotal}
          currency={content[SECTIONS.riskProvisioning].currency}
          onChange={(newValue) =>
            handleChange({
              section: SECTIONS.riskProvisioning,
              label: QUESTIONS[SECTIONS.riskProvisioning].stageThreeTotal,
              value: newValue,
            })
          }
          isEditMode={!!isEditMode}
        />
        <BoolQuestion
          label={t(QUESTIONS[SECTIONS.riskProvisioning].changeInProvisioningRequired)}
          value={content[SECTIONS.riskProvisioning].changeInProvisioningRequired}
          onChange={(newValue) =>
            handleChange({
              section: SECTIONS.riskProvisioning,
              label: QUESTIONS[SECTIONS.riskProvisioning].changeInProvisioningRequired,
              value: newValue,
            })
          }
          isEditMode={!!isEditMode}
        />
        <CommentQuestion
          label={t(QUESTIONS[SECTIONS.riskProvisioning].riskProvisioningComment)}
          isPdfView={isPdfView}
          isEditMode={!!isEditMode}
          value={content[SECTIONS.riskProvisioning].riskProvisioningComment}
          onChange={(newContent) =>
            handleChange({
              section: SECTIONS.riskProvisioning,
              label: QUESTIONS[SECTIONS.riskProvisioning].riskProvisioningComment,
              value: newContent,
            })
          }
        />
      </Section>
      <Section label={t(SECTIONS.stageThreeRequirement)}>
        <BoolQuestion
          label={t(QUESTIONS[SECTIONS.stageThreeRequirement].stageThreeNotYetIdentified)}
          value={content[SECTIONS.stageThreeRequirement].stageThreeNotYetIdentified}
          onChange={(newValue) =>
            handleChange({
              section: SECTIONS.stageThreeRequirement,
              label: QUESTIONS[SECTIONS.stageThreeRequirement].stageThreeNotYetIdentified,
              value: newValue,
            })
          }
          isEditMode={!!isEditMode}
        />
        <BoolQuestion
          label={t(QUESTIONS[SECTIONS.stageThreeRequirement].noStageThreeRequirement)}
          value={content[SECTIONS.stageThreeRequirement].noStageThreeRequirement}
          onChange={(newValue) =>
            handleChange({
              section: SECTIONS.stageThreeRequirement,
              label: QUESTIONS[SECTIONS.stageThreeRequirement].noStageThreeRequirement,
              value: newValue,
            })
          }
          isEditMode={!!isEditMode}
        />
      </Section>
    </div>
  )
}

ImpairmentCheckTile.propTypes = propTypes

export default ImpairmentCheckTile
