import {
  Text,
  Label,
  FlexBox,
  FlexBoxDirection,
  WrappingType,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/monitoring/settings/PropertyMonitoringSettingsBaselineDate.module.css'
import DialogGroup from 'components/domains/properties/monitoring/settings/dialog/DialogGroup'
import EditableDatePickerComponent from 'components/ui/input/EditableDatePickerComponent'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const PropertyMonitoringSettingsBaselineDate = ({
  isAutomaticMonitoringActivated,
  previouslySetBaselineDate = '',
  handleBaselineDateChange,
  baselineSuggestion: { hasErrorLoading, suggestionDate },
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dialog.monitoring-settings' })
  const { format, parse, localePattern } = useShortDateFormatter()

  const formattedBaselineSuggestion = () => {
    if (!isAutomaticMonitoringActivated && !hasErrorLoading && suggestionDate) {
      return format(suggestionDate)
    }
    if (previouslySetBaselineDate) {
      return format(previouslySetBaselineDate)
    }
    return ''
  }

  // Needed to activate the save button once monitoring gets enabled with a suggestionDate
  useEffect(() => {
    if (!isAutomaticMonitoringActivated && !hasErrorLoading && suggestionDate) {
      handleBaselineDateChange(suggestionDate)
    }
  })

  const handleDateChanged = ({ target: { liveValue: monitoringBaselineDate } }) => {
    if (!monitoringBaselineDate) {
      handleBaselineDateChange('')
      return
    }
    const parsedBaselineDate = parse(monitoringBaselineDate, localePattern)
    handleBaselineDateChange(parsedBaselineDate)
  }

  const todaysDate = new Date().toISOString().slice(0, 10)
  return (
    <DialogGroup headerText={t('fix-monitoring-baseline-text')}>
      <Text wrapping-type={WrappingType.Normal}>{t('baseline-explanation')}</Text>
      <FlexBox direction={FlexBoxDirection.Column}>
        <Label for="monitoring-baseline-date-picker" className={styles.baselineDatePickerLabel}>
          {t('baseline-date-label')}
        </Label>
        <EditableDatePickerComponent
          id="monitoring-baseline-date-picker"
          className={styles.baselineDatePicker}
          editable={true}
          onChange={handleDateChanged}
          placeholder={t('baseline-datepicker.placeholder')}
          value={formattedBaselineSuggestion()}
          max-date={format(todaysDate)}
          valueState={hasErrorLoading ? ValueState.Warning : ValueState.None}
          valueStateMessage={
            <span slot="valueStateMessage">{t('baseline-datepicker.warning')}</span>
          }
        />
      </FlexBox>
    </DialogGroup>
  )
}

PropertyMonitoringSettingsBaselineDate.propTypes = {
  isAutomaticMonitoringActivated: PropTypes.bool.isRequired,
  previouslySetBaselineDate: PropTypes.string,
  handleBaselineDateChange: PropTypes.func.isRequired,
  baselineSuggestion: PropTypes.exact({
    hasErrorLoading: PropTypes.bool.isRequired,
    suggestionDate: PropTypes.string,
  }).isRequired,
}

export default PropertyMonitoringSettingsBaselineDate
