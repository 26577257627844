import {
  Button,
  ButtonDesign,
  Dialog,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  TextAlign,
  Title,
  TitleLevel,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { isBadRequestError } from 'api/requests'
import styles from 'components/domains/properties/monitoring/PropertyMonitoringExportLogDialog.module.css'
import DatePickerWithoutMinWidth from 'components/ui/date-picker/DatePickerWithoutMinWidth'
import { useShowMessageBox, MessageBoxTypes } from 'components/ui/message-box/MessageBox'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useExportMonitoringLog from 'hooks/services/properties/monitoring/useExportMonitoringLog'

const PropertyMonitoringExportLogDialog = ({ open, setIsOpen, propertyUuid }) => {
  const today = DateTime.now().toISODate()
  const showMessageBox = useShowMessageBox()
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.property-monitoring.export-log-dialog',
  })
  const { parse, localePattern } = useShortDateFormatter()
  const { download: downloadLogs } = useExportMonitoringLog()
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState(today)

  const handleOnClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const handleOnAfterClose = useCallback(() => {
    setIsOpen(false)
    setToDate(today)
    setFromDate('')
  }, [setIsOpen, today])

  const handleOnExportLogButtonClicked = useCallback(() => {
    downloadLogs(
      { fromDate, toDate, propertyUuid },
      {
        onError: (error) => {
          setIsOpen(false)
          const errorMessage = isBadRequestError(error)
            ? t('response-too-large')
            : t('download-error')

          showMessageBox({
            type: MessageBoxTypes.Error,
            children: errorMessage,
          })
        },
        onSuccess: () => {
          setIsOpen(false)
        },
      },
    )
  }, [downloadLogs, fromDate, toDate, propertyUuid, setIsOpen, showMessageBox, t])

  const isInvalidDateRange = fromDate > toDate

  const footer = useMemo(
    () => (
      <FlexBox justifyContent={FlexBoxJustifyContent.End} alignItems={FlexBoxAlignItems.Center}>
        <Button
          disabled={isInvalidDateRange}
          design={ButtonDesign.Emphasized}
          onClick={handleOnExportLogButtonClicked}
        >
          {t('button.export')}
        </Button>
        <Button onClick={handleOnClose} design={ButtonDesign.Transparent}>
          {tNoPrefix('buttons.cancel')}
        </Button>
      </FlexBox>
    ),
    [handleOnClose, handleOnExportLogButtonClicked, tNoPrefix, t, isInvalidDateRange],
  )

  const handleOnChangefromDate = useCallback(
    ({ detail: { value } }) => {
      const parsedDate = parse(value, localePattern)
      setFromDate(parsedDate)
    },
    [localePattern, parse],
  )

  const handleOnChangetoDate = useCallback(
    ({ detail: { value } }) => {
      const parsedDate = parse(value, localePattern)
      setToDate(parsedDate)
    },
    [localePattern, parse],
  )

  return (
    <>
      {open &&
        createPortal(
          <Dialog
            footer={footer}
            headerText={t('title')}
            open={open}
            onAfterClose={handleOnAfterClose}
          >
            <FlexBox direction={FlexBoxDirection.Column} alignItems={TextAlign.Center}>
              <FlexBox>
                <Title level={TitleLevel.H5}>{t('date-picker-from')}</Title>
                <DatePickerWithoutMinWidth
                  onChange={handleOnChangefromDate}
                  maxDate={today}
                  valueState={isInvalidDateRange ? ValueState.Error : ValueState.None}
                  value={fromDate}
                  className={styles['fromDatePicker']}
                  formatPattern={localePattern}
                />
                <Title level={TitleLevel.H5}>{t('date-picker-until')}</Title>
                <DatePickerWithoutMinWidth
                  onChange={handleOnChangetoDate}
                  value={toDate}
                  maxDate={today}
                  className={styles['untilDatePicker']}
                  formatPattern={localePattern}
                />
              </FlexBox>
              <Title className={styles['textDescription']} level={TitleLevel.H5}>
                {t('text')}
              </Title>
            </FlexBox>
          </Dialog>,
          document.body,
        )}
    </>
  )
}

PropertyMonitoringExportLogDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  propertyUuid: PropTypes.string.isRequired,
}

export default PropertyMonitoringExportLogDialog
