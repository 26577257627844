import { BusyIndicatorSize } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import styles from 'components/domains/deals/covenants/referenced-covenant-checks/ReferencedCovenantChecksCard.module.css'
import ReferencedCovenantChecksTable from 'components/domains/deals/covenants/referenced-covenant-checks/ReferencedCovenantChecksTable'
import useCwpEntityToMainEntity from 'components/domains/deals/covenants/shared/useCwpEntityToMainEntity'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInTable } from 'components/ui/errors/ErrorDataUnavailableInTable'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useCovenantChecksWithFrontendPagination } from 'hooks/services/deals/covenants/checks/useCovenantChecks'
import { useInfiniteLoading } from 'hooks/services/useInfiniteLoading'

const validEntities = [cwpEntityTypes.DEAL, cwpEntityTypes.BUSINESS_PARTNER]

/**
 * Displays a table containing referenced covenant checks
 *
 * @param {string} entityType - cwpEntityType of the main entity
 * @param {string} entityId - Identifier of the main entity
 * @param {string} referenceEntityType - cwpEntityType of the referenced entity
 * @param {boolean} isAllowedToReadCovenants - flag whether covenant links are displayed or not
 */
const ReferencedCovenantChecksCard = ({
  isAllowedToReadCovenants,
  entityType,
  entityId,
  referenceEntityType,
}) => {
  const [sorting, setSorting] = useState({})
  const paginationDelta = 50

  const onSortingChanged = (orderField, orderDirection) => {
    setSorting({ orderDirection, orderField })
  }

  const { cwpEntityToMainEntity } = useCwpEntityToMainEntity()
  const mainEntityType = cwpEntityToMainEntity[entityType]
  const monitoringReferenceEntityType = cwpEntityToMainEntity[referenceEntityType]

  const {
    isFetching: isFetchingCovenantChecks,
    isError: isErrorCovenantChecks,
    data: {
      covenantChecks = [],
      pagination = {
        offset: 0,
        limit: 0,
        total: 0,
      },
    },
    loadMore: loadMoreCovenantChecks,
  } = useInfiniteLoading({
    requestHook: useCovenantChecksWithFrontendPagination,
    requestHookArgs: {
      mainEntityType: mainEntityType,
      mainEntityId: entityId,
      monitoringReferenceEntityType: monitoringReferenceEntityType,
      sort: sorting,
    },
    dataListElement: 'covenantChecks',
    paginationDelta: paginationDelta,
  })

  return (
    <Card>
      <div className={styles.tableWrapper}>
        <RequestStateResolver
          center
          busyIndicatorSize={BusyIndicatorSize.Medium}
          isLoading={isFetchingCovenantChecks}
          isError={isErrorCovenantChecks}
          errorToDisplay={<ErrorDataUnavailableInTable />}
          renderContent={() => (
            <ReferencedCovenantChecksTable
              covenantChecksData={covenantChecks}
              pagination={pagination}
              onSortingChanged={onSortingChanged}
              loadMore={loadMoreCovenantChecks}
              sorting={sorting}
              isAllowedToReadCovenants={isAllowedToReadCovenants}
              entityType={entityType}
            />
          )}
        />
      </div>
    </Card>
  )
}

ReferencedCovenantChecksCard.propTypes = {
  isAllowedToReadCovenants: PropTypes.bool,
  entityType: PropTypes.oneOf(validEntities).isRequired,
  referenceEntityType: PropTypes.oneOf(validEntities).isRequired,
  entityId: PropTypes.string.isRequired,
}

export default ReferencedCovenantChecksCard
