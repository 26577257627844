import { FlexBox, FlexBoxDirection, Title } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { MAX_COLLAPSED_COMMENT_LENGTH } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/constants'
import CollapsibleRichTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/rich-text/CollapsibleRichTextView'
import FormattedTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/rich-text-editor/FormattedTextView'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/authority-level-calculator/SharedAuthorityLevelCalculatorComment.module.css'

const SharedAuthorityLevelCalculatorComment = ({ comment, isPdfView = false }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.shared.authority-level-calculator.comment',
  })
  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.commentWrapper}>
      <Title className={styles.headerText} role="heading" part="title">
        {t('title')}
      </Title>
      {isPdfView ? (
        <FormattedTextView formattedText={comment} />
      ) : (
        <CollapsibleRichTextView text={comment} characterLimit={MAX_COLLAPSED_COMMENT_LENGTH} />
      )}
    </FlexBox>
  )
}

SharedAuthorityLevelCalculatorComment.propTypes = {
  comment: PropTypes.string,
  isPdfView: PropTypes.bool,
}

export default SharedAuthorityLevelCalculatorComment
