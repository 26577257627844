import PropTypes from 'prop-types'
import { useContext } from 'react'
import { decisionPaperTileTypes } from 'api/decision-paper/decisionPaperApi'
import {
  getCurrentTileVersionForTileCode,
  getLiveHookForTileCode,
  getLiveImagesForTileCode,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import useReduxTileImageLoaderEffects from 'hooks/services/business-events-and-tasks/decision-papers/redux-loader/useReduxTileImageLoaderEffects'
import useReduxTileLoaderEffects from 'hooks/services/business-events-and-tasks/decision-papers/redux-loader/useReduxTileLoaderEffects'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionPaperReduxStateAutomaticTileLiveLoader = ({
  id: tileId,
  code: tileCode,
  viewConfig,
  isEditableInMinorVersion,
}) => {
  const event = useContext(BusinessEventsAndTasksContext).event
  const useHook = getLiveHookForTileCode(tileCode)
  const currentTileVersion = getCurrentTileVersionForTileCode(tileCode)
  const { isLoading, isError, data, error } = useHook(event, tileId, tileCode)

  useReduxTileLoaderEffects({
    tileId,
    isDefaultExpanded: viewConfig.isDefaultExpanded,
    isLoading,
    isError,
    data,
    error,
    type: decisionPaperTileTypes.automatic,
    isEditableInMinorVersion,
    tileVersion: currentTileVersion,
  })

  const useImageHook = getLiveImagesForTileCode(tileCode)
  const {
    isDefaultLoader: isImagesDefaultLoader,
    isLoading: isImageLoading,
    isError: isImageError,
    data: imageData,
  } = useImageHook(event, tileId, tileCode)

  useReduxTileImageLoaderEffects({
    tileId,
    isDefaultLoader: isImagesDefaultLoader,
    isLoading: isImageLoading,
    isError: isImageError,
    data: imageData,
  })
}

DecisionPaperReduxStateAutomaticTileLiveLoader.propTypes = {
  id: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  viewConfig: PropTypes.shape({
    isDefaultExpanded: PropTypes.bool.isRequired,
  }).isRequired,
}

export default DecisionPaperReduxStateAutomaticTileLiveLoader
