import { InputType, ValueState } from '@fioneer/ui5-webcomponents-react'
import { has } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import getPropertyRentalUnitsTableContentDefinitions, {
  brandingFranchisePartnerExpandedContentKey,
  tenantExpandedContentKey,
} from 'components/domains/properties/getPropertyRentRollWorkingVersionReferenceData'
import PropertyIdInput from 'components/domains/properties/rent-roll/working-version/PropertyIdInput'

import {
  TableCheckBox,
  TableComboBox,
  TableDatePicker,
  TableInput,
  TablePropertyRentalUnitBusinessPartnerInput,
  TableSelectWithFooter,
} from 'components/domains/properties/rent-roll/working-version/rental-units-table/PropertyRentalUnitsWorkingVersionTableInput'
import { getRentalUnitWorkingVersionRowKey } from 'components/domains/properties/rent-roll/working-version/utils/rentRollWorkingVersionMappers'
import { useAreaMeasurementUnitFormatter } from 'hooks/i18n/useI18n'

export const usePropertyRentalUnitsWorkingVersionDataMapper = ({
  rentalUnitsValues,
  segments,
  isMultiProperty,
  handleOnChange,
  handleBusinessPartnerChange,
  allOptions,
  filterAndMapSegmentsToOptions,
  renderSegmentSelectFooter,
  disableSegmentSelect,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property.rent-roll.table' })
  const formatAreaUnit = useAreaMeasurementUnitFormatter()
  const contentDefinitions = getPropertyRentalUnitsTableContentDefinitions(t, isMultiProperty)

  const renderTableInput = useCallback(
    (rowKey, contentKey, inputType, valueObj, labelAsIcon, readonly) => (
      <TableInput
        rowKey={rowKey}
        contentKey={contentKey}
        inputType={inputType}
        valueObj={valueObj}
        handleOnChange={handleOnChange}
        labelAsIcon={labelAsIcon}
        readonly={readonly}
      />
    ),
    [handleOnChange],
  )
  const getOriginalExcelValueOrNull = (rentalUnit, contentKey) =>
    has(rentalUnit, ['original_excel_content', 'value', contentKey])
      ? rentalUnit.original_excel_content.value[contentKey]
      : null
  return useMemo(
    () =>
      rentalUnitsValues.map((rentalUnit) => {
        const rowKey = getRentalUnitWorkingVersionRowKey(rentalUnit.row_number)
        const rentalUnitTableRow = { rowKey: rowKey }
        contentDefinitions.forEach((conDef) => {
          const contentKey = conDef.contentKey
          const valueObj = rentalUnit[contentKey]
            ? {
                ...rentalUnit[contentKey],
              }
            : {
                value: null,
                valueState: ValueState.None,
                valueStateMessage: null,
              }
          const originalExcelValue = getOriginalExcelValueOrNull(rentalUnit, contentKey)
          let cellComponent
          rentalUnitTableRow[contentKey] = { value: valueObj.value, cellComponent: null }
          switch (contentKey) {
            //static select fields
            case 'property_uuid':
              cellComponent = (
                <TableComboBox
                  rowKey={rowKey}
                  contentKey={contentKey}
                  options={[
                    ...allOptions.properties.map((property) => ({
                      key: property.key,
                      display_name: property.selectDisplayValue,
                    })),
                  ]}
                  valueObj={valueObj}
                  originalExcelValue={originalExcelValue}
                  rentalUnitRow={rentalUnit}
                  handleOnChange={handleOnChange}
                />
              )
              break
            case 'segment_usage_type_id':
              cellComponent = (
                <TableComboBox
                  rowKey={rowKey}
                  contentKey={contentKey}
                  options={[...allOptions.segmentUsageTypeCodes]}
                  valueObj={valueObj}
                  originalExcelValue={originalExcelValue}
                  rentalUnitRow={rentalUnit}
                  handleOnChange={handleOnChange}
                />
              )
              break
            case 'rental_unit_area_uom_id':
              cellComponent = (
                <TableComboBox
                  rowKey={rowKey}
                  contentKey={contentKey}
                  options={[...allOptions.areaMeasureUnitCodes]}
                  valueObj={valueObj}
                  originalExcelValue={originalExcelValue}
                  handleOnChange={handleOnChange}
                />
              )
              break
            case 'rental_unit_currency_id':
              cellComponent = (
                <TableComboBox
                  rowKey={rowKey}
                  contentKey={contentKey}
                  options={[...allOptions.currencyCodes]}
                  valueObj={valueObj}
                  originalExcelValue={originalExcelValue}
                  handleOnChange={handleOnChange}
                />
              )
              break
            case 'occupancy_status_id':
              cellComponent = (
                <TableComboBox
                  rowKey={rowKey}
                  contentKey={contentKey}
                  options={[...allOptions.occupancyStatusCodes]}
                  valueObj={valueObj}
                  originalExcelValue={originalExcelValue}
                  handleOnChange={handleOnChange}
                />
              )
              break
            //custom field property id
            case 'property_id':
              cellComponent = (
                <PropertyIdInput
                  rowKey={rowKey}
                  contentKey={contentKey}
                  propertyUuidValue={rentalUnitTableRow['property_uuid']?.value}
                  properties={allOptions.properties}
                />
              )
              break
            // sourced segments select
            case 'segment_uuid':
              cellComponent = (
                <TableSelectWithFooter
                  rowKey={rowKey}
                  contentKey={contentKey}
                  options={filterAndMapSegmentsToOptions(segments, rowKey)}
                  valueObj={valueObj}
                  handleOnChange={handleOnChange}
                  footer={renderSegmentSelectFooter(rowKey)}
                  disabled={disableSegmentSelect(rowKey)}
                />
              )
              break
            //input text
            case 'rental_unit_name':
            case 'comment':
            case 'auxiliary_column1':
            case 'auxiliary_column2':
            case 'auxiliary_column3':
            case 'auxiliary_column4':
              cellComponent = renderTableInput(rowKey, contentKey, InputType.Text, valueObj)
              break
            //checkbox
            case 'anonymous_tenant':
            case 'no_lease_expiry':
            case 'rent_dispute':
              cellComponent = (
                <TableCheckBox
                  rowKey={rowKey}
                  contentKey={contentKey}
                  valueObj={valueObj}
                  handleOnChange={handleOnChange}
                />
              )
              break
            //custom input tenant name
            case 'tenant_name':
              cellComponent = (
                <TablePropertyRentalUnitBusinessPartnerInput
                  type="tenant"
                  value={{ name: valueObj.value, id: rentalUnit['tenant_id']?.value }}
                  onChange={(businessPartner) =>
                    handleBusinessPartnerChange({
                      rowKey,
                      businessPartner,
                      contentKeys: {
                        expandContentKey: tenantExpandedContentKey,
                        idContentKey: 'tenant_id',
                        contentKey,
                      },
                    })
                  }
                  valueState={valueObj.valueState}
                  valueStateMessage={valueObj.valueStateMessage}
                  originalExcelValue={originalExcelValue}
                />
              )
              break
            case 'branding_franchise_partner_name':
              cellComponent = (
                <TablePropertyRentalUnitBusinessPartnerInput
                  type="brand-franchise-provider"
                  value={{
                    name: valueObj.value,
                    id: rentalUnit['branding_franchise_partner_id']?.value,
                  }}
                  onChange={(businessPartner) =>
                    handleBusinessPartnerChange({
                      rowKey,
                      businessPartner,
                      contentKeys: {
                        expandContentKey: brandingFranchisePartnerExpandedContentKey,
                        idContentKey: 'branding_franchise_partner_id',
                        contentKey,
                      },
                    })
                  }
                  valueState={valueObj.valueState}
                  valueStateMessage={valueObj.valueStateMessage}
                  originalExcelValue={originalExcelValue}
                />
              )
              break
            //input number
            case 'rental_unit_area':
              cellComponent = renderTableInput(
                rowKey,
                contentKey,
                InputType.Number,
                { ...valueObj, value: parseFloat(valueObj.value) },
                formatAreaUnit(rentalUnit['rental_unit_area_uom_id']?.value),
              )
              break
            case 'rent_contracted_year':
              cellComponent = renderTableInput(
                rowKey,
                contentKey,
                InputType.Number,
                { ...valueObj, value: parseFloat(valueObj.value) },
                rentalUnit['rental_unit_currency_id']?.value,
              )

              break
            case 'current_net_rent':
              cellComponent = renderTableInput(
                rowKey,
                contentKey,
                InputType.Number,
                { ...valueObj, value: parseFloat(valueObj.value) },
                rentalUnit['rental_unit_currency_id']?.value,
              )
              break
            case 'number_of_units':
            case 'rent_arrears':
              // value should be parsed to float
              cellComponent = renderTableInput(rowKey, contentKey, InputType.Number, {
                ...valueObj,
                value: parseFloat(valueObj.value),
              })
              break
            //input read only
            case 'tenant_id':
            case 'branding_franchise_partner_id':
            case 'rental_unit_id':
              cellComponent = renderTableInput(
                rowKey,
                contentKey,
                InputType.Number,
                valueObj,
                undefined,
                true,
              )
              break
            //input date
            case 'rent_start_date':
            case 'lease_expiry_date':
            case 'lease_break_date':
            case 'lease_start_date':
              cellComponent = (
                <TableDatePicker
                  rowKey={rowKey}
                  contentKey={contentKey}
                  valueObj={valueObj}
                  handleOnChange={handleOnChange}
                />
              )
              break
            default:
              break
          }
          rentalUnitTableRow[contentKey].cellComponent = cellComponent
        })

        return rentalUnitTableRow
      }),
    [
      rentalUnitsValues,
      contentDefinitions,
      allOptions,
      handleOnChange,
      filterAndMapSegmentsToOptions,
      segments,
      renderSegmentSelectFooter,
      disableSegmentSelect,
      renderTableInput,
      formatAreaUnit,
      handleBusinessPartnerChange,
    ],
  )
}
