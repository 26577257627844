import { Icon, MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionTypes } from 'api/conditions/conditions'
import styles from 'components/domains/conditions/overview/buttons/generic/ConditionsExportButton.module.css'
import useExportCondition from 'hooks/services/conditions/useExportCondition'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const ConditionsExportButton = ({ conditionType, mappedBackendFilters, sortBy, orderBy }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.buttons.export-condition',
  })

  const { download: downloadCondition } = useExportCondition()

  const {
    entityRef: { entityType, entityId },
  } = useContext(ConditionsContext)

  const showMessageBox = Modals.useShowMessageBox()

  const onExportError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('error'),
      draggable: true,
      resizable: true,
    })
  }, [showMessageBox, t])

  const onExportButtonClick = useCallback(() => {
    downloadCondition(
      { conditionType, entityType, entityId },
      mappedBackendFilters,
      {
        sortBy,
        orderBy,
      },
      {
        onError: () => {
          onExportError()
        },
        onSuccess: () => {},
      },
    )
  }, [
    downloadCondition,
    conditionType,
    entityType,
    entityId,
    mappedBackendFilters,
    sortBy,
    orderBy,
    onExportError,
  ])

  return (
    <>
      <Icon
        name="excel-attachment"
        interactive
        className={styles.exportIcon}
        onClick={onExportButtonClick}
      />
    </>
  )
}

ConditionsExportButton.propTypes = {
  conditionType: PropTypes.oneOf(Object.values(conditionTypes)),
  mappedBackendFilters: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  sortBy: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  orderBy: PropTypes.string,
}

export default ConditionsExportButton
