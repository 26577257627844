import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SponsorGuarantorOverviewAddress from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/SponsorGuarantorOverviewAddress'
import SponsorGuarantorOverviewAppreciation from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/SponsorGuarantorOverviewAppreciation'
import SponsorGuarantorOverviewBusinessPartnerSelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/SponsorGuarantorOverviewBusinessPartnerSelect'
import SponsorGuarantorOverviewFinancialIndicators from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/SponsorGuarantorOverviewFinancialIndicators'
import SponsorGuarantorOverviewInformation from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/SponsorGuarantorOverviewInformation'
import SponsorGuarantorOverviewRatings from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/SponsorGuarantorOverviewRatings'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/SponsorGuarantorOverviewTile.module.css'

const SponsorGuarantorOverviewTile = ({ tileId, showConfirmationForLinks, isPdfView }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.bp-sponsor-guarantor-overview',
  })
  const { data: { guarantors = [], sponsors = [], guarantorsAndSponsors = [] } = {} } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId],
  )
  const allBusinessPartners = useMemo(
    () => [...sponsors, ...guarantorsAndSponsors, ...guarantors],
    [guarantors, guarantorsAndSponsors, sponsors],
  )
  const [selectedBp, setSelectedBp] = useState(allBusinessPartners[0])
  const renderBusinessPartnerInfo = useCallback(
    (businessPartner) => (
      <>
        <SponsorGuarantorOverviewInformation
          businessPartner={businessPartner}
          showConfirmationForLinks={showConfirmationForLinks}
        />
        <SponsorGuarantorOverviewAddress businessPartner={businessPartner} />
        <SponsorGuarantorOverviewRatings businessPartner={businessPartner} />
        <SponsorGuarantorOverviewFinancialIndicators businessPartner={businessPartner} />
        <SponsorGuarantorOverviewAppreciation businessPartner={businessPartner} />
      </>
    ),
    [showConfirmationForLinks],
  )

  const content = useMemo(() => {
    if (isNil(selectedBp)) {
      return (
        <FlexBox
          className={styles.listCardEmptyContent}
          alignItems={FlexBoxAlignItems.Center}
          justifyContent={FlexBoxJustifyContent.Center}
        >
          {t('empty')}
        </FlexBox>
      )
    }

    if (isPdfView) {
      return (
        <>
          {allBusinessPartners.map((businessPartner) => (
            <Fragment key={businessPartner.information.businessPartnerId}>
              <Title level={TitleLevel.H3} className={styles.pdfViewSeparatorTitle}>
                {businessPartner.information.name}
              </Title>
              {renderBusinessPartnerInfo(businessPartner)}
            </Fragment>
          ))}
        </>
      )
    }

    return (
      <>
        <SponsorGuarantorOverviewBusinessPartnerSelect
          tileId={tileId}
          selectedBp={selectedBp}
          setSelectedBp={setSelectedBp}
        />

        {renderBusinessPartnerInfo(selectedBp)}
      </>
    )
  }, [allBusinessPartners, isPdfView, renderBusinessPartnerInfo, selectedBp, t, tileId])
  return (
    <FlexBox className={styles.listCardWrapper} direction={FlexBoxDirection.Column}>
      {content}
    </FlexBox>
  )
}

SponsorGuarantorOverviewTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool,
}

export default SponsorGuarantorOverviewTile
