import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import FurtherDetailsPieChartsCard, {
  chartsWithValuations,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/FurtherDetailsPieChartsCard'
import {
  TenantColorProvider,
  useTenantColors,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/TenantColorContext'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/ui/EmptyCardContent'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'

const FurtherDetailsGranularityTegovaRatingTile = ({ tileId, isPdfView }) => {
  const {
    data: {
      multiPropertyKpisByGranularity,
      multiPropertyValuationsByGranularity,
      isMultiProperty,
      noProperties,
    },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { t: tRating } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles',
  })

  const getTegovaRatingRentRollKpis = (multiPropertyKpis) =>
    multiPropertyKpis?.tegovaRatingRentRollKpis ?? []

  const getTegovaRatingValuations = (multiPropertyValuations) => {
    const valuations = multiPropertyValuations?.granularities?.tegovaRating ?? []
    let valuationsWithFullName
    if (!isEmpty(valuations)) {
      valuationsWithFullName = valuations.map((valuation) => ({
        ...valuation,
        displayName: valuation.code
          ? `${valuation.code} - ${valuation.displayName}`
          : tRating('further-details.none'),
      }))
    } else {
      valuationsWithFullName = []
    }
    return valuationsWithFullName
  }

  const getTegovaRatingName = (rentRollKpi) => {
    const rating = rentRollKpi?.tegovaRatingCode
    return rating
      ? `${rating} - ${tRating(`tegova-rating.${rating}`)}`
      : tRating('further-details.none')
  }

  if (noProperties) {
    return <NoPropertyAssignedToDealInfo />
  }
  if (!isMultiProperty) {
    return <EmptyCardContent />
  }
  return (
    <FurtherDetailsPieChartsCard
      colorProvider={TenantColorProvider}
      useColors={useTenantColors}
      getRentRollKpis={getTegovaRatingRentRollKpis}
      getValuations={getTegovaRatingValuations}
      getNameFromKpi={getTegovaRatingName}
      granularityValues={{
        multiPropertyKpisByGranularity,
        multiPropertyValuationsByGranularity,
      }}
      chartOptions={chartsWithValuations}
      isPdfView={isPdfView}
    />
  )
}

FurtherDetailsGranularityTegovaRatingTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default FurtherDetailsGranularityTegovaRatingTile
