export const DEFAULT_COLLAPSIBLE_CHARACTER_LIMIT = 70
export const TABLE_TEXT_DEFAULT_HEIGHT = 16
export const TABLE_TEXT_DEFAULT_MARGIN = 8
export const TABLE_CELL_DEFAULT_ITEMS_GAP = 8

const NUMBER_OF_LINES = 2
export const TABLE_HEADER_ROW_HEIGHT =
  (TABLE_TEXT_DEFAULT_HEIGHT + TABLE_TEXT_DEFAULT_MARGIN) * NUMBER_OF_LINES

export const TABLE_ROW_HEIGHT =
  TABLE_TEXT_DEFAULT_HEIGHT * NUMBER_OF_LINES +
  TABLE_TEXT_DEFAULT_MARGIN * NUMBER_OF_LINES +
  TABLE_CELL_DEFAULT_ITEMS_GAP

export const ROW_TYPE_TRIGGER_GROUP = 'triggerGroup'
export const ROW_TYPE_RISK_INDICATOR = 'riskIndicator'

export const RISK_INDICATOR_GROUP_TYPES = Object.freeze({
  OPEN: 'OPEN',
  MONITORED: 'MONITORED',
  ACCEPTED: 'ACCEPTED',
})

export const FORBEARANCE_MODALS_FIELDS_TYPES = Object.freeze({
  TEXT: 'text',
  DATE: 'date',
  SELECT: 'select',
  TEXTAREA: 'textarea',
})

export const MAX_COLLAPSED_COMMENT_LENGTH = 300

export const RISK_INDICATOR_VIOLATION_TYPES = {
  THRESHOLD: 'THRESHOLD',
  CRITICAL_CHANGE: 'CRITICAL_CHANGE',
}
