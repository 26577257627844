import { WebStorageStateStore, UserManager } from 'oidc-client-ts'

const mapStoreToStorage = (store) => (store === 'local' ? localStorage : sessionStorage)

const userManager = (args) => {
  const {
    authority,
    clientId,
    clientSecret,
    redirectUri,
    silentRedirectUri,
    postLogoutRedirectUri,
    responseType,
    scope,
    popupWindowFeatures,
    popupRedirectUri,
    popupWindowTarget,
    extraQueryParams,
    store,
  } = args

  return new UserManager({
    authority: authority || '',
    client_id: clientId || '',
    client_secret: clientSecret,
    redirect_uri: window.location.origin,
    silent_redirect_uri: silentRedirectUri || redirectUri,
    post_logout_redirect_uri: postLogoutRedirectUri || redirectUri,
    response_type: responseType || 'code',
    scope: scope || 'openid',
    loadUserInfo: false,
    popupWindowFeatures: popupWindowFeatures,
    popup_redirect_uri: popupRedirectUri,
    popupWindowTarget: popupWindowTarget,
    automaticSilentRenew: false,
    userStore: new WebStorageStateStore({ store: mapStoreToStorage(store) }),
    extraQueryParams,
  })
}

const buildOidcConfiguration = (oidcConfig) => {
  if (!oidcConfig.clientId) throw new Error('clientId')
  if (!oidcConfig.authority) throw new Error('authority')
  if (!oidcConfig.scope) throw new Error('scope')
  if (!oidcConfig.store || (oidcConfig.store !== 'local' && oidcConfig.store !== 'session')) {
    oidcConfig.store = 'local'
  }
  return {
    autoSignIn: false,
    automaticSilentRenew: false, // broken in oidc-client-js, see AuthenticationWrapper for workaround
    loadUserInfo: false,
    redirectUri: window.location.origin,
    onSignIn: () => {
      window.location.assign(localStorage.redirectUri || window.location.origin)
      delete localStorage.redirectUri
    },
    userManager: userManager({ ...oidcConfig }),
    ...oidcConfig,
  }
}

export default buildOidcConfiguration
