import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react/dist'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import useMediaBlobDownload from 'hooks/services/media/useMediaBlobDownload'

const AttachmentsDownloadAllButton = ({
  attachmentBlobIdsAndNames,
  setIsErrorDialogOpen,
  resetRowHighlights,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-paper-attachments-table',
  })

  const isDownloadAllButtonDisabled = useCallback(
    () => !attachmentBlobIdsAndNames || attachmentBlobIdsAndNames.length < 2,
    [attachmentBlobIdsAndNames],
  )

  const { download } = useMediaBlobDownload()

  const handleError = useCallback(() => {
    setIsErrorDialogOpen(true)
  }, [setIsErrorDialogOpen])

  const downloadAllAttachments = () => {
    resetRowHighlights()
    attachmentBlobIdsAndNames.forEach((data) => {
      download(data, { onError: handleError })
    })
  }

  return (
    <Button
      design={ButtonDesign.Transparent}
      disabled={isDownloadAllButtonDisabled()}
      onClick={() => downloadAllAttachments()}
    >
      {t('download-all')}
    </Button>
  )
}

AttachmentsDownloadAllButton.propTypes = {
  attachmentBlobIdsAndNames: PropTypes.arrayOf(
    PropTypes.shape({
      mediaBlobId: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setIsErrorDialogOpen: PropTypes.func.isRequired,
  resetRowHighlights: PropTypes.func.isRequired,
}

export default AttachmentsDownloadAllButton
