import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import PropertyRentRollOverviewExpiringLeasesChart from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared/PropertyRentRollOverviewExpiringLeasesChart'
import CardHeaderWithMetrics from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/card/CardHeaderWithMetrics'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/tegovaRating/shared/EmptyCardContent'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/TenancyOverviewExpiringLeasesTile.module.css'

const MultiTenancyOverviewExpiringLeasesTile = ({ tileId, selectedDealIndex }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.property.rent-roll.overview.expiring-leases.chart',
  })
  const tileState = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const {
    isLoading,
    isError,
    data: { tenancyOverviewExpiringLeasesPerDealUuidData, dealsData } = {},
  } = tileState

  const selectedDealUuid = dealsData[selectedDealIndex]?.dealUuid

  const tenancyOverviewExpiringLeases = Object.values(
    tenancyOverviewExpiringLeasesPerDealUuidData ?? {},
  )?.find(({ dealUuid }) => dealUuid === selectedDealUuid)

  if (
    tenancyOverviewExpiringLeases?.noProperties ||
    (!tenancyOverviewExpiringLeases?.expiringLeasesCardHeaderInfo &&
      !tenancyOverviewExpiringLeases?.expiringLeasesChartInfo)
  ) {
    return <NoPropertyAssignedToDealInfo />
  }

  return (
    <>
      <CardHeaderWithMetrics
        primaryMetric={tenancyOverviewExpiringLeases?.expiringLeasesCardHeaderInfo?.primaryMetric}
        referenceDate={tenancyOverviewExpiringLeases?.expiringLeasesCardHeaderInfo?.keyDate}
        isError={isError}
        isLoading={isLoading}
        isOnDecisionPaper={true}
        className={styles.header}
      />
      <DecisionPaperTileListSeparator />
      {!tenancyOverviewExpiringLeases?.expiringLeasesChartInfo ||
      tenancyOverviewExpiringLeases?.expiringLeasesChartInfo.isEmpty ? (
        <EmptyCardContent title={t('empty.title')} subtitle={t('empty.subtitle')} />
      ) : (
        <div className={styles.chart}>
          <PropertyRentRollOverviewExpiringLeasesChart
            currentRent={tenancyOverviewExpiringLeases?.expiringLeasesChartInfo.currentRent}
            expiringLeases={tenancyOverviewExpiringLeases?.expiringLeasesChartInfo.expiringLeases}
            currency={tenancyOverviewExpiringLeases?.expiringLeasesChartInfo.currency}
            todayXAxisPosition={
              tenancyOverviewExpiringLeases?.expiringLeasesChartInfo.todayXAxisPosition
            }
          />
        </div>
      )}
    </>
  )
}

MultiTenancyOverviewExpiringLeasesTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultiTenancyOverviewExpiringLeasesTile
