import camelize from 'camelize'
import { useRequest } from 'hooks/services/baseService'

const useTypeCodes = (typeCode, excludedCodes = []) => {
  const result = useRequest({
    path: `/risk-monitoring/configurations/category/${typeCode}`,
    translated: true,
  })

  const transformedData = {
    typeCodes: result?.data?.configuration?.[typeCode]
      .map((element) => ({
        code: element.code,
        short_text: element.short_text,
        additional_properties: element.additional_properties,
      }))
      .filter((element) => !excludedCodes.includes(element.code)),
  }

  return {
    ...result,
    data: camelize(transformedData),
  }
}

export default useTypeCodes
