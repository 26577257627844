import {
  FlexBox,
  FlexBoxDirection,
  Label,
  ObjectStatus,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionsStatusTypes } from 'api/conditions/conditions'
import styles from 'components/domains/conditions/overview/table-cells/view/TableStatusCell.module.css'
import LoadingSelect from 'components/ui/select/LoadingSelect'

const statusTypeToValueState = {
  [conditionsStatusTypes.open]: ValueState.None,
  [conditionsStatusTypes.inProgress]: ValueState.Information,
  [conditionsStatusTypes.done]: ValueState.Success,
}

const TableStatusCell = ({
  requirements,
  statusType,
  statusName,
  editedStatusCode,
  loadingHook,
  loadingHookParams,
  onChange,
  isEditMode,
  showEmptyOption,
  emptyOptionText,
  hasStatusError,
  ...selectOptions
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.columns.status',
  })

  const { data } = loadingHook()

  const onChangeInternal = useCallback(
    (_event, newCode) => {
      const newType = data?.status.find(({ code }) => code === newCode)?.type
      onChange(newCode, newType)
    },
    [onChange, data],
  )

  const renderedRequirements = useMemo(() => {
    if (!requirements) {
      return
    }
    return (
      <FlexBox className={styles.requirementsFlexbox}>
        <Label>{t('requirements')}</Label>
        <Text>{`${requirements.done}/${requirements.total}`}</Text>
      </FlexBox>
    )
  }, [requirements, t])

  if (isEditMode) {
    return (
      <LoadingSelect
        showEmptyOption={showEmptyOption}
        emptyOptionText={emptyOptionText}
        selectedKey={editedStatusCode}
        loadingHook={loadingHook}
        loadingHookParams={loadingHookParams}
        onChange={onChangeInternal}
        optionKeyName="code"
        optionDisplayName="name"
        selectionName="status"
        isErrorState={hasStatusError}
        {...selectOptions}
      />
    )
  }

  if (isNil(statusType)) {
    return <Text>{}</Text>
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.statusFlexbox}>
      <ObjectStatus inverted state={statusTypeToValueState[statusType]}>
        {statusName}
      </ObjectStatus>
      {renderedRequirements}
    </FlexBox>
  )
}

TableStatusCell.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  statusType: PropTypes.oneOf(Object.values(conditionsStatusTypes)),
  statusName: PropTypes.string,
  editedStatusCode: PropTypes.string,
  requirements: PropTypes.shape({
    total: PropTypes.number.isRequired,
    done: PropTypes.number.isRequired,
  }),
  loadingHook: PropTypes.func.isRequired,
  loadingHookParams: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func.isRequired,
  showEmptyOption: PropTypes.bool.isRequired,
  emptyOptionText: PropTypes.string,
  hasStatusError: PropTypes.bool,
}

export default TableStatusCell
