import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  ComposedChart,
  Scatter,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'

import styles from 'components/domains/properties/rent-roll/overview/vacancy-over-time/PropertyRentRollOverviewVacancyOverTimeChart.module.css'
import sharedChartStyles from 'components/ui/charts/shared-chart-styles.module.css'
import { usePercentageFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'
import useChartAnimation from 'hooks/services/properties/kpis/charts/useChartAnimation'
import useHistoricalChartDataWithPadding from 'hooks/services/properties/kpis/charts/useHistoricalChartDataWithPadding'
import useHistoricalChartXAxisTickConfig from 'hooks/services/properties/kpis/charts/useHistoricalChartXAxisTickConfig'

const TOTAL_VACANCY_COLOR = '#C53F38'
const STROKE_COLOR = '#FFF'
const AXIS_COLOR = '#9B9B9B'
/** @type {import('recharts/types/util/types').SymbolType[]} */
const SCATTER_SHAPES = ['circle', 'triangle', 'cross', 'star', 'diamond']

const AREA_CHART_FIELDS = ['total']

const PropertyRentRollOverviewVacancyOverTimeChart = ({ data, usageTypes }) => {
  const { t: tVacancyOverTimeChart } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.vacancy-over-time.chart',
  })
  const animationProps = useChartAnimation()
  const { format: formatDate } = useShortDateFormatter()
  const formatPercentage = usePercentageFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const formatTooltipDate = useCallback(
    (timestamp) => formatDate(new Date(timestamp).toISOString()),
    [formatDate],
  )
  const xAxisTickConfig = useHistoricalChartXAxisTickConfig(data)
  const { dataWithEndPadding, TooltipForNonPaddingData } = useHistoricalChartDataWithPadding(
    data,
    AREA_CHART_FIELDS,
  )

  return (
    <ResponsiveContainer width="100%" aspect={2.2} maxHeight={400}>
      <ComposedChart
        data={dataWithEndPadding}
        margin={{ right: 20, top: 10 }}
        className={`${styles.vacancyOverTimeChart} ${sharedChartStyles.cwpChart}`}
      >
        <CartesianGrid vertical={false} />

        <YAxis axisLine={false} tickLine={false} tickFormatter={formatPercentage} />

        <XAxis
          dataKey="date"
          type="number"
          tickLine={false}
          domain={['dataMin', 'dataMax']}
          scale="time"
          {...xAxisTickConfig}
          padding={{
            left: 20,
            right: 20,
          }}
          axisLine={{
            stroke: AXIS_COLOR,
          }}
        />

        <Legend />

        <Area
          type="stepAfter"
          dataKey="total"
          fill={TOTAL_VACANCY_COLOR}
          stroke={TOTAL_VACANCY_COLOR}
          fillOpacity={1}
          strokeOpacity={1}
          name={tVacancyOverTimeChart('total')}
          legendType="square"
          {...animationProps}
        />

        <Tooltip
          formatter={formatPercentage}
          labelFormatter={formatTooltipDate}
          content={TooltipForNonPaddingData}
        />

        {usageTypes.map(
          ({ id: usageTypeId, name: usageTypeName, color: usageTypeColor }, index) => {
            const shape = SCATTER_SHAPES[index % SCATTER_SHAPES.length]
            return (
              <Scatter
                key={usageTypeId}
                dataKey={usageTypeId}
                fill={usageTypeColor}
                stroke={STROKE_COLOR}
                strokeWidth={1}
                name={usageTypeName}
                shape={shape}
                legendType={shape}
                {...animationProps}
              />
            )
          },
        )}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

PropertyRentRollOverviewVacancyOverTimeChart.propTypes = {
  /** data points to be displayed in the chart */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      /** rent roll key date as unix timestamp */
      date: PropTypes.number.isRequired,
      /** property vacancy in percent */
      total: PropTypes.number.isRequired,
      /** additionally, there can be fields with vacancy values for the individual usage types */
    }),
  ).isRequired,
  /** all usage types that will be display in the chart and the legend */
  usageTypes: PropTypes.arrayOf(
    PropTypes.shape({
      /** usage types id to match the data point vacancy values */
      id: PropTypes.string.isRequired,
      /** name of the usage type that is displayed in the legend */
      name: PropTypes.string.isRequired,
      /** color of the usage type scatter point */
      color: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default PropertyRentRollOverviewVacancyOverTimeChart
