import { Title } from '@fioneer/ui5-webcomponents-react'
import { lazy, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { isNotFoundError } from 'api/requests'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import useRegulatoryInformation from 'components/domains/business-partners/tile/general-information/regulatoryInformation/useRegulatoryInformations'
import applyTemplate from 'components/domains/business-partners/tile/ratings/applyTemplate'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Link from 'components/ui/link/Link'
import Page from 'components/ui/page/Page'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import lazyRetry from 'lazyRetry'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import OpenInPegaButton from 'routes/business-partners/OpenInPegaButton'
import paths from 'routes/business-partners/paths'

const InformationTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "InformationTile" */ 'components/domains/business-partners/tile/general-information/InformationTile'
      ),
    'InformationTile',
  ),
)

const AddressTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AddressTile" */ 'components/domains/business-partners/tile/general-information/AddressTile'
      ),
    'AddressTile',
  ),
)

const RolesTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "RolesTile" */ 'components/domains/business-partners/tile/general-information/RolesTile'
      ),
    'RolesTile',
  ),
)

const StatusTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "StatusTile" */ 'components/domains/business-partners/tile/general-information/StatusTile'
      ),
    'StatusTile',
  ),
)

const ExternalIdentificationTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ExternalIdentificationTile" */ 'components/domains/business-partners/tile/general-information/ExternalIdentificationTile'
      ),
    'ExternalIdentificationTile',
  ),
)

const TaxIdentificationTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "TaxIdentificationTile" */ 'components/domains/business-partners/tile/general-information/TaxIdentificationTile'
      ),
    'TaxIdentificationTile',
  ),
)

const RegulatoryInformation = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "RegulatoryInformation" */ 'components/domains/business-partners/tile/general-information/regulatoryInformation/RegulatoryInformation'
      ),
    'RegulatoryInformation',
  ),
)

const KnowYourCustomerTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "KnowYourCustomerTile" */ 'components/domains/business-partners/tile/general-information/knowYourCustomer/KnowYourCustomerTile'
      ),
    'KnowYourCustomerTile',
  ),
)

const GBAReportingTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "GBAReportingTile" */ 'components/domains/business-partners/tile/exposure/GBAReportingTile'
      ),
    'GBAReportingTile',
  ),
)

const RiskMonitoringInformationTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "RiskMonitoringInformationTile" */ 'components/domains/business-partners/tile/general-information/RiskMonitoringInformationTile'
      ),
    'RiskMonitoringInformationTile',
  ),
)

const BusinessPartnerGeneralInformation = () => {
  const { id, roles = [], linkToKycSystemTemplate = '', kyc } = useContext(BusinessPartnerContext)
  const {
    data: regulatoryInformation,
    isLoading,
    isError,
    error,
  } = useRegulatoryInformation({ businessPartnerId: id })

  const isRegulatoryInformationNotFound = !!error && isNotFoundError(error)
  const isRegulatoryInformationError = isError && !isRegulatoryInformationNotFound

  const { t } = useTranslation()
  const linkToPega = applyTemplate(linkToKycSystemTemplate, { businessPartnerId: id })

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [id],
  )

  const renderContent = () =>
    id ? (
      <CWPLayout>
        <InformationTile />
        <AddressTile />
        <RolesTile roles={roles} showLink={false} />
        <StatusTile />
        <ExternalIdentificationTile />
        <TaxIdentificationTile />
        <RegulatoryInformation
          isError={isRegulatoryInformationError && !isRegulatoryInformationNotFound}
          isLoading={isLoading}
          regulatoryInformation={regulatoryInformation}
        />
        <KnowYourCustomerTile kycInformation={kyc} />
        <GBAReportingTile />
        <RiskMonitoringInformationTile />
      </CWPLayout>
    ) : (
      <div id="not-found-tab">
        <Title>{t('pages.business-partner.not-found')}</Title>
        <Link href={paths.businessPartners}>{t('pages.business-partner.search')}</Link>
      </div>
    )

  return (
    <Page
      isError={false}
      isLoading={false}
      renderHeaderTitle={() => (
        <BusinessPartnerHeader
          actions={[
            <CreateBusinessPartnerEventAction key={'create-event-action'} />,
            <OpenInPegaButton to={linkToPega} key={'open-in-pega'} />,
            markFavoriteAction,
          ]}
          breadcrumbs={[
            { text: t('navigation.item.title.business-partner-general-information'), href: '' },
          ]}
        />
      )}
      renderContent={renderContent}
    />
  )
}

BusinessPartnerGeneralInformation.propTypes = {}

export default BusinessPartnerGeneralInformation
