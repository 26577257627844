import { Label, Text, WrappingType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/impairment-check/NumberQuestion.module.css'
import FormattedNumberInput from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/NumberQuestionFormattedNumberInput'
import { useCurrencyFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  currency: PropTypes.string,
  isEditMode: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
}
/**
 * @typedef {object} overrides
 * @property {(newValue: number?) => void} [onChange]
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 */
const NumberQuestion = ({ label, value, currency, isEditMode, onChange }) => {
  const format = useCurrencyFormatter({ currency })
  return (
    <div className={styles.question}>
      <Label className={styles.label} wrappingType={WrappingType.Normal}>
        {label}
      </Label>
      {!isEditMode && <Text className={styles.value}>{format(value)}</Text>}
      {isEditMode && (
        <FormattedNumberInput
          value={value}
          onChange={/** @param {number?} newValue */ (newValue) => onChange?.(newValue)}
          className={styles.value}
          icon={currency && <Text className={styles.currency}>{currency}</Text>}
        />
      )}
    </div>
  )
}

NumberQuestion.propTypes = propTypes

export default NumberQuestion
