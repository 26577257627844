import { Input, ValueState } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MarketEditMarketTypeDialog from 'components/domains/markets/detail/MarketEditMarketTypeDialog'
import { calculateExternalIdAndTypeFormErrors } from 'components/domains/markets/helper/calculateExternalIdAndTypeFormErrors'
import DisplayCardView from 'components/ui/card/DisplayCardView'
import EditCardItem from 'components/ui/card/EditCardItem'
import EditCardView from 'components/ui/card/EditCardView'
import editComponentTypes from 'components/ui/card/editComponentTypes'
import ErrorDialog from 'components/ui/dialog/ErrorDialog'
import LoadingDropdown from 'components/ui/dropdown/LoadingDropdown'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import useDeprecatedValues from 'hooks/common/useDeprecatedValues'
import useMarketConfigurationExternalIdTypes from 'hooks/services/markets/configuration/useMarketConfigurationExternalIdTypes'
import useEditMarketInformation from 'hooks/services/markets/useEditMarketInformation'
import useMarketTypes from 'hooks/services/markets/useMarketTypes'

const initialEditFields = {
  marketName: '',
  marketTypeCode: '',
  marketExternalId: '',
  marketExternalIdType: '',
}

const hyphen = '-'

const MarketGeneralInformationCard = ({
  marketId,
  marketName,
  marketType,
  marketTypeCode,
  marketCountry,
  marketExternalId,
  marketExternalIdType,
  showEditButton,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.markets.detail.information' })
  const { t: tWithoutPrefix } = useTranslation()
  const queryClient = useQueryClient()
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false)
  const [editFields, setEditFields] = useState(initialEditFields)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const [isEditMarketTypeConfirmationDialogOpen, setIsEditMarketTypeConfirmationDialogOpen] =
    useState(false)
  const { mutate: editMarket } = useEditMarketInformation()
  const {
    data: { market_types: marketTypes = [] } = {},
    isLoading: isMarketTypesDataLoading,
    isError: isMarketTypesDataError,
  } = useMarketTypes()

  const { deprecatedValues } = useDeprecatedValues({
    availableValues: marketTypes,
    selectedValues: [{ code: marketTypeCode, name: marketType }],
  })

  const marketTypesLoaderResponse = useMemo(() => {
    if (isMarketTypesDataLoading || isMarketTypesDataError)
      return {
        isLoading: isMarketTypesDataLoading,
        isError: isMarketTypesDataError,
        data: undefined,
      }

    return {
      data: [
        ...deprecatedValues.map(({ code, name }) => ({ code, displayName: name })),
        ...marketTypes.map(({ code, value }) => ({
          code,
          displayName: value,
        })),
      ],
      isLoading: false,
      isError: false,
    }
  }, [deprecatedValues, isMarketTypesDataError, isMarketTypesDataLoading, marketTypes])
  const marketTypesLoader = useCallback(
    () => marketTypesLoaderResponse,
    [marketTypesLoaderResponse],
  )

  const saveEditedMarket = useCallback(() => {
    editMarket(
      {
        marketId,
        marketName: editFields.marketName,
        marketTypeCode: editFields.marketTypeCode,
        marketExternalId: editFields.marketExternalId,
        marketExternalIdType: editFields.marketExternalIdType,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['markets'])
          if (editFields.marketTypeCode !== marketTypeCode)
            queryClient.invalidateQueries(['market-kpis', marketId])
          setIsEditModeEnabled(false)
        },
        onError: () => {
          setIsErrorDialogOpen(true)
        },
      },
    )
  }, [
    editFields.marketExternalId,
    editFields.marketExternalIdType,
    editFields.marketName,
    editFields.marketTypeCode,
    editMarket,
    marketId,
    marketTypeCode,
    queryClient,
  ])

  const onSaveClicked = useCallback(() => {
    marketTypeCode !== editFields.marketTypeCode
      ? setIsEditMarketTypeConfirmationDialogOpen(true)
      : saveEditedMarket()
  }, [editFields.marketTypeCode, marketTypeCode, saveEditedMarket])

  const onCancelClicked = useCallback(() => {
    setEditFields(initialEditFields)
    setIsEditModeEnabled(false)
  }, [])

  const onEditMarketTypeConfirmationSaveClicked = useCallback(() => {
    saveEditedMarket()
    setIsEditMarketTypeConfirmationDialogOpen(false)
  }, [saveEditedMarket])

  const onEditMarketTypeConfirmationCancel = useCallback(() => {
    setIsEditMarketTypeConfirmationDialogOpen(false)
  }, [])

  const onEditClicked = useCallback(() => {
    setEditFields({
      marketName,
      marketTypeCode,
      marketExternalId,
      marketExternalIdType,
    })
    setIsEditModeEnabled(true)
  }, [marketExternalId, marketExternalIdType, marketName, marketTypeCode])

  const onRetry = useCallback(() => {
    saveEditedMarket()
    setIsErrorDialogOpen(false)
  }, [saveEditedMarket])

  const renderCustomEditComponentWrapper = useCallback(
    (item, inputComponent) => (
      <EditCardItem
        key={item.label}
        editComponent={inputComponent}
        isMandatory={item.isMandatory}
        label={item.label}
      />
    ),
    [],
  )

  const onMarketNameBlur = useCallback(({ target: { value: newMarketName } }) => {
    if (newMarketName === '') {
      setFormErrors((oldState) => ({ ...oldState, marketName: true }))
      return
    }
    setFormErrors((oldState) => ({ ...oldState, marketName: false }))
  }, [])

  const onMarketNameInput = useCallback(({ target: { value: newMarketName } }) => {
    if (newMarketName !== '') {
      setFormErrors((oldState) => ({ ...oldState, marketName: false }))
    }
    setEditFields((oldState) => ({ ...oldState, marketName: newMarketName }))
  }, [])

  const renderMarketName = useCallback(
    ({ item }) => {
      const inputComponent = (
        <Input
          value={editFields.marketName}
          onBlur={onMarketNameBlur}
          onInput={onMarketNameInput}
          valueState={formErrors.marketName ? ValueState.Error : ValueState.None}
          valueStateMessage={
            <span>
              {tWithoutPrefix('components.ui.card.display-edit-card.input-error-message', {
                label: t('name'),
              })}
            </span>
          }
          className="edit-input-field"
        />
      )
      return renderCustomEditComponentWrapper(item, inputComponent)
    },
    [
      editFields.marketName,
      formErrors.marketName,
      onMarketNameBlur,
      onMarketNameInput,
      renderCustomEditComponentWrapper,
      t,
      tWithoutPrefix,
    ],
  )

  const onChangeMarketType = useCallback((newMarketTypeCode) => {
    setEditFields((oldState) => ({ ...oldState, marketTypeCode: newMarketTypeCode }))
  }, [])

  const renderMarketType = useCallback(
    ({ item }) => {
      const inputComponent = (
        <LoadingDropdown
          id="market-type-select"
          value={editFields.marketTypeCode}
          onChange={onChangeMarketType}
          useLoader={marketTypesLoader}
          className="edit-input-field"
        />
      )
      return renderCustomEditComponentWrapper(item, inputComponent)
    },
    [
      editFields.marketTypeCode,
      marketTypesLoader,
      onChangeMarketType,
      renderCustomEditComponentWrapper,
    ],
  )

  const onExternalIdBlur = useCallback(() => {
    calculateExternalIdAndTypeFormErrors({
      newMarketExternalId: editFields.marketExternalId,
      newMarketExternalIdType: editFields.marketExternalIdType,
      setFormErrors,
    })
  }, [editFields.marketExternalId, editFields.marketExternalIdType])

  const onExternalIdInput = useCallback(
    ({ target: { value: newExternalId } }) => {
      setEditFields((oldState) => ({ ...oldState, marketExternalId: newExternalId }))
      if (newExternalId !== '') {
        calculateExternalIdAndTypeFormErrors({
          newMarketExternalId: newExternalId,
          newMarketExternalIdType: editFields.marketExternalIdType,
          setFormErrors,
        })
      }
    },
    [editFields.marketExternalIdType],
  )

  const renderExternalId = useCallback(
    ({ item }) => {
      const inputComponent = (
        <Input
          value={editFields.marketExternalId}
          onBlur={onExternalIdBlur}
          onInput={onExternalIdInput}
          valueState={formErrors.marketExternalId ? ValueState.Error : ValueState.None}
          valueStateMessage={<span>{t('external-id.error')}</span>}
        />
      )
      return renderCustomEditComponentWrapper(item, inputComponent)
    },
    [
      editFields.marketExternalId,
      formErrors.marketExternalId,
      onExternalIdBlur,
      onExternalIdInput,
      renderCustomEditComponentWrapper,
      t,
    ],
  )

  const onExternalIdTypeChanged = useCallback(
    (_event, selectedExternalIdType) => {
      setEditFields((oldState) => ({ ...oldState, marketExternalIdType: selectedExternalIdType }))
      calculateExternalIdAndTypeFormErrors({
        newMarketExternalId: editFields.marketExternalId,
        newMarketExternalIdType: selectedExternalIdType,
        setFormErrors,
      })
    },
    [editFields.marketExternalId],
  )

  const renderExternalIdType = useCallback(
    ({ item }) => {
      const inputComponent = (
        <LoadingSelect
          selectedKey={editFields.marketExternalIdType}
          onChange={onExternalIdTypeChanged}
          loadingHook={useMarketConfigurationExternalIdTypes}
          selectionName="externalIdTypes"
          optionKeyName="code"
          optionDisplayName="code"
          valueState={formErrors.marketExternalIdType ? ValueState.Error : ValueState.None}
          valueStateMessage={<span>{t('external-id-type.error')}</span>}
        />
      )
      return renderCustomEditComponentWrapper(item, inputComponent)
    },
    [
      editFields.marketExternalIdType,
      formErrors.marketExternalIdType,
      onExternalIdTypeChanged,
      renderCustomEditComponentWrapper,
      t,
    ],
  )

  const marketGeneralInformationFields = useMemo(
    () => [
      {
        name: 'marketName',
        label: t('name'),
        value: marketName,
        isMandatory: true,
        renderCustomEditComponent: renderMarketName,
      },
      {
        name: 'marketType',
        label: t('type'),
        value: marketType,
        isMandatory: true,
        renderCustomEditComponent: renderMarketType,
      },
      {
        name: 'marketCountry',
        label: t('country'),
        value: marketCountry,
        editComponentType: editComponentTypes.Input,
        editComponentProps: {
          readOnly: true,
        },
      },
      {
        name: 'marketExternalIdType',
        label: t('external-id-type'),
        value: marketExternalIdType || hyphen,
        renderCustomEditComponent: renderExternalIdType,
      },
      {
        name: 'marketExternalId',
        label: t('external-id'),
        value: marketExternalId || hyphen,
        renderCustomEditComponent: renderExternalId,
      },
    ],
    [
      marketCountry,
      marketExternalId,
      marketExternalIdType,
      marketName,
      marketType,
      renderExternalId,
      renderExternalIdType,
      renderMarketName,
      renderMarketType,
      t,
    ],
  )

  const isSaveButtonEnabled = useMemo(() => {
    const hasFormErrors = Object.values(formErrors).some((value) => value)
    if (hasFormErrors) {
      return false
    }
    if (editFields.marketName === '') {
      return false
    }
    if (isEmpty(editFields.marketExternalId) && !isEmpty(editFields.marketExternalIdType)) {
      return false
    }
    if (
      editFields.marketName === marketName &&
      editFields.marketTypeCode === marketTypeCode &&
      editFields.marketExternalId === marketExternalId &&
      editFields.marketExternalIdType === marketExternalIdType
    ) {
      return false
    }
    return true
  }, [editFields, formErrors, marketExternalId, marketExternalIdType, marketName, marketTypeCode])

  if (isEditModeEnabled) {
    return (
      <>
        <EditCardView
          cardHeaderTitle={t('title')}
          fieldDefinitions={marketGeneralInformationFields}
          onSaveClicked={onSaveClicked}
          onCancelClicked={onCancelClicked}
          setEditModeHasChanges={() => {}}
          editModeHasChanges={isSaveButtonEnabled}
          values={{ marketCountry }}
        />
        {isErrorDialogOpen && (
          <ErrorDialog
            isOpen={isErrorDialogOpen}
            setIsOpen={setIsErrorDialogOpen}
            texts={{
              retryButton: tWithoutPrefix('buttons.try-again'),
              cancelButton: tWithoutPrefix('buttons.cancel'),
              title: t('edit-error.title'),
              description: t('edit-error.description'),
            }}
            onRetry={onRetry}
          />
        )}
        {isEditMarketTypeConfirmationDialogOpen && (
          <MarketEditMarketTypeDialog
            isOpen={isEditMarketTypeConfirmationDialogOpen}
            handleConfirmation={onEditMarketTypeConfirmationSaveClicked}
            handleCancel={onEditMarketTypeConfirmationCancel}
            marketId={marketId}
            newMarketTypeCode={editFields.marketTypeCode}
          />
        )}
      </>
    )
  }

  return (
    <DisplayCardView
      cardHeaderTitle={t('title')}
      fieldDefinitions={marketGeneralInformationFields}
      setEditMode={onEditClicked}
      isEditable={showEditButton}
    />
  )
}

MarketGeneralInformationCard.propTypes = {
  marketId: PropTypes.string.isRequired,
  marketType: PropTypes.string.isRequired,
  marketTypeCode: PropTypes.string.isRequired,
  marketName: PropTypes.string.isRequired,
  marketCountry: PropTypes.string.isRequired,
  marketExternalId: PropTypes.string.isRequired,
  marketExternalIdType: PropTypes.string.isRequired,
  showEditButton: PropTypes.bool.isRequired,
}

export default MarketGeneralInformationCard
