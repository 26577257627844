import { Option, Select, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import useCashflowsObjectDefinitions from 'hooks/services/deals/cashflow/useCashflowsObjectDefinitions'

const dealCashflowInputParametersUnitSelectProps = {
  className: PropTypes.string,
  cell: PropTypes.object.isRequired,
  parameter: PropTypes.object.isRequired,
  setEditData: PropTypes.func.isRequired,
}

const DealCashflowInputParametersUnitSelect = ({
  cell,
  parameter,
  setEditData = () => {},
  ...props
}) => {
  const { data: { objects = [] } = {} } = useCashflowsObjectDefinitions()
  const { parameters = [] } = objects.find(({ ccodId }) => ccodId === parameter.ccodId) ?? {}
  const { units = [] } = parameters.find(({ ccpdId }) => ccpdId === parameter.ccpdId) ?? {}
  const { invalid, coId } = cell?.row?.original ?? {}

  if (!units.length) return

  if (units.length === 1) {
    return <Text className={props.className}>{parameter.unit}</Text>
  }

  const onChangeUnit = ({ detail }) => {
    const { value } = detail.selectedOption
    setEditData({
      [coId]: {
        parameters: {
          [parameter.ccpdId]: { ccpuId: value },
        },
      },
    })
  }

  const isSelected = (option) => option.ccpuId === parameter.ccpuId

  return (
    <Select {...props} disabled={invalid || parameter.isSaveLoading} onChange={onChangeUnit}>
      {units.map((option) => (
        <Option key={option.ccpuId} value={option.ccpuId} selected={isSelected(option)}>
          {option.name}
        </Option>
      ))}
    </Select>
  )
}

DealCashflowInputParametersUnitSelect.propTypes = dealCashflowInputParametersUnitSelectProps

export default DealCashflowInputParametersUnitSelect
