import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TILE_VERSION as tileVersion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import ShadowBankingQuestionnaireTileVersion1 from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/shadow-banking/shadow-banking-questionnaire-version-1/ShadowBankingQuestionnaireTile'
import ShadowBankingQuestionnaireTileVersion2 from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/shadow-banking/shadow-banking-questionnaire-version-2/ShadowBankingQuestionnaireTileVersion2'

const ShadowBankingQuestionnaireTileWrapper = ({
  tileId,
  currentContent,
  onChange,
  isEditMode,
  isPdfView,
  setHasContentChanges,
}) => {
  const { version } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const shadowBankingQuestionnaireTile = useMemo(() => {
    switch (version) {
      case tileVersion.V1:
        return (
          <ShadowBankingQuestionnaireTileVersion1
            tileId={tileId}
            currentContent={currentContent}
            onChange={onChange}
            isEditMode={isEditMode}
            isPdfView={isPdfView}
            setHasContentChanges={setHasContentChanges}
          />
        )
      default:
        return (
          <ShadowBankingQuestionnaireTileVersion2
            tileId={tileId}
            currentContent={currentContent}
            onChange={onChange}
            isEditMode={isEditMode}
            isPdfView={isPdfView}
            setHasContentChanges={setHasContentChanges}
          />
        )
    }
  }, [currentContent, isEditMode, isPdfView, onChange, setHasContentChanges, tileId, version])

  return shadowBankingQuestionnaireTile
}

ShadowBankingQuestionnaireTileWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  currentContent: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
}

export default ShadowBankingQuestionnaireTileWrapper
