import { Link, MessageStrip, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/deal-adjustment/DealLiveVersionMessageStrip.module.css'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { DealContext } from 'routes/deals/DealContext'
import useNavigateToWorkingVersion from 'routes/deals/useNavigateToWorkingVersion'

const DealLiveVersionMessageStrip = ({ showWVLink, ...props }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.deal-adjustment.live-version-strip',
  })

  const {
    deal,
    dealHeaders: { workingVersion: workingVersionDeal = {} },
  } = useContext(DealContext)

  const switchWorkingVersion = useNavigateToWorkingVersion()
  const dealLink = useMemo(
    () => <Link onClick={() => switchWorkingVersion(WorkingVersionType.WORKING_VERSION)} />,
    [switchWorkingVersion],
  )

  if (deal.workingVersion === WorkingVersionType.WORKING_VERSION || isEmpty(workingVersionDeal)) {
    return null
  }

  return (
    <>
      <MessageStrip
        hideCloseButton
        design={MessageStripDesign.Warning}
        className={styles.messageStrip}
        {...props}
      >
        {showWVLink ? (
          <Trans
            i18nKey="components.deals.deal-adjustment.live-version-strip.label"
            components={{ dealLink }}
            defaultTransParent
          />
        ) : (
          t('label-no-link')
        )}
      </MessageStrip>
    </>
  )
}

DealLiveVersionMessageStrip.propTypes = {
  showWVLink: PropTypes.bool,
}

export default DealLiveVersionMessageStrip
