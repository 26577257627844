import { MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import CloseableMessageStrip from 'components/ui/feedback/CloseableMessageStrip'
import styles from 'components/ui/screen-size-indicator/ScreenSizeIndicator.module.css'
import { useWindowDimensions } from 'hooks/screen-size/useWindowDimensions'

const ScreenSizeIndicator = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.screen-size-indicator',
  })

  const { width } = useWindowDimensions()

  const smallestSupportedScreenWidth = 1024

  if (width >= smallestSupportedScreenWidth) return

  return (
    <>
      <CloseableMessageStrip
        className={styles.screenSizeIndicator}
        design={MessageStripDesign.Warning}
      >
        {t('text')}
      </CloseableMessageStrip>
    </>
  )
}

export default ScreenSizeIndicator
