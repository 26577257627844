import PropTypes from 'prop-types'
import { Component } from 'react'
import DefaultErrorPage from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/ui/DefaultErrorPage'

const propTypes = {
  fallback: PropTypes.element,
  // eslint-disable-next-line react/forbid-prop-types
  fallbackProps: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null, errorInfo: null }
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo })
    // eslint-disable-next-line no-console
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.error) {
      const Fallback = this.props.fallback || DefaultErrorPage
      return (
        <Fallback
          error={this.state.error}
          errorInfo={this.state.errorInfo}
          {...this.props.fallbackProps}
        />
      )
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = propTypes

export default ErrorBoundary
