import { IconDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import DocumentIcon from 'components/domains/documents/DocumentIcon'
import styles from 'components/domains/documents/documents-table/DocumentIconCell.module.css'

export const DocumentIconCell = ({ value }) => (
  <DocumentIcon format={value} design={IconDesign.Neutral} className={styles.documentIcon} />
)

DocumentIconCell.propTypes = { value: PropTypes.string }
