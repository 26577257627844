import camelize from 'camelize'
import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useRiskFigures } from 'hooks/services/business-partners/risk-figures/useRiskFigures'

const useBaselTwoSettlementRisk = ({ entityRef: { entityId: businessPartnerId } }, tileId) => {
  const {
    isLoading: isLoadingRiskFigures,
    isError: isErorrRiskFigures,
    data: riskFigures,
  } = useRiskFigures({
    businessPartnerId,
  })

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isLoadingRiskFigures],
    errorValues: [isErorrRiskFigures],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        riskFiguresElements: camelize(riskFigures) ?? {},
        sourceRender: {
          businessPartnerId,
        },
      },
    }
  }, [businessPartnerId, error, isSomeValueError, isSomeValueLoading, riskFigures])
}

export default useBaselTwoSettlementRisk
