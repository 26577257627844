import { useMutation } from '@tanstack/react-query'
import snakeCase from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateKpiAdjustment = ({ onSuccess, onError }) => {
  const { post: postKpiAdjustment } = useAccessTokenRequest()

  return useMutation(
    async ({ adjustment = {}, options = {} }) => {
      await postKpiAdjustment({
        path: `/kpis/adjustments`,
        body: snakeCase(adjustment),
        options: { ...options },
      })
    },
    { onSuccess, onError },
  )
}

export default useCreateKpiAdjustment
