import { useMemo } from 'react'
import useHeadquarterCurrency from 'hooks/services/deals/syndication/configGetters/useHeadquarterCurrency'

const useExtractedHeadquarterCurrency = () => {
  const {
    data: headquarterCurrencyData,
    isFetching: isHeadquarterCurrencyFetching,
    isError: isHeadquarterCurrencyError,
    error: headquarterCurrencyError,
  } = useHeadquarterCurrency()

  const headquarterCurrency = useMemo(() => {
    if (!headquarterCurrencyData) {
      return
    }
    return headquarterCurrencyData.configuration.HEADQUARTERCURRENCY[0].code
  }, [headquarterCurrencyData])

  return useMemo(
    () => ({
      isHeadquarterCurrencyFetching,
      isHeadquarterCurrencyError,
      headquarterCurrencyError,
      headquarterCurrency,
    }),
    [
      isHeadquarterCurrencyFetching,
      isHeadquarterCurrencyError,
      headquarterCurrency,
      headquarterCurrencyError,
    ],
  )
}

export default useExtractedHeadquarterCurrency
