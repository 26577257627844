import { ToolbarSeparator } from '@fioneer/ui5-webcomponents-react'
import { lazy, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { eventStatus } from 'api/events/status'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import CloseIndicatorGroupAction from 'components/domains/business-partners/tile/risk-monitoring/group-details/CloseIndicatorGroupAction'
import CreateMonitoringEventAction from 'components/domains/business-partners/tile/risk-monitoring/risk-indicator/create-risk-indicator/CreateMonitoringEventAction'
import LinkRiskMonitoringEventAction from 'components/domains/business-partners/tile/risk-monitoring/risk-indicator/link-risk-indicator/LinkRiskMonitoringEventAction'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Page from 'components/ui/page/Page'
import useEventsByIdList from 'hooks/services/business-events-and-tasks/events/useEventsByIdList'
import useRiskIndicatorGroupById from 'hooks/services/risk-monitoring/useRiskIndicatorGroupById'
import useRiskIndicators from 'hooks/services/risk-monitoring/useRiskIndicators'
import lazyRetry from 'lazyRetry'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

const RiskIndicatorsGroupGeneralInformation = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "RiskIndicatorsGroupGeneralInformation" */ 'components/domains/business-partners/tile/risk-monitoring/group-details/tiles/general-information/RiskIndicatorsGroupGeneralInformation'
      ),
    'RiskIndicatorsGroupGeneralInformation',
  ),
)

const RiskIndicatorsDetailed = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "RiskIndicatorsDetailed" */ 'components/domains/business-partners/tile/risk-monitoring/group-details/tiles/detailed/RiskIndicatorsDetailed'
      ),
    'RiskIndicatorsDetailed',
  ),
)

const RiskIndicatorsDescription = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "RiskIndicatorsDetailed" */ 'components/domains/business-partners/tile/risk-monitoring/group-details/tiles/description/RiskIndicatorsDescription'
      ),
    'RiskIndicatorsDescription',
  ),
)

const RiskIndicatorsGroupBusinessEvents = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "RiskIndicatorsGroupBusinessEvents" */ 'components/domains/business-partners/tile/risk-monitoring/group-details/tiles/business-events/RiskIndicatorsGroupBusinessEvents'
      ),
    'RiskIndicatorsGroupBusinessEvents',
  ),
)

const RiskIndicatorsGroupCriticality = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "RiskIndicatorsGroupCriticality" */ 'components/domains/business-partners/tile/risk-monitoring/group-details/tiles/criticality/RiskIndicatorsGroupCriticality'
      ),
    'RiskIndicatorsGroupCriticality',
  ),
)

const RiskIndicatorGroupDetailsPage = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.business-partner',
  })

  const businessPartner = useContext(BusinessPartnerContext)
  const { riskIndicatorGroupDisplayId } = useParams()

  const {
    data: { riskIndicatorGroups } = {},
    isLoading: isGroupsLoading,
    isError: isGroupsError,
  } = useRiskIndicators({ businessPartnerId: businessPartner?.id, riskIndicatorGroupDisplayId })

  const riskIndicatorGroupId = riskIndicatorGroups?.[0]?.groupId

  const {
    data: riskIndicatorGroup,
    isLoading: isGroupLoading,
    isError: isGroupError,
  } = useRiskIndicatorGroupById(businessPartner?.id, riskIndicatorGroupId)

  const groupEventIds = riskIndicatorGroup?.events?.map((event) => event?.id)

  const {
    data: eventsData,
    isLoading: isEventsLoading,
    isError: isEventsError,
  } = useEventsByIdList(groupEventIds)

  const hasOngoingEvents = eventsData?.some((event) => event?.status === eventStatus.ongoing)

  const isLoading = isGroupsLoading || isGroupLoading || isEventsLoading
  const isError = isGroupsError || isGroupError || isEventsError

  return (
    <Page
      isLoading={isLoading}
      isError={isError}
      renderHeaderTitle={() => (
        <BusinessPartnerHeader
          breadcrumbs={[
            { href: 'risk-monitoring', text: t('risk-monitoring') },
            { text: riskIndicatorGroup?.groupName ?? '', href: '' },
          ]}
          actions={
            <>
              <CloseIndicatorGroupAction
                businessPartnerId={businessPartner?.id}
                riskIndicatorGroupId={riskIndicatorGroupId}
              />
              <ToolbarSeparator />
              <LinkRiskMonitoringEventAction
                businessPartnerId={businessPartner?.id}
                riskIndicatorGroupId={riskIndicatorGroupId}
              />
              <CreateMonitoringEventAction
                businessPartnerId={businessPartner?.id}
                riskIndicatorGroupId={riskIndicatorGroupId}
                disabled={hasOngoingEvents}
              />
              <CreateBusinessPartnerEventAction />
            </>
          }
        />
      )}
      renderContent={() => (
        <CWPLayout>
          <CWPLayout.Half>
            <RiskIndicatorsGroupGeneralInformation
              businessPartnerId={businessPartner?.id}
              groupId={riskIndicatorGroup?.groupId}
            />
          </CWPLayout.Half>
          <CWPLayout.Half>
            <RiskIndicatorsGroupCriticality
              businessPartnerId={businessPartner?.id}
              groupId={riskIndicatorGroup?.groupId}
            />
          </CWPLayout.Half>
          <CWPLayout.Full>
            <RiskIndicatorsDescription
              businessPartnerId={businessPartner?.id}
              groupId={riskIndicatorGroup?.groupId}
            />
          </CWPLayout.Full>
          <CWPLayout.Full>
            <RiskIndicatorsDetailed
              businessPartnerId={businessPartner?.id}
              groupId={riskIndicatorGroup?.groupId}
            />
          </CWPLayout.Full>
          <CWPLayout.Full>
            <RiskIndicatorsGroupBusinessEvents
              businessPartnerId={businessPartner?.id}
              groupId={riskIndicatorGroup?.groupId}
            />
          </CWPLayout.Full>
        </CWPLayout>
      )}
    />
  )
}

export default RiskIndicatorGroupDetailsPage
