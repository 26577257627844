import { FlexBoxJustifyContent } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { filterTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/tables/sorted-tables/useFilters'

const propTypes = {
  additionalTableProperties: PropTypes.object,
  columnDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      columnKey: PropTypes.string.isRequired,
      sortingDisabled: PropTypes.bool,
      additionalColumnCellProperties: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        style: PropTypes.object,
        className: PropTypes.string,
        waitForDefine: PropTypes.bool,
      }),
      renderColumnContent: PropTypes.func,
      title: PropTypes.string,
      onClick: PropTypes.func,
      wrapText: PropTypes.bool,
      alignment: PropTypes.oneOf([
        FlexBoxJustifyContent.Start,
        FlexBoxJustifyContent.Center,
        FlexBoxJustifyContent.End,
      ]),
      filter: PropTypes.oneOfType([
        PropTypes.oneOf([
          filterTypes.CONTAINS,
          filterTypes.BETWEEN_DATES,
          filterTypes.BETWEEN_NUMBERS,
          filterTypes.OF_ENUM_TYPE,
          filterTypes.MULTISELECT_ENUM,
          filterTypes.MULTI_OBJECT_CONTAINS,
          filterTypes.CUSTOM,
        ]),
        PropTypes.object,
      ]),
    }),
  ),
  customOrderFunction: PropTypes.func,
  customOrderCallback: PropTypes.func,
  maximumRowsShown: PropTypes.number,
  noDataText: PropTypes.string,
  renderEmptyTableComponent: PropTypes.func,
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  toolbarConfig: PropTypes.shape({
    searching: PropTypes.bool,
    sorting: PropTypes.shape({
      columnKey: PropTypes.string,
      isSortingAscending: PropTypes.bool,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    style: PropTypes.object,
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    showColumnSelection: PropTypes.bool,
    additionalActions: PropTypes.arrayOf(PropTypes.element),
  }),
  paginationConfig: PropTypes.shape({
    growing: PropTypes.string,
    growingButtonText: PropTypes.string,
    growingButtonSubtext: PropTypes.string,
    totalNumberOfItems: PropTypes.number,
    loadMore: PropTypes.func,
  }),
}

/** @typedef {PropTypes.InferProps<typeof propTypes>} SortedTableProps */

export default propTypes
