import PropTypes from 'prop-types'
import { useCallback } from 'react'
import styles from 'components/domains/conditions/overview/filter-components/LoadingMultiComboBoxFilter.module.css'
import LoadingMultiComboBox from 'components/ui/multi-combo-box/LoadingMultiComboBox'

const LoadingMultiComboBoxFilter = ({
  value,
  setSelectedFilters,
  columnKey,
  useLoadingHook,
  loadingHookParams,
  selectionName,
}) => {
  const onSelectionChange = useCallback(
    ({ detail: { items: newSelectedItems } }) => {
      const newFilterValue = newSelectedItems.map((item) => item.getAttribute('data-value'))
      setSelectedFilters((currentlySelectedFilters) =>
        currentlySelectedFilters.map((filter) => {
          if (filter.columnKey !== columnKey) {
            return filter
          }
          return { ...filter, value: newFilterValue }
        }),
      )
    },
    [columnKey, setSelectedFilters],
  )
  return (
    <LoadingMultiComboBox
      selected={value}
      useLoader={useLoadingHook}
      onSelectionChange={onSelectionChange}
      input={loadingHookParams}
      optionKeyName="code"
      optionDisplayName="name"
      selectionName={selectionName}
      className={styles.fullWidth}
    />
  )
}

LoadingMultiComboBoxFilter.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  columnKey: PropTypes.string.isRequired,
  useLoadingHook: PropTypes.func.isRequired,
  loadingHookParams: PropTypes.object,
  selectionName: PropTypes.string.isRequired,
}

export default LoadingMultiComboBoxFilter
