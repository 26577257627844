import { Icon, Input } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/toolbar/AssetValuationOverviewTablesToolbar.module.css'

const ToolbarSearching = ({ searching, ...aditionalInputProps }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'components.ui.tables.toolbar',
  })

  const onInput = useCallback(
    ({ target: { value: newSearchParam } }) => {
      searching.onUpdateSearchParam(newSearchParam)
    },
    [searching],
  )

  if (searching) {
    return (
      <Input
        showClearIcon={true}
        className={styles.searchInput}
        placeholder={t('search.placeholder')}
        value={searching.searchParam}
        onInput={onInput}
        onKeyDown={(event) => searching.onKeyDown && searching.onKeyDown(event)}
        style={{ margin: 0 }}
        icon={
          <Icon
            name="search"
            onClick={() => searching.onSearchIconClick && searching.onSearchIconClick()}
          />
        }
        {...aditionalInputProps}
      />
    )
  }
}

ToolbarSearching.propTypes = {
  searching: PropTypes.shape({
    searchParam: PropTypes.string,
    onUpdateSearchParam: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    onSearchIconClick: PropTypes.func,
  }),
}

export default ToolbarSearching
