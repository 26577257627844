import { useContext } from 'react'
import { covenantPermissions } from 'api/deals/covenantPermissions'
import DealCovenantChecksCard from 'components/domains/deals/covenants/covenant-checks-table/DealCovenantChecksCard'
import ReferencedCovenantChecksCard from 'components/domains/deals/covenants/referenced-covenant-checks/ReferencedCovenantChecksCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { CovenantContext } from 'routes/business-partners/CovenantContext'
import { DealContext } from 'routes/deals/DealContext'

const DealCovenantsMonitoring = () => {
  const { deal } = useContext(DealContext)
  const { allowedOperations: covenantAllowedOperations = [] } = useContext(CovenantContext)
  const isAllowedToReadCovenants = covenantAllowedOperations?.includes(
    covenantPermissions.readContractCovenant,
  )
  return (
    <CWPLayout>
      <CWPLayout.Full>
        <DealCovenantChecksCard
          entityId={deal.dealUuid}
          entityType={cwpEntityTypes.DEAL}
          isAllowedToReadCovenants={isAllowedToReadCovenants}
        />
      </CWPLayout.Full>
      <CWPLayout.Full>
        <ReferencedCovenantChecksCard
          entityId={deal.dealUuid}
          entityType={cwpEntityTypes.DEAL}
          referenceEntityType={cwpEntityTypes.BUSINESS_PARTNER}
          isAllowedToReadCovenants={isAllowedToReadCovenants}
        />
      </CWPLayout.Full>
    </CWPLayout>
  )
}

export default DealCovenantsMonitoring
