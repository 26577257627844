import AnnualReviewManualCheckSheetTileWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/appendix/manual-checksheet/AnnualReviewManualCheckSheetTileWrapper'
import BaselTwoManualCheckSheetTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/manual-checksheet/BaselTwoManualCheckSheetTile'
import ArrearsApprovalChecklistTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrearsApprovalChecklist/ArrearsApprovalChecklistTile'
import RiskIndicatorsForbearanceMeasuresCheckSheet from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/default-risk-indicators-forbearance-measures/RiskIndicatorsForbearanceMeasuresCheckSheet'
import ImpairmentCheckTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/impairment-check/ImpairmentCheckTile'
import { TILE_VERSION as tileVersion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import ForbearanceAndUtpQuestionareTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/ForbearanceAndUtpQuestionareTile'
import HandoverCoverPoolStatusTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/handover/general-deal-information/HandoverCoverPoolStatusTile'
import HandoverInsiderListTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/handover/monitoring/HandoverInsiderListTile'
import RichTextTileWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/RichTextTileWrapper'
import DecisionPaperRiskViewTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/risk-view/DecisionPaperRiskViewTile'
import LeveragedTransactionQuestionnaireTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/leveraged-transaction/LeveragedTransactionQuestionnaireTile'
import SecuritizationQuestionnaireTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/securitization/SecuritizationQuestionnaireTile'
import ShadowBankingQuestionnaireTileWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/shadow-banking/ShadowBankingQuestionnaireTileWrapper'
import SpecializedLendingQuestionnaireTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/specialized-lending/SpecializedLendingQuestionnaireTile'
import NonRefCreditDecisionManualCheckSheetTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/non-ref-credit-decision/involved-parties/manual-checksheet/NonRefCreditDecisionManualCheckSheetTile'
import FactSheetValuationFactor from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-factor/FactSheetValuationFactor'
import FactSheetValuationParameters from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-parameters/FactSheetValuationParameters'
import FactSheetValuationScenarios from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-scenarios/FactSheetValuationScenarios'
import DisbursementTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/disbursement/DisbursementTile'
import PortfolioAnalysisTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/portolio-analysis/PortfolioAnalysisTile'
import CriticalFacilityProposalTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/CriticalFacilityProposalTile'
import WaiverImpactClassificationTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/waiver/WaiverImpactClassificationTile'

export const availableManualSubtiles = Object.freeze({
  richText: 'RICH_TEXT',
  other: 'OTHER',
})

const manualSubtileCodeMap = Object.freeze({
  [availableManualSubtiles.richText]: {
    viewComponent: RichTextTileWrapper,
  },
})

export const availableManualTiles = Object.freeze({
  arrearsApprovalChecklist: 'ARREARS_APPROVAL_CHECKLIST',
  dealTeamRiskViewForInvestment: 'DEAL_TEAM_RISK_VIEW_FOR_INVESTMENT',
  disbursement: 'DISBURSEMENT',
  factSheetValuationFactor: 'FACT_SHEET_VALUATION_FACTOR',
  factSheetValuationScenarios: 'FACT_SHEET_VALUATION_SCENARIOS',
  factSheetValuationParameters: 'FACT_SHEET_VALUATION_PARAMETERS',
  informationPortfolioAnalysis: 'INFORMATION_PORTFOLIO_ANALYSIS',
  shadowBankingCheckQuestionnaire: 'SHADOW_BANKING_CHECK_QUESTIONNAIRE',
  leveragedTransactionCheckQuestionnaire: 'LEVERAGED_TRANSACTION_CHECK_QUESTIONNAIRE',
  securitizationCheckQuestionnaire: 'SECURITIZATION_CHECK_QUESTIONNAIRE',
  specializedLendingCheckQuestionnaire: 'SPECIALIZED_LENDING_CHECK_QUESTIONNAIRE',
  handoverGeneralDealInformationCoverPoolStatus:
    'HANDOVER_GENERAL_DEAL_INFORMATION_COVER_POOL_STATUS',
  handoverMonitoringInsiderList: 'HANDOVER_MONITORING_INSIDER_LIST',
  multipleManualChecksheet: 'MULTIPLE_APPENDIX_BUSINESS_PARTNER_DETAILS_MANUAL_CHECK_SHEET',
  annualReviewBaselTwoConfirmationCheckSheet: 'ANNUAL_REVIEW_BASEL_II_CONFIRMATION_CHECKSHEET',
  monitoredRiskIndicatorRegulatoryChecksCriticalFacilityStatusProposal:
    'MONITORED_RISK_INDICATOR_REGULATORY_CHECKS_CRITICAL_FACILITY_STATUS_PROPOSAL',
  monitoredRiskIndicatorRiskMonitoringDefaultIndicatorsAndForbearanceMeasures:
    'MONITORED_RISK_INDICATOR_RISK_MONITORING_DEFAULT_INDICATORS_AND_FORBEARANCE_MEASURES',
  monitoredRiskIndicatorRegulatoryChecksImpairmentCheck:
    'MONITORED_RISK_INDICATOR_REGULATORY_CHECKS_IMPAIRMENT_CHECK',
  nonRefCreditDecisionManualCheckSheetTile: 'NON_REF_MANUAL_CHECK_SHEET',
  waiverImpactClassification: 'WAIVER_TYPE',
  forbearanceAndUtpQuestionare: 'FORBEARANCE_AND_UNLIKELY_TO_PAY_QUESTIONNAIRE',
})

const manualTileCodeMap = Object.freeze({
  [availableManualTiles.arrearsApprovalChecklist]: {
    viewComponent: ArrearsApprovalChecklistTile,
  },
  [availableManualTiles.dealTeamRiskViewForInvestment]: {
    viewComponent: DecisionPaperRiskViewTile,
  },
  [availableManualTiles.disbursement]: {
    viewComponent: DisbursementTile,
  },
  [availableManualTiles.factSheetValuationFactor]: {
    viewComponent: FactSheetValuationFactor,
  },
  [availableManualTiles.factSheetValuationScenarios]: {
    viewComponent: FactSheetValuationScenarios,
  },
  [availableManualTiles.factSheetValuationParameters]: {
    viewComponent: FactSheetValuationParameters,
  },
  [availableManualTiles.forbearanceAndUtpQuestionare]: {
    viewComponent: ForbearanceAndUtpQuestionareTile,
  },
  [availableManualTiles.informationPortfolioAnalysis]: {
    viewComponent: PortfolioAnalysisTile,
  },
  [availableManualTiles.shadowBankingCheckQuestionnaire]: {
    viewComponent: ShadowBankingQuestionnaireTileWrapper,
    version: tileVersion.V2,
  },
  [availableManualTiles.leveragedTransactionCheckQuestionnaire]: {
    viewComponent: LeveragedTransactionQuestionnaireTile,
  },
  [availableManualTiles.securitizationCheckQuestionnaire]: {
    viewComponent: SecuritizationQuestionnaireTile,
  },
  [availableManualTiles.specializedLendingCheckQuestionnaire]: {
    viewComponent: SpecializedLendingQuestionnaireTile,
  },
  [availableManualTiles.handoverGeneralDealInformationCoverPoolStatus]: {
    viewComponent: HandoverCoverPoolStatusTile,
  },
  [availableManualTiles.handoverMonitoringInsiderList]: {
    viewComponent: HandoverInsiderListTile,
  },
  [availableManualTiles.multipleManualChecksheet]: {
    viewComponent: AnnualReviewManualCheckSheetTileWrapper,
  },
  [availableManualTiles.annualReviewBaselTwoConfirmationCheckSheet]: {
    viewComponent: BaselTwoManualCheckSheetTile,
  },
  [availableManualTiles.monitoredRiskIndicatorRegulatoryChecksCriticalFacilityStatusProposal]: {
    viewComponent: CriticalFacilityProposalTile,
  },
  [availableManualTiles.monitoredRiskIndicatorRiskMonitoringDefaultIndicatorsAndForbearanceMeasures]:
    {
      viewComponent: RiskIndicatorsForbearanceMeasuresCheckSheet,
    },
  [availableManualTiles.monitoredRiskIndicatorRegulatoryChecksImpairmentCheck]: {
    viewComponent: ImpairmentCheckTile,
  },
  [availableManualTiles.nonRefCreditDecisionManualCheckSheetTile]: {
    viewComponent: NonRefCreditDecisionManualCheckSheetTile,
  },
  [availableManualTiles.waiverImpactClassification]: {
    viewComponent: WaiverImpactClassificationTile,
  },
})

/**
 * @param {typeof availableManualSubtiles [keyof typeof availableManualSubtiles]} subtileCode
 * @param {keyof typeof manualTileCodeMap} tileCode
 */
export const getViewComponentForManualTile = (subtileCode, tileCode) => {
  if (subtileCode === availableManualSubtiles.other) {
    return manualTileCodeMap[tileCode]?.viewComponent
  }
  return manualSubtileCodeMap[subtileCode]?.viewComponent
}

/**
 * @param {keyof typeof manualTileCodeMap} tileCode
 */
export const getCurrentTileVersionForTileCode = (tileCode) => manualTileCodeMap[tileCode]?.version
