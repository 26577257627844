import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { collateralAgreementPermissions } from 'api/collateral-agreements/collateralAgreementPermissions'
import DialogPrimaryButton from 'components/ui/dialog/DialogPrimaryButton'
import DialogSecondaryButton from 'components/ui/dialog/DialogSecondaryButton'
import MessageBox, {
  MessageBoxActions,
  MessageBoxTypes,
} from 'components/ui/message-box/MessageBox'
import useCollateralAgreementsAllowedOperations from 'hooks/services/collaterals/useCollateralsAllowedOperations'
import CollateralAgreementCreationDialog from 'routes/deals/collaterals/creation/CollateralAgreementCreationDialog'

const CollateralAgreementCreateButton = () => {
  const { t: tNoPrefix } = useTranslation('translation')
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.detail.collaterals' })
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false)

  const handleCreateCollateralAgreementClick = () => {
    setIsDialogOpen(true)
  }

  const { data: { allowedOperations = [] } = {} } = useCollateralAgreementsAllowedOperations()
  const canCreateCollateralAgreement = allowedOperations.includes(
    collateralAgreementPermissions.createCollateralAgreement,
  )

  const handleCreationDialogCancel = () => {
    setIsMessageBoxOpen(true)
  }

  const handleMessageBoxClose = (action) => {
    setIsMessageBoxOpen(false)
    if (action === MessageBoxActions.OK) {
      setIsDialogOpen(false)
    }
  }

  if (!canCreateCollateralAgreement) return false

  return (
    <>
      <Button onClick={handleCreateCollateralAgreementClick} design={ButtonDesign.Emphasized}>
        {t('create-collateral-agreement')}
      </Button>
      <CollateralAgreementCreationDialog
        open={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        onCancel={handleCreationDialogCancel}
      />
      <MessageBox
        open={isMessageBoxOpen}
        titleText={t('cancel-dialog.title')}
        type={MessageBoxTypes.Warning}
        onClose={({ detail }) => handleMessageBoxClose(detail.action)}
        actions={[
          <DialogPrimaryButton data-action={MessageBoxActions.OK} key="discard">
            {tNoPrefix('buttons.discard')}
          </DialogPrimaryButton>,
          <DialogSecondaryButton data-action={MessageBoxActions.Cancel} key="cancel">
            {tNoPrefix('buttons.cancel')}
          </DialogSecondaryButton>,
        ]}
      >
        {t('cancel-dialog.message')}
      </MessageBox>
    </>
  )
}

export default CollateralAgreementCreateButton
