import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useDecisionStagesByIdList = ({ eventIds = [], options = {} }) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: eventIds.map((eventId) => ({
        path: `/events/${eventId}/decision-stages`,
        keys: ['events', eventId, 'decision-stages'],
      })),
      useCache: true,
      options: {
        enabled: !isEmpty(eventIds),
        ...options,
      },
    }),
  )

  return useMemo(
    () =>
      responses.map((result, index) => ({
        ...result,
        data: { eventId: eventIds[index], ...camelize(result.data) },
      })),
    [eventIds, responses],
  )
}

export default useDecisionStagesByIdList
