import { FlexBox } from '@fioneer/ui5-webcomponents-react'
import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import BaselTwoManualCheckSheetResultMessageStrip from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/manual-checksheet/BaselTwoManualCheckSheetResultMessageStrip'
import BaselTwoManualCheckSheetTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/manual-checksheet/BaselTwoManualCheckSheetTable'
import BaselTwoManualCheckSheetTableEdit from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/manual-checksheet/BaselTwoManualCheckSheetTableEdit'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/manual-checksheet/BaselTwoManualCheckSheetTile.module.css'

const BaselTwoManualCheckSheetTile = ({
  tileId,
  currentContent: currentContentStringified,
  onChange,
  isEditMode,
  setHasContentChanges,
}) => {
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const initialAnswers = useMemo(
    () => ({
      answers: {
        Q3: null,
        Q9: null,
        Q10: null,
        Q11: null,
        Q12: null,
        Q13: null,
        Q14: null,
        Q15: null,
        Q16: null,
        Q18: null,
        Q19: null,
        Q20: null,
        Q21: null,
        Comment: null,
      },
    }),
    [],
  )

  const { answers: currentAnswers } = useMemo(
    () =>
      currentContentStringified && typeof currentContentStringified === 'string'
        ? JSON.parse(currentContentStringified)
        : initialAnswers,
    [currentContentStringified, initialAnswers],
  )

  useEffect(() => {
    const hasContentChanges =
      !(isNil(currentContentStringified) && isNil(tileStatus?.data?.data)) &&
      !isEqual(tileStatus?.data?.data, currentContentStringified)
    setHasContentChanges(hasContentChanges)
  }, [currentContentStringified, tileStatus, setHasContentChanges, currentAnswers])

  return (
    <FlexBox direction="Column" className={styles.wrapper}>
      <BaselTwoManualCheckSheetResultMessageStrip
        currentAnswers={currentAnswers}
        isEditMode={isEditMode}
      />
      {isEditMode ? (
        <BaselTwoManualCheckSheetTableEdit currentAnswers={currentAnswers} onChange={onChange} />
      ) : (
        <BaselTwoManualCheckSheetTable currentAnswers={currentAnswers} />
      )}
    </FlexBox>
  )
}

BaselTwoManualCheckSheetTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  currentContent: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
}

export default BaselTwoManualCheckSheetTile
