import { Link } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import RequirementsTableAttachmentCell from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableAttachmentCell'
import useRequirementsCellAddMode from 'hooks/services/conditions/edit-mode/useRequirementsCellAddMode'

const RequirementsTableAttachmentCellWrapper = ({
  value: { id: requirementId },
  cell: {
    row: { id: rowId },
  },
  toggleRowExpanded,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.columns.attachment',
  })

  const { isRequirementAddMode } = useRequirementsCellAddMode({
    requirementId,
  })

  const noAttachmentCell = useMemo(
    () => <Link disabled={true}>{t('link-label', { numberOfAttachments: 0 })}</Link>,
    [t],
  )

  return isRequirementAddMode ? (
    noAttachmentCell
  ) : (
    <RequirementsTableAttachmentCell
      requirementId={requirementId}
      rowId={rowId}
      toggleRowExpanded={toggleRowExpanded}
    />
  )
}

RequirementsTableAttachmentCellWrapper.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  cell: PropTypes.shape({
    row: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  toggleRowExpanded: PropTypes.func.isRequired,
}

export default RequirementsTableAttachmentCellWrapper
