import { useRequest } from 'hooks/services/baseService'
import { useFrontendPagination } from 'hooks/services/deals/useFrontendPagination'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const initialCovenantSort = [
  { orderField: 'status', orderDirection: 'asc' },
  { orderField: 'covenantDefinition.type', orderDirection: 'asc' },
  { orderField: 'covenantDefinition.name', orderDirection: 'asc' },
]

// eslint-disable-next-line no-magic-numbers
const covenantsLimit = String(5000)
const covenantsOffset = String(0)

const extendItemsWithCheckCycleOrder = (items = []) => {
  const CheckCycleUnitToMonths = {
    MONTHLY: 1,
    QUARTERLY: 3,
    BI_ANNUALLY: 6,
    ANNUALLY: 12,
  }
  const isValidCheckCycle = (cycle) => Object.keys(CheckCycleUnitToMonths).includes(cycle)

  return items.map((item) => ({
    ...item,
    checkCycleOrder: isValidCheckCycle(item?.checkCycle?.cycle)
      ? CheckCycleUnitToMonths[item.checkCycle.cycle] * item?.checkCycle?.number
      : Number.MAX_VALUE,
  }))
}

export const useCovenants = ({ mainEntityType, mainEntityId, validAt, options = {} }) => {
  const queryParams = new URLSearchParams()

  queryParams.append('main_entity_type', mainEntityType)
  queryParams.append('main_entity_id', mainEntityId)
  queryParams.append('limit', covenantsLimit)
  queryParams.append('offset', covenantsOffset)
  if (validAt) queryParams.append('validAt', validAt)

  const result = useCamelizedResponse(
    useRequest({
      path: `/covenants?${queryParams.toString()}`,
      translated: true,
      useCache: true,
      keys: ['covenants', mainEntityType, mainEntityId, validAt],
      options: { enabled: !!mainEntityId && !!mainEntityType, ...options },
    }),
  )

  return {
    ...result,
    data: {
      ...result.data,
      covenants: extendItemsWithCheckCycleOrder(result?.data?.covenants),
    },
  }
}

export const useCovenantsWithFrontendPagination = ({
  mainEntityId,
  mainEntityType,
  validAt: validAt,
  sort = {},
  pagination = {},
}) => {
  const result = useCovenants({ mainEntityType, mainEntityId, validAt })
  return useFrontendPagination({
    fetchResult: result,
    dataKey: 'covenants',
    initialSort: initialCovenantSort,
    sort: sort,
    pagination: pagination,
  })
}
