import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'

const usePageConfig = (code, options = { enabled: !!code }) => {
  const params = new URLSearchParams()
  if (code) params.append('code', code)

  const paramsString = [...params.keys()].length ? `?${params.toString()}` : ''

  const response = useRequest({
    path: `/central-data/configurations/page-tiles/versions/latest${paramsString}`,
    useCache: true,
    keys: ['central-data', 'configurations', 'page-tiles', 'versions', 'latest', code],
    options,
  })

  return useMemo(() => ({ ...response }), [response])
}

export default usePageConfig
