import { Label } from '@fioneer/ui5-webcomponents-react'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/properties-search/table/PropertyCreatedAt.module.css'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import '@ui5/webcomponents-icons/dist/AllIcons.js'

const PropertyCreatedAt = ({ createdAt }) => {
  const { t } = useTranslation()
  const { localePattern } = useShortDateFormatter()
  const dateTime = DateTime.fromISO(createdAt)

  const renderLabel = (value, fontStyle = 'normal') => (
    <Label className={styles.label} wrappingType="Normal" style={{ fontStyle }}>
      {value}
    </Label>
  )

  if (!createdAt || !dateTime.isValid)
    return renderLabel(t('pages.properties.data-error'), 'italic')

  const formattedDate = dateTime.toFormat(localePattern)
  const formattedHour = String(dateTime.get('hour')).padStart(2, '0')
  const formattedMinute = String(dateTime.get('minute')).padStart(2, '0')

  return renderLabel(`${formattedDate} ${formattedHour}:${formattedMinute}`)
}

PropertyCreatedAt.propTypes = {
  userId: PropTypes.string,
}

export default PropertyCreatedAt
