import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/events/dialog/EventCreationDialog.module.css'
import EventCreationDialogEventTemplates from 'components/domains/business-events-and-tasks/events/dialog/EventCreationDialogEventTemplates'

const EventCreationEventTemplates = ({
  selectedEntityId,
  selectedEntityType,
  isSelectedEntityWorkingVersion,
  setSelectedEventType,
  selectedEventType,
  disabled,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dialog.event-creation' })

  if (!selectedEntityId || !selectedEntityType) {
    return <Label>{t('event-template.missing-selection')}</Label>
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.flexGrid}>
      <EventCreationDialogEventTemplates
        entityType={selectedEntityType}
        isEntityWorkingVersion={isSelectedEntityWorkingVersion}
        onEventTypeSelected={setSelectedEventType}
        preSelectedEventType={selectedEventType}
        disabled={disabled}
      />
    </FlexBox>
  )
}

EventCreationEventTemplates.propTypes = {
  selectedEntityType: PropTypes.string.isRequired,
  selectedEntityId: PropTypes.string.isRequired,
  isSelectedEntityWorkingVersion: PropTypes.bool.isRequired,
  setSelectedEventType: PropTypes.func.isRequired,
  selectedEventType: PropTypes.string,
  disabled: PropTypes.bool,
}

export default EventCreationEventTemplates
