import isNil from 'lodash.isnil'
import { useContext, useMemo } from 'react'
import { ConfigContext } from 'hooks/config/useConfig'
import useCollateralAgreementsByTrancheIds from 'hooks/services/collaterals/useCollateralAgreementsByTrancheIds'
import useTranches from 'hooks/services/deals/covenants/useTranches'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'

const trancheDataSourceNewBusiness = DATA_SOURCES.NEW_BUSINESS

export const useInvolvedPartiesGetGuarantors = ({ dealUuid }) => {
  const { businessPartner: { functionCodes: { guarantor: guarantorFunctionCode } = {} } = {} } =
    useContext(ConfigContext)

  const {
    isLoading: isTranchesLoading,
    isFetching: isTranchesFetching,
    isError: isTranchesError,
    error: tranchesError,
    data: tranchesData,
  } = useTranches({ dealUuid, dataSource: trancheDataSourceNewBusiness })

  const trancheIds = useMemo(
    () => tranchesData?.tranches.map(({ trancheId }) => trancheId) || [],
    [tranchesData],
  )

  const {
    isLoading: isCollateralAgreementsLoading,
    isFetching: isCollateralAgreementsFetching,
    isError: isCollateralAgreementsError,
    error: collateralAgreementsError,
    data: collateralAgreements,
  } = useCollateralAgreementsByTrancheIds({ ids: trancheIds })

  const collateralAgreementsBusinessPartners = useMemo(() => {
    const result = new Set()
    collateralAgreements?.collateralAgreements.forEach(({ businessPartners }) => {
      businessPartners?.forEach(({ function: { id: functionCode }, displayId }) => {
        if (functionCode === guarantorFunctionCode) {
          result.add(displayId)
        }
      })
    })
    return Array.from(result)
  }, [collateralAgreements?.collateralAgreements, guarantorFunctionCode])

  const isAnyLoading = isTranchesLoading || (trancheIds.length > 0 && isCollateralAgreementsLoading)
  const isAnyFetching =
    isTranchesFetching || (trancheIds.length > 0 && isCollateralAgreementsFetching)
  const isAnyError = isTranchesError || isCollateralAgreementsError
  const errors = useMemo(
    () => [tranchesError, collateralAgreementsError].filter((error) => !isNil(error)),
    [collateralAgreementsError, tranchesError],
  )

  return useMemo(() => {
    if (isAnyLoading || isAnyError || isAnyFetching) {
      return { isError: isAnyError, isLoading: isAnyLoading, isFetching: isAnyFetching, errors }
    }
    return {
      isLoading: false,
      isFetching: false,
      isError: false,
      data: collateralAgreementsBusinessPartners.map((partyId) => ({ partyId })),
    }
  }, [isAnyLoading, isAnyError, isAnyFetching, collateralAgreementsBusinessPartners, errors])
}
