import { Link as UI5Link } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useResolvedPath } from 'react-router-dom'
import useCreateNavigateClickHandler from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/shared/table/table-cells/conditions/link/useCreateNavigateClickHandler'

const propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
    }),
  ]),
  /** `_self` => uses React Router navigation; else `window.open` */
  target: PropTypes.string,
  /** only used with `window.open` */
  features: PropTypes.string,
  /** only used with `useNavigate` */
  preventScrollReset: PropTypes.bool,
  /** only used with `useNavigate` */
  relative: PropTypes.oneOf(['route', 'path']),
  /** only used with `useNavigate` */
  replace: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
}

/**
 * @typedef {Parameters<typeof UI5Link>[0]} extend
 *
 * @typedef {object} overrides
 * @property {import('react-router-dom').RelativeRoutingType} [relative]
 * @property {import('react').CSSProperties} [style]
 * @property {extend['onClick']} [onClick]
 *
 * It's a link. **Only works for links to the same host**
 *
 * This component combines the design of the UI5 Link with custom click logic.
 *
 * If you set the `target` to `'_self'`, it uses a `react-router` navigation,
 * otherwise it calls `window.open`
 *
 * If you define the `onClick` handler, consider calling `event.preventDefault()` to prevent page reloads.
 * @typedef {PropTypes.InferProps<typeof propTypes>} props
 * @typedef {import('react').Ref<import('@fioneer/ui5-webcomponents-react').LinkDomRef>} ref
 */
const Link = forwardRef(
  /**
   * @param {Omit<Omit<extend, keyof props> & props, keyof overrides> & overrides} props
   * @param {ref} ref
   */
  ({ href, target, features, preventScrollReset, relative, replace, children, ...props }, ref) => {
    const createClickHandler = useCreateNavigateClickHandler({
      features: features ?? undefined,
      preventScrollReset: preventScrollReset ?? undefined,
      relative: relative ?? undefined,
      replace: replace ?? undefined,
      target: target ?? undefined,
    })

    const path = useResolvedPath(href ?? '')

    const handleClick = createClickHandler(path)

    return (
      <UI5Link
        // HINT: keep onClick above the {...props} so the caller can overwrite the onClick handling
        onClick={handleClick}
        {...props}
        ref={ref}
        target={target ?? undefined}
        href={`${path.pathname}${path.search}${path.hash}`}
      >
        {children}
      </UI5Link>
    )
  },
)

Link.displayName = 'Link'

Link.propTypes = propTypes

export default Link
