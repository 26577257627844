import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxDirection,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/conditions/overview/buttons/external-conditions/dialogs/TransferExternalConditionDialog.module.css'
import BusinessPartnerAutocompleteInput from 'components/domains/deals/creation-dialog/BusinessPartnerAutocompleteInput'
import Dialog from 'components/ui/dialog/Dialog'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'

const formatBusinessPartnerName = ({ id, name }) => {
  if (!name) {
    return ''
  }
  return id ? `${name} (${id})` : name
}

const TransferExternalConditionDialog = ({
  selectedBusinessPartnerId,
  isConfirmDisabled,
  isOpen,
  setIsOpen,
  onChange,
  onConfirm,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.buttons.transfer-condition',
  })

  const { data: selectedBusinessPartnerData } = useBusinessPartnerById(selectedBusinessPartnerId)
  const selectedBusinessPartner = useMemo(
    () => ({
      id: selectedBusinessPartnerData?.id,
      name: selectedBusinessPartnerData?.fullName,
    }),
    [selectedBusinessPartnerData],
  )

  const handleOnAfterClose = useCallback(() => setIsOpen(false), [setIsOpen])

  const dialogConfirmButton = useMemo(
    () => (
      <Button onClick={onConfirm} design={ButtonDesign.Emphasized} disabled={isConfirmDisabled}>
        {t('dialog.confirm-button')}
      </Button>
    ),
    [isConfirmDisabled, onConfirm, t],
  )

  const getSearchString = useCallback(
    (input) => {
      if (!isEmpty(input)) {
        return input
      }
      return formatBusinessPartnerName(selectedBusinessPartner)
    },
    [selectedBusinessPartner],
  )

  return (
    <Dialog
      headerText={t('dialog.title')}
      primaryButton={dialogConfirmButton}
      open={isOpen}
      onAfterClose={handleOnAfterClose}
    >
      <FlexBox className={styles.dialogContent} direction={FlexBoxDirection.Column}>
        <Text>{t('dialog.text')}</Text>
        <BusinessPartnerAutocompleteInput
          defaultValue={formatBusinessPartnerName(selectedBusinessPartner)}
          suggestionItemProps={({ name, id }) => ({
            text: formatBusinessPartnerName({ id, name }),
          })}
          getSearchString={getSearchString}
          valueForUnknown={(text) => ({ name: text })}
          onBusinessPartnerSelected={onChange}
        />
      </FlexBox>
    </Dialog>
  )
}

TransferExternalConditionDialog.propTypes = {
  selectedBusinessPartnerId: PropTypes.string,
  isConfirmDisabled: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default TransferExternalConditionDialog
