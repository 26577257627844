import { MessageBoxActions, MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddNewCommentField from 'components/ui/comment/AddNewCommentField'
import { usePublishEventComment } from 'hooks/services/business-events-and-tasks/events/comments/usePublishEventComment'
import { usePublishTaskComment } from 'hooks/services/business-events-and-tasks/tasks/usePublishTaskComment'

const NewCommentField = ({ eventId, taskId }) => {
  const [isEditCommentMode, setIsEditCommentMode] = useState(false)
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.comment-section.edit',
  })
  const showMessageBox = Modals.useShowMessageBox()
  const queryClient = useQueryClient()

  const onPublishError = useCallback(() => {
    showMessageBox(
      {
        titleText: t('error.title'),
        children: t('error.description'),
        actions: [MessageBoxActions.Cancel],
        type: MessageBoxTypes.Error,
        draggable: true,
        resizable: true,
      },
      document.body,
    )
  }, [showMessageBox, t])

  const onPublishTaskSuccess = useCallback(() => {
    queryClient.invalidateQueries(['events', eventId, 'tasks', taskId, 'comments'])
    setIsEditCommentMode(false)
  }, [eventId, queryClient, taskId])
  const onPublishEventSuccess = useCallback(() => {
    queryClient.invalidateQueries(['events', eventId, 'comments'])
    setIsEditCommentMode(false)
  }, [eventId, queryClient])

  const { mutate: mutatePublishEventComment } = usePublishEventComment({
    onSuccess: onPublishEventSuccess,
    onError: onPublishError,
  })
  const { mutate: mutatePublishTaskComment } = usePublishTaskComment({
    onSuccess: onPublishTaskSuccess,
    onError: onPublishError,
  })

  const handleCommentSave = useCallback(
    (content) => {
      if (taskId) {
        mutatePublishTaskComment({
          eventId,
          taskId,
          comment: content,
        })
        return
      }
      mutatePublishEventComment({
        eventId,
        comment: content,
      })
    },
    [eventId, mutatePublishEventComment, mutatePublishTaskComment, taskId],
  )

  return (
    <AddNewCommentField
      onSave={handleCommentSave}
      isEditMode={isEditCommentMode}
      setIsEditMode={setIsEditCommentMode}
    />
  )
}

NewCommentField.propTypes = {
  eventId: PropTypes.string.isRequired,
  taskId: PropTypes.string,
}

export default NewCommentField
