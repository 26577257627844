import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  Modals,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/entity-info/EntityTypeAndIdWithClipboard.module.css'
const space = <>&nbsp;</>

const EntityTypeAndIdWithClipboard = ({ entityType, name, id }) => {
  const { t } = useTranslation()
  const ref = useRef()
  const showToast = Modals.useShowToast()

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(id)
    showToast(
      {
        children: t('buttons.copy.success'),
        placement: 'TopCenter',
      },
      ref.current,
    )
  }, [id, showToast, t])

  const clipboardIcon = (
    <Button icon="copy" design={ButtonDesign.Transparent} onClick={copyToClipboard} />
  )

  const displayEntityInfo = () =>
    entityType && name ? `${t(`events.entity-type.${entityType}`)}: ${name} (${id})` : id

  return (
    <FlexBox className={styles.entityTypeNameId} alignItems={FlexBoxAlignItems.Center} ref={ref}>
      <div>
        {displayEntityInfo()}
        {space}
      </div>
      {clipboardIcon}
    </FlexBox>
  )
}

EntityTypeAndIdWithClipboard.propTypes = {
  name: PropTypes.string,
  entityType: PropTypes.string,
  id: PropTypes.string.isRequired,
}

export default EntityTypeAndIdWithClipboard
