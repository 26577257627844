import { ValueState } from '@fioneer/ui5-webcomponents-react'
import { get, set, has, reduce } from 'lodash'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useStore } from 'react-redux'
import {
  ROWHEIGHT_DUALCURRENCY,
  ROWHEIGHT_READMODE,
  getRowKey,
  isDualCurrencyRow,
} from 'components/domains/business-partners/tile/arrears/current-approvals/useCurrentApprovalsTableData'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import { useUpdateAllApprovalItems } from 'hooks/services/arrears/useUpdateAllApprovalItems'
import { useUpdateMultipleApprovalItems } from 'hooks/services/arrears/useUpdateMultipleApprovalItems'
import {
  deleteEditRow,
  setErrorSummary,
  setProductRowInEdit,
  setWarningSummary,
  updateEditRowField,
} from 'redux/slices/arrears/arrearApprovalSlice'
import { BusinessPartnerArrearsContext } from 'routes/business-partners/arrears/BusinessPartnerArrearsContext'
import { ENTITY_TYPES } from 'routes/business-partners/arrears/constants'

const getValueOrZero = (value) => (value !== null ? value : 0)

const useCurrentApprovalsTableEdit = ({
  originalErrorSum = 0,
  originalWarningSum = 0,
  isStartApprovalProcess = false,
  onFinishUpdatingAllApprovalItems = () => {},
  tableData = [],
}) => {
  const store = useStore()
  const dispatch = useDispatch()

  const isRowInEditMode = useCallback(
    (rowKey) => {
      const productRowInEdit =
        store.getState().arrears.arrearApproval.currentApproval.productRowInEdit
      return (
        productRowInEdit?.rowKey === rowKey ||
        productRowInEdit?.approvalItemRowKeys?.some((subRowKey) => subRowKey === rowKey)
      )
    },
    [store],
  )

  /**
   * Gets the editRows based on boolean isStartApprovalProcess directly from redux store on demand.
   */
  const getEditRows = useCallback(() => {
    const arrearApprovalStore = store.getState().arrears.arrearApproval
    if (isStartApprovalProcess) {
      return arrearApprovalStore.startApproval.editRows
    } else {
      return arrearApprovalStore.currentApproval.editRows
    }
  }, [isStartApprovalProcess, store])

  const getEditRow = useCallback(
    (row) => {
      const editRows = getEditRows()
      return editRows[getRowKey(row)]
    },
    [getEditRows],
  )

  const isParentRowByRowKey = useCallback(
    (row, rowKey) => !!row.subRows.find((subRow) => getRowKey(subRow) === rowKey),
    [],
  )

  const getParentRowByRowKey = useCallback(
    (rowKey, rows = tableData) => {
      for (const row of rows) {
        if (isParentRowByRowKey(row, rowKey)) {
          return row
        }
        if (row.subRows) {
          const parentRow = getParentRowByRowKey(rowKey, row.subRows)
          if (parentRow) {
            return parentRow
          }
        }
      }
      return null
    },
    [isParentRowByRowKey, tableData],
  )

  const setRowHeightToAppropriateVal = (row, isEdit = false) => {
    if (isDualCurrencyRow(row) && isEdit) {
      row['rowHeight'] = ROWHEIGHT_DUALCURRENCY
    } else {
      row['rowHeight'] = ROWHEIGHT_READMODE
    }
  }

  const hasDirectApprovalItems = useCallback(
    (row) => row.subRows?.some((subRow) => subRow.entityType === ENTITY_TYPES.APPROVALITEM),
    [],
  )

  const setRowAndSubRowsHeightAfterToggle = useCallback((row, isToggleToEditMode) => {
    setRowHeightToAppropriateVal(row, isToggleToEditMode)
    row.subRows.forEach((subRow) => {
      if (subRow.entityType === ENTITY_TYPES.APPROVALITEM) {
        setRowHeightToAppropriateVal(subRow, isToggleToEditMode)
      }
    })
  }, [])

  const setProductRowInEditAfterToggle = useCallback(
    (productRowInEdit, isToggleToEditMode) => {
      if (isToggleToEditMode) {
        dispatch(setProductRowInEdit(productRowInEdit))
      } else {
        dispatch(setProductRowInEdit(null))
      }
    },
    [dispatch],
  )

  const toggleEditRow = useCallback(
    (productRowInEdit, row) => {
      if (!hasDirectApprovalItems(row)) {
        return
      }
      const isToggleToEditMode = !isRowInEditMode(productRowInEdit.rowKey)
      setRowAndSubRowsHeightAfterToggle(row, isToggleToEditMode)
      setProductRowInEditAfterToggle(productRowInEdit, isToggleToEditMode)
    },
    [
      hasDirectApprovalItems,
      isRowInEditMode,
      setProductRowInEditAfterToggle,
      setRowAndSubRowsHeightAfterToggle,
    ],
  )

  const getApprovalItemRowKeys = useCallback(
    (row) =>
      row.subRows
        ?.map((subRow) =>
          subRow.entityType === ENTITY_TYPES.APPROVALITEM ? getRowKey(subRow) : null,
        )
        .filter(Boolean),
    [],
  )

  const toggleEditRowAndSubRows = useCallback(
    (row) => {
      const rowKey = getRowKey(row)
      const productRowInEdit = {
        rowKey,
        approvalItemRowKeys: getApprovalItemRowKeys(row),
      }
      toggleEditRow(productRowInEdit, row)
    },
    [getApprovalItemRowKeys, toggleEditRow],
  )

  const getRowValueState = useCallback(
    (row) => {
      const editedRow = getEditRow(row)
      if (!hasDirectApprovalItems(row)) {
        return []
      }
      return [
        {
          approvedUntilValueState: has(editedRow, 'approvedUntil.valueState')
            ? editedRow.approvedUntil.valueState
            : row.rowData.approvedUntil.valueState,
          classificationCodeValueState: has(editedRow, 'classificationCode.valueState')
            ? editedRow.classificationCode.valueState
            : row.rowData.classificationCode.valueState,
        },
      ]
    },
    [getEditRow, hasDirectApprovalItems],
  )

  const getSubrowsValueStates = useCallback(
    (row) => {
      const subRowsPatch = row.subRows.reduce((valueStatePatches, subRow) => {
        const editedSubRow = getEditRow(subRow)
        if (subRow.entityType === ENTITY_TYPES.APPROVALITEM) {
          const approvalItemValueStatePatch = {
            approvalAmountValueState: has(editedSubRow, 'approvalAmount.valueState')
              ? editedSubRow.approvalAmount.valueState
              : subRow.rowData.approvalItem.approvalAmount.valueState,
          }
          return [...valueStatePatches, approvalItemValueStatePatch]
        }
        if (subRow.entityType !== ENTITY_TYPES.APPROVALITEM && hasDirectApprovalItems(subRow)) {
          const productValueStatePatch = {
            approvedUntilValueState: has(editedSubRow, 'approvedUntil.valueState')
              ? editedSubRow.approvedUntil.valueState
              : subRow.rowData.approvedUntil.valueState,
            classificationCodeValueState: has(editedSubRow, 'classificationCode.valueState')
              ? editedSubRow.classificationCode.valueState
              : subRow.rowData.classificationCode.valueState,
          }
          return [...valueStatePatches, productValueStatePatch]
        }
        return valueStatePatches
      }, [])
      return [...subRowsPatch]
    },
    [getEditRow, hasDirectApprovalItems],
  )

  const recursivelyExtractSubrowValueStates = useCallback(
    (row) => [
      ...getSubrowsValueStates(row),
      ...row.subRows.flatMap((subRow) => recursivelyExtractSubrowValueStates(subRow)),
    ],

    [getSubrowsValueStates],
  )

  const getRowAndSubrowsValueStates = useCallback(
    (row) => [...getRowValueState(row), ...recursivelyExtractSubrowValueStates(row)],
    [getRowValueState, recursivelyExtractSubrowValueStates],
  )

  const getAllValueStates = useCallback(() => {
    const valueStates = tableData.flatMap((row) => getRowAndSubrowsValueStates(row))
    let error = 0
    let warning = 0
    valueStates.forEach(
      (singleRow) =>
        (error += Object.values(singleRow).filter(
          (valueState) => valueState === ValueState.Error,
        ).length),
    )
    valueStates.forEach(
      (singleRow) =>
        (warning += Object.values(singleRow).filter(
          (valueState) => valueState === ValueState.Warning,
        ).length),
    )
    return { error, warning }
  }, [getRowAndSubrowsValueStates, tableData])

  const updateErrorAndWarningSummary = useCallback(
    (errorSum, warningSum) => {
      dispatch(setErrorSummary({ isStartApprovalProcess, value: errorSum }))
      dispatch(setWarningSummary({ isStartApprovalProcess, value: warningSum }))
    },
    [dispatch, isStartApprovalProcess],
  )

  const updateAndValidateEditRow = useCallback(
    (originalRow, fieldName, value, valueState, isParentRow = false) => {
      const rowKey = getRowKey(originalRow)
      const valueUpdate = {
        value,
      }
      if (!isParentRow) {
        set(valueUpdate, 'valueState', valueState)
      }
      dispatch(
        updateEditRowField({
          isStartApprovalProcess,
          rowKey,
          fieldName: fieldName,
          fieldValue: valueUpdate,
        }),
      )
      const valueStates = getAllValueStates()
      updateErrorAndWarningSummary(valueStates.error, valueStates.warning)
    },
    [dispatch, getAllValueStates, isStartApprovalProcess, updateErrorAndWarningSummary],
  )

  const clearEditRow = useCallback(
    (originalRow) => {
      const rowKey = getRowKey(originalRow)
      dispatch(
        deleteEditRow({
          isStartApprovalProcess,
          rowKey,
        }),
      )
    },
    [dispatch, isStartApprovalProcess],
  )

  const getCurrentEditValue = useCallback(
    (row, valueFieldName) => get(getEditRow(row), valueFieldName),
    [getEditRow],
  )

  const correctSummedAmountForParentRow = useCallback(
    (row, oldAmount, newAmount) => {
      const amountDifference = parseFloat(oldAmount) - parseFloat(newAmount)
      let parentRow = getParentRowByRowKey(getRowKey(row))
      let parentRowAmount
      do {
        parentRowAmount =
          getCurrentEditValue(parentRow, 'approvalAmount.value') ?? parentRow.sumData.approvedAmount
        updateAndValidateEditRow(
          parentRow,
          'approvalAmount',
          parseFloat(parentRowAmount) - parseFloat(amountDifference),
          ValueState.None,
          true,
        )
        parentRow = getParentRowByRowKey(getRowKey(parentRow))
      } while (parentRow !== null)
    },
    [getCurrentEditValue, getParentRowByRowKey, updateAndValidateEditRow],
  )

  const clearRowAndSubrowsFromEditMode = useCallback(
    (row) => {
      clearEditRow(row)
      row.subRows?.forEach((subRow) => clearRowAndSubrowsFromEditMode(subRow))
    },
    [clearEditRow],
  )

  const cancelAllEditRows = useCallback(() => {
    if (isStartApprovalProcess) {
      updateErrorAndWarningSummary(originalErrorSum, originalWarningSum)
    } else {
      dispatch(setProductRowInEdit(null))
    }
    tableData.forEach((row) => {
      clearEditRow(row)
      row.subRows?.forEach((subRow) => clearRowAndSubrowsFromEditMode(subRow))
    })
  }, [
    isStartApprovalProcess,
    tableData,
    updateErrorAndWarningSummary,
    originalErrorSum,
    originalWarningSum,
    dispatch,
    clearEditRow,
    clearRowAndSubrowsFromEditMode,
  ])

  const showErrorMessageBox = useShowErrorMessageBox()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.in-approval',
  })

  const onUpdateRowAndSubrowsFinish = (errors) => {
    cancelAllEditRows()
    if (errors?.length) {
      showErrorMessageBox({ message: t('error.save'), error: errors })
    }
  }

  const { businessPartnerId } = useContext(BusinessPartnerArrearsContext)
  const { isUpdating: isUpdatingApprovalItems, updateApprovalItems } =
    useUpdateMultipleApprovalItems({
      businessPartnerId,
      onFinish: onUpdateRowAndSubrowsFinish,
    })
  const { updateAllApprovalItems } = useUpdateAllApprovalItems({
    businessPartnerId,
    onFinish: () => {
      onFinishUpdatingAllApprovalItems()
      cancelAllEditRows()
    },
  })

  const hasRelevantChanges = useCallback((rowUpdate, originalRow) => {
    const relevantFields = ['approvalAmount', 'approvedUntil', 'comment']
    return (
      relevantFields.some(
        (valueFieldName) => get(rowUpdate, valueFieldName) !== originalRow[valueFieldName]?.value,
      ) || get(rowUpdate, 'classificationCode') !== originalRow.classification.value.code
    )
  }, [])

  const createApprovalItemPatches = useCallback(
    (row) => {
      const editRows = getEditRows()
      const editedRow = editRows[getRowKey(row)]
      return reduce(
        row.subRows,
        (itemPatches, subRow) => {
          if (subRow.entityType === ENTITY_TYPES.APPROVALITEM) {
            const editedSubRow = editRows[getRowKey(subRow)]
            const approvalItemPatch = {
              approvalItemId: subRow.rowData.approvalItem.id,
              arrearExternalId: subRow.rowData.approvalItem.arrearExternalId,
              approvalAmount: has(editedSubRow, 'approvalAmount.value')
                ? getValueOrZero(editedSubRow.approvalAmount.value)
                : subRow.rowData.approvalItem.approvalAmount.value,
              approvedUntil: has(editedRow, 'approvedUntil.value')
                ? editedRow.approvedUntil.value
                : subRow.rowData.approvalItem.approvedUntil.value,
              classificationCode: has(editedRow, 'classificationCode.value')
                ? editedRow.classificationCode.value
                : subRow.rowData.approvalItem.classification.value.code,
              comment: has(editedRow, 'comment.value')
                ? editedRow.comment.value
                : subRow.rowData.approvalItem.comment.value,
            }

            return isStartApprovalProcess ||
              hasRelevantChanges(approvalItemPatch, subRow.rowData.approvalItem)
              ? [...itemPatches, approvalItemPatch]
              : itemPatches
          }
          return itemPatches
        },
        [],
      )
    },
    [getEditRows, hasRelevantChanges, isStartApprovalProcess],
  )

  const recursivelyExtractApprovalItems = useCallback(
    (row) => [
      ...createApprovalItemPatches(row),
      ...row.subRows.flatMap((subRow) => recursivelyExtractApprovalItems(subRow)),
    ],
    [createApprovalItemPatches],
  )

  const isSaveEnabled = useCallback(
    (row) => {
      const rowAndSubrowsValueStates = [
        ...getRowValueState(row),
        ...recursivelyExtractSubrowValueStates(row),
      ]
      const validValueStateRowAndSubrows = rowAndSubrowsValueStates.every(
        (singleRow) => !Object.values(singleRow).includes(ValueState.Error),
      )
      return !!recursivelyExtractApprovalItems(row).length && validValueStateRowAndSubrows
    },
    [getRowValueState, recursivelyExtractApprovalItems, recursivelyExtractSubrowValueStates],
  )

  const handleSaveRowAndSubrows = useCallback(
    (row) => {
      const patchObjects = recursivelyExtractApprovalItems(row)
      updateApprovalItems(patchObjects)
    },
    [recursivelyExtractApprovalItems, updateApprovalItems],
  )

  const handleSaveAll = useCallback(() => {
    updateAllApprovalItems(tableData.flatMap((row) => recursivelyExtractApprovalItems(row)) ?? [])
  }, [recursivelyExtractApprovalItems, tableData, updateAllApprovalItems])

  return useMemo(
    () => ({
      toggleEditRowAndSubRows,
      updateAndValidateEditRow,
      correctSummedAmountForParentRow,
      handleSaveRowAndSubrows,
      isSaveEnabled,
      handleSaveAll,
      cancelAllEditRows,
      isUpdatingApprovalItems,
      updateErrorAndWarningSummary,
      hasDirectApprovalItems,
    }),
    [
      toggleEditRowAndSubRows,
      updateAndValidateEditRow,
      correctSummedAmountForParentRow,
      handleSaveRowAndSubrows,
      isSaveEnabled,
      handleSaveAll,
      cancelAllEditRows,
      isUpdatingApprovalItems,
      updateErrorAndWarningSummary,
      hasDirectApprovalItems,
    ],
  )
}

export default useCurrentApprovalsTableEdit
