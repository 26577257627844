import { ObjectPageSection } from '@fioneer/ui5-webcomponents-react'
import get from 'lodash.get'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useConfigurableComponentsPageState } from 'components/ui/configurable-components-page/useConfigurableComponentsPageState'
import { wrapComponentWithLayout } from 'components/ui/configurable-components-page/wrapComponentWithLayout'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'

/**
 *
 * @param {unknown} pageData
 * @param {import('components/ui/configurable-components-page/useConfigurableComponentsPageState').DynamicPageEntry[] | undefined} children
 * @returns {ReactElement[] | undefined}
 */
const renderChildren = (contextData, pageData, children) => {
  if (isNil(children)) {
    return undefined
  }
  return children.flatMap((child) => {
    const renderedGrandChildren = renderChildren(contextData, pageData, child.children)
    if (child.if && !get(pageData, child.if)) {
      return []
    }
    return [
      wrapComponentWithLayout({
        id: child.id,
        Component: child.resolvedComponent?.Component,
        componentType: child.resolvedComponent?.type,
        span: child.span,
        contextData,
        pageData,
        staticData: child.staticData,
        children: renderedGrandChildren,
      }),
    ]
  })
}

const propTypes = {
  pageCode: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  contextData: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  pageData: PropTypes.any,
  renderContent: PropTypes.func,
}

/**
 *
 * @param {import('prop-types').InferProps<propTypes>} props
 * @returns
 */
const ConfigurableComponentsPage = ({ pageCode, pageData, renderContent }) => {
  const contextData = useMemo(() => ({}), [])

  const { isAnyLoading, isAnyError, loadingComponents, pageConfig } =
    useConfigurableComponentsPageState({
      pageCode,
      contextData,
    })

  const children = useMemo(() => {
    if (isAnyLoading || isAnyError) {
      return (
        <ObjectPageSection id={pageConfig?.defaultTab}>
          <RequestStateResolver
            isLoading={isAnyLoading}
            isError={isAnyError}
            renderContent={() => null}
            errorToDisplay={<ErrorDataUnavailableInContent />}
          />
        </ObjectPageSection>
      )
    }
    return renderChildren(contextData, pageData, pageConfig?.children)
  }, [
    contextData,
    isAnyError,
    isAnyLoading,
    pageConfig?.children,
    pageConfig?.defaultTab,
    pageData,
  ])

  const content = useMemo(
    () => renderContent({ pageConfig, children }),
    [children, pageConfig, renderContent],
  )

  return (
    <>
      {loadingComponents}
      {content}
    </>
  )
}

ConfigurableComponentsPage.propTypes = propTypes

export default ConfigurableComponentsPage
