import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MarketMap from 'components/domains/markets/detail/cards/map/MarketMap'
import Card from 'components/ui/card/Card'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useMarketArea from 'hooks/services/markets/useMarketArea'
import useMultiProperties from 'hooks/services/properties/useMultiProperties'

const MarketMapCard = ({ marketExists, marketId, properties, showEditButton }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.markets.detail.properties' })

  const { data: propertyRequestsData = { properties: [] } } = useMultiProperties(properties, {
    enabled: properties?.length !== 0,
  })

  const {
    data: marketArea,
    isLoading: isLoadingArea,
    isError: isErrorArea,
  } = useMarketArea(marketId)
  const [displayPolygon, setDisplayPolygon] = useState('')

  useEffect(() => {
    if (!marketArea) return
    setDisplayPolygon(marketArea.area.polygonCoordinates)
  }, [marketArea])

  const mapMarketPropertyMarkersData = (property) => ({
    id: property.id,
    uuid: property.uuid,
    name: property.description,
    address: {
      country: property.address.countryName,
      street: property.address.streetName,
      houseNumber: property.address.houseId,
      zipCode: property.address.postalCode,
      city: property.address.cityName,
    },
    propertyType: property.typeName,
    // TODO: Refactor so that latitutde and longitude are handled as strings and not as numbers
    location: {
      lat: +property.geoLocation.latitude,
      lng: +property.geoLocation.longitude,
    },
    creationDate: property.systemAdministrativeData.creationDateTime,
  })
  const renderContent = () => {
    const filteredPropertiesWithLink = propertyRequestsData.properties.map(
      mapMarketPropertyMarkersData,
    )

    return (
      <MarketMap
        marketExists={marketExists}
        marketId={marketId}
        propertyData={propertyRequestsData}
        propertyIsLoading={isLoadingArea}
        propertyIsError={isErrorArea}
        polygon={displayPolygon}
        filteredPropertiesWithLink={filteredPropertiesWithLink}
        showEditButton={showEditButton}
      />
    )
  }

  return (
    <Card>
      <LoadingStateWrapper
        isError={isErrorArea}
        isLoading={!isErrorArea && (isLoadingArea || displayPolygon === '')}
        renderContent={renderContent}
        errorTitle={t('error.title')}
        errorDescription={t('error.description')}
        errorDetails={t('error.details')}
      />
    </Card>
  )
}

MarketMapCard.propTypes = {
  marketExists: PropTypes.bool.isRequired,
  marketId: PropTypes.string.isRequired,
  properties: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  ),
  showEditButton: PropTypes.bool.isRequired,
}

export default MarketMapCard
