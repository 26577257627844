import {
  BusyIndicator,
  BusyIndicatorSize,
  Dialog,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/multi-property-upload/MultiPropertyUploadingDialog.module.css'

/**
 * A dialog component that displays a loading indicator while an excel file is being uploaded or created.
 * @param {Object} props - The props object for the component.
 * @param {boolean} props.isUploading - A boolean value indicating whether the file is being uploaded or created.
 * @returns {JSX.Element} - A JSX element representing the MultiPropertyUploadingDialog component.
 */
const MultiPropertyUploadingDialog = ({ isUploading }) => {
  const { t: tMultiPropertyUpload } = useTranslation('translation', {
    keyPrefix: 'pages.property-upload',
  })

  return (
    <Dialog open={isUploading} draggable resizable>
      <FlexBox
        className={styles.gap}
        direction={FlexBoxDirection.Column}
        alignItems={FlexBoxAlignItems.Center}
        justifyContent={FlexBoxJustifyContent.Center}
      >
        <BusyIndicator active size={BusyIndicatorSize.Large} />
        <Label>{tMultiPropertyUpload('uploading-file')}</Label>
      </FlexBox>
    </Dialog>
  )
}
MultiPropertyUploadingDialog.propTypes = {
  isUploading: PropTypes.bool.isRequired,
}
export default MultiPropertyUploadingDialog
