import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import CloseableMessageStrip from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/ui/feedback/CloseableMessageStrip'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/ui/standard-component-indicator/StandardComponentIndicator.module.css'
import { useConfig } from 'hooks/config/useConfig'

const useStandardComponentIndicatorConfig = () => {
  const { data: config } = useConfig()
  return config?.showStandardComponentIndicator === true
}

/**
 * Higher-Order Component that displays a message strip above your
 * component indicating that the shared standard component was used.
 *
 * ⚠️ **Only use this for components inside the `src/components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/ui/` folder!**
 *
 * How to use:
 *
 * @example   const MyComponent = (props) => { ... }
 *            export default withStandardComponentIndicator(MyComponent)
 *
 * @typedef {import('react').FunctionComponent} FunctionComponent
 * @typedef {import('react').ForwardRefExoticComponent} ForwardRefComponent
 *
 * @param {FunctionComponent} Component
 * @param {string} [name] the component name to display
 * @return {FunctionComponent | ForwardRefComponent} the wrapped component or original component
 *                                                   if standard component indicators are disabled
 */
const withStandardComponentIndicator = (Component, name) => {
  const componentName = name || Component.displayName

  if (!componentName) {
    throw new Error(
      'withStandardComponentIndicator: no component name was provided. Either pass a name as second argument or ensure that the wrapped component has the `displayName` property set ',
    )
  }

  const ComponentWithStandardComponentIndicator = forwardRef((props, ref) => {
    const shallDisplayStandardComponentIndicator = useStandardComponentIndicatorConfig()
    const { t } = useTranslation('decisionPaper')
    const renderedComponent = <Component {...props} ref={ref} />

    if (!shallDisplayStandardComponentIndicator) return renderedComponent

    return (
      <FlexBox className={styles.standardComponentWrapper} direction={FlexBoxDirection.Column}>
        <CloseableMessageStrip design="Positive">
          {t('decision-paper.unit-overview.components.ui.standard-component-indicator.message', {
            componentName,
          })}
        </CloseableMessageStrip>
        {renderedComponent}
      </FlexBox>
    )
  })

  ComponentWithStandardComponentIndicator.propTypes = Component.propTypes
  ComponentWithStandardComponentIndicator.displayName = `${componentName}StandardComponentIndicator`

  return ComponentWithStandardComponentIndicator
}

export default withStandardComponentIndicator
