import { Link, Text } from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { useMemo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/rich-text/ShortenedRichText.module.css'
import { emptyContent } from 'components/ui/rich-text-editor/CWPCKEditor'
import FormattedTextView from 'components/ui/rich-text-editor/FormattedTextView'

const htmlEndTagStartTagRegEx = /((<\/)\w+(>))(<)/g
const maxDefaultDescriptionDisplaySize = 400

const ShortenedRichText = ({
  description,
  maxDescriptionDisplaySize = maxDefaultDescriptionDisplaySize,
}) => {
  const newLineBetweenEndAndStartTagReplacer = useCallback(
    (_match, completeEndTag, _endTagFirstPart, _endTagSecondPart, startTag, _offset, _string) =>
      `${completeEndTag}\n${startTag}`,
    [],
  )

  const splitDescriptionLines = useCallback(
    (descriptionToSplit) =>
      descriptionToSplit
        .replaceAll(htmlEndTagStartTagRegEx, newLineBetweenEndAndStartTagReplacer)
        .split('\n'),
    [newLineBetweenEndAndStartTagReplacer],
  )
  const shortenDescription = useCallback(
    (descriptionToShorten) => descriptionToShorten.substring(0, maxDescriptionDisplaySize),
    [maxDescriptionDisplaySize],
  )

  const { t } = useTranslation('translation')
  const [showMore, setShowMore] = useState(false)

  const descriptionLines = useMemo(
    () => splitDescriptionLines(description),
    [description, splitDescriptionLines],
  )
  const toggleMoreButton = useCallback(
    (event) => {
      event.preventDefault()
      setShowMore(!showMore)
    },
    [showMore],
  )

  const link = useMemo(
    () => (
      <Link href="" onClick={toggleMoreButton}>
        {t('buttons.less')}
      </Link>
    ),
    [t, toggleMoreButton],
  )

  if (!description || description === emptyContent) {
    return <Text>{'-'}</Text>
  }

  if (
    showMore ||
    (descriptionLines.length <= 2 && descriptionLines[0].length <= maxDescriptionDisplaySize)
  ) {
    return (
      <div className={styles.textView}>
        <FormattedTextView className={styles.ignoreFirstParagraph} formattedText={description} />
        {showMore && link}
      </div>
    )
  }
  const descriptionText =
    descriptionLines[1] === emptyContent
      ? descriptionLines[0]
      : descriptionLines[0] + descriptionLines[1]
  const displayedText = shortenDescription(descriptionText)

  return (
    <div className={styles.textView}>
      <FormattedTextView className={styles.ignoreFirstParagraph} formattedText={displayedText} />
      {'... '}
      <Link href="" onClick={toggleMoreButton}>
        {t('buttons.more')}
      </Link>
    </div>
  )
}

ShortenedRichText.propTypes = {
  description: PropType.string.isRequired,
  maxDescriptionDisplaySize: PropType.number,
}

export default ShortenedRichText
