import { ObjectPageSection, ObjectPageSubSection } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { decisionPaperSynchronizationStatus } from 'api/decision-paper/decisionPaperApi'
import DecisionPaperPdfExportHeader from 'components/domains/business-events-and-tasks/decision-paper/pdf-export/DecisionPaperPdfExportHeader'
import DecisionPaperPdfExportPermissionWrapper from 'components/domains/business-events-and-tasks/decision-paper/pdf-export/DecisionPaperPdfExportPermissionWrapper'
import EventProvider from 'components/domains/business-events-and-tasks/decision-paper/pdf-export/EventProvider'
import DecisionPaperReduxStateLoaderWrapper from 'components/domains/business-events-and-tasks/decision-paper/redux-loader/DecisionPaperReduxStateLoaderWrapper'
import DecisionStageInformationCard from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageInformationCard'
import DecisionStageVotersTableLoadingWrapper from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageVotersTableLoadingWrapper'
import DecisionStageConditionsTable from 'components/domains/business-events-and-tasks/decision-stage/conditions/DecisionStageConditionsTable'
import DecisionStageConditionsVotersTable from 'components/domains/business-events-and-tasks/decision-stage/conditions/DecisionStageConditionsVotersTable'
import ConditionsInformationCard from 'components/domains/business-events-and-tasks/decision-stage/conditions/information/ConditionsInformationCard'
import DecisionStageMinutesCard from 'components/domains/business-events-and-tasks/decision-stage/minutes/DecisionStageMinutesCard'
import DecisionStageMinutesInformationCard from 'components/domains/business-events-and-tasks/decision-stage/minutes/DecisionStageMinutesInformationCard'
import DecisionStageMinutesVotersTable from 'components/domains/business-events-and-tasks/decision-stage/minutes/DecisionStageMinutesVotersTable'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useDecisionPaperVersion from 'hooks/services/business-events-and-tasks/decision-papers/useDecisionPaperVersion'
import useDecisionStageConditions from 'hooks/services/business-events-and-tasks/decision-process/stages/conditions/useDecisionStageConditions'
import useDecisionStageMinutes from 'hooks/services/business-events-and-tasks/decision-process/stages/minutes/useDecisionStageMinutes'
import useDecisionStage from 'hooks/services/business-events-and-tasks/decision-process/stages/useDecisionStage'
import DecisionPaperPdfExportContent from 'routes/business-events-and-tasks/decision-paper/pdf-export/DecisionPaperPdfExportContent'
import { someTilesAreLoadingSelector } from 'routes/business-events-and-tasks/decision-paper/selectors/decisionPaperSelectors'
import styles from 'routes/business-events-and-tasks/decision-process/stage/pdf-export/DecisionStagePdfExport.module.css'
import DecisionStagePdfExportHeader from 'routes/business-events-and-tasks/decision-process/stage/pdf-export/DecisionStagePdfExportHeader'

// Timeout for automatic printing function to allow rendering of the map and tables
const printingTimeout = 5000
const pageBreak = <div className={styles.pageBreak} />

const DecisionStagePdfExport = () => {
  const [queryParams] = useSearchParams()
  const eventId = queryParams.get('event_id')
  const decisionStageId = queryParams.get('stage_id')
  const [isPrintButtonEnabled, setIsPrintButtonEnabled] = useState(false)

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-stage.pdf-export',
  })

  const { t: tDecisionPaper } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper.pdf-export',
  })

  const {
    isError: isDecisionStageError,
    isLoading: isDecisionStageLoading,
    data: decisionStageData,
  } = useDecisionStage({ eventId, decisionStageId })

  const hasDecisionPaper = useMemo(
    () => !isNil(decisionStageData?.decisionPaper?.version),
    [decisionStageData?.decisionPaper?.version],
  )

  const {
    isError: isConditionsError,
    isLoading: isConditionsLoading,
    data: decisionStageConditionsData,
  } = useDecisionStageConditions({ eventId, decisionStageId })

  const {
    isError: isMinutesError,
    isLoading: isMinutesLoading,
    data: decisionStageMinutes,
  } = useDecisionStageMinutes({ eventId, decisionStageId })

  const {
    isLoading: isDecisionPaperLoading,
    isError: isDecisionPaperError,
    data: decisionPaper,
  } = useDecisionPaperVersion(
    { eventId, versionNumber: decisionStageData?.decisionPaper?.version },
    { enabled: hasDecisionPaper },
  )

  const hasMinutes = decisionStageData?.minutes.hasMinutes
  const hasConditions = decisionStageData?.conditions.hasConditions

  const noTilesAvailable = useSelector((state) => isEmpty(state.decisionPaper.tilesOverview.tiles))
  const anyTileLoading = useSelector((state) =>
    Object.values(state.decisionPaper.tilesOverview.tiles).some(({ isLoading }) => isLoading),
  )
  const anyTileHasError = useSelector((state) =>
    Object.values(state.decisionPaper.tilesOverview.tiles).some(({ isError }) => isError),
  )
  const someTilesAreLoading = useSelector(someTilesAreLoadingSelector)

  const isDecisionStageLoadingOrError = useMemo(
    () =>
      isDecisionStageLoading ||
      isDecisionStageError ||
      (hasMinutes && (isMinutesLoading || isMinutesError)) ||
      (hasConditions && (isConditionsLoading || isConditionsError)),
    [
      hasConditions,
      hasMinutes,
      isDecisionStageLoading,
      isDecisionStageError,
      isMinutesLoading,
      isMinutesError,
      isConditionsLoading,
      isConditionsError,
    ],
  )

  const isDecisionPaperTilesLoadingOrError = useMemo(
    () =>
      hasDecisionPaper &&
      (isDecisionPaperLoading ||
        isDecisionPaperError ||
        noTilesAvailable ||
        anyTileLoading ||
        anyTileHasError ||
        someTilesAreLoading),
    [
      hasDecisionPaper,
      isDecisionPaperLoading,
      isDecisionPaperError,
      noTilesAvailable,
      anyTileLoading,
      anyTileHasError,
      someTilesAreLoading,
    ],
  )

  useEffect(() => {
    if (isDecisionStageLoadingOrError || isDecisionPaperTilesLoadingOrError) {
      return
    }
    if (!hasDecisionPaper) {
      setIsPrintButtonEnabled(true)
      return
    }
    const timeoutRef = setTimeout(() => {
      setIsPrintButtonEnabled(true)
    }, printingTimeout)
    return () => clearTimeout(timeoutRef)
  }, [isDecisionStageLoadingOrError, isDecisionPaperTilesLoadingOrError]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderConditions = useCallback(() => {
    const {
      info: { decisionDate, decisionStatus },
      decisionStageVoterConditions,
    } = decisionStageConditionsData
    return (
      <ObjectPageSection hideTitleText={true}>
        <ObjectPageSubSection titleText={t('conditions.section-title')}>
          <ConditionsInformationCard decisionStatus={decisionStatus} decisionDate={decisionDate} />
          <Card>
            <DecisionStageConditionsVotersTable
              decisionStageId={decisionStageId}
              decisionStatus={decisionStatus}
              isPdfView={true}
            />
          </Card>
          <DecisionStageConditionsTable
            eventId={eventId}
            decisionStageId={decisionStageId}
            decisionStageVoterConditions={decisionStageVoterConditions}
            decisionStatus={decisionStatus}
            showEditButtons={false}
            showMoreButtons={false}
            isPdfView={true}
          />
        </ObjectPageSubSection>
      </ObjectPageSection>
    )
  }, [decisionStageConditionsData, decisionStageId, eventId, t])

  const renderMinutes = useCallback(() => {
    const {
      info: { decisionStatus, decisionDate, minutes, lastUpdatedAt, lastUpdatedBy },
    } = decisionStageMinutes
    return (
      <ObjectPageSection hideTitleText={true}>
        <ObjectPageSubSection titleText={t('minutes.section-title')}>
          <DecisionStageMinutesInformationCard
            decisionStatus={decisionStatus}
            decisionDate={decisionDate}
          />
          <Card>
            <DecisionStageMinutesVotersTable
              decisionStageId={decisionStageId}
              decisionStatus={decisionStatus}
              isPdfView={true}
            />
          </Card>
          <DecisionStageMinutesCard
            decisionStatus={decisionStatus}
            decisionStageId={decisionStageId}
            minutes={minutes}
            lastUpdatedAt={lastUpdatedAt}
            lastUpdatedBy={lastUpdatedBy}
            isPdfView={true}
          />
        </ObjectPageSubSection>
      </ObjectPageSection>
    )
  }, [decisionStageId, decisionStageMinutes, t])

  const renderDecisionStage = useCallback(() => {
    const {
      info: { decisionStatus: decisionStageStatus },
    } = decisionStageData

    return (
      <>
        <ObjectPageSection hideTitleText={true}>
          <ObjectPageSubSection titleText={t('section-title')}>
            <DecisionStageInformationCard decisionStage={decisionStageData} isPdfView={true} />
            <Card className={styles.stageVotersTable}>
              <DecisionStageVotersTableLoadingWrapper
                decisionStageId={decisionStageId}
                decisionStageStatus={decisionStageStatus}
                isPdfView={true}
              />
            </Card>
          </ObjectPageSubSection>
        </ObjectPageSection>
        {pageBreak}
        {hasConditions && (
          <>
            <RequestStateResolver
              isLoading={isConditionsLoading}
              isError={isConditionsError}
              renderContent={renderConditions}
              errorToDisplay={
                <ErrorDataUnavailableInContent
                  titleText={t('conditions.loading.error.title')}
                  subtitleText={t('conditions.loading.error.description')}
                />
              }
            />
            {pageBreak}
          </>
        )}
        {hasMinutes && (
          <>
            <RequestStateResolver
              isLoading={isMinutesLoading}
              isError={isMinutesError}
              renderContent={renderMinutes}
              errorToDisplay={
                <ErrorDataUnavailableInContent
                  titleText={t('minutes.loading.error.title')}
                  subtitleText={t('minutes.loading.error.description')}
                />
              }
            />
            {pageBreak}
          </>
        )}
      </>
    )
  }, [
    decisionStageData,
    decisionStageId,
    hasConditions,
    hasMinutes,
    isConditionsError,
    isConditionsLoading,
    isMinutesError,
    isMinutesLoading,
    renderConditions,
    renderMinutes,
    t,
  ])

  const renderDecisionPaper = useCallback(
    () => (
      <DecisionPaperPdfExportPermissionWrapper>
        <DecisionPaperPdfExportHeader
          isFrozen={
            decisionPaper?.synchronizationStatus === decisionPaperSynchronizationStatus.frozen
          }
          version={decisionPaper?.currentVersion}
          frozenDate={decisionPaper?.frozenDate}
          showPrintButton={false}
        />
        <DecisionPaperPdfExportContent decisionPaper={decisionPaper} />
      </DecisionPaperPdfExportPermissionWrapper>
    ),
    [decisionPaper],
  )

  const renderContent = useCallback(
    () => (
      <>
        <DecisionStagePdfExportHeader
          decisionStage={decisionStageData}
          isPrintButtonEnabled={isPrintButtonEnabled}
        />
        <div className={styles.printPageWrapper}>
          {renderDecisionStage()}
          {hasDecisionPaper && (
            <>
              <DecisionPaperReduxStateLoaderWrapper decisionPaper={decisionPaper} />
              <RequestStateResolver
                isLoading={isDecisionPaperLoading || anyTileLoading}
                isError={isDecisionPaperError || anyTileHasError}
                renderContent={renderDecisionPaper}
                errorToDisplay={
                  <ErrorDataUnavailableInContent
                    titleText={tDecisionPaper('loading.error.title')}
                    subtitleText={tDecisionPaper('loading.error.description')}
                  />
                }
              />
            </>
          )}
        </div>
      </>
    ),
    [
      anyTileHasError,
      anyTileLoading,
      decisionPaper,
      decisionStageData,
      hasDecisionPaper,
      isDecisionPaperError,
      isDecisionPaperLoading,
      isPrintButtonEnabled,
      renderDecisionStage,
      renderDecisionPaper,
      tDecisionPaper,
    ],
  )

  return (
    <EventProvider eventId={eventId}>
      <RequestStateResolver
        isLoading={isDecisionStageLoading}
        isError={isDecisionStageError}
        renderContent={renderContent}
        errorToDisplay={
          <ErrorDataUnavailableInContent
            titleText={t('loading.error.title')}
            subtitleText={t('loading.error.description')}
          />
        }
      />
    </EventProvider>
  )
}

export default DecisionStagePdfExport
