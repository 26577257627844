import { ButtonDesign, Menu, MenuItem } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  valuationRequestCreationError,
  valuationStatus,
} from 'api/property/valuation/valuationRequests'
import styles from 'components/domains/properties/valuation/ValuationRequestCreationDialog.module.css'
import useShowVaulationRequestDialogError from 'components/domains/properties/valuation/helper/useCreateShowValuationRequestDialogError'
import ValuationRequestCreationPanel, {
  propertiesShape,
} from 'components/domains/properties/valuation/panels/ValuationRequestCreationPanel'
import LoadingButton from 'components/ui/button/LoadingButton'
import Dialog, { DialogSecondaryButton } from 'components/ui/dialog/Dialog'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import { usePropertiesValuationCreationBulk } from 'hooks/services/properties/valuations/creation/usePropertiesValuationCreationBulk'
import { resetRequestCreation, setSubmitClicked } from 'redux/slices/valuation/requestCreationSlice'
import { highlightRequestRows } from 'redux/slices/valuation/valuationRequestsTableSlice'

const createButtonOptions = {
  create: 'create',
  draft: 'draft',
}

const submitButtonId = 'request-creation-submit-button'

const ValuationRequestCreationDialog = ({ properties, isOpen, setIsOpen, showCreateButton }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.valuation-requests.create-dialog',
  })
  const { t: tNoPrefix } = useTranslation()
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false)
  const dispatch = useDispatch()
  const showMessageBox = useShowMessageBox()
  const client = useQueryClient()
  const { requestReason, requestType, requestNote, requestDueDate } = useSelector(
    (state) => state.request.requestCreation,
  )
  const [createBusinessEvent, setCreateBusinessEvent] = useState(true)
  const showCreateVaulationRequestMessageError = useShowVaulationRequestDialogError()

  const onSaveSuccess = useCallback(
    ({ valuationRequestsResponses }) => {
      dispatch(resetRequestCreation())
      const valuationRequestIds = valuationRequestsResponses
        .map(({ valuationRequest }) => valuationRequest?.id)
        .filter((valuationRequestId) => !!valuationRequestId)
      dispatch(highlightRequestRows({ valuationRequestIds }))
      setIsOpen(false)
      if (properties.length === 1) {
        client.invalidateQueries(['properties', properties[0].propertyUuid, 'valuation-requests'])
      } else {
        client.invalidateQueries([
          'properties',
          'valuation-requests',
          ...properties.map(({ propertyUuid }) => propertyUuid),
        ])
      }
      showCreateVaulationRequestMessageError(
        valuationRequestsResponses,
        properties,
        valuationRequestCreationError.eventCreationFailedError,
      )
    },
    [client, dispatch, properties, setIsOpen, showCreateVaulationRequestMessageError],
  )

  const onSaveError = useCallback(() => {
    setIsOpen(false)

    showMessageBox({
      type: MessageBoxTypes.Error,
      emphasizedAction: MessageBoxActions.Close,
      actions: [MessageBoxActions.Close],
      children: t('post-error.description'),
    })
  }, [setIsOpen, showMessageBox, t])

  const { mutate: createRequestMutation, isLoading } = usePropertiesValuationCreationBulk({
    onSuccess: onSaveSuccess,
    onError: onSaveError,
  })

  const closeSaveDialog = useCallback(() => setIsSaveDialogOpen(false), [])

  const handleClose = useCallback(() => {
    setCreateBusinessEvent(true)
    setIsOpen(false)
  }, [setIsOpen])

  const handleCreation = useCallback(
    (status) => {
      dispatch(setSubmitClicked(true))

      if (requestReason && requestType) {
        createRequestMutation({
          propertyUuids: properties.map((property) => property.propertyUuid),
          valuation: {
            reason: requestReason,
            type: requestType,
            status,
            note: requestNote,
            dueDate: requestDueDate,
          },
          createBusinessEvent,
        })
      }
    },
    [
      createBusinessEvent,
      createRequestMutation,
      dispatch,
      properties,
      requestDueDate,
      requestNote,
      requestReason,
      requestType,
    ],
  )

  const handleCreationDraft = useCallback(() => {
    handleCreation(valuationStatus.draft)
  }, [handleCreation])

  const handleCreationCreated = useCallback(() => {
    handleCreation(valuationStatus.created)
  }, [handleCreation])

  const handleOnSubmitClick = useCallback(() => {
    setIsSaveDialogOpen(!isSaveDialogOpen)
  }, [isSaveDialogOpen])

  const handleOnCheckboxClick = useCallback((isCreateBusinessEvent) => {
    setCreateBusinessEvent(isCreateBusinessEvent)
  }, [])

  const handleOnMenuItemClick = useCallback(
    ({ detail: { item: pressedMenuItem } }) => {
      setIsSaveDialogOpen(false)
      const pressedIdentifier = pressedMenuItem.getAttribute('data-button-id')
      switch (pressedIdentifier) {
        case createButtonOptions.draft:
          handleCreationDraft()
          break
        case createButtonOptions.create:
          handleCreationCreated()
          break
      }
    },
    [handleCreationDraft, handleCreationCreated],
  )

  const menuComponent = useMemo(
    () => (
      <Menu
        opener={submitButtonId}
        open={isSaveDialogOpen}
        onAfterClose={closeSaveDialog}
        onItemClick={handleOnMenuItemClick}
      >
        {
          <MenuItem
            text={t('submit-draft-button.title')}
            data-button-id={createButtonOptions.draft}
            disabled={isLoading}
          />
        }
        {showCreateButton && (
          <MenuItem
            text={t('submit-create-button.title')}
            data-button-id={createButtonOptions.create}
            disabled={isLoading}
          />
        )}
      </Menu>
    ),
    [isSaveDialogOpen, closeSaveDialog, handleOnMenuItemClick, t, showCreateButton, isLoading],
  )

  const onBeforeClose = useCallback(
    (event) => {
      if (isLoading) {
        event.preventDefault()
      }
    },
    [isLoading],
  )

  const onCancel = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  return (
    <>
      <Dialog
        id="request-creation-dialog"
        open={isOpen}
        headerText={t('request-popup.title')}
        onAfterClose={handleClose}
        onBeforeClose={onBeforeClose}
        closeButton={
          <DialogSecondaryButton onClick={onCancel} disabled={isLoading}>
            {tNoPrefix('buttons.cancel')}
          </DialogSecondaryButton>
        }
        secondaryButton={
          <LoadingButton
            id={submitButtonId}
            isLoading={isLoading}
            onClick={handleOnSubmitClick}
            design={ButtonDesign.Emphasized}
            renderContent={() => t('submit-button.title')}
            disabled={requestReason === '' || requestType === '' || isLoading}
          />
        }
      >
        <div {...(isLoading && { inert: '' })} className={isLoading ? styles.disabled : ''}>
          <ValuationRequestCreationPanel
            properties={properties}
            isBusinessEventChecked={createBusinessEvent}
            onBusinessEventCheckboxClick={handleOnCheckboxClick}
          />
        </div>
      </Dialog>
      {menuComponent}
    </>
  )
}

ValuationRequestCreationDialog.propTypes = {
  properties: propertiesShape.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  showCreateDraftButton: PropTypes.bool,
  showCreateButton: PropTypes.bool,
}

export default ValuationRequestCreationDialog
