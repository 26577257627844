import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useBusinessPartnerRatings from 'hooks/services/business-partners/ratings/useBusinessPartnerRatings'

const useMonitoredRiskIndicatorCheckOverviewPdRating = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: ratingsData,
    isLoading: isLoadingRatingsData,
    isError: isErrorRatingsData,
    error: errorRatingsData,
  } = useBusinessPartnerRatings(businessPartnerId)

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isLoadingRatingsData],
    errorValues: [isErrorRatingsData],
    errorDetails: [errorRatingsData],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        sourceRender: { businessPartnerId },
        ratings: ratingsData ?? {},
      },
    }
  }, [isSomeValueError, isSomeValueLoading, error, ratingsData, businessPartnerId])
}

export default useMonitoredRiskIndicatorCheckOverviewPdRating
