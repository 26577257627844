import { isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const RETRIES = 3

const useCustomAssessmentVersions = ({ entityId, entityType, assessmentCode }, options = {}) => {
  const queryParams = new URLSearchParams()
  const keys = ['central-data', 'assessments', entityType, entityId, assessmentCode]
  queryParams.append('entity_id', entityId)
  queryParams.append('entity_type', entityType)
  queryParams.append('code', assessmentCode)

  return useCamelizedResponse(
    useRequest({
      path: `/central-data/assessments?${queryParams}`,
      useCache: true,
      keys,
      options: {
        retry: (failureCount, error) => !(isNotFoundError(error) || failureCount >= RETRIES),
        ...options,
      },
    }),
  )
}

export default useCustomAssessmentVersions
