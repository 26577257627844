import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const emptyArray = []

const useBusinessPartnerMiniByIds = (businessPartnerIds = emptyArray, options) => {
  const { post } = useAccessTokenRequest()
  const queryFn = useCallback(async () => {
    const { data } = await post({
      path: '/businesspartners/minis',
      body: {
        ids: businessPartnerIds,
      },
    })
    return data
  }, [businessPartnerIds, post])

  return useCamelizedResponse(
    useQuery({
      queryKey: ['businesspartners', 'minis', ...businessPartnerIds],
      queryFn,
      retry: false,
      enabled: businessPartnerIds.length > 0,
      ...options,
    }),
  )
}

export default useBusinessPartnerMiniByIds
