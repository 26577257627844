import { MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DocumentSelectFilterBar from 'components/domains/documents/DocumentSelectFilterBar'
import DocumentSelectTable from 'components/domains/documents/DocumentSelectTable'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import useFilteredDocuments from 'components/domains/documents/useFilteredDocuments'
import Dialog from 'components/ui/dialog/Dialog'
import CloseableMessageStrip from 'components/ui/feedback/CloseableMessageStrip'
import useDocumentsFlat from 'hooks/services/documents/useDocumentsFlat'

/** @typedef {import('components/domains/documents/useFilteredDocuments').Filters} Filters */

const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    /** Use `DocumentTypes` */
    type: PropTypes.oneOf(Object.values(DocumentTypes)).isRequired,
  }).isRequired,
}
/** @param {Omit<PropTypes.InferProps<typeof propTypes>, "onClose" | "onChange"> & {onClose?: () => void, onChange?: (documentId?: string) => void}} props */
const DocumentSelectDialog = ({ open, onClose = () => {}, onChange = () => {}, entity }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'components.documents.select-dialog' })

  const {
    data: { documents },
    isLoading: isDocumentsLoading,
    isError: isDocumentsError,
  } = useDocumentsFlat({ entityId: entity.id, entityType: entity.type })

  const [filters, setFilters] = useState(/** @type {Filters} */ ({}))

  const {
    isLoading: isFilterLoading,
    isError: isFilterError,
    documents: filteredDocuments,
  } = useFilteredDocuments({ filters, documents })

  /**
   * @param {string} [newDocumentId]
   */
  const handleSelectionChange = (newDocumentId) => {
    onChange(newDocumentId)
    onClose()
  }

  /** @param {Filters} newFilters */
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters)
  }

  const isLoading = isDocumentsLoading || isFilterLoading
  const isError = isDocumentsError || isFilterError

  return (
    <Dialog open={open} onAfterClose={onClose} headerText={t('title')} size="L">
      <DocumentSelectFilterBar entity={entity} onChange={handleFilterChange} />
      {isFilterError && (
        <CloseableMessageStrip design={MessageStripDesign.Warning}>
          {t('filter-error')}
        </CloseableMessageStrip>
      )}
      <DocumentSelectTable
        isError={isError}
        isLoading={isLoading}
        documents={filteredDocuments}
        onChange={handleSelectionChange}
        entity={entity}
      />
    </Dialog>
  )
}

DocumentSelectDialog.propTypes = propTypes

export default DocumentSelectDialog
