import {
  Button,
  ButtonDesign,
  Modals,
  ResponsiveGridLayout,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  conditionTypes,
  conditionsMassEditOperations,
  conditionsStatusTypes,
  conditionsEntityTypes,
} from 'api/conditions/conditions'
import ConditionsMassEditApprovalLevelSelect from 'components/domains/conditions/dialogs/mass-editing/ConditionsMassEditApprovalLevelSelect'
import ConditionsMassEditAssigneeSelect from 'components/domains/conditions/dialogs/mass-editing/ConditionsMassEditAssigneeSelect'
import ConditionsMassEditCategorySelect from 'components/domains/conditions/dialogs/mass-editing/ConditionsMassEditCategorySelect'
import ConditionsMassEditCovenantCheckSelect from 'components/domains/conditions/dialogs/mass-editing/ConditionsMassEditCovenantCheckSelect'
import ConditionsMassEditDealExternalAssigneeSelect from 'components/domains/conditions/dialogs/mass-editing/ConditionsMassEditDealExternalAssigneeSelect'
import ConditionsMassEditExternalStatusSelect from 'components/domains/conditions/dialogs/mass-editing/ConditionsMassEditExternalStatusSelect'
import ConditionsMassEditExternalTypeSelect from 'components/domains/conditions/dialogs/mass-editing/ConditionsMassEditExternalTypeSelect'
import ConditionsMassEditInternalStatusSelect from 'components/domains/conditions/dialogs/mass-editing/ConditionsMassEditInternalStatusSelect'
import ConditionsMassEditInternalTypeSelect from 'components/domains/conditions/dialogs/mass-editing/ConditionsMassEditInternalTypeSelect'
import ConditionsMassEditRefSelect from 'components/domains/conditions/dialogs/mass-editing/ConditionsMassEditRefSelect'
import ConditionsMassEditReferencesSelect from 'components/domains/conditions/dialogs/mass-editing/ConditionsMassEditReferencesSelect'
import ConditionsMassEditVisibilitySelect from 'components/domains/conditions/dialogs/mass-editing/ConditionsMassEditVisibilitySelect'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import Dialog, { DialogPrimaryButton, DialogSecondaryButton } from 'components/ui/dialog/Dialog'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import useGetMultipleRequirements from 'hooks/services/conditions/requirements/useGetMultipleRequirements'
import useMassUpdateExternalConditions from 'hooks/services/conditions/useMassUpdateExternalConditions'
import useMassUpdateInternalConditions from 'hooks/services/conditions/useMassUpdateInternalConditions'
import useGetDocumentTypes from 'hooks/services/documents/useGetDocumentTypes'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const ConditionsMassEditDialog = ({
  conditionType,
  selectedConditionIds,
  open,
  setIsOpen,
  allReferencesEntityType,
}) => {
  const { t: tNoPrefix } = useTranslation('translation')
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.dialogs.mass-edit',
  })
  const { t: tInvalidDocumentTypes } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.update.invalid-document-type-dialog',
  })
  const {
    entityRef: { entityType, entityId },
  } = useContext(ConditionsContext)
  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()

  const [showVisibilitiesWarning, setShowVisibilitiesWarning] = useState(false)
  const [showStatusWarning, setShowStatusWarning] = useState(false)

  const { mutate: updateInternalConditions, isLoading: isUpdateInternalConditionsLoading } =
    useMassUpdateInternalConditions()
  const { mutate: updateExternalConditions, isLoading: isUpdateExternalConditionsLoading } =
    useMassUpdateExternalConditions()

  const isLoading = isUpdateInternalConditionsLoading || isUpdateExternalConditionsLoading
  const [fieldsToChange, setFieldsToChange] = useState({})

  const handleSaveSuccess = useCallback(
    (shouldThrowDocumentTypeInvalidError) => () => {
      if (shouldThrowDocumentTypeInvalidError) {
        showMessageBox({
          type: MessageBoxTypes.Error,
          children: tInvalidDocumentTypes('message'),
          actions: [MessageBoxActions.Close],
        })
      }
      setIsOpen(false)
      queryClient.invalidateQueries(['conditions', conditionType, entityType, entityId])
      selectedConditionIds.forEach((conditionId) => {
        queryClient.invalidateQueries(['conditions', 'external', conditionId, 'requirements'])
      })
      showToast({ children: t('save.success') })
    },
    [
      setIsOpen,
      queryClient,
      conditionType,
      entityType,
      entityId,
      showToast,
      t,
      showMessageBox,
      tInvalidDocumentTypes,
    ],
  )

  const handleSaveError = useCallback(
    ({ retryAction }) => {
      showMessageBox({
        type: MessageBoxTypes.Error,
        titleText: t('save.error.title'),
        children: t('save.error.description'),
        actions: [
          <Button
            key="save-error-retry-button"
            data-action={MessageBoxActions.Retry}
            design={ButtonDesign.Emphasized}
          >
            {tNoPrefix('buttons.try-again')}
          </Button>,
          <Button
            key="save-error-cancel-button"
            data-action={MessageBoxActions.Cancel}
            design={ButtonDesign.Transparent}
          >
            {tNoPrefix('buttons.cancel')}
          </Button>,
        ],
        onClose: ({ detail: { action } }) => {
          if (action !== MessageBoxActions.Retry) {
            return
          }
          retryAction()
        },
      })
    },
    [showMessageBox, t, tNoPrefix],
  )

  const handleCancelClicked = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const getChangedValue = useCallback((changeItem) => {
    if (isNil(changeItem)) {
      return undefined
    }
    const { operation, value } = changeItem
    switch (operation) {
      case conditionsMassEditOperations.clear:
        return null
      case conditionsMassEditOperations.replace:
        return value
      default:
        return undefined
    }
  }, [])

  const { data: requirements } = useGetMultipleRequirements({
    conditionIds: selectedConditionIds,
  })

  const latestDocumentTypes = useMemo(
    () =>
      requirements?.map((requirement) => requirement?.data?.requirements?.[0]?.info?.documentType),
    [requirements],
  )

  const changedReferences = getChangedValue(fieldsToChange.references)

  const noId = 'no-id'

  const documentTypesEntityRefsForAfterEditMode = useMemo(() => {
    switch (entityType) {
      case DocumentTypes.BusinessPartner:
        return [
          { type: DocumentTypes.Requirement, id: noId },
          { type: entityType, id: entityId },
        ]

      case DocumentTypes.Deal:
        if (changedReferences?.entityIds && changedReferences?.entityIds.length > 0) {
          return [
            { type: DocumentTypes.Requirement, id: noId },
            ...changedReferences.entityIds.map((id) => ({
              type: changedReferences.entityType,
              id,
            })),
          ]
        }

        return [
          { type: DocumentTypes.Requirement, id: noId },
          { type: entityType, id: entityId },
        ]
      default:
        break
    }
  }, [entityType, entityId, changedReferences])

  const { data: { documentTypes } = {} } = useGetDocumentTypes({
    entityRefs: documentTypesEntityRefsForAfterEditMode,
  })

  const checkIfDocumentTypeExistsInAllValidTypes = useCallback(
    ({ selectedDocumentType, references }) => {
      if (
        isNil(changedReferences) &&
        references?.operation !== conditionsMassEditOperations.clear
      ) {
        return true
      }
      return selectedDocumentType.every((type) => documentTypes.includes(type))
    },
    [documentTypes, changedReferences],
  )

  const saveExternalConditionChanges = useCallback(() => {
    const statusValue = getChangedValue(fieldsToChange.status)
    const shouldThrowDocumentTypeInvalidError = !checkIfDocumentTypeExistsInAllValidTypes({
      selectedDocumentType: latestDocumentTypes,
      references: fieldsToChange.references,
    })

    updateExternalConditions(
      {
        conditionIds: selectedConditionIds,
        editedFields: {
          category: getChangedValue(fieldsToChange.category),
          typeCode: getChangedValue(fieldsToChange.type),
          refNumber: getChangedValue(fieldsToChange.ref),
          references: getChangedValue(fieldsToChange.references),
          covenantCheck: getChangedValue(fieldsToChange.covenantCheck),
          visibilityCodes: getChangedValue(fieldsToChange.visibility),
          assignee: getChangedValue(fieldsToChange.assignee),
          externalAssignee: getChangedValue(fieldsToChange.externalAssignee),
          statusCode: isNil(statusValue) ? statusValue : statusValue.code,
        },
      },
      {
        onSuccess: handleSaveSuccess(shouldThrowDocumentTypeInvalidError),
        onError: () => {
          handleSaveError({ retryAction: saveExternalConditionChanges })
        },
      },
    )
  }, [
    updateExternalConditions,
    selectedConditionIds,
    getChangedValue,
    fieldsToChange,
    handleSaveSuccess,
    handleSaveError,
    latestDocumentTypes,
    checkIfDocumentTypeExistsInAllValidTypes,
  ])

  const saveInternalConditionChanges = useCallback(() => {
    const statusValue = getChangedValue(fieldsToChange.status)
    updateInternalConditions(
      {
        conditionIds: selectedConditionIds,
        editedFields: {
          category: getChangedValue(fieldsToChange.category),
          typeCode: getChangedValue(fieldsToChange.type),
          refNumber: getChangedValue(fieldsToChange.ref),
          approvalLevelCode: getChangedValue(fieldsToChange.approvalLevel),
          assignee: getChangedValue(fieldsToChange.assignee),
          statusCode: statusValue?.code,
        },
      },
      {
        onSuccess: handleSaveSuccess(),
        onError: () => {
          handleSaveError({ retryAction: saveInternalConditionChanges })
        },
      },
    )
  }, [
    updateInternalConditions,
    selectedConditionIds,
    getChangedValue,
    fieldsToChange,
    handleSaveSuccess,
    handleSaveError,
  ])

  const save = useCallback(() => {
    if (conditionType === conditionTypes.external) {
      saveExternalConditionChanges()
      return
    }
    saveInternalConditionChanges()
  }, [conditionType, saveExternalConditionChanges, saveInternalConditionChanges])

  const openStatusWarning = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Confirm,
      titleText: tNoPrefix('buttons.save'),
      children: t('status.warning.description'),
      actions: [
        <Button
          key="status-warning-confirm-button"
          data-action={MessageBoxActions.Yes}
          design={ButtonDesign.Emphasized}
        >
          {tNoPrefix('buttons.save')}
        </Button>,
        <Button
          key="status-warning-cancel-button"
          data-action={MessageBoxActions.Cancel}
          design={ButtonDesign.Transparent}
        >
          {tNoPrefix('buttons.cancel')}
        </Button>,
      ],
      emphasizedAction: MessageBoxActions.Retry,
      onClose: ({ detail: { action } }) => {
        if (action !== MessageBoxActions.Yes) {
          return
        }
        save()
      },
    })
  }, [showMessageBox, save, t, tNoPrefix])

  const openVisibilitiesWarning = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Confirm,
      titleText: tNoPrefix('buttons.edit'),
      children: t('visibilities.warning.description'),
      actions: [
        <Button
          key="visibilities-warning-confirm-button"
          data-action={MessageBoxActions.Yes}
          design={ButtonDesign.Emphasized}
        >
          {tNoPrefix('buttons.edit')}
        </Button>,
        <Button
          key="visibilities-warning-cancel-button"
          data-action={MessageBoxActions.Cancel}
          design={ButtonDesign.Transparent}
        >
          {tNoPrefix('buttons.cancel')}
        </Button>,
      ],
      emphasizedAction: MessageBoxActions.Retry,
      onClose: ({ detail: { action } }) => {
        if (action !== MessageBoxActions.Yes) {
          return
        }
        if (showStatusWarning) {
          openStatusWarning()
          return
        }
        save()
      },
    })
  }, [showMessageBox, showStatusWarning, openStatusWarning, save, t, tNoPrefix])

  const handleSaveButtonClicked = useCallback(() => {
    if (showVisibilitiesWarning) {
      openVisibilitiesWarning()
      return
    }
    if (showStatusWarning) {
      openStatusWarning()
      return
    }
    save()
  }, [showVisibilitiesWarning, openVisibilitiesWarning, showStatusWarning, openStatusWarning, save])

  const handleCategorySelectionChanged = useCallback(
    (changeItem) => {
      setFieldsToChange({ ...fieldsToChange, category: changeItem })
    },
    [fieldsToChange, setFieldsToChange],
  )

  const handleTypeSelectionChanged = useCallback(
    (changeItem) => {
      setFieldsToChange({ ...fieldsToChange, type: changeItem })
    },
    [fieldsToChange, setFieldsToChange],
  )

  const handleRefSelectionChanged = useCallback(
    (changeItem) => {
      setFieldsToChange({ ...fieldsToChange, ref: changeItem })
    },
    [fieldsToChange, setFieldsToChange],
  )

  const handleApprovalLevelSelectionChanged = useCallback(
    (changeItem) => {
      setFieldsToChange({ ...fieldsToChange, approvalLevel: changeItem })
    },
    [fieldsToChange, setFieldsToChange],
  )

  const handleReferenceSelectionChanged = useCallback(
    (changeItem) => {
      setFieldsToChange({ ...fieldsToChange, references: changeItem })
    },
    [fieldsToChange, setFieldsToChange],
  )

  const handleCovenantCheckSelectionChanged = useCallback(
    (changeItem) => {
      setFieldsToChange({ ...fieldsToChange, covenantCheck: changeItem })
    },
    [fieldsToChange, setFieldsToChange],
  )

  const handleVisibilitySelectionChanged = useCallback(
    (changeItem) => {
      setShowVisibilitiesWarning(changeItem?.operation === conditionsMassEditOperations.replace)
      setFieldsToChange({ ...fieldsToChange, visibility: changeItem })
    },
    [fieldsToChange, setFieldsToChange],
  )

  const handleAssigneeSelectionChanged = useCallback(
    (changeItem) => {
      setFieldsToChange({ ...fieldsToChange, assignee: changeItem })
    },
    [fieldsToChange, setFieldsToChange],
  )

  const handleExternalAssigneeSelectionChanged = useCallback(
    (changeItem) => {
      setFieldsToChange({ ...fieldsToChange, externalAssignee: changeItem })
    },
    [fieldsToChange, setFieldsToChange],
  )

  const handleStatusSelectionChanged = useCallback(
    (changeItem) => {
      setShowStatusWarning(changeItem.value?.type === conditionsStatusTypes.done)
      setFieldsToChange({ ...fieldsToChange, status: changeItem })
    },
    [fieldsToChange, setFieldsToChange],
  )

  const typeSelect = useMemo(
    () =>
      conditionType === conditionTypes.external ? (
        <ConditionsMassEditExternalTypeSelect
          onChange={handleTypeSelectionChanged}
          disabled={isLoading}
        />
      ) : (
        <ConditionsMassEditInternalTypeSelect
          onChange={handleTypeSelectionChanged}
          disabled={isLoading}
        />
      ),
    [conditionType, handleTypeSelectionChanged, isLoading],
  )

  const statusSelect = useMemo(
    () =>
      conditionType === conditionTypes.external ? (
        <ConditionsMassEditExternalStatusSelect
          onChange={handleStatusSelectionChanged}
          disabled={isLoading}
        />
      ) : (
        <ConditionsMassEditInternalStatusSelect
          onChange={handleStatusSelectionChanged}
          disabled={isLoading}
        />
      ),
    [conditionType, handleStatusSelectionChanged, isLoading],
  )

  const onBeforeClose = useCallback(
    (event) => {
      if (isLoading) {
        event.preventDefault()
      }
    },
    [isLoading],
  )

  const onAfterClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  return (
    <Dialog
      open={open}
      headerText={t('header.text', { numberOfSelectedConditions: selectedConditionIds.length })}
      onBeforeClose={onBeforeClose}
      onAfterClose={onAfterClose}
      primaryButton={
        <DialogPrimaryButton isLoading={isLoading} onClick={handleSaveButtonClicked}>
          {tNoPrefix('buttons.save')}
        </DialogPrimaryButton>
      }
      closeButton={
        <DialogSecondaryButton onClick={handleCancelClicked} disabled={isLoading}>
          {tNoPrefix('buttons.close')}
        </DialogSecondaryButton>
      }
    >
      <ResponsiveGridLayout columnsXL={1} columnsL={1} columnsM={1} columnsS={1} rowGap="8px">
        <ConditionsMassEditCategorySelect
          onChange={handleCategorySelectionChanged}
          disabled={isLoading}
        />
        {typeSelect}
        <ConditionsMassEditRefSelect onChange={handleRefSelectionChanged} disabled={isLoading} />
        {conditionType === conditionTypes.internal && (
          <ConditionsMassEditApprovalLevelSelect
            onChange={handleApprovalLevelSelectionChanged}
            disabled={isLoading}
          />
        )}
        {conditionType === conditionTypes.external && (
          <>
            <ConditionsMassEditReferencesSelect
              onChange={handleReferenceSelectionChanged}
              disabled={isLoading}
              allReferencesEntityType={allReferencesEntityType}
            />
            <ConditionsMassEditCovenantCheckSelect
              onChange={handleCovenantCheckSelectionChanged}
              disabled={isLoading}
            />
            <ConditionsMassEditVisibilitySelect
              onChange={handleVisibilitySelectionChanged}
              disabled={isLoading}
            />
          </>
        )}
        <ConditionsMassEditAssigneeSelect
          onChange={handleAssigneeSelectionChanged}
          disabled={isLoading}
        />
        {conditionType === conditionTypes.external && entityType === conditionsEntityTypes.deal && (
          <ConditionsMassEditDealExternalAssigneeSelect
            onChange={handleExternalAssigneeSelectionChanged}
            disabled={isLoading}
          />
        )}
        {statusSelect}
      </ResponsiveGridLayout>
    </Dialog>
  )
}

ConditionsMassEditDialog.propTypes = {
  conditionType: PropTypes.oneOf(Object.values(conditionTypes)).isRequired,
  selectedConditionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  allReferencesEntityType: PropTypes.string,
}

export default ConditionsMassEditDialog
