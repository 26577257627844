import { useAuth } from 'oidc-react'
import Request from 'api/requests'

const useAccessTokenRequest = () => {
  const { userData } = useAuth()
  if (!userData) {
    // Todo: Explicit error / navigate to login page / reauthenticate?
    throw Error('Must be logged in')
  }
  const accessToken = userData.access_token
  return {
    /** @param {Omit<Parameters<typeof Request["get"]>[0], "accessToken">} requestOptions */
    get: (requestOptions) => Request.get({ accessToken, ...requestOptions }),
    /** @param {Omit<Parameters<typeof Request["post"]>[0], "accessToken">} requestOptions */
    post: (requestOptions) => Request.post({ accessToken, ...requestOptions }),
    /** @param {Omit<Parameters<typeof Request["postFileList"]>[0], "accessToken">} requestOptions */
    postFileList: (requestOptions) => Request.postFileList({ accessToken, ...requestOptions }),
    /** @param {Omit<Parameters<typeof Request["patch"]>[0], "accessToken">} requestOptions */
    patch: (requestOptions) => Request.patch({ accessToken, ...requestOptions }),
    /** @param {Omit<Parameters<typeof Request["put"]>[0], "accessToken">} requestOptions */
    put: (requestOptions) => Request.put({ accessToken, ...requestOptions }),
    /** @param {Omit<Parameters<typeof Request["delete"]>[0], "accessToken">} requestOptions */
    delete: (requestOptions) => Request.delete({ accessToken, ...requestOptions }),
  }
}

export { useAccessTokenRequest }
