import { MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionPaperSynchronizationStatus } from 'api/decision-paper/decisionPaperApi'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'
import { MessageBoxActions, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useFreezeDecisionPaperFlow from 'hooks/services/business-events-and-tasks/decision-papers/routes/useFreezeDecisionPaperFlow'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionPaperFreezeVersionDialog = ({
  isOpen,
  setIsOpen,
  setIsPopoverOpen,
  setIsFreezeLoading,
  synchronizationStatus,
  isMinorVersion,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper',
  })

  const showMessageBox = useShowMessageBox()
  const { id: eventId } = useContext(BusinessEventsAndTasksContext).event
  const isSyncOn = synchronizationStatus === decisionPaperSynchronizationStatus.live

  const onFreezeSuccess = useCallback(() => {
    setIsFreezeLoading(false)
    const successText = t(`freeze.success`)
    Modals.showToast({
      children: successText,
    })
  }, [setIsFreezeLoading, t])

  const onFreezeError = useCallback(() => {
    setIsFreezeLoading(false)
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('freeze.error'),
      actions: [MessageBoxActions.Close],
      emphasizedAction: MessageBoxActions.Close,
    })
  }, [setIsFreezeLoading, showMessageBox, t])

  const { handleFreezeClicked } = useFreezeDecisionPaperFlow({
    eventId,
    onFreezeSuccess,
    onFreezeError,
    isSyncOn,
    isMinorVersion,
  })

  const handleConfirmation = useCallback(() => {
    setIsFreezeLoading(true)
    handleFreezeClicked()
  }, [handleFreezeClicked, setIsFreezeLoading])

  const handleConfirmationCancel = useCallback(() => {
    setIsPopoverOpen(false)
    setIsOpen(false)
  }, [setIsOpen, setIsPopoverOpen])

  return (
    <>
      <ConfirmationDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleConfirmation={handleConfirmation}
        handleCancel={handleConfirmationCancel}
        confirmationMessage={t('confirmation-message.description')}
        confirmationTitle={t('confirmation-message.title')}
        confirmButtonText={t('buttons.confirm-freeze')}
        draggable
        resizable
      />
    </>
  )
}

DecisionPaperFreezeVersionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setIsPopoverOpen: PropTypes.func.isRequired,
  setIsFreezeLoading: PropTypes.func.isRequired,
  synchronizationStatus: PropTypes.string.isRequired,
  isMinorVersion: PropTypes.bool.isRequired,
}

export default DecisionPaperFreezeVersionDialog
