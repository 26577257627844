import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const RETRIES = 3

const useDecisionPaperSubsectionAssessment = ({ eventId, version, subsectionId }) =>
  useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/decision-paper/versions/${version}/subsections/${subsectionId}/assessment`,
      useCache: true,
      keys: [
        'events',
        eventId,
        'decision-paper',
        'versions',
        version,
        'subsections',
        subsectionId,
        'assessment',
      ],
      options: {
        retry: (failureCount, error) => {
          if (isNotFoundError(error) || isMissingPermissionError(error)) {
            return false
          }
          if (failureCount < RETRIES) {
            return true
          }
          return false
        },
      },
    }),
  )

export default useDecisionPaperSubsectionAssessment
