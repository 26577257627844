import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomizeDecisionPaperTemplateTable from 'components/domains/business-events-and-tasks/decision-paper/dialogs/customize-decision-paper/CustomizeDecisionPaperTemplateTable'
import {
  createCustomizedTemplate,
  getInitialRowSelection,
} from 'components/domains/business-events-and-tasks/decision-paper/dialogs/customize-decision-paper/customizeDecisionPaperTemplateHelper'
import DialogContent from 'components/domains/properties/monitoring/settings/dialog/DialogContent'
import Dialog, {
  DialogPrimaryButton,
  DialogSecondaryButton,
  DialogSize,
} from 'components/ui/dialog/Dialog'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'

/**
 * @typedef {import('components/domains/business-events-and-tasks/decision-paper/dialogs/customize-decision-paper/customizeDecisionPaperTemplateTableSelectionHelper').Template} Template
 */

/**
 * @typedef {object} Props
 * @property {string} title
 * @property {bool} isOpen
 * @property {(customizedTemplate: Record<string, boolean>) => void} onOk passes no parameter if no customization was made
 * @property {() => void} onCancel
 * @property {string} templateCode
 * @property {Template} existingDecisionPaperTemplate
 * @param {Props} props
 * @returns {import('react').ReactElement}
 */
const CustomizeDecisionPaperTemplateDialog = ({
  title,
  isOpen,
  onOk,
  onCancel,
  existingDecisionPaperTemplate,
  originalTemplateConfiguration,
  isLoading,
  isFetching,
  isError,
}) => {
  const { t: tCommon } = useTranslation()
  const [selectedRowIds, setSelectedRowIds] = useState({})

  const handleOkButtonClicked = useCallback(() => {
    const customizedTemplate = createCustomizedTemplate(
      originalTemplateConfiguration,
      selectedRowIds,
    )
    onOk(customizedTemplate)
  }, [onOk, selectedRowIds, originalTemplateConfiguration])

  const saveButton = useMemo(
    () => (
      <DialogPrimaryButton
        onClick={handleOkButtonClicked}
        disabled={Object.keys(selectedRowIds).length === 0}
      >
        {tCommon('buttons.save')}
      </DialogPrimaryButton>
    ),
    [handleOkButtonClicked, selectedRowIds, tCommon],
  )

  useEffect(() => {
    const initialRowSelection = getInitialRowSelection(
      originalTemplateConfiguration,
      existingDecisionPaperTemplate,
    )
    setSelectedRowIds(initialRowSelection)
  }, [existingDecisionPaperTemplate, originalTemplateConfiguration])

  const handleRowSelect = useCallback((newRowSelection) => {
    setSelectedRowIds(newRowSelection)
  }, [])

  const handleResetSelectionButtonClick = useCallback(() => {
    setSelectedRowIds(getInitialRowSelection(originalTemplateConfiguration))
  }, [originalTemplateConfiguration])

  const resetSelectionButton = useMemo(
    () => (
      <DialogSecondaryButton onClick={handleResetSelectionButtonClick}>
        {tCommon('buttons.reset')}
      </DialogSecondaryButton>
    ),
    [handleResetSelectionButtonClick, tCommon],
  )

  const renderCustomizationTable = useCallback(
    () => (
      <CustomizeDecisionPaperTemplateTable
        templateData={originalTemplateConfiguration}
        isFetching={isFetching}
        selectedRowIds={selectedRowIds}
        onRowSelect={handleRowSelect}
      />
    ),
    [originalTemplateConfiguration, isFetching, selectedRowIds, handleRowSelect],
  )

  return (
    <Dialog
      open={isOpen}
      size={DialogSize.L}
      headerText={title}
      rightHeaderContent={resetSelectionButton}
      primaryButton={saveButton}
      onBeforeClose={onCancel}
      draggable
      resizable
    >
      <DialogContent>
        <RequestStateResolver
          isLoading={isLoading}
          isError={isError}
          errorToDisplay={<ErrorDataUnavailableInContent />}
          renderContent={renderCustomizationTable}
        />
      </DialogContent>
    </Dialog>
  )
}

CustomizeDecisionPaperTemplateDialog.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  existingDecisionPaperTemplate: PropTypes.shape({
    code: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        subsections: PropTypes.arrayOf(
          PropTypes.shape({
            code: PropTypes.string.isRequired,
            tiles: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string.isRequired })),
          }),
        ),
        tiles: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string.isRequired })),
      }),
    ),
  }),
  originalTemplateConfiguration: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        subsections: PropTypes.arrayOf(
          PropTypes.shape({
            code: PropTypes.string.isRequired,
            tiles: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string.isRequired })),
          }),
        ),
        tiles: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string.isRequired })),
      }),
    ),
  }),
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
}

export default CustomizeDecisionPaperTemplateDialog
