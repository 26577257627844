import { IllustrationMessageSize, IllustrationMessageType } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import PropertyRentRollOverviewRentalIncomeOverTimeChart from 'components/domains/properties/rent-roll/overview/rental-income-over-time/PropertyRentRollOverviewRentalIncomeOverTimeChart'
import styles from 'components/domains/properties/rent-roll/overview/vacancy-over-time/PropertyRentRollOverviewVacancyOverTimeCardContent.module.css'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useHistoricalRentRollKpisByUsageType from 'hooks/services/properties/kpis/charts/useHistoricalRentRollKpisByUsageType'
import useHistoricalUsageTypeChartData from 'hooks/services/properties/kpis/charts/useHistoricalUsageTypeChartData'

const getAnnualizedCurrentRentForUsageType = (usageTypeKpi) =>
  usageTypeKpi?.annualizedCurrentRent?.number ?? 0

const getTotalPropertyVacancy = (propertyKpis) => ({
  marketRentInclVacancy: propertyKpis?.totalMarketRent?.number ?? 0,
  marketRentExclVacancy: propertyKpis?.letMarketRent?.number ?? 0,
})
const aggregateGroupedUsageTypeValues = (otherValues) =>
  otherValues?.reduce((acc, curr) => acc + curr, 0) ?? 0

const PropertyRentRollOverviewRentalIncomeOverTimeCardContent = () => {
  const { t: tRentalIncomeOverTimeChart } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.rental-income-over-time.chart',
  })

  const { data, isLoading, isError } = useHistoricalRentRollKpisByUsageType()
  const { chartData, usageTypes } = useHistoricalUsageTypeChartData(data, {
    getMetricValueForUsageTypeKpi: getAnnualizedCurrentRentForUsageType,
    getPropertyMetrics: getTotalPropertyVacancy,
    aggregateGroupedUsageTypeValues: aggregateGroupedUsageTypeValues,
  })
  const currency = data?.[0]?.kpis?.totalMarketRent?.currency
  const isEmpty = chartData.length === 0

  return (
    <div className={styles.chartContainer}>
      <LoadingStateWrapper
        isLoading={isLoading}
        isError={isError}
        errorTitle={tRentalIncomeOverTimeChart('error.title')}
        errorDescription={tRentalIncomeOverTimeChart('error.subtitle')}
        errorDetails=" "
        loadingDescription={tRentalIncomeOverTimeChart('loading')}
      >
        {isEmpty ? (
          <EmptyCardContent
            title={tRentalIncomeOverTimeChart('empty.title')}
            subtitle={tRentalIncomeOverTimeChart('empty.subtitle')}
            illustrationName={IllustrationMessageType.UnableToLoad}
            size={IllustrationMessageSize.Scene}
          />
        ) : (
          <PropertyRentRollOverviewRentalIncomeOverTimeChart
            data={chartData}
            usageTypes={usageTypes}
            currency={currency}
          />
        )}
      </LoadingStateWrapper>
    </div>
  )
}

export default PropertyRentRollOverviewRentalIncomeOverTimeCardContent
