import { Input, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'routes/deals/collaterals/creation/CollateralAgreementCreationDialog.module.css'
import CustomFormItem from 'routes/deals/collaterals/creation/CustomFormItem'

const maxNameLength = 30

// This component is implicitly tested by CollateralAgreementCreationDialog.unit.test.jsx
const CagNameFormItem = ({ cagName, setCagName, disabled }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.detail.collaterals' })
  return (
    <CustomFormItem className={styles.columnItem}>
      <Label showColon required for="cag-name-input">
        {t('fields.name')}
      </Label>
      <Input
        disabled={disabled}
        id="cag-name-input"
        value={cagName}
        maxlength={maxNameLength}
        onChange={(event) => setCagName(event.target.value)}
      />
    </CustomFormItem>
  )
}

CagNameFormItem.propTypes = {
  cagName: PropTypes.string.isRequired,
  setCagName: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default CagNameFormItem
