import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateDecisionStage = () => {
  const { post } = useAccessTokenRequest()

  return useMutation(({ eventId, stageTemplateId }) =>
    post({
      path: `/events/${eventId}/decision-stages`,
      body: {
        decision_stage_template_id: stageTemplateId,
      },
    }),
  )
}

export default useCreateDecisionStage
