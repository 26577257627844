const conditionReferencesChanged = (
  state,
  { payload: { conditionIndex, references: newReferences } },
) => {
  if (!state.conditions[conditionIndex]) {
    return
  }
  state.conditions[conditionIndex].references = newReferences
}

export default conditionReferencesChanged
