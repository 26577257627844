import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultipleCovenantsByBusinessPartnerId = ({ businessPartnerIds, options = {} }) => {
  const mainEntityType = mainEntityTypes.BUSINESSPARTNER

  const queryParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams()

    urlSearchParams.append('main_entity_type', mainEntityType)

    return urlSearchParams
  }, [mainEntityType])

  // HINT: `queryParams.size` seems to return `undefined` even if there are params
  const additionalParamsString = [...queryParams.keys()].length ? `&${queryParams.toString()}` : ''

  const responses = useRequestsMemo(
    useRequests({
      requests: businessPartnerIds.map((businessPartnerId) => ({
        path: `/covenants?main_entity_id=${businessPartnerId}${additionalParamsString}`,
        keys: ['covenants', mainEntityType, businessPartnerId],
      })),
      translated: true,
      useCache: true,
      options: { enabled: !isEmpty(businessPartnerIds), ...options },
    }),
  )

  return useMemo(
    () =>
      responses.map((response, index) => ({
        ...response,
        data: {
          businessPartnerId: businessPartnerIds[index],
          ...camelize(response.data),
        },
      })),
    [businessPartnerIds, responses],
  )
}

export default useMultipleCovenantsByBusinessPartnerId
