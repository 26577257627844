import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'

const PropertyValuationResultsDateInputCell = ({ date }) => {
  const { format: formatDate } = useShortDateFormatter()

  if (!date) {
    return null
  }

  return <Text>{formatDate(date)}</Text>
}

PropertyValuationResultsDateInputCell.propTypes = {
  date: PropTypes.string,
}

export default PropertyValuationResultsDateInputCell
