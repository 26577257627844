import { ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/common/comments/EventsAndTasksCommentMultipleDialog.module.css'
import LoadingButton from 'components/ui/button/LoadingButton'
import Dialog from 'components/ui/dialog/Dialog'
import CWPCKEditor from 'components/ui/rich-text-editor/CWPCKEditor'

const propTypes = {
  onSaveClicked: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  setIsDialogOpen: PropTypes.func.isRequired,
  commentValue: PropTypes.string,
  setCommentValue: PropTypes.func.isRequired,
  isSaveLoading: PropTypes.bool,
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const EventsAndTasksCommentMultipleDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  onSaveClicked,
  commentValue,
  setCommentValue,
  isSaveLoading,
}) => {
  const { t: tNoPrefix } = useTranslation('translation')
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.comment-multiple',
  })

  const handleAfterClose = useCallback(() => {
    setCommentValue('')
    setIsDialogOpen(false)
  }, [setCommentValue, setIsDialogOpen])
  const isSaveButtonDisabled = isEmpty(commentValue)
  return (
    <Dialog
      headerText={t('dialog.title')}
      className={styles.dialog}
      open={isDialogOpen}
      onAfterClose={handleAfterClose}
      primaryButton={
        <LoadingButton
          onClick={onSaveClicked}
          disabled={isSaveButtonDisabled}
          isLoading={isSaveLoading}
          design={ButtonDesign.Emphasized}
          renderContent={() => tNoPrefix('buttons.save')}
        />
      }
    >
      <div className={styles.textEditor}>
        <CWPCKEditor
          currentContent={commentValue}
          setCurrentContent={setCommentValue}
          placeholderText={t('dialog.placeholder')}
          maxCharacterAmount={1000}
          autofocus
        />
      </div>
    </Dialog>
  )
}

EventsAndTasksCommentMultipleDialog.propTypes = propTypes

export default EventsAndTasksCommentMultipleDialog
