import { Label, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessObjectLink from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import { noSelectionValueCode } from 'components/domains/business-events-and-tasks/decision-paper/DecisionPaperTemplateDropdown'
import DecisionPaperTemplateGroup from 'components/domains/business-events-and-tasks/decision-paper/DecisionPaperTemplateGroup'
import styles from 'components/domains/business-events-and-tasks/decision-paper/dialogs/SelectDecisionPaperTemplateDialog.module.css'
import DecisionPaperTable from 'components/domains/business-events-and-tasks/events/creation/DecisionPaperTable'
import DialogContent from 'components/domains/properties/monitoring/settings/dialog/DialogContent'
import DialogGroup from 'components/domains/properties/monitoring/settings/dialog/DialogGroup'
import Dialog, { DialogPrimaryButton } from 'components/ui/dialog/Dialog'
import LabeledSwitch from 'components/ui/input/LabeledSwitch'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

export const okButtonTypes = {
  edit: 'edit',
  create: 'create',
}

const SelectDecisionPaperTemplateDialog = ({
  title,
  isOpen,
  templateCode,
  okButtonType,
  existingDecisionPaperTemplate,
  onOk,
  onCancel,
}) => {
  const event = useContext(BusinessEventsAndTasksContext).event
  const { t: tCommon } = useTranslation()
  const [displayTable, setDisplayTable] = useState(false)
  const [selectedDecisionPaper, setSelectedDecisionPaper] = useState(undefined)
  const initialDropdownValue = templateCode || noSelectionValueCode
  const [selectedDecisionPaperObject, setSelectedDecisionPaperObject] = useState({
    templateCode: initialDropdownValue,
    customizedTemplateStructure: null,
    hasChangesToExistingTemplate: false,
    hasChangesToBaseTemplate: false,
  })
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.select-dialog',
  })

  useEffect(() => {
    if (templateCode) {
      setSelectedDecisionPaperObject(() => ({
        templateCode,
        customizedTemplateStructure: null,
        hasChangesToExistingTemplate: false,
        hasChangesToBaseTemplate: false,
      }))
    }
  }, [templateCode])

  const handleCancel = useCallback(() => {
    setSelectedDecisionPaperObject(() => ({
      templateCode: noSelectionValueCode,
      customizedTemplateStructure: null,
      hasChangesToExistingTemplate: false,
      hasChangesToBaseTemplate: false,
    }))
    onCancel()
  }, [onCancel])

  const isSaveButtonDisabled = useMemo(() => {
    const isNoTemplateSelected = selectedDecisionPaperObject?.templateCode === noSelectionValueCode
    return (
      isNoTemplateSelected ||
      (templateCode &&
        selectedDecisionPaperObject?.templateCode === templateCode &&
        !selectedDecisionPaperObject?.hasChangesToExistingTemplate)
    )
  }, [
    selectedDecisionPaperObject?.hasChangesToExistingTemplate,
    selectedDecisionPaperObject?.templateCode,
    templateCode,
  ])

  const handleSwitchClicked = useCallback(() => {
    setDisplayTable(!displayTable)
    setSelectedDecisionPaper(undefined)
  }, [setDisplayTable, displayTable])

  const onRadioChange = useCallback(
    (eventId) => () => {
      setSelectedDecisionPaper(eventId)
    },
    [],
  )

  const handleOnClick = useCallback(() => {
    const includeCustomizedTemplateStructure =
      selectedDecisionPaperObject?.hasChangesToBaseTemplate &&
      selectedDecisionPaperObject?.hasChangesToExistingTemplate

    onOk(
      selectedDecisionPaperObject?.templateCode,
      selectedDecisionPaper,
      includeCustomizedTemplateStructure
        ? selectedDecisionPaperObject?.customizedTemplateStructure
        : undefined,
    )
  }, [
    selectedDecisionPaperObject?.hasChangesToBaseTemplate,
    selectedDecisionPaperObject?.hasChangesToExistingTemplate,
    selectedDecisionPaperObject?.templateCode,
    selectedDecisionPaperObject?.customizedTemplateStructure,
    onOk,
    selectedDecisionPaper,
  ])

  const saveButton = useMemo(
    () => (
      <DialogPrimaryButton onClick={handleOnClick} disabled={isSaveButtonDisabled}>
        {okButtonType === okButtonTypes.create
          ? tCommon('buttons.create')
          : tCommon('buttons.save')}
      </DialogPrimaryButton>
    ),
    [handleOnClick, isSaveButtonDisabled, okButtonType, tCommon],
  )

  const handleTemplateChange = useCallback((templateChangeEvent) => {
    setSelectedDecisionPaperObject(templateChangeEvent)
    setDisplayTable(false)
  }, [])

  return (
    <>
      <Dialog
        open={isOpen}
        headerText={title}
        primaryButton={saveButton}
        onBeforeClose={handleCancel}
        className={styles.selectTemplateDialog}
        draggable
        resizable
      >
        <DialogContent>
          <DialogGroup>
            <Text>{t('subtitle')}</Text>
            <BusinessObjectLink
              entityId={event.entityRef.entityId}
              entityType={event.entityRef.entityType}
            />
          </DialogGroup>
          <DialogGroup>
            <div>
              <Label for="decision-paper-template-select" required>
                {t('template-selection.label')}
              </Label>
              <DecisionPaperTemplateGroup
                onChange={handleTemplateChange}
                templateCode={selectedDecisionPaperObject?.templateCode}
                existingDecisionPaperTemplate={existingDecisionPaperTemplate}
                eventCode={event.info.code}
                entityId={event.entityRef.entityId}
                entityType={event.entityRef.entityType}
              />
              {selectedDecisionPaperObject?.templateCode !== noSelectionValueCode && (
                <>
                  <Text className={styles.importSwitchLabel}>{t('import-data')}</Text>
                  <LabeledSwitch
                    onChange={handleSwitchClicked}
                    checked={displayTable}
                    checkedText={t('synchronization.on')}
                    uncheckedText={t('synchronization.off')}
                    isLoading={false}
                  />

                  {displayTable && (
                    <DecisionPaperTable
                      entityId={event.entityRef.entityId}
                      onChange={onRadioChange}
                      selectedDecisionPaper={selectedDecisionPaper}
                      setSelectedDecisionPaper={setSelectedDecisionPaper}
                      isEventCreation
                    />
                  )}
                </>
              )}
            </div>
          </DialogGroup>
        </DialogContent>
      </Dialog>
    </>
  )
}

SelectDecisionPaperTemplateDialog.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  templateCode: PropTypes.string,
  okButtonType: PropTypes.oneOf(Object.values(okButtonTypes)).isRequired,
  existingDecisionPaperTemplate: PropTypes.shape({
    code: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        subsections: PropTypes.arrayOf(
          PropTypes.shape({
            code: PropTypes.string.isRequired,
            tiles: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string.isRequired })),
          }),
        ),
        tiles: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string.isRequired })),
      }),
    ),
  }),
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default SelectDecisionPaperTemplateDialog
