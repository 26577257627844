import { useTranslation } from 'react-i18next'
import { CovenantPeriodValues } from 'components/domains/deals/covenants/limit-cards/model/CovenantPeriodValues'

const useFormatRelativeLimit = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.limit-card',
  })

  const formatRelativeLimit = (period, value) => {
    if (CovenantPeriodValues.Month === period) {
      return `${t('month')} ${value}`
    }
    if (CovenantPeriodValues.Year === period) {
      return `${t('year')} ${value}`
    }
    if (CovenantPeriodValues.Quarter === period) {
      return `${t('quarter')} ${value}`
    }
    return value
  }

  return { format: formatRelativeLimit }
}

export default useFormatRelativeLimit
