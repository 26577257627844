import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const usePublishDealAdjustmentWorkingVersion = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ dealUuid }) => {
      const response = await post({
        path: `/deals/${dealUuid}/working-version/publish`,
      })
      return { ...response, data: camelize(response?.data) }
    },
    { onSuccess, onError },
  )
}

export default usePublishDealAdjustmentWorkingVersion
