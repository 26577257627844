import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  decisionPaperSynchronizationStatus,
  decisionPaperVersion,
  isMajorVersion,
} from 'api/decision-paper/decisionPaperApi'
import DecisionPaperTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTile'
import DecisionPaperAutomaticTileWithMetaDataHeader from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/with-meta-data/DecisionPaperAutomaticTileWithMetaDataHeader'
import MissingPermissionErrorTileContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/generic/MissingPermissionErrorTileContent'
import useIsDecisionPaperTileExpanded from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useIsDecisionPaperTileExpanded'

const DecisionPaperAutomaticTileWithMetaDataRenderer = ({
  tileId,
  tileCode,
  titleText,
  viewConfig: { columnSpan, isDefaultExpanded },
  isPdfView,
  actions,
  children,
  currentVersion,
  synchronizationStatus,
  isEditableInMinorVersion,
  lastAvailableVersion,
}) => {
  const { isExpanded, collapse, expand } = useIsDecisionPaperTileExpanded({
    tileId,
    isPdfView,
    isDefaultExpanded,
  })
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const isAccessDeniedError = useMemo(
    () => !!tileStatus?.error?.isAccessDeniedError,
    [tileStatus?.error?.isAccessDeniedError],
  )

  const isTileHeaderEditEnabled = useMemo(() => {
    if (
      currentVersion === decisionPaperVersion.workingVersion &&
      synchronizationStatus === decisionPaperSynchronizationStatus.live &&
      !tileStatus?.isLoading &&
      !tileStatus?.isError
    ) {
      return isMajorVersion(lastAvailableVersion) || isEditableInMinorVersion
    }
    return false
  }, [
    currentVersion,
    isEditableInMinorVersion,
    lastAvailableVersion,
    synchronizationStatus,
    tileStatus?.isLoading,
    tileStatus?.isError,
  ])

  const header = tileStatus && (
    <DecisionPaperAutomaticTileWithMetaDataHeader
      tileId={tileId}
      tileCode={tileCode}
      titleText={titleText}
      isExpanded={isExpanded}
      isPdfView={isPdfView}
      isEditEnabled={isTileHeaderEditEnabled}
      actions={actions}
      onCollapse={collapse}
      onExpand={expand}
      currentVersion={currentVersion}
    />
  )

  return (
    <DecisionPaperTile columnSpan={columnSpan} header={header} isExpanded={isExpanded}>
      {isAccessDeniedError ? <MissingPermissionErrorTileContent /> : children}
    </DecisionPaperTile>
  )
}

DecisionPaperAutomaticTileWithMetaDataRenderer.propTypes = {
  tileId: PropTypes.string.isRequired,
  tileCode: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired,
  viewConfig: PropTypes.shape({
    columnSpan: PropTypes.number.isRequired,
    isDefaultExpanded: PropTypes.bool.isRequired,
  }).isRequired,
  isPdfView: PropTypes.bool.isRequired,
  actions: PropTypes.element,
  children: PropTypes.element,
  currentVersion: PropTypes.string.isRequired,
  synchronizationStatus: PropTypes.oneOf([
    decisionPaperSynchronizationStatus.live,
    decisionPaperSynchronizationStatus.frozen,
    decisionPaperSynchronizationStatus.stopped,
  ]),
  isEditableInMinorVersion: PropTypes.bool.isRequired,
  lastAvailableVersion: PropTypes.string.isRequired,
}

export default DecisionPaperAutomaticTileWithMetaDataRenderer
