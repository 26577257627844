import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  rentRollToCompare: {},
}

export const compareRentRollSlice = createSlice({
  name: 'properties/rent-roll/compare',
  initialState,
  reducers: {
    setRentRollToCompare: (state, { payload }) => {
      state.rentRollToCompare = payload
    },
    resetRentRollToCompare: () => initialState,
  },
})

export const { setRentRollToCompare, resetRentRollToCompare } = compareRentRollSlice.actions

export default compareRentRollSlice.reducer
