import { Label, List, ListMode, ListSeparators } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/events/dialog/EventCreationDialogEventTemplates.module.css'
import EventCreationDialogEventTemplatesListItem from 'components/domains/business-events-and-tasks/events/dialog/EventCreationDialogEventTemplatesListItem'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useEventTypes } from 'hooks/services/business-events-and-tasks/events/useEventTypes'

const EventCreationDialogEventTemplates = ({
  entityType,
  isEntityWorkingVersion,
  onEventTypeSelected,
  preSelectedEventType,
  disabled,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dialog.event-creation.event-template',
  })
  const [selectedEventTypeCode, setSelectedEventTypeCode] = useState(preSelectedEventType)
  const {
    isError,
    isLoading,
    data: eventTypes,
  } = useEventTypes({ entityType, isEntityWorkingVersion })
  const handleSelectionChange = ({ detail: { item } }) => {
    const eventTypeCode = item.getAttribute('data-event-code')
    setSelectedEventTypeCode(eventTypeCode)
    onEventTypeSelected(eventTypeCode)
  }

  const renderEventTypeRadios = () => {
    if (eventTypes.result.length === 0) {
      return <Label>{t('empty')}</Label>
    }

    return (
      <List
        className={disabled && styles.listDisabled}
        onItemClick={handleSelectionChange}
        mode={ListMode.SingleSelectBegin}
        separators={ListSeparators.Inner}
      >
        {eventTypes.result.map((eventType, index) => (
          <EventCreationDialogEventTemplatesListItem
            eventType={eventType}
            selectedEventTypeCode={selectedEventTypeCode}
            key={index}
          />
        ))}
      </List>
    )
  }

  return (
    <LoadingStateWrapper
      isError={isError}
      isLoading={isLoading}
      loadingDescription={t('loading')}
      renderContent={renderEventTypeRadios}
      errorTitle={t('error.title')}
      errorDescription={t('error.description')}
      errorDetails=" "
    />
  )
}

EventCreationDialogEventTemplates.propTypes = {
  entityType: PropTypes.string.isRequired,
  isEntityWorkingVersion: PropTypes.bool.isRequired,
  onEventTypeSelected: PropTypes.func.isRequired,
  preSelectedEventType: PropTypes.string,
  disabled: PropTypes.bool,
}

export default EventCreationDialogEventTemplates
