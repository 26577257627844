import PropTypes from 'prop-types'
import { useCallback } from 'react'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import WatcherButton from 'components/ui/watcher/WatcherButton'
import { useStaffMemberSelf } from 'hooks/services/business-partners/staff-members/useStaffMemberSelf'

const WatcherButtonLoadingWrapper = ({ id, watchers, onUserSubscribe, onUserUnsubscribe }) => {
  const { isLoading, isError, data: ownUser } = useStaffMemberSelf()

  const renderContent = useCallback(
    () => (
      <WatcherButton
        id={id}
        watchers={watchers}
        userId={ownUser.id}
        onUserSubscribe={onUserSubscribe}
        onUserUnsubscribe={onUserUnsubscribe}
      />
    ),
    [watchers, id, ownUser?.id, onUserSubscribe, onUserUnsubscribe],
  )

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      error=""
      renderContent={renderContent}
    />
  )
}

WatcherButtonLoadingWrapper.propTypes = {
  id: PropTypes.string,
  watchers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onUserSubscribe: PropTypes.func.isRequired,
  onUserUnsubscribe: PropTypes.func.isRequired,
}

export default WatcherButtonLoadingWrapper
