import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'

const useDataSourceQueryParams = (forceDataSource) => {
  const [searchParams] = useSearchParams()

  return useMemo(() => {
    const isExistingBusinessViewSet =
      searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS

    let dataSource
    if (forceDataSource) {
      dataSource = forceDataSource
    } else {
      dataSource = isExistingBusinessViewSet
        ? DATA_SOURCES.EXISTING_BUSINESS
        : DATA_SOURCES.NEW_BUSINESS
    }

    return {
      dataSourceQueryParam: `dataSource=${dataSource}`,
      dataSourceQueryKeys: ['dataSource', dataSource],
      isExistingBusinessViewSet,
    }
  }, [forceDataSource, searchParams])
}

export default useDataSourceQueryParams
