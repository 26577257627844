import isEmpty from 'lodash.isempty'
import sum from 'lodash.sum'
import uniq from 'lodash.uniq'
import upperFirst from 'lodash.upperfirst'
import { useMemo } from 'react'
import useAuthorityLevelCalculator from 'hooks/services/business-partners/authority-level-calculator/useAuthorityLevelCalculator'
import useAuthorityLevelCalculatorList from 'hooks/services/business-partners/authority-level-calculator/useAuthorityLevelCalculatorList'
import { ENTITY_TYPES } from 'routes/business-partners/arrears/constants'
import { useBusinessPartnerArrears } from 'routes/business-partners/arrears/useBusinessPartnerArrears'

const HEADQUARTER_CURRENCY = 'EUR'

const getItemsWithKey = (arr, itemKey, itemSubKey) =>
  arr
    .map((obj) => {
      const value = obj?.[itemKey]
      if (value !== undefined) {
        if (typeof value === 'object') {
          return value?.[itemSubKey]
        }
        return value
      }
    })
    .filter(Boolean)

const getCurrencies = (arr, itemKey) => {
  const currencies = uniq(getItemsWithKey(arr, itemKey))

  if (currencies.length === 1 && currencies[0] !== HEADQUARTER_CURRENCY) {
    return { isUniqNonHqCurrency: true, currency: currencies[0] }
  } else {
    return { isUniqNonHqCurrency: false, currency: HEADQUARTER_CURRENCY }
  }
}

const getSumAmountAndCurrency = (arr, currencyKey, itemKey) => {
  let amount, additionalAmount
  const currencies = getCurrencies(arr, currencyKey)
  const headquarterItemKey = `headquarter${upperFirst(itemKey)}`
  const sumHeadquarterCurrencyAmount = sum(getItemsWithKey(arr, headquarterItemKey))

  if (currencies.isUniqNonHqCurrency) {
    amount = sumHeadquarterCurrencyAmount
    additionalAmount = sum(getItemsWithKey(arr, itemKey))
  } else {
    amount = sumHeadquarterCurrencyAmount
  }
  return {
    amount,
    currency: HEADQUARTER_CURRENCY,
    additionalAmount,
    additionalCurrency: currencies.currency,
  }
}

const getLoanSumAmountAndCurrencyByProducts = (products, accessors) => {
  let originalVal = 0,
    headquarterVal = 0,
    amount,
    additionalAmount

  const isTranche = (product) => product.type === ENTITY_TYPES.TRANCHE
  const isCreditAccount = (product) => product.type === ENTITY_TYPES.BCA && product.isCreditAccount

  const loanProducts = products.filter((product) => isTranche(product) || isCreditAccount(product))
  const currencies = getCurrencies(loanProducts, accessors.originalCurrency)

  loanProducts.forEach((product) => {
    if (isTranche(product) || isCreditAccount(product)) {
      originalVal += product[accessors.originalValue] ?? 0
      headquarterVal += product[accessors.headquarterValue] ?? 0
    }
  })

  if (currencies.isUniqNonHqCurrency) {
    amount = headquarterVal
    additionalAmount = originalVal
  } else {
    amount = headquarterVal
  }
  return {
    amount,
    currency: HEADQUARTER_CURRENCY,
    additionalAmount,
    additionalCurrency: currencies.currency,
  }
}

const useArrearsCurrencyValues = (businessPartnerId) => {
  const {
    isLoading: isLoadingBpArrears,
    isError: isErrorBpArrears,
    products,
    arrears,
  } = useBusinessPartnerArrears(businessPartnerId)

  const isErrorBcaAmounts = useMemo(
    () => products.some((product) => !!product.isErrorBcaAmounts),
    [products],
  )

  const arrearsAmountAndCurrency = getSumAmountAndCurrency(arrears, 'currency', 'arrearAmount')

  const overpaymentAmountAndCurrency = getSumAmountAndCurrency(
    arrears,
    'currency',
    'overpaymentAmount',
  )

  const {
    data: authorityLevelCalculatorListData = {},
    isFetching: isLoadingAuthorityLevelCalculatorList,
  } = useAuthorityLevelCalculatorList(businessPartnerId)

  const standaloneUnit = authorityLevelCalculatorListData?.standalone
  const isStandaloneUnit = !isEmpty(standaloneUnit)
  const isListUnit = !isEmpty(authorityLevelCalculatorListData)

  let unitHeadBpId, unitTypeId
  if (isStandaloneUnit) {
    unitHeadBpId = businessPartnerId
    unitTypeId = standaloneUnit[0].unitTypeId
  } else if (isListUnit) {
    const otherUnit = Object.values(authorityLevelCalculatorListData).find(
      (units) => !isEmpty(units),
    )
    unitHeadBpId = otherUnit?.[0]?.unitHeadBpId
    unitTypeId = otherUnit?.[0]?.unitTypeId
  }

  const {
    data: authorityLevelCalculatorData = {},
    isFetching: isLoadingAuthorityLevelCalculatorData,
  } = useAuthorityLevelCalculator(businessPartnerId, unitHeadBpId, unitTypeId)

  const isUnitHeadBp = businessPartnerId === unitHeadBpId
  let currentClosedCommitmentTotal
  if (isStandaloneUnit || isUnitHeadBp) {
    currentClosedCommitmentTotal =
      authorityLevelCalculatorData?.totalValues?.totalExistingValues?.totalImportedExistingValues
        ?.amount
  } else {
    const unitMember = authorityLevelCalculatorData?.unitMembers?.filter(
      ({ unitMemberBpId }) => unitMemberBpId === businessPartnerId,
    )
    currentClosedCommitmentTotal = unitMember?.[0]?.totalValues?.importedExistingValues?.amount
  }

  const currentClosedCommitmentLoans = getLoanSumAmountAndCurrencyByProducts(products, {
    originalValue: 'currentClosedCommitment',
    originalCurrency: 'currentClosedCommitmentCurrency',
    headquarterValue: 'headquarterCurrentClosedCommitment',
    headquarterCurrency: 'headquarterCurrentClosedCommitmentCurrency',
  })

  const currentClosedCommitmentLoansOwnShare = getLoanSumAmountAndCurrencyByProducts(products, {
    originalValue: 'currentClosedCommitmentOwnShare',
    originalCurrency: 'ownShareCurrency',
    headquarterValue: 'headquarterCurrentClosedCommitmentOwnShare',
    headquarterCurrency: 'headquarterOwnShareCurrency',
  })

  const outstandingLoansOwnShare = getLoanSumAmountAndCurrencyByProducts(products, {
    originalValue: 'outstandingOwnShare',
    originalCurrency: 'ownShareCurrency',
    headquarterValue: 'headquarterOutstandingOwnShare',
    headquarterCurrency: 'headquarterOwnShareCurrency',
  })

  const availableLoansOwnShare = getLoanSumAmountAndCurrencyByProducts(products, {
    originalValue: 'availableOwnShare',
    originalCurrency: 'ownShareCurrency',
    headquarterValue: 'headquarterAvailableOwnShare',
    headquarterCurrency: 'headquarterAvailableCurrency',
  })

  return {
    arrearsAmountAndCurrency,
    overpaymentAmountAndCurrency,
    currentClosedCommitmentTotal,
    currentClosedCommitmentLoans,
    currentClosedCommitmentLoansOwnShare,
    outstandingLoansOwnShare,
    availableLoansOwnShare,
    isLoading:
      isLoadingBpArrears ||
      isLoadingAuthorityLevelCalculatorList ||
      isLoadingAuthorityLevelCalculatorData,
    isError: isErrorBpArrears,
    isErrorBcaAmounts,
  }
}

export default useArrearsCurrencyValues
