import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState'
import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import DecisionPaperRiskViewRow from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/risk-view/DecisionPaperRiskViewRow'
import { noSelectionCode } from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/risk-view/DecisionPaperRiskViewSelect'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/risk-view/DecisionPaperRiskViewTile.module.css'
import { riskCategories } from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/risk-view/shared/riskViewApi'

const DecisionPaperRiskViewTile = ({
  tileId,
  currentContent,
  onChange,
  isEditMode,
  isPdfView,
  setIsSaveEnabled,
  setHasContentChanges,
}) => {
  const [valueStates, setValueStates] = useState({})
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  useEffect(() => {
    const hasValidationErrors = Object.values(valueStates).includes(ValueState.Error)
    const allFieldsHaveValues =
      !isNil(currentContent) &&
      Object.values(riskCategories).every((riskCategory) => riskCategory in currentContent)
    setIsSaveEnabled(allFieldsHaveValues && !hasValidationErrors)
    const hasContentChanges = !isEqual(tileStatus?.data?.data, currentContent)
    setHasContentChanges(hasContentChanges)
  }, [currentContent, valueStates, setIsSaveEnabled, setHasContentChanges, tileStatus])

  const setValueState = useCallback(
    (selectedValue, riskCategory) => {
      setValueStates({
        ...valueStates,
        [riskCategory]: selectedValue === noSelectionCode ? ValueState.Error : ValueState.None,
      })
    },
    [valueStates, setValueStates],
  )

  const handleChange = useCallback(
    (selectedValue, riskCategory) => {
      setValueState(selectedValue, riskCategory)
      onChange({ ...currentContent, [riskCategory]: selectedValue })
    },
    [onChange, currentContent, setValueState],
  )

  const handleBlur = useCallback(
    (selectedValue, riskCategory) => {
      setValueState(selectedValue, riskCategory)
    },
    [setValueState],
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.content}>
      {Object.values(riskCategories).map((riskCategory) => (
        <DecisionPaperRiskViewRow
          key={riskCategory}
          value={isNil(currentContent) ? undefined : currentContent[riskCategory]}
          riskCategory={riskCategory}
          onChange={handleChange}
          onBlur={handleBlur}
          isEditMode={isEditMode}
          isPdfView={isPdfView}
          valueState={valueStates[riskCategory]}
        />
      ))}
    </FlexBox>
  )
}

DecisionPaperRiskViewTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  currentContent: PropTypes.shape({
    financingStructureRisk: PropTypes.string,
    propertyRisk: PropTypes.string,
    tenancyRisk: PropTypes.string,
    cashflowRisk: PropTypes.string,
    involvedPartiesRisk: PropTypes.string,
    totalRisk: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  setIsSaveEnabled: PropTypes.func.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
}

export default DecisionPaperRiskViewTile
