import { useMutation } from '@tanstack/react-query'
import { useAuth } from 'oidc-react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import useRentRollWorkingVersionDateFormatter from 'hooks/services/properties/useRentRollWorkingVersionDateFormatter'

const useUpdateRentRollWorkingVersion = (options = {}) => {
  const { put } = useAccessTokenRequest()
  const { userData } = useAuth()
  const dateFormatter = useRentRollWorkingVersionDateFormatter()

  return useMutation(
    async ({ property_uuids, uuid, header, rental_units }) => {
      const formattedHeader = dateFormatter.formatDateInHeader(header)
      const formattedRentalUnits = dateFormatter.formatDateInRentalUnits(rental_units)

      const { data } = await put({
        path: `/properties/rent-roll/working-version`,
        body: {
          property_uuids,
          rent_roll_working_version: {
            uuid,
            property_uuids,
            header: { ...formattedHeader, creator: userData.profile.name },
            rental_units: formattedRentalUnits,
          },
        },
      })
      return data
    },
    { ...options },
  )
}

export default useUpdateRentRollWorkingVersion
