/**
 *
 * @typedef {{id: string, type: string}} ValuationType
 * @typedef {{id: string, reason: string, types: {valuation_types: ValuationType[]}}} Reason
 *
 * @param {Reason[]} valuationReasons
 * @returns {ValuationType[]} the intersection of all valuationTypes
 */
const calculateIntersectionOfValuationTypesForReasons = (valuationReasons) => {
  if (valuationReasons.length === 0) {
    return []
  }

  const valuationTypeLists = valuationReasons.map(
    ({ types: { valuation_types } }) => valuation_types,
  )
  const firstValuationTypeList = valuationTypeLists[0]
  const otherValuationTypesList = valuationTypeLists.slice(1)
  /*
   * Map of intersected valuationTypes indexed by ID. Entries are only ever removed
   * if not found in the other valuation type lists. It does not matter which initial
   * value we seed the list with. The first list of valuation types seems appropriate.
   */
  const valuationTypesIntersectionMap = new Map(
    firstValuationTypeList.map((valuationType) => [valuationType.id, valuationType]),
  )

  for (const valuationTypes of otherValuationTypesList) {
    // return early if map of remaining valuation types is empty
    if (valuationTypesIntersectionMap.size === 0) {
      break
    }

    /*
     * remove valuation type from intersection map if ID does not exist in
     * next list of valuation - this after the whole iteration only
     * keeps valuationTypes in the intersection map that appear in
     * all the valuation reasons.
     */
    const nextValuationTypeIds = new Set(valuationTypes.map(({ id }) => id))
    for (const valuationTypeId of valuationTypesIntersectionMap.keys()) {
      if (!nextValuationTypeIds.has(valuationTypeId)) {
        valuationTypesIntersectionMap.delete(valuationTypeId)
      }
    }
  }

  return Array.from(valuationTypesIntersectionMap.values())
}

export default calculateIntersectionOfValuationTypesForReasons
