import { useMemo } from 'react'
import {
  getTimeSeriesForKpi,
  getKpiForestFromKpiList,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/kpis/KpiUtilsV3'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useMultipleStaffMemberByObjectIdOrEmail from 'hooks/services/business-events-and-tasks/decision-papers/tiles/financial-ratio/kpi-chart/shared/useMultipleStaffMemberByObjectIdOrEmail'
import { useMultipleKpis } from 'hooks/services/kpis/useMultipleKpis'

const useKpiChartTile = ({ entityRef: { entityId: businessPartnerId } }, tileId) => {
  const {
    data: { dealsData, selectedDeal } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const { selectedDealDisplayId } = selectedDeal ?? {}
  const dealUuids = dealsData?.map((dealData) => dealData.dealUuid)

  const responses = useMultipleKpis(cwpEntityTypes.DEAL, dealUuids)

  const isMultipleKpisLoading = responses.some((r) => r.isLoading)
  const isMultipleKpisError = responses.some((r) => r.isError)
  const multipleKpisErrors = responses.map((r) => r.error)

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isMultipleKpisLoading, isAnnualReviewDealsLoading],
    errorValues: [isMultipleKpisError, isAnnualReviewDealsError],
    errorDetails: [annualReviewDealsError, multipleKpisErrors],
    tileId,
  })

  const kpiLists = useMemo(
    () =>
      responses.map((r, index) => ({
        kpiList: r.data.kpis,
        dealUuid: dealUuids[index],
      })),
    [dealUuids, responses],
  )

  const updatedByIdSet = useMemo(() => {
    const set = new Set()

    kpiLists.forEach((kpiList) => {
      const kpiForest = getKpiForestFromKpiList(kpiList.kpiList)

      const timeSeries = kpiForest.map((kpi) => getTimeSeriesForKpi(kpi))
      timeSeries.forEach((singleTimeSeries) => {
        const updatedByIds = [...new Set(singleTimeSeries.map((kpiValue) => kpiValue.updatedBy))]
        updatedByIds.filter((id) => id).forEach(set.add, set)
      })
    })

    return set
  }, [kpiLists])

  const {
    data: staffMembers,
    isSomeLoading: isStaffMemberLoading,
    isSomeError: isStaffMemberError,
    isNotFoundErrorOnly: isStaffMemberNotFoundErrorOnly,
  } = useMultipleStaffMemberByObjectIdOrEmail(
    {
      objectIdOrEmailAddressList: updatedByIdSet,
    },
    {
      enabled: !isSomeValueLoading && !isSomeValueError,
    },
  )

  const isStaffMemberErrorWithoutNotFoundError =
    isStaffMemberError && !isStaffMemberNotFoundErrorOnly

  if (
    isSomeValueLoading ||
    isStaffMemberLoading ||
    isSomeValueError ||
    isStaffMemberErrorWithoutNotFoundError
  ) {
    return {
      isLoading: isSomeValueLoading || isStaffMemberLoading,
      // We do not want to break the tile when the staff member is not resolved correctly,
      // instead we display "unknown" user in the tool tip.
      isError: isSomeValueError || isStaffMemberErrorWithoutNotFoundError,
      error,
    }
  }

  const updatedByNames = []
  if (staffMembers) {
    Object.keys(staffMembers).forEach((staffMemberId) => {
      const staffMember = staffMembers[staffMemberId]
      if (staffMember?.fullName) {
        updatedByNames.push({ id: staffMemberId, name: staffMembers[staffMemberId].fullName })
      }
    })
  }

  return {
    data: {
      sourceRender: {
        dealDisplayId: selectedDealDisplayId,
      },
      kpiLists,
      dealsData,
      updatedByNames,
    },
    isLoading: false,
    isError: false,
    isFetching: responses.some((r) => r.isFetching),
  }
}

export default useKpiChartTile
