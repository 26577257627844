import { FlexBox, FlexBoxDirection, ResponsiveGridLayout } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import {
  decisionStageStatus as possibleDecisionStageStatus,
  decisionStageVoterStatus as possibleDecisionStageVoterStatus,
} from 'api/decision-process/decisionProcessApi'
import { decisionProcessConditionsDecisionStatus } from 'api/decision-process/decisionProcessConditionsApi'
import useDecisionStageName from 'components/domains/business-events-and-tasks/common/useDecisionStageName'
import DecisionStageConditionsTable from 'components/domains/business-events-and-tasks/decision-stage/conditions/DecisionStageConditionsTable'
import styles from 'components/domains/business-events-and-tasks/decision-stage/conditions/approval/DecisionStageConditionsApprovalContent.module.css'
import DecisionStageConditionsApprovalInformationCard from 'components/domains/business-events-and-tasks/decision-stage/conditions/approval/DecisionStageConditionsApprovalInformationCard'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
const numberOfColumns = 2

const DecisionStageConditionsApprovalContent = ({
  decisionStage,
  decisionStageConditions,
  voterStatusCode,
  decisionStageId,
}) => {
  const {
    event: { id: eventId },
  } = useContext(BusinessEventsAndTasksContext)
  const decisionStageName = useDecisionStageName({
    name: decisionStage.info.name,
    decisionStageType: decisionStage.info.decisionType,
  })
  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.pageWrapper}>
      <ResponsiveGridLayout
        columnsXL={numberOfColumns}
        columnsL={numberOfColumns}
        columnsM={numberOfColumns}
        columnsS={numberOfColumns}
        columnGap="1rem"
        rowGap="1rem"
      >
        <DecisionStageConditionsApprovalInformationCard
          decisionPaperVersion={decisionStage.decisionPaper.version}
          decisionStageName={decisionStageName}
          decisionStageStatus={decisionStage.info.decisionStatus}
          decisionStageDecisionDate={decisionStage.info.decisionDate}
          decisionStageConditionsStatus={decisionStageConditions.info.decisionStatus}
          voterStatus={voterStatusCode}
        />
      </ResponsiveGridLayout>
      <DecisionStageConditionsTable
        eventId={eventId}
        decisionStageId={decisionStageId}
        decisionStageVoterConditions={decisionStageConditions.decisionStageVoterConditions}
        showEditButtons={false}
        showMoreButtons={false}
      />
    </FlexBox>
  )
}

DecisionStageConditionsApprovalContent.propTypes = {
  decisionStage: PropTypes.shape({
    info: PropTypes.shape({
      name: PropTypes.string.isRequired,
      decisionType: PropTypes.string.isRequired,
      decisionStatus: PropTypes.oneOf(Object.values(possibleDecisionStageStatus)).isRequired,
      decisionDate: PropTypes.string,
    }).isRequired,
    decisionPaper: PropTypes.shape({
      version: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  decisionStageConditions: PropTypes.shape({
    info: PropTypes.shape({
      decisionStatus: PropTypes.oneOf(Object.values(decisionProcessConditionsDecisionStatus))
        .isRequired,
    }).isRequired,
    decisionStageVoterConditions: PropTypes.array.isRequired,
  }).isRequired,
  voterStatusCode: PropTypes.oneOf(Object.values(possibleDecisionStageVoterStatus)).isRequired,
  decisionStageId: PropTypes.string.isRequired,
}

export default DecisionStageConditionsApprovalContent
