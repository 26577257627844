import { MessageBoxTypes, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import MessageBox from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/components/ui/message-box/MessageBox'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/message-box/MessageBoxWithExpandableDetails.module.css'
import MessageBoxWithExpandableDetailsShowDetails from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/message-box/MessageBoxWithExpandableDetailsShowDetails'

const MessageBoxWithExpandableDetails = ({
  messageSummary,
  messageDetails,
  messageBoxType,
  isOpen,
  onClose,
}) => {
  const hasDetails = useMemo(() => messageDetails && messageDetails !== '{}', [messageDetails])
  const handleOnClose = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <>
      {isOpen && (
        <MessageBox
          type={messageBoxType}
          open={isOpen}
          onClose={handleOnClose}
          className={styles.expandableErrorMessageBox}
        >
          <Text>{messageSummary}</Text>
          {hasDetails && (
            <MessageBoxWithExpandableDetailsShowDetails messageDetails={messageDetails} />
          )}
        </MessageBox>
      )}
    </>
  )
}

MessageBoxWithExpandableDetails.propTypes = {
  messageSummary: PropTypes.string,
  messageDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  messageBoxType: PropTypes.oneOf(Object.values(MessageBoxTypes)).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
}

export default MessageBoxWithExpandableDetails
