/**
 * This result of this hook is a method that takes a translated string.
 * If this string contains unused templates, these template will be removed from the string.
 * @returns A translated string with removed unused template and its surroundings. E.g. "Milestones ({{milestoneCount}})" -> "Milestones"
 */
const useDealChangeOverviewUnusedTranslationTemplateRemover = () => {
  const remover = (translation) => {
    if (translation?.includes('{{') && translation?.includes('}}')) {
      const regex = /[^ ]*{{[^{}]*}}[^ ]*/g // Regular expression to match the template and its surroundings
      const cleanedTranslation = translation.replace(regex, '')
      return cleanedTranslation.trim()
    } else {
      return translation
    }
  }

  return {
    remover,
  }
}

export default useDealChangeOverviewUnusedTranslationTemplateRemover
