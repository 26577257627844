import { Button, ButtonDesign, FlexBox } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import PropType from 'prop-types'
import { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useCancelPopover from 'components/domains/deals/useCancelPopover'
import styles from 'components/domains/properties/valuation/table/table-cells/ValuationRequestsTableActionsCell.module.css'
import ValuationRequestsTableDeleteButton from 'components/domains/properties/valuation/table/table-cells/edit/ValuationRequestsTableDeleteButton'
import ValuationRequestsTableSaveButton from 'components/domains/properties/valuation/table/table-cells/edit/ValuationRequestsTableSaveButton'

const propTypes = {
  propertyUuids: PropType.arrayOf(PropType.string.isRequired),
  valuationRequest: PropType.shape({
    id: PropType.string.isRequired,
    propertyId: PropType.string.isRequired,
    status: PropType.string.isRequired,
  }).isRequired,
  editValuation: PropType.shape({
    id: PropType.string.isRequired,
    reason: PropType.string,
    valuationType: PropType.string,
  }).isRequired,
  isDirty: PropType.bool,
  isRowEditable: PropType.bool,
  isRowRemovable: PropType.bool,
  onEditCancel: PropType.func.isRequired,
  onEdit: PropType.func.isRequired,
}

/**
 *  @param {PropType.InferProps<typeof propTypes>} props
 */
const ValuationRequestsTableActionsCell = ({
  propertyUuids,
  valuationRequest,
  editValuation,
  isDirty,
  isRowEditable = true,
  isRowRemovable = true,
  onEditCancel,
  onEdit,
}) => {
  const { t } = useTranslation()
  const isEditing = useMemo(
    () => editValuation.id === valuationRequest.id,
    [editValuation.id, valuationRequest.id],
  )
  const isEditingDisabled = useMemo(() => !!editValuation.id, [editValuation.id])
  const cancelButtonId = useMemo(
    () => `valuation-cancel-${valuationRequest.id}`,
    [valuationRequest.id],
  )
  const queryClient = useQueryClient()

  const modalContainerRef = useRef(document.body)

  const handleOnEdit = useCallback(() => onEdit(valuationRequest), [onEdit, valuationRequest])

  const handleEditCancelConfirmation = useCallback(() => {
    onEditCancel()
  }, [onEditCancel])

  const { show: showCancelPopover } = useCancelPopover({
    onConfirmCancel: handleEditCancelConfirmation,
    opener: cancelButtonId,
    container: modalContainerRef.current,
  })
  const handleEditCancel = useCallback(() => {
    isDirty ? showCancelPopover() : handleEditCancelConfirmation()
  }, [handleEditCancelConfirmation, isDirty, showCancelPopover])

  const refreshSite = useCallback(() => {
    if (propertyUuids.length === 1) {
      queryClient.invalidateQueries(['properties', propertyUuids[0], 'valuation-requests'])
    } else {
      queryClient.invalidateQueries(['properties', 'valuation-requests', ...propertyUuids])
    }
  }, [propertyUuids, queryClient])

  const handleOnSaveSuccess = useCallback(() => {
    refreshSite()
    onEditCancel()
  }, [onEditCancel, refreshSite])

  const isSaveButtonDisabled = useMemo(
    () => !isDirty || isEmpty(editValuation.reason) || isEmpty(editValuation.valuationType),
    [isDirty, editValuation.reason, editValuation.valuationType],
  )

  if (!isRowEditable) {
    return <></>
  }

  const buttons = isEditing ? (
    <>
      <ValuationRequestsTableSaveButton
        id={`valuation-save-${valuationRequest.id}`}
        originalStatus={valuationRequest.status}
        editValuation={editValuation}
        disabled={isSaveButtonDisabled}
        onSaveSuccess={handleOnSaveSuccess}
      >
        {t('buttons.save')}
      </ValuationRequestsTableSaveButton>
      <Button id={cancelButtonId} design={ButtonDesign.Transparent} onClick={handleEditCancel}>
        {t('buttons.cancel')}
      </Button>
    </>
  ) : (
    <>
      <Button
        id={`valuation-edit-${valuationRequest.id}`}
        disabled={isEditingDisabled}
        onClick={handleOnEdit}
        design={ButtonDesign.Transparent}
        icon="edit"
      />
      {isRowRemovable && (
        <ValuationRequestsTableDeleteButton
          id={`valuation-delete-${valuationRequest.id}`}
          valuationRequestId={valuationRequest.id}
          disabled={isEditingDisabled}
          propertyId={valuationRequest.propertyId}
          onDeleteSuccess={refreshSite}
        />
      )}
    </>
  )

  return <FlexBox className={styles.actionsContainer}>{buttons}</FlexBox>
}

ValuationRequestsTableActionsCell.propTypes = propTypes

export default ValuationRequestsTableActionsCell
