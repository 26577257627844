import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CashFlowScenarioNameTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/CashFlowScenarioNameTile'
import { DealCashFlowDebtAndEquityCard } from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/DealCashFlowDebtAndEquityCard'
import ErrorScreen from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/DealCashFlowErrorScreen'

const CashFlowDebtAndEquityProjectionTile = ({ tileId, isPdfView }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.cash-flow',
  })

  const {
    data: {
      periodQuatersCalculatedKpis,
      calculatedResults,
      dealId,
      tileMetaData,
      cashflowScenarioPeriodNotFound,
    },
    isLoading,
    isError,
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  if (cashflowScenarioPeriodNotFound) {
    return (
      <ErrorScreen title={t('loading.error.title')} description={t('loading.error.description')} />
    )
  }

  return (
    <>
      <CashFlowScenarioNameTile
        metaData={tileMetaData?.metaData}
        dealId={dealId}
        isPdfView={isPdfView}
      />
      <DealCashFlowDebtAndEquityCard
        calculatedKpis={periodQuatersCalculatedKpis}
        calculatedResults={calculatedResults}
        isCard={false}
        metaData={tileMetaData?.metaData}
        showTitle={false}
        isLoading={isLoading}
        isError={isError}
        isOnDecisionPaper={true}
      />
    </>
  )
}

CashFlowDebtAndEquityProjectionTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default CashFlowDebtAndEquityProjectionTile
