import {
  AnalyticalTableScaleWidthMode,
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  TextAlign,
} from '@fioneer/ui5-webcomponents-react/dist'
import isEmpty from 'lodash.isempty'
import orderBy from 'lodash.orderby'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Card from 'components/ui/card/Card'
import AnalyticalTableNoDataComponent from 'components/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import FiscalFigureCell from 'routes/business-partners/FiscalFigureCell'
import styles from 'routes/business-partners/FiscalInformationCard.module.css'
import OpenInFssLink from 'routes/business-partners/OpenInFssLink'
import { mapFiscalDataForFinancialInformationTable } from 'routes/business-partners/mapFiscalDataForFinancialInformationTable'
import { stringTemplate } from 'utils/stringTemplate'

const FiscalInformationCard = ({
  fiscalYears = {},
  fiscalFigureGroups = {},
  fiscalFigureTypes = {},
  minimumImportanceLevel = 1,
  isLoading = false,
  isError = false,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.business-partner.fiscal-information',
  })
  const { format: formatDate } = useShortDateFormatter()

  const { linkToFiscalYearInFiscalInformationSystemTemplate, id: businessPartnerId } =
    useContext(BusinessPartnerContext)

  const columns = useMemo(() => {
    const linkToFss = (referenceId) =>
      stringTemplate(linkToFiscalYearInFiscalInformationSystemTemplate, {
        businessPartnerId,
        fiscalYearId: referenceId,
      })
    return [
      {
        Header: t('table-header.description'),
        id: 'description',
        accessor: (row) => ({
          description: row.description ?? '',
          isSectionHeader: row.isSectionHeader,
        }),
        Cell: (row) => {
          if (!row.value.description) return ''
          return (
            <div className={row.value.isSectionHeader ? styles.sectionHeader : ''}>
              {row.value.description}
            </div>
          )
        },
        disableFilters: true,
        width: 460,
        disableDragAndDrop: true,
      },
      {
        Header: t('table-header.description'),
        id: 'description-for-filter',
        filterType: filterTypes.CONTAINS,
        accessor: (row) => row.description ?? '',
        hide: true,
        disableDragAndDrop: true,
      },
      ...orderBy(
        Object.entries(fiscalYears).map(
          ([year, { end_date: endDate, reference_id: referenceId }]) => ({
            Header: (
              <FlexBox
                direction={FlexBoxDirection.Row}
                className={styles.flexBox}
                justifyContent={FlexBoxJustifyContent.End}
              >
                <FlexBox direction={FlexBoxDirection.Column}>
                  {endDate ? formatDate(endDate) : year}
                </FlexBox>
                <FlexBox direction={FlexBoxDirection.Column}>
                  <OpenInFssLink to={linkToFss(referenceId)} />
                </FlexBox>
              </FlexBox>
            ),
            id: year,
            accessor: (row) => ({
              value: row[year]?.value,
              unit: row[year]?.unit,
              format: row[year]?.format,
            }),
            Cell: FiscalFigureCell,
            hAlign: TextAlign.End,
            disableFilters: true,
            disableDragAndDrop: true,
          }),
        ),
        ['id'],
        'desc',
      ),
    ]
  }, [
    businessPartnerId,
    fiscalYears,
    formatDate,
    linkToFiscalYearInFiscalInformationSystemTemplate,
    t,
  ])

  const tableData = useMemo(
    () =>
      isEmpty(fiscalYears)
        ? []
        : mapFiscalDataForFinancialInformationTable({
            fiscalFigureGroups,
            fiscalFigureTypes,
            fiscalYears,
            minimumImportanceLevel,
          }),
    [fiscalFigureGroups, fiscalFigureTypes, fiscalYears, minimumImportanceLevel],
  )

  const nrOfEntries = useMemo(
    () => tableData.filter(({ isSectionHeader }) => !isSectionHeader).length,
    [tableData],
  )

  return (
    <Card>
      <AnalyticalTableWithToolbar
        title={t('table-title')}
        columns={columns}
        // scaleWidthMode={AnalyticalTableScaleWidthMode.Smart} // using "smart" causes hidden columns to be shown.
        scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
        data={tableData}
        className={styles.tableWrapper}
        minRows={1}
        rowHeight={32}
        sortable={false}
        withRowHighlight={true}
        nrOfEntries={nrOfEntries}
        NoDataComponent={() => (
          <AnalyticalTableNoDataComponent
            tableId={'fiscal-information'}
            isError={isError}
            isLoading={isLoading}
          />
        )}
        filterable
        visibleRows={Math.max(tableData.length, 1)}
      />
    </Card>
  )
}

FiscalInformationCard.propTypes = {
  fiscalYears: PropTypes.object.isRequired,
  fiscalFigureGroups: PropTypes.object.isRequired,
  fiscalFigureTypes: PropTypes.object.isRequired,
  minimumImportanceLevel: PropTypes.number,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
}

export default FiscalInformationCard
