import {
  ButtonDesign,
  FlexBox,
  FlexBoxDirection,
  Label,
  MessageBoxTypes,
  RadioButton,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/rent-roll/working-version/PropertyRentRollWorkingVersionUnsavedChangesDialog.module.css'
import LoadingButton from 'components/ui/button/LoadingButton'
import MessageBox, { MessageBoxActions } from 'components/ui/message-box/MessageBox'

const noopFn = () => {}

const PropertyRentRollWorkingVersionUnsavedChangesDialog = ({
  isOpen = false,
  onProceed = noopFn,
  onCancel = noopFn,
  saveChanges,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.working-version.unsaved-changes-dialog',
  })

  const [selectedAction, setSelectedAction] = useState('discard')
  const [isLoading, setIsLoading] = useState(false)

  const onRadioButtonChange = useCallback((event) => setSelectedAction(event.target.value), [])

  const onMessageBoxClose = useCallback(
    async (event) => {
      if (event.detail.action !== MessageBoxActions.OK) {
        return onCancel()
      }

      try {
        if (selectedAction === 'save') {
          setIsLoading(true)
          await saveChanges()
        }
      } finally {
        setIsLoading(false)
        onProceed()
      }
    },
    [onCancel, onProceed, saveChanges, selectedAction],
  )

  const okButton = (
    <LoadingButton
      key={'okButton'}
      isError={false}
      isLoading={isLoading}
      design={ButtonDesign.Emphasized}
      className={styles.okButton}
      renderContent={() => MessageBoxActions.OK}
      data-action={MessageBoxActions.OK}
    />
  )

  return (
    <MessageBox
      open={isOpen}
      type={MessageBoxTypes.Warning}
      actions={[okButton, MessageBoxActions.Cancel]}
      onClose={onMessageBoxClose}
    >
      <FlexBox direction={FlexBoxDirection.Column} className={styles.content}>
        <Text className={styles.text}>{t('message')}</Text>

        <FlexBox direction={FlexBoxDirection.Column} className={styles.options}>
          {['save', 'discard'].map((action) => {
            const actionId = `rrwv-unsaved-changes-dialog-action--${action}`
            return (
              <FlexBox key={action} direction={FlexBoxDirection.Row} className={styles.option}>
                <RadioButton
                  value={action}
                  checked={selectedAction === action}
                  name="unsaved-changes-action"
                  id={actionId}
                  onChange={onRadioButtonChange}
                />
                <FlexBox direction={FlexBoxDirection.Column} className={styles.labelWrapper}>
                  <Label className={styles.label} for={actionId}>
                    {t(`actions.${action}.label`)}
                  </Label>
                  <Text className={styles.text}>{t(`actions.${action}.sub-label`)}</Text>
                </FlexBox>
              </FlexBox>
            )
          })}
        </FlexBox>
      </FlexBox>
    </MessageBox>
  )
}

PropertyRentRollWorkingVersionUnsavedChangesDialog.propTypes = {
  isOpen: PropTypes.bool,
  onProceed: PropTypes.func,
  onCancel: PropTypes.func,
  saveChanges: PropTypes.func.isRequired,
}

export default PropertyRentRollWorkingVersionUnsavedChangesDialog
