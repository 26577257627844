import { Input } from '@fioneer/ui5-webcomponents-react'
import React, { useCallback, useContext, useMemo } from 'react'
import { CardEditItem, CardShowItem } from 'components/domains/deals/card/CardItem'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { useCashflowsExtensionFields } from 'hooks/services/deals/configurations/useCashflowsExtensionFields'
import { UserProfileContext } from 'routes/UserProfileContext'

export const useExternalInformationFields = (extensionFields = [], isDisplayMode = true) => {
  const { zoneId: timeZone } = useContext(UserProfileContext) ?? {}
  const { format: formatDate } = useShortDateFormatter()
  const { format: formatDateAndTime } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
    timeZone,
  })

  const { data: { fields = [] } = {}, isLoading, isError } = useCashflowsExtensionFields()

  const getFieldValue = useCallback(
    (id) => extensionFields.find(({ ccsefId }) => ccsefId === id)?.value,
    [extensionFields],
  )

  const formatValue = useCallback(
    (value, type) => {
      if (!value) return
      return (
        {
          STRING: value,
          DATE: formatDate(value),
          DATE_TIME: formatDateAndTime(value),
        }[type] ?? value
      )
    },
    [formatDate, formatDateAndTime],
  )

  const displayItems = useMemo(
    () =>
      fields.map(({ ccsefId, displayFieldName, type }, index) => {
        const value = getFieldValue(ccsefId)
        const formattedValue = formatValue(value, type)
        return <CardShowItem label={displayFieldName} value={formattedValue} key={index} />
      }),
    [fields, formatValue, getFieldValue],
  )

  const editItems = useMemo(
    () =>
      fields.map(({ ccsefId, displayFieldName, type }, index) => {
        const value = getFieldValue(ccsefId)
        const formattedValue = formatValue(value, type)
        return (
          <CardEditItem
            label={displayFieldName}
            value={<Input readonly value={formattedValue} />}
            key={index}
          />
        )
      }),
    [fields, formatValue, getFieldValue],
  )

  return {
    data: isDisplayMode ? displayItems : editItems,
    isLoading,
    isError,
  }
}
