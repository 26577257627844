import { BusyIndicatorSize, TableGrowingMode } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from 'components/domains/deals/covenants/covenant-checks-table/CovenantChecksTable.module.css'
import useCovenantChecksTableColumnDefinitions from 'components/domains/deals/covenants/covenant-checks-table/useCovenantChecksTableColumnDefinitions'
import useCovenantChecksTableData from 'components/domains/deals/covenants/covenant-checks-table/useCovenantChecksTableData'
import useCovenantChecksTableEnumns from 'components/domains/deals/covenants/covenant-checks-table/useCovenantChecksTableEnumns'
import { ErrorDataUnavailableInTable } from 'components/ui/errors/ErrorDataUnavailableInTable'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import paths from 'routes/paths'

const columnKeyToCovenantChecksDataMap = new Map()
columnKeyToCovenantChecksDataMap.set('covenant-check', 'name')
columnKeyToCovenantChecksDataMap.set('key-date', 'keyDate')
columnKeyToCovenantChecksDataMap.set('monitoring-reference-type', 'monitoringReferenceEntity.type')
columnKeyToCovenantChecksDataMap.set('monitoring-reference', 'monitoringReferenceEntity.id')
columnKeyToCovenantChecksDataMap.set('status', 'statusOrder')
columnKeyToCovenantChecksDataMap.set('first-target-delivery-date', 'firstTargetDeliveryDate')
columnKeyToCovenantChecksDataMap.set('breach', 'breach')

const CovenantChecksTable = ({
  covenantChecksData,
  pagination,
  onSortingChanged,
  loadMore,
  sorting: { orderDirection, orderField } = {},
  isAllowedToReadCovenants,
  entityType,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.covenants.monitoring.covenant-checks',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const enumnsForFiltering = useCovenantChecksTableEnumns({ data: covenantChecksData, t })
  const columnDefinitions = useCovenantChecksTableColumnDefinitions({
    t,
    entityType,
    ...enumnsForFiltering,
  })
  const { tableData, isLoading } = useCovenantChecksTableData({
    data: covenantChecksData,
    isAllowedToReadCovenants,
  })
  const navigate = useNavigate()

  const showMoreButton = pagination.limit < pagination.total
  const growingMode = showMoreButton ? TableGrowingMode.Button : TableGrowingMode.None
  const growingButtonText = tNoPrefix('components.ui.tables.sorted-tables.growing-button-text')
  const growingButtonSubtext = `[ ${pagination.limit} / ${pagination.total} ]`

  const handleOnRowClick = ({ detail: { row: clickedTableRow } }) => {
    const covenantCheckUuid = clickedTableRow.getAttribute('covenant-check-uuid')
    const monitoringReferenceEntityType = clickedTableRow.getAttribute(
      'monitoring-reference-entity-type',
    )
    const monitoringReferenceId = clickedTableRow.getAttribute('monitoring-entity-display-id')
    let navigationLink
    switch (monitoringReferenceEntityType) {
      case 'DEAL':
        navigationLink = `/${paths.deals}/${monitoringReferenceId}/${dealDetailPaths.covenants}/${dealDetailPaths.covenantChecks}/${covenantCheckUuid}`
        break
      case 'BUSINESSPARTNER':
        navigationLink = `/${paths.businessPartners}/${monitoringReferenceId}/covenants/covenant-checks/${covenantCheckUuid}`
        break
    }
    navigate(navigationLink)
  }
  const customOrderCallback = (columnKey, attributeOrderDirection) => {
    const newOrderField = columnKeyToCovenantChecksDataMap.get(columnKey)
    onSortingChanged(newOrderField, attributeOrderDirection)
  }

  const sortingColumnKey = Array.from(columnKeyToCovenantChecksDataMap.keys()).find(
    (key) => columnKeyToCovenantChecksDataMap.get(key) === orderField,
  )
  return (
    <RequestStateResolver
      center
      busyIndicatorSize={BusyIndicatorSize.Medium}
      isLoading={isLoading}
      isError={false}
      errorToDisplay={<ErrorDataUnavailableInTable />}
      renderContent={() => (
        <div className={`${!isAllowedToReadCovenants && styles['default-cursor']}`}>
          <SortedTable
            columnDefinitions={columnDefinitions}
            tableData={tableData}
            toolbarConfig={{
              title: t('table-title'),
              sorting: {
                columnKey: sortingColumnKey,
                isSortingAscending: orderDirection === 'asc',
              },
            }}
            customOrderCallback={customOrderCallback}
            // needs to be specified to prevent sorting from the SortedTable
            customOrderFunction={(data) => data}
            noDataText={tNoPrefix('components.table.no-data')}
            paginationConfig={{
              growing: growingMode,
              growingButtonText: growingButtonText,
              growingButtonSubtext: growingButtonSubtext,
              totalNumberOfItems: pagination?.total,
              loadMore: loadMore,
            }}
            additionalTableProperties={{
              onRowClick: isAllowedToReadCovenants ? handleOnRowClick : () => {},
              className: styles.innerTable,
            }}
          />
        </div>
      )}
    />
  )
}

export const covenantChecksDataPropTypes = {
  covenantCheckUuid: PropTypes.string.isRequired,
  name: PropTypes.string,
  monitoringReferenceEntity: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  status: PropTypes.string.isRequired,
  breach: PropTypes.bool.isRequired,
  keyDate: PropTypes.string,
  firstDeliveryDate: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  covenants: PropTypes.arrayOf(
    PropTypes.shape({
      covenantUuid: PropTypes.string.isRequired,
      mainEntity: PropTypes.shape({
        type: PropTypes.string,
        id: PropTypes.string,
      }).isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string,
    }),
  ),
  linkedEventId: PropTypes.string,
}

CovenantChecksTable.propTypes = {
  covenantChecksData: PropTypes.arrayOf(PropTypes.shape(covenantChecksDataPropTypes)),
  pagination: PropTypes.shape({
    offset: PropTypes.number,
    limit: PropTypes.number,
    total: PropTypes.number,
  }),
  onSortingChanged: PropTypes.func,
  loadMore: PropTypes.func,
  sorting: PropTypes.shape({
    orderDirection: PropTypes.string,
    orderField: PropTypes.string,
  }),
  isAllowedToReadCovenants: PropTypes.bool,
  entityType: PropTypes.oneOf([cwpEntityTypes.DEAL, cwpEntityTypes.BUSINESS_PARTNER]),
}

export default CovenantChecksTable
