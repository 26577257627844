import { TextAlign } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyValueCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/deal-uses-and-sources/table-cells/CurrencyValueCell'
import NameCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/deal-uses-and-sources/table-cells/NameCell'
import ShareCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/deal-uses-and-sources/table-cells/ShareCell'

const cellDefaults = {
  disableDragAndDrop: true,
  disableGlobalFilter: true,
  disableFilters: true,
  disableSortBy: true,
  disableGroupBy: true,
  isSelectableForHiding: true,
  sortingDisabled: false,
}

const useDealSourcesColumns = () => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.deal-uses-and-sources',
  })
  const columns = useMemo(
    () => [
      {
        ...cellDefaults,
        Header: '',
        id: 'name',
        accessor: ({ name, isSectionHeader, isEmptyCell }) => ({
          name,
          isSectionHeader,
          isEmptyCell,
        }),
        Cell: NameCell,
        disableResizing: true,
      },
      {
        ...cellDefaults,
        Header: t('columns.amount'),
        id: 'amount',
        accessor: ({ original, headquarter, isSectionHeader, isEmptyCell }) => ({
          original,
          headquarter,
          isSectionHeader,
          isEmptyCell,
        }),
        Cell: CurrencyValueCell,
        hAlign: TextAlign.Right,
        disableResizing: true,
      },
      {
        ...cellDefaults,
        Header: t('columns.share'),
        id: 'share',
        accessor: ({ share, isEmptyCell, isSectionHeader }) => ({
          share,
          isSectionHeader,
          isEmptyCell,
        }),
        Cell: ShareCell,
        hAlign: TextAlign.Right,
        disableResizing: true,
        width: 80,
      },
    ],
    [t],
  )

  return useMemo(() => ({ columns }), [columns])
}

export default useDealSourcesColumns
