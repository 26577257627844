import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AnalyticalTableNoDataComponent from 'components/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import styles from 'components/ui/tables/collateral-agreement/CollateralAgreementTable.module.css'
import { ConfigContext } from 'hooks/config/useConfig'

/**
 * move to correct folder | https://fioneer.atlassian.net/browse/CWP-13200
 * @param {import('prop-types').InferProps<typeof propTypes>} props
 */
const CollateralAgreementTable = ({ id, hooks, translationKey }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: translationKey,
  })

  const { collateralAgreements: { systemStatus = {} } = {} } = useContext(ConfigContext)
  const { useTableData, useTableColumnConfig, useMapTableData } = hooks
  const { isLoading, isError, data } = useTableData(id)
  const { columnDefinitions } = useTableColumnConfig()
  const mappedData = useMapTableData(data)

  const allExpanded = useMemo(() => {
    let initial = {}
    Object.entries(systemStatus).forEach((_, index) => {
      initial = {
        ...initial,
        [index]: true,
      }
    })
    return initial
  }, [systemStatus])

  const subRowCount = mappedData?.reduce(
    (acc, item) => acc?.subRows?.length || 0 + item?.subRows?.length || 0,
    0,
  )

  return (
    <AnalyticalTableWithToolbar
      id="collateral-agreement-table"
      isTreeTable
      filterable={false}
      withRowHighlight
      minRows={1}
      rowHeight={50}
      headerRowHeight={30}
      groupable={false}
      sortable={false}
      overscanCountHorizontal={99}
      className={`tree-table ${styles.table}`}
      title={t('table-title')}
      isLoading={isLoading}
      isError={isError}
      columns={columnDefinitions}
      data={mappedData}
      nrOfEntries={subRowCount ?? 0}
      NoDataComponent={() => (
        <AnalyticalTableNoDataComponent
          isLoading={isLoading}
          isError={isError}
          tableId="collateral-agreement-table"
        />
      )}
      reactTableOptions={{
        enableExpanding: false,
        initialState: {
          expanded: allExpanded,
        },
      }}
    />
  )
}

CollateralAgreementTable.propTypes = {
  id: PropTypes.string.isRequired,
  hooks: PropTypes.object.isRequired,
  translationKey: PropTypes.string.isRequired,
}

export default CollateralAgreementTable
