import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const UNKNOWN = 'UNKNOWN'

const DecisionPaperTileErrorDetails = ({ sections, tileId, error }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper.tile-error-details',
  })

  const tileInformation = useMemo(() => {
    for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
      const section = sections[sectionIndex]
      const matchingSectionTile = section.tiles.find((tile) => tile.id === tileId)

      if (matchingSectionTile) {
        return t('tile-in-section.info', {
          sectionName: section.name,
          tileName: matchingSectionTile.title,
          tileId: tileId,
        })
      }

      for (
        let subSectionIndex = 0;
        subSectionIndex < section.subsections.length;
        subSectionIndex++
      ) {
        const subSection = section.subsections[subSectionIndex]
        const matchingSubsectionTile = subSection.tiles.find((tile) => tile.id === tileId)

        if (matchingSubsectionTile) {
          return t('tile-in-subsection.info', {
            sectionName: section.name,
            subsectionName: subSection.name,
            tileName: matchingSubsectionTile.title,
            tileId: tileId,
          })
        }
      }
    }
    return t('tile-not-found', { tileId: tileId })
  }, [sections, t, tileId])

  const errorInformation = useMemo(
    () =>
      t('error', {
        code: error?.code ?? UNKNOWN,
        description: error?.title ?? UNKNOWN,
      }),
    [error, t],
  )

  return (
    <>
      {tileInformation}
      {errorInformation}
    </>
  )
}

DecisionPaperTileErrorDetails.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      tiles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          code: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        }),
      ).isRequired,
      subsections: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          tiles: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              code: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
            }),
          ).isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  tileId: PropTypes.string.isRequired,
  error: PropTypes.shape({
    code: PropTypes.string,
    title: PropTypes.string,
  }),
}

export default DecisionPaperTileErrorDetails
