import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SyndicationInformationRow from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/SyndicationInformationRow'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/hooks/i18n/useI18n'
import usePeriodFields from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/routes/deals/syndication/syndicationIntention/fields/period/usePeriodFields'
import useSellingAmountShareField from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/routes/deals/syndication/syndicationIntention/fields/sellingAmountShare/useSellingAmountShareFields'
import useSellingAmountTotalFields from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/routes/deals/syndication/syndicationIntention/fields/sellingAmountTotal/useSellingAmountTotalFields'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/ui/card/DisplayCardViewItemView'

const OpsMemoSyndicationPostSaleTile = ({ tileId }) => {
  const tileData = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const { syndicationIntentionPostSale: data } = tileData?.data || {}

  const { t } = useTranslation('decisionPaper')
  const { format } = useShortDateFormatter()

  const postSaleSellingAmountTotalFields = useSellingAmountTotalFields({
    labelCode: 'decision-paper.post-sale-selling-amount-total',
    name: 'postSaleSellingAmountTotal',
    isShownInDisplay: true,
    sellingAmountTotal: {
      amount: data?.sellingAmountTotal?.amount,
      currency: data?.sellingAmountTotal?.currency,
    },
    sellingAmountTotalHeadquarter: {
      amount: data?.sellingAmountTotalHeadquarter?.amount,
      currency: data?.sellingAmountTotalHeadquarter?.currency,
    },
  })

  const postSaleSellingAmountShareField = useSellingAmountShareField({
    labelCode: 'decision-paper.post-sale-selling-amount-share',
    name: 'postSaleSellingAmountShare',
    isShownInDisplay: true,
    sellingAmountShare: data?.sellingAmountShare,
  })

  const periodFields = usePeriodFields({
    name: 'postSalePeriod',
    isShownInDisplay: true,
    periodReference: {
      period: data?.period,
      referenceDateTypeCode: data?.referenceDateTypeCode,
      referenceDateShortText: data?.referenceDateShortText,
    },
  })

  const deadlineField = {
    label: t('tiles.syndications.intention-card.post-sale-deadline'),
    name: 'postSaleDeadline',
    value: data?.deadline ? format(data?.deadline) : '',
    isShownInDisplay: true,
  }

  return (
    <>
      <SyndicationInformationRow
        label={postSaleSellingAmountTotalFields[0].label}
        value={postSaleSellingAmountTotalFields[0].value}
        isFirstItem
      />
      {data?.sellingAmountTotalHeadquarter?.currency !== data?.sellingAmountTotal?.currency && (
        <DisplayCardViewItemView>
          <>{postSaleSellingAmountTotalFields[1].customInfoComponent}</>
        </DisplayCardViewItemView>
      )}
      <SyndicationInformationRow
        label={postSaleSellingAmountShareField.label}
        value={postSaleSellingAmountShareField.value}
      />
      <SyndicationInformationRow label={periodFields.label} value={periodFields.value} />
      <SyndicationInformationRow label={deadlineField.label} value={deadlineField.value} />
    </>
  )
}

OpsMemoSyndicationPostSaleTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default OpsMemoSyndicationPostSaleTile
