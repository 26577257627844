import { BusyIndicator, BusyIndicatorSize } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

const LoadingContent = ({
  contentKey,
  busyIndicatorSize,
  isLoading = false,
  isError = false,
  errorContent,
  children,
}) => {
  if (isLoading)
    return (
      <BusyIndicator
        id={`loading-content-${contentKey}`}
        active
        size={busyIndicatorSize ? busyIndicatorSize : BusyIndicatorSize.Small}
        style={{ padding: '4px', textAlign: 'center', display: 'block' }}
      />
    )

  if (isError) return errorContent || <></>

  return children
}
LoadingContent.propTypes = {
  contentKey: PropTypes.string.isRequired,
  busyIndicatorSize: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  errorContent: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}
export default LoadingContent
