import { CardHeader } from '@fioneer/ui5-webcomponents-react'
import '@ui5/webcomponents-icons/dist/AllIcons.js'
import '@ui5/webcomponents/dist/Assets.js'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { eventStatus } from 'api/events/status'
import EventsSummaryCardTable from 'components/domains/business-events-and-tasks/events/events-summary-card/EventsSummaryCardTable'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useEvents } from 'hooks/services/business-events-and-tasks/events/useEvents'
import paths from 'routes/paths'

const maxEventsAndTasks = 5
const sorting = {
  sortBy: 'event_summary',
  orderBy: 'asc',
}
const emptyString = ''

const UserEventsSummaryCard = ({ eventAssigneeId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.events.user-events-summary-card',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const filterOptions = {
    assigneeId: eventAssigneeId,
    status: [eventStatus.drafted, eventStatus.ongoing],
  }
  const paging = { limit: maxEventsAndTasks }
  const navigate = useNavigate()

  const {
    isLoading: isEventsLoading,
    isError: isEventsError,
    data: eventsData,
  } = useEvents(sorting, filterOptions, paging)

  const titleLinkPath = useMemo(
    () =>
      `/${paths.businessEventsAndTasks}?status=${eventStatus.drafted},${eventStatus.ongoing}&assigneeId=${eventAssigneeId}`,
    [eventAssigneeId],
  )

  const handleOnHeaderClick = useCallback(() => navigate(titleLinkPath), [navigate, titleLinkPath])

  const eventsCardHeader = useMemo(() => {
    const isErrorText = isEventsError
      ? tNoPrefix('app.loading.error.data-unavailable')
      : emptyString
    const total = eventsData?.total ?? isErrorText

    return (
      <CardHeader
        titleText={t('header.title')}
        subtitleText={t('header.subtitle', { total })}
        interactive
        onClick={handleOnHeaderClick}
      />
    )
  }, [eventsData?.total, handleOnHeaderClick, isEventsError, t, tNoPrefix])

  const renderEventsContent = useCallback(
    () => (
      <EventsSummaryCardTable
        shownColumns={['name', 'businessObject', 'businessObjectType', 'dueDate', 'status']}
        events={eventsData.events}
        noDataText={t('no-data-text')}
        additionalActions={[]}
      />
    ),
    [eventsData?.events, t],
  )

  return (
    <Card header={eventsCardHeader}>
      <RequestStateResolver
        isLoading={isEventsLoading}
        isError={isEventsError}
        renderContent={renderEventsContent}
        errorToDisplay={<ErrorDataUnavailableInContent />}
      />
    </Card>
  )
}

UserEventsSummaryCard.propTypes = {
  eventAssigneeId: PropTypes.string.isRequired,
}

export default UserEventsSummaryCard
