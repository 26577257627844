import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Form,
  FormItem,
  Input,
  Label,
  TextArea,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DocumentUpload from 'components/domains/documents/DocumentUpload'
import Dialog, { DialogPrimaryButton } from 'components/ui/dialog/Dialog'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import styles from 'routes/documents/document-versions/AddDocumentVersionDialog.module.css'

const maxLengthComment = 255

const CustomFormItem = ({ children }) => (
  <FormItem>
    <FlexBox direction={FlexBoxDirection.Column} className={styles.uploadFormItem}>
      {children}
    </FlexBox>
  </FormItem>
)
CustomFormItem.propTypes = {
  children: PropTypes.node.isRequired,
}

const getFileExtension = (filename) => (filename?.includes('.') ? filename.split('.').at(-1) : '')

const AddDocumentVersionDialog = ({
  initialState: { documentName, documentType, keyDate, file: initialFile },
  open = false,
  onAfterClose,
  onUploadNewVersion,
}) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('', { keyPrefix: 'pages.documents.detail.versions-table' })
  const { format: dateFormat } = useShortDateFormatter()

  const [comment, setComment] = useState('')
  const [fileName, setFileName] = useState(initialFile?.name ?? '')
  const [fileSize, setFileSize] = useState(initialFile?.size ?? '-')
  const [fileType, setFileType] = useState(getFileExtension(initialFile?.name) ?? '-')
  const [file, setFile] = useState(initialFile)

  /** @param {File[]} files */
  const handleFilesChange = (files) => {
    if (!files?.length) return
    setFile(files[0])
    setFileSize(files[0].size)

    const newFileName = files[0].name
    setFileName(newFileName)
    setFileType(newFileName.includes('.') ? newFileName.split('.').at(-1) : '')
  }

  const handleCommentChange = ({ target }) => {
    setComment(target.value ?? '')
  }

  const handleAddClick = () => {
    onUploadNewVersion({ file, comment })
    onAfterClose?.()
  }

  return (
    <Dialog
      open={open}
      headerText={t('add-version')}
      onAfterClose={() => onAfterClose?.()}
      primaryButton={
        <DialogPrimaryButton onClick={handleAddClick} disabled={!file}>
          {tNoPrefix('buttons.add')}
        </DialogPrimaryButton>
      }
    >
      <Form onSubmit={({ preventDefault }) => preventDefault()}>
        <FlexBox
          justifyContent={FlexBoxJustifyContent.Start}
          direction={FlexBoxDirection.Column}
          className={styles.uploadForm}
        >
          <FormItem>
            <DocumentUpload
              fileName={fileName}
              fileType={fileType}
              fileSize={`${fileSize}`}
              onHandleFileUpload={(e) => handleFilesChange(e.detail.files)}
            />
          </FormItem>
          <CustomFormItem>
            <Label showColon>{t('form.label.document-name')}</Label>
            <Input value={documentName} readonly />
          </CustomFormItem>
          <CustomFormItem>
            <Label showColon>{t('form.label.document-type')}</Label>
            <Input value={documentType} readonly />
          </CustomFormItem>

          <CustomFormItem>
            <Label showColon>{t('form.label.key-date')}</Label>
            <Input value={dateFormat(keyDate)} readonly />
          </CustomFormItem>
          <CustomFormItem>
            <Label showColon>{t('form.label.comment')}</Label>
            <TextArea
              value={comment}
              onInput={handleCommentChange}
              maxlength={maxLengthComment}
              onFocus={(e) => e.stopPropagation()}
            />
          </CustomFormItem>
        </FlexBox>
      </Form>
    </Dialog>
  )
}

AddDocumentVersionDialog.propTypes = {
  initialState: PropTypes.shape({
    documentName: PropTypes.string.isRequired,
    documentType: PropTypes.string.isRequired,
    keyDate: PropTypes.string.isRequired,
    file: PropTypes.object,
  }).isRequired,
  open: PropTypes.bool,
  onAfterClose: PropTypes.func.isRequired,
  onUploadNewVersion: PropTypes.func.isRequired,
}

export default AddDocumentVersionDialog
