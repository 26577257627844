import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import EventCreationPage from 'components/domains/business-events-and-tasks/events/creation/EventCreationPage'
import ErrorScreen from 'components/ui/screens/ErrorScreen'

const EventCreationQueryParametersWrapper = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.events.creation.query',
  })
  const [query] = useSearchParams()
  const entityId = query.get('entity_id')
  const entityType = query.get('entity_type')
  const eventType = query.get('event_type')

  if (!entityId || !entityType || !eventType) {
    return <ErrorScreen title={t('error.title')} description={t('error.description')} />
  }

  return <EventCreationPage entityId={entityId} entityType={entityType} eventType={eventType} />
}
EventCreationQueryParametersWrapper.propTypes = {}

export default EventCreationQueryParametersWrapper
