import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  ComposedChart,
  Line,
  Bar,
  ResponsiveContainer,
  ReferenceLine,
  Tooltip,
} from 'recharts'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared/PropertyRentRollOverviewExpiringLeasesChart.module.css'
import {
  useLanguage,
  useNumberFormatter,
  usePercentageFormatter,
  useCurrencyFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared/hooks/i18n/useI18n'
import useChartAnimation from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared/useChartAnimation'
import sharedChartStyles from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/ui/shared-chart-styles.module.css'

const fillColors = new Map([
  ['percentRemaningBreak', '#C52C4A'],
  ['percentRemaningExpiry', '#9B3285'],
  ['currentRentExpiringBreak', '#DB577C'],
  ['currentRentExpiringExpiry', '#BE60AD'],
])

const strokeColor = '#FFF'

const ONE_MILLION = 1_000_000

const PropertyRentRollOverviewExpiringLeasesChart = ({
  currentRent,
  expiringLeases,
  currency,
  todayXAxisPosition,
  disableAnimation = false,
}) => {
  const { t: tExpiringLeasesChart } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.property.rent-roll.overview.expiring-leases.chart',
  })

  const animationProps = useChartAnimation(!disableAnimation)

  const formatYAxisPercent = usePercentageFormatter()
  const formatNumber = useNumberFormatter()
  const formatYAxisRent = useCallback(
    (value) => {
      const valueInMillions = value / ONE_MILLION
      return formatNumber(valueInMillions)
    },
    [formatNumber],
  )
  const rightYAxisTicks = useMemo(
    () => [
      /* eslint-disable no-magic-numbers */
      0,
      currentRent * 0.2,
      currentRent * 0.4,
      currentRent * 0.6,
      currentRent * 0.8,
      currentRent,
      /* eslint-enable no-magic-numbers */
    ],
    [currentRent],
  )

  const formatTooltipPercent = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formatTooltipRent = useCurrencyFormatter({ currency })
  const formatTooltipValue = useCallback(
    (value, name, { dataKey }) => {
      switch (dataKey) {
        case 'percentRemaningBreak':
        case 'percentRemaningExpiry':
          return formatTooltipPercent(value)
        case 'currentRentExpiringBreak':
        case 'currentRentExpiringExpiry':
          return formatTooltipRent(value)
        default:
          return value
      }
    },
    [formatTooltipPercent, formatTooltipRent],
  )

  const language = useLanguage()

  const localizedMillionCompact = useMemo(
    () =>
      Intl.NumberFormat(language, { notation: 'compact' })
        .formatToParts(ONE_MILLION)
        .find(({ type }) => type === 'compact')?.value ?? 'M',
    [language],
  )

  return (
    <div className={styles.container}>
      <div className={styles.leftYAxisDescription}>
        {tExpiringLeasesChart('y-axis.remaining-volume')}
      </div>
      <ResponsiveContainer width="100%" aspect={2.1} maxHeight={400}>
        <ComposedChart
          data={expiringLeases}
          margin={{ top: 8, bottom: 0, left: 8, right: 8 }}
          barGap={2}
          barCategoryGap="5%"
          className={`${sharedChartStyles.cwpChart} ${styles.expiringLeasesChart}`}
        >
          <CartesianGrid vertical={false} />
          <YAxis
            axisLine={false}
            tickFormatter={formatYAxisPercent}
            yAxisId="left"
            tickLine={false}
            tickCount={6}
            type="number"
          />
          <YAxis
            axisLine={false}
            tickFormatter={formatYAxisRent}
            ticks={rightYAxisTicks}
            allowDecimals
            yAxisId="right"
            orientation="right"
            domain={[0, currentRent]}
            tickLine={false}
            type="number"
          />
          <XAxis dataKey="name" tickLine={false} axisLine={false} />
          <XAxis hide type="number" domain={[0, 1]} xAxisId="todayIndicatorReferenceAxis" />

          <Legend />

          <Tooltip formatter={formatTooltipValue} />

          <Bar
            dataKey="currentRentExpiringBreak"
            fill={fillColors.get('currentRentExpiringBreak')}
            name={tExpiringLeasesChart('legend.net-rent-expiring-break')}
            yAxisId="right"
            {...animationProps}
          />
          <Bar
            dataKey="currentRentExpiringExpiry"
            fill={fillColors.get('currentRentExpiringExpiry')}
            name={tExpiringLeasesChart('legend.net-rent-expiring-expiry')}
            yAxisId="right"
            {...animationProps}
          />

          {todayXAxisPosition !== undefined && (
            <ReferenceLine
              x={todayXAxisPosition}
              xAxisId="todayIndicatorReferenceAxis"
              yAxisId="left"
              className={sharedChartStyles.todayLine}
            />
          )}

          <Line
            dataKey="percentRemaningBreak"
            fill={fillColors.get('percentRemaningBreak')}
            stroke={fillColors.get('percentRemaningBreak')}
            name={tExpiringLeasesChart('legend.remaining-volume-break')}
            yAxisId="left"
            dot={{ stroke: strokeColor, r: 4 }}
            {...animationProps}
          />
          <Line
            dataKey="percentRemaningExpiry"
            fill={fillColors.get('percentRemaningExpiry')}
            stroke={fillColors.get('percentRemaningExpiry')}
            name={tExpiringLeasesChart('legend.remaining-volume-expiry')}
            yAxisId="left"
            dot={{ stroke: strokeColor, r: 4 }}
            {...animationProps}
          />
        </ComposedChart>
      </ResponsiveContainer>

      <div className={styles.rightYAxisDescription}>
        {tExpiringLeasesChart('y-axis.net-rent-expiring', {
          millionCompact: localizedMillionCompact,
          currency,
        })}
      </div>
    </div>
  )
}

PropertyRentRollOverviewExpiringLeasesChart.propTypes = {
  currentRent: PropTypes.number.isRequired,
  expiringLeases: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      percentRemaningBreak: PropTypes.number.isRequired,
      percentRemaningExpiry: PropTypes.number.isRequired,
      currentRentExpiringBreak: PropTypes.number.isRequired,
      currentRentExpiringExpiry: PropTypes.number.isRequired,
    }),
  ).isRequired,
  currency: PropTypes.string.isRequired,
  todayXAxisPosition: PropTypes.number,
  disableAnimation: PropTypes.bool,
}

export default PropertyRentRollOverviewExpiringLeasesChart
