import { useRequest } from 'hooks/services/baseService'

/**
 * Returns all property typecode keys and their localized display name
 * The Typecodes structure is as follows:
 * {
 *   "segment_usage_types": [
 *       {
 *           "key": "000001",
 *           "display_name": "Retail"
 *       },
 *       [...]
 *   ]
 * }
 * @returns {{isLoading: boolean, isFetching: boolean, isError: boolean, data: SegmentUsageTypes}}
 *
 */
export const useSegmentUsageTypes = (options) => {
  const { isLoading, isFetching, isError, data } = useRequest({
    path: `/properties/segment-usage-types`,
    translated: true,
    options,
  })
  return {
    isLoading,
    isFetching,
    isError,
    data: data?.segment_usage_types ?? [],
  }
}

export const useSegmentUsageTypesInSpecifiedLanguage = (language, options) => {
  const { isLoading, isFetching, isError, data } = useRequest({
    path: `/properties/segment-usage-types`,
    translated: false,
    keys: ['properties', 'segment-usage-types', language],
    requestOptions: { headers: { 'Accept-Language': language } },
    options,
  })

  return {
    isLoading,
    isFetching,
    isError,
    data: data?.segment_usage_types ?? [],
  }
}
