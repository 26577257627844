import { CardHeader } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { eventStatus } from 'api/events/status'
import TasksSummaryTable from 'components/domains/business-events-and-tasks/tasks/tasks-summary/TasksSummaryTable'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useTasks } from 'hooks/services/business-events-and-tasks/tasks/useTasks'
import paths from 'routes/paths'

const maxTasks = 5
const sorting = {
  sortBy: 'task_summary',
  orderBy: 'asc',
}
const emptyString = ''

const UserTasksSummaryCard = ({ taskAssigneeId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.tasks.tasks-summary.user-tasks-summary-card',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const filter = { eventStatus: eventStatus.ongoing, taskAssigneeId }
  const navigate = useNavigate()

  const {
    isLoading: isTasksLoading,
    isError: isTasksError,
    data: tasksData,
  } = useTasks(sorting, filter, { limit: maxTasks })

  const titleLinkPath = useMemo(
    () =>
      `/${paths.businessEventsAndTasks}/?event_status=${eventStatus.ongoing}&task_assignee=${taskAssigneeId}#tasks`,
    [taskAssigneeId],
  )

  const handleOnHeaderClick = useCallback(() => navigate(titleLinkPath), [navigate, titleLinkPath])

  const tasksCardHeader = useMemo(() => {
    const isErrorText = isTasksError ? tNoPrefix('app.loading.error.data-unavailable') : emptyString
    const total = tasksData?.total ?? isErrorText

    return (
      <CardHeader
        titleText={t('header.title')}
        subtitleText={t('header.subtitle', { total })}
        interactive
        onClick={handleOnHeaderClick}
      />
    )
  }, [handleOnHeaderClick, isTasksError, t, tNoPrefix, tasksData?.total])

  const renderTasksContent = useCallback(
    () => (
      <TasksSummaryTable
        shownColumns={[
          'name',
          'businessEvent',
          'businessObject',
          'businessObjectType',
          'dueDate',
          'status',
        ]}
        tasks={tasksData.tasks}
        additionalActions={[]}
      />
    ),
    [tasksData?.tasks],
  )

  return (
    <Card header={tasksCardHeader}>
      <RequestStateResolver
        isLoading={isTasksLoading}
        isError={isTasksError}
        renderContent={renderTasksContent}
        errorToDisplay={<ErrorDataUnavailableInContent />}
      />
    </Card>
  )
}

UserTasksSummaryCard.propTypes = {
  taskAssigneeId: PropTypes.string.isRequired,
}

export default UserTasksSummaryCard
