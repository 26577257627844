import { Label, Text } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DualMeasurementValue from 'components/domains/business-partners/dual-unit-value/DualMeasurementValue'
import styles from 'components/domains/business-partners/tile/tenancy/as-branding-or-franchise-provider/RentedAreaCell.module.css'
import { useNumberFormatter, usePercentageFormatter } from 'hooks/i18n/useI18n'

const propTypes = {
  rentedArea: PropTypes.number,
  measurementUnit: PropTypes.string,
  share: PropTypes.number,
  unit: PropTypes.number,
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const RentedAreaCell = ({ rentedArea, measurementUnit, share, unit }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.tenancy.as-branding-franchise-provider',
  })
  const formatPercent = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formatNumber = useNumberFormatter({ maximumFractionDigits: 0, minimumFractionDigits: 0 })

  if (isNil(share) && isNil(unit) && isNil(measurementUnit) && isNil(rentedArea)) return null

  return (
    <div className={styles.rentedAreaCell}>
      {!isNil(measurementUnit) && !isNil(rentedArea) && (
        <DualMeasurementValue primaryMeasurementUnit={measurementUnit} primaryValue={rentedArea} />
      )}
      <div className={styles.additionalInfo}>
        {!isNil(share) && !isNaN(share) && (
          <div className={styles.keyValuePair}>
            <Label>{t('share')}</Label> <Text>{formatPercent(share)}</Text>
          </div>
        )}
        {!isNil(unit) && (
          <div className={styles.keyValuePair}>
            <Label>{t('no')}</Label> <Text>{formatNumber(unit)}</Text>
          </div>
        )}
      </div>
    </div>
  )
}

RentedAreaCell.propTypes = propTypes

export default RentedAreaCell
