import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/covenants-ratios/MultipleCovenantsRatiosCovenantChecksMonitoringTile.module.css'
import useCovenantChecksTableColumnDefinitions from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/covenants-ratios/useCovenantChecksTableColumnDefinitions'
import useCovenantChecksTableData from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/covenants-ratios/useCovenantChecksTableData'
import useCovenantChecksTableEnumns from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/covenants-ratios/useCovenantChecksTableEnumns'
import SortedTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/periodical-checks/checks/table/shared/sorted-tables/SortedTable'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useDecisionPaperLinkConfirmationDialog from 'hooks/services/business-events-and-tasks/decision-papers/useDecisionPaperLinkConfirmationDialog'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import paths from 'routes/paths'

const covenantCheckStatusToFilter = ['ACTIVE', 'PLANNED']

const MultipleCovenantsRatiosCovenantChecksMonitoringTile = ({
  tileId,
  showConfirmationForLinks,
  isPdfView,
  selectedDealIndex,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.covenants-rations.covenant-checks',
  })
  const { t: tNoPrefix } = useTranslation('decisionPaper')

  const {
    data: {
      multipleCovenantChecksData = [],
      multipleDealsByUuidMiniData = [],
      multipleEventsData = [],
      dealsData = [],
    } = {},
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const selectedDealDisplayId = dealsData[selectedDealIndex]?.dealDisplayId

  const covenantChecksData = useMemo(
    () => multipleCovenantChecksData?.[selectedDealIndex]?.covenantChecks ?? [],
    [multipleCovenantChecksData, selectedDealIndex],
  )

  useEffect(() => {
    dispatch(
      setSourceRender({
        tileId,
        sourceRender: { dealDisplayId: selectedDealDisplayId },
      }),
    )
  }, [dispatch, selectedDealDisplayId, tileId])

  const enumnsForFiltering = useCovenantChecksTableEnumns({ data: covenantChecksData, t })
  const columnDefinitions = useCovenantChecksTableColumnDefinitions({
    t,
    entityType: cwpEntityTypes.DEAL,
    ...enumnsForFiltering,
  })

  const filteredCovenantChecksData = useMemo(
    () =>
      covenantChecksData?.filter(({ keyDate, status } = {}) => {
        const twoYearsObject = { years: 2 }
        const nowTwoYearsAgo = DateTime.now().minus(twoYearsObject)

        return (
          DateTime.fromISO(keyDate) >= nowTwoYearsAgo &&
          !covenantCheckStatusToFilter.includes(status)
        )
      }) ?? [],
    [covenantChecksData],
  )

  const tableData = useCovenantChecksTableData({
    data: filteredCovenantChecksData,
    allDealsData: multipleDealsByUuidMiniData,
    allEventsData: multipleEventsData,
  })

  const handleOnRowClick = useCallback(({ detail: { row: clickedTableRow } }) => {
    const covenantCheckUuid = clickedTableRow.getAttribute('covenant-check-uuid')
    const monitoringReferenceEntityType = clickedTableRow.getAttribute(
      'monitoring-reference-entity-type',
    )
    const monitoringReferenceId = clickedTableRow.getAttribute('monitoring-entity-display-id')
    let navigationLink
    switch (monitoringReferenceEntityType) {
      case 'DEAL':
        navigationLink = `/${paths.deals}/${monitoringReferenceId}/${dealDetailPaths.covenants}/${dealDetailPaths.covenantChecks}/${covenantCheckUuid}`
        break
      case 'BUSINESSPARTNER':
        navigationLink = `/${paths.businessPartners}/${monitoringReferenceId}/covenants/covenant-checks/${covenantCheckUuid}`
        break
    }

    window.open(navigationLink, '_blank')
  }, [])

  const { showConfirmationDialog } = useDecisionPaperLinkConfirmationDialog({
    onConfirm: handleOnRowClick,
  })

  return (
    <div className={styles.tableWrapper}>
      <SortedTable
        columnDefinitions={
          isPdfView
            ? columnDefinitions.map((column) => ({
                ...column,
                filter: undefined,
              }))
            : columnDefinitions
        }
        tableData={tableData}
        toolbarConfig={{
          title: t('table-title'),
          showColumnSelection: !isPdfView,
          sorting: isPdfView
            ? undefined
            : {
                columnKey: 'key-date',
                isSortingAscending: false,
              },
        }}
        noDataText={tNoPrefix('components.table.no-data')}
        additionalTableProperties={{
          onRowClick: showConfirmationForLinks ? showConfirmationDialog : handleOnRowClick,
        }}
      />
    </div>
  )
}

MultipleCovenantsRatiosCovenantChecksMonitoringTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultipleCovenantsRatiosCovenantChecksMonitoringTile
