import PropTypes, { func, object, string } from 'prop-types'
import { Fragment, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { DealCreationReviewRow } from 'components/domains/deals/creation-dialog/review-step/DealCreationReviewRow'
import { useDealTypes } from 'hooks/services/deals/configurations/useBusinessSegments'
import { useOriginationTeams } from 'hooks/services/deals/configurations/useOriginationTeams'

export const DealCreationReviewGeneralInformation = ({
  businessSegmentCode,
  originationTeamCode,
  dealTypeCode,
  dealName,
  accountManager: { staffMember = {} } = {},
  comment,
  onEdit,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.dialog.general-information',
  })
  const { data: dealTypesConfig } = useDealTypes(businessSegmentCode)

  const { data: originationTeams = [] } = useOriginationTeams(businessSegmentCode)

  const getOriginationTeam = useCallback(
    (code) => originationTeams.find((originationTeam) => originationTeam.code === code) ?? {},
    [originationTeams],
  )

  const getDealTypeName = (dealType) => dealTypesConfig.find(({ code }) => dealType === code)?.name

  return (
    <Fragment>
      <DealCreationReviewRow
        id="deal-creation-review-general-information-business-segment"
        label={t('business-segment.label')}
        text={businessSegmentCode}
      />
      <DealCreationReviewRow
        id="deal-creation-review-general-information-deal-name"
        label={t('deal-name.label')}
        text={dealName}
      />
      <DealCreationReviewRow
        id="deal-creation-review-general-information-deal-type"
        label={t('deal-type.label')}
        text={getDealTypeName(dealTypeCode)}
      />
      <DealCreationReviewRow
        id="deal-creation-review-general-information-account-manager"
        label={t('account-manager.label')}
        text={staffMember.fullName}
      />
      <DealCreationReviewRow
        id="deal-creation-review-general-information-team"
        label={t('team.label')}
        text={staffMember.team}
      />
      <DealCreationReviewRow
        id="deal-creation-review-general-information-origination-team"
        label={t('origination-team.label')}
        text={getOriginationTeam(originationTeamCode).name ?? ''}
      />
      <DealCreationReviewRow
        id="deal-creation-review-general-information-origination-platform"
        label={t('origination-platform.label')}
        text={getOriginationTeam(originationTeamCode).platform ?? ''}
      />
      <DealCreationReviewRow
        id="deal-creation-review-general-information-comment"
        label={t('comment.label')}
        text={comment}
        onEdit={onEdit}
      />
    </Fragment>
  )
}

DealCreationReviewGeneralInformation.propTypes = {
  businessSegmentCode: string,
  originationTeamCode: PropTypes.string,
  dealTypeCode: string,
  dealName: string,
  accountManager: object,
  comment: string,
  onEdit: func.isRequired,
}
