import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Link,
} from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import styles from 'components/ui/tables/cells/EntityCell.module.css'

const propTypes = {
  /** The entity's name shown as the topmost label of the entity, and, if present, the body of the link. */
  name: PropTypes.string,
  /** The entity's ID show below the name. Usually a display ID in case of deals and business partners. */
  id: PropTypes.string,
  /**
   * A link pointing to the entity's details page.
   *
   * If the link does not start with a slash '/', the link string will only be appended to the current URL.
   */
  link: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.string]),
  /**
   * Options to modify how the Cell will look like.
   *
   * - `isNameBold` Display the name in bold, regardless of whether a link is provided. Defaults to true.
   * - `openInNewTab` Will open the provided link in a new tab. Defaults to false.
   * - `overflowMarginTreeLevel` If an Analytical Tree Table is used and the entity is shown as the leftmost column,
   *                              the expand/collapse toggle needs to be taken into account for overflow handling/margin.
   *                              This number specifies the level/depth the entity has in the tree table. Defaults to 0.
   * - `forceAddLeftMargin` Adds a margin that fixes rows that are used with `isSectionHeader` but have subRows. The
   *                        property `isSectionHeader` was added to the ui/webcomponents-react's fork and was originally
   *                        only meant for section headers without subRows.
   */
  options: PropTypes.shape({
    isNameBold: PropTypes.bool,
    openInNewTab: PropTypes.bool,
    overflowMarginTreeLevel: PropTypes.number,
    forceAddLeftMargin: PropTypes.bool,
  }),
  /** Defines whether a loading spinner is shown. */
  isLoading: PropTypes.bool,
  /** Defines whether a loading spinner is shown. */
  isFetching: PropTypes.bool,
  /** Defines whether an error is shown. */
  isError: PropTypes.bool,
  /** Allows to define a custom error component. Defaults to undefined so that `<ErrorDataUnavailableInCell />` is shown. */
  errorToDisplay: PropTypes.node,
  /** Defines additional styling to the outmost div of the cell's content. */
  className: PropTypes.string,
  /** Defines additional styling to the link of the cell. */
  classNameForLink: PropTypes.string,
}

/**
 * Shared Entity Cell for usage within the Responsive and the Analytical Table.
 *
 * It can be used to display entities, like Business Partners, Deals, and Properties,
 * but also sub-entities, like Tranches oder Drawdowns.
 *
 * @typedef Props
 * @property {String} name The entity's name shown as the topmost label of the entity, and, if present, the body of the link.
 * @property {String} id The entity's ID show below the name. Usually a display ID in case of deals and business partners.
 * @property {String} link A link pointing to the entity's details page.
 * @property {Object} options Options to modify how the Cell will look like.
 * @property {Boolean} options.isNameBold Display the name in bold, regardless of whether a link is provided. Defaults to true.
 * @property {Boolean} options.openInNewTab Will open the provided link in a new tab. Defaults to false.
 * @property {Number} options.overflowMarginTreeLevel Defines the level/depth the entity has in a tree table. Defaults to 0.
 * @property {Boolean} [isLoading] Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} [isFetching] Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} [isError] Defines whether an error is shown.
 * @property {React.ReactNode} [errorToDisplay] Allows to define a custom error component. Defaults to undefined so that <ErrorDataUnavailableInCell /> is shown.
 * @property {String} [className] Defines additional styling to the outmost div of the cell's content.
 * @property {String} [classNameForLink] Defines additional styling to the link of the cell.
 */

/**
 * @param {Props} props Note for Storybook: cf. code for complete JSDoc
 */
const EntityCell = ({
  name,
  link,
  id,
  options: {
    isNameBold = true,
    openInNewTab = false,
    overflowMarginTreeLevel = 0,
    forceAddLeftMargin = false,
  } = {},
  isLoading = false,
  isFetching = false,
  isError = false,
  className,
  classNameForLink,
  errorToDisplay,
}) => {
  const renderLinkAndName = () => {
    if (link && name) {
      return (
        <Link
          href={link}
          target={openInNewTab ? '_blank' : undefined}
          wrappingType="None"
          className={compact([
            styles.textWithOverflowHandling,
            isNameBold && styles.textBold,
            classNameForLink,
          ]).join(' ')}
        >
          {name}
        </Link>
      )
    } else if (name) {
      return (
        <div
          className={compact([styles.textWithOverflowHandling, isNameBold && styles.textBold]).join(
            ' ',
          )}
        >
          {name}
        </div>
      )
    }
  }

  const renderContent = () => (
    <FlexBox
      direction={FlexBoxDirection.Column}
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      className={compact([
        forceAddLeftMargin && styles.firstColumnTreeTableMarginAdjustment,
        styles.entityWrapper,
        !!overflowMarginTreeLevel && styles[`overflowMarginTreeLevel-${overflowMarginTreeLevel}`],
        className,
      ]).join(' ')}
    >
      {renderLinkAndName()}
      {!!id && <span className={styles.textWithOverflowHandling}>{id}</span>}
    </FlexBox>
  )

  return (
    <SmallLoadingWrapper
      isLoading={isLoading || isFetching}
      isError={isError}
      error={errorToDisplay ?? <ErrorDataUnavailableInCell />}
      renderContent={renderContent}
    />
  )
}

EntityCell.propTypes = propTypes

export default EntityCell
