import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ConditionsCovenantCheckLink from 'components/domains/conditions/overview/table-cells/generic/ConditionsCovenantCheckLink'
import styles from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableCovenantCheckSubcomponent.module.css'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useCovenantById from 'hooks/services/deals/covenants/useCovenantById'
import useCovenantCheckById from 'hooks/services/deals/covenants/useCovenantCheckById'
import { requirementRowHasError } from 'redux/slices/conditions/requirementsTableSlice'

const RequirementsTableCovenantCheckSubcomponent = ({ requirementId, covenantCheckId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.subcomponents.covenant-check',
  })
  const dispatch = useDispatch()
  const {
    isLoading: isCovenantCheckLoading,
    isError: isCovenantCheckError,
    data: covenantCheck,
  } = useCovenantCheckById(covenantCheckId)
  const covenantId = covenantCheck?.covenantMonitoringItems[0]?.covenant.covenantUuid
  const {
    isLoading: isCovenantLoading,
    isError: isCovenantError,
    data: covenant,
  } = useCovenantById(covenantId)
  const covenantDefinitionCheckType = covenant?.covenantDefinition?.covenantDefinitionCheckType
  const isError = isCovenantCheckError || isCovenantError

  useEffect(() => {
    if (isError) {
      dispatch(requirementRowHasError({ id: requirementId, error: { hasError: true } }))
    }
  }, [dispatch, requirementId, isError])

  const renderContent = useCallback(
    () => (
      <ConditionsCovenantCheckLink
        covenantCheckId={covenantCheckId}
        covenantCheckName={covenantCheck?.name}
        covenantDefinitionCheckType={covenantDefinitionCheckType}
      />
    ),
    [covenantCheck?.name, covenantCheckId, covenantDefinitionCheckType],
  )

  if (!covenantCheckId) {
    return (
      <>
        <Label className={styles.emptyTextPadding}>{t('label')}</Label>
      </>
    )
  }

  return (
    <>
      <Label>{t('label')}</Label>
      <RequestStateResolver
        isError={isError}
        isLoading={isCovenantCheckLoading || isCovenantLoading}
        errorToDisplay={<ErrorDataUnavailableInCell />}
        renderContent={renderContent}
      />
    </>
  )
}

RequirementsTableCovenantCheckSubcomponent.propTypes = {
  requirementId: PropTypes.string,
  covenantCheckId: PropTypes.string,
}

export default RequirementsTableCovenantCheckSubcomponent
