import {
  Popover,
  PopoverPlacementType,
  PopoverHorizontalAlign,
  List,
  StandardListItem,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { decisionPaperSynchronizationStatus } from 'api/decision-paper/decisionPaperApi'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import { isEventClosed } from 'api/events/status'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import DecisionPaperChangeVersionDialog from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperChangeVersionDialog'
import DecisionPaperNewVersionDialog from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperNewVersionDialog'
import styles from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperPopovers.module.css'
import DecisionPaperTemplateInformation from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperTemplateInformation'
import DecisionPaperVersionInformation from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperVersionInformation'
import DecisionPaperStartMinorVersionListItem from 'routes/business-events-and-tasks/decision-paper/versions/minor/DecisionPaperStartMinorVersionListItem'

const DecisionPaperFrozenVersionPopover = ({
  isPopoverOpen,
  setIsPopoverOpen,
  versionName,
  majorVersionLastSyncedOn,
  minorVersionLastSyncedOn,
  lastFrozenOn,
  openerId,
  versions,
  versionNumber,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper.working-version-popover',
  })

  const {
    allowedOperations,
    event: { status },
  } = useContext(BusinessEventsAndTasksContext)

  const [isChangeVersionDialogOpen, setIsChangeVersionDialogOpen] = useState(false)
  const [isNextVersionDialogOpen, setIsNextVersionDialogOpen] = useState(false)
  const [isCreatingVersionLoading, setIsCreatingVersionLoading] = useState(false)

  const isMostRecentVersion = useMemo(
    () => versions.slice(-1)[0].version === versionNumber,
    [versionNumber, versions],
  )

  const relevantVersion = useMemo(
    () => versions.find(({ version }) => version === versionNumber),
    [versionNumber, versions],
  )

  const hasUpdatePermission = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.updateDecisionPaper),
    [allowedOperations],
  )

  const displayNewVersionOptions = useMemo(
    () => hasUpdatePermission && isMostRecentVersion && !isEventClosed(status),
    [hasUpdatePermission, isMostRecentVersion, status],
  )

  return createPortal(
    <>
      <Popover
        opener={openerId}
        open={isPopoverOpen}
        placementType={PopoverPlacementType.Bottom}
        horizontalAlign={PopoverHorizontalAlign.Left}
        onAfterClose={() => setIsPopoverOpen(false)}
        className={styles.popover}
      >
        <DecisionPaperTemplateInformation
          templateName={relevantVersion?.name}
          isCustomized={relevantVersion?.isCustomized}
          headerText={versionName}
        />
        <List className={styles.popoverBreak}>
          {/* passing 'disabled' as property to StandardListItem does unfortunately not work because it does not rerender itself when 
              the variable changes, therefore this workaround is needed */}
          {displayNewVersionOptions && !isCreatingVersionLoading && (
            <StandardListItem onClick={() => setIsNextVersionDialogOpen(true)}>
              <Text className="sapUiSmallMargin">{t('start-next-version')}</Text>
            </StandardListItem>
          )}
          {displayNewVersionOptions && isCreatingVersionLoading && (
            <StandardListItem data-testid={'create-major-version-list-element'} disabled={true}>
              <Text className="sapUiSmallMargin">{t('start-next-version')}</Text>
            </StandardListItem>
          )}
          {displayNewVersionOptions && (
            <DecisionPaperStartMinorVersionListItem
              isCreatingVersionLoading={isCreatingVersionLoading}
              setIsCreatingVersionLoading={setIsCreatingVersionLoading}
            />
          )}
          <StandardListItem onClick={() => setIsChangeVersionDialogOpen(true)}>
            <Text className="sapUiSmallMargin">{t('change')}</Text>
          </StandardListItem>
        </List>
        <DecisionPaperVersionInformation
          lastFrozenOn={lastFrozenOn}
          majorVersionLastSyncedOn={majorVersionLastSyncedOn}
          minorVersionLastSyncedOn={minorVersionLastSyncedOn}
          synchronizationStatus={decisionPaperSynchronizationStatus.frozen}
        />
      </Popover>
      <DecisionPaperChangeVersionDialog
        isOpen={isChangeVersionDialogOpen}
        setIsOpen={setIsChangeVersionDialogOpen}
        versions={versions}
        setIsPopoverOpen={setIsPopoverOpen}
      />
      <DecisionPaperNewVersionDialog
        isOpen={isNextVersionDialogOpen}
        setIsOpen={setIsNextVersionDialogOpen}
        setIsCreatingVersionLoading={setIsCreatingVersionLoading}
      />
    </>,
    document.body,
  )
}

DecisionPaperFrozenVersionPopover.propTypes = {
  isPopoverOpen: PropTypes.bool.isRequired,
  setIsPopoverOpen: PropTypes.func.isRequired,
  versionName: PropTypes.string.isRequired,
  majorVersionLastSyncedOn: PropTypes.string,
  minorVersionLastSyncedOn: PropTypes.string,
  lastFrozenOn: PropTypes.string.isRequired,
  openerId: PropTypes.string.isRequired,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      version: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  ).isRequired,
  versionNumber: PropTypes.string.isRequired,
}

export default DecisionPaperFrozenVersionPopover
