import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import merge from 'lodash.merge'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { covenantPermissions } from 'api/deals/covenantPermissions'
import PeriodicalMonitoringInformationDisplay from 'components/domains/business-partners/periodical-checks/monitoring-information/PeriodicalMonitoringInformationDisplay'
import PeriodicalMonitoringInformationEdit from 'components/domains/business-partners/periodical-checks/monitoring-information/PeriodicalMonitoringInformationEdit'
import usePeriodicalMonitoringInformationData from 'components/domains/business-partners/periodical-checks/monitoring-information/usePeriodicalMonitoringInformationData'
import useValidateMonitoringInformation from 'components/domains/business-partners/periodical-checks/monitoring-information/useValidateMonitoringInformation'
import { CardHeaderLastEditedText } from 'components/domains/deals/card/CardHeaderLastEditedText'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import Card from 'components/ui/card/Card'
import { ToggleEditMode } from 'components/ui/card/CardHeaderWithEditMode'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import useCreateCovenantMonitoring from 'hooks/services/deals/covenants/monitorings/useCreateCovenantMonitoring'
import useUpdateCovenantMonitoring from 'hooks/services/deals/covenants/monitorings/useUpdateCovenantMonitoring'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import { CovenantContext } from 'routes/business-partners/CovenantContext'

const PeriodicalMonitoringInformationCard = ({ covenantUuid, covenantDefinition }) => {
  const { id: businessPartnerId } = useContext(BusinessPartnerContext)
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-partners.periodical-checks.monitoring-card',
  })
  const { allowedOperations = [] } = useContext(CovenantContext)
  const { t: tNoPrefix } = useTranslation('translation')
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const showErrorMessageBox = useShowErrorMessageBox()

  const [isEditMode, setIsEditMode] = useState(false)
  const [formState, setFormState] = useState()
  const [isFormStateInitialized, setIsFormStateInitialized] = useState(false)
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
  const [isSaveLoading, setIsSaveLoading] = useState(false)

  const isAllowedToUpdateMonitoring = allowedOperations?.includes(
    covenantPermissions.updatePeriodicalCheckMonitoringInformation,
  )
  const { monitoringInformation, isLoading, isError } = usePeriodicalMonitoringInformationData({
    covenantUuid,
    covenantDefinition,
  })
  const isFormStateValid = useValidateMonitoringInformation({
    monitoringInformation: formState,
    covenantDefinition,
  })

  useEffect(() => {
    if (!isFormStateInitialized && !isLoading && !isError && !isSaveLoading) {
      setFormState(cloneDeep(monitoringInformation))
      setIsFormStateInitialized(true)
    }
  }, [isError, isFormStateInitialized, isLoading, isSaveLoading, monitoringInformation])

  const onCancelChanges = () => {
    setIsEditMode(false)
    setIsFormStateInitialized(false)
  }

  const onSuccess = () => {
    showToast(
      {
        children: tNoPrefix('toast.changes-saved'),
      },
      document.body,
    )
    queryClient.invalidateQueries('covenants', covenantUuid, 'monitoring')
    queryClient.invalidateQueries('covenants', 'monitoring', 'items')
    setIsEditMode(false)
    setIsFormStateInitialized(false)
    setIsSaveLoading(false)
  }
  const onError = async (error) => {
    const errorResponse = await error.response.json()
    showErrorMessageBox({
      message: t('update.error'),
      error: errorResponse,
    })
    setIsSaveLoading(false)
  }
  const updateMonitoring = useUpdateCovenantMonitoring({ onSuccess, onError })
  const createMonitoring = useCreateCovenantMonitoring({ onSuccess, onError })

  const onSaveChanges = () => {
    setIsSaveLoading(true)
    const enrichedFormState = merge(cloneDeep(formState), {
      monitoringReferenceEntity: { type: mainEntityTypes.BUSINESSPARTNER, id: businessPartnerId },
    })
    if (monitoringInformation?.covenantMonitoringUuid) {
      return updateMonitoring.mutate({
        covenantUuid: covenantUuid,
        monitoring: enrichedFormState,
      })
    }
    return createMonitoring.mutate({
      covenantUuid: covenantUuid,
      monitoring: enrichedFormState,
    })
  }

  const checkSaveDisabled = () => isEqual(monitoringInformation, formState) || !isFormStateValid

  return (
    <>
      <Card
        header={
          <CardHeaderLastEditedText
            title={t('title')}
            email={monitoringInformation?.lastUpdatedBy}
            timestamp={monitoringInformation?.lastUpdatedAt}
            actions={
              !isLoading &&
              !isError &&
              isAllowedToUpdateMonitoring && (
                <ToggleEditMode
                  isEditMode={isEditMode}
                  onToggle={() => setIsEditMode(true)}
                  onSave={() => setIsConfirmationDialogOpen(true)}
                  onCancel={onCancelChanges}
                  buttonIdPrefix="periodical-check-general-information"
                  checkSaveDisabled={checkSaveDisabled}
                  isSaveLoading={isSaveLoading}
                />
              )
            }
          />
        }
      >
        <RequestStateResolver
          center
          isLoading={isLoading}
          isError={isError}
          errorToDisplay={<ErrorDataUnavailableInContent />}
          renderContent={() =>
            isEditMode ? (
              <PeriodicalMonitoringInformationEdit
                formState={formState}
                updateFormState={setFormState}
                configurationForCovenant={covenantDefinition}
              />
            ) : (
              <PeriodicalMonitoringInformationDisplay
                monitoringInformation={monitoringInformation}
                configurationForCovenant={covenantDefinition}
              />
            )
          }
        />
      </Card>
      <ConfirmationDialog
        confirmationMessage={`${t('update.save-changes')}\n\n ${t('update.confirmation')}`}
        confirmButtonText={tNoPrefix('buttons.confirm')}
        isOpen={isConfirmationDialogOpen}
        handleConfirmation={onSaveChanges}
        handleCancel={() => setIsConfirmationDialogOpen(false)}
      />
    </>
  )
}

PeriodicalMonitoringInformationCard.propTypes = {
  covenantUuid: PropTypes.string.isRequired,
  covenantDefinition: PropTypes.shape({
    withDocumentsRequest: PropTypes.bool,
    checkCycle: PropTypes.shape({
      number: PropTypes.number,
      cycle: PropTypes.string,
    }),
  }),
}

export default PeriodicalMonitoringInformationCard
