import _ from 'lodash'

/**
 * Counts the active filter for filterParams being a flat object with string or array values
 *
 * It ignores fields of other types
 *
 * Example:
 *
 * Input:
 * ```js
 * {
 *  searchString: 'foo',
 *  originalDueDateRange: '',
 *  currentDueDateRange: '',
 *  selectedTypes: ['c', 'd', 'e'], // counts as 1
 *  showInactive: true,             // ignored
 * }
 * ```
 * Result: `2`
 *
 * @param {{[x: string]: any}} filterParams
 * @returns number of active filters
 */
const countActiveFilters = (filterParams) =>
  Object.values(filterParams)
    .map((value) => {
      const type = typeof value
      if (type === 'string') return value.length > 0
      if (_.isArray(value)) return value.length > 0
      return false
    })
    .reduce((acc, val) => acc + +val, 0)

export default countActiveFilters
