import PropTypes from 'prop-types'
import Card from 'components/ui/card/Card'
import 'components/ui/card/Card.css'
import CardHeaderWithEditMode from 'components/ui/card/CardHeaderWithEditMode'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'

const EmptyCard = ({
  cardHeaderTitle,
  title,
  subtitle,
  illustrationName,
  onEditClicked,
  showCardHeaderActions,
  actions,
  className,
  contentClassName,
}) => (
  <Card
    className={className}
    header={
      <CardHeaderWithEditMode
        isEditMode={false}
        titleText={cardHeaderTitle}
        showActions={showCardHeaderActions}
        onToggle={() => {
          onEditClicked()
        }}
      />
    }
  >
    <EmptyCardContent
      className={contentClassName}
      title={title}
      subtitle={subtitle}
      illustrationName={illustrationName}
      actions={actions}
    />
  </Card>
)

EmptyCard.defaultProps = {
  showCardHeaderActions: true,
}

EmptyCard.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  cardHeaderTitle: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  illustrationName: PropTypes.string,
  onEditClicked: PropTypes.func,
  showCardHeaderActions: PropTypes.bool.isRequired,
  actions: PropTypes.element,
}

export default EmptyCard
