export default {
  pipelineExposure: 'pipeline',
  creditAuthorityLevel: 'existing',
  settlementRiskLimit: 'sr',
  settlementRiskLimitValue: 'srl',
  exposureAtDefault: 'ead',
  thresholdForLargeExposure: 'thresholdLe',
  exposureBeforeCreditRiskMitigation: 'brm',
  maxLimitForExposure25: 'maxLe',
  exposureAfterCreditRiskMitigation: 'arm',
}
