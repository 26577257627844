import isNil from 'lodash.isnil'
import sortBy from 'lodash.sortby'
import uniq from 'lodash.uniq'
import {
  groupRentalUnitsBySegmentUsageTypeCode,
  tenancyMeasurementUnitsPC,
} from 'hooks/services/business-partners/tenancy/tenancyCalculationUtils'

const filterForAreaNotPC = (rentalUnits) =>
  rentalUnits.filter(
    (rentalUnit) => !tenancyMeasurementUnitsPC.includes(rentalUnit.area.measurementUnit),
  )

const getAllMeasurementUnits = (rentalUnits) =>
  rentalUnits.map((rentalUnit) => rentalUnit.area.measurementUnit)

const getValue = (rentalUnits, units) => {
  const getRent = (rentalUnit) =>
    units.length > 1 ? rentalUnit.areaInHeadQuarterMeasurementUnit?.value : rentalUnit.area?.value

  return rentalUnits.reduce((sum, rentalUnit) => sum + getRent(rentalUnit), 0)
}

const getUnit = (rentalUnits, units) => {
  if (units.length > 1) {
    return rentalUnits[0].areaInHeadQuarterMeasurementUnit?.measurementUnit
  } else {
    return rentalUnits[0]?.area?.measurementUnit
  }
}

export const calculateTenancyTotalRentedArea = (
  contextRentalUnits,
  rentalUnits,
  totalRentedArea,
) => {
  const filteredContextRentalUnits = filterForAreaNotPC(contextRentalUnits)
  const units = uniq(getAllMeasurementUnits(filteredContextRentalUnits))

  const rentalUnitsWithAreaNotPc = filterForAreaNotPC(rentalUnits)
  const fallbackUnit = getUnit(filterForAreaNotPC(contextRentalUnits), units)
  const value = getValue(rentalUnitsWithAreaNotPc, units)
  let share = NaN
  if (!isNil(totalRentedArea)) {
    share = totalRentedArea ? value / totalRentedArea : 0
  }

  return {
    value,
    unit: getUnit(rentalUnitsWithAreaNotPc, units) || fallbackUnit,
    share,
  }
}

const getValueBySegmentUsageType = (filteredRentalUnits, unit, units) => {
  const groupedRentalUnits = groupRentalUnitsBySegmentUsageTypeCode(filteredRentalUnits)

  const areaPiecesPerSegementUsageType = []
  Object.entries(groupedRentalUnits).forEach((rentalUnitsBySegement) => {
    const rentalUnitsForSegment = rentalUnitsBySegement[1]
    const segmentName = rentalUnitsBySegement[1][0].segmentUsageTypeName

    areaPiecesPerSegementUsageType.push({
      value: getValue(rentalUnitsForSegment, units),
      unit: unit,
      share: 0,
      type: segmentName,
    })
  })

  return sortBy(areaPiecesPerSegementUsageType, (item) => item.type)
}

export const calculateTenancyTotalRentedAreaPerSegmentUsageType = (rentalUnits) => {
  const rentalUnitsWithAreaNotPc = filterForAreaNotPC(rentalUnits)
  const units = uniq(getAllMeasurementUnits(rentalUnitsWithAreaNotPc))
  const unit = getUnit(rentalUnitsWithAreaNotPc, units)

  return getValueBySegmentUsageType(rentalUnitsWithAreaNotPc, unit, units)
}
