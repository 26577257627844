import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateDecisionPaperAttachment = (mutationOptions) => {
  const { postFileList } = useAccessTokenRequest()
  return useMutation(async ({ eventId, files }) => {
    const formData = new FormData()
    files.forEach((file) => formData.append('attachments', file))

    const { data } = await postFileList({
      path: `/events/${eventId}/decision-paper/versions/current/attachments`,
      body: formData,
    })
    return data
  }, mutationOptions)
}

export default useCreateDecisionPaperAttachment
