import isNil from 'lodash.isnil'
import { useTranslation } from 'react-i18next'
import ConvertedAmount from 'components/domains/business-events-and-tasks/decision-paper/tiles/loan-market-statement/shared/ConvertedAmount'
import syndicationIntentionStyles from 'components/domains/business-events-and-tasks/decision-paper/tiles/loan-market-statement/shared/SyndicationIntentionCard.module.css'
import {
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/loan-market-statement/shared/hooks/useI18n'

const useLoanMarketStatementSyndicationIntentionFields = (syndicationIntentionData) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.loan-market-statement.syndication-intention',
  })

  const formatCurrency = useCustomizableCurrencyFormatter()
  const formatShare = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })
  const { format: formatDate } = useShortDateFormatter()

  const { preSale, postSale, syndicationPreSale, syndicationPostSale } =
    syndicationIntentionData ?? {}

  const {
    sellingAmountTotal: preSaleSellingAmountTotal,
    sellingAmountTotalHeadquarter: preSaleSellingAmountTotalHeadquarter,
    sellingAmountShare: preSaleSellingAmountShare,
    reasonShortText: preSaleReasonShortText,
  } = syndicationPreSale ?? {}

  const {
    mandatory: postSaleMandatory,
    onHold: postSaleOnHold,
    sellingAmountTotal: postSaleSellingAmountTotal,
    sellingAmountTotalHeadquarter: postSaleSellingAmountTotalHeadquarter,
    sellingAmountShare: postSaleSellingAmountShare,
    reasonShortText: postSaleReasonShortText,
    period: postSalePeriod,
    referenceDateShortText: postSaleReferenceDateShortText,
    deadline: postSaleDeadline,
    forecastYear: postSaleForecastYear,
    completionForecastShortText: postSaleForecastShortText,
  } = syndicationPostSale ?? {}

  const preSaleFields = [
    {
      label: t('pre-sale-selling-amount-total'),
      value: preSaleSellingAmountTotal
        ? formatCurrency(preSaleSellingAmountTotal?.amount, preSaleSellingAmountTotal?.currency, {
            currencyDisplay: 'code',
          })
        : null,
    },
    ...(!isNil(preSaleSellingAmountTotalHeadquarter) &&
    preSaleSellingAmountTotal?.currency !== preSaleSellingAmountTotalHeadquarter?.currency
      ? [
          {
            customInfoComponent: ConvertedAmount(
              preSaleSellingAmountTotalHeadquarter?.amount,
              preSaleSellingAmountTotalHeadquarter?.currency,
            ),
          },
        ]
      : []),
    {
      label: t('pre-sale-selling-amount-share'),
      value: preSaleSellingAmountShare ? formatShare(preSaleSellingAmountShare) : null,
    },
    {
      label: t('pre-sale-reason'),
      value: preSaleReasonShortText,
    },
  ]

  const postSaleFields = [
    {
      label: t('post-sale-mandatory'),
      value: postSaleMandatory ? t('yes') : t('no'),
    },
    {
      label: t('post-sale-on-hold'),
      value: postSaleOnHold ? t('yes') : t('no'),
    },
    {
      label: t('pre-sale-selling-amount-total'),
      value: postSaleSellingAmountTotal
        ? formatCurrency(postSaleSellingAmountTotal?.amount, postSaleSellingAmountTotal?.currency, {
            currencyDisplay: 'code',
          })
        : null,
    },
    ...(!isNil(postSaleSellingAmountTotalHeadquarter) &&
    postSaleSellingAmountTotal?.currency !== postSaleSellingAmountTotalHeadquarter?.currency
      ? [
          {
            customInfoComponent: ConvertedAmount(
              postSaleSellingAmountTotalHeadquarter?.amount,
              postSaleSellingAmountTotalHeadquarter?.currency,
            ),
          },
        ]
      : []),
    {
      label: t('post-sale-selling-amount-share'),
      value: postSaleSellingAmountShare ? formatShare(postSaleSellingAmountShare) : null,
    },
    {
      label: t('post-sale-reason'),
      value: postSaleReasonShortText,
    },
    {
      label: t('post-sale-period'),
      value:
        postSalePeriod && postSaleReferenceDateShortText ? (
          <div className={syndicationIntentionStyles.alignTextRight}>
            {postSalePeriod} {t('reference-date.period-months-from')}{' '}
            {postSaleReferenceDateShortText}
          </div>
        ) : null,
    },
    {
      label: t('post-sale-deadline'),
      value: postSaleDeadline ? formatDate(postSaleDeadline) : null,
    },
    {
      label: t('post-sale-completion-forecast'),
      value:
        postSaleForecastShortText && postSaleForecastYear
          ? `${postSaleForecastShortText} ${postSaleForecastYear}`
          : null,
    },
  ]

  return [
    {
      value: {
        sectionTitle: t('pre-sale'),
        sectionValue: preSale ? t('yes') : t('no'),
      },
      isSectionTitle: true,
    },
    ...(preSale ? preSaleFields : []),
    {
      name: 'postSale',
      value: {
        sectionTitle: t('post-sale'),
        sectionValue: postSale ? t('yes') : t('no'),
      },
      isSectionTitle: true,
    },
    ...(postSale ? postSaleFields : []),
  ]
}

export default useLoanMarketStatementSyndicationIntentionFields
