import { useTranslation } from 'react-i18next'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const useLastEditedText = (name, timestamp, timeZone) => {
  const { format: formatDateAndTime } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
    timeZone,
  })

  const { t } = useTranslation()

  if (!timestamp) {
    return null
  }

  if (!name) {
    return `${t('pages.deals.tranches.details.last-edited', {
      date: formatDateAndTime(timestamp),
    })}`
  }

  return `${t('pages.deals.tranches.details.last-edited-by', {
    name,
    date: formatDateAndTime(timestamp),
  })}`
}

export { useLastEditedText }
