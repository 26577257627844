import { ObjectStatus, ValueState } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useColorOptions = () => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.forbearance-and-utp-questionare.options.colors',
  })

  return useMemo(
    () => [
      {
        key: '-',
        value: '-',
        displayValue: t('empty-value'),
      },
      {
        key: 'red',
        value: 'red',
        displayValue: (
          <ObjectStatus inverted state={ValueState.Error}>
            {t('red')}
          </ObjectStatus>
        ),
      },
      {
        key: 'yellow',
        value: 'yellow',
        displayValue: (
          <ObjectStatus inverted state={ValueState.Warning}>
            {t('yellow')}
          </ObjectStatus>
        ),
      },
      {
        key: 'green',
        value: 'green',
        displayValue: (
          <ObjectStatus inverted state={ValueState.Success}>
            {t('green')}
          </ObjectStatus>
        ),
      },
    ],
    [t],
  )
}

export default useColorOptions
