import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'

export const useRentRoll = (propertyUuid, options = {}) => {
  const {
    data: rentRoll,
    isError: isError,
    isLoading: isLoading,
    error,
  } = useRequest({
    keys: ['rent-roll', propertyUuid],
    path: `/properties/${propertyUuid}/rent-roll`,
    translated: true,
    options: {
      retry: (_, errorObj) => !isNotFoundError(errorObj),
      ...options,
    },
  })

  return useMemo(() => {
    let resultIsError = isError
    let resultIsNotFoundError = !!error && isNotFoundError(error)
    if (!isError && !isLoading && isEmpty(rentRoll?.rental_units)) {
      resultIsError = true
      resultIsNotFoundError = true
    }
    return {
      data: rentRoll,
      isError: resultIsError,
      isLoading,
      isNotFoundError: resultIsNotFoundError,
    }
  }, [error, isError, isLoading, rentRoll])
}
