import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash.clonedeep'
import isNil from 'lodash.isnil'
import merge from 'lodash.merge'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CheckedCovenantEditRowCells from 'components/domains/deals/covenant-check-detail/checked-covenants/CheckedCovenantEditRowCells'
import { monitoringItemShape } from 'components/domains/deals/covenant-check-detail/checked-covenants/CheckedCovenantPropTypes'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import useUpdateMonitoringItem from 'hooks/services/deals/covenants/monitorings/useUpdateMonitoringItem'

const CheckedCovenantEditRow = ({
  monitoringItem,
  onCancel,
  onSave,
  covenantCheckUuid,
  ...props
}) => {
  const { t: tNoPrefix } = useTranslation('translation')

  const showErrorMessageBox = useShowErrorMessageBox()
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()

  const [formState, setFormState] = useState({
    result: monitoringItem?.result?.value,
    newTargetDeliveryDate: monitoringItem?.newTargetDeliveryDate,
    breach: monitoringItem?.breach,
    waived: monitoringItem?.waived,
  })

  const onValueChange = (value) => {
    const oldFormState = cloneDeep(formState)
    const newFormState = merge(oldFormState, value)
    setFormState(newFormState)
  }

  const isCovenantTypeFinancial = monitoringItem?.covenant?.type === 'FINANCIAL'

  const isNewDeliveryDateValid = useMemo(() => {
    // DateTime.fromISO accepts multiple ISO-formats e.g. 2020-01
    const dateRegex = /^\d{4}-\d{2}-\d{2}/
    if (!dateRegex.test(formState?.newTargetDeliveryDate)) return false

    const parsedNewDeliveryDate = DateTime.fromISO(formState?.newTargetDeliveryDate)
    if (parsedNewDeliveryDate.isValid) return Boolean(!formState?.result)
    return false
  }, [formState?.newTargetDeliveryDate, formState?.result])

  const isFormStateValid = useMemo(() => {
    if (formState?.newTargetDeliveryDate) {
      return isNewDeliveryDateValid
    }
    if (isCovenantTypeFinancial) {
      return Boolean(!isNil(formState?.breach) && !isNil(formState?.result))
    }
    return Boolean(formState?.breach)
  }, [
    formState?.breach,
    formState?.newTargetDeliveryDate,
    formState?.result,
    isCovenantTypeFinancial,
    isNewDeliveryDateValid,
  ])

  const onError = async (error) => {
    const { errors: [errorResponse] = [] } = await error.response.json()
    showErrorMessageBox({
      message: tNoPrefix('components.deals.covenants.checked-covenants.update.error'),
      error: errorResponse,
    })
  }
  const onSuccess = () => {
    showToast(
      { children: tNoPrefix('components.deals.covenants.checked-covenants.update.success') },
      document.body,
    )
    queryClient.invalidateQueries(
      'covenants',
      monitoringItem?.covenant?.covenantUuid,
      'monitoring',
      'items',
    )
    queryClient.invalidateQueries('covenants', 'monitoring', 'items')
    queryClient.invalidateQueries('covenants', 'covenant-checks', covenantCheckUuid)
    onSave()
  }
  const updateMonitoringItem = useUpdateMonitoringItem({ onSuccess, onError })

  const mapFormStateToUpdateFormat = () => ({
    result: formState.result,
    breach: formState.breach,
    newTargetDeliveryDate: formState.newTargetDeliveryDate,
    waived: formState.waived,
  })
  const executeUpdateMonitoringItem = () => {
    updateMonitoringItem.mutate({
      covenantUuid: monitoringItem?.covenant?.covenantUuid,
      covenantMonitoringItemUuid: monitoringItem?.covenantMonitoringItemUuid,
      covenantMonitoringItem: mapFormStateToUpdateFormat(),
    })
  }

  return (
    <CheckedCovenantEditRowCells
      monitoringItem={monitoringItem}
      formState={formState}
      updateFormState={onValueChange}
      isFormStateValid={isFormStateValid}
      isNewDeliveryDateValid={isNewDeliveryDateValid}
      executeUpdateMonitoringItem={executeUpdateMonitoringItem}
      onCancel={onCancel}
      {...props}
    />
  )
}

CheckedCovenantEditRow.propTypes = {
  monitoringItem: monitoringItemShape,
  dealUuid: PropTypes.string,
  covenantCheckUuid: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default CheckedCovenantEditRow
