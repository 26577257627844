import { DatePicker, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import '@ui5/webcomponents/dist/Assets.js'

const EditableDatePickerComponent = ({ id, onChange, placeholder, value, editable, ...props }) => {
  const { localePattern } = useShortDateFormatter()

  return editable ? (
    <DatePicker
      id={id}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      formatPattern={localePattern}
      {...props}
    />
  ) : (
    <Label>{value ? value : placeholder}</Label>
  )
}

EditableDatePickerComponent.propTypes = {
  id: PropTypes.any,
  onChange: PropTypes.func,
  value: PropTypes.any,
  editable: PropTypes.bool,
  placeholder: PropTypes.string,
}
export default EditableDatePickerComponent
