const MIN_ROW_HEIGHT = 32
const CELL_PADDING = 8

const TEXT_HEIGHT = 16
const TEXT_ROW_GAP = 14
const INNER_CELL_PADDING = 16

export const calculateDealCashFlowRowHeightDisplayMode = ({ length } = []) => {
  const rowHeight =
    length * TEXT_HEIGHT + (length - 1) * TEXT_ROW_GAP + CELL_PADDING + INNER_CELL_PADDING
  return Math.max(rowHeight, MIN_ROW_HEIGHT)
}

const INPUT_HEIGHT = 26
const INPUT_MARGIN = 8

export const calculateDealCashFlowRowHeightEditMode = ({ length } = []) => {
  const rowHeight = length * (INPUT_HEIGHT + INPUT_MARGIN) + CELL_PADDING
  return Math.max(rowHeight, MIN_ROW_HEIGHT)
}
