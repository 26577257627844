import { Table, TableRow, TableColumn, TableCell } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Entity from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/components/ui/data/Entity'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/non-ref-credit-decision/involved-parties/manual-checksheet/NonRefCreditDecisionCheckSheetTable.module.css'
import NonRefCreditDecisionCheckSheetTableCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/non-ref-credit-decision/involved-parties/manual-checksheet/NonRefCreditDecisionCheckSheetTableCell'

const NonRefCreditDecisionCheckSheetTable = ({
  id,
  translationPrefix,
  customAnswerTranslationPrefix,
  currentData,
  selectedBorrowerId,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: translationPrefix,
  })

  const currentBorrowerData =
    currentData?.find((borrower) => borrower.borrowerId === selectedBorrowerId) ?? {}

  return (
    <Table
      id={id}
      className={styles.nonRefCreditDecisionCheckSheetTable}
      columns={
        <>
          <TableColumn />
          <TableColumn key={currentBorrowerData.borrowerId}>
            <Entity
              id={currentBorrowerData.borrowerId}
              name={currentBorrowerData.borrowerName}
              link={`/business-partners/${currentBorrowerData.borrowerId}/`}
            />
          </TableColumn>
        </>
      }
      hideNoData={true}
    >
      {Object.entries(currentBorrowerData?.answers || {}).map(([key, value]) => (
        <TableRow key={key}>
          <TableCell>{t(key)}</TableCell>
          <TableCell>
            <NonRefCreditDecisionCheckSheetTableCell
              question={key}
              value={value}
              customAnswerTranslationPrefix={customAnswerTranslationPrefix}
            />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}

NonRefCreditDecisionCheckSheetTable.propTypes = {
  id: PropTypes.string.isRequired,
  translationPrefix: PropTypes.string.isRequired,
  customAnswerTranslationPrefix: PropTypes.string,
  selectedBorrowerId: PropTypes.string,
  currentData: PropTypes.arrayOf(
    PropTypes.shape({
      borrowerId: PropTypes.string.isRequired,
      borrowerName: PropTypes.string,
      answers: PropTypes.shape({}),
    }),
  ),
}

export default NonRefCreditDecisionCheckSheetTable
