import PropTypes from 'prop-types'
import { useCallback } from 'react'
import MultipleBorrowerSelectionPdfViewWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/appendix/wrapper/MultipleBorrowerSelectionPdfViewWrapper'
import FinancialAnalysisTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/custom-assessment/financial-analysis/FinancialAnalysisTile'

const FinancialAnalysisTileWrapper = ({ tileId, isPdfView }) => {
  const contentCreator = useCallback(
    ({ selectedBorrowerId }) => (
      <FinancialAnalysisTile tileId={tileId} selectedBorrowerId={selectedBorrowerId} />
    ),
    [tileId],
  )

  return (
    <MultipleBorrowerSelectionPdfViewWrapper
      tileId={tileId}
      contentCreator={contentCreator}
      isPdfView={isPdfView}
    />
  )
}

FinancialAnalysisTileWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default FinancialAnalysisTileWrapper
