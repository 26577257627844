import { CardHeader } from '@fioneer/ui5-webcomponents-react'
import '@ui5/webcomponents-fiori/dist/illustrations/NoData.js'
import '@ui5/webcomponents-fiori/dist/illustrations/NoEntries.js'
import { useTranslation } from 'react-i18next'
import AsTenantCardHeader from 'components/domains/business-partners/tile/tenancy/AsTenantCardHeader'
import tenancyCardDataPropTypes from 'components/domains/business-partners/tile/tenancy/tenancyCardDataPropTypes'
import AsTenantCardTable from 'components/domains/business-partners/tile/tenancy/tenant-unit-table/AsTenantCardTable'
import Card from 'components/ui/card/Card'
import 'components/ui/card/Card.css'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const AsTenantCard = ({ tenancyCardData }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.tenancy.as-tenant',
  })
  const { format: formatDate } = useShortDateFormatter()

  const latestKeyDate = tenancyCardData?.rentalUnitsData?.latestKeyDate
  return (
    <Card
      header={
        <CardHeader
          titleText={t('title')}
          subtitleText={
            latestKeyDate && `${t('subtitle-keydate', { keyDate: formatDate(latestKeyDate) })}`
          }
        />
      }
      className="cwp-card"
    >
      <>
        <AsTenantCardHeader
          rentalUnits={tenancyCardData?.dataByFinancingStatus}
          propertyDeals={tenancyCardData?.propertyDeals}
          financingStatus={tenancyCardData?.financingStatus}
        />
        <AsTenantCardTable isFetching={false} tenancyCardData={tenancyCardData} />
      </>
    </Card>
  )
}

AsTenantCard.propTypes = {
  tenancyCardData: tenancyCardDataPropTypes,
}

export default AsTenantCard
