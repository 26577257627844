import { DynamicPage } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import TabPage from 'components/ui/page/TabPage'
import { allTilesInitializedSelector } from 'routes/business-events-and-tasks/decision-paper/selectors/decisionPaperSelectors'

const DecisionPaperPage = ({
  children,
  headerContent,
  headerTitle,
  displayTabBar = true,
  isLoading = false,
  isError = false,
  isEmpty = false,
}) => {
  const allTilesInitialized = useSelector(allTilesInitializedSelector)

  const [selectedSubSectionId, setSelectedSubSectionId] = useState(undefined)

  const onSelectedSectionChange = useCallback((e) => {
    setSelectedSubSectionId(e?.detail?.subSectionId)
  }, [])

  const renderAsTabPage = isLoading || isError || isEmpty || !allTilesInitialized || displayTabBar

  return renderAsTabPage ? (
    <TabPage
      isLoading={isLoading}
      isError={isError}
      isEmpty={isEmpty || !allTilesInitialized}
      headerTitle={headerTitle}
      headerContent={headerContent}
      isStickyTabBar
      alwaysShowContentHeader
      includeUrlQuery={true}
      selectedSubSectionId={selectedSubSectionId}
      onSelectedSectionChange={onSelectedSectionChange}
    >
      {children}
    </TabPage>
  ) : (
    <DynamicPage
      headerTitle={headerTitle}
      headerContent={headerContent}
      showHideHeaderButton={false}
      headerContentPinnable={false}
      alwaysShowContentHeader
    >
      {children}
    </DynamicPage>
  )
}

DecisionPaperPage.propTypes = {
  children: PropTypes.node,
  headerActions: PropTypes.node,
  headerContent: PropTypes.node,
  headerTitle: PropTypes.node,
  displayTabBar: PropTypes.bool,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  isEmpty: PropTypes.bool,
}

export default DecisionPaperPage
