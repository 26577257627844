import isEmpty from 'lodash.isempty'

const conditionRefNumberChanged = (state, { payload: { refNumber: newRefNumber } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }
  state.editedRow.currentValues.refNumber = newRefNumber
  if (newRefNumber === state.editedRow.initialValues.refNumber) {
    delete state.editedRow.changedFields.refNumber
    return
  }
  state.editedRow.changedFields.refNumber = true
}

export default conditionRefNumberChanged
