import {
  Text,
  Popover,
  PopoverPlacementType,
  PopoverHorizontalAlign,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'

const MarketsKpiTablePopoverComponent = ({ kpiName, description }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const kpiNamePopover = useMemo(
    () =>
      createPortal(
        <Popover
          opener={`popover-${kpiName}`}
          open={isPopoverOpen}
          placementType={PopoverPlacementType.Bottom}
          horizontalAlign={PopoverHorizontalAlign.Center}
          onAfterClose={() => setIsPopoverOpen(false)}
        >
          {description ? description : '-'}
        </Popover>,
        document.body,
      ),
    [description, isPopoverOpen, kpiName],
  )

  const mouseEnter = useCallback(() => {
    setIsPopoverOpen(true)
  }, [])
  const mouseLeave = useCallback(() => {
    setIsPopoverOpen(false)
  }, [])

  return (
    <div id={'hover-div'} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <Text id={`popover-${kpiName}`}>{kpiName}</Text>
      {kpiNamePopover}
    </div>
  )
}

MarketsKpiTablePopoverComponent.propTypes = {
  kpiName: PropTypes.string.isRequired,
  description: PropTypes.string,
}

export default MarketsKpiTablePopoverComponent
