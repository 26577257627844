import { Grid } from '@fioneer/ui5-webcomponents-react'
import isEqual from 'lodash.isequal'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/valuation/PropertyValuationsRequests.module.css'
import {
  areFiltersApplied,
  getRequestParametersForValuationRequests,
} from 'components/domains/properties/valuation/ValuationRequestsUtils'
import ValuationRequestsTable from 'components/domains/properties/valuation/table/ValuationRequestsTable'
import Card from 'components/ui/card/Card'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import usePropertyDetailedUuids from 'hooks/services/properties/usePropertyDetailedUuids'
import { usePropertiesValuationRequests } from 'hooks/services/properties/valuations/usePropertiesValuationRequests'
import usePropertyValuationReasons from 'hooks/services/properties/valuations/usePropertyValuationReasons'
import { PropertyPortfolioContext } from 'routes/properties/portfolio/PropertyPortfolioContext'

const ascending = 'asc'

const PropertyPortfolioValuationsRequestsCard = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property.valuation.requests' })
  const { properties } = useContext(PropertyPortfolioContext)
  const propertyUuids = properties?.map((property) => property.uuid)

  const [sorting, setSorting] = useState({ sortBy: 'info.status', orderBy: ascending })
  const [filters, setFilters] = useState([])
  const [searchParam, setSearchParam] = useState('')
  const {
    isLoading: isPropertiesLoading,
    isError: isPropertiesError,
    data: propertiesData,
  } = usePropertyDetailedUuids(propertyUuids)

  const {
    isLoading: isReasonsLoading,
    isError: isReasonsError,
    data: valuationReasonsData,
  } = usePropertyValuationReasons()

  const requestParameters = useMemo(
    () => getRequestParametersForValuationRequests(filters, searchParam),
    [filters, searchParam],
  )

  const {
    isLoading: isRequestsLoading,
    isError: isRequestsError,
    data: valuationData,
    fetchNextPage: loadMoreRequests,
  } = usePropertiesValuationRequests({
    propertyUuids,
    filter: requestParameters,
    sort: sorting,
  })

  const updateFilters = useCallback(
    (updatedFilters) => {
      if (!isEqual(filters, updatedFilters)) {
        setFilters(updatedFilters)
      }
    },
    [filters],
  )

  const updateSearchParam = useCallback(
    (updatedSearchParam) => {
      if (!isEqual(searchParam, updatedSearchParam)) {
        setSearchParam(updatedSearchParam)
      }
    },
    [searchParam],
  )

  const onSortingChanged = useCallback(
    ({ sortBy, orderBy }) => {
      if (!isEqual(sorting.sortBy, sortBy) || !isEqual(sorting.orderBy, orderBy)) {
        setSorting({ sortBy, orderBy })
      }
    },
    [sorting.sortBy, sorting.orderBy],
  )

  const noDataText = useMemo(() => {
    if (areFiltersApplied(filters) || searchParam) return t('table.no-data-with-filters')
    return t('table.no-data')
  }, [filters, searchParam, t])

  const renderContent = useCallback(() => {
    const mappedProperties = propertiesData.data.properties.map(
      ({
        description,
        uuid,
        id,
        address: { street_name, house_id, city_name },
        allowed_operations: { allowed_operations },
      }) => ({
        propertyName: description,
        propertyDisplayId: id,
        propertyUuid: uuid,
        propertyAddress: { streetName: street_name, houseId: house_id, cityName: city_name },
        allowedOperations: allowed_operations,
      }),
    )

    return (
      <Card>
        <Grid vSpacing="0rem" defaultSpan="XL12 L12 M12 S12" className={styles.card}>
          <ValuationRequestsTable
            valuationRequests={valuationData.valuationRequests}
            valuationReasons={valuationReasonsData}
            sortBy={sorting.sortBy}
            orderBy={sorting.orderBy}
            filters={filters}
            onSortingChanged={onSortingChanged}
            setFilters={updateFilters}
            searchParam={searchParam}
            setSearchParam={updateSearchParam}
            noDataText={noDataText}
            properties={mappedProperties}
            onLoadMore={loadMoreRequests}
            totalValuationRequestAmount={valuationData.total}
          />
        </Grid>
      </Card>
    )
  }, [
    propertiesData?.data?.properties,
    valuationData?.valuationRequests,
    valuationData?.total,
    valuationReasonsData,
    sorting.sortBy,
    sorting.orderBy,
    filters,
    onSortingChanged,
    updateFilters,
    searchParam,
    updateSearchParam,
    noDataText,
    loadMoreRequests,
  ])

  return (
    <LoadingStateWrapper
      isLoading={isRequestsLoading || isReasonsLoading || isPropertiesLoading}
      isError={isRequestsError || isReasonsError || isPropertiesError}
      renderContent={renderContent}
      errorDescription={t('error.description')}
      errorDetails={' '}
      errorTitle={t('error.title')}
    />
  )
}

export default PropertyPortfolioValuationsRequestsCard
