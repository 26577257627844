import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useCovenantCheckSelectionTableColumnDefinitions from 'components/domains/conditions/covenant-check-selection/useCovenantCheckSelectionTableColumnDefinitions'
import useCovenantCheckSelectionTableData from 'components/domains/conditions/covenant-check-selection/useCovenantCheckSelectionTableData'
import useCovenentCheckSelectionEnums from 'components/domains/conditions/covenant-check-selection/useCovenentCheckSelectionEnums'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'

const CovenantCheckSelectionTable = ({ covenantChecks, onSelectTableRow, mainEntityType }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.covenant-check-selection.table',
  })

  const onRowClick = useCallback(
    ({ detail: { row } }) => {
      if (isNil(covenantChecks)) {
        return
      }
      const selectedCovenantCheckUuid = row.dataset.id
      const covenantCheck = covenantChecks.find(
        ({ covenantCheckUuid }) => covenantCheckUuid === selectedCovenantCheckUuid,
      )
      onSelectTableRow({ covenantCheck })
    },
    [covenantChecks, onSelectTableRow],
  )

  const enums = useCovenentCheckSelectionEnums({ covenantChecks })
  const columns = useCovenantCheckSelectionTableColumnDefinitions({ enums, mainEntityType })
  const { tableData } = useCovenantCheckSelectionTableData({
    data: covenantChecks,
    mainEntityType,
  })

  const additionalTableProperties = useMemo(() => ({ onRowClick }), [onRowClick])

  return (
    <SortedTable
      toolbarConfig={{
        title: t('title'),
      }}
      noDataText={t('empty')}
      columnDefinitions={columns}
      tableData={tableData}
      additionalTableProperties={additionalTableProperties}
    />
  )
}

CovenantCheckSelectionTable.propTypes = {
  covenantChecks: PropTypes.arrayOf(
    PropTypes.shape({
      covenantCheckUuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      breach: PropTypes.bool,
      keyDate: PropTypes.string.isRequired,
      firstDeliveryDate: PropTypes.string.isRequired,
      covenants: PropTypes.arrayOf(
        PropTypes.shape({
          covenantUuid: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          mainEntity: PropTypes.shape({
            type: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
          }).isRequired,
        }),
      ).isRequired,
      monitoringReferenceEntity: PropTypes.shape({
        type: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
  onSelectTableRow: PropTypes.func.isRequired,
  mainEntityType: PropTypes.string.isRequired,
}

export default CovenantCheckSelectionTable
