import '@ui5/webcomponents-fiori/dist/illustrations/UnableToLoad.js'
import { IllustrationMessageType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/TenancyOverviewExpiringLeasesTile.module.css'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/shared/NoPropertyAssignedToDealInfo'
import PropertyRentRollOverviewExpiringLeasesChart from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/shared/PropertyRentRollOverviewExpiringLeasesChart'
import CardHeaderWithMetrics from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/shared/ui/CardHeaderWithMetrics'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/shared/ui/EmptyCardContent'

const TenancyOverviewExpiringLeasesTile = ({ tileId }) => {
  const { t: tExpiringLeasesChart } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.rent-roll.overview.expiring-leases.chart',
  })
  const tileState = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const {
    isLoading,
    isError,
    data: { noProperties, expiringLeasesCardHeaderInfo, expiringLeasesChartInfo } = {},
  } = tileState

  if (noProperties || (!expiringLeasesCardHeaderInfo && !expiringLeasesChartInfo)) {
    return <NoPropertyAssignedToDealInfo />
  }
  return (
    <>
      <CardHeaderWithMetrics
        primaryMetric={expiringLeasesCardHeaderInfo?.primaryMetric}
        referenceDate={expiringLeasesCardHeaderInfo?.keyDate}
        isError={isError}
        isLoading={isLoading}
        isOnDecisionPaper={true}
        className={styles.header}
      />
      <DecisionPaperTileListSeparator />
      {!expiringLeasesChartInfo || expiringLeasesChartInfo.isEmpty ? (
        <EmptyCardContent
          illustrationName={IllustrationMessageType.UnableToLoad}
          title={tExpiringLeasesChart('empty.title')}
          subtitle={tExpiringLeasesChart('empty.subtitle')}
        />
      ) : (
        <div className={styles.chart}>
          <PropertyRentRollOverviewExpiringLeasesChart
            currentRent={expiringLeasesChartInfo.currentRent}
            expiringLeases={expiringLeasesChartInfo.expiringLeases}
            currency={expiringLeasesChartInfo.currency}
            todayXAxisPosition={expiringLeasesChartInfo.todayXAxisPosition}
          />
        </div>
      )}
    </>
  )
}

TenancyOverviewExpiringLeasesTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default TenancyOverviewExpiringLeasesTile
