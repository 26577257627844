import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useDealMini from 'hooks/services/deals/useDealMini'
import usePropertyUuids from 'hooks/services/properties/usePropertyUuids'

const useEntityNameResolver = ({ entityId, entityType }) => {
  const {
    data: deal,
    isLoading: isDealLoading,
    isError: isDealError,
  } = useDealMini(entityId, { enabled: entityType === cwpEntityTypes.DEAL })

  const {
    data: properties,
    isLoading: isPropertyLoading,
    isError: ioPropertyError,
  } = usePropertyUuids(
    [entityId],
    {
      enabled: entityType === cwpEntityTypes.PROPERTY,
    },
    true,
  )

  const {
    data: businessPartners,
    isLoading: isBusinessPartnerLoading,
    isError: isBusinessPartnerError,
  } = useBusinessPartnerMiniByIds([entityId], {
    enabled: entityType === cwpEntityTypes.BUSINESS_PARTNER,
  })

  switch (entityType) {
    case cwpEntityTypes.BUSINESS_PARTNER:
      return {
        entityName: businessPartners?.businessPartnerMinis?.[0]?.fullName,
        entityDisplayId: businessPartners?.businessPartnerMinis?.[0]?.id,
        entityId,
        isLoading: isBusinessPartnerLoading,
        isError: isBusinessPartnerError,
      }
    case cwpEntityTypes.DEAL:
      return {
        entityName: deal?.name,
        entityDisplayId: deal?.dealId,
        entityId,
        isLoading: isDealLoading,
        isError: isDealError,
      }
    case cwpEntityTypes.PROPERTY:
      return {
        entityName: properties?.properties?.[0]?.description,
        entityDisplayId: properties?.properties?.[0]?.id,
        entityId,
        isLoading: isPropertyLoading,
        isError: ioPropertyError,
      }
  }
}

export default useEntityNameResolver
