import { BusyIndicatorSize, TableGrowingMode } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { covenantChecksDataPropTypes } from 'components/domains/deals/covenants/covenant-checks-table/CovenantChecksTable'
import useCovenantChecksTableData from 'components/domains/deals/covenants/covenant-checks-table/useCovenantChecksTableData'
import useCovenantChecksTableEnumns from 'components/domains/deals/covenants/covenant-checks-table/useCovenantChecksTableEnumns'
import styles from 'components/domains/deals/covenants/referenced-covenant-checks/ReferencedCovenantChecksTable.module.css'
import useReferencedCovenantChecksTableColumnDefinitions from 'components/domains/deals/covenants/referenced-covenant-checks/useReferencedCovenantChecksTableColumnDefinitions'
import { ErrorDataUnavailableInTable } from 'components/ui/errors/ErrorDataUnavailableInTable'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'

const colKeyToReferencedCovenantChecksDataMap = new Map()
colKeyToReferencedCovenantChecksDataMap.set('covenant-check', 'name')
colKeyToReferencedCovenantChecksDataMap.set('key-date', 'keyDate')
colKeyToReferencedCovenantChecksDataMap.set('status', 'statusOrder')
colKeyToReferencedCovenantChecksDataMap.set('first-target-delivery-date', 'firstTargetDeliveryDate')
colKeyToReferencedCovenantChecksDataMap.set('breach', 'breach')
colKeyToReferencedCovenantChecksDataMap.set(
  'monitoring-reference',
  'monitoringReferenceEntity.nameWithId',
)

const ReferencedCovenantChecksTable = ({
  covenantChecksData,
  pagination,
  onSortingChanged,
  loadMore,
  sorting: { orderDirection, orderField } = {},
  isAllowedToReadCovenants,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.covenants.monitoring.covenant-checks',
  })
  const { t: tNoPrefix } = useTranslation('translation')

  const enumnsForFiltering = useCovenantChecksTableEnumns({ data: covenantChecksData, t })
  const columnDefinitions = useReferencedCovenantChecksTableColumnDefinitions({
    ...enumnsForFiltering,
  })
  const { tableData, isLoading } = useCovenantChecksTableData({
    data: covenantChecksData,
    isAllowedToReadCovenants,
    isCovenantCheckClickable: true,
  })

  const showMoreButton = pagination.limit < pagination.total
  const growingMode = showMoreButton ? TableGrowingMode.Button : TableGrowingMode.None
  const growingButtonText = tNoPrefix('components.ui.tables.sorted-tables.growing-button-text')
  const growingButtonSubtext = `[ ${pagination.limit} / ${pagination.total} ]`

  const customOrderCallback = (columnKey, attributeOrderDirection) => {
    const newOrderField = colKeyToReferencedCovenantChecksDataMap.get(columnKey)
    onSortingChanged(newOrderField, attributeOrderDirection)
  }

  const sortingColumnKey = Array.from(colKeyToReferencedCovenantChecksDataMap.keys()).find(
    (key) => colKeyToReferencedCovenantChecksDataMap.get(key) === orderField,
  )

  return (
    <RequestStateResolver
      center
      busyIndicatorSize={BusyIndicatorSize.Medium}
      isLoading={isLoading}
      isError={false}
      errorToDisplay={<ErrorDataUnavailableInTable />}
      renderContent={() => (
        <div className={`${styles['default-cursor']}`}>
          <SortedTable
            columnDefinitions={columnDefinitions}
            tableData={tableData}
            toolbarConfig={{
              title: t('referenced-covenants-table-title'),
              sorting: {
                columnKey: sortingColumnKey,
                isSortingAscending: orderDirection === 'asc',
              },
            }}
            customOrderCallback={customOrderCallback}
            // needs to be specified to prevent sorting from the SortedTable
            customOrderFunction={(data) => data}
            noDataText={tNoPrefix('components.table.no-data')}
            paginationConfig={{
              growing: growingMode,
              growingButtonText: growingButtonText,
              growingButtonSubtext: growingButtonSubtext,
              totalNumberOfItems: pagination?.total,
              loadMore: loadMore,
            }}
            additionalTableProperties={{
              className: styles.innerTable,
            }}
          />
        </div>
      )}
    />
  )
}

ReferencedCovenantChecksTable.propTypes = {
  covenantChecksData: PropTypes.arrayOf(PropTypes.shape(covenantChecksDataPropTypes)),
  pagination: PropTypes.shape({
    offset: PropTypes.number,
    limit: PropTypes.number,
    total: PropTypes.number,
  }),
  onSortingChanged: PropTypes.func,
  loadMore: PropTypes.func,
  sorting: PropTypes.shape({
    orderDirection: PropTypes.string,
    orderField: PropTypes.string,
  }),
  isAllowedToReadCovenants: PropTypes.bool,
}

export default ReferencedCovenantChecksTable
