import { useRequest } from 'hooks/services/baseService'

/**
 * Returns all property typecode keys and their localized display name
 * The Typecodes structure is as follows:
 * {
 *   "typecodes": [
 *       {
 *           "key": "010001",
 *           "display_name": "Single family house"
 *       },
 *       [...]
 *   ]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: typecodes}}
 *
 */
export const useTypecodes = (options) =>
  useRequest({
    path: `/properties/typecodes`,
    translated: true,
    options,
  })
