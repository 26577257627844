import { ObjectPageSection } from '@fioneer/ui5-webcomponents-react'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import CreateDealWorkingVersionButton from 'components/domains/deals/deal-adjustment/create/CreateDealWorkingVersionButton'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { useDealWorkingVersionToolbarButtons } from 'components/domains/deals/deal-adjustment/useDealWorkingVersionToolbarButtons'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import ChineseWallToggle from 'components/domains/deals/overview/ChineseWallToggle'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useAllowedDealAdjustmentWorkingVersion, {
  DealAdjustmentAllowedOperations,
} from 'hooks/services/deals/deal-adjustment/useAllowedDealAdjustmentWorkingVersion'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'
import DealOverviewManagerTab from 'routes/deals/overview/DealOverviewManagerTab'
import DealOverviewSummaryTab from 'routes/deals/overview/DealOverviewSummaryTab'

const DealOverview = () => {
  const {
    deal: { dealUuid, workingVersion },
    allowedOperations,
  } = useContext(DealContext)
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.detail.overview' })
  const location = useLocation()
  const navigate = useNavigate()
  const { toggleWorkingVersionButton, manageWorkingVersionButton } =
    useDealWorkingVersionToolbarButtons()

  const isAllowedToUpdateDeal = allowedOperations.includes(dealsPermissions.updateDeal)

  const {
    isLoadingAllowed,
    data: { allowedOperations: dealAdjustmentAllowedOperations = [] } = {},
  } = useAllowedDealAdjustmentWorkingVersion(dealUuid)
  const isCreateDealAdjustmentWorkingVersionAllowed = useMemo(
    () =>
      !isLoadingAllowed &&
      dealAdjustmentAllowedOperations.includes(DealAdjustmentAllowedOperations.CREATE),
    [dealAdjustmentAllowedOperations, isLoadingAllowed],
  )

  const isTabLocation = useCallback(
    (tabLocation) => tabLocation === location.hash.substring(1),
    [location.hash],
  )

  useEffect(() => {
    if (location?.pathname.slice(-1) !== '/') {
      navigate({ ...location, pathname: `${location.pathname}/` }, { replace: true })
    }
  }, [location, navigate])

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [dealUuid],
  )

  const isWorkingVersion = useMemo(
    () => workingVersion === WorkingVersionType.WORKING_VERSION,
    [workingVersion],
  )

  const headerActions = useMemo(() => {
    const hasCWToggle = isTabLocation('manager-tab') && !isWorkingVersion

    return createSegmentedHeaderActions(
      [toggleWorkingVersionButton, manageWorkingVersionButton],
      [
        hasCWToggle && (
          <ChineseWallToggle
            dealUuid={dealUuid}
            isAllowedToUpdateDeal={isAllowedToUpdateDeal}
            key="chinese-wall-toggle"
          />
        ),
      ],
      [
        <CreateDealEventAction key="create-event-action" />,
        isCreateDealAdjustmentWorkingVersionAllowed && (
          <CreateDealWorkingVersionButton key="create-deal-working-version" />
        ),
        markFavoriteAction,
      ],
    )
  }, [
    dealUuid,
    isCreateDealAdjustmentWorkingVersionAllowed,
    isAllowedToUpdateDeal,
    isTabLocation,
    isWorkingVersion,
    manageWorkingVersionButton,
    markFavoriteAction,
    toggleWorkingVersionButton,
  ])

  return (
    <DealDetailsWrapper
      isTabPage
      actions={headerActions}
      additionalProperties={{ includeUrlQuery: true, defaultSectionId: 'summary-tab' }}
    >
      <ObjectPageSection id="summary-tab" titleText={t('summary')}>
        <DealOverviewSummaryTab />
      </ObjectPageSection>
      <ObjectPageSection id="manager-tab" titleText={t('manager')}>
        <DealOverviewManagerTab />
      </ObjectPageSection>
    </DealDetailsWrapper>
  )
}

export default DealOverview
