import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TILE_VERSION } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import FurtherDetailsGranularityTenantIndustryTileV1 from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/FurtherDetailsGranularityTenantIndustryTileV1'
import FurtherDetailsGranularityTenantIndustryTileV2 from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/FurtherDetailsGranularityTenantIndustryTileV2'

const FurtherDetailsGranularityTenantIndustryTile = ({ tileId, isPdfView }) => {
  const { version } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const assetValuationOverviewTileViewModeForVersion = useMemo(() => {
    switch (version) {
      case TILE_VERSION.V1:
        return (
          <FurtherDetailsGranularityTenantIndustryTileV1 tileId={tileId} isPdfView={isPdfView} />
        )
      default:
        return (
          <FurtherDetailsGranularityTenantIndustryTileV2 tileId={tileId} isPdfView={isPdfView} />
        )
    }
  }, [isPdfView, tileId, version])

  return assetValuationOverviewTileViewModeForVersion
}

FurtherDetailsGranularityTenantIndustryTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default FurtherDetailsGranularityTenantIndustryTile
