import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionStageVoteOptions } from 'api/decision-process/decisionProcessApi'
import DecisionStageApprovalApproveWithConditionsDialog from 'components/domains/business-events-and-tasks/decision-stage/approval/shared/DecisionStageApprovalApproveWithConditionsDialog'
import SemanticButton, { SemanticButtonDesign } from 'components/ui/button/SemanticButton'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import { emptyContent } from 'components/ui/rich-text-editor/CWPCKEditor'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useOverarchingVoteError from 'hooks/services/business-events-and-tasks/decision-process/stages/error-checks/useOverarchingVoteError'
import useEditDecisionStage from 'hooks/services/business-events-and-tasks/decision-process/stages/useEditDecisionStage'

const DecisionStageApprovalOverarchingApproveWithConditionsButton = ({
  eventId,
  decisionStageId,
  decisionStageType,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'decision-stage.vote-options.overarching.approve-with-conditions',
  })

  const [currentCondition, setCurrentCondition] = useState(emptyContent)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const showMessageBox = useShowMessageBox()
  const { mutate: editDecisionStage } = useEditDecisionStage()
  const queryClient = useQueryClient()
  const { format, localePattern } = useShortDateFormatter()
  const today = format(new Date().toISOString().substring(0, 10), localePattern)
  const [newDecisionDate, setNewDecisionDate] = useState(today)

  const { onError } = useOverarchingVoteError()

  const onApproveWithConditionsSuccess = useCallback(() => {
    setCurrentCondition(emptyContent)
    showMessageBox({
      type: MessageBoxTypes.Success,
      titleText: t('success.title'),
      children: t('success.text'),
      actions: [MessageBoxActions.OK],
    })
    queryClient.invalidateQueries(['events', eventId, 'decision-stages'])
  }, [showMessageBox, eventId, queryClient, t])

  const onApproveConfirm = useCallback(() => {
    editDecisionStage(
      {
        decisionStageId,
        eventId,
        decisionDate: newDecisionDate,
        voteStatusCode: decisionStageVoteOptions.approveWithConditions,
        condition: currentCondition,
      },
      { onSuccess: onApproveWithConditionsSuccess, onError },
    )
  }, [
    editDecisionStage,
    decisionStageId,
    eventId,
    newDecisionDate,
    currentCondition,
    onApproveWithConditionsSuccess,
    onError,
  ])

  const onApproveWithConditions = useCallback(() => {
    setIsConfirmDialogOpen(true)
  }, [])

  return (
    <>
      <SemanticButton design={SemanticButtonDesign.Warning} onClick={onApproveWithConditions}>
        {t('action')}
      </SemanticButton>

      <DecisionStageApprovalApproveWithConditionsDialog
        currentCondition={currentCondition}
        setCurrentCondition={setCurrentCondition}
        isDialogOpen={isConfirmDialogOpen}
        setIsDialogOpen={setIsConfirmDialogOpen}
        onApproveWithConditions={onApproveConfirm}
        decisionStageType={decisionStageType}
        newDecisionDate={newDecisionDate}
        setNewDecisionDate={setNewDecisionDate}
        showWarningLabel={true}
      />
    </>
  )
}

DecisionStageApprovalOverarchingApproveWithConditionsButton.propTypes = {
  eventId: PropTypes.string.isRequired,
  decisionStageId: PropTypes.string.isRequired,
  decisionStageType: PropTypes.string.isRequired,
}

export default DecisionStageApprovalOverarchingApproveWithConditionsButton
