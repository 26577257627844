import {
  FlexBox,
  FlexBoxDirection,
  Label,
  ObjectStatus,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionsStatusTypes } from 'api/conditions/conditions'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/view/TableStatusCell.module.css'

const statusTypeToValueState = {
  [conditionsStatusTypes.open]: ValueState.None,
  [conditionsStatusTypes.inProgress]: ValueState.Information,
  [conditionsStatusTypes.done]: ValueState.Success,
}

const TableStatusCell = ({ requirements, statusType, statusName }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix:
      'components.business-events-and-tasks.decision-paper.conditions.table.columns.status',
  })

  const renderedRequirements = useMemo(() => {
    if (!requirements) {
      return
    }
    return (
      <FlexBox className={styles.requirementsFlexbox}>
        <Label>{t('requirements')}</Label>
        <Text>{`${requirements.done}/${requirements.total}`}</Text>
      </FlexBox>
    )
  }, [requirements, t])

  if (isNil(statusType)) {
    return <Text>{'-'}</Text>
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.statusFlexbox}>
      <ObjectStatus inverted state={statusTypeToValueState[statusType]}>
        {statusName}
      </ObjectStatus>
      {renderedRequirements}
    </FlexBox>
  )
}

TableStatusCell.propTypes = {
  statusType: PropTypes.oneOf(Object.values(conditionsStatusTypes)),
  statusName: PropTypes.string,
  requirements: PropTypes.shape({
    total: PropTypes.number.isRequired,
    done: PropTypes.number.isRequired,
  }),
}

export default TableStatusCell
