import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { ErrorDataUnavailableInCell } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/errors/ErrorDataUnavailableInCell'
import SmallLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/loading/SmallLoadingWrapper'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/NumberCell.module.css'
import { useNumberFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'

const propTypes = {
  /** The number value to display. */
  number: PropTypes.number,
  /** An optional prefix to show before the formatted number. */
  prefix: PropTypes.string,
  /** An optional unit to show after the formatted number. */
  unit: PropTypes.string,
  /**
   * Options to modify how the Cell will look like.
   *
   * - `isBold` Display the number value and unit (not the prefix) in bold font. Defaults to false.
   * - `showPrefixColon` Display a colon after the prefix label. Defaults to true.
   * - `showWhitespaceBeforeUnit` Display a white space before the unit text. Defaults to true.
   * - `maximumFractionDigits` Defines the maximum digits of the number value. Defaults to 2.
   * - `minimumFractionDigits` Defines the minimum digits of the number value. Defaults to 2.
   */
  options: PropTypes.shape({
    isBold: PropTypes.bool,
    showPrefixColon: PropTypes.bool,
    showWhitespaceBeforeUnit: PropTypes.bool,
    maximumFractionDigits: PropTypes.number,
    minimumFractionDigits: PropTypes.number,
  }),
  /** Defines whether a loading spinner is shown. */
  isLoading: PropTypes.bool,
  /** Defines whether a loading spinner is shown. */
  isFetching: PropTypes.bool,
  /** Defines whether an error is shown. */
  isError: PropTypes.bool,
  /** Allows to define a custom error component. Defaults to undefined so that `<ErrorDataUnavailableInCell />` is shown. */
  errorToDisplay: PropTypes.node,
  /** Defines additional styling to the outmost div of the cell's content. */
  className: PropTypes.string,
}

/**
 * Shared Number Cell for usage within the Responsive and the Analytical Table.
 *
 * It displays either just a numeric value, a numeric value with its unit, a label prefix before the number, or both.
 *
 * @typedef Props
 * @property {Number} number The number value to display.
 * @property {String} prefix An optional prefix to show before the formatted number.
 * @property {String} unit An optional unit to show after the formatted number.
 * @property {Object} options Options to modify how the Cell will look like.
 * @property {Boolean} options.isBold Display the number value and unit (not the prefix) in bold font. Defaults to false.
 * @property {Boolean} options.showPrefixColon Display a colon after the prefix label. Defaults to true.
 * @property {Boolean} options.showWhitespaceBeforeUnit Display a white space before the unit text. Defaults to true.
 * @property {Number} options.maximumFractionDigits Defines the maximum digits of the number value. Defaults to 2.
 * @property {Number} options.minimumFractionDigits Defines the minimum digits of the number value. Defaults to 2.
 * @property {Boolean} isLoading Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} isFetching Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} isError Defines whether an error is shown.
 * @property {React.ReactNode} errorToDisplay Allows to define a custom error component. Defaults to undefined so that <ErrorDataUnavailableInCell /> is shown.
 * @property {String} className Defines additional styling to the outmost div of the cell's content.
 */

/**
 * @param {Props} props Note for Storybook: cf. code for complete JSDoc
 */
const NumberCell = ({
  number,
  prefix,
  unit,
  options: {
    isBold = false,
    showPrefixColon = true,
    showWhitespaceBeforeUnit = true,
    maximumFractionDigits = 2,
    minimumFractionDigits = 2,
  } = {},
  isLoading = false,
  isFetching = false,
  isError = false,
  className,
  errorToDisplay,
}) => {
  const formatNumber = useNumberFormatter({
    maximumFractionDigits,
    minimumFractionDigits,
  })

  const renderNumber = () => {
    let numberString = formatNumber(number)

    if (!isNil(unit) && showWhitespaceBeforeUnit) {
      numberString += ` ${unit}`
    } else if (!isNil(unit)) {
      numberString += `${unit}`
    }
    return numberString
  }

  const renderContent = () => {
    if (isNil(number)) {
      return <div />
    }

    return (
      <FlexBox direction={FlexBoxDirection.Row} className={className ?? ''}>
        {prefix && (
          <>
            <Label showColon={showPrefixColon}>{prefix}</Label>&nbsp;
          </>
        )}
        <span className={isBold ? styles.textBold : ''}>{renderNumber()}</span>
      </FlexBox>
    )
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading || isFetching}
      isError={isError}
      error={errorToDisplay ?? <ErrorDataUnavailableInCell />}
      renderContent={renderContent}
    />
  )
}

NumberCell.propTypes = propTypes

export default NumberCell
