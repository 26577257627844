import { TableRowType, Text } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CovenantCheckSelectionHelperCovenantsColumn from 'components/domains/conditions/covenant-check-selection/columns/CovenantCheckSelectionHelperCovenantsColumn'
import CovenantCheckSelectionHelperDealsColumn from 'components/domains/conditions/covenant-check-selection/columns/CovenantCheckSelectionHelperDealsColumn'
import { CovenantCheckSelectionTableColums } from 'components/domains/conditions/covenant-check-selection/useCovenantCheckSelectionTableColumnDefinitions'
import styles from 'components/domains/deals/covenants/covenant-checks-table/CovenantChecksTable.module.css'
import LoadingEventEntity from 'components/domains/deals/covenants/shared/LoadingEventEntity'
import StatusCell from 'components/domains/deals/covenants/shared/StatusCell'
import Entity from 'components/ui/data/Entity'
import ReferenceEntities from 'components/ui/data/ReferenceEntities'
import { ReferenceEntityType } from 'components/ui/data/ReferenceEntityType'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { useMultipleDealsByUuidMini } from 'hooks/services/deals/useMultipleDealsByUuidMini'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const useCovenantCheckSelectionTableData = ({ data, mainEntityType }) => {
  const { format: formatDate } = useShortDateFormatter()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.covenant-check-selection.table',
  })

  const dealUuids = useMemo(() => {
    const uuidsSet = new Set()
    data?.forEach(({ monitoringReferenceEntity, covenants }) => {
      if (monitoringReferenceEntity?.type === ReferenceEntityType.Deal) {
        uuidsSet.add(monitoringReferenceEntity.id)
      }
      covenants.forEach((covenant) => {
        if (covenant?.mainEntity?.type === ReferenceEntityType.Deal) {
          uuidsSet.add(covenant.mainEntity.id)
        }
      })
    })
    return Array.from(uuidsSet)
  }, [data])

  const {
    data: allDeals,
    isLoading: isDealsLoading,
    isError: isDealsError,
  } = useCombinedQueryResults(useMultipleDealsByUuidMini(dealUuids))

  const dealUuidToIdMap = useMemo(
    () => Object.fromEntries(allDeals?.map(({ dealUuid, dealId }) => [dealUuid, dealId]) ?? []),
    [allDeals],
  )

  const getCovenantDeals = useCallback(
    (covenants) =>
      Array.from(new Set(covenants.map(({ mainEntity: { id } }) => id)))
        .map((covenantDealUuid) => allDeals?.find(({ dealUuid }) => dealUuid === covenantDealUuid))
        .filter((deal) => !isNil(deal)),
    [allDeals],
  )

  const tableData = useMemo(
    () =>
      (data ?? []).map(
        ({
          covenantCheckUuid,
          breach,
          covenants,
          keyDate,
          firstDeliveryDate,
          name,
          status,
          monitoringReferenceEntity,
          linkedEventId,
        }) => {
          const covenantCheckEntityProps = {
            name: name,
            link: undefined,
            openInNewTab: undefined,
          }

          const covenantDeals = getCovenantDeals(covenants)
          const dealsColumn =
            mainEntityType === cwpEntityTypes.BUSINESS_PARTNER
              ? {
                  [CovenantCheckSelectionTableColums.deals]: {
                    value: covenantDeals.map(({ name: dealName }) => dealName).join(','),
                    cellComponent: (
                      <CovenantCheckSelectionHelperDealsColumn
                        isLoading={isDealsLoading}
                        isError={isDealsError}
                        covenantDeals={covenantDeals}
                      />
                    ),
                  },
                }
              : {}

          return {
            rowKey: covenantCheckUuid,
            rowProperties: {
              className: breach ? styles.markLeftBorder : undefined,
              type: TableRowType.Active,
            },
            [CovenantCheckSelectionTableColums.covenantCheck]: {
              value: name,
              cellComponent: <Entity {...covenantCheckEntityProps} />,
            },
            ...dealsColumn,
            [CovenantCheckSelectionTableColums.monitoringReferenceType]: {
              value: t(`enum.monitoring-reference-type.${monitoringReferenceEntity?.type}`),
              cellComponent: (
                <span>
                  {t(`enum.monitoring-reference-type.${monitoringReferenceEntity?.type}`)}
                </span>
              ),
            },
            [CovenantCheckSelectionTableColums.monitoringReference]: {
              value: monitoringReferenceEntity?.id,
              cellComponent: (
                <ReferenceEntities
                  type={monitoringReferenceEntity.type}
                  ids={[monitoringReferenceEntity.id]}
                />
              ),
            },
            [CovenantCheckSelectionTableColums.covenants]: {
              value: covenants.map(({ name: covenantName }) => covenantName).join(' '),
              cellComponent: (
                <CovenantCheckSelectionHelperCovenantsColumn
                  isLoading={isDealsLoading}
                  isError={isDealsError}
                  covenants={covenants}
                  dealUuidToIdMap={dealUuidToIdMap}
                />
              ),
            },
            [CovenantCheckSelectionTableColums.monitoringEvent]: {
              value: linkedEventId,
              cellComponent: <LoadingEventEntity eventId={linkedEventId} />,
            },
            [CovenantCheckSelectionTableColums.keyDate]: {
              value: keyDate,
              cellComponent: formatDate(keyDate),
            },
            [CovenantCheckSelectionTableColums.status]: {
              value: t(`enum.status.${status}`),
              cellComponent: <StatusCell status={status} />,
            },
            [CovenantCheckSelectionTableColums.firstDeliveryDate]: {
              value: firstDeliveryDate,
              cellComponent: formatDate(firstDeliveryDate),
            },
            [CovenantCheckSelectionTableColums.breach]: {
              value: t(`enum.breach.${breach}`),
              cellComponent: <Text>{t(`enum.breach.${breach}`)}</Text>,
            },
          }
        },
      ),
    [
      data,
      dealUuidToIdMap,
      formatDate,
      getCovenantDeals,
      isDealsError,
      isDealsLoading,
      mainEntityType,
      t,
    ],
  )
  return useMemo(() => ({ tableData }), [tableData])
}

export default useCovenantCheckSelectionTableData
