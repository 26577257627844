import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { read, utils } from 'xlsx'

const excelColumnHeaders = {
  name: 'Name',
  type: 'Type',
  refNumber: 'RefNr',
  approvalLevel: 'ApprovalLevel',
  description: 'Description',
}

const excelColumnKeys = {
  name: 'A',
  type: 'B',
  refNumber: 'C',
  approvalLevel: 'D',
  description: 'E',
}

const isValidHeader = (row) =>
  Object.values(row).length === Object.values(excelColumnHeaders).length &&
  row[excelColumnKeys.name] === excelColumnHeaders.name &&
  row[excelColumnKeys.type] === excelColumnHeaders.type &&
  row[excelColumnKeys.refNumber] === excelColumnHeaders.refNumber &&
  row[excelColumnKeys.approvalLevel] === excelColumnHeaders.approvalLevel &&
  row[excelColumnKeys.description] === excelColumnHeaders.description

const isValidCondition = (row) =>
  !isEmpty(row[excelColumnKeys.name]) &&
  !isEmpty(row[excelColumnKeys.type]) &&
  Object.keys(row).every((key) => Object.values(excelColumnKeys).includes(key))

const getInternalTypeCodeByName = (internalTypeName, internalTypes) => {
  const externalType = internalTypes?.types?.find(({ name }) => name === internalTypeName)
  if (isNil(externalType?.code)) {
    throw new Error()
  }
  return externalType.code
}

const getApprovalLevelCodeByName = (approvalLevelName, approvalLevels) => {
  const externalType = approvalLevels?.approvalLevels?.find(
    ({ name }) => name === approvalLevelName,
  )
  if (isNil(externalType?.code)) {
    throw new Error()
  }
  return externalType.code
}

const rowToCondition = (row, internalTypes, approvalLevels) => ({
  name: row[excelColumnKeys.name],
  type: getInternalTypeCodeByName(row[excelColumnKeys.type], internalTypes),
  refNumber: row[excelColumnKeys.refNumber],
  approvalLevel: row[excelColumnKeys.approvalLevel]
    ? getApprovalLevelCodeByName(row[excelColumnKeys.approvalLevel], approvalLevels)
    : undefined,
  description: row[excelColumnKeys.description],
})

const excelContentToConditions = (excelContent, internalTypes, approvalLevels) => {
  const conditions = []
  excelContent.every((row, index) => {
    if (index === 0) {
      if (!isValidHeader(row)) {
        throw new Error()
      }
      return true
    }
    if (!isValidCondition(row)) {
      throw new Error()
    }
    conditions.push(rowToCondition(row, internalTypes, approvalLevels))
    return true
  })

  return conditions
}

export const readExcel = ({
  loadEvent,
  internalTypes,
  approvalLevels,
  onUploadSuccess,
  onUploadError,
}) => {
  try {
    const bufferArray = loadEvent?.target.result
    const workBook = read(bufferArray, { type: 'buffer' })
    const workSheetName = workBook.SheetNames[0]
    const workSheet = workBook.Sheets[workSheetName]
    const excelContent = utils.sheet_to_json(workSheet, {
      header: 'A',
      range: 0,
    })
    const loadedConditions = excelContentToConditions(excelContent, internalTypes, approvalLevels)
    if (isEmpty(loadedConditions)) {
      onUploadError()
      return
    }
    onUploadSuccess(loadedConditions)
  } catch (error) {
    onUploadError()
  }
}
