import { AnalyticalTableScaleWidthMode } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TABLE_HEADER_ROW_HEIGHT } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/constants'
import mapAuthorityLevelCalculatorDataToTableStructure from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/utils/mapper/mapAuthorityLevelCalculatorDataToTableStructure'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/analytical/AnalyticalTableWithToolbar'
import { useCollapseExpandTableActions } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/analytical/useCollapseExpandTableActions'
import AnalyticalTableScalingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/AnalyticalTableScalingWrapper'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/authority-level-calculator/SharedAuthorityLevelCalculatorTable.module.css'
import filterUnitMembers from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/authority-level-calculator/utils/filterUnitMembers'
import moveBorrowerToFrontAndMark from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/authority-level-calculator/utils/moveBorrowerToFrontAndMark'

const SharedAuthorityLevelCalculatorTable = ({
  data,
  isPdfView = false,
  showConfirmationForLinks = false,
  dealBorrowerBpId,
  columnDefinitionsFunction,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.shared.authority-level-calculator.table',
  })
  const { t: tNoPrefix } = useTranslation('decisionPaper')
  const tableRef = useRef(null)
  const [tableWidth, setTableWidth] = useState(tableRef?.current?.totalColumnsWidth ?? 0)

  useEffect(() => {
    setTableWidth(tableRef?.current?.totalColumnsWidth ?? 0)
  }, [tableRef?.current?.totalColumnsWidth])

  const {
    handleOnRowExpand,
    additionalTableActions: collapseExpandActions = [],
    expandOrCollapseSubRows,
  } = useCollapseExpandTableActions(tableRef)

  useEffect(() => {
    if (isPdfView) {
      expandOrCollapseSubRows(true)
    }
  }, [expandOrCollapseSubRows, isPdfView])

  const { backendTableData, containsGuarantor } = useMemo(
    () => mapAuthorityLevelCalculatorDataToTableStructure(tNoPrefix, data),
    [data],
  )

  const tableData = useMemo(() => {
    let mergedData = backendTableData
    mergedData = filterUnitMembers(mergedData, dealBorrowerBpId)
    mergedData = moveBorrowerToFrontAndMark(mergedData, dealBorrowerBpId)
    return mergedData
  }, [backendTableData, dealBorrowerBpId])

  const columns = columnDefinitionsFunction({
    showGuarantorColumn: containsGuarantor,
    showConfirmationForLinks,
  })

  return (
    <AnalyticalTableScalingWrapper isPdfView={isPdfView} tableWidth={tableWidth}>
      <AnalyticalTableWithToolbar
        title={t('title')}
        nrOfEntries={
          tableData.reduce((acc, cur) => (cur.subRows ? cur.subRows.length : 0) + acc, 0) ?? 0
        }
        additionalActions={!isPdfView ? collapseExpandActions : []}
        id="authorityLevelCalculatorTable"
        sortable={false}
        minRows={0}
        className={styles['authorityLevelCalculator-table']}
        columns={columns}
        overscanCountHorizontal={99}
        // high number of visible rows fixes re-rendering of height on expand
        visibleRows={99}
        // rowHeight needs to be set to a non-empty string to not show an empty row at the end
        rowHeight={'individual'}
        headerRowHeight={TABLE_HEADER_ROW_HEIGHT}
        onRowExpandChange={(event) => handleOnRowExpand(event.detail.row)}
        data={tableData}
        isPdfView={isPdfView}
        isTreeTable={true}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
        withRowHighlight={false}
        reactTableOptions={{
          autoResetPage: false,
          autoResetExpanded: false,
          autoResetSelectedRows: false,
          autoResetSortBy: false,
          autoResetFilters: false,
          selectSubRows: false,
        }}
        ref={tableRef}
      />
    </AnalyticalTableScalingWrapper>
  )
}

SharedAuthorityLevelCalculatorTable.propTypes = {
  // PropTypes are not defined in depth as this is a part of the backend response
  data: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    totalValues: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    unitMembers: PropTypes.array,
  }),
  isPdfView: PropTypes.bool,
  showConfirmationForLinks: PropTypes.bool,
  dealBorrowerBpId: PropTypes.string,
  columnDefinitionsFunction: PropTypes.func.isRequired,
}

export default SharedAuthorityLevelCalculatorTable
