import PropTypes from 'prop-types'
import { createContext, useMemo } from 'react'

export const PropertyRentRollKpiChartsDataContext = createContext({})

/**
 * adds a context with the hook for fetching the current rent roll KPIs to be used by chart components
 * on both single and multi property pages. To be used in conjunction with the `useCurrentRentRollOverviewPropertyKpis`
 * hook.
 */
const PropertyRentRollKpiChartsDataProvider = ({ children, usePropertyRentRollKpiChartsData }) => {
  const contextValue = useMemo(
    () => ({
      usePropertyRentRollKpiChartsData,
    }),
    [usePropertyRentRollKpiChartsData],
  )
  return (
    <PropertyRentRollKpiChartsDataContext.Provider value={contextValue}>
      {children}
    </PropertyRentRollKpiChartsDataContext.Provider>
  )
}

PropertyRentRollKpiChartsDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
  usePropertyRentRollKpiChartsData: PropTypes.func.isRequired,
}

export default PropertyRentRollKpiChartsDataProvider
