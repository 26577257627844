const mapAsSponsorCardTableData = (dealSponsors) =>
  dealSponsors.map((sponsor) => {
    const {
      borrower,
      dealId,
      dealName,
      dealCreatedDate,
      dealStatus,
      accountManager,
      dealCommitment,
      dealCommitmentHeadquarter,
      businessPartnerUnits,
    } = sponsor ?? {}

    return {
      borrower: { id: borrower.bpId, name: borrower.fullName },
      deal: { id: dealId, name: dealName },
      units: businessPartnerUnits.units,
      creationYear: dealCreatedDate && new Date(dealCreatedDate).getFullYear(),
      status: dealStatus,
      accountManager,
      dealCommitment: {
        value: { amount: dealCommitment.number, currency: dealCommitment.currencyCode },
        valueHeadquarter: {
          amount: dealCommitmentHeadquarter?.number,
          currency: dealCommitmentHeadquarter?.currencyCode,
        },
      },
    }
  })

export default mapAsSponsorCardTableData
