import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Icon,
  Link,
  Popover,
  PopoverHorizontalAlign,
  PopoverPlacementType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useId, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/documents/documents-table/NameCell.module.css'

const NameCell = ({ cell }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.documents.table.columns',
  })

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const onIconMouseEnter = useCallback(() => setIsPopoverOpen(true), [setIsPopoverOpen])
  const onIconMouseLeave = useCallback(() => setIsPopoverOpen(false), [setIsPopoverOpen])

  const popoverId = useId()

  const isDocument = !Array.isArray(cell.row?.original?.subFolders)
  if (!isDocument)
    return (
      <div className={styles.nameWrapper}>
        <div className={styles.nameOverflow}>{cell.value.name}</div>
      </div>
    )

  const handleClick = (id) => {
    if (!id) return
    window.open(`/documents/${id}`, '_blank')
  }

  return (
    <>
      <div className={styles.nameOverflow}>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleClick(cell?.row?.original?.id)}
        >
          {cell.value.name}
        </Link>
      </div>
      <div>
        {cell.value.hasReferencedRequirement && (
          <Icon
            id={popoverId}
            name="information"
            className={styles.icon}
            onMouseEnter={onIconMouseEnter}
            onMouseLeave={onIconMouseLeave}
          />
        )}
        {isPopoverOpen &&
          createPortal(
            <Popover
              opener={popoverId}
              header={
                <FlexBox
                  direction={FlexBoxDirection.Row}
                  justifyContent={FlexBoxJustifyContent.Start}
                  alignItems={FlexBoxAlignItems.Center}
                  className={styles.popoverTitle}
                >
                  <Icon name="information" />
                  {t('namecell.heading')}
                </FlexBox>
              }
              placementType={PopoverPlacementType.Bottom}
              horizontalAlign={PopoverHorizontalAlign.Center}
              open={isPopoverOpen}
              className={styles.popoverBody}
            >
              {t('namecell.body')
                .split('\n')
                .map((part) => (
                  <div key={part}>{part}</div>
                ))}
            </Popover>,
            document.body,
          )}
      </div>
    </>
  )
}

NameCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string,
        subFolders: PropTypes.array,
      }),
    }),
    value: PropTypes.shape({
      name: PropTypes.string,
      hasReferencedRequirement: PropTypes.bool,
    }),
  }).isRequired,
}

export default NameCell
