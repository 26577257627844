import {
  FlexBox,
  FlexBoxDirection,
  Label,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import LoadingDropdown from 'components/ui/dropdown/LoadingDropdown'
import useMarketCountries from 'hooks/services/markets/useMarketCountries'
import { setMarketCountry } from 'redux/slices/markets/marketCreationSlice'

export const centerOfGermany = { lat: 51.163361, lng: 10.447683 }

const MarketAreaPanel = () => {
  const { marketCountry } = useSelector((state) => state.markets.marketCreation)
  const { isError, isLoading, data: marketCountryData } = useMarketCountries()
  const [formErrors, setFormErrors] = useState({})
  const { t } = useTranslation('translation', { keyPrefix: 'pages.markets.overview.create-dialog' })
  const dispatch = useDispatch()

  const marketCountries = () => {
    if (isLoading || isError) return { isLoading, isError, data: undefined }

    return {
      data: marketCountryData.countries.map(({ code, name }) => ({ code, displayName: name })),
      isLoading: false,
      isError: false,
    }
  }

  return (
    <>
      <FlexBox
        direction={FlexBoxDirection.Column}
        style={{ gridColumn: 'span 2', marginLeft: '-16px', marginRight: '-16px' }}
      >
        <Label showColon required>
          {t('area-panel.countries-column')}
        </Label>
        <LoadingDropdown
          id="filter-market-countries-select"
          value={marketCountry}
          onChange={(value) => {
            dispatch(setMarketCountry(value))
            if (value === '') {
              setFormErrors((oldState) => ({ ...oldState, marketCountry: true }))
              return
            }
            setFormErrors((oldState) => ({ ...oldState, marketCountry: false }))
          }}
          onBlur={(value) => {
            if (!value) {
              setFormErrors((oldState) => ({ ...oldState, marketCountry: true }))
              return
            }
            setFormErrors((oldState) => ({ ...oldState, marketCountry: false }))
          }}
          useLoader={marketCountries}
          initialDropdownValue={' '}
          valueState={formErrors.marketCountry ? ValueState.Error : ValueState.None}
          style={{ width: '100%' }}
          valueStateMessage={<Text>{t('market.country.error-state')}</Text>}
        />
      </FlexBox>
    </>
  )
}
export default MarketAreaPanel
