import { ObjectPageSection } from '@fioneer/ui5-webcomponents-react'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionsEntityTypes } from 'api/conditions/conditions'
import { conditionsAllowedOperations as conditionsAllowedOperationsAll } from 'api/conditions/conditionsAllowedOperations'
import { useDealWorkingVersionToolbarButtons } from 'components/domains/deals/deal-adjustment/useDealWorkingVersionToolbarButtons'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import OpenConditionsChangelogAction from 'components/domains/deals/header-actions/OpenConditionsChangelogAction'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useConditionsAllowedOperations from 'hooks/services/conditions/useConditionsAllowedOperations'
import useDealMini from 'hooks/services/deals/useDealMini'
import ConditionRequirementsPage from 'routes/conditions/ConditionRequirementsPage'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'
import ConditionsPage from 'routes/conditions/ConditionsPage'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'

const DealConditions = () => {
  const {
    deal: { dealUuid },
  } = useContext(DealContext)

  const { t } = useTranslation('translation', { keyPrefix: 'pages.conditions' })
  const { t: tNoPrefix } = useTranslation()
  const { toggleWorkingVersionButton, manageWorkingVersionButton } =
    useDealWorkingVersionToolbarButtons()

  const breadcrumb = useMemo(() => ({ text: t('title') }), [t])
  const additionalProperties = useMemo(() => ({ includeUrlQuery: true }), [])
  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={dealUuid}
        entityType={conditionsEntityTypes.deal}
      />
    ),
    [dealUuid],
  )

  const headerActions = useMemo(
    () =>
      createSegmentedHeaderActions(
        [<OpenConditionsChangelogAction key="open-conditions-changelog-action" />],
        [toggleWorkingVersionButton, manageWorkingVersionButton],
        [<CreateDealEventAction key="create-event-action" />, markFavoriteAction],
      ),
    [manageWorkingVersionButton, markFavoriteAction, toggleWorkingVersionButton],
  )

  const {
    isLoading: isConditionsAllowedOperationsLoading,
    isError: isConditionsAllowedOperationsError,
    data: conditionsAllowedOperations = { allowedOperations: [] },
  } = useConditionsAllowedOperations()

  const {
    isFetching: isDealMiniLoading,
    isError: isDealMiniError,
    data: dealMini,
  } = useDealMini(dealUuid)

  const conditionsContext = useMemo(
    () => ({
      entityRef: {
        entityId: dealUuid,
        entityDisplayId: dealMini?.dealId,
        entityType: conditionsEntityTypes.deal,
      },
      allowedOperations: conditionsAllowedOperations.allowedOperations,
      referencesMapping: {
        [cwpEntityTypes.BUSINESS_PARTNER]: {
          initialId: dealMini?.borrowerBpId,
        },
        [cwpEntityTypes.PROPERTY]: {
          dealId: dealUuid,
        },
      },
    }),
    [
      conditionsAllowedOperations.allowedOperations,
      dealMini?.borrowerBpId,
      dealMini?.dealId,
      dealUuid,
    ],
  )

  const renderContent = useCallback(() => {
    if (
      !conditionsAllowedOperations.allowedOperations.includes(
        conditionsAllowedOperationsAll.readCondition,
      )
    ) {
      return (
        <ErrorDataUnavailableInContent
          titleText={tNoPrefix('app.permission-error.title')}
          subtitleText={tNoPrefix('app.permission-error.subtitle')}
        />
      )
    }
    return (
      <ConditionsContext.Provider value={conditionsContext}>
        <DealDetailsWrapper
          childBreadcrumbs={breadcrumb}
          isTabPage={true}
          additionalProperties={additionalProperties}
          actions={headerActions}
        >
          <ObjectPageSection id="conditions" titleText={t('tabs.conditions')}>
            <ConditionsPage />
          </ObjectPageSection>
          <ObjectPageSection id="requirements" titleText={t('tabs.requirements')}>
            <ConditionRequirementsPage />
          </ObjectPageSection>
        </DealDetailsWrapper>
      </ConditionsContext.Provider>
    )
  }, [
    additionalProperties,
    breadcrumb,
    conditionsAllowedOperations,
    conditionsContext,
    headerActions,
    t,
    tNoPrefix,
  ])

  return (
    <RequestStateResolver
      isLoading={isConditionsAllowedOperationsLoading || isDealMiniLoading}
      isError={isConditionsAllowedOperationsError || isDealMiniError}
      renderContent={renderContent}
      errorToDisplay={<ErrorDataUnavailableInContent />}
    />
  )
}

DealConditions.propTypes = {}

export default DealConditions
