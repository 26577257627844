import { createContext } from 'react'

export const CurrentRentRollContext = createContext()

export const COMPARABLE_STATUS = {
  NEW: 'new',
  OLD: 'old',
  COMPARABLE: 'comparable',
  NOT_COMPARABLE: 'notComparable',
}

export const COMPARABLE_STATUS_COLORS = {
  [COMPARABLE_STATUS.NEW]: 'green',
  [COMPARABLE_STATUS.OLD]: 'red',
  NEUTRAL: 'var(--sapList_BorderColor)',
}
