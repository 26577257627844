import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DecisionStageInformationDisplay from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageInformationDisplay'
import calculateDecisionStageToDisplay from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageToDisplayCalculator'
import styles from 'components/domains/business-events-and-tasks/events/AssigneeTableCell.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useDecisionStages from 'hooks/services/business-events-and-tasks/decision-process/stages/useDecisionStages'

const CurrentDecisionStageTableCell = ({ eventId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.event-overview.table.cells.current-decision-stage',
  })
  const { isLoading, isError, data: decisionStageData } = useDecisionStages({ eventId })

  const renderContent = () => {
    if (decisionStageData.decisionStages.length === 0) return

    const decisionStageToDisplay = calculateDecisionStageToDisplay(decisionStageData.decisionStages)
    return <DecisionStageInformationDisplay decisionStage={decisionStageToDisplay} />
  }

  const errorMessage = <Label className={styles['error-label']}>{t('error.description')}</Label>

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderContent}
      error={errorMessage}
    />
  )
}

CurrentDecisionStageTableCell.propTypes = {
  eventId: PropTypes.string,
}

export default CurrentDecisionStageTableCell
