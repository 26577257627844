import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { filterTypesEmptyValues } from 'components/ui/tables/sorted-tables/useFilters'
import { filters } from 'components/ui/tables/toolbar/propTypes'

const TasksTableIsMandatoryCustomFilter = ({ selectedFilters, setSelectedFilters, columnKey }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.events.tasks-table.custom-mandatory-filter',
  })

  const selectedOption = useMemo(
    () => selectedFilters.find((filter) => filter.columnKey === columnKey)?.value,
    [columnKey, selectedFilters],
  )

  const handleOnOptionChange = useCallback(
    (mandatory) => {
      setSelectedFilters(
        selectedFilters.map((filter) =>
          filter.columnKey === columnKey ? { ...filter, value: mandatory } : filter,
        ),
      )
    },
    [columnKey, selectedFilters, setSelectedFilters],
  )

  return (
    <Select
      id="custom-mandatory-filter-select"
      onChange={({
        detail: {
          selectedOption: { value: mandatory },
        },
      }) => {
        handleOnOptionChange(mandatory)
      }}
      value={selectedOption}
    >
      <Option key="all" value={filterTypesEmptyValues.CUSTOM} />
      <Option key="mandatory" value={true} selected={selectedOption === true}>
        {t('mandatory')}
      </Option>
      <Option key="non-mandatory" value={false} selected={selectedOption === 'false'}>
        {t('non-mandatory')}
      </Option>
    </Select>
  )
}

TasksTableIsMandatoryCustomFilter.propTypes = {
  selectedFilters: filters.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  columnKey: PropTypes.string.isRequired,
}

export default TasksTableIsMandatoryCustomFilter
