import { Text } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/financing-structure/collateral-agreements/MultiCollateralAgreementsTableCell.module.css'
import { EUR_CURRENCY } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/financing-structure/collateral-agreements/shared/constants'
import { useCustomizableCurrencyFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'

export const renderCagCell = ({ cell, t }) => {
  const { isBold, value, displayId, hasWarning } = cell.value ?? {}
  return (
    <div className={[styles.commitmentCell, isBold && styles.textBold].join(' ')}>
      <div className={styles.commitmentCellSingleElement}>
        <div>
          {!isNil(value) && (
            <div>
              <Text
                wrapping={false}
                className={[isBold && styles.textBold, styles.textWithOverflowHandling].join(' ')}
              >
                {value}
              </Text>
            </div>
          )}
          {displayId && (
            <div>
              <Text
                wrapping={false}
                className={[isBold && styles.textLabelColor, styles.textWithOverflowHandling].join(
                  ' ',
                )}
              >
                {displayId}
              </Text>
            </div>
          )}
        </div>
        {hasWarning && (
          <div className={styles.warningLabel}>
            {t('tiles.annual-review.collateral-agreements.table.missing-information.label')}
          </div>
        )}
      </div>
    </div>
  )
}

/**
 * @param {object} params
 * @param {object} params.cell
 * @param {object} [params.cell.value]
 * @param {{value: number, currency: string}} [params.cell.value.cagValue]
 * @param {{value: number, currency: string}} [params.cell.value.cagValueInHeadQuarterCurrency]
 * @param {boolean} [params.cell.value.isBold]
 * @param {(value: number, currency: string, options?: object | undefined) => string} params.formatMoney
 */
export const renderMoneyColumnCell = ({ cell, formatMoney }) => {
  const { isBold, cagValue, cagValueInHeadQuarterCurrency } = cell.value ?? {}

  const isSameCurrency = cagValue?.currency === cagValueInHeadQuarterCurrency?.currency

  return (
    <div className={[styles.moneyCell, isBold && styles.textBold].join(' ')}>
      <div className={styles.moneyCellSingleElement}>
        <div>
          {!isNil(cagValue) && (
            <div className={styles.caretCursor}>
              {formatMoney(cagValue.value, cagValue.currency || EUR_CURRENCY, {
                currencyDisplay: 'code',
              })}
            </div>
          )}
          {!isNil(cagValueInHeadQuarterCurrency) && !isSameCurrency && (
            <div className={`${styles.textLabelColor} ${styles.caretCursor}`}>
              {formatMoney(
                cagValueInHeadQuarterCurrency.value,
                cagValueInHeadQuarterCurrency.currency,
                {
                  currencyDisplay: 'code',
                },
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const MultiCollateralAgreementsTableCell = (props) => {
  const formatMoney = useCustomizableCurrencyFormatter()
  const { t: tFromHook } = useTranslation('decisionPaper')

  return props.renderCell({ cell: props.cell, formatMoney, t: tFromHook })
}

MultiCollateralAgreementsTableCell.propTypes = {
  cell: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  renderCell: PropTypes.func.isRequired,
}
