import { Button, ButtonDesign, FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from 'components/domains/markets/MarketsCreationDialog.module.css'
import GeneralMarketInfoPanel from 'components/domains/markets/panels/GeneralMarketInfoPanel'
import MarketAreaPanel from 'components/domains/markets/panels/MarketAreaPanel'
import MarketExternalIdTypesPanel from 'components/domains/markets/panels/MarketExternalIdTypesPanel'
import Dialog from 'components/ui/dialog/Dialog'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import { useMarketCreation } from 'hooks/services/markets/useMarketCreation'
import { resetMarketCreation } from 'redux/slices/markets/marketCreationSlice'

const MarketsCreationDialog = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.markets.overview.create-dialog' })
  const { t: tNoPrefix } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const client = useQueryClient()
  const showMessageBox = useShowMessageBox()

  const { marketName, marketCode, marketCountry, marketExternalId, marketExternalIdType } =
    useSelector((state) => state.markets.marketCreation)

  const mandatoryFieldsAreFilled = useMemo(
    () => marketCode && marketName && marketCountry,
    [marketCode, marketName, marketCountry],
  )

  const externalIdFieldsInvalid = useMemo(() => {
    if (!marketExternalId && !marketExternalIdType) {
      return false
    }
    return !marketExternalId || !marketExternalIdType
  }, [marketExternalId, marketExternalIdType])

  const handleOnTryAgainClicked = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  const tryAgainButton = useMemo(
    () => (
      <Button onClick={handleOnTryAgainClicked} design={ButtonDesign.Emphasized}>
        {tNoPrefix('buttons.try-again')}
      </Button>
    ),
    [handleOnTryAgainClicked, tNoPrefix],
  )

  const errorMessageBoxCancelButton = useMemo(
    () => <Button design={ButtonDesign.Transparent}>{tNoPrefix('buttons.cancel')}</Button>,
    [tNoPrefix],
  )

  const openErrorMessageBox = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('post-error.description'),
      actions: [tryAgainButton, errorMessageBoxCancelButton],
    })
  }, [tryAgainButton, errorMessageBoxCancelButton, showMessageBox, t])

  const { mutate: createMarket } = useMarketCreation({
    onSuccess: (data) => {
      dispatch(resetMarketCreation())
      client.invalidateQueries(['markets'])
      navigate(`/markets/${data.id}`)
    },
    onError: () => {
      setIsOpen(false)
      openErrorMessageBox()
    },
  })

  const handleOnSubmitClicked = useCallback(() => {
    if (mandatoryFieldsAreFilled) {
      createMarket({
        code: marketCode,
        name: marketName,
        countryCode: marketCountry,
        externalId: marketExternalId,
        externalIdType: marketExternalIdType,
      })
    }
  }, [
    mandatoryFieldsAreFilled,
    createMarket,
    marketCode,
    marketName,
    marketCountry,
    marketExternalId,
    marketExternalIdType,
  ])

  const handleOnCancelClicked = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const createButton = useMemo(
    () => (
      <Button
        onClick={handleOnSubmitClicked}
        design={ButtonDesign.Emphasized}
        disabled={!mandatoryFieldsAreFilled || externalIdFieldsInvalid}
      >
        {t('buttons.create')}
      </Button>
    ),
    [handleOnSubmitClicked, mandatoryFieldsAreFilled, externalIdFieldsInvalid, t],
  )

  const cancelButton = useMemo(
    () => (
      <Button design={ButtonDesign.Transparent} onClick={handleOnCancelClicked}>
        {tNoPrefix('buttons.cancel')}
      </Button>
    ),
    [handleOnCancelClicked, tNoPrefix],
  )

  return (
    <Dialog
      open={isOpen}
      headerText={t('market-popup.title')}
      primaryButton={createButton}
      closeButton={cancelButton}
    >
      <FlexBox direction={FlexBoxDirection.Column} className={styles.marketCreationDialogContent}>
        <GeneralMarketInfoPanel />
        <MarketAreaPanel />
        <MarketExternalIdTypesPanel />
      </FlexBox>
    </Dialog>
  )
}

MarketsCreationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default MarketsCreationDialog
