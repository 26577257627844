import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import LinkWithIdAndConfirmation from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/LinkWithIdAndConfirmation'

const DecisionPaperLeaveConfirmationLink = ({ name, link, id, showConfirmationForLinks }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix:
      'tiles.collaterals.components.business-events-and-tasks.decision-stage.approval-view',
  })
  return (
    <LinkWithIdAndConfirmation
      name={name}
      link={link}
      id={id}
      confirmMessageText={t('confirmation.text')}
      confirmMessageTitle={t('confirmation.title')}
      confirmButtonText={t('confirmation.button')}
      showConfirmationForLinks={showConfirmationForLinks}
    />
  )
}

DecisionPaperLeaveConfirmationLink.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  id: PropTypes.string,
  showConfirmationForLinks: PropTypes.bool.isRequired,
}

export default DecisionPaperLeaveConfirmationLink
