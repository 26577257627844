import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  Link,
  Text,
  Popover,
  PopoverHorizontalAlign,
  PopoverPlacementType,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/risk-monitoring/CollapsibleTextCell.module.css'

const DEFAULT_COLLAPSIBLE_CHARACTER_LIMIT = 75

const CollapsibleTextCell = ({
  text,
  characterLimit = DEFAULT_COLLAPSIBLE_CHARACTER_LIMIT,
  rowId,
  openerId,
}) => {
  const [isTextExpanded, setIsTextExpanded] = useState(false)
  const { t } = useTranslation('decisionPaper')

  if (isEmpty(text)) {
    return ''
  }

  const toggleShowMore = () => {
    setIsTextExpanded(!isTextExpanded)
  }

  const renderPopover = () =>
    createPortal(
      <>
        <Popover
          opener={openerId ?? `collapsible-text-cell-${rowId}`}
          open={isTextExpanded}
          placementType={PopoverPlacementType.Bottom}
          horizontalAlign={PopoverHorizontalAlign.Center}
          onAfterClose={() => setIsTextExpanded(false)}
          className={styles.popover}
        >
          <Text className={styles.popoverWrapper}>{text}</Text>
        </Popover>
      </>,
      document.body,
    )

  return (
    <FlexBox direction={FlexBoxDirection.Row} alignItems={FlexBoxAlignItems.End}>
      <div className={styles.overflowWrapper}>
        {renderPopover()}
        <div data-testid={'shortenedText'}>
          {text?.length <= characterLimit ? text : `${text?.substring(0, characterLimit)}...`}
          {text?.length > characterLimit && (
            <Link onClick={toggleShowMore} id={`collapsible-text-cell-${rowId}`}>
              {isTextExpanded ? t('buttons.less') : t('buttons.more')}
            </Link>
          )}
        </div>
      </div>
    </FlexBox>
  )
}

CollapsibleTextCell.propTypes = {
  text: PropTypes.string.isRequired,
  characterLimit: PropTypes.number,
  rowId: PropTypes.string,
  openerId: PropTypes.string,
}

export default CollapsibleTextCell
