import isNil from 'lodash.isnil'
import {
  ROW_TYPE_UNIT_MEMBER,
  TABLE_ROW_DEFAULT_HEIGHT,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/constants'
import mapProductClasses from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/utils/mapper/mapProductClasses'

const mapUnitMembers = (t, unitMembers, headquarterCurrency) => {
  if (!unitMembers) {
    return []
  }

  const existingPipelineColumns = (importedExistingValues, importedPipelineValues) => ({
    source: [
      t('pages.business-partner.authority-level-calculator.table.columns.source.existing'),
      t('pages.business-partner.authority-level-calculator.table.columns.source.pipeline'),
    ],
    amount: [
      !isNil(importedExistingValues?.amount?.amount)
        ? {
            amount: importedExistingValues?.amount?.amount,
            currency: importedExistingValues?.amount?.currency,
          }
        : undefined,
      !isNil(importedPipelineValues?.amount?.amount)
        ? {
            amount: importedPipelineValues?.amount?.amount,
            currency: importedPipelineValues?.amount?.currency,
          }
        : undefined,
    ],
    pdRegulatory: [importedExistingValues?.pdRegulatory, importedPipelineValues?.pdRegulatory],
    pdRegulatoryClass: [
      importedExistingValues?.pdRegulatoryClass,
      importedPipelineValues?.pdRegulatoryClass,
    ],
    lgdRegulatory: [importedExistingValues?.lgdRegulatory, importedPipelineValues?.lgdRegulatory],
  })

  const adjustedColumns = (adjustedValues) => ({
    adjustedAmount: {
      amount: adjustedValues?.adjustedAmount?.amount,
      currency: adjustedValues?.adjustedAmount?.currency,
    },
    adjustedAmountInAlcCurrency: {
      amount: adjustedValues?.adjustedAmountInAlcCurrency?.amount,
      currency: adjustedValues?.adjustedAmountInAlcCurrency?.currency,
    },
    adjustedPdRegulatory: adjustedValues?.adjustedPdRegulatory,
    adjustedLgdRegulatory: adjustedValues?.adjustedLgdRegulatory,
    adjustedAdditionalInformation: {
      isHighRisk: {
        label: t('pages.business-partner.authority-level-calculator.highRisk'),
        value: adjustedValues?.isHighRisk,
      },
      isOrgankredit: {
        label: t('pages.business-partner.authority-level-calculator.organkredit'),
        value: adjustedValues?.isOrgankredit,
      },
    },
  })

  const calculatedColumns = (calculatedValues) => ({
    pdCalculation: calculatedValues?.pdCalculation,
    pdRegulatoryClassCalculation: calculatedValues?.pdCalculationClass,
    lgdCalculation: calculatedValues?.lgdCalculation,
    elUnweighted: !isNil(calculatedValues?.elUnweighted?.amount)
      ? {
          amount: calculatedValues?.elUnweighted?.amount,
          currency: calculatedValues?.elUnweighted?.currency,
        }
      : undefined,
    elWeighted: !isNil(calculatedValues?.elWeighted?.amount)
      ? {
          amount: calculatedValues?.elWeighted?.amount,
          currency: calculatedValues?.elWeighted?.currency,
        }
      : undefined,
    elBps: calculatedValues?.elRate,
    creditAuthorityLimit: !isNil(calculatedValues?.creditAuthorityLimit?.amount)
      ? {
          amount: calculatedValues?.creditAuthorityLimit?.amount,
          currency: calculatedValues?.creditAuthorityLimit?.currency,
        }
      : undefined,
    elClass: calculatedValues?.elClass,
    additionalInformationCalculation: {
      isHighRisk: {
        label: t('pages.business-partner.authority-level-calculator.highRisk'),
        value: calculatedValues?.isHighRisk,
      },
      isOrgankredit: {
        label: t('pages.business-partner.authority-level-calculator.organkredit'),
        value: calculatedValues?.isOrgankredit,
      },
    },
  })

  const initialPdColumns = (totalValues) => ({
    initialPdRegulatory: totalValues.initialPdRegulatory,
    initialPdRegulatoryClass: totalValues.initialPdRegulatoryClass,
  })

  return unitMembers.map((unitMember) => {
    const rootRowId = `${ROW_TYPE_UNIT_MEMBER}-${unitMember?.unitMemberBpId}`
    return {
      rowHeight: TABLE_ROW_DEFAULT_HEIGHT,
      isSectionHeader: true,
      rowType: ROW_TYPE_UNIT_MEMBER,
      rootRowId: rootRowId,
      unitMemberBpId: unitMember?.unitMemberBpId,
      isInEditMode: false,

      businessPartnerProductClass: {
        name: unitMember?.unitMemberBpFullName,
        id: unitMember?.unitMemberBpId,
      },
      additionalInformation: {
        isHighRisk: {
          label: t('pages.business-partner.authority-level-calculator.highRisk'),
          value: unitMember?.isHighRisk ?? false,
        },
      },
      ...initialPdColumns(unitMember?.totalValues),
      ...existingPipelineColumns(
        unitMember?.totalValues?.importedExistingValues,
        unitMember?.totalValues?.importedPipelineValues,
      ),
      ...adjustedColumns(unitMember?.totalValues?.adjustedValues),
      ...calculatedColumns(unitMember?.totalValues?.calculatedValues),
      subRows: mapProductClasses(
        t,
        unitMember?.productClasses,
        rootRowId,
        unitMember?.unitMemberBpId,
        headquarterCurrency,
      ),
    }
  })
}

export default mapUnitMembers
