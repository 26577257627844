import {
  AnalyticalTableScaleWidthMode,
  IllustratedMessage,
  TextAlign,
  VerticalAlign,
} from '@fioneer/ui5-webcomponents-react'
import find from 'lodash.find'
import get from 'lodash.get'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import OpsMemoTranchesOverviewTableCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche/OpsMemoTranchesOverviewTableCell'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche/OpsMemoTranchesTables.module.css'
import AnalyticalTableScalingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche/shared/AnalyticalTableScalingWrapper'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche/shared/ui/tables/analytical/AnalyticalTableWithToolbar'
import mapTranchesData from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche/utils/mapTranchesData'
import paths from 'routes/paths'

const OpsMemoTranchesTable = ({ tileId, isPdfView = false }) => {
  const tileData = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const { financingData: data } = tileData?.data || {}

  const { dealDisplayId } = tileData?.data?.sourceRender ?? {}

  const navigate = useNavigate()
  const { t } = useTranslation('decisionPaper', { keyPrefix: 'tiles.tranches-table' })
  const { t: tNoPrefix } = useTranslation('decisionPaper')
  const tableRef = useRef(null)
  const [tableWidth, setTableWidth] = useState(tableRef?.current?.totalColumnsWidth ?? 0)

  useEffect(() => {
    setTableWidth(tableRef?.current?.totalColumnsWidth ?? 0)
  }, [tableRef?.current?.totalColumnsWidth])

  const columnDefinitions = () =>
    [
      {
        Header: t('tranche-label'),
        accessor: 'tranche',
        minWidth: 270,
      },
      {
        Header: t('product-type-label'),
        accessor: 'productTypeCode',
        width: 110,
      },
      {
        Header: t('loan-type-label'),
        accessor: 'loanTypeCode',
        width: 110,
      },
      {
        Header: t('commitment-total-label'),
        accessor: 'totalCommitment',
        hAlign: TextAlign.Right,
        width: 180,
      },
      {
        Header: `${t('commitment-label')} (${t('own-share-label')})`,
        accessor: 'commitment',
        hAlign: TextAlign.Right,
        width: 180,
      },
      {
        Header: t('syndicated-amount-total-label'),
        accessor: 'syndicatedAmountTotal',
        hAlign: TextAlign.Right,
        width: 180,
      },
      // "fake" entry to have an additional column to show the navigation arrow
      {
        Header: '',
        accessor: 'navigation',
        vAlign: VerticalAlign.Middle,
        hAlign: TextAlign.Right,
        disableSortBy: true,
        width: 20,
        disableResizing: true,
      },
    ].map((element) => ({
      disableDragAndDrop: true,
      disableResizing: false,
      Cell: OpsMemoTranchesOverviewTableCell,
      vAlign: VerticalAlign.Top,
      ...element,
    }))

  const [sortingParameters, setSortingParameters] = useState({
    columnKey: 'tranche.rank',
    sortDescending: false,
  })

  const sortingDefinitions = () => {
    const sortableColumns = [
      { title: t('tranche-id-label'), columnKey: 'tranche.displayId' },
      { title: t('tranche-name-label'), columnKey: 'tranche.name' },
      { title: t('product-type-label'), columnKey: 'productTypeCode' },
      { title: t('loan-type-label'), columnKey: 'loanTypeCode' },
      {
        title: t('commitment-total-label'),
        columnKey: 'totalCommitment',
      },
      {
        title: `${t('commitment-label')} (${t('own-share-label')})`,
        columnKey: 'commitment',
      },
      {
        title: t('syndicated-amount-total-label'),
        columnKey: 'syndicatedAmountTotal',
      },
    ]
    return {
      columnKey: sortingParameters.columnKey,
      // as the summary row needs to be the last element in the data array, the native functionality is not used
      isSortingAscending: !sortingParameters.sortDescending,
      sortableColumns: sortableColumns.map(({ title, columnKey }) => ({ title, columnKey })),
      onUpdateSorting: (event) => {
        const { sortBy, sortDescending } = event
        // strangely event does not contain the columnKey of the selected element
        const { columnKey } = find(sortableColumns, ['title', sortBy])
        setSortingParameters({ columnKey, sortDescending })
      },
    }
  }

  const sortMoney = (tranches, columnKey) => {
    const getEuroValue = (element) => {
      const obj = get(element, columnKey)
      return obj.converted?.value ? obj.converted.value : obj.original.value
    }
    tranches.sort((a, b) => (getEuroValue(a) < getEuroValue(b) ? 1 : -1))
  }

  const tableData = useMemo(() => {
    const sortData = (tranches, { columnKey, sortDescending }) => {
      const copy = [...tranches]
      if (['totalCommitment', 'commitment', 'available', 'outstanding'].includes(columnKey))
        sortMoney(copy, columnKey)
      else copy.sort((a, b) => (get(a, columnKey) < get(b, columnKey) ? 1 : -1))
      return sortDescending ? copy : copy.reverse()
    }

    if (!isEmpty(data)) {
      let mappedTranchesData = mapTranchesData(tNoPrefix, data)
      if (mappedTranchesData.length > 1) {
        mappedTranchesData = [
          ...sortData(mappedTranchesData.slice(0, -1), sortingParameters),
          mappedTranchesData.at(-1),
        ]
      }

      return mappedTranchesData
    } else return []
  }, [data, sortingParameters])

  return (
    <AnalyticalTableScalingWrapper isPdfView={isPdfView} tableWidth={tableWidth}>
      <div className={styles.tableWrapper}>
        <AnalyticalTableWithToolbar
          nrOfEntries={data?.tranches?.length ?? 0}
          title={t('header')}
          customSorting={sortingDefinitions()}
          id="tranches-table"
          className={styles['tranches-table']}
          sortable={false}
          minRows={0}
          columns={columnDefinitions()}
          overscanCountHorizontal={99}
          rowHeight={70}
          headerRowHeight={30}
          data={tableData}
          isTreeTable={false}
          isPdfView={isPdfView}
          scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
          NoDataComponent={() => (
            <IllustratedMessage
              name="NoData"
              // Unfortunately, the library's default title contains two apostrophes:
              // "There''s no data yet
              // Therefore a custom title is used
              titleText={t('no-data-title')}
              subtitleText={t('no-data-text')}
            />
          )}
          onRowClick={(e) => {
            const trancheDisplayId = e.detail.row.original.tranche?.displayId
            if (trancheDisplayId) {
              navigate(`/${paths.deals}/${dealDisplayId}/financing/tranches/${trancheDisplayId}`)
            }
          }}
          ref={tableRef}
        />
      </div>
    </AnalyticalTableScalingWrapper>
  )
}

OpsMemoTranchesTable.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default OpsMemoTranchesTable
