import isNil from 'lodash.isnil'
import omit from 'lodash.omit'
import { calculateSyndicationRowHeight } from 'routes/deals/syndication/syndicationStructure/utils/backendToTableStructure/calculateSyndicationRowHeight'
import { ROW_TYPE_TRANCHE } from 'routes/deals/syndication/syndicationStructure/utils/constants'
import { convertDecimalToBps } from 'routes/deals/syndication/syndicationStructure/utils/convertBps'

const mapTotalTranches = (tranches, rootRowId) =>
  tranches.map((tranche) => {
    const hasCustomerMargin = !Object.values(
      omit(tranche.customerMargin, ['feeTypeShortText', 'skimTypeShortText']),
    ).every(isNil)
    const feesWithCustomerMargin = (hasCustomerMargin ? [tranche.customerMargin] : []).concat(
      tranche.fees ?? [],
    )

    const isSpacingForHQCurrencyNeeded =
      tranche.totalCommitment?.currency !== tranche.totalCommitmentHeadquarter?.currency

    const rowHeight = calculateSyndicationRowHeight(feesWithCustomerMargin.length, tranche)

    return {
      rootRowId,
      partyRoleTranche: {
        value: tranche.name,
        trancheDisplayId: tranche.trancheDisplayId,
      },
      commitment: {
        value: tranche.totalCommitment,
        valueHeadquarter: tranche.totalCommitmentHeadquarter,
      },
      amountPA: feesWithCustomerMargin.map((fee) => ({
        value: fee.amountPerAnnum,
        valueHeadquarter: fee.amountPerAnnumHeadquarter,
      })),
      amountTerm: feesWithCustomerMargin.map((fee) => ({
        value: fee.amountTerm,
        valueHeadquarter: fee.amountTermHeadquarter,
      })),
      type: feesWithCustomerMargin.map((fee) => ({
        value: fee.feeTypeShortText,
      })),
      rate: feesWithCustomerMargin.map((fee) => ({
        value: convertDecimalToBps(fee.rate),
      })),
      rowHeight,
      isSpacingForHQCurrencyNeeded,
      rowType: ROW_TYPE_TRANCHE,
    }
  })

export default mapTotalTranches
