import { CardHeader } from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/arrears/current-arrears/CurrentArrearsCard.module.css'
import CurrentArrearsTable from 'components/domains/business-partners/tile/arrears/current-arrears/CurrentArrearsTable'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { BusinessPartnerArrearsContext } from 'routes/business-partners/arrears/BusinessPartnerArrearsContext'

const CurrentArrearsCard = () => {
  const { t: tBusinessPartnerArrearsTable } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.current-arrears.table',
  })

  const businessPartnerArrearsContext = useContext(BusinessPartnerArrearsContext)

  const arrearsCountWithoutOverpayments = useMemo(
    () => businessPartnerArrearsContext.arrearsWithoutOverpayments?.length,
    [businessPartnerArrearsContext.arrearsWithoutOverpayments?.length],
  )

  const renderTable = () => <CurrentArrearsTable arrearsCount={arrearsCountWithoutOverpayments} />

  return (
    <Card
      header={
        (businessPartnerArrearsContext.isLoading || businessPartnerArrearsContext.isError) && (
          <CardHeader titleText={tBusinessPartnerArrearsTable('title')} />
        )
      }
    >
      <div className={styles.tableWrapper}>
        <RequestStateResolver
          isLoading={businessPartnerArrearsContext.isLoading}
          isError={businessPartnerArrearsContext.isError}
          renderContent={renderTable}
          errorToDisplay={<ErrorDataUnavailableInContent />}
          center
        />
      </div>
    </Card>
  )
}

export default CurrentArrearsCard
