import {
  Dialog,
  Bar,
  Button,
  FlexBox,
  FlexBoxDirection,
  RadioButton,
  Label,
  Icon,
  Title,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ExcelUploadContext } from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollExcelUploadContext'

const APPLY = 'apply'
const IGNORE = 'ignore'

const PropertyRentRollApplyLocalValueMappingDialog = ({
  handleOptionalApplyLocalValueMappingAndContinue,
}) => {
  const { t: tExcelUpload } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.excel-upload',
  })

  const excelUploadContext = useContext(ExcelUploadContext)
  const { isApplyLocalValueMappingDialogOpen, closeApplyLocalValueMappingDialog } =
    excelUploadContext
  const [localMappingChoice, setLocalMappingChoice] = useState(APPLY)

  const handleAcceptLocalMappingChoice = () => {
    if (localMappingChoice === APPLY) {
      handleOptionalApplyLocalValueMappingAndContinue({ applyLocalValueMapping: true })
    }
    if (localMappingChoice === IGNORE) {
      handleOptionalApplyLocalValueMappingAndContinue({ applyLocalValueMapping: false })
    }
  }

  return (
    <Dialog
      open={isApplyLocalValueMappingDialogOpen}
      header={
        <Bar
          startContent={
            <>
              <Icon style={{ color: '#0F5DE9' }} name="sap-icon://sys-help-2" />
              <Title>{tExcelUpload('apply-local-mapping-dialog.title')}</Title>
            </>
          }
        />
      }
      footer={
        <Bar
          endContent={
            <>
              <Button
                id={'acceptApplyLocalMappingButton'}
                design="Emphasized"
                onClick={handleAcceptLocalMappingChoice}
              >
                {tExcelUpload('accept-button')}
              </Button>
              <Button
                id={'declineApplyLocalMappingButton'}
                onClick={closeApplyLocalValueMappingDialog}
              >
                {tExcelUpload('abort-button')}
              </Button>
            </>
          }
        />
      }
    >
      <FlexBox>{tExcelUpload('apply-local-mapping-dialog.text') + '\n'}</FlexBox>
      <FlexBox direction={FlexBoxDirection.Column}>
        <FlexBox style={{ marginTop: '5px' }} direction={FlexBoxDirection.Row}>
          <RadioButton
            name="chooseLocalMappingOperation"
            id="radio-button-apply-local-mapping"
            text=""
            value={APPLY}
            checked={localMappingChoice === APPLY}
            onChange={(event) => setLocalMappingChoice(event.target.value)}
          />
          <Label
            id="radio-button-apply-local-mapping-label"
            for="radio-button-apply-local-mapping"
            onClick={() => setLocalMappingChoice(APPLY)}
            style={{ cursor: 'pointer' }}
          >
            <b>{tExcelUpload('apply-local-mapping-dialog.apply-title')}</b>
            <br />
            {tExcelUpload('apply-local-mapping-dialog.apply')}
          </Label>
        </FlexBox>
        <FlexBox style={{ marginTop: '5px' }} direction={FlexBoxDirection.Row}>
          <RadioButton
            name="chooseLocalMappingOperation"
            id="radio-button-ignore-local-mapping"
            text=""
            value={IGNORE}
            checked={localMappingChoice === IGNORE}
            onChange={(event) => setLocalMappingChoice(event.target.value)}
          />
          <Label
            id="radio-button-ignore-local-mapping-label"
            for="radio-button-ignore-local-mapping"
            onClick={() => setLocalMappingChoice(IGNORE)}
            style={{ cursor: 'pointer' }}
          >
            <b>{tExcelUpload('apply-local-mapping-dialog.ignore-title')}</b>
            <br />
            {tExcelUpload('apply-local-mapping-dialog.ignore')}
          </Label>
        </FlexBox>
      </FlexBox>
    </Dialog>
  )
}

PropertyRentRollApplyLocalValueMappingDialog.propTypes = {
  handleOptionalApplyLocalValueMappingAndContinue: PropTypes.func.isRequired,
}

export default PropertyRentRollApplyLocalValueMappingDialog
