import { useMemo } from 'react'
import useMultipleDealProperties from 'hooks/services/deals/properties/useMultipleDealProperties'
import useMultiplePropertyRentRollOverviewExpiringLeasesChartData from 'hooks/services/properties/kpis/charts/useMultiplePropertyRentRollOverviewExpiringLeasesChartData'
import useCurrentMultiPropertyKpisList from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpisList'
import useMultipleAnnualizedCurrentRentPrimaryMetric from 'hooks/services/properties/kpis/useMultipleAnnualizedCurrentRentPrimaryMetric'
import { useMultiPropertyViewByPropertyUuidsArrays } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuidsArrays'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const useMultipleTenancyOverviewExpiringLeases = ({ dealUuids }) => {
  const {
    data: dealPropertiesDealUuidPairs,
    isLoading: isLoadingPropertiesDealUuidPairs,
    isError: isErrorPropertiesDealUuidPairs,
  } = useCombinedQueryResults(useMultipleDealProperties({ dealUuids }))

  const dealUuidPropertyUuidsPairs =
    dealPropertiesDealUuidPairs?.map((pair) => ({
      dealUuid: pair.dealUuid,
      propertyUuids: pair?.dealProperties.flatMap(({ propertyUuid }) => propertyUuid),
    })) ?? []

  const allPropertyUuids = dealUuidPropertyUuidsPairs.flatMap((pair) => pair.propertyUuids)

  const isMultiPropertyPerDealUuid = dealUuidPropertyUuidsPairs
    .map(({ dealUuid, propertyUuids }) => ({
      [dealUuid]: propertyUuids.length > 1,
    }))
    .reduce((prev, curr) => Object.assign(prev, curr), {})

  const {
    data: multiPropertyKpisData,
    isLoading: isLoadingMultiPropertyKpis,
    isError: isErrorMultiPropertyKpis,
  } = useCurrentMultiPropertyKpisList(
    dealUuidPropertyUuidsPairs.map((pair) => ({
      propertyUuids: pair.propertyUuids,
      withTenantGranularity: true,
    })),
    { enabled: allPropertyUuids.length > 0 },
  )

  const multiPropertyKpisPropertyUuidsDealUuidTriplets =
    multiPropertyKpisData?.map((kpi, index) => ({
      propertyUuids: kpi?.data?.propertyUuids,
      kpis: {
        ...kpi?.data?.keyDateToMultiPropertyRentRollKpis?.[0]?.kpis,
        keyDate: kpi?.data?.keyDateToMultiPropertyRentRollKpis?.[0]?.keyDate,
      },
      dealUuid: dealUuidPropertyUuidsPairs[index]?.dealUuid,
    })) ?? []

  const primaryMetricPerDealUuid = useMultipleAnnualizedCurrentRentPrimaryMetric(
    multiPropertyKpisPropertyUuidsDealUuidTriplets,
  )
  const keyDatePerDealUuid = multiPropertyKpisPropertyUuidsDealUuidTriplets
    .map(({ dealUuid, kpis }) => ({ [dealUuid]: kpis?.keyDate }))
    .reduce((prev, curr) => Object.assign(prev, curr), {})

  const propertyRentRollOverviewExpiringLeasesChartDataPerDealUuid =
    useMultiplePropertyRentRollOverviewExpiringLeasesChartData(
      multiPropertyKpisPropertyUuidsDealUuidTriplets,
    )

  const {
    data: multiPropertyView,
    isLoading: isLoadingMultiPropertyView,
    isError: isErrorMultiPropertyView,
  } = useMultiPropertyViewByPropertyUuidsArrays({
    propertyUuidsArrays: dealUuidPropertyUuidsPairs.map((pair) => pair.propertyUuids),
    options: { enabled: allPropertyUuids.length > 0 },
  })

  const multiPropertyViewPerDealUuid = dealUuidPropertyUuidsPairs
    .map(({ dealUuid }, index) => ({
      [dealUuid]: multiPropertyView?.[index],
    }))
    .reduce((prev, curr) => Object.assign(prev, curr), {})

  const sourcePathPerDealUuid = useMemo(
    () =>
      multiPropertyKpisPropertyUuidsDealUuidTriplets
        .map(({ dealUuid }) => ({
          [dealUuid]: isMultiPropertyPerDealUuid
            ? `/properties/portfolio/rent-roll?portfolio-view-id=${multiPropertyViewPerDealUuid[dealUuid]?.data?.uuid}`
            : `/properties/${
                dealUuidPropertyUuidsPairs.find((e) => e.dealUuid === dealUuid).propertyUuids?.[0]
              }/rent-roll`,
        }))
        .reduce((prev, curr) => Object.assign(prev, curr), {}),
    [
      dealUuidPropertyUuidsPairs,
      isMultiPropertyPerDealUuid,
      multiPropertyKpisPropertyUuidsDealUuidTriplets,
      multiPropertyViewPerDealUuid,
    ],
  )

  return useMemo(() => {
    const isLoading =
      isLoadingPropertiesDealUuidPairs || isLoadingMultiPropertyKpis || isLoadingMultiPropertyView
    const isError =
      isErrorPropertiesDealUuidPairs || isErrorMultiPropertyKpis || isErrorMultiPropertyView

    const multipleTenancyOverviewExpiringLeasesPerDealUuid =
      multiPropertyKpisPropertyUuidsDealUuidTriplets
        .map(({ dealUuid }) => ({
          [dealUuid]: {
            dealUuid,
            sourceRender: { path: sourcePathPerDealUuid[dealUuid] },
            noProperties: false,
            expiringLeasesCardHeaderInfo: {
              primaryMetric: primaryMetricPerDealUuid[dealUuid],
              keyDate: keyDatePerDealUuid[dealUuid],
            },
            expiringLeasesChartInfo: {
              currentRent:
                propertyRentRollOverviewExpiringLeasesChartDataPerDealUuid[dealUuid]?.currentRent,
              expiringLeases:
                propertyRentRollOverviewExpiringLeasesChartDataPerDealUuid[dealUuid]
                  ?.expiringLeases,
              currency:
                propertyRentRollOverviewExpiringLeasesChartDataPerDealUuid[dealUuid]?.currency,
              isEmpty:
                propertyRentRollOverviewExpiringLeasesChartDataPerDealUuid[dealUuid]?.isEmpty,
              todayXAxisPosition:
                propertyRentRollOverviewExpiringLeasesChartDataPerDealUuid[dealUuid]
                  ?.todayXAxisPosition,
            },
          },
        }))
        .reduce((prev, curr) => Object.assign(prev, curr), {})

    return {
      isLoading,
      isError,
      data: !(isError || isLoading) ? multipleTenancyOverviewExpiringLeasesPerDealUuid : undefined,
    }
  }, [
    isLoadingPropertiesDealUuidPairs,
    isLoadingMultiPropertyKpis,
    isLoadingMultiPropertyView,
    isErrorPropertiesDealUuidPairs,
    isErrorMultiPropertyKpis,
    isErrorMultiPropertyView,
    propertyRentRollOverviewExpiringLeasesChartDataPerDealUuid,
    multiPropertyKpisPropertyUuidsDealUuidTriplets,
    sourcePathPerDealUuid,
    primaryMetricPerDealUuid,
    keyDatePerDealUuid,
  ])
}

export default useMultipleTenancyOverviewExpiringLeases
