import { useMutation } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

export const useUpdateArrearApprovalsByBusinessPartnerId = ({
  onSuccess = () => {},
  onError = () => {},
}) => {
  const { put } = useAccessTokenRequest()
  const { parse, localePattern } = useShortDateFormatter()

  const dateToIsoFormat = (dateString) =>
    DateTime.fromISO(dateString).isValid ? dateString : parse(dateString, localePattern)

  return useMutation(
    async ({ businessPartnerId, approvalItems }) => {
      const { data } = await put({
        path: `/arrears/approvals/${businessPartnerId}`,
        body: approvalItems.map((approvalItem) =>
          snakecaseKeys({
            arrearExternalId: approvalItem.arrearExternalId,
            approvalAmount: approvalItem.approvalAmount,
            approvedUntil: dateToIsoFormat(approvalItem.approvedUntil),
            classificationCode: approvalItem.classificationCode,
            comment: approvalItem.comment,
          }),
        ),
      })
      return data
    },
    { onSuccess, onError },
  )
}
