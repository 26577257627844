import { useMemo } from 'react'
import { DefaultTooltipContent } from 'recharts/es6/component/DefaultTooltipContent'

export const END_PADDING_IN_PERCENT = 0.05

/**
 * for our "over time" charts, we are using recharts Area charts with
 * the "stepAfter" type, which have the downside that the value of the
 * last data point is not visible, as it's rendered at the rightmost end of the area
 *
 * hence, we use this hook to add a "fake" data point with identical values a bit
 * right to the last data point, so one can see a line with the last actual data
 * point's value in the chart
 *
 * @typedef {object} HistoricalChartDataWithPadding
 * @property {object[]} dataWithEndPadding
 * @property {import('react').FunctionComponent} TooltipForNonPaddingData
 *
 * @param {object[]} data the chart data
 * @param {string[]} [fields=[]] names of the fields to copy over from the last data point
 * @returns {HistoricalChartDataWithPadding}
 */
const useHistoricalChartDataWithPadding = (data, fields = []) =>
  useMemo(() => {
    const firstRentRollKpis = data.at(0)
    const lastRentRollKpis = data.at(-1)

    const dataWithEndPadding =
      data.length < 2
        ? data
        : [
            ...data,
            {
              ...fields.reduce((endPaddingDataPoint, fieldName) => {
                if (fieldName in lastRentRollKpis) {
                  endPaddingDataPoint[fieldName] = lastRentRollKpis[fieldName]
                }
                return endPaddingDataPoint
              }, {}),
              date:
                lastRentRollKpis.date +
                (lastRentRollKpis.date - firstRentRollKpis.date) * END_PADDING_IN_PERCENT,
            },
          ]

    const TooltipForNonPaddingData = (tooltipProps) => {
      const isOriginalDataPoint =
        tooltipProps.label && data.find(({ date }) => date === tooltipProps.label) !== undefined
      return isOriginalDataPoint ? <DefaultTooltipContent {...tooltipProps} /> : null
    }

    return {
      dataWithEndPadding,
      TooltipForNonPaddingData,
    }
  }, [data, fields])

export default useHistoricalChartDataWithPadding
