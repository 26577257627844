import {
  ENTITY_TYPES,
  NO_DEAL,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/businesspartner/shared/constants'
import set from 'utils/set'

// Hint: Here is no check with "secondaryXId" necessary, because in this case we have on both side the primary id.
// Only if we check the product id with an arrear or arrear approval item we have also to check for the secondaryProductId
const findProductByIdAndType = (productsFromContext, productId, productType) =>
  productsFromContext.find(({ id, type }) => id === productId && type === productType)

export const isFinancialProduct = (entityType) =>
  entityType === ENTITY_TYPES.TRANCHE ||
  entityType === ENTITY_TYPES.DRAWING ||
  entityType === ENTITY_TYPES.NON_LOAN ||
  entityType === ENTITY_TYPES.BCA

export const isSecondLevelHierarchy = (row) => row.hierarchyLevel === 1

const extendRowDataWithProduct = (productsFromContext, row) => {
  const { entityId, entityType } = row
  if (isFinancialProduct(entityType)) {
    set(row, 'rowData.product', findProductByIdAndType(productsFromContext, entityId, entityType))
  }
}

export const unshiftNoDealRowOnFirstHierarchyLevel = (productHierarchy, hierarchyLevel) => {
  let productHierarchyResult = [...productHierarchy]
  if (hierarchyLevel === 0) {
    const noDealRow = productHierarchyResult.find(
      (hierarchyItem) => hierarchyItem.productId === NO_DEAL,
    )
    if (noDealRow) {
      productHierarchyResult = productHierarchyResult.filter(
        (hierarchyItem) => hierarchyItem.productId !== NO_DEAL,
      )
      productHierarchyResult.unshift(noDealRow)
    }
  }
  return productHierarchyResult
}

export const buildRowsDataRecursive = ({
  businessPartnerId,
  products,
  productHierarchy,
  extendRowsWithAdditionalData = () => {},
  hierarchyLevel = 0,
  isFirstLevel = true,
  dealId = null,
}) =>
  unshiftNoDealRowOnFirstHierarchyLevel(productHierarchy, hierarchyLevel).map(
    ({ productId, secondaryProductId, productTypeCode, childProducts: childHierarchy }) => {
      const row = {
        businessPartnerId,
        dealId: isFirstLevel && productTypeCode === ENTITY_TYPES.DEAL ? productId : dealId,
        isSectionHeader: isFirstLevel,
        hierarchyLevel,
        entityId: productId,
        secondaryEntityId: secondaryProductId,
        entityType: productTypeCode,
        rowData: {},
        sumData: {},
        subRows: [],
      }
      extendRowDataWithProduct(products, row)
      extendRowsWithAdditionalData(row)
      return {
        ...row,
        subRows: [
          ...row.subRows,
          ...buildRowsDataRecursive({
            businessPartnerId,
            products,
            productHierarchy: childHierarchy,
            extendRowsWithAdditionalData,
            hierarchyLevel: hierarchyLevel + 1,
            isFirstLevel: false,
            dealId: row.dealId,
          }),
        ],
      }
    },
  )

const wasSubRowsTrimmed = (row, shouldShowRow) => {
  const filteredSubRows = row.subRows.filter((subRow) => shouldShowRow(subRow))
  const wasTrimmed = filteredSubRows.length < row.subRows.length
  row.subRows = [...filteredSubRows]
  return wasTrimmed
}

const trimRowsRecursive = (row, shouldShowRow = () => true) => {
  let wasTrimmed = false
  do {
    wasTrimmed = wasSubRowsTrimmed(row, shouldShowRow)
  } while (row.subRows.some((subRow) => trimRowsRecursive(subRow, shouldShowRow)))
  return wasTrimmed
}

export const trimRowsDeep = (rows, shouldShowRow = () => true) => {
  rows.forEach((row) => trimRowsRecursive(row, shouldShowRow))
  return rows.filter((row) => shouldShowRow(row))
}

export const extractApprovalStatusList = (approvalStatuses) =>
  approvalStatuses?.map((approvalStatusObj) => approvalStatusObj.approvalStatus) ?? []
