import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  errorRows: {},
  rowsHaveAnError: false,
  highlightRows: {},
}

export const valuationRequestsTableSlice = createSlice({
  name: 'valuation-requests-table',
  initialState,
  reducers: {
    valuationRowHasError: (state, { payload: { id: valuationId, error } }) => {
      state.errorRows = {
        ...state.errorRows,
        [valuationId]: { error },
      }
      state.rowsHaveAnError = true
    },
    resetValuationRow: (state, { payload: valuationId }) => {
      delete state.errorRows[valuationId]
      if (Object.keys(state.errorRows).length === 0) {
        state.rowsHaveAnError = false
      }
    },
    highlightRequestRows: (state, { payload: { valuationRequestIds } }) => {
      state.highlightRows = {
        ...state.highlightRows,
        ...valuationRequestIds.reduce((rows, id) => ({ ...rows, [id]: true }), {}),
      }
    },
    reset: () => initialState,
  },
})

export const { valuationRowHasError, resetValuationRow, highlightRequestRows, reset } =
  valuationRequestsTableSlice.actions

export default valuationRequestsTableSlice.reducer
