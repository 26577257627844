import isNil from 'lodash.isnil'

const useSelectStaffMembersHelper = ({
  dealTeamStaffMembers,
  accountManagerSapRelationId,
  loanAccountManagerSapRelationId,
  underwriterSapRelationId,
}) => {
  if (isNil(dealTeamStaffMembers)) {
    return {
      accountManagerUser: undefined,
      loanAccountManagerUserId: undefined,
      underwriterUserId: undefined,
    }
  }

  const underwriterUser = dealTeamStaffMembers.find(
    ({ leader, relation }) => leader && relation?.sapRelationId === underwriterSapRelationId,
  )

  const accountManagerUser = dealTeamStaffMembers.find(
    ({ leader, relation }) => leader && relation?.sapRelationId === accountManagerSapRelationId,
  )

  const loanAccountManagerUser = dealTeamStaffMembers.find(
    ({ leader, relation }) => leader && relation?.sapRelationId === loanAccountManagerSapRelationId,
  )

  return {
    accountManagerUserId: accountManagerUser?.userId,
    loanAccountManagerUserId: loanAccountManagerUser?.userId,
    underwriterUserId: underwriterUser?.userId,
  }
}

export default useSelectStaffMembersHelper
