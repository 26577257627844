import {
  ROW_TYPE_PERCENTAGE,
  DEFAULT_DISPLAY_ROW_HEIGHT,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/constants'

const rowKeys = ['customerMarginAverage']

const mapRowData = ({ rowKey, responses }) => {
  const createRow = (createObjectFunction) =>
    responses
      ?.map(({ data, isLoading, isFetching, isError }) =>
        createObjectFunction(data, { isLoading, isFetching, isError }),
      )
      .reduce((prev, curr) => Object.assign(prev, curr), {})

  switch (rowKey) {
    case 'customerMarginAverage':
      return createRow(({ dealUuid, values: { customerMarginAverage } = {} }, requestStates) => ({
        [dealUuid]: {
          value: customerMarginAverage,
          ...requestStates,
        },
      }))
  }
}

const mapRowType = (rowKey) => {
  switch (rowKey) {
    case 'customerMarginAverage':
      return ROW_TYPE_PERCENTAGE
  }
}

const mapMultipleDealPricingResponses = ({ multipleDealsPricingResponses, t }) =>
  rowKeys.map((rowKey) => {
    const rowData = mapRowData({
      rowKey,
      responses: multipleDealsPricingResponses,
    })

    return {
      kpi: t(`rows.section.loan.${rowKey}`),
      rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT,
      ...rowData,
      rowType: mapRowType(rowKey),
      rowHasError: Object.values(rowData).some((column) => column.isError),
    }
  })

export default mapMultipleDealPricingResponses
