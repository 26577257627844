import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useTextualAssessments = ({ businessPartnerId, type }, options) =>
  useCamelizedResponse(
    useRequest({
      path: `/businesspartners/${businessPartnerId}/textual-assessments/${type}`,
      keys: ['businesspartners', businessPartnerId, 'textual-assessments', type],
      options,
    }),
  )
