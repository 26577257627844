import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/text/LastEditedText.module.css'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

/**
 * @param {*} children should provide the name of the last changes' author
 */

const LastEditedText = ({
  className,
  preText,
  lastUpdatedAt,
  postText,
  wrapping = false,
  children,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.ui.text.last-edited' })
  const { format: formatDateAndTime } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
  })

  return (
    <Text className={className ?? styles.sourceLabel} wrapping={wrapping}>
      {preText}
      {t('datetime-by', { parsedDate: formatDateAndTime(lastUpdatedAt) })}
      {children}
      {postText}
    </Text>
  )
}

LastEditedText.propTypes = {
  className: PropTypes.string,
  preText: PropTypes.string,
  lastUpdatedAt: PropTypes.string.isRequired,
  postText: PropTypes.string,
  wrapping: PropTypes.bool,
  children: PropTypes.node,
}

export default LastEditedText
