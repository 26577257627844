import { useMemo } from 'react'
import { multiPropertyValuations } from 'api/property/valuation/valuations'
import { MarkerColors } from 'components/ui/map/internal/markers/MarkerColors'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useDealProperties from 'hooks/services/deals/properties/useDealProperties'
import useMarketArea from 'hooks/services/markets/useMarketArea'
import usePropertyIdForAssignedMarket from 'hooks/services/markets/usePropertyIdForAssignedMarket'
import { useMultiPropertyViewByPropertyUuids } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuids'
import usePropertyUuids from 'hooks/services/properties/usePropertyUuids'
import useMultiPropertyValuations from 'hooks/services/properties/valuations/useMultiPropertyValuations'

const getPropertyValuation = (
  { isFetching, isError, data: valuationsData },
  propertyUuid,
  formatCurrency,
) => {
  if (isFetching || isError) {
    return { isLoading: isFetching, isError, data: undefined }
  }
  const marketValueData =
    valuationsData?.valuations?.[propertyUuid]?.[multiPropertyValuations.marketValue]?.value_amount

  if (!marketValueData) {
    return { isLoading: isFetching, isError, data: '-' }
  }
  return {
    isLoading: isFetching,
    isError,
    data: formatCurrency(marketValueData.number, marketValueData.currency),
  }
}

const mapMarketPropertyMarkersData = (
  propertyInformation,
  marketPropertiesValuationsResult,
  formatCurrency,
  singlePropertyUuid,
) => {
  const address = propertyInformation.address
    ? {
        country: propertyInformation.address?.country_name,
        street: propertyInformation.address?.street_name,
        houseNumber: propertyInformation.address?.house_id,
        zipCode: propertyInformation.address?.postal_code,
        city: propertyInformation.address?.city_name,
      }
    : undefined
  return {
    uuid: propertyInformation.uuid,
    id: propertyInformation.id,
    name: propertyInformation.description,
    address: address,
    location: {
      lat: Number(propertyInformation.geo_location?.latitude),
      lng: Number(propertyInformation.geo_location?.longitude),
    },
    propertyType: propertyInformation.type_name,
    creationDate: propertyInformation.system_administrative_data?.creation_date_time,
    color:
      !singlePropertyUuid || singlePropertyUuid === propertyInformation.uuid
        ? MarkerColors.Blue
        : MarkerColors.Grey,
    valuation: getPropertyValuation(
      marketPropertiesValuationsResult,
      propertyInformation.uuid,
      formatCurrency,
    ),
  }
}

const buildPropertyMarkers = (
  propertiesResult,
  valuationsResult,
  formatCurrency,
  singlePropertyUuid,
) => {
  let missingGeoLocations = 0
  if (propertiesResult?.data?.data) {
    const markers = propertiesResult.data.data.properties
      .filter((property) => {
        if (
          !property?.geo_location?.latitude ||
          !property?.geo_location?.longitude ||
          (property.geo_location?.latitude === '0' && property.geo_location?.longitude === '0')
        ) {
          missingGeoLocations++
          return false
        }
        return true
      })
      .map((property) =>
        mapMarketPropertyMarkersData(
          property,
          valuationsResult,
          formatCurrency,
          singlePropertyUuid,
        ),
      )
    return [markers, missingGeoLocations]
  }
  return []
}

const buildResponse = (
  dealUuidByTileCodeResult,
  dealPropertiesResult,
  propertiesResult,
  valuationsResult,
  sourcePath,
  markers,
  missingGeoLocations,
  assignedMarketResult,
  marketAreaResult,
  marketPropertiesResult,
) => {
  const isLoading =
    dealUuidByTileCodeResult.isFetching ||
    dealPropertiesResult.isFetching ||
    propertiesResult.isFetching ||
    valuationsResult.isFetching ||
    assignedMarketResult.isFetching ||
    marketAreaResult.isFetching ||
    marketPropertiesResult.isFetching
  const isError =
    dealUuidByTileCodeResult.isError ||
    dealPropertiesResult.isError ||
    propertiesResult.isError ||
    valuationsResult.isError ||
    assignedMarketResult.isError ||
    marketAreaResult.isError ||
    marketPropertiesResult.isError

  if (
    !isLoading &&
    !isError &&
    (!dealPropertiesResult?.data?.dealProperties ||
      dealPropertiesResult?.data?.dealProperties?.length === 0)
  ) {
    return {
      isLoading: false,
      isError: false,
      data: {
        noProperties: true,
      },
    }
  }

  const data = !isLoading
    ? {
        propertyMarkers: markers,
        totalAmountOfProperties: propertiesResult?.data?.data?.properties.length,
        missingGeoLocations: missingGeoLocations,
        marketAreaData: marketAreaResult?.data,
        sourceRender: { path: sourcePath },
        noProperties: false,
      }
    : undefined
  return {
    isLoading: isLoading,
    isError: isError,
    data: data,
  }
}

const usePortfolioFinancedAssetsArea = ({ entityRef: { entityId: dealUuid } }, _, tileCode) => {
  const formatCurrency = useCustomizableCurrencyFormatter()
  const dealUuidByTileCodeResult = useDealUuidByTileCode({
    dealUuid,
    tileCode,
  })
  const { data: { dealUuid: dealUuidByTileCode } = {} } = dealUuidByTileCodeResult
  const dealPropertiesResult = useDealProperties({
    dealUuid: dealUuidByTileCode,
  })
  const isFollowUpRequestsEnabled = useMemo(
    () => !!dealPropertiesResult.data && !!dealPropertiesResult?.data?.dealProperties?.length > 0,
    [dealPropertiesResult],
  )
  const propertyUuids = useMemo(
    () =>
      isFollowUpRequestsEnabled
        ? dealPropertiesResult?.data?.dealProperties.map((property) => property.propertyUuid)
        : [],
    [dealPropertiesResult, isFollowUpRequestsEnabled],
  )

  const propertiesResult = usePropertyUuids(propertyUuids, {
    enabled: isFollowUpRequestsEnabled,
  })

  const valuationsResult = useMultiPropertyValuations(propertyUuids, undefined, undefined, {
    enabled: isFollowUpRequestsEnabled,
  })

  const isMultiProperty = useMemo(
    () => propertiesResult?.data?.data?.properties?.length > 1,
    [propertiesResult],
  )
  const multiPropertyViewUuidResponse = useMultiPropertyViewByPropertyUuids(propertyUuids, {
    enabled: isFollowUpRequestsEnabled && isMultiProperty,
  })
  const multiPropertyViewUuid = useMemo(
    () => multiPropertyViewUuidResponse?.data?.data?.uuid || '',
    [multiPropertyViewUuidResponse],
  )

  // get market results, markers and area used in case of a single property
  const singlePropertyUuid = propertiesResult?.data?.data?.properties?.[0]?.uuid

  const assignedMarketResult = usePropertyIdForAssignedMarket([singlePropertyUuid], {
    enabled: !!singlePropertyUuid,
  })
  const market = assignedMarketResult?.data?.markets?.[0] // hook either returns one market at position [0] or no market

  const assignedMarketId = market?.id
  const assignedMarketPropertieUuids = market?.properties?.map(({ id } = {}) => id) ?? []
  const assignedMarketPropertiesResult = usePropertyUuids(assignedMarketPropertieUuids, {
    enabled: assignedMarketPropertieUuids?.length > 0,
  })

  const marketAreaResult = useMarketArea(assignedMarketId, {
    enabled: !!assignedMarketId,
  })

  const [markers, missingGeoLocations] = buildPropertyMarkers(
    isMultiProperty ? propertiesResult : assignedMarketPropertiesResult,
    valuationsResult,
    formatCurrency,
    isMultiProperty ? null : singlePropertyUuid,
  )

  const sourcePath = useMemo(
    () =>
      isMultiProperty
        ? `/properties/portfolio/overview?portfolio-view-id=${multiPropertyViewUuid}`
        : `/properties/${propertiesResult?.data?.data?.properties?.[0]?.id}`,
    [isMultiProperty, multiPropertyViewUuid, propertiesResult?.data?.data?.properties],
  )
  return useMemo(
    () =>
      buildResponse(
        dealUuidByTileCodeResult,
        dealPropertiesResult,
        propertiesResult,
        valuationsResult,
        sourcePath,
        markers,
        missingGeoLocations,
        assignedMarketResult,
        marketAreaResult,
        assignedMarketPropertiesResult,
      ),
    [
      dealUuidByTileCodeResult,
      dealPropertiesResult,
      propertiesResult,
      valuationsResult,
      sourcePath,
      markers,
      missingGeoLocations,
      assignedMarketResult,
      marketAreaResult,
      assignedMarketPropertiesResult,
    ],
  )
}

export default usePortfolioFinancedAssetsArea
