import camelize from 'camelize'
import { useRequests } from 'hooks/services/baseService'

const useEventsByIdList = (eventIds = []) => {
  const results = useRequests({
    requests: eventIds.map((eventId) => ({
      path: `/events/${eventId}`,
      keys: ['events', eventId],
    })),
    useCache: true,
    options: { enabled: eventIds.length > 0, retry: 1 },
  })

  return camelize({
    isLoading: results?.some(({ isLoading }) => isLoading),
    isError: results?.some(({ isError }) => isError),
    isFetching: results?.some(({ isFetching }) => isFetching),
    data: results?.map((res) => res?.data),
  })
}

export default useEventsByIdList
