import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDecisionStageMinutesApprovalVoterVoteOptions = (
  { eventId, decisionStageId, decisionStageMinutesVoterId },
  options,
) =>
  useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/decision-stages/${decisionStageId}/minutes/voters/${decisionStageMinutesVoterId}/vote-options`,
      useCache: true,
      keys: [
        'events',
        eventId,
        'decision-stages',
        decisionStageId,
        'minutes',
        'voters',
        decisionStageMinutesVoterId,
        'vote-options',
      ],
      options,
    }),
  )

export default useDecisionStageMinutesApprovalVoterVoteOptions
