import {
  Button,
  ButtonDesign,
  MessageBoxActions,
  MessageBoxTypes,
} from '@fioneer/ui5-webcomponents-react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useShowMessageBox } from 'components/domains/business-events-and-tasks/decision-paper/links/shared/MessageBox'

const useDecisionPaperLinkConfirmationDialog = ({ onConfirm }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.approval-view',
  })

  const confirmMessageText = t('confirmation.text')
  const confirmMessageTitle = t('confirmation.title')
  const confirmButtonText = t('confirmation.button')

  const showMessageBox = useShowMessageBox()
  const showConfirmationDialog = useCallback(
    (event) => {
      showMessageBox(
        {
          type: MessageBoxTypes.Confirm,
          titleText: confirmMessageTitle,
          children: confirmMessageText,
          actions: [
            <Button
              key="button-confirm"
              design={ButtonDesign.Emphasized}
              onClick={() => onConfirm(event)}
            >
              {confirmButtonText}
            </Button>,
            MessageBoxActions.Cancel,
          ],
          draggable: true,
          resizable: true,
        },
        document.body,
      )
    },
    [confirmButtonText, confirmMessageText, confirmMessageTitle, onConfirm, showMessageBox],
  )

  return { showConfirmationDialog }
}

export default useDecisionPaperLinkConfirmationDialog
