import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useUpdatePropertyPartner = ({ onSuccess, onError }) => {
  const { delete: deletePropertyPartner, post } = useAccessTokenRequest()

  return useMutation(
    async ({ entityUuid, partnerToPost, partnerIdToDelete, functionCodeToDelete }) => {
      await deletePropertyPartner({
        path: `/properties/${entityUuid}/businesspartners?businesspartner_id=${partnerIdToDelete}&businesspartner_function_code=${functionCodeToDelete}`,
      })
      await post({
        path: `/properties/${entityUuid}/businesspartners`,
        body: {
          ...partnerToPost,
        },
      })
    },
    { onSuccess, onError },
  )
}
