import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'
import { valueHelpSchema } from 'hooks/services/valueHelpsSchema'

export const useCollateralAgreementCategories = () => {
  const response = useCamelizedResponse(
    useRequest({
      path: '/collateral-agreements/value-helps/cag-categories',
      translated: true,
      useCache: true,
      keys: ['collateral-agreements', 'cag-categories'],
    }),
  )
  const data = useMemo(
    () => (response.data ? valueHelpSchema.parse(response.data) : undefined),
    [response.data],
  )

  if (!data) return response

  return { ...response, data }
}
