import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

/** Backend handles 3 states:
 *   To update property information, an attribute can be
 *   - set with a value --> property attribute gets new value
 *   - set to undefined --> property attribute doesn't get changed
 *   - set to null      --> property attribute gets reset
 */

export const useUpdatePropertyDetails = ({ onSuccess, onError }) => {
  const { patch } = useAccessTokenRequest()

  const isInChangeSet = (attribute, changeSet) => attribute in changeSet

  const getStringOrNullIfNotSet = (attribute) => (attribute ? attribute : null)
  const getNumberOrNullIfNotSet = (attribute) =>
    (attribute || attribute === 0) && !isNaN(attribute) ? Number(attribute) : null
  const getMoneyOrNullIfNotSet = (value, currency) =>
    (!!value || value === 0) && currency && !isNaN(value)
      ? {
          number: Number(value),
          currency: currency,
        }
      : null

  const setPropertyChangeStringValue = (attribute, propertyChanges) => {
    if (isInChangeSet(attribute, propertyChanges)) {
      return getStringOrNullIfNotSet(propertyChanges[attribute])
    } else {
      return undefined
    }
  }

  const setPropertyChangeNumberValue = (attribute, propertyChanges) => {
    if (isInChangeSet(attribute, propertyChanges)) {
      return getNumberOrNullIfNotSet(propertyChanges[attribute])
    } else {
      return undefined
    }
  }

  const setPropertyChangeMoneyValue = (valueAttribute, currencyAttribute, propertyChanges) => {
    if (
      isInChangeSet(valueAttribute, propertyChanges) &&
      isInChangeSet(currencyAttribute, propertyChanges)
    ) {
      return getMoneyOrNullIfNotSet(
        propertyChanges[valueAttribute],
        propertyChanges[currencyAttribute],
      )
    } else {
      return undefined
    }
  }

  return useMutation(
    async ({ property_uuid, property }) => {
      const { data } = await patch({
        path: `/properties/${property_uuid}`,
        body: {
          deal_id: setPropertyChangeStringValue('dealId', property),
          type_code: setPropertyChangeStringValue('typeCode', property),
          type_name: setPropertyChangeStringValue('typeName', property),
          area_measure_unit_code: setPropertyChangeStringValue('areaMeasureUnitCode', property),
          description: setPropertyChangeStringValue('description', property),
          total_area_measure: setPropertyChangeNumberValue('totalArea', property),
          currency_code: setPropertyChangeStringValue('currencyCode', property),
          real_estate_usage_code: setPropertyChangeStringValue('realEstateUsageCode', property),
          market_value_amount: setPropertyChangeMoneyValue('marketValue', 'currencyCode', property),
          construction_completion_date: setPropertyChangeStringValue(
            'constructionCompletionDate',
            property,
          ),
          construction_completion_percent: setPropertyChangeNumberValue(
            'constructionCompletionPercent',
            property,
          ),
          address: {
            street_name: setPropertyChangeStringValue('streetName', property),
            house_id: setPropertyChangeStringValue('houseNumber', property),
            postal_code: setPropertyChangeStringValue('postalCode', property),
            city_name: setPropertyChangeStringValue('cityName', property),
            country_code: setPropertyChangeStringValue('countryCode', property),
          },
          geo_location: {
            latitude: setPropertyChangeStringValue('latitude', property),
            longitude: setPropertyChangeStringValue('longitude', property),
          },
          usage: {
            usage_code: setPropertyChangeStringValue('typeCodeMinor', property),
            utilization_code: setPropertyChangeStringValue('utilizationCode', property),
          },
          actual_rent_amount: setPropertyChangeMoneyValue(
            'actualRentAmount',
            'currencyCode',
            property,
          ),
          number_of_units: setPropertyChangeNumberValue('numberOfUnits', property),
        },
      })
      return data
    },
    { onSuccess, onError },
  )
}
