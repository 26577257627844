import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  ResponsiveGridLayout,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import BorrowerOverviewLabeledValue from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/borrower/BorrowerOverviewLabeledValue'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/borrower/BorrowerOverviewTile.module.css'
import {
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const BorrowerOverviewFinancialIndicators = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.bp-borrower-overview.financial-indicators',
  })
  const formatCurrency = useCustomizableCurrencyFormatter()
  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const { format: formatDate } = useShortDateFormatter()
  const { data: tileData } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const { financialIndicators } = tileData ?? {}

  const content = useMemo(() => {
    if (isNil(financialIndicators)) {
      return (
        <FlexBox
          className={styles.listCardEmptyContent}
          alignItems={FlexBoxAlignItems.Center}
          justifyContent={FlexBoxJustifyContent.Center}
        >
          {t('empty')}
        </FlexBox>
      )
    }
    return (
      <ResponsiveGridLayout columnsXL={2} columnsL={2} columnsM={2} columnsS={2}>
        <FlexBox direction={FlexBoxDirection.Column}>
          {!isNil(financialIndicators.keyDate) && (
            <BorrowerOverviewLabeledValue
              label={t('key-date')}
              value={formatDate(financialIndicators.keyDate)}
            />
          )}
          {!isNil(financialIndicators.turnover?.value) && (
            <BorrowerOverviewLabeledValue
              label={financialIndicators.turnover.label}
              value={formatCurrency(
                financialIndicators.turnover.value.value,
                financialIndicators.turnover.value.unit,
              )}
            />
          )}
          {!isNil(financialIndicators.ebitda?.value) && (
            <BorrowerOverviewLabeledValue
              label={financialIndicators.ebitda.label}
              value={formatCurrency(
                financialIndicators.ebitda.value.value,
                financialIndicators.ebitda.value.unit,
              )}
            />
          )}
          {!isNil(financialIndicators.netResult?.value) && (
            <BorrowerOverviewLabeledValue
              label={financialIndicators.netResult.label}
              value={formatCurrency(
                financialIndicators.netResult.value.value,
                financialIndicators.netResult.value.unit,
              )}
            />
          )}
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column}>
          {!isNil(financialIndicators.totalAssets?.value) && (
            <BorrowerOverviewLabeledValue
              label={financialIndicators.totalAssets.label}
              value={formatCurrency(
                financialIndicators.totalAssets.value.value,
                financialIndicators.totalAssets.value.unit,
              )}
            />
          )}
          {!isNil(financialIndicators.equityCapital?.value) && (
            <BorrowerOverviewLabeledValue
              label={financialIndicators.equityCapital.label}
              value={formatCurrency(
                financialIndicators.equityCapital.value.value,
                financialIndicators.equityCapital.value.unit,
              )}
            />
          )}
          {!isNil(financialIndicators.equityRatio?.value) && (
            <BorrowerOverviewLabeledValue
              label={financialIndicators.equityRatio.label}
              value={`${formatNumber(financialIndicators.equityRatio.value.value)} ${
                financialIndicators.equityRatio.value.unit
              }`}
            />
          )}
        </FlexBox>
      </ResponsiveGridLayout>
    )
  }, [financialIndicators, formatCurrency, formatDate, formatNumber, t])

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.listCard}>
      <Text className={styles.listCardTitle}>{t('title')}</Text>
      {content}
    </FlexBox>
  )
}

BorrowerOverviewFinancialIndicators.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default BorrowerOverviewFinancialIndicators
