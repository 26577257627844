import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDeal = (dealUuid, options = { enabled: !!dealUuid }) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}`,
      translated: true,
      useCache: true,
      keys: ['deals', dealUuid],
      options,
    }),
  )

export default useDeal
