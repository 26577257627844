import { ResponsiveGridLayout } from '@fioneer/ui5-webcomponents-react'
import styles from 'components/ui/configurable-components-page/core-components/layout/ui5GridAdapter.module.css'

const numberOfColumns = {
  BIG_SCREENS: 8,
  SMALL_SCREENS: 1,
}

/**
 * Responsive layout to be used in CWP pages. Uses eight column layout on big screens and one column layout on medium and small screens.
 *
 * Use the `fullWidthCols` className to have children taking the full width of the screen.
 *
 * Use the `quarterWidthCols` className to have children taking the quarter width of the screen.
 *
 * Use the `firstColWider` className to have two childs sharing screen in the 5:3 ratio.
 */
export const ui5GridAdapter = ({ staticData, children, key }) => (
  <ResponsiveGridLayout
    columnsXL={numberOfColumns.BIG_SCREENS}
    columnsL={numberOfColumns.BIG_SCREENS}
    columnsM={numberOfColumns.SMALL_SCREENS}
    columnsS={numberOfColumns.SMALL_SCREENS}
    className={styles[staticData.className]}
    key={key}
    rowGap={'1rem'}
    columnGap={'1rem'}
  >
    {children}
  </ResponsiveGridLayout>
)
