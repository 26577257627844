import { ValueState } from '@fioneer/ui5-webcomponents-react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import usePropertyDetailedUuids from 'hooks/services/properties/usePropertyDetailedUuids'
import determinePropertyValueStateBasedOnStatus from 'routes/properties/determinePropertyValueState'

// A helper hook to add a status to property object
// properties is a list that is retrieved from POST properties/uuids
// This hook calls POST /properties/detailed/uuids to retrieve system_status
// and add this information to each property object
export const useEnrichPropertyWithStatus = (properties = []) => {
  //the status in translation files are used to translate the system status that we received from backend to English.
  const { t: tHeaderStatus } = useTranslation('translation', {
    keyPrefix: 'pages.properties.header.status',
  })
  const { t: tHeaderStatusText } = useTranslation('translation', {
    keyPrefix: 'pages.properties.header.status.text',
  })

  const propertyUuids = properties.map(({ uuid }) => uuid)

  const { data, isFetching, isLoading, isError } = usePropertyDetailedUuids(propertyUuids, {
    enabled: !!propertyUuids && propertyUuids.length > 0,
  })

  const emptyPropertyStatus = {
    text: null,
    valueState: ValueState.None,
  }

  if (isFetching || isLoading || isError || data === undefined) {
    return properties.map((property) => ({
      ...property,
      propertyStatus: { ...emptyPropertyStatus, isLoading: isLoading && isFetching },
    }))
  }

  const detailedProperties = data.data.properties

  const checkPropertyStatus = (property) =>
    !_.isEmpty(property?.system_status) && Array.isArray(property?.system_status)

  const systemStatusInLocalTranslation = (property) =>
    checkPropertyStatus(property) ? property.system_status[0].toLowerCase().replace(' ', '-') : ''

  return properties.map((property) => {
    const detailedProperty = detailedProperties.find(({ uuid }) => uuid === property.uuid)

    const systemStatusInStandardTranslation = tHeaderStatus(
      systemStatusInLocalTranslation(detailedProperty),
    )
    const statusTranslationText = tHeaderStatusText(
      systemStatusInStandardTranslation?.toLowerCase(),
    )
    return {
      ...property,
      propertyStatus: {
        text: checkPropertyStatus ? statusTranslationText : '',
        valueState: checkPropertyStatus
          ? determinePropertyValueStateBasedOnStatus(systemStatusInStandardTranslation)
          : '',
        isLoading: false,
      },
    }
  })
}
