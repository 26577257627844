import { MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import { Answer } from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/questionnaireStructure'

const Q1_1_KEY_PATH = ['Q1', 'Q1-1']
const Q2_1_1_KEY_PATH = ['Q2', 'Q2-1', 'Q2-1-1']
const Q2_2_1_1_KEY_PATH = ['Q2', 'Q2-2', 'Q2-2-1', 'Q2-2-1-1']
const Q2_2_2_1_KEY_PATH = ['Q2', 'Q2-2', 'Q2-2-2', 'Q2-2-2-1']
const Q2_2_3_1_KEY_PATH = ['Q2', 'Q2-2', 'Q2-2-3', 'Q2-2-3-1']
const Q2_2_4_1_KEY_PATH = ['Q2', 'Q2-2', 'Q2-2-4', 'Q2-2-4-1']
const Q2_2_5_1_KEY_PATH = ['Q2', 'Q2-2', 'Q2-2-5', 'Q2-2-5-1']
const Q3_1_KEY_PATH = ['Q3', 'Q3-1']

const PASS_KEY = 'PASS'
const PASS_KEY_PATH = [PASS_KEY]
const FAIL_KEY = 'FAIL'
const FAIL_KEY_PATH = [FAIL_KEY]

const SPV_CRITERION_PASS = { keys: [Q1_1_KEY_PATH], result: Answer.YES }
const SPV_CRITERION_FAIL = { keys: [Q1_1_KEY_PATH], result: Answer.NO }
const CTL_CRITERION_PASS_BY_Q2_1 = { keys: [Q2_1_1_KEY_PATH], result: Answer.YES }
const CTL_CRITERION_PASS_BY_Q2_2 = {
  keys: [
    Q2_2_1_1_KEY_PATH,
    Q2_2_2_1_KEY_PATH,
    Q2_2_3_1_KEY_PATH,
    Q2_2_4_1_KEY_PATH,
    Q2_2_5_1_KEY_PATH,
  ],
  result: Answer.YES,
  min: 3,
}
const CTL_CRITERION_FAIL = {
  keys: [
    Q2_2_1_1_KEY_PATH,
    Q2_2_2_1_KEY_PATH,
    Q2_2_3_1_KEY_PATH,
    Q2_2_4_1_KEY_PATH,
    Q2_2_5_1_KEY_PATH,
  ],
  result: Answer.YES,
  max: 2,
}
const CF_CRITERION_PASS = { keys: [Q3_1_KEY_PATH], result: Answer.YES }
const CF_CRITERION_FAIL = { keys: [Q3_1_KEY_PATH], result: Answer.NO }

export const questionnaireStructure = [
  {
    key: 'Q1',
    hint: true,
    children: [
      {
        key: 'Q1-1',
        alwaysVisible: true,
        proceedWith: [{ key: Q2_1_1_KEY_PATH }],
        infos: [
          {
            type: 'INF-SPVCRIT-PASS',
            condition: [SPV_CRITERION_PASS],
          },
          {
            type: 'INF-SPVCRIT-FAIL',
            condition: [SPV_CRITERION_FAIL],
          },
        ],
      },
    ],
  },
  {
    key: 'Q2',
    hint: true,
    children: [
      {
        key: 'Q2-1',
        children: [
          {
            key: 'Q2-1-1',
            proceedWith: [
              { key: Q3_1_KEY_PATH, condition: [CTL_CRITERION_PASS_BY_Q2_1] },
              { key: Q2_2_1_1_KEY_PATH },
            ],
            infos: [{ type: 'INF-CTLCRIT-PASS', condition: [CTL_CRITERION_PASS_BY_Q2_1] }],
          },
        ],
      },
      {
        key: 'Q2-2',
        children: [
          {
            key: 'Q2-2-1',
            children: [
              {
                key: 'Q2-2-1-1',
                proceedWith: [{ key: Q2_2_2_1_KEY_PATH }],
              },
            ],
          },
          {
            key: 'Q2-2-2',
            children: [
              {
                key: 'Q2-2-2-1',
                proceedWith: [{ key: Q2_2_3_1_KEY_PATH }],
              },
            ],
          },
          {
            key: 'Q2-2-3',
            children: [
              {
                key: 'Q2-2-3-1',
                proceedWith: [{ key: Q2_2_4_1_KEY_PATH }],
              },
            ],
          },
          {
            key: 'Q2-2-4',
            children: [
              {
                key: 'Q2-2-4-1',
                proceedWith: [{ key: Q2_2_5_1_KEY_PATH }],
              },
            ],
          },
          {
            key: 'Q2-2-5',
            children: [
              {
                key: 'Q2-2-5-1',
                proceedWith: [{ key: Q3_1_KEY_PATH }],
                infos: [
                  {
                    type: 'INF-CTLCRIT-PASS',
                    condition: [CTL_CRITERION_PASS_BY_Q2_2],
                  },
                  {
                    type: 'INF-CTLCRIT-FAIL',
                    condition: [CTL_CRITERION_FAIL],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: 'Q3',
    hint: true,
    children: [
      {
        key: 'Q3-1',
        proceedWith: [
          {
            key: FAIL_KEY_PATH,
            condition: [SPV_CRITERION_PASS, CTL_CRITERION_PASS_BY_Q2_1, CF_CRITERION_PASS],
          },
          {
            key: FAIL_KEY_PATH,
            condition: [SPV_CRITERION_PASS, CTL_CRITERION_PASS_BY_Q2_2, CF_CRITERION_PASS],
          },
          { key: PASS_KEY_PATH },
        ],
        infos: [
          {
            type: 'INF-CFCRIT-PASS',
            condition: [CF_CRITERION_PASS],
          },
          {
            type: 'INF-CFCRIT-FAIL',
            condition: [CF_CRITERION_FAIL],
          },
        ],
      },
    ],
  },
]

export const availableEndings = [
  { key: PASS_KEY, messageStripDesign: MessageStripDesign.Positive },
  { key: FAIL_KEY, messageStripDesign: MessageStripDesign.Warning },
]
