import cloneDeep from 'lodash.clonedeep'
import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * Returns all deal statustype keys
 *
 * The structure (without details) is as follows:
 *   [
 *       {
 *           "code": "ndc_approved",
 *           "name": "NDC approved"
 *       },
 *       [...]
 *    ]
 *
 *    The structure (with details) is as follows:
 *   [
 *       {
 *           "code": "ndc_approved",
 *           "name": "NDC approved"
 *           "allowedTransitionsFrom": ["Unbinding Termsheet Signed","Dead - Bank Declined"]
 *           "allowedTransitionsTo": ["Unbinding Termsheet Signed","Dead - Bank Declined"]
 *       },
 *       [...]
 *    ]
 *
 * @returns {{isLoading: boolean, isError: boolean, data: {code: string, name: string}[] }}
 *
 */

/* eslint-disable react-hooks/rules-of-hooks */
/* disabling is ok, since eslint does not understand the factory pattern and
 * thinks we are calling the hooks directly (but we are not; we are producing hooks) */

/**
 * Factory to build variations of useDealStatus hook
 * @param expandDetails
 * @returns {function(): *|(*&{data: *|[]})}
 */
const factoryUseDealStatus = (expandDetails) => () =>
  useCamelizedResponse(
    useRequest({
      path: expandDetails ? `/deals/configurations/status/details` : `/deals/configurations/status`,
      useCache: true,
      translated: true,
      keys: expandDetails
        ? ['deals', 'configurations', 'status', 'details']
        : ['deals', 'configurations', 'status'],
    }),
  )

const useDealStatus = factoryUseDealStatus(false) // build normal hook
const useDealStatusWithDetails = factoryUseDealStatus(true) //build detailed hook

/**
 * Hooks to return allowed/restricted dealStatus
 * @param sourceStatusCode The source status for which the hook shall be built
 * @returns {function(): any} The lists of dealStatus reachable from sourceStatusCode
 */
const useRestrictedDealStatus = (sourceStatusCode) => {
  const dealStatusWithDetailsList = useDealStatusWithDetails()

  const dealStatusWithDetailsListRestricted = useMemo(() => {
    //clone dealStatus results
    const clonedDealStatusWithDetailsList = cloneDeep(dealStatusWithDetailsList)
    const originalData = clonedDealStatusWithDetailsList.data
    if (originalData) {
      //restrict data to reachable transitions
      clonedDealStatusWithDetailsList.data = originalData.filter((status) =>
        status?.allowedTransitionsFrom?.includes(sourceStatusCode),
      )
    }
    return clonedDealStatusWithDetailsList
  }, [sourceStatusCode, dealStatusWithDetailsList])

  return dealStatusWithDetailsListRestricted
}

export default useDealStatus
export { useDealStatusWithDetails, useRestrictedDealStatus }
