import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DocumentTypeInput from 'components/domains/documents/DocumentTypeInput'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import useAnalyticalTableIndexHelper from 'hooks/services/conditions/tables/useAnalyticalTableIndexHelper'
import {
  requirementDocumentTypeChanged,
  requirementRowHasError,
} from 'redux/slices/conditions/conditionCreationSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const ConditionCreationDocumentTypeCell = ({
  row: {
    id,
    index,
    depth,
    original: { documentType },
  },
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix:
      'components.conditions.overview.dialog.external-condition-creation.table.document-type-cell',
  })
  const {
    entityRef: { entityId, entityType },
  } = useContext(ConditionsContext)
  const dispatch = useDispatch()
  const { isConditionsCell, conditionIndex, requirementIndex } = useAnalyticalTableIndexHelper({
    id,
    index,
    depth,
  })

  const references = useSelector(
    (state) => state.conditions.conditionsCreation.conditions[conditionIndex]?.references,
  )

  const documentTypesEntityRefs = useMemo(() => {
    const defaultRefs = [{ type: DocumentTypes.Requirement, id: 'no-id' }]

    if (entityType === DocumentTypes.BusinessPartner) {
      return [...defaultRefs, { type: entityType, id: entityId }]
    }

    if (
      entityType === DocumentTypes.Deal &&
      references?.entityType &&
      references?.entityType !== entityType &&
      !isNil(references.entityIds) &&
      references.entityIds?.length > 0
    ) {
      return [...defaultRefs, { type: references.entityType, id: references.entityIds[0] }]
    }

    return [...defaultRefs, { type: entityType, id: entityId }]
  }, [references, entityId, entityType])

  const onTypeSelectionChanged = useCallback(
    (newDocumentType) => {
      if (newDocumentType === '' || newDocumentType === t('fallback')) {
        dispatch(
          requirementDocumentTypeChanged({
            conditionIndex,
            requirementIndex,
            documentType: '',
          }),
        )
        dispatch(
          requirementRowHasError({
            conditionIndex,
            requirementIndex,
            field: 'documentType',
            error: {
              warning: false,
            },
            showError: true,
          }),
        )

        return
      }
      dispatch(
        requirementDocumentTypeChanged({
          conditionIndex,
          requirementIndex,
          documentType: newDocumentType,
        }),
      )
    },
    [dispatch, conditionIndex, requirementIndex, t],
  )

  const inputError = useSelector(
    (state) =>
      state.conditions.conditionsCreation.errorRows[conditionIndex]?.[requirementIndex]
        ?.documentType,
  )
  const showInputError = useSelector(
    (state) =>
      !!state.conditions.conditionsCreation.errorRows[conditionIndex]?.[requirementIndex]
        ?.documentType?.showError,
  )
  const inputValueState = useMemo(() => {
    if (!inputError || !showInputError) {
      return { valueState: ValueState.None, valueMessage: '' }
    }
    if (inputError.error.warning) {
      return { valueState: ValueState.Warning, valueMessage: t('error') }
    }
    return { valueState: ValueState.Error, valueMessage: t('error') }
  }, [inputError, showInputError, t])

  if (isConditionsCell) {
    return
  }

  return (
    <DocumentTypeInput
      value={documentType}
      valueState={inputValueState.valueState}
      valueStateMessage={inputValueState.valueMessage}
      emptyOptionText={t('fallback')}
      onChange={onTypeSelectionChanged}
      entityRefs={documentTypesEntityRefs}
    />
  )
}

ConditionCreationDocumentTypeCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    depth: PropTypes.number.isRequired,
    original: PropTypes.shape({
      documentType: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default ConditionCreationDocumentTypeCell
