import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useSyndicationRoles = (options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/financing-structures/configurations/f4/category/SYNDICATION_ROLE`,
      translated: true,
      options,
    }),
  )

export default useSyndicationRoles
