import { Text } from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/default-risk-indicators-forbearance-measures/Section.module.css'

const propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  isResult: PropTypes.bool,
}
/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const Section = ({ label, children, isResult }) => (
  <section className={compact([styles.section, isResult && styles.result]).join(' ')}>
    <Text
      // HINT: Global class so that the counter has to be defined only once (selectors are not global)
      className={'label'}
    >
      {label}
    </Text>
    <main className={styles.content}>{children}</main>
  </section>
)

Section.propTypes = propTypes

export default Section
