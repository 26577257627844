import { MessageStrip } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/NewBusinessCheckResultMessageStrip.module.css'

export const NewBusinessCheckResultMessageStrip = ({
  isEditMode,
  activeEnding,
  possibleEndings,
  t,
}) => {
  if (!activeEnding || isEditMode) return <></>
  const resultMessageStrip = possibleEndings.find((ending) => ending.key === activeEnding)
  const highlightedText = resultMessageStrip?.highlightedTextKey && (
    <span className={styles.highlighedText}>{t(resultMessageStrip.highlightedTextKey)}</span>
  )
  return (
    resultMessageStrip && (
      <div className={styles.messageStripWrapper}>
        <MessageStrip design={resultMessageStrip.messageStripDesign} hideCloseButton>
          <span>
            {t(resultMessageStrip.key)}
            {highlightedText}
          </span>
        </MessageStrip>
      </div>
    )
  )
}

NewBusinessCheckResultMessageStrip.propTypes = {
  isEditMode: PropTypes.bool,
  activeEnding: PropTypes.string,
  possibleEndings: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      messageStripDesign: PropTypes.string,
    }),
  ),
  t: PropTypes.func.isRequired,
}
