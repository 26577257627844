import {
  BusyIndicatorSize,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ErrorDataUnavailableInTable } from 'components/ui/errors/ErrorDataUnavailableInTable'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import styles from 'components/ui/tables/analytical/AnalyticalTableNoDataComponent.module.css'

const AnalyticalTableNoDataComponent = ({
  tableId,
  isLoading,
  isError,
  translationPrefix = 'analytical-table',
}) => {
  const { t } = useTranslation('', { keyPrefix: translationPrefix })

  return (
    <div id={`${tableId}-NoDataComponentWrapper`} className={styles.noDataComponentWrapper}>
      <RequestStateResolver
        center
        busyIndicatorSize={BusyIndicatorSize.Medium}
        isLoading={isLoading}
        isError={isError}
        errorToDisplay={<ErrorDataUnavailableInTable />}
        renderContent={() => (
          <FlexBox direction={FlexBoxDirection.Column} alignItems={FlexBoxAlignItems.Center}>
            <span>{t('no-data-text')}</span>
          </FlexBox>
        )}
      />
    </div>
  )
}

AnalyticalTableNoDataComponent.propTypes = {
  tableId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  translationPrefix: PropTypes.string,
}

export default AnalyticalTableNoDataComponent
