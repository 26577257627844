import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useMultipleUpdatePropertyPartners = ({ propertyUuid, onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  const { delete: deletePropertyPartner } = useAccessTokenRequest()

  return useMutation(
    async ({ partnersToPost, partnersToDelete }) => {
      for (const partnerToPost of partnersToPost) {
        await post({
          path: `/properties/${propertyUuid}/businesspartners`,
          body: {
            ...partnerToPost,
          },
        })
      }

      for (const { partnerId, functionCode } of partnersToDelete) {
        await deletePropertyPartner({
          path: `/properties/${propertyUuid}/businesspartners?businesspartner_id=${partnerId}&businesspartner_function_code=${functionCode}`,
        })
      }
    },
    {
      onSuccess,
      onError,
    },
  )
}
