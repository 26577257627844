import {
  CustomListItem,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  ListItemType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperTileListItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListItem'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/key-facts/MultipleKeyFactsTile.module.css'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'
import FormattedAddress from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/keyFacts/shared/FormattedAddress'

const NO_DATA_PLACEHOLDER = '-'

const MultipleKeyFactsTile = ({ tileId, selectedDealIndex }) => {
  const {
    data: { factSheetKeyFactsPerDealUuid = {}, dealsData },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const selectedDealUuid = dealsData[selectedDealIndex]?.dealUuid

  // WARN: We can't index into the object directly because the keys are being mutated by the decision paper framework
  //       They're converted to snake_case and to camelCase, and that messes up the UUIDs
  const factSheetData =
    Object.values(factSheetKeyFactsPerDealUuid).find(
      ({ dealUuid }) => dealUuid === selectedDealUuid,
    ) ?? {}

  const { propertiesInformation, modernizationYear, kpiRentValues, isMultiProperty, noProperties } =
    factSheetData

  const { t: tKeyFacts } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.fact-sheet-key-facts',
  })

  const getValueOrPlaceholder = (value) => (value ? value : NO_DATA_PLACEHOLDER)

  const getYearOrPlaceholder = (yearList) =>
    yearList?.length > 1 ? `${yearList[0]} - ${yearList[1]}` : getValueOrPlaceholder(yearList?.[0])

  const getCountryOrPlaceholder = (countryList) => {
    if (countryList?.length > 1) {
      return countryList.join(', ')
    }
    return getValueOrPlaceholder(countryList?.[0])
  }

  if (noProperties) {
    return <NoPropertyAssignedToDealInfo />
  }
  return (
    <>
      <DecisionPaperTileListItem
        label={tKeyFacts('major-type')}
        value={getValueOrPlaceholder(propertiesInformation?.majorTypeName)}
        isFirstItem
      />
      {!isMultiProperty && (
        <DecisionPaperTileListItem
          label={tKeyFacts('sub-type')}
          value={getValueOrPlaceholder(propertiesInformation?.subType)}
        />
      )}
      <DecisionPaperTileListItem
        label={tKeyFacts('construction-year')}
        value={getYearOrPlaceholder(propertiesInformation?.constructionYear)}
      />
      <DecisionPaperTileListItem
        label={tKeyFacts('modernization-year')}
        value={getYearOrPlaceholder(modernizationYear)}
      />
      <DecisionPaperTileListItem
        label={isMultiProperty ? tKeyFacts('countries') : tKeyFacts('country')}
        value={getCountryOrPlaceholder(propertiesInformation?.countryName)}
      />
      {!isMultiProperty && (
        <CustomListItem type={ListItemType.Inactive}>
          <FlexBox fitContainer justifyContent={FlexBoxJustifyContent.SpaceBetween}>
            <Label>{tKeyFacts('address')}</Label>
            <FlexBox
              alignItems={FlexBoxAlignItems.End}
              direction={FlexBoxDirection.Column}
              className={styles.address}
            >
              <FormattedAddress
                country={propertiesInformation?.address?.countryName}
                street={propertiesInformation?.address?.streetName}
                houseNumber={propertiesInformation?.address?.houseId}
                zipCode={propertiesInformation?.address?.postalCode}
                city={propertiesInformation?.address?.cityName}
              />
            </FlexBox>
          </FlexBox>
        </CustomListItem>
      )}
      {isMultiProperty && (
        <DecisionPaperTileListItem
          label={tKeyFacts('nr-of-properties')}
          value={getValueOrPlaceholder(propertiesInformation?.nrOfProperties)}
        />
      )}
      <DecisionPaperTileListItem
        label={tKeyFacts('annualized-current-rent')}
        value={getValueOrPlaceholder(kpiRentValues?.annualizedCurrentRent)}
      />
      <DecisionPaperTileListItem
        label={tKeyFacts('market-rent')}
        value={getValueOrPlaceholder(kpiRentValues?.marketRent)}
      />
      <DecisionPaperTileListItem
        label={tKeyFacts('multiplier')}
        value={getValueOrPlaceholder(propertiesInformation?.multiplier)}
      />
      <div className={styles.lastItem}>
        <DecisionPaperTileListItem
          label={tKeyFacts('initial-yield')}
          value={getValueOrPlaceholder(propertiesInformation?.initialYield)}
        />
      </div>
    </>
  )
}

MultipleKeyFactsTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultipleKeyFactsTile
