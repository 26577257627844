import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DisbursementTileDisplayOrEditItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/disbursement/fields/DisbursementTileDisplayOrEditItem'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/portolio-analysis/PortfolioAnalysisTile.module.css'
import BooleanSelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/portolio-analysis/fields/BooleanSelect'
import PortfolioAnalysisTileTextField from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/portolio-analysis/fields/PortfolioAnalysisTileTextField'
import { useBooleanToTextFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const contentKeys = {
  coverpoolRelevance: 'coverpoolRelevance',
  coverpoolReason: 'coverpoolReason',
}

const coverpoolReasonMaxLength = 200
const growingMaxLines = 10

const PortfolioAnalysisTile = ({
  tileId,
  currentContent,
  onChange,
  isEditMode,
  isPdfView,
  setHasContentChanges,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.portfolio-analysis',
  })
  const formatBoolean = useBooleanToTextFormatter()

  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const showEditMode = useMemo(() => !isPdfView && isEditMode, [isEditMode, isPdfView])

  const updateContent = useCallback(
    (updatedContent) => {
      const hasChanges = !isEqual(tileStatus?.data?.data, updatedContent)
      setHasContentChanges(hasChanges)
      onChange(updatedContent)
    },
    [onChange, setHasContentChanges, tileStatus?.data?.data],
  )

  const handleOnChange = useCallback(
    (key, newValue) => {
      updateContent({ ...currentContent, [key]: newValue })
    },
    [currentContent, updateContent],
  )

  const handleOnCoverpoolRelevanceChange = useCallback(
    (newValue) => {
      handleOnChange(contentKeys.coverpoolRelevance, newValue)
    },
    [handleOnChange],
  )

  const handleOnCoverpoolReasonChange = useCallback(
    (newValue) => {
      handleOnChange(contentKeys.coverpoolReason, newValue)
    },
    [handleOnChange],
  )

  const renderCoverpoolRelevanceEditMode = useCallback(
    () => (
      <BooleanSelect
        value={currentContent?.coverpoolRelevance}
        onChange={handleOnCoverpoolRelevanceChange}
      />
    ),
    [currentContent?.coverpoolRelevance, handleOnCoverpoolRelevanceChange],
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.tileWrapper}>
      <DisbursementTileDisplayOrEditItem
        label={t('label.coverpool-relevance')}
        value={formatBoolean(currentContent?.coverpoolRelevance)}
        renderEditComponent={renderCoverpoolRelevanceEditMode}
        isEditMode={showEditMode}
      />
      <PortfolioAnalysisTileTextField
        label={t('label.coverpool-reason')}
        value={currentContent?.coverpoolReason}
        isEditMode={showEditMode}
        maxLength={coverpoolReasonMaxLength}
        onChange={handleOnCoverpoolReasonChange}
        growing={true}
        growingMaxLines={growingMaxLines}
      />
    </FlexBox>
  )
}

PortfolioAnalysisTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  currentContent: PropTypes.shape({
    coverpoolReason: PropTypes.string,
    coverpoolRelevance: PropTypes.bool,
  }),
  onChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
}

export default PortfolioAnalysisTile
