import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

export const useMultipleKpis = (referenceEntityType, referenceEntityIds, options = {}) => {
  const mappedRequests = referenceEntityIds.map((referenceEntityId) => {
    const urlSearchParams = new URLSearchParams()

    if (referenceEntityType) urlSearchParams.append('reference_entity_type', referenceEntityType)
    if (referenceEntityId) urlSearchParams.append('reference_entity_id', referenceEntityId)

    const paramsString = [...urlSearchParams.keys()].length ? `?${urlSearchParams.toString()}` : ''

    return {
      path: `/kpis${paramsString}`,
      keys: ['kpis', referenceEntityType, referenceEntityId],
    }
  })

  const responses = useRequestsMemo(
    useRequests({
      requests: mappedRequests,
      useCache: true,
      options: { ...options, enabled: !isEmpty(referenceEntityIds) },
    }),
  )

  return useMemo(
    () =>
      responses.map((result, index) => ({
        ...result,
        data: { referenceEntityId: referenceEntityIds[index], ...camelize(result.data) },
      })),
    [referenceEntityIds, responses],
  )
}
