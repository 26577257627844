import { useMemo } from 'react'

export const getConditionIndexFromId = (id) => {
  if (id.indexOf('.') === -1) {
    return +id
  }
  return +id.substring(0, id.indexOf('.'))
}

export const getRequirementIndexFromId = (id) => {
  if (id.indexOf('.') === -1) {
    return -1
  }
  return +id.substring(id.indexOf('.') + 1)
}

export const calculateConditionIndex = ({ isConditionsCell, index, id }) => {
  if (isConditionsCell) {
    return index
  }
  return getConditionIndexFromId(id)
}

export const calculateRequirementIndex = ({ isConditionsCell, index }) => {
  if (!isConditionsCell) {
    return index
  }
  return -1
}

export const isConditionsCell = (depth) => depth === 0

const useAnalyticalTableIndexHelper = ({ id, index, depth }) => {
  const isConditionsCellInternal = useMemo(() => isConditionsCell(depth), [depth])
  const conditionIndex = useMemo(
    () => calculateConditionIndex({ isConditionsCell: isConditionsCellInternal, index, id }),
    [isConditionsCellInternal, id, index],
  )
  const requirementIndex = useMemo(
    () => calculateRequirementIndex({ isConditionsCell: isConditionsCellInternal, index }),
    [isConditionsCellInternal, index],
  )

  return useMemo(
    () => ({
      isConditionsCell: isConditionsCellInternal,
      requirementIndex,
      conditionIndex,
    }),
    [isConditionsCellInternal, requirementIndex, conditionIndex],
  )
}

export default useAnalyticalTableIndexHelper
