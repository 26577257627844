import camelize from 'camelize'
import { useMemo } from 'react'
import { isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { collateralAgreementSchema } from 'hooks/services/collaterals/collateralAgreementSchema'

const retries = 3

/**
 * Get collateral agreements by id
 * @param {string} [id]
 * @param {boolean} disableFetchStatus
 *
 * When disableFetchStatus set to true, the query is not called (usecase needed for getting the status of cags, when data is provided locally)
 */
const useCollateralAgreementByID = (id, { enabled = true } = {}) => {
  const uuidLength = 36
  const result = useRequest({
    keys: ['collateral-agreements', id],
    path: `/collateral-agreements/${id}`,
    options: {
      retry: (failureCount, error) => (isNotFoundError(error) ? false : failureCount < retries),
      enabled: enabled && !!id && id.length === uuidLength,
    },
  })

  const data = camelize(result.data)
  if (data) {
    data.allowedOperations = result.data?._allowed_operations ?? []
  }

  return useMemo(
    () => ({
      ...result,
      data: collateralAgreementSchema.parse(data),
    }),
    [data, result],
  )
}

export default useCollateralAgreementByID
