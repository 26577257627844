import { AnalyticalTableScaleWidthMode } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { mapAggregatedMultiCollateralAgreementsToTableData as mapAggregatedCollateralAgreementsToTableData } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/financing-structure/collateral-agreements/mapAggregatedMultiCollateralAgreementsToTableData'
import { default as useCollateralAgreementsTileColumnDefinitions } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/financing-structure/collateral-agreements/useMultiCollateralAgreementsTileColumnDefinitions'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/RiskMonitoringCollateralAgreementsTile.module.css'
import AnalyticalTableNoDataComponent from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/analytical/AnalyticalTableWithToolbar'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const RiskMonitoringCollateralAgreementsTile = ({ tileId, selectedDealIndex }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.collaterals.collateral-agreements-table',
  })

  const { dealsData, dealCollateralAgreementsData, sourcePaths } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId].data,
  )

  const selectedDealUuid = dealsData[selectedDealIndex]?.dealUuid

  useEffect(() => {
    dispatch(
      setSourceRender({ tileId, sourceRender: { path: `${sourcePaths?.[selectedDealIndex]}` } }),
    )
  }, [selectedDealIndex, dealsData, dispatch, sourcePaths, tileId])

  const collateralAgreements = useMemo(
    () =>
      dealCollateralAgreementsData?.find(
        (dealCollateralAgreements) => dealCollateralAgreements?.dealUuid === selectedDealUuid,
      ),
    [dealCollateralAgreementsData, selectedDealUuid],
  )

  const mappedData = useMemo(
    () =>
      mapAggregatedCollateralAgreementsToTableData({
        aggregatedCollateralAgreements: !isEmpty(collateralAgreements?.cagClusters)
          ? collateralAgreements
          : {},
        areRowsSectionHeader: false,
        isTotalSectionHeader: true,
        isNormalRowBold: false,
      }),
    [collateralAgreements],
  )

  const columnDefinitions = useCollateralAgreementsTileColumnDefinitions()

  return useMemo(
    () => (
      <AnalyticalTableWithToolbar
        title={t('title')}
        showNrOfEntries={false}
        className={styles.table}
        loading={false}
        id="collateralAgreementsTile-table"
        sortable={false}
        minRows={0}
        columns={columnDefinitions}
        overscanCountHorizontal={99}
        rowHeight={50}
        visibleRows={99}
        headerRowHeight={30}
        data={mappedData ?? []}
        isTreeTable={false}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
        withRowHighlight={false}
        NoDataComponent={() => (
          <AnalyticalTableNoDataComponent
            isLoading={false}
            isError={false}
            tableId="collateralAgreementsTile-table"
          />
        )}
      />
    ),
    [columnDefinitions, mappedData, t],
  )
}

RiskMonitoringCollateralAgreementsTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default RiskMonitoringCollateralAgreementsTile
