import { Label } from '@fioneer/ui5-webcomponents-react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/modular-table/ModularTableTitle.module.css'

const ModularTableTitle = ({ title, nrOfEntries }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.ui.tables.toolbar' })
  return <Label className={styles.tableTitle}>{t('title', { title, nrOfEntries })}</Label>
}

ModularTableTitle.propTypes = {
  title: PropTypes.string,
  nrOfEntries: PropTypes.number,
}

export default ModularTableTitle
