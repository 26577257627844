import isNil from 'lodash.isnil'
import {
  DEFAULT_DISPLAY_TEXT_GAP,
  DEFAULT_DISPLAY_ROW_HEIGHT,
  ROW_TYPE_OWN_SHARE,
  DEFAULT_DISPLAY_TEXT_HEIGHT,
  ROOT_ROW_ID_OWN_SHARE,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/constants'
import createSectionHeaderRow from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/createSectionHeaderRow'
import mapOwnShareRoles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/mapOwnShareRoles'

const mapOwnShare = (t, data) => {
  if (!data) {
    return []
  }

  const calculatedRowHeight =
    DEFAULT_DISPLAY_ROW_HEIGHT +
    (!isNil(data.amountTotalHeadquarter) &&
    data.amountTotalHeadquarter?.currency !== data.amountTotal?.currency
      ? DEFAULT_DISPLAY_TEXT_HEIGHT
      : 0) +
    (!isNil(data.amountShare) ? DEFAULT_DISPLAY_TEXT_HEIGHT + DEFAULT_DISPLAY_TEXT_GAP : 0)
  const rootRowId = ROOT_ROW_ID_OWN_SHARE

  const content = {
    rowType: ROW_TYPE_OWN_SHARE,
    rootRowId,
    partyRoleTranche: {
      value: data.businessPartnerId,
      isBold: true,
    },
    commitment: {
      value: data.amountTotal,
      valueHeadquarter: data.amountTotalHeadquarter,
      share: data.amountShare,
      isBold: true,
    },
    rowHeight: Math.max(calculatedRowHeight, DEFAULT_DISPLAY_ROW_HEIGHT),
    subRows: mapOwnShareRoles(data.roles ?? [], rootRowId),
  }
  return [createSectionHeaderRow(t, 'ownShare'), content]
}

export default mapOwnShare
