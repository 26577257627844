import { FilterGroupItem, Input } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import { sortBy, capitalize } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import DealChangeOverviewMultiComboBoxFilter from 'components/domains/deals/change-overview/DealChangeOverviewMultiComboBoxFilter'
import DashboardFilterBar from 'components/ui/page/DashboardFilterBar'
import useDealChangeOverviewUnusedTranslationTemplateRemover from 'hooks/services/deals/change-overview/useDealChangeOverviewTranslationTemplateRemover'
import useChangeLogActionTypes from 'hooks/services/traceability-of-changes/useChangeLogActionTypes'
import useChangeLogCards from 'hooks/services/traceability-of-changes/useChangeLogCards'
import useChangeLogEntityTypes from 'hooks/services/traceability-of-changes/useChangeLogEntityTypes'
import useChangeLogPages from 'hooks/services/traceability-of-changes/useChangeLogPages'

const getFilterStateByQueryParams = (queryParams) => ({
  entity_id: queryParams.get('entity_id') || '',
  entity_type: queryParams.get('entity_type') || '',
  page_id: queryParams.get('page_id') || '',
  card_id: queryParams.get('card_id') || '',
  action_type: queryParams.get('action_type') || '',
})

const isEnterButtonEvent = (event) => event.code === 'Enter' || event.code === 'NumpadEnter'

const DealChangeOverviewFilterBar = ({ dealUuid }) => {
  const ENTITY_TYPE = 'deal'
  const { t: tDealChangeOverview } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.change-overview',
  })

  const filterOptions = {
    entity_id: { visibleOnBar: true },
    entity_type: { visibleOnBar: true },
    page_id: { visibleOnBar: true },
    card_id: { visibleOnBar: true },
    action_type: { visibleOnBar: true },
  }

  const { remover: translationTemplateRemover } =
    useDealChangeOverviewUnusedTranslationTemplateRemover()

  const { data: entityTypesData } = useChangeLogEntityTypes({
    entityId: dealUuid,
    entityType: ENTITY_TYPE,
  })
  const entityTypes =
    sortBy(
      entityTypesData?.entityTypes.map((entityType) => ({
        key: entityType,
        display_name: tDealChangeOverview(`entities.${entityType}`),
      })),
      'display_name',
    ) ?? []

  const { data: pagesData } = useChangeLogPages({
    entityId: dealUuid,
    entityType: ENTITY_TYPE,
  })
  const pages =
    sortBy(
      pagesData?.pageKeys.map((page) => ({
        key: page?.id,
        display_name: translationTemplateRemover(t(page?.translationKey)),
      })),
      'display_name',
    ) ?? []

  const { data: cardsData } = useChangeLogCards({
    entityId: dealUuid,
    entityType: ENTITY_TYPE,
  })
  const cards =
    sortBy(
      cardsData?.cardKeys.map((card) => ({
        key: card.id,
        page_translation_key: t(card.pageTranslationKey),
        display_name: `${translationTemplateRemover(
          t(card.cardTranslationKey),
        )} (${translationTemplateRemover(t(card.pageTranslationKey))})`,
      })),
      'page_translation_key',
    ) ?? []

  const { data: actionTypesData } = useChangeLogActionTypes({
    entityId: dealUuid,
    entityType: ENTITY_TYPE,
  })
  const actionTypes =
    sortBy(
      actionTypesData?.actionTypes.map((actionType) => ({
        key: actionType,
        display_name: capitalize(actionType.replaceAll('_', ' ')),
      })),
      'display_name',
    ) ?? []

  const [queryParams, setQueryParams] = useSearchParams()
  const [filterParams, setFilterParams] = useState(getFilterStateByQueryParams(queryParams))

  useEffect(() => {
    setFilterParams(getFilterStateByQueryParams(queryParams))
  }, [queryParams])

  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false)

  const onFilter = () => {
    Object.entries(filterParams).forEach(([key, value]) => queryParams.set(key, value))
    setQueryParams(queryParams)
  }

  const onKeyDown = (event) => {
    if (isEnterButtonEvent(event) && !isFilterDialogOpen) {
      onFilter()
    }
  }

  const textInputChange = (key) => (event) => {
    setFilterParams({
      ...filterParams,
      [key]: event.target.value.trim(),
    })
  }

  const calculateActiveFilterCount = (currQueryParams) => {
    const activeFilterCount = [...currQueryParams.entries()].filter(
      ([fieldName, fieldValue]) => fieldName !== 'sort' && Boolean(fieldValue),
    ).length

    return activeFilterCount
  }

  const isFieldActive = (fieldName) =>
    [...queryParams.entries()].filter(
      ([filterFieldName, filterFieldValue]) =>
        filterFieldName === fieldName && Boolean(filterFieldValue),
    ).length > 0

  const filters = [
    <FilterGroupItem
      label={`${tDealChangeOverview('table.filter.entity-id')}`}
      key="entity_id"
      active={isFieldActive('entity_id')}
    >
      <Input
        id="deal-changed-overview-table-entity-id-filter"
        value={filterParams.entity_id}
        onInput={textInputChange('entity_id')}
        onKeyDown={onKeyDown}
        showClearIcon
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${tDealChangeOverview('table.filter.entity-type')}`}
      key="entity_type"
      active={isFieldActive('entity_type')}
    >
      <DealChangeOverviewMultiComboBoxFilter
        filterItems={entityTypes}
        filterType={'entity_type'}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        onKeyDown={onKeyDown}
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${tDealChangeOverview('table.filter.page')}`}
      key="page_id"
      active={isFieldActive('page_id')}
    >
      <DealChangeOverviewMultiComboBoxFilter
        filterItems={pages}
        filterType={'page_id'}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        onKeyDown={onKeyDown}
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${tDealChangeOverview('table.filter.card')}`}
      key="card_id"
      active={isFieldActive('card_id')}
    >
      <DealChangeOverviewMultiComboBoxFilter
        filterItems={cards}
        filterType={'card_id'}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        onKeyDown={onKeyDown}
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${tDealChangeOverview('table.filter.action')}`}
      key="action_type"
      active={isFieldActive('action_type')}
    >
      <DealChangeOverviewMultiComboBoxFilter
        filterItems={actionTypes}
        filterType={'action_type'}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        onKeyDown={onKeyDown}
      />
    </FilterGroupItem>,
  ]

  return (
    <DashboardFilterBar
      key="deal-changed-overview-search-filter-bar"
      filterOptions={filterOptions}
      activeFiltersCount={calculateActiveFilterCount(queryParams)}
      onGo={onFilter}
      setDialogOpen={setIsFilterDialogOpen}
      searchInputField={<Input placeholder="Search" />}
    >
      {filters}
    </DashboardFilterBar>
  )
}
DealChangeOverviewFilterBar.propTypes = {
  dealUuid: PropTypes.string.isRequired,
}

export default DealChangeOverviewFilterBar
