import camelize from 'camelize'
import { useRequest } from 'hooks/services/baseService'

const useTaskTemplates = (eventId) => {
  const result = useRequest({
    path: `/events/${eventId}/task-templates`,
    useCache: true,
    keys: ['events', eventId, 'task-templates'],
  })

  return { ...result, data: camelize(result.data) }
}

export default useTaskTemplates
