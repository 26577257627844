import { Input } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { valuationRelevance } from 'api/property/valuation/valuationRequests'
import BooleanCell from 'components/ui/tables/cells/BooleanCell'

const PropertyValuationResultsRegulatoryRelevantCell = ({ regulatoryRelevance, isAddMode }) => {
  const { t } = useTranslation('translation')
  const isRegulatoryRelevant = regulatoryRelevance === valuationRelevance.relevant

  if (!isAddMode) {
    return <BooleanCell value={isRegulatoryRelevant} />
  }

  return <Input readonly value={t('formatters.boolean.no')} />
}

PropertyValuationResultsRegulatoryRelevantCell.propTypes = {
  regulatoryRelevance: PropTypes.string,
  isAddMode: PropTypes.bool.isRequired,
}

export default PropertyValuationResultsRegulatoryRelevantCell
