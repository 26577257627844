import { FlexBox, FlexBoxDirection, FlexBoxJustifyContent } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/properties/monitoring/settings/dialog/DialogContent.module.css'

const DialogContent = ({ children }) => {
  const childrenArray = Array.isArray(children) ? children : [children]
  const normalizedChildren = childrenArray.filter((childComponent) => !!childComponent)

  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      fitContainer
      justifyContent={FlexBoxJustifyContent.Start}
    >
      {normalizedChildren.map((childComponent, index) => (
        <div key={`dialog-content-${index}`} className={styles.dialogContent}>
          {childComponent}
        </div>
      ))}
    </FlexBox>
  )
}

DialogContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}

export default DialogContent
