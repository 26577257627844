import { Label, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { cloneElement, isValidElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/card/CardItem.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'

const cardItemProps = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.element,
  ]),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
}

/**
 * https://fioneer.atlassian.net/browse/CWP-13200 | move to ui-folder
 */
const CardItem = ({
  id,
  className,
  label,
  required = false,
  value,
  isLoading = false,
  isError = false,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.deals.card-show-item' })
  const childId = value?.props?.id || id || `card-show-item-${label}`

  const renderItemValue = useCallback(
    () =>
      isValidElement(value) ? (
        cloneElement(value, { className: `${styles.value} ${value.props.className ?? ''}` })
      ) : (
        <Text id={childId} className={styles.textValue} wrapping>
          {value || t('empty-value')}
        </Text>
      ),
    [childId, t, value],
  )

  return (
    <div className={`${styles.cardItem} ${className ?? ''}`}>
      <Label className={styles.label} for={childId} required={required} wrapping>
        {label}
      </Label>
      <SmallLoadingWrapper
        id={`${childId}-loader`}
        isLoading={isLoading}
        isError={isError}
        error={t('error')}
        showErrorInHover
        renderContent={renderItemValue}
      />
    </div>
  )
}

CardItem.propTypes = cardItemProps
const CardShowItem = ({ className, ...props }) => (
  <CardItem className={`${styles.showItem} ${className ?? ''}`} {...props} />
)

CardShowItem.propTypes = cardItemProps

const CardEditItem = ({ className, ...props }) => (
  <CardItem className={`${styles.editItem} ${className ?? ''}`} {...props} />
)

CardEditItem.propTypes = { ...cardItemProps, children: PropTypes.element }

export { CardItem, CardEditItem, CardShowItem }
