import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionsMassEditOperations } from 'api/conditions/conditions'
import LoadingDropdown from 'components/ui/dropdown/LoadingDropdown'

const MassEditOperationSelect = ({ idPrefix, selectableOperations, onChange, disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.dialogs.mass-edit',
  })

  const operationsLoader = useCallback(
    () => ({
      isError: false,
      isLoading: false,
      data: selectableOperations.map((operation) => ({
        code: operation,
        displayName: t(`operations.${operation}`),
      })),
    }),
    [selectableOperations, t],
  )

  return (
    <LoadingDropdown
      id={`${idPrefix}-mass-edit-operation-select`}
      useLoader={operationsLoader}
      onChange={onChange}
      value={selectableOperations[0]}
      disabled={disabled}
    />
  )
}

MassEditOperationSelect.propTypes = {
  idPrefix: PropTypes.string.isRequired,
  selectableOperations: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(conditionsMassEditOperations)),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default MassEditOperationSelect
