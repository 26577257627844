import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import ForbearanceAndUtpSection from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/ForbearanceAndUtpSection'
import ForbearancePreCheckSection from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/ForbearancePrecheckSection'
import { QuestionKeys } from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/forbearanceAndUtpQuestionareSchema'
import useIrrefutableForbearanceMeasureOptions from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/options//useIrrefutableForbearanceMeasureOptions'
import useBoolOptions from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/options/useBoolOptions'
import useColorOptions from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/options/useColorOptions'
import useFinalResultOptions from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/options/useFinalResultOptions'
import useForbearanceAndUtpCheckOptions from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/options/useForbearanceAndUtpCheckOptions'
import useIndicatorForFinancialDifficultiesOptions from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/options/useIndicatorForFinancialDifficultiesOptions'

export const ForbearanceAndUtpQuestionareContext = createContext()
const ForbearanceAndUtpQuestionareTile = ({
  tileId,
  currentContent,
  onChange,
  isEditMode,
  setHasContentChanges,
  setIsSaveEnabled,
}) => {
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const boolOptions = useBoolOptions()
  const colorOptions = useColorOptions()
  const forbearanceAndUtpCheckOptions = useForbearanceAndUtpCheckOptions()
  const indicatorForFinancialDifficultiesOptions = useIndicatorForFinancialDifficultiesOptions()
  const irrefutableForbearanceMeasureOptions = useIrrefutableForbearanceMeasureOptions()
  const finalResultOptions = useFinalResultOptions()

  const initialAnswers = useMemo(
    () => ({
      answers: {
        Q11: { value: null, isRequired: true },
        Q11_comment: { value: null, isRequired: false },
        Q12: { value: null, isRequired: true },
        Q12_comment: { value: null, isRequired: false },
        Q13: { value: null, isRequired: false },
        Q211: { value: null, isRequired: false },
        Q211_comment: { value: null, isRequired: false },
        Q212: { value: null, isRequired: false },
        Q212_comment: { value: null, isRequired: false },
        Q213: { value: null, isRequired: false },
        Q213_comment: { value: null, isRequired: false },
        Q214: { value: null, isRequired: false },
        Q214_comment: { value: null, isRequired: false },
        Q215: { value: null, isRequired: false },
        Q215_comment: { value: null, isRequired: false },
        Q221: { value: null, isRequired: false },
        Q221_comment: { value: null, isRequired: false },
        Q222: { value: null, isRequired: false },
        Q222_comment: { value: null, isRequired: false },
        Q223: { value: null, isRequired: false },
        Q223_comment: { value: null, isRequired: false },
        Q224: { value: null, isRequired: false },
        Q224_comment: { value: null, isRequired: true },
        Q231: { value: null, isRequired: false },
        Q231_comment: { value: null, isRequired: true },
      },
    }),
    [],
  )

  const [questionareState, setQuestionareState] = useState({
    shouldBeCollapsed: false,
  })

  const { answers: currentAnswers } = useMemo(
    () =>
      currentContent && typeof currentContent === 'string'
        ? JSON.parse(currentContent)
        : initialAnswers,
    [currentContent, initialAnswers],
  )

  useEffect(() => {
    const hasContentChanges =
      !(isNil(currentContent) && isNil(tileStatus?.data?.data)) &&
      !isEqual(tileStatus?.data?.data, currentContent)
    setHasContentChanges(hasContentChanges)
  }, [currentContent, tileStatus, setHasContentChanges, currentAnswers])

  const isFirstSectionAnswered = useMemo(
    () => currentAnswers[QuestionKeys.Q13].value === 'notRequired',
    [currentAnswers],
  )

  useEffect(() => {
    const shouldSaveBeDisabled = [
      currentAnswers[QuestionKeys.Q11],
      currentAnswers[QuestionKeys.Q12],
      currentAnswers[QuestionKeys.Q11_comment],
      currentAnswers[QuestionKeys.Q12_comment],
      currentAnswers[QuestionKeys.Q224_comment],
      currentAnswers[QuestionKeys.Q231_comment],
    ]
      .filter((a) => a?.isRequired)
      .some((a) => !a.value || a.value === '-')

    if (shouldSaveBeDisabled) {
      setIsSaveEnabled(false)
    } else {
      setIsSaveEnabled(true)
    }
  }, [currentAnswers, setIsSaveEnabled])

  return (
    <ForbearanceAndUtpQuestionareContext.Provider
      value={{
        questionareState,
        setQuestionareState,
        setIsSaveEnabled,
      }}
    >
      <ForbearancePreCheckSection
        isEditMode={isEditMode}
        allSelectableOptions={{
          boolOptions,
          colorOptions,
          forbearanceAndUtpCheckOptions,
          indicatorForFinancialDifficultiesOptions,
        }}
        currentAnswers={currentAnswers}
        onChange={onChange}
      />
      <ForbearanceAndUtpSection
        isEditMode={isEditMode && !isFirstSectionAnswered}
        allSelectableOptions={{
          boolOptions,
          colorOptions,
          forbearanceAndUtpCheckOptions,
          indicatorForFinancialDifficultiesOptions,
          irrefutableForbearanceMeasureOptions,
          finalResultOptions,
        }}
        currentAnswers={currentAnswers}
        onChange={onChange}
      />
    </ForbearanceAndUtpQuestionareContext.Provider>
  )
}

ForbearanceAndUtpQuestionareTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  currentContent: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
  setIsSaveEnabled: PropTypes.func.isRequired,
}

export default ForbearanceAndUtpQuestionareTile
