import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import styles from 'components/ui/tables/cells/BooleanCell.module.css'

const propTypes = {
  /** The boolean value or statement to evaluate the cell's label. */
  value: PropTypes.bool,
  /** Custom labels to show for the different boolean value cases. */
  customLabels: PropTypes.shape({
    trueLabel: PropTypes.string,
    falseLabel: PropTypes.string,
    emptyLabel: PropTypes.string,
  }),
  /**
   * Options to modify how the Cell will look like.
   *
   * - `isBold` Display the resulting text in bold font. Defaults to false.
   */
  options: PropTypes.shape({
    isBold: PropTypes.bool,
  }),
  /** Defines whether a loading spinner is shown. */
  isLoading: PropTypes.bool,
  /** Defines whether a loading spinner is shown. */
  isFetching: PropTypes.bool,
  /** Defines whether an error is shown. */
  isError: PropTypes.bool,
  /** Allows to define a custom error component. Defaults to undefined so that `<ErrorDataUnavailableInCell />` is shown. */
  errorToDisplay: PropTypes.node,
  /** Defines additional styling to the outmost div of the cell's content. */
  className: PropTypes.string,
}

/**
 * Shared Boolean Cell for usage within the Responsive and Analytical Table.
 *
 * @typedef Props
 * @property {Boolean?} [value] The status value to display as the component's label.
 * @property {Object} [customLabels] Custom labels to show for the different boolean value cases.
 * @property {String} [customLabels.trueLabel] The custom label string to show for a true value.
 * @property {String} [customLabels.falseLabel] The custom label string to show for a false value.
 * @property {String} [customLabels.emptyLabel] The custom label string to show for an undefined/null value.
 * @property {Object} [options] Options to modify how the Cell will look like.
 * @property {Boolean} [options.isBold] Display the resulting text in bold font. Defaults to false.
 * @property {Boolean} [isLoading] Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} [isFetching] Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} [isError] Defines whether an error is shown.
 * @property {React.ReactNode} [errorToDisplay] Allows to define a custom error component. Defaults to undefined so that <ErrorDataUnavailableInCell /> is shown.
 * @property {String} [className] Defines additional styling to the outmost div of the cell's content.
 */

/**
 * @param {Props} props Note for Storybook: cf. code for complete JSDoc
 */

const BooleanCell = ({
  value,
  customLabels: { trueLabel, falseLabel, emptyLabel } = {},
  options: { isBold = false } = {},
  isLoading = false,
  isFetching = false,
  isError = false,
  className,
  errorToDisplay,
}) => {
  const { t } = useTranslation('translation')

  const renderContent = () => {
    const renderText = () => {
      switch (value) {
        case true:
          return trueLabel ?? t('formatters.boolean.yes')
        case false:
          return falseLabel ?? t('formatters.boolean.no')
        case undefined:
        case null:
          return emptyLabel ?? t('formatters.boolean.empty-value')
        default:
          return ''
      }
    }

    return (
      <div className={compact([isBold ? styles.textBold : '', className]).join(' ')}>
        {renderText()}
      </div>
    )
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading || isFetching}
      isError={isError}
      error={errorToDisplay ?? <ErrorDataUnavailableInCell />}
      renderContent={renderContent}
    />
  )
}

BooleanCell.propTypes = propTypes

export default BooleanCell
