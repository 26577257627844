import { useMemo } from 'react'
import { useFactSheetKeyFacts } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/useFactSheetKeyFacts'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'

const useAnnualReviewFactSheetKeyFacts = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { selectedDeal, dealsData, dealUuids } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const { selectedDealUuid } = selectedDeal ?? {}

  const {
    data: factSheetKeyFactsData,
    isLoading: isFactSheetKeyFactsLoading,
    isError: isFactSheetKeyFactsError,
    error: factSheetKeyFactsError,
  } = useFactSheetKeyFacts({ dealUuids }) ?? {}

  const { factSheetKeyFactsPerDealUuid } = factSheetKeyFactsData ?? {}

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isAnnualReviewDealsLoading, isFactSheetKeyFactsLoading],
    errorValues: [isAnnualReviewDealsError, isFactSheetKeyFactsError],
    errorDetails: [annualReviewDealsError, factSheetKeyFactsError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        factSheetKeyFactsPerDealUuid,
        sourceRender: factSheetKeyFactsPerDealUuid[selectedDealUuid]?.sourceRender,
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealsData,
    factSheetKeyFactsPerDealUuid,
    selectedDealUuid,
    error,
  ])
}
export default useAnnualReviewFactSheetKeyFacts
