import {
  Button,
  Dialog,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Grid,
} from '@fioneer/ui5-webcomponents-react'
import uniqueId from 'lodash.uniqueid'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/charts/kpi-chart/ClickableKpiChart.module.css'
import KpiChart from 'components/ui/charts/kpi-chart/KpiChart'
import AugmentedKpiChart from 'components/ui/charts/kpi-chart/augmented-kpi-chart/AugmentedKpiChart'

const DEFAULT_YEARS_TO_SUBTRACT = 3
const DEFAULT_MONTHS_TO_SUBTRACT = 3

const calculateDateInPast = (
  yearsToSubtract = DEFAULT_YEARS_TO_SUBTRACT,
  monthsToSubtract = DEFAULT_MONTHS_TO_SUBTRACT,
) => {
  const modifiedDate = new Date()
  modifiedDate.setFullYear(modifiedDate.getFullYear() - yearsToSubtract)
  modifiedDate.setMonth(modifiedDate.getMonth() - monthsToSubtract)
  return modifiedDate
}

// eslint-disable-next-line no-magic-numbers
const dateThreeYearsAgo = calculateDateInPast(3)

const ClickableKpiChart = ({
  kpiName,
  kpiData,
  kpiUnit,
  threshold,
  thresholdLabel,
  baselineDate,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.ui.charts.kpi-chart' })
  const [id] = useState(uniqueId(`clickable-kpi-chart-${kpiName}`))
  const [isAugmentedChartOpen, setIsAugmentedChartOpen] = useState(false)

  const handleAfterClose = useCallback(() => setIsAugmentedChartOpen(false), [])
  const handleDeclineButtonClicked = useCallback(() => {
    setIsAugmentedChartOpen(false)
  }, [])

  if (kpiData.length === 0) {
    return t('empty')
  }

  const mappedTableKpiData = kpiData
    .map(({ value, ...kpiProperties }) => {
      if (new Date(kpiProperties.validity_date) < dateThreeYearsAgo) return undefined
      return {
        ...kpiProperties,
        value: +value,
      }
    })
    .filter((mappedKpiData) => !!mappedKpiData)

  const mappedDialogKpiData = kpiData.map(({ value, ...kpiProperties }) => ({
    ...kpiProperties,
    value: +value,
  }))

  const dialog = createPortal(
    <Dialog
      id={`augmented-chart-dialog-${id}`}
      className={styles.dialog}
      open={isAugmentedChartOpen}
      onAfterClose={handleAfterClose}
      header={
        <Grid className={styles.headerGrid}>
          <h4 data-layout-span="XL11 L11 M11 S11">
            {kpiUnit ? kpiName + ' | ' + kpiUnit : kpiName}
          </h4>
          <FlexBox
            alignItems={FlexBoxAlignItems.Center}
            justifyContent={FlexBoxJustifyContent.Center}
            className={styles.headerBox}
            data-layout-span="XL1 L1 M1 S1"
          >
            <Button icon="decline" onClick={handleDeclineButtonClicked} />
          </FlexBox>
        </Grid>
      }
      draggable
      resizable
    >
      {isAugmentedChartOpen && (
        <AugmentedKpiChart
          kpiName={kpiName}
          kpiData={mappedDialogKpiData}
          kpiUnit={kpiUnit}
          threshold={threshold}
          thresholdLabel={thresholdLabel}
          baselineDate={baselineDate}
          emptyTitle={t('empty-filter')}
          emptySubtitle={t('empty-filter.subtitle')}
        />
      )}
    </Dialog>,
    document.body,
  )

  return (
    <>
      <div
        id={`div-${id}`}
        className={styles.kpiChartWrapper}
        onClick={() => {
          setIsAugmentedChartOpen(true)
        }}
      >
        <KpiChart
          kpiName={kpiName}
          kpiData={mappedTableKpiData}
          kpiUnit={kpiUnit}
          augmented={false}
          threshold={threshold}
          thresholdLabel={thresholdLabel}
          baselineDate={baselineDate}
        />
      </div>
      {dialog}
    </>
  )
}

ClickableKpiChart.propTypes = {
  kpiName: PropTypes.string.isRequired,
  kpiData: PropTypes.arrayOf(
    PropTypes.shape({
      validity_date: PropTypes.string.isRequired,
      receipt_date: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  kpiUnit: PropTypes.string.isRequired,
  threshold: PropTypes.number,
  thresholdLabel: PropTypes.string,
  baselineDate: PropTypes.string,
}

export default ClickableKpiChart
