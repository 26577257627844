import { createSlice } from '@reduxjs/toolkit'
import startValuationResultsAddModeImport from 'redux/slices/properties/valuation-results/startValuationResultsAddMode'
import stopValuationResultsAddModeImport from 'redux/slices/properties/valuation-results/stopValuationResultsAddMode'
import valuationKeyDateChangedImport from 'redux/slices/properties/valuation-results/valuationKeyDateChanged'
import valuationMethodChangedImport from 'redux/slices/properties/valuation-results/valuationMethodChanged'
import valuationValidFromChangedImport from 'redux/slices/properties/valuation-results/valuationValidFromChanged'
import valuationValidToChangedImport from 'redux/slices/properties/valuation-results/valuationValidToChanged'
import valuationValueAmountChangedImport from 'redux/slices/properties/valuation-results/valuationValueAmountChanged'

const initialState = {
  highlightedRows: [],
  editedRow: {},
  isAddMode: false,
}

export const valuationResultsTableSlice = createSlice({
  name: 'properties/valuation-results-table',
  initialState,
  reducers: {
    startValuationResultsAddMode: startValuationResultsAddModeImport,
    stopValuationResultsAddMode: stopValuationResultsAddModeImport,
    valuationMethodChanged: valuationMethodChangedImport,
    valuationValidFromChanged: valuationValidFromChangedImport,
    valuationValidToChanged: valuationValidToChangedImport,
    valuationKeyDateChanged: valuationKeyDateChangedImport,
    valuationValueAmountChanged: valuationValueAmountChangedImport,
    setHighlightedValuations: (state, { payload: highlightedValuations }) => {
      state.highlightedRows = highlightedValuations
    },
    addHighlightedValuation: (state, { payload: highlightedValuation }) => {
      state.highlightedRows = [highlightedValuation, ...state.highlightedRows]
    },
    reset: () => initialState,
  },
})

export const {
  startValuationResultsAddMode,
  stopValuationResultsAddMode,
  valuationMethodChanged,
  valuationValidFromChanged,
  valuationValidToChanged,
  valuationKeyDateChanged,
  valuationValueAmountChanged,
  setHighlightedValuations,
  addHighlightedValuation,
  reset: resetValuationTableState,
} = valuationResultsTableSlice.actions

export default valuationResultsTableSlice.reducer
