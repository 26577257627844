import isEmpty from 'lodash.isempty'

const conditionCategoryChanged = (state, { payload: { category: newCategory } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }
  state.editedRow.currentValues.category = newCategory
  if (newCategory === state.editedRow.initialValues.category) {
    delete state.editedRow.changedFields.category
    return
  }
  state.editedRow.changedFields.category = true
}

export default conditionCategoryChanged
