import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import useStaffMemberByIds from 'hooks/services/business-partners/staff-members/useStaffMemberByIds'

const useAssigneeNameResolver = ({ selectedConditions }) => {
  const assigneeIds = useMemo(
    () =>
      selectedConditions?.map((condition) => condition.info.assignee).filter((id) => id !== null),
    [selectedConditions],
  )

  const isAssigneeHookEnabled = !isEmpty(assigneeIds)
  const uniqueAssigneeIds = useMemo(() => [...new Set(assigneeIds)], [assigneeIds])

  const {
    data: assigneesData,
    isFetching: isAssigneesFetching,
    isError: isAssigneesError,
  } = useStaffMemberByIds(uniqueAssigneeIds)

  const updatedSelectedConditions = useMemo(() => {
    if (assigneesData) {
      return selectedConditions?.map((condition) => {
        const conditionAssignee = assigneesData.find(
          (assignee) => assignee.id === condition.info.assignee,
        )
        return {
          ...condition,
          info: {
            ...condition.info,
            assignee: conditionAssignee ? conditionAssignee.fullName : null,
          },
        }
      })
    }
    return selectedConditions
  }, [assigneesData, selectedConditions])

  return {
    mappedSelectedConditions: updatedSelectedConditions,
    isFetching: isAssigneesFetching && isAssigneeHookEnabled,
    isError: isAssigneesError,
  }
}

export default useAssigneeNameResolver
