import {
  AnalyticalTableScaleWidthMode,
  Button,
  ButtonDesign,
  FileUploader,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
} from '@fioneer/ui5-webcomponents-react'
import { extname } from 'path-browserify'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import documentVersionPermissions from 'api/documents/DocumentVersionPermissions'
import Card from 'components/ui/card/Card'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import DocumentFileDrop from 'components/ui/document/DocumentFileDrop'
import useCanFileDrop from 'components/ui/document/useCanFileDrop'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import { useDocumentVersionUpload } from 'hooks/services/documents/useDocumentVersionUpload'
import useDocumentsAllowedOperations from 'hooks/services/documents/useDocumentsAllowedOperations'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'
import AddDocumentVersionDialog from 'routes/documents/document-versions/AddDocumentVersionDialog'
import styles from 'routes/documents/document-versions/DocumentVersionsTable.module.css'
import { useDocumentVersionsColumns } from 'routes/documents/document-versions/useDocumentVersionsColumns'

/**
 * @param {string} fileName
 */
const fileNameWithoutExtension = (fileName) => {
  const extension = extname(fileName)
  if (!extension) return fileName
  return fileName.slice(0, -extension.length)
}

/**
 * @param {string} documentFileName
 * @param {string} versionFileExtension
 */
export const getVersionFileName = (documentFileName, versionFileExtension) =>
  `${fileNameWithoutExtension(documentFileName)}.${versionFileExtension}`

const propTypes = {
  isFetching: PropTypes.bool,
  document: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    keyDate: PropTypes.string,
    fileName: PropTypes.string.isRequired,
    versions: PropTypes.arrayOf(
      PropTypes.shape({
        comment: PropTypes.string,
        createDateIso: PropTypes.string,
        guid: PropTypes.string,
        isWorkVersion: PropTypes.bool,
        ownerName: PropTypes.string,
        version: PropTypes.string,
        versionExt: PropTypes.string,
        versionSize: PropTypes.number,
      }),
    ),
  }),
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const DocumentVersionsTable = ({
  isFetching,
  document: { id: documentId, versions, keyDate, name: documentName, type: documentType, fileName },
}) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.documents.detail.versions-table',
  })
  const [isOpenAddNewVersionDialog, setIsOpenAddNewVersionDialog] = useState(false)
  const [file, setFile] = useState()

  const { data: { allowedOperations: allowedDocumentOperations = [] } = {} } =
    useDocumentsAllowedOperations()
  const hasDocumentVersionCreatePermission = useMemo(
    () => allowedDocumentOperations?.includes(documentVersionPermissions.create),
    [allowedDocumentOperations],
  )
  const hasDocumentVersionDeletePermission = useMemo(
    () => allowedDocumentOperations?.includes(documentVersionPermissions.delete),
    [allowedDocumentOperations],
  )

  const columns = useDocumentVersionsColumns({
    canDelete: hasDocumentVersionDeletePermission,
    documentName,
  })

  const data = useMemo(
    () =>
      versions.map(
        ({
          comment,
          createDateIso,
          guid,
          isWorkVersion,
          ownerName,
          version,
          versionExt,
          versionSize,
        }) => ({
          active: isWorkVersion,
          version,
          versionDate: createDateIso,
          format: versionExt,
          size: versionSize,
          editor: ownerName,
          comment,
          guid,
          documentId: documentId,
          fileName: getVersionFileName(fileName, versionExt),
          documentName,
        }),
      ),
    [versions, documentId, fileName, documentName],
  )

  const handleAddClicked = useCallback(() => {
    setIsOpenAddNewVersionDialog(true)
  }, [])

  const handleFileUploadChange = useCallback(({ detail }) => {
    const newFile = detail?.files?.[0]
    setFile(newFile)
    if (newFile) setIsOpenAddNewVersionDialog(true)
  }, [])

  const handleDrop = useCallback(
    (newFiles) => {
      if (newFiles?.length > 1) {
        Modals.showMessageBox({
          actions: [MessageBoxActions.OK],
          type: MessageBoxTypes.Error,
          children: <span>{t('multi-document-error')}</span>,
        })
        return
      }
      const newFile = newFiles?.[0]
      setFile(newFile)
      if (newFile) setIsOpenAddNewVersionDialog(true)
    },
    [t],
  )

  const handleUploadSuccess = () => {
    Modals.showToast({ children: <span>{t('upload-success')}</span> })
    setFile(undefined)
  }

  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false)
  const [deletionError, setDeletionError] = useState(undefined)
  const handleUploadError = async (error) => {
    setDeletionError(await formatHookError(error))
    setIsErrorMessageOpen(true)
    setFile(undefined)
  }

  const { mutate: uploadDocumentVersion, isLoading } = useDocumentVersionUpload({
    onSuccess: handleUploadSuccess,
    onError: handleUploadError,
    documentId: documentId,
  })

  const maxVersion = useMemo(() => {
    const numbersOnly = versions.filter(({ version }) => !isNaN(version) && version !== null)
    const mappedToNumber = numbersOnly.map(({ version }) => +version)
    return Math.max(...mappedToNumber)
  }, [versions])

  const handleUploadNewVersion = ({ file: fileToUpload, comment }) => {
    setIsOpenAddNewVersionDialog(false)
    uploadDocumentVersion({
      file: fileToUpload,
      comment,
      version: maxVersion + 1,
    })
  }

  const toastRef = useRef(null)

  const additionalActions = useMemo(() => {
    const actions = []
    if (hasDocumentVersionCreatePermission) {
      actions.push(
        <FileUploader key="file-uploader" onChange={handleFileUploadChange} hideInput>
          <Button design={ButtonDesign.Transparent} onClick={handleAddClicked} disabled={isLoading}>
            {t('add-version')}
          </Button>
        </FileUploader>,
      )
    }
    return actions
  }, [handleAddClicked, t, hasDocumentVersionCreatePermission, handleFileUploadChange, isLoading])

  const { isOver, canDrop, dropRef } = useCanFileDrop()

  return (
    <Card className={styles.card}>
      <div ref={dropRef} className={styles.tableWrapper}>
        <LoadingStateWrapper isLoading={isFetching} isError={false}>
          <>
            <AnalyticalTableWithToolbar
              title={t('title')}
              columns={columns}
              data={data}
              additionalActions={additionalActions}
              minRows={1}
              scaleWidthMode={AnalyticalTableScaleWidthMode.Grow}
              no-data-text={tNoPrefix('components.table.no-data')}
              sortable={false}
            />
            {hasDocumentVersionCreatePermission && (
              <DocumentFileDrop
                onDrop={handleDrop}
                isOver={isOver}
                canDrop={canDrop}
                isVersionDrop={true}
                ref={toastRef}
              />
            )}
            {isOpenAddNewVersionDialog && (
              <AddDocumentVersionDialog
                open={isOpenAddNewVersionDialog}
                onAfterClose={() => setIsOpenAddNewVersionDialog(false)}
                onUploadNewVersion={handleUploadNewVersion}
                initialState={{
                  documentName,
                  documentType,
                  keyDate,
                  file,
                }}
              />
            )}
            {createPortal(
              <>
                {isErrorMessageOpen && (
                  <ErrorMessageBoxWithExpandableDetails
                    messageSummary={t('upload-error')}
                    messageDetails={deletionError}
                    isOpen={isErrorMessageOpen}
                    onClose={() => {
                      setIsErrorMessageOpen(false)
                    }}
                  />
                )}
              </>,
              document.body,
            )}
          </>
        </LoadingStateWrapper>
      </div>
    </Card>
  )
}

DocumentVersionsTable.propTypes = propTypes

export default DocumentVersionsTable
