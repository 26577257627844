import isEmpty from 'lodash.isempty'
import resolveLifecycleStatusCodeToValueState from 'components/domains/business-partners/tile/risk-monitoring/forbearance/helper/resolveLifecycleStatusCodeToValueState'
import { TABLE_ROW_HEIGHT } from 'components/domains/business-partners/tile/risk-monitoring/utils/constants'
import paths from 'routes/paths'

const mapForbearanceStatus = ({
  group,
  forbearanceStatusVersions,
  forbearanceStatusId,
  staffMembers,
}) => {
  if (isEmpty(forbearanceStatusVersions)) {
    return []
  }

  return forbearanceStatusVersions?.map((item) => {
    const {
      version,
      lifecycleStatusTypeCode,
      lifecycleStatusShortText,
      prioritizedForbearanceMeasureShortText,
      prioritizedForbearanceMeasureTypeCode,
      forbearanceStatusTypeCode,
      createdOn,
      currentBusinessEvent,
      customFields,
      startDate,
      id,
    } = item ?? {}

    return {
      name: `${group.forbearanceStatusShortText} ${version}`,
      status: {
        statusValue: lifecycleStatusShortText,
        statusState: resolveLifecycleStatusCodeToValueState(lifecycleStatusTypeCode),
      },
      isHeader: false,
      prioritizedForbearanceMeasureCode: prioritizedForbearanceMeasureTypeCode,
      prioritizedForbearanceMeasure: prioritizedForbearanceMeasureShortText,
      gracePeriodStartDate: customFields?.gracePeriodStartDate?.value,
      gracePeriodEndDate: customFields?.gracePeriodEndDate?.value,
      probationPeriodStartDate: customFields?.probationPeriodStartDate?.value,
      probationPeriodEndDate: customFields?.probationPeriodEndDate?.value,
      prioritizedForbearanceMeasureSigningDate:
        customFields?.prioritizedForbearanceMeasureSigningDate?.value,
      createdOn: createdOn?.createdOn,
      createdBy: staffMembers?.[createdOn?.createdBy]?.fullName,
      ...(currentBusinessEvent && {
        currentBusinessEvent: {
          id: currentBusinessEvent?.displayId,
          name: currentBusinessEvent?.name,
          link: `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${currentBusinessEvent?.id}`,
        },
      }),
      rowHeight: TABLE_ROW_HEIGHT,
      startDate,
      forbearanceStatusId,
      forbearanceStatusVersionId: id,
      forbearanceStatusTypeCode,
    }
  })
}

export default mapForbearanceStatus
