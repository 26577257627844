import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useLinkRiskMonitoringEvent = ({ onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()

  return useMutation(
    /**
     * @param {object} params
     * @param {string} [params.businessPartnerId] ID of the business partner.
     * @param {string} [params.riskIndicatorGroupId] ID of the risk indicator group.
     * @param {string} [params.eventId] ID of the event that is to be linked to the risk indicator group.
     */
    async ({ businessPartnerId, riskIndicatorGroupId, eventId }) => {
      if (!businessPartnerId || !riskIndicatorGroupId || !eventId) {
        return
      }
      await put({
        path: `/risk-monitoring/business-partners/${businessPartnerId}/risk-indicator-groups/${riskIndicatorGroupId}/event`,
        body: { event_id: eventId },
      })
    },
    { onSuccess, onError },
  )
}

export default useLinkRiskMonitoringEvent
