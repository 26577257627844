import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  ObjectStatus,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/risk-view/DecisionPaperRiskViewRow.module.css'
import DecisionPaperRiskViewSelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/risk-view/DecisionPaperRiskViewSelect'
import {
  riskCategories,
  riskTypes,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/risk-view/shared/riskViewApi'

const riskCategoryTextKeys = {
  [riskCategories.cashflow]: 'risk-category.cashflow',
  [riskCategories.financingStructure]: 'risk-category.financing-structure',
  [riskCategories.involvedParties]: 'risk-category.involved-parties',
  [riskCategories.property]: 'risk-category.property',
  [riskCategories.tenancy]: 'risk-category.tenancy',
  [riskCategories.total]: 'risk-category.total',
}

const DecisionPaperRiskViewRow = ({
  value,
  riskCategory,
  onChange,
  onBlur,
  isEditMode,
  isPdfView,
  valueState,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.risk-view',
  })

  const renderEditMode = useCallback(
    () => (
      <DecisionPaperRiskViewSelect
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        riskCategory={riskCategory}
        valueState={valueState}
      />
    ),
    [value, onChange, onBlur, riskCategory, valueState],
  )

  const renderDisplayMode = useCallback(() => {
    switch (value) {
      case riskTypes.veryLow:
        return (
          <ObjectStatus inverted state={ValueState.Success}>
            {t('risk-type.very-low')}
          </ObjectStatus>
        )
      case riskTypes.low:
        return (
          <ObjectStatus inverted state={ValueState.Success}>
            {t('risk-type.low')}
          </ObjectStatus>
        )
      case riskTypes.medium:
        return (
          <ObjectStatus inverted state={ValueState.Warning}>
            {t('risk-type.medium')}
          </ObjectStatus>
        )
      case riskTypes.mediumHigh:
        return (
          <ObjectStatus inverted state={ValueState.Warning}>
            {t('risk-type.medium-high')}
          </ObjectStatus>
        )
      case riskTypes.high:
        return (
          <ObjectStatus inverted state={ValueState.Error}>
            {t('risk-type.high')}
          </ObjectStatus>
        )
      case riskTypes.veryHigh:
        return (
          <ObjectStatus inverted state={ValueState.Error}>
            {t('risk-type.very-high')}
          </ObjectStatus>
        )
      default:
        return <Text>{'-'}</Text>
    }
  }, [value, t])

  const riskViewValueComponent = useMemo(
    () => (!isPdfView && isEditMode ? renderEditMode() : renderDisplayMode()),
    [isPdfView, isEditMode, renderEditMode, renderDisplayMode],
  )

  const riskCategoryLabel = useMemo(
    () =>
      riskCategory === riskCategories.total ? (
        <Text>
          <strong>{t(riskCategoryTextKeys[riskCategory])}</strong>
        </Text>
      ) : (
        <Text>{t(riskCategoryTextKeys[riskCategory])}</Text>
      ),
    [riskCategory, t],
  )

  return (
    <FlexBox
      direction={FlexBoxDirection.Row}
      alignItems={FlexBoxAlignItems.Stretch}
      className={styles.flexContainer}
    >
      <FlexBox alignItems={FlexBoxAlignItems.Center} className={styles.flexCell}>
        {riskCategoryLabel}
      </FlexBox>
      <FlexBox alignItems={FlexBoxAlignItems.Center} className={styles.flexCell}>
        {riskViewValueComponent}
      </FlexBox>
    </FlexBox>
  )
}

DecisionPaperRiskViewRow.propTypes = {
  riskCategory: PropTypes.oneOf(Object.values(riskCategories)).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.oneOf(Object.values(riskTypes)),
  isEditMode: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  valueState: PropTypes.oneOf([ValueState.Error, ValueState.None]),
}

export default DecisionPaperRiskViewRow
