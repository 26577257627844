import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const usePropertyMonitoringCreation = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ propertyUuid, monitoringStatus, baselineDate, note }) => {
      const { data } = await post({
        path: '/property-monitoring',
        body: {
          property_id: propertyUuid,
          monitoring_status: monitoringStatus,
          note,
          baseline_date: baselineDate,
        },
      })
      return data
    },
    { onSuccess, onError },
  )
}
