import PropTypes from 'prop-types'
import { useMemo } from 'react'
import ConditionsTableSingleReferenceView from 'components/domains/conditions/shared/ConditionsTableSingleReferenceView'
import ReferenceEntities from 'components/ui/data/ReferenceEntities'
import { ReferenceEntityType } from 'components/ui/data/ReferenceEntityType'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'

export const EntityTypeMap = {
  [cwpEntityTypes.DEAL]: ReferenceEntityType.Deal,
  [cwpEntityTypes.BUSINESS_PARTNER]: ReferenceEntityType.BusinessPartner,
  [cwpEntityTypes.PROPERTY]: ReferenceEntityType.Property,
}

const ConditionsReferencesView = ({ references, onError }) => {
  const customComponentProps = useMemo(
    () => ({ entityType: references?.entityType }),
    [references?.entityType],
  )

  return (
    <ReferenceEntities
      type={EntityTypeMap[references?.entityType]}
      ids={references?.entityIds}
      onError={onError}
      CustomComponent={
        !references || references.entityIds.length <= 1
          ? ConditionsTableSingleReferenceView
          : undefined
      }
      customComponentProps={customComponentProps}
    />
  )
}

ConditionsReferencesView.propTypes = {
  references: PropTypes.shape({
    entityType: PropTypes.string.isRequired,
    entityIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  onError: PropTypes.func,
}

export default ConditionsReferencesView
