import useDeal from 'hooks/services/deals/useDeal'
import { useDealHeaders } from 'hooks/services/deals/useDealHeaders'

//Prefix WV_LIVE indicates tiles with data from LIVE version of the deal, which are present in Working Version decision paper templates
export const WORKING_VERSION_TILE_WITH_LIVE_VARIANT_PREFIX = 'WV_LIVE_'

/**
 * Helper hook which is used to determine correct dealUuid based on the decision paper tile code
 *
 * Whenever tile code starts with WV_LIVE it indicates that tile resides in Working Version event template, but should display
 * data from the LIVE version of the given deal
 *
 * In order to retrieve live deal data from the working version of the deal, this hook fetches and returns live dealUuid,
 * otherwise it just returns given dealUuid regardless of its working version variant
 *
 * @param {String} dealUuid
 * @param {String} tileCode
 * @param {Object} options
 * @param {Boolean} isWorkingVersionInfoInSourcePath
 * @returns {Object} { data: { dealUuid, isWorkingVersionDataTile }, isFetching, isError, errors }
 */
export const useDealUuidByTileCode = ({
  dealUuid,
  tileCode,
  options = { enabled: !!dealUuid },
  isWorkingVersionInfoInSourcePath = false,
}) => {
  const isWorkingVersionLiveTile = tileCode?.startsWith(
    WORKING_VERSION_TILE_WITH_LIVE_VARIANT_PREFIX,
  )

  const isHookEnabled =
    (isWorkingVersionInfoInSourcePath || !!isWorkingVersionLiveTile) && !!options?.enabled

  const {
    data: { dealId } = {},
    isFetching: isFetchingDeal,
    isError: isErrorDeal,
    error: dealError,
  } = useDeal(dealUuid, { enabled: isHookEnabled })

  const {
    data: { live: { dealUuid: dealUuidLive } = {} } = {},
    isFetching: isFetchingHeaders,
    isError: isErrorHeaders,
    error: headersError,
  } = useDealHeaders(dealId)

  if (!isWorkingVersionInfoInSourcePath && !isWorkingVersionLiveTile) {
    return { data: { dealUuid }, isFetching: false, isError: false, errors: [] }
  }

  const dealUuidByTileCode = isWorkingVersionLiveTile ? dealUuidLive : dealUuid

  const isLiveVersionDataTile = dealUuidByTileCode === dealUuidLive

  const isFetching = isFetchingDeal || isFetchingHeaders
  const isError = isErrorDeal || isErrorHeaders

  return isFetching || isError
    ? {
        data: undefined,
        isFetching,
        isError,
        errors: [dealError, headersError].filter(Boolean),
      }
    : {
        data: {
          dealUuid: dealUuidByTileCode,
          isWorkingVersionDataTile: !isLiveVersionDataTile,
        },
        isFetching: false,
        isError: false,
        errors: [],
      }
}
