const useModifyLimits = ({ formState, updateFormState, isLimitAbsolute }) => {
  const limits = (isLimitAbsolute ? formState?.absoluteLimits : formState?.relativeLimits) ?? []

  const findIndexByRowKey = (rowKey) =>
    limits.findIndex((limit) => limit[isLimitAbsolute ? 'clvaId' : 'clvrId'] === rowKey)

  const editResultOfBreach = (value) => {
    const newFormState = {
      ...formState,
      configCovenantBreachResultUuid: value,
    }
    updateFormState(newFormState)
  }

  const buildLimit = (limitFields, buildAbsLimit) => ({
    [buildAbsLimit ? 'clvaId' : 'clvrId']: crypto.randomUUID(),
    period: undefined,
    unit: undefined,
    currencyCode: undefined,
    validFrom: '',
    validTo: '',
    isCovenantHoliday: false,
    ...limitFields,
  })

  const addLimit = (newLimitFields) => {
    const newLimit = buildLimit(newLimitFields, isLimitAbsolute)
    const localLimits = [...limits, newLimit]
    const newFormState = {
      ...formState,
      [isLimitAbsolute ? 'absoluteLimits' : 'relativeLimits']: localLimits,
    }
    updateFormState(newFormState)
  }

  const editLimits = (rowKey, keyValue) => {
    const index = findIndexByRowKey(rowKey)
    if (index === -1) return
    const updatedRow = { ...limits[index], ...keyValue }
    const localLimits = [...limits]
    localLimits.splice(index, 1, updatedRow)
    const newFormState = {
      ...formState,
      [isLimitAbsolute ? 'absoluteLimits' : 'relativeLimits']: localLimits,
    }
    updateFormState(newFormState)
  }

  const updateAllPeriods = (period) => {
    const newLimits = limits?.map((oldLimit) => ({
      ...oldLimit,
      period,
    }))
    const newFormState = {
      ...formState,
      [isLimitAbsolute ? 'absoluteLimits' : 'relativeLimits']: newLimits,
    }
    updateFormState(newFormState)
  }

  const deleteLimits = (selectedRowKeys) => {
    const limitsWithoutSelection = limits.filter(
      (limit) => !selectedRowKeys.includes(limit[isLimitAbsolute ? 'clvaId' : 'clvrId']),
    )
    const newFormState = {
      ...formState,
      [isLimitAbsolute ? 'absoluteLimits' : 'relativeLimits']: limitsWithoutSelection,
    }
    updateFormState(newFormState)
  }

  const cleanLimits = () => {
    for (const limit of limits) {
      delete limit?.isValidNumberInput
    }
    const newFormState = {
      ...formState,
      [isLimitAbsolute ? 'absoluteLimits' : 'relativeLimits']: limits,
    }
    updateFormState(newFormState)
  }

  return {
    addLimit,
    buildLimit,
    editLimits,
    updateAllPeriods,
    deleteLimits,
    cleanLimits,
    editResultOfBreach,
  }
}

export default useModifyLimits
