import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropertyValuationResultsDateInputCell from 'components/domains/properties/valuation/valuation-results/cells/PropertyValuationResultsDateInputCell'
import { valuationValidToChanged } from 'redux/slices/properties/valuationResultsTableSlice'

const PropertyValuationResultsValidToCell = ({ validTo, isAddMode }) => {
  const dispatch = useDispatch()
  const handleChange = useCallback(
    (newDate) => {
      if (!isAddMode) {
        return
      }
      dispatch(valuationValidToChanged({ validTo: newDate }))
    },
    [dispatch, isAddMode],
  )

  return (
    <PropertyValuationResultsDateInputCell
      date={validTo}
      onChange={handleChange}
      isAddMode={isAddMode}
    />
  )
}

PropertyValuationResultsValidToCell.propTypes = {
  validTo: PropTypes.string,
  isAddMode: PropTypes.bool.isRequired,
}

export default PropertyValuationResultsValidToCell
