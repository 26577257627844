// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xHTfQaXsfhXF242zAWZo, .VRA5f3v0U_GhL3gNsRSl {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
}

.UJFZxHPg6FrQq54WULUS {
  color: var(--sapCriticalColor)
}`, "",{"version":3,"sources":["webpack://./src/components/ui/card/DisplayAndEditCard.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE;AACF","sourcesContent":[".displayCardItem, .editCardItem {\n  padding-left: 16px;\n  padding-right: 16px;\n  padding-bottom: 8px;\n}\n\n.warningText {\n  color: var(--sapCriticalColor)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"displayCardItem": `xHTfQaXsfhXF242zAWZo`,
	"editCardItem": `VRA5f3v0U_GhL3gNsRSl`,
	"warningText": `UJFZxHPg6FrQq54WULUS`
};
export default ___CSS_LOADER_EXPORT___;
