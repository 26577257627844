import { Bar, Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionTypes, conditionsStatusTypes } from 'api/conditions/conditions'
import ConditionSelectionFilterBar from 'components/domains/conditions/condition-selection/ConditionSelectionFilterBar'
import ConditionSelectionTable from 'components/domains/conditions/condition-selection/ConditionSelectionTable'
import Dialog, { DialogSize } from 'components/ui/dialog/Dialog'
import useConditions from 'hooks/services/conditions/useConditions'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const ConditionSelectionHelperDialog = ({ isOpen, onCancel, onSelect }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.condition-selection',
  })
  const {
    entityRef: { entityId, entityType },
  } = useContext(ConditionsContext)

  const [currentSearchFilter, setCurrentSearchFilter] = useState('')

  useEffect(() => {
    if (!isOpen) {
      setCurrentSearchFilter('')
    }
  }, [isOpen])

  const onFilterChanged = useCallback((searchFilter) => {
    setCurrentSearchFilter(searchFilter)
  }, [])

  const {
    data: conditions,
    isLoading,
    isError,
  } = useConditions(
    conditionTypes.external,
    { entityId, entityType },
    {
      searchFilter: currentSearchFilter,
      statusTypes: [conditionsStatusTypes.open, conditionsStatusTypes.inProgress],
    },
  )

  const footer = useMemo(
    () => (
      <Bar
        endContent={
          <Button design={ButtonDesign.Transparent} onClick={onCancel}>
            {tNoPrefix('buttons.cancel')}
          </Button>
        }
      />
    ),
    [tNoPrefix, onCancel],
  )

  return (
    <Dialog
      footer={footer}
      open={isOpen}
      onAfterClose={onCancel}
      size={DialogSize.XL}
      headerText={t('title')}
    >
      {isOpen && (
        <>
          <ConditionSelectionFilterBar searchFilter={currentSearchFilter} onGo={onFilterChanged} />
          <ConditionSelectionTable
            conditions={conditions?.externalConditions}
            isLoading={isLoading}
            isError={isError}
            onSelectTableRow={onSelect}
          />
        </>
      )}
    </Dialog>
  )
}

ConditionSelectionHelperDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default ConditionSelectionHelperDialog
