import isEmpty from 'lodash.isempty'
import isEqual from 'lodash.isequal'

const conditionVisibilitiesChanged = (state, { payload: { visibilities: newVisibilities } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }
  state.editedRow.currentValues.visibilities = newVisibilities
  const visibilitiesCodes = state.editedRow.initialValues.visibilities

  if (isEqual(newVisibilities.sort(), visibilitiesCodes.sort())) {
    delete state.editedRow.changedFields.visibilities
    return
  }
  state.editedRow.changedFields.visibilities = true
}

export default conditionVisibilitiesChanged
