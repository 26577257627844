import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DecisionStageName from 'components/domains/business-events-and-tasks/common/DecisionStageName'
import styles from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageName.module.css'
import calculateDecisionStageToDisplay from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageToDisplayCalculator'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useDecisionStages from 'hooks/services/business-events-and-tasks/decision-process/stages/useDecisionStages'

const DecisionStagesActiveName = ({ eventId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.event-overview.table.cells.current-decision-stage',
  })

  const emptyContent = useMemo(() => <Label>{'-'}</Label>, [])

  const { isLoading, isError, data: decisionStages } = useDecisionStages({ eventId })

  const displayDecisionStageName = useCallback(
    (decisionStage) => {
      if (!decisionStage) {
        return emptyContent
      }

      return (
        <Label>
          <DecisionStageName
            name={decisionStage.info.name}
            decisionStageType={decisionStage.info.decisionType}
          />
        </Label>
      )
    },
    [emptyContent],
  )

  const renderContent = useCallback(() => {
    if (decisionStages.decisionStages.length === 0) {
      return emptyContent
    }

    return displayDecisionStageName(calculateDecisionStageToDisplay(decisionStages.decisionStages))
  }, [decisionStages, displayDecisionStageName, emptyContent])

  const errorMessage = <Label className={styles['error-label']}>{t('error.description')}</Label>

  return (
    <SmallLoadingWrapper
      isError={isError}
      isLoading={isLoading}
      error={errorMessage}
      renderContent={renderContent}
    />
  )
}

DecisionStagesActiveName.propTypes = {
  eventId: PropTypes.string.isRequired,
}

export default DecisionStagesActiveName
