import { useMemo } from 'react'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useFinancing from 'hooks/services/deals/financing/useFinancing'
import useDealMini from 'hooks/services/deals/useDealMini'

const useOpsMemoTranchesTable = ({ entityRef: { entityId: dealUuid } }, _, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    data: dealData,
    isFetching: isFetchingDealData,
    isError: isErrorDealData,
  } = useDealMini(dealUuidByTileCode)

  const dealDisplayId = useMemo(() => dealData?.dealId, [dealData?.dealId])

  const {
    data: financingData,
    isFetching: isFetchingFinancing,
    isError: isErrorFinancing,
  } = useFinancing(dealUuidByTileCode, { enabled: !!dealUuidByTileCode })

  const sourcePathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?dataSource=newBusiness&working-version=true' : ''),
    [isWorkingVersionDataTile],
  )

  return useMemo(() => {
    const isLoading = isDealUuidFetching || isFetchingDealData || isFetchingFinancing
    const isError = isDealUuidError || isErrorDealData || isErrorFinancing

    if (isLoading || isError) {
      return { isLoading, isError, data: undefined }
    }

    const data = financingData
      ? {
          financingData: financingData ?? {},
          sourceRender: { dealDisplayId, sourcePathSuffix },
        }
      : undefined

    return {
      isLoading: false,
      isError: false,
      data,
    }
  }, [
    dealDisplayId,
    financingData,
    isDealUuidError,
    isDealUuidFetching,
    isErrorDealData,
    isErrorFinancing,
    isFetchingDealData,
    isFetchingFinancing,
    sourcePathSuffix,
  ])
}

export default useOpsMemoTranchesTable
