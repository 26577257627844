import { Bar, BarDesign, Button, ButtonDesign, Dialog } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { propertyMonitoringPermissions } from 'api/property/monitoring/propertyMonitoringConfiguration'
import styles from 'components/domains/properties/monitoring/settings/dialog/PropertyMonitoringSettingsDialogView.module.css'

/**
 * use ui-components dialog | https://fioneer.atlassian.net/browse/CWP-13200
 */
const PropertyMonitoringSettingsDialogView = ({
  children,
  isDialogOpen,
  setIsDialogOpen,
  isSaveButtonShown,
  isSaveButtonEnabled,
  handleSettingsOnSave,
  isMonitoringActivated,
  monitoringPermissions,
}) => {
  const { t } = useTranslation()

  const hasActivateDeactivatePermission = monitoringPermissions.includes(
    propertyMonitoringPermissions.activateDeactivate,
  )

  const renderSaveAndCancelButtons = () => (
    <>
      {isSaveButtonShown && (
        <Button
          design={ButtonDesign.Emphasized}
          disabled={!isSaveButtonEnabled}
          onClick={() => handleSettingsOnSave()}
        >
          {t('buttons.save')}
        </Button>
      )}
      <Button onClick={() => setIsDialogOpen(false)}>{t('buttons.cancel')}</Button>
    </>
  )

  const renderCloseButtons = () => (
    <Button onClick={() => setIsDialogOpen(false)}>{t('buttons.close')}</Button>
  )

  const calculateEndContent = () => {
    if (!hasActivateDeactivatePermission) {
      if (isMonitoringActivated) {
        return renderSaveAndCancelButtons()
      }
      return renderCloseButtons()
    }
    return renderSaveAndCancelButtons()
  }

  const dialogFooter = <Bar design={BarDesign.Footer} endContent={calculateEndContent()} />

  return createPortal(
    <Dialog
      headerText={t('dialog.monitoring-settings.title')}
      footer={dialogFooter}
      onAfterClose={() => setIsDialogOpen(false)}
      open={isDialogOpen}
      className={styles.propertyMonitoringDialog}
    >
      {children}
    </Dialog>,
    document.body,
  )
}

PropertyMonitoringSettingsDialogView.defaultProps = {
  isSaveButtonShown: false,
  isSaveButtonEnabled: false,
}

PropertyMonitoringSettingsDialogView.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  setIsDialogOpen: PropTypes.func.isRequired,
  isSaveButtonShown: PropTypes.bool.isRequired,
  isSaveButtonEnabled: PropTypes.bool.isRequired,
  handleSettingsOnSave: PropTypes.func,
  isMonitoringActivated: PropTypes.bool.isRequired,
  monitoringPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default PropertyMonitoringSettingsDialogView
