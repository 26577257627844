import { FlexBox, FlexBoxDirection, Input, Modals } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/conflict-clearance/DealReportConflictDialog.module.css'
import DealAutocompleteInput from 'components/domains/deals/deal-search/DealAutocompleteInput'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import Labeled from 'components/ui/data/Labeled'
import Dialog, { DialogPrimaryButton, DialogSize } from 'components/ui/dialog/Dialog'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useCreateDealConflict from 'hooks/services/deals/conflict-clearance/useCreateDealConflict'
import useDealMini from 'hooks/services/deals/useDealMini'

const dealReportConflictDialogProps = {
  dealUuid: PropTypes.string,
}

const DealReportConflictDialog = forwardRef(({ dealUuid, ...props }, forwardedDialogRef) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.report-conflict.dialog',
  })
  const showToast = Modals.useShowToast()
  const showErrorMessageBox = useShowErrorMessageBox()
  const { isLoading, isError, data: { name, dealId } = {} } = useDealMini(dealUuid)
  const [conflictDealUuid, setConflictDealUuid] = useState()

  const showReportingSuccess = () => {
    showToast({ children: t('success-toast') })
  }

  const showReportingError = async (error) => {
    const { errors: [errorResponse] = [] } = await error.response.json()
    showErrorMessageBox({ message: t('error-message'), error: errorResponse })
  }

  const { mutateAsync } = useCreateDealConflict({
    onSuccess: showReportingSuccess,
    onError: showReportingError,
  })

  const selectConflictingDeal = (deal) => {
    setConflictDealUuid(deal?.dealUuid)
  }

  const reportConflictingDeal = async () => {
    await mutateAsync({ dealUuid, conflictDealUuid })
    forwardedDialogRef?.current.close()
  }

  return (
    <Dialog
      ref={forwardedDialogRef}
      headerText={t('title')}
      size={DialogSize.S}
      primaryButton={
        <DialogPrimaryButton
          onClick={reportConflictingDeal}
          disabled={!conflictDealUuid || isLoading || isError}
        >
          {t('report')}
        </DialogPrimaryButton>
      }
      {...props}
    >
      <LoadingStateWrapper isLoading={isLoading} isError={isError}>
        <FlexBox direction={FlexBoxDirection.Column} className={styles.dialogContent}>
          <Labeled label={t('deal')} vertical showColon required>
            <Input readonly value={`${name} - ${dealId}`} />
          </Labeled>
          <Labeled label={t('conflicting-deal')} required showColon vertical>
            <DealAutocompleteInput onDealSuggestionSelected={selectConflictingDeal} />
          </Labeled>
        </FlexBox>
      </LoadingStateWrapper>
    </Dialog>
  )
})

DealReportConflictDialog.displayName = 'DealReportConflictDialog'
DealReportConflictDialog.propTypes = dealReportConflictDialogProps

export default DealReportConflictDialog
