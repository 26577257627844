import { useMemo } from 'react'
import useOtherFinancingSource from 'hooks/services/deals/financing/useOtherFinancingSource'

const calculateValuesForOtherSources = (otherFinancingSources) =>
  otherFinancingSources?.items.map(({ amount, amountHeadquarter, creditor: name }) => {
    if (!amountHeadquarter && !amount) {
      return { name }
    }
    if (!!amountHeadquarter && !!amount) {
      return {
        name,
        original: {
          currency: amount.currency,
          value: amount.amount,
        },
        headquarter: {
          currency: amountHeadquarter.currency,
          value: amountHeadquarter.amount,
        },
      }
    }
    return {
      name,
      original: {
        currency: amount.currency,
        value: amount.amount,
      },
      headquarter: {
        currency: amount.currency,
        value: amount.amount,
      },
    }
  })

const useOtherFinancingSourcesCalculation = ({ dealUuid }) => {
  const {
    data: otherFinancingSources,
    isFetching: isOtherFinancingSourcesFetching,
    isError: isOtherFinancingSourcesError,
    error: otherFinancingSourcesError,
  } = useOtherFinancingSource(dealUuid, { enabled: !!dealUuid })

  const mappedOtherSources = useMemo(
    () => calculateValuesForOtherSources(otherFinancingSources),
    [otherFinancingSources],
  )

  return useMemo(
    () => ({
      isOtherFinancingSourcesFetching,
      isOtherFinancingSourcesError,
      otherFinancingSourcesError,
      mappedOtherSources,
    }),
    [
      isOtherFinancingSourcesFetching,
      isOtherFinancingSourcesError,
      otherFinancingSourcesError,
      mappedOtherSources,
    ],
  )
}

export default useOtherFinancingSourcesCalculation
