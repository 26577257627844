import PropTypes from 'prop-types'
import DocumentDownload from 'components/domains/documents/DocumentDownload'
import { getVersionFileName } from 'routes/documents/document-versions/DocumentVersionsTable'

const propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string,
        guid: PropTypes.string,
        fileName: PropTypes.string,
        name: PropTypes.string,
        versions: PropTypes.arrayOf(
          PropTypes.shape({
            guid: PropTypes.string,
            isWorkVersion: PropTypes.bool,
            versionExt: PropTypes.string,
            version: PropTypes.string,
          }),
        ),
        subFolders: PropTypes.array,
      }),
    }),
  }).isRequired,
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const DownloadDocumentCell = ({ cell }) => {
  const id = cell.row?.original?.id
  const isDocument = !Array.isArray(cell.row?.original?.subFolders)

  const activeVersion = cell.row?.original?.versions?.find((version) => version.isWorkVersion)
  const versionGuid = activeVersion?.guid
  const version = activeVersion?.version
  const activeFileName =
    activeVersion && getVersionFileName(cell.row?.original?.fileName, activeVersion.versionExt)
  const documentName = cell.row?.original?.name

  if (!id || !isDocument) return <div />
  return (
    <DocumentDownload
      type="icon"
      documentId={id}
      documentVersionGuid={versionGuid}
      fileName={activeFileName}
      documentName={documentName}
      version={version}
    />
  )
}

DownloadDocumentCell.propTypes = propTypes

export default DownloadDocumentCell
