import { array, bool, string } from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/DealCashFlowUsedParametersTable.module.css'
import { DealCashFlowAnalyticalTable } from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/analytical-table/DealCashFlowAnalyticalTable'
import { calculateDealCashFlowRowHeightDisplayMode } from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/parameters/calculateDealCashFlowRowHeight'
import { useDealCashFlowCashflowParametersColumns } from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/parameters/useDealCashFlowCashflowParametersColumns'

export const DealCashFlowUsedParametersTable = ({
  data = [],
  config = [],
  titleText,
  isPdfViewOnDecisionPaper = false,
}) => {
  const columns = useDealCashFlowCashflowParametersColumns(isPdfViewOnDecisionPaper)
  let nrOfEntries = 0

  const reduceParameters = (parameters, parameterConfig) =>
    parameters.reduce(
      (result, { cashflowParameterDefinition, cashflowParameterUnit, value }) => {
        const { decimalPlaces } =
          parameterConfig
            ?.find(({ ccpdId }) => ccpdId === cashflowParameterDefinition.ccpdId)
            ?.units?.find(({ ccpuId }) => ccpuId === cashflowParameterUnit.ccpuId) ?? {}
        result.parameter.push({ label: cashflowParameterDefinition.name })
        result.value.push({ value, unit: cashflowParameterUnit.name, decimalPlaces })
        return result
      },
      { parameter: [], value: [] },
    )

  const mapRow = ({ objectName, objectDefinition, parameters }) => {
    nrOfEntries++
    return {
      name: objectName,
      type: objectDefinition.name,
      rowHeight: calculateDealCashFlowRowHeightDisplayMode(parameters),
      ...reduceParameters(parameters, config),
    }
  }

  const tableData = data.map((item) => {
    const rowObject = mapRow(item)
    rowObject.subRows = item.children?.map(mapRow)

    return rowObject
  })

  return (
    <DealCashFlowAnalyticalTable
      className={styles['used-parameters-table']}
      title={titleText}
      columns={columns}
      data={tableData}
      nrOfEntries={nrOfEntries}
      visibleRows={nrOfEntries}
      customRowHeight
      isPdfViewOnDecisionPaper={isPdfViewOnDecisionPaper}
    />
  )
}

DealCashFlowUsedParametersTable.propTypes = {
  data: array.isRequired,
  config: array.isRequired,
  titleText: string.isRequired,
  isPdfViewOnDecisionPaper: bool,
}
