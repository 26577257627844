import { useQuery } from '@tanstack/react-query'
import camelize from 'camelize'
import snakeCase from 'lodash.snakecase'
import { useMemo } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const constantCaseObjectKeysToSnakeCase = (inputObject) => {
  if (typeof inputObject !== 'object' && inputObject !== null) return inputObject
  if (Array.isArray(inputObject)) return inputObject.map(constantCaseObjectKeysToSnakeCase)
  return Object.keys(inputObject).reduce((accumulator, currentKey) => {
    const currentObj = inputObject[currentKey]
    const newObj =
      typeof currentObj === 'object' && currentObj !== null
        ? constantCaseObjectKeysToSnakeCase(currentObj)
        : currentObj
    accumulator[currentKey.toLowerCase()] = newObj
    return accumulator
  }, {})
}

export const camelCaseArrayToConstantCase = (inputArray) => {
  if (inputArray) {
    const arr = inputArray.map((input) => snakeCase(input).toUpperCase())
    return arr
  }
  return []
}

/**
 * @typedef {'sum'} Aggregate
 * @typedef {'city' | 'country' | 'propertyType' | 'tegovaRating'} Granularity
 */

/**
 *
 * @param {Array<string>} propertyIds - UUIDs of properties to get valuations for
 * @param {Array<Aggregate>} [includeAggregates=[]] - Included aggregates
 * @param {string} [preferredCurrency] - Preferred currency
 * @param {object} [options] - Options for useRequest
 * @param {Array<Granularity>} [includeGranularities=[]] - Included aggregates
 * @returns {{isLoading: boolean, isFetching: boolean, isError: boolean, data: *}}
 */

const useMultiPropertyValuationsDecisionPaper = (
  propertyIds = [],
  includeAggregates = [],
  preferredCurrency,
  options = {},
  includeGranularities = [],
) => {
  const { post } = useAccessTokenRequest()
  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: [
      'properties',
      'multi-valuations',
      propertyIds.sort((a, b) => a.localeCompare(b)),
      includeAggregates,
      preferredCurrency,
      includeGranularities,
    ],
    refetchOnWindowFocus: false,
    queryFn: () =>
      post({
        path: `/properties/valuations`,
        body: {
          property_uuids: propertyIds.filter(Boolean),
          include_aggregates: camelCaseArrayToConstantCase(includeAggregates),
          preferred_currency: preferredCurrency,
          include_granularities: camelCaseArrayToConstantCase(includeGranularities),
        },
      }),
    ...options,
  })

  //wrap uuids in each object and not as object keys to avoid case problem
  const newData = useMemo(
    () =>
      data
        ? {
            ...data?.data,
            valuations: Object.entries(data?.data?.valuations ?? {}).map(([key, value]) => ({
              uuid: key,
              ...value,
            })),
          }
        : undefined,
    [data],
  )

  return useMemo(
    () => ({
      isLoading,
      isFetching,
      isError,
      //camelize CONSTANT_CASE not working in camelize library, therefore first snake_case all then camelize
      data: camelize(constantCaseObjectKeysToSnakeCase(newData)),
    }),
    [isError, isLoading, isFetching, newData],
  )
}

export default useMultiPropertyValuationsDecisionPaper
