import { FlexBox, FlexBoxAlignItems, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import { useCustomizableCurrencyFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/hooks/i18n/useI18n'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/routes/deals/financing/shared-components/ConvertedAmount.module.css'

const ConvertedAmount = (amount, headquarterCurrency) => {
  const formatMoney = useCustomizableCurrencyFormatter()

  return (
    <FlexBox
      fitContainer
      direction={FlexBoxDirection.Column}
      alignItems={FlexBoxAlignItems.End}
      className={styles.convertedAmountWrapper}
    >
      <div
        id="info-in-headquarter-currency"
        className={styles.textLabelColor}
        data-testid="info-in-headquarter-currency"
      >
        {formatMoney(amount, headquarterCurrency, { currencyDisplay: 'code' })}
      </div>
    </FlexBox>
  )
}

export default ConvertedAmount
