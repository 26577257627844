import { ThemeProvider } from '@fioneer/ui5-webcomponents-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from 'App'
import 'index.css'
import registerServiceWorker from 'registerServiceWorker'
;(async () => {
  await registerServiceWorker('/service-worker.js')
  // HINT: don't render a React page when opening / downloading a document
  if (window.location.pathname.match(/^\/documents\/[^/]*\/[^/]*\/raw/)) return

  const STALE_TIME = 5 // minutes

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: STALE_TIME * 1000 * 60,
        cacheTime: Infinity, // do not delete stale data
      },
    },
  })

  const container = document.getElementById('root')
  if (!container) {
    throw new Error("Didn't find root element")
  }
  const root = createRoot(container)

  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </QueryClientProvider>
    </React.StrictMode>,
  )
})()
