export const ADDED = 'added-'

export const PERIODS = {
  ANNUALLY: 'annually',
  MONTHLY: 'monthly',
  NONE: 'none',
}

export const TOTAL_AREA = 'total_area'

export const isDeletable = (segmentUuid, segmentKpisData) => {
  const segmentKpi = segmentKpisData?.kpis?.find((kpi) => kpi.segment_uuid === segmentUuid)
  // a segment is deletable when the kpi for total area is not set or is set to <= 0
  return !segmentKpi?.total_area_surface?.value || segmentKpi?.total_area_surface?.value <= 0
}

export const findSegmentKpisByPropertyUuid = (propertyUuid, multiPropertySegmentKpisData) => {
  const segmentKpisDataByKeyDates = multiPropertySegmentKpisData?.find(
    (segmentKpisData) => segmentKpisData.property_uuid === propertyUuid,
  )?.key_date_to_segment_rent_roll_kpis
  return segmentKpisDataByKeyDates?.length > 0 ? segmentKpisDataByKeyDates[0] : []
}

export const findAreaMeasureUnitCodeByPropertyUuid = (propertyUuid, properties) => {
  const property = properties.find((prop) => prop.uuid === propertyUuid)
  return property?.area_measure_unit_code
}
