import { useRequests } from 'hooks/services/baseService'

const NOT_FOUND = 404

export const useRentRolls = (propertyUuids = [], options = { enabled: true }) => {
  const requests = propertyUuids.map((propertyUuid) => ({
    keys: ['rent-roll', propertyUuid],
    path: `/properties/${propertyUuid}/rent-roll`,
  }))

  const results = useRequests({
    requests,
    translated: true,
    options: {
      retry: (nFailures, error) => error.response?.status !== NOT_FOUND && nFailures < 1,
      ...options,
    },
  })

  const consolidatedResults = results.reduce(
    (consolidated, { isLoading, isError, error, data, fetchStatus }) => {
      const isActuallyLoading = isLoading && fetchStatus !== 'idle'
      const isActualError = isError && !(error.response?.status === NOT_FOUND)
      const actualError = isActualError ? error : undefined

      return {
        isLoading: consolidated.isLoading || isActuallyLoading,
        isError: consolidated.isError || isActualError,
        errors: actualError ? [...(consolidated.errors ?? []), actualError] : consolidated.errors,
        data: data ? [...(consolidated.data ?? []), data] : consolidated.data,
      }
    },
    {
      isLoading: false,
      isError: false,
    },
  )

  return {
    ...consolidatedResults,
    data:
      !consolidatedResults.isError && !consolidatedResults.isLoading
        ? consolidatedResults.data
        : undefined,
  }
}
