import {
  FlexBox,
  Icon,
  Link,
  ObjectStatus,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import get from 'lodash.get'
import { Fragment } from 'react'
import Labeled from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/AssetValuationOverviewLabeled'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/AssetValuationOverviewPropertyPortfolioOverviewTableCard.module.css'
import { getAssetValuationOverviewYearOrYears } from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/AssetValuationOverviewYearUnitUtil'
import AnalyticalTableCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/analytical/AssetValuationOverviewAnalyticalTableCell'
import {
  valuationClassification,
  valuationClassificationAbbreviationsTranslated,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/domains/property/valuation/valuationRequests'

const PLACE_HOLDER = '-'

const renderCellWithGroupSize = (value, _rowData, _additionalData, { groupedRows }) => {
  const typeNameGroup = groupedRows?.find((group) => group.groupByVal === value)
  if (typeNameGroup?.isGrouped) {
    const groupSize = typeNameGroup.leafRows?.length
    return groupSize ? `${value} (${groupSize})` : value
  } else {
    return value
  }
}

const getGroupByValFromAggregatedCell = (cell) => cell?.row?.groupByVal
const getGroupByIdFromAggregatedCell = (cell) => cell?.row?.groupByID

const getGranularityValue = (groupById, groupByVal, multiPropertyKpis, subPortfolioKpis, t) => {
  let multiPropertyKpisValueGroupByVal

  switch (groupById) {
    case 'country':
      multiPropertyKpisValueGroupByVal = multiPropertyKpis?.countryRentRollKpis?.filter(
        (value) => value.countryName === groupByVal,
      )?.[0]
      break
    case 'city':
      multiPropertyKpisValueGroupByVal = multiPropertyKpis?.cityRentRollKpis?.filter(
        (value) => value.city === groupByVal,
      )?.[0]
      break
    case 'type_name':
      multiPropertyKpisValueGroupByVal = multiPropertyKpis?.propertyTypeRentRollKpis?.filter(
        (value) => value.propertyTypeName === groupByVal,
      )?.[0]
      break
    case 'sub_portfolio':
      multiPropertyKpisValueGroupByVal =
        //if accessor subPortfolio is null, groupByVal has this translation value due to function getValueByAccessorOrDefault
        groupByVal === t('group-by.sub-portfolio.no-data-group')
          ? subPortfolioKpis?.filter((value) => value.subPortfolio === null)?.[0]?.kpis
          : subPortfolioKpis?.filter((value) => value.subPortfolio === groupByVal)?.[0]?.kpis
      break
  }
  return multiPropertyKpisValueGroupByVal
}

export const AssetValuationOverviewDescriptionCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>
    {({ description, id, typeName, propertyStatus }, { t }) => (
      <>
        <Link href={`/properties/${id}`} design="Emphasized">
          {description}
        </Link>
        <span>{id}</span>
        <Labeled
          label={t('table.columns.property-status')}
          showColon
          className={styles.extraTopMargin}
        >
          <span>
            <ObjectStatus
              inverted={
                propertyStatus?.valueState && propertyStatus?.valueState !== ValueState.None
              }
              state={propertyStatus?.valueState ?? ValueState.None}
            >
              {propertyStatus?.text ?? '-'}
            </ObjectStatus>
          </span>
        </Labeled>
        <Labeled
          label={t('table.columns.property-type')}
          showColon
          className={styles.extraTopMargin}
        />
        <span>{typeName}</span>
      </>
    )}
  </AnalyticalTableCell>
)

export const AssetValuationOverviewDealPropertyDescriptionCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>
    {(
      { description, id, typeName, subPortfolio, propertyStatus },
      { t, isPdfViewOnDecisionPaper },
    ) => (
      <>
        {!isPdfViewOnDecisionPaper ? (
          <Link href={`/properties/${id}`} target="_blank" design="Emphasized">
            {description}
          </Link>
        ) : (
          <Text>{description}</Text>
        )}
        <span>{id}</span>
        <Labeled
          label={t('table.columns.property-status')}
          showColon
          className={styles.extraTopMargin}
        >
          <span>
            <ObjectStatus
              inverted={
                propertyStatus?.valueState && propertyStatus?.valueState !== ValueState.None
              }
              state={propertyStatus?.valueState ?? ValueState.None}
            >
              {propertyStatus?.text ?? ''}
            </ObjectStatus>
          </span>
        </Labeled>
        <Labeled
          label={t('table.columns.property-type')}
          showColon
          className={styles.extraTopMargin}
        >
          <span>{typeName}</span>
        </Labeled>
        <Labeled
          label={t('table.columns.subportfolio')}
          showColon
          className={styles.extraTopMargin}
        >
          <span>{subPortfolio || ''}</span>
        </Labeled>
      </>
    )}
  </AnalyticalTableCell>
)

export const AssetValuationOverviewPropertyStatusCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>{() => <></>}</AnalyticalTableCell>
)

export const AssetValuationOverviewPropertyTypeCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>{() => <></>}</AnalyticalTableCell>
)

export const AssetValuationOverviewSubPortfolioCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>{() => <></>}</AnalyticalTableCell>
)

const calculateShare = (value, sum) => {
  if (!value || !sum) {
    return 0
  }
  return value / sum
}

export const AssetValuationOverviewAllocatedLoanAmountCell = (
  <AnalyticalTableCell alignRight>
    {(
      {
        allocatedLoanAmount,
        allocatedLoanAmountHeadquarter,
        allocatedLoanAmountHeadquarterSum,
        releasePremium,
      },
      { t, formatCurrency, formatPercent },
    ) => (
      <>
        <span>
          {formatCurrency(
            allocatedLoanAmountHeadquarter?.number,
            allocatedLoanAmountHeadquarter?.currencyCode,
          ) || ''}
        </span>
        {allocatedLoanAmount?.currencyCode !== allocatedLoanAmountHeadquarter?.currencyCode && (
          <Labeled label={t('table.columns.original')} showColon>
            {formatCurrency(allocatedLoanAmount?.number, allocatedLoanAmount?.currencyCode) || '-'}
          </Labeled>
        )}
        {allocatedLoanAmountHeadquarter && (
          <Labeled label={t('table.columns.share')} showColon>
            {formatPercent(
              calculateShare(
                allocatedLoanAmountHeadquarter?.number,
                allocatedLoanAmountHeadquarterSum,
              ),
            )}
          </Labeled>
        )}
        {releasePremium !== null && (
          <Labeled label={t('table.columns.release-premium')} showColon>
            {formatPercent(releasePremium) || ''}
          </Labeled>
        )}
      </>
    )}
  </AnalyticalTableCell>
)

export const AssetValuationOverviewAddressCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>
    {({ address }, { t }) => (
      <>
        <span>{address?.countryName}</span>
        <Labeled label={t('table.columns.postal-code')} showColon>
          {address?.postalCode}
        </Labeled>
        <Labeled label={t('table.columns.city')} showColon>
          {address?.cityName}
        </Labeled>
        <Labeled label={t('table.columns.street')} showColon>
          {address?.streetName}
        </Labeled>
        <Labeled label={t('table.columns.house-no')} showColon>
          {address?.houseId}
        </Labeled>
      </>
    )}
  </AnalyticalTableCell>
)

export const AssetValuationOverviewCountryCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>{() => <></>}</AnalyticalTableCell>
)

export const AssetValuationOverviewCityCell = (
  <AnalyticalTableCell aggregatedValue={renderCellWithGroupSize}>{() => <></>}</AnalyticalTableCell>
)

export const AssetValuationOverviewMoneyValueCell = (
  <AnalyticalTableCell alignRight>
    {({ valuations }, { t, formatCurrency, formatPercent, classification }) => {
      const value = get(valuations, classification, null)
      const valueAmount = value?.valueAmount
      const originValueAmount = value?.originValueAmount
      const shares = value?.shares?.sum
      const isValidConversion = value?.isValidConversion
      return (
        valueAmount?.number > 0 && (
          <>
            {isValidConversion && (
              <span>{formatCurrency(valueAmount.number, valueAmount.currency)} </span>
            )}
            {originValueAmount?.number && (
              <FlexBox>
                <Labeled label={t('table.columns.original')} showColon>
                  {formatCurrency(originValueAmount.number, originValueAmount.currency)}
                </Labeled>
                {!isValidConversion && <Icon name="alert" className={styles.conversionErrorIcon} />}
              </FlexBox>
            )}
            <Labeled label={t('table.columns.share')} showColon>
              {formatPercent(shares)}
            </Labeled>
          </>
        )
      )
    }}
  </AnalyticalTableCell>
)

export const AssetValuationOverviewMoneyValueCellV3 = (
  <AnalyticalTableCell alignRight>
    {(
      { valuations },
      { t, tNoPrefix, formatCurrency, formatPercent, classifications, mainValuationTypes },
    ) => {
      const preparedValuations = []
      classifications.forEach((classification) => {
        const {
          valueAmount,
          originValueAmount,
          shares: { sum: share } = {},
          isValidConversion,
        } = valuations?.[classification] ?? {}

        const valuationClassificationKey = valuationClassification?.[classification]

        // We only want to add a label for the non "main" categories/valuation types.
        // So we still have market value and mortgage lending value as the main values for their columns
        const label = mainValuationTypes.includes(classification)
          ? undefined
          : tNoPrefix(valuationClassificationAbbreviationsTranslated?.[valuationClassificationKey])

        if (valueAmount?.number > 0) {
          preparedValuations.push({
            valueAmount,
            originValueAmount,
            share,
            isValidConversion,
            label,
          })
        }
      })

      return preparedValuations.map(
        ({ valueAmount, originValueAmount, share, isValidConversion, label }, index) => (
          <Fragment key={index}>
            {label && isValidConversion && (
              <Labeled label={label} showColon>
                {formatCurrency(valueAmount.number, valueAmount.currency)}
              </Labeled>
            )}
            {!label && isValidConversion && (
              <span>{formatCurrency(valueAmount.number, valueAmount.currency)} </span>
            )}
            {originValueAmount?.number && (
              <FlexBox>
                <Labeled label={t('table.columns.original')} showColon>
                  {formatCurrency(originValueAmount.number, originValueAmount.currency)}
                </Labeled>
                {!isValidConversion && <Icon name="alert" className={styles.conversionErrorIcon} />}
              </FlexBox>
            )}
            <Labeled label={t('table.columns.share')} showColon>
              {formatPercent(share)}
            </Labeled>
          </Fragment>
        ),
      )
    }}
  </AnalyticalTableCell>
)

export const AssetValuationOverviewAggregatedMoneyValueCell = (
  <AnalyticalTableCell alignRight renderAggregateRegularly>
    {(
      { currencyCode },
      { t, formatCurrency, formatPercent, valuationSum, classification },
      { cell },
    ) => {
      const valuationByClassification = get(valuationSum, classification, null)
      return cell?.value ? (
        <>
          <b>{formatCurrency(cell.value, valuationByClassification?.currency || currencyCode)}</b>
          <Labeled label={t('table.columns.share')} showColon>
            {formatPercent(calculateShare(cell?.value, valuationByClassification?.number))}
          </Labeled>
        </>
      ) : (
        <></>
      )
    }}
  </AnalyticalTableCell>
)

export const AssetValuationOverviewAggregatedMoneyValueCellV3 = (
  <AnalyticalTableCell alignRight renderAggregateRegularly>
    {(
      { currencyCode },
      {
        t,
        tNoPrefix,
        formatCurrency,
        formatPercent,
        valuationSum,
        classifications,
        mainValuationTypes,
      },
      { cell },
    ) => {
      const { subRows } = cell?.row ?? {}

      let mainColumnClassification = undefined
      const preparedValuationSums = []

      // Since we only have the aggregation over the main valuation types, we need to implement summation for all other types ourselves.
      // Luckily, the Analytical table provides all subRows currently grouped under the given aggregation.

      classifications?.forEach((classification) => {
        const valuationClassificationKey = valuationClassification?.[classification]

        const otherValuationSum = subRows?.reduce((acc, cur) => {
          const { valueAmount, originValueAmount } =
            cur?.original?.valuations?.[classification] ?? {}

          // in case of the main valuation type, skip the summation and set the variable for later use
          if (mainValuationTypes.includes(classification)) {
            mainColumnClassification = classification
            return acc
          } else if (originValueAmount?.number) {
            return acc + originValueAmount?.number
          }
          return acc + (valueAmount?.number ?? 0)
        }, 0)

        if (!isNaN(otherValuationSum) && otherValuationSum > 0) {
          preparedValuationSums.push({
            classification,
            label: tNoPrefix(
              valuationClassificationAbbreviationsTranslated?.[valuationClassificationKey],
            ),
            value: otherValuationSum,
          })
        }
      })

      // Here we define the old/standard case of summation done by the Analytical table aggregation logic
      if (mainColumnClassification) {
        preparedValuationSums.unshift({
          classification: mainColumnClassification,
          label: undefined,
          value: cell?.value,
        })
      }

      return preparedValuationSums?.map(({ classification, label, value }, index) => {
        const valuationSumByClassification = valuationSum?.[classification] ?? null
        return value ? (
          <Fragment key={index}>
            {label && (
              <Labeled label={label} showColon>
                <b>
                  {formatCurrency(value, valuationSumByClassification?.currency || currencyCode)}
                </b>
              </Labeled>
            )}
            {!label && (
              <b>{formatCurrency(value, valuationSumByClassification?.currency || currencyCode)}</b>
            )}
            <Labeled label={t('table.columns.share')} showColon>
              {formatPercent(calculateShare(value, valuationSumByClassification?.number))}
            </Labeled>
          </Fragment>
        ) : (
          <></>
        )
      })
    }}
  </AnalyticalTableCell>
)

export const AssetValuationOverviewLettableAreaCell = (
  <AnalyticalTableCell alignRight>
    {({ kpis }, { t, formatNumber, formatPercent, formatAreaUnit, multiPropertyKpis }) =>
      kpis?.totalAreaSurface?.value ? (
        <>
          <span>
            {formatNumber(kpis?.totalAreaSurface?.value)}&nbsp;
            {formatAreaUnit(kpis?.totalAreaSurface?.measurementUnit)}
          </span>
          <Labeled label={t('table.columns.share')} showColon>
            {formatPercent(
              kpis?.totalAreaSurface?.value / multiPropertyKpis?.totalAreaSurface?.value,
            )}
          </Labeled>
          <Labeled label={t('table.columns.rented')} showColon>
            {formatPercent(kpis?.letSurface?.percent)}
          </Labeled>
          <Labeled label={t('table.columns.number-of-units')} showColon>
            {formatNumber(kpis?.totalNumberOfUnits)}
          </Labeled>
        </>
      ) : (
        <></>
      )
    }
  </AnalyticalTableCell>
)

export const AssetValuationOverviewAggregatedLettableAreaCell = (
  <AnalyticalTableCell alignRight renderAggregateRegularly>
    {(
      { kpis },
      { t, formatNumber, formatPercent, formatAreaUnit, multiPropertyKpis },
      { cell },
    ) => {
      if (cell?.value) {
        return (
          <>
            <b>
              {formatNumber(cell.value)}&nbsp;
              {formatAreaUnit(kpis?.totalAreaSurface?.measurementUnit)}
            </b>
            <Labeled label={t('table.columns.share')} showColon>
              {formatPercent(cell.value / multiPropertyKpis?.totalAreaSurface?.value)}
            </Labeled>
          </>
        )
      } else {
        return <></>
      }
    }}
  </AnalyticalTableCell>
)

export const AssetValuationOverviewCurrentRentalIncomeCell = (
  <AnalyticalTableCell alignRight>
    {({ kpis }, { t, formatCurrency, formatPercent, multiPropertyKpis }) =>
      kpis?.annualizedCurrentRent?.number ? (
        <>
          <span>
            {formatCurrency(
              kpis?.annualizedCurrentRent?.number,
              kpis?.annualizedCurrentRent?.currency,
            )}
          </span>
          {kpis?.originalAnnualizedCurrentRent?.number && (
            <FlexBox>
              <Labeled label={t('table.columns.original')} showColon>
                {formatCurrency(
                  kpis?.originalAnnualizedCurrentRent?.number,
                  kpis?.originalAnnualizedCurrentRent?.currency,
                )}
              </Labeled>
            </FlexBox>
          )}
          <Labeled label={t('table.columns.share')} showColon>
            {formatPercent(
              kpis?.annualizedCurrentRent?.number /
                multiPropertyKpis?.annualizedCurrentRent?.number,
            )}
          </Labeled>
        </>
      ) : (
        <></>
      )
    }
  </AnalyticalTableCell>
)

export const AssetValuationOverviewAggregatedCurrentRentalIncomeDecisionPaperCell = (
  <AnalyticalTableCell alignRight renderAggregateRegularly>
    {(
      { _ },
      { t, formatCurrency, formatPercent, multiPropertyKpis, subPortfolioKpis },
      { cell },
    ) => {
      const groupByVal = getGroupByValFromAggregatedCell(cell)
      const groupById = getGroupByIdFromAggregatedCell(cell)
      const multiPropertyKpisValueGroupByVal = getGranularityValue(
        groupById,
        groupByVal,
        multiPropertyKpis,
        subPortfolioKpis,
        t,
      )
      return multiPropertyKpisValueGroupByVal?.annualizedCurrentRent?.number ? (
        <>
          <b>
            {formatCurrency(
              multiPropertyKpisValueGroupByVal?.annualizedCurrentRent?.number,
              multiPropertyKpisValueGroupByVal?.annualizedCurrentRent?.currency,
            )}
          </b>
          <Labeled label={t('table.columns.share')} showColon>
            {formatPercent(
              multiPropertyKpisValueGroupByVal?.annualizedCurrentRent?.number /
                multiPropertyKpis?.annualizedCurrentRent?.number,
            )}
          </Labeled>
        </>
      ) : null
    }}
  </AnalyticalTableCell>
)

export const AssetValuationOverviewMarketRentCell = (
  <AnalyticalTableCell alignRight>
    {({ kpis, tegovaRating }, { t, formatCurrency }) =>
      kpis?.totalMarketRent?.number || tegovaRating ? (
        <>
          <span>
            {kpis?.totalMarketRent?.number && kpis?.totalMarketRent?.currency
              ? formatCurrency(kpis?.totalMarketRent?.number, kpis?.totalMarketRent?.currency)
              : '-'}
          </span>
          {kpis?.originalTotalMarketRent?.number && (
            <FlexBox>
              <Labeled label={t('table.columns.original')} showColon>
                {formatCurrency(
                  kpis?.originalAnnualizedCurrentRent?.number,
                  kpis?.originalAnnualizedCurrentRent?.currency,
                )}
              </Labeled>
            </FlexBox>
          )}
          <Labeled label={t('table.columns.tegova-rating')} showColon>
            <span>{tegovaRating || '-'}</span>
          </Labeled>
          <span>{tegovaRating ? `- ${t(`rating.label.${tegovaRating}`)}` : ''}</span>
        </>
      ) : null
    }
  </AnalyticalTableCell>
)

export const AssetValuationOverviewAggregatedMarketRentDecisionPaperCell = (
  <AnalyticalTableCell alignRight renderAggregateRegularly>
    {({ _ }, { t, multiPropertyKpis, formatCurrency, subPortfolioKpis }, { cell }) => {
      const groupByVal = getGroupByValFromAggregatedCell(cell)
      const groupById = getGroupByIdFromAggregatedCell(cell)
      const multiPropertyKpisValueGroupByVal = getGranularityValue(
        groupById,
        groupByVal,
        multiPropertyKpis,
        subPortfolioKpis,
        t,
      )
      return multiPropertyKpisValueGroupByVal?.totalMarketRent?.number ? (
        <b>
          {formatCurrency(
            multiPropertyKpisValueGroupByVal?.totalMarketRent?.number,
            multiPropertyKpisValueGroupByVal?.totalMarketRent?.currency,
          )}
        </b>
      ) : null
    }}
  </AnalyticalTableCell>
)

export const AssetValuationOverviewWaultCell = (
  <AnalyticalTableCell alignRight>
    {({ kpis }, { t, tNoPrefix, formatWault }) =>
      kpis?.waultToBreakInYears ? (
        <>
          <span>{`${formatWault(kpis?.waultToBreakInYears)} ${getAssetValuationOverviewYearOrYears(
            tNoPrefix,
            kpis?.waultToBreakInYears,
          )}`}</span>
          <Labeled label={t('table.columns.wault-expiry')} showColon />
          <span>{`${formatWault(kpis?.waultToExpiryInYears)} ${getAssetValuationOverviewYearOrYears(
            tNoPrefix,
            kpis?.waultToExpiryInYears,
          )}`}</span>
        </>
      ) : (
        <></>
      )
    }
  </AnalyticalTableCell>
)

export const AssetValuationOverviewWaultExpiryCell = (
  <AnalyticalTableCell alignRight>
    {({ kpis }, { t, formatWault }) =>
      kpis?.waultToExpiryInYears ? (
        <>
          <Labeled label={t('table.columns.wault-expiry')} showColon />
          <span>{`${formatWault(kpis.waultToExpiryInYears)} ${getAssetValuationOverviewYearOrYears(
            t,
            kpis.waultToExpiryInYears,
          )}`}</span>
        </>
      ) : (
        <></>
      )
    }
  </AnalyticalTableCell>
)

export const AssetValuationOverviewAggregatedWaultDecisionPaperCell = (
  <AnalyticalTableCell alignRight renderAggregateRegularly>
    {({ _ }, { t, tNoPrefix, formatWault, multiPropertyKpis, subPortfolioKpis }, { cell }) => {
      const groupByVal = getGroupByValFromAggregatedCell(cell)
      const groupById = getGroupByIdFromAggregatedCell(cell)
      const multiPropertyKpisValueGroupByVal = getGranularityValue(
        groupById,
        groupByVal,
        multiPropertyKpis,
        subPortfolioKpis,
        t,
      )
      return multiPropertyKpisValueGroupByVal?.waultToBreakInYears ? (
        <>
          <b>
            {`${formatWault(
              multiPropertyKpisValueGroupByVal?.waultToBreakInYears,
            )} ${getAssetValuationOverviewYearOrYears(
              tNoPrefix,
              multiPropertyKpisValueGroupByVal?.waultToBreakInYears,
            )}`}
          </b>
          <Labeled label={t('table.columns.wault-expiry')} showColon />
          <span>
            {`${formatWault(
              multiPropertyKpisValueGroupByVal?.waultToExpiryInYears,
            )} ${getAssetValuationOverviewYearOrYears(
              tNoPrefix,
              multiPropertyKpisValueGroupByVal?.waultToExpiryInYears,
            )}`}
          </span>
        </>
      ) : null
    }}
  </AnalyticalTableCell>
)

export const AssetValuationOverviewPropertyKpisCell = (
  <AnalyticalTableCell>
    {({ keyData }, { t, formatNumber, formatPercentWithDigits }) =>
      keyData?.multiplier ? (
        <>
          <Labeled label={t('gross-multiplier')} showColon>
            {formatNumber(keyData.multiplier)}
          </Labeled>
          <Labeled label={t('gross-initial-yield')} showColon>
            {formatPercentWithDigits(keyData?.initialYield)}
          </Labeled>
        </>
      ) : (
        <></>
      )
    }
  </AnalyticalTableCell>
)

export const AssetValuationOverviewGreenDataCell = (
  <AnalyticalTableCell>
    {(
      { greenData: { customFields = [], customFieldClassEnumValues = [] } = {} },
      { t, formatNumberWithDigits, formatBoolean },
    ) => {
      const getFormatBooleanValue = (customFieldKey) =>
        formatBoolean(customFields?.find(({ key }) => key === customFieldKey)?.value) ??
        PLACE_HOLDER

      return customFields?.some(({ value }) => !!value) ? (
        <>
          <Labeled label={t('class')} showColon>
            {customFieldClassEnumValues.find(
              ({ code }) => code === customFields?.find(({ key }) => key === 'YY_EPC_CLASS')?.value,
            )?.displayName ?? PLACE_HOLDER}
          </Labeled>
          <Labeled label={t('co2-emissions')} showColon>
            {customFields?.find(({ key }) => key === 'YY_CO2_EMISSION')?.value
              ? formatNumberWithDigits(
                  customFields?.find(({ key }) => key === 'YY_CO2_EMISSION')?.value,
                )
              : PLACE_HOLDER}
          </Labeled>
          <Labeled label={t('pbb-green-score')} showColon>
            {customFields?.find(({ key }) => key === 'YY_PBB_GREEN_SCORE')?.value ?? PLACE_HOLDER}
          </Labeled>
          <Labeled label={t('green-loan-elig')} showColon>
            {getFormatBooleanValue('YY_PBB_GREEN_FW_LOAN_ELIGIB')}
          </Labeled>
          <Labeled label={t('green-bond-elig')} showColon>
            {getFormatBooleanValue('YY_QUALIFIED_FOR_GREEN_BOND')}
          </Labeled>
          <Labeled label={t('pbb-green-bond')} showColon>
            {getFormatBooleanValue('YY_PBB_GREEN_BOND_USAGE')}
          </Labeled>
          <Labeled label={t('eu-taxonomy-elig')} showColon>
            {getFormatBooleanValue('YY_EU_TAXONOMY_ELIGIBLE')}
          </Labeled>
        </>
      ) : (
        <></>
      )
    }}
  </AnalyticalTableCell>
)
