import { MessageStripDesign, Text } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/deal-specifics/RiskMonitoringDealSpecificsPropertySelect.module.css'
import CloseableMessageStrip from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/deal-specifics/shared/ui/feedback/CloseableMessageStrip'
import LoadingSelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/deal-specifics/shared/ui/select/LoadingSelect'

const RiskMonitoringDealSpecificsPropertySelect = ({
  propertyMetadata,
  propertiesData,
  handleOnChange,
  isPdfView = false,
}) => {
  const { t: tNoPrefix } = useTranslation('decisionPaper')

  const {
    selectedPropertyUuid: propertyUuid,
    selectedPropertyName: propertyName,
    selectedPropertyId: propertyId,
  } = propertyMetadata

  const propertySelectData = useMemo(
    () =>
      propertiesData?.map((property) => ({
        key: property?.uuid,
        name: `${property?.description} (${property?.id})`,
      })) ?? [],
    [propertiesData],
  )

  return useMemo(() => {
    if (isEmpty(propertiesData)) {
      return (
        <CloseableMessageStrip design={MessageStripDesign.Information} hideCloseButton={true}>
          {tNoPrefix('tiles.risk-monitoring.deal-specifics.property-select.no-properties')}
        </CloseableMessageStrip>
      )
    } else if (isPdfView) {
      return <Text>{`${propertyName} (${propertyId})`}</Text>
    }
    return (
      <LoadingSelect
        id="riskMonitoringProperty-loadingSelect"
        className={styles.riskMonitoringPropertySelect}
        loadingHook={() => ({
          isLoading: false,
          isError: false,
          data: propertySelectData,
        })}
        selectedKey={propertyUuid}
        showEmptyOption={isEmpty(propertySelectData)}
        optionDisplayName="name"
        optionKeyName="key"
        onChange={handleOnChange}
      />
    )
  }, [
    handleOnChange,
    isPdfView,
    propertiesData,
    propertyId,
    propertyName,
    propertySelectData,
    propertyUuid,
    tNoPrefix,
  ])
}

RiskMonitoringDealSpecificsPropertySelect.propTypes = {
  isPdfView: PropTypes.bool,
  propertiesData: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  propertyMetadata: PropTypes.shape({
    selectedPropertyUuid: PropTypes.string,
    selectedPropertyName: PropTypes.string,
    selectedPropertyId: PropTypes.string,
  }),
  handleOnChange: PropTypes.func,
}

export default RiskMonitoringDealSpecificsPropertySelect
