import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

export const useCreatePropertyInsurance = ({ onSuccess = () => {}, onError = () => {} }) => {
  const { post } = useAccessTokenRequest()
  const { parse, localePattern } = useShortDateFormatter()
  return useMutation(
    async ({ propertyUuid, insurance }) => {
      const { data } = await post({
        path: `/properties/${propertyUuid}/insurance`,
        body: snakecaseKeys({
          ...insurance,
          insuranceStartDate: parse(insurance.insuranceStartDate, localePattern),
          insuranceExpiryDate: parse(insurance.insuranceExpiryDate, localePattern),
        }),
      })
      return data
    },
    { onSuccess, onError },
  )
}
