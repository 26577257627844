import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { referencesPropTypes } from 'components/domains/conditions/overview/table-cells/conditions/propTypes'
import ReferencesSelectionComponent, {
  ReferencesSelectionComponentRenderDirections,
} from 'components/domains/conditions/reference-selection/ReferencesSelectionComponent'
import ConditionsReferencesView from 'components/domains/conditions/shared/ConditionsReferencesView'
import useConditionsCellEditMode from 'hooks/services/conditions/edit-mode/useConditionsCellEditMode'
import {
  conditionReferencesChanged,
  conditionRowHasError,
} from 'redux/slices/conditions/conditionsTableSlice'

const ConditionsTableReferencesCell = ({ value: { id: conditionId, references } }) => {
  const dispatch = useDispatch()

  const onError = useCallback(
    (error) => {
      dispatch(conditionRowHasError({ id: conditionId, error }))
    },
    [dispatch, conditionId],
  )

  const editedReferences = useSelector(
    (state) => state.conditions.conditionsTable.editedRow.currentValues?.references,
  )

  const { isEditModeForCurrentRow } = useConditionsCellEditMode({
    conditionId,
  })

  const onReferencesChanged = useCallback(
    (newReferences) => {
      if (newReferences?.entityIds.length === 0) {
        dispatch(
          conditionReferencesChanged({
            conditionId,
            references: {
              entityIds: [],
              entityType: newReferences.entityType,
            },
          }),
        )
        return
      }

      dispatch(
        conditionReferencesChanged({
          conditionId,
          references: newReferences,
        }),
      )
    },
    [conditionId, dispatch],
  )

  if (isEditModeForCurrentRow) {
    return (
      <ReferencesSelectionComponent
        references={editedReferences}
        onReferencesChanged={onReferencesChanged}
        renderDirection={ReferencesSelectionComponentRenderDirections.reversed}
      />
    )
  }

  return <ConditionsReferencesView references={references} onError={onError} />
}

ConditionsTableReferencesCell.propTypes = {
  value: PropTypes.shape({
    references: referencesPropTypes,
    id: PropTypes.string.isRequired,
  }).isRequired,
}

export default ConditionsTableReferencesCell
