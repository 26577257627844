import PropTypes from 'prop-types'
import { useRef } from 'react'
import FormattedNumberInput from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/ui/input/FormattedNumberInput'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche/shared/ui/tables/toolbar/input/NumberFilterInput.module.css'
import { filters } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche/shared/ui/tables/toolbar/propTypes'

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  targetName: PropTypes.string.isRequired,
  selectedFilters: filters.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  columnKey: PropTypes.string.isRequired,
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
function NumberFilterInput({
  value,
  targetName,
  selectedFilters,
  setSelectedFilters,
  columnKey,
  ...props
}) {
  const formattedNumberInputRef = useRef()
  return (
    <FormattedNumberInput
      value={value}
      onChange={(parsedNumber) => {
        const isValid = formattedNumberInputRef?.current?.isValid
        const isEmpty = typeof parsedNumber !== 'number'
        setSelectedFilters(
          selectedFilters.map((filter) =>
            filter.columnKey === columnKey
              ? {
                  ...filter,
                  value: {
                    ...filter.value,
                    [targetName]:
                      isValid && !isEmpty ? parsedNumber : filter.emptyValue[targetName],
                  },
                  valueValidities: {
                    ...filter.valueValidities,
                    [targetName]: isValid,
                  },
                }
              : filter,
          ),
        )
      }}
      className={styles.input}
      ref={formattedNumberInputRef}
      {...props}
    />
  )
}

NumberFilterInput.propTypes = propTypes

export default NumberFilterInput
