import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PortfolioFinancedAssetsAreaMap from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-area/PortfolioFinancedAssetsAreaMap'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/tegovaRating/shared/EmptyCardContent'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const MultiplePortfolioFinancedAssetsAreaTile = ({ tileId, isPdfView, selectedDealIndex }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.portfolio-financed-assets-area',
  })

  const {
    data: {
      multiplePropertyMarkers = [],
      multipleMissingGeoLocations = [],
      multipleTotalAmountOfProperties = [],
      multipleMarketAreaData = [],
      noProperties,
      sourcePaths,
    } = {},
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { propertyMarkers, missingGeoLocations, totalAmountOfProperties, marketAreaData } = useMemo(
    () => ({
      propertyMarkers: multiplePropertyMarkers?.[selectedDealIndex],
      missingGeoLocations: multipleMissingGeoLocations?.[selectedDealIndex],
      totalAmountOfProperties: multipleTotalAmountOfProperties?.[selectedDealIndex],
      marketAreaData: multipleMarketAreaData?.[selectedDealIndex],
    }),
    [
      multipleMarketAreaData,
      multipleMissingGeoLocations,
      multiplePropertyMarkers,
      multipleTotalAmountOfProperties,
      selectedDealIndex,
    ],
  )

  useEffect(() => {
    dispatch(
      setSourceRender({ tileId, sourceRender: { path: `${sourcePaths?.[selectedDealIndex]}` } }),
    )
  }, [dispatch, selectedDealIndex, sourcePaths, tileId])

  if (noProperties) {
    return <NoPropertyAssignedToDealInfo />
  }

  if (
    !propertyMarkers ||
    propertyMarkers?.length === 0 ||
    (!marketAreaData?.marketId && totalAmountOfProperties === 1)
  ) {
    return (
      <EmptyCardContent
        illustrationName="NoData"
        title={t('no-data.title')}
        subtitle={t('no-data.subtitle')}
      />
    )
  }
  return (
    <PortfolioFinancedAssetsAreaMap
      markers={propertyMarkers}
      disabled={isPdfView}
      missingLocations={missingGeoLocations}
      totalAmountOfProperties={totalAmountOfProperties}
      marketAreaData={marketAreaData}
    />
  )
}

MultiplePortfolioFinancedAssetsAreaTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultiplePortfolioFinancedAssetsAreaTile
