import { conditionTypes } from 'api/conditions/conditions'

const calculateApprovalLevel = ({
  conditionType,
  hasApprovalLevelChanged,
  currentApprovalLevel,
}) => {
  if (conditionType === conditionTypes.external || !hasApprovalLevelChanged) {
    return
  }
  if (currentApprovalLevel === '') {
    return null
  }
  return {
    code: currentApprovalLevel,
  }
}

export const mapChangedConditionsToBECall = ({
  currentValues,
  changedFields,
  conditionType,
  updateRefNumberForRequirements,
}) => {
  let references
  if (changedFields.references) {
    references = currentValues.references
      ? {
          entityIds: currentValues.references.entityIds,
          entityType: currentValues.references.entityType,
        }
      : currentValues.references
  }

  let info
  if (
    changedFields.name ||
    changedFields.category ||
    changedFields.type ||
    changedFields.description ||
    changedFields.assignee ||
    changedFields.externalAssignee ||
    changedFields.approvalLevel ||
    changedFields.refNumber ||
    changedFields.visibilities ||
    changedFields.covenantCheckId
  ) {
    info = {
      name: changedFields.name ? currentValues.name : undefined,
      category:
        conditionType === conditionTypes.external && changedFields.category
          ? currentValues.category
          : undefined,
      type: changedFields.type
        ? {
            code: currentValues.type,
          }
        : undefined,
      covenantCheckId: changedFields.covenantCheckId ? currentValues.covenantCheckId : undefined,
      description: changedFields.description ? currentValues.description : undefined,
      assignee: changedFields.assignee ? currentValues.assignee : undefined,
      externalAssigneeEmail:
        conditionType === conditionTypes.external && changedFields.externalAssignee
          ? currentValues.externalAssignee
          : undefined,
      approvalLevel: calculateApprovalLevel({
        conditionType,
        hasApprovalLevelChanged: changedFields.approvalLevel,
        currentApprovalLevel: currentValues.approvalLevel,
      }),
      refNumber: changedFields.refNumber ? currentValues.refNumber : undefined,
      visibilities:
        conditionType === conditionTypes.external && changedFields.visibilities
          ? currentValues.visibilities.map((visibility) => ({ code: visibility }))
          : undefined,
      updateLinkedRequirementsRefNumber: updateRefNumberForRequirements,
    }
  }
  return {
    info,
    references,
    status: changedFields.status ? { code: currentValues.status.code } : undefined,
  }
}
