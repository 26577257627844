import { FlexBox, FlexBoxJustifyContent, Label } from '@fioneer/ui5-webcomponents-react'
import styles from 'components/domains/properties/rent-roll/details/table/PropertyRentRollDetailsTableCells.module.css'
import AnalyticalTableCell from 'components/ui/tables/analytical/AnalyticalTableCell'

export const RentRollRentalUnitCell = (
  <AnalyticalTableCell>
    {({ name, occupancy_status }, { t }) => (
      <>
        <>{name?.cellComponent}</>
        <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween}>
          <Label>{`${t('label.occupancy')}:`}</Label>
          {occupancy_status?.cellComponent}
        </FlexBox>
      </>
    )}
  </AnalyticalTableCell>
)

export const RentRollTenantCell = (
  <AnalyticalTableCell>{({ tenant }) => <>{tenant?.cellComponent}</>}</AnalyticalTableCell>
)

export const RentRollSegmentAndUsageTypeCell = (
  <AnalyticalTableCell>
    {({ segment_name, segment_usage_type_name }) => (
      <>
        <>{segment_name?.cellComponent}</>
        <>{segment_usage_type_name?.cellComponent}</>
      </>
    )}
  </AnalyticalTableCell>
)

export const RentRollAreaCell = (
  <AnalyticalTableCell>{({ area }) => <>{area?.cellComponent}</>}</AnalyticalTableCell>
)

export const RentRollRentContractedYearCell = (
  <AnalyticalTableCell>
    {({ rent_contracted_year }) => (
      <>
        <>{rent_contracted_year?.cellComponent}</>
      </>
    )}
  </AnalyticalTableCell>
)

export const RentRollCurrentNetRentCell = (
  <AnalyticalTableCell>
    {({ rent_current_year }) => (
      <>
        <>{rent_current_year?.cellComponent}</>
      </>
    )}
  </AnalyticalTableCell>
)

export const RentRollRentStartCell = (
  <AnalyticalTableCell>
    {({ rent_start_date, lease_start_date, lease_expiry_date }, { t }) => (
      <>
        <div className={styles.alignRight}>
          <>{rent_start_date?.cellComponent}</>
        </div>
        <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween}>
          <Label>{`${t('label.lease-start')}:`}</Label>
          {lease_start_date?.cellComponent}
        </FlexBox>
        <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween}>
          <Label>{`${t('label.lease-expiry')}:`}</Label>
          {lease_expiry_date?.cellComponent}
        </FlexBox>
      </>
    )}
  </AnalyticalTableCell>
)
