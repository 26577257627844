import { InputType, Text } from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import { round, isNil } from 'lodash'
import PropTypes from 'prop-types'
import { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NumberInput from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/ui/input/NumberInput'
import {
  useNumberFormatter,
  useFormattedNumberParser,
  useSeparators,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onKeyDown: PropTypes.func,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  minimumFractionDigits: PropTypes.number,
  maximumFractionDigits: PropTypes.number,
  valueState: PropTypes.string,
  valueStateMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const FormattedNumberInput = forwardRef(
  /**
   * @param {PropTypes.InferProps<typeof propTypes>} props
   */
  (
    {
      value,
      onKeyDown = () => {},
      onChange = () => {},
      onInput = () => {},
      onFocus = () => {},
      onBlur = () => {},
      maximumFractionDigits = 2,
      minimumFractionDigits = 2,
      valueState: externalValueState = ValueState.None,
      valueStateMessage: externalValueStateMessage,
      ...props
    },
    inputRef,
  ) => {
    const { t } = useTranslation('decisionPaper')
    const formatNumber = useNumberFormatter({ maximumFractionDigits, minimumFractionDigits })
    const formatNumberEditMode = useNumberFormatter({
      useGrouping: false,
      maximumFractionDigits,
    })
    const parseNumber = useFormattedNumberParser()

    const separators = useSeparators()

    const parseAndRound = (number) => {
      const numberToString = typeof number === 'string' ? number : number?.toString()

      const regPattern = new RegExp(`\\${separators?.thousandsSeparator}`, 'g')

      const strippedNumber = numberToString?.replace(regPattern, '')

      const parsed = parseNumber(strippedNumber)

      if (parsed === null || isNaN(parsed) || strippedNumber === '') {
        return null
      }

      const decimals = 10 ** maximumFractionDigits
      return round(parsed * decimals) / decimals
    }

    const [parsedValue, setParsedValue] = useState(parseAndRound(value))
    const formattedValue = formatNumber(parsedValue)
    const [invalidInputValue, setInvalidInputValue] = useState('')
    const [isFocused, setIsFocused] = useState(false)
    const [currentValueState, setCurrentValueState] = useState(externalValueState)
    const [currentValueStateMessage, setCurrentValueStateMessage] = useState(
      externalValueStateMessage || <Text>{t('form.validation.number')}</Text>,
    )

    const isValidInput = (inputValue) => inputValue?.match(/[^\d.,-]/g) === null

    const updateValueStateMessage = (isValid) => {
      const displayExternalValueStateMessage =
        (externalValueState === ValueState.Error || isValid) && externalValueStateMessage
      setCurrentValueStateMessage(
        displayExternalValueStateMessage ? (
          externalValueStateMessage
        ) : (
          <Text>{t('form.validation.number')}</Text>
        ),
      )
    }

    useEffect(() => {
      setParsedValue(parseAndRound(value))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    // Setting the valueState while initializing useState is not enough,
    // if the props and state change more than once a single render.
    // Therefore, useEffect is used to bridge this "immutable gap"
    useEffect(() => {
      if (!invalidInputValue) {
        setCurrentValueState(externalValueState)
        setCurrentValueStateMessage(externalValueStateMessage)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalValueState, externalValueStateMessage])

    const preventNonNumericInputs = (event) => {
      if (event.metaKey || event.ctrlKey) return
      if (
        !isFinite(event.key) &&
        event.key.length <= 1 &&
        !['.', ',', '-', 'b', 'm', 'k'].includes(event.key)
      ) {
        event.preventDefault()
      }
    }

    const onPasteHandler = (event) => {
      if (isValidInput(event?.clipboardData?.getData('text'))) {
        setInvalidInputValue('')
      } else {
        setInvalidInputValue(event?.clipboardData?.getData('text'))
      }
    }

    const getCurrentValue = () => {
      if (!isNil(invalidInputValue) && invalidInputValue.length > 0) {
        return invalidInputValue
      }
      if (parsedValue === null) {
        return ''
      }

      if (formattedValue === '') {
        return ''
      }

      return isFocused ? formatNumberEditMode(parsedValue) : formattedValue
    }

    return (
      <NumberInput
        {...props}
        ref={inputRef}
        type={InputType.Text}
        isFocused={isFocused}
        value={getCurrentValue()}
        onKeyDown={(e) => {
          preventNonNumericInputs(e)
          onKeyDown(e)
        }}
        onInput={(e) => {
          onInput(parseAndRound(e.target.value))
        }}
        onPaste={(e) => {
          onPasteHandler(e)
        }}
        onChange={(e) => {
          if (inputRef) {
            inputRef.current.isValid = isValidInput(e.target.value)
          }
          if (isValidInput(e.target.value)) {
            setInvalidInputValue('')
          } else {
            setInvalidInputValue(e.target.value)
            setParsedValue(parseAndRound(e.target.value))
          }
          const parsedNumericValue = parseAndRound(e.target.value)
          setParsedValue(parsedNumericValue)
          onChange(parsedNumericValue)
        }}
        onFocus={(e) => {
          setIsFocused(true)
          onFocus(e)
        }}
        onBlur={(e) => {
          setIsFocused(false)
          setCurrentValueState(invalidInputValue ? ValueState.Error : externalValueState)
          updateValueStateMessage(!invalidInputValue)
          onBlur(e)
        }}
        valueState={currentValueState}
        valueStateMessage={currentValueStateMessage}
      />
    )
  },
)

FormattedNumberInput.displayName = 'FormattedNumberInput'

FormattedNumberInput.propTypes = propTypes

export default FormattedNumberInput
