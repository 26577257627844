export const CHECKLIST_ITEMS = [
  {
    labelKey: 'rating-update-necessary',
    checkListItem: 'ratingUpdateNecessary',
    isFirstItem: true,
  },
  {
    labelKey: 'account-manager-one-informed',
    checkListItem: 'accountManagerOneInformed',
    isFirstItem: false,
  },
  {
    labelKey: 'syndication-partner-informed',
    checkListItem: 'syndicationPartnerInformed',
    isFirstItem: false,
  },
  {
    labelKey: 'guarantor-informed',
    checkListItem: 'guarantorInformed',
    isFirstItem: false,
  },
]

export const CHECK_VALUE_YES = 'YES'
export const CHECK_VALUE_NO = 'NO'
