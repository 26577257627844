import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styles from 'components/ui/data/LabelValueList.module.css'

const isVisibleRow = (show) => show === undefined || !!show

/**
 * @param rows array of json objects with optional attributes label, value, caption, linkTo
 * label: label text
 * value: value text
 * caption: label adopts style of caption if flag is true
 * linkTo: what resource value should link to
 * omitMargin: if true, the row is not given additional margin. used to visually group rows
 * show: if defined and falsy at the same time, the row is hidden entirely. use to render row conditionally
 * @param {string} className className JSX attribute passed to the component
 */
const LabelValueList = ({ rows, className }) => {
  const labelClassName = (bold) =>
    bold ? 'sapTextColor sapFontBoldFamily' : 'sapContent_LabelColor'

  const renderLabel = (label, caption, index) => (
    <Label data-testid={`label-${index}`} className={labelClassName(caption)}>
      {label}
    </Label>
  )

  const renderValue = (value, linkTo, index) => {
    if (linkTo) {
      return (
        <Link
          className={`sapFontBoldFamily sapInformativeColor ${styles.valueItem}`}
          data-testid={`value-link-${index}`}
          to={linkTo}
        >
          {value}
        </Link>
      )
    }
    return (
      <div className={`sapTextColor ${styles.valueItem}`} data-testid={`value-${index}`}>
        {value}
      </div>
    )
  }

  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      className={`${className} ${styles.labelValueList}`}
    >
      {rows.map(
        ({ label, value, caption, linkTo, omitMargin, show }, index) =>
          isVisibleRow(show) && (
            <FlexBox
              data-testid={`row-${index}`}
              key={`label-value-card-row-${index}`}
              direction={FlexBoxDirection.Row}
              justifyContent={FlexBoxJustifyContent.SpaceBetween}
              className={omitMargin ? '' : 'sapUiTinyMarginTop'}
            >
              {renderLabel(label, caption, index)}
              {renderValue(value, linkTo, index)}
            </FlexBox>
          ),
      )}
    </FlexBox>
  )
}

LabelValueList.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.node,
      caption: PropTypes.bool,
      linkTo: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
}

export default LabelValueList
