import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { isNotFoundError } from 'api/requests'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useStaffMemberByObjectIdOrEmail'

const ConditionRequirementChangelogTriggeredByCell = ({ triggeredBy }) => {
  const isEnabled = !isNil(triggeredBy)

  const {
    data: staffMember,
    isFetching,
    isError,
    error,
  } = useStaffMemberByObjectIdOrEmail({ objectIdOrEmail: triggeredBy })

  const renderContent = useCallback(() => {
    if (isNil(staffMember)) {
      return triggeredBy
    }
    return staffMember.fullName
  }, [staffMember, triggeredBy])

  const isTechnicalError = isError && !isNotFoundError(error)

  return (
    <RequestStateResolver
      isLoading={isFetching && isEnabled}
      isError={isTechnicalError}
      renderContent={renderContent}
      errorToDisplay={<ErrorDataUnavailableInCell />}
    />
  )
}

ConditionRequirementChangelogTriggeredByCell.propTypes = {
  triggeredBy: PropTypes.string,
}

export default ConditionRequirementChangelogTriggeredByCell
