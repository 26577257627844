import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-partners/dual-unit-value/DualUnitValue.module.css'

/**
 * Component to display a unit value along an alternative unit value
 *
 * @param primaryValueWithUnit the primary value with unit (e.g. '1.000.000 EUR', '500 sqm')
 * @param secondaryValueWithUnit the optional secondary value with unit (e.g. '1.000.000 USD', '500 sqft')
 * @param className a class name to add to the outermost element
 */
const DualUnitValue = ({ primaryValueWithUnit, secondaryValueWithUnit, className }) => {
  let dualCurrencyStyle = styles.dualCurrency
  if (className) {
    dualCurrencyStyle = `${dualCurrencyStyle} ${className}`
  }

  return (
    <div className={dualCurrencyStyle}>
      <div className={styles.caretCursor}>
        <Text className={styles.textBold}>{primaryValueWithUnit}</Text>
      </div>
      {secondaryValueWithUnit !== undefined && (
        <div className={`${styles.caretCursor} ${styles.textLabelColor}`}>
          {secondaryValueWithUnit}
        </div>
      )}
    </div>
  )
}

DualUnitValue.propTypes = {
  primaryValueWithUnit: PropTypes.string.isRequired,
  secondaryValueWithUnit: PropTypes.string,
  className: PropTypes.string,
}

export default DualUnitValue
