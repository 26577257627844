import { TextAlign } from '@fioneer/ui5-webcomponents-react'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DocumentIconCell } from 'components/domains/documents/documents-table/DocumentIconCell'
import DownloadDocumentCell from 'components/domains/documents/documents-table/DownloadDocumentCell'
import NameCell from 'components/domains/documents/documents-table/NameCell'
import styles from 'components/domains/documents/documents-table/NameCell.module.css'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import VersionCell from 'hooks/services/documents/documents-table/VersionCell'
import useResolveNameFromEmail from 'routes/deals/syndication/syndicationIntention/utils/useResolveNameFromEmail'

const formatDateTime = (dateTime) =>
  dateTime.toLocaleString({
    weekday: 'short',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  })

/**
 * can be used to filter column definitions if not all columns should be displayed
 */
export const documentsTableColumnIds = {
  name: 'name',
  nameForFiltering: 'nameForFiltering',
  type: 'type',
  keyDate: 'keyDate',
  fileExtension: 'fileExtension',
  version: 'version',
  creator: 'creator',
  modificationTime: 'modificationTime',
  downloadDocument: 'downloadDocument',
}

export const sortFolderStructureNodeByAttribute = (node1, node2, attribute, descending) => {
  const isSameDepth = node1.depth === node2.depth
  const isFirstNodeAFolder = Array.isArray(node1?.original?.subFolders)
  const isSecondNodeAFolder = Array.isArray(node2?.original?.subFolders)
  const areFoldersComparable = isFirstNodeAFolder && isSecondNodeAFolder && isSameDepth

  if (isFirstNodeAFolder && !areFoldersComparable) return descending ? -1 : 1
  if (isSecondNodeAFolder && !areFoldersComparable) return descending ? 1 : -1

  return node1.original[attribute]?.localeCompare(node2.original[attribute])
}

export const useDocumentsTableColumns = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.documents.table.columns',
  })
  const { format: formatDate } = useShortDateFormatter()

  const Name = useCallback(
    ({ cell }) => (
      <>
        <NameCell cell={cell} />
        <div className={styles.icon}>
          <DownloadDocumentCell cell={cell} />
        </div>
      </>
    ),
    [],
  )

  const Version = useCallback(
    ({ cell }) => (
      <div className={styles.versionContainer}>
        {cell.value}
        <VersionCell cell={cell} />
      </div>
    ),
    [],
  )

  const CreatorCell = ({ cell: { value } }) => {
    const resolvedName = useResolveNameFromEmail(value)
    return resolvedName
  }

  const DateCell = useCallback(
    ({ cell: { value } }) =>
      value && <span title={formatDateTime(DateTime.fromISO(value))}>{formatDate(value)}</span>,
    [formatDate],
  )
  DateCell.propTypes = {
    cell: PropTypes.shape({
      value: PropTypes.string,
    }).isRequired,
  }

  const columns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: ({ name, hasReferencedRequirement }) => ({
          name,
          hasReferencedRequirement,
        }),
        id: documentsTableColumnIds.name,
        Cell: Name,
        minWidth: 400,
        sortType: (row1, row2, _columnId, descending) =>
          sortFolderStructureNodeByAttribute(row1, row2, 'name', descending),
        disableFilters: true,
        disableDragAndDrop: true,
        title: t('name'),
        isSelectableForHiding: true,
        isVisible: true,
      },
      {
        Header: t('name'),
        width: 0,
        id: documentsTableColumnIds.nameForFiltering,
        filterType: filterTypes.CONTAINS,
        accessor: 'name',
        hide: true,
      },
      {
        Header: t('type'),
        accessor: 'type',
        id: documentsTableColumnIds.type,
        sortType: (row1, row2, _columnId, descending) =>
          sortFolderStructureNodeByAttribute(row1, row2, 'type', descending),
        filterType: filterTypes.CONTAINS,
        disableDragAndDrop: true,
        title: t('type'),
        isSelectableForHiding: true,
        isVisible: true,
      },
      {
        Header: t('key-date'),
        accessor: 'keyDate',
        width: 110,
        id: documentsTableColumnIds.keyDate,
        Cell: DateCell,
        hAlign: TextAlign.End,
        sortType: (row1, row2, _columnId, descending) =>
          sortFolderStructureNodeByAttribute(row1, row2, 'keyDate', descending),
        filterType: filterTypes.BETWEEN_DATES,
        disableDragAndDrop: true,
        title: t('key-date'),
        isSelectableForHiding: true,
        isVisible: true,
      },
      {
        Header: t('format'),
        accessor: 'fileExtension',
        width: 100,
        id: documentsTableColumnIds.fileExtension,
        hAlign: TextAlign.Center,
        Cell: DocumentIconCell,
        sortType: (row1, row2, _columnId, descending) =>
          sortFolderStructureNodeByAttribute(row1, row2, 'fileExtension', descending),
        filterType: filterTypes.CONTAINS,
        disableSortBy: true,
        disableDragAndDrop: true,
        title: t('format'),
        isSelectableForHiding: true,
        isVisible: true,
      },
      {
        Header: t('version'),
        accessor: 'currentVersion',
        width: 100,
        id: documentsTableColumnIds.version,
        Cell: Version,
        sortType: (row1, row2, _columnId, descending) =>
          sortFolderStructureNodeByAttribute(row1, row2, 'version', descending),
        filterType: filterTypes.CONTAINS,
        disableDragAndDrop: true,
        title: t('version'),
        isSelectableForHiding: true,
        isVisible: true,
      },
      {
        Header: t('last-modified-at'),
        accessor: 'modificationTime',
        width: 110,
        id: documentsTableColumnIds.modificationTime,
        Cell: DateCell,
        hAlign: TextAlign.End,
        sortType: (row1, row2, _columnId, descending) =>
          sortFolderStructureNodeByAttribute(row1, row2, 'modificationTime', descending),
        filterType: filterTypes.BETWEEN_DATES,
        disableDragAndDrop: true,
        title: t('last-modified-at'),
        isSelectableForHiding: true,
        isVisible: true,
      },
      {
        Header: t('creator'),
        accessor: 'creator',
        minWidth: 100,
        id: documentsTableColumnIds.creator,
        sortType: (row1, row2, _columnId, descending) =>
          sortFolderStructureNodeByAttribute(row1, row2, 'creator', descending),
        filterType: filterTypes.CONTAINS,
        disableDragAndDrop: true,
        Cell: CreatorCell,
        title: t('creator'),
        isSelectableForHiding: true,
        isVisible: true,
      },
    ],
    [t, Name, DateCell, Version],
  )
  return { columns, formatDate }
}
