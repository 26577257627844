// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cwp-card {
  margin-bottom: var(--sapUiSmallSpace);
}

.card-button {
  margin: 0.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/card/Card.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;AACvC;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".cwp-card {\n  margin-bottom: var(--sapUiSmallSpace);\n}\n\n.card-button {\n  margin: 0.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
