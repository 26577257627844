import { Modals } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { requirementStatusTypes } from 'api/conditions/conditions'
import styles from 'components/domains/conditions/overview/requirements/RequirementsTableDocumentFileDrop.module.css'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import DocumentUploadDialog from 'components/domains/documents/DocumentUploadDialog'
import DocumentFileDrop from 'components/ui/document/DocumentFileDrop'
import useCanFileDrop from 'components/ui/document/useCanFileDrop'
import { MessageBoxTypes } from 'components/ui/message-box/MessageBox'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const RequirementsTableDocumentFileDrop = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.document-file-drop',
  })
  const {
    entityRef: { entityId, entityType },
  } = useContext(ConditionsContext)

  const atLeastOneRequirementInStatusDone = (requirements) =>
    Object.values(requirements).some(({ status }) => status === requirementStatusTypes.done)

  const selectedRequirementIds = useSelector(
    (state) => state.conditions.requirementsTable.selectedRows.selectedRequirementIds,
  )

  const [isUploadDocumentDialogOpen, setIsUploadDocumentDialogOpen] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState()

  const showMessageBox = Modals.useShowMessageBox()

  const selectedEntities = useMemo(() => {
    const objectValues = Object.values(selectedRequirementIds)
    const objectEntries = Object.entries(selectedRequirementIds)

    switch (entityType) {
      case DocumentTypes.BusinessPartner:
        if (
          objectValues.every(
            ({ references }) => references?.entityType === DocumentTypes.BusinessPartner,
          )
        ) {
          return [
            { id: entityId, type: entityType },
            ...objectEntries.flatMap(([selectedRequirementId, { references }]) => {
              const type = references?.entityType ?? entityType

              return [
                ...(references?.entityIds?.map((id) => ({ id, type })) ?? [{ id: entityId, type }]),
                { id: selectedRequirementId, type: DocumentTypes.Requirement },
              ]
            }),
          ]
        }

        return [
          { id: entityId, type: entityType },
          ...objectEntries.flatMap(([selectedRequirementId]) => [
            { id: selectedRequirementId, type: DocumentTypes.Requirement },
          ]),
        ]

      case DocumentTypes.Deal:
        if (objectValues.every(({ references }) => references === null)) {
          return objectEntries.flatMap(([selectedRequirementId]) => [
            { id: entityId, type: entityType },
            { id: selectedRequirementId, type: DocumentTypes.Requirement },
          ])
        }

        return objectEntries.flatMap(([selectedRequirementId, { references }]) => {
          const type = references?.entityType ?? entityType

          // Create an array containing the required objects and return it directly
          return [
            ...(references?.entityIds?.map((id) => ({ id, type })) ?? [{ id: entityId, type }]),
            { id: selectedRequirementId, type: DocumentTypes.Requirement },
          ]
        })
    }
  }, [entityId, entityType, selectedRequirementIds])

  const documentTypeIfAllEqual = useMemo(() => {
    const documentTypesArray = Object.values(selectedRequirementIds).map(
      ({ documentType }) => documentType,
    )
    return documentTypesArray.every((documentType) => documentType === documentTypesArray[0])
      ? documentTypesArray[0]
      : undefined
  }, [selectedRequirementIds])

  const initialState = useMemo(
    () => ({
      files: uploadedFiles,
      selectedEntities: selectedEntities,
      documentType: documentTypeIfAllEqual,
    }),
    [documentTypeIfAllEqual, selectedEntities, uploadedFiles],
  )

  const { isOver, canDrop, dropRef } = useCanFileDrop()

  const toastRef = useRef(null)

  const onFileDrop = useCallback(
    (files) => {
      if (isEmpty(selectedEntities)) {
        showMessageBox({
          type: MessageBoxTypes.Error,
          titleText: t('error.no-requirement-selected.title'),
          children: t('error.no-requirement-selected.description'),
          draggable: true,
          resizable: true,
        })
        return
      }

      if (!documentTypeIfAllEqual) {
        showMessageBox({
          type: MessageBoxTypes.Error,
          titleText: t('error.different-document-types.title'),
          children: t('error.different-document-types.description'),
          draggable: true,
          resizable: true,
        })
        return
      }

      setUploadedFiles(files)
      setIsUploadDocumentDialogOpen(true)
    },
    [documentTypeIfAllEqual, selectedEntities, showMessageBox, t],
  )

  const onUploadDocumentClose = useCallback(() => {
    setIsUploadDocumentDialogOpen(false)
  }, [])

  return (
    <div ref={dropRef}>
      {!atLeastOneRequirementInStatusDone(selectedRequirementIds) && (
        <DocumentFileDrop
          onDrop={onFileDrop}
          isOver={isOver}
          canDrop={canDrop}
          ref={toastRef}
          customContainerStyles={styles.customFileDropContainerStyles}
        />
      )}
      {isUploadDocumentDialogOpen && (
        <DocumentUploadDialog
          isOpen={isUploadDocumentDialogOpen}
          setIsOpen={setIsUploadDocumentDialogOpen}
          initialState={initialState}
          type={DocumentTypes.Requirement}
          onClose={onUploadDocumentClose}
        />
      )}
    </div>
  )
}

RequirementsTableDocumentFileDrop.propTypes = {}

export default RequirementsTableDocumentFileDrop
