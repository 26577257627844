export const FINANCING_TRANCHE_READ = 'FinancingTranche_Read'
export const FINANCING_TRANCHE_CREATE = 'FinancingTranche_Create'
export const FINANCING_TRANCHE_UPDATE = 'FinancingTranche_Update'
export const FINANCING_TRANCHE_REMOVE = 'FinancingTranche_Remove'
export const FINANCING_TRANCHE_FEE_READ = 'FinancingTrancheFee_Read'
export const FINANCING_TRANCHE_FEE_UPDATE = 'FinancingTrancheFee_Update'
export const FINANCING_TRANCHE_INTEREST_CONDITIONS_READ = 'FinancingTrancheInterestConditions_Read'
export const FINANCING_TRANCHE_INTEREST_CONDITIONS_UPDATE =
  'FinancingTrancheInterestConditions_Update'
export const FINANCING_TRANCHE_AMORTIZATION_CONDITIONS_READ =
  'FinancingTrancheAmortizationConditions_Read'
export const FINANCING_TRANCHE_AMORTIZATION_CONDITIONS_UPDATE =
  'FinancingTrancheAmortizationConditions_Update'
export const FINANCING_TRANCHE_PRICING_READ = 'FinancingTranchePricing_Read'
export const FINANCING_TRANCHE_PRICING_UPDATE = 'FinancingTranchePricing_Update'
export const FINANCING_TRANCHE_INTEREST_RATE_HEDGING_REQUIREMENT_READ =
  'FinancingTrancheInterestRateHedgingRequirement_Read'
export const FINANCING_TRANCHE_INTEREST_RATE_HEDGING_REQUIREMENT_UPDATE =
  'FinancingTrancheInterestRateHedgingRequirement_Update'
export const FINANCING_TRANCHE_ACCOUNTING_READ = 'FinancingTrancheAccounting_Read'
export const FINANCING_TRANCHE_ACCOUNTING_UPDATE = 'FinancingTrancheAccounting_Update'
export const FINANCING_TRANCHE_ADDITIONAL_INTEREST_INFORMATION_READ =
  'FinancingTrancheAdditionalInterestInformation_Read'
export const FINANCING_TRANCHE_ADDITIONAL_INTEREST_INFORMATION_UPDATE =
  'FinancingTrancheAdditionalInterestInformation_Update'
export const FINANCING_TRANCHE_OPTIONS_READ = 'FinancingTrancheOptions_Read'
export const FINANCING_TRANCHE_OPTIONS_UPDATE = 'FinancingTrancheOptions_Update'

export const FINANCING_OTHER_FINANCING_SOURCES_READ = 'FinancingOtherFinancingSources_Read'
export const FINANCING_OTHER_FINANCING_SOURCES_UPDATE = 'FinancingOtherFinancingSources_Update'

export const FINANCING_CUSTOM_FIELDS_UPDATE = 'FinancingCustomFields_Update'
export const FINANCING_CUSTOM_FIELDS_READ = 'FinancingCustomFields_Read'

export const FINANCING_NON_LOAN_PRODUCTS_READ = 'FinancingNonLoanProducts_Read'
export const FINANCING_NON_LOAN_PRODUCTS_UPDATE = 'FinancingNonLoanProducts_Update'

export const FINANCING_BASIC_AGREEMENT_CREATE = 'FinancingBasicAgreement_Create'

export const SYNDICATION_INTENTION_READ = 'SyndicationIntention_Read'
export const SYNDICATION_INTENTION_UPDATE = 'SyndicationIntention_Update'
export const SYNDICATION_STRUCTURE_READ = 'SyndicationStructure_Read'
export const SYNDICATIONS_EXISTING_BUSINESS_READ = 'SyndicationsExistingBusiness_Read'
export const SYNDICATION_STRUCTURE_UPDATE = 'SyndicationStructure_Update'
export const SYNDICATION_COMMENT_READ = 'SyndicationComment_Read'
export const SYNDICATION_COMMENT_UPDATE = 'SyndicationComment_Update'

export const SYNDICATION_STATEMENT_READ = 'SyndicationStatement_Read'
export const SYNDICATION_STATEMENT_UPDATE = 'SyndicationStatement_Update'
