import { Link, Text } from '@fioneer/ui5-webcomponents-react'
import parse from 'html-react-parser'
import PropType from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/tasks/table/TasksTableDescriptionCell.module.css'

const htmlEndTagStartTagRegEx = /((<\/)\w+(>))(<)/g
const emptyDescription = '<p><br></p>'
const maxDescriptionDisplaySize = 400
const maxDescriptionDisplaySizeWithoutDots = 397
const maxShortenedDescriptionDisplaySize = 100

const TasksTableDescriptionCell = ({ description }) => {
  const newLineBetweenEndAndStartTagReplacer = (
    _match,
    completeEndTag,
    _endTagFirstPart,
    _endTagSecondPart,
    startTag,
    _offset,
    _string,
  ) => `${completeEndTag}\n${startTag}`

  const splitDescriptionLines = (description) =>
    description
      .replaceAll(htmlEndTagStartTagRegEx, newLineBetweenEndAndStartTagReplacer)
      .split('\n')

  const shortenDescription = (description) =>
    description.substring(0, maxShortenedDescriptionDisplaySize)

  const formatDescriptionOnShowMoreClicked = (description) =>
    description.length > maxDescriptionDisplaySize
      ? parse(description.substring(0, maxDescriptionDisplaySizeWithoutDots) + '...')
      : parse(description)

  const { t } = useTranslation('translation')
  const [showMore, setShowMore] = useState(false)
  const descriptionLines = splitDescriptionLines(description)
  const handleMoreClick = (event) => {
    event.preventDefault()
    setShowMore(true)
  }

  if (!description || description === emptyDescription) {
    return <Text>{}</Text>
  }

  if (
    showMore ||
    (descriptionLines.length < 2 &&
      descriptionLines[0].length <= maxShortenedDescriptionDisplaySize)
  ) {
    const displayedText = formatDescriptionOnShowMoreClicked(description)

    return <Text>{displayedText}</Text>
  }
  const displayedText = shortenDescription(descriptionLines[0] + descriptionLines[1])

  return (
    <>
      <Text className={styles['descriptionText']}>{parse(displayedText)}</Text>
      {'... '}
      <Link className={styles['moreLink']} href="" onClick={handleMoreClick}>
        {t('buttons.more')}
      </Link>
    </>
  )
}

TasksTableDescriptionCell.propTypes = {
  description: PropType.string.isRequired,
}

export default TasksTableDescriptionCell
