import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * Returns all available action types in the change log set
 * The response body structure is as follows:
 * {
 *   "actionTypes": ["CREATED", "UPDATED", ...]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: actionTypes}}
 */
const useChangeLogActionTypes = ({ entityId, entityType }) => {
  const queryParams = new URLSearchParams()
  if (entityId) queryParams.set('entity_id', entityId)
  if (entityType) queryParams.set('entity_type', entityType)

  return useCamelizedResponse(
    useRequest({
      path: `/traceability/configurations/action-types?${queryParams.toString()}`,
      keys: ['traceability', 'configuration', 'action-types', queryParams.toString()],
      translated: true,
      useCache: false,
    }),
  )
}

export default useChangeLogActionTypes
