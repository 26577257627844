import flatten from 'lodash.flatten'

const mapUserUuidsFromForbearanceStatus = (data) => {
  const { forbearanceStatuses } = data ?? {}

  const createdByUuids = forbearanceStatuses?.map((f) => f.createdOn?.createdBy) ?? []
  const createdByVersionsUuids =
    forbearanceStatuses?.map((f) =>
      f.forbearanceStatusVersions.map((fv) => fv.createdOn?.createdBy),
    ) ?? []

  return flatten([...createdByUuids, ...createdByVersionsUuids])
}

export default mapUserUuidsFromForbearanceStatus
