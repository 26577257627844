import { FlexBox, FlexBoxAlignItems, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { conditionsEntityTypes } from 'api/conditions/conditions'
import { referencesPropTypes } from 'components/domains/conditions/overview/table-cells/conditions/propTypes'
import ReferenceMultiSelectionInput from 'components/domains/conditions/reference-selection/ReferenceMultiSelectionInput'
import ReferenceSelectionHelperDialog from 'components/domains/conditions/reference-selection/ReferenceSelectionHelperDialog'
import ReferenceTypeSelectionInput from 'components/domains/conditions/reference-selection/ReferenceTypeSelectionInput'
import styles from 'components/domains/conditions/reference-selection/ReferencesSelectionComponent.module.css'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

export const ReferencesSelectionComponentRenderDirections = {
  normal: 'normal',
  horizontal: 'horizontal',
  reversed: 'reversed',
}

const ReferencesSelectionComponent = ({
  references,
  onReferencesChanged,
  disableInput = false,
  renderDirection = ReferencesSelectionComponentRenderDirections.normal,
}) => {
  const [isHelperDialogOpen, setIsHelperDialogOpen] = useState(false)
  const [selectedReferenceType, setSelectedReferenceType] = useState(references?.entityType)

  const {
    entityRef: { entityType: conditionEntityType },
  } = useContext(ConditionsContext)

  const mappedReferenceSelection = useMemo(
    () =>
      references?.entityIds?.map((entityId) => ({
        entityId,
        entityType: selectedReferenceType,
      })),
    [references?.entityIds, selectedReferenceType],
  )

  const selectableReferenceTypes = useMemo(() => {
    if (conditionEntityType === conditionsEntityTypes.deal) {
      return [cwpEntityTypes.PROPERTY, cwpEntityTypes.BUSINESS_PARTNER]
    }
    return [cwpEntityTypes.DEAL, cwpEntityTypes.PROPERTY, cwpEntityTypes.BUSINESS_PARTNER]
  }, [conditionEntityType])

  const onClickReferenceSelection = useCallback(() => {
    setIsHelperDialogOpen(true)
  }, [])

  const onCancelReferenceSelection = useCallback(() => {
    setIsHelperDialogOpen(false)
  }, [])

  const cleanedSelectedReferenceType = useMemo(() => {
    if (!selectableReferenceTypes.includes(selectedReferenceType)) {
      return undefined
    }
    return selectedReferenceType
  }, [selectableReferenceTypes, selectedReferenceType])

  const onClearSelection = useCallback(() => {
    onReferencesChanged({ entityType: cleanedSelectedReferenceType, entityIds: [] })
  }, [cleanedSelectedReferenceType, onReferencesChanged])

  const onReferenceSelectionChanged = useCallback(
    ({ selectedReferences }) => {
      const mappedEntityIds = selectedReferences.map(({ entityId }) => entityId)
      onReferencesChanged({ entityIds: mappedEntityIds, entityType: cleanedSelectedReferenceType })
      setIsHelperDialogOpen(false)
    },
    [onReferencesChanged, cleanedSelectedReferenceType],
  )

  const onDeleteReference = useCallback(
    ({ entityId: deletedRefId }) => {
      onReferencesChanged(
        references?.entityIds.length > 1
          ? {
              entityIds: references?.entityIds.filter((entityId) => entityId !== deletedRefId),
              entityType: cleanedSelectedReferenceType,
            }
          : {
              entityIds: [],
              entityType: cleanedSelectedReferenceType,
            },
      )
    },
    [onReferencesChanged, references?.entityIds, cleanedSelectedReferenceType],
  )

  useEffect(() => {
    setSelectedReferenceType(references?.entityType)
  }, [references?.entityType]) // eslint-disable-line react-hooks/exhaustive-deps

  const onEntityTypeSelectionChange = useCallback(
    (_event, newSelectedEntityType) => {
      setSelectedReferenceType(newSelectedEntityType)
      if (isEmpty(newSelectedEntityType)) {
        onReferencesChanged(null)
        return
      }
      onReferencesChanged({ entityIds: [], entityType: newSelectedEntityType })
    },
    [onReferencesChanged],
  )

  const flexBoxRendering = useMemo(() => {
    if (renderDirection === ReferencesSelectionComponentRenderDirections.horizontal) {
      return {
        direction: FlexBoxDirection.Row,
        alignItems: FlexBoxAlignItems.Center,
        className: styles.inputsHorizontal,
      }
    }
    if (renderDirection === ReferencesSelectionComponentRenderDirections.reversed) {
      return {
        direction: FlexBoxDirection.ColumnReverse,
        alignItems: FlexBoxAlignItems.Center,
        className: styles.inputs,
      }
    }
    return {
      direction: FlexBoxDirection.Column,
      alignItems: FlexBoxAlignItems.Center,
      className: styles.inputs,
    }
  }, [renderDirection])

  return (
    <>
      <FlexBox {...flexBoxRendering}>
        <ReferenceTypeSelectionInput
          selectedReferenceType={cleanedSelectedReferenceType}
          onSelectionChange={onEntityTypeSelectionChange}
          selectableReferenceTypes={selectableReferenceTypes}
          disableInput={disableInput}
        />
        <ReferenceMultiSelectionInput
          selectedReferences={mappedReferenceSelection}
          onClear={onClearSelection}
          onDeleteReference={onDeleteReference}
          onClickValueHelperIcon={onClickReferenceSelection}
          showValueHelperIcon
          disabled={disableInput || isEmpty(cleanedSelectedReferenceType)}
          selectedReferenceType={cleanedSelectedReferenceType}
        />
      </FlexBox>
      {isHelperDialogOpen && (
        <ReferenceSelectionHelperDialog
          isOpen={isHelperDialogOpen}
          onCancel={onCancelReferenceSelection}
          selectedReferences={mappedReferenceSelection}
          onSelect={onReferenceSelectionChanged}
          selectedReferenceType={cleanedSelectedReferenceType}
        />
      )}
    </>
  )
}

ReferencesSelectionComponent.propTypes = {
  references: referencesPropTypes,
  onReferencesChanged: PropTypes.func.isRequired,
  disableInput: PropTypes.bool,
  renderDirection: PropTypes.oneOf(Object.values(ReferencesSelectionComponentRenderDirections)),
}

export default ReferencesSelectionComponent
