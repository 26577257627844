import { BusyIndicatorSize } from '@fioneer/ui5-webcomponents-react'
import { Suspense, lazy, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import OpenInBcaButton from 'components/domains/deals/financing/bank-customer-accounts/details/OpenInBcaButton'
import LazyLoadingFallbackCard from 'components/ui/card/LazyLoadingFallbackCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useBankCustomerAccountDetails from 'hooks/services/deals/financing/bank-customer-accounts/useBankCustomerAccountDetails'
import lazyRetry from 'lazyRetry'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'
import paths from 'routes/paths'

const BcaLimitsCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "BcaLimitsCard" */ 'components/domains/deals/financing/bank-customer-accounts/details/BcaLimitsCard'
      ),
    'BcaLimitsCard',
  ),
)
const BankCustomerAccountGeneralInformationCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "BankCustomerAccountGeneralInformationCard" */ 'components/domains/deals/financing/bank-customer-accounts/details/BankCustomerAccountGeneralInformationCard'
      ),
    'BankCustomerAccountGeneralInformationCard',
  ),
)

const BankCustomerAccountInterestInformationCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "BankCustomerAccountInterestInformationCard" */ 'components/domains/deals/financing/bank-customer-accounts/details/BankCustomerAccountInterestInformationCard'
      ),
    'BankCustomerAccountInterestInformationCard',
  ),
)
const BankCustomerAccountCustomFieldsCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "BankCustomerAccountCustomFieldsCard" */ 'components/domains/deals/financing/bank-customer-accounts/details/BankCustomerAccountCustomFieldsCard'
      ),
    'BankCustomerAccountCustomFieldsCard',
  ),
)

const DealFinancingBankCustomerAccountDetails = () => {
  const { t } = useTranslation()
  const { deal: { displayId } = {} } = useContext(DealContext)
  const { externalAccountNumber } = useParams()
  const { data, isFetching, isError } = useBankCustomerAccountDetails({
    bcaIdOrExternalAccountNumber: externalAccountNumber,
  })

  const breadcrumbs = useMemo(
    () => [
      {
        text: t('pages.deals.breadcrumbs.financing'),
        href: `/${paths.deals}/${displayId}/financing/`,
      },
      {
        text: `${t('pages.deals.breadcrumbs.account')}: ${externalAccountNumber}`,
      },
    ],
    [externalAccountNumber, displayId, t],
  )

  const actions = useMemo(
    () => [
      <RequestStateResolver
        key={'open-in-sap-bca-button'}
        isLoading={isFetching}
        isError={isError}
        errorToDisplay={<></>}
        busyIndicatorSize={BusyIndicatorSize.Small}
        renderContent={() => (
          <OpenInBcaButton
            externalAccountNumber={data?.externalAccountNumber}
            bankArea={data?.bankArea}
          />
        )}
      />,
    ],
    [data?.bankArea, data?.externalAccountNumber, isError, isFetching],
  )

  return (
    <DealDetailsWrapper childBreadcrumbs={breadcrumbs} actions={actions}>
      <CWPLayout sixColumns>
        <CWPLayout.Half>
          <Suspense
            fallback={
              <LazyLoadingFallbackCard data-testid="BankCustomerAccountGeneralInformationCard-fallback" />
            }
          >
            <BankCustomerAccountGeneralInformationCard />
          </Suspense>
        </CWPLayout.Half>
        <CWPLayout.Half>
          <Suspense fallback={<LazyLoadingFallbackCard data-testid="BcaLimitsCard-fallback" />}>
            <BcaLimitsCard />
          </Suspense>
        </CWPLayout.Half>
        <CWPLayout.Half>
          <Suspense
            fallback={
              <LazyLoadingFallbackCard data-testid="BankCustomerAccountInterestInformationCard-fallback" />
            }
          >
            <BankCustomerAccountInterestInformationCard />
          </Suspense>
        </CWPLayout.Half>
        <CWPLayout.Half>
          <Suspense
            fallback={
              <LazyLoadingFallbackCard data-testid="BankCustomerAccountCustomFieldsCard-fallback" />
            }
          >
            <BankCustomerAccountCustomFieldsCard />
          </Suspense>
        </CWPLayout.Half>
      </CWPLayout>
    </DealDetailsWrapper>
  )
}

export default DealFinancingBankCustomerAccountDetails
