import {
  Button,
  ButtonDesign,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
} from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { valuationStatus } from 'api/property/valuation/valuationRequests'
import { useValuationRequestsPatchReport } from 'components/domains/properties/valuation/table/table-cells/edit/useValuationRequestsPatchReport'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'
import usePropertyEditValuation from 'hooks/services/properties/valuations/usePropertyEditValuation'

const confirmationDialogState = {
  DRAFT: 'table.edit.confirmation-message.status-draft.description',
  COMPLETED: 'table.edit.confirmation-message.status-completed.description',
  CANCELLED: 'table.edit.confirmation-message.status-cancelled.description',
  OTHER: 'table.edit.confirmation-message.status-other.description',
}

/**
 *  @typedef {PropType.InferProps<typeof ValuationRequestsTableSaveButton.propTypes>} ValuationRequestsTableSaveButton
 */
const ValuationRequestsTableSaveButton = ({
  editValuation,
  originalStatus,
  disabled,
  onSaveSuccess,
  children,
  ...props
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property.valuation.requests' })
  const { t: tNoPrefix } = useTranslation()

  const [confirmationDialogStatus, setConfirmationDialogStatus] = useState('')
  const showMessageBox = Modals.useShowMessageBox()

  const handleReportUpdateEntityRefs = useValuationRequestsPatchReport({
    valuationRequestId: editValuation.id,
    onSuccess: () => {
      onSaveSuccess?.()
    },
    onError: () => {
      showMessageBox({
        titleText: t('patch-error.title'),
        children: t('patch-error.description'),
        type: MessageBoxTypes.Error,
        actions: [MessageBoxActions.Retry, MessageBoxActions.Cancel],
        onClose: ({ detail: { action } }) => {
          if (action === MessageBoxActions.Retry) {
            handleReportUpdateEntityRefs(editValuation.valuationReport)
          }
        },
      })
    },
  })

  const { mutate } = usePropertyEditValuation({
    onSuccess: () => {
      onSaveSuccess?.()
    },
    onError: () => {
      showMessageBox({
        titleText: t('patch-error.title'),
        children: t('patch-error.description'),
        type: MessageBoxTypes.Error,
        actions: [MessageBoxActions.Retry, MessageBoxActions.Cancel],
        onClose: ({ detail: { action } }) => {
          if (action === MessageBoxActions.Retry) {
            mutate(editValuation)
          }
        },
      })
    },
  })
  const handleConfirmation = useCallback(() => {
    mutate(editValuation)
    handleReportUpdateEntityRefs(editValuation.valuationReport)
    setConfirmationDialogStatus('')
  }, [editValuation, handleReportUpdateEntityRefs, mutate])

  const handleConfirmationCancel = useCallback(({ detail: { action } }) => {
    if (action !== 'cancel') {
      return
    }
    setConfirmationDialogStatus('')
  }, [])

  const submit = useCallback(() => {
    if (originalStatus === editValuation.status) {
      mutate(editValuation)
      handleReportUpdateEntityRefs(editValuation.valuationReport)
      return
    }

    if (
      originalStatus === valuationStatus.draft &&
      editValuation.status === valuationStatus.created
    ) {
      setConfirmationDialogStatus(confirmationDialogState['DRAFT'])
    } else if (editValuation.status === valuationStatus.cancelled) {
      setConfirmationDialogStatus(confirmationDialogState['CANCELLED'])
    } else if (editValuation.status === valuationStatus.completed) {
      setConfirmationDialogStatus(confirmationDialogState['COMPLETED'])
    } else {
      setConfirmationDialogStatus(confirmationDialogState['OTHER'])
    }
  }, [editValuation, handleReportUpdateEntityRefs, mutate, originalStatus])

  return (
    <>
      <Button disabled={disabled} design={ButtonDesign.Emphasized} onClick={submit} {...props}>
        {children}
      </Button>
      <ConfirmationDialog
        isOpen={!!confirmationDialogStatus}
        setIsOpen={handleConfirmationCancel}
        handleConfirmation={handleConfirmation}
        handleCancel={handleConfirmationCancel}
        confirmationMessage={t(confirmationDialogStatus)}
        confirmationTitle={t('table.edit.confirmation-message.title')}
        confirmButtonText={tNoPrefix('buttons.confirm')}
      />
    </>
  )
}

const valuationRequestPropType = PropType.shape({
  id: PropType.string.isRequired,
  reason: PropType.string,
  propertyId: PropType.string.isRequired,
  valuationType: PropType.string,
  status: PropType.string.isRequired,
  note: PropType.string,
  reportDate: PropType.oneOfType([PropType.instanceOf(Date), PropType.string]),
  valuer: PropType.string,
  valuationReport: PropType.shape({
    report: PropType.shape({
      id: PropType.string,
    }),
    operation: PropType.string,
  }),
})

ValuationRequestsTableSaveButton.propTypes = {
  originalStatus: PropType.string,
  editValuation: valuationRequestPropType,
  disabled: PropType.bool,
  onSaveSuccess: PropType.func,
  children: PropType.oneOfType([PropType.object, PropType.string, PropType.array]),
}
export default ValuationRequestsTableSaveButton
