import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import { Icon, MultiInput, SuggestionItem, Token } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { useMemo, useRef, useState } from 'react'
import BusinessPartnerSearchDialog from 'components/domains/business-partners/BusinessPartnerSearchDialog'
import styles from 'components/domains/deals/covenants/create-dialog/BusinessPartnerSearchInput.module.css'
import { useMatchingBusinessPartnersThrottled } from 'components/domains/deals/creation-dialog/BusinessPartnerAutocompleteInput'
import { useBusinessPartnersByIds } from 'hooks/services/business-partners/getBusinessPartners'
import useRoleConfig, { RoleConfig } from 'hooks/services/deals/configurations/useRoleConfig'

const DEFAULT_THROTTLE_TIMEOUT = 500
const EMPTY = ''

const BusinessPartnerSearchMultiInput = ({
  onChange,
  onBlur,
  throttleTimeout = DEFAULT_THROTTLE_TIMEOUT,
  additionalSearchParams = {},
  value = [],
  ...props
}) => {
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false)
  const { data: covenantRoles = [] } = useRoleConfig(RoleConfig.COVENANT)
  const multiInputRef = useRef(null)

  const searchParams = useMemo(
    () => ({ ...additionalSearchParams, roles: covenantRoles }),
    [additionalSearchParams, covenantRoles],
  )
  const { data, setSearch } = useMatchingBusinessPartnersThrottled(throttleTimeout, searchParams)
  const suggestions = data.map(({ name, id }) => ({ text: name, additionalText: id }))

  const { data: businessPartners = [] } = useBusinessPartnersByIds(value, {
    enabled: value.length > 0,
  })

  const clearInputValue = () => {
    if (multiInputRef?.current) {
      multiInputRef.current.value = EMPTY
    }
  }

  const handleOnBlur = () => {
    clearInputValue()
    onBlur()
  }

  const onInput = (event) => {
    setSearch(event.target.value)
  }

  const onSuggestionItemSelect = (event) => {
    onChange([...value, event.detail.item.id])
    clearInputValue()
  }

  const handleTokenDelete = (event) => {
    const deletedId = event.detail.token.id
    const idsWithoutSelected = value.filter((id) => id !== deletedId)
    onChange(idsWithoutSelected)
  }

  const getDisplayText = (id) => {
    const businessPartner = businessPartners.find(({ id: bpId }) => id === bpId) ?? {}
    return `${businessPartner.fullName} (${id})`
  }

  const handleIconClick = () => setIsSearchDialogOpen(true)
  const handleSearchDialogSelectionChange = (selectedBusinessPartners) => {
    onChange(selectedBusinessPartners.map(({ id }) => id))
  }

  const mappedBusinessPartners = businessPartners.map((businessPartner) => ({
    ...businessPartner,
    name: businessPartner.fullName,
  }))
  return (
    <>
      <MultiInput
        id="business-partner-multi-input"
        ref={multiInputRef}
        showSuggestions
        className={styles.fullWidth}
        onTokenDelete={handleTokenDelete}
        icon={
          <Icon
            id="open-search-dialog"
            name="value-help"
            onClick={handleIconClick}
            className={styles.actionIcon}
          />
        }
        onInput={onInput}
        onSuggestionItemSelect={onSuggestionItemSelect}
        tokens={value.map((id) => (
          <Token key={id} id={id} text={getDisplayText(id)} />
        ))}
        onBlur={handleOnBlur}
        {...props}
        value={EMPTY} // has to be set to an empty value, otherwise the id-array is set and displayed as text
      >
        {suggestions.map(({ text, additionalText }, index) => (
          <SuggestionItem
            text={text}
            additionalText={additionalText}
            id={additionalText}
            data-key={index}
            key={index}
          />
        ))}
      </MultiInput>
      {isSearchDialogOpen && (
        <BusinessPartnerSearchDialog
          open={isSearchDialogOpen}
          isMultiSelect={true}
          withCreateOption={false}
          onChange={handleSearchDialogSelectionChange}
          onClose={() => setIsSearchDialogOpen(false)}
          initialBusinessPartners={mappedBusinessPartners}
          initialRoles={covenantRoles}
        />
      )}
    </>
  )
}

BusinessPartnerSearchMultiInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  throttleTimeout: PropTypes.number,
  additionalSearchParams: PropTypes.shape({ [PropTypes.string]: PropTypes.string }),
  value: PropTypes.arrayOf(PropTypes.string),
  isInvalid: PropTypes.bool,
}

export default BusinessPartnerSearchMultiInput
