import PropTypes from 'prop-types'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/critical-facility/shared/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/critical-facility/shared/DisplayCardViewItemView'

const dash = '-'

const AnnualReviewDealOverviewBorrowerOverviewLabeledValue = ({ label, value }) => (
  <DisplayCardViewItemView>
    <DisplayCardViewInfoItemView label={label} value={value ?? dash} />
  </DisplayCardViewItemView>
)

AnnualReviewDealOverviewBorrowerOverviewLabeledValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export default AnnualReviewDealOverviewBorrowerOverviewLabeledValue
