import { TextAlign } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/ratings/BusinessPartnerExternalRatingsTile.module.css'
import { externalRatings as externalRatingsPropTypes } from 'components/domains/business-partners/tile/ratings/propTypes'
import Card from 'components/ui/card/Card'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

export const BUSINESSPARTNER_EXTERNAL_RATINGS_ACTION_TYPES = ['CONFIRM', 'UPGRADE', 'DENY']

const BusinessPartnerExternalRatingsTile = ({ ratings }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner.ratings' })
  const { t: translationWithoutPrefix } = useTranslation()
  const { format: dateFormat } = useShortDateFormatter()

  //This is needed since there are inconsistencies in s4
  const formatToBooleanValues = (value) => {
    switch (value) {
      case 'X':
        return translationWithoutPrefix(`formatters.boolean.yes`)
      case '':
        return translationWithoutPrefix(`formatters.boolean.no`)
      default:
        return translationWithoutPrefix(`formatters.boolean.empty-value`)
    }
  }

  const columnDefinitions = [
    { columnKey: 'agency', title: t('external.agency'), filter: filterTypes.CONTAINS },
    { columnKey: 'grade', title: t('external.grade'), filter: filterTypes.CONTAINS },
    { columnKey: 'outlook', title: t('external.outlook'), filter: filterTypes.CONTAINS },
    {
      columnKey: 'actionType',
      title: t('external.action-type'),
      filter: filterTypes.OF_ENUM_TYPE,
      additionalFilterOptions: {
        enumValues: BUSINESSPARTNER_EXTERNAL_RATINGS_ACTION_TYPES.map((actionType) =>
          t(`external.action-type.${actionType}`, { defaultValue: actionType }),
        ).reduce((acc, val) => ({ ...acc, [val]: val }), {}),
      },
    },
    {
      columnKey: 'validFrom',
      title: t('external.valid-from'),
      alignment: TextAlign.End,
      filter: filterTypes.BETWEEN_DATES,
    },
    {
      columnKey: 'validTo',
      title: t('external.valid-to'),
      alignment: TextAlign.End,
      filter: filterTypes.BETWEEN_DATES,
      isVisible: false,
    },
    {
      columnKey: 'ratingInherited',
      title: t('internal.ratingInherited'),
      filter: filterTypes.OF_ENUM_TYPE,
      alignment: TextAlign.End,
      additionalFilterOptions: {
        enumValues: ['X', ''].map((value) => formatToBooleanValues(value)),
      },
      isVisible: false,
    },
    {
      columnKey: 'ratingInheritedFrom',
      title: t('internal.ratingInheritedFrom'),
      filter: filterTypes.CONTAINS,
      alignment: TextAlign.End,
      isVisible: false,
    },
  ]
  const tableData = ratings.map(
    ({ agency, grade, outlook, actionType, validFrom, validTo, customAttributes }) => {
      const ratingInherited = formatToBooleanValues(customAttributes?.ratingInherited)
      const ratingInheritedFrom = customAttributes?.ratingInheritedFrom

      return {
        rowKey: agency.id + validFrom,
        agency: { value: agency.name, cellComponent: agency.name },
        grade: { value: grade, cellComponent: grade },
        outlook: { value: outlook.name, cellComponent: outlook.name },
        actionType: {
          value: t(`external.action-type.${actionType}`, { defaultValue: actionType }),
          cellComponent: t(`external.action-type.${actionType}`, { defaultValue: actionType }),
        },
        validFrom: {
          value: validFrom, // use date in format YYYY-MM-DD for sorting
          cellComponent: dateFormat(validFrom),
        },
        validTo: {
          value: validTo, // use date in format YYYY-MM-DD for sorting
          cellComponent: dateFormat(validTo),
        },
        ratingInherited: {
          value: ratingInherited,
          cellComponent: ratingInherited,
        },
        ratingInheritedFrom: {
          value: ratingInheritedFrom,
          cellComponent: ratingInheritedFrom,
        },
      }
    },
  )
  return (
    <Card className={styles.card}>
      <div className={styles.tableWrapper}>
        <SortedTable
          columnDefinitions={columnDefinitions}
          tableData={tableData}
          toolbarConfig={{
            sorting: { columnKey: 'validFrom', isSortingAscending: false },
            title: t('external'),
          }}
          noDataText={t('external.no-data')}
        />
      </div>
    </Card>
  )
}

BusinessPartnerExternalRatingsTile.propTypes = {
  ratings: externalRatingsPropTypes.isRequired,
}

export default BusinessPartnerExternalRatingsTile
