import { useContext } from 'react'
import CheckResultsCard from 'components/domains/deals/covenants/check-results-card/CheckResultsCard'
import MonitoringItemsForDealCard from 'components/domains/deals/covenants/monitoring-items-card-deal/MonitoringItemsForDealCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import { DealContext } from 'routes/deals/DealContext'

const DealCovenantsResults = () => {
  const { deal } = useContext(DealContext)
  return (
    <CWPLayout>
      <CWPLayout.Full>
        <CheckResultsCard dealUuid={deal.dealUuid} />
      </CWPLayout.Full>
      <CWPLayout.Full>
        <MonitoringItemsForDealCard dealUuid={deal.dealUuid} />
      </CWPLayout.Full>
    </CWPLayout>
  )
}

DealCovenantsResults.propTypes = {}

export default DealCovenantsResults
