import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateCovenant = ({ onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()

  return useMutation(
    async ({ covenantCheckUuid, status }) => {
      const response = await put({
        path: `/covenants/covenant-checks/${covenantCheckUuid}/status`,
        body: snakecaseKeys(status, { deep: true }),
      })
      return { ...response, data: camelize(response?.data) }
    },
    { onSuccess, onError },
  )
}

export default useUpdateCovenant
