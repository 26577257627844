import { useState, useEffect } from 'react'
import {
  KEY_DOWN,
  KEY_UP,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/constants/keys'

const useCtrlOrMetaKeyPressed = () => {
  const [isCtrlOrMetaPressed, setIsCtrlOrMetaPressed] = useState(false)

  const handleKeyChange = (event) => {
    setIsCtrlOrMetaPressed(event.ctrlKey || event.metaKey)
  }

  useEffect(() => {
    window.addEventListener(KEY_DOWN, handleKeyChange)
    window.addEventListener(KEY_UP, handleKeyChange)

    return () => {
      window.removeEventListener(KEY_DOWN, handleKeyChange)
      window.removeEventListener(KEY_UP, handleKeyChange)
    }
  }, [])

  return isCtrlOrMetaPressed
}

export default useCtrlOrMetaKeyPressed
