import { MessageStripDesign, ObjectPageSection } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import CloseableMessageStrip from 'components/ui/feedback/CloseableMessageStrip'
import TabPage from 'components/ui/page/TabPage'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { ConfigContext } from 'hooks/config/useConfig'
import useBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationships'
import useGroupExposureById from 'hooks/services/business-partners/risk-figures/useGroupExposureById'
import { useRiskFigureTypes } from 'hooks/services/business-partners/risk-figures/useRiskFigureTypes'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import BusinessPartnerGroupExposure from 'routes/business-partners/BusinessPartnerGroupExposure'
import styles from 'routes/business-partners/BusinessPartnerGroupExposures.module.css'
import { mapGroupExposureToTableData } from 'routes/business-partners/mapGroupExposureToTableData'

export const columnTypes = ['current', 'gfe', 'gfel', 'nfe', 'sr', 'srl']

const BusinessPartnerGroupExposures = () => {
  const { t } = useTranslation()
  const businessPartner = useContext(BusinessPartnerContext)
  const { data: { unitRelationships = [] } = {} } = useBusinessPartnerRelationships(
    businessPartner.id,
  )

  const {
    businessPartner: {
      sapRelationshipIds: {
        borrower: borrowerRelationshipId,
        reviewUnit: reviewUnitRelationshipId,
      } = {},
    } = {},
  } = useContext(ConfigContext)

  const { data: groupExposure, isLoading, isError } = useGroupExposureById(businessPartner.id)
  const { data: riskFigureTypes, isFetching: isRiskFigureTypesLoading } = useRiskFigureTypes()

  const mappedExposure = useMemo(
    () => mapGroupExposureToTableData(groupExposure, riskFigureTypes),
    [groupExposure, riskFigureTypes],
  )

  const isBorrowerUnitRelationship = useCallback(
    (relationshipId) => !isNil(borrowerRelationshipId) && relationshipId === borrowerRelationshipId,
    [borrowerRelationshipId],
  )

  const isReviewUnitRelationship = useCallback(
    (relationshipId) =>
      !isNil(reviewUnitRelationshipId) && relationshipId === reviewUnitRelationshipId,
    [reviewUnitRelationshipId],
  )

  const isHeadOfBorrowerUnit = useCallback(
    () =>
      unitRelationships.some(
        ({ id, relationships }) =>
          isBorrowerUnitRelationship(id) &&
          relationships?.some(({ head }) => head.id === businessPartner.id),
      ),
    [businessPartner.id, isBorrowerUnitRelationship, unitRelationships],
  )

  const isHeadOfReviewUnit = useCallback(
    () =>
      unitRelationships.some(
        ({ id, relationships }) =>
          isReviewUnitRelationship(id) &&
          relationships?.some(({ head }) => head.id === businessPartner.id),
      ),
    [businessPartner.id, isReviewUnitRelationship, unitRelationships],
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={businessPartner.id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [businessPartner.id],
  )

  return (
    <TabPage
      isLoading={isLoading || isRiskFigureTypesLoading}
      isError={isError}
      headerTitle={
        <BusinessPartnerHeader
          breadcrumbs={[{ text: t('navigation.item.title.business-partner-group-exposure') }]}
          actions={
            <>
              <CreateBusinessPartnerEventAction />, {markFavoriteAction}
            </>
          }
        />
      }
    >
      {Object.entries(mappedExposure ?? {}).map(([internalName, ratingGroup]) => (
        <ObjectPageSection key={internalName} id={internalName} titleText={ratingGroup.displayName}>
          {isHeadOfBorrowerUnit() || isHeadOfReviewUnit() ? (
            <>
              <CloseableMessageStrip
                design={MessageStripDesign.Information}
                className={styles.messageStripe}
              >
                {t('pages.business-partner.group-exposures.business-partner-is-a-unit-head')}{' '}
                <Link
                  to={`/business-partners/${businessPartner.id}/relationships`}
                  className={styles.link}
                >
                  {t('pages.business-partner.group-exposures.see-relationships')}
                </Link>
              </CloseableMessageStrip>
              <BusinessPartnerGroupExposure
                groupExposure={ratingGroup.headRiskFigures}
                businessPartnerTableData={ratingGroup.tableData}
                classTypes={groupExposure.classTypes}
                internalName={internalName}
              />
            </>
          ) : (
            <CloseableMessageStrip design={MessageStripDesign.Negative} hideCloseButton={true}>
              {t('pages.business-partner.group-exposures.business-partner-is-no-unit-head')}{' '}
              <Link
                to={`/business-partners/${businessPartner.id}/relationships`}
                className={styles.link}
              >
                {t('pages.business-partner.relationships')}
              </Link>
            </CloseableMessageStrip>
          )}
        </ObjectPageSection>
      ))}
    </TabPage>
  )
}

export default BusinessPartnerGroupExposures
