import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash.clonedeep'
import { useTranslation } from 'react-i18next'
import useEnrichDueDate from 'components/domains/business-partners/periodical-checks/next-periodical-check/useEnrichDueDate'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import useUpdateMonitoringItem from 'hooks/services/deals/covenants/monitorings/useUpdateMonitoringItem'

const useUpdatePeriodicalCheckMonitoringItem = ({
  setIsEditMode,
  setIsFormInitialized,
  monitoringItem,
  covenantCheckUuid,
}) => {
  const { covenantMonitoringItemUuid, covenant: { covenantUuid } = {} } = monitoringItem ?? {}

  const { t } = useTranslation()
  const showErrorMessageBox = useShowErrorMessageBox()
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const { subtractBaseDataFromDate } = useEnrichDueDate()

  const onError = async (error) => {
    const errorResponse = await error.response.json()
    showErrorMessageBox({
      message: t('components.cards.save-error'),
      error: errorResponse,
    })
  }
  const onSuccess = () => {
    showToast(
      {
        children: t('pages.business-partner.periodical-checks.general-information.update.success'),
      },
      document.body,
    )
    queryClient.invalidateQueries('covenants', covenantUuid, 'monitoring', 'items')
    queryClient.invalidateQueries('covenants', 'monitoring', 'items')
    queryClient.invalidateQueries('covenants', 'covenant-checks', covenantCheckUuid)
    setIsEditMode(false)
    setIsFormInitialized(false)
  }
  const updateItem = useUpdateMonitoringItem({ onSuccess, onError })

  const performUpdate = (formValues, checkDateBaseData) => {
    const updatedMonitoringItemBody = cloneDeep(formValues)

    if (formValues?.newCheckDueDate) {
      updatedMonitoringItemBody.newTargetDeliveryDate = subtractBaseDataFromDate(
        formValues.newCheckDueDate,
        checkDateBaseData,
      )
      delete updatedMonitoringItemBody.newCheckDueDate
    }

    updateItem.mutate({
      covenantUuid: covenantUuid,
      covenantMonitoringItemUuid: covenantMonitoringItemUuid,
      covenantMonitoringItem: updatedMonitoringItemBody,
    })
  }

  return { performUpdate }
}

export default useUpdatePeriodicalCheckMonitoringItem
