export const getCustomXAxisTick = (
  { x, y, index },
  years,
  formatDate,
  tickStyle,
  dayOne,
  lastDate,
) => {
  const isLastItem = index === years.length - 1
  const X_POS_OFFFSET = 16
  const LAST_TICK_X_POS_OFFFSET = -4
  const Y_POS_OFFFSET = 24
  const LAST_TICK_Y_POS_OFFFSET = 4

  const getXPosition = () => {
    if (isLastItem) return LAST_TICK_X_POS_OFFFSET

    return X_POS_OFFFSET
  }

  const getYPosition = () => {
    if (isLastItem) return LAST_TICK_Y_POS_OFFFSET

    return Y_POS_OFFFSET
  }

  const render = (label) => (
    <g transform={`translate(${x},${y})`}>
      <text
        className={tickStyle}
        x={getXPosition()}
        y={getYPosition()}
        dy={0}
        textAnchor="end"
        fill="#666"
        transform="rotate(-45)"
      >
        {label}
      </text>
    </g>
  )

  if (index === 0) return render(formatDate(dayOne))
  if (isLastItem) return render(formatDate(lastDate))

  return render(years[index])
}
