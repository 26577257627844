import { TextAlign, VerticalAlign } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AsSponsorCardTableCell from 'components/domains/business-partners/tile/sponsor/AsSponsorCardTableCell'

const useAsSponsorCardTableColumnDefinitions = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.sponsor.table',
  })

  //TODO: Eventually replace with a helper/hook to allow customizable ID patterns
  // Currently, this is explicitly non-numeric (so the option{ numeric: true } is missing),
  // to compare on a per-char basis
  const sortDealIds = (value1, value2) => value1.localeCompare(value2)

  return useMemo(
    () =>
      [
        {
          disableGroupBy: false,
          Header: t('borrower-label'),
          accessor: 'borrower.id',
          minWidth: 200,
          disableSortBy: false,
          sortType: (row1, row2) => {
            // Two reasons for this:
            // 1. We need to sort by borrower name instead of the default, the accessor, which is the borrower id
            // 2. Trimming is needed because (in this case) names can also have leading whitespace,
            // which flunks up sorting
            const value1 = row1?.original?.borrower?.name.trim() ?? ''
            const value2 = row2?.original?.borrower?.name.trim() ?? ''
            return value1.localeCompare(value2)
          },
        },
        {
          Header: t('deal-label'),
          accessor: 'deal',
          minWidth: 200,
          disableSortBy: false,
          sortType: (row1, row2) => {
            const value1 = row1?.original?.deal?.id ?? ''
            const value2 = row2?.original?.deal?.id ?? ''
            return sortDealIds(value1, value2)
          },
        },
        {
          Header: t('units-label'),
          accessor: 'units',
          minWidth: 200,
          disableSortBy: true,
        },
        {
          Header: t('creation-year-label'),
          accessor: 'creationYear',
          hAlign: TextAlign.Right,
          minWidth: 120,
          maxWidth: 150,
          disableGroupBy: false,
        },
        {
          Header: t('status-label'),
          accessor: 'status',
          minWidth: 100,
          maxWidth: 200,
          disableGroupBy: false,
          disableSortBy: false,
        },
        {
          Header: t('account-manager-label'),
          accessor: 'accountManager',
          minWidth: 150,
        },
        {
          Header: t('deal-commitment-label'),
          accessor: 'dealCommitment',
          hAlign: TextAlign.Right,
          minWidth: 200,
          maxWidth: 400,
        },
      ].map((element) => ({
        disableDragAndDrop: true,
        disableResizing: false,
        disableGroupBy: true,
        disableSortBy: true,
        Cell: (cellProps) =>
          AsSponsorCardTableCell({
            ...cellProps,
          }),
        Aggregated: (cellProps) =>
          AsSponsorCardTableCell({
            isAggregated: true,
            ...cellProps,
          }),
        vAlign: VerticalAlign.Middle,
        ...element,
      })),
    [t],
  )
}

export default useAsSponsorCardTableColumnDefinitions
