import { useQuery } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDealsCollateralInformationByPropertyUuids = (propertyUuids = [], options = {}) => {
  const { post } = useAccessTokenRequest()

  return useCamelizedResponse(
    useQuery({
      queryKey: ['deals', 'properties', 'collateral', ...propertyUuids],
      refetchOnWindowFocus: false,
      queryFn: () =>
        post({
          path: `/deals/properties/collateral`,
          body: propertyUuids,
        }),
      retry: false,
      enabled: !isEmpty(propertyUuids),
      ...options,
    }),
  )
}

export default useDealsCollateralInformationByPropertyUuids
