import isEqual from 'lodash.isequal'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { conditionTypes } from 'api/conditions/conditions'
import ExternalConditionStandardListItemDialog from 'components/domains/conditions/dialogs/ExternalConditionStandardListItemDialog'
import { ConditionTablesGeneralPropTypes } from 'components/domains/conditions/overview/ConditionTablePropTypes'
import ConditionsTable from 'components/domains/conditions/overview/ConditionsTable'
import ConditionsTableToolbar from 'components/domains/conditions/overview/ConditionsTableToolbar'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import {
  conditionFilterPrefixes,
  conditionServiceTypes,
} from 'hooks/services/conditions/tables/useConditionFilterQuery'
import useConditionTableColumns from 'hooks/services/conditions/tables/useConditionTableColumns'
import useConditions from 'hooks/services/conditions/useConditions'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const allColumns = [
  'name',
  'refNumber',
  'category',
  'type',
  'references',
  // Cell that is never displayed
  'covenantCheck',
  'visibilities',
  'assignee',
  'status',
  'watcher',
  'actions',
  // Cell that is never displayed
  'externalAssignee',
  // Cell that is never displayed
  'creationDate',
]

const subcomponentHeight = 220

const defaultHiddenColumns = ['creationDate', 'externalAssignee', 'covenantCheck']

const ConditionsExternalTable = ({
  totalNumberOfInternalConditions,
  totalNumberOfExternalConditions,
  switchActiveConditionSelection,
  currentlySelectedConditionType,
}) => {
  const {
    entityRef: { entityId, entityType },
  } = useContext(ConditionsContext)
  const { t } = useTranslation('translation', { keyPrefix: 'components.conditions.internal.table' })
  const navigate = useNavigate()
  const [queryParams] = useSearchParams()

  const {
    columnSelection,
    setColumnSelection,
    filters,
    setFilters,
    sortableColumns,
    sorting,
    onUpdateSorting,
    searchParam,
    setSearchParam,
    mappedBackendFilters,
  } = useConditionTableColumns({
    shownColumns: allColumns,
    defaultHiddenColumns,
    conditionType: conditionTypes.external,
    entityType,
  })

  const {
    isLoading,
    isError,
    data: externalConditionsResult,
    fetchNextPage,
  } = useConditions(conditionTypes.external, { entityId, entityType }, mappedBackendFilters, {
    sortBy: sorting.sortingKey,
    orderBy: sorting.sortDescending ? 'desc' : 'asc',
  })

  const isFilterOrSearchApplied = useMemo(
    () =>
      filters.filter(({ value, emptyValue }) => !!value && !isEqual(value, emptyValue)).length !==
        0 || searchParam !== '',
    [filters, searchParam],
  )

  const isWorkingVersion = useMemo(
    () => queryParams.get('working-version') === 'true',
    [queryParams],
  )

  const handleOnRowClick = useCallback(
    (event) => {
      navigate({
        hash: `${conditionServiceTypes.requirements}`,
        search: `?${conditionFilterPrefixes.requirements}externalConditionId=${event.detail.row.original.id}&working-version=${isWorkingVersion}`,
      })
    },
    [isWorkingVersion, navigate],
  )

  const renderExternalConditionsTable = useCallback(
    () => (
      <>
        <ConditionsTableToolbar
          totalNumberOfInternalConditions={totalNumberOfInternalConditions}
          totalNumberOfExternalConditions={
            externalConditionsResult?.total ?? totalNumberOfExternalConditions
          }
          switchActiveConditionSelection={switchActiveConditionSelection}
          currentlySelectedConditionType={currentlySelectedConditionType}
          columnSelection={columnSelection}
          setColumnSelection={setColumnSelection}
          filters={filters}
          setFilters={setFilters}
          searchParam={searchParam}
          onUpdateSearchParam={setSearchParam}
          sorting={{
            columnKey: sorting.sortBy,
            sortBy: sorting.sortingKey,
            isSortingAscending: !sorting.sortDescending,
            sortableColumns,
            onUpdateSorting,
          }}
          mappedBackendFilters={mappedBackendFilters}
        />
        <ConditionsTable
          isLoading={isLoading}
          conditions={externalConditionsResult?.externalConditions}
          columns={columnSelection}
          isFilterOrSearchApplied={isFilterOrSearchApplied}
          fetchNextPage={fetchNextPage}
          conditionType={conditionTypes.external}
          onRowClick={handleOnRowClick}
          subcomponentHeight={subcomponentHeight}
        />
        <ExternalConditionStandardListItemDialog />
      </>
    ),
    [
      totalNumberOfInternalConditions,
      totalNumberOfExternalConditions,
      switchActiveConditionSelection,
      currentlySelectedConditionType,
      externalConditionsResult,
      columnSelection,
      isFilterOrSearchApplied,
      setColumnSelection,
      filters,
      setFilters,
      searchParam,
      setSearchParam,
      sortableColumns,
      sorting,
      onUpdateSorting,
      isLoading,
      fetchNextPage,
      mappedBackendFilters,
      handleOnRowClick,
    ],
  )
  return (
    <RequestStateResolver
      isLoading={false}
      isError={isError}
      renderContent={renderExternalConditionsTable}
      errorToDisplay={
        <ErrorDataUnavailableInContent
          titleText={t('error.title')}
          subtitleText={t('error.description')}
        />
      }
    />
  )
}

ConditionsExternalTable.propTypes = ConditionTablesGeneralPropTypes

export default ConditionsExternalTable
