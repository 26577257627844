import {
  getConditionIndexFromId,
  getRequirementIndexFromId,
} from 'hooks/services/conditions/tables/useAnalyticalTableIndexHelper'

const filterForNonExistingConditionsOrRequirements = (conditions) => (selectedRowId) => {
  const conditionIndex = getConditionIndexFromId(selectedRowId)
  const requirementIndex = getRequirementIndexFromId(selectedRowId)

  if (!conditions[conditionIndex]) {
    return false
  }
  if (requirementIndex === -1) {
    return true
  }
  if (!conditions[conditionIndex].requirements[requirementIndex]) {
    return false
  }
  return true
}

const reduceToObject = (acc, currentKey) => ({ ...acc, [currentKey]: true })

export const filterSelectedRowsForNotAvailableRows = ({ selectedRows, conditions }) =>
  Object.keys(selectedRows)
    .filter(filterForNonExistingConditionsOrRequirements(conditions))
    .reduce(reduceToObject, {})

export const increaseConditionIndexOfCurrentlySelectedRows = ({ selectedRows, conditions }) =>
  Object.keys(selectedRows)
    .map((currentlySelectedRowId) => {
      const conditionIndexOfSelectedRow = getConditionIndexFromId(currentlySelectedRowId)
      const requirementIndexOfSelectedRow = getRequirementIndexFromId(currentlySelectedRowId)
      if (requirementIndexOfSelectedRow === -1) {
        return `${conditionIndexOfSelectedRow + 1}`
      }
      return `${conditionIndexOfSelectedRow + 1}.${requirementIndexOfSelectedRow}`
    })
    .filter(filterForNonExistingConditionsOrRequirements(conditions))
    .reduce(reduceToObject, {})

export const increaseConditionIndexOfCurrentErrorRows = ({ errorRows }) =>
  Object.entries(errorRows).reduce(
    (currentResultObject, [conditionIndex, conditionRowContent]) => ({
      ...currentResultObject,
      [+conditionIndex + 1]: conditionRowContent,
    }),
    {},
  )

export const increaseConditionIndexOfCurrentErrorRowsAfterConditionIndex = ({
  errorRows,
  conditionIndex,
}) =>
  Object.entries(errorRows).reduce(
    (currentResultObject, [currentConditionIndex, conditionRowContent]) => {
      if (currentConditionIndex < conditionIndex) {
        return { ...currentResultObject, [currentConditionIndex]: conditionRowContent }
      }
      return { ...currentResultObject, [+currentConditionIndex + 1]: conditionRowContent }
    },
    {},
  )

export const increaseRequirementIndexOfCurrentErrorRows = ({ errorRows, conditionIndex }) =>
  Object.entries(errorRows).reduce(
    (currentResultObject, [currentConditionIndex, conditionRowContent]) => {
      if (+currentConditionIndex === conditionIndex) {
        const resultingConditionRowContent = Object.entries(conditionRowContent).reduce(
          (currentResultingRowContent, [requirementIndexOrFieldName, fieldContent]) => {
            if (isNaN(requirementIndexOrFieldName)) {
              // fieldName, return directly
              return { ...currentResultingRowContent, [requirementIndexOrFieldName]: fieldContent }
            }
            return {
              ...currentResultingRowContent,
              [+requirementIndexOrFieldName + 1]: fieldContent,
            }
          },
          {},
        )
        return { ...currentResultObject, [+currentConditionIndex]: resultingConditionRowContent }
      }
      return { ...currentResultObject, [+currentConditionIndex]: conditionRowContent }
    },
    {},
  )

export const getConditionIndexesOfSelectedRows = (selectedRows) =>
  Object.keys(selectedRows)
    .filter((selectedRowId) => {
      const requirementIndexOfSelectedRow = getRequirementIndexFromId(selectedRowId)
      if (requirementIndexOfSelectedRow === -1) {
        return true
      }
      return false
    })
    .map((conditionIndex) => +conditionIndex)

export const increaseIndexForAllRequirementsBelowCondition = ({ selectedRows, conditionIndex }) =>
  Object.keys(selectedRows).reduce((acc, selectedRowId) => {
    const conditionIndexOfSelectedRow = getConditionIndexFromId(selectedRowId)
    const requirementIndexOfSelectedRow = getRequirementIndexFromId(selectedRowId)
    if (conditionIndexOfSelectedRow !== conditionIndex || requirementIndexOfSelectedRow === -1) {
      return { ...acc, [selectedRowId]: true }
    }
    return { ...acc, [`${conditionIndexOfSelectedRow}.${requirementIndexOfSelectedRow + 1}`]: true }
  }, {})

export const increaseAllSelectionsBelowConditionIndex = ({ selectedRows, conditionIndex }) =>
  Object.keys(selectedRows).reduce((acc, selectedRowId) => {
    const conditionIndexOfSelectedRow = getConditionIndexFromId(selectedRowId)
    const requirementIndexOfSelectedRow = getRequirementIndexFromId(selectedRowId)
    if (conditionIndexOfSelectedRow < conditionIndex) {
      return { ...acc, [selectedRowId]: true }
    }
    if (requirementIndexOfSelectedRow === -1) {
      return { ...acc, [conditionIndexOfSelectedRow + 1]: true }
    }
    return { ...acc, [`${conditionIndexOfSelectedRow + 1}.${requirementIndexOfSelectedRow}`]: true }
  }, {})

export const getDetailedErrorInformationFromErrorRows = ({ errorRows }) => {
  const resultingErrorArray = []
  Object.entries(errorRows).forEach(([conditionIndex, conditionRowContent]) => {
    Object.entries(conditionRowContent).forEach(([requirementIndexOrFieldName, fieldContent]) => {
      if (isNaN(requirementIndexOrFieldName)) {
        const resultingErrorObject = {
          isConditionError: true,
          conditionIndex: +conditionIndex,
          requirementIndex: -1,
          fieldName: requirementIndexOrFieldName,
          additionalInformation: fieldContent,
        }
        resultingErrorArray.push(resultingErrorObject)
        return
      }
      Object.entries(fieldContent).forEach(([requirementFieldName, requirementFieldContent]) => {
        const resultingErrorObject = {
          isConditionError: false,
          conditionIndex: +conditionIndex,
          requirementIndex: +requirementIndexOrFieldName,
          fieldName: requirementFieldName,
          additionalInformation: requirementFieldContent,
        }
        resultingErrorArray.push(resultingErrorObject)
      })
    })
  })
  return resultingErrorArray
}
