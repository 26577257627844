import { useQueries } from '@tanstack/react-query'
import camelize from 'camelize'
import * as i18next from 'i18next'
import uniq from 'lodash.uniq'
import { useCallback } from 'react'
import { isNotFoundError } from 'api/requests'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { entitiesQuantity } from 'components/ui/data/ReferenceEntitiesView'
import { ReferenceEntityType } from 'components/ui/data/ReferenceEntityType'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'
import paths from 'routes/paths'

/**
 * We need to fetch reference entities for all covenants.
 * The CovenantsTable does that with the ReferenceEntities component,
 * but in decision paper tiles, all data must be provided up front by
 * the hook and the tile component must not fetch anything.
 *
 * As data fetching is usually done via hooks, but hooks cannot be called
 * multiple times for an array of data, we must pull the data fetching
 * logic out of the hooks into an async function which can be called for
 * all covenants via `useQueries`.
 */
const fetchReferenceEntities = async (
  { type, ids, dealUuid, displayId, dealTranches },
  fetchData,
) => {
  const t = (key) => i18next.t(`pages.deals.covenants.${key}`)

  const covenantHasAllTranches =
    [ReferenceEntityType.TrancheNewBusiness, ReferenceEntityType.TrancheExistingBusiness].includes(
      type,
    ) && ids.length === dealTranches.length

  if (ids.length === 0 || covenantHasAllTranches) {
    return {
      quantity: entitiesQuantity.none,
    }
  }

  const trancheBasePath = `/${paths.deals}/${displayId}/${dealDetailPaths.financing}/tranches`
  const businessPartnersBasePath = `/${paths.businessPartners}`

  let dataSource = undefined
  if (type === ReferenceEntityType.TrancheNewBusiness) {
    dataSource = DATA_SOURCES.NEW_BUSINESS
  } else if (type === ReferenceEntityType.TrancheExistingBusiness) {
    dataSource = DATA_SOURCES.EXISTING_BUSINESS
  }

  const shouldFetchTranches = !!dataSource

  let tranches = []
  if (shouldFetchTranches) {
    try {
      tranches = (
        await fetchData(
          `/financing-structures/deals/${dealUuid}/tranches/mini?${new URLSearchParams(
            dataSource ? { dataSource } : undefined,
          )}`,
        )
      )?.tranchesMinis
    } catch (err) {
      if (!isNotFoundError(err)) throw err
    }
  }

  let mappedTranches
  if (type === ReferenceEntityType.TrancheNewBusiness) {
    mappedTranches = ids.map((id) => {
      const matchingTranche = tranches?.find(({ trancheId }) => id === trancheId) ?? {}
      return {
        id: matchingTranche.displayId,
        name: matchingTranche.trancheName || matchingTranche.displayId,
      }
    })
  }
  if (type === ReferenceEntityType.TrancheExistingBusiness) {
    mappedTranches = ids.map((id) => {
      const matchingTranche =
        tranches?.find(({ externalContractIds }) => externalContractIds?.includes(id)) ?? {}
      return {
        id: matchingTranche.externalContractIds?.[0],
        name: matchingTranche.externalContractIds?.[0],
      }
    })
  }
  mappedTranches = mappedTranches?.filter(({ id }) => !!id) ?? []

  const shouldFetchBusinessPartners = type === ReferenceEntityType.BusinessPartner

  const businessPartnerData = shouldFetchBusinessPartners
    ? await fetchData(`/businesspartners/ids?ids=${ids}`)
    : []

  const businessPartners = businessPartnerData.map(({ id, fullName }) => ({
    id,
    name: fullName,
  }))

  const referenceEntities = (() => {
    if (ids.length === 1) {
      switch (type) {
        case ReferenceEntityType.TrancheNewBusiness:
        case ReferenceEntityType.TrancheExistingBusiness: {
          const mappedTranche = mappedTranches[0]
          if (!mappedTranche) {
            return {
              quantity: entitiesQuantity.none,
            }
          }
          return {
            quantity: entitiesQuantity.single,
            id: mappedTranche.id,
            name: mappedTranche.name,
            link: `${trancheBasePath}/${mappedTranche.id}`,
          }
        }
        case ReferenceEntityType.BusinessPartner:
          return {
            quantity: entitiesQuantity.single,
            id: businessPartners[0]?.id,
            name: businessPartners[0]?.name,
            link: `${businessPartnersBasePath}/${businessPartners[0]?.id}`,
          }
        default:
          return {
            quantity: entitiesQuantity.none,
          }
      }
    }

    switch (type) {
      case ReferenceEntityType.TrancheNewBusiness:
      case ReferenceEntityType.TrancheExistingBusiness: {
        if (mappedTranches.length === 0) {
          return {
            quantity: entitiesQuantity.none,
          }
        }
        return {
          quantity: entitiesQuantity.multiple,
          linkText: t('tranches'),
          listItems: mappedTranches,
          linkBasePath: trancheBasePath,
        }
      }
      case ReferenceEntityType.BusinessPartner:
        return {
          quantity: entitiesQuantity.multiple,
          linkText: t('business-partners'),
          listItems: businessPartners,
          linkBasePath: businessPartnersBasePath,
        }
      default:
        return {
          quantity: entitiesQuantity.none,
        }
    }
  })()

  return referenceEntities
}

const useReferenceEntities = (
  { referenceEntities, dealUuid, displayId, dealTranches },
  options = {},
) => {
  const { get } = useAccessTokenRequest()

  const fetchData = useCallback(
    async (path) => {
      const response = await get({ path })
      return camelize(response.data)
    },
    [get],
  )

  const referenceEntitiesResults = useQueries({
    queries: referenceEntities.map(({ type, ids }) => {
      const uniqIds = uniq(ids)
      return {
        queryKey: ['referenceEntities', type, uniqIds],
        queryFn: () =>
          fetchReferenceEntities(
            { type, ids: uniqIds, dealUuid, displayId, dealTranches },
            fetchData,
          ),
        ...options,
      }
    }),
  })

  return useCombinedQueryResults(referenceEntitiesResults)
}

export default useReferenceEntities
