import PropTypes from 'prop-types'
import { useCallback } from 'react'
import LoadingSelect from 'components/ui/select/LoadingSelect'

const LoadingDropdownFilter = ({
  value,
  setSelectedFilters,
  columnKey,
  useLoadingHook,
  loadingHookParams,
  selectionName,
}) => {
  const onChange = useCallback(
    (_event, newValue) => {
      setSelectedFilters((currentlySelectedFilters) =>
        currentlySelectedFilters.map((filter) => {
          if (filter.columnKey !== columnKey) {
            return filter
          }
          return { ...filter, value: newValue }
        }),
      )
    },
    [columnKey, setSelectedFilters],
  )
  return (
    <LoadingSelect
      selectedKey={value}
      loadingHook={useLoadingHook}
      loadingHookParams={loadingHookParams}
      onChange={onChange}
      optionKeyName="code"
      optionDisplayName="name"
      selectionName={selectionName}
    />
  )
}

LoadingDropdownFilter.propTypes = {
  value: PropTypes.string.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  columnKey: PropTypes.string.isRequired,
  useLoadingHook: PropTypes.func.isRequired,
  loadingHookParams: PropTypes.object,
  selectionName: PropTypes.string.isRequired,
}

export default LoadingDropdownFilter
