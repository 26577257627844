import find from 'lodash.find'
import isEmpty from 'lodash.isempty'
import uniq from 'lodash.uniq'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useBusinessPartnersByIds } from 'hooks/services/business-partners/getBusinessPartners'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useCombinedUserFavoritesAndRecentVisits from 'hooks/services/user-profile/useCombinedUserFavoritesAndRecentVisits'

const getEntityIds = (items = []) => items.map((item) => item.entityId)
const getUniqueIds = (items = []) => uniq(getEntityIds(items))

const requestTypes = {
  NORMAL: 'normal',
  MINI: 'mini',
}

const mapBusinessPartnerToFavoriteAndRecentVisit = (favsAndRecVisits = [], businessPartners = []) =>
  favsAndRecVisits.map((favAndRecVisit) => {
    const foundBps = businessPartners.filter((bp) => bp.id === favAndRecVisit.entityId)
    const foundBp = find(foundBps, (bp) => bp.requestType === requestTypes.NORMAL)
    const foundBpMini = find(foundBps, (bp) => bp.requestType === requestTypes.MINI)

    if (!foundBp && !foundBpMini) {
      return {
        ...favAndRecVisit,
        notFound: true,
      }
    }

    if (!foundBp) {
      return {
        ...favAndRecVisit,
        ...foundBpMini,
        notFound: false,
      }
    }

    return {
      ...favAndRecVisit,
      ...foundBp,
      roles: foundBpMini.roles ?? [],
      notFound: false,
    }
  })

const mapRequestTypeToBps = (bps = [], requestType) => bps.map((bp) => ({ ...bp, requestType }))

const useGetAndEnrichFavoritesAndRecentVisitsWithBusinessPartners = () => {
  const getBusinessPartnersFavsAndRecVisits = useCombinedUserFavoritesAndRecentVisits({
    entityType: cwpEntityTypes.BUSINESS_PARTNER,
  })
  const uniqueBusinessPartnerIds = getUniqueIds(getBusinessPartnersFavsAndRecVisits.data)
  const getAllBusinessPartners = useBusinessPartnersByIds(uniqueBusinessPartnerIds)
  const getAllBusinessPartnersMini = useBusinessPartnerMiniByIds(uniqueBusinessPartnerIds)

  const isLoading =
    getBusinessPartnersFavsAndRecVisits.isLoading ||
    (getAllBusinessPartners.isLoading && !isEmpty(uniqueBusinessPartnerIds)) ||
    (getAllBusinessPartnersMini.isLoading && !isEmpty(uniqueBusinessPartnerIds))
  const isError =
    getBusinessPartnersFavsAndRecVisits.isError ||
    getAllBusinessPartners.isError ||
    getAllBusinessPartnersMini.isError
  const data =
    isLoading || isError
      ? undefined
      : mapBusinessPartnerToFavoriteAndRecentVisit(getBusinessPartnersFavsAndRecVisits.data, [
          ...mapRequestTypeToBps(getAllBusinessPartners.data, requestTypes.NORMAL),
          ...mapRequestTypeToBps(
            getAllBusinessPartnersMini.data?.businessPartnerMinis,
            requestTypes.MINI,
          ),
        ])

  return {
    isLoading,
    isError,
    data,
  }
}

export default useGetAndEnrichFavoritesAndRecentVisitsWithBusinessPartners
