import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const sortByTargetDate = (a, b) => {
  if (a.targetDate < b.targetDate) {
    return 1
  } else if (a.targetDate > b.targetDate) {
    return -1
  }
  return 0
}

const useMultipleGetMilestones = ({ dealUuids, options = {} }) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: dealUuids.map((dealUuid) => ({
        path: `/deals/${dealUuid}/milestones`,
        keys: ['deals', dealUuid, 'milestones'],
      })),
      translated: true,
      useCache: true,
      options: { enabled: !isEmpty(dealUuids), ...options },
    }),
  )

  return useMemo(
    () =>
      responses.map((response, index) => ({
        ...response,
        data: {
          dealUuid: dealUuids[index],
          milestones: camelize(response?.data?.milestones?.slice().sort(sortByTargetDate)),
        },
      })),
    [dealUuids, responses],
  )
}

export default useMultipleGetMilestones
