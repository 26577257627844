import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { conditionTypes } from 'api/conditions/conditions'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import useDeprecatedValues from 'hooks/common/useDeprecatedValues'
import useExternalTypes from 'hooks/services/conditions/config/useExternalTypes'
import useInternalTypes from 'hooks/services/conditions/config/useInternalTypes'
import { conditionTypeChanged } from 'redux/slices/conditions/conditionsTableSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const ConditionsTableTypeEditCell = ({ typeName, typeCode, conditionType }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.columns.type',
  })
  const {
    entityRef: { entityType },
  } = useContext(ConditionsContext)

  const dispatch = useDispatch()
  const editedConditionType = useSelector(
    (state) => state.conditions.conditionsTable.editedRow.currentValues?.type,
  )

  const { data: { types: availableExternalTypes = [] } = {}, ...useExternalTypesResult } =
    useExternalTypes(
      {
        includeDeprecated: false,
        entityType,
      },
      { enabled: conditionType === conditionTypes.external },
    )
  const { data: { types: availableInternalTypes = [] } = {}, ...useInternalTypesResult } =
    useInternalTypes(
      {
        includeDeprecated: false,
        entityType,
      },
      { enabled: conditionType === conditionTypes.internal },
    )

  const [availableTypes, useTypesResult] = useMemo(
    () =>
      conditionType === conditionTypes.external
        ? [availableExternalTypes, useExternalTypesResult]
        : [availableInternalTypes, useInternalTypesResult],
    [
      availableExternalTypes,
      availableInternalTypes,
      conditionType,
      useExternalTypesResult,
      useInternalTypesResult,
    ],
  )

  const { deprecatedValues } = useDeprecatedValues({
    availableValues: availableTypes,
    selectedValues: [{ name: typeName, code: typeCode }],
  })
  const typesData = useMemo(
    () => ({
      ...useTypesResult,
      data: {
        types: [...deprecatedValues, ...availableTypes],
      },
    }),
    [availableTypes, deprecatedValues, useTypesResult],
  )
  const typesLoader = useCallback(() => typesData, [typesData])

  const hasError = useSelector(
    (state) => !!state.conditions.conditionsTable.editedRow.errorFields?.type,
  )

  const onChange = useCallback(
    (_, newCode) => {
      dispatch(conditionTypeChanged({ type: newCode }))
    },
    [dispatch],
  )

  const errorValueStateMessage = useMemo(() => <Text>{t('error')}</Text>, [t])

  const hookInput = useMemo(
    () => ({
      entityType,
    }),
    [entityType],
  )

  return (
    <LoadingSelect
      emptyOptionText={t('fallback')}
      selectedKey={editedConditionType}
      loadingHook={typesLoader}
      loadingHookParams={hookInput}
      isErrorState={hasError}
      valueStateMessage={errorValueStateMessage}
      onChange={onChange}
      showEmptyOption={false}
      optionKeyName="code"
      optionDisplayName="name"
      selectionName="types"
    />
  )
}

ConditionsTableTypeEditCell.propTypes = {
  typeName: PropTypes.string.isRequired,
  typeCode: PropTypes.string.isRequired,
  conditionType: PropTypes.string.isRequired,
}

export default ConditionsTableTypeEditCell
