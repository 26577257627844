/**
 * @param {import("hooks/services/documents/documentsSchema").Documents} [root]
 * @returns {import("hooks/services/documents/documentsSchema").Document | undefined}
 */
const findDocument = (root) => {
  let document = root?.documents?.find((doc) => !!doc)
  if (document) return document

  for (const subFolder of root?.subFolders ?? []) {
    document = findDocument(subFolder)
    if (document) return document
  }
}

export default findDocument
