import { CardHeader } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/portfolio/overview/PropertyPortfolioInformationCard.module.css'
import { PropertyPortfolioOverviewCardItem } from 'components/domains/properties/portfolio/overview/PropertyPortfolioOverviewCardItem'
import Card from 'components/ui/card/Card'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import {
  useAreaMeasurementUnitFormatter,
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  usePercentageFormatter,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'
import useCurrentMultiPropertyKpis from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpis'

const PropertyPortfolioRentRollInfoCard = ({ properties, className = '' }) => {
  const { t: tRentRoll } = useTranslation('translation', {
    keyPrefix: 'pages.properties.portfolio.overview.rent-roll',
  })
  const { t } = useTranslation()
  const formatAreaUnit = useAreaMeasurementUnitFormatter()
  const formatCurrency = useCustomizableCurrencyFormatter()
  const { format: formatDate } = useShortDateFormatter()

  const formatPercent = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formatWault = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  // CWP-16174: Improve performance and decrease load on backend system by using common queries for multiple use cases
  const propertyUuids = properties.map(({ uuid }) => uuid)
  const {
    data: {
      keyDate,
      totalAreaSurface,
      totalNumberOfUnits: totalNoOfUnits,
      letSurface,
      annualizedCurrentRent,
      waultToBreakInYears: waultToBreak,
      waultToExpiryInYears: waultToExpiry,
    } = {},
    isLoading,
    isError,
  } = useCurrentMultiPropertyKpis(propertyUuids, {
    withCityGranularity: true,
    withCountryGranularity: true,
    withPropertyTypeGranularity: true,
    withTenantIndustryGranularity: true,
    withSegmentUsageTypeGranularity: true,
    withTegovaRatingGranularity: true,
  })

  const formattedKeyDate = useMemo(() => formatDate(keyDate), [formatDate, keyDate])
  const getTotalAreaSurfaceDisplayValue = useCallback(() => {
    if (totalAreaSurface?.value && totalAreaSurface?.measurementUnit) {
      return `${formatNumber(totalAreaSurface?.value)} ${formatAreaUnit(
        totalAreaSurface?.measurementUnit,
      )}`
    }
  }, [formatAreaUnit, formatNumber, totalAreaSurface?.measurementUnit, totalAreaSurface?.value])

  /**
   * In the future, there will be a util-function getYearOrYears.
   * When it is available, this util-function shall be used instead of the following function.
   */
  const getYearOrYears = useCallback(
    (value) => (value === 1 ? tRentRoll('year') : tRentRoll('years')),
    [tRentRoll],
  )

  const getYearAmountDisplayValue = useCallback(
    (yearAmount) => {
      if (yearAmount) {
        return `${formatWault(yearAmount)} ${getYearOrYears(yearAmount)}`
      }
    },
    [formatWault, getYearOrYears],
  )
  return (
    <Card
      header={<CardHeader titleText={tRentRoll('title')} />}
      className={`${styles.card} ${className}`}
    >
      <div className={styles.cardContentWrapper}>
        <LoadingStateWrapper
          isLoading={isLoading}
          isError={isError}
          loadingDescription={t('pages.properties.portfolio.overview.card-loading')}
        >
          <form className={styles.form}>
            <PropertyPortfolioOverviewCardItem
              label={tRentRoll('label.key-date')}
              value={formattedKeyDate}
            />
            <PropertyPortfolioOverviewCardItem
              label={tRentRoll('label.lettable-area')}
              value={getTotalAreaSurfaceDisplayValue()}
            />
            <PropertyPortfolioOverviewCardItem
              label={tRentRoll('label.parking-spots')}
              value={!isNaN(totalNoOfUnits) ? `${totalNoOfUnits}` : undefined}
            />
            <PropertyPortfolioOverviewCardItem
              label={tRentRoll('label.occupancy-rate')}
              value={formatPercent(letSurface?.percent)}
            />
            <PropertyPortfolioOverviewCardItem
              label={tRentRoll('label.current-rental-income')}
              value={formatCurrency(annualizedCurrentRent?.number, annualizedCurrentRent?.currency)}
            />
            <PropertyPortfolioOverviewCardItem
              label={tRentRoll('label.wault-break')}
              value={getYearAmountDisplayValue(waultToBreak)}
            />
            <PropertyPortfolioOverviewCardItem
              label={tRentRoll('label.wault-expiry')}
              value={getYearAmountDisplayValue(waultToExpiry)}
            />
          </form>
        </LoadingStateWrapper>
      </div>
    </Card>
  )
}

PropertyPortfolioRentRollInfoCard.propTypes = {
  properties: PropTypes.array,
  isLoadingProperties: PropTypes.bool,
  className: PropTypes.string,
}

export default PropertyPortfolioRentRollInfoCard
