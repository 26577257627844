import { CardHeader } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/deals/card/CardHeaderLastEditedText.module.css'
import useLastEditedTextByEmail from 'components/domains/deals/card/useLastEditedTextByEmail'

/**
 * https://fioneer.atlassian.net/browse/CWP-13200 | move to ui-folder
 */
export const CardHeaderLastEditedText = ({ title, email, timestamp, actions }) => {
  const { lastEditedText } = useLastEditedTextByEmail({ email, timestamp })

  return (
    <CardHeader
      className={styles['card-header']}
      titleText={title}
      subtitleText={lastEditedText}
      action={actions}
    />
  )
}
CardHeaderLastEditedText.propTypes = {
  title: PropTypes.string.isRequired,
  email: PropTypes.string,
  timestamp: PropTypes.string,
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.bool])),
    PropTypes.element,
    PropTypes.bool,
  ]),
}
