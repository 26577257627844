import { useMemo } from 'react'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useMultiplePropertyGranularityPieChart from 'hooks/services/pbb-custom-service/useMultiplePropertyGranularityPieChart'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const useMultipleAnnualReviewFurtherDetailsPieChartCardData = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { dealsData, dealUuids = [] } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const {
    data: multiplePropertyGranularityPieChartData = [],
    isLoading: isMultiplePropertyGranularityPieChartLoading,
    isError: isMultiplePropertyGranularityPieChartError,
  } = useCombinedQueryResults(
    useMultiplePropertyGranularityPieChart({
      dealUuids,
    }),
    { forceDataReturn: true },
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isAnnualReviewDealsLoading, isMultiplePropertyGranularityPieChartLoading],
    errorValues: [isAnnualReviewDealsError, isMultiplePropertyGranularityPieChartError],
    errorDetails: [annualReviewDealsError],
    tileId,
  })

  const sourcePaths = useMemo(
    () =>
      dealUuids?.map((_, index) => {
        const { isMultiProperty, multiPropertyViewId, propertyId } =
          multiplePropertyGranularityPieChartData?.[index] ?? {}
        return isMultiProperty
          ? `/properties/portfolio/rent-roll?portfolio-view-id=${multiPropertyViewId}`
          : `/properties/${propertyId}/rent-roll`
      }),
    [dealUuids, multiplePropertyGranularityPieChartData],
  )

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        multiplePropertyGranularityPieChartData,
        sourcePaths,
        sourceRender: { path: `${sourcePaths[0]}` },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealsData,
    multiplePropertyGranularityPieChartData,
    sourcePaths,
    error,
  ])
}

export default useMultipleAnnualReviewFurtherDetailsPieChartCardData
