import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateConditions = (options) => {
  const { post } = useAccessTokenRequest()
  return useMutation(async ({ conditions, conditionType }) => {
    const { data } = await post({
      path: `/conditions/${conditionType}`,
      body: snakecaseKeys(conditions),
    })
    return data
  }, options)
}

export default useCreateConditions
