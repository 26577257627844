import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Form,
  FormItem,
  Input,
  Label,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog, { DialogPrimaryButton, DialogSize } from 'components/ui/dialog/Dialog'
import EditableDatePickerComponent from 'components/ui/input/EditableDatePickerComponent'
import NumberInput from 'components/ui/input/NumberInput'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const MarketsKpiDialog = ({
  isOpen,
  setIsOpen,
  kpiName,
  onAddClick,
  kpiCurrentValue,
  kpiCurrentUnit,
  kpiKey,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.markets.detail.kpis.dialog' })
  const { parse, localePattern } = useShortDateFormatter()
  const [newKpiValue, setNewKpiValue] = useState('')
  const today = new Date().toISOString().substring(0, 10)
  const [newKpiDate, setNewKpiDate] = useState(today)

  const onAddClicked = () => {
    setIsOpen(false)
    onAddClick({ key: kpiKey, value: newKpiValue, validity_date: newKpiDate })
  }

  const handleClose = () => {
    setIsOpen(false)
    setNewKpiValue('')
    setNewKpiDate(today)
  }

  const onNewKpiValueChanged = (e) => {
    setNewKpiValue(e.target.value)
  }

  const handleNewKpiDateChanged = (e) => {
    if (!e.target.value) {
      setNewKpiDate('')
      return
    }
    const parsedKpiDate = parse(e.target.value, localePattern)
    setNewKpiDate(parsedKpiDate)
  }

  const isAddButtonDisabled = !newKpiValue || !newKpiDate

  return (
    <Dialog
      open={isOpen}
      headerText={t('title')}
      onAfterClose={handleClose}
      primaryButton={
        <DialogPrimaryButton onClick={onAddClicked} disabled={isAddButtonDisabled}>
          {t('buttons.add')}
        </DialogPrimaryButton>
      }
      size={DialogSize.S}
    >
      <FlexBox justifyContent={FlexBoxJustifyContent.Start}>
        <Form
          labelSpanL={12}
          labelSpanM={12}
          labelSpanXL={12}
          columnsXL={1}
          style={{ width: '100%' }}
        >
          <FormItem>
            <FlexBox
              direction={FlexBoxDirection.Column}
              style={{ alignItems: 'start', width: '100%' }}
            >
              <Label showColon for="market-kpi-dialog-name">
                {t('name')}
              </Label>
              <Input id="market-kpi-dialog-name" value={kpiName} disabled />
            </FlexBox>
          </FormItem>
          <FormItem>
            <FlexBox
              direction={FlexBoxDirection.Column}
              style={{ alignItems: 'start', width: '100%' }}
            >
              <Label showColon for="market-kpi-dialog-current-value">
                {t('current-value')}
              </Label>
              <Input
                id="market-kpi-dialog-current-value"
                value={kpiCurrentValue}
                icon={<Label>{kpiCurrentUnit}</Label>}
                disabled
              />
            </FlexBox>
          </FormItem>
          <FormItem>
            <FlexBox
              direction={FlexBoxDirection.Column}
              style={{ alignItems: 'start', width: '100%' }}
            >
              <Label showColon for="market-kpi-dialog-new-value" required>
                {t('new-value')}
              </Label>
              <NumberInput
                id="market-kpi-dialog-new-value"
                value={newKpiValue}
                placeholder={t('new-value')}
                onInput={onNewKpiValueChanged}
                required
                icon={<Label>{kpiCurrentUnit}</Label>}
              />
            </FlexBox>
          </FormItem>
          <FormItem>
            <FlexBox
              direction={FlexBoxDirection.Column}
              style={{ alignItems: 'start', width: '100%' }}
            >
              <Label showColon for="market-kpi-dialog-new-value-date" required>
                {t('date')}
              </Label>
              <EditableDatePickerComponent
                style={{ width: '100%' }}
                id="market-kpi-dialog-new-value-date"
                value={newKpiDate}
                maxDate={today}
                onChange={handleNewKpiDateChanged}
                editable
              />
            </FlexBox>
          </FormItem>
        </Form>
      </FlexBox>
    </Dialog>
  )
}

MarketsKpiDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  kpiCurrentValue: PropTypes.string,
  kpiCurrentUnit: PropTypes.string,
  kpiName: PropTypes.string,
  kpiKey: PropTypes.string,
}

export default MarketsKpiDialog
