import {
  BusyIndicatorSize,
  Button,
  ButtonDesign,
  DynamicPage,
  MessageBoxTypes,
  ResponsiveGridLayout,
  ToolbarSeparator,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { neededOperationsForDecisionPaperCreate } from 'api/events/eventAllowedOperations'
import { getObjectErrorForEventRequest } from 'api/events/eventsRequestErrors'
import { eventStatus, taskStatus } from 'api/events/status'
import { hasUserRequiredOperations } from 'api/helper'
import EventCreationDecisionPaperTemplateTile from 'components/domains/business-events-and-tasks/events/creation/EventCreationDecisionPaperTemplateTile'
import EventCreationDescriptionTile from 'components/domains/business-events-and-tasks/events/creation/EventCreationDescriptionTile'
import EventCreationInformationTile from 'components/domains/business-events-and-tasks/events/creation/EventCreationInformationTile'
import styles from 'components/domains/business-events-and-tasks/events/creation/EventCreationPage.module.css'
import EventCreationTasksTile from 'components/domains/business-events-and-tasks/events/creation/EventCreationTasksTile'
import LoadingButton from 'components/ui/button/LoadingButton'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import ErrorMessageStripWithDetails from 'components/ui/errors/ErrorMessageStripWithDetails'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { MessageBoxActions, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import Header from 'components/ui/page/Header'
import { useCreateEvent } from 'hooks/services/business-events-and-tasks/events/useCreateEvent'
import { useEventTypes } from 'hooks/services/business-events-and-tasks/events/useEventTypes'
import useEventsAllowedOperations from 'hooks/services/business-events-and-tasks/events/useEventsAllowedOperations'
import useGetEventDefaultAssigneeStaffMembers from 'hooks/services/business-events-and-tasks/events/useGetEventDefaultAssigneeStaffMembers'
import {
  resetEventCreation,
  setAssignee,
  setDefaultTasks,
  setEntityId,
  setEntityType,
  setEventCode,
  setEventDescription,
  setEventDueDate,
  setEventName,
  setLastSelectedSaveStatus,
  setTasks,
} from 'redux/slices/events/eventCreationSlice'
import paths from 'routes/paths'
import getHistoryLength from 'utils/getHistoryLength'

const EventCreationPage = ({ entityId, entityType, eventType }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.events.creation',
  })
  const { t: tNoPrefix } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const eventState = useSelector((state) => state.events.eventCreation)
  const showMessage = useShowMessageBox()
  const [isDraftCreation, setIsDraftCreation] = useState(false)

  const { mutate: createEvent, isLoading: isCreateEventLoading } = useCreateEvent()
  const {
    isLoading: isAllowedOperationsLoading,
    isError: isAllowedOperationsError,
    data: allowedOperationsResponse,
  } = useEventsAllowedOperations()
  const {
    data: eventTypeData,
    isLoading: isEventTypesLoading,
    isError: isEventTypesError,
  } = useEventTypes({ entityType, eventCode: eventType })
  const {
    data: defaultAssignees,
    isLoading: isDefaultAssigneesLoading,
    isError: isDefaultAssigneesError,
  } = useGetEventDefaultAssigneeStaffMembers({ eventCode: eventType, entityType, entityId })

  const hasDecisionPaperCreatePermission = useMemo(
    () =>
      hasUserRequiredOperations(
        neededOperationsForDecisionPaperCreate,
        allowedOperationsResponse?.allowed_operations,
      ),
    [allowedOperationsResponse],
  )

  const eventTypeInformation = eventTypeData?.result?.find(
    (currentEventType) => currentEventType.code === eventType,
  )

  const showDecisionPaperTile = eventTypeInformation?.is_decisions_supported

  useEffect(() => {
    if (!eventTypeInformation) {
      return
    }
    const {
      name: initialName,
      description: initialDescription,
      code: initialCode,
      due_date: initialDueDate,
      task_types: initialTaskTypes,
    } = eventTypeInformation
    dispatch(setLastSelectedSaveStatus(eventStatus.drafted))
    dispatch(setAssignee(defaultAssignees?.eventAssignee))
    dispatch(setEntityType(entityType))
    dispatch(setEntityId(entityId))
    dispatch(setEventName(initialName))
    dispatch(setEventDescription(initialDescription))
    dispatch(setEventDueDate(initialDueDate))
    dispatch(setEventCode(initialCode))

    const mappedTasks = initialTaskTypes
      ? initialTaskTypes.map((taskType, index) => ({
          id: index,
          info: {
            name: taskType.name,
            assignee: defaultAssignees?.taskAssignees?.[taskType.id],
            description: taskType.description,
            dueDate: taskType.due_date,
            isMandatory: taskType.is_mandatory,
          },
          isDefaultTask: true,
        }))
      : []

    dispatch(setTasks(mappedTasks))
    dispatch(
      setDefaultTasks(
        mappedTasks.map(({ info: { name, description, dueDate, isMandatory, assignee } }) => ({
          name,
          assignee,
          description,
          dueDate,
          isMandatory,
        })),
      ),
    )
  }, [
    eventTypeInformation,
    dispatch,
    entityType,
    entityId,
    defaultAssignees?.taskAssignees,
    isDefaultAssigneesLoading,
    defaultAssignees?.eventAssignee?.id,
    defaultAssignees?.eventAssignee,
  ])

  const onEventCreationSuccess = useCallback(
    ({ errors, event }) => {
      queryClient.invalidateQueries(['events'])
      getHistoryLength() <= 1 &&
        window.opener?.postMessage('event-created-by-child-window', window.origin)
      const navigationTarget = `/${paths.businessEventsAndTasks}/business-events/${event.id}`
      if (errors.length === 0) {
        navigate(navigationTarget)
      }
      errors.forEach(({ code }, index) => {
        showMessage({
          onAfterOpen: () => {
            index === 0 && navigate(navigationTarget)
          }, //fixes message rendered with an incorrect formatting
          type: MessageBoxTypes.Error,
          children: tNoPrefix(getObjectErrorForEventRequest(code).translationKey),
          actions: [MessageBoxActions.Close],
          emphasizedAction: MessageBoxActions.Close,
        })
      })
    },
    [navigate, queryClient, showMessage, tNoPrefix],
  )

  const onEventCreationError = useCallback(() => {
    showMessage({
      type: MessageBoxTypes.Error,
      children: tNoPrefix(getObjectErrorForEventRequest().translationKey),
      actions: [MessageBoxActions.Close],
      emphasizedAction: MessageBoxActions.Close,
    })
  }, [showMessage, tNoPrefix])

  const createEventCallback = useCallback(
    (status) => {
      const {
        eventCode,
        eventName,
        eventDescription,
        assignee,
        eventDueDate,
        entityId: eventEntityId,
        entityType: eventEntityType,
        tasks,
        decisionPaperTemplateCode,
        customizedDecisionPaperTemplateStructure,
        eventIdToImportDataFrom,
      } = eventState
      createEvent(
        {
          skipAssigneeCalculation: true,
          entityId: eventEntityId,
          entityType: eventEntityType,
          code: eventCode,
          name: eventName,
          description: eventDescription,
          assignee: assignee?.id,
          dueDate: eventDueDate,
          status,
          tasks: tasks.map((task) => ({
            info: {
              ...task.info,
              assignee: task.info?.assignee?.id,
            },
            status: taskStatus.created,
          })),
          decisionPaperTemplateCode,
          customizedTemplateStructure: customizedDecisionPaperTemplateStructure,
          eventIdToImportDataFrom,
        },
        {
          onSuccess: onEventCreationSuccess,
          onError: onEventCreationError,
        },
      )
    },
    [createEvent, eventState, onEventCreationError, onEventCreationSuccess],
  )

  const handleConfirmationButtonClick = useCallback(() => {
    dispatch(resetEventCreation())
    getHistoryLength() > 1 ? navigate(-1) : window.close()
  }, [dispatch, navigate])

  const handlePublishButtonClick = useCallback(() => {
    setIsDraftCreation(false)
    dispatch(setLastSelectedSaveStatus(eventStatus.ongoing))
    createEventCallback(eventStatus.ongoing)
  }, [createEventCallback, dispatch])

  const handleCreateDraftButtonClick = useCallback(() => {
    setIsDraftCreation(true)
    dispatch(setLastSelectedSaveStatus(eventStatus.drafted))
    createEventCallback(eventStatus.drafted)
  }, [createEventCallback, dispatch])

  const handleCancelButtonClick = useCallback(() => {
    showMessage({
      type: MessageBoxTypes.Confirm,
      titleText: t('cancel-confirmation.title'),
      children: t('cancel-confirmation.text'),
      actions: [
        <Button
          key="button-confirm"
          design={ButtonDesign.Emphasized}
          onClick={handleConfirmationButtonClick}
        >
          {t('cancel-confirmation.button.text')}
        </Button>,
        MessageBoxActions.Cancel,
      ],
    })
  }, [showMessage, t, handleConfirmationButtonClick])

  const isSaveButtonDisabled =
    isDefaultAssigneesLoading ||
    eventState.eventDescription === '' ||
    eventState.eventDescription === '<p><br></p>'

  const publishEventButton = useMemo(() => {
    const renderContent = () => tNoPrefix('buttons.publish')

    return (
      <LoadingButton
        key="create-event"
        design={ButtonDesign.Emphasized}
        onClick={handlePublishButtonClick}
        disabled={isSaveButtonDisabled || (isCreateEventLoading && isDraftCreation)}
        isLoading={isCreateEventLoading && !isDraftCreation}
        renderContent={renderContent}
      />
    )
  }, [
    handlePublishButtonClick,
    isCreateEventLoading,
    isDraftCreation,
    isSaveButtonDisabled,
    tNoPrefix,
  ])

  const createDraftEventButton = useMemo(() => {
    const renderContent = () => tNoPrefix('buttons.save-as-draft')
    return (
      <LoadingButton
        key="create-draft-event"
        design={ButtonDesign.Default}
        onClick={handleCreateDraftButtonClick}
        disabled={isDefaultAssigneesLoading || (isCreateEventLoading && !isDraftCreation)}
        isLoading={isDraftCreation && isCreateEventLoading}
        renderContent={renderContent}
      />
    )
  }, [
    handleCreateDraftButtonClick,
    isCreateEventLoading,
    isDefaultAssigneesLoading,
    isDraftCreation,
    tNoPrefix,
  ])

  const cancelEventButton = useMemo(
    () => (
      <Button
        key="cancel-event"
        design={ButtonDesign.Transparent}
        onClick={handleCancelButtonClick}
        disabled={isCreateEventLoading}
      >
        {tNoPrefix('buttons.cancel')}
      </Button>
    ),
    [handleCancelButtonClick, isCreateEventLoading, tNoPrefix],
  )

  const actionButtons = useMemo(
    () => (
      <>
        {publishEventButton}
        {createDraftEventButton}
        <ToolbarSeparator />
        {cancelEventButton}
      </>
    ),
    [cancelEventButton, createDraftEventButton, publishEventButton],
  )
  const defaultAssigneesErrorMessageStrip = useMemo(() => {
    if (!isDefaultAssigneesError) {
      return
    }
    const errorDetails = defaultAssignees?.errors?.map(({ message }) => message).join('\n\n') ?? ''
    return (
      <div className={styles.errorMessageStrip}>
        <ErrorMessageStripWithDetails
          message={t('error.default-assignees.message')}
          messageBoxSummary={t('error.default-assignees.details')}
          messageBoxDetails={errorDetails}
        />
      </div>
    )
  }, [defaultAssignees?.errors, isDefaultAssigneesError, t])

  const renderContent = () => {
    const breadcrumbs = [
      {
        text: t('navigate-back'),
        href: `/${paths.businessEventsAndTasks}`,
      },
      {
        text: eventTypeInformation ? eventTypeInformation.name : t('default-breadcrumb-text'),
      },
    ]

    const headerTitleText = eventTypeInformation ? eventTypeInformation.name : ''

    const headerTitle = (
      <Header title={headerTitleText} actions={actionButtons} breadcrumbs={breadcrumbs} />
    )

    return (
      <DynamicPage
        showHideHeaderButton={false}
        headerContentPinnable={false}
        headerTitle={headerTitle}
      >
        <div className={styles.contentWrapper}>
          {defaultAssigneesErrorMessageStrip}
          <ResponsiveGridLayout
            columnsXL={2}
            columnsL={2}
            columnsM={2}
            columnsS={2}
            // TODO: Extract to CSS once known how to do it with ui5-webcomponents
            columnGap="1rem"
            rowGap="1rem"
          >
            <EventCreationInformationTile
              entityId={entityId}
              entityType={entityType}
              disabled={isCreateEventLoading}
              isDefaultAssigneeLoading={isDefaultAssigneesLoading}
              defaultAssignee={defaultAssignees?.eventAssignee}
            />
            <EventCreationDescriptionTile disabled={isCreateEventLoading} />
          </ResponsiveGridLayout>
          <ResponsiveGridLayout
            className={styles.fullWidthGrid}
            columnsXL={1}
            columnsL={1}
            columnsM={1}
            columnsS={1}
            // TODO: Extract to CSS once known how to do it with ui5-webcomponents
            columnGap="1rem"
            rowGap="1rem"
          >
            {hasDecisionPaperCreatePermission && showDecisionPaperTile && (
              <EventCreationDecisionPaperTemplateTile
                eventCode={eventType}
                entityType={entityType}
                entityId={entityId}
                disabled={isCreateEventLoading}
              />
            )}
            <EventCreationTasksTile
              disabled={isCreateEventLoading}
              isDefaultAssigneesLoading={isDefaultAssigneesLoading}
              defaultTemplateAssignees={defaultAssignees?.taskAssignees}
            />
          </ResponsiveGridLayout>
        </div>
      </DynamicPage>
    )
  }

  return (
    <RequestStateResolver
      isLoading={isEventTypesLoading || isAllowedOperationsLoading}
      isError={isEventTypesError || isAllowedOperationsError}
      errorToDisplay={<ErrorDataUnavailableInContent />}
      renderContent={renderContent}
      busyIndicatorSize={BusyIndicatorSize.Medium}
      center
    />
  )
}

EventCreationPage.propTypes = {
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
}

export default EventCreationPage
