import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DealCashflowChartWrapper from 'components/domains/deals/deal-cashflow/result-charts/chart-components/DealCashflowChartWrapper'
import { DealCashflowDebtEquityChart } from 'components/domains/deals/deal-cashflow/result-charts/chart-components/DealCashflowDebtEquityChart'
import useGetMaturityData from 'components/domains/deals/deal-cashflow/result-charts/useGetMaturityData'

const KPI_CATEGORY = null
const CALC_KPI = 'ER_EOP'
const RESULT_CATEGORY = 'Total'
const EQUITY_CCT_ID = '42184b77-f829-48d6-a027-b5203724d7b3'
const DEBT_CCT_ID = '93155f79-858b-4df7-979c-f30e6a82e31b'

export const DealCashflowDebtAndEquityCard = ({
  isLoading,
  isError,
  calculatedResults,
  calculatedKpis,
  isCard = true,
  metaData = undefined,
  showTitle,
  isOnDecisionPaper = false,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.cashflow.financial-ratios',
  })
  const [selectedCashflowKpi, setSelectedCashflowKpi] = useState()

  const dataCalculatedKpi = calculatedKpis?.find(
    (data) => data.category === KPI_CATEGORY && data.cashflowKpi.code === CALC_KPI,
  )

  const dataEquity = calculatedResults?.find(
    ({ category, cashflowType }) =>
      category === RESULT_CATEGORY && cashflowType.cctId === EQUITY_CCT_ID,
  )

  const dataDebt = calculatedResults?.find(
    ({ category, cashflowType }) =>
      category === RESULT_CATEGORY && cashflowType.cctId === DEBT_CCT_ID,
  )

  const combinedDataset = dataCalculatedKpi?.values?.map((kpiItem) => {
    const equity = dataEquity?.values?.find(
      (equityItem) => equityItem.endDate === kpiItem.endDate,
    )?.value

    const totalDebt = dataDebt?.values?.find(
      (debtItem) => debtItem.endDate === kpiItem.endDate,
    )?.value

    return {
      kpi: kpiItem.value,
      equity,
      totalDebt,
      sumOfEquityTotalDebt: equity + totalDebt,
      endDate: kpiItem.endDate,
    }
  })

  const { maturityData: maturityDataDebt = {} } = useGetMaturityData(dataDebt)

  const maturityData = {
    value: maturityDataDebt.value,
    endDate: maturityDataDebt.endDate,
  }

  const headerProps = {
    selectedData: combinedDataset
      ? {
          rawValues: [
            {
              endDate: combinedDataset[0].endDate,
              value: combinedDataset[0].totalDebt,
            },
          ],
        }
      : {},
    isLoading,
    isError,
    currency: dataEquity?.currencyCode,
    maturityData,
    setSelectedCashflowKpi,
    selectedCashflowKpi,
    isFixed: true,
    title: t('title.equity-debt'),
    showTitle: showTitle,
    isOnDecisionPaper: isOnDecisionPaper,
    subtitlePrefix: t('subtitle.total-debt'),
  }

  return (
    <DealCashflowChartWrapper
      isCard={isCard}
      isLoading={isLoading}
      isError={isError}
      isSelectedData={!!dataCalculatedKpi}
      isMetaData={!!metaData}
      headerProps={headerProps}
    >
      <DealCashflowDebtEquityChart data={combinedDataset} maturityData={maturityData} />
    </DealCashflowChartWrapper>
  )
}

DealCashflowDebtAndEquityCard.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  calculatedResults: PropTypes.array.isRequired,
  calculatedKpis: PropTypes.array.isRequired,
  isCard: PropTypes.bool,
  metaData: PropTypes.object,
  showTitle: PropTypes.bool,
  isOnDecisionPaper: PropTypes.bool,
}
