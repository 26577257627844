import { Button, CardHeader } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import RequirementsTable from 'components/domains/conditions/overview/requirements/RequirementsTable'
import CardSection from 'components/domains/deals/card/CardSection'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useRequirements from 'hooks/services/conditions/requirements/useRequirements'
import useRequirementsTableColumns from 'hooks/services/conditions/tables/useRequirementsTableColumns'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import useCovenantCheckById from 'hooks/services/deals/covenants/useCovenantCheckById'

const mainEntityTypesToCwpEntityTypesMap = {
  [mainEntityTypes.DEAL]: cwpEntityTypes.DEAL,
  [mainEntityTypes.BUSINESSPARTNER]: cwpEntityTypes.BUSINESS_PARTNER,
}

const shownColumns = [
  'name',
  'condition',
  'refNumber',
  'references',
  'attachment',
  'assignee',
  'status',
  'watcher',
  'actions',
]

const hiddenColumns = []

const CovenantCheckMonitoringRequirementsCard = ({ covenantCheckUuid }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.monitoring-requirements-card',
  })
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const {
    data: {
      monitoringReferenceEntity: { type: monitoringReferenceType, id: monitoringReferenceId } = {},
    } = {},
    isFetching: isFetchingCovenantCheck,
    isError: isErrorCovenantCheck,
  } = useCovenantCheckById(covenantCheckUuid)

  const mappedReferenceType = mainEntityTypesToCwpEntityTypesMap[monitoringReferenceType]

  // HINT: requirements are parts of conditions.
  // The requirements GET endpoints includes a covenant check filter, so the results can be queried directly.
  const {
    data: requirementsData,
    isLoading: isLoadingRequirements,
    isError: isErrorRequirements,
    fetchNextPage,
  } = useRequirements(
    { entityId: monitoringReferenceId, entityType: mappedReferenceType },
    { covenantCheck: covenantCheckUuid },
    undefined,
    { enabled: !!monitoringReferenceType && !!monitoringReferenceId },
  )

  const { columnSelection } = useRequirementsTableColumns({
    entityType: mappedReferenceType,
    entityId: monitoringReferenceId,
    shownColumns,
    hiddenColumns,
  })

  const isLoading = useMemo(
    () => isLoadingRequirements || isFetchingCovenantCheck,
    [isFetchingCovenantCheck, isLoadingRequirements],
  )
  const isError = useMemo(
    () => isErrorRequirements || isErrorCovenantCheck,
    [isErrorRequirements, isErrorCovenantCheck],
  )
  const handleButtonClick = () => {
    const [_, businessEntity, id] = pathname.split('/')
    navigate(`/${businessEntity}/${id}/conditions?con_covenantCheck=${covenantCheckUuid}`)
  }

  return (
    <Card
      header={
        <CardHeader
          titleText={t('title')}
          action={<Button onClick={handleButtonClick}>{t('header-action')}</Button>}
        />
      }
    >
      <CardSection>
        <RequestStateResolver
          isError={isError}
          isLoading={false} // handled by the RequirementsTable
          errorToDisplay={<ErrorDataUnavailableInContent />}
          renderContent={() => (
            <RequirementsTable
              isLoading={isLoading}
              requirements={requirementsData?.requirements}
              columns={columnSelection}
              isFilterOrSearchApplied={false}
              fetchNextPage={fetchNextPage}
              isUsedForCovenantChecks
            />
          )}
        />
      </CardSection>
    </Card>
  )
}

CovenantCheckMonitoringRequirementsCard.propTypes = {
  covenantCheckUuid: PropTypes.string.isRequired,
}

export default CovenantCheckMonitoringRequirementsCard
