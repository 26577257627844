import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropertyValuationResultsDateInputCell from 'components/domains/properties/valuation/valuation-results/cells/PropertyValuationResultsDateInputCell'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { valuationKeyDateChanged } from 'redux/slices/properties/valuationResultsTableSlice'

const PropertyValuationResultsKeyDateCell = ({ keyDate, isAddMode }) => {
  const { format: formatDate } = useShortDateFormatter()
  const dispatch = useDispatch()
  const currentDate = formatDate(new Date().toISOString())
  const handleChange = useCallback(
    (newDate) => {
      if (!isAddMode) {
        return
      }
      dispatch(valuationKeyDateChanged({ keyDate: newDate }))
    },
    [dispatch, isAddMode],
  )

  return (
    <PropertyValuationResultsDateInputCell
      date={keyDate}
      maxDate={currentDate}
      onChange={handleChange}
      isAddMode={isAddMode}
    />
  )
}

PropertyValuationResultsKeyDateCell.propTypes = {
  keyDate: PropTypes.string,
  isAddMode: PropTypes.bool.isRequired,
}

export default PropertyValuationResultsKeyDateCell
