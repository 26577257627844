import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import React from 'react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/CovenantsOfCheckBreachCell.module.css'
import {
  useCovenantsOfCheckCustomizableCurrencyFormatter,
  useCovenantsOfCheckPercentageFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/i18n/useCovenantsOfCheckI18n'

const CovenantsOfCheckLimitCell = ({ unit, value, currencyCode }) => {
  const formatCurrency = useCovenantsOfCheckCustomizableCurrencyFormatter()
  const formatPercentage = useCovenantsOfCheckPercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  if (unit === 'CURRENCY') {
    return (
      <span className={styles.noWrap}>
        {formatCurrency(value, currencyCode, {
          currencyDisplay: 'code',
        })}
      </span>
    )
  }

  if (unit === 'PERCENT') {
    return !isNil(value) ? <span>{formatPercentage(value / 100)}</span> : null
  }
  return null
}

CovenantsOfCheckLimitCell.propTypes = {
  unit: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currencyCode: PropTypes.string,
}

export default CovenantsOfCheckLimitCell
