import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useEditTask = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(
    ({ taskId, eventId, description, assignee, originalDueDate, currentDueDate, status }) =>
      patch({
        path: `/events/${eventId}/tasks/${taskId}`,
        body: {
          info: {
            description,
            assignee,
            original_due_date: originalDueDate,
            current_due_date: currentDueDate,
          },
          status: status,
        },
      }),
    mutationOptions,
  )
}

export default useEditTask
