import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useStaffMemberByObjectIdOrEmail'
import { UserProfileContext } from 'routes/UserProfileContext'

// TODO: CWP-12317: Probably refactor this to useLastEditedTextByObjectIdOrEmail
// But be careful, there are some callers, that actually provide an email from a business field,
// for example RiskIndicatorsGroupGeneralInformation
// Others provide a lastUpdatedBy field, that will me migrated from email to objectid
const useLastEditedTextByEmail = ({ email, timestamp } = {}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.card-header-last-edited',
  })
  const { zoneId } = useContext(UserProfileContext) ?? {}
  const { format: formatDateAndTime } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
    timeZone: zoneId,
  })

  const { data: staffMember } = useStaffMemberByObjectIdOrEmail({
    objectIdOrEmail: email,
  })

  const staffMemberName = useMemo(() => staffMember?.fullName || email, [staffMember, email])

  if (!email || !timestamp) {
    return { lastEditedText: undefined }
  }

  return {
    lastEditedText: t('subtitle', { name: staffMemberName, date: formatDateAndTime(timestamp) }),
  }
}

export default useLastEditedTextByEmail
