import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  startApproval: {
    editRows: {},
    errorSummary: 0,
    warningSummary: 0,
    isConfirmDialogOpen: false,
    isDiscardDialogOpen: false,
  },
  currentApproval: {
    editRows: {},
    errorSummary: 0,
    warningSummary: 0,
    productRowInEdit: null,
  },
}

export const arrearApprovalSlice = createSlice({
  name: 'arrears/arrear-approval-validation',
  initialState,
  reducers: {
    updateEditRowField: (state, { payload }) => {
      const { isStartApprovalProcess, rowKey, fieldName, fieldValue } = payload
      if (isStartApprovalProcess) {
        state.startApproval.editRows = {
          ...state.startApproval.editRows,
          [rowKey]: {
            ...(state.startApproval.editRows[rowKey] ?? {}),
            [fieldName]: fieldValue,
          },
        }
      } else {
        state.currentApproval.editRows = {
          ...state.currentApproval.editRows,
          [rowKey]: {
            ...(state.currentApproval.editRows[rowKey] ?? {}),
            [fieldName]: fieldValue,
          },
        }
      }
    },
    deleteEditRow: (state, { payload }) => {
      const { isStartApprovalProcess, rowKey } = payload
      if (isStartApprovalProcess) {
        delete state.startApproval.editRows[rowKey]
      } else {
        delete state.currentApproval.editRows[rowKey]
      }
    },
    setErrorSummary: (state, { payload }) => {
      const { isStartApprovalProcess, value } = payload
      if (isStartApprovalProcess) {
        state.startApproval.errorSummary = value
      } else {
        state.currentApproval.errorSummary = value
      }
    },
    setWarningSummary: (state, { payload }) => {
      const { isStartApprovalProcess, value } = payload
      if (isStartApprovalProcess) {
        state.startApproval.warningSummary = value
      } else {
        state.currentApproval.warningSummary = value
      }
    },
    setConfirmDialogOpen: (state, { payload: isOpen }) => {
      state.startApproval.isConfirmDialogOpen = isOpen
    },
    setDiscardDialogOpen: (state, { payload: isOpen }) => {
      state.startApproval.isDiscardDialogOpen = isOpen
    },
    setProductRowInEdit: (state, { payload: productRowInEdit }) => {
      state.currentApproval.productRowInEdit = productRowInEdit
    },
  },
})

export const {
  updateEditRowField,
  deleteEditRow,
  setErrorSummary,
  setWarningSummary,
  setConfirmDialogOpen,
  setDiscardDialogOpen,
  setProductRowInEdit,
} = arrearApprovalSlice.actions

export const selectors = {
  getEditRow: ({ state, isStartApprovalProcess, rowKey }) => {
    const arrearApprovalState = state.arrears.arrearApproval
    return isStartApprovalProcess
      ? arrearApprovalState.startApproval.editRows[rowKey]
      : arrearApprovalState.currentApproval.editRows[rowKey]
  },
  getEditRowValue: ({ state, isStartApprovalProcess, rowKey, field }) => {
    const arrearApprovalState = state.arrears.arrearApproval
    const value = 'value'
    return isStartApprovalProcess
      ? arrearApprovalState.startApproval.editRows[rowKey]?.[field]?.[value]
      : arrearApprovalState.currentApproval.editRows[rowKey]?.[field]?.[value]
  },
  getEditRowValueState: ({ state, isStartApprovalProcess, rowKey, field }) => {
    const arrearApprovalState = state.arrears.arrearApproval
    const valueState = 'valueState'
    return isStartApprovalProcess
      ? arrearApprovalState.startApproval.editRows[rowKey]?.[field]?.[valueState]
      : arrearApprovalState.currentApproval.editRows[rowKey]?.[field]?.[valueState]
  },
  getProductRowInEdit: (state) => state.arrears.arrearApproval.currentApproval.productRowInEdit,
}
export default arrearApprovalSlice.reducer
