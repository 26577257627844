import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateRequirement = (options) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({
      conditionId,
      requirementData: { name, assignee, refNumber, description, documentType, dueDate },
    }) => {
      const requirement = {
        info: {
          name,
          assignee,
          refNumber,
          description,
          documentType,
          dueDate,
        },
      }
      const { data } = await post({
        path: `/conditions/external/${conditionId}/requirements`,
        body: snakecaseKeys(requirement),
      })
      return data
    },
    options,
  )
}

export default useCreateRequirement
