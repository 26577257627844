import {
  BusyIndicator,
  BusyIndicatorSize,
  Dialog,
  Text,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/rent-roll/working-version/excel-upload/CreateRentRollWorkingVersionProgressIndicator.module.css'

const CreateRentRollWorkingVersionProgressIndicator = ({
  isLoading,
  numberUploaded,
  numberTotal,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.excel-upload',
  })
  return (
    <Dialog open={isLoading}>
      <Title className={styles.title} level={TitleLevel.H5}>
        {t('progress-indicator.title')}
      </Title>
      <div className={styles.textWrapper}>
        <Text className={styles.text}>
          {t('progress-indicator.subtitle', {
            processedCount: numberUploaded,
            totalCount: numberTotal,
          })}
        </Text>
      </div>
      <div className={styles.busyWrapper}>
        <BusyIndicator active size={BusyIndicatorSize.Medium} />
      </div>
    </Dialog>
  )
}

CreateRentRollWorkingVersionProgressIndicator.propTypes = {
  isLoading: PropType.bool.isRequired,
  numberUploaded: PropType.number.isRequired,
  numberTotal: PropType.number.isRequired,
}

export default CreateRentRollWorkingVersionProgressIndicator
