import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ErrorScreen from 'components/ui/screens/ErrorScreen'

const SortedTableErrorMessage = ({
  customErrorTitle,
  customErrorDescription,
  customErrorDetails,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'sorted-table-error' })

  return (
    <ErrorScreen
      title={t(customErrorTitle ?? 'error-title')}
      description={t(customErrorDescription ?? 'error-description')}
      error={t(customErrorDetails ?? 'error-details')}
    />
  )
}

SortedTableErrorMessage.propTypes = {
  customErrorTitle: PropTypes.string,
  customErrorDescription: PropTypes.string,
  customErrorDetails: PropTypes.string,
}

export default SortedTableErrorMessage
