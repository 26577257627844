import { ObjectStatus } from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import find from 'lodash.find'
import { useNavigate } from 'react-router-dom'
import { DisplayValueOfCodeText } from 'components/domains/deals/deal-search/DisplayValueOfCodeText'
import useGetAndEnrichFavoritesAndRecentVisitsWithDeals from 'components/domains/deals/user-favorite/useGetAndEnrichFavoritesAndRecentVisitsWithDeals'
import EntityCell from 'components/ui/tables/cells/EntityCell'
import UserFavoritesAndRecentVisitsCard from 'components/ui/user-favorite/UserFavoritesAndRecentsCard'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import {
  useBusinessSegments,
  useDealTypes,
} from 'hooks/services/deals/configurations/useBusinessSegments'
import paths from 'routes/paths'

const columnNames = {
  ID_AND_NAME: 'deal-id-and-name',
  SEGMENT_NAME: 'deal-segment-name',
  TYPE: 'deal-type',
  STATUS: 'deal-status',
}

const renderDealIdAndName = (dealId, name) => (
  <EntityCell name={name} link={`/deals/${dealId}`} id={dealId} options={{ isNameBold: true }} />
)

const renderDealSegment = (businessSegmentCode) => (
  <DisplayValueOfCodeText code={businessSegmentCode} loadingHook={useBusinessSegments} />
)

const renderDealType = (dealTypeCode) => (
  <DisplayValueOfCodeText code={dealTypeCode} loadingHook={useDealTypes} />
)

const renderDealStatus = (status) => (
  <ObjectStatus inverted state={status ? ValueState.Information : ValueState.None}>
    {status}
  </ObjectStatus>
)

const renderIfFound = (notFound, component) => (notFound ? null : component)

const mapDataToTableRow = ({
  dealId,
  name,
  businessSegmentCode,
  dealTypeCode,
  status,
  itemType,
  entityId,
  entityType,
  notFound,
}) => ({
  [columnNames.ID_AND_NAME]: renderIfFound(notFound, renderDealIdAndName(dealId, name)),
  [columnNames.SEGMENT_NAME]: renderIfFound(notFound, renderDealSegment(businessSegmentCode)),
  [columnNames.TYPE]: renderIfFound(notFound, renderDealType(dealTypeCode)),
  [columnNames.STATUS]: renderIfFound(notFound, renderDealStatus(status)),
  itemType,
  entityId,
  entityType,
  notFound,
})

const mapDataToTableRows = (data) => data?.map(mapDataToTableRow) ?? []

const DealsUserFavoritesAndRecentVisitsCard = () => {
  const dealUserFavoritesAndRecentVisits = useGetAndEnrichFavoritesAndRecentVisitsWithDeals()
  const navigate = useNavigate()
  const handleOnRowClick = (dealUuid) => {
    const foundDeal = find(
      dealUserFavoritesAndRecentVisits.data,
      (item) => item.dealUuid === dealUuid,
    )

    if (foundDeal) {
      navigate(`/${paths.deals}/${foundDeal.dealId}`)
    }
  }
  const handleOnHeaderClick = () => navigate(`/${paths.deals}`)

  return (
    <UserFavoritesAndRecentVisitsCard
      entityType={cwpEntityTypes.DEAL}
      title="deal-title"
      subtitle="deal-subtitle"
      isLoading={dealUserFavoritesAndRecentVisits.isLoading}
      isError={dealUserFavoritesAndRecentVisits.isError}
      rowsData={mapDataToTableRows(dealUserFavoritesAndRecentVisits.data)}
      columnNames={Object.values(columnNames)}
      handleOnHeaderClick={handleOnHeaderClick}
      handleOnRowClick={handleOnRowClick}
    />
  )
}

export default DealsUserFavoritesAndRecentVisitsCard
