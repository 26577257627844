import isEmpty from 'lodash.isempty'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import useReferenceEntities from 'hooks/services/business-events-and-tasks/decision-papers/tiles/covenants-and-ratios/useReferenceEntities'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import { useCovenants } from 'hooks/services/deals/covenants/useCovenants'
import useCovenantsLimitsByIds from 'hooks/services/deals/covenants/useCovenantsLimitsByIds'
import useFinancingMini from 'hooks/services/deals/financing/useFinancingMini'
import useDealMini from 'hooks/services/deals/useDealMini'

const useCovenantsRatiosCovenantsOverview = (
  { entityRef: { entityId: dealUuid } },
  _,
  tileCode,
) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isFetchingDealUuid,
    isError: isErrorDealUuid,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    data: deal,
    isFetching: isFetchingDeal,
    isError: isErrorDeal,
  } = useDealMini(dealUuidByTileCode)

  const validAt = useMemo(() => DateTime.now().toISODate(), [])
  const {
    data: covenantsData,
    isFetching: isFetchingCovenants,
    isError: isErrorCovenants,
  } = useCovenants({
    mainEntityId: dealUuidByTileCode,
    mainEntityType: mainEntityTypes.DEAL,
    validAt,
    options: {
      enabled: !!dealUuidByTileCode,
    },
  })

  const dealId = deal?.dealId
  const covenants = useMemo(() => covenantsData?.covenants ?? [], [covenantsData])
  const referenceEntities = covenants.map((covenant) => covenant.referenceEntity)
  const covenantsUuids = covenants.map((covenant) => covenant.covenantUuid)

  const {
    data: { tranchesMinis: dealTranches = [] } = {},
    isFetching: isFetchingDealTranches,
    isError: isErrorDealTranches,
  } = useFinancingMini(dealUuidByTileCode)

  const {
    data: resolvedReferenceEntities = [],
    isFetching: isFetchingReferenceEntities,
    isError: isErrorReferenceEntities,
  } = useReferenceEntities(
    { referenceEntities, dealUuid: dealUuidByTileCode, displayId: dealId, dealTranches },
    { enabled: !(isFetchingDealTranches || isErrorDealTranches) && !!dealUuidByTileCode },
  )

  const {
    data: covenantsLimits = [],
    isFetching: isFetchingCovenantsLimits,
    isError: isErrorCovenantsLimits,
  } = useCovenantsLimitsByIds(covenantsUuids)

  const mappedCovenantsLimits = useMemo(() => {
    if (covenantsLimits.length === 0) {
      return []
    }

    const getLimits = (covenantLimits, limitType) => {
      const limitsByType = covenantLimits.find(
        (covenantLimit) => covenantLimit.limitType === limitType,
      )
      const limits = isEmpty(limitsByType?.absoluteLimits ?? [])
        ? limitsByType?.relativeLimits
        : limitsByType?.absoluteLimits
      return (limits ?? []).filter(({ isCovenantHoliday }) => isCovenantHoliday === false)
    }

    return covenantsLimits.map(({ covenantLimits }) => ({
      softLimits: getLimits(covenantLimits, 'SOFT_LIMIT'),
      hardLimits: getLimits(covenantLimits, 'HARD_LIMIT'),
    }))
  }, [covenantsLimits])

  const covenantsWithResolvedEntitiesAndLimits = useMemo(() => {
    if (!resolvedReferenceEntities) return covenants
    return covenants.map((covenant, index) => ({
      ...covenant,
      referenceEntities: resolvedReferenceEntities[index],
      ...mappedCovenantsLimits[index],
    }))
  }, [covenants, mappedCovenantsLimits, resolvedReferenceEntities])

  const sourcePathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?working-version=true' : ''),
    [isWorkingVersionDataTile],
  )

  const isError =
    isErrorDealUuid ||
    isErrorDeal ||
    isErrorCovenants ||
    isErrorDealTranches ||
    isErrorReferenceEntities ||
    isErrorCovenantsLimits
  const isLoading =
    isFetchingDealUuid ||
    isFetchingDeal ||
    isFetchingCovenants ||
    isFetchingDealTranches ||
    isFetchingReferenceEntities ||
    isFetchingCovenantsLimits

  return useMemo(() => {
    const data = {
      covenants: covenantsWithResolvedEntitiesAndLimits,
      sourceRender: {
        dealId,
        sourcePathSuffix,
      },
    }
    return {
      isError,
      isLoading,
      data: !(isError || isLoading) ? data : undefined,
    }
  }, [covenantsWithResolvedEntitiesAndLimits, dealId, isError, isLoading, sourcePathSuffix])
}

export default useCovenantsRatiosCovenantsOverview
