import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useAssignProperties = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, propertyUuids }) =>
      await post({
        path: `/deals/${dealUuid}/properties`,
        body: propertyUuids,
      }),
    { onSuccess, onError },
  )
}

export default useAssignProperties
