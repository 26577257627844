import { isMissingPermissionError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useSyndicationIntention = (dealId) =>
  useCamelizedResponse(
    useRequest({
      path: `/financing-structures/deals/${dealId}/syndication-intentions`,
      translated: true,
      keys: ['deals', dealId, 'syndicationIntention'],
      options: {
        enabled: !!dealId,
        retry: (_, error) => !isMissingPermissionError(error),
      },
    }),
  )

export default useSyndicationIntention
