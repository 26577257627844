import { MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import { useCallback } from 'react'
import TextWithExpandableJson from 'components/domains/deals/message/TextWithExpandableJson'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'

/**
 * use MessageBox instead | https://fioneer.atlassian.net/browse/CWP-13200
 */
const useShowErrorMessageBox = () => {
  const showMessageBox = useShowMessageBox()

  return useCallback(
    ({ message, error, ...messageBoxProps }, container) =>
      showMessageBox(
        {
          ...messageBoxProps,
          type: MessageBoxTypes.Error,
          children: <TextWithExpandableJson text={message} json={error} />,
        },
        container,
      ),
    [showMessageBox],
  )
}

export default useShowErrorMessageBox
