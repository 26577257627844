import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useAssessmentLevels = () => {
  const response = useCamelizedResponse(
    useRequest({
      path: '/businesspartners/value-helps/assessment-levels',
      keys: ['f4helps', 'assessment-levels'],
    }),
  )
  return useMemo(
    () => ({
      ...response,
      data: response.data
        ? response.data.assessmentLevels?.map(({ level, displayValue }) => ({
            code: String(level),
            displayName: displayValue,
          }))
        : [],
    }),
    [response],
  )
}
