import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import { Input } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DealMobileSuggestionInput from 'components/domains/deals/deal-search/DealMobileSuggestionInput'
import DealSearchSuggestions from 'components/domains/deals/deal-search/DealSearchSuggestions'
import { dealFilterPropTypes } from 'hooks/services/deals/useDeals'

const propTypes = {
  onDealSuggestionSelected: PropTypes.func.isRequired,
  preSelectedValue: PropTypes.string,
  additionalDealFilterValues: dealFilterPropTypes,
}

const maxMobileWidth = 970

/**
 * @typedef {Parameters<typeof Input>[0]} restProps
 * @typedef {object} overrides
 * @property {(deal?: {dealUuid?: string, dealId?: string, dealName?: string}) => void} overrides.onDealSuggestionSelected
 * @param {restProps & Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 */
const DealAutocompleteInput = ({
  onDealSuggestionSelected,
  preSelectedValue = '',
  additionalDealFilterValues,
  ...props
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.deal-search.deal-autocomplete-input',
  })

  const [isMobile, setIsMobile] = useState(false)
  const handleResize = () => {
    // eslint-disable-next-line no-restricted-globals
    const windowWidth = window.innerWidth > 0 ? window.innerWidth : screen.width
    setIsMobile(windowWidth < maxMobileWidth)
  }
  useEffect(() => {
    handleResize() // it's necessary for the first render to call this function, because the resize-event is only triggered on screen resize
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const [currentSearchValue, setCurrentSearchValue] = useState(preSelectedValue)

  /** @param {{detail: {item: import('@fioneer/ui5-webcomponents-react').SuggestionItemDomRef}}} params */
  const onSuggestionItemSelect = ({ detail: { item: selectedSuggestion } }) => {
    const dealUuid = selectedSuggestion.dataset.uuid
    const dealId = selectedSuggestion.dataset.id
    const dealName = selectedSuggestion.dataset.name
    const dealStatus = selectedSuggestion.dataset.status
    const displaySearchValue = selectedSuggestion.textContent
    setCurrentSearchValue(displaySearchValue)
    onDealSuggestionSelected({ dealUuid, dealId, dealName, dealStatus })
  }

  const onSearchValueChanged = ({ target: { value: search } }) => {
    setCurrentSearchValue(search)
    if (!search) {
      onDealSuggestionSelected()
    }
  }

  if (isMobile)
    return (
      <DealMobileSuggestionInput
        currentSearchValue={currentSearchValue}
        onSearchValueChanged={onSearchValueChanged}
        preSelectedValue={preSelectedValue}
        onSuggestionItemSelect={onSuggestionItemSelect}
        additionalDealFilterValues={additionalDealFilterValues}
        {...props}
      />
    )

  return (
    <Input
      noTypeahead
      showSuggestions
      showClearIcon={!!preSelectedValue}
      disabled={!!preSelectedValue}
      onInput={onSearchValueChanged}
      value={currentSearchValue}
      onSuggestionItemSelect={onSuggestionItemSelect}
      {...props}
    >
      <DealSearchSuggestions
        loadingText={t('loading')}
        errorText={t('error')}
        emptyResultsText={t('empty')}
        typeMoreText={t('type-more')}
        currentSearchValue={currentSearchValue}
        additionalDealFilterValues={additionalDealFilterValues}
      />
    </Input>
  )
}

DealAutocompleteInput.propTypes = propTypes

export default DealAutocompleteInput
