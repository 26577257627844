import { Modals, SegmentedButton, SegmentedButtonItem } from '@fioneer/ui5-webcomponents-react'
import { lazy, Suspense, useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import {
  SYNDICATIONS_EXISTING_BUSINESS_READ,
  SYNDICATION_COMMENT_READ,
  SYNDICATION_INTENTION_READ,
  SYNDICATION_STATEMENT_READ,
  SYNDICATION_STRUCTURE_READ,
} from 'api/deals/financing/allowedOperationsConstants'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { useDealWorkingVersionToolbarButtons } from 'components/domains/deals/deal-adjustment/useDealWorkingVersionToolbarButtons'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import LazyLoadingFallbackCard from 'components/ui/card/LazyLoadingFallbackCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useDealMini from 'hooks/services/deals/useDealMini'
import lazyRetry from 'lazyRetry'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'
import { DATA_SOURCES, DEAL_STATUS_RANKS } from 'routes/deals/financing/financingConstants'

const SyndicationCommentCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "SyndicationCommentCard" */ 'routes/deals/syndication/syndicationComment/SyndicationCommentCard'
      ),
    'SyndicationCommentCard',
  ),
)
const SyndicationIntentionCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "SyndicationIntentionCard" */ 'routes/deals/syndication/syndicationIntention/SyndicationIntentionCard'
      ),
    'SyndicationIntentionCard',
  ),
)
const SyndicationStatementCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "SyndicationStatementCard" */ 'routes/deals/syndication/syndicationStatement/SyndicationStatementCard'
      ),
    'SyndicationStatementCard',
  ),
)
const SyndicationStructureCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "SyndicationStructureCard" */ 'routes/deals/syndication/syndicationStructure/SyndicationStructureCard'
      ),
    'SyndicationStructureCard',
  ),
)

const ExistingBusinessSyndicationAgreementsCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ExistingBusinessSyndicationAgreementsCard" */ 'components/domains/deals/syndication/existing-business-syndication-agreements/ExistingBusinessSyndicationAgreementsCard'
      ),
    'ExistingBusinessSyndicationAgreementsCard',
  ),
)

const ExistingBusinessSyndicationStructureCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ExistingBusinessSyndicationStructureCard" */ 'components/domains/deals/syndication/existing-business-syndication-structure/ExistingBusinessSyndicationStructureCard'
      ),
    'ExistingBusinessSyndicationStructureCard',
  ),
)

const DealSyndicationOverview = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.detail.syndications' })
  const {
    deal: { dealUuid, workingVersion },
    financingAllowedOperations: allowedOperations,
  } = useContext(DealContext)
  const { toggleWorkingVersionButton, manageWorkingVersionButton } =
    useDealWorkingVersionToolbarButtons()
  const { isFetching: isFetchingDealMini, data: { rank } = {} } = useDealMini(dealUuid)

  const shouldShowNewAndExistingBusinessSwitch = useMemo(
    () => rank >= DEAL_STATUS_RANKS.SIGNED && rank < DEAL_STATUS_RANKS.DEAD,
    [rank],
  )
  const [searchParams, setSearchParams] = useSearchParams()
  const showToast = Modals.useShowToast()

  // HINT: NB/EB button is not displayed in the working version view
  useEffect(() => {
    if (isFetchingDealMini) return
    if (
      workingVersion === WorkingVersionType.WORKING_VERSION ||
      !shouldShowNewAndExistingBusinessSwitch
    ) {
      const updatedSearchParams = new URLSearchParams(searchParams.toString())
      updatedSearchParams.set('dataSource', DATA_SOURCES.NEW_BUSINESS)
      return setSearchParams(updatedSearchParams.toString())
    }
    if (workingVersion === WorkingVersionType.LIVE && !searchParams.get('dataSource')) {
      return setSearchParams({ dataSource: DATA_SOURCES.EXISTING_BUSINESS })
    }
  }, [
    isFetchingDealMini,
    searchParams,
    setSearchParams,
    shouldShowNewAndExistingBusinessSwitch,
    workingVersion,
  ])
  const selectedBusinessType = useMemo(() => searchParams.get('dataSource'), [searchParams])

  const breadcrumb = { text: t('title') }
  const hasSyndicationCommentCardReadPermission =
    allowedOperations.includes(SYNDICATION_COMMENT_READ)

  const hasSyndicationIntentionReadPermission = allowedOperations.includes(
    SYNDICATION_INTENTION_READ,
  )
  const hasSyndicationStructureReadPermission = allowedOperations.includes(
    SYNDICATION_STRUCTURE_READ,
  )
  const hasSyndicationStatementReadPermission = allowedOperations.includes(
    SYNDICATION_STATEMENT_READ,
  )
  const hasSyndicationsExistingBusinessReadPermission = allowedOperations.includes(
    SYNDICATIONS_EXISTING_BUSINESS_READ,
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [dealUuid],
  )

  const segmentedButton = useMemo(
    () => (
      <SegmentedButton key="business-switch">
        <SegmentedButtonItem
          pressed={selectedBusinessType === DATA_SOURCES.NEW_BUSINESS}
          onClick={() => {
            setSearchParams({ dataSource: DATA_SOURCES.NEW_BUSINESS })
            showToast({
              children: t('header.segmented-button.message.switch-to-new-business-view'),
            })
          }}
        >
          {t('header.segmented-button.new-business')}
        </SegmentedButtonItem>
        <SegmentedButtonItem
          pressed={selectedBusinessType === DATA_SOURCES.EXISTING_BUSINESS}
          onClick={() => {
            setSearchParams({ dataSource: DATA_SOURCES.EXISTING_BUSINESS })
            showToast({
              children: t('header.segmented-button.message.switch-to-existing-business-view'),
            })
          }}
        >
          {t('header.segmented-button.existing-business')}
        </SegmentedButtonItem>
      </SegmentedButton>
    ),
    [selectedBusinessType, t, setSearchParams, showToast],
  )

  const headerActions = useMemo(
    () =>
      createSegmentedHeaderActions(
        [toggleWorkingVersionButton, manageWorkingVersionButton],
        [shouldShowNewAndExistingBusinessSwitch && segmentedButton],
        [<CreateDealEventAction key="create-event-action" />, markFavoriteAction],
      ),
    [
      manageWorkingVersionButton,
      markFavoriteAction,
      segmentedButton,
      shouldShowNewAndExistingBusinessSwitch,
      toggleWorkingVersionButton,
    ],
  )

  const renderNewBusinessView = useCallback(
    () => (
      <CWPLayout overview>
        {hasSyndicationIntentionReadPermission && (
          <Suspense fallback={<LazyLoadingFallbackCard />}>
            <CWPLayout.OneThird>
              <SyndicationIntentionCard />
            </CWPLayout.OneThird>
          </Suspense>
        )}
        {hasSyndicationStatementReadPermission && (
          <Suspense fallback={<LazyLoadingFallbackCard />}>
            <CWPLayout.TwoThirds>
              <SyndicationStatementCard />
            </CWPLayout.TwoThirds>
          </Suspense>
        )}
        {hasSyndicationStructureReadPermission && (
          <Suspense fallback={<LazyLoadingFallbackCard />}>
            <CWPLayout.Full>
              <SyndicationStructureCard />
            </CWPLayout.Full>
          </Suspense>
        )}
        {hasSyndicationCommentCardReadPermission && (
          <Suspense fallback={<LazyLoadingFallbackCard />}>
            <CWPLayout.Full>
              <SyndicationCommentCard />
            </CWPLayout.Full>
          </Suspense>
        )}
      </CWPLayout>
    ),
    [
      hasSyndicationCommentCardReadPermission,
      hasSyndicationIntentionReadPermission,
      hasSyndicationStatementReadPermission,
      hasSyndicationStructureReadPermission,
    ],
  )

  const renderExistingBusinessView = useCallback(
    () => (
      <CWPLayout overview>
        <Suspense fallback={<LazyLoadingFallbackCard />}>
          <CWPLayout.Half>
            <ExistingBusinessSyndicationAgreementsCard />
          </CWPLayout.Half>
          <CWPLayout.Full>
            {hasSyndicationsExistingBusinessReadPermission && (
              <ExistingBusinessSyndicationStructureCard />
            )}
          </CWPLayout.Full>
        </Suspense>
      </CWPLayout>
    ),
    [hasSyndicationsExistingBusinessReadPermission],
  )

  return (
    <DealDetailsWrapper
      childBreadcrumbs={breadcrumb}
      defaultSectionId="syndication-structure-section"
      actions={headerActions}
    >
      {selectedBusinessType === DATA_SOURCES.NEW_BUSINESS && renderNewBusinessView()}
      {selectedBusinessType === DATA_SOURCES.EXISTING_BUSINESS && renderExistingBusinessView()}
    </DealDetailsWrapper>
  )
}

export default DealSyndicationOverview
