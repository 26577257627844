import {
  CheckBox,
  DatePicker,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxWrap,
  Label,
  Text,
  Token,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  valuationReasons as getValuationReason,
  valuationTypes as getValuationTypesBasedOnReason,
} from 'components/domains/properties/valuation//ValuationRequestReasonsUtils'
import styles from 'components/domains/properties/valuation/panels/ValuationRequestCreationPanel.module.css'
import Labeled from 'components/ui/data/Labeled'
import LoadingDropdown from 'components/ui/dropdown/LoadingDropdown'
import TextAreaWithMaxCharacterRestriction from 'components/ui/input/TextAreaWithMaxCharacterRestriction'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import usePropertyValuationReasons from 'hooks/services/properties/valuations/usePropertyValuationReasons'
import {
  setRequestNote,
  setRequestDueDate,
  setRequestReason,
  setRequestType,
} from 'redux/slices/valuation/requestCreationSlice'

const createBusinessEventCheckboxId = 'create-business-event-checkbox'
const requestNoteMaxLength = 255

const ValuationRequestCreationPanel = ({
  properties,
  isBusinessEventChecked,
  onBusinessEventCheckboxClick,
}) => {
  const { localePattern, parse } = useShortDateFormatter()
  const { requestReason, requestNote, requestDueDate, requestType, submitClicked } = useSelector(
    (state) => state.request.requestCreation,
  )
  const { isError, isLoading, data: valuationReasonsData } = usePropertyValuationReasons()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.valuation-requests.create-dialog',
  })
  const dispatch = useDispatch()

  const valuationReasons = () => getValuationReason(isLoading, isError, valuationReasonsData)

  const valuationTypes = () =>
    getValuationTypesBasedOnReason(isLoading, isError, valuationReasonsData, requestReason)

  const reasonChanged = useCallback(
    (value) => {
      dispatch(setRequestReason(value))
      if (value === '') dispatch(setRequestType(value))
    },
    [dispatch],
  )
  const onRequestTypeChange = useCallback((value) => dispatch(setRequestType(value)), [dispatch])
  const onNoteChange = useCallback((newNote) => dispatch(setRequestNote(newNote)), [dispatch])
  const onDueDateChange = useCallback(
    ({ target: { liveValue: valuationDate } }) => {
      const isoValuationDate = parse(valuationDate, localePattern)
      dispatch(setRequestDueDate(isoValuationDate))
    },
    [dispatch, localePattern, parse],
  )
  const singlePropertyDisplay = useMemo(() => {
    const [{ propertyName, propertyAddress: address }] = properties
    return (
      <FlexBox className={styles.propertyInfoWrapper} direction={FlexBoxDirection.Column}>
        <Text className={styles.propertyName}>{propertyName}</Text>
        {address && <Text>{`${address.streetName} ${address.houseId} ${address.cityName}`}</Text>}
      </FlexBox>
    )
  }, [properties])
  const multiPropertiesDisplay = useMemo(() => {
    const tokens = properties.map(({ propertyName, propertyDisplayId }) => (
      <Token readonly key={propertyDisplayId} text={`${propertyName} (${propertyDisplayId})`} />
    ))
    return (
      <FlexBox className={styles.propertiesTokenWrapper}>
        <FlexBox
          alignItems={FlexBoxAlignItems.Center}
          wrap={FlexBoxWrap.Wrap}
          className={styles.propertiesTokenizer}
        >
          {tokens}
        </FlexBox>
      </FlexBox>
    )
  }, [properties])

  const onCreateBusinessEventCheckboxChange = useCallback(
    (event) => {
      onBusinessEventCheckboxClick(event.target.checked)
    },
    [onBusinessEventCheckboxClick],
  )

  return (
    <>
      <Label>{t('info-panel.title')}</Label>
      <FlexBox direction={FlexBoxDirection.Column} className={styles.formFields}>
        {properties.length === 1 ? singlePropertyDisplay : multiPropertiesDisplay}

        <Labeled label={t('panel.reason-column')} required showColon vertical>
          <LoadingDropdown
            id="filter-request-reason-select"
            value={requestReason}
            onChange={reasonChanged}
            useLoader={valuationReasons}
            initialDropdownValue={' '}
            valueState={submitClicked && requestReason === '' ? 'Error' : ''}
          />
        </Labeled>

        <Labeled label={t('panel.type-column')} required showColon vertical>
          <LoadingDropdown
            id="filter-request-type-select"
            value={requestType}
            disabled={requestReason === ''}
            onChange={onRequestTypeChange}
            useLoader={valuationTypes}
            initialDropdownValue={' '}
            valueState={submitClicked && requestType === '' ? 'Error' : ''}
          />
        </Labeled>

        <Labeled label={t('panel.due-date-column')} showColon vertical>
          <DatePicker
            formatPattern={localePattern}
            onChange={onDueDateChange}
            value={requestDueDate}
          />
        </Labeled>

        <Labeled label={t('info-panel.description-column')} showColon vertical>
          <TextAreaWithMaxCharacterRestriction
            id="market-description"
            value={requestNote}
            onInput={onNoteChange}
            maxLength={requestNoteMaxLength}
            valueState={submitClicked && requestType === '' ? 'Error' : ''}
          />
        </Labeled>
      </FlexBox>

      <FlexBox alignItems={FlexBoxAlignItems.Center}>
        <CheckBox
          checked={isBusinessEventChecked}
          onChange={onCreateBusinessEventCheckboxChange}
          id={createBusinessEventCheckboxId}
        />
        <Label for={createBusinessEventCheckboxId}>{t('panel.checkbox-label')}</Label>
      </FlexBox>
    </>
  )
}

export const propertyAddressShape = PropTypes.shape({
  streetName: PropTypes.string.isRequired,
  houseId: PropTypes.string.isRequired,
  cityName: PropTypes.string.isRequired,
})

export const propertiesShape = PropTypes.arrayOf(
  PropTypes.shape({
    propertyName: PropTypes.string.isRequired,
    propertyDisplayId: PropTypes.string.isRequired,
    propertyUuid: PropTypes.string.isRequired,
    propertyAddress: propertyAddressShape.isRequired,
    allowedOperations: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
)

ValuationRequestCreationPanel.propTypes = {
  properties: propertiesShape,
  isBusinessEventChecked: PropTypes.bool.isRequired,
  onBusinessEventCheckboxClick: PropTypes.func.isRequired,
}

export default ValuationRequestCreationPanel
