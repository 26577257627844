import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'

const requirementConditionChanged = (state, { payload: { condition: newCondition } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }
  state.editedRow.currentValues.condition = newCondition
    ? {
        id: newCondition.id,
        name: newCondition.name,
        references: newCondition.references,
      }
    : null

  if (isNil(state.editedRow.currentValues.condition)) {
    state.editedRow.errorFields.condition = true
  } else {
    delete state.editedRow.errorFields.condition
  }

  if (newCondition?.id === state.editedRow.initialValues.condition.id) {
    delete state.editedRow.changedFields.condition
    return
  }

  state.editedRow.changedFields.condition = true
}

export default requirementConditionChanged
