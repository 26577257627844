import { ObjectStatus, Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import { useExternalInformationFields } from 'components/domains/deals/deal-cashflow/general-information/useExternalInformationFields'
import { FormattedDateText } from 'components/domains/deals/deal-search/FormattedDateText'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import { useBooleanToTextFormatter } from 'hooks/i18n/useI18n'
import useMultipleStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useMultipleStaffMemberByObjectIdOrEmail'

const DealCashflowScenarioGeneralInformationCardDisplay = ({ cashflowScenario }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.cashFlow.details.general-information',
  })
  const formatBoolean = useBooleanToTextFormatter()

  const statusConfig = {
    LINKED: { color: ValueState.Success, label: t('status.linked') },
    NOT_LINKED: { color: ValueState.Warning, label: t('status.not-linked') },
  }[cashflowScenario?.status]

  const {
    data: staffMembers,
    isSomeLoading: isLoadingStaffMembers = false,
    isSomeError: isErrorStaffMembers = false,
  } = useMultipleStaffMemberByObjectIdOrEmail({
    objectIdOrEmailAddressList: [cashflowScenario?.createdBy, cashflowScenario?.lastUpdatedBy],
  })

  const { data: displayItems } = useExternalInformationFields(cashflowScenario.extensionFields)

  return (
    <>
      <CardSection title={t('subtitle.basic-information')}>
        <CardShowItem label={t('scenario-name')} value={cashflowScenario.name} />
        <CardShowItem
          label={t('status')}
          value={
            <ObjectStatus inverted state={statusConfig?.color}>
              {statusConfig?.label}
            </ObjectStatus>
          }
        />
        <CardShowItem label={t('frozen')} value={formatBoolean(cashflowScenario?.frozen)} />
        <CardShowItem
          label={t('created-by')}
          value={
            <SmallLoadingWrapper
              error={cashflowScenario?.createdBy}
              isLoading={isLoadingStaffMembers}
              isError={isErrorStaffMembers}
              renderContent={() => (
                <Text>
                  {staffMembers[cashflowScenario?.createdBy]?.fullName ||
                    cashflowScenario?.createdBy}
                </Text>
              )}
            />
          }
        />
        <CardShowItem
          label={t('created-at')}
          value={<FormattedDateText dateString={cashflowScenario?.createdAt} showTime />}
        />
        <CardShowItem label={t('description')} value={cashflowScenario?.description} />
      </CardSection>
      <CardSection title={t('subtitle.external-information')}>
        <CardShowItem
          label={t('external-scenario-id')}
          value={cashflowScenario?.externalScenarioId}
        />
        {displayItems}
      </CardSection>
    </>
  )
}

export const cashflowScenarioShape = PropTypes.shape({
  name: PropTypes.string,
  status: PropTypes.string,
  frozen: PropTypes.bool,
  createdBy: PropTypes.string,
  createdAt: PropTypes.string,
  lastUpdatedBy: PropTypes.string,
  lastUpdatedAt: PropTypes.string,
  description: PropTypes.string,
  extensionFields: PropTypes.array,
  externalScenarioId: PropTypes.string,
})

DealCashflowScenarioGeneralInformationCardDisplay.propTypes = {
  cashflowScenario: cashflowScenarioShape,
}

export default DealCashflowScenarioGeneralInformationCardDisplay
