import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useChargesByUuid = (propertyUuid) =>
  useCamelizedResponse(
    useRequest({
      path: `/properties/${propertyUuid}/charges`,
      useCache: true,
      keys: ['property-charges', propertyUuid],
      options: {
        retry: 0,
        enabled: !!propertyUuid,
      },
    }),
  )

export default useChargesByUuid
