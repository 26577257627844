import {
  Button,
  Link,
  AnalyticalTableScaleWidthMode,
  AnalyticalTableSelectionBehavior,
  AnalyticalTableSelectionMode,
  TextAlign,
  ButtonDesign,
} from '@fioneer/ui5-webcomponents-react'

import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DocumentDownload from 'components/domains/documents/DocumentDownload'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import DocumentUploadDialog from 'components/domains/documents/DocumentUploadDialog'
import mapDocumentsForTable from 'components/domains/documents/documents-table/mapDocumentsForTable'
import {
  labelNewDocuments,
  sortColumnWithStatus,
} from 'components/domains/markets/detail/cards/MarketDocumentTableFunctions'
import styles from 'components/domains/markets/detail/cards/MarketDocumentsTable.module.css'
import AnalyticalTableNoDataComponent from 'components/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { UserProfileContext } from 'routes/UserProfileContext'
import { getVersionFileName } from 'routes/documents/document-versions/DocumentVersionsTable'

const downloadPropTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}
/** @param {PropTypes.InferProps<typeof downloadPropTypes>} props */
const Download = ({ cell }) => {
  const row = cell.row.original
  const id = row?.id
  const versionGuid = cell.row?.original?.versions?.find((version) => version.isWorkVersion).guid
  const activeVersion = cell.row?.original?.versions?.find((version) => version.isWorkVersion)
  const activeFileName =
    activeVersion && getVersionFileName(cell.row?.original?.fileName, activeVersion.versionExt)

  return (
    <DocumentDownload
      type="icon"
      documentId={id}
      documentVersionGuid={versionGuid}
      fileName={activeFileName}
    />
  )
}

Download.propTypes = downloadPropTypes

/** @param {string} [guid] */
const toDocumentPage = (guid) => {
  if (!guid) return
  window.open(`/documents/${guid}`, '_blank')
}

const namePropTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
      subRows: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    }),
    value: PropTypes.string,
  }).isRequired,
}
/** @param {PropTypes.InferProps<typeof namePropTypes>} props */
const Name = ({ cell }) => {
  const isLeafRow = cell.row?.subRows.length === 0
  if (!isLeafRow) return cell.value

  return (
    <>
      <div className={styles.nameOverflow}>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => toDocumentPage(cell?.row?.original?.id)}
        >
          {cell.value}
        </Link>
      </div>
      <div className={styles.icon}>
        <Download cell={cell} />
      </div>
    </>
  )
}

Name.propTypes = namePropTypes

/**
 * @param {DateTime} dateTime
 * @param {string} [locale]
 */
const formatDateTime = (dateTime, locale) =>
  dateTime.toLocaleString(
    {
      weekday: 'short',
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    },
    { locale },
  )

const dateCellPropTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
}
/** @param {PropTypes.InferProps<typeof dateCellPropTypes>} props */
const DateCell = ({ cell: { value } }) => {
  const { format: formatDate } = useShortDateFormatter()
  const { language, zoneId } = useContext(UserProfileContext)
  return (
    value && (
      <span title={formatDateTime(DateTime.fromISO(value, { zone: zoneId }), language)}>
        {formatDate(value)}
      </span>
    )
  )
}

DateCell.propTypes = dateCellPropTypes

const propTypes = {
  allowUploadDocument: PropTypes.bool,
  uploadInitialState: PropTypes.shape({
    // HINT: FileList doesn't have a constructor, so we can't test it => use `File[]`
    files: PropTypes.arrayOf(PropTypes.instanceOf(File).isRequired),
  }),
  marketName: PropTypes.string.isRequired,
  marketId: PropTypes.string.isRequired,
  documents: PropTypes.shape({
    subFolders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string,
        fileName: PropTypes.string,
        fileExtension: PropTypes.string,
        modificationTime: PropTypes.string,
        keyDate: PropTypes.string,
      }).isRequired,
    ),
  }),
  headerTitle: PropTypes.string.isRequired,
  displayedColumns: PropTypes.arrayOf(PropTypes.string.isRequired),
  onUploadSuccess: PropTypes.func,
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const MarketDocumentsTable = ({
  documents,
  headerTitle,
  marketName,
  marketId,
  allowUploadDocument = false,
  uploadInitialState,
  onUploadSuccess,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.markets.detail.reports.documents',
  })

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false)

  useEffect(() => {
    setIsUploadDialogOpen(!!uploadInitialState?.files)
  }, [uploadInitialState?.files])

  const [newDocumentIds, setNewDocumentIds] = useState(/** @type {string[]} */ ([]))

  const markedDocuments = useMemo(
    () => labelNewDocuments(documents, newDocumentIds),
    [newDocumentIds, documents],
  )

  const preparedDocuments = useMemo(() => mapDocumentsForTable(markedDocuments), [markedDocuments])

  /** @param {string[]} [uploadedDocumentIds] */
  const handleUploadFinished = (uploadedDocumentIds = []) => {
    setNewDocumentIds(uploadedDocumentIds)
    onUploadSuccess?.()
  }

  const columns = useMemo(() => {
    const allColumns = [
      {
        Header: t('columns.name'),
        title: t('columns.name'),
        accessor: 'name',
        filterType: filterTypes.CONTAINS,
        Cell: Name,
        sortType: (row1, row2, columnId, descending) =>
          sortColumnWithStatus(row1, row2, columnId, descending),
        isSelectableForHiding: true,
        isVisible: true,
      },
      {
        Header: t('columns.type'),
        title: t('columns.type'),
        accessor: 'type',
        filterType: filterTypes.CONTAINS,
        sortType: (row1, row2, columnId, descending) =>
          sortColumnWithStatus(row1, row2, columnId, descending),
        isSelectableForHiding: true,
        isVisible: false,
      },
      {
        Header: t('columns.format'),
        title: t('columns.format'),
        accessor: 'fileExtension',
        filterType: filterTypes.CONTAINS,
        sortType: (row1, row2, columnId, descending) =>
          sortColumnWithStatus(row1, row2, columnId, descending),
        isSelectableForHiding: true,
        isVisible: false,
        disableDragAndDrop: true,
      },
      {
        Header: t('columns.last-modified-at'),
        title: t('columns.last-modified-at'),
        accessor: 'modificationTime',
        Cell: DateCell,
        hAlign: TextAlign.End,
        filterType: filterTypes.BETWEEN_DATES,
        sortType: (row1, row2, columnId, descending) =>
          sortColumnWithStatus(row1, row2, columnId, descending),
        isSelectableForHiding: true,
        isVisible: false,
      },
      {
        Header: t('columns.key-date'),
        title: t('columns.key-date'),
        accessor: 'keyDate',
        Cell: DateCell,
        //width must be defined, so that the AnalytacalTableScaleWidthMode.Grow fills the remaining space
        width: 100,
        hAlign: TextAlign.End,
        filterType: filterTypes.BETWEEN_DATES,
        sortType: (row1, row2, columnId, descending) =>
          sortColumnWithStatus(row1, row2, columnId, descending),
        isSelectableForHiding: true,
        isVisible: true,
      },
    ]

    return allColumns.map((column) => ({
      disableDragAndDrop: true,
      ...column,
    }))
  }, [t])

  const handleUploadDialogIsOpen = () => setIsUploadDialogOpen(true)

  return (
    <>
      <AnalyticalTableWithToolbar
        title={headerTitle}
        columns={columns}
        withRowHighlight
        highlightField={'status'}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
        data={preparedDocuments.subFolders}
        sortable
        overscanCountHorizontal={99}
        filterable
        showColumnSelection
        subRowsKey="subFolders"
        isTreeTable
        // Upgrading ui5 requires to set values here, otherwise tests will fail
        rowHeight={32}
        headerRowHeight={30}
        minRows={1}
        className={styles.table}
        selectionMode={AnalyticalTableSelectionMode.None}
        selectionBehavior={AnalyticalTableSelectionBehavior.RowSelector}
        reactTableOptions={{
          initialState: { sortBy: [{ id: 'keyDate', desc: true }] },
        }}
        additionalActions={
          allowUploadDocument
            ? [
                <Button
                  key="upload"
                  onClick={handleUploadDialogIsOpen}
                  design={ButtonDesign.Transparent}
                >
                  {t('upload')}
                </Button>,
              ]
            : []
        }
        NoDataComponent={() => (
          <AnalyticalTableNoDataComponent
            isLoading={false}
            isError={false}
            tableId={'market-documents-table'}
          />
        )}
      />
      <DocumentUploadDialog
        initialState={{
          ...uploadInitialState,
          selectedEntities: [{ name: marketName, id: marketId }],
        }}
        isOpen={isUploadDialogOpen}
        setIsOpen={setIsUploadDialogOpen}
        onUploadFinished={handleUploadFinished}
        type={DocumentTypes.Market}
      />
    </>
  )
}

MarketDocumentsTable.propTypes = propTypes

export default MarketDocumentsTable
