import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import { Icon, SuggestionItem, Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import throttle from 'lodash.throttle'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessPartnerCreateDialog from 'components/domains/business-partners/BusinessPartnerCreateDialog'
import BusinessPartnerSearchDialog from 'components/domains/business-partners/BusinessPartnerSearchDialog'
import styles from 'components/domains/deals/covenants/create-dialog/BusinessPartnerSearchInput.module.css'
import InputWithClearIcon from 'components/ui/input/InputWithClearIcon'
import { useMatchingBusinessPartners } from 'hooks/services/business-partners/searchBusinessPartners'

export const useMatchingBusinessPartnersThrottled = (
  throttleTimeout,
  additionalSearchParams = {},
) => {
  const [search, setSearchInternal] = useState('')
  const setSearch = useMemo(
    () => throttle(setSearchInternal, throttleTimeout, { trailing: true }),
    [throttleTimeout],
  )
  const result = useMatchingBusinessPartners(
    {
      ...additionalSearchParams,
      sort: '+id',
      q: search,
    },
    { enabled: !!search },
  )
  return { ...result, data: result.data?.businessPartners || [], setSearch, search }
}

const includesText = (dataProps, value) => dataProps.find(({ text }) => text === value)

const DEFAULT_THROTTLE_TIMEOUT = 500
const CREATE_NEW_BUSINESS_PARTNER_KEY = 'create-new-business-partner'

const BusinessPartnerAutocompleteInput = ({
  onBusinessPartnerSelected = (_id, _data, _evt) => {},
  valueForUnknown,
  defaultValue = '',
  additionalSearchParams = {},
  onInput = () => {},
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  throttleTimeout = DEFAULT_THROTTLE_TIMEOUT,
  suggestionItemProps = ({ name, id }) => ({ text: name, additionalText: id }),
  getSearchString = (input) => input,
  valueState,
  withCreateOption = false,
  createDialogOptions,
  ...rest
}) => {
  const { t } = useTranslation()
  const [touched, setTouched] = useState(false)
  const [value, setValue] = useState(defaultValue)
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [initialBusinessPartners, setInitialBusinessPartners] = useState([])
  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])
  const { data, isLoading, isError, setSearch } = useMatchingBusinessPartnersThrottled(
    throttleTimeout,
    additionalSearchParams,
  )
  useEffect(() => {
    setSearch(getSearchString(value))
  }, [value, setSearch, getSearchString])

  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false)

  const dataProps = data.map(suggestionItemProps)

  const handleOnInput = (evt) => {
    setValue(evt.target.value)
    onInput(evt)
    // call onBusinessPartnerSelected if the clear icon has been clicked
    if (!evt.target.value) {
      onBusinessPartnerSelected(undefined, valueForUnknown?.(evt.target.value), evt)
    }
  }
  const handleOnChange = (evt) => {
    setTouched(true)
    onChange(evt)
    if (valueForUnknown && !includesText(dataProps, evt.target.value)) {
      onBusinessPartnerSelected(undefined, valueForUnknown(evt.target.value), evt)
    }
  }
  const handleOnFocus = (evt) => {
    setTouched(false)
    setValue(getSearchString(value))
    return onFocus(evt)
  }
  const handleOnBlur = (evt) => {
    const match = dataProps?.find(({ text }) => getSearchString(text) === value)
    if (value?.length > 0 && match?.text) {
      setValue(match?.text)
    }
    setTouched(true)
    return onBlur(evt)
  }
  const handleOnSuggestionItemSelect = (evt) => {
    const key = evt.detail?.item?.getAttribute('data-key')
    if (key === CREATE_NEW_BUSINESS_PARTNER_KEY) {
      setIsCreateDialogOpen(true)
      return
    }
    const suggestionItem = data[key] || {}
    setValue(suggestionItemProps(suggestionItem).text)
    onBusinessPartnerSelected(suggestionItem?.id, suggestionItem, evt)
  }

  let state = valueState
  let stateMessage
  if (!valueForUnknown && !state && touched && !isLoading && !includesText(dataProps, value)) {
    state = ValueState.Error
    stateMessage = <Text wrapping>{t('form.validation.unknownSelection')}</Text>
  }
  if (!state && isError) {
    state = ValueState.Warning
    stateMessage = <Text wrapping>{t('select.loading.error')}</Text>
  }

  const handleIconClick = () => setIsSearchDialogOpen(true)
  const handleSearchDialogSelectionChange = (selectedBusinessPartners) => {
    setValue(suggestionItemProps(selectedBusinessPartners[0]).text)
    setInitialBusinessPartners(selectedBusinessPartners)

    const selectedBPRoles = selectedBusinessPartners[0].roles
      .flatMap((it) => it.backEndRoles)
      .map((it) => it.id)
    const allowedRoles = additionalSearchParams.roles ?? []
    const isAllowed =
      allowedRoles.length === 0 ||
      selectedBPRoles.filter((role) => allowedRoles.includes(role)).length > 0

    if (isAllowed) {
      onBusinessPartnerSelected(
        selectedBusinessPartners[0].id,
        selectedBusinessPartners[0],
        undefined,
      )
    } else {
      onBusinessPartnerSelected(undefined, { fullName: '' }, undefined)
    }
  }

  const suggestions = dataProps.map((props, index) => (
    <SuggestionItem {...props} data-key={index} key={index} />
  ))

  return (
    <>
      <InputWithClearIcon
        showSuggestions
        noTypeahead={!!valueForUnknown}
        value={value}
        onInput={handleOnInput}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onSuggestionItemSelect={handleOnSuggestionItemSelect}
        valueState={state}
        valueStateMessage={stateMessage}
        {...rest}
        icon={
          <Icon
            id="open-search-dialog"
            name="value-help"
            onClick={handleIconClick}
            className={styles.actionIcon}
          />
        }
      >
        {suggestions && [
          ...suggestions,
          withCreateOption && (
            <SuggestionItem
              key={CREATE_NEW_BUSINESS_PARTNER_KEY}
              data-key={CREATE_NEW_BUSINESS_PARTNER_KEY}
              additionalText={t('buttons.create')}
              additionalTextState={ValueState.Information}
            />
          ),
        ]}
      </InputWithClearIcon>
      {isSearchDialogOpen && (
        <BusinessPartnerSearchDialog
          open={isSearchDialogOpen}
          isMultiSelect={false}
          withCreateOption={withCreateOption}
          createDialogOptions={createDialogOptions}
          onChange={handleSearchDialogSelectionChange}
          onClose={() => setIsSearchDialogOpen(false)}
          initialRoles={additionalSearchParams.roles ?? []}
          initialSearch={getSearchString(value)}
          initialBusinessPartners={initialBusinessPartners}
        />
      )}
      {isCreateDialogOpen && (
        <BusinessPartnerCreateDialog
          open={isCreateDialogOpen}
          onClose={() => setIsCreateDialogOpen(false)}
          onAfterCreate={(businessPartner) => handleSearchDialogSelectionChange([businessPartner])}
          {...createDialogOptions}
        />
      )}
    </>
  )
}

BusinessPartnerAutocompleteInput.propTypes = {
  onBusinessPartnerSelected: PropTypes.func.isRequired,
  valueForUnknown: PropTypes.func,
  defaultValue: PropTypes.string,
  additionalSearchParams: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string),
    minLength: PropTypes.number,
  }),
  onInput: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  throttleTimeout: PropTypes.number,
  valueState: PropTypes.oneOf(['None', 'Error', 'Success', 'Warning', 'Information']),
  suggestionItemProps: PropTypes.func,
  getSearchString: PropTypes.func,
  withCreateOption: PropTypes.bool,
  createDialogOptions: PropTypes.shape({
    title: PropTypes.string,
    creationRole: PropTypes.string,
  }),
}

export default BusinessPartnerAutocompleteInput
