import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const ConditionsChangelogColumn = {
  changedInformation: 'changedInformation',
  action: 'action',
  changedAt: 'changedAt',
  changedBy: 'changedBy',
}

const cellDefaults = {
  disableResizing: false,
  disableDragAndDrop: false,
  disableGlobalFilter: true,
  disableGroupBy: true,
}

const useConditionsChangelogColumns = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.dialog.changelog.table.columns',
  })
  return useMemo(
    () => ({
      tableColumns: [
        {
          ...cellDefaults,
          columnKey: ConditionsChangelogColumn.changedInformation,
          title: t('changed-information'),
        },
        {
          ...cellDefaults,
          columnKey: ConditionsChangelogColumn.action,
          title: t('action'),
        },
        {
          ...cellDefaults,
          columnKey: ConditionsChangelogColumn.changedAt,
          title: t('changed-at'),
          width: 200,
        },
        {
          ...cellDefaults,
          columnKey: ConditionsChangelogColumn.changedBy,
          title: t('changed-by'),
          width: 200,
        },
      ],
    }),
    [t],
  )
}

export default useConditionsChangelogColumns
