import PropTypes from 'prop-types'
import ConfigurableComponentsPage from 'components/ui/configurable-components-page/ConfigurableComponentsPage'
import TabPage from 'components/ui/page/TabPage'
import { availableDynamicPagesEnum } from 'hooks/page-config/useGetPageConfig'

const PropertyOverviewDynamic = ({ header }) => (
  <ConfigurableComponentsPage
    pageCode={availableDynamicPagesEnum.PropertiesOverview}
    renderContent={({ children, pageConfig }) => (
      <TabPage headerContent={header} defaultSectionId={pageConfig.defaultTab}>
        {children}
      </TabPage>
    )}
  />
)
PropertyOverviewDynamic.propTypes = {
  header: PropTypes.node.isRequired,
}
export default PropertyOverviewDynamic
