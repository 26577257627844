import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import { emptyContent } from 'components/domains/business-events-and-tasks/decision-paper/tiles/loan-market-statement/shared/ui/rich-text-editor/CWPCKEditor'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/loan-market-statement/shared/ui/rich-text-editor/FormattedTextView.module.css'
import ImageView from 'components/domains/business-events-and-tasks/decision-paper/tiles/loan-market-statement/shared/ui/rich-text-editor/ImageView'

const FormattedTextView = ({ formattedText, ...otherProps }) => (
  <div className={styles.formattedTextView} {...otherProps}>
    <div className="ck-content">
      {formattedText &&
        formattedText !== emptyContent &&
        parse(formattedText, {
          replace: ({ name, attribs }) => {
            if (name === 'img') {
              return <ImageView {...attribs} />
            }
          },
        })}
    </div>
  </div>
)

FormattedTextView.propTypes = {
  formattedText: PropTypes.string.isRequired,
}

export default FormattedTextView
