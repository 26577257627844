import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

/**
 * @typedef {{version: number, content: string, createdAt: string, createdBy: string}} TextualAssessment
 * @typedef {{availableVersions: TextualAssessment[], _allowedOperations: string[]}} TextualAssessmentResponse
 * @typedef {'description' | 'front_office' | 'back_office'} TextualAssessmentType
 * @param {{ businessPartnerIds: string[], type: TextualAssessmentType, options: import('@tanstack/react-query').QueryOptions }} options
 * @return {import('@tanstack/react-query').UseQueryResult<TextualAssessmentResponse>[]}
 */
export const useMultipleTextualAssessments = ({ businessPartnerIds, type, options }) => {
  const requests = businessPartnerIds.map((businessPartnerId) => ({
    path: `/businesspartners/${businessPartnerId}/textual-assessments/${type}`,
    keys: ['businesspartners', businessPartnerId, 'textual-assessments', type],
  }))
  const responses = useRequestsMemo(useRequests({ requests, translated: true, options }))

  return useMemo(
    () =>
      responses.map((result) => ({
        ...result,
        data: camelize(result.data),
      })),
    [responses],
  )
}
