import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const NOT_FOUND = 404
const checkForNotFoundError = (error) => error.response.status === NOT_FOUND

export const useRentRollWorkingVersionColumnMapping = (propertyUuids) => {
  const { post } = useAccessTokenRequest()

  const [isNotFoundError, setNotFoundError] = useState(false)
  const { data: columnMapping } = useQuery({
    queryFn: async () =>
      post({
        path: '/properties/rent-roll/working-version/column-mapping',
        body: {
          property_uuids: propertyUuids,
        },
      }),
    retry: (_, error) => {
      if (checkForNotFoundError(error)) {
        setNotFoundError(true)
        return false
      }
      return true
    },
    queryKey: ['column_mapping', ...propertyUuids],
  })
  return {
    columnMapping: columnMapping,
    isNotFoundError: isNotFoundError,
  }
}
