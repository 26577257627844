export const entitiesQuantity = {
  single: 'SINGLE',
  multiple: 'MULTIPLE',
  none: 'NONE',
}

// copied from 'components/ui/data/ReferenceEntitiesView'
export const mapReferenceEntitiesToViewProps = ({
  ids,
  id,
  name,
  link,
  linkText,
  listItems,
  linkBasePath,
  linkSuffix,
  workingVersion,
}) => {
  if (ids.length === 0) {
    return {
      quantity: entitiesQuantity.none,
    }
  }

  if (ids.length === 1) {
    return {
      quantity: entitiesQuantity.single,
      id,
      name,
      link,
      linkSuffix,
      workingVersion,
    }
  }

  return {
    quantity: entitiesQuantity.multiple,
    linkText,
    listItems,
    link,
    linkSuffix,
    linkBasePath,
    workingVersion,
  }
}
