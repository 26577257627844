import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultipleArrearApprovalsByBusinessPartnerId = ({ businessPartnerIds, options = {} }) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: businessPartnerIds.map((businessPartnerId) => ({
        path: `/arrears/approvals/${businessPartnerId}`,
        keys: ['arrears', 'approvals', businessPartnerId],
      })),
      translated: true,
      useCache: true,
      options: { ...options, enabled: !isEmpty(businessPartnerIds) },
    }),
  )

  return useMemo(
    () => ({
      isLoading: responses?.some(({ isLoading }) => isLoading),
      isError: responses?.some(({ isError }) => isError),
      isFetching: responses?.some(({ isFetching }) => isFetching),
      data: responses.map((response, index) => ({
        ...response,
        data: {
          businessPartnerId: businessPartnerIds[index],
          ...camelize(response.data),
        },
      })),
    }),
    [businessPartnerIds, responses],
  )
}

export default useMultipleArrearApprovalsByBusinessPartnerId
