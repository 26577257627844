import isEmpty from 'lodash.isempty'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import PropertyRatingCardHeader from 'components/domains/properties/general-information/rating/PropertyRatingCardHeader'
import PropertyRatingTable from 'components/domains/properties/general-information/rating/PropertyRatingTable'
import PropertyRatingTile from 'components/domains/properties/general-information/rating/PropertyRatingTile'
import Card from 'components/ui/card/Card'
import { useTegovaRating } from 'hooks/services/properties/rating/useTegovaRating'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyRatingCard = () => {
  const {
    property: {
      uuid: propertyUuid,
      change_request_exist_indicator: changeRequestExistIndicator,
      allowed_operations: allowed_operations,
    },
  } = useContext(PropertyContext)

  const { t: tRating } = useTranslation('translation', { keyPrefix: 'pages.property.rating' })

  const allowedOperations = allowed_operations?.allowed_operations ?? []

  const userIsAllowedToEdit =
    !changeRequestExistIndicator && allowedOperations.includes('PropertyRating_Update')

  const {
    isLoading: isLoadingTegovaRating,
    isError: isErrorTegovaRating,
    data,
  } = useTegovaRating([propertyUuid])

  const tegovaRatingData = data?.tegovaRatings?.[0]
  const tegovaRating =
    !isLoadingTegovaRating && !isErrorTegovaRating && !isEmpty(tegovaRatingData)
      ? {
          tegovaRatingUuid: tegovaRatingData.tegovaRatingUuid,
          validFromDate: tegovaRatingData.validFromDate,
          keyDate: tegovaRatingData.keyDate,
          criteriaClasses: {
            market: tegovaRatingData.market,
            location: tegovaRatingData.location,
            property: tegovaRatingData.property,
            cashflow: tegovaRatingData.cashflow,
            total: tegovaRatingData.total,
            developmentRiskAndChances: tegovaRatingData.developmentRiskAndChances,
            salesProspects: tegovaRatingData.salesProspects,
          },
        }
      : {}

  const cardHeader = (
    <PropertyRatingCardHeader tRating={tRating} userIsAllowedToEdit={userIsAllowedToEdit} />
  )

  return (
    <Card header={cardHeader}>
      <PropertyRatingTile
        keyDate={tegovaRating.keyDate}
        validFrom={tegovaRating.validFromDate}
        tRating={tRating}
      />
      <PropertyRatingTable criteriaClasses={tegovaRating.criteriaClasses} />
    </Card>
  )
}

export default PropertyRatingCard
