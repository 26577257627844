import { useRequest } from 'hooks/services/baseService'

const useEventsAllowedOperations = () =>
  useRequest({
    path: '/events/allowed-operations',
    useCache: true,
    keys: ['events', 'allowed-operations'],
  })

export default useEventsAllowedOperations
