import isEmpty from 'lodash.isempty'
import { isNotFoundError } from 'api/requests'
import { Direction } from 'components/domains/deals/covenants/shared/useDirectionTranslation'
import { temporalUnitEnum } from 'components/domains/deals/covenants/shared/useTemporalUnitTranslation'
import { useCovenantMonitoringWithDefault } from 'hooks/services/deals/covenants/monitorings/useCovenantMonitoring'
import useCovenantMonitoringItems from 'hooks/services/deals/covenants/monitorings/useCovenantMonitoringItems'

const getNewMonitoringInformation = (checkCycle = {}) => ({
  checkRequired: false,
  firstDueDate: {
    keyDate: null,
    targetDeliveryDateBaseData: {
      amountToAdd: '',
      direction: Direction.AFTER,
      temporalUnit: temporalUnitEnum.DAYS,
    },
    checkDateBaseData: {
      amountToAdd: '',
      direction: Direction.AFTER,
      temporalUnit: temporalUnitEnum.DAYS,
    },
    deliveryDate: null,
    checkDate: null,
  },
  checkCycle: checkCycle,
  nextCovenantMonitoringItemUuid: null,
})

const usePeriodicalMonitoringInformationData = ({ covenantUuid, covenantDefinition }) => {
  const defaultCheckCycle = {}
  if (!isEmpty(covenantDefinition?.checkCycle)) {
    defaultCheckCycle.number = covenantDefinition?.checkCycle?.['defaultNumber']
    defaultCheckCycle.cycle = covenantDefinition?.checkCycle?.['default']
  }

  const {
    data: monitoringInformation = {},
    isFetching: isFetchingMonitoring,
    isError: isErrorMonitoring,
  } = useCovenantMonitoringWithDefault(getNewMonitoringInformation(defaultCheckCycle), covenantUuid)

  const monitoringItemsResponse = useCovenantMonitoringItems(covenantUuid)
  const isMonitoringItemsNotFound =
    monitoringItemsResponse?.isError && isNotFoundError(monitoringItemsResponse?.error)
  const {
    isFetching: isFetchingMonitoringItems,
    isError: isErrorMonitoringItems,
    data: { covenantMonitoringItems: monitoringItems = [] } = {},
  } = monitoringItemsResponse

  const monitoringItem =
    monitoringItems?.find(
      ({ covenantMonitoringItemUuid }) =>
        covenantMonitoringItemUuid === monitoringInformation?.nextCovenantMonitoringItemUuid,
    ) ?? {}

  const isLoading = isFetchingMonitoring || isFetchingMonitoringItems
  const isError = (isMonitoringItemsNotFound ? false : isErrorMonitoringItems) || isErrorMonitoring

  return {
    monitoringInformation,
    monitoringItem,
    isLoading,
    isError,
  }
}

export default usePeriodicalMonitoringInformationData
