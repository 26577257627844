import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useAssessmentUpdate = ({ businessPartnerId, onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()
  const queryClient = useQueryClient()

  const innerOnSuccess = () => {
    queryClient.invalidateQueries(['businesspartners', businessPartnerId, 'assessments'])
    onSuccess()
  }

  return useMutation({
    mutationFn: async (level) => {
      const body = { assessments: [{ level }] }
      const { data } = await put({
        path: `/businesspartners/${businessPartnerId}/assessments`,
        body: body,
      })
      return data
    },
    onSuccess: innerOnSuccess,
    onError: onError,
  })
}

export default useAssessmentUpdate
