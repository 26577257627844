import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultipleGeneralInformation = ({ eventId, dealUuids, options = {} }) => {
  const queryParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams()

    if (eventId) {
      urlSearchParams.append('eventId', eventId)
    }

    return urlSearchParams
  }, [eventId])

  const paramsString = [...queryParams.keys()].length ? `?${queryParams.toString()}` : ''

  const responses = useRequestsMemo(
    useRequests({
      requests: dealUuids.map((dealUuid) => ({
        path: `/pbb-custom-service/decision-paper/deals/${dealUuid}/general-information${paramsString}`,
        keys: [
          'pbb-custom-service',
          'decision-paper',
          'deals',
          dealUuid,
          'general-information',
          eventId,
        ],
      })),
      translated: true,
      useCache: true,
      options: { ...options, enabled: !isEmpty(dealUuids) },
    }),
  )

  return useMemo(
    () =>
      responses.map((result, index) => ({
        ...result,
        data: {
          dealUuid: dealUuids[index],
          ...camelize(result.data),
        },
      })),
    [dealUuids, responses],
  )
}

export default useMultipleGeneralInformation
