import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useUpdateApprovalItem } from 'hooks/services/arrears/useUpdateApprovalItem'

export const useUpdateMultipleApprovalItems = ({ businessPartnerId, onFinish = () => {} }) => {
  const [expectedUpdates, setExpectedUpdates] = useState(0)

  const [isUpdating, setIsUpdating] = useState(false)

  const [errorText, setErrorText] = useState([])

  const decrementUpdateCount = () => setExpectedUpdates((count) => count - 1)

  const queryClient = useQueryClient()

  const onErrorUpdate = async (error) => {
    const errorResponseJson = await error.response.json()
    setErrorText((currentErrors) => [...currentErrors, errorResponseJson])
    decrementUpdateCount()
  }

  const updateApprovalItem = useUpdateApprovalItem({
    onSuccess: decrementUpdateCount,
    onError: onErrorUpdate,
  })

  const updateApprovalItems = (approvalItemUpdates) => {
    setExpectedUpdates(() => approvalItemUpdates.length)
    approvalItemUpdates.forEach((approvalItemUpdate) => {
      updateApprovalItem.mutate({ ...approvalItemUpdate })
    })
  }

  useEffect(() => {
    if (!isUpdating && expectedUpdates > 0) {
      setIsUpdating(true)
    }
    if (isUpdating && expectedUpdates === 0) {
      setIsUpdating(false)
      onFinish(errorText)
      queryClient.invalidateQueries(['arrears', businessPartnerId])
      queryClient.invalidateQueries(['arrears', 'approvals', businessPartnerId])
      setErrorText([])
    }
  }, [businessPartnerId, errorText, expectedUpdates, isUpdating, onFinish, queryClient])

  return {
    isUpdating,
    updateApprovalItems,
  }
}
