import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useAssignPropertyToMarket = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ propertyId, marketId }) => {
      const { data } = await post({
        path: `/markets/${marketId}/properties`,
        body: {
          property_id: propertyId,
        },
      })
      return data
    },
    { onSuccess, onError },
  )
}
