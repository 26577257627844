import get from 'lodash.get'
import { useTranslation } from 'react-i18next'
import { TILE_VERSION } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import { renderAnalyticalTableCell } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/property/shared/AnalyticalTableCell'
import {
  AddressCell,
  AggregatedMoneyValueCell,
  CityCell,
  CountryCell,
  DescriptionCell,
  MoneyValueCell,
  MoneyValueCellV2,
  PropertyTypeCell,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/property/shared/TableCells'
import {
  useAreaMeasurementUnitFormatter,
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  usePercentageFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const useOpsMemoPropertyPortfolioOverviewTableColumns = ({
  multiPropertyKpis,
  valuationSum,
  version,
}) => {
  const MARKET_VALUE = 'marketValue'
  const MORTGAGE_LENDING_VALUE = 'mortgageLendingValue'

  const OPS_MEMO_PROPERTY_OVERVIEW_MARKET_VALUE_DISPLAY_VALUATION_TYPES = [
    'marketValue',
    'preliminaryMarketValue',
  ]
  const OPS_MEMO_PROPERTY_OVERVIEW_MORTGAGE_LENDING_VALUE_DISPLAY_VALUATION_TYPES = [
    'mortgageLendingValue',
    'preliminaryMortgageLendingValue',
  ]

  const mainValuationTypes = [MARKET_VALUE, MORTGAGE_LENDING_VALUE]

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.properties.portfolio.overview',
  })
  const { t: tNoPrefix } = useTranslation('decisionPaper')
  const formatNumber = useNumberFormatter()
  const formatWault = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const formatPercent = usePercentageFormatter({ maximumFractionDigits: 0 })
  const formatAreaUnit = useAreaMeasurementUnitFormatter()
  const doFormatCurrency = useCustomizableCurrencyFormatter()
  const formatCurrency = (value, currency) => currency && doFormatCurrency(value, currency)

  const tableHelpers = {
    formatNumber,
    formatCurrency,
    formatPercent,
    formatAreaUnit,
    formatWault,
    multiPropertyKpis,
    valuationSum,
    t,
    tNoPrefix,
    mainValuationTypes,
  }

  const defaultDisables = {
    disableResizing: true,
    disableDragAndDrop: true,
    disableGlobalFilter: true,
  }

  const aggregateValues = (leafValues) => [...new Set(leafValues)].filter((v) => v).join(', ')

  const getValueByAccessorOrDefault = (accessor) => (row) =>
    get(row, accessor) || t('table.columns.no-data')

  const getFilteredValuationValue = (classificationAccessor, valueAccessor) => (row) => {
    const valuationByClassification = get(row, classificationAccessor)
    const isValidConversion = valuationByClassification?.isValidConversion
    if (typeof isValidConversion === 'boolean' && !isValidConversion) {
      return 0
    } else {
      return get(valuationByClassification, valueAccessor) || 0
    }
  }

  const replacementValuationColumnsV2 = [
    {
      Header: t('table.columns.market-value'),
      id: 'market_value',
      accessor: getFilteredValuationValue('valuations.marketValue', 'valueAmount.number'),
      Cell: renderAnalyticalTableCell(MoneyValueCellV2, {
        ...tableHelpers,
        classifications: OPS_MEMO_PROPERTY_OVERVIEW_MARKET_VALUE_DISPLAY_VALUATION_TYPES,
      }),
      // there are no aggregates/grouping options in this table
      hAlign: 'right',
      vAlign: 'top',
      dialogOrder: 4,
      disableGroupBy: true,
      ...defaultDisables,
    },
    {
      Header: t('table.columns.mortgage-lending-value'),
      id: 'mortgage_lending_value',
      accessor: getFilteredValuationValue('valuations.mortgageLendingValue', 'valueAmount.number'),
      Cell: renderAnalyticalTableCell(MoneyValueCellV2, {
        ...tableHelpers,
        classifications: OPS_MEMO_PROPERTY_OVERVIEW_MORTGAGE_LENDING_VALUE_DISPLAY_VALUATION_TYPES,
      }),
      // there are no aggregates/grouping options in this table
      hAlign: 'right',
      vAlign: 'top',
      dialogOrder: 5,
      disableGroupBy: true,
      ...defaultDisables,
    },
  ]

  return {
    data: [
      {
        Header: t('table.columns.property'),
        filterLabel: t('table.columns.property-name'),
        sortByLabel: t('table.columns.property-name'),
        id: 'description',
        accessor: 'description',
        Cell: renderAnalyticalTableCell(DescriptionCell, tableHelpers),
        Aggregated: renderAnalyticalTableCell(DescriptionCell, tableHelpers, true),
        disableGroupBy: true,
        dialogOrder: 0,
        ...defaultDisables,
      },
      {
        Header: t('table.columns.property-type'),
        filterLabel: t('table.columns.property-type'),
        groupByLabel: t('table.columns.property-type'),
        sortByLabel: t('table.columns.property-type'),
        accessor: getValueByAccessorOrDefault('typeName'),
        id: 'type_name',
        Cell: renderAnalyticalTableCell(PropertyTypeCell, tableHelpers),
        Aggregated: renderAnalyticalTableCell(PropertyTypeCell, tableHelpers, true),
        aggregate: aggregateValues,
        dialogOrder: 1,
        hide: true,
        ...defaultDisables,
      },
      {
        Header: t('table.columns.address'),
        id: 'address',
        Cell: renderAnalyticalTableCell(AddressCell, tableHelpers),
        Aggregated: renderAnalyticalTableCell(AddressCell, tableHelpers, true),
        disableSortBy: true,
        disableFilters: true,
        disableGroupBy: true,
        ...defaultDisables,
      },

      {
        Header: t('table.columns.country'),
        sortByLabel: t('table.columns.country'),
        groupByLabel: t('table.columns.country'),
        filterLabel: t('table.columns.country'),
        accessor: getValueByAccessorOrDefault('address.countryName'),
        id: 'country',
        Cell: renderAnalyticalTableCell(CountryCell, tableHelpers),
        Aggregated: renderAnalyticalTableCell(CountryCell, tableHelpers, true),
        dialogOrder: 2,
        hide: true,
        ...defaultDisables,
      },
      {
        Header: t('table.columns.city'),
        sortByLabel: t('table.columns.city'),
        groupByLabel: t('table.columns.city'),
        filterLabel: t('table.columns.city'),
        accessor: getValueByAccessorOrDefault('address.cityName'),
        id: 'city',
        Cell: renderAnalyticalTableCell(CityCell, tableHelpers),
        Aggregated: renderAnalyticalTableCell(CityCell, tableHelpers, true),
        dialogOrder: 3,
        hide: true,
        ...defaultDisables,
      },
      ...(version === TILE_VERSION.V2
        ? replacementValuationColumnsV2
        : [
            {
              Header: t('table.columns.market-value'),
              id: 'market_value',
              accessor: getFilteredValuationValue('valuations.marketValue', 'valueAmount.number'),
              Cell: renderAnalyticalTableCell(MoneyValueCell, {
                ...tableHelpers,
                classification: MARKET_VALUE,
              }),
              Aggregated: renderAnalyticalTableCell(AggregatedMoneyValueCell, {
                ...tableHelpers,
                classification: MARKET_VALUE,
              }),
              aggregate: 'sum',
              hAlign: 'right',
              vAlign: 'top',
              dialogOrder: 4,
              disableGroupBy: true,
              ...defaultDisables,
            },
            {
              Header: t('table.columns.mortgage-lending-value'),
              id: 'mortgage_lending_value',
              accessor: getFilteredValuationValue(
                'valuations.mortgageLendingValue',
                'valueAmount.number',
              ),
              Cell: renderAnalyticalTableCell(MoneyValueCell, {
                ...tableHelpers,
                classification: MORTGAGE_LENDING_VALUE,
              }),
              Aggregated: renderAnalyticalTableCell(AggregatedMoneyValueCell, {
                ...tableHelpers,
                classification: MORTGAGE_LENDING_VALUE,
              }),
              aggregate: 'sum',
              hAlign: 'right',
              vAlign: 'top',
              dialogOrder: 5,
              disableGroupBy: true,
              ...defaultDisables,
            },
          ]),
      {
        hide: true,
        Header: t('table.columns.wault-expiry'),
        id: 'wault_expiry',
        accessor: 'kpis.waultToExpiryInYears',
        Cell: () => {},
        Aggregated: () => {},
        dialogOrder: 10,
        disableGroupBy: true,
        ...defaultDisables,
      },
    ],
  }
}

export default useOpsMemoPropertyPortfolioOverviewTableColumns
