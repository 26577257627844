import { useMemo } from 'react'
import { aggregateInvolvedPartiesQuery } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/gcc-involved-parties/sponsor-guarantor/helper/aggregateInvolvedPartiesQuery'
import { useMultipleAssessments } from 'hooks/services/business-partners/assessments/useMultipleAssessments'
import { useMultipleTextualAssessments } from 'hooks/services/business-partners/assessments/useMultipleTextualAssessments'
import { useMultipleBusinessPartnersById } from 'hooks/services/business-partners/getBusinessPartners'
import { useMultipleFiscalYears } from 'hooks/services/business-partners/kpis/useMultipleFiscalYears'
import useMultipleBusinessPartnerRatings from 'hooks/services/business-partners/ratings/useMultipleBusinessPartnerRatings'

/**
 * @param {object} options
 * @param {string[]} options.businessPartnerIds
 * @param {object} options.options
 * @return {{isLoading: boolean, isError: boolean, errors: any[], data?: Record<string, import('hooks/services/business-partners/ratings/useMultipleBusinessPartnerRatings').RatingResponse>}}
 */
export const useMultipleBusinessPartnerRatingsAggregated = ({ businessPartnerIds, options }) => {
  const responses = useMultipleBusinessPartnerRatings(businessPartnerIds, options)
  return useMemo(
    () => aggregateInvolvedPartiesQuery(responses, businessPartnerIds),
    [businessPartnerIds, responses],
  )
}

/**
 * @param {object} options
 * @param {string[]} options.businessPartnerIds
 * @param {import('@tanstack/react-query').QueryOptions} options.options
 * @return {{isLoading: boolean, isError: boolean, errors: any[], data?: Record<string, import('hooks/services/business-partners/kpis/useMultipleFiscalYears').FiscalYearsResponse>}}
 */
export const useMultipleFiscalYearsAggregated = ({ businessPartnerIds, options }) => {
  const responses = useMultipleFiscalYears({ businessPartnerIds, options })
  return useMemo(
    () => aggregateInvolvedPartiesQuery(responses, businessPartnerIds),
    [businessPartnerIds, responses],
  )
}

/**
 * @param {object} options
 * @param {string[]} options.businessPartnerIds
 * @param {import('@tanstack/react-query').QueryOptions} options.options
 * @return {{isLoading: boolean, isError: boolean, errors: any[], data?: Record<string, import('hooks/services/business-partners/assessments/useMultipleAssessments').AssessmentResponse>}}
 */
export const useMultipleAssessmentsAggregated = ({ businessPartnerIds, options }) => {
  const responses = useMultipleAssessments({ businessPartnerIds, options })
  return useMemo(
    () => aggregateInvolvedPartiesQuery(responses, businessPartnerIds),
    [businessPartnerIds, responses],
  )
}

/**
 * @param {object} options
 * @param {string[]} options.businessPartnerIds
 * @param {import('@tanstack/react-query').QueryOptions} options.options
 * @return {{isLoading: boolean, isError: boolean, errors: any[], data?: Record<string, import('hooks/services/business-partners/assessments/useMultipleAssessments').AssessmentResponse>}}
 */
export const useMultipleBusinessPartnersByIdAggregated = ({ businessPartnerIds, options }) => {
  const responses = useMultipleBusinessPartnersById(businessPartnerIds, options)
  return useMemo(
    () => aggregateInvolvedPartiesQuery(responses, businessPartnerIds),
    [businessPartnerIds, responses],
  )
}

/**
 * @param {object} options
 * @param {string[]} options.businessPartnerIds
 * @param {import('hooks/services/business-partners/assessments/useMultipleTextualAssessments').TextualAssessmentType} options.type
 * @param {import('@tanstack/react-query').QueryOptions} options.options
 * @return {{isLoading: boolean, isError: boolean, errors: any[], data?: Record<string, import('hooks/services/business-partners/assessments/useMultipleTextualAssessments').TextualAssessmentResponse>}}
 */
export const useMultipleTextualAssessmentsAggregated = ({ businessPartnerIds, type, options }) => {
  const responses = useMultipleTextualAssessments({ businessPartnerIds, type, options })
  return useMemo(
    () => aggregateInvolvedPartiesQuery(responses, businessPartnerIds),
    [businessPartnerIds, responses],
  )
}
