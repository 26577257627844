import { useContext, useMemo } from 'react'
import { businessEventsAllowedOperations } from 'api/events/eventAllowedOperations'
import { entityTypes } from 'api/events/status'
import CreateEventAction from 'components/domains/business-events-and-tasks/events/dialog/CreateEventAction'
import useEventsAllowedOperations from 'hooks/services/business-events-and-tasks/events/useEventsAllowedOperations'
import { PropertyContext } from 'routes/properties/PropertyContext'

/**
 * Secondary button to create Property events from the header of Property pages.
 * @returns {JSX.Element | false} Create Property Event Action or false
 */
const CreatePropertyEventAction = () => {
  const { property: { id, type_name, uuid } = {} } = useContext(PropertyContext)
  const { data: allowedEventsOperationsData } = useEventsAllowedOperations()
  const isCreateEventAllowed = !!allowedEventsOperationsData?.allowed_operations.includes(
    businessEventsAllowedOperations.createEvent,
  )
  return useMemo(
    () =>
      isCreateEventAllowed &&
      !!uuid && (
        <CreateEventAction
          entity={{
            entityType: entityTypes.property,
            entityDisplayId: id,
            entityName: type_name,
            entityId: uuid,
            isWorkingVersion: false,
          }}
        />
      ),
    [isCreateEventAllowed, id, type_name, uuid],
  )
}

export default CreatePropertyEventAction
