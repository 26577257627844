import { useCallback, useEffect, useRef, useState } from 'react'
import useRecognizeBusinessPartners from 'hooks/services/properties/businessPartners/useRecognizeBusinessPartners'
import { useMatchOrCreateSegmentsToRentalUnits } from 'hooks/services/properties/rent-roll/working-version/useMatchOrCreateSegmentsToRentalUnits'

export const useMatchBpsAndSegmentsToExcelUpload = (propertyUuids, onMappingComplete) => {
  const initialTableData = useRef([])

  const [matchingStarted, setMatchingStarted] = useState(false)

  const onSegmentMatchingComplete = useCallback(
    (mappedTableData) => {
      onMappingComplete(mappedTableData)
    },
    [onMappingComplete],
  )

  const {
    isLoading: recognizeBPsIsLoading,
    isError: recognizeBPsIsError,
    data: mappedTableDataWithRecognizedBusinessPartners,
    finishedBPRequests,
    totalAmountOfBPRequests,
  } = useRecognizeBusinessPartners(initialTableData.current)

  const { matchOrCreateSegments } = useMatchOrCreateSegmentsToRentalUnits(
    propertyUuids,
    onSegmentMatchingComplete,
  )

  const matchBpsAndSegmentsUntilFinished = () => {
    if (matchingStarted && !recognizeBPsIsLoading && !recognizeBPsIsError) {
      matchOrCreateSegments(mappedTableDataWithRecognizedBusinessPartners)
      setMatchingStarted(false)
    } else if (matchingStarted && !recognizeBPsIsLoading && recognizeBPsIsError) {
      matchOrCreateSegments(initialTableData.current)
      setMatchingStarted(false)
    }
  }

  useEffect(matchBpsAndSegmentsUntilFinished, [
    mappedTableDataWithRecognizedBusinessPartners,
    matchOrCreateSegments,
    matchingStarted,
    recognizeBPsIsError,
    recognizeBPsIsLoading,
  ])

  const matchBPsAndSegments = (tableData) => {
    initialTableData.current = tableData
    setMatchingStarted(true)
  }

  return {
    matchBPsAndSegments,
    finishedBPRequests,
    totalAmountOfBPRequests,
  }
}
