import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/card/CovenantsOfCheckCardSection.module.css'

const cardSectionProps = {
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  isSmallTitle: PropTypes.bool,
  required: PropTypes.bool,
  children: PropTypes.any,
}

const CardSection = ({
  id,
  className,
  title,
  isSmallTitle = false,
  required = false,
  children,
}) => (
  <div id={id} className={`${styles.section} ${className ?? ''}`}>
    {title && (
      <Label className={isSmallTitle ? styles.smallTitle : styles.title} required={required}>
        {title}
      </Label>
    )}
    {children}
  </div>
)

CardSection.propTypes = cardSectionProps

export default CardSection
