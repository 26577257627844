import { useRequests } from 'hooks/services/baseService'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

export const usePortfolioSegments = ({ propertyUuids }) => {
  const requests = {
    requests: propertyUuids.map((propertyUuid) => ({
      path: `/properties/${propertyUuid}/segments`,
      keys: ['segments', propertyUuid],
    })),
    translated: true,
  }

  return useCombinedQueryResults(useRequests(requests))
}
