import { Link, MessageStrip, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import { lazy, Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams, useNavigate } from 'react-router-dom'
import {
  FINANCING_TRANCHE_READ,
  FINANCING_OTHER_FINANCING_SOURCES_READ,
  SYNDICATION_STRUCTURE_READ,
  FINANCING_NON_LOAN_PRODUCTS_READ,
} from 'api/deals/financing/allowedOperationsConstants'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import LazyLoadingFallbackCard from 'components/ui/card/LazyLoadingFallbackCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import useSyndication from 'hooks/services/deals/syndication/useSyndication'
import useDealMini from 'hooks/services/deals/useDealMini'
import lazyRetry from 'lazyRetry'
import { DealContext } from 'routes/deals/DealContext'
import { DATA_SOURCES, DEAL_STATUS_RANKS } from 'routes/deals/financing/financingConstants'

import paths from 'routes/paths'

const TranchesOverviewCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "TranchesOverviewCard" */ 'routes/deals/financing/tranchesOverview/TranchesOverviewCard'
      ),
    'TranchesOverviewCard',
  ),
)
const OtherFinancingSourcesCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "OtherFinancingSourcesCard" */ 'routes/deals/financing/otherFinancingSources/OtherFinancingSourcesCard'
      ),
    'OtherFinancingSourcesCards',
  ),
)
const DerivativesCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DerivativesCard" */ 'routes/deals/financing/non-loan-products/derivatives/DerivativesCard'
      ),
    'DerivativesCard',
  ),
)
const MoneyMarketsCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MoneyMarketsCard" */ 'routes/deals/financing/non-loan-products/moneyMarkets/MoneyMarketsCard'
      ),
    'MoneyMarketsCard',
  ),
)
const BankCustomerAccountsCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "BankCustomerAccountsCard" */ 'components/domains/deals/financing/bank-customer-accounts/overview/BankCustomerAccountsCard'
      ),
    'BankCustomerAccountsCard',
  ),
)
const BondsCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "BondsCard" */ 'routes/deals/financing/non-loan-products/bonds/BondsCard'
      ),
    'BondsCard',
  ),
)
const ReposCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ReposCard" */ 'routes/deals/financing/non-loan-products/repos/ReposCard'
      ),
    'ReposCard',
  ),
)
const OtherLendingCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "OtherLendingCard" */ 'routes/deals/financing/non-loan-products/other-lending/OtherLendingCard'
      ),
    'OtherLendingCard',
  ),
)
const OtherCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "OtherCard" */ 'routes/deals/financing/non-loan-products/other/OtherCard'
      ),
    'OtherCard',
  ),
)

const DealFinancingOverviewFinancialProductsTab = () => {
  const { deal, financingAllowedOperations: allowedOperations } = useContext(DealContext)
  const { data: { rank } = {} } = useDealMini(deal.dealUuid)

  const { t } = useTranslation('translation')

  const [openedAtTimestamp, setOpenedAtTimestamp] = useState(Date.now())
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  // Save the timestamp of opening this page once after initial rendering
  useEffect(() => {
    setOpenedAtTimestamp(Date.now())
  }, [])

  // When deal is in working version, always set dataSource to newBusiness
  useEffect(() => {
    if (deal.workingVersion === WorkingVersionType.WORKING_VERSION) {
      const updatedSearchParams = new URLSearchParams(searchParams.toString())
      updatedSearchParams.set('dataSource', DATA_SOURCES.NEW_BUSINESS)
      setSearchParams(updatedSearchParams.toString())
    }
  }, [deal, navigate, searchParams, setSearchParams])

  const isDealSigned = rank >= DEAL_STATUS_RANKS.SIGNED

  const hasTrancheReadPermission = allowedOperations.includes(FINANCING_TRANCHE_READ)
  const hasOtherFinancingSourcesReadPermission = allowedOperations.includes(
    FINANCING_OTHER_FINANCING_SOURCES_READ,
  )
  const hasNonLoanProductsReadPermission = allowedOperations.includes(
    FINANCING_NON_LOAN_PRODUCTS_READ,
  )
  const hasSyndicationStructureReadPermission = allowedOperations.includes(
    SYNDICATION_STRUCTURE_READ,
  )

  const { data: syndicationData } = useSyndication(deal.dealUuid)

  const areSyndicationCounterpartiesEmpty = useMemo(
    () => isEmpty(syndicationData?.counterparties),
    [syndicationData?.counterparties],
  )

  const isExistingBusinessViewSet =
    searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS

  const pathToOverview = `/${paths.deals}/${deal.displayId}/syndication/`
  const navigateToOverview = useCallback(
    (linkEvent) => {
      linkEvent.preventDefault()
      navigate(pathToOverview)
    },
    [navigate, pathToOverview],
  )

  return (
    <CWPLayout overview>
      {hasSyndicationStructureReadPermission && !areSyndicationCounterpartiesEmpty && (
        <CWPLayout.Full>
          <MessageStrip design={MessageStripDesign.Information} hideCloseButton>
            {`${t('pages.deals.financing.message-strip.syndication-information.description')} `}
            <Link onClick={navigateToOverview} href={pathToOverview}>
              {t('pages.deals.financing.message-strip.syndication-information.link')}
            </Link>
          </MessageStrip>
        </CWPLayout.Full>
      )}
      {hasTrancheReadPermission && (
        <CWPLayout.Full>
          <Suspense
            fallback={<LazyLoadingFallbackCard data-testid="TranchesOverviewCard-fallback" />}
          >
            <TranchesOverviewCard openedAtTimestamp={openedAtTimestamp} />
          </Suspense>
        </CWPLayout.Full>
      )}
      {hasOtherFinancingSourcesReadPermission && !isExistingBusinessViewSet && (
        <CWPLayout.Full>
          <Suspense
            fallback={<LazyLoadingFallbackCard data-testid="OtherFinancingSourcesCard-fallback" />}
          >
            <OtherFinancingSourcesCard />
          </Suspense>
        </CWPLayout.Full>
      )}
      {isExistingBusinessViewSet && (
        <CWPLayout.Full>
          <Suspense
            fallback={
              <LazyLoadingFallbackCard data-testid="BankCustomerAccountsCard-CreditAccounts-fallback" />
            }
          >
            <BankCustomerAccountsCard isCreditAccountsCard={true} />
          </Suspense>
        </CWPLayout.Full>
      )}
      {isExistingBusinessViewSet && (
        <CWPLayout.Full>
          <Suspense
            fallback={
              <LazyLoadingFallbackCard data-testid="BankCustomerAccountsCard-OtherAccounts-fallback" />
            }
          >
            <BankCustomerAccountsCard />
          </Suspense>
        </CWPLayout.Full>
      )}
      {isDealSigned && hasNonLoanProductsReadPermission && (
        <CWPLayout.Full>
          <Suspense fallback={<LazyLoadingFallbackCard data-testid="DerivativesCard-fallback" />}>
            <DerivativesCard />
          </Suspense>
        </CWPLayout.Full>
      )}
      {isDealSigned && hasNonLoanProductsReadPermission && (
        <CWPLayout.Full>
          <Suspense fallback={<LazyLoadingFallbackCard data-testid="MoneyMarketsCard-fallback" />}>
            <MoneyMarketsCard />
          </Suspense>
        </CWPLayout.Full>
      )}
      {isDealSigned && hasNonLoanProductsReadPermission && (
        <CWPLayout.Full>
          <Suspense fallback={<LazyLoadingFallbackCard data-testid="BondsCard-fallback" />}>
            <BondsCard />
          </Suspense>
        </CWPLayout.Full>
      )}
      {isDealSigned && hasNonLoanProductsReadPermission && (
        <CWPLayout.Full>
          <Suspense fallback={<LazyLoadingFallbackCard data-testid="ReposCard-fallback" />}>
            <ReposCard />
          </Suspense>
        </CWPLayout.Full>
      )}
      {isDealSigned && hasNonLoanProductsReadPermission && (
        <CWPLayout.Full>
          <Suspense fallback={<LazyLoadingFallbackCard data-testid="OtherLendingCard-fallback" />}>
            <OtherLendingCard />
          </Suspense>
        </CWPLayout.Full>
      )}
      {isDealSigned && hasNonLoanProductsReadPermission && (
        <CWPLayout.Full>
          <Suspense fallback={<LazyLoadingFallbackCard data-testid="OtherCard-fallback" />}>
            <OtherCard />
          </Suspense>
        </CWPLayout.Full>
      )}
    </CWPLayout>
  )
}

export default DealFinancingOverviewFinancialProductsTab
