const useSegmentedChartData = (chartData, lastItemWithResult) => {
  const lastResultKeyDate = lastItemWithResult?.dueDate?.keyDate

  const chartDataBeforeLastResultDate = lastResultKeyDate
    ? chartData.filter(({ keyDate }) => new Date(keyDate) <= new Date(lastResultKeyDate))
    : null

  const chartDataAfterLastResultDate = lastResultKeyDate
    ? chartData.filter(({ keyDate }) => new Date(keyDate) >= new Date(lastResultKeyDate))
    : chartData

  return { chartDataBeforeLastResultDate, chartDataAfterLastResultDate }
}

export default useSegmentedChartData
