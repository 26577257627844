import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateCashflowScenario = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, cfScenario }) => {
      const { data } = await post({
        path: `/deals/${dealUuid}/cashflow-scenarios`,
        body: snakecaseKeys(cfScenario, { deep: true }),
      })
      return camelize(data)
    },
    { onSuccess, onError },
  )
}

export default useCreateCashflowScenario
