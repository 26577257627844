import { conditionsEntityTypes } from 'api/conditions/conditions'
import { ENTITIES } from 'components/domains/deals/change-overview/constants'
import useRequirements from 'hooks/services/conditions/requirements/useRequirements'
import useGetCondition from 'hooks/services/conditions/useGetCondition'
import { useCashFlowScenario } from 'hooks/services/deals/cashflow/useCashFlowScenario'
import useCovenantById from 'hooks/services/deals/covenants/useCovenantById'
import useDocument from 'hooks/services/documents/useDocument'

const useChangedInformationName = ({ dealUuid, entityType, entityId, tranches }) => {
  const isEntityType = (expectedEntityType) => expectedEntityType === entityType

  const { isLoading: isLoadingCovenant, data: covenant } = useCovenantById(
    isEntityType(ENTITIES.COVENANT) ? entityId : null,
    {
      retry: 0,
    },
  )

  const { isLoading: isLoadingCashflowScenario, data: cashflow } = useCashFlowScenario(
    dealUuid,
    isEntityType(ENTITIES.CASHFLOW_SCENARIO) ? entityId : null,
    {
      retry: 0,
    },
  )

  const { isLoading: isLoadingDocument, data: document } = useDocument({
    documentId: isEntityType(ENTITIES.DOCUMENT) ? entityId : null,
    additionalOptions: {
      retry: 0,
    },
  })

  const { isLoading: isLoadingExternalCondition, data: externalCondition } = useGetCondition(
    'external',
    {
      conditionId: entityId,
    },
    {
      retry: 0,
      enabled: isEntityType(ENTITIES.EXTERNAL_CONDITION),
    },
  )

  const { isLoading: isLoadingInternalCondition, data: internalCondition } = useGetCondition(
    'internal',
    {
      conditionId: entityId,
    },
    {
      retry: 0,
      enabled: isEntityType(ENTITIES.INTERNAL_CONDITION),
    },
  )

  const { isLoading: isLoadingRequirements, data: requirements } = useRequirements(
    {
      entityId: dealUuid,
      entityType: conditionsEntityTypes.deal,
    },
    {
      enabled: isEntityType(ENTITIES.REQUIREMENT),
    },
  )

  const getEntityNameByEntityType = () => {
    switch (entityType) {
      case ENTITIES.COVENANT:
        return covenant?.description
      case ENTITIES.TRANCHE:
        return tranches?.find((tranche) => tranche.technicalId === entityId)?.name
      case ENTITIES.CASHFLOW_SCENARIO:
        return cashflow?.name
      case ENTITIES.DOCUMENT:
        return document?.name
      case ENTITIES.EXTERNAL_CONDITION:
        return externalCondition?.info?.name
      case ENTITIES.INTERNAL_CONDITION:
        return internalCondition?.info?.name
      case ENTITIES.REQUIREMENT:
        return requirements?.requirements?.find((requirement) => requirement.id === entityId)?.info
          ?.name
      default:
        return null
    }
  }

  return {
    isLoading:
      (isLoadingDocument && isEntityType(ENTITIES.DOCUMENT)) ||
      (isLoadingCovenant && isEntityType(ENTITIES.COVENANT)) ||
      (isLoadingCashflowScenario && isEntityType(ENTITIES.CASHFLOW_SCENARIO)) ||
      (isLoadingExternalCondition && isEntityType(ENTITIES.EXTERNAL_CONDITION)) ||
      (isLoadingInternalCondition && isEntityType(ENTITIES.INTERNAL_CONDITION)) ||
      (isLoadingRequirements && isEntityType(ENTITIES.REQUIREMENT)),
    data: getEntityNameByEntityType(),
  }
}

export default useChangedInformationName
