import isEmpty from 'lodash.isempty'
import sortBy from 'lodash.sortby'
import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'
import { useConfig } from 'hooks/config/useConfig'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import {
  DEFAULT_RATING_AGENCY_PRIORITY,
  MAX_DISPLAYED_TENANTS,
} from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useTenantDetails'
import useMultipleTenantDetails from 'hooks/services/pbb-custom-service/useMultipleTenantDetails'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const getCurrentKpis = (multiPropertyKpis) => {
  const rentRollKpis = multiPropertyKpis?.keyDateToMultiPropertyRentRollKpis?.[0]

  if (!rentRollKpis) return undefined

  return {
    keyDate: rentRollKpis.keyDate,
    ...rentRollKpis.kpis,
  }
}

const getLatestTextualAssessment = (textualAssessments) => {
  if (isEmpty(textualAssessments?.availableVersions)) {
    return undefined
  }
  const { availableVersions } = textualAssessments
  const latestAssessment = [...availableVersions].sort(
    ({ version: firstVersion }, { version: secondVersion }) => secondVersion - firstVersion,
  )[0]
  return latestAssessment?.content
}

const useMultipleAnnualReviewTenantDetails = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const { data: config, isFetching: isFetchingConfig, isErrorConfig } = useConfig()

  const ratingAgencyPriority = useMemo(
    () => config?.ratingAgencyPriority ?? DEFAULT_RATING_AGENCY_PRIORITY,
    [config],
  )

  const sortExternalRatingsByAgencyPriority = useCallback(
    (a, b) =>
      (ratingAgencyPriority.includes(a.agency.id)
        ? ratingAgencyPriority.indexOf(a.agency.id)
        : ratingAgencyPriority.length) -
      (ratingAgencyPriority.includes(b.agency.id)
        ? ratingAgencyPriority.indexOf(b.agency.id)
        : ratingAgencyPriority.length),
    [ratingAgencyPriority],
  )

  const {
    data: { selectedDeal, dealUuids, dealsData } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const { selectedDealUuid } = selectedDeal ?? {}

  const {
    data: multipleTenantDetailsData,
    isLoading: isMultipleTenantDetailsLoading,
    isError: isMultipleTenantDetailsError,
    errorTenantDetails,
  } = useCombinedQueryResults(
    useMultipleTenantDetails({
      dealUuids,
    }),
    { forceDataReturn: true },
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isAnnualReviewDealsLoading, isMultipleTenantDetailsLoading, isFetchingConfig],
    errorValues: [isAnnualReviewDealsError, isMultipleTenantDetailsError, isErrorConfig],
    errorDetails: [annualReviewDealsError, errorTenantDetails],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    const multipleHighestRentTenants = multipleTenantDetailsData.map((mt) => {
      const multiPropertyKpis = getCurrentKpis(mt.multiPropertyKpis)
      const highestRentTenants = sortBy(
        (multiPropertyKpis?.tenantRentRollKpis ?? []).filter(({ tenant }) => !!tenant),
        ({ annualizedCurrentRent }) => annualizedCurrentRent.number,
      )
        .reverse()
        .slice(0, MAX_DISPLAYED_TENANTS)

      return highestRentTenants.map(({ tenant }) => tenant)
    })

    const multiplePropertiesPath = multipleTenantDetailsData.map((mt) => {
      const dealPropertyUuids = (mt?.properties ?? []).map(({ uuid }) => uuid)

      const dealHasProperties = dealPropertyUuids.length > 0
      const dealHasMultiProperties = dealPropertyUuids.length > 1

      if (dealHasMultiProperties) {
        const multiPropertyViewUuid = mt.multiPropertyView?.uuid
        return `/properties/portfolio/rent-roll?portfolio-view-id=${multiPropertyViewUuid}`
      }

      if (dealHasProperties) {
        const propertyId = mt.properties?.[0]?.id
        return `/properties/${propertyId}/rent-roll`
      }

      return null
    })

    const tenantSourceRenders = multipleTenantDetailsData?.map(
      (mt, index) =>
        multiplePropertiesPath &&
        multipleHighestRentTenants && {
          propertiesPath: multiplePropertiesPath[index],
          businessPartnerId: multipleHighestRentTenants[index][0],
          dealUuid: dealUuids[index],
        },
    )

    return {
      isLoading: false,
      isError: false,
      data: {
        multipleTenantDetails: multipleTenantDetailsData.map((mt, index) => {
          const multiPropertyKpis = getCurrentKpis(mt.multiPropertyKpis)
          const highestRentTenants = sortBy(
            (multiPropertyKpis?.tenantRentRollKpis ?? []).filter(({ tenant }) => !!tenant),
            ({ annualizedCurrentRent }) => annualizedCurrentRent.number,
          )
            .reverse()
            .slice(0, MAX_DISPLAYED_TENANTS)

          const highestRentTenantIds = highestRentTenants.map(({ tenant }) => tenant)

          return {
            tenantDetails: Object.fromEntries(
              highestRentTenantIds.map((tenantId, tenantIdIndex) => {
                const businessPartner = mt.businessPartners.find((b) => b.id === tenantId)
                const ratings = mt.businessPartnerRatings[tenantId]
                const relationships = mt.businessPartnerRelationships[tenantId]
                const kpis = (multiPropertyKpis?.tenantRentRollKpis ?? []).find(
                  ({ tenant }) => tenant === tenantId,
                )
                const tenantPropertiesUuids = (mt.propertyKpis ?? [])
                  .filter((propertyKpis) =>
                    propertyKpis.keyDateToRentRollKpis?.[0]?.kpis?.tenantRentRollKpis?.some(
                      (rentRollKpi) => rentRollKpi.tenant === tenantId,
                    ),
                  )
                  .map((propertyKpis) => propertyKpis.propertyUuid)

                const internalRating = (ratings?.internal ?? []).find((rating) => rating.isActive)
                const today = DateTime.now()

                const validExternalRatingsOrderedByRatingAgencyPriority = (ratings?.external ?? [])
                  .filter((externalRating) => {
                    const validFromDate = DateTime.fromISO(externalRating.validFrom)
                    const validToDate = DateTime.fromISO(externalRating.validTo)
                    return validFromDate <= today && validToDate >= today
                  })
                  .sort(sortExternalRatingsByAgencyPriority)

                const externalRanking = validExternalRatingsOrderedByRatingAgencyPriority[0]

                const tenantUnitHead = relationships?.unitRelationships?.find(
                  ({ type }) => type === 'tenant',
                )?.relationships?.[0]?.head?.fullName

                return [
                  tenantId,
                  {
                    id: tenantId,
                    sortIndex: tenantIdIndex,
                    name: businessPartner?.fullName,
                    industryCode:
                      businessPartner?.industry &&
                      `${businessPartner.industry.sectorId} – ${businessPartner.industry.keyDescription}`,
                    tenantUnit: tenantUnitHead,
                    internalRating: {
                      class: internalRating?.ratingClass,
                      method: internalRating?.method?.name,
                      validFrom: internalRating?.validFrom,
                    },
                    externalRating: {
                      grade: externalRanking?.grade,
                      agency: externalRanking?.agency?.name,
                      validFrom: externalRanking?.validFrom,
                    },
                    rentedArea: kpis?.totalAreaSurface,
                    annualizedCurrentRent: kpis?.annualizedCurrentRent,
                    appreciation: getLatestTextualAssessment(
                      mt.businessPartnerTextualAssessments[tenantId],
                    ),
                    properties: tenantPropertiesUuids.map((propertyUuid) => {
                      const tenantProperty = (mt?.properties ?? []).find(
                        (property) => property.uuid === propertyUuid,
                      )
                      const tenantPropertyKpis = mt.propertyKpis
                        .find((propertyKpis) => propertyKpis.propertyUuid === propertyUuid)
                        ?.keyDateToRentRollKpis?.[0]?.kpis?.tenantRentRollKpis?.find(
                          (rentRollKpi) => rentRollKpi.tenant === tenantId,
                        )

                      return {
                        id: tenantProperty?.id,
                        name: tenantProperty?.description,
                        city: tenantProperty?.address?.cityName,
                        country: tenantProperty?.address?.countryName,
                        rentedArea: tenantPropertyKpis?.totalAreaSurface,
                        annualizedCurrentRent: tenantPropertyKpis?.annualizedCurrentRent,
                      }
                    }),
                  },
                ]
              }),
            ),
            dealUuid: dealUuids[index],
          }
        }),
        sourceRender: tenantSourceRenders.find((t) => t.dealUuid === selectedDealUuid),
        tenantSourceRenders,
        dealsData,
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    multipleTenantDetailsData,
    dealsData,
    error,
    dealUuids,
    sortExternalRatingsByAgencyPriority,
    selectedDealUuid,
  ])
}

export default useMultipleAnnualReviewTenantDetails
