import { DateTime } from 'luxon'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const useRentRollWorkingVersionDateFormatter = () => {
  const { parse, localePattern } = useShortDateFormatter()

  const dateInIsoFormat = (dateStr) => DateTime.fromISO(dateStr).isValid

  const formatDateToIso = (dateStr) =>
    dateInIsoFormat(dateStr) ? dateStr : parse(dateStr, localePattern)

  const formatDateInHeader = ({ key_date, ...header }) => ({
    ...header,
    key_date: key_date ? formatDateToIso(key_date) : null,
  })
  const formatDateInRentalUnits = (rentalUnits) =>
    rentalUnits.map(
      ({
        lease_break_date,
        lease_expiry_date,
        lease_start_date,
        rent_start_date,
        ...rental_unit
      }) => ({
        lease_break_date: lease_break_date ? formatDateToIso(lease_break_date) : null,
        lease_expiry_date: lease_expiry_date ? formatDateToIso(lease_expiry_date) : null,
        lease_start_date: lease_start_date ? formatDateToIso(lease_start_date) : null,
        rent_start_date: rent_start_date ? formatDateToIso(rent_start_date) : null,
        ...rental_unit,
      }),
    )

  return { formatDateInHeader, formatDateInRentalUnits }
}

export default useRentRollWorkingVersionDateFormatter
