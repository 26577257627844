import { Icon } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EventSearchDialog from 'components/domains/business-events-and-tasks/events/dialog/EventSearchDialog'
import EventAutocompleteInput from 'components/domains/business-events-and-tasks/events/input/EventAutocompleteInput'

const propTypes = {
  isAllowedToEdit: PropTypes.bool,
  isDialogInputOnly: PropTypes.bool,
  value: PropTypes.shape({
    /** Unique identifier */
    id: PropTypes.string,
    /** Human-readable pseudo-unique identifier */
    displayId: PropTypes.string,
    name: PropTypes.string,
  }),
  onChange: PropTypes.func,
  /** Initial value of the EventAutocompleteInput */
  initialSearch: PropTypes.string,
  initialFilterValues: PropTypes.shape({
    searchValue: PropTypes.string,
    assigneeId: PropTypes.string,
    currentDueDateRange: PropTypes.string,
    originalDueDateRange: PropTypes.string,
    status: PropTypes.arrayOf(PropTypes.string),
    entityType: PropTypes.string,
    entityId: PropTypes.string,
  }),
  isInputFiltered: PropTypes.bool,
  readOnlyFilters: PropTypes.arrayOf(PropTypes.string),
  customEventCodeFilterList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
    }),
  ),
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const EventSearchInputAndDialog = ({
  isAllowedToEdit = true,
  isDialogInputOnly = false,
  initialSearch,
  initialFilterValues = {},
  customEventCodeFilterList = [],
  isInputFiltered = false,
  readOnlyFilters = [],
  value,
  onChange = () => {},
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.events.search.input-dialog',
  })

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [currentSearchValue, setCurrentSearchValue] = useState(initialSearch ?? '')
  const [currentSelectedEvent, setCurrentSelectedEvent] = useState(
    value ?? { id: undefined, displayId: undefined, name: undefined },
  )

  // Set and reset current values, and reset when dialog opens/closes
  useEffect(() => {
    if (value && value?.id) {
      const { id, displayId, name } = value ?? {}
      setCurrentSearchValue(`${name} (${displayId})`)
      setCurrentSelectedEvent({ id, displayId, name })
    } else {
      setCurrentSearchValue(initialSearch ?? '')
      setCurrentSelectedEvent({ id: undefined, displayId: undefined, name: undefined })
    }
  }, [value, isDialogOpen, initialSearch])

  const clearInput = useCallback(() => {
    setCurrentSearchValue('')
    setCurrentSelectedEvent({ id: undefined, displayId: undefined, name: undefined })
    onChange(undefined)
  }, [onChange])

  const handleSelect = useCallback(
    ({ id, displayId, name } = {}) => {
      if (id && displayId && name) {
        setCurrentSearchValue(`${name} (${displayId})`)
        setCurrentSelectedEvent({ id, displayId, name })
        onChange({ id, displayId, name })
      } else {
        clearInput()
      }
    },
    [clearInput, onChange],
  )

  return (
    <>
      <EventAutocompleteInput
        onSuggestionSelected={handleSelect}
        icon={
          isAllowedToEdit && (
            <>
              {isDialogInputOnly && currentSearchValue && (
                // we need a custom clear icon, because it's removed in readonly-mode
                <Icon name="decline" interactive onClick={clearInput} />
              )}
              <Icon name="value-help" onClick={() => setIsDialogOpen(true)} />
            </>
          )
        }
        readonly={!isAllowedToEdit || isDialogInputOnly}
        placeholder={t('placeholder')}
        value={currentSearchValue}
        preFilterValues={isInputFiltered ? initialFilterValues : {}}
        showClearIcon
      />
      {isAllowedToEdit && (
        <EventSearchDialog
          initialFilterValues={{
            ...initialFilterValues,
            ...(currentSelectedEvent?.displayId && {
              searchValue: currentSelectedEvent?.displayId,
            }),
          }}
          readOnlyFilters={readOnlyFilters}
          customEventCodeFilterList={customEventCodeFilterList}
          isOpen={isDialogOpen}
          isMultiSelect={false}
          setIsOpen={() => {}}
          onClose={() => setIsDialogOpen(false)}
          onSelect={(selectedEvents) => {
            // since events are provided as an array from the dialog, only pass the first entry for this single select case
            handleSelect(selectedEvents?.[0])
          }}
        />
      )}
    </>
  )
}

EventSearchInputAndDialog.propTypes = propTypes

export default EventSearchInputAndDialog
