import { ComboBox, ComboBoxItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { valuationRequestMultiEditOperations } from 'api/property/valuation/valuationRequests'
import Labeled from 'components/ui/data/Labeled'

const valuationTypeFieldId = 'ValuationRequestMultiEditValuationTypeField'

const ValuationRequestMultiEditValuationTypeField = ({
  disabled,
  onChange,
  availableValuationTypes,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.properties.valuation.multi-edit.fields',
  })
  const { t: tOperations } = useTranslation('translation', {
    keyPrefix: 'components.properties.valuation.multi-edit.operations',
  })

  const [selectedValuationType, setSelectedValuationType] = useState(
    tOperations(valuationRequestMultiEditOperations.keep),
  )

  /*
   * resets the valuation type value display when the reason changes
   * to stay in sync with the manual 'reset' in the multi edit dialog
   */
  useEffect(() => {
    setSelectedValuationType(tOperations(valuationRequestMultiEditOperations.keep))
  }, [availableValuationTypes, tOperations])

  const onChangeInternal = useCallback(
    ({ detail: { item: selectedComboBoxItem } }) => {
      const operation = selectedComboBoxItem.getAttribute('data-operation')
      const valuationType = selectedComboBoxItem.getAttribute('text')
      setSelectedValuationType(valuationType)
      onChange({ operation, value: valuationType })
    },
    [onChange],
  )

  const valuationTypeComboBoxItems = useMemo(
    () =>
      availableValuationTypes.map(({ id: valuationTypeId, type: valuationTypeName }) => (
        <ComboBoxItem
          key={valuationTypeId}
          data-operation={valuationRequestMultiEditOperations.replace}
          text={valuationTypeName}
        />
      )),
    [availableValuationTypes],
  )

  return (
    <Labeled id={valuationTypeFieldId} label={t('valuation-type.label')} vertical showColon>
      <ComboBox
        disabled={disabled}
        onSelectionChange={onChangeInternal}
        value={selectedValuationType}
      >
        <ComboBoxItem
          data-operation={valuationRequestMultiEditOperations.keep}
          text={tOperations(valuationRequestMultiEditOperations.keep)}
        />
        {valuationTypeComboBoxItems}
      </ComboBox>
    </Labeled>
  )
}

ValuationRequestMultiEditValuationTypeField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  availableValuationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default ValuationRequestMultiEditValuationTypeField
