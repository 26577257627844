import { Input, SuggestionItem, Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import { isCtrlA } from '@ui5/webcomponents-base/dist/Keys.js'
import isEmpty from 'lodash.isempty'
import throttle from 'lodash.throttle'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStaffMembersWithFilteredResults from 'hooks/services/business-partners/staff-members/useStaffMembersWithFilteredResults'

const DEFAULT_SUGGESTIONS_LIMIT = 10
const DEFAULT_SEARCH_MIN_LENGTH = 3
const DEFAULT_THROTTLE_TIMEOUT = 300

const propTypes = {
  staffMemberName: PropTypes.string,
  onInput: PropTypes.func,
  onStaffMemberSelect: PropTypes.func,
  suggestionsLimit: PropTypes.number,
  searchMinLength: PropTypes.number,
  throttleTimeout: PropTypes.number,
  errorMessage: PropTypes.string,
  staffMemberSuggestionsFilter: PropTypes.func,
  isDirty: PropTypes.bool,
}

const selectInputOnCtrlA = (event) => {
  event.target.shadowRoot.querySelector('input').select()
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const StaffMemberAutocompleteInput = ({
  staffMemberName = '',
  onInput = () => {},
  onStaffMemberSelect = () => {},
  suggestionsLimit = DEFAULT_SUGGESTIONS_LIMIT,
  searchMinLength = DEFAULT_SEARCH_MIN_LENGTH,
  throttleTimeout = DEFAULT_THROTTLE_TIMEOUT,
  errorMessage,
  staffMemberSuggestionsFilter,
  isDirty = false,
  ...inputOptions
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ui.input.staff-member-autocomplete',
  })

  const [search, setSearch] = useState('')
  const [isError, setIsError] = useState(false)
  const setSearchThrottled = useMemo(
    () => throttle(setSearch, throttleTimeout, { trailing: true }),
    [throttleTimeout],
  )

  const {
    isLoading,
    isError: isSearchError,
    data: { staffMembers: staffMemberSuggestions = [] } = {},
  } = useStaffMembersWithFilteredResults({
    staffMemberSuggestionsFilter,
    name: search,
    limit: suggestionsLimit,
    minLength: searchMinLength,
  })

  const getStaffMemberMatch = useCallback(
    (searchValue, searchKey = 'fullName') =>
      staffMemberSuggestions.find(
        ({ [searchKey]: staffMemberKey }) => staffMemberKey === searchValue,
      ),
    [staffMemberSuggestions],
  )

  const isValid = useCallback(
    (staffMember) => {
      if (inputOptions?.required) {
        return !isEmpty(search?.trim()) && !!staffMember?.fullName
      }
      return isLoading || !search || !!staffMember?.fullName
    },
    [inputOptions?.required, isLoading, search],
  )

  const handleOnInput = (event) => {
    setSearchThrottled(event.target.value)
    onInput(event)
  }

  const handleOnChange = useCallback(
    (event) => {
      const selectedStaffMember = getStaffMemberMatch(event.target.value)
      onStaffMemberSelect(selectedStaffMember)
      setIsError(!isValid(selectedStaffMember))
    },
    [getStaffMemberMatch, isValid, onStaffMemberSelect],
  )

  const handleOnSuggestionItemSelect = (event) => {
    const staffMemberId = event.detail?.item.getAttribute('additional-text')
    if (!staffMemberId) {
      event.target.value = ''
      return
    }
    const selectedStaffMember = getStaffMemberMatch(staffMemberId, 'id')
    onStaffMemberSelect(selectedStaffMember)
  }

  const valueState = useMemo(() => {
    if (
      (isDirty && inputOptions?.required && isEmpty(staffMemberName)) ||
      isError ||
      isSearchError
    ) {
      return ValueState.Error
    }
    return ValueState.None
  }, [inputOptions?.required, isError, isSearchError, staffMemberName])

  const valueStateMessage = useMemo(() => {
    if (errorMessage) {
      return errorMessage
    } else if (isDirty && inputOptions?.required && isEmpty(search)) {
      return t('empty-error.description')
    }
    return isSearchError ? t('search-error.description') : t('error.description')
  }, [t, inputOptions?.required, errorMessage, search, isSearchError])

  const isNoData = !isLoading && staffMemberSuggestions.length === 0

  return (
    <Input
      showSuggestions
      value={staffMemberName ?? undefined}
      onInput={handleOnInput}
      onChange={handleOnChange}
      onKeyDown={(e) => (isCtrlA(e) ? selectInputOnCtrlA(e) : undefined)}
      onSuggestionItemSelect={handleOnSuggestionItemSelect}
      valueState={valueState}
      valueStateMessage={<Text wrapping>{valueStateMessage}</Text>}
      {...inputOptions}
    >
      {isLoading && <SuggestionItem key="loading" text={t('loading')} />}
      {isNoData && <SuggestionItem key="no-data" text={t('no-data')} />}
      {staffMemberSuggestions.map(({ id, fullName }) => (
        <SuggestionItem key={id} text={fullName} additionalText={id} />
      ))}
    </Input>
  )
}

//StaffMemberAutocompleteInput.displayName = 'StaffMemberAutocompleteInput'

StaffMemberAutocompleteInput.propTypes = propTypes

export default StaffMemberAutocompleteInput
