import compact from 'lodash.compact'
import uniq from 'lodash.uniq'
import { useCallback, useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import { useMultipleDealTranches } from 'hooks/services/business-partners/unit-overview/loan/useMultipleDealTranches'
import useMultipleTranchesWithSubEntities from 'hooks/services/deals/financing/useMultipleTranchesWithSubEntities'
import useMultipleGetMilestones from 'hooks/services/deals/milestones/useMultipleGetMilestones'
import useMultipleGetPricing from 'hooks/services/deals/pricing/useMultipleGetPricing'
import useMultipleSyndicationsExistingBusiness from 'hooks/services/deals/syndication/useMultipleSyndicationsExistingBusiness'
import { useMultipleDealsById } from 'hooks/services/deals/useMultipleDealsById'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'

const useMultipleFinancingTermsExistingBusiness = (dealUuids = [], tileId) => {
  const {
    isLoading: isLoadingMultipleDeals,
    isError: isErrorMultipleDeals,
    data: multipleDealsData,
  } = useCombinedQueryResults(useMultipleDealsById(dealUuids))

  const dealsPerDealUuid = multipleDealsData
    ?.map((deal) => ({ [deal.dealUuid]: deal }))
    ?.reduce((prev, curr) => Object.assign(prev, curr), {})

  const {
    data: multiplePricingData,
    isLoading: isLoadingMultiplePricingData,
    isError: isErrorMultiplePricingData,
  } = useCombinedQueryResults(useMultipleGetPricing({ dealUuids }))

  const pricingPerDealUuid = multiplePricingData
    ?.map(({ dealUuid, pricing }) => ({ [dealUuid]: pricing }))
    ?.reduce((prev, curr) => Object.assign(prev, curr), {})

  const {
    data: multipleMilestonesData,
    isLoading: isLoadingMultipleMilestonesData,
    isError: isErrorMultipleMilestonesData,
  } = useCombinedQueryResults(useMultipleGetMilestones({ dealUuids }))

  const mileStonesPerDealUuid = multipleMilestonesData
    ?.map(({ dealUuid, milestones }) => ({ [dealUuid]: milestones }))
    ?.reduce((prev, curr) => Object.assign(prev, curr), {})

  const {
    data: multipleTrancheData,
    isLoading: isLoadingMultipleTrancheData,
    isError: isErrorMultipleTrancheData,
  } = useCombinedQueryResults(
    useMultipleTranchesWithSubEntities({
      dealUuids,
      dataSource: DATA_SOURCES.EXISTING_BUSINESS,
    }),
  )

  const {
    data: trancheDetailsData,
    isLoading: isTrancheDetailsLoading,
    isError: isTrancheDetailsError,
  } = useCombinedQueryResults(
    useMultipleDealTranches(dealUuids, {}, DATA_SOURCES.EXISTING_BUSINESS),
  )

  const {
    data: multipleSyndicationsExistingBusinessData = [],
    isLoading: isMultipleSyndicationsExistingBusinessLoading,
    isError: isMultipleSyndicationsExistingBusinessError,
  } = useCombinedQueryResults(useMultipleSyndicationsExistingBusiness({ dealUuids }))

  const syndicationsExistingBusinessPerDealUuid = multipleSyndicationsExistingBusinessData
    ?.map(({ dealUuid, ...syndication }) => ({ [dealUuid]: syndication }))
    ?.reduce((prev, curr) => Object.assign(prev, curr), {})

  const mergeTranches = useCallback((tranches, trancheDetails) => {
    if (!tranches || !trancheDetails) return []
    return tranches.map((tranche, index) => {
      // either match by externalContractId (safest) or displayId (safe), and in case both aren't set, match tranche by index (should also be safe in theory)
      const details =
        trancheDetails.find((detail) => {
          if (detail?.externalContractId?.[0] && tranche?.externalContractId?.[0]) {
            return detail.externalContractId[0] === tranche.externalContractId[0]
          } else if (detail.displayId && tranche.displayId) {
            return detail.displayId === tranche.displayId
          }
        }) ?? trancheDetails?.[index]
      return { ...tranche, ...details }
    })
  }, [])

  const mergedTrancheData = useMemo(() => {
    if (!multipleTrancheData || !trancheDetailsData) return []
    return multipleTrancheData.map(({ dealUuid, data, ...rest }) => {
      const trancheDetails = trancheDetailsData.find((data) => data.dealUuid === dealUuid)
      const mergedTranches = mergeTranches(data.tranches, trancheDetails?.tranches)
      return {
        dealUuid,
        data: { ...data, ...trancheDetails, tranches: mergedTranches },
        ...rest,
      }
    })
  }, [mergeTranches, multipleTrancheData, trancheDetailsData])

  const allBusinessPartnerIdsInTrancheData = useMemo(
    () =>
      uniq(
        compact(
          mergedTrancheData?.flatMap(({ data: { tranches } = {} } = {}) =>
            tranches?.flatMap(({ borrowerId } = {}) => borrowerId),
          ),
        ),
      ) ?? [],
    [mergedTrancheData],
  )

  const {
    data: { businessPartnerMinis: allBusinessPartnersData = [] } = {},
    isLoading: isBusinessPartnerLoading,
    isError: isBusinessPartnerError,
  } = useBusinessPartnerMiniByIds(allBusinessPartnerIdsInTrancheData, {
    enabled: allBusinessPartnerIdsInTrancheData?.length > 0,
  })

  const tranchesWithSubEntitiesPerDealUuid = mergedTrancheData
    ?.map(({ dealUuid, data, embeddedOptions }) => ({
      [dealUuid]: { ...data, embeddedOptions },
    }))
    ?.reduce((prev, curr) => Object.assign(prev, curr), {})

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isLoadingMultipleDeals,
      isLoadingMultiplePricingData,
      isLoadingMultipleMilestonesData,
      isLoadingMultipleTrancheData,
      isTrancheDetailsLoading,
      isMultipleSyndicationsExistingBusinessLoading,
      isBusinessPartnerLoading && allBusinessPartnerIdsInTrancheData?.length > 0,
    ],
    errorValues: [
      isErrorMultipleDeals,
      isErrorMultiplePricingData,
      isErrorMultipleMilestonesData,
      isErrorMultipleTrancheData,
      isBusinessPartnerError,
      isTrancheDetailsError,
      isMultipleSyndicationsExistingBusinessError,
    ],
    // HINT: useCombinedQueryResults doesn't return the errors
    errorDetails: [],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: dealUuids
        ?.map((dealUuid) => ({
          [dealUuid]: {
            dealData: dealsPerDealUuid[dealUuid] ?? {},
            trancheData: tranchesWithSubEntitiesPerDealUuid[dealUuid] ?? {},
            pricingData: pricingPerDealUuid[dealUuid]?.values ?? {},
            pricingCustomFields: pricingPerDealUuid[dealUuid]?.meta?.custom_fields ?? {},
            milestonesData: mileStonesPerDealUuid[dealUuid] ?? {},
            syndicationExistingBusinessData:
              syndicationsExistingBusinessPerDealUuid[dealUuid] ?? {},
            businessPartnersData: allBusinessPartnersData,
            sourceRender: { dealId: dealUuid, dealDisplayId: dealsPerDealUuid[dealUuid]?.dealId },
          },
        }))
        ?.reduce((prev, curr) => Object.assign(prev, curr), {}),
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealUuids,
    error,
    dealsPerDealUuid,
    tranchesWithSubEntitiesPerDealUuid,
    pricingPerDealUuid,
    mileStonesPerDealUuid,
    syndicationsExistingBusinessPerDealUuid,
    allBusinessPartnersData,
  ])
}

export default useMultipleFinancingTermsExistingBusiness
