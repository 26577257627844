import { MessageStrip, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import uniq from 'lodash.uniq'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/periodical-checks/general-information/PeriodicalCheckGeneralInformationCard.module.css'
import PeriodicalCheckGeneralInformationDisplay from 'components/domains/business-partners/periodical-checks/general-information/PeriodicalCheckGeneralInformationDisplay'
import PeriodicalCheckGeneralInformationEdit from 'components/domains/business-partners/periodical-checks/general-information/PeriodicalCheckGeneralInformationEdit'
import useUpdatePeriodicalCheckMonitoringItem from 'components/domains/business-partners/periodical-checks/general-information/useUpdatePeriodicalCheckMonitoringItem'
import useEnrichDueDate from 'components/domains/business-partners/periodical-checks/next-periodical-check/useEnrichDueDate'
import { CardHeaderLastEditedText } from 'components/domains/deals/card/CardHeaderLastEditedText'
import Card from 'components/ui/card/Card'
import { ToggleEditMode } from 'components/ui/card/CardHeaderWithEditMode'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useCovenantMonitoring from 'hooks/services/deals/covenants/monitorings/useCovenantMonitoring'
import useCovenantCheckById from 'hooks/services/deals/covenants/useCovenantCheckById'
import { editButtonStateEnum } from 'routes/deals/covenant-checks/useCalculateEditButtonState'

const PeriodicalCheckGeneralInformationCard = ({
  covenantCheckUuid,
  hasDocumentRequirements = true,
  showBreach = true,
  editButtonState,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.periodical-checks.general-information',
  })

  const [isEditMode, setIsEditMode] = useState(false)
  const [formValues, setFormValues] = useState()
  const [isFormInitialized, setIsFormInitialized] = useState(false)
  const [disabledInputs, setDisabledInputs] = useState({})
  const [invalidInputs, setInvalidInputs] = useState([])

  const { localePattern, parse } = useShortDateFormatter()
  const { addBaseDataToDate } = useEnrichDueDate()

  const {
    data: periodicalCheck,
    isFetching: isCovenantCheckLoading,
    isError: isCovenantCheckError,
  } = useCovenantCheckById(covenantCheckUuid)

  const monitoringItem = useMemo(
    // For periodical checks, there is always exactly one monitoring item
    () => periodicalCheck?.covenantMonitoringItems?.[0],
    [periodicalCheck?.covenantMonitoringItems],
  )

  const showMissingData =
    isNil(monitoringItem?.breach) && isNil(monitoringItem?.newTargetDeliveryDate)

  const {
    data: { firstDueDate } = {},
    isFetching: isFetchingMonitoring,
    isError: isErrorMonitoring,
  } = useCovenantMonitoring(monitoringItem?.covenant?.covenantUuid)
  const { checkDateBaseData } = firstDueDate ?? {}

  const { performUpdate } = useUpdatePeriodicalCheckMonitoringItem({
    setIsEditMode,
    setIsFormInitialized,
    monitoringItem,
    covenantCheckUuid,
  })

  const newCheckDueDate = useMemo(() => {
    if (!hasDocumentRequirements && monitoringItem?.newTargetDeliveryDate) {
      return addBaseDataToDate(monitoringItem?.newTargetDeliveryDate, checkDateBaseData)
    }
  }, [
    addBaseDataToDate,
    checkDateBaseData,
    hasDocumentRequirements,
    monitoringItem?.newTargetDeliveryDate,
  ])

  const initialValues = useMemo(
    () => ({
      actualDeliveryDate: monitoringItem?.actualDeliveryDate || '',
      newTargetDeliveryDate: monitoringItem?.newTargetDeliveryDate || '',
      newCheckDueDate: newCheckDueDate || null,
      breach: monitoringItem?.breach || null,
      waived: !!monitoringItem?.waived,
    }),
    [
      monitoringItem?.actualDeliveryDate,
      monitoringItem?.breach,
      monitoringItem?.newTargetDeliveryDate,
      monitoringItem?.waived,
      newCheckDueDate,
    ],
  )

  const initializeFormValues = useCallback(() => {
    setFormValues(initialValues)
  }, [initialValues])

  useEffect(() => {
    const disabledEntries = {}
    for (const [key, value] of Object.entries(formValues ?? {})) {
      if (key === 'actualDeliveryDate' && value) {
        disabledEntries.newTargetDeliveryDate = true
      }
      if (key === 'newTargetDeliveryDate') {
        disabledEntries.actualDeliveryDate = !!value
        disabledEntries.breach = !!value
      }
      // For having the ability to postpone an item which does not have a newTargetDeliveryDate
      if (key === 'newCheckDueDate') {
        disabledEntries.breach = !!value || !!formValues?.newTargetDeliveryDate
      }
      if (key === 'breach') {
        disabledEntries.waived = !['SOFT', 'HARD', 'YES'].includes(value)
      }
    }
    setDisabledInputs(disabledEntries)
  }, [formValues])

  useEffect(() => {
    if (!isCovenantCheckLoading && !isFetchingMonitoring && !isFormInitialized) {
      initializeFormValues()
      setIsFormInitialized(true)
    }
  }, [
    initialValues,
    initializeFormValues,
    isCovenantCheckLoading,
    isFetchingMonitoring,
    isFormInitialized,
  ])

  const handleSave = () => performUpdate(formValues, checkDateBaseData)

  const handleCancel = () => {
    setIsFormInitialized(false)
    setIsEditMode(false)
  }

  const handleUpdateForDependentFields = (change) => {
    const changeKey = Object.keys(change)?.[0]
    const changeValue = change?.[changeKey]
    if (changeKey === 'actualDeliveryDate' && changeValue) {
      return {
        [changeKey]: parse(changeValue, localePattern) || changeValue,
        newTargetDeliveryDate: '',
      }
    }
    const newDeliveryDateKeys = ['newTargetDeliveryDate', 'newCheckDueDate']
    if (newDeliveryDateKeys.includes(changeKey)) {
      if (!changeValue) {
        return newDeliveryDateKeys.reduce((acc, curr) => ({ ...acc, [curr]: '' }), {
          breach: null,
          waived: false,
        })
      }
      return {
        [changeKey]: parse(changeValue, localePattern) || changeValue,
        actualDeliveryDate: '',
        breach: 'POSTPONED',
        waived: false,
      }
    }
    if (changeKey === 'breach') {
      return {
        [changeKey]: changeValue,
        waived: ['YES', 'SOFT', 'HARD'].includes(changeValue) ? !!formValues?.waived : false,
      }
    }
    return change
  }

  const handleValidState = (key, isValid) => {
    if (isValid) {
      return setInvalidInputs((prevInvalidInputs) =>
        prevInvalidInputs.filter((entry) => entry !== key),
      )
    }
    setInvalidInputs((prevInvalidInputs) => {
      prevInvalidInputs.push(key)
      return uniq(prevInvalidInputs)
    })
  }

  const handleOnChange = (formChange, isValid = true) => {
    handleValidState(Object.keys(formChange)?.[0], isValid)
    const changeSet = handleUpdateForDependentFields(formChange)
    setFormValues((state) => ({ ...state, ...changeSet }))
  }

  return (
    <Card
      header={
        <CardHeaderLastEditedText
          title={t('title', { covenantName: monitoringItem?.covenant?.name })}
          email={periodicalCheck?.lastUpdatedBy}
          timestamp={periodicalCheck?.lastUpdatedAt}
          actions={
            editButtonState === editButtonStateEnum.VISIBLE &&
            !isCovenantCheckLoading && (
              <ToggleEditMode
                isEditMode={isEditMode}
                onToggle={() => setIsEditMode(true)}
                onSave={() => handleSave()}
                onCancel={() => handleCancel()}
                buttonIdPrefix="periodical-check-general-information"
                checkSaveDisabled={() =>
                  !!invalidInputs.length || isEqual(initialValues, formValues)
                }
                isEditDisabled={editButtonState === editButtonStateEnum.DISABLED}
              />
            )
          }
        />
      }
    >
      <RequestStateResolver
        center
        isLoading={isCovenantCheckLoading || isFetchingMonitoring}
        isError={isCovenantCheckError || isErrorMonitoring}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        renderContent={() => (
          <>
            {showMissingData && (
              <div className={styles.messageStripe}>
                <MessageStrip design={MessageStripDesign.Warning} hideCloseButton>
                  {t('missing-data')}
                </MessageStrip>
              </div>
            )}
            {isEditMode ? (
              <PeriodicalCheckGeneralInformationEdit
                periodicalCheck={periodicalCheck}
                monitoringItem={monitoringItem}
                formValues={formValues}
                hasDocumentRequirements={hasDocumentRequirements}
                showBreach={showBreach}
                onChange={handleOnChange}
                disabledInputs={disabledInputs}
                invalidInputs={invalidInputs}
              />
            ) : (
              <PeriodicalCheckGeneralInformationDisplay
                periodicalCheck={periodicalCheck}
                monitoringItem={monitoringItem}
                hasDocumentRequirements={hasDocumentRequirements}
                showBreach={showBreach}
                newCheckDueDate={newCheckDueDate}
              />
            )}
          </>
        )}
      />
    </Card>
  )
}

PeriodicalCheckGeneralInformationCard.propTypes = {
  covenantCheckUuid: PropTypes.string.isRequired,
  hasDocumentRequirements: PropTypes.bool,
  showBreach: PropTypes.bool,
  editButtonState: PropTypes.oneOf(Object.values(editButtonStateEnum)),
}

export default PeriodicalCheckGeneralInformationCard
