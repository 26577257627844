import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useSyndicationsExistingBusiness = (dealUuid, options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/financing-structures/syndications-existing-business/deals/${dealUuid}`,
      keys: ['financing-structures', 'syndications-existing-business', 'deals', dealUuid],
      options,
    }),
  )

export default useSyndicationsExistingBusiness
