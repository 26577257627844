import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useCovenantCheckById = (covenantCheckUuid, options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/covenants/covenant-checks/${covenantCheckUuid}`,
      translated: true,
      useCache: true,
      keys: ['covenants', 'covenant-checks', covenantCheckUuid],
      options: { enabled: !!covenantCheckUuid, retry: 1, ...options },
    }),
  )

export default useCovenantCheckById
