import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useBusinessPartnerRelationships = (businessPartnerId, options = {}) =>
  useCamelizedResponse(
    useRequest({
      translated: true,
      path: `/businesspartners/${businessPartnerId}/relationships`,
      keys: ['businesspartners', businessPartnerId, 'relationships'],
      options: {
        enabled: !!businessPartnerId,
        ...options,
      },
    }),
  )

export default useBusinessPartnerRelationships
