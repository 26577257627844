import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Card from 'components/ui/card/Card'
import CardHeaderWithMetrics from 'components/ui/card/CardHeaderWithMetrics'
import { useNumberFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'
import usePropertyRentRollComparisonKpis from 'hooks/services/properties/kpis/comparison/usePropertyRentRollComparisonKpis'
import usePropertyRentRollComparisonMetric from 'hooks/services/properties/kpis/comparison/usePropertyRentRollComparisonMetric'
import useAnnualizedCurrentRentPrimaryMetric from 'hooks/services/properties/kpis/useAnnualizedCurrentRentPrimaryMetric'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyRentRollComparisonCurrentRentCard = () => {
  const { t: tComparison } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.comparison',
  })

  const { property } = useContext(PropertyContext)

  const { format: formatDate } = useShortDateFormatter()

  const formatAnnualizedCurrentRent = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: 'compact',
  })

  const {
    isLoading,
    isError,
    currentRentRollKpis,
    comparisonRentRollKpis,
    currentKeyDate,
    comparisonKeyDate,
  } = usePropertyRentRollComparisonKpis(property)

  const primaryMetric = useAnnualizedCurrentRentPrimaryMetric(
    currentRentRollKpis,
    formatDate(currentKeyDate),
  )

  const { label, unit, value } = useAnnualizedCurrentRentPrimaryMetric(
    comparisonRentRollKpis,
    formatDate(comparisonKeyDate) ?? '',
  )

  const secondaryMetric =
    value && unit
      ? {
          label: label,
          value: `${value} ${unit}`,
        }
      : { label: label, value: value }

  const comparisonMetric = usePropertyRentRollComparisonMetric({
    isLoading,
    isError,
    formatter: formatAnnualizedCurrentRent,
    primaryValue: currentRentRollKpis?.annualizedCurrentRent?.number,
    comparisonValue: comparisonRentRollKpis?.annualizedCurrentRent?.number,
    unit: primaryMetric?.unit ?? secondaryMetric?.unit,
  })

  const isInComparison = comparisonKeyDate && comparisonKeyDate !== currentKeyDate

  const secondaryMetrics = isInComparison ? [secondaryMetric, comparisonMetric] : [secondaryMetric]

  return (
    <Card>
      <CardHeaderWithMetrics
        title={tComparison('table.columns.annualized-current-rent')}
        primaryMetric={primaryMetric}
        secondaryMetrics={secondaryMetrics}
        showReferenceDate={false}
        isLoading={isLoading}
        isError={isError}
      />
    </Card>
  )
}
export default PropertyRentRollComparisonCurrentRentCard
