import { useTranslation } from 'react-i18next'

export const Direction = {
  BEFORE: 'BEFORE',
  AFTER: 'AFTER',
}

const useDirectionTranslation = () => {
  const { t } = useTranslation('translation')

  const getDirectionTranslation = (requestedDirection) =>
    ({
      BEFORE: t('components.deals.covenants.monitoring-card.direction.before'),
      AFTER: t('components.deals.covenants.monitoring-card.direction.after'),
    }[requestedDirection] ?? '')

  return { getDirectionTranslation }
}

export default useDirectionTranslation
