const mapRatingMarketNational = (marketNational) => {
  if (!marketNational) return []
  const subRows = [
    { criteriaClass: 'marketNationalActsOfGod', rating: marketNational?.marketNationalActsOfGod },
    {
      criteriaClass: 'marketNationalSocioDemoGraphicDevelopment',
      rating: marketNational?.marketNationalSocioDemoGraphicDevelopment,
    },
    {
      criteriaClass: 'marketNationalOverallEconomicDevelopment',
      rating: marketNational?.marketNationalOverallEconomicDevelopment,
    },
    {
      criteriaClass: 'marketNationalPltmcondition',
      rating: marketNational?.marketNationalPltmcondition,
    },
    {
      criteriaClass: 'marketNationalPropertyMarket',
      rating: marketNational?.marketNationalPropertyMarket,
    },
  ]
  return [
    {
      rootRowId: 'ratingMarketNational',
      criteriaClass: 'marketNational',
      rating: marketNational.marketNational,
      subRows,
    },
  ]
}

const mapRatingMarketRegional = (marketRegional) => {
  if (!marketRegional) return []
  const subRows = [
    { criteriaClass: 'marketRegionalActsOfGod', rating: marketRegional?.marketRegionalActsOfGod },
    {
      criteriaClass: 'marketRegionalSocioDemoGraphicDevelopment',
      rating: marketRegional?.marketRegionalSocioDemoGraphicDevelopment,
    },
    {
      criteriaClass: 'marketRegionalEconomicSituationAndAttractiveness',
      rating: marketRegional?.marketRegionalEconomicSituationAndAttractiveness,
    },
    {
      criteriaClass: 'marketRegionalPropertyMarket',
      rating: marketRegional?.marketRegionalPropertyMarket,
    },
  ]
  return [
    {
      rootRowId: 'ratingMarketRegional',
      criteriaClass: 'marketRegional',
      rating: marketRegional.marketRegional,
      subRows,
    },
  ]
}

const mapRatingMarket = (market) => {
  if (!market) return []
  const marketSubRows = [
    ...mapRatingMarketNational(market?.marketNational),
    ...mapRatingMarketRegional(market?.marketRegional),
  ]
  return [
    {
      rootRowId: 'ratingMarket',
      criteriaClass: 'market',
      rating: market.market,
      subRows: marketSubRows,
    },
  ]
}

const mapRatingLocation = (location) => {
  if (!location) return []
  return [
    {
      rootRowId: 'ratingLocation',
      criteriaClass: 'location',
      rating: location.location,
      subRows: [
        { criteriaClass: 'locationPropertyType', rating: location?.locationPropertyType },
        { criteriaClass: 'locationImageQuarter', rating: location?.locationImageQuarter },
        { criteriaClass: 'qualityOfTransportation', rating: location?.qualityOfTransportation },
        {
          criteriaClass: 'qualityOfLocalSupplyFacilities',
          rating: location?.qualityOfLocalSupplyFacilities,
        },
        { criteriaClass: 'locationActsOfGod', rating: location?.locationActsOfGod },
      ],
    },
  ]
}

const mapRatingProperty = (property) => {
  if (!property) return []
  return [
    {
      rootRowId: 'ratingProperty',
      criteriaClass: 'property',
      rating: property.property,
      subRows: [
        { criteriaClass: 'architecture', rating: property?.architecture },
        { criteriaClass: 'fitout', rating: property?.fitout },
        { criteriaClass: 'structuralCondition', rating: property?.structuralCondition },
        { criteriaClass: 'plotSituation', rating: property?.plotSituation },
        { criteriaClass: 'ecologicalSustainability', rating: property?.ecologicalSustainability },
        {
          criteriaClass: 'profitabilityOfBuildingConcept',
          rating: property?.profitabilityOfBuildingConcept,
        },
      ],
    },
  ]
}

const mapRatingCashflow = (cashflow) => {
  if (!cashflow) return []
  return [
    {
      rootRowId: 'ratingCashflow',
      criteriaClass: 'cashflow',
      rating: cashflow.cashflow,
      subRows: [
        { criteriaClass: 'tenantSituation', rating: cashflow?.tenantSituation },
        { criteriaClass: 'rentalGrowthPotential', rating: cashflow?.rentalGrowthPotential },
        { criteriaClass: 'lettingProspects', rating: cashflow?.lettingProspects },
        { criteriaClass: 'vacancySituation', rating: cashflow?.vacancySituation },
        {
          criteriaClass: 'recoverableNonRecoverableExpenses',
          rating: cashflow?.recoverableNonRecoverableExpenses,
        },
        { criteriaClass: 'usabilityByThirdParties', rating: cashflow?.usabilityByThirdParties },
      ],
    },
  ]
}

const mapRatingTotal = (data) => {
  if (!data) return []

  const content = [
    {
      rootRowId: 'ratingTotal',
      criteriaClass: 'total',
      rating: data?.total,

      subRows: [
        ...mapRatingMarket(data?.market),
        ...mapRatingLocation(data?.location),
        ...mapRatingProperty(data?.property),
        ...mapRatingCashflow(data?.cashflow),
      ],
    },
  ]

  return content
}

export default mapRatingTotal
