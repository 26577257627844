import { set } from 'lodash'
import { useState } from 'react'
import { useMultipleCurrencyConversions } from 'hooks/services/central-data/useMultipleCurrencyConversions'

export const CONVERSION_STATUS = {
  INITIAL: 'initial',
  CONVERTED: 'converted',
  ERROR: 'error',
}

const DEFAULT_CURRENCY = 'EUR'

export const useDealReservationCurrencyConversionData = ({ validReservations }) => {
  const [convertingStatus, setConvertingStatus] = useState(CONVERSION_STATUS.INITIAL)

  const { mutate: doMultipleConversions } = useMultipleCurrencyConversions({
    onSingleConversionSuccess: ({ data }) => {
      const currencyConversionValues = data.currencyConversionValues[0]
      const sourceReservation = validReservations.find(
        ({ creditReservationUuid }) => creditReservationUuid === currencyConversionValues?.id,
      )
      set(sourceReservation, 'amountInEUR', currencyConversionValues?.value)
    },
    onSingleConversionError: () => setConvertingStatus(CONVERSION_STATUS.ERROR),
    onMultipleConversionSuccess: () => setConvertingStatus(CONVERSION_STATUS.CONVERTED),
  })

  /*
   * Filter out reservations with default currency, in order to save calls to the server
   */
  const getValuesForConversion = () =>
    validReservations
      .filter(({ amount }) => amount.currencyCode !== DEFAULT_CURRENCY)
      .map(({ amount, creditReservationUuid, startTimestamp }) => ({
        keyDate: startTimestamp,
        currencyConversionValues: [
          {
            id: creditReservationUuid,
            value: amount.number,
            sourceCurrency: amount.currencyCode,
            targetCurrency: DEFAULT_CURRENCY,
          },
        ],
      }))

  /**
   * If there are values to convert, do a conversion. Otherwise, set status to CONVERTED
   */
  const convertToDefaultCurrency = () => {
    const valuesToConvert = getValuesForConversion()

    valuesToConvert.length
      ? doMultipleConversions(valuesToConvert)
      : setConvertingStatus(CONVERSION_STATUS.CONVERTED)
  }

  return {
    convertingStatus,
    convertToDefaultCurrency,
  }
}
