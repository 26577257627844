import { WrappingType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/ui/data/Entity.module.css'
import Link from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/ui/link/Link'

const propTypes = {
  /** the entity's name */
  name: PropTypes.string,
  /** the entity's ID */
  id: PropTypes.string,
  /** a link pointing to the entity's detail page. Putting character / at the start of the link gets rid of URL parts of the current page */
  link: PropTypes.string,
  /** will open the pointed link in a new tab */
  openInNewTab: PropTypes.bool,
  additionalClassNamesForLink: PropTypes.string,
  /** styles to add to the outermost element */
  style: PropTypes.shape({}),
  className: PropTypes.string,
}

/**
 * Component to display an entity (business partner, property, ...)
 *
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const Entity = ({
  name,
  id,
  link,
  openInNewTab,
  style,
  className,
  additionalClassNamesForLink = 'sapInformativeColor',
}) => (
  <div className={[styles.entity, className].join(' ')} style={style ?? undefined}>
    {name && link && (
      <Link
        href={link}
        target={openInNewTab ? '_blank' : undefined}
        className={[styles.name, 'sapFontBoldFamily', additionalClassNamesForLink].join(' ')}
        wrappingType={WrappingType.Normal}
        onClick={(e) =>
          // required to prevent the click event from being propagated to the parent element (especially ui5 table)
          e.stopPropagation()
        }
      >
        {name}
      </Link>
    )}
    {name && !link && <span className={[styles.name, 'sapFontBoldFamily'].join(' ')}>{name}</span>}
    {!name && <div>-</div>}
    {id}
  </div>
)

Entity.propTypes = propTypes

export default Entity
