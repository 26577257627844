import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import LoadingButton from 'components/ui/button/LoadingButton'
import styles from 'components/ui/card/CardHeaderWithEditMode.module.css'
import CtrlClickableCardHeader from 'components/ui/card/CtrlClickableCardHeader'

export function ToggleEditMode({
  isEditMode,
  slot,
  checkSaveDisabled = () => false,
  isEditDisabled = false,
  onToggle,
  onSave,
  onCancel,
  buttonIdPrefix,
  additionalActions,
  isSaveLoading = false,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'buttons' })

  return (
    <>
      {isEditMode ? (
        <div
          className="actions"
          slot={slot}
          style={additionalActions ? { display: 'flex' } : undefined}
        >
          {additionalActions}
          {additionalActions ? <hr id="card-header-hr" style={{ margin: '1px 4px' }} /> : undefined}
          <LoadingButton
            id={buttonIdPrefix ? `${buttonIdPrefix}-editModeSaveButton` : 'editModeSaveButton'}
            className={styles['toggle-edit-mode-save']}
            isLoading={isSaveLoading}
            isError={false}
            design={ButtonDesign.Emphasized}
            renderContent={() => t('save')}
            disabled={checkSaveDisabled() || isSaveLoading}
            onClick={() => {
              onToggle && onToggle(false)
              onSave && onSave()
            }}
          />
          <Button
            id={buttonIdPrefix ? `${buttonIdPrefix}-editModeCancelButton` : 'editModeCancelButton'}
            className="toggle-edit-mode-cancel"
            design={ButtonDesign.Transparent}
            onClick={() => {
              onToggle && onToggle(false)
              onCancel && onCancel()
            }}
            disabled={isSaveLoading}
          >
            {t('cancel')}
          </Button>
        </div>
      ) : (
        <div slot={slot} style={additionalActions ? { display: 'flex' } : undefined}>
          <Button
            disabled={isEditDisabled}
            design={ButtonDesign.Transparent}
            className="toggle-edit-mode-edit"
            onClick={() => onToggle && onToggle(true)}
          >
            {t('edit')}
          </Button>
        </div>
      )}
    </>
  )
}

ToggleEditMode.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  slot: PropTypes.any,
  checkSaveDisabled: PropTypes.func,
  isEditDisabled: PropTypes.bool,
  onToggle: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  buttonIdPrefix: PropTypes.string,
  additionalActions: PropTypes.any,
  isSaveLoading: PropTypes.bool,
}

/**
 * card header that includes a title and a view / edit mode toggle
 * @param isEditMode is the card currently in edit mode
 * @param titleText card title
 * @param checkSaveDisabled callback that returns whether the save button should be disabled
 * @param onToggle callback for switching between display and edit mode, will be called with the updated `isEditMode` flag
 * @param onSave callback that is called when clicking on save in edit mode
 * @param onCancel callback that is called when clicking on cancel in edit mode
 * @param style CSS inline style
 * @param onHeaderClick set header interactive and call function onClick
 * @param requiredFieldsValidation validation for required fields
 * @param isSaveLoading whether the save request (mutate) is still loading/ongoing
 */
function CardHeaderWithEditMode({
  isEditMode,
  titleText,
  subtitleText,
  checkSaveDisabled = () => false,
  isEditDisabled = false,
  onToggle,
  onCancel,
  onSave,
  style,
  slot,
  showActions = true,
  onHeaderClick,
  additionalActions,
  requiredFieldsValidation,
  isSaveLoading = false,
}) {
  const { hasWarning, messageStrip } = requiredFieldsValidation || {}
  return (
    <>
      {hasWarning && isEditMode && (
        <div className={messageStrip ? styles.messageStripContainer : ''}>{messageStrip}</div>
      )}
      <CtrlClickableCardHeader
        className={onHeaderClick && styles.interactiveCardHeader}
        slot={slot}
        titleText={titleText}
        subtitleText={subtitleText}
        style={{ lineHeight: '19px', fontSize: '16px', ...(style ?? {}) }}
        interactive={!!onHeaderClick}
        onClick={onHeaderClick}
        action={
          showActions && (
            <ToggleEditMode
              isEditMode={isEditMode}
              checkSaveDisabled={checkSaveDisabled}
              isEditDisabled={isEditDisabled}
              onToggle={onToggle}
              onCancel={onCancel}
              onSave={onSave}
              buttonIdPrefix={titleText}
              additionalActions={additionalActions}
              isSaveLoading={isSaveLoading}
            />
          )
        }
      />
    </>
  )
}

CardHeaderWithEditMode.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  titleText: PropTypes.string.isRequired,
  subtitleText: PropTypes.string,
  showActions: PropTypes.bool,
  checkSaveDisabled: PropTypes.func,
  isEditDisabled: PropTypes.bool,
  onToggle: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  style: PropTypes.any,
  slot: PropTypes.any,
  onHeaderClick: PropTypes.func,
  additionalActions: PropTypes.any,
  requiredFieldsValidation: PropTypes.shape({
    hasWarning: PropTypes.bool,
    messageStrip: PropTypes.node,
  }),
  isSaveLoading: PropTypes.bool,
}

export default CardHeaderWithEditMode
