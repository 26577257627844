// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T321fqKTWP_p2P2YeiGo {
  padding-top: 16px;
}

.mbhispN2mq4Utca6naM6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.vfGcusf8xhe4tQWJ7JpP {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
}

.VibT9lE6sOSvgA7Nur6M {
  padding-right: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/card/DisplayCardViewInfoItemView.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".firstItem {\n  padding-top: 16px;\n}\n\n.displayCardTextBox {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-end;\n}\n\n.displayCardItem {\n  padding-left: 16px;\n  padding-right: 16px;\n  padding-bottom: 8px;\n}\n\n.displayCardLabel {\n  padding-right: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"firstItem": `T321fqKTWP_p2P2YeiGo`,
	"displayCardTextBox": `mbhispN2mq4Utca6naM6`,
	"displayCardItem": `vfGcusf8xhe4tQWJ7JpP`,
	"displayCardLabel": `VibT9lE6sOSvgA7Nur6M`
};
export default ___CSS_LOADER_EXPORT___;
