import { IllustrationMessageSize, IllustrationMessageType } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/rent-roll/overview/expiring-leases/PropertyRentRollOverviewExpiringLeasesCardContent.module.css'
import PropertyRentRollOverviewExpiringLeasesChart from 'components/domains/properties/rent-roll/overview/expiring-leases/PropertyRentRollOverviewExpiringLeasesChart'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import usePropertyRentRollOverviewExpiringLeasesChartData from 'hooks/services/properties/kpis/charts/usePropertyRentRollOverviewExpiringLeasesChartData'
import useCurrentRentRollOverviewPropertyKpis from 'hooks/services/properties/kpis/useCurrentRentRollOverviewPropertyKpis'

const PropertyRentRollOverviewExpiringLeasesCardContent = () => {
  const { t: tExpiringLeasesChart } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.expiring-leases.chart',
  })

  const { isLoading, isError, data: propertyKpiData } = useCurrentRentRollOverviewPropertyKpis()

  const { currentRent, expiringLeases, currency, isEmpty, todayXAxisPosition } =
    usePropertyRentRollOverviewExpiringLeasesChartData(propertyKpiData)

  return (
    <div className={styles.chartContainer}>
      <LoadingStateWrapper
        isLoading={isLoading}
        isError={isError}
        errorTitle={tExpiringLeasesChart('error.title')}
        errorDescription={tExpiringLeasesChart('error.subtitle')}
        errorDetails=" "
        loadingDescription={tExpiringLeasesChart('loading')}
      >
        {isEmpty ? (
          <EmptyCardContent
            title={tExpiringLeasesChart('empty.title')}
            subtitle={tExpiringLeasesChart('empty.subtitle')}
            illustrationName={IllustrationMessageType.UnableToLoad}
            size={IllustrationMessageSize.Scene}
          />
        ) : (
          <PropertyRentRollOverviewExpiringLeasesChart
            currentRent={currentRent}
            expiringLeases={expiringLeases}
            currency={currency}
            todayXAxisPosition={todayXAxisPosition}
          />
        )}
      </LoadingStateWrapper>
    </div>
  )
}

export default PropertyRentRollOverviewExpiringLeasesCardContent
