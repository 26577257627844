import isNil from 'lodash.isnil'
import { useMemo } from 'react'
import { isConflictError, isNotFoundError } from 'api/requests'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useGeneralInformationUsers from 'hooks/services/business-events-and-tasks/decision-papers/tiles/general-information/helper/useGeneralInformationUsers'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useAuthorityLevelCalculatorAuthorityLevel from 'hooks/services/business-partners/authority-level-calculator/useAuthorityLevelCalculatorAuthorityLevel'
import { useApplicationTypes } from 'hooks/services/deals/configurations/useApplicationTypes'
import { useBusinessSegments } from 'hooks/services/deals/configurations/useBusinessSegments'
import { useOriginationTeams } from 'hooks/services/deals/configurations/useOriginationTeams'
import useDealTeam from 'hooks/services/deals/deal-team/useDealTeam'
import useRiskConformity from 'hooks/services/deals/risk-conformity/useRiskConformity'
import useDeal from 'hooks/services/deals/useDeal'

const getMembersByRelation = (dealTeamMembers, relation) => {
  if (!dealTeamMembers) {
    return []
  }
  return dealTeamMembers.filter((member) => member.relation.internalDealTeamRelation === relation)
}

const getLeadingMember = (members) => {
  if (members.length === 1) {
    return members[0]
  }
  return members.find((member) => member.leader)
}

const getUserId = (dealTeam, relation) => {
  const members = getMembersByRelation(dealTeam, relation)
  const leadingMember = getLeadingMember(members)
  return leadingMember?.userId
}

export const useDecisionPaperGeneralInformation = (
  { id: businessEventId, entityRef: { entityId: dealUuid } },
  tileId,
  tileCode,
) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidLoading,
    isError: isDealUuidError,
    errors: dealUuidErrors,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    isFetching: isDealLoading,
    isError: isDealError,
    error: dealError,
    data: dealData,
  } = useDeal(dealUuidByTileCode)

  const isLoadApplicationTypeCodesEnabled = !isNil(dealData?.applicationTypeCode)
  const {
    isFetching: isApplicationTypesLoading,
    isError: isApplicationTypesError,
    error: applicaitonTypesError,
    data: applicationTypesData,
  } = useApplicationTypes({ enabled: isLoadApplicationTypeCodesEnabled })

  const isLoadBusinessSegmentsEnabled =
    !isNil(dealData?.businessSegmentCode) && !isNil(dealData?.dealTypeCode)
  const {
    isFetching: isBusinessSegmentsLoading,
    isError: isBusinessSegmentsError,
    error: businessSegmentsError,
    data: businessSegmentsData,
  } = useBusinessSegments({ enabled: isLoadBusinessSegmentsEnabled })

  const isLoadOriginationTeamsEnabled = !isNil(dealData?.originationTeamCode)
  const {
    isFetching: isOriginationTeamsLoading,
    isError: isOriginationTeamsError,
    error: originationTeamsError,
    data: originationTeamsData,
  } = useOriginationTeams(dealData?.businessSegmentCode, { enabled: isLoadOriginationTeamsEnabled })

  const { borrowerBpId } = dealData ?? {}

  const {
    isFetching: isDealRiskConformityLoading,
    isError: isDealRiskConformityError,
    error: dealRiskConformityError,
    data: dealRiskConformityData,
  } = useRiskConformity(dealUuidByTileCode)

  const isFetchAuthorityLevelCalculation = useMemo(
    () => !!borrowerBpId && !isNil(dealRiskConformityData?.compliance),
    [borrowerBpId, dealRiskConformityData?.compliance],
  )

  const {
    isFetching: isAuthorityLevelRequestLoading,
    isError: isAuthorityLevelError,
    error: authorityLevelError,
    data: authorityLevelData,
  } = useAuthorityLevelCalculatorAuthorityLevel(
    {
      eventId: businessEventId,
    },
    { enabled: isFetchAuthorityLevelCalculation },
  )
  const isAuthorityLevelLoading = useMemo(
    () => isFetchAuthorityLevelCalculation && isAuthorityLevelRequestLoading,
    [isAuthorityLevelRequestLoading, isFetchAuthorityLevelCalculation],
  )

  const {
    data: { staffMembers: dealTeam = [] } = {},
    isFetching: isDealTeamFetching,
    isError: isDealTeamError,
    error: dealTeamError,
  } = useDealTeam({ dealUuid: dealUuidByTileCode })

  const accountManagerId = getUserId(dealTeam, 'ACCOUNT_MANAGER')

  const isAuthorityLevelBusinessError =
    isAuthorityLevelError && isConflictError(authorityLevelError)

  //to freeze decision paper when s4 has error in users hook, remove state here
  //and provide fallback value
  const { data: usersData, isAnyFetching: usersIsAnyLoading } = useGeneralInformationUsers({
    dealUuid: dealUuidByTileCode,
    borrowerBpId,
  })

  const isDealRiskConformityNotFoundError =
    dealRiskConformityError && isNotFoundError(dealRiskConformityError)

  const { isSomeValueError, isSomeValueLoading, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isDealUuidLoading,
      isAuthorityLevelLoading,
      isDealLoading,
      isApplicationTypesLoading,
      isBusinessSegmentsLoading,
      isOriginationTeamsLoading,
      isDealRiskConformityLoading,
      isDealTeamFetching,
      usersIsAnyLoading,
    ],
    errorValues: [
      isDealUuidError,
      isAuthorityLevelError && !isAuthorityLevelBusinessError,
      isDealError,
      isApplicationTypesError,
      isBusinessSegmentsError,
      isOriginationTeamsError,
      isDealRiskConformityError && !isDealRiskConformityNotFoundError,
      isDealTeamError,
    ],
    errorDetails: [
      ...dealUuidErrors,
      isAuthorityLevelBusinessError ? undefined : authorityLevelError,
      dealError,
      applicaitonTypesError,
      businessSegmentsError,
      originationTeamsError,
      isDealRiskConformityNotFoundError ? undefined : dealRiskConformityError,
      dealTeamError,
    ],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }
    const applicationTypeName = applicationTypesData?.find(
      ({ code }) => code === dealData?.applicationTypeCode,
    )?.name

    const dealTypeName = businessSegmentsData
      ?.find(({ code }) => code === dealData?.businessSegmentCode)
      ?.dealTypes?.find(({ code }) => code === dealData?.dealTypeCode)?.name

    const originationTeamName = originationTeamsData?.find(
      ({ code }) => code === dealData?.originationTeamCode,
    )?.name

    return {
      isLoading: false,
      isError: false,
      error: error,
      data: {
        ...usersData,
        accountManagerId,
        isAuthorityLevelBusinessError,
        authorityLevel: authorityLevelData,
        deal: {
          name: dealData?.name,
          dealTypeName,
          applicationTypeName,
          originationTeamName,
        },
        dealRiskConformity: dealRiskConformityData,
        sourceRender: {
          dealDisplayId: isWorkingVersionDataTile
            ? `${dealData?.dealId}?working-version=true`
            : dealData?.dealId,
          businessPartnerId: borrowerBpId,
        },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    applicationTypesData,
    businessSegmentsData,
    originationTeamsData,
    error,
    usersData,
    accountManagerId,
    isAuthorityLevelBusinessError,
    authorityLevelData,
    dealData?.name,
    dealData?.dealId,
    dealData?.applicationTypeCode,
    dealData?.businessSegmentCode,
    dealData?.dealTypeCode,
    dealData?.originationTeamCode,
    dealRiskConformityData,
    isWorkingVersionDataTile,
    borrowerBpId,
  ])
}
