import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'

const IndustryCellComponent = ({ id }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner-search.table' })
  const {
    data: businessPartnerMiniData,
    isLoading: isLoadingBusinessPartnerMini,
    isError: isErrorBusinessPartnerMini,
  } = useBusinessPartnerMiniByIds([id])
  const businessPartnerMini = businessPartnerMiniData?.businessPartnerMinis?.[0] ?? undefined

  return (
    <SmallLoadingWrapper
      isError={isErrorBusinessPartnerMini}
      error={t('industry.error')}
      isLoading={isLoadingBusinessPartnerMini}
      renderContent={() => businessPartnerMini?.industry?.keyDescription}
    />
  )
}

IndustryCellComponent.propTypes = {
  id: PropTypes.string,
}

export default IndustryCellComponent
