import { Button, ButtonDesign, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import MessageBox, { MessageBoxTypes } from 'components/ui/message-box/MessageBox'
import useDeleteCovenant from 'hooks/services/deals/covenants/useDeleteCovenant'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import paths from 'routes/paths'

const DeleteCovenantConfirmationMessageBox = ({
  dealDisplayId,
  covenantUuid,
  isOpen,
  toggleOpen,
  ...props
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.delete-confirmation',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const showErrorMessageBox = useShowErrorMessageBox()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isDeleting, setIsDeleting] = useState(false)

  const closeMessageBox = useCallback(() => toggleOpen(false), [toggleOpen])

  const onSuccess = () => {
    queryClient.invalidateQueries('covenants', covenantUuid)
    showToast({ children: t('success') }, document.body)
    navigate(
      `/${paths.deals}/${dealDisplayId}/${dealDetailPaths.covenants}?${searchParams.toString()}`,
    )
    closeMessageBox()
    setIsDeleting(false)
  }
  const onError = async (error) => {
    const { errors: [errorResponse] = [] } = await error.response.json()
    showErrorMessageBox({ message: t('error'), error: errorResponse })
    closeMessageBox()
    setIsDeleting(false)
  }
  const deleteCovenant = useDeleteCovenant({ onSuccess, onError })
  const executeDeleteCovenant = () => {
    setIsDeleting(true)
    deleteCovenant.mutate({ covenantUuid })
  }

  const actions = [
    <Button
      key="delete"
      design={ButtonDesign.Emphasized}
      id="confirm-deletion-button"
      onClick={executeDeleteCovenant}
      disabled={isDeleting}
    >
      {tNoPrefix('buttons.delete')}
    </Button>,
    <Button
      key="cancel"
      design={ButtonDesign.Transparent}
      disabled={isDeleting}
      onClick={closeMessageBox}
    >
      {tNoPrefix('buttons.cancel')}
    </Button>,
  ]

  return (
    <MessageBox
      open={isOpen}
      onClose={closeMessageBox}
      type={MessageBoxTypes.Warning}
      titleText={tNoPrefix('buttons.delete')}
      actions={actions}
      {...props}
    >
      {t('text')}
    </MessageBox>
  )
}

DeleteCovenantConfirmationMessageBox.propTypes = {
  dealDisplayId: PropTypes.string.isRequired,
  covenantUuid: PropTypes.string.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default DeleteCovenantConfirmationMessageBox
