import { useMutation, useQueryClient } from '@tanstack/react-query'
import camelize from 'camelize'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

// Use a regex to remove data url part
const removeDataStringFromBase64Result = (readerResult) =>
  readerResult.result.replace('data:', '').replace(/^.+,/, '')

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = (error) => reject(error)
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(removeDataStringFromBase64Result(reader))
    }
  })

const obtainKeys = ({ ref }) => ['documents', ref.type, ref.id]

const useDocumentUpload = ({ onSuccess: onSuccessCallback = () => {}, ...mutateOptions } = {}) => {
  const { post } = useAccessTokenRequest()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ entityRefs, file, documentName, keyDate, documentType }) => {
      const content = await toBase64(file)
      const fileName = file.name
      const { data } = await post({
        path: `/documents`,
        body: {
          document_content: content,
          document_name: documentName,
          file_name: fileName,
          key_date: keyDate,
          entity_refs: entityRefs,
          document_type: documentType,
        },
      })

      return { data: camelize(data), entityRefs }
    },
    onSuccess: ({ data, entityRefs }, ...rest) => {
      entityRefs?.forEach((ref) =>
        queryClient.invalidateQueries(
          obtainKeys({
            ref,
          }),
        ),
      )
      onSuccessCallback(data, ...rest)
    },
    ...mutateOptions,
  })
}

export default useDocumentUpload
