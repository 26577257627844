import { useContext } from 'react'
import { entityTypes } from 'api/events/status'
import DecisionProcessAlcMessageStrip from 'components/domains/business-events-and-tasks/decision-process/alc/DecisionProcessAlcMessageStrip'
import CWPLayout from 'components/ui/layout/CWPLayout'
import { useConfig } from 'hooks/config/useConfig'
import { pbbCustomServicePermissions } from 'hooks/services/pbb-custom-service/pbbCustomServicePermissions'
import usePbbCustomServiceAllowedOperations from 'hooks/services/pbb-custom-service/usePbbCustomServiceAllowedOperations'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionProcessAlcMessageStripWrapper = () => {
  const {
    event: {
      id: eventId,
      info: { code: eventCode },
      entityRef,
    },
  } = useContext(BusinessEventsAndTasksContext)

  const { data: pbbAllowedOperations } = usePbbCustomServiceAllowedOperations()

  const { data: config } = useConfig()

  const showMessageStripForEventCodes = config?.alc?.showMessageStripForEventCodes ?? []
  const allowedOperations = pbbAllowedOperations?.allowedOperations ?? []

  if (
    !allowedOperations.includes(pbbCustomServicePermissions.pbbAuthorityLevelCalculatorRead) ||
    !showMessageStripForEventCodes.includes(eventCode) ||
    entityRef.entityType === entityTypes.property
  ) {
    return
  }
  return (
    <CWPLayout.Full>
      <DecisionProcessAlcMessageStrip
        eventId={eventId}
        entityId={entityRef.entityId}
        entityType={entityRef.entityType}
      />
    </CWPLayout.Full>
  )
}

DecisionProcessAlcMessageStripWrapper.propTypes = {}

export default DecisionProcessAlcMessageStripWrapper
