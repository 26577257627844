import isNil from 'lodash.isnil'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { collapseTile, expandTile } from 'redux/slices/decision-paper/tilesOverviewSlice'

const useIsDecisionPaperTileExpanded = ({ tileId, isPdfView, isDefaultExpanded }) => {
  const dispatch = useDispatch()
  const tileState = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const isExpanded = useMemo(() => {
    if (isPdfView) {
      return true
    }
    if (isNil(tileState?.isExpanded)) {
      return isDefaultExpanded
    }
    return tileState.isExpanded
  }, [isPdfView, isDefaultExpanded, tileState])

  const collapse = useCallback(() => dispatch(collapseTile(tileId)), [dispatch, tileId])
  const expand = useCallback(() => dispatch(expandTile(tileId)), [dispatch, tileId])

  return { isExpanded, expand, collapse }
}

export default useIsDecisionPaperTileExpanded
