import cloneDeep from 'lodash.clonedeep'
import { isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useCovenantMonitoring = (covenantUuid, options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/covenants/${covenantUuid}/monitoring-information`,
      translated: true,
      useCache: true,
      keys: ['covenants', covenantUuid, 'monitoring'],
      options: {
        enabled: !!covenantUuid,
        ...options,
        retry: 1,
      },
    }),
  )

export const useCovenantMonitoringWithDefault = (newMonitoring, covenantUuid, options = {}) => {
  const response = useCovenantMonitoring(covenantUuid, options)
  const isMonitoringNotFound = response?.isError && isNotFoundError(response?.error)

  return {
    ...response,
    data: isMonitoringNotFound ? cloneDeep(newMonitoring) : response?.data,
    isError: isMonitoringNotFound ? false : response?.isError,
  }
}

export default useCovenantMonitoring
