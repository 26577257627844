import { AnalyticalTableSelectionMode } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BcaFilterKeys } from 'components/domains/deals/financing/bank-customer-accounts/bca-search-dialog/BcaFilterBar'
import {
  useBcaSearchTableData,
  useBcaSearchColumnDefinitions,
} from 'components/domains/deals/financing/bank-customer-accounts/bca-search-dialog/useBcaSearchTableHelper'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'

export const BcaSearchTableMode = {
  SingleSelect: AnalyticalTableSelectionMode.SingleSelect,
  MultiSelect: AnalyticalTableSelectionMode.MultiSelect,
}

export const BcaSearchTableColumn = {
  ACCOUNT: 'account',
  ACCOUNT_STATUS: 'accountStatus',
  DEAL: 'deal',
  DEAL_STATUS: 'dealStatus',
  BOOK_BALANCE: 'bookBalance',
}

const BcaSearchTable = ({
  bcas,
  mode,
  onSelectionChanged = () => {},
  selectedBcaIds = [],
  lockedSelectedBcas = [],
  columnsRestrictedTo = null,
  additionalToolbarProperties = {},
  showInitPlaceholder = false,
  isError,
  isLoading,
  filterParams = {},
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.financing.bank-customer-accounts.bca-search',
  })
  const noDataText = isError ? t('table.error') : t('table.no-data')
  const loadingOrNoDataText = isLoading ? t('table.loading') : noDataText

  const columnDefinitions = useBcaSearchColumnDefinitions({ columnsRestrictedTo })

  const tableProperties = () => ({
    mode,
    stickyColumnHeader: true,
    onSelectionChange: onSelectionChanged,
    busy: isLoading,
  })

  const mapTableData = useBcaSearchTableData({
    selectedBcaIds,
    lockedSelectedBcas,
    deal: filterParams[BcaFilterKeys.Deal],
    mode,
    onSelectionChanged,
  })

  const tableData = useMemo(() => mapTableData(bcas), [bcas, mapTableData])

  return (
    <div>
      <SortedTable
        columnDefinitions={columnDefinitions}
        tableData={tableData}
        toolbarConfig={{
          title: t('accounts'),
          ...additionalToolbarProperties,
        }}
        noDataText={loadingOrNoDataText}
        additionalTableProperties={tableProperties()}
        {...(showInitPlaceholder
          ? { tableData: [], noDataText: t('table.init-placeholder-text'), paginationConfig: {} }
          : {})}
      />
    </div>
  )
}

export default BcaSearchTable

BcaSearchTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  bcas: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  additionalToolbarProperties: PropTypes.object,
  mode: PropTypes.oneOf(Object.values(BcaSearchTableMode)),
  onSelectionChanged: PropTypes.func,
  selectedBcaIds: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  lockedSelectedBcas: PropTypes.arrayOf(PropTypes.object),
  columnsRestrictedTo: PropTypes.arrayOf(PropTypes.oneOf(Object.values(BcaSearchTableColumn))),
  isAllowedToReadBca: PropTypes.bool,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  showInitPlaceholder: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  filterParams: PropTypes.arrayOf(PropTypes.any),
}
