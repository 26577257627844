import { MessageStrip } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/NewBusinessCheckQuestion.module.css'
import { NewBusinessCheckRadioButtons } from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/NewBusinessCheckRadioButtons'
import { TreeItemCustomWithMaxWidth } from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/TreeItemCustomWithMaxWidth'
import useNewBusinessCheckQuestionnaireAnswerFormatter from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/useNewBusinessCheckQuestionnaireAnswerFormatter'

const NewBusinessCheckQuestion = ({
  question,
  questionResult,
  keyPath,
  showEditMode,
  questionIsDisabled,
  resultMessageStrip,
  infoMessageTypes,
  t,
  handleOnChange,
  questionComment,
  handleCommentChange,
  className,
}) => {
  const formatAnswer = useNewBusinessCheckQuestionnaireAnswerFormatter()

  const showCommentEditComponent = showEditMode && !!question.commentEditComponent
  const showCommentDisplayComponent =
    !showEditMode && !!question.commentDisplayComponent && !isNil(questionResult)

  const highlightedMessageStripText = resultMessageStrip?.highlightedTextKey && (
    <span className={styles.highlighedMessageStripText}>
      {t(resultMessageStrip.highlightedTextKey)}
    </span>
  )

  return (
    <TreeItemCustomWithMaxWidth
      key={keyPath.join('-')}
      className={className}
      content={
        <div className={styles.questionContentWrapper}>
          <div className={styles.question} key={question.key}>
            <span
              className={`${styles.questionText} ${
                keyPath.length > 1 ? styles.nestedQuestionText : ''
              }`}
            >
              {t(keyPath.join('.'))}
            </span>
            {showEditMode ? (
              <div className={styles.questionAnswerSection}>
                <NewBusinessCheckRadioButtons
                  disabled={questionIsDisabled}
                  name={`questionAnswerRadioButton-${question.key}`}
                  currentAnswer={questionResult}
                  handleOnChange={handleOnChange}
                  keyPath={keyPath}
                  proceedWith={question.proceedWith}
                  hasIndeterminableOption={question.hasIndeterminableOption}
                />
              </div>
            ) : (
              <div className={styles.questionAnswerSection}>
                {!isNil(questionResult) && formatAnswer(questionResult)}
              </div>
            )}
          </div>
          {question.hint && (
            <div
              className={`${styles.questionHint} ${
                keyPath.length > 1 ? styles.nestedQuestionHint : ''
              }`}
            >
              {t(`${keyPath.join('.')}.hint`)}
            </div>
          )}
          {showCommentEditComponent && (
            <question.commentEditComponent
              keyPath={keyPath}
              comment={questionComment}
              disabled={questionIsDisabled}
              handleCommentChange={handleCommentChange}
            />
          )}
          {showCommentDisplayComponent && (
            <question.commentDisplayComponent comment={questionComment} />
          )}
          {infoMessageTypes?.map((infoMessageType) => (
            <MessageStrip
              key={infoMessageType}
              className={styles.questionMessageStripDesign}
              hideCloseButton
            >
              {t(infoMessageType)}
            </MessageStrip>
          ))}
          {resultMessageStrip && (
            <MessageStrip
              design={resultMessageStrip.messageStripDesign}
              className={styles.questionMessageStripDesign}
              hideCloseButton
            >
              <span>
                {t(resultMessageStrip.key)}
                {highlightedMessageStripText}
              </span>
            </MessageStrip>
          )}
        </div>
      }
    />
  )
}

NewBusinessCheckQuestion.propTypes = {
  question: PropTypes.shape({
    key: PropTypes.string.isRequired,
    hint: PropTypes.bool,
    hasIndeterminableOption: PropTypes.bool,
    commentEditComponent: PropTypes.func,
    commentDisplayComponent: PropTypes.func,
    proceedWith: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.arrayOf(PropTypes.string).isRequired,
        condition: PropTypes.arrayOf(
          PropTypes.shape({
            keys: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
            result: PropTypes.string.isRequired,
          }),
        ),
      }),
    ).isRequired,
  }).isRequired,
  questionResult: PropTypes.string,
  keyPath: PropTypes.arrayOf(PropTypes.string).isRequired,
  showEditMode: PropTypes.bool.isRequired,
  questionIsDisabled: PropTypes.bool.isRequired,
  resultMessageStrip: PropTypes.shape({
    key: PropTypes.string,
    messageStripDesign: PropTypes.string,
    text: PropTypes.string,
    highlightedTextKey: PropTypes.string,
  }),
  infoMessageTypes: PropTypes.arrayOf(PropTypes.string),
  t: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  questionComment: PropTypes.object,
  handleCommentChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default NewBusinessCheckQuestion
