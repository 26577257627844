import { useRequest } from 'hooks/services/baseService'

// camelizing keys which contains numbers could cause potential formatting errors
// e.g. string_value_2 -> stringValue2 -> string_value_2
// since pricing meta property contains custom fields where we do not have control over key names
// camelizing the response could potentially cause errors
const useGetPricing = ({ dealUuid }) =>
  useRequest({
    path: `/deals/${dealUuid}/pricing`,
    keys: ['deals', dealUuid, 'pricing'],
    options: { enabled: !!dealUuid },
  })
export default useGetPricing
