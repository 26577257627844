import get from 'lodash.get'
import isNumber from 'lodash.isnumber'
import isString from 'lodash.isstring'
import orderBy from 'lodash.orderby'

const caseInsensitiveOrderBy = (data, key, direction) =>
  orderBy(
    data,
    [
      (item) => {
        const value = get(item, key + '.value')
        if (isString(value)) {
          return value ? value.toLowerCase() : ''
        }
        if (isNumber(value)) {
          return value
        }
        return ''
      },
    ],
    [direction],
  )

export default caseInsensitiveOrderBy
