import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/properties-search/table/PropertyCreatedByName.module.css'
import StaffMemberByUserId from 'components/ui/staff-member/StaffMemberByUserId'

const PropertyCreatedByName = ({ userId }) => {
  const { t } = useTranslation()
  const fontStyle = userId ? 'normal' : 'italic'

  return (
    <Label className={styles.label} wrappingType="Normal" style={{ fontStyle }}>
      {(userId && <StaffMemberByUserId s4UserId={userId} />) || t('pages.properties.data-error')}
    </Label>
  )
}

PropertyCreatedByName.propTypes = {
  userId: PropTypes.string,
}

export default PropertyCreatedByName
