import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  ObjectStatus,
  TableRowType,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropType from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { eventStatus, getObjectStatusForEventStatus } from 'api/events/status'
import BusinessObjectLink from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import AssigneeTableCell from 'components/domains/business-events-and-tasks/events/AssigneeTableCell'
import styles from 'components/domains/business-events-and-tasks/events/events-summary-card/EventsSummaryCardTable.module.css'
import Entity from 'components/ui/data/Entity'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import paths from 'routes/paths'

const EventsSummaryCardTable = ({
  events,
  shownColumns,
  noDataText,
  additionalActions,
  openEventInNewTab,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.events.events-summary-card.table',
  })

  const { t: tWithoutPrefix } = useTranslation()
  const { format } = useShortDateFormatter()

  const possibleEventColumns = useMemo(
    () => ({
      name: {
        title: t('name'),
        columnKey: 'name',
      },
      businessObject: {
        title: t('business-object'),
        columnKey: 'businessObject',
      },
      businessObjectType: {
        title: t('business-object-type'),
        columnKey: 'businessObjectType',
      },
      assignee: {
        title: t('assignee'),
        columnKey: 'assignee',
      },
      dueDate: {
        title: t('due-date'),
        columnKey: 'dueDate',
      },
      status: {
        title: t('status'),
        columnKey: 'status',
      },
    }),
    [t],
  )

  const eventColumns = useMemo(
    () => shownColumns.map((columnKey) => possibleEventColumns[columnKey]),
    [shownColumns, possibleEventColumns],
  )

  const displayStatus = useCallback(
    (status) => {
      const { translationKey: statusTranslationKey, objectStatus } =
        getObjectStatusForEventStatus(status)
      return (
        <ObjectStatus inverted state={objectStatus}>
          {tWithoutPrefix(statusTranslationKey)}
        </ObjectStatus>
      )
    },
    [tWithoutPrefix],
  )

  const tableData = useMemo(
    () =>
      events.map(
        ({
          id,
          displayId,
          status,
          entityRef: { entityType, entityId },
          info: { name, assignee: assigneeId, originalDueDate, currentDueDate },
        }) => ({
          rowKey: `events-table-${id}`,
          rowProperties: {
            type: TableRowType.Active,
            'data-event-id': id,
          },
          name: {
            cellComponent: (
              <Entity
                openInNewTab={openEventInNewTab}
                name={name}
                id={displayId}
                link={`/${paths.businessEventsAndTasks}/business-events/${id}`}
              />
            ),
          },
          businessObject: {
            cellComponent: <BusinessObjectLink entityId={entityId} entityType={entityType} />,
          },
          businessObjectType: {
            cellComponent: <Label>{tWithoutPrefix(`events.entity-type.${entityType}`)}</Label>,
          },
          assignee: {
            cellComponent: assigneeId ? <AssigneeTableCell assigneeId={assigneeId} /> : null,
          },
          dueDate: {
            cellComponent: (
              <FlexBox direction={FlexBoxDirection.Column}>
                {currentDueDate ? (
                  <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween}>
                    <Label>{t('current')}</Label>
                    <Text>{`${format(currentDueDate)}`}</Text>
                  </FlexBox>
                ) : null}
                {originalDueDate ? (
                  <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween}>
                    <Label>{t('original')}</Label>
                    <Text>{`${format(originalDueDate)}`}</Text>
                  </FlexBox>
                ) : null}
              </FlexBox>
            ),
          },
          status: {
            cellComponent: <Label>{displayStatus(status)}</Label>,
          },
        }),
      ),
    [events, openEventInNewTab, tWithoutPrefix, t, format, displayStatus],
  )

  const toolbarConfig = () => ({
    title: t('header.title'),
    additionalActions: [additionalActions],
    showColumnSelection: false,
  })

  return (
    <div className={styles.tableWrapper}>
      <SortedTable
        columnDefinitions={eventColumns}
        tableData={tableData}
        toolbarConfig={!isNil(additionalActions) ? toolbarConfig() : undefined}
        additionalTableProperties={{
          stickyColumnHeader: true,
          className: styles.tableProperties,
        }}
        noDataText={noDataText ?? t('no-data-text')}
      />
    </div>
  )
}

EventsSummaryCardTable.propTypes = {
  events: PropType.arrayOf(
    PropType.shape({
      id: PropType.string,
      displayId: PropType.string,
      status: PropType.oneOf(Object.values(eventStatus)),
      entityRef: PropType.shape({
        entityType: PropType.string,
        entityId: PropType.string,
      }),
      info: PropType.shape({
        name: PropType.string,
        assignee: PropType.string,
        originalDueDate: PropType.string,
        currentDueDate: PropType.string,
      }),
    }),
  ).isRequired,
  shownColumns: PropType.arrayOf(
    PropType.oneOf([
      'name',
      'businessObject',
      'businessObjectType',
      'assignee',
      'dueDate',
      'status',
    ]),
  ).isRequired,
  noDataText: PropType.string,
  // eslint-disable-next-line react/forbid-prop-types
  additionalActions: PropType.object,
  openEventInNewTab: PropType.bool,
}

export default EventsSummaryCardTable
