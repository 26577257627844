import isEmpty from 'lodash.isempty'

export const getOrdinalPositionForRiskFigureTypes = (riskFigures, riskFigureTypesConfig) => {
  if (!riskFigures || !riskFigureTypesConfig || !riskFigureTypesConfig.riskFigureCategories) {
    return null
  }
  const riskFiguresTypesConfigMap = riskFigureTypesConfig?.riskFigureCategories?.reduce(
    (acc, currentCategory) => {
      acc[currentCategory?.name] = currentCategory?.ordinalPosition
      return acc
    },
    {},
  )

  return Object.keys(riskFigures)?.reduce((acc, currentRiskFigure) => {
    acc[currentRiskFigure] = {
      ...riskFigures[currentRiskFigure],
      ordinalPosition: riskFiguresTypesConfigMap[currentRiskFigure],
    }

    return acc
  }, {})
}

export const sortByOrdinalPosition = (data) => {
  if (isEmpty(data)) return []
  const sortable = Object.entries(data)
  sortable.sort(([, a], [, b]) => a.ordinalPosition - b.ordinalPosition)

  return sortable
}
