import {
  ComboBox,
  ComboBoxFilter,
  ComboBoxItem,
  FlexBox,
  FlexBoxDirection,
  Label,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionsMassEditOperations } from 'api/conditions/conditions'
import { useExternalDealTeam } from 'hooks/services/deals/deal-team/useExternalDealTeam'
import { DealContext } from 'routes/deals/DealContext'

const operations = [conditionsMassEditOperations.keep, conditionsMassEditOperations.clear]

const ConditionsMassEditDealExternalAssigneeSelect = ({ onChange, disabled }) => {
  const {
    deal: { dealUuid: dealUuid },
  } = useContext(DealContext)
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.dialogs.mass-edit',
  })
  const [value, setValue] = useState()
  const searchString = useMemo(() => value?.toLocaleLowerCase() ?? '', [value])
  const { data: externalAssignees, isError } = useExternalDealTeam({
    dealUuid,
  })

  const options = useMemo(() => {
    const operationItems = operations.map((operation) => (
      <ComboBoxItem
        key={operation}
        data-operation={operation}
        text={t(`operations.${operation}`)}
      />
    ))
    const dealTeamMembers = externalAssignees?.externalDealTeamMembers ?? []
    const externalAssigneeItems = dealTeamMembers
      .filter(
        ({ name, email }) =>
          name?.toLocaleLowerCase().includes(searchString) ||
          email?.toLocaleLowerCase().includes(searchString),
      )
      .map(({ name, email }) => {
        const textToDisplay = name === '' ? email : name

        return (
          <ComboBoxItem
            key={`${name}-${email}`}
            data-operation={conditionsMassEditOperations.replace}
            data-user-email={email}
            text={textToDisplay}
          />
        )
      })
    return [...operationItems, ...externalAssigneeItems]
  }, [externalAssignees?.externalDealTeamMembers, searchString, t])

  const handleSelectionChanged = useCallback(
    (event) => {
      const operation = event.detail?.item?.getAttribute('data-operation')
      const assigneeId = event.detail?.item?.getAttribute('data-user-email')

      onChange({ operation, value: assigneeId })
    },
    [onChange],
  )

  const handleInput = useCallback(
    (event) => {
      const input = event.target.getAttribute('value')
      setValue(input)
    },
    [setValue],
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label>{t('labels.external-assignee')}</Label>
      <ComboBox
        onSelectionChange={handleSelectionChanged}
        onInput={handleInput}
        value={t(`operations.${conditionsMassEditOperations.keep}`)}
        valueState={isError ? ValueState.Error : ValueState.None}
        valueStateMessage={<Text>{t('assignee.loading.error')}</Text>}
        filter={ComboBoxFilter.Contains}
        disabled={disabled}
      >
        {options}
      </ComboBox>
    </FlexBox>
  )
}

ConditionsMassEditDealExternalAssigneeSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default ConditionsMassEditDealExternalAssigneeSelect
