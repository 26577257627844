import find from 'lodash.find'
import { useMemo } from 'react'
import { useExternalIdTypeConfigValues } from 'hooks/services/properties/external-ids/useExternalIdTypeConfigValues'
import { useExternalIdTypes } from 'hooks/services/properties/external-ids/useExternalIdTypes'

/**
 * Returns all external id type config values together with the translation
 * which was retrieved from the externalIdTypes
 */
export const useExternalIdTypeConfigValuesWithTranslation = () => {
  const { data: externalIdTypeConfigValuesReturned } = useExternalIdTypeConfigValues()
  const externalIdTypeConfigValues = externalIdTypeConfigValuesReturned?.externalIdTypeConfigValues

  const { data: externalIdTypesReturned } = useExternalIdTypes()
  const externalIdTypes = externalIdTypesReturned?.externalIdTypes

  return useMemo(
    () =>
      externalIdTypeConfigValues && externalIdTypes
        ? externalIdTypeConfigValues.map((configEntry) => ({
            ...configEntry,
            translation:
              find(externalIdTypes, ({ key }) => key === configEntry.code)?.displayName ?? null,
          }))
        : [],
    [externalIdTypeConfigValues, externalIdTypes],
  )
}
