import {
  AnalyticalTableScaleWidthMode,
  Icon,
  IllustratedMessage,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/shared/analytical/ArrearsInApprovalAnalyticalTableWithToolbar'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/shared/domains/business-partners/tile/arrears/current-approvals/CurrentApprovalsTable.module.css'
import useArrearsInApprovalTableColumns from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/useArrearsInApprovalTableColumns'
import useArrearsInApprovalTableData from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/useArrearsInApprovalTableData'

const ArrearsInApprovalTable = ({
  businessPartnerId,
  arrears,
  productHierarchy,
  arrearApprovals,
  arrearApprovalEvents,
  products,
  arrearClassifications,
  allDealsData = [], // fallback for old decision paper tiles
  tileVersion,
}) => {
  const tableRef = useRef(null)

  const { t: tWithPrefix } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.business-partner.arrears.in-approval',
  })

  const { data: tableData, datacount } = useArrearsInApprovalTableData({
    businessPartnerId,
    arrears,
    productHierarchy,
    arrearApprovals,
    arrearApprovalEvents,
    products,
    arrearClassifications,
    allDealsData,
  })

  const tableColumns = useArrearsInApprovalTableColumns({ tileVersion })

  const expandOrCollapseSubRows = useCallback((isExpanded) => {
    tableRef?.current?.toggleAllRowsExpanded?.(isExpanded)
  }, [])

  useEffect(() => {
    expandOrCollapseSubRows(true)
  }, [expandOrCollapseSubRows])

  const additionalTableActions = useMemo(
    () => [
      <Icon
        id={`arrears-in-approval-table-collapse`}
        interactive
        className={styles.icon}
        name="collapse-all"
        key={'collapse-subrows'}
        onClick={() => expandOrCollapseSubRows(false)}
      />,
      <Icon
        id={`arrears-in-approval-table-expand`}
        interactive
        className={styles.icon}
        name="expand-all"
        key={'expand-subrows'}
        onClick={() => expandOrCollapseSubRows(true)}
      />,
    ],
    [expandOrCollapseSubRows],
  )

  return (
    <AnalyticalTableWithToolbar
      ref={tableRef}
      className={styles.adjustMarginTop}
      title={tWithPrefix('arrear-approvals-with-count', {
        count: datacount,
      })}
      id="decisionPaperArrearsInApprovalTable"
      minRows={0}
      columns={tableColumns ?? []}
      overscanCountHorizontal={999}
      visibleRows={999}
      rowHeight={58}
      headerRowHeight={32}
      data={tableData ?? []}
      NoDataComponent={() => (
        <IllustratedMessage
          name="NoData"
          titleText={tWithPrefix('no-data-title')}
          subtitleText={tWithPrefix('no-data-text')}
        />
      )}
      additionalActions={additionalTableActions}
      filterable={false}
      sortable={false}
      disableColumnPopover={true}
      withRowHighlight={false}
      showNrOfEntries={false}
      loading={false}
      isTreeTable={true}
      reactTableOptions={{
        autoResetExpanded: false,
        scaleWidthMode: AnalyticalTableScaleWidthMode.Smart,
      }}
    />
  )
}

ArrearsInApprovalTable.propTypes = {
  businessPartnerId: PropTypes.string,
  arrears: PropTypes.array,
  productHierarchy: PropTypes.array,
  arrearApprovals: PropTypes.array,
  arrearApprovalEvents: PropTypes.array,
  products: PropTypes.array,
  arrearClassifications: PropTypes.object,
  allDealsData: PropTypes.arrayOf(
    PropTypes.shape({
      dealId: PropTypes.string,
      dealUuid: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  tileVersion: PropTypes.string.isRequired,
}

export default ArrearsInApprovalTable
