import { TextAlign } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { filterTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/sorted-tables/useFilters'

export const ValuationResultsTableColumns = {
  method: 'method',
  result: 'result',
  keyDate: 'keyDate',
  validFrom: 'validFrom',
  validTo: 'validTo',
  actions: 'actions',
}

const usePropertyValuationResultsTableColumns = ({ methodEnum }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.valuation-results.pages.property.valuation.results',
  })

  const columnDefinitions = useMemo(
    () => [
      {
        title: t('table.title-row.method'),
        columnKey: ValuationResultsTableColumns.method,
        filter: filterTypes.OF_ENUM_TYPE,
        additionalFilterOptions: {
          enumValues: methodEnum,
        },
      },
      {
        title: t('table.title-row.result'),
        popinText: t('table.title-row.result'),
        columnKey: ValuationResultsTableColumns.result,
        sortingDisabled: true,
        demandPopin: true,
        minWidth: 525,
        filter: filterTypes.BETWEEN_NUMBERS,
        alignment: TextAlign.End,
      },
      {
        title: t('table.title-row.key-date'),
        columnKey: ValuationResultsTableColumns.keyDate,
        filter: filterTypes.BETWEEN_DATES,
        demandPopin: true,
        minWidth: 1225,
        popinText: t('table.title-row.key-date'),
        alignment: TextAlign.End,
      },
      {
        title: t('table.title-row.valid-from'),
        columnKey: ValuationResultsTableColumns.validFrom,
        filter: filterTypes.BETWEEN_DATES,
        demandPopin: true,
        minWidth: 950,
        popinText: t('table.title-row.valid-from'),
        alignment: TextAlign.End,
      },
      {
        title: t('table.title-row.valid-to'),
        columnKey: ValuationResultsTableColumns.validTo,
        filter: filterTypes.BETWEEN_DATES,
        demandPopin: true,
        minWidth: 950,
        popinText: t('table.title-row.valid-to'),
        alignment: TextAlign.End,
      },
    ],
    [methodEnum, t],
  )

  return useMemo(() => ({ columnDefinitions }), [columnDefinitions])
}

export default usePropertyValuationResultsTableColumns
