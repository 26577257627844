import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteCashflowScenario = ({ onSuccess, onError }) => {
  const { delete: deleteCashflowScenario } = useAccessTokenRequest()
  return useMutation(
    async ({ dealUuid, cashflowScenarioUuid }) => {
      await deleteCashflowScenario({
        path: `/deals/${dealUuid}/cashflow-scenarios/${cashflowScenarioUuid}`,
      })
    },
    { onSuccess, onError },
  )
}

export default useDeleteCashflowScenario
