import isEmpty from 'lodash.isempty'

const assigneeChanged = (state, { payload: { assignee: newAssignee } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }
  state.editedRow.currentValues.assignee = newAssignee
  if (newAssignee === state.editedRow.initialValues.assignee) {
    delete state.editedRow.changedFields.assignee
    return
  }
  state.editedRow.changedFields.assignee = true
}

export default assigneeChanged
