import { ObjectPageSection } from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { useDealWorkingVersionToolbarButtons } from 'components/domains/deals/deal-adjustment/useDealWorkingVersionToolbarButtons'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useDealMini from 'hooks/services/deals/useDealMini'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'
import DealFinancingOverviewFinancialProductsTab from 'routes/deals/financing/DealFinancingOverviewFinancialProductsTab'
import DealFinancingOverviewReservationTab from 'routes/deals/financing/DealFinancingOverviewReservationTab'
import { DEAL_STATUS_RANKS, DATA_SOURCES } from 'routes/deals/financing/financingConstants'
import NewAndExistingBusinessSwitch from 'routes/deals/financing/trancheDetails/financing-header/NewAndExistingBusinessSwitch'

const DealFinancingOverview = () => {
  const { t } = useTranslation()
  const { deal: { dealUuid, workingVersion } = {} } = useContext(DealContext)
  const { toggleWorkingVersionButton, manageWorkingVersionButton } =
    useDealWorkingVersionToolbarButtons()
  const { data: { rank } = {} } = useDealMini(dealUuid)
  const [searchParams] = useSearchParams()

  const breadcrumb = { text: t('pages.deals.breadcrumbs.financing') }

  const shouldShowNewAndExistingBusinessSwitch =
    rank >= DEAL_STATUS_RANKS.SIGNED && rank < DEAL_STATUS_RANKS.DEAD

  const newAndExistingBusinessSwitch = useMemo(
    () =>
      shouldShowNewAndExistingBusinessSwitch && (
        <NewAndExistingBusinessSwitch key="new-and-existing-business-switch" />
      ),
    [shouldShowNewAndExistingBusinessSwitch],
  )

  const isNewBusinessDeal = searchParams.get('dataSource') === DATA_SOURCES.NEW_BUSINESS

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [dealUuid],
  )

  const headerActions = useMemo(
    () =>
      createSegmentedHeaderActions(
        [toggleWorkingVersionButton, manageWorkingVersionButton],
        [newAndExistingBusinessSwitch],
        [<CreateDealEventAction key="create-event-action" />, markFavoriteAction],
      ),
    [
      manageWorkingVersionButton,
      markFavoriteAction,
      newAndExistingBusinessSwitch,
      toggleWorkingVersionButton,
    ],
  )

  return (
    <>
      {isNewBusinessDeal && workingVersion === WorkingVersionType.LIVE ? (
        <DealDetailsWrapper childBreadcrumbs={breadcrumb} actions={headerActions}>
          <DealFinancingOverviewFinancialProductsTab />
        </DealDetailsWrapper>
      ) : (
        <DealDetailsWrapper
          isTabPage
          childBreadcrumbs={breadcrumb}
          actions={headerActions}
          additionalProperties={{
            includeUrlQuery: true,
            defaultSectionId: 'financial-products-tab',
          }}
        >
          <ObjectPageSection
            id={'financial-products-tab'}
            titleText={t('pages.deals.financing.financial-products-tab')}
          >
            <DealFinancingOverviewFinancialProductsTab />
          </ObjectPageSection>
          <ObjectPageSection
            id="reservations-tab"
            titleText={t('pages.deals.financing.reservations-tab')}
          >
            <DealFinancingOverviewReservationTab />
          </ObjectPageSection>
        </DealDetailsWrapper>
      )}
    </>
  )
}

export default DealFinancingOverview
