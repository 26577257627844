import isNil from 'lodash.isnil'

const mapRegulatoryInformationData = ({ borrowerId, multipleRegulatoryInformationData }) => {
  const regulatoryInformationData =
    multipleRegulatoryInformationData?.find((data) => data.businessPartnerId === borrowerId) ?? {}

  let hasShadowBankingEntity
  if (isNil(regulatoryInformationData?.shadowBankingEntity)) {
    hasShadowBankingEntity = null
  } else {
    hasShadowBankingEntity = regulatoryInformationData.shadowBankingEntity
  }

  let hasCompanyClassificationEqual01
  if (isNil(regulatoryInformationData?.companyClassification)) {
    hasCompanyClassificationEqual01 = null
  } else {
    hasCompanyClassificationEqual01 = regulatoryInformationData.companyClassification.id === '01'
  }

  let hasLeveragedTransaction
  if (isNil(regulatoryInformationData?.leveragedTransaction)) {
    hasLeveragedTransaction = null
  } else {
    hasLeveragedTransaction = regulatoryInformationData.leveragedTransaction
  }

  let amlRiskClassification
  if (
    isNil(regulatoryInformationData?.amlRiskClassification) ||
    isNil(regulatoryInformationData?.amlRiskClassification.name) ||
    regulatoryInformationData?.amlRiskClassification.name.trim().length === 0
  ) {
    amlRiskClassification = null
  } else {
    amlRiskClassification = regulatoryInformationData?.amlRiskClassification.name.trim()
  }

  return {
    hasShadowBankingEntity,
    hasCompanyClassificationEqual01,
    hasLeveragedTransaction,
    amlRiskClassification,
  }
}

export default mapRegulatoryInformationData
