import groupBy from 'lodash.groupby'
import isNil from 'lodash.isnil'
import sortBy from 'lodash.sortby'

export const KpiThresholdTypes = {
  GREATER_THAN: '>',
  GREATER_OR_EQUAL_THAN: '≥',
  LESS_THAN: '<',
  LESS_OR_EQUAL_THAN: '≤',
}

export const getKpiForestFromKpiList = (kpiList = []) => {
  const kpiMap = {}
  kpiList.forEach((kpi) => (kpiMap[kpi.id] = { ...kpi, children: [] }))

  kpiList.forEach((kpi) => {
    kpi.parents.forEach((parentId) => kpiMap[parentId].children.push(kpiMap[kpi.id]))
  })

  return Object.values(kpiMap).filter((kpi) => kpi.parents.length === 0)
}

const extractKeyDatesFromValues = (values) =>
  values.reduce((acc, value) => {
    !acc.find((keyDate) => keyDate === value.keyDate) && acc.push(value.keyDate)

    return acc
  }, [])

const extractAndAppendKeyDatesFromAdjustments = (adjustments, keyDates) =>
  adjustments.reduce((acc, { validFrom, validTo }) => {
    !acc.find((keyDate) => keyDate === validFrom) && acc.push(validFrom)
    !acc.find((keyDate) => keyDate === validTo) && acc.push(validTo)

    return acc
  }, keyDates)

export const getTimeSeriesForKpi = (kpiTree) => {
  const today = new Date()
  const allKpiValues =
    kpiTree?.values?.toSorted((a, b) => new Date(a.keyDate) - new Date(b.keyDate)) ?? []
  const adjustments =
    kpiTree?.adjustments?.toSorted((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)) ?? []
  const adjustmentIds = adjustments.map(({ id }) => id)

  const allCurrentKeyDateValues = []

  const keyDateGroups = groupBy(allKpiValues, 'keyDate')
  Object.entries(keyDateGroups).forEach(([_, keyDateValues]) => {
    if (keyDateValues.length === 1) {
      allCurrentKeyDateValues.push(keyDateValues[0])
    }
    if (keyDateValues.length > 1) {
      const currentKeyDateValue = sortBy(keyDateValues, 'validFrom').reverse()[0]
      allCurrentKeyDateValues.push(currentKeyDateValue)
    }
  })

  const keyDatesFromValues = extractKeyDatesFromValues(allCurrentKeyDateValues)
  const allSortedKeyDates = extractAndAppendKeyDatesFromAdjustments(
    adjustments,
    keyDatesFromValues,
  ).sort()

  return allSortedKeyDates.map((date) => {
    const kpiValueForKeyDate = allCurrentKeyDateValues.findLast(
      (keyDateValue) =>
        keyDateValue.keyDate <= date && date <= allCurrentKeyDateValues?.at(-1).keyDate,
    )

    // find an adjustment for this value -> use updatedBy if found
    const adjustmentForKeyDate = adjustments.findLast(
      (adjustment) => adjustment.validFrom <= date && adjustment.validTo >= date,
    )

    // use value if it is a direct adjustment
    const isDirectedAdjustment = adjustmentIds.includes(adjustmentForKeyDate?.id)

    const hasOngoingAdjustment =
      !!adjustmentForKeyDate &&
      today >= new Date(adjustmentForKeyDate.validFrom) &&
      today <= new Date(adjustmentForKeyDate.validTo)

    const currentValue = isDirectedAdjustment
      ? adjustmentForKeyDate?.value
      : kpiValueForKeyDate?.value

    // simple time series structure for drawing chart
    return {
      value: currentValue,
      keyDate: date,
      createdBy: kpiValueForKeyDate?.createdBy,
      updatedBy: adjustmentForKeyDate?.updatedBy,
      calculatedValue: kpiValueForKeyDate?.value,
      directAdjustedValue: isDirectedAdjustment ? adjustmentForKeyDate.value : null,
      overallAdjustedValue: adjustmentForKeyDate?.updatedBy ? currentValue : null,
      comment: isDirectedAdjustment ? adjustmentForKeyDate.comment ?? null : null,
      hasOngoingAdjustment,
    }
  })
}

export const getCurrentValueForTimeSeries = (timeSeries) => {
  const today = new Date()
  const lastKeyDateWithCalculatedValue = timeSeries
    // timeSeries now contain also adjustment key dates, so current value should also be current calculated value, not adjusted (since adjustments can be in the future)
    .filter(({ keyDate, calculatedValue }) => new Date(keyDate) <= today && !isNil(calculatedValue))
    .toSorted((a, b) => new Date(b.keyDate) - new Date(a.keyDate))
    .find(() => true)

  return {
    ...lastKeyDateWithCalculatedValue,
    value: lastKeyDateWithCalculatedValue?.hasOngoingAdjustment
      ? lastKeyDateWithCalculatedValue?.value
      : lastKeyDateWithCalculatedValue?.calculatedValue,
  }
}

export const getLastUltimoForTimeSeries = (timeSeries) => {
  const today = new Date()
  // last ultimo = last day of last month
  const lastUltimoDate = new Date(today.getFullYear(), today.getMonth(), 0).toDateString()
  return timeSeries.find(({ keyDate }) => new Date(keyDate).toDateString() === lastUltimoDate)
}
