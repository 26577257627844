import { useMemo } from 'react'
import { mapShare } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/deal-uses-and-sources/helper/useMappedSources'
import useDealUses from 'hooks/services/deals/deal-uses/useDealUses'

const reduceTotalAmount = (
  resultingAmout,
  {
    headquarter: { value: headquarterValue = 0 } = {},
    original: { value: originalValue = 0 } = {},
  },
) => ({
  original: resultingAmout.original + originalValue,
  headquarter: resultingAmout.headquarter + headquarterValue,
})

const calculateTotalUsesAmount = (mappedUses) => {
  if (!mappedUses || mappedUses.length === 0) {
    return { original: 0, headquarter: 0 }
  }
  const initialValue = {
    original: 0,
    headquarter: 0,
  }
  return mappedUses.reduce(reduceTotalAmount, initialValue)
}

const calculateMappedUses = (uses) => {
  if (!uses) {
    return
  }
  return uses.uses.map(({ name, value, valueHeadquarter }) => {
    const parsedValueHeadquarter = valueHeadquarter || value
    return {
      name,
      original: {
        value: value.number,
        currency: value.currencyCode,
      },
      headquarter: {
        value: parsedValueHeadquarter.number,
        currency: parsedValueHeadquarter.currencyCode,
      },
    }
  })
}

const useUsesCalculation = ({ dealUuid }) => {
  const {
    data: dealUses,
    isFetching: isDealUsesFetching,
    isError: isDealUsesError,
    error: dealUsesError,
  } = useDealUses(dealUuid, { enabled: !!dealUuid })

  const mappedUses = useMemo(() => calculateMappedUses(dealUses), [dealUses])
  const { original: totalUsesAmount, headquarter: totalUsesAmountHeadquarter } = useMemo(
    () => calculateTotalUsesAmount(mappedUses),
    [mappedUses],
  )

  const sanitizedTotalUsesAmountHeadquarter =
    totalUsesAmountHeadquarter === 0 ? Number.NaN : totalUsesAmountHeadquarter

  const mappedUsesWithShare = useMemo(() => {
    const result = []

    const usesWithShare = mappedUses?.map(mapShare(sanitizedTotalUsesAmountHeadquarter)) || []
    result.push(...usesWithShare)

    return result
  }, [mappedUses, sanitizedTotalUsesAmountHeadquarter])

  return useMemo(
    () => ({
      isDealUsesFetching,
      isDealUsesError,
      dealUsesError,
      mappedUses,
      totalUsesAmount,
      totalUsesAmountHeadquarter,
      mappedUsesWithShare,
    }),
    [
      isDealUsesError,
      isDealUsesFetching,
      dealUsesError,
      mappedUses,
      totalUsesAmount,
      mappedUsesWithShare,
      totalUsesAmountHeadquarter,
    ],
  )
}

export default useUsesCalculation
