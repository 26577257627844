import { ObjectStatus } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { objectStatusForPropertyLinkedDealByCode } from 'api/property/linked-deal/propertyLinkedDeal'
import { isMissingPermissionError } from 'api/requests'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import usePropertyFinancingStatus from 'hooks/services/properties/usePropertyFinancingStatus'

const PropertyFinancingStatusCell = ({ isLoading, isError, error, propertyData }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.markets.detail.properties',
  })

  const {
    data: financingStatusData,
    isLoading: isLoadingFinancingStatus,
    isError: isErrorFinancingStatus,
  } = usePropertyFinancingStatus()

  const displayName = useCallback(
    () =>
      financingStatusData?.financingStatusCodes.find(
        (element) => element.key === propertyData.financingStatusCode,
      )?.displayName ?? t('table.linked-deal.unknown'),
    [financingStatusData, propertyData, t],
  )

  const renderCell = useCallback(
    () => (
      <ObjectStatus
        inverted
        state={
          objectStatusForPropertyLinkedDealByCode[propertyData.financingStatusCode]?.objectStatus
        }
      >
        {displayName()}
      </ObjectStatus>
    ),
    [displayName, propertyData],
  )

  if (isError && isMissingPermissionError(error)) {
    return t('error.missing-permission')
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading || isLoadingFinancingStatus}
      isError={isError || isErrorFinancingStatus}
      renderContent={renderCell}
      error={t('error')}
    />
  )
}

PropertyFinancingStatusCell.propTypes = {
  propertyData: PropTypes.shape({
    financingStatusCode: PropTypes.string.isRequired,
  }),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  error: PropTypes.shape({
    response: PropTypes.shape({
      status: PropTypes.number.isRequired,
    }),
  }),
}

export default PropertyFinancingStatusCell
