const requirementAssigneeChanged = (
  state,
  { payload: { conditionIndex, requirementIndex, assignee: newAssignee } },
) => {
  if (
    !state.conditions[conditionIndex] ||
    !state.conditions[conditionIndex].requirements[requirementIndex]
  ) {
    return
  }
  state.conditions[conditionIndex].requirements[requirementIndex].assignee = newAssignee
}

export default requirementAssigneeChanged
