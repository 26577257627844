import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const DEFAULT_LIMIT = 50
const DEFAULT_OFFSET = 0

const useDecisionPaperTemplateConfiguration = (
  { templateCode, offset = DEFAULT_OFFSET, limit = DEFAULT_LIMIT },
  options,
) => {
  const params = new URLSearchParams()
  params.append('template_code', templateCode)
  params.append('offset', offset)
  params.append('limit', limit)

  return useCamelizedResponse(
    useRequest({
      path: `/events/configurations/decision-paper-templates?${params}`,
      useCache: true,
      keys: ['events', 'configurations', 'decision-paper-templates', templateCode, offset, limit],
      options,
    }),
  )
}

export default useDecisionPaperTemplateConfiguration
