import _ from 'lodash'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'

const BRANDING_FRANCHISE_FUNCTION_TYPE = 'branding-franchise'

const getBusinessPartnerIdByFunctionType = (businessPartners, functionType) =>
  businessPartners?.find((businessPartner) => businessPartner.function_type === functionType)?.id

const expandRentalUnits = (rentalUnits) => {
  const externalBusinessPartnerIds = []
  const tenantIds = []
  const rentalUnitsExpanded = rentalUnits?.map((rentalUnit) => {
    const { tenant: tenantId, external_business_partners } = rentalUnit
    const rentalUnitExpanded = {
      ...rentalUnit,
      tenant: {},
      branding_franchise: {},
    }
    if (tenantId) {
      rentalUnitExpanded.tenant.id = tenantId
      tenantIds.push(tenantId)
    }
    if (!external_business_partners?.length) return rentalUnitExpanded
    const brandingFranchiseId = getBusinessPartnerIdByFunctionType(
      external_business_partners,
      BRANDING_FRANCHISE_FUNCTION_TYPE,
    )
    if (brandingFranchiseId) {
      rentalUnitExpanded.branding_franchise.id = brandingFranchiseId
      externalBusinessPartnerIds.push(brandingFranchiseId)
    }
    return rentalUnitExpanded
  })
  return { tenantIds, externalBusinessPartnerIds, rentalUnitsExpanded }
}

const getBusinessPartnerInfoById = (businessPartners, id) =>
  businessPartners?.find((businessPartner) => businessPartner.id === id)

const getExternalBusinessPartnerExpanded = (rentalUnit, externalBusinessPartners, functionType) => {
  const { [functionType]: businessPartner } = rentalUnit
  return businessPartner?.id
    ? {
        ...businessPartner,
        name: getBusinessPartnerInfoById(externalBusinessPartners, businessPartner.id)?.fullName,
      }
    : {}
}
const getTenantExpanded = (tenant, tenants) =>
  tenant
    ? {
        ...tenant,
        name: getBusinessPartnerInfoById(tenants, tenant.id)?.fullName,
      }
    : {}

const expandBusinessPartners = (rentalUnits, tenants, externalBusinessPartners) =>
  rentalUnits?.map((rentalUnit) => ({
    ...rentalUnit,
    tenant: !rentalUnit?.anonymous_tenant ? getTenantExpanded(rentalUnit?.tenant, tenants) : {},
    branding_franchise: getExternalBusinessPartnerExpanded(
      rentalUnit,
      externalBusinessPartners,
      'branding_franchise',
    ),
  }))

export const useExpandRentalUnitsWithBusinessPartners = (rentalUnits) => {
  const { tenantIds, externalBusinessPartnerIds, rentalUnitsExpanded } =
    expandRentalUnits(rentalUnits)
  const {
    data: externalBusinessPartners,
    isLoading: isLoadingExternalBusinessPartners,
    isError: isErrorExternalBusinessPartners,
    fetchStatus: fetchStatusExternalBusinessPartners,
  } = useBusinessPartnerMiniByIds(_.uniq(externalBusinessPartnerIds), {
    enabled: !!externalBusinessPartnerIds?.length,
  })

  const {
    data: tenants,
    isLoading: isLoadingTenants,
    isError: isErrorTenants,
    fetchStatus: fetchStatusTenants,
  } = useBusinessPartnerMiniByIds(_.uniq(tenantIds), {
    enabled: !!tenantIds?.length,
  })

  const data =
    rentalUnits &&
    (Array.isArray(tenants?.businessPartnerMinis) ||
      Array.isArray(externalBusinessPartners?.businessPartnerMinis))
      ? expandBusinessPartners(
          rentalUnitsExpanded,
          tenants?.businessPartnerMinis,
          externalBusinessPartners?.businessPartnerMinis,
        )
      : rentalUnitsExpanded

  return {
    data,
    isLoading:
      (isLoadingExternalBusinessPartners && fetchStatusExternalBusinessPartners === 'fetching') ||
      (isLoadingTenants && fetchStatusTenants === 'fetching'),
    isError: isErrorExternalBusinessPartners || isErrorTenants,
  }
}
