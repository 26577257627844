import {
  ButtonDesign,
  CardHeader,
  FlexBox,
  Label,
  FlexBoxJustifyContent,
  Link,
  LinkDesign,
  FlexBoxDirection,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'components/domains/properties/PropertyCards.css'
import { propertyMonitoringStatus } from 'api/property/monitoring/propertyMonitoringConfiguration'
import PropertyAssignMarketButton from 'components/domains/properties/PropertyAssignMarketButton'
import PropertyUnassignMarketButton from 'components/domains/properties/PropertyUnassignMarketButton'
import Card from 'components/ui/card/Card'
import LeafletMapWithPolygonAndPropertyMarkers from 'components/ui/map/LeafletMapWithPolygonAndPropertyMarkers'
import { MarkerColors } from 'components/ui/map/internal/markers/MarkerColors'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useMarketArea from 'hooks/services/markets/useMarketArea'
import useMultiProperties from 'hooks/services/properties/useMultiProperties'
import paths from 'routes/paths'

const PropertyMarketInfoCard = ({
  propertyId,
  marketId,
  marketName,
  marketType,
  marketPropertyIds,
  displayAssignButton,
  monitoringStatus,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.property-overview-market-info-card',
  })
  const [displayPolygon, setDisplayPolygon] = useState('')
  const {
    isLoading: isLoadingArea,
    isError: isErrorArea,
    data: marketAreaData,
  } = useMarketArea(marketId)
  const {
    isLoading: isPropertiesLoading,
    isError: isPropertiesError,
    data: propertyRequestsData,
  } = useMultiProperties(marketPropertyIds)
  let marketPropertyMarkersData = []

  useEffect(() => {
    if (!marketAreaData) return
    setDisplayPolygon(marketAreaData.area.polygonCoordinates)
  }, [marketAreaData])

  const mapMarketPropertyMarkersData = (property) => ({
    uuid: property.uuid,
    id: property.id,
    name: property.description,
    address: {
      country: property.address.countryName,
      street: property.address.streetName,
      houseNumber: property.address.houseId,
      zipCode: property.address.postalCode,
      city: property.address.cityName,
    },
    propertyType: property.typeName,
    location: {
      lat: +property.geoLocation.latitude,
      lng: +property.geoLocation.longitude,
    },
    creationDate: property.systemAdministrativeData.creationDateTime,
    color: propertyId === property.uuid ? MarkerColors.Blue : MarkerColors.Grey,
    linkedDeal: property.financingStatusCode,
  })

  const mapProperties = () => propertyRequestsData.properties.map(mapMarketPropertyMarkersData)

  const renderContent = () => {
    if (!isPropertiesError) marketPropertyMarkersData = mapProperties()

    return (
      <>
        <FlexBox
          justifyContent={FlexBoxJustifyContent.SpaceBetween}
          style={{
            padding: '16px',
          }}
        >
          <Link
            design={LinkDesign.Emphasized}
            href={`/${paths.markets}/${marketId}`}
            target="_blank"
            rel="noreferrer"
            wrappingType="Normal"
          >
            {marketName}
          </Link>
          <FlexBox>
            {displayAssignButton && (
              <PropertyUnassignMarketButton
                propertyId={propertyId}
                monitoringStatus={monitoringStatus}
                marketId={marketId}
              />
            )}
            {displayAssignButton && (
              <PropertyAssignMarketButton
                label={t('edit-assignment')}
                propertyId={propertyId}
                design={ButtonDesign.Transparent}
                style={{ height: '18px' }}
                monitoringStatus={monitoringStatus}
              />
            )}
          </FlexBox>
        </FlexBox>
        <Card>
          {marketAreaData.area.polygonCoordinates && (
            <div className="map-wrapper">
              <LeafletMapWithPolygonAndPropertyMarkers
                polygon={displayPolygon}
                markers={marketPropertyMarkersData}
              />
            </div>
          )}
        </Card>
        <FlexBox
          justifyContent={FlexBoxJustifyContent.SpaceBetween}
          direction={FlexBoxDirection.Column}
          style={{
            padding: '16px',
          }}
        >
          <FlexBox
            justifyContent={FlexBoxJustifyContent.SpaceBetween}
            style={{ paddingBottom: '8px' }}
          >
            <Label>{t('market-type')}</Label>
            {marketType}
          </FlexBox>
          <FlexBox
            justifyContent={FlexBoxJustifyContent.SpaceBetween}
            style={{ paddingBottom: '8px' }}
          >
            <Label>{t('properties-qty')}</Label>
            {marketPropertyIds.length}
          </FlexBox>
        </FlexBox>
      </>
    )
  }

  return (
    <Card header={<CardHeader titleText={t('title')} />}>
      <LoadingStateWrapper
        isLoading={isLoadingArea || isPropertiesLoading || displayPolygon === ''}
        isError={isErrorArea}
        loadingDescription={t('loading.description')}
        renderContent={renderContent}
      />
    </Card>
  )
}
PropertyMarketInfoCard.propTypes = {
  marketId: PropTypes.string.isRequired,
  propertyId: PropTypes.string.isRequired,
  marketName: PropTypes.string.isRequired,
  marketType: PropTypes.string.isRequired,
  marketPropertyIds: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  displayAssignButton: PropTypes.bool.isRequired,
  monitoringStatus: PropTypes.oneOf(Object.values(propertyMonitoringStatus)),
}

export default PropertyMarketInfoCard
