import { ObjectStatus } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import BusinessPartnerRolesView from 'components/domains/business-partners/roles/BusinessPartnerRolesView'
import useGetAndEnrichFavoritesAndRecentVisitsWithBusinessPartners from 'components/domains/business-partners/user-favorite/useGetAndEnrichFavoritesAndRecentVisitsWithBusinessPartners'
import EntityCell from 'components/ui/tables/cells/EntityCell'
import UserFavoritesAndRecentVisitsCard from 'components/ui/user-favorite/UserFavoritesAndRecentsCard'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import determineValueStateBasedOnStatus from 'routes/business-partners/determineValueState'
import paths from 'routes/paths'

const columnNames = {
  ID_AND_NAME: 'bp-id-and-name',
  COUNTRY: 'bp-country',
  CITY: 'bp-city',
  ROLES: 'bp-roles',
  STATUS: 'bp-status',
}

const renderBusinessPartnerIdAndName = (bpId, name) => (
  <EntityCell
    name={name}
    link={`/business-partners/${bpId}`}
    id={bpId}
    options={{ isNameBold: true }}
  />
)

const renderBusinessPartnerCountry = (address) => <>{address?.country?.name}</>
const renderBusinessPartnerCity = (address) => <>{address?.city}</>
const renderBusinessPartnerRoles = (roles) => <BusinessPartnerRolesView roles={roles} />
const renderBusinessPartnerStatus = (status, translate) => (
  <ObjectStatus inverted state={determineValueStateBasedOnStatus(status)}>
    {translate(status, { keyPrefix: 'pages.business-partner.general-information.status' })}
  </ObjectStatus>
)

const renderIfFound = (notFound, component) => (notFound ? null : component)

const mapDataToTableRow = (
  { id, fullName, address, status, roles, itemType, entityId, entityType, notFound },
  translate,
) => ({
  [columnNames.ID_AND_NAME]: renderIfFound(notFound, renderBusinessPartnerIdAndName(id, fullName)),
  [columnNames.COUNTRY]: renderIfFound(notFound, renderBusinessPartnerCountry(address)),
  [columnNames.CITY]: renderIfFound(notFound, renderBusinessPartnerCity(address)),
  [columnNames.ROLES]: renderIfFound(notFound, renderBusinessPartnerRoles(roles)),
  [columnNames.STATUS]: renderIfFound(notFound, renderBusinessPartnerStatus(status, translate)),
  itemType,
  entityId,
  entityType,
  notFound,
})

const mapDataToTableRows = (data, translate) =>
  data?.map((record) => mapDataToTableRow(record, translate)) ?? []

const BusinessPartnersUserFavoritesAndRecentVisitsCard = () => {
  const { t } = useTranslation('translation')
  const businessPartnerUserFavoritesAndRecentVisits =
    useGetAndEnrichFavoritesAndRecentVisitsWithBusinessPartners()
  const navigate = useNavigate()
  const handleOnRowClick = (businessPartnerId) => {
    if (businessPartnerId) {
      navigate(`/${paths.businessPartners}/${businessPartnerId}`)
    }
  }
  const handleOnHeaderClick = () => navigate(`/${paths.businessPartners}`)

  return (
    <UserFavoritesAndRecentVisitsCard
      entityType={cwpEntityTypes.BUSINESS_PARTNER}
      title="bp-title"
      subtitle="bp-subtitle"
      isError={businessPartnerUserFavoritesAndRecentVisits.isError}
      isLoading={businessPartnerUserFavoritesAndRecentVisits.isLoading}
      rowsData={mapDataToTableRows(businessPartnerUserFavoritesAndRecentVisits.data, t)}
      columnNames={Object.values(columnNames)}
      handleOnRowClick={handleOnRowClick}
      handleOnHeaderClick={handleOnHeaderClick}
    />
  )
}

export default BusinessPartnersUserFavoritesAndRecentVisitsCard
