import { useMemo } from 'react'
import useDecisionStageMinutes from 'hooks/services/business-events-and-tasks/decision-process/stages/minutes/useDecisionStageMinutes'
import useDecisionStageMinutesVoters from 'hooks/services/business-events-and-tasks/decision-process/stages/minutes/useDecisionStageMinutesVoters'
import useDecisionStage from 'hooks/services/business-events-and-tasks/decision-process/stages/useDecisionStage'
import { useStaffMemberSelf } from 'hooks/services/business-partners/staff-members/useStaffMemberSelf'

const useDecisionStageMinutesApproval = (
  { eventId, decisionStageId, voterId },
  options = { enabled: true },
) => {
  const {
    isLoading: isDecisionStageLoading,
    isError: isDecisionStageError,
    data: decisionStageData,
  } = useDecisionStage({ eventId, decisionStageId }, options)

  const {
    isLoading: isDecisionStageMinutesLoading,
    isError: isDecisionStageMinutesError,
    data: decisionStageMinutesData,
  } = useDecisionStageMinutes({ eventId, decisionStageId }, options)

  const {
    isLoading: isStaffMemberSelfLoading,
    isError: isStaffMemberSelfError,
    data: staffMember,
  } = useStaffMemberSelf(options)

  const {
    isLoading: isDecisionStageMinutesVotersLoading,
    isError: isDecisionStageMinutesVotersError,
    data: decisionStageMinutesVoters,
  } = useDecisionStageMinutesVoters({ eventId, decisionStageId }, options)

  return useMemo(() => {
    const isLoading =
      isDecisionStageLoading ||
      isStaffMemberSelfLoading ||
      isDecisionStageMinutesVotersLoading ||
      isDecisionStageMinutesLoading
    const isError =
      isDecisionStageError ||
      isStaffMemberSelfError ||
      isDecisionStageMinutesVotersError ||
      isDecisionStageMinutesError
    return {
      isLoading,
      isError,
      data:
        !isLoading && !isError
          ? {
              decisionStageData,
              decisionStageMinutesData,
              staffMember,
              decisionStageMinutesVoters,
              userVote: decisionStageMinutesVoters.voters.find(
                ({ userId, id }) => userId === staffMember.id && id === voterId,
              ),
            }
          : undefined,
    }
  }, [
    isDecisionStageLoading,
    isStaffMemberSelfLoading,
    isDecisionStageMinutesVotersLoading,
    isDecisionStageMinutesLoading,
    isDecisionStageError,
    isStaffMemberSelfError,
    isDecisionStageMinutesVotersError,
    isDecisionStageMinutesError,
    decisionStageData,
    decisionStageMinutesData,
    staffMember,
    decisionStageMinutesVoters,
    voterId,
  ])
}

export default useDecisionStageMinutesApproval
