import { AnalyticalTableSelectionMode } from '@fioneer/ui5-webcomponents-react'

export const TrancheSearchTableMode = {
  SingleSelect: AnalyticalTableSelectionMode.SingleSelect,
  MultiSelect: AnalyticalTableSelectionMode.MultiSelect,
  NavigateOnClick: 'None',
}

export const TrancheSearchTableColumns = {
  TRANCHE: 'tranche',
  TRANCHE_STATUS: 'trancheStatus',
  DEAL: 'deal',
  DEAL_STATUS: 'dealStatus',
  BUSINESS_TYPE: 'businessType',
  TOTAL_COMMITMENT: 'totalCommitment',
  CREATEDAT: 'createdAt',
  CREATEDBY: 'createdBy',
}
