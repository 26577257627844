import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { isNotFoundError } from 'api/requests'
import { sortingFields } from 'components/ui/card/VersionsHistoryButtonLoadingWrapper'
import IntegratedVersionedDisplayAndEditCard from 'components/ui/card/integrated-versioned-display-and-edit-card/IntegratedVersionedDisplayAndEditCard'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useCreateCustomAssessmentVersionEditWrapper from 'hooks/services/central-data/custom-assessments/useCreateCustomAssessmentVersionEditWrapper'
import useCustomAssessmentVersions from 'hooks/services/central-data/custom-assessments/useCustomAssessmentVersions'

const CustomAssessmentTile = ({
  entityType,
  entityId,
  assessmentCode,
  title,
  hasEditPermission,
}) => {
  const {
    isFetching,
    isError,
    error,
    data: customAssessmentVersions,
  } = useCustomAssessmentVersions({
    entityType,
    entityId,
    assessmentCode,
  })

  const id = useMemo(
    () => `${entityType}-${assessmentCode}-${entityId}`,
    [entityType, assessmentCode, entityId],
  )

  const versionsForDisplayAndEditCard = useMemo(
    () =>
      customAssessmentVersions?.versions?.map(({ version, text, updatedBy, updatedAt }) => ({
        version,
        content: text,
        createdBy: updatedBy,
        createdAt: updatedAt,
      })) ?? [],
    [customAssessmentVersions?.versions],
  )

  const getVersions = useCallback(
    () => ({
      isLoading: isFetching,
      isError: isError && !isNotFoundError(error),
      data: { availableVersions: versionsForDisplayAndEditCard },
    }),
    [isFetching, isError, versionsForDisplayAndEditCard, error],
  )

  return (
    <IntegratedVersionedDisplayAndEditCard
      id={id}
      getContentVersionsHook={getVersions}
      putContentUpdateHook={useCreateCustomAssessmentVersionEditWrapper}
      putContentUpdateHookParameters={{
        entityId,
        entityType,
        code: assessmentCode,
        versionsForDisplayAndEditCard: versionsForDisplayAndEditCard,
      }}
      hasEditPermission={hasEditPermission}
      cardTitle={title}
      sortingField={sortingFields.version}
    />
  )
}

export default CustomAssessmentTile

CustomAssessmentTile.propTypes = {
  entityType: PropTypes.oneOf(Object.values(cwpEntityTypes)).isRequired,
  entityId: PropTypes.string.isRequired,
  assessmentCode: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
}
