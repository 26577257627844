import { useMutation } from '@tanstack/react-query'
import useCurrencyConversion from 'hooks/services/central-data/useCurrencyConversion'

export const useMultipleCurrencyConversions = (options) => {
  const { onMultipleConversionSuccess, onSingleConversionSuccess, onSingleConversionError } =
    options
  const { mutate: convertCurrencyValues } = useCurrencyConversion({
    onSuccess: onSingleConversionSuccess,
    onError: onSingleConversionError,
  })

  return useMutation(
    async (valuesToConvert) =>
      await Promise.all(
        valuesToConvert.map((valueToConvert) => convertCurrencyValues(valueToConvert)),
      ),
    { onSuccess: onMultipleConversionSuccess },
  )
}
