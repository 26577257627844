import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteDecisionStageMinutesVoter = () => {
  const { delete: deleteCall } = useAccessTokenRequest()

  return useMutation(({ eventId, decisionStageId, voterId }) =>
    deleteCall({
      path: `/events/${eventId}/decision-stages/${decisionStageId}/minutes/voters/${voterId}`,
    }),
  )
}

export default useDeleteDecisionStageMinutesVoter
