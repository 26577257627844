import isNil from 'lodash.isnil'
import sortBy from 'lodash.sortby'
import uniq from 'lodash.uniq'
import { groupRentalUnitsBySegmentUsageTypeCode } from 'hooks/services/business-partners/tenancy/tenancyCalculationUtils'

const collectAllUnitsForContractedYear = (rentalUnits) =>
  rentalUnits.map((item) => item.rentContractedYear?.currency)

const getValue = (rentalUnits, units) => {
  const getRent = (rentalUnit) =>
    units.length > 1
      ? rentalUnit.rentContractedYearInHeadQuarterCurrency?.number
      : rentalUnit.rentContractedYear?.number

  return rentalUnits.reduce((sum, rentalUnit) => sum + getRent(rentalUnit), 0)
}

const getUnit = (rentalUnits, units) => {
  if (units.length > 1) {
    return rentalUnits[0].rentContractedYearInHeadQuarterCurrency?.currency
  } else {
    return units[0]
  }
}

export const calculateTenancyTotalContractedRent = (contextRentalUnits, rentalUnits, totalRent) => {
  const units = uniq(collectAllUnitsForContractedYear(contextRentalUnits))
  const value = getValue(rentalUnits, units)
  let share = NaN
  if (!isNil(totalRent)) {
    share = totalRent ? value / totalRent : 0
  }
  return {
    value,
    unit: getUnit(rentalUnits, units),
    share,
  }
}

export const calculateTenancyTotalContractedRentPerSegmentUsageType = (rentalUnits) => {
  const units = uniq(collectAllUnitsForContractedYear(rentalUnits))
  const unit = getUnit(rentalUnits, units)
  const totalContractedRentPerSegementUsageType = []

  const groupedRentalUnits = groupRentalUnitsBySegmentUsageTypeCode(rentalUnits)
  Object.entries(groupedRentalUnits).forEach((rentalUnitsBySegement) => {
    const rentalUnitsForSegment = rentalUnitsBySegement[1]
    const segmentName = rentalUnitsBySegement[1][0].segmentUsageTypeName

    totalContractedRentPerSegementUsageType.push({
      value: getValue(rentalUnitsForSegment, units),
      unit: unit,
      share: 0,
      type: segmentName,
    })
  })

  return sortBy(totalContractedRentPerSegementUsageType, (item) => item.type)
}
