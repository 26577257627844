import { Text, Link } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/sources/DecisionPaperSourceLink.module.css'
import applyTemplate from 'components/domains/business-partners/tile/ratings/applyTemplate'

const DecisionPaperSourceLink = ({
  source: { nameTranslationKey, path },
  isPdfView,
  sourceRender,
  className,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.tiles.header',
  })
  const { t: tDecisionPaper } = useTranslation('decisionPaper')

  if (isPdfView || !path) {
    return <Text className={styles.sourceLabel}>{tDecisionPaper(nameTranslationKey)}</Text>
  }

  const link = applyTemplate(path, sourceRender)

  if (link) {
    return (
      <Link target="_blank" href={link} className={className}>
        {tDecisionPaper(nameTranslationKey)}
      </Link>
    )
  }

  return <Text className={styles.sourceLabel}>{t('source.error')}</Text>
}

DecisionPaperSourceLink.propTypes = {
  source: PropTypes.shape({
    nameTranslationKey: PropTypes.string.isRequired,
    path: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sourceRender: PropTypes.object,
  isPdfView: PropTypes.bool.isRequired,
  className: PropTypes.string,
}

export default DecisionPaperSourceLink
