import { FlexBox, Input } from '@fioneer/ui5-webcomponents-react'
import { isCtrlA } from '@ui5/webcomponents-base/dist/Keys.js'
import PropTypes from 'prop-types'
import 'components/domains/properties/general-information/image/edit/PropertyImageEditInput.css'

const selectInputOnCtrlA = (event) => {
  event.target.shadowRoot.querySelector('input').select()
}
const PropertyImageEditInput = ({ imageDescription, onChange, inEdit }) => {
  if (inEdit) {
    return (
      <FlexBox
        className="property-image-name-container"
        direction="Row"
        justifyContent="Start"
        alignItems="Center"
      >
        <Input
          className="property-image-name-input"
          valueState={'None'}
          value={imageDescription}
          onKeyDown={(e) => (isCtrlA(e) ? selectInputOnCtrlA(e) : undefined)}
          onInput={(e) => {
            onChange(e.target.value)
          }}
          type="Text"
        />
      </FlexBox>
    )
  } else {
    return (
      <FlexBox direction="Row" justifyContent="Start" alignItems="Center">
        <span className="property-image-name">{imageDescription}</span>
      </FlexBox>
    )
  }
}
PropertyImageEditInput.propTypes = {
  imageDescription: PropTypes.string,
  imageUrl: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  inEdit: PropTypes.bool.isRequired,
}

export default PropertyImageEditInput
