export const sortMarketProxies = (proxyA, proxyB) => {
  const isProxyAMonitored = proxyA?.overrideProxy
    ? proxyA.overrideProxy.isMonitored
    : !!proxyA?.defaultProxy
  const isProxyBMonitored = proxyB?.overrideProxy
    ? proxyB.overrideProxy.isMonitored
    : !!proxyB?.defaultProxy

  const proxyAMonitoring = isProxyAMonitored ? 0 : 1
  const proxyBMonitoring = isProxyBMonitored ? 0 : 1
  if (proxyAMonitoring !== proxyBMonitoring) {
    return proxyAMonitoring - proxyBMonitoring
  }
  if (proxyA.name < proxyB.name) {
    return -1
  }
  if (proxyA.name === proxyB.name) {
    return 0
  }
  return 1
}

export const criticalMovementIconName = {
  DOWN: 'trend-down',
  UP: 'trend-up',
}
