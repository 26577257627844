import { uniq } from 'lodash'
import compact from 'lodash.compact'
import isEmpty from 'lodash.isempty'
import pick from 'lodash.pick'
import uniqBy from 'lodash.uniqby'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { entityTypes } from 'api/conditions/conditions'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import useBusinessPartnerCustomFieldConfig from 'components/domains/business-partners/tile/general-information/useBusinessPartnerCustomFieldConfig'
import { useMultipleBusinessPartnerCustomFields } from 'components/domains/business-partners/tile/general-information/useMultipleBusinessPartnerCustomFields'
import extractTenantIds from 'components/domains/business-partners/tile/unit-overview/property-and-tenancy/extractTenantIds'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useBusinessPartnerUnitOverviewDealComplianceWithRiskStrategy from 'hooks/services/business-partners/unit-overview/deal/useBusinessPartnerUnitOverviewDealComplianceWithRiskStrategy'
import useBusinessPartnerUnitOverviewDealLenders from 'hooks/services/business-partners/unit-overview/deal/useBusinessPartnerUnitOverviewDealLenders'
import { useMultipleRiskForbearanceStatuses } from 'hooks/services/business-partners/unit-overview/deal/useMultipleRiskForbearanceStatuses'
import { useMultipleDealTranches } from 'hooks/services/business-partners/unit-overview/loan/useMultipleDealTranches'
import { useMultipleDealsPricing } from 'hooks/services/business-partners/unit-overview/loan/useMultipleDealsPricing'
import { useMultipleAggregatedDealPortfolios } from 'hooks/services/business-partners/unit-overview/property-and-tenancy/useMultipleAggregatedDealPortfolios'
import { useMultiplePropertiesFromDeals } from 'hooks/services/business-partners/unit-overview/property-and-tenancy/useMultiplePropertiesFromDeals'
import useBusinessPartnerUnitOverview from 'hooks/services/business-partners/unit-overview/useBusinessPartnerUnitOverview'
import { useTypeCodesAllValues } from 'hooks/services/deals/configurations/useTypeCodesAllValues'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import useMultipleCovenantMonitoringItemsByEntityId from 'hooks/services/deals/covenants/monitorings/useMultipleCovenantMonitoringItemsByEntityId'
import useMultipleSyndicationsExistingBusiness from 'hooks/services/deals/syndication/useMultipleSyndicationsExistingBusiness'
import useDealMini from 'hooks/services/deals/useDealMini'
import { useMultipleDealsByUuidMini } from 'hooks/services/deals/useMultipleDealsByUuidMini'
import { useMultipleKpis } from 'hooks/services/kpis/useMultipleKpis'
import useMultiplePropertyCustomFieldsByPropertyUuids from 'hooks/services/properties/customfield/useMultiplePropertyCustomFieldsByPropertyUuids'
import useCurrentMultiPropertyKpisList from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpisList'
import { useCountryCodes } from 'hooks/services/properties/useCountryCodes'
import useMultiProperties from 'hooks/services/properties/useMultiProperties'
import { useTypecodes } from 'hooks/services/properties/useTypecodes'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const COVENANT_DEFINITION_NAME = 'Annual Review'
const ENTITY_TYPE = 'BUSINESS_PARTNER'

const useAnnualReviewUnitDealOverview = ({ entityRef: { entityId, entityType } }, tileId) => {
  const {
    data: { borrowerBpId } = {},
    isLoading: isDealLoading,
    isError: isDealError,
    error: dealError,
  } = useDealMini(entityType === cwpEntityTypes.DEAL ? entityId : undefined)

  // The selected unit is undefined if the user is currently on a different tab because the unit selection tile
  // has not been rendered, which correctly sets the loading state of this hook to true.
  // Once the use opens the tab with this tile, the selected unit will be set to a default of null,
  // which disabled the loading state, if not units are present, or, returns the correct unit selection object.
  const { selectedUnit } =
    useSelector(
      (state) =>
        state.decisionPaper.tilesOverview.tileMetadata?.[availableAutomaticTiles.unitSelectionTile],
    ) ?? {}

  const businessPartnerId = entityType === cwpEntityTypes.DEAL ? borrowerBpId : entityId

  const pickNecessaryAttributesFromQueriesResponse = (response) =>
    pick(response, ['isLoading', 'isFetching', 'isError', 'data'])

  const { data: { typecodes: typeCodesConfig = [] } = {} } = useTypecodes()
  const { data: { country_codes: countryCodesConfig = [] } = {} } = useCountryCodes()

  const unitId = selectedUnit?.typeId
  const unitHeadId = selectedUnit?.headId

  const {
    data: businessPartnerUnitOverviewData,
    isLoading: isBusinessPartnerUnitOverviewDataLoading,
    isError: isBusinessPartnerUnitOverviewDataError,
    error: businessPartnerUnitOverviewDataError,
  } = useBusinessPartnerUnitOverview({
    businessPartnerId,
    unitId: unitId,
    unitHeadId: unitHeadId,
    options: { enabled: !!businessPartnerId && !!unitId && !!unitHeadId },
  })

  const dealUuids = useMemo(
    () =>
      businessPartnerUnitOverviewData?.unitDeals
        ? businessPartnerUnitOverviewData.unitDeals?.map((unitDeal) => unitDeal.kpiDeal.dealUuid)
        : [],
    [businessPartnerUnitOverviewData?.unitDeals],
  )

  const multipleDealDetailsResponses = useMultipleDealsByUuidMini(dealUuids, {
    enabled: dealUuids?.length > 0,
  }).map(pickNecessaryAttributesFromQueriesResponse)

  const isMultipleDealDetailsResponsesLoading = multipleDealDetailsResponses.some(
    (response) => response.isLoading,
  )

  const multipleAggregatedDealPortfolioResponses = useMultipleAggregatedDealPortfolios(
    dealUuids,
  ).map(pickNecessaryAttributesFromQueriesResponse)

  const isMultipleAggregatedDealPortfoliosDataLoading =
    multipleAggregatedDealPortfolioResponses.some((response) => response.isLoading)

  const multiplePropertiesFromDealsResponses = useMultiplePropertiesFromDeals(dealUuids).map(
    pickNecessaryAttributesFromQueriesResponse,
  )
  const isMultiplePropertiesFromDealsDataLoading = multiplePropertiesFromDealsResponses.some(
    (response) => response.isLoading,
  )
  // multiPropertyKpisResponses hooks
  const propertyUuidsByDealUuids = useMemo(
    () =>
      multiplePropertiesFromDealsResponses
        ? multiplePropertiesFromDealsResponses
            .map(({ data: { dealProperties, dealUuid } }) => ({
              [dealUuid]: dealProperties?.map((dealProperty) => dealProperty.propertyUuid),
            }))
            .reduce((prev, curr) => Object.assign(prev, curr), {})
        : {},
    [multiplePropertiesFromDealsResponses],
  )

  // some deals, especially test deals, might not have properties at all.
  // calling useCurrentMultiPropertyKpisList with an empty error results in errors though.
  const dealUuidsOfDealsWithoutProperties = dealUuids.filter(
    (dealUuid) => !isEmpty(propertyUuidsByDealUuids[dealUuid]),
  )

  const {
    data: multiPropertyKpisResponsesWithoutDealUuid,
    isLoading: isMultiPropertyKpisResponsesWithoutDealUuidLoading,
    isError: isMultiPropertyKpisResponsesWithoutDealUuidError,
    error: multiPropertyKpisResponsesWithoutDealUuidError,
  } = useCurrentMultiPropertyKpisList(
    dealUuidsOfDealsWithoutProperties.map((dealUuid) => ({
      propertyUuids: propertyUuidsByDealUuids[dealUuid],
      withTenantGranularity: true,
    })),
  )

  const multiPropertyKpisResponses = useMemo(
    () =>
      dealUuidsOfDealsWithoutProperties.map((dealUuid, index) => ({
        ...multiPropertyKpisResponsesWithoutDealUuid?.[index],
        data: { ...multiPropertyKpisResponsesWithoutDealUuid?.[index]?.data, dealUuid },
      })),
    [dealUuidsOfDealsWithoutProperties, multiPropertyKpisResponsesWithoutDealUuid],
  )

  const isMultiplePropertyKpisDataLoading = multiPropertyKpisResponses.some(
    (response) => response.isLoading,
  )
  // end multiPropertyKpisResponses hooks

  const tenantIds = useMemo(
    () =>
      !multiPropertyKpisResponses?.some((e) => e.isLoading)
        ? extractTenantIds(multiPropertyKpisResponses)
        : [],
    [multiPropertyKpisResponses],
  )

  const {
    data: businessPartnersData,
    isLoading: isBusinessPartnersDataLoading,
    isError: isBusinessPartnersDataError,
    error: businessPartnersDataError,
  } = useBusinessPartnerMiniByIds(tenantIds, {
    enabled: !isEmpty(tenantIds) || false,
  })

  // useBusinessPartnerUnitOverviewDealLenders hooks
  const lendersResponses = useBusinessPartnerUnitOverviewDealLenders(dealUuids).map(
    pickNecessaryAttributesFromQueriesResponse,
  )
  const isLendersDataLoading = lendersResponses.some((response) => response.isLoading)
  // end useBusinessPartnerUnitOverviewDealLenders hooks

  // multipleSyndicationsExistingBusiness hooks
  const multipleSyndicationsExistingBusinessResponses = useMultipleSyndicationsExistingBusiness({
    dealUuids,
  }).map(pickNecessaryAttributesFromQueriesResponse)

  const isMultipleSyndicationsExistingBusinessLoading =
    multipleSyndicationsExistingBusinessResponses.some((response) => response.isLoading)

  const syndicationBusinessPartnerIds = useMemo(
    () =>
      !multipleSyndicationsExistingBusinessResponses?.some((e) => e.isLoading)
        ? multipleSyndicationsExistingBusinessResponses.flatMap(
            ({ data: { tranches = [] } = {} }) =>
              tranches?.flatMap(({ syndications = [] } = {}) =>
                syndications.map(({ bpId }) => bpId),
              ),
          )
        : [],
    [multipleSyndicationsExistingBusinessResponses],
  )

  const customerBusinessPartnerIdResponse = useTypeCodesAllValues('CUSTOMER_BP_ID')

  const isCustomerBusinessPartnerIdLoading = customerBusinessPartnerIdResponse?.isLoading

  const syndicationBusinessPartnerIdsWithCustomer = uniq(
    compact([
      ...syndicationBusinessPartnerIds,
      customerBusinessPartnerIdResponse?.data?.typeCodes?.[0]?.key,
    ]),
  )

  const {
    data: businessPartnersSyndicationData,
    isLoading: isBusinessPartnersSyndicationLoading,
    isError: isBusinessPartnersSyndicationError,
  } = useBusinessPartnerMiniByIds(syndicationBusinessPartnerIdsWithCustomer, {
    enabled: !isEmpty(syndicationBusinessPartnerIdsWithCustomer),
  })

  // end multipleSyndicationsExistingBusiness hooks

  // useBusinessPartnerUnitOverviewDealComplianceWithRiskStrategy hooks
  const complianceWithRiskStrategyResponses =
    useBusinessPartnerUnitOverviewDealComplianceWithRiskStrategy(dealUuids).map(
      pickNecessaryAttributesFromQueriesResponse,
    )
  const isComplianceWithRiskStrategyDataLoading = complianceWithRiskStrategyResponses.some(
    (response) => response.isLoading,
  )
  // end useBusinessPartnerUnitOverviewDealComplianceWithRiskStrategy hooks

  // useMultipleDealTranches hooks
  const multipleDealTrancheResponses = useMultipleDealTranches(dealUuids).map(
    pickNecessaryAttributesFromQueriesResponse,
  )
  const isMultipleDealTranchesDataLoading = multipleDealTrancheResponses.some(
    (response) => response.isLoading,
  )
  // end useMultipleDealTranches hooks

  // useMultipleDealsPricing hooks
  const multipleDealsPricingResponses = useMultipleDealsPricing(dealUuids).map(
    pickNecessaryAttributesFromQueriesResponse,
  )
  const isMultipleDealsPricingDataLoading = multipleDealsPricingResponses.some(
    (response) => response.isLoading,
  )
  // end useMultipleDealsPricing hooks

  // useMultipleKpis hooks
  const multipleKpisResponses = useMultipleKpis(entityTypes.deal, dealUuids).map(
    pickNecessaryAttributesFromQueriesResponse,
  )
  const isMultipleKpisDataLoading = multipleKpisResponses.some((response) => response.isLoading)
  // end useMultipleKpis hooks

  // useMultipleRiskForbearanceStatuses useMultipleDealCovenants and useMultipleDealCovenantMonitoringItems hooks
  const dealUuidsWithBusinessPartner = useMemo(
    () =>
      businessPartnerUnitOverviewData?.unitDeals
        ? businessPartnerUnitOverviewData.unitDeals?.map((unitDeal) => ({
            dealUuid: unitDeal?.kpiDeal?.dealUuid,
            businessPartnerId: unitDeal?.kpiDeal?.bpId,
          }))
        : [],
    [businessPartnerUnitOverviewData?.unitDeals],
  )

  const multipleForbearanceStatuses = useMultipleRiskForbearanceStatuses(
    dealUuidsWithBusinessPartner,
  ).map(pickNecessaryAttributesFromQueriesResponse)

  const isMultipleForbearanceStatusesDataLoading = multipleForbearanceStatuses.some(
    (response) => response.isLoading,
  )

  const {
    data: multipleCovenantMonitoringItemsData = [],
    isLoading: isMultipleCovenantMonitoringItemsLoading,
    isError: isMultipleCovenantMonitoringItemsError,
  } = useCombinedQueryResults(
    useMultipleCovenantMonitoringItemsByEntityId({
      mainEntityIds: uniqBy(dealUuidsWithBusinessPartner, 'businessPartnerId')?.map(
        ({ businessPartnerId: bpId }) => bpId,
      ),
      mainEntityType: mainEntityTypes.BUSINESSPARTNER,
    }),
    { forceDataReturn: false },
  )

  const multipleCovenantMonitoringItems = useMemo(
    () =>
      multipleCovenantMonitoringItemsData?.map((covenantResponse) => ({
        businessPartnerId: covenantResponse?.BUSINESSPARTNER,
        covenantMonitoringItems:
          covenantResponse?.covenantMonitoringItems?.filter(
            (c) => c?.covenant?.name === COVENANT_DEFINITION_NAME,
          ) || [],
      })),
    [multipleCovenantMonitoringItemsData],
  )

  // used for getting FPO_RESULT custom field enums
  const {
    data: { customFieldMetadata } = {},
    isLoading: isMetadataLoading,
    isError: isMetadataError,
  } = useBusinessPartnerCustomFieldConfig(ENTITY_TYPE)

  // end useMultipleRiskForbearanceStatuses useMultipleDealCovenants and useMultipleDealCovenantMonitoringItems hooks

  // useMultipleBusinessPartnerCustomFields hook
  const multipleBusinessPartnerCustomFieldsResponses = useMultipleBusinessPartnerCustomFields(
    dealUuidsWithBusinessPartner,
  ).map(pickNecessaryAttributesFromQueriesResponse)

  const isMultipleBusinessPartnerCustomFieldsDataLoading =
    multipleBusinessPartnerCustomFieldsResponses.some((response) => response.isLoading)
  // end useMultipleBusinessPartnerCustomFields hook

  // multiplePropertyCustomFieldsByPropertyUuids hook
  const dealUuidsWithPropertyUuids = useMemo(() => {
    if (multiplePropertiesFromDealsResponses) {
      return multiplePropertiesFromDealsResponses?.map(
        ({ data: { dealProperties, dealUuid } }) => ({
          dealUuid,
          propertyUuids: dealProperties?.map((dealProperty) => dealProperty?.propertyUuid),
        }),
      )
    }
    return []
  }, [multiplePropertiesFromDealsResponses])

  const allDealsPropertyUuids = dealUuidsWithPropertyUuids?.flatMap(
    ({ propertyUuids }) => propertyUuids,
  )

  const {
    data: multiCustomFields = [],
    isLoading: isMultiplePropertyCustomFieldsByPropertyUuidsDataLoading,
  } = useMultiplePropertyCustomFieldsByPropertyUuids({ propertyUuids: allDealsPropertyUuids })

  const propertyUuidsRequestParam = allDealsPropertyUuids.map((id) => ({
    id,
  }))

  const { data: multiProperties, isLoading: isMultiPropertiesLoading } =
    useMultiProperties(propertyUuidsRequestParam)

  const combinedPropertiesData = useMemo(() => {
    if (multiProperties?.properties && multiCustomFields) {
      return multiProperties.properties.map((property) => {
        const customFields = multiCustomFields.find(
          (cf) => cf?.data?.propertyUuid === property?.uuid,
        )?.data?.customFields
        return {
          ...property,
          customFields,
        }
      })
    }
    return []
  }, [multiProperties?.properties, multiCustomFields])

  const multiplePropertyCustomFieldsByPropertyUuidsData = useMemo(() => {
    if (combinedPropertiesData) {
      return dealUuidsWithPropertyUuids.map(({ dealUuid, propertyUuids }) => ({
        dealUuid,
        properties: propertyUuids?.map((propertyUuid) =>
          combinedPropertiesData?.find((cpd) => cpd?.uuid === propertyUuid),
        ),
      }))
    }
    return []
  }, [dealUuidsWithPropertyUuids, combinedPropertiesData])
  // end multiplePropertyCustomFieldsByPropertyUuids hook

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isBusinessPartnerUnitOverviewDataLoading && selectedUnit !== null,
      isMultipleAggregatedDealPortfoliosDataLoading,
      isMultipleDealDetailsResponsesLoading,
      isMultiplePropertiesFromDealsDataLoading,
      isMultiPropertyKpisResponsesWithoutDealUuidLoading,
      isMultiplePropertyKpisDataLoading,
      isBusinessPartnersDataLoading && !isEmpty(tenantIds),
      isLendersDataLoading,
      isMultipleSyndicationsExistingBusinessLoading,
      isCustomerBusinessPartnerIdLoading,
      isBusinessPartnersSyndicationLoading && !isEmpty(syndicationBusinessPartnerIdsWithCustomer),
      isComplianceWithRiskStrategyDataLoading,
      isMultipleDealTranchesDataLoading,
      isMultipleDealsPricingDataLoading,
      isMultipleKpisDataLoading,
      isMultipleForbearanceStatusesDataLoading,
      // isMultipleCovenantsByBusinessPartnerLoading,
      isMultipleCovenantMonitoringItemsLoading,
      isMultipleBusinessPartnerCustomFieldsDataLoading,
      isMultiplePropertyCustomFieldsByPropertyUuidsDataLoading,
      isMultiPropertiesLoading,
      isMetadataLoading,
      isDealLoading && entityType === cwpEntityTypes.DEAL,
    ],
    errorValues: [
      isBusinessPartnerUnitOverviewDataError,
      isMultiPropertyKpisResponsesWithoutDealUuidError,
      isBusinessPartnersDataError,
      isBusinessPartnersSyndicationError,
      // isMultipleCovenantsByBusinessPartnerError,
      isMultipleCovenantMonitoringItemsError,
      isMetadataError,
      isDealError && entityType === cwpEntityTypes.DEAL,
    ],
    errorDetails: [
      businessPartnerUnitOverviewDataError,
      multiPropertyKpisResponsesWithoutDealUuidError,
      businessPartnersDataError,
      dealError,
    ],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError || isSomeValueLoading) {
      return {
        isLoading: isSomeValueLoading,
        isError: isSomeValueError,
        error,
      }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        sourceRender: { businessPartnerId },
        unitOverviewData: businessPartnerUnitOverviewData,
        typeCodesConfig,
        countryCodesConfig,
        multipleDealDetailsResponses,
        multipleAggregatedDealPortfolioResponses,
        multiPropertyKpisResponses,
        businessPartnersData,
        lendersResponses,
        multipleSyndicationsExistingBusinessResponses,
        customerBusinessPartnerIdResponse,
        businessPartnersSyndicationData,
        complianceWithRiskStrategyResponses,
        multipleDealTrancheResponses,
        multipleDealsPricingResponses,
        multipleKpisResponses,
        multipleForbearanceStatuses,
        multipleCovenantMonitoringItems,
        multipleBusinessPartnerCustomFieldsResponses,
        multiplePropertyCustomFieldsByPropertyUuidsData,
        businessPartnerCustomFieldConfigMetadata: customFieldMetadata,
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    businessPartnerId,
    businessPartnerUnitOverviewData,
    typeCodesConfig,
    countryCodesConfig,
    multipleDealDetailsResponses,
    multipleAggregatedDealPortfolioResponses,
    multiPropertyKpisResponses,
    businessPartnersData,
    lendersResponses,
    multipleSyndicationsExistingBusinessResponses,
    customerBusinessPartnerIdResponse,
    businessPartnersSyndicationData,
    complianceWithRiskStrategyResponses,
    multipleDealTrancheResponses,
    multipleDealsPricingResponses,
    multipleKpisResponses,
    multipleForbearanceStatuses,
    multipleCovenantMonitoringItems,
    multipleBusinessPartnerCustomFieldsResponses,
    multiplePropertyCustomFieldsByPropertyUuidsData,
    customFieldMetadata,
    error,
  ])
}

export default useAnnualReviewUnitDealOverview
