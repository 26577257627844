import { combineReducers } from '@reduxjs/toolkit'
import conditionCreationReducer from 'redux/slices/conditions/conditionCreationSlice'
import conditionsTableReducer from 'redux/slices/conditions/conditionsTableSlice'
import requirementsTableReducer from 'redux/slices/conditions/requirementsTableSlice'

const conditionsReducer = combineReducers({
  conditionsTable: conditionsTableReducer,
  requirementsTable: requirementsTableReducer,
  conditionsCreation: conditionCreationReducer,
})

export default conditionsReducer
