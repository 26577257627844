import { CardHeader, FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import { useContext } from 'react'
import { covenantPermissions } from 'api/deals/covenantPermissions'
import PeriodicalMonitoringInformationCard from 'components/domains/business-partners/periodical-checks/monitoring-information/PeriodicalMonitoringInformationCard'
import NextPeriodicalCheckCard from 'components/domains/business-partners/periodical-checks/next-periodical-check/NextPeriodicalCheckCard'
import CovenantCommentCard from 'components/domains/deals/covenant-check-detail/comment/CovenantCommentCard'
import MonitoringItemsCard from 'components/domains/deals/covenants/monitoring-items-card/MonitoringItemsCard'
import Card from 'components/ui/card/Card'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import { CovenantContext } from 'routes/business-partners/CovenantContext'
import styles from 'routes/business-partners/periodical-checks/periodical-checks-layout/overviewStyles.module.css'

const useMapOverviewTileCodes = (covenantUuid, covenantDefinition) => {
  const { allowedOperations = [] } = useContext(CovenantContext) ?? {}
  const isAllowedToReadMonitoring = allowedOperations?.includes(
    covenantPermissions.readPeriodicalCheckMonitoring,
  )

  const mapOverviewTileCodeToComponent = (tileCode) =>
    ({
      MONITORING_INFORMATION: (
        <FlexBox direction={FlexBoxDirection.Column} className={styles.gap}>
          <PeriodicalMonitoringInformationCard
            covenantUuid={covenantUuid}
            covenantDefinition={covenantDefinition}
          />
          <NextPeriodicalCheckCard
            covenantUuid={covenantUuid}
            covenantDefinition={covenantDefinition}
          />
        </FlexBox>
      ),
      BUSINESS_PARTNER_INFORMATION: (
        <Card header={<CardHeader titleText={'Business Partner Information'} />} />
      ),
      COMMENT_WITH_HISTORY: (
        <CovenantCommentCard
          linkedEntityId={covenantUuid}
          linkedEntityType={mainEntityTypes.BUSINESSPARTNER}
          isEditEnabled={allowedOperations?.includes(covenantPermissions.updatePeriodicalCheck)}
        />
      ),
      CHECKS_TABLE: isAllowedToReadMonitoring && (
        <MonitoringItemsCard
          covenantId={covenantUuid}
          covenantMainEntityType={cwpEntityTypes.BUSINESS_PARTNER}
          covenantHasDocumentRequirements={covenantDefinition?.withDocumentsRequest}
          covenantCheckName={covenantDefinition?.name}
        />
      ),
    }[tileCode])

  return { mapOverviewTileCodeToComponent }
}

export default useMapOverviewTileCodes
