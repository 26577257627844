import {
  AnalyticalTableScaleWidthMode,
  AnalyticalTableSelectionBehavior,
  AnalyticalTableSelectionMode,
  AnalyticalTableVisibleRowCountMode,
  Button,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { forwardRef, useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import SegmentCreationDialog from 'components/domains/properties/rent-roll/segment/segment-create/SegmentCreationDialog'
import styles from 'components/domains/properties/rent-roll/working-version/rental-units-table/PropertyRentalUnitsWorkingVersionTable.module.css'
import PropertyRentalUnitsWorkingVersionTableEditDialog from 'components/domains/properties/rent-roll/working-version/rental-units-table/PropertyRentalUnitsWorkingVersionTableEditDialog'
import RentalUnitTableSubComponent from 'components/domains/properties/rent-roll/working-version/rental-units-table/PropertyRentalUnitsWorkingVersionTableSubComponent'
import { usePropertyRentalUnitsWorkingVersionAdditionalActions } from 'components/domains/properties/rent-roll/working-version/rental-units-table/usePropertyRentalUnitsWorkingVersionAdditionalActions'
import { usePropertyRentalUnitsWorkingVersionDataMapper } from 'components/domains/properties/rent-roll/working-version/rental-units-table/usePropertyRentalUnitsWorkingVersionDataMapper'
import { rentalUnitWorkingVersionRowKeyPrefix } from 'components/domains/properties/rent-roll/working-version/utils/rentRollWorkingVersionMappers'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import useGetAllRentRollWorkingVersionOptions from 'hooks/services/properties/rent-roll/working-version/useGetAllRentRollWorkingVersionOptions'
import usePropertyRentalUnitsWorkingVersionColumns from 'hooks/services/properties/rent-roll/working-version/usePropertyRentalUnitsWorkingVersionColumns'

const PropertyRentalUnitsWorkingVersionTable = forwardRef(
  (
    {
      segments: segmentsData,
      rentalUnitsValues,
      handleOnChange,
      handleAdd,
      handleMassEdit,
      handleDeleteRows,
      onSegmentCreate,
      isMultiProperty = false,
      isNoRowSelectedState,
    },
    ref,
  ) => {
    const { segments, isLoading: isLoadingSegments } = segmentsData
    const { t } = useTranslation('translation', { keyPrefix: 'pages.property.rent-roll.table' })
    const allOptions = useGetAllRentRollWorkingVersionOptions()

    const tableRef = ref
    const [showSegmentCreationDialog, setShowSegmentCreationDialog] = useState(false)
    const [rowToCreateSegmentFor, setRowToCreateSegmentFor] = useState()
    const handleOnCloseSegmentCreationDialog = () => {
      setRowToCreateSegmentFor()
      setShowSegmentCreationDialog(false)
    }

    const handleBusinessPartnerChange = ({ businessPartner = {}, rowKey, contentKeys = {} }) => {
      const { id, orgName1, name: bpName } = businessPartner
      const { expandContentKey, idContentKey, contentKey } = contentKeys
      const expandedBusinessPartner = id ? businessPartner : undefined
      // after creating a bp: only orgName1 is set; after selecting an existing bp: only name is set
      const name = bpName || orgName1
      handleOnChange(rowKey, expandContentKey, expandedBusinessPartner)
      handleOnChange(rowKey, idContentKey, id || null)
      handleOnChange(rowKey, contentKey, name || null)
    }

    const handleOnSegmentCreate = (segment) => {
      handleOnChange(rowToCreateSegmentFor, 'segment_usage_type_id', segment.usage_type_code)
      handleOnChange(rowToCreateSegmentFor, 'segment_uuid', segment.uuid)
      onSegmentCreate()
    }

    const renderEmptyTableComponent = () => (
      <FlexBox
        direction={FlexBoxDirection.Column}
        alignItems={FlexBoxAlignItems.Center}
        className={styles.emptyContent}
      >
        <span>{t('empty')}</span>
      </FlexBox>
    )

    const columnDefinitions = usePropertyRentalUnitsWorkingVersionColumns({
      segments,
      isMultiProperty,
    })

    const extractRowNumberFromRowKey = (rowKey) =>
      parseInt(rowKey.replace(rentalUnitWorkingVersionRowKeyPrefix, ''))

    const allSelectedRowNumbers = () =>
      tableRef?.current?.selectedFlatRows
        ? tableRef.current.selectedFlatRows.map((selectedFlatRow) =>
            extractRowNumberFromRowKey(selectedFlatRow.original.rowKey),
          )
        : []

    const [isNoRowSelected, setIsNoRowSelected] = isNoRowSelectedState

    const handleDelete = () => {
      handleDeleteRows(allSelectedRowNumbers())
      setIsNoRowSelected(true)
      tableRef.current.toggleAllRowsSelected(false)
    }

    const [editDialogIsOpen, setEditDialogIsOpen] = useState(false)
    const [isCollapseIconDisabled, setIsCollapseIconDisabled] = useState(true)
    const handleOnRowSelect = (arrayLength) => {
      if (arrayLength === 0) {
        setIsNoRowSelected(true)
      } else {
        setIsNoRowSelected(false)
      }
    }
    const handleOnRowExpand = (row) => {
      const tableState = tableRef?.current?.state
      const expandedRowCountBeforeStateUpdate =
        tableState?.expanded && Object.keys(tableState.expanded).length
      if (expandedRowCountBeforeStateUpdate > 1 || !row.isExpanded) {
        setIsCollapseIconDisabled(false)
      } else {
        setIsCollapseIconDisabled(true)
      }
    }

    const getSegmentText = (segment) => {
      const name = segment.name ? segment.name : t('segment-no-name.label')
      const usageType = segment.usage_type_name ? ' [' + segment.usage_type_name + ']' : ''
      return name + usageType
    }

    const getPropertyUuidByRowKey = (rowKey) => {
      const rowNumber = extractRowNumberFromRowKey(rowKey)
      const selectedRentalUnit = rentalUnitsValues?.find(
        (rentalUnit) => rentalUnit.row_number === rowNumber,
      )
      return selectedRentalUnit?.['property_uuid']?.value
    }

    const mapSegmentsToOptions = (segmentsToMap) => [
      { key: null, display_name: '' },
      ...segmentsToMap.map((segment) => ({
        key: segment.uuid,
        display_name: getSegmentText(segment),
      })),
    ]

    const filterAndMapSegmentsToOptions = (segmentsToMap, rowKey) => {
      if (isMultiProperty) {
        const propertyUuid = getPropertyUuidByRowKey(rowKey)
        return mapSegmentsToOptions(
          segmentsToMap.filter((segment) => segment.property_uuid === propertyUuid),
        )
      }
      return mapSegmentsToOptions(segmentsToMap)
    }

    const renderSegmentSelectFooter = (rowKey) => (
      <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        <Button
          data-testid="segment-create-dialog-open"
          design="Transparent"
          onClick={() => {
            setRowToCreateSegmentFor(rowKey)
            setShowSegmentCreationDialog(true)
          }}
        >
          {t('button.add')}
        </Button>
      </div>
    )

    const disableSegmentSelect = (rowKey) => isMultiProperty && !getPropertyUuidByRowKey(rowKey)

    const tableData = usePropertyRentalUnitsWorkingVersionDataMapper({
      rentalUnitsValues,
      segments,
      isMultiProperty,
      handleOnChange,
      handleBusinessPartnerChange,
      allOptions,
      filterAndMapSegmentsToOptions,
      renderSegmentSelectFooter,
      disableSegmentSelect,
    })

    const additionalTableActions = usePropertyRentalUnitsWorkingVersionAdditionalActions({
      tableRef,
      isNoRowSelected,
      handleAdd,
      handleDelete,
      setIsCollapseIconDisabled,
      isCollapseIconDisabled,
      setEditDialogIsOpen,
    })

    const renderRowSubComponent = (row) => (
      <RentalUnitTableSubComponent row={row} isMultiProperty={isMultiProperty} />
    )

    const rentRollWorkingVersionVisibleRows = 7
    // Needs to be in sync with the specified rowHeight in the module.css file!
    const rentRollWorkingVersionRowHeight = 106
    return (
      <>
        <div className={styles.tableContainer}>
          <AnalyticalTableWithToolbar
            className={styles['rent-roll-working-version-table']}
            toolbarClassName={styles.toolbar}
            title={t('toolbar.title')}
            columns={columnDefinitions}
            data={tableData}
            scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
            selectionMode={AnalyticalTableSelectionMode.MultiSelect}
            selectionBehavior={AnalyticalTableSelectionBehavior.RowSelector}
            onRowSelect={(event) => handleOnRowSelect(event.detail.selectedFlatRows.length)}
            onRowExpandChange={(event) => handleOnRowExpand(event.detail.row)}
            subRowsKey="subRows"
            headerRowHeight={40}
            rowHeight={rentRollWorkingVersionRowHeight}
            tableHeight={rentRollWorkingVersionVisibleRows * rentRollWorkingVersionRowHeight}
            minRows={rentRollWorkingVersionVisibleRows}
            // eslint-disable-next-line react-hooks/exhaustive-deps
            renderRowSubComponent={useCallback((row) => renderRowSubComponent(row), [])}
            visibleRowCountMode={AnalyticalTableVisibleRowCountMode.Fixed}
            visibleRows={rentRollWorkingVersionVisibleRows}
            overscanCount={3}
            disableColumnPopover={true}
            NoDataComponent={renderEmptyTableComponent}
            reactTableOptions={{
              autoResetPage: false,
              autoResetExpanded: false,
              autoResetSelectedRows: false,
              autoResetSortBy: false,
              autoResetFilters: false,
              selectSubRows: false,
            }}
            ref={tableRef}
            sortable
            filterable
            isLoadingEnumFilterValues={allOptions.optionsAreLoading || isLoadingSegments}
            additionalActions={additionalTableActions}
          />
        </div>
        {[
          showSegmentCreationDialog &&
            createPortal(
              <SegmentCreationDialog
                key={'segmentCreationDialog'}
                showDialog={showSegmentCreationDialog}
                onCloseDialog={handleOnCloseSegmentCreationDialog}
                onSegmentCreate={handleOnSegmentCreate}
                propertyUuid={
                  isMultiProperty ? getPropertyUuidByRowKey(rowToCreateSegmentFor) : undefined
                }
              />,
              document.body,
            ),
          editDialogIsOpen &&
            createPortal(
              <PropertyRentalUnitsWorkingVersionTableEditDialog
                key={'massEditDialog'}
                isOpen={editDialogIsOpen}
                setEditDialogIsOpen={setEditDialogIsOpen}
                rowsToEdit={tableRef?.current?.selectedFlatRows}
                segments={segments}
                handleMassEdit={handleMassEdit}
                isMultiProperty={isMultiProperty}
              />,
              document.body,
            ),
        ]}
      </>
    )
  },
)

const validationFieldPropTypeString = PropTypes.shape({
  value: PropTypes.string,
  valueState: PropTypes.oneOf(['None', 'Error', 'Warning']),
  valueStateMessage: PropTypes.string,
})

const validationFieldPropTypeNumber = PropTypes.shape({
  value: PropTypes.number,
  valueState: PropTypes.oneOf(['None', 'Error', 'Warning']),
  valueStateMessage: PropTypes.string,
})

const validationFieldPropTypeBoolean = PropTypes.shape({
  value: PropTypes.bool,
  valueState: PropTypes.oneOf(['None', 'Error', 'Warning']),
  valueStateMessage: PropTypes.string,
})

PropertyRentalUnitsWorkingVersionTable.propTypes = {
  segments: PropTypes.shape({
    segments: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  rentalUnitsValues: PropTypes.arrayOf(
    PropTypes.shape({
      row_number: PropTypes.number,
      tenant_name: validationFieldPropTypeString,
      tenant_id: validationFieldPropTypeString,
      anonymous_tenant: validationFieldPropTypeBoolean,
      rental_unit_name: validationFieldPropTypeString,
      rental_unit_id: validationFieldPropTypeString,
      segment_usage_type: validationFieldPropTypeString,
      segment_name: validationFieldPropTypeString,
      rental_unit_area: validationFieldPropTypeNumber,
      rental_unit_uom: validationFieldPropTypeString,
      rent_contracted_year: validationFieldPropTypeNumber,
      rent_currency: validationFieldPropTypeString,
      current_net_rent: validationFieldPropTypeNumber,
      no_lease_expiry: validationFieldPropTypeBoolean,
      comment: validationFieldPropTypeString,
      branding_franchise_partner_name: validationFieldPropTypeString,
      branding_franchise_partner_id: validationFieldPropTypeString,
      number_of_units: validationFieldPropTypeNumber,
      rent_arrears: validationFieldPropTypeString,
      rent_dispute: validationFieldPropTypeBoolean,
      auxiliary_column1: validationFieldPropTypeString,
      auxiliary_column2: validationFieldPropTypeString,
      auxiliary_column3: validationFieldPropTypeString,
      auxiliary_column4: validationFieldPropTypeString,
      rent_start_date: validationFieldPropTypeString,
      lease_expiry_date: validationFieldPropTypeString,
      lease_break_date: validationFieldPropTypeString,
      occupancy_status: validationFieldPropTypeString,
      lease_start_date: validationFieldPropTypeString,
    }),
  ),
  handleOnChange: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleMassEdit: PropTypes.func.isRequired,
  handleDeleteRows: PropTypes.func.isRequired,
  onSegmentCreate: PropTypes.func.isRequired,
  isMultiProperty: PropTypes.bool.isRequired,
  isNoRowSelectedState: PropTypes.array.isRequired,
}

PropertyRentalUnitsWorkingVersionTable.displayName = 'PropertyRentalUnitsWorkingVersionTable'

export default PropertyRentalUnitsWorkingVersionTable
