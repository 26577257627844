import compact from 'lodash.compact'
import get from 'lodash.get'
import isEmpty from 'lodash.isempty'
import uniq from 'lodash.uniq'
import { useMemo, useCallback } from 'react'
import {
  arrearsInApprovalBuildRowsDataRecursive,
  arrearsInApprovalTrimRowsDeep,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/ArrearsUtil'
import {
  ARREARS_IN_APPROVAL_ENTITY_TYPES,
  OVERPAYMENT,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/constants'
import { useRecursivelyExtractApprovalItems } from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/shared/useRecursivelyExtractApprovalItems'
import set from 'utils/set'

const PLACE_HOLDER = '-'

const useArrearsInApprovalTableData = ({
  businessPartnerId,
  arrears,
  productHierarchy,
  arrearApprovals,
  products,
  arrearClassifications,
  allDealsData,
}) => {
  const shouldHaveSubRowsOrIsApprovalItem = (row) =>
    !!row.subRows?.length || !!row.rowData?.approvalItem

  const shouldNotBeOverpayment = (row) =>
    row.entityType !== ARREARS_IN_APPROVAL_ENTITY_TYPES.APPROVALITEM ||
    row.rowData.approvalItem.arrearType.code !== OVERPAYMENT

  const latestApproval = arrearApprovals?.at(0)

  const approvalItems = useMemo(
    () => latestApproval?.arrearApprovalItems ?? [],
    [latestApproval?.arrearApprovalItems],
  )

  const findApprovalItemsByRow = useCallback(
    ({ entityId, secondaryEntityId, entityType }) =>
      approvalItems.filter(
        (approvalItem) =>
          (approvalItem.product.id === entityId || approvalItem.product.id === secondaryEntityId) &&
          approvalItem.product.type.code === entityType,
      ),
    [approvalItems],
  )

  const findArrearTypeByApprovalItem = useCallback(
    ({ arrearExternalId }) =>
      arrears.find((arrear) => arrear.externalId === arrearExternalId)?.type ?? {
        code: PLACE_HOLDER,
        name: PLACE_HOLDER,
      },
    [arrears],
  )

  const extendRowDataWithArrearAndApprovalItem = useCallback(
    (row) => {
      const approvalItemsByRow = findApprovalItemsByRow(row)

      approvalItemsByRow.forEach((approvalItem) => {
        const arrearType = findArrearTypeByApprovalItem(approvalItem)
        const subRow = {
          entityId: approvalItem.id,
          entityType: ARREARS_IN_APPROVAL_ENTITY_TYPES.APPROVALITEM,
          rowData: {
            approvalItem: {
              ...approvalItem,
              arrearType: arrearType,
            },
          },
          sumData: {},
          subRows: [],
        }
        row.subRows.push(subRow)
      })
    },
    [findApprovalItemsByRow, findArrearTypeByApprovalItem],
  )

  const calculateSummedApprovalAmount = useCallback((row) => {
    row.subRows?.forEach((subRow) => calculateSummedApprovalAmount(subRow))

    const approvalAmountDirect = row.rowData?.approvalItem?.approvalAmount
    const foreignApprovalAmountDirect = row.rowData?.approvalItem?.foreignApprovalAmount
    const foreignCurrencyDirect = row.rowData?.approvalItem?.foreignCurrency

    const approvalAmountSumFromSubrows = row.subRows?.reduce((acc, cur) => {
      const currentAmount = cur.sumData?.approvedAmount ?? 0
      return currentAmount + acc
    }, 0)

    const foreignApprovalAmountSumFromSubrows = row.subRows?.reduce((acc, cur) => {
      const currentAmount = cur.sumData?.foreignApprovalAmount ?? 0
      return currentAmount + acc
    }, 0)

    const foreignCurrenciesFromSubRows = row.subRows?.reduce((acc, cur) => {
      const currencies = cur.sumData?.foreignCurrencies
      currencies.forEach((currency) => {
        acc.push(currency)
      })
      return uniq(acc)
    }, [])

    set(row, 'sumData.approvedAmount', approvalAmountDirect ?? approvalAmountSumFromSubrows)
    set(
      row,
      'sumData.foreignApprovalAmount',
      foreignApprovalAmountDirect ?? foreignApprovalAmountSumFromSubrows,
    )
    set(
      row,
      'sumData.foreignCurrencies',
      compact(
        !isEmpty(foreignCurrenciesFromSubRows)
          ? foreignCurrenciesFromSubRows
          : [foreignCurrencyDirect],
      ),
    )
  }, [])

  const calculateValueFromSubRows = useCallback(
    (row, sourceAccessor, targetAccessor, defaultVal) => {
      row.subRows?.forEach((subRow) =>
        calculateValueFromSubRows(subRow, sourceAccessor, targetAccessor, defaultVal),
      )
      const valueDirect = get(row.rowData, sourceAccessor)
      const valueFromFirstSubRow = get(row.subRows?.at(0), targetAccessor) ?? defaultVal
      set(row, targetAccessor, valueDirect ?? valueFromFirstSubRow)
    },
    [],
  )

  const extendRowsWithValue = useCallback(
    (rows, sourceAccessor, targetAccessor, defaultVal) => {
      rows.forEach((row) =>
        calculateValueFromSubRows(row, sourceAccessor, targetAccessor, defaultVal),
      )
    },
    [calculateValueFromSubRows],
  )

  const extendRowsWithApprovalSumAmount = useCallback(
    (rows) => rows.forEach((row) => calculateSummedApprovalAmount(row)),
    [calculateSummedApprovalAmount],
  )

  const getClassificationNameByCode = useCallback(
    (classificationCode) => {
      if (Array.isArray(arrearClassifications?.arrearClassifications)) {
        return arrearClassifications.arrearClassifications.find(
          (classification) => classification.code === classificationCode,
        )?.name
      }
      return null
    },
    [arrearClassifications?.arrearClassifications],
  )

  const extendApprovalItemsWithClassificationTranslation = useCallback(
    (row) => {
      row.subRows?.forEach((subRow) => extendApprovalItemsWithClassificationTranslation(subRow))
      const arrearClassificationCode = row.rowData?.classificationCode

      const classificationTranslated = getClassificationNameByCode(arrearClassificationCode)
      if (arrearClassificationCode) {
        set(row, 'rowData.classificationName', classificationTranslated ?? arrearClassificationCode)
      }
    },
    [getClassificationNameByCode],
  )

  const extendRowsWithClassificationTranslation = useCallback(
    (rows) => rows.forEach((row) => extendApprovalItemsWithClassificationTranslation(row)),
    [extendApprovalItemsWithClassificationTranslation],
  )

  const buildRowsData = useCallback(() => {
    const rowsData = arrearsInApprovalBuildRowsDataRecursive({
      businessPartnerId,
      products,
      productHierarchy,
      allDealsData,
      extendRowsWithAdditionalData: extendRowDataWithArrearAndApprovalItem,
    })
    extendRowsWithValue(rowsData, 'approvalItem.approvedUntil', 'rowData.approvedUntil', '')
    extendRowsWithValue(rowsData, 'approvalItem.comment', 'rowData.comment', '')
    extendRowsWithValue(
      rowsData,
      'approvalItem.classification.code',
      'rowData.classificationCode',
      '',
    )
    extendRowsWithClassificationTranslation(rowsData)
    extendRowsWithApprovalSumAmount(rowsData)
    const trimmedRowsWithOverpayments = arrearsInApprovalTrimRowsDeep(
      rowsData,
      shouldHaveSubRowsOrIsApprovalItem,
    )
    const trimmedRowsWithoutOverpayments = arrearsInApprovalTrimRowsDeep(
      trimmedRowsWithOverpayments,
      shouldNotBeOverpayment,
    )
    return trimmedRowsWithoutOverpayments
  }, [
    allDealsData,
    businessPartnerId,
    extendRowDataWithArrearAndApprovalItem,
    extendRowsWithApprovalSumAmount,
    extendRowsWithClassificationTranslation,
    extendRowsWithValue,
    productHierarchy,
    products,
  ])

  const data = useMemo(() => buildRowsData() ?? [], [buildRowsData])
  const datacount = useRecursivelyExtractApprovalItems(data).length

  return useMemo(
    () => ({
      data,
      datacount,
    }),
    [data, datacount],
  )
}

export default useArrearsInApprovalTableData
