import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import { Input } from '@fioneer/ui5-webcomponents-react'
import throttle from 'lodash.throttle'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import EventSearchSuggestions from 'components/domains/business-events-and-tasks/events/input/EventSearchSuggestions'
import { DEFAULT_EVENT_INPUT_THROTTLE_TIMEOUT } from 'components/domains/business-events-and-tasks/events/input/constants'

const propTypes = {
  onSuggestionSelected: PropTypes.func.isRequired,
  preSelectedValue: PropTypes.string,
  throttleTimeout: PropTypes.number,
  /** These values pre-apply filters to every event request and cannot be disabled by the user */
  preFilterValues: PropTypes.shape({
    searchValue: PropTypes.string,
    assigneeId: PropTypes.string,
    currentDueDateRange: PropTypes.string,
    originalDueDateRange: PropTypes.string,
    status: PropTypes.arrayOf(PropTypes.string),
    entityType: PropTypes.string,
    entityId: PropTypes.string,
  }),
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const EventAutocompleteInput = ({
  preFilterValues,
  onSuggestionSelected,
  preSelectedValue = '',
  throttleTimeout = DEFAULT_EVENT_INPUT_THROTTLE_TIMEOUT,
  ...inputProps
}) => {
  const [currentSearchValue, setCurrentSearchValue] = useState(preSelectedValue)
  const setCurrentSearchValueThrottled = useMemo(
    () => throttle(setCurrentSearchValue, throttleTimeout, { trailing: true }),
    [throttleTimeout],
  )

  const onSuggestionItemSelect = ({ detail: { item: selectedSuggestion } }) => {
    const { id, displayId, name } = selectedSuggestion.dataset
    const displaySearchValue = selectedSuggestion.text
    setCurrentSearchValue(displaySearchValue)
    onSuggestionSelected({ id, displayId, name })
  }

  const onSearchValueChanged = ({ target: { value: search } }) => {
    setCurrentSearchValueThrottled(search)
    if (!search) {
      onSuggestionSelected({})
    }
  }

  return (
    <Input
      noTypeahead
      showSuggestions
      showClearIcon={!!preSelectedValue}
      disabled={!!preSelectedValue}
      onInput={onSearchValueChanged}
      value={currentSearchValue}
      onSuggestionItemSelect={onSuggestionItemSelect}
      {...inputProps}
    >
      <EventSearchSuggestions
        preFilterValues={preFilterValues}
        currentSearchValue={currentSearchValue}
      />
    </Input>
  )
}

EventAutocompleteInput.propTypes = propTypes

export default EventAutocompleteInput
