import { useSearchParams } from 'react-router-dom'
import { queryParameterNames } from 'routes/deals/DealRoutes'

export const useGetAndAppendWorkingVersionSearchParam = () => {
  const [searchParams] = useSearchParams()
  const workingVersionSelected = !!searchParams.get(queryParameterNames.workingVersion)
  const replaceMultipleSlashes = (path) => path.replace(/\/+/g, '/')
  const constructPath = (pathBase) =>
    replaceMultipleSlashes(
      `${pathBase}?${queryParameterNames.workingVersion}=${workingVersionSelected}`,
    )

  return { appendWorkingVersionSearchParam: (path) => constructPath(path) }
}
