import { useRequest } from 'hooks/services/baseService'
import { useFrontendPagination } from 'hooks/services/deals/useFrontendPagination'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useCashflowScenarios = (dealUuid) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/cashflow-scenarios`,
      translated: true,
      useCache: true,
      keys: ['deals', dealUuid, 'cashflow-scenarios'],
    }),
  )

/***
 * Retrieves Cashflows and performs frontend pagination
 * @param dealUuid UUID (dealUuid) of deal for which to retrieve cashflows
 * @param sort { orderField,  orderDirection } with orderField=<cashflowField to sort> and orderDirection="asc"/desc"
 * @param offset index of first cashflow to be shown in paginated result
 * @returns {{data: {pagination: {total: (number|number), offset: (*|number), limit: (number|number)}, cashflowScenarios: (*|[])}, isMaximumResultSizeExceeded: boolean}}
 */
const useCashflowScenariosWithFrontendPagination = ({
  dealUuid: dealUuid,
  sort = {},
  pagination = {},
}) => {
  const result = useCashflowScenarios(dealUuid)

  return useFrontendPagination({
    fetchResult: result,
    dataKey: 'cashflowScenarios',
    sort,
    pagination,
  })
}

export default useCashflowScenariosWithFrontendPagination
