import { MessageStrip, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import { isUndefined } from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/appendix/manual-checksheet/AnnualReviewManualCheckSheetResultMessageStrip.module.css'
import { annualReviewManualQuestionnaireSchema } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/appendix/manual-checksheet/annualReviewManualQuestionnaireSchema'

const AnnualReviewManualCheckSheetResultMessageStrip = ({
  id,
  selectedBorrowerId,
  currentData,
  isEditMode,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.appendix.checksheet.message-strip',
  })

  const currentBorrowerData =
    currentData?.find((borrower) => borrower.borrowerId === selectedBorrowerId) ?? {}
  const { success: isCompletedQuestionnaire } = annualReviewManualQuestionnaireSchema.safeParse(
    currentBorrowerData.answers || {},
  )

  return (
    selectedBorrowerId &&
    !isEditMode &&
    !isUndefined(currentBorrowerData) && (
      <div className={styles.messageStripWrapper}>
        <MessageStrip
          id={id}
          design={
            isCompletedQuestionnaire ? MessageStripDesign.Positive : MessageStripDesign.Warning
          }
          hideCloseButton
        >
          <span>{isCompletedQuestionnaire ? t('complete') : t('not-complete')}</span>
        </MessageStrip>
      </div>
    )
  )
}

AnnualReviewManualCheckSheetResultMessageStrip.propTypes = {
  id: PropTypes.string.isRequired,
  selectedBorrowerId: PropTypes.string,
  currentData: PropTypes.arrayOf(
    PropTypes.shape({
      borrowerId: PropTypes.string.isRequired,
      borrowerName: PropTypes.string,
      answers: PropTypes.shape({}),
    }),
  ),
  isEditMode: PropTypes.bool,
}

export default AnnualReviewManualCheckSheetResultMessageStrip
