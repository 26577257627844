import {
  FlexBoxAlignItems,
  Icon,
  ObjectStatus,
  TableRowType,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import {
  DealSearchTableColumn,
  DealSearchTableMode,
} from 'components/domains/deals/deal-search/DealSearchTable'
import styles from 'components/domains/deals/deal-search/DealSearchTable.module.css'
import { DisplayValueOfCodeText } from 'components/domains/deals/deal-search/DisplayValueOfCodeText'
import { FormattedDateText } from 'components/domains/deals/deal-search/FormattedDateText'
import EntityCell from 'components/ui/tables/cells/EntityCell'
import {
  useBusinessSegments,
  useDealTypes,
} from 'hooks/services/deals/configurations/useBusinessSegments'
import { useOriginationTeams } from 'hooks/services/deals/configurations/useOriginationTeams'
import paths from 'routes/paths'

export const useDealSearchTableData = ({
  selectedDealUuids,
  lockedSelectedDeals = [],
  staffMembers,
  isAllowedToReadDeal,
  mode,
  onSelectionChanged,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals' })

  const isDealLocked = useCallback(
    (dealUuid) =>
      lockedSelectedDeals?.some(({ dealUuid: lockedDealUuid } = {}) => lockedDealUuid === dealUuid),
    [lockedSelectedDeals],
  )

  return (deals) =>
    deals.map(
      ({
        dealUuid,
        dealId,
        name,
        businessSegmentCode,
        dealTypeCode,
        originationTeamCode,
        created,
        userCreated,
        status,
        workingVersion,
      } = {}) => {
        const dealName =
          workingVersion === WorkingVersionType.WORKING_VERSION
            ? t('table.working-version.name', { dealName: name })
            : name
        return {
          rowKey: `deal-table-row-${dealUuid}`,
          rowProperties: {
            onClick: ({ ctrlKey, metaKey } = {}) => {
              if (!isAllowedToReadDeal) return
              if (mode !== DealSearchTableMode.NavigateOnClick) {
                mode === DealSearchTableMode.MultiSelect &&
                  onSelectionChanged(
                    {
                      detail: {
                        selectedRows: [
                          { dataset: { dealId, dealUuid, dealName, dealStatus: status } },
                        ],
                      },
                    },
                    true,
                  )
                return
              }

              const shouldOpenInNewTab = !!ctrlKey || !!metaKey
              if (shouldOpenInNewTab) window.open(`/${paths.deals}/${dealId}`, '_blank')
              else navigate(`${dealId}`)
              // Note for SingleSelect/MultiSelect:
              // Selected rows are passed directly from/to
              // SortedTable->DealSearchDialog via onSelectionChanged callback
            },
            selected: selectedDealUuids.includes(dealUuid),
            className: isDealLocked(dealUuid) ? styles.disabledRow : '',
            type:
              isAllowedToReadDeal && mode !== DealSearchTableMode.MultiSelect
                ? TableRowType.Active
                : TableRowType.Inactive,
            'data-deal-id': dealId,
            'data-deal-uuid': dealUuid,
            'data-deal-name': dealName,
            'data-deal-status': status,
          },
          deal: {
            value: dealId,
            cellComponent: (
              <EntityCell
                name={dealName}
                id={dealId}
                link={mode !== DealSearchTableMode.NavigateOnClick && `/deals/${dealId}`}
                options={{ isNameBold: true, openInNewTab: true }}
              />
            ),
          },
          businessSegment: {
            value: businessSegmentCode,
            cellComponent: (
              <DisplayValueOfCodeText
                code={businessSegmentCode}
                loadingHook={useBusinessSegments}
              />
            ),
          },
          dealType: {
            value: dealTypeCode,
            cellComponent: (
              <DisplayValueOfCodeText code={dealTypeCode} loadingHook={useDealTypes} />
            ),
          },
          originationPlatform: {
            value: originationTeamCode,
            cellComponent: (
              <DisplayValueOfCodeText
                code={originationTeamCode}
                loadingHook={useOriginationTeams}
                valueKey="platform"
              />
            ),
          },
          originationTeam: {
            value: originationTeamCode,
            cellComponent: (
              <DisplayValueOfCodeText
                code={originationTeamCode}
                loadingHook={useOriginationTeams}
              />
            ),
          },
          status: {
            value: status,
            cellComponent: (
              <ObjectStatus inverted state={status ? ValueState.Information : ValueState.None}>
                {status}
              </ObjectStatus>
            ),
          },
          createdAt: {
            value: created,
            cellComponent: <FormattedDateText dateString={created} showTime />,
          },
          createdBy: {
            value: userCreated,
            cellComponent: <Text>{staffMembers[userCreated]?.fullName ?? userCreated}</Text>,
          },
          arrow: {
            cellComponent: isAllowedToReadDeal && <Icon name="slim-arrow-right" />,
          },
        }
      },
    )
}

export const useDealSearchColumnDefinitions = ({ mode, columnsRestrictedTo = null } = {}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals' })

  const columnDefinitions = [
    {
      title: t('table.deal'),
      columnKey: 'deal',
      isSelectableForHiding: false,
    },
    {
      title: t('table.business-segment'),
      columnKey: DealSearchTableColumn.BUSINESSSEGMENT,
      sortingDisabled: true,
    },
    {
      title: t('table.deal-type'),
      columnKey: DealSearchTableColumn.DEALTYPE,
      sortingDisabled: true,
    },
    {
      title: t('table.origination-platform'),
      columnKey: DealSearchTableColumn.ORIGINATIONPLATFORM,
      sortingDisabled: true,
    },
    {
      title: t('table.origination-team'),
      columnKey: DealSearchTableColumn.ORIGINATIONTEAM,
      sortingDisabled: true,
    },
    {
      title: t('table.status'),
      columnKey: DealSearchTableColumn.STATUS,
    },
    {
      title: t('table.created-at'),
      columnKey: DealSearchTableColumn.CREATEDAT,
      isVisible: false,
    },
    {
      title: t('table.created-by'),
      columnKey: DealSearchTableColumn.CREATEDBY,
      isVisible: false,
      sortingDisabled: true,
    },
  ]
  if (mode === DealSearchTableMode.NavigateOnClick)
    columnDefinitions.push({
      title: '',
      columnKey: 'arrow',
      sortingDisabled: true,
      alignment: FlexBoxAlignItems.End,
      isSelectableForHiding: false,
    })
  if (columnsRestrictedTo !== null) {
    return columnDefinitions.filter((column) => columnsRestrictedTo.includes(column.columnKey))
  }
  return columnDefinitions
}
