import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import PortfolioFinancedAssetsImagesSection from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-information/PortfolioFinancedAssetsImagesSection'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-information/shared/NoPropertyAssignedToDealInfo'

const noop = () => {}

const PortfolioFinancedAssetsViewTile = ({ tileId, isPdfView }) => {
  const tileState = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  if (tileState?.data.noProperties) {
    return <NoPropertyAssignedToDealInfo />
  }
  return (
    <PortfolioFinancedAssetsImagesSection
      tileId={tileId}
      isPdfView={isPdfView}
      setSelectedImageIndex={noop}
    />
  )
}
PortfolioFinancedAssetsViewTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
}

export default PortfolioFinancedAssetsViewTile
