import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import LinkWithId from 'components/domains/business-events-and-tasks/LinkWithId'
import DecisionProcessLabeledLoadingContent from 'components/domains/business-events-and-tasks/decision-process/alc/helper/DecisionProcessLabeledLoadingContent'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import { UNIT_TYPE_ID_STANDALONE } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/gcc-involved-parties/constants'
import useAuthorityLevelCalculatorAuthorityLevel from 'hooks/services/business-partners/authority-level-calculator/useAuthorityLevelCalculatorAuthorityLevel'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import paths from 'routes/paths'

const DecisionProcessAlcReviewUnit = ({ eventId, borrowerBpId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.overview.alc-info.dialog',
  })

  const bpResponse = useBusinessPartnerById(borrowerBpId)
  const alcResponse = useAuthorityLevelCalculatorAuthorityLevel({ eventId })

  const renderReviewUnit = useCallback((authorityLevel) => {
    const { unit: { unitHeadBpId, unitHeadBpFullName } = {} } = authorityLevel ?? {}

    const link = `/${paths.businessPartners}/${unitHeadBpId}`
    return <LinkWithId href={link} name={unitHeadBpFullName} id={unitHeadBpId} />
  }, [])

  const renderBorrower = useCallback((borrower) => {
    const link = `/${paths.businessPartners}/${borrower.id}`
    return <LinkWithId href={link} name={borrower.fullName} id={borrower.id} />
  }, [])

  const renderContent = useCallback(
    () =>
      alcResponse?.data?.unit?.unitTypeId === UNIT_TYPE_ID_STANDALONE ? (
        <DecisionProcessLabeledLoadingContent
          response={bpResponse}
          renderCallback={renderBorrower}
          label={t('fields.standalone-bp')}
        />
      ) : (
        <DecisionProcessLabeledLoadingContent
          response={alcResponse}
          renderCallback={renderReviewUnit}
          label={t('fields.review-unit')}
        />
      ),
    [alcResponse, bpResponse, renderBorrower, renderReviewUnit, t],
  )

  return (
    <SmallLoadingWrapper
      isLoading={alcResponse.isLoading}
      isError={false}
      error={''}
      renderContent={renderContent}
    />
  )
}

DecisionProcessAlcReviewUnit.propTypes = {
  eventId: PropTypes.string.isRequired,
  borrowerBpId: PropTypes.string.isRequired,
}

export default DecisionProcessAlcReviewUnit
