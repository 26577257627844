import camelize from 'camelize'
import _ from 'lodash'
import { useRequest } from 'hooks/services/baseService'

/**
 * Get staff members matching the provided name
 *
 * @param limit (optional) max number of suggestions
 * @param minLength (default: 0) min number of characters to trigger search
 */
const useStaffMembers = ({ name = '', limit, minLength = 0 } = {}) => {
  const hasMinLength = name.length >= minLength

  const params = new URLSearchParams()
  params.append('q', name)
  if (!_.isNil(limit)) params.append('limit', limit)

  const result = useRequest({
    path: `/users?${params.toString()}`,
    options: { enabled: hasMinLength },
  })
  return { ...result, data: camelize(result.data) }
}

export default useStaffMembers
