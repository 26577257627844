import { DynamicPage, ObjectStatus, ValueState } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import HeaderWithContent from 'components/domains/deals/HeaderWithContent'
import DealMessagesHeader from 'components/domains/deals/deal-adjustment/DealMessagesHeader'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import NeedToKnowWrapper from 'components/ui/need-to-know/NeedToKnowWrapper'
import Page from 'components/ui/page/Page'
import TabPage from 'components/ui/page/TabPage'
import useDealNeedToKnow from 'hooks/services/need-to-know/useDealNeedToKnow'
import { DealContext } from 'routes/deals/DealContext'
import styles from 'routes/deals/DealDetailsWrapper.module.css'
import paths from 'routes/paths'

const pageAdditionalProperties = {
  renderHeaderContent: PropTypes.func,
  renderFooter: PropTypes.func,
  headerContentPinnable: PropTypes.bool,
  showHideHeaderButton: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
}

const tabPageAdditionalProperties = {
  headerContent: PropTypes.elementType,
  alwaysShowContentHeader: PropTypes.bool,
  defaultSectionId: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isStickyTabBar: PropTypes.bool,
  isStickyHeader: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  includeUrlQuery: PropTypes.bool,
}

const childBreadCrumbPropType = PropTypes.shape({
  text: PropTypes.node,
  href: PropTypes.string,
})

const dealDetailsWrapperPropTypes = {
  childBreadcrumbs: PropTypes.oneOfType([
    childBreadCrumbPropType,
    PropTypes.arrayOf(childBreadCrumbPropType),
  ]),
  isTabPage: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  additionalProperties: PropTypes.shape({
    ...pageAdditionalProperties,
    ...tabPageAdditionalProperties,
  }),
  actions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  showWVLink: PropTypes.bool,
}

const DealDetailsWrapper = ({
  childBreadcrumbs,
  isTabPage = false,
  children,
  additionalProperties,
  actions,
  showWVLink = true,
}) => {
  const { deal } = useContext(DealContext)
  const { t } = useTranslation()
  const { data: need2knowData, isLoading, isError } = useDealNeedToKnow(deal.dealUuid)

  const isWorkingVersion = useMemo(
    () => WorkingVersionType.WORKING_VERSION === deal.workingVersion,
    [deal],
  )

  const workingVersionSuffix = useMemo(
    () => (isWorkingVersion ? '?working-version=true' : ''),
    [isWorkingVersion],
  )

  const defaultBreadcrumbs = useMemo(
    () => [
      { text: t('pages.deals.breadcrumbs.deals'), href: `/${paths.deals}` },
      {
        text: deal.name,
        href: `/${paths.deals}/${deal.displayId}${workingVersionSuffix}`,
      },
    ],
    [deal, t, workingVersionSuffix],
  )

  const breadcrumbs = useMemo(
    () => (childBreadcrumbs ? defaultBreadcrumbs.concat(childBreadcrumbs) : defaultBreadcrumbs),
    [childBreadcrumbs, defaultBreadcrumbs],
  )

  const subtitle = useMemo(() => <EntityTypeAndIdWithClipboard id={deal.displayId} />, [deal])

  const headerTitle = useMemo(
    () => (
      <HeaderWithContent
        breadcrumbs={breadcrumbs}
        title={deal.name}
        id={deal.displayId}
        actions={actions}
        subHeader={subtitle}
      >
        {deal.status && (
          <ObjectStatus inverted state={ValueState.Information}>
            {deal.status}
          </ObjectStatus>
        )}
        {deal.onHold && (
          <ObjectStatus inverted state={ValueState.Error} className={styles.marginLeft}>
            {t('pages.deals.detail.overview.on-hold')}
          </ObjectStatus>
        )}
      </HeaderWithContent>
    ),
    [deal, subtitle, actions, breadcrumbs, t],
  )

  if (isTabPage) {
    if (isLoading || isError || !need2knowData?.allowed) {
      return (
        <DynamicPage headerTitle={headerTitle} showHideHeaderButton={false}>
          <NeedToKnowWrapper isLoading={isLoading} isError={isError || !need2knowData?.allowed} />
        </DynamicPage>
      )
    }
    return (
      <TabPage
        headerTitle={headerTitle}
        headerContent={<DealMessagesHeader showWVLink={showWVLink} />}
        {...additionalProperties}
      >
        {children}
      </TabPage>
    )
  }

  const wrappedChildren = (
    <NeedToKnowWrapper isLoading={isLoading} isError={isError || !need2knowData?.allowed}>
      <DealMessagesHeader containerClass={styles.noPadding} showWVLink={showWVLink} />
      {children}
    </NeedToKnowWrapper>
  )
  return (
    <Page
      isError={false}
      isLoading={false}
      renderContent={() => wrappedChildren}
      renderHeaderTitle={() => headerTitle}
      {...additionalProperties}
    />
  )
}

DealDetailsWrapper.propTypes = dealDetailsWrapperPropTypes

export default DealDetailsWrapper
