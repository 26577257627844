import PropTypes from 'prop-types'
import { usePercentageFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/cashflow-scenario/shared/i18n/useI18n'

const PercentageCell = ({ cell: { value } }) => {
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  return (
    <>{typeof value?.percentage === 'number' ? formatPercentage(value?.percentage / 100) : ''}</>
  )
}

PercentageCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.shape({
      percentage: PropTypes.number,
    }),
  }).isRequired,
}

export default PercentageCell
