import {
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
  Title,
  TitleLevel,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import styles from 'components/ui/illustrated-message/CustomizedIllustratedMessage.module.css'

// Can be moved to props once there is need for other versions
const illustrationWidth = '170px'
const illustrationHeight = '128px'
const titleSize = TitleLevel.H4
const subtitleSize = TitleLevel.H6

/**
 *
 * IllustrationMessage, where both the title sizes as well as the size of the illustration are modified
 *
 * @param {String} titleText
 * @param {String} subtitleText
 * @param {IllustrationMessageType} name
 * @param {IllustrationMessageSize} size
 */
const CustomizedIllustratedMessage = ({ titleText, subtitleText, name, size }) => {
  /**
   * Callback ref: Adds the custom style rules to the host element
   */
  const updateIllustratedMessageStyles = useCallback((illustratedMessageRef) => {
    const container = illustratedMessageRef?.shadowRoot
    if (!container) {
      return
    }
    const styleElement = document.createElement('style')
    container.appendChild(styleElement)
    const sheet = styleElement.sheet
    if (!sheet) {
      return
    }
    const widthSetting = `width: ${illustrationWidth};`
    const heigthSetting = `height: ${illustrationHeight};`
    sheet.insertRule(`svg {${widthSetting}${heigthSetting}}`)
    sheet.insertRule(`.ui5-illustrated-message-illustration {margin-bottom: 8px !important;}`)
  }, [])

  return (
    <IllustratedMessage ref={updateIllustratedMessageStyles} name={name} size={size}>
      <Title
        level={titleSize}
        slot="title"
        wrappingType={WrappingType.Normal}
        className={styles.title}
      >
        {titleText}
      </Title>
      <Title
        level={subtitleSize}
        slot="subtitle"
        wrappingType={WrappingType.Normal}
        className={styles.subtitle}
      >
        {subtitleText}
      </Title>
    </IllustratedMessage>
  )
}

CustomizedIllustratedMessage.propTypes = {
  titleText: PropTypes.string.isRequired,
  subtitleText: PropTypes.string.isRequired,
  name: PropTypes.oneOf(Object.values(IllustrationMessageType)).isRequired,
  size: PropTypes.oneOf(Object.values(IllustrationMessageSize)).isRequired,
}

export default CustomizedIllustratedMessage
