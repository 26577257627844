import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useArrearComments = ({ businessPartnerId }) =>
  useCamelizedResponse(
    useRequest({
      path: `/arrears/comments/${businessPartnerId}`,
      keys: ['arrears', 'comments', businessPartnerId],
    }),
  )

export default useArrearComments
