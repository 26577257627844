import {
  Button,
  ButtonDesign,
  Link,
  LinkDesign,
  MessageBoxActions,
  MessageBoxTypes,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useShowMessageBox } from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-information/shared/ui/MessageBox'

const space = <>&nbsp;</>

const LinkWithIdAndConfirmation = ({
  name,
  link,
  id,
  confirmMessageText,
  confirmMessageTitle,
  confirmButtonText,
  showConfirmationForLinks,
}) => {
  const showMessageBox = useShowMessageBox()

  const onConfirm = useCallback(() => {
    window.open(link, '_blank')
  }, [link])

  const onLinkClick = useCallback(() => {
    if (!showConfirmationForLinks) {
      window.open(link, '_blank')
      return
    }
    showMessageBox(
      {
        type: MessageBoxTypes.Confirm,
        titleText: confirmMessageTitle,
        children: confirmMessageText,
        actions: [
          <Button key="button-confirm" design={ButtonDesign.Emphasized} onClick={onConfirm}>
            {confirmButtonText}
          </Button>,
          MessageBoxActions.Cancel,
        ],
      },
      document.body,
    )
  }, [
    showMessageBox,
    onConfirm,
    confirmButtonText,
    confirmMessageText,
    confirmMessageTitle,
    showConfirmationForLinks,
    link,
  ])
  return (
    <>
      <Link
        style={{ alignItems: 'center' }}
        design={LinkDesign.Emphasized}
        onClick={onLinkClick}
        wrappingType={WrappingType.Normal}
      >
        {name}
      </Link>
      {id && (
        <>
          {space}
          {`(${id})`}
        </>
      )}
    </>
  )
}

LinkWithIdAndConfirmation.propTypes = {
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  confirmMessageText: PropTypes.string.isRequired,
  confirmMessageTitle: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
}

export default LinkWithIdAndConfirmation
