import mapSummaryData from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche/utils/mapSummaryData'
import mapTrancheData from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche/utils/mapTrancheData'

const mapTranchesData = (t, backendData) => {
  let tranches = [...backendData.tranches.map((tranche) => mapTrancheData(t, tranche))]
  if ((backendData.summary || backendData.summaryHeadquarter) && backendData.tranches.length > 1)
    tranches = [
      ...tranches,
      mapSummaryData({
        summary: backendData.summary,
        summaryHeadquarter: backendData.summaryHeadquarter,
      }),
    ]
  return tranches
}

export default mapTranchesData
