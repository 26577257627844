import uniq from 'lodash.uniq'
import { DateTime } from 'luxon'

const useYearlyTickConfig = (data = []) => {
  const firstDate = data.at(0)?.keyDate
  const lastDate = data.at(-1)?.keyDate

  let yearTicks = []
  if (data.length > 1) {
    const firstItemDate = DateTime.fromJSDate(new Date(firstDate))
    const lastItemDate = DateTime.fromJSDate(new Date(lastDate))
    const yearsToShow = []

    for (let year = firstItemDate.year + 1; year <= lastItemDate.year; year++) {
      yearsToShow.push(year)
    }

    yearTicks = yearsToShow.map((year) => DateTime.fromObject({ year }).toMillis())
  }

  const ticks = []
  if (data.length === 1) {
    ticks.push(firstDate)
  } else if (data.length > 1) {
    ticks.push(firstDate, ...yearTicks, lastDate)
  }

  return { ticks: uniq(ticks) }
}

export default useYearlyTickConfig
