import { useMutation } from '@tanstack/react-query'
import { useAuth } from 'oidc-react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import useRentRollWorkingVersionDateFormatter from 'hooks/services/properties/useRentRollWorkingVersionDateFormatter'

export const useCreateRentRollWorkingVersion = (options) => {
  const { post } = useAccessTokenRequest()
  const { userData } = useAuth()

  const dateFormatter = useRentRollWorkingVersionDateFormatter()

  return useMutation(async ({ property_uuids, header, rental_units }) => {
    const formattedHeader = dateFormatter.formatDateInHeader(header)
    const formattedRentalUnits = dateFormatter.formatDateInRentalUnits(rental_units)

    const { data } = await post({
      path: `/properties/rent-roll/working-version/create`,
      body: {
        property_uuids,
        rent_roll_working_version: {
          property_uuids,
          header: {
            ...formattedHeader,
            creator: userData.profile.name,
          },
          rental_units: formattedRentalUnits,
        },
      },
    })
    return data
  }, options)
}
