import { useQuery, useQueries, useInfiniteQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { evaluateRetry } from 'hooks/services/retryHelper'

/**
 * @param {object} params
 * @param {string} params.path the path of the request
 * @param {boolean} [params.translated = false] if the request is translated (defaults to false)
 * @param {boolean} [params.useCache = true] defaults to true
 * @param {string[]} [params.keys]
 * @param {string} [params.language]
 * @param {URLSearchParams} [params.queryParams]
 */
const calculateCacheKeys = ({ keys, translated, path, language, queryParams }) => {
  /** @type {string[]} */
  const cacheKeys = []
  if (keys) {
    cacheKeys.push(...keys)
  } else {
    cacheKeys.push(path)
  }

  if (translated) {
    cacheKeys.push(language ?? '')
  }
  if (queryParams) {
    cacheKeys.push(queryParams.toString())
  }
  return cacheKeys
}

/**
 * Makes a GET request to the given path and caches the request by the given
 * path part via `useQuery`. The current language is added to the cache key to
 * ensure that translated requests are refreshed on language change based on
 * the translated parameter.
 * @param {object} params
 * @param {string} params.path the path of the request
 * @param {boolean} [params.translated = false] if the request is translated (defaults to false)
 * @param {boolean} [params.useCache = true] defaults to true
 * @param {string[]} params.keys  cache keys passed to the cacheKey param of useQuery, adds only path as key if empty
 * @param {Omit<import('@tanstack/react-query').UseQueryOptions, "queryKey" | "queryFn">} [params.options] properties used in useQuery
 * @param {Omit<Parameters<ReturnType<typeof useAccessTokenRequest>["get"]>, "path">} [params.requestOptions] GET request options
 */
export const useRequest = ({
  path,
  translated = false,
  useCache = true,
  keys,
  options,
  requestOptions,
}) => {
  const { get } = useAccessTokenRequest()
  const { i18n } = useTranslation()

  const queryOptions = {
    refetchOnWindowFocus: false,
    retry: evaluateRetry,
    ...options,
  }
  if (!useCache) {
    queryOptions.staleTime = 1
  }
  const cacheKeys = calculateCacheKeys({
    keys,
    translated,
    path,
    language: translated ? i18n.language : undefined,
  })

  return useQuery({
    queryKey: cacheKeys,
    queryFn: async () => {
      const { data } = await get({ path, ...requestOptions })
      return data
    },
    ...queryOptions,
  })
}

/**
 * Makes a series of GET requests to the given paths and caches the request
 * by the given path part via `useQueries`. The current language is added to the cache key to
 * ensure that translated requests are refreshed on language change based on
 * the translated parameter.
 * @param {object} params
 * @param {object[]} params.requests array of objects with properties path, keys, and requestOptions for each request
 * @param {string} params.requests.path the path of each request
 * @param {any[]} params.requests.keys cache keys passed to the cacheKey param of each request object passed to useQueries,
 * adds only path as key if empty
 * @param {Omit<Parameters<ReturnType<typeof useAccessTokenRequest>["get"]>, "path">} [params.requests.requestOptions]
 * GET request options for each request object
 * @param {boolean} [params.translated] if the requests are translated (defaults to false)
 * @param {boolean} [params.useCache] defaults to true
 * @param {Omit<import('@tanstack/react-query').UseQueryOptions, "context">} [params.options]
 * properties used in each query object
 */
export const useRequests = ({ requests, translated = false, useCache = true, options }) => {
  const { get } = useAccessTokenRequest()
  const { i18n } = useTranslation()

  const queryOptions = {
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      if (typeof options?.retry === 'function')
        return options?.retry(failureCount, error) ?? evaluateRetry(failureCount, error)
      return options?.retry ?? evaluateRetry(failureCount, error)
    },
    ...options,
  }
  if (!useCache) {
    queryOptions.staleTime = 1
  }

  const queries = requests.map((request) => ({
    queryKey: calculateCacheKeys({
      keys: request.keys,
      translated,
      path: request.path,
      language: translated ? i18n.language : undefined,
    }),
    queryFn: async () => {
      const { data } = await get({ path: request.path, ...request.requestOptions })
      return data
    },
    ...queryOptions,
  }))

  return useQueries({ queries })
}

const DEFAULT_PAGE_SIZE = 50

/**
 * Makes a paginated GET request to an endpoint. This function expects that the response contains a `total` key,
 * where the total amount of data is returned, needed for calculations whether a next page exists.
 *
 * @param path request path, must not contain URL params; use `queryParams` instead
 * @param queryParams request URL params
 * @param limit page size (default: 50)
 * @param translated is this request translated
 * @param keys keys for caching
 * @param options options for `useInfiniteQuery`
 * @param requestOptions options for the requestFunction which defaults to `get`
 * @param requestFn the function to execute the request, defaults to `get` from `useAccessTokenRequest`
 * @returns {import('@tanstack/react-query').UseInfiniteQueryResult} the result of the useInfiniteQuery hook
 */
export const useInfiniteRequest = ({
  path,
  translated = false,
  keys,
  options,
  requestOptions,
  queryParams = new URLSearchParams(),
  limit: defaultLimit = DEFAULT_PAGE_SIZE,
  requestFn: requestFnOverride,
}) => {
  const { get } = useAccessTokenRequest()
  const { i18n } = useTranslation()
  const requestFn = requestFnOverride ?? get
  const queryOptions = {
    refetchOnWindowFocus: false,
    retry: evaluateRetry,
    ...options,
  }
  const cacheKeys = calculateCacheKeys({
    keys,
    translated,
    path,
    queryParams,
    language: translated && i18n.language,
  })
  return useInfiniteQuery({
    queryKey: cacheKeys,
    queryFn: async ({ pageParam: { offset = 0, limit = defaultLimit } = {} }) => {
      const params = new URLSearchParams(Array.from(queryParams.entries()))
      params.set('offset', offset)
      params.set('limit', limit)
      const { data } = await requestFn({ path: `${path}?${params.toString()}`, ...requestOptions })
      return { offset, ...data }
    },
    getNextPageParam: (lastPage) => {
      const newOffset = lastPage.offset + defaultLimit
      if (newOffset >= lastPage.total) {
        return undefined
      }
      return { offset: lastPage.offset + defaultLimit, limit: defaultLimit }
    },
    ...queryOptions,
  })
}
