import {
  CheckBox,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Input,
  Label,
  Option,
  Select,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import classNames from 'classnames'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsCellEdits.module.css'
import {
  ADDED,
  PERIODS,
  TOTAL_AREA,
} from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsUtils'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import { useAreaMeasurementUnitFormatter } from 'hooks/i18n/useI18n'
import {
  addInvalidEditState,
  removeInvalidEditState,
} from 'redux/slices/properties/segmentsTableSlice'

const parseFloatOrNull = (value) => (value && !isNaN(parseFloat(value)) ? parseFloat(value) : null)
const ANNUALLY_DIVISOR = 12

export const SelectAndUnselectAllTableCellEdit = ({ selectable, selected, onSelectChangeAll }) => (
  <FlexBox alignItems={FlexBoxAlignItems.Center} justifyContent={FlexBoxJustifyContent.Center}>
    <CheckBox
      disabled={!selectable}
      checked={selected}
      onChange={(e) => {
        if (selectable) {
          onSelectChangeAll(e)
        }
      }}
    />
  </FlexBox>
)
SelectAndUnselectAllTableCellEdit.propTypes = {
  selectable: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelectChangeAll: PropTypes.func.isRequired,
}

export const SelectAndUnselectTableCellEdit = ({ selectable, selected, onSelectChange }) => (
  <FlexBox alignItems={FlexBoxAlignItems.Center} justifyContent={FlexBoxJustifyContent.Center}>
    <CheckBox
      disabled={!selectable}
      checked={selected}
      onChange={(e) => {
        if (selectable) {
          onSelectChange(e)
        }
      }}
    />
  </FlexBox>
)
SelectAndUnselectTableCellEdit.propTypes = {
  selectable: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelectChange: PropTypes.func.isRequired,
}

export const NameAndUsageTypeTableCellEdit = ({
  segmentInEdit,
  usageTypeCodes,
  onChangeName,
  onChangeUsageType,
}) => {
  const isNewlyAdded = segmentInEdit?.uuid.startsWith(ADDED)

  const [isDirtyName, setIsDirtyName] = useState(isNewlyAdded)
  const [isDirtyUsageType, setIsDirtyUsageType] = useState(isNewlyAdded)
  const dispatch = useDispatch()

  const isDirtyAndInvalidName = isDirtyName && !segmentInEdit?.name
  const isDirtyAndInvalidUsageType = isDirtyUsageType && !segmentInEdit?.usage_type_code

  const addOrRemoveInvalidEditState = useCallback(
    (isDirtyAndInvalid, attribute) => {
      if (isDirtyAndInvalid) {
        dispatch(addInvalidEditState({ uuid: segmentInEdit.uuid, attribute }))
      } else {
        dispatch(removeInvalidEditState({ uuid: segmentInEdit.uuid, attribute }))
      }
    },
    [segmentInEdit, dispatch],
  )

  useEffect(() => {
    addOrRemoveInvalidEditState(isDirtyAndInvalidName, 'name')
  }, [isDirtyAndInvalidName, addOrRemoveInvalidEditState])

  useEffect(() => {
    addOrRemoveInvalidEditState(isDirtyAndInvalidUsageType, 'usage_type_code')
  }, [isDirtyAndInvalidUsageType, addOrRemoveInvalidEditState])

  return (
    <FlexBox direction="Column" className={styles.fullHeight}>
      <Input
        onChange={(e) => {
          setIsDirtyName(true)
          onChangeName(segmentInEdit.uuid, e?.target?.value)
        }}
        value={segmentInEdit?.name}
        valueState={isDirtyAndInvalidName ? ValueState.Error : ValueState.None}
        valueStateMessage={<Text>{t('form.validation.required')}</Text>}
      />
      <FlexBox className={styles['table-cell-last-item']}>
        <Select
          disabled={!isNewlyAdded}
          onChange={(e) => {
            setIsDirtyUsageType(true)
            onChangeUsageType(segmentInEdit.uuid, e?.detail?.selectedOption?.dataset?.value)
          }}
          valueState={isDirtyAndInvalidUsageType ? ValueState.Error : ValueState.None}
          valueStateMessage={<Text>{t('form.validation.required')}</Text>}
        >
          <Option selected={!segmentInEdit?.usage_type_code} key={''} data-value={''} />
          {usageTypeCodes.map((code) => (
            <Option
              selected={segmentInEdit?.usage_type_code === code.key}
              key={code.key}
              data-value={code.key}
            >
              {code.display_name}
            </Option>
          ))}
        </Select>
      </FlexBox>
    </FlexBox>
  )
}
NameAndUsageTypeTableCellEdit.propTypes = {
  segmentInEdit: PropTypes.object.isRequired,
  usageTypeCodes: PropTypes.array.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onChangeUsageType: PropTypes.func.isRequired,
}

export const DescriptionTableCellEdit = ({ segmentInEdit, onChangeDescription }) => (
  <FlexBox direction="Column">
    <Input
      onChange={(e) => {
        onChangeDescription(segmentInEdit.uuid, e?.target?.value)
      }}
      value={segmentInEdit?.description}
    />
  </FlexBox>
)
DescriptionTableCellEdit.propTypes = {
  segmentInEdit: PropTypes.object.isRequired,
  onChangeDescription: PropTypes.func.isRequired,
}

export const AreaAndMeasureUnitTableCellEdit = ({
  tSegments,
  segmentUuid,
  totalArea,
  totalAreas,
  totalNumberOfUnits,
  areaMeasureUnitCode,
  defaultAreaMeasureUnitCode,
  onChangeTotalArea,
  disabled = false,
}) => {
  const dispatch = useDispatch()
  const formattedNumberRef = useRef(null)

  const formatArea = useAreaMeasurementUnitFormatter()

  const calculateAreaSum = (areas) =>
    areas?.reduce((partialSum, addition) => partialSum + (addition ? addition : 0), 0) ?? 0

  const calculateAreaShare = (area, areaSum) => {
    let share = 0
    if (area && areaSum) {
      share = area / areaSum
    }
    return (share * 100).toFixed(0)
  }

  const [isValidInput, setIsValidInput] = useState(true)
  const isNegative = totalArea < 0

  useEffect(() => {
    if (!isValidInput || isNegative) {
      dispatch(addInvalidEditState({ uuid: segmentUuid, attribute: TOTAL_AREA }))
    } else {
      dispatch(removeInvalidEditState({ uuid: segmentUuid, attribute: TOTAL_AREA }))
    }
  }, [dispatch, segmentUuid, isValidInput, isNegative])

  return (
    <FlexBox direction="Column" className={styles.fullHeight}>
      <FlexBox direction="Row" alignItems="Center" justifyContent="SpaceBetween">
        <Label className={styles['table-cell-label']}>{`${tSegments('area.size')}:`}</Label>
        <FormattedNumberInput
          disabled={disabled}
          className={styles['table-cell-input']}
          icon={<Label>{formatArea(areaMeasureUnitCode || defaultAreaMeasureUnitCode)}</Label>}
          value={totalArea}
          valueState={isNegative ? ValueState.Error : ValueState.None}
          valueStateMessage={
            isNegative ? <Text>{t('form.validation.min', { minValue: 0 })}</Text> : ''
          }
          onChange={(parsedNumericValue) => {
            setIsValidInput(formattedNumberRef?.current?.isValid)
            onChangeTotalArea(segmentUuid, parsedNumericValue)
          }}
          ref={formattedNumberRef}
        />
      </FlexBox>
      <FlexBox
        className={styles['table-cell-last-item']}
        direction="Row"
        alignItems="Center"
        justifyContent="SpaceBetween"
      >
        <Label className={styles['table-cell-label']}>{`${tSegments('area.share')}:`}</Label>
        <Input
          disabled={true}
          className={styles['table-cell-input']}
          icon={<Label className="segment-market-rent-input-label">{'%'}</Label>}
          valueState={ValueState.None}
          value={calculateAreaShare(totalArea, calculateAreaSum(totalAreas))}
          type="Number"
        />
      </FlexBox>
      {totalNumberOfUnits > 0 && (
        <FlexBox
          className={styles['table-cell-last-item']}
          direction="Row"
          alignItems="Center"
          justifyContent="SpaceBetween"
        >
          <Label className={styles['table-cell-label']}>{`${tSegments('area.pieces')}:`}</Label>
          <Input
            disabled={true}
            className={styles['table-cell-input']}
            valueState={ValueState.None}
            value={totalNumberOfUnits}
            type="Number"
          />
        </FlexBox>
      )}
    </FlexBox>
  )
}
AreaAndMeasureUnitTableCellEdit.propTypes = {
  tSegments: PropTypes.func.isRequired,
  segmentUuid: PropTypes.string.isRequired,
  totalArea: PropTypes.number,
  totalAreas: PropTypes.array,
  totalNumberOfUnits: PropTypes.number,
  areaMeasureUnitCode: PropTypes.string,
  defaultAreaMeasureUnitCode: PropTypes.string,
  onChangeTotalArea: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export const MonthlyAndAnnuallyMoneyTableCellEdit = ({
  attribute,
  segmentInEdit,
  defaultCurrency,
  onChangeMoneyValue,
  perUomLabel,
  period,
}) => {
  const dispatch = useDispatch()
  const annualValue = segmentInEdit[attribute]?.number
  const currency = segmentInEdit[attribute]?.currency || defaultCurrency

  const [annuallyValue, setAnnuallyValue] = useState(parseFloatOrNull(annualValue?.toFixed(2)))
  const [monthlyValue, setMonthlyValue] = useState(
    parseFloatOrNull((annualValue / ANNUALLY_DIVISOR)?.toFixed(2)),
  )
  const [editMode, setEditMode] = useState(PERIODS.NONE)
  const isNegative = annualValue < 0
  const monthlyRef = useRef(null)
  const annuallyRef = useRef(null)

  const isMonthly = period === 'monthly'

  const updateEditState = (isValidInput) => {
    if (isNegative || !isValidInput) {
      dispatch(addInvalidEditState({ uuid: segmentInEdit.uuid, attribute: attribute }))
    } else {
      dispatch(removeInvalidEditState({ uuid: segmentInEdit.uuid, attribute: attribute }))
    }
  }

  const handleAnnuallyInput = (parsedValue) => {
    const annually = parsedValue
    const monthly = parsedValue ? (annually / ANNUALLY_DIVISOR).toFixed(2) : parsedValue
    setMonthlyValue(parseFloatOrNull(monthly))
  }

  const handleMonthlyInput = (parsedValue) => {
    const monthly = parsedValue
    const annually = parsedValue ? (monthly * ANNUALLY_DIVISOR).toFixed(2) : parsedValue
    setAnnuallyValue(parseFloatOrNull(annually))
  }

  const handleAnnuallyChange = (parsedValue) => {
    const annually = parsedValue
    setAnnuallyValue(parseFloatOrNull(annually))
    onChangeMoneyValue(segmentInEdit.uuid, parseFloatOrNull(annually))
  }

  const handleMonthlyChange = (parsedValue) => {
    const monthly = parsedValue
    const annually = (monthly * ANNUALLY_DIVISOR).toFixed(2)
    setMonthlyValue(parseFloatOrNull(monthly))
    onChangeMoneyValue(segmentInEdit.uuid, parseFloatOrNull(annually))
  }

  return (
    <FlexBox direction="Column" className={styles.fullHeight}>
      <FlexBox
        className={classNames({
          [styles.displayNone]: isMonthly,
        })}
        direction="Row"
        alignItems="Center"
        justifyContent="SpaceBetween"
      >
        <Label className={styles['table-cell-label']}>{perUomLabel}</Label>
        <FormattedNumberInput
          className={styles['table-cell-input']}
          disabled={editMode === PERIODS.MONTHLY}
          icon={<Label>{`${currency || defaultCurrency}`}</Label>}
          value={annuallyValue}
          valueState={isNegative ? ValueState.Error : ValueState.None}
          valueStateMessage={
            isNegative ? <Text>{t('form.validation.min', { minValue: 0 })}</Text> : ''
          }
          onInput={handleAnnuallyInput}
          onChange={(parsedNumericValue) => {
            updateEditState(monthlyRef?.current?.isValid)
            handleAnnuallyChange(parsedNumericValue)
          }}
          onFocus={() => setEditMode(PERIODS.ANNUALLY)}
          onBlur={() => setEditMode(PERIODS.NONE)}
          ref={monthlyRef}
        />
      </FlexBox>
      <FlexBox
        direction="Row"
        alignItems="Center"
        justifyContent="SpaceBetween"
        className={classNames({
          [styles.displayNone]: !isMonthly,
        })}
      >
        <Label className={styles['table-cell-label']}>{perUomLabel}</Label>
        <FormattedNumberInput
          className={styles['table-cell-input']}
          disabled={editMode === PERIODS.ANNUALLY}
          icon={<Label>{`${currency || defaultCurrency}`}</Label>}
          value={monthlyValue}
          valueState={isNegative ? ValueState.Error : ValueState.None}
          valueStateMessage={
            isNegative ? <Text>{t('form.validation.min', { minValue: 0 })}</Text> : ''
          }
          onInput={handleMonthlyInput}
          onChange={(parsedNumericValue) => {
            updateEditState(annuallyRef?.current?.isValid)
            handleMonthlyChange(parsedNumericValue)
          }}
          onFocus={() => setEditMode(PERIODS.MONTHLY)}
          onBlur={() => setEditMode(PERIODS.NONE)}
          ref={annuallyRef}
        />
      </FlexBox>
    </FlexBox>
  )
}
MonthlyAndAnnuallyMoneyTableCellEdit.propTypes = {
  attribute: PropTypes.string.isRequired,
  segmentInEdit: PropTypes.object.isRequired,
  defaultCurrency: PropTypes.string.isRequired,
  onChangeMoneyValue: PropTypes.func.isRequired,
  perUomLabel: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
}
