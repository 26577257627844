import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { decisionPaperVersion, isMajorVersion } from 'api/decision-paper/decisionPaperApi'
import AssessmentVersionsHistoryButtonLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/assessment/AssessmentVersionsHistoryButtonLoadingWrapper'
import DecisionPaperSubsectionAssessmentTile from 'components/domains/business-events-and-tasks/decision-paper/assessment/DecisionPaperSubsectionAssessmentTile'
import BackToCurrentVersionButton from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/BackToCurrentVersionButton'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useDecisionPaperSubsectionAssessment from 'hooks/services/business-events-and-tasks/decision-papers/assessments/useDecisionPaperSubsectionAssessment'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const currentVersion = 'current'

const getDisplayedAssessmentText = ({ isCurrentTileVersion, currentText, tileStatus }) => {
  if (isCurrentTileVersion || isNil(tileStatus)) {
    return currentText ?? ''
  }
  const displayedVersion = tileStatus.displayedVersion
  const versionData = tileStatus.versions.find(({ version }) => version === displayedVersion)
  return versionData?.text ?? ''
}

const DecisionPaperSubsectionAssessmentWrapper = ({
  tileId,
  version,
  subsectionId,
  isPdfView = false,
  isEditableInMinorVersion,
  lastAvailableVersion,
}) => {
  const isWorkingVersion = useMemo(() => version === decisionPaperVersion.workingVersion, [version])
  const currentDecisionPaperVersion = isWorkingVersion ? currentVersion : version

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.subsections.assessment',
  })

  const { id: eventId } = useContext(BusinessEventsAndTasksContext).event
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const displayedVersion = tileStatus?.displayedVersion
  const isCurrentTileVersion = useMemo(
    () => isNil(displayedVersion) || displayedVersion === currentVersion || !isWorkingVersion,
    [displayedVersion, isWorkingVersion],
  )
  const {
    isLoading,
    isError,
    data: assessmentData,
  } = useDecisionPaperSubsectionAssessment({
    eventId,
    version: currentDecisionPaperVersion,
    subsectionId,
  })

  const isAssessmentTileEditEnabled = useMemo(() => {
    if (decisionPaperVersion.workingVersion === version) {
      return isMajorVersion(lastAvailableVersion) || isEditableInMinorVersion
    }
    return false
  }, [isEditableInMinorVersion, lastAvailableVersion, version])

  const backToCurrentVersionButton = useMemo(() => {
    if (!isCurrentTileVersion) {
      return <BackToCurrentVersionButton key="back-to-current-version-button" tileId={tileId} />
    }
  }, [isCurrentTileVersion, tileId])
  const versionsHistoryButton = useMemo(
    () => (
      <AssessmentVersionsHistoryButtonLoadingWrapper
        key="versions-history-button-wrapper"
        subsectionId={subsectionId}
      />
    ),
    [subsectionId],
  )
  const tileVersionActions = useMemo(
    () => (isWorkingVersion ? [backToCurrentVersionButton, versionsHistoryButton] : []),
    [isWorkingVersion, backToCurrentVersionButton, versionsHistoryButton],
  )

  const renderContent = () => {
    const { text, lastUpdatedBy, lastUpdatedAt } = assessmentData
    const displayedText = getDisplayedAssessmentText({
      isCurrentTileVersion,
      currentText: text,
      tileStatus,
    })
    return (
      <DecisionPaperSubsectionAssessmentTile
        tileId={tileId}
        subsectionId={subsectionId}
        assessmentText={displayedText}
        lastUpdatedBy={lastUpdatedBy}
        lastUpdatedAt={lastUpdatedAt}
        isPdfView={isPdfView}
        isEditEnabled={isAssessmentTileEditEnabled}
        currentVersion={version}
        additionalEditActions={tileVersionActions}
      />
    )
  }

  return (
    <LoadingStateWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderContent}
      errorDescription={t('loading.error.description')}
      errorTitle={t('loading.error.title')}
    />
  )
}

DecisionPaperSubsectionAssessmentWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  subsectionId: PropTypes.string.isRequired,
  isEditableInMinorVersion: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool,
  lastAvailableVersion: PropTypes.string.isRequired,
}

export default DecisionPaperSubsectionAssessmentWrapper
