import { FlexBox, FlexBoxDirection, Label, Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { conditionsMassEditOperations } from 'api/conditions/conditions'
import RequirementsMassEditDocumentTypeSelect from 'components/domains/conditions/dialogs/requirements/mass-editing/RequirementsMassEditDocumentTypeSelect'

const RequirementsMassEditDocumentTypeSelectWrapper = ({ onChange, forceKeepValues, disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.dialogs.mass-edit',
  })
  const { t: tOperations } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.dialogs.mass-edit.operations',
  })

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label>{t('labels.document-type')}</Label>
      {forceKeepValues ? (
        <Select disabled={disabled}>
          <Option
            data-id={conditionsMassEditOperations.keep}
            value={conditionsMassEditOperations.keep}
            selected
          >
            {tOperations(conditionsMassEditOperations.keep)}
          </Option>
        </Select>
      ) : (
        <RequirementsMassEditDocumentTypeSelect onChange={onChange} disabled={disabled} />
      )}
    </FlexBox>
  )
}

RequirementsMassEditDocumentTypeSelectWrapper.propTypes = {
  onChange: PropTypes.func.isRequired,
  forceKeepValues: PropTypes.bool.isRequired,
  businessObjectRefs: PropTypes.arrayOf(
    PropTypes.shape({
      entityType: PropTypes.string.isRequired,
      entityId: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  disabled: PropTypes.bool,
}

export default RequirementsMassEditDocumentTypeSelectWrapper
