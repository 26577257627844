import { DatePicker } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { filters } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/ui/tables/toolbar/propTypes'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

/**
 * Input element for a date filter. Because the date picker works with local strings,
 * the selected values are converted to iso dates.
 */
const DateFilterInput = ({
  value,
  maxDate,
  minDate,
  targetName,
  selectedFilters,
  setSelectedFilters,
  columnKey,
}) => {
  const { localePattern, parse } = useShortDateFormatter()
  return (
    <DatePicker
      value={value}
      maxDate={maxDate}
      minDate={minDate}
      onChange={(e) => {
        if (e.detail.valid) {
          setSelectedFilters(
            selectedFilters.map((filter) => {
              if (filter.columnKey !== columnKey) return filter
              const isoDate = parse(e.detail.value, localePattern) ?? filter.emptyValue[targetName]
              return { ...filter, value: { ...filter.value, [targetName]: isoDate } }
            }),
          )
        }
      }}
      style={{ flex: '1 1 auto' }}
      formatPattern={localePattern}
    />
  )
}

DateFilterInput.propTypes = {
  value: PropTypes.string,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  targetName: PropTypes.string.isRequired,
  selectedFilters: filters.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  columnKey: PropTypes.string.isRequired,
}

export default DateFilterInput
