import { FlexBox, FlexBoxDirection, Label, Text, TextArea } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/critical-facility/shared/TextAreaWithMaxCharacterRestriction.module.css'

const TextAreaWithMaxCharacterRestriction = ({
  value,
  maxLength,
  onInput = (_) => {},
  label,
  readOnly,
  ...props
}) => {
  const { t } = useTranslation('decisionPaper', { keyPrefix: 'components.ui.input.text-area' })
  const onInputInternal = (evt) => onInput(evt?.target?.value, evt)

  const textAreaRef = useRef(null)

  // WARN: The TextArea breaks on focus out when, this is a workaround to fix it
  useEffect(() => {
    if (typeof textAreaRef.current?._onfocusout === 'function') {
      textAreaRef.current._onfocusout = (e) => {
        const eTarget = e?.relatedTarget
        const focusedOutToValueStateMessage =
          eTarget?.shadowRoot?.querySelector('.ui5-valuestatemessage-root') ?? undefined
        textAreaRef.current.focused = false
        if (!isNil(focusedOutToValueStateMessage)) {
          textAreaRef.current._openValueStateMsgPopover = false
        }
      }
    }
  }, [textAreaRef?.current?._onfocusout])

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      {label && <Label>{label}</Label>}
      <TextArea
        value={value}
        className={styles.charactersTextArea}
        maxlength={maxLength}
        onInput={onInputInternal}
        ref={textAreaRef}
        readonly={readOnly}
        {...props}
        onFocus={(e) => {
          e.stopPropagation()
        }}
      />
      <Text className={styles.charactersRemaining}>
        {t('characters-remaining', {
          charactersRemaining: maxLength - value.length,
        })}
      </Text>
    </FlexBox>
  )
}

TextAreaWithMaxCharacterRestriction.displayName = 'TextAreaWithMaxCharacterRestriction'
TextAreaWithMaxCharacterRestriction.defaultProps = {
  value: '',
  readOnly: false,
}
TextAreaWithMaxCharacterRestriction.propTypes = {
  value: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onInput: PropTypes.func,
  label: PropTypes.string,
}

export default TextAreaWithMaxCharacterRestriction
