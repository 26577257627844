import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useTask = ({ eventId, taskId }) =>
  useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/tasks/${taskId}`,
      useCache: true,
      keys: ['events', eventId, 'tasks', taskId],
    }),
  )

export default useTask
