import { Button } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import EventCreationDialog from 'components/domains/business-events-and-tasks/events/dialog/EventCreationDialog'

const CreateEventAction = ({ entity, renderCustomDialog }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.events.create-event-action',
  })

  const [isCreateEventDialogOpen, setIsCreateEventDialogOpen] = useState(false)

  const renderDialog = () =>
    renderCustomDialog ? (
      renderCustomDialog({
        isOpen: isCreateEventDialogOpen,
        setIsOpen: setIsCreateEventDialogOpen,
        preselectedEntity: entity,
      })
    ) : (
      <EventCreationDialog
        isOpen={isCreateEventDialogOpen}
        setIsOpen={setIsCreateEventDialogOpen}
        preselectedEntity={entity}
        createInNewTab={true}
      />
    )

  return (
    <>
      <Button onClick={() => setIsCreateEventDialogOpen(true)}>{t('button')}</Button>
      {isCreateEventDialogOpen && renderDialog()}
    </>
  )
}

CreateEventAction.propTypes = {
  entity: PropTypes.shape({
    entityName: PropTypes.string.isRequired,
    entityId: PropTypes.string.isRequired,
    entityDisplayId: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
    isWorkingVersion: PropTypes.bool,
  }).isRequired,
  renderCustomDialog: PropTypes.func,
}

export default CreateEventAction
