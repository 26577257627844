import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Card from 'components/ui/card/Card'
import CardHeaderWithMetrics from 'components/ui/card/CardHeaderWithMetrics'
import { useNumberFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'
import usePropertyRentRollComparisonKpis from 'hooks/services/properties/kpis/comparison/usePropertyRentRollComparisonKpis'
import usePropertyRentRollComparisonMetric from 'hooks/services/properties/kpis/comparison/usePropertyRentRollComparisonMetric'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyRentRollComparisonWaultCard = () => {
  const { t: tComparison } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.comparison',
  })

  const yearOrYears = (value) =>
    value === 1 ? tComparison('table.cells.year') : tComparison('table.cells.years')

  const { property } = useContext(PropertyContext)

  const { format: formatDate } = useShortDateFormatter()

  const {
    isLoading,
    isError,
    currentRentRollKpis,
    comparisonRentRollKpis,
    currentKeyDate,
    comparisonKeyDate,
  } = usePropertyRentRollComparisonKpis(property)

  const formatWault = useNumberFormatter({
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  const findWaultToBreak = (kpis) => kpis?.waultToBreakInYears

  const currentWaultToBreak = findWaultToBreak(currentRentRollKpis)
  const comparisonWaultToBreak = findWaultToBreak(comparisonRentRollKpis)

  const currentFormattedWaultToBreak = currentWaultToBreak ? formatWault(currentWaultToBreak) : null
  const comparisonFormattedWaultToBreak = comparisonWaultToBreak
    ? formatWault(comparisonWaultToBreak)
    : null

  const formatSecondaryMetric = (value, unit) => (value ? `${value} ${unit}` : null)

  const primaryMetric = {
    label: formatDate(currentKeyDate),
    value: currentFormattedWaultToBreak,
    unit: currentWaultToBreak ? yearOrYears(currentWaultToBreak) : null,
  }

  const secondaryMetric = {
    label: formatDate(comparisonKeyDate),
    value: formatSecondaryMetric(
      comparisonFormattedWaultToBreak,
      yearOrYears(comparisonWaultToBreak),
    ),
  }

  const comparisonMetric = usePropertyRentRollComparisonMetric({
    isLoading,
    isError,
    formatter: formatWault,
    primaryValue: currentWaultToBreak,
    comparisonValue: comparisonWaultToBreak,
    unit: yearOrYears(currentWaultToBreak - comparisonWaultToBreak),
  })

  const isInComparison = comparisonKeyDate && comparisonKeyDate !== currentKeyDate

  const secondaryMetrics = isInComparison ? [secondaryMetric, comparisonMetric] : [secondaryMetric]

  return (
    <Card>
      <CardHeaderWithMetrics
        title={tComparison('table.cells.wault-break')}
        primaryMetric={primaryMetric}
        secondaryMetrics={secondaryMetrics}
        showReferenceDate={false}
        isLoading={isLoading}
        isError={isError}
      />
    </Card>
  )
}
export default PropertyRentRollComparisonWaultCard
