// These codes are hardcoded in the frontend, as they are product specific
export const CLUB_DEAL_IN = 'ClubDealIn'
export const CLUB_DEAL_OUT = 'ClubDealOut'
export const SYNDICATION_IN = 'SyndicationIn'
export const SYNDICATION_OUT = 'SyndicationOut'
export const NO_SYNDICATION_TYPE = 'NoSyndicationType'

export const syndicationTypeCodesForTable = [
  CLUB_DEAL_IN,
  CLUB_DEAL_OUT,
  SYNDICATION_IN,
  SYNDICATION_OUT,
  NO_SYNDICATION_TYPE,
]

export const syndicationTypeCodesForButtons = [
  CLUB_DEAL_IN,
  CLUB_DEAL_OUT,
  SYNDICATION_IN,
  SYNDICATION_OUT,
]
