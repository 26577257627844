import { ObjectPageSection, ToolbarSeparator } from '@fioneer/ui5-webcomponents-react'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionsEntityTypes } from 'api/conditions/conditions'
import { conditionsAllowedOperations as conditionsAllowedOperationsAll } from 'api/conditions/conditionsAllowedOperations'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import TabPage from 'components/ui/page/TabPage'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useConditionsAllowedOperations from 'hooks/services/conditions/useConditionsAllowedOperations'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import BusinessPartnerConditionsOpenChangelogAction from 'routes/business-partners/conditions/BusinessPartnerConditionsOpenChangelogAction'
import ConditionRequirementsPage from 'routes/conditions/ConditionRequirementsPage'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'
import ConditionsPage from 'routes/conditions/ConditionsPage'

const BusinessPartnerConditions = () => {
  const { id: businessPartnerId } = useContext(BusinessPartnerContext)

  const { t } = useTranslation('translation', { keyPrefix: 'pages.conditions' })
  const { t: tNoPrefix } = useTranslation()

  const openChangelogAction = useMemo(
    () => (
      <BusinessPartnerConditionsOpenChangelogAction key="bp-conditions-show-changelog-action" />
    ),
    [],
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="bp-conditions-user-favorite-icon"
        entityId={businessPartnerId}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [businessPartnerId],
  )

  const {
    isLoading: isConditionsAllowedOperationsLoading,
    isError: isConditionsAllowedOperationsError,
    data: conditionsAllowedOperations = { allowedOperations: [] },
  } = useConditionsAllowedOperations()

  const conditionsContext = useMemo(
    () => ({
      entityRef: {
        entityId: businessPartnerId,
        entityDisplayId: businessPartnerId,
        entityType: conditionsEntityTypes.businessPartner,
      },
      allowedOperations: conditionsAllowedOperations.allowedOperations,
      referencesMapping: {
        [cwpEntityTypes.DEAL]: {
          borrowerId: businessPartnerId,
        },
      },
    }),
    [businessPartnerId, conditionsAllowedOperations.allowedOperations],
  )

  const renderContent = useCallback(() => {
    if (
      !conditionsAllowedOperations.allowedOperations.includes(
        conditionsAllowedOperationsAll.readCondition,
      )
    ) {
      return (
        <ErrorDataUnavailableInContent
          titleText={tNoPrefix('app.permission-error.title')}
          subtitleText={tNoPrefix('app.permission-error.subtitle')}
        />
      )
    }
    return (
      <ConditionsContext.Provider value={conditionsContext}>
        <TabPage
          includeUrlQuery
          headerTitle={
            <BusinessPartnerHeader
              breadcrumbs={[{ text: t('title', { keyPrefix: 'pages.conditions' }) }]}
              actions={[
                openChangelogAction,
                <ToolbarSeparator key={`toolbar-separator`} />,
                markFavoriteAction,
              ]}
            />
          }
        >
          <ObjectPageSection id="conditions" titleText={t('tabs.conditions')}>
            <ConditionsPage />
          </ObjectPageSection>
          <ObjectPageSection id="requirements" titleText={t('tabs.requirements')}>
            <ConditionRequirementsPage />
          </ObjectPageSection>
        </TabPage>
      </ConditionsContext.Provider>
    )
  }, [
    conditionsAllowedOperations.allowedOperations,
    conditionsContext,
    markFavoriteAction,
    openChangelogAction,
    t,
    tNoPrefix,
  ])

  return (
    <RequestStateResolver
      isLoading={isConditionsAllowedOperationsLoading}
      isError={isConditionsAllowedOperationsError}
      renderContent={renderContent}
      errorToDisplay={<ErrorDataUnavailableInContent />}
    />
  )
}

BusinessPartnerConditions.propTypes = {}

export default BusinessPartnerConditions
