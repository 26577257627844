import {
  Button,
  ButtonDesign,
  CheckBox,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Link,
  LinkDesign,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { AREA_COLUMN_TYPES } from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsCellViews'

export const SegmentCheckboxCell = ({ selectable, checked, onCheck }) => (
  <FlexBox alignItems={FlexBoxAlignItems.Center} justifyContent={FlexBoxJustifyContent.Center}>
    <CheckBox disabled={!selectable} checked={checked} onChange={onCheck} />
  </FlexBox>
)
SegmentCheckboxCell.propTypes = {
  selectable: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
}

export const ValuationResultsLinkCell = ({ tSegments, propertyId }) => (
  <FlexBox justifyContent={FlexBoxJustifyContent.End}>
    <Link
      design={LinkDesign.Default}
      href={`/properties/${propertyId}/${AREA_COLUMN_TYPES.VALUATION}`}
      target="_self"
      rel="noreferrer"
    >
      {tSegments('valuation-results-value')}
    </Link>
  </FlexBox>
)
ValuationResultsLinkCell.propTypes = {
  tSegments: PropTypes.func.isRequired,
  propertyId: PropTypes.string.isRequired,
}

export const RentRollLinkCell = ({ tSegments, propertyId }) => (
  <FlexBox justifyContent={FlexBoxJustifyContent.End}>
    <Link
      design={LinkDesign.Default}
      href={`/properties/${propertyId}/${AREA_COLUMN_TYPES.RENT_ROLL}`}
      target="_self"
      rel="noreferrer"
    >
      {tSegments('rent-roll-value')}
    </Link>
  </FlexBox>
)
RentRollLinkCell.propTypes = {
  tSegments: PropTypes.func.isRequired,
  propertyId: PropTypes.string.isRequired,
}

export const AddButtonCell = ({ propertyUuid, onAdd }) => (
  <FlexBox
    fitContainer={true}
    alignItems={FlexBoxAlignItems.Center}
    justifyContent={FlexBoxJustifyContent.End}
  >
    <Button design={ButtonDesign.Transparent} icon="add" onClick={() => onAdd(propertyUuid)} />
  </FlexBox>
)
AddButtonCell.propTypes = {
  propertyUuid: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
}
