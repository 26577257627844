// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CjSMcLPDCKEP4Fg3_OJ4 {
    margin-top: 4px;
    margin-bottom: 0;
    color: var(--sapContent_LabelColor) !important;
}

.K3XiDkl0r4YWQDp3OIrY {
    text-align: center;
    color: var(--sapTextColor);
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/illustrated-message/CustomizedIllustratedMessage.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,8CAA8C;AAClD;;AAEA;IACI,kBAAkB;IAClB,0BAA0B;AAC9B","sourcesContent":[".subtitle {\n    margin-top: 4px;\n    margin-bottom: 0;\n    color: var(--sapContent_LabelColor) !important;\n}\n\n.title {\n    text-align: center;\n    color: var(--sapTextColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": `CjSMcLPDCKEP4Fg3_OJ4`,
	"title": `K3XiDkl0r4YWQDp3OIrY`
};
export default ___CSS_LOADER_EXPORT___;
