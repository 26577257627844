import { ValueState } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionPaperSynchronizationStatus } from 'api/decision-paper/decisionPaperApi'
import { eventStatus } from 'api/events/status'
import DateCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/DateCell'
import EntityCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/EntityCell'
import StatusCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/StatusCell'
import TextCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/TextCell'
import CurrentDecisionStageTableCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/components/CurrentDecisionStageTableCell'
import paths from 'routes/paths'

const objectStatusForEventStatus = {
  [eventStatus.drafted]: {
    translationKey: 'events.status.drafted',
    objectStatus: ValueState.None,
  },
  [eventStatus.ongoing]: {
    translationKey: 'events.status.ongoing',
    objectStatus: ValueState.Information,
  },
  [eventStatus.completed]: {
    translationKey: 'events.status.completed',
    objectStatus: ValueState.Success,
  },
  [eventStatus.aborted]: {
    translationKey: 'events.status.aborted',
    objectStatus: ValueState.Error,
  },
  default: {
    translationKey: 'events.status.unknown',
    objectStatus: ValueState.None,
  },
}
const getObjectStatusForEventStatus = (status) =>
  objectStatusForEventStatus[status] !== undefined
    ? objectStatusForEventStatus[status]
    : objectStatusForEventStatus.default

const renderBusinessEventStatus = (status, tNoPrefix) => {
  const { translationKey: statusTranslationKey, objectStatus } =
    getObjectStatusForEventStatus(status)
  return <StatusCell statusState={objectStatus} statusValue={tNoPrefix(statusTranslationKey)} />
}

const renderDecisionPaperStatus = (statusData, tNoPrefix) => {
  if (!statusData?.status) {
    return '-'
  }
  const isDecisionPaperInSync = statusData?.status === decisionPaperSynchronizationStatus.live
  const statusState = isDecisionPaperInSync ? ValueState.Success : ValueState.Error
  const statusValueKey = isDecisionPaperInSync ? 'in-sync' : 'not-in-sync'
  return (
    <StatusCell
      statusState={statusState}
      statusValue={tNoPrefix(
        `pages.business-partner.risk-monitoring.risk-indicators.group-details.business-events.decision-paper-status.${statusValueKey}`,
      )}
      isLoading={statusData?.isLoading ?? false}
      isError={statusData?.isError ?? false}
    />
  )
}

const useRiskIndicatorsGroupBusinessEventsTableData = (riskIndicatorGroupEvents) => {
  const { t: tNoPrefix } = useTranslation('decisionPaper')

  return useMemo(
    () =>
      riskIndicatorGroupEvents.map(
        ({
          businessEvent,
          creationDate,
          dueDate,
          assignee,
          decisionDate,
          businessEventStatus,
          decisionPaperStatus,
          decisionStages,
        }) => ({
          rowKey: businessEvent?.id,
          businessEvent: {
            value: businessEvent?.displayId,
            cellComponent: (
              <EntityCell
                name={businessEvent?.name}
                link={`/${paths.businessEventsAndTasks}/${paths.businessEvents}/${businessEvent?.id}`}
                id={businessEvent?.displayId}
                options={{ isNameBold: true }}
              />
            ),
          },
          creationDate: {
            value: new Date(creationDate).getTime(),
            cellComponent: <DateCell date={creationDate} />,
          },
          dueDate: {
            cellComponent: <DateCell date={dueDate} />,
          },
          assignee: {
            cellComponent: <TextCell text={assignee?.name} />,
          },
          businessEventStatus: {
            cellComponent: renderBusinessEventStatus(businessEventStatus, tNoPrefix),
          },
          decisionStatus: {
            cellComponent: <CurrentDecisionStageTableCell decisionStages={decisionStages} />,
          },
          decisionDate: {
            cellComponent: (
              <DateCell
                date={decisionDate?.date}
                isLoading={decisionDate?.isLoading}
                isError={decisionDate?.isError}
              />
            ),
          },
          decisionPaperStatus: {
            cellComponent: renderDecisionPaperStatus(decisionPaperStatus, tNoPrefix),
          },
        }),
      ),
    [riskIndicatorGroupEvents, tNoPrefix],
  )
}
export default useRiskIndicatorsGroupBusinessEventsTableData
