import { Text } from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  riskCategories,
  riskTypes,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/risk-view/shared/riskViewApi'
import LoadingDropdown from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/risk-view/shared/ui/dropdown/LoadingDropdown'

export const noSelectionCode = 'decision-paper-risk-type-select-no-selection-code'

const DecisionPaperRiskViewSelect = ({ riskCategory, onChange, onBlur, value, valueState }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.risk-view',
  })

  const riskTypeData = useMemo(
    () => [
      { code: riskTypes.veryLow, displayName: t('risk-type.very-low') },
      { code: riskTypes.low, displayName: t('risk-type.low') },
      { code: riskTypes.medium, displayName: t('risk-type.medium') },
      { code: riskTypes.mediumHigh, displayName: t('risk-type.medium-high') },
      { code: riskTypes.high, displayName: t('risk-type.high') },
      { code: riskTypes.veryHigh, displayName: t('risk-type.very-high') },
    ],
    [t],
  )

  const handleChange = useCallback(
    (selectedValue) => {
      if (!selectedValue) {
        onChange(noSelectionCode, riskCategory)
        return
      }
      onChange(selectedValue, riskCategory)
    },
    [onChange, riskCategory],
  )

  const handleBlur = useCallback(
    (selectedValue) => {
      if (!selectedValue) {
        onBlur(noSelectionCode, riskCategory)
        return
      }
      onBlur(selectedValue, riskCategory)
    },
    [onBlur, riskCategory],
  )

  const riskTypeLoader = useCallback(() => {
    const data = value
      ? riskTypeData
      : [{ code: noSelectionCode, displayName: t('select.no-selection') }, ...riskTypeData]
    return {
      isLoading: false,
      isError: false,
      data,
    }
  }, [value, riskTypeData, t])

  return (
    <LoadingDropdown
      id={`decision-paper-risk-type-select-${riskCategory}`}
      useLoader={riskTypeLoader}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value ?? noSelectionCode}
      valueState={valueState}
      valueStateMessage={<Text>{t('select.error.empty')}</Text>}
    />
  )
}

DecisionPaperRiskViewSelect.propTypes = {
  riskCategory: PropTypes.oneOf(Object.values(riskCategories)).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.oneOf(Object.values(riskTypes)),
  valueState: PropTypes.oneOf([ValueState.Error, ValueState.None]),
}

export default DecisionPaperRiskViewSelect
