import { isNil } from 'lodash'
import { useContext, useMemo } from 'react'
import DealManageWorkingVersionButton from 'components/domains/deals/deal-adjustment/DealManageWorkingVersionButton'
import DealToggleWorkingVersionButton from 'components/domains/deals/deal-adjustment/DealToggleWorkingVersionButton'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { DealContext } from 'routes/deals/DealContext'

export const useDealWorkingVersionToolbarButtons = () => {
  const {
    deal: { dealUuid, workingVersion },
    dealHeaders: { workingVersion: existingWorkingVersion },
  } = useContext(DealContext)

  const toggleWorkingVersionButton = useMemo(
    () =>
      !isNil(existingWorkingVersion) && (
        <DealToggleWorkingVersionButton key="toggle-working-version-type" />
      ),
    [existingWorkingVersion],
  )

  const isWorkingVersion = useMemo(
    () => workingVersion === WorkingVersionType.WORKING_VERSION,
    [workingVersion],
  )

  const manageWorkingVersionButton = useMemo(
    () =>
      isWorkingVersion && (
        <DealManageWorkingVersionButton key="manage-working-version" dealUuid={dealUuid} />
      ),
    [dealUuid, isWorkingVersion],
  )

  return {
    toggleWorkingVersionButton,
    manageWorkingVersionButton,
  }
}
