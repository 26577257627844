import i18n from 'i18next'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const DEFAULT_LOCALE = 'en-US'
const CACHE = {}

const getCurrencyIntlNumberFormatOrDefault = ({
  currency,
  locales,
  specificOptions = {},
  optionOverrides = {},
}) => {
  const createFormatter = (currencyValue) =>
    new Intl.NumberFormat(locales, {
      ...specificOptions,
      currency: currencyValue,
      ...optionOverrides,
    }).format

  let isPotentiallyValidCurrency = !!currency
  let format

  try {
    format = createFormatter(currency || 'EUR')
  } catch (error) {
    isPotentiallyValidCurrency = false
    format = createFormatter('EUR')
  }

  return {
    isPotentiallyValidCurrency,
    format,
  }
}

export const useLanguage = () => {
  const [language, setLanguage] = useState(i18n.language)

  useEffect(() => {
    i18n.on('languageChanged', setLanguage)
    return () => i18n.off('languageChanged')
  }, [])

  return language || DEFAULT_LOCALE
}

export const useFormatterCache = (options, loader) => {
  const language = useLanguage()
  const locales = [language, DEFAULT_LOCALE]
  const optionsKey = Object.keys(options)
    .sort((a, b) => a.localeCompare(b))
    .map((key) => `${key}-${options[key]}`)
    .join('-')
  const cacheKey = locales.join('-') + optionsKey
  if (!CACHE[cacheKey]) {
    CACHE[cacheKey] = loader(locales, options)
  }
  return CACHE[cacheKey]
}

export const useBooleanToTextFormatter = () => {
  const { t } = useTranslation('decisionPaper')
  return useCallback(
    (value) => {
      if (typeof value !== 'boolean') return t('formatters.boolean.empty-value')
      const key = value ? 'yes' : 'no'
      return t(`formatters.boolean.${key}`)
    },
    [t],
  )
}

const isNumber = (value) => typeof value === 'number' && !isNaN(value)

/**
 * Helper-method to place the minus before the number instead of before the currency
 * I.e. EUR -8.000,00 instead of -EUR 8.000,00 for english formatting
 * @param parsedString
 * @returns string
 */
const prefixNumberWithMinus = (parsedString) => {
  const minusSignPattern = /-?/
  const digitsPattern = /(-?[\d,.]+)/ // positive or negative values including commas and points
  return parsedString
    .replace(minusSignPattern, '') // removing the minus
    .replace(digitsPattern, `-$1`) // placing the minus at desired position
}

/**
 * This provides a wrapper with CWP formatting defaults for the Intl API. For
 * optional overrides see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 *
 * Do not use currencySign: 'accounting' without expecting suspicious results.
 * The default which we agreed on looks like the following for negative values: EUR -1.000,00
 */
export const useCustomizableCurrencyFormatter = ({
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
  currencyDisplay = 'code',
  ...options
} = {}) =>
  useFormatterCache(
    {
      ...options,
      style: 'currency',
      maximumFractionDigits,
      minimumFractionDigits,
      currencyDisplay,
    },
    (locales, specificOptions) =>
      (value, currency, optionOverrides = {}) => {
        // Valid currency codes consist of three letters and might change
        // Therefore it is only tested that the currency is not undefined, null or ''
        const { isPotentiallyValidCurrency, format } = getCurrencyIntlNumberFormatOrDefault({
          currency,
          locales,
          specificOptions,
          optionOverrides,
        })
        let parsedString = isNumber(value) ? format(value) : ''
        if (value < 0) parsedString = prefixNumberWithMinus(parsedString)
        return isPotentiallyValidCurrency ? parsedString : parsedString.replace('EUR', 'N/A')
      },
  )

const isUSLocale = (locale) => locale === 'en-US'

/**
 * Helper function that returns the dateString format pattern for the given formatter.
 */
export const getShortDatePattern = (formatter, locale) => {
  const separator = ' '
  const partToPattern = (part) => {
    switch (part.type) {
      case 'second':
        return 'ss'
      case 'minute':
        return 'mm'
      case 'hour':
        return isUSLocale(locale) ? 'hh' : 'HH'
      case 'day':
        return 'dd'
      case 'dayPeriod':
        return 'a'
      case 'month':
        return 'MM'
      case 'year':
        return 'yyyy'
      case 'literal':
        return part.value.trim() ? part.value : separator
      default:
        return ''
    }
  }

  return formatter.formatToParts(new Date()).map(partToPattern).join('')
}

/**
 * Exposes useful properties for parsing date-strings between ISO and locale specific format.
 * For optional overrides see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {*} options Object containing Intl.DateTimeFormat options.
 * @returns
 * * format: Function that takes a dateString in ISO format and returns the string in locale specific format.
 * * parse: Function that takes a dateString in locale specific format and the localePattern and returns the string in ISO format.
 * * localePattern: DateString pattern for the given locale.
 */
export const useShortDateFormatter = ({
  year = 'numeric',
  month = '2-digit',
  day = '2-digit',
  ...options
} = {}) =>
  useFormatterCache(
    {
      year,
      month,
      day,
      ...options,
    },
    (locales, specificOptions) => {
      const formatter = Intl.DateTimeFormat(locales, specificOptions)
      const validIsoStringWithDate = (isoString) => {
        const regex = /^\d{4}-\d{2}-\d{2}/
        return regex.test(isoString)
      }

      return {
        format: (isoString) => {
          if (validIsoStringWithDate(isoString)) {
            const dateTime = DateTime.fromISO(isoString).setLocale(locales[0])
            return dateTime.isValid ? dateTime.toLocaleString(specificOptions) : isoString
          }
          return isoString
        },
        parse: (strValue, localePattern) => {
          if (!strValue || !localePattern) {
            return null
          }
          const dateTime = DateTime.fromFormat(strValue, localePattern)
          return dateTime.toISODate()
        },
        localePattern: getShortDatePattern(formatter, locales[0]),
      }
    },
  )
