import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import camelize from 'camelize'
import { every } from 'lodash'
import isEmpty from 'lodash.isempty'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import PropertyKeyDataTable from 'components/domains/properties/general-information/keyData/PropertyKeyDataTable'
import DisplayAndEditCard from 'components/ui/card/DisplayAndEditCard'
import {
  useAreaMeasurementUnitFormatter,
  useNumberFormatter,
  usePercentageFormatter,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'
import useCurrentPropertiesKpis from 'hooks/services/properties/kpis/useCurrentPropertiesKpis'
import { useUpdatePropertyDetails } from 'hooks/services/properties/useUpdatePropertyDetails'
import useMultiPropertyValuations from 'hooks/services/properties/valuations/useMultiPropertyValuations'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyKeyDataCard = () => {
  const { property } = useContext(PropertyContext)
  const {
    uuid,
    currencyCode,
    areaMeasureUnitCode,
    totalAreaMeasure: totalArea,
    numberOfUnits: totalUnitsNumber,
    actualRentAmount,
    allowedOperations,
    changeRequestExistIndicator,
  } = camelize(property)

  const {
    isLoading: isLoadingPropertyKpis,
    isError: isErrorPropertyKpis,
    data: propertiesKpiData,
  } = useCurrentPropertiesKpis([uuid])
  const kpiData = propertiesKpiData[0]
  const kpiDataEmpty = isEmpty(kpiData)

  const {
    isFetching: isLoadingValuations,
    isError: isErrorValuations,
    data: { valuations = {} } = {},
  } = useMultiPropertyValuations([uuid])

  const allowedOperationList = allowedOperations?.allowedOperations ?? []
  const userIsAllowedToEdit =
    !changeRequestExistIndicator && allowedOperationList.includes('PropertyMasterData_Update')

  const { t } = useTranslation()

  const formatAreaUnit = useAreaMeasurementUnitFormatter()
  const { format: formatDate } = useShortDateFormatter()
  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })

  const kpisExist = !isLoadingPropertyKpis && !isErrorPropertyKpis && !kpiDataEmpty
  const valuationsExist = !isLoadingValuations && !isErrorValuations && !isEmpty(valuations)
  const getValueOrDefault = (value, dataExist = true) => (value && dataExist ? value : '')
  const getNumberValueOrDefault = (value, dataExist = true) =>
    (!!value || value === 0) && dataExist ? value : ''

  //not camelize data, camelize not work on uuid and constant case as prop name
  const marketValue = valuations?.[uuid]?.MARKET_VALUE?.value_amount?.number ?? 0
  const annualRentalIncome = actualRentAmount?.number ?? 0
  const multiplier = marketValue && annualRentalIncome ? marketValue / annualRentalIncome : 0
  const getFormattedNumberValueOrDefault = (multiplierValue, dataExist = true) =>
    multiplierValue && dataExist ? formatNumber(multiplierValue) : ''
  const getPercentageValueOrDefault = (multiplierValue, dataExist = true) =>
    multiplierValue && dataExist ? formatPercentage(1 / multiplierValue) : ''

  const rentRollValues = {
    rentRollMeasurementUnit: getValueOrDefault(
      formatAreaUnit(kpiData?.totalAreaSurface?.measurementUnit),
      kpisExist,
    ),
    rentRollCurrency: getValueOrDefault(kpiData?.annualizedCurrentRent?.currency, kpisExist),
    rentRollValueTypes: {
      totalArea: getNumberValueOrDefault(kpiData?.totalAreaSurface.value, kpisExist),
      totalUnitsNumber: getNumberValueOrDefault(kpiData?.totalNumberOfUnits, kpisExist),
      actualRentAmount: getNumberValueOrDefault(kpiData?.annualizedCurrentRent.number, kpisExist),
      keyDate: getValueOrDefault(formatDate(kpiData?.keyDate), kpisExist),
      multiplier: '',
      yield: '',
    },
  }

  const valuationResultsValues = {
    valuationMeasurementUnit: formatAreaUnit(areaMeasureUnitCode),
    valuationCurrency: currencyCode,
    valuationValueTypes: {
      totalArea: getNumberValueOrDefault(totalArea),
      totalUnitsNumber: getNumberValueOrDefault(totalUnitsNumber),
      actualRentAmount: getNumberValueOrDefault(actualRentAmount?.number),
      multiplier: getFormattedNumberValueOrDefault(multiplier, valuationsExist),
      yield: getPercentageValueOrDefault(multiplier, valuationsExist),
    },
  }

  const valuationValueEmpty = every(valuationResultsValues.valuationValueTypes, (value) => !value)

  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()
  const updateValuationResults = useUpdatePropertyDetails({
    onSuccess: () => {
      queryClient.invalidateQueries(['properties'])
      showToast({ children: t('toast.changes-saved') })
    },
  })

  const saveChanges = (changes) => {
    const updateProperty = {
      ...changes,
      areaMeasureUnitCode,
      currencyCode,
    }
    updateValuationResults.mutate({
      property_uuid: uuid,
      property: { ...updateProperty },
    })
  }

  const renderEditView = ({ handleOnChange }) => (
    <PropertyKeyDataTable
      isEditMode={true}
      rentRollValues={rentRollValues}
      valuationResultsValues={valuationResultsValues}
      handleOnChange={handleOnChange}
    />
  )

  const keyDataFieldDefinitions = [
    {
      label: 'key-data-table-read',
      isShownInDisplay: true,
      customInfoComponent: (
        <PropertyKeyDataTable
          isEditMode={false}
          rentRollValues={rentRollValues}
          valuationResultsValues={valuationResultsValues}
        />
      ),
      isShownInEdit: false,
    },
    {
      label: 'key-data-table-edit',
      isShownInDisplay: false,
      renderCustomEditComponent: renderEditView,
      isShownInEdit: true,
    },
  ]
  return (
    <DisplayAndEditCard
      cardHeaderTitle={t('pages.properties.key-data-card.title')}
      isEmpty={kpiDataEmpty && valuationValueEmpty && !isErrorPropertyKpis}
      isError={isErrorPropertyKpis || isErrorValuations}
      isLoading={isLoadingPropertyKpis || isLoadingValuations}
      saveChanges={saveChanges}
      fieldDefinitions={keyDataFieldDefinitions}
      isEditable={userIsAllowedToEdit}
      saveHookIsError={updateValuationResults.isError}
      saveHookIsSuccess={updateValuationResults.isSuccess}
    />
  )
}

export default PropertyKeyDataCard
