import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionStageStatus } from 'api/decision-process/decisionProcessApi'
import styles from 'components/domains/business-events-and-tasks/events/decision-stage/UserDecisionStageVotesCard.module.css'
import UserDecisionStageVotesCardHeader from 'components/domains/business-events-and-tasks/events/decision-stage/UserDecisionStageVotesCardHeader'
import UserDecisionStageVotesCardTable from 'components/domains/business-events-and-tasks/events/decision-stage/UserDecisionStageVotesCardTable'
import Card from 'components/ui/card/Card'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useAllDecisionStageVoters } from 'hooks/services/business-events-and-tasks/decision-papers/useAllDecisionStageVoters'

const limit = 5
const statusFilterOptions = {
  inProgress: [decisionStageStatus.inProgress],
  inProgressAndCompleted: [
    decisionStageStatus.inProgress,
    decisionStageStatus.approved,
    decisionStageStatus.approvedWithConditions,
    decisionStageStatus.declined,
    decisionStageStatus.reconvened,
  ],
}

const UserDecisionStageVotesCard = ({ userId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.homepage.my-decisions',
  })
  const [statusFilter, setStatusFilter] = useState(statusFilterOptions.inProgress)
  const filter = { status: statusFilter, userId }

  const {
    isLoading,
    isError,
    data: decisionData,
    fetchNextPage,
  } = useAllDecisionStageVoters('stage', filter, { limit })

  const selectedFilter = !statusFilter[0].includes(decisionStageStatus.approved)
  const selectTasksOptions = useMemo(
    () => [
      <Option
        key={'status-dropdown-filter-default'}
        selected={selectedFilter}
        value={statusFilterOptions.inProgress}
      >
        {t('status-filter.in-progress')}
      </Option>,
      <Option
        key={'status-dropdown-filter'}
        selected={!selectedFilter}
        value={statusFilterOptions.inProgressAndCompleted}
      >
        {t('status-filter.in-progress-plus-completed')}
      </Option>,
    ],
    [selectedFilter, t],
  )

  const handleFilterChange = useCallback((event) => {
    setStatusFilter([event.detail.selectedOption.value])
  }, [])

  const statusSelect = useMemo(
    () => (
      <Select
        onChange={handleFilterChange}
        className={styles.select}
        key="homepage-decisions-status-select"
      >
        {selectTasksOptions}
      </Select>
    ),
    [handleFilterChange, selectTasksOptions],
  )

  const stageCardHeader = useMemo(
    () => <UserDecisionStageVotesCardHeader titleText={t('title')} />,
    [t],
  )

  const onLoadMore = useCallback(() => fetchNextPage(), [fetchNextPage])

  const renderContent = useCallback(
    () => (
      <UserDecisionStageVotesCardTable
        voterData={decisionData}
        additionalActions={statusSelect}
        onLoadMore={onLoadMore}
        noDataText={t('no-data')}
        shownColumns={[
          'decisionPaper',
          'entityRef',
          'event',
          'decisionStage',
          'decisionDate',
          'stageStatus',
        ]}
        tableTitle={t('title')}
      />
    ),
    [decisionData, statusSelect, onLoadMore, t],
  )

  return (
    <Card header={stageCardHeader}>
      <LoadingStateWrapper isLoading={isLoading} isError={isError} renderContent={renderContent} />
    </Card>
  )
}

UserDecisionStageVotesCard.propTypes = {
  userId: PropTypes.string.isRequired,
}

export default UserDecisionStageVotesCard
