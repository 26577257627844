import { useRequest } from 'hooks/services/baseService'

/**
 * downloads the image ans converts to a base64 data blob
 * @returns {{isLoading: boolean, isError: boolean, data: Base64ImageData}}
 */

export const useImageDownload = (image_uuid) =>
  useRequest({
    path: `/properties/images/${image_uuid}/download`,
  })
