import PropTypes from 'prop-types'
import DecisionPaperLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/links/DecisionPaperLeaveConfirmationLink'
import { useDecisionStageDecisionPaperName } from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/decision-overview/shared/useDecisionStageDecisionPaperName'
import paths from 'routes/paths'

const DecisionStageDecisionPaperLink = ({
  decisionStageStatus,
  decisionPaperVersion,
  eventId,
  showConfirmationForLinks = false,
}) => {
  const decisionPaperName = useDecisionStageDecisionPaperName({
    decisionStageStatus,
    decisionPaperVersion,
  })

  const link = `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}/decision-paper?version=${decisionPaperVersion}`

  return (
    <DecisionPaperLeaveConfirmationLink
      name={decisionPaperName}
      link={link}
      showConfirmationForLinks={showConfirmationForLinks}
    />
  )
}

DecisionStageDecisionPaperLink.propTypes = {
  eventId: PropTypes.string.isRequired,
  decisionStageStatus: PropTypes.string.isRequired,
  decisionPaperVersion: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool,
}

export default DecisionStageDecisionPaperLink
