import { z } from 'zod'

const exposureValueSchema = z.object({
  value: z.number(),
  currency: z.string(),
})

const largeExposureThresholdsSchema = z.object({
  largeExposureLimit: exposureValueSchema,
  largeExposureReportingThreshold: exposureValueSchema,
  reportingDate: z.string(),
})

export default largeExposureThresholdsSchema
