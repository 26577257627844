import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const usePropertiesValuationCreationBulk = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({
      propertyUuids,
      valuation: { reason, type, note, status, dueDate },
      createBusinessEvent,
    }) => {
      const { data } = await post({
        path: '/properties/valuation-requests/bulk-create',
        body: {
          property_uuids: propertyUuids,
          valuation_request_creation_dto: {
            reason,
            type,
            info: {
              note,
              status,
              due_date: dueDate,
            },
            create_business_event: createBusinessEvent,
          },
        },
      })
      return camelize(data)
    },
    { onSuccess, onError },
  )
}
