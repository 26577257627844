import { Modals, Text } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import '@ui5/webcomponents-fiori/dist/illustrations/EmptyList.js'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMultipleUpdatePropertyPartners } from 'hooks/services/properties/partners/useMultipleUpdatePropertyPartners'
import { usePropertyPartners } from 'hooks/services/properties/partners/usePropertyPartners'
import { PropertyContext } from 'routes/properties/PropertyContext'

/**
 * Hook that encapsulates the logic for creating, editing and deleting key internal contacts
 */
const useHandlePropertyKeyInternalContacts = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [keyInternalContactsState, setKeyInternalContactsState] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  const {
    property: { uuid: propertyUuid },
  } = useContext(PropertyContext)

  const {
    data: businessPartnersData,
    isLoading,
    isError,
  } = usePropertyPartners(propertyUuid, 'internal')

  const updatePropertyPartnersMutation = useMultipleUpdatePropertyPartners({
    propertyUuid: propertyUuid,
    onSuccess: () => {
      queryClient.invalidateQueries(['properties', propertyUuid, 'businesspartners', 'internal'])
      Modals.showToast({
        children: <Text>{t('toast.changes-saved')}</Text>,
      })
    },
    onError: async (error) => {
      queryClient.invalidateQueries(['properties', propertyUuid, 'businesspartners', 'internal'])
      const { message } = await error.response.json()
      setErrorMessage(message)
      setIsErrorDialogOpen(true)
    },
  })

  useEffect(() => {
    setIsErrorDialogOpen(isError)
  }, [isError, setIsErrorDialogOpen])

  const mappedKeyInternalContacts = useMemo(
    () =>
      businessPartnersData?.business_partners
        ? businessPartnersData.business_partners.reduce((accumulator, currentValue) => {
            const { party_id, business_partner_function_code, business_partner_function_name } =
              currentValue
            const existingBusinessPartner = accumulator.find((o) => o.userId === party_id)
            if (!existingBusinessPartner) {
              accumulator.push({
                userId: party_id,
                functions: [
                  {
                    id: business_partner_function_code,
                    modifiable: true,
                    name: business_partner_function_name,
                  },
                ],
              })
            } else {
              existingBusinessPartner.functions.push({
                id: business_partner_function_code,
                modifiable: true,
                name: business_partner_function_name,
              })
            }
            return accumulator
          }, [])
        : [],
    [businessPartnersData?.business_partners],
  )

  useEffect(() => {
    setKeyInternalContactsState(mappedKeyInternalContacts)
  }, [businessPartnersData, mappedKeyInternalContacts])

  const partnersToPost = useMemo(
    () =>
      keyInternalContactsState.reduce((internalContactsToAdd, currentInternalContact) => {
        const matchingMappedContactFunctions =
          mappedKeyInternalContacts.find(
            (mappedContact) => mappedContact.userId === currentInternalContact.userId,
          )?.functions ?? []
        const addedFunctions = currentInternalContact.functions.filter(
          (function1) =>
            !matchingMappedContactFunctions.some((function2) => function1.id === function2.id),
        )
        const mappedAddedPartners = addedFunctions.map(
          ({ id: functionId, name: functionName }) => ({
            party_id: currentInternalContact.userId,
            business_partner_function_code: functionId,
            business_partner_function_name: functionName,
          }),
        )
        return internalContactsToAdd.concat(mappedAddedPartners)
      }, []),
    [keyInternalContactsState, mappedKeyInternalContacts],
  )

  const partnersToDelete = useMemo(
    () =>
      mappedKeyInternalContacts.reduce((internalContactsToDelete, currentInternalContact) => {
        const matchingMappedContactFunctions =
          keyInternalContactsState.find(
            (mappedContact) => mappedContact.userId === currentInternalContact.userId,
          )?.functions ?? []
        const removedFunctions = currentInternalContact.functions.filter(
          (function1) =>
            !matchingMappedContactFunctions.some((function2) => function1.id === function2.id),
        )
        const mappedRemovedPartners = removedFunctions.map(({ id: functionCode }) => ({
          partnerId: currentInternalContact.userId,
          functionCode,
        }))
        return internalContactsToDelete.concat(mappedRemovedPartners)
      }, []),
    [keyInternalContactsState, mappedKeyInternalContacts],
  )

  // Add an empty key internal contact to the beginning of the array so they are shown first
  const addEmptyKeyInternalContact = () => {
    setKeyInternalContactsState([{ userId: '', functions: [] }, ...keyInternalContactsState])
  }

  const editKeyInternalContact = (keyInternalContactId, functions, index) => {
    const newKeyInternalContacts = [...keyInternalContactsState]
    newKeyInternalContacts[index] = {
      ...newKeyInternalContacts[index],
      userId: keyInternalContactId,
      functions,
    }
    setKeyInternalContactsState(newKeyInternalContacts)
  }

  const removeKeyInternalContact = (index) => {
    const keyInternalContact = keyInternalContactsState[index]
    editKeyInternalContact(keyInternalContact.userId, [], index)
  }

  const resetKeyInternalContacts = () => {
    setKeyInternalContactsState(mappedKeyInternalContacts)
  }

  const saveKeyInternalContacts = () => {
    updatePropertyPartnersMutation.mutate({
      partnersToPost: partnersToPost,
      partnersToDelete: partnersToDelete,
    })
  }

  return {
    keyInternalContacts: keyInternalContactsState,
    addEmptyKeyInternalContact,
    editKeyInternalContact,
    removeKeyInternalContact,
    saveKeyInternalContacts,
    resetKeyInternalContacts,
    isLoading,
    isErrorDialogOpen,
    setIsErrorDialogOpen,
    errorMessage,
  }
}

export default useHandlePropertyKeyInternalContacts
