import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useEditDecisionStageVoter = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(
    ({
      decisionStageId,
      eventId,
      voterId,
      id,
      voteTypeCode,
      voteStatusCode,
      declineReason,
      condition,
    }) =>
      patch({
        path: `/events/${eventId}/decision-stages/${decisionStageId}/voters/${id}`,
        body: {
          user_id: voterId,
          vote_type: voteTypeCode
            ? {
                code: voteTypeCode,
              }
            : undefined,
          vote_option: voteStatusCode,
          decline_reason: declineReason,
          condition,
        },
      }),
    mutationOptions,
  )
}

export default useEditDecisionStageVoter
