import { Button, ButtonDesign, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { conditionsStatusTypes } from 'api/conditions/conditions'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import useDeleteRequirements from 'hooks/services/conditions/requirements/useDeleteRequirements'
import { reset } from 'redux/slices/conditions/conditionsTableSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const atLeastOneRequirementInStatusDone = (requirements) =>
  Object.values(requirements).some(({ status }) => status === conditionsStatusTypes.done)

const DeleteSelectedRequirementsButton = ({ disabled }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.overview.buttons.delete-requirements',
  })
  const {
    entityRef: { entityId, entityType },
  } = useContext(ConditionsContext)
  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { mutate: deleteRequirements } = useDeleteRequirements()

  const selectedRequirementIds = useSelector(
    (state) => state.conditions.requirementsTable.selectedRows.selectedRequirementIds,
  )

  const numberOfSelectedRequirements = useMemo(
    () => Object.keys(selectedRequirementIds).length,
    [selectedRequirementIds],
  )

  const onDeleteSuccess = useCallback(() => {
    queryClient.invalidateQueries(['conditions', 'external', entityType, entityId])
    showToast({ children: t('success') })
    dispatch(reset())
  }, [queryClient, entityType, entityId, showToast, t, dispatch])

  const onDeleteError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('error'),
    })
  }, [showMessageBox, t])

  const deleteSelectedRequirements = useCallback(() => {
    const requirementIds = Object.keys(selectedRequirementIds)
    deleteRequirements({ requirementIds }, { onSuccess: onDeleteSuccess, onError: onDeleteError })
  }, [deleteRequirements, selectedRequirementIds, onDeleteSuccess, onDeleteError])

  const onDeleteButtonClick = useCallback(() => {
    showMessageBox({
      titleText: tNoPrefix('buttons.delete'),
      type: MessageBoxTypes.Warning,
      children: t('confirmation', { numberOfSelectedRequirements }),
      actions: [
        <Button
          key={'button_confirm'}
          design={ButtonDesign.Emphasized}
          onClick={deleteSelectedRequirements}
        >
          {tNoPrefix('buttons.delete')}
        </Button>,
        <Button
          key="button_cancel"
          data-action={MessageBoxActions.Cancel}
          design={ButtonDesign.Transparent}
        >
          {tNoPrefix('buttons.cancel')}
        </Button>,
      ],
    })
  }, [showMessageBox, t, tNoPrefix, deleteSelectedRequirements, numberOfSelectedRequirements])

  const isDeleteButtonDisabled = useMemo(
    () =>
      disabled ||
      isEmpty(selectedRequirementIds) ||
      atLeastOneRequirementInStatusDone(selectedRequirementIds),
    [disabled, selectedRequirementIds],
  )

  return (
    <Button
      disabled={isDeleteButtonDisabled}
      design={ButtonDesign.Transparent}
      onClick={onDeleteButtonClick}
    >
      {tNoPrefix('buttons.delete')}
    </Button>
  )
}

DeleteSelectedRequirementsButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
}

export default DeleteSelectedRequirementsButton
