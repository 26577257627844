import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import LimitBaseTable from 'components/domains/deals/covenants/limit-cards/LimitBaseTable'
import styles from 'components/domains/deals/covenants/limit-cards/display-tables/DisplayTableStyles.module.css'

import useFinancialColumns from 'components/domains/deals/covenants/limit-cards/useFinancialColumns'
import useFormatRelativeLimit from 'components/domains/deals/covenants/limit-cards/useFormatRelativeLimit'
import LimitCell from 'components/domains/deals/covenants/shared/LimitCell'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const FinancialLimitDisplayTable = ({ absoluteLimits = [], relativeLimits = [], actions = [] }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.limit-card',
  })

  const { format: formatDate } = useShortDateFormatter()
  const { format: formatRelativeLimit } = useFormatRelativeLimit()

  const isLimitAbsolute = absoluteLimits?.length > 0
  const limits = isLimitAbsolute ? absoluteLimits : relativeLimits

  const { columnDefinitions } = useFinancialColumns({ isEditMode: false })

  const tableData = limits?.map((limit) => ({
    rowKey: isLimitAbsolute ? limit.clvaId : limit.clvrId,
    covenantHoliday: {
      content: limit.isCovenantHoliday && t('covenant-holiday'),
      props: limit.isCovenantHoliday ? { className: styles.borderLeft } : undefined,
    },
    limit: {
      content: (
        <LimitCell value={limit.value} unit={limit.unit} currencyCode={limit.currencyCode} />
      ),
    },
    validFrom: {
      content: isLimitAbsolute
        ? formatDate(limit.validFrom)
        : formatRelativeLimit(limit.period, limit.validFrom),
    },
    validTo: {
      content: isLimitAbsolute
        ? formatDate(limit.validTo)
        : formatRelativeLimit(limit.period, limit.validTo),
    },
  }))

  return (
    <LimitBaseTable
      columnDefinitions={columnDefinitions}
      tableData={tableData}
      actions={actions}
      title={t('limits')}
    />
  )
}

export const limitShape = PropTypes.shape({
  clvrId: PropTypes.string,
  clvaId: PropTypes.string,
  currencyCode: PropTypes.string,
  isCovenantHoliday: PropTypes.bool,
  period: PropTypes.string,
  unit: PropTypes.string,
  validFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.number,
})

FinancialLimitDisplayTable.propTypes = {
  absoluteLimits: PropTypes.arrayOf(limitShape),
  relativeLimits: PropTypes.arrayOf(limitShape),
  actions: PropTypes.arrayOf(PropTypes.element),
}

export default FinancialLimitDisplayTable
