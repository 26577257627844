import { Table, TableCell, TableColumn, TableRow } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/manual-checksheet/BaselTwoManualCheckSheetTable.module.css'
import BaselTwoManualCheckSheetTableEditCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/manual-checksheet/BaselTwoManualCheckSheetTableEditCell'

const BaselTwoManualCheckSheetTableEdit = ({ currentAnswers, onChange }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.basel-two.checksheet.manual',
  })

  const onValuesChange = (question, newValue) => {
    const changedAnswersCopy = { ...currentAnswers }
    changedAnswersCopy[question] = newValue || null

    onChange(
      JSON.stringify({
        answers: changedAnswersCopy,
      }),
    )
  }

  return (
    <Table
      id="BaselTwoCheckSheetTable"
      noDataText={t('no-data')}
      className={styles.BaselTwoCheckSheetTable}
      columns={
        <>
          <TableColumn />
          <TableColumn />
        </>
      }
      hideNoData={true}
    >
      {Object.entries(currentAnswers || {}).map(([key, value]) => (
        <TableRow key={key}>
          <TableCell>{t(key)}</TableCell>
          <TableCell>
            <BaselTwoManualCheckSheetTableEditCell
              question={key}
              value={value}
              onChange={onValuesChange}
            />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}

BaselTwoManualCheckSheetTableEdit.propTypes = {
  currentAnswers: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
}

export default BaselTwoManualCheckSheetTableEdit
