import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTile.module.css'
import Card from 'components/ui/card/Card'

const DecisionPaperTile = ({ columnSpan, children, header, isExpanded }) => (
  <Card
    header={header}
    // Inline style because the columnSpan is returned by backend
    style={{ gridColumn: `span ${columnSpan}` }}
    className={isExpanded ? styles.expandedTile : styles.collapsedTile}
  >
    {isExpanded ? children : <></>}
  </Card>
)

DecisionPaperTile.propTypes = {
  columnSpan: PropTypes.number.isRequired,
  children: PropTypes.any.isRequired,
  header: PropTypes.any,
  isExpanded: PropTypes.bool.isRequired,
}

export default DecisionPaperTile
