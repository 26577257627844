import camelize from 'camelize'
import get from 'lodash.get'
import { useEffect, useMemo, useRef } from 'react'

export const useCamelizedResponse = (response) => {
  const data = useMemo(() => camelize(response.data), [response.data])
  return useMemo(() => {
    if (!response.data) return response
    return { ...response, data }
    // We only care about the error, data, loading and fetching states here since otherwise
    // the response object changes on each render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    response.isLoading,
    response.isError,
    response.isFetching,
    response.error,
    response.isFetchingNextPage,
  ])
}

export const useArrayMemo = (array) => {
  const ref = useRef([])

  const areArraysTheSame =
    ref.current &&
    array &&
    array.length === ref.current.length &&
    array.every((element, index) => element === ref.current[index])

  useEffect(() => {
    if (!areArraysTheSame) {
      ref.current = array
    }
  }, [areArraysTheSame, array])

  return areArraysTheSame ? ref.current : array
}

/**
 * WARN: **Wrap the result of `useRequests` directly with `useRequestsMemo`! Map afterwards!**
 * This also means, you'll have to use an additional `useMemo` when mapping!
 *
 * @example
 * ```js
 * const results = useRequestsMemo(useRequests(...))
 * return useMemo(() => results.map((result) => ({
 *     ...result,
 *     data: camelize(result.data),
 *   })),
 *   [results]
 * )
 * ```
 *
 * @param {import('@tanstack/react-query').UseQueryResult<unknown, unknown>[]} array
 */
export const useRequestsMemo = (array) => {
  const ref = useRef(
    /** @type {import('@tanstack/react-query').UseQueryResult<unknown, unknown>[]} */ ([]),
  )

  const areArraysTheSame =
    ref.current &&
    array &&
    array.length === ref.current.length &&
    array.every(
      (element, index) =>
        element.data === ref.current[index].data &&
        element.isLoading === ref.current[index].isLoading &&
        element.isError === ref.current[index].isError,
    )

  useEffect(() => {
    if (!areArraysTheSame) {
      ref.current = array
    }
  }, [areArraysTheSame, array])

  return areArraysTheSame ? ref.current : array
}

const reduceResponseDataWithTotal = (response, responseKey, totalKey) => {
  if (!response.data) return undefined
  return response.data.pages.reduce(
    (acc, currentResponsePageData) => ({
      [responseKey]: [...get(acc, responseKey, []), ...get(currentResponsePageData, responseKey)],
      total: get(currentResponsePageData, totalKey),
    }),
    {},
  )
}

export const useInfiniteRequestsWithTotalMemo = (response, responseKey, totalKey = 'total') =>
  useMemo(
    () => ({
      ...response,
      data: camelize(reduceResponseDataWithTotal(response, responseKey, totalKey)),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      response.data,
      response.isLoading,
      response.isFetching,
      response.isError,
      response.error,
      response.isFetchingNextPage,
      responseKey,
    ],
  )

/**
 * @param {import('@tanstack/react-query').UseQueryResult[]} queryResults
 * @param {object} options
 * @param {boolean} options.forceDataReturn
 */
export const useCombinedQueryResults = (queryResults, options = { forceDataReturn: false }) =>
  useMemo(() => {
    const isSuccess = queryResults.every((result) => result.isSuccess)
    const isLoading = queryResults.some((result) => result.isLoading)
    const data = queryResults.map((result) => result.data)

    return {
      isError: queryResults.some(({ isError }) => isError),
      isLoading,
      isFetching: queryResults.some(({ isFetching }) => isFetching),
      isSuccess,
      data: (options.forceDataReturn && !isLoading) || isSuccess ? data : undefined,
    }
  }, [options, queryResults])
