import { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { conditionTypes } from 'api/conditions/conditions'
import ConditionsExternalTable from 'components/domains/conditions/overview/ConditionsExternalTable'
import ConditionsInternalTable from 'components/domains/conditions/overview/ConditionsInternalTable'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useConditions from 'hooks/services/conditions/useConditions'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'
import styles from 'routes/conditions/ConditionsPage.module.css'

const getTableComponentForConditionType = (conditionType) => {
  if (conditionType === conditionTypes.external) {
    return ConditionsExternalTable
  }
  return ConditionsInternalTable
}

const businessTypeParam = 'con_businessType'

const ConditionsPage = () => {
  const {
    entityRef: { entityId, entityType },
  } = useContext(ConditionsContext)

  const [queryParams, setQueryParams] = useSearchParams()
  const currentTypeParam = queryParams.get(businessTypeParam)
  const currentType = currentTypeParam ? currentTypeParam : conditionTypes.external

  const { t } = useTranslation('translation', { keyPrefix: 'pages.conditions' })
  const [activeConditionType, setActiveConditionType] = useState(currentType)

  const {
    isLoading: isDealInternalConditionsLoading,
    isError: isDealInternalConditionsError,
    data: dealInternalConditions,
  } = useConditions(conditionTypes.internal, { entityId, entityType })
  const {
    isLoading: isDealExternalConditionsLoading,
    isError: isDealExternalConditionsError,
    data: dealExternaConditions,
  } = useConditions(conditionTypes.external, { entityId, entityType })

  const switchActiveConditionTypeSelection = useCallback(() => {
    setActiveConditionType((currentConditionType) => {
      if (currentConditionType === conditionTypes.external) {
        setQueryParams({
          ...Object.fromEntries(queryParams.entries()),
          con_businessType: conditionTypes.internal,
        })
        return conditionTypes.internal
      }
      setQueryParams({
        ...Object.fromEntries(queryParams.entries()),
        con_businessType: conditionTypes.external,
      })
      return conditionTypes.external
    })
  }, [queryParams, setQueryParams])

  const renderTable = useCallback(() => {
    const ConditionsTableComponent = getTableComponentForConditionType(activeConditionType)

    return (
      <ConditionsTableComponent
        totalNumberOfInternalConditions={dealInternalConditions.total}
        totalNumberOfExternalConditions={dealExternaConditions.total}
        switchActiveConditionSelection={switchActiveConditionTypeSelection}
        currentlySelectedConditionType={activeConditionType}
      />
    )
  }, [
    activeConditionType,
    dealInternalConditions,
    dealExternaConditions,
    switchActiveConditionTypeSelection,
  ])

  return (
    <>
      <Card>
        <div className={styles.tableWrapper}>
          <RequestStateResolver
            isLoading={isDealInternalConditionsLoading || isDealExternalConditionsLoading}
            isError={isDealInternalConditionsError || isDealExternalConditionsError}
            renderContent={renderTable}
            errorToDisplay={
              <ErrorDataUnavailableInContent
                titleText={t('error.title')}
                subtitleText={t('error.description')}
              />
            }
          />
        </div>
      </Card>
    </>
  )
}

ConditionsPage.propTypes = {}

export default ConditionsPage
