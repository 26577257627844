import { isMissingPermissionError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useSyndicationStatement = (dealId) => {
  const result = useCamelizedResponse(
    useRequest({
      path: `/syndication-structures/deals/${dealId}/syndication-statement`,
      keys: ['deals', dealId, 'syndicationStatement'],
      options: {
        enabled: !!dealId,
        retry: (_, error) => !isMissingPermissionError(error),
      },
    }),
  )

  return {
    ...result,
    data: result?.data,
  }
}
export default useSyndicationStatement
