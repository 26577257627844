import { Label, Text } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/details/deal-structure-tile/cells/shared/useI18n'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/details/shared/routes/deals/syndication/syndicationStructure/SyndicationStructureTableCell.module.css'

const CommitmentCell = ({ cell }) => {
  const { t: tNoPrefix } = useTranslation('decisionPaper')

  const formatMoney = useCustomizableCurrencyFormatter()
  const formatShare = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })

  const divClassNameList = useMemo(() => {
    if (cell?.value?.isBold) {
      return [styles.commitmentCell, styles.textBold]
    }
    return [styles.commitmentCell]
  }, [cell?.value?.isBold])

  const isSpacingForHQCurrencyNeeded = useMemo(
    () => cell?.row?.original?.isSpacingForHQCurrencyNeeded,
    [cell?.row?.original?.isSpacingForHQCurrencyNeeded],
  )

  const array = Array.isArray(cell.value) ? cell.value : [cell.value]

  return (
    <div className={divClassNameList.join(' ')}>
      {array.map((element, index) => {
        const { value, valueHeadquarter, share } = element ?? {}
        return (
          <div className={styles.commitmentCellSingleElement} key={index}>
            {!isNil(value) && (
              <div>
                <div className={styles.caretCursor}>
                  {formatMoney(value.amount, value.currency, {
                    currencyDisplay: 'code',
                  })}
                </div>
                {valueHeadquarter?.currency !== value?.currency && (
                  <div className={`${styles.textLabelColor} ${styles.caretCursor}`}>
                    {formatMoney(valueHeadquarter?.amount, valueHeadquarter?.currency, {
                      currencyDisplay: 'code',
                    })}
                  </div>
                )}
              </div>
            )}
            {isNil(value) && (
              <div>
                <div>&nbsp;</div>
                {isSpacingForHQCurrencyNeeded && <div>&nbsp;</div>}
              </div>
            )}

            {!isNil(share) && (
              <div className={styles.caretCursor}>
                <Label showColon>
                  {tNoPrefix('pages.deals.syndicationStructure-table.share-label')}
                </Label>
                &nbsp;
                <Text>{formatShare(share)}</Text>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

CommitmentCell.propTypes = {
  cell: PropTypes.any,
}

export default CommitmentCell
