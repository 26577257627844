import { MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { getObjectErrorForRequestCreation } from 'api/property/valuation/valuationRequests'
import MessageBoxWithExpandableDetailsShowDetails from 'components/ui/dialog/MessageBoxWithExpandableDetailsShowDetails'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'

const useShowVaulationRequestDialogError = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.valuation-requests.create-dialog.submit-error',
  })
  const showMessageBox = useShowMessageBox()

  return useCallback(
    (valuationRequestsResponses, properties, errorCode) => {
      const propertiesWithErrors = valuationRequestsResponses
        .filter(
          ({
            valuationRequest: {
              info: { propertyId },
            },
            errors,
          }) =>
            errors.some(({ message }) => message === errorCode) &&
            properties.some(({ propertyUuid }) => propertyUuid === propertyId),
        )
        .map(
          ({
            valuationRequest: {
              info: { propertyId },
            },
          }) => properties.find(({ propertyUuid }) => propertyUuid === propertyId),
        )

      if (propertiesWithErrors.length === 0) {
        return
      }

      const listedPropertiesWithErrors = (
        <ul>
          {propertiesWithErrors.map(({ propertyName, propertyUuid }) => (
            <li key={propertyUuid}>{propertyName}</li>
          ))}
        </ul>
      )

      showMessageBox({
        type: MessageBoxTypes.Error,
        children: (
          <>
            {t(getObjectErrorForRequestCreation(errorCode).translationKey)}
            <MessageBoxWithExpandableDetailsShowDetails
              messageDetails={listedPropertiesWithErrors}
            />
          </>
        ),
      })
    },
    [showMessageBox, t],
  )
}

export default useShowVaulationRequestDialogError
