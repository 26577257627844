import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js'
import { FlexBox, FlexBoxDirection, Input } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import RichTextTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/RichTextTile'
import DealDocumentSelection from 'components/domains/deals/DealDocumentSelection'
import { CardEditItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import ReferenceEntitySelect from 'components/domains/deals/covenants/create-dialog/ReferenceEntitySelect'
import styles from 'components/domains/deals/covenants/general-information/CovenantInformationEdit.module.css'
import CurrencySelect from 'components/domains/deals/covenants/shared/CurrencySelect'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import { referenceEntityToDataSource } from 'components/ui/data/ReferenceEntities'
import { ReferenceEntityType } from 'components/ui/data/ReferenceEntityType'
import TextAreaWithMaxCharacterRestriction from 'components/ui/input/TextAreaWithMaxCharacterRestriction'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { standardMappings, useFormMapped } from 'hooks/form/useFormMapped'
import { useFormValidations } from 'hooks/form/useFormValidations'
import { useFormattedNumberParser, useNumberFormatter } from 'hooks/i18n/useI18n'
import useTranches from 'hooks/services/deals/covenants/useTranches'
import { DealContext } from 'routes/deals/DealContext'

const descriptionMaxLength = 500
const commentMaxLength = 1000
const referenceClauseMaxLength = 200

const capitalizeFirstLetter = (value) => value?.[0]?.toUpperCase() + value?.slice(1)?.toLowerCase()

const CovenantInformationEdit = ({
  covenant: {
    covenantUuid,
    covenantDefinition = {},
    description,
    currencyCode,
    status,
    referenceEntity: { type: defaultReferenceEntityType, ids } = {},
    loanSizing = {},
    comment,
    referenceClause,
  } = {},
  onSelectedDocumentsChanged: onSelectedDocumentsChangedParent = () => {}, // ({allDocIDsToBeLinked: [], allDocIDsToBeUnlinked: []})=>{...}
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.covenants' })
  const { deal = {} } = useContext(DealContext)
  const [isDefaultApplied, setIsDefaultApplied] = useState(false)

  const formatNumber = useNumberFormatter({ maximumFractionDigits: 2, minimumFractionDigits: 2 })
  const parseNumber = useFormattedNumberParser()

  const { required } = useFormValidations()
  const { register, watch, control, setValue, getValues, trigger } = useFormMapped()

  const [currentCommentLengthExcludingHTML, setCurrentCommentLengthExcludingHTML] = useState(0)

  const referenceEntityType = watch('referenceEntity.type')

  const dataSource =
    referenceEntityToDataSource.find(({ key }) => referenceEntityType === key)?.dataSource ?? null
  const {
    isLoading: isLoadingTranches,
    isFetching: isFetchingTranches,
    isError: isErrorTranches,
    data: { tranches = [] } = {},
  } = useTranches({
    dealUuid: deal.dealUuid,
    dataSource,
  })

  const referenceEntityTypeData = {
    referenceEntityTypes: [
      { key: ReferenceEntityType.TrancheNewBusiness, value: t('tranche-new-business') },
      { key: ReferenceEntityType.TrancheExistingBusiness, value: t('tranche-existing-business') },
      { key: ReferenceEntityType.BusinessPartner, value: t('business-partner') },
    ],
  }

  useEffect(() => {
    if (isDefaultApplied) return
    // manual validation-trigger of required fields to circumvent validation-issues after destroying and recreating the component
    setValue('covenantUuid', covenantUuid)
    if (description) {
      setValue('description', description)
      trigger('description')
    }
    if (currencyCode) {
      setValue('currencyCode', currencyCode)
      trigger('currencyCode')
    }
    if (ids) {
      setValue('referenceEntity.ids', ids)
      trigger('referenceEntity.ids')
    }
    if (defaultReferenceEntityType) {
      setValue('referenceEntity.type', defaultReferenceEntityType)
      trigger('referenceEntity.type')
    }
    if (loanSizing?.value) setValue('loanSizing.value', loanSizing.value)
    setValue('comment', comment)
    setValue('referenceClause', referenceClause)
    setIsDefaultApplied(true)
  }, [
    setValue,
    covenantUuid,
    description,
    currencyCode,
    comment,
    referenceClause,
    isDefaultApplied,
    setIsDefaultApplied,
    ids,
    loanSizing.value,
    loanSizing.threshold,
    defaultReferenceEntityType,
    trigger,
  ])

  const isUnitCurrency = covenantDefinition?.unit === 'CURRENCY'
  const isCovenantFinancial = covenantDefinition.type === 'FINANCIAL'
  const selectedCurrencyCode = watch('currencyCode')

  return (
    <form onSubmit={(event) => event.preventDefault()}>
      <CardSection>
        <CardEditItem
          label={t('covenant')}
          value={<Input disabled value={covenantDefinition?.name} />}
        />
        <CardEditItem
          label={t('covenant-id')}
          value={
            <Input
              disabled
              {...register('covenantUuid', {
                ...standardMappings.NativeInput,
              })}
            />
          }
        />
        <CardEditItem
          label={t('type')}
          value={
            <Input
              id="covenant-type"
              disabled
              value={isCovenantFinancial ? t('financial') : t('non-financial')}
            />
          }
        />
        <CardEditItem
          label={t('create.description')}
          value={
            <div>
              <TextAreaWithMaxCharacterRestriction
                id="description"
                rows="4"
                maxLength={descriptionMaxLength}
                {...register('description', {
                  handlerMappings: {
                    onChange: (handler) => ({ onInput: (_, event) => handler(event) }),
                  },
                })}
              />
            </div>
          }
        />
        {isCovenantFinancial && (
          <CardEditItem
            label={t('create.unit')}
            required={isUnitCurrency}
            value={
              isUnitCurrency ? (
                <CurrencySelect registerName="currencyCode" />
              ) : (
                <Input id="percentage-input" disabled value={t('unit.percent')} />
              )
            }
          />
        )}
        <CardEditItem
          label={t('status')}
          value={<Input disabled value={capitalizeFirstLetter(status)} />}
        />
        <CardEditItem
          label={t('reference-entity-type')}
          required
          value={
            <LoadingSelect
              id="reference-entity-type"
              loadingHook={() => ({
                isLoading: false,
                isError: false,
                data: referenceEntityTypeData,
              })}
              selectionName="referenceEntityTypes"
              optionKeyName="key"
              optionDisplayName="value"
              {...register('referenceEntity.type', {
                ...standardMappings.LoadingSelect,
                validate: {
                  required: required(),
                },
                handlerMappings: {
                  ...standardMappings.LoadingSelect.handlerMappings,
                  onChange: () => ({
                    onChange: async (e, value) => {
                      await setValue(e.target.name, value)
                      await setValue('referenceEntity.ids', [])
                      await trigger(e.target.name)
                    },
                  }),
                },
              })}
            />
          }
        />
        <CardEditItem
          label={t('reference-entity')}
          required
          value={
            <ReferenceEntitySelect
              referenceEntityType={referenceEntityType}
              tranches={tranches}
              isLoadingTranches={isLoadingTranches && isFetchingTranches}
              isErrorTranches={isErrorTranches}
            />
          }
        />
        {isCovenantFinancial && (
          <CardEditItem
            label={t('loan-sizing')}
            value={
              <FlexBox direction={FlexBoxDirection.Row}>
                <Controller
                  control={control}
                  name="loanSizing.value"
                  shouldUnregister={true}
                  render={({ field: { name } }) => (
                    <Input
                      id="loansizing-value"
                      required
                      {...register(name, {
                        setValueAs: (v) => parseNumber(v),
                        displayAs: (v) => (typeof v === 'number' ? formatNumber(v) : ''),
                      })}
                      className={styles.valueInput}
                    />
                  )}
                />
                <Input
                  id="disabled-unit"
                  className={isUnitCurrency ? styles.unitInput : styles.smallInputWidth}
                  disabled
                  value={isUnitCurrency ? selectedCurrencyCode : t('unit.percent')}
                />
              </FlexBox>
            }
          />
        )}
        <CardEditItem
          label={t('create.document')}
          value={
            <DealDocumentSelection
              dealId={deal.displayId}
              dealUuid={deal.dealUuid}
              dealName={deal.name}
              isReadOnly={false}
              filterForEntityRef={{
                type: DocumentTypes.Covenant,
                id: covenantUuid,
              }}
              onSelectedDocumentsChanged={onSelectedDocumentsChangedParent}
            />
          }
        />
        <CardEditItem
          label={t('create.reference-clause')}
          value={
            <div>
              <TextAreaWithMaxCharacterRestriction
                id="referenceClause"
                rows="4"
                maxLength={referenceClauseMaxLength}
                {...register('referenceClause', {
                  handlerMappings: {
                    onChange: (handler) => ({ onInput: (_, event) => handler(event) }),
                  },
                })}
              />
            </div>
          }
        />
      </CardSection>

      <CardSection title={t('comment')}>
        <RichTextTile
          id="comment"
          isEditMode={true}
          maxCharacterAmount={commentMaxLength}
          onUsedCharactersChanged={(usedCharactersExcludingHTML) => {
            setCurrentCommentLengthExcludingHTML(usedCharactersExcludingHTML)
          }}
          currentContent={getValues('comment')}
          {...register('comment', {
            validate: (_) => currentCommentLengthExcludingHTML <= commentMaxLength,
            handlerMappings: {
              ref: null,
            },
          })}
          onChange={(value) => {
            setValue('comment', value)
            trigger('comment')
          }}
          isPdfView={false}
        />
      </CardSection>
    </form>
  )
}

CovenantInformationEdit.propTypes = {
  covenant: PropTypes.shape({
    covenantUuid: PropTypes.string,
    covenantDefinition: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      unit: PropTypes.string,
    }),
    description: PropTypes.string,
    currencyCode: PropTypes.string,
    status: PropTypes.string,
    referenceEntity: PropTypes.shape({
      ids: PropTypes.arrayOf(PropTypes.string),
      type: PropTypes.string,
    }),
    loanSizing: PropTypes.shape({
      threshold: PropTypes.string,
      value: PropTypes.number,
    }),
    comment: PropTypes.string,
    referenceClause: PropTypes.string,
  }),
  onSelectedDocumentsChanged: PropTypes.func, // ({allDocIDsToBeLinked: [], allDocIDsToBeUnlinked: []})=>{...}
}

export default CovenantInformationEdit
