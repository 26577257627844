import { useContext } from 'react'
import CardSection from 'components/domains/deals/card/CardSection'
import DealAdjustmentHistoryTable from 'components/domains/deals/change-overview/adjustments-history-card/DealAdjustmentHistoryTable'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useAdjustments } from 'hooks/services/deals/deal-adjustment/useAdjustments'
import { DealContext } from 'routes/deals/DealContext'

const DealAdjustmentHistory = () => {
  const {
    deal: { dealUuid: dealUuid },
  } = useContext(DealContext)

  const {
    data: adjustmentData,
    isLoading: isAdjustmentsLoading,
    isFetching: isAdjustmentsFetching,
    isError: isAdjustmentsError,
  } = useAdjustments({ dealUuid, includeWarnings: true, enabled: !!dealUuid })

  return (
    <Card>
      <CardSection>
        <RequestStateResolver
          center
          isLoading={isAdjustmentsLoading && isAdjustmentsFetching}
          isError={isAdjustmentsError}
          errorToDisplay={<ErrorDataUnavailableInContent />}
          renderContent={() => (
            <DealAdjustmentHistoryTable adjustments={adjustmentData?.adjustments} />
          )}
        />
      </CardSection>
    </Card>
  )
}

DealAdjustmentHistory.propTypes = {}

export default DealAdjustmentHistory
