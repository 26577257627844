import { useMemo } from 'react'
import { conditionsEntityTypes } from 'api/conditions/conditions'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useTileMetaData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/meta-data/useTileMetaData'
import useAssigneeNameResolver from 'hooks/services/conditions/assignee/useAssigneeNameResolver'
import useExternalStatus from 'hooks/services/conditions/config/useExternalStatus'
import useExternalConditionsReferencesResolver from 'hooks/services/conditions/reference-selection/useExternalConditionsReferencesResolver'
import useExternalConditionsUuids from 'hooks/services/conditions/useExternalConditionsUuids'
import useDealMini from 'hooks/services/deals/useDealMini'

const resourcePathPartMapping = {
  DEAL: 'deals',
  BUSINESS_PARTNER: 'business-partners',
}

const useExternalConditions = ({ id: eventId, entityRef: { entityType, entityId } }, tileId) => {
  const isDeal = entityType === conditionsEntityTypes.deal

  const {
    data: dealData,
    isFetching: isDealFetching,
    isError: isDealError,
    error: dealError,
  } = useDealMini(entityId, { enabled: isDeal })

  const {
    data: tileMetadata,
    isFetching: isTileMetadataFetching,
    isError: isTileMetadataError,
    error: tileMetadataError,
  } = useTileMetaData({ eventId, tileId })

  const selectedConditionUuids = Object.keys(tileMetadata?.meta_data?.selectedConditions || {})

  const {
    data: selectedConditions,
    isFetching: isSelectedConditionsFetching,
    isError: isSelectedConditionsError,
    error: selectedConditionsError,
  } = useExternalConditionsUuids(
    {
      externalConditionUuids: selectedConditionUuids,
      entityId,
      entityType,
    },
    {
      enabled:
        !isTileMetadataFetching &&
        !isTileMetadataError &&
        !!tileMetadata?.meta_data?.selectedConditions &&
        selectedConditionUuids.length !== 0,
    },
  )

  const isReferenceResolvingEnabled =
    !isTileMetadataFetching &&
    !isTileMetadataError &&
    !!tileMetadata?.meta_data?.selectedConditions &&
    selectedConditionUuids.length !== 0 &&
    !isSelectedConditionsFetching &&
    !isSelectedConditionsError &&
    selectedConditions?.externalConditions?.length !== 0

  const {
    mappedSelectedConditions: mappedSelectedConditionsWithResolvedReferences,
    isLoading: isReferencesFetching,
    isError: isReferencesError,
  } = useExternalConditionsReferencesResolver({
    selectedConditions: selectedConditions?.externalConditions,
    enabled: isReferenceResolvingEnabled,
  })

  const {
    mappedSelectedConditions: mappedSelectedConditionsWithResolvedReferencesAndAssigneeName,
    isFetching: isAssigneeFetching,
    isError: isAssigneeError,
  } = useAssigneeNameResolver({
    selectedConditions: mappedSelectedConditionsWithResolvedReferences,
  })

  const {
    data: statusValuesData,
    isFetching: isStatusValuesFetching,
    isError: isStatusValuesError,
    error: statusValuesError,
  } = useExternalStatus({
    includeDeprecated: false,
    entityType,
  })

  const sourceRender = useMemo(
    () => ({
      displayId: isDeal ? dealData?.dealId : entityId,
      resourcePathPart: resourcePathPartMapping[entityType],
    }),
    [dealData?.dealId, entityId, entityType, isDeal],
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isDeal && isDealFetching,
      isTileMetadataFetching,
      isSelectedConditionsFetching,
      isReferencesFetching,
      isAssigneeFetching,
      isStatusValuesFetching,
    ],
    errorValues: [
      isDeal && isDealError,
      isTileMetadataError,
      isSelectedConditionsError,
      isReferencesError,
      isAssigneeError,
      isStatusValuesError,
    ],
    errorDetails: [dealError, tileMetadataError, selectedConditionsError, statusValuesError],
    tileId,
  })

  const result = useMemo(() => {
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }
    if (
      !isTileMetadataFetching &&
      !isTileMetadataError &&
      (!tileMetadata?.meta_data ||
        !tileMetadata.meta_data.selectedConditions ||
        selectedConditionUuids.length === 0)
    ) {
      return {
        isLoading: false,
        isError: false,
        data: { selectedConditions: [], sourceRender, entityId },
      }
    }

    if (isSomeValueError) {
      return {
        isLoading: false,
        isError: true,
        error,
      }
    }

    // Everything has loaded --> return the external conditions
    return {
      isLoading: false,
      isError: false,
      data: {
        selectedConditions: mappedSelectedConditionsWithResolvedReferencesAndAssigneeName,
        sourceRender,
        entityId,
        statusValues: statusValuesData?.status,
      },
    }
  }, [
    isTileMetadataFetching,
    isTileMetadataError,
    tileMetadata?.meta_data,
    selectedConditionUuids?.length,
    isSomeValueError,
    isSomeValueLoading,
    mappedSelectedConditionsWithResolvedReferencesAndAssigneeName,
    sourceRender,
    entityId,
    error,
    statusValuesData?.status,
  ])

  return result
}

export default useExternalConditions
