import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * Returns all external id types
 * @returns {{isLoading: boolean, isError: boolean, data: ExternalIdTypes}}
 *
 */
export const useExternalIdTypes = () =>
  useCamelizedResponse(
    useRequest({
      path: `/properties/external-id-types`,
      translated: true,
    }),
  )
