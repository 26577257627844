import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import LinkWithId from 'components/domains/business-events-and-tasks/LinkWithId'
import DecisionProcessLabeledLoadingContent from 'components/domains/business-events-and-tasks/decision-process/alc/helper/DecisionProcessLabeledLoadingContent'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import paths from 'routes/paths'

const DecisionProcessAlcBorrower = ({ borrowerBpId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.overview.alc-info.dialog',
  })
  const businessPartnerResponse = useBusinessPartnerById(borrowerBpId)
  const renderBorrower = useCallback((borrower) => {
    const link = `/${paths.businessPartners}/${borrower.id}`
    return <LinkWithId href={link} name={borrower.fullName} id={borrower.id} />
  }, [])

  return (
    <DecisionProcessLabeledLoadingContent
      response={businessPartnerResponse}
      renderCallback={renderBorrower}
      label={t('fields.borrower')}
    />
  )
}

DecisionProcessAlcBorrower.propTypes = {
  borrowerBpId: PropTypes.string.isRequired,
}

export default DecisionProcessAlcBorrower
