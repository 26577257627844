import isEmpty from 'lodash.isempty'
import maxBy from 'lodash.maxby'
import { useMemo } from 'react'
import { assessmentTypes } from 'components/domains/business-partners/tile/assessments/assessmentTypes'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useTextualAssessments } from 'hooks/services/business-partners/assessments/useTextualAssessments'

const useBusinessPartnerGeneralDescription = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: textualAssessmentData,
    isLoading: isTextualAssessmentLoading,
    isError: isTextualAssessmentError,
    error: textualAssessmentError,
  } = useTextualAssessments(
    { businessPartnerId, type: assessmentTypes.Description },
    { enabled: !!businessPartnerId },
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isTextualAssessmentLoading],
    errorValues: [isTextualAssessmentError],
    errorDetails: [textualAssessmentError],
    tileId,
  })

  const generalDescription = useMemo(() => {
    if (isEmpty(textualAssessmentData?.availableVersions)) {
      return undefined
    }
    const { availableVersions } = textualAssessmentData
    // Sort descending by version number to return the content with highest version
    const latestAssessment = maxBy(availableVersions, 'version')
    return latestAssessment?.content
  }, [textualAssessmentData])

  return useMemo(() => {
    if (isSomeValueError || isSomeValueLoading) {
      return {
        isLoading: isSomeValueLoading,
        isError: isSomeValueError,
        error,
      }
    }

    return {
      isLoading: isSomeValueLoading,
      isError: isSomeValueError,
      data: {
        sourceRender: { businessPartnerId },
        generalDescription,
      },
    }
  }, [generalDescription, businessPartnerId, error, isSomeValueError, isSomeValueLoading])
}

export default useBusinessPartnerGeneralDescription
