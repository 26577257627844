import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TextField from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/fields/TextField'

const OpsMemoDealTeamInformationTile = ({ tileId }) => {
  const tileData = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const { dealInformationData } = tileData?.data || {}

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.deal-team.information',
  })

  return (
    <>
      <TextField
        value={dealInformationData?.platform ?? '-'}
        label={t('origination-platform')}
        isFirstItem={true}
      />
      <TextField
        value={dealInformationData?.name ?? '-'}
        label={t('origination-team')}
        isFirstItem={false}
      />
    </>
  )
}

OpsMemoDealTeamInformationTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default OpsMemoDealTeamInformationTile
