import { useMemo } from 'react'

export const calculateShare = (ownAmount, totalAmount) => ownAmount / totalAmount

export const mapShare = (sanitizedTotalSourcesAmount) => (value) => ({
  ...value,
  share: calculateShare(value.headquarter?.value, sanitizedTotalSourcesAmount),
})

const useMappedSources = ({
  mappedTranchesValues,
  mappedSyndicationValues,
  mappedOtherSources,
  mappedEquity,
  headquarterCurrency,
  totalSourcesAmount,
  totalSourcesAmountHeadquarter,
  availableCurrencies,
  areAllCurrenciesTheSame,
}) =>
  useMemo(() => {
    const result = []

    const sanitizedTotalSourcesAmountHeadquarter =
      totalSourcesAmountHeadquarter === 0 ? Number.NaN : totalSourcesAmountHeadquarter

    if (mappedTranchesValues) {
      const mappedTranchesValuesWithShare = {
        ...mappedTranchesValues,
        share: calculateShare(
          mappedTranchesValues.headquarter.value,
          sanitizedTotalSourcesAmountHeadquarter,
        ),
      }
      result.push(mappedTranchesValuesWithShare)
    }

    const mappedSyndicationValuesWithShare = (
      mappedSyndicationValues ? mappedSyndicationValues : []
    ).map(mapShare(sanitizedTotalSourcesAmountHeadquarter))

    const mappedOtherSourcesWithShare = (mappedOtherSources ? mappedOtherSources : []).map(
      mapShare(sanitizedTotalSourcesAmountHeadquarter),
    )

    result.push(...mappedSyndicationValuesWithShare)
    result.push(...mappedOtherSourcesWithShare)

    if (mappedEquity) {
      const mappedEquityWithShare = {
        ...mappedEquity,
        share: calculateShare(
          mappedEquity.headquarter.value,
          sanitizedTotalSourcesAmountHeadquarter,
        ),
      }
      result.push(mappedEquityWithShare)
    }

    const totalValueOriginal = {
      currency: availableCurrencies[0],
      value: totalSourcesAmount,
    }
    const totalValueHeadquarter = {
      currency: headquarterCurrency,
      value: totalSourcesAmountHeadquarter,
    }

    result.push({
      isSectionHeader: true,
      original: areAllCurrenciesTheSame ? totalValueOriginal : totalValueHeadquarter,
      headquarter: areAllCurrenciesTheSame ? totalValueOriginal : totalValueHeadquarter,
      share: 1,
    })

    return result
  }, [
    mappedTranchesValues,
    mappedSyndicationValues,
    mappedOtherSources,
    mappedEquity,
    headquarterCurrency,
    totalSourcesAmount,
    availableCurrencies,
    totalSourcesAmountHeadquarter,
    areAllCurrenciesTheSame,
  ])

export default useMappedSources
