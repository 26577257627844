import { useCallback, useEffect } from 'react'
import { useDispatch, useStore } from 'react-redux'
import { TILE_VERSION as availableTileVersions } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import {
  collapseTile,
  initializeTile,
  tileDataLoaded,
  tileHasError,
  tileIsLoading,
  tilesAreLoading,
  tilesFinishedLoading,
  noTilesHaveTechnicalError,
  someTilesHaveTechnicalError,
  noTilesHavePermissionError,
  someTilesHavePermissionError,
} from 'redux/slices/decision-paper/tilesOverviewSlice'

const useReduxTileLoaderEffects = ({
  tileId,
  isDefaultExpanded,
  isLoading,
  isError,
  error,
  data,
  type,
  isEditableInMinorVersion,
  tileVersion = availableTileVersions.V1,
}) => {
  const dispatch = useDispatch()
  const store = useStore()

  const allTilesInitialized = store.getState().decisionPaper.tilesOverview.allTilesInitialized

  const handleAllTilesFinishedLoading = useCallback(() => {
    const tiles = store.getState().decisionPaper.tilesOverview.tiles
    if (Object.values(tiles).some((tile) => tile.isLoading)) return

    dispatch(tilesFinishedLoading())
  }, [store, dispatch])

  const handleNoTilesHaveTechnicalError = useCallback(() => {
    const someTilesHaveTechnicalErrorData =
      store.getState().decisionPaper.tilesOverview.someTilesHaveTechnicalError
    if (!someTilesHaveTechnicalErrorData) return

    const tiles = store.getState().decisionPaper.tilesOverview.tiles
    if (Object.values(tiles).some((tile) => tile.isError)) return

    dispatch(noTilesHaveTechnicalError())
  }, [dispatch, store])

  const handleNoTilesHavePermissionError = useCallback(() => {
    const someTilesHavePermissionErrorData =
      store.getState().decisionPaper.tilesOverview.someTilesHavePermissionError
    if (!someTilesHavePermissionErrorData) return

    const tiles = store.getState().decisionPaper.tilesOverview.tiles
    if (Object.values(tiles).some((tile) => tile.isError && tile.error.isAccessDeniedError)) return

    dispatch(noTilesHavePermissionError())
  }, [dispatch, store])

  const handleSomeTilesAreLoading = useCallback(() => {
    const someTilesAreLoading = store.getState().decisionPaper.tilesOverview.someTilesAreLoading
    if (!someTilesAreLoading) {
      dispatch(tilesAreLoading())
    }
  }, [store, dispatch])

  const handleSomeTilesHaveTechnicalError = useCallback(
    (tileError) => {
      if (!!tileError && tileError.isAccessDeniedError) return

      const someTilesHaveTechnicalErrorData =
        store.getState().decisionPaper.tilesOverview.someTilesHaveTechnicalError
      if (someTilesHaveTechnicalErrorData) return

      dispatch(someTilesHaveTechnicalError())
    },
    [store, dispatch],
  )

  const handleSomeTilesHavePermissionError = useCallback(
    (tileError) => {
      if (!tileError?.isAccessDeniedError) return

      const someTilesHavePermissionErrorData =
        store.getState().decisionPaper.tilesOverview.someTilesHavePermissionError
      if (someTilesHavePermissionErrorData) return

      dispatch(someTilesHavePermissionError())
    },
    [store, dispatch],
  )

  useEffect(() => {
    /* allTilesInitialized is inside this dependency array to enter the useEffect again after switching decision paper sync, 
       to reduce unnecessary operations return instantly when all tiles are initialized */
    if (allTilesInitialized) return

    const tileData = store.getState().decisionPaper.tilesOverview.tiles[tileId]
    if (!tileData) {
      dispatch(initializeTile({ tileId, type }))
      if (!isDefaultExpanded) {
        dispatch(collapseTile(tileId))
      }
    }
  }, [tileId, isDefaultExpanded, type, allTilesInitialized]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLoading) {
      dispatch(tileIsLoading(tileId))
      handleSomeTilesAreLoading()
    }
  }, [isLoading, tileId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isError) {
      dispatch(tileHasError({ id: tileId, error }))
      handleSomeTilesHaveTechnicalError(error)
      handleSomeTilesHavePermissionError(error)
      handleAllTilesFinishedLoading()
    }
  }, [isError, error, tileId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      dispatch(tileDataLoaded({ id: tileId, data, isEditableInMinorVersion, version: tileVersion }))
      handleAllTilesFinishedLoading()
      // following 2 lines are needed because some tiles first enter a state with isError = true and then the tileDataLoaded above sets isError back to false
      handleNoTilesHaveTechnicalError()
      handleNoTilesHavePermissionError()
    }
  }, [data, tileId]) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useReduxTileLoaderEffects
