import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useEditRequirement = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(
    async ({ conditionId, requirementId, requirement }) =>
      patch({
        path: `/conditions/external/${conditionId}/requirements/${requirementId}`,
        body: snakecaseKeys(requirement),
      }),
    mutationOptions,
  )
}

export default useEditRequirement
