import {
  Button,
  ButtonDesign,
  Dialog,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  MessageBoxTypes,
  Modals,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-process/stages/DecisionProcessCreateStageDialog.module.css'
import DecisionProcessStageSelect, {
  noSelectionCode,
} from 'components/domains/business-events-and-tasks/decision-process/stages/DecisionProcessStageSelect'
import useCreateDecisionStage from 'hooks/services/business-events-and-tasks/decision-process/stages/useCreateDecisionStage'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionProcessCreateStageDialog = ({
  isOpen,
  setIsOpen,
  headerText,
  submitButtonText,
  successNotification,
}) => {
  const { t: tNoPrefix } = useTranslation('translation')
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.create-stage',
  })
  const queryClient = useQueryClient()
  const { id: eventId } = useContext(BusinessEventsAndTasksContext).event
  const [selectedStage, setSelectedStage] = useState(noSelectionCode)
  const showMessageBox = Modals.useShowMessageBox()
  const showToast = Modals.useShowToast()
  const { mutate: addDecisionStage } = useCreateDecisionStage()

  const saveButtonDisabled = useMemo(() => selectedStage === noSelectionCode, [selectedStage])

  const handleSuccess = useCallback(() => {
    showToast({ children: successNotification })
    queryClient.invalidateQueries(['events', eventId, 'decision-stages'])
    setIsOpen(false)
  }, [showToast, successNotification, queryClient, eventId, setIsOpen])

  const handleError = useCallback(() => {
    showMessageBox({ type: MessageBoxTypes.Error, children: t('error.description') })
  }, [showMessageBox, t])

  const handleCreateButtonClicked = useCallback(() => {
    addDecisionStage(
      { eventId, stageTemplateId: selectedStage },
      { onSuccess: handleSuccess, onError: handleError },
    )
  }, [addDecisionStage, eventId, selectedStage, handleError, handleSuccess])

  const handleCancelButtonClicked = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const handleDialogClosed = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const footer = useMemo(
    () => (
      <FlexBox
        className={styles.footerContainer}
        direction={FlexBoxDirection.Row}
        alignItems={FlexBoxAlignItems.Center}
        justifyContent={FlexBoxJustifyContent.End}
      >
        <Button
          design={ButtonDesign.Emphasized}
          onClick={handleCreateButtonClicked}
          disabled={saveButtonDisabled}
        >
          {submitButtonText}
        </Button>
        <Button
          className={styles.cancelButton}
          design={ButtonDesign.Transparent}
          onClick={handleCancelButtonClicked}
        >
          {tNoPrefix('buttons.cancel')}
        </Button>
      </FlexBox>
    ),
    [
      saveButtonDisabled,
      submitButtonText,
      handleCreateButtonClicked,
      handleCancelButtonClicked,
      tNoPrefix,
    ],
  )

  return createPortal(
    <Dialog
      resizable
      draggable
      className={styles.dialog}
      headerText={headerText}
      footer={footer}
      open={isOpen}
      onAfterClose={handleDialogClosed}
    >
      <DecisionProcessStageSelect
        value={selectedStage}
        onChange={({ code }) => {
          setSelectedStage(code)
        }}
      />
    </Dialog>,
    document.body,
  )
}

DecisionProcessCreateStageDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  successNotification: PropTypes.string.isRequired,
}

export default DecisionProcessCreateStageDialog
