import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import AssetValuationOverviewTileViewModeCurrentVersion from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/asset-list/AssetValuationOverviewTileViewModeCurrentVersion'
import AssetValuationOverviewTileViewModeV1 from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/asset-list/AssetValuationOverviewTileViewModeV1'
import sortTableDataByMarketValueAndDescription from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/asset-list/shared/sortTableDataByMarketValueAndDescription'
import { useEnrichPropertyWithStatus } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/asset-list/useEnrichPropertyWithStatus'
import { TILE_VERSION as tileVersion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'

const GREEN_DATA_KEYS = [
  'YY_QUALIFIED_FOR_GREEN_BOND',
  'YY_PBB_GREEN_FW_LOAN_ELIGIB',
  'YY_PBB_GREEN_BOND_USAGE',
  'YY_PBB_GREEN_SCORE',
  'YY_EPC_CLASS',
  'YY_CO2_EMISSION',
  'YY_EU_TAXONOMY_ELIGIBLE',
]

const GREEN_DATA_CLASS_KEY = 'YY_EPC_CLASS'

const MultipleAssetValuationOverviewTile = ({ tileId, isPdfView, selectedDealIndex }) => {
  const { data: { assetValuationOverviewData, dealsData } = [], version } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId],
  )

  const selectedDealUuid = dealsData[selectedDealIndex]?.dealUuid

  const filteredData = useMemo(
    () => assetValuationOverviewData?.filter((item) => item?.dealUuid === selectedDealUuid),
    [assetValuationOverviewData, selectedDealUuid],
  )[0]

  const propertiesWithStatus = useEnrichPropertyWithStatus(filteredData?.properties ?? [])

  return useMemo(() => {
    const multiPropertyKpis = {
      requestedKeyDate:
        filteredData?.multiPropertyKpis?.keyDateToMultiPropertyRentRollKpis[0]?.requestedKeyDate,
      keyDate: filteredData?.multiPropertyKpis?.keyDateToMultiPropertyRentRollKpis[0]?.keyDate,
      ...filteredData?.multiPropertyKpis?.keyDateToMultiPropertyRentRollKpis[0]?.kpis,
    }

    const propertyValuations = filteredData?.propertyValuations?.valuations ?? {}
    const propertyValuationSums = filteredData?.propertyValuations?.sum ?? {}

    const marketValue = {
      number: 0,
      currency: '',
    }

    const mortgageLendingValue = {
      number: 0,
      currency: '',
    }

    const purchasePrice = {
      number: 0,
      currency: '',
    }

    const valuationsProperties = []
    for (const [key, value] of Object.entries(propertyValuations)) {
      valuationsProperties.push({
        uuid: key,
        valuations: value,
      })

      marketValue.number += ~~value?.marketValue?.valueAmount?.number
      marketValue.currency = value?.marketValue?.valueAmount?.currency

      mortgageLendingValue.number += ~~value?.mortgageLendingValue?.valueAmount?.number
      mortgageLendingValue.currency = value?.mortgageLendingValue?.valueAmount?.currency

      purchasePrice.number += ~~value?.purchasePrice?.valueAmount?.number
      purchasePrice.currency = value?.purchasePrice?.valueAmount?.currency
    }

    const tableData = propertiesWithStatus?.map((property) => {
      const tegovaRating = filteredData?.tegovaRatings?.find(
        ({ propertyUuid }) => property?.uuid === propertyUuid,
      )

      const annualRentalIncome = property?.actualRentAmount?.number

      const valuation = valuationsProperties?.find(
        ({ uuid }) => uuid.toLowerCase() === property.uuid.replace(/-/g, '').toLowerCase(),
      )?.valuations

      const valuationMarketValue = valuation?.marketValue?.valueAmount?.number

      const multiplier =
        annualRentalIncome && valuationMarketValue ? valuationMarketValue / annualRentalIncome : 0
      const initialYield = multiplier ? 1 / multiplier : 0

      const customFields =
        filteredData?.propertyCustomFields
          ?.find(({ propertyUuid }) => property?.uuid === propertyUuid)
          ?.customFields?.filter(({ key }) => GREEN_DATA_KEYS.includes(key)) ?? []

      const customFieldClassEnumValues =
        filteredData?.propertyCustomFieldsConfig?.customFieldMetadata?.find(
          ({ key }) => key === GREEN_DATA_CLASS_KEY,
        )?.enumValues

      const propertiesKpis =
        filteredData?.propertyKpis?.propertyRentRollKpis?.find(
          ({ propertyUuid }) => property?.uuid === propertyUuid,
        )?.keyDateToRentRollKpis ?? []

      const allocatedLoanAmountHeadquarterSum = filteredData?.dealProperties.reduce(
        (acc, { allocatedLoanAmountHeadquarter }) =>
          acc + (allocatedLoanAmountHeadquarter?.number || 0),
        0,
      )

      const dealProperty = filteredData?.dealProperties.find(
        ({ propertyUuid }) => property?.uuid === propertyUuid,
      )

      return {
        ...property,
        ...dealProperty,
        allocatedLoanAmountHeadquarterSum,
        kpis: {
          keyDate: propertiesKpis[0]?.keyDate,
          ...propertiesKpis[0]?.kpis,
        },
        valuations: valuation ?? {},
        tegovaRating: tegovaRating?.total ?? 0,
        keyData: { multiplier, initialYield },
        greenData: { customFields, customFieldClassEnumValues },
      }
    })

    // properties sorted by descending market value, and in case of no market value sorted by property name
    const sortedTableData = sortTableDataByMarketValueAndDescription(tableData ?? [])

    const subPortfolioKpis = [
      {
        propertyUuids: filteredData?.subPortfolioKpis?.propertyUuids,
        headquarterCurrency: propertiesWithStatus?.[0]?.headquarterCurrency,
        ...filteredData?.subPortfolioKpis?.keyDateToMultiPropertyRentRollKpis[0],
      },
    ]

    const properties = propertiesWithStatus || []
    const noProperties = propertiesWithStatus?.length === 0

    const mappedData = {
      tableData: sortedTableData,
      multiPropertyKpis,
      valuationSum:
        version === tileVersion.V1 || version === tileVersion.V2
          ? {
              marketValue,
              mortgageLendingValue,
              purchasePrice,
            }
          : propertyValuationSums,
      subPortfolioKpis,
      properties,
      noProperties,
    }

    switch (version) {
      case tileVersion.V1:
        return <AssetValuationOverviewTileViewModeV1 data={mappedData} isPdfView={isPdfView} />
      default:
        return (
          <AssetValuationOverviewTileViewModeCurrentVersion
            data={mappedData}
            version={version}
            isPdfView={isPdfView}
          />
        )
    }
  }, [isPdfView, version, filteredData, propertiesWithStatus])
}

MultipleAssetValuationOverviewTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default MultipleAssetValuationOverviewTile
