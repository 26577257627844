import isNil from 'lodash.isnil'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useInternalTypes = ({ entityType, includeDeprecated } = {}, options) => {
  const queryParams = new URLSearchParams()
  if (entityType) {
    queryParams.append('entity_type', entityType)
  }
  if (!isNil(includeDeprecated)) {
    queryParams.append('include_deprecated', includeDeprecated)
  }
  return useCamelizedResponse(
    useRequest({
      path: `/conditions/configurations/internal-condition-types?${queryParams}`,
      useCache: true,
      translated: true,
      keys: ['conditions', 'configurations', 'internal', 'types', entityType, includeDeprecated],
      options,
    }),
  )
}

export default useInternalTypes
