import camelize from 'camelize'
import { useMemo } from 'react'
import { useInfiniteRequest } from 'hooks/services/baseService'

const usePropertyValuationRequests = (
  { sortBy, orderBy } = {},
  { reason, valuationType, status, reportDateFrom, reportDateUntil, valuer, search } = {},
  { propertyId },
  { limit } = {},
  options,
) => {
  const queryParams = new URLSearchParams()

  const cacheKeys = [
    'properties',
    propertyId,
    'valuation-requests',
    `sortBy-${sortBy}-orderBy-${orderBy}`,
    reason,
    valuationType,
    status,
    reportDateFrom,
    reportDateUntil,
    valuer,
    search,
    limit,
  ]

  if (sortBy) {
    queryParams.append('sort_by', sortBy)
    queryParams.append('order_by', orderBy)
  }

  if (status) {
    queryParams.append('status', status)
  }

  if (reason) {
    queryParams.append('reason', reason)
  }

  if (valuationType) {
    queryParams.append('type', valuationType)
  }

  if (reportDateFrom) {
    queryParams.append('report_date_from', reportDateFrom)
  }

  if (reportDateUntil) {
    queryParams.append('report_date_until', reportDateUntil)
  }

  if (valuer) {
    queryParams.append('valuer', valuer)
  }

  if (search) {
    queryParams.append('search', search)
  }

  const result = useInfiniteRequest({
    path: `/properties/${propertyId}/valuation-requests`,
    queryParams: queryParams,
    useCache: true,
    keys: cacheKeys,
    limit: limit,
    options,
  })
  const processedResponse = useMemo(() => {
    if (!result.data) return undefined
    return camelize(
      result.data.pages.reduce((acc, { valuation_requests, size }) => ({
        valuation_requests: [...acc.valuation_requests, ...valuation_requests],
        size,
      })),
    )
  }, [result])

  return { ...result, data: processedResponse }
}

export default usePropertyValuationRequests
