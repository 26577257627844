import { isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'

const usePropertyKpis = (propertyUuid, filterKpisForMonitoring = false) =>
  useRequest({
    path: `/property-kpi?property_id=${propertyUuid}${
      filterKpisForMonitoring ? '&filter_kpis_for_monitoring=true' : ''
    }`,
    useCache: true,
    keys: ['property-kpi', propertyUuid, `filterKpisForMonitoring=${filterKpisForMonitoring}`],
    options: {
      retry: (failureCount, error) => {
        if (isNotFoundError(error)) {
          return false
        }
        if (failureCount >= 1) {
          return false
        }
        return true
      },
    },
  })

export default usePropertyKpis
