import {
  List,
  ListMode,
  ListSeparators,
  Popover,
  PopoverHorizontalAlign,
  PopoverPlacementType,
  StandardListItem,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/FinancingTermsTrancheDetailsPopover.module.css'

const FinancingTermsTrancheDetailsPopover = ({
  isPopoverOpen,
  setIsPopoverOpen,
  openerId,
  content,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.financing-terms.tranche-details',
  })

  return createPortal(
    <>
      <Popover
        opener={openerId}
        open={isPopoverOpen}
        placementType={PopoverPlacementType.Bottom}
        horizontalAlign={PopoverHorizontalAlign.Center}
        onAfterClose={() => setIsPopoverOpen(false)}
        className={styles.popover}
      >
        <List separators={ListSeparators.Inner} mode={ListMode.None}>
          {content.map(({ from, type, rate }, index) => {
            let output = ''
            if (from) {
              output += `${t('property.from-label')} ${from ?? ''}:`
            }
            if (rate) {
              output += ` ${rate ?? ''},`
            }
            if (type) {
              output += ` ${type ?? ''}`
            }
            return (
              <StandardListItem key={index} className={styles.popoverListItem}>
                {output}
              </StandardListItem>
            )
          })}
        </List>
      </Popover>
    </>,
    document.body,
  )
}
FinancingTermsTrancheDetailsPopover.propTypes = {
  isPopoverOpen: PropTypes.bool.isRequired,
  setIsPopoverOpen: PropTypes.func.isRequired,
  openerId: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string,
      type: PropTypes.string,
      rate: PropTypes.string,
    }),
  ).isRequired,
}

export default FinancingTermsTrancheDetailsPopover
