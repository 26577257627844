import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { constantCaseObjectKeysToSnakeCase } from 'hooks/services/properties/valuations/useMultiPropertyValuationsDecisionPaper'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultipleTenantDetails = ({ dealUuids, options = {} }) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: dealUuids.map((dealUuid) => ({
        path: `/pbb-custom-service/decision-paper/deals/${dealUuid}/tenant-details`,
        keys: ['pbb-custom-service', 'decision-paper', 'deals', dealUuid, 'tenant-details'],
      })),
      translated: true,
      useCache: true,
      options: { ...options, enabled: !isEmpty(dealUuids) },
    }),
  )
  return useMemo(
    () =>
      responses.map((result, index) => ({
        ...result,
        data: {
          dealUuid: dealUuids[index],
          ...camelize(constantCaseObjectKeysToSnakeCase(result.data)),
        },
      })),
    [dealUuids, responses],
  )
}

export default useMultipleTenantDetails
