import camelize from 'camelize'
import dayjs from 'dayjs'
import get from 'lodash.get'
import uniq from 'lodash.uniq'
import { useMemo } from 'react'
import useTileMetaData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/meta-data/useTileMetaData'
import { weightedAvgByFields } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useTenancyOverview'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useCashflowGroups from 'hooks/services/deals/cashflow/useCashflowGroups'
import useCashflowScenarioResultsById from 'hooks/services/deals/cashflow/useCashflowScenarioResultsById'
import useDealMini from 'hooks/services/deals/useDealMini'

const DEBT_BALANCE_MATURITY = 'Debt Balance at Maturity'
const AIR = 'All-in Interest Rate'
const AIR_DAY_ONE = 'All-in Interest Rate at Day One'
const AIR_MATURITY = 'All-In Interest Rate at Maturity'
const AIR_MARGIN_IPP_1 = 'Margin - From IPP 1'
const AIR_BASE_RATE_IPP_1 = 'Base Rate - From IPP 1'
const COMMITMENT_AMOUNT = 'Commitment Amount'
const FINANCIAL_RATIOS = 'Financial Ratios'
const DEBT_BALANCE = 'Debt Balance'

const selectedParameterCode = ['LTV_DS', 'LTV_STD', 'DY_STD', 'ISC_STD', 'DSC_STD']
const allInInterestParameters = [
  AIR_BASE_RATE_IPP_1,
  AIR_MARGIN_IPP_1,
  COMMITMENT_AMOUNT,
  AIR_MATURITY,
]

const getMaturityValue = (data) => {
  if (!data || !data.maturityDate) {
    return undefined
  }
  const selector = data.rawValues ? 'rawValues' : 'values'

  const maturityDate = dayjs(data.maturityDate)
  const maturityDataIndex = data[selector]?.findIndex((item) => maturityDate.isBefore(item.endDate))

  const beforeMaturityDateValues = data[selector][maturityDataIndex - 1]
  const afterMaturityDateValues = data[selector][maturityDataIndex]

  const maturityData =
    Math.abs(maturityDate.diff(beforeMaturityDateValues?.endDate)) >
    Math.abs(maturityDate.diff(afterMaturityDateValues?.endDate))
      ? afterMaturityDateValues
      : beforeMaturityDateValues

  return maturityData.value
}

const getSelectedCashflowParameterValue = (cashflowParameter, trancheValue) => {
  if (allInInterestParameters.includes(cashflowParameter.cashflowParameterDefinition.name)) {
    trancheValue[cashflowParameter.cashflowParameterDefinition.name] = cashflowParameter.value
  }
}

const addNewTrancheValue = (
  tranche,
  debtBalanceResult,
  trancheValueIterationArray,
  isMaturityValue,
) => {
  const trancheValue = {}

  tranche.parameters.map((cashflowParameter) =>
    getSelectedCashflowParameterValue(cashflowParameter, trancheValue),
  )

  const getDebtBalanceMaturityValue = () => {
    if (isMaturityValue) {
      const selectedDebtBalanceValue = debtBalanceResult.filter(
        (result) => result.category === tranche.objectName,
      )
      return getMaturityValue(selectedDebtBalanceValue[0])
    }
    return 0
  }

  trancheValue[DEBT_BALANCE_MATURITY] = getDebtBalanceMaturityValue()
  trancheValue[AIR_DAY_ONE] =
    get(trancheValue, AIR_MARGIN_IPP_1) + get(trancheValue, AIR_BASE_RATE_IPP_1)

  trancheValueIterationArray.push(trancheValue)
}

const getParameterName = (array, parameterName) =>
  !array.find((data) => data.parameter === parameterName) ? parameterName : undefined

const addNewRow = (dataList, parameterName, trancheBracketName, dayOneValue, maturityValue) => {
  if (!!dayOneValue || !!maturityValue) {
    dataList.push({
      parameter: getParameterName(dataList, parameterName),
      trancheBracket: trancheBracketName,
      dayOne: dayOneValue,
      maturity: maturityValue,
    })
  }
}

const getSelectedKpis = (selectedCalculatedKpis, parameterCode, trancheBracket) =>
  selectedCalculatedKpis.find(
    (kpi) => kpi.cashflowKpi.code === parameterCode && kpi.category === trancheBracket.objectName,
  )

const useCovenantsRatiosFinancialRatios = (
  { id: eventId, entityRef: { entityId: dealUuid } },
  tileId,
  tileCode,
) => {
  const {
    data: tileMetaDataSnakeCase,
    isFetching: isTileMetaDataFetching,
    isError: isTileMetaDataError,
  } = useTileMetaData({ eventId, tileId })

  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    data: dealData,
    isFetching: isDealFetching,
    isError: isDealError,
  } = useDealMini(dealUuidByTileCode)

  const {
    data: { groups = [] } = {},
    isFetching: isGroupsFetching,
    isError: isGroupsError,
  } = useCashflowGroups()

  const tileMetaData = camelize(tileMetaDataSnakeCase)
  const selectedCashFlowScenarioUuid = tileMetaData?.metaData?.selectedCashFlowScenarioUuid

  const {
    data: { calculatedResults = [], calculatedKpis = [], usedParameters = [] } = {},
    isFetching: isKpisResultsFetching,
    isError: isKpisResultsError,
  } = useCashflowScenarioResultsById(
    {
      dealUuid: dealUuidByTileCode,
      cashflowId: selectedCashFlowScenarioUuid,
      datasetPeriod: 'QUARTER',
    },
    { enabled: !!selectedCashFlowScenarioUuid && !!dealUuidByTileCode },
  )

  const trancheBrackets = useMemo(
    () =>
      usedParameters.filter(
        (cashFlowObject) =>
          cashFlowObject.objectDefinition.internalObjectType === 'TRANCHE_BRACKET',
      ),
    [usedParameters],
  )

  const isTrancheNameUnique = useMemo(() => {
    const trancheNameList = []

    trancheBrackets.forEach((bracket) =>
      bracket.children.map((tranche) => trancheNameList.push(tranche.objectName)),
    )
    if (trancheNameList.length === uniq(trancheNameList).length) {
      return true
    }
    return false
  }, [trancheBrackets])

  const allInInterestTableData = useMemo(() => {
    let aIRValues = []
    const allInInterestValues = []

    const addNewRowForAIRValues = (trancheBracket, filterDebtBalanceResult) => {
      trancheBracket.children.forEach((tranche) => {
        addNewTrancheValue(tranche, filterDebtBalanceResult, aIRValues, isTrancheNameUnique)
      })
      const weightedDayOneValue = weightedAvgByFields(aIRValues, AIR_DAY_ONE, COMMITMENT_AMOUNT)
      const weightedMaturityValue = weightedAvgByFields(
        aIRValues,
        AIR_MATURITY,
        DEBT_BALANCE_MATURITY,
      )
      addNewRow(
        allInInterestValues,
        AIR,
        trancheBracket.objectName,
        weightedDayOneValue,
        weightedMaturityValue,
      )
    }

    trancheBrackets.forEach((trancheBracket) => {
      const isTrancheInTrancheBracket = trancheBracket.children.length > 0

      if (isTrancheInTrancheBracket) {
        const filterDebtBalanceResult = calculatedResults.filter(
          ({ cashflowType: { name } = {} }) => name === DEBT_BALANCE,
        )
        addNewRowForAIRValues(trancheBracket, filterDebtBalanceResult)
      }
      aIRValues = []
    })
    return allInInterestValues
  }, [calculatedResults, isTrancheNameUnique, trancheBrackets])

  const singleParameterTableData = useMemo(() => {
    const singleParameterDataList = []

    const addNewRowForSingleParameter = (
      dataList,
      selectedKpis,
      selectedParameterName,
      trancheBracket,
    ) => {
      const dayOneValue = selectedKpis?.values?.[0]?.value
      const maturityValue = getMaturityValue(selectedKpis)
      addNewRow(
        dataList,
        selectedParameterName,
        trancheBracket.objectName,
        dayOneValue,
        maturityValue,
      )
    }
    const getSingleParameterValue = (
      dataList,
      selectedCalculatedKpis,
      selectedParameters,
      parameterCode,
    ) => {
      const selectedParameterName = selectedParameters.find(
        (parameter) => parameter.code === parameterCode,
      )?.name

      trancheBrackets.forEach((trancheBracket) => {
        const selectedKpis = getSelectedKpis(selectedCalculatedKpis, parameterCode, trancheBracket)
        addNewRowForSingleParameter(dataList, selectedKpis, selectedParameterName, trancheBracket)
      })
    }

    const selectedParameters =
      !isGroupsFetching && !isGroupsError
        ? groups
            .filter((group) => group?.name === FINANCIAL_RATIOS)?.[0]
            ?.kpis?.filter((kpi) => selectedParameterCode.includes(kpi.code)) ?? []
        : []

    const selectedCalculatedKpis = calculatedKpis.filter((kpi) =>
      selectedParameterCode.includes(kpi.cashflowKpi?.code),
    )

    selectedParameterCode.forEach((parameterCode) => {
      getSingleParameterValue(
        singleParameterDataList,
        selectedCalculatedKpis,
        selectedParameters,
        parameterCode,
      )
    })
    return singleParameterDataList
  }, [calculatedKpis, groups, isGroupsError, isGroupsFetching, trancheBrackets])

  const dealId = useMemo(() => dealData?.dealId, [dealData?.dealId])
  const sourceRender = useMemo(
    () => ({
      dealDisplayId: dealId,
      sourcePathSuffix: isWorkingVersionDataTile ? '?working-version=true' : '',
    }),
    [dealId, isWorkingVersionDataTile],
  )

  const isLoading =
    isTileMetaDataFetching ||
    isDealUuidFetching ||
    isDealFetching ||
    isGroupsFetching ||
    isKpisResultsFetching
  const isError =
    isTileMetaDataError || isDealUuidError || isDealError || isGroupsError || isKpisResultsError

  return useMemo(() => {
    const data = {
      dealId,
      dealUuid: dealUuidByTileCode,
      tileMetaData,
      tableData: [...singleParameterTableData, ...allInInterestTableData],
      sourceRender,
    }
    return {
      isError,
      isLoading,
      data: !(isError || isLoading) ? data : undefined,
    }
  }, [
    dealId,
    dealUuidByTileCode,
    tileMetaData,
    singleParameterTableData,
    allInInterestTableData,
    sourceRender,
    isError,
    isLoading,
  ])
}

export default useCovenantsRatiosFinancialRatios
