import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useRemindConditionsVoter = (mutationOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    ({ decisionStageId, eventId, voterId }) =>
      post({
        path: `/events/${eventId}/decision-stages/${decisionStageId}/conditions/voters/${voterId}/reminder`,
      }),
    mutationOptions,
  )
}

export default useRemindConditionsVoter
