import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const usePropertyTypeUsageTypeAllowedCombinations = () =>
  useCamelizedResponse(
    useRequest({
      path: `/properties/type-usagetype-allowed-combinations`,
      translated: true,
    }),
  )
