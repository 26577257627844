import {
  Label,
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  FlexBoxAlignItems,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperPopovers.module.css'

const propTypes = {
  templateName: PropTypes.string.isRequired,
  isCustomized: PropTypes.bool,
  headerText: PropTypes.string,
  styleForDialog: PropTypes.bool,
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const DecisionPaperTemplateInformation = ({
  isCustomized,
  templateName,
  headerText,
  styleForDialog,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper.working-version-popover',
  })

  const prefixedTemplateName = useMemo(
    () => (isCustomized ? t('customized-template-name', { templateName }) : templateName),
    [isCustomized, t, templateName],
  )

  const className = styleForDialog ? '' : `${styles.header}`

  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      alignItems={FlexBoxAlignItems.Start}
      className={className}
    >
      <FlexBox alignItems={FlexBoxAlignItems.Center}>
        <Text>{headerText}</Text>
      </FlexBox>
      <FlexBox alignItems={FlexBoxAlignItems.Center}>
        <Label wrappingType="Normal">
          {t('template-name', { templateName: prefixedTemplateName })}
        </Label>
      </FlexBox>
    </FlexBox>
  )
}

DecisionPaperTemplateInformation.propTypes = propTypes

export default DecisionPaperTemplateInformation
