import { Button, ButtonDesign, MessageBoxActions, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useDeleteDecisionStage from 'hooks/services/business-events-and-tasks/decision-process/stages/useDeleteDecisionStage'

const useDeleteDecisionStageFlow = ({ eventId, stageId, successCallback, errorCallback }) => {
  const { t: tNoPrefix } = useTranslation('translation')
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.delete-stage',
  })
  const { mutate: deleteDecisionStage } = useDeleteDecisionStage()
  const showMessageBox = Modals.useShowMessageBox()
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()

  const handleDeleteSuccess = useCallback(() => {
    successCallback?.()
    showToast({ children: t('delete.success.text') }, document.body)
    queryClient.invalidateQueries(['events', eventId, 'decision-stages'])
  }, [eventId, queryClient, showToast, t, successCallback])

  const handleDeleteError = useCallback(() => {
    errorCallback?.()
    showMessageBox(
      {
        children: t('delete.error.text'),
        titleText: t('delete.error.title'),
        draggable: true,
        resizable: true,
      },
      document.body,
    )
  }, [showMessageBox, t, errorCallback])

  const handleDeleteConfirmation = useCallback(() => {
    deleteDecisionStage(
      { eventId, stageId },
      { onSuccess: handleDeleteSuccess, onError: handleDeleteError },
    )
  }, [deleteDecisionStage, eventId, stageId, handleDeleteSuccess, handleDeleteError])

  const handleDeleteClicked = useCallback(() => {
    showMessageBox(
      {
        children: t('delete.text'),
        titleText: t('delete.title'),
        actions: [
          <Button
            key={`delete-stage-button_${stageId}`}
            design={ButtonDesign.Emphasized}
            onClick={() => handleDeleteConfirmation(stageId)}
          >
            {tNoPrefix('buttons.delete')}
          </Button>,
          MessageBoxActions.Cancel,
        ],
        draggable: true,
        resizable: true,
      },
      document.body,
    )
  }, [showMessageBox, t, stageId, tNoPrefix, handleDeleteConfirmation])

  return useMemo(() => ({ handleDeleteClicked }), [handleDeleteClicked])
}

export default useDeleteDecisionStageFlow
