import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useMassEditEvents = (mutateOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    ({ eventIds, editedFields: { assignee, originalDueDate, currentDueDate, status } }) =>
      post({
        path: '/events/bulk-edit',
        body: snakecaseKeys({
          eventIds,
          editedFields: {
            info: {
              assignee,
              originalDueDate,
              currentDueDate,
            },
            status,
          },
        }),
      }),
    mutateOptions,
  )
}

export default useMassEditEvents
