import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useBusinessPartnerUnitOverviewDealSyndications = (dealUuids = [], options = {}) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: dealUuids.map((dealUuid) => ({
        path: `/syndication-structures/deals/${dealUuid}/syndications/lenders`,
        keys: ['deals', dealUuid, 'syndication', 'syndication-lenders'],
        useCache: true,
      })),
      translated: true,
      options: { ...options, enabled: !isEmpty(dealUuids) },
    }),
  )

  return useMemo(
    () =>
      responses.map((result, index) => ({
        ...result,
        data: { dealUuid: dealUuids[index], ...camelize(result.data) },
      })),
    [dealUuids, responses],
  )
}

export default useBusinessPartnerUnitOverviewDealSyndications
