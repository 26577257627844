import { DatePicker } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import DisbursementTileDisplayOrEditItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/disbursement/fields/DisbursementTileDisplayOrEditItem'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const DisbursementTileDateField = ({ label, value, isEditMode, onChange, isFirstItem }) => {
  const { localePattern, format: formatDate, parse } = useShortDateFormatter()

  const formattedDate = useMemo(() => formatDate(value), [formatDate, value])

  const handleOnChange = useCallback(
    ({ detail: { value: localeDate } }) => {
      const parsedDate = parse(localeDate, localePattern)
      onChange(parsedDate)
    },
    [onChange, localePattern, parse],
  )

  const renderEditComponent = useCallback(
    () => <DatePicker value={value} onChange={handleOnChange} formatPattern={localePattern} />,
    [value, handleOnChange, localePattern],
  )

  return (
    <DisbursementTileDisplayOrEditItem
      label={label}
      value={formattedDate}
      renderEditComponent={renderEditComponent}
      isEditMode={isEditMode}
      isFirstItem={isFirstItem}
    />
  )
}

DisbursementTileDateField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isEditMode: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isFirstItem: PropTypes.bool,
}

export default DisbursementTileDateField
