import useMultipleStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useMultipleStaffMemberByObjectIdOrEmail'

/**
 * @param {import('hooks/services/documents/documentsSchema').Document[]} documents
 */
const useFilterByCreator = (documents) => {
  const { data: staffMembers, isSomeLoading } = useMultipleStaffMemberByObjectIdOrEmail({
    objectIdOrEmailAddressList: documents.map(({ creator }) => creator),
  })

  /** @param {string} [staffMemberId] the staff member ID to filter by */
  const filterDocuments = (staffMemberId) => {
    // HINT: filter by undefined or no staff members (b/c isError or isLoading) => return all documents
    if (!staffMemberId || !staffMembers) return documents

    return documents.filter(({ creator: emailOrName }) => {
      // HINT: creator is either the found staffMember, or `null` if it wasn't found or there was an error
      const creator = staffMembers[emailOrName]
      // HINT: if the creator is not defined, we can't filter by it, so we just display the document (w/o creator)
      if (!creator) return true
      return creator?.id === staffMemberId
    })
  }

  return {
    isLoading: isSomeLoading,
    isError: false,
    filterDocuments,
  }
}

export default useFilterByCreator
