import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import useAuthorityLevelCalculatorList from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/useAuthorityLevelCalculatorList'
import useDealMini from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/useDealMini'
import { cwpEntityTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/unit-overview/cwpEntityTypes'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'

const useUnitSelectionOptions = ({ entityRef: { entityId, entityType } }) => {
  const {
    data: { borrowerBpId } = {},
    isLoading: isLoadingDealData,
    isError: isErrorDealData,
    error: errorDealData,
  } = useDealMini(entityType === cwpEntityTypes.DEAL ? entityId : undefined)

  const businessPartnerId = entityType === cwpEntityTypes.DEAL ? borrowerBpId : entityId

  const { selectedUnit } =
    useSelector(
      (state) =>
        state.decisionPaper.tilesOverview.tileMetadata?.[availableAutomaticTiles.unitSelectionTile],
    ) ?? {}

  const {
    data: { gcc, review, standalone } = { gcc: [], review: [], standalone: [] },
    isLoading: isLoadingList,
    isError: isErrorList,
    error: errorList,
  } = useAuthorityLevelCalculatorList(businessPartnerId)

  const {
    isSomeValueLoading: isLoading,
    isSomeValueError: isError,
    error,
  } = useAutomaticTileHookHelper({
    loadingValues: [isLoadingList, isLoadingDealData && entityType === cwpEntityTypes.DEAL],
    errorValues: [isErrorList, isErrorDealData && entityType === cwpEntityTypes.DEAL],
    errorDetails: [errorList, errorDealData],
  })

  return useMemo(
    () => ({
      isLoading,
      isError,
      error,
      data: {
        options: [...gcc, ...review, ...standalone.map((unit) => ({ ...unit, standalone: true }))],
        selectedUnit,
      },
    }),
    [error, gcc, isError, isLoading, review, selectedUnit, standalone],
  )
}

export default useUnitSelectionOptions
