import { useMemo } from 'react'
import useCollateralAgreementsByTrancheIds from 'hooks/services/collaterals/useCollateralAgreementsByTrancheIds'
import useTranches from 'hooks/services/deals/covenants/useTranches'

/** @typedef {import('hooks/services/collaterals/collateralAgreementSchema').CollateralAgreementSearch} CAG */

/**
 * @typedef {{value: number, currency: string | undefined}} Money
 *
 * @typedef {object} Agreement
 * @property {CAG["id"]} id
 * @property {CAG["displayId"]} displayId
 * @property {CAG["cagCluster"]} cagCluster
 * @property {CAG["cagType"]} cagType
 * @property {CAG["name"]} name
 * @property {CAG["systemStatus"]} systemStatus
 * @property {CAG["cagValue"]} cagValue
 * @property {CAG["cagValueHq"]} cagValueInHeadQuarterCurrency
 * @property {CAG["nominalValue"]} cagNominalValue
 * @property {CAG["nominalValueHq"]} cagNominalValueInHeadQuarterCurrency
 * @property {CAG["extLandCharge"]} externalLandCharge
 * @property {CAG["newBusinessReceivables"]} newBusinessReceivables
 * @property {CAG["existingBusinessReceivables"]} existingBusinessReceivables
 * @property {CAG["assetsCount"]} assetsCount
 * @property {Money} cagValueInHeadQuarterCurrency
 * @property {Money} cagNominalValueInHeadQuarterCurrency
 *
 * @typedef {object} CagType
 * @property {CAG["cagType"]} cagType
 * @property {Money} cagAggregatedValueInHeadQuarterCurrency
 * @property {Money} cagAggregatedNominalValueInHeadQuarterCurrency
 * @property {Agreement[]} agreements
 *
 * @typedef {object} Cluster
 * @property {CAG["cagCluster"]} cagCluster
 * @property {Money} cagAggregatedValueInHeadQuarterCurrency
 * @property {Money} cagAggregatedNominalValueInHeadQuarterCurrency
 * @property {{[x: string]: CagType}} cagTypes
 *
 * @typedef {object} Aggregated
 * @property {Money} cagAggregatedTotalValueInHeadQuarterCurrency
 * @property {Money} cagAggregatedTotalNominalValueInHeadQuarterCurrency
 * @property {{[x: string]: Cluster}} cagClusters
 */

/** @type {Aggregated} */
const emptyAggregatedCollateralAgreements = {
  cagAggregatedTotalValueInHeadQuarterCurrency: {
    value: 0,
    currency: '',
  },
  cagAggregatedTotalNominalValueInHeadQuarterCurrency: {
    value: 0,
    currency: '',
  },
  cagClusters: {},
}

const getHqValuesForAggregation = (collateralAgreement) => {
  if (collateralAgreement.extLandCharge) return { cagValueHqToAdd: 0, nominalValueHqToAdd: 0 }

  return {
    cagValueHqToAdd: collateralAgreement.cagValueHq?.value ?? 0,
    nominalValueHqToAdd: collateralAgreement.nominalValueHq?.value ?? 0,
  }
}

const useAggregatedCollateralAgreements = ({ dealUuid }) => {
  const {
    data: { tranches } = {},
    isError: isTrancheError,
    isLoading: isTrancheLoading,
    isFetching: isTrancheFetching,
    error: trancheError,
  } = useTranches({
    dealUuid,
    dataSource: 'newBusiness',
  })

  const {
    isError: isCagError,
    isLoading: isCagLoading,
    isFetching: isCagFetching,
    data: collateralAgreementsData,
    error: cagError,
  } = useCollateralAgreementsByTrancheIds({
    ids: tranches?.map(({ trancheId }) => trancheId),
  })

  const isError = isTrancheError || isCagError
  const isLoading = (isCagLoading && tranches && tranches?.length > 0) || isTrancheLoading
  const isFetching = isTrancheFetching || isCagFetching
  const errors = isError ? [trancheError, cagError] : []

  /**
   * @param {Aggregated} aggregatedCollateralAgreements
   * @param {CAG} currentCollateralAgreement
   */
  const calculateTotalValues = (aggregatedCollateralAgreements, currentCollateralAgreement) => {
    const { cagValueHqToAdd, nominalValueHqToAdd } = getHqValuesForAggregation(
      currentCollateralAgreement,
    )

    return {
      ...aggregatedCollateralAgreements,
      cagAggregatedTotalValueInHeadQuarterCurrency: {
        currency: currentCollateralAgreement.cagValueHq?.currency,
        value:
          aggregatedCollateralAgreements.cagAggregatedTotalValueInHeadQuarterCurrency.value +
          cagValueHqToAdd,
      },
      cagAggregatedTotalNominalValueInHeadQuarterCurrency: {
        currency: currentCollateralAgreement.nominalValueHq?.currency,
        value:
          aggregatedCollateralAgreements.cagAggregatedTotalNominalValueInHeadQuarterCurrency.value +
          nominalValueHqToAdd,
      },
    }
  }

  /**
   * @param {Cluster} collateralAgreementsCagCluster
   * @param {CAG} currentCollateralAgreement
   */
  const getCagSpecificValues = (collateralAgreementsCagCluster, currentCollateralAgreement) => {
    const { cagValueHqToAdd, nominalValueHqToAdd } = getHqValuesForAggregation(
      currentCollateralAgreement,
    )

    return {
      cagCluster: {
        ...currentCollateralAgreement.cagCluster,
      },
      cagAggregatedValueInHeadQuarterCurrency: {
        currency: currentCollateralAgreement.cagValueHq?.currency,
        value:
          (collateralAgreementsCagCluster?.cagAggregatedValueInHeadQuarterCurrency.value ?? 0) +
          cagValueHqToAdd,
      },
      cagAggregatedNominalValueInHeadQuarterCurrency: {
        currency: currentCollateralAgreement.nominalValueHq?.currency,
        value:
          (collateralAgreementsCagCluster?.cagAggregatedNominalValueInHeadQuarterCurrency.value ??
            0) + nominalValueHqToAdd,
      },
    }
  }

  /**
   * @param {CagType} collateralAgreementsCagType
   * @param {CAG} currentCollateralAgreement
   */
  const getCagTypeSpecificValues = (collateralAgreementsCagType, currentCollateralAgreement) => {
    const { cagValueHqToAdd, nominalValueHqToAdd } = getHqValuesForAggregation(
      currentCollateralAgreement,
    )
    return {
      cagType: {
        ...currentCollateralAgreement.cagType,
      },
      cagAggregatedValueInHeadQuarterCurrency: {
        currency: currentCollateralAgreement.cagValueHq?.currency,
        value:
          (collateralAgreementsCagType?.cagAggregatedValueInHeadQuarterCurrency.value ?? 0) +
          cagValueHqToAdd,
      },
      cagAggregatedNominalValueInHeadQuarterCurrency: {
        currency: currentCollateralAgreement.nominalValueHq?.currency,
        value:
          (collateralAgreementsCagType?.cagAggregatedNominalValueInHeadQuarterCurrency.value ?? 0) +
          nominalValueHqToAdd,
      },
    }
  }

  /**
   * @param {CAG} currentCollateralAgreement
   */
  const getAgreementSpecificValues = (currentCollateralAgreement) => ({
    id: currentCollateralAgreement.id,
    displayId: currentCollateralAgreement.displayId,
    cagCluster: currentCollateralAgreement.cagCluster,
    cagType: currentCollateralAgreement.cagType,
    name: currentCollateralAgreement.name,
    systemStatus: currentCollateralAgreement.systemStatus,
    cagValue: currentCollateralAgreement.cagValue,
    cagValueInHeadQuarterCurrency: currentCollateralAgreement.cagValueHq,
    cagNominalValue: currentCollateralAgreement.nominalValue,
    cagNominalValueInHeadQuarterCurrency: currentCollateralAgreement.nominalValueHq,
    externalLandCharge: currentCollateralAgreement.extLandCharge,
    newBusinessReceivables: currentCollateralAgreement.newBusinessReceivables,
    existingBusinessReceivables: currentCollateralAgreement.existingBusinessReceivables,
    assetsCount: currentCollateralAgreement.assetsCount,
  })

  const result = useMemo(() => {
    if (!collateralAgreementsData) {
      return
    }

    return collateralAgreementsData?.collateralAgreements?.reduce(
      (aggregatedCollateralAgreements, currentCollateralAgreement) => {
        const currentCagCluster = currentCollateralAgreement.cagCluster.id
        const currentCagType = currentCollateralAgreement.cagType.id

        return {
          ...calculateTotalValues(aggregatedCollateralAgreements, currentCollateralAgreement),
          cagClusters: {
            ...aggregatedCollateralAgreements.cagClusters,
            [currentCagCluster]: {
              ...getCagSpecificValues(
                aggregatedCollateralAgreements.cagClusters[currentCagCluster],
                currentCollateralAgreement,
              ),
              cagTypes: {
                ...(aggregatedCollateralAgreements.cagClusters[currentCagCluster]?.cagTypes ?? {}),
                [currentCagType]: {
                  ...getCagTypeSpecificValues(
                    aggregatedCollateralAgreements.cagClusters[currentCagCluster]?.cagTypes[
                      currentCagType
                    ],
                    currentCollateralAgreement,
                  ),
                  agreements: [
                    ...(aggregatedCollateralAgreements.cagClusters[currentCagCluster]?.cagTypes[
                      currentCagType
                    ]?.agreements ?? []),
                    getAgreementSpecificValues(currentCollateralAgreement),
                  ],
                },
              },
            },
          },
        }
      },
      emptyAggregatedCollateralAgreements,
    )
  }, [collateralAgreementsData])

  return {
    aggregatedCollateralAgreements: result,
    isLoading,
    isFetching,
    isError,
    errors,
  }
}

export default useAggregatedCollateralAgreements
