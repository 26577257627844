import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import TableWatcherCell, {
  TableWatcherCellType,
} from 'components/domains/conditions/overview/table-cells/generic/TableWatcherCell'

const TableWatcherCellWrapper = ({ value }) => {
  if (isNil(value.watchers) || isNil(value.conditionId)) {
    return
  }
  return <TableWatcherCell value={value} />
}

TableWatcherCellWrapper.propTypes = {
  value: PropTypes.shape({
    watchers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        userId: PropTypes.string.isRequired,
      }),
    ),
    conditionId: PropTypes.string,
    requirementId: PropTypes.string,
    watcherType: PropTypes.oneOf(Object.values(TableWatcherCellType)),
  }),
}

export default TableWatcherCellWrapper
