import { IllustrationMessageType, IllustrationMessageSize } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/EmptyCardContent'

const MissingPermissionErrorTileContent = () => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.loading.error.permissions',
  })

  return (
    <EmptyCardContent
      size={IllustrationMessageSize.Spot}
      title={t('title')}
      subtitle={t('subtitle')}
      illustrationName={IllustrationMessageType.UnableToLoad}
    />
  )
}

export default MissingPermissionErrorTileContent
