import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useCashFlowScenario = (dealUuid, cashflowScenarioUuid, options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/cashflow-scenarios/${cashflowScenarioUuid}`,
      translated: true,
      useCache: true,
      keys: ['deals', dealUuid, 'cashflow-scenarios', cashflowScenarioUuid],
      options: { enabled: !!dealUuid && !!cashflowScenarioUuid },
      ...options,
    }),
  )
