import isNil from 'lodash.isnil'
import mapArrearApprovalsData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/shared/automatic-checksheet/mappers/mapArrearApprovalsData'
import mapAuthorityLevelData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/shared/automatic-checksheet/mappers/mapAuthorityLevelData'
import mapCustomerSpecificAttributesData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/shared/automatic-checksheet/mappers/mapCustomerSpecificAttributesData'
import mapRegulatoryInformationData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/shared/automatic-checksheet/mappers/mapRegulatoryInformationData'

const mapBaselTwoAutomaticCheckSheetData = ({
  borrowersData,
  multipleRegulatoryInformationData,
  multipleArrearApprovalsData,
  authorityLevelData,
  multipleCustomerSpecificAttributes,
  dealIdTrancheIdPairsByBusinessPartner,
}) =>
  borrowersData.map(({ borrowerId, borrowerName }) => {
    const { hasShadowBankingEntity, hasLeveragedTransaction } = mapRegulatoryInformationData({
      borrowerId,
      multipleRegulatoryInformationData,
    })

    const { hasSpecializedLending, hasSecuritization } = mapCustomerSpecificAttributesData({
      borrowerId,
      multipleCustomerSpecificAttributes,
      dealIdTrancheIdPairsByBusinessPartner,
    })

    const { isOrgankredit, largeExposureRelevant } = mapAuthorityLevelData({
      authorityLevelData,
    })

    const { hasEventNotOlderThanTwelveMonths } = mapArrearApprovalsData({
      borrowerId,
      multipleArrearApprovalsData,
    })

    return {
      borrowerId,
      borrowerName,
      // Missing questions due to copy and owning from Annual Review Automatic Checksheet
      answers: {
        // eslint-disable-next-line no-nested-ternary
        Q1: isNil(hasShadowBankingEntity)
          ? 'data-not-available'
          : hasShadowBankingEntity
          ? 'yes'
          : 'no',

        Q3: hasSpecializedLending ? 'yes' : 'no',

        // eslint-disable-next-line no-nested-ternary
        Q4: isNil(hasLeveragedTransaction)
          ? 'data-not-available'
          : hasLeveragedTransaction
          ? 'yes'
          : 'no',

        Q5: hasSecuritization ? 'yes' : 'no',

        Q7: isOrgankredit ? 'yes' : 'no',

        Q7b: largeExposureRelevant ? 'yes' : 'no',

        // eslint-disable-next-line no-nested-ternary
        Q8: isNil(hasEventNotOlderThanTwelveMonths)
          ? 'n/a'
          : hasEventNotOlderThanTwelveMonths
          ? 'yes'
          : 'no',
      },
    }
  })

export default mapBaselTwoAutomaticCheckSheetData
