import isNil from 'lodash.isnil'
import {
  RISK_INDICATOR_TYPE_CODE_CATEGORIES,
  riskIndicatorStatusMapping,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/utils/typeCodes'

/**
 * @typedef {RISK_INDICATOR_TYPE_CODE_CATEGORIES[keyof typeof RISK_INDICATOR_TYPE_CODE_CATEGORIES]} Category
 *
 * @param {object} params
 * @param {Category} params.category
 * @param {{[K in Category]?: ({code?: string; shortText?: string} | undefined)[]}} [params.typeCodes]
 * @param {string} params.cellTypeCode
 */
const resolveTypeCodeForStatus = ({ category, typeCodes, cellTypeCode }) => {
  if (isNil(cellTypeCode)) {
    return {
      statusState: undefined,
      statusValue: undefined,
    }
  }
  // Type codes also need to be covered in between the static values defined in the FE.
  // So if a cell type code 70 is given, and the FE only has 60 and 80 defined, the lower local boundary of 60 is returned.
  // Therefore, the type code object are transformed into an array and sorted by code value.
  const typeCodeArray = Object.values(typeCodes?.[category] ?? {})
  typeCodeArray.sort((a, b) => Number(a?.code) - Number(b?.code))

  // Then, the type code that is the next highest to the current cell type code is selected,
  // where, in case of the current cell type code already being the largest type code defined, it is its own upper boundary.
  let upperTypeCodeBoundaryIndex = typeCodeArray.findIndex(
    (status) => Number(status?.code) > Number(cellTypeCode),
  )
  if (upperTypeCodeBoundaryIndex === -1) {
    upperTypeCodeBoundaryIndex = typeCodeArray.length
  }

  // Finally, either the type code from the BE matches the FE defined one exactly,
  // or the one directly before the upper local boundary is returned,
  // or the lowest boundary is returned, if the upper local boundary equals the lowest absolute boundary.
  const typeCode =
    typeCodeArray.find((status) => status?.code === cellTypeCode) ||
    typeCodeArray?.[upperTypeCodeBoundaryIndex - 1] ||
    typeCodeArray?.[0]

  switch (category) {
    case RISK_INDICATOR_TYPE_CODE_CATEGORIES.STATUS:
      return {
        statusState: typeCode?.code && riskIndicatorStatusMapping.STATUS[typeCode.code]?.valueState,
        statusValue: typeCode?.shortText,
      }
    case RISK_INDICATOR_TYPE_CODE_CATEGORIES.CRITICALITY:
      return {
        statusState:
          typeCode?.code && riskIndicatorStatusMapping.CRITICALITY[typeCode.code]?.valueState,
        statusValue: typeCode?.shortText,
      }
  }
}

export default resolveTypeCodeForStatus
