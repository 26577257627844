// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bjdrzzH506YXKahvX2kQ {
  width: 20rem;
}

.bjdrzzH506YXKahvX2kQ header ui5-title {
  color: var(--sapGroup_TitleTextColor);
}

.bjdrzzH506YXKahvX2kQ footer ui5-button:last-of-type {
  margin-right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/message-box/MessageBox.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".messageBox {\n  width: 20rem;\n}\n\n.messageBox header ui5-title {\n  color: var(--sapGroup_TitleTextColor);\n}\n\n.messageBox footer ui5-button:last-of-type {\n  margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageBox": `bjdrzzH506YXKahvX2kQ`
};
export default ___CSS_LOADER_EXPORT___;
