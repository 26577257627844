import { Button, FileUploader } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import excelUploadAcceptedFileEndings from 'components/domains/properties/rent-roll/working-version/excel-upload/excelUploadAcceptedFileEndings'

const excelUploadButtonId = 'excelUploadButtonId'
const acceptFileTypes = excelUploadAcceptedFileEndings.join(',')

const ExcelFileUploaderButton = ({ handleUploadFile, uploadButtonText }) => {
  /**
   * The dynamic key is important!
   * When a file is uploaded, it is added to a FileList inside the FileUploader. This list is readonly.
   * If you try to upload the same file for the second time, the onChange will not be fired (no changes detected).
   * But because it should be possible to do that, the component is recreated by changing the key on every change event.
   * The file and the content themself are still passed correctly.
   */
  const [dynamicKey, setDynamicKey] = useState(0)
  return (
    <FileUploader
      hideInput
      key={dynamicKey}
      id={excelUploadButtonId}
      accept={acceptFileTypes}
      onChange={(e) => {
        handleUploadFile(e.target.files[0], e.target)
        setDynamicKey((prev) => prev + 1)
      }}
    >
      <Button data-testid="excel-upload-button" design="Emphasized">
        {uploadButtonText}
      </Button>
    </FileUploader>
  )
}

ExcelFileUploaderButton.propTypes = {
  handleUploadFile: PropTypes.func.isRequired,
  uploadButtonText: PropTypes.string.isRequired,
}

export default ExcelFileUploaderButton
