import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  ObjectStatus,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import styles from 'components/domains/properties/rent-roll/overview/structure-overview/StructureOverviewNoDataAvailableCell.module.css'

const StructureOverviewNoDataAvailableCell = ({ times, showIteration, alignment }) => {
  const isShowIterationSameLengthAsTimes = showIteration?.length === times
  const shouldRenderDataUnavailable = (timesIndex) =>
    !isShowIterationSameLengthAsTimes ||
    (isShowIterationSameLengthAsTimes && showIteration[timesIndex])
  return (
    <FlexBox
      className={styles.flexbox}
      direction={FlexBoxDirection.Row}
      justifyContent={FlexBoxJustifyContent.End}
    >
      {[...Array(times)].map((_, index) => (
        <FlexBox
          key={index}
          className={styles.flexboxItem}
          direction={FlexBoxDirection.Row}
          justifyContent={alignment ?? FlexBoxJustifyContent.Center}
          alignItems={FlexBoxAlignItems.Center}
        >
          {shouldRenderDataUnavailable(index) && (
            <ObjectStatus state={ValueState.Error}>
              {t('app.loading.error.data-unavailable')}
            </ObjectStatus>
          )}
        </FlexBox>
      ))}
    </FlexBox>
  )
}
StructureOverviewNoDataAvailableCell.propTypes = {
  times: PropTypes.number.isRequired,
  showIteration: PropTypes.arrayOf(PropTypes.bool),
  alignment: PropTypes.string,
}
export default StructureOverviewNoDataAvailableCell
