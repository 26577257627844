import {
  DateRangePicker,
  FilterGroupItem,
  Input,
  Option,
  Select,
} from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { useEffect, useMemo, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { entityTypes } from 'api/events/status'
import AssigneeFilter from 'components/domains/business-events-and-tasks/events/filterbar/AssigneeFilter'
import BusinessPartnerMultiInput from 'components/domains/business-events-and-tasks/events/filterbar/BusinessPartnerMultiInput'
import DealMultiInput from 'components/domains/business-events-and-tasks/events/filterbar/DealMultiInput'
import EventStatusComboBox from 'components/domains/business-events-and-tasks/events/filterbar/EventStatusComboBox'
import {
  mapEntityTypeFilters,
  parseDueDateRange,
  useReferenceFilterHandler,
} from 'components/domains/business-events-and-tasks/events/filterbar/EventsAndTasksFilterUtils'
import PropertyMultiInput from 'components/domains/business-events-and-tasks/events/filterbar/PropertyMultiInput'
import DashboardFilterBar from 'components/ui/page/DashboardFilterBar'
import countActiveFilters from 'components/ui/page/countActiveFilters'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const filterOptions = {
  searchFilter: { visibleOnBar: true },
  assigneeId: { visibleOnBar: true },
  originalDueDateRange: { visibleOnBar: true },
  currentDueDateRange: { visibleOnBar: true },
  businessObjectType: { visibleOnBar: false },
  status: { visibleOnBar: true },
  deal: { visibleOnBar: true },
  property: { visibleOnBar: true },
  businessPartner: { visibleOnBar: true },
}

const setInitialFilterState = (queryParams) => ({
  search_filter: queryParams.get('search_filter') || '',
  original_due_date_range: queryParams.get('original_due_date_range') || '',
  current_due_date_range: queryParams.get('current_due_date_range') || '',
  status: queryParams.get('status') || '',
  entity_type: queryParams.get('entity_type') || '',
  assigneeId: queryParams.get('assigneeId') || '',
  bp_ids: queryParams.get('bp_ids') || '',
  property_ids: queryParams.get('property_ids') || '',
  deal_ids: queryParams.get('deal_ids') || '',
})

const isEnterButtonEvent = (event) => event.code === 'Enter'

const EventOverviewFilterBar = ({
  showFilterFields = ['searchFilter', 'originalDueDateRange', 'currentDueDateRange'],
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.event-overview.filter-bar' })
  const { t: tNoKeyPrefix } = useTranslation()
  const [queryParams, setQueryParams] = useSearchParams()
  const [filterParams, setFilterParams] = useState(setInitialFilterState(queryParams))
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false)
  const { localePattern, format, parse } = useShortDateFormatter()

  const eventStatusList = useMemo(
    () => (filterParams.status ? filterParams.status.split(',') : []),
    [filterParams.status],
  )

  useEffect(() => {
    setFilterParams(setInitialFilterState(queryParams))
  }, [queryParams])

  const activeFiltersCount = useMemo(() => countActiveFilters(filterParams), [filterParams])

  const onKeyDown = (event) => {
    if (isEnterButtonEvent(event) && !isFilterDialogOpen) {
      setQueryParams({ ...Object.fromEntries(queryParams.entries()), ...filterParams })
    }
  }

  const onFilter = () => {
    setQueryParams({ ...Object.fromEntries(queryParams.entries()), ...filterParams })
  }

  const formatDateRangePickerValue = (dueDateRange) => {
    if (!dueDateRange) return
    const { startDate, endDate } = parseDueDateRange(dueDateRange)
    return `${format(startDate)} - ${format(endDate)}`
  }

  const { handleSearchDialogSelectionChanges, onClearSelections, onDeleteReferences } =
    useReferenceFilterHandler({
      setFilterParams,
      filterParams,
    })

  const getBpFilters = useCallback(
    () => mapEntityTypeFilters(filterParams.bp_ids, cwpEntityTypes.BUSINESS_PARTNER),
    [filterParams],
  )

  const getPropertyFilters = useCallback(
    () => mapEntityTypeFilters(filterParams.property_ids, cwpEntityTypes.PROPERTY),
    [filterParams],
  )

  const getDealFilters = useCallback(
    () => mapEntityTypeFilters(filterParams.deal_ids, cwpEntityTypes.DEAL),
    [filterParams],
  )

  const possibleFilterBarItems = {
    searchFilter: (
      <FilterGroupItem label={t('search')} key="searchFilter">
        <Input
          id="events-overview-search-input"
          value={filterParams.search_filter}
          onInput={({ target: { value: search_filter } }) => {
            setFilterParams({ ...filterParams, search_filter })
          }}
          onKeyDown={onKeyDown}
          showClearIcon
        />
      </FilterGroupItem>
    ),
    businessPartner: (
      <FilterGroupItem key="businessPartner" label={t('bp-filter')}>
        <BusinessPartnerMultiInput
          selectedReferences={getBpFilters()}
          handleOnChange={handleSearchDialogSelectionChanges}
          onDeleteReferences={onDeleteReferences}
          onClearSelections={onClearSelections}
        />
      </FilterGroupItem>
    ),
    deal: (
      <FilterGroupItem key="deal" label={t('deal-filter')}>
        <DealMultiInput
          handleOnChange={handleSearchDialogSelectionChanges}
          selectedReferences={getDealFilters()}
          onDeleteReferences={onDeleteReferences}
          onClearSelections={onClearSelections}
        />
      </FilterGroupItem>
    ),
    property: (
      <FilterGroupItem key="property" label={t('property-filter')}>
        <PropertyMultiInput
          handleOnChange={handleSearchDialogSelectionChanges}
          selectedReferences={getPropertyFilters()}
          onDeleteReferences={onDeleteReferences}
          onClearSelections={onClearSelections}
        />
      </FilterGroupItem>
    ),
    businessObjectType: (
      <FilterGroupItem key="businessObjectType" label={t('business-object-type')}>
        <Select
          id="events-overview-entity-type-select"
          onChange={({
            detail: {
              selectedOption: { value: entityRefType },
            },
          }) => {
            setFilterParams({ ...filterParams, entity_type: entityRefType })
          }}
          value={filterParams.entity_type}
        >
          {[
            <Option key={-1} value={''} selected={filterParams.businessObjectType === ''} />,
            ...Object.keys(entityTypes).map((key) => (
              <Option
                key={`entity-type-${key}`}
                value={entityTypes[key]}
                selected={filterParams.businessObjectType === entityTypes[key]}
              >
                {tNoKeyPrefix(`events.entity-type.${entityTypes[key]}`)}
              </Option>
            )),
          ]}
        </Select>
      </FilterGroupItem>
    ),
    assigneeId: (
      <FilterGroupItem key="assigneeId" label={t('assignee')}>
        <AssigneeFilter
          onAssigneeChange={(assignee) =>
            setFilterParams({
              ...filterParams,
              assigneeId: assignee,
            })
          }
          assigneeId={filterParams.assigneeId}
          displayPlaceholderText={false}
        />
      </FilterGroupItem>
    ),
    originalDueDateRange: (
      <FilterGroupItem key="originalDueDateRange" label={t('due-date.original')}>
        <DateRangePicker
          id="events-overview-original-date-range-picker"
          formatPattern={localePattern}
          placeholder=""
          onChange={({ detail: { value: original_due_date_range } }) => {
            if (!original_due_date_range) {
              setFilterParams({
                ...filterParams,
                original_due_date_range: '',
              })
              return
            }
            const { startDate, endDate } = parseDueDateRange(original_due_date_range)
            const ISOdueDateStart = parse(startDate, localePattern)
            const ISOdueDateEnd = parse(endDate, localePattern)
            setFilterParams({
              ...filterParams,
              original_due_date_range: `${ISOdueDateStart} - ${ISOdueDateEnd}`,
            })
          }}
          value={formatDateRangePickerValue(queryParams.get('original_due_date_range'))}
        />
      </FilterGroupItem>
    ),
    currentDueDateRange: (
      <FilterGroupItem key="currentDueDateRange" label={t('due-date.current')}>
        <DateRangePicker
          id="events-overview-current-date-range-picker"
          placeholder=""
          formatPattern={localePattern}
          onChange={({ detail: { value: current_due_date_range } }) => {
            if (!current_due_date_range) {
              setFilterParams({
                ...filterParams,
                current_due_date_range: '',
              })
              return
            }
            const { startDate, endDate } = parseDueDateRange(current_due_date_range)
            const ISOdueDateStart = parse(startDate, localePattern)
            const ISOdueDateEnd = parse(endDate, localePattern)
            setFilterParams({
              ...filterParams,
              current_due_date_range: `${ISOdueDateStart} - ${ISOdueDateEnd}`,
            })
          }}
          value={formatDateRangePickerValue(queryParams.get('current_due_date_range'))}
        />
      </FilterGroupItem>
    ),
    status: (
      <FilterGroupItem key="status" label={t('status')}>
        <EventStatusComboBox
          id="events-overview-status-select"
          selected={eventStatusList}
          onSelectionChange={(eventStatusValues) =>
            setFilterParams({
              ...filterParams,
              status: eventStatusValues.join(','),
            })
          }
          onKeyDown={onKeyDown}
        />
      </FilterGroupItem>
    ),
  }

  const filterBarItems = showFilterFields.map((filterField) => possibleFilterBarItems[filterField])

  return (
    <DashboardFilterBar
      filterOptions={filterOptions}
      activeFiltersCount={activeFiltersCount}
      onGo={onFilter}
      setDialogOpen={setIsFilterDialogOpen}
    >
      {filterBarItems}
    </DashboardFilterBar>
  )
}

EventOverviewFilterBar.propTypes = {
  showFilterFields: PropType.arrayOf(
    PropType.oneOf([
      'searchFilter',
      'businessObjectType',
      'originalDueDateRange',
      'currentDueDateRange',
      'status',
      'assigneeId',
      'deal',
      'property',
      'businessPartner',
    ]),
  ),
}

export default EventOverviewFilterBar
