import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useDealPermissions = (dealUuid, options = {}) => {
  let path = '/deals/allowed-operations'
  const queryParams = new URLSearchParams()

  if (dealUuid) {
    queryParams.append('deal-uuid', dealUuid)
    path += `?${queryParams.toString()}`
  }

  return useCamelizedResponse(
    useRequest({
      path,
      useCache: true,
      keys: ['deals', 'allowed-operations', dealUuid],
      ...options,
    }),
  )
}
