import { configureStore } from '@reduxjs/toolkit'
import arrearsReducer from 'redux/combinedReducers/arrearsReducer'
import conditionsReducer from 'redux/combinedReducers/conditionsReducer'
import dealsReducer from 'redux/combinedReducers/dealsReducer'
import decisionPaperReducer from 'redux/combinedReducers/decisionPaperReducer'
import eventsReducer from 'redux/combinedReducers/eventsReducer'
import marketsReducer from 'redux/combinedReducers/marketsReducer'
import propertiesReducer from 'redux/combinedReducers/propertiesReducer'
import requestReducer from 'redux/combinedReducers/requestReducer'
import tasksReducer from 'redux/combinedReducers/tasksReducer'

export const reducer = {
  arrears: arrearsReducer,
  events: eventsReducer,
  markets: marketsReducer,
  request: requestReducer,
  tasks: tasksReducer,
  properties: propertiesReducer,
  decisionPaper: decisionPaperReducer,
  deals: dealsReducer,
  conditions: conditionsReducer,
}

export default configureStore({ reducer })
