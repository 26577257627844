import { MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import { useContext } from 'react'
import styles from 'components/ui/environment-indicator/EnvironmentIndicator.module.css'
import CloseableMessageStrip from 'components/ui/feedback/CloseableMessageStrip'
import { ConfigContext } from 'hooks/config/useConfig'

const EnvironmentIndicator = () => {
  const config = useContext(ConfigContext)

  if (!config.environmentText) {
    return
  }

  return (
    <>
      <CloseableMessageStrip className={styles.environmentText} design={MessageStripDesign.Warning}>
        {config.environmentText}
      </CloseableMessageStrip>
    </>
  )
}

export default EnvironmentIndicator
