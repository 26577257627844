import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { calculateTenancyBrandingFranchiseProvider } from 'hooks/services/business-partners/tenancy/calculateTenancyBrandingFranchiseProvider'
import { getPropertyAndDealCountBySegmentUsageType } from 'hooks/services/business-partners/tenancy/getPropertyAndDealCountBySegmentUsageType'
import { tenancyHeaderInformationValueType } from 'hooks/services/business-partners/tenancy/tenancyHeaderInformationValueType'

export const useTenantTopHeaderInformation = (
  rentalUnitsDataByFinancingStatusActive,
  rentalUnitsDataByFinancingStatusPipeline,
  propertyDeals,
) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.tenancy.as-tenant',
  })

  /* no rented properties */
  const activePropertiesCount =
    rentalUnitsDataByFinancingStatusActive?.matchingProperties.length ?? 0
  const pipelinePropertiesCount =
    rentalUnitsDataByFinancingStatusPipeline?.matchingProperties.length ?? 0

  const activePropertiesPerSegmentUsageType = getPropertyAndDealCountBySegmentUsageType(
    rentalUnitsDataByFinancingStatusActive?.matchingRentalUnits ?? [],
    propertyDeals,
  )
  const pipelinePropertiesPerSegmentUsageType = getPropertyAndDealCountBySegmentUsageType(
    rentalUnitsDataByFinancingStatusPipeline?.matchingRentalUnits ?? [],
    propertyDeals,
  )

  /* no of deals */
  const activeDealCount = rentalUnitsDataByFinancingStatusActive?.matchingDeals.length ?? 0
  const pipelineDealCount = rentalUnitsDataByFinancingStatusPipeline?.matchingDeals.length ?? 0

  /* branding/franchise provider */
  const activeAndPipelineRentalUnits =
    rentalUnitsDataByFinancingStatusActive?.matchingRentalUnits.concat(
      rentalUnitsDataByFinancingStatusPipeline?.matchingRentalUnits ?? [],
    ) ?? []
  const brandingFranchsieBps = calculateTenancyBrandingFranchiseProvider(
    activeAndPipelineRentalUnits,
  )

  return useMemo(
    () => [
      {
        caption: {
          kpiName: t('nr-rented-properties'),
        },
        activeDeal: {
          kpiValue: activePropertiesCount,
          kpiValueType: tenancyHeaderInformationValueType.plain,
          segmentUsageTypes: activePropertiesPerSegmentUsageType.properties,
        },
        pipelineDeal: {
          kpiValue: pipelinePropertiesCount,
          kpiValueType: tenancyHeaderInformationValueType.plain,
          segmentUsageTypes: pipelinePropertiesPerSegmentUsageType.properties,
        },
      },
      {
        caption: {
          kpiName: t('nr-deals'),
        },
        activeDeal: {
          kpiValue: activeDealCount,
          kpiValueType: tenancyHeaderInformationValueType.plain,
          segmentUsageTypes: activePropertiesPerSegmentUsageType.deals,
        },
        pipelineDeal: {
          kpiValue: pipelineDealCount,
          kpiValueType: tenancyHeaderInformationValueType.plain,
          segmentUsageTypes: pipelinePropertiesPerSegmentUsageType.deals,
        },
      },
      {
        caption: {
          kpiName: t('branding-franchise'),
        },
        entityIds: brandingFranchsieBps,
      },
    ],
    [
      activeDealCount,
      activePropertiesCount,
      activePropertiesPerSegmentUsageType,
      brandingFranchsieBps,
      pipelineDealCount,
      pipelinePropertiesCount,
      pipelinePropertiesPerSegmentUsageType,
      t,
    ],
  )
}
