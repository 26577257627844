import { FlexBox, FlexBoxAlignItems } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/entity-info/EntityTypeAndIdWithClipboard.module.css'

const DecisionPaperPdfExportSubheader = ({ entityType, name, id }) => {
  const { t } = useTranslation()

  return (
    <FlexBox className={styles.entityTypeNameId} alignItems={FlexBoxAlignItems.Center}>
      <div>{`${t(`events.entity-type.${entityType}`)}: ${name} (${id})`}</div>
    </FlexBox>
  )
}

DecisionPaperPdfExportSubheader.propTypes = {
  name: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default DecisionPaperPdfExportSubheader
