import { combineReducers } from '@reduxjs/toolkit'
import requestCreationReducer from 'redux/slices/valuation/requestCreationSlice'
import valuationRequestTableReducer from 'redux/slices/valuation/valuationRequestsTableSlice'

const requestReducer = combineReducers({
  requestCreation: requestCreationReducer,
  valuationRequestsTable: valuationRequestTableReducer,
})

export default requestReducer
