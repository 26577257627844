import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/gam-statement/shared/FormattedTextView.module.css'
import ImageView from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/gam-statement/shared/ImageView'

const emptyContent = ''
const image = 'img'

const FormattedTextView = ({ formattedText }) => (
  <div className={styles.formattedTextView}>
    <div className="ck-content">
      {formattedText &&
        formattedText !== emptyContent &&
        parse(formattedText, {
          replace: ({ name, attribs }) => {
            if (name === image) {
              return <ImageView {...attribs} />
            }
          },
        })}
    </div>
  </div>
)

FormattedTextView.propTypes = {
  formattedText: PropTypes.string.isRequired,
}

export default FormattedTextView
