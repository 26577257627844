import orderBy from 'lodash.orderby'
import { DateTime } from 'luxon'
import { decisionStageStatus } from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/components/utils/decisionProcessApi'

const hasOneDecisionStage = (decisionStages) => decisionStages.length === 1

const countDecisionStagesInSpecificStatus = (expectedStatus, decisionStages) =>
  decisionStages.reduce(
    (currentValue, decisionStage) =>
      decisionStage.info.decisionStatus === expectedStatus ? currentValue + 1 : currentValue,
    0,
  )

const atLeastOneStagesHasStatusPlanned = (decisionStages) =>
  countDecisionStagesInSpecificStatus(decisionStageStatus.planned, decisionStages) > 1

const someDecisionStagesHaveDecisionDate = (decisionStagesToCheck) =>
  Object.values(decisionStagesToCheck).some((decisionStage) => decisionStage.info.decisionDate)

const getFirstAlphabeticallySortedStage = (decisionStagesToSort) => {
  decisionStagesToSort.sort((a, b) => a.info.name.localeCompare(b.info.name))

  return decisionStagesToSort[0]
}

const getPlannedDecisionStageWithDecisionDateToDisplay = (plannedDecisionStages) => {
  const plannedDecisionStagesWithDates = plannedDecisionStages.filter(
    (decisionStage) => decisionStage.info.decisionDate,
  )

  plannedDecisionStagesWithDates.sort(
    (a, b) =>
      new Date(a.info.decisionDate) - new Date(b.info.decisionDate) ||
      a.info.name.localeCompare(b.info.name),
  )

  return plannedDecisionStagesWithDates[0]
}

const getPlannedDecisionStageToDisplay = (decisionStages) => {
  const plannedDecisionStages = decisionStages.filter(
    (decisionStage) => decisionStage.info.decisionStatus === decisionStageStatus.planned,
  )

  if (someDecisionStagesHaveDecisionDate(plannedDecisionStages)) {
    return getPlannedDecisionStageWithDecisionDateToDisplay(plannedDecisionStages)
  }

  return getFirstAlphabeticallySortedStage(plannedDecisionStages)
}

const getInProgressDecisionStageToDisplay = (decisionStages) => {
  const inProgressDecisionStages = orderBy(
    decisionStages.filter(
      ({ info: { decisionStatus } }) =>
        decisionStatus === decisionStageStatus.inProgress ||
        decisionStatus === decisionStageStatus.inProgressRequestDiscarded,
    ),
    ['info.decisionDate', 'info.name'],
    ['desc', 'asc'],
  )
  const inProgressDecisionStagesWithDecisionDate = inProgressDecisionStages.filter(
    (decisionStage) => !!decisionStage.info.decisionDate,
  )

  if (
    inProgressDecisionStages.length === 1 ||
    inProgressDecisionStagesWithDecisionDate.length === 0
  ) {
    return inProgressDecisionStages[0]
  }

  const today = DateTime.now().toISODate()
  const farestDecisionStageWithDecisionDateBeforeToday = [
    ...inProgressDecisionStagesWithDecisionDate,
  ]
    .reverse()
    .find(({ info: { decisionDate } }) => decisionDate < today)
  if (farestDecisionStageWithDecisionDateBeforeToday) {
    return farestDecisionStageWithDecisionDateBeforeToday
  }
  return inProgressDecisionStagesWithDecisionDate.at(-1)
}

const atLeastOneStageInStatusInProgress = (decisionStages) => {
  const inProgressDecisionStagesAmount = countDecisionStagesInSpecificStatus(
    decisionStageStatus.inProgress,
    decisionStages,
  )
  const inProgressDiscardedDecisionStagesAmount = countDecisionStagesInSpecificStatus(
    decisionStageStatus.inProgressRequestDiscarded,
    decisionStages,
  )

  return inProgressDecisionStagesAmount + inProgressDiscardedDecisionStagesAmount > 0
}

const allDecisionStagesInFinalStateOrPlanned = (decisionStages) =>
  decisionStages.every(
    ({ info: { decisionStatus } }) =>
      decisionStatus === decisionStageStatus.approved ||
      decisionStatus === decisionStageStatus.approvedWithConditions ||
      decisionStatus === decisionStageStatus.declined ||
      decisionStatus === decisionStageStatus.reconvened ||
      decisionStatus === decisionStageStatus.planned,
  ) &&
  !decisionStages.every(
    ({ info: { decisionStatus } }) => decisionStatus === decisionStageStatus.planned,
  )

const getLatestFinalDecisionStageToDisplay = (decisionStages) => {
  const filteredDecisionStages = decisionStages.filter(
    ({ info: { decisionStatus } }) =>
      decisionStatus === decisionStageStatus.approved ||
      decisionStatus === decisionStageStatus.approvedWithConditions ||
      decisionStatus === decisionStageStatus.declined ||
      decisionStatus === decisionStageStatus.reconvened,
  )
  const sortedDecisionStages = orderBy(
    filteredDecisionStages,
    ['info.decisionDate', 'info.name'],
    ['desc', 'asc'],
  )
  return sortedDecisionStages[0]
}

const atLeast2DecisionStagesInStatePlannedAndNoneInProgress = (decisionStages) => {
  const plannedDecisionCount = countDecisionStagesInSpecificStatus(
    decisionStageStatus.planned,
    decisionStages,
  )

  const inProgressDecisionCount = decisionStages.filter(
    ({ info: { decisionStatus } }) =>
      decisionStatus === decisionStageStatus.inProgress ||
      decisionStatus === decisionStageStatus.inProgressRequestDiscarded,
  ).length

  return plannedDecisionCount >= 2 && inProgressDecisionCount === 0
}

const calculateDecisionStageToDisplay = (decisionStages) => {
  if (decisionStages.length === 0) {
    return
  }
  if (hasOneDecisionStage(decisionStages)) {
    return decisionStages[0]
  }
  if (atLeast2DecisionStagesInStatePlannedAndNoneInProgress(decisionStages)) {
    return getPlannedDecisionStageToDisplay(decisionStages)
  }
  if (allDecisionStagesInFinalStateOrPlanned(decisionStages)) {
    return getLatestFinalDecisionStageToDisplay(decisionStages)
  }
  if (atLeastOneStageInStatusInProgress(decisionStages)) {
    return getInProgressDecisionStageToDisplay(decisionStages)
  }
  if (atLeastOneStagesHasStatusPlanned(decisionStages)) {
    return getPlannedDecisionStageToDisplay(decisionStages)
  }
}

export default calculateDecisionStageToDisplay
