import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { conditionsStatusTypes } from 'api/conditions/conditions'
import RequirementsMassEditDialog from 'components/domains/conditions/dialogs/requirements/mass-editing/RequirementsMassEditDialog'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const atLeastOneRequirementInStatusDone = (requirements) =>
  Object.values(requirements).some(({ status }) => status === conditionsStatusTypes.done)

const RequirementsMassEditButton = ({ disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.toolbar',
  })
  const {
    entityRef: { entityId, entityType },
  } = useContext(ConditionsContext)

  const selectedRequirementIds = useSelector(
    (state) => state.conditions.requirementsTable.selectedRows.selectedRequirementIds,
  )

  const selectedRequirementIdList = useMemo(
    () => Object.keys(selectedRequirementIds),
    [selectedRequirementIds],
  )

  const businessObjectRefs = useMemo(
    () =>
      Object.values(selectedRequirementIds).map(
        (requirement) => requirement.businessObjectRef ?? { entityType, entityId },
      ),
    [entityId, entityType, selectedRequirementIds],
  )

  const [isMassEditDialogOpen, setIsMassEditDialogOpen] = useState(false)

  const isButtonDisabled = useMemo(
    () =>
      isEmpty(selectedRequirementIdList) ||
      atLeastOneRequirementInStatusDone(selectedRequirementIds) ||
      disabled,
    [selectedRequirementIds, selectedRequirementIdList, disabled],
  )

  const openMassEditButton = useCallback(() => {
    setIsMassEditDialogOpen(true)
  }, [setIsMassEditDialogOpen])

  return (
    <>
      <Button
        design={ButtonDesign.Transparent}
        onClick={openMassEditButton}
        disabled={isButtonDisabled}
      >
        {t('buttons.edit-multiple')}
      </Button>
      {isMassEditDialogOpen && (
        <RequirementsMassEditDialog
          selectedRequirementIds={selectedRequirementIdList}
          selectedBusinessObjectRefs={businessObjectRefs}
          open={isMassEditDialogOpen}
          setIsOpen={setIsMassEditDialogOpen}
        />
      )}
    </>
  )
}

RequirementsMassEditButton.propTypes = {
  disabled: PropTypes.bool,
}

export default RequirementsMassEditButton
