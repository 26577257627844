import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * Returns all available card translation keys in the change log set
 * The response body structure is as follows:
 * {
 *   "cardKeys": [{
 *      id: 'card-id',
 *      card_translations_key: 'card.translation.key',
 *      page_translation_key: 'page.translation.key,
 *    }, ...]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: cardKeys}}
 */
const useChangeLogCards = ({ entityId, entityType }) => {
  const queryParams = new URLSearchParams()
  if (entityId) queryParams.set('entity_id', entityId)
  if (entityType) queryParams.set('entity_type', entityType)

  return useCamelizedResponse(
    useRequest({
      path: `/traceability/configurations/card-keys?${queryParams.toString()}`,
      keys: ['traceability', 'configuration', 'card-keys', queryParams.toString()],
      translated: true,
      useCache: false,
    }),
  )
}

export default useChangeLogCards
