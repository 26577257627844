import {
  Button,
  ButtonDesign,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
  ObjectStatus,
  PopoverPlacementType,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useMemo, useState, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionStageVoterStatus } from 'api/decision-process/decisionProcessApi'
import styles from 'components/domains/business-events-and-tasks/decision-stage/voters-table/DecisionStageVotersTable.module.css'
import VotersTableDepartmentCell from 'components/domains/business-events-and-tasks/decision-stage/voters-table/table-cells/VotersTableDepartmentCell'
import VotersTableNameCell from 'components/domains/business-events-and-tasks/decision-stage/voters-table/table-cells/VotersTableNameCell'
import VotersTableTypeCell from 'components/domains/business-events-and-tasks/decision-stage/voters-table/table-cells/VotersTableTypeCell'
import { CancelPopoverContent } from 'components/ui/button/CancelButtonWithPopover'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'

const voterCancelButtonId = 'voter-cancel-button'
const toolbarAddButtonId = 'toolbar-add-button'
const newVoter = {
  id: '',
  userId: '',
  type: {
    code: '',
    name: '',
  },
  status: {
    code: '',
    name: '',
  },
}

const DecisionStageVotersTable = ({
  voters,
  eventId,
  decisionStageId,
  showAddButton,
  deleteVoter,
  createVoter,
  editVoter,
  useVoteTypes,
  renderActionCell,
  getObjectStatusForVoteStatus,
  isPdfView = false,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.voters-table',
  })
  const { t: tNoPrefix } = useTranslation()
  const queryClient = useQueryClient()
  const popoverRef = useRef()
  const showToast = Modals.useShowToast()
  const showMessageBox = Modals.useShowMessageBox()
  const showPopover = Modals.useShowPopover()
  const [currentRow, setCurrentRow] = useState()
  const [highlightRow, setHighlightRow] = useState([])
  const [editedRow, setEditedRow] = useState()
  const [addNewRow, setAddNewRow] = useState(false)

  const onNameSelected = useCallback(
    (newUserId) => {
      setEditedRow({ ...editedRow, userId: newUserId })
    },
    [editedRow],
  )

  const onTypeChange = useCallback(
    (newTypeCode) => {
      setEditedRow({ ...editedRow, type: { code: newTypeCode } })
    },
    [editedRow],
  )

  const hasAnyVoterDeclinedWithReason = useMemo(
    () => voters.find(({ declineReason }) => !!declineReason),
    [voters],
  )

  const columnDefinitions = useMemo(() => {
    const columns = [
      {
        title: t('columns.name'),
        columnKey: 'name',
        sortingDisabled: true,
      },
      {
        title: t('columns.department'),
        columnKey: 'department',
        sortingDisabled: true,
      },
      {
        title: t('columns.vote-type'),
        columnKey: 'voteType',
        sortingDisabled: true,
      },
      {
        title: t('columns.vote-status'),
        columnKey: 'voteStatus',
        sortingDisabled: true,
      },
      {
        title: '',
        columnKey: 'actions',
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
    ]
    if (hasAnyVoterDeclinedWithReason) {
      columns.push({
        title: t('columns.decline-reason'),
        columnKey: 'declineReason',
        sortingDisabled: true,
      })
    }
    return columns
  }, [t, hasAnyVoterDeclinedWithReason])
  const isSaveButtonDisabled = useMemo(() => {
    if (editedRow?.userId === '' || editedRow?.type.code === '') {
      return true
    }

    return (
      currentRow?.userId === editedRow?.userId && currentRow?.type.code === editedRow?.type.code
    )
  }, [currentRow, editedRow])
  const onCancelButtonConfirmed = useCallback(() => {
    setCurrentRow()
    setEditedRow()
    setAddNewRow(false)
  }, [])

  const onSaveCancelButtonClicked = useCallback(() => {
    if (!isSaveButtonDisabled) {
      popoverRef.current = showPopover({
        opener: voterCancelButtonId,
        placementType: PopoverPlacementType.Top,
        children: (
          <CancelPopoverContent
            onCancelClicked={() => {
              popoverRef.current.close()
              onCancelButtonConfirmed()
            }}
          />
        ),
      })
      return
    }
    onCancelButtonConfirmed()
  }, [isSaveButtonDisabled, showPopover, onCancelButtonConfirmed])

  const onEditButtonClicked = useCallback(
    ({ id, userId, voteTypeCode }) =>
      () => {
        setEditedRow({ id, userId, type: { code: voteTypeCode } })
        setCurrentRow({ id, userId, type: { code: voteTypeCode } })
      },
    [],
  )

  const onSuccessDeleteVoter = useCallback(() => {
    queryClient.invalidateQueries(['events', eventId, 'decision-stages', decisionStageId])
    showToast({ children: t('delete.success.text') })
  }, [decisionStageId, queryClient, eventId, showToast, t])

  const onError = useCallback(() => {
    showMessageBox({
      children: t('error.text'),
      type: MessageBoxTypes.Error,
      draggable: true,
      resizable: true,
    })
  }, [showMessageBox, t])

  const handleDeleteConfirmation = useCallback(
    (voterId) => () => {
      deleteVoter(
        { eventId, decisionStageId, voterId },
        { onSuccess: onSuccessDeleteVoter, onError: onError },
      )
    },
    [deleteVoter, onSuccessDeleteVoter, onError, eventId, decisionStageId],
  )

  const onDeleteButtonClicked = useCallback(
    ({ id }) =>
      () => {
        showMessageBox({
          children: t('delete.text'),
          titleText: t('delete.title'),
          actions: [
            <Button
              key={`delete-voter-${id}`}
              design={ButtonDesign.Emphasized}
              onClick={handleDeleteConfirmation(id)}
            >
              {tNoPrefix('buttons.delete')}
            </Button>,
            <Button
              key="delete-voter-cancel-button"
              data-action={MessageBoxActions.Cancel}
              design={ButtonDesign.Transparent}
            >
              {tNoPrefix('buttons.cancel')}
            </Button>,
          ],
          draggable: true,
          resizable: true,
        })
      },
    [handleDeleteConfirmation, showMessageBox, t, tNoPrefix],
  )

  const addNewVoterRow = useCallback(() => {
    setCurrentRow(newVoter)
    setEditedRow(newVoter)
    setAddNewRow(true)
  }, [])

  const addVoterButton = useMemo(
    () =>
      showAddButton && (
        <Button
          key={toolbarAddButtonId}
          disabled={addNewRow}
          onClick={addNewVoterRow}
          design={ButtonDesign.Transparent}
        >
          {t('toolbar.add-button')}
        </Button>
      ),

    [addNewVoterRow, addNewRow, showAddButton, t],
  )

  const departmentCellVoter = useCallback(
    (rowId, userId) => {
      if (editedRow) {
        return rowId === editedRow.id ? editedRow.userId : userId
      }
      return userId
    },
    [editedRow],
  )

  const onSaveSuccess = useCallback(
    (id, toastMessage) => {
      setAddNewRow(false)
      setHighlightRow([...highlightRow, id])
      setCurrentRow()
      setEditedRow()
      queryClient.invalidateQueries(['events', eventId, 'decision-stages', decisionStageId])
      showToast({ children: toastMessage })
    },
    [decisionStageId, eventId, highlightRow, queryClient, showToast],
  )

  const onSaveButtonClicked = useCallback(() => {
    const editedVoterId = editedRow.userId // NOSONAR
    const rowId = currentRow.id // NOSONAR
    const voteTypeCode = editedRow.type.code // NOSONAR
    if (addNewRow) {
      createVoter(
        { eventId, decisionStageId, voterId: editedVoterId, voteTypeCode },
        {
          onSuccess: ({ data: { id } }) => onSaveSuccess(id, t('create.success-notification')),
          onError: onError,
        },
      )
      return
    }

    editVoter(
      {
        eventId,
        decisionStageId,
        id: rowId,
        voterId: editedVoterId,
        voteTypeCode,
      },
      {
        onSuccess: () => onSaveSuccess(rowId, t('update.success-notification')),
        onError: onError,
      },
    )
  }, [
    addNewRow,
    createVoter,
    currentRow?.id,
    decisionStageId,
    editVoter,
    editedRow?.userId,
    editedRow?.type.code,
    eventId,
    onError,
    onSaveSuccess,
    t,
  ])

  const votersData = useMemo(
    () => (addNewRow ? [newVoter, ...voters] : voters),
    [addNewRow, voters],
  )

  const tableData = useMemo(
    () =>
      votersData.map(
        ({
          id,
          userId,
          type: { name: voteTypeName, code: voteTypeCode },
          status: { code: statusCode },
          declineReason,
        }) => {
          const isEditModeForCurrentRow = id === editedRow?.id
          const areOtherRowButtonsDisabled = editedRow ? id !== editedRow.id : false
          const { translationKey, objectStatus } = getObjectStatusForVoteStatus(statusCode)
          return {
            rowKey: `voter-${userId}-${id}`,
            rowProperties: highlightRow.includes(id)
              ? { className: styles.hightlightNewOrEditedRow }
              : {},
            name: {
              cellComponent: (
                <VotersTableNameCell
                  userId={isEditModeForCurrentRow ? editedRow.userId : userId} // NOSONAR
                  onNameSelected={onNameSelected}
                  isEditMode={isEditModeForCurrentRow}
                />
              ),
            },
            department: {
              cellComponent: <VotersTableDepartmentCell userId={departmentCellVoter(id, userId)} />,
            },
            voteType: {
              cellComponent: (
                <VotersTableTypeCell
                  voteTypeName={voteTypeName}
                  onTypeChange={onTypeChange}
                  isEditMode={isEditModeForCurrentRow}
                  showEmptyOption={addNewRow}
                  voteTypeCode={voteTypeCode}
                  eventId={eventId}
                  decisionStageId={decisionStageId}
                  loadingHook={useVoteTypes}
                />
              ),
            },
            voteStatus: {
              cellComponent: !addNewRow && statusCode !== decisionStageVoterStatus.ignored && (
                <ObjectStatus inverted state={objectStatus}>
                  {tNoPrefix(translationKey)}
                </ObjectStatus>
              ),
            },
            declineReason: {
              cellComponent: <Text wrapping>{declineReason}</Text>,
            },
            actions: {
              cellComponent: renderActionCell({
                voterCancelButtonId,
                isEditModeForCurrentRow,
                isEditButtonDisabled: areOtherRowButtonsDisabled,
                isDeleteButtonDisabled: areOtherRowButtonsDisabled,
                onSaveCancelButtonClicked,
                onSaveButtonClicked,
                isSaveButtonDisabled,
                onEditButtonClicked: onEditButtonClicked({ id, userId, voteTypeCode }),
                onDeleteButtonClicked: onDeleteButtonClicked({ id }),
                userId,
                voterStatusCode: statusCode,
                voterId: id,
              }),
            },
          }
        },
      ),
    [
      votersData,
      highlightRow,
      onNameSelected,
      editedRow,
      departmentCellVoter,
      onTypeChange,
      addNewRow,
      eventId,
      decisionStageId,
      onSaveCancelButtonClicked,
      onSaveButtonClicked,
      isSaveButtonDisabled,
      onEditButtonClicked,
      onDeleteButtonClicked,
      tNoPrefix,
      useVoteTypes,
      renderActionCell,
      getObjectStatusForVoteStatus,
    ],
  )
  return (
    <SortedTable
      tableData={tableData}
      columnDefinitions={columnDefinitions}
      toolbarConfig={{
        title: t('toolbar.title'),
        additionalActions: showAddButton ? [addVoterButton] : [],
        showColumnSelection: !isPdfView,
      }}
      noDataText={t('no-data')}
    />
  )
}

DecisionStageVotersTable.propTypes = {
  voters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      type: PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
      status: PropTypes.shape({
        code: PropTypes.string.isRequired,
      }),
    }),
  ),
  eventId: PropTypes.string.isRequired,
  decisionStageId: PropTypes.string.isRequired,
  deleteVoter: PropTypes.func.isRequired,
  createVoter: PropTypes.func.isRequired,
  editVoter: PropTypes.func.isRequired,
  useVoteTypes: PropTypes.func.isRequired,
  showAddButton: PropTypes.bool.isRequired,
  renderActionCell: PropTypes.func.isRequired,
  getObjectStatusForVoteStatus: PropTypes.func.isRequired,
  isPdfView: PropTypes.bool,
}

export default DecisionStageVotersTable
