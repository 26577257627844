import { useQueries } from '@tanstack/react-query'
import camelize from 'camelize'
import chunk from 'lodash.chunk'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

export const dealFilterPropTypes = PropTypes.shape({
  dealId: PropTypes.string,
  dealName: PropTypes.string,
  accountManager: PropTypes.string,
  businessSegments: PropTypes.string,
  dealTypes: PropTypes.string,
  originationPlatform: PropTypes.string,
  originationTeam: PropTypes.string,
  status: PropTypes.string,
  borrowerId: PropTypes.string,
  propertyUuids: PropTypes.arrayOf(PropTypes.string),
  workingVersion: PropTypes.string,
})

// This is just an educated quess of what is best for no specific reason besides that there is an max url length
const MAX_NUMBER_OF_PROPERTIES_PER_REQUEST = 40

const hasPageWithOffset = ({ offset, total }) => offset < total

const createRemainingRequests = ({ pagination, queryParams, path }) => {
  const result = []
  const { limit = 0, total = 0 } = pagination
  let offset = limit
  while (hasPageWithOffset({ offset, total })) {
    const queryParamsWithOffset = new URLSearchParams(queryParams)
    queryParamsWithOffset.append('offset', offset)
    result.push({
      path: `${path}?${queryParamsWithOffset}`,
    })
    offset += limit
  }
  return result
}

const loadRemainingPages = async ({ path, pagination = {}, firstPage, get, queryParams }) => {
  const requests = createRemainingRequests({ path, pagination, queryParams })
  const result = await Promise.all(requests.map((req) => get(req)))
  const deals = result.map(({ data }) => ({
    deals: data.deals,
  }))
  return [firstPage, ...deals]
}

const loadAllPages = async ({ get, queryParams, path }) => {
  const { data } = await get({ path: `${path}?${queryParams}` })
  return loadRemainingPages({
    path,
    pagination: data?.pagination,
    firstPage: data,
    get,
    queryParams,
  })
}

/**
 * Fetches the deals for all given properties by using the deals search endpoint.
 *
 * For more than 10 properties, multiple requests are being made. If there are multiple response pages, all will be loaded.
 */
const useDealsForProperties = ({ propertyUuids }, queryOptions = {}) => {
  const { get } = useAccessTokenRequest()
  const queries = useMemo(
    () =>
      chunk(propertyUuids, MAX_NUMBER_OF_PROPERTIES_PER_REQUEST)
        .map((propertyUuidChunk) => {
          const queryParams = new URLSearchParams()
          propertyUuidChunk.forEach((uuid) => {
            queryParams.append('property_uuid', uuid)
          })
          return queryParams
        })
        .map((queryParams) => ({
          queryKey: ['deals', queryParams.toString()],
          queryFn: async () => {
            const pages = await loadAllPages({
              queryParams,
              get,
              path: '/deals',
            })
            return camelize(pages.flatMap(({ deals }) => deals))
          },
          ...queryOptions,
        })),
    [get, propertyUuids, queryOptions],
  )

  return useCombinedQueryResults(useQueries({ queries }))
}

export default useDealsForProperties
