export const conditionsAllowedOperations = {
  readCondition: 'condition-read',
  createCondition: 'condition-create',
  editCondition: 'condition-edit',
  deleteCondition: 'condition-delete',
  createReminder: 'condition-requirement-reminder-create',
  deleteReminder: 'condition-requirement-reminder-delete',
  editReminder: 'condition-requirement-reminder-edit',
}

export const neededOperationsForConditionsRead = [conditionsAllowedOperations.readCondition]

export const neededOperationsForConditionsCreate = [
  conditionsAllowedOperations.readCondition,
  conditionsAllowedOperations.createCondition,
]

export const neededOperationsForConditionEdit = [conditionsAllowedOperations.editCondition]
export const neededOperationsForConditionDelete = [conditionsAllowedOperations.deleteCondition]
export const neededOperationsForRequirementCreate = [
  conditionsAllowedOperations.readCondition,
  conditionsAllowedOperations.createCondition,
]
export const neededOperationsForRequirementDelete = [conditionsAllowedOperations.deleteCondition]
export const neededOperationsForRequirementEdit = [conditionsAllowedOperations.editCondition]

export const neededOperationsForRequirementReminderCreate = [
  conditionsAllowedOperations.readCondition,
  conditionsAllowedOperations.createReminder,
]

export const neededOperationsForRequirementReminderDelete = [
  conditionsAllowedOperations.deleteReminder,
]

export const neededOperationsForRequirementReminderEdit = [conditionsAllowedOperations.editReminder]
