/**
 * @param {import("hooks/services/documents/documentsSchema").Documents} documents
 * @returns {import("hooks/services/documents/documentsSchema").Document[]}
 * */
const flattenDocuments = (documents) => [
  ...(documents?.documents ?? []),
  ...(documents?.subFolders?.flatMap(flattenDocuments) ?? []),
]

export default flattenDocuments
