import {
  CardHeader,
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-history/PropertyValuationResultsHistoryChartHeader.module.css'
import PropertyValuationsChartHeaderItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-history/PropertyValuationsChartHeaderItem'
import { valuationClassification } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/domains/property/valuation/valuationRequests'

const PropertyValuationResultsHistoryChartHeader = ({ valuationsData = [] }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.fact-sheet-valuation.pages.property.valuation.results',
  })

  const currentValuationsByCodeObject = valuationsData
    ?.map((valuation) => ({
      [valuation?.cwpCode]: valuation?.currentValue,
    }))
    ?.reduce((acc, cur) => Object.assign(acc, cur), {})

  const getValuationObjectByCode = (code) => {
    const valuationObject = currentValuationsByCodeObject?.[code] ?? {}
    const { valueAmount: { number, currency } = {}, keyDate } = valuationObject
    return { number, currency, keyDate }
  }

  return (
    <>
      <CardHeader titleText={t('card.header.title')} />
      <FlexBox
        className={styles.flexBoxWrapperSpacing}
        direction={FlexBoxDirection.Row}
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
      >
        <FlexBox
          direction={FlexBoxDirection.Row}
          justifyContent={FlexBoxJustifyContent.Start}
          className={styles.flexBoxSpacing}
        >
          <PropertyValuationsChartHeaderItem
            code={valuationClassification.marketValue}
            valuationData={getValuationObjectByCode(valuationClassification.marketValue)}
            isLeftAligned={true}
          />
          <PropertyValuationsChartHeaderItem
            code={valuationClassification.mortgageLendingValue}
            valuationData={getValuationObjectByCode(valuationClassification.mortgageLendingValue)}
            isLeftAligned={true}
          />
        </FlexBox>
        <FlexBox>
          <FlexBox
            direction={FlexBoxDirection.Row}
            justifyContent={FlexBoxJustifyContent.End}
            className={styles.flexBoxSpacing}
          >
            <PropertyValuationsChartHeaderItem
              code={valuationClassification.sellOffMarketValue}
              valuationData={getValuationObjectByCode(valuationClassification.sellOffMarketValue)}
              isLeftAligned={false}
            />
            <PropertyValuationsChartHeaderItem
              code={valuationClassification.sellOffMortgageLendingValue}
              valuationData={getValuationObjectByCode(
                valuationClassification.sellOffMortgageLendingValue,
              )}
              isLeftAligned={false}
            />
            <PropertyValuationsChartHeaderItem
              code={valuationClassification.currentStateMortgageLendingValue}
              valuationData={getValuationObjectByCode(
                valuationClassification.currentStateMortgageLendingValue,
              )}
              isLeftAligned={false}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </>
  )
}

PropertyValuationResultsHistoryChartHeader.propTypes = {
  valuationsData: PropTypes.arrayOf(
    PropTypes.shape({
      cwpCode: PropTypes.string,
      currentValue: PropTypes.shape({
        keyDate: PropTypes.string,
        valueAmount: PropTypes.shape({
          number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          currency: PropTypes.string,
        }),
      }),
    }),
  ),
}

export default PropertyValuationResultsHistoryChartHeader
