import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

/**
 * A button component that allows users to download the error content of a row.
 * @param {Object} props - The props object for the component.
 * @param {number} props.rowIndex - The index of thw row in the excel file.
 * @param {string} props.content - The error content of the property create request.
 * @returns {JSX.Element} - A JSX element representing the MultiPropertyUploadDownloadErrorButton component.
 */
const MultiPropertyUploadDownloadErrorButton = ({ rowIndex, content }) => {
  const fileContents = encodeURIComponent(content)
  const dataUri = `data:text/plain;charset=utf-8,${fileContents}`

  const handleClick = () => {
    const timestamp = Date.now()
    const fileName = `properties-upload-error-row-${rowIndex}_${timestamp}.txt`
    const link = document.createElement('a')
    link.href = dataUri
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Button design={ButtonDesign.Transparent} onClick={handleClick}>
      {'Download'}
    </Button>
  )
}
MultiPropertyUploadDownloadErrorButton.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
}

export default MultiPropertyUploadDownloadErrorButton
