import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import BusinessExposureTile from 'components/domains/business-partners/tile/exposure/BusinessExposureTile'

const SettlementRiskOverview = ({ settlementRisk, settlementRiskLimit }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.business-partner' })

  const dataSection = [settlementRisk, settlementRiskLimit]

  return <BusinessExposureTile title={t('settlement-risk-overview')} sections={[dataSection]} />
}

SettlementRiskOverview.propTypes = {
  settlementRisk: PropTypes.shape({
    value: PropTypes.number,
    unit: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  settlementRiskLimit: PropTypes.shape({
    value: PropTypes.number,
    unit: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
}

export default SettlementRiskOverview
