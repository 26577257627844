import {
  DynamicPageHeader,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Icon,
  ObjectStatus,
  TableGrowingMode,
  TableRowType,
} from '@fioneer/ui5-webcomponents-react'
import _ from 'lodash'
import isEmpty from 'lodash.isempty'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { isGatewayTimeoutError, isMissingPermissionError } from 'api/requests'
import BusinessPartnerFilterBar from 'components/domains/business-partners/filterbar/BusinessPartnerFilterBar'
import BusinessPartnerRolesView from 'components/domains/business-partners/roles/BusinessPartnerRolesView'
import Card from 'components/ui/card/Card'
import Entity from 'components/ui/data/Entity'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import Header from 'components/ui/page/Header'
import Page from 'components/ui/page/Page'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import useCtrlOrMetaKeyPressed from 'components/useCtrlOrMetaPressed'
import { useNumberFormatter } from 'hooks/i18n/useI18n'
import { useMatchingBusinessPartners } from 'hooks/services/business-partners/searchBusinessPartners'
import styles from 'routes/business-partners/BusinessPartners.module.css'
import determineValueStateBasedOnStatus from 'routes/business-partners/determineValueState'

const initialSorting = { columnKey: 'score', orderBy: 'desc' }

const BusinessPartners = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const numberFormatter = useNumberFormatter()
  const isCtrlOrMetaPressed = useCtrlOrMetaKeyPressed()

  const [params, updateParams] = useSearchParams()
  const initialQuery = params.get('q') ?? ''
  const initialId = params.get('id') ?? ''
  const initialStreet = params.get('street') ?? ''
  const initialCity = params.get('city') ?? ''
  const initialCountry = useMemo(() => params.get('country')?.split(',') ?? [], [params])
  const initialExcludeInactive = params.get('exclude_inactive') !== 'false'
  const initialRoles = useMemo(() => params.get('roles')?.split(',') ?? [], [params])

  const [filters, setFilters] = useState({
    q: initialQuery,
    id: initialId,
    street: initialStreet,
    city: initialCity,
    country: initialCountry,
    excludeInactive: initialExcludeInactive,
    roles: initialRoles,
    sort: initialSorting,
  })
  const translateSortSettingToSortParameter = (sortSetting) =>
    `${sortSetting?.orderBy === 'asc' ? '+' : '-'}${sortSetting?.columnKey}`
  const {
    isError: isSuggestionsError,
    error,
    isLoading: isSuggestionsLoading,
    data: { businessPartners: suggestions = [], total } = {},
    fetchNextPage,
    hasNextPage,
  } = useMatchingBusinessPartners({
    ...filters,
    sort: translateSortSettingToSortParameter(filters.sort),
  })

  const isError = isSuggestionsError && !isGatewayTimeoutError(error)
  const isAccessDeniedError = isSuggestionsError && isMissingPermissionError(error)

  const renderHeader = () => (
    <Header
      title={t('pages.business-partner-search.title')}
      breadcrumbs={[
        { text: t('navigation.item.title.business-partners'), href: '/business-partners' },
      ]}
    />
  )

  const updateFilters = (filterParams) => {
    setFilters(filterParams)
    const { sort, roles, excludeInactive } = filterParams
    // remove falsy values from filterParams
    updateParams(
      _.pickBy(
        {
          ...filterParams,
          exclude_inactive: '' + excludeInactive,
          roles: roles?.join() ?? '',
          sort: translateSortSettingToSortParameter(sort),
        },
        (x) => !isEmpty(x),
      ),
    )
  }

  const handleFilterbarGo = (newFilters) => {
    const { sort } = filters
    updateFilters({ ...newFilters, sort })
  }

  const customOrderCallback = (columnKey, newOrderBy) => {
    const sort = { columnKey, orderBy: newOrderBy }
    updateFilters({ ...filters, sort })
  }

  const getContent = () => {
    const columnDefinitions = [
      {
        title: t('pages.business-partner-search.table.score.label'),
        columnKey: 'score',
        isVisible: false,
      },
      {
        title: t('pages.business-partner-search.table.toolbar.title', { count: 1 }),
        columnKey: 'name',
      },
      {
        title: t('pages.business-partner-search.table.country.label'),
        columnKey: 'country',
        sortingDisabled: true,
      },
      {
        title: t('pages.business-partner-search.table.postal-code.label'),
        columnKey: 'postalCode',
        alignment: FlexBoxJustifyContent.End,
        sortingDisabled: true,
      },
      {
        title: t('pages.business-partner-search.table.city.label'),
        columnKey: 'city',
        sortingDisabled: true,
      },
      {
        title: t('pages.business-partner-search.table.street.label'),
        columnKey: 'streetName',
        sortingDisabled: true,
      },
      {
        title: t('pages.business-partner-search.table.roles.label'),
        columnKey: 'roles',
        sortingDisabled: true,
      },
      {
        title: t('components.event-information.label.status'),
        columnKey: 'status',
        sortingDisabled: true,
      },
      {
        title: '',
        columnKey: 'arrow',
        isSelectableForHiding: false,
        sortingDisabled: true,
        alignment: FlexBoxAlignItems.End,
      },
    ]

    const tableData = suggestions.map(
      ({
        id,
        name,
        address: { country, postalCode, city, streetName },
        status,
        roles,
        searchScore,
      }) => ({
        rowKey: `business-partner-table-row-${id}`,
        rowProperties: {
          type: TableRowType.Active,
          ['business-partner-id']: id,
        },
        score: {
          cellComponent: <>{numberFormatter(searchScore)}</>,
          value: searchScore,
        },
        name: {
          cellComponent: <Entity name={name} id={id} />,
          value: name,
        },
        country: {
          cellComponent: <>{country?.name}</>,
          value: country?.name,
        },
        postalCode: {
          cellComponent: <>{postalCode}</>,
          value: postalCode,
        },
        city: {
          cellComponent: <>{city}</>,
          value: city,
        },
        streetName: {
          cellComponent: <>{streetName}</>,
          value: streetName,
        },
        roles: {
          cellComponent: (
            <BusinessPartnerRolesView
              roles={
                roles?.map(({ name: roleName, ...role }) => ({
                  ...role,
                  name: t(`components.business-partner.roles.${roleName}`),
                })) ?? []
              }
            />
          ),
        },
        status: {
          cellComponent: (
            <ObjectStatus inverted state={determineValueStateBasedOnStatus(status)}>
              {t(status, { keyPrefix: 'pages.business-partner.general-information.status' })}
            </ObjectStatus>
          ),
          value: status,
        },
        arrow: {
          cellComponent: <Icon name="slim-arrow-right" className={styles.arrow} />,
        },
      }),
    )

    const handleOnRowClick = ({ detail: { row: clickedTableRow } }) => {
      const businessPartnerId = clickedTableRow.getAttribute('business-partner-id')
      if (isCtrlOrMetaPressed) {
        window.open(
          `${window.location.protocol}//${window.location.host}/business-partners/${businessPartnerId}`,
          '_blank',
        )
      } else {
        navigate(businessPartnerId)
      }
    }
    return (
      <>
        <SortedTable
          tableData={tableData}
          columnDefinitions={columnDefinitions}
          toolbarConfig={{
            sorting: {
              columnKey: filters.sort.columnKey,
              isSortingAscending: filters.sort.orderBy === 'asc',
            },
            title: t('pages.business-partner-search.table.toolbar.title', { count: 1 }),
          }}
          customOrderCallback={customOrderCallback}
          // The data already gets sorted by the backend. but since we configure the sorting for the toolbar config
          // to be able to change the sorting parameters, we need to make sure that the table does not sort again itself.
          customOrderFunction={(sortedData) => sortedData}
          paginationConfig={{
            growing: hasNextPage ? TableGrowingMode.Button : undefined,
            growingButtonText: t('components.ui.tables.sorted-tables.growing-button-text'),
            growingButtonSubtext: '[ ' + suggestions.length + ' / ' + total + ' ]',
            totalNumberOfItems: total,
            loadMore: () => fetchNextPage(),
          }}
          noDataText={t('pages.business-partner-search.no-results')}
          additionalTableProperties={{
            onRowClick: handleOnRowClick,
          }}
        />
        {error && isGatewayTimeoutError(error) && (
          <div className={styles.error}>
            <p>{t('pages.business-partner-search.gateway-timeout.title')}</p>
            <p>{t('pages.business-partner-search.gateway-timeout.subtitle')}</p>
          </div>
        )}
      </>
    )
  }

  const renderContent = () => {
    if (isAccessDeniedError) {
      return (
        <CenteredIllustratedMessage
          name="UnableToLoad"
          size="Spot"
          titleText={t('app.permission-error.title')}
          subtitleText={t('app.permission-error.subtitle')}
        />
      )
    }
    return (
      <Card>
        <DynamicPageHeader>
          <BusinessPartnerFilterBar
            initialValues={{
              q: initialQuery,
              id: initialId,
              street: initialStreet,
              city: initialCity,
              country: initialCountry,
              roles: initialRoles,
              excludeInactive: initialExcludeInactive,
            }}
            onGo={handleFilterbarGo}
          />
        </DynamicPageHeader>
        <div className={styles.tableWrapper}>
          <LoadingStateWrapper
            isError={isError}
            isLoading={isSuggestionsLoading}
            renderContent={getContent}
          />
        </div>
      </Card>
    )
  }

  return (
    <Page
      isError={false}
      isLoading={false}
      renderHeaderTitle={renderHeader}
      renderContent={renderContent}
    />
  )
}

BusinessPartners.propTypes = {}

export default BusinessPartners
