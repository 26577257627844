import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import DecisionProcessLabeledLoadingContent from 'components/domains/business-events-and-tasks/decision-process/alc/helper/DecisionProcessLabeledLoadingContent'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import useAuthorityLevelCalculatorAuthorityLevel from 'hooks/services/business-partners/authority-level-calculator/useAuthorityLevelCalculatorAuthorityLevel'

const dash = '-'

const DecisionProcessAlcBorrowerLimit = ({ eventId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.overview.alc-info.dialog',
  })
  const formatMoney = useCustomizableCurrencyFormatter()
  const authorityLevelResponse = useAuthorityLevelCalculatorAuthorityLevel({ eventId })
  const renderBorrowerLimit = useCallback(
    ({ creditAuthorityLimit: { unitMemberLevel } = {} }) =>
      isNil(unitMemberLevel?.amount)
        ? dash
        : formatMoney(unitMemberLevel.amount, unitMemberLevel.currency),
    [formatMoney],
  )
  return (
    <DecisionProcessLabeledLoadingContent
      response={authorityLevelResponse}
      renderCallback={renderBorrowerLimit}
      label={t('fields.borrower-limit')}
    />
  )
}

DecisionProcessAlcBorrowerLimit.propTypes = {
  eventId: PropTypes.string.isRequired,
}

export default DecisionProcessAlcBorrowerLimit
