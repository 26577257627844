import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/fields/CommentField.module.css'

const CommentField = ({ label, text }) => (
  <FlexBox direction={FlexBoxDirection.Column} className={styles.commentItem}>
    <div className={styles.commentItemLabel}>{label}:</div>
    <div>{text}</div>
  </FlexBox>
)

CommentField.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
}

export default CommentField
