/**
 * Get values from one key in list of objects and create a new array for all values.
 * Undefined value is filtered out.
 * Can be used for maximum two level nested object.
 **/

export const getItemsWithKey = (arr, itemKey, itemSubKey) =>
  arr
    .map((obj) => {
      const value = obj?.[itemKey]
      if (value !== undefined) {
        if (typeof value === 'object') {
          return value?.[itemSubKey]
        }
        return value
      }
    })
    .filter(Boolean)
