import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CashFlowScenarioNameTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/CashFlowScenarioNameTile'
import ErrorScreen from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/DealCashFlowErrorScreen'
import { DealCashFlowFinancialRatioCard } from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/DealCashFlowFinancialRatioCard'

const PRESELECTED_KPI_LEFT_CHART = 'DSC_STD'

const CashFlowFinancialRatiosTile = ({ tileId, isPdfView }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.cash-flow',
  })

  const {
    data: {
      periodYearsCalculatedKpis,
      groups,
      dealId,
      tileMetaData,
      cashflowScenarioPeriodNotFound,
    } = {},
    isLoading,
    isError,
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  if (cashflowScenarioPeriodNotFound) {
    return (
      <ErrorScreen title={t('loading.error.title')} description={t('loading.error.description')} />
    )
  }

  return (
    <>
      <CashFlowScenarioNameTile
        metaData={tileMetaData?.metaData}
        dealId={dealId}
        isPdfView={isPdfView}
      />
      <DealCashFlowFinancialRatioCard
        defaultKpiCode={PRESELECTED_KPI_LEFT_CHART}
        calculatedKpis={periodYearsCalculatedKpis}
        groups={groups}
        isCard={false}
        metaData={tileMetaData?.metaData}
        showTitle={false}
        isLoading={isLoading}
        isError={isError}
        isOnDecisionPaper={true}
        isPdfViewOnDecisionPaper={isPdfView}
      />
    </>
  )
}

CashFlowFinancialRatiosTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default CashFlowFinancialRatiosTile
