import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  taskName: '',
  assignee: null,
  dueDate: null,
  isMandatory: false,
  description: '',
}

export const taskCreationSlice = createSlice({
  name: 'task/creation',
  initialState,
  reducers: {
    setTaskName: (state, { payload }) => {
      state.taskName = payload
    },
    setAssignee: (state, { payload }) => {
      state.assignee = payload
    },
    setDueDate: (state, { payload }) => {
      state.dueDate = payload
    },
    setIsMandatory: (state, { payload }) => {
      state.isMandatory = payload
    },
    setDescription: (state, { payload }) => {
      state.description = payload
    },
    resetTaskCreation: () => initialState,
  },
})

export const {
  setTaskName,
  setAssignee,
  setDueDate,
  setIsMandatory,
  setDescription,
  resetTaskCreation,
} = taskCreationSlice.actions

export default taskCreationSlice.reducer
