import { useTranslation } from 'react-i18next'
import i18n from 'i18n/setupI18n'

/**
 *
 * @param {string | Record<string, string>} data
 * @returns
 */
export function useTranslateStaticData(data) {
  const { i18n: i18nFromHook } = useTranslation('translation')
  if (typeof data === 'string') {
    return data
  }
  const resolvedLanguage = i18nFromHook.resolvedLanguage
  if (!resolvedLanguage || !data) {
    return ''
  }
  return data[resolvedLanguage] ?? data[resolvedLanguage.split('-')[0]] ?? ''
}

export function translateStaticData(data) {
  if (typeof data === 'string') {
    return data
  }
  const resolvedLanguage = i18n.resolvedLanguage
  if (!resolvedLanguage || !data) {
    return ''
  }
  return data[resolvedLanguage] ?? data[resolvedLanguage.split('-')[0]] ?? ''
}
