import compact from 'lodash.compact'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { DateTime } from 'luxon'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { assessmentTypes } from 'components/domains/business-partners/tile/assessments/assessmentTypes'
import useMultipleRegulatoryInformation from 'components/domains/business-partners/tile/general-information/regulatoryInformation/useMultipleRegulatoryInformation'
import { ConfigContext } from 'hooks/config/useConfig'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useFinancialIndicatorsHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/financial-indicators/useFinancialIndicatorsHelper'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { DEFAULT_RATING_AGENCY_PRIORITY } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useTenantDetails'
import { useMultipleAssessments } from 'hooks/services/business-partners/assessments/useMultipleAssessments'
import { useMultipleTextualAssessments } from 'hooks/services/business-partners/assessments/useMultipleTextualAssessments'
import {
  useBusinessPartnersByIds,
  useMultipleBusinessPartnersById,
} from 'hooks/services/business-partners/getBusinessPartners'
import { useMultipleFiscalYears } from 'hooks/services/business-partners/kpis/useMultipleFiscalYears'
import useMultipleBusinessPartnerRatings from 'hooks/services/business-partners/ratings/useMultipleBusinessPartnerRatings'
import useMultipleBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useMultipleBusinessPartnerRelationships'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const useAnnualReviewBorrowerOverview = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
  tileCode,
) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.bp-borrower-overview',
  })

  const {
    data: { selectedDeal, dealsData } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })
  const { selectedDealUuid } = selectedDeal ?? {}
  const selectedBusinessPartnerId = dealsData?.find(
    (deal) => deal?.dealUuid === selectedDealUuid,
  )?.bpId

  const {
    ratingAgencyPriority = DEFAULT_RATING_AGENCY_PRIORITY,
    businessPartner: {
      sapRelationshipIds: { jointAccount: jointAccountId, borrower: borrowerRelationshipId } = {},
    } = {},
  } = useContext(ConfigContext)

  const {
    isLoading: isLoadingFinancialIndicatorHelper,
    isError: isErrorFinancialIndicatorHelper,
    getFinancialIndicators,
  } = useFinancialIndicatorsHelper(tileCode)

  const sortExternalRatingsByAgencyPriority = useCallback(
    (a, b) =>
      (ratingAgencyPriority.includes(a.agency.id)
        ? ratingAgencyPriority.indexOf(a.agency.id)
        : ratingAgencyPriority.length) -
      (ratingAgencyPriority.includes(b.agency.id)
        ? ratingAgencyPriority.indexOf(b.agency.id)
        : ratingAgencyPriority.length),
    [ratingAgencyPriority],
  )

  const businessPartnerIds = dealsData?.map((deal) => deal?.bpId) ?? []

  const {
    data: multipleBusinessPartnersData,
    isLoading: isMultipleBusinessPartnersLoading,
    isError: isMultipleBusinessPartnersError,
    error: multipleBusinessPartnersError,
  } = useCombinedQueryResults(
    useMultipleBusinessPartnersById(businessPartnerIds, {
      enabled: businessPartnerIds.length > 0,
    }),
  )

  const {
    data: multipleRegulatoryInformationData,
    isLoading: isMultipleRegulatoryInformationLoading,
    // commented out because for now that endpoint returns a 404
    // even if the request was successful and there simply is no data
    // isError: isMultipleRegulatoryInformationError,
  } = useCombinedQueryResults(
    useMultipleRegulatoryInformation({
      businessPartnerIds: businessPartnerIds,
      options: {
        enabled: businessPartnerIds.length > 0,
      },
    }),
    { forceDataReturn: false },
  )

  const {
    data: multipleAssessmentsData,
    isLoading: isMultipleAssessmentsLoading,
    isError: isMultipleAssessmentsError,
  } = useCombinedQueryResults(
    useMultipleAssessments({
      businessPartnerIds: businessPartnerIds,
      options: {
        enabled: businessPartnerIds.length > 0,
      },
    }),
  )

  const {
    data: multipleBusinessPartnerRatingsData,
    isLoading: isMultipleBusinessPartnerRatingsLoading,
    isError: isMultipleBusinessPartnerRatingsError,
  } = useCombinedQueryResults(
    useMultipleBusinessPartnerRatings(businessPartnerIds, {
      enabled: businessPartnerIds.length > 0,
    }),
  )

  const {
    data: multipleFiscalYearsData,
    isLoading: isMultipleFiscalYearsLoading,
    isError: isMultipleFiscalYearsError,
  } = useCombinedQueryResults(
    useMultipleFiscalYears({
      businessPartnerIds: businessPartnerIds,
      options: {
        enabled: businessPartnerIds.length > 0,
      },
    }),
  )

  const {
    data: multipleTextualAssessmentsData,
    isLoading: isMultipleTextualAssessmentsLoading,
    isError: isMultipleTextualAssessmentsError,
  } = useCombinedQueryResults(
    useMultipleTextualAssessments({
      businessPartnerIds: businessPartnerIds,
      type: assessmentTypes.Description,
      options: {
        enabled: businessPartnerIds.length > 0,
      },
    }),
  )

  const {
    data: multipleBusinessPartnerRelationshipsData,
    isLoading: isMultipleBusinessPartnerRelationshipsLoading,
    isError: isMultipleBusinessPartnerRelationshipsError,
  } = useCombinedQueryResults(useMultipleBusinessPartnerRelationships(businessPartnerIds))

  const multipleBorrowerGroupMemberIds = useMemo(
    () =>
      multipleBusinessPartnerRelationshipsData?.map((borrowerRelationshipData) => {
        if (isNil(borrowerRelationshipData)) {
          return []
        }
        const { unitRelationships } = borrowerRelationshipData
        let borrowerRelationships = unitRelationships.find(({ id }) => id === jointAccountId)
        if (!borrowerRelationships) {
          borrowerRelationships = unitRelationships.find(({ id }) => id === borrowerRelationshipId)
        }
        return borrowerRelationships?.relationships
          ?.find(({ head }) => head.id === businessPartnerId)
          ?.members.map(({ id }) => id)
      }) ?? [],
    [
      borrowerRelationshipId,
      businessPartnerId,
      jointAccountId,
      multipleBusinessPartnerRelationshipsData,
    ],
  )

  const flatMultipleBorrowerGroupMemberIds = compact(
    multipleBorrowerGroupMemberIds?.flatMap((group) => group),
  )

  const {
    data: borrowerGroupMembersData,
    isLoading: isBorrowerGroupMembersLoading,
    isError: isBorrowerGroupMembersError,
    error: borrowerGroupMembersError,
  } = useBusinessPartnersByIds(flatMultipleBorrowerGroupMemberIds)

  const multipleBorrowerGroupMembersData = multipleBorrowerGroupMemberIds.map((group) =>
    group?.map((borrowerId) => borrowerGroupMembersData?.find(({ id }) => id === borrowerId)),
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isAnnualReviewDealsLoading,
      isMultipleBusinessPartnersLoading,
      isMultipleRegulatoryInformationLoading,
      isMultipleAssessmentsLoading,
      isMultipleBusinessPartnerRatingsLoading,
      isMultipleFiscalYearsLoading,
      isMultipleTextualAssessmentsLoading,
      isMultipleBusinessPartnerRelationshipsLoading,
      !isEmpty(flatMultipleBorrowerGroupMemberIds) && isBorrowerGroupMembersLoading,
      isLoadingFinancialIndicatorHelper,
    ],
    errorValues: [
      isAnnualReviewDealsError,
      isMultipleBusinessPartnersError,
      isMultipleAssessmentsError,
      isMultipleBusinessPartnerRatingsError,
      isMultipleFiscalYearsError,
      isMultipleTextualAssessmentsError,
      isMultipleBusinessPartnerRelationshipsError,
      isBorrowerGroupMembersError,
    ],
    errorDetails: [
      annualReviewDealsError,
      multipleBusinessPartnersError,
      borrowerGroupMembersError,
    ],
    tileId,
  })

  const multipleFiscalData = useMemo(
    () =>
      multipleFiscalYearsData?.map((fiscalYearsData) => {
        if (isNil(fiscalYearsData?.fiscal_years)) {
          return undefined
        }
        const fiscalYears = fiscalYearsData.fiscal_years
        let latestYear = 0
        for (const yearString in fiscalYears) {
          const year = +yearString
          if (year > latestYear) {
            latestYear = year
          }
        }
        const fiscalData = fiscalYears[String(latestYear)]
        return { ...fiscalData, financialIndicators: getFinancialIndicators(fiscalData) }
      }),
    [getFinancialIndicators, multipleFiscalYearsData],
  )

  const multipleExternalRating = useMemo(
    () =>
      multipleBusinessPartnerRatingsData?.map((ratingsData) => {
        const today = DateTime.now()
        return (ratingsData?.external ?? [])
          .filter((rating) => {
            const validFromDate = DateTime.fromISO(rating.validFrom)
            const validToDate = DateTime.fromISO(rating.validTo)
            return validFromDate <= today && validToDate >= today
          })
          .sort(sortExternalRatingsByAgencyPriority)?.[0]
      }),
    [multipleBusinessPartnerRatingsData, sortExternalRatingsByAgencyPriority],
  )

  const multipleInternalRating = useMemo(
    () =>
      multipleBusinessPartnerRatingsData?.map((ratingsData) =>
        (ratingsData?.internal ?? []).find((rating) => rating.isActive),
      ),
    [multipleBusinessPartnerRatingsData],
  )

  const multipleAppreciation = useMemo(
    () =>
      multipleTextualAssessmentsData?.map((textualAssessmentData) => {
        if (isEmpty(textualAssessmentData?.availableVersions)) {
          return undefined
        }
        const { availableVersions } = textualAssessmentData
        // Sort descending by version number to return the content with the highest version
        return [...availableVersions].sort(
          ({ version: firstVersion }, { version: secondVersion }) => secondVersion - firstVersion,
        )[0]?.content
      }),
    [multipleTextualAssessmentsData],
  )

  return useMemo(() => {
    if (isErrorFinancialIndicatorHelper) {
      return {
        isLoading: isSomeValueLoading,
        isError: true,
        error: {
          isTileConfigError: isErrorFinancialIndicatorHelper,
          customErrorMessage: t('error.config'),
        },
      }
    }
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        multipleBusinessPartnersData,
        multipleBorrowerGroupMembersData,
        multipleRegulatoryInformationData,
        multipleAssessmentsData,
        multipleInternalRating,
        multipleExternalRating,
        multipleFiscalData,
        multipleAppreciation,
        sourceRender: {
          businessPartnerId: selectedBusinessPartnerId,
        },
      },
    }
  }, [
    dealsData,
    error,
    isSomeValueError,
    isSomeValueLoading,
    isErrorFinancialIndicatorHelper,
    multipleAppreciation,
    multipleAssessmentsData,
    multipleBorrowerGroupMembersData,
    multipleBusinessPartnersData,
    multipleExternalRating,
    multipleFiscalData,
    multipleInternalRating,
    multipleRegulatoryInformationData,
    selectedBusinessPartnerId,
    t,
  ])
}

export default useAnnualReviewBorrowerOverview
