import { useRequests } from 'hooks/services/baseService'
import { useCamelizedResponse, useCombinedQueryResults } from 'hooks/services/queryHelper'

const useCovenantsLimitsByIds = (covenantUuids = []) =>
  useCamelizedResponse(
    useCombinedQueryResults(
      useRequests({
        requests: covenantUuids.map((covenantUuid) => ({
          path: `/covenants/${covenantUuid}/limits`,
          keys: ['covenants', covenantUuid, 'limits'],
        })),
        translated: true,
        options: { enabled: covenantUuids.length > 0 },
      }),
    ),
  )

export default useCovenantsLimitsByIds
