import { ValueState } from '@fioneer/ui5-webcomponents-react'
import StatusCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/StatusCell'
import {
  ACTIVE,
  APPROVED,
  OBSOLETE,
  OUTDATED,
  PROPOSED,
  NON_PERFORMING,
  NO_FORBEARANCE,
  PERFORMING,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/borrower/constants'

const RISK_MONITORING_STATUSES = {
  RESOLVED: 'Resolved',
  RESTRUCTURING: 'Restructuring/Workout',
  IMPAIRMENT: 'impairment',
  FACILITY_IN_FOCUS: 'Facility in Focus',
  WATCHLIST: 'Watchlist',
  NO_RISK_MONITORING: 'no-risk-monitoring',
}

const BUSINESS_PARTNER_STATUSES = {
  PROVISIONAL: 'provisional',
  ARCHIVED: 'archived',
  INACTIVE: 'inactive',
}

const GREEN_SCORE_STATUSES = {
  GREEN: 'Green',
  YELLOW: 'Yellow',
  RED: 'Red',
}

// NOTE: this whole shenanigans is a workaround for the fact that some statuses are coming
// from the backend translated and some are not, so we need to handle both cases as this
// fn is handling the rendering of Business Partner, Forbearance and Risk Monitoring statuses
export const mapStatuses = (renderValue, t) => {
  if (!renderValue) {
    return null
  }

  let statusValue = renderValue
  let statusState = ValueState.Information

  switch (renderValue.toLowerCase()) {
    case ACTIVE.toLowerCase():
    case RISK_MONITORING_STATUSES.RESOLVED.toLowerCase():
    case NO_FORBEARANCE.toLowerCase():
    case RISK_MONITORING_STATUSES.NO_RISK_MONITORING:
      statusState = ValueState.Success
      statusValue = t(`status.${renderValue.toLowerCase().replace(/\s/g, '')}`)
      break

    case PERFORMING.toLowerCase():
    case BUSINESS_PARTNER_STATUSES.PROVISIONAL:
    case RISK_MONITORING_STATUSES.RESTRUCTURING.toLowerCase():
      statusState = ValueState.Warning
      statusValue = t(`status.${renderValue.toLowerCase().replace(/\s/g, '')}`)
      break

    case NON_PERFORMING.toLowerCase():
    case BUSINESS_PARTNER_STATUSES.ARCHIVED:
    case RISK_MONITORING_STATUSES.IMPAIRMENT.toLowerCase():
      statusState = ValueState.Error
      statusValue = t(`status.${renderValue.toLowerCase().replace(/\s/g, '')}`)
      break

    case OUTDATED.toLowerCase():
    case OBSOLETE.toLowerCase():
    case BUSINESS_PARTNER_STATUSES.INACTIVE:
    case RISK_MONITORING_STATUSES.FACILITY_IN_FOCUS.toLowerCase():
      statusState = ValueState.None
      statusValue = t(`status.${renderValue.toLowerCase().replace(/\s/g, '')}`)
      break

    case PROPOSED.toLowerCase():
    case APPROVED.toLowerCase():
    case RISK_MONITORING_STATUSES.WATCHLIST.toLowerCase():
      statusState = ValueState.Information
      statusValue = t(`status.${renderValue.toLowerCase().replace(/\s/g, '')}`)
      break
  }

  switch (renderValue) {
    case GREEN_SCORE_STATUSES.GREEN:
      statusState = ValueState.Success
      break
    case GREEN_SCORE_STATUSES.YELLOW:
      statusState = ValueState.Warning
      break
    case GREEN_SCORE_STATUSES.RED:
      statusState = ValueState.Error
      break
  }

  return <StatusCell statusState={statusState} statusValue={statusValue} />
}
