import {
  MultiComboBox,
  MultiComboBoxGroupItem,
  MultiComboBoxItem,
} from '@fioneer/ui5-webcomponents-react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'
import useBusinessPartnerRoles from 'hooks/services/business-partners/config/useBusinessPartnerRoles'

const TenantFilterRolesComboBox = React.forwardRef(
  ({ selectedRoles, handleMultiComboboxChange }, _ref) => {
    const { t } = useTranslation()
    const { data: { categories = [] } = {} } = useBusinessPartnerRoles()

    const handleOnChangeFilterRoles = (event) => {
      handleMultiComboboxChange(event, 'roles')
    }

    return (
      <MultiComboBox
        id="tenant-filter-roles-input"
        ref={_ref}
        filter={filterTypes.CONTAINS}
        onSelectionChange={handleOnChangeFilterRoles}
        onFocus={(event) => event.stopPropagation()}
      >
        {categories
          .filter((category) => !isEmpty(category.roles))
          .map(({ category, roles: technicalRoles }) => (
            <React.Fragment key={category}>
              <MultiComboBoxGroupItem
                text={t('components.business-partner.roles.' + category)}
                data-value={
                  // HINT: workaround: when typing in the MultiComboBox,
                  //       and there's a group and an item with the same name,
                  //       it chooses the first element with this name (most often the group)
                  //       This fixes it by giving the group the data-value attribute as well
                  //       if an item has the same name
                  //       The bug is tracked in https://github.com/SAP/ui5-webcomponents/issues/6222
                  Object.entries(technicalRoles).find(([_key, value]) => value === category)?.[0]
                }
              />
              {Object.values(technicalRoles).map(({ id, name }) => (
                <MultiComboBoxItem
                  key={id}
                  text={name}
                  data-value={id}
                  selected={selectedRoles?.includes(id)}
                />
              ))}
            </React.Fragment>
          ))}
      </MultiComboBox>
    )
  },
)

TenantFilterRolesComboBox.displayName = 'TenantFilterRolesComboBox'

TenantFilterRolesComboBox.propTypes = {
  selectedRoles: PropTypes.arrayOf(PropTypes.string.isRequired),
  handleMultiComboboxChange: PropTypes.func.isRequired,
}

export default TenantFilterRolesComboBox
