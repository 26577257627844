import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useEditDecisionPaper = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(
    ({ eventId, synchronizationStatus, tileData }) =>
      patch({
        path: `/events/${eventId}/decision-paper/versions/current`,
        body: {
          synchronization_status: synchronizationStatus,
          tile_data: tileData,
        },
      }),
    mutationOptions,
  )
}

export default useEditDecisionPaper
