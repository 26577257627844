import { Button, Menu, MenuItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { eventStatus } from 'api/events/status'
import { noSelectionValueCode } from 'components/domains/business-events-and-tasks/decision-paper/DecisionPaperTemplateDropdown'
import styles from 'components/domains/deals/header-actions/working-version-event-create/CreateWorkingVersionDealEventDialogSecondStage.module.css'
import WorkingVersionDealEventSecondStageDialogBody from 'components/domains/deals/header-actions/working-version-event-create/WorkingVersionDealEventSecondStageDialogBody'
import LoadingButton from 'components/ui/button/LoadingButton'
import Dialog from 'components/ui/dialog/Dialog'

const CreateWorkingVersionDealEventDialogSecondStage = ({
  isOpen,
  setIsOpen,
  deal,
  event,
  handleCreate,
  isPublishing,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dialog.event-creation.deal-working-version',
  })
  const { t: tNoPrefix } = useTranslation()
  const [displayTable, setDisplayTable] = useState(false)
  const [eventIdToImportDataFrom, setEventIdToImportDataFrom] = useState(undefined)
  const [decisionPaperTemplateCode, setDecisionPaperTemplateCode] = useState(null)
  const [customizedDecisionPaperTemplateStructure, setCustomizedDecisionPaperTemplateStructure] =
    useState(undefined)
  const [isButtonMenuOpen, setIsButtonMenuOpen] = useState(false)

  const handleTemplateChange = useCallback(
    ({ templateCode, customizedTemplateStructure, hasChangesToBaseTemplate }) => {
      setDisplayTable(false)
      if (!templateCode || templateCode === noSelectionValueCode) {
        setEventIdToImportDataFrom(undefined)
        setDecisionPaperTemplateCode(null)
        return
      }
      setDecisionPaperTemplateCode(templateCode)
      setCustomizedDecisionPaperTemplateStructure(
        hasChangesToBaseTemplate ? customizedTemplateStructure : null,
      )
      setEventIdToImportDataFrom(undefined)
    },
    [],
  )

  const onRadioChange = useCallback(
    (eventId) => () => {
      setEventIdToImportDataFrom(eventId)
    },
    [],
  )

  const handleSwitchClicked = useCallback(() => {
    setDisplayTable(!displayTable)
    setEventIdToImportDataFrom(undefined)
  }, [setDisplayTable, displayTable])

  const onClickMenuItem = ({ detail: { item: pressedMenuItem } }) => {
    setIsButtonMenuOpen(false)
    const pressedIdentifier = pressedMenuItem.getAttribute('data-button-id')
    handleCreate({
      eventStatus: pressedIdentifier,
      eventIdToImportDataFrom,
      customizedDecisionPaperTemplateStructure,
      selectedTemplate: decisionPaperTemplateCode,
    })
  }

  return (
    <Dialog
      open={isOpen}
      headerText={t('title')}
      onAfterClose={() => setIsOpen(false)}
      secondaryButton={<Button disabled={true}>{t('button.review')}</Button>}
      primaryButton={
        <>
          <LoadingButton
            id={'create-wv-event-btn'}
            onClick={() => setIsButtonMenuOpen(!isButtonMenuOpen)}
            icon="slim-arrow-down"
            disabled={!decisionPaperTemplateCode}
            iconEnd
            isLoading={isPublishing}
            renderContent={() => tNoPrefix('buttons.create')}
          />
          <Menu
            opener={'create-wv-event-btn'}
            open={isButtonMenuOpen}
            onAfterClose={(e) => {
              // HINT: Propagation needs to be prevented, otherwise onAfterClose of the Dialog is triggered as well
              e.stopPropagation()
              setIsButtonMenuOpen(false)
            }}
            onItemClick={onClickMenuItem}
          >
            <MenuItem
              text={tNoPrefix('buttons.save-as-draft')}
              data-button-id={eventStatus.drafted}
            />
            <MenuItem text={tNoPrefix('buttons.publish')} data-button-id={eventStatus.ongoing} />
          </Menu>
        </>
      }
    >
      <div {...(isPublishing && { inert: '' })} className={isPublishing ? styles.disabled : ''}>
        <WorkingVersionDealEventSecondStageDialogBody
          deal={deal}
          event={event}
          handleTemplateChange={handleTemplateChange}
          handleSwitchClicked={handleSwitchClicked}
          decisionPaperTemplateCode={decisionPaperTemplateCode}
          displayTable={displayTable}
          onImportEventChange={onRadioChange}
          eventIdToImportDataFrom={eventIdToImportDataFrom}
          setEventIdToImportDataFrom={setEventIdToImportDataFrom}
        />
      </div>
    </Dialog>
  )
}

CreateWorkingVersionDealEventDialogSecondStage.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPublishing: PropTypes.bool,
  setIsOpen: PropTypes.func.isRequired,
  deal: PropTypes.shape({
    displayId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  event: PropTypes.shape({
    entityType: PropTypes.string.isRequired,
    entityId: PropTypes.string.isRequired,
    eventType: PropTypes.string.isRequired,
  }),
  handleCreate: PropTypes.func.isRequired,
}

export default CreateWorkingVersionDealEventDialogSecondStage
