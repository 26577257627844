import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

/**
 * @typedef RatingDelete
 * @property {string} methodId
 * @property {string} validTo
 */

/**
 * @param {object} params
 * @param {number} params.businessPartnerId
 * @param {function|undefined} params.onSuccess
 * @param {function|undefined} params.onError
 */
const useDeleteBusinessPartnerRating = ({ businessPartnerId, onError, onSuccess }) => {
  const { delete: deleteCall } = useAccessTokenRequest()
  const queryClient = useQueryClient()

  return useMutation(
    /** @param {RatingDelete} param */
    ({ methodId, validTo }) =>
      deleteCall({
        path: `/businesspartners/${businessPartnerId}/ratings/${methodId}/${validTo}`,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['businesspartners', businessPartnerId, 'ratings'])
        onSuccess?.()
      },
      onError,
    },
  )
}

export default useDeleteBusinessPartnerRating
