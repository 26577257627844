import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useEditCondition = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(async ({ conditionId, conditionType, condition }) => {
    const { data } = await patch({
      path: `/conditions/${conditionType}/${conditionId}`,
      body: snakecaseKeys(condition),
    })
    return camelize(data)
  }, mutationOptions)
}

export default useEditCondition
