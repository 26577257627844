import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateNewMinorVersion = (mutationOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(async ({ eventId }) => {
    const { data } = await post({
      path: `/events/${eventId}/decision-paper/versions`,
      body: snakecaseKeys({
        isMinorVersion: true,
      }),
    })
    return data
  }, mutationOptions)
}

export default useCreateNewMinorVersion
