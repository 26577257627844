const sortUnitDealsData = (data) =>
  data.toSorted((a, b) => {
    if (a?.kpiDeal?.isBPDeal && !b?.kpiDeal?.isBPDeal) {
      return -1
    }
    if (!a?.kpiDeal?.isBPDeal && b?.kpiDeal?.isBPDeal) {
      return 1
    }
    if (a?.kpiDeal?.businessPartnerId < b?.kpiDeal?.businessPartnerId) {
      return -1
    }
    if (a?.kpiDeal?.businessPartnerId > b?.kpiDeal?.businessPartnerId) {
      return 1
    }
    if (a?.kpiDeal?.dealName < b?.kpiDeal?.dealName) {
      return -1
    }
    if (a?.kpiDeal?.dealName > b?.kpiDeal?.dealName) {
      return 1
    }
    return 0
  })

export default sortUnitDealsData
