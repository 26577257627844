import { useQueries } from '@tanstack/react-query'
import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { useMemo } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { GetMultiPropertyRentRollKPIsRequestBody } from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpis.schema'
import { useArrayMemo } from 'hooks/services/queryHelper'

const useCurrentMultiPropertyKpisList = (requests, options = {}) => {
  const { post } = useAccessTokenRequest()

  const getValueOrUndefinedIfNotSet = (attribute) => (attribute ? attribute : undefined)

  const queries = requests.map((request) => {
    const requestOptions = {
      property_uuids: request.propertyUuids,
      currency: getValueOrUndefinedIfNotSet(request.currency),
      area_unit_of_measurement: getValueOrUndefinedIfNotSet(request.areaUnitOfMeasurement),
      key_dates: request.keyDates ?? null,
      with_tenant_granularity: getValueOrUndefinedIfNotSet(request.withTenantGranularity),
      with_city_granularity: getValueOrUndefinedIfNotSet(request.withCityGranularity),
      with_property_type_granularity: getValueOrUndefinedIfNotSet(
        request.withPropertyTypeGranularity,
      ),
      with_country_granularity: getValueOrUndefinedIfNotSet(request.withCountryGranularity),
      with_segment_usage_type_granularity: getValueOrUndefinedIfNotSet(
        request.withSegmentUsageTypeGranularity,
      ),
    }
    return {
      queryKey: ['multi-property-kpis', request.propertyUuids, request.currency, request.keyDates],
      queryFn: async () => {
        const parsedRequestOptions = GetMultiPropertyRentRollKPIsRequestBody.parse(requestOptions)

        return post({
          path: '/properties/kpis/multi-property',
          body: parsedRequestOptions,
        })
      },
      retry: 0,
      ...options,
      enabled: !isNil(options.enabled)
        ? options.enabled && !isEmpty(request.propertyUuids)
        : !isEmpty(request.propertyUuids),
    }
  })
  const responses = useQueries({ queries })
  const isAnyRequestLoading = useMemo(
    () =>
      responses.some(
        ({ isLoading: isPropertyLoading }, index) => isPropertyLoading && queries?.[index]?.enabled,
      ),
    [queries, responses],
  )
  const isAnyRequestError = responses.some(({ isError: isPropertyError }) => isPropertyError)
  const kpisData = useArrayMemo(
    responses.map(({ data, isLoading, isError, isFetching }) => ({
      ...camelize(data),
      isLoading,
      isError,
      isFetching,
    })),
  )

  return useMemo(
    () => ({ data: kpisData, isLoading: isAnyRequestLoading, isError: isAnyRequestError }),
    [isAnyRequestError, isAnyRequestLoading, kpisData],
  )
}

export default useCurrentMultiPropertyKpisList
