import { DynamicPageHeader } from '@fioneer/ui5-webcomponents-react'
import { compact } from 'lodash'
import { useContext, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import DealChangeOverviewFilterBar from 'components/domains/deals/change-overview/DealChangeOverviewFilterBar'
import styles from 'components/domains/deals/change-overview/DealChangeOverviewSearch.module.css'
import DealChangeOverviewTable from 'components/domains/deals/change-overview/DealChangeOverviewTable'
import Card from 'components/ui/card/Card'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useMultipleStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useMultipleStaffMemberByObjectIdOrEmail'
import useDealChangeOverviewTableColumnDefinitions from 'hooks/services/deals/change-overview/useDealChangeOverviewTableColumns'
import useChangeLogs, {
  PAGINATION_LIMIT,
} from 'hooks/services/traceability-of-changes/useChangeLogs'
import { useInfiniteLoading } from 'hooks/services/useInfiniteLoading'
import { DealContext } from 'routes/deals/DealContext'

const getOrderSymbol = (orderBy) => (orderBy === 'DESC' ? '-' : '+')
const getSortString = (sortBy, orderBy) =>
  sortBy && orderBy ? `${getOrderSymbol(orderBy)}${sortBy}` : ''
const getFieldFromSortString = (sortString) => sortString.slice(1)
const getDirectionFromSortString = (sortString) => (sortString.slice(0, 1) === '-' ? 'DESC' : 'ASC')

const DealChangeOverviewSearch = () => {
  const {
    deal: { dealUuid: dealUuid },
  } = useContext(DealContext)

  const initialSorting = useMemo(
    () => ({
      sortBy: 'triggered_at',
      orderBy: 'DESC',
    }),
    [],
  )

  const [query] = useSearchParams({
    sort: getSortString(initialSorting.sortBy, initialSorting.orderBy),
  })
  const sortString = query.get('sort')
  const entityId = query.get('entity_id')
  const entityType = query.get('entity_type')
  const pageId = query.get('page_id')
  const cardId = query.get('card_id')
  const actionType = query.get('action_type')

  const sort = {
    field: getFieldFromSortString(sortString),
    direction: getDirectionFromSortString(sortString),
  }

  const {
    isLoading: isLoadingChangeLogs,
    isError: isErrorChangeLogs,
    data: changeLogsData,
    loadMore: loadMoreChangeLogs,
  } = useInfiniteLoading({
    requestHook: useChangeLogs,
    requestHookArgs: {
      dealUuid: dealUuid,
      filter: {
        entityId,
        entityType,
        pageId,
        cardId,
        actionType,
      },
      sort,
    },
    dataListElement: 'changeLogs',
    paginationDelta: PAGINATION_LIMIT,
    useCache: false,
  })

  const changeLogs = useMemo(() => changeLogsData?.changeLogs ?? [], [changeLogsData])

  const objectIdOrEmailAddressList = useMemo(
    () => compact(changeLogs?.map((changeLog) => changeLog?.triggeredBy)),
    [changeLogs],
  )

  const shouldLoadStaffMembers = useMemo(
    () =>
      changeLogs?.length > 0 &&
      objectIdOrEmailAddressList?.length > 0 &&
      !isLoadingChangeLogs &&
      !isErrorChangeLogs,
    [
      changeLogs?.length,
      objectIdOrEmailAddressList?.length,
      isErrorChangeLogs,
      isLoadingChangeLogs,
    ],
  )

  const { isSomeLoading: isLoadingStaffMember, data: staffMember } =
    useMultipleStaffMemberByObjectIdOrEmail(
      {
        objectIdOrEmailAddressList,
      },
      {
        enabled: shouldLoadStaffMembers,
      },
    )

  const isLoading = isLoadingChangeLogs || (isLoadingStaffMember && shouldLoadStaffMembers)

  const changeLogsPagination = changeLogsData?.pagination ?? {
    offset: 0,
    limit: 0,
    total: 0,
  }

  const [tableColumns, setTableColumns] = useDealChangeOverviewTableColumnDefinitions()

  return (
    <>
      <Card>
        <DynamicPageHeader className={styles['dynamic-page-header']}>
          <DealChangeOverviewFilterBar dealUuid={dealUuid} />
        </DynamicPageHeader>
        <div className={styles['table-wrapper']}>
          <LoadingStateWrapper
            isLoading={isLoading}
            isError={isErrorChangeLogs}
            renderContent={() => (
              <DealChangeOverviewTable
                changeLogs={changeLogs}
                staffMember={staffMember}
                tableColumns={tableColumns}
                setTableColumns={setTableColumns}
                pagination={changeLogsPagination}
                onLoadMore={loadMoreChangeLogs}
              />
            )}
          />
        </div>
      </Card>
    </>
  )
}

export default DealChangeOverviewSearch
