import { TILE_VERSION as tileVersion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import {
  getCurrentValueForTimeSeries as getCurrentValueForTimeSeriesV1,
  getLastUltimoForTimeSeries as getLastUltimoForTimeSeriesV1,
  KpiThresholdTypes as KpiThresholdTypesV1,
  getTimeSeriesForKpi as getTimeSeriesForKpiV1,
  getKpiForestFromKpiList as getKpiForestFromKpiListV1,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/kpis/KpiUtils'
import {
  getCurrentValueForTimeSeries as getCurrentValueForTimeSeriesV3,
  getLastUltimoForTimeSeries as getLastUltimoForTimeSeriesV3,
  KpiThresholdTypes as KpiThresholdTypesV3,
  getTimeSeriesForKpi as getTimeSeriesForKpiV3,
  getKpiForestFromKpiList as getKpiForestFromKpiListV3,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/kpis/KpiUtilsV3'

const useVersionedKpiUtils = (version) => {
  switch (version) {
    case tileVersion.V1:
    case tileVersion.V2:
      return {
        getCurrentValueForTimeSeries: getCurrentValueForTimeSeriesV1,
        getLastUltimoForTimeSeries: getLastUltimoForTimeSeriesV1,
        KpiThresholdTypes: KpiThresholdTypesV1,
        getTimeSeriesForKpi: getTimeSeriesForKpiV1,
        getKpiForestFromKpiList: getKpiForestFromKpiListV1,
      }
    default:
      return {
        getCurrentValueForTimeSeries: getCurrentValueForTimeSeriesV3,
        getLastUltimoForTimeSeries: getLastUltimoForTimeSeriesV3,
        KpiThresholdTypes: KpiThresholdTypesV3,
        getTimeSeriesForKpi: getTimeSeriesForKpiV3,
        getKpiForestFromKpiList: getKpiForestFromKpiListV3,
      }
  }
}

export default useVersionedKpiUtils
