import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionsMassEditOperations } from 'api/conditions/conditions'
import MassEditOperationSelect from 'components/domains/conditions/dialogs/mass-editing/MassEditOperationSelect'
import ReferencesSelectionComponent from 'components/domains/conditions/reference-selection/ReferencesSelectionComponent'

const ConditionsMassEditReferencesSelect = ({ onChange, disabled, allReferencesEntityType }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.dialogs.mass-edit',
  })

  const initialReferences = allReferencesEntityType
    ? { entityIds: [], entityType: allReferencesEntityType }
    : undefined

  const [showInputField, setShowInputField] = useState(false)
  const [selectedReferences, setSelectedReferences] = useState(initialReferences)

  const handleSelectedOperationChanged = useCallback(
    (selection) => {
      switch (selection) {
        case conditionsMassEditOperations.keep:
          setShowInputField(false)
          onChange({ operation: conditionsMassEditOperations.keep })
          return
        case conditionsMassEditOperations.clear:
          setShowInputField(false)
          onChange({ operation: conditionsMassEditOperations.clear })
          return
        case conditionsMassEditOperations.replace:
          setShowInputField(true)
          onChange({ operation: conditionsMassEditOperations.replace, value: null })
      }
    },
    [onChange],
  )

  const OnReferenceSelectionChanged = useCallback(
    (newReferences) => {
      if (!newReferences) {
        setSelectedReferences(null)
        onChange({
          operation: conditionsMassEditOperations.clear,
        })
        return
      }
      setSelectedReferences(newReferences)
      onChange({
        operation: conditionsMassEditOperations.replace,
        value: newReferences,
      })
    },
    [setSelectedReferences, onChange],
  )

  const referencesInputField = useMemo(
    () => (
      <ReferencesSelectionComponent
        references={selectedReferences}
        onReferencesChanged={OnReferenceSelectionChanged}
        disableInput={disabled}
      />
    ),
    [OnReferenceSelectionChanged, disabled, selectedReferences],
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label>{t('labels.references')}</Label>
      <MassEditOperationSelect
        idPrefix="conditions-references"
        selectableOperations={[
          conditionsMassEditOperations.keep,
          conditionsMassEditOperations.replace,
          conditionsMassEditOperations.clear,
        ]}
        onChange={handleSelectedOperationChanged}
        disabled={disabled}
      />
      {showInputField && referencesInputField}
    </FlexBox>
  )
}

ConditionsMassEditReferencesSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  allReferencesEntityType: PropTypes.string,
}

export default ConditionsMassEditReferencesSelect
