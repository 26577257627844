import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useInternalConditionsUuids = (
  { internalConditionUuids = [], entityId, entityType },
  options,
) => {
  const { post } = useAccessTokenRequest()
  const queryFn = useCallback(async () => {
    const { data } = await post({
      path: '/conditions/internal/uuids',
      body: {
        internal_condition_ids: internalConditionUuids,
      },
    })
    return data
  }, [internalConditionUuids, post])
  return useCamelizedResponse(
    useQuery({
      queryKey: [
        'conditions',
        'internal',
        'uuids',
        entityType,
        entityId,
        'uuids',
        ...internalConditionUuids,
      ],
      queryFn,
      retry: false,
      ...options,
    }),
  )
}

export default useInternalConditionsUuids
