import isEmpty from 'lodash.isempty'

const valuationValueAmountChanged = (state, { payload: { valueAmount: newValueAmount } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }
  state.editedRow.currentValues.valueAmount.value = newValueAmount
  state.editedRow.touchedFields.valueAmount = true

  if (newValueAmount) {
    delete state.editedRow.errorFields.valueAmount
  } else {
    state.editedRow.errorFields.valueAmount = true
  }

  if (newValueAmount === state.editedRow.initialValues.valueAmount.value) {
    delete state.editedRow.changedFields.valueAmount
    return
  }

  state.editedRow.changedFields.valueAmount = true
}

export default valuationValueAmountChanged
