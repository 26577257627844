import { Input, InputType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  useFormattedNumberParser,
  useLanguage,
  useSeparators,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'
import { getNewCursorPosition } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/input/getNewCursorPosition'
import { isInputAllowed } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/input/isInputAllowed'
import { replaceShortHands } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/input/replaceShortHands'

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isFocused: PropTypes.bool,
  maximumFractionDigits: PropTypes.number,
  onKeyDown: PropTypes.func,
  onInput: PropTypes.func,
}

const noOp = () => {}

/**
 * NumberInput is a wrapper around the Input component that live formats the input value as a number.
 * It also replaces shorthand for thousand, million and billion with their respective values.
 * @param {PropTypes.InferProps<typeof propTypes>} props
 * @param {React.Ref<HTMLInputElement>} ref
 * @returns {React.ReactElement}
 * @displayName NumberInput
 */
const NumberInput = forwardRef(
  ({ value, isFocused, onKeyDown = noOp, onInput = noOp, ...props }, ref) => {
    const parseNumber = useFormattedNumberParser()

    const separators = useSeparators()
    const language = useLanguage()

    const parsedValue = useMemo(() => parseNumber(value), [value, parseNumber])

    const formatterOptions = useMemo(
      () => (isFocused ? {} : { maximumFractionDigits: 20 }),
      [isFocused],
    )

    const formatter = useMemo(
      () => Intl.NumberFormat(language, formatterOptions),
      [language, formatterOptions],
    )

    const [formattedValue, setFormattedValue] = useState(formatter.format(parseFloat(parsedValue)))

    useEffect(() => {
      setFormattedValue(value === '' ? '' : formatter.format(parseFloat(parsedValue)))
    }, [value, parsedValue, language, formatter])

    // use the ref passed from the parent component, or create a new one
    // ref is required to be able to set the cursor position after the input value has been updated
    const innerRef = useRef(null)
    const inputRef = ref || innerRef

    const handleInputChange = useCallback(
      (event) => {
        const cursorPosition = event?.target?.nativeInput?.selectionStart

        const inputValue = event.target.value

        // if the input value is empty, set the formatted value to empty and call the onInput function passed from the parent component
        if (inputValue === '') {
          setFormattedValue('')
          onInput?.(event)
          return
        }

        if (inputValue === separators?.decimalSeparator) {
          setFormattedValue(`0${separators?.decimalSeparator}`)
          onInput?.(event)
          return
        }

        // if the input value is a minus sign, set the formatted value to a minus sign and call the onInput function passed from the parent component
        if (inputValue === '-') {
          setFormattedValue('-')
          onInput?.(event)
          return
        }

        const inputValueWithEvaluatedShorthands = replaceShortHands(inputValue, separators)

        const inputValueWithoutThousandSeparators = inputValueWithEvaluatedShorthands.replace(
          new RegExp(`\\${separators?.thousandsSeparator}`, 'g'),
          '',
        )

        const newFormattedValue = formatter.format(
          parseFloat(parseNumber(inputValueWithoutThousandSeparators)),
        )

        setFormattedValue(newFormattedValue)

        // call the onInput function passed from the parent component
        const newEvent = {
          ...event,
          target: {
            ...event.target,
            value: parseFloat(inputValueWithoutThousandSeparators).toString(),
          },
        }
        onInput?.(newEvent)

        // Set the cursor position to the new position
        // Note: This function and related operations depend on timing-sensitive behavior,
        // potentially leading to unexpected results or race conditions. Please keep that in mind
        // when using this component.
        // In manuel testing, the cursor position is always set correctly, but it's possible that
        // in some edge cases, the cursor position is not set correctly.
        requestAnimationFrame(() => {
          const newCursorPosition = getNewCursorPosition(
            cursorPosition,
            inputValue,
            inputValueWithEvaluatedShorthands,
            newFormattedValue,
            separators?.thousandsSeparator,
          )

          inputRef.current.nativeInput.setSelectionRange(newCursorPosition, newCursorPosition)
        })
      },
      [separators, formatter, parseNumber, onInput, inputRef],
    )

    const handleOnKeyDown = useCallback(
      (event) => {
        if (!isInputAllowed(event, separators)) {
          event.preventDefault()
        }
        onKeyDown?.(event)
      },
      [onKeyDown, separators],
    )

    const inputValue = useMemo(() => {
      if (isFocused !== undefined) {
        return isFocused ? formattedValue : value
      }
      if (formattedValue === '' || isNaN(parseNumber(formattedValue))) {
        return ''
      }

      if (formattedValue === `0${separators?.decimalSeparator}`) return formattedValue

      return formatter.format(parseFloat(parseNumber(formattedValue)))
    }, [isFocused, formattedValue, parseNumber, separators?.decimalSeparator, formatter, value])

    return (
      <Input
        value={inputValue}
        type={InputType.Text}
        ref={inputRef}
        onInput={handleInputChange}
        onKeyDown={handleOnKeyDown}
        {...props}
      />
    )
  },
)

NumberInput.displayName = 'NumberInput'

export default NumberInput

NumberInput.propTypes = propTypes
