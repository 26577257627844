import { BusyIndicatorSize } from '@fioneer/ui5-webcomponents-react'
import uniq from 'lodash.uniq'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ReferenceEntitiesView, { entitiesQuantity } from 'components/ui/data/ReferenceEntitiesView'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useEventsByIdList from 'hooks/services/business-events-and-tasks/events/useEventsByIdList'
import paths from 'routes/paths'

const EventsReferenceEntitiesView = ({ eventIds = [] }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.change-overview.adjustment-history',
  })

  const {
    data: resolvedEvents,
    isLoading: isLoadingEvents,
    isError: isErrorEvents,
  } = useEventsByIdList(uniq(eventIds))

  const getEventProperties = (id) => {
    const event = resolvedEvents?.find(({ id: resolvedEventId } = {}) => resolvedEventId === id)
    return {
      name: event?.info?.name,
      id: id,
      displayId: event?.displayId,
      link: `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${id}`,
    }
  }

  let entityReferenceEntitiesProps = { quantity: entitiesQuantity.none }
  if (eventIds.length > 1) {
    entityReferenceEntitiesProps = {
      quantity: entitiesQuantity.multiple,
      linkText: t('further-events.link'),
      linkBasePath: `/${paths.businessEventsAndTasks}/${paths.businessEvents}`,
      listItems: eventIds.map((id) => getEventProperties(id)),
    }
  } else if (eventIds.length === 1) {
    entityReferenceEntitiesProps = {
      quantity: entitiesQuantity.single,
      ...getEventProperties(eventIds[0]),
    }
  }

  return (
    <RequestStateResolver
      isLoading={isLoadingEvents}
      busyIndicatorSize={BusyIndicatorSize.Small}
      isError={isErrorEvents}
      renderContent={() => <ReferenceEntitiesView {...entityReferenceEntitiesProps} />}
    />
  )
}

EventsReferenceEntitiesView.propTypes = {
  resolvedEvents: PropTypes.arrayOf(PropTypes.shape({})),
  eventIds: PropTypes.arrayOf(PropTypes.string),
}

export default EventsReferenceEntitiesView
