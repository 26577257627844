import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useMultipleDealProperties from 'hooks/services/deals/properties/useMultipleDealProperties'
import useDeals from 'hooks/services/deals/useDeals'
import useMultipleArrayPropertyUuids from 'hooks/services/properties/useMultipleArrayPropertyUuids'
import useMultipleCwpPropertyValuations from 'hooks/services/properties/valuations/useMultipleCwpPropertyValuations'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const useRiskIndicatorDealSpecificsValuationHistory = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { deals: dealsData = [] } = {},
    isLoading: isDealsLoading,
    isError: isDealsError,
    error: dealsError,
  } = useDeals({ filter: { borrowerId: businessPartnerId } }) || {}

  const mappedDealUuids = useMemo(() => dealsData?.map((deal) => deal.dealUuid), [dealsData])

  // get an array of property uuids for each deal
  const {
    data: multipleDealPropertiesData,
    isLoading: isMultipleDealPropertiesLoading,
    isError: isMultipleDealPropertiesError,
    error: multipleDealPropertiesError,
  } = useCombinedQueryResults(
    useMultipleDealProperties({
      dealUuids: mappedDealUuids,
    }) ?? {},
  )

  const isFollowUpRequestEnabled = useMemo(
    () =>
      !isMultipleDealPropertiesLoading &&
      !isMultipleDealPropertiesError &&
      multipleDealPropertiesData &&
      multipleDealPropertiesData.some((property) => property.dealProperties?.length > 0),
    [isMultipleDealPropertiesError, isMultipleDealPropertiesLoading, multipleDealPropertiesData],
  )

  // create an array of arrays of property uuids related to a deal id
  const propertyUuidsArrays = useMemo(
    () =>
      isFollowUpRequestEnabled
        ? multipleDealPropertiesData.map((dealProperty) =>
            dealProperty.dealProperties.map((property) => property.propertyUuid),
          )
        : [],
    [isFollowUpRequestEnabled, multipleDealPropertiesData],
  )

  const flatPropertyUuidsArray = useMemo(
    () => (isFollowUpRequestEnabled ? propertyUuidsArrays.flat().map((uuid) => [uuid]) : []),
    [isFollowUpRequestEnabled, propertyUuidsArrays],
  )

  // array version of usePropertyUuids:
  // get an array of responses for each properties array (related to a deal id),
  // so one entry per deal on the highest array level
  const {
    data: multipleArrayPropertyUuidsData,
    isLoading: isMultipleArrayPropertyUuidsLoading,
    isError: isMultipleArrayPropertyUuidsError,
  } = useMultipleArrayPropertyUuids({
    propertyUuidsArrays,
    options: { enabled: isFollowUpRequestEnabled },
  })

  // array version of useCwpPropertyValuations:
  const {
    data: multiplePropertyValuationsData,
    isLoading: isMultiplePropertyValuationsLoading,
    isError: isMultiplePropertyValuationsError,
  } = useCombinedQueryResults(
    useMultipleCwpPropertyValuations({
      propertyUuids: flatPropertyUuidsArray,
      options: { enabled: isFollowUpRequestEnabled },
    }) ?? {},
    { forceDataReturn: false },
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isDealsLoading,
      isMultipleDealPropertiesLoading,
      isMultipleArrayPropertyUuidsLoading,
      isMultiplePropertyValuationsLoading,
    ],
    errorValues: [
      isDealsError,
      isMultipleDealPropertiesError,
      isMultipleArrayPropertyUuidsError,
      isMultiplePropertyValuationsError,
    ],
    errorDetails: [dealsError, multipleDealPropertiesError],
    tileId,
  })

  const propertyId = useMemo(
    () =>
      mappedDealUuids.map((dealUuid, index) => {
        const propertiesArrayData = multipleArrayPropertyUuidsData?.[index]?.data?.properties
        return propertiesArrayData?.[0]?.id
      }),
    [mappedDealUuids, multipleArrayPropertyUuidsData],
  )

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        multipleArrayPropertyUuidsData,
        multiplePropertyValuationsData,
        sourceRender: { propertyId: propertyId[0] },
      },
    }
  }, [
    dealsData,
    error,
    isSomeValueError,
    isSomeValueLoading,
    multipleArrayPropertyUuidsData,
    multiplePropertyValuationsData,
    propertyId,
  ])
}

export default useRiskIndicatorDealSpecificsValuationHistory
