import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  MultiInput,
  Token,
  Icon,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/conditions/reference-selection/ReferenceMultiSelectionInput.module.css'
import useReferenceNames from 'hooks/services/conditions/reference-selection/useReferenceNames'

const ReferenceMultiSelectionInput = ({
  selectedReferences = [],
  onClickValueHelperIcon,
  onClear,
  onDeleteReference,
  showValueHelperIcon = true,
  resolveNames = true,
  displayPlaceholderText = true,
  disabled,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.reference-object-selection.reference-input',
  })

  const { resolvedReferences } = useReferenceNames({
    references: selectedReferences,
    disabled: !resolveNames,
  })

  const referencesToUse = resolveNames ? resolvedReferences : selectedReferences

  const multiInputContent = useMemo(
    () => (
      <>
        {referencesToUse?.map(({ entityId, entityName, entityType }) => (
          <Token
            key={entityId}
            data-entity-id={entityId}
            data-entity-type={entityType}
            text={entityName || entityId}
          />
        ))}
      </>
    ),
    [referencesToUse],
  )

  const onTokenDelete = useCallback(
    ({
      detail: {
        token: {
          dataset: { entityId, entityType },
        },
      },
    }) => {
      onDeleteReference({ entityId, entityType })
    },
    [onDeleteReference],
  )

  return (
    <FlexBox
      direction={FlexBoxDirection.Row}
      alignItems={FlexBoxAlignItems.Center}
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      fitContainer
    >
      <MultiInput
        onTokenDelete={onTokenDelete}
        tokens={multiInputContent}
        onKeyDown={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        showValueHelpIcon={showValueHelperIcon}
        onValueHelpTrigger={onClickValueHelperIcon}
        className={styles.inputField}
        disabled={disabled}
        placeholder={displayPlaceholderText ? t('placeholder') : ''}
        icon={
          selectedReferences.length !== 0 ? <Icon name="decline" onClick={onClear} /> : undefined
        }
      />
    </FlexBox>
  )
}

ReferenceMultiSelectionInput.propTypes = {
  name: PropTypes.string,
  showValueHelperIcon: PropTypes.bool,
  onClickValueHelperIcon: PropTypes.func,
  onClear: PropTypes.func.isRequired,
  onDeleteReference: PropTypes.func.isRequired,
  selectedReferences: PropTypes.arrayOf(
    PropTypes.shape({
      entityId: PropTypes.string.isRequired,
      entityType: PropTypes.string.isRequired,
    }),
  ),
  resolveNames: PropTypes.bool,
  disabled: PropTypes.bool,
  displayPlaceholderText: PropTypes.bool,
}

export default ReferenceMultiSelectionInput
