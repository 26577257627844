import styles from 'components/domains/business-partners/tile/relationships/UnitMembersTable.module.css'
import Entity from 'components/ui/data/Entity'

const text = (value) => ({ value, cellComponent: <>{value}</> })
const entity = ({ name, id }) => ({
  value: name + id,
  cellComponent: <Entity name={name} id={id} link={`/business-partners/${id}`} />,
})
const entityWithLeadingMemberCategory = ({ name, id, leadingMemberCategory }) => ({
  value: name + id + leadingMemberCategory,
  cellComponent: (
    <div className={styles.businessPartnerCell}>
      <Entity name={name} id={id} link={`/business-partners/${id}`} />
      <span className={styles.unitName}>{leadingMemberCategory}</span>
    </div>
  ),
})

const mapUnitMembersForTable = (members) =>
  members.map(({ id, fullName, address, relationshipType, leadingMemberCategory }) => ({
    rowKey: id,
    businessPartner: leadingMemberCategory
      ? entityWithLeadingMemberCategory({
          name: fullName,
          id,
          leadingMemberCategory,
        })
      : entity({ name: fullName, id }),
    country: text(address?.country?.name),
    postalCode: text(address?.postalCode),
    city: text(address?.city),
    streetName: text(address?.streetName),
    relationshipType: text(relationshipType?.name),
    leadingMemberCategory,
    rowProperties: leadingMemberCategory ? { className: styles.highlightedRow } : {},
  }))

export default mapUnitMembersForTable
