import {
  Label,
  Toolbar,
  ToolbarDesign,
  ToolbarSeparator,
  ToolbarSpacer,
  ToolbarStyle,
} from '@fioneer/ui5-webcomponents-react'
import '@ui5/webcomponents-fiori/dist/SortItem'
import omit from 'lodash.omit'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FilterDialog from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/toolbar/DealCashFlowFilterDialog'
import FilterInfoBar from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/toolbar/DealCashFlowFilterInfoBar'
import GroupingDialog from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/toolbar/DealCashFlowGroupingDialog'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/toolbar/DealCashFlowTablesToolbar.module.css'
import '@ui5/webcomponents-icons/dist/AllIcons.js'
import ToolbarColumnSelection from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/toolbar/DealCashFlowToolbarColumnSelection'
import ToolbarSearching from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/toolbar/DealCashFlowToolbarSearching'
import ToolbarSorting from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/toolbar/DealCashFlowToolbarSorting'
import {
  DealCashFlowColumnSelectionPropTypes,
  DealCashFlowFilters,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/toolbar/dealCashFlowPropTypes'

/**
 * Toolbar for tables. Includes the optional functionalities to sort and search a table.
 *
 * @param title Text to display
 * @param nrOfEntries the number of entries in the table. Not required if displayed in a different way.
 * @param searching optional configuration for the search functionality. If not present, searching is disabled.
 *   - searchParam: The entered value
 *   - onUpdateSearchParam: callback for when the value changes
 * @param style given to the <Toolbar />
 * @param sorting optional configuration for the sorting functionality. If not present, sorting is disabled.
 *   - columnKey: The column to sort by
 *   - isSortingAscending: Whether to sort ascending. Defaults to true
 *   - sortableColumns: Array of columns that are sortable (consisting of title and columnKey)
 *   - onUpdateSorting: Callback when the sorting selection changes (columnKey or isSortingAscending),
 * @param filtering optional configuration for the filter functionality. If not present, filtering is disabled.
 * @param grouping optional configuration for the grouping functionality.
 * @param showColumnSelection optional configuration for the toolbar column selection
 * @param additionalActions optional array to define further buttons as additional table actions
 * @param additionalActionsNextToTitle optional array to define further buttons as additional table actions next to the title
 * @param columnSelection array to define which columns are visible
 *  - columnKey,
 *  - title,
 *  - isVisible: whether the column is visible,
 *  - isSelectableForHiding: whether the column can be hided
 * @param setColumnSelection: Callback for when the selection gets updated
 */
const TablesToolbar = ({
  title: toolbarTitle,
  nrOfEntries,
  searching,
  style,
  sorting,
  additionalActions,
  additionalActionsNextToTitle,
  filtering,
  grouping,
  showColumnSelection = true,
  columnSelection = [],
  setColumnSelection = () => {},
  className,
  onSubmitFilters = () => {},
  ...toolbarOptions
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.deal-cash-flow.components.ui.tables.toolbar',
  })
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false)

  const title =
    nrOfEntries !== undefined
      ? t('title', { title: toolbarTitle, nrOfEntries })
      : t('title-no-entry-number', { title: toolbarTitle })

  return (
    <>
      <Toolbar
        design={ToolbarDesign.Auto}
        toolbarStyle={ToolbarStyle.Clear}
        className={[styles.toolbar, className ?? ''].join(' ')}
        style={style}
        {...omit(toolbarOptions, 'className')}
      >
        <Label className={styles.titleLabel}>{title}</Label>
        {nrOfEntries === undefined && additionalActionsNextToTitle && <ToolbarSeparator />}
        {additionalActionsNextToTitle}
        <ToolbarSpacer />
        <ToolbarSearching searching={searching} />
        {additionalActions?.length && additionalActions}
        {(searching || additionalActions?.length) && (sorting || filtering || grouping) && (
          <ToolbarSeparator />
        )}
        {sorting && <ToolbarSorting sorting={sorting} />}
        {filtering && (
          <FilterDialog
            isOpen={isFilterDialogOpen}
            setIsOpen={setIsFilterDialogOpen}
            filters={filtering.filters}
            setFilters={filtering.setFilters}
            onSubmit={onSubmitFilters}
          />
        )}
        {grouping && <GroupingDialog groups={grouping.groups} setGroupBy={grouping.setGroupBy} />}
        {columnSelection?.length > 0 && showColumnSelection && (
          <ToolbarColumnSelection
            columnSelection={columnSelection}
            setColumnSelection={setColumnSelection}
          />
        )}
      </Toolbar>
      {filtering && (
        <FilterInfoBar setIsFilterDialogOpen={setIsFilterDialogOpen} filters={filtering.filters} />
      )}
    </>
  )
}

TablesToolbar.propTypes = {
  filtering: PropTypes.shape({
    filters: DealCashFlowFilters.isRequired,
    setFilters: PropTypes.func.isRequired,
  }),
  nrOfEntries: PropTypes.number,
  searching: PropTypes.shape({
    searchParam: PropTypes.string,
    onUpdateSearchParam: PropTypes.func.isRequired,
  }),
  sorting: PropTypes.shape({
    columnKey: PropTypes.string,
    isSortingAscending: PropTypes.bool,
    sortableColumns: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        columnKey: PropTypes.string.isRequired,
      }),
    ).isRequired,
    onUpdateSorting: PropTypes.func.isRequired,
  }),
  grouping: PropTypes.shape(GroupingDialog.propTypes),
  showColumnSelection: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  additionalActions: PropTypes.arrayOf(PropTypes.element),
  additionalActionsNextToTitle: PropTypes.any,
  columnSelection: DealCashFlowColumnSelectionPropTypes,
  setColumnSelection: PropTypes.func,
  onSubmitFilters: PropTypes.func,
}

export default TablesToolbar
