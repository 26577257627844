import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/arrears/current-approvals/CurrentApprovalsTable.module.css'
import AnalyticalTableNoDataComponent from 'components/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import { useCollapseExpandTableActions } from 'components/ui/tables/analytical/useCollapseExpandTableActions'

const CurrentApprovalsTable = ({
  tableColumns,
  tableData,
  datacount,
  isStartApprovalProcess = false,
}) => {
  const tableRef = useRef(null)

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.in-approval',
  })

  const {
    additionalTableActions: collapseExpandActions = [],
    expandOrCollapseSubRows,
    handleOnRowExpand,
  } = useCollapseExpandTableActions(tableRef)

  useEffect(() => {
    expandOrCollapseSubRows(true)
  }, [expandOrCollapseSubRows])

  return (
    <AnalyticalTableWithToolbar
      ref={tableRef}
      className={styles.adjustMarginTop}
      title={t('arrear-approvals-with-count', {
        count: datacount,
      })}
      id="businessPartnerArrearApprovalsTable"
      minRows={0}
      columns={tableColumns ?? []}
      // high number of visible rows fixes re-rendering of height on expand
      overscanCountHorizontal={999}
      visibleRows={999}
      // rowHeight needs to be set to a non-empty string to not show an empty row at the end
      rowHeight={'individual'}
      headerRowHeight={32}
      data={tableData ?? []}
      NoDataComponent={() => (
        <AnalyticalTableNoDataComponent
          tableId={'current-approvals-table'}
          isLoading={false}
          isError={false}
        />
      )}
      additionalActions={isStartApprovalProcess ? [] : collapseExpandActions}
      onRowExpandChange={(event) => handleOnRowExpand(event.detail.row)}
      filterable={false}
      sortable={false}
      disableColumnPopover={true}
      withRowHighlight={false}
      showNrOfEntries={false}
      loading={false}
      isTreeTable={true}
      reactTableOptions={{
        autoResetExpanded: false,
      }}
    />
  )
}

export default CurrentApprovalsTable

CurrentApprovalsTable.propTypes = {
  tableColumns: PropTypes.array,
  tableData: PropTypes.array,
  datacount: PropTypes.number,
  isStartApprovalProcess: PropTypes.bool,
}
