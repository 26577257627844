import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ImageCarousel from 'components/domains/properties/general-information/image/ImageCarousel'
import PropertyImageEdit from 'components/domains/properties/general-information/image/edit/PropertyImageEdit'
import DisplayAndEditCard from 'components/ui/card/DisplayAndEditCard'
import {
  PROPERTIES_KEY,
  PROPERTY_IMAGES_KEY,
  usePropertyImages,
} from 'hooks/services/properties/images/usePropertyImages'
import { usePutPropertyImages } from 'hooks/services/properties/images/usePutPropertyImages'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyImageCard = () => {
  const { t } = useTranslation()

  const {
    property: {
      uuid: propertyUuid,
      change_request_exist_indicator: changeRequestExistIndicator,
      allowed_operations,
    },
  } = useContext(PropertyContext)

  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()

  const {
    isLoading: isLoadingImages,
    isError: isErrorImages,
    data: imagesData,
  } = usePropertyImages(propertyUuid)

  const images = !isLoadingImages && !isErrorImages ? imagesData : []
  const noImages = !images.length

  const isAllowedPropertyImageEdit = () =>
    !changeRequestExistIndicator &&
    !!allowed_operations?.allowed_operations?.includes('PropertyImages_Update')

  const putPropertyImages = usePutPropertyImages({
    onSuccess: () => {
      queryClient.invalidateQueries([PROPERTIES_KEY, propertyUuid, PROPERTY_IMAGES_KEY])
      showToast({ children: t('toast.changes-saved') })
    },
    onError: (e) => {
      console.log('Failed to put property images', e)
    },
  })

  const renderInfoComponent = () => <ImageCarousel images={images} />

  const renderEditTable = ({ handleOnChange }) => (
    <PropertyImageEdit propertyUuid={propertyUuid} onChange={handleOnChange} />
  )

  const fieldDefinitions = [
    {
      isShownInDisplay: true,
      customInfoComponent: renderInfoComponent(),
      isShownInEdit: false,
    },
    {
      isShownInDisplay: false,
      customInfoComponent: null,
      renderCustomEditComponent: renderEditTable,
      isShownInEdit: true,
    },
  ]

  const handleSaveChanges = (changes) => {
    if (changes && 'images' in changes) {
      putPropertyImages.mutate({
        property_uuid: propertyUuid,
        property_images: changes['images'],
      })
    }
  }

  const handleCancelChanges = () => {
    queryClient.invalidateQueries([PROPERTIES_KEY, propertyUuid, PROPERTY_IMAGES_KEY])
  }

  return (
    <DisplayAndEditCard
      cardHeaderTitle={t('pages.properties.image-card.title')}
      saveHookIsSuccess={putPropertyImages.isSuccess}
      saveHookIsError={putPropertyImages.isError}
      fieldDefinitions={fieldDefinitions}
      saveChanges={handleSaveChanges}
      cancelChanges={handleCancelChanges}
      isEmpty={noImages}
      emptyContent={{
        hasIllustration: true,
        title: t('pages.properties.image-card.empty.title'),
      }}
      isNotAllowed={false}
      notAllowedContent={{ hasIllustration: true }}
      isError={isErrorImages}
      errorContent={{ hasIllustration: true }}
      isLoading={isLoadingImages}
      isEditable={isAllowedPropertyImageEdit()}
    />
  )
}

export default PropertyImageCard
