import { FlexBox, FlexBoxAlignItems, Switch, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/markets/detail/cards/cells/proxies/MarketProxiesTableSettingsCell.module.css'

const MarketProxiesTableSettingsCell = ({ isEditing, onChange, proxySetting }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.markets.detail.manager.market-proxies.table',
  })
  const isOverride = proxySetting.isOverride
  const settingsText = t(isOverride ? 'setting.specific' : 'setting.default')
  const handleChange = useCallback(
    (event) => {
      onChange(proxySetting.key, event.target.checked)
    },
    [onChange, proxySetting.key],
  )
  if (!isEditing) {
    return <Text>{settingsText}</Text>
  }

  return (
    <FlexBox alignItems={FlexBoxAlignItems.Center} className={styles.settingsWrapper}>
      <Text>{settingsText}</Text>
      <Switch checked={isOverride} onChange={handleChange} />
    </FlexBox>
  )
}

MarketProxiesTableSettingsCell.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  proxySetting: PropTypes.shape({
    key: PropTypes.string.isRequired,
    isOverride: PropTypes.bool.isRequired,
    overrideProxy: PropTypes.shape({}),
  }).isRequired,
}

export default MarketProxiesTableSettingsCell
