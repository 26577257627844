import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useConfig } from 'hooks/config/useConfig'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useMultipleGeneralInformation from 'hooks/services/pbb-custom-service/useMultipleGeneralInformation'
import useMultipleStaffMemberByIds from 'hooks/services/pbb-custom-service/useMultipleStaffMemberByIds'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const useAnnualReviewGeneralInformation = (
  { id: eventId, entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { selectedDeal, dealsData, dealUuids = [] } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const { selectedDealDisplayId } = selectedDeal ?? {}

  const {
    data: multipleGeneralInformationData,
    isLoading: isMultipleGeneralInformationLoading,
    isError: isMultipleGeneralInformationError,
    error: multipleGeneralInformationError,
  } = useCombinedQueryResults(
    useMultipleGeneralInformation({
      eventId,
      dealUuids,
    }),
    { forceDataReturn: true },
  )

  const {
    data: config,
    isLoading: isConfigLoading,
    isError: isConfigError,
    error: configError,
  } = useConfig()

  const sapRelationshipIds = config?.businessPartner?.sapRelationshipIds

  const dealUserIds = []
  if (multipleGeneralInformationData?.length) {
    for (const item of multipleGeneralInformationData) {
      const dealTeamStaffMembers = item?.dealTeam?.staffMembers
      const underwriterSapRelationId = sapRelationshipIds?.underwriter
      const accountManagerSapRelationId = sapRelationshipIds?.accountManager
      const loanAccountManagerSapRelationId = sapRelationshipIds?.loanAccountManager

      const underwriterUser = dealTeamStaffMembers?.find(
        ({ leader, relation }) => leader && relation?.sapRelationId === underwriterSapRelationId,
      )

      const accountManagerUser = dealTeamStaffMembers?.find(
        ({ leader, relation }) => leader && relation?.sapRelationId === accountManagerSapRelationId,
      )

      const loanAccountManagerUser = dealTeamStaffMembers?.find(
        ({ leader, relation }) =>
          leader && relation?.sapRelationId === loanAccountManagerSapRelationId,
      )

      const userIds = [
        accountManagerUser?.userId,
        loanAccountManagerUser?.userId,
        underwriterUser?.userId,
      ].filter((userId) => !!userId)

      if (!isEmpty(userIds)) {
        dealUserIds.push({
          dealUuid: item?.dealUuid,
          underwriterUser,
          accountManagerUser,
          loanAccountManagerUser,
          userIds,
        })
      }
    }
  }

  const {
    data: staffMembersData,
    isLoading: isStaffMembersLoading,
    isError: isStaffMembersError,
    error: staffMembersError,
  } = useCombinedQueryResults(
    useMultipleStaffMemberByIds({
      dealUserIds,
    }),
    { forceDataReturn: true },
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isAnnualReviewDealsLoading,
      isConfigLoading,
      isMultipleGeneralInformationLoading,
      isStaffMembersLoading,
    ],
    errorValues: [
      isAnnualReviewDealsError,
      isConfigError,
      isMultipleGeneralInformationError,
      isStaffMembersError,
    ],
    errorDetails: [
      annualReviewDealsError,
      configError,
      multipleGeneralInformationError,
      staffMembersError,
    ],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        multipleGeneralInformationData,
        sapRelationshipIds,
        staffMembersData,
        sourceRender: {
          businessPartnerId,
          dealDisplayId: selectedDealDisplayId,
        },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealsData,
    multipleGeneralInformationData,
    sapRelationshipIds,
    staffMembersData,
    businessPartnerId,
    selectedDealDisplayId,
    error,
  ])
}

export default useAnnualReviewGeneralInformation
