import { FilterGroupItem, Input, Option, Select } from '@fioneer/ui5-webcomponents-react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { entityTypes as conditionEntityTypes } from 'api/conditions/conditions'
import styles from 'components/domains/conditions/business-object/BusinessObjectSelectionFilterBar.module.css'
import DashboardFilterBar from 'components/ui/page/DashboardFilterBar'

const filterOptions = {
  searchFilter: { visibleOnBar: true },
  entityType: { visibleOnBar: true },
}

const noop = () => {}

const isEnterButtonEvent = (event) => event.code === 'Enter' || event.code === 'NumpadEnter'

const BusinessObjectSelectionFilterBar = ({
  onGo,
  addDealSearch,
  initialSearchFilter = { searchFilter: '', entityType: '' },
}) => {
  const [filterParams, setFilterParams] = useState(initialSearchFilter)
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.business-object-selection.filterbar',
  })

  const onKeyDown = useCallback(
    (event) => {
      if (event.code === 'Space') {
        event.stopPropagation()
        return
      }
      if (isEnterButtonEvent(event)) {
        onGo(filterParams)
      }
    },
    [onGo, filterParams],
  )

  const handleSearchFilterChange = useCallback(({ target: { value: newSearchFilter } }) => {
    setFilterParams((currentFilterParams) => ({
      ...currentFilterParams,
      searchFilter: newSearchFilter,
    }))
  }, [])

  const handleEntityTypeChanged = useCallback(
    ({
      detail: {
        selectedOption: { value: selectedEntityType },
      },
    }) => {
      setFilterParams((currentFilterParams) => ({
        ...currentFilterParams,
        entityType: selectedEntityType,
      }))
    },
    [],
  )

  const filters = [
    <FilterGroupItem label={t('entity-types')} key="entityType">
      <Select
        id="business-object-entity-types"
        value={filterParams.entityType}
        onChange={handleEntityTypeChanged}
        onKeyDown={onKeyDown}
      >
        <Option value="" />
        <Option
          value={conditionEntityTypes.property}
          selected={filterParams.entityType === conditionEntityTypes.property}
        >
          {t('entity-types.property')}
        </Option>
        <Option
          value={conditionEntityTypes.businesspartner}
          selected={filterParams.entityType === conditionEntityTypes.businesspartner}
        >
          {t('entity-types.business-partner')}
        </Option>
        <Option
          value={conditionEntityTypes.deal}
          disabled={!addDealSearch}
          selected={filterParams.entityType === conditionEntityTypes.deal}
        >
          {t('entity-types.deal')}
        </Option>
      </Select>
    </FilterGroupItem>,
  ]
  const onGoInternal = useCallback(() => {
    onGo(filterParams)
  }, [onGo, filterParams])

  const onInputFocus = useCallback((event) => {
    event.stopPropagation()
  }, [])

  return (
    <DashboardFilterBar
      filterOptions={filterOptions}
      activeFiltersCount={0}
      setDialogOpen={noop}
      onGo={onGoInternal}
      additionalProperties={{
        hideToolbar: false,
        search: (
          <Input
            placeholder={t('search')}
            value={filterParams.searchFilter}
            onFocus={onInputFocus}
            onInput={handleSearchFilterChange}
            onKeyDown={onKeyDown}
          />
        ),
        hideToggleFiltersButton: false,
        hideFilterConfiguration: true,
        className: styles.filterbar,
      }}
    >
      {filters}
    </DashboardFilterBar>
  )
}

BusinessObjectSelectionFilterBar.propTypes = {
  onGo: PropTypes.func.isRequired,
  addDealSearch: PropTypes.bool.isRequired,
  initialSearchFilter: PropTypes.shape({
    searchFilter: PropTypes.string,
    entityType: PropTypes.string,
  }),
}

export default BusinessObjectSelectionFilterBar
