import { Modals, Button } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import 'components/domains/properties/property-create/PropertyCreateDialog.css'
import { useDispatch } from 'react-redux'
import { valuationClassification } from 'api/property/valuation/valuationRequests'
import PropertyCreateDialogBasics from 'components/domains/properties/property-create/PropertyCreateDialogBasics'
import Dialog, { DialogPrimaryButton, DialogSecondaryButton } from 'components/ui/dialog/Dialog'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import MessageBox from 'components/ui/message-box/MessageBox'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useAssignProperties from 'hooks/services/deals/properties/useAssignProperties'
import { useAssignPropertyToMarket } from 'hooks/services/markets/useAssignPropertyToMarket'
import { useCreateProperty } from 'hooks/services/properties/useCreateProperty'
import { useProperties } from 'hooks/services/properties/useProperties'
import { usePutPropertyValuations } from 'hooks/services/properties/valuations/usePutPropertyValuations'
import { useValuationCreateMethodCodes } from 'hooks/services/properties/valuations/useValuationCreateMethodCodes'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'
import { setResetPropertiesPagination } from 'redux/slices/properties/resetPropertiesPaginationSlice'
import { addUploadedProperty } from 'redux/slices/properties/uploadedPropertiesSlice'
import { stringContainsNoSpecialCharacters } from 'utils/specialCharacters'

/**
 * use ui-components dialog | https://fioneer.atlassian.net/browse/CWP-13200
 */
const PropertyCreateDialog = ({ onCloseDialog, showDialog, deal = {} }) => {
  const propertyCreateInputsRef = useRef(null)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const handleErrorDialogClose = useCallback(() => setIsErrorDialogOpen(false), [])
  const [errorDetails, setErrorDetails] = useState('')
  const showToast = Modals.useShowToast()

  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const {
    isLoading: isLoadingValuationCreateMethodCodes,
    isError: isErrorValuationCreateMethodCodes,
    data: valuationCreateMethodCodes,
  } = useValuationCreateMethodCodes()

  const findValuationCreateMethodCodeByType = (type) =>
    valuationCreateMethodCodes.find((code) => code?.createMethodCode === type)?.key

  const initialProperty = {
    dealUuid: deal.dealUuid || '',
    dealId: deal.dealId || '',
    typeCode: '',
    description: '',
    countryCode: '',
    postalCode: '',
    cityName: '',
    streetName: '',
    houseNumber: '',
    purchasePrice: '',
    marketValue: '',
    currencyCode: '',
    totalArea: '',
    areaMeasureUnitCode: '',
    longitude: '',
    latitude: '',
    marketId: '',
  }

  const initialValidNumberInputs = {
    purchasePrice: true,
    marketValue: true,
    totalArea: true,
  }

  const [property, setProperty] = useState(initialProperty)
  const [validNumberInputs, setValidNumberInputs] = useState(initialValidNumberInputs)
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [errorSummary, setErrorSummary] = useState()
  const [addressFilter, setAddressFilter] = useState({})

  const isPropertiesRequestEnabled =
    !!addressFilter?.country && !!addressFilter?.zipCode && !!addressFilter?.city
  const { data: { properties = [] } = {}, isFetching: isPropertyCheckLoading } = useProperties({
    filter: addressFilter,
    options: { enabled: isPropertiesRequestEnabled },
  })

  const updateAddressFilter = useCallback(
    (addressFilterObject) => {
      setAddressFilter({
        ...addressFilterObject,
      })
    },
    [setAddressFilter],
  )

  const setPropertyAttribute = useCallback(
    (arg1, value, isValidNumberInput) => {
      setProperty((prevProperty) => ({ ...prevProperty, [arg1]: value }))
      if (arg1 in validNumberInputs) {
        setValidNumberInputs((prevInvalidInputs) => ({
          ...prevInvalidInputs,
          [arg1]: isValidNumberInput,
        }))
      }
    },
    [validNumberInputs],
  )

  const bulkUpdatePropertyAttributes = (newAttributes) => {
    setProperty({ ...property, ...newAttributes })
  }

  const resetState = () => {
    propertyCreateInputsRef?.current?.resetState()
    setProperty(initialProperty)
    setAddressFilter({})
  }

  const isValid = (attribute) => attribute !== ''

  const isNumeric = (num) => !isNaN(num)

  const isGreaterEqualZero = (value) => isNumeric(value) && Number(value) >= 0

  const isOptionalButValidNumber = (attribute, value) => {
    if (value === '') {
      return true
    } else if (validNumberInputs[attribute]) {
      return isGreaterEqualZero(value)
    } else return false
  }

  const isValidProperty = (_property) =>
    isValid(_property.typeCode) &&
    isValid(_property.description) &&
    isValid(_property.countryCode) &&
    isValid(_property.postalCode) &&
    isValid(_property.cityName) &&
    isValid(_property.currencyCode) &&
    isValid(_property.areaMeasureUnitCode) &&
    stringContainsNoSpecialCharacters(_property.postalCode) &&
    stringContainsNoSpecialCharacters(_property.cityName) &&
    stringContainsNoSpecialCharacters(_property.streetName) &&
    stringContainsNoSpecialCharacters(_property.houseNumber) &&
    isOptionalButValidNumber('purchasePrice', _property.purchasePrice) &&
    isOptionalButValidNumber('marketValue', _property.marketValue) &&
    isOptionalButValidNumber('totalArea', _property.totalArea)

  const hasValidDealId = (_property) => isValid(_property?.dealUuid) && isValid(_property?.dealId)

  const hasMarketAssignment = (_property) => _property.marketId && isValid(_property.marketId)

  const hasPurchasePrice = (_property) =>
    _property.purchasePrice !== '' && isGreaterEqualZero(_property.purchasePrice)
  const hasMarketValue = (_property) =>
    _property.marketValue !== '' && isGreaterEqualZero(_property.marketValue)

  const hasAtLeastOneValuation = (_property) =>
    hasPurchasePrice(_property) || hasMarketValue(_property)

  const hasChanges =
    property.typeCode !== initialProperty.typeCode ||
    property.description !== initialProperty.description ||
    property.countryCode !== initialProperty.countryCode ||
    property.postalCode !== initialProperty.postalCode ||
    property.cityName !== initialProperty.cityName ||
    property.streetName !== initialProperty.streetName ||
    property.houseNumber !== initialProperty.houseNumber ||
    property.purchasePrice !== initialProperty.purchasePrice ||
    property.marketValue !== initialProperty.marketValue ||
    property.currencyCode !== initialProperty.currencyCode ||
    property.totalArea !== initialProperty.totalArea ||
    property.areaMeasureUnitCode !== initialProperty.areaMeasureUnitCode ||
    property.longitude !== initialProperty.longitude ||
    property.latitude !== initialProperty.latitude ||
    property.dealUuid !== initialProperty.dealUuid ||
    property.dealId !== initialProperty.dealId

  const handleOnCloseDialog = (force) => {
    if (hasChanges && !force) {
      setShowCancelDialog(true)
    } else {
      resetState()
      onCloseDialog()
    }
  }

  /**
    Create Property consists of 3 steps, steps 2 and 3 are optional and only apply, if the created Property has a dealId
    1. Create Property
    2. Assign Deal to Property to Deal
    3. Assign Property to Market
  */
  const createProperty = useCreateProperty({})
  const assignPropertiesToDeal = useAssignProperties({})
  const putValuations = usePutPropertyValuations({})
  const assignMarketsToProperty = useAssignPropertyToMarket({})

  const invalidatePropertiesAndResetPagination = () => {
    queryClient.invalidateQueries(['properties'])
    dispatch(setResetPropertiesPagination(true))
  }

  const afterPropertyCreateSucceeded = () => {
    setIsSaving(false)
    handleOnCloseDialog(true)
  }
  const showPropertyCreateErrorDialog = async (e) => {
    setErrorSummary(t('pages.property-create.error-text'))
    const errorMessageString = await formatHookError(e)
    setErrorDetails(errorMessageString)
    setIsSaving(false)
    setIsErrorDialogOpen(true)
  }

  const showPropertyCreateWarningDialog = async () => {
    setErrorSummary(t('pages.property-create.warning-text'))
    setIsSaving(false)
    setIsErrorDialogOpen(true)
  }

  const setOrAppendWarning = (errorMessageString) => {
    setErrorDetails((prev) => {
      if (prev === '') {
        return errorMessageString
      } else {
        return prev + '\n\n' + errorMessageString
      }
    })
  }

  const appendPropertyCreateWarning = async (e) => {
    const errorMessageString = await formatHookError(e)
    setOrAppendWarning(errorMessageString)
  }

  const assignPropertyToDeal = (propertyUuid) => {
    assignPropertiesToDeal.mutate(
      {
        dealUuid: property?.dealUuid,
        propertyUuids: [propertyUuid],
      },
      {
        onError: (e) => {
          appendPropertyCreateWarning(e)
          showPropertyCreateWarningDialog()
        },
        onSuccess: () => {
          if (deal) {
            showToast({ children: t('pages.deals.detail.portfolio.create.success') })
          }
          queryClient.invalidateQueries(['deals', deal.dealUuid, 'properties'])
        },
      },
    )
  }

  const assignPropertyToMarket = (propertyUuid, marketId) => {
    if (hasMarketAssignment(property)) {
      assignMarketsToProperty.mutate(
        {
          propertyId: propertyUuid,
          marketId: marketId,
        },
        {
          onError: (e) => {
            appendPropertyCreateWarning(e)
            showPropertyCreateWarningDialog()
          },
        },
      )
    }
  }

  const appendPurchasePriceIfExists = (valuationsToCreate) => {
    if (hasPurchasePrice(property)) {
      valuationsToCreate.push({
        calculation_method_code: findValuationCreateMethodCodeByType(
          valuationClassification.purchasePrice,
        ),
        value_amount: {
          number: property.purchasePrice,
          currency: property.currencyCode,
        },
      })
    }
  }

  const appendMarketValueIfExists = (valuationsToCreate) => {
    if (hasMarketValue(property)) {
      valuationsToCreate.push({
        calculation_method_code: findValuationCreateMethodCodeByType(
          valuationClassification.marketValue,
        ),
        value_amount: {
          number: property.marketValue,
          currency: property.currencyCode,
        },
      })
    }
  }

  const createValuations = (propertyUuid) => {
    const valuationsToCreate = []
    appendMarketValueIfExists(valuationsToCreate)
    appendPurchasePriceIfExists(valuationsToCreate)
    return putValuations.mutateAsync(
      {
        property_uuid: propertyUuid,
        valuations: valuationsToCreate,
      },
      {
        onError: (e) => {
          appendPropertyCreateWarning(e)
          showPropertyCreateWarningDialog()
        },
      },
    )
  }

  const refreshProperties = (propertyId, navigationPropertyMutations) => {
    dispatch(addUploadedProperty(propertyId))
    // FYI: if navigationPropertyMutations is empty, the "then" callback is automatically executed
    Promise.allSettled(navigationPropertyMutations).then(() =>
      invalidatePropertiesAndResetPagination(),
    )
  }

  const handleOnSubmitProperty = (e) => {
    e.preventDefault()
    if (isValidProperty(property)) {
      setIsSaving(true)
      createProperty.mutate(property, {
        onSuccess: async (response) => {
          setErrorDetails('')
          const navigationPropertyMutations = []
          const { uuid, id } = response
          if (hasValidDealId(property)) {
            assignPropertyToDeal(uuid)
          }
          if (hasAtLeastOneValuation(property)) {
            navigationPropertyMutations.push(createValuations(uuid))
          }
          if (hasMarketAssignment(property)) {
            assignPropertyToMarket(uuid, property.marketId)
          }
          afterPropertyCreateSucceeded()
          refreshProperties(id, navigationPropertyMutations)
        },
        onError: (err) => {
          showPropertyCreateErrorDialog(err)
        },
      })
    }
  }

  const handleOnSubmitCancelDialog = () => {
    setShowCancelDialog(false)
    resetState()
    onCloseDialog()
  }

  const handleOnCloseCancelDialog = () => {
    setShowCancelDialog(false)
  }

  return (
    <LoadingStateWrapper
      isLoading={isLoadingValuationCreateMethodCodes}
      isError={isErrorValuationCreateMethodCodes}
      renderContent={() => (
        <>
          <Dialog
            id="property-create-dialog"
            open={showDialog}
            onAfterClose={handleOnCloseDialog}
            className="property-create-dialog-width"
            headerText={t('pages.property-create.create-property')}
            primaryButton={
              <DialogPrimaryButton
                id="property-create-dialog-submit-button"
                design="Emphasized"
                className={'property-create-dialog-footer-button'}
                onClick={handleOnSubmitProperty}
                disabled={!isValidProperty(property) || isSaving || isPropertyCheckLoading}
              >
                {t('buttons.create')}
              </DialogPrimaryButton>
            }
            closeButton={
              <DialogSecondaryButton
                id="property-create-dialog-cancel-button"
                design="Transparent"
                className={'property-create-dialog-footer-button'}
                onClick={() => handleOnCloseDialog(false)}
              >
                {t('buttons.cancel')}
              </DialogSecondaryButton>
            }
          >
            <PropertyCreateDialogBasics
              ref={propertyCreateInputsRef}
              property={property}
              propertiesWithSameAddressFilter={properties}
              addressFilter={addressFilter}
              onChange={setPropertyAttribute}
              onChangeMany={bulkUpdatePropertyAttributes}
              onUpdateAddressFilter={updateAddressFilter}
              deal={deal}
            />
          </Dialog>
          <MessageBox
            id="property-create-cancel-dialog"
            open={showCancelDialog}
            onClose={handleOnCloseCancelDialog}
            titleText={t('pages.property-create.discard-dialog-title')}
            actions={[
              <Button
                id="property-cancel-dialog-submit-button"
                key="submit"
                className={'property-cancel-dialog-footer-button'}
                design="Emphasized"
                onClick={handleOnSubmitCancelDialog}
              >
                {t('buttons.discard')}
              </Button>,
              <Button
                key="cancel"
                id="property-cancel-dialog-cancel-button"
                className={'property-cancel-dialog-footer-button'}
                design="Transparent"
                onClick={handleOnCloseCancelDialog}
              >
                {t('buttons.cancel')}
              </Button>,
            ]}
            type="Warning"
          >
            {t('pages.property-create.discard-dialog-text')}
          </MessageBox>
          <ErrorMessageBoxWithExpandableDetails
            messageSummary={errorSummary}
            messageDetails={errorDetails}
            isOpen={isErrorDialogOpen}
            onClose={handleErrorDialogClose}
          />
        </>
      )}
    />
  )
}
PropertyCreateDialog.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
  showDialog: PropTypes.bool.isRequired,
  deal: PropTypes.shape({
    dealName: PropTypes.string,
    dealUuid: PropTypes.string,
    dealId: PropTypes.string,
  }),
}

export default PropertyCreateDialog
