import { Button, MessageBox, MessageBoxTypes, TextAlign } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import { useAuth } from 'oidc-react'
import PropType from 'prop-types'
import { useCallback, useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx'
import { getExcelUploadContentDefinitions } from 'components/domains/properties/getPropertyRentRollWorkingVersionReferenceData'
import CreateRentRollWorkingVersionProgressIndicator from 'components/domains/properties/rent-roll/working-version/excel-upload/CreateRentRollWorkingVersionProgressIndicator'
import RentRollAdjustExcelColumnMappingDialog, {
  getHeaderFromInputExcelWithDuplicates,
  workingVersionContentKeysWithoutExcelMapping,
} from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollAdjustExcelColumnMappingDialog'
import PropertyRentRollApplyLocalValueMappingDialog from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollApplyLocalValueMappingDialog'
import PropertyRentRollExcelChooseHeaderRowDialog from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollExcelChooseHeaderRowDialog'
import PropertyRentRollExcelColumnMappingExistsDialog from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollExcelColumnMappingExistsDialog'
import PropertyRentRollExcelConfirmOverwriteDialog from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollExcelConfirmOverwriteDialog'
import ExcelFileUploaderButton from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollExcelFileUploaderButton'
import PropertyRentRollExcelFileUploaderDropZone from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollExcelFileUploaderDropZone'
import { ExcelUploadContext } from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollExcelUploadContext'
import applyMappingToExcelValues from 'components/domains/properties/rent-roll/working-version/excel-upload/applyPropertyRentRollWorkingVersionExcelValueMapping'
import {
  excelRowIsEmpty,
  findFirstContentRow,
} from 'components/domains/properties/rent-roll/working-version/excel-upload/commonRentRollExcelUploadFunctions'
import excelUploadAcceptedFileEndings from 'components/domains/properties/rent-roll/working-version/excel-upload/excelUploadAcceptedFileEndings'
import getPropertyRentRollWorkingVersionExcelColumnMapping from 'components/domains/properties/rent-roll/working-version/excel-upload/getPropertyRentRollWorkingVersionExcelColumnMapping'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useCreateOrUpdateRentRollWorkingVersionColumnMapping from 'hooks/services/properties/rent-roll/working-version/excel-upload/useCreateOrUpdateRentRollWorkingVersionColumnMapping'
import { useMatchBpsAndSegmentsToExcelUpload } from 'hooks/services/properties/rent-roll/working-version/excel-upload/useMatchBpsAndSegmentsToExcelUpload'
import { useRentRollWorkingVersionColumnMapping } from 'hooks/services/properties/rent-roll/working-version/excel-upload/useRentRollWorkingVersionColumnMapping'
import { useRentRollWorkingVersionExcelGlobalValueMappingRules } from 'hooks/services/properties/rent-roll/working-version/excel-upload/useRentRollWorkingVersionExcelGlobalValueMappingRules'
import { useRentRollWorkingVersionExcelLocalValueMappingRules } from 'hooks/services/properties/rent-roll/working-version/excel-upload/useRentRollWorkingVersionExcelLocalValueMappingRules'
import useValidateRentalUnitIds from 'hooks/services/properties/rent-roll/working-version/excel-upload/useValidateRentalUnitIds'
import { useGetAllRRWVOptionsInAllMappingLanguages } from 'hooks/services/properties/rent-roll/working-version/useGetAllRentRollWorkingVersionOptionsIncludingAdditionalLanguages'
import { useCreateRentRollWorkingVersion } from 'hooks/services/properties/useCreateRentRollWorkingVersion'
import { useDeleteRentRollWorkingVersion } from 'hooks/services/properties/useDeleteRentRollWorkingVersion'
import useRentRollWorkingVersions from 'hooks/services/properties/useRentRollWorkingVersions'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'

const MAPPINGFROMSCRATCH = 'mappingFromScratch'
const MAPPINGFROMEXISTINGSOURCE = 'mappingFromExistingSource'

const EXCEL_UPLOAD_NUMBER_COLUMN_KEYS = [
  'rent_contracted_year',
  'current_net_rent',
  'rental_unit_area',
  'number_of_units',
  'rent_arrears',
]

class ExcelTooLargeError extends Error {
  errorName = 'EXCEL_TOO_LARGE_ERROR'
  constructor(message) {
    super(message)
    this.name = this.errorName
  }
}

const PropertyRentRollExcelUpload = ({ propertyUuids }) => {
  const { pathname, search } = useLocation()

  const isMultiProperty = propertyUuids?.length > 1
  const routeToWorkingVersion = pathname + '/working-version' + search
  const navigate = useNavigate()
  const { userData } = useAuth()
  const { data: rentRollWorkingVersions } = useRentRollWorkingVersions(propertyUuids)
  const rentRollWorkingVersionExists = !isEmpty(rentRollWorkingVersions)

  const { columnMapping, isNotFoundError: columnMappingDoesNotExist } =
    useRentRollWorkingVersionColumnMapping(propertyUuids)
  const existingColumnMapping = columnMapping?.data
  const {
    data: { field_mapping_rules: localValueMappingRules } = {},
    isNotFoundError: localValueMappingDoesNotExist,
  } = useRentRollWorkingVersionExcelLocalValueMappingRules(propertyUuids)

  const { data } = useRentRollWorkingVersionExcelGlobalValueMappingRules()
  const globalValueMappingRules = data ? data.field_mapping_rules : []

  const { t: tExcelUpload } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.excel-upload',
  })
  const { t: tRentRollTableDE } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.table',
    lng: 'de',
  })
  const { t: tRentRollTableEN } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.table',
    lng: 'en-GB',
  })
  const { localePattern: localeDatePattern } = useShortDateFormatter()
  const queryClient = useQueryClient()
  const contentDefinitions = getExcelUploadContentDefinitions(
    [tRentRollTableDE, tRentRollTableEN],
    isMultiProperty,
  )

  const [deletedWorkingVersions, setDeletedWorkingVersions] = useState(0)
  const [rentalUnitsCount, setRentalUnitsCount] = useState(0)
  const [finalRentalUnitMappingResult, setFinalRentalUnitMappingResult] = useState()
  const [isErrorWindowOpen, setIsErrorWindowOpen] = useState(false)
  const [isErrorWindowWithDetailsOpen, setIsErrorWindowWithDetailsOpen] = useState(false)
  const [isLoadingExcelUpload, setIsLoadingExcelUpload] = useState(false)
  const [excelUploadErrorMessage, setExcelUploadErrorMessage] = useState('')
  const [errorDetails, setErrorDetails] = useState('')

  const [mappedTableData, setMappedTableData] = useState([])

  const allSelectOptions = useGetAllRRWVOptionsInAllMappingLanguages()
  const excelUploadContext = useContext(ExcelUploadContext)

  const {
    openConfirmOverwriteDialog,
    closeConfirmOverwriteDialog,
    openMappingExistsDialog,
    openChooseHeaderRowDialog,
    openApplyLocalValueMappingDialog,
    inputExcelData,
    excelToTableMapping: excelToTableColumnMapping,
    setInputExcelData,
    setExcelToTableMapping,
    setMappedExcelData,
    rowNumberHeader,
    setRowNumberHeader,
    setDefaultRowNumberHeader,
    setDroppedFileToUpload,
  } = excelUploadContext
  const getColumnMappingFromExistingSource = (headerRow) => {
    const entries =
      typeof headerRow !== 'undefined'
        ? Object.entries(getHeaderFromInputExcelWithDuplicates(headerRow))
        : []
    const newMapping = new Map()
    existingColumnMapping.mappings.forEach((mapping) => {
      const matchingEntry = entries.filter(
        (entry) => mapping.column_title_excel === entry[1] && !!entry[1],
      )
      if (matchingEntry.length > 0) {
        newMapping.set(mapping.column_key, {
          excelCol: matchingEntry[0][0],
          excelColContent: matchingEntry[0][1],
        })
      } else {
        newMapping.set(mapping.column_key, { excelCol: '', excelColContent: '' })
      }
    })
    return newMapping
  }

  const getColumnMappingFromScratch = (headerRow) => {
    const entries = Object.entries(headerRow)
    const newMapping = new Map()
    const contentDefinitionsWithMapping = contentDefinitions.filter(
      (conDef) => !workingVersionContentKeysWithoutExcelMapping.includes(conDef.contentKey),
    )
    contentDefinitionsWithMapping.forEach((conDef) => {
      const matchingEntry = entries.filter(
        (entry) => conDef.title === entry[1] || conDef.secondTitle === entry[1],
      )
      if (matchingEntry.length > 0) {
        newMapping.set(conDef.contentKey, {
          excelCol: matchingEntry[0][0],
          excelColContent: matchingEntry[0][1],
        })
      } else {
        newMapping.set(conDef.contentKey, { excelCol: '', excelColContent: '' })
      }
    })
    return newMapping
  }

  const initiateExcelToTableMapping = ({ headerRow, nextRow, mode }) => {
    let mapping
    switch (mode) {
      case MAPPINGFROMSCRATCH:
        mapping = new Map(getColumnMappingFromScratch(headerRow))
        break
      case MAPPINGFROMEXISTINGSOURCE:
        mapping = new Map(getColumnMappingFromExistingSource(headerRow))
        break
      default:
        mapping = null
    }
    const { mapRowToMap } = getPropertyRentRollWorkingVersionExcelColumnMapping(
      mapping,
      allSelectOptions,
      localeDatePattern,
    )
    setExcelToTableMapping(mapping)
    setMappedExcelData(mapRowToMap(nextRow))
  }

  const handleOpenNextDialog = (excelContent) => {
    //depending on whether there is a column mapping saved either the mappingExistsDialog or the adjustMappingDialog will open
    closeConfirmOverwriteDialog()
    const firstRowNumberNotEmpty = excelContent.findIndex((row) => !excelRowIsEmpty(row))
    setInputExcelData(excelContent)
    if (!columnMappingDoesNotExist && existingColumnMapping?.mappings?.length > 0) {
      const savedRowNumberHeader = existingColumnMapping.row_number_header
      const rowNumberFirstContentRow = findFirstContentRow(savedRowNumberHeader, excelContent)
      initiateExcelToTableMapping({
        headerRow:
          excelContent.length > savedRowNumberHeader
            ? excelContent[savedRowNumberHeader]
            : new Map(),
        nextRow:
          excelContent.length > rowNumberFirstContentRow
            ? excelContent[rowNumberFirstContentRow]
            : new Map(),
        mode: MAPPINGFROMEXISTINGSOURCE,
      })
      const rowNumberHeaderSavedOrDefault =
        excelContent.length > savedRowNumberHeader ? savedRowNumberHeader : firstRowNumberNotEmpty
      setRowNumberHeader(rowNumberHeaderSavedOrDefault)
      setDefaultRowNumberHeader(firstRowNumberNotEmpty)
      openMappingExistsDialog()
    } else {
      const rowNumberFirstContentRow = findFirstContentRow(firstRowNumberNotEmpty, excelContent)
      setRowNumberHeader(firstRowNumberNotEmpty)
      initiateExcelToTableMapping({
        headerRow: excelContent[firstRowNumberNotEmpty],
        nextRow: excelContent[rowNumberFirstContentRow],
        mode: MAPPINGFROMSCRATCH,
      })
      openChooseHeaderRowDialog()
    }
  }

  const handleMutationSuccess = async () => {
    await queryClient.invalidateQueries(['rent-roll-working-version', ...propertyUuids.sort()])
    setIsLoadingExcelUpload(false)
    navigate(routeToWorkingVersion)
  }
  const handleMutationError = async (e) => {
    const errorMessageString = await formatHookError(e)
    closeConfirmOverwriteDialog()
    setErrorDetails(errorMessageString)
    setIsErrorWindowWithDetailsOpen(true)
  }

  const rentRollCreationMutation = useCreateRentRollWorkingVersion({
    onSuccess: () => {
      handleMutationSuccess()
    },
    onError: (e) => {
      setIsLoadingExcelUpload(false)
      handleMutationError(e)
    },
  })

  const rentRollDeleteMutation = useDeleteRentRollWorkingVersion({
    onSuccess: () => {
      setDeletedWorkingVersions((oldCount) => oldCount + 1)
    },
    onError: (e) => {
      handleMutationError(e)
    },
  })

  const createOrUpdateColumnMappingMutation = useCreateOrUpdateRentRollWorkingVersionColumnMapping({
    onSuccess: () => {
      queryClient.invalidateQueries(['column_mapping', ...propertyUuids])
    },
  })

  const handleSaveMapping = () => {
    createOrUpdateColumnMappingMutation.mutate({
      propertyUuids: propertyUuids,
      columnMapping: excelToTableColumnMapping,
      rowNumberHeader: rowNumberHeader,
    })
  }

  const createWorkingVersion = useCallback(
    (rentalUnits) => {
      rentRollCreationMutation.mutate({
        property_uuids: propertyUuids,
        header: { creator: userData.profile.name },
        rental_units: rentalUnits,
      })
    },
    [propertyUuids, rentRollCreationMutation, userData.profile.name],
  )

  const { validateAndAdjustRentalUnitIds } = useValidateRentalUnitIds()

  const handleBpAndSegmentMappingComplete = useCallback(
    (tableDataWithMappedBpsAndSegments) => {
      const finalMappingResult = validateAndAdjustRentalUnitIds(tableDataWithMappedBpsAndSegments)
      if (rentRollWorkingVersionExists) {
        setFinalRentalUnitMappingResult(finalMappingResult)
        rentRollWorkingVersions?.map((workingVersion) =>
          rentRollDeleteMutation.mutate({ propertyUuids: workingVersion.property_uuids }),
        )
        return
      }
      createWorkingVersion(finalMappingResult)
    },
    [
      createWorkingVersion,
      rentRollDeleteMutation,
      rentRollWorkingVersionExists,
      rentRollWorkingVersions,
      validateAndAdjustRentalUnitIds,
    ],
  )

  // This useEffect is triggered, when a working version is deleted
  // When all working versions have been deleted, the new one will be created.
  useEffect(() => {
    if (deletedWorkingVersions > 0 && deletedWorkingVersions === rentRollWorkingVersions?.length) {
      queryClient.invalidateQueries(['rent-roll-working-version', ...propertyUuids.sort()])
      createWorkingVersion(finalRentalUnitMappingResult)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedWorkingVersions])

  const { matchBPsAndSegments, totalAmountOfBPRequests, finishedBPRequests } =
    useMatchBpsAndSegmentsToExcelUpload(propertyUuids, handleBpAndSegmentMappingComplete)

  const appendPropertyUuidAndCheckNumberFields = (columnMappedTableData) => {
    columnMappedTableData.forEach((row) => {
      // single property: append property_uuid to working version
      !isMultiProperty && (row['property_uuid'] = propertyUuids[0])
      // remove NaN values from number fields
      EXCEL_UPLOAD_NUMBER_COLUMN_KEYS.forEach((columnKey) => {
        isNaN(row[columnKey]) && (row[columnKey] = '')
      })
    })
  }

  const filterEmptyExcelRows = (excelContent) => [
    ...excelContent.filter((row) => !excelRowIsEmpty(row)),
  ]
  const handleContinueAfterColumnMappingComplete = () => {
    const { mapData } = getPropertyRentRollWorkingVersionExcelColumnMapping(
      excelToTableColumnMapping,
      allSelectOptions,
      localeDatePattern,
    )
    const inputExcelDataWithoutHeader = inputExcelData.slice(rowNumberHeader + 1)
    const columnMappedTableData = mapData(filterEmptyExcelRows(inputExcelDataWithoutHeader))
    setRentalUnitsCount(columnMappedTableData.length)
    appendPropertyUuidAndCheckNumberFields(columnMappedTableData)
    const globalValueMappedTableData = applyMappingToExcelValues({
      rentalUnitsWithOriginalExcelData: columnMappedTableData,
      valueMappingRules: globalValueMappingRules,
    })
    if (localValueMappingDoesNotExist || !localValueMappingRules?.length) {
      setIsLoadingExcelUpload(true)
      matchBPsAndSegments(globalValueMappedTableData)
    } else {
      setMappedTableData([...globalValueMappedTableData])
      openApplyLocalValueMappingDialog()
    }
  }

  const handleOptionalApplyLocalValueMappingAndContinue = ({ applyLocalValueMapping }) => {
    const tableDataWithGlobalMappingApplied = applyMappingToExcelValues({
      rentalUnitsWithOriginalExcelData: mappedTableData,
      valueMappingRules: globalValueMappingRules,
    })
    if (applyLocalValueMapping) {
      const tableDataWithLocalAndGlobalMappingApplied = applyMappingToExcelValues({
        rentalUnitsWithOriginalExcelData: tableDataWithGlobalMappingApplied,
        valueMappingRules: localValueMappingRules,
      })
      matchBPsAndSegments(tableDataWithLocalAndGlobalMappingApplied)
    } else {
      matchBPsAndSegments(tableDataWithGlobalMappingApplied)
    }
    setIsLoadingExcelUpload(true)
  }

  const renderErrorMessageBox = () =>
    isErrorWindowOpen &&
    createPortal(
      <MessageBox
        style={{ textAlign: TextAlign.Left }}
        type={MessageBoxTypes.Error}
        open={isErrorWindowOpen}
        onClose={() => setIsErrorWindowOpen(false)}
      >
        {excelUploadErrorMessage}
      </MessageBox>,
      document.body,
    )

  const renderErrorMessageBoxWithDetails = () =>
    isErrorWindowWithDetailsOpen &&
    createPortal(
      <ErrorMessageBoxWithExpandableDetails
        messageSummary={tExcelUpload('error-message.unexpected-error')}
        messageDetails={errorDetails}
        isOpen={isErrorWindowWithDetailsOpen}
        onClose={() => setIsErrorWindowWithDetailsOpen(false)}
      />,
      document.body,
    )

  /**
   * @typedef {import('@fioneer/ui5-webcomponents-react').FileUploaderDomRef} FileUploaderInput
   *
   * @param {File} file the file to upload
   * @param {FileUploaderInput} [uploadInput] the file input element in case the element was uploaded
   *                                          via button click, otherwise undefined
   */
  const handleUploadFile = (file, uploadInput) => {
    const fileReader = new FileReader()
    fileReader.readAsArrayBuffer(file)

    fileReader.onload = (e) => {
      try {
        const bufferArray = e?.target.result
        if (uploadInput) {
          uploadInput.value = undefined
        }
        const workBook = XLSX.read(bufferArray, { type: 'buffer' })
        const workSheetName = workBook.SheetNames[0]
        const workSheet = workBook.Sheets[workSheetName]
        const excelContent = XLSX.utils.sheet_to_json(workSheet, {
          header: 'A',
          defval: null,
          blankrows: true,
          range: 0,
        })
        if (Object.keys(excelContent?.[0] || {}).includes('AAA')) {
          throw new ExcelTooLargeError('Uploaded Excel contains too many columns')
        }
        handleOpenNextDialog(excelContent)
      } catch (error) {
        if (error.name === new ExcelTooLargeError().name) {
          setExcelUploadErrorMessage(tExcelUpload('error-message.excel-too-large'))
        } else {
          setExcelUploadErrorMessage(tExcelUpload('error-message.unexpected-error') + error.name)
        }
        closeConfirmOverwriteDialog()
        setIsErrorWindowOpen(true)
      } finally {
        setDroppedFileToUpload(null)
      }
    }
  }

  const onFileDrop = (files) => {
    if (files.length > 1) {
      setExcelUploadErrorMessage(tExcelUpload('error-message.too-many-files'))
      setIsErrorWindowOpen(true)
      return
    }

    const droppedFile = files[0]

    // it would make more sense to check for MIME types, but the UI5 upload button
    // only supports file endings, and we rather don't want two configs for the same thing
    const isValidFile = excelUploadAcceptedFileEndings.some((fileEnding) =>
      droppedFile.name.endsWith(fileEnding),
    )

    if (!isValidFile) {
      setExcelUploadErrorMessage(
        tExcelUpload('error-message.unsupported-file-type', {
          acceptedTypes: excelUploadAcceptedFileEndings.join(', '),
        }),
      )
      setIsErrorWindowOpen(true)
      return
    }

    if (rentRollWorkingVersionExists) {
      setDroppedFileToUpload(droppedFile)
      openConfirmOverwriteDialog()
    } else {
      handleUploadFile(droppedFile)
    }
  }

  // Bottleneck in the RRWV creation process are the BP requests (one for each unique BP).
  // That's why that is the measurement for the progress indicator.
  const numberToDisplayInLoadingScreen = () => {
    const percentageFinished = totalAmountOfBPRequests
      ? finishedBPRequests / totalAmountOfBPRequests
      : 1
    return parseInt(rentalUnitsCount * percentageFinished)
  }
  return (
    <>
      {renderErrorMessageBox()}
      {renderErrorMessageBoxWithDetails()}
      {!rentRollWorkingVersionExists ? (
        <ExcelFileUploaderButton
          handleUploadFile={handleUploadFile}
          uploadButtonText={tExcelUpload('upload-button')}
        />
      ) : (
        <>
          <Button design="Emphasized" onClick={openConfirmOverwriteDialog}>
            {tExcelUpload('upload-button')}
          </Button>
          {createPortal(
            <PropertyRentRollExcelConfirmOverwriteDialog handleUploadFile={handleUploadFile} />,
            document.body,
          )}
        </>
      )}
      <PropertyRentRollExcelFileUploaderDropZone onDrop={onFileDrop} />
      <>
        {createPortal(
          <PropertyRentRollExcelChooseHeaderRowDialog
            initiateExcelToTableMapping={initiateExcelToTableMapping}
            columnMappingExists={
              !columnMappingDoesNotExist && existingColumnMapping?.mappings?.length > 0
            }
          />,
          document.body,
        )}
        {createPortal(
          <RentRollAdjustExcelColumnMappingDialog
            handleAcceptMappingAndContinue={() => {
              handleSaveMapping()
              handleContinueAfterColumnMappingComplete()
            }}
            isMultiProperty={isMultiProperty}
          />,
          document.body,
        )}
        {createPortal(
          <PropertyRentRollExcelColumnMappingExistsDialog
            propertyUuids={propertyUuids}
            initiateExcelToTableMapping={initiateExcelToTableMapping}
            handleAcceptMappingAndContinue={() => {
              handleContinueAfterColumnMappingComplete()
            }}
          />,
          document.body,
        )}
        {createPortal(
          <PropertyRentRollApplyLocalValueMappingDialog
            handleOptionalApplyLocalValueMappingAndContinue={
              handleOptionalApplyLocalValueMappingAndContinue
            }
          />,
          document.body,
        )}
        {createPortal(
          <CreateRentRollWorkingVersionProgressIndicator
            isLoading={isLoadingExcelUpload}
            numberTotal={rentalUnitsCount}
            numberUploaded={numberToDisplayInLoadingScreen()}
          />,
          document.body,
        )}
      </>
    </>
  )
}

PropertyRentRollExcelUpload.propTypes = {
  propertyUuids: PropType.arrayOf(PropType.string.isRequired),
}

export default PropertyRentRollExcelUpload
