import PropTypes from 'prop-types'

const ExposureRiskFigure = PropTypes.shape({
  keyDate: PropTypes.string,
  value: PropTypes.number,
  unit: PropTypes.string,
})

const RiskFigures = PropTypes.shape({
  pipeline: ExposureRiskFigure,
  existing: ExposureRiskFigure,
  sr: ExposureRiskFigure,
  srl: ExposureRiskFigure,
  ead: ExposureRiskFigure,
  thresholdLe: ExposureRiskFigure,
  brm: ExposureRiskFigure,
  maxLe: ExposureRiskFigure,
  arm: ExposureRiskFigure,
})

const RiskFiguresDisplay = PropTypes.shape({
  pipeline: PropTypes.string,
  existing: PropTypes.string,
  sr: PropTypes.string,
  srl: PropTypes.string,
  ead: PropTypes.string,
  thresholdLe: PropTypes.string,
  brm: PropTypes.string,
  maxLe: PropTypes.string,
  arm: PropTypes.string,
})

const assetSubClassesShape = {
  riskFigures: RiskFigures,
}
assetSubClassesShape.assetSubClasses = PropTypes.objectOf(PropTypes.shape(assetSubClassesShape))

const AssetClasses = PropTypes.objectOf(
  PropTypes.shape({
    assetSubClasses: PropTypes.objectOf(PropTypes.shape(assetSubClassesShape)),
    riskFigures: RiskFigures,
  }),
)

export default { RiskFigures, RiskFiguresDisplay, AssetClasses }
