import { ValueState } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import { riskIndicatorStatusMapping } from 'components/domains/business-partners/tile/risk-monitoring/utils/typeCodes'

const useRiskIndicatorStatusFieldDecisionPaper = ({ id, name, shortText, typeCode }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix:
      'pages.business-partner.risk-monitoring.risk-indicators.group-details.general-information',
  })

  return [
    {
      name,
      label: t(id),
      value: shortText ?? '-',
      statusProps: {
        inverted: true,
        state:
          riskIndicatorStatusMapping?.[id.toUpperCase()]?.[typeCode]?.valueState || ValueState.None,
        children: shortText,
      },

      isShownInDisplay: true,
      isShownInEdit: false,
    },
  ]
}

export default useRiskIndicatorStatusFieldDecisionPaper
