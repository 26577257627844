import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import EventsSummaryCardTable from 'components/domains/business-events-and-tasks/events/events-summary-card/EventsSummaryCardTable'

const EventsSummaryCard = ({ events }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.events.empty-events-summary-card',
  })

  return (
    <EventsSummaryCardTable
      events={events}
      shownColumns={['name', 'assignee', 'dueDate', 'status']}
      openEventInNewTab
      noDataText={t('no-data-text')}
    />
  )
}

EventsSummaryCard.propTypes = {
  events: PropTypes.array.isRequired,
}

export default EventsSummaryCard
