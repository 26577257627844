// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Important annotations needed since otherwise the properties of ui5-webcomponents Text will take precedence over this css class */
.YjM9nocaU5fLCyRMWNen {
  display: flex !important;
  justify-content: flex-end !important;
  color: rgb(50 54 58 / 60%) !important;
}

._rBMw6VtHls_Qocz5b7d {
  margin: auto;
}

`, "",{"version":3,"sources":["webpack://./src/components/ui/input/TextAreaWithMaxCharacterRestriction.module.css"],"names":[],"mappings":"AAAA,mIAAmI;AACnI;EACE,wBAAwB;EACxB,oCAAoC;EACpC,qCAAqC;AACvC;;AAEA;EACE,YAAY;AACd","sourcesContent":["/* Important annotations needed since otherwise the properties of ui5-webcomponents Text will take precedence over this css class */\n.charactersRemaining {\n  display: flex !important;\n  justify-content: flex-end !important;\n  color: rgb(50 54 58 / 60%) !important;\n}\n\n.charactersTextArea {\n  margin: auto;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"charactersRemaining": `YjM9nocaU5fLCyRMWNen`,
	"charactersTextArea": `_rBMw6VtHls_Qocz5b7d`
};
export default ___CSS_LOADER_EXPORT___;
