import { Link, Text } from '@fioneer/ui5-webcomponents-react'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/TextShowMoreWrapper.module.css'

const TextShowMoreWrapper = ({ text, characterLimit, shouldFormatHtml = false }) => {
  const [isTextExpanded, setIsTextExpanded] = useState(false)
  const { t } = useTranslation('decisionPaper')

  const toggleShowMore = () => {
    setIsTextExpanded(!isTextExpanded)
  }

  const formatHTML = (inputText) => (shouldFormatHtml ? parse(inputText) : inputText)

  if (!isTextExpanded) {
    return (
      <Text>
        {text.length > characterLimit && (
          <>
            {formatHTML(`${text.substring(0, characterLimit)}...`)}
            <Link className={styles.showMoreLessButton} href="" onClick={toggleShowMore}>
              {t('buttons.more')}
            </Link>
          </>
        )}
        {text.length < characterLimit && <>{formatHTML(text)}</>}
      </Text>
    )
  }

  return (
    <Text>
      {formatHTML(text)}
      <Link className={styles.showMoreLessButton} href="" onClick={toggleShowMore}>
        {t('buttons.less')}
      </Link>
    </Text>
  )
}

TextShowMoreWrapper.propTypes = {
  text: PropTypes.string.isRequired,
  characterLimit: PropTypes.number.isRequired,
  shouldFormatHtml: PropTypes.bool,
}

export default TextShowMoreWrapper
