import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Icon,
  Popover,
  PopoverPlacementType,
  Text,
  Title,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { useId, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import styles from 'components/domains/deals/limit-check/reservations/ReservationWarningPopover.module.css'

export const ReservationWarningPopoverType = {
  Error: 'error',
  Warning: 'warning',
}

const ReservationWarningPopover = ({ content, title, type }) => {
  const idSuffix = useId()
  const id = useMemo(
    () => `outdated-reservation-${type ?? 'warning'}-icon-${idSuffix}`,
    [idSuffix, type],
  )
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const iconName = type === ReservationWarningPopoverType.Error ? 'error' : 'alert'

  return (
    <React.Fragment>
      <Button
        id={id}
        design={ButtonDesign.Transparent}
        icon={iconName}
        className={styles[type]}
        onClick={() => setIsPopoverOpen(true)}
      />
      {createPortal(
        <Popover
          opener={id}
          className={styles.popover}
          onAfterClose={() => setIsPopoverOpen(false)}
          header={
            <FlexBox
              alignItems={FlexBoxAlignItems.Center}
              justifyContent={FlexBoxJustifyContent.Start}
              direction={FlexBoxDirection.Row}
              className={styles.popoverHeader}
            >
              <Icon name={iconName} className={styles[type]} />
              <Title className={styles.popoverTitle}>{title}</Title>
            </FlexBox>
          }
          placementType={PopoverPlacementType.Bottom}
          open={isPopoverOpen}
        >
          <Text className={styles[type]}>{content}</Text>
        </Popover>,
        document.body,
      )}
    </React.Fragment>
  )
}

export default ReservationWarningPopover

ReservationWarningPopover.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
}
