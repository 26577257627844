import { IllustratedMessage } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/OtherRelationships.module.css'
import Card from 'components/ui/card/Card'
import Entity from 'components/ui/data/Entity'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'

const compareRelationshipsByBusinessPartnerName = (a, b) =>
  a.relatedBusinessPartner.fullName.localeCompare(b.relatedBusinessPartner.fullName)

const mapRelationshipsToRows = (relationships) => {
  const text = (value) => ({ value, cellComponent: <>{value}</> })
  const entity = ({ name, id }) => ({
    value: name + id,
    cellComponent: <Entity name={name} id={id} link={`/business-partners/${id}`} />,
  })

  return relationships.map((relationship) => {
    const businessPartner = relationship.relatedBusinessPartner
    return {
      rowKey: `${relationship.localizedDescription}-${businessPartner.id}`,
      relationship: text(relationship.localizedDescription),
      relatedBusinessPartner: entity({ name: businessPartner.fullName, id: businessPartner.id }),
      country: text(businessPartner.address?.country?.name),
      postalCode: text(businessPartner.address?.postalCode),
      city: text(businessPartner.address?.city),
      streetName: text(businessPartner.address?.streetName),
    }
  })
}

/**
 * Component to display all other (non-unit) relationships of the current business partner
 * @param relationships array of relationship objects with attributes
 *    - relatedBusinessPartner: the business partner with a relation to the current business partner
 *    - localizedDescription: string describing the relationship in natural language
 */
const OtherRelationships = ({ relationships }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-partner.relationships.other',
  })
  const columnDefinitions = [
    { title: t('relationship'), columnKey: 'relationship', filter: filterTypes.CONTAINS },
    {
      title: t('related-business-partner'),
      columnKey: 'relatedBusinessPartner',
      filter: filterTypes.CONTAINS,
    },
    { title: t('country'), columnKey: 'country', filter: filterTypes.CONTAINS },
    { title: t('postal-code'), columnKey: 'postalCode', filter: filterTypes.CONTAINS },
    { title: t('city'), columnKey: 'city', filter: filterTypes.CONTAINS },
    { title: t('street'), columnKey: 'streetName', filter: filterTypes.CONTAINS },
  ]

  const tableData = useMemo(() => {
    const sortedRelationships = [...relationships].sort(compareRelationshipsByBusinessPartnerName)
    return mapRelationshipsToRows(sortedRelationships)
  }, [relationships])

  return (
    <Card>
      <div className={styles.cardContent}>
        <SortedTable
          columnDefinitions={columnDefinitions}
          toolbarConfig={{
            title: t('relationships'),
            searching: true,
            sorting: { columnKey: 'relationship', isSortingAscending: true },
          }}
          tableData={tableData}
          renderEmptyTableComponent={() => (
            <IllustratedMessage
              name="NoData"
              titleText={t('empty.title')}
              subtitleText={t('empty.subtitle')}
            />
          )}
        />
      </div>
    </Card>
  )
}

OtherRelationships.propTypes = {
  relationships: PropTypes.arrayOf(
    PropTypes.shape({
      relatedBusinessPartner: PropTypes.any.isRequired,
      localizedDescription: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default OtherRelationships
