import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { usePropertyKeyInternalContactFunctionsMapping } from 'components/domains/properties/overview/keyInternalContacts/usePropertyKeyInternalContactFunctionsMapping'
import LoadingMultiComboBox from 'components/ui/multi-combo-box/LoadingMultiComboBox'
import { usePropertyPartnerFunctions } from 'hooks/services/properties/partners/usePropertyPartnerFunctions'

const PropertyKeyInternalContactFunctionsMultiSelect = ({
  userId,
  selected = [],
  onSelectionChange,
}) => {
  const { isError, isLoading, data } = usePropertyPartnerFunctions('internal')

  const selectedIds = useMemo(() => selected.map(({ id }) => id), [selected])

  return (
    <LoadingMultiComboBox
      id={`key-internal-contact-functions-multi-combo-box-${userId}`}
      useLoader={usePropertyKeyInternalContactFunctionsMapping}
      input={{ isError, isLoading, data }}
      onSelectionChange={onSelectionChange}
      selected={selectedIds}
    />
  )
}

PropertyKeyInternalContactFunctionsMultiSelect.propTypes = {
  userId: PropTypes.string.isRequired,
  selected: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })),
  onSelectionChange: PropTypes.func.isRequired,
}

export default PropertyKeyInternalContactFunctionsMultiSelect
