import camelize from 'camelize'
import { countBy, uniq, isEmpty } from 'lodash'
import sum from 'lodash.sum'
import { useMemo } from 'react'
import {
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  usePercentageFormatter,
} from 'hooks/i18n/useI18n'
import useMultipleFactSheetKeyFacts from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/backend/useMultipleFactSheetKeyFacts'
import { useTypeCodesMinor } from 'hooks/services/properties/useTypeCodesMinor'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

export const useFactSheetKeyFacts = ({ dealUuids }) => {
  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })
  const formatCurrency = useCustomizableCurrencyFormatter()
  const typeCodesMinor = useTypeCodesMinor()

  const {
    data: multipleFactSheetKeyFactsData,
    isLoading: isLoadingMultipleFactSheetKeyFacts,
    isError: isErrorMultipleFactSheetKeyFacts,
  } = useCombinedQueryResults(useMultipleFactSheetKeyFacts({ dealUuids }))

  const factSheetKeyFactsPerDealUuid = multipleFactSheetKeyFactsData
    ?.map((factSheetKeyFacts) => {
      const detailedProperties = factSheetKeyFacts?.detailedProperties
      const valuationSum = factSheetKeyFacts?.propertyValuations?.SUM
      const typeCodes = factSheetKeyFacts?.typeCodes
      const multiPropertyKpis =
        factSheetKeyFacts?.multiPropertyKpis?.keyDateToMultiPropertyRentRollKpis?.[0]?.kpis
      const multiPropertyView = factSheetKeyFacts?.multiPropertyView
      const costs = factSheetKeyFacts?.propertiesCosts

      const propertyUuidList = detailedProperties?.map(({ uuid }) => uuid) ?? []

      const isMultiProperty = propertyUuidList.length > 1

      const getFullYear = (date) => new Date(date).getFullYear()
      const getYearRange = (yearList) => {
        const uniqYearList = uniq(yearList?.map((year) => getFullYear(year)))
        const sortedYearList = uniqYearList
          ?.filter((date) => !!date)
          .sort((a, b) => new Date(a) - new Date(b))

        if (isEmpty(sortedYearList)) {
          return []
        }
        return sortedYearList.length === 1
          ? [sortedYearList[0]]
          : [sortedYearList[0], sortedYearList.pop()]
      }

      const getTypeCodeName = (typeCodeList, typeCode) =>
        (typeCodeList ?? []).find(({ key }) => key === typeCode)?.displayName

      const getTypeMinorName = (typeCodeMinor) =>
        typeCodesMinor?.data?.type_codes_minor?.find((element) => element.key === typeCodeMinor)
          ?.display_name

      const getPropertiesInformation = () => {
        const properties = detailedProperties ? camelize(detailedProperties) : []

        const majorTypeCodes = []
        const countryNameSet = new Set()
        const constructionYearList = []
        const annualRentalIncome = []

        properties.forEach((property) => {
          majorTypeCodes.push(property?.typeCode)
          countryNameSet.add(property?.address?.countryName)
          constructionYearList.push(property?.constructionCompletionDate)
          annualRentalIncome.push(property?.actualRentAmount?.number)
        })

        //Counts how often a typeCode is present, sorts the typeCode counts in descending order and picks the typeCode that has the highest count.
        const majorTypeCode = Object.entries(countBy(majorTypeCodes)).sort(
          (a, b) => b[1] - a[1],
        )?.[0]?.[0]
        const majorTypeName = getTypeCodeName(camelize(typeCodes), majorTypeCode)

        const constructionYearValues = getYearRange(constructionYearList)

        const marketValue = valuationSum?.MARKETVALUE?.number
        const sumAnnualRentalIncome = sum(annualRentalIncome.filter(Boolean))
        const multiplier =
          marketValue && sumAnnualRentalIncome ? marketValue / sumAnnualRentalIncome : 0

        const formattedMultiplier = multiplier ? formatNumber(multiplier) : 0
        const initialYield = multiplier ? formatPercentage(1 / multiplier) : 0

        const singlePropertyResult = {
          majorTypeName,
          subType: getTypeMinorName(properties[0]?.usage.usageCode) ?? '',
          constructionYear: [getFullYear(constructionYearList?.[0])],
          countryName: Array.from(countryNameSet),
          address: properties[0]?.address,
          id: properties[0]?.id,
          multiplier: formattedMultiplier,
          initialYield,
        }
        const multiPropertyResult = {
          majorTypeName,
          constructionYear: constructionYearValues,
          countryName: Array.from(countryNameSet),
          nrOfProperties: propertyUuidList.length,
          multiplier: formattedMultiplier,
          initialYield,
        }
        return isMultiProperty ? multiPropertyResult : singlePropertyResult
      }

      const propertiesInformation = getPropertiesInformation()

      const getModernizationYear = () => {
        const modernizationYearList = []
        costs?.costsWithLastModernizationDate.forEach((cost) => {
          if (cost?.lastModernizationDate) {
            modernizationYearList.push(cost?.lastModernizationDate)
          }
        })
        return getYearRange(modernizationYearList)
      }

      const modernizationYear = getModernizationYear()

      const currentKpis = multiPropertyKpis ?? []
      const getKpiRentValues = () => {
        const getFormattedValueOrUndefined = (value, currency) =>
          value && currency ? formatCurrency(value, currency) : undefined
        return {
          annualizedCurrentRent: getFormattedValueOrUndefined(
            currentKpis?.annualizedCurrentRent?.number,
            currentKpis?.annualizedCurrentRent?.currency,
          ),
          marketRent: getFormattedValueOrUndefined(
            currentKpis?.totalMarketRent?.number,
            currentKpis?.totalMarketRent?.currency,
          ),
        }
      }

      const kpiRentValues = getKpiRentValues()

      const multiPropertyViewId = multiPropertyView?.uuid

      const sourcePath = isMultiProperty
        ? `/properties/portfolio/overview?portfolio-view-id=${multiPropertyViewId}`
        : `/properties/${propertiesInformation.id}`

      return {
        [factSheetKeyFacts.dealUuid]: {
          dealUuid: factSheetKeyFacts.dealUuid,
          propertiesInformation,
          modernizationYear,
          kpiRentValues,
          isMultiProperty,
          sourceRender: { path: sourcePath },
          noProperties: isEmpty(propertyUuidList),
        },
      }
    })
    .reduce((prev, curr) => Object.assign(prev, curr), {})

  return useMemo(
    () => ({
      isLoading: isLoadingMultipleFactSheetKeyFacts,
      isError: isErrorMultipleFactSheetKeyFacts,
      data: {
        factSheetKeyFactsPerDealUuid,
      },
    }),
    [
      isLoadingMultipleFactSheetKeyFacts,
      isErrorMultipleFactSheetKeyFacts,
      factSheetKeyFactsPerDealUuid,
    ],
  )
}
