import isNil from 'lodash.isnil'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useConfig } from 'hooks/config/useConfig'
import { useRequest } from 'hooks/services/baseService'

const useFilterEventTypes = ({ entityType, isEntityWorkingVersion }) => {
  const {
    data: {
      businessPartner: { businessPartnerEventTypes } = {},
      deals: { liveVersionEventTypes, workingVersionEventTypes } = {},
    } = {},
  } = useConfig()

  const getEventsConfiguration = () => {
    if (entityType === cwpEntityTypes.DEAL) {
      if (isNil(isEntityWorkingVersion)) return []
      return isEntityWorkingVersion ? workingVersionEventTypes : liveVersionEventTypes
    }
    if (entityType === cwpEntityTypes.BUSINESS_PARTNER) {
      return businessPartnerEventTypes
    }
    return []
  }

  const filterEvents = (eventTypesResponse) => {
    const eventTypes = eventTypesResponse?.data?.result
    const eventsConfiguration = getEventsConfiguration()
    // No value in the configuration leads to the display of all event types
    if (!eventsConfiguration?.length || !eventTypes?.length) {
      return eventTypesResponse
    }
    const filteredEventTypesList = eventTypes.filter(({ code }) =>
      eventsConfiguration.includes(code),
    )
    return { ...eventTypesResponse, data: { result: filteredEventTypesList } }
  }

  return filterEvents
}

export const useEventTypes = ({ entityType, eventCode, isEntityWorkingVersion, enabled }) => {
  const queryParams = new URLSearchParams({ entity_type: entityType })
  const filterEvents = useFilterEventTypes({ entityType, isEntityWorkingVersion })
  if (!isNil(eventCode)) {
    queryParams.append('event_code', eventCode)
  }
  const response = useRequest({
    path: `/events/configurations/types?${queryParams}`,
    options: { enabled },
  })

  return filterEvents(response)
}
