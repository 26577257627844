import PropTypes from 'prop-types'
import styles from 'components/domains/business-partners/tile/tenancy/EntityWithStatus.module.css'
import Entity from 'components/ui/data/Entity'

const propTypes = {
  status: PropTypes.node,
  className: PropTypes.string,
}

/**
 * @typedef {Parameters<typeof Entity>[0]?} overrides
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 */
const EntityWithStatus = ({ status, ...props }) => (
  <div className={styles.wrapper}>
    <Entity
      {...props}
      className={props?.className ? `${props.className} ${styles.entity}` : styles.entity}
    />
    {status}
  </div>
)

EntityWithStatus.propTypes = propTypes

export default EntityWithStatus
