import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import get from 'lodash.get'
import BusinessPartnerLink from 'components/domains/business-events-and-tasks/BusinessPartnerLink'
import PropertyLink from 'components/domains/business-events-and-tasks/PropertyLink'
import PropertyRentRollComparisonDeviation, {
  YEAR_UNIT,
} from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonDeviation'
import styles from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonTable.module.css'
import { COMPARABLE_STATUS } from 'components/domains/properties/rent-roll/comparison/constants'
import Entity from 'components/ui/data/Entity'
import Labeled from 'components/ui/data/Labeled'
import AnalyticalTableCell from 'components/ui/tables/analytical/AnalyticalTableCell'
import { useAreaPiecesUnit } from 'hooks/i18n/useI18n'
import useTenantCellBorder from 'hooks/services/properties/rent-roll/comparison/useTenantCellBorder'

const renderTenantStatus = (comparableStatus, t) => {
  switch (comparableStatus) {
    case COMPARABLE_STATUS.NEW:
      return t('table.cells.new-tenant')
    case COMPARABLE_STATUS.OLD:
      return t('table.cells.old-tenant')
    default:
      return null
  }
}

const renderTenantStatusStyle = (comparableStatus) => {
  switch (comparableStatus) {
    case COMPARABLE_STATUS.NEW:
      return styles.tenantCellNewTenant
    case COMPARABLE_STATUS.OLD:
      return styles.tenantCellOldTenant
    default:
      return null
  }
}

const getOriginalValuesByAccessor = (originalRows, accessor) =>
  originalRows.map((row) => get(row?.original, accessor) ?? 0)

const sum = (groupedCell, accessor) => {
  const originalRows = groupedCell?.row?.subRows
  const originalValues = getOriginalValuesByAccessor(originalRows, accessor)
  return originalValues.reduce((a, b) => a + b, 0)
}

const weightedSum = (groupedCell, accessor, weightAccessor, weightSum) => {
  const originalRows = groupedCell?.row?.subRows
  const originalValues = getOriginalValuesByAccessor(originalRows, accessor)
  const originalWeights = getOriginalValuesByAccessor(originalRows, weightAccessor)
  const weightedValues = originalValues.map(
    (value, index) => value * (originalWeights[index] / weightSum),
  )
  return weightedValues.reduce((a, b) => a + b, 0)
}

const getGroupCellComparisonStatus = (groupCell) => {
  const originalRows = groupCell?.row?.subRows
  const originalValues = getOriginalValuesByAccessor(originalRows, 'comparableStatus')
  return originalValues.every((value) => value !== COMPARABLE_STATUS.NOT_COMPARABLE)
    ? COMPARABLE_STATUS.COMPARABLE
    : COMPARABLE_STATUS.NOT_COMPARABLE
}

const getCurrentOrComparisonValue = (current, comparison) => current ?? comparison

export const EmptyCell = <AnalyticalTableCell>{() => <></>}</AnalyticalTableCell>

export const PropertyCell = (
  <AnalyticalTableCell>
    {({ property }, { t }) => (
      <FlexBox direction={FlexBoxDirection.Column}>
        <PropertyLink propertyUuid={property.uuid} />
        <Labeled label={t('property-type')} showColon className={styles.extraTopMargin}>
          <span>{property.typeName}</span>
        </Labeled>
      </FlexBox>
    )}
  </AnalyticalTableCell>
)

export const TenantCell = (
  <AnalyticalTableCell>
    {({ tenant, comparableStatus }, { t }) => {
      const tenantCellRef = useTenantCellBorder(comparableStatus)
      return (
        <div ref={tenantCellRef}>
          {tenant.anonymous ? (
            <Entity name={tenant.name} />
          ) : (
            <BusinessPartnerLink businessPartnerId={tenant.id} useSingleLine={false} />
          )}
          <div className={renderTenantStatusStyle(comparableStatus)}>
            {renderTenantStatus(comparableStatus, t)}
          </div>
          <Labeled label={t('table.cells.segment')} showColon className={styles.extraTopMargin}>
            <span>{tenant.segmentName}</span>
          </Labeled>
          <Labeled label={t('table.cells.usage-type')} showColon className={styles.extraTopMargin}>
            <span>{tenant.usageType}</span>
          </Labeled>
        </div>
      )
    }}
  </AnalyticalTableCell>
)

export const RentalUnitsCell = (
  <AnalyticalTableCell>
    {({ rentalUnits, comparableStatus }, { formatNumber, t }) => (
      <>
        <PropertyRentRollComparisonDeviation
          label={t('table.cells.number')}
          comparableStatus={comparableStatus}
          primaryValue={rentalUnits.count.current}
          comparisonValue={rentalUnits.count.compare}
          formatter={formatNumber}
          unit=""
        />
      </>
    )}
  </AnalyticalTableCell>
)

export const AggregatedRentalUnitsCell = (
  <AnalyticalTableCell renderAggregateRegularly>
    {({ _ }, { formatNumber, t, accessors }, { cell }) => {
      const aggregatedCurrentValue = sum(cell, accessors.current)
      const aggregatedComparisonValue = sum(cell, accessors.compare)
      return (
        <>
          <PropertyRentRollComparisonDeviation
            label={t('table.cells.number')}
            comparableStatus={getGroupCellComparisonStatus(cell)}
            primaryValue={aggregatedCurrentValue}
            comparisonValue={aggregatedComparisonValue}
            formatter={formatNumber}
            unit=""
          />
        </>
      )
    }}
  </AnalyticalTableCell>
)

export const TotalAreaCell = (
  <AnalyticalTableCell>
    {({ totalArea, comparableStatus }, { t, formatNumber, formatArea, formatPercent }) => (
      <>
        <PropertyRentRollComparisonDeviation
          comparableStatus={comparableStatus}
          primaryValue={totalArea.surface.current?.value}
          comparisonValue={totalArea.surface.compare?.value}
          unit={formatArea(
            getCurrentOrComparisonValue(
              totalArea.surface.current?.measurement_unit,
              totalArea.surface.compare?.measurement_unit,
            ),
          )}
          formatter={formatNumber}
        />
        <PropertyRentRollComparisonDeviation
          label={t('table.cells.share')}
          comparableStatus={comparableStatus}
          primaryValue={totalArea.share.current}
          comparisonValue={totalArea.share.compare}
          deviationFactor={100}
          formatter={formatPercent}
          unit=""
        />
        <PropertyRentRollComparisonDeviation
          label={t('table.cells.number')}
          comparableStatus={comparableStatus}
          primaryValue={totalArea.number_of_units.current}
          comparisonValue={totalArea.number_of_units.compare}
          formatter={formatNumber}
          unit=""
        />
      </>
    )}
  </AnalyticalTableCell>
)

export const AggregatedTotalAreaCell = (
  <AnalyticalTableCell renderAggregateRegularly>
    {({ totalArea }, { t, formatNumber, formatArea, formatPercent, accessors }, { cell }) => {
      const groupCellComparisonStatus = getGroupCellComparisonStatus(cell)
      const aggregatedCurrentSurfaceValue = sum(cell, accessors.current.surface)
      const aggregatedCompareSurfaceValue = sum(cell, accessors.compare.surface)
      const aggregatedCurrentShareValue = sum(cell, accessors.current.share)
      const aggregatedCompareShareValue = sum(cell, accessors.compare.share)
      const aggregatedCurrentNumberOfUnitsValue = sum(cell, accessors.current.number)
      const aggregatedCompareNumberOfUnitsValue = sum(cell, accessors.compare.number)
      return (
        <>
          <PropertyRentRollComparisonDeviation
            comparableStatus={groupCellComparisonStatus}
            primaryValue={aggregatedCurrentSurfaceValue}
            comparisonValue={aggregatedCompareSurfaceValue}
            unit={formatArea(
              getCurrentOrComparisonValue(
                totalArea.surface.current?.measurement_unit,
                totalArea.surface.compare?.measurement_unit,
              ),
            )}
            formatter={formatNumber}
          />
          <PropertyRentRollComparisonDeviation
            label={t('table.cells.share')}
            comparableStatus={groupCellComparisonStatus}
            primaryValue={aggregatedCurrentShareValue}
            comparisonValue={aggregatedCompareShareValue}
            deviationFactor={100}
            formatter={formatPercent}
            unit=""
          />
          <PropertyRentRollComparisonDeviation
            label={t('table.cells.number')}
            comparableStatus={groupCellComparisonStatus}
            primaryValue={aggregatedCurrentNumberOfUnitsValue}
            comparisonValue={aggregatedCompareNumberOfUnitsValue}
            formatter={formatNumber}
            unit=""
          />
        </>
      )
    }}
  </AnalyticalTableCell>
)

export const AnnualizedCurrentRentCell = (
  <AnalyticalTableCell>
    {(
      { annualizedCurrentRent, totalArea, comparableStatus },
      { t, formatNumberCompact, formatPercent, formatArea },
    ) => (
      <>
        <PropertyRentRollComparisonDeviation
          comparableStatus={comparableStatus}
          primaryValue={annualizedCurrentRent.value.current?.number}
          comparisonValue={annualizedCurrentRent.value.compare?.number}
          unit={getCurrentOrComparisonValue(
            annualizedCurrentRent.value.current?.currency,
            annualizedCurrentRent.value.compare?.currency,
          )}
          formatter={formatNumberCompact}
        />
        <PropertyRentRollComparisonDeviation
          label={t('table.cells.share')}
          comparableStatus={comparableStatus}
          primaryValue={annualizedCurrentRent.share.current}
          comparisonValue={annualizedCurrentRent.share.compare}
          deviationFactor={100}
          formatter={formatPercent}
          unit=""
        />
        <PropertyRentRollComparisonDeviation
          label={`${t('table.cells.per-uom')} ${formatArea(
            getCurrentOrComparisonValue(
              totalArea.surface.current?.measurement_unit,
              totalArea.surface.compare?.measurement_unit,
            ),
          )}`}
          comparableStatus={comparableStatus}
          primaryValue={annualizedCurrentRent.per_uom.current?.number}
          comparisonValue={annualizedCurrentRent.per_uom.compare?.number}
          unit={getCurrentOrComparisonValue(
            annualizedCurrentRent.per_uom.current?.currency,
            annualizedCurrentRent.per_uom.compare?.currency,
          )}
          formatter={formatNumberCompact}
        />
        <PropertyRentRollComparisonDeviation
          label={`${t('table.cells.per-uom')} ${useAreaPiecesUnit()}`}
          comparableStatus={comparableStatus}
          primaryValue={annualizedCurrentRent.per_pieces.current?.number}
          comparisonValue={annualizedCurrentRent.per_pieces.compare?.number}
          unit={getCurrentOrComparisonValue(
            annualizedCurrentRent.per_pieces.current?.currency,
            annualizedCurrentRent.per_pieces.compare?.currency,
          )}
          formatter={formatNumberCompact}
        />
      </>
    )}
  </AnalyticalTableCell>
)

export const AggregatedAnnualizedCurrentRentCell = (
  <AnalyticalTableCell renderAggregateRegularly>
    {(
      { annualizedCurrentRent, totalArea },
      { t, formatNumberCompact, formatPercent, formatArea, accessors },
      { cell },
    ) => {
      const groupCellComparisonStatus = getGroupCellComparisonStatus(cell)
      const aggregatedCurrentTotalAreaNumberValue = sum(cell, accessors.current.totalArea)
      const aggregatedCompareTotalAreaNumberValue = sum(cell, accessors.compare.totalArea)
      const aggregatedCurrentTotalRentNumberValue = sum(cell, accessors.current.totalRent)
      const aggregatedCompareTotalRentNumberValue = sum(cell, accessors.compare.totalRent)
      const aggregatedCurrentRentPerUomNumberValue = weightedSum(
        cell,
        accessors.current.perUom,
        accessors.current.totalArea,
        aggregatedCurrentTotalAreaNumberValue,
      )
      const aggregatedCompareRentPerUomNumberValue = weightedSum(
        cell,
        accessors.compare.perUom,
        accessors.compare.totalArea,
        aggregatedCompareTotalAreaNumberValue,
      )
      const aggregatedCurrentShareValue = sum(cell, accessors.current.share)
      const aggregatedCompareShareValue = sum(cell, accessors.compare.share)
      return (
        <>
          <PropertyRentRollComparisonDeviation
            comparableStatus={groupCellComparisonStatus}
            primaryValue={aggregatedCurrentTotalRentNumberValue}
            comparisonValue={aggregatedCompareTotalRentNumberValue}
            unit={getCurrentOrComparisonValue(
              annualizedCurrentRent.value.current?.currency,
              annualizedCurrentRent.value.compare?.currency,
            )}
            formatter={formatNumberCompact}
          />
          <PropertyRentRollComparisonDeviation
            label={`${t('table.cells.per-uom')} ${formatArea(
              getCurrentOrComparisonValue(
                totalArea.surface.current?.measurement_unit,
                totalArea.surface.compare?.measurement_unit,
              ),
            )}`}
            comparableStatus={groupCellComparisonStatus}
            primaryValue={aggregatedCurrentRentPerUomNumberValue}
            comparisonValue={aggregatedCompareRentPerUomNumberValue}
            unit={getCurrentOrComparisonValue(
              annualizedCurrentRent.per_uom.current?.currency,
              annualizedCurrentRent.per_uom.compare?.currency,
            )}
            formatter={formatNumberCompact}
          />
          <PropertyRentRollComparisonDeviation
            label={t('table.cells.share')}
            comparableStatus={groupCellComparisonStatus}
            primaryValue={aggregatedCurrentShareValue}
            comparisonValue={aggregatedCompareShareValue}
            deviationFactor={100}
            formatter={formatPercent}
            unit=""
          />
        </>
      )
    }}
  </AnalyticalTableCell>
)

export const WaultCell = (
  <AnalyticalTableCell>
    {({ wault, comparableStatus }, { t, formatWault }) => (
      <>
        <PropertyRentRollComparisonDeviation
          comparableStatus={comparableStatus}
          primaryValue={wault.expiry.current}
          comparisonValue={wault.expiry.compare}
          unit={YEAR_UNIT}
          formatter={formatWault}
        />
        <PropertyRentRollComparisonDeviation
          label={t('table.cells.wault-break')}
          comparableStatus={comparableStatus}
          primaryValue={wault.break.current}
          comparisonValue={wault.break.compare}
          unit={YEAR_UNIT}
          formatter={formatWault}
        />
      </>
    )}
  </AnalyticalTableCell>
)

export const AggregatedWaultCell = (
  <AnalyticalTableCell renderAggregateRegularly>
    {({ _ }, { t, formatWault, accessors }, { cell }) => {
      const groupCellComparisonStatus = getGroupCellComparisonStatus(cell)
      const aggregatedCurrentTotalNumberValue = sum(cell, accessors.current.total)
      const aggregatedCompareTotalNumberValue = sum(cell, accessors.compare.total)
      const aggregatedCurrentWaultExpiryValue = weightedSum(
        cell,
        accessors.current.waultExpiry,
        accessors.current.total,
        aggregatedCurrentTotalNumberValue,
      )
      const aggregatedCompareWaultExpiryValue = weightedSum(
        cell,
        accessors.compare.waultExpiry,
        accessors.compare.total,
        aggregatedCompareTotalNumberValue,
      )
      const aggregatedCurrentWaultBreakValue = weightedSum(
        cell,
        accessors.current.waultBreak,
        accessors.current.total,
        aggregatedCurrentTotalNumberValue,
      )
      const aggregatedCompareWaultBreakValue = weightedSum(
        cell,
        accessors.compare.waultBreak,
        accessors.compare.total,
        aggregatedCompareTotalNumberValue,
      )

      return (
        <>
          <PropertyRentRollComparisonDeviation
            comparableStatus={groupCellComparisonStatus}
            primaryValue={aggregatedCurrentWaultExpiryValue}
            comparisonValue={aggregatedCompareWaultExpiryValue}
            unit={YEAR_UNIT}
            formatter={formatWault}
          />
          <PropertyRentRollComparisonDeviation
            label={t('table.cells.wault-break')}
            comparableStatus={groupCellComparisonStatus}
            primaryValue={aggregatedCurrentWaultBreakValue}
            comparisonValue={aggregatedCompareWaultBreakValue}
            unit={YEAR_UNIT}
            formatter={formatWault}
          />
        </>
      )
    }}
  </AnalyticalTableCell>
)
