import _ from 'lodash'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useRoutes } from 'react-router-dom'
import PropertyDocumentsDynamic from 'components/domains/properties/documents/PropertyDocumentsDynamic'
import PropertyGeneralInformationDynamic from 'components/domains/properties/general-information/PropertyGeneralInformationDynamic'
import PropertyOpenInCmsButton from 'components/domains/properties/general-information/cms/PropertyOpenInCmsButton'
import CreatePropertyEventAction from 'components/domains/properties/header-actions/CreatePropertyEventAction'
import PropertyInsurancesDynamic from 'components/domains/properties/insurances/PropertyInsurancesDynamic'
import PropertyOverviewDynamic from 'components/domains/properties/overview/PropertyOverviewDynamic'
import PropertyRentRollHeaderActions from 'components/domains/properties/rent-roll/PropertyRentRollHeaderActions'
import PropertyRentRollDynamic from 'components/domains/properties/rent-roll/dynamic/PropertyRentRollDynamic'
import PropertyRentRollWorkingVersion from 'components/domains/properties/rent-roll/working-version/PropertyRentRollWorkingVersion'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import Header from 'components/ui/page/Header'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import PropertyKpiDetailsDynamic from 'routes/kpis/PropertyKpiDetailsDynamic'
import PropertyKpiOverviewDynamic from 'routes/kpis/PropertyKpiOverviewDynamic'
import { PropertyContext } from 'routes/properties/PropertyContext'
import PropertyPage from 'routes/properties/PropertyPage'
import PropertyValuationsWrapperDynamic from 'routes/properties/PropertyValuationsWrapperDynamic'
import determinePropertyValueStateBasedOnStatus from 'routes/properties/determinePropertyValueState'
import PropertyMonitoringDynamic from 'routes/properties/monitoring/PropertyMonitoringDynamic'
import { usePropertyDynamicCards } from 'routes/properties/usePropertyDynamicCards'
import { usePropertyStatus } from 'routes/properties/usePropertyStatus'

const PropertyDetails = () => {
  const { property } = useContext(PropertyContext)
  const { t } = useTranslation()

  // register dynamic cards for property domain
  usePropertyDynamicCards()

  const { t: tPropertyOverview } = useTranslation('translation', {
    keyPrefix: 'pages.property-overview',
  })
  //the status in translation files are used to translate the system status that we received from backend to English.
  const { t: tHeaderStatus } = useTranslation('translation', {
    keyPrefix: 'pages.properties.header.status',
  })
  const { t: tHeaderStatusText } = useTranslation('translation', {
    keyPrefix: 'pages.properties.header.status.text',
  })

  const { objectStatusForFinancingStatus, objectStatusForCAGStatus } = usePropertyStatus()

  const propertyOverviewBreadcrumbs = [
    {
      text: t('pages.properties.title'),
      href: '/properties',
    },
    { text: property.description },
  ]

  const subtitle = <EntityTypeAndIdWithClipboard id={property.id} />

  const generateBreadcrumb = (text, href = undefined) => ({
    text: text,
    href: href,
  })

  const generateBreadcrumbs = (additionalBreadcrumbs = []) => {
    if (!property?.id) return []

    const propertiesLink = '/properties'
    const propertyLink = `${propertiesLink}/${property.id}`
    return [
      { text: t('pages.properties.title'), href: propertiesLink },
      { text: property.description, href: propertyLink },
      ...additionalBreadcrumbs.map((breadcrumb) =>
        breadcrumb.href ? { ...breadcrumb, href: `${propertyLink}${breadcrumb.href}` } : breadcrumb,
      ),
    ]
  }

  const checkPropertyStatus =
    !_.isEmpty(property?.system_status) && Array.isArray(property?.system_status)

  const systemStatusInLocalTranslation = checkPropertyStatus
    ? property.system_status[0].toLowerCase().replace(' ', '-')
    : ''
  const systemStatusInStandardTranslation = tHeaderStatus(systemStatusInLocalTranslation)
  const statusTranslationText = tHeaderStatusText(systemStatusInStandardTranslation?.toLowerCase())
  const propertyStatus = {
    label: `${tHeaderStatus('label.system-status')}:`,
    text: checkPropertyStatus ? statusTranslationText : '',
    valueState: checkPropertyStatus
      ? determinePropertyValueStateBasedOnStatus(systemStatusInStandardTranslation)
      : '',
  }

  const propertyAdditionalStatuses = [
    {
      label: `${tHeaderStatus('label.financing-status')}:`,
      ...objectStatusForFinancingStatus[property.financing_status_code],
    },
    {
      label: `${tHeaderStatus('label.cag-status')}:`,
      ...objectStatusForCAGStatus[property.cag_status_code],
    },
  ].filter(({ text }) => !!text)

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="property-details-user-favorite-icon"
        entityId={property.uuid}
        entityType={cwpEntityTypes.PROPERTY}
      />
    ),
    [property.uuid],
  )

  const createPropertyEventAction = useMemo(
    () => <CreatePropertyEventAction key="create-event-action" />,
    [],
  )

  const propertyOverviewHeaderActions = [createPropertyEventAction, markFavoriteAction]
  const propertyGeneralInformationHeaderActions = [
    createPropertyEventAction,
    <PropertyOpenInCmsButton propertyUuid={property.uuid} key={'open-in-cms-button'} />,
    markFavoriteAction,
  ]

  return useRoutes([
    {
      path: '',
      element: (
        <PropertyOverviewDynamic
          header={
            <Header
              title={property.description}
              subtitle={subtitle}
              id={property.id}
              breadcrumbs={propertyOverviewBreadcrumbs}
              status={propertyStatus}
              additionalStatuses={propertyAdditionalStatuses}
              actions={propertyOverviewHeaderActions}
            />
          }
        />
      ),
    },
    {
      path: 'general-information',
      element: (
        <PropertyGeneralInformationDynamic
          header={
            <Header
              title={property.description}
              id={property.id}
              subtitle={subtitle}
              breadcrumbs={generateBreadcrumbs([
                generateBreadcrumb(tPropertyOverview('general-information')),
              ])}
              status={propertyStatus}
              additionalStatuses={propertyAdditionalStatuses}
              actions={propertyGeneralInformationHeaderActions}
            />
          }
        />
      ),
    },
    /*     {
      path: 'collaterals',
      element: (
        <PropertyPage
          pageTitle={t('collaterals')}
          breadcrumbs={generateBreadcrumbs(t('collaterals'))}
        >
          <PropertyTile id="property-collaterals-tile" />
        </PropertyPage>
      ),
    }, */
    {
      path: 'valuation',
      element: (
        <PropertyValuationsWrapperDynamic
          status={propertyStatus}
          additionalStatuses={propertyAdditionalStatuses}
          breadcrumbs={generateBreadcrumbs([generateBreadcrumb(tPropertyOverview('valuation'))])}
        />
      ),
    },
    {
      path: 'monitoring',
      element: (
        <PropertyMonitoringDynamic
          propertyDescription={property.description}
          propertyId={property.id}
          propertyUuid={property.uuid}
          breadcrumbs={generateBreadcrumbs([generateBreadcrumb(tPropertyOverview('monitoring'))])}
          status={propertyStatus}
          additionalStatuses={propertyAdditionalStatuses}
        />
      ),
    },
    {
      path: 'rent-roll',
      element: (
        <PropertyRentRollDynamic
          header={
            <Header
              title={property.description}
              id={property.id}
              subtitle={subtitle}
              breadcrumbs={generateBreadcrumbs([
                generateBreadcrumb(tPropertyOverview('rent-roll')),
              ])}
              status={propertyStatus}
              additionalStatuses={propertyAdditionalStatuses}
              actions={[<PropertyRentRollHeaderActions key="2" property={property} />]}
            />
          }
        />
      ),
    },
    {
      path: 'rent-roll/working-version',
      element: (
        <PropertyPage
          pageTitle={property?.description}
          id={property?.id}
          subtitle={property !== undefined && subtitle}
          breadcrumbs={generateBreadcrumbs([
            generateBreadcrumb(tPropertyOverview('rent-roll'), '/rent-roll'),
            generateBreadcrumb(tPropertyOverview('rent-roll.working-version')),
          ])}
          status={propertyStatus}
          additionalStatuses={propertyAdditionalStatuses}
        >
          <PropertyRentRollWorkingVersion propertyUuids={[property.uuid]} />
        </PropertyPage>
      ),
    },
    {
      path: 'insurances',
      element: (
        <PropertyInsurancesDynamic
          propertyDescription={property.description}
          breadcrumbs={generateBreadcrumbs([generateBreadcrumb(tPropertyOverview('insurances'))])}
          status={propertyStatus}
          additionalStatuses={propertyAdditionalStatuses}
        />
      ),
    },
    {
      path: 'kpis',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: (
            <PropertyKpiOverviewDynamic
              propertyDescription={property.description}
              breadcrumbs={generateBreadcrumbs([generateBreadcrumb(tPropertyOverview('kpis'))])}
              status={propertyStatus}
              additionalStatuses={propertyAdditionalStatuses}
            />
          ),
        },
        {
          path: ':kpiId/*',
          element: (
            <PropertyKpiDetailsDynamic
              propertyDescription={property.description}
              breadcrumbs={generateBreadcrumbs([
                generateBreadcrumb(tPropertyOverview('kpis'), '/kpis'),
              ])}
              status={propertyStatus}
              additionalStatuses={propertyAdditionalStatuses}
            />
          ),
        },
      ],
    },
    {
      path: 'documents',
      element: (
        <PropertyDocumentsDynamic
          id={property?.id}
          propertyDescription={property.description}
          breadcrumbs={generateBreadcrumbs([generateBreadcrumb(tPropertyOverview('documents'))])}
          status={propertyStatus}
          additionalStatuses={propertyAdditionalStatuses}
        />
      ),
    },
  ])
}

export default PropertyDetails
