import {
  FlexBox,
  Select,
  Option,
  FlexBoxDirection,
  Label,
  IllustratedMessage,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/OpsMemoTrancheDetails.module.css'
import OpsMemoTrancheAccountingCard from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/OpsMemoTrancheAccountingCard'
import OpsMemoTrancheAdditionalInterestInformationCard from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/OpsMemoTrancheAdditionalInterestInformationCard'
import OpsMemoTrancheAmortizationConditionsCard from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/OpsMemoTrancheAmortizationConditionsCard'
import OpsMemoTrancheBorrowerDetailsCard from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/OpsMemoTrancheBorrowerDetailsCard'
import OpsMemoTrancheEmbeddedOptionsCard from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/OpsMemoTrancheEmbeddedOptionsCard'
import OpsMemoTrancheFeesCard from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/OpsMemoTrancheFeesCard'
import OpsMemoTrancheGeneralInformationCard from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/OpsMemoTrancheGeneralInformationCard'
import OpsMemoTrancheInterestConditionsCard from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/OpsMemoTrancheInterestConditionsCard'
import OpsMemoTranchePricingCard from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/OpsMemoTranchePricingCard'

const OpsMemoTrancheDetails = ({ tileId, isPdfView }) => {
  const tileData = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.ops-memo-tranche-details',
  })

  const deal = tileData?.data?.dealData
  const tranches = tileData?.data?.tranchesData?.tranches
  const creditDecisionApprovedDate = tileData?.data?.creditDecisionApprovedDate
  const tranchesEmbeddedOptions =
    // failsafe to prevent the page from crashing if the embeddedOptions are not stringified, which is the case for
    // older frozen decision papers
    typeof tileData?.data?.tranchesEmbeddedOptionsData === 'string'
      ? JSON.parse(tileData?.data?.tranchesEmbeddedOptionsData ?? [])
      : []
  const businessPartners = tileData?.data?.businessPartnersData
  const initialTranche = tranches?.[0]
  const memberRoleTypeCodes = tileData?.data?.memberRoleTypeCodesData

  const [selectedTranche, setSelectedTranche] = useState(initialTranche)

  const onTrancheSelectionChange = (trancheId) => {
    const selectedItem = tranches.find((tranche) => tranche?.trancheId === trancheId)
    setSelectedTranche(() => selectedItem)
  }

  const renderTranchesDropdown = () => (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.tranchesDropdownWrapper}>
      <Label showColon>{t('dropdown-label')}</Label>
      <Select
        id="ops-memo-tranche-details-tranche-dropdown"
        className={styles.tranchesDropdown}
        onChange={(e) => onTrancheSelectionChange(e.detail.selectedOption.dataset.value)}
      >
        {tranches?.map((option) => (
          <Option
            key={option?.trancheId}
            selected={option?.trancheId === selectedTranche?.trancheId}
            data-value={option?.trancheId}
            data-testid={option?.trancheId}
          >
            {`${option?.trancheName}`}
          </Option>
        ))}
      </Select>
    </FlexBox>
  )

  const renderCards = (item) => {
    const embeddedOptions = tranchesEmbeddedOptions?.find(
      (option) => option?.trancheId === item?.trancheId,
    )
    return (
      <>
        <OpsMemoTrancheGeneralInformationCard
          deal={deal}
          tranche={item}
          creditDecisionApprovedDate={creditDecisionApprovedDate}
        />
        <OpsMemoTrancheBorrowerDetailsCard
          deal={deal}
          tranche={item}
          businessPartners={businessPartners}
        />
        <OpsMemoTrancheAccountingCard tranche={item} />
        <OpsMemoTrancheInterestConditionsCard tranche={item} />
        <OpsMemoTrancheAdditionalInterestInformationCard tranche={item} />
        <OpsMemoTrancheAmortizationConditionsCard
          tranche={item}
          memberRoleTypeCodes={memberRoleTypeCodes}
        />
        <OpsMemoTranchePricingCard tranche={item} />
        <OpsMemoTrancheFeesCard tranche={item} memberRoleTypeCodes={memberRoleTypeCodes} />
        <OpsMemoTrancheEmbeddedOptionsCard embeddedOptions={embeddedOptions?.options} />
      </>
    )
  }

  const renderNoDataView = () => (
    <IllustratedMessage
      name="NoData"
      titleText={t('no-data-title')}
      subtitleText={t('no-data-text')}
    />
  )

  const renderView = (item) => (
    <FlexBox
      key={item?.trancheId}
      direction={FlexBoxDirection.Column}
      className={styles.trancheDetailsWrapper}
    >
      {renderTranchesDropdown()}
      {renderCards(item)}
    </FlexBox>
  )

  const renderPrintView = (item) => (
    <FlexBox
      key={item?.trancheId}
      direction={FlexBoxDirection.Column}
      className={styles.trancheDetailsWrapperPdf}
    >
      {renderCards(item)}
    </FlexBox>
  )

  if (!tranches?.length) return renderNoDataView()

  return isPdfView
    ? tranches?.map((tranche) => renderPrintView(tranche))
    : renderView(selectedTranche)
}

OpsMemoTrancheDetails.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool,
}

export default OpsMemoTrancheDetails
