import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import defaultCellStyles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/cells/DefaultCellStyles.module.css'
import { useNumberFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'

const PercentageCell = ({ value, isBold = false, errorText }) => {
  const formatPercentage = useNumberFormatter({
    maximumFractionDigits: 3,
    minimumFractionDigits: 3,
    style: 'percent',
  })

  if (errorText) {
    return <div className={defaultCellStyles.warningText}>{errorText}</div>
  }

  if (!Array.isArray(value)) {
    value = [value]
  }

  return (
    <div className={defaultCellStyles.columnFlexBox}>
      {value.map((item, index) => (
        <div key={index} className={isBold ? defaultCellStyles.boldFont : ''}>
          {!isNil(item) ? formatPercentage(item) : <>&nbsp;</>}
        </div>
      ))}
    </div>
  )
}

PercentageCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  isBold: PropTypes.bool,
  errorText: PropTypes.string,
}

export default PercentageCell
