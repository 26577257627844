import { useContext } from 'react'
import { businessEventsAllowedOperations } from 'api/events/eventAllowedOperations'
import { entityTypes } from 'api/events/status'
import CreateEventAction from 'components/domains/business-events-and-tasks/events/dialog/CreateEventAction'
import useEventsAllowedOperations from 'hooks/services/business-events-and-tasks/events/useEventsAllowedOperations'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

/**
 * Secondary button to create Business Partner events from the header of Business Partner pages.
 */
const CreateBusinessPartnerEventAction = () => {
  const { id, fullName } = useContext(BusinessPartnerContext)
  const { data: allowedEventsOperationsData } = useEventsAllowedOperations()
  const isAllowedToCreateEvents = !!allowedEventsOperationsData?.allowed_operations.includes(
    businessEventsAllowedOperations.createEvent,
  )

  if (!id) return undefined
  if (!isAllowedToCreateEvents) return undefined

  return (
    <CreateEventAction
      entity={{
        entityType: entityTypes.businesspartner,
        entityDisplayId: id,
        entityName: fullName,
        entityId: id,
        isWorkingVersion: false,
      }}
    />
  )
}

export default CreateBusinessPartnerEventAction
