import { useTranslation } from 'react-i18next'
import PropertyRentRollOverviewVacancyOverTimeCardContent from 'components/domains/properties/rent-roll/overview/vacancy-over-time/PropertyRentRollOverviewVacancyOverTimeCardContent'
import Card from 'components/ui/card/Card'
import CardHeaderWithMetrics from 'components/ui/card/CardHeaderWithMetrics'
import {
  useNumberFormatter,
  usePercentageFormatter,
  useAreaMeasurementUnitFormatter,
} from 'hooks/i18n/useI18n'
import useCurrentRentRollOverviewPropertyKpis from 'hooks/services/properties/kpis/useCurrentRentRollOverviewPropertyKpis'

const PropertyRentRollOverviewVacancyOverTimeCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.vacancy-over-time',
  })

  const {
    isLoading: isLoadingPropertyKpis,
    isError: isErrorPropertyKpis,
    data: propertyKpiData,
  } = useCurrentRentRollOverviewPropertyKpis()

  const formatArea = useAreaMeasurementUnitFormatter()
  const formatPercentage = usePercentageFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const formatNumber = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: 'compact',
  })

  const setValueOrUndefined = (value) => (value ? value : undefined)

  const primaryMetric = {
    label: t('vacancy-rate-area'),
    value: propertyKpiData?.vacancySurface?.percent
      ? formatNumber(propertyKpiData.vacancySurface.percent * 100)
      : undefined,
    unit: '%',
  }

  const areaValue = (value, unit) => formatNumber(value) + ' ' + formatArea(unit)
  const vacantAreaValue = propertyKpiData?.vacancySurface?.value
    ? areaValue(
        propertyKpiData.vacancySurface.value,
        propertyKpiData?.vacancySurface?.measurementUnit,
      )
    : undefined
  const vacancyRate = propertyKpiData?.totalNumberOfUnits
    ? formatPercentage(propertyKpiData?.vacancyNumberOfUnits / propertyKpiData?.totalNumberOfUnits)
    : undefined

  const secondaryMetrics = [
    { label: t('vacant-area'), value: vacantAreaValue },
    { label: t('vacancy-rate-number'), value: vacancyRate },
    {
      label: t('vacant-number'),
      value: setValueOrUndefined(propertyKpiData?.vacancyNumberOfUnits),
    },
  ]

  return (
    <Card
      header={
        <CardHeaderWithMetrics
          title={t('title')}
          primaryMetric={primaryMetric}
          secondaryMetrics={secondaryMetrics}
          referenceDate={propertyKpiData?.keyDate}
          isLoading={isLoadingPropertyKpis}
          isError={isErrorPropertyKpis}
        />
      }
    >
      <PropertyRentRollOverviewVacancyOverTimeCardContent />
    </Card>
  )
}

export default PropertyRentRollOverviewVacancyOverTimeCard
