import { BusyIndicator, Option, Select, ValueState } from '@fioneer/ui5-webcomponents-react'
import get from 'lodash.get'
import PropTypes from 'prop-types'
import { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/select/LoadingSelect.css'

const LoadingSelect = forwardRef(
  (
    {
      id,
      className,
      onChange,
      loadingHook,
      loadingHookParams,
      selectionName,
      optionKeyName,
      optionDisplayName,
      emptyOptionText = '',
      showEmptyOption = true,
      isErrorState,
      selectedKey,
      errorText,
      formatDisplayName,
      ...selectOptions
    },
    ref,
  ) => {
    const { t } = useTranslation('decisionPaper')

    const {
      isLoading,
      isError,
      data = { key_dates: [] },
    } = loadingHookParams ? loadingHook(loadingHookParams) : loadingHook()

    const selections = useMemo(() => {
      if (isLoading || isError || !data) {
        return []
      }
      if (selectionName) {
        return get(data, selectionName, [])
      }
      return data
    }, [isError, isLoading, data, selectionName])

    const key = optionKeyName ? optionKeyName : 'key'
    const displayName = optionDisplayName ? optionDisplayName : 'display_name'

    const isSelected = (keyToCheck) => {
      if (selectedKey) {
        return selectedKey === keyToCheck
      } else {
        return keyToCheck
      }
    }

    const getKey = (type) => {
      if (type && typeof type === 'object' && key in type) {
        return type[key]
      } else {
        return type
      }
    }

    const getDisplayName = (type) => {
      if (type && typeof type === 'object' && displayName in type) {
        return formatDisplayName ? formatDisplayName(type[displayName]) : type[displayName]
      } else {
        return formatDisplayName ? formatDisplayName(type) : type
      }
    }

    if (isLoading) {
      return <BusyIndicator active={true} className="loading-select-text" />
    }
    if (isError) {
      return (
        <div className="loading-select-error-text">
          <div>{errorText ? errorText : t('select.loading.error')}</div>
        </div>
      )
    }
    return (
      <Select
        id={id || selectionName + '-select'}
        onChange={(e) => onChange(e, e?.detail?.selectedOption?.dataset?.id)}
        className={className}
        valueState={isErrorState ? ValueState.Error : ValueState.None}
        value={selectedKey}
        ref={ref}
        {...selectOptions}
      >
        {showEmptyOption && (
          <Option key={''} data-id={''} selected={isSelected('')}>
            {emptyOptionText}
          </Option>
        )}
        {selections.map((type) => (
          <Option
            value={getKey(type)}
            key={getKey(type)}
            data-id={getKey(type)}
            selected={isSelected(getKey(type))}
          >
            {getDisplayName(type)}
          </Option>
        ))}
      </Select>
    )
  },
)

LoadingSelect.displayName = 'LoadingSelect'
LoadingSelect.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  selectedKey: PropTypes.string,
  loadingHook: PropTypes.func.isRequired,
  loadingHookParams: PropTypes.any,
  selectionName: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  optionKeyName: PropTypes.string,
  optionDisplayName: PropTypes.string,
  emptyOptionText: PropTypes.string,
  showEmptyOption: PropTypes.bool,
  isErrorState: PropTypes.bool,
  errorText: PropTypes.string,
  formatDisplayName: PropTypes.func,
}
export default LoadingSelect
