import compact from 'lodash.compact'
import { useMemo } from 'react'
import useMultipleRegulatoryInformation from 'components/domains/business-partners/tile/general-information/regulatoryInformation/useMultipleRegulatoryInformation'
import useMultipleArrearApprovalsByBusinessPartnerId from 'hooks/services/arrears/useMultipleArrearApprovalsByBusinessPartnerId'
import mapAnnualReviewAutomaticCheckSheetData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/appendix/automatic-check-sheet/mappers/mapAnnualReviewAutomaticCheckSheetData'
import extractDealIdTrancheIdPairs from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/shared/automatic-checksheet/mappers/extractDealIdTrancheIdPairs'
import extractDealIdsAndUuids from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/shared/automatic-checksheet/mappers/extractDealIdsAndUuids'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useAuthorityLevelCalculatorAuthorityLevel from 'hooks/services/business-partners/authority-level-calculator/useAuthorityLevelCalculatorAuthorityLevel'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import useBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationships'
import useMultipleTranchesByBusinessPartner from 'hooks/services/business-partners/tranches/useMultipleTranchesByBusinessPartner'
import useMultipleDealCollateralInformation from 'hooks/services/deals/collateral/useMultipleDealCollateralInformation'
import useMultipleCustomerSpecificAttributes from 'hooks/services/deals/financing/customer-specific-attributes/useMultipleCustomerSpecificAttributes'
import useMultipleDealsByBorrowerId from 'hooks/services/deals/useMultipleDealsByBorrowerId'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const JOINT_ACCOUNT_CODE = 'YJR080'

const useAnnualReviewAutomaticCheckSheet = (
  { id: eventId, entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: businessPartnerRelationships,
    isLoading: isBusinessPartnerRelationshipsLoading,
    isError: isBusinessPartnerRelationshipsError,
    error: businessPartnerRelationshipsError,
  } = useBusinessPartnerRelationships(businessPartnerId)

  const {
    data: businessPartner,
    isLoading: isBusinessPartnerLoading,
    isError: isBusinessPartnerError,
    error: businessPartnerError,
  } = useBusinessPartnerById(businessPartnerId)

  const businessPartnerName = businessPartner?.fullName

  const gktoUnitRelationship = businessPartnerRelationships?.unitRelationships.find(
    (unitRelationship) => unitRelationship.id === JOINT_ACCOUNT_CODE,
  )
  const gktoRelationships = gktoUnitRelationship?.relationships.find(
    (relationship) => relationship.head.id === businessPartnerId,
  )

  // gktoMembers are borrowers
  const borrowersData = compact(
    [{ borrowerId: businessPartnerId, borrowerName: businessPartnerName }].concat(
      gktoRelationships?.members.map((member) => ({
        borrowerId: member.id,
        borrowerName: member.fullName,
      })),
    ),
  )

  const borrowerIds = borrowersData.map((borrower) => borrower.borrowerId)

  const {
    isLoading: isMultipleRegulatoryInformationLoading,
    // commented out because for now that endpoint returns a 404
    // even if the request was successful and there simply is no data
    // isError: isMultipleRegulatoryInformationError,
    data: multipleRegulatoryInformationData,
  } = useCombinedQueryResults(useMultipleRegulatoryInformation({ businessPartnerIds: borrowerIds }))

  const {
    isLoading: isMultipleTranchesByBusinessPartnerLoading,
    isError: isMultipleTranchesByBusinessPartnerError,
    data: multipleTranchesByBusinessPartnerData,
  } = useCombinedQueryResults(
    useMultipleTranchesByBusinessPartner({ businessPartnerIds: borrowerIds }),
  )

  const { dealIdTrancheIdPairsByBusinessPartner, allDealIdTrancheIdPairs } =
    extractDealIdTrancheIdPairs({ multipleTranchesByBusinessPartnerData })

  const {
    data: multipleCustomerSpecificAttributes,
    isLoading: isMultipleCustomerSpecificAttributesLoading,
    error: isMultipleCustomerSpecificAttributesError,
  } = useCombinedQueryResults(
    useMultipleCustomerSpecificAttributes({
      dealIdTrancheIdPairs: allDealIdTrancheIdPairs,
    }),
    { forceDataReturn: true },
  )

  const deadDealRank = 999

  const {
    isLoading: isMultipleDealsWithTranchesAndDrawdownsByBorrowerLoading,
    isError: isMultipleDealsWithTranchesAndDrawdownsByBorrowerError,
    data: multipleDealsWithTranchesAndDrawdownsByBorrowerData,
  } = useMultipleDealsByBorrowerId({
    borrowerIds,
    filter: { dealRankFrom: 100, dealRankTo: deadDealRank - 1 },
  })

  const { dealIdsAndUuidsByBorrowerId, allDealUuids } = extractDealIdsAndUuids({
    multipleDealsWithTranchesAndDrawdownsByBorrowerData,
    borrowerIds,
  })

  const {
    data: multipleDealCollateralInformationData,
    isLoading: isMultipleDealCollateralInformationLoading,
    error: isMultipleDealCollateralInformationError,
  } = useCombinedQueryResults(useMultipleDealCollateralInformation({ dealUuids: allDealUuids }))

  const {
    data: multipleArrearApprovalsData,
    isLoading: isMultipleArrearApprovalsLoading,
    isError: isMultipleArrearApprovalsError,
  } = useMultipleArrearApprovalsByBusinessPartnerId({ businessPartnerIds: borrowerIds })

  const {
    data: authorityLevelData,
    isLoading: isAuthorityLevelLoading,
    isError: isAuthorityLevelError,
  } = useAuthorityLevelCalculatorAuthorityLevel({ eventId })

  const borrowers = useMemo(
    () =>
      isBusinessPartnerRelationshipsLoading &&
      isBusinessPartnerLoading &&
      isMultipleRegulatoryInformationLoading &&
      isMultipleArrearApprovalsLoading &&
      isAuthorityLevelLoading &&
      isMultipleDealsWithTranchesAndDrawdownsByBorrowerLoading &&
      isMultipleDealCollateralInformationLoading
        ? []
        : mapAnnualReviewAutomaticCheckSheetData({
            borrowersData,
            multipleRegulatoryInformationData,
            multipleArrearApprovalsData,
            authorityLevelData,
            dealIdsAndUuidsByBorrowerId,
            multipleDealCollateralInformationData,
            multipleCustomerSpecificAttributes,
            dealIdTrancheIdPairsByBusinessPartner,
          }),
    [
      authorityLevelData,
      borrowersData,
      dealIdTrancheIdPairsByBusinessPartner,
      dealIdsAndUuidsByBorrowerId,
      isAuthorityLevelLoading,
      isBusinessPartnerLoading,
      isBusinessPartnerRelationshipsLoading,
      isMultipleArrearApprovalsLoading,
      isMultipleDealCollateralInformationLoading,
      isMultipleDealsWithTranchesAndDrawdownsByBorrowerLoading,
      isMultipleRegulatoryInformationLoading,
      multipleArrearApprovalsData,
      multipleCustomerSpecificAttributes,
      multipleDealCollateralInformationData,
      multipleRegulatoryInformationData,
    ],
  )

  const sourceRender = useMemo(() => ({ businessPartnerId }), [businessPartnerId])

  const { isSomeValueError, isSomeValueLoading, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isBusinessPartnerRelationshipsLoading,
      isBusinessPartnerLoading,
      isMultipleRegulatoryInformationLoading,
      isMultipleTranchesByBusinessPartnerLoading,
      isMultipleCustomerSpecificAttributesLoading,
      isMultipleDealsWithTranchesAndDrawdownsByBorrowerLoading,
      isMultipleDealCollateralInformationLoading,
      isMultipleArrearApprovalsLoading,
      isAuthorityLevelLoading,
    ],
    errorValues: [
      isBusinessPartnerRelationshipsError,
      isBusinessPartnerError,
      // commented out because for now that endpoint returns a 404
      // even if the request was successful and there simply is no data
      // isMultipleRegulatoryInformationError,
      isMultipleArrearApprovalsError,
      isMultipleTranchesByBusinessPartnerError,
      isMultipleDealsWithTranchesAndDrawdownsByBorrowerError,
      isMultipleDealCollateralInformationError,
      isMultipleCustomerSpecificAttributesError,
      isAuthorityLevelError,
    ],
    errorDetails: [businessPartnerRelationshipsError, businessPartnerError],
    tileId,
  })

  const result = useMemo(() => {
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    // When there is no metadata, this thing will stay disabled and therefore loading --> check after the other finished loading
    // whether now the selected conditions are loading.
    if (isBusinessPartnerRelationshipsLoading || isBusinessPartnerLoading) {
      return {
        isLoading: true,
        isError: false,
      }
    }

    // Everything has loaded --> return the GKTO relationships
    return {
      isLoading: false,
      isError: false,
      data: {
        borrowers: JSON.stringify(borrowers),
        sourceRender,
        businessPartnerId,
      },
    }
  }, [
    isBusinessPartnerRelationshipsLoading,
    isBusinessPartnerLoading,
    isSomeValueLoading,
    isSomeValueError,
    borrowers,
    sourceRender,
    businessPartnerId,
    error,
  ])

  return result
}

export default useAnnualReviewAutomaticCheckSheet
