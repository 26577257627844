import { useMemo } from 'react'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'

const useCwpEntityToMainEntity = () => {
  /**
   * @enum
   */
  const cwpEntityToMainEntity = useMemo(
    () => ({
      [cwpEntityTypes.BUSINESS_PARTNER]: mainEntityTypes.BUSINESSPARTNER,
      [cwpEntityTypes.DEAL]: mainEntityTypes.DEAL,
    }),
    [],
  )

  return { cwpEntityToMainEntity }
}

export default useCwpEntityToMainEntity
