import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import WaiverImpactClassificationTileContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/waiver/WaiverImpactClassificationTileContent'
import WaiverImpactClassificationTileContentEdit from 'components/domains/business-events-and-tasks/decision-paper/tiles/waiver/WaiverImpactClassificationTileContentEdit'

const WaiverImpactClassificationTile = ({
  tileId,
  currentContent,
  onChange,
  isEditMode,
  setHasContentChanges,
}) => {
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const initialContent = useMemo(
    () => ({
      classification: '',
      reason: '',
    }),
    [],
  )

  const content = useMemo(() => currentContent ?? initialContent, [currentContent, initialContent])

  useEffect(() => {
    const isEmptyCurrentContent = isNil(currentContent) || isEqual(currentContent, initialContent)

    const hasContentChanges =
      !(isEmptyCurrentContent && isNil(tileStatus?.data?.data)) &&
      !isEqual(tileStatus?.data?.data, currentContent)
    setHasContentChanges(hasContentChanges)
  }, [currentContent, initialContent, setHasContentChanges, tileStatus?.data?.data])

  return isEditMode ? (
    <WaiverImpactClassificationTileContentEdit onChange={onChange} content={content} />
  ) : (
    <WaiverImpactClassificationTileContent content={content} />
  )
}

WaiverImpactClassificationTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  currentContent: PropTypes.shape({
    classification: PropTypes.string,
    reason: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
}

export default WaiverImpactClassificationTile
