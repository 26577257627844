import { Icon } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'
import { MessageBoxTypes } from 'components/ui/message-box/MessageBox'

const propTypes = {
  methodId: PropTypes.string.isRequired,
  validTo: PropTypes.string.isRequired,
  className: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const DeleteRatingButton = ({ methodId, validTo, className, onDelete }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner-ratings' })
  const { t: translationWithoutPrefix } = useTranslation()
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)

  const handleClick = () => {
    setIsConfirmationDialogOpen(true)
  }

  const handleConfirmation = () => {
    setIsConfirmationDialogOpen(false)
    onDelete({ methodId, validTo })
  }

  const handleCancel = () => {
    setIsConfirmationDialogOpen(false)
  }

  return (
    <>
      <Icon
        className={className ?? ''}
        name="delete"
        data-testid="delete-rating"
        interactive
        onClick={handleClick}
      />
      <ConfirmationDialog
        confirmButtonText={translationWithoutPrefix('buttons.ok')}
        confirmationMessage={t('delete.text')}
        confirmationTitle={t('delete.title')}
        handleCancel={handleCancel}
        handleConfirmation={handleConfirmation}
        isOpen={isConfirmationDialogOpen}
        messageBoxType={MessageBoxTypes.Information}
      />
    </>
  )
}

DeleteRatingButton.propTypes = propTypes

export default DeleteRatingButton
