import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { getGeoLocationOfCompleteAddress } from 'components/domains/util/AddressUtil'
import AddressAutocompleteInput from 'components/ui/input/AddressAutocompleteInput'
import { useCountryCodes } from 'hooks/services/properties/useCountryCodes'

const formatAddress = ({ streetName, houseId, postalCode, cityName, countryName }) => {
  const line1 = [streetName, houseId].filter((it) => it?.length > 0).join(' ')
  const line2 = [postalCode, cityName].filter((it) => it?.length > 0).join(' ')
  return [line1, line2, countryName].filter((it) => it?.length > 0).join(', ')
}

const DealAddressAutocompleteInput = ({ basePath, onAddressSelectedCustomHandler, ...props }) => {
  const { getValues, setValue, trigger } = useFormContext()
  const value = getValues(basePath)
  const [typedValue, setTypedValue] = useState(value?.address ? formatAddress(value?.address) : '')

  useEffect(() => {
    setTypedValue(value?.address ? formatAddress(value?.address) : '')
  }, [value])

  const { data: { country_codes = [] } = {} } = useCountryCodes()

  const getCountryNameFromCode = useCallback(
    (countryCode) => {
      if (!countryCode) {
        return undefined
      }
      return country_codes.find(({ key }) => key === countryCode)?.display_name
    },
    [country_codes],
  )

  const onAddressSelected = useCallback(
    async (address) => {
      const setValueAndTrigger = async (key, value) => {
        await setValue(key, value ?? null)
        await trigger(key)
      }
      const geoLocation = getGeoLocationOfCompleteAddress(address, null)
      await setValueAndTrigger(`${basePath}.address.streetName`, address?.road ?? address?.name)
      await setValueAndTrigger(`${basePath}.address.houseId`, address?.houseNumber)
      await setValueAndTrigger(`${basePath}.address.postalCode`, address?.postcode)
      await setValueAndTrigger(`${basePath}.address.cityName`, address?.city)
      await setValueAndTrigger(`${basePath}.address.countryCode`, address?.countryCode)
      await setValueAndTrigger(
        `${basePath}.address.countryName`,
        getCountryNameFromCode(address?.countryCode),
      )
      await setValueAndTrigger(`${basePath}.geoLocation.latitude`, geoLocation.latitude)
      await setValueAndTrigger(`${basePath}.geoLocation.longitude`, geoLocation.longitude)
      onAddressSelectedCustomHandler(address)
    },
    [basePath, setValue, trigger, onAddressSelectedCustomHandler, getCountryNameFromCode],
  )

  return (
    <AddressAutocompleteInput
      {...props}
      onAddressSelected={onAddressSelected}
      onInput={(e) => setTypedValue(e?.target?.value ?? '')}
      value={typedValue}
    />
  )
}

DealAddressAutocompleteInput.propTypes = {
  ...AddressAutocompleteInput.propTypes,
  basePath: PropTypes.string.isRequired,
  onAddressSelectedCustomHandler: PropTypes.func,
}

export default DealAddressAutocompleteInput
