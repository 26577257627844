import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import useBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationships'
import { setTileMetadata } from 'redux/slices/decision-paper/tilesOverviewSlice'

const JOINT_ACCOUNT_CODE = 'YJR080'

const useAnnualReviewBorrowerSelection = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: businessPartnerRelationships,
    isLoading: isBusinessPartnerRelationshipsLoading,
    isError: isBusinessPartnerRelationshipsError,
    error: businessPartnerRelationshipsError,
  } = useBusinessPartnerRelationships(businessPartnerId)

  const {
    data: businessPartner,
    isLoading: isBusinessPartnerLoading,
    isError: isBusinessPartnerError,
    error: businessPartnerError,
  } = useBusinessPartnerById(businessPartnerId)

  const gktoUnitRelationship = businessPartnerRelationships?.unitRelationships.find(
    (unitRelationship) => unitRelationship.id === JOINT_ACCOUNT_CODE,
  )
  const gktoRelationships = gktoUnitRelationship?.relationships.find(
    (relationship) => relationship.head.id === businessPartnerId,
  )

  const businessPartnerName = businessPartner?.fullName

  // gktoMembers equal borrowers in this case
  const gktoMembers = compact(
    [{ id: businessPartnerId, name: businessPartnerName }].concat(
      gktoRelationships?.members.map((member) => ({
        id: member.id,
        name: member.fullName,
      })),
    ),
  )

  const sourceRender = useMemo(() => ({ businessPartnerId }), [businessPartnerId])

  const borrowersMetadata = useSelector(
    (state) =>
      state.decisionPaper.tilesOverview.tileMetadata?.[
        availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsBorrowerSelection
      ],
  )

  /**
   * Sets the current business partner as the default value.
   * The reason behind this is, that there could be a case where the user is opening a decision paper and is freezing without visiting the section where the
   * borrower selection tile is located, therefore it is never rendered and the default ist not set. Thus, we have to set it in the loading hook.
   */
  const dispatch = useDispatch()
  const [isDefaultSet, setIsDefaultSet] = useState(false)
  useEffect(() => {
    if (isDefaultSet) {
      return
    }

    if (
      isBusinessPartnerRelationshipsError ||
      isBusinessPartnerError ||
      !isNil(borrowersMetadata)
    ) {
      setIsDefaultSet(true)
      return
    }

    const isLoaded = !isBusinessPartnerRelationshipsLoading && !isBusinessPartnerLoading
    if (isLoaded) {
      dispatch(
        setTileMetadata({
          tileCode: availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsBorrowerSelection,
          metadata: {
            selectedBorrowerId: businessPartnerId,
            selectedBorrowerName: businessPartnerName,
            allBorrowers: gktoMembers,
          },
        }),
      )
      setIsDefaultSet(true)
    }
  }, [
    dispatch,
    borrowersMetadata,
    gktoMembers,
    isDefaultSet,
    isBusinessPartnerRelationshipsLoading,
    isBusinessPartnerLoading,
    isBusinessPartnerRelationshipsError,
    isBusinessPartnerError,
    businessPartnerId,
    businessPartnerName,
  ])

  const { isSomeValueError, isSomeValueLoading, error } = useAutomaticTileHookHelper({
    loadingValues: [isBusinessPartnerRelationshipsLoading, isBusinessPartnerLoading, !isDefaultSet],
    errorValues: [isBusinessPartnerRelationshipsError, isBusinessPartnerError],
    errorDetails: [businessPartnerRelationshipsError, businessPartnerError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    // When there is no metadata, this thing will stay disabled and therefore loading --> check after the other finished loading
    // whether now the selected conditions are loading.
    if (isBusinessPartnerRelationshipsLoading || isBusinessPartnerLoading) {
      return {
        isLoading: true,
        isError: false,
      }
    }

    // Everything has loaded --> return the GKTO relationships
    return {
      isLoading: false,
      isError: false,
      data: {
        gktoMembers,
        sourceRender,
        businessPartnerId,
      },
    }
  }, [
    isBusinessPartnerRelationshipsLoading,
    isBusinessPartnerLoading,
    isSomeValueLoading,
    isSomeValueError,
    gktoMembers,
    sourceRender,
    businessPartnerId,
    error,
  ])
}

export default useAnnualReviewBorrowerSelection
