import z from 'zod'

const receivableBaseSchema = z.object({
  id: z.string().uuid(),
  financialProductId: z.string(),
  cagId: z.string().uuid(),
  portionId: z.number().int(),
  priority: z.number().int(),
  ranking: z.number().int(),
})

const existingBusinessReceivableBaseSchema = z.object({
  id: z.string().uuid(),
  financialProductId: z.string(),
  cagId: z.string().uuid(),
  companyCode: z.string().optional(),
  contractNumber: z.string().optional(),
  productType: z.string().nullish().optional(),
  portionId: z.number().int(),
  priority: z.number().int(),
  ranking: z.number().int(),
})

/** @typedef {import('zod').TypeOf<typeof receivableBaseSchema>} Receivable */

export const receivablesBaseSchema = z.object({
  newBusinessReceivables: z.array(receivableBaseSchema),
  existingBusinessReceivables: z.array(existingBusinessReceivableBaseSchema),
})

/** @typedef {import('zod').TypeOf<typeof receivablesBaseSchema>} Receivables */

const receivablesSchema = receivablesBaseSchema.optional()

export default receivablesSchema
