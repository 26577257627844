import { useEffect, useMemo, useState } from 'react'
import { useRequest } from 'hooks/services/baseService'

const useImageDownload = ({ imageUrl }) => {
  const url = useMemo(() => {
    try {
      return new URL(imageUrl)
    } catch (error) {
      return
    }
  }, [imageUrl])

  const path = url?.pathname

  const {
    data: imageData,
    isLoading,
    isError,
  } = useRequest({
    path,
    keys: ['media', path],
    options: {
      enabled: !!url,
    },
  })

  const [imageBase64, setImageBase64] = useState('placeholder')

  const blobToBase64 = (blob) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = function () {
      const base64data = reader.result
      setImageBase64(base64data)
    }
  }

  useEffect(() => {
    if (!isLoading && !isError) {
      blobToBase64(imageData)
    }
  }, [imageData, isError, isLoading])

  return imageBase64
}

export default useImageDownload
