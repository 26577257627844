import { DATA_TYPES } from 'components/domains/properties/multi-property-upload/MultiPropertyUpload'
import { useAreaMeasureUnitCodes } from 'hooks/services/properties/useAreaUnitOfMeasureCodes'
import { useCountryCodes } from 'hooks/services/properties/useCountryCodes'
import { useCurrencyCodes } from 'hooks/services/properties/useCurrencyCodes'
import { useTypeCodesMinor } from 'hooks/services/properties/useTypeCodesMinor'
import { useTypecodes } from 'hooks/services/properties/useTypecodes'
import { useUtilizationCodes } from 'hooks/services/properties/useUtilizationCodes'

/**
 * React hook that loads the necessary enumerations of a property and returns the column definitions of the Excel template.
 * The column definitions contain information such as whether a column is mandatory or not, and the possible values of an enumeration column.
 * These column definitions are most likely used for pre-validations before the properties are being created and mapping the excel rows into property create payloads.
 *
 * @returns {{
 *   isLoading: boolean,
 *   isError: boolean,
 *   data: Array<{
 *     accessor: string,
 *     en: string,
 *     de: string,
 *     mandatory?: boolean,
 *     enumeration?: Array<string>,
 *     type?: string,
 *   }>
 * }}
 *
 * An object with the following properties:
 * - `isLoading`: A boolean indicating whether the necessary data is still being loaded.
 * - `isError`: A boolean indicating whether an error occurred while loading the necessary data.
 * - `data`: An array of objects representing the column definitions of the Excel template. Each object has the following properties:
 *   - `accessor`: The name of the property in the data object that corresponds to this column.
 *   - `en`: The English name of the column.
 *   - `de`: The German name of the column.
 *   - `mandatory` (optional): A boolean indicating whether this column is mandatory.
 *   - `enumeration` (optional): An array of strings representing the possible values of an enumeration column.
 *   - `type` (optional): A string representing the data type of the column.
 * }}
 */
const useMultiPropertyUploadColumns = () => {
  const {
    isLoading: isPropertyTypesLoading,
    isError: isPropertyTypesError,
    data: propertyTypesData,
  } = useTypecodes()
  const propertyTypes = propertyTypesData?.typecodes ?? []

  const {
    isLoading: isPropertyMinorTypesLoading,
    isError: isPropertyMinorTypesError,
    data: propertyMinorTypesData,
  } = useTypeCodesMinor()
  const propertyMinorTypes = propertyMinorTypesData?.type_codes_minor ?? []

  const {
    isLoading: isAreaMeasureUnitCodesLoading,
    isError: isAreaMeasureUnitCodesError,
    data: areaMeasurementUnitsData,
  } = useAreaMeasureUnitCodes()
  const areaMeasureUnitCodes = areaMeasurementUnitsData?.measure_unit_codes ?? []

  const {
    isLoading: isCurrenciesLoading,
    isError: isCurrenciesError,
    data: currenciesData,
  } = useCurrencyCodes()
  const currencies = currenciesData?.currency_codes ?? []

  const {
    isLoading: isUtilizationsLoading,
    isError: isUtilizationsError,
    data: utilizationsData,
  } = useUtilizationCodes()
  const utilizations = utilizationsData?.utilization_codes ?? []

  const {
    isLoading: isCountryCodesLoading,
    isError: isCountryCodesError,
    data: countryCodesData,
  } = useCountryCodes()
  const countryCodes = countryCodesData?.country_codes ?? []

  const isLoading =
    isPropertyTypesLoading ||
    isPropertyMinorTypesLoading ||
    isAreaMeasureUnitCodesLoading ||
    isCurrenciesLoading ||
    isUtilizationsLoading ||
    isCountryCodesLoading

  const isError =
    isPropertyTypesError ||
    isPropertyMinorTypesError ||
    isAreaMeasureUnitCodesError ||
    isCurrenciesError ||
    isUtilizationsError ||
    isCountryCodesError

  if (isLoading || isError) {
    return {
      isLoading,
      isError,
      data: [],
    }
  }

  // TODO: Add Property Type Minor, when F4 Helper etc. is available
  return {
    isLoading: false,
    isError: false,
    data: [
      {
        accessor: 'description',
        en: 'Property Name',
        de: 'Immobilienname',
        mandatory: true,
      },
      {
        accessor: 'typeCode',
        en: 'Property Type ID',
        de: 'Objekttyp ID',
        mandatory: true,
        enumeration: propertyTypes,
      },
      {
        accessor: 'typeCodeMinor',
        en: 'Property Type Minor ID',
        de: 'Objektsubtyp ID',
        mandatory: false,
        enumeration: propertyMinorTypes,
      },
      {
        accessor: 'areaMeasureUnitCode',
        en: 'Unit Area Measurement Type ID',
        de: 'Maßeinheit (Fläche) ID',
        mandatory: true,
        enumeration: areaMeasureUnitCodes,
      },
      {
        accessor: 'currencyCode',
        en: 'Property Currency',
        de: 'Währung',
        mandatory: true,
        enumeration: currencies,
      },
      {
        accessor: 'marketValue',
        en: 'Market Value',
        de: 'Marktwert',
        type: DATA_TYPES.NUMERIC,
      },
      {
        accessor: 'purchasePrice',
        en: 'Purchase Prize',
        de: 'Kaufpreis',
        type: DATA_TYPES.NUMERIC,
      },
      {
        accessor: 'mortgageLendingValue',
        en: 'Mortgage Lending Value',
        de: 'Beleihungswert',
        type: DATA_TYPES.NUMERIC,
      },
      {
        accessor: 'totalArea',
        en: 'Total Area',
        de: 'Gesamtfläche',
        type: DATA_TYPES.NUMERIC,
      },
      {
        accessor: 'utilization',
        en: 'Utilization ID',
        de: 'Nutzung ID',
        enumeration: utilizations,
      },
      {
        accessor: 'constructionCompletionDate',
        en: 'Construction Year',
        de: 'Baujahr',
        type: DATA_TYPES.DATE_YEAR,
      },
      {
        accessor: 'constructionCompletionPercent',
        en: 'Degree of Completion',
        de: 'Fertigstellungsgrad',
        type: DATA_TYPES.PERCENTAGE,
      },
      {
        accessor: 'streetName',
        en: 'Street',
        de: 'Straße',
      },
      {
        accessor: 'houseNumber',
        en: 'House Number',
        de: 'Hausnummer',
      },
      {
        accessor: 'postalCode',
        en: 'Postal Code',
        de: 'Postleitzahl',
      },
      {
        accessor: 'cityName',
        en: 'City',
        de: 'Stadt',
        mandatory: true,
      },
      {
        accessor: 'countryCode',
        en: 'Country ID',
        de: 'Land ID',
        mandatory: true,
        enumeration: countryCodes,
      },
      {
        accessor: 'latitude',
        en: 'Latitude',
        de: 'Breitengrad',
        type: DATA_TYPES.NUMERIC,
      },
      {
        accessor: 'longitude',
        en: 'Longitude',
        de: 'Längengrad',
        type: DATA_TYPES.NUMERIC,
      },
      {
        accessor: 'forceUpdate',
        en: 'Force Update ID',
        de: 'Update erzwingen ID',
        type: DATA_TYPES.BOOLEAN,
      },
    ],
  }
}
export default useMultiPropertyUploadColumns
