import { CardHeader, MessageStrip } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import documentPermissions from 'api/documents/DocumentPermissions'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import DocumentUploadDialog from 'components/domains/documents/DocumentUploadDialog'
import DocumentsTable from 'components/domains/documents/documents-table/DocumentsTable'
import mapDocumentsForTable from 'components/domains/documents/documents-table/mapDocumentsForTable'
import {
  labelNewDocuments,
  labelReferencedDocuments,
} from 'components/domains/markets/detail/cards/MarketDocumentTableFunctions'
import styles from 'components/domains/properties/documents/PropertyDocuments.module.css'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useDocumentsTableColumns } from 'hooks/services/documents/documents-table/useDocumentsTableColumnDefinitions'
import useDocuments from 'hooks/services/documents/useDocuments'
import useDocumentsAllowedOperations from 'hooks/services/documents/useDocumentsAllowedOperations'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyDocuments = () => {
  const [showMessageStrip, setShowMessageStrip] = useState(true)
  /** @type {string[]} */
  const initialUploadedDocumentIds = []
  const [uploadedDocumentIds, setUploadedDocumentIds] = useState(initialUploadedDocumentIds)
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false)
  const { property } = useContext(PropertyContext)
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property-overview.documents',
  })
  const {
    data: documentRoot = [],
    isLoading,
    isError,
  } = useDocuments({ entityId: property.uuid, entityType: DocumentTypes.Property })

  const labeledDocuments = useMemo(
    () => labelNewDocuments(documentRoot, uploadedDocumentIds),
    [documentRoot, uploadedDocumentIds],
  )

  const referencedDocuments = useMemo(
    () => labelReferencedDocuments(labeledDocuments),
    [labeledDocuments],
  )

  const preparedDocumentRoot = useMemo(
    () => mapDocumentsForTable(referencedDocuments),
    [referencedDocuments],
  )

  const {
    data: allowedOperationsData,
    isLoading: isLoadingAllowedOperations,
    isError: isAllowedOperationsError,
  } = useDocumentsAllowedOperations()

  const hasDocumentReadPermission =
    allowedOperationsData?.allowedOperations.includes(documentPermissions.Read) ?? false
  const hasDocumentUploadPermission =
    allowedOperationsData?.allowedOperations.includes(documentPermissions.Create) ?? false

  const { columns, formatDate } = useDocumentsTableColumns()
  const [files, setFiles] = useState(undefined)

  const handleOnDrop = ({ files: newFiles } = {}) => {
    setFiles(newFiles)
    setIsUploadDialogOpen(true)
  }

  if (!hasDocumentReadPermission) return <></>

  return (
    <>
      <DocumentUploadDialog
        type={DocumentTypes.Property}
        isOpen={isUploadDialogOpen}
        initialState={{
          open: undefined,
          files,
          selectedEntities: [
            { id: property.uuid, name: property.description, displayId: property.id },
          ],
        }}
        onUploadFinished={(newDocumentIds) => {
          setUploadedDocumentIds(newDocumentIds)
        }}
        onClose={() => {
          setIsUploadDialogOpen(false)
          setFiles(undefined)
        }}
      />
      {showMessageStrip && (
        <MessageStrip className={styles.infoStrip} onClose={() => setShowMessageStrip(false)}>
          {t('maybe-not-all-document-are-shown')}
        </MessageStrip>
      )}
      <RequestStateResolver
        isLoading={isLoading || isLoadingAllowedOperations}
        isError={isError || isAllowedOperationsError}
        errorToDisplay={
          <Card header={<CardHeader titleText={t('title')} />}>
            <ErrorDataUnavailableInContent />
          </Card>
        }
        renderContent={() => (
          <Card>
            <div className={styles.content}>
              <div>
                <DocumentsTable
                  columns={columns}
                  folders={preparedDocumentRoot.subFolders}
                  title={t('title')}
                  formatDate={formatDate}
                  handleOnDrop={handleOnDrop}
                  hasDocumentUploadPermission={hasDocumentUploadPermission}
                />
              </div>
            </div>
          </Card>
        )}
      />
    </>
  )
}

PropertyDocuments.propTypes = {
  propertyDescription: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ),
  status: PropTypes.shape({
    text: PropTypes.string.isRequired,
    valueState: PropTypes.string,
  }),
  additionalStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string.isRequired,
      valueState: PropTypes.string,
    }),
  ),
}

export default PropertyDocuments
