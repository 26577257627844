import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const propTypes = {
  onClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedEntities: PropTypes.array,
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const EventsAndTasksCommentMultipleButton = ({ onClick, selectedEntities }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.comment-multiple',
  })

  const isButtonDisabled = isEmpty(selectedEntities)

  return (
    <Button design={ButtonDesign.Transparent} onClick={onClick} disabled={isButtonDisabled}>
      {t('button')}
    </Button>
  )
}

EventsAndTasksCommentMultipleButton.propTypes = propTypes

export default EventsAndTasksCommentMultipleButton
