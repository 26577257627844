import { Input, Label, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const TableDescriptionSubcomponent = ({ description, isEditMode, onInput }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.conditions.table' })

  const onInputInternal = useCallback(
    ({ target: { value: newDescription } }) => {
      onInput(newDescription)
    },
    [onInput],
  )

  // This is a hack for the React Table. It seems like react table listens to the space event and stops
  // the propagation of this event to other components like the input. With this the event is not handed to
  // other components but the input which solves the behaviour for us.
  const onKeyDown = useCallback((event) => {
    if (event.code === 'Space' || event.code === 'ArrowRight' || event.code === 'ArrowLeft') {
      event.stopPropagation()
    }
  }, [])
  const onFocus = useCallback((event) => {
    event.stopPropagation()
  }, [])

  const textOrInputComponent = useMemo(() => {
    if (isEditMode) {
      return (
        <Input
          value={description}
          placeholder={t('description.placeholder')}
          onInput={onInputInternal}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
        />
      )
    }
    return <Text wrapping>{description ? description : ''}</Text>
  }, [isEditMode, description, onKeyDown, onInputInternal, t, onFocus])
  return (
    <div>
      <Label>{t('label.description')}</Label>
      <div>{textOrInputComponent}</div>
    </div>
  )
}

TableDescriptionSubcomponent.propTypes = {
  description: PropTypes.string,
  isEditMode: PropTypes.bool.isRequired,
  onInput: PropTypes.func.isRequired,
}

export default TableDescriptionSubcomponent
