import { useQuery } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useBankCustomerAccountsAmounts = ({ bcaIds = [], options } = {}) => {
  const { post } = useAccessTokenRequest()
  return useCamelizedResponse(
    useQuery({
      queryKey: ['bank-customer-accounts', 'amounts', ...bcaIds],
      refetchOnWindowFocus: false,
      queryFn: () =>
        post({
          path: `/financing-structures/bank-customer-accounts/amounts`,
          body: { bca_ids: bcaIds },
        }),
      retry: false,
      options: { enabled: !!bcaIds.length, ...options },
    }),
  )
}
export default useBankCustomerAccountsAmounts
