import { useMemo } from 'react'
import { isConflictError } from 'api/requests'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { UNIT_TYPE_ID_STANDALONE } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/gcc-involved-parties/constants'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useLeadingAuthorityLevelCalculator from 'hooks/services/business-partners/authority-level-calculator/exec-summary/useLeadingAuthorityLevelCalculator'
import useAuthorityLevelCalculatorComment from 'hooks/services/business-partners/authority-level-calculator/useAuthorityLevelCalculatorComment'
import useDeal from 'hooks/services/deals/useDeal'

const useGccInvolvedPartiesAuthorityLevelCalculator = (
  { entityRef: { entityId: dealUuid } },
  tileId,
  tileCode,
) => {
  const {
    data: { dealUuid: dealUuidByTileCode } = {},
    isFetching: isFetchingDealUuid,
    isError: isErrorDealUuid,
  } = useDealUuidByTileCode({
    dealUuid,
    tileCode,
  })

  const {
    data: authorityLevelCalculatorData,
    isFetching: isFetchingAuthorityLevelCalculatorData,
    isError: isErrorAuthorityLevelCalculatorData,
    error: authorityLevelCalculatorError,
  } = useLeadingAuthorityLevelCalculator(dealUuidByTileCode)

  const businessPartnerId = authorityLevelCalculatorData?.unitHead?.unitHeadBpId
  const unitTypeId = authorityLevelCalculatorData?.unitHead?.unitTypeId

  const {
    data: authorityLevelCalculatorCommentData,
    isFetching: isFetchingAuthorityLevelCalculatorCommentData,
    isError: isErrorAuthorityLevelCalculatorCommentData,
  } = useAuthorityLevelCalculatorComment(businessPartnerId, businessPartnerId, unitTypeId)

  const {
    data: dealData,
    isFetching: isFetchingDealData,
    isError: isErrorDealData,
  } = useDeal(dealUuidByTileCode)

  const normalizedAuthorityLevelCalculatorError = useMemo(() => {
    if (isErrorAuthorityLevelCalculatorData) {
      if (isConflictError(authorityLevelCalculatorError)) {
        return false
      }
      return true
    }
    return false
  }, [authorityLevelCalculatorError, isErrorAuthorityLevelCalculatorData])

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isFetchingAuthorityLevelCalculatorData,
      isFetchingAuthorityLevelCalculatorCommentData,
      isFetchingDealData,
      isFetchingDealUuid,
    ],
    errorValues: [
      normalizedAuthorityLevelCalculatorError,
      isErrorAuthorityLevelCalculatorCommentData,
      isErrorDealData,
      isErrorDealUuid,
    ],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    // TODO: Handle what to show if the endpoint returns a 409.
    // Needs to be specified by BAs first though

    const data =
      authorityLevelCalculatorData && authorityLevelCalculatorCommentData && dealData
        ? {
            authorityLevelCalculatorTableData: authorityLevelCalculatorData,
            authorityLevelCalculatorHeaderData: {
              businessPartnerName: authorityLevelCalculatorData.unitHead?.unitHeadBpFullName,
              businessPartnerId: authorityLevelCalculatorData.unitHead?.unitHeadBpId,
              isStandaloneBusinessPartner:
                authorityLevelCalculatorData.unitHead?.unitTypeId === UNIT_TYPE_ID_STANDALONE,
            },
            authorityLevelCalculatorCommentData: authorityLevelCalculatorCommentData.comment,
            dealBorrowerBpId: dealData.borrowerBpId,
            sourceRender: { businessPartnerId },
          }
        : undefined

    return {
      isLoading: false,
      isError: false,
      data,
    }
  }, [
    authorityLevelCalculatorCommentData,
    authorityLevelCalculatorData,
    businessPartnerId,
    dealData,
    error,
    isSomeValueError,
    isSomeValueLoading,
  ])
}

export default useGccInvolvedPartiesAuthorityLevelCalculator
