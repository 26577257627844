import PropTypes from 'prop-types'
import { QuestionLayout } from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/forbearanceAndUtpQuestionareSchema'
import Question from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/shared/checksheet/Question'

const propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node,
  isEditMode: PropTypes.bool.isRequired,
}
/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const NonEditableQuestion = ({ label, value, isEditMode }) => (
  <Question isEditMode={isEditMode} label={label} layout={QuestionLayout.ROW}>
    {value}
  </Question>
)

NonEditableQuestion.propTypes = propTypes

export default NonEditableQuestion
