import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/conditions/TableCell.module.css'

const ConditionsTableRefNumberCell = ({ value: { refNumber } }) => (
  <Text className={styles.title} wrapping>
    {refNumber}
  </Text>
)

ConditionsTableRefNumberCell.propTypes = {
  value: PropTypes.shape({
    refNumber: PropTypes.string,
  }).isRequired,
}

export default ConditionsTableRefNumberCell
