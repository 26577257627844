import camelize from 'camelize'
import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import { useRequest } from 'hooks/services/baseService'
import { forbearanceMeasuresSchema } from 'hooks/services/risk-monitoring/forbearance/measures/forbearanceMeasuresSchema'

/**
 * @param {object} params
 * @param {string} [params.businessPartnerId]
 * @param {"createdDate"} [params.sortBy]
 * @param {"asc"|"desc"} [params.orderBy]
 * @param {Omit<Parameters<typeof useRequest>[0]["options"], "enabled">} [params.options]
 */
const useForbearanceMeasures = ({ businessPartnerId, sortBy, orderBy, options = {} }) => {
  const params = new URLSearchParams()
  if (sortBy) params.append('sortBy', sortBy)
  if (orderBy) params.append('orderBy', orderBy)

  // HINT: `params.size` seems to return `undefined` even if there are params
  const paramsString = [...params.keys()].length ? `?${params.toString()}` : ''

  const result = useRequest({
    path: `/risk-monitoring/business-partners/${businessPartnerId}/forbearance/measures${paramsString}`,
    keys: compact([
      'risk-monitoring',
      'business-partners',
      businessPartnerId,
      'forbearance',
      'measures',
    ]),
    translated: true,
    options: {
      ...options,
      enabled: !isNil(businessPartnerId),
    },
  })

  const parsed = forbearanceMeasuresSchema.optional().safeParse(camelize(result.data))

  if (!parsed.success) {
    return { ...result, data: undefined, isError: true, error: parsed.error }
  }

  return {
    ...result,
    data: parsed.data,
  }
}

export default useForbearanceMeasures
