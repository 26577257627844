import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/components/ui/tables/toolbar/FilterInfoBar.module.css'

const filterForNotChangedValues = ({ value, emptyValue }) => !!value && !isEqual(value, emptyValue)

const FilterInfoBar = ({ setIsFilterDialogOpen, filters }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.events.components.ui.tables.toolbar',
  })

  const showFilterInfoBar = useMemo(() => filters?.some(filterForNotChangedValues), [filters])

  const onClick = useCallback(() => setIsFilterDialogOpen(true), [setIsFilterDialogOpen])

  if (!showFilterInfoBar) {
    return
  }

  return (
    <div className={styles.filterInfoBar} onClick={onClick}>
      {t('filterbar.title', {
        filters: filters
          .filter(filterForNotChangedValues)
          .map(({ label }) => label)
          .join(', '),
      })}
    </div>
  )
}

FilterInfoBar.propTypes = {
  setIsFilterDialogOpen: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      emptyValue: PropTypes.any,
      label: PropTypes.string,
    }),
  ),
}

export default FilterInfoBar
