import { setLanguage } from '@ui5/webcomponents-base/dist/config/Language'
import i18n, { changeLanguage } from 'i18next'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useUserProfile from 'hooks/services/user-profile/useUserProfile'
import { getSupportedLanguages } from 'i18n/setupI18n'
import { UserProfileContext } from 'routes/UserProfileContext'

/**
 * Wrapper component that loads the current user profile into the react-query cache.
 *
 * Performs some additional logic to configure the app whenever the user profile is updated.
 * For instance, setting the app's language.
 */
const UserContextWrapper = ({ children }) => {
  const { data, isLoading, isError } = useUserProfile()
  const dataJSON = JSON.stringify(data)
  useEffect(() => {
    if (data) {
      let language = data.language
      const supportedLanguages = getSupportedLanguages()
      if (!supportedLanguages.includes(data.language)) {
        language = i18n.language
      }

      // Need to change both i18next language and ui5-webcomponents language
      setLanguage(language)
      changeLanguage(language)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataJSON])

  return (
    <LoadingStateWrapper
      isError={isError}
      isLoading={isLoading}
      renderContent={() => (
        <UserProfileContext.Provider value={data}>{children}</UserProfileContext.Provider>
      )}
    />
  )
}

UserContextWrapper.propTypes = {
  children: PropTypes.element.isRequired,
}

export default UserContextWrapper
