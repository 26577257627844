import { Button, ButtonDesign, FlexBox } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionProcessConditionsDecisionStatus } from 'api/decision-process/decisionProcessConditionsApi'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import styles from 'components/domains/business-events-and-tasks/decision-stage/conditions/DecisionStageConditionButtons.module.css'
import useDiscardConditionDecisionFlow from 'hooks/services/business-events-and-tasks/decision-process/stages/conditions/flows/useDiscardConditionDecisionFlow'
import useRequestConditionDecisionFlow from 'hooks/services/business-events-and-tasks/decision-process/stages/conditions/flows/useRequestConditionDecisionFlow'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionStageConditionButtons = ({ decisionStageId, decisionStatus }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.conditions',
  })
  const {
    event: { id: eventId },
    allowedOperations,
  } = useContext(BusinessEventsAndTasksContext)

  const hasUpdateDecisionStagePermission = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.updateDecisionStage),
    [allowedOperations],
  )

  const { onRequestDecisionButtonClicked } = useRequestConditionDecisionFlow({
    eventId,
    decisionStageId,
  })

  const { onDiscardDecisionButtonClicked } = useDiscardConditionDecisionFlow({
    eventId,
    decisionStageId,
  })

  const requestDecisionButton = useMemo(
    () =>
      hasUpdateDecisionStagePermission && (
        <Button design={ButtonDesign.Emphasized} onClick={onRequestDecisionButtonClicked}>
          {t('buttons.request-decision')}
        </Button>
      ),
    [t, hasUpdateDecisionStagePermission, onRequestDecisionButtonClicked],
  )

  const discardDecisionButton = useMemo(
    () =>
      hasUpdateDecisionStagePermission && (
        <Button design={ButtonDesign.Transparent} onClick={onDiscardDecisionButtonClicked}>
          {t('buttons.discard')}
        </Button>
      ),
    [t, hasUpdateDecisionStagePermission, onDiscardDecisionButtonClicked],
  )

  if (
    decisionStatus === decisionProcessConditionsDecisionStatus.pending ||
    decisionStatus === decisionProcessConditionsDecisionStatus.inProgressRequestDiscarded
  ) {
    return <FlexBox className={styles.buttonWrapper}>{requestDecisionButton}</FlexBox>
  }

  if (decisionStatus === decisionProcessConditionsDecisionStatus.inProgress) {
    return <FlexBox className={styles.buttonWrapper}>{discardDecisionButton}</FlexBox>
  }
}

DecisionStageConditionButtons.propTypes = {
  decisionStageId: PropTypes.string.isRequired,
  decisionStatus: PropTypes.oneOf(Object.values(decisionProcessConditionsDecisionStatus))
    .isRequired,
}

export default DecisionStageConditionButtons
