import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useCustomizableCurrencyFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/hooks/i18n/useI18n'
import ConvertedAmount from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/routes/deals/financing/shared-components/ConvertedAmount'

const useSellingAmountTotalFields = ({
  labelCode,
  name,
  isShownInDisplay,
  sellingAmountTotal,
  sellingAmountTotalHeadquarter,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.syndications.intention-card',
  })

  const formatCurrency = useCustomizableCurrencyFormatter()

  const label = t(labelCode)

  return [
    {
      label,
      name,
      value: formatCurrency(sellingAmountTotal?.amount, sellingAmountTotal?.currency, {
        currencyDisplay: 'code',
      }),

      isShownInDisplay: !!isShownInDisplay,
      isShownInEdit: false,
    },
    {
      customInfoComponent: ConvertedAmount(
        sellingAmountTotalHeadquarter?.amount,
        sellingAmountTotalHeadquarter?.currency,
      ),

      isShownInDisplay:
        !!isShownInDisplay &&
        !!sellingAmountTotalHeadquarter?.amount &&
        sellingAmountTotal?.currency !== sellingAmountTotalHeadquarter?.currency,

      isShownInEdit: false,
    },
  ]
}

useSellingAmountTotalFields.defaultProps = {
  labelCode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isShownInDisplay: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sellingAmountTotal: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  sellingAmountTotalHeadquarter: PropTypes.object,
}

export default useSellingAmountTotalFields
