import { MultiComboBox, MultiComboBoxItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/deals/change-overview/DealChangeOverviewMultiComboBoxFilter.module.css'

const DealChangeOverviewMultiComboBoxFilter = ({
  filterType,
  filterItems,
  filterParams,
  setFilterParams,
  onKeyDown,
}) => (
  <MultiComboBox
    id={`deal-changed-overview-table-${filterType}-filter`}
    className={styles.maxInputWidth}
    onSelectionChange={({ detail: { items: selectedEntityTypes } }) => {
      const keys = selectedEntityTypes.map((type) => type.dataset.key)
      setFilterParams({
        ...filterParams,
        [filterType]: keys.join(','),
      })
    }}
    onKeyDown={onKeyDown}
  >
    {filterItems.map(({ key, display_name }) => (
      <MultiComboBoxItem
        key={key}
        text={display_name}
        selected={filterParams[filterType].split(',').includes(key)}
        data-key={key}
      />
    ))}
  </MultiComboBox>
)
DealChangeOverviewMultiComboBoxFilter.propTypes = {
  filterType: PropTypes.string.isRequired,
  filterItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      display_name: PropTypes.string.isRequired,
    }),
  ),
  filterParams: PropTypes.shape({
    entity_id: PropTypes.string.isRequired,
    entity_type: PropTypes.string.isRequired,
    page_key: PropTypes.string.isRequired,
    card_key: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
  }),
  setFilterParams: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
}

export default DealChangeOverviewMultiComboBoxFilter
