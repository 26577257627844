import camelize from 'camelize'
import uniq from 'lodash.uniq'
import { useMemo } from 'react'
import addSharesToUnit from 'components/domains/business-partners/addSharesToUnits'
import businessPartnerFunctionTypes from 'components/domains/business-partners/tile/tenancy/businessPartnerFunctionTypes'
import { useRentalUnits } from 'hooks/services/properties/useRentalUnits'

/** @param {string} businessPartnerId */
export const useRentalUnitsAsTenantInTableStructure = (businessPartnerId) => {
  const {
    isLoading: isRentalUnitsLoading,
    isError: isRentalUnitsError,
    data: rentalUnitsData,
  } = useRentalUnits(businessPartnerId, 'tenant')

  /**
   * @typedef {object} rentalUnit
   * @property {{id: string; functionType: string}[]} [externalBusinessPartners]
   * @param {rentalUnit[]?} [rentalUnits]
   */
  const extractBrandingFranchiseProviderIds = (rentalUnits) => {
    if (!rentalUnits) return []
    const externalBusinessPartners = rentalUnits.flatMap(
      (rentalUnit) => rentalUnit.externalBusinessPartners ?? [],
    )
    const brandOrFranchiseProviders = externalBusinessPartners.filter(
      (businessPartner) =>
        businessPartner.functionType === businessPartnerFunctionTypes.branding_franchise,
    )

    return uniq(brandOrFranchiseProviders.map(({ id }) => id))
  }

  const brandingFranchiseProviderIds = useMemo(
    () => extractBrandingFranchiseProviderIds(rentalUnitsData?.rentalUnits),
    [rentalUnitsData?.rentalUnits],
  )

  const rentalUnitsWithShares = useMemo(() => {
    const rentalUnits = rentalUnitsData?.rentalUnits ?? []
    return rentalUnits.map((rentalUnit) =>
      addSharesToUnit({
        units: camelize(rentalUnit),
        totalRent: rentalUnitsData?.totalRentCurrentYearInHeadQuarterCurrency?.number,
        totalArea: rentalUnitsData?.totalRentedAreaInHeadQuarterMeasurementUnit?.value,
      }),
    )
  }, [
    rentalUnitsData?.rentalUnits,
    rentalUnitsData?.totalRentCurrentYearInHeadQuarterCurrency?.number,
    rentalUnitsData?.totalRentedAreaInHeadQuarterMeasurementUnit?.value,
  ])

  const isLoading = isRentalUnitsLoading
  const isError = isRentalUnitsError

  return {
    isLoading,
    isError,
    data: {
      ...camelize(rentalUnitsData),
      rentalUnits: rentalUnitsWithShares,
      brandingFranchiseProviderIds,
    },
  }
}
