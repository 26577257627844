import {
  AnalyticalTableScaleWidthMode,
  MessageStrip,
  MessageStripDesign,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DealCashflowAnalyticalTable } from 'components/domains/deals/deal-cashflow/analytical-table/DealCashflowAnalyticalTable'
import styles from 'components/domains/deals/deal-cashflow/input-parameters/DealCashflowInputParametersTable.module.css'
import { useCashflowInputParametersColumnsEditMode } from 'components/domains/deals/deal-cashflow/input-parameters/useCashflowInputParametersColumns'
import {
  calculateRowHeightDisplayMode,
  calculateRowHeightEditMode,
} from 'components/domains/deals/deal-cashflow/parameters/calculateRowHeight'
import { useCashflowParametersColumns } from 'components/domains/deals/deal-cashflow/parameters/useCashflowParametersColumns'

const DealCashflowInputParametersTable = ({
  cashflowInputParameters,
  isEditMode,
  editData,
  setEditData,
  valueState,
  config,
  isSaveLoading = false,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.cashflow.input-params',
  })

  const columns = useCashflowParametersColumns()
  const columnsEditMode = useCashflowInputParametersColumnsEditMode(setEditData, valueState)

  const getParameterConfig = (objectName, ccpdId) =>
    config.objects
      ?.find((configItem) => configItem.name === objectName)
      ?.parameters?.find((paramItem) => paramItem.ccpdId === ccpdId)

  let nrOfEntries = 0
  let invalidDealCashflowObjects = 0
  const mapRow = (rowData, rowOriginData) => {
    nrOfEntries++

    if (rowOriginData.invalid) {
      invalidDealCashflowObjects++
    }

    const parameterList = []

    const coIdEditDataParams = editData?.[rowData.coId]?.parameters

    const filterCallback = (ccpdId) =>
      isEditMode
        ? !coIdEditDataParams[ccpdId]?.isRemoved
        : ccpdId !== 'new' && !coIdEditDataParams[ccpdId]?.isRemoved

    Object.keys(rowData.parameters)
      .sort((a, b) => coIdEditDataParams[a].index - coIdEditDataParams[b].index)
      .filter(filterCallback)
      .forEach((ccpdId) => {
        parameterList.push({
          label: rowOriginData.parameters.find(
            (param) => param.cashflowParameterDefinition.ccpdId === ccpdId,
          )?.cashflowParameterDefinition.name,
          ccpdId: coIdEditDataParams[ccpdId]?.ccpdId || ccpdId,
          ccodId: rowOriginData.objectDefinition.ccodId,
          isSaveLoading,
        })
      })

    const value = Object.keys(rowData.parameters)
      .sort((a, b) => coIdEditDataParams[a].index - coIdEditDataParams[b].index)
      .filter(filterCallback)
      .map((ccpdId) => {
        const editedParamId =
          coIdEditDataParams[ccpdId]?.isChanged && coIdEditDataParams[ccpdId]?.ccpdId
        const paramConfig = getParameterConfig(
          rowOriginData.objectDefinition.name,
          editedParamId || ccpdId,
        )

        const ccpuId = coIdEditDataParams[ccpdId]?.ccpuId ?? paramConfig?.units?.[0].ccpuId
        const selectedUnitDef = paramConfig?.units?.find((unitDef) => unitDef.ccpuId === ccpuId)

        return {
          ccodId: rowOriginData.objectDefinition.ccodId,
          value: coIdEditDataParams[ccpdId]?.value,
          unit: selectedUnitDef?.name,
          decimalPlaces: selectedUnitDef?.decimalPlaces,
          ccpdId,
          ccpuId,
          isSaveLoading,
        }
      })

    return {
      name: rowOriginData.objectName,
      type: rowOriginData.objectDefinition.name,
      ccodId: rowOriginData.objectDefinition.ccodId,
      coId: rowData.coId,
      parameter: parameterList,
      value,
      rowHeight: isEditMode
        ? calculateRowHeightEditMode(parameterList)
        : calculateRowHeightDisplayMode(parameterList),
      invalid: rowOriginData.invalid,
    }
  }

  const cashflowTableData =
    Object.keys(editData).length > 0
      ? cashflowInputParameters
          .sort((itemA, itemB) => {
            const indexConfigItemA = config.objects.findIndex(
              (objDef) => objDef.ccodId === itemA.objectDefinition.ccodId,
            )
            const indexConfigItemB = config.objects.findIndex(
              (objDef) => objDef.ccodId === itemB.objectDefinition.ccodId,
            )

            return indexConfigItemA - indexConfigItemB
          })
          .map((item) => {
            const rowObject = mapRow(editData[item.coId], item)

            rowObject.subRows = item.children.map((child) => mapRow(editData[child.coId], child))

            return rowObject
          })
      : []

  return (
    <>
      {invalidDealCashflowObjects > 0 && (
        <div className={styles.messageWrapper}>
          <MessageStrip design={MessageStripDesign.Warning} hideCloseButton>
            {t(`message.objects-deleted`, { count: invalidDealCashflowObjects })}
          </MessageStrip>
        </div>
      )}
      <DealCashflowAnalyticalTable
        className={`${styles['input-parameters-table']} ${
          isEditMode ? styles['input-parameters-table-edit'] : ''
        }`}
        title={t('subtitle')}
        nrOfEntries={nrOfEntries}
        columns={isEditMode ? columnsEditMode : columns}
        customRowHeight
        visibleRows={99}
        data={cashflowTableData}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
        withRowHighlight
        highlightField={(data) => (data.invalid ? ValueState.Warning : ValueState.None)}
      />
    </>
  )
}

DealCashflowInputParametersTable.propTypes = {
  cashflowInputParameters: PropTypes.array.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  editData: PropTypes.object.isRequired,
  setEditData: PropTypes.func.isRequired,
  valueState: PropTypes.oneOf([ValueState.Error, ValueState.None]),
  config: PropTypes.object.isRequired,
  isSaveLoading: PropTypes.bool,
}

export default DealCashflowInputParametersTable
