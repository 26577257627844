import {
  AnalyticalTable,
  AnalyticalTableScaleWidthMode,
  FlexBox,
  FlexBoxDirection,
} from '@fioneer/ui5-webcomponents-react'
import orderBy from 'lodash.orderby'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/tenancy/AsTenantCard.module.css'
import { TenancyCardTableColumnId } from 'components/domains/business-partners/tile/tenancy/tenant-unit-table/AsTenantTableConstants'
import { sortAsTenantTableData } from 'components/domains/business-partners/tile/tenancy/tenant-unit-table/sortAsTenantTableData'
import { useAsTenantCardTableColumns } from 'components/domains/business-partners/tile/tenancy/tenant-unit-table/useAsTenantCardTableColumns'
import useAsTenantTableData from 'components/domains/business-partners/tile/tenancy/tenant-unit-table/useAsTenantTableData'
import tableStyles from 'components/ui/tables/analytical/AnalyticalTableWithToolbar.module.css'
import { useCollapseExpandTableActions } from 'components/ui/tables/analytical/useCollapseExpandTableActions'
import TablesToolbar from 'components/ui/tables/toolbar/TablesToolbar'

const NoDataComponent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'analytical-table',
  })
  return <div className={styles.noDataWrapper}>{t('no-data-text')}</div>
}

const AsTenantCardTable = ({ tenancyCardData, isFetching }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.tenancy.as-tenant',
  })
  const tableRef = useRef(null)
  const [grouping, setGrouping] = useState({ groups: [], setGroupBy: () => {} })
  const groupedBy = useMemo(
    () => grouping.groups.find(({ isGrouped }) => isGrouped)?.id,
    [grouping.groups],
  )

  const { columns } = useAsTenantCardTableColumns({ groupedBy })

  const sortableColumns = useMemo(
    () =>
      columns.map(({ id, sortByLabel, Header }) => ({
        columnKey: id,
        title: sortByLabel || Header,
      })),
    [columns],
  )
  const [activeSorting, setActiveSorting] = useState({
    columnKey: TenancyCardTableColumnId.contractedRent,
    isSortingAscending: false,
    sortableColumns,
  })
  const onUpdateSorting = useCallback(
    ({ sortBy: updatedSortBy, sortDescending }) => {
      // Allows previously expanded rows with subcomponents to be expanded in their height when rendering them again after sorting
      delete tableRef.current.state?.subComponentsHeight
      const columnKey = sortableColumns.find((column) => column.title === updatedSortBy)?.columnKey
      setActiveSorting({
        columnKey,
        isSortingAscending: !sortDescending,
        sortableColumns,
      })
    },
    [sortableColumns],
  )
  const sorting = useMemo(
    () => ({
      ...activeSorting,
      onUpdateSorting,
    }),
    [activeSorting, onUpdateSorting],
  )

  const setGroupBy = useCallback((groupedColumn) => {
    const hiddenColumns = tableRef.current.state?.hiddenColumns ?? []
    hiddenColumns.forEach((hiddenColumn) => {
      tableRef.current.toggleHideColumn(hiddenColumn, false)
    })
    if (groupedColumn) {
      tableRef.current.toggleHideColumn(groupedColumn, true)
    }

    setGrouping((prev) => ({
      ...prev,
      groups: prev.groups.map((group) => ({
        ...group,
        isGrouped: groupedColumn === group.id,
      })),
    }))
  }, [])

  useEffect(() => {
    const groupOptions = columns
      .filter((column) => column.isGroupable)
      .map((column) => ({
        id: column.id,
        isGrouped: column.id === groupedBy,
        label: column.groupLabel || column.Header,
        groupByOrder: column.groupByOrder,
      }))
    setGrouping({
      noGroupingText: t('table-grouping.no-grouping-text'),
      setGroupBy: setGroupBy,
      groups: orderBy(groupOptions, 'groupByOrder', 'asc'),
    })
  }, [columns, groupedBy, setGroupBy, t])

  const { handleOnRowExpand, additionalTableActions: collapseExpandActions } =
    useCollapseExpandTableActions(tableRef)

  const additionalTableActions = [...collapseExpandActions]

  /**
   * This is a hack to mark the first column as grouped. Analytical Table will
   * always move the 'grouped' column to the first positions and does not like
   * the grouped column to be hidden. This way we have grouped table backgrounds
   * and indicators but do not have to re-create the columns based on grouping
   */
  const groupBy = useMemo(
    () => (grouping.groups.some(({ isGrouped }) => isGrouped) ? ['property'] : []),
    [grouping.groups],
  )

  const { tableData } = useAsTenantTableData({ tenancyCardData, groupedBy, activeSorting })
  const sortedTableData = useMemo(
    () => sortAsTenantTableData({ tableData, activeSorting }),
    [activeSorting, tableData],
  )

  return (
    <FlexBox className={styles.tableWrapper} direction={FlexBoxDirection.Column}>
      <div className={styles.border} />
      <TablesToolbar
        title={t('table-title')}
        grouping={grouping}
        toolbarStyle="Standard"
        additionalActions={additionalTableActions}
        sorting={sorting}
      />
      <AnalyticalTable
        minRows={0}
        rowHeight={70}
        columns={columns}
        overscanCountHorizontal={99}
        data={sortedTableData}
        isTreeTable
        groupBy={groupBy}
        groupable
        scaleWidthMode={AnalyticalTableScaleWidthMode.Smart}
        NoDataComponent={NoDataComponent}
        tableInstance={tableRef}
        loading={isFetching}
        onRowExpandChange={(event) => handleOnRowExpand(event.detail.row)}
        reactTableOptions={{
          autoResetPage: false,
          autoResetExpanded: false,
          autoResetSortBy: false,
          initialState: {},
          autoResetSelectedRows: false,
          autoResetHiddenColumns: false,
          manualSortBy: true,
          manualGroupBy: true,
        }}
        className={`business-partner-tile tree-table ${tableStyles['analytical-table']}`}
      />
    </FlexBox>
  )
}

AsTenantCardTable.propTypes = {
  isFetching: PropTypes.bool,
  tenancyCardData: PropTypes.shape({
    dealProperties: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    propertyDeals: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    rentalUnitsData: PropTypes.shape({}),
    dataByFinancingStatus: PropTypes.objectOf(
      PropTypes.shape({
        matchingProperties: PropTypes.arrayOf(PropTypes.shape({})),
        matchingDeals: PropTypes.arrayOf(PropTypes.shape({})),
        matchingRentalUnits: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    ),
    utilization: PropTypes.shape({
      vacantUtilizationKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
      selfUtilizationKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
  }),
}

export default AsTenantCardTable
