import { useMemo } from 'react'
import { isNotFoundError } from 'api/requests'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useCustomAssessmentVersions from 'hooks/services/central-data/custom-assessments/useCustomAssessmentVersions'
import useDealMini from 'hooks/services/deals/useDealMini'

const entityType = 'DEAL'

const useLatestDealCustomAssessment = ({ dealUuid, assessmentCode, tileId, tileCode }) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({
    dealUuid,
    tileCode,
    isWorkingVersionInfoInSourcePath: true,
  })

  const {
    isFetching: isDealFetching,
    isError: isDealError,
    error: dealError,
    data: dealData,
  } = useDealMini(dealUuidByTileCode)

  const {
    isFetching: isAssessmentFetching,
    isError: isAssessmentError,
    error: assessmentError,
    data: assessmentData,
  } = useCustomAssessmentVersions(
    {
      entityId: dealUuidByTileCode,
      entityType,
      assessmentCode,
    },
    { enabled: !!dealUuidByTileCode },
  )

  const isAssessmentNotFoundError = isAssessmentError && isNotFoundError(assessmentError)

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isDealUuidFetching, isDealFetching, isAssessmentFetching],
    errorValues: [isDealUuidError, isDealError, isAssessmentError && !isAssessmentNotFoundError],
    errorDetails: [dealError, isAssessmentNotFoundError ? undefined : assessmentError],
    tileId,
  })

  const sourcePathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?working-version=true' : ''),
    [isWorkingVersionDataTile],
  )

  const result = useMemo(() => {
    if (isSomeValueLoading || isSomeValueError) {
      return { isLoading: isSomeValueLoading, isError: isSomeValueError, error }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        sourceRender: {
          dealDisplayId: dealData?.dealId,
          sourcePathSuffix,
        },
        text: assessmentData?.versions?.[0]?.text,
      },
    }
  }, [
    isSomeValueLoading,
    isSomeValueError,
    dealData?.dealId,
    sourcePathSuffix,
    assessmentData?.versions,
    error,
  ])

  return result
}

export default useLatestDealCustomAssessment
