import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TILE_VERSION as tileVersion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import AssetValuationOverviewTileViewModeCurrentVersion from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/AssetValuationOverviewTileViewModeCurrentVersion'
import AssetValuationOverviewTileViewModeV1 from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/AssetValuationOverviewTileViewModeV1'

const AssetValuationOverviewTile = ({ tileId, isPdfView }) => {
  const { version } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  return useMemo(() => {
    switch (version) {
      case tileVersion.V1:
        return <AssetValuationOverviewTileViewModeV1 tileId={tileId} isPdfView={isPdfView} />
      default:
        return (
          <AssetValuationOverviewTileViewModeCurrentVersion tileId={tileId} isPdfView={isPdfView} />
        )
    }
  }, [isPdfView, tileId, version])
}

AssetValuationOverviewTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default AssetValuationOverviewTile
