import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash.clonedeep'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { covenantPermissions } from 'api/deals/covenantPermissions'
import { CardHeaderLastEditedText } from 'components/domains/deals/card/CardHeaderLastEditedText'
import MonitoringInformationEdit from 'components/domains/deals/covenants/monitoring-information-card/MonitoringInformationEdit'
import { covenantDefinitionShape } from 'components/domains/deals/covenants/monitoring-information-card/MonitoringInformationPropTypes'
import MonitoringInformationView from 'components/domains/deals/covenants/monitoring-information-card/MonitoringInformationView'
import useValidateMonitoringInformationForm from 'components/domains/deals/covenants/monitoring-information-card/useValidateMonitoringInformationForm'
import { Direction } from 'components/domains/deals/covenants/shared/useDirectionTranslation'
import { temporalUnitEnum } from 'components/domains/deals/covenants/shared/useTemporalUnitTranslation'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import Card from 'components/ui/card/Card'
import { ToggleEditMode } from 'components/ui/card/CardHeaderWithEditMode'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useCovenantMonitoringWithDefault } from 'hooks/services/deals/covenants/monitorings/useCovenantMonitoring'
import useCreateCovenantMonitoring from 'hooks/services/deals/covenants/monitorings/useCreateCovenantMonitoring'
import useUpdateCovenantMonitoring from 'hooks/services/deals/covenants/monitorings/useUpdateCovenantMonitoring'
import useCovenantCheckById from 'hooks/services/deals/covenants/useCovenantCheckById'
import useDeal from 'hooks/services/deals/useDeal'
import { CovenantContext } from 'routes/business-partners/CovenantContext'
import { DealContext } from 'routes/deals/DealContext'
import { DEAL_STATUS_RANKS } from 'routes/deals/financing/financingConstants'

const commentMaxLength = 1000

export const getNewMonitoring = (dealUuid) => ({
  firstDueDate: {
    keyDate: null,
    targetDeliveryDateBaseData: {
      amountToAdd: '',
      direction: Direction.AFTER,
      temporalUnit: temporalUnitEnum.DAYS,
    },
    checkDateBaseData: {
      amountToAdd: '',
      direction: Direction.AFTER,
      temporalUnit: temporalUnitEnum.DAYS,
    },
    deliveryDate: null,
    checkDate: null,
  },
  checkCycle: null,
  comment: null,
  monitoringReferenceEntity: {
    type: 'DEAL',
    id: dealUuid,
  },
})

const MonitoringInformationCard = ({ covenantId, configuration = {} }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.monitoring-card',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const { deal: { dealUuid } = {} } = useContext(DealContext)
  const { allowedOperations: covenantAllowedOperations = [] } = useContext(CovenantContext)
  const isAllowedToUpdateMonitoring = covenantAllowedOperations.includes(
    covenantPermissions.updateCovenantContractMonitoring,
  )

  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const showErrorMessageBox = useShowErrorMessageBox()

  const [isEditMode, setIsEditMode] = useState(false)
  const [formState, setFormState] = useState()
  const [isFormStateInitialized, setIsFormStateInitialized] = useState(false)
  const [isCommentLengthValid, setIsCommentLengthValid] = useState()

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)

  const {
    data: { lastUpdatedBy, lastUpdatedAt, nextCovenantCheckUuid, ...monitoringInformation } = {},
    isLoading: isLoadingMonitoring,
    isFetching: isFetchingMonitoring,
    isError: isErrorMonitoring,
  } = useCovenantMonitoringWithDefault(getNewMonitoring(dealUuid), covenantId)

  const {
    data: covenantCheck,
    isLoading: isLoadingCovenantCheck,
    isFetching: isFetchingCovenantCheck,
    isError: isErrorCovenantCheck,
  } = useCovenantCheckById(nextCovenantCheckUuid)

  const {
    data: { dealRank } = {},
    isFetching: isFetchingDeal,
    isError: isErrorDeal,
  } = useDeal(dealUuid)

  useEffect(() => {
    if (!isFormStateInitialized && !isFetchingMonitoring) {
      setFormState(cloneDeep(monitoringInformation))
      setIsFormStateInitialized(true)
    }
  }, [
    isFetchingCovenantCheck,
    isFetchingMonitoring,
    isFormStateInitialized,
    isLoadingCovenantCheck,
    monitoringInformation,
  ])

  const isFormStateValid = useValidateMonitoringInformationForm({
    monitoringInformation: formState,
  })

  const onSuccess = () => {
    showToast(
      {
        children: t('update.success'),
      },
      document.body,
    )
    queryClient.invalidateQueries('covenants', covenantId, 'monitoring')
    queryClient.invalidateQueries('covenants', 'monitoring', 'items')
    setIsEditMode(false)
    setIsFormStateInitialized(false)
  }
  const onError = async (error) => {
    const { errors: [errorResponse] = [] } = await error.response.json()
    showErrorMessageBox({
      message: t('update.error'),
      error: errorResponse,
    })
  }
  const updateMonitoring = useUpdateCovenantMonitoring({ onSuccess, onError })
  const createMonitoring = useCreateCovenantMonitoring({ onSuccess, onError })

  const onSaveChanges = () => {
    if (monitoringInformation?.covenantMonitoringUuid) {
      return updateMonitoring.mutate({ covenantUuid: covenantId, monitoring: formState })
    }
    return createMonitoring.mutate({ covenantUuid: covenantId, monitoring: formState })
  }

  const onCancelChanges = () => {
    setIsEditMode(false)
    setIsFormStateInitialized(false)
  }

  const shouldShowEdit =
    !isFetchingDeal &&
    !isErrorDeal &&
    dealRank < DEAL_STATUS_RANKS.SIGNED &&
    !isFetchingMonitoring &&
    !(isLoadingCovenantCheck && isFetchingCovenantCheck) &&
    !isErrorMonitoring &&
    !isErrorCovenantCheck &&
    isAllowedToUpdateMonitoring

  return (
    <>
      <Card
        header={
          <CardHeaderLastEditedText
            title={t('title')}
            email={lastUpdatedBy}
            timestamp={lastUpdatedAt}
            actions={
              shouldShowEdit && (
                <ToggleEditMode
                  isEditMode={isEditMode}
                  onToggle={() => setIsEditMode(true)}
                  onSave={() => setIsConfirmationDialogOpen(true)}
                  onCancel={onCancelChanges}
                  buttonIdPrefix="covenant-general-information"
                  checkSaveDisabled={() => !isFormStateValid || !isCommentLengthValid}
                />
              )
            }
          />
        }
      >
        <RequestStateResolver
          center
          isLoading={isLoadingMonitoring || (isLoadingCovenantCheck && isFetchingCovenantCheck)}
          isError={isErrorMonitoring || isErrorCovenantCheck}
          errorToDisplay={<ErrorDataUnavailableInContent />}
          renderContent={() =>
            isEditMode ? (
              <MonitoringInformationEdit
                formState={formState}
                updateFormState={setFormState}
                covenantCheck={covenantCheck}
                commentMaxLength={commentMaxLength}
                configuration={configuration}
                setIsCommentLengthValid={setIsCommentLengthValid}
              />
            ) : (
              <MonitoringInformationView
                monitoringInformation={monitoringInformation}
                covenantCheck={covenantCheck}
              />
            )
          }
        />
      </Card>
      <ConfirmationDialog
        confirmationMessage={`${t('update.save-changes')}\n\n ${t('update.confirmation')}`}
        confirmButtonText={tNoPrefix('buttons.confirm')}
        isOpen={isConfirmationDialogOpen}
        handleConfirmation={onSaveChanges}
        handleCancel={() => setIsConfirmationDialogOpen(false)}
      />
    </>
  )
}

MonitoringInformationCard.propTypes = {
  covenantId: PropTypes.string.isRequired,
  configuration: covenantDefinitionShape,
}

export default MonitoringInformationCard
