import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js'
import { BusyIndicator, Button, ButtonDesign, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styles from 'components/domains/deals/covenants/create-dialog/CreateCovenantDialog.module.css'
import CreateCovenantForm from 'components/domains/deals/covenants/create-dialog/CreateCovenantForm'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import { referenceEntityToDataSource } from 'components/ui/data/ReferenceEntities'
import { ReferenceEntityType } from 'components/ui/data/ReferenceEntityType'
import Dialog, { DialogSize } from 'components/ui/dialog/Dialog'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import useCovenantDefinitions from 'hooks/services/deals/covenants/useCovenantDefinitions'
import useCreateCovenant from 'hooks/services/deals/covenants/useCreateCovenant'
import useTranches from 'hooks/services/deals/covenants/useTranches'
import { DealContext } from 'routes/deals/DealContext'

const CONTRACT_CHECK = 'CONTRACT_CHECK'

const CreateCovenantDialog = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.covenants' })
  const { t: tNoPrefix } = useTranslation('translation')
  const { deal: { dealUuid } = {} } = useContext(DealContext)
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const showToast = Modals.useShowToast()
  const showErrorMessageBox = useShowErrorMessageBox()
  const [isCreating, setIsCreating] = useState(false)

  const referenceEntityTypeData = {
    referenceEntityTypes: [
      { key: ReferenceEntityType.TrancheNewBusiness, value: t('tranche-new-business') },
      { key: ReferenceEntityType.TrancheExistingBusiness, value: t('tranche-existing-business') },
      { key: ReferenceEntityType.BusinessPartner, value: t('business-partner') },
    ],
  }

  const form = useForm({ mode: 'onBlur' })
  const {
    formState: { isValid },
    reset,
    watch,
    handleSubmit,
  } = form

  const onClose = () => {
    reset() // needs to be done twice, otherwise the form is not reset
    setIsOpen(false)
  }

  useEffect(() => {
    if (!isOpen) {
      reset() // needs to be done twice, otherwise the form is not reset
    }
  }, [isOpen, reset])

  const {
    isLoading: isLoadingDefinitions,
    isError: isErrorDefinitions,
    data: { covenantDefinitions = [] } = {},
  } = useCovenantDefinitions()
  const filteredCovenantDefinitions = covenantDefinitions
    .filter(({ deprecated }) => !deprecated)
    .filter(({ covenantDefinitionCheckType }) => covenantDefinitionCheckType === CONTRACT_CHECK)

  const onCreationSuccess = ({ data: { covenantUuid } = {} } = {}) => {
    setIsCreating(false)
    onClose()
    showToast({ children: t('create.success') }, document.body)
    queryClient.invalidateQueries(['covenants', mainEntityTypes.DEAL, dealUuid])
    navigate(`${covenantUuid}?${searchParams.toString()}`)
  }
  const onCreationError = async (error) => {
    setIsCreating(false)
    onClose()
    const { errors: [errorResponse] = [] } = await error.response.json()
    showErrorMessageBox({ message: t('create.error'), error: errorResponse })
  }
  const createCovenant = useCreateCovenant({
    onSuccess: onCreationSuccess,
    onError: onCreationError,
  })

  const onSubmit = async (covenant) => {
    setIsCreating(true)
    createCovenant.mutate({
      covenant: {
        ...covenant,
        mainEntity: { type: mainEntityTypes.DEAL, id: dealUuid },
      },
    })
  }
  const submitForm = handleSubmit(onSubmit)

  const referenceEntityType = watch('referenceEntity.type')
  const dataSource =
    referenceEntityToDataSource.find(({ key }) => referenceEntityType === key)?.dataSource ?? null
  const {
    isLoading: isLoadingTranches,
    isError: isErrorTranches,
    data: { tranches = [] } = {},
  } = useTranches({
    dealUuid,
    dataSource,
    options: { enabled: !!dealUuid && !!dataSource },
  })

  return (
    <Dialog
      id="create-covenant-dialog"
      headerText={t('create.header-title')}
      size={DialogSize.S}
      open={isOpen}
      onBeforeClose={onClose}
      primaryButton={
        <Button
          id="submit-button"
          disabled={!isValid}
          design={ButtonDesign.Emphasized}
          type="submit"
          onClick={submitForm}
        >
          {tNoPrefix('buttons.create')}
        </Button>
      }
    >
      <div className={isCreating ? styles.hidden : ''}>
        <FormProvider {...form}>
          <CreateCovenantForm
            onSubmit={submitForm}
            covenantDefinitions={filteredCovenantDefinitions}
            isLoadingDefinitions={isLoadingDefinitions}
            isErrorDefinitions={isErrorDefinitions}
            tranches={tranches}
            isLoadingTranches={isLoadingTranches}
            isErrorTranches={isErrorTranches}
            referenceEntityTypeData={referenceEntityTypeData}
          />
        </FormProvider>
      </div>

      <BusyIndicator
        delay={0}
        active={isCreating}
        className={isCreating ? styles.spinner : styles.displayNone}
      />
    </Dialog>
  )
}

CreateCovenantDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default CreateCovenantDialog
