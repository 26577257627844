import {
  FlexBox,
  FlexBoxAlignItems,
  Label,
  Toolbar,
  ToolbarDesign,
  ToolbarSeparator,
  ToolbarSpacer,
  ToolbarStyle,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ConditionsTableToolbarFilterDialog from 'components/domains/conditions/overview/ConditionsTableToolbarFilterDialog'
import RequirementsToolbarButtons from 'components/domains/conditions/overview/buttons/requirements/RequirementsToolbarButtons'
import styles from 'components/domains/conditions/overview/requirements/RequirementsTableToolbar.module.css'
import FilterInfoBar from 'components/ui/tables/toolbar/FilterInfoBar'
import tableToolBarStyles from 'components/ui/tables/toolbar/TablesToolbar.module.css'
import ToolbarColumnSelection from 'components/ui/tables/toolbar/ToolbarColumnSelection'
import ToolbarSearching from 'components/ui/tables/toolbar/ToolbarSearching'
import ToolbarSorting from 'components/ui/tables/toolbar/ToolbarSorting'
import { columnSelectionPropTypes } from 'components/ui/tables/toolbar/propTypes'
import { conditionServiceTypes } from 'hooks/services/conditions/tables/useConditionFilterQuery'

const RequirementsTableToolbar = ({
  numberOfRequirements,
  columnSelection,
  setColumnSelection,
  filters,
  setFilters,
  searchParam,
  onUpdateSearchParam,
  sorting,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.toolbar',
  })
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false)
  const isAnyRowInEditMode = useSelector(
    (state) => !!state.conditions.requirementsTable.editedRow.requirementId,
  )

  return (
    <>
      <Toolbar
        design={ToolbarDesign.Auto}
        toolbarStyle={ToolbarStyle.Clear}
        className={tableToolBarStyles.toolbar}
      >
        <Label className={tableToolBarStyles.titleLabel}>
          {t('title', { numberOfRequirements })}
        </Label>
        <ToolbarSpacer />
        <ToolbarSearching
          searching={{ searchParam, onUpdateSearchParam }}
          disabled={isAnyRowInEditMode}
        />
        <RequirementsToolbarButtons disabled={isAnyRowInEditMode} />
        <ToolbarSeparator />
        <FlexBox className={styles.flexToolbarItems} alignItems={FlexBoxAlignItems.Start}>
          <ToolbarSorting sorting={sorting} disabled={isAnyRowInEditMode} />
          <ConditionsTableToolbarFilterDialog
            isOpen={isFilterDialogOpen}
            disabled={isAnyRowInEditMode}
            setIsOpen={setIsFilterDialogOpen}
            filters={filters}
            setFilters={setFilters}
            tableType={conditionServiceTypes.requirements}
          />
          <ToolbarColumnSelection
            columnSelection={columnSelection}
            setColumnSelection={setColumnSelection}
            disabled={isAnyRowInEditMode}
          />
        </FlexBox>
      </Toolbar>
      <FilterInfoBar setIsFilterDialogOpen={setIsFilterDialogOpen} filters={filters} />
    </>
  )
}

RequirementsTableToolbar.propTypes = {
  numberOfRequirements: PropTypes.number.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      columnKey: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
    }),
  ).isRequired,
  setFilters: PropTypes.func.isRequired,
  searchParam: PropTypes.string,
  onUpdateSearchParam: PropTypes.func.isRequired,
  sorting: PropTypes.any.isRequired,
  columnSelection: columnSelectionPropTypes.isRequired,
  setColumnSelection: PropTypes.func.isRequired,
}

export default RequirementsTableToolbar
