import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Icon,
  IconDesign,
  Popover,
  PopoverPlacementType,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/covenants-ratios/shared/BreachCell.module.css'
import { useBreachTranslation } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/covenants-ratios/shared/useBreachTranslations'

const BreachCell = ({ breach, popoverContent }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'components.deals.covenants.monitoring-items-card',
  })

  const { getTranslationForBreach } = useBreachTranslation()

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const id = crypto.randomUUID()

  const text = getTranslationForBreach(breach)
  if (!text) return null

  if (breach === 'NO') return text

  return (
    <div className={styles.container}>
      <span>{text}</span>
      {breach !== 'POSTPONED' && (
        <Button
          id={id}
          design={ButtonDesign.Transparent}
          icon="alert"
          onClick={() => setIsPopoverOpen(true)}
          className={styles.popoverButton}
        />
      )}
      {createPortal(
        <Popover
          id="breach-popover"
          opener={id}
          placementType={PopoverPlacementType.Top}
          open={isPopoverOpen}
          onAfterClose={() => setIsPopoverOpen(false)}
          className={`${styles.popover} ${!popoverContent ? styles.popoverWithoutContent : ''}`}
          header={
            <FlexBox
              justifyContent={FlexBoxJustifyContent.Start}
              alignItems={FlexBoxAlignItems.Center}
              className={styles.header}
            >
              <Icon design={IconDesign.Critical} name="alert" />
              <Title level={TitleLevel.H5} className={styles.title}>
                {t('breach-of-covenant')}
              </Title>
            </FlexBox>
          }
        >
          {popoverContent}
        </Popover>,
        document.body,
      )}
    </div>
  )
}

BreachCell.propTypes = {
  breach: PropTypes.string,
  popoverContent: PropTypes.node,
}

export default BreachCell
