import {
  FlexBox,
  FlexBoxDirection,
  Link,
  LinkDesign,
  Popover,
  PopoverPlacementType,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { Fragment, useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import DecisionPaperLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/links/DecisionPaperLeaveConfirmationLink'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/general-information/DecisionPaperGeneralInformationTile.module.css'
import paths from 'routes/paths'

const dash = '-'
const sponsorsPopoverButtonId = 'sponsors-popover-button'

const GeneralInformationSponsorsField = ({ sponsors, showConfirmationForLinks, isPdfView }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.general-information.tile.fields.sponsors',
  })
  const [isSponsorsOpen, setIsSponsorsOpen] = useState(false)
  const openSponsorsPopover = useCallback(() => setIsSponsorsOpen(true), [])
  const closeSponsorsPopover = useCallback(() => setIsSponsorsOpen(false), [])

  const renderSponsor = useCallback(
    (sponsor) => {
      if (isPdfView) {
        return `${sponsor?.fullName} (${sponsor.id})`
      }
      const link = `/${paths.businessPartners}/${sponsor.id}`
      return (
        <DecisionPaperLeaveConfirmationLink
          link={link}
          name={sponsor.fullName}
          id={sponsor?.id}
          showConfirmationForLinks={showConfirmationForLinks}
        />
      )
    },
    [isPdfView, showConfirmationForLinks],
  )

  if (isEmpty(sponsors)) {
    return <Text>{dash}</Text>
  }
  if (sponsors.length === 1) {
    return renderSponsor(sponsors[0])
  }

  if (isPdfView) {
    return (
      <Text className={styles.sponsorsPdfText}>
        {sponsors.map((sponsor) => renderSponsor(sponsor)).join(', ')}
      </Text>
    )
  }
  return (
    <>
      <Link
        design={LinkDesign.Default}
        accessibleRole="button"
        id={sponsorsPopoverButtonId}
        onClick={openSponsorsPopover}
      >
        {t('more', { sponsorsCount: sponsors.length })}
      </Link>
      {createPortal(
        <Popover
          open={isSponsorsOpen}
          opener={sponsorsPopoverButtonId}
          className={styles.sponsorsPopover}
          placementType={PopoverPlacementType.Bottom}
          onAfterClose={closeSponsorsPopover}
        >
          <FlexBox direction={FlexBoxDirection.Column}>
            {sponsors.map((sponsor) => (
              <Text className={styles.popoverLink} key={sponsor.id}>
                {renderSponsor(sponsor)}
              </Text>
            ))}
          </FlexBox>
        </Popover>,
        document.body,
      )}
    </>
  )
}

GeneralInformationSponsorsField.propTypes = {
  sponsors: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  showConfirmationForLinks: PropTypes.bool,
  isPdfView: PropTypes.bool,
}

export default GeneralInformationSponsorsField
