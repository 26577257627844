import { Title, TitleLevel } from '@fioneer/ui5-webcomponents-react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import CustomAssessments from 'components/domains/custom-assessments/CustomAssessments'
import styles from 'components/domains/properties/general-information/assessment-wrapper/AssessmentWrapperCard.module.css'
import PropertyCommentCard from 'components/domains/properties/general-information/comment/PropertyCommentCard'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useCustomAssessmentsHelper from 'hooks/services/central-data/custom-assessments/useCustomAssessmentsHelper'
import { PropertyContext } from 'routes/properties/PropertyContext'

const AssessmentWrapperCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.general-information',
  })

  const { allowedOperations, property } = useContext(PropertyContext)

  const {
    isFetching: isCustomAssessmentsFetching,
    isError: isCustomAssessmentsError,
    showCustomAssessments,
  } = useCustomAssessmentsHelper({
    entityType: cwpEntityTypes.PROPERTY,
  })

  const isCommentEditAllowed =
    property?.change_request_exist_indicator === false &&
    allowedOperations?.includes('PropertyComment_Update')

  const standardAssessmentCard = (
    <PropertyCommentCard propertyUuid={property.uuid} isEditEnabled={isCommentEditAllowed} />
  )
  // show custom assessments subsection when custom assessments are loading or have an error to display loading/error screen
  const showCustomAssessmentsSubsection =
    showCustomAssessments || isCustomAssessmentsFetching || isCustomAssessmentsError
  const standardAssessmentSubsection = showCustomAssessmentsSubsection ? (
    <>
      <Title level={TitleLevel.H3} className={styles.subsectionTitle}>
        {t('assessments-tab.subsections.standard-assessments.title')}
      </Title>
      {standardAssessmentCard}
    </>
  ) : (
    standardAssessmentCard
  )
  const customAssessmentsSubsection = showCustomAssessmentsSubsection && (
    <>
      <Title level={TitleLevel.H3} className={styles.subsectionTitle}>
        {t('assessments-tab.subsections.custom-assessments.title')}
      </Title>
      <CustomAssessments entityId={property.uuid} entityType={cwpEntityTypes.PROPERTY} />
    </>
  )

  return (
    <>
      {standardAssessmentSubsection}
      {customAssessmentsSubsection}
    </>
  )
}

export default AssessmentWrapperCard
