import { useMemo } from 'react'
import { staffMembersSchema } from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/staff-member/staffMembersSchema'
import useStaffMemberByEmail from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/staff-member/useStaffMemberByEmail'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const UUID_REG_EXP = '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$'
const uuidRegExp = RegExp(UUID_REG_EXP, 'i') // i = ignore case, as the UUID_REG_EXP does not contain A-Z
const isUuid = (idOrEmail) => !!idOrEmail && uuidRegExp.test(idOrEmail)
const isEmail = (idOrEmail) => !!idOrEmail && idOrEmail.includes('@')

const extractFirstStaffMember = (response) => ({
  ...response,
  data: response?.data?.staffMembers[0],
})

/**
 * Get staff members by their objectIds.
 */
const useStaffMemberByObjectIdsOnly = ({ objectIds }, options) => {
  const params = new URLSearchParams()
  const objectIdsString = Array.from(new Set(objectIds ?? [])).join(',')
  const enabled = Array.isArray(objectIds) && objectIds.every((objectId) => isUuid(objectId))
  params.append('searchType', 'OIDS')
  params.append('q', objectIdsString)
  const response = useCamelizedResponse(
    useRequest({
      path: `/users?${params.toString()}`,
      keys: ['users', 'OIDS', objectIdsString],
      options: {
        enabled: enabled,
        retryOnMount: false,
        ...options,
      },
    }),
  )
  return useMemo(() => ({ ...response, data: staffMembersSchema.parse(response.data) }), [response])
}

/**
 * Get a staff member by their objectId.
 */
const useStaffMemberByObjectIdOnly = ({ objectId }, options) =>
  useStaffMemberByObjectIdsOnly({ objectIds: [objectId] }, options)

/**
 * Get a staff member by their id (or for backwards compatibility during a migration phase: by email)
 *
 * CWP-12317: Product Standards: GDPR Personal Data: Retrieval and Deletion
 * Avoid user emails in audit attributes like "changedBy". Use user ids instead.
 *
 * This function temporarily serves as useStaffMemberByObjectId. It is able to either lookup the staffmember by object id or by email.
 * It is designed to work with both object id and email, so the migration from email to object id can be done without
 * a mandatory software update at the same time.
 *
 * Migration Plan:
 * 1. All callers of useStaffMemberByEmail({ email: id }) will be migrated to useStaffMemberById(id, idMayBeAnEmail = true)
 * 2. New "createdBy", "changedBy", ... data will be saved with the user id, not the email anymore.
 * 3. Already existing "createdBy", ... data will be migrated from email to id.
 * --> there will be some time where "changedBy", ... contains emails for old records and ids for new records.
 * This function is intended to be able to deal with both.
 * 4. When emails have been migrated to ids, the flag "idMayBeAnEmail = true" can be removed at the migrated callers.
 * 5. When all emails have been migrated to ids everywhere, this function can be replaced by the implementation:
 * useStaffMemberByObjectIdOnly
 */
const useStaffMemberByObjectIdOrEmail = ({ objectIdOrEmail }, options) => {
  const staffMemberByObjectId = useStaffMemberByObjectIdOnly(
    { objectId: objectIdOrEmail },
    {
      enabled: isUuid(objectIdOrEmail),
      ...options,
    },
  )
  const staffMemberByEmail = useStaffMemberByEmail(
    { email: objectIdOrEmail },
    {
      enabled: isEmail(objectIdOrEmail),
      ...options,
    },
  )
  let staffMember
  if (isUuid(objectIdOrEmail)) {
    staffMember = extractFirstStaffMember(staffMemberByObjectId)
  } else if (isEmail(objectIdOrEmail)) {
    staffMember = extractFirstStaffMember(staffMemberByEmail)
  } else {
    staffMember = {
      isInitialLoading: false,
      isLoading: false,
      isFetching: false,
      isError: true,
    }
  }
  return useMemo(() => staffMember, [staffMember])
}

export default useStaffMemberByObjectIdOrEmail
