import { t } from 'i18next'
import { lazy, Suspense } from 'react'
import { Navigate } from 'react-router-dom'
import NavigationL1 from 'components/ui/navigation/NavigationL1'
import lazyRetry from 'lazyRetry'
import Home from 'routes/Home'
import BusinessEventsAndTasksRoutes from 'routes/business-events-and-tasks/BusinessEventsAndTasksRoutes'
import DecisionPaperPdfExport from 'routes/business-events-and-tasks/decision-paper/pdf-export/DecisionPaperPdfExport'
import DecisionStagePdfExport from 'routes/business-events-and-tasks/decision-process/stage/pdf-export/DecisionStagePdfExport'
import BusinessPartnerRoutes from 'routes/business-partners/BusinessPartnerRoutes'
import DealRoutes from 'routes/deals/DealRoutes'
import DealsOutlet from 'routes/deals/DealsOutlet'
import MarketsRoutes from 'routes/markets/MarketsRoutes'
import paths from 'routes/paths'
import PropertiesRoutes from 'routes/properties/PropertiesRoutes'

const BusinessEventsAndTasksOutlet = lazy(() =>
  lazyRetry(
    () => import('routes/business-events-and-tasks/BusinessEventsAndTasksOutlet'),
    'BusinessEventsAndTasksOutlet',
  ),
)
const BusinessPartnerOutlet = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "BusinessPartnerOutlet" */ 'routes/business-partners/BusinessPartnerOutlet'
      ),
    'BusinessPartnerOutlet',
  ),
)
const DocumentDetailsPage = lazy(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "DocumentDetailsPage" */ 'routes/documents/DocumentDetailsPage'),
    'DocumentDetailsPage',
  ),
)
const RightDetailsPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "RightDetailsPage" */ 'routes/rights/RightDetailsPage'),
    'RightDetailsPage',
  ),
)
const CollateralDetails = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "CollateralDetails" */ 'routes/deals/collaterals/details/CollateralDetails'
      ),
    'CollateralDetails',
  ),
)
const Entitlements = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "Entitlements" */ 'routes/entitlements/Entitlements'),
    'Entitlements',
  ),
)
const MarketsOutlet = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "MarketsOutlet" */ 'routes/markets/MarketsOutlet'),
    'MarketsOutlet',
  ),
)
const PropertiesOutlet = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "PropertiesOutlet" */ 'routes/properties/PropertiesOutlet'),
    'PropertiesOutlet',
  ),
)

const UserProfile = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "UserProfile" */ 'routes/user-profile/UserProfile'),
    'UserProfile',
  ),
)

const getRoutes = () => [
  {
    element: <NavigationL1 />,
    path: '/',
    children: [
      { path: paths.dashboard, index: true, element: <Navigate to={paths.home} /> },
      { path: paths.home, element: <Home /> },
      {
        path: paths.properties,
        element: (
          <Suspense>
            <PropertiesOutlet />
          </Suspense>
        ),
        children: PropertiesRoutes,
      },
      {
        path: paths.deals,
        element: <DealsOutlet />,
        children: DealRoutes,
      },
      {
        path: paths.entitlements,
        element: (
          <Suspense>
            <Entitlements />
          </Suspense>
        ),
      },
      {
        path: paths.businessPartners,
        element: (
          <Suspense>
            <BusinessPartnerOutlet />
          </Suspense>
        ),
        children: BusinessPartnerRoutes,
      },
      {
        path: `${paths.businessEventsAndTasks}/*`,
        element: (
          <Suspense>
            <BusinessEventsAndTasksOutlet />
          </Suspense>
        ),
        children: BusinessEventsAndTasksRoutes,
      },
      {
        path: paths.notifications,
        element: <div>{t('navigation.item.title.notifications')}</div>,
      },
      {
        path: paths.userProfile,
        element: (
          <Suspense>
            <UserProfile />
          </Suspense>
        ),
      },
      {
        path: paths.otherApps,
        element: <div>{t('navigation.item.title.other-applications')}</div>,
      },
      {
        path: paths.markets,
        element: (
          <Suspense>
            <MarketsOutlet />
          </Suspense>
        ),
        children: MarketsRoutes,
      },
      {
        path: paths.documents,
        element: (
          <Suspense>
            <DocumentDetailsPage />
          </Suspense>
        ),
      },
      {
        path: paths.collaterals,
        element: (
          <Suspense>
            <CollateralDetails />
          </Suspense>
        ),
      },
      {
        path: paths.rights,
        element: (
          <Suspense>
            <RightDetailsPage />
          </Suspense>
        ),
      },
      { path: '*', element: <div>{t('navigation.item.title.404')}</div> },
    ],
  },
  {
    element: <DecisionPaperPdfExport />,
    path: paths.decisionPaperPdfExport,
  },

  {
    element: <DecisionStagePdfExport />,
    path: paths.decisionStagePdfExport,
  },
]

export default getRoutes
