import { Button, ButtonDesign, FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { neededOperationsForDecisionPaperUpdate } from 'api/events/eventAllowedOperations'
import { hasUserRequiredOperations } from 'api/helper'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/with-meta-data/shared/actions/TileHeaderEditActions.module.css'
import CancelButtonWithPopover from 'components/ui/button/CancelButtonWithPopover'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

export const hasDecisionPaperUpdatePermissions = (allowedOperations) =>
  hasUserRequiredOperations(neededOperationsForDecisionPaperUpdate, allowedOperations)

const ExternalConditionsTileHeaderEditActions = ({
  isEditMode,
  setIsEditMode,
  initialContent,
  setCurrentContent,
  hasChanges,
  onSaveClicked,
  isExpanded,
  expand,
}) => {
  const { allowedOperations } = useContext(BusinessEventsAndTasksContext)
  const { t: tNoPrefix } = useTranslation()

  const onEditClicked = useCallback(() => {
    setIsEditMode(true)
  }, [setIsEditMode])

  const showEditButton = useMemo(
    () => hasDecisionPaperUpdatePermissions(allowedOperations),
    [allowedOperations],
  )

  const handleEditClicked = useCallback(
    (clickEvent) => {
      if (!isExpanded) expand()
      onEditClicked(clickEvent)
    },
    [isExpanded, expand, onEditClicked],
  )

  const editButton = useMemo(
    () =>
      showEditButton && (
        <Button
          key="tile-edit-button"
          onClick={handleEditClicked}
          design={ButtonDesign.Transparent}
        >
          {tNoPrefix('buttons.edit')}
        </Button>
      ),
    [handleEditClicked, tNoPrefix, showEditButton],
  )

  const onCancelClicked = useCallback(() => {
    setIsEditMode(false)
    setCurrentContent(initialContent)
  }, [initialContent, setIsEditMode, setCurrentContent])

  const cancelButton = useMemo(
    () => <CancelButtonWithPopover onCancelClicked={onCancelClicked} showPopover={hasChanges} />,
    [onCancelClicked, hasChanges],
  )

  const isSaveButtonDisabled = useMemo(() => !hasChanges, [hasChanges])

  const saveButton = useMemo(
    () => (
      <Button
        key="save-button"
        onClick={onSaveClicked}
        design={ButtonDesign.Emphasized}
        disabled={isSaveButtonDisabled}
      >
        {tNoPrefix('buttons.save')}
      </Button>
    ),
    [onSaveClicked, tNoPrefix, isSaveButtonDisabled],
  )

  return isEditMode ? (
    <FlexBox direction={FlexBoxDirection.Row} className={styles.flexBox}>
      {saveButton}
      {cancelButton}
    </FlexBox>
  ) : (
    editButton
  )
}

ExternalConditionsTileHeaderEditActions.propTypes = {
  tileId: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  setIsEditMode: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialContent: PropTypes.any,
  setCurrentContent: PropTypes.func.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  expand: PropTypes.func.isRequired,
}

export default ExternalConditionsTileHeaderEditActions
