import { Link, LinkDesign, MessageBoxActions } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'

const useIndividualVoteCheck = ({ successText, successTitle, queriesToInvalidate, linkTo }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'decision-stage.vote-options.voter.success',
  })
  const queryClient = useQueryClient()
  const showMessageBox = useShowMessageBox()

  const onLinkClick = useCallback(() => {
    window.open(linkTo, '_blank')
  }, [linkTo])

  const onSuccess = useCallback(
    async ({ data: response }) => {
      queryClient.invalidateQueries(queriesToInvalidate)
      if (response?.anonymous_overarching_vote_error) {
        showMessageBox({
          type: MessageBoxTypes.Error,
          children: (
            <span>
              {t('overarching-vote-error.before-link')}
              <Link onClick={onLinkClick} design={LinkDesign.Default}>
                {t('overarching-vote-error.link')}
              </Link>
              {t('overarching-vote-error.after-link')}
            </span>
          ),
        })
        return
      }
      showMessageBox({
        type: MessageBoxTypes.Success,
        titleText: successTitle,
        children: successText,
        actions: [MessageBoxActions.OK],
      })
    },
    [onLinkClick, queriesToInvalidate, queryClient, showMessageBox, successText, successTitle, t],
  )

  return useMemo(
    () => ({
      onSuccess,
    }),
    [onSuccess],
  )
}

export default useIndividualVoteCheck
