import {
  Button,
  ButtonDesign,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
} from '@fioneer/ui5-webcomponents-react'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useShowDealErrorCodeMessageBox from 'components/domains/deals/creation-dialog/useShowDealErrorCodeMessageBox'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useDeleteDealAdjustmentWorkingVersion from 'hooks/services/deals/deal-adjustment/useDeleteDealAdjustmentWorkingVersion'
import { DealContext } from 'routes/deals/DealContext'
import useNavigateToWorkingVersion from 'routes/deals/useNavigateToWorkingVersion'

const defaultMessageBoxProps = {
  draggable: true,
  resizable: true,
}

/**
 * Hook for deleting working version of a deal
 */
export const useDeleteWorkingVersion = () => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.deal-adjustment.delete',
  })

  const switchToWorkingVersion = useNavigateToWorkingVersion()

  const showToast = Modals.useShowToast()
  const showMessageBox = useShowMessageBox()
  const showDealErrorCodeMessageBox = useShowDealErrorCodeMessageBox()

  const {
    deal: { dealUuid },
  } = useContext(DealContext)

  const deleteWorkingVersion = useDeleteDealAdjustmentWorkingVersion({
    onSuccess: (response) => {
      // show any warnings
      if (response.data.warnings?.length) {
        showDealErrorCodeMessageBox({
          warnings: response.data.warnings,
          type: MessageBoxTypes.Warning,
        })
      }

      // success actions
      showToast({
        children: t('success-toast-deleted'),
      })
      switchToWorkingVersion(WorkingVersionType.LIVE, true)
    },
    onError: async (error) => {
      const { errors: [errorResponse] = [] } = await error.response.json()
      showDealErrorCodeMessageBox({
        warnings: [errorResponse],
        fallbackWarningMessage: t('error-text-deleted'),
        type: MessageBoxTypes.Error,
      })
    },
  })

  const onDeleteConfirmed = useCallback(() => {
    deleteWorkingVersion.mutate({
      dealUuid: dealUuid,
    })
  }, [deleteWorkingVersion, dealUuid])

  const onDelete = useCallback(() => {
    showMessageBox({
      ...defaultMessageBoxProps,
      type: MessageBoxTypes.Warning,
      titleText: t('delete-modal.title'),
      children: t('delete-modal.text'),
      actions: [
        <Button
          id="button-delete-confirm"
          key="button-delete"
          design={ButtonDesign.Emphasized}
          onClick={onDeleteConfirmed}
        >
          {tNoPrefix('buttons.delete')}
        </Button>,
        <Button
          key="button-cancel"
          data-action={MessageBoxActions.Cancel}
          design={ButtonDesign.Transparent}
        >
          {tNoPrefix('buttons.cancel')}
        </Button>,
      ],
    })
  }, [showMessageBox, t, tNoPrefix, onDeleteConfirmed])

  return {
    onDelete,
  }
}
