import { FlexBox, FlexBoxDirection, Link, Text } from '@fioneer/ui5-webcomponents-react'
import { object, string } from 'prop-types'
import { useTranslation } from 'react-i18next'

export const DealCreationBusinessPartnerLink = ({
  businessPartner: { bpId, fullName } = {},
  additionalInfo,
  ...props
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.dialog.business-partner-link',
  })

  if (bpId) {
    return (
      <FlexBox direction={FlexBoxDirection.Column} {...props}>
        {
          <Link href={`/business-partners/${bpId}`} target="_blank">
            {fullName}
          </Link>
        }
        <Text>{bpId}</Text>
        {additionalInfo && <Text>{additionalInfo}</Text>}
      </FlexBox>
    )
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column} {...props}>
      <Text>{fullName}</Text>
      <Text>{t('new-businessPartner')}</Text>
    </FlexBox>
  )
}

DealCreationBusinessPartnerLink.propTypes = {
  businessPartner: object,
  additionalInfo: string,
}
