import { useQuery } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const OK = 200

const useMultiPropertySegmentKpis = (
  propertyUuidsWithKeyDatesAndSegments,
  { currency, areaUnitOfMeasurement, withTenantGranularity = false } = {},
) => {
  const { post } = useAccessTokenRequest()
  const { data, isLoading, isError } = useQuery({
    queryKey: [
      'segment-kpis',
      ...propertyUuidsWithKeyDatesAndSegments.map(({ property_uuid }) => property_uuid),
      ...propertyUuidsWithKeyDatesAndSegments.map(({ segment_uuids }) => segment_uuids),
      currency,
      areaUnitOfMeasurement,
      ...propertyUuidsWithKeyDatesAndSegments.map(({ key_dates }) => key_dates),
      withTenantGranularity,
    ],
    refetchOnWindowFocus: false,
    queryFn: () =>
      post({
        path: `/properties/kpis/multi-property-segment`,
        body: {
          property_uuid_to_key_dates_and_segments: propertyUuidsWithKeyDatesAndSegments,
          currency: currency,
          area_unit_of_measurement: areaUnitOfMeasurement,
          with_tenant_granularity: withTenantGranularity,
        },
      }),
    retry: 0,
  })
  if (isLoading || isError) return { isLoading, isError, data: null }
  return {
    data: data?.data?.property_rent_roll_by_segments_kpis ?? [],
    isError: data?.status !== OK,
    isLoading: false,
  }
}

export default useMultiPropertySegmentKpis
