import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import TenancyHeaderPopover from 'components/domains/business-partners/tile/tenancy/TenancyHeaderPopover'
import styles from 'components/domains/business-partners/tile/tenancy/TenancyLabelValueList.module.css'
import ReferenceEntities from 'components/ui/data/ReferenceEntities'
import { ReferenceEntityType } from 'components/ui/data/ReferenceEntityType'

const labelClassName = (bold) => (bold ? 'sapTextColor sapFontBoldFamily' : 'sapContent_LabelColor')

const renderLabel = (label, isCaption) => (
  <Label
    className={[labelClassName(isCaption), ...(isCaption ? [styles.captionPadding] : [])].join(' ')}
  >
    {label}
  </Label>
)

const hyphen = '-'

const TenancyLabelValueList = ({ rows, financingStatus }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.tenancy.as-tenant',
  })

  const renderValue = useCallback((kpiName, kpiInfo, status, caption, entityIds) => {
    if (entityIds) {
      return (
        <ReferenceEntities
          type={ReferenceEntityType.BusinessPartner}
          ids={entityIds}
          entityClassName={styles.entityAlign}
        />
      )
    }
    if (caption) {
      return <div className={styles.emptyRowHeight} />
    }
    if (isEmpty(kpiInfo.segmentUsageTypes)) {
      return <Text className={styles.textAlign}>{hyphen}</Text>
    }
    return (
      <TenancyHeaderPopover
        kpiName={kpiName}
        kpiValue={kpiInfo.kpiValue}
        kpiValueType={kpiInfo.kpiValueType}
        segmentUsageTypes={kpiInfo.segmentUsageTypes}
        status={status}
      />
    )
  }, [])

  return (
    <>
      {rows.map(({ caption, activeDeal, pipelineDeal, entityIds }, index) => (
        <FlexBox
          key={`label-value-card-row-${index}`}
          direction={FlexBoxDirection.Row}
          justifyContent={FlexBoxJustifyContent.SpaceBetween}
        >
          <FlexBox direction={FlexBoxDirection.Column}>
            {renderLabel(caption.kpiName, true)}
            {activeDeal && renderLabel(t('linked-deal.active'))}
            {pipelineDeal && renderLabel(t('linked-deal.pipeline'))}
          </FlexBox>
          <FlexBox direction={FlexBoxDirection.Column}>
            {renderValue(caption.kpiName, undefined, undefined, true, entityIds)}
            {activeDeal && renderValue(caption.kpiName, activeDeal, financingStatus?.active)}
            {pipelineDeal && renderValue(caption.kpiName, pipelineDeal, financingStatus?.pipeline)}
          </FlexBox>
        </FlexBox>
      ))}
    </>
  )
}

TenancyLabelValueList.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.shape({
        kpiName: PropTypes.string.isRequired,
        value: PropTypes.string,
      }).isRequired,
      activeDeal: PropTypes.shape({
        kpiValue: PropTypes.number.isRequired,
        segmentUsageTypes: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
          }),
        ),
      }),
      pipelineDeal: PropTypes.shape({
        kpiValue: PropTypes.number.isRequired,
        segmentUsageTypes: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
          }),
        ),
      }),
    }),
  ).isRequired,
  financingStatus: PropTypes.shape({
    active: PropTypes.shape({
      code: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }).isRequired,
    pipeline: PropTypes.shape({
      code: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }).isRequired,
  }),
}

export default TenancyLabelValueList
