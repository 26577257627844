import {
  DateRangePicker,
  FilterGroupItem,
  Input,
  Option,
  Select,
} from '@fioneer/ui5-webcomponents-react'
import { useEffect, useMemo, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { entityTypes as possibleEntityTypes } from 'api/events/status'
import AssigneeFilter from 'components/domains/business-events-and-tasks/events/filterbar/AssigneeFilter'
import BusinessPartnerMultiInput from 'components/domains/business-events-and-tasks/events/filterbar/BusinessPartnerMultiInput'
import DealMultiInput from 'components/domains/business-events-and-tasks/events/filterbar/DealMultiInput'
import {
  mapEntityTypeFilters,
  parseDueDateRange,
  useReferenceFilterHandler,
} from 'components/domains/business-events-and-tasks/events/filterbar/EventsAndTasksFilterUtils'
import PropertyMultiInput from 'components/domains/business-events-and-tasks/events/filterbar/PropertyMultiInput'
import TaskStatusComboBox from 'components/domains/business-events-and-tasks/tasks/table/filterbar/TaskStatusComboBox.jsx'
import DashboardFilterBar from 'components/ui/page/DashboardFilterBar'
import countActiveFilters from 'components/ui/page/countActiveFilters'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const mappedEntityTypes = [
  possibleEntityTypes.property,
  possibleEntityTypes.businesspartner,
  possibleEntityTypes.deal,
]

const filterOptions = {
  searchString: { visibleOnBar: true },
  taskAssigneeId: { visibleOnBar: true },
  mandatory: { visibleOnBar: false },
  originalDueDateRange: { visibleOnBar: true },
  currentDueDateRange: { visibleOnBar: true },
  taskStatus: { visibleOnBar: true },
  eventEntityRefType: { visibleOnBar: false },
  deal: { visibleOnBar: true },
  property: { visibleOnBar: true },
  businessPartner: { visibleOnBar: true },
}

const setInitialFilterState = (queryParams) => ({
  search_string: queryParams.get('search_string') || '',
  task_status: queryParams.get('task_status') || '',
  mandatory: queryParams.get('mandatory') || '',
  original_due_date_range: queryParams.get('original_due_date_range') || '',
  current_due_date_range: queryParams.get('current_due_date_range') || '',
  event_entity_ref_type: queryParams.get('event_entity_ref_type') || '',
  task_assignee: queryParams.get('task_assignee') || '',
  bp_ids: queryParams.get('bp_ids') || '',
  property_ids: queryParams.get('property_ids') || '',
  deal_ids: queryParams.get('deal_ids') || '',
})

const isEnterButtonEvent = (event) => event.code === 'Enter'

const TasksFilterBar = () => {
  const navigate = useNavigate()
  const { hash } = useLocation()
  const { t } = useTranslation('translation', { keyPrefix: 'components.tasks.filter-bar' })
  const { t: eventEntityTypeTranslation } = useTranslation('translation', {
    keyPrefix: 'events.entity-type',
  })
  const [queryParams] = useSearchParams()
  const setQueryParams = (newQueryParams) => {
    //the useSearchParams implementation of setQueryParams removes the hash
    const searchParams = new URLSearchParams(Object.entries(newQueryParams))
    navigate(`?${searchParams.toString()}${hash}`)
  }
  const [filterParams, setFilterParams] = useState(setInitialFilterState(queryParams))
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false)
  const { localePattern, format, parse } = useShortDateFormatter()
  const taskStatusList = useMemo(
    () => (filterParams.task_status ? filterParams.task_status.split(',') : []),
    [filterParams.task_status],
  )

  useEffect(() => {
    setFilterParams(setInitialFilterState(queryParams))
  }, [queryParams])

  const mappedEntityTypesOptions = mappedEntityTypes.map((entityType, index) => (
    <Option
      key={`entity-type-${entityType}-${index}`}
      value={entityType}
      selected={filterParams.entity_type === entityType}
    >
      {eventEntityTypeTranslation(entityType)}
    </Option>
  ))

  const onKeyDown = (event) => {
    if (isEnterButtonEvent(event) && !isFilterDialogOpen) {
      setQueryParams(filterParams)
    }
  }

  const onFilter = () => {
    setQueryParams(filterParams)
  }

  const formatDateRangePickerValue = (due_date) => {
    const dueDateRange = queryParams.get(due_date)
    if (!dueDateRange) return
    const { startDate, endDate } = parseDueDateRange(dueDateRange)
    return `${format(startDate)} - ${format(endDate)}`
  }

  const { handleSearchDialogSelectionChanges, onClearSelections, onDeleteReferences } =
    useReferenceFilterHandler({
      setFilterParams,
      filterParams,
    })

  const getBpFilters = useCallback(
    () => mapEntityTypeFilters(filterParams.bp_ids, cwpEntityTypes.BUSINESS_PARTNER),
    [filterParams],
  )

  const getPropertyFilters = useCallback(
    () => mapEntityTypeFilters(filterParams.property_ids, cwpEntityTypes.PROPERTY),
    [filterParams],
  )

  const getDealFilters = useCallback(
    () => mapEntityTypeFilters(filterParams.deal_ids, cwpEntityTypes.DEAL),
    [filterParams],
  )

  const filterBarItems = [
    <FilterGroupItem label={t('search')} key="searchString">
      <Input
        id="tasks-overview-search-input"
        value={filterParams.search_string}
        onInput={({ target: { value: searchString } }) => {
          setFilterParams({ ...filterParams, search_string: searchString })
        }}
        onKeyDown={onKeyDown}
        showClearIcon
      />
    </FilterGroupItem>,
    <FilterGroupItem key="mandatory" label={t('mandatory.label')}>
      <Select
        id="tasks-overview-mandatory-select"
        onChange={({
          detail: {
            selectedOption: { value: mandatory },
          },
        }) => {
          setFilterParams({ ...filterParams, mandatory })
        }}
        value={filterParams.mandatory}
      >
        <Option key="all" value="all" />
        <Option key="mandatory" value="mandatory" selected={filterParams.mandatory === 'mandatory'}>
          {t('mandatory.mandatory')}
        </Option>
        <Option
          key="non-mandatory"
          value={'non-mandatory'}
          selected={filterParams.mandatory === 'non-mandatory'}
        >
          {t('mandatory.non-mandatory')}
        </Option>
      </Select>
    </FilterGroupItem>,
    <FilterGroupItem key="businessPartner" label={t('bp-filter')}>
      <BusinessPartnerMultiInput
        selectedReferences={getBpFilters()}
        handleOnChange={handleSearchDialogSelectionChanges}
        onDeleteReferences={onDeleteReferences}
        onClearSelections={onClearSelections}
      />
    </FilterGroupItem>,
    <FilterGroupItem key="deal" label={t('deal-filter')}>
      <DealMultiInput
        handleOnChange={handleSearchDialogSelectionChanges}
        selectedReferences={getDealFilters()}
        onDeleteReferences={onDeleteReferences}
        onClearSelections={onClearSelections}
      />
    </FilterGroupItem>,
    <FilterGroupItem key="property" label={t('property-filter')}>
      <PropertyMultiInput
        handleOnChange={handleSearchDialogSelectionChanges}
        selectedReferences={getPropertyFilters()}
        onDeleteReferences={onDeleteReferences}
        onClearSelections={onClearSelections}
      />
    </FilterGroupItem>,
    <FilterGroupItem key="eventEntityRefType" label={t('entity-type')}>
      <Select
        id="tasks-overview-entity-type-select"
        onChange={({
          detail: {
            selectedOption: { value: eventEntityRefType },
          },
        }) => {
          setFilterParams({ ...filterParams, event_entity_ref_type: eventEntityRefType })
        }}
        value={filterParams.event_entity_ref_type}
      >
        {[<Option key={-1} value={''} />, ...mappedEntityTypesOptions]}
      </Select>
    </FilterGroupItem>,
    <FilterGroupItem key="taskAssigneeId" label={t('assignee')}>
      <AssigneeFilter
        onAssigneeChange={(assignee) =>
          setFilterParams({
            ...filterParams,
            task_assignee: assignee,
          })
        }
        assigneeId={filterParams.task_assignee}
        displayPlaceholderText={false}
      />
    </FilterGroupItem>,
    <FilterGroupItem key="originalDueDateRange" label={t('original-due-date')}>
      <DateRangePicker
        id="tasks-overview-original-date-range-picker"
        placeholder=""
        formatPattern={localePattern}
        onChange={({ detail: { value: dueDateRange } }) => {
          if (!dueDateRange) {
            setFilterParams({
              ...filterParams,
              original_due_date_range: '',
            })
            return
          }
          const { startDate, endDate } = parseDueDateRange(dueDateRange)
          const ISOdueDateStart = parse(startDate, localePattern)
          const ISOdueDateEnd = parse(endDate, localePattern)
          setFilterParams({
            ...filterParams,
            original_due_date_range: `${ISOdueDateStart} - ${ISOdueDateEnd}`,
          })
        }}
        value={formatDateRangePickerValue('original_due_date_range')}
      />
    </FilterGroupItem>,
    <FilterGroupItem key="currentDueDateRange" label={t('current-due-date')}>
      <DateRangePicker
        id="tasks-overview-current-date-range-picker"
        placeholder=""
        formatPattern={localePattern}
        onChange={({ detail: { value: dueDateRange } }) => {
          if (!dueDateRange) {
            setFilterParams({
              ...filterParams,
              current_due_date_range: '',
            })
            return
          }
          const { startDate, endDate } = parseDueDateRange(dueDateRange)
          const ISOdueDateStart = parse(startDate, localePattern)
          const ISOdueDateEnd = parse(endDate, localePattern)
          setFilterParams({
            ...filterParams,
            current_due_date_range: `${ISOdueDateStart} - ${ISOdueDateEnd}`,
          })
        }}
        value={formatDateRangePickerValue('current_due_date_range')}
      />
    </FilterGroupItem>,
    <FilterGroupItem key="taskStatus" label={t('task-status')}>
      <TaskStatusComboBox
        id="tasks-overview-task-status-select"
        selected={taskStatusList}
        onSelectionChange={(selectedStatusValues) => {
          setFilterParams({
            ...filterParams,
            task_status: selectedStatusValues.join(','),
          })
        }}
        onKeyDown={onKeyDown}
      />
    </FilterGroupItem>,
  ]

  const activeFiltersCount = useMemo(() => countActiveFilters(filterParams), [filterParams])

  return (
    <DashboardFilterBar
      filterOptions={filterOptions}
      activeFiltersCount={activeFiltersCount}
      onGo={onFilter}
      setDialogOpen={setIsFilterDialogOpen}
    >
      {filterBarItems}
    </DashboardFilterBar>
  )
}

export default TasksFilterBar
