import camelize from 'camelize'
import { useRequest } from 'hooks/services/baseService'

const useBusinessPartnerRatings = (businessPartnerId) => {
  const response = useRequest({
    path: `/businesspartners/${businessPartnerId}/ratings`,
    keys: ['businesspartners', businessPartnerId, 'ratings'],
    options: {
      enabled: !!businessPartnerId,
    },
  })

  // TODO: either flatten all types here or none
  const dataWithFlattenedActionTypes = response.data
    ? {
        ...response.data,
        external: response.data.external.map((externalRating) =>
          externalRating.action_type
            ? { ...externalRating, action_type: externalRating.action_type.name }
            : externalRating,
        ),
      }
    : undefined

  return {
    ...response,
    data: camelize(dataWithFlattenedActionTypes),
  }
}

export default useBusinessPartnerRatings
