import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import useCurrencies from 'hooks/services/collaterals/useCurrencies'

const propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string,
  }),
  onChange: PropTypes.func,
}
/**
 * @typedef {Parameters<typeof Select>[0]} extends
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof extends> & extends} props
 */
const CagCurrencyInput = ({ value, onChange, ...props }) => {
  const { t } = useTranslation('decisionPaper', { keyPrefix: 'components.input' })
  const { data: { currencies = [] } = {}, isLoading, isError, isSuccess } = useCurrencies()

  return (
    <Select onChange={onChange} {...props}>
      {isLoading && (
        <Option key={'loading'} selected>
          {t('loading')}
        </Option>
      )}
      {isError && (
        <Option key={'error'} selected>
          {t('error')}
        </Option>
      )}
      {isSuccess && <Option key={''} selected={!value?.id} />}
      {currencies?.map(({ id }) => (
        <Option key={id} data-id={id} selected={id === value?.id}>
          {id}
        </Option>
      ))}
    </Select>
  )
}

CagCurrencyInput.displayName = 'CagCurrencyInput'
CagCurrencyInput.propTypes = propTypes

export default CagCurrencyInput
