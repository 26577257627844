import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useAdjustmentStatusTranslation = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.change-overview.adjustment-history',
  })
  /**
   * @enum
   */
  const adjustmentStatusEnum = useMemo(
    () => ({
      DELETED: t('status.deleted'),
      PUBLISHED: t('status.published'),
    }),
    [t],
  )
  const getTranslationForAdjustmentStatus = (adjustment) => adjustmentStatusEnum[adjustment] ?? ''

  return { getTranslationForAdjustmentStatus, adjustmentStatusEnum }
}
