import { useMemo } from 'react'
import { useCollateralAgreementCategories } from 'hooks/services/collaterals/useCollateralAgreementCategories'
import { useCollateralAgreementTypes } from 'hooks/services/collaterals/useCollateralAgreementTypes'

export const useMappedCollateralAgreementTypes = () => {
  const {
    data: { types = [] } = {},
    isLoading: isTypesLoading,
    isError: isTypesError,
  } = useCollateralAgreementTypes()
  const {
    data: { items: categories = [] } = {},
    isLoading: isCategoriesLoading,
    isError: isCategoriesError,
  } = useCollateralAgreementCategories()

  const cagTypes = useMemo(
    () =>
      categories.map((category) => [
        { ...category, isGroupHeader: true },
        ...types.filter(({ categoryId }) => categoryId === category.id),
      ]),
    [types, categories],
  ).flat()

  return {
    isLoading: isTypesLoading || isCategoriesLoading,
    isError: isTypesError || isCategoriesError,
    data: { cagTypes },
  }
}
