import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  ResponsiveGridLayout,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/borrower/BorrowerOverviewTile.module.css'
import SponsorGuarantorOverviewLabeledValue from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/SponsorGuarantorOverviewLabeledValue'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const SponsorGuarantorOverviewRatings = ({
  businessPartner: { internalRating, externalRating },
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.bp-borrower-overview.ratings',
  })
  const { format } = useShortDateFormatter()

  const content = useMemo(() => {
    if (isNil(internalRating) && isNil(externalRating)) {
      return (
        <FlexBox
          className={styles.listCardEmptyContent}
          alignItems={FlexBoxAlignItems.Center}
          justifyContent={FlexBoxJustifyContent.Center}
        >
          {t('empty')}
        </FlexBox>
      )
    }
    return (
      <ResponsiveGridLayout columnsXL={2} columnsL={2} columnsM={2} columnsS={2}>
        {!isNil(internalRating) && (
          <FlexBox direction={FlexBoxDirection.Column}>
            {!isNil(internalRating?.class) && (
              <SponsorGuarantorOverviewLabeledValue
                label={t('internal.class')}
                value={internalRating?.class}
              />
            )}
            {!isNil(internalRating.method) && (
              <SponsorGuarantorOverviewLabeledValue
                label={t('internal.method')}
                value={internalRating?.method}
              />
            )}
            {!isNil(internalRating.validFrom) && (
              <SponsorGuarantorOverviewLabeledValue
                label={t('internal.valid-from')}
                value={format(internalRating?.validFrom)}
              />
            )}
          </FlexBox>
        )}
        {!isNil(externalRating) && (
          <FlexBox direction={FlexBoxDirection.Column}>
            {!isNil(externalRating.grade) && (
              <SponsorGuarantorOverviewLabeledValue
                label={t('external.grade')}
                value={externalRating.grade}
              />
            )}
            {!isNil(externalRating.agency) && (
              <SponsorGuarantorOverviewLabeledValue
                label={t('external.agency')}
                value={externalRating.agency}
              />
            )}
            {!isNil(externalRating.validFrom) && (
              <SponsorGuarantorOverviewLabeledValue
                label={t('external.valid-from')}
                value={format(externalRating.validFrom)}
              />
            )}
          </FlexBox>
        )}
      </ResponsiveGridLayout>
    )
  }, [externalRating, internalRating, format, t])

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.listCard}>
      <Text className={styles.listCardTitle}>{t('title')}</Text>
      {content}
    </FlexBox>
  )
}

SponsorGuarantorOverviewRatings.propTypes = {
  businessPartner: PropTypes.shape({
    internalRating: PropTypes.shape({
      validFrom: PropTypes.string,
      class: PropTypes.string,
      method: PropTypes.string,
    }),
    externalRating: PropTypes.shape({
      validFrom: PropTypes.string,
      grade: PropTypes.string,
      agency: PropTypes.string,
    }),
  }),
}

export default SponsorGuarantorOverviewRatings
