import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useManualTileLoader = ({ eventId, tileId, version }) => {
  const currentVersion = version ? version : 'current'
  return useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/decision-paper/versions/${currentVersion}/tiles/${tileId}/manual`,
      useCache: true,
      keys: [
        'events',
        eventId,
        'decision-paper',
        'versions',
        currentVersion,
        'tiles',
        tileId,
        'manual',
      ],
    }),
  )
}

export default useManualTileLoader
