import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styles from 'components/domains/properties/valuation/table/table-cells/ValuationRequestCell.module.css'
import Entity from 'components/ui/data/Entity'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import { valuationRowHasError } from 'redux/slices/valuation/valuationRequestsTableSlice'
import paths from 'routes/paths'

const ValuationRequestBusinessPartnerDisplay = ({ valuerId, valuationRequestId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.valuation.requests.table.row',
  })
  const {
    data: businessPartnerMinis,
    isLoading,
    isError,
  } = useBusinessPartnerMiniByIds([`${valuerId}`])
  const businessPartner = businessPartnerMinis?.businessPartnerMinis?.find(
    (businessPartnerMini) => businessPartnerMini.id === valuerId,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (isError) {
      dispatch(valuationRowHasError({ id: valuationRequestId, error: { hasError: true } }))
    }
  }, [isError, dispatch, valuationRequestId])

  const errorComponent = useMemo(
    () => (
      <Text wrapping className={styles.error}>
        {t('error')}
      </Text>
    ),
    [t],
  )

  const renderCell = useCallback(
    () => (
      <Entity
        name={businessPartner.fullName}
        link={`/${paths.businessPartners}/${valuerId}`}
        openInNewTab
      />
    ),
    [businessPartner?.fullName, valuerId],
  )

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderCell}
      error={errorComponent}
    />
  )
}

ValuationRequestBusinessPartnerDisplay.propTypes = {
  valuerId: PropTypes.string,
  valuationRequestId: PropTypes.string.isRequired,
}

export default ValuationRequestBusinessPartnerDisplay
