import { Button, ButtonDesign, MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import styles from 'hooks/services/conditions/requirements/helper/useShowRequirementRefNrUpdateConfirmation.module.css'

const useShowRequirementRefNrUpdateConfirmation = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.requirements.ref-number-update',
  })
  const showMessageBox = useShowMessageBox()

  const openConfirmationDialog = useCallback(
    ({ onConfirm, refNumber, onReject, body }) => {
      const internalBody = body ?? t('text', { refNumber })

      showMessageBox({
        type: MessageBoxTypes.Confirm,
        children: internalBody,
        titleText: t('title'),
        className: styles.messageBox,
        actions: [
          <Button
            key="ref-number-update-confirm"
            onClick={onConfirm}
            design={ButtonDesign.Emphasized}
          >
            {t('button.confirm')}
          </Button>,
          <Button key="ref-number-update-reject" onClick={onReject}>
            {t('button.reject')}
          </Button>,
        ],
      })
    },
    [showMessageBox, t],
  )

  return useMemo(
    () => ({
      openConfirmationDialog,
    }),
    [openConfirmationDialog],
  )
}

export default useShowRequirementRefNrUpdateConfirmation
