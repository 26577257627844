import PropTypes from 'prop-types'
import ConditionsDealContextProvider from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/ConditionsDealContextProvider'
import InternalConditionsTileEditMode from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/internal/InternalConditionsTileEditMode'
import InternalConditionsTileViewMode from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/internal/InternalConditionsTileViewMode'

const shownColumns = ['name', 'refNumber', 'type', 'approvalLevel', 'assignee', 'status']

const shownSubcomponents = {
  description: true,
}

const subcomponentHeight = 66

const InternalConditionsTile = ({ isEditMode, isPdfView, ...tileProps }) => (
  <ConditionsDealContextProvider>
    {isEditMode && !isPdfView ? (
      <InternalConditionsTileEditMode
        {...tileProps}
        shownColumns={shownColumns}
        shownSubcomponents={shownSubcomponents}
        subcomponentHeight={subcomponentHeight}
      />
    ) : (
      <InternalConditionsTileViewMode
        {...tileProps}
        shownColumns={shownColumns}
        isPdfView={isPdfView}
        shownSubcomponents={shownSubcomponents}
        subcomponentHeight={subcomponentHeight}
      />
    )}
  </ConditionsDealContextProvider>
)

InternalConditionsTile.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default InternalConditionsTile
