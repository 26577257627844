import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import TableInputCell from 'components/domains/conditions/overview/table-cells/view/TableInputCell'
import useConditionsCellEditMode from 'hooks/services/conditions/edit-mode/useConditionsCellEditMode'
import {
  conditionNameChanged,
  conditionNameOnBlur,
} from 'redux/slices/conditions/conditionsTableSlice'

const ConditionsTableNameCell = ({ value: { name, conditionId } }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.columns.name',
  })

  const dispatch = useDispatch()
  const { isEditModeForCurrentRow } = useConditionsCellEditMode({
    conditionId,
  })
  const editedConditionName = useSelector(
    (state) => state.conditions.conditionsTable.editedRow.currentValues?.name,
  )
  const hasNameError = useSelector(
    (state) => !!state.conditions.conditionsTable.editedRow.errorFields?.name,
  )

  const onInput = useCallback(
    (newName) => {
      dispatch(conditionNameChanged({ name: newName }))
    },
    [dispatch],
  )

  const onBlur = useCallback(() => {
    dispatch(conditionNameOnBlur())
  }, [dispatch])

  return (
    <TableInputCell
      input={isEditModeForCurrentRow ? editedConditionName : name}
      isEditMode={isEditModeForCurrentRow}
      onBlur={onBlur}
      onInput={onInput}
      hasError={hasNameError}
      placeholder={t('placeholder')}
      errorText={t('error')}
    />
  )
}

ConditionsTableNameCell.propTypes = {
  value: PropTypes.shape({
    conditionId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

export default ConditionsTableNameCell
