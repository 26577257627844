import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const PAGINATION_LIMIT = 50

const useChangeLogs = ({
  dealUuid,
  filter: { entityId, entityType, cardId, pageId, actionType } = {},
  sort: { field, direction } = {},
  pagination: { offset, limit } = {},
  options = {},
}) => {
  const queryParams = new URLSearchParams()
  if (entityId) queryParams.append('filter_entity_id', entityId)
  if (entityType) queryParams.append('filter_entity_type', entityType)
  if (cardId) queryParams.append('filter_card_id', cardId)
  if (pageId) queryParams.append('filter_page_id', pageId)
  if (actionType) queryParams.append('filter_action', actionType)
  if (field) queryParams.append('sort_field', field)
  if (direction) queryParams.append('sort_direction', direction)
  if (offset) queryParams.append('offset', offset)
  if (limit) queryParams.append('limit', limit)

  const request = useRequest({
    path: `/traceability/deal/${dealUuid}/change-logs?${queryParams.toString()}`,
    translated: true,
    useCache: false,
    keys: ['deal', dealUuid, 'traceability-of-changes', queryParams.toString()],
    options,
  })

  return useCamelizedResponse(request)
}

export default useChangeLogs
