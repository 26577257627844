import '@ui5/webcomponents-icons/dist/error.js'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import RequirementRemindersTable from 'components/domains/conditions/dialogs/requirements/reminders/RequirementRemindersTable'
import Dialog, { DialogSize, DialogSecondaryButton } from 'components/ui/dialog/Dialog'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useRequirementReminders from 'hooks/services/conditions/requirements/reminders/useRequirementReminders'

const limit = 20

const RequirementRemindersDialog = ({ isOpen, setIsOpen, requirementId, requirementStatus }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.requirements.reminders' })
  const { t: tNoPrefix } = useTranslation('translation')

  const {
    isLoading,
    isError,
    data: reminderData,
    fetchNextPage,
  } = useRequirementReminders(
    {
      requirementId,
    },
    { limit },
  )

  const renderRemindersTable = useCallback(
    () => (
      <RequirementRemindersTable
        reminders={reminderData.reminders}
        totalReminders={reminderData.total}
        requirementId={requirementId}
        requirementStatus={requirementStatus}
        onLoadMore={fetchNextPage}
      />
    ),
    [fetchNextPage, reminderData, requirementId, requirementStatus],
  )

  const closeDialog = useCallback(() => setIsOpen(false), [setIsOpen])

  const onCancel = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  return (
    <Dialog
      open={isOpen}
      onAfterClose={onCancel}
      headerText={t('title')}
      size={DialogSize.L}
      closeButton={
        <DialogSecondaryButton id="reminder-dialog-close-btn" onClick={closeDialog}>
          {tNoPrefix('buttons.close')}
        </DialogSecondaryButton>
      }
    >
      <RequestStateResolver
        isLoading={isLoading}
        isError={isError}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        renderContent={renderRemindersTable}
      />
    </Dialog>
  )
}

RequirementRemindersDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  requirementId: PropTypes.string.isRequired,
  requirementStatus: PropTypes.string,
}

export default RequirementRemindersDialog
