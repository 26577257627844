import { DateTime } from 'luxon'

export const SETTINGS = {
  DEFAULT_ZOOM: { x1: 0, x2: 0 },
  ASPECT: 1.63,
  MIN_WIDTH: 235,
  MIN_HEIGHT: 144,
  MAX_HEIGHT: 144,
  GREEN: '#198244',
  RED: '#dc0c0f',
  YAXIS_WIDTH: 90,
  STROKE_DASH: '5 4',
  BASELINE_COLOR: 'orange',
  DEFAULT_Y_TICK_COUNT: 10,
  DEFAULT_X_TICK_COUNT: 10,
}

export const calculateZoomTimestamp = (zoomPoint, width, timestamps) =>
  Math.round(timestamps.start + (zoomPoint / width) * (timestamps.end - timestamps.start))

export const calculateZoomAreaWidth = (zoomAreaSelection) =>
  zoomAreaSelection.x2 >= zoomAreaSelection.x1
    ? zoomAreaSelection.x2 - zoomAreaSelection.x1
    : zoomAreaSelection.x1 - zoomAreaSelection.x2

const convertToTimestamp = (date) => DateTime.fromISO(date).toMillis()

export const enrichKpiDataWithValidityDateTimeStamp = (kpiData) => {
  if (!kpiData) return

  return kpiData.map(({ value, validity_date, receipt_date, unit }) => ({
    value,
    validity_date,
    receipt_date,
    unit,
    timestamp: convertToTimestamp(validity_date),
  }))
}

export const isBaseLineValueOrClosestToBaseLineValue = ({ index, baselineDate, data } = {}) => {
  // !index not possible since with index 0 this would result to enter the if statement which is not wanted
  if (index === undefined || !baselineDate || !data || data.length === 0) {
    return false
  }

  const baselineTimestamp = convertToTimestamp(baselineDate)
  const { timestamp: currentTimestamp, receipt_date: currentReceiptDate } = data[index]
  const currentReceiptTimestamp = convertToTimestamp(currentReceiptDate)
  if (currentReceiptTimestamp > baselineTimestamp) {
    return false
  }

  if (currentTimestamp === baselineTimestamp) {
    return true
  }

  if (currentTimestamp < baselineTimestamp) {
    const nextDataPoint = data[index + 1]
    if (!nextDataPoint) {
      return true
    }
    const { timestamp: nextTimestamp, receipt_date: nextReceiptDate } = nextDataPoint
    const nextReceiptDateTimestamp = convertToTimestamp(nextReceiptDate)

    if (nextTimestamp <= baselineTimestamp) {
      if (nextReceiptDateTimestamp > baselineTimestamp) {
        return true
      }
      return false
    }
    return true
  }

  return false
}
