import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import MultipleFactSheetValuationHistoryV1 from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-history/MultipleFactSheetValuationHistoryV1'
import MultipleFactSheetValuationHistoryV2 from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-history/MultipleFactSheetValuationHistoryV2'
import MultipleDealSelectionPdfViewWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/wrapper/MultipleDealSelectionPdfViewWrapper'
import { TILE_VERSION } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'

const MultipleFactSheetValuationHistoryDealSelectionWrapper = ({ tileId, isPdfView }) => {
  const { version } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const contentCreator = useCallback(
    ({ selectedDealIndex }) => {
      switch (version) {
        case TILE_VERSION.V1:
          return (
            <MultipleFactSheetValuationHistoryV1
              tileId={tileId}
              selectedDealIndex={selectedDealIndex}
              isPdfView={isPdfView}
            />
          )
        default:
          return (
            <MultipleFactSheetValuationHistoryV2
              tileId={tileId}
              selectedDealIndex={selectedDealIndex}
              isPdfView={isPdfView}
            />
          )
      }
    },
    [isPdfView, tileId, version],
  )
  return (
    <MultipleDealSelectionPdfViewWrapper
      tileId={tileId}
      contentCreator={contentCreator}
      isPdfView={isPdfView}
    />
  )
}

MultipleFactSheetValuationHistoryDealSelectionWrapper.propTypes = {
  tileId: PropTypes.string,
  isPdfView: PropTypes.bool,
}

export default MultipleFactSheetValuationHistoryDealSelectionWrapper
