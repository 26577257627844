import { List, ListMode, ListSeparators, StandardListItem } from '@fioneer/ui5-webcomponents-react'
import debounce from 'lodash.debounce'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/with-meta-data/cash-flow/CashFlowScenarioSelectDialog.module.css'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'
import Card from 'components/ui/card/Card'
import Dialog, { DialogPrimaryButton, DialogSecondaryButton } from 'components/ui/dialog/Dialog'
import ToolbarSearching from 'components/ui/tables/toolbar/ToolbarSearching'
import { useCashflowScenarios } from 'hooks/services/deals/cashflow/useCashflowScenarios'

const CashFlowScenarioSelectDialog = ({
  isSelectDialogOpen,
  setIsSelectDialogOpen,
  handleSaveSelectedScenario,
  dealUuid,
  cashFlowScenarioContent,
  setCashFlowScenarioContent,
  hasChanges,
  lastSelectedScenario,
  tCashFlow,
}) => {
  const { t } = useTranslation()
  const {
    data: { cashflowScenarios = [] } = {},
    isLoading: isCashflowScenariosLoading,
    isError: isCashflowScenariosError,
  } = useCashflowScenarios(dealUuid)

  const cashflowScenariosList = useMemo(
    () => (!isCashflowScenariosLoading && !isCashflowScenariosError ? cashflowScenarios : []),
    [cashflowScenarios, isCashflowScenariosError, isCashflowScenariosLoading],
  )

  const { format: formatDate } = useShortDateFormatter()
  const [searchParam, setSearchParam] = useState('')
  const onAfterClose = () => setIsSelectDialogOpen(false)

  const filteredCashFlowScenarios = useMemo(
    () =>
      searchParam.trim().length > 0
        ? cashflowScenariosList.filter((scenario) =>
            scenario.name.toLowerCase().match(searchParam.toLowerCase()),
          )
        : cashflowScenariosList,
    [cashflowScenariosList, searchParam],
  )

  const toolbarSearching = useMemo(() => {
    const debounceValue = 300
    return {
      searchParam: '',
      onUpdateSearchParam: debounce((updatedValue) => {
        setSearchParam(updatedValue)
      }, debounceValue),
    }
  }, [])

  const handleSelectionChange = ({ detail: { item } }) => {
    const selectScenarioUuid = item.getAttribute('data-scenario-uuid')
    const selectScenarioName = item.getAttribute('data-scenario-name')
    if (selectScenarioUuid) {
      setCashFlowScenarioContent({
        selectedCashFlowScenarioUuid: selectScenarioUuid,
        selectedCashFlowScenarioName: selectScenarioName,
      })
    } else {
      setCashFlowScenarioContent()
    }
  }

  const handleNewScenarioSelected = useCallback(() => {
    setIsSelectDialogOpen(false)
    handleSaveSelectedScenario()
  }, [handleSaveSelectedScenario, setIsSelectDialogOpen])

  const onCloseButtonClick = useCallback(() => {
    setIsSelectDialogOpen(false)
    setCashFlowScenarioContent(lastSelectedScenario)
  }, [lastSelectedScenario, setIsSelectDialogOpen, setCashFlowScenarioContent])

  return (
    <Card>
      <Dialog
        headerText={tCashFlow('header')}
        open={isSelectDialogOpen}
        onAfterClose={onAfterClose}
        closeButton={
          <DialogSecondaryButton onClick={onCloseButtonClick}>
            {t('buttons.cancel')}
          </DialogSecondaryButton>
        }
        primaryButton={
          <DialogPrimaryButton onClick={handleNewScenarioSelected} disabled={!hasChanges}>
            {t('buttons.select')}
          </DialogPrimaryButton>
        }
      >
        <ToolbarSearching className={styles.toolbarSearching} searching={toolbarSearching} />
        <List
          onItemClick={handleSelectionChange}
          mode={ListMode.SingleSelectBegin}
          separators={ListSeparators.Inner}
          className={styles.list}
          busy={isCashflowScenariosLoading}
        >
          <StandardListItem
            className={styles.noScenarioSelected}
            selected={!cashFlowScenarioContent?.selectedCashFlowScenarioUuid}
          >
            {tCashFlow('empty-selection')}
          </StandardListItem>
          {filteredCashFlowScenarios.map((scenario) => (
            <StandardListItem
              key={scenario.cashflowScenarioUuid}
              data-scenario-uuid={scenario.cashflowScenarioUuid}
              data-scenario-name={scenario.name}
              description={scenario.description}
              additionalText={formatDate(scenario.lastUpdatedAt)}
              selected={
                !!cashFlowScenarioContent &&
                cashFlowScenarioContent?.selectedCashFlowScenarioUuid ===
                  scenario.cashflowScenarioUuid
              }
            >
              {scenario.name}
            </StandardListItem>
          ))}
        </List>
      </Dialog>
    </Card>
  )
}

CashFlowScenarioSelectDialog.propTypes = {
  isSelectDialogOpen: PropTypes.bool.isRequired,
  setIsSelectDialogOpen: PropTypes.func.isRequired,
  handleSaveSelectedScenario: PropTypes.func,
  dealUuid: PropTypes.string,
  cashFlowScenarioContent: PropTypes.shape({
    selectedCashFlowScenarioUuid: PropTypes.string,
    selectedCashFlowScenarioName: PropTypes.string,
  }),
  setCashFlowScenarioContent: PropTypes.func,
  hasChanges: PropTypes.bool,
  lastSelectedScenario: PropTypes.shape({
    selectedCashFlowScenarioUuid: PropTypes.string,
    selectedCashFlowScenarioName: PropTypes.string,
  }),
  tCashFlow: PropTypes.func,
}

export default CashFlowScenarioSelectDialog
