import { Input, Text } from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useAnalyticalTableIndexHelper from 'hooks/services/conditions/tables/useAnalyticalTableIndexHelper'
import useFocusEffect from 'hooks/services/conditions/tables/useFocusEffect'
import {
  conditionNameChanged,
  requirementNameChanged,
  requirementRowHasError,
  conditionRowHasError,
} from 'redux/slices/conditions/conditionCreationSlice'

const ConditionCreationNameCell = ({
  row: {
    id,
    index,
    depth,
    original: { name: currentName },
  },
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.dialog.external-condition-creation.table.name-cell',
  })
  const dispatch = useDispatch()
  const inputRef = useRef()

  const { isConditionsCell, requirementIndex, conditionIndex } = useAnalyticalTableIndexHelper({
    id,
    index,
    depth,
  })

  useFocusEffect({ conditionIndex, requirementIndex, componentRef: inputRef, fieldName: 'name' })

  const placeholderText = useMemo(
    () => (isConditionsCell ? t('placeholder.condition') : t('placeholder.requirement')),
    [isConditionsCell, t],
  )

  const onInput = useCallback(
    ({ target: { value: name } }) => {
      if (isConditionsCell) {
        dispatch(conditionNameChanged({ conditionIndex, name }))
        return
      }
      dispatch(requirementNameChanged({ conditionIndex, requirementIndex, name }))
    },
    [dispatch, isConditionsCell, conditionIndex, requirementIndex],
  )

  const onBlur = useCallback(
    ({ target: { value: name } }) => {
      if (name === '') {
        if (isConditionsCell) {
          dispatch(
            conditionRowHasError({
              conditionIndex,
              field: 'name',
              error: { warning: false },
              showError: true,
            }),
          )
          return
        }
        dispatch(
          requirementRowHasError({
            conditionIndex,
            requirementIndex,
            field: 'name',
            error: {
              warning: false,
            },
            showError: true,
          }),
        )
      }
    },
    [isConditionsCell, dispatch, conditionIndex, requirementIndex],
  )

  const hasInputError = useSelector((state) => {
    if (isConditionsCell) {
      return state.conditions.conditionsCreation.errorRows[conditionIndex]?.name
    }
    return state.conditions.conditionsCreation.errorRows[conditionIndex]?.[requirementIndex]?.name
  })

  const showInputError = useSelector((state) => {
    if (isConditionsCell) {
      return state.conditions.conditionsCreation.errorRows[conditionIndex]?.name?.showError
    }
    return state.conditions.conditionsCreation.errorRows[conditionIndex]?.[requirementIndex]?.name
      ?.showError
  })

  // This is a hack for the React Table. It seems like react table listens to the space event and stops
  // the propagation of this event to other components like the input. With this the event is not handed to
  // other components but the input which solves the behaviour for us.
  const onKeyDown = useCallback((event) => {
    if (event.code === 'Space' || event.code === 'ArrowRight' || event.code === 'ArrowLeft') {
      event.stopPropagation()
    }
  }, [])

  return (
    <Input
      ref={inputRef}
      onInput={onInput}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      placeholder={placeholderText}
      value={currentName}
      valueState={hasInputError && showInputError ? ValueState.Error : ValueState.None}
      valueStateMessage={
        <Text>{isConditionsCell ? t('conditions.error') : t('requirements.error')}</Text>
      }
    />
  )
}

ConditionCreationNameCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    depth: PropTypes.number.isRequired,
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default ConditionCreationNameCell
