import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import ReferencesSelectionComponent, {
  ReferencesSelectionComponentRenderDirections,
} from 'components/domains/conditions/reference-selection/ReferencesSelectionComponent'

const ReferencesMultiSelectionFilter = ({ value: references, setSelectedFilters, columnKey }) => {
  const onReferencesChanged = useCallback(
    (selectedReferences) => {
      setSelectedFilters((currentlySelectedFilters) =>
        currentlySelectedFilters.map((filter) => {
          if (filter.columnKey !== columnKey) {
            return filter
          }
          const newReferences = isEmpty(selectedReferences)
            ? undefined
            : {
                entityType: selectedReferences.entityType,
                entityIds: selectedReferences.entityIds,
              }
          return { ...filter, value: newReferences }
        }),
      )
    },
    [columnKey, setSelectedFilters],
  )
  return (
    <ReferencesSelectionComponent
      references={references}
      onReferencesChanged={onReferencesChanged}
      renderDirection={ReferencesSelectionComponentRenderDirections.normal}
    />
  )
}

export default ReferencesMultiSelectionFilter

ReferencesMultiSelectionFilter.propTypes = {
  value: PropTypes.shape({
    entityIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    entityType: PropTypes.string.isRequired,
  }),
  setSelectedFilters: PropTypes.func.isRequired,
  columnKey: PropTypes.string.isRequired,
}
