import DocumentTypes from 'components/domains/documents/DocumentTypes'

/**
 * @param {object} entity
 * @param {string} entity.id
 * @param {typeof DocumentTypes[keyof typeof DocumentTypes]} entity.type
 */
const entityToLink = ({ id, type }) => {
  switch (type) {
    case DocumentTypes.Property: {
      return `/properties/${id}`
    }
    case DocumentTypes.BusinessPartner: {
      return `/business-partners/${id}`
    }
    case DocumentTypes.Deal: {
      return `/deals/${id}`
    }
    default: {
      throw new RangeError(`Unsupported type: ${type}`)
    }
  }
}

export default entityToLink
