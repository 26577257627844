import {
  DynamicPage,
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import { getObjectStatusForEventStatus, taskStatus as possibleTaskStatus } from 'api/events/status'
import BusinessObjectLink from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import DueDateWarningMessageStrip from 'components/domains/business-events-and-tasks/common/DueDateWarningMessageStrip'
import CommentSectionLoadingWrapper from 'components/domains/business-events-and-tasks/events/comment-section/CommentSectionLoadingWrapper'
import TasksWatchersButton from 'components/domains/business-events-and-tasks/tasks/watchers/TasksWatchersButton'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Header from 'components/ui/page/Header'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useTask from 'hooks/services/business-events-and-tasks/tasks/useTask'
import { useTaskComments } from 'hooks/services/business-events-and-tasks/tasks/useTaskComments'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import TaskDescriptionCard from 'routes/business-events-and-tasks/tasks/TaskDescriptionCard'
import styles from 'routes/business-events-and-tasks/tasks/TaskDetail.module.css'
import TaskGeneralInformationCard from 'routes/business-events-and-tasks/tasks/TaskGeneralInformationCard'
import paths from 'routes/paths'

const defaultLimit = 10

const CustomObjectReference = ({ entityType, id, name, hasError }) => {
  if (hasError) {
    return null
  }
  return <EntityTypeAndIdWithClipboard entityType={entityType} name={name} id={id} />
}
CustomObjectReference.propTypes = {
  name: PropTypes.string,
  entityType: PropTypes.string,
  id: PropTypes.string,
  hasError: PropTypes.bool,
}

const TaskDetail = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.task-detail' })
  const { t: tWithoutPrefix } = useTranslation()
  const [limit, setLimit] = useState(defaultLimit)
  const { allowedOperations } = useContext(BusinessEventsAndTasksContext)

  const { taskId, eventId } = useParams()
  const { isError, isLoading, data: taskData } = useTask({ eventId, taskId })
  const {
    data: comments,
    isLoading: isLoadingComments,
    isError: isErrorComments,
  } = useTaskComments(eventId, taskId, { limit, offset: 0 })

  const breadcrumbs = useMemo(
    () => [
      {
        text: t('event-manager.header'),
        href: `/${paths.businessEventsAndTasks}`,
      },
      {
        text: taskData ? taskData.event.info.name : t('loading-event-name'),
        href: `/${paths.businessEventsAndTasks}/business-events/${eventId}`,
      },
      {
        text: taskData ? taskData.info.name : t('loading-task-name'),
      },
    ],
    [eventId, t, taskData],
  )

  const { translationKey: statusTranslationKey, objectStatus } = getObjectStatusForEventStatus(
    taskData ? taskData.event.status : '',
  )

  const taskReadAllowed = useMemo(
    () => allowedOperations?.includes(businessEventAllowedOperations.readTask),
    [allowedOperations],
  )
  const taskUpdateAllowed = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.updateTask),
    [allowedOperations],
  )
  const eventAutomaticallyUpdateAllowed = useMemo(
    () =>
      allowedOperations.includes(businessEventAllowedOperations.automaticallyCreatedUpdateEvent),
    [allowedOperations],
  )

  const watcherButton = useMemo(
    () =>
      taskReadAllowed &&
      !isLoading &&
      !isError && <TasksWatchersButton eventId={eventId} taskId={taskId} />,
    [eventId, taskReadAllowed, isLoading, isError, taskId],
  )

  const actionButtons = useMemo(() => <>{watcherButton}</>, [watcherButton])

  const subtitle = useMemo(
    () => (
      <BusinessObjectLink
        entityId={taskData?.event.entityRef.entityId}
        entityType={taskData?.event.entityRef.entityType}
        CustomComponent={CustomObjectReference}
        isLoading={isLoading}
      />
    ),
    [isLoading, taskData?.event.entityRef.entityId, taskData?.event.entityRef.entityType],
  )
  const headerTitle = useMemo(
    () => (
      <Header
        title={taskData ? taskData.event.info.name : t('loading-event-name')}
        subtitle={subtitle}
        breadcrumbs={breadcrumbs}
        actions={actionButtons}
        status={
          taskData
            ? { text: tWithoutPrefix(statusTranslationKey), valueState: objectStatus }
            : { text: '' }
        }
      />
    ),
    [
      breadcrumbs,
      objectStatus,
      statusTranslationKey,
      subtitle,
      t,
      tWithoutPrefix,
      taskData,
      actionButtons,
    ],
  )

  const onLoadMoreComments = useCallback(() => {
    setLimit(limit + defaultLimit)
  }, [limit])

  const determineInformationEditability = () => {
    if (!taskUpdateAllowed) return false
    return (
      taskData.status !== possibleTaskStatus.aborted &&
      taskData.status !== possibleTaskStatus.completed
    )
  }

  const isTaskDescriptionEditable = useCallback(() => {
    if (!taskUpdateAllowed) return false
    if (taskData.event.info.created === 'AUTOMATED' && !eventAutomaticallyUpdateAllowed)
      return false
    return (
      taskData.status !== possibleTaskStatus.aborted &&
      taskData.status !== possibleTaskStatus.completed
    )
  }, [
    eventAutomaticallyUpdateAllowed,
    taskData?.event.info.created,
    taskData?.status,
    taskUpdateAllowed,
  ])

  const determineErrorScreen = () => {
    if (!taskReadAllowed) {
      return (
        <IllustratedMessage
          name={IllustrationMessageType.UnableToLoad}
          size={IllustrationMessageSize.Spot}
          titleText={tWithoutPrefix('app.permission-error.title')}
          subtitleText={tWithoutPrefix('app.permission-error.subtitle')}
        />
      )
    }
    return (
      <IllustratedMessage
        name={IllustrationMessageType.UnableToLoad}
        size={IllustrationMessageSize.Spot}
        titleText={t('loading-error.title')}
        subtitleText={t('loading-error.description')}
      />
    )
  }

  if (!taskReadAllowed || isError) {
    return (
      <DynamicPage
        id="market-overview-dynamic-page"
        showHideHeaderButton={false}
        headerContentPinnable={false}
        headerTitle={headerTitle}
      >
        {determineErrorScreen()}
      </DynamicPage>
    )
  }

  const renderContent = () => (
    <>
      <DueDateWarningMessageStrip
        dueDate={taskData.info.currentDueDate}
        className={styles['due-date-warning']}
        taskStatus={taskData.status}
      />
      <CWPLayout>
        <TaskGeneralInformationCard
          taskId={taskId}
          eventId={eventId}
          eventCreationAction={taskData.event.info.created}
          name={taskData.info.name}
          isMandatory={taskData.info.isMandatory}
          assignee={taskData.info.assignee}
          currentDueDate={taskData.info.currentDueDate}
          originalDueDate={taskData.info.originalDueDate}
          status={taskData.status}
          eventStatus={taskData.event.status}
          isEditable={determineInformationEditability()}
        />
        <TaskDescriptionCard
          taskId={taskId}
          eventId={eventId}
          description={taskData.info.description}
          isEditable={isTaskDescriptionEditable()}
        />
        <CommentSectionLoadingWrapper
          eventId={eventId}
          taskId={taskId}
          onLoadMoreComments={onLoadMoreComments}
          comments={comments}
          isLoading={isLoadingComments}
          isError={isErrorComments}
          allowedOperations={allowedOperations}
        />
      </CWPLayout>
    </>
  )

  return (
    <DynamicPage
      id="market-overview-dynamic-page"
      showHideHeaderButton={false}
      headerContentPinnable={false}
      headerTitle={headerTitle}
    >
      <LoadingStateWrapper isError={isError} isLoading={isLoading} renderContent={renderContent} />
    </DynamicPage>
  )
}

export default TaskDetail
