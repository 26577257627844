import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/sponsor/AsSponsorCard.module.css'
import AsSponsorCardCommitmentField from 'components/domains/business-partners/tile/sponsor/AsSponsorCardCommitmentField'
import LabelValueList from 'components/ui/data/LabelValueList'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useDealSponsorOverview from 'hooks/services/deals/sponsor/useDealSponsorOverview'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

const AsSponsorCardOverview = () => {
  const businessPartnerId = useContext(BusinessPartnerContext)?.id
  const {
    data: { totalDealCommitment, totalDealCommitmentHeadquarter, totalBorrowers, totalDeals } = {},
    isLoading,
    isError,
  } = useDealSponsorOverview(businessPartnerId) || {}

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.sponsor.overview',
  })

  const labelValueMap = [
    {
      label: t('number-of-borrowers'),
      value: String(totalBorrowers ?? '-'),
    },
    {
      label: t('number-of-deals'),
      value: String(totalDeals ?? '-'),
    },
  ]

  return (
    <LoadingStateWrapper isLoading={isLoading} isError={isError}>
      <div className={styles.grid}>
        <AsSponsorCardCommitmentField
          commitment={totalDealCommitment}
          commitmentHeadquarter={totalDealCommitmentHeadquarter}
        />
        <LabelValueList rows={labelValueMap} />
      </div>
    </LoadingStateWrapper>
  )
}

export default AsSponsorCardOverview
