import { MessageStrip } from '@fioneer/ui5-webcomponents-react'
import { formatDate } from '@storybook/blocks'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useRentRoll } from 'hooks/services/properties/useRentRoll'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyRentRollComparisonMessageBox = () => {
  const { t: tComparison } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.comparison',
  })

  const { property } = useContext(PropertyContext)
  const rentRollToCompare = useSelector(
    (state) => state.properties.compareRentRoll.rentRollToCompare,
  )

  const {
    isLoading: isLoadingRentRoll,
    isError: isErrorRentRoll,
    data: currentRentRoll,
  } = useRentRoll(property.uuid)

  const comparisonKeyDate = useMemo(() => rentRollToCompare?.keyDate, [rentRollToCompare])

  return (
    <RequestStateResolver
      isLoading={isLoadingRentRoll}
      isError={isErrorRentRoll}
      center={true}
      renderContent={() => (
        <MessageStrip type="Information" hideCloseButton={true}>
          {rentRollToCompare?.index &&
            tComparison('in-comparison', {
              currentKeyDate: formatDate(currentRentRoll?.key_date),
              comparisonKeyDate: formatDate(comparisonKeyDate),
            })}
          {!rentRollToCompare?.index && tComparison('not-in-comparison')}
        </MessageStrip>
      )}
    />
  )
}

export default PropertyRentRollComparisonMessageBox
