import {
  BusyIndicator,
  BusyIndicatorSize,
  Button,
  ButtonDesign,
  CustomListItem,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  ListItemType,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import orderBy from 'lodash.orderby'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useStaffMemberByMultipleIds from 'hooks/services/business-partners/staff-members/useStaffMemberByMultipleIds'

const useWatcherStaffMembers = (watcherIds) => {
  const staffMembers = useStaffMemberByMultipleIds(watcherIds)

  const isSomeLoading = useMemo(
    () => staffMembers.some(({ isLoading }) => isLoading),
    [staffMembers],
  )
  const isSomeError = useMemo(() => staffMembers.some(({ isError }) => isError), [staffMembers])
  const mappedWatchers = useMemo(() => staffMembers.map(({ data }) => data || {}), [staffMembers])

  return useMemo(
    () => ({ isSomeLoading, isSomeError, mappedWatchers }),
    [isSomeLoading, isSomeError, mappedWatchers],
  )
}

const WatcherMenuWatchers = ({ watchers, onUserUnsubscribe, userId }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.ui.watcher' })
  const watcherIds = useMemo(
    () => watchers.map(({ userId: watcherUserId }) => watcherUserId),
    [watchers],
  )

  const { isSomeLoading, mappedWatchers } = useWatcherStaffMembers(watcherIds)

  const onDeleteInternal = useCallback(
    (watcherId) => () => {
      onUserUnsubscribe(watcherId)
    },
    [onUserUnsubscribe],
  )

  const mappedAndSortedWatchers = useMemo(() => {
    if (isSomeLoading) {
      return []
    }
    const mappedMembers = watchers.map(({ id, userId: watcherUserId }) => ({
      id,
      userId: watcherUserId,
      fullName: mappedWatchers.find(({ id: staffMemberId }) => staffMemberId === watcherUserId)
        ?.fullName,
    }))
    return orderBy(mappedMembers, ['fullName', 'userId'], ['asc', 'asc'])
  }, [isSomeLoading, mappedWatchers, watchers])

  const renderedWatchers = useMemo(() => {
    if (mappedAndSortedWatchers.length === 0) {
      return
    }

    return mappedAndSortedWatchers.map(({ id, userId: watcherUserId, fullName }) => {
      const name = fullName || watcherUserId
      const renderedName = userId === watcherUserId ? t('me', { name }) : name
      return (
        <CustomListItem
          key={`watcher-watcher-${id}-${watcherUserId}}`}
          type={ListItemType.Inactive}
        >
          <FlexBox
            fitContainer
            alignItems={FlexBoxAlignItems.Center}
            justifyContent={FlexBoxJustifyContent.SpaceBetween}
          >
            <Text>{renderedName}</Text>
            <Button
              icon="decline"
              design={ButtonDesign.Transparent}
              onClick={onDeleteInternal(id)}
            />
          </FlexBox>
        </CustomListItem>
      )
    })
  }, [mappedAndSortedWatchers, onDeleteInternal, userId, t])

  if (watcherIds.length === 0) {
    return (
      <CustomListItem type={ListItemType.Inactive}>
        <Text>{t('empty')}</Text>
      </CustomListItem>
    )
  }

  if (isSomeLoading) {
    return (
      <CustomListItem type={ListItemType.Inactive}>
        <BusyIndicator active delay={0} size={BusyIndicatorSize.Small} />
      </CustomListItem>
    )
  }

  return renderedWatchers
}

WatcherMenuWatchers.propTypes = {
  watchers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onUserUnsubscribe: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
}

export default WatcherMenuWatchers
