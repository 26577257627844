import isEmpty from 'lodash.isempty'

const conditionTypeChanged = (state, { payload: { conditionIndex, type: newType } }) => {
  if (!state.conditions[conditionIndex]) {
    return
  }
  state.conditions[conditionIndex].type = newType
  if (newType !== '') {
    delete state.errorRows[conditionIndex]?.type
    if (isEmpty(state.errorRows[conditionIndex])) {
      delete state.errorRows[conditionIndex]
    }
  }
}

export default conditionTypeChanged
