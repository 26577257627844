import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useBreachTranslation = () => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'components.deals.covenants.monitoring-items-card',
  })
  /**
   * @enum
   */
  const breachEnum = useMemo(
    () => ({
      YES: t('breach.yes'),
      NO: t('breach.no'),
      SOFT: t('breach.soft'),
      HARD: t('breach.hard'),
      POSTPONED: t('breach.postponed'),
    }),
    [t],
  )
  const getTranslationForBreach = (breach) => breachEnum[breach] ?? ''

  return { getTranslationForBreach, breachEnum }
}
