import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  tiles: {},
}

// Setting isLoading true to have this as fallback to not have null pointers
// or anything similar
const initialTileState = {
  isLoading: true,
  isError: false,
  error: undefined,
  data: undefined,
}

export const imagesForTilesSlice = createSlice({
  name: 'images-for-tiles',
  initialState,
  reducers: {
    initializeImages: (state, { payload: tileId }) => {
      state.tiles = { ...state.tiles, [tileId]: initialTileState }
    },
    imagesAreLoading: (state, { payload: tileId }) => {
      state.tiles = {
        ...state.tiles,
        [tileId]: {
          ...state.tiles[tileId],
          isLoading: true,
          isError: false,
          error: undefined,
          data: undefined,
        },
      }
    },
    imagesHaveError: (state, { payload: { id: tileId, error: imageError } }) => {
      state.tiles = {
        ...state.tiles,
        [tileId]: {
          ...state.tiles[tileId],
          isError: true,
          error: imageError,
          isLoading: false,
          data: undefined,
        },
      }
    },
    imagesLoaded: (state, { payload: { id: tileId, data: imageData } }) => {
      state.tiles = {
        ...state.tiles,
        [tileId]: {
          ...state.tiles[tileId],
          isError: false,
          error: undefined,
          isLoading: false,
          data: imageData,
        },
      }
    },
    resetImages: () => initialState,
  },
})
export const { initializeImages, imagesAreLoading, imagesHaveError, imagesLoaded, resetImages } =
  imagesForTilesSlice.actions

export default imagesForTilesSlice.reducer
