import {
  FlexBox,
  FlexBoxDirection,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Card from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/DealCashFlowCard'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/DealCashFlowEmptyCardContent'
import LoadingStateWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/DealCashFlowLoadingStateWrapper'
import { DealCashflowResultChartCardHeader } from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/DealCashFlowResultChartCardHeader'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/chart-components/DealCashFlowChartWrapper.module.css'

const DealCashflowChartWrapper = ({
  isCard,
  isLoading,
  isError,
  isSelectedData,
  isMetaData,
  headerProps,
  children,
}) => {
  const { t: tCashFlow } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.cash-flow',
  })

  const emptyInfoContent = useMemo(
    () => <EmptyCardContent illustrationName={IllustrationMessageType.UnableToLoad} />,
    [],
  )
  const emptyContent = useMemo(() => {
    const noSelectionCardContent = (
      <EmptyCardContent
        illustrationName={IllustrationMessageType.UnableToLoad}
        title={tCashFlow('no-scenario.selected.title')}
        subtitle={tCashFlow('no-scenario.selected.subtitle')}
      />
    )
    return isMetaData ? emptyInfoContent : noSelectionCardContent
  }, [emptyInfoContent, isMetaData, tCashFlow])

  return isCard ? (
    <Card header={<DealCashflowResultChartCardHeader {...headerProps} />}>
      <div className={styles.chartWrapper}>
        <LoadingStateWrapper isLoading={isLoading} isError={isError}>
          {isSelectedData ? children : emptyInfoContent}
        </LoadingStateWrapper>
      </div>
    </Card>
  ) : (
    <FlexBox direction={FlexBoxDirection.Column}>
      <DealCashflowResultChartCardHeader {...headerProps} />
      <div className={styles.chartWrapper}>{isSelectedData ? children : emptyContent}</div>
    </FlexBox>
  )
}

DealCashflowChartWrapper.propTypes = {
  isCard: PropTypes.bool,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  isSelectedData: PropTypes.bool.isRequired,
  isMetaData: PropTypes.bool,
  headerProps: PropTypes.object.isRequired,
  children: PropTypes.element,
}

export default DealCashflowChartWrapper
