import camelize from 'camelize'
import { useRequests } from 'hooks/services/baseService'

const useDealTeamUsersByIds = (userIds, options = {}) => {
  const results = useRequests({
    requests: userIds.map((id) => ({
      path: `/users/${id}`,
      keys: ['users', id],
      options: {
        ...options,
        enabled: !!id,
      },
    })),
    translated: true,
    useCache: true,
  })

  return {
    isSomeLoading: results.some(({ isLoading }) => isLoading),
    isSomeFetching: results.some(({ isFetching }) => isFetching),
    isSomeError: results.some(({ isError }) => isError),
    isAllError: results.length !== 0 ? results.every(({ isError }) => isError) : false,
    data: results.map((res) => camelize(res?.data)),
  }
}

export default useDealTeamUsersByIds
