import camelize from 'camelize'
import { useRequests } from 'hooks/services/baseService'

export const useTenantUnitTableData = (businessPartnerIds) => {
  const requests = businessPartnerIds?.map((businessPartnerId) => {
    const queryParams = new URLSearchParams()
    queryParams.append('business_partner_id', businessPartnerId)
    queryParams.append('role', 'tenant')

    const path = `/rental_units?${queryParams.toString()}`
    const keys = ['rental_units', businessPartnerId, 'tenant']
    return { path, keys }
  })

  const tenantUnitResponse = useRequests({
    requests: requests,
    options: { enabled: !!businessPartnerIds?.length },
  })

  const tenantUnitsData = tenantUnitResponse.map((singleResponse) => camelize(singleResponse.data))
  const tenantUnitsLoading = tenantUnitResponse.some(({ isLoading }) => isLoading)
  const tenantUnitsError = tenantUnitResponse.some(({ isError }) => isError)

  return {
    data: tenantUnitsData,
    isLoading: tenantUnitsLoading,
    isError: tenantUnitsError,
  }
}
