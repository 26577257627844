import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useCashflowsObjectDefinitions = () =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/configurations/cashflows/object-definitions`,
      useCache: true,
      keys: ['deals', 'configuration', 'cashflows', 'object-definitions'],
    }),
  )

export default useCashflowsObjectDefinitions
