import {
  ButtonDesign,
  FlexBox,
  Title,
  DynamicPageTitle,
  TitleLevel,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessObjectLink from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import styles from 'components/domains/business-events-and-tasks/decision-paper/pdf-export/DecisionPaperPdfExportHeader.module.css'
import DecisionPaperPdfExportSubheader from 'components/domains/business-events-and-tasks/decision-paper/pdf-export/DecisionPaperPdfExportSubheader'
import LoadingButton from 'components/ui/button/LoadingButton'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionPaperPdfExportHeader = ({
  isFrozen,
  version,
  frozenDate,
  isPrintButtonEnabled = false,
  showPrintButton = true,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.pdf-export.header',
  })
  const { format: formatDateAndTime } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
  })
  const {
    event: {
      info: { name: eventName },
      entityRef: { entityId, entityType },
    },
  } = useContext(BusinessEventsAndTasksContext)

  const renderButtonContent = useCallback(() => t('buttons.export'), [t])

  const printButton = useMemo(
    () =>
      showPrintButton ? (
        <FlexBox className={styles.buttonWrapper}>
          <LoadingButton
            className={styles.noPrint}
            design={ButtonDesign.Emphasized}
            onClick={() => window.print()}
            disabled={!isPrintButtonEnabled}
            isLoading={!isPrintButtonEnabled}
            renderContent={renderButtonContent}
          />
        </FlexBox>
      ) : undefined,
    [showPrintButton, isPrintButtonEnabled, renderButtonContent],
  )

  const titleText = useMemo(
    () =>
      isFrozen
        ? t('frozen.title', {
            eventName,
            version,
            date: formatDateAndTime(frozenDate),
          })
        : t('live.title', {
            eventName,
          }),
    [isFrozen, eventName, version, formatDateAndTime, frozenDate, t],
  )

  const header = useMemo(
    () => (
      <Title wrappingType={WrappingType.Normal} level={TitleLevel.H1}>
        {titleText}
      </Title>
    ),
    [titleText],
  )

  const subHeader = useMemo(
    () => (
      <BusinessObjectLink
        entityId={entityId}
        entityType={entityType}
        CustomComponent={DecisionPaperPdfExportSubheader}
      />
    ),
    [entityId, entityType],
  )

  return (
    <>
      <DynamicPageTitle
        id={styles.header}
        header={header}
        subHeader={subHeader}
        actions={printButton}
      />
    </>
  )
}

DecisionPaperPdfExportHeader.propTypes = {
  isFrozen: PropTypes.bool.isRequired,
  version: PropTypes.string,
  frozenDate: PropTypes.string,
  isPrintButtonEnabled: PropTypes.bool,
  showPrintButton: PropTypes.bool,
}

export default DecisionPaperPdfExportHeader
