import {
  FlexBox,
  FlexBoxDirection,
  IllustrationMessageType,
  Option,
  Select,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import chartStyle from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/KpiChartTile.module.css'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/empty-card/EmptyCardContent'
import KpiCardHeader from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/kpis/KpiCardHeader'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/kpis/KpiTimeSeriesCard.module.css'
import KpiTimeSeriesChart from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/kpis/chart/KpiTimeSeriesChart'
import useVersionedKpiUtils from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/kpis/useVersionedKpiUtils'

const KpiChartTile = ({ tileId, isPdfView, selectedDealIndex }) => {
  const { t } = useTranslation('decisionPaper')

  const {
    data: { kpiLists, updatedByNames },
    version,
    isFetching,
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { kpiList = [] } = kpiLists[selectedDealIndex] ?? {}
  const [selectedKpiCode, setSelectedKpiCode] = useState()
  const { getTimeSeriesForKpi, getKpiForestFromKpiList } = useVersionedKpiUtils(version)

  const kpiForest = useMemo(
    () => getKpiForestFromKpiList(kpiList),
    [getKpiForestFromKpiList, kpiList],
  )
  const selectedKpi = useMemo(
    () => kpiForest.find((it) => it.code === selectedKpiCode),
    [kpiForest, selectedKpiCode],
  )

  useEffect(() => {
    if (selectedKpiCode === undefined) {
      setSelectedKpiCode(kpiForest?.[0]?.code)
    }
  }, [kpiForest, selectedKpiCode, setSelectedKpiCode])

  const onSelectionChange = (code) => setSelectedKpiCode(code)

  const kpiSelectionOptions = useMemo(
    () => kpiForest.map((kpi) => ({ kpiCode: kpi?.code, name: kpi?.name })),
    [kpiForest],
  )

  const renderContent = useCallback(
    (renderedKpi) => {
      const timeSeries = getTimeSeriesForKpi(renderedKpi)
      const thresholds = renderedKpi?.thresholds ?? []
      const unit = renderedKpi?.unit

      return (
        <FlexBox
          direction={FlexBoxDirection.Column}
          className={chartStyle.chartWrapper}
          key={renderedKpi?.code}
        >
          {!isEmpty(renderedKpi) && (
            <KpiCardHeader
              kpi={renderedKpi}
              title={t('components.kpis.chart.title')}
              actions={
                !isPdfView
                  ? [
                      <div key="select">
                        <Select
                          onChange={(event) => onSelectionChange(event.detail.selectedOption.value)}
                          className={styles.minWidthSelect}
                        >
                          {kpiSelectionOptions.map(({ kpiCode, name }) => (
                            <Option
                              key={kpiCode}
                              value={kpiCode}
                              selected={kpiCode === renderedKpi?.code}
                            >
                              {name}
                            </Option>
                          ))}
                        </Select>
                      </div>,
                    ]
                  : []
              }
              tileVersion={version}
            />
          )}
          {timeSeries.length ? (
            <KpiTimeSeriesChart
              kpiTimeSeries={timeSeries}
              thresholds={thresholds}
              unit={unit}
              updatedByNames={updatedByNames}
              tileVersion={version}
            />
          ) : (
            <EmptyCardContent illustrationName={IllustrationMessageType.NoEntries} />
          )}
        </FlexBox>
      )
    },
    [getTimeSeriesForKpi, t, isPdfView, kpiSelectionOptions, updatedByNames, version],
  )

  return (
    !isFetching &&
    (isPdfView ? kpiForest.map((kpi) => renderContent(kpi)) : renderContent(selectedKpi))
  )
}

KpiChartTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default KpiChartTile
