import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useCovenantAllowedOperations = (covenantUuid = '', mainEntity = {}, options = {}) => {
  const queryParams = new URLSearchParams()

  if (covenantUuid) {
    queryParams.append('covenant-uuid', covenantUuid)
  }
  if (mainEntity?.mainEntityId) {
    queryParams.append('main_entity_id', mainEntity.mainEntityId)
  }
  if (mainEntity?.mainEntityType) {
    queryParams.append('main_entity_type', mainEntity.mainEntityType)
  }

  return useCamelizedResponse(
    useRequest({
      path: `/covenants/allowed-operations?${queryParams.toString()}`,
      useCache: true,
      keys: [
        'covenants',
        'allowed-operations',
        covenantUuid,
        mainEntity?.mainEntityType,
        mainEntity?.mainEntityId,
      ],
      ...options,
    }),
  )
}
