import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useExternalConditionTemplates = ({ entityType } = {}) => {
  const queryParams = new URLSearchParams()
  if (entityType) {
    queryParams.append('entity_type', entityType)
  }
  return useCamelizedResponse(
    useRequest({
      path: `/conditions/configurations/external-condition-templates?${queryParams.toString()}`,
      useCache: true,
      translated: true,
      keys: ['conditions', 'configurations', 'external', 'condition-templates', entityType],
    }),
  )
}

export default useExternalConditionTemplates
