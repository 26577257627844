import PropertyRentRollOverviewOccupancyCardHeader from 'components/domains/properties/rent-roll/overview/occupancy/PropertyRentRollOverviewOccupancyCardHeader'
import PropertyRentRollOverviewOccupancyCardPieChart from 'components/domains/properties/rent-roll/overview/occupancy/PropertyRentRollOverviewOccupancyCardPieChart'
import Card from 'components/ui/card/Card'

const PropertyRentRollOverviewOccupancyCard = () => (
  <Card header={<PropertyRentRollOverviewOccupancyCardHeader />}>
    <PropertyRentRollOverviewOccupancyCardPieChart />
  </Card>
)

export default PropertyRentRollOverviewOccupancyCard
