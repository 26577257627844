import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import PropertyCostsCardTable from 'components/domains/properties/general-information/costs/PropertyCostsCardTable'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import { useCosts } from 'hooks/services/properties/costs/useCosts'
import { useCreateCost } from 'hooks/services/properties/costs/useCreateCost'
import { useDeleteCost } from 'hooks/services/properties/costs/useDeleteCost'
import { useUpdateCost } from 'hooks/services/properties/costs/useUpdateCost'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyCostsCard = () => {
  const { t: tCostsCardTable } = useTranslation('translation', {
    keyPrefix: 'pages.properties.costs-card.table',
  })
  const { t: tCostsCard } = useTranslation('translation', {
    keyPrefix: 'pages.properties.costs-card',
  })

  const {
    property: {
      uuid: propertyUuid,
      currency_code: currency,
      change_request_exist_indicator: changeRequestExistIndicator,
      allowed_operations: allowed_operations,
    },
  } = useContext(PropertyContext)

  const allowedOperations = allowed_operations?.allowed_operations ?? []
  const userIsAllowedToEdit =
    !changeRequestExistIndicator && allowedOperations.includes('PropertyCosts_Update')

  const { data, isLoading, isError } = useCosts([propertyUuid])
  const generateRowKey = (cost) => cost.costUuid

  const costsOnProperty = data
    ? [
        ...data.costsWithLastModernizationDate[0].costs.map((cost) => ({
          rowKey: generateRowKey(cost),
          ...cost,
        })),
      ]
    : []

  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()
  const onCostChangeSuccess = useCallback(
    ({ successText }) => {
      showToast({
        children: successText,
      })
      queryClient.invalidateQueries(['properties', [propertyUuid], 'costs'])
    },
    [propertyUuid, queryClient, showToast],
  )

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [errorDialogText, setErrorDialogText] = useState('')

  const onCostChangeError = async (error) => {
    setIsErrorDialogOpen(true)
    const errorDetails = await formatHookError(error)
    setErrorDialogText(errorDetails)
  }

  const mutationBehavior = useCallback(
    (successText) => ({
      onSuccess: () => onCostChangeSuccess({ successText }),
      onError: (error) => onCostChangeError(error),
    }),
    [onCostChangeSuccess],
  )

  const saveRow = useCreateCost(mutationBehavior(tCostsCardTable('saved')))
  const updateRow = useUpdateCost(mutationBehavior(tCostsCardTable('updated')))
  const deleteRow = useDeleteCost(mutationBehavior(tCostsCardTable('deleted')))

  const handleSaveRow = (cost) => {
    saveRow.mutate({
      propertyUuid: propertyUuid,
      cost: { ...cost },
      currency: currency,
    })
  }
  const handleUpdateRow = (rowKey, cost) => {
    updateRow.mutate({
      propertyUuid: propertyUuid,
      costUuid: rowKey,
      currency: currency,
      cost: { ...cost },
    })
  }
  const handleDeleteRow = (rowKey) => {
    const costToDelete = costsOnProperty.find((cost) => cost.rowKey === rowKey)
    deleteRow.mutate({
      propertyUuid: propertyUuid,
      costUuid: costToDelete.costUuid,
    })
  }

  return (
    <>
      <PropertyCostsCardTable
        cardTitle={tCostsCard('title')}
        costsOnProperty={costsOnProperty}
        currency={currency}
        isLoading={isLoading}
        isError={isError}
        handleDeleteRow={handleDeleteRow}
        handleSaveRow={handleSaveRow}
        handleUpdateRow={handleUpdateRow}
        tCostsCardTable={tCostsCardTable}
        userIsAllowedToEdit={userIsAllowedToEdit}
      />
      {createPortal(
        <>
          {isErrorDialogOpen && (
            <ErrorMessageBoxWithExpandableDetails
              messageSummary={tCostsCardTable('error')}
              messageDetails={errorDialogText}
              isOpen={isErrorDialogOpen}
              onClose={() => setIsErrorDialogOpen(false)}
            />
          )}
        </>,
        document.body,
      )}
    </>
  )
}

export default PropertyCostsCard
