import { compact } from 'lodash'
import orderBy from 'lodash.orderby'

const CUSTOMER_BUSINESS_PARTNER_ID = 'PBB'

const mapUnitDealSyndicationLenders = ({
  syndicationTranches = [],
  syndicationTotalShareAmount,
  syndicationOwnShareAmountShare,
  customerBusinessPartnerId,
  getSyndicationBusinessPartnerFullName,
}) => {
  const syndicationExistingBusinessAmountsPerBp = syndicationTranches?.reduce((accum, current) => {
    Object.values(current?.syndications ?? {}).forEach(({ bpId, syndicationAmount = {} } = {}) => {
      accum[bpId] =
        accum[bpId] + syndicationAmount?.amount?.amountOriginalCurrency?.amount ||
        syndicationAmount?.amount?.amountOriginalCurrency?.amount
    })
    return {
      ...accum,
    }
  }, {})

  const mappedSyndicationLenders = compact(
    Object.entries(syndicationExistingBusinessAmountsPerBp ?? {})?.map(([bpId, sum]) =>
      bpId !== customerBusinessPartnerId && bpId !== CUSTOMER_BUSINESS_PARTNER_ID
        ? {
            businessPartnerId: bpId,
            businessPartnerFullName: getSyndicationBusinessPartnerFullName(bpId) ?? bpId,
            amountShare:
              !isNaN(syndicationTotalShareAmount) && !isNaN(sum)
                ? sum / syndicationTotalShareAmount
                : '-',
          }
        : undefined,
    ),
  )

  // sort lenders based on descending amount share, but put customer at the top of the list regardless
  return [
    {
      businessPartnerId: customerBusinessPartnerId,
      businessPartnerFullName: getSyndicationBusinessPartnerFullName(customerBusinessPartnerId),
      amountShare: syndicationOwnShareAmountShare,
    },
    ...(mappedSyndicationLenders
      ? orderBy(mappedSyndicationLenders, ['amountShare'], ['desc'])
      : []),
  ]
}

export default mapUnitDealSyndicationLenders
