import {
  Button,
  ButtonDesign,
  ResponsiveGridLayout,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import EventCreationEntityForm from 'components/domains/business-events-and-tasks/events/dialog/shared/EventCreationEntityForm'
import EventCreationEventTemplates from 'components/domains/business-events-and-tasks/events/dialog/shared/EventCreationEventTemplates'
import Dialog, { DialogSize } from 'components/ui/dialog/Dialog'

const CreateWorkingVersionDealEventDialogFirstStage = ({
  isOpen,
  setIsOpen,
  preselectedEntity,
  handleContinue,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dialog.event-creation.deal-working-version',
  })
  const [selectedEntityType, setSelectedEntityType] = useState(preselectedEntity.entityType)
  const [selectedEntityId, setSelectedEntityId] = useState(preselectedEntity.entityId)
  const [selectedEventType, setSelectedEventType] = useState('')
  const [isSelectedEntityWorkingVersion, setIsSelectedEntityWorkingVersion] = useState(false)

  const isContinueButtonDisabled = !selectedEntityType || !selectedEntityId || !selectedEventType

  return (
    <Dialog
      open={isOpen}
      size={DialogSize.L}
      headerText={t('title')}
      onAfterClose={() => setIsOpen(false)}
      primaryButton={
        <Button
          onClick={() =>
            handleContinue({ selectedEntityType, selectedEntityId, selectedEventType })
          }
          design={ButtonDesign.Emphasized}
          disabled={isContinueButtonDisabled}
        >
          {t('button.continue')}
        </Button>
      }
    >
      <ResponsiveGridLayout columnsXL={1} columnsL={1} columnsM={1} columnsS={1} rowGap="16px">
        <EventCreationEntityForm
          setSelectedEntityId={setSelectedEntityId}
          setSelectedEventType={setSelectedEventType}
          selectedEntityType={selectedEntityType}
          setSelectedEntityType={setSelectedEntityType}
          preselectedEntity={preselectedEntity}
          setIsSelectedEntityWorkingVersion={setIsSelectedEntityWorkingVersion}
        />
        <Title level={TitleLevel.H4}>{t('event-template')}</Title>
        <EventCreationEventTemplates
          selectedEntityId={selectedEntityId || ''}
          selectedEntityType={selectedEntityType}
          setSelectedEventType={setSelectedEventType}
          isSelectedEntityWorkingVersion={isSelectedEntityWorkingVersion}
        />
      </ResponsiveGridLayout>
    </Dialog>
  )
}

CreateWorkingVersionDealEventDialogFirstStage.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  preselectedEntity: PropTypes.shape({
    entityDisplayId: PropTypes.string,
    entityName: PropTypes.string,
    entityId: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
    isWorkingVersion: PropTypes.bool,
  }).isRequired,
  handleContinue: PropTypes.func.isRequired,
}

export default CreateWorkingVersionDealEventDialogFirstStage
