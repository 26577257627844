import { Label, WrappingType } from '@fioneer/ui5-webcomponents-react'
import { number, shape, string } from 'prop-types'
import { useNumberFormatter, usePercentageFormatter } from 'hooks/i18n/useI18n'

export const FormattedParameterValueText = ({
  id,
  className,
  parameter: { value, unit, decimalPlaces } = {},
}) => {
  const formatNumber = useNumberFormatter({
    minimumFractionDigits: decimalPlaces ?? 0,
    maximumFractionDigits: decimalPlaces ?? 0,
  })
  const formatPercentage = usePercentageFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  if (unit === '%') {
    return (
      <Label id={id} className={className} wrappingType={WrappingType.Normal}>
        {formatPercentage(value / 100)}
      </Label>
    )
  } else
    return (
      <Label id={id} className={className} wrappingType={WrappingType.Normal}>
        {formatNumber(value)} {unit}
      </Label>
    )
}

FormattedParameterValueText.propTypes = {
  id: string,
  className: string,
  parameter: shape({
    value: number,
    unit: string,
    decimalPlaces: number,
  }).isRequired,
}
