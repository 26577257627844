import isNil from 'lodash.isnil'
import sortBy from 'lodash.sortby'
import uniqBy from 'lodash.uniqby'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import CheckResultsCardHeader from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/covenants-ratios/check-results/CheckResultsCardHeader'
import CheckResultsChart from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/covenants-ratios/check-results/CheckResultsChart'
import CovenantSelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/covenants-ratios/check-results/CovenantSelect'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/covenants-ratios/check-results/MultipleCovenantsRatiosCovenantChecksResultsTile.module.css'
import useCheckResultsChartData from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/covenants-ratios/check-results/useCheckResultsChartData'
import useSegmentedChartData from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/covenants-ratios/check-results/useSegmentedChartData'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/card/EmptyCardContent'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const MultipleCovenantsRatiosCovenantChecksResultsTile = ({
  tileId,
  isPdfView,
  selectedDealIndex,
}) => {
  const dispatch = useDispatch()

  const { data: { multipleCovenantMonitoringItemsData = [], dealsData } = {} } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId],
  )

  const selectedDealDisplayId = dealsData[selectedDealIndex]?.dealDisplayId

  useEffect(() => {
    dispatch(
      setSourceRender({
        tileId,
        sourceRender: { dealDisplayId: selectedDealDisplayId },
      }),
    )
  }, [dispatch, selectedDealDisplayId, tileId])

  const [selectedCovenantUuid, setSelectedCovenantUuid] = useState()

  const covenantMonitoringItems = useMemo(
    () => multipleCovenantMonitoringItemsData?.[selectedDealIndex]?.covenantMonitoringItems,
    [multipleCovenantMonitoringItemsData, selectedDealIndex],
  )

  const {
    financialMonitoringItems = [],
    lastMonitoringItemWithResult,
    displayUnit,
    name,
    chartData = [],
  } = useCheckResultsChartData({ covenantMonitoringItems, selectedCovenantUuid }) ?? {}

  const segmentedChartData = useSegmentedChartData(chartData, lastMonitoringItemWithResult)

  const dropDownOptions = uniqBy(
    financialMonitoringItems?.map(({ covenant }) => ({
      covenantUuid: covenant.covenantUuid,
      name: covenant.name,
    })),
    'covenantUuid',
  )
  const sortedDropDownOptions = sortBy(dropDownOptions, 'name')

  useEffect(() => {
    if (isNil(selectedCovenantUuid)) {
      setSelectedCovenantUuid(sortedDropDownOptions?.[0]?.covenantUuid)
    }
  }, [selectedCovenantUuid, selectedDealIndex, sortedDropDownOptions])

  return (
    <>
      <CheckResultsCardHeader
        className={styles.header}
        name={name}
        unit={displayUnit}
        value={lastMonitoringItemWithResult?.result?.value}
        headerAction={
          <CovenantSelect
            options={sortedDropDownOptions}
            value={selectedCovenantUuid}
            onChange={setSelectedCovenantUuid}
            className={styles.minWidthSelect}
            disabled={isPdfView}
          />
        }
      />
      <DecisionPaperTileListSeparator />
      {chartData.length === 0 ? (
        <EmptyCardContent />
      ) : (
        <div className={styles.chartContainer}>
          <CheckResultsChart chartData={chartData} segmentedChartData={segmentedChartData} />
        </div>
      )}
    </>
  )
}

MultipleCovenantsRatiosCovenantChecksResultsTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultipleCovenantsRatiosCovenantChecksResultsTile
