import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getObjectStatusForEventStatus } from 'api/events/status'
import BusinessObjectLink, {
  BusinessObjectLinkCustomRender,
} from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import Header from 'components/ui/page/Header'
import TabPage from 'components/ui/page/TabPage'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const BusinessEventsAndTasksPage = ({
  breadcrumbs,
  children,
  isEmpty,
  defaultSectionId,
  actions,
}) => {
  const {
    event: {
      info: { name: eventName },
      status: eventStatus,
      entityRef: { entityId, entityType },
    },
  } = useContext(BusinessEventsAndTasksContext)
  const { t: tNoPrefix } = useTranslation()

  const { translationKey: statusTranslationKey, objectStatus } =
    getObjectStatusForEventStatus(eventStatus)

  const subtitle = useMemo(
    () => (
      <BusinessObjectLink
        entityId={entityId}
        entityType={entityType}
        customRender={BusinessObjectLinkCustomRender.clipboard}
      />
    ),
    [entityId, entityType],
  )
  const headerTitle = useMemo(
    () => (
      <Header
        title={eventName}
        subtitle={subtitle}
        breadcrumbs={breadcrumbs}
        status={{ text: tNoPrefix(statusTranslationKey), valueState: objectStatus }}
        actions={actions}
      />
    ),
    [breadcrumbs, eventName, objectStatus, statusTranslationKey, subtitle, tNoPrefix, actions],
  )

  return (
    <TabPage
      id="event-detail-dynamic-page"
      headerTitle={headerTitle}
      isEmpty={isEmpty}
      defaultSectionId={defaultSectionId}
    >
      {children}
    </TabPage>
  )
}

BusinessEventsAndTasksPage.propTypes = {
  children: PropTypes.any.isRequired,
  defaultSectionId: PropTypes.string.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ),
  actions: PropTypes.node,
}

export default BusinessEventsAndTasksPage
