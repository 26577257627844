import { useUniqueMatchingBusinessPartners } from 'hooks/services/business-partners/searchBusinessPartners'

const DEFAULT_BUSINESS_PARTNER_FIELD_NAMES = ['tenant', 'branding_franchise_partner']

/**
 * Translates unambigous businesspartner names into the full businesspartner name and its id.
 *
 * Example: Given dataWithBPSeachKeys = [
 *   {
 *     tenant_name: 'tenant_a',
 *     branding_franchise_partner_name: 'franchise_a',
 *     ...
 *   },
 *   {
 *     tenant_name: 'tenant_b',
 *     branding_franchise_partner_name: 'franchise_b',
 *   },
 * ]
 *
 * and unambiguous recognition of tenant_a and franchise_b, but ambiguos or no recognition of tenant_b and franchise_a
 *
 * results in [
 *   {
 *     tenant_name: 'Mieter A',
 *     tenant_id: '123',
 *     branding_franchise_partner_name: 'franchise_a',
 *     ...
 *   },
 *   {
 *     tenant_name: 'tenant_b',
 *     branding_franchise_partner_name: 'Branding Partner B',
 *     branding_franchise_partner_id: '789',
 *     ...
 *   },
 * ]
 */
const useRecognizeBusinessPartners = (
  dataWithBPSearchKeys,
  businessPartnerFieldNames = DEFAULT_BUSINESS_PARTNER_FIELD_NAMES,
) => {
  const getBpSearchKeyWithWildCards = (searchKey) => `*${searchKey}*`
  /**
   * Retrieves a list of searchKeys from the mappedTableData to query the BP service with.
   * The searchKeys are taken from the fields specified in recognizeBPsFor, e.g. tenant and branding_franchice_partner.
   * These search keys may be undefined and/or contain duplicates at this point.
   */
  const bpSearchKeys = dataWithBPSearchKeys.flatMap((row) =>
    businessPartnerFieldNames.map((bpField) => {
      const bpNameField = bpField + '_name'
      return row[bpNameField] ? getBpSearchKeyWithWildCards(row[bpNameField]) : ''
    }),
  )

  const {
    data: matchingBPsData,
    isLoading: matchingBPsIsLoading,
    isError: matchingBPsIsError,
    finishedRequests: finishedBPRequests,
    totalAmountOfRequests: totalAmountOfBPRequests,
  } = useUniqueMatchingBusinessPartners({ searchKeys: bpSearchKeys, searchScore: 1 })

  /**
   * For unambiguously identified BPs: Store the actual BP name and id in the respective fields.
   * Replace the original name that has been used as a search key with the BP service
   */
  const recognizeUniquelyIdentifiableTenants = () => {
    if (matchingBPsIsLoading || matchingBPsIsError) return undefined

    const recognizeBPsInRow = (row) => {
      const resultRow = { ...row }
      businessPartnerFieldNames.forEach((bpField) => {
        const bpIdField = bpField + '_id'
        const bpNameField = bpField + '_name'
        const matchingBPsForSearchKey =
          matchingBPsData[getBpSearchKeyWithWildCards(row[bpNameField])]
        if (row[bpNameField] && matchingBPsForSearchKey?.businessPartners?.length === 1) {
          resultRow[bpNameField] = matchingBPsForSearchKey.businessPartners[0].name
          resultRow[bpIdField] = matchingBPsForSearchKey.businessPartners[0].id
        }
      })
      return resultRow
    }

    const recognizedBPsTableData = dataWithBPSearchKeys.map((row) => recognizeBPsInRow(row))
    return recognizedBPsTableData
  }
  const recognizedUniquelyIdentifiableTenants = recognizeUniquelyIdentifiableTenants()

  return {
    isLoading: matchingBPsIsLoading,
    isError: matchingBPsIsError,
    data: recognizedUniquelyIdentifiableTenants,
    finishedBPRequests,
    totalAmountOfBPRequests,
  }
}

export default useRecognizeBusinessPartners
