import {
  ButtonDesign,
  FlexBox,
  Title,
  DynamicPageTitle,
  TitleLevel,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessObjectLink from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import useDecisionStageName from 'components/domains/business-events-and-tasks/common/useDecisionStageName'
import DecisionPaperPdfExportSubheader from 'components/domains/business-events-and-tasks/decision-paper/pdf-export/DecisionPaperPdfExportSubheader'
import LoadingButton from 'components/ui/button/LoadingButton'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import styles from 'routes/business-events-and-tasks/decision-process/stage/pdf-export/DecisionStagePdfExportHeader.module.css'

const DecisionStagePdfExportHeader = ({
  decisionStage,
  isPrintButtonEnabled = false,
  showPrintButton = true,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-stage.pdf-export.header',
  })
  const {
    event: {
      info: { name: eventName },
      entityRef: { entityId, entityType },
      displayId: eventDisplayId,
    },
  } = useContext(BusinessEventsAndTasksContext)

  const stageName = useDecisionStageName({
    name: decisionStage.info.name,
    decisionStageType: decisionStage.info.decisionType,
  })

  const printButton = useMemo(
    () =>
      showPrintButton ? (
        <FlexBox className={styles.buttonWrapper}>
          <LoadingButton
            className={styles.noPrint}
            design={ButtonDesign.Emphasized}
            onClick={() => window.print()}
            disabled={!isPrintButtonEnabled}
            isLoading={!isPrintButtonEnabled}
            renderContent={() => t('buttons.print')}
          />
        </FlexBox>
      ) : undefined,
    [showPrintButton, isPrintButtonEnabled, t],
  )

  const titleText = t('title', {
    stageName,
    eventName,
    eventDisplayId,
  })

  const subHeader = useMemo(
    () => (
      <BusinessObjectLink
        entityId={entityId}
        entityType={entityType}
        CustomComponent={DecisionPaperPdfExportSubheader}
      />
    ),
    [entityId, entityType],
  )

  const header = useMemo(
    () => (
      <Title wrappingType={WrappingType.Normal} level={TitleLevel.H1}>
        {titleText}
      </Title>
    ),
    [titleText],
  )

  return (
    <DynamicPageTitle
      id={styles.header}
      header={header}
      subHeader={subHeader}
      actions={printButton}
    />
  )
}

DecisionStagePdfExportHeader.propTypes = {
  decisionStage: PropTypes.shape({
    info: PropTypes.shape({
      decisionType: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isPrintButtonEnabled: PropTypes.bool,
  showPrintButton: PropTypes.bool,
}

export default DecisionStagePdfExportHeader
