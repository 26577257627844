import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  RadioButton,
  TextArea,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/handover/monitoring/HandoverInsiderListTileContentEdit.module.css'
import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js'

const HandoverInsiderListTileContentEdit = ({ onChange, content }) => {
  const textAreaRef = useRef(null)

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.handover.insider-list',
  })

  const handleOnRadioButtonChange = useCallback(
    ({ target: { value: newValue } }) => {
      onChange({ ...content, isListNecessary: newValue === 'true' })
    },
    [content, onChange],
  )

  const handleOnListChange = useCallback(
    ({ target: { value: newValue } }) => {
      onChange({ ...content, list: newValue })
    },
    [content, onChange],
  )

  const IsListNecessaryRadioButtonGroup = () => (
    <FlexBox direction={FlexBoxDirection.Row}>
      <RadioButton
        text="Yes"
        name="isListNecessaryRadioButtonGroup"
        checked={content.isListNecessary}
        onChange={handleOnRadioButtonChange}
        value="true"
        data-testid="radio-button-true"
      />
      <RadioButton
        text="No"
        name="isListNecessaryRadioButtonGroup"
        checked={!content.isListNecessary}
        onChange={handleOnRadioButtonChange}
        value="false"
      />
    </FlexBox>
  )

  // Fixes error "Cannot read properties of null (reading 'querySelector')" from ui5's TextArea._onfocusout
  // In this case no, shadow-root is available so undefined is used as fallback instead and checked by lodash.isNil
  // Otherwise, functionality is the same.
  useEffect(() => {
    if (typeof textAreaRef.current._onfocusout === 'function') {
      textAreaRef.current._onfocusout = (e) => {
        const eTarget = e?.relatedTarget
        const focusedOutToValueStateMessage =
          eTarget?.shadowRoot?.querySelector('.ui5-valuestatemessage-root') ?? undefined
        textAreaRef.current.focused = false
        if (!isNil(focusedOutToValueStateMessage)) {
          textAreaRef.current._openValueStateMsgPopover = false
        }
      }
    }
  }, [textAreaRef?.current?._onfocusout])

  const ListTextArea = () => (
    <TextArea
      data-testid={'list-text-area'}
      ref={textAreaRef}
      className={styles.editComponent}
      value={content.list}
      onChange={handleOnListChange}
      disabled={!content.isListNecessary}
    />
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.wrapper}>
      <FlexBox
        direction={FlexBoxDirection.Row}
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        alignItems={FlexBoxAlignItems.Center}
        className={styles.row}
      >
        <Label>{t('is-list-necessary.label')}</Label>
        <IsListNecessaryRadioButtonGroup />
      </FlexBox>
      <FlexBox
        direction={FlexBoxDirection.Row}
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        alignItems={FlexBoxAlignItems.Center}
        className={styles.row}
      >
        <Label>{t('list.label')}</Label>
        <ListTextArea />
      </FlexBox>
    </FlexBox>
  )
}

HandoverInsiderListTileContentEdit.propTypes = {
  onChange: PropTypes.func.isRequired,
  content: PropTypes.shape({
    isListNecessary: PropTypes.bool,
    list: PropTypes.string,
  }),
}

export default HandoverInsiderListTileContentEdit
