import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import PropertyImage from 'components/domains/properties/general-information/image/PropertyImage'
import DisplayCardView, { hideOptions } from 'components/ui/card/DisplayCardView'
import { usePropertyImages } from 'hooks/services/properties/images/usePropertyImages'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyGeneralInformationCard = () => {
  const { t } = useTranslation()
  const {
    property: {
      uuid: propertyUuid,
      id: propertyId,
      type_name: propertyTypeDisplayName,
      address: address,
    },
  } = useContext(PropertyContext)

  const { data: images = [] } = usePropertyImages(propertyUuid)

  const customInfoComponentImage = (imageTitle, imageUuid) => (
    <PropertyImage
      imageTitle={imageTitle}
      imageUuid={imageUuid}
      imageContainerClassName={'general-info-card-image-container'}
    />
  )

  const imageFieldDefinition = images.length
    ? [
        {
          customInfoComponent: customInfoComponentImage(
            images[0].description,
            images[0].image_uuid,
          ),
        },
      ]
    : []
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const routeToGeneralInformation = pathname + '/general-information'
  const handleHeaderClick = () => {
    navigate(routeToGeneralInformation)
  }

  const baseFieldDefinitions = [
    {
      label: t('components.property-info-card.label.property-id'),
      name: 'propertyId',
      value: propertyId,
    },
    {
      label: t('components.property-info-card.label.property-type'),
      name: 'propertyTypeNoEdit',
      value: propertyTypeDisplayName,
      hideOption: hideOptions.hideWhenEmpty,
    },
    {
      label: t('components.property-address-card.country'),
      name: 'countryName',
      value: address?.country_name,
      hideOption: hideOptions.hideWhenEmpty,
    },
    {
      label: t('components.property-address-card.postal-code'),
      name: 'postalCode',
      value: address?.postal_code,
      hideOption: hideOptions.hideWhenEmpty,
    },
    {
      label: t('components.property-address-card.city'),
      name: 'cityName',
      value: address?.city_name,
      hideOption: hideOptions.hideWhenEmpty,
    },
    {
      label: t('components.property-address-card.street'),
      name: 'streetName',
      value: address?.street_name,
      hideOption: hideOptions.hideWhenEmpty,
    },
    {
      label: t('components.property-address-card.number'),
      name: 'houseId',
      value: address?.house_id,
      hideOption: hideOptions.hideWhenEmpty,
    },
    {
      label: t('components.property-info-card.rate'),
      name: 'rating',
      value: '',
      hideOption: hideOptions.hideWhenEmpty,
    },
  ]
  const fieldDefinitions = [...imageFieldDefinition, ...baseFieldDefinitions]
  return (
    <DisplayCardView
      cardHeaderTitle={t('pages.property-overview.general-information')}
      fieldDefinitions={fieldDefinitions}
      isEditable={false}
      onHeaderClick={handleHeaderClick}
      setEditMode={() => {}}
    />
  )
}

export default PropertyGeneralInformationCard
