/**
 * @param {object[]} fields
 * @param {string} fields.keyBackend
 * @param {string} [fields.keyFrontend]
 * @param {string} fieldName
 * @param {object} [options]
 * @param {boolean} [options.enabled]
 */
const useLookupBackendFieldName = (fields, fieldName, options) => {
  if (options?.enabled === false) return undefined
  const keyBackend = fields.find(({ keyFrontend }) => keyFrontend === fieldName)?.keyBackend

  if (keyBackend === undefined)
    // eslint-disable-next-line no-console
    console.warn(
      `Didn't find needed config for the custom field with the (frontend) key ${fieldName}.\n` +
        'Default field configuration will be used.\n' +
        'This message can be ignored if the field is no longer needed.',
    )

  return keyBackend
}

export default useLookupBackendFieldName
