import isEmpty from 'lodash.isempty'

const conditionNameChanged = (state, { payload: { conditionIndex, name: newName } }) => {
  if (!state.conditions[conditionIndex]) {
    return
  }
  state.conditions[conditionIndex].name = newName
  delete state.errorRows[conditionIndex]?.name
  if (isEmpty(state.errorRows[conditionIndex])) {
    delete state.errorRows[conditionIndex]
  }
}

export default conditionNameChanged
