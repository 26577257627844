import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultipleCovenantChecks = ({
  mainEntityIds,
  mainEntityType,
  monitoringReferenceEntityType,
  monitoringReferenceEntityId,
  eventId,
  options = {},
}) => {
  const queryParams = new URLSearchParams()
  queryParams.append('main_entity_type', mainEntityType)
  if (monitoringReferenceEntityType) {
    queryParams.append('monitoring_reference_entity_type', monitoringReferenceEntityType)
  }
  if (monitoringReferenceEntityId) {
    queryParams.append('monitoring_reference_entity_id', monitoringReferenceEntityId)
  }
  if (eventId) {
    queryParams.append('event_id', eventId)
  }

  // HINT: `queryParams.size` seems to return `undefined` even if there are params
  const additionalParamsString = [...queryParams.keys()].length ? `&${queryParams.toString()}` : ''

  const responses = useRequestsMemo(
    useRequests({
      requests: mainEntityIds.map((entityId) => ({
        path: `/covenants/covenant-checks?main_entity_id=${entityId}${additionalParamsString}`,
        keys: [
          'covenants',
          'covenant-checks',
          mainEntityType,
          entityId,
          monitoringReferenceEntityType,
          monitoringReferenceEntityId,
          eventId,
        ],
      })),
      translated: true,
      useCache: true,
      options: { enabled: !isEmpty(mainEntityIds), ...options },
    }),
  )

  return useMemo(
    () =>
      responses.map((response, index) => ({
        ...response,
        data: {
          [mainEntityType]: mainEntityIds[index],
          ...camelize(response.data),
        },
      })),
    [responses, mainEntityType, mainEntityIds],
  )
}

export default useMultipleCovenantChecks
