import { Icon, TableRowType, Text } from '@fioneer/ui5-webcomponents-react'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/covenants/monitoring-items-card/MonitoringItemsTable.module.css'
import BreachCell from 'components/domains/deals/covenants/shared/BreachCell'
import StatusCell from 'components/domains/deals/covenants/shared/StatusCell'
import { useBreachTranslation } from 'components/domains/deals/covenants/shared/useBreachTranslations'
import Entity from 'components/ui/data/Entity'
import { ReferenceEntityType } from 'components/ui/data/ReferenceEntityType'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { DealContext } from 'routes/deals/DealContext'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import paths from 'routes/paths'

const useMonitoringItemsTableData = ({ monitoringItems, linkedEvents, isPeriodicalCheck }) => {
  const { deal: { displayId } = {} } = useContext(DealContext) || {}

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.monitoring-items-card',
  })
  const { format: formatDate } = useShortDateFormatter()
  const { getTranslationForBreach } = useBreachTranslation()

  const getEventById = useCallback(
    (eventId) => linkedEvents.find((event) => event?.id === eventId) ?? {},
    [linkedEvents],
  )

  return monitoringItems.map(
    ({
      covenantMonitoringItemUuid,
      status,
      name,
      linkedCovenantCheck,
      dueDate: { targetDeliveryDate, checkDate } = {},
      breach,
      resultOfBreach,
    }) => {
      const covenantCheckEntityProps = {
        name: linkedCovenantCheck?.covenantCheckName,
        link: undefined,
        openInNewTab: undefined,
        isLinkBold: false,
      }
      switch (linkedCovenantCheck?.monitoringReferenceEntity?.type) {
        case ReferenceEntityType.Deal:
          covenantCheckEntityProps.link = `/${paths.deals}/${displayId}/${dealDetailPaths.covenants}/${dealDetailPaths.covenantChecks}/${linkedCovenantCheck?.covenantCheckUuid}`
          covenantCheckEntityProps.openInNewTab = false
          break
        case ReferenceEntityType.BusinessPartner:
          covenantCheckEntityProps.link = `/${paths.businessPartners}/${linkedCovenantCheck?.monitoringReferenceEntity.id}/covenants/covenant-checks/${linkedCovenantCheck?.covenantCheckUuid}`
          covenantCheckEntityProps.openInNewTab = true
          break
      }
      return {
        rowKey: covenantMonitoringItemUuid,
        rowProperties: {
          type:
            isPeriodicalCheck && status !== 'PLANNED' ? TableRowType.Active : TableRowType.Inactive,
          covenantMonitoringItemUuid,
          'linked-covenant-check-monitoring-reference-uuid':
            linkedCovenantCheck?.monitoringReferenceEntity?.id,
          'linked-covenant-check-covenant-check-uuid': linkedCovenantCheck?.covenantCheckUuid,
          className: breach && breach !== 'NO' ? styles.borderLeft : undefined,
        },
        'monitoring-item': {
          value: `${name}`,
          cellComponent: <Entity name={name} />,
        },
        'covenant-check': {
          value: `${linkedCovenantCheck?.covenantCheckName}`,
          cellComponent: linkedCovenantCheck?.covenantCheckUuid && (
            <Entity {...covenantCheckEntityProps} />
          ),
        },
        status: {
          value: t(`status.${status?.toLowerCase()}`), // has to be the translation, because the SortedTable uses display-value as filter-value from filterEnum
          cellComponent: <StatusCell status={status} />,
        },
        'business-event': {
          value: getEventById(linkedCovenantCheck?.linkedEventId).displayId,
          cellComponent: (
            <Entity
              name={getEventById(linkedCovenantCheck?.linkedEventId).info?.name}
              id={getEventById(linkedCovenantCheck?.linkedEventId).displayId}
              link={`/${paths.businessEventsAndTasks}/${paths.businessEvents}/${linkedCovenantCheck?.linkedEventId}`}
              openInNewTab
              isLinkBold={isPeriodicalCheck}
            />
          ),
        },
        'check-date': {
          value: `${checkDate} ${formatDate(checkDate)}`,
          cellComponent: formatDate(checkDate),
        },
        'target-delivery-date': {
          value: `${targetDeliveryDate} ${formatDate(targetDeliveryDate)}`,
          cellComponent: formatDate(targetDeliveryDate),
        },
        breach: {
          value: getTranslationForBreach(breach), // has to be the translation, because the SortedTable uses display-value as filter-value from filterEnum
          cellComponent: (
            <BreachCell
              breach={breach}
              {...(!isPeriodicalCheck && {
                popoverContent: (
                  <Text className={styles.warningText}>{`${t('result-of-breach')} ${
                    resultOfBreach?.name
                  }`}</Text>
                ),
              })}
            />
          ),
        },
        arrow: {
          cellComponent: <Icon name="slim-arrow-right" />,
        },
      }
    },
  )
}

export default useMonitoringItemsTableData
