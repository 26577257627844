import isEmpty from 'lodash.isempty'

const descriptionChanged = (state, { payload: { description: newDescription } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }
  state.editedRow.currentValues.description = newDescription
  if (newDescription === state.editedRow.initialValues.description) {
    delete state.editedRow.changedFields.description
    return
  }
  state.editedRow.changedFields.description = true
}

export default descriptionChanged
