import { FilterGroupItem, Icon, Input, Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/conditions/business-object/BusinessObjectSelectionFilterBar.module.css'
import FilterMarketTypesComboBox from 'components/domains/markets/filterbar/FilterMarketTypesComboBox'
import DashboardFilterBar from 'components/ui/page/DashboardFilterBar'

const isEnterButtonEvent = (event) => event.code === 'Enter' || event.code === 'NumpadEnter'

const filterOptions = {
  searchFilter: { visibleOnBar: true },
  marketTypes: { visibleOnBar: true },
}

const noop = () => {}

const AssignableMarketsFilterBar = ({
  onGo,
  filterParams,
  propertyType,
  hasNoMarketTypes,
  propertyTypeDisplayName,
}) => {
  const [internalFilterParams, setInternalFilterParams] = useState(filterParams)
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.markets.assignable-markets-dialog.filterbar',
  })

  const onKeyDown = useCallback(
    (event) => {
      if (event.code === 'Space') {
        event.stopPropagation()
        return
      }
      if (isEnterButtonEvent(event)) {
        onGo(internalFilterParams)
      }
    },
    [onGo, internalFilterParams],
  )

  const handleSearchFilterChange = useCallback(
    ({ target: { value: newSearchFilter } }) => {
      setInternalFilterParams({ ...internalFilterParams, searchFilter: newSearchFilter })
    },
    [internalFilterParams],
  )

  const onGoInternal = useCallback(() => {
    onGo(internalFilterParams)
  }, [onGo, internalFilterParams])

  const onInputFocus = useCallback((event) => {
    event.stopPropagation()
  }, [])

  const handleMarketTypeChange = ({ detail: { items: newMarketTypes } }) => {
    const filteredMarketTypes = newMarketTypes
      .map((element) => element.dataset.value)
      .filter(_.negate(_.isNil))
    setInternalFilterParams({ ...internalFilterParams, marketTypes: filteredMarketTypes })
  }

  const filters = [
    <FilterGroupItem label={t('market-types.label')} key="marketTypes">
      <FilterMarketTypesComboBox
        selectedMarketTypes={internalFilterParams.marketTypes}
        onSelectionChange={handleMarketTypeChange}
        propertyType={propertyType}
        valueState={hasNoMarketTypes ? ValueState.Information : ValueState.None}
        valueStateMessage={
          hasNoMarketTypes && (
            <Text>
              {t('no-market-types-configured', { propertyType: propertyTypeDisplayName })}
            </Text>
          )
        }
      />
    </FilterGroupItem>,
  ]

  return (
    <DashboardFilterBar
      filterOptions={filterOptions}
      setDialogOpen={noop}
      onGo={onGoInternal}
      activeFiltersCount={0}
      additionalProperties={{
        hideToolbar: false,
        search: (
          <Input
            placeholder={t('search')}
            value={internalFilterParams.searchFilter}
            onFocus={onInputFocus}
            onInput={handleSearchFilterChange}
            onKeyDown={onKeyDown}
            icon={<Icon name="search" />}
          />
        ),
        hideToggleFiltersButton: false,
        hideFilterConfiguration: true,
        className: styles.filterbar,
      }}
    >
      {filters}
    </DashboardFilterBar>
  )
}

AssignableMarketsFilterBar.propTypes = {
  onGo: PropTypes.func.isRequired,
  propertyType: PropTypes.string.isRequired,
  filterParams: PropTypes.object.isRequired,
  hasNoMarketTypes: PropTypes.bool,
  propertyTypeDisplayName: PropTypes.string,
}

export default AssignableMarketsFilterBar
