import { FlexBox, FlexBoxDirection, Label, Option, Select } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { valuationRequestMultiEditOperations } from 'api/property/valuation/valuationRequests'
import BusinessPartnerSearchInput from 'components/domains/business-partners/search-input/BusinessPartnerSearchInput'
import useValuationRequestRotationPrincipleChecker from 'components/domains/properties/valuation/helper/useValuationRequestRotationPrincipleChecker'
import DialogPrimaryButton from 'components/ui/dialog/DialogPrimaryButton'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import { ConfigContext } from 'hooks/config/useConfig'

const operationSelectId = 'valuationRequestMultiEditValuerFieldOperationSelect'
const confirmActionKey = 'Confirm'

const ValuationRequestMultiEditValuerField = ({
  onChange,
  valuationRequests,
  selectedValuationRequestIds,
}) => {
  const config = useContext(ConfigContext)
  const showMessageBox = useShowMessageBox()
  const { t: tField } = useTranslation('translation', {
    keyPrefix: 'components.properties.valuation.multi-edit.fields',
  })
  const { t: tOperations } = useTranslation('translation', {
    keyPrefix: 'components.properties.valuation.multi-edit.operations',
  })
  const { t: tRotationPrinciple } = useTranslation('translation', {
    keyPrefix: 'pages.property.valuation.requests.table.rotation-principle.confirmation-message',
  })
  const { t: tNoPrefix } = useTranslation()
  const checkIsRotationPrincipleViolated = useValuationRequestRotationPrincipleChecker(
    valuationRequests,
    selectedValuationRequestIds,
  )
  const [massEditOperation, setMassEditOperation] = useState(
    valuationRequestMultiEditOperations.keep,
  )
  const showValueFiled = useMemo(
    () => massEditOperation === valuationRequestMultiEditOperations.replace,
    [massEditOperation],
  )

  const handleSelectedOperationChanged = useCallback(
    ({
      detail: {
        selectedOption: { value: operation },
      },
    }) => {
      setMassEditOperation(operation)
      switch (operation) {
        case valuationRequestMultiEditOperations.keep:
          onChange({ operation: valuationRequestMultiEditOperations.keep })
          return
        case valuationRequestMultiEditOperations.clear:
          onChange({ operation: valuationRequestMultiEditOperations.clear })
          return
        case valuationRequestMultiEditOperations.replace:
          onChange({ operation: valuationRequestMultiEditOperations.replace, value: null })
          return
      }
    },
    [onChange],
  )

  const appraiserRoleCode = config?.properties?.appraiserRoleCode

  const [searchInputValue, setSearchInputValue] = useState({ id: '' })

  const setNewValuer = useCallback(
    (businessPartner) => {
      setSearchInputValue(businessPartner)
      onChange({
        operation: valuationRequestMultiEditOperations.replace,
        value: businessPartner.id,
      })
    },
    [onChange],
  )

  const onValuerChange = useCallback(
    (businessPartner) => {
      // onChange is called by BusinessPartnerSearchInput on every input but with an empty string as id
      // to prevent rotation principle check on every keystroke, we have to check for this
      if (isEmpty(businessPartner.id)) {
        setSearchInputValue(businessPartner)
        return
      }
      const isRotationPrincipleViolated = checkIsRotationPrincipleViolated(businessPartner.id)
      if (!isRotationPrincipleViolated) {
        setNewValuer(businessPartner)
        return
      }
      showMessageBox({
        type: MessageBoxTypes.Confirm,
        children: tRotationPrinciple('description'),
        actions: [
          <DialogPrimaryButton key={confirmActionKey} data-action={confirmActionKey}>
            {tNoPrefix('buttons.confirm')}
          </DialogPrimaryButton>,
          MessageBoxActions.Cancel,
        ],
        onClose: ({ detail: { action } }) => {
          if (action === confirmActionKey) {
            setNewValuer(businessPartner)
          }
        },
      })
    },
    [checkIsRotationPrincipleViolated, setNewValuer, showMessageBox, tNoPrefix, tRotationPrinciple],
  )

  return (
    <>
      <FlexBox direction={FlexBoxDirection.Column}>
        <Label for={operationSelectId} showColon>
          {tField('valuer.label')}
        </Label>
        <Select id={operationSelectId} onChange={handleSelectedOperationChanged}>
          <Option
            value={valuationRequestMultiEditOperations.keep}
            selected={massEditOperation === valuationRequestMultiEditOperations.keep}
          >
            {tOperations(valuationRequestMultiEditOperations.keep)}
          </Option>
          <Option
            value={valuationRequestMultiEditOperations.clear}
            selected={massEditOperation === valuationRequestMultiEditOperations.clear}
          >
            {tOperations(valuationRequestMultiEditOperations.clear)}
          </Option>
          <Option
            value={valuationRequestMultiEditOperations.replace}
            selected={massEditOperation === valuationRequestMultiEditOperations.replace}
          >
            {tOperations(valuationRequestMultiEditOperations.replace)}
          </Option>
        </Select>
        {showValueFiled && (
          <BusinessPartnerSearchInput
            value={searchInputValue}
            onChange={onValuerChange}
            preselectedRoles={appraiserRoleCode ? [`${appraiserRoleCode}`] : []}
          />
        )}
      </FlexBox>
    </>
  )
}

ValuationRequestMultiEditValuerField.propTypes = {
  onChange: PropTypes.func.isRequired,
  valuationRequests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      valuer: PropTypes.string,
      info: PropTypes.shape({
        propertyId: PropTypes.string,
      }),
    }).isRequired,
  ).isRequired,
  selectedValuationRequestIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}

export default ValuationRequestMultiEditValuerField
