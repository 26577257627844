import isEqual from 'lodash.isequal'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTextualAssessments } from 'hooks/services/business-partners/assessments/useTextualAssessments'
import useTextualAssessmentsUpdate from 'hooks/services/business-partners/assessments/useTextualAssessmentsUpdate'

export const useAssessmentVersions = ({ businessPartnerId, type, onUpdateSuccess }) => {
  const [availableVersions, setAvailableVersions] = useState([])
  const availableVersionsRef = useRef([])

  const updateState = useCallback((newData) => {
    const isNewDataEqualToOldData = newData.every((version, index) =>
      isEqual(version, availableVersionsRef.current[index]),
    )
    if (isNewDataEqualToOldData) {
      return
    }
    availableVersionsRef.current = newData
    setAvailableVersions(newData)
  }, [])

  const {
    data: { availableVersions: versionData = [], allowedOperations = [] } = {},
    isLoading,
    isError,
  } = useTextualAssessments({ businessPartnerId, type })

  useEffect(() => {
    updateState(versionData)
  }, [versionData, updateState])

  const {
    mutate,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    isLoading: isUpdateLoading,
  } = useTextualAssessmentsUpdate({
    businessPartnerId,
    type,
    onSuccess: (data) => {
      updateState(data.availableVersions)
      onUpdateSuccess?.(data)
    },
  })

  return {
    isLoading,
    isError,
    isUpdateLoading,
    isUpdateError,
    isUpdateSuccess,
    mutate,
    data: {
      availableVersions: availableVersions,
      allowedOperations,
    },
  }
}
