/**
 * @enum
 */
export const editButtonStateEnum = {
  VISIBLE: 'VISIBLE',
  HIDDEN: 'HIDDEN',
  DISABLED: 'DISABLED',
}

const CLOSED_STATUS = 'CLOSED'

const useCalculateEditButtonState = ({ isEditable, status, isAllowedToEdit }) => {
  if (isEditable && isAllowedToEdit) {
    return editButtonStateEnum.VISIBLE
  } else if (!isEditable && status === CLOSED_STATUS) {
    return editButtonStateEnum.HIDDEN
  }
  return editButtonStateEnum.DISABLED
}

export default useCalculateEditButtonState
