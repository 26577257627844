import {
  BusyIndicator,
  BusyIndicatorSize,
  Label,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import orderBy from 'lodash.orderby'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { valuationClassification } from 'api/property/valuation/valuationRequests'
import ModularTableCell from 'components/domains/properties/modular-table/ModularTableCell'
import styles from 'components/domains/properties/properties-search/table/PropertyValuationValue.module.css'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import usePropertyValuations from 'hooks/services/properties/valuations/usePropertyValuations'

const orderByLatestValues = (valuations) =>
  orderBy(valuations, ['key_date', 'valid_from'], ['desc', 'desc'])

const PropertyValuationValue = ({ valuationClass, propertyUuid }) => {
  const valuationsReq = usePropertyValuations(propertyUuid)
  const formatMoney = useCustomizableCurrencyFormatter()
  const { t } = useTranslation()

  if (valuationsReq.isLoading) {
    return <BusyIndicator size={BusyIndicatorSize.Small} active />
  }

  if (valuationsReq.isError) {
    return (
      <Label className={styles.errorLabel} wrappingType={WrappingType.Normal}>
        {t('pages.properties.data-error')}
      </Label>
    )
  }

  const valuations = valuationsReq?.data?.valuations ?? []

  const valuationsByClass = valuations.filter((v) => v.classification === valuationClass)
  if (valuationsByClass.length === 0) {
    return <div />
  }

  const orderedValuations = orderByLatestValues(valuationsByClass)
  const { value_amount: valuationAmount } = orderedValuations[0]

  if (!valuationAmount || !valuationAmount.number || !valuationAmount.currency) {
    return <div />
  }

  return (
    <ModularTableCell wrapText={false}>{`${formatMoney(
      valuationAmount.number,
      valuationAmount.currency,
    )}`}</ModularTableCell>
  )
}

PropertyValuationValue.propTypes = {
  propertyUuid: PropTypes.string.isRequired,
  valuationClass: PropTypes.oneOf([
    valuationClassification.mortgageLendingValue,
    valuationClassification.marketValue,
  ]),
}

export default PropertyValuationValue
