import { Icon } from '@fioneer/ui5-webcomponents-react/dist'
import PropTypes from 'prop-types'
import FORMAT_TO_ICON_NAME from 'components/domains/documents/formatToIconName'

const DocumentIcon = ({ format, ...rest }) => {
  const iconName = FORMAT_TO_ICON_NAME.get(format)
  return iconName ? (
    <Icon name={iconName} showTooltip accessibleName={format} {...rest} />
  ) : (
    <span className={rest.className}>{format}</span>
  )
}

DocumentIcon.propTypes = {
  format: PropTypes.string,
}

export default DocumentIcon
