import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/properties/modular-table/ModularTableCell.module.css'

const ModularTableCell = ({ children, wrapText = false }) => (
  <Label wrappingType={wrapText ? 'Normal' : 'None'} className={styles.label}>
    {children}
  </Label>
)

ModularTableCell.propTypes = {
  children: PropTypes.node.isRequired,
  wrapText: PropTypes.bool,
  className: PropTypes.string,
}

export default ModularTableCell
