import { useRequest } from 'hooks/services/baseService'

const useGroupings = () => {
  const result = useRequest({
    path: '/businesspartners/value-helps/groupings',
    keys: ['groupings'],
    translated: true,
  })
  return result.data
    ? {
        ...result,
        data: result.data.groupings.map(({ id, name }) => ({
          code: id,
          displayName: name,
        })),
      }
    : result
}

export default useGroupings
