import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultiplePortfolioAppreciation = ({ dealUuids }) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: dealUuids.map((dealUuid) => ({
        path: `/deals/${dealUuid}/portfolio/appreciation`,
        keys: ['deals', dealUuid, 'portfolio', 'appreciation'],
      })),
      options: { enabled: !isEmpty(dealUuids) },
    }),
  )
  return useMemo(
    () => ({
      data: responses.map((result, index) => ({
        dealUuid: dealUuids[index],
        ...camelize(result.data),
      })),
      isMultiplePortfolioLoading: responses.some((result) => result.isLoading),
      isMultiplePortfolioError: responses.some((result) => result.isError),
    }),
    [dealUuids, responses],
  )
}

export default useMultiplePortfolioAppreciation
