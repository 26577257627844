import camelize from 'camelize'
import { useMemo } from 'react'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useDealProperties from 'hooks/services/deals/properties/useDealProperties'
import useCurrentMultiPropertyKpis from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpis'
import { useSegmentUsageTypes } from 'hooks/services/properties/segments/useSegmentUsageTypes'
import { useMultiPropertyViewByPropertyUuids } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuids'
import usePropertyUuids from 'hooks/services/properties/usePropertyUuids'
import useMultiPropertyValuationsDecisionPaper from 'hooks/services/properties/valuations/useMultiPropertyValuationsDecisionPaper'

const useFurtherDetailsPieChartCardData = ({ entityRef: { entityId: dealUuid } }, _, tileCode) => {
  const dealUuidByTileCodeResult = useDealUuidByTileCode({
    dealUuid,
    tileCode,
  })
  const { data: { dealUuid: dealUuidByTileCode } = {} } = dealUuidByTileCodeResult
  const dealPropertiesResult = useDealProperties({ dealUuid: dealUuidByTileCode })

  const isPropertyRequestEnabled = useMemo(
    () =>
      !dealUuidByTileCodeResult.isFetching &&
      !dealUuidByTileCodeResult.isError &&
      !dealPropertiesResult.isFetching &&
      !dealPropertiesResult.isError &&
      !!dealPropertiesResult?.data &&
      dealPropertiesResult?.data?.dealProperties?.length > 0,
    [dealPropertiesResult, dealUuidByTileCodeResult],
  )

  const propertyUuids = useMemo(
    () =>
      isPropertyRequestEnabled
        ? dealPropertiesResult.data.dealProperties.map((property) => property.propertyUuid)
        : [],
    [dealPropertiesResult, isPropertyRequestEnabled],
  )

  const {
    data: multiPropertyKpisByGranularity,
    isFetching: isFetchingMultiPropertyKpis,
    isError: isErrorMultiPropertyKpis,
  } = useCurrentMultiPropertyKpis(
    propertyUuids,
    {
      withCityGranularity: true,
      withCountryGranularity: true,
      withPropertyTypeGranularity: true,
      withTenantIndustryGranularity: true,
      withSegmentUsageTypeGranularity: true,
      withTegovaRatingGranularity: true,
    },
    { enabled: isPropertyRequestEnabled },
  )

  const {
    data: multiPropertyValuationsByGranularity,
    isFetching: isFetchingMultiPropertyValuations,
    isError: isErrorMultiPropertyValuations,
  } = useMultiPropertyValuationsDecisionPaper(
    propertyUuids,
    [],
    undefined,
    { enabled: isPropertyRequestEnabled },
    ['city', 'country', 'propertyType', 'tegovaRating'],
  )

  const {
    isFetching: isFetchingSegmentUsageTypes,
    isError: isErrorSegmentUsageTypes,
    data: usageTypeCodes,
  } = useSegmentUsageTypes()

  const isMultiProperty = useMemo(() => propertyUuids.length > 1, [propertyUuids.length])

  const propertiesResult = usePropertyUuids(propertyUuids, {
    enabled: isPropertyRequestEnabled && !isMultiProperty,
  })
  const propertyId = useMemo(
    () => propertiesResult.data?.data?.properties?.[0]?.id,
    [propertiesResult.data?.data?.properties],
  )

  const multiPropertyViewResponse = useMultiPropertyViewByPropertyUuids(propertyUuids, {
    enabled: isMultiProperty,
  })
  const multiPropertyViewId = useMemo(
    () => multiPropertyViewResponse?.data?.data?.uuid,
    [multiPropertyViewResponse],
  )

  const sourcePath = useMemo(
    () =>
      isMultiProperty
        ? `/properties/portfolio/rent-roll?portfolio-view-id=${multiPropertyViewId}`
        : `/properties/${propertyId}/rent-roll`,
    [isMultiProperty, multiPropertyViewId, propertyId],
  )

  return useMemo(() => {
    const isLoading =
      dealUuidByTileCodeResult.isFetching ||
      dealPropertiesResult.isFetching ||
      isFetchingMultiPropertyKpis ||
      isFetchingMultiPropertyValuations ||
      propertiesResult.isFetching ||
      (isMultiProperty && multiPropertyViewResponse.isFetching) ||
      isFetchingSegmentUsageTypes
    const isError =
      dealUuidByTileCodeResult.isError ||
      dealPropertiesResult.isError ||
      isErrorMultiPropertyKpis ||
      isErrorMultiPropertyValuations ||
      propertiesResult.isError ||
      (isMultiProperty && multiPropertyViewResponse.isError) ||
      isErrorSegmentUsageTypes
    if (
      !dealUuidByTileCodeResult.isFetching &&
      !dealUuidByTileCodeResult.isError &&
      !dealPropertiesResult.isFetching &&
      !dealPropertiesResult.isError &&
      (!dealPropertiesResult?.data?.dealProperties ||
        dealPropertiesResult?.data?.dealProperties?.length === 0)
    ) {
      return {
        isLoading: false,
        isError: false,
        data: {
          noProperties: true,
        },
      }
    }
    return {
      isLoading,
      isError,
      data: {
        multiPropertyKpisByGranularity: multiPropertyKpisByGranularity,
        multiPropertyValuationsByGranularity: camelize(multiPropertyValuationsByGranularity),
        usageTypeCodes: camelize(usageTypeCodes),
        isMultiProperty,
        properties: camelize(propertiesResult.data?.data?.properties),
        sourceRender: { path: `${sourcePath}` },
        noProperties: false,
      },
    }
  }, [
    dealUuidByTileCodeResult,
    dealPropertiesResult?.data?.dealProperties,
    dealPropertiesResult.isError,
    dealPropertiesResult.isFetching,
    isErrorMultiPropertyKpis,
    isErrorMultiPropertyValuations,
    isErrorSegmentUsageTypes,
    isFetchingMultiPropertyKpis,
    isFetchingMultiPropertyValuations,
    isFetchingSegmentUsageTypes,
    isMultiProperty,
    multiPropertyKpisByGranularity,
    multiPropertyValuationsByGranularity,
    multiPropertyViewResponse.isError,
    multiPropertyViewResponse.isFetching,
    propertiesResult.data?.data?.properties,
    propertiesResult.isError,
    propertiesResult.isFetching,
    sourcePath,
    usageTypeCodes,
  ])
}

export default useFurtherDetailsPieChartCardData
