/**
 * Replaces shorthand notations for thousand (k), million (m), and billion (b) with their respective values.
 *
 * @param {string} inputValue - The input string that may contain shorthand notations.
 * @returns {string} The input string with shorthand notations replaced by their full numeric representations.
 */
export const replaceDealCashFlowShortHands = (inputValue, separators) => {
  const thousandsMultiplier = 1_000
  const millionsMultiplier = 1_000_000
  const billionsMultiplier = 1_000_000_000

  const replaceWithMultiplier = (match, p1, multiplier) => {
    const numberFormatted = p1
      .replace(new RegExp(`\\${separators?.thousandsSeparator}`, 'g'), '')
      .replace(new RegExp(`\\${separators?.decimalSeparator}`, 'g'), '.')
    const parsedNumber = parseFloat(numberFormatted)

    if (parsedNumber > Number.MAX_SAFE_INTEGER / multiplier) {
      return match
    }

    const result = parsedNumber * multiplier

    return result.toString()
  }

  inputValue = inputValue.replace(
    new RegExp(
      `([\\d${separators?.thousandsSeparator}${separators?.decimalSeparator}]+)[kK]\\b`,
      'i',
    ),
    (match, p1) => replaceWithMultiplier(match, p1, thousandsMultiplier),
  )
  inputValue = inputValue.replace(
    new RegExp(
      `([\\d${separators?.thousandsSeparator}${separators?.decimalSeparator}]+)[mM]\\b`,
      'i',
    ),
    (match, p1) => replaceWithMultiplier(match, p1, millionsMultiplier),
  )
  inputValue = inputValue.replace(
    new RegExp(
      `([\\d${separators?.thousandsSeparator}${separators?.decimalSeparator}]+)[bB]\\b`,
      'i',
    ),
    (match, p1) => replaceWithMultiplier(match, p1, billionsMultiplier),
  )

  return inputValue
}
