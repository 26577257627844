import { get, groupBy } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { multiPropertyValuations } from 'api/property/valuation/valuations'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import { useMultiPropertyImagesDownload } from 'hooks/services/business-events-and-tasks/decision-papers/useMultiPropertyImagesDownload'
import useDealProperties from 'hooks/services/deals/properties/useDealProperties'
import { usePropertiesImages } from 'hooks/services/properties/images/usePropertyImages'
import useMultiPropertyValuations from 'hooks/services/properties/valuations/useMultiPropertyValuations'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const DEFAULT_PROPERTY_IMAGE_ORDER = 0
const DEFAULT_PROPERTY_IMAGE_META = 'Unknown'

const blobToBase64 = (blob) =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data)
    }
  })

const sortImagesGroupedByProperty = (images) =>
  Object.values(groupBy(images, 'propertyUuid'))
    .map((propertyImages) =>
      [...propertyImages].sort((a, b) => a.propertyImageOrder - b.propertyImageOrder),
    )
    .flat()

const getPropertiesInOrder = (valuationResult) => {
  const propertiesWithMarketValue = Object.entries(valuationResult?.data?.valuations || {}).map(
    ([key, value]) => ({
      property: key,
      marketValue: value?.[multiPropertyValuations.marketValue]?.value_amount?.number,
    }),
  )
  return propertiesWithMarketValue.sort(({ marketValue: a }, { marketValue: b }) => {
    const aIsNaN = isNaN(a)
    const bIsNaN = isNaN(b)
    if (aIsNaN && bIsNaN) {
      return 0
    } else if (aIsNaN) {
      return 1
    } else if (bIsNaN) {
      return -1
    }
    return Number(b) - Number(a)
  })
}

const sortImagesByPropertyMarketValue = (images, valuationResult) => {
  if (images?.data?.noProperties) {
    return images
  }
  const groupedImages = groupBy(images?.data?.propertyImages, 'propertyUuid')
  const propertiesInOrder = getPropertiesInOrder(valuationResult)
  return {
    isLoading: images.isLoading,
    isError: images.isError,
    data: {
      propertyImages: propertiesInOrder.flatMap((property) => groupedImages[property.property]),
    },
  }
}

const loadingResult = {
  isLoading: true,
  isError: false,
}

const noPropertiesResult = {
  isLoading: false,
  isError: false,
  data: { noProperties: true },
}

const usePortfolioFinancedAssetsInformationImages = (
  { entityRef: { entityId: dealUuid } },
  _,
  tileCode,
) => {
  const {
    data: { dealUuid: dealUuidByTileCode } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({ dealUuid, tileCode })

  const [imageResult, setImageResult] = useState(loadingResult)
  const [parsedImages, setParsedImages] = useState()
  const dealPropertiesResult = useDealProperties({
    dealUuid: dealUuidByTileCode,
  })

  const isPropertyRequestEnabled =
    !isDealUuidFetching &&
    !isDealUuidError &&
    !dealPropertiesResult.isFetching &&
    !dealPropertiesResult.isError &&
    !!dealPropertiesResult?.data &&
    dealPropertiesResult?.data?.dealProperties?.length > 0

  const propertyUuids = useMemo(
    () =>
      isPropertyRequestEnabled
        ? dealPropertiesResult?.data?.dealProperties.map((property) => property.propertyUuid)
        : [],
    [dealPropertiesResult, isPropertyRequestEnabled],
  )

  const imageInformationResult = useCamelizedResponse(
    usePropertiesImages(propertyUuids, { enabled: isPropertyRequestEnabled }),
  )

  const propertyValuationResult = useMultiPropertyValuations(propertyUuids, [], undefined, {
    enabled: isPropertyRequestEnabled,
  })

  const reduceFunctionBuildImageData = useCallback(
    (accumulator, current, index) => {
      const imageData = current.length
        ? current.map((imageInfo) => ({
            ...imageInfo,
            propertyUuid: propertyUuids[index],
            noImage: false,
          }))
        : [
            {
              propertyUuid: propertyUuids[index],
              propertyImageOrder: DEFAULT_PROPERTY_IMAGE_ORDER,
              noImage: true,
            },
          ]
      accumulator.data.push(...imageData)
      return accumulator
    },
    [propertyUuids],
  )
  const imageInformation = useMemo(() => {
    const isLoading = imageInformationResult.isFetching || propertyValuationResult.isFetching
    const isError = imageInformationResult.isError || propertyValuationResult.isError
    if (isLoading || isError || !imageInformationResult.data) {
      return {
        isLoading: isLoading,
        isError: isError,
        data: undefined,
      }
    }
    return imageInformationResult.data.reduce(reduceFunctionBuildImageData, {
      isLoading: false,
      isError: false,
      data: [],
    })
  }, [imageInformationResult, propertyValuationResult, reduceFunctionBuildImageData])

  const enableImageDownload =
    isPropertyRequestEnabled &&
    imageInformation.data &&
    !imageInformation.isLoading &&
    !imageInformation.isError

  const downloadableImages = useMemo(
    () => imageInformation?.data?.filter((imageInfo) => !imageInfo.noImage),
    [imageInformation.data],
  )
  const nonDownloadableImages = useMemo(
    () => imageInformation?.data?.filter((imageInfo) => imageInfo.noImage),
    [imageInformation.data],
  )

  const imageDownloadResult = useMultiPropertyImagesDownload({
    images: downloadableImages || [],
    options: { enabled: enableImageDownload },
  })
  const preparedImageData = useMemo(() => {
    const images = downloadableImages?.map((image, index) => ({
      imageTitle: get(image, 'meta.name', DEFAULT_PROPERTY_IMAGE_META),
      imageDescription: get(image, 'description', DEFAULT_PROPERTY_IMAGE_META),
      propertyImageOrder: get(image, 'propertyImageOrder', DEFAULT_PROPERTY_IMAGE_ORDER),
      propertyUuid: get(image, 'propertyUuid', DEFAULT_PROPERTY_IMAGE_META),
      imageData: imageDownloadResult.data?.[index],
    }))

    const sortedImages = sortImagesGroupedByProperty(images)
    return {
      isLoading:
        isDealUuidFetching ||
        dealPropertiesResult.isFetching ||
        !imageInformation.data ||
        propertyValuationResult.isFetching ||
        imageDownloadResult.isFetching,
      isError:
        isDealUuidError ||
        dealPropertiesResult.isError ||
        imageInformation.isError ||
        propertyValuationResult.isError ||
        imageDownloadResult.isError,
      data: { propertyImages: sortedImages },
    }
  }, [
    downloadableImages,
    isDealUuidFetching,
    dealPropertiesResult.isFetching,
    dealPropertiesResult.isError,
    imageInformation.data,
    imageInformation.isError,
    propertyValuationResult.isFetching,
    propertyValuationResult.isError,
    imageDownloadResult.isFetching,
    imageDownloadResult.isError,
    imageDownloadResult.data,
    isDealUuidError,
  ])

  useEffect(() => {
    if (preparedImageData.isLoading || preparedImageData.isError) {
      return
    }
    Promise.all(
      preparedImageData.data.propertyImages.map(({ imageData }) =>
        imageData ? blobToBase64(imageData) : Promise.reject(),
      ),
    )
      .then((images) => {
        setParsedImages(images)
      })
      // Catch so that we don't have unhandled promise rejections.
      .catch(() => {})
  }, [preparedImageData, dealPropertiesResult])

  useEffect(() => {
    Promise.all(parsedImages)
      // Fallback if the data is still loading or has an error
      .catch(() => preparedImageData.data.propertyImages?.map(() => undefined))
      .then((potentiallyParsedImages) => {
        if (
          !isDealUuidFetching &&
          !isDealUuidError &&
          !dealPropertiesResult.isFetching &&
          !dealPropertiesResult.isError &&
          (!dealPropertiesResult?.data?.dealProperties ||
            dealPropertiesResult?.data?.dealProperties?.length === 0)
        ) {
          setImageResult(noPropertiesResult)
          return
        }
        const propertyImages =
          !preparedImageData.isError && !preparedImageData.isLoading
            ? [
                ...preparedImageData.data.propertyImages.map((image, index) => ({
                  ...image,
                  imageData: potentiallyParsedImages[index],
                })),
                ...nonDownloadableImages,
              ]
            : undefined
        setImageResult({
          isLoading: !preparedImageData.isError && (preparedImageData.isLoading || !parsedImages),
          isError: preparedImageData.isError,
          data: {
            propertyImages,
          },
        })
      })
  }, [
    parsedImages,
    preparedImageData,
    dealPropertiesResult,
    nonDownloadableImages,
    isDealUuidFetching,
    isDealUuidError,
  ])

  return useMemo(
    () => sortImagesByPropertyMarketValue(imageResult, propertyValuationResult),
    [imageResult, propertyValuationResult],
  )
}

export default usePortfolioFinancedAssetsInformationImages
