import {
  ComboBox,
  ComboBoxGroupItem,
  ComboBoxItem,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  Label,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/involved-parties/sponsor-guarantor-overview/AnnualReviewDealOverviewSponsorGuarantorOverviewBusinessPartnerSelect.module.css'
import useAnnualReviewDealOverviewSelectedDeal from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/utils/useAnnualReviewDealOverviewSelectedDeal'

const businessPartnerSelectId = 'bp-sponsor-guarantor-overview-business-partner-select'

const buildBpOptions = (businessPartners) =>
  businessPartners.map(({ information: { businessPartnerId, name } }) => (
    <ComboBoxItem key={businessPartnerId} text={name} data-bp-id={businessPartnerId} />
  ))

const AnnualReviewDealOverviewSponsorGuarantorOverviewBusinessPartnerSelect = ({
  tileId,
  selectedBp: {
    information: { name: selectedBpName },
  },
  setSelectedBp,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.bp-sponsor-guarantor-overview.business-partner-select',
  })

  const { data: { multipleCategorizedBusinessPartners = [] } = {} } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId],
  )

  const { selectedDealIndex } = useAnnualReviewDealOverviewSelectedDeal(tileId)

  const {
    guarantors = [],
    sponsors = [],
    guarantorsAndSponsors = [],
  } = multipleCategorizedBusinessPartners?.[selectedDealIndex] ?? {}

  const comboBoxData = useMemo(() => {
    const sponsorGroup = isEmpty(sponsors) ? null : (
      <>
        <ComboBoxGroupItem text={t('sponsor')} />
        {buildBpOptions(sponsors)}
      </>
    )
    const guarantorAndSponsorGroup = isEmpty(guarantorsAndSponsors) ? null : (
      <>
        <ComboBoxGroupItem text={t('sponsor-and-guarantor')} />
        {buildBpOptions(guarantorsAndSponsors)}
      </>
    )
    const guarantorGroup = isEmpty(guarantors) ? null : (
      <>
        <ComboBoxGroupItem text={t('guarantor')} />
        {buildBpOptions(guarantors)}
      </>
    )
    return (
      <>
        {sponsorGroup}
        {guarantorAndSponsorGroup}
        {guarantorGroup}
      </>
    )
  }, [guarantors, guarantorsAndSponsors, sponsors, t])

  const onBpChange = useCallback(
    ({ detail: { item } }) => {
      const selectedBpId = item.getAttribute('data-bp-id')
      const selectedBp = [...guarantors, ...sponsors, ...guarantorsAndSponsors].find(
        ({ information: { businessPartnerId } }) => businessPartnerId === selectedBpId,
      )
      setSelectedBp(selectedBp)
    },
    [guarantors, guarantorsAndSponsors, setSelectedBp, sponsors],
  )

  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      className={styles.businessPartnerSection}
      alignItems={FlexBoxAlignItems.Start}
    >
      <Label for={businessPartnerSelectId} showColon>
        {t('business-partner')}
      </Label>
      <ComboBox
        id={businessPartnerSelectId}
        className={styles.businessPartnerSelect}
        value={selectedBpName}
        onSelectionChange={onBpChange}
      >
        {comboBoxData}
      </ComboBox>
    </FlexBox>
  )
}

AnnualReviewDealOverviewSponsorGuarantorOverviewBusinessPartnerSelect.propTypes = {
  tileId: PropTypes.string.isRequired,
  selectedBp: PropTypes.shape({
    information: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  setSelectedBp: PropTypes.func.isRequired,
}

export default AnnualReviewDealOverviewSponsorGuarantorOverviewBusinessPartnerSelect
