import isEqual from 'lodash.isequal'
import { useCallback, useEffect, useRef, useState } from 'react'

const useContentVersions = ({
  getContentVersionsHook,
  getContentVersionsHookParameters,
  putContentUpdateHook,
  putContentUpdateHookParameters,
  onContentUpdateSuccess,
}) => {
  const [availableVersions, setAvailableVersions] = useState([])
  const availableVersionsRef = useRef([])

  const updateState = useCallback((newData) => {
    const isNewDataEqualToOldData =
      newData.length === availableVersionsRef.current.length &&
      newData.every((version, index) => isEqual(version, availableVersionsRef.current[index]))

    if (isNewDataEqualToOldData) {
      return
    }
    availableVersionsRef.current = newData
    setAvailableVersions(newData)
  }, [])

  const {
    isLoading,
    isError,
    data: { availableVersions: versionData = [] } = {},
  } = getContentVersionsHook({ ...(getContentVersionsHookParameters ?? {}) })

  useEffect(() => {
    updateState(versionData)
  }, [versionData, updateState])

  const {
    mutate,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    isLoading: isUpdateLoading,
  } = putContentUpdateHook({
    ...(putContentUpdateHookParameters ?? {}),
    onSuccess: (data) => {
      updateState(data.availableVersions)
      onContentUpdateSuccess?.(data)
    },
  })

  return {
    isLoading,
    isError,
    isUpdateLoading,
    isUpdateError,
    isUpdateSuccess,
    mutate,
    data: {
      availableVersions,
    },
  }
}

export default useContentVersions
