import camelize from 'camelize'
import { useRequests } from 'hooks/services/baseService'

const useStaffMemberByMultipleIds = (staffMemberIds) => {
  const response = useRequests({
    requests: staffMemberIds.map((staffMemberId) => ({
      path: `/users/${staffMemberId}`,
    })),
  })

  return response.map(({ data, ...otherResponse }) => ({ data: camelize(data), ...otherResponse }))
}
export default useStaffMemberByMultipleIds
