import { Table, TableCell, TableColumn, TableRow } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import MultiPropertyUploadDownloadErrorButton from 'components/domains/properties/multi-property-upload/MultiPropertyUploadDownloadErrorButton'

/**
 * A table component that displays the failed property creates request.
 * This table is rendered in an error dialog when at least one property creation failed by the excel upload.
 * @param {Object} props - The props object for the component.
 * @param {Array} props.requestErrors - An array of objects representing the errors in the request.
 * @param {number} props.requestErrors[].rowIndex - The index of the row that the error occurred in.
 * @param {string} props.requestErrors[].message - The error message.
 * @returns {JSX.Element} A JSX element representing the MultiPropertyUploadRequestErrorTable component.
 */
const MultiPropertyUploadRequestErrorTable = ({ requestErrors }) => {
  const { t: tMultiPropertyUpload } = useTranslation('translation', {
    keyPrefix: 'pages.property-upload',
  })
  return (
    <Table
      columns={
        <>
          <TableColumn>{tMultiPropertyUpload('column.row-index')}</TableColumn>
          <TableColumn>{tMultiPropertyUpload('column.error-type')}</TableColumn>
        </>
      }
    >
      {requestErrors.map((error, index) => (
        <TableRow key={index}>
          <TableCell key={`${index}-row`}>{error.rowIndex}</TableCell>
          <TableCell key={`${index}-type`}>
            <MultiPropertyUploadDownloadErrorButton
              rowIndex={error.rowIndex}
              content={error.message}
            />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
MultiPropertyUploadRequestErrorTable.propTypes = {
  requestErrors: PropTypes.array.isRequired,
}
export default MultiPropertyUploadRequestErrorTable
