import { useMutation, useQueryClient } from '@tanstack/react-query'
import uniqBy from 'lodash.uniqby'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useCwpBusinessPartnerRoles } from 'hooks/services/properties/rent-roll/working-version/useCwpBusinessPartnerRoles'

export const useAppendTenantRoleToTenants = (options = {}) => {
  const { post } = useAccessTokenRequest()
  const queryClient = useQueryClient()
  const businessPartnerRolesResponse = useCwpBusinessPartnerRoles()
  const businessPartnerRoles =
    businessPartnerRolesResponse?.data?.configSapBusinessPartnerRoles ?? []
  const tenantWriteCode = businessPartnerRoles.find(
    (role) => role.isWriteCode && role.role === 'tenant',
  )?.businessPartnerRoleCode

  const getTenantsWithoutRole = (tenants) => {
    const tenantRoles = businessPartnerRoles.filter((role) => role.role === 'tenant')
    return tenants.filter((tenant) => {
      const backendRoleIds = tenant.roles
        ?.map((role) => role.backEndRoles.map((backendRole) => backendRole.id))
        .flat()
      return !backendRoleIds?.some((backendRoleId) =>
        tenantRoles.some((role) => role.businessPartnerRoleCode === backendRoleId),
      )
    })
  }

  const getPreviousInactiveTenants = (tenants, previousInactiveBps) =>
    tenants.filter((tenant) =>
      previousInactiveBps.some((prevTenant) => prevTenant.id === tenant.id),
    )

  const obtainKeys = (businessPartnerId) => ['businesspartners', businessPartnerId]
  return useMutation(
    async ({ tenants, previousInactiveBps }) => {
      if (!tenants?.length || !tenantWriteCode || !businessPartnerRolesResponse.isSuccess) {
        return []
      }
      const previousInactiveTenants = getPreviousInactiveTenants(tenants, previousInactiveBps || [])
      const businessPartnersWithoutRole = getTenantsWithoutRole(
        uniqBy([...tenants, ...previousInactiveTenants], 'id'),
      )

      await Promise.all(
        businessPartnersWithoutRole.map(async (businessPartner) => {
          await post({
            path: `/businesspartners/${businessPartner.id}/roles?role_id=${tenantWriteCode}`,
          })
        }),
      )
      return businessPartnersWithoutRole
    },
    {
      ...options,
      onSettled: async (data, _error) =>
        data.forEach((businessPartner) => {
          queryClient.invalidateQueries(obtainKeys(businessPartner.id))
        }),
    },
  )
}
