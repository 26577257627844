import { useMemo } from 'react'
import { useConfig } from 'hooks/config/useConfig'
import useSelectStaffMembersHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/general-information/helper/useSelectStaffMembersHelper'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import useBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationships'
import useStaffMemberByIds from 'hooks/services/business-partners/staff-members/useStaffMemberByIds'
import useDealTeam from 'hooks/services/deals/deal-team/useDealTeam'

const useGeneralInformationUsers = ({ dealUuid, borrowerBpId }) => {
  const {
    isLoading: isDealTeamLoading,
    isFetching: isDealTeamFetching,
    isError: isDealTeamError,
    error: dealTeamError,
    data: dealTeamData,
  } = useDealTeam({ dealUuid })

  const {
    isLoading: isRelationshipsLoading,
    isFetching: isRelationshipsFetching,
    isError: isRelationshipsError,
    error: relationshipsError,
    data: relationshipsData,
  } = useBusinessPartnerRelationships(borrowerBpId)

  const {
    isLoading: isBorrowerLoading,
    isFetching: isBorrowerFetching,
    isError: isBorrowerError,
    error: borrowerError,
    data: borrowerData,
  } = useBusinessPartnerById(borrowerBpId)

  const {
    data: config,
    isFetching: isConfigFetching,
    isLoading: isConfigLoading,
    isError: isConfigError,
    error: configError,
  } = useConfig()

  const sapRelationshipIds = config?.businessPartner?.sapRelationshipIds ?? {}
  const { accountManagerUserId, loanAccountManagerUserId, underwriterUserId } =
    useSelectStaffMembersHelper({
      dealTeamStaffMembers: dealTeamData?.staffMembers,
      accountManagerSapRelationId: sapRelationshipIds.accountManager,
      loanAccountManagerSapRelationId: sapRelationshipIds.loanAccountManager,
      underwriterSapRelationId: sapRelationshipIds.underwriter,
    })

  const userIds = [accountManagerUserId, loanAccountManagerUserId, underwriterUserId].filter(
    (userId) => !!userId,
  )

  const {
    data: staffMembersData,
    isFetching: isStaffMembersFetching,
    isLoading: isStaffMembersLoading,
    isError: isStaffMembersError,
    error: staffMembersError,
  } = useStaffMemberByIds(userIds)

  const staffMembers = useMemo(
    () => ({
      accountManager: (staffMembersData ?? []).find(({ id }) => id === accountManagerUserId),
      loanAccountManager: (staffMembersData ?? []).find(
        ({ id }) => id === loanAccountManagerUserId,
      ),
      underwriter: (staffMembersData ?? []).find(({ id }) => id === underwriterUserId),
    }),
    [accountManagerUserId, loanAccountManagerUserId, staffMembersData, underwriterUserId],
  )

  const sponsors = useMemo(
    () =>
      (relationshipsData?.unitRelationships ?? [])
        .filter(({ id: relationshipId }) => relationshipId === sapRelationshipIds.sponsor)
        .flatMap(({ relationships }) => relationships.map(({ head }) => head)),
    [relationshipsData?.unitRelationships, sapRelationshipIds.sponsor],
  )

  const isAnyLoading = useMemo(
    () =>
      [
        ...(userIds.length > 0 ? [isStaffMembersLoading] : []),
        isDealTeamLoading,
        isRelationshipsLoading,
        isConfigLoading,
        isBorrowerLoading,
      ].some((isLoading) => isLoading),
    [
      isBorrowerLoading,
      isConfigLoading,
      isDealTeamLoading,
      isRelationshipsLoading,
      isStaffMembersLoading,
      userIds.length,
    ],
  )

  const isAnyFetching = useMemo(
    () =>
      [
        isDealTeamFetching,
        isRelationshipsFetching,
        isConfigFetching,
        isBorrowerFetching,
        isStaffMembersFetching,
      ].some((isFetching) => isFetching),
    [
      isBorrowerFetching,
      isConfigFetching,
      isDealTeamFetching,
      isRelationshipsFetching,
      isStaffMembersFetching,
    ],
  )

  const isAnyError = useMemo(
    () =>
      [
        isStaffMembersError,
        isDealTeamError,
        isRelationshipsError,
        isConfigError,
        isBorrowerError,
      ].some((isError) => isError),
    [isBorrowerError, isConfigError, isDealTeamError, isRelationshipsError, isStaffMembersError],
  )

  const errorDetails = useMemo(
    () =>
      [staffMembersError, dealTeamError, relationshipsError, configError, borrowerError].filter(
        (error) => !!error,
      ),
    [borrowerError, configError, dealTeamError, relationshipsError, staffMembersError],
  )

  return useMemo(
    () => ({
      isAnyLoading,
      isAnyFetching,
      isAnyError,
      errorDetails,
      data: {
        borrower: borrowerData,
        staffMembers,
        sponsors,
      },
    }),
    [borrowerData, errorDetails, isAnyError, isAnyFetching, isAnyLoading, sponsors, staffMembers],
  )
}

export default useGeneralInformationUsers
