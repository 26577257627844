import { isMissingPermissionError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useOtherFinancingSource = (dealUuid, options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/financing-structures/deals/${dealUuid}/other-financing-sources`,
      translated: true,
      keys: ['deals', dealUuid, 'otherFinancingSource'],
      options: {
        retry: (_, error) => !isMissingPermissionError(error),
        ...options,
      },
    }),
  )

export default useOtherFinancingSource
