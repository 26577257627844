import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ConditionRequirementChangelogConditionLink from 'components/domains/conditions/dialogs/changelog/table/table-cells/ConditionRequirementChangelogConditionLink'
import ConditionRequirementChangelogRequirementLink from 'components/domains/conditions/dialogs/changelog/table/table-cells/ConditionRequirementChangelogRequirementLink'
import { ENTITIES } from 'components/domains/deals/change-overview/constants'

const fieldTranslationPrefix = 'components.conditions.overview.dialog.changelog.table.fields'

const ConditionRequirementChangelogEntryCell = ({
  entityId,
  entityType,
  changedFields,
  action,
}) => {
  const { t: tFields } = useTranslation('translation', {
    keyPrefix: fieldTranslationPrefix,
  })
  const getFieldTranslation = useCallback(
    (fieldName) => {
      const fieldTranslation = tFields(fieldName)
      return fieldTranslation.includes(fieldTranslationPrefix) ? fieldName : fieldTranslation
    },
    [tFields],
  )
  const entityCell = useMemo(() => {
    switch (entityType) {
      case ENTITIES.EXTERNAL_CONDITION:
      case ENTITIES.INTERNAL_CONDITION:
        return (
          <ConditionRequirementChangelogConditionLink
            action={action}
            entityType={entityType}
            conditionId={entityId}
          />
        )
      case ENTITIES.REQUIREMENT:
        return (
          <ConditionRequirementChangelogRequirementLink action={action} requirementId={entityId} />
        )
      default:
        return null
    }
  }, [action, entityId, entityType])
  const changedFieldsCell = useMemo(
    () => <div>{changedFields?.map((field) => getFieldTranslation(field)).join(', ')}</div>,
    [changedFields, getFieldTranslation],
  )

  return (
    <>
      {entityCell}
      {changedFieldsCell}
    </>
  )
}

ConditionRequirementChangelogEntryCell.propTypes = {
  action: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  changedFields: PropTypes.arrayOf(PropTypes.string),
}

export default ConditionRequirementChangelogEntryCell
