import { ToolbarSeparator } from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import DealCashflowDetailsCreateButton from 'components/domains/deals/deal-cashflow/DealCashflowDetailsCreateButton'
import DealCashflowDetailsDeleteButton from 'components/domains/deals/deal-cashflow/DealCashflowDetailsDeleteButton'
import DealCashflowDetailsDuplicateButton from 'components/domains/deals/deal-cashflow/DealCashflowDetailsDuplicateButton'
import DealCashflowDetailsMainToggle from 'components/domains/deals/deal-cashflow/DealCashflowDetailsMainToggle'
import DealCashflowDetailsStartButton from 'components/domains/deals/deal-cashflow/DealCashflowDetailsStartButton'
import { DealCashflowScenarioGeneralInformationCard } from 'components/domains/deals/deal-cashflow/general-information/DealCashflowScenarioGeneralInformationCard'
import DealCashflowInputParametersCard from 'components/domains/deals/deal-cashflow/input-parameters/DealCashflowInputParametersCard'
import DealCashflowDebtAndEquityCardWrapper from 'components/domains/deals/deal-cashflow/result-charts/DealCashflowDebtAndEquityCardWrapper'
import DealCashflowFinancialRatioCardWrapper from 'components/domains/deals/deal-cashflow/result-charts/DealCashflowFinancialRatioCardWrapper'
import DealCashflowVacancyCardWrapper from 'components/domains/deals/deal-cashflow/result-charts/DealCashflowVacancyCardWrapper'
import CashflowResults from 'components/domains/deals/deal-cashflow/results/CashflowResults'
import { DealCashflowUsedParametersCard } from 'components/domains/deals/deal-cashflow/used-parameters/DealCashflowUsedParametersCard'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Section from 'components/ui/page/Section'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useCashFlowScenario } from 'hooks/services/deals/cashflow/useCashFlowScenario'
import usePbbCustomServiceAllowedOperations from 'hooks/services/pbb-custom-service/usePbbCustomServiceAllowedOperations'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'
import paths from 'routes/paths'
// default selected chart values defined in https://fioneer.atlassian.net/browse/CWP-3314
const PRESELECTED_KPI_RIGHT_CHART = 'DY_STD'
const PRESELECTED_KPI_LEFT_CHART = 'DSC_STD'

const DealCashflowDetails = () => {
  const { deal, allowedOperations } = useContext(DealContext)
  const { cashflowId } = useParams()

  const { data: allowedOperationsPbb } = usePbbCustomServiceAllowedOperations()

  const hasPermission = (cfScenarioPermission) => allowedOperations?.includes(cfScenarioPermission)
  const hasUpdatePermissionPBB = allowedOperationsPbb?.allowedOperations.includes(
    dealsPermissions.updateCashflowScenarioPBB,
  )

  const {
    data: { name, frozen, externalScenarioId, cashflowScenarioUuid, isMainScenario } = {},
    data = {},
    isLoading,
    isFetching,
    isError,
  } = useCashFlowScenario(deal.dealUuid, cashflowId, {
    options: {
      enabled:
        !!deal.dealUuid && !!cashflowId && !!hasPermission(dealsPermissions.readCashflowScenario),
    },
  })
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals' })

  const isWorkingVersion = useMemo(
    () => WorkingVersionType.WORKING_VERSION === deal.workingVersion,
    [deal],
  )

  const workingVersionSuffix = useMemo(
    () => (isWorkingVersion ? '?working-version=true' : ''),
    [isWorkingVersion],
  )

  const breadcrumbs = useMemo(
    () => [
      {
        text: t('detail.cashflow.title'),
        href: `/${paths.deals}/${deal.displayId}/cashflows${workingVersionSuffix}`,
      },
      {
        text: name ?? cashflowId,
      },
    ],
    [t, name, cashflowId, deal, workingVersionSuffix],
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={deal.dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [deal.dealUuid],
  )

  const renderActions = () => {
    const buttons = []
    const createEventAction = <CreateDealEventAction key="create-event-action" />

    if (!cashflowScenarioUuid) {
      return (
        <>
          {createEventAction}, {markFavoriteAction}
        </>
      )
    }

    if (
      hasPermission(dealsPermissions.updateCashflowScenarioGeneralInformation) ||
      hasPermission(dealsPermissions.updateCashflowScenarioMainScenario)
    ) {
      buttons.push(
        <DealCashflowDetailsMainToggle
          key="main"
          dealUuid={deal.dealUuid}
          cashflowScenarioUuid={cashflowScenarioUuid}
          isMainScenario={isMainScenario}
        />,
      )
    }

    if (
      hasPermission(dealsPermissions.updateCashflowScenario) &&
      hasUpdatePermissionPBB &&
      !externalScenarioId &&
      !frozen
    ) {
      buttons.push(
        <DealCashflowDetailsCreateButton
          key="create"
          dealUuid={data.dealUuid}
          cashflowScenarioUuid={cashflowScenarioUuid}
        />,
      )
    }

    if (externalScenarioId && hasUpdatePermissionPBB) {
      buttons.push(
        <DealCashflowDetailsStartButton
          key="start"
          dealUuid={deal.dealUuid}
          cashflowScenarioUuid={cashflowScenarioUuid}
        />,
      )
    }

    if (hasPermission(dealsPermissions.createCashflowScenario)) {
      buttons.push(
        <DealCashflowDetailsDuplicateButton
          key="duplicate"
          dealId={deal.displayId}
          originalCashflowScenarioUuid={cashflowScenarioUuid}
        />,
      )
    }

    if (hasPermission(dealsPermissions.deleteCashflowScenario) && !frozen) {
      if (hasPermission(dealsPermissions.updateCashflowScenario)) {
        buttons.push(<ToolbarSeparator key={'separator'} />)
      }

      buttons.push(
        <DealCashflowDetailsDeleteButton
          key="delete"
          dealUuid={deal.dealUuid}
          dealDisplayId={deal.displayId}
          externalScenarioId={externalScenarioId}
          cashflowScenarioUuid={cashflowScenarioUuid}
        />,
      )
    }

    buttons.push(createEventAction)
    buttons.push(markFavoriteAction)

    return <>{buttons}</>
  }

  const renderCashflowScenarioDetails = () => {
    if (!hasPermission(dealsPermissions.readCashflowScenario)) {
      return (
        <CenteredIllustratedMessage
          name="UnableToLoad"
          size="Spot"
          titleText={t('not-allowed.title')}
          subtitleText={t('not-allowed.subtitle')}
        />
      )
    }

    return (
      <>
        <Section titleText={t('detail.cashflow.details.subtitle')}>
          <CWPLayout>
            <DealCashflowScenarioGeneralInformationCard
              isAllowedToEdit={
                hasPermission(dealsPermissions.updateCashflowScenarioGeneralInformation) && !frozen
              }
            />
          </CWPLayout>
        </Section>
        <Section titleText={t('detail.cashflow.details.calculation-result-charts')}>
          <CWPLayout>
            <DealCashflowFinancialRatioCardWrapper
              dealUuid={deal.dealUuid}
              cashflowScenarioUuid={cashflowId}
              defaultKpiCode={PRESELECTED_KPI_LEFT_CHART}
            />
            <DealCashflowFinancialRatioCardWrapper
              dealUuid={deal.dealUuid}
              cashflowScenarioUuid={cashflowId}
              defaultKpiCode={PRESELECTED_KPI_RIGHT_CHART}
            />
            <DealCashflowDebtAndEquityCardWrapper
              dealUuid={deal.dealUuid}
              cashflowScenarioUuid={cashflowId}
            />
            <DealCashflowVacancyCardWrapper
              dealUuid={deal.dealUuid}
              cashflowScenarioUuid={cashflowId}
            />
          </CWPLayout>
        </Section>
        <Section titleText={t('detail.cashflow.parameters.title')}>
          <CWPLayout>
            <CWPLayout.Full>
              <DealCashflowInputParametersCard cashflowScenario={data} />
            </CWPLayout.Full>
            <CWPLayout.Full>
              <DealCashflowUsedParametersCard cashFlowScenario={data} />
            </CWPLayout.Full>
          </CWPLayout>
        </Section>
        <CashflowResults dealUuid={deal.dealUuid} cashflowId={cashflowId} />
      </>
    )
  }

  return (
    <DealDetailsWrapper
      childBreadcrumbs={breadcrumbs}
      actions={hasPermission(dealsPermissions.readCashflowScenario) && renderActions()}
      showWVLink={false}
    >
      <LoadingStateWrapper
        isLoading={isLoading && isFetching}
        isError={isError}
        renderContent={renderCashflowScenarioDetails}
      />
    </DealDetailsWrapper>
  )
}

export default DealCashflowDetails
