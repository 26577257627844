import isNil from 'lodash.isnil'
import { useRequest } from 'hooks/services/baseService'

const useMarketTypes = ({ propertyType, includeDeprecated } = {}) => {
  const queryParams = new URLSearchParams()

  if (propertyType) {
    queryParams.append('property_type', propertyType)
  }
  if (!isNil(includeDeprecated)) {
    queryParams.append('include_deprecated', includeDeprecated)
  }

  return useRequest({
    path: `/markets/configurations/types?${queryParams.toString()}`,
    translated: true,
  })
}

export default useMarketTypes
