import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useConditionsCellEditMode = ({ conditionId }) => {
  const editedRowConditionId = useSelector(
    (state) => state.conditions.conditionsTable.editedRow.conditionId,
  )
  const isEditModeForCurrentRow = useMemo(
    () => editedRowConditionId === conditionId,
    [editedRowConditionId, conditionId],
  )

  const isEditModeForAnyRow = useMemo(() => !!editedRowConditionId, [editedRowConditionId])

  return useMemo(
    () => ({
      editedRowConditionId,
      isEditModeForCurrentRow,
      isEditModeForAnyRow,
    }),
    [editedRowConditionId, isEditModeForCurrentRow, isEditModeForAnyRow],
  )
}

export default useConditionsCellEditMode
