import { Input, Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CardEditItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import { cashflowScenarioShape } from 'components/domains/deals/deal-cashflow/general-information/DealCashflowScenarioGeneralInformationCardDisplay'
import { useExternalInformationFields } from 'components/domains/deals/deal-cashflow/general-information/useExternalInformationFields'
import TextAreaWithMaxCharacterRestriction from 'components/ui/input/TextAreaWithMaxCharacterRestriction'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import { useBooleanToTextFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'
import useMultipleStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useMultipleStaffMemberByObjectIdOrEmail'
import { UserProfileContext } from 'routes/UserProfileContext'

const nameMaxLength = 75
const descriptionMaxLength = 200

const DealCashflowScenarioGeneralInformationCardEdit = ({
  formState,
  updateFormState,
  cashflowScenario,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.cashFlow.details.general-information',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const { zoneId: timeZone } = useContext(UserProfileContext) ?? {}

  const formatBoolean = useBooleanToTextFormatter()
  const { format: formatDateAndTime } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
    timeZone,
  })

  const statusConfig = {
    LINKED: { color: ValueState.Success, label: t('status.linked') },
    NOT_LINKED: { color: ValueState.Warning, label: t('status.not-linked') },
  }[cashflowScenario?.status]

  const {
    data: staffMembers,
    isSomeLoading: isLoadingStaffMembers = false,
    isSomeError: isErrorStaffMembers = false,
  } = useMultipleStaffMemberByObjectIdOrEmail({
    objectIdOrEmailAddressList: [cashflowScenario?.createdBy, cashflowScenario?.lastUpdatedBy],
  })

  const { data: editItems } = useExternalInformationFields(cashflowScenario.extensionFields, false)

  return (
    <>
      <CardSection title={t('subtitle.basic-information')}>
        <CardEditItem
          label={t('scenario-name')}
          required
          value={
            <Input
              id="name-input"
              maxLength={nameMaxLength}
              value={formState.name}
              valueState={formState.name ? ValueState.None : ValueState.Error}
              valueStateMessage={<Text>{tNoPrefix('form.validation.required')}</Text>}
              onChange={(event) => updateFormState({ name: event.target.value })}
            />
          }
        />
        <CardEditItem label={t('status')} value={<Input readonly value={statusConfig?.label} />} />
        <CardEditItem
          label={t('frozen')}
          value={<Input readonly value={formatBoolean(cashflowScenario?.frozen)} />}
        />
        <CardEditItem
          label={t('created-by')}
          value={
            <SmallLoadingWrapper
              error={t('error')}
              isLoading={isLoadingStaffMembers}
              isError={isErrorStaffMembers}
              renderContent={() => (
                <Input readonly value={staffMembers[cashflowScenario?.createdBy]?.fullName} />
              )}
            />
          }
        />
        <CardEditItem
          label={t('created-at')}
          value={<Input readonly value={formatDateAndTime(cashflowScenario?.createdAt)} />}
        />
        <CardEditItem
          label={t('description')}
          value={
            <div>
              <TextAreaWithMaxCharacterRestriction
                id="description-textbox"
                maxLength={descriptionMaxLength}
                value={formState.description}
                onInput={(value) => updateFormState({ description: value })}
                rows="4"
              />
            </div>
          }
        />
      </CardSection>
      <CardSection title={t('subtitle.external-information')}>
        <CardEditItem
          label={t('external-scenario-id')}
          value={<Input readonly value={cashflowScenario?.externalScenarioId} />}
        />
        {editItems}
      </CardSection>
    </>
  )
}

DealCashflowScenarioGeneralInformationCardEdit.propTypes = {
  formState: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  updateFormState: PropTypes.func,
  cashflowScenario: cashflowScenarioShape,
}

export default DealCashflowScenarioGeneralInformationCardEdit
