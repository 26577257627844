import {
  Button,
  ButtonDesign,
  DynamicPage,
  DynamicPageHeader,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import pickBy from 'lodash.pickby'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import DealReportConflictDialog from 'components/domains/deals/conflict-clearance/DealReportConflictDialog'
import DealCreationDialog from 'components/domains/deals/creation-dialog/DealCreationDialog'
import DealCreationFormProvider from 'components/domains/deals/creation-dialog/DealCreationFormProvider'
import { DealFilterBar, DealFilterKeys } from 'components/domains/deals/deal-search/DealFilterBar'
import DealSearchTable, {
  DealSearchTableMode,
} from 'components/domains/deals/deal-search/DealSearchTable'
import Card from 'components/ui/card/Card'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import Header from 'components/ui/page/Header'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useDealPermissions } from 'hooks/services/deals/useDealPermissions'
import useDeals from 'hooks/services/deals/useDeals'
import { useInfiniteLoading } from 'hooks/services/useInfiniteLoading'
import styles from 'routes/deals/DealsListPage.module.css'

const buildInitialSorting = (queryParams) => ({
  orderField: queryParams.get('orderField') ?? DealFilterKeys.CreatedAt,
  orderDirection: queryParams.get('orderDirection') ?? 'desc',
})

const buildInitialFilterParams = (queryParams) => {
  //get filter + sorting parameters from queryParams
  const initialFilterParameters = Object.fromEntries(queryParams)

  //strip sorting parameters
  if (initialFilterParameters.orderField) delete initialFilterParameters.orderField
  if (initialFilterParameters.orderDirection) delete initialFilterParameters.orderDirection
  return initialFilterParameters
}

const REPORT_CONFLICT_QUERY_KEY = 'reportConflict'

const visibleFilters = Object.values(DealFilterKeys)
  .map((value) => (DealFilterKeys.WorkingVersion === value ? undefined : value))
  .filter(Boolean)

const DealsListPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals' })
  const [isCreationDialogOpen, setIsCreationDialogOpen] = useState(false)
  const [queryParams, setQueryParams] = useSearchParams()
  const [filterParams, setFilterParams] = useState(buildInitialFilterParams(queryParams))
  const [sortingParams, setSortingParams] = useState(buildInitialSorting(queryParams))
  const reportDialogRef = useRef()
  const showMessageBox = useShowMessageBox()

  const updateFilters = (filterParams, sortingParams) => {
    setFilterParams(filterParams)
    setSortingParams(sortingParams)
    setFilterParams(filterParams)
    setSortingParams(sortingParams)
    // remove falsy values from filterParams
    setQueryParams(
      pickBy(
        {
          ...filterParams,
          ...sortingParams,
        },
        (x) => !isEmpty(x),
      ),
    )
  }

  const handleFilterbarGo = (newFilters) => {
    updateFilters(newFilters, sortingParams)
  }

  const onSortingChanged = (orderField, orderDirection) => {
    const sortingParams = { orderField, orderDirection }
    updateFilters(filterParams, sortingParams)
  }

  const {
    isLoading: isLoadingAllowedOperations,
    isError: isErrorAllowedOperations,
    data: dealPermissions = {},
  } = useDealPermissions()
  const { allowedOperations = [] } = dealPermissions

  const isAllowedDealListRead = allowedOperations.includes(dealsPermissions.readDealList)
  const isAllowedDealRead = allowedOperations.includes(dealsPermissions.readDeal)
  const isAllowedDealCreate = allowedOperations.includes(dealsPermissions.createDeal)
  const isAllowedDealReportConflict = allowedOperations.includes(
    dealsPermissions.reportDealConflict,
  )
  const onEventCreationButtonClicked = () => {
    setIsCreationDialogOpen(true)
  }

  const reportConflictDealUuid = useMemo(
    () => queryParams.get(REPORT_CONFLICT_QUERY_KEY),
    [queryParams],
  )

  useEffect(() => {
    if (isLoadingAllowedOperations || isErrorAllowedOperations) return
    if (isAllowedDealReportConflict && reportConflictDealUuid) {
      reportDialogRef.current?.show()
      return
    }
    if (reportConflictDealUuid) {
      showMessageBox({ children: t('report-conflict.message.no-permission') })
    }
  }, [
    isAllowedDealReportConflict,
    isErrorAllowedOperations,
    isLoadingAllowedOperations,
    reportConflictDealUuid,
    showMessageBox,
    t,
  ])

  const actionButton = isAllowedDealCreate ? (
    <Button
      id="create-deal"
      design={ButtonDesign.Emphasized}
      onClick={onEventCreationButtonClicked}
    >
      {t('create')}
    </Button>
  ) : undefined

  const breadcrumbs = [{ text: t('subtitle') }]

  const headerTitle = <Header title={t('title')} actions={actionButton} breadcrumbs={breadcrumbs} />

  const {
    isLoading: isLoadingDeals,
    isError: isErrorDeals,
    data: { deals = [], pagination = { offset: 0, limit: 0, total: 0 } } = {},
    loadMore: loadMoreDeals,
  } = useInfiniteLoading({
    requestHook: useDeals,
    requestHookArgs: {
      filter: {
        dealId: filterParams[DealFilterKeys.Id],
        dealName: filterParams[DealFilterKeys.Name],
        accountManager: filterParams[DealFilterKeys.AccountManager],
        businessSegments: filterParams[DealFilterKeys.BusinessSegments],
        dealTypes: filterParams[DealFilterKeys.Type],
        originationPlatform: filterParams[DealFilterKeys.OriginationPlatform],
        originationTeam: filterParams[DealFilterKeys.OriginationTeam],
        status: filterParams[DealFilterKeys.Status],
        borrowerId: filterParams[DealFilterKeys.Borrower],
        workingVersion: filterParams[DealFilterKeys.WorkingVersion],
      },
      sort: sortingParams,
    },
    dataListElement: 'deals',
    paginationDelta: 50,
  })

  const renderTable = () => (
    <DealSearchTable
      deals={deals}
      mode={DealSearchTableMode.NavigateOnClick}
      pagination={pagination}
      orderField={sortingParams.orderField}
      orderDirection={sortingParams.orderDirection}
      onSortingChanged={onSortingChanged}
      isError={isErrorDeals || isErrorAllowedOperations}
      isLoading={isLoadingDeals || isLoadingAllowedOperations}
      loadMore={loadMoreDeals}
      isAllowedToReadDeal={isAllowedDealRead}
    />
  )

  const noPermissionsIllustratedMessage = (
    <CenteredIllustratedMessage
      name="UnableToLoad"
      size="Spot"
      titleText={t('not-allowed.title')}
      subtitleText={t('not-allowed.subtitle')}
    />
  )

  return (
    <>
      <DynamicPage
        showHideHeaderButton={false}
        headerContentPinnable={false}
        headerTitle={headerTitle}
      >
        {!isLoadingAllowedOperations && !isErrorAllowedOperations && !isAllowedDealListRead ? (
          noPermissionsIllustratedMessage
        ) : (
          <Card>
            <DynamicPageHeader>
              <DealFilterBar
                onGo={handleFilterbarGo}
                initialValues={filterParams}
                visibleFilters={visibleFilters}
              />
            </DynamicPageHeader>
            <div className={styles['table-wrapper']}>
              <LoadingStateWrapper
                isError={isErrorDeals || isErrorAllowedOperations}
                isLoading={isLoadingDeals || isLoadingAllowedOperations}
                renderContent={renderTable}
                errorTitle={t('error.title')}
                errorDescription={t('error.description')}
              />
            </div>
          </Card>
        )}
        {createPortal(
          <DealReportConflictDialog ref={reportDialogRef} dealUuid={reportConflictDealUuid} />,
          document.body,
        )}
      </DynamicPage>
      <DealCreationFormProvider>
        <DealCreationDialog isOpen={isCreationDialogOpen} setIsOpen={setIsCreationDialogOpen} />
      </DealCreationFormProvider>
    </>
  )
}

export default DealsListPage
