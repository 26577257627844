import { useMemo } from 'react'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useGetBusinessObjectsName } from 'hooks/services/conditions/business-objects/useGetBusinessObjectsName'

const fallbackResponse = {
  dealIds: [],
  propertyIds: [],
  businessPartnerIds: [],
}

const useExternalConditionsReferencesResolver = ({ selectedConditions, enabled }) => {
  const { dealIds, propertyIds, businessPartnerIds } = useMemo(() => {
    if (!enabled) {
      return fallbackResponse
    }
    const { internalDealIds, internalPropertyIds, internalBusinessPartnerIds } =
      selectedConditions.reduce(
        (acc, condition) => {
          if (!condition.references) {
            return acc
          }

          switch (condition.references.entityType) {
            case cwpEntityTypes.DEAL:
              return {
                ...acc,
                internalDealIds: [...acc.internalDealIds, ...condition.references.entityIds],
              }
            case cwpEntityTypes.PROPERTY:
              return {
                ...acc,
                internalPropertyIds: [
                  ...acc.internalPropertyIds,
                  ...condition.references.entityIds,
                ],
              }
            case cwpEntityTypes.BUSINESS_PARTNER:
              return {
                ...acc,
                internalBusinessPartnerIds: [
                  ...acc.internalBusinessPartnerIds,
                  ...condition.references.entityIds,
                ],
              }
          }
          return acc
        },
        {
          internalDealIds: [],
          internalPropertyIds: [],
          internalBusinessPartnerIds: [],
        },
      )

    return {
      dealIds: [...new Set(internalDealIds)],
      propertyIds: [...new Set(internalPropertyIds)],
      businessPartnerIds: [...new Set(internalBusinessPartnerIds)],
    }
  }, [enabled, selectedConditions])

  const {
    data: resolvedDeals,
    isLoading: isDealsLoading,
    isError: isDealsError,
  } = useGetBusinessObjectsName({
    entityType: cwpEntityTypes.DEAL,
    entityIds: dealIds,
  })

  const {
    data: resolvedBusinessPartners,
    isLoading: isBusinessPartnerLoading,
    isError: isBusinessPartnerError,
  } = useGetBusinessObjectsName({
    entityType: cwpEntityTypes.BUSINESS_PARTNER,
    entityIds: businessPartnerIds,
  })

  const {
    data: resolvedProperties,
    isLoading: isPropertyLoading,
    isError: isPropertyError,
  } = useGetBusinessObjectsName({
    entityType: cwpEntityTypes.PROPERTY,
    entityIds: propertyIds,
  })

  const isAnyLoading = isPropertyLoading || isDealsLoading || isBusinessPartnerLoading
  const isAnyError = isPropertyError || isDealsError || isBusinessPartnerError

  const mappedSelectedConditions = useMemo(() => {
    if (!enabled || isAnyError || isAnyLoading) {
      return []
    }
    return selectedConditions.map((condition) => {
      if (!condition.references) {
        return condition
      }
      const entityType = condition.references.entityType
      const resolvedEntityInformation = condition.references.entityIds.map((entityId) => {
        switch (entityType) {
          case cwpEntityTypes.DEAL:
            return resolvedDeals.find((deal) => deal.entityId === entityId)
          case cwpEntityTypes.PROPERTY:
            return resolvedProperties.find((property) => property.entityId === entityId)
          case cwpEntityTypes.BUSINESS_PARTNER:
            return resolvedBusinessPartners.find(
              (businessPartner) => businessPartner.entityId === entityId,
            )
          default:
            return { entityId, name: entityId }
        }
      })

      return {
        ...condition,
        references: {
          ...condition.references,
          resolvedEntityInformation,
        },
      }
    })
  }, [
    enabled,
    isAnyError,
    isAnyLoading,
    resolvedBusinessPartners,
    resolvedDeals,
    resolvedProperties,
    selectedConditions,
  ])

  return {
    mappedSelectedConditions,
    isLoading: isAnyLoading,
    isError: isAnyError,
  }
}

export default useExternalConditionsReferencesResolver
