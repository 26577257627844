import {
  DynamicPage,
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, matchPath, useMatch, useParams } from 'react-router-dom'
import {
  businessEventAllowedOperations,
  neededOperationsForDecisionPaperRead,
} from 'api/events/eventAllowedOperations'
import { hasUserRequiredOperations } from 'api/helper'
import { isMissingPermissionError } from 'api/requests'
import NavigationL2 from 'components/ui/navigation/NavigationL2'
import Header from 'components/ui/page/Header'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useEvent from 'hooks/services/business-events-and-tasks/events/useEvent'
import BusinessEventsAndTasks from 'routes/business-events-and-tasks/BusinessEventsAndTasks'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import DecisionStageRedirect from 'routes/business-events-and-tasks/DecisionStageRedirect'
import DecisionPaper from 'routes/business-events-and-tasks/decision-paper/DecisionPaper'
import DecisionProcess from 'routes/business-events-and-tasks/decision-process/DecisionProcess'
import DecisionStage from 'routes/business-events-and-tasks/decision-process/stage/DecisionStage'
import DecisionStageApproval from 'routes/business-events-and-tasks/decision-process/stage/DecisionStageApproval'
import DecisionStageConditionsApproval from 'routes/business-events-and-tasks/decision-process/stage/DecisionStageConditionsApproval'
import DecisionStageMinutesApproval from 'routes/business-events-and-tasks/decision-process/stage/DecisionStageMinutesApproval'
import EventCreationQueryParametersWrapper from 'routes/business-events-and-tasks/events/EventCreationQueryParametersWrapper'
import EventDetail from 'routes/business-events-and-tasks/events/EventDetail'
import TaskDetail from 'routes/business-events-and-tasks/tasks/TaskDetail'
import paths from 'routes/paths'
export const eventsAndTasksPaths = {
  overview: '',
  eventDetail: {
    main: 'business-events/:eventId/*',
    detail: '',
    taskDetail: 'tasks/:taskId',
    decisionPaper: 'decision-paper',
    decisionProcess: 'decision-process',
    decisionStageDetail: 'decision-process/decision-stages/:decisionStageId',
    decisionStageDetailOld: 'decision-stages/:decisionStageId',
    decisionStageApproval:
      'decision-process/decision-stages/:decisionStageId/approval/voters/:voterId',
    decisionStageApprovalOld: 'decision-stages/:decisionStageId/approval/voters/:voterId',
    decisionStageConditionsApproval:
      'decision-process/decision-stages/:decisionStageId/conditions/approval/voters/:voterId',
    decisionStageConditionsApprovalOld:
      'decision-stages/:decisionStageId/conditions/approval/voters/:voterId',
    decisionStageMinutesApproval:
      'decision-process/decision-stages/:decisionStageId/minutes/approval/voters/:voterId',
    decisionStageMinutesApprovalOld:
      'decision-stages/:decisionStageId/minutes/approval/voters/:voterId',
  },
  create: 'business-events/create',
}

const hasDecisionPaperReadPermission = (allowedOperations) =>
  hasUserRequiredOperations(neededOperationsForDecisionPaperRead, allowedOperations)

const hasDecisionProcessReadPermission = (allowedOperations) =>
  (allowedOperations ?? []).includes(businessEventAllowedOperations.readDecisionStage)

const EventDetailWrapper = () => {
  const { t: tNoPrefix } = useTranslation('translation')
  const { t } = useTranslation('translation', { keyPrefix: 'pages.event-detail' })
  const { eventId } = useParams()
  const { data: event, isLoading, isError, error } = useEvent(eventId)
  const stageApprovalPathPattern = `/${paths.businessEventsAndTasks}/business-events/:eventId/${eventsAndTasksPaths.eventDetail.decisionStageApproval}/`
  const stageConditionsApprovalPathPattern = `/${paths.businessEventsAndTasks}/business-events/:eventId/${eventsAndTasksPaths.eventDetail.decisionStageConditionsApproval}/`
  const stageConditionsMinutesPathPattern = `/${paths.businessEventsAndTasks}/business-events/:eventId/${eventsAndTasksPaths.eventDetail.decisionStageMinutesApproval}/`
  const matchApprovalRoute = useMatch(stageApprovalPathPattern)
  const matchConditionsApprovalRoute = useMatch(stageConditionsApprovalPathPattern)
  const matchMinutesApprovalRoute = useMatch(stageConditionsMinutesPathPattern)

  const renderPermissionError = useCallback(
    () => (
      <IllustratedMessage
        name={IllustrationMessageType.UnableToLoad}
        size={IllustrationMessageSize.Spot}
        titleText={tNoPrefix('app.permission-error.title')}
        subtitleText={tNoPrefix('app.permission-error.subtitle')}
      />
    ),
    [tNoPrefix],
  )

  const navItems = useMemo(() => {
    const calculatedNavItems = [
      {
        path: '',
        translationToken: 'pages.business-events-and-tasks.overview',
        testIsActive: (currentPath) =>
          !!matchPath(
            { path: `/${paths.businessEventsAndTasks}/business-events/:eventId`, end: true },
            currentPath,
          ) ||
          !!matchPath(
            `/${paths.businessEventsAndTasks}/business-events/:eventId/${eventsAndTasksPaths.eventDetail.taskDetail}`,
            currentPath,
          ),
      },
    ]
    const isDecisionSupported = event?.info?.isDecisionsSupported ?? false
    if (isDecisionSupported) {
      if (hasDecisionPaperReadPermission(event?.AllowedOperations)) {
        calculatedNavItems.push({
          path: 'decision-paper',
          translationToken: 'pages.business-events-and-tasks.decision-paper',
        })
      }
      if (hasDecisionProcessReadPermission(event?.AllowedOperations)) {
        calculatedNavItems.push({
          path: 'decision-process',
          translationToken: 'pages.business-events-and-tasks.decision-process',
        })
      }
    }
    return calculatedNavItems
  }, [event])

  const renderErrorScreen = useCallback(() => {
    if (isError && isMissingPermissionError(error)) {
      return renderPermissionError()
    }
    return (
      <IllustratedMessage
        name={IllustrationMessageType.UnableToLoad}
        size={IllustrationMessageSize.Spot}
        titleText={t('loading-error.title')}
        subtitleText={t('loading-error.description')}
      />
    )
  }, [renderPermissionError, t, error, isError])

  const renderContent = useCallback(
    () => (
      <BusinessEventsAndTasksContext.Provider
        value={{ event: event, allowedOperations: event.AllowedOperations }}
      >
        {!matchApprovalRoute && !matchConditionsApprovalRoute && !matchMinutesApprovalRoute ? (
          <NavigationL2 navItems={navItems} />
        ) : (
          <Outlet />
        )}
      </BusinessEventsAndTasksContext.Provider>
    ),
    [matchApprovalRoute, event, matchConditionsApprovalRoute, matchMinutesApprovalRoute, navItems],
  )

  if (isError) {
    const breadcrumbs = [
      {
        text: t('navigate-back'),
        href: `/${paths.businessEventsAndTasks}`,
      },
      {
        text: t('title'),
      },
    ]

    const headerTitle = <Header title={t('title')} breadcrumbs={breadcrumbs} />
    return (
      <DynamicPage
        showHideHeaderButton={false}
        headerContentPinnable={false}
        headerTitle={headerTitle}
      >
        {renderErrorScreen()}
      </DynamicPage>
    )
  }

  return (
    <LoadingStateWrapper isError={isError} isLoading={isLoading} renderContent={renderContent} />
  )
}

const BusinessEventsAndTasksRoutes = [
  { path: eventsAndTasksPaths.overview, index: true, element: <BusinessEventsAndTasks /> },
  {
    path: eventsAndTasksPaths.eventDetail.main,
    element: <EventDetailWrapper />,
    children: [
      {
        path: eventsAndTasksPaths.eventDetail.detail,
        element: <EventDetail />,
      },
      { path: eventsAndTasksPaths.eventDetail.taskDetail, element: <TaskDetail /> },
      { path: eventsAndTasksPaths.eventDetail.decisionPaper, element: <DecisionPaper /> },
      { path: eventsAndTasksPaths.eventDetail.decisionProcess, element: <DecisionProcess /> },
      {
        path: eventsAndTasksPaths.eventDetail.decisionStageDetail,
        element: <DecisionStage />,
      },
      {
        path: eventsAndTasksPaths.eventDetail.decisionStageApproval,
        element: <DecisionStageApproval />,
      },
      {
        path: eventsAndTasksPaths.eventDetail.decisionStageConditionsApproval,
        element: <DecisionStageConditionsApproval />,
      },
      {
        path: eventsAndTasksPaths.eventDetail.decisionStageMinutesApproval,
        element: <DecisionStageMinutesApproval />,
      },
      {
        path: eventsAndTasksPaths.eventDetail.decisionStageDetailOld,
        element: <DecisionStageRedirect />,
      },
      {
        path: eventsAndTasksPaths.eventDetail.decisionStageApprovalOld,
        element: <DecisionStageRedirect />,
      },
      {
        path: eventsAndTasksPaths.eventDetail.decisionStageConditionsApprovalOld,
        element: <DecisionStageRedirect />,
      },
      {
        path: eventsAndTasksPaths.eventDetail.decisionStageMinutesApprovalOld,
        element: <DecisionStageRedirect />,
      },
    ],
  },
  { path: eventsAndTasksPaths.create, element: <EventCreationQueryParametersWrapper /> },
]

export default BusinessEventsAndTasksRoutes
