import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-stage/conditions/table-cells/ConditionsTableWrittenByCell.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'

const ConditionsTableWrittenByCell = ({ userId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.conditions.table.written-by',
  })
  const { data: conditionUser, isError, isLoading } = useStaffMemberById(userId)

  const renderContent = useCallback(
    () => <Label>{conditionUser.fullName ?? '-'}</Label>,
    [conditionUser?.fullName],
  )

  const errorMessage = <Label className={styles.errorLabel}>{t('error')}</Label>

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderContent}
      error={errorMessage}
    />
  )
}

ConditionsTableWrittenByCell.propTypes = {
  userId: PropTypes.string.isRequired,
}

export default ConditionsTableWrittenByCell
