import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Table,
  TableColumn,
  TableRow,
  TableCell,
  Label,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import sortBy from 'lodash.sortby'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/OpsMemoTrancheDetailsCard.module.css'
import TextShowMoreWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/TextShowMoreWrapper'
import {
  useNumberFormatter,
  useShortDateFormatter,
  useCustomizableCurrencyFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const MAX_COLLAPSED_COMMENT_LENGTH = 200
const PARTICIPANT_ROLE_KEYS = ['Participant', 'Silent Participant']

const OpsMemoTrancheAmortizationConditionsCard = ({ tranche, memberRoleTypeCodes }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.ops-memo-tranche-details.amortization-conditions',
  })
  const { t: tTable } = useTranslation('decisionPaper', {
    keyPrefix: 'components.table',
  })
  const formatPercentage = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })
  const { format: formatDate } = useShortDateFormatter()
  const formatMoney = useCustomizableCurrencyFormatter()

  const items = tranche?.amortizationConditions?.items
  const comment = tranche?.amortizationConditions?.comment

  const renderMoneyValue = (value) =>
    !isNil(value?.amount) && value.currency && formatMoney(value.amount, value.currency)

  const renderTableColumn = (title) => (
    <TableColumn>
      <div className={styles.tableCellPadding}>{title}</div>
    </TableColumn>
  )

  const renderTableCell = (value) => (
    <TableCell className={styles.tableCellPadding}>{value}</TableCell>
  )

  const renderAmortizationAmountToBeBooked = (value, share) => {
    const participanRoleDisplayName = memberRoleTypeCodes?.find((typeCode) =>
      PARTICIPANT_ROLE_KEYS.includes(typeCode.key),
    )?.displayName

    if (isNil(value?.amount)) {
      return <TableCell />
    }

    if (tranche?.financialRole === participanRoleDisplayName) {
      const calculatedAmountToBeBooked = {
        amount: value?.amount * share,
        currency: value?.currency,
      }

      return (
        <TableCell className={styles.tableCellPadding}>
          {renderMoneyValue(calculatedAmountToBeBooked)}
        </TableCell>
      )
    }

    return <TableCell className={styles.tableCellPadding}>{renderMoneyValue(value)}</TableCell>
  }

  const renderTableColumns = () => (
    <>
      {renderTableColumn(t('amortization-type'))}
      {renderTableColumn(t('valid-from'))}
      {renderTableColumn(t('amortization-frequency'))}
      {renderTableColumn(t('amortization-amount'))}
      {renderTableColumn(t('amortization-rate'))}
    </>
  )

  const renderTableRows = () =>
    items?.length > 0 &&
    sortBy(items, 'validFrom')?.map((item) => (
      <TableRow key={item?.id}>
        {renderTableCell(item?.amortizationConditionItemTypeShortText)}
        {renderTableCell(item?.validFrom && formatDate(item?.validFrom))}
        {renderTableCell(item?.frequencyShortText)}
        {renderAmortizationAmountToBeBooked(item?.amountPerInterval, tranche?.ownCommitmentShare)}
        {renderTableCell(!isNil(item?.ratePerAnnum) && formatPercentage(item?.ratePerAnnum))}
      </TableRow>
    ))

  const renderCommentSection = () => (
    <FlexBox
      fitContainer
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      className={styles.commentWrapper}
    >
      <Label>{t('comment')}</Label>
      <div className={styles.commentText}>
        <FlexBox
          direction={FlexBoxDirection.Column}
          justifyContent={FlexBoxJustifyContent.Center}
          alignItems={FlexBoxAlignItems.End}
        >
          {comment && (
            <TextShowMoreWrapper
              text={comment}
              characterLimit={MAX_COLLAPSED_COMMENT_LENGTH}
              shouldFormatHtml={true}
            />
          )}
        </FlexBox>
      </div>
    </FlexBox>
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.card}>
      <Text className={styles.cardHeaderTitle}>
        {t('amortization-conditions')} {`(${items?.length})`}
      </Text>

      <FlexBox direction={FlexBoxDirection.Column} className={styles.tableWrapper}>
        <Table
          columns={renderTableColumns()}
          noDataText={tTable('no-data')}
          className={styles.table}
        >
          {renderTableRows()}
        </Table>
        {renderCommentSection()}
      </FlexBox>
    </FlexBox>
  )
}

OpsMemoTrancheAmortizationConditionsCard.propTypes = {
  tranche: PropTypes.shape({
    financialRole: PropTypes.string,
    ownCommitmentShare: PropTypes.number,
    amortizationConditions: PropTypes.shape({
      comment: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          amortizationConditionItemTypeShortText: PropTypes.string,
          validFrom: PropTypes.string,
          frequencyShortText: PropTypes.string,
          ratePerAnnum: PropTypes.number,
        }),
      ),
    }),
  }),
  memberRoleTypeCodes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      displayName: PropTypes.string,
    }),
  ),
}

export default OpsMemoTrancheAmortizationConditionsCard
