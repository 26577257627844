import { useRequest } from 'hooks/services/baseService'

export const useRiskFigures = ({ businessPartnerId }) =>
  useRequest({
    path: `/risk-figures/${businessPartnerId}`,
    keys: [businessPartnerId],
    options: {
      enabled: !!businessPartnerId,
    },
  })
