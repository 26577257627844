import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { arrearsPermissions } from 'api/arrears/ArrearsPermissions'
import IntegratedVersionedDisplayAndEditCard from 'components/ui/card/integrated-versioned-display-and-edit-card/IntegratedVersionedDisplayAndEditCard'
import useArrearCommentUpdate from 'hooks/services/arrears/useArrearCommentUpdate'
import useArrearComments from 'hooks/services/arrears/useArrearComments'
import { BusinessPartnerArrearsContext } from 'routes/business-partners/arrears/BusinessPartnerArrearsContext'

const ArrearsCommentsCard = () => {
  const { businessPartnerId, allowedOperations } = useContext(BusinessPartnerArrearsContext)

  const hasPermission = allowedOperations?.includes(arrearsPermissions.writeArrearComments) ?? false

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.comments',
  })

  return (
    <IntegratedVersionedDisplayAndEditCard
      getContentVersionsHook={useArrearComments}
      getContentVersionsHookParameters={{ businessPartnerId }}
      putContentUpdateHook={useArrearCommentUpdate}
      putContentUpdateHookParameters={{ businessPartnerId }}
      cardTitle={t('title')}
      hasEditPermission={hasPermission}
    />
  )
}

export default ArrearsCommentsCard
