import uniq from 'lodash.uniq'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import useCovenantChecks from 'hooks/services/deals/covenants/checks/useCovenantChecks'
import useCovenantCheckById from 'hooks/services/deals/covenants/useCovenantCheckById'
import { useMultipleDealsById } from 'hooks/services/deals/useMultipleDealsById'

const useCovenantsOfCheck = ({ entityRef: { entityId, entityType } = {}, id: eventId }, tileId) => {
  const {
    isFetching: isFetchingCovenantCheckList,
    isError: isErrorCovenantCheckList,
    data: { covenantChecks = [] } = {},
    error: errorCovenantCheckList,
  } = useCovenantChecks({
    mainEntityType: mainEntityTypes.DEAL,
    eventId,
  })
  const { covenantCheckUuid } = covenantChecks?.[0] ?? {}

  const {
    isFetching: isFetchingCovenantCheck,
    isError: isErrorCovenantCheck,
    data: { covenantMonitoringItems = [], keyDate } = {},
    error: errorCovenantCheck,
  } = useCovenantCheckById(covenantCheckUuid)

  const dealUuidList = covenantMonitoringItems
    .map((monitoringItem) => {
      // only dealId instead of dealUuid is needed
      const isCovenantDealMainEntityType =
        monitoringItem?.covenant?.mainEntity?.type === mainEntityTypes.DEAL
      return isCovenantDealMainEntityType ? monitoringItem?.covenant.mainEntity.id : null
    })
    .filter((id) => !!id)

  if (entityType === cwpEntityTypes.DEAL) {
    dealUuidList.push(entityId)
  }

  const uniqueDealUuids = uniq(dealUuidList)

  const allDealResponses = useMultipleDealsById(uniqueDealUuids, {
    enabled: !!uniqueDealUuids.length,
  })

  const someDealsAreFetching = allDealResponses.some((response) => response.isFetching)
  const someDealsAreError = allDealResponses.some((response) => response.isError)

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [someDealsAreFetching, isFetchingCovenantCheck, isFetchingCovenantCheckList],
    errorValues: [someDealsAreError, isErrorCovenantCheck, isErrorCovenantCheckList],
    errorDetails: [
      errorCovenantCheck,
      errorCovenantCheckList,
      ...allDealResponses.map((response) => response.error),
    ],
    tileId,
  })

  const deals = allDealResponses.map((response) => response.data)

  if (isSomeValueError) {
    return { isLoading: false, isError: true, error }
  }
  if (isSomeValueLoading) {
    return { isLoading: true, isError: false }
  }

  let path
  if (entityType === cwpEntityTypes.DEAL) {
    const currentDealId = deals.find((localDeal) => localDeal.dealUuid === entityId)?.dealId
    path = `/deals/${currentDealId}/covenants/covenant-checks/${covenantCheckUuid}`
  }
  if (entityType === cwpEntityTypes.BUSINESS_PARTNER) {
    path = `/business-partners/${entityId}/covenants/covenant-checks/${covenantCheckUuid}`
  }

  return {
    isLoading: false,
    isError: false,
    data: {
      sourceRender: {
        path,
      },
      entityType,
      covenantMonitoringItems,
      keyDate,
      deals,
    },
  }
}

export default useCovenantsOfCheck
