import groupBy from 'lodash.groupby'
import isNil from 'lodash.isnil'
import sortBy from 'lodash.sortby'
import { DateTime } from 'luxon'

export const KpiThresholdTypes = {
  GREATER_THAN: '>',
  GREATER_OR_EQUAL_THAN: '≥',
  LESS_THAN: '<',
  LESS_OR_EQUAL_THAN: '≤',
}

const AdjustmentType = {
  PROSPECTIVE: 'PROSPECTIVE',
  RETROSPECTIVE: 'RETROSPECTIVE',
}

export const getKpiForestFromKpiList = (kpiList = []) => {
  const kpiMap = {}
  kpiList.forEach((kpi) => (kpiMap[kpi.id] = { ...kpi, children: [] }))

  kpiList.forEach((kpi) => {
    kpi.parents.forEach((parentId) => kpiMap[parentId].children.push(kpiMap[kpi.id]))
  })

  return Object.values(kpiMap).filter((kpi) => kpi.parents.length === 0)
}

const extractKeyDatesFromValues = (values) =>
  values.reduce((acc, value) => {
    !acc.find((keyDate) => keyDate === value.keyDate) && acc.push(value.keyDate)

    return acc
  }, [])

const extractAndAppendKeyDatesFromAdjustments = (adjustments, keyDates) =>
  adjustments.reduce((acc, { validFrom, validTo }) => {
    !acc.find((keyDate) => keyDate === validFrom) && acc.push(validFrom)
    !acc.find((keyDate) => keyDate === validTo) && acc.push(validTo)

    return acc
  }, keyDates)

export const getTimeSeriesForKpi = (kpiTree) => {
  const today = DateTime.now().startOf('day')

  const allKpiValues =
    kpiTree?.values?.toSorted((a, b) => new Date(a.keyDate) - new Date(b.keyDate)) ?? []
  const adjustments =
    kpiTree?.adjustments?.toSorted((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)) ?? []
  const adjustmentIds = adjustments.map(({ id }) => id)

  const allCurrentKeyDateValues = []

  const keyDateGroups = groupBy(allKpiValues, 'keyDate')
  Object.entries(keyDateGroups).forEach(([_, keyDateValues]) => {
    if (keyDateValues.length === 1) {
      allCurrentKeyDateValues.push(keyDateValues[0])
    }
    if (keyDateValues.length > 1) {
      const currentKeyDateValue = sortBy(keyDateValues, 'validFrom').reverse()[0]
      allCurrentKeyDateValues.push(currentKeyDateValue)
    }
  })

  const keyDatesFromValues = extractKeyDatesFromValues(allCurrentKeyDateValues)
  const allSortedKeyDates = extractAndAppendKeyDatesFromAdjustments(
    adjustments,
    keyDatesFromValues,
  ).sort()

  return allSortedKeyDates.map((date) => {
    const lastCurrentKeyDate = allCurrentKeyDateValues?.at(-1)?.keyDate
    const kpiValueForKeyDate = allCurrentKeyDateValues.findLast(
      (keyDateValue) => keyDateValue.keyDate <= date && date <= lastCurrentKeyDate,
    )

    // find an adjustment for this value -> use updatedBy if found
    const adjustmentForKeyDate = adjustments.findLast(
      (adjustment) => adjustment.validFrom <= date && adjustment.validTo >= date,
    )

    // use value if it is a direct adjustment
    const isDirectedAdjustment = adjustmentIds.includes(adjustmentForKeyDate?.id)

    // An adjustment is valued as ongoing if it's within the current date relative to today OR
    // if the last current key date of the KPI values is earlier than the adjustments latest valid date minus one day.
    // Edge case: There are no calculated kpi key dates and the adjustments are the only data in the time series -> also mark adjustment as ongoing
    const hasOngoingAdjustment =
      !!adjustmentForKeyDate &&
      today >= DateTime.fromISO(adjustmentForKeyDate.validFrom) &&
      (today <= DateTime.fromISO(adjustmentForKeyDate.validTo) ||
        isNil(lastCurrentKeyDate) ||
        DateTime.fromISO(lastCurrentKeyDate) <= DateTime.fromISO(adjustmentForKeyDate.validTo))

    const currentValue = isDirectedAdjustment
      ? adjustmentForKeyDate?.value
      : kpiValueForKeyDate?.value

    // simple time series structure for drawing chart
    return {
      value: currentValue,
      keyDate: date,
      createdBy: kpiValueForKeyDate?.createdBy,
      updatedBy: adjustmentForKeyDate?.updatedBy,
      adjustmentType: adjustmentForKeyDate?.type,
      calculatedValue: kpiValueForKeyDate?.value,
      directAdjustedValue: isDirectedAdjustment ? adjustmentForKeyDate.value : null,
      overallAdjustedValue: adjustmentForKeyDate?.updatedBy ? currentValue : null,
      comment: isDirectedAdjustment ? adjustmentForKeyDate.comment ?? null : null,
      hasOngoingAdjustment,
    }
  })
}

export const getCurrentValueForTimeSeries = (timeSeries) => {
  // Luxon is used instead of js dates, because a default comparison between dates JS Dates,
  // especially if using the "now" date, is always taking into account the date AND the time.
  // Therefore, a comparison using "<" to get, e.g., all dates from yesterday and before,
  // results in true also for time differences (because the KPIs ISO data has no time and is interpreted as 00:00:00), which we don't want.
  const today = DateTime.now().startOf('day')
  const yesterday = DateTime.now().minus({ days: 1 }).startOf('day')

  // Adjustments made while there is an active kpi value present result in a time series element WITH both value and calculated value.
  // However, adjustments made AFTER the last active kpi value result in a time series element WITHOUT a calculated value, and only a value.
  // Also note, that the value equals the calculated value for normal KPI entries, so it is always safe to use the value instead of the calculated value.

  // Basic cases that should be covered by this block:
  // 1. There are only past KPI key dates, so all KPI data lies before today -> take the most current value, adjusted or calculated
  // 2. There are only past KPI key dates with adjustments in between and set into the future -> take the most current value (today or before) if calculated, or adjusted if present from before today, NOT today
  // 3. There is KPI data going into the future with adjustments in between -> take the most current value (today or before) if calculated, or adjusted if present from before today, NOT today
  // In any case: Adjustments are prioritized over calculated values if they overlap, retrospective adjustments are prioritized over prospective adjustments (which is implicitly given through prior sorting).
  const lastKeyDateWithCalculatedValue = timeSeries
    .filter(
      ({ keyDate, calculatedValue }) =>
        DateTime.fromISO(keyDate) <= yesterday ||
        (DateTime.fromISO(keyDate) <= today && !isNil(calculatedValue)),
    )
    .toSorted((a, b) => {
      const dateA = new Date(a.keyDate)
      const dateB = new Date(b.keyDate)
      if (a.keyDate !== b.keyDate) {
        return dateB - dateA
      }
      // retrospective adjustments are sorted before prospective ones if the date is indecisive
      const adjustmentTypeOrder = [AdjustmentType.RETROSPECTIVE, AdjustmentType.PROSPECTIVE]
      const aAdjustmentTypeIndex = adjustmentTypeOrder.indexOf(a?.adjustmentType)
      const bAdjustmentTypeIndex = adjustmentTypeOrder.indexOf(b?.adjustmentType)
      return aAdjustmentTypeIndex - bAdjustmentTypeIndex
    })
    .find(() => true)

  return {
    ...lastKeyDateWithCalculatedValue,
    value: lastKeyDateWithCalculatedValue?.hasOngoingAdjustment
      ? lastKeyDateWithCalculatedValue?.value
      : lastKeyDateWithCalculatedValue?.calculatedValue,
  }
}

export const getLastUltimoForTimeSeries = (timeSeries) => {
  const today = new Date()
  // last ultimo = last day of last month
  const lastUltimoDate = new Date(today.getFullYear(), today.getMonth(), 0).toDateString()
  return timeSeries.find(({ keyDate }) => new Date(keyDate).toDateString() === lastUltimoDate)
}
