import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import AutomaticTileLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/AutomaticTileLoadingWrapper'
import { getViewComponentForTileCode } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import DecisionPaperAutomaticTileWithMetaDataTypeWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/with-meta-data/DecisionPaperAutomaticTileWithMetaDataTypeWrapper'
import TileUnknown from 'components/domains/business-events-and-tasks/decision-paper/tiles/generic/TileUnknown'
import DecisionPaperManualTileLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/DecisionPaperManualTileLoadingWrapper'
import { getViewComponentForManualTile } from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/availableTiles'

const supportedTileTypes = Object.freeze({
  automatic: 'AUTOMATIC',
  automaticWithMetaData: 'AUTOMATIC_WITH_META_DATA',
  manual: 'MANUAL',
})

// Generic Wrapper for all Tiles to differentiate based on the Tile / type.2
const TileTypeWrapper = ({
  tile: { code: tileCode, id: tileId, type: tileType, ...tileProps },
  showConfirmationForLinks,
  currentVersion,
  synchronizationStatus,
  isEditableInMinorVersion,
  lastAvailableVersion,
  subsectionTilesIds,
}) => {
  const tileState = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const tileHiddenState = useSelector(
    (state) => state.decisionPaper.tilesOverview.hiddenTiles[tileId],
  )

  const isHiddenViaTileHiddenState = useMemo(
    () => !!tileHiddenState?.isHidden,
    [tileHiddenState?.isHidden],
  )
  const isHiddenViaHookResult = useMemo(
    () => !tileState?.isLoading && !tileState?.isError && !!tileState?.data?.isHidden,
    [tileState?.data?.isHidden, tileState?.isError, tileState?.isLoading],
  )

  if (isHiddenViaHookResult || isHiddenViaTileHiddenState) {
    return <div />
  }

  if (tileType === supportedTileTypes.manual) {
    const Tile = getViewComponentForManualTile(tileProps.subType, tileCode)
    if (Tile) {
      return (
        <DecisionPaperManualTileLoadingWrapper
          Tile={Tile}
          tileId={tileId}
          tileCode={tileCode}
          currentVersion={currentVersion}
          showConfirmationForLinks={showConfirmationForLinks}
          isEditableInMinorVersion={isEditableInMinorVersion}
          lastAvailableVersion={lastAvailableVersion}
          {...tileProps}
        />
      )
    }
  }

  if (tileType === supportedTileTypes.automatic) {
    const Tile = getViewComponentForTileCode(tileCode)
    if (Tile) {
      return (
        <AutomaticTileLoadingWrapper
          Tile={Tile}
          tileId={tileId}
          tileCode={tileCode}
          currentVersion={currentVersion}
          showConfirmationForLinks={showConfirmationForLinks}
          {...tileProps}
        />
      )
    }
  }

  if (tileType === supportedTileTypes.automaticWithMetaData) {
    return (
      <DecisionPaperAutomaticTileWithMetaDataTypeWrapper
        tileId={tileId}
        tileCode={tileCode}
        currentVersion={currentVersion}
        showConfirmationForLinks={showConfirmationForLinks}
        synchronizationStatus={synchronizationStatus}
        isEditableInMinorVersion={isEditableInMinorVersion}
        lastAvailableVersion={lastAvailableVersion}
        subsectionTilesIds={subsectionTilesIds}
        {...tileProps}
      />
    )
  }

  return <TileUnknown tileCode={tileCode} />
}

TileTypeWrapper.propTypes = {
  tile: PropTypes.shape({
    id: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    version: PropTypes.string,
  }).isRequired,
  currentVersion: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
  synchronizationStatus: PropTypes.string.isRequired,
  isEditableInMinorVersion: PropTypes.bool.isRequired,
  lastAvailableVersion: PropTypes.string.isRequired,
  subsectionTilesIds: PropTypes.shape({
    subsectionCode: PropTypes.string,
  }),
}

export default TileTypeWrapper
