import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import IntegratedVersionedDisplayAndEditCard from 'components/ui/card/integrated-versioned-display-and-edit-card/IntegratedVersionedDisplayAndEditCard'
import usePropertyComment from 'hooks/services/properties/comment/usePropertyComment'
import usePropertyCommentUpdate from 'hooks/services/properties/comment/usePropertyCommentUpdate'

const PropertyCommentCard = ({ propertyUuid, isEditEnabled }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.property-comment-card' })
  return (
    <IntegratedVersionedDisplayAndEditCard
      getContentVersionsHook={usePropertyComment}
      getContentVersionsHookParameters={{ propertyUuid }}
      putContentUpdateHook={usePropertyCommentUpdate}
      putContentUpdateHookParameters={{ propertyUuid }}
      cardTitle={t('title')}
      hasEditPermission={isEditEnabled}
    />
  )
}

PropertyCommentCard.propTypes = {
  propertyUuid: PropTypes.string.isRequired,
  isEditEnabled: PropTypes.bool.isRequired,
}

export default PropertyCommentCard
