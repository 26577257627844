import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useDeletePropertyInsurance = ({ onSuccess, onError }) => {
  const { delete: deletePropertyInsurance } = useAccessTokenRequest()

  return useMutation(
    async ({ propertyUuid, insuranceUuid }) => {
      await deletePropertyInsurance({
        path: `/properties/${propertyUuid}/insurance/${insuranceUuid}`,
      })
    },
    { onSuccess, onError },
  )
}
