import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import useDecisionStageName from 'components/domains/business-events-and-tasks/common/useDecisionStageName'

const DecisionStageName = ({ name, decisionStageType }) => {
  const displayName = useDecisionStageName({ name, decisionStageType })

  return <Text>{displayName}</Text>
}

DecisionStageName.propTypes = {
  name: PropTypes.string.isRequired,
  decisionStageType: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default DecisionStageName
