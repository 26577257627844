import { FlexBox, FlexBoxDirection, Table, TableColumn } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import OpsMemoDealTeamInternalMembersTableRow from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/deal/OpsMemoDealTeamInternalMembersTableRow'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/deal/OpsMemoDealTeamInternalMembersTile.module.css'

const OpsMemoDealTeamInternalMembersTile = ({ tileId }) => {
  const tileData = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const { dealTeamData = [] } = tileData?.data || {}

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.deal-team.information',
  })

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.internalMembersCard}>
      <Table
        id="internalMembersTable"
        noDataText={t('property.no-data')}
        className={styles.internalMembersTable}
        columns={
          <>
            <TableColumn>{t('property.name')}</TableColumn>
            <TableColumn>{t('property.team')}</TableColumn>
            <TableColumn>{t('property.function')}</TableColumn>
            <TableColumn>{t('property.leading')}</TableColumn>
          </>
        }
      >
        {dealTeamData.map(
          ({
            userId,
            relation: { sapRelationName } = {},
            leader,
            user: { fullName, team } = {},
          }) => (
            <OpsMemoDealTeamInternalMembersTableRow
              key={userId}
              userId={userId}
              sapRelationName={sapRelationName}
              leader={leader}
              fullName={fullName}
              team={team}
            />
          ),
        )}
      </Table>
    </FlexBox>
  )
}

OpsMemoDealTeamInternalMembersTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default OpsMemoDealTeamInternalMembersTile
