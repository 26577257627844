import { Text, RadioButton } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/events/creation/DecisionPaperTable.module.css'
import Entity from 'components/ui/data/Entity'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useRelevantDecisionPapersByEntityId from 'hooks/services/business-events-and-tasks/decision-papers/useRelevantDecisionPapersByEntityId'
import { setEventIdToImportDataFrom } from 'redux/slices/events/eventCreationSlice'
import paths from 'routes/paths'

// move file
const DecisionPaperTable = ({
  entityId,
  onChange,
  selectedDecisionPaper,
  setSelectedDecisionPaper,
  isEventCreation,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.relevant-decision-paper-table',
  })
  const { format: formatDateAndTime } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
  })
  const dispatch = useDispatch()
  const {
    isLoading,
    isError,
    data: relevantDecisionPaperData,
  } = useRelevantDecisionPapersByEntityId(entityId)

  useEffect(() => {
    if (!selectedDecisionPaper && relevantDecisionPaperData?.relevantFrozenVersions.length !== 0) {
      setSelectedDecisionPaper(relevantDecisionPaperData?.relevantFrozenVersions[0].eventId)
      if (isEventCreation) {
        dispatch(
          setEventIdToImportDataFrom(relevantDecisionPaperData?.relevantFrozenVersions[0].eventId),
        )
      }
    }
  }, [
    setSelectedDecisionPaper,
    relevantDecisionPaperData?.relevantFrozenVersions,
    selectedDecisionPaper,
    dispatch,
    isEventCreation,
  ])

  const decisionPaperColumns = [
    {
      columnKey: 'checkbox',
    },
    {
      title: t('name'),
      columnKey: 'name',
    },
    {
      title: t('frozen-date'),
      columnKey: 'frozenDate',
    },
    {
      title: t('event'),
      columnKey: 'event',
    },
  ]

  const mappedTableData = useMemo(
    () =>
      relevantDecisionPaperData?.relevantFrozenVersions.map(
        ({ decisionPaperName, frozenDate, eventId, eventName, eventDisplayId }) => ({
          rowKey: eventId,
          checkbox: {
            cellComponent: (
              <RadioButton
                id={eventId}
                onChange={onChange(eventId)}
                checked={selectedDecisionPaper === eventId}
              />
            ),
          },
          name: {
            cellComponent: <Text>{decisionPaperName}</Text>,
          },
          frozenDate: {
            cellComponent: <Text>{`${formatDateAndTime(frozenDate)}`}</Text>,
          },
          event: {
            cellComponent: (
              <Entity
                name={eventName}
                id={eventDisplayId}
                link={`/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}`}
                openInNewTab={true}
              />
            ),
          },
        }),
      ),
    [
      formatDateAndTime,
      relevantDecisionPaperData?.relevantFrozenVersions,
      onChange,
      selectedDecisionPaper,
    ],
  )

  const renderContent = () => (
    <>
      <Text className={styles.textPadding}>{t('select-for-import')}</Text>
      <SortedTable
        tableData={mappedTableData}
        columnDefinitions={decisionPaperColumns}
        noDataText={t('no-data')}
      />
    </>
  )

  return (
    <LoadingStateWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderContent}
      errorDescription={t('loading.error.description')}
      errorTitle={t('loading.error.title')}
      errorDetails={''}
    />
  )
}

DecisionPaperTable.propTypes = {
  entityId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedDecisionPaper: PropTypes.string,
  setSelectedDecisionPaper: PropTypes.func.isRequired,
  isEventCreation: PropTypes.bool,
}

export default DecisionPaperTable
