import { z } from 'zod'

/** @typedef {import('zod').TypeOf<typeof documentTypeSchema>} DocumentTypes */

const documentTypeSchema = z
  .object({
    documentTypes: z.array(z.string()),
  })
  .optional()

export default documentTypeSchema
