import { ValueState } from '@fioneer/ui5-webcomponents-react'
import { useConfig } from 'hooks/config/useConfig'

const useReservationToleranceConfig = () => {
  const { data: config } = useConfig()
  return config?.deals?.reservationTolerance ?? 0
}

const useDealReservationConfigMaps = () => {
  const reservationTolerance = useReservationToleranceConfig()

  const statusConfigMap = {
    NOT_RESERVED: {
      code: 'NOT_RESERVED',
      displayName: 'not-reserved',
      style: ValueState.Error,
      predicate: (arr, matcher) => !!matcher.length || arr.every((element) => !matcher[element]),
    },
    PARTIALLY_RESERVED: {
      code: 'PARTIALLY_RESERVED',
      displayName: 'partially-reserved',
      style: ValueState.Warning,
      predicate: (arr, firstMatcher, secondMatcher) =>
        arr.some(
          (element) =>
            firstMatcher[element] &&
            firstMatcher[element] < secondMatcher[element] - reservationTolerance,
        ),
    },
    RESERVED: {
      code: 'RESERVED',
      displayName: 'reserved',
      style: ValueState.Success,
      predicate: (arr, firstMatcher, secondMatcher) =>
        arr.every(
          (element) =>
            firstMatcher[element] >= secondMatcher[element] - reservationTolerance &&
            firstMatcher[element] <= secondMatcher[element] + reservationTolerance,
        ),
    },
    OVERRESERVED: {
      code: 'OVERRESERVED',
      displayName: 'overreserved',
      style: ValueState.None,
      predicate: (arr, firstMatcher, secondMatcher) =>
        arr.some(
          (element) => firstMatcher[element] > secondMatcher[element] + reservationTolerance,
        ),
    },
    NOT_SUPPORTED: {
      code: 'NOT_SUPPORTED',
      displayName: 'not-supported',
      style: ValueState.None,
    },
  }

  const warningConfigMap = {
    PROPERTY_CHANGED: {
      predicate: (arr, code) => arr.some((element) => element !== code),
      message: 'property-changed',
    },
    COUNTRY_CHANGED: {
      predicate: (arr, codes) => arr.some((element) => !codes.includes(element)),
      message: 'country-changed',
    },
    PRODUCT_CHANGED: {
      predicate: (arr, codes) => arr.some((element) => !codes.includes(element)),
      message: 'product-changed',
    },
    MULTI_COUNTRY_OR_PROPERTY_TYPE: {
      predicate: (noOfCountries, noOfPropertyTypes) => noOfCountries > 1 || noOfPropertyTypes > 1,
      message: 'multi-country-or-property-type',
    },
    UNDER_RESERVED: {
      predicate: (status) => status === statusConfigMap.PARTIALLY_RESERVED.code,
      message: 'under-reserved',
    },
    STATUS_CALCULATION_NOT_SUPPORTED: {
      predicate: () => true,
      message: 'status-calculation-not-supported',
    },
    NO_RESERVATIONS: {
      predicate: (noOfReservations) => noOfReservations === 0,
      message: 'no-reservations',
    },
    OUTDATED_OR_OUT_OF_SYNC_RESERVATIONS: {
      predicate: (noOfReservations, noOfValidReservations) =>
        noOfReservations > 0 && noOfValidReservations === 0,
      message: 'outdated-or-out-of-sync',
    },
  }

  const isStatusResolved = (status, firstParam, secondParam, thirdParam) =>
    status.predicate(firstParam, secondParam, thirdParam)

  const resolveWarningMessage = (warningObject, firstParam, secondParam) => {
    const { predicate, message } = warningObject

    return predicate(firstParam, secondParam) && message
  }

  return {
    statusConfigMap,
    warningConfigMap,
    isStatusResolved,
    resolveWarningMessage,
  }
}

export default useDealReservationConfigMaps
