import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import uniq from 'lodash.uniq'
import { lazy, Suspense, useContext, useMemo } from 'react'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import DealPropertiesHeader from 'components/domains/deals/portfolio/DealPropertiesHeader'
import Card from 'components/ui/card/Card'
import LazyLoadingFallbackCard from 'components/ui/card/LazyLoadingFallbackCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import useDealProperties from 'hooks/services/deals/properties/useDealProperties'
import usePropertyUuids from 'hooks/services/properties/usePropertyUuids'
import lazyRetry from 'lazyRetry'
import { DealContext } from 'routes/deals/DealContext'
import styles from 'routes/deals/properties/DealProperties.module.css'
import { PropertyPortfolioContext } from 'routes/properties/portfolio/PropertyPortfolioContext'

const DealPropertiesMap = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealPropertiesMap" */ 'components/domains/deals/portfolio/DealPropertiesMap'
      ),
    'DealPropertiesMap',
  ),
)
const DealPropertiesTable = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealPropertiesTable" */ 'components/domains/deals/portfolio/DealPropertiesTable'
      ),
    'DealPropertiesMap',
  ),
)
const PortfolioOverviewCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "PortfolioOverviewCard" */ 'components/domains/deals/portfolio/PortfolioOverviewCard'
      ),
    'PortfolioOverviewCard',
  ),
)
const RentRollValues = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "RentRollValues" */ 'components/domains/deals/portfolio/RentRollValues'
      ),
    'RentRollValues',
  ),
)
const PropertyPortfolioOverviewPerCityChartsCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "PropertyPortfolioOverviewPerCityChartsCard" */
        'components/domains/properties/portfolio/overview/pie-charts/PropertyPortfolioOverviewPerCityChartsCard'
      ),
    'PropertyPortfolioOverviewPerCityChartsCard',
  ),
)
const PropertyPortfolioOverviewPerCountryChartsCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "PropertyPortfolioOverviewPerCountryChartsCard" */
        'components/domains/properties/portfolio/overview/pie-charts/PropertyPortfolioOverviewPerCountryChartsCard'
      ),
    'PropertyPortfolioOverviewPerCountryChartsCard',
  ),
)
const PropertyPortfolioOverviewPerPropertyTypeChartsCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "PropertyPortfolioOverviewPerPropertyTypeChartsCard" */
        'components/domains/properties/portfolio/overview/pie-charts/PropertyPortfolioOverviewPerPropertyTypeChartsCard'
      ),
    'PropertyPortfolioOverviewPerPropertyTypeChartsCard',
  ),
)

const DealProperties = () => {
  const {
    deal: { dealUuid },
    allowedOperations,
  } = useContext(DealContext)

  const { data: { dealProperties = [] } = [], isLoading: isLoadingDealProperties } =
    useDealProperties({
      dealUuid: dealUuid,
    })

  const isAllowedEditPortfolioOverview = allowedOperations.includes(dealsPermissions.updateDeal)

  const propertyUuids = dealProperties.map((item) => item.propertyUuid)
  const {
    data: { data: { properties = [] } = {} } = {},
    isFetching: isFetchingProperties,
    isLoading: isLoadingProperties,
    isError: isErrorProperties,
  } = usePropertyUuids(propertyUuids, { enabled: !!propertyUuids.length })

  const hasPropertiesInDifferentCountries = useMemo(
    () => uniq(properties.map((property) => property.address?.country_code)).length > 1,
    [properties],
  )

  return (
    <CWPLayout>
      <FlexBox direction={FlexBoxDirection.Column}>
        <Suspense
          fallback={<LazyLoadingFallbackCard data-testid="PortfolioOverviewCard-fallback" />}
        >
          <PortfolioOverviewCard
            dealUuid={dealUuid}
            isAllowedToEdit={isAllowedEditPortfolioOverview}
          />
        </Suspense>
        <Suspense fallback={<LazyLoadingFallbackCard data-testid="RentRollValues-fallback" />}>
          <RentRollValues
            properties={properties}
            isLoadingProperties={
              (isLoadingProperties && isFetchingProperties) || isLoadingDealProperties
            }
          />
        </Suspense>
      </FlexBox>
      <Suspense fallback={<LazyLoadingFallbackCard data-testid="DealPropertiesMap-fallback" />}>
        <DealPropertiesMap
          properties={properties}
          isLoadingProperties={isLoadingProperties}
          isErrorProperties={isErrorProperties}
        />
      </Suspense>
      <CWPLayout.Full>
        <FlexBox className={styles.containerGap}>
          <PropertyPortfolioContext.Provider value={{ properties }}>
            {hasPropertiesInDifferentCountries ? (
              <Suspense
                fallback={
                  <LazyLoadingFallbackCard data-testid="PropertyPortfolioOverviewPerCountryChartsCard-fallback" />
                }
              >
                <PropertyPortfolioOverviewPerCountryChartsCard />
              </Suspense>
            ) : (
              <Suspense
                fallback={
                  <LazyLoadingFallbackCard data-testid="PropertyPortfolioOverviewPerCityChartsCard-fallback" />
                }
              >
                <PropertyPortfolioOverviewPerCityChartsCard />
              </Suspense>
            )}
            <Suspense
              fallback={
                <LazyLoadingFallbackCard data-testid="PropertyPortfolioOverviewPerPropertyTypeChartsCard-fallback" />
              }
            >
              <PropertyPortfolioOverviewPerPropertyTypeChartsCard />
            </Suspense>
          </PropertyPortfolioContext.Provider>
        </FlexBox>
      </CWPLayout.Full>
      <CWPLayout.Full>
        <Card header={<DealPropertiesHeader propertyUuids={propertyUuids} />}>
          <Suspense
            fallback={<LazyLoadingFallbackCard data-testid="DealPropertiesTable-fallback" />}
          >
            <DealPropertiesTable
              properties={properties}
              dealProperties={dealProperties}
              isLoadingProperties={
                (isLoadingProperties && isFetchingProperties) || isLoadingDealProperties
              }
            />
          </Suspense>
        </Card>
      </CWPLayout.Full>
    </CWPLayout>
  )
}

export default DealProperties
