import { ResponsiveGridLayout, Title, TitleLevel } from '@fioneer/ui5-webcomponents-react'
import 'components/domains/deals/creation-dialog/review-step/DealCreationReviewStep.css'
import { func } from 'prop-types'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Step } from 'components/domains/deals/creation-dialog/DealCreationDialog'
import { DealCreationReviewCounterparty } from 'components/domains/deals/creation-dialog/review-step/DealCreationReviewCounterparty'
import { DealCreationReviewGeneralInformation } from 'components/domains/deals/creation-dialog/review-step/DealCreationReviewGeneralInformation'
import { DealCreationReviewProperty } from 'components/domains/deals/creation-dialog/review-step/DealCreationReviewProperty'
import { useFormMapped } from 'hooks/form/useFormMapped'

const DealCreationReviewStep = ({ setCurrentStep }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.dialog' })
  const { control } = useFormMapped()
  const {
    businessSegmentCode,
    originationTeamCode,
    name,
    dealTypeCode,
    comment,
    dealTeam: { staffMembers = [] } = {},
    counterParty: { sponsor = [], borrower = {} } = {},
    tranche: { totalCommitment = {}, loanType } = {},
    financedObjectType,
    portfolio,
  } = useWatch({ control }) ?? {}

  return (
    <ResponsiveGridLayout
      columnsS={3}
      columnsM={3}
      columnsL={3}
      columnsXL={3}
      className="deal-creation-review-step"
    >
      <Title
        id="deal-creation-review-general-information"
        className="review-title"
        level={TitleLevel.H4}
      >
        {t('general-information.title', { wizardStep: Step.GENERAL_INFORMATION })}
      </Title>
      <DealCreationReviewGeneralInformation
        businessSegmentCode={businessSegmentCode}
        originationTeamCode={originationTeamCode}
        dealName={name}
        accountManager={staffMembers[0]}
        dealTypeCode={dealTypeCode}
        comment={comment}
        onEdit={() => setCurrentStep(Step.GENERAL_INFORMATION)}
      />

      <Title
        id="deal-creation-review-counterparty-and-financing"
        className="review-title"
        level={TitleLevel.H4}
      >
        {t('counterparty-and-financing.title', { wizardStep: Step.COUNTERPARTY_AND_FINANCING })}
      </Title>
      <DealCreationReviewCounterparty
        sponsor={sponsor}
        borrower={borrower}
        totalCommitment={totalCommitment}
        loanType={loanType}
        onEdit={() => setCurrentStep(Step.COUNTERPARTY_AND_FINANCING)}
      />

      <Title id="deal-creation-review-property" className="review-title" level={TitleLevel.H4}>
        {t('property.title', { wizardStep: Step.PROPERTY })}
      </Title>
      <DealCreationReviewProperty
        financedObjectType={financedObjectType}
        portfolio={portfolio}
        onEdit={() => setCurrentStep(Step.PROPERTY)}
      />
    </ResponsiveGridLayout>
  )
}

DealCreationReviewStep.propTypes = {
  setCurrentStep: func.isRequired,
}

export default DealCreationReviewStep
