import { useRequest } from 'hooks/services/baseService'

export const useMemberRoleTypeCodes = () => {
  const typeCode = 'SYNDICATION_MEMBER_ROLE_TYPE'
  const result = useRequest({
    // TODO: should probably be adapted to `syndication-structures
    path: `/financing-structures/configurations/category/${typeCode}`,
    translated: true,
  })

  const transformedData = {
    typeCodes: result?.data?.configuration?.[typeCode].map((element) => ({
      key: element.code,
      displayName: element.short_text,
      hasSyndicateShare: element.additional_properties.hasSyndicateShare === 'true',
    })),
  }

  return {
    ...result,
    data: transformedData,
  }
}
