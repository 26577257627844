import { useQuery } from '@tanstack/react-query'
import camelize from 'camelize'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const OK = 200

export const getFirstKeyDatePropertiesKpis = (multiKeyDatePropertiesKpis) =>
  (
    multiKeyDatePropertiesKpis?.map((propertiesKpis) => {
      if (!propertiesKpis) return null
      if (!propertiesKpis.keyDateToRentRollKpis?.length) return null
      const firstKeyDateRentRollKpis = propertiesKpis.keyDateToRentRollKpis[0]
      return {
        propertyUuid: propertiesKpis.propertyUuid,
        keyDate: firstKeyDateRentRollKpis.keyDate,
        ...firstKeyDateRentRollKpis.kpis,
      }
    }) ?? []
  ).filter(Boolean)

const usePropertiesKpis = (
  propertyUuids,
  { keyDates = null, currency, areaUnitOfMeasurement, withTenantGranularity = false } = {},
  queryOptions = { retry: 0 },
) => {
  const mappedPropertyUuids = propertyUuids.map((uuid) => ({
    property_uuid: uuid,
    key_dates: keyDates,
  }))
  const { post } = useAccessTokenRequest()
  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: [
      'properties-kpis',
      mappedPropertyUuids,
      currency,
      areaUnitOfMeasurement,
      keyDates,
      withTenantGranularity,
    ],
    refetchOnWindowFocus: false,
    queryFn: () =>
      post({
        path: `/properties/kpis/property`,
        body: {
          property_uuid_to_key_dates: mappedPropertyUuids,
          currency: currency,
          area_unit_of_measurement: areaUnitOfMeasurement,
          with_tenant_granularity: withTenantGranularity,
        },
      }),
    ...queryOptions,
  })
  if (isLoading || isFetching || isError) return { isLoading, isFetching, isError, data: [] }
  return {
    data: camelize(data?.data?.property_rent_roll_kpis) ?? [],
    isError: data?.status !== OK,
    isLoading: false,
    isFetching: false,
  }
}

export default usePropertiesKpis
