import { Button, ButtonDesign, Modals } from '@fioneer/ui5-webcomponents-react'
import find from 'lodash.find'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import VersionedDisplayAndEditCard from 'components/ui/card/VersionedDisplayAndEditCard'
import VersionsHistoryButtonLoadingWrapper from 'components/ui/card/VersionsHistoryButtonLoadingWrapper'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import LabeledSwitch from 'components/ui/input/LabeledSwitch'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useStaffMemberByObjectIdOrEmail'
import useDealCollateralInformation from 'hooks/services/deals/collateral/useDealCollateralInformation'
import useUpdateDealCollateralInformation from 'hooks/services/deals/collateral/useUpdateDealCollateralInformation'
import useTextEditorCurrentContent from 'hooks/text-editor/useTextEditorCurrentContent'

const editMaxCharacterAmount = 1000
const viewMaxCharacterAmount = 300

const DealCollateralInformationCard = ({ dealUuid, allowedOperations }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.collateral-agreements-general-information',
  })
  const { t: tNoPrefix } = useTranslation()

  const showToast = Modals.useShowToast()
  const showErrorMessageBox = useShowErrorMessageBox()

  const isEditAllowed = !!allowedOperations?.includes(
    dealsPermissions.updateStandardCollateralPackage,
  )

  /***************
   * Backend Data
   */
  const {
    data: { lastUpdatedBy, lastUpdatedAt, isStandardPackage, notes = [] } = {},
    isLoading: isLoadingDealCollateral,
    isError: isErrorDealCollateral,
  } = useDealCollateralInformation({ dealUuid })

  const {
    mutate: updateDealCollateralInformation,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useUpdateDealCollateralInformation({
    dealUuid,
    onSuccess: () => {
      showToast({ children: tNoPrefix('toast.changes-saved') })
    },
    onError: async (error) => {
      const { errors: [errorResponse] = [] } = await error.response.json()
      showErrorMessageBox({ message: t('is-standard-package-update-error'), error: errorResponse })
    },
  })

  /***************
   * Display Data
   */
  const [versionToShow, setVersionToShow] = useState(0)

  const latestVersion = useMemo(() => notes.at(-1)?.version, [notes])

  const enforceLatestVersionToBeShownRef = useRef(false)
  useEffect(() => {
    // latestVersion => most recent/most up-to-date version
    // versionToShow => what the users sees on screen
    // only update on initial loading or after a user updated the note
    if (versionToShow && !enforceLatestVersionToBeShownRef.current) return
    enforceLatestVersionToBeShownRef.current = false
    setVersionToShow(latestVersion)
  }, [latestVersion, versionToShow])

  const getContentForVersion = useCallback(
    (version) => find(notes, { version })?.note ?? '',
    [notes],
  )

  const {
    currentContent: noteToShow,
    setCurrentContent,
    hasChanges,
  } = useTextEditorCurrentContent(getContentForVersion(versionToShow))

  const { data: staffMember, isLoading: isLoadingStaffMember } = useStaffMemberByObjectIdOrEmail({
    objectIdOrEmail: lastUpdatedBy,
  })

  const lastUpdatedByName = useMemo(
    () => staffMember?.fullName ?? lastUpdatedBy,
    [staffMember, lastUpdatedBy],
  )

  const isLoading =
    isLoadingDealCollateral ||
    /* there is a last updating user and it is loading */ (!!lastUpdatedBy && isLoadingStaffMember)

  /***************
   * Action handlers
   */
  const handleSaveToggleChanges = useCallback(
    (isStandardPackageNewValue) => {
      updateDealCollateralInformation({
        isStandardPackage: !!isStandardPackageNewValue,
        // use the text of the latest version to avoid creating a new version unintentionally
        note: notes?.at(-1)?.note ?? '',
      })
    },
    [notes, updateDealCollateralInformation],
  )

  const handleSaveChanges = () => {
    enforceLatestVersionToBeShownRef.current = true
    updateDealCollateralInformation({
      isStandardPackage: !!isStandardPackage,
      note: noteToShow,
    })
  }

  const handleCancelChanges = () => {
    const contentForVersion = getContentForVersion(versionToShow)
    setCurrentContent(contentForVersion)
  }

  const handleBackToCurrentVersionButtonClicked = useCallback(() => {
    setVersionToShow(latestVersion)
  }, [latestVersion])

  const handleVersionClicked = useCallback((selectedVersion) => {
    setVersionToShow(selectedVersion)
  }, [])

  /***************
   * Header
   */
  const backToCurrentVersionButton = useMemo(() => {
    const showBackToCurrentVersionButton = !!versionToShow && latestVersion > versionToShow
    if (showBackToCurrentVersionButton) {
      return (
        <Button
          design={ButtonDesign.Emphasized}
          key="back-to-current-version-button"
          id="back-to-current-version-button"
          onClick={handleBackToCurrentVersionButtonClicked}
        >
          {tNoPrefix('components.ui.buttons.versions-history.back-to-current-version.text')}
        </Button>
      )
    }
  }, [handleBackToCurrentVersionButtonClicked, tNoPrefix, latestVersion, versionToShow])

  const versionsHistoryButton = useMemo(
    () =>
      !!notes?.length && (
        <VersionsHistoryButtonLoadingWrapper
          key="versions-history-button-wrapper"
          id="versions-history-button-wrapper"
          versionData={notes.map((noteInLoop) => ({
            ...noteInLoop,
            lastUpdated: {
              name: noteInLoop.lastUpdatedBy,
              lastUpdatedOn: noteInLoop.lastUpdatedAt,
            },
          }))}
          onItemClicked={handleVersionClicked}
        />
      ),
    [handleVersionClicked, notes],
  )

  const standardPackageSwitch = useMemo(
    () => (
      <LabeledSwitch
        key="deal-collateral-is-standard-package-switch"
        checkedText={t('is-standard-package-on')}
        uncheckedText={t('is-standard-package-off')}
        checked={isStandardPackage}
        onChange={() => {
          handleSaveToggleChanges(!isStandardPackage)
        }}
        isLoading={isLoading}
        disabled={!isEditAllowed}
      />
    ),
    [isStandardPackage, isLoading, isEditAllowed, handleSaveToggleChanges, t],
  )

  const tileVersionActions = useMemo(
    () => [standardPackageSwitch, backToCurrentVersionButton, versionsHistoryButton],
    [standardPackageSwitch, backToCurrentVersionButton, versionsHistoryButton],
  )

  // layout
  return (
    <RequestStateResolver
      isError={isErrorDealCollateral}
      isLoading={isLoading}
      errorToDisplay={<ErrorDataUnavailableInContent />}
      renderContent={() => (
        <VersionedDisplayAndEditCard
          tileText={t('is-standard-package-title')}
          currentContent={noteToShow ?? ''}
          setCurrentContent={setCurrentContent}
          lastUpdatedBy={isEmpty(notes) ? null : lastUpdatedByName}
          lastUpdatedAt={isEmpty(notes) ? null : lastUpdatedAt}
          hasChanges={hasChanges}
          isEditEnabled={isEditAllowed}
          saveChanges={handleSaveChanges}
          cancelChanges={handleCancelChanges}
          saveHookIsSuccess={isUpdateSuccess}
          saveHookIsError={isUpdateError}
          additionalEditActions={tileVersionActions}
          formattingOptions={{
            editMaxCharacterAmount,
            viewMaxCharacterAmount,
          }}
        />
      )}
    />
  )
}

DealCollateralInformationCard.propTypes = {
  dealUuid: PropTypes.string.isRequired,
  allowedOperations: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default DealCollateralInformationCard
