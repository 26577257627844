import { MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import { Answer } from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/questionnaireStructure'

const Q1_1_KEY_PATH = ['Q1', 'Q1-1']
const Q1_2_KEY_PATH = ['Q1', 'Q1-2']
const Q1_3_KEY_PATH = ['Q1', 'Q1-3']
const Q1_4_KEY_PATH = ['Q1', 'Q1-4']
const Q1_5_KEY_PATH = ['Q1', 'Q1-5']
const Q1_6_KEY_PATH = ['Q1', 'Q1-6']
const Q1_7_KEY_PATH = ['Q1', 'Q1-7']
const Q1_8_KEY_PATH = ['Q1', 'Q1-8']
const Q2_KEY_PATH = ['Q2']
const Q3_1_KEY_PATH = ['Q3', 'Q3-1']
const Q3_2_KEY_PATH = ['Q3', 'Q3-2']
const Q3_3_KEY_PATH = ['Q3', 'Q3-3']
const Q4_KEY_PATH = ['Q4']
const Q5_KEY_PATH = ['Q5']
const Q6_1_KEY_PATH = ['Q6', 'Q6-1']
const Q6_2_KEY_PATH = ['Q6', 'Q6-2']
const Q6_3_KEY_PATH = ['Q6', 'Q6-3']
const Q6_4_KEY_PATH = ['Q6', 'Q6-4']
const Q7_KEY_PATH = ['Q7']
const Q8_KEY_PATH = ['Q8']
const Q9_1_KEY_PATH = ['Q9', 'Q9-1']
const Q9_2_KEY_PATH = ['Q9', 'Q9-2']
const Q9_3_KEY_PATH = ['Q9', 'Q9-3']

// Positive results
const RES_1_KEY = 'RES-1'
const RES_1_KEY_PATH = [RES_1_KEY]

// Negative results
const BAD_1_KEY = 'BAD-1'
const BAD_1_KEY_PATH = [BAD_1_KEY]
// Translation Key for highlighted text in results
const END_HIGHLIGHT_TEXT_KEY = 'END-HIGHLIGHT-TEXT'

export const shadowBankingStructure = [
  {
    key: 'Q1',
    children: [
      {
        key: 'Q1-1',
        alwaysVisible: true,
        proceedWith: [
          {
            key: Q1_2_KEY_PATH,
            condition: [{ keys: [Q1_1_KEY_PATH], result: Answer.NO }],
          },
          { key: Q2_KEY_PATH },
        ],
      },
      {
        key: 'Q1-2',
        proceedWith: [
          {
            key: Q1_3_KEY_PATH,
            condition: [{ keys: [Q1_2_KEY_PATH], result: Answer.NO }],
          },
          { key: Q2_KEY_PATH },
        ],
      },
      {
        key: 'Q1-3',
        proceedWith: [
          {
            key: Q1_4_KEY_PATH,
            condition: [{ keys: [Q1_3_KEY_PATH], result: Answer.NO }],
          },
          { key: Q2_KEY_PATH },
        ],
      },
      {
        key: 'Q1-4',
        proceedWith: [
          {
            key: Q1_5_KEY_PATH,
            condition: [{ keys: [Q1_4_KEY_PATH], result: Answer.NO }],
          },
          { key: Q2_KEY_PATH },
        ],
      },
      {
        key: 'Q1-5',
        proceedWith: [
          {
            key: Q1_6_KEY_PATH,
            condition: [{ keys: [Q1_5_KEY_PATH], result: Answer.NO }],
          },
          { key: Q2_KEY_PATH },
        ],
      },
      {
        key: 'Q1-6',
        proceedWith: [
          {
            key: Q1_7_KEY_PATH,
            condition: [{ keys: [Q1_6_KEY_PATH], result: Answer.NO }],
          },
          { key: Q2_KEY_PATH },
        ],
      },
      {
        key: 'Q1-7',
        proceedWith: [
          {
            key: Q1_8_KEY_PATH,
            condition: [{ keys: [Q1_7_KEY_PATH], result: Answer.NO }],
          },
          { key: Q2_KEY_PATH },
        ],
      },
      {
        key: 'Q1-8',
        proceedWith: [
          {
            key: RES_1_KEY_PATH,
            condition: [{ keys: [Q1_8_KEY_PATH], result: Answer.NO }],
          },
          { key: Q2_KEY_PATH },
        ],
      },
    ],
  },
  {
    key: 'Q2',
    proceedWith: [
      {
        key: Q3_1_KEY_PATH,
        condition: [{ keys: [Q2_KEY_PATH], result: Answer.NO }],
      },
      { key: BAD_1_KEY_PATH },
    ],
  },
  {
    key: 'Q3',
    children: [
      {
        key: 'Q3-1',
        proceedWith: [
          {
            key: Q3_2_KEY_PATH,
            condition: [{ keys: [Q3_1_KEY_PATH], result: Answer.NO }],
          },
          { key: BAD_1_KEY_PATH },
        ],
      },
      {
        key: 'Q3-2',
        proceedWith: [
          {
            key: Q3_3_KEY_PATH,
            condition: [{ keys: [Q3_2_KEY_PATH], result: Answer.NO }],
          },
          { key: BAD_1_KEY_PATH },
        ],
      },
      {
        key: 'Q3-3',
        proceedWith: [
          {
            key: Q4_KEY_PATH,
            condition: [{ keys: [Q3_3_KEY_PATH], result: Answer.NO }],
          },
          { key: BAD_1_KEY_PATH },
        ],
      },
    ],
  },
  {
    key: 'Q4',
    proceedWith: [
      {
        key: Q5_KEY_PATH,
        condition: [{ keys: [Q4_KEY_PATH], result: Answer.NO }],
      },
      { key: RES_1_KEY_PATH },
    ],
  },
  {
    key: 'Q5',
    proceedWith: [
      {
        key: Q6_1_KEY_PATH,
        condition: [{ keys: [Q5_KEY_PATH], result: Answer.NO }],
      },
      { key: RES_1_KEY_PATH },
    ],
  },
  {
    key: 'Q6',
    children: [
      {
        key: 'Q6-1',
        proceedWith: [
          {
            key: Q6_2_KEY_PATH,
            condition: [{ keys: [Q6_1_KEY_PATH], result: Answer.NO }],
          },
          { key: RES_1_KEY_PATH },
        ],
      },
      {
        key: 'Q6-2',
        proceedWith: [
          {
            key: Q6_3_KEY_PATH,
            condition: [{ keys: [Q6_2_KEY_PATH], result: Answer.NO }],
          },
          { key: RES_1_KEY_PATH },
        ],
      },
      {
        key: 'Q6-3',
        proceedWith: [
          {
            key: Q6_4_KEY_PATH,
            condition: [{ keys: [Q6_3_KEY_PATH], result: Answer.NO }],
          },
          { key: RES_1_KEY_PATH },
        ],
      },
      {
        key: 'Q6-4',
        proceedWith: [
          {
            key: Q7_KEY_PATH,
            condition: [{ keys: [Q6_4_KEY_PATH], result: Answer.NO }],
          },
          { key: RES_1_KEY_PATH },
        ],
      },
    ],
  },
  {
    key: 'Q7',
    proceedWith: [
      {
        key: Q8_KEY_PATH,
        condition: [{ keys: [Q7_KEY_PATH], result: Answer.NO }],
      },
      { key: RES_1_KEY_PATH },
    ],
  },
  {
    key: 'Q8',
    proceedWith: [
      {
        key: Q9_1_KEY_PATH,
        condition: [{ keys: [Q8_KEY_PATH], result: Answer.NO }],
      },
      { key: RES_1_KEY_PATH },
    ],
  },
  {
    key: 'Q9',
    children: [
      {
        key: 'Q9-1',
        proceedWith: [
          {
            key: Q9_2_KEY_PATH,
            condition: [{ keys: [Q9_1_KEY_PATH], result: Answer.NO }],
          },
          { key: RES_1_KEY_PATH },
        ],
      },
      {
        key: 'Q9-2',
        proceedWith: [
          {
            key: Q9_3_KEY_PATH,
            condition: [{ keys: [Q9_2_KEY_PATH], result: Answer.NO }],
          },
          { key: RES_1_KEY_PATH },
        ],
      },
      {
        key: 'Q9-3',
        proceedWith: [
          {
            key: BAD_1_KEY_PATH,
            condition: [{ keys: [Q9_3_KEY_PATH], result: Answer.NO }],
          },
          { key: RES_1_KEY_PATH },
        ],
      },
    ],
  },
]

const SHADOW_BANKING_RESULT_DESCRIPTION = 'isShadowBankingEntity'
export const possibleEndings = [
  {
    key: RES_1_KEY,
    highlightedTextKey: END_HIGHLIGHT_TEXT_KEY,
    messageStripDesign: MessageStripDesign.Positive,
    checkResultStatus: false,
    checkResultName: SHADOW_BANKING_RESULT_DESCRIPTION,
  },
  {
    key: BAD_1_KEY,
    highlightedTextKey: END_HIGHLIGHT_TEXT_KEY,
    messageStripDesign: MessageStripDesign.Warning,
    checkResultStatus: true,
    checkResultName: SHADOW_BANKING_RESULT_DESCRIPTION,
  },
]
