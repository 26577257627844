import { SuggestionItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import InputWithSearchSuggestions from 'components/ui/input/InputWithSearchSuggestions'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'
import useStaffMembers from 'hooks/services/business-partners/staff-members/useStaffMembers'

const ConditionAssigneeSelect = ({ onAssigneeChange, onRequestError, assigneeId }) => {
  const { data: assigneeUser, isError, error } = useStaffMemberById(assigneeId)
  const [userSearchKey, setUserSearchKey] = useState('')
  const { data: suggestions, isError: isSuggestionsError } = useStaffMembers({
    name: userSearchKey,
    minLength: 1,
  })

  const handleOnAssigneeChange = useCallback(
    (event) => {
      const newAssignee = event.detail.item.getAttribute('data-user-id')
      onAssigneeChange(newAssignee)
    },
    [onAssigneeChange],
  )

  const handleOnClear = useCallback(() => {
    onAssigneeChange(null)
  }, [onAssigneeChange])

  const renderSuggestions = useCallback(() => {
    const suggestionsItems = []
    suggestions?.staffMembers.forEach((user, index) => {
      suggestionsItems.push(
        <SuggestionItem key={index} text={user.fullName} data-user-id={user.id} />,
      )
    })
    return suggestionsItems
  }, [suggestions])

  useEffect(() => {
    if (isError) {
      onRequestError(error)
    }
  }, [error, isError, onRequestError])

  return (
    <InputWithSearchSuggestions
      initialValue={assigneeUser?.fullName ?? ''}
      renderSuggestions={renderSuggestions}
      handleOnChange={handleOnAssigneeChange}
      handleOnClear={handleOnClear}
      setSearchKey={setUserSearchKey}
      isSuggestionsError={isSuggestionsError}
    />
  )
}

ConditionAssigneeSelect.propTypes = {
  onAssigneeChange: PropTypes.func.isRequired,
  onRequestError: PropTypes.func.isRequired,
  assigneeId: PropTypes.string,
}

export default ConditionAssigneeSelect
