import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import documentsSchema from 'hooks/services/documents/documentsSchema'

/**
 * @param {object} [params = {}]
 * @param {string} [params.entityId]
 * @param {string} [params.entityType] Use `DocumentTypes`
 * @returns
 */
const useDocuments = ({ entityId, entityType } = {}) => {
  const params = new URLSearchParams()

  if (entityId) params.append('entity_id', entityId)

  if (entityType) params.append('entity_type', entityType)

  const response = useRequest({
    path: '/documents?' + params.toString(),
    useCache: true,
    keys: ['documents', entityType, entityId],
    options: { enabled: !!entityId && !!entityType },
  })
  const data = useMemo(
    () => documentsSchema.optional().parse(camelize(response.data)),
    [response.data],
  )
  return { ...response, data }
}
export default useDocuments
