import { Link, Button, MessageBoxActions, MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import DialogPrimaryButton from 'components/ui/dialog/DialogPrimaryButton'
import MessageBox from 'components/ui/message-box/MessageBox'
import { ConfigContext } from 'hooks/config/useConfig'
import { stringTemplate } from 'utils/stringTemplate'

/**
 * @typedef Props
 * @property {string} propertyUuid
 */
/**
 * @type {React.FC<Props>}
 */
const PropertyOpenInCmsButton = ({ propertyUuid }) => {
  const config = useContext(ConfigContext)

  const linkTemplate = config.properties ? config.properties.linktocms : ''
  const to = stringTemplate(linkTemplate, {
    propertyUuid: propertyUuid.replace(/-/g, '').toUpperCase(),
  })
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.properties.general-information.cms',
  })
  const { t: tNoPrefix } = useTranslation()

  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const handleClick = () => {
    setIsConfirmOpen(true)
  }

  const handleMessageBoxClose = () => {
    setIsConfirmOpen(false)
  }

  return (
    <>
      <Button onClick={handleClick}>{t('button')}</Button>
      {createPortal(
        <MessageBox
          open={isConfirmOpen}
          onClose={handleMessageBoxClose}
          type={MessageBoxTypes.Information}
          actions={[
            <Link key="okay-button" href={to} target="_blank">
              <DialogPrimaryButton>{tNoPrefix('buttons.ok')}</DialogPrimaryButton>
            </Link>,
            MessageBoxActions.Close,
          ]}
        >
          {t('confirmation-message')}
        </MessageBox>,
        document.body,
      )}
    </>
  )
}

PropertyOpenInCmsButton.propTypes = {
  propertyUuid: PropTypes.string,
}

export default PropertyOpenInCmsButton
