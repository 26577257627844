import { Icon, Toast, ToastPlacement } from '@fioneer/ui5-webcomponents-react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/analytical/DragAndDropZone.module.css'

const DragAndDropZone = ({ handleOnDrop }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.tiles.forbearance.components.tables.analytical.drag-and-drop-zone',
  })

  const dropZoneRef = useRef()

  const [{ isOver, canDrop }, dropRef] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop: handleOnDrop,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [],
  )

  useEffect(() => {
    if (canDrop) {
      dropZoneRef?.current?.show()
    }
  }, [canDrop, isOver, dropZoneRef])

  const className = classNames({
    [styles.documentDragAndDropContainer]: !isOver && !canDrop,
    [styles.isOver]: isOver && canDrop,
    [styles.isNotOver]: !isOver && canDrop,
  })

  const iconClassName = classNames({
    [styles.icon]: !isOver && !canDrop,
    [styles.iconCanDrop]: !isOver && canDrop,
    [styles.iconIsOver]: isOver && canDrop,
  })

  return (
    <div className={className} ref={dropRef}>
      {canDrop && (
        <Toast
          className={styles.toast}
          ref={dropZoneRef}
          placementType={ToastPlacement.BottomCenter}
          duration={60000}
        >
          {isOver ? t('success-message') : t('drop-message')}
        </Toast>
      )}

      <Icon className={iconClassName} name="upload" />
      {t('drop')}
    </div>
  )
}

export default DragAndDropZone

DragAndDropZone.propTypes = {
  handleOnDrop: PropTypes.func.isRequired,
}
