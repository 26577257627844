import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDealUses = (dealUuid, options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/uses`,
      translated: true,
      useCache: true,
      keys: ['deals', dealUuid, 'uses'],
      options,
    }),
  )

export default useDealUses
