/**
 * @param {CustomEvent<{ctrlKey: boolean | undefined, metaKey: boolean | undefined}?>} event
 */
const customClickEventToMouseEvent = (event) => {
  event.preventDefault()
  event.stopImmediatePropagation()
  if (!event.detail) {
    const newEvent = new MouseEvent('click', {
      ctrlKey: event?.ctrlKey,
      metaKey: event?.metaKey,
      detail: 0,
    })
    newEvent.preventDefault()
    newEvent.stopImmediatePropagation()
    return newEvent
  }
  const {
    detail: { ctrlKey, metaKey },
  } = event
  const newEvent = new MouseEvent('click', { ctrlKey, metaKey, detail: 1 })
  newEvent.preventDefault()
  newEvent.stopImmediatePropagation()
  return newEvent
}

export default customClickEventToMouseEvent
