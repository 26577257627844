import { Tree, TreeItem } from '@fioneer/ui5-webcomponents-react'
import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import EmptyNewBusinessCheckContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/EmptyNewBusinessCheckContent'
import NewBusinessCheckQuestion from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/NewBusinessCheckQuestion'
import { NewBusinessCheckResultMessageStrip } from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/NewBusinessCheckResultMessageStrip'
import {
  Answer,
  getInfoMessageTypesToDisplay,
  getKeyPathToProceedWith,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/questionnaireStructure'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/shadow-banking/shadow-banking-questionnaire-version-1/ShadowBankingQuestionnaireTile.module.css'
import {
  possibleEndings,
  shadowBankingStructure,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/shadow-banking/shadow-banking-questionnaire-version-1/shadowBankingQuestionStructure'
import { useQuestionnaireHelpers } from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/useQuestionnaireHelpers'

const ShadowBankingQuestionnaireTile = ({
  tileId,
  currentContent: currentContentStringified,
  onChange,
  isEditMode,
  isPdfView,
  setHasContentChanges,
}) => {
  const { answers: currentAnswers } = useMemo(
    () =>
      currentContentStringified && typeof currentContentStringified === 'string'
        ? JSON.parse(currentContentStringified)
        : {},
    [currentContentStringified],
  )

  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const {
    calculateEnabledQuestions,
    buildResults,
    hasAnswerStructureNestedValue,
    findResult,
    handleOnChange,
    handleCommentChange,
  } = useQuestionnaireHelpers({
    currentAnswers,
    baseStructure: shadowBankingStructure,
    possibleEndings,
    onChange,
  })

  const [enabledQuestions, setEnabledQuestions] = useState(buildResults(shadowBankingStructure))
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.nbcs.shadow-banking',
  })
  const showEditMode = useMemo(() => !isPdfView && isEditMode, [isEditMode, isPdfView])

  useEffect(() => {
    setEnabledQuestions(calculateEnabledQuestions(currentAnswers, shadowBankingStructure))
  }, [calculateEnabledQuestions, currentAnswers])

  useEffect(() => {
    const hasContentChanges =
      !(isNil(currentContentStringified) && isNil(tileStatus?.data?.data)) &&
      !isEqual(tileStatus?.data?.data, currentContentStringified)
    setHasContentChanges(hasContentChanges)
  }, [currentContentStringified, setHasContentChanges, tileStatus?.data?.data])

  const resultStripBelowQuestionInEdit = (proceedWith, keyPath) => {
    const proceedWithKeyPath = getKeyPathToProceedWith(keyPath, currentAnswers, proceedWith)
    if (isEditMode && proceedWithKeyPath) {
      return possibleEndings.find((item) => isEqual(item.key, proceedWithKeyPath[0]))
    }
  }

  const concatenateStyleClasses = (classes) => classes.filter((className) => !!className).join(' ')

  const renderTree = (data, level, keyPath) =>
    data.map((child, index) => {
      const keyPathWithChild = [...keyPath, child.key]

      if (child?.children?.length) {
        // Numerate first level in alphabetical order
        // eslint-disable-next-line no-magic-numbers
        const numeration = level === 1 ? String.fromCharCode(index + 1 + 64) : `${index + 1}.`
        const structureForTreeExpansion = isEditMode ? enabledQuestions : currentAnswers
        const isRelevantQuestionBelowNode = hasAnswerStructureNestedValue(
          findResult(keyPathWithChild, structureForTreeExpansion),
          isEditMode ? [true, false] : Object.values(Answer),
        )

        if (!isEditMode && !isRelevantQuestionBelowNode) {
          return
        }

        return (
          <TreeItem
            expanded={isRelevantQuestionBelowNode}
            className={concatenateStyleClasses([
              styles.node,
              level === 1 && styles.firstLevelNode,
              index === 0 && styles.firstNodeAtLevel,
              data.length - 1 === index && styles.lastNodeAtLevel,
            ])}
            text={`${numeration} ${t(keyPathWithChild.join('.'))}`}
            key={keyPathWithChild}
          >
            {child?.hint && (
              <TreeItem
                className={styles.internalNodeHint}
                text={t(`${keyPathWithChild.join('.')}.hint`)}
              />
            )}
            {renderTree(child?.children, level + 1, keyPathWithChild)}
          </TreeItem>
        )
      }

      const answer = findResult(keyPathWithChild, currentAnswers)
      if (isEditMode || !isNil(answer)) {
        return (
          <NewBusinessCheckQuestion
            key={keyPathWithChild.join('-')}
            question={child}
            questionResult={answer}
            keyPath={keyPathWithChild}
            showEditMode={showEditMode}
            questionIsDisabled={!findResult(keyPathWithChild, enabledQuestions)}
            resultMessageStrip={resultStripBelowQuestionInEdit(child.proceedWith, keyPathWithChild)}
            infoMessageTypes={getInfoMessageTypesToDisplay(
              keyPathWithChild,
              currentAnswers,
              child?.infos,
            )}
            t={t}
            handleOnChange={handleOnChange}
            handleCommentChange={handleCommentChange}
            className={concatenateStyleClasses([
              styles.node,
              styles.leafNode,
              level === 1 ? styles.firstLevelNode : styles.nestedLeafNode,
              index === 0 && styles.firstNodeAtLevel,
              data.length - 1 === index && styles.lastNodeAtLevel,
            ])}
          />
        )
      }
    })

  const getActiveEnding = () =>
    Object.entries(enabledQuestions).find((possibleEnding) => possibleEnding[1] === true)?.[0]

  return (
    <>
      <NewBusinessCheckResultMessageStrip
        activeEnding={getActiveEnding()}
        possibleEndings={possibleEndings}
        isEditMode={isEditMode}
        t={t}
      />
      {currentAnswers || isEditMode ? (
        <Tree className={styles.tree}>{renderTree(shadowBankingStructure, 1, [])}</Tree>
      ) : (
        <EmptyNewBusinessCheckContent />
      )}
    </>
  )
}

ShadowBankingQuestionnaireTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  currentContent: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
}

export default ShadowBankingQuestionnaireTile
