// allowed input keys include thousand separator, decimal separator and shorthands for thousand (k) million (m) and billion (b)
const allowedInputKeys = ['-', '.', ',', 'k', 'm', 'b']

/**
 * check for invalid shorthand key
 * @param {{key: string, value: string, selectionStart: number}} options
 * @returns {boolean} whether the shorthand is invalid
 */
const isInvalidShorthand = ({ key, value, selectionStart }) => {
  const keyIsShortHand = key.length === 1 && /[bmkBMK]/.test(key)
  // disallow shorthands if the cursor is at the beginning of the input
  if (!keyIsShortHand) {
    return false
  }

  return (
    selectionStart === 0 || // disallow shorthands as first character
    selectionStart !== value.length || // disallow shorthands not at the end of the number
    value.includes(key) // disallow repeated shorthands
  )
}

/**
 * @typedef {{decimalSeparator: string, thousandsSeparator: string}} Separators
 * @param {{key: string, value: string, selectionStart: number, selectionEnd: number, separators: Separators}} options
 * @returns {boolean} whether the input would result in an invalid separator
 */
const isInvalidSeparator = ({ key, value, selectionStart, selectionEnd, separators }) => {
  // prevent input of thousand separators
  if (key === separators.thousandsSeparator) {
    return true
  }

  // allow input of decimal separator if the cursor is at the beginning of the input
  if (
    key === separators.decimalSeparator &&
    selectionStart === 0 &&
    !value.includes(separators.decimalSeparator)
  ) {
    return false
  }

  // disallow - sign if it is not the first character or if there is already a - sign
  if (key === '-' && (selectionStart !== 0 || value.includes('-'))) {
    return true
  }

  // if decimal separator already exists, prevent input of decimal separator
  if (key === separators.decimalSeparator && value.includes(separators.decimalSeparator)) {
    return true
  }

  // disallow separators if the cursor is at the beginning of the input
  if (
    [separators.thousandsSeparator, separators.decimalSeparator].includes(key) &&
    selectionStart === 0
  ) {
    return true
  }

  // Check if the key pressed is Backspace and if the selection range is around a comma or period
  if (
    selectionStart === selectionEnd &&
    key === 'Backspace' &&
    value[selectionStart - 1] === separators.thousandsSeparator
  ) {
    return true
  }

  return false
}

/**
 * Prevents input of non-allowed keys in an event.
 *
 * @param {Object} event - The event object from a keydown or keypress event.
 * @param {string} event.key - The key property from the event object.
 * @param {boolean} event.metaKey - The metaKey property from the event object.
 * @param {boolean} event.ctrlKey - The ctrlKey property from the event object.
 * @returns {void} If the key is not allowed, it prevents the default action of the event.
 */
export const isInputAllowed = (event, separators) => {
  const { key, target, metaKey, ctrlKey } = event
  const { value } = target
  const selectionStart = event?.target?.nativeInput?.selectionStart
  const selectionEnd = event?.target?.nativeInput?.selectionEnd

  // disallow invalid shorthands (not end of string, repeated shorthands)
  if (isInvalidShorthand({ key, value, selectionStart })) {
    return false
  }

  // disallow invalid separators (thousands and decimal)
  if (isInvalidSeparator({ key, value, selectionStart, selectionEnd, separators })) {
    return false
  }

  // Check if the metaKey or ctrlKey is pressed
  if (metaKey || ctrlKey) {
    return true
  }

  return !(
    (!isFinite(event.key) && !(event.key.length > 1) && !allowedInputKeys.includes(event.key)) ||
    event.key === ' '
  )
}
