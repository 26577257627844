const mapCovenantUuidsPerPeriodicalCheckTypePerBusinessPartner = (
  businessPartnerIdAndCovenantsPairs,
) => {
  const covenantUuidsPerPeriodicalCheckTypePerBusinessPartner = {}
  businessPartnerIdAndCovenantsPairs?.forEach(({ covenants, businessPartnerId: bpId }) => {
    const periodicalChecks = {}
    covenants?.forEach((covenant) => {
      periodicalChecks[covenant.covenantDefinition.name] = covenant.covenantUuid
    })
    covenantUuidsPerPeriodicalCheckTypePerBusinessPartner[bpId] = periodicalChecks
  })

  const covenantUuids = Object.values(
    covenantUuidsPerPeriodicalCheckTypePerBusinessPartner,
  ).flatMap((covenantUuidsPerPeriodicalCheckType) =>
    Object.values(covenantUuidsPerPeriodicalCheckType),
  )
  return { covenantUuidsPerPeriodicalCheckTypePerBusinessPartner, covenantUuids }
}

export default mapCovenantUuidsPerPeriodicalCheckTypePerBusinessPartner
