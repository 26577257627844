import {
  CustomListItem,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Icon,
  IconDesign,
  List,
  ListMode,
  Popover,
  PopoverPlacementType,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import sortBy from 'lodash.sortby'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styles from 'components/domains/conditions/dialogs/condition-creation/ConditionCreationValidationErrorPopover.module.css'
import { setFocusElement, setRowExpanded } from 'redux/slices/conditions/conditionCreationSlice'

const ConditionCreationValidationErrorPopover = ({
  opener,
  setIsOpen,
  isOpen,
  detailedErrorInformation,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.dialog.external-condition-creation.error-dialog',
  })
  const dispatch = useDispatch()
  const handleOnRowClick = useCallback(
    ({ conditionIndex, requirementIndex, fieldName }) =>
      () => {
        setIsOpen(false)
        dispatch(setRowExpanded({ conditionIndex, expanded: true }))
        dispatch(setFocusElement({ conditionIndex, requirementIndex, fieldName }))
      },
    [setIsOpen, dispatch],
  )

  const mappedErrors = useMemo(
    () =>
      sortBy(detailedErrorInformation, [
        'additionalInformation.error.warning',
        'conditionIndex',
        'requirementIndex',
        'fieldName',
      ])
        .filter(({ additionalInformation: { showError } }) => showError)
        .map(
          ({
            fieldName,
            conditionIndex,
            requirementIndex,
            isConditionError,
            additionalInformation: {
              error: { warning },
            },
          }) => {
            const fieldNameText = isConditionError
              ? t(`conditions.${fieldName}`)
              : t(`requirements.${fieldName}`)
            return (
              <CustomListItem
                key={`${conditionIndex}-${requirementIndex}-${fieldName}`}
                icon="alert"
              >
                <FlexBox
                  alignItems={FlexBoxAlignItems.Center}
                  justifyContent={FlexBoxJustifyContent.SpaceBetween}
                  className={styles.errorRow}
                  onClick={handleOnRowClick({ conditionIndex, requirementIndex, fieldName })}
                >
                  <FlexBox alignItems={FlexBoxAlignItems.Center} className={styles.rowContainer}>
                    <Icon
                      name="alert"
                      className={warning ? styles.iconAlertWarning : styles.iconAlertError}
                    />
                    <FlexBox
                      direction={FlexBoxDirection.Column}
                      className={styles.messageContainer}
                    >
                      <Text className={styles.text}>{fieldNameText}</Text>
                      <Text className={styles.fieldName}>
                        {isConditionError
                          ? t(`field-names.conditions.${fieldName}`)
                          : t(`field-names.requirements.${fieldName}`)}
                      </Text>
                    </FlexBox>
                  </FlexBox>
                  <Icon name="slim-arrow-right" design={IconDesign.Neutral} />
                </FlexBox>
              </CustomListItem>
            )
          },
        ),
    [detailedErrorInformation, handleOnRowClick, t],
  )

  return createPortal(
    <Popover
      id="rrwv-validation-results-summary-popover"
      className={styles.popover}
      opener={opener}
      placementType={PopoverPlacementType.Top}
      open={isOpen}
      onAfterClose={() => setIsOpen(false)}
      hideArrow
    >
      <List mode={ListMode.None}>{mappedErrors}</List>
    </Popover>,
    document.body,
  )
}

ConditionCreationValidationErrorPopover.propTypes = {
  opener: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  detailedErrorInformation: PropTypes.arrayOf(
    PropTypes.shape({
      conditionIndex: PropTypes.number.isRequired,
      requirementIndex: PropTypes.number,
      isConditionError: PropTypes.bool.isRequired,
      fieldName: PropTypes.string.isRequired,
      additionalInformation: PropTypes.shape({
        error: PropTypes.shape({
          warning: PropTypes.bool,
        }),
        showError: PropTypes.bool,
      }),
    }),
  ).isRequired,
}

export default ConditionCreationValidationErrorPopover
