import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDecisionPaperReferencedTemplate = ({ eventId }, options) =>
  useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/decision-paper/versions/current/referenced-template`,
      useCache: true,
      keys: ['events', eventId, 'decision-paper', 'versions', 'current', 'referenced-template'],
      options,
    }),
  )

export default useDecisionPaperReferencedTemplate
