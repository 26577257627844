import { InputType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { TableInput } from 'components/domains/properties/rent-roll/working-version/rental-units-table/PropertyRentalUnitsWorkingVersionTableInput'

const PropertyIdInput = ({ rowKey, contentKey, propertyUuidValue, properties }) => {
  const propertyUuid = propertyUuidValue ?? ''
  const selectedProperty = properties?.find((property) => property.key === propertyUuid)
  return (
    <TableInput
      rowKey={rowKey}
      contentKey={contentKey}
      valueObj={{ value: selectedProperty?.id ?? '' }}
      inputType={InputType.Text}
      readonly
    />
  )
}

export default PropertyIdInput

PropertyIdInput.propTypes = {
  rowKey: PropTypes.string.isRequired,
  contentKey: PropTypes.string.isRequired,
  propertyUuidValue: PropTypes.string,
  properties: PropTypes.array.isRequired,
}
