import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ErrorScreen from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/cashflow-scenario/shared/screens/ErrorScreen'
import LoadingScreen from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/cashflow-scenario/shared/screens/LoadingScreen'

/**
 * Wrapper component that shows either a loading screen, an error screen or the target content.
 *
 * @deprecated Please use RequestStateResolver!
 * @param {object} props
 * @param {boolean} props.isLoading Wether or not to show a loading screen
 * @param {boolean} props.isError Whether or not to show an error screen
 * @param {boolean} [props.isNotFoundError] Whether or not to show a not found error screen
 * @param {function} [props.renderContent] function to render the target content
 * @param {string} [props.loadingTitle] optional, defaults to 'app.loading.title'
 * @param {string} [props.loadingDescription] optional, defaults to 'app.loading.description'
 * @param {string} [props.errorTitle] optional, defaults to 'app.loading.error.title'
 * @param {string} [props.errorDescription] optional, defaults to 'app.loading.error.description'
 * @param {string} [props.errorDetails] optional, defaults to 'app.loading.error.details'
 * @param {string} [props.errorNotFoundTitle] optional, defaults to 'error.not-found.title'
 * @param {string} [props.errorNotFoundDescription] optional, defaults to 'error.not-found.description'
 * @param {string} [props.errorNotFoundDetails] optional, defaults to 'error.not-found.details'
 * @param {function} [props.renderCustomErrorScreen] function to render custom content in case of an error
 * @param {import('react').ReactNode} [props.children]
 */

const LoadingStateWrapper = ({
  isLoading,
  isError,
  isNotFoundError = false,
  renderContent,
  children,
  loadingTitle,
  loadingDescription,
  loadingSize,
  loadingClassName,
  errorTitle,
  errorDescription,
  errorDetails,
  errorNotFoundTitle,
  errorNotFoundDescription,
  errorNotFoundDetails,
  renderCustomErrorScreen,
}) => {
  const { t } = useTranslation('decisionPaper', { keyPrefix: 'tiles.cashflow-of-check' })

  if (isLoading) {
    return (
      <LoadingScreen
        title={t(loadingTitle ?? 'app.loading.title')}
        description={loadingDescription}
        size={loadingSize}
        className={loadingClassName}
      />
    )
  }

  if (isError) {
    if (renderCustomErrorScreen) {
      return renderCustomErrorScreen()
    }
    if (isNotFoundError) {
      errorTitle = errorNotFoundTitle ?? 'error.not-found.title'
      errorDescription = errorNotFoundDescription ?? 'error.not-found.description'
      errorDetails = errorNotFoundDetails ?? 'error.not-found.details'
    }
    return (
      <ErrorScreen
        title={t(errorTitle ?? 'app.loading.error.title')}
        description={t(errorDescription ?? 'app.loading.error.description')}
        error={t(errorDetails ?? 'app.loading.error.details')}
      />
    )
  }

  if (renderContent) return renderContent()
  if (children) return children
  return (
    <ErrorScreen
      title={t(errorTitle ?? 'app.loading.error.title')}
      description={t(errorDescription ?? 'app.loading.error.description')}
      error={t(errorDetails ?? 'app.loading.error.details')}
    />
  )
}

LoadingStateWrapper.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  isNotFoundError: PropTypes.bool,
  renderContent: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  loadingTitle: PropTypes.string,
  loadingDescription: PropTypes.string,
  loadingSize: PropTypes.string,
  loadingClassName: PropTypes.string,
  errorTitle: PropTypes.string,
  errorDescription: PropTypes.string,
  errorDetails: PropTypes.string,
  errorNotFoundTitle: PropTypes.string,
  errorNotFoundDescription: PropTypes.string,
  errorNotFoundDetails: PropTypes.string,
  renderCustomErrorScreen: PropTypes.func,
}

export default LoadingStateWrapper
