// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UPcU7dLHM9rDdqg5RzpG {
  text-align: 'center';
  display: 'block';
  position: absolute;
  inset: 0;
  margin-top: 4px;
  color: var(--sap_ui5_busy_indicator_color);
}


/* The text is only hidden to keep the buttons width identical when showing the busy indicator */
.KMgnle95ukPbNZrnNRY4 {
  color: transparent;
}

.qvKgaMczAlM1fNNppL4s {
  opacity: 1 !important;
}

.pkJTfvs7lLnqDWCYe0ll {
  background-color: color-mix(in srgb, var(--sapButton_Emphasized_Background), transparent 40%);
  border-color: transparent;
}

.kuQARCqhYbbB3boQKFHE {
  border: var(--sapButton_BorderWidth) solid color-mix(in srgb, var(--sapButton_Emphasized_BorderColor), transparent 40%)
}

.B3ODppOtnsQTzaUdjMVa {
  border: var(--sapButton_BorderWidth) solid color-mix(in srgb, var(--sapButton_Accept_BorderColor), transparent 40%)
}

.Cuq4uQ7CYZ1HdqUn6W6X {
  border: var(--sapButton_BorderWidth) solid color-mix(in srgb, var(--sapButton_Negative_BorderColor), transparent 40%)
}

.e74_mUH5LmScUPLoaJzO {
  border: var(--sapButton_BorderWidth) solid color-mix(in srgb, var(--sapButton_Attention_BorderColor), transparent 40%)
}

.uuBmGtDb1dJvitprqomn {
  color: white;
}

/* Strangely the color would be black if the design is set to Attention */
.BsuZyNPYECtVUbYo08F9 {
  color: var(--sapButton_Attention_BorderColor);
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/button/LoadingButton.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,kBAAkB;EAClB,QAAQ;EACR,eAAe;EACf,0CAA0C;AAC5C;;;AAGA,gGAAgG;AAChG;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,6FAA6F;EAC7F,yBAAyB;AAC3B;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE,YAAY;AACd;;AAEA,yEAAyE;AACzE;EACE,6CAA6C;AAC/C","sourcesContent":[".busyIndicator {\n  text-align: 'center';\n  display: 'block';\n  position: absolute;\n  inset: 0;\n  margin-top: 4px;\n  color: var(--sap_ui5_busy_indicator_color);\n}\n\n\n/* The text is only hidden to keep the buttons width identical when showing the busy indicator */\n.hiddenText {\n  color: transparent;\n}\n\n.removeOpacity {\n  opacity: 1 !important;\n}\n\n.emphasizedButtonDisabled {\n  background-color: color-mix(in srgb, var(--sapButton_Emphasized_Background), transparent 40%);\n  border-color: transparent;\n}\n\n.defaultButtonDisabled {\n  border: var(--sapButton_BorderWidth) solid color-mix(in srgb, var(--sapButton_Emphasized_BorderColor), transparent 40%)\n}\n\n.positiveButtonDisabled {\n  border: var(--sapButton_BorderWidth) solid color-mix(in srgb, var(--sapButton_Accept_BorderColor), transparent 40%)\n}\n\n.negativeButtonDisabled {\n  border: var(--sapButton_BorderWidth) solid color-mix(in srgb, var(--sapButton_Negative_BorderColor), transparent 40%)\n}\n\n.attentionButtonDisabled {\n  border: var(--sapButton_BorderWidth) solid color-mix(in srgb, var(--sapButton_Attention_BorderColor), transparent 40%)\n}\n\n.emphasizedBusyIndicator {\n  color: white;\n}\n\n/* Strangely the color would be black if the design is set to Attention */\n.attentionBusyIndicator {\n  color: var(--sapButton_Attention_BorderColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"busyIndicator": `UPcU7dLHM9rDdqg5RzpG`,
	"hiddenText": `KMgnle95ukPbNZrnNRY4`,
	"removeOpacity": `qvKgaMczAlM1fNNppL4s`,
	"emphasizedButtonDisabled": `pkJTfvs7lLnqDWCYe0ll`,
	"defaultButtonDisabled": `kuQARCqhYbbB3boQKFHE`,
	"positiveButtonDisabled": `B3ODppOtnsQTzaUdjMVa`,
	"negativeButtonDisabled": `Cuq4uQ7CYZ1HdqUn6W6X`,
	"attentionButtonDisabled": `e74_mUH5LmScUPLoaJzO`,
	"emphasizedBusyIndicator": `uuBmGtDb1dJvitprqomn`,
	"attentionBusyIndicator": `BsuZyNPYECtVUbYo08F9`
};
export default ___CSS_LOADER_EXPORT___;
