import { AnalyticalTableSelectionMode } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import AnalyticalTableNoDataComponent from 'components/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import { useRentRollComparisonColumns } from 'hooks/services/properties/rent-roll/comparison/useRentRollComparisonColumns'
import { useRentRollComparisonRows } from 'hooks/services/properties/rent-roll/comparison/useRentRollComparisonRows'

const PropertyRentRollComparisonTable = ({ property, segments }) => {
  const tableRef = useRef()
  const { t: tComparison } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.comparison',
  })
  const columnDefinitions = useRentRollComparisonColumns(
    property?.area_measure_unit_code ?? tComparison('uom'),
    segments,
  )
  const {
    isLoading: isTableDataLoading,
    isError: isTableDataError,
    data: tableData,
  } = useRentRollComparisonRows({ property, segments })
  return (
    <AnalyticalTableWithToolbar
      ref={tableRef}
      columns={columnDefinitions}
      data={tableData ?? []}
      headerRowHeight={40}
      rowHeight={140}
      loading={false}
      adjustTableHeightOnPopIn
      disableColumnPopover={true}
      groupable={true}
      filterable={true}
      sortable={true}
      minRows={1}
      title={tComparison('sub-title')}
      NoDataComponent={() => (
        <AnalyticalTableNoDataComponent
          isLoading={isTableDataLoading}
          isError={isTableDataError}
          tableId={'property-rent-roll-comparison-table'}
        />
      )}
      selectionMode={AnalyticalTableSelectionMode.None}
      reactTableOptions={{ autoResetSelectedRows: false }}
      // row highlight needs to be deactivated here, since there is a custom implementation used
      withRowHighlight={false}
    />
  )
}
PropertyRentRollComparisonTable.propTypes = {
  property: PropTypes.object.isRequired,
  segments: PropTypes.array.isRequired,
}
export default PropertyRentRollComparisonTable
