import { periodValues } from 'components/domains/deals/deal-cashflow/results/PeriodSelect'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useCashFlowScenario } from 'hooks/services/deals/cashflow/useCashFlowScenario'
import useCashflowGroups from 'hooks/services/deals/cashflow/useCashflowGroups'
import useCashflowScenarioResultsById from 'hooks/services/deals/cashflow/useCashflowScenarioResultsById'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import useCovenantChecks from 'hooks/services/deals/covenants/checks/useCovenantChecks'
import useCovenantCheckById from 'hooks/services/deals/covenants/useCovenantCheckById'
import useDealMini from 'hooks/services/deals/useDealMini'

const useCashFlowScenarioOfCheck = (
  { entityRef: { entityId: dealUuid } = {}, id: eventId } = {},
  tileId,
) => {
  const {
    isFetching: isFetchingCovenantCheckList,
    isError: isErrorCovenantCheckList,
    data: { covenantChecks = [] } = {},
    error: errorCovenantCheckList,
  } = useCovenantChecks({
    mainEntityType: mainEntityTypes.DEAL,
    eventId,
  })
  const { covenantCheckUuid } = covenantChecks?.[0] ?? {}

  const {
    isFetching: isFetchingCovenantCheck,
    isError: isErrorCovenantCheck,
    data: covenantCheck = {},
    error: errorCovenantCheck,
  } = useCovenantCheckById(covenantCheckUuid)

  const cashFlowScenarioUuid = covenantCheck?.linkedCashflowScenarioUuid

  const {
    data: cashFlowScenario = {},
    isError: isErrorScenario,
    isFetching: isFetchingScenario,
    error: errorScenario,
  } = useCashFlowScenario(dealUuid, cashFlowScenarioUuid, {
    enabled: !!cashFlowScenarioUuid && !!dealUuid,
  })

  const {
    data: { calculatedKpis = [] } = {},
    isError: isErrorResults,
    isFetching: isFetchingResults,
    error: errorResults,
  } = useCashflowScenarioResultsById(
    {
      dealUuid: dealUuid,
      cashflowId: cashFlowScenarioUuid,
      datasetPeriod: periodValues.years,
    },
    { enabled: !!cashFlowScenarioUuid && !!dealUuid },
  )

  const {
    data: { groups = [] } = {},
    isFetching: isFetchingGroups,
    isError: isErrorGroups,
    error: errorGroups,
  } = useCashflowGroups()

  const {
    data: { dealId } = {},
    isError: isErrorDeal,
    isFetching: isFetchingDeal,
    error: errorDeal,
  } = useDealMini(dealUuid)

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isFetchingCovenantCheckList,
      isFetchingCovenantCheck,
      isFetchingScenario,
      isFetchingResults,
      isFetchingGroups,
      isFetchingDeal,
    ],
    errorValues: [
      isErrorCovenantCheckList,
      isErrorCovenantCheck,
      isErrorScenario,
      isErrorResults,
      isErrorGroups,
      isErrorDeal,
    ],
    errorDetails: [
      errorCovenantCheckList,
      errorCovenantCheck,
      errorScenario,
      errorResults,
      errorGroups,
      errorDeal,
    ],
    tileId,
  })

  if (isSomeValueError) {
    return { isLoading: false, isError: true, error, data: undefined }
  }
  if (isSomeValueLoading) {
    return { isLoading: true, isError: false, data: undefined }
  }

  const path = `/deals/${dealId}/covenants/covenant-checks/${covenantCheckUuid}`

  return {
    isLoading: false,
    isError: false,
    data: {
      sourceRender: {
        path,
      },
      dealUuid,
      dealDisplayId: dealId,
      tileId,
      covenantCheck,
      cashFlowScenario,
      calculatedKpis,
      groups,
    },
  }
}

export default useCashFlowScenarioOfCheck
