import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FurtherDetailsPieChartsCard, {
  chartsWithValuations,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/FurtherDetailsPieChartsCard'
import {
  UsageTypeColorProvider,
  useUsageTypeColors,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/UsageTypeColorContext'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/card/EmptyCardContent'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const MultipleFurtherDetailsGranularityPropertyTypeTile = ({
  tileId,
  isPdfView,
  selectedDealIndex,
}) => {
  const dispatch = useDispatch()

  const {
    data: {
      multiplePropertyGranularityPieChartData = [],
      sourcePaths = [],
      sourceRender,
      dealsData,
    },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const selectedDealDisplayId = dealsData[selectedDealIndex]?.dealDisplayId

  useEffect(() => {
    dispatch(setSourceRender({ tileId, sourceRender: { path: sourcePaths?.[selectedDealIndex] } }))
  }, [dispatch, selectedDealDisplayId, selectedDealIndex, sourcePaths, tileId])

  useEffect(() => {
    if (isNil(sourceRender)) {
      dispatch(
        setSourceRender({ tileId, sourceRender: { path: sourcePaths?.[selectedDealIndex] } }),
      )
    }
  }, [dispatch, selectedDealDisplayId, selectedDealIndex, sourcePaths, sourceRender, tileId])

  const {
    multiPropertyKpisByGranularity,
    multiPropertyValuationsByGranularity,
    isMultiProperty,
    noProperties,
  } = useMemo(() => {
    const propertyGranularityPieChartData =
      multiplePropertyGranularityPieChartData?.[selectedDealIndex] ?? {}

    return {
      multiPropertyKpisByGranularity:
        propertyGranularityPieChartData?.multiPropertyKpisByGranularity
          ?.keyDateToMultiPropertyRentRollKpis?.[0]?.kpis,
      multiPropertyValuationsByGranularity:
        propertyGranularityPieChartData?.multiPropertyValuationsByGranularity,
      isMultiProperty: propertyGranularityPieChartData?.isMultiProperty,
      noProperties: !propertyGranularityPieChartData?.properties,
    }
  }, [multiplePropertyGranularityPieChartData, selectedDealIndex])

  const getPropertyTypeRentRollKpis = (multiPropertyKpis) =>
    multiPropertyKpis?.propertyTypeRentRollKpis ?? []

  const getPropertyTypeValuations = (multiPropertyValuations) =>
    multiPropertyValuations?.granularities?.propertyType ?? []

  const getPropertyTypeName = (rentRollKpi) => rentRollKpi?.propertyTypeName

  if (noProperties) {
    return <NoPropertyAssignedToDealInfo />
  }
  if (!isMultiProperty) {
    return <EmptyCardContent />
  }
  return (
    <FurtherDetailsPieChartsCard
      colorProvider={UsageTypeColorProvider}
      useColors={useUsageTypeColors}
      getRentRollKpis={getPropertyTypeRentRollKpis}
      getValuations={getPropertyTypeValuations}
      getNameFromKpi={getPropertyTypeName}
      granularityValues={{ multiPropertyKpisByGranularity, multiPropertyValuationsByGranularity }}
      chartOptions={chartsWithValuations}
      isPdfView={isPdfView}
    />
  )
}

MultipleFurtherDetailsGranularityPropertyTypeTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultipleFurtherDetailsGranularityPropertyTypeTile
