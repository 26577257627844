import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/DisplayCardViewItemView'

const dash = '-'

const GeneralInformationComplianceWithRiskStrategyField = ({ dealRiskConformity }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.general-information.tile.fields',
  })
  const { t: tBoolean } = useTranslation('decisionPaper', {
    keyPrefix: 'formatters.boolean',
  })

  const complianceWithRiskStrategyValue = useMemo(() => {
    if (isNil(dealRiskConformity) || isNil(dealRiskConformity.compliance)) {
      return dash
    }
    return dealRiskConformity.compliance ? tBoolean('yes') : tBoolean('no')
  }, [dealRiskConformity, tBoolean])

  return (
    <DisplayCardViewItemView>
      <DisplayCardViewInfoItemView
        label={t('compliance-with-risk-strategy')}
        value={complianceWithRiskStrategyValue}
      />
    </DisplayCardViewItemView>
  )
}

GeneralInformationComplianceWithRiskStrategyField.propTypes = {
  dealRiskConformity: PropTypes.shape({
    compliance: PropTypes.bool,
  }),
}

export default GeneralInformationComplianceWithRiskStrategyField
