import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/rent-roll/details/PropertyRentRollDetails.module.css'
import PropertyRentRollDetailsTile from 'components/domains/properties/rent-roll/details/PropertyRentRollDetailsTile'
import PropertyRentRollDetailsTable from 'components/domains/properties/rent-roll/details/table/PropertyRentRollDetailsTable'
import CreateRentRollWorkingVersionButton from 'components/domains/properties/rent-roll/working-version/PropertyRentRollCreateWorkingVersionButton'
import PropertyRentRollWorkingVersionEdit from 'components/domains/properties/rent-roll/working-version/PropertyRentRollWorkingVersionEdit'
import PropertyRentRollExcelUpload from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollExcelUpload'
import ExcelUploadContextProvider from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollExcelUploadContext'
import Card from 'components/ui/card/Card'
import CardHeaderWithButtons from 'components/ui/card/CardHeaderWithButtons'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import StaffMemberByUserId from 'components/ui/staff-member/StaffMemberByUserId'
import LastEditedText from 'components/ui/text/LastEditedText'
import { useRentRoll } from 'hooks/services/properties/useRentRoll'
import '@ui5/webcomponents-fiori/dist/illustrations/NoEntries.js'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyRentRollDetails = () => {
  const { t } = useTranslation()
  const { property } = useContext(PropertyContext)
  const { uuid: propertyUuid } = property
  const { data: rentRoll, isLoading, isError, isNotFoundError } = useRentRoll(propertyUuid)

  const getRentRoll = () => rentRoll ?? {}

  const getRentalUnits = () => rentRoll?.rental_units ?? []

  const {
    last_change_user_account_id: lastChangedUser = '',
    last_change_date_time: lastChangedTime = '',
  } = rentRoll?.system_administrative_data ?? {}

  const subTitle = useMemo(
    () => (
      <LastEditedText lastUpdatedAt={lastChangedTime}>
        <StaffMemberByUserId s4UserId={lastChangedUser} />
      </LastEditedText>
    ),
    [lastChangedUser, lastChangedTime],
  )

  const header = (
    <CardHeaderWithButtons
      gridArea="rent-roll-title"
      title={t('pages.property.rent-roll.header.title')}
      subTitle={subTitle}
    >
      <ExcelUploadContextProvider>
        <PropertyRentRollExcelUpload propertyUuids={[propertyUuid]} />
      </ExcelUploadContextProvider>
      <CreateRentRollWorkingVersionButton rentalUnits={getRentalUnits()} properties={[property]} />
      <PropertyRentRollWorkingVersionEdit properties={[property]} />
    </CardHeaderWithButtons>
  )

  const renderContent = () => (
    <>
      <PropertyRentRollDetailsTile rentRoll={getRentRoll()} />
      <PropertyRentRollDetailsTable rentalUnits={getRentalUnits()} />
    </>
  )

  return (
    <FlexBox className={styles['rent-roll-page-wrapper']} direction={FlexBoxDirection.Column}>
      <Card className={styles['rent-roll-card']} header={header}>
        {isNotFoundError ? (
          <EmptyCardContent
            title={t('components.cards.empty.title')}
            subtitle={t('components.cards.empty.subtitle')}
          />
        ) : (
          <div className={styles['rent-roll-wrapper']}>
            <RequestStateResolver
              isLoading={isLoading}
              errorToDisplay={<ErrorDataUnavailableInContent />}
              isError={isError && !isNotFoundError}
              renderContent={renderContent}
            />
          </div>
        )}
      </Card>
    </FlexBox>
  )
}

export default PropertyRentRollDetails
