import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { cwpLayoutAdapter } from 'components/ui/configurable-components-page/core-components/layout/cwpLayoutAdapter'
import { ui5GridAdapter } from 'components/ui/configurable-components-page/core-components/layout/ui5GridAdapter'
import DynamicMessageStrip from 'components/ui/configurable-components-page/core-components/ui5-wrapper/DynamicMessageStrip'
import {
  objectPageSectionAdapter,
  objectPageSubSectionAdapter,
} from 'components/ui/configurable-components-page/core-components/ui5-wrapper/objectPageSectionAdapter'
import {
  ComponentTypeEnum,
  useCreateComponentRegistry,
} from 'components/ui/configurable-components-page/useComponentRegistry'

/**
 * @enum
 */
export const CoreComponentsEnum = {
  DynamicMessageStrip: 'DYNAMIC_MESSAGE_STRIP',
  CwpLayout: 'CWP_LAYOUT',
  Ui5Grid: 'UI5_GRID',
  PageSection: 'PAGE_SECTION',
  PageSubSection: 'PAGE_SUB_SECTION',
}

const CoreComponentsRegistry = ({ children }) => {
  const { ComponentRegistryProvider, registry } = useCreateComponentRegistry('CoreComponents')
  useEffect(() => {
    registry.register(CoreComponentsEnum.DynamicMessageStrip, { Component: DynamicMessageStrip })
    registry.register(CoreComponentsEnum.CwpLayout, {
      Component: cwpLayoutAdapter,
      type: ComponentTypeEnum.Adapter,
    })
    registry.register(CoreComponentsEnum.Ui5Grid, {
      Component: ui5GridAdapter,
      type: ComponentTypeEnum.Adapter,
    })
    registry.register(CoreComponentsEnum.PageSection, {
      Component: objectPageSectionAdapter,
      type: ComponentTypeEnum.Adapter,
    })
    registry.register(CoreComponentsEnum.PageSubSection, {
      Component: objectPageSubSectionAdapter,
      type: ComponentTypeEnum.Adapter,
    })
  }, [registry])
  return <ComponentRegistryProvider>{children}</ComponentRegistryProvider>
}

CoreComponentsRegistry.propTypes = {
  children: PropTypes.node,
}

export default CoreComponentsRegistry
