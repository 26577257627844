import { FlexBoxJustifyContent, TextAlign } from '@fioneer/ui5-webcomponents-react'
import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { UI5_TABLE_CELL_PADDING } from 'components/ui/tables/display-and-edit-table/constants'
import useFilters, { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'
import { useBooleanToTextFormatter } from 'hooks/i18n/useI18n'

const useBusinessPartnerInternalRatingsColumns = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner.ratings' })
  const { t: translationWithoutPrefix } = useTranslation()

  const formatBoolean = useBooleanToTextFormatter()
  const todayMinusFiveYears = DateTime.now().minus({ years: 5 }).toISODate()

  const filters = useFilters({
    overrides: {
      [filterTypes.BETWEEN_DATES]: {
        value: { lowerBound: todayMinusFiveYears, upperBound: '' },
      },
    },
  })

  //This is needed since there are inconsistencies in s4
  const formatToBooleanValues = useCallback(
    (value) => {
      switch (value) {
        case 'X':
          return translationWithoutPrefix(`formatters.boolean.yes`)
        case '':
          return translationWithoutPrefix(`formatters.boolean.no`)
        default:
          return null
      }
    },
    [translationWithoutPrefix],
  )

  const columnDefinitions = useMemo(
    () => [
      {
        columnKey: 'method',
        title: t('internal.method'),
        filter: filterTypes.CONTAINS,
        minWidth: 120,
      },
      {
        columnKey: 'status',
        title: t('internal.status'),
        filter: filterTypes.CONTAINS,
        minWidth: 120,
      },
      {
        columnKey: 'ratingClass',
        title: t('internal.class'),
        filter: filterTypes.BETWEEN_NUMBERS,
        minWidth: 120,
        wrapText: false,
      },
      {
        columnKey: 'rate',
        title: t('internal.rate'),
        alignment: TextAlign.End,
        filter: filterTypes.BETWEEN_NUMBERS,
        minWidth: 120,
        wrapText: false,
      },
      {
        columnKey: 'letterGrade',
        title: t('internal.letter-grade'),
        filter: filterTypes.CONTAINS,
        minWidth: 120,
      },
      {
        columnKey: 'overwritten',
        title: t('internal.overwritten'),
        filter: filterTypes.OF_ENUM_TYPE,
        additionalFilterOptions: {
          enumValues: [true, false].map((value) =>
            translationWithoutPrefix(`${formatBoolean(value)}`),
          ),
        },
        minWidth: 120,
      },
      {
        columnKey: 'validFrom',
        title: t('internal.valid-from'),
        alignment: TextAlign.End,
        filter: filterTypes.BETWEEN_DATES,
        minWidth: 120,
      },
      {
        columnKey: 'validTo',
        title: t('internal.valid-to'),
        alignment: TextAlign.End,
        filter: filters.BETWEEN_DATES,
        minWidth: 120,
      },
      {
        columnKey: 'ratingInherited',
        title: t('internal.ratingInherited'),
        filter: filterTypes.OF_ENUM_TYPE,
        alignment: TextAlign.End,
        additionalFilterOptions: {
          enumValues: ['X', ''].map((value) => formatToBooleanValues(value)),
        },
        minWidth: 120,
      },
      {
        columnKey: 'ratingInheritedFrom',
        title: t('internal.ratingInheritedFrom'),
        filter: filterTypes.CONTAINS,
        alignment: TextAlign.End,
        minWidth: 120,
      },
    ],
    [filters.BETWEEN_DATES, formatBoolean, formatToBooleanValues, t, translationWithoutPrefix],
  )

  return useMemo(() => {
    let minWidthSum = 0
    return columnDefinitions
      .map((column) => {
        // we still add each column's minWidth to the sum,
        // because we need the overall width relative to the table width for the breakpoint
        minWidthSum += (column.minWidth ?? 0) + UI5_TABLE_CELL_PADDING
        return {
          ...column,
          demandPopin: true,
          popinText: column?.title,
          minWidth: minWidthSum,
        }
      })
      .concat({
        title: '',
        columnKey: 'buttons',
        alignment: FlexBoxJustifyContent.End,
        sortingDisabled: true,
      })
  }, [columnDefinitions])
}

export default useBusinessPartnerInternalRatingsColumns
