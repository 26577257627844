import { forwardRef, useContext } from 'react'
import DealLiveVersionMessageStrip from 'components/domains/deals/deal-adjustment/DealLiveVersionMessageStrip'
import styles from 'components/domains/deals/deal-adjustment/DealMessagesHeader.module.css'
import DealWorkingVersionMessageStrip from 'components/domains/deals/deal-adjustment/DealWorkingVersionMessageStrip'
import DealReservationWarningSection from 'components/domains/deals/limit-check/reservations/DealReservationWarningSection'
import { DealContext } from 'routes/deals/DealContext'

const DealMessagesHeader = forwardRef((props, ref) => {
  const { containerClass, ...restProps } = props
  const { warningMessages } = useContext(DealContext)

  return (
    <div
      id={'deal-messages-header'}
      ref={ref}
      className={`${styles.header} ${containerClass ?? ''}`}
    >
      <DealWorkingVersionMessageStrip {...restProps} />
      <DealLiveVersionMessageStrip {...restProps} />
      <DealReservationWarningSection messages={warningMessages} />
    </div>
  )
})

DealMessagesHeader.displayName = 'DealMessagesHeader'
DealMessagesHeader.propTypes = DealWorkingVersionMessageStrip.propTypes

export default DealMessagesHeader
