import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/AnalyticalTableScalingWrapper.module.css'

const UI5_SMALL_SPACE = 32
const PDF_VIEW_WIDTH = 900

/**
 * Background for using zoom instead of the better supported transform scale:
 *  Not an ideal solution, as zoom works fine in Chrome,
 *  but is neither supported in Firefox nor an official CSS rule.
 *  However, transform-scale does not respect the already rendered table DOM, which causes problems with the analytical table,
 *  which uses absolute positioning.
 *
 * @param isPdfView
 * @param tableWidth - the DOM width of the table, usually acquired using the tableRef's totalColumnsWidth prop
 * @param tableMarginOffset - this offset value is added to the calculation of the zoom
 * @param customZoom - overwrite the width based zoom calculation with a custom value
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const AnalyticalTableScalingWrapper = ({
  isPdfView,
  tableWidth,
  tableMarginOffset = UI5_SMALL_SPACE,
  customZoom = undefined,
  children,
}) => {
  const tableScalingRef = useRef(null)
  const [tableZoom, setTableZoom] = useState(1)

  useEffect(() => {
    if (isPdfView) {
      const parentRefCurrent = tableScalingRef?.current
      const tableStyles = window.getComputedStyle(parentRefCurrent?.childNodes[0])
      const tableMargin =
        parseInt(tableStyles.getPropertyValue('margin-right'), 10) +
        parseInt(tableStyles.getPropertyValue('margin-left'), 10)

      const tableScalingWidth =
        tableScalingRef?.current?.getBoundingClientRect().width || PDF_VIEW_WIDTH - UI5_SMALL_SPACE
      const tableMarginWidth = tableMarginOffset ?? (isNaN(tableMargin) ? 0 : tableMargin)

      const zoom = customZoom ?? (tableScalingWidth / (tableWidth + tableMarginWidth) || 1)
      setTableZoom(Math.min(zoom, 1))
    }
  }, [
    isPdfView,
    tableMarginOffset,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    tableScalingRef?.current?.getBoundingClientRect().width,
    tableWidth,
    customZoom,
  ])

  if (isPdfView) {
    return (
      <div className={'tableScalingWrapperRef'} ref={tableScalingRef}>
        <div
          data-testid="tableScalingWrapper"
          className={styles.tableScalingWrapper}
          style={{ zoom: tableZoom }}
        >
          {children}
        </div>
      </div>
    )
  }
  return children
}

AnalyticalTableScalingWrapper.propTypes = {
  isPdfView: PropTypes.bool.isRequired,
  tableWidth: PropTypes.number,
  tableMarginOffset: PropTypes.number,
  customZoom: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default AnalyticalTableScalingWrapper
