import { Select, Option, Text } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/PropertyPortfolioOverviewPieChartsCard.module.css'
import CWPLayout from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/ui/CWPLayout'
import DonutChart from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/ui/DonutChart'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/ui/EmptyCardContent'

export const hasOnlyZeroValues = (data) =>
  (data ?? []).reduce((currentSum, { value }) => currentSum + value, 0) === 0

const PropertyPieChart = ({
  getNameFromKpi,
  rentRollKpis,
  valuations,
  useColors,
  colorProvider: ColorProvider,
  charts,
  granularities,
  activeGranularity,
  setChartOptions,
  tCharts,
  isPdfView = false,
}) => {
  const [activeChart, setActiveChart] = useState(() => Object.keys(charts)[0])

  useEffect(() => {
    Object.keys(charts) && !charts[activeChart] && setActiveChart(Object.keys(charts)[0])
  }, [activeChart, charts])

  const handleSelectChartChange = useCallback((e) => {
    setActiveChart(e.detail.selectedOption.value)
  }, [])

  const handleSelectGranularityChange = useCallback(
    (e) => {
      setChartOptions(e.detail.selectedOption.value)
    },
    [setChartOptions],
  )

  return (
    <>
      {!isPdfView &&
        (granularities ? (
          <CWPLayout sixColumns className={styles.noBottomMargin}>
            <CWPLayout.Half>
              <Select onChange={handleSelectGranularityChange}>
                {Object.keys(granularities).map((granularity) => (
                  <Option
                    key={granularity}
                    value={granularity}
                    selected={activeGranularity === granularity}
                  >
                    {tCharts(granularity)}
                  </Option>
                ))}
              </Select>
            </CWPLayout.Half>
            <CWPLayout.Half>
              <Select onChange={handleSelectChartChange}>
                {Object.keys(charts).map((chartName) => (
                  <Option key={chartName} value={chartName} selected={activeChart === chartName}>
                    {tCharts(chartName)}
                  </Option>
                ))}
              </Select>
            </CWPLayout.Half>
          </CWPLayout>
        ) : (
          <Select onChange={handleSelectChartChange} className={styles.select}>
            {Object.keys(charts).map((chartName) => (
              <Option key={chartName} value={chartName} selected={activeChart === chartName}>
                {tCharts(chartName)}
              </Option>
            ))}
          </Select>
        ))}

      {Object.entries(charts).map(
        ([chartName, ChartDataProvider]) =>
          //when isPdfView all charts should be displayed
          (isPdfView || (!isPdfView && chartName === activeChart)) && (
            <div key={chartName}>
              {isPdfView && <Text>{tCharts(chartName)}</Text>}
              <ColorProvider triggerResetColors={activeGranularity}>
                <ChartDataProvider
                  rentRollKpis={rentRollKpis}
                  valuations={valuations}
                  getNameFromKpi={getNameFromKpi}
                  useColors={useColors}
                >
                  {(data, tooltipValueFormatter) => {
                    if (isEmpty(data)) {
                      // no API data present at all => show generic empty message
                      return (
                        <EmptyCardContent
                          title={tCharts('empty.title')}
                          subtitle={tCharts('empty.subtitle')}
                        />
                      )
                    }

                    if (hasOnlyZeroValues(data)) {
                      // API data present, but all values are 0 => show chart specific empty message
                      return (
                        <EmptyCardContent
                          title={''}
                          subtitle={tCharts(`${chartName}.empty-message`)}
                        />
                      )
                    }

                    return <DonutChart data={data} tooltipValueFormatter={tooltipValueFormatter} />
                  }}
                </ChartDataProvider>
              </ColorProvider>
            </div>
          ),
      )}
    </>
  )
}

PropertyPieChart.propTypes = {
  getNameFromKpi: PropTypes.func.isRequired,
  rentRollKpis: PropTypes.array.isRequired,
  valuations: PropTypes.array.isRequired,
  useColors: PropTypes.func.isRequired,
  colorProvider: PropTypes.func.isRequired,
  charts: PropTypes.object.isRequired,
  granularities: PropTypes.object,
  activeGranularity: PropTypes.string,
  setChartOptions: PropTypes.func,
  tCharts: PropTypes.func.isRequired,
  isPdfView: PropTypes.bool,
}

export default PropertyPieChart
