// allowed input keys include thousand separator, decimal separator and shorthands for thousand (k) million (m) and billion (b)
const allowedInputKeys = ['-', '.', ',', 'k', 'm', 'b']

/**
 * Prevents input of non-allowed keys in an event.
 *
 * @param {Object} event - The event object from a keydown or keypress event.
 * @param {string} event.key - The key property from the event object.
 * @param {boolean} event.metaKey - The metaKey property from the event object.
 * @param {boolean} event.ctrlKey - The ctrlKey property from the event object.
 * @returns {void} If the key is not allowed, it prevents the default action of the event.
 */
export const isInputAllowed = (event, separators) => {
  const { key, target, metaKey, ctrlKey } = event
  const { value } = target
  const selectionStart = event?.target?.nativeInput?.selectionStart
  const selectionEnd = event?.target?.nativeInput?.selectionEnd

  const keyIsShortHand = key.length === 1 && /[bmkBMK]/.test(key)

  // prevent input of thousand separators
  if (key === separators.thousandsSeparator) {
    return false
  }

  // allow input of decimal separator if the cursor is at the beginning of the input
  if (
    key === separators.decimalSeparator &&
    selectionStart === 0 &&
    !value.includes(separators.decimalSeparator)
  ) {
    return true
  }

  // if decimal separator already exists, prevent input of decimal separator
  if (key === separators.decimalSeparator && value.includes(separators.decimalSeparator)) {
    return false
  }

  // disallow shorthands if the cursor is at the beginning of the input
  if (keyIsShortHand && selectionStart === 0) {
    return false
  }

  // disallow shorthands if its not at the end of the input
  if (keyIsShortHand && selectionStart !== value.length) {
    return false
  }

  // disallow multiple shorthands
  if (keyIsShortHand && value.includes(key)) {
    return false
  }

  // disallow - sign if it is not the first character or if there is already a - sign
  if (key === '-' && (selectionStart !== 0 || value.includes('-'))) {
    return false
  }

  // disallow separators if the cursor is at the beginning of the input
  if (
    [separators.thousandsSeparator, separators.decimalSeparator].includes(key) &&
    selectionStart === 0
  ) {
    return false
  }

  // Check if the key pressed is Backspace and if the selection range is around a comma or period
  if (
    selectionStart === selectionEnd &&
    key === 'Backspace' &&
    value[selectionStart - 1] === separators.thousandsSeparator
  ) {
    return false
  }

  // Check if the metaKey or ctrlKey is pressed
  if (metaKey || ctrlKey) {
    return true
  }

  return !(
    (!isFinite(event.key) && !(event.key.length > 1) && !allowedInputKeys.includes(event.key)) ||
    event.key === ' '
  )
}
