import 'components/ui/illustrated-message/CenteredIllustratedMessage.css'
import { IllustratedMessage } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import '@ui5/webcomponents-icons/dist/AllIcons.js'
import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js'

const CenteredIllustratedMessage = ({ titleText, subtitleText, name, size }) => (
  <div className="centered-illustrated-message">
    <IllustratedMessage name={name} size={size} titleText={titleText} subtitleText={subtitleText} />
  </div>
)

CenteredIllustratedMessage.propTypes = {
  titleText: PropTypes.string.isRequired,
  subtitleText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
}

export default CenteredIllustratedMessage
