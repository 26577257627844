import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MIN_RESERVATION_DURATION_IN_MONTHS,
  DEFAULT_END_TIMESTAMP_HOUR,
  DEFAULT_END_TIMESTAMP_MINUTE,
  DEFAULT_RESERVATION_STATUS,
} from 'components/domains/deals/limit-check/reservations/constants'
import useCreateReservation from 'hooks/services/deals/reservations/useCreateReservation'
import useDeleteReservation from 'hooks/services/deals/reservations/useDeleteReservation'
import { getReservationsKey } from 'hooks/services/deals/reservations/useReservations'
import useExternalCreateReservationTrigger from 'hooks/services/pbb-custom-service/limit-management/useExternalCreateReservationTrigger'
import useExternalDeleteReservationsTrigger from 'hooks/services/pbb-custom-service/limit-management/useExternalDeleteReservationsTrigger'
import useExternalLoadConsolidationPointsManualReservationTrigger from 'hooks/services/pbb-custom-service/limit-management/useExternalLoadConsolidationPointsManualReservationTrigger'
import useExternalSynchronizeReservationsTrigger from 'hooks/services/pbb-custom-service/limit-management/useExternalSynchronizeReservationsTrigger'
import { useErrorMessageBoxWithExpandableDetails } from 'hooks/services/useErrorMessageBoxWithExpandableDetails'

/**
 * Hook contains all actions which will be triggered in Deal Reservations Card
 *
 * @returns {{isLoadingExternalSynchronization: boolean, isValidReservation: boolean,
 *  validateNewReservationField: function, getNewReservationValue: function, onCancel: function, onSave: function, onDelete: function, onSynchronize: function, onChange: function }}
 *
 */

const getInitialValues = () => ({
  endTimestamp: DateTime.now()
    .plus({ months: MIN_RESERVATION_DURATION_IN_MONTHS })
    .set({
      hour: DEFAULT_END_TIMESTAMP_HOUR,
      minute: DEFAULT_END_TIMESTAMP_MINUTE,
    })
    .toJSDate()
    .toISOString(),
  startTimestamp: DateTime.now().toJSDate().toISOString(),
  status: DEFAULT_RESERVATION_STATUS,
})

export const useDealReservationCardActions = ({ dealUuid }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.limit-check.reservations',
  })
  const { t: tNoPrefix } = useTranslation()

  const [newReservation, setNewReservation] = useState(getInitialValues())
  const [reservationUuid, setReservationUuid] = useState()
  const [isPreDealLimitCheckFinished, setIsPreDealLimitCheckFinished] = useState(false)
  const [errorSummary, setErrorSummary] = useState()
  const { isErrorMessageOpen, onErrorMessageBoxClose, errorDetails, setError } =
    useErrorMessageBoxWithExpandableDetails()

  const resetReservationState = () => setNewReservation(getInitialValues())
  const onChange = (field, value) =>
    setNewReservation((prevState) => ({
      ...prevState,
      [field]: value,
    }))

  const validateNewReservationField = useCallback(
    (field) => !!newReservation[field],
    [newReservation],
  )
  const getNewReservationValue = useCallback((field) => newReservation[field], [newReservation])

  /**
   * Indicates if all reservation fields are valid, so new reservation request can be formed and sent via create reservation API
   */
  const isValidReservation = useMemo(
    () =>
      [
        'externalProductTypeCode',
        'externalMainPropertyTypeCode',
        'countryCode',
        'number',
        'currencyCode',
        'startTimestamp',
        'endTimestamp',
      ].every((field) => validateNewReservationField(field)),
    [validateNewReservationField],
  )

  const showToast = Modals.useShowToast()

  const queryClient = useQueryClient()

  const onSuccess = (message) => () => {
    message &&
      showToast({
        children: message,
      })
    queryClient.invalidateQueries(getReservationsKey(dealUuid))
  }

  const onError = (message) => (error) => {
    setError(error)
    setErrorSummary(message)
  }

  /**
   * {{externalCreateReservationTrigger}}: Mutate action which will trigger creation of reservation in the external system
   */
  const { mutate: externalCreateReservationTrigger } = useExternalCreateReservationTrigger({
    onSuccess: onSuccess(t('create-reservation.success')),
    onError: onError(t('create-reservation.error')),
  })

  /**
   * {{externalDeleteReservationsTrigger}}: Mutate action which will trigger deletion of reservations in the external system
   */
  const { mutate: externalDeleteReservationsTrigger } = useExternalDeleteReservationsTrigger({
    onSuccess: onSuccess(t('delete-reservation.success')),
    onError: onError(t('delete-reservation.error')),
  })

  /**
   * {{externalSynchronizeReservationsTrigger}}:  Mutate action which will trigger synchronization of reservations in the external system
   */
  const {
    mutate: externalSynchronizeReservationsTrigger,
    isLoading: isLoadingExternalSynchronization,
  } = useExternalSynchronizeReservationsTrigger({
    onSuccess: onSuccess(t('synchronize-reservations.success')),
    onError: onError(t('synchronize-reservations.error')),
  })

  const { mutate: deleteReservation } = useDeleteReservation({
    onSuccess: onSuccess(),
    onError: onError(t('delete-reservation.error')),
  })

  const onConfirmReservation = () => externalCreateReservationTrigger({ dealUuid, reservationUuid })
  const onCancelReservation = () => deleteReservation({ dealUuid, reservationUuid })
  const onClose = () => setIsPreDealLimitCheckFinished(false)

  /**
   * {{externalLoadConsolidationPointsManualReservationTrigger}}: Mutate action which will trigger loading of consolidation points from the external system
   *
   * After action is successfull, decision dialog will appear where user is prompted to confirm and trigger creation of reservation in external system,
   * or cancel operation and delete newly created reservation from CWP system
   */
  const { mutate: externalLoadConsolidationPointsManualReservationTrigger } =
    useExternalLoadConsolidationPointsManualReservationTrigger({
      onSuccess: async () => {
        await queryClient.invalidateQueries(getReservationsKey(dealUuid))
        setIsPreDealLimitCheckFinished(true)
      },
      onError: onError(
        tNoPrefix('components.deals.limit-check.dialog.load-consolidation-points.error'),
      ),
      onSettled: resetReservationState,
    })

  /**
   * {{createReservation}}: Mutate action which will create reservation in CWP system
   *
   * After action is successfull, loading consolidation points for manually created reservation will be triggered in external system
   */
  const { mutate: createReservation } = useCreateReservation({
    onSuccess: ({ data: { creditReservations = [] } = {} } = {}) => {
      const creditReservationUuid = creditReservations[0]?.creditReservationUuid
      setReservationUuid(creditReservationUuid)

      creditReservationUuid &&
        externalLoadConsolidationPointsManualReservationTrigger({
          dealUuid,
          reservationUuid: creditReservationUuid,
        })
    },
    onError: onError(t('create-reservation.error')),
    onSettled: resetReservationState,
  })

  /** event handlers for Request Reservation card */
  const onSave = useCallback(() => {
    createReservation({ dealUuid, ...newReservation })
  }, [createReservation, dealUuid, newReservation])

  const onDelete = useCallback(
    (reservationId) =>
      externalDeleteReservationsTrigger({ dealUuid, reservationUuids: [reservationId] }),
    [externalDeleteReservationsTrigger, dealUuid],
  )

  const onSynchronize = useCallback(
    (reservationUuids) => externalSynchronizeReservationsTrigger({ dealUuid, reservationUuids }),
    [dealUuid, externalSynchronizeReservationsTrigger],
  )

  const onCancel = () => resetReservationState()

  return {
    isLoadingExternalSynchronization,
    isValidReservation,
    isPreDealLimitCheckFinished,
    reservationUuid,
    getInitialValues,
    validateNewReservationField,
    getNewReservationValue,
    onChange,
    onCancel,
    onClose,
    onCancelReservation,
    onConfirmReservation,
    onDelete,
    onSave,
    onSynchronize,
    error: {
      isErrorMessageOpen,
      onErrorMessageBoxClose,
      errorDetails,
      errorSummary,
    },
  }
}
