import { Icon } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/deals/deal-cashflow/input-parameters/DealCashflowInputParametersTableButtons.module.css'

const dealCashflowInputParametersTableButtonsProps = {
  className: PropTypes.string,
  parameter: PropTypes.object,
  cell: PropTypes.object.isRequired,
  setEditData: PropTypes.func.isRequired,
  showAdd: PropTypes.bool,
}

const DealCashflowInputParametersTableButtons = ({
  className = '',
  cell,
  parameter,
  showAdd = false,
  setEditData = () => {},
}) => {
  const { invalid, coId } = cell?.row?.original ?? {}
  const onAddParameter = () => setEditData({ [coId]: { parameters: { new: {} } } }, true)

  const onDeleteParameter = () =>
    setEditData(
      {
        [coId]: { parameters: { [parameter?.ccpdId]: { isRemoved: true } } },
      },
      true,
    )

  return (
    <div className={`${styles.buttons} ${className}`}>
      {!invalid && showAdd && (
        <Icon
          interactive={!parameter?.isSaveLoading}
          className={styles.addButton}
          name="add"
          onClick={onAddParameter}
        />
      )}
      {parameter && (
        <Icon
          interactive={!parameter?.isSaveLoading}
          className={styles.deleteButton}
          name="delete"
          onClick={onDeleteParameter}
        />
      )}
    </div>
  )
}

DealCashflowInputParametersTableButtons.propTypes = dealCashflowInputParametersTableButtonsProps

export default DealCashflowInputParametersTableButtons
