import isNil from 'lodash.isnil'
import mapArrearApprovalsData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/shared/automatic-checksheet/mappers/mapArrearApprovalsData'
import mapAuthorityLevelData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/shared/automatic-checksheet/mappers/mapAuthorityLevelData'
import mapCustomerSpecificAttributesData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/shared/automatic-checksheet/mappers/mapCustomerSpecificAttributesData'
import mapDealCollateralInformationData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/shared/automatic-checksheet/mappers/mapDealCollateralInformationData'
import mapRegulatoryInformationData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/shared/automatic-checksheet/mappers/mapRegulatoryInformationData'

const mapAnnualReviewAutomaticCheckSheetData = ({
  borrowersData,
  multipleRegulatoryInformationData,
  multipleArrearApprovalsData,
  authorityLevelData,
  dealIdsAndUuidsByBorrowerId,
  multipleDealCollateralInformationData,
  multipleCustomerSpecificAttributes,
  dealIdTrancheIdPairsByBusinessPartner,
}) =>
  borrowersData.map(({ borrowerId, borrowerName }) => {
    const {
      hasShadowBankingEntity,
      hasCompanyClassificationEqual01,
      hasLeveragedTransaction,
      amlRiskClassification,
    } = mapRegulatoryInformationData({
      borrowerId,
      multipleRegulatoryInformationData,
    })

    const { hasSpecializedLending, hasSecuritization } = mapCustomerSpecificAttributesData({
      borrowerId,
      multipleCustomerSpecificAttributes,
      dealIdTrancheIdPairsByBusinessPartner,
    })

    const { isSomeStandardPackageFalse } = mapDealCollateralInformationData({
      borrowerId,
      dealIdsAndUuidsByBorrowerId,
      multipleDealCollateralInformationData,
    })

    const { isOrgankredit, largeExposureRelevant } = mapAuthorityLevelData({
      authorityLevelData,
    })

    const { hasEventNotOlderThanTwelveMonths } = mapArrearApprovalsData({
      borrowerId,
      multipleArrearApprovalsData,
    })

    return {
      borrowerId,
      borrowerName,
      answers: {
        // eslint-disable-next-line no-nested-ternary
        Q1: isNil(hasShadowBankingEntity)
          ? 'data-not-available'
          : hasShadowBankingEntity
          ? 'yes'
          : 'no',

        // eslint-disable-next-line no-nested-ternary
        Q2: isNil(hasCompanyClassificationEqual01)
          ? 'data-not-available'
          : hasCompanyClassificationEqual01
          ? 'yes'
          : 'no',

        Q3: hasSpecializedLending ? 'yes' : 'no',

        // eslint-disable-next-line no-nested-ternary
        Q4: isNil(hasLeveragedTransaction)
          ? 'data-not-available'
          : hasLeveragedTransaction
          ? 'yes'
          : 'no',

        Q5: hasSecuritization ? 'yes' : 'no',

        Q6: amlRiskClassification,

        Q7: isOrgankredit ? 'yes' : 'no',

        Q7b: largeExposureRelevant ? 'yes' : 'no',

        // eslint-disable-next-line no-nested-ternary
        Q8: isNil(hasEventNotOlderThanTwelveMonths)
          ? 'n/a'
          : hasEventNotOlderThanTwelveMonths
          ? 'yes'
          : 'no',

        // eslint-disable-next-line no-nested-ternary
        Q9: isNil(isSomeStandardPackageFalse) ? 'n/a' : isSomeStandardPackageFalse ? 'no' : 'yes',
      },
    }
  })

export default mapAnnualReviewAutomaticCheckSheetData
