import { IllustratedMessage, Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import KpiChart from 'components/ui/charts/kpi-chart/KpiChart'

const defaultInterval = 120

const filterKpiDataByMonths = (kpiData, months) =>
  kpiData.filter(
    (item) => new Date(item?.validity_date) >= new Date().setMonth(new Date().getMonth() - months),
  )

const AugmentedKpiChart = ({
  kpiName,
  kpiData,
  kpiUnit,
  threshold,
  thresholdLabel,
  baselineDate,
  emptyTitle,
  emptySubtitle,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.ui.charts.kpi-chart' })
  const [selectedValueOption, setSelectedValueOption] = useState(defaultInterval)
  const [dataset, setDataset] = useState(filterKpiDataByMonths(kpiData, defaultInterval))

  const intervals = [
    { text: `3 ${t('range.months')}`, value: 3 },
    { text: `1 ${t('range.year')}`, value: 12 },
    { text: `3 ${t('range.years')}`, value: 36 },
    { text: `5 ${t('range.years')}`, value: 60 },
    { text: `10 ${t('range.years')}`, value: 120 },
  ]

  const handleIntervalSelectionChange = ({
    detail: {
      selectedOption: { value },
    },
  }) => {
    setSelectedValueOption(Number(value))
    setDataset(filterKpiDataByMonths(kpiData, value))
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Select
        onChange={handleIntervalSelectionChange}
        style={{ paddingLeft: '16px', width: '148px', height: '26px' }}
      >
        {intervals.map(({ value, text }) => (
          <Option key={value} selected={value === selectedValueOption} value={value}>
            {text}
          </Option>
        ))}
      </Select>
      {dataset.length === 0 ? (
        <IllustratedMessage name="NoData" titleText={emptyTitle} subtitleText={emptySubtitle} />
      ) : (
        <KpiChart
          kpiName={kpiName}
          kpiData={dataset}
          kpiUnit={kpiUnit}
          augmented={true}
          threshold={threshold}
          thresholdLabel={thresholdLabel}
          baselineDate={baselineDate}
          intervalMonths={selectedValueOption}
        />
      )}
    </div>
  )
}

AugmentedKpiChart.propTypes = {
  kpiName: PropTypes.string.isRequired,
  kpiData: PropTypes.arrayOf(
    PropTypes.shape({
      validity_date: PropTypes.string.isRequired,
      receipt_date: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  kpiUnit: PropTypes.string.isRequired,
  threshold: PropTypes.number,
  thresholdLabel: PropTypes.string,
  baselineDate: PropTypes.string,
  emptyTitle: PropTypes.string.isRequired,
  emptySubtitle: PropTypes.string,
}

export default AugmentedKpiChart
