import isEmpty from 'lodash.isempty'

const valuationValidFromChanged = (state, { payload: { validFrom: newValidFrom } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }
  state.editedRow.currentValues.validFrom = newValidFrom
  state.editedRow.touchedFields.validFrom = true

  if (newValidFrom === state.editedRow.initialValues.validFrom) {
    delete state.editedRow.changedFields.validFrom
    return
  }

  state.editedRow.changedFields.validFrom = true
}

export default valuationValidFromChanged
