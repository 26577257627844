import { z } from 'zod'

export const nonRefCreditDecisionManualQuestionnaireSchema = z.object({
  Q01: z.string(),
  Q02: z.string(),
  Q03: z.string(),
  Q04: z.string(),
  Q05: z.string(),
  Q06: z.string(),
  Q07: z.string(),
  Q08: z.string(),
  Q09: z.string(),
  Q10: z.string(),
  Q11: z.string(),
  Q12: z.string(),
  Q13: z.string(),
  Q14: z.string(),
  Comment: z.string().nullable(),
})
