import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  ObjectStatus,
  TableRowType,
} from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getObjectStatusForTaskStatus } from 'api/events/status'
import BusinessObjectLink from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import styles from 'components/domains/business-events-and-tasks/tasks/tasks-summary/TasksSummaryTable.module.css'
import Entity from 'components/ui/data/Entity'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import paths from 'routes/paths'

const TasksSummaryTable = ({ tasks, shownColumns, additionalActions }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.tasks.tasks-summary.table',
  })

  const { t: tWithoutPrefix } = useTranslation()
  const { format } = useShortDateFormatter()

  const possibleTasksColumns = useMemo(
    () => ({
      name: {
        title: t('name'),
        columnKey: 'name',
      },
      businessEvent: {
        title: t('business-event'),
        columnKey: 'businessEvent',
      },
      businessObjectType: {
        title: t('business-object-type'),
        columnKey: 'businessObjectType',
      },
      businessObject: {
        title: t('business-object-name'),
        columnKey: 'businessObject',
      },
      dueDate: {
        title: t('due-date'),
        columnKey: 'dueDate',
      },
      status: {
        title: t('status'),
        columnKey: 'status',
      },
    }),
    [t],
  )

  const tasksColumns = useMemo(
    () => shownColumns.map((columnKey) => possibleTasksColumns[columnKey]),
    [shownColumns, possibleTasksColumns],
  )

  const displayStatus = useCallback(
    (status) => {
      const { translationKey: statusTranslationKey, objectStatus } =
        getObjectStatusForTaskStatus(status)
      return (
        <ObjectStatus inverted state={objectStatus}>
          {tWithoutPrefix(statusTranslationKey)}
        </ObjectStatus>
      )
    },
    [tWithoutPrefix],
  )

  const tableData = useMemo(
    () =>
      tasks.map(
        ({
          id: taskId,
          status,
          event: {
            info: { name: eventName },
            displayId: eventDisplayId,
            id: eventId,
            entityRef: { entityType, entityId },
          },
          info: { name, originalDueDate, currentDueDate, isMandatory },
        }) => ({
          rowKey: `tasks-summary-table-${taskId}`,
          rowProperties: {
            type: TableRowType.Active,
            'data-task-id': taskId,
            'data-event-id': eventId,
          },
          name: {
            cellComponent: (
              <FlexBox direction={FlexBoxDirection.Column}>
                <Entity
                  name={name}
                  link={`/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}/tasks/${taskId}`}
                />
                {isMandatory && <Label className={styles.mandatoryLabel}>{t('mandatory')}</Label>}
              </FlexBox>
            ),
          },
          businessEvent: {
            cellComponent: (
              <Entity
                name={eventName}
                id={eventDisplayId}
                link={`/${paths.businessEventsAndTasks}/business-events/${eventId}`}
              />
            ),
          },
          businessObjectType: {
            cellComponent: <Label>{tWithoutPrefix(`events.entity-type.${entityType}`)}</Label>,
          },
          businessObject: {
            cellComponent: <BusinessObjectLink entityId={entityId} entityType={entityType} />,
          },
          dueDate: {
            cellComponent: (
              <FlexBox direction={FlexBoxDirection.Column}>
                {currentDueDate ? (
                  <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween}>
                    <Label>{t('due-date.current')}</Label>
                    <Label>{`${format(currentDueDate)}`}</Label>
                  </FlexBox>
                ) : (
                  <Label>{'-'}</Label>
                )}
                {originalDueDate ? (
                  <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween}>
                    <Label>{t('due-date.original')}</Label>
                    <Label>{`${format(originalDueDate)}`}</Label>
                  </FlexBox>
                ) : (
                  <Label>{'-'}</Label>
                )}
              </FlexBox>
            ),
          },
          status: {
            cellComponent: <Label>{displayStatus(status)}</Label>,
          },
        }),
      ),
    [tasks, t, format, displayStatus, tWithoutPrefix],
  )

  return (
    <div className={styles.tableWrapper}>
      <SortedTable
        columnDefinitions={tasksColumns}
        tableData={tableData}
        toolbarConfig={{
          title: t('header.title'),
          additionalActions: [additionalActions],
          showColumnSelection: false,
        }}
        additionalTableProperties={{
          className: styles.tableProperties,
        }}
        noDataText={t('no-data-text')}
      />
    </div>
  )
}

TasksSummaryTable.propTypes = {
  tasks: PropType.array.isRequired,
  shownColumns: PropType.arrayOf(
    PropType.oneOf([
      'name',
      'businessEvent',
      'businessObject',
      'businessObjectType',
      'dueDate',
      'status',
    ]),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  additionalActions: PropType.object,
}

export default TasksSummaryTable
