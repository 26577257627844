import PropTypes from 'prop-types'
import TableDescriptionSubcomponent from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/view/TableDescriptionSubcomponent'

const ConditionsTableDescriptionSubcomponent = ({ description }) => (
  <TableDescriptionSubcomponent description={description} />
)

ConditionsTableDescriptionSubcomponent.propTypes = {
  description: PropTypes.string,
}

export default ConditionsTableDescriptionSubcomponent
