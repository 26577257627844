import PropTypes from 'prop-types'
import useGccInvolvedPartiesAuthorityLevelCalculatorTableColumnDefinitions from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/authority-level-calculator/useGccInvolvedPartiesAuthorityLevelCalculatorTableColumnDefinitions'
import SharedAuthorityLevelCalculatorTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/authority-level-calculator/SharedAuthorityLevelCalculatorTile'

const GccInvolvedPartiesAuthorityLevelCalculatorTile = ({ tileId, showConfirmationForLinks }) => (
  <SharedAuthorityLevelCalculatorTile
    tileId={tileId}
    columnDefinitionsFunction={useGccInvolvedPartiesAuthorityLevelCalculatorTableColumnDefinitions}
    showConfirmationForLinks={showConfirmationForLinks}
  />
)

GccInvolvedPartiesAuthorityLevelCalculatorTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool,
}

export default GccInvolvedPartiesAuthorityLevelCalculatorTile
