import { useRequest } from 'hooks/services/baseService'

const usePropertyValuationReasons = () =>
  useRequest({
    path: `/properties/valuation-requests/reasons`,
    translated: true,
    useCache: true,
    keys: ['reasons'],
  })

export default usePropertyValuationReasons
