import conditionRowHasError from 'redux/slices/conditions/actions/condition-creation/conditionRowHasError'
import requirementRowHasError from 'redux/slices/conditions/actions/condition-creation/requirementRowHasError'

const filterRequirements = (selectedRows) => (currentCondition, conditionIndex) => {
  currentCondition.requirements = currentCondition.requirements.filter(
    (_requirement, requirementIndex) =>
      selectedRows[`${conditionIndex}.${requirementIndex}`] === undefined,
  )
}
const filterConditions = (selectedRows) => (_condition, conditionIndex) =>
  selectedRows[conditionIndex] === undefined

const calculateNewErrors = (state) => {
  state.conditions.forEach(({ name: conditionName, type, requirements }, conditionIndex) => {
    if (conditionName === '') {
      conditionRowHasError(state, {
        payload: { conditionIndex, field: 'name', error: { warning: false } },
      })
    }
    if (!type) {
      conditionRowHasError(state, {
        payload: { conditionIndex, field: 'type', error: { warning: false } },
      })
    }
    requirements.forEach(({ name: requirementName, documentType }, requirementIndex) => {
      if (requirementName === '') {
        requirementRowHasError(state, {
          payload: { conditionIndex, requirementIndex, field: 'name', error: { warning: false } },
        })
      }
      if (!documentType) {
        requirementRowHasError(state, {
          payload: {
            conditionIndex,
            requirementIndex,
            field: 'documentType',
            error: { warning: false },
          },
        })
      }
    })
  })
}

// Filter requirements first and afterwards deletion since otherwise the selected indexes
// might no longer match
const deleteSelectedRows = (state) => {
  if (Object.keys(state.selectedRows).length === 0) {
    return
  }
  state.conditions.forEach(filterRequirements(state.selectedRows))
  state.conditions = state.conditions.filter(filterConditions(state.selectedRows))
  state.selectedRows = {}
  state.errorRows = {}
  calculateNewErrors(state)
}

export default deleteSelectedRows
