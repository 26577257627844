export const TABLE_TEXT_DEFAULT_HEIGHT = 16
export const TABLE_TEXT_DEFAULT_MARGIN = 8
export const TABLE_CELL_DEFAULT_ITEMS_GAP = 8

const NUMBER_OF_LINES_HEADER = 2
const NUMBER_OF_LINES_ROW = 5
export const TABLE_HEADER_ROW_HEIGHT =
  TABLE_TEXT_DEFAULT_HEIGHT + TABLE_TEXT_DEFAULT_MARGIN * NUMBER_OF_LINES_HEADER

export const TABLE_ROW_HEIGHT =
  TABLE_TEXT_DEFAULT_HEIGHT * NUMBER_OF_LINES_ROW +
  TABLE_TEXT_DEFAULT_MARGIN * NUMBER_OF_LINES_ROW +
  TABLE_CELL_DEFAULT_ITEMS_GAP
