import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useAddEventWatcher = (options) => {
  const { post } = useAccessTokenRequest()
  return useMutation(async ({ eventId, userId }) => {
    const { data } = await post({
      path: `/events/${eventId}/watchers`,
      body: {
        user_id: userId,
      },
    })
    return data
  }, options)
}
export default useAddEventWatcher
