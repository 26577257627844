import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CreditAuthorityLimitOverview from 'components/domains/business-partners/tile/exposure/CreditAuthorityLimitOverview'
import RegularityExposureTile from 'components/domains/business-partners/tile/exposure/RegulatoryExposureTile'
import SettlementRiskOverview from 'components/domains/business-partners/tile/exposure/SettlementRiskOverview'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Section from 'components/ui/page/Section'
import useAuthorityLevelCalculator from 'hooks/services/business-partners/authority-level-calculator/useAuthorityLevelCalculator'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import styles from 'routes/business-partners/BusinessPartnerGroupExposures.module.css'
import GroupExposureBusinessPartnerTable from 'routes/business-partners/GroupExposureBusinessPartnerTable'

const BusinessPartnerGroupExposure = ({
  groupExposure,
  classTypes,
  businessPartnerTableData,
  internalName,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner.group-exposures' })
  const businessPartner = useContext(BusinessPartnerContext)

  const [jsonErrorResult, setJsonErrorResult] = useState(null)

  const {
    isLoading,
    isError,
    error,
    data: { totalValues } = {},
  } = useAuthorityLevelCalculator(businessPartner.id, businessPartner.id, internalName)

  //If there is an error, the body of the error response is extracted and parsed into a JSON object
  //This is done to display the error message in the UI
  useEffect(() => {
    const extractErrorMessage = async () => {
      if (error) {
        const result = await formatHookError(error)
        setJsonErrorResult(JSON.parse(result))
      }
    }

    extractErrorMessage()
  }, [error])

  // more convenient access for display names
  const riskFigureDisplayNamesMap = useMemo(
    () =>
      Object.entries(classTypes).reduce(
        (dictionary, [key, value]) => ({ ...dictionary, [key]: value.displayName }),
        {},
      ),
    [classTypes],
  )

  return (
    <>
      {!isEmpty(groupExposure) && !isEmpty(classTypes) && (
        <Section titleText={t('overview')}>
          <CWPLayout>
            <CreditAuthorityLimitOverview
              isLoading={isLoading}
              isError={isError}
              errorStatus={error?.response?.status}
              errorCode={jsonErrorResult?.errorDetails?.code}
              creditAuthorityLimitContribution={{
                value: totalValues?.totalCalculatedValues?.creditAuthorityLimitExisting?.amount,
                unit: totalValues?.totalCalculatedValues?.creditAuthorityLimitExisting?.currency,
                label: t('group-bp.contribution-to-credit-authority-limit-existing'),
              }}
              pipelineExposure={{
                value:
                  totalValues?.totalCalculatedValues?.creditAuthorityLimitPipelineLending?.amount,
                unit: totalValues?.totalCalculatedValues?.creditAuthorityLimitPipelineLending
                  ?.currency,
                label: t('group-bp.contribution-to-credit-authority-limit-pipeline-lending'),
              }}
              totalLabel={t('group-bp.total-label')}
            />
            <RegularityExposureTile
              largeExposureKeyDate={{
                value: groupExposure.arm?.keyDate,
                label: t('key-date-for', { label: classTypes.arm?.displayName }),
                unit: 'date',
              }}
              creditRiskMigrationKeyDate={{
                value: groupExposure.brm?.keyDate,
                label: t('key-date-for', { label: classTypes.brm?.displayName }),
                unit: 'date',
              }}
              exposureAtDefault={{
                ...groupExposure.ead,
                label: classTypes.ead?.displayName,
              }}
              thresholdForExposure10={{
                ...groupExposure.thresholdLe,
                label: classTypes.thresholdLe?.displayName,
              }}
              exposureBeforeCreditRiskMitigation={{
                ...groupExposure.brm,
                label: classTypes.brm?.displayName,
              }}
              maxLimitForExposure25={{
                ...groupExposure.maxLe,
                label: classTypes.maxLe?.displayName,
              }}
              exposureAfterCreditRiskMitigation={{
                ...groupExposure.arm,
                label: classTypes.arm?.displayName,
              }}
            />
            <SettlementRiskOverview
              settlementRisk={{
                ...groupExposure.sr,
                label: classTypes.sr?.displayName,
              }}
              settlementRiskLimit={{
                ...groupExposure.srl,
                label: classTypes.srl?.displayName,
              }}
            />
          </CWPLayout>
        </Section>
      )}
      {!isEmpty(businessPartnerTableData) && (
        <Section
          titleText={t('business-partners')}
          className={styles.groupExposureBusinessPartnerTable}
        >
          <GroupExposureBusinessPartnerTable
            tableData={businessPartnerTableData}
            riskFigureDisplayNamesMap={riskFigureDisplayNamesMap}
            groupExposure={groupExposure}
          />
        </Section>
      )}
    </>
  )
}

BusinessPartnerGroupExposure.propTypes = {
  groupExposure: PropTypes.shape({
    total: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string.isRequired,
    }),
    pipeline: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string.isRequired,
    }),
    existing: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string.isRequired,
    }),
    ead: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string.isRequired,
    }),
    brm: PropTypes.shape({
      keyDate: PropTypes.string,
    }),
    arm: PropTypes.shape({
      keyDate: PropTypes.string,
    }),
    thresholdLe: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string.isRequired,
    }),
    maxLe: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string.isRequired,
    }),
    sr: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string.isRequired,
    }),
    srl: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string.isRequired,
    }),
  }),
  businessPartnerTableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      isTotal: PropTypes.bool,
      isSectionHeader: PropTypes.bool,
      currentExposure: PropTypes.shape({
        value: PropTypes.number,
        unit: PropTypes.string,
        keyDate: PropTypes.string,
        version: PropTypes.string,
      }),
      grossFutureExposure: PropTypes.shape({
        value: PropTypes.number,
        unit: PropTypes.string,
        keyDate: PropTypes.string,
        version: PropTypes.string,
      }),
      grossFutureExposureLimit: PropTypes.shape({
        value: PropTypes.number,
        unit: PropTypes.string,
        keyDate: PropTypes.string,
        version: PropTypes.string,
      }),
      netFutureExposure: PropTypes.shape({
        value: PropTypes.number,
        unit: PropTypes.string,
        keyDate: PropTypes.string,
        version: PropTypes.string,
      }),
      settlementRisk: PropTypes.shape({
        value: PropTypes.number,
        unit: PropTypes.string,
        keyDate: PropTypes.string,
        version: PropTypes.string,
      }),
      settlementRiskLimit: PropTypes.shape({
        value: PropTypes.number,
        unit: PropTypes.string,
        keyDate: PropTypes.string,
        version: PropTypes.string,
      }),
    }),
  ),
  classTypes: PropTypes.objectOf(
    PropTypes.shape({
      displayName: PropTypes.string,
    }).isRequired,
  ),
  internalName: PropTypes.string.isRequired,
}

export default BusinessPartnerGroupExposure
