import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useEditMultiTilesMetaData = (mutationOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(async ({ eventId, metaData }) => {
    const { data } = await post({
      path: `/events/${eventId}/decision-paper/versions/current/tiles/meta-data`,
      body: {
        data: metaData,
      },
    })
    return data
  }, mutationOptions)
}
