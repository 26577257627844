import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-stage/voters-table/table-cells/VotersTableDepartmentCell.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'

const VotersTableDepartmentCell = ({ userId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.voters-table.cells.approver',
  })
  const { isLoading, isError, data: approverData } = useStaffMemberById(userId)

  const renderContent = useCallback(() => <Label>{approverData.team}</Label>, [approverData?.team])

  const errorMessage = <Label className={styles.errorLabel}>{t('loading.error.description')}</Label>

  if (!userId) return

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderContent}
      error={errorMessage}
    />
  )
}

VotersTableDepartmentCell.propTypes = {
  userId: PropTypes.string.isRequired,
}

export default VotersTableDepartmentCell
