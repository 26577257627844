import { createSlice } from '@reduxjs/toolkit'

// TODO: Research if this cant be generalized for multi-select-card

const initialState = {
  invalidEditStates: [],
}

export const interestConditionsTableSlice = createSlice({
  name: 'deals/financing/tranches/interest-conditions-table',
  initialState,
  reducers: {
    addInvalidEditState: (state, { payload }) => {
      if (
        !state.invalidEditStates.find(
          (item) => item.id === payload.id && item.attribute === payload.attribute,
        )
      ) {
        state.invalidEditStates.push(payload)
      }
    },
    removeInvalidEditState: (state, { payload }) => {
      state.invalidEditStates = state.invalidEditStates.filter(
        (item) => item.id !== payload.id || item.attribute !== payload.attribute,
      )
    },
    removeIdsFromInvalidEditStates: (state, { payload }) => {
      state.invalidEditStates = state.invalidEditStates.filter((item) => !payload.includes(item.id))
    },
    resetInvalidEditState: () => initialState,
  },
})

export const {
  addInvalidEditState,
  removeInvalidEditState,
  removeIdsFromInvalidEditStates,
  resetInvalidEditState,
} = interestConditionsTableSlice.actions

export default interestConditionsTableSlice.reducer
