import {
  CheckBox,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Icon,
  Input,
  Modals,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  GeolocationMessageStrip,
  LOAD_GEOCODE,
  NO_VERIFY,
  RELOAD_GEOCODE,
  SUCCESS,
} from 'components/domains/properties/general-information/address/GeolocationMessageStrip'
import { getGeoLocationOfCompleteAddress } from 'components/domains/util/AddressUtil'
import Card from 'components/ui/card/Card'
import DisplayAndEditCard from 'components/ui/card/DisplayAndEditCard'
import { hideOptions } from 'components/ui/card/DisplayCardView'
import EditCardItem from 'components/ui/card/EditCardItem'
import editComponentTypes from 'components/ui/card/editComponentTypes'
import ExistingPropertyMessageBox from 'components/ui/feedback/ExistingPropertyInfoMessage'
import AddressAutocompleteInput from 'components/ui/input/AddressAutocompleteInput'
import LeafletMapWithPolygonAndPropertyMarkers from 'components/ui/map/LeafletMapWithPolygonAndPropertyMarkers'
import 'components/ui/card/Card.css'
import 'components/domains/properties/PropertyCards.css'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import {
  DEFAULT_GEOLOCATION,
  useGeolocation,
} from 'hooks/services/properties/geolocation/useGeolocation'
import { useUpdateGeolocation } from 'hooks/services/properties/geolocation/useUpdateGeolocation'
import { useCountryCodes } from 'hooks/services/properties/useCountryCodes'
import { useProperties } from 'hooks/services/properties/useProperties'
import { useUpdatePropertyDetails } from 'hooks/services/properties/useUpdatePropertyDetails'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'
import { PropertyContext } from 'routes/properties/PropertyContext'
import {
  EXISTING_PROPERTY_INDICATOR,
  generatePropertyFilterObject,
  generatePropertyInfoMessage,
} from 'utils/propertyAddressCheck'
import { stringContainsNoSpecialCharacters } from 'utils/specialCharacters'

const PropertyAddressCard = ({ allowUserToEdit = true }) => {
  const { t } = useTranslation()
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()
  const {
    property: {
      id: propertyId,
      description: propertyName,
      type_name: propertyType,
      address: address,
      geo_location: geoLocationFromContext,
      uuid: propertyUuid,
      financing_status_code: propertyFinancingStatus,
      system_administrative_data: propertySystemAdministrativeData,
      change_request_exist_indicator: changeRequestExistIndicator,
      allowed_operations,
    },
  } = useContext(PropertyContext)

  const allowedOperations = allowed_operations?.allowed_operations ?? []
  const isAllowedPropertyGeoLocationEdit =
    allowUserToEdit &&
    !changeRequestExistIndicator &&
    allowedOperations.includes('PropertyGeoLocation_Update')
  const isAllowedPropertyMasterDataEdit =
    allowUserToEdit &&
    !changeRequestExistIndicator &&
    allowedOperations.includes('PropertyMasterData_Update')

  const [inManualMode, setInManualMode] = useState(false)

  const handleHookSuccess = () => {
    queryClient.invalidateQueries(['properties'])
    showToast({ children: t('toast.changes-saved') })
  }

  const updateProperty = useUpdatePropertyDetails({
    onSuccess: () => handleHookSuccess(),
  })
  const updateGeolocation = useUpdateGeolocation({
    onSuccess: () => handleHookSuccess(),
  })

  const {
    data: geolocationFromHook,
    isLoading: isLoadingGeolocation,
    isError: isErrorGeolocation,
  } = useGeolocation({ propertyUuid })

  const countryCodes = useCountryCodes()

  const position = {
    longitude: Number(geoLocationFromContext.longitude),
    latitude: Number(geoLocationFromContext.latitude),
  }

  const [addressFilter, setAddressFilter] = useState({})
  const [infoMessage, setInfoMessage] = useState(EXISTING_PROPERTY_INDICATOR.NONE)

  const isPropertiesRequestEnabled =
    !!addressFilter?.country && !!addressFilter?.zipCode && !!addressFilter?.city
  const { data: { properties = [] } = {}, isFetching: isPropertyCheckLoading } = useProperties({
    filter: { ...addressFilter },
    options: { enabled: isPropertiesRequestEnabled },
  })

  const areAddressFieldsUpdated = useMemo(
    () =>
      [addressFilter.country, addressFilter.zipCode, addressFilter.city, addressFilter.street].some(
        (element) => element !== undefined,
      ),
    [addressFilter],
  )

  const generateInfoMessage = useCallback(
    ({ zipCode, country, street, city, houseId }) => {
      setInfoMessage(
        generatePropertyInfoMessage(zipCode, country, street, city, houseId, properties),
      )
    },
    [properties],
  )

  const updateAddressFilter = useCallback(
    (addressFilterObject) => {
      setAddressFilter({
        ...addressFilterObject,
      })
    },
    [setAddressFilter],
  )

  const onAddressFieldUpdateHandler = (key, value, fieldValues, oldValues) => {
    if (key === 'address')
      updateAddressFilter(generatePropertyFilterObject('address', value, addressFilter))
    else {
      !_.isEqual(fieldValues, oldValues) &&
        updateAddressFilter(generatePropertyFilterObject('address', fieldValues, addressFilter))
    }
  }

  useEffect(() => {
    generateInfoMessage(addressFilter)
  }, [addressFilter, properties, generateInfoMessage])

  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const updateIsLoading = () => {
      setIsLoading(false)
    }
    updateIsLoading()
  }, [])

  const addressInputIsValid = (value) => stringContainsNoSpecialCharacters(value)
  const mandatoryAddressInputIsValid = (value) => value !== '' && addressInputIsValid(value)

  const renderMap = () => (
    <LeafletMapWithPolygonAndPropertyMarkers
      latitude={position.latitude}
      longitude={position.longitude}
      markers={[
        {
          uuid: propertyUuid,
          id: propertyId,
          name: propertyName,
          propertyType: propertyType,
          location: { lat: position.latitude, lng: position.longitude },
          address: {
            houseNumber: address?.house_id,
            street: address?.street_name,
            city: address?.city_name,
            zipCode: address?.postal_code,
            country: address?.country_name,
          },
          linkedDeal: propertyFinancingStatus,
          creationDate: propertySystemAdministrativeData.creation_date_time,
        },
      ]}
    />
  )

  const onAddressSuggestionSelected = (
    selectedAddress,
    handleBulkOnChange,
    handleOnChangeCustom,
  ) => {
    const geoLocation = getGeoLocationOfCompleteAddress(selectedAddress, '')
    handleBulkOnChange({
      countryCode: selectedAddress.countryCode || '',
      postalCode: selectedAddress.postcode || '',
      cityName: selectedAddress.city || '',
      streetName: selectedAddress.road || selectedAddress.name || '',
      houseId: selectedAddress.houseNumber || '',
      longitude: geoLocation.longitude,
      latitude: geoLocation.latitude,
    })
    handleOnChangeCustom('address', selectedAddress)
  }

  const renderAutocompleteComponentWrapper = (item, autoCompleteComponent) => (
    <EditCardItem
      key={item.label}
      editComponent={autoCompleteComponent}
      isMandatory={item.isMandatory}
      label={item.label}
    />
  )

  const renderAddressAutocompleteComponent = ({
    handleBulkOnChange,
    handleOnChangeCustom,
    item,
  }) => {
    const inputComponent = (
      <AddressAutocompleteInput
        id={`propertyAddress-${item.name}`}
        placeholder={t('components.property-address-card.search.placeholder')}
        icon={<Icon name="search" />}
        suggestionItemProps={{ icon: 'map' }}
        onAddressSelected={(selectedAddress) =>
          onAddressSuggestionSelected(selectedAddress, handleBulkOnChange, handleOnChangeCustom)
        }
        className="edit-input-field"
      />
    )
    return renderAutocompleteComponentWrapper(item, inputComponent)
  }

  const renderManualModeCheckBox = ({ item }) => (
    <EditCardItem
      key={item.label}
      editComponent={
        <FlexBox
          direction="Column"
          justifyContent={FlexBoxJustifyContent.Center}
          alignItems={FlexBoxAlignItems.End}
        >
          <CheckBox
            id={`propertyAddress-${item.name}`}
            checked={inManualMode}
            text={item.label}
            onChange={(e) => {
              setInManualMode(e.target.checked)
            }}
          />
        </FlexBox>
      }
    />
  )

  const renderPostalCodeEditComponent = ({
    handleOnChange,
    handleOnChangeCustom,
    fieldValues,
    item,
    isInvalid,
  }) => (
    <EditCardItem
      key={item.label}
      isMandatory={item.isMandatory}
      label={item.label}
      editComponent={
        <Input
          value={fieldValues[item.name]}
          valueState={isInvalid ? ValueState.Error : ValueState.None}
          className="edit-input-field"
          valueStateMessage={
            fieldValues[item.name] === '' ? (
              <span>{t('pages.property-create.basic-information.validation.postal-code')}</span>
            ) : (
              <span>{t('pages.property-create.basic-information.validation.no-special-char')}</span>
            )
          }
          onInput={(event) => {
            handleOnChange(
              item.name,
              event.target.value,
              true,
              item.isMandatory,
              item.validationFunction,
            )
          }}
          onBlur={(event) => {
            handleOnChange(
              item.name,
              event.target.value,
              false,
              item.isMandatory,
              item.validationFunction,
            )
            handleOnChangeCustom(item.name, event.target.value)
          }}
          disabled={!inManualMode}
        />
      }
    />
  )

  const renderCityAutocompleteComponent = ({
    handleOnChange,
    handleOnChangeCustom,
    fieldValues,
    item,
    isInvalid,
  }) => {
    const inputComponent = (
      <AddressAutocompleteInput
        id={`propertyAddress-${item.name}`}
        value={fieldValues[item.name]}
        valueState={isInvalid ? ValueState.Error : ValueState.None}
        valueStateMessage={
          fieldValues[item.name] === '' ? (
            <span>{t('pages.property-create.basic-information.validation.city-name')}</span>
          ) : (
            <span>{t('pages.property-create.basic-information.validation.no-special-char')}</span>
          )
        }
        apiParams={{
          tag: 'place:city,place:town,place:village',
          countrycodes: fieldValues.countryCode?.toLowerCase(),
        }}
        onAddressSelected={({ city, name }) => {
          handleOnChange(item.name, city || name, item.isMandatory, item.validationFunction)
          handleOnChangeCustom(item.name, city || name)
        }}
        formatAddress={({ city, name }) => city || name || ''}
        onInput={(evt) =>
          handleOnChange(
            item.name,
            evt.target.value,
            true,
            item.isMandatory,
            item.validationFunction,
          )
        }
        onBlur={(evt) => {
          handleOnChange(
            item.name,
            evt.target.value,
            false,
            item.isMandatory,
            item.validationFunction,
          )
        }}
        minInputLengthForSuggestions={2}
        className="edit-input-field"
        disabled={!inManualMode}
      />
    )
    return renderAutocompleteComponentWrapper(item, inputComponent)
  }

  const fieldDefinitions = [
    {
      label: t('components.property-address-card.search'),
      name: 'search',
      isShownInDisplay: false,
      isShownInEdit: true,
      renderCustomEditComponent: renderAddressAutocompleteComponent,
      editComponentProps: {},
      isMandatory: false,
    },
    {
      label: t('components.property-address-card.manual-mode'),
      name: 'manualMode',
      isShownInDisplay: false,
      isShownInEdit: true,
      renderCustomEditComponent: renderManualModeCheckBox,
      editComponentProps: {},
      isMandatory: false,
    },
    {
      label: t('components.property-address-card.country'),
      name: 'countryCode',
      value: address?.country_code,
      isShownInDisplay: false,
      customInfoComponent: null,
      isShownInEdit: true,
      customEditComponent: null,
      editComponentProps: { disabled: !inManualMode },
      editComponentType: editComponentTypes.Select,
      isMandatory: true,
      editComponentSelectOptions:
        !countryCodes.isLoading && !countryCodes.isError ? countryCodes?.data?.country_codes : [],
    },
    {
      label: t('components.property-address-card.country'),
      name: 'countryName',
      value: address?.country_name,
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: true,
      customInfoComponent: null,
      isShownInEdit: false,
      customEditComponent: null,
      editComponentProps: { disabled: !inManualMode },
      editComponentType: editComponentTypes.Input,
      isMandatory: true,
      editComponentSelectOptions: null,
    },
    {
      label: t('components.property-address-card.postal-code'),
      name: 'postalCode',
      value: address?.postal_code,
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: true,
      customInfoComponent: null,
      isShownInEdit: true,
      renderCustomEditComponent: renderPostalCodeEditComponent,
      isMandatory: true,
      validationFunction: mandatoryAddressInputIsValid,
    },
    {
      label: t('components.property-address-card.city'),
      name: 'cityName',
      value: address?.city_name,
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: true,
      customInfoComponent: null,
      isShownInEdit: true,
      renderCustomEditComponent: renderCityAutocompleteComponent,
      isMandatory: true,
      editComponentSelectOptions: null,
      validationFunction: mandatoryAddressInputIsValid,
    },
    {
      label: t('components.property-address-card.street'),
      name: 'streetName',
      value: address?.street_name,
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: true,
      customInfoComponent: null,
      isShownInEdit: true,
      customEditComponent: null,
      editComponentProps: {
        disabled: !inManualMode,
        valueStateMessage: (
          <span>{t('pages.property-create.basic-information.validation.no-special-char')}</span>
        ),
      },
      editComponentType: editComponentTypes.Input,
      isMandatory: false,
      editComponentSelectOptions: null,
      validationFunction: addressInputIsValid,
    },
    {
      label: t('components.property-address-card.house-number'),
      name: 'houseId',
      value: address?.house_id,
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: true,
      customInfoComponent: null,
      isShownInEdit: true,
      customEditComponent: null,
      editComponentType: editComponentTypes.Input,
      editComponentProps: {
        disabled: !inManualMode,
        valueStateMessage: (
          <span>{t('pages.property-create.basic-information.validation.no-special-char')}</span>
        ),
      },
      isMandatory: false,
      editComponentSelectOptions: null,
      validationFunction: addressInputIsValid,
    },
    {
      label: t('components.property-address-card.latitude'),
      name: 'latitude',
      value: position.latitude,
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: false,
      customInfoComponent: null,
      isShownInEdit: true,
      customEditComponent: null,
      editComponentType: editComponentTypes.Input,
      editComponentProps: { type: 'Number', disabled: !inManualMode },
      isMandatory: false,
      editComponentSelectOptions: null,
    },
    {
      label: t('components.property-address-card.longitude'),
      name: 'longitude',
      value: position.longitude,
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: false,
      customInfoComponent: null,
      isShownInEdit: true,
      customEditComponent: null,
      editComponentType: editComponentTypes.Input,
      editComponentProps: { type: 'Number', disabled: !inManualMode },
      isMandatory: false,
      editComponentSelectOptions: null,
    },
  ]

  const handleUpdateGeolocation = (force) => {
    updateGeolocation.mutate({
      property_uuid: propertyUuid,
      force: force,
    })
  }

  const mapDefinitions = [
    {
      label: null,
      name: 'map',
      value: null,
      isShownInDisplay: true,
      customInfoComponent: (
        <Card>
          <div className="map-wrapper display-card-item">
            <LoadingStateWrapper
              isError={false}
              isLoading={isLoading}
              isNotFoundError={false}
              renderContent={renderMap}
              errorDescription={t('error.description')}
              errorDetails={t('error.details')}
              errorTitle={t('error.title')}
            />
          </div>
        </Card>
      ),
      isShownInEdit: false,
      customEditComponent: null,
      editComponentType: null,
      editComponentProps: {},
      isMandatory: false,
      editComponentSelectOptions: null,
    },
  ]

  const messageStripDefinition = (action, showLink, googleLinkLat, googleLinkLong) => ({
    isShownInDisplay: true,
    isShownInEdit: false,
    customInfoComponent: (
      <GeolocationMessageStrip
        action={action}
        hasLink={isAllowedPropertyGeoLocationEdit && showLink}
        handleUpdate={handleUpdateGeolocation}
        latitude={googleLinkLat}
        longitude={googleLinkLong}
      />
    ),
  })

  const addMessageStripToFieldDefinitions = (originalFieldDefinitions) => {
    const isDefaultGeolocation = _.isEqual(geoLocationFromContext, DEFAULT_GEOLOCATION)
    const geolocationsAreEqual = _.isEqual(geoLocationFromContext, geolocationFromHook)
    if (isDefaultGeolocation) {
      //No value is set in CMS
      if (geolocationFromHook) {
        return [messageStripDefinition(LOAD_GEOCODE), ...originalFieldDefinitions]
      } else {
        return [messageStripDefinition(LOAD_GEOCODE, false), ...originalFieldDefinitions]
      }
    }
    if (!isDefaultGeolocation && !geolocationsAreEqual) {
      //Value is set in CMS, does not match calculated location
      if (geolocationFromHook) {
        return [
          messageStripDefinition(RELOAD_GEOCODE),
          ...originalFieldDefinitions,
          ...mapDefinitions,
        ]
      } else {
        return [
          messageStripDefinition(NO_VERIFY, false),
          ...originalFieldDefinitions,
          ...mapDefinitions,
        ]
      }
    }
    const googleLinkLat = geoLocationFromContext.latitude
    const googleLinkLong = geoLocationFromContext.longitude
    return [
      messageStripDefinition(SUCCESS, true, googleLinkLat, googleLinkLong),
      ...originalFieldDefinitions,
      ...mapDefinitions,
    ]
  }

  let fieldDefinitionsToRender = fieldDefinitions
  if (!isLoadingGeolocation && !isErrorGeolocation) {
    fieldDefinitionsToRender = addMessageStripToFieldDefinitions(fieldDefinitions)
  }

  const saveChanges = (changes) => {
    const newAddress = {
      streetName: changes.streetName,
      houseNumber: changes.houseId,
      postalCode: changes.postalCode,
      cityName: changes.cityName,
      countryCode: changes.countryCode,
      latitude: changes.latitude,
      longitude: changes.longitude,
    }

    updateProperty.mutate({
      property_uuid: propertyUuid,
      property: newAddress,
    })
    setInManualMode(false)
    setInfoMessage(EXISTING_PROPERTY_INDICATOR.NONE)
    setAddressFilter({})
  }

  const tryJsonParse = (maybeJSON) => {
    let parsed
    try {
      parsed = JSON.parse(maybeJSON)
    } catch (e) {
      parsed = {}
    }
    return parsed
  }

  // fix: CWP-6967: CMS error message contains ampersand
  const [updatePropertyError, setUpdatePropertyError] = useState('')
  const [isErrorMessageProcessed, setMessageProcessed] = useState(false)

  useEffect(() => {
    if (!updateProperty?.isError && isErrorMessageProcessed) {
      setMessageProcessed(false)
    }
  }, [isErrorMessageProcessed, updateProperty?.isError])

  const asyncSetSaveErrorDetails = async (error) => {
    const errorDetailsResponse = await formatHookError(error)
    if (errorDetailsResponse?.includes('errorDetails')) {
      const jsonError = tryJsonParse(errorDetailsResponse)
      const traceDetailsJson = tryJsonParse(jsonError?.errorDetails?.trace_details)
      const messageError = traceDetailsJson?.error?.message
      // remove ampersand from error message
      traceDetailsJson.error.message = messageError?.replace(/&/g, '')
      setUpdatePropertyError({
        response: {
          json: async () => ({
            status: jsonError.status,
            statusText: jsonError.statusText,
            url: jsonError.url,
            trace_details: JSON.stringify(traceDetailsJson),
          }),
        },
      })
      setMessageProcessed(true)
    }
  }

  if (updateProperty?.isError) {
    asyncSetSaveErrorDetails(updateProperty?.error)
  }

  return (
    <DisplayAndEditCard
      cardHeaderTitle={t('components.property-address-card.title')}
      saveHookIsSuccess={updateProperty.isSuccess}
      saveHookIsError={updateProperty.isError && isErrorMessageProcessed}
      saveHookError={updatePropertyError}
      fieldDefinitions={fieldDefinitionsToRender}
      saveChanges={saveChanges}
      isEditable={isAllowedPropertyMasterDataEdit}
      isSaveDisabled={isPropertyCheckLoading && areAddressFieldsUpdated}
      cancelChanges={() => {
        setInManualMode(false)
        setAddressFilter({})
      }}
      additionalEditHeaderProps={{ isSaveLoading: isPropertyCheckLoading }}
      additionalContentProps={
        <ExistingPropertyMessageBox
          className={'existing-property-message-box'}
          infoMessage={infoMessage}
        />
      }
      customOnChangeHandler={(key, value, fieldValues, oldValues) =>
        onAddressFieldUpdateHandler(key, value, fieldValues, oldValues)
      }
    />
  )
}

PropertyAddressCard.propTypes = {
  allowUserToEdit: PropTypes.bool,
}

export default PropertyAddressCard
