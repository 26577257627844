import {
  FlexBox,
  FlexBoxDirection,
  Label,
  Text,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ConditionSelectionHelperDialog from 'components/domains/conditions/condition-selection/ConditionSelectionHelperDialog'
import ConditionSelectionInput from 'components/domains/conditions/condition-selection/ConditionSelectionInput'
import styles from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableConditionCell.module.css'
import useRequirementsCellAddMode from 'hooks/services/conditions/edit-mode/useRequirementsCellAddMode'
import useRequirementsCellEditMode from 'hooks/services/conditions/edit-mode/useRequirementsCellEditMode'

import useShowRequirementRefNrUpdateConfirmation from 'hooks/services/conditions/requirements/helper/useShowRequirementRefNrUpdateConfirmation'
import {
  requirementConditionChanged,
  requirementRefNumberChanged,
} from 'redux/slices/conditions/requirementsTableSlice'

const hyphen = '-'

const RequirementsTableConditionCell = ({ value: { id: requirementId, condition } }) => {
  const dispatch = useDispatch()
  const [isHelperDialogOpen, setIsHelperDialogOpen] = useState(false)
  const { openConfirmationDialog } = useShowRequirementRefNrUpdateConfirmation()

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.columns.condition.name',
  })

  const { isAddModeForCurrentRow } = useRequirementsCellAddMode({
    requirementId,
  })
  const { isEditModeForCurrentRow } = useRequirementsCellEditMode({ requirementId })

  const editedName = useSelector(
    (state) => state.conditions.requirementsTable.editedRow.currentValues?.condition?.name,
  )

  const editedRefNr = useSelector(
    (state) => state.conditions.requirementsTable.editedRow.currentValues?.refNumber,
  )

  const hasConditionError = useSelector(
    (state) => !!state.conditions.requirementsTable.editedRow.errorFields?.condition,
  )

  const onCancelConditionSelection = useCallback(() => {
    setIsHelperDialogOpen(false)
  }, [])

  const onConfirmRefNumberUpdate = useCallback(
    ({ refNumber }) =>
      () => {
        dispatch(requirementRefNumberChanged({ refNumber }))
      },
    [dispatch],
  )

  const onSelectionChanged = useCallback(
    ({ condition: newCondition }) => {
      dispatch(
        requirementConditionChanged({
          requirementId,
          condition: {
            id: newCondition.id,
            name: newCondition.info.name,
            references: newCondition.references,
          },
        }),
      )
      setIsHelperDialogOpen(false)

      const refNumber = newCondition.info.refNumber
      if (isEmpty(refNumber)) {
        return
      }

      if (!isEmpty(editedRefNr)) {
        openConfirmationDialog({
          onConfirm: onConfirmRefNumberUpdate({ refNumber }),
          refNumber,
        })
        return
      }
      onConfirmRefNumberUpdate({ refNumber })()
    },
    [dispatch, editedRefNr, onConfirmRefNumberUpdate, openConfirmationDialog, requirementId],
  )

  const onClickConditionSelection = useCallback(() => {
    setIsHelperDialogOpen(true)
  }, [])

  const onClearSelection = useCallback(() => {
    dispatch(
      requirementConditionChanged({
        requirementId,
        condition: null,
      }),
    )
  }, [dispatch, requirementId])

  if (isAddModeForCurrentRow || isEditModeForCurrentRow)
    return (
      <>
        <ConditionSelectionInput
          name={editedName}
          hasError={hasConditionError}
          onClick={onClickConditionSelection}
          onClear={onClearSelection}
        />
        <ConditionSelectionHelperDialog
          isOpen={isHelperDialogOpen}
          onCancel={onCancelConditionSelection}
          onSelect={onSelectionChanged}
        />
      </>
    )

  if (!condition?.info?.name) {
    return
  }
  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.conditionWrapper}>
      <Text>{condition.info.name}</Text>
      <div className={styles.conditionInfoWrapper}>
        <Label>{t('type-label')}</Label>{' '}
        <Text wrapping={WrappingType.None} className={styles.conditionInfoValue}>
          {condition.info.type.name}
        </Text>
      </div>
      <div className={styles.conditionInfoWrapper}>
        <Label>{t('category-label')}</Label>{' '}
        <Text wrapping={WrappingType.None} className={styles.conditionInfoValue}>
          {condition.info.category || hyphen}
        </Text>
      </div>
    </FlexBox>
  )
}

RequirementsTableConditionCell.propTypes = {
  value: PropTypes.shape({
    condition: PropTypes.shape({
      info: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.shape({
          code: PropTypes.string,
          name: PropTypes.string,
        }),
        category: PropTypes.string,
      }),
    }), // TODO
    id: PropTypes.string,
  }).isRequired,
}

export default RequirementsTableConditionCell
