import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import DisbursementTileCurrencySelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/disbursement/fields/DisbursementTileCurrencySelect'
import DisbursementTileDisplayOrEditItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/disbursement/fields/DisbursementTileDisplayOrEditItem'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/disbursement/fields/DisbursementTileMonetaryValueField.module.css'
import { useCustomizableCurrencyFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'
import FormattedNumberInput from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/input/FormattedNumberInput'

const DisbursementTileMonetaryValueField = ({
  label,
  value,
  currency,
  disabled,
  isEditMode,
  onChange,
  onCurrencyChange,
  isFirstItem,
  isCurrencySelectEnabled = false,
}) => {
  const formattedNumberInputRef = useRef()

  const formatCurrency = useCustomizableCurrencyFormatter()

  const renderEditComponent = () => (
    <FlexBox className={styles.inputWrapper} direction={FlexBoxDirection.Row}>
      <FormattedNumberInput
        value={value}
        onChange={(parsedNumber) =>
          onChange(parsedNumber, formattedNumberInputRef?.current?.isValid)
        }
        readonly={disabled}
        ref={formattedNumberInputRef}
      />
      <DisbursementTileCurrencySelect
        disabled={!isCurrencySelectEnabled}
        value={currency}
        onChange={onCurrencyChange}
      />
    </FlexBox>
  )

  return (
    <DisbursementTileDisplayOrEditItem
      label={label}
      value={formatCurrency(value, currency)}
      renderEditComponent={renderEditComponent}
      isEditMode={isEditMode}
      isFirstItem={isFirstItem}
    />
  )
}

DisbursementTileMonetaryValueField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  currency: PropTypes.string,
  disabled: PropTypes.bool,
  isEditMode: PropTypes.bool,
  onChange: PropTypes.func,
  onCurrencyChange: PropTypes.func,
  isFirstItem: PropTypes.bool,
  isCurrencySelectEnabled: PropTypes.bool,
}

export default DisbursementTileMonetaryValueField
