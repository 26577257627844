import { first } from 'lodash'
import sortBy from 'lodash.sortby'
import { useTranslation } from 'react-i18next'
import PropertyRentRollOverviewCurrentRentPieChart from 'components/domains/properties/rent-roll/overview/current-rent/PropertyRentRollOverviewCurrentRentPieChart'
import PropertyRentRollOverviewPrimaryTenant from 'components/domains/properties/rent-roll/overview/current-rent/PropertyRentRollOverviewPrimaryTenant'
import PropertyRentRollOverviewPrimaryTenantUnit from 'components/domains/properties/rent-roll/overview/current-rent/PropertyRentRollOverviewPrimaryTenantUnit'
import Card from 'components/ui/card/Card'
import CardHeaderWithMetrics from 'components/ui/card/CardHeaderWithMetrics'
import useAnnualizedCurrentRentPrimaryMetric from 'hooks/services/properties/kpis/useAnnualizedCurrentRentPrimaryMetric'
import useCurrentRentRollOverviewPropertyKpis from 'hooks/services/properties/kpis/useCurrentRentRollOverviewPropertyKpis'

const PropertyRentRollOverviewCurrentRentCard = () => {
  const { t: tCurrentRent } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.current-rent',
  })

  const {
    isLoading: isLoadingPropertyKpis,
    isError: isErrorPropertyKpis,
    data: propertyKpi,
  } = useCurrentRentRollOverviewPropertyKpis()
  const keyDate = propertyKpi?.keyDate

  const getPrimaryTenantId = () => {
    const sortedTenantKPIs = sortBy(
      propertyKpi?.tenantRentRollKpis,
      (item) => -item.annualizedCurrentRent.number,
    )
    return first(sortedTenantKPIs)?.tenant
  }

  const primaryTenantId = getPrimaryTenantId()

  const primaryMetric = useAnnualizedCurrentRentPrimaryMetric(propertyKpi)

  const secondaryMetrics = [
    {
      label: tCurrentRent('primary-tenant'),
      value: primaryTenantId ? (
        <PropertyRentRollOverviewPrimaryTenant primaryTenantId={primaryTenantId} />
      ) : undefined,
    },
    {
      label: tCurrentRent('tenant-unit'),
      value: primaryTenantId ? (
        <PropertyRentRollOverviewPrimaryTenantUnit primaryTenantId={primaryTenantId} />
      ) : undefined,
    },
  ]

  return (
    <Card
      header={
        <CardHeaderWithMetrics
          title={tCurrentRent('title')}
          primaryMetric={primaryMetric}
          secondaryMetrics={secondaryMetrics}
          referenceDate={keyDate}
          isLoading={isLoadingPropertyKpis}
          isError={isErrorPropertyKpis}
        />
      }
    >
      <PropertyRentRollOverviewCurrentRentPieChart />
    </Card>
  )
}

export default PropertyRentRollOverviewCurrentRentCard
