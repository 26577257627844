import 'components/domains/markets/detail/MarketsTables.css'
import {
  Label,
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Button,
  FlexBoxAlignItems,
  Text,
  CheckBox,
  TableGrowingMode,
  CardHeader,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MarketsKpiDialog from 'components/domains/markets/detail/MarketsKpiDialog'
import styles from 'components/domains/markets/detail/MarketsKpiTable.module.css'
import MarketsKpiTablePopoverComponent from 'components/domains/markets/detail/MarketsKpiTablePopoverComponent.jsx'
import Card from 'components/ui/card/Card'
import ClickableKpiChart from 'components/ui/charts/kpi-chart/ClickableKpiChart'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'
import ErrorDialog from 'components/ui/dialog/ErrorDialog'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { useNumberFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'
import useMarketKpis from 'hooks/services/markets/useMarketKpis'
import useUpdateMarketKpis from 'hooks/services/markets/useUpdateMarketKpis'

const defaultNumberOfKpis = 10

const MarketsKpiTable = ({ marketId, showEditButton }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.markets.detail.kpis' })
  const formatNumber = useNumberFormatter()
  const { t: tNoPrefix } = useTranslation()
  const [selectedEditKpi, setSelectedEditKpi] = useState({})
  const [isKpiDialogOpen, setIsKpiDialogOpen] = useState(false)
  const [showEmptyKpiTableRows, setShowEmptyKpiTableRows] = useState(true)
  const { format } = useShortDateFormatter()
  const [shownKpis, setShownKpis] = useState(defaultNumberOfKpis)
  const [kpiToUpdate, setKpiToUpdate] = useState({})
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
  const queryClient = useQueryClient()

  const {
    data: marketKpis,
    isLoading,
    isError,
  } = useMarketKpis({ marketId, sortBy: 'validity_date', filterDuplicateValidityDate: true })

  const { mutate: updateKpisMutation } = useUpdateMarketKpis({
    onSuccess: () => {
      queryClient.invalidateQueries(['market-kpis', marketId])
      setIsConfirmationDialogOpen(false)
    },
    onError: () => {
      setIsErrorDialogOpen(true)
    },
  })

  const handleSaveConfirmation = useCallback(() => {
    const kpiData = {
      [kpiToUpdate.key]: {
        values: [
          {
            value: kpiToUpdate.value,
            validity_date: kpiToUpdate.validity_date,
          },
        ],
      },
    }
    updateKpisMutation({ marketId, kpiData })
  }, [kpiToUpdate, marketId, updateKpisMutation])
  const handleRetry = useCallback(() => {
    setIsErrorDialogOpen(false)
    handleSaveConfirmation()
  }, [handleSaveConfirmation])

  const handleConfirmationCancel = useCallback(() => {
    setIsConfirmationDialogOpen(false)
  }, [])
  const onAddClick = useCallback(({ key, value, validity_date }) => {
    setKpiToUpdate({ key, value, validity_date })
    setIsConfirmationDialogOpen(true)
  }, [])

  const calculateLastUpdatedValue = useCallback(
    (mappedKpis) => {
      if (mappedKpis.length === 0) {
        return '-'
      }
      const currentDateValueKpis = mappedKpis.map(({ current: { date } }) =>
        date === '-' ? -1 : new Date(date).getTime(),
      )
      const lastUpdateDate = Math.max(...currentDateValueKpis)
      if (lastUpdateDate === -1) {
        return '-'
      }
      return format(DateTime.fromMillis(lastUpdateDate).toISODate())
    },
    [format],
  )

  const kpisColumns = useCallback(() => {
    const editKpiValueColumn = {
      title: '',
      columnKey: 'editKpi',
      className: 'edit-kpi-column',
    }

    const columns = [
      {
        title: t('table.kpi'),
        columnKey: 'kpiName',
        minWidth: 600,
        demandPopin: true,
        popinText: t('table.kpi'),
      },
      {
        title: t('table.time-series'),
        columnKey: 'timeSeries',
      },
      {
        title: t('table.value'),
        columnKey: 'kpiValue',
        minWidth: 600,
        demandPopin: true,
        popinText: t('table.value'),
      },
    ]

    if (showEditButton) columns.push(editKpiValueColumn)

    return columns
  }, [showEditButton, t])

  const hasNonEmptyValue = (kpiValue) => {
    if (kpiValue.value !== '-') return true
    return false
  }

  const handleEditKpiButtonClicked = useCallback(
    (kpiKey) => () => {
      setSelectedEditKpi({
        key: kpiKey,
        name: marketKpis.kpis[kpiKey].name,
        value: marketKpis.kpis[kpiKey].values[0]?.value,
        date: marketKpis.kpis[kpiKey].values[0]?.validity_date,
        unit: marketKpis.kpis[kpiKey].unit,
      })
      setIsKpiDialogOpen(true)
    },
    [marketKpis],
  )

  const onLoadMoreButtonClick = useCallback(() => {
    setShownKpis(shownKpis + defaultNumberOfKpis)
  }, [shownKpis])

  const mappedKpis = useMemo(() => {
    if (!marketKpis || !marketKpis.kpis) {
      return []
    }
    return Object.entries(marketKpis.kpis)
      .filter(([_kpiKey, { values: kpiValues }]) => {
        if (showEmptyKpiTableRows && kpiValues.length === 0) {
          return false
        }
        return true
      })
      .map(
        ([
          kpiKey,
          { name: kpiName, unit: kpiUnit, description: kpiDescription, values: kpiValues },
        ]) => {
          const reversedKpiValues = [...kpiValues]
            .reverse()
            .map(({ value, validity_date, receipt_date }) => ({
              value: +value,
              validity_date,
              receipt_date,
            }))

          return {
            kpiKey,
            kpiName,
            kpiUnit,
            kpiDescription,
            current: {
              value: kpiValues[0]?.value || '-',
              displayValue: kpiValues[0] ? `${formatNumber(+kpiValues[0].value)} ${kpiUnit}` : '-',
              date: kpiValues[0]?.validity_date || '-',
            },
            previous: {
              value: kpiValues[1]?.value || '-',
              displayValue: kpiValues[1] ? `${formatNumber(+kpiValues[1].value)} ${kpiUnit}` : '-',
              date: kpiValues[1]?.validity_date || '-',
            },
            kpiData: reversedKpiValues,
          }
        },
      )
  }, [formatNumber, marketKpis, showEmptyKpiTableRows])

  const numberOfShownKpis = shownKpis >= mappedKpis.length ? mappedKpis.length : shownKpis

  const onShowEmptyKpiRowEntriesClicked = useCallback(() => {
    setShowEmptyKpiTableRows(!showEmptyKpiTableRows)
  }, [showEmptyKpiTableRows])

  const kpiWithValueCheckBox = useMemo(
    () => (
      <CheckBox
        key="kpi-with-value-checkbox"
        checked={showEmptyKpiTableRows}
        onChange={onShowEmptyKpiRowEntriesClicked}
        text={t('display.empty-kpis.checkbox')}
      />
    ),
    [showEmptyKpiTableRows, onShowEmptyKpiRowEntriesClicked, t],
  )

  const marketsTitle = useMemo(() => {
    if (!isLoading && !isError) {
      return t('title')
    }
    return t('title.loading')
  }, [isLoading, isError, t])

  const toolbarConfig = useMemo(
    () => ({
      title: t('table.title'),
      additionalActions: [kpiWithValueCheckBox],
      showColumnSelection: false,
    }),
    [kpiWithValueCheckBox, t],
  )

  const renderKpiTable = useCallback(() => {
    const kpiTableData = mappedKpis
      .map(({ kpiKey, kpiName, kpiUnit, current, previous, kpiData, kpiDescription }) => ({
        rowKey: `kpi-table-row-${kpiKey}`,
        kpiName: {
          value: kpiName,
          cellComponent: (
            <MarketsKpiTablePopoverComponent kpiName={kpiName} description={kpiDescription} />
          ),
        },
        kpiValue: {
          value: current.value,
          cellComponent: (
            <FlexBox
              direction={FlexBoxDirection.Column}
              justifyContent={FlexBoxJustifyContent.Center}
            >
              {hasNonEmptyValue(current) && (
                <FlexBox
                  direction={FlexBoxDirection.Row}
                  justifyContent={FlexBoxJustifyContent.Center}
                  style={{ marginBottom: '8px' }}
                >
                  <Label>{t('current-kpi')}</Label>
                  <FlexBox
                    direction={FlexBoxDirection.Column}
                    justifyContent={FlexBoxJustifyContent.Center}
                  >
                    <Text>{current.displayValue}</Text>
                    <Text>{format(current.date)}</Text>
                  </FlexBox>
                </FlexBox>
              )}
              {!hasNonEmptyValue(current) && (
                <FlexBox justifyContent={FlexBoxJustifyContent.Center}>
                  <Text>{'-'}</Text>
                </FlexBox>
              )}

              {hasNonEmptyValue(previous) && (
                <FlexBox
                  direction={FlexBoxDirection.Row}
                  justifyContent={FlexBoxJustifyContent.Center}
                >
                  <Label>{t('previous-kpi')}</Label>
                  <FlexBox
                    direction={FlexBoxDirection.Column}
                    justifyContent={FlexBoxJustifyContent.Center}
                  >
                    <Text>{previous.displayValue}</Text>
                    <Text>{format(previous.date)}</Text>
                  </FlexBox>
                </FlexBox>
              )}
            </FlexBox>
          ),
        },
        timeSeries: {
          value: 0,
          cellComponent: (
            <ClickableKpiChart kpiName={kpiName} kpiData={kpiData} kpiUnit={kpiUnit} />
          ),
        },
        editKpi: {
          cellComponent: (
            <FlexBox
              direction={FlexBoxDirection.Row}
              alignItems={FlexBoxAlignItems.End}
              justifyContent={FlexBoxJustifyContent.End}
            >
              <Button onClick={handleEditKpiButtonClicked(kpiKey)}>{t('table.add-kpi')}</Button>
            </FlexBox>
          ),
        },
      }))
      .slice(0, shownKpis)

    return (
      <div className={styles.tableWrapper}>
        <SortedTable
          columnDefinitions={kpisColumns()}
          tableData={kpiTableData}
          noDataText={t('table.no-data')}
          initialSortingColumnKey="kpiName"
          toolbarConfig={toolbarConfig}
          paginationConfig={{
            growing: mappedKpis.length > shownKpis ? TableGrowingMode.Button : undefined,
            growingButtonText: t('load-more'),
            growingButtonSubtext: `[${numberOfShownKpis} / ${mappedKpis.length}]`,
            totalNumberOfItems: mappedKpis.length,
            loadMore: onLoadMoreButtonClick,
          }}
        />
      </div>
    )
  }, [
    mappedKpis,
    shownKpis,
    kpisColumns,
    t,
    numberOfShownKpis,
    onLoadMoreButtonClick,
    format,
    toolbarConfig,
    handleEditKpiButtonClicked,
  ])

  const cardHeader = useMemo(
    () => (
      <CardHeader
        titleText={marketsTitle}
        subtitleText={t('lastUpdate', { lastUpdate: calculateLastUpdatedValue(mappedKpis) })}
        className={styles.cardHeader}
      />
    ),
    [marketsTitle, calculateLastUpdatedValue, mappedKpis, t],
  )

  return (
    <Card id="properties-card" header={cardHeader}>
      <LoadingStateWrapper
        isError={isError}
        isLoading={isLoading}
        renderContent={renderKpiTable}
        errorDescription={t('error.loading.description')}
        errorDetails=" "
        errorTitle={t('error.loading.title')}
      />
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        texts={{
          retryButton: tNoPrefix('buttons.try-again'),
          cancelButton: tNoPrefix('buttons.cancel'),
          title: t('error.title'),
          description: t('error.description'),
        }}
        onRetry={handleRetry}
      />
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        setIsOpen={setIsConfirmationDialogOpen}
        handleConfirmation={handleSaveConfirmation}
        handleCancel={handleConfirmationCancel}
        confirmationMessage={t('confirmation.message')}
      />
      <MarketsKpiDialog
        isOpen={isKpiDialogOpen}
        setIsOpen={setIsKpiDialogOpen}
        kpiName={selectedEditKpi.name}
        onAddClick={onAddClick}
        kpiCurrentUnit={selectedEditKpi.unit}
        kpiCurrentValue={selectedEditKpi.value}
        kpiKey={selectedEditKpi.key}
      />
    </Card>
  )
}

MarketsKpiTable.propTypes = {
  marketId: PropTypes.string.isRequired,
  showEditButton: PropTypes.bool.isRequired,
}

export default MarketsKpiTable
