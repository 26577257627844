import camelize from 'camelize'
import { useRequest } from 'hooks/services/baseService'

const useDocument = ({ documentId, options = {} }) => {
  const result = useRequest({
    path: `/documents/${documentId}`,
    keys: ['documents', documentId],
    options: {
      enabled: !!documentId,
    },
    ...options,
  })
  return { ...result, data: camelize(result.data) }
}

export default useDocument
