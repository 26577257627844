import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useForbearanceAndUtpCheckOptions = () => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.forbearance-and-utp-questionare.options.bool-options',
  })

  return useMemo(
    () => [
      {
        key: '-',
        value: '-',
        displayValue: t('empty-value'),
      },
      {
        key: 'required',
        value: 'required',
        displayValue: t('required'),
      },
      {
        key: 'notRequired',
        value: 'notRequired',
        displayValue: t('not-required'),
      },
    ],
    [t],
  )
}

export default useForbearanceAndUtpCheckOptions
