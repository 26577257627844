import { IllustrationMessageSize, IllustrationMessageType } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/rent-roll/overview/current-rent/PropertyRentRollOverviewCurrentRentPieChart.module.css'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import DonutChart from 'components/ui/charts/donut-chart/DonutChart'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useCurrencyFormatter } from 'hooks/i18n/useI18n'
import usePropertyTenantsPieChartData from 'hooks/services/properties/kpis/charts/usePropertyTenantsPieChartData'
import useCurrentRentRollOverviewPropertyKpis from 'hooks/services/properties/kpis/useCurrentRentRollOverviewPropertyKpis'

export const MAX_DISPLAYED_TENANTS = 4

/**
 * @typedef {object} CurrentRentChartData
 * @property {object} data the data to be consumed by the chart component
 * @property {string} currency the currency to be displayed in the chart tooltips
 * @property {boolean} isLoading is data being fetched
 * @property {boolean} isError has an error occurred during fetching
 * @property {boolean} isEmpty is there data to display
 * @property {boolean} isInvalidData is there data that can't be displayed in the chart
 */

/**
 * @return {CurrentRentChartData} the data necessary to render the view
 */
const useCurrentRentChartData = () => {
  const {
    isLoading: isLoadingPropertyKpis,
    isError: isErrorPropertyKpis,
    data: propertyKpiData,
  } = useCurrentRentRollOverviewPropertyKpis()

  const {
    data: currentRentChartDataWithTenantNames,
    isLoading: isLoadingChartData,
    isError: isErrorChartData,
    isEmpty: hasEmptyRentRollData,
  } = usePropertyTenantsPieChartData({
    propertyKpiData,
    getMetricFromTenantKpi: (tenantKpi) => tenantKpi.annualizedCurrentRent.number,
    maxDisplayedTenants: MAX_DISPLAYED_TENANTS,
  })

  const isLoading = isLoadingPropertyKpis || isLoadingChartData
  const isError = isErrorPropertyKpis || isErrorChartData

  const totalPropertyAnnualizedCurrentRent = propertyKpiData?.annualizedCurrentRent?.number ?? 0
  const currency = propertyKpiData?.annualizedCurrentRent?.currency

  return {
    data: currentRentChartDataWithTenantNames,
    currency,
    isLoading,
    isError,
    isEmpty: hasEmptyRentRollData,
    isInvalidData: totalPropertyAnnualizedCurrentRent === 0,
  }
}

const PropertyRentRollOverviewCurrentRentPieChart = () => {
  const { t: tPieChart } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.current-rent.pie-chart',
  })

  const {
    data: currentRentChartData,
    currency,
    isLoading,
    isError,
    isEmpty,
    isInvalidData,
  } = useCurrentRentChartData()

  // handle an edge case where the rent of all properties is zero, which would
  // result in an empty chart. In this case we simply show nothing.
  const shouldHideChart = !isError && !isLoading && !isEmpty && isInvalidData

  const formatTooltipRent = useCurrencyFormatter({ currency })

  if (shouldHideChart) return null

  return (
    <div className={styles.chartContainer}>
      <LoadingStateWrapper
        isLoading={isLoading}
        isError={isError}
        errorTitle={tPieChart('error.title')}
        errorDescription={tPieChart('error.subtitle')}
        errorDetails=" "
        loadingDescription={tPieChart('loading')}
      >
        {isEmpty ? (
          <EmptyCardContent
            title={tPieChart('empty.title')}
            subtitle={tPieChart('empty.subtitle')}
            illustrationName={IllustrationMessageType.UnableToLoad}
            size={IllustrationMessageSize.Scene}
          />
        ) : (
          <DonutChart data={currentRentChartData} tooltipValueFormatter={formatTooltipRent} />
        )}
      </LoadingStateWrapper>
    </div>
  )
}

export default PropertyRentRollOverviewCurrentRentPieChart
