import { FlexBox, FlexBoxDirection, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { decisionStageStatus } from 'api/decision-process/decisionProcessApi'
import useDecisionStageName from 'components/domains/business-events-and-tasks/common/useDecisionStageName'
import DecisionProcessOverviewTableStatusCell from 'components/domains/business-events-and-tasks/decision-process/DecisionProcessOverviewTableStatusCell'
import styles from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageInformationDisplay.module.css'

const DecisionStageInformationDisplay = ({ decisionStage }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage',
  })
  const displayName = useDecisionStageName({
    name: decisionStage?.info.name,
    decisionStageType: decisionStage?.info.decisionType,
  })
  if (!decisionStage) {
    return t('status-unknown')
  }
  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Text className={styles['upper-label']}>{displayName}</Text>
      <DecisionProcessOverviewTableStatusCell stageStatus={decisionStage.info.decisionStatus} />
    </FlexBox>
  )
}

DecisionStageInformationDisplay.propTypes = {
  decisionStage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    info: PropTypes.shape({
      code: PropTypes.string.isRequired,
      decisionDate: PropTypes.string,
      decisionStatus: PropTypes.oneOf(Object.values(decisionStageStatus)).isRequired,
      decisionType: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }),
}

export default DecisionStageInformationDisplay
