import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdatePropertyMonitoring = ({ onSuccess, onError }) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(
    async ({ monitoringPropertyId, monitoringStatus, baselineDate, note }) => {
      await patch({
        path: `/property-monitoring/${monitoringPropertyId}`,
        body: {
          monitoring_status: monitoringStatus,
          note,
          baseline_date: baselineDate,
        },
      })
    },
    { onSuccess, onError },
  )
}

export default useUpdatePropertyMonitoring
