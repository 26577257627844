import { AnalyticalTableScaleWidthMode } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/financing-structure/collateral-agreements/MultiCollateralAgreementsTile.module.css'
import { mapAggregatedMultiCollateralAgreementsToTableData } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/financing-structure/collateral-agreements/mapAggregatedMultiCollateralAgreementsToTableData'
import useMultiCollateralAgreementsTileColumnDefinitions from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/financing-structure/collateral-agreements/useMultiCollateralAgreementsTileColumnDefinitions'
import AnalyticalTableNoDataComponent from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/analytical/AnalyticalTableWithToolbar'
import AnalyticalTableScalingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/AnalyticalTableScalingWrapper'

const MultiCollateralAgreementsTile = ({ tileId, isPdfView, selectedDealIndex }) => {
  const { data: { multiDealCollateralAgreements = [], dealsData } = {} } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId],
  )

  const selectedDealUuid = dealsData[selectedDealIndex]?.dealUuid

  const collateralAgreements = useMemo(
    () =>
      multiDealCollateralAgreements?.find(
        (dealCollateralAgreement) => dealCollateralAgreement?.dealUuid === selectedDealUuid,
      ),
    [multiDealCollateralAgreements, selectedDealUuid],
  )

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.collateral-agreements.table',
  })
  const tableRef = useRef(null)
  const [tableWidth, setTableWidth] = useState(tableRef?.current?.totalColumnsWidth ?? 0)

  useEffect(() => {
    setTableWidth(tableRef?.current?.totalColumnsWidth ?? 0)
  }, [tableRef?.current?.totalColumnsWidth])

  const mappedData = useMemo(
    () =>
      mapAggregatedMultiCollateralAgreementsToTableData({
        aggregatedCollateralAgreements: !isEmpty(collateralAgreements?.cagClusters)
          ? collateralAgreements
          : {},
        areRowsSectionHeader: false,
        isTotalSectionHeader: true,
        isNormalRowBold: false,
      }),
    [collateralAgreements],
  )

  const columnDefinitions = useMultiCollateralAgreementsTileColumnDefinitions()

  return useMemo(
    () => (
      <AnalyticalTableScalingWrapper isPdfView={isPdfView} tableWidth={tableWidth}>
        <div className={styles.tableWrapper}>
          <AnalyticalTableWithToolbar
            title={t('title')}
            showNrOfEntries={false}
            loading={false}
            id="collateralAgreementsTile-table"
            sortable={false}
            minRows={0}
            columns={columnDefinitions}
            overscanCountHorizontal={99}
            rowHeight={50}
            visibleRows={99}
            headerRowHeight={30}
            data={mappedData ?? []}
            isTreeTable={false}
            scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
            withRowHighlight={false}
            NoDataComponent={() => (
              <AnalyticalTableNoDataComponent
                isLoading={false}
                isError={false}
                tableId="collateralAgreementsTile-table"
              />
            )}
          />
        </div>
      </AnalyticalTableScalingWrapper>
    ),
    [columnDefinitions, isPdfView, mappedData, t, tableWidth],
  )
}

MultiCollateralAgreementsTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultiCollateralAgreementsTile
