import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

/**
 * @typedef {{level: number, category: number, levelDisplayValue: string, cangedAt: string, changedBy: string}} Assessment
 * @typedef {{assessments: Assessment[], _allowedOperations: string[]}} AssessmentResponse
 * @param {{businessPartnerIds: string[], options: import('@tanstack/react-query').QueryOptions}} options
 * @return {import('@tanstack/react-query').UseQueryResult<TextualAssessmentResponse>[]}
 */
export const useMultipleAssessments = ({ businessPartnerIds, options }) => {
  const requests = businessPartnerIds.map((businessPartnerId) => ({
    path: `/businesspartners/${businessPartnerId}/assessments`,
    keys: ['businesspartners', businessPartnerId, 'assessments'],
  }))
  const responses = useRequestsMemo(useRequests({ requests, options }))

  return useMemo(
    () =>
      responses.map((result) => ({
        ...result,
        data: camelize(result.data),
      })),
    [responses],
  )
}
