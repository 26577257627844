import camelize from 'camelize'
import { useRequest } from 'hooks/services/baseService'

const useDocumentsAllowedOperations = () =>
  camelize(
    useRequest({
      path: '/documents/allowed-operations',
      useCache: true,
      keys: ['documents', 'permissions'],
    }),
  )

export default useDocumentsAllowedOperations
