import { useMutation } from '@tanstack/react-query'
import snakeCase from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateKpiAdjustment = ({ onSuccess, onError }) => {
  const { patch: patchKpiAdjustment } = useAccessTokenRequest()

  return useMutation(
    async ({ adjustmentId, adjustment = {} }) => {
      await patchKpiAdjustment({
        path: `/kpis/adjustments/${adjustmentId}`,
        body: snakeCase(adjustment),
        options: { enabled: !!adjustmentId },
      })
    },
    { onSuccess, onError },
  )
}

export default useUpdateKpiAdjustment
