import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { DateTime } from 'luxon'

const addLegalFormToMap = (legalForm, legalFormMap) => {
  if (isNil(legalForm)) {
    return legalFormMap
  }
  return legalForm in legalFormMap
    ? {
        ...legalFormMap,
        [legalForm]: legalFormMap[legalForm] + 1,
      }
    : {
        ...legalFormMap,
        [legalForm]: 1,
      }
}

export const getLegalDataAndFoundationDatesFromMembers = (members) =>
  members?.reduce(
    ({ legalFormMap, foundationDatesMap }, member) => {
      if (isEmpty(member.legalForm) && isEmpty(member.foundationDate)) {
        return { legalFormMap, foundationDatesMap }
      }
      const newLegalFormMap = addLegalFormToMap(member.legalForm, legalFormMap)

      if (isNil(member.foundationDate)) {
        return {
          legalFormMap: newLegalFormMap,
          foundationDatesMap,
        }
      }
      if (isNil(foundationDatesMap.min) && isNil(foundationDatesMap.max)) {
        return {
          legalFormMap: newLegalFormMap,
          foundationDatesMap: { min: member.foundationDate, max: member.foundationDate },
        }
      }
      const currentMin = DateTime.fromISO(foundationDatesMap.min)
      const currentMax = DateTime.fromISO(foundationDatesMap.max)
      const currentFoundationDate = DateTime.fromISO(member.foundationDate)

      const min =
        currentMin >= currentFoundationDate ? member.foundationDate : foundationDatesMap.min
      const max =
        currentMax <= currentFoundationDate ? member.foundationDate : foundationDatesMap.max

      return { legalFormMap: newLegalFormMap, foundationDatesMap: { min, max } }
    },
    { legalFormMap: {}, foundationDatesMap: {} },
  )

export const convertLegalDataObjectToString = (legalDataObject) => {
  if (isEmpty(legalDataObject)) {
    return undefined
  }
  const entries = Object.entries(legalDataObject)
  const [firstLegalForm, firstCount] = entries[0]
  if (entries.length === 1 && firstCount === 1) {
    return firstLegalForm
  }
  return entries
    .sort(([firstKey], [secondKey]) => {
      if (firstKey < secondKey) {
        return -1
      }
      // Its not possible that two keys are equal so this is ugly but correct
      return 1
    })
    .map(([key, value]) => `${value} ${key}`)
    .join(', ')
}
