import { Link } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/cells/BusinessPartnerCell.module.css'
import DecisionStageLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/decision-stage-leave-confirmation/DecisionStageLeaveConfirmationLink'

const BusinessPartnerCell = ({
  value,
  hasLeftMargin = false,
  shouldBeMarkedAsBorrower = false,
  showConfirmationForLinks = false,
}) => {
  const { t: tDecisionPaper } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.shared.authority-level-calculator.table',
  })

  return (
    <div
      className={[styles.columnFlexBox, hasLeftMargin ? styles.leftMargin : ''].join(' ').trim()}
    >
      <div>
        {showConfirmationForLinks ? (
          <DecisionStageLeaveConfirmationLink
            name={value?.name}
            link={`/business-partners/${value?.id}`}
          />
        ) : (
          <Link
            className={styles.boldFont}
            href={`/business-partners/${value?.id}`}
            target="_blank"
          >
            {value?.name}
          </Link>
        )}
        {shouldBeMarkedAsBorrower && <span>{` (${tDecisionPaper('borrower')})`}</span>}
      </div>
      <span>{value?.id}</span>
    </div>
  )
}

BusinessPartnerCell.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  hasLeftMargin: PropTypes.bool,
  shouldBeMarkedAsBorrower: PropTypes.bool,
  showConfirmationForLinks: PropTypes.bool,
}

export default BusinessPartnerCell
