import {
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { decisionProcessConditionsDecisionStatus } from 'api/decision-process/decisionProcessConditionsApi'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import DecisionStageConditionsApprovalContent from 'components/domains/business-events-and-tasks/decision-stage/conditions/approval/DecisionStageConditionsApprovalContent'
import DecisionStageConditionsApprovalHeader from 'components/domains/business-events-and-tasks/decision-stage/conditions/approval/DecisionStageConditionsApprovalHeader'
import TabPage from 'components/ui/page/TabPage'
import useDecisionStageConditionsApproval from 'hooks/services/business-events-and-tasks/decision-process/stages/conditions/approval/useDecisionStageConditionsApproval'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionStageConditionsApproval = () => {
  const {
    event: { id: eventId },
    allowedOperations,
  } = useContext(BusinessEventsAndTasksContext)
  const { decisionStageId, voterId } = useParams()
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-stage.conditions.approval',
  })

  const hasVoteUpdatePermission = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.updateDecisionStageVote),
    [allowedOperations],
  )
  const {
    isLoading,
    isError,
    data: decisionStageConditionsData,
  } = useDecisionStageConditionsApproval(
    { eventId, decisionStageId, voterId },
    { enabled: hasVoteUpdatePermission },
  )

  const decisionStageConditionsInWrongStatus =
    decisionStageConditionsData?.decisionStageConditionsData.info.decisionStatus ===
      decisionProcessConditionsDecisionStatus.pending ||
    decisionStageConditionsData?.decisionStageConditionsData.info.decisionStatus ===
      decisionProcessConditionsDecisionStatus.inProgressRequestDiscarded

  const userIsNotAVoter = !decisionStageConditionsData?.userVote

  const hideVoterPage =
    decisionStageConditionsInWrongStatus || userIsNotAVoter || !hasVoteUpdatePermission

  const content = useMemo(() => {
    if (isLoading || isError) {
      return <div />
    }
    if (decisionStageConditionsInWrongStatus) {
      return (
        <IllustratedMessage
          titleText={t('wrong-status.title')}
          subtitleText={t('wrong-status.subtitle')}
          name={IllustrationMessageType.NoTasks}
          size={IllustrationMessageSize.Scene}
        />
      )
    }
    if (userIsNotAVoter) {
      return (
        <IllustratedMessage
          titleText={t('wrong-user.title')}
          subtitleText={t('wrong-user.subtitle')}
          name={IllustrationMessageType.NoTasks}
          size={IllustrationMessageSize.Scene}
        />
      )
    }
    return (
      <DecisionStageConditionsApprovalContent
        decisionStage={decisionStageConditionsData.decisionStageData}
        decisionStageConditions={decisionStageConditionsData.decisionStageConditionsData}
        voterStatusCode={decisionStageConditionsData.userVote?.status.code}
        decisionStageId={decisionStageId}
      />
    )
  }, [
    isLoading,
    isError,
    decisionStageConditionsInWrongStatus,
    userIsNotAVoter,
    decisionStageConditionsData,
    t,
    decisionStageId,
  ])

  if (!hasVoteUpdatePermission) {
    return (
      <IllustratedMessage
        titleText={tNoPrefix('app.permission-error.title')}
        subtitleText={tNoPrefix('app.permission-error.subtitle')}
        name={IllustrationMessageType.UnableToLoad}
        size={IllustrationMessageSize.Spot}
      />
    )
  }

  return (
    <TabPage
      isLoading={isLoading}
      isError={isError}
      isEmpty
      headerTitle={
        <DecisionStageConditionsApprovalHeader
          hideVoterPage={hideVoterPage}
          decisionStageConditionsVoterId={decisionStageConditionsData?.userVote?.id}
          voterStatusCode={decisionStageConditionsData?.userVote?.status.code}
        />
      }
    >
      {content}
    </TabPage>
  )
}

DecisionStageConditionsApproval.propTypes = {}

export default DecisionStageConditionsApproval
