import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'

/**
 * There only exist two data sources, new and existing business.
 * However, the financial products table needs to distinguish between "live" and "working version" new business receivables.
 * So the working version type also basically represents new business, but for receivables that are assigned to working version deals.
 */
export const FINANCIAL_PRODUCT_BUSINESS_TYPES = {
  ...DATA_SOURCES,
  BANK_CUSTOMER_ACCOUNTS: 'bankCustomerAccounts',
  ALL: 'all',
}
