import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useGetFinancingStatusCodes = () =>
  useCamelizedResponse(
    useRequest({
      path: '/properties/financing-status-codes',
      keys: ['properties', 'financing-status-codes'],
    }),
  )

export default useGetFinancingStatusCodes
