import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import { useEffect, useMemo, useState } from 'react'
import { decisionPaperVersion } from 'api/decision-paper/decisionPaperApi'

export const imageSourceRegex = /src="([^"]*)"/gm

export const getSanitizedCharactersCount = (currentContent) => {
  const matches = currentContent.match(imageSourceRegex)
  const charactersToRemove =
    matches?.reduce((currentValue, sourceMatch) => currentValue + sourceMatch.length, 0) ?? 0

  return currentContent.length - charactersToRemove
}

export const getRawCharactersCount = (currentContent) => {
  const parsedText = new DOMParser().parseFromString(currentContent, 'text/html')
  return (parsedText.body.textContent || '').length
}

export const getContentFromTileStatus = ({ tileStatus, currentDecisionPaperVersion }) => {
  if (isNil(tileStatus)) {
    return ''
  }
  const displayedVersion = tileStatus?.displayedVersion
  if (
    isNil(displayedVersion) ||
    displayedVersion === 'current' ||
    currentDecisionPaperVersion !== decisionPaperVersion.workingVersion
  ) {
    return tileStatus.data?.data?.text ?? ''
  }
  const versionData = tileStatus.versions.find(({ version }) => version === displayedVersion)
  return versionData?.data?.text ?? ''
}

const useTextEditorCurrentContent = (initialContent) => {
  const content = initialContent ?? ''
  const [currentContent, setCurrentContent] = useState(content)

  useEffect(() => {
    setCurrentContent(content)
  }, [content])

  const hasChanges = useMemo(() => !isEqual(currentContent, content), [currentContent, content])

  return useMemo(
    () => ({
      currentContent,
      setCurrentContent,
      hasChanges,
    }),
    [currentContent, hasChanges],
  )
}

export default useTextEditorCurrentContent
