import { ObjectStatus, Popover } from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import PropTypes from 'prop-types'
import { useCallback, useId, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import EntityWithStatus from 'components/domains/business-partners/tile/tenancy/EntityWithStatus'
import styles from 'components/domains/business-partners/tile/tenancy/tenant-unit-table/table-cells/TenancyDealCell.module.css'
import Link from 'components/ui/link/Link'

const tenancyDealCellPropTypes = {
  cell: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.shape({
        dealUuid: PropTypes.string.isRequired,
        dealId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
}

const getDealEntity = ({ deal: { dealId, name, status }, className }) => (
  <EntityWithStatus
    key={dealId}
    className={className}
    name={name}
    id={dealId}
    link={`/deals/${dealId}`}
    openInNewTab={true}
    status={
      <ObjectStatus
        inverted
        state={status ? ValueState.Information : ValueState.None} // HINT: copied from DealSearchTable
      >
        {status}
      </ObjectStatus>
    }
  />
)

/**
 * @param {PropTypes.InferProps<typeof tenancyDealCellPropTypes>} props
 */
const TenancyDealCell = ({ cell: { value: deals } }) => {
  const id = useId()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.tenancy.as-branding-franchise-provider',
  })
  const [isOpen, setIsOpen] = useState(false)
  const onPopoverClose = useCallback(() => setIsOpen(false), [])
  const onOpenerLinkClick = useCallback((e) => {
    e.preventDefault()
    setIsOpen(true)
  }, [])

  if (!deals?.length) {
    return
  }

  if (deals.length === 1) {
    return getDealEntity({ deal: deals[0], className: styles.plainEntity })
  }

  const foundXDeals = t('found-x-deals', { numberOfDeals: deals.length })
  const openerId = `multiple-deals-${id}`

  return (
    <>
      <Link id={openerId} className="sapFontBoldFamily" onClick={onOpenerLinkClick}>
        {foundXDeals}
      </Link>
      {createPortal(
        <Popover
          className={`${styles.popover} ${styles.popoverLink}`}
          placementType="Right"
          verticalAlign="Center"
          open={isOpen}
          opener={openerId}
          headerText={foundXDeals}
          horizontalAlign="Center"
          onAfterClose={onPopoverClose}
        >
          <div className={styles.detailListContainer}>
            {deals.map((deal) => getDealEntity({ deal, className: styles.detailListEntities }))}
          </div>
        </Popover>,
        document.body,
      )}
    </>
  )
}

TenancyDealCell.propTypes = tenancyDealCellPropTypes

export default TenancyDealCell
