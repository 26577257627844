import PropTypes from 'prop-types'
import { useCallback, useMemo, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { conditionTypes, conditionsStatusTypes } from 'api/conditions/conditions'
import TableStatusCell from 'components/domains/conditions/overview/table-cells/view/TableStatusCell'
import useDeprecatedValues from 'hooks/common/useDeprecatedValues'
import useExternalStatus from 'hooks/services/conditions/config/useExternalStatus'
import useInternalStatus from 'hooks/services/conditions/config/useInternalStatus'
import useConditionsCellEditMode from 'hooks/services/conditions/edit-mode/useConditionsCellEditMode'
import { conditionStatusChanged } from 'redux/slices/conditions/conditionsTableSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const ConditionsTableStatusCell = ({
  value: {
    conditionId,
    status: { type: statusType, code: statusCode, name: statusName },
    requirements,
  },
  conditionType,
}) => {
  const dispatch = useDispatch()
  const {
    entityRef: { entityType },
  } = useContext(ConditionsContext)
  const editedConditionStatusCode = useSelector(
    (state) => state.conditions.conditionsTable.editedRow.currentValues?.status.code,
  )

  const { data: { status: avialableExternalStatusValues = [] } = {}, ...useExternalStatusResult } =
    useExternalStatus(
      {
        includeDeprecated: false,
        entityType,
      },
      { enabled: conditionType === conditionTypes.external },
    )

  const { data: { status: avialableInternalStatusValues = [] } = {}, ...useInternalStatusResult } =
    useInternalStatus(
      {
        includeDeprecated: false,
        entityType,
      },
      { enabled: conditionType === conditionTypes.internal },
    )

  const [avialableStatusValues, useStatusResult] = useMemo(
    () =>
      conditionType === conditionTypes.external
        ? [avialableExternalStatusValues, useExternalStatusResult]
        : [avialableInternalStatusValues, useInternalStatusResult],
    [
      avialableExternalStatusValues,
      avialableInternalStatusValues,
      conditionType,
      useExternalStatusResult,
      useInternalStatusResult,
    ],
  )

  const { deprecatedValues } = useDeprecatedValues({
    availableValues: avialableStatusValues,
    selectedValues: [{ name: statusName, code: statusCode }],
  })
  const statusData = useMemo(
    () => ({
      ...useStatusResult,
      data: {
        status: [...deprecatedValues, ...avialableStatusValues],
      },
    }),
    [avialableStatusValues, deprecatedValues, useStatusResult],
  )
  const statusLoader = useCallback(() => statusData, [statusData])

  const { isEditModeForCurrentRow } = useConditionsCellEditMode({
    conditionId,
  })
  const onChangeSelection = useCallback(
    (newStatusCode, newStatusType) => {
      dispatch(conditionStatusChanged({ status: { code: newStatusCode, type: newStatusType } }))
    },
    [dispatch],
  )

  return (
    <TableStatusCell
      showEmptyOption={false}
      statusType={statusType}
      statusName={statusName}
      editedStatusCode={editedConditionStatusCode}
      requirements={requirements}
      loadingHook={statusLoader}
      onChange={onChangeSelection}
      isEditMode={isEditModeForCurrentRow}
    />
  )
}

ConditionsTableStatusCell.propTypes = {
  value: PropTypes.shape({
    conditionId: PropTypes.string.isRequired,
    status: PropTypes.shape({
      type: PropTypes.oneOf(Object.values(conditionsStatusTypes)).isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }).isRequired,
    requirements: PropTypes.shape({
      total: PropTypes.number.isRequired,
      done: PropTypes.number.isRequired,
    }),
  }).isRequired,
  conditionType: PropTypes.string.isRequired,
}

export default ConditionsTableStatusCell
