import { FlexBox, FlexBoxAlignItems, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styles from 'components/domains/properties/rent-roll/comparison/RentRollKeyDatesDropdown.module.css'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import usePortfolioHistoricalRentRollKeyDates from 'hooks/services/properties/rent-roll/usePortfolioHistoricalRentRollKeyDates'
import { setRentRollToCompare } from 'redux/slices/properties/compareRentRollSlice'

const RentRollKeyDatesDropdown = ({ propertyUuids }) => {
  const dispatch = useDispatch()
  const { format: formatDate } = useShortDateFormatter()
  const { t: tComparison } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.comparison',
  })

  const { data: rentRollComparisonOptions } = usePortfolioHistoricalRentRollKeyDates({
    propertyUuids,
    deduplicateKeyDates: true,
  })

  const [indexOfRentRollToCompare, setIndexOfRentRollToCompare] = useState(0)

  useEffect(() => {
    const selectedOption =
      rentRollComparisonOptions?.dates?.find(
        (option) => option.index === indexOfRentRollToCompare,
      ) ?? null
    dispatch(setRentRollToCompare(selectedOption))
  }, [dispatch, indexOfRentRollToCompare, rentRollComparisonOptions])
  return (
    <FlexBox alignItems={FlexBoxAlignItems.Center}>
      <Label className={styles.comparisonRentRollLabel} showColon>
        {tComparison('comparison-key-date')}
      </Label>
      <LoadingSelect
        className={styles.rentRollKeyDatesDropdown}
        onChange={(event) => {
          setIndexOfRentRollToCompare(event.detail.selectedOption.dataset.id)
        }}
        loadingHook={usePortfolioHistoricalRentRollKeyDates}
        loadingHookParams={{ propertyUuids, deduplicateKeyDates: true }}
        selectionName="dates"
        optionKeyName="index"
        optionDisplayName="keyDate"
        selectedKey={indexOfRentRollToCompare}
        formatDisplayName={formatDate}
      />
    </FlexBox>
  )
}
RentRollKeyDatesDropdown.propTypes = {
  propertyUuids: PropTypes.arrayOf(PropTypes.string),
}
export default RentRollKeyDatesDropdown
