import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useBooleanToTextFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const BooleanSelect = ({ value, onChange }) => {
  const formatBoolean = useBooleanToTextFormatter()

  const handleOnChange = useCallback(
    (event) => {
      const newValue = event.detail.selectedOption.value
      const newBoolValue = isEmpty(newValue) ? null : newValue === 'true'
      onChange(newBoolValue)
    },
    [onChange],
  )

  return (
    <Select onChange={handleOnChange}>
      <Option value={''} selected={isNil(value)}>
        {''}
      </Option>
      <Option value={'true'} selected={value === true}>
        {formatBoolean(true)}
      </Option>
      <Option value={'false'} selected={value === false}>
        {formatBoolean(false)}
      </Option>
    </Select>
  )
}

BooleanSelect.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default BooleanSelect
