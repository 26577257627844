import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import TableWrappingTextCell from 'components/domains/conditions/overview/table-cells/generic/TableWrappingTextCell'
import ConditionDealExternalAssigneeSelect from 'components/domains/conditions/shared/assignee/ConditionDealExternalAssigneeSelect'
import useConditionsCellEditMode from 'hooks/services/conditions/edit-mode/useConditionsCellEditMode'
import { conditionExternalAssigneeChanged } from 'redux/slices/conditions/conditionsTableSlice'

const ConditionsTableDealExternalAssigneeSubcomponent = ({ conditionId, externalAssignee }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.subcomponents.external-assignee',
  })
  const dispatch = useDispatch()
  const { isEditModeForCurrentRow } = useConditionsCellEditMode({
    conditionId,
  })

  const handleOnAssigneeChange = useCallback(
    (newExternalAssignee) => {
      dispatch(conditionExternalAssigneeChanged({ externalAssignee: newExternalAssignee }))
    },
    [dispatch],
  )

  return (
    <div>
      <Label>{t('label')}</Label>
      <div>
        {isEditModeForCurrentRow ? (
          <ConditionDealExternalAssigneeSelect
            externalAssignee={externalAssignee?.name || externalAssignee?.email}
            onAssigneeChange={handleOnAssigneeChange}
          />
        ) : (
          <TableWrappingTextCell value={externalAssignee?.name || externalAssignee?.email} />
        )}
      </div>
    </div>
  )
}

ConditionsTableDealExternalAssigneeSubcomponent.propTypes = {
  conditionId: PropTypes.string.isRequired,
  externalAssignee: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string.isRequired,
  }),
}

export default ConditionsTableDealExternalAssigneeSubcomponent
