import { BusyIndicatorSize, TableRowType, Text } from '@fioneer/ui5-webcomponents-react'
import orderBy from 'lodash.orderby'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import AdjustmentStatusWithWarnings from 'components/domains/deals/change-overview/adjustments-history-card/AdjustmentStatusWithWarnings'
import styles from 'components/domains/deals/change-overview/adjustments-history-card/DealAdjustmentHistoryTable.module.css'
import EventsReferenceEntitiesView from 'components/domains/deals/change-overview/adjustments-history-card/EventsReferenceEntitiesView'
import LoadingEventEntity from 'components/domains/deals/covenants/shared/LoadingEventEntity'
import ReferenceEntitiesView, { entitiesQuantity } from 'components/ui/data/ReferenceEntitiesView'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import StaffMemberByObjectIdOrEmail from 'components/ui/staff-member/StaffMemberByObjectIdOrEmail'
import TextEllipsis from 'components/ui/text/TextEllipsis'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { useAdjustmentTypesConfig } from 'hooks/services/deals/deal-adjustment/useAdjustmentTypesConfig'

const useDealAdjustmentHistoryTableData = ({ data }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.change-overview.adjustment-history',
  })
  const { format: formatDate } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })

  const {
    data: { adjustmentTypes } = {},
    isLoading: isAdjustmentTypesLoading,
    isError: isAdjustmentTypesError,
  } = useAdjustmentTypesConfig()

  const getAdjustmentTypeTranslation = useCallback(
    (type) =>
      adjustmentTypes?.find((adjustmentType) => adjustmentType.code === type)?.displayName ?? type,
    [adjustmentTypes],
  )

  const orderedData = orderBy([...data], 'createdAt', 'desc')

  return orderedData.map(
    (
      {
        adjustmentUuid,
        events,
        comment,
        types,
        lastUpdatedAt,
        lastUpdatedBy,
        status,
        dealStatus,
        warnings,
      },
      index,
    ) => {
      const adjustmentIndex = orderedData.length - index
      let adjustmentTypeProps = { quantity: entitiesQuantity.none }
      if (types.length > 1) {
        adjustmentTypeProps = {
          quantity: entitiesQuantity.multiple,
          linkText: t('adjustment-types.link'),
          listItems: types.map((type) => ({ name: getAdjustmentTypeTranslation(type) })),
          disableLink: true,
          entityClassName: styles['normal-font-weight'],
        }
      } else if (types.length === 1) {
        adjustmentTypeProps = {
          quantity: entitiesQuantity.single,
          name: getAdjustmentTypeTranslation(types[0]),
          entityClassName: styles['normal-font-weight'],
        }
      }
      return {
        rowKey: adjustmentUuid,
        rowProperties: {
          type: TableRowType.Inactive,
          className: warnings.length ? styles.borderLeft : undefined,
        },
        index: {
          value: adjustmentIndex,
          cellComponent: <Text>{adjustmentIndex}</Text>,
        },
        event: {
          value: events.primary,
          cellComponent: events.primary ? (
            <LoadingEventEntity eventId={events.primary} />
          ) : (
            t('no-event')
          ),
        },
        'further-events': {
          value: events.primary,
          cellComponent: events.other?.length ? (
            <EventsReferenceEntitiesView eventIds={events.other} />
          ) : (
            <></>
          ),
        },
        'adjustment-type': {
          value: types,
          cellComponent: (
            <RequestStateResolver
              isLoading={isAdjustmentTypesLoading}
              busyIndicatorSize={BusyIndicatorSize.Small}
              isError={isAdjustmentTypesError}
              errorToDisplay={<ErrorDataUnavailableInCell />}
              renderContent={() => <ReferenceEntitiesView {...adjustmentTypeProps} />}
            />
          ),
        },
        status: {
          value: status,
          cellComponent: <AdjustmentStatusWithWarnings status={status} warnings={warnings} />,
        },
        'deal-status': {
          value: dealStatus,
          cellComponent: <Text>{dealStatus}</Text>,
        },
        comment: {
          value: comment,
          cellComponent: <TextEllipsis content={comment} maxShortenedContentDisplaySize={150} />,
        },
        'last-updated-at': {
          value: lastUpdatedAt,
          cellComponent: <Text>{formatDate(lastUpdatedAt)}</Text>,
        },
        'last-updated-by': {
          value: lastUpdatedBy,
          cellComponent: <StaffMemberByObjectIdOrEmail objectIdOrEmail={lastUpdatedBy} />,
        },
      }
    },
  )
}

export default useDealAdjustmentHistoryTableData
