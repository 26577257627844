import {
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
  Option,
  Select,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CardSection from 'components/domains/deals/card/CardSection'
import KpiCardHeader from 'components/domains/kpis/KpiCardHeader'
import styles from 'components/domains/kpis/KpiTimeSeriesCard.module.css'
import { getKpiForestFromKpiList, getTimeSeriesForKpi } from 'components/domains/kpis/KpiUtils'
import KpiTimeSeriesChart from 'components/domains/kpis/chart/KpiTimeSeriesChart'
import Card from 'components/ui/card/Card'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useKpis from 'hooks/services/kpis/useKpis'

const KpiOverviewChartCard = ({ referenceEntityType, referenceEntityId, kpiId }) => {
  const { t } = useTranslation('translation')

  const {
    data: { kpis: kpiList = [] } = {},
    isError,
    isFetching,
  } = useKpis(referenceEntityType, referenceEntityId)

  const [selectedKpiCode, setSelectedKpiCode] = useState()

  const kpiForest = useMemo(() => {
    const mainForest = getKpiForestFromKpiList(kpiList)
    if (kpiId) {
      const childForest = []
      const kpiTree = mainForest.find(({ id }) => id === kpiId)
      childForest.push(kpiTree)
      kpiTree?.children.forEach((it) => childForest.push(it))
      return childForest
    }

    return mainForest.filter(({ displayInOverview }) => displayInOverview === true)
  }, [kpiList, kpiId])

  const selectedKpi = useMemo(
    () => kpiForest.find((it) => it.code === selectedKpiCode),
    [kpiForest, selectedKpiCode],
  )

  useEffect(() => {
    if (selectedKpiCode === undefined) {
      setSelectedKpiCode(kpiForest?.[0]?.code)
    }
  }, [kpiForest, selectedKpiCode, setSelectedKpiCode])

  const timeSeries = useMemo(() => getTimeSeriesForKpi(selectedKpi), [selectedKpi])
  const thresholds = useMemo(() => selectedKpi?.thresholds ?? [], [selectedKpi])
  const unit = useMemo(() => selectedKpi?.unit, [selectedKpi])

  const kpiSelectionOptions = useMemo(
    () => kpiForest.map((kpi) => ({ kpiCode: kpi.code, name: kpi.name })),
    [kpiForest],
  )
  const onSelectionChange = (code) => setSelectedKpiCode(code)

  return (
    <Card
      header={
        !isFetching &&
        !isError &&
        !isEmpty(selectedKpi) && (
          <KpiCardHeader
            kpi={selectedKpi}
            title={t('components.kpis.chart.title')}
            actions={[
              <div key={'select'}>
                <Select
                  onChange={(event) => onSelectionChange(event.detail.selectedOption.value)}
                  className={styles.minWidthSelect}
                >
                  {kpiSelectionOptions.map(({ kpiCode, name }) => (
                    <Option key={kpiCode} value={kpiCode} selected={kpiCode === selectedKpi?.code}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </div>,
            ]}
          />
        )
      }
    >
      <RequestStateResolver
        isLoading={isFetching}
        isError={isError}
        center
        renderContent={() => (
          <CardSection>
            {timeSeries.length ? (
              <KpiTimeSeriesChart
                kpiTimeSeries={timeSeries}
                thresholds={thresholds}
                unit={unit}
                detailed={!!kpiId}
              />
            ) : (
              <EmptyCardContent
                illustrationName={IllustrationMessageType.UnableToLoad}
                size={IllustrationMessageSize.Scene}
              />
            )}
          </CardSection>
        )}
        errorToDisplay={
          <IllustratedMessage
            name={IllustrationMessageType.UnableToLoad}
            titleText={t('app.loading.error.data-unavailable')}
          />
        }
      />
    </Card>
  )
}

KpiOverviewChartCard.propTypes = {
  referenceEntityType: PropTypes.string.isRequired,
  referenceEntityId: PropTypes.string.isRequired,
  kpiId: PropTypes.string,
}

export default KpiOverviewChartCard
