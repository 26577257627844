import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'

const useMultipleBusinessPartnerRelationships = (businessPartnerIds) => {
  const results = useRequests({
    requests: businessPartnerIds.map((businessPartnerId) => ({
      path: `/businesspartners/${businessPartnerId}/relationships`,
      keys: ['businesspartners', businessPartnerId, 'relationships'],
    })),
    translated: true,
    options: {
      enabled: businessPartnerIds.length > 0,
    },
  })

  return useMemo(
    () =>
      results.map((result) => ({
        ...result,
        data: camelize(result.data),
      })),
    [results],
  )
}

export default useMultipleBusinessPartnerRelationships
