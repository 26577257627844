export const pbbCustomServicePermissions = {
  CreditExposureDelete: 'Credit_Exposure_Delete',
  CreditExposureRead: 'Credit_Exposure_Read',
  pbbAuthorityLevelCalculatorCommentRead: 'PBB_AuthorityLevelCalculatorComment_Read',
  pbbAuthorityLevelCalculatorCommentUpdate: 'PBB_AuthorityLevelCalculatorComment_Update',
  pbbAuthorityLevelCalculatorRead: 'PBB_AuthorityLevelCalculator_Read',
  pbbAuthorityLevelCalculatorResetUpdate: 'PBB_AuthorityLevelCalculatorReset_Update',
  pbbAuthorityLevelCalculatorUpdate: 'PBB_AuthorityLevelCalculator_Update',
  pbbCapitalGrowthIndexUpdate: 'PBBCapitalGrowthIndex_Update',
  pbbCashflowUpdate: 'PBBCashflow_Update',
  pbbConfigurationRead: 'PBB_Configuration_Read',
  pbbConfigurationUpdate: 'PBB_Configuration_Update',
  pbbPricingUpdate: 'PBBPricing_Update',
  pbbTenancyShareCalculation: 'PBB_TenancyShareCalculation',
  pbbWindowsUserInformationRead: 'PBBWindowsUserInformation_Read',
}
