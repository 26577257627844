import dayjs from 'dayjs'

const useGetMaturityData = (data) => {
  if (!data || !data.maturityDate) {
    return {}
  }

  const selector = data.rawValues ? 'rawValues' : 'values'

  const maturityDate = dayjs(data.maturityDate)
  const maturityDataIndex = data[selector]?.findIndex((item) => maturityDate.isBefore(item.endDate))

  const beforeMaturityDateValues = data[selector][maturityDataIndex - 1]
  const afterMaturityDateValues = data[selector][maturityDataIndex]

  const maturityData =
    Math.abs(maturityDate.diff(beforeMaturityDateValues?.endDate)) >
    Math.abs(maturityDate.diff(afterMaturityDateValues?.endDate))
      ? afterMaturityDateValues
      : beforeMaturityDateValues

  return { maturityData }
}

export default useGetMaturityData
