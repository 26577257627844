import camelize from 'camelize'
import { useRequests } from 'hooks/services/baseService'

const useMarketsByIds = (ids) => {
  const result = useRequests({
    requests: ids.map((id) => ({
      path: `/markets/${id}`,
      keys: ['markets', id, `getAllKpiValues=${false}`, `filterKpisForMonitoring=${false}`], // copy-paste from useMarket
    })),
    translated: true,
    useCache: true,
  })
  return camelize(result)
}

export default useMarketsByIds
