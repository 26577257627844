import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import { Input, Label, ListItemType, SuggestionItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EventEntityRefNameSearchSuggestions from 'components/domains/business-events-and-tasks/events/input/EventEntityRefNameSearchSuggestions'

const EventEntityRefNameSearch = ({
  inputTitle,
  loadingText,
  errorText,
  typeMoreText,
  emptyResultsText,
  required,
  entityType,
  preselectedEntity,
  onEntityRefNameSelected = () => {},
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.entity-suggestions',
  })

  const [currentSearchValue, setCurrentSearchValue] = useState('')

  useEffect(() => {
    const resetSearchValueOnEntityTypeChange = () => {
      setCurrentSearchValue('')
      onEntityRefNameSelected()
    }
    if (!preselectedEntity) {
      resetSearchValueOnEntityTypeChange()
    }
  }, [entityType, onEntityRefNameSelected, preselectedEntity])

  const renderSuggestions = () => {
    if (!entityType) {
      return <div />
    }
    // eslint-disable-next-line no-magic-numbers
    if (!currentSearchValue || currentSearchValue.length < 3) {
      return <SuggestionItem text={typeMoreText} type={ListItemType.Inactive} />
    }
    return (
      <EventEntityRefNameSearchSuggestions
        entityType={entityType}
        loadingText={loadingText}
        errorText={errorText}
        emptyResultsText={emptyResultsText}
        currentSearchValue={currentSearchValue}
      />
    )
  }

  const onSuggestionItemSelect = ({ detail: { item: selectedSuggestion } }) => {
    const entityId = selectedSuggestion.getAttribute('data-entity-id')
    const isWorkingVersion = selectedSuggestion.getAttribute('data-is-working-version') === 'true'
    const displaySearchValue = selectedSuggestion.text
    setCurrentSearchValue(displaySearchValue)
    onEntityRefNameSelected(entityId, isWorkingVersion)
  }

  const onSearchValueChanged = ({ target: { value: search } }) => {
    setCurrentSearchValue(search)
    if (!search) {
      onEntityRefNameSelected()
    }
  }

  const preselectedEntityName = useMemo(() => {
    if (!preselectedEntity) {
      return
    }
    if (preselectedEntity.isWorkingVersion) {
      return t('working-version', {
        entityName: preselectedEntity.entityName,
        entityDisplayId: preselectedEntity.entityDisplayId,
      })
    }
    return t('non-working-version', {
      entityName: preselectedEntity.entityName,
      entityDisplayId: preselectedEntity.entityDisplayId,
    })
  }, [preselectedEntity, t])

  return (
    <>
      <Label showColon required={required}>
        {inputTitle}
      </Label>
      {preselectedEntity ? (
        <Input value={preselectedEntityName} disabled />
      ) : (
        <Input
          noTypeahead
          disabled={!entityType}
          placeholder={inputTitle}
          showSuggestions
          showClearIcon
          onInput={onSearchValueChanged}
          value={currentSearchValue}
          onSuggestionItemSelect={onSuggestionItemSelect}
        >
          {renderSuggestions()}
        </Input>
      )}
    </>
  )
}

EventEntityRefNameSearch.propTypes = {
  entityType: PropTypes.string,
  preselectedEntity: PropTypes.shape({
    entityName: PropTypes.string,
    entityId: PropTypes.string,
    entityDisplayId: PropTypes.string,
    entityType: PropTypes.string,
    isWorkingVersion: PropTypes.bool,
  }),
  inputTitle: PropTypes.string.isRequired,
  loadingText: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
  emptyResultsText: PropTypes.string.isRequired,
  typeMoreText: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  onEntityRefNameSelected: PropTypes.func,
}

export default EventEntityRefNameSearch
