// TODO: luxon should be used instead of dayjs
import dayjs from 'dayjs'
import isEmpty from 'lodash.isempty'
import sortBy from 'lodash.sortby'

const mapArrearApprovalsData = ({ borrowerId, multipleArrearApprovalsData }) => {
  const arrearApprovalsData = multipleArrearApprovalsData.find(
    (data) => data.data.businessPartnerId === borrowerId,
  ).data

  if (isEmpty(arrearApprovalsData.arrearApprovals)) {
    return { hasEventNotOlderThanTwelveMonths: null }
  }

  const createdAt = sortBy(arrearApprovalsData.arrearApprovals, 'createdAt')[
    arrearApprovalsData.arrearApprovals.length - 1
  ].createdAt
  const creationDate = dayjs(Date.parse(createdAt))
  const todayTwelveMonthsAgoDate = dayjs().subtract(1, 'year')
  return {
    hasEventNotOlderThanTwelveMonths: creationDate > todayTwelveMonthsAgoDate,
  }
}

export default mapArrearApprovalsData
