import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useConditionComments = ({ conditionId, conditionType }) =>
  useCamelizedResponse(
    useRequest({
      path: `/conditions/${conditionType}/${conditionId}/comments`,
      useCache: true,
      keys: ['conditions', conditionType, conditionId, 'comments'],
    }),
  )

export default useConditionComments
