import { useExternalIdTypeConfigValues } from 'hooks/services/properties/external-ids/useExternalIdTypeConfigValues'
import { usePropertyExternalIds } from 'hooks/services/properties/external-ids/usePropertyExternalIds'

/**
 * Gets backshop deals for a property
 * @param { propertyUuid } - property uuid
 * @returns backshopDealIds - list of backshop deals
 */
const useBackshopDealsForProperty = ({ propertyUuid }) => {
  const BACKSHOP_DEAL_ID = 'Backshop Deal ID'

  // finds related backshop deals
  const {
    isLoading: isLoadingExternalIds,
    isError: isErrorExternalIds,
    data: externalIdsData,
  } = usePropertyExternalIds({ propertyUuid })
  const externalIds = externalIdsData?.externalIds ?? []

  // gets config values for extranalIds
  const { data: externalIdTypeConfigValuesReturned } = useExternalIdTypeConfigValues()
  const externalIdTypeConfigValues = externalIdTypeConfigValuesReturned?.externalIdTypeConfigValues

  const externalIdConfigurationForBackshopDeal = externalIdTypeConfigValues?.find(
    (externalIdConfig) => externalIdConfig.type === BACKSHOP_DEAL_ID,
  )
  const backshopDealCode = externalIdConfigurationForBackshopDeal?.code ?? null
  const backshopDealIds =
    externalIds &&
    externalIds
      .filter((externalId) => externalId.extCode === backshopDealCode)
      .map((externalId) => externalId.id)

  return {
    backshopDealIds,
    isLoadingExternalIds,
    isErrorExternalIds,
  }
}

export default useBackshopDealsForProperty
