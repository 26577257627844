import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  ResponsiveGridLayout,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import BorrowerOverviewLabeledValue from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/borrower/BorrowerOverviewLabeledValue'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/borrower/BorrowerOverviewTile.module.css'

const BorrowerOverviewAddress = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.bp-borrower-overview.address',
  })
  const { data: tileData } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const { address } = tileData ?? {}

  const content = useMemo(() => {
    if (isNil(address)) {
      return (
        <FlexBox
          className={styles.listCardEmptyContent}
          alignItems={FlexBoxAlignItems.Center}
          justifyContent={FlexBoxJustifyContent.Center}
        >
          {t('empty')}
        </FlexBox>
      )
    }
    return (
      <ResponsiveGridLayout columnsXL={2} columnsL={2} columnsM={2} columnsS={2}>
        <FlexBox direction={FlexBoxDirection.Column}>
          {!isNil(address?.streetName) && (
            <BorrowerOverviewLabeledValue label={t('street')} value={address.streetName} />
          )}
          {!isNil(address?.postalCode) && (
            <BorrowerOverviewLabeledValue label={t('postal-code')} value={address.postalCode} />
          )}
          {!isNil(address?.city) && (
            <BorrowerOverviewLabeledValue label={t('city')} value={address.city} />
          )}
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column}>
          {!isNil(address?.country?.name) && (
            <BorrowerOverviewLabeledValue label={t('country')} value={address.country.name} />
          )}
          {!isNil(address?.region?.name) && (
            <BorrowerOverviewLabeledValue label={t('region')} value={address.region.name} />
          )}
        </FlexBox>
      </ResponsiveGridLayout>
    )
  }, [address, t])

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.listCard}>
      <Text className={styles.listCardTitle}>{t('title')}</Text>
      {content}
    </FlexBox>
  )
}

BorrowerOverviewAddress.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default BorrowerOverviewAddress
