import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/collaterals/StandardCollateralPackageTile.module.css'
import CollapsibleRichTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/collaterals/shared/CollapsibleRichTextView'

const CHARACTER_LIMIT = 1000
const NON_TRANSLATED_LABEL_TEXT = 'Deviation from standard:'
const NON_TRANSLATED_ON_TEXT = 'No'
const NON_TRANSLATED_OFF_TEXT = 'Yes'

const StandardCollateralPackageTile = ({ tileId }) => {
  const { data } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const deviationInformation = useMemo(
    () => (
      <div>
        <Text className={styles.text}>{NON_TRANSLATED_LABEL_TEXT}</Text>
        <Text>{data?.isStandardPackage ? NON_TRANSLATED_ON_TEXT : NON_TRANSLATED_OFF_TEXT}</Text>
      </div>
    ),
    [data],
  )

  const latestNote = useMemo(() => {
    if (!data?.notes?.length) return null

    const latestNoteVersion = data.notes.reduce(
      (prev, current) => (prev && prev.version > current.version ? prev : current),
      undefined,
    )
    return latestNoteVersion?.note
  }, [data])

  const textView = useMemo(
    () => (
      <div className={styles.borderDiv}>
        <CollapsibleRichTextView text={latestNote} characterLimit={CHARACTER_LIMIT} />
      </div>
    ),
    [latestNote],
  )

  return (
    <>
      {deviationInformation}
      {latestNote && textView}
    </>
  )
}

StandardCollateralPackageTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default StandardCollateralPackageTile
