import { isEqual, isNil } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import DecisionPaperManualTileTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/generic/DecisionPaperManualTileTable'
import { useNumberFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/shared/i18n/useI18n'
import useFactSheetValuationFactorAndScenariosEditRow from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/useFactSheetValuationFactorAndScenariosEditRow'

const PLACEHOLDER = '-'

const FactSheetValuationFactorAndScenarios = ({
  tileId,
  currentContent,
  onChange,
  isEditMode,
  isPdfView,
  setIsSaveEnabled,
  setHasContentChanges,
  rowAmount,
  selectOptions,
  columns,
}) => {
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const formatNumber = useNumberFormatter({ minimumFractionDigits: 2, maximumFractionDigits: 2 })

  useEffect(() => {
    const hasContentChanges =
      !isEqual(tileStatus?.data?.data, currentContent) &&
      !(isNil(currentContent) && isNil(tileStatus?.data?.data))
    setHasContentChanges(hasContentChanges)
  }, [currentContent, setHasContentChanges, tileStatus?.data?.data])

  const getOptionBySelectKey = useCallback(
    (key) => selectOptions.find((option) => option.key === key),
    [selectOptions],
  )

  const handleIsSaveEnabled = (selectedOption, content) => {
    const trimmedContent = typeof content === 'string' ? content.trim() : content
    const contentIsPresent = !!trimmedContent || trimmedContent === 0
    const optionIsSelected = !!selectedOption
    setIsSaveEnabled(
      (optionIsSelected && contentIsPresent) || (!optionIsSelected && !contentIsPresent),
    )
  }

  const getChangeSetProperty = (key, value, rowNumber) =>
    value !== undefined ? { [key]: value } : { [key]: currentContent?.[rowNumber]?.[key] }

  const handleOnChange = ({ option, content, label }, rowNumber) => {
    const changeSet = Object.assign(
      {},
      getChangeSetProperty('option', option, rowNumber),
      getChangeSetProperty('content', content, rowNumber),
      getChangeSetProperty('label', label, rowNumber),
    )
    onChange({
      ...currentContent,
      [rowNumber]: changeSet,
    })
    handleIsSaveEnabled(changeSet.option, changeSet.content)
  }

  const rowDataView = useMemo(() => {
    const rowData = []
    for (let i = 0; i < rowAmount; i++) {
      const rowValues = currentContent?.[i]
      const selectedRowOption = getOptionBySelectKey(rowValues?.option)
      const formattedDisplayValue = !isNaN(rowValues?.content)
        ? formatNumber(Number(rowValues?.content))
        : rowValues?.content
      const rowContent = formattedDisplayValue
        ? `${formattedDisplayValue} ${rowValues?.label || ''}`.trim()
        : undefined
      rowData.push([selectedRowOption?.displayValue || PLACEHOLDER, rowContent || PLACEHOLDER])
    }
    return rowData
  }, [currentContent, formatNumber, getOptionBySelectKey, rowAmount])

  const getEditRow = useFactSheetValuationFactorAndScenariosEditRow({
    handleOnChange,
    selectOptions,
    currentContent,
  })

  const rowDataEdit = useMemo(() => {
    const rowData = []
    for (let i = 0; i < rowAmount; i++) {
      const previousRowHasContent =
        currentContent?.[i - 1]?.content || !isNaN(currentContent?.[i - 1]?.content)
      const previousHasValue = i ? currentContent?.[i - 1]?.option && previousRowHasContent : true
      const selectedOption = currentContent?.[i]?.option
      const currentInputContent = currentContent?.[i]?.content
      if (previousHasValue || selectedOption || currentInputContent) {
        rowData.push(getEditRow(getOptionBySelectKey(selectedOption), currentInputContent, i))
      } else {
        rowData.push([PLACEHOLDER, PLACEHOLDER])
      }
    }
    return rowData
  }, [currentContent, getEditRow, getOptionBySelectKey, rowAmount])

  return (
    <DecisionPaperManualTileTable
      columnLabelsAndAlignments={columns}
      rowDataView={rowDataView}
      rowDataEdit={rowDataEdit}
      isEditMode={isEditMode && !isPdfView}
    />
  )
}

FactSheetValuationFactorAndScenarios.propTypes = {
  tileId: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  currentContent: PropTypes.shape({
    financingStructureRisk: PropTypes.string,
    propertyRisk: PropTypes.string,
    tenancyRisk: PropTypes.string,
    cashflowRisk: PropTypes.string,
    involvedPartiesRisk: PropTypes.string,
    totalRisk: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  setIsSaveEnabled: PropTypes.func.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
  rowAmount: PropTypes.number.isRequired,
  selectOptions: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
}
export default FactSheetValuationFactorAndScenarios
