import { CardHeader, FlexBox, Title, TitleLevel, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useId } from 'react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/shared/ui/CardHeaderWithMetrics.module.css'
import LoadingContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/shared/ui/LoadingContent'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/shared/useI18n'

const testId = (id) =>
  process.env.NODE_ENV !== 'production'
    ? { 'data-testid': `property-rent-roll-overview-card-header__${id}` }
    : {}

export const NO_CONTENT_PLACEHOLDER = '–'

const cardHeaderWithMetricsPropTypes = {
  /** the card header title text */
  title: PropTypes.node,
  primaryMetric: PropTypes.shape({
    /** the card header subtitle text displayed above the metric */
    label: PropTypes.node.isRequired,
    /** the formatted metric value */
    value: PropTypes.node,
    /** the unit of the metric */
    unit: PropTypes.node,
  }).isRequired,
  /** additional information to display in a tabular fashion on the right of the card header */
  secondaryMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      /** name of the attribute */
      label: PropTypes.node.isRequired,
      /** formatted value of the Attribute */
      value: PropTypes.node,
    }),
  ),
  /** an ISO 8601 date string indicating when the metric was captured, e.g. "2023-04-14" */
  referenceDate: PropTypes.string,
  /** whether data is being fetched */
  isLoading: PropTypes.bool,
  /** whether an error occurred during fetching the data */
  isError: PropTypes.bool,
  /** custom fallback content to display if there was an error */
  errorContent: PropTypes.node,
  showReferenceDate: PropTypes.bool,
  isOnDecisionPaper: PropTypes.bool,
  isKpisCard: PropTypes.bool,
}
/**
 * Shared card header component for cards on the property rent roll overview page
 *
 * @param {PropTypes.InferProps<typeof cardHeaderWithMetricsPropTypes>} props
 */
const CardHeaderWithMetrics = ({
  title,
  primaryMetric,
  secondaryMetrics = [],
  referenceDate,
  isLoading = false,
  isError = false,
  errorContent = NO_CONTENT_PLACEHOLDER,
  showReferenceDate = true,
  isOnDecisionPaper = false,
  isKpisCard = false,
  ...otherProps
}) => {
  const { format: formatDate } = useShortDateFormatter()
  const id = useId()
  // fixes a prop types error from <LoadingContent>, which only
  // accepts a React element, but no strings or the like
  const errorContentAsElement = <>{errorContent}</>

  return (
    <div {...otherProps}>
      {!isOnDecisionPaper && <CardHeader titleText={title} {...testId('title')} />}
      <FlexBox direction="Column" className={styles.container}>
        <Title level={TitleLevel.H6} {...testId('primary-metric-label')}>
          {primaryMetric?.label}
        </Title>
        <FlexBox direction="Row" alignItems="End" className={styles.metrics}>
          <FlexBox direction="Row" alignItems="Baseline" className={styles.primaryMetric}>
            <Title
              level={TitleLevel.H1}
              className={isKpisCard ? styles.kpisCardTitleColor : ''}
              {...testId('primary-metric-value')}
            >
              <LoadingContent
                contentKey={`${id}__primary-metric-value`}
                isLoading={isLoading && !primaryMetric.value}
                isError={isError && !primaryMetric.value}
                errorContent={errorContentAsElement}
                busyIndicatorSize="Medium"
              >
                {primaryMetric.value ?? NO_CONTENT_PLACEHOLDER}
              </LoadingContent>
            </Title>
            {primaryMetric.value && primaryMetric.unit && (
              <Title level={TitleLevel.H6} {...testId('primary-metric-unit')}>
                {primaryMetric.unit}
              </Title>
            )}
          </FlexBox>

          <FlexBox
            className={styles.secondaryMetrics}
            direction="Row"
            justifyContent="End"
            alignItems="Start"
            {...testId('secondary-metrics')}
          >
            {secondaryMetrics.map((metric, index) => (
              <FlexBox
                key={metric.title ?? index}
                direction="Column"
                className={styles.secondaryMetric}
                alignItems="End"
                {...testId('secondary-metric')}
              >
                <Title
                  wrappingType="Normal"
                  level={TitleLevel.H6}
                  {...testId('secondary-metric-label')}
                >
                  {metric.label}
                </Title>
                <Text {...testId('secondary-metric-value')}>
                  <LoadingContent
                    contentKey={`${id}__secondary-metric-value__${index}`}
                    isLoading={isLoading && !metric.value}
                    isError={isError && !metric.value}
                    errorContent={errorContentAsElement}
                  >
                    {metric.value ?? NO_CONTENT_PLACEHOLDER}
                  </LoadingContent>
                </Text>
              </FlexBox>
            ))}
          </FlexBox>
        </FlexBox>
        {showReferenceDate && (
          <Title
            level={TitleLevel.H6}
            className={styles.referenceDate}
            {...testId('reference-date')}
          >
            <LoadingContent
              contentKey={`${id}__reference-date`}
              isLoading={isLoading && !referenceDate}
              isError={isError && !referenceDate}
              errorContent={errorContentAsElement}
            >
              {referenceDate ? formatDate(referenceDate) : NO_CONTENT_PLACEHOLDER}
            </LoadingContent>
          </Title>
        )}
      </FlexBox>
    </div>
  )
}

CardHeaderWithMetrics.propTypes = cardHeaderWithMetricsPropTypes

export default CardHeaderWithMetrics
