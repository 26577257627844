import * as L from 'leaflet'
import PropTypes, { arrayOf, number, shape, string, bool } from 'prop-types'
import { Fragment, useEffect } from 'react'
import { FeatureGroup, MapContainer, Marker, TileLayer, useMap } from 'react-leaflet'
import { MarkerColors } from 'components/ui/map/internal/markers/MarkerColors'

const xSize = 30
const xAnchor = xSize / 2
const y = 40
const ySize = y
const yAnchor = y
const DEFAULT_INITIAL_ZOOM_LEVEL = 15

const getPosition = (location) => [location.latitude, location.longitude]

const leafletMapWithMarkersProps = {
  id: string,
  className: string,
  center: PropTypes.arrayOf(PropTypes.number),
  bounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  markers: arrayOf(
    shape({
      latitude: number,
      longitude: number,
    }),
  ),
  initialZoomLevel: number,
  scrollWheelZoom: bool,
}

export const LeafletMapWithMarkers = ({
  id,
  className,
  center,
  bounds,
  markers = [],
  initialZoomLevel = DEFAULT_INITIAL_ZOOM_LEVEL,
  scrollWheelZoom = false,
}) => {
  center = center ? center : getPosition(markers[0])
  return (
    <MapContainer
      id={id}
      className={className}
      zoom={initialZoomLevel}
      scrollWheelZoom={scrollWheelZoom}
      center={center}
    >
      <MapWithMarkers
        id={id}
        center={center}
        bounds={bounds}
        markers={markers}
        zoom={initialZoomLevel}
      />
    </MapContainer>
  )
}

LeafletMapWithMarkers.propTypes = leafletMapWithMarkersProps

const mapWithMarkersProps = {
  id: string,
  center: arrayOf(number),
  bounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  markers: arrayOf(
    shape({
      latitude: number,
      longitude: number,
    }),
  ),
  zoom: number,
}

const MapWithMarkers = ({ id, center, bounds, markers, zoom }) => {
  const map = useMap()
  const getMarkerIcon = (markerColor) => {
    const iconUrl =
      markerColor === MarkerColors.Grey
        ? require('components/ui/map/internal/markers/grey-marker.png')
        : require('components/ui/map/internal/markers/blue-marker.png')
    return new L.Icon({
      iconUrl,
      iconSize: new L.Point(xSize, ySize),
      iconAnchor: new L.Point(xAnchor, yAnchor),
    })
  }

  useEffect(() => {
    if (!bounds?.length) {
      map.setView(center, zoom)
    } else {
      map.fitBounds(bounds)
    }
  }, [map, center, bounds, zoom])

  return (
    <Fragment>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <FeatureGroup>
        {markers.map((marker, index) => (
          <Marker
            id={`${id}-marker-${index}`}
            key={index}
            position={getPosition(marker)}
            icon={getMarkerIcon(marker.color)}
          />
        ))}
      </FeatureGroup>
    </Fragment>
  )
}

MapWithMarkers.propTypes = mapWithMarkersProps
