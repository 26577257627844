import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/manual-checksheet/BaselTwoManualCheckSheetTableCell.module.css'
import RichTextTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/RichTextTile'

const BaselTwoManualCheckSheetTableCell = ({ question, value }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.basel-two.checksheet.manual.table.cell',
  })

  const renderDefault = () => (!isNil(value) ? t(value) : t('empty-value'))

  const renderRTE = () =>
    !isNil(value) ? (
      <RichTextTile
        isEditMode={false}
        isPdfView
        currentContent={value}
        className={styles.richTextTile}
        onChange={() => {}} // onChange is required for RichTextTile but in this case it's RichTextTile is read-only
      />
    ) : (
      t('empty-value')
    )

  switch (question) {
    case 'Q21':
      return <span>{t('irrelevant')}</span>

    case 'Comment':
      return renderRTE()

    default:
      return renderDefault()
  }
}

BaselTwoManualCheckSheetTableCell.propTypes = {
  question: PropTypes.string.isRequired,
  value: PropTypes.string,
}

export default BaselTwoManualCheckSheetTableCell
