import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import ConditionsDealContextProvider from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/ConditionsDealContextProvider'
import ExternalConditionsTileEditMode from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/external/ExternalConditionsTileEditMode'
import ExternalConditionsTileViewMode from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/external/ExternalConditionsTileViewMode'
import ExternalConditionsTileViewModeV1 from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/external/ExternalConditionsTileViewModeV1'
import { TILE_VERSION as tileVersion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'

const shownColumnsViewMode = [
  'name',
  'refNumber',
  'category',
  'type',
  'referencesDecisionPaper',
  'visibilities',
  'assignee',
  'externalAssignee',
  'status',
]

const shownColumnsEditMode = [
  'name',
  'refNumber',
  'category',
  'type',
  'references',
  'visibilities',
  'assignee',
  'externalAssignee',
  'status',
]

const shownSubcomponents = {
  description: true,
  externalAssignee: true,
}

const subcomponentHeight = 106

const ExternalConditionsTile = ({ isEditMode, isPdfView, tileId, ...tileProps }) => {
  const { version } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const externalConditionsTileViewModeForVersion = useMemo(() => {
    switch (version) {
      case tileVersion.V1:
        return (
          <ExternalConditionsTileViewModeV1
            {...tileProps}
            shownColumns={shownColumnsViewMode}
            isPdfView={isPdfView}
            shownSubcomponents={shownSubcomponents}
            subcomponentHeight={subcomponentHeight}
            tileId={tileId}
          />
        )
      default:
        return (
          <ExternalConditionsTileViewMode
            {...tileProps}
            shownColumns={shownColumnsViewMode}
            isPdfView={isPdfView}
            shownSubcomponents={shownSubcomponents}
            subcomponentHeight={subcomponentHeight}
            tileId={tileId}
          />
        )
    }
  }, [isPdfView, tileId, tileProps, version])

  return (
    <ConditionsDealContextProvider>
      {isEditMode && !isPdfView ? (
        <ExternalConditionsTileEditMode
          {...tileProps}
          tileId={tileId}
          shownColumns={shownColumnsEditMode}
          shownSubcomponents={shownSubcomponents}
          subcomponentHeight={subcomponentHeight}
        />
      ) : (
        externalConditionsTileViewModeForVersion
      )}
    </ConditionsDealContextProvider>
  )
}

ExternalConditionsTile.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  tileId: PropTypes.string.isRequired,
}

export default ExternalConditionsTile
