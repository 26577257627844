export const tilesSelector = (state) => state.decisionPaper.tilesOverview.tiles

export const hiddenTilesSelector = (state) => state.decisionPaper.tilesOverview.hiddenTiles

export const allTilesInitializedSelector = (state) =>
  state.decisionPaper.tilesOverview.allTilesInitialized

export const someTilesAreLoadingSelector = (state) =>
  state.decisionPaper.tilesOverview.someTilesAreLoading

export const someTilesHaveTechnicalErrorSelector = (state) =>
  state.decisionPaper.tilesOverview.someTilesHaveTechnicalError

export const someTilesHavePermissionErrorSelector = (state) =>
  state.decisionPaper.tilesOverview.someTilesHavePermissionError
