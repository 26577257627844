import { useMemo } from 'react'
import { useConfig } from 'hooks/config/useConfig'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useAggregateOpsMemoCollateralAgreements from 'hooks/services/business-events-and-tasks/decision-papers/tiles/collateral-overview/helper/useAggregateOpsMemoCollateralAgreements'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useCollateralAgreementsByTrancheIds from 'hooks/services/collaterals/useCollateralAgreementsByTrancheIds'
import useFinancingMini from 'hooks/services/deals/financing/useFinancingMini'
import useDeal from 'hooks/services/deals/useDeal'

const useOpsMemoCollateralOverview = ({ entityRef: { entityId: dealUuid } }, tileId, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
    errors: dealUuidErrors,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    data: dealData,
    isFetching: isDealFetching,
    isError: isDealError,
    error: dealError,
  } = useDeal(dealUuidByTileCode)

  const {
    data: { tranchesMinis: tranchesData } = {},
    isFetching: isTranchesFetching,
    isError: isTranchesError,
    error: tranchesError,
  } = useFinancingMini(dealUuidByTileCode)

  const {
    data: collateralAgreementsData,
    isFetching: isCollateralAgreementsFetching,
    isError: isCollateralAgreementsError,
    error: collateralAgreementsError,
  } = useCollateralAgreementsByTrancheIds({ ids: tranchesData?.map(({ trancheId }) => trancheId) })

  const { isLoading: isConfigLoading, isError: isConfigError, data: config } = useConfig()

  const aggregatedCollateralAgreementsData =
    useAggregateOpsMemoCollateralAgreements(collateralAgreementsData)

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isDealUuidFetching,
      isDealFetching,
      isTranchesFetching,
      isCollateralAgreementsFetching,
      isConfigLoading,
    ],
    errorValues: [
      isDealUuidError,
      isDealError,
      isTranchesError,
      isCollateralAgreementsError,
      isConfigError,
    ],
    errorDetails: [...dealUuidErrors, dealError, tranchesError, collateralAgreementsError],
    tileId,
  })

  const sourcePathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?dataSource=newBusiness&working-version=true' : ''),
    [isWorkingVersionDataTile],
  )

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error, data: undefined }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false, data: undefined }
    }
    const statusMapping = config?.collateralAgreements?.systemStatus ?? {}

    return {
      isLoading: false,
      isError: false,
      data: {
        tranchesData,
        collateralAgreementsData: collateralAgreementsData,
        aggregatedCollateralAgreementsData: aggregatedCollateralAgreementsData,
        sourceRender: {
          dealDisplayId: dealData?.dealId,
          sourcePathSuffix,
        },
        statusMapping,
      },
    }
  }, [
    aggregatedCollateralAgreementsData,
    collateralAgreementsData,
    config?.collateralAgreements?.systemStatus,
    dealData?.dealId,
    error,
    isSomeValueError,
    isSomeValueLoading,
    sourcePathSuffix,
    tranchesData,
  ])
}

export default useOpsMemoCollateralOverview
