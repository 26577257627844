import _ from 'lodash'

const sumUpCagValues = (cagList, field) =>
  cagList.reduce((acc, cag) => acc + (cag[field]?.value ?? 0), 0)

const isSameCurrency = (cagList, field) => {
  if (cagList.length < 1) return false

  return cagList.every((cag) => cag[field].currency === cagList[0][field].currency)
}

const extractGenericCagData = (cag) => ({
  cag: {
    isBold: true,
    id: cag.id,
    displayId: cag?.displayId,
    isHeader: false,
    isProperty: true,
    name: cag.name,
  },
  cagCluster: {
    value: cag.cagCluster.name,
  },
  cagType: {
    value: cag.cagType.name,
  },
  cagValue: {
    cagValue: cag.cagValue,
    cagValueInHeadQuarterCurrency: cag.cagValueHq,
  },
  nominalValue: {
    cagValue: cag.nominalValue,
    cagValueInHeadQuarterCurrency: cag.nominalValueHq,
  },
})

const extractGenericHeaderCagData = (cagList) => ({
  cag: {
    isBold: true,
    value: cagList[0].status.name,
    id: cagList[0].status.id,
    isHeader: true,
  },
  status: '',
  cagCluster: '',
  cagValue: {
    isBold: true,
    cagValue: {
      value: sumUpCagValues(cagList, 'cagValue'),
      currency: isSameCurrency(cagList, 'cagValue') ? cagList[0].cagValue.currency : '',
    },
    cagValueInHeadQuarterCurrency: {
      value: sumUpCagValues(cagList, 'cagValueHq'),
      currency: cagList[0].cagValueHq.currency,
    },
  },
  nominalValue: {
    isBold: true,
    cagValue: {
      value: sumUpCagValues(cagList, 'nominalValue'),
      currency: isSameCurrency(cagList, 'nominalValue') ? cagList[0].nominalValue.currency : '',
    },
    cagValueInHeadQuarterCurrency: {
      value: sumUpCagValues(cagList, 'nominalValueHq'),
      currency: cagList[0].nominalValueHq.currency,
    },
  },
})

const extractAssetsCagData = (cag) => ({
  ...extractGenericCagData(cag),
  rank: {
    value: cag.rank,
  },
  sequence: {
    value: cag.sequence,
  },
})

const extractPropertiesCagData = (cag) => ({
  ...extractGenericCagData(cag),
  externalLandCharge: cag.externalLandCharge,
  noOfFinProducts: {
    value: cag.noOfFinProducts,
  },
  noOfAssets: {
    value: cag.noOfAssets,
  },
})

const useMapCollateralAssetsToTableData = (data) => {
  if (!data?.length) return []

  const groupByStatus = _.groupBy(data, 'status.id')
  return Object.values(groupByStatus).map((cagList) => ({
    ...extractGenericHeaderCagData(cagList),
    rank: '',
    sequence: '',
    isSectionHeader: true,
    subRows: _.sortBy(
      cagList.map((c) => extractAssetsCagData(c)),
      ['cagCluster.name', 'cagType.name', 'name'],
    ),
  }))
}

const useMapCollateralPropertiesToTableData = (data) => {
  if (!data?.length) return []

  const extractedData = data.map((cag) => ({
    id: cag.id,
    displayId: cag.displayId,
    name: cag.name,
    cagCluster: cag.cagCluster,
    cagType: cag.cagType,
    cagValue: cag.cagValue,
    cagValueHq: cag.cagValueHq,
    nominalValue: cag.nominalValue,
    nominalValueHq: cag.nominalValueHq,
    status: cag.systemStatus,
    externalLandCharge: cag.extLandCharge,
    noOfFinProducts:
      (cag.newBusinessReceivables?.length ?? 0) + (cag.existingBusinessReceivables?.length ?? 0),
    noOfAssets: cag.assetsCount,
  }))

  const groupByStatus = _.groupBy(extractedData, 'status.id')
  return Object.values(groupByStatus).map((cagList) => ({
    ...extractGenericHeaderCagData(cagList),
    isSectionHeader: true,
    externalLandCharge: '',
    noOfFinProducts: '',
    noOfAssets: '',
    subRows: _.sortBy(
      cagList.map((cag) => extractPropertiesCagData(cag)),
      ['cagCluster.name', 'cagType.name', 'name'],
    ),
  }))
}

export { useMapCollateralAssetsToTableData, useMapCollateralPropertiesToTableData }
