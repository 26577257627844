import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useCreateEvent = (mutateOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({
      entityId,
      entityType,
      code,
      name,
      description,
      assignee,
      dueDate,
      status,
      tasks,
      decisionPaperTemplateCode,
      customizedTemplateStructure,
      eventIdToImportDataFrom,
      skipAssigneeCalculation,
    }) => {
      const { data } = await post({
        path: `/events`,
        body: {
          skip_assignee_calculation: skipAssigneeCalculation,
          info: {
            code,
            name,
            assignee,
            description,
            original_due_date: dueDate,
          },
          entity_ref: {
            entity_id: entityId,
            entity_type: entityType,
          },
          status,
          tasks: tasks.map((task) => ({
            status: task.status,
            info: {
              assignee: task.info.assignee,
              description: task.info.description,
              current_due_date: task.info.dueDate,
              original_due_date: task.info.dueDate,
              is_mandatory: task.info.isMandatory,
              name: task.info.name,
            },
          })),
          decision_paper: decisionPaperTemplateCode
            ? {
                template_code: decisionPaperTemplateCode,
                event_id_to_import_data_from: eventIdToImportDataFrom,
                customized_template_structure: customizedTemplateStructure,
              }
            : undefined,
        },
      })
      return data
    },
    mutateOptions,
  )
}
