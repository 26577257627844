import { SuggestionItem } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useContext, useState } from 'react'
import InputWithSearchSuggestions from 'components/ui/input/InputWithSearchSuggestions'
import { useExternalDealTeam } from 'hooks/services/deals/deal-team/useExternalDealTeam'
import { DealContext } from 'routes/deals/DealContext'

const ConditionDealExternalAssigneeSelect = ({ onAssigneeChange, externalAssignee }) => {
  const {
    deal: { dealUuid: dealUuid },
  } = useContext(DealContext)
  const [userSearchKey, setUserSearchKey] = useState('')
  const { data: externalAssignees, isError: isSuggestionsError } = useExternalDealTeam({
    dealUuid,
  })

  const handleOnAssigneeChange = useCallback(
    (event) => {
      const newAssignee = event.detail.item.getAttribute('data-assignee-email')
      onAssigneeChange(newAssignee)
    },
    [onAssigneeChange],
  )

  const handleOnClear = useCallback(() => {
    onAssigneeChange(null)
  }, [onAssigneeChange])

  const renderSuggestions = useCallback(() => {
    const searchLowerCased = userSearchKey?.toLocaleLowerCase()
    const filteredAssignees = (externalAssignees?.externalDealTeamMembers ?? []).filter(
      (assignee) =>
        assignee.name?.toLocaleLowerCase().includes(searchLowerCased) ||
        assignee.email?.toLocaleLowerCase().includes(searchLowerCased),
    )
    return filteredAssignees.map((assignee, index) => (
      <SuggestionItem
        key={index}
        text={isEmpty(assignee.name) ? assignee.email : assignee.name}
        data-assignee-email={assignee.email}
      />
    ))
  }, [externalAssignees?.externalDealTeamMembers, userSearchKey])

  // This is a hack for the AnalyticalTable. Otherwise the input field gets no focus.
  const onFocus = useCallback((event) => {
    event.stopPropagation()
  }, [])

  return (
    <InputWithSearchSuggestions
      initialValue={externalAssignee ?? ''}
      renderSuggestions={renderSuggestions}
      handleOnChange={handleOnAssigneeChange}
      handleOnClear={handleOnClear}
      setSearchKey={setUserSearchKey}
      isSuggestionsError={isSuggestionsError}
      onFocus={onFocus}
    />
  )
}

ConditionDealExternalAssigneeSelect.propTypes = {
  onAssigneeChange: PropTypes.func.isRequired,
  externalAssignee: PropTypes.string,
}

export default ConditionDealExternalAssigneeSelect
