import { useMemo } from 'react'
import { eventStatus } from 'api/events/status'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import { useEvents } from 'hooks/services/business-events-and-tasks/events/useEvents'
import useDealMini from 'hooks/services/deals/useDealMini'

const resourcePathPartMapping = {
  DEAL: 'deals',
  BUSINESS_PARTNER: 'business-partners',
}

const useEventHistory = ({ entityRef: { entityId, entityType } }, tileId, tileCode) => {
  const {
    data: eventData,
    isFetching: isEventsFetching,
    error: eventsError,
    isError: isEventsError,
  } = useEvents({}, { entityId, entityType, status: [eventStatus.ongoing, eventStatus.completed] })

  const isDeal = entityType === 'DEAL'

  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
    errors: dealUuidErrors,
  } = useDealUuidByTileCode({
    dealUuid: entityId,
    tileCode,
    options: { enabled: isDeal && !!entityId },
    isWorkingVersionInfoInSourcePath: true,
  })

  const {
    data: dealData,
    isFetching: isDealFetching,
    isError: isDealError,
    error: dealError,
  } = useDealMini(dealUuidByTileCode)

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isEventsFetching, isDealUuidFetching, isDealFetching],
    errorValues: [isEventsError, isDeal && isDealUuidError, isDeal && isDealError],
    errorDetails: [eventsError, ...dealUuidErrors, dealError],
    tileId,
  })

  const sourcePathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?working-version=true' : ''),
    [isWorkingVersionDataTile],
  )

  return useMemo(() => {
    const data =
      eventData && !isSomeValueError && !isSomeValueLoading
        ? {
            ...eventData,
            sourceRender: {
              displayId: isDeal ? dealData?.dealId : entityId,
              resourcePathPart: resourcePathPartMapping[entityType],
              sourcePathSuffix,
            },
          }
        : undefined

    return {
      isLoading: isSomeValueLoading,
      isError: isSomeValueError,
      error,
      data,
    }
  }, [
    eventData,
    isSomeValueError,
    isSomeValueLoading,
    isDeal,
    dealData?.dealId,
    entityId,
    entityType,
    sourcePathSuffix,
    error,
  ])
}

export default useEventHistory
