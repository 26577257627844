import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useCreateRequirementReminder = (options) => {
  const { post } = useAccessTokenRequest()
  return useMutation(async ({ requirementId, date, method, comment }) => {
    const { data } = await post({
      path: `/conditions/external/requirements/${requirementId}/reminders`,
      body: {
        date,
        method: {
          code: method,
        },
        comment,
      },
    })
    return data
  }, options)
}
