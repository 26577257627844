import PropTypes from 'prop-types'

export default PropTypes.shape({
  dealProperties: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  propertyDeals: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  rentalUnitsData: PropTypes.shape({}),
  dataByFinancingStatus: PropTypes.objectOf(
    PropTypes.shape({
      matchingProperties: PropTypes.arrayOf(PropTypes.shape({})),
      matchingDeals: PropTypes.arrayOf(PropTypes.shape({})),
      matchingRentalUnits: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
  utilization: PropTypes.shape({
    vacantUtilizationKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
    selfUtilizationKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
})
