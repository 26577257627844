import {
  ButtonDesign,
  CheckBox,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Icon,
  IconDesign,
  Label,
  TextArea,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/monitoring/market-valuation/MarketValueConfirmationStatusChangeDialog.module.css'
import LoadingButton from 'components/ui/button/LoadingButton'
import Dialog, { DialogSize } from 'components/ui/dialog/Dialog'

/**
 * @typedef {object} Props
 * @property {bool} isOpen Wether or not the dialog is open
 * @property {() => void} onChangeStatus callback that is executed when the dialog is close via the confirm button
 * @property {() => void} onCancel allback that is executed when the dialog is close via the cancel button
 * @property {String} translationPrefix prefix for the text values
 * @returns {import('react').ReactElement}
 */
const MarketValueConfirmationStatusChangeDialog = ({
  isOpen,
  onCancel,
  onChangeStatus,
  translationPrefix,
  maxCommentLength,
  isCallLoading,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: translationPrefix,
  })

  const [shouldCompleteBusinessEvent, setShouldCompleteBusinessEvent] = useState(false)
  const [comment, setComment] = useState('')

  const handleConfirmClick = useCallback(() => {
    onChangeStatus({ shouldCompleteBusinessEvent, comment })
  }, [onChangeStatus, shouldCompleteBusinessEvent, comment])

  const handleCheckBoxClick = useCallback(() => {
    setShouldCompleteBusinessEvent(!shouldCompleteBusinessEvent)
  }, [shouldCompleteBusinessEvent])

  const onChangeComment = useCallback(({ target: { value } }) => {
    setComment(value)
  }, [])

  const handleCancel = useCallback(() => {
    setComment('')
    setShouldCompleteBusinessEvent(false)
    onCancel()
  }, [onCancel])

  const confirmButton = useMemo(
    () => (
      <LoadingButton
        isLoading={isCallLoading}
        onClick={handleConfirmClick}
        disabled={!comment}
        design={ButtonDesign.Emphasized}
        renderContent={() => t('dialog.confirm')}
      />
    ),
    [comment, handleConfirmClick, isCallLoading, t],
  )

  return (
    <Dialog
      open={isOpen}
      size={DialogSize.M}
      headerText={
        <>
          <Icon name="sys-help-2" design={IconDesign.Neutral} />
          <Label className={styles.title}>{t('dialog.title')}</Label>
        </>
      }
      primaryButton={confirmButton}
      onBeforeClose={handleCancel}
      draggable
      resizable
    >
      <FlexBox
        className={styles.dialog}
        fitContainer
        justifyContent={FlexBoxJustifyContent.SpaceAround}
        alignItems={FlexBoxAlignItems.Start}
        direction={FlexBoxDirection.Column}
      >
        <>{t('dialog.text')}</>
        <>
          <FlexBox className={styles.comment} fitContainer direction={FlexBoxDirection.Column}>
            <Label required showColon>
              {t('dialog.comment')}
            </Label>
            <TextArea
              value={comment}
              maxLength={maxCommentLength}
              growing
              growingMaxLines={10}
              onInput={onChangeComment}
              required
              placeholder={t('dialog.comment.placeholder')}
            />
          </FlexBox>
        </>
        <CheckBox
          text={t('dialog.checkbox')}
          onChange={handleCheckBoxClick}
          checked={shouldCompleteBusinessEvent}
        />
      </FlexBox>
    </Dialog>
  )
}

MarketValueConfirmationStatusChangeDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  translationPrefix: PropTypes.string.isRequired,
  maxCommentLength: PropTypes.number,
  isCallLoading: PropTypes.bool,
}

export default MarketValueConfirmationStatusChangeDialog
