import { Wizard } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useLayoutEffect, useRef } from 'react'

const StyledWizard = ({ children, ...props }) => {
  const wizardRef = useRef()
  const { current: { shadowRoot = undefined } = {} } = wizardRef

  useLayoutEffect(() => {
    // dirty, because ui5 forgot to add part=navigator & part=step-content to the webcomponent
    if (shadowRoot && !shadowRoot?.querySelector('style')) {
      const styleEl = document.createElement('style')
      styleEl.innerHTML = `
      .ui5-wiz-nav {
        padding: 1rem !important;
      }
      .ui5-wiz-nav-list {
          table-layout: auto !important;
      }
      .ui5-wiz-content {
          overflow-y: hidden !important;
      }
      .ui5-wiz-content-item {
          height: 100% !important;
          overflow-y: auto !important;
          padding: 0 !important;
          background-color: var(--sapObjectHeader_Background) !important;
      }
      .ui5-wiz-content-item-wrapper { 
          background: transparent !important;
      }
      `
      shadowRoot.appendChild(styleEl)
    }
  }, [shadowRoot])

  return (
    <Wizard ref={wizardRef} {...props}>
      {children}
    </Wizard>
  )
}

export default StyledWizard

StyledWizard.propTypes = {
  children: PropTypes.any,
}
