import { t } from 'i18next'
import { DEFAULT_DISPLAY_ROW_HEIGHT } from 'routes/deals/syndication/syndicationStructure/utils/constants'

const createSectionHeaderRow = (i18nSubSectionTypeCode) => ({
  partyRoleTranche: {
    value: t(`pages.deals.syndicationStructure-table.subSection.${i18nSubSectionTypeCode}-label`),
    isBold: true,
  },
  isSectionHeader: true,
  subSectionTypeCode: i18nSubSectionTypeCode,
  rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT,
})

export default createSectionHeaderRow
