import { MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import '@ui5/webcomponents-icons/dist/error.js'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionsAllowedOperations } from 'api/conditions/conditionsAllowedOperations'
import styles from 'components/domains/conditions/dialogs/requirements/comments/RequirementCommentDialog.module.css'
import CommentDialog from 'components/ui/comment/CommentDialog'
import CommentEntry from 'components/ui/comment/CommentEntry'
import 'components/ui/dialog/ErrorDialog.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import { useStaffMemberSelf } from 'hooks/services/business-partners/staff-members/useStaffMemberSelf'
import useCreateRequirementComment from 'hooks/services/conditions/requirements/comments/useCreateRequirementComment'
import useDeleteRequirementComment from 'hooks/services/conditions/requirements/comments/useDeleteRequirementComment'
import useEditRequirementComment from 'hooks/services/conditions/requirements/comments/useEditRequirementComment'
import useRequirementComments from 'hooks/services/conditions/requirements/comments/useRequirementComments'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const RequirementCommentDialog = ({ isOpen, setIsOpen, requirementId }) => {
  const { mutate: createComment } = useCreateRequirementComment()
  const { mutate: deleteComment } = useDeleteRequirementComment()
  const { mutate: editComment } = useEditRequirementComment()
  const queryClient = useQueryClient()
  const { t } = useTranslation('translation', { keyPrefix: 'components.requirements.comments' })
  const {
    entityRef: { entityType, entityId },
  } = useContext(ConditionsContext)

  const { allowedOperations } = useContext(ConditionsContext)

  const hasEditPermission = useMemo(
    () => allowedOperations.includes(conditionsAllowedOperations.editCondition),
    [allowedOperations],
  )

  const showMessageBox = Modals.useShowMessageBox()

  const [isEditMode, setIsEditMode] = useState(false)
  const [commentEditModeState, setCommentEditModeState] = useState({})
  const isCommentEditMode = useCallback(
    (commentId) => commentEditModeState[commentId] ?? false,
    [commentEditModeState],
  )
  const setIsCommentEditMode = useCallback(
    (commentId, isEditing) =>
      setCommentEditModeState({ ...commentEditModeState, [commentId]: isEditing }),
    [commentEditModeState],
  )

  const {
    isLoading,
    isError,
    data: commentData,
  } = useRequirementComments({
    requirementId,
  })

  const { data: userData } = useStaffMemberSelf()

  const handleDeleteComment = useCallback(
    (commentId) => {
      deleteComment(
        { commentId, requirementId },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([
              'conditions',
              'external',
              'requirements',
              requirementId,
              'comments',
            ])
            queryClient.invalidateQueries([
              'conditions',
              'external',
              entityType,
              entityId,
              'requirements',
            ])
          },
          onError: () => {
            showMessageBox({ type: MessageBoxTypes.Error, children: t('delete.error.description') })
          },
        },
      )
    },
    [deleteComment, requirementId, queryClient, entityType, entityId, showMessageBox, t],
  )

  const handleUpdateComment = useCallback(
    ({ commentId, newComment }) => {
      editComment(
        {
          requirementId,
          commentId,
          comment: newComment,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([
              'conditions',
              'external',
              'requirements',
              requirementId,
              'comments',
            ])
            setIsCommentEditMode(commentId, false)
          },
          onError: (_error) => {
            showMessageBox({ type: MessageBoxTypes.Error, children: t('edit.error.description') })
          },
        },
      )
    },
    [editComment, requirementId, queryClient, setIsCommentEditMode, showMessageBox, t],
  )

  const handleCreateComment = useCallback(
    (comment) => {
      createComment(
        { requirementId, comment },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([
              'conditions',
              'external',
              'requirements',
              requirementId,
              'comments',
            ])
            queryClient.invalidateQueries([
              'conditions',
              'external',
              entityType,
              entityId,
              'requirements',
            ])
            setIsEditMode(false)
          },
          onError: () => {
            showMessageBox({ type: MessageBoxTypes.Error, children: t('create.error.description') })
          },
        },
      )
    },
    [createComment, requirementId, queryClient, entityType, entityId, showMessageBox, t],
  )

  const renderComments = useCallback(() => {
    const { comments } = commentData
    const userMail = userData?.email
    return comments.map(
      ({ ownerId, creationTime, comment, id: commentId, lastModifiedAt }, index) => (
        <div key={index} className={styles.comment}>
          <CommentEntry
            ownerId={ownerId}
            commentText={comment}
            creationTime={creationTime}
            lastModifiedAt={lastModifiedAt}
            showCommentActions={hasEditPermission && userMail === ownerId}
            onDeleteComment={() => handleDeleteComment(commentId)}
            onUpdateComment={(newComment) => handleUpdateComment({ commentId, newComment })}
            isEditMode={isCommentEditMode(commentId)}
            setIsEditMode={(isEditing) => setIsCommentEditMode(commentId, isEditing)}
          />
        </div>
      ),
    )
  }, [
    commentData,
    userData?.email,
    hasEditPermission,
    isCommentEditMode,
    handleDeleteComment,
    handleUpdateComment,
    setIsCommentEditMode,
  ])

  return (
    <CommentDialog
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onCreateComment={handleCreateComment}
      showNewCommentCard={hasEditPermission}
    >
      <SmallLoadingWrapper
        isLoading={isLoading}
        isError={isError}
        error={t('loading.error.description')}
        renderContent={renderComments}
      />
    </CommentDialog>
  )
}

RequirementCommentDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  requirementId: PropTypes.string.isRequired,
}

export default RequirementCommentDialog
