import { Button, ButtonDesign, FileUploader } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import excelUploadAcceptedFileEndings from 'components/domains/properties/rent-roll/working-version/excel-upload/excelUploadAcceptedFileEndings'

export const MULTI_PROPERTY_FILE_UPLOADER_ID = 'multi-property-file-uploader'
/**
 * A button component that allows users to upload multiple property files.
 * @param {Object} props - The props object for the component.
 * @param {boolean} props.disable - A boolean value indicating whether the button is disabled.
 * @param {function} props.onUpload - A function that is called when a file is uploaded.
 * @returns {JSX.Element} - A JSX element representing the MultiPropertyUploadButton component.
 */
const MultiPropertyUploadButton = ({ disable, onUpload, hasDealUuid = false }) => {
  const acceptFileTypes = excelUploadAcceptedFileEndings.join(',')
  const { t: tPropertyUpload } = useTranslation('translation', {
    keyPrefix: 'pages.property-upload',
  })
  const { t: tNoPrefix } = useTranslation('translation')

  return (
    <FileUploader
      disabled={disable}
      hideInput
      id={MULTI_PROPERTY_FILE_UPLOADER_ID}
      accept={acceptFileTypes}
      onClick={(e) => {
        e.stopPropagation()
      }}
      onChange={(e) => {
        onUpload(e.target.files[0], e.target)
      }}
    >
      {hasDealUuid ? (
        <Button design={ButtonDesign.Transparent}>{tNoPrefix('buttons.upload')}</Button>
      ) : (
        <Button disabled={disable} id="multi-property-upload-button" design={ButtonDesign.Default}>
          {tPropertyUpload('upload-properties')}
        </Button>
      )}
    </FileUploader>
  )
}
MultiPropertyUploadButton.propTypes = {
  disable: PropTypes.bool.isRequired,
  onUpload: PropTypes.func.isRequired,
  hasDealUuid: PropTypes.bool,
}
export default MultiPropertyUploadButton
