import PropTypes from 'prop-types'
import { useCallback } from 'react'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useStaffMemberByUserId from 'hooks/services/business-partners/staff-members/useStaffMemberByUserId'

const StaffMemberByUserId = ({ s4UserId }) => {
  const {
    data: staffMember,
    isLoading: isStaffMemberLoading,
    isError: isStaffMemberError,
  } = useStaffMemberByUserId({ s4UserId })

  const renderStaffMember = useCallback(() => {
    if (staffMember.staffMembers?.length === 1) {
      return staffMember.staffMembers[0].fullName
    }
    return s4UserId
  }, [staffMember, s4UserId])

  return (
    <SmallLoadingWrapper
      isLoading={isStaffMemberLoading}
      isError={isStaffMemberError}
      error={s4UserId}
      renderContent={renderStaffMember}
    />
  )
}

StaffMemberByUserId.propTypes = {
  s4UserId: PropTypes.string.isRequired,
}

export default StaffMemberByUserId
