import { Dialog } from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { useTranslation } from 'react-i18next'
import LoadingScreen from 'components/ui/screens/LoadingScreen'

const CreateRentRollWorkingVersionLoadingScreen = ({ isLoading }) => {
  const { t } = useTranslation('translation')
  return (
    <Dialog open={isLoading}>
      <LoadingScreen title={t('app.loading.title')} description={t('app.loading.description')} />
    </Dialog>
  )
}

CreateRentRollWorkingVersionLoadingScreen.propTypes = {
  isLoading: PropType.bool.isRequired,
}

export default CreateRentRollWorkingVersionLoadingScreen
