import { useState } from 'react'
import { createPortal } from 'react-dom'
import TrancheDeleteButton from 'components/domains/deals/financing/tranche-delete/TrancheDeleteButton'
import TrancheDeleteDialog from 'components/domains/deals/financing/tranche-delete/TrancheDeleteDialog'

const TrancheDelete = () => {
  const [showDeleteTrancheDialog, setShowDeleteTrancheDialog] = useState(false)

  const handleOnShowDeleteTrancheDialog = () => {
    setShowDeleteTrancheDialog(true)
  }

  const handleOnCloseDeleteTrancheDialog = () => {
    setShowDeleteTrancheDialog(false)
  }

  return (
    <>
      <TrancheDeleteButton onShowDeleteTrancheDialog={handleOnShowDeleteTrancheDialog} />
      {createPortal(
        <TrancheDeleteDialog
          onCloseDeleteTrancheDialog={handleOnCloseDeleteTrancheDialog}
          showDeleteTrancheDialog={showDeleteTrancheDialog}
        />,
        document.body,
      )}
    </>
  )
}

export default TrancheDelete
