import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * Renders a dropdown with support for a hook using `useQuery` to load the
 * dropdown values. Loading and error messages are rendered based on the
 * `useQuery` return values.
 * @param id the id of the `<select>` node
 * @param useLoader the hook to load data with `useQuery`. The response must
 * be a list of objects with the `code` and `displayName` keys.
 * @param input parameter used by the given useLoader hook
 * @param initialDropdownValue initial dropdown value can be set to empty string
 * @param value the initial select value
 * @param onChange the change handler
 * @param {...props} props is referenced by the Select component
 */
const LoadingDropdown = ({
  id,
  useLoader,
  input,
  initialDropdownValue,
  value,
  onChange,
  loadingText,
  errorText,
  onBlur,
  ...props
}) => {
  const { data, isError, isLoading } = useLoader(input)
  const { t } = useTranslation('decisionPaper', { keyPrefix: 'components.loading-dropdown' })

  const loadingMessage = loadingText || t('loading')
  const errorMessage = errorText || t('error')

  const options = useMemo(() => {
    if (isLoading) {
      return [<Option key="loading">{loadingMessage}</Option>]
    } else if (isError) {
      return [<Option key="error">{errorMessage}</Option>]
    } else {
      return data.map(({ code, displayName }) => (
        <Option key={code} data-id={code} selected={code === value}>
          {displayName}
        </Option>
      ))
    }
  }, [isLoading, isError, data, loadingMessage, errorMessage, value])

  if (isLoading || isError || initialDropdownValue !== undefined) {
    options.unshift(
      <Option key="" data-id="">
        {initialDropdownValue}
      </Option>,
    )
  }

  return (
    <Select
      id={id}
      onChange={(event) => {
        onChange(event.detail.selectedOption.dataset.id)
      }}
      {...props}
      onBlur={() => {
        onBlur?.(value)
      }}
    >
      {options}
    </Select>
  )
}

LoadingDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  useLoader: PropTypes.func.isRequired,
  input: PropTypes.any,
  initialDropdownValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  loadingText: PropTypes.string,
  errorText: PropTypes.string,
}

export default LoadingDropdown
