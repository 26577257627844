const addSharesToUnit = ({ units, totalRent, totalArea }) => {
  const rent = units.rentContractedYearInHeadQuarterCurrency?.number
  const area = units.areaInHeadQuarterMeasurementUnit?.value
  const rentalPercentShare = rent / totalRent
  const rentalUnitAreaSftSqmShare = area / totalArea
  const rentContractedYearShare = rentalPercentShare
  const result = {
    ...units,
    rentContractedYearShare,
    rentalUnitAreaSftSqmShare,
  }

  return result
}

export default addSharesToUnit
