import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDecisionStageMinutesVoteOptions = ({ eventId, decisionStageId }, options) =>
  useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/decision-stages/${decisionStageId}/minutes/vote-options`,
      useCache: true,
      keys: ['events', eventId, 'decision-stages', decisionStageId, 'minutes', 'vote-options'],
      options,
    }),
  )

export default useDecisionStageMinutesVoteOptions
