import {
  BusyIndicator,
  BusyIndicatorSize,
  IllustratedMessage,
  IllustrationMessageSize,
  Title,
  TitleLevel,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import { IllustrationMessageType } from '@fioneer/ui5-webcomponents-react/dist/enums'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/need-to-know/NeedToKnowWrapper.module.css'
import { useDelay } from 'hooks/common/useDelay'

const DEFAULT_DELAY = 1000

const NeedToKnowWrapper = ({
  delay = DEFAULT_DELAY,
  isLoading = false,
  isError = false,
  children,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ui.need-to-know.wrapper',
  })

  const [loadingVisible, setLoadingVisible] = useState(false)
  const delayedSetLoadingVisible = useDelay(setLoadingVisible, delay)
  useEffect(() => {
    if (isLoading && !loadingVisible) {
      delayedSetLoadingVisible(true)
    }
    if (!isLoading && loadingVisible) {
      setLoadingVisible(false)
    }
  }, [isLoading, loadingVisible, delayedSetLoadingVisible])

  if (isLoading) {
    return (
      <div className={loadingVisible ? styles.centered : styles.hidden}>
        <div className={styles.indicator}>
          <BusyIndicator active size={BusyIndicatorSize.Large} delay={delay} />
        </div>
        <div>
          <Title level={TitleLevel.H4} className={styles.title} wrappingType={WrappingType.Normal}>
            {t('loading.title')}
          </Title>
        </div>
        <div>
          <Title
            level={TitleLevel.H5}
            className={styles.subtitle}
            wrappingType={WrappingType.Normal}
          >
            {t('loading.subtitle')}
          </Title>
        </div>
      </div>
    )
  }

  if (isError) {
    return (
      <div className={styles.centered}>
        <IllustratedMessage
          name={IllustrationMessageType.UnableToLoad}
          size={IllustrationMessageSize.Spot}
          titleText={t('error.title')}
          subtitleText={t('error.subtitle')}
        />
      </div>
    )
  }

  return children
}

NeedToKnowWrapper.propTypes = {
  delay: PropTypes.number,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  children: PropTypes.node,
}

export default NeedToKnowWrapper
