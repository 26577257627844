import {
  Button,
  ButtonDesign,
  Menu,
  MenuItem,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useId, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'

const deleteMenuAction = 'delete'
const editMenuAction = 'edit'

const CommentOptionButton = ({ onDeleteClicked, onEditClicked }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ui.comment.dialog',
  })
  const openerId = useId()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const showMessageBox = Modals.useShowMessageBox()

  const onDeleteConfirmed = useCallback(() => {
    onDeleteClicked()
  }, [onDeleteClicked])

  const handleDeleteClicked = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Confirm,
      titleText: t('delete.confirmation.title'),
      children: t('delete.confirmation.text'),
      actions: [
        <Button key="button-delete" design={ButtonDesign.Emphasized} onClick={onDeleteConfirmed}>
          {tNoPrefix('buttons.delete')}
        </Button>,
        <Button
          key="button-cancel"
          data-action={MessageBoxActions.Cancel}
          design={ButtonDesign.Transparent}
        >
          {tNoPrefix('buttons.cancel')}
        </Button>,
      ],
      draggable: true,
      resizable: true,
    })
  }, [showMessageBox, t, tNoPrefix, onDeleteConfirmed])

  const onMenuItemClick = useCallback(
    ({ detail: { item: pressedMenuItem } }) => {
      setIsMenuOpen(false)
      const pressedMenuAction = pressedMenuItem.getAttribute('data-action')
      switch (pressedMenuAction) {
        case deleteMenuAction:
          handleDeleteClicked()
          break
        case editMenuAction:
          onEditClicked()
          break
      }
    },
    [handleDeleteClicked, onEditClicked],
  )

  return (
    <>
      {createPortal(
        <Menu
          open={isMenuOpen}
          onAfterClose={() => setIsMenuOpen(false)}
          onItemClick={onMenuItemClick}
          opener={openerId}
        >
          <MenuItem icon="edit" text={tNoPrefix('buttons.edit')} data-action={editMenuAction} />
          <MenuItem
            icon="delete"
            text={tNoPrefix('buttons.delete')}
            data-action={deleteMenuAction}
          />
        </Menu>,
        document.body,
      )}
      <Button
        onClick={() => setIsMenuOpen(true)}
        id={openerId}
        design={ButtonDesign.Transparent}
        icon="overflow"
      />
    </>
  )
}

CommentOptionButton.propTypes = {
  onDeleteClicked: PropTypes.func.isRequired,
  onEditClicked: PropTypes.func.isRequired,
}

export default CommentOptionButton
