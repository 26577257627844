import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useExternalDeleteReservationsTrigger = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ dealUuid, reservationUuids }) => {
      await post({
        path: `/pbb-custom-service/deals/${dealUuid}/limit-management/delete-reservations`,
        body: {
          external_ids: reservationUuids,
        },
      })
    },
    { onSuccess, onError },
  )
}

export default useExternalDeleteReservationsTrigger
