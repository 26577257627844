import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { entityTypes } from 'api/events/status'
import BusinessPartnerLink from 'components/domains/business-events-and-tasks/BusinessPartnerLink'
import DealLink from 'components/domains/business-events-and-tasks/DealLink'
import PropertyLink from 'components/domains/business-events-and-tasks/PropertyLink'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'

export const BusinessObjectLinkCustomRender = {
  clipboard: 'CLIPBOARD',
  singleLine: 'SINGLE_LINE',
}

const BusinessObjectLink = ({
  entityId,
  entityType,
  customRender,
  errorCallback,
  CustomComponent,
  customComponentProperties,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.business-object-link',
  })

  const renderContent = useCallback(() => {
    switch (entityType) {
      case entityTypes.property:
        return (
          <PropertyLink
            propertyUuid={entityId}
            customRender={customRender}
            errorCallback={errorCallback}
            CustomComponent={CustomComponent}
            customComponentProperties={customComponentProperties}
          />
        )
      case entityTypes.businesspartner:
        return (
          <BusinessPartnerLink
            businessPartnerId={entityId}
            customRender={customRender}
            errorCallback={errorCallback}
            CustomComponent={CustomComponent}
            customComponentProperties={customComponentProperties}
          />
        )
      case entityTypes.deal:
        return (
          <DealLink
            dealUuid={entityId}
            customRender={customRender}
            errorCallback={errorCallback}
            CustomComponent={CustomComponent}
            customComponentProperties={customComponentProperties}
          />
        )
      default:
        errorCallback?.({ hasError: true })
        return CustomComponent ? (
          <CustomComponent hasError={true} {...customComponentProperties} />
        ) : (
          <Text wrapping>{t('error.description')}</Text>
        )
    }
  }, [
    CustomComponent,
    customComponentProperties,
    customRender,
    entityId,
    entityType,
    errorCallback,
    t,
  ])

  return (
    <SmallLoadingWrapper
      isError={false}
      isLoading={!entityId || !entityType}
      error={''}
      renderContent={renderContent}
    />
  )
}

BusinessObjectLink.propTypes = {
  entityId: PropTypes.string,
  entityType: PropTypes.string,
  customRender: PropTypes.oneOf(['CLIPBOARD', 'SINGLE_LINE', 'CLICKABLE_INPUT']),
  errorCallback: PropTypes.func,
  CustomComponent: PropTypes.func,
  customComponentProperties: PropTypes.object,
}

export default BusinessObjectLink
