import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import AnnualReviewManualCheckSheetResultMessageStrip from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/appendix/manual-checksheet/AnnualReviewManualCheckSheetResultMessageStrip'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/appendix/manual-checksheet/AnnualReviewManualCheckSheetTile.module.css'
import { questionKeys } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/appendix/manual-checksheet/questions'
import syncInitialAndCurrentData from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/appendix/manual-checksheet/syncInitialAndCurrentData'
import AnnualReviewCheckSheetTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/checksheets/AnnualReviewCheckSheetTable'
import AnnualReviewCheckSheetTableEdit from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/checksheets/AnnualReviewCheckSheetTableEdit'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'

const AnnualReviewManualCheckSheetTile = ({
  tileId,
  currentContent,
  onChange,
  isEditMode,
  setHasContentChanges,
  selectedBorrowerId,
  ...tileProps
}) => {
  const translationPrefix = 'tiles.annual-review.appendix.checksheet.manual'

  const borrowersMetadata = useSelector(
    (state) =>
      state.decisionPaper.tilesOverview.tileMetadata?.[
        availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsBorrowerSelection
      ],
  )
  const { allBorrowers } = borrowersMetadata ?? {}

  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const initialAnswers = useMemo(
    () =>
      questionKeys
        .map((questionKey) => ({ [questionKey]: null }))
        .reduce((prev, curr) => Object.assign(prev, curr), {}),
    [],
  )

  const initialData = useMemo(
    () =>
      allBorrowers?.map(({ id, name }) => ({
        borrowerId: id,
        borrowerName: name,
        answers: { ...initialAnswers },
      })),
    [allBorrowers, initialAnswers],
  )

  const currentData = useMemo(
    () =>
      currentContent
        ? syncInitialAndCurrentData({
            currentData: currentContent ?? [],
            initialData,
          })
        : initialData,
    [currentContent, initialData],
  )

  useEffect(() => {
    const hasContentChanges =
      !(isNil(currentContent) && isNil(tileStatus?.data?.data)) &&
      !isEqual(tileStatus?.data?.data, currentContent)
    setHasContentChanges(hasContentChanges)
  }, [currentContent, tileStatus, setHasContentChanges, currentData])

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.wrapper}>
      <AnnualReviewManualCheckSheetResultMessageStrip
        {...tileProps}
        id="annualReviewManualCheckSheetResultMessageStrip"
        selectedBorrowerId={selectedBorrowerId}
        currentData={currentData}
        isEditMode={isEditMode}
      />
      {isEditMode ? (
        <AnnualReviewCheckSheetTableEdit
          {...tileProps}
          id="annualReviewManualCheckSheetTableEdit"
          translationPrefix={translationPrefix}
          selectedBorrowerId={selectedBorrowerId}
          currentData={currentData}
          onChange={onChange}
        />
      ) : (
        <AnnualReviewCheckSheetTable
          {...tileProps}
          id="annualReviewManualCheckSheetTable"
          translationPrefix={translationPrefix}
          selectedBorrowerId={selectedBorrowerId}
          currentData={currentData}
        />
      )}
    </FlexBox>
  )
}

AnnualReviewManualCheckSheetTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  selectedBorrowerId: PropTypes.string.isRequired,
  currentContent: PropTypes.arrayOf(
    PropTypes.shape({
      borrowerId: PropTypes.string.isRequired,
      borrowerName: PropTypes.string,
      answers: PropTypes.shape({}),
    }),
  ),
  onChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
}

export default AnnualReviewManualCheckSheetTile
