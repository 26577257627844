import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import { isEmpty } from 'lodash'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { minimalToolbarConfig } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/rich-text-editor/CWPCKEditorToolbarConfigurations'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/manual-checksheet/BaselTwoManualCheckSheetTableEditCell.module.css'
import RichTextTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/RichTextTile'

const BaselTwoManualCheckSheetTableEditCell = ({ question, value, onChange }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.basel-two.checksheet.manual.table.cell',
  })

  const renderSelect = (options) => (
    <Select
      onChange={(event) => {
        const newValue = event.detail.selectedOption.value
        onChange(question, newValue)
      }}
      className={styles.dropdown}
    >
      <Option selected={isNil(value)} />
      {!isEmpty(options) &&
        options.map((option) => (
          <Option key={option} value={option} selected={value === option}>
            {t(option)}
          </Option>
        ))}
    </Select>
  )

  const renderRTE = () => (
    <RichTextTile
      isEditMode={true}
      isPdfView={false}
      currentContent={value}
      onChange={(newValue) => {
        onChange(question, newValue)
      }}
      toolbarConfig={minimalToolbarConfig}
    />
  )

  switch (question) {
    case 'Q10':
      return renderSelect([
        'not-required',
        'not-fulfilled-and-necessary-measures-not-completed',
        'not-fulfilled-and-necessary-measures-completed-on',
        'fulfilled-documents-received-on/checked-on',
      ])

    case 'Q11':
    case 'Q13':
    case 'Q16':
    case 'Q19':
    case 'Q20':
      return renderSelect(['yes', 'no', 'n/a'])

    case 'Q18':
      return renderSelect(['yes', 'no', 'in-part', 'not-required'])

    case 'Q21':
      return <span>{t('irrelevant')}</span>

    case 'Comment':
      return renderRTE()

    default:
      return renderSelect(['yes', 'no'])
  }
}

BaselTwoManualCheckSheetTableEditCell.propTypes = {
  question: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default BaselTwoManualCheckSheetTableEditCell
