import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import documentPermissions from 'api/documents/DocumentPermissions'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import BusinessPartnerAssociatedDeals from 'components/domains/business-partners/related-documents/BusinessPartnerAssociatedDeals'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import DocumentUploadDialog from 'components/domains/documents/DocumentUploadDialog'
import DocumentsTable from 'components/domains/documents/documents-table/DocumentsTable'
import mapDocumentsForTable from 'components/domains/documents/documents-table/mapDocumentsForTable'
import {
  labelNewDocuments,
  labelReferencedDocuments,
} from 'components/domains/markets/detail/cards/MarketDocumentTableFunctions'
import Card from 'components/ui/card/Card'
import Page from 'components/ui/page/Page'
import Section from 'components/ui/page/Section'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import {
  documentsTableColumnIds,
  useDocumentsTableColumns,
} from 'hooks/services/documents/documents-table/useDocumentsTableColumnDefinitions'
import useDocuments from 'hooks/services/documents/useDocuments'
import useDocumentsAllowedOperations from 'hooks/services/documents/useDocumentsAllowedOperations'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import styles from 'routes/business-partners/documents/BusinessPartnerRelatedDocumentsPage.module.css'

const displayedColumns = [
  documentsTableColumnIds.name,
  documentsTableColumnIds.nameForFiltering,
  documentsTableColumnIds.type,
  documentsTableColumnIds.keyDate,
  documentsTableColumnIds.fileExtension,
  documentsTableColumnIds.modificationTime,
  documentsTableColumnIds.version,
  documentsTableColumnIds.creator,
  documentsTableColumnIds.downloadDocument,
]

const BusinessPartnerRelatedDocumentsPage = () => {
  const { t } = useTranslation('translation')
  const businessPartner = useContext(BusinessPartnerContext)
  const {
    data: documentRoot = [],
    isLoading,
    isError,
  } = useDocuments({ entityId: businessPartner.id, entityType: DocumentTypes.BusinessPartner })

  const initialUploadedDocumentIds = []
  const [uploadedDocumentIds, setUploadedDocumentIds] = useState(initialUploadedDocumentIds)
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false)
  const [files, setFiles] = useState(undefined)

  const labeledDocuments = useMemo(
    () => labelNewDocuments(documentRoot, uploadedDocumentIds),
    [documentRoot, uploadedDocumentIds],
  )

  const referencedDocuments = useMemo(
    () => labelReferencedDocuments(labeledDocuments),
    [labeledDocuments],
  )

  const preparedDocumentRoot = useMemo(
    () => mapDocumentsForTable(referencedDocuments),
    [referencedDocuments],
  )

  const {
    data: allowedOperationsData,
    isLoading: isLoadingAllowedOperations,
    isError: isAllowedOperationsError,
  } = useDocumentsAllowedOperations()

  const hasDocumentReadPermission =
    allowedOperationsData?.allowedOperations.includes(documentPermissions.Read) ?? false
  const hasDocumentUploadPermission =
    allowedOperationsData?.allowedOperations.includes(documentPermissions.Create) ?? false

  const { columns: allColumns, formatDate } = useDocumentsTableColumns()

  const columns = useMemo(
    () => allColumns.filter(({ id }) => displayedColumns.includes(id)),
    [allColumns],
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={businessPartner.id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [businessPartner.id],
  )

  const renderHeaderTitle = () => (
    <BusinessPartnerHeader
      breadcrumbs={[{ text: t('navigation.item.title.business-partner-related-documents') }]}
      actions={[<CreateBusinessPartnerEventAction key="create-event-action" />, markFavoriteAction]}
    />
  )

  const handleOnDrop = ({ files: newFiles } = {}) => {
    setFiles(newFiles)
    setIsUploadDialogOpen(true)
  }

  const isOpen = !!files?.length || isUploadDialogOpen
  const renderContent = () => (
    <div className={styles.content}>
      <DocumentUploadDialog
        type={DocumentTypes.BusinessPartner}
        isOpen={isOpen}
        initialState={{
          open: undefined,
          files,
          selectedEntities: [
            {
              id: businessPartner.id,
              name: businessPartner.fullName,
            },
          ],
        }}
        onUploadFinished={(newDocumentIds) => {
          setUploadedDocumentIds(newDocumentIds)
        }}
        onClose={() => {
          setIsUploadDialogOpen(false)
          setFiles(undefined)
        }}
      />

      <BusinessPartnerAssociatedDeals />
      <Section titleText={t('pages.deals.detail.documents.header')}>
        <Card>
          <LoadingStateWrapper
            isLoading={isLoading || isLoadingAllowedOperations}
            isError={isError || isAllowedOperationsError}
          >
            <div className={styles.contentInner}>
              <DocumentsTable
                columns={columns}
                folders={preparedDocumentRoot.subFolders}
                title={t('pages.business-partner.related-documents.title')}
                formatDate={formatDate}
                handleOnDrop={handleOnDrop}
                hasDocumentUploadPermission={hasDocumentUploadPermission}
              />
            </div>
          </LoadingStateWrapper>
        </Card>
      </Section>
    </div>
  )

  if (!hasDocumentReadPermission) return <></>

  return (
    <Page
      isError={isError}
      isLoading={isLoading}
      renderHeaderTitle={renderHeaderTitle}
      renderContent={renderContent}
    />
  )
}

export default BusinessPartnerRelatedDocumentsPage
