import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import { WorkingVersionType } from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/components/domains/deals/deal-adjustment/model/WorkingVersionType'
import useBusinessPartnerUnitOverview from 'hooks/services/business-partners/unit-overview/useBusinessPartnerUnitOverview'
import { useMultipleDealsByUuidMini } from 'hooks/services/deals/useMultipleDealsByUuidMini'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const useAnnualReviewDealOverviewDeals = ({ businessPartnerId }) => {
  const {
    data: { unitDeals = [] } = {},
    isLoading,
    isError,
    error,
  } = useBusinessPartnerUnitOverview({ businessPartnerId }) ?? {}

  const dealsData = useMemo(() => unitDeals.map(({ kpiDeal } = {}) => kpiDeal), [unitDeals])
  const dealUuids = useMemo(() => dealsData?.map((deal) => deal.dealUuid), [dealsData])

  const { dealUuid, dealName, dealDisplayId } = dealsData?.[0] ?? {}

  const {
    data: multipleDealDetails = [],
    isLoading: isMultipleDealDetailsLoading,
    isError: isMultipleDealDetailsError,
  } = useCombinedQueryResults(
    useMultipleDealsByUuidMini(dealUuids, {
      enabled: dealUuids?.length > 0,
    }),
    { forceDataReturn: true },
  )

  const enrichedUnitDealsData = useMemo(
    () =>
      dealsData?.map((deal) => {
        const currentDealDetails = multipleDealDetails?.find(
          ({ dealUuid: detailsDealUuid }) => detailsDealUuid === deal?.dealUuid,
        )
        return {
          ...deal,
          isWorkingVersion:
            currentDealDetails?.workingVersion === WorkingVersionType.WORKING_VERSION,
        }
      }),
    [dealsData, multipleDealDetails],
  )

  // although this is a hook, eslint still recognizes this as a false usage within useMemo directly below this block
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedDeal = useSelector(
    (state) =>
      state.decisionPaper.tilesOverview.tileMetadata?.[
        availableAutomaticTiles.annualReviewDealSelection
      ],
  ) ?? {
    selectedDealUuid: dealUuid,
    selectedDealName: dealName,
    selectedDealDisplayId: dealDisplayId,
  }

  return useMemo(
    () => ({
      data: { selectedDeal, dealsData: enrichedUnitDealsData, dealUuids },
      isLoading: isLoading || isMultipleDealDetailsLoading,
      isError: isError || isMultipleDealDetailsError,
      error,
    }),
    [
      dealUuids,
      enrichedUnitDealsData,
      error,
      isError,
      isLoading,
      isMultipleDealDetailsError,
      isMultipleDealDetailsLoading,
      selectedDeal,
    ],
  )
}

export default useAnnualReviewDealOverviewDeals
