import round from 'lodash.round'
import { MONTHLY_CONTENT_KEY_MAP } from 'components/domains/properties/rent-roll/working-version/excel-upload/constants'
import getIsoDate from 'components/domains/properties/rent-roll/working-version/excel-upload/getIsoDate'
import { EXCEL_FIELDS_TO_OBTAIN_MAPPINGS_FOR } from 'components/domains/properties/rent-roll/working-version/excel-upload/getPropertyRentRollWorkingVersionExcelLocalValueMapping'
import { getKeyOrReturnDisplayName } from 'hooks/services/properties/rent-roll/working-version/useGetAllRentRollWorkingVersionOptions'

const STRINGS_DEFAULTING_TO_TRUE = ['true', 'ja', 'yes', 'j', 'y', 'x']
const ANONYMOUS_STRINGS_DEFAULTING_TO_TRUE = [...STRINGS_DEFAULTING_TO_TRUE, 'anonym', 'anonymous']
const MONTHS_PER_YEAR = 12

const getPropertyRentRollWorkingVersionExcelColumnMapping = (
  excelToTableMapping,
  allSelectCodes,
  localeDatePattern,
) => {
  //when providing selectOptions to the mapping function, it will try to map the select text to its corresponding ID
  //e.g. Segment_usage_type 'Hospitality' will be mapped to 'UT-1'
  const {
    areaMeasureUnitCodes = [],
    occupancyStatusCodes = [],
    currencyCodes = [],
    segmentUsageTypeCodes = [],
    properties = [],
  } = allSelectCodes

  const persistOriginalExcelDataForChosenFields = (excelRow) => {
    const originalExcelData = {}
    EXCEL_FIELDS_TO_OBTAIN_MAPPINGS_FOR.forEach(({ primary: fieldName }) => {
      const fieldMapping = excelToTableMapping.get(fieldName)
      const originalValue = fieldMapping ? excelRow[fieldMapping['excelCol']] : null
      originalExcelData[fieldName] = originalValue
    })
    return originalExcelData
  }

  const mapValueToBoolean = (value, mappingStrings) =>
    typeof value === 'string' ? mappingStrings.includes(value?.toLowerCase()) : false

  const getValueOrUndefined = (value) => value || undefined

  const roundNumber = (originalValue, precision) =>
    !isNaN(Number(originalValue))
      ? getValueOrUndefined(round(Number(originalValue), precision))
      : getValueOrUndefined(originalValue)

  /**
   * This function checks, whether a monthly value mapping is set.
   * This is due to the fact, that rent input data can be provided in two ways: As monthly or yearly values.
   * If yes --> Multiply original value * 12
   * If not --> Return original value, no calculation needed
   * @param {Map} map
   * @param {String} columnDefKey
   * @param {String?} originalValue
   * @returns {String?} aggregatedRent
   */
  const getRent = (map, columnDefKey, originalValue) => {
    const roundedRent = roundNumber(originalValue, 2)
    const monthlyValueDefKey = MONTHLY_CONTENT_KEY_MAP.get(columnDefKey)
    const isMonthlyValue = map.get(monthlyValueDefKey)?.excelCol
    return isMonthlyValue && !isNaN(Number(roundedRent))
      ? getValueOrUndefined(Number(roundedRent) * MONTHS_PER_YEAR)
      : getValueOrUndefined(roundedRent)
  }

  const getBusinessPartnerName = (originalValue) => {
    const bpName = originalValue ? String(originalValue)?.trim() : originalValue
    return getValueOrUndefined(bpName)
  }

  const mapToTableValues = (excelRow, excelColName, columnDefKey, map) => {
    let selectKey
    let cellValue
    const originalValue = excelRow[excelColName]
    switch (columnDefKey) {
      case 'segment_usage_type_id':
        selectKey = getKeyOrReturnDisplayName({
          displayName: originalValue,
          options: segmentUsageTypeCodes,
        })
        cellValue = selectKey
        break
      case 'rental_unit_area_uom_id':
        selectKey = getKeyOrReturnDisplayName({
          displayName: originalValue,
          options: areaMeasureUnitCodes,
        })
        cellValue = selectKey
        break
      case 'rental_unit_currency_id':
        selectKey = getKeyOrReturnDisplayName({
          displayName: originalValue,
          options: currencyCodes,
        })
        cellValue = selectKey
        break
      case 'occupancy_status_id':
        selectKey = getKeyOrReturnDisplayName({
          displayName: originalValue,
          options: occupancyStatusCodes,
        })
        cellValue = selectKey
        break
      case 'property_uuid':
        selectKey = getKeyOrReturnDisplayName({
          displayName: originalValue,
          options: properties,
        })
        cellValue = selectKey
        break
      case 'rent_start_date':
      case 'lease_start_date':
      case 'lease_expiry_date':
      case 'lease_break_date':
        cellValue = getIsoDate(originalValue, localeDatePattern)
        break
      case 'rent_contracted_year':
      case 'current_net_rent':
        cellValue = getRent(map, columnDefKey, originalValue)
        break
      case 'rent_dispute':
      case 'no_lease_expiry':
        cellValue = mapValueToBoolean(originalValue, STRINGS_DEFAULTING_TO_TRUE)
        break
      case 'anonymous_tenant':
        cellValue = mapValueToBoolean(originalValue, ANONYMOUS_STRINGS_DEFAULTING_TO_TRUE)
        break
      case 'rental_unit_area':
        cellValue = roundNumber(originalValue, 2)
        break
      case 'number_of_units':
        cellValue = roundNumber(originalValue, 0)
        break
      case 'tenant_name':
      case 'branding_franchise_partner_name':
        cellValue = getBusinessPartnerName(originalValue)
        break
      default:
        cellValue = originalValue ? String(originalValue) : originalValue
        break
    }
    return cellValue
  }

  const mapRow = (excelRow, mapValuesToTableValues = true) => {
    const tableRow = {}
    excelToTableMapping.forEach((columnMapping, columnDefKey, map) => {
      if (Array.from(MONTHLY_CONTENT_KEY_MAP.values()).includes(columnDefKey)) {
        return
      }
      const excelColName = columnMapping['excelCol']
      let cellValue
      if (mapValuesToTableValues) {
        cellValue = mapToTableValues(excelRow, excelColName, columnDefKey, map)
      } else {
        cellValue = excelRow[excelColName]
      }
      tableRow[columnDefKey] = cellValue
    })
    tableRow['original_excel_content'] = { ...persistOriginalExcelDataForChosenFields(excelRow) }
    return tableRow
  }
  const mapRowToMap = (excelRow) => {
    const mappedRow = mapRow(excelRow, false)
    const mappedRowAsMap = new Map()
    Object.getOwnPropertyNames(mappedRow).forEach((property) => {
      mappedRowAsMap.set(property, mappedRow[property])
    })
    return mappedRowAsMap
  }

  const mapData = (excelData) =>
    excelData.map((row, index) => ({
      row_number: index,
      ...mapRow(row),
    }))

  return { mapData, mapRow, mapRowToMap }
}

export default getPropertyRentRollWorkingVersionExcelColumnMapping
