import { ComboBox, ComboBoxItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { valuationRequestMultiEditOperations } from 'api/property/valuation/valuationRequests'
import Labeled from 'components/ui/data/Labeled'

const reasonFieldId = 'valuationRequestMultiEditReasonField'

const ValuationRequestMultiEditReasonField = ({ disabled, onChange, valuationReasons }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.properties.valuation.multi-edit.fields',
  })
  const { t: tOperations } = useTranslation('translation', {
    keyPrefix: 'components.properties.valuation.multi-edit.operations',
  })

  const onChangeInternal = useCallback(
    ({ detail: { item: selectedComboBoxItem } }) => {
      const operation = selectedComboBoxItem.getAttribute('data-operation')
      const reason = selectedComboBoxItem.getAttribute('text')
      onChange({ operation, value: reason })
    },
    [onChange],
  )

  const reasonComboBoxItems = useMemo(
    () =>
      valuationReasons.map(({ id, reason }) => (
        <ComboBoxItem
          key={id}
          data-operation={valuationRequestMultiEditOperations.replace}
          text={reason}
        />
      )),
    [valuationReasons],
  )

  return (
    <Labeled id={reasonFieldId} vertical showColon label={t('reason.label')}>
      <ComboBox
        onSelectionChange={onChangeInternal}
        value={tOperations(valuationRequestMultiEditOperations.keep)}
        disabled={disabled}
      >
        <ComboBoxItem
          data-operation={valuationRequestMultiEditOperations.keep}
          text={tOperations(valuationRequestMultiEditOperations.keep)}
        />
        {reasonComboBoxItems}
      </ComboBox>
    </Labeled>
  )
}

ValuationRequestMultiEditReasonField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  valuationReasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      reason: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
}

export default ValuationRequestMultiEditReasonField
