import i18n from 'i18next'
import { useEffect, useMemo, useState } from 'react'

const DEFAULT_LOCALE = 'en-US'
const CACHE = {}

const getCurrencyIntlNumberFormatOrDefault = ({
  currency,
  locales,
  specificOptions = {},
  optionOverrides = {},
}) => {
  const createFormatter = (currencyValue) =>
    new Intl.NumberFormat(locales, {
      ...specificOptions,
      currency: currencyValue,
      ...optionOverrides,
    }).format

  let isPotentiallyValidCurrency = !!currency
  let format

  try {
    format = createFormatter(currency || 'EUR')
  } catch (error) {
    isPotentiallyValidCurrency = false
    format = createFormatter('EUR')
  }

  return {
    isPotentiallyValidCurrency,
    format,
  }
}

export const useLanguage = () => {
  const [language, setLanguage] = useState(i18n.language)

  useEffect(() => {
    i18n.on('languageChanged', setLanguage)
    return () => i18n.off('languageChanged')
  }, [])

  return language || DEFAULT_LOCALE
}

export const useFormatterCache = (options, loader) => {
  const language = useLanguage()
  const locales = [language, DEFAULT_LOCALE]
  const optionsKey = Object.keys(options)
    .sort((a, b) => a.localeCompare(b))
    .map((key) => `${key}-${options[key]}`)
    .join('-')
  const cacheKey = locales.join('-') + optionsKey
  if (!CACHE[cacheKey]) {
    CACHE[cacheKey] = loader(locales, options)
  }
  return CACHE[cacheKey]
}

const isNumber = (value) => typeof value === 'number' && !isNaN(value)
const oneThousand = 1000
const oneMillion = 1000000
const isNumberBetween4And6Digits = (value) =>
  isNumber(value) && value >= oneThousand && value < oneMillion

const isGermanLanguage = (locales) => locales[0] === 'de-DE'
/**
 *
 * @returns {Object} Returns an object containing the decimal and thousands separators.
 */
export const useSeparators = () => {
  const lang = useLanguage()

  const separators = useMemo(() => {
    const testNumberWithDecimalSeparator = 1.1
    const testNumberWithThousandsSeparator = 1000

    const numberWithDecimalSeparator = new Intl.NumberFormat(lang).format(
      testNumberWithDecimalSeparator,
    )
    const decimalSeparator = numberWithDecimalSeparator.substring(1, 2)

    const numberWithThousandsSeparator = new Intl.NumberFormat(lang).format(
      testNumberWithThousandsSeparator,
    )
    const thousandsSeparator = numberWithThousandsSeparator.substring(1, 2)

    return { decimalSeparator, thousandsSeparator }
  }, [lang])

  return separators
}

/**
 * This provides a wrapper with CWP formatting defaults for the Intl API. For
 * optional overrides see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 */
export const useNumberFormatter = ({
  // this
  maximumFractionDigits = 2,
  useGrouping = true,
  ...options
} = {}) =>
  useFormatterCache(
    {
      ...options,
      maximumFractionDigits,
      useGrouping,
    },
    (locales, specificOptions) => {
      const { format } = Intl.NumberFormat(locales, {
        ...specificOptions,
      })
      const { format: germanSpecialFormat } = Intl.NumberFormat(locales, {
        ...specificOptions,
      })
      return (value) => {
        if (!isNumber(value)) {
          return ''
        }
        // Needed since PBB wants to have a dot seperator for four digits numbers even though
        // this is not the german "standard". Instead of writing our own parser, only catch this
        // specific requirement here.
        if (isGermanLanguage(locales) && isNumberBetween4And6Digits(value)) {
          if (options['notation'] === 'compact') {
            return germanSpecialFormat(value / oneThousand) + ' Tsd.'
          }
          return germanSpecialFormat(value)
        }
        return format(value)
      }
    },
  )

/**
 * Helper-method to place the minus before the number instead of before the currency
 * I.e. EUR -8.000,00 instead of -EUR 8.000,00 for english formatting
 * @param parsedString
 * @returns string
 */
const prefixNumberWithMinus = (parsedString) => {
  const minusSignPattern = /-?/
  const digitsPattern = /(-?[\d,.]+)/ // positive or negative values including commas and points
  return parsedString
    .replace(minusSignPattern, '') // removing the minus
    .replace(digitsPattern, `-$1`) // placing the minus at desired position
}

/**
 * This provides a wrapper with CWP formatting defaults for the Intl API. For
 * optional overrides see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 *
 * Do not use currencySign: 'accounting' without expecting suspicious results.
 * The default which we agreed on looks like the following for negative values: EUR -1.000,00
 */
export const useCustomizableCurrencyFormatter = ({
  // this
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
  currencyDisplay = 'code',
  ...options
} = {}) =>
  useFormatterCache(
    {
      ...options,
      style: 'currency',
      maximumFractionDigits,
      minimumFractionDigits,
      currencyDisplay,
    },
    (locales, specificOptions) =>
      (value, currency, optionOverrides = {}) => {
        // Valid currency codes consist of three letters and might change
        // Therefore it is only tested that the currency is not undefined, null or ''
        const { isPotentiallyValidCurrency, format } = getCurrencyIntlNumberFormatOrDefault({
          currency,
          locales,
          specificOptions,
          optionOverrides,
        })
        let parsedString = isNumber(value) ? format(value) : ''
        if (value < 0) parsedString = prefixNumberWithMinus(parsedString)
        return isPotentiallyValidCurrency ? parsedString : parsedString.replace('EUR', 'N/A')
      },
  )
