import PropTypes from 'prop-types'
import Card from 'components/ui/card/Card'
import CardHeaderWithMetrics from 'components/ui/card/CardHeaderWithMetrics'
import usePropertyRentRollComparisonMetric from 'hooks/services/properties/kpis/comparison/usePropertyRentRollComparisonMetric'

const PropertyPortfolioRentRollAnalysisCard = ({
  title,
  primaryMetric,
  secondaryMetric,
  unit,
  isLoading,
  isError,
  formatter,
}) => {
  const comparisonMetric = usePropertyRentRollComparisonMetric({
    isLoading,
    isError,
    formatter,
    primaryValue: primaryMetric?.value,
    comparisonValue: secondaryMetric?.value,
    unit,
  })

  const secondaryMetricPlaceholder = {}

  const secondaryMetrics = secondaryMetric
    ? [
        {
          ...secondaryMetric,
          value: secondaryMetric?.value
            ? `${formatter(secondaryMetric.value)} ${secondaryMetric?.unit}`
            : null,
        },
        comparisonMetric,
      ]
    : [secondaryMetricPlaceholder]

  return (
    <Card>
      <CardHeaderWithMetrics
        title={title}
        primaryMetric={{ ...primaryMetric, value: formatter(primaryMetric.value) }}
        secondaryMetrics={secondaryMetrics}
        isLoading={isLoading}
        isError={isError}
        showReferenceDate={false}
        showPlaceholder={false}
      />
    </Card>
  )
}
PropertyPortfolioRentRollAnalysisCard.propTypes = {
  title: PropTypes.string.isRequired,
  primaryMetric: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired,
  }),
  secondaryMetric: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired,
  }),
  unit: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  formatter: PropTypes.func.isRequired,
}

export default PropertyPortfolioRentRollAnalysisCard
