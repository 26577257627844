import {
  FlexBox,
  FlexBoxDirection,
  Label,
  Option,
  Select,
  TextArea,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { valuationRequestMultiEditOperations } from 'api/property/valuation/valuationRequests'

const operationSelectId = 'valuationRequestNoteField'

const ValuationRequestMultiEditNoteField = ({ disabled, onChange }) => {
  const { t: tField } = useTranslation('translation', {
    keyPrefix: 'components.properties.valuation.multi-edit.fields',
  })
  const { t: tOperations } = useTranslation('translation', {
    keyPrefix: 'components.properties.valuation.multi-edit.operations',
  })
  const [massEditOperation, setMassEditOperation] = useState(
    valuationRequestMultiEditOperations.keep,
  )
  const showNoteField = useMemo(
    () => massEditOperation === valuationRequestMultiEditOperations.replace,
    [massEditOperation],
  )

  const onNoteChange = useCallback(
    ({ target: { value: newValue } }) => {
      onChange({ operation: valuationRequestMultiEditOperations.replace, value: newValue })
    },
    [onChange],
  )
  const handleSelectedOperationChanged = useCallback(
    ({
      detail: {
        selectedOption: { value: operation },
      },
    }) => {
      setMassEditOperation(operation)
      switch (operation) {
        case valuationRequestMultiEditOperations.keep:
          onChange({ operation: valuationRequestMultiEditOperations.keep })
          return
        case valuationRequestMultiEditOperations.clear:
          onChange({ operation: valuationRequestMultiEditOperations.clear })
          return
        case valuationRequestMultiEditOperations.replace:
          onChange({ operation: valuationRequestMultiEditOperations.replace, value: null })
          return
      }
    },
    [onChange],
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label for={`${operationSelectId}-select`} showColon>
        {tField('note.label')}
      </Label>
      <Select
        id={`${operationSelectId}-select`}
        onChange={handleSelectedOperationChanged}
        disabled={disabled}
      >
        <Option
          value={valuationRequestMultiEditOperations.keep}
          selected={massEditOperation === valuationRequestMultiEditOperations.keep}
        >
          {tOperations(valuationRequestMultiEditOperations.keep)}
        </Option>
        <Option
          value={valuationRequestMultiEditOperations.clear}
          selected={massEditOperation === valuationRequestMultiEditOperations.clear}
        >
          {tOperations(valuationRequestMultiEditOperations.clear)}
        </Option>
        <Option
          value={valuationRequestMultiEditOperations.replace}
          selected={massEditOperation === valuationRequestMultiEditOperations.replace}
        >
          {tOperations(valuationRequestMultiEditOperations.replace)}
        </Option>
      </Select>
      {showNoteField && (
        <TextArea id={`${operationSelectId}-textarea`} onInput={onNoteChange} disabled={disabled} />
      )}
    </FlexBox>
  )
}

ValuationRequestMultiEditNoteField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ValuationRequestMultiEditNoteField
