import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

const TableWrappingTextCell = ({ value: text }) => <Text wrapping>{text ?? '-'}</Text>

TableWrappingTextCell.propTypes = {
  value: PropTypes.string,
}

export default TableWrappingTextCell
