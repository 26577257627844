import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  ResponsiveGridLayout,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import SponsorGuarantorOverviewLabeledValue from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/SponsorGuarantorOverviewLabeledValue'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/SponsorGuarantorOverviewTile.module.css'

const SponsorGuarantorOverviewAddress = ({ businessPartner: { address } }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.bp-sponsor-guarantor-overview.address',
  })

  const content = useMemo(() => {
    if (isNil(address)) {
      return (
        <FlexBox
          className={styles.listCardEmptyContent}
          alignItems={FlexBoxAlignItems.Center}
          justifyContent={FlexBoxJustifyContent.Center}
        >
          {t('empty')}
        </FlexBox>
      )
    }
    return (
      <ResponsiveGridLayout columnsXL={2} columnsL={2} columnsM={2} columnsS={2}>
        <FlexBox direction={FlexBoxDirection.Column}>
          {!isNil(address?.streetName) && (
            <SponsorGuarantorOverviewLabeledValue label={t('street')} value={address.streetName} />
          )}
          {!isNil(address?.postalCode) && (
            <SponsorGuarantorOverviewLabeledValue
              label={t('postal-code')}
              value={address.postalCode}
            />
          )}
          {!isNil(address?.city) && (
            <SponsorGuarantorOverviewLabeledValue label={t('city')} value={address.city} />
          )}
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column}>
          {!isNil(address?.country?.name) && (
            <SponsorGuarantorOverviewLabeledValue
              label={t('country')}
              value={address.country.name}
            />
          )}
          {!isNil(address?.region?.name) && (
            <SponsorGuarantorOverviewLabeledValue label={t('region')} value={address.region.name} />
          )}
        </FlexBox>
      </ResponsiveGridLayout>
    )
  }, [address, t])

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.listCard}>
      <Text className={styles.listCardTitle}>{t('title')}</Text>
      {content}
    </FlexBox>
  )
}

SponsorGuarantorOverviewAddress.propTypes = {
  businessPartner: PropTypes.shape({
    address: PropTypes.shape({
      streetName: PropTypes.string,
      postalCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.shape({
        name: PropTypes.string,
      }),
      region: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }),
}

export default SponsorGuarantorOverviewAddress
