import { Option, Select, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { QuestionLayout } from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/forbearanceAndUtpQuestionareSchema'
import style from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/shared/checksheet/DropDownQuestion.module.css'
import Question from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/shared/checksheet/Question'
import questionStyle from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/shared/checksheet/Question.module.css'

const propTypes = {
  label: PropTypes.node.isRequired,
  questionKey: PropTypes.string,
  selectedValue: PropTypes.string,
  isEditMode: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
      displayValue: PropTypes.node,
    }),
  ).isRequired,
  highWidth: PropTypes.bool,
  shrinkValue: PropTypes.number,
}
/**
 * @typedef {object} overrides
 * @property {(newValue: string) => void} [onChange]
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 */
const DropDownQuestion = ({
  questionKey,
  label,
  selectedValue,
  isEditMode,
  onChange,
  selectOptions,
  highWidth = false,
  shrinkValue,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.forbearance-and-utp-questionare',
  })
  const { value, displayValue } = selectedValue ?? {}

  return (
    <Question
      isEditMode={isEditMode}
      label={label}
      layout={QuestionLayout.ROW}
      value={value}
      shrinkValue={shrinkValue}
    >
      {!isEditMode && (
        <Text className={questionStyle.value}>{displayValue || t('empty-value')}</Text>
      )}
      {isEditMode && (
        <Select
          onChange={(e) => onChange?.(questionKey, e.detail.selectedOption.value)}
          className={`${questionStyle.value} ${style.selectWrapper} ${
            highWidth ? style.highWidth : style.width
          }`}
        >
          {selectOptions.map((option, index) => (
            <Option key={`option-${index}`} value={option.value} selected={value === option.value}>
              {option.displayValue}
            </Option>
          ))}
        </Select>
      )}
    </Question>
  )
}

DropDownQuestion.propTypes = propTypes

export default DropDownQuestion
