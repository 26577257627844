import { Button, FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import { useContext, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import VisualRulesDialog from 'components/domains/business-partners/ratings/VisualRulesDialog'
import BusinessPartnerExternalRatingsTile from 'components/domains/business-partners/tile/ratings/BusinessPartnerExternalRatingsTile'
import BusinessPartnerInternalRatingsTile from 'components/domains/business-partners/tile/ratings/BusinessPartnerInternalRatingsTile'
import Page from 'components/ui/page/Page'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import useBusinessPartnerRatings from 'hooks/services/business-partners/ratings/useBusinessPartnerRatings'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import styles from 'routes/business-partners/BusinessPartnerRatingsPage.module.css'

const BusinessPartnerRatingsPage = () => {
  const businessPartner = useContext(BusinessPartnerContext)
  const { data: ratings, isLoading, isError } = useBusinessPartnerRatings(businessPartner.id)
  const [isVrDialogOpen, setIsVrDialogOpen] = useState(false)
  const { t } = useTranslation()

  const onClickOpenInVR = () => {
    setIsVrDialogOpen(true)
  }

  // TODO Technical Debt:
  //  Workaround to retrieve linkToBpInRatingSystemTemplate from backend;
  //  should be configured in frontend instead
  const { data: businessPartnerFull } = useBusinessPartnerById(businessPartner.id)

  const openInVRButton = (
    <Button key="open-in-vr" id="open-in-vr" onClick={onClickOpenInVR}>
      {t('pages.business-partner-ratings.open-in-vr')}
    </Button>
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={businessPartner.id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [businessPartner.id],
  )

  return (
    businessPartnerFull && (
      <>
        <Page
          renderHeaderTitle={() => (
            <BusinessPartnerHeader
              breadcrumbs={[{ text: t('navigation.item.title.business-partner-ratings') }]}
              actions={
                <>
                  <CreateBusinessPartnerEventAction />
                  {openInVRButton}
                  {markFavoriteAction}
                </>
              }
            />
          )}
          renderContent={() => (
            <FlexBox direction={FlexBoxDirection.Column} className={styles.content}>
              <BusinessPartnerInternalRatingsTile ratings={ratings?.internal ?? []} />
              <BusinessPartnerExternalRatingsTile ratings={ratings?.external ?? []} />
            </FlexBox>
          )}
          isError={isError}
          isLoading={isLoading}
        />
        <VisualRulesDialog
          isOpen={isVrDialogOpen}
          onClose={() => setIsVrDialogOpen(false)}
          businessPartnerId={businessPartner.id}
          linkTemplate={businessPartnerFull.linkToBpInRatingSystemTemplate}
        />
      </>
    )
  )
}

export default BusinessPartnerRatingsPage
