import PropTypes from 'prop-types'
import { useCallback, useState, forwardRef } from 'react'
import BusinessPartnerSearchDialog from 'components/domains/business-partners/BusinessPartnerSearchDialog'
import ReferenceMultiSelectionInput from 'components/domains/conditions/reference-selection/ReferenceMultiSelectionInput'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useReferenceNames from 'hooks/services/conditions/reference-selection/useReferenceNames'

const BusinessPartnerMultiInput = forwardRef(
  ({ selectedReferences = [], onDeleteReferences, onClearSelections, handleOnChange }, _ref) => {
    const [isHelperDialogOpen, setIsHelperDialogOpen] = useState(false)

    const { resolvedReferences } = useReferenceNames({
      references: selectedReferences,
    })

    const mappedReferencesToSearchDialog = resolvedReferences?.map(({ entityName, entityId }) => ({
      id: entityId,
      name: entityName,
    }))

    const onClickReferenceSelection = useCallback(() => {
      setIsHelperDialogOpen(true)
    }, [])

    const onClearSelection = useCallback(() => {
      onClearSelections(cwpEntityTypes.BUSINESS_PARTNER)
    }, [onClearSelections])

    const handleOnChanges = useCallback(
      (selectedBusinessPartners) => {
        const bpIds = selectedBusinessPartners.map(({ id }) => ({
          entityId: id,
          entityType: cwpEntityTypes.BUSINESS_PARTNER,
        }))
        handleOnChange(bpIds)
      },
      [handleOnChange],
    )

    const handleSearchClose = useCallback(() => {
      setIsHelperDialogOpen(false)
    }, [])

    return (
      <>
        <ReferenceMultiSelectionInput
          selectedReferences={selectedReferences}
          onClear={onClearSelection}
          onDeleteReference={onDeleteReferences}
          onClickValueHelperIcon={onClickReferenceSelection}
          displayPlaceholderText={false}
          resolveNames
          showValueHelperIcon
        />
        {isHelperDialogOpen && (
          <BusinessPartnerSearchDialog
            open={isHelperDialogOpen}
            onClose={handleSearchClose}
            onChange={handleOnChanges}
            initialBusinessPartners={mappedReferencesToSearchDialog}
            withCreateOption={false}
            isMultiSelect={true}
          />
        )}
      </>
    )
  },
)

BusinessPartnerMultiInput.displayName = 'BusinessPartnerMultiInput'

BusinessPartnerMultiInput.propTypes = {
  selectedReferences: PropTypes.arrayOf(
    PropTypes.shape({
      entityId: PropTypes.string.isRequired,
      entityType: PropTypes.string.isRequired,
    }),
  ),
  onDeleteReferences: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  onClearSelections: PropTypes.func.isRequired,
}

export default BusinessPartnerMultiInput
