import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import largeExposureThresholdsSchema from 'hooks/services/business-partners/risk-figures/largeExposureThresholdsSchema'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useLargeExposureThresholds = () => {
  const response = useCamelizedResponse(
    useRequest({
      path: '/risk-figures/configurations/large-exposure-thresholds',
      keys: ['risk-figures', 'configurations', 'large-exposure-thresholds'],
    }),
  )

  const data = useMemo(
    () => (response.data ? largeExposureThresholdsSchema.parse(response.data) : undefined),
    [response.data],
  )
  if (!data) {
    return response
  }
  const parsedResponse = {
    ...response,
    data: {
      ...data,
      largeExposureLimit: {
        value: data.largeExposureLimit.value,
        unit: data.largeExposureLimit.currency,
      },
      largeExposureReportingThreshold: {
        value: data.largeExposureReportingThreshold.value,
        unit: data.largeExposureReportingThreshold.currency,
      },
    },
  }

  return parsedResponse
}
