import { useCallback, useState } from 'react'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'

/**
 * @typedef {object} UseErrorMessageBoxWithExpandableDetails
 * @property {boolean} isErrorMessageOpen
 * @property {function} onErrorMessageBoxClose
 * @property {string|undefined} errorDetails
 * @property {function} setError
 */

/**
 * Provides the state management and error formatting for the ErrorMessageBoxWithExpandableDetails
 * @returns {UseErrorMessageBoxWithExpandableDetails}
 */
export const useErrorMessageBoxWithExpandableDetails = () => {
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false)
  const [errorDetails, setErrorDetails] = useState(undefined)

  const setError = useCallback(async (error) => {
    try {
      setErrorDetails(await formatHookError(error))
      setIsErrorMessageOpen(true)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Error while parsing error message', e)
    }
  }, [])

  const onErrorMessageBoxClose = useCallback(() => {
    setIsErrorMessageOpen(false)
    setErrorDetails(undefined)
  }, [])

  return { isErrorMessageOpen, onErrorMessageBoxClose, errorDetails: errorDetails, setError }
}
