import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

/**
 * @typedef {{value: number, unit: string}} FiscalFigure
 * @typedef {{start_date: string, end_date: string, currency: string, reference_id: string, fiscal_figures: Record<string, FiscalFigure>}} FiscalYearValue
 * @typedef {{ordinal_position: number, format: string, group: string, importance: number, display_name: string}} FiscalFigureTypeValue
 * @typedef {{ordinal_position: number, display_name: string}} FiscalFigureGroupValue
 * @typedef {{fiscal_years: Record<string, FiscalYearValue>, fiscal_figure_types: Record<string, FiscalFigureTypeValue>, fiscal_figure_groups: Record<string, FiscalFigureGroupValue>}} FiscalYearsResponse
 * @param {{businessPartnerIds: string[], options: import('@tanstack/react-query').QueryOptions}} options
 * @return {import('@tanstack/react-query').UseQueryResult<FiscalYearsResponse>[]}
 */
export const useMultipleFiscalYears = ({ businessPartnerIds, options }) => {
  const requests = businessPartnerIds.map((businessPartnerId) => ({
    path: `/businesspartners/${businessPartnerId}/fiscal-years`,
    keys: ['businesspartners', businessPartnerId, 'fiscal-years'],
  }))
  const responses = useRequestsMemo(useRequests({ requests, options }))
  return useMemo(
    () =>
      responses.map((result) => ({
        ...result,
        data: result.data, // this value is explicitly not camelized
      })),
    [responses],
  )
}
