import { propertyMonitoringStatus } from 'api/property/monitoring/propertyMonitoringConfiguration'
const { default: ValueState } = require('@ui5/webcomponents-base/dist/types/ValueState')

const determineValueStateBasedOnMonitoringStatus = (monitoringStatus) => {
  switch (monitoringStatus) {
    case propertyMonitoringStatus.inactive:
      return ValueState.None
    case propertyMonitoringStatus.activeAutomated:
    case propertyMonitoringStatus.activeManual:
    case propertyMonitoringStatus.activeManualForced:
      return ValueState.Success
    case propertyMonitoringStatus.paused:
      return ValueState.Warning
    default:
      return ValueState.None
  }
}

export default determineValueStateBasedOnMonitoringStatus
