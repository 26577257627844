import { ValueState } from '@fioneer/ui5-webcomponents-react'

const determinePropertyValueStateBasedOnStatus = (status) => {
  switch (status) {
    case 'Active':
      return ValueState.Success
    case 'Inactive':
      return ValueState.Warning
    case 'Created':
    default:
      return ValueState.Information
  }
}

export default determinePropertyValueStateBasedOnStatus
