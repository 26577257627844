import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useMarketTypeChangeInformation from 'hooks/services/markets/useMarketTypeChangeInformation'

const MarketEditMarketTypeDialog = ({
  isOpen,
  handleConfirmation,
  handleCancel,
  marketId,
  newMarketTypeCode,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.markets.detail.edit-market-type.dialog',
  })

  const {
    data: marketTypeChangeInformation,
    isLoading,
    isError,
  } = useMarketTypeChangeInformation({ marketId, newMarketTypeCode })

  const renderContent = () => {
    const {
      number_of_affected_properties: affectedPropertiesAmount,
      number_of_assigned_properties: assignedPropertiesAmount,
    } = marketTypeChangeInformation

    return (
      <ConfirmationDialog
        isOpen={isOpen}
        handleConfirmation={handleConfirmation}
        handleCancel={handleCancel}
        confirmationMessage={t('confirmation.message', {
          affectedPropertiesAmount,
          assignedPropertiesAmount,
        })}
        confirmationTitle={t('confirmation.title')}
      />
    )
  }

  return (
    <LoadingStateWrapper
      isError={isError}
      isLoading={isLoading}
      renderContent={renderContent}
      errorDescription={t('error.description')}
      errorDetails=" "
      errorTitle={t('error.title')}
    />
  )
}

MarketEditMarketTypeDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  marketId: PropTypes.string.isRequired,
  newMarketTypeCode: PropTypes.string.isRequired,
}

export default MarketEditMarketTypeDialog
