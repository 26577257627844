import PropTypes from 'prop-types'
import PropertiesOverviewTableCardHeader from 'components/domains/properties/PropertiesOverviewTableCardHeader'
import useCurrentMultiPropertyKpis from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpis'
import useMultiPropertyValuationsDecisionPaper from 'hooks/services/properties/valuations/useMultiPropertyValuationsDecisionPaper'

const DealPropertiesHeader = ({ propertyUuids, currency }) => {
  // CWP-16174: Improve performance and decrease load on backend system by using common queries for multiple use cases
  const { data: multiPropertyKpis } = useCurrentMultiPropertyKpis(propertyUuids, {
    currency: currency,
    withCityGranularity: true,
    withCountryGranularity: true,
    withPropertyTypeGranularity: true,
    withTenantIndustryGranularity: true,
    withSegmentUsageTypeGranularity: true,
    withTegovaRatingGranularity: true,
  })
  // CWP-16174: Improve performance and decrease load on backend system for Deal -> Financed objects page
  // Different tiles on the Deal -> Financed objects page require similar valuations and kpi data.
  // There are slightly different requirements for each tile with respect to needed aggregations and granularities.
  // We now request the same superset of valuation and kpi data at each location, so the result is just one single request
  // A further improvement idea might be: Move the valuation and kpi data to a context.
  // Look for similar comments like this to find other consolidated hook usages.
  // Some examples are:  DealPropertiesHeader, DealPropertiesTable, PropertyPortfolioInformationCard,
  // PropertyPortfolioOverviewTableCard and usePropertyPortfolioOverviewPieChartCardData
  const { data: { sum: valuationSum = {} } = {} } = useMultiPropertyValuationsDecisionPaper(
    propertyUuids,
    ['sum'],
    currency,
    {
      enabled: !!propertyUuids.length,
    },
    ['city', 'country', 'propertyType', 'tegovaRating'],
  )
  return (
    <PropertiesOverviewTableCardHeader
      multiPropertyKpis={multiPropertyKpis}
      totalValuation={valuationSum}
      totalNumberOfProperties={propertyUuids?.length}
    />
  )
}
DealPropertiesHeader.propTypes = {
  propertyUuids: PropTypes.arrayOf(PropTypes.string),
  currency: PropTypes.string,
}

export default DealPropertiesHeader
