import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import LoadingMultiComboBox from 'components/ui/multi-combo-box/LoadingMultiComboBox'

const PropertiesFilterStatusComboBox = React.forwardRef(
  ({ selectionName, selectedValues, values, handleMultiComboboxChange, disabled }, _ref) => {
    const { t } = useTranslation()

    const getStatusCodeDataMapping = () => ({
      data: Object.keys(values).map((key) => ({
        code: key,
        displayName: t(values[key].text),
      })),
      isLoading: false,
      isError: false,
    })

    const handleOnSelectionChange = (event) => {
      handleMultiComboboxChange(event, selectionName)
    }

    return (
      <LoadingMultiComboBox
        id={`properties_filter_${selectionName}`}
        useLoader={getStatusCodeDataMapping}
        selected={selectedValues}
        disabled={disabled}
        onSelectionChange={handleOnSelectionChange}
      />
    )
  },
)

PropertiesFilterStatusComboBox.displayName = 'PropertiesFilterStatusComboBox'

PropertiesFilterStatusComboBox.propTypes = {
  selectionName: PropTypes.string,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  values: PropTypes.shape({
    objectStatus: PropTypes.string,
    translationKey: PropTypes.string,
  }),
  handleMultiComboboxChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export default PropertiesFilterStatusComboBox
