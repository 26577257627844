import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { evaluateRetry } from 'hooks/services/retryHelper'

const usePropertyIdForAssignedMarket = (propertyUuids = [], queryOptions = {}) => {
  const { post } = useAccessTokenRequest()
  const { i18n } = useTranslation()
  return useQuery({
    queryKey: ['markets', `propertyIds=${propertyUuids}`, i18n.language],
    refetchOnWindowFocus: false,
    staleTime: 1,
    retry: evaluateRetry,
    queryFn: async () => {
      const { data } = await post({
        path: '/markets/properties',
        body: {
          property_uuids: propertyUuids,
        },
      })
      return data
    },
    ...queryOptions,
    enabled: !!propertyUuids.length || !!queryOptions?.enabled,
  })
}

export default usePropertyIdForAssignedMarket
