import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/deal-search/DealSearchTable.module.css'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import {
  TrancheSearchTableColumns,
  TrancheSearchTableMode,
} from 'routes/deals/financing/shared-components/tranches-search-dialog/TrancheSearchTableEnums'
import {
  useTrancheSearchColumnDefinitions,
  useTrancheSearchTableData,
} from 'routes/deals/financing/shared-components/tranches-search-dialog/useTrancheSearchTableHelper'

/**
 * DealSearchTable
 * - mode: TrancheSearchTableMode
 * - columnsRestrictedTo: null | Array<TrancheSearchTableColumns>
 */
const TrancheSearchTable = ({
  deal,
  tranches,
  mode,
  onSelectionChanged = () => {},
  selectedTrancheIds = [],
  disabledTrancheIds = [],
  columnsRestrictedTo = null,
  additionalToolbarProperties = {},
  showInitPlaceholder = false,
  isAllowedToReadTranche,
  isError,
  isLoading,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.financing.tranche-search-dialog',
  })

  const noDataText = isError ? t('table.error') : t('table.no-data')

  const mapTableData = useTrancheSearchTableData({
    deal,
    selectedTrancheIds,
    isAllowedToReadTranche,
    disabledTrancheIds,
    mode,
    onSelectionChanged,
  })

  const columnDefinitions = useTrancheSearchColumnDefinitions({ mode, columnsRestrictedTo })
  const tableData = mapTableData(tranches)

  const tableProperties = () => ({
    mode,
    stickyColumnHeader: true,
    onSelectionChange: onSelectionChanged,
    busy: isLoading,
  })

  return (
    <div className={`${!isAllowedToReadTranche && styles['default-cursor']}`}>
      <SortedTable
        columnDefinitions={columnDefinitions}
        tableData={tableData}
        toolbarConfig={{
          title: t('table.title'),
          ...additionalToolbarProperties,
        }}
        noDataText={noDataText}
        additionalTableProperties={tableProperties()}
        {...(showInitPlaceholder
          ? { tableData: [], noDataText: t('table.init-placeholder-text'), paginationConfig: {} }
          : {})}
      />
    </div>
  )
}

export default TrancheSearchTable

TrancheSearchTable.propTypes = {
  tranches: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  additionalToolbarProperties: PropTypes.object,
  mode: PropTypes.oneOf(Object.values(TrancheSearchTableMode)),
  onSelectionChanged: PropTypes.func,
  selectedTrancheIds: PropTypes.arrayOf(PropTypes.string),
  deal: PropTypes.shape({
    dealUuid: PropTypes.string.isRequired,
    dealId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }),
  disabledTrancheIds: PropTypes.arrayOf(PropTypes.string),
  columnsRestrictedTo: PropTypes.arrayOf(PropTypes.oneOf(Object.values(TrancheSearchTableColumns))),
  isAllowedToReadTranche: PropTypes.bool,
  loadMore: PropTypes.func,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  showInitPlaceholder: PropTypes.bool,
}
