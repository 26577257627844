import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxWrap,
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
  Label,
  TableCell,
  TableColumn,
  TableRow,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperTileTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileTable'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants-and-ratios/CovenantsRatiosCovenantsOverviewTile.module.css'
import LimitCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants-and-ratios/shared/LimitCell'
import ReferenceEntitiesView from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants-and-ratios/shared/ui/ReferenceEntitiesView'
import useFormatRelativeLimit from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants-and-ratios/shared/useFormatRelativeLimit'

const CovenantsRatiosCovenantsOverviewTile = ({ tileId, isPdfView }) => {
  const {
    data: { covenants },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const covenantsSorted = useMemo(
    () =>
      _.orderBy(
        covenants,
        ['status', 'covenantDefinition.type', 'covenantDefinition.name'],
        ['asc', 'asc', 'asc'],
      ),
    [covenants],
  )

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.covenants-ratios-overview',
    lng: 'en',
  })

  const { format: formatRelativeLimit } = useFormatRelativeLimit()

  const formatLimitTimePeriod = useCallback(
    (limit) => `${formatRelativeLimit(limit.period, `${limit.validFrom}-${limit.validTo}`)}:`,
    [formatRelativeLimit],
  )

  if (covenants?.length === 0)
    return (
      <IllustratedMessage
        name={IllustrationMessageType.NoEntries}
        size={IllustrationMessageSize.Scene}
      />
    )

  return (
    <div className={styles.tile}>
      <DecisionPaperTileTable
        showColumnBorders={false}
        columns={
          <>
            <TableColumn>
              <Label>{t('covenant')}</Label>
            </TableColumn>
            <TableColumn>
              <Label>{t('type')}</Label>
            </TableColumn>
            <TableColumn>
              <Label>{t('reference-entity')}</Label>
            </TableColumn>
            <TableColumn>
              <Label>{t('soft-limit')}</Label>
            </TableColumn>
            <TableColumn>
              <Label>{t('hard-limit')}</Label>
            </TableColumn>
            <TableColumn>
              <Label>{t('loan-sizing')}</Label>
            </TableColumn>
          </>
        }
      >
        {covenantsSorted?.map(
          ({
            covenantDefinition,
            covenantUuid,
            currencyCode,
            softLimits,
            hardLimits,
            loanSizing,
            referenceEntities,
          }) => (
            <TableRow key={covenantUuid}>
              <TableCell data-column="covenant">
                <Label wrappingType={WrappingType.Normal}>
                  <b>{covenantDefinition.name}</b>
                </Label>
              </TableCell>
              <TableCell data-column="type">
                <Label>
                  {covenantDefinition.type === 'FINANCIAL' && t('financial')}
                  {covenantDefinition.type === 'NON_FINANCIAL' && t('non-financial')}
                </Label>
              </TableCell>
              <TableCell data-column="reference-entities">
                <ReferenceEntitiesView isPdfView={isPdfView} {...referenceEntities} />
              </TableCell>
              <TableCell data-column="soft-limits">
                {softLimits.map((limit) => (
                  <FlexBox
                    direction={FlexBoxDirection.Row}
                    wrap={FlexBoxWrap.Wrap}
                    key={limit.clvrId}
                    className={styles.limitValue}
                  >
                    <Label>{formatLimitTimePeriod(limit)}</Label>
                    <LimitCell {...limit} />
                  </FlexBox>
                ))}
              </TableCell>
              <TableCell data-column="hard-limits">
                {hardLimits.map((limit) => (
                  <FlexBox
                    direction={FlexBoxDirection.Row}
                    wrap={FlexBoxWrap.Wrap}
                    key={limit.clvrId}
                    className={styles.limitValue}
                  >
                    <Label>{formatLimitTimePeriod(limit)}</Label>
                    <LimitCell {...limit} />
                  </FlexBox>
                ))}
              </TableCell>
              <TableCell data-column="loan-sizing">
                <LimitCell
                  unit={covenantDefinition?.unit}
                  value={loanSizing?.value}
                  currencyCode={currencyCode}
                />
              </TableCell>
            </TableRow>
          ),
        )}
      </DecisionPaperTileTable>
    </div>
  )
}

CovenantsRatiosCovenantsOverviewTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default CovenantsRatiosCovenantsOverviewTile
