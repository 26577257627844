import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/comment/CovenantCheckCommentTile.module.css'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/comment/shared/EmptyCardContent'
import FormattedTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/comment/shared/rich-text-editor/FormattedTextView'

const CovenantCheckCommentTile = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.covenant-check-comment',
  })

  const { data: tileData } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const comment = tileData?.comment

  if (isNil(comment?.content)) {
    return (
      <EmptyCardContent
        title={t('components.cards.empty.title')}
        subtitle={t('components.cards.empty.subtitle')}
      />
    )
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.wrapper}>
      <FormattedTextView formattedText={comment?.content} />
    </FlexBox>
  )
}

CovenantCheckCommentTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}
export default CovenantCheckCommentTile
