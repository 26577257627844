import { ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { isBadRequestError } from 'api/requests'
import { createPolygonsFromWKT } from 'components/domains/util/PolygonFileUploadUtil'
import FileUploaderButton from 'components/ui/FileUploaderButton'
import Card from 'components/ui/card/Card'
import CardHeaderWithEditMode from 'components/ui/card/CardHeaderWithEditMode'
import EditableLeafletMapWithPolygonAndPropertyMarkers from 'components/ui/map/EditableLeafletMapWithPolygonAndPropertyMarker'
import {
  getBounds,
  getBoundingBoxCenter,
  polygonsToCoordArray,
} from 'components/ui/map/MapPolygonUtils'
import { useShowMessageBox, MessageBoxTypes } from 'components/ui/message-box/MessageBox'
import useEditMarketArea from 'hooks/services/markets/useEditMarketArea'

const blueOptions = { color: 'blue' }

const MarketMap = ({
  marketExists,
  marketId,
  polygon,
  propertyData,
  propertyIsLoading,
  propertyIsError,
  filteredPropertiesWithLink,
  showEditButton,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.markets.detail.market-area',
  })
  const queryClient = useQueryClient()
  const showMessageBox = useShowMessageBox()
  const [isMarketMapEditModeEnabled, setIsMarketMapEditModeEnabled] = useState(false)
  const [uploadedPolygon, setUploadedPolygon] = useState()
  const [newPolygonPoints, setNewPolygonPoints] = useState([])
  const [center, setCenter] = useState([0, 0])
  const [bounds, setBounds] = useState([])
  const editMarketMutation = useEditMarketArea()

  const handleSaveEditMarketMapChanges = useCallback(() => {
    if (JSON.stringify(polygon) !== JSON.stringify(newPolygonPoints)) {
      editMarketMutation.mutate(
        {
          marketId: marketId,
          marketPolygonCoordinates: newPolygonPoints,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['market-area', marketId])
          },
          onError: (error) => {
            const errorMessage = isBadRequestError(error)
              ? t('area-update-failed.polygon-not-linear-ring.error.description')
              : t('area-update-failed.error.description')

            showMessageBox({
              type: MessageBoxTypes.Error,
              children: errorMessage,
            })
          },
        },
      )
    }

    setIsMarketMapEditModeEnabled(false)
  }, [editMarketMutation, marketId, newPolygonPoints, polygon, queryClient, showMessageBox, t])

  const handleCancelEditMarketMapChanges = () => {
    setIsMarketMapEditModeEnabled(false)
  }

  const handleFileUpload = (event) =>
    new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = () => {
        try {
          const text = reader.result
          const polygonsFromWKT = createPolygonsFromWKT(text)
          setUploadedPolygon(polygonsFromWKT)
          setNewPolygonPoints(polygonsFromWKT)
          const coordArray = polygonsToCoordArray(polygonsFromWKT)
          setCenter(getBoundingBoxCenter(coordArray))
          setBounds(getBounds(coordArray))
        } catch (e) {
          alert(t('edit-market-area.polygon-upload-error'))
        }
        resolve()
      }
      reader.readAsText(event.target.files[0])
    })

  const fileUploadButton = (
    <FileUploaderButton
      text={t('edit-market-area.polygon-upload-text')}
      design={ButtonDesign.Default}
      onChange={handleFileUpload}
      disabled={!marketExists}
    />
  )

  const header = (
    <CardHeaderWithEditMode
      isEditMode={isMarketMapEditModeEnabled}
      titleText={t('edit-market-area.market-area.text')}
      onToggle={setIsMarketMapEditModeEnabled}
      onSave={handleSaveEditMarketMapChanges}
      onCancel={handleCancelEditMarketMapChanges}
      showActions={showEditButton}
      additionalActions={fileUploadButton}
    />
  )

  return (
    <>
      {header}
      <Card className="map-wrapper">
        <EditableLeafletMapWithPolygonAndPropertyMarkers
          color={blueOptions}
          polygon={polygon}
          markers={filteredPropertiesWithLink}
          isEditMode={isMarketMapEditModeEnabled}
          propertyData={propertyData}
          propertyIsLoading={propertyIsLoading}
          propertyIsError={propertyIsError}
          newPolygonPoints={newPolygonPoints}
          setNewPolygonPoints={setNewPolygonPoints}
          uploadedPolygon={uploadedPolygon}
          center={center}
          setCenter={setCenter}
          bounds={bounds}
          setBounds={setBounds}
        />
      </Card>
    </>
  )
}

MarketMap.propTypes = {
  marketExists: PropTypes.bool.isRequired,
  marketId: PropTypes.string.isRequired,
  propertyData: PropTypes.shape({
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
        financingStatusCode: PropTypes.string,
      }),
    ),
  }).isRequired,
  propertyIsLoading: PropTypes.bool.isRequired,
  propertyIsError: PropTypes.bool.isRequired,
  filteredPropertiesWithLink: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      address: PropTypes.exact({
        country: PropTypes.string,
        street: PropTypes.string,
        houseNumber: PropTypes.string,
        zipCode: PropTypes.string,
        city: PropTypes.string,
      }),
      propertyType: PropTypes.string,
      location: PropTypes.exact({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      }),
    }),
  ),
  polygon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  showEditButton: PropTypes.bool.isRequired,
}

export default MarketMap
