import {
  PROPERTY_PBB_GREEN_SCORE,
  PROPERTY_PBB_GREEN_SCORE_SORT_MAX,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/constants'

// Business logic for mapping the green score values https://fioneer.atlassian.net/browse/CWP-11696
export const mapGreenScoreRowValue = (rowDealUuid, data, sort) => {
  const rowDealProperties = data?.find(({ dealUuid }) => dealUuid === rowDealUuid)?.properties

  const greenScores = rowDealProperties
    ?.map((property) => {
      const customField = property?.customFields?.find(
        ({ key }) => key === PROPERTY_PBB_GREEN_SCORE,
      )
      return {
        propertyUuid: property?.uuid,
        propertyId: property?.id,
        propertyName: property?.description,
        customField: customField,
      }
    })
    ?.reduce((prev, curr) => {
      if (sort === PROPERTY_PBB_GREEN_SCORE_SORT_MAX) {
        return prev.customField?.value > curr.customField?.value ? prev : curr
      } else {
        return prev.customField?.value < curr.customField?.value ? prev : curr
      }
    })

  return rowDealProperties?.length > 1 ? greenScores : greenScores?.customField?.value
}
