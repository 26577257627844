import { useRequest } from 'hooks/services/baseService'

/**
 * Returns all property usageCodes keys and their localized display name
 * The usageCode structure is as follows:
 * {
 *   "utilization_codes": [
 *       {
 *           "key": "000001",
 *           "display_name": "Owner Occupied",
 *           "type: "self",
 *           "isWriteCode: "true"
 *       },
 *       {
 *           "key": "000003",
 *           "display_name": "Rented or Leased",
 *           "type: "let",
 *           "isWriteCode: null
 *       },
 *       [...]
 *   ]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: utilizationCodes}}
 *
 */
export const useUtilizationCodes = () =>
  useRequest({
    path: '/properties/utilization_codes',
    translated: true,
  })

export const useUtilizationCodesInSpecifiedLanguage = (language) =>
  useRequest({
    path: '/properties/utilization_codes',
    translated: false,
    keys: ['properties', 'utilization-codes', language],
    requestOptions: { headers: { 'Accept-Language': language } },
  })
