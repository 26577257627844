import PropTypes from 'prop-types'

export const monitoringInformationPropTypes = PropTypes.shape({
  monitoringReferenceEntity: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }),
  firstDueDate: PropTypes.shape({
    checkDate: PropTypes.string,
    keyDate: PropTypes.string,
    deliveryDate: PropTypes.string,
  }),
  checkCycle: PropTypes.shape({
    number: PropTypes.number,
    cycle: PropTypes.string,
  }),
  lastChecked: PropTypes.string,
  comment: PropTypes.string,
})

export const covenantCheckPropTypes = PropTypes.shape({
  covenantCheckUuid: PropTypes.string,
  name: PropTypes.string,
  keyDate: PropTypes.string,
  firstDeliveryDate: PropTypes.string,
  linkedEventId: PropTypes.string,
})

export const covenantDefinitionShape = PropTypes.shape({
  checkCycle: PropTypes.shape({ modifiable: PropTypes.bool }),
  existingCheckCycles: PropTypes.arrayOf(PropTypes.string),
})
