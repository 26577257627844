import {
  CheckBox,
  ComboBox,
  ComboBoxItem,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  SuggestionItem,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/tasks/dialogs/AddTaskDialog.module.css'
import Dialog, { DialogPrimaryButton, DialogSize } from 'components/ui/dialog/Dialog'
import EditableDatePickerComponent from 'components/ui/input/EditableDatePickerComponent'
import InputWithSearchSuggestions from 'components/ui/input/InputWithSearchSuggestions'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import CWPCKEditor from 'components/ui/rich-text-editor/CWPCKEditor'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { useTaskCreation } from 'hooks/services/business-events-and-tasks/tasks/useTaskCreation'
import useTaskTemplates from 'hooks/services/business-events-and-tasks/tasks/useTaskTemplates'
import useStaffMembers from 'hooks/services/business-partners/staff-members/useStaffMembers'
import {
  resetTaskCreation,
  setTaskName,
  setAssignee,
  setDueDate,
  setIsMandatory,
  setDescription,
} from 'redux/slices/tasks/taskCreationSlice'

const maxCharacterAmount = 2000

const AddTaskDialog = ({ eventId, isOpen, setIsOpen }) => {
  const client = useQueryClient()
  const { parse, localePattern } = useShortDateFormatter()
  const dispatch = useDispatch()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.tasks.dialogs.add',
  })
  const showMessageBox = useShowMessageBox()
  const [formErrors, setFormErrors] = useState({})
  const [userSearchKey, setUserSearchKey] = useState('')
  const [usedDescriptionCharacters, setUsedDescriptionCharacters] = useState(0)
  const { taskName, assignee, dueDate, isMandatory, description } = useSelector(
    (state) => state.tasks.taskCreation,
  )
  const {
    data: taskTemplatesResult,
    isLoading: isTemplatesResultLoading,
    isError: isTaskTemplatesResultError,
  } = useTaskTemplates(eventId)
  const { data: userData, isError } = useStaffMembers({
    name: userSearchKey,
    minLength: 1,
  })

  const onCreateSuccess = useCallback(() => {
    dispatch(resetTaskCreation())
    setIsOpen(false)
    client.invalidateQueries(['events', eventId])
  }, [client, dispatch, eventId, setIsOpen])

  const onCreateError = useCallback(() => {
    setIsOpen(false)
    showMessageBox({ type: MessageBoxTypes.Error, children: t('error.description') })
  }, [setIsOpen, showMessageBox, t])

  const { mutate: createTask } = useTaskCreation()

  const handleOnAfterClose = useCallback(() => {
    setUserSearchKey('')
    dispatch(resetTaskCreation())
    setIsOpen(false)
  }, [dispatch, setIsOpen])

  const handleCreate = useCallback(() => {
    createTask(
      {
        eventId,
        name: taskName,
        description: description,
        isMandatory: isMandatory,
        assignee: assignee,
        dueDate: dueDate,
      },
      {
        onSuccess: onCreateSuccess,
        onError: onCreateError,
      },
    )
  }, [
    assignee,
    createTask,
    description,
    dueDate,
    eventId,
    isMandatory,
    onCreateError,
    onCreateSuccess,
    taskName,
  ])

  const handleTaskNameChange = useCallback(
    ({ target: { value: newTaskName } }) => {
      if (newTaskName === '') {
        setFormErrors((oldState) => ({ ...oldState, taskName: true }))
      }
      const template = taskTemplatesResult?.taskTemplates.find(({ name }) => name === newTaskName)

      if (template) {
        dispatch(setTaskName(template.name))
        dispatch(setDescription(template.description))
        dispatch(setIsMandatory(template.isMandatory))
        dispatch(setDueDate(template.dueDate ?? null))
        return
      }
      dispatch(setTaskName(newTaskName))
    },
    [dispatch, taskTemplatesResult?.taskTemplates],
  )

  const handleIsMandatoryChange = useCallback(
    (event) => {
      dispatch(setIsMandatory(event.target.checked))
    },
    [dispatch],
  )

  const handleDueDateChange = useCallback(
    (event) => {
      const date = parse(event.detail.value, localePattern)
      dispatch(setDueDate(date))
    },
    [dispatch, localePattern, parse],
  )

  const handleDescriptionChange = useCallback(
    (newDescription) => {
      dispatch(setDescription(newDescription))
    },
    [dispatch],
  )

  const handleAssigneeChange = useCallback(
    (event) => {
      dispatch(setAssignee(event.detail?.item.getAttribute('data-user-id')))
    },
    [dispatch],
  )

  const handleOnClearAssignee = useCallback(() => {
    dispatch(setAssignee(null))
  }, [dispatch])

  const disableButton = !taskName || usedDescriptionCharacters > maxCharacterAmount

  const renderSuggestions = useCallback(() => {
    const suggestionsItems = []
    userData?.staffMembers.forEach((user, index) => {
      suggestionsItems.push(
        <SuggestionItem key={index} text={user.fullName} data-user-id={user.id} />,
      )
    })
    return suggestionsItems
  }, [userData?.staffMembers])

  const valueStateMessage = useMemo(
    () => (
      <Text>
        {isTaskTemplatesResultError
          ? t('error-states.task-templates')
          : t('error-states.task-name')}
      </Text>
    ),
    [isTaskTemplatesResultError, t],
  )

  const valueState = useCallback(() => {
    if (isTaskTemplatesResultError) {
      return ValueState.Error
    }

    return formErrors.taskName ? ValueState.Error : ValueState.None
  }, [formErrors.taskName, isTaskTemplatesResultError])

  const onInputTaskName = useCallback(({ target: { value: newTaskName } }) => {
    if (newTaskName !== '') {
      setFormErrors((oldState) => ({ ...oldState, taskName: false }))
    }
  }, [])

  const onUsedCharactersChanged = useCallback((newDescriptionCharacters) => {
    setUsedDescriptionCharacters(newDescriptionCharacters)
  }, [])

  return (
    <Dialog
      headerText={t('title')}
      open={isOpen}
      size={DialogSize.L}
      onAfterClose={handleOnAfterClose}
      primaryButton={
        <DialogPrimaryButton disabled={disableButton} onClick={handleCreate}>
          {t('buttons.create')}
        </DialogPrimaryButton>
      }
    >
      <FlexBox direction={FlexBoxDirection.Column} className={styles.dialogWrapper}>
        <FlexBox direction={FlexBoxDirection.Column}>
          <Label for="task-name-combo-box" required={true} showColon>
            {t('labels.task-name')}
          </Label>
          <FlexBox
            justifyContent={FlexBoxJustifyContent.SpaceBetween}
            alignItems={FlexBoxAlignItems.Center}
          >
            <ComboBox
              id="task-name-combo-box"
              loading={isTemplatesResultLoading}
              value={taskName}
              onChange={handleTaskNameChange}
              onInput={onInputTaskName}
              required
              valueState={valueState()}
              valueStateMessage={valueStateMessage}
            >
              {taskTemplatesResult?.taskTemplates.map(({ name }, index) => (
                <ComboBoxItem key={`combobox-item-${name}-${index}`} text={name} />
              ))}
            </ComboBox>
            <CheckBox
              text={t('labels.is-mandatory')}
              checked={isMandatory}
              onChange={handleIsMandatoryChange}
              className={styles.mandatoryCheckBox}
            />
          </FlexBox>
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column}>
          <Label for="assignee-input" showColon>
            {t('labels.assignee')}
          </Label>
          <InputWithSearchSuggestions
            id={'assignee-input'}
            initialValue={userSearchKey}
            renderSuggestions={renderSuggestions}
            handleOnClear={handleOnClearAssignee}
            handleOnChange={handleAssigneeChange}
            setSearchKey={setUserSearchKey}
            isSuggestionsError={isError}
          />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column}>
          <Label for="due-date-picker" showColon>
            {t('labels.due-date')}
          </Label>
          <EditableDatePickerComponent
            id="due-date-picker"
            value={dueDate}
            editable={true}
            onChange={handleDueDateChange}
          />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column}>
          <Label for="description-input" className={styles.labelPadding} showColon>
            {t('labels.description')}
          </Label>
          <CWPCKEditor
            setCurrentContent={handleDescriptionChange}
            currentContent={description}
            maxCharacterAmount={maxCharacterAmount}
            onUsedCharactersChanged={onUsedCharactersChanged}
          />
        </FlexBox>
      </FlexBox>
    </Dialog>
  )
}

AddTaskDialog.propTypes = {
  eventId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default AddTaskDialog
