import '@ui5/webcomponents-icons/dist/AllIcons.js'
import {
  BusyIndicator,
  BusyIndicatorSize,
  ObjectStatus,
  Popover,
  PopoverPlacementType,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import uniqueId from 'lodash.uniqueid'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'

const SmallLoadingWrapper = ({
  id,
  isLoading,
  isError,
  error,
  showErrorInHover = false,
  renderContent,
}) => {
  const [isErrorHoverShowing, setIsErrorHoverShowing] = useState(false)
  const [internalId] = useState(id || uniqueId('loading-wrapper'))
  if (isLoading) {
    return <BusyIndicator id={internalId} active delay={0} size={BusyIndicatorSize.Small} />
  }

  if (isError) {
    if (showErrorInHover) {
      return (
        <>
          <ObjectStatus
            style={{ verticalAlign: 'baseline' }}
            id={internalId}
            onMouseEnter={() => {
              setIsErrorHoverShowing(true)
            }}
            onMouseLeave={() => {
              setIsErrorHoverShowing(false)
            }}
            showDefaultIcon
            state={ValueState.Error}
          />
          {createPortal(
            <Popover
              opener={internalId}
              open={isErrorHoverShowing}
              placementType={PopoverPlacementType.Top}
            >
              {error}
            </Popover>,
            document.body,
          )}
        </>
      )
    }
    return <>{error}</>
  }

  return renderContent()
}

SmallLoadingWrapper.propTypes = {
  id: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  error: PropTypes.node.isRequired,
  renderContent: PropTypes.func.isRequired,
  showErrorInHover: PropTypes.bool,
}

export default SmallLoadingWrapper
