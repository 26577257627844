import { Label } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import RequirementsTableSubcomponentAttachments from 'components/domains/conditions/overview/table-cells/view/RequirementsTableSubcomponentAttachments'

const RequirementsTableAttachmentsSubcomponent = ({ requirementId, showDeleteDocumentButton }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.requirements.table' })

  return (
    <div>
      <Label>{t('label.attachments')}</Label>
      {!isNil(requirementId) ? (
        <RequirementsTableSubcomponentAttachments
          requirementId={requirementId}
          showDeleteDocumentButton={showDeleteDocumentButton}
        />
      ) : (
        '-'
      )}
    </div>
  )
}

RequirementsTableAttachmentsSubcomponent.propTypes = {
  requirementId: PropTypes.string,
  showDeleteDocumentButton: PropTypes.bool.isRequired,
}

export default RequirementsTableAttachmentsSubcomponent
