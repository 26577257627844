import { Carousel } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import styles from 'components/domains/properties/general-information/image/ImageCarousel.module.css'
import { ImageEnlargedDialog } from 'components/domains/properties/general-information/image/ImageEnlargedDialog'
import PropertyImage from 'components/domains/properties/general-information/image/PropertyImage'

const ImageCarousel = ({ images }) => {
  const [enlargedImage, setEnlargedImage] = useState({ title: '', source: '' })
  const [openEnlargedDialog, setOpenEnlargedDialog] = useState(false)
  const onEnlargeImage = (source, title) => {
    setEnlargedImage({ source, title })
    setOpenEnlargedDialog(true)
  }
  const orderImages = () => images.sort((a, b) => a.property_image_order - b.property_image_order)

  const renderCarousel = () => (
    <>
      <Carousel cyclic arrowsPlacement="Navigation" className={styles.carousel}>
        {orderImages().map((image, index) => (
          <PropertyImage
            key={index}
            imageTitle={image.description}
            imageUuid={image.image_uuid}
            onEnlargeImage={onEnlargeImage}
          />
        ))}
      </Carousel>
      {createPortal(
        <ImageEnlargedDialog
          enlargedImage={enlargedImage}
          setOpenEnlargedDialog={setOpenEnlargedDialog}
          openEnlargedDialog={openEnlargedDialog}
        />,
        document.body,
      )}
    </>
  )

  return renderCarousel()
}
ImageCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      url: PropTypes.string.isRequired,
      property_image_order: PropTypes.number.isRequired,
      meta: PropTypes.shape({
        format: PropTypes.string,
        name: PropTypes.string.isRequired,
        size: PropTypes.number,
        uploaded_by: PropTypes.string,
        uploaded_at: PropTypes.string,
      }),
    }),
  ).isRequired,
}
export default ImageCarousel
