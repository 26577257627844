import isEmpty from 'lodash.isempty'

export const calculateExternalIdAndTypeFormErrors = ({
  newMarketExternalId,
  newMarketExternalIdType,
  setFormErrors,
}) => {
  if (
    (isEmpty(newMarketExternalId) && isEmpty(newMarketExternalIdType)) ||
    (newMarketExternalId !== '' && newMarketExternalIdType !== '')
  ) {
    setFormErrors((oldState) => ({
      ...oldState,
      marketExternalIdType: false,
      marketExternalId: false,
    }))
    return
  }
  if (isEmpty(newMarketExternalId)) {
    setFormErrors((oldState) => ({
      ...oldState,
      marketExternalIdType: false,
      marketExternalId: true,
    }))
    return
  }
  setFormErrors((oldState) => ({
    ...oldState,
    marketExternalIdType: true,
    marketExternalId: false,
  }))
}
