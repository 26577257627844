import { MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { decisionPaperSynchronizationStatus } from 'api/decision-paper/decisionPaperApi'
import { isEventClosed } from 'api/events/status'
import { ErrorDataUnavailableInContent } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/loading/RequestStateResolver'
import DecisionPaperAutomaticTileWithMetaDataRenderer from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/with-meta-data/DecisionPaperAutomaticTileWithMetaDataRenderer'
import TileHeaderEditActions from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/with-meta-data/shared/actions/TileHeaderEditActions'
import CoverPoolAdjustmentsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/cover-pool/CoverPoolAdjustmentsTile'
import useEditTileMetaData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/meta-data/useEditTileMetaData'
import useTileMetaData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/meta-data/useTileMetaData'
import useIsDecisionPaperTileExpanded from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useIsDecisionPaperTileExpanded'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const CoverPoolAdjustmentsTileLoadingWrapper = ({
  tileId,
  title: tileTitle,
  tileCode,
  viewConfig,
  isPdfView = false,
  currentVersion,
  synchronizationStatus,
  showConfirmationForLinks,
  isEditableInMinorVersion,
  lastAvailableVersion,
  ...tileProps
}) => {
  const {
    event: { id: eventId, status: eventStatus },
  } = useContext(BusinessEventsAndTasksContext)

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.tiles',
  })
  const queryClient = useQueryClient()
  const showMessageBox = Modals.useShowMessageBox()
  const {
    data: tileMetaData,
    isLoading: isTileMetadataLoading,
    isError: isTileMetadataError,
  } = useTileMetaData({ eventId, tileId })

  const { isExpanded, expand } = useIsDecisionPaperTileExpanded({
    tileId,
    isPdfView,
    isDefaultExpanded: viewConfig.isDefaultExpanded,
  })

  const { mutate: editTileMetaData } = useEditTileMetaData()
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const [isEditMode, setIsEditMode] = useState(false)
  const [metaDataContent, setMetaDataContent] = useState(tileMetaData?.meta_data)

  useEffect(() => {
    setMetaDataContent(tileMetaData?.meta_data)
  }, [tileMetaData?.meta_data])

  const hasChanges = useMemo(
    () => !isEqual(metaDataContent, tileMetaData?.meta_data),
    [metaDataContent, tileMetaData?.meta_data],
  )

  const onSaveSuccess = useCallback(() => {
    setIsEditMode(false)
    queryClient.invalidateQueries([
      'events',
      eventId,
      'decision-paper',
      'versions',
      'current',
      'tiles',
      tileId,
      'meta-data',
    ])
  }, [setIsEditMode, queryClient, eventId, tileId])

  const onSaveError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('meta-data.save.error.description'),
    })
  }, [showMessageBox, t])

  const handleSave = useCallback(() => {
    editTileMetaData(
      { eventId, tileId, metaData: metaDataContent },
      { onSuccess: onSaveSuccess, onError: onSaveError },
    )
  }, [editTileMetaData, eventId, tileId, metaDataContent, onSaveSuccess, onSaveError])

  const editActions = useMemo(
    () =>
      !isEventClosed(eventStatus) && (
        <TileHeaderEditActions
          tileId={tileId}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          initialContent={tileMetaData?.meta_data}
          setCurrentContent={setMetaDataContent}
          hasChanges={hasChanges}
          onSaveClicked={handleSave}
          isExpanded={isExpanded}
          expand={expand}
        />
      ),
    [
      tileId,
      hasChanges,
      isEditMode,
      tileMetaData?.meta_data,
      handleSave,
      isExpanded,
      expand,
      eventStatus,
    ],
  )

  const renderContent = useCallback(
    () => (
      <CoverPoolAdjustmentsTile
        tileId={tileId}
        tileCode={tileCode}
        isEditMode={isEditMode}
        isPdfView={isPdfView}
        metaData={metaDataContent}
        onMetaDataChange={setMetaDataContent}
        showConfirmationForLinks={showConfirmationForLinks}
        currentVersion={currentVersion}
        {...tileProps}
      />
    ),
    [
      tileId,
      tileCode,
      isEditMode,
      isPdfView,
      metaDataContent,
      setMetaDataContent,
      showConfirmationForLinks,
      currentVersion,
      tileProps,
    ],
  )

  return (
    <DecisionPaperAutomaticTileWithMetaDataRenderer
      tileId={tileId}
      tileCode={tileCode}
      titleText={tileTitle}
      viewConfig={viewConfig}
      isPdfView={isPdfView}
      actions={editActions}
      currentVersion={currentVersion}
      isEditableInMinorVersion={isEditableInMinorVersion}
      lastAvailableVersion={lastAvailableVersion}
      synchronizationStatus={synchronizationStatus}
    >
      <RequestStateResolver
        isLoading={
          isTileMetadataLoading ||
          !tileStatus ||
          tileStatus.isLoading ||
          (!tileStatus.data && !tileStatus.isError)
        }
        center={true}
        isError={isTileMetadataError || (!!tileStatus && tileStatus.isError)}
        busyIndicatorText={t('loading')}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        renderContent={renderContent}
      />
    </DecisionPaperAutomaticTileWithMetaDataRenderer>
  )
}

CoverPoolAdjustmentsTileLoadingWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  tileCode: PropTypes.string.isRequired,
  subType: PropTypes.string,
  viewConfig: PropTypes.shape({
    columnSpan: PropTypes.number.isRequired,
    isDefaultExpanded: PropTypes.bool.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool,
  currentVersion: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
  synchronizationStatus: PropTypes.oneOf([
    decisionPaperSynchronizationStatus.live,
    decisionPaperSynchronizationStatus.frozen,
    decisionPaperSynchronizationStatus.stopped,
  ]).isRequired,
  isEditableInMinorVersion: PropTypes.bool.isRequired,
  lastAvailableVersion: PropTypes.string.isRequired,
}

export default CoverPoolAdjustmentsTileLoadingWrapper
