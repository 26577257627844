import { TextAlign } from '@fioneer/ui5-webcomponents-react'
import { groupBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import CurrencyCell from 'components/domains/deals/deal-cashflow/results/CurrencyCell'
import PercentageCell from 'components/domains/deals/deal-cashflow/results/PercentageCell'
import { periodValues } from 'components/domains/deals/deal-cashflow/results/PeriodSelect'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const useCalculateTableData = ({ calculatedMeasures, selectedPeriod, displayDayOne }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.cashflow.details.results',
  })
  const { format: formatDate } = useShortDateFormatter()

  const getTableHeader = ({ endDate, iteration }) => {
    let tableHeaderText
    if (iteration === 0) {
      tableHeaderText = t('day-1')
    } else {
      switch (selectedPeriod) {
        case periodValues.years: {
          tableHeaderText = t('year-x', { year: iteration })
          break
        }
        case periodValues.quarters: {
          tableHeaderText = t('quarter-x', {
            // eslint-disable-next-line no-magic-numbers
            year: Math.ceil(iteration / 4),
            // eslint-disable-next-line no-magic-numbers
            quarter: iteration % 4 !== 0 ? iteration % 4 : 4,
          })
          break
        }
        case periodValues.months: {
          tableHeaderText = t('month-x', {
            // eslint-disable-next-line no-magic-numbers
            year: Math.ceil(iteration / 12),
            // eslint-disable-next-line no-magic-numbers
            month: iteration % 12 !== 0 ? iteration % 12 : 12,
          })
          break
        }
      }
    }
    return (
      <>
        <div>{tableHeaderText}</div>
        <div>{formatDate(endDate)}</div>
      </>
    )
  }

  const groupedByCategory = groupBy(calculatedMeasures, 'category')
  const structuredData = []
  // to maintain order it is iterated over calculatedMeasures instead of iterating over category
  for (const result of calculatedMeasures) {
    // if no category given, the leaf should be at root level
    if (result.category === null) {
      structuredData.push(result)
    } else {
      // prevent duplicates
      if (!structuredData.find(({ categoryName }) => categoryName === result.category)) {
        structuredData.push({
          categoryName: result.category,
          subRows: groupedByCategory[result.category],
        })
      }
    }
  }

  const columns = [
    {
      id: 'parameter',
      Header: t('parameter'),
      accessor: ({ categoryName, cashflowType, cashflowKpi }) =>
        categoryName ?? cashflowType?.name ?? cashflowKpi?.name,
      minWidth: 250,
      width: 280,
      disableDragAndDrop: true,
    },
  ]

  for (const result of calculatedMeasures) {
    for (const [index, dateValue] of result.values.entries()) {
      if (index === 0 && !displayDayOne) {
        continue
      }

      if (!columns.find(({ id }) => id === dateValue.endDate)) {
        columns.push({
          id: dateValue.endDate,
          Header: getTableHeader({
            endDate: dateValue.endDate,
            iteration: index,
          }),
          accessor: ({ values, currencyCode }) => ({
            number: values?.find(({ endDate }) => endDate === dateValue.endDate)?.value,
            currency: currencyCode,
            percentage: values?.find(({ endDate }) => endDate === dateValue.endDate)?.value,
          }),
          Cell: result.currencyCode ? CurrencyCell : PercentageCell,
          hAlign: TextAlign.End,
          minWidth: 140,
          width: 160,
          disableDragAndDrop: true,
        })
      }
    }
  }

  return { structuredData, calculatedColumns: columns }
}

export default useCalculateTableData
