import { ButtonDesign, FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { eventsAndTasksMassEditOperations } from 'api/events/events'
import { eventStatus as possibleEventStatus } from 'api/events/status'
import BusinessEventsAndTasksMassEditAssigneeSelect from 'components/domains/business-events-and-tasks/mass-edit/BusinessEventsAndTasksMassEditAssigneeSelect'
import BusinessEventsAndTasksMassEditCurrentDueDateSelect from 'components/domains/business-events-and-tasks/mass-edit/BusinessEventsAndTasksMassEditCurrentDueDateSelect'
import styles from 'components/domains/business-events-and-tasks/mass-edit/BusinessEventsAndTasksMassEditDialog.module.css'
import BusinessEventsAndTasksMassEditOriginalDueDateSelect from 'components/domains/business-events-and-tasks/mass-edit/BusinessEventsAndTasksMassEditOriginalDueDateSelect'
import BusinessEventsAndTasksMassEditStatusSelect from 'components/domains/business-events-and-tasks/mass-edit/BusinessEventsAndTasksMassEditStatusSelect'
import LoadingButton from 'components/ui/button/LoadingButton'
import Dialog, { DialogSecondaryButton, DialogSize } from 'components/ui/dialog/Dialog'

const initialEditedState = {
  assignee: undefined,
  originalDueDate: undefined,
  currentDueDate: undefined,
  status: undefined,
}

const BusinessEventsAndTasksMassEditDialog = ({
  eventsOrTasks,
  isEventList,
  onAfterClose,
  onSave,
  isLoading = false,
  isOpen,
  ...dialogProps
}) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.mass-edit-dialog',
  })
  const dialogRef = useRef()
  const [editedState, setEditedState] = useState(initialEditedState)

  const someEventInInitialStatus = useMemo(
    () => eventsOrTasks.some(({ status }) => status === possibleEventStatus.drafted),
    [eventsOrTasks],
  )

  const someEventInOngoingStatus = useMemo(
    () => eventsOrTasks.some(({ status }) => status === possibleEventStatus.ongoing),
    [eventsOrTasks],
  )

  const isSomeTaskEventInInitialStatus = useMemo(
    () => eventsOrTasks.some(({ eventStatus }) => eventStatus === possibleEventStatus.drafted),
    [eventsOrTasks],
  )

  const isSomeTaskEventInOngoingStatus = useMemo(
    () => eventsOrTasks.some(({ eventStatus }) => eventStatus === possibleEventStatus.ongoing),
    [eventsOrTasks],
  )

  const isOriginalDueDateSelectDisabled =
    (isEventList && someEventInOngoingStatus) || (!isEventList && isSomeTaskEventInOngoingStatus)
  const isCurrentDueDateSelectDisabled =
    (isEventList && someEventInInitialStatus) || (!isEventList && isSomeTaskEventInInitialStatus)

  const isStatusSelectDisabled = useMemo(
    () =>
      !isEventList &&
      eventsOrTasks.some(({ eventStatus }) => eventStatus === possibleEventStatus.drafted),
    [isEventList, eventsOrTasks],
  )

  const onAfterCloseInternal = useCallback(() => {
    setEditedState(initialEditedState)
    onAfterClose()
  }, [onAfterClose])

  const onSaveInternal = useCallback(() => {
    onSave(editedState)
  }, [onSave, editedState])

  const setValueOfEditedStateBasedOnOperation = useCallback(
    (key) =>
      ({ operation, value }) => {
        switch (operation) {
          case eventsAndTasksMassEditOperations.clear: {
            setEditedState((currentEditedState) => ({ ...currentEditedState, [key]: null }))
            return
          }
          case eventsAndTasksMassEditOperations.keep: {
            setEditedState((currentEditedState) => ({ ...currentEditedState, [key]: undefined }))
            return
          }
          case eventsAndTasksMassEditOperations.replace: {
            setEditedState((currentEditedState) => ({ ...currentEditedState, [key]: value }))
            return
          }
        }
      },
    [],
  )

  const onCloseButtonClick = useCallback(() => {
    dialogRef.current?.close()
  }, [dialogRef])

  const classes = isLoading ? `${styles.disabled} ${styles.dialogContent}` : styles.dialogContent

  return (
    <Dialog
      {...dialogProps}
      ref={dialogRef}
      open={isOpen}
      onAfterClose={onAfterCloseInternal}
      headerText={t('title', { numberOfSelectedRows: eventsOrTasks.length })}
      size={DialogSize.M}
      closeButton={
        <DialogSecondaryButton onClick={onCloseButtonClick}>
          {tNoPrefix('buttons.close')}
        </DialogSecondaryButton>
      }
      primaryButton={
        <LoadingButton
          design={ButtonDesign.Emphasized}
          onClick={onSaveInternal}
          isLoading={isLoading}
          renderContent={() => tNoPrefix('buttons.save')}
        />
      }
    >
      <FlexBox
        direction={FlexBoxDirection.Column}
        className={classes}
        {...(isLoading && { inert: '' })}
      >
        <BusinessEventsAndTasksMassEditAssigneeSelect
          onChange={setValueOfEditedStateBasedOnOperation('assignee')}
        />
        {!isOriginalDueDateSelectDisabled && (
          <BusinessEventsAndTasksMassEditOriginalDueDateSelect
            onChange={setValueOfEditedStateBasedOnOperation('originalDueDate')}
          />
        )}
        {!isCurrentDueDateSelectDisabled && (
          <BusinessEventsAndTasksMassEditCurrentDueDateSelect
            onChange={setValueOfEditedStateBasedOnOperation('currentDueDate')}
          />
        )}
        {!isStatusSelectDisabled && (
          <BusinessEventsAndTasksMassEditStatusSelect
            onChange={setValueOfEditedStateBasedOnOperation('status')}
            eventsOrTasks={eventsOrTasks}
            isEventList={isEventList}
          />
        )}
      </FlexBox>
    </Dialog>
  )
}

BusinessEventsAndTasksMassEditDialog.propTypes = {
  eventsOrTasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      eventStatus: PropTypes.string,
    }),
  ).isRequired,
  isEventList: PropTypes.bool.isRequired,
  onAfterClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default BusinessEventsAndTasksMassEditDialog
