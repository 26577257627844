import compact from 'lodash.compact'
import uniq from 'lodash.uniq'
import { useContext, useMemo } from 'react'
import { REFERENCE_TYPE_CODES } from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/utils/typeCodes'
import { RISK_INDICATOR_TYPE_CODE_CATEGORIES } from 'components/domains/business-partners/tile/risk-monitoring/utils/typeCodes'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import { useMultipleDealsByUuidMini } from 'hooks/services/deals/useMultipleDealsByUuidMini'
import { useMultiplePropertiesByUuid } from 'hooks/services/properties/useMultiplePropertiesByUuid'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'
import useDetailedRiskIndicatorsByGroupId from 'hooks/services/risk-monitoring/useDetailedRiskIndicatorsByGroupId'
import useRiskIndicators from 'hooks/services/risk-monitoring/useRiskIndicators'
import useTypeCodes from 'hooks/services/risk-monitoring/useTypeCodes'
import useValuesByOriginInformationRequests from 'hooks/services/risk-monitoring/useValuesByOriginInformationRequests'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

/**
 * @param {object} props
 * @param {object} props.entityRef
 * @param {string} props.entityRef.entityId
 * @param {string} props.tileId
 */
const useRiskIndicatorsDecisionPaper = ({ entityRef: { entityId: businessPartnerId }, tileId }) => {
  const { event } = useContext(BusinessEventsAndTasksContext)

  // HINT: The search endpoint currently returns the same values as the get by ID endpoint
  //       If this changes, we might have to take the group ID from the search result and use it to fetch the details by ID
  const {
    data: groupData,
    isLoading: isGroupLoading,
    isError: isGroupError,
    isFetching: isGroupFetching,
    error: groupError,
  } = useRiskIndicators({
    businessPartnerId: businessPartnerId,
    businessEventId: event?.id,
  })

  const group = groupData?.riskIndicatorGroups?.[0]
  /** optimistic exists => if we don't know yet if it exists, the group exists */
  const groupExists = groupData?.riskIndicatorGroups?.length !== 0
  const {
    data,
    isLoading: isDataLoading,
    isFetching: isDataFetching,
    isError: isDataError,
    error: dataError,
  } = useDetailedRiskIndicatorsByGroupId(businessPartnerId, group?.groupId)

  const {
    data: { typeCodes } = {},
    isLoading: isTypeCodesLoading,
    isFetching: isTypeCodesFetching,
    isError: isTypeCodesError,
    error: typeCodesError,
  } = useTypeCodes(RISK_INDICATOR_TYPE_CODE_CATEGORIES.CRITICALITY)

  const requestedValues = useValuesByOriginInformationRequests({
    riskIndicators: data?.riskIndicators ?? [],
  })

  const riskIndicatorEntityIds = useMemo(
    () => uniq(compact(data?.riskIndicators?.map(({ reference }) => reference?.referenceEntityId))),
    [data?.riskIndicators],
  )

  const {
    data: multipleDealsByUuidMiniData = [],
    isLoading: isMultipleDealsByUuidMiniLoading,
    // returns an error 500 for invalid deal ids
    // isError: isMultipleDealsByUuidMiniError,
  } = useCombinedQueryResults(
    useMultipleDealsByUuidMini(riskIndicatorEntityIds, {
      enabled: riskIndicatorEntityIds.length > 0,
    }),
    { forceDataReturn: true },
  )

  const {
    data: multiplePropertiesData = [],
    isLoading: isMultiplePropertiesLoading,
    // returns an error 502 for invalid property ids
    // isError: isMultiplePropertiesError,
  } = useCombinedQueryResults(
    useMultiplePropertiesByUuid(riskIndicatorEntityIds, {
      enabled: riskIndicatorEntityIds.length > 0,
    }),
    { forceDataReturn: true },
  )

  const {
    data: { businessPartnerMinis: businessPartnersData = [] } = {},
    isLoading: isBusinessPartnersLoading,
    isError: isBusinessPartnerError,
  } = useBusinessPartnerMiniByIds(riskIndicatorEntityIds)

  const mappedRiskIndicatorReference = data?.riskIndicators?.map(({ reference }) => {
    let name = undefined

    if (reference?.referenceTypeCode === REFERENCE_TYPE_CODES.DEAL) {
      ;({ name } =
        multipleDealsByUuidMiniData.find(
          ({ dealUuid } = {}) => dealUuid === reference?.referenceEntityId,
        ) ?? {})
    } else if (reference?.referenceTypeCode === REFERENCE_TYPE_CODES.BUSINESS_PARTNER) {
      ;({ fullName: name } =
        businessPartnersData.find(({ id } = {}) => id === reference?.referenceEntityId) ?? {})
    } else if (reference?.referenceTypeCode === REFERENCE_TYPE_CODES.PROPERTY) {
      ;({ description: name } =
        multiplePropertiesData.find(
          ({ uuid: propertyUuid } = {}) => propertyUuid === reference?.referenceEntityId,
        ) ?? {})
    }

    return name
      ? {
          ...reference,
          referenceEntityName: name,
        }
      : null
  })

  const riskIndicatorsWithReferenceNames = data?.riskIndicators?.map((riskIndicator, index) => ({
    ...riskIndicator,
    reference: mappedRiskIndicatorReference[index],
  }))

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isGroupLoading,
      isGroupFetching,
      isTypeCodesLoading,
      isTypeCodesFetching,
      groupExists && isDataLoading,
      groupExists && isDataFetching,
      isMultipleDealsByUuidMiniLoading && riskIndicatorEntityIds.length > 0,
      isMultiplePropertiesLoading && riskIndicatorEntityIds.length > 0,
      isBusinessPartnersLoading && riskIndicatorEntityIds.length > 0,
    ],
    errorValues: [isGroupError, isTypeCodesError, isDataError, isBusinessPartnerError],
    errorDetails: [groupError, typeCodesError, dataError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: isSomeValueLoading,
      isError: isSomeValueError,
      data: {
        error,
        riskIndicators: riskIndicatorsWithReferenceNames ?? [],
        requestedValues,
        isNoData: !groupExists,
        typeCodes: typeCodes ?? [],
        sourceRender: {
          businessPartnerId,
          riskIndicatorGroupId: group?.groupDisplayId ?? '',
        },
      },
    }
  }, [
    businessPartnerId,
    error,
    group?.groupDisplayId,
    groupExists,
    isSomeValueError,
    isSomeValueLoading,
    requestedValues,
    riskIndicatorsWithReferenceNames,
    typeCodes,
  ])
}

export default useRiskIndicatorsDecisionPaper
