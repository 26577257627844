import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxJustifyContent,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CancelButtonWithPopover from 'components/ui/button/CancelButtonWithPopover'
import CWPCKEditor from 'components/ui/rich-text-editor/CWPCKEditor'
import styles from 'components/ui/rich-text-editor/CWPCKEditorWithSaveButtonAndCancelPopover.module.css'

const CWPCKEditorWithSaveButtonAndCancelPopover = ({
  autofocus,
  initialContent,
  placeholderText,
  onSave,
  onCancel,
  maxCharacterAmount,
}) => {
  const { t: tCommon } = useTranslation('translation')

  const [currentContent, setCurrentContent] = useState(initialContent)

  const hasChanges = useMemo(
    () => currentContent !== initialContent,
    [currentContent, initialContent],
  )

  return (
    <>
      <CWPCKEditor
        autofocus={autofocus}
        setCurrentContent={setCurrentContent}
        currentContent={currentContent}
        placeholderText={placeholderText}
        maxCharacterAmount={maxCharacterAmount}
      />
      <FlexBox justifyContent={FlexBoxJustifyContent.End} className={styles.saveAndCancelFlexbox}>
        <Button
          className={styles.saveButton}
          disabled={!hasChanges}
          design={ButtonDesign.Emphasized}
          onClick={() => onSave(currentContent)}
        >
          {tCommon('buttons.save')}
        </Button>
        <CancelButtonWithPopover showPopover={hasChanges} onCancelClicked={onCancel} />
      </FlexBox>
    </>
  )
}

CWPCKEditorWithSaveButtonAndCancelPopover.propTypes = {
  autofocus: PropTypes.bool,
  className: PropTypes.string,
  initialContent: PropTypes.string,
  placeholderText: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  maxCharacterAmount: PropTypes.number,
}
export default CWPCKEditorWithSaveButtonAndCancelPopover
