import { useTranslation } from 'react-i18next'
import { ReferenceEntityType } from 'components/ui/data/ReferenceEntityType'

const useReferenceEntityTypeTranslations = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.covenants' })

  const getTranslationForReferenceEntityType = (type) =>
    ({
      [ReferenceEntityType.TrancheNewBusiness]: t('tranche-new-business'),
      [ReferenceEntityType.TrancheExistingBusiness]: t('tranche-existing-business'),
      [ReferenceEntityType.BusinessPartner]: t('business-partner'),
    }[type] ?? '')

  return { getTranslationForReferenceEntityType }
}

export default useReferenceEntityTypeTranslations
