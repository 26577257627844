import { useMemo } from 'react'
import { isNotFoundError } from 'api/requests'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import extractBpIdsFromMultipleSyndicationsExistingBusinessData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/syndication-structure/extractBpIdsFromMultipleSyndicationsExistingBusinessData'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useSyndicationRoles from 'hooks/services/deals/financing/useSyndicationRoles'
import useMultipleSyndicationsExistingBusiness from 'hooks/services/deals/syndication/useMultipleSyndicationsExistingBusiness'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const useExistingBusinessSyndicationStructure = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { selectedDeal, dealsData, dealUuids } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const selectedDealDisplayId = selectedDeal?.selectedDealDisplayId

  // For whatever reason, the original (non-multi) hook returns an internal server error, if the deal has no syndication data.
  // Therefore, the error state cannot be used to determine a "real" error and is omitted.
  const {
    data: multipleSyndicationsExistingBusinessData = [],
    isLoading: isMultipleSyndicationsExistingBusinessLoading,
    isError: isMultipleSyndicationsExistingBusinessError,
  } = useCombinedQueryResults(useMultipleSyndicationsExistingBusiness({ dealUuids }), {
    forceDataReturn: true,
  })

  const allBusinessPartnerIdsInSyndicationData = useMemo(
    () =>
      extractBpIdsFromMultipleSyndicationsExistingBusinessData(
        multipleSyndicationsExistingBusinessData,
      ),
    [multipleSyndicationsExistingBusinessData],
  )

  const {
    data: { businessPartnerMinis: allBusinessPartnersData = [] } = {},
    isLoading: isBusinessPartnerLoading,
    isError: isBusinessPartnerError,
  } = useBusinessPartnerMiniByIds(allBusinessPartnerIdsInSyndicationData, {
    enabled: allBusinessPartnerIdsInSyndicationData?.length > 0,
  })

  // TODO: CWP-11353: Re-check hook call once it is completely implemented
  const {
    data: { configuration: { SYNDICATIONROLE: financialRolesData = [] } = {} } = {},
    isLoading: isSyndicationRolesLoading,
    isError: isSyndicationRolesError,
    error: syndicationRolesError,
  } = useSyndicationRoles()

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isAnnualReviewDealsLoading,
      isMultipleSyndicationsExistingBusinessLoading,
      isSyndicationRolesLoading,
      isBusinessPartnerLoading && allBusinessPartnerIdsInSyndicationData?.length > 0,
    ],
    errorValues: [
      isAnnualReviewDealsError,
      isSyndicationRolesError && !!syndicationRolesError && !isNotFoundError(syndicationRolesError),
      isBusinessPartnerError,
      isMultipleSyndicationsExistingBusinessError,
    ],
    errorDetails: [annualReviewDealsError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        financialRolesData,
        multipleSyndicationsExistingBusinessData,
        allBusinessPartnersData,
        sourceRender: {
          dealDisplayId: selectedDealDisplayId,
        },
      },
    }
  }, [
    allBusinessPartnersData,
    dealsData,
    error,
    financialRolesData,
    isSomeValueError,
    isSomeValueLoading,
    multipleSyndicationsExistingBusinessData,
    selectedDealDisplayId,
  ])
}

export default useExistingBusinessSyndicationStructure
