import { DynamicPage } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import ErrorBoundary from 'components/ui/errors/ErrorBoundary'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'

const Page = ({
  isLoading,
  isError,
  renderHeaderTitle,
  renderHeaderContent = () => {},
  renderContent,
  renderFooter = () => {},
  headerContentPinnable = false,
  showHideHeaderButton = false,
  style,
}) => {
  const renderDynamicPage = () => (
    <DynamicPage
      style={style}
      showHideHeaderButton={showHideHeaderButton}
      headerContentPinnable={headerContentPinnable}
      headerTitle={renderHeaderTitle()}
      headerContent={renderHeaderContent()}
      footer={renderFooter()}
    >
      {renderContent()}
    </DynamicPage>
  )

  return (
    <ErrorBoundary>
      <RequestStateResolver
        isLoading={isLoading}
        isError={isError}
        renderContent={renderDynamicPage}
        errorToDisplay={<ErrorDataUnavailableInContent />}
      />
    </ErrorBoundary>
  )
}

Page.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  renderHeaderTitle: PropTypes.func.isRequired,
  renderHeaderContent: PropTypes.func,
  renderContent: PropTypes.func.isRequired,
  renderFooter: PropTypes.func,
  headerContentPinnable: PropTypes.bool,
  showHideHeaderButton: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
}

export default Page
