import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  MultiInput,
  Token,
} from '@fioneer/ui5-webcomponents-react'
import uniqBy from 'lodash.uniqby'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/properties-search/dialog/PropertiesSearchDialog.module.css'
import PropertiesSearchTable from 'components/domains/properties/properties-search/dialog/PropertiesSearchTable'
import Dialog, {
  DialogPrimaryButton,
  DialogSecondaryButton,
  DialogSize,
} from 'components/ui/dialog/Dialog'

const PropertiesSearchDialog = ({
  isOpen,
  setIsOpen,
  onAccept,
  allowMultiSelect = true,
  initiallySelectedProperties,
  lockedSelectedProperties = [],
  addButtonText,
  initialFilterCriteria,
  hiddenFilters = [],
  propertyIdsToHide = [],
}) => {
  const { t } = useTranslation('translation')

  // locked properties can also be part of the initially selected properties, but should not be shown twice
  const uniqueSelectedProperties = useMemo(
    () =>
      uniqBy(
        [...(initiallySelectedProperties ?? []), ...(lockedSelectedProperties ?? [])],
        'uuid',
      ) ?? [],
    [initiallySelectedProperties, lockedSelectedProperties],
  )

  const [selectedProperties, setSelectedProperties] = useState(uniqueSelectedProperties)

  const isPropertyLocked = useCallback(
    (uuid) => lockedSelectedProperties?.some(({ uuid: lockedUuid } = {}) => lockedUuid === uuid),
    [lockedSelectedProperties],
  )

  const multiInputContent = useMemo(
    () => (
      <>
        {selectedProperties.map(({ uuid, id, description }) => {
          if (isPropertyLocked(uuid)) {
            // remove deletion options for locked selected properties
            return (
              <Token
                key={uuid}
                data-uuid={uuid}
                text={`${description} (${id})`}
                closeIcon={<div className={styles.emptyIcon} />}
              />
            )
          }
          return <Token key={uuid} data-uuid={uuid} text={`${description} (${id})`} />
        })}
      </>
    ),
    [isPropertyLocked, selectedProperties],
  )

  const onTokenDelete = useCallback(
    (event) => {
      const propertyUuid = event.detail.token.dataset.uuid

      // do not delete locked selected properties
      if (isPropertyLocked(propertyUuid)) {
        return
      }

      setSelectedProperties([...selectedProperties.filter(({ uuid }) => uuid !== propertyUuid)])
    },
    [isPropertyLocked, selectedProperties],
  )

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const handleSelectAndClose = useCallback(() => {
    onAccept(selectedProperties)
    onClose()
  }, [onAccept, onClose, selectedProperties])

  const handleResetProperties = useCallback(() => {
    // reset selected properties to locked selected properties (since they should never be removed)
    setSelectedProperties(lockedSelectedProperties ?? [])
  }, [lockedSelectedProperties])

  const handleSelectionChange = useCallback(
    (newSelectedProperties) => {
      setSelectedProperties(newSelectedProperties)

      if (!allowMultiSelect) {
        onAccept([newSelectedProperties?.at(-1)])
        onClose()
      }
    },
    [allowMultiSelect, onAccept, onClose],
  )

  const addButton = useMemo(
    () =>
      allowMultiSelect && (
        <DialogPrimaryButton
          id="submit-add-btn"
          onClick={handleSelectAndClose}
          disabled={!selectedProperties.length}
        >
          {addButtonText ?? t('buttons.ok')}
        </DialogPrimaryButton>
      ),
    [allowMultiSelect, handleSelectAndClose, selectedProperties.length, addButtonText, t],
  )

  const renderFooter = useCallback(
    () =>
      allowMultiSelect && (
        <FlexBox
          direction={FlexBoxDirection.Column}
          justifyContent={FlexBoxJustifyContent.End}
          className={styles.footerWrapper}
        >
          <Label className={styles.titleLabel}>
            {t('components.property-search-dialog.selected-properties', {
              count: selectedProperties.length,
            })}
          </Label>
          <FlexBox className={styles.selectionRow} alignItems={FlexBoxAlignItems.Center}>
            <MultiInput
              className={styles.multiInput}
              onTokenDelete={onTokenDelete}
              tokens={multiInputContent}
            />
            <Button
              icon="sap-icon://decline"
              id="clear-selection-button"
              design={ButtonDesign.Transparent}
              onClick={handleResetProperties}
            />
          </FlexBox>
        </FlexBox>
      ),
    [
      allowMultiSelect,
      handleResetProperties,
      multiInputContent,
      onTokenDelete,
      selectedProperties.length,
      t,
    ],
  )

  return (
    <Dialog
      open={isOpen}
      onBeforeClose={(e) => e.detail.escPressed && onClose()}
      className={styles.dialog}
      size={DialogSize.XL}
      headerText={t('components.property-search-dialog.title')}
      primaryButton={addButton}
      closeButton={
        <DialogSecondaryButton onClick={onClose}>{t('buttons.cancel')}</DialogSecondaryButton>
      }
    >
      <FlexBox direction={FlexBoxDirection.Column} className={styles.dialogContentWrapper}>
        <PropertiesSearchTable
          allowMultiSelect={allowMultiSelect}
          selectedProperties={selectedProperties}
          lockedSelectedProperties={lockedSelectedProperties}
          onSelectionChange={handleSelectionChange}
          initialFilterCriteria={initialFilterCriteria}
          hiddenFilters={hiddenFilters}
          propertyIdsToHide={propertyIdsToHide}
          startSearchOnNonEmptyFilterCriteria
          additionalFilterBarProperties={{ hideFilterConfiguration: true }}
        />
        {renderFooter()}
      </FlexBox>
    </Dialog>
  )
}

PropertiesSearchDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  allowMultiSelect: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  initiallySelectedProperties: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  lockedSelectedProperties: PropTypes.arrayOf(PropTypes.object),
  addButtonText: PropTypes.string,
  initialFilterCriteria: PropTypes.shape({
    propertyName: PropTypes.string,
    propertyId: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    dealId: PropTypes.string,
    zipCode: PropTypes.string,
    marketId: PropTypes.string,
    financingStatusCode: PropTypes.arrayOf(PropTypes.string),
    cagStatusCode: PropTypes.arrayOf(PropTypes.string),
  }),
  hiddenFilters: PropTypes.arrayOf(PropTypes.string),
  propertyIdsToHide: PropTypes.arrayOf(PropTypes.string),
}

export default PropertiesSearchDialog
