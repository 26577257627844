import { orderBy } from 'lodash'
import { TABLE_ROW_HEIGHT } from 'components/domains/business-partners/tile/risk-monitoring/forbearance/constants'
import resolveLifecycleStatusCodeToValueState from 'components/domains/business-partners/tile/risk-monitoring/forbearance/helper/resolveLifecycleStatusCodeToValueState'
import mapMeasureVersion from 'components/domains/business-partners/tile/risk-monitoring/forbearance/measures/mapper/mapMeasureVersion'

/**
 * @param {import('hooks/services/risk-monitoring/forbearance/measures/forbearanceMeasuresSchema').ForbearanceMeasure} measure
 * @param {object} staffMembers
 */
const mapMeasure = (measure, staffMembers) => {
  const latestForbearanceVersion = orderBy(
    measure.forbearanceMeasureVersions,
    (v) => v.version,
    'desc',
  )[0]

  const latestStartDate = latestForbearanceVersion?.startDate
  const latestEndDate = latestForbearanceVersion?.endDate

  return {
    forbearanceMeasureVersion: `${measure.forbearanceMeasureShortText} ${measure.postfix}`,
    status: {
      statusValue: measure.lifecycleStatusTypeShortText,
      statusCode: measure.lifecycleStatusTypeCode,
      statusState: resolveLifecycleStatusCodeToValueState(measure.lifecycleStatusTypeCode),
    },
    startDate: latestStartDate,
    endDate: latestEndDate,
    isHeader: true,
    forbearanceMeasureId: measure.id,
    createdOn: measure?.createdOn?.createdOn,
    createdBy: staffMembers?.[measure?.createdOn?.createdBy]?.fullName,
    subRows: measure.forbearanceMeasureVersions.map((measureVersion) =>
      mapMeasureVersion(
        {
          measureVersion,
          measureShortText: measure.forbearanceMeasureShortText,
          forbearanceMeasureId: measure.id,
        },
        staffMembers,
      ),
    ),

    rowHeight: TABLE_ROW_HEIGHT,
  }
}

export default mapMeasure
