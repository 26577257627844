import 'components/domains/markets/detail/MarketsTables.css'
import {
  Button,
  ButtonDesign,
  CheckBox,
  FlexBox,
  FlexBoxDirection,
  Label,
  Link,
  MessageBoxActions,
  MessageBoxTypes,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { isMissingPermissionError } from 'api/requests'
import styles from 'components/domains/markets/detail/MarketsPropertiesTable.module.css'
import PropertyFinancingStatusCell from 'components/domains/properties/common/PropertyFinancingStatusCell'
import PropertiesSearchDialog from 'components/domains/properties/properties-search/dialog/PropertiesSearchDialog'
import { HIDDEN_FILTER_KEYWORDS } from 'components/domains/properties/properties-search/filterbar/PropertiesSearchDialogFilterBar'
import Card from 'components/ui/card/Card'
import FormattedAddress from 'components/ui/data/FormattedAddress'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'
import { useAddressFormatter } from 'hooks/i18n/useI18n'
import useAssignPropertiesToMarket from 'hooks/services/markets/useAssignPropertiesToMarket'
import useMarketCompatiblePropertyTypes from 'hooks/services/markets/useMarketCompatiblePropertyTypes'
import usePropertyIdForAssignedMarket from 'hooks/services/markets/usePropertyIdForAssignedMarket'
import useUnassignPropertiesToMarket from 'hooks/services/markets/useUnassignPropertiesToMarket'
import useGetFinancingStatusCodes from 'hooks/services/properties/financing-status/useGetFinancingStatusCodes'
import useMultiProperties from 'hooks/services/properties/useMultiProperties'
import paths from 'routes/paths'

const noPermissionValue = 'NO_PERMISSION'

const MarketsPropertiesTable = ({
  properties,
  marketId,
  marketCountryCode,
  showUnassignButton,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.markets.detail.properties' })

  const showMessageBox = useShowMessageBox()

  const enableMultiProperties = properties?.length !== 0

  const {
    isLoading: isPropertiesLoading,
    isError: isPropertiesError,
    data: propertyRequestsData = { properties: [] },
  } = useMultiProperties(properties, {
    enabled: enableMultiProperties,
  })

  const {
    isLoading: isCompatiblePropertyTypesLoading,
    isError: isCompatiblePropertyTypesPropertiesError,
    data: compatiblePropertyTypesData,
  } = useMarketCompatiblePropertyTypes({ marketId })

  const isPropertiesLoadingInternal = enableMultiProperties ? isPropertiesLoading : false

  const [selectedIdsProperties, setSelectedIdsProperties] = useState([])
  // this counter is needed so that the use effect is called again. Solves an issue that pressing assign after canceling does not work anymore
  const [openCounter, setOpenCounter] = useState(0)
  const [propertiesIdsToAssign, setPropertiesIdsToAssign] = useState([])
  const [isPropertySearchDialogOpen, setIsPropertySearchDialogOpen] = useState(false)
  const queryClient = useQueryClient()

  const { data: propertiesToAssignMarketData } = usePropertyIdForAssignedMarket(
    propertiesIdsToAssign,
    {
      enabled: propertiesIdsToAssign.length !== 0,
    },
  )
  const parseAddress = useAddressFormatter()

  const selectAllProperties = useCallback(() => {
    const allPropertyIds = propertyRequestsData.properties.map((property) => property.uuid)
    setSelectedIdsProperties(allPropertyIds)
  }, [propertyRequestsData.properties])

  const handleUnassignPropertiesError = useCallback(
    (error) => {
      showMessageBox({
        id: 'error-message',
        children: isMissingPermissionError(error)
          ? t('table.error.unassign.permissions')
          : t('table.error'),
        type: MessageBoxTypes.Error,
      })
    },
    [showMessageBox, t],
  )

  const unassignPropertiesMutation = useUnassignPropertiesToMarket({
    onSuccess: () => {
      queryClient.invalidateQueries(['markets', marketId])
    },
    onError: (error) => {
      handleUnassignPropertiesError(error)
    },
  })

  const handleAssignPropertiesError = useCallback(
    (error) => {
      showMessageBox({
        id: 'error-message',
        children: isMissingPermissionError(error)
          ? t('table.error.assign.permissions')
          : t('table.error'),
        type: MessageBoxTypes.Error,
      })
    },
    [showMessageBox, t],
  )

  const assignPropertiesMutation = useAssignPropertiesToMarket({
    onSuccess: () => {
      queryClient.invalidateQueries(['markets', marketId])
    },
    onError: (error) => handleAssignPropertiesError(error),
  })

  const mutateUnassignProperties = useCallback(() => {
    unassignPropertiesMutation.mutate({
      propertyIds: selectedIdsProperties,
      marketId,
    })
  }, [marketId, selectedIdsProperties, unassignPropertiesMutation])

  const handleReassignPropertiesConfirmation = useCallback(() => {
    assignPropertiesMutation.mutate({
      propertyIds: propertiesIdsToAssign,
      marketId,
    })
    setPropertiesIdsToAssign([])
  }, [assignPropertiesMutation, marketId, propertiesIdsToAssign])

  const handleReassignPropertiesConfirmationClose = useCallback(({ detail: { action } }) => {
    if (action === MessageBoxActions.Cancel) {
      setIsPropertySearchDialogOpen(true)
    }
  }, [])

  useEffect(() => {
    if (propertiesIdsToAssign.length === 0 || !propertiesToAssignMarketData) return

    if (propertiesToAssignMarketData.markets.length !== 0) {
      showMessageBox({
        id: 'reassign-confirmation-dialog',
        type: MessageBoxTypes.Confirm,
        titleText: t('table.confirmation-reassign-properties-title'),
        children: t('table.confirmation-reassign-properties-to-markets'),
        actions: [
          <Button
            key="button-approve"
            design={ButtonDesign.Emphasized}
            onClick={handleReassignPropertiesConfirmation}
          >
            {t('table.confirmation-reassign-properties-to-markets-button')}
          </Button>,
          MessageBoxActions.Cancel,
        ],
        onClose: handleReassignPropertiesConfirmationClose,
      })
    } else {
      assignPropertiesMutation.mutate({
        propertyIds: propertiesIdsToAssign,
        marketId,
      })
      setPropertiesIdsToAssign([])
    }
  }, [marketId, propertiesToAssignMarketData, openCounter]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleAssignButtonClick = useCallback(() => {
    setIsPropertySearchDialogOpen(true)
  }, [])

  const handleUnassignPropertiesConfirmation = useCallback(() => {
    mutateUnassignProperties()
  }, [mutateUnassignProperties])

  const handleUnassignProperties = useCallback(() => {
    showMessageBox({
      id: 'unassig-confirmation-dialog',
      type: MessageBoxTypes.Confirm,
      children: t('table.confirmation'),
      actions: [
        <Button
          key="button-confirm"
          design={ButtonDesign.Emphasized}
          onClick={handleUnassignPropertiesConfirmation}
        >
          {t('table.confirmation-reassign-properties-to-markets-button')}
        </Button>,
        MessageBoxActions.Cancel,
      ],
    })
  }, [handleUnassignPropertiesConfirmation, showMessageBox, t])

  const handleAssignProperties = useCallback(
    (selectedPropertiesToAssign) => {
      setPropertiesIdsToAssign(selectedPropertiesToAssign.map((property) => property.uuid))

      setOpenCounter(openCounter + 1)
    },
    [openCounter],
  )

  const deselectAllProperties = useCallback(() => setSelectedIdsProperties([]), [])

  const handleCheckBoxClick = useCallback((propertyId, isChecked) => {
    isChecked
      ? setSelectedIdsProperties((oldState) => [...oldState, propertyId])
      : setSelectedIdsProperties((oldState) => oldState.filter((element) => element !== propertyId))
  }, [])

  const handleHeaderCheckBox = useCallback(
    (isChecked) => {
      isChecked ? selectAllProperties() : deselectAllProperties()
    },
    [deselectAllProperties, selectAllProperties],
  )

  const formatAddress = useCallback(
    ({ countryCode, country, street, houseNumber, zipCode, city }) => {
      const { firstLine, secondLine, thirdLine } = parseAddress({
        countryCode,
        country,
        street,
        houseNumber,
        zipCode,
        city,
      })

      return `${firstLine} ${secondLine} ${thirdLine}`
    },
    [parseAddress],
  )

  const {
    data: { financingStatusCodes } = {},
    isLoading: isFinancingStatusLoading,
    isError: isFinancingStatusError,
    error: financingStatusError,
  } = useGetFinancingStatusCodes()

  const findCorrectPropertyInArray = useCallback(
    (uuid) => {
      if (isFinancingStatusLoading || isFinancingStatusError) {
        return {
          isLoading: isFinancingStatusLoading,
          isError: isFinancingStatusError,
          error: financingStatusError,
        }
      }
      return propertyRequestsData.properties.find((e) => e.uuid === uuid)
    },
    [propertyRequestsData, isFinancingStatusError, isFinancingStatusLoading, financingStatusError],
  )

  const setLinkedDealValue = useCallback(
    (propertyResult) => {
      if (propertyResult?.isError && isMissingPermissionError(propertyResult.error)) {
        return noPermissionValue
      }
      if (propertyResult?.isLoading || propertyResult?.isError) {
        return '-'
      }

      if (isFinancingStatusLoading || isFinancingStatusError) {
        return '-'
      }

      return financingStatusCodes?.find(
        (financingStatusCode) => financingStatusCode.key === propertyResult?.financingStatusCode,
      )?.displayName
    },
    [financingStatusCodes, isFinancingStatusError, isFinancingStatusLoading],
  )

  const setLinkedDealCellComponent = useCallback(
    (propertyResult) => (
      <PropertyFinancingStatusCell
        isLoading={propertyResult?.isLoading}
        isError={propertyResult?.isError}
        error={propertyResult?.error}
        propertyData={propertyResult}
      />
    ),
    [],
  )

  const tableFilterDealTypeEnum = useMemo(() => {
    const tableEnum = {}

    financingStatusCodes?.forEach((financingStatusCode) => {
      tableEnum[financingStatusCode.key] = financingStatusCode.displayName
    })

    return tableEnum
  }, [financingStatusCodes])

  const propertiesColumns = useCallback(
    (tableDataSize) => {
      const columnDefinitions = [
        {
          renderColumnContent: () => (
            <CheckBox
              disabled={tableDataSize === 0}
              checked={tableDataSize > 0 && selectedIdsProperties.length === tableDataSize}
              onChange={(event) => handleHeaderCheckBox(event.target.checked)}
            />
          ),
          columnKey: 'checkbox',
          sortingDisabled: true,
          isSelectableForHiding: false,
        },
        {
          title: t('table.title-row.name'),
          columnKey: 'name',
        },
        {
          title: t('table.title-row.address'),
          columnKey: 'address',
        },
        {
          title: t('table.title-row.object-type'),
          columnKey: 'objectType',
        },
        {
          title: t('table.title-row.deal-id'),
          columnKey: 'linkedDeal',
          filter: filterTypes.OF_ENUM_TYPE,
          additionalFilterOptions: { enumValues: tableFilterDealTypeEnum },
        },
      ]

      if (!showUnassignButton) {
        columnDefinitions.shift()
      }
      return columnDefinitions
    },
    [
      handleHeaderCheckBox,
      selectedIdsProperties.length,
      showUnassignButton,
      t,
      tableFilterDealTypeEnum,
    ],
  )

  const renderPropertiesTable = useCallback(() => {
    const tableData = propertyRequestsData.properties.map((property) => {
      const propertyResult = findCorrectPropertyInArray(property.uuid)
      return {
        rowKey: `property-table-row-${property.uuid}`,
        checkbox: {
          cellComponent: (
            <CheckBox
              checked={selectedIdsProperties.includes(property.uuid)}
              onChange={(event) => handleCheckBoxClick(property.uuid, event.target.checked)}
            />
          ),
        },
        name: {
          value: property.description,
          cellComponent: (
            <FlexBox direction={FlexBoxDirection.Column}>
              <Link href={`/${paths.properties}/${property.id}`} target="_blank" rel="noreferrer">
                <b>{property.description}</b>
              </Link>
              <Label>{property.id}</Label>
            </FlexBox>
          ),
        },
        address: {
          value: formatAddress({
            country: property.address.countryName,
            street: property.address.streetName,
            houseNumber: property.address.houseId,
            zipCode: property.address.postalCode,
            city: property.address.cityName,
          }),
          cellComponent: (
            <FormattedAddress
              city={property.address.cityName}
              country={property.address.countryName}
              street={property.address.streetName}
              houseNumber={property.address.houseId}
              zipCode={property.address.postalCode}
            />
          ),
        },
        objectType: {
          value: property.typeName,
          cellComponent: <Label wrappingType={WrappingType.Normal}>{property.typeName}</Label>,
        },
        linkedDeal: {
          value: setLinkedDealValue(propertyResult),
          cellComponent: setLinkedDealCellComponent(propertyResult),
        },
      }
    })

    const toolBarConfig = {
      searching: true,
      title: t('table.toolbar.title'),
      sorting: {
        columnKey: 'name',
      },
    }
    toolBarConfig.additionalActions = [
      <Button
        id="assign-button"
        design={ButtonDesign.Transparent}
        key={'assign-button'}
        onClick={handleAssignButtonClick}
      >
        {t('table.toolbar.button.assign')}
      </Button>,
    ]
    if (showUnassignButton) {
      toolBarConfig.additionalActions.push(
        <Button
          id="unassign-button"
          disabled={selectedIdsProperties.length === 0}
          design={ButtonDesign.Transparent}
          key={'unassign-button'}
          onClick={handleUnassignProperties}
        >
          {t('table.toolbar.button.unassign')}
        </Button>,
      )
    }

    const initialFilterCriteria = {
      propertyName: '',
      propertyId: '',
      country: marketCountryCode,
      propertyTypes: compatiblePropertyTypesData
        ? compatiblePropertyTypesData.propertyTypes
            .map((propertyType) => propertyType.code)
            .join(',')
        : '',
      city: '',
      dealId: '',
      zipCode: '',
      marketId: '',
    }

    return (
      <div className={styles.tableWrapper}>
        <SortedTable
          columnDefinitions={propertiesColumns(tableData.length)}
          tableData={tableData}
          noDataText={t('table.empty')}
          additionalTableProperties={{
            stickyColumnHeader: true,
            style: { overflow: 'auto', height: '631px' },
          }}
          toolbarConfig={toolBarConfig}
        />
        {createPortal(
          <PropertiesSearchDialog
            isOpen={isPropertySearchDialogOpen}
            setIsOpen={setIsPropertySearchDialogOpen}
            allowMultiSelect
            onAccept={handleAssignProperties}
            initiallySelectedProperties={[]}
            addButtonText={t('table.properties-search-dialog.add-button.text')}
            initialFilterCriteria={initialFilterCriteria}
            hiddenFilters={[HIDDEN_FILTER_KEYWORDS.deal]}
            propertyIdsToHide={properties.map((property) => property.id)}
          />,
          document.body,
        )}
      </div>
    )
  }, [
    compatiblePropertyTypesData,
    findCorrectPropertyInArray,
    formatAddress,
    handleAssignButtonClick,
    handleAssignProperties,
    handleCheckBoxClick,
    handleUnassignProperties,
    isPropertySearchDialogOpen,
    marketCountryCode,
    properties,
    propertiesColumns,
    propertyRequestsData.properties,
    selectedIdsProperties,
    setLinkedDealCellComponent,
    setLinkedDealValue,
    showUnassignButton,
    t,
  ])

  return (
    <Card id="properties-card" style={{ heigth: '100%' }}>
      <LoadingStateWrapper
        isError={isPropertiesError || isCompatiblePropertyTypesPropertiesError}
        isLoading={isPropertiesLoadingInternal || isCompatiblePropertyTypesLoading}
        loadingDescription={t('loading')}
        renderContent={renderPropertiesTable}
        errorTitle={t('error.title')}
        errorDescription={t('error.description')}
        errorDetails={t('error.details')}
      />
    </Card>
  )
}

MarketsPropertiesTable.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  ),
  marketId: PropTypes.string.isRequired,
  marketCountryCode: PropTypes.string.isRequired,
  showUnassignButton: PropTypes.bool.isRequired,
}

export default MarketsPropertiesTable
