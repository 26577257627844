/**
 * Outlayer Item
 */
import EvEmitter from 'lib/ev-emitter/ev-emitter'
import getSize from 'lib/get-size/get-size'

/** @typedef {import('lib/outlayer/outlayer').default} Outlayer */

// ----- helpers ----- //

/**
 * @param {object} obj
 */
function isEmptyObj(obj) {
  for (const _prop in obj) {
    return false
  }
  return true
}
// dash before all cap letters, including first for
// WebkitTransform => -webkit-transform
/**
 * @param {string} str
 */
function toDashedAll(str) {
  return str.replace(/([A-Z])/g, (/** @type {string} */ $1) => '-' + $1.toLowerCase())
}
// properties that I munge to make my life easier
const dashedVendorProperties = {
  '-webkit-transform': 'transform',
}
const cleanTransitionStyle = {
  transitionProperty: '',
  transitionDuration: '',
  transitionDelay: '',
}

// -------------------------- CSS3 support -------------------------- //

const docElemStyle = document.documentElement.style

const transitionProperty =
  typeof docElemStyle.transition === 'string' ? 'transition' : 'WebkitTransition'
const transformProperty =
  typeof docElemStyle.transform === 'string' ? 'transform' : 'WebkitTransform'
const transitionProps = 'opacity,' + toDashedAll(transformProperty)

const transitionEndEvent = {
  WebkitTransition: 'webkitTransitionEnd',
  transition: 'transitionend',
}[transitionProperty]

// cache all vendor properties that could have vendor prefix
const vendorProperties = {
  transform: transformProperty,
  transition: transitionProperty,
  transitionDuration: transitionProperty + 'Duration',
  transitionProperty: transitionProperty + 'Property',
  transitionDelay: transitionProperty + 'Delay',
}

// -------------------------- Item -------------------------- //

class Item extends EvEmitter {
  /** @type {HTMLElement | undefined} */
  element
  /** @type {{x: number, y: number} | undefined} */
  position
  /** @type {Outlayer | undefined} */
  layout

  /**
   * @param {HTMLElement | undefined} element
   * @param {Outlayer} layout
   */
  constructor(element, layout) {
    super()
    if (!element) {
      return
    }

    this.element = element
    // parent layout class, i.e. Masonry, Isotope, or Packery
    this.layout = layout
    this.position = {
      x: 0,
      y: 0,
    }

    this._create()
  }

  _create() {
    // transition objects
    this._transn = {
      ingProperties: {},
      clean: {},
      onEnd: {},
    }

    this.css({
      position: 'absolute',
    })
  }

  // trigger specified handler for event type
  /**
   * @param {{ type: string; }} event
   */
  handleEvent(event) {
    const method = 'on' + event.type
    if (this[method]) {
      this[method](event)
    }
  }

  getSize() {
    this.size = getSize(this.element)
  }

  /**
   * apply CSS styles to element
   * @param {object} style
   */
  css(style) {
    const elemStyle = this.element?.style

    for (const prop in style) {
      // use vendor property if available
      const supportedProp = vendorProperties[prop] || prop
      if (!elemStyle) return
      elemStyle[supportedProp] = style[prop]
    }
  }

  // measure position, and sets it
  getPosition() {
    const style = this.element && getComputedStyle(this.element)
    const isOriginLeft = this.layout?._getOption('originLeft')
    const isOriginTop = this.layout?._getOption('originTop')
    const xValue = style?.[isOriginLeft ? 'left' : 'right'] ?? ''
    const yValue = style?.[isOriginTop ? 'top' : 'bottom'] ?? ''
    let x = parseFloat(xValue)
    let y = parseFloat(yValue)
    // convert percent to pixels
    const layoutSize = this.layout?.size
    if (xValue.includes('%')) {
      x = (x / 100) * layoutSize?.width
    }
    if (yValue.includes('%')) {
      y = (y / 100) * layoutSize?.height
    }
    // clean up 'auto' or other non-integer values
    x = isNaN(x) ? 0 : x
    y = isNaN(y) ? 0 : y
    // remove padding from measurement
    x -= isOriginLeft ? layoutSize?.paddingLeft : layoutSize?.paddingRight
    y -= isOriginTop ? layoutSize?.paddingTop : layoutSize?.paddingBottom

    this.position.x = x
    this.position.y = y
  }

  // set settled position, apply padding
  layoutPosition() {
    const layoutSize = this.layout.size
    const style = {}
    const isOriginLeft = this.layout._getOption('originLeft')
    const isOriginTop = this.layout._getOption('originTop')

    // x
    const xPadding = isOriginLeft ? 'paddingLeft' : 'paddingRight'
    const xProperty = isOriginLeft ? 'left' : 'right'
    const xResetProperty = isOriginLeft ? 'right' : 'left'

    const x = this.position.x + layoutSize[xPadding]
    // set in percentage or pixels
    style[xProperty] = this.getXValue(x)
    // reset other property
    style[xResetProperty] = ''

    // y
    const yPadding = isOriginTop ? 'paddingTop' : 'paddingBottom'
    const yProperty = isOriginTop ? 'top' : 'bottom'
    const yResetProperty = isOriginTop ? 'bottom' : 'top'

    const y = this.position.y + layoutSize[yPadding]
    // set in percentage or pixels
    style[yProperty] = this.getYValue(y)
    // reset other property
    style[yResetProperty] = ''

    this.css(style)
    this.emitEvent('layout', [this])
  }

  /**
   * @param {string | number} x
   */
  getXValue(x) {
    const isHorizontal = this.layout._getOption('horizontal')
    return this.layout.options.percentPosition && !isHorizontal
      ? (x / this.layout.size.width) * 100 + '%'
      : x + 'px'
  }

  /**
   * @param {string | number} y
   */
  getYValue(y) {
    const isHorizontal = this.layout._getOption('horizontal')
    return this.layout.options.percentPosition && isHorizontal
      ? (y / this.layout.size.height) * 100 + '%'
      : y + 'px'
  }

  /**
   * @param {number} x
   * @param {number | undefined} [y]
   */
  _transitionTo(x, y) {
    this.getPosition()
    // get current x & y from top/left
    const curX = this.position.x
    const curY = this.position.y

    const didNotMove = x === curX && y === curY

    // save end position
    this.setPosition(x, y)

    // if did not move and not transitioning, just go to layout
    if (didNotMove && !this.isTransitioning) {
      this.layoutPosition()
      return
    }

    const transX = x - curX
    const transY = y - curY
    const transitionStyle = {}
    transitionStyle.transform = this.getTranslate(transX, transY)

    this.transition({
      to: transitionStyle,
      onTransitionEnd: {
        transform: this.layoutPosition,
      },
      isCleaning: true,
    })
  }

  /**
   * @param {string | number} x
   * @param {string | number} y
   */
  getTranslate(x, y) {
    // flip cooridinates if origin on right or bottom
    const isOriginLeft = this.layout._getOption('originLeft')
    const isOriginTop = this.layout._getOption('originTop')
    x = isOriginLeft ? x : -x
    y = isOriginTop ? y : -y
    return 'translate3d(' + x + 'px, ' + y + 'px, 0)'
  }

  // non transition + transform support
  /**
   * @param {number} x
   * @param {number} y
   */
  goTo(x, y) {
    this.setPosition(x, y)
    this.layoutPosition()
  }

  /**
   * @param {number[]} args
   */
  moveTo(...args) {
    return this._transitionTo(...args)
  }

  /**
   * @param {string} x
   * @param {string} y
   */
  setPosition(x, y) {
    this.position.x = parseFloat(x)
    this.position.y = parseFloat(y)
  }

  // ----- transition ----- //

  // non transition, just trigger callback
  /**
   * @param {Object} style - CSS
   * @param {Function} onTransitionEnd
   * @param {{ to: any; from?: Object; isCleaning: any; onTransitionEnd: any; }} args
   */
  _nonTransition(args) {
    this.css(args.to)
    if (args.isCleaning) {
      this._removeStyles(args.to)
    }
    for (const prop in args.onTransitionEnd) {
      args.onTransitionEnd[prop].call(this)
    }
  }

  /**
   * proper transition
   * @param {Object} args - arguments
   * @param {Object} args.to - style to transition to
   * @param {Object} args.from - style to start transition from
   * @param {Boolean} args.isCleaning - removes transition styles after transition
   * @param {Function} args.onTransitionEnd - callback
   */
  transition(args) {
    // redirect to nonTransition if no transition duration
    if (!parseFloat(this.layout.options.transitionDuration)) {
      this._nonTransition(args)
      return
    }

    const _transition = this._transn
    // keep track of onTransitionEnd callback by css property
    for (const prop in args.onTransitionEnd) {
      _transition.onEnd[prop] = args.onTransitionEnd[prop]
    }
    // keep track of properties that are transitioning
    for (const prop in args.to) {
      _transition.ingProperties[prop] = true
      // keep track of properties to clean up when transition is done
      if (args.isCleaning) {
        _transition.clean[prop] = true
      }
    }

    // set from styles
    if (args.from) {
      this.css(args.from)
      // force redraw. http://blog.alexmaccaw.com/css-transitions
      // HINT: updated link https://gist.github.com/paulirish/5d52fb081b3570c81e3a
      /* eslint-disable-next-line no-unused-expressions */
      this.element.offsetHeight
    }
    // enable transition
    this.enableTransition(args.to)
    // set styles that are transitioning
    this.css(args.to)

    this.isTransitioning = true
  }

  enableTransition(/* style */) {
    // HACK changing transitionProperty during a transition
    // will cause transition to jump
    if (this.isTransitioning) {
      return
    }

    // make `transition: foo, bar, baz` from style object
    // HACK un-comment this when enableTransition can work
    // while a transition is happening
    // var transitionValues = [];
    // for ( var prop in style ) {
    //   // dash-ify camelCased properties like WebkitTransition
    //   prop = vendorProperties[ prop ] || prop;
    //   transitionValues.push( toDashedAll( prop ) );
    // }
    // munge number to millisecond, to match stagger
    let duration = this.layout.options.transitionDuration
    duration = typeof duration === 'number' ? duration + 'ms' : duration
    // enable transition styles
    this.css({
      transitionProperty: transitionProps,
      transitionDuration: duration,
      transitionDelay: this.staggerDelay || 0,
    })
    // listen for transition end event
    this.element.addEventListener(transitionEndEvent, this, false)
  }

  // ----- events ----- //

  /**
   * @param {any} event
   */
  onwebkitTransitionEnd(event) {
    this.ontransitionend(event)
  }

  /**
   * @param {any} event
   */
  onotransitionend(event) {
    this.ontransitionend(event)
  }

  /**
   * @param {{ target: Element | undefined; propertyName: string | number; }} event
   */
  ontransitionend(event) {
    // disregard bubbled events from children
    if (event.target !== this.element) {
      return
    }
    const _transition = this._transn
    // get property name of transitioned property, convert to prefix-free
    const propertyName = dashedVendorProperties[event.propertyName] || event.propertyName

    // remove property that has completed transitioning
    delete _transition.ingProperties[propertyName]
    // check if any properties are still transitioning
    if (isEmptyObj(_transition.ingProperties)) {
      // all properties have completed transitioning
      this.disableTransition()
    }
    // clean style
    if (propertyName in _transition.clean) {
      // clean up style
      this.element.style[event.propertyName] = ''
      delete _transition.clean[propertyName]
    }
    // trigger onTransitionEnd callback
    if (propertyName in _transition.onEnd) {
      const onTransitionEnd = _transition.onEnd[propertyName]
      onTransitionEnd.call(this)
      delete _transition.onEnd[propertyName]
    }

    this.emitEvent('transitionEnd', [this])
  }

  disableTransition() {
    this.removeTransitionStyles()
    this.element.removeEventListener(transitionEndEvent, this, false)
    this.isTransitioning = false
  }

  /**
   * removes style property from element
   * @param {Object} style
   **/
  _removeStyles(style) {
    // clean up transition styles
    const cleanStyle = {}
    for (const prop in style) {
      cleanStyle[prop] = ''
    }
    this.css(cleanStyle)
  }

  removeTransitionStyles() {
    // remove transition
    this.css(cleanTransitionStyle)
  }

  // ----- stagger ----- //

  /**
   * @param {string | number} delay
   */
  stagger(delay) {
    delay = isNaN(delay) ? 0 : delay
    this.staggerDelay = delay + 'ms'
  }

  // ----- show/hide/remove ----- //

  // remove element from DOM
  removeElem() {
    const parent = this.element.parentNode
    if (parent) {
      parent.removeChild(this.element)
    }
    // remove display: none
    this.css({ display: '' })
    this.emitEvent('remove', [this])
  }

  remove() {
    // just remove element if no transition support or no transition
    if (!transitionProperty || !parseFloat(this.layout.options.transitionDuration)) {
      this.removeElem()
      return
    }

    // start transition
    this.once('transitionEnd', () => {
      this.removeElem()
    })
    this.hide()
  }

  reveal() {
    delete this.isHidden
    // remove display: none
    this.css({ display: '' })

    const options = this.layout.options

    const onTransitionEnd = {}
    const transitionEndProperty = this.getHideRevealTransitionEndProperty('visibleStyle')
    onTransitionEnd[transitionEndProperty] = this.onRevealTransitionEnd

    this.transition({
      from: options.hiddenStyle,
      to: options.visibleStyle,
      isCleaning: true,
      onTransitionEnd: onTransitionEnd,
    })
  }

  onRevealTransitionEnd() {
    // check if still visible
    // during transition, item may have been hidden
    if (!this.isHidden) {
      this.emitEvent('reveal')
    }
  }

  /**
   * get style property use for hide/reveal transition end
   * @param {String} styleProperty - hiddenStyle/visibleStyle
   * @returns {String}
   */
  getHideRevealTransitionEndProperty(styleProperty) {
    const optionStyle = this.layout.options[styleProperty]
    // use opacity
    if (optionStyle.opacity) {
      return 'opacity'
    }
    // get first property
    for (const prop in optionStyle) {
      return prop
    }
  }

  hide() {
    // set flag
    this.isHidden = true
    // remove display: none
    this.css({ display: '' })

    const options = this.layout.options

    const onTransitionEnd = {}
    const transitionEndProperty = this.getHideRevealTransitionEndProperty('hiddenStyle')
    onTransitionEnd[transitionEndProperty] = this.onHideTransitionEnd

    this.transition({
      from: options.visibleStyle,
      to: options.hiddenStyle,
      // keep hidden stuff hidden
      isCleaning: true,
      onTransitionEnd: onTransitionEnd,
    })
  }

  onHideTransitionEnd() {
    // check if still hidden
    // during transition, item may have been un-hidden
    if (this.isHidden) {
      this.css({ display: 'none' })
      this.emitEvent('hide')
    }
  }

  destroy() {
    this.css({
      position: '',
      left: '',
      right: '',
      top: '',
      bottom: '',
      transition: '',
      transform: '',
    })
  }
}

export default Item
