import { useRequest } from 'hooks/services/baseService'

const usePropertyMonitoringPermissions = (propertyUuid) =>
  useRequest({
    path: `/property-monitoring/allowed-operations?property_id=${propertyUuid}`,
    useCache: true,
    keys: ['property-monitoring', 'permissions'],
  })

export default usePropertyMonitoringPermissions
