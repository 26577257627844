import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useEventWatchers = ({ eventId }) =>
  useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/watchers`,
      useCache: true,
      keys: ['events', eventId, 'watchers'],
    }),
  )

export default useEventWatchers
