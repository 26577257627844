export const riskTypes = {
  veryLow: 'VERY_LOW_RISK',
  low: 'LOW_RISK',
  medium: 'MEDIUM_RISK',
  mediumHigh: 'MEDIUM_HIGH_RISK',
  high: 'HIGH_RISK',
  veryHigh: 'VERY_HIGH_RISK',
}

// Because these risk categories are used as keys in persisted json screaming snake case is not working
export const riskCategories = {
  financingStructure: 'financingStructure',
  property: 'property',
  tenancy: 'tenancy',
  cashflow: 'cashflow',
  involvedParties: 'involvedParties',
  total: 'total',
}
