import { Label, ObjectStatus, Text } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/risk-monitoring/StatusWithDateCell.module.css'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const StatusWithDateCell = ({ statusState, statusValue, date }) => {
  const { format: formatDate } = useShortDateFormatter()
  const { t } = useTranslation('decisionPaper')

  if (isNil(date) || isNil(statusState) || isNil(statusValue)) {
    return ''
  }

  return (
    <div className={styles.twoLineCell}>
      <ObjectStatus inverted state={statusState}>
        {statusValue}
      </ObjectStatus>
      <div>
        <Label showColon>{t('date')}</Label>&nbsp;
        <Text>{formatDate(date)}</Text>
      </div>
    </div>
  )
}

StatusWithDateCell.propTypes = {
  date: PropTypes.string,
  statusState: PropTypes.string.isRequired,
  statusValue: PropTypes.string.isRequired,
}

export default StatusWithDateCell
