import { TableRowType } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  valuationClassificationTranslated,
  valuationRelevance,
} from 'api/property/valuation/valuationRequests'
import styles from 'components/domains/properties/valuation/valuation-results/PropertyValuationResultsTable.module.css'
import PropertyValuationResultsKeyDateCell from 'components/domains/properties/valuation/valuation-results/cells/PropertyValuationResultsKeyDateCell'
import PropertyValuationResultsMethodCell from 'components/domains/properties/valuation/valuation-results/cells/PropertyValuationResultsMethodCell'
import PropertyValuationResultsRegulatoryRelevantCell from 'components/domains/properties/valuation/valuation-results/cells/PropertyValuationResultsRegulatoryRelevantCell'
import PropertyValuationResultsTableActionsCell from 'components/domains/properties/valuation/valuation-results/cells/PropertyValuationResultsTableActionsCell'
import PropertyValuationResultsValidFromCell from 'components/domains/properties/valuation/valuation-results/cells/PropertyValuationResultsValidFromCell'
import PropertyValuationResultsValidToCell from 'components/domains/properties/valuation/valuation-results/cells/PropertyValuationResultsValidToCell'
import PropertyValuationResultsValuationTypeCell from 'components/domains/properties/valuation/valuation-results/cells/PropertyValuationResultsValuationTypeCell'
import PropertyValuationResultsValueAmountCell from 'components/domains/properties/valuation/valuation-results/cells/PropertyValuationResultsValueAmountCell'
import { ValuationResultsTableColumns } from 'components/domains/properties/valuation/valuation-results/usePropertyValuationResultsTableColumns'
import { useBooleanToTextFormatter } from 'hooks/i18n/useI18n'

const useMapPropertyValuationResultsTableData = ({
  valuationResults,
  isAddMode,
  currentEditedValues,
  highlightedValuations,
  propertyUuid,
}) => {
  const { t } = useTranslation()
  const formatBoolean = useBooleanToTextFormatter()

  const valuationData = useMemo(
    () => (isAddMode ? [currentEditedValues, ...valuationResults] : valuationResults),
    [isAddMode, currentEditedValues, valuationResults],
  )

  const tableData = useMemo(
    () =>
      valuationData.map(
        ({
          valuationId,
          externalCalculationMethodName,
          classification,
          regulatoryRelevance,
          keyDate: initialKeyDate,
          valueAmount: { number: initialValueAmount, currency },
          validFrom: initialValidFrom,
          validTo: initialValidTo,
        }) => {
          const isCurrentRowAddMode = isAddMode && valuationId === currentEditedValues?.valuationId
          const keyDate = isCurrentRowAddMode ? currentEditedValues?.keyDate : initialKeyDate
          const validFrom = isCurrentRowAddMode ? currentEditedValues?.validFrom : initialValidFrom
          const validTo = isCurrentRowAddMode ? currentEditedValues?.validTo : initialValidTo
          const valueAmount = isCurrentRowAddMode
            ? currentEditedValues?.valueAmount?.value
            : initialValueAmount
          const isRowHighlighted = highlightedValuations.includes(valuationId)
          return {
            rowKey: `valuation-result-${valuationId}`,
            rowProperties: {
              type: TableRowType.Inactive,
              'data-result-id': valuationId,
              className: isRowHighlighted ? styles.hightlightNewOrEditedRow : null,
            },
            [ValuationResultsTableColumns.valuationType]: {
              value: t(valuationClassificationTranslated?.[classification]),
              cellComponent: (
                <PropertyValuationResultsValuationTypeCell
                  valuationType={classification}
                  isAddMode={isCurrentRowAddMode}
                />
              ),
            },
            [ValuationResultsTableColumns.method]: {
              value: externalCalculationMethodName,
              cellComponent: (
                <PropertyValuationResultsMethodCell
                  calculationMethodName={externalCalculationMethodName}
                  calculationMethodCode={currentEditedValues?.calculationMethodCode}
                  isAddMode={isCurrentRowAddMode}
                />
              ),
            },
            [ValuationResultsTableColumns.keyDate]: {
              value: keyDate,
              cellComponent: (
                <PropertyValuationResultsKeyDateCell
                  keyDate={keyDate}
                  isAddMode={isCurrentRowAddMode}
                />
              ),
            },
            [ValuationResultsTableColumns.result]: {
              value: valueAmount,
              cellComponent: (
                <PropertyValuationResultsValueAmountCell
                  valueAmount={valueAmount}
                  currency={currency}
                  isAddMode={isCurrentRowAddMode}
                />
              ),
            },
            [ValuationResultsTableColumns.validFrom]: {
              value: validFrom,
              cellComponent: (
                <PropertyValuationResultsValidFromCell
                  validFrom={validFrom}
                  isAddMode={isCurrentRowAddMode}
                />
              ),
            },
            [ValuationResultsTableColumns.validTo]: {
              value: validTo,
              cellComponent: (
                <PropertyValuationResultsValidToCell
                  validTo={validTo}
                  isAddMode={isCurrentRowAddMode}
                />
              ),
            },
            [ValuationResultsTableColumns.regulatoryRelevant]: {
              value: formatBoolean(regulatoryRelevance === valuationRelevance.relevant),
              cellComponent: (
                <PropertyValuationResultsRegulatoryRelevantCell
                  regulatoryRelevance={regulatoryRelevance}
                  isAddMode={isCurrentRowAddMode}
                />
              ),
            },
            [ValuationResultsTableColumns.actions]: {
              value: valuationId,
              cellComponent: (
                <PropertyValuationResultsTableActionsCell
                  isAddMode={isCurrentRowAddMode}
                  propertyUuid={propertyUuid}
                />
              ),
            },
          }
        },
      ),
    [
      currentEditedValues?.calculationMethodCode,
      currentEditedValues?.keyDate,
      currentEditedValues?.validFrom,
      currentEditedValues?.validTo,
      currentEditedValues?.valuationId,
      currentEditedValues?.valueAmount?.value,
      formatBoolean,
      highlightedValuations,
      isAddMode,
      propertyUuid,
      t,
      valuationData,
    ],
  )

  return useMemo(() => ({ tableData }), [tableData])
}

export default useMapPropertyValuationResultsTableData
