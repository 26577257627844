import { MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import { Answer } from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/questionnaireStructure'

const SAQ1_1_KEY_PATH = ['SAQA', 'SAQ1', 'SAQ1-1']
const SAQ1_2_KEY_PATH = ['SAQA', 'SAQ1', 'SAQ1-2']
const SAQ2_KEY_PATH = ['SAQA', 'SAQ2', 'SAQ2']
const SAQ3_KEY_PATH = ['SAQA', 'SAQ3', 'SAQ3']
const SAQ4_1_KEY_PATH = ['SAQA', 'SAQ4', 'SAQ4-1']
const SAQ4_2_KEY_PATH = ['SAQA', 'SAQ4', 'SAQ4-2']
const SAQ4_3_KEY_PATH = ['SAQA', 'SAQ4', 'SAQ4-3']
const SAQ5_KEY_PATH = ['SAQA', 'SAQ5', 'SAQ5']
const SAQ6_1_KEY_PATH = ['SAQA', 'SAQ6', 'SAQ6-1']
const SAQ6_2_KEY_PATH = ['SAQA', 'SAQ6', 'SAQ6-2']
const SAQ7_1_KEY_PATH = ['SAQA', 'SAQ7', 'SAQ7-1']
const SAQ7_2_KEY_PATH = ['SAQA', 'SAQ7', 'SAQ7-2']
const SAQ7_3_KEY_PATH = ['SAQA', 'SAQ7', 'SAQ7-3']
const SAQ8_KEY_PATH = ['SAQA', 'SAQ8', 'SAQ8']
const SAQ9_1_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-1']
const SAQ9_2_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-2']
const SAQ9_3_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-3']
const SAQ9_4_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-4']
const SAQ9_5_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-5']
const SAQ9_6_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-6']
const SAQ9_7_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-7']
const SAQ9_8_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-8']
const SAQ9_9_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-9']
const SAQ9_10_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-10']
const SAQ9_11_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-11']
const SAQ9_12_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-12']
const SAQ9_13_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-13']
const SAQ9_14_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-14']
const SAQ9_15_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-15']
const SAQ9_16_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-16']
const SAQ9_17_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-17']
const SAQ9_18_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-18']
const SAQ9_19_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-19']
const SAQ9_20_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-20']
const SAQ9_21_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-21']
const SAQ9_22_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-22']
const SAQ9_23_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-23']
const SAQ9_24_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-24']
const SAQ9_25_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-25']
const SAQ9_26_KEY_PATH = ['SAQA', 'SAQ9', 'SAQ9-26']
const SAQ10_1_KEY_PATH = ['SAQA', 'SAQ10', 'SAQ10-1']
const SAQ10_2_KEY_PATH = ['SAQA', 'SAQ10', 'SAQ10-2']
const SAQ10_3_KEY_PATH = ['SAQA', 'SAQ10', 'SAQ10-3']
const SAQ10_4_KEY_PATH = ['SAQA', 'SAQ10', 'SAQ10-4']
const SAQ10_5_KEY_PATH = ['SAQA', 'SAQ10', 'SAQ10-5']
const SAQ11_1_KEY_PATH = ['SAQA', 'SAQ11', 'SAQ11-1']
const SAQ11_2_KEY_PATH = ['SAQA', 'SAQ11', 'SAQ11-2']
const SAQ11_3_KEY_PATH = ['SAQA', 'SAQ11', 'SAQ11-3']
const SAQ11_4_KEY_PATH = ['SAQA', 'SAQ11', 'SAQ11-4']
const SAQ11_5_KEY_PATH = ['SAQA', 'SAQ11', 'SAQ11-5']

const SBQ1_1_KEY_PATH = ['SBQB', 'SBQ1', 'SBQ1-1']
const SBQ1_2_KEY_PATH = ['SBQB', 'SBQ1', 'SBQ1-2']
const SBQ1_3_KEY_PATH = ['SBQB', 'SBQ1', 'SBQ1-3']
const SBQ1_4_KEY_PATH = ['SBQB', 'SBQ1', 'SBQ1-4']
const SBQ2_1_KEY_PATH = ['SBQB', 'SBQ2', 'SBQ2-1']
const SBQ3_1_KEY_PATH = ['SBQB', 'SBQ3', 'SBQ3-1']
const SBQ3_2_KEY_PATH = ['SBQB', 'SBQ3', 'SBQ3-2']
const SBQ3_3_KEY_PATH = ['SBQB', 'SBQ3', 'SBQ3-3']
const SBQ3_4_KEY_PATH = ['SBQB', 'SBQ3', 'SBQ3-4']
const SBQ3_5_KEY_PATH = ['SBQB', 'SBQ3', 'SBQ3-5']
const SBQ3_6_KEY_PATH = ['SBQB', 'SBQ3', 'SBQ3-6']
const SBQ3_7_KEY_PATH = ['SBQB', 'SBQ3', 'SBQ3-7']

// Positive results
const RES_1_KEY = 'RES-1'
const RES_1_KEY_PATH = [RES_1_KEY]
const RES_2_KEY = 'RES-2'
const RES_2_KEY_PATH = [RES_2_KEY]
const RES_3_KEY = 'RES-3'
const RES_3_KEY_PATH = [RES_3_KEY]
// Negative results
const BAD_1_KEY = 'BAD-1'
const BAD_1_KEY_PATH = [BAD_1_KEY]
// Translation Key for highlighted text in results
const END_HIGHLIGHT_TEXT_KEY = 'END-HIGHLIGHT-TEXT'

export const shadowBankingStructure = [
  {
    key: 'SAQA',
    children: [
      {
        key: 'SAQ1',
        children: [
          {
            key: 'SAQ1-1',
            alwaysVisible: true,
            proceedWith: [
              {
                key: SAQ2_KEY_PATH,
                condition: [{ keys: [SAQ1_1_KEY_PATH], result: Answer.NO }],
              },
              { key: SAQ1_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ1-2',
            proceedWith: [
              {
                key: SAQ2_KEY_PATH,
                condition: [{ keys: [SAQ1_2_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_1_KEY_PATH },
            ],
          },
        ],
      },
      {
        key: 'SAQ2',
        children: [
          {
            key: 'SAQ2',
            proceedWith: [
              {
                key: SAQ3_KEY_PATH,
                condition: [{ keys: [SAQ2_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_1_KEY_PATH },
            ],
          },
        ],
      },
      {
        key: 'SAQ3',
        children: [
          {
            key: 'SAQ3',
            proceedWith: [
              {
                key: SAQ4_1_KEY_PATH,
                condition: [{ keys: [SAQ3_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_1_KEY_PATH },
            ],
          },
        ],
      },
      {
        key: 'SAQ4',
        hint: true,
        children: [
          {
            key: 'SAQ4-1',
            proceedWith: [
              {
                key: SAQ5_KEY_PATH,
                condition: [{ keys: [SAQ4_1_KEY_PATH], result: Answer.NO }],
              },
              { key: SAQ4_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ4-2',
            proceedWith: [
              {
                key: SAQ5_KEY_PATH,
                condition: [{ keys: [SAQ4_2_KEY_PATH], result: Answer.NO }],
              },
              { key: SAQ4_3_KEY_PATH },
            ],
          },
          {
            key: 'SAQ4-3',
            proceedWith: [
              {
                key: SAQ5_KEY_PATH,
                condition: [{ keys: [SAQ4_3_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_1_KEY_PATH },
            ],
          },
        ],
      },
      {
        key: 'SAQ5',
        hint: true,
        children: [
          {
            key: 'SAQ5',
            proceedWith: [
              {
                key: SAQ6_1_KEY_PATH,
                condition: [{ keys: [SAQ5_KEY_PATH], result: Answer.NO }],
              },
              { key: SBQ1_1_KEY_PATH },
            ],
            infos: [
              {
                type: 'INF-1',
                condition: [{ keys: [SAQ5_KEY_PATH], result: Answer.YES }],
              },
            ],
          },
        ],
      },
      {
        key: 'SAQ6',
        children: [
          {
            key: 'SAQ6-1',
            proceedWith: [
              {
                key: SAQ7_1_KEY_PATH,
                condition: [{ keys: [SAQ6_1_KEY_PATH], result: Answer.NO }],
              },
              { key: SAQ6_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ6-2',
            proceedWith: [
              {
                key: SAQ7_1_KEY_PATH,
                condition: [{ keys: [SAQ6_2_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_1_KEY_PATH },
            ],
          },
        ],
      },
      {
        key: 'SAQ7',
        children: [
          {
            key: 'SAQ7-1',
            proceedWith: [
              {
                key: SAQ7_2_KEY_PATH,
                condition: [{ keys: [SAQ7_1_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_3_KEY_PATH },
            ],
          },
          {
            key: 'SAQ7-2',
            proceedWith: [
              {
                key: SAQ7_3_KEY_PATH,
                condition: [{ keys: [SAQ7_2_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_3_KEY_PATH },
            ],
          },
          {
            key: 'SAQ7-3',
            proceedWith: [
              {
                key: SAQ8_KEY_PATH,
                condition: [{ keys: [SAQ7_3_KEY_PATH], result: Answer.NO }],
              },
              { key: SBQ1_1_KEY_PATH },
            ],
            infos: [
              {
                type: 'INF-1',
                condition: [{ keys: [SAQ7_3_KEY_PATH], result: Answer.YES }],
              },
            ],
          },
        ],
      },
      {
        key: 'SAQ8',
        children: [
          {
            key: 'SAQ8',
            proceedWith: [
              {
                key: SAQ9_1_KEY_PATH,
                condition: [{ keys: [SAQ8_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_1_KEY_PATH },
            ],
          },
        ],
      },
      {
        key: 'SAQ9',
        children: [
          {
            key: 'SAQ9-1',
            proceedWith: [
              {
                key: SAQ9_2_KEY_PATH,
                condition: [{ keys: [SAQ9_1_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-2',
            proceedWith: [
              {
                key: SAQ9_3_KEY_PATH,
                condition: [{ keys: [SAQ9_2_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-3',
            proceedWith: [
              {
                key: SAQ9_4_KEY_PATH,
                condition: [{ keys: [SAQ9_3_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-4',
            proceedWith: [
              {
                key: SAQ9_5_KEY_PATH,
                condition: [{ keys: [SAQ9_4_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-5',
            proceedWith: [
              {
                key: SAQ9_6_KEY_PATH,
                condition: [{ keys: [SAQ9_5_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-6',
            proceedWith: [
              {
                key: SAQ9_7_KEY_PATH,
                condition: [{ keys: [SAQ9_6_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-7',
            proceedWith: [
              {
                key: SAQ9_8_KEY_PATH,
                condition: [{ keys: [SAQ9_7_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-8',
            proceedWith: [
              {
                key: SAQ9_9_KEY_PATH,
                condition: [{ keys: [SAQ9_8_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-9',
            proceedWith: [
              {
                key: SAQ9_10_KEY_PATH,
                condition: [{ keys: [SAQ9_9_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-10',
            proceedWith: [
              {
                key: SAQ9_11_KEY_PATH,
                condition: [{ keys: [SAQ9_10_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-11',
            proceedWith: [
              {
                key: SAQ9_12_KEY_PATH,
                condition: [{ keys: [SAQ9_11_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-12',
            proceedWith: [
              {
                key: SAQ9_13_KEY_PATH,
                condition: [{ keys: [SAQ9_12_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-13',
            proceedWith: [
              {
                key: SAQ9_14_KEY_PATH,
                condition: [{ keys: [SAQ9_13_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-14',
            proceedWith: [
              {
                key: SAQ9_15_KEY_PATH,
                condition: [{ keys: [SAQ9_14_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-15',
            proceedWith: [
              {
                key: SAQ9_16_KEY_PATH,
                condition: [{ keys: [SAQ9_15_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-16',
            proceedWith: [
              {
                key: SAQ9_17_KEY_PATH,
                condition: [{ keys: [SAQ9_16_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-17',
            proceedWith: [
              {
                key: SAQ9_18_KEY_PATH,
                condition: [{ keys: [SAQ9_17_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-18',
            proceedWith: [
              {
                key: SAQ9_19_KEY_PATH,
                condition: [{ keys: [SAQ9_18_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-19',
            proceedWith: [
              {
                key: SAQ9_20_KEY_PATH,
                condition: [{ keys: [SAQ9_19_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-20',
            proceedWith: [
              {
                key: SAQ9_21_KEY_PATH,
                condition: [{ keys: [SAQ9_20_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-21',
            proceedWith: [
              {
                key: SAQ9_22_KEY_PATH,
                condition: [{ keys: [SAQ9_21_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-22',
            proceedWith: [
              {
                key: SAQ9_23_KEY_PATH,
                condition: [{ keys: [SAQ9_22_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-23',
            proceedWith: [
              {
                key: SAQ9_24_KEY_PATH,
                condition: [{ keys: [SAQ9_23_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-24',
            proceedWith: [
              {
                key: SAQ9_25_KEY_PATH,
                condition: [{ keys: [SAQ9_24_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-25',
            proceedWith: [
              {
                key: SAQ9_26_KEY_PATH,
                condition: [{ keys: [SAQ9_25_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SAQ9-26',
            proceedWith: [
              {
                key: SAQ10_1_KEY_PATH,
                condition: [{ keys: [SAQ9_26_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_1_KEY_PATH },
            ],
          },
        ],
      },
      {
        key: 'SAQ10',
        children: [
          {
            key: 'SAQ10-1',
            proceedWith: [
              {
                key: SAQ10_2_KEY_PATH,
                condition: [{ keys: [SAQ10_1_KEY_PATH], result: Answer.NO }],
              },
              { key: SBQ1_1_KEY_PATH },
            ],
            infos: [
              {
                type: 'INF-1',
                condition: [{ keys: [SAQ10_1_KEY_PATH], result: Answer.YES }],
              },
            ],
          },
          {
            key: 'SAQ10-2',
            proceedWith: [
              {
                key: SAQ10_3_KEY_PATH,
                condition: [{ keys: [SAQ10_2_KEY_PATH], result: Answer.NO }],
              },
              { key: SBQ1_1_KEY_PATH },
            ],
            infos: [
              {
                type: 'INF-1',
                condition: [{ keys: [SAQ10_2_KEY_PATH], result: Answer.YES }],
              },
            ],
          },
          {
            key: 'SAQ10-3',
            proceedWith: [
              {
                key: SAQ10_4_KEY_PATH,
                condition: [{ keys: [SAQ10_3_KEY_PATH], result: Answer.NO }],
              },
              { key: SBQ1_1_KEY_PATH },
            ],
            infos: [
              {
                type: 'INF-1',
                condition: [{ keys: [SAQ10_3_KEY_PATH], result: Answer.YES }],
              },
            ],
          },
          {
            key: 'SAQ10-4',
            proceedWith: [
              {
                key: SAQ10_5_KEY_PATH,
                condition: [{ keys: [SAQ10_4_KEY_PATH], result: Answer.NO }],
              },
              { key: SBQ1_1_KEY_PATH },
            ],
            infos: [
              {
                type: 'INF-1',
                condition: [{ keys: [SAQ10_4_KEY_PATH], result: Answer.YES }],
              },
            ],
          },
          {
            key: 'SAQ10-5',
            proceedWith: [
              {
                key: SAQ11_1_KEY_PATH,
                condition: [{ keys: [SAQ10_5_KEY_PATH], result: Answer.NO }],
              },
              { key: SBQ1_1_KEY_PATH },
            ],
            infos: [
              {
                type: 'INF-1',
                condition: [{ keys: [SAQ10_5_KEY_PATH], result: Answer.YES }],
              },
            ],
          },
        ],
      },
      {
        key: 'SAQ11',
        children: [
          {
            key: 'SAQ11-1',
            proceedWith: [
              {
                key: SAQ11_2_KEY_PATH,
                condition: [{ keys: [SAQ11_1_KEY_PATH], result: Answer.NO }],
              },
              { key: SBQ1_1_KEY_PATH },
            ],
            infos: [
              {
                type: 'INF-1',
                condition: [{ keys: [SAQ11_1_KEY_PATH], result: Answer.YES }],
              },
            ],
          },
          {
            key: 'SAQ11-2',
            proceedWith: [
              {
                key: SAQ11_3_KEY_PATH,
                condition: [{ keys: [SAQ11_2_KEY_PATH], result: Answer.NO }],
              },
              { key: SBQ1_1_KEY_PATH },
            ],
            infos: [
              {
                type: 'INF-1',
                condition: [{ keys: [SAQ11_2_KEY_PATH], result: Answer.YES }],
              },
            ],
          },
          {
            key: 'SAQ11-3',
            proceedWith: [
              {
                key: SAQ11_4_KEY_PATH,
                condition: [{ keys: [SAQ11_3_KEY_PATH], result: Answer.NO }],
              },
              { key: SBQ1_1_KEY_PATH },
            ],
            infos: [
              {
                type: 'INF-1',
                condition: [{ keys: [SAQ11_3_KEY_PATH], result: Answer.YES }],
              },
            ],
          },
          {
            key: 'SAQ11-4',
            proceedWith: [
              {
                key: SAQ11_5_KEY_PATH,
                condition: [{ keys: [SAQ11_4_KEY_PATH], result: Answer.NO }],
              },
              { key: SBQ1_1_KEY_PATH },
            ],
            infos: [
              {
                type: 'INF-1',
                condition: [{ keys: [SAQ11_4_KEY_PATH], result: Answer.YES }],
              },
            ],
          },
          {
            key: 'SAQ11-5',
            proceedWith: [
              {
                key: RES_2_KEY_PATH,
                condition: [{ keys: [SAQ11_5_KEY_PATH], result: Answer.NO }],
              },
              { key: SBQ1_1_KEY_PATH },
            ],
            infos: [
              {
                type: 'INF-1',
                condition: [{ keys: [SAQ11_5_KEY_PATH], result: Answer.YES }],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: 'SBQB',
    children: [
      {
        key: 'SBQ1',
        children: [
          {
            key: 'SBQ1-1',
            proceedWith: [
              {
                key: SBQ2_1_KEY_PATH,
                condition: [{ keys: [SBQ1_1_KEY_PATH], result: Answer.NO }],
              },
              { key: SBQ1_2_KEY_PATH },
            ],
          },
          {
            key: 'SBQ1-2',
            proceedWith: [
              {
                key: SBQ2_1_KEY_PATH,
                condition: [{ keys: [SBQ1_2_KEY_PATH], result: Answer.NO }],
              },
              { key: SBQ1_3_KEY_PATH },
            ],
          },
          {
            key: 'SBQ1-3',
            proceedWith: [
              {
                key: SBQ2_1_KEY_PATH,
                condition: [{ keys: [SBQ1_3_KEY_PATH], result: Answer.NO }],
              },
              { key: SBQ1_4_KEY_PATH },
            ],
          },
          {
            key: 'SBQ1-4',
            proceedWith: [
              {
                key: SBQ2_1_KEY_PATH,
                condition: [{ keys: [SBQ1_4_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_1_KEY_PATH },
            ],
          },
        ],
      },
      {
        key: 'SBQ2',
        children: [
          {
            key: 'SBQ2-1',
            proceedWith: [
              {
                key: SBQ3_1_KEY_PATH,
                condition: [{ keys: [SBQ2_1_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_1_KEY_PATH },
            ],
          },
        ],
      },
      {
        key: 'SBQ3',
        children: [
          {
            key: 'SBQ3-1',
            proceedWith: [
              {
                key: SBQ3_2_KEY_PATH,
                condition: [{ keys: [SBQ3_1_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SBQ3-2',
            proceedWith: [
              {
                key: SBQ3_3_KEY_PATH,
                condition: [{ keys: [SBQ3_2_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SBQ3-3',
            proceedWith: [
              {
                key: SBQ3_4_KEY_PATH,
                condition: [{ keys: [SBQ3_3_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SBQ3-4',
            proceedWith: [
              {
                key: SBQ3_5_KEY_PATH,
                condition: [{ keys: [SBQ3_4_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SBQ3-5',
            proceedWith: [
              {
                key: SBQ3_6_KEY_PATH,
                condition: [{ keys: [SBQ3_5_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SBQ3-6',
            proceedWith: [
              {
                key: SBQ3_7_KEY_PATH,
                condition: [{ keys: [SBQ3_6_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_2_KEY_PATH },
            ],
          },
          {
            key: 'SBQ3-7',
            proceedWith: [
              {
                key: BAD_1_KEY_PATH,
                condition: [{ keys: [SBQ3_7_KEY_PATH], result: Answer.NO }],
              },
              { key: RES_1_KEY_PATH },
            ],
          },
        ],
      },
    ],
  },
]

export const possibleEndings = [
  {
    key: RES_1_KEY,
    highlightedTextKey: END_HIGHLIGHT_TEXT_KEY,
    messageStripDesign: MessageStripDesign.Positive,
  },
  {
    key: RES_2_KEY,
    highlightedTextKey: END_HIGHLIGHT_TEXT_KEY,
    messageStripDesign: MessageStripDesign.Positive,
  },
  {
    key: RES_3_KEY,
    highlightedTextKey: END_HIGHLIGHT_TEXT_KEY,
    messageStripDesign: MessageStripDesign.Positive,
  },
  {
    key: BAD_1_KEY,
    highlightedTextKey: END_HIGHLIGHT_TEXT_KEY,
    messageStripDesign: MessageStripDesign.Warning,
  },
]
