import { Label, WrappingType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/impairment-check/CommentQuestion.module.css'
import RichTextTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/RichTextTile'

const propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isEditMode: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool,
  onChange: PropTypes.func,
}
/**
 * @typedef {object} overrides
 * @property {(newValue: string) => void} [onChange]
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 */
const CommentQuestion = ({ label, value, isEditMode, onChange, isPdfView }) => (
  <div className={styles.question}>
    <Label showColon className={styles.label} wrappingType={WrappingType.Normal}>
      {label}
    </Label>
    <RichTextTile
      isPdfView={isPdfView}
      isEditMode={isEditMode}
      currentContent={value}
      onChange={onChange}
      className={styles.value}
    />
  </div>
)

CommentQuestion.propTypes = propTypes

export default CommentQuestion
