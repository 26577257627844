import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { useEventTypes } from 'hooks/services/business-events-and-tasks/events/useEventTypes'

const propTypes = {
  entityType: PropTypes.string,
  eventCode: PropTypes.string,
  onChange: PropTypes.func,
  customEventCodeFilterList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
    }),
  ),
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const EventTypeSelect = forwardRef(
  ({ eventCode, entityType, onChange, customEventCodeFilterList = [] }, _ref) => {
    if (customEventCodeFilterList?.length > 0) {
      return (
        <Select
          id="events-search-event-type-select"
          onChange={({
            detail: {
              selectedOption: { value: selectedEventCode },
            },
          }) => onChange(selectedEventCode)}
          value={eventCode}
        >
          {[
            ...customEventCodeFilterList.map((eventType, index) => {
              const { name, code } = eventType
              return (
                <Option
                  key={`entity-type-${code}`}
                  value={code}
                  selected={(eventCode === '' && index === 0) || eventCode === code}
                >
                  {name}
                </Option>
              )
            }),
          ]}
        </Select>
      )
    }

    if (!isNil(entityType)) {
      return (
        <LoadingSelect
          id="events-search-event-type-loading-select"
          loadingHook={useEventTypes}
          onChange={(_event, selectedEventCode) => onChange(selectedEventCode)}
          selectionName="result"
          optionKeyName="code"
          optionDisplayName="name"
          loadingHookParams={{ entityType: entityType }}
          selectedKey={eventCode}
        />
      )
    }
    return undefined
  },
)

EventTypeSelect.displayName = 'EventTypeSelect'

EventTypeSelect.propTypes = propTypes

export default EventTypeSelect
