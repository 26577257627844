import isNil from 'lodash.isnil'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useRequirementsCellAddMode = ({ requirementId }) => {
  const isRequirementAddMode = useSelector(
    (state) => state.conditions.requirementsTable.isRequirementAddMode,
  )
  const isAddModeForCurrentRow = useMemo(
    () => isRequirementAddMode && isNil(requirementId),
    [isRequirementAddMode, requirementId],
  )

  return useMemo(
    () => ({
      isRequirementAddMode,
      isAddModeForCurrentRow,
    }),
    [isRequirementAddMode, isAddModeForCurrentRow],
  )
}

export default useRequirementsCellAddMode
