import { useContext } from 'react'
import useCurrentMultiPropertyKpis from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpis'
import { PropertyPortfolioContext } from 'routes/properties/portfolio/PropertyPortfolioContext'

/**
 * Fetch properties kpis for cards on the property portfolio rent roll overview page.
 * As properties kpis are used sereval times on the page, we want to ensure
 * that the query is called with consistent arguments, so that it can be
 * deduplicated.
 */
const useCurrentRentRollOverviewMultiPropertyKpis = () => {
  const { properties } = useContext(PropertyPortfolioContext)
  const propertyUuids = properties?.map((property) => property.uuid) ?? []

  return useCurrentMultiPropertyKpis(
    propertyUuids,
    {
      withTenantGranularity: true,
      withSegmentUsageTypeGranularity: true,
    },
    { enabled: propertyUuids.length > 0 },
  )
}

export default useCurrentRentRollOverviewMultiPropertyKpis
