import {
  IllustratedMessage,
  IllustrationMessageType,
  IllustrationMessageSize,
} from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'

const DecisionProcessEmptyPage = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process',
  })

  return (
    <IllustratedMessage
      titleText={t('page.empty.title')}
      subtitleText={t('page.empty.subtitle')}
      name={IllustrationMessageType.NoTasks}
      size={IllustrationMessageSize.Scene}
    />
  )
}

export default DecisionProcessEmptyPage
