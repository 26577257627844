import isEmpty from 'lodash.isempty'
import maxBy from 'lodash.maxby'
import sortBy from 'lodash.sortby'
import uniq from 'lodash.uniq'
import { calculateTenancyTotalRentCurrentYear } from 'hooks/services/business-partners/tenancy/calculateTenancyTotalRentCurrentYear'
import { groupRentalUnitsBySegmentUsageTypeCode } from 'hooks/services/business-partners/tenancy/tenancyCalculationUtils'

const daysInAYear = 365
const millisecondsInASecond = 1000
const secondsInAnHour = 3600
const hoursInADay = 24

const getMappedDayDifference = (date1, date2) => {
  const timeDifference = date1.getTime() - date2.getTime()

  const differenceInDays = Math.ceil(
    timeDifference / (millisecondsInASecond * secondsInAnHour * hoursInADay),
  )
  return differenceInDays / daysInAYear
}

/* Determines the first part of the formula for wault calculation:
(lease_break_date - key_date) / 365 * rent_current_year*/
const getNormalizedProduct = (date1, date2, rent) => {
  const normalizedBreakDate = getMappedDayDifference(date1, date2)
  return normalizedBreakDate * rent
}

const collectAllUnitsForCurrentYear = (rentalUnits) =>
  rentalUnits.map((rentalUnit) => rentalUnit.rentCurrentYear?.currency)

const filterRentalUnitsByDateAndOccupancyStatus = (
  rentalUnits,
  vacantUtilizationKeys,
  selfUtilizationKeys,
) => {
  const maxKeyDate = maxBy(rentalUnits, ({ keyDate }) => new Date(keyDate))?.keyDate
  return rentalUnits.filter(
    (rentalUnit) =>
      !vacantUtilizationKeys?.includes(rentalUnit.occupancyStatus) &&
      !selfUtilizationKeys?.includes(rentalUnit.occupancyStatus) &&
      new Date(rentalUnit.leaseBreakDate) > new Date(maxKeyDate),
  )
}

const getDividend = (rentalUnits, units) => {
  const getRent = (rentalUnit) =>
    units.length > 1
      ? getNormalizedProduct(
          new Date(rentalUnit.leaseBreakDate),
          new Date(rentalUnit.keyDate),
          rentalUnit.rentCurrentYearInHeadQuarterCurrency?.number ?? 0,
        )
      : getNormalizedProduct(
          new Date(rentalUnit.leaseBreakDate),
          new Date(rentalUnit.keyDate),
          rentalUnit.rentCurrentYear?.number ?? 0,
        )

  return rentalUnits.reduce((sum, rentalUnit) => sum + getRent(rentalUnit), 0)
}

/* contextRentalUnits determines whether rentCurrentYear or rentCurrentYearInHeadQuarterCurrency is used! */
export const calculateTenancyWault = (
  contextRentalUnits,
  rentalUnits,
  vacantUtilizationKeys,
  selfUtilizationKeys,
) => {
  /* The formula for WAULT is:
  sumAllRentalUnitsThatMatchFilter((lease_break_date - key_date) / 365 * rent_current_year) /
  sumAllRentalUnitsThatMatchFilter(rentCurrentYear) */

  const filteredContextRentalUnits = filterRentalUnitsByDateAndOccupancyStatus(
    contextRentalUnits,
    vacantUtilizationKeys,
    selfUtilizationKeys,
  )
  const units = uniq(collectAllUnitsForCurrentYear(filteredContextRentalUnits))

  const filteredRentalUnits = filterRentalUnitsByDateAndOccupancyStatus(
    rentalUnits,
    vacantUtilizationKeys,
    selfUtilizationKeys,
  )

  if (isEmpty(filteredRentalUnits)) {
    return {
      value: NaN,
    }
  }
  const totalRentCurrentYear = calculateTenancyTotalRentCurrentYear(filteredRentalUnits, units)
  return {
    value: getDividend(filteredRentalUnits, units) / totalRentCurrentYear.value,
  }
}

const getWaultValuesForSegmentUsageTypes = (filteredRentalUnits, units) => {
  const groupedRentalUnits = groupRentalUnitsBySegmentUsageTypeCode(filteredRentalUnits)

  const waultPerSegementUsageType = []
  Object.entries(groupedRentalUnits).forEach((rentalUnitsBySegement) => {
    const segmentName = rentalUnitsBySegement[1][0].segmentUsageTypeName
    const rentalUnitsForSegment = rentalUnitsBySegement[1]

    waultPerSegementUsageType.push({
      value:
        getDividend(rentalUnitsForSegment, units) /
        calculateTenancyTotalRentCurrentYear(rentalUnitsForSegment, units).value,
      type: segmentName,
    })
  })

  return sortBy(waultPerSegementUsageType, (item) => item.type)
}

export const calculateTenancyWaultPerSegmentUsageType = (
  rentalUnits,
  vacantUtilizationKeys,
  selfUtilizationKeys,
) => {
  const filteredRentalUnits = filterRentalUnitsByDateAndOccupancyStatus(
    rentalUnits,
    vacantUtilizationKeys,
    selfUtilizationKeys,
  )
  const units = uniq(collectAllUnitsForCurrentYear(filteredRentalUnits))
  return getWaultValuesForSegmentUsageTypes(filteredRentalUnits, units)
}
