import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import styles from 'components/domains/properties/valuation/table/table-cells/edit/ValuationRequestsTableValuationTypeSelect.module.css'

/**
 *  @typedef {PropType.InferProps<typeof ValuationRequestsTableValuationTypeSelect.propTypes>} ValuationRequestsTableValuationTypeSelect
 */
const ValuationRequestsTableValuationTypeSelect = ({
  valuationType,
  valuationReasons,
  reason,
  onChange,
  disabled,
}) => {
  const handleOnValuationTypeChange = useCallback(
    ({
      detail: {
        selectedOption: { value: selectedStatus },
      },
    }) => {
      onChange(selectedStatus)
    },
    [onChange],
  )
  return (
    <Select
      id="edit-valuation-request-type"
      className={styles.valuationTypeSelect}
      onChange={handleOnValuationTypeChange}
      disabled={disabled}
    >
      <Option key={-1} value={''} selected={!valuationType} />
      {valuationReasons?.valuation_reasons
        .find((entry) => entry.reason === reason)
        ?.types.valuation_types.map(({ type: optionValuationType }) => (
          <Option
            key={optionValuationType}
            value={optionValuationType}
            selected={valuationType === optionValuationType}
          >
            {optionValuationType}
          </Option>
        ))}
    </Select>
  )
}

ValuationRequestsTableValuationTypeSelect.propTypes = {
  valuationReasons: PropTypes.object.isRequired,
  reason: PropTypes.string,
  valuationType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default ValuationRequestsTableValuationTypeSelect
