import { Input } from '@fioneer/ui5-webcomponents-react'
import merge from 'lodash.merge'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/BusinessPartnerCreateForm.module.css'
import IndustryInput from 'components/domains/business-partners/tile/general-information/IndustryInput'
import CountryBusinessPartnerCreationSelect from 'components/domains/rentroll/CountryBusinessPartnerCreationSelect'
import Labeled from 'components/ui/data/Labeled'
import LoadingDropdown from 'components/ui/dropdown/LoadingDropdown'
import useGroupings from 'hooks/services/business-partners/config/useGroupings'
import useRegionsForLoadingDropdown from 'hooks/services/business-partners/config/useRegionsForLoadingDropdown'

const CharacterLimit = {
  name1: 40,
  orgName2: 40,
  orgName3: 40,
  postalCode: 10,
  city: 40,
  street: 60,
}

const businessPartnerProps = PropTypes.shape({
  orgName1: PropTypes.string,
  orgName2: PropTypes.string,
  orgName3: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  address: PropTypes.shape({
    country: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    region: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    city: PropTypes.string,
    streetName: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  grouping: PropTypes.string,
  industry: PropTypes.shape({
    sectorId: PropTypes.string.isRequired,
    keyDescription: PropTypes.string,
  }),
})

const businessPartnerCreateFormProps = {
  values: businessPartnerProps,
  onChange: PropTypes.func,
  checkIsValid: PropTypes.func,
  isLoading: PropTypes.bool,
}

/** @param {PropTypes.InferProps<typeof businessPartnerCreateFormProps>} props */
const BusinessPartnerCreateForm = ({
  values: { orgName1, orgName2, orgName3, address = {}, grouping, industry } = {},
  onChange = (_) => {},
  checkIsValid = (_) => {},
  isLoading = false,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-partner.create-form',
  })

  useEffect(() => {
    checkIsValid(!!orgName1 && !!grouping)
  }, [checkIsValid, orgName1, grouping])

  const updateAddress = (value) => onChange({ address: merge(address, value) })

  return (
    <div className={styles.form}>
      <Labeled label={t('label-name-1')} required vertical showColon>
        <Input
          data-testid="name-1-input"
          value={orgName1}
          maxlength={CharacterLimit.name1}
          onFocus={(e) => e.stopPropagation()}
          onChange={(e) => onChange({ orgName1: e.target.value })}
          disabled={isLoading}
        />
      </Labeled>
      <Labeled label={t('label-name-2')} vertical showColon>
        <Input
          data-testid="name-2-input"
          value={orgName2}
          maxlength={CharacterLimit.orgName2}
          onFocus={(e) => e.stopPropagation()}
          onChange={(e) => onChange({ orgName2: e.target.value })}
          disabled={isLoading}
        />
      </Labeled>
      <Labeled label={t('label-name-3')} vertical showColon>
        <Input
          data-testid="name-3-input"
          value={orgName3}
          maxlength={CharacterLimit.orgName3}
          onFocus={(e) => e.stopPropagation()}
          onChange={(e) => onChange({ orgName3: e.target.value })}
          disabled={isLoading}
        />
      </Labeled>
      <Labeled label={t('label-country')} vertical showColon>
        <CountryBusinessPartnerCreationSelect
          id="country-input"
          value={address.country?.id}
          onChange={(id) => updateAddress({ country: { id, name: '' } })}
          disabled={isLoading}
        />
      </Labeled>
      <Labeled vertical showColon label={t('label-region')}>
        <LoadingDropdown
          id="region-input"
          useLoader={useRegionsForLoadingDropdown}
          input={address.country?.id}
          initialDropdownValue=""
          value={address.region?.id}
          onChange={(id) => updateAddress({ region: { id, name: '' } })}
          disabled={!address.country?.id || isLoading}
        />
      </Labeled>
      <Labeled vertical showColon label={t('label-city')}>
        <Input
          data-testid="city-input"
          value={address.city}
          maxlength={CharacterLimit.city}
          onFocus={(e) => e.stopPropagation()}
          onChange={(e) => updateAddress({ city: e.target.value })}
          disabled={!address.country?.id || isLoading}
        />
      </Labeled>
      <Labeled vertical showColon label={t('label-street')}>
        <Input
          id="street-input"
          value={address.streetName}
          maxlength={CharacterLimit.street}
          onFocus={(e) => e.stopPropagation()}
          onChange={(e) => updateAddress({ streetName: e.target.value })}
          disabled={!address.country?.id || isLoading}
        />
      </Labeled>
      <Labeled vertical showColon label={t('label-postal-code')}>
        <Input
          id="postal-code-input"
          value={address.postalCode}
          maxlength={CharacterLimit.postalCode}
          onFocus={(e) => e.stopPropagation()}
          onChange={(e) => updateAddress({ postalCode: e.target.value })}
          disabled={!address.country?.id || isLoading}
        />
      </Labeled>
      <Labeled vertical showColon label={t('label-grouping')} required>
        <LoadingDropdown
          id="grouping-input"
          useLoader={useGroupings}
          initialDropdownValue=""
          value={grouping}
          onChange={(value) => onChange({ grouping: value })}
          disabled={isLoading}
        />
      </Labeled>
      <Labeled vertical showColon label={t('label-industry')}>
        <IndustryInput
          industry={industry}
          setIndustry={({ sectorId, keyDescription } = {}) =>
            onChange({ industry: sectorId ? { sectorId, keyDescription } : undefined })
          }
          disabled={isLoading}
        />
      </Labeled>
    </div>
  )
}

BusinessPartnerCreateForm.propTypes = businessPartnerCreateFormProps

export { businessPartnerProps }
export default BusinessPartnerCreateForm
