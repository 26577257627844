// To see examples of what input gets converted to which output look at the tests
export const mapPolygonLatLngArrayToPolygonArrayArray = (polygonLatLngArray) => {
  const result = [
    [
      polygonLatLngArray.map((point) => ({
        lat: point['lat'],
        lng: point['lng'],
      })),
    ],
  ]

  result[0][0].push({
    lat: polygonLatLngArray[0]['lat'],
    lng: polygonLatLngArray[0]['lng'],
  })
  return result
}

export const mapGeometryPolygonObjectArrayToPolygonArrayArray = (geometryPolygon) => [
  [
    geometryPolygon.slice().map((point) => ({
      lat: point[1],
      lng: point[0],
    })),
  ],
]

const singlePolygonToCoordArray = (polygon) =>
  polygon.map((ring) => ring.slice().map((point) => [point['lat'], point['lng']])).flat()

export const polygonsToCoordArray = (polygonArrayArray) => {
  let coordArray = []

  polygonArrayArray.forEach((polygon) => {
    coordArray = coordArray.concat(singlePolygonToCoordArray(polygon))
  })

  return coordArray
}

export const getBounds = (coordArray, addExtraLatLength = true, padding = 0) => {
  let minLng = Number.POSITIVE_INFINITY
  let minLat = Number.POSITIVE_INFINITY
  let maxLng = Number.NEGATIVE_INFINITY
  let maxLat = Number.NEGATIVE_INFINITY

  coordArray.forEach((element) => {
    if (element[0] > maxLat) maxLat = element[0]
    if (element[0] < minLat) minLat = element[0]
    if (element[1] > maxLng) maxLng = element[1]
    if (element[1] < minLng) minLng = element[1]
  })

  const latPadding = (maxLat - minLat) * padding
  const lngPadding = (maxLng - minLng) * padding

  /* TODO leaflet thinks that it has more space that it actually has and cuts some part of the polygon out.
   Therefore we act as if the polygon was bigger so that everything is shown */
  const latLengthExtra = addExtraLatLength ? (maxLat - minLat) / 2 : 0

  return [
    [minLat - latLengthExtra - latPadding, minLng - lngPadding],
    [maxLat + latPadding, maxLng + lngPadding],
  ]
}

export const getBoundingBoxCenter = (coordArray) =>
  coordArray.reduce(
    (x, y) => [x[0] + y[0] / coordArray.length, x[1] + y[1] / coordArray.length],
    [0, 0],
  )
