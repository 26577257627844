import { FlexBox } from '@fioneer/ui5-webcomponents-react'
import { isEmpty } from 'lodash'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/FinancingTermsGeneralInformationTile.module.css'
import CommentField from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/fields/CommentField'
import MoneyPercentageField from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/fields/MoneyPercentageField'
import PercentageField from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/fields/PercentageField'
import TextField from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/fields/TextField'

const FinancingTermsGeneralInformationTile = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper', { keyPrefix: 'tiles.financing-terms' })
  const tileState = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const dealData = tileState?.data?.dealData
  const trancheData = tileState?.data?.trancheData

  const { summary: trancheSummary, summaryHeadquarter: trancheSummaryHeadquarter } =
    trancheData ?? {}

  //TODO: Remove the summaryHeadquarter fallback once the tranches-with-sub-entities-endpoint is fixed.
  // Background: If no foreign currency is present, the "summary" prop is null and only "summaryHeadquarter" has data,
  // although both objects should contain (the same) data. This is probably due to the recent HQ currency changes.
  const currency = trancheSummary?.currency ?? trancheSummaryHeadquarter?.currency
  const totalCommitment =
    trancheSummary?.totalCommitment ?? trancheSummaryHeadquarter?.totalCommitment
  const ownCommitment = trancheSummary?.ownCommitment ?? trancheSummaryHeadquarter?.ownCommitment
  const ownCommitmentShare =
    trancheSummary?.ownCommitmentShare ?? trancheSummaryHeadquarter?.ownCommitmentShare

  const renderRWA = () => {
    const rwa = dealData?.riskWeightedAsset
    const rwaShare = rwa / ownCommitment

    return (
      <MoneyPercentageField
        label={t('general-information.rwa')}
        amount={rwa}
        currency={currency}
        percentage={rwaShare}
        showMarginLabel={false}
      />
    )
  }

  const renderCoverpoolEligibleAmount = () => {
    let amountTotal = 0
    let coverPoolCurrency = ''

    if (trancheData?.tranches?.length > 0) {
      trancheData.tranches?.forEach((tranche) => {
        if (
          tranche?.coverpoolEligibleAmount?.currency ===
          tranche?.coverpoolEligibleAmountHeadquarter?.currency
        ) {
          amountTotal += tranche?.coverpoolEligibleAmount?.amount ?? 0
          coverPoolCurrency = tranche?.coverpoolEligibleAmount?.currency ?? coverPoolCurrency
        } else {
          amountTotal += tranche?.coverpoolEligibleAmountHeadquarter?.amount ?? 0
          coverPoolCurrency =
            tranche?.coverpoolEligibleAmountHeadquarter?.currency ?? coverPoolCurrency
        }
      })
    }
    return (
      <MoneyPercentageField
        label={t('general-information.coverpool-eligible-amount')}
        amount={amountTotal}
        currency={coverPoolCurrency}
      />
    )
  }

  const renderRequiredHedgeAmount = () => {
    let amount = 0

    // every amount needs to be used in headquarter currency
    const totalCommitmentHeadquarter = trancheSummaryHeadquarter?.totalCommitment

    if (trancheData?.tranches?.length > 0) {
      let totalTrancheSum = 0

      trancheData.tranches.forEach((tranche) => {
        const hasInterestRateHedgingRequirement =
          tranche?.interestRateHedgingRequirement?.required ?? false

        const requiredHedgeAmountSharePerTranche = hasInterestRateHedgingRequirement
          ? tranche?.interestRateHedgingRequirement?.requiredHedgeAmountShare ?? 0
          : 0
        const totalCommitmentHeadquarterPerTranche =
          tranche?.totalCommitmentHeadquarter?.amount ?? 0

        let fixedTotalCommitmentHeadquarter = 0
        if (tranche?.interestConditions?.items?.length > 0) {
          const hasFixedInterestRate = tranche.interestConditions.items.some(
            (item) => item.interestRateTypeCode === 'Fixed',
          )

          if (hasFixedInterestRate) {
            fixedTotalCommitmentHeadquarter = totalCommitmentHeadquarterPerTranche
          }
        }

        totalTrancheSum +=
          requiredHedgeAmountSharePerTranche * totalCommitmentHeadquarterPerTranche +
          fixedTotalCommitmentHeadquarter
      })
      amount = totalTrancheSum / totalCommitmentHeadquarter
    }

    return <PercentageField label={t('general-information.required-hedge-amount')} value={amount} />
  }

  const renderHedgeInstrument = () => {
    const tranches = trancheData?.tranches

    if (tranches) {
      const allUniqueTranchesFees = compact(
        tranches
          .map((tranche) =>
            tranche?.interestRateHedgingRequirement?.required
              ? tranche?.interestRateHedgingRequirement?.interestRateHedgeTypeShortText
              : '',
          )
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort((a, b) => a?.localeCompare(b)),
      )

      return (
        <TextField
          label={t('general-information.hedge-instrument')}
          value={allUniqueTranchesFees.length > 0 ? allUniqueTranchesFees.join(', ') : '-'}
        />
      )
    }
  }

  const renderHedgeComments = () => {
    const hedgeComments = []

    if (trancheData?.tranches?.length > 0) {
      for (const tranche of trancheData.tranches) {
        if (!isEmpty(tranche?.interestRateHedgingRequirement?.comment)) {
          hedgeComments.push({
            id: tranche?.interestRateHedgingRequirement?.id,
            label: tranche?.trancheName,
            text: tranche?.interestRateHedgingRequirement?.comment,
          })
        }
      }
    }

    return (
      hedgeComments.length > 0 && (
        <FlexBox direction="Column" className={styles.commentsWrapper}>
          <div className={styles.commentsTitle}>{t('general-information.hedge-comment')}</div>
          {hedgeComments.map((comment, index) => (
            <CommentField key={index} label={comment.label} text={comment.text} />
          ))}
        </FlexBox>
      )
    )
  }

  return (
    <>
      <MoneyPercentageField
        label={t('general-information.total-commitment')}
        amount={totalCommitment}
        currency={currency}
        isFirstItem={true}
      />
      <MoneyPercentageField
        label={t('general-information.commitment-own-share')}
        amount={ownCommitment}
        currency={currency}
        percentage={ownCommitmentShare}
        showMarginLabel={false}
      />
      {renderRWA()}
      {renderCoverpoolEligibleAmount()}
      {renderRequiredHedgeAmount()}
      {renderHedgeInstrument()}
      {renderHedgeComments()}
      <div className={styles.spacer} />
    </>
  )
}

FinancingTermsGeneralInformationTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default FinancingTermsGeneralInformationTile
