export default {
  overview: '',
  eventDetail: {
    main: 'business-events/:eventId/*',
    detail: '',
    taskDetail: 'tasks/:taskId',
    decisionPaper: 'decision-paper',
    decisionProcess: 'decision-process',
    decisionStageDetail: 'decision-stages/:decisionStageId',
    decisionStageApproval: 'decision-stages/:decisionStageId/approval',
  },
  create: 'business-events/create',
}
