import useStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useStaffMemberByObjectIdOrEmail'

// TODO: CWP-12317: Maybe refactor the name of the hook. Need to verify that the callers actually will call it with an object id in the future.
const useResolveNameFromEmail = (email) => {
  const { data: staffMember } = useStaffMemberByObjectIdOrEmail({ objectIdOrEmail: email })

  if (!email) {
    return ''
  }

  return staffMember?.fullName ?? email
}

export default useResolveNameFromEmail
