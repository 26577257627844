import { Title } from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { covenantPermissions } from 'api/deals/covenantPermissions'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import DealCovenantChecksCard from 'components/domains/deals/covenants/covenant-checks-table/DealCovenantChecksCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Link from 'components/ui/link/Link'
import Page from 'components/ui/page/Page'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useCovenantAllowedOperations } from 'hooks/services/deals/covenants/useCovenantAllowedOperations'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import { CovenantContext } from 'routes/business-partners/CovenantContext'
import paths from 'routes/paths'

const BusinessPartnerCovenantsPage = () => {
  const { id } = useContext(BusinessPartnerContext)
  const { t } = useTranslation()

  const {
    isLoading: isLoadingAllowedOperationsCovenant,
    isError: isErrorAllowedOperationsCovenant,
    data: { allowedOperations: covenantAllowedOperations = [] } = {},
  } = useCovenantAllowedOperations()
  const isAllowedToReadCovenants = covenantAllowedOperations?.includes(
    covenantPermissions?.readCovenantContractMonitoring,
  )

  const generateBreadcrumbs = () => {
    if (id) {
      return [{ text: t('navigation.item.title.business-partner-covenants') }]
    }
    return []
  }

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [id],
  )

  const renderContent = () =>
    id ? (
      <CovenantContext.Provider value={{ allowedOperations: covenantAllowedOperations }}>
        <CWPLayout>
          <CWPLayout.Full>
            <DealCovenantChecksCard
              entityId={id}
              entityType={cwpEntityTypes.BUSINESS_PARTNER}
              isAllowedToReadCovenants={isAllowedToReadCovenants}
            />
          </CWPLayout.Full>
        </CWPLayout>
      </CovenantContext.Provider>
    ) : (
      <div id="not-found-tab">
        <Title>{t('pages.business-partner.not-found')}</Title>
        <Link href={`/${paths.businessPartners}`}>{t('pages.business-partner.search')}</Link>
      </div>
    )

  return (
    <Page
      isError={isErrorAllowedOperationsCovenant}
      isLoading={isLoadingAllowedOperationsCovenant}
      renderHeaderTitle={() => (
        <BusinessPartnerHeader
          actions={[
            <CreateBusinessPartnerEventAction key={'create-event-action'} />,
            markFavoriteAction,
          ]}
          breadcrumbs={generateBreadcrumbs()}
        />
      )}
      renderContent={renderContent}
    />
  )
}

BusinessPartnerCovenantsPage.propTypes = {}

export default BusinessPartnerCovenantsPage
