import { useMutation } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

export const useUpdateApprovalItem = ({ onSuccess = () => {}, onError = () => {} }) => {
  const { put } = useAccessTokenRequest()
  const { parse, localePattern } = useShortDateFormatter()
  const dateToIsoFormat = (dateString) =>
    DateTime.fromISO(dateString).isValid ? dateString : parse(dateString, localePattern)

  return useMutation(
    async ({ approvalItemId, ...approvalItem }) => {
      const { data } = await put({
        path: `/arrears/approvals/items/${approvalItemId}`,
        body: snakecaseKeys({
          ...approvalItem,
          approvedUntil: dateToIsoFormat(approvalItem.approvedUntil),
        }),
      })
      return data
    },
    { onSuccess, onError },
  )
}
