import { FlexBox, FlexBoxDirection, Grid, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import MoneyPercentageField from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/fields/MoneyPercentageField'
import TextField from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/fields/TextField'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/OpsMemoTrancheDetailsCard.module.css'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const OpsMemoTrancheGeneralInformationCard = ({ deal, tranche, creditDecisionApprovedDate }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.ops-memo-tranche-details.general-information',
  })
  const { t: tBoolean } = useTranslation('decisionPaper', {
    keyPrefix: 'formatters.boolean',
  })
  const { format: formatDate } = useShortDateFormatter()

  const customFields = tranche?.customFields?.customFields || {}

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.card}>
      <Text className={styles.cardHeaderTitle}>{t('general-information')}</Text>

      <Grid defaultSpan="XL6 L6 M12 S12">
        <FlexBox direction={FlexBoxDirection.Column}>
          <TextField label={t('cwp-deal-id')} value={deal?.dealId} isFirstItem={true} />
          <TextField label={t('cwp-loan-name')} value={tranche?.trancheName} />
          <TextField label={t('cwp-loan-id')} value={tranche?.displayId} />
          <TextField label={t('financial-role')} value={tranche?.financialRole} />
          <TextField label={t('loan-currency')} value={tranche?.totalCommitment?.currency} />
          <MoneyPercentageField
            label={t('commitment-to-be-booked')}
            amount={tranche?.commitmentToBeBooked?.amount}
            currency={tranche?.commitmentToBeBooked?.currency}
            showMarginLabel={false}
          />
          <TextField
            label={t('approved-date')}
            value={creditDecisionApprovedDate ? formatDate(creditDecisionApprovedDate) : '-'}
          />

          <TextField
            label={t('actual-signing-date')}
            value={deal?.actualSigningDate ? formatDate(deal?.actualSigningDate) : '-'}
          />
          <TextField
            label={t('maturity-date')}
            value={tranche?.maturityDate ? formatDate(tranche?.maturityDate) : '-'}
          />
          <TextField
            label={t('availability-date')}
            value={tranche?.latestDrawingDate ? formatDate(tranche?.latestDrawingDate) : '-'}
          />
          <TextField label={t('product-type')} value={tranche?.productTypeShortText} />
          <TextField label={t('loan-type')} value={tranche?.loanTypeShortText} />
        </FlexBox>

        <FlexBox direction={FlexBoxDirection.Column}>
          <TextField
            label={t('revolving-type')}
            value={tranche?.revolvingTypeShortText}
            isFirstItem={true}
          />
          <TextField
            label={t('promissory-loan')}
            value={customFields?.PromissoryLoan?.value ? tBoolean('yes') : tBoolean('no')}
          />
          <TextField
            label={t('securitization')}
            value={customFields?.Securitization?.value ? tBoolean('yes') : tBoolean('no')}
          />
          <TextField label={t('credit-risk-type')} value={tranche?.creditRiskTypeShortText} />
          <TextField
            label={t('specialised-lending')}
            value={customFields?.SpecializedLending?.value ? tBoolean('yes') : tBoolean('no')}
          />
          <TextField
            label={t('hvcre')}
            value={customFields?.HVCRE?.value ? tBoolean('yes') : tBoolean('no')}
          />
          <TextField
            label={t('estimated-date-of-relief')}
            value={
              customFields?.EstimatedDateOfRelief?.value
                ? formatDate(customFields.EstimatedDateOfRelief.value)
                : '-'
            }
          />
          <MoneyPercentageField
            label={t('outstanding-equity-customer')}
            amount={customFields?.OutstandingEquityCustomer?.value}
            currency={customFields?.OutstandingEquityCustomer?.currencyCode}
            showMarginLabel={false}
          />
          <MoneyPercentageField
            label={t('limit-for-equity-replacement')}
            amount={customFields?.LimitForEquityReplacement?.value}
            currency={customFields?.LimitForEquityReplacement?.currencyCode}
            showMarginLabel={false}
          />
          <TextField
            label={t('green-loan-documentation')}
            value={customFields?.GreenLoanDocumentation?.value ? tBoolean('yes') : tBoolean('no')}
          />
          <TextField
            label={t('green-loan-flag')}
            value={customFields?.GreenLoanFlag?.value ? tBoolean('yes') : tBoolean('no')}
          />
          <MoneyPercentageField
            label={t('coverpool-eligible-amount')}
            amount={tranche?.coverpoolEligibleAmount?.amount}
            percentage={tranche?.coverpoolEligibleAmount?.currency}
            showMarginLabel={false}
          />
        </FlexBox>
      </Grid>
    </FlexBox>
  )
}

OpsMemoTrancheGeneralInformationCard.propTypes = {
  deal: PropTypes.shape({
    dealId: PropTypes.string,
    dealUuid: PropTypes.string,
    actualSigningDate: PropTypes.string,
  }),
  tranche: PropTypes.shape({
    trancheName: PropTypes.string,
    displayId: PropTypes.string,
    externalContractId: PropTypes.arrayOf(PropTypes.string),
    totalCommitment: PropTypes.shape({
      currency: PropTypes.string,
      amount: PropTypes.number,
    }),
    totalCommitmentHeadquarter: PropTypes.shape({
      currency: PropTypes.string,
      amount: PropTypes.number,
    }),
    commitmentToBeBooked: PropTypes.shape({
      currency: PropTypes.string,
      amount: PropTypes.number,
    }),
    financialRole: PropTypes.string,
    maturityDate: PropTypes.string,
    productTypeShortText: PropTypes.string,
    loanTypeShortText: PropTypes.string,
    revolvingTypeShortText: PropTypes.string,
    creditRiskTypeShortText: PropTypes.string,
    coverpoolEligibleAmount: PropTypes.shape({
      currency: PropTypes.string,
      amount: PropTypes.number,
    }),
    latestDrawingDate: PropTypes.string,
    customFields: PropTypes.shape({
      customFields: PropTypes.shape({
        PromissoryLoan: PropTypes.shape({
          value: PropTypes.bool,
        }),
        Securitization: PropTypes.shape({
          value: PropTypes.bool,
        }),
        SpecializedLending: PropTypes.shape({
          value: PropTypes.bool,
        }),
        HVCRE: PropTypes.shape({
          value: PropTypes.bool,
        }),
        EstimatedDateOfRelief: PropTypes.shape({
          value: PropTypes.string,
        }),
        OutstandingEquityCustomer: PropTypes.shape({
          value: PropTypes.number,
          currencyCode: PropTypes.string,
        }),
        LimitForEquityReplacement: PropTypes.shape({
          value: PropTypes.number,
          currencyCode: PropTypes.string,
        }),
        GreenLoanDocumentation: PropTypes.shape({
          value: PropTypes.bool,
        }),
        GreenLoanFlag: PropTypes.shape({
          value: PropTypes.bool,
        }),
      }),
    }),
  }),
  creditDecisionApprovedDate: PropTypes.string,
}

export default OpsMemoTrancheGeneralInformationCard
