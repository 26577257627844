import { useMemo } from 'react'
import useTranches from 'hooks/services/deals/covenants/useTranches'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'

const pbb = 'PBB'

const calculatePbbValueForTranches = (tranches) => {
  if (!tranches || (!tranches.summary && !tranches.summaryHeadquarter)) {
    return
  }
  const summaryResult = {
    currency: tranches.summary?.currency,
    value: tranches.summary?.ownCommitment,
  }
  const summaryHeadquarterResult = {
    currency: tranches.summaryHeadquarter?.currency,
    value: tranches.summaryHeadquarter?.ownCommitment,
  }
  return {
    name: pbb,
    original: tranches.summary ? summaryResult : summaryHeadquarterResult,
    headquarter: tranches.summaryHeadquarter ? summaryHeadquarterResult : summaryResult,
  }
}

const usePbbCalculation = ({ dealUuid }) => {
  const {
    data: tranches,
    isFetching: isTranchesFetching,
    isError: isTranchesError,
    error: tranchesError,
  } = useTranches({ dealUuid, dataSource: DATA_SOURCES.NEW_BUSINESS })

  const mappedTranchesValues = useMemo(() => calculatePbbValueForTranches(tranches), [tranches])

  return useMemo(
    () => ({
      mappedTranchesValues,
      isTranchesFetching,
      isTranchesError,
      tranchesError,
    }),
    [mappedTranchesValues, isTranchesFetching, isTranchesError, tranchesError],
  )
}

export default usePbbCalculation
