import { Button, ButtonDesign, Menu, MenuItem } from '@fioneer/ui5-webcomponents-react/dist'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { requirementStatusTypes } from 'api/conditions/conditions'
import BusinessObjectSelectionHelperDialog from 'components/domains/conditions/business-object/BusinessObjectSelectionHelperDialog'
import DocumentSelectDialog from 'components/domains/documents/DocumentSelectDialog'
import DocumentTypes, { allowedTypes } from 'components/domains/documents/DocumentTypes'
import DocumentUploadDialog from 'components/domains/documents/DocumentUploadDialog'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useEntityNameResolver from 'hooks/entity-resolver/useEntityNameResolver'
import {
  patchDocumentReferencesOperations,
  usePatchDocumentReferencesForDocument,
} from 'hooks/services/documents/usePatchDocumentReferencesForDocument'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const addDocumentButtonId = 'add-document-button'
const selectDocumentMenuItemId = 'select-document-menu-item-id'
const uploadDocumentMenuItemId = 'upload-document-menu-item-id'

const initialFilterEntityState = {
  entityDisplayId: '',
  entityId: '',
  entityName: '',
  entityType: '',
}

const atLeastOneRequirementInStatusDone = (requirements) =>
  Object.values(requirements).some(({ status }) => status === requirementStatusTypes.done)

const RequirementAddDocumentButton = ({ disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.toolbar.buttons',
  })
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isUploadDocumentDialogOpen, setIsUploadDocumentDialogOpen] = useState(false)
  const [isKeepUploadDocumentDialogMounted, setIsKeepUploadDocumentDialogMounted] = useState(false)
  const [isSelectDocumentDialogOpen, setIsSelectDocumentDialogOpen] = useState(false)
  const [isHelperDialogOpen, setIsHelperDialogOpen] = useState(false)
  const [filterEntity, setFilterEntity] = useState(initialFilterEntityState)
  const [selectedDocumentId, setSelectedDocumentId] = useState()
  const queryClient = useQueryClient()

  const showDocumentUploadDialog = useMemo(
    () => isSelectDocumentDialogOpen || isKeepUploadDocumentDialogMounted,
    [isKeepUploadDocumentDialogMounted, isSelectDocumentDialogOpen],
  )

  const {
    entityRef: { entityId, entityType },
  } = useContext(ConditionsContext)
  const showMessageBox = useShowMessageBox()
  const selectedRequirementIds = useSelector(
    (state) => state.conditions.requirementsTable.selectedRows.selectedRequirementIds,
  )

  const isUploadDocumentButtonDisabled = useMemo(
    () =>
      isEmpty(selectedRequirementIds) ||
      atLeastOneRequirementInStatusDone(selectedRequirementIds) ||
      disabled,
    [disabled, selectedRequirementIds],
  )

  const mapReferencesToEntities = useCallback(
    (references) =>
      references?.entityIds?.map((id) => ({
        id,
        type: references?.entityType,
      })) ?? [
        {
          id: entityId,
          type: entityType,
        },
      ],
    [entityId, entityType],
  )

  const selectedEntities = useMemo(
    () =>
      Object.entries(selectedRequirementIds).flatMap(([selectedRequirementId, { references }]) => [
        ...mapReferencesToEntities(references),
        { id: selectedRequirementId, type: DocumentTypes.Requirement },
      ]),
    [mapReferencesToEntities, selectedRequirementIds],
  )

  const onAddDocumentButtonClick = useCallback(() => {
    setIsMenuOpen(true)
  }, [])

  const onAddDocumentButtonClose = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  const onUploadDocumentClose = useCallback(() => {
    setIsUploadDocumentDialogOpen(false)
  }, [])

  const onSelectDocumentClose = useCallback(() => {
    setIsSelectDocumentDialogOpen(false)
  }, [])

  const handleAddSuccess = useCallback(() => {
    queryClient.invalidateQueries(['documents', DocumentTypes.Requirement])
  }, [queryClient])

  const handleAddError = useCallback(() => {
    showMessageBox({ type: MessageBoxTypes.Error, children: t('error.description') })
  }, [showMessageBox, t])

  const addRequirementsMutation = usePatchDocumentReferencesForDocument({
    documentId: selectedDocumentId,
    onSuccess: handleAddSuccess,
    onError: handleAddError,
  })

  const handleDocumentSelectionChange = useCallback((documentId) => {
    setSelectedDocumentId(documentId)
  }, [])

  useEffect(() => {
    if (!isNil(selectedDocumentId)) {
      const entityRefs = Object.keys(selectedRequirementIds).map((requirementId) => ({
        type: DocumentTypes.Requirement,
        id: requirementId,
      }))
      addRequirementsMutation.mutate({
        entityRefs,
        operation: patchDocumentReferencesOperations.add,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocumentId])

  const documentTypeIfAllEqual = useMemo(() => {
    const documentTypesArray = Object.values(selectedRequirementIds).map(
      ({ documentType }) => documentType,
    )
    return documentTypesArray.every((documentType) => documentType === documentTypesArray[0])
      ? documentTypesArray[0]
      : undefined
  }, [selectedRequirementIds])

  const documentEntityReferenceType = useMemo(() => {
    const filteredEntities = selectedEntities.filter(
      (entity) => entity.type !== DocumentTypes.Requirement,
    )

    switch (entityType) {
      case DocumentTypes.BusinessPartner:
        if (filteredEntities.every((entity) => entity.type === DocumentTypes.BusinessPartner)) {
          return selectedEntities.some((entity) => entity.id === entityId)
            ? [...selectedEntities]
            : [{ id: entityId, type: entityType }, ...selectedEntities]
        }
        return [
          { type: entityType, id: entityId },
          ...selectedEntities.filter(
            (entity) =>
              entity.type === DocumentTypes.Requirement ||
              entity.type === DocumentTypes.BusinessPartner,
          ),
        ]

      case DocumentTypes.Deal:
        if (filteredEntities.every((entity) => entity.type === DocumentTypes.Deal)) {
          return selectedEntities
        }

        return selectedEntities.filter((entity) => allowedTypes.includes(entity.type))

      default:
        return selectedEntities
    }
  }, [entityId, entityType, selectedEntities])

  const businessObjectRefIfAllEqual = useMemo(() => {
    const businessObjectRefArray = Object.values(selectedRequirementIds).map(
      ({ references }) => references,
    )
    return businessObjectRefArray.every(
      (ref) =>
        ref?.entityIds?.length > 0 &&
        ref.entityIds[0] === businessObjectRefArray[0]?.entityIds?.[0],
    )
      ? {
          entityId: businessObjectRefArray[0]?.entityIds[0],
          entityType: businessObjectRefArray[0]?.entityType,
        }
      : undefined
  }, [selectedRequirementIds])

  const getCorrectEntityForDocumentSelect = useMemo(() => {
    // in both cases returns object with entityId and entityType
    const businessObjectRef = businessObjectRefIfAllEqual
    if (isNil(businessObjectRef)) {
      return Object.values(selectedRequirementIds)[0]?.conditionEntityRef
    }
    return businessObjectRef
  }, [selectedRequirementIds, businessObjectRefIfAllEqual])

  const entityToFilterBy = getCorrectEntityForDocumentSelect
  const entity = useEntityNameResolver({
    entityId: entityToFilterBy?.entityId,
    entityType: entityToFilterBy?.entityType,
  })
  const helperDialogFilter = {
    searchFilter: entity?.entityName ?? '',
    entityType: entityToFilterBy?.entityType,
  }

  const onUploadDocumentMenuItemClick = useCallback(() => {
    if (!documentTypeIfAllEqual) {
      showMessageBox({
        type: MessageBoxTypes.Error,
        titleText: t('add-document.upload-document.error.title'),
        children: t('add-document.upload-document.error.description'),
      })
    } else {
      setIsUploadDocumentDialogOpen(true)
      setIsKeepUploadDocumentDialogMounted(true)
    }
  }, [documentTypeIfAllEqual, showMessageBox, t])

  const onSelectDocumentMenuItemClick = useCallback(() => {
    if (!documentTypeIfAllEqual) {
      showMessageBox({
        type: MessageBoxTypes.Error,
        titleText: t('add-document.upload-document.error.title'),
        children: t('add-document.upload-document.error.description'),
      })
    } else {
      setIsHelperDialogOpen(true)
    }
  }, [documentTypeIfAllEqual, showMessageBox, t])

  const initialState = useMemo(
    () => ({
      selectedEntities: documentEntityReferenceType,
      documentType: documentTypeIfAllEqual,
    }),
    [documentEntityReferenceType, documentTypeIfAllEqual],
  )

  const onMenuItemClick = useCallback(
    ({ detail: { item: pressedMenuItem } }) => {
      setIsMenuOpen(false)
      const pressedIdentifier = pressedMenuItem.getAttribute('menu-item-id')
      switch (pressedIdentifier) {
        case uploadDocumentMenuItemId:
          onUploadDocumentMenuItemClick()
          break
        case selectDocumentMenuItemId:
          onSelectDocumentMenuItemClick()
      }
    },
    [onUploadDocumentMenuItemClick, onSelectDocumentMenuItemClick],
  )

  const onCancelBusinessObjectSelection = useCallback(() => {
    setIsHelperDialogOpen(false)
  }, [])

  const onSelectionChanged = useCallback(
    ({ entityId: newEntityId, entityType: newEntityType, entityName, entityDisplayId }) => {
      setFilterEntity({
        entityDisplayId: entityDisplayId,
        entityId: newEntityId,
        entityName: entityName,
        entityType: newEntityType,
      })
      setIsHelperDialogOpen(false)
      setIsSelectDocumentDialogOpen(true)
    },
    [],
  )

  const onUploadDocumentFinished = useCallback(
    () => setIsKeepUploadDocumentDialogMounted(false),
    [],
  )

  return (
    <>
      <Button
        disabled={isUploadDocumentButtonDisabled}
        id={addDocumentButtonId}
        design={ButtonDesign.Transparent}
        onClick={onAddDocumentButtonClick}
      >
        {t('add-document')}
      </Button>
      {isMenuOpen && (
        <Menu
          opener={addDocumentButtonId}
          open={isMenuOpen}
          onAfterClose={onAddDocumentButtonClose}
          onItemClick={onMenuItemClick}
        >
          <MenuItem
            text={t('add-document.select-document')}
            menu-item-id={selectDocumentMenuItemId}
          />
          <MenuItem
            text={t('add-document.upload-document')}
            menu-item-id={uploadDocumentMenuItemId}
          />
        </Menu>
      )}
      {showDocumentUploadDialog && (
        <DocumentUploadDialog
          isOpen={isUploadDocumentDialogOpen}
          setIsOpen={setIsUploadDocumentDialogOpen}
          initialState={initialState}
          type={DocumentTypes.Requirement}
          onClose={onUploadDocumentClose}
          onUploadFinished={onUploadDocumentFinished}
        />
      )}
      {isHelperDialogOpen && (
        <BusinessObjectSelectionHelperDialog
          isOpen={isHelperDialogOpen}
          onCancel={onCancelBusinessObjectSelection}
          onSelect={onSelectionChanged}
          addDealSearch={true}
          initialSearchFilter={helperDialogFilter}
        />
      )}
      {isSelectDocumentDialogOpen && (
        <DocumentSelectDialog
          open={isSelectDocumentDialogOpen}
          onClose={onSelectDocumentClose}
          onChange={handleDocumentSelectionChange}
          entity={{
            id: filterEntity.entityId,
            name: filterEntity.entityName,
            displayId: filterEntity.entityDisplayId,
            type: filterEntity.entityType,
          }}
        />
      )}
    </>
  )
}

RequirementAddDocumentButton.propTypes = {
  disabled: PropTypes.bool,
}

export default RequirementAddDocumentButton
