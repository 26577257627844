import { BusyIndicatorSize, WrappingType } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionTypes } from 'api/conditions/conditions'
import { isNotFoundError } from 'api/requests'
import { ChangelogDialogContext } from 'components/domains/conditions/dialogs/changelog/ChangelogDialogContext'
import { ENTITIES } from 'components/domains/deals/change-overview/constants'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import Link from 'components/ui/link/Link'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useGetCondition from 'hooks/services/conditions/useGetCondition'
import paths from 'routes/paths'

const hyphen = '-'

const ConditionRequirementChangelogConditionLink = ({ entityType, conditionId, action }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.dialog.changelog.table.entities',
  })
  const { relatedEntityType, relatedEntityDisplayId, hideNameForEntityId } =
    useContext(ChangelogDialogContext)

  const conditionType = useMemo(() => {
    switch (entityType) {
      case ENTITIES.EXTERNAL_CONDITION:
        return conditionTypes.external
      case ENTITIES.INTERNAL_CONDITION:
        return conditionTypes.internal
      default:
        return null
    }
  }, [entityType])

  const isEnabled =
    action !== 'DELETED' && !isNil(conditionType) && hideNameForEntityId !== conditionId
  const {
    data: condition,
    isFetching,
    isError,
    error,
  } = useGetCondition(conditionType, { conditionId }, { enabled: isEnabled })

  const isTechnicalError = isError && !isNotFoundError(error)

  const conditionTypeName = isNil(conditionType) ? hyphen : t(entityType)

  const entityPath = useMemo(() => {
    switch (relatedEntityType) {
      case ENTITIES.DEAL:
        return paths.deals
      case ENTITIES.BUSINESS_PARTNER:
        return paths.businessPartners
      default:
        return null
    }
  }, [relatedEntityType])

  const linkPrefix = useMemo(() => {
    if (isNil(entityPath)) {
      return null
    }
    switch (conditionType) {
      case conditionTypes.external:
        return `/${entityPath}/${relatedEntityDisplayId}/conditions?con_businessType=external&con_externalConditionId=`
      case conditionTypes.internal:
        return `/${entityPath}/${relatedEntityDisplayId}/conditions?con_businessType=internal&con_internalConditionId=`
      default:
        return null
    }
  }, [conditionType, entityPath, relatedEntityDisplayId])

  const renderContent = useCallback(() => {
    if (isNil(condition) || isNil(linkPrefix)) {
      return conditionTypeName
    }
    const link = `${linkPrefix}${conditionId}`
    const name = `${conditionTypeName} - ${condition?.info?.name}`
    return (
      <Link href={link} target={'_blank'} wrappingType={WrappingType.Normal}>
        {name}
      </Link>
    )
  }, [condition, conditionId, conditionTypeName, linkPrefix])

  if (!isEnabled) {
    return conditionTypeName
  }

  return (
    <RequestStateResolver
      isLoading={isFetching && isEnabled}
      isError={isTechnicalError}
      busyIndicatorSize={BusyIndicatorSize.Small}
      errorToDisplay={
        <>
          {conditionTypeName} <ErrorDataUnavailableInCell />
        </>
      }
      renderContent={renderContent}
    />
  )
}

ConditionRequirementChangelogConditionLink.propTypes = {
  entityType: PropTypes.string.isRequired,
  conditionId: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
}

export default ConditionRequirementChangelogConditionLink
