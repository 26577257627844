import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useEditDecisionStageMinutes = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(
    ({ decisionStageId, eventId, minutes, decisionDate, decisionStatus }) =>
      patch({
        path: `/events/${eventId}/decision-stages/${decisionStageId}/minutes`,
        body: snakecaseKeys({
          info: {
            minutes,
            decision_date: decisionDate,
            decision_status: decisionStatus,
          },
        }),
      }),
    mutationOptions,
  )
}

export default useEditDecisionStageMinutes
