import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { arrearsInApprovalFilters } from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/shared/toolbar/arrearsInApprovalPropTypes'

const EnumSelectInput = ({
  initialValue,
  enumValues,
  selectedFilters,
  setSelectedFilters,
  selectRefs,
  columnKey,
}) => {
  const keyNames = Object.keys(enumValues)
  const options = keyNames.map((keyName) => (
    <Option key={enumValues[keyName]} data-id={keyName} selected={initialValue === keyName}>
      {enumValues[keyName]}
    </Option>
  ))
  options.unshift(
    <Option key={''} data-id={''} selected={!initialValue}>
      {''}
    </Option>,
  )
  return (
    <Select
      id="enum-select"
      ref={(ref) => (selectRefs.current[columnKey] = ref)}
      onChange={(e) => {
        setSelectedFilters(
          selectedFilters.map((filter) =>
            filter.columnKey === columnKey
              ? { ...filter, value: e.detail.selectedOption.dataset.id }
              : filter,
          ),
        )
      }}
    >
      {options}
    </Select>
  )
}

EnumSelectInput.propTypes = {
  initialValue: PropTypes.string,
  enumValues: PropTypes.object.isRequired,
  selectedFilters: arrearsInApprovalFilters.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  selectRefs: PropTypes.object.isRequired,
  columnKey: PropTypes.string.isRequired,
}

export default EnumSelectInput
