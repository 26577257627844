import { Tab, TabContainer, TabLayout, TabsOverflowMode } from '@fioneer/ui5-webcomponents-react'
import { addCustomCSSWithScoping } from '@fioneer/ui5-webcomponents-react/dist/internal/addCustomCSSWithScoping'
import isNil from 'lodash.isnil'
import { Suspense, lazy, useCallback, useContext, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useSearchParams } from 'react-router-dom'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import LazyLoadingFallbackCard from 'components/ui/card/LazyLoadingFallbackCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Page from 'components/ui/page/Page'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import {
  GCC_UNIT_ID,
  REVIEW_UNIT_ID,
  STANDALONE_BP_UNIT_ID,
} from 'hooks/services/business-partners/unit-overview/constants'
import useBusinessPartnerUnitOverviewUnits from 'hooks/services/business-partners/unit-overview/useBusinessPartnerUnitOverviewUnits'
import lazyRetry from 'lazyRetry'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import styles from 'routes/business-partners/BusinessPartnerUnitOverviewPage.module.css'
import paths from 'routes/business-partners/paths'

const UnitOverviewTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "UnitOverviewTile" */ 'components/domains/business-partners/tile/unit-overview/UnitOverviewTile'
      ),
    'UnitOverviewTile',
  ),
)

const borrowerRole = { id: 'Borrower' }

const BusinessPartnerUnitOverviewPage = () => {
  addCustomCSSWithScoping(
    'ui5-tabcontainer',
    `
    :host .ui5-tc__header {
      padding: 0;
      background-color: unset;
      box-shadow: unset;
      margin-left: -16px;
    }
    `,
  )

  const businessPartner = useContext(BusinessPartnerContext)
  const { t } = useTranslation()

  const { data, isLoading, isError } = useBusinessPartnerUnitOverviewUnits(businessPartner?.id)
  const [selectedTab, setSelectedTab] = useState()
  const [selectedUnit, setSelectedUnit] = useState()
  const [searchParams, setSearchParams] = useSearchParams()

  const businessPartnerFound = () => Boolean(businessPartner?.id)
  const hasRoleBorrower = businessPartner.roles?.some((role) => role.id === borrowerRole.id)

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={businessPartner.id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [businessPartner.id],
  )

  const getSelectedUnit = useCallback(
    (id, headId) => {
      if (!id || !headId) {
        searchParams.delete('id')
        searchParams.delete('headId')
        setSearchParams(searchParams)
        return data.gcc[0]
      }

      const gccUnit = data.gcc.find((unit) => unit.id === id && unit.headId === headId)

      const reviewUnit = data.review.find((unit) => unit.id === id && unit.headId === headId)

      const standaloneUnit = data.standalone.find(
        (unit) => unit.id === id && unit.headId === headId,
      )

      return gccUnit ?? reviewUnit ?? standaloneUnit
    },
    [data, searchParams, setSearchParams],
  )

  useEffect(() => {
    const id = searchParams.get('id')
    const headId = searchParams.get('headId')

    const setUnitAndTab = (unitId, unitData) => {
      setSelectedTab(unitId)
      setSelectedUnit(unitData)
    }

    if (id && headId && !selectedUnit) {
      const preselectedUnit = getSelectedUnit(id, headId)
      if (!isNil(preselectedUnit)) {
        setUnitAndTab(id, preselectedUnit)
      }
    } else if ((!id || !headId) && !selectedUnit) {
      if (data?.gcc.length > 0) {
        setUnitAndTab(GCC_UNIT_ID, data.gcc[0])
      } else if (data?.review.length > 0) {
        setUnitAndTab(REVIEW_UNIT_ID, data.review[0])
      } else if (data?.standalone.length > 0) {
        setUnitAndTab(STANDALONE_BP_UNIT_ID, data.standalone[0])
      }
    }
  }, [getSelectedUnit, searchParams, selectedUnit, data])

  useEffect(() => {
    const id = searchParams.get('id')
    const headId = searchParams.get('headId')

    if (id || headId) {
      setSearchParams({ id: id, headId: headId })
    }
  }, [searchParams, setSearchParams])

  const renderTile = () => (
    <CWPLayout overview>
      {hasRoleBorrower && (
        <>
          <Suspense fallback={<LazyLoadingFallbackCard />}>
            <CWPLayout.Full>
              <UnitOverviewTile selectedUnit={selectedUnit} />
            </CWPLayout.Full>
          </Suspense>
        </>
      )}
    </CWPLayout>
  )

  const renderContent = () =>
    businessPartnerFound() ? (
      <>
        <TabContainer
          tabsOverflowMode={TabsOverflowMode.Scrollable}
          tabLayout={TabLayout.Inline}
          collapsed={true}
          fixed={true}
          className={styles.tabContainer}
          onTabSelect={(e) => {
            const id = e?.detail?.tab?.getAttribute('id')
            const headId = e?.detail?.tab?.getAttribute('headId')
            const unit = getSelectedUnit(id, headId)
            setSelectedUnit(unit)
            setSearchParams({ id: id, headId: headId })
          }}
        >
          {data.gcc.length > 0 && (
            <Tab
              data-testid={GCC_UNIT_ID}
              selected={selectedTab === GCC_UNIT_ID}
              subTabs={
                <>
                  {data.gcc.map((item) => (
                    <Tab key={item.id} text={item.headFullName} id={item.id} headId={item.headId} />
                  ))}
                </>
              }
              text={t('pages.business-partner.unit-overview.gcc-unit')}
            />
          )}
          {data.review.length > 0 && (
            <Tab
              data-testid={REVIEW_UNIT_ID}
              selected={selectedTab === REVIEW_UNIT_ID}
              subTabs={
                <>
                  {data.review.map((item) => (
                    <Tab key={item.id} text={item.headFullName} id={item.id} headId={item.headId} />
                  ))}
                </>
              }
              text={t('pages.business-partner.unit-overview.review-unit')}
            />
          )}
          {data.standalone.length > 0 && (
            <Tab
              data-testid={STANDALONE_BP_UNIT_ID}
              selected={selectedTab === STANDALONE_BP_UNIT_ID}
              subTabs={
                <>
                  {data.standalone.map((item) => (
                    <Tab key={item.id} text={item.headFullName} id={item.id} headId={item.headId} />
                  ))}
                </>
              }
              text={t('pages.business-partner.unit-overview.standalone-unit')}
            />
          )}
        </TabContainer>
        {renderTile()}
      </>
    ) : (
      <>
        {t('pages.business-partner.not-found')}
        {<NavLink to={paths.businessPartners}>{t('pages.business-partner.search')}</NavLink>}
      </>
    )

  return (
    <>
      <Page
        isError={isError}
        isLoading={isLoading}
        renderHeaderTitle={() => (
          <BusinessPartnerHeader
            breadcrumbs={[{ text: t('navigation.item.title.business-partner-unit-overview') }]}
            actions={
              <>
                <CreateBusinessPartnerEventAction />
                {markFavoriteAction}
              </>
            }
          />
        )}
        renderContent={renderContent}
        id="businessPartnerUnitOverviewId"
      />
    </>
  )
}

BusinessPartnerUnitOverviewPage.propTypes = {}

export default BusinessPartnerUnitOverviewPage
