import { useQueries } from '@tanstack/react-query'
import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { useMemo } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useArrayMemo } from 'hooks/services/queryHelper'

/**
 * Retrieve multi property (portfolio) data for each property uuids array element.
 * Handle multiple API requests for retrieving portfolio data based on their uuids.
 * @param propertyUuidsArrays Array of arrays of property uuids, one hook result for each parent array element
 * @param options Additional query options to pass to the useQueries hook
 * @returns {{isLoading: boolean, isError: boolean, data: *}}
 */
export const useMultiPropertyViewByPropertyUuidsArrays = ({
  propertyUuidsArrays,
  options = {},
}) => {
  const { post } = useAccessTokenRequest()

  const queries = propertyUuidsArrays.map((propertyUuids) => ({
    queryKey: ['multiPropertyView', 'propertyUuids', ...propertyUuids],
    refetchOnWindowFocus: false,
    queryFn: () =>
      post({
        path: `/properties/multi-property-view`,
        body: { property_uuids: propertyUuids },
      }),
    retry: false,
    ...options,
    enabled: !isNil(options.enabled)
      ? options.enabled && !isEmpty(propertyUuids)
      : !isEmpty(propertyUuids),
  }))

  const responses = useQueries({ queries })
  const isAnyRequestLoading = useMemo(
    () =>
      responses.some(
        ({ isLoading: isPropertyLoading }, index) => isPropertyLoading && queries?.[index]?.enabled,
      ),
    [queries, responses],
  )
  const isAnyRequestError = responses.some(({ isError: isPropertyError }) => isPropertyError)

  const propertiesArrays = useArrayMemo(
    responses.map(({ data }) => ({
      ...camelize(data),
    })),
  )

  return useMemo(
    () => ({ data: propertiesArrays, isLoading: isAnyRequestLoading, isError: isAnyRequestError }),
    [isAnyRequestError, isAnyRequestLoading, propertiesArrays],
  )
}
