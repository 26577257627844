import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import useDealCovenantMonitoringItems from 'hooks/services/deals/covenants/monitorings/useDealCovenantMonitoringItems'

const FINANCIAL = 'FINANCIAL'

const useCheckResultsChartData = ({ dealUuid, selectedCovenantUuid }) => {
  const { t: tNoPrefix } = useTranslation('translation')
  const {
    data: { covenantMonitoringItems = [] } = {},
    isLoading: isLoadingCovenantMonitoringItems,
    isError: isErrorCovenantMonitoringItems,
  } = useDealCovenantMonitoringItems(mainEntityTypes.DEAL, dealUuid)

  return useMemo(() => {
    const financialItems = covenantMonitoringItems.filter(
      ({ covenant }) => covenant?.type === FINANCIAL,
    )

    const filteredItemsByCovenantUuid = financialItems
      .filter(({ covenant }) => covenant?.covenantUuid === selectedCovenantUuid)
      .sort(({ dueDate: a }, { dueDate: b }) => new Date(a?.keyDate) - new Date(b?.keyDate))

    const monitoringItemWithName = filteredItemsByCovenantUuid.find(
      ({ covenant }) => !!covenant.name,
    )
    const unit =
      monitoringItemWithName?.softLimit?.unit ??
      monitoringItemWithName?.hardLimit?.unit ??
      monitoringItemWithName?.result?.unit

    const getDisplayUnit = (limit) =>
      limit?.unit === 'PERCENT' ? tNoPrefix('unit.percent') : limit?.currencyCode

    const displayUnit =
      getDisplayUnit(monitoringItemWithName?.softLimit) ??
      getDisplayUnit(monitoringItemWithName?.hardLimit)

    const chartData = filteredItemsByCovenantUuid.map(
      ({ covenantMonitoringItemUuid, hardLimit, softLimit, result, dueDate, status }) => ({
        covenantMonitoringItemUuid,
        keyDate: new Date(dueDate?.keyDate).valueOf(),
        hardLimit: hardLimit?.value,
        softLimit: softLimit?.value,
        result: result?.value,
        unit: unit,
        currencyCode: result?.currencyCode,
        status: status,
      }),
    )

    const lastItemWithResult = filteredItemsByCovenantUuid
      .filter(({ result }) => !!result?.value)
      .at(-1)

    return {
      isLoading: isLoadingCovenantMonitoringItems,
      isError: isErrorCovenantMonitoringItems,
      financialMonitoringItems: financialItems,
      lastMonitoringItemWithResult: lastItemWithResult,
      name: monitoringItemWithName?.covenant?.name,
      displayUnit: displayUnit,
      chartData,
    }
  }, [
    covenantMonitoringItems,
    isErrorCovenantMonitoringItems,
    isLoadingCovenantMonitoringItems,
    selectedCovenantUuid,
    tNoPrefix,
  ])
}

export default useCheckResultsChartData
