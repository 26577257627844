import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useGetMilestoneCategories = () =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/configurations/milestone-categories`,
      translated: true,
    }),
  )

export const useMilestoneTypes = (selectedCategory) => {
  const { isLoading, isError, data: { options = [] } = {} } = useGetMilestoneCategories()

  if (isLoading || isError) return { isLoading, isError, options }

  const milestoneTypes = selectedCategory
    ? options?.find(({ categoryCode }) => categoryCode === selectedCategory)?.types ?? []
    : options
        ?.map((option) => option.types)
        .flat(1)
        .filter((item, index, self) => self.findIndex(({ code }) => code === item.code) === index)
  return { isLoading, isError, data: milestoneTypes }
}
