import { MessageStrip, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BusinessPartnerArrearsContext } from 'routes/business-partners/arrears/BusinessPartnerArrearsContext'

const ArrearsBpPdLevelBanner = () => {
  const { businessPartnerPdLevel } = useContext(BusinessPartnerArrearsContext)
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.bp-level-banner',
  })

  const isBelowLimit = useMemo(
    () => businessPartnerPdLevel?.isBelowLimit ?? true,
    [businessPartnerPdLevel?.isBelowLimit],
  )

  if (!isBelowLimit) {
    return (
      <MessageStrip design={MessageStripDesign.Warning} hideCloseButton>
        {t('message', {
          level: businessPartnerPdLevel?.level,
          limit: businessPartnerPdLevel?.limit,
        })}
      </MessageStrip>
    )
  }
}

export default ArrearsBpPdLevelBanner
