import { includes } from 'lodash'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DecisionPaperTileListItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListItem'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/MultipleFactSheetTegovaRatingTile.module.css'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/tegovaRating/shared/EmptyCardContent'
import { useNumberFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/tegovaRating/shared/i18n/useI18n'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const NO_DATA_PLACEHOLDER = '-'

const MultipleFactSheetTegovaRatingTile = ({ tileId, selectedDealIndex }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.fact-sheet.tegova-rating',
  })

  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  })

  const {
    data: {
      multipleWeightedTegovaRating = [],
      multipleEmptyTegovaRating = [],
      noProperties,
      sourcePaths,
    } = {},
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { weightedTegovaRating, emptyTegovaRating } = useMemo(
    () => ({
      emptyTegovaRating: multipleEmptyTegovaRating?.[selectedDealIndex],
      weightedTegovaRating: multipleWeightedTegovaRating?.[selectedDealIndex],
    }),
    [multipleEmptyTegovaRating, multipleWeightedTegovaRating, selectedDealIndex],
  )

  useEffect(() => {
    dispatch(
      setSourceRender({ tileId, sourceRender: { path: `${sourcePaths?.[selectedDealIndex]}` } }),
    )
  }, [dispatch, selectedDealIndex, sourcePaths, tileId])

  if (noProperties) {
    return <NoPropertyAssignedToDealInfo />
  }
  if (isNil(weightedTegovaRating) || emptyTegovaRating) {
    return <EmptyCardContent />
  }

  const ratingNoSeparator = ['cashflow', 'relett', 'total']
  const checkIsSeparator = (ratingLabel) => !includes(ratingNoSeparator, ratingLabel)

  return (
    weightedTegovaRating && (
      <>
        {Object.entries(weightedTegovaRating).map(([ratingLabel, rating], index) => (
          <div
            key={`rating-list-${ratingLabel}`}
            className={checkIsSeparator(ratingLabel) ? styles.itemWithSeparator : ''}
          >
            <DecisionPaperTileListItem
              key={`rating-row-${ratingLabel}-${rating}`}
              label={t(`${ratingLabel}`)}
              value={
                rating
                  ? `${formatNumber(rating)} - ${t(`${Math.round(rating)}`)}`
                  : NO_DATA_PLACEHOLDER
              }
              isFirstItem={index === 0}
              textValueIsBold={ratingLabel === 'total'}
            />
            {checkIsSeparator(ratingLabel) && (
              <DecisionPaperTileListSeparator key={`rating-separator-${ratingLabel}-${rating}`} />
            )}
          </div>
        ))}
      </>
    )
  )
}

MultipleFactSheetTegovaRatingTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultipleFactSheetTegovaRatingTile
