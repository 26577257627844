import { Table, TableCell, TableColumn, TableRow } from '@fioneer/ui5-webcomponents-react'
import sortBy from 'lodash.sortby'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PropertyLink from 'components/domains/business-events-and-tasks/PropertyLink'

/**
 * A table component that displays the valuation which were not created for the given properties
 * This table is rendered in an warning dialog when at least one valuation create or update failed.
 * @param {Array<SkippedValuation>} skippedValuations
 * @returns {JSX.Element} A JSX element representing the MultiPropertyUploadRequestErrorTable component.
 */
const MultiPropertyUploadValuationCreateWarningTable = ({ skippedValuations }) => {
  const MARKET_VALUE = {
    key: 'classification.market-value',
    value: 'MARKET_VALUE',
  }
  const MORTGAGE_LENDING_VALUE = {
    key: 'classification.mortgage-lending-value',
    value: 'MORTGAGE_LENDING_VALUE',
  }
  const PURCHASE_PRICE = {
    key: 'classification.purchase-price',
    value: 'PURCHASE_PRICE',
  }

  const { t: tMultiPropertyUpload } = useTranslation('translation', {
    keyPrefix: 'pages.property-upload',
  })

  const getClassification = (classification) => {
    switch (classification) {
      case MARKET_VALUE.value:
        return tMultiPropertyUpload(MARKET_VALUE.key)
      case MORTGAGE_LENDING_VALUE.value:
        return tMultiPropertyUpload(MORTGAGE_LENDING_VALUE.key)
      case PURCHASE_PRICE.value:
        return tMultiPropertyUpload(PURCHASE_PRICE.key)
      default:
        return ''
    }
  }

  return (
    <Table
      columns={
        <>
          <TableColumn>{tMultiPropertyUpload('column.property')}</TableColumn>
          <TableColumn>{tMultiPropertyUpload('column.valuation-classification')}</TableColumn>
          <TableColumn>{tMultiPropertyUpload('column.valuation-calculation-method')}</TableColumn>
        </>
      }
    >
      {sortBy(skippedValuations, 'propertyUuid').map((skippedValuation, index) => (
        <TableRow key={index}>
          <TableCell key={`${index}-property`}>
            <PropertyLink propertyUuid={skippedValuation.propertyUuid} />
          </TableCell>
          <TableCell key={`${index}-valuation-classification`}>
            {getClassification(skippedValuation.classification)}
          </TableCell>
          <TableCell key={`${index}-valuation-calculation-method`}>
            {`${skippedValuation.calculationMethodCodeName} (${skippedValuation.calculationMethodCode})`}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
MultiPropertyUploadValuationCreateWarningTable.propTypes = {
  skippedValuations: PropTypes.array.isRequired,
}
export default MultiPropertyUploadValuationCreateWarningTable
