import { Link, WrappingType } from '@fioneer/ui5-webcomponents-react'
import { first } from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/current-rent/components/PropertyRentRollOverviewPrimaryTenantUnit.module.css'
import PropertyRentRollOverviewCurrentRentText from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared/PropertyRentRollOverviewCurrentRentText'
import LoadingContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/AssetValuationOverviewLoadingContent'

const PropertyRentRollOverviewPrimaryTenantUnit = ({
  primaryTenantId,
  multipleBusinessPartnerRelationshipsDataByPrimaryTenantId,
}) => {
  const { t: tCurrentRent } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.property.rent-roll.overview.current-rent',
  })

  const businessPartnerRelationshipsData =
    multipleBusinessPartnerRelationshipsDataByPrimaryTenantId[primaryTenantId]

  const getPrimaryTenantUnit = () => {
    const relationship = first(
      first(businessPartnerRelationshipsData?.unitRelationships)?.relationships,
    )
    return relationship?.head
  }

  const primaryTenantUnit = getPrimaryTenantUnit()

  const renderPrimaryTenantUnitLink = () => {
    if (primaryTenantUnit) {
      return (
        <Link
          className={styles.primaryTenantUnitLink}
          wrappingType={WrappingType.Normal}
          href={`/business-partners/${primaryTenantUnit?.id}`}
          target="_parent"
        >
          {primaryTenantUnit?.fullName}
        </Link>
      )
    } else {
      return <PropertyRentRollOverviewCurrentRentText text={tCurrentRent('not-allocated')} />
    }
  }

  if (primaryTenantId) {
    return (
      <>
        <LoadingContent
          contentKey="primary-tenant-unit"
          errorContent={<PropertyRentRollOverviewCurrentRentText text={tCurrentRent('error')} />}
        >
          {renderPrimaryTenantUnitLink()}
        </LoadingContent>
      </>
    )
  } else {
    return <></>
  }
}
PropertyRentRollOverviewPrimaryTenantUnit.propTypes = {
  primaryTenantId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  multipleBusinessPartnerRelationshipsDataByPrimaryTenantId: PropTypes.any,
}
export default PropertyRentRollOverviewPrimaryTenantUnit
