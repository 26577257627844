import isNil from 'lodash.isnil'
import { conditionsMassEditOperations } from 'api/conditions/conditions'

export const calculateIsDocumentTypeSelectDisabled = (
  selectedBusinessObjectRefs,
  conditionChange,
) => {
  const requirementsHaveDifferingTypes =
    new Set(selectedBusinessObjectRefs.map((businessObjectRef) => businessObjectRef?.entityType))
      .size > 1

  const isKeepConditionSelected =
    isNil(conditionChange) || conditionChange.operation === conditionsMassEditOperations.keep

  const isReplaceConditionNil =
    conditionChange?.operation === conditionsMassEditOperations.replace &&
    isNil(conditionChange.value)

  return (isKeepConditionSelected || isReplaceConditionNil) && requirementsHaveDifferingTypes
}
