import { DateTime } from 'luxon'

function sanitizeForFileName(str) {
  return str?.replace(/[/?<>\\:*|"]/g, '')
}

function epochMillisOfIso(isoDate) {
  return DateTime.fromISO(isoDate).toMillis()
}

function getMostRecentKeyDate(rentalUnits) {
  return rentalUnits
    ?.map(({ rent_roll_key_date }) => rent_roll_key_date)
    .sort((first, second) => epochMillisOfIso(second) - epochMillisOfIso(first))[0]
}

/**
 * Generates the rent roll Excel file name depending on whether the file
 * should contain rental units of multiple rent rolls or not.
 * @param rentalUnits rental units (must be enriched for the fields `rent_roll_key_date` and `property_id`)
 * @param multiPropertyViewId multi property view ID (required when rental units of multiple rent rolls / properties are provided)
 */
export default function ({ rentalUnits, multiPropertyViewId }) {
  if (multiPropertyViewId) {
    const mostRecentKeyDate = getMostRecentKeyDate(rentalUnits)

    return `RentRoll_MultiPropertySelection_${multiPropertyViewId}${
      mostRecentKeyDate ? `_${mostRecentKeyDate}` : ''
    }.xlsx`
  }

  const propertyName = sanitizeForFileName(rentalUnits?.[0]?.property_name)
  const propertyId = rentalUnits?.[0]?.property_id
  const keyDate = rentalUnits?.[0]?.rent_roll_key_date

  return `RentRoll${propertyName ? `_${propertyName}` : ''}${propertyId ? `_${propertyId}` : ''}${
    keyDate ? `_${keyDate}` : ''
  }.xlsx`
}
