import isEmpty from 'lodash.isempty'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * @enum
 */
export const BankCustomerAccountTypes = Object.freeze({
  CreditAccount: 'CREDIT_ACCOUNT',
  OtherAccount: 'OTHER_ACCOUNT',
})

/**
 * If includeCreditAccounts and includeOtherAccounts are both left blank, all accounts are included.
 *
 * @param {object} param
 * @param {string} param.dealUuid - mandatory deal UUID, request is disabled if not specified
 * @param {boolean} param.includeCreditAccounts - optional, only credit accounts present in response
 * @param {boolean} param.includeOtherAccounts - optional, only other accounts present in response
 * @param {object} param.options - optional request options
 * @returns bank customer accounts
 */
const useBankCustomerAccounts = ({
  dealUuid,
  includeCreditAccounts,
  includeOtherAccounts,
  options = {},
}) => {
  const queryParams = new URLSearchParams()
  const accountTypes = []
  includeCreditAccounts && accountTypes.push(BankCustomerAccountTypes.CreditAccount)
  includeOtherAccounts && accountTypes.push(BankCustomerAccountTypes.OtherAccount)
  queryParams.append('account_types', accountTypes)

  return useCamelizedResponse(
    useRequest({
      path: isEmpty(accountTypes)
        ? `/financing-structures/deals/${dealUuid}/bank-customer-accounts`
        : `/financing-structures/deals/${dealUuid}/bank-customer-accounts?${queryParams.toString()}`,
      keys: ['deals', dealUuid, 'bank-customer-accounts', ...accountTypes],
      options: { enabled: !!dealUuid, ...options },
    }),
  )
}

export default useBankCustomerAccounts
