import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { referencesPropTypes } from 'components/domains/conditions/overview/table-cells/conditions/propTypes'
import ConditionsReferencesView from 'components/domains/conditions/shared/ConditionsReferencesView'
import useRequirementsCellEditMode from 'hooks/services/conditions/edit-mode/useRequirementsCellEditMode'
import { requirementRowHasError } from 'redux/slices/conditions/requirementsTableSlice'

const RequirementsTableReferencesCell = ({
  value: { id: requirementId, references: viewReferences },
}) => {
  const dispatch = useDispatch()

  const onError = useCallback(
    (error) => {
      dispatch(requirementRowHasError({ id: requirementId, error }))
    },
    [dispatch, requirementId],
  )

  const editedReferences = useSelector(
    (state) => state.conditions.requirementsTable.editedRow.currentValues?.condition?.references,
  )

  const { isEditModeForCurrentRow } = useRequirementsCellEditMode({
    requirementId,
  })

  const references = useMemo(
    () => (isEditModeForCurrentRow ? editedReferences : viewReferences),
    [editedReferences, isEditModeForCurrentRow, viewReferences],
  )

  return <ConditionsReferencesView references={references} onError={onError} />
}

RequirementsTableReferencesCell.propTypes = {
  value: PropTypes.shape({
    references: referencesPropTypes,
    id: PropTypes.string,
  }).isRequired,
}

export default RequirementsTableReferencesCell
