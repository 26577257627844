import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { responseCodes } from 'api/requests'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultipleCovenantMonitoringItemsByCovenantUuid = ({ covenantUuids, options = {} }) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: covenantUuids.map((covenantUuid) => ({
        path: `/covenants/${covenantUuid}/monitoring-information/items`,
        keys: ['covenants', covenantUuid, 'monitoring', 'items'],
      })),
      translated: true,
      useCache: true,
      options: { enabled: !isEmpty(covenantUuids), ...options },
    }),
  )

  return useMemo(
    () =>
      responses.map((response, index) => ({
        ...response,
        // filter out 404s, because they are not errors per se
        isError:
          response?.error?.response?.status === responseCodes.NOT_FOUND ? false : response.isError,
        data: {
          covenantUuid: covenantUuids[index],
          ...camelize(response.data),
        },
      })),
    [covenantUuids, responses],
  )
}

export default useMultipleCovenantMonitoringItemsByCovenantUuid
