import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/handover/general-deal-information/HandoverCoverPoolStatusTileContent.module.css'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/DisplayCardViewItemView'

const HandoverCoverPoolStatusTileContent = ({ content }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.handover.coverpool-status',
  })

  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      alignItems={FlexBoxAlignItems.Stretch}
      className={styles.displayFlexBox}
    >
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          data-testid="coverpool-status"
          label={t('coverpool-status.label')}
          value={content.coverpoolStatus ? t(`coverpool-status.${content.coverpoolStatus}`) : '-'}
          isFirstItem
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          data-testid="comment"
          label={t('comment.label')}
          value={content.comment || '-'}
        />
      </DisplayCardViewItemView>
    </FlexBox>
  )
}

HandoverCoverPoolStatusTileContent.propTypes = {
  content: PropTypes.shape({
    coverpoolStatus: PropTypes.string,
    comment: PropTypes.string,
  }),
}
export default HandoverCoverPoolStatusTileContent
