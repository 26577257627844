import isString from 'lodash.isstring'
import omit from 'lodash.omit'
import { useLocation, useNavigate as useReactRouterNavigate } from 'react-router-dom'

/**
 * @param {import('react-router-dom').Path} path
 */
const pathToUrl = ({ pathname, search, hash }) => `${pathname}${search}${hash}`

/**
 * @typedef {object} extensions
 * @property {string} [target]
 * @property {string} [features]
 * @typedef {import('react-router-dom').NavigateOptions & extensions} options
 * @param {options} [defaultOptions]
 */
const useNavigate = (defaultOptions) => {
  const reactRouterNavigate = useReactRouterNavigate()
  const location = useLocation()

  /**
   * @param {import('react-router-dom').To} to
   * @param {options} [optionOverrides]
   */
  const navigate = (to, optionOverrides) => {
    const options = { ...(defaultOptions ?? {}), ...(optionOverrides ?? {}) }
    const { target = '_self', features } = options

    if (!isString(to)) {
      const { pathname, search, hash } = { ...location, ...to }
      to = pathToUrl({ pathname, search, hash })
    }

    if (target === '_self') reactRouterNavigate(to, omit(options, 'target', 'features'))
    else window.open(to, target, features)
  }

  return navigate
}

export default useNavigate
