import { useCallback, useMemo } from 'react'

/**
 * @typedef {{id: string, valuer: string, info: {propertyId: string}}} ValuationRequest
 *
 * @param {ValuationRequest[]} valuationRequests list of all valuation requests for the current property or properties
 * @param {number[]} selectedValuationRequestIds list of valuationRequestIds a valuer should be assigned to
 * @returns {(valuerId: string) => boolean} function that checks if rotation principle would be violated for newly assigned valuerId
 */
const useValuationRequestRotationPrincipleChecker = (
  valuationRequests,
  selectedValuationRequestIds,
) => {
  const propertyValuersMap = useMemo(() => {
    const unselectedValuationRequests = valuationRequests.filter(
      ({ id }) => !selectedValuationRequestIds.includes(id),
    )

    return unselectedValuationRequests.reduce((valuerMap, { valuer, info: { propertyId } }) => {
      if (propertyId in valuerMap) {
        return { ...valuerMap, [propertyId]: valuerMap[propertyId].add(valuer) }
      }
      return { ...valuerMap, [propertyId]: new Set([valuer]) }
    }, {})
  }, [selectedValuationRequestIds, valuationRequests])

  const selectedPropertyIds = useMemo(
    () =>
      valuationRequests
        .filter(({ id }) => selectedValuationRequestIds.includes(id))
        .map(({ info: { propertyId } }) => propertyId),
    [selectedValuationRequestIds, valuationRequests],
  )

  return useCallback(
    (valuerId) => {
      /*
       * if list of propertyIds for selected valuation requests
       * contains duplicated items, the rotation principle will always be
       * violated when assigning a new valuer to those valuation requests.
       */
      const selectedPropertyIdsSet = new Set(selectedPropertyIds)

      if (selectedPropertyIdsSet.size !== selectedPropertyIds.length) {
        return true
      }
      for (const propertyId of selectedPropertyIds) {
        if (!(propertyId in propertyValuersMap)) {
          continue
        }
        if (propertyValuersMap[propertyId].has(valuerId)) {
          return true
        }
      }
      return false
    },
    [propertyValuersMap, selectedPropertyIds],
  )
}

export default useValuationRequestRotationPrincipleChecker
