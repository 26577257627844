import { Link } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/related-documents/AssociatedDeals.module.css'
import Card from 'components/ui/card/Card'
import Entity from 'components/ui/data/Entity'
import Section from 'components/ui/page/Section'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'

const maxInitialDealsToShow = 5
const maxDealNameLength = 80
const dealNameSuffix = '…'

const buildDealDisplayName = (name = '') => {
  if (name.length <= maxDealNameLength) return name
  return `${name.substring(0, maxDealNameLength - dealNameSuffix.length)}${dealNameSuffix}`
}

const AssociatedDeals = ({ deals = [], isLoading, isError }) => {
  const [expanded, setExpanded] = useState(false)
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-partner.associated-deals',
  })

  const toggleIsExpanded = () => setExpanded((prev) => !prev)

  const totalDeals = deals.length
  const dealsToShow = expanded ? deals : deals.slice(0, maxInitialDealsToShow)
  const showExpandLink = totalDeals > maxInitialDealsToShow
  const linkText = t(expanded ? 'collapse' : 'expand', { count: totalDeals })

  return (
    <Section
      titleText={t('title')}
      actions={
        showExpandLink && (
          <Link className={styles.expandLink} onClick={toggleIsExpanded}>
            {linkText}
          </Link>
        )
      }
    >
      <LoadingStateWrapper isLoading={isLoading} isError={isError}>
        {dealsToShow.length ? (
          <div className={styles.entries}>
            {dealsToShow.map(({ name, dealId }) => (
              <Card key={dealId}>
                <Entity
                  className={styles.entry}
                  id={dealId}
                  name={buildDealDisplayName(name)}
                  link={`/deals/${dealId}/documents`}
                  openInNewTab
                />
              </Card>
            ))}
          </div>
        ) : (
          <div className={styles.noData}>
            <p className={styles.noDataTitle}>{t('no-deals-title')}</p>
            <p className={styles.noDataSubtitle}>{t('no-deals-subtitle')}</p>
          </div>
        )}
      </LoadingStateWrapper>
    </Section>
  )
}

AssociatedDeals.propTypes = {
  deals: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
}

export default AssociatedDeals
