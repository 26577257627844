import isNil from 'lodash.isnil'

/**
 * @template Data
 * @param {import('@tanstack/react-query').UseQueryResult<Data>[]} responses
 * @param {string[]} responsesKeys
 * @returns {{isLoading: boolean, isError: boolean, errors: any[], data?: Record<string, Data>}}
 */
export const aggregateInvolvedPartiesQuery = (responses, responsesKeys) => {
  const errors = []
  const aggregatedData = {}

  if (isNil(responses) || isNil(responsesKeys) || responses.length !== responsesKeys.length) {
    return {
      isError: false,
      isLoading: false,
      isFetching: false,
      data: aggregatedData,
      errors,
    }
  }

  let isAnyLoading = false
  let isAnyError = false
  let isAnyFetching = false
  responses.forEach(({ isLoading, isFetching, isError, data, error }, index) => {
    isAnyLoading ||= isLoading
    isAnyError ||= isError
    isAnyFetching ||= isFetching
    if (error) {
      errors.push(error)
    }
    if (data) {
      aggregatedData[responsesKeys[index]] = data
    }
  })

  if (isAnyLoading || isAnyError || isAnyFetching) {
    return { isError: isAnyError, isLoading: isAnyLoading, isFetching: isAnyFetching, errors }
  }

  return {
    isError: false,
    isLoading: false,
    isFetching: false,
    data: aggregatedData,
    errors: [],
  }
}
