import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { propertyAllowedOperations } from 'api/property/propertyAllowedOperations'
import PropertyRentRollKpiChartsDataProvider from 'components/domains/properties/common/PropertyRentRollKpiChartsDataProvider'
import PropertyInformationWithoutNeed2Know from 'components/domains/properties/information-without-need2know/PropertyInformationWithoutNeed2Know'
import { TenantColorProvider } from 'components/domains/properties/rent-roll/overview/chart-colors/TenantColorContext'
import { UsageTypeColorProvider } from 'components/domains/properties/rent-roll/overview/chart-colors/UsageTypeColorContext'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import NavigationL2 from 'components/ui/navigation/NavigationL2'
import RecentVisitWrapper from 'components/ui/recent-visit/RecentVisitWrapper'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import usePropertyNeedToKnow from 'hooks/services/need-to-know/usePropertyNeedToKnow'
import useCurrentRentRollOverviewSinglePropertyKpis from 'hooks/services/properties/kpis/useCurrentRentRollOverviewSinglePropertyKpis'
import { usePropertiesPermissions } from 'hooks/services/properties/usePropertiesPermissions'
import { useProperty } from 'hooks/services/properties/useProperty'
import { PropertyContext } from 'routes/properties/PropertyContext'

const buildL2NavBarBasedOnPermissions = (permissionsData) => {
  const navItems = []
  navItems.push({
    path: '',
    translationToken: 'pages.property-overview.overview',
  })
  navItems.push({
    path: 'general-information',
    translationToken: 'pages.property-overview.general-information',
  })
  permissionsData.allowed_operations.includes('PropertyValuation_Read') &&
    navItems.push({
      path: 'valuation',
      translationToken: 'pages.property-overview.valuation',
    })
  navItems.push({
    path: 'monitoring',
    translationToken: 'pages.property-overview.monitoring',
  })
  navItems.push({
    path: 'rent-roll',
    translationToken: 'pages.property-overview.rent-roll',
  })
  navItems.push({
    path: 'insurances',
    translationToken: 'pages.property-overview.insurances',
  })
  navItems.push({
    path: 'kpis',
    translationToken: 'pages.property-overview.kpis',
  })
  navItems.push({
    path: 'documents',
    translationToken: 'pages.property-overview.documents',
  })
  return navItems
}
export const PropertyWrapper = () => {
  // TODO: request a single property via the dedicated service method.
  // Do not request all properties filtered by a single id and choose the first result
  const { t } = useTranslation('translation', { keyPrefix: 'pages.properties' })
  const { propertyId } = useParams()

  const {
    data,
    isLoading: isLoadingProperties,
    isError: isErrorProperties,
  } = useProperty(propertyId, false)
  const {
    isError: isErrorPermissions,
    isLoading: isLoadingPermissions,
    data: propertyPermissions,
  } = usePropertiesPermissions()

  const { data: needToKnow } = usePropertyNeedToKnow(data?.uuid)

  const renderContent = () => {
    const property = data
    const navItems = buildL2NavBarBasedOnPermissions(propertyPermissions)

    const isAllowedPropertyRead = () =>
      propertyPermissions?.allowed_operations?.includes(propertyAllowedOperations.propertyRead)

    if (!isAllowedPropertyRead()) {
      return (
        <CenteredIllustratedMessage
          name="UnableToLoad"
          size="Spot"
          titleText={t('permissions.error.title')}
          subtitleText={t('permissions.error.sub-title')}
        />
      )
    }

    return (
      <TenantColorProvider>
        <UsageTypeColorProvider>
          <PropertyContext.Provider
            value={{
              property: property,
              allowedOperations: propertyPermissions.allowed_operations,
            }}
          >
            <PropertyRentRollKpiChartsDataProvider
              usePropertyRentRollKpiChartsData={useCurrentRentRollOverviewSinglePropertyKpis}
            >
              {needToKnow?.allowed ? (
                property && <NavigationL2 navItems={navItems} />
              ) : (
                <PropertyInformationWithoutNeed2Know />
              )}
            </PropertyRentRollKpiChartsDataProvider>
          </PropertyContext.Provider>
        </UsageTypeColorProvider>
      </TenantColorProvider>
    )
  }

  return (
    <RecentVisitWrapper entityId={data?.uuid} entityType={cwpEntityTypes.PROPERTY}>
      <LoadingStateWrapper
        isError={isErrorProperties || isLoadingProperties || isErrorPermissions}
        isLoading={isLoadingProperties || isLoadingPermissions}
        renderContent={renderContent}
      />
    </RecentVisitWrapper>
  )
}
