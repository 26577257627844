import compact from 'lodash.compact'
import isEmpty from 'lodash.isempty'

const extractDealIdsAndUuids = ({
  multipleDealsWithTranchesAndDrawdownsByBorrowerData,
  borrowerIds,
}) => {
  const dealIdsAndUuidsByBorrowerId = {}
  borrowerIds?.forEach((borrowerId) => {
    dealIdsAndUuidsByBorrowerId[borrowerId] = multipleDealsWithTranchesAndDrawdownsByBorrowerData
      ?.find((data) => data?.data?.businessPartnerId === borrowerId)
      ?.data?.deals?.map(({ dealId, dealUuid }) => ({ dealId, dealUuid }))
  })

  const allDealUuids = isEmpty(dealIdsAndUuidsByBorrowerId)
    ? []
    : compact(Object.values(dealIdsAndUuidsByBorrowerId).flat()).map((deal) => deal.dealUuid)

  return { dealIdsAndUuidsByBorrowerId, allDealUuids }
}

export default extractDealIdsAndUuids
