import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { kpisAllowedOperations } from 'api/kpis/kpisAllowedOperations'
import KpiOverviewCard from 'components/domains/kpis/KpiOverviewCard'
import KpiOverviewChartCard from 'components/domains/kpis/KpiOverviewChartCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'
import KpiPermissionWrapper from 'routes/kpis/KpiPermissionWrapper'

const DealKpiOverviewPage = () => {
  const { t } = useTranslation('translation')

  const {
    deal: { dealUuid },
  } = useContext(DealContext)

  const breadcrumb = { text: t('pages.deals.detail.kpis.breadcrumb') }

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [dealUuid],
  )

  return (
    <DealDetailsWrapper
      childBreadcrumbs={breadcrumb}
      actions={markFavoriteAction}
      showWVLink={false}
    >
      <KpiPermissionWrapper requiredOperation={kpisAllowedOperations.readKpi}>
        <CWPLayout>
          <CWPLayout.Full>
            <KpiOverviewCard
              referenceEntityType={cwpEntityTypes.DEAL}
              referenceEntityId={dealUuid}
            />
          </CWPLayout.Full>
          <CWPLayout.Full>
            <KpiOverviewChartCard
              referenceEntityType={cwpEntityTypes.DEAL}
              referenceEntityId={dealUuid}
            />
          </CWPLayout.Full>
        </CWPLayout>
      </KpiPermissionWrapper>
    </DealDetailsWrapper>
  )
}

export default DealKpiOverviewPage
