import {
  Button,
  AnalyticalTableScaleWidthMode,
  AnalyticalTableSelectionBehavior,
  AnalyticalTableSelectionMode,
  TextAlign,
  ButtonDesign,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/covenant-check-detail/check-documents/CovenantCheckDocumentsTable.module.css'
import {
  CheckDocumentsDateCell,
  CheckDocumentsDownload,
  CheckDocumentsName,
} from 'components/domains/deals/covenant-check-detail/check-documents/CovenantCheckDocumentsTableCells'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import DocumentUploadDialog from 'components/domains/documents/DocumentUploadDialog'
import mapDocumentsForTable from 'components/domains/documents/documents-table/mapDocumentsForTable'
import {
  labelNewDocuments,
  sortColumnWithStatus,
} from 'components/domains/markets/detail/cards/MarketDocumentTableFunctions'
import AnalyticalTableNoDataComponent from 'components/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'

const propTypes = {
  allowUploadDocument: PropTypes.bool,
  uploadInitialState: PropTypes.shape({
    // HINT: FileList doesn't have a constructor, so we can't test it => use `File[]`
    files: PropTypes.arrayOf(PropTypes.instanceOf(File).isRequired),
  }),
  covenantCheckId: PropTypes.string.isRequired,
  documents: PropTypes.shape({
    subFolders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string,
        fileName: PropTypes.string,
        fileExtension: PropTypes.string,
        modificationTime: PropTypes.string,
        keyDate: PropTypes.string,
      }).isRequired,
    ),
  }),
  referenceEntity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    displayId: PropTypes.string,
    documentType: PropTypes.oneOf([DocumentTypes.Deal, DocumentTypes.BusinessPartner]),
  }),
  onUploadSuccess: PropTypes.func,
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const CovenantCheckDocumentsTable = ({
  documents,
  referenceEntity,
  covenantCheckId,
  allowUploadDocument = false,
  uploadInitialState,
  onUploadSuccess,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.check-documents',
  })
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false)

  useEffect(() => {
    setIsUploadDialogOpen(!!uploadInitialState?.files)
  }, [uploadInitialState?.files])

  const [newDocumentIds, setNewDocumentIds] = useState(/** @type {string[]} */ ([]))

  const markedDocuments = useMemo(
    () => labelNewDocuments(documents, newDocumentIds),
    [newDocumentIds, documents],
  )

  const preparedDocuments = useMemo(() => mapDocumentsForTable(markedDocuments), [markedDocuments])

  /** @param {string[]} [uploadedDocumentIds] */
  const handleUploadFinished = (uploadedDocumentIds = []) => {
    setNewDocumentIds(uploadedDocumentIds)
    onUploadSuccess?.()
  }

  const columns = useMemo(() => {
    const allColumns = [
      {
        Header: t('columns.name'),
        title: t('columns.name'),
        accessor: 'name',
        filterType: filterTypes.CONTAINS,
        Cell: CheckDocumentsName,
        sortType: (row1, row2, columnId, descending) =>
          sortColumnWithStatus(row1, row2, columnId, descending),
        isSelectableForHiding: true,
        isVisible: true,
      },
      {
        Header: t('columns.type'),
        title: t('columns.type'),
        accessor: 'type',
        filterType: filterTypes.CONTAINS,
        sortType: (row1, row2, columnId, descending) =>
          sortColumnWithStatus(row1, row2, columnId, descending),
        isSelectableForHiding: true,
        isVisible: true,
      },
      {
        Header: t('columns.format'),
        title: t('columns.format'),
        accessor: 'fileExtension',
        filterType: filterTypes.CONTAINS,
        sortType: (row1, row2, columnId, descending) =>
          sortColumnWithStatus(row1, row2, columnId, descending),
        isSelectableForHiding: true,
        isVisible: false,
      },
      {
        Header: t('columns.last-modified-at'),
        title: t('columns.last-modified-at'),
        accessor: 'modificationTime',
        Cell: CheckDocumentsDateCell,
        hAlign: TextAlign.End,
        filterType: filterTypes.BETWEEN_DATES,
        sortType: (row1, row2, columnId, descending) =>
          sortColumnWithStatus(row1, row2, columnId, descending),
        isSelectableForHiding: true,
        isVisible: false,
      },
      {
        Header: t('columns.key-date'),
        title: t('columns.key-date'),
        accessor: 'keyDate',
        Cell: CheckDocumentsDateCell,
        //width must be defined, so that the AnalytacalTableScaleWidthMode.Grow fills the remaining space
        width: 100,
        hAlign: TextAlign.End,
        filterType: filterTypes.BETWEEN_DATES,
        sortType: (row1, row2, columnId, descending) =>
          sortColumnWithStatus(row1, row2, columnId, descending),
        isSelectableForHiding: true,
        isVisible: true,
      },
      {
        accessor: 'download',
        hAlign: TextAlign.Center,
        disableSortBy: true,
        disableFilters: true,
        //width must be defined, so that the AnalytacalTableScaleWidthMode.Grow fills the remaining space
        width: 46,
        disableResizing: true,
        Cell: CheckDocumentsDownload,
        isVisible: true,
      },
    ]

    return allColumns.map((column) => ({
      disableDragAndDrop: true,
      ...column,
    }))
  }, [t])

  const handleUploadDialogIsOpen = () => setIsUploadDialogOpen(true)
  return (
    <>
      <AnalyticalTableWithToolbar
        title={t('title')}
        columns={columns}
        withRowHighlight
        highlightField={'status'}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
        data={preparedDocuments.subFolders}
        sortable
        filterable
        overscanCountHorizontal={99}
        showColumnSelection
        subRowsKey="subFolders"
        isTreeTable
        // Upgrading ui5 requires to set values here, otherwise tests will fail
        rowHeight={32}
        headerRowHeight={30}
        minRows={1}
        className={styles.table}
        selectionMode={AnalyticalTableSelectionMode.None}
        selectionBehavior={AnalyticalTableSelectionBehavior.RowSelector}
        reactTableOptions={{
          initialState: { sortBy: [{ id: 'keyDate', desc: true }] },
        }}
        additionalActions={
          allowUploadDocument
            ? [
                <Button
                  key="upload"
                  onClick={handleUploadDialogIsOpen}
                  design={ButtonDesign.Transparent}
                >
                  {t('upload')}
                </Button>,
              ]
            : []
        }
        NoDataComponent={() => (
          <AnalyticalTableNoDataComponent
            isLoading={false}
            isError={false}
            tableId={'covenants-documents-table'}
          />
        )}
      />
      <DocumentUploadDialog
        initialState={{
          ...uploadInitialState,
          selectedEntities: [
            {
              name: referenceEntity.name,
              id: referenceEntity.id,
              displayId: referenceEntity.displayId,
            },
            { type: DocumentTypes.CovenantCheck, id: covenantCheckId },
          ],
        }}
        isOpen={isUploadDialogOpen}
        setIsOpen={setIsUploadDialogOpen}
        onUploadFinished={handleUploadFinished}
        type={referenceEntity.documentType}
      />
    </>
  )
}

CovenantCheckDocumentsTable.propTypes = propTypes

export default CovenantCheckDocumentsTable
