import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import styles from 'components/domains/conditions/dialogs/requirements/reminders/table-cells/RequirementReminderMethodCell.module.css'
import LoadingDropdown from 'components/ui/dropdown/LoadingDropdown'
import useRequirementReminderMethods from 'hooks/services/conditions/requirements/reminders/useRequirementReminderMethods'

const RequirementReminderMethodCell = ({ onChange, currentValue, isEditMode }) => {
  const { isLoading, isError, data: reminderMehotdsData } = useRequirementReminderMethods()

  const entityTypesLoaderForLoadingDropdown = useCallback(() => {
    if (isLoading || isError) {
      return { isLoading: isLoading, isError: isError, data: undefined }
    }
    return {
      isLoading: false,
      isError: false,
      data: reminderMehotdsData.methods.map(({ code, name }) => ({ code, displayName: name })),
    }
  }, [isError, isLoading, reminderMehotdsData])

  return isEditMode ? (
    <LoadingDropdown
      id={'requirement-reminder-methods-select'}
      value={currentValue}
      onChange={onChange}
      useLoader={entityTypesLoaderForLoadingDropdown}
      initialDropdownValue={''}
      className={styles.inputWidth}
    />
  ) : (
    <Label>{currentValue}</Label>
  )
}

RequirementReminderMethodCell.propTypes = {
  onChange: PropTypes.func,
  isEditMode: PropTypes.bool,
  currentValue: PropTypes.string,
}
export default RequirementReminderMethodCell
