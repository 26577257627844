import {
  ROW_TYPE_PERCENTAGE,
  DEFAULT_DISPLAY_ROW_HEIGHT,
  LTV_CODE,
  DY_CODE,
  ISC_CODE,
  DSC_CODE,
  CPLTV_CODE,
  LTC_CODE,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/constants'
import mapFinancialRatioValue from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/financial-ratios/mapFinancialRatioValue'

const rowKeys = ['ltv', 'dy', 'isc', 'dsc', 'cpltv', 'ltc']

const mapRowData = ({ rowKey, responses }) => {
  const createRow = (createObjectFunction) =>
    responses
      ?.map(({ data, isLoading, isFetching, isError }) =>
        createObjectFunction(data, { isLoading, isFetching, isError }),
      )
      .reduce((prev, curr) => Object.assign(prev, curr), {})

  switch (rowKey) {
    case 'ltv':
      return createRow(({ referenceEntityId }, requestStates) => ({
        [referenceEntityId]: {
          value: mapFinancialRatioValue(referenceEntityId, responses, LTV_CODE),
          ...requestStates,
        },
      }))

    case 'dy':
      return createRow(({ referenceEntityId }, requestStates) => ({
        [referenceEntityId]: {
          value: mapFinancialRatioValue(referenceEntityId, responses, DY_CODE),
          ...requestStates,
        },
      }))

    case 'isc':
      return createRow(({ referenceEntityId }, requestStates) => ({
        [referenceEntityId]: {
          value: mapFinancialRatioValue(referenceEntityId, responses, ISC_CODE),
          ...requestStates,
        },
      }))

    case 'dsc':
      return createRow(({ referenceEntityId }, requestStates) => ({
        [referenceEntityId]: {
          value: mapFinancialRatioValue(referenceEntityId, responses, DSC_CODE),
          ...requestStates,
        },
      }))

    case 'cpltv':
      return createRow(({ referenceEntityId }, requestStates) => ({
        [referenceEntityId]: {
          value: mapFinancialRatioValue(referenceEntityId, responses, CPLTV_CODE),
          ...requestStates,
        },
      }))

    case 'ltc':
      return createRow(({ referenceEntityId }, requestStates) => ({
        [referenceEntityId]: {
          value: mapFinancialRatioValue(referenceEntityId, responses, LTC_CODE),
          ...requestStates,
        },
      }))
  }
}

const mapFinancialRatioData = ({ multipleKpisResponses, t }) =>
  rowKeys.map((rowKey) => {
    const rowData = mapRowData({
      rowKey,
      responses: multipleKpisResponses,
    })

    return {
      kpi: t(`rows.section.financial-ratios.${rowKey}`),
      rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT,
      ...rowData,
      rowType: ROW_TYPE_PERCENTAGE,
      rowHasError: Object.values(rowData).some((column) => column.isError),
    }
  })

export default mapFinancialRatioData
