import { z } from 'zod'

export const adjustmentTypesConfigSchema = z.object({
  code: z.string(),
  displayName: z.string(),
  deprecated: z.boolean(),
})

/** @typedef {import('zod').TypeOf<typeof adjustmentTypesConfigSchema>} AdjustmentTypeConfigDTO */

export const adjustmentTypesListConfigSchema = z.object({
  adjustmentTypes: z.array(adjustmentTypesConfigSchema),
})

/** @typedef {import('zod').TypeOf<typeof adjustmentTypesListConfigSchema>} AdjustmentTypeListConfigDTO */
