import { useRequest } from 'hooks/services/baseService'

const useEntitlementsAllowedOperations = () =>
  useRequest({
    path: '/entitlements/allowed-operations',
    useCache: true,
    keys: ['entitlements', 'permissions'],
  })

export default useEntitlementsAllowedOperations
