import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/covenants/monitoring-information-card/MonitoringInformationView.module.css'
import useDirectionTranslation from 'components/domains/deals/covenants/shared/useDirectionTranslation'
import useTemporalUnitTranslation from 'components/domains/deals/covenants/shared/useTemporalUnitTranslation'

const RelativeDateComparisonView = ({
  amount,
  temporalUnit,
  direction,
  referenceDateTranslation,
}) => {
  const { t } = useTranslation('translation')

  const { getTemporalUnitTranslation } = useTemporalUnitTranslation()
  const { getDirectionTranslation } = useDirectionTranslation()

  if (typeof amount === 'number' && temporalUnit && direction)
    return (
      <div>
        {amount} {getTemporalUnitTranslation(temporalUnit)}{' '}
        <span className={styles.lowerCase}>{getDirectionTranslation(direction)}</span>{' '}
        {referenceDateTranslation}
      </div>
    )

  return t('components.deals.card-show-item.empty-value')
}

RelativeDateComparisonView.propTypes = {
  amount: PropTypes.number,
  temporalUnit: PropTypes.string,
  direction: PropTypes.oneOf(['BEFORE', 'AFTER']),
  referenceDateTranslation: PropTypes.string,
}

export default RelativeDateComparisonView
