import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/cover-pool-information/BaselTwoCoverPoolInformation.module.css'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/critical-facility/shared/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/critical-facility/shared/DisplayCardViewItemView'
import { useCustomizableCurrencyFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'

const BaselTwoCoverPoolInformation = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.cover-pool-information',
  })

  const formatMoney = useCustomizableCurrencyFormatter()

  const { data } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const { tranchesData: tranches = [] } = data ?? []

  const totalCoverpoolEligibleAmount = tranches?.reduce(
    (acc, tranche) => acc + (tranche?.coverpoolEligibleAmount?.amount ?? 0),
    0,
  )

  const totalOwnShareCommitment = tranches?.reduce(
    (acc, tranche) => acc + (tranche?.ownShare?.commitment ?? 0),
    0,
  )

  const totalCoverPoolAmount = ~~totalOwnShareCommitment - ~~totalCoverpoolEligibleAmount
  const totalCoverPoolCurrency = tranches?.at(-1)?.ownShare?.currency

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.card}>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('label')}
          value={
            totalCoverPoolCurrency
              ? formatMoney(totalCoverPoolAmount, totalCoverPoolCurrency)
              : t('empty-value')
          }
        />
      </DisplayCardViewItemView>
    </FlexBox>
  )
}

BaselTwoCoverPoolInformation.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default BaselTwoCoverPoolInformation
