import { Link, LinkDesign, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DecisionStageLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/decision-stage-leave-confirmation/DecisionStageLeaveConfirmationLink'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import styles from 'routes/deals/syndication/syndicationStructure/uiElements/SyndicationBusinessPartnerView.module.css'

const OpsMemoSyndicationBusinessPartnerViewV1 = ({
  businessPartnerId,
  showLink = true,
  showConfirmationForLinks = false,
}) => {
  const {
    data: businessPartnerData,
    isLoading: isLoading,
    isError: isError,
  } = useBusinessPartnerMiniByIds([businessPartnerId])

  const { t } = useTranslation('decisionPaper')
  const fullName = businessPartnerData?.businessPartnerMinis?.[0]?.fullName || ''

  const linkElement = () => {
    if (showLink && showConfirmationForLinks) {
      return (
        <DecisionStageLeaveConfirmationLink
          name={fullName}
          link={`/business-partners/${businessPartnerId}`}
        />
      )
    } else if (showLink) {
      return (
        <Link
          design={LinkDesign.Emphasized}
          href={`/business-partners/${businessPartnerId}`}
          target="_blank"
          wrappingType="Normal"
        >
          {fullName}
        </Link>
      )
    }
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      error={
        <div className={styles.errorLabel}>
          {t('pages.deals.syndicationStructure-table.errors.failed-to-fetch-bp')}
        </div>
      }
      renderContent={() => (
        <div className={styles.counterpartyCell}>
          {linkElement()}
          <Text
            wrapping={false}
            className={`${styles.textWithOverflowHandling} ${!showLink ? styles.textBold : ''}`}
          >
            {showLink ? businessPartnerId : fullName}
          </Text>
        </div>
      )}
    />
  )
}

export default OpsMemoSyndicationBusinessPartnerViewV1

OpsMemoSyndicationBusinessPartnerViewV1.propTypes = {
  businessPartnerId: PropTypes.string.isRequired,
  showLink: PropTypes.bool,
  showConfirmationForLinks: PropTypes.bool,
}
