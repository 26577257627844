import {
  decisionPaperSynchronizationStatus,
  isMajorVersion,
} from 'api/decision-paper/decisionPaperApi'

const getLatestMajorVersionSyncDate = (availableVersions, decisionPaperVersion) => {
  const filteredVersions = availableVersions.filter(
    (availableVersion) =>
      availableVersion.version <= decisionPaperVersion &&
      isMajorVersion(availableVersion.version) &&
      availableVersion.status !== decisionPaperSynchronizationStatus.live &&
      availableVersion.lastSyncedDate,
  )
  if (filteredVersions.length === 0) return null

  return filteredVersions.reduce((prev, current) =>
    prev.version > current.version ? prev : current,
  )?.lastSyncedDate
}

const getLatestMinorVersionSyncDate = (availableVersions, decisionPaperVersion) => {
  const filteredVersions = availableVersions.filter(
    (availableVersion) =>
      availableVersion.version <= decisionPaperVersion &&
      !isMajorVersion(availableVersion.version) &&
      availableVersion.status !== decisionPaperSynchronizationStatus.live &&
      availableVersion.lastSyncedDate,
  )
  if (filteredVersions.length === 0) return null

  return filteredVersions.reduce(
    (prev, current) => (prev?.version > current.version ? prev : current),
    null,
  )?.lastSyncedDate
}

export const getLatestDecisionPaperSyncAndFreezeDates = (availableVersions, currentVersion) => {
  const matchingVersion = availableVersions.find(
    (versionData) => versionData.version === currentVersion,
  )

  const latestMajorVersionSyncDate =
    availableVersions && getLatestMajorVersionSyncDate(availableVersions, currentVersion)

  const latestMinorVersionSyncDate =
    !matchingVersion || (isMajorVersion(matchingVersion.version) && matchingVersion.lastSyncedDate)
      ? undefined
      : getLatestMinorVersionSyncDate(availableVersions, matchingVersion.version)

  return {
    latestMajorVersionSyncDate: latestMajorVersionSyncDate,
    latestMinorVersionSyncDate: latestMinorVersionSyncDate,
    frozenDate: matchingVersion?.frozenDate,
  }
}
