import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteKpiAdjustment = ({ onSuccess, onError }) => {
  const { delete: deleteKpiAdjustment } = useAccessTokenRequest()

  return useMutation(
    async ({ adjustmentId }) => {
      await deleteKpiAdjustment({
        path: `/kpis/adjustments/${adjustmentId}`,
        options: { enabled: !!adjustmentId },
      })
    },
    { onSuccess, onError },
  )
}

export default useDeleteKpiAdjustment
