import { useRequest } from 'hooks/services/baseService'

/**
 * Returns all property allowed operations
 * The allowed operations structure is as follows:
 * {
 *   "allowed_operations": [
 *      'PropertyRead_List',
 *      'Property_Read',
 *      [...]
 *   ]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: allowedOperations}}
 */

export const usePropertiesPermissions = () =>
  useRequest({
    path: '/properties/allowed-operations',
    useCache: true,
    keys: ['properties', 'permissions'],
  })
