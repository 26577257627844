import { Link, LinkDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

const space = <>&nbsp;</>

const LinkWithId = ({ name, href, id }) => (
  <>
    <Link
      style={{ alignItems: 'center' }}
      design={LinkDesign.Emphasized}
      target="blank"
      href={href}
    >
      {name}
    </Link>
    {space}
    {`(${id})`}
  </>
)

LinkWithId.propTypes = {
  href: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default LinkWithId
