import '@ui5/webcomponents-icons/dist/AllIcons.js'
import { FlexBox, FlexBoxJustifyContent, Table } from '@fioneer/ui5-webcomponents-react'
import { PropTypes } from 'prop-types'
import styles from 'components/domains/properties/modular-table/ModularTable.module.css'

export const alignTableContent = (content, alignment) => {
  let className = ''
  // this is needed for the rendered Text component in the column header applying the style `white-space: pre-line`,
  // which breaks the flex layout when text breaks into a second line
  if (alignment === FlexBoxJustifyContent.End) {
    className = styles.alignRight
  }

  return alignment ? (
    <FlexBox justifyContent={alignment} className={className}>
      {content}
    </FlexBox>
  ) : (
    content
  )
}

const ModularTable = ({
  columns,
  noDataText,
  paginationConfig,
  additionalTableProperties,
  children: tableRows,
}) => (
  <Table
    columns={columns}
    growing={paginationConfig.growing}
    growingButtonText={paginationConfig.growingButtonText}
    growingButtonSubtext={paginationConfig.growingButtonSubtext}
    noDataText={noDataText}
    onLoadMore={paginationConfig.loadMore}
    {...additionalTableProperties}
  >
    {tableRows}
  </Table>
)

ModularTable.propTypes = {
  columns: PropTypes.node,
  noDataText: PropTypes.string,
  children: PropTypes.node,
  additionalTableProperties: PropTypes.object,
  paginationConfig: PropTypes.shape({
    growing: PropTypes.string,
    growingButtonText: PropTypes.string,
    growingButtonSubtext: PropTypes.string,
    totalNumberOfItems: PropTypes.number,
    loadMore: PropTypes.func,
  }),
}

export default ModularTable
