import { Button, ButtonDesign, FlexBox } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionProcessMinutesDecisionStatus } from 'api/decision-process/decisionProcessMinutesApi'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import styles from 'components/domains/business-events-and-tasks/decision-stage/minutes/DecisionStageMinutesButtons.module.css'
import useDiscardMinutesDecisionFlow from 'hooks/services/business-events-and-tasks/decision-process/stages/minutes/flows/useDiscardMinutesDecisionFlow'
import useRequestMinutesDecisionFlow from 'hooks/services/business-events-and-tasks/decision-process/stages/minutes/flows/useRequestMinutesDecisionFlow'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionStageMinutesButtons = ({ decisionStageId, decisionStatus }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.minutes',
  })
  const {
    event: { id: eventId },
    allowedOperations,
  } = useContext(BusinessEventsAndTasksContext)

  const hasUpdateDecisionStagePermission = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.updateDecisionStage),
    [allowedOperations],
  )

  const { onRequestDecisionButtonClicked } = useRequestMinutesDecisionFlow({
    eventId,
    decisionStageId,
  })

  const { onDiscardDecisionButtonClicked } = useDiscardMinutesDecisionFlow({
    eventId,
    decisionStageId,
  })

  const requestDecisionButton = useMemo(
    () =>
      hasUpdateDecisionStagePermission && (
        <Button design={ButtonDesign.Emphasized} onClick={onRequestDecisionButtonClicked}>
          {t('buttons.request')}
        </Button>
      ),
    [t, hasUpdateDecisionStagePermission, onRequestDecisionButtonClicked],
  )

  const discardDecisionButton = useMemo(
    () =>
      hasUpdateDecisionStagePermission && (
        <Button design={ButtonDesign.Transparent} onClick={onDiscardDecisionButtonClicked}>
          {t('buttons.discard')}
        </Button>
      ),
    [t, hasUpdateDecisionStagePermission, onDiscardDecisionButtonClicked],
  )

  if (
    decisionStatus === decisionProcessMinutesDecisionStatus.pending ||
    decisionStatus === decisionProcessMinutesDecisionStatus.inProgressRequestDiscarded
  ) {
    return <FlexBox className={styles.buttonWrapper}>{requestDecisionButton}</FlexBox>
  }

  if (decisionStatus === decisionProcessMinutesDecisionStatus.inProgress) {
    return <FlexBox className={styles.buttonWrapper}>{discardDecisionButton}</FlexBox>
  }
}

DecisionStageMinutesButtons.propTypes = {
  decisionStageId: PropTypes.string.isRequired,
  decisionStatus: PropTypes.oneOf(Object.values(decisionProcessMinutesDecisionStatus)).isRequired,
}

export default DecisionStageMinutesButtons
