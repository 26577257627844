import { Button, Dialog, Text, Bar, FlexBox } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-information/shared/PropertyImage.module.css'

/**
 * use ui-components dialog | https://fioneer.atlassian.net/browse/CWP-13200
 */
export const ImageEnlargedDialog = ({
  enlargedImage,
  setOpenEnlargedDialog,
  openEnlargedDialog,
}) => {
  const { t } = useTranslation('decisionPaper')
  return (
    <>
      <Dialog
        footer={
          <Bar
            design="Footer"
            startContent={<Text>{enlargedImage.title}</Text>}
            endContent={
              <Button id="dialog-image-close" onClick={() => setOpenEnlargedDialog(false)}>
                {t('pages.properties.image-card.enlarged-image.close')}
              </Button>
            }
          />
        }
        open={openEnlargedDialog}
        stretch
        onAfterClose={() => setOpenEnlargedDialog(false)}
      >
        <FlexBox style={{ width: '100%', height: '100%' }}>
          <img className={styles['full-screen-img']} src={enlargedImage.source} />
        </FlexBox>
      </Dialog>
    </>
  )
}

ImageEnlargedDialog.propTypes = {
  enlargedImage: PropTypes.shape({
    title: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
  }),
  setOpenEnlargedDialog: PropTypes.func.isRequired,
  openEnlargedDialog: PropTypes.bool.isRequired,
}
