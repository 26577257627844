import { Label, Toolbar, ToolbarDesign, ToolbarStyle } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import tableToolBarStyles from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/DecisionPaperConditionsTileToolbar.module.css'

const DecisionPaperConditionsTileToolbar = ({ numberOfConditions }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.conditions.internal.toolbar',
  })

  return (
    <>
      <Toolbar
        design={ToolbarDesign.Auto}
        toolbarStyle={ToolbarStyle.Clear}
        className={tableToolBarStyles.toolbar}
      >
        <Label className={tableToolBarStyles.titleLabel}>
          {t('title', { numberOfConditions })}
        </Label>
      </Toolbar>
    </>
  )
}

DecisionPaperConditionsTileToolbar.propTypes = {
  numberOfConditions: PropTypes.number.isRequired,
}

export default DecisionPaperConditionsTileToolbar
