const startConditionEditMode = (state, { payload: { conditionId, condition } }) => {
  const filteredValues = {
    name: condition.info.name,
    category: condition.info.category ?? '',
    type: condition.info.type.code,
    refNumber: condition.info.refNumber ?? '',
    approvalLevel: condition.info.approvalLevel?.code,
    references: condition.references,
    covenantCheckId: condition.covenantCheckId,
    visibilities: condition.info.visibilities
      ? condition.info.visibilities.map(({ code }) => code)
      : [],
    assignee: condition.info.assignee ?? '',
    status: {
      code: condition.status.code,
      type: condition.status.type,
    },
    description: condition.info.description ?? '',
  }
  state.editedRow = {
    conditionId,
    initialValues: filteredValues,
    currentValues: filteredValues,
    changedFields: {},
    errorFields: {},
  }
}

export default startConditionEditMode
