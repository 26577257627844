import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDecisionStageMinutesVoteTypes = ({ eventId, decisionStageId }) =>
  useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/decision-stages/${decisionStageId}/minutes/vote-types`,
      useCache: true,
      keys: ['events', eventId, 'decision-stages', decisionStageId, 'minutes', 'vote-types'],
    }),
  )

export default useDecisionStageMinutesVoteTypes
