import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useEvent = (eventId, options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}`,
      useCache: true,
      keys: ['events', eventId],
      options: { enabled: !!eventId, ...options },
    }),
  )

export default useEvent
