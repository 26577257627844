import { useMemo } from 'react'
import { conditionsEntityTypes } from 'api/conditions/conditions'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useTileMetaData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/meta-data/useTileMetaData'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useAssigneeNameResolver from 'hooks/services/conditions/assignee/useAssigneeNameResolver'
import useInternalStatus from 'hooks/services/conditions/config/useInternalStatus'
import useInternalConditionsUuids from 'hooks/services/conditions/useInternalConditionsUuids'
import useDealMini from 'hooks/services/deals/useDealMini'

const resourcePathPartMapping = {
  DEAL: 'deals',
  BUSINESS_PARTNER: 'business-partners',
}

const useInternalConditions = (
  { id: eventId, entityRef: { entityType, entityId } },
  tileId,
  tileCode,
) => {
  const isDeal = entityType === conditionsEntityTypes.deal

  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({
    dealUuid: entityId,
    tileCode,
    options: { enabled: isDeal && !!entityId },
    isWorkingVersionInfoInSourcePath: true,
  })

  const {
    data: dealData,
    isFetching: isDealFetching,
    isError: isDealError,
    error: dealError,
  } = useDealMini(dealUuidByTileCode, { enabled: isDeal && !!dealUuidByTileCode })

  const {
    data: tileMetadata,
    isFetching: isTileMetadataFetching,
    isError: isTileMetadataError,
    error: tileMetadataError,
  } = useTileMetaData({ eventId, tileId })

  const selectedConditionUuids = Object.keys(tileMetadata?.meta_data?.selectedConditions || {})

  const {
    data: selectedConditions,
    isFetching: isSelectedConditionsFetching,
    isError: isSelectedConditionsError,
    error: selectedConditionsError,
  } = useInternalConditionsUuids(
    {
      internalConditionUuids: selectedConditionUuids,
      entityId: isDeal ? dealUuidByTileCode : entityId,
      entityType,
    },
    {
      enabled:
        !isTileMetadataFetching &&
        !isTileMetadataError &&
        !!tileMetadata?.meta_data?.selectedConditions &&
        selectedConditionUuids.length !== 0,
    },
  )

  const {
    mappedSelectedConditions: mappedSelectedConditionsWithResolvedAssigneeName,
    isFetching: isAssigneeFetching,
    isError: isAssigneeError,
  } = useAssigneeNameResolver({
    selectedConditions: selectedConditions?.internalConditions,
  })

  const {
    data: statusValuesData,
    isFetching: isStatusValuesFetching,
    isError: isStatusValuesError,
    error: statusValuesError,
  } = useInternalStatus({
    includeDeprecated: false,
    entityType,
  })

  const sourceRender = useMemo(
    () => ({
      displayId: isDeal ? dealData?.dealId : entityId,
      resourcePathPart: resourcePathPartMapping[entityType],
      sourcePathSuffix: isDeal && isWorkingVersionDataTile ? '&working-version=true' : '',
    }),
    [dealData?.dealId, entityId, entityType, isDeal, isWorkingVersionDataTile],
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isDeal && isDealUuidFetching,
      isDeal && isDealFetching,
      isTileMetadataFetching,
      isSelectedConditionsFetching,
      isAssigneeFetching,
      isStatusValuesFetching,
    ],
    errorValues: [
      isDeal && isDealUuidError,
      isDeal && isDealError,
      isTileMetadataError,
      isSelectedConditionsError,
      isAssigneeError,
      isStatusValuesError,
    ],
    errorDetails: [dealError, tileMetadataError, selectedConditionsError, statusValuesError],
    tileId,
  })

  const result = useMemo(() => {
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }
    if (
      !isTileMetadataFetching &&
      !isTileMetadataError &&
      (!tileMetadata?.meta_data ||
        !tileMetadata?.meta_data.selectedConditions ||
        selectedConditionUuids.length === 0)
    ) {
      return {
        isLoading: false,
        isError: false,
        data: {
          selectedConditions: [],
          sourceRender,
          entityId: isDeal ? dealUuidByTileCode : entityId,
        },
      }
    }
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }

    // Everything has loaded --> return the internal conditions
    return {
      isLoading: false,
      isError: false,
      data: {
        selectedConditions: mappedSelectedConditionsWithResolvedAssigneeName,
        sourceRender,
        entityId: isDeal ? dealUuidByTileCode : entityId,
        statusValues: statusValuesData?.status,
      },
    }
  }, [
    isSomeValueLoading,
    isTileMetadataFetching,
    isTileMetadataError,
    tileMetadata?.meta_data,
    selectedConditionUuids.length,
    isSomeValueError,
    mappedSelectedConditionsWithResolvedAssigneeName,
    sourceRender,
    isDeal,
    dealUuidByTileCode,
    entityId,
    statusValuesData?.status,
    error,
  ])

  return result
}

export default useInternalConditions
