import { CardHeader, Modals } from '@fioneer/ui5-webcomponents-react'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { propertyAllowedOperations } from 'api/property/propertyAllowedOperations'
import Card from 'components/ui/card/Card'
import CustomFieldsTile from 'components/ui/customfield/CustomFieldsTile'
import { useParseCustomFieldValue } from 'components/ui/customfield/useCustomFieldDefinitions'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import usePropertyCustomFieldConfig from 'hooks/services/properties/customfield/usePropertyCustomFieldConfig'
import usePropertyCustomFields from 'hooks/services/properties/customfield/usePropertyCustomFields'
import useUpdatePropertyCustomFields from 'hooks/services/properties/customfield/useUpdatePropertyCustomFields'
import { PropertyContext } from 'routes/properties/PropertyContext'

const ENTITY_TYPE = 'PROPERTY'

const PropertyCustomFieldCard = () => {
  const { t } = useTranslation('translation')

  const { property } = useContext(PropertyContext)

  const isEditable =
    property.change_request_exist_indicator === false &&
    property.allowed_operations?.allowed_operations?.includes(
      propertyAllowedOperations.propertyCustomFieldUpdate,
    )

  const {
    data: { customFieldMetadata } = {},
    isFetching: isMetadataLoading,
    isError: isMetadataError,
  } = usePropertyCustomFieldConfig(ENTITY_TYPE)

  const {
    data: { customFields } = {},
    isFetching: isCustomFieldsLoading,
    isError: isCustomFieldsError,
  } = usePropertyCustomFields(property.uuid)

  const showToast = Modals.useShowToast()
  const [saveError, setSaveError] = useState(undefined)
  const {
    mutate: saveCustomFields,
    isSuccess,
    isError,
  } = useUpdatePropertyCustomFields(property.uuid, ENTITY_TYPE, {
    onSuccess: () => {
      showToast({ children: t('toast.changes-saved') })
    },
    onError: async (error) => {
      setSaveError(error)
    },
  })

  const parseValue = useParseCustomFieldValue()

  /** @param {{[key: string]: any}} newFields */
  const handleSave = (newFields) => {
    const fieldsWithTypes = Object.entries(newFields).map(([key, value]) => {
      const { type } = customFieldMetadata?.find((field) => field.key === key) ?? {}

      const parsedValue = parseValue(value, type)
      return { key, type, value: parsedValue }
    })

    saveCustomFields({ customFields: fieldsWithTypes })
  }

  return (
    <RequestStateResolver
      isLoading={isCustomFieldsLoading || isMetadataLoading}
      isError={isCustomFieldsError || isMetadataError}
      errorToDisplay={
        <Card header={<CardHeader titleText={t('title')} />}>
          <ErrorDataUnavailableInContent />
        </Card>
      }
      renderContent={() => (
        <CustomFieldsTile
          customFields={customFields ?? []}
          config={customFieldMetadata ?? []}
          isEditable={isEditable}
          cardHeaderTitle={t('components.property-customfield-card.title')}
          saveChanges={handleSave}
          saveHookError={saveError}
          saveHookIsSuccess={isSuccess}
          saveHookIsError={isError}
        />
      )}
    />
  )
}

export default PropertyCustomFieldCard
