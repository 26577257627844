import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PropertiesSearchTable from 'components/domains/properties/properties-search/dialog/PropertiesSearchTable'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const mapPropertiesToReferences = (newSelection) =>
  newSelection.map((reference) => ({
    entityId: reference.uuid,
    entityType: cwpEntityTypes.PROPERTY,
    entityName: reference.description,
  }))

const defaultInitialFilterCriteria = {
  propertyName: '',
  propertyId: '',
  country: '',
  propertyTypes: '',
  city: '',
  dealId: '',
  zipCode: '',
  marketId: '',
}

const PropertySelectionTable = ({ selectedReferences, onSelectionChange }) => {
  const { referencesMapping } = useContext(ConditionsContext)
  const mappedReferencesToProperties = useMemo(
    () =>
      selectedReferences.map((reference) => ({
        uuid: reference.entityId,
        description: reference.entityName,
      })),
    [selectedReferences],
  )

  const [selectedProperties, setSelectedProperties] = useState(mappedReferencesToProperties)

  useEffect(() => {
    setSelectedProperties(mappedReferencesToProperties)
  }, [mappedReferencesToProperties])

  const onSelectionChangedInternal = useCallback(
    (newSelectionFunction) => {
      const newSelectedProperties = newSelectionFunction(selectedProperties)
      setSelectedProperties(newSelectedProperties)

      const mappedReferences = mapPropertiesToReferences(newSelectedProperties)
      onSelectionChange(mappedReferences)
    },
    [onSelectionChange, selectedProperties],
  )

  const initialFilterCriteria = useMemo(
    () => ({
      ...defaultInitialFilterCriteria,
      ...referencesMapping?.[cwpEntityTypes.PROPERTY],
    }),
    [referencesMapping],
  )

  return (
    <PropertiesSearchTable
      allowMultiSelect={true}
      selectedProperties={selectedProperties}
      onSelectionChange={onSelectionChangedInternal}
      initialFilterCriteria={initialFilterCriteria}
      startSearchOnNonEmptyFilterCriteria
    />
  )
}

PropertySelectionTable.propTypes = {
  selectedReferences: PropTypes.arrayOf(
    PropTypes.shape({
      entityId: PropTypes.string.isRequired,
      entityType: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
}

export default PropertySelectionTable
