import isEqual from 'lodash.isequal'
import { valuationStatus } from 'api/property/valuation/valuationRequests'

export const filterKeys = {
  infoStatus: 'info.status',
  status: 'status',
  reportDate: 'reportDate',
  type: 'type',
  valuer: 'valuer',
  reason: 'reason',
}

const filterForNotChangedValues = ({ value, emptyValue }) => !!value && !isEqual(value, emptyValue)

export const areFiltersApplied = (filters) => filters?.some(filterForNotChangedValues)

export const getRequestParametersForValuationRequests = (filters, searchParam) => {
  const requestParams = {}
  filters.forEach((filter) => {
    switch (filter.columnKey) {
      case filterKeys.status:
        if (filter.value !== filter.emptyValue) requestParams.status = valuationStatus[filter.value]
        break
      case filterKeys.reportDate:
        if (filter.value.lowerBound) requestParams.reportDateFrom = filter.value.lowerBound
        if (filter.value.upperBound) requestParams.reportDateUntil = filter.value.upperBound
        break
      case filterKeys.type:
        if (filter.value !== filter.emptyValue) requestParams.valuationType = filter.value
        break
      case filterKeys.reason:
        if (filter.value !== filter.emptyValue) requestParams.reason = filter.value
        break
      case filterKeys.valuer:
        if (filter.value !== filter.emptyValue) requestParams.valuer = filter.value
        break
    }
  })
  if (searchParam !== '') {
    requestParams.search = searchParam
  }
  return requestParams
}
