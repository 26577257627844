import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DecisionPaperTemplateDropdown, {
  noSelectionValueCode,
} from 'components/domains/business-events-and-tasks/decision-paper/DecisionPaperTemplateDropdown'
import styles from 'components/domains/business-events-and-tasks/decision-paper/DecisionPaperTemplateGroup.module.css'
import CustomizeDecisionPaperTemplateDialog from 'components/domains/business-events-and-tasks/decision-paper/dialogs/customize-decision-paper/CustomizeDecisionPaperTemplateDialog'
import { isTemplateCustomized } from 'components/domains/business-events-and-tasks/decision-paper/dialogs/customize-decision-paper/customizeDecisionPaperTemplateHelper'
import useDecisionPaperReferencedTemplate from 'hooks/services/business-events-and-tasks/decision-papers/useDecisionPaperReferencedTemplate'
import useDecisionPaperTemplateConfiguration from 'hooks/services/business-events-and-tasks/decision-papers/useDecisionPaperTemplateConfiguration'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionPaperTemplateGroup = ({
  templateCode,
  currentTemplateCode,
  currentTemplateName,
  onChange,
  eventCode,
  entityId,
  entityType,
  existingDecisionPaperTemplate,
}) => {
  const { t: tCommon } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.select-dialog',
  })

  const [customizeDialogOpen, setCustomizeDialogOpen] = useState(false)
  const [selectedTemplateCustomization, setSelectedTemplateCustomization] = useState()
  const [customizedTemplateCodes, setCustomizedTemplateCodes] = useState({})

  const event = useContext(BusinessEventsAndTasksContext)?.event
  const eventId = event?.id

  const isCurrentlyConfiguredTemplateRequestEnabled =
    (!isNil(templateCode) &&
      templateCode !== noSelectionValueCode &&
      templateCode !== existingDecisionPaperTemplate?.code) ||
    isNil(eventId)

  const isReferencedTemplateRequestEnabled =
    !isNil(templateCode) &&
    !isNil(eventId) &&
    templateCode !== noSelectionValueCode &&
    templateCode === existingDecisionPaperTemplate?.code

  const {
    data: templatesData,
    isLoading,
    isFetching,
    isError,
  } = useDecisionPaperTemplateConfiguration(
    { templateCode },
    { enabled: isCurrentlyConfiguredTemplateRequestEnabled },
  )

  const {
    data: referencedTemplateData,
    isLoading: isReferencedTemplateLoading,
    isFetching: isReferencedTemplateFetching,
    isError: isReferencedTemplateError,
  } = useDecisionPaperReferencedTemplate(
    { eventId },
    { enabled: isReferencedTemplateRequestEnabled },
  )

  const originalTemplateConfiguration = useMemo(() => {
    if (isCurrentlyConfiguredTemplateRequestEnabled) return templatesData?.templates?.[0]
    return referencedTemplateData
  }, [
    isCurrentlyConfiguredTemplateRequestEnabled,
    referencedTemplateData,
    templatesData?.templates,
  ])

  useEffect(() => {
    setCustomizedTemplateCodes((_customizedCodes) => {
      const customizedCodes = { ..._customizedCodes }
      if (!isNil(selectedTemplateCustomization)) {
        customizedCodes[templateCode] = true
      } else {
        delete customizedCodes[templateCode]
      }
      const isInitialTemplateCustomized =
        !isNil(originalTemplateConfiguration) &&
        !isNil(existingDecisionPaperTemplate) &&
        originalTemplateConfiguration.code === existingDecisionPaperTemplate.code &&
        isTemplateCustomized(originalTemplateConfiguration, existingDecisionPaperTemplate)
      if (isInitialTemplateCustomized) {
        customizedCodes[originalTemplateConfiguration.code] = true
      }
      return customizedCodes
    })
  }, [
    existingDecisionPaperTemplate,
    originalTemplateConfiguration,
    selectedTemplateCustomization,
    templateCode,
  ])

  const showCustomizationButton = useMemo(
    () => templateCode && templateCode !== noSelectionValueCode,
    [templateCode],
  )

  const handleCLickCustomizeButton = useCallback(() => {
    setCustomizeDialogOpen(true)
  }, [])

  const handleOnCancelCustomizeDialog = useCallback(() => {
    setCustomizeDialogOpen(false)
  }, [])

  const handleOnOkCustomizeDialog = useCallback(
    (customizedTemplate) => {
      setCustomizeDialogOpen(false)
      const compareToExisting =
        existingDecisionPaperTemplate?.code === originalTemplateConfiguration?.code
      const hasChangesToExistingTemplate = isTemplateCustomized(
        originalTemplateConfiguration,
        customizedTemplate,
        compareToExisting ? existingDecisionPaperTemplate : undefined,
      )
      const hasChangesToBaseTemplate = isTemplateCustomized(
        originalTemplateConfiguration,
        customizedTemplate,
      )
      const onOkEvent = {
        templateCode,
        customizedTemplateStructure: customizedTemplate,
        hasChangesToExistingTemplate,
        hasChangesToBaseTemplate,
      }

      setSelectedTemplateCustomization(customizedTemplate)
      onChange(onOkEvent)
    },
    [existingDecisionPaperTemplate, onChange, originalTemplateConfiguration, templateCode],
  )

  const handleTemplateChange = useCallback(
    (selectedTemplate) => {
      const onOkEvent = {
        templateCode: selectedTemplate,
        customizedTemplateStructure: null,
        hasChangesToExistingTemplate: false,
        hasChangesToBaseTemplate: false,
      }
      setSelectedTemplateCustomization(undefined)
      onChange(onOkEvent)
    },
    [onChange],
  )

  const existingDecisionPaperTemplateCustomization = useMemo(() => {
    if (!isNil(selectedTemplateCustomization)) {
      return selectedTemplateCustomization
    }
    if (existingDecisionPaperTemplate?.code === templateCode) {
      return existingDecisionPaperTemplate
    }
  }, [existingDecisionPaperTemplate, selectedTemplateCustomization, templateCode])

  const isLoadingParam = useMemo(
    () =>
      (isCurrentlyConfiguredTemplateRequestEnabled && isLoading) ||
      (isReferencedTemplateRequestEnabled && isReferencedTemplateLoading),
    [
      isCurrentlyConfiguredTemplateRequestEnabled,
      isLoading,
      isReferencedTemplateLoading,
      isReferencedTemplateRequestEnabled,
    ],
  )

  return (
    <>
      <div className={styles.templateContainer}>
        <DecisionPaperTemplateDropdown
          id="decision-paper-template-select"
          initialValue={templateCode}
          currentTemplateCode={currentTemplateCode}
          currentTemplateName={currentTemplateName}
          eventCode={eventCode}
          entityId={entityId}
          entityType={entityType}
          customizedTemplateCodes={customizedTemplateCodes}
          onChange={handleTemplateChange}
          required
        />
        {showCustomizationButton && (
          <Button
            className={styles.customizeButton}
            design={ButtonDesign.Default}
            onClick={handleCLickCustomizeButton}
          >
            {t('customize-button.label')}
          </Button>
        )}
      </div>
      {customizeDialogOpen && (
        <CustomizeDecisionPaperTemplateDialog
          isOpen={customizeDialogOpen}
          title={tCommon(
            'components.business-events-and-tasks.decision-papers.customize-dialog.title',
          )}
          existingDecisionPaperTemplate={existingDecisionPaperTemplateCustomization}
          originalTemplateConfiguration={originalTemplateConfiguration}
          isLoading={isLoadingParam}
          isFetching={isFetching || isReferencedTemplateFetching}
          isError={isError || isReferencedTemplateError}
          onCancel={handleOnCancelCustomizeDialog}
          onOk={handleOnOkCustomizeDialog}
        />
      )}
    </>
  )
}

DecisionPaperTemplateGroup.propTypes = {
  templateCode: PropTypes.string,
  currentTemplateCode: PropTypes.string,
  currentTemplateName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  eventCode: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  existingDecisionPaperTemplate: PropTypes.shape({
    code: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        subsections: PropTypes.arrayOf(
          PropTypes.shape({
            code: PropTypes.string.isRequired,
            tiles: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string.isRequired })),
          }),
        ),
        tiles: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string.isRequired })),
      }),
    ),
  }),
}

export default DecisionPaperTemplateGroup
