import { TreeItemCustom } from '@fioneer/ui5-webcomponents-react'
import { useEffect, useRef, useState } from 'react'

export const TreeItemCustomWithMaxWidth = (props) => {
  const [shouldRerender, setShouldRerender] = useState(false)
  const ref = useRef(null)
  /**
   * Added this shadow-dom-hacking as a last resort to expand the row to it's full width.
   * Inside the shadow-dom of the TreeItemCustom, there is a part-accessor (native-li). But there is no way
   * of adapting child components.
   */
  useEffect(() => {
    const listItemInShadowRoot = ref.current?.shadowRoot?.querySelector('.ui5-li-content')
    if (listItemInShadowRoot) {
      listItemInShadowRoot.style.display = 'block'
    }
    if (!shouldRerender) {
      setShouldRerender(true)
    }
  }, [shouldRerender, ref])
  return <TreeItemCustom {...props} ref={ref} />
}
