import PropTypes from 'prop-types'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/i18n/useI18n'
import PropertyValuationResultsDateInputCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/valuation-results/cells/PropertyValuationResultsDateInputCell'

const PropertyValuationResultsValidFromCell = ({ validFrom, isAddMode }) => {
  const { format: formatDate } = useShortDateFormatter()
  const currentDate = formatDate(new Date().toISOString())

  return (
    <PropertyValuationResultsDateInputCell
      date={validFrom}
      maxDate={currentDate}
      onChange={() => {}}
      isAddMode={isAddMode}
    />
  )
}

PropertyValuationResultsValidFromCell.propTypes = {
  validFrom: PropTypes.string,
  isAddMode: PropTypes.bool.isRequired,
}

export default PropertyValuationResultsValidFromCell
