import {
  BusyIndicatorSize,
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/CriticalFacilityTileDisplayCardView.module.css'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/CriticalFacilityTileDisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/CriticalFacilityTileDisplayCardViewItemView'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/card/EmptyCardContent'
import LoadingStateWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/screens/LoadingStateWrapper'
import 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/card/Card.css'
import 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/CriticalFacilityTileDisplayAndEditCard.css'

export const hideOptions = Object.freeze({
  alwaysHidden: 'alwaysHidden',
  hideWhenEmpty: 'hideWhenEmpty',
})

const propTypes = {
  cardHeaderTitle: PropTypes.string.isRequired,
  cardHeaderSubtitle: PropTypes.string,
  isEmpty: PropTypes.bool,
  emptyContent: PropTypes.shape({
    size: PropTypes.string,
    hasIllustration: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  isError: PropTypes.bool,
  errorContent: PropTypes.shape({
    size: PropTypes.string,
    hasIllustration: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  isNotAllowed: PropTypes.bool,
  notAllowedContent: PropTypes.shape({
    size: PropTypes.string,
    hasIllustration: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  setEditMode: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  fieldDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.shape({
          sectionTitle: PropTypes.string,
          sectionSubtitle: PropTypes.string,
          sectionValue: PropTypes.string,
        }),
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      isSectionTitle: PropTypes.bool,
      customInfoComponent: PropTypes.node,
    }).isRequired,
  ).isRequired,
  onHeaderClick: PropTypes.func,
  isEditable: PropTypes.bool,
  fillEmptyValuesWithPlaceholder: PropTypes.bool,
  className: PropTypes.string,
  conflictErrorTitle: PropTypes.string,
  conflictErrorText: PropTypes.string,
}

/**
 * Display Card Standard Component
 *
 * Important: Some props have a default value. They are defined at the end of this file.
 *
 * props:
 * - cardHeaderTitle: Title for the card
 * - cardHeaderSubtitle: (optional) Subtitle for the card
 * - isEmpty: renders emtpyContent when true
 * - emptyContent: {title, subtitle, illustrationName} (all optional) for the message if isEmpty is true
 * - isError: renders errorContent when true (not to be confused with errors after save)
 * - errorContent: {title, subtitle, hasIllustration} (all optional) for the message if isError is true
 * - isNotAllowed: renders notAllowedContent when true (precedent over empty state)
 * - notAllowedContent: {title, subtitle, illustrationName} (all optional) for the message if isNotAllowed is true
 * - isLoading: Flag for the LoadingStateWrapper (Loading animation when true)
 * - showCardHeaderActions: hides all buttons in header when true
 * - onHeaderClick: Header would be interactive, e.g gets hover effect, gets focus outline and click event is fired, when pressed.
 * - fillEmptyValuesWithPlaceholder: Shows "-" as value when values are empty instead of hiding them
 * - fieldDefinitions: array of objects defining this view
 *    -> possible definitons: sections, default content, custom components (see below)
 *
 * Example field Definition:
 * ```jsx
 * const fieldDefinitions = [
 *   {
 *     value: {sectionTitle: "Title 1", sectionSubtitle: "Subtitle"},
 *     isSectionTitle: true,
 *   },
 *   {
 *     value: {sectionTitle: "Title 2", sectionValue: "Title Value"},
 *     isSectionTitle: true,
 *   },
 *   {
 *     label: 'Label 1',
 *     name: 'propertyName',
 *     value: "Skyscraper Frankfurt Messe",
 *   },
 *   {
 *     label: 'Label 2',
 *     customInfoComponent: (
 *       <FlexBox fitContainer justifyContent={FlexBoxJustifyContent.SpaceBetween}>
 *         <Label>{"Label:"}</Label>
 *         <div>
 *           <Text>{"Custom display"}</Text>
 *           <Text>{"component"}</Text>
 *         </div>
 *       </FlexBox>
 *     ),
 *   },
 * ]
 * ```
 *
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const DisplayCardView = ({
  isEmpty = false,
  emptyContent,
  isError = false,
  errorContent,
  isNotAllowed = false,
  notAllowedContent,
  isLoading = false,
  fieldDefinitions,
  fillEmptyValuesWithPlaceholder = false,
  conflictErrorTitle = '',
  conflictErrorText = '',
}) => {
  const { t } = useTranslation('decisionPaper')

  const getHideRowStatus = (item) => {
    if (item.hideOption === hideOptions.alwaysHidden) return true
    if (item.hideOption === hideOptions.hideWhenEmpty) {
      return !item.value && !item.formattedValue
    }
    return false
  }

  const getHiddenRowCount = () => fieldDefinitions.filter((item) => getHideRowStatus(item)).length

  const renderSectionTitle = (infoItem, index) => {
    const subtitle = infoItem.value?.sectionSubtitle && (
      <Label className={styles.displayAndEditSubtitle}>{infoItem.value.sectionSubtitle}</Label>
    )
    const title = infoItem.value?.sectionTitle && (
      <Label
        className={`${styles.displayAndEditTitle} ${
          subtitle ? styles.displayAndEditSubtitleSeparator : ''
        }`}
      >
        {infoItem.value.sectionTitle}
      </Label>
    )
    const value = infoItem.value?.sectionValue && (
      <div className={styles.displayCardTextBox}>
        <Text>{infoItem.value.sectionValue}</Text>
      </div>
    )

    return title && value ? (
      <FlexBox
        fitContainer
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        className={`${index !== 0 ? styles.displayAndEditTitleSeparator : ''}`}
      >
        <FlexBox fitContainer direction={FlexBoxDirection.Column}>
          {title}
          {subtitle}
        </FlexBox>
        {value}
      </FlexBox>
    ) : (
      <FlexBox
        fitContainer
        direction={FlexBoxDirection.Column}
        className={`${index !== 0 ? styles.displayAndEditTitleSeparator : ''}`}
      >
        {title}
        {subtitle}
      </FlexBox>
    )
  }

  const fillPlaceholder = (value) => (fillEmptyValuesWithPlaceholder && !value ? '-' : value)

  const renderInfoItem = (infoItem, index) => {
    if (infoItem.isSectionTitle) {
      return renderSectionTitle(infoItem, index)
    }
    const isFirstItem = index === 0
    return (
      <DisplayCardViewInfoItemView
        label={infoItem.label}
        isFirstItem={isFirstItem}
        value={infoItem.formattedValue ?? fillPlaceholder(infoItem.value)}
      />
    )
  }

  const renderDisplayContent = () => (
    <>
      {fieldDefinitions
        .filter((item) => !getHideRowStatus(item))
        .map((item, index) => (
          <DisplayCardViewItemView key={`item-${index}`}>
            {item.customInfoComponent ? (
              <>{item.customInfoComponent}</>
            ) : (
              renderInfoItem(item, index)
            )}
          </DisplayCardViewItemView>
        ))}
      <div style={{ paddingBottom: '8px' }} />
    </>
  )

  const renderContent = () => {
    if (isNotAllowed) {
      return (
        <EmptyCardContent
          size={notAllowedContent?.size ?? 'Spot'}
          title={notAllowedContent?.title || t('components.cards.not-allowed.title')}
          subtitle={notAllowedContent?.subtitle || t('components.cards.not-allowed.subtitle')}
          illustrationName={notAllowedContent?.hasIllustration ? 'UnableToLoad' : undefined}
        />
      )
    }
    if (isError) {
      return (
        <EmptyCardContent
          size={errorContent?.size ?? 'Spot'}
          title={
            conflictErrorTitle !== ''
              ? conflictErrorTitle
              : errorContent?.title || t('components.cards.error.title')
          }
          subtitle={
            conflictErrorText !== ''
              ? conflictErrorText
              : errorContent?.subtitle || t('components.cards.error.subtitle')
          }
          illustrationName={errorContent?.hasIllustration ? 'UnableToLoad' : undefined}
        />
      )
    }
    if ((isEmpty || getHiddenRowCount() === fieldDefinitions.length) && !isLoading) {
      return (
        <EmptyCardContent
          size={emptyContent?.size ?? 'Spot'}
          title={emptyContent?.title || t('components.cards.empty.title')}
          subtitle={emptyContent?.subtitle || t('components.cards.empty.subtitle')}
          illustrationName={emptyContent?.hasIllustration ? 'NoData' : undefined}
        />
      )
    }
    return (
      <LoadingStateWrapper
        isLoading={!!isLoading}
        isError={false} // Error handling by DisplayAndEditCard (display MessageBox)
        renderContent={renderDisplayContent}
        loadingSize={BusyIndicatorSize.Medium}
        loadingClassName={styles.displayCardLoadingStateMargin}
      />
    )
  }

  return renderContent()
}

DisplayCardView.propTypes = propTypes

export default DisplayCardView
