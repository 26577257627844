import { z } from 'zod'

export const valueHelpEntrySchema = z.object({
  id: z.string(),
  name: z.string(),
})

export const valueHelpSchema = z.object({
  items: valueHelpEntrySchema.array(),
})
