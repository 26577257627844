import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-reporting-summary/shared/DecisionPaperEmptyCardContent'
import DecisionPaperCollapsibleRichTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-reporting-summary/shared/rich-text/DecisionPaperCollapsibleRichTextView'

const ArrearsReportingSummary = ({ tileId }) => {
  const { data: { latestVersion: { content: currentContent = '' } = {} } = {} } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId],
  )

  const { t: tWithPrefix } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.arrears-reporting-summary.components',
  })

  if (currentContent !== '') {
    return <DecisionPaperCollapsibleRichTextView text={currentContent} t={tWithPrefix} />
  }
  return (
    <EmptyCardContent
      title={tWithPrefix('cards.empty.title')}
      subtitle={tWithPrefix('cards.empty.subtitle')}
    />
  )
}

ArrearsReportingSummary.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default ArrearsReportingSummary
