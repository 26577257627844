import find from 'lodash.find'
import isNil from 'lodash.isnil'
import maxBy from 'lodash.maxby'

const mapFinancialRatioValue = (dealUuid, responsesData, rowCode) => {
  let displayValue = null

  const kpiValues = find(
    responsesData,
    ({ data }) => data?.referenceEntityId === dealUuid,
  )?.data?.kpis?.find(({ parents, code }) => !parents?.length && code === rowCode)

  displayValue = maxBy(kpiValues?.values, ({ keyDate }) => keyDate)

  if (kpiValues?.adjustments?.length) {
    const today = new Date()
    const adjustedDisplayValue = kpiValues?.adjustments?.find((adjustment) => {
      const validFrom = new Date(adjustment.validFrom)
      const validTo = new Date(adjustment.validTo)
      return today >= validFrom && today <= validTo
    })
    if (!isNil(adjustedDisplayValue)) {
      displayValue = adjustedDisplayValue
    }
  }

  return displayValue?.value && displayValue.value / 100
}

export default mapFinancialRatioValue
