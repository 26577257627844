import isEmpty from 'lodash.isempty'
import requirementRowHasError from 'redux/slices/conditions/actions/condition-creation/requirementRowHasError'

const conditionPossibleDocumentsChanged = (
  state,
  {
    payload: {
      conditionIndex,
      possibleDocumentTypes: newPossibleDocumentTypes,
      fallbackDocumentTypeText,
    },
  },
) => {
  if (!state.conditions[conditionIndex]) {
    return
  }
  state.conditions[conditionIndex].possibleDocumentTypes = newPossibleDocumentTypes
  state.conditions[conditionIndex].requirements.forEach(({ documentType }, requirementIndex) => {
    if (!documentType || documentType === '' || documentType === fallbackDocumentTypeText) {
      return
    }
    if (newPossibleDocumentTypes.includes(documentType)) {
      delete state.errorRows[conditionIndex]?.[requirementIndex]?.documentType
      if (isEmpty(state.errorRows[conditionIndex]?.[requirementIndex])) {
        delete state.errorRows[conditionIndex]?.[requirementIndex]
      }
      if (isEmpty(state.errorRows[conditionIndex])) {
        delete state.errorRows[conditionIndex]
      }
      return
    }
    requirementRowHasError(state, {
      payload: {
        conditionIndex,
        requirementIndex,
        field: 'documentType',
        error: { warning: true },
      },
    })
  })
}

export default conditionPossibleDocumentsChanged
