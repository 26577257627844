import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useSyndicationIntention from 'hooks/services/deals/syndication/useSyndicationIntention'
import useSyndicationStatement from 'hooks/services/deals/syndication/useSyndicationStatement'
import useDeal from 'hooks/services/deals/useDeal'

const useLoanMarketStatement = ({ entityRef: { entityId: dealUuid } }, tileId, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
    errors: dealUuidErrors,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    data: dealData,
    isFetching: isDealFetching,
    isError: isDealError,
    error: dealError,
  } = useDeal(dealUuidByTileCode)

  const {
    data: syndicationIntentionData,
    isFetching: isSyndicationIntentionFetching,
    isError: isSyndicationIntentionError,
    error: syndicationIntentionError,
  } = useSyndicationIntention(dealUuidByTileCode)

  const {
    data: syndicationStatementData,
    isFetching: isSyndicationStatementFetching,
    isError: isSyndicationStatementError,
    error: syndicationStatementError,
  } = useSyndicationStatement(dealUuidByTileCode)

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isDealUuidFetching,
      isDealFetching,
      isSyndicationIntentionFetching,
      isSyndicationStatementFetching,
    ],
    errorValues: [
      isDealUuidError,
      isDealError,
      isSyndicationIntentionError,
      isSyndicationStatementError,
    ],
    errorDetails: [
      ...dealUuidErrors,
      dealError,
      syndicationIntentionError,
      syndicationStatementError,
    ],
    tileId,
  })

  const sourcePathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?dataSource=newBusiness&working-version=true' : ''),
    [isWorkingVersionDataTile],
  )

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        syndicationIntentionData,
        syndicationStatementData,
        sourceRender: {
          dealDisplayId: dealData?.dealId,
          sourcePathSuffix,
        },
      },
    }
  }, [
    dealData?.dealId,
    error,
    isSomeValueError,
    isSomeValueLoading,
    sourcePathSuffix,
    syndicationIntentionData,
    syndicationStatementData,
  ])
}

export default useLoanMarketStatement
