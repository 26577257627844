import { useContext, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useRoutes } from 'react-router-dom'
import PropertyPortfolioOverview from 'components/domains/properties/portfolio/overview/PropertyPortfolioOverview'
import PropertyPortfolioRentRoll from 'components/domains/properties/portfolio/rent-roll/PropertyPortfolioRentRoll'
import PropertyPortfolioValuationRequests from 'components/domains/properties/portfolio/valuation-requests/PropertyPortfolioValuationRequests'
import PropertyRentRollWorkingVersion from 'components/domains/properties/rent-roll/working-version/PropertyRentRollWorkingVersion'
import Header from 'components/ui/page/Header'
import Page from 'components/ui/page/Page'
import { useMultiPropertyViewByPropertyUuids } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuids'
import PropertyPage from 'routes/properties/PropertyPage'
import { PropertyPortfolioContext } from 'routes/properties/portfolio/PropertyPortfolioContext'
import {
  PORTFOLIO_VIEW_QUERY_PARAM,
  multiPropertyViewDefault,
} from 'routes/properties/portfolio/PropertyPortfolioWrapper'

const PropertyPortfolioDetails = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.properties',
  })
  const { properties } = useContext(PropertyPortfolioContext)
  const propertyUuids = properties?.map((property) => property.uuid)

  const { data: multiPropertyViewReturned } = useMultiPropertyViewByPropertyUuids(propertyUuids)

  const multiPropertyView = useMemo(
    () => multiPropertyViewReturned?.data ?? multiPropertyViewDefault,
    [multiPropertyViewReturned?.data],
  )

  const renderRoute = useCallback(
    (title, content, breadcrumbs) => (
      <Page
        isLoading={!properties}
        isError={false}
        renderHeaderTitle={() => <Header title={t(title)} breadcrumbs={breadcrumbs} />}
        renderContent={() => content}
      />
    ),
    [properties, t],
  )

  const generateBreadcrumb = useCallback(
    (text, href = undefined) => ({
      text: text,
      href: href,
    }),
    [],
  )

  const generateBreadcrumbs = (additionalBreadcrumbs = []) => {
    if (!multiPropertyViewReturned) return []

    const searchParams = new URLSearchParams()
    searchParams.append(PORTFOLIO_VIEW_QUERY_PARAM, multiPropertyView.uuid)
    const qs = searchParams.toString()
    const propertiesLink = '/properties'
    const portfolioBaseLink = `${propertiesLink}/portfolio`
    return [
      { text: t('title'), href: propertiesLink },
      {
        text: t('portfolio.title'),
        href: `${portfolioBaseLink}/overview?${qs}`,
      },
      ...additionalBreadcrumbs.map((breadcrumb) =>
        breadcrumb.href
          ? {
              ...breadcrumb,
              href: `${portfolioBaseLink}${breadcrumb.href}?${qs}`,
            }
          : breadcrumb,
      ),
    ]
  }

  const overviewBreadCrumbs = [
    { text: t('title'), href: '/properties' },
    { text: t('portfolio.title') },
  ]
  return useRoutes([
    {
      path: 'overview',
      element: renderRoute(
        'portfolio.overview.title',
        <PropertyPortfolioOverview properties={properties} />,
        overviewBreadCrumbs,
      ),
    },
    {
      path: 'rent-roll',
      element: (
        <PropertyPortfolioRentRoll
          header={
            <Header
              title={t('portfolio.rent-roll.title')}
              breadcrumbs={generateBreadcrumbs([
                generateBreadcrumb(t('portfolio.rent-roll.title')),
              ])}
            />
          }
        />
      ),
    },
    {
      path: 'rent-roll/working-version',
      element: (
        <PropertyPage
          pageTitle={t('portfolio.title')}
          breadcrumbs={generateBreadcrumbs([
            generateBreadcrumb(t('portfolio.rent-roll.title'), '/rent-roll'),
            generateBreadcrumb(t('portfolio.rent-roll.working-version')),
          ])}
        >
          <PropertyRentRollWorkingVersion propertyUuids={propertyUuids} />
        </PropertyPage>
      ),
    },
    {
      path: 'valuation-requests',
      element: (
        <PropertyPortfolioValuationRequests
          propertyUuids={propertyUuids}
          header={
            <Header
              title={t('portfolio.valuation-requests.title')}
              breadcrumbs={generateBreadcrumbs([
                generateBreadcrumb(t('portfolio.valuation-requests.title')),
              ])}
            />
          }
        />
      ),
    },
  ])
}

export default PropertyPortfolioDetails
