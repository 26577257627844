import { ValueState } from '@fioneer/ui5-webcomponents-react'
import { difference, find, isEmpty, uniq, isNil } from 'lodash'
import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  brandingFranchisePartnerExpandedContentKey,
  tenantExpandedContentKey,
} from 'components/domains/properties/getPropertyRentRollWorkingVersionReferenceData'
import { getRentalUnitWorkingVersionKey } from 'hooks/services/properties/rent-roll/working-version/excel-upload/useValidateRentalUnitIds'
import { useCwpBusinessPartnerRoles } from 'hooks/services/properties/rent-roll/working-version/useCwpBusinessPartnerRoles'
import useGetAllRentRollWorkingVersionOptions from 'hooks/services/properties/rent-roll/working-version/useGetAllRentRollWorkingVersionOptions'
import useMultiplePropertiesSegments from 'hooks/services/properties/segments/useMultiplePropertiesSegments'
import { useRentRoll } from 'hooks/services/properties/useRentRoll'

const cmsOccStatus = {
  self: '000001',
  let: '000002',
  vacant: '000004',
}

const CMS_MAX_CHARS_LENGTH = 160

function isValidIsoDate(dateString) {
  const regex = /^\d{4}-\d{2}-\d{2}$/
  return regex.test(dateString)
}

const valueIsNumber = (value) => !isNaN(value)
const valueExist = (value) => !isNil(value)
const valueIsNotEmptyString = (value) => valueExist(value) && value !== ''
const valueIsValidString = (value) => isNaN(value) && value !== ''
const valueExistAndValidString = (value) => valueExist(value) && valueIsValidString(value)
const valueExistAndValidIsoDate = (value) =>
  valueExistAndValidString(value) && isValidIsoDate(value)
const valueNotExist = (value) => isNil(value)
const optionValueExist = (value, options) =>
  valueExist(value) &&
  !!find(options, (option) => option.key === value) &&
  !['Unknown', '-', ''].includes(value)
const valueIsSmallerOrEqual = (value, compValue) => value <= compValue
const valueIsGreaterOrEqual = (value, compValue) => value >= compValue
const valueIsGreater = (value, compValue) => value > compValue
const valueIsSmaller = (value, compValue) => value < compValue

const occupancyStatusExistAndVacant = (occStatus) =>
  valueExist(occStatus) && occStatus === cmsOccStatus.vacant
const occupancyStatusExistAndLet = (occStatus) =>
  valueExist(occStatus) && occStatus === cmsOccStatus.let
const occupancyStatusExistAndSelf = (occStatus) =>
  valueExist(occStatus) && occStatus === cmsOccStatus.self

const compareDateValuesWhenOccupancyStatusLet = (value, compValue, comparator, occupancyStatus) => {
  if (
    occupancyStatusExistAndLet(occupancyStatus) &&
    valueExistAndValidIsoDate(value) &&
    valueExistAndValidIsoDate(compValue)
  ) {
    switch (comparator) {
      case '<':
        return valueIsSmaller(value, compValue)
      case '<=':
        return valueIsSmallerOrEqual(value, compValue)
      case '>':
        return valueIsGreater(value, compValue)
      case '>=':
        return valueIsGreaterOrEqual(value, compValue)
    }
  } else return true
}

const internallyValidFormattedNumberInput = (isInternallyValid) =>
  isInternallyValid === undefined || isInternallyValid

const BUSINESS_PARTNER_INACTIVE = 'INACTIVE'

const getValidationRules = (
  dateInValidFormat,
  t,
  { areaMeasureUnitCodes, occupancyStatusCodes, currencyCodes, segmentUsageTypeCodes, properties },
  currentRentRollKeyDate,
  segments,
  businessPartnerRoles,
) => ({
  property_uuid: [
    {
      name: t('property-not-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (properties.length !== 0) {
          return valueExist(rowValues['property_uuid'].value) && optionValueExist(value, properties)
        } else return valueExist(rowValues['property_uuid'].value)
      },
    },
  ],
  tenant_id: [
    {
      name: t('no-tenant-role'),
      valueState: ValueState.Warning,
      validate: ({ value, rowValues }) => {
        const businessPartner = rowValues[tenantExpandedContentKey]?.value
        if (valueExist(value) && valueExist(businessPartner)) {
          const backendRoleIds = businessPartner.roles
            ?.map((role) => role.backEndRoles.map((backendRole) => backendRole.id))
            .flat()
          const tenantRoles = businessPartnerRoles.filter((role) => role.role === 'tenant')
          return backendRoleIds?.some((backendRoleId) =>
            tenantRoles.some((role) => role.businessPartnerRoleCode === backendRoleId),
          )
        } else return true
      },
    },
    {
      name: t('bp-inactive'),
      valueState: ValueState.Warning,
      validate: ({ value, rowValues }) => {
        const businessPartner = rowValues[tenantExpandedContentKey]?.value
        if (valueExist(value) && valueExist(businessPartner)) {
          return businessPartner.status !== BUSINESS_PARTNER_INACTIVE
        } else return true
      },
    },
  ],
  branding_franchise_partner_id: [
    {
      name: t('no-branding-franchise-role'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        const businessPartner = rowValues[brandingFranchisePartnerExpandedContentKey]?.value
        if (valueExist(value) && valueExist(businessPartner)) {
          const backendRoleIds = businessPartner.roles
            ?.map((role) => role.backEndRoles.map((backendRole) => backendRole.id))
            .flat()
          const brandingFranchiseRoles = businessPartnerRoles.filter(
            (role) => role.role === 'branding_franchise_partner',
          )
          return backendRoleIds?.some((backendRoleId) =>
            brandingFranchiseRoles.some((role) => role.businessPartnerRoleCode === backendRoleId),
          )
        } else return true
      },
    },
    {
      name: t('bp-inactive'),
      valueState: ValueState.Warning,
      validate: ({ value, rowValues }) => {
        const businessPartner = rowValues[brandingFranchisePartnerExpandedContentKey]?.value
        if (valueExist(value) && valueExist(businessPartner)) {
          return businessPartner.status !== BUSINESS_PARTNER_INACTIVE
        } else return true
      },
    },
  ],
  current_net_rent: [
    {
      name: t('rent-curr-zero'),
      valueState: ValueState.Warning,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndLet(rowValues['occupancy_status_id'].value)) {
          return valueExist(value) && parseFloat(value) !== 0
        } else return true
      },
    },
    {
      name: t('rent-curr-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndLet(rowValues['occupancy_status_id'].value)) {
          return valueExist(value) && valueIsGreaterOrEqual(parseFloat(value), 0)
        } else return true
      },
    },
    {
      name: t('rent-curr-not-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndVacant(rowValues['occupancy_status_id'].value)) {
          return !valueExist(value)
        } else return true
      },
    },
    {
      name: t('rent-curr-not-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndSelf(rowValues['occupancy_status_id'].value)) {
          return !valueExist(value) || value === 0
        } else return true
      },
    },
    {
      name: t('rent-curr-is-valid-number'),
      valueState: ValueState.Error,
      validate: ({ rowValues, isInternallyValid }) => {
        if (occupancyStatusExistAndLet(rowValues['occupancy_status_id'].value)) {
          return internallyValidFormattedNumberInput(isInternallyValid)
        } else return true
      },
    },
  ],
  lease_break_date: [
    {
      name: t('lease-break-filled'),
      valueState: ValueState.Warning,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndLet(rowValues['occupancy_status_id'].value)) {
          return valueExist(value)
        } else return true
      },
    },
    {
      name: t('lease-break-valid-date-format'),
      valueState: ValueState.Error,
      validate: ({ value }) => {
        if (valueExistAndValidString(value)) {
          return dateInValidFormat(value)
        } else return true
      },
    },
    {
      name: t('lease-break-not-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndVacant(rowValues['occupancy_status_id'].value)) {
          return !valueExist(value)
        } else return true
      },
    },
    {
      name: t('lease-break-not-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndSelf(rowValues['occupancy_status_id'].value)) {
          return !valueExist(value)
        } else return true
      },
    },
    {
      name: t('lease-break-not-after-expiry'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) =>
        compareDateValuesWhenOccupancyStatusLet(
          value,
          rowValues['lease_expiry_date'].value,
          '<=',
          rowValues['occupancy_status_id'].value,
        ),
    },
    {
      name: t('lease-break-after-start'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) =>
        compareDateValuesWhenOccupancyStatusLet(
          rowValues['lease_start_date'].value,
          value,
          '<',
          rowValues['occupancy_status_id'].value,
        ),
    },
  ],
  lease_expiry_date: [
    {
      name: t('lease-expiry-valid-date-format'),
      valueState: ValueState.Error,
      validate: ({ value }) => {
        if (valueExistAndValidString(value)) {
          return dateInValidFormat(value)
        } else return true
      },
    },
    {
      name: t('lease-expiry-not-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndVacant(rowValues['occupancy_status_id'].value)) {
          return !valueExist(value)
        } else return true
      },
    },
    {
      name: t('lease-expiry-not-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndSelf(rowValues['occupancy_status_id'].value)) {
          return !valueExist(value)
        } else return true
      },
    },
    {
      name: t('lease-expiry-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (
          occupancyStatusExistAndLet(rowValues['occupancy_status_id'].value) &&
          !rowValues['no_lease_expiry']?.value
        ) {
          return valueExist(value)
        } else return true
      },
    },
    {
      name: t('lease-expiry-not-filled-when-no-lease-expiry'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (
          occupancyStatusExistAndLet(rowValues['occupancy_status_id'].value) &&
          rowValues['no_lease_expiry']?.value
        ) {
          return valueNotExist(value)
        }
        return true
      },
    },
    {
      name: t('lease-expiry-after-lease-start'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) =>
        compareDateValuesWhenOccupancyStatusLet(
          value,
          rowValues['lease_start_date'].value,
          '>',
          rowValues['occupancy_status_id'].value,
        ),
    },
    {
      name: t('lease-expiry-not-prior-key-date'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues, rentRollValues: { header } }) =>
        compareDateValuesWhenOccupancyStatusLet(
          value,
          header['key_date'].value,
          '>=',
          rowValues['occupancy_status_id'].value,
        ),
    },
  ],
  lease_start_date: [
    {
      name: t('lease-start-valid-date-format'),
      valueState: ValueState.Error,
      validate: ({ value }) => {
        if (valueExistAndValidString(value)) {
          return dateInValidFormat(value)
        } else return true
      },
    },
    {
      name: t('lease-start-not-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndVacant(rowValues['occupancy_status_id'].value)) {
          return !valueExist(value)
        } else return true
      },
    },
    {
      name: t('lease-start-not-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndSelf(rowValues['occupancy_status_id'].value)) {
          return !valueExist(value)
        } else return true
      },
    },
    {
      name: t('lease-start-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndLet(rowValues['occupancy_status_id'].value)) {
          return valueExist(value)
        } else return true
      },
    },
    {
      name: t('lease-start-prior-lease-expiry'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (
          occupancyStatusExistAndLet(rowValues['occupancy_status_id'].value) &&
          valueExistAndValidIsoDate(value) &&
          valueExistAndValidIsoDate(rowValues['lease_expiry_date'].value)
        ) {
          return valueIsSmaller(value, rowValues['lease_expiry_date'].value)
        } else return true
      },
    },
    {
      name: t('lease-start-not-prior-key-date'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues, rentRollValues: { header } }) => {
        if (
          occupancyStatusExistAndLet(rowValues['occupancy_status_id'].value) &&
          valueExistAndValidIsoDate(value) &&
          valueExistAndValidIsoDate(header['key_date'].value)
        ) {
          return valueIsSmallerOrEqual(value, header['key_date'].value)
        } else return true
      },
    },
  ],
  number_of_units: [
    {
      name: t('number-of-units-is-valid-number'),
      valueState: ValueState.Error,
      validate: ({ value, isInternallyValid }) =>
        !valueExist(value) || internallyValidFormattedNumberInput(isInternallyValid),
    },
  ],
  occupancy_status_id: [
    {
      name: t('occupancy-filled'),
      valueState: ValueState.Error,
      validate: ({ value }) => optionValueExist(value, occupancyStatusCodes),
    },
  ],
  rent_arrears: [
    {
      name: t('rent-arrear-is-valid-number'),
      valueState: ValueState.Error,
      validate: ({ value, isInternallyValid }) =>
        !valueExist(value) || internallyValidFormattedNumberInput(isInternallyValid),
    },
  ],
  rent_contracted_year: [
    {
      name: t('rent-contr-zero'),
      valueState: ValueState.Warning,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndLet(rowValues['occupancy_status_id'].value)) {
          return valueExist(value) && parseFloat(value) !== 0
        } else return true
      },
    },
    {
      name: t('rent-contr-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndLet(rowValues['occupancy_status_id'].value)) {
          return valueExist(value) && valueIsGreaterOrEqual(parseFloat(value), 0)
        } else return true
      },
    },
    {
      name: t('rent-contr-not-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndSelf(rowValues['occupancy_status_id'].value)) {
          return !valueExist(value) || value === 0
        } else return true
      },
    },
    {
      name: t('rent-contr-not-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndVacant(rowValues['occupancy_status_id'].value)) {
          return !valueExist(value)
        } else return true
      },
    },
    {
      name: t('rent-contr-is-valid-number'),
      valueState: ValueState.Error,
      validate: ({ rowValues, isInternallyValid }) => {
        if (occupancyStatusExistAndLet(rowValues['occupancy_status_id'].value)) {
          return internallyValidFormattedNumberInput(isInternallyValid)
        } else return true
      },
    },
  ],
  rent_start_date: [
    {
      name: t('rent-start-valid-date-format'),
      valueState: ValueState.Error,
      validate: ({ value }) => {
        if (valueExist(value)) {
          return dateInValidFormat(value)
        } else return true
      },
    },
    {
      name: t('rent-start-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndLet(rowValues['occupancy_status_id'].value)) {
          return valueExist(value)
        } else return true
      },
    },
    {
      name: t('rent-start-not-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndVacant(rowValues['occupancy_status_id'].value)) {
          return !valueExist(value)
        } else return true
      },
    },
    {
      name: t('rent-start-not-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndSelf(rowValues['occupancy_status_id'].value)) {
          return !valueExist(value)
        } else return true
      },
    },
    {
      name: t('rent-start-not-prior-lease-start'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (
          valueExistAndValidIsoDate(value) &&
          valueExistAndValidIsoDate(rowValues['lease_start_date'].value) &&
          occupancyStatusExistAndLet(rowValues['occupancy_status_id'].value)
        ) {
          return valueIsGreaterOrEqual(value, rowValues['lease_start_date'].value)
        } else return true
      },
    },
    {
      name: t('rent-start-prior-lease-expiry'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (
          valueExistAndValidIsoDate(value) &&
          valueExistAndValidIsoDate(rowValues['lease_expiry_date'].value) &&
          occupancyStatusExistAndLet(rowValues['occupancy_status_id'].value)
        ) {
          return valueIsSmaller(value, rowValues['lease_expiry_date'].value)
        } else return true
      },
    },
  ],
  rental_unit_area: [
    {
      name: t('area-not-zero'),
      valueState: ValueState.Warning,
      validate: ({ value }) => valueExist(value) && parseInt(value) !== 0,
    },
    {
      name: t('area-filled'),
      valueState: ValueState.Error,
      validate: ({ value }) => valueExist(value) && valueIsNumber(value),
    },
    {
      name: t('area-number'),
      valueState: ValueState.Error,
      validate: ({ value }) => {
        if (valueExist(value)) {
          return valueIsGreaterOrEqual(parseInt(value), 0)
        } else return true
      },
    },
    {
      name: t('area-is-valid-number'),
      valueState: ValueState.Error,
      validate: ({ value, isInternallyValid }) =>
        !valueExist(value) || internallyValidFormattedNumberInput(isInternallyValid),
    },
  ],
  rental_unit_area_uom_id: [
    {
      name: t('uom-filled'),
      valueState: ValueState.Error,
      validate: ({ value }) => optionValueExist(value, areaMeasureUnitCodes),
    },
    {
      name: t('uom-same-as-segment'),
      valueState: ValueState.Warning,
      validate: ({ value, rowValues }) => {
        if (
          optionValueExist(value, areaMeasureUnitCodes) &&
          valueExist(rowValues['segment_uuid'].value) &&
          valueExist(rowValues['segment_usage_type_id'].value)
        ) {
          const segment = segments.find(
            (s) =>
              s.usage_type_code === rowValues['segment_usage_type_id'].value &&
              s.uuid === rowValues['segment_uuid'].value,
          )
          if (segment) {
            return segment.area_measure_unit_code === value || value === 'PC' || value === 'ST'
          } else {
            return true
          }
        } else return true
      },
    },
    {
      name: t('uom-equal'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues, rentRollValues: { rental_units: rentalUnits } }) => {
        if (valueExist(value)) {
          const uniqUoms = uniq(
            rentalUnits
              .filter((ru) => ru['property_uuid']?.value === rowValues['property_uuid']?.value)
              .map((ru) => ru['rental_unit_area_uom_id'].value),
          ).filter(Boolean)
          return (
            !uniqUoms.length || difference(uniq([...uniqUoms, value]), ['PC', 'ST']).length <= 1
          )
        } else return true
      },
    },
  ],
  rental_unit_currency_id: [
    {
      name: t('curr-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndLet(rowValues['occupancy_status_id'].value)) {
          return optionValueExist(value, currencyCodes)
        } else return true
      },
    },
    {
      name: t('curr-not-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndSelf(rowValues['occupancy_status_id'].value)) {
          return !valueExist(value)
        } else return true
      },
    },
    {
      name: t('curr-not-filled'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues }) => {
        if (occupancyStatusExistAndVacant(rowValues['occupancy_status_id'].value)) {
          return !valueExist(value)
        } else return true
      },
    },
  ],
  rental_unit_name: [
    {
      name: t('ru-name'),
      valueState: ValueState.Error,
      validate: ({ value }) => {
        if (valueExist(value)) {
          return value.length <= CMS_MAX_CHARS_LENGTH
        } else return true
      },
    },
    {
      name: t('same-rental-unit-identifier'),
      valueState: ValueState.Error,
      validate: ({ value, rowValues, rentRollValues: { rental_units: rentalUnits } }) => {
        if (valueExist(rowValues['segment_uuid']?.value)) {
          const key = getRentalUnitWorkingVersionKey(value, rowValues['segment_uuid'].value)
          const unitsWithSameId = uniq(
            rentalUnits.filter(
              (ru) =>
                getRentalUnitWorkingVersionKey(
                  ru['rental_unit_name']?.value,
                  ru['segment_uuid']?.value,
                ) === key,
            ),
          ).filter(Boolean)
          return !unitsWithSameId || unitsWithSameId.length <= 1
        } else return true
      },
    },
  ],
  key_date: [
    {
      name: t('key-date-valid-date-format'),
      valueState: ValueState.Error,
      validate: ({ value }) => {
        if (valueExistAndValidString(value)) {
          return dateInValidFormat(value)
        } else return true
      },
    },
    {
      name: t('key-date-filled'),
      valueState: ValueState.Error,
      validate: ({ value }) => valueExistAndValidString(value),
    },
    {
      name: t('key-date-not-after-today'),
      valueState: ValueState.Error,
      validate: ({ value }) => {
        if (valueExistAndValidIsoDate(value)) {
          return value <= DateTime.now().toISODate()
        } else return true
      },
    },
    {
      name: t('key-date-not-after-curr-rent-roll-key-date'),
      valueState: ValueState.Error,
      validate: ({ value }) => {
        if (valueExistAndValidIsoDate(value) && valueExistAndValidIsoDate(currentRentRollKeyDate)) {
          return currentRentRollKeyDate <= value
        } else return true
      },
    },
  ],
  tenant_name: [
    {
      name: t('tenant-filled'),
      valueState: ValueState.Error,
      validate: ({ rowValues }) => {
        if (
          occupancyStatusExistAndLet(rowValues['occupancy_status_id'].value) &&
          !rowValues['anonymous_tenant'].value
        ) {
          return (
            valueExist(rowValues['tenant_id'].value) && valueExist(rowValues['tenant_name'].value)
          )
        } else return true
      },
    },
    {
      name: t('tenant-filled'),
      valueState: ValueState.Error,
      validate: ({ rowValues }) => {
        if (
          occupancyStatusExistAndSelf(rowValues['occupancy_status_id'].value) &&
          !rowValues['anonymous_tenant'].value
        ) {
          return (
            valueExist(rowValues['tenant_id'].value) && valueExist(rowValues['tenant_name'].value)
          )
        } else return true
      },
    },
    {
      name: t('tenant-not-filled'),
      valueState: ValueState.Error,
      validate: ({ rowValues }) => {
        if (
          occupancyStatusExistAndVacant(rowValues['occupancy_status_id'].value) ||
          rowValues['anonymous_tenant'].value
        ) {
          return (
            !valueExist(rowValues['tenant_id'].value) && !valueExist(rowValues['tenant_name'].value)
          )
        } else return true
      },
    },
  ],
  segment_usage_type_id: [
    {
      name: t('segment-usage-type-filled'),
      valueState: ValueState.Error,
      validate: ({ value }) => optionValueExist(value, segmentUsageTypeCodes),
    },
  ],
  segment_uuid: [
    {
      name: t('segment-uuid-filled'),
      valueState: ValueState.Error,
      validate: ({ value }) => valueExist(value),
    },
  ],
  description: [
    {
      name: t('description-filled'),
      valueState: ValueState.Error,
      validate: ({ value }) => valueIsNotEmptyString(value),
    },
  ],
  rental_units_length: [
    {
      name: t('rental-units-exist'),
      valueState: ValueState.Error,
      validate: ({ value }) => value > 0,
    },
  ],
})

export const useValidateRentRollWorkingVersion = (rentRollValues) => {
  const dateInValidFormat = (dateStr) => isValidIsoDate(dateStr)
  const { t: tValidation } = useTranslation('translation', {
    keyPrefix: 'pages.rent-roll-working-version.validation',
  })

  const allOptions = useGetAllRentRollWorkingVersionOptions()
  const businessPartnerRolesResponse = useCwpBusinessPartnerRoles()
  const businessPartnerRoles = useMemo(
    () => businessPartnerRolesResponse?.data?.configSapBusinessPartnerRoles ?? [],
    [businessPartnerRolesResponse?.data?.configSapBusinessPartnerRoles],
  )

  const propertyUuids = rentRollValues.property_uuids
  const propertyUuid = propertyUuids?.length === 1 ? propertyUuids[0] : undefined
  const {
    data: rentRoll,
    isLoading: rentRollIsLoading,
    isError: rentRollIsError,
  } = useRentRoll(propertyUuid, { enabled: !!propertyUuid })

  const { data: segmentsReturned } = useMultiplePropertiesSegments(propertyUuids)

  const currentRentRollKeyDate = useMemo(
    () => (rentRollIsLoading || rentRollIsError || isEmpty(rentRoll) ? '' : rentRoll.key_date),
    [rentRoll, rentRollIsError, rentRollIsLoading],
  )

  const segments = useMemo(() => segmentsReturned?.segments ?? [], [segmentsReturned?.segments])

  const validationRules = useMemo(
    () =>
      getValidationRules(
        dateInValidFormat,
        tValidation,
        allOptions,
        currentRentRollKeyDate,
        segments,
        businessPartnerRoles,
      ),
    [allOptions, businessPartnerRoles, currentRentRollKeyDate, segments, tValidation],
  )
  const validationFields = Object.keys(validationRules)

  const validateField = useCallback(
    (fieldName, row, initRentRollValues, rules) => {
      const valRulesField = rules[fieldName]
      const valRulesFieldNotExist = !valRulesField?.length
      const field = row[fieldName]
      let validatedField = { ...field, valueState: ValueState.None, valueStateMessage: '' }

      if (valRulesFieldNotExist) return validatedField

      valRulesField.map((valRule) => {
        const validationValues = {
          value: field.value,
          isInternallyValid: field.isInternallyValid,
          rowValues: row,
          rentRollValues: { ...rentRollValues, ...initRentRollValues },
        }

        const currMessage = validatedField.valueStateMessage

        const isNotValid = !valRule.validate(validationValues)

        if (isNotValid) {
          validatedField = {
            ...validatedField,
            valueState: valRule.valueState,
            valueStateMessage: currMessage ? currMessage.concat(' ', valRule.name) : valRule.name,
          }
        }
      })
      return validatedField
    },
    [rentRollValues],
  )

  const validateRow = useCallback(
    (row, initRentRollValues = {}) => {
      const validatedRow = {}
      Object.keys(row).forEach((fieldName) => {
        if (validationFields.includes(fieldName)) {
          validatedRow[fieldName] = validateField(
            fieldName,
            row,
            initRentRollValues,
            validationRules,
          )
        } else {
          validatedRow[fieldName] = row[fieldName]
        }
      })

      return validatedRow
    },
    [validateField, validationFields, validationRules],
  )

  const validateHeader = useCallback(
    (headerValues, initRentRollValues = {}) => validateRow(headerValues, initRentRollValues),
    [validateRow],
  )

  const validateRentalUnit = useCallback(
    (rentalUnit, initRentRollValues = {}) => validateRow(rentalUnit, initRentRollValues),
    [validateRow],
  )

  const validateAuxiliaryFields = useCallback(
    (auxiliaryFieldValues, initRentRollValues = {}) =>
      validateRow(auxiliaryFieldValues, initRentRollValues),
    [validateRow],
  )

  const validateRentalUnits = useCallback(
    (rentalUnits, initRentRollValues = {}) =>
      rentalUnits.map((rentalUnit) => validateRentalUnit(rentalUnit, initRentRollValues)),
    [validateRentalUnit],
  )

  const validateRentRoll = useCallback(
    (rentRollValuesToValidate, initRentRollValues = {}) => {
      const rentRollExist = rentRollValuesToValidate && Object.keys(rentRollValuesToValidate).length
      if (!rentRollExist) return rentRollValuesToValidate

      return {
        ...rentRollValuesToValidate,
        header: validateHeader(rentRollValuesToValidate.header, initRentRollValues),
        rental_units: validateRentalUnits(
          rentRollValuesToValidate.rental_units,
          initRentRollValues,
        ),
        auxiliary_fields: validateAuxiliaryFields(
          rentRollValuesToValidate.auxiliary_fields,
          initRentRollValues,
        ),
      }
    },
    [validateAuxiliaryFields, validateHeader, validateRentalUnits],
  )
  const getErrorsInRow = useCallback(
    (row) =>
      Object.keys(row)
        .map((fieldName) => row[fieldName].valueState === ValueState.Error)
        .filter(Boolean),
    [],
  )

  const getErrorsInHeader = useCallback((header) => getErrorsInRow(header), [getErrorsInRow])
  const getErrorsInRentalUnits = useCallback(
    (rentalUnits) => rentalUnits.map((rentalUnit) => getErrorsInRow(rentalUnit)).flat(),
    [getErrorsInRow],
  )

  const getErrorsExist = useCallback(() => {
    const rentRollExist = rentRollValues && Object.keys(rentRollValues).length
    if (!rentRollExist) return false

    const { header, rental_units: rentalUnits } = rentRollValues
    const errorsInHeader = getErrorsInHeader(header)
    const errorsInRentalUnits = getErrorsInRentalUnits(rentalUnits)

    return errorsInHeader.length > 0 || errorsInRentalUnits.length > 0
  }, [getErrorsInHeader, getErrorsInRentalUnits, rentRollValues])

  return useMemo(
    () => ({
      validateHeader,
      validateRentalUnit,
      validateRentalUnits,
      validateRentRoll,
      validateAuxiliaryFields,
      getErrorsExist,
    }),
    [
      getErrorsExist,
      validateAuxiliaryFields,
      validateHeader,
      validateRentRoll,
      validateRentalUnit,
      validateRentalUnits,
    ],
  )
}
