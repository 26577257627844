import { useInfiniteRequest } from 'hooks/services/baseService'
import { useInfiniteRequestsWithTotalMemo } from 'hooks/services/queryHelper'

const useIndividualEntityChangeLogs = (
  {
    entityType,
    entityId,
    relatedEntityTypes,
    pageIds,
    sorting: { field: sortField, direction: sortDirection } = {},
    pagination: { limit } = {},
  },
  options,
) => {
  const queryParams = new URLSearchParams()
  if (sortField) {
    queryParams.append('sort_field', sortField)
  }
  if (sortDirection) {
    queryParams.append('sort_direction', sortDirection)
  }
  if (relatedEntityTypes) {
    queryParams.append('related_entity_type', relatedEntityTypes.join(','))
  }
  if (pageIds) {
    queryParams.append('page_id', pageIds.join(','))
  }
  const response = useInfiniteRequest({
    path: `/traceability/change-logs/${entityType}/${entityId}/single`,
    queryParams: queryParams,
    translated: true,
    keys: ['traceability', 'change-logs', entityType, entityId],
    limit,
    options: {
      staleTime: 1,
      ...options,
    },
  })

  return useInfiniteRequestsWithTotalMemo(response, 'change_logs', 'pagination.total')
}

export default useIndividualEntityChangeLogs
