import isEmpty from 'lodash.isempty'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from 'components/domains/properties/overview/PropertyInsurancesCard.module.css'
import DisplayCardView, { hideOptions } from 'components/ui/card/DisplayCardView'
import { useCustomizableCurrencyFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'
import { useInsuranceTypes } from 'hooks/services/properties/insurances/useInsuranceTypes'
import { usePropertyInsurances } from 'hooks/services/properties/insurances/usePropertyInsurances'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyInsurancesCard = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property-overview.insurances' })
  const {
    property: { uuid: propertyUuid },
  } = useContext(PropertyContext)
  const {
    isError: isErrorInsurances,
    isLoading: isLoadingInsurances,
    data,
  } = usePropertyInsurances(propertyUuid)
  const insurances = useMemo(() => data?.insurances ?? [], [data?.insurances])
  const { data: insuranceTypes } = useInsuranceTypes()
  const getInsuranceName = useCallback(
    (insuranceTypeCode) => {
      const insuranceName = insuranceTypes?.insuranceTypes.find(
        (insurance) => insurance.insuranceType === insuranceTypeCode,
      ).insuranceName
      return insuranceName
    },
    [insuranceTypes],
  )

  const formatCurrency = useCustomizableCurrencyFormatter()
  const { format: formatDate } = useShortDateFormatter()

  const getCurrencyOrUndefined = useCallback(
    (value, currency) => (value && currency ? formatCurrency(value, currency) : undefined),
    [formatCurrency],
  )

  const { pathname } = useLocation()
  const navigate = useNavigate()
  const routeToRentRoll = `${pathname}/insurances`
  const handleHeaderClick = () => {
    navigate(routeToRentRoll)
  }

  const fieldDefinitions = useMemo(() => {
    const insuranceFieldDefinitions = []

    insurances.forEach((insurance, index) => {
      const isEmptyInsurance =
        isEmpty(insurance?.insuranceSumAmount) && isEmpty(insurance?.insuranceExpiryDate)

      const isLastElement = index === insurances.length - 1

      insuranceFieldDefinitions.push(
        {
          label: getInsuranceName(insurance?.insuranceType),
          name: 'insuranceType',
          value: getCurrencyOrUndefined(
            insurance?.insuranceSumAmount,
            insurance?.insuranceCurrency,
          ),
          hideOption: hideOptions.hideWhenEmpty,
        },
        {
          label: t('expiry-date'),
          name: 'insuranceExpiryDate',
          value: formatDate(insurance?.insuranceExpiryDate),
          hideOption: hideOptions.hideWhenEmpty,
        },
        !isLastElement && !isEmptyInsurance
          ? {
              customInfoComponent: <div className={styles.separator} />,
            }
          : { customInfoComponent: <></> },
      )
    })

    return insuranceFieldDefinitions
  }, [formatDate, getCurrencyOrUndefined, getInsuranceName, insurances, t])

  return (
    <DisplayCardView
      cardHeaderTitle={t('title')}
      onHeaderClick={handleHeaderClick}
      fieldDefinitions={fieldDefinitions}
      isError={isErrorInsurances}
      isLoading={isLoadingInsurances}
      isEmpty={isEmpty(data?.insurances)}
      isEditable={false}
      setEditMode={() => {}}
    />
  )
}

export default PropertyInsurancesCard
