export const questionKeys = [
  'Q01',
  'Q02',
  'Q03',
  'Q04',
  'Q05',
  'Q06',
  'Q07',
  'Q08',
  'Q09',
  'Q10',
  'Q11',
  'Q12',
  'Q13',
  'Q14',
  'Comment',
]

export const questionTypes = {
  booleanWithNotApplicable: ['Q05', 'Q06', 'Q07', 'Q08', 'Q09', 'Q13', 'Q14'],
  size: [],
  date: [],
  comment: ['Comment'],
  booleanWithoutNotApplicable: ['Q01', 'Q02', 'Q03', 'Q04', 'Q10', 'Q11', 'Q12'],
}
