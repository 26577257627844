import { useQueryClient } from '@tanstack/react-query'
import get from 'lodash.get'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { propertyMonitoringStatus } from 'api/property/monitoring/propertyMonitoringConfiguration'
import { isNotFoundError } from 'api/requests'
import PropertyMonitoringSettingsDialog from 'components/domains/properties/monitoring/settings/PropertyMonitoringSettingsDialog'
import PropertyMonitoringSettingsDialogView from 'components/domains/properties/monitoring/settings/dialog/PropertyMonitoringSettingsDialogView'
import ErrorDialog from 'components/ui/dialog/ErrorDialog'
import ErrorScreen from 'components/ui/screens/ErrorScreen'
import LoadingScreen from 'components/ui/screens/LoadingScreen'
import useLatestMatchingValuationForPropertyMonitoring from 'hooks/services/properties/monitoring/useLatestMatchingValuationForPropertyMonitoring'
import { usePropertyMonitoring } from 'hooks/services/properties/monitoring/usePropertyMonitoring'
import { usePropertyMonitoringCreation } from 'hooks/services/properties/monitoring/usePropertyMonitoringCreation'
import useUpdatePropertyMonitoring from 'hooks/services/properties/monitoring/useUpdatePropertyMonitoring'

const PropertyMonitoringSettingsDialogLoadingWrapper = ({
  propertyUuid,
  setIsDialogOpen,
  isDialogOpen,
  monitoringPermissions,
  isPropertyAssignedToMarket,
}) => {
  const { t } = useTranslation()
  const client = useQueryClient()
  const {
    isLoading: isPropertyMonitoringLoading,
    isError: isPropertyMonitoringError,
    error: propertyMonitoringError,
    data: propertyMonitoringData,
  } = usePropertyMonitoring(propertyUuid)
  const {
    isLoading: isValuationSuggestionLoading,
    isError: isValuationSuggestionError,
    data: valuationSuggestion,
  } = useLatestMatchingValuationForPropertyMonitoring(propertyUuid)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)

  const handleHookSuccess = () => {
    client.invalidateQueries(['property-monitoring', propertyUuid])
  }

  const handleHookError = () => {
    setIsErrorDialogOpen(true)
  }

  const handleRetry = () => {
    setIsErrorDialogOpen(false)
    setIsDialogOpen(true)
  }

  const propertyMonitoringCreation = usePropertyMonitoringCreation({
    onSuccess: handleHookSuccess,
    onError: handleHookError,
  })

  const updatePropertyMonitoring = useUpdatePropertyMonitoring({
    onSuccess: handleHookSuccess,
    onError: handleHookError,
  })

  if (
    isPropertyMonitoringLoading ||
    (isPropertyMonitoringError && !isNotFoundError(propertyMonitoringError)) ||
    isValuationSuggestionLoading
  ) {
    const renderLoadingOrFallbackErrorScreen = () => {
      if (isPropertyMonitoringLoading || isValuationSuggestionLoading) {
        return (
          <LoadingScreen
            title={t('app.loading.title')}
            description={t('app.loading.description')}
          />
        )
      }
      return (
        <ErrorScreen
          title={t('app.loading.error.title')}
          description={t('app.loading.error.description')}
          error={t('app.loading.error.details')}
        />
      )
    }
    return (
      <>
        {isDialogOpen && (
          <PropertyMonitoringSettingsDialogView
            isDialogOpen={isDialogOpen}
            setIsDialogOpen={setIsDialogOpen}
            isSaveButtonShown={false}
            isMonitoringActivated={false}
            monitoringPermissions={monitoringPermissions}
          >
            {renderLoadingOrFallbackErrorScreen()}
          </PropertyMonitoringSettingsDialogView>
        )}
      </>
    )
  }

  const currentBaselineDate = get(propertyMonitoringData, 'baseline_date')
  const currentMonitoringStatus = get(propertyMonitoringData, 'monitoring_status')
  const currentNote = get(propertyMonitoringData, 'note', '')

  const getCurrentMonitoringStatus = () => {
    if (isPropertyMonitoringError && isNotFoundError(propertyMonitoringError)) {
      return propertyMonitoringStatus.inactive
    }
    return currentMonitoringStatus
  }

  const calculateMonitoringStatus = ({ monitoringActivated, baselineDate }) => {
    if (!monitoringActivated) return propertyMonitoringStatus.inactive
    if (baselineDate && currentMonitoringStatus === propertyMonitoringStatus.paused)
      return propertyMonitoringStatus.paused
    if (baselineDate) return propertyMonitoringStatus.activeAutomated
    return propertyMonitoringStatus.activeManual
  }

  const onSettingsSaved = ({ monitoringActivated, baselineDate, note }) => {
    const newMonitoringStatus = calculateMonitoringStatus({
      monitoringActivated,
      baselineDate,
    })

    if (isPropertyMonitoringError && isNotFoundError(propertyMonitoringError)) {
      return propertyMonitoringCreation.mutate({
        propertyUuid,
        monitoringStatus: newMonitoringStatus,
        baselineDate,
        note,
      })
    }

    updatePropertyMonitoring.mutate({
      monitoringPropertyId: propertyMonitoringData.id,
      monitoringStatus: newMonitoringStatus,
      baselineDate: baselineDate,
      note,
    })
  }

  const calculateBaselineSuggestion = () => {
    if (isValuationSuggestionError) {
      return { hasErrorLoading: true, suggestionDate: undefined }
    }
    if (!valuationSuggestion) {
      return { hasErrorLoading: false, suggestionDate: undefined }
    }
    return { hasErrorLoading: false, suggestionDate: valuationSuggestion.validFrom }
  }

  return (
    <>
      {createPortal(
        <>
          <PropertyMonitoringSettingsDialog
            monitoringStatus={getCurrentMonitoringStatus()}
            onSettingsSaved={onSettingsSaved}
            setIsDialogOpen={setIsDialogOpen}
            isDialogOpen={isDialogOpen}
            previouslySetBaselineDate={currentBaselineDate}
            isPropertyAssignedToMarket={isPropertyAssignedToMarket}
            note={currentNote}
            monitoringPermissions={monitoringPermissions}
            baselineSuggestion={calculateBaselineSuggestion()}
          />
          <ErrorDialog
            isOpen={isErrorDialogOpen}
            setIsOpen={setIsErrorDialogOpen}
            texts={{
              retryButton: t(
                'pages.property-monitoring.settings-dialog.patch-error.button-try-again',
              ),
              cancelButton: t(
                'pages.property-monitoring.settings-dialog.patch-error.button-cancel',
              ),
              title: t('pages.property-monitoring.settings-dialog.patch-error.title'),
              description: t('pages.property-monitoring.settings-dialog.patch-error.description'),
            }}
            onRetry={handleRetry}
          />
        </>,
        document.body,
      )}
    </>
  )
}

PropertyMonitoringSettingsDialogLoadingWrapper.propTypes = {
  propertyUuid: PropTypes.string.isRequired,
  setIsDialogOpen: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  monitoringPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  isPropertyAssignedToMarket: PropTypes.bool.isRequired,
}

export default PropertyMonitoringSettingsDialogLoadingWrapper
