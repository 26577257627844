import { Button, ButtonDesign, FlexBox } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DecisionPaperSubsectionCommentDialog from 'components/domains/business-events-and-tasks/decision-paper/comments//DecisionPaperSubsectionCommentDialog'
import styles from 'components/domains/business-events-and-tasks/decision-paper/comments/DecisionPaperSubsectionCommentButton.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useDecisionPaperSubsectionComments from 'hooks/services/business-events-and-tasks/decision-papers/comments/useDecisionPaperSubsectionComments'
import { useStaffMemberSelf } from 'hooks/services/business-partners/staff-members/useStaffMemberSelf'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const currentVersionIndicator = 'WORKING_VERSION'
const currentVersion = 'current'

const DecisionPaperSubsectionCommentsButton = ({ version, subsectionId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.subsections.comments',
  })

  const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false)

  const { id: eventId } = useContext(BusinessEventsAndTasksContext).event
  const currentVersionValue = version === currentVersionIndicator ? currentVersion : version

  const {
    isLoading,
    isError,
    data: commentData,
  } = useDecisionPaperSubsectionComments({
    eventId,
    version: currentVersionValue,
    subsectionId,
  })

  const { data: userData } = useStaffMemberSelf()

  const handleOnClick = useCallback(() => {
    setIsCommentDialogOpen(true)
  }, [])

  const renderCommentButton = useCallback(() => {
    const { comments, total: commentCount } = commentData
    return (
      <>
        {version === currentVersionIndicator && (
          <Button onClick={handleOnClick} design={ButtonDesign.Transparent}>
            {t('button.text', { commentCount })}
          </Button>
        )}
        <DecisionPaperSubsectionCommentDialog
          isOpen={isCommentDialogOpen}
          setIsOpen={setIsCommentDialogOpen}
          subsectionId={subsectionId}
          version={currentVersionValue}
          comments={comments}
          userMail={userData?.email}
        />
      </>
    )
  }, [
    commentData,
    isCommentDialogOpen,
    currentVersionValue,
    subsectionId,
    userData,
    t,
    handleOnClick,
    version,
  ])

  return (
    <FlexBox className={isLoading ? styles.commentLoadingIndicator : styles.commentButton}>
      <SmallLoadingWrapper
        isLoading={isLoading}
        isError={isError}
        error={t('loading.error.description')}
        renderContent={renderCommentButton}
      />
    </FlexBox>
  )
}

DecisionPaperSubsectionCommentsButton.propTypes = {
  version: PropTypes.string.isRequired,
  subsectionId: PropTypes.string.isRequired,
}

export default DecisionPaperSubsectionCommentsButton
