import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import useCashflowsObjectDefinitions from 'hooks/services/deals/cashflow/useCashflowsObjectDefinitions'

const dealCashflowInputParametersParameterSelectProps = {
  cell: PropTypes.object.isRequired,
  parameter: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setEditData: PropTypes.func.isRequired,
}

const DealCashflowInputParametersParameterSelect = ({
  cell,
  parameter,
  index,
  setEditData = () => {},
  ...props
}) => {
  const { data: { objects = [] } = {} } = useCashflowsObjectDefinitions()
  const { parameters = [] } = objects.find((oD) => oD.ccodId === parameter.ccodId) ?? {}
  const { coId, invalid = false } = cell.row.original

  const onChangeParameter = (selectedOption) => {
    const { value: ccpdId } = selectedOption
    const selectedParameter = parameters.find((param) => param.ccpdId === ccpdId)
    const ccpuId = selectedParameter.units[0].ccpuId

    setEditData(
      {
        [coId]: {
          coId,
          parameters: {
            [parameter?.ccpdId]: { ccpdId, isRemoved: true, ccpuId, index: 99 },
            [ccpdId]: { ccpdId, ccpuId, isRemoved: false, index },
          },
        },
      },
      true,
    )
  }

  const renderSelectOptions = () =>
    parameters.reduce((options, option) => {
      if (
        option.ccpdId === parameter?.ccpdId ||
        !cell?.row.original.parameter.some(({ ccpdId }) => ccpdId === option.ccpdId)
      ) {
        options.push(
          <Option
            key={option.ccpdId}
            value={option.ccpdId}
            selected={option.ccpdId === parameter?.ccpdId}
          >
            {option.name}
          </Option>,
        )
      }
      return options
    }, [])

  return (
    <Select
      {...props}
      value={parameter?.ccpdId}
      disabled={invalid || parameter.isSaveLoading}
      onChange={({ detail: { selectedOption } }) => onChangeParameter(selectedOption)}
    >
      {parameter?.ccpdId === 'new' && <Option key={'new'} selected />}
      {renderSelectOptions()}
    </Select>
  )
}

DealCashflowInputParametersParameterSelect.propTypes =
  dealCashflowInputParametersParameterSelectProps

export default DealCashflowInputParametersParameterSelect
