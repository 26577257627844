import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useArrayMemo } from 'hooks/services/queryHelper'

export const useMultiPropertyImagesDownload = ({ images, options = {} }) => {
  const responses = useRequests({
    requests: images.map(({ imageUuid }) => ({
      path: `/properties/images/${imageUuid}/download`,
    })),
    options,
  })

  const isLoading = useMemo(
    () => responses.some(({ isLoading: isPropertyLoading }) => isPropertyLoading),
    [responses],
  )
  const isFetching = useMemo(
    () => responses.some(({ isFetching: isPropertyFetching }) => isPropertyFetching),
    [responses],
  )
  const isError = responses.some(({ isError: isPropertyError }) => isPropertyError)
  const imageData = useArrayMemo(responses.map(({ data }) => data))

  return useMemo(
    () => ({
      data: imageData,
      isLoading,
      isFetching,
      isError,
    }),
    [isError, isLoading, isFetching, imageData],
  )
}
