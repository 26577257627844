import isNil from 'lodash.isnil'
import { WorkingVersionType } from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/components/domains/deals/deal-adjustment/model/WorkingVersionType'
import {
  DEFAULT_DISPLAY_CELL_PADDING,
  DEFAULT_DISPLAY_ENTITY_TEXT_HEIGHT,
  DEFAULT_DISPLAY_ROW_HEIGHT,
  DEFAULT_DISPLAY_TEXT_GAP,
  ROW_TYPE_COMPLIANCE_WITH_RISK_STRATEGY,
  ROW_TYPE_DEAL,
  ROW_TYPE_LENDERS,
  ROW_TYPE_SPONSORS,
  ROW_TYPE_STATUS,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/constants'
import { DEAL_STATUS_RANKS } from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/deal/financingConstants'
import mapUnitDealSyndicationLenders from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/deal/mapUnitDealSyndicationLenders'

const rowKeys = [
  'dealName',
  'dealType',
  'status',
  'lenders',
  'sponsor',
  'complianceWithRiskStrategy',
]

const mapRowData = ({
  rowKey,
  deals,
  multipleDealDetailsResponses,
  complianceWithRiskStrategyResponses,
  lendersResponses,
  multipleSyndicationsExistingBusinessResponses,
  customerBusinessPartnerIdResponse,
  getSyndicationBusinessPartnerFullName,
}) => {
  const createRow = (createObjectFunction) =>
    deals?.map(createObjectFunction).reduce((prev, curr) => Object.assign(prev, curr), {})

  const mapComplianceWithRiskStrategy = (dealUuid, complianceWithRiskStrategyData) => {
    const complianceWithRiskStrategyResponse = complianceWithRiskStrategyData?.find(
      ({ data: { dealUuid: responseDealUuid } }) => responseDealUuid === dealUuid,
    )

    return complianceWithRiskStrategyResponse ?? false
  }

  const mapLenders = (dealUuid) => {
    const lendersResponse = lendersResponses?.find(
      ({ data: { dealUuid: responseDealUuid } }) => responseDealUuid === dealUuid,
    )
    const syndicationExistingBusinessResponse = multipleSyndicationsExistingBusinessResponses?.find(
      ({ data: { dealUuid: responseDealUuid } }) => responseDealUuid === dealUuid,
    )

    const dealRank = deals?.find(
      ({ kpiDeal: { dealUuid: responseDealUuid } }) => responseDealUuid === dealUuid,
    )?.kpiDeal?.dealRank

    const customerBusinessPartnerId = customerBusinessPartnerIdResponse?.data?.typeCodes?.[0]?.key

    if (dealRank && dealRank >= DEAL_STATUS_RANKS.SIGNED) {
      if (isNil(syndicationExistingBusinessResponse?.data?.ownShare?.amountShare)) {
        return {}
      }

      const mappedSyndicationLendersWithCustomer = mapUnitDealSyndicationLenders({
        syndicationTranches: syndicationExistingBusinessResponse?.data?.tranches,
        syndicationTotalShareAmount:
          syndicationExistingBusinessResponse?.data?.totalShare?.amount?.amountOriginalCurrency
            ?.amount,
        syndicationOwnShareAmountShare:
          syndicationExistingBusinessResponse?.data?.ownShare?.amountShare,
        customerBusinessPartnerId,
        getSyndicationBusinessPartnerFullName,
      })

      return {
        ...syndicationExistingBusinessResponse,
        isLoading:
          syndicationExistingBusinessResponse?.isLoading ||
          customerBusinessPartnerIdResponse?.isLoading,
        data: { dealUuid, lenders: mappedSyndicationLendersWithCustomer },
      }
    }

    // Sort lenders based on descending amount share, but put customer at the top of the list regardless
    const sortedLenders = lendersResponse?.data?.lenders?.toSorted((lenderA, lenderB) => {
      if (lenderA.businessPartnerId === lenderB.businessPartnerId) return 0
      if (lenderA.businessPartnerId === customerBusinessPartnerId) return -1
      if (lenderB.businessPartnerId === customerBusinessPartnerId) return 1
      return lenderB.amountShare - lenderA.amountShare
    })

    return lendersResponse
      ? {
          ...lendersResponse,
          data: {
            ...lendersResponse?.data,
            lenders: sortedLenders,
          },
        }
      : {}
  }

  const mapDeal = (dealUuid) => {
    const currentDeal = deals?.find(
      ({ kpiDeal: { dealUuid: responseDealUuid } }) => responseDealUuid === dealUuid,
    )?.kpiDeal
    const currentDealDetails = multipleDealDetailsResponses?.find(
      ({ data }) => data?.dealUuid === currentDeal?.dealUuid,
    )
    return {
      ...currentDeal,
      isWorkingVersion:
        currentDealDetails?.data?.workingVersion === WorkingVersionType.WORKING_VERSION,
    }
  }

  switch (rowKey) {
    case 'dealName':
      return createRow(({ kpiDeal: { dealUuid } }) => ({
        [dealUuid]: {
          value: mapDeal(dealUuid),
        },
      }))

    case 'dealType':
      return createRow(({ kpiDeal: { dealUuid, dealType } }) => ({
        [dealUuid]: {
          value: dealType,
        },
      }))

    case 'status':
      return createRow(({ kpiDeal: { dealUuid, dealStatus } }) => ({
        [dealUuid]: {
          value: dealStatus,
        },
      }))

    case 'lenders':
      return createRow(({ kpiDeal: { dealUuid } }) => ({
        [dealUuid]: {
          value: mapLenders(dealUuid),
        },
      }))

    case 'complianceWithRiskStrategy':
      return createRow(({ kpiDeal: { dealUuid } }) => ({
        [dealUuid]: {
          value: mapComplianceWithRiskStrategy(dealUuid, complianceWithRiskStrategyResponses),
        },
      }))

    case 'sponsor':
      return createRow(({ kpiDeal: { dealUuid, sponsors } }) => ({
        [dealUuid]: {
          value: sponsors,
        },
      }))
  }
}

const mapRowType = (rowKey) => {
  switch (rowKey) {
    case 'status':
      return ROW_TYPE_STATUS

    case 'complianceWithRiskStrategy':
      return ROW_TYPE_COMPLIANCE_WITH_RISK_STRATEGY

    case 'lenders':
      return ROW_TYPE_LENDERS

    case 'sponsor':
      return ROW_TYPE_SPONSORS

    case 'dealName':
      return ROW_TYPE_DEAL
  }
}

const mapRowHeight = (rowKey, rowData, sponsorsRowCount) => {
  const calculateMultiItemCellHeight = (itemsCount) => {
    const height = (DEFAULT_DISPLAY_ENTITY_TEXT_HEIGHT + DEFAULT_DISPLAY_TEXT_GAP / 2) * itemsCount
    const gap = DEFAULT_DISPLAY_TEXT_GAP * (itemsCount - 1)
    return height + gap + DEFAULT_DISPLAY_CELL_PADDING
  }

  const lendersMaxNumber = Object.values(rowData)
    ?.map((item) => item?.value?.data?.lenders)
    ?.reduce((acc, curr) => Math.max(acc, curr?.length ?? 0), 0)

  switch (rowKey) {
    case 'lenders':
      return {
        rowHeight:
          lendersMaxNumber > 0
            ? calculateMultiItemCellHeight(lendersMaxNumber)
            : DEFAULT_DISPLAY_ENTITY_TEXT_HEIGHT,
      }

    case 'sponsor':
      return {
        rowHeight:
          sponsorsRowCount > 0
            ? calculateMultiItemCellHeight(sponsorsRowCount)
            : DEFAULT_DISPLAY_ENTITY_TEXT_HEIGHT,
      }

    case 'dealName':
      return { rowHeight: DEFAULT_DISPLAY_ENTITY_TEXT_HEIGHT + DEFAULT_DISPLAY_CELL_PADDING }

    default:
      return { rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT }
  }
}

const mapUnitDealData = ({
  deals,
  multipleDealDetailsResponses,
  complianceWithRiskStrategyResponses,
  lendersResponses,
  multipleSyndicationsExistingBusinessResponses,
  customerBusinessPartnerIdResponse,
  getSyndicationBusinessPartnerFullName,
  t,
}) => {
  let sponsorsRowCount = 0

  const sponsorsMaxNumber = deals
    ?.map((item) => item?.kpiDeal?.sponsors)
    ?.reduce((acc, curr) => Math.max(acc, curr?.length ?? 0), 0)

  if (sponsorsMaxNumber > sponsorsRowCount) {
    sponsorsRowCount = sponsorsMaxNumber
  }

  return rowKeys.map((rowKey) => {
    const rowData = mapRowData({
      rowKey,
      deals,
      multipleDealDetailsResponses,
      complianceWithRiskStrategyResponses,
      lendersResponses,
      multipleSyndicationsExistingBusinessResponses,
      customerBusinessPartnerIdResponse,
      getSyndicationBusinessPartnerFullName,
    })

    return {
      kpi: t(`rows.section.deal.${rowKey}`),
      ...rowData,
      ...mapRowHeight(rowKey, rowData, sponsorsRowCount),
      rowType: mapRowType(rowKey),
      rowHasError: Object.values(rowData).some((column) => column.isError),
    }
  })
}

export default mapUnitDealData
