import { Button, ButtonDesign, FlexBox, MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CurrentApprovalsTable from 'components/domains/business-partners/tile/arrears/current-approvals/CurrentApprovalsTable'
import useCurrentApprovalsTableColumns from 'components/domains/business-partners/tile/arrears/current-approvals/useCurrentApprovalsTableColumns'
import useCurrentApprovalsTableData from 'components/domains/business-partners/tile/arrears/current-approvals/useCurrentApprovalsTableData'
import useCurrentApprovalsTableEdit from 'components/domains/business-partners/tile/arrears/current-approvals/useCurrentApprovalsTableEdit'
import { useCurrentApprovalsValidationSummary } from 'components/domains/business-partners/tile/arrears/current-approvals/useCurrentApprovalsValidationSummary'
import styles from 'components/domains/business-partners/tile/arrears/current-arrears/CurrentArrearsTableStartApprovalProcessDialog.module.css'
import useStartApprovalProcessButton from 'components/domains/business-partners/tile/arrears/current-arrears/useStartApprovalProcessButton'
import Dialog, {
  DialogPrimaryButton,
  DialogSecondaryButton,
  DialogSize,
} from 'components/ui/dialog/Dialog'
import MessageBox, { MessageBoxActions } from 'components/ui/message-box/MessageBox'
import {
  setConfirmDialogOpen,
  setDiscardDialogOpen,
} from 'redux/slices/arrears/arrearApprovalSlice'

const SaveButton = ({ isUpdatingAllApprovalItems, onClick }) => {
  const { t } = useTranslation()
  const errorSummary = useSelector(
    (state) => state.arrears.arrearApproval.startApproval.errorSummary,
  )

  return (
    <DialogPrimaryButton
      isLoading={isUpdatingAllApprovalItems}
      onClick={onClick}
      disabled={errorSummary > 0}
    >
      {t('buttons.start')}
    </DialogPrimaryButton>
  )
}
SaveButton.propTypes = {
  isUpdatingAllApprovalItems: PropTypes.bool,
  onClick: PropTypes.func,
  t: PropTypes.func,
}

const ErrorSummary = () => {
  const errorSummary = useSelector(
    (state) => state.arrears.arrearApproval.startApproval.errorSummary,
  )
  return (
    <>
      {!!errorSummary && (
        <Button
          id={'error-sum-button'}
          className={styles.buttonError}
          design={ButtonDesign.Emphasized}
          icon={'error'}
        >
          {errorSummary}
        </Button>
      )}
    </>
  )
}

const WarningSummary = () => {
  const warningSummary = useSelector(
    (state) => state.arrears.arrearApproval.startApproval.warningSummary,
  )
  return (
    <>
      {!!warningSummary && (
        <Button
          id={'warning-sum-button'}
          className={styles.buttonWarning}
          design={ButtonDesign.Emphasized}
          icon={'error'}
        >
          {warningSummary}
        </Button>
      )}
    </>
  )
}

const ConfirmDialog = ({
  isUpdatingAllApprovalItems,
  setIsUpdatingAllApprovalItems,
  handleSaveAll,
}) => {
  const dispatch = useDispatch()
  const isOpen = Boolean(
    useSelector((state) => state.arrears.arrearApproval.startApproval.isConfirmDialogOpen),
  )
  const { t } = useTranslation()
  const { t: tArrearsStartApproval } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.current-arrears.table',
  })

  const { noApprovalExists, approvalExistsAndIsOngoingAndNotLocked, approvalExistsAndIsLocked } =
    useStartApprovalProcessButton()

  const overrideExistingApproval = approvalExistsAndIsOngoingAndNotLocked
  const startNewApproval = noApprovalExists || approvalExistsAndIsLocked

  return (
    <MessageBox
      open={isOpen}
      type={MessageBoxTypes.Confirm}
      onClose={() => dispatch(setConfirmDialogOpen(false))}
      actions={[
        <Button
          id={'confirm-button'}
          key={'message-box-confirm-button'}
          isLoading={isUpdatingAllApprovalItems}
          design={ButtonDesign.Emphasized}
          onClick={() => {
            setIsUpdatingAllApprovalItems(true)
            handleSaveAll()
          }}
        >
          {t('buttons.confirm')}
        </Button>,
        MessageBoxActions.Cancel,
      ]}
      titleText={t('buttons.confirm')}
    >
      {overrideExistingApproval && tArrearsStartApproval('message-box.confirm')}
      {startNewApproval && tArrearsStartApproval('message-box.confirm-new-approval')}
    </MessageBox>
  )
}
ConfirmDialog.propTypes = {
  isUpdatingAllApprovalItems: PropTypes.bool,
  setIsUpdatingAllApprovalItems: PropTypes.func,
  handleSaveAll: PropTypes.func,
}

const DiscardDialog = ({
  isUpdatingAllApprovalItems,
  cancelAllEditRows,
  setIsApprovalModalOpen,
}) => {
  const dispatch = useDispatch()
  const isOpen = Boolean(
    useSelector((state) => state.arrears.arrearApproval.startApproval.isDiscardDialogOpen),
  )
  const { t } = useTranslation()
  const { t: tArrearsStartApproval } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.current-arrears.table',
  })
  return (
    <MessageBox
      open={isOpen}
      type={MessageBoxTypes.Warning}
      disabled={isUpdatingAllApprovalItems}
      onClose={() => dispatch(setDiscardDialogOpen(false))}
      actions={[
        <Button
          id={'discard-button'}
          key={'message-box-discard-button'}
          design={ButtonDesign.Emphasized}
          onClick={() => {
            dispatch(setDiscardDialogOpen(false))
            setIsApprovalModalOpen(false)
            cancelAllEditRows()
          }}
        >
          {t('buttons.discard')}
        </Button>,
        MessageBoxActions.Cancel,
      ]}
      titleText={t('buttons.discard')}
    >
      {tArrearsStartApproval('message-box.discard')}
    </MessageBox>
  )
}
DiscardDialog.propTypes = {
  isUpdatingAllApprovalItems: PropTypes.bool,
  cancelAllEditRows: PropTypes.func,
  setIsApprovalModalOpen: PropTypes.func,
}

const CurrentArrearsTableStartApprovalProcessDialog = ({
  isApprovalModalOpen,
  setIsApprovalModalOpen,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { t: tArrearsStartApproval } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.current-arrears.table',
  })

  const [isUpdatingAllApprovalItems, setIsUpdatingAllApprovalItems] = useState(false)

  const onFinishUpdatingAllApprovalItems = () => {
    setIsUpdatingAllApprovalItems(false)
    dispatch(setConfirmDialogOpen(false))
    dispatch(setDiscardDialogOpen(false))
  }

  const { data: tableData, datacount } = useCurrentApprovalsTableData(true)

  const { errorSum, warningSum } = useCurrentApprovalsValidationSummary(true)

  const arrearApprovalEditFunctions = useCurrentApprovalsTableEdit({
    originalErrorSum: errorSum,
    originalWarningSum: warningSum,
    onFinishUpdatingAllApprovalItems,
    tableData,
    isStartApprovalProcess: true,
  })

  const [isInitialErrorAndWarningSummary, setIsInitialErrorAndWarningSummary] = useState(true)
  useEffect(() => {
    if (isInitialErrorAndWarningSummary) {
      arrearApprovalEditFunctions.updateErrorAndWarningSummary(errorSum, warningSum)
      setIsInitialErrorAndWarningSummary(false)
    }
  }, [arrearApprovalEditFunctions, dispatch, errorSum, isInitialErrorAndWarningSummary, warningSum])

  const { tableColumns } = useCurrentApprovalsTableColumns({
    isStartApprovalProcess: true,
    arrearApprovalEditFunctions,
  })

  return isApprovalModalOpen ? (
    <>
      <Dialog
        open={isApprovalModalOpen}
        onBeforeClose={(e) => {
          if (e?.detail?.escPressed) {
            e.preventDefault()
            dispatch(setDiscardDialogOpen(true))
          }
        }}
        onAfterClose={() => setIsApprovalModalOpen(false)}
        primaryButton={
          <div>
            <SaveButton
              isUpdatingAllApprovalItems={isUpdatingAllApprovalItems}
              onClick={() => {
                dispatch(setConfirmDialogOpen(true))
              }}
            />
          </div>
        }
        closeButton={
          <DialogSecondaryButton
            disabled={isUpdatingAllApprovalItems}
            onClick={() => {
              dispatch(setDiscardDialogOpen(true))
            }}
          >
            {t('buttons.cancel')}
          </DialogSecondaryButton>
        }
        headerText={tArrearsStartApproval('start-approval-process')}
        size={DialogSize.XL}
        leftFooterContent={
          <FlexBox className={styles.errorAndWarningGap}>
            <ErrorSummary />
            <WarningSummary />
          </FlexBox>
        }
      >
        <CurrentApprovalsTable
          tableColumns={tableColumns}
          tableData={tableData}
          datacount={datacount}
          isStartApprovalProcess={true}
        />
      </Dialog>
      <ConfirmDialog
        isUpdatingAllApprovalItems={isUpdatingAllApprovalItems}
        setIsUpdatingAllApprovalItems={setIsUpdatingAllApprovalItems}
        handleSaveAll={arrearApprovalEditFunctions.handleSaveAll}
      />
      <DiscardDialog
        isUpdatingAllApprovalItems={isUpdatingAllApprovalItems}
        cancelAllEditRows={arrearApprovalEditFunctions.cancelAllEditRows}
        setIsApprovalModalOpen={setIsApprovalModalOpen}
      />
    </>
  ) : (
    <></>
  )
}

CurrentArrearsTableStartApprovalProcessDialog.propTypes = {
  isApprovalModalOpen: PropTypes.bool.isRequired,
  setIsApprovalModalOpen: PropTypes.func.isRequired,
}

export default CurrentArrearsTableStartApprovalProcessDialog
