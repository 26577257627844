import { BusyIndicator, BusyIndicatorSize } from '@fioneer/ui5-webcomponents-react'
import Card from 'components/ui/card/Card'
import styles from 'components/ui/card/LazyLoadingFallbackCard.module.css'

const LazyLoadingFallbackCard = () => (
  <Card>
    <div className={styles.busyIndicatorWrapper}>
      <BusyIndicator active size={BusyIndicatorSize.Medium} />
    </div>
  </Card>
)

export default LazyLoadingFallbackCard
