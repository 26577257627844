import { ObjectPageSection } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import ManagerTab from 'components/domains/business-partners/ManagerTab'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import BusinessPartnerOverviewFinancialIndicatorsTile from 'components/domains/business-partners/tile/BusinessPartnerOverviewFinancialIndicatorsTile'
import GeneralInformationTile from 'components/domains/business-partners/tile/GeneralInformationTile'
import RelationshipsTile from 'components/domains/business-partners/tile/RelationshipsTile'
import RolesTile from 'components/domains/business-partners/tile/general-information/RolesTile'
import TenancyOverviewTile from 'components/domains/business-partners/tile/general-information/TenancyOverviewTile'
import { findActiveRating } from 'components/domains/business-partners/tile/ratings/InternalRatingsOverview'
import RatingsOverviewTile from 'components/domains/business-partners/tile/ratings/RatingsOverviewTile'
import CWPLayout from 'components/ui/layout/CWPLayout'
import TabPage from 'components/ui/page/TabPage'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useBusinessPartnerRatings from 'hooks/services/business-partners/ratings/useBusinessPartnerRatings'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import paths from 'routes/business-partners/paths'

const tenantRole = { id: 'Tenant' }

const BusinessPartnerOverview = () => {
  const businessPartner = useContext(BusinessPartnerContext)
  const { data: ratings, isLoading, isError } = useBusinessPartnerRatings(businessPartner.id)
  const { t } = useTranslation()

  const businessPartnerFound = () => Boolean(businessPartner.id)
  const hasRatings = !isEmpty(findActiveRating(ratings?.internal)) || !isEmpty(ratings?.external)
  const hasRoleTenant = () => businessPartner.roles?.some(({ id }) => id === tenantRole.id)

  const renderContent = () =>
    businessPartnerFound() && ratings ? (
      [
        <ObjectPageSection
          id="summary-tab"
          key="summary-tab"
          titleText={t('pages.business-partner.overview.summary')}
        >
          <CWPLayout overview>
            <GeneralInformationTile
              streetName={businessPartner.address?.streetName ?? ''}
              postalCode={businessPartner.address?.postalCode ?? ''}
              city={businessPartner.address?.city ?? ''}
              country={businessPartner.address?.country?.name ?? ''}
            />
            {hasRatings && <RatingsOverviewTile ratings={ratings} />}
            {hasRoleTenant() && <TenancyOverviewTile />}
            <BusinessPartnerOverviewFinancialIndicatorsTile />
            <RolesTile roles={businessPartner.roles ?? []} />
            <RelationshipsTile />
          </CWPLayout>
        </ObjectPageSection>,
        <ObjectPageSection
          id="manager-tab"
          key="manager-tab"
          titleText={t('pages.business-partner.overview.manager')}
        >
          <ManagerTab />
        </ObjectPageSection>,
      ]
    ) : (
      <ObjectPageSection
        id="not-found-tab"
        key="not-found-tab"
        titleText={t('pages.business-partner.not-found')}
      >
        <Link to={paths.businessPartners}>{t('pages.business-partner.search')}</Link>
      </ObjectPageSection>
    )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={businessPartner.id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [businessPartner.id],
  )

  const renderHeaderTitle = () => (
    <BusinessPartnerHeader
      breadcrumbs={[]}
      actions={
        <>
          <CreateBusinessPartnerEventAction />
          {markFavoriteAction}
        </>
      }
    />
  )

  return (
    <TabPage
      isLoading={isLoading}
      isError={isError}
      headerTitle={renderHeaderTitle()}
      defaultSectionId="summary-tab"
      className="businessPartnerOverview"
      id="businessPartnerId"
    >
      {renderContent()}
    </TabPage>
  )
}

BusinessPartnerOverview.propTypes = {}

export default BusinessPartnerOverview
