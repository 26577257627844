import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import ConditionAssigneeDisplay from 'components/domains/conditions/shared/assignee/ConditionAssigneeDisplay'
import ConditionAssigneeSelect from 'components/domains/conditions/shared/assignee/ConditionAssigneeSelect'
import useConditionsCellEditMode from 'hooks/services/conditions/edit-mode/useConditionsCellEditMode'
import {
  conditionAssigneeChanged,
  conditionRowHasError,
} from 'redux/slices/conditions/conditionsTableSlice'

const ConditionsTableAssigneeCell = ({ value: { id: conditionId, assignee: assigneeId } }) => {
  const dispatch = useDispatch()
  const { isEditModeForCurrentRow } = useConditionsCellEditMode({
    conditionId,
  })

  const onRequestError = useCallback(() => {
    dispatch(conditionRowHasError({ id: conditionId, error: { hasError: true } }))
  }, [dispatch, conditionId])

  const handleOnAssigneeChange = useCallback(
    (newAssignee) => {
      dispatch(conditionAssigneeChanged({ assignee: newAssignee }))
    },
    [dispatch],
  )

  return isEditModeForCurrentRow ? (
    <ConditionAssigneeSelect
      assigneeId={assigneeId}
      onRequestError={onRequestError}
      onAssigneeChange={handleOnAssigneeChange}
    />
  ) : (
    <ConditionAssigneeDisplay assigneeId={assigneeId} onRequestError={onRequestError} />
  )
}

ConditionsTableAssigneeCell.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assignee: PropTypes.string,
  }).isRequired,
}

export default ConditionsTableAssigneeCell
