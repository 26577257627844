import { FlexBoxAlignItems } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'

const useReferencedCovenantChecksTableColumnDefinitions = ({ statusEnum, breachEnumns }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.covenants.monitoring.covenant-checks',
  })
  return [
    {
      title: t('column.covenant-check'),
      columnKey: 'covenant-check',
      isSelectableForHiding: false,
      filter: filterTypes.CONTAINS,
    },
    {
      // For now, monitoring-reference is always a business partner
      title: t('column.business-partner'),
      popinText: t('column.business-partner'),
      columnKey: 'monitoring-reference',
      isVisible: true,
      minWidth: 900,
      demandPopin: true,
    },
    {
      title: t('column.covenants'),
      popinText: t('column.covenants'),
      columnKey: 'covenants',
      minWidth: 900,
      demandPopin: true,
      sortingDisabled: true,
    },
    {
      title: t('column.monitoring-event'),
      popinText: t('column.monitoring-event'),
      columnKey: 'monitoring-event',
      minWidth: 700,
      demandPopin: true,
      sortingDisabled: true,
    },
    {
      title: t('column.key-date'),
      popinText: t('column.key-date'),
      columnKey: 'key-date',
      filter: filterTypes.BETWEEN_DATES,
      alignment: FlexBoxAlignItems.End,
      minWidth: 700,
      demandPopin: true,
    },
    {
      title: t('column.status'),
      popinText: t('column.status'),
      columnKey: 'status',
      filter: filterTypes.OF_ENUM_TYPE,
      additionalFilterOptions: { enumValues: statusEnum },
      minWidth: 700,
      demandPopin: true,
    },
    {
      title: t('column.first-target-delivery-date'),
      popinText: t('column.first-target-delivery-date'),
      columnKey: 'first-target-delivery-date',
      filter: filterTypes.BETWEEN_DATES,
      alignment: FlexBoxAlignItems.End,
      minWidth: 600,
      demandPopin: true,
    },
    {
      title: t('column.breach'),
      popinText: t('column.breach'),
      columnKey: 'breach',
      filter: filterTypes.OF_ENUM_TYPE,
      additionalFilterOptions: { enumValues: breachEnumns },
      minWidth: 600,
      demandPopin: true,
    },
  ]
}

export default useReferencedCovenantChecksTableColumnDefinitions
