import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/DisplayCardViewItemView'

const SyndicationInformationRow = ({ label, value, ...props }) => {
  value = value === '' || isNil(value) ? '-' : value

  return (
    <DisplayCardViewItemView>
      <DisplayCardViewInfoItemView label={label} value={value} {...props} />
    </DisplayCardViewItemView>
  )
}

SyndicationInformationRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
}

export default SyndicationInformationRow
