import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { standardMappings, useFormMapped } from 'hooks/form/useFormMapped'
import { useFormValidations } from 'hooks/form/useFormValidations'
import { useDealTypes } from 'hooks/services/deals/configurations/useBusinessSegments'

export const DealCreationDealTypeDropdown = () => {
  const { t } = useTranslation()

  const { register, watch } = useFormMapped()
  const businessSegmentCode = watch('businessSegmentCode')

  const { isLoading, isError, data: dealTypesData } = useDealTypes(businessSegmentCode)

  const isSelectedOptionValid = useCallback(
    (selectedOption) =>
      !selectedOption ||
      dealTypesData?.length < 1 ||
      !!dealTypesData.find(({ code }) => code === selectedOption),
    [dealTypesData],
  )
  const { required } = useFormValidations()
  const validate = {
    required: required(),
    existingDealType: (v) => isSelectedOptionValid(v) || t('form.validation.required'),
  }

  const getLoanType = useCallback(
    (dealTypeCode) => dealTypesData.find(({ code }) => code === dealTypeCode)?.defaultLoanType,
    [dealTypesData],
  )

  return (
    <LoadingSelect
      id="deal-creation-deal-type-dropdown"
      loadingHook={() => ({ isLoading, isError, data: dealTypesData })}
      optionKeyName="code"
      optionDisplayName="name"
      {...register('dealTypeCode', {
        ...standardMappings.LoadingSelect,
        handlerMappings: {
          ...standardMappings.LoadingSelect.handlerMappings,
          onChange: (_, { setValue, trigger }) => ({
            onChange: async (e, value) => {
              await setValue(e.target.name, value)
              await setValue('tranche.loanType', getLoanType(value))
              await trigger(e.target.name)
            },
          }),
        },
        validate,
        deps: ['tranche.loanType'],
      })}
    />
  )
}
