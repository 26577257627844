import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { createMonitoringEventResponseSchema } from 'hooks/services/risk-monitoring/createMonitoringEventResponseSchema'

const useCreateMonitoringEvent = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  const result = useMutation(
    /**
     * @param {object} params
     * @param {string} [params.businessPartnerId] ID of the business partner.
     * @param {string} [params.riskIndicatorGroupId] ID of the risk indicator group.
     */
    async ({ businessPartnerId, riskIndicatorGroupId }) => {
      if (!businessPartnerId || !riskIndicatorGroupId) {
        return
      }

      const postResult = await post({
        path: `/risk-monitoring/business-partners/${businessPartnerId}/risk-indicator-groups/${riskIndicatorGroupId}/event`,
      })

      const parsedResponse = createMonitoringEventResponseSchema.safeParse(
        camelize(postResult.data),
      )

      if (!parsedResponse.success) {
        // eslint-disable-next-line no-console
        console.warn(
          'Creating event successful, but response from server did not match expected schema.',
          parsedResponse.error,
        )
      }
      return parsedResponse.data
    },
    { onSuccess, onError },
  )

  return {
    ...result,
    createMonitoringEvent: result.mutate,
  }
}

export default useCreateMonitoringEvent
