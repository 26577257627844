import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useCreateSegment = (options) => {
  const { post } = useAccessTokenRequest()

  const getStringOrUndefinedIfNotSet = (attribute) => (attribute ? attribute : undefined)
  const getNumberOrUndefinedIfNotSet = (attribute) =>
    attribute && !isNaN(attribute) ? Number(attribute) : undefined
  const getMoneyOrUndefinedIfNotSet = (value, currency) =>
    value && currency && !isNaN(value)
      ? {
          number: Number(value),
          currency: currency,
        }
      : undefined

  return useMutation(async ({ property_uuid, segment }) => {
    const { data } = await post({
      path: `/properties/${property_uuid}/segments`,
      body: {
        name: getStringOrUndefinedIfNotSet(segment?.name),
        description: getStringOrUndefinedIfNotSet(segment?.description),
        usage_type_code: getStringOrUndefinedIfNotSet(segment?.usage_type_code),
        market_rent_amount: getMoneyOrUndefinedIfNotSet(
          segment?.market_rent_amount?.number,
          segment?.market_rent_amount?.currency,
        ),
        vacancy_cost_amount: getMoneyOrUndefinedIfNotSet(
          segment?.vacancy_cost_amount?.number,
          segment?.vacancy_cost_amount?.currency,
        ),
        total_area: getNumberOrUndefinedIfNotSet(segment?.total_area),
        area_measure_unit_code: getStringOrUndefinedIfNotSet(segment?.area_measure_unit_code),
      },
    })
    return data
  }, options)
}
