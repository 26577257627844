import compact from 'lodash.compact'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { periodValues } from 'components/domains/deals/deal-cashflow/results/PeriodSelect'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import {
  APPLIED_LEASE_END,
  CASH_FLOW_START_DATE,
  CASH_FLOW_START_DATE_LABEL,
  GLOBAL_OBJECT_TYPE,
  PROPERTY_AND_PORTFOLIO_OBJECT_TYPE,
  PROPERTY_AND_PORTFOLIO_PARAMETERS,
} from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/cashflow-analysis/cashflowConstant'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useTileMetaData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/meta-data/useTileMetaData'
import useCashflowGroups from 'hooks/services/deals/cashflow/useCashflowGroups'
import useCashflowsObjectDefinitions from 'hooks/services/deals/cashflow/useCashflowsObjectDefinitions'
import useMultipleCashFlowScenario from 'hooks/services/deals/cashflow/useMultipleCashFlowScenario'
import useMultipleCashflowScenarioResultsById from 'hooks/services/deals/cashflow/useMultipleCashflowScenarioResultsById'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const getFilteredUsedParameter = (usedParameters, internalObjectTypeArray) =>
  usedParameters.filter((cashFlowObject) =>
    internalObjectTypeArray.includes(cashFlowObject?.objectDefinition?.internalObjectType),
  )

const useAnnualReviewCashFlowAnalysis = (
  { id: eventId, entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const { format: formatDate } = useShortDateFormatter()

  const {
    data: { selectedDeal, dealsData, dealUuids } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const {
    data: { meta_data: multipleDealTileMetaData = [] } = {},
    isLoading: isTileMetaDataLoading,
    isError: isTileMetaDataError,
  } = useTileMetaData({ eventId, tileId })

  const { selectedDealDisplayId } = selectedDeal ?? {}

  const dealUuidCashflowIdPairs = useMemo(
    () =>
      compact(
        dealUuids?.map((dealUuid) =>
          multipleDealTileMetaData?.[dealUuid]
            ? {
                dealUuid: dealUuid,
                cashflowId: multipleDealTileMetaData?.[dealUuid]?.selectedCashFlowScenarioUuid,
              }
            : undefined,
        ),
      ),
    [dealUuids, multipleDealTileMetaData],
  )

  const multipleDealTileMetaDataArray = Object.entries(multipleDealTileMetaData ?? {}).map(
    ([dealUuid, tileMetaData]) => ({
      ...tileMetaData,
      dealUuid,
    }),
  )

  const {
    data: multipleCashflowScenarioPeriodYearsData,
    isLoading: isCashflowScenarioPeriodYearsResultLoading,
    isError: isCashflowScenarioPeriodYearsResultError,
  } = useCombinedQueryResults(
    useMultipleCashflowScenarioResultsById({
      dealUuidCashflowIdPairs,
      datasetPeriod: periodValues.years,
      options: {
        enabled: !isTileMetaDataError && !isTileMetaDataLoading,
      },
    }),
    { forceDataReturn: true },
  )

  const {
    data: multipleCashflowScenarioPeriodQuartersData,
    isLoading: isCashflowScenarioPeriodQuartersResultLoading,
    isError: isCashflowScenarioPeriodQuartersResultError,
  } = useCombinedQueryResults(
    useMultipleCashflowScenarioResultsById({
      dealUuidCashflowIdPairs,
      datasetPeriod: periodValues.quarters,
      options: {
        enabled: !isTileMetaDataError && !isTileMetaDataLoading,
      },
    }),
    { forceDataReturn: true },
  )

  const {
    data: { groups = [] } = {},
    isLoading: isGroupsLoading,
    isError: isGroupsError,
  } = useCashflowGroups()

  const {
    data: { objects: config = [] } = {},
    isLoading: isConfigLoading,
    isError: isConfigError,
  } = useCashflowsObjectDefinitions()

  const {
    data: multipleCashFlowScenarioData,
    isLoading: isMultipleCashFlowScenarioLoading,
    isError: isMultipleCashFlowScenarioError,
  } = useCombinedQueryResults(
    useMultipleCashFlowScenario({
      dealUuidCashflowIdPairs,
      options: {
        enabled: !isTileMetaDataError && !isTileMetaDataLoading,
      },
    }),
    {
      forceDataReturn: true,
    },
  )

  const multipleFilteredUsedParameters = useMemo(
    () =>
      dealUuidCashflowIdPairs?.map(({ dealUuid, cashflowId }, index) => {
        const cashFlowScenarioInfo = multipleCashFlowScenarioData?.[index]?.extensionFields ?? []
        const usedParameters =
          multipleCashflowScenarioPeriodYearsData?.[index]?.cashflowScenario?.usedParameters ?? []

        const cashFlowStartDate = cashFlowScenarioInfo.filter(
          (field) => field?.fieldName === CASH_FLOW_START_DATE,
        )?.[0]

        const parameterCashFlowStartDate = [
          {
            cashflowParameterDefinition: { name: CASH_FLOW_START_DATE_LABEL },
            cashflowParameterUnit: { name: formatDate(cashFlowStartDate?.value) ?? '' },
            value: null,
          },
        ]

        const usedParameterGlobal = getFilteredUsedParameter(
          usedParameters,
          GLOBAL_OBJECT_TYPE,
        )?.[0]
        const parameterAppliedLeaseEnd =
          usedParameterGlobal?.parameters?.filter(
            (parameter) => parameter.cashflowParameterDefinition.name === APPLIED_LEASE_END,
          ) ?? []

        const filteredUsedParameterGlobal = !isEmpty(usedParameterGlobal)
          ? [
              {
                ...usedParameterGlobal,
                parameters: [...parameterCashFlowStartDate, ...parameterAppliedLeaseEnd],
              },
            ]
          : []

        const usedParametersPropertyAndPortfolio = getFilteredUsedParameter(
          usedParameters,
          PROPERTY_AND_PORTFOLIO_OBJECT_TYPE,
        )
        const filteredUsedParametersPropertyAndPortfolio = usedParametersPropertyAndPortfolio.map(
          (usedParameter) => {
            const filteredParameters = usedParameter.parameters.filter((parameter) =>
              PROPERTY_AND_PORTFOLIO_PARAMETERS.includes(
                parameter.cashflowParameterDefinition.name,
              ),
            )
            const getParameterName = (parameter) => parameter.cashflowParameterDefinition.name

            return {
              ...usedParameter,
              parameters: filteredParameters.sort(
                (a, b) =>
                  PROPERTY_AND_PORTFOLIO_PARAMETERS.indexOf(getParameterName(a)) -
                  PROPERTY_AND_PORTFOLIO_PARAMETERS.indexOf(getParameterName(b)),
              ),
            }
          },
        )

        return {
          dealUuid,
          cashflowId,
          filteredUsedParameters: [
            ...filteredUsedParameterGlobal,
            ...filteredUsedParametersPropertyAndPortfolio,
          ],
        }
      }),
    [
      dealUuidCashflowIdPairs,
      formatDate,
      multipleCashFlowScenarioData,
      multipleCashflowScenarioPeriodYearsData,
    ],
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isAnnualReviewDealsLoading,
      isTileMetaDataLoading,
      isCashflowScenarioPeriodYearsResultLoading,
      isCashflowScenarioPeriodQuartersResultLoading,
      isMultipleCashFlowScenarioLoading,
      isGroupsLoading,
      isConfigLoading,
    ],
    errorValues: [
      isAnnualReviewDealsError,
      isTileMetaDataError,
      isCashflowScenarioPeriodYearsResultError,
      isCashflowScenarioPeriodQuartersResultError,
      isMultipleCashFlowScenarioError,
      isGroupsError,
      isConfigError,
    ],
    errorDetails: [annualReviewDealsError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        multipleDealTileMetaData: multipleDealTileMetaDataArray,
        groups,
        config,
        multipleCashflowScenarioPeriodQuartersData,
        multipleCashflowScenarioPeriodYearsData,
        multipleFilteredUsedParameters,
        sourceRender: {
          dealDisplayId: selectedDealDisplayId,
        },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealsData,
    multipleDealTileMetaDataArray,
    groups,
    config,
    multipleCashflowScenarioPeriodQuartersData,
    multipleCashflowScenarioPeriodYearsData,
    multipleFilteredUsedParameters,
    selectedDealDisplayId,
    error,
  ])
}

export default useAnnualReviewCashFlowAnalysis
