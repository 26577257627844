import {
  Button,
  ButtonDesign,
  Link,
  MessageBoxActions,
  MessageBoxTypes,
  MessageStrip,
  MessageStripDesign,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import compact from 'lodash.compact'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { arrearsPermissions } from 'api/arrears/ArrearsPermissions'
import { eventStatus } from 'api/events/status'
import styles from 'components/domains/business-partners/tile/arrears/current-approvals/CurrentApprovalsCard.module.css'
import CurrentApprovalsTable from 'components/domains/business-partners/tile/arrears/current-approvals/CurrentApprovalsTable'
import CurrentApprovalsTile from 'components/domains/business-partners/tile/arrears/current-approvals/CurrentApprovalsTile'
import useApprovalAndDecisionPaperStatus, {
  ACTIVE,
  DECISION_PAPER_MISSING,
  IN_SYNC,
  LOCKED,
  NO_INFORMATION,
  OUT_OF_SYNC,
} from 'components/domains/business-partners/tile/arrears/current-approvals/useApprovalAndDecisionPaperStatus'
import useCurrentApprovalsTableColumns from 'components/domains/business-partners/tile/arrears/current-approvals/useCurrentApprovalsTableColumns'
import useCurrentApprovalsTableData from 'components/domains/business-partners/tile/arrears/current-approvals/useCurrentApprovalsTableData'
import useCurrentApprovalsTableEdit from 'components/domains/business-partners/tile/arrears/current-approvals/useCurrentApprovalsTableEdit'
import { CardHeaderLastEditedText } from 'components/domains/deals/card/CardHeaderLastEditedText'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import { useLockArrearApprovalById } from 'hooks/services/arrears/useLockArrearApprovalById'
import { BusinessPartnerArrearsContext } from 'routes/business-partners/arrears/BusinessPartnerArrearsContext'
import { BUSINESS_PARTNER } from 'routes/business-partners/arrears/useBusinessPartnerArrears'

const CurrentApprovalsCard = () => {
  const {
    arrearApprovals,
    arrearApprovalEvents,
    businessPartnerId,
    businessPartnerPdLevel,
    isLoading,
    isError,
    allowedOperations,
  } = useContext(BusinessPartnerArrearsContext)

  const hasPermission = allowedOperations.includes(arrearsPermissions.writeApprovals)

  const latestApproval = arrearApprovals?.at(0)

  const eventForLatestApproval = arrearApprovalEvents?.find(
    (event) => event?.id === latestApproval?.eventId,
  )
  const latestApprovalOngoing = eventForLatestApproval?.status === eventStatus.ongoing

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.in-approval',
  })
  const { t: tNoPrefix } = useTranslation('translation')

  const { approvalStatus, decisionPaperSyncStatus } = useApprovalAndDecisionPaperStatus({
    arrearApprovalLocked: latestApproval?.isLocked,
    event: eventForLatestApproval,
  })

  const approvalAndEventExists = latestApproval && eventForLatestApproval

  const isBpPdLevelBelowLimit = businessPartnerPdLevel?.isBelowLimit ?? true

  const isEditable = useMemo(
    () =>
      approvalAndEventExists &&
      isBpPdLevelBelowLimit &&
      eventForLatestApproval.status === eventStatus.ongoing &&
      hasPermission &&
      approvalStatus() === ACTIVE &&
      (decisionPaperSyncStatus() === IN_SYNC ||
        decisionPaperSyncStatus() === DECISION_PAPER_MISSING),
    [
      approvalAndEventExists,
      isBpPdLevelBelowLimit,
      eventForLatestApproval,
      approvalStatus,
      decisionPaperSyncStatus,
      hasPermission,
    ],
  )

  const { data: tableData, datacount } = useCurrentApprovalsTableData(false)

  const arrearApprovalEditFunctions = useCurrentApprovalsTableEdit({ tableData })

  const { tableColumns } = useCurrentApprovalsTableColumns({
    isEditable,
    arrearApprovalEditFunctions,
  })

  const renderTable = () => (
    <CurrentApprovalsTable
      tableColumns={tableColumns}
      tableData={tableData}
      datacount={datacount}
    />
  )

  const queryClient = useQueryClient()

  const onSuccess = () => {
    queryClient.invalidateQueries(['arrears', businessPartnerId])
    queryClient.invalidateQueries(['arrears', 'approvals', businessPartnerId])
    queryClient.invalidateQueries(['events', BUSINESS_PARTNER, businessPartnerId])
  }

  const showErrorMessageBox = useShowErrorMessageBox()

  const onError = async (error) => {
    const errorResponseJson = await error.response.json()
    showErrorMessageBox({ message: t('message-box.error.lock'), error: errorResponseJson })
  }

  const lockArrearApproval = useLockArrearApprovalById({ onSuccess, onError })

  const showMessageBox = useShowMessageBox()

  const onButtonClick = useCallback(
    () =>
      showMessageBox(
        {
          type: MessageBoxTypes.Confirm,
          titleText: tNoPrefix('buttons.confirm'),
          children: t('close-approval.confirm'),
          actions: [
            <Button
              id={'confirm-button'}
              key={'message-box-confirm-button'}
              design={ButtonDesign.Emphasized}
              onClick={() => {
                lockArrearApproval.mutate(latestApproval?.id)
              }}
            >
              {tNoPrefix('buttons.confirm')}
            </Button>,
            MessageBoxActions.Cancel,
          ],
        },
        document.body,
      ),
    [latestApproval?.id, lockArrearApproval, showMessageBox, t, tNoPrefix],
  )

  const approvalCanBeClosed =
    approvalAndEventExists &&
    isBpPdLevelBelowLimit &&
    hasPermission &&
    eventForLatestApproval.status !== eventStatus.ongoing &&
    approvalStatus() !== LOCKED

  const renderButton = useCallback(() => {
    if (approvalCanBeClosed) {
      return <Button onClick={onButtonClick}>{t('close-approval')}</Button>
    }
  }, [approvalCanBeClosed, onButtonClick, t])

  const renderMessageStrip = useCallback(() => {
    const showMessageStrip =
      decisionPaperSyncStatus() === NO_INFORMATION || decisionPaperSyncStatus() === OUT_OF_SYNC
    return (
      showMessageStrip && (
        <MessageStrip design={MessageStripDesign.Warning} hideCloseButton>
          {t('message-strip.warning')}
          <Link
            href={`/business-events-and-tasks/business-events/${eventForLatestApproval?.id}/decision-paper`}
            target="_blank"
            rel="noreferrer"
          >
            {t('message-strip.open-decision-paper')}
          </Link>
        </MessageStrip>
      )
    )
  }, [decisionPaperSyncStatus, eventForLatestApproval?.id, t])

  return (
    <Card
      header={
        <CardHeaderLastEditedText
          title={t('title')}
          email={latestApprovalOngoing ? latestApproval?.updatedBy : ''}
          timestamp={latestApprovalOngoing ? latestApproval?.updatedAt : ''}
          actions={renderButton()}
        />
      }
    >
      {renderMessageStrip()}
      <CurrentApprovalsTile
        arrearApprovalLocked={latestApproval?.isLocked}
        event={eventForLatestApproval}
      />
      <div className={compact([styles.tableWrapper, isLoading && styles.loadingWrapper]).join(' ')}>
        <RequestStateResolver
          isLoading={isLoading}
          isError={isError}
          renderContent={renderTable}
          errorToDisplay={<ErrorDataUnavailableInContent />}
          center
        />
      </div>
    </Card>
  )
}

export default CurrentApprovalsCard
