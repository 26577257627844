import PropTypes from 'prop-types'
import { useCallback } from 'react'
import AnnualReviewManualCheckSheetTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/appendix/manual-checksheet/AnnualReviewManualCheckSheetTile'
import MultipleBorrowerSelectionPdfViewWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/appendix/wrapper/MultipleBorrowerSelectionPdfViewWrapper'

const AnnualReviewManualCheckSheetTileWrapper = ({
  tileId,
  isPdfView,
  currentContent,
  onChange,
  isEditMode,
  setHasContentChanges,
  ...tileProps
}) => {
  const contentCreator = useCallback(
    ({ selectedBorrowerId }) => (
      <AnnualReviewManualCheckSheetTile
        tileId={tileId}
        selectedBorrowerId={selectedBorrowerId}
        currentContent={currentContent}
        onChange={onChange}
        isEditMode={isEditMode}
        setHasContentChanges={setHasContentChanges}
        {...tileProps}
      />
    ),
    [currentContent, isEditMode, onChange, setHasContentChanges, tileId, tileProps],
  )

  return (
    <MultipleBorrowerSelectionPdfViewWrapper
      tileId={tileId}
      contentCreator={contentCreator}
      isPdfView={isPdfView}
    />
  )
}

AnnualReviewManualCheckSheetTileWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  currentContent: PropTypes.arrayOf(
    PropTypes.shape({
      borrowerId: PropTypes.string.isRequired,
      borrowerName: PropTypes.string,
      answers: PropTypes.shape({}),
    }),
  ),
  onChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
}
export default AnnualReviewManualCheckSheetTileWrapper
