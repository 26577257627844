import { Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { createRoot } from 'react-dom/client'
import styles from 'components/ui/select/SelectWithFooter.module.css'

const setFooterStyles = (footer) => {
  footer.setAttribute('class', 'select-footer')
  footer.setAttribute('style', 'padding: 0.5rem 1rem;border-top: 1px solid rgba(0,0,0,0.42);')
}

function useSelectFooter(selectRef, footer) {
  const [initialRender, setInitialrender] = useState(false)
  useEffect(() => {
    if (!initialRender) setInitialrender(true)
    const staticAreaItemDom = selectRef?.current?.staticAreaItem?.shadowRoot
    const responsivePopOver = staticAreaItemDom?.querySelector('ui5-responsive-popover')
    const responsivePopOverShadowRoot = responsivePopOver?.shadowRoot
    const popOverSection = responsivePopOverShadowRoot?.querySelector('section')
    const hasFooter = popOverSection?.querySelector('.select-footer') !== null
    if (popOverSection && !hasFooter) {
      const footerElement = document.createElement('div')
      setFooterStyles(footerElement)
      popOverSection.appendChild(footerElement)
      const root = createRoot(footerElement)
      root.render(footer)
    }
  }, [selectRef, initialRender, footer])
}

const SelectWithFooter = forwardRef(
  ({ id, onChange, footer, children, ...additionalSelectProps }, externalRef) => {
    const selectRef = useRef()
    useImperativeHandle(externalRef, () => selectRef.current)
    useSelectFooter(selectRef, footer)

    return (
      <>
        <Select
          id={id}
          className={styles.select}
          ref={selectRef}
          onChange={onChange}
          onFocus={(event) => event.stopPropagation()}
          {...additionalSelectProps}
        >
          {children}
        </Select>
      </>
    )
  },
)

SelectWithFooter.displayName = 'SelectWithFooter'

SelectWithFooter.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  footer: PropTypes.node.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
}

export default SelectWithFooter
