import {
  TABLE_ROW_WITH_HEADQUARTER_CURRENCY_HEIGHT,
  TABLE_ROW_WITHOUT_HEADQUARTER_CURRENCY_HEIGHT,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/constants'

// get the latest date value for any attribute containing an UltimateMaximumExtensionDate
const ultimateMaximumExtensionDate = (trancheOptions) => {
  let latestDate = new Date(null) // 1970-01-01T00:00:00.000Z
  let latestDateString = undefined

  trancheOptions?.clusteredOptions?.EXTENSION?.options?.forEach((option) => {
    const uedAttribute = option?.attributes.find((attribute) =>
      attribute?.optionAttributeCode.includes('UltimateMaximumExtensionDate'),
    )
    if (uedAttribute) {
      const tempDate = new Date(uedAttribute?.value)
      if (tempDate > latestDate) {
        latestDate = tempDate
        latestDateString = uedAttribute?.value
      }
    }
  })
  return latestDateString
}

const mapTrancheDetailsData = (
  {
    rank,
    trancheId,
    displayId,
    borrowerId,
    trancheName,
    loanTypeCode,
    loanTypeShortText,
    maturityDate,
    firstDrawdownDate,
    latestDrawingDate,
    ownCommitmentShare,
    ownShare,
    ownShareHeadquarter,
    amortizationConditions,
    interestConditions,
    pricing,
    options,
    externalContractId: externalContractIds,
  },
  expectedSigningDate,
  isPdfView = false,
  allBusinessPartnersData,
) => {
  const rowHeight = () => {
    if (
      isPdfView &&
      (amortizationConditions?.items.length > 1 || interestConditions?.items.length > 1)
    ) {
      const nrOfEntries = Math.max(
        interestConditions?.items.length,
        amortizationConditions?.items.length,
      )
      return nrOfEntries * TABLE_ROW_WITH_HEADQUARTER_CURRENCY_HEIGHT
    } else if (
      ownShareHeadquarter?.currency !== ownShare?.currency ||
      amortizationConditions?.items.length === 1 ||
      interestConditions?.items.length === 1
    ) {
      return TABLE_ROW_WITH_HEADQUARTER_CURRENCY_HEIGHT
    } else {
      return TABLE_ROW_WITHOUT_HEADQUARTER_CURRENCY_HEIGHT
    }
  }

  return {
    rank,
    tranche: {
      name: trancheName,
      displayId,
      externalContractId: externalContractIds?.[0],
    },
    loanType: {
      key: loanTypeCode,
      text: loanTypeShortText,
    },
    borrower: {
      businessPartnerId: borrowerId,
      businessPartnerName: allBusinessPartnersData?.find(
        (businessPartner) => businessPartner?.id === borrowerId,
      )?.fullName,
    },
    commitment: {
      original: {
        value: ownShare?.commitment,
        currency: ownShare?.currency,
      },
      converted:
        ownShareHeadquarter?.currency !== ownShare?.currency
          ? {
              value: ownShareHeadquarter?.commitment,
              currency: ownShareHeadquarter?.currency,
            }
          : {},
      share: ownCommitmentShare,
    },
    outstandingAmount: {
      original: {
        value: ownShare?.outstanding,
        currency: ownShare?.currency,
      },
      converted:
        ownShareHeadquarter?.currency !== ownShare?.currency
          ? {
              value: ownShareHeadquarter?.outstanding,
              currency: ownShareHeadquarter?.currency,
            }
          : {},
    },
    maturity: {
      maturityDate: maturityDate,
      closingDate: expectedSigningDate,
    },
    availability: {
      latestDrawingDate: latestDrawingDate,
      closingDate: expectedSigningDate,
    },
    drawdownDates: {
      firstDrawdownDate,
      latestDrawingDate,
    },
    lastExtensionOption: {
      ultimateMaximumExtensionDate: ultimateMaximumExtensionDate(options),
      closingDate: expectedSigningDate,
    },
    repayment: (amortizationConditions?.items ?? []).map((item) => ({
      validFrom: item?.validFrom,
      amortizationConditionItemTypeShortText: item?.amortizationConditionItemTypeShortText,
      rate: item?.ratePerAnnum,
    })),
    interestRateType: (interestConditions?.items ?? []).map((item) => ({
      validFrom: item?.validFrom,
      interestRateShortText: item?.interestRateShortText,
      customerMargin: item?.customerMargin,
    })),
    netRevenueMargin: pricing?.netRevenueMargin,
    riskReturnRatio: pricing?.customFields?.riskReturnRatio?.value,
    trancheId,
    rowHeight: rowHeight(),
  }
}

export default mapTrancheDetailsData
