import { useMemo } from 'react'
import { useRequest, useRequests } from 'hooks/services/baseService'
import { useCamelizedResponse, useCombinedQueryResults } from 'hooks/services/queryHelper'
import useDataSourceQueryParams from 'routes/deals/financing/utils/useDataSourceQueryParams'

const useExternalTrancheDetails = (trancheId, forceDataSource) => {
  const { dataSourceQueryKeys, dataSourceQueryParam } = useDataSourceQueryParams(forceDataSource)
  return useCamelizedResponse(
    useRequest({
      path: `/financing-structures/deals/tranches/${trancheId}/details?${dataSourceQueryParam}`,
      keys: ['deals', 'tranches', trancheId, ...dataSourceQueryKeys],
      options: {
        enabled: !!trancheId,
      },
    }),
  )
}

export const useMultipleExternalTrancheDetails = (trancheIds, options) => {
  const { dataSourceQueryKeys, dataSourceQueryParam } = useDataSourceQueryParams()

  const requestsConfig = useMemo(
    () => ({
      requests: trancheIds.map((trancheId) => ({
        path: `/financing-structures/deals/tranches/${trancheId}/details?${dataSourceQueryParam}`,
        keys: ['deals', 'tranches', trancheId, ...dataSourceQueryKeys],
      })),
      options,
    }),
    [dataSourceQueryKeys, dataSourceQueryParam, options, trancheIds],
  )

  return useCamelizedResponse(useCombinedQueryResults(useRequests(requestsConfig)))
}

export default useExternalTrancheDetails
