import { Icon, IconDesign, SortItem, ViewSettingsDialog } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/shared/toolbar/ArrearsInApprovalToolbarSorting.module.css'

export const ArrearsInApprovalToolbarSortingPropTypes = {
  sorting: PropTypes.shape({
    columnKey: PropTypes.string,
    isSortingAscending: PropTypes.bool,
    sortableColumns: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        columnKey: PropTypes.string.isRequired,
      }),
    ).isRequired,
    onUpdateSorting: PropTypes.func.isRequired,
  }),
  disabled: PropTypes.bool,
}

const ToolbarSorting = ({ sorting, disabled }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.arrears-in-approval.components.ui.tables.toolbar',
  })
  const sortingDialogRef = useRef()

  const onIconClick = useCallback(() => sortingDialogRef.current.show(), [])

  const sortItems = useMemo(() => {
    if (sorting) {
      return sorting.sortableColumns.map(({ columnKey, title: text }) => (
        <SortItem text={text} key={columnKey} selected={sorting.columnKey === columnKey} />
      ))
    }
  }, [sorting])

  const onConfirm = useCallback(
    ({ detail: { sortBy, sortDescending } }) => {
      sorting.onUpdateSorting({
        sortBy,
        sortDescending,
      })
    },
    [sorting],
  )

  if (sorting) {
    if (disabled) {
      return (
        <div>
          <Icon
            className={styles.sortIconDisabled}
            name="sort"
            design={IconDesign.NonInteractive}
          />
        </div>
      )
    }
    return (
      <div>
        <Icon className={styles.sortIcon} name="sort" interactive onClick={onIconClick} />
        {createPortal(
          <ViewSettingsDialog
            style={{ margin: 0 }}
            title={t('sort.title')}
            sortDescending={!sorting.isSortingAscending}
            ref={sortingDialogRef}
            sortItems={sortItems}
            onConfirm={onConfirm}
          />,
          document.body,
        )}
      </div>
    )
  }
}

ToolbarSorting.propTypes = ArrearsInApprovalToolbarSortingPropTypes

export default ToolbarSorting
