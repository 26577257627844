import { useMemo } from 'react'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import useCovenantChecks from 'hooks/services/deals/covenants/checks/useCovenantChecks'
import useCovenantComments from 'hooks/services/deals/covenants/comment/useCovenantComments'
import useDealMini from 'hooks/services/deals/useDealMini'

const useCovenantCheckComment = (
  { entityRef: { entityId, entityType } = {}, id: eventId } = {},
  tileId,
) => {
  const {
    isFetching: isFetchingCovenantCheckList,
    isError: isErrorCovenantCheckList,
    data: { covenantChecks = [] } = {},
    error: errorCovenantCheckList,
  } = useCovenantChecks({
    mainEntityType: mainEntityTypes.DEAL,
    eventId,
  })

  const {
    data: deal,
    isFetching: isFetchingDeal,
    isError: isErrorDeal,
    error: dealError,
  } = useDealMini(entityId, { enabled: entityType === cwpEntityTypes.DEAL })

  const { covenantCheckUuid } = covenantChecks?.[0] ?? {}

  const {
    data: comment,
    isFetching: isFetchingComments,
    isError: isErrorComments,
    error: commentError,
  } = useCovenantComments({
    linkedEntityType: 'COVENANT_CHECK',
    linkedEntityId: covenantCheckUuid,
  })
  const latestComment = useMemo(() => comment?.availableVersions?.[0], [comment?.availableVersions])

  const { isSomeValueError, isSomeValueLoading, error } = useAutomaticTileHookHelper({
    loadingValues: [isFetchingCovenantCheckList, isFetchingComments, isFetchingDeal],
    errorValues: [isErrorCovenantCheckList, isErrorComments, isErrorDeal],
    errorDetails: [errorCovenantCheckList, commentError, dealError],
    tileId,
  })

  let path
  if (entityType === cwpEntityTypes.DEAL) {
    path = `/deals/${deal?.dealId}/covenants/covenant-checks/${covenantCheckUuid}`
  }
  if (entityType === cwpEntityTypes.BUSINESS_PARTNER) {
    path = `/business-partners/${entityId}/covenants/covenant-checks/${covenantCheckUuid}`
  }

  return useMemo(() => {
    if (isSomeValueLoading || isSomeValueError) {
      return {
        isLoading: isSomeValueLoading,
        isError: isSomeValueError,
        error,
      }
    }
    return {
      isLoading: false,
      isError: false,
      data: {
        comment: latestComment,
        sourceRender: {
          path,
        },
      },
    }
  }, [isSomeValueLoading, isSomeValueError, path, latestComment, error])
}

export default useCovenantCheckComment
