import { useMemo } from 'react'
import useBusinessPartnerRiskMonitoringStatus from 'components/domains/business-partners/useBusinessPartnerRiskMonitoringStatus'
import useBusinessPartnerArrearByBusinessPartnerId from 'hooks/services/arrears/useBusinessPartnerArrearByBusinessPartnerId'
import useArrearsCurrencyValues from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useArrearsCurrencyValues'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useBusinessPartnerRatings from 'hooks/services/business-partners/ratings/useBusinessPartnerRatings'

const useArrearsBusinessPartnerSummary = ({ entityRef: { entityId: businessPartnerId } } = {}) => {
  const {
    data: { businessPartnerMinis = [] } = {},
    isFetching: isLoadingBpMinis,
    isError: isErrorBpMinis,
  } = useBusinessPartnerMiniByIds([businessPartnerId])

  const businessPartnerName = businessPartnerMinis?.[0]?.fullName

  const {
    isFetching: isFetchingStatus,
    isError: isErrorStatus,
    data: riskMonitoringStatus,
  } = useBusinessPartnerRiskMonitoringStatus(businessPartnerId)

  const {
    data: { internal = [] } = {},
    isFetching: isLoadingRating,
    isError: isErrorRating,
  } = useBusinessPartnerRatings(businessPartnerId)
  const activeRating = internal.filter(({ isActive }) => isActive === true)?.[0]

  const {
    arrearsAmountAndCurrency,
    overpaymentAmountAndCurrency,
    currentClosedCommitmentTotal,
    currentClosedCommitmentLoans,
    currentClosedCommitmentLoansOwnShare,
    outstandingLoansOwnShare,
    availableLoansOwnShare,
    isLoading: isLoadingCurrencyValues,
    isError: isErrorCurrencyValues,
    isErrorBcaAmounts,
  } = useArrearsCurrencyValues(businessPartnerId)

  const {
    data: bpArrearData,
    isLoading: isLoadingBpArrear,
    isError: isErrorBpArrear,
  } = useBusinessPartnerArrearByBusinessPartnerId({
    businessPartnerId,
  })

  const isLoading =
    isLoadingBpMinis ||
    isLoadingRating ||
    isFetchingStatus ||
    isLoadingCurrencyValues ||
    isLoadingBpArrear
  const isError =
    isErrorBpMinis || isErrorRating || isErrorStatus || isErrorCurrencyValues || isErrorBpArrear

  return useMemo(
    () => ({
      isLoading,
      isError,
      data:
        !isLoading && !isError
          ? {
              sourceRender: { businessPartnerId },
              businessPartner: { businessPartnerId, businessPartnerName },
              ratingClass: activeRating?.ratingClass,
              validFrom: activeRating?.validFrom,
              riskMonitoringStatus,
              arrearsAmountAndCurrency,
              overpaymentAmountAndCurrency,
              currentClosedCommitmentTotal,
              currentClosedCommitmentLoansOwnShare,
              currentClosedCommitmentLoans,
              outstandingLoansOwnShare,
              availableLoansOwnShare,
              daysOfGrace: bpArrearData?.pastDueDefaultDays,
              valutaDate: bpArrearData?.since,
              defaultQuote: bpArrearData?.dodQuota,
              isErrorBcaAmounts,
            }
          : undefined,
    }),
    [
      activeRating?.ratingClass,
      activeRating?.validFrom,
      arrearsAmountAndCurrency,
      bpArrearData?.dodQuota,
      bpArrearData?.pastDueDefaultDays,
      bpArrearData?.since,
      businessPartnerId,
      businessPartnerName,
      currentClosedCommitmentLoans,
      currentClosedCommitmentLoansOwnShare,
      currentClosedCommitmentTotal,
      isError,
      isLoading,
      outstandingLoansOwnShare,
      availableLoansOwnShare,
      overpaymentAmountAndCurrency,
      riskMonitoringStatus,
      isErrorBcaAmounts,
    ],
  )
}

export default useArrearsBusinessPartnerSummary
