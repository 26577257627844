import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useMarketCreation = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({
      code,
      name,
      description,
      countryCode,
      polygonCoordinates,
      externalId,
      externalIdType,
    }) => {
      const { data } = await post({
        path: '/markets',
        body: {
          info: {
            name,
            description,
          },
          code,
          area: {
            polygon_coordinates: polygonCoordinates,
            country_code: countryCode,
          },
          external_id: externalId === '' ? undefined : externalId,
          external_id_type: externalIdType === '' ? undefined : externalIdType,
        },
      })
      return data
    },
    { onSuccess, onError },
  )
}
