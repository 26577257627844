import mapOwnShareSyndicationShares from 'routes/deals/syndication/syndicationStructure/utils/backendToTableStructure/mapOwnShareSyndicationShares'
import {
  DEFAULT_DISPLAY_ROW_HEIGHT,
  DEFAULT_DISPLAY_HEIGHT_WITHOUT_TEXT,
  ROW_TYPE_ROLE,
  DEFAULT_DISPLAY_TEXT_HEIGHT,
} from 'routes/deals/syndication/syndicationStructure/utils/constants'

const mapOwnShareRoles = (roles, rootRowId) =>
  roles.map((role) => {
    const syndicationFee = role.syndicationFee ?? {}
    const calculatedRowHeight =
      DEFAULT_DISPLAY_HEIGHT_WITHOUT_TEXT +
      (syndicationFee.amountPerAnnumHeadquarter || syndicationFee.amountTermHeadquarter
        ? DEFAULT_DISPLAY_TEXT_HEIGHT * 2
        : DEFAULT_DISPLAY_TEXT_HEIGHT)

    return {
      rootRowId,
      rowType: ROW_TYPE_ROLE,
      partyRoleTranche: {
        value: role.roleTypeShortText,
        code: role.roleTypeCode,
      },
      rowHeight: Math.max(calculatedRowHeight, DEFAULT_DISPLAY_ROW_HEIGHT),
      subRows: mapOwnShareSyndicationShares(role.syndicationShares ?? [], rootRowId),
    }
  })

export default mapOwnShareRoles
