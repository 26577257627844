import PropTypes from 'prop-types'
import { DealCashflowFinancialRatioCard } from 'components/domains/deals/deal-cashflow/result-charts/DealCashflowFinancialRatioCard'
import { periodValues } from 'components/domains/deals/deal-cashflow/results/PeriodSelect'
import useCashflowGroups from 'hooks/services/deals/cashflow/useCashflowGroups'
import useCashflowScenarioResultsById from 'hooks/services/deals/cashflow/useCashflowScenarioResultsById'

const DealCashflowFinancialRatioCardWrapper = ({
  dealUuid,
  cashflowScenarioUuid,
  defaultKpiCode,
  defaultCategory,
}) => {
  const {
    data: { calculatedKpis = [] } = {},
    isLoading: isLoadingData,
    isError: isErrorData,
  } = useCashflowScenarioResultsById({
    dealUuid,
    cashflowId: cashflowScenarioUuid,
    datasetPeriod: periodValues.years,
  })

  const {
    data: { groups = [] } = {},
    isLoading: isLoadingGroups,
    isError: isErrorGroups,
  } = useCashflowGroups()

  return (
    <DealCashflowFinancialRatioCard
      isLoading={isLoadingData || isLoadingGroups}
      isError={isErrorData || isErrorGroups}
      calculatedKpis={calculatedKpis}
      groups={groups}
      defaultKpiCode={defaultKpiCode}
      defaultCategory={defaultCategory}
    />
  )
}

DealCashflowFinancialRatioCardWrapper.propTypes = {
  dealUuid: PropTypes.string.isRequired,
  cashflowScenarioUuid: PropTypes.string.isRequired,
  defaultKpiCode: PropTypes.string,
  defaultCategory: PropTypes.string,
}

export default DealCashflowFinancialRatioCardWrapper
