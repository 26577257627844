import { MessageStrip, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import { isUndefined } from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/manual-checksheet/BaselTwoManualCheckSheetResultMessageStrip.module.css'
import { baselTwoManualCheckSheetSchema } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/manual-checksheet/baselTwoManualCheckSheetSchema'

const BaselTwoManualCheckSheetResultMessageStrip = ({ currentAnswers, isEditMode }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.basel-two.checksheet.manual',
  })
  const { success: isCompletedQuestionnaire } = baselTwoManualCheckSheetSchema.safeParse(
    currentAnswers || {},
  )

  return (
    !isEditMode &&
    !isUndefined(currentAnswers) && (
      <div className={styles.messageStripWrapper}>
        <MessageStrip
          design={
            isCompletedQuestionnaire ? MessageStripDesign.Positive : MessageStripDesign.Warning
          }
          hideCloseButton
        >
          <span>{isCompletedQuestionnaire ? t('complete') : t('not-complete')}</span>
        </MessageStrip>
      </div>
    )
  )
}

BaselTwoManualCheckSheetResultMessageStrip.propTypes = {
  currentAnswers: PropTypes.shape({}),
  isEditMode: PropTypes.bool,
}

export default BaselTwoManualCheckSheetResultMessageStrip
