import { FlexBox, FlexBoxDirection, Title, TitleLevel } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import snakecaseKeys from 'snakecase-keys'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-history/MultipleFactSheetValuationHistoryV1.module.css'
import AnnualReviewDealOverviewPropertySelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-selection/AnnualReviewDealOverviewPropertySelect'
import AnnualReviewPropertySelectPdfView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-selection/AnnualReviewPropertySelectPdfView'
import useAnnualReviewDealOverviewSelectedProperty from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/utils/useAnnualReviewDealOverviewSelectedProperty'
import { ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/constants'
import {
  useNumberFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'
import PropertyValuationResultsHistoryChart from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/deal-specifics/shared/properties/valuation/PropertyValuationResultsHistoryChart'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const compareValuationResultsByAscendingDateUnixTimestamp = (valuationResult1, valuationResult2) =>
  valuationResult1.dateUnixTimestamp - valuationResult2.dateUnixTimestamp

const legacyValuationClassification = {
  marketValue: 'Market Value',
  mortgageLendingValue: 'Mortgage Lending Value',
  other: 'Other',
}

const legacyValuationRelevance = {
  relevant: 'Relevant',
  nonRelevant: 'Non Relevant',
}

const prepareValuationResultsData = (valuations = []) => {
  const filteredValuations = valuations
    ?.filter(
      (valuation) =>
        valuation.regulatory_relevance === legacyValuationRelevance.relevant &&
        (valuation.classification === legacyValuationClassification.marketValue ||
          valuation.classification === legacyValuationClassification.mortgageLendingValue),
    )
    ?.map((valuation) => ({
      dateUnixTimestamp: new Date(valuation.key_date).getTime(),
      currency: valuation.value_amount.currency,
      ...(valuation.classification === legacyValuationClassification.marketValue && {
        marketValue: valuation.value_amount.number,
        marketValueMethod: valuation.calculation_method_name,
      }),
      ...(valuation.classification === legacyValuationClassification.mortgageLendingValue && {
        mortgageLendingValue: valuation.value_amount.number,
        mortgageLendingValueMethod: valuation.calculation_method_name,
      }),
    }))

  let preparedValuationDataArray = []
  filteredValuations?.forEach(
    (a) =>
      (preparedValuationDataArray[a.dateUnixTimestamp] = {
        ...preparedValuationDataArray[a.dateUnixTimestamp],
        ...a,
      }),
  )
  preparedValuationDataArray = Object.values(preparedValuationDataArray)
  preparedValuationDataArray.sort(compareValuationResultsByAscendingDateUnixTimestamp)
  return preparedValuationDataArray
}

const MultipleFactSheetValuationHistoryV1 = ({ tileId, selectedDealIndex, isPdfView = false }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.fact-sheet-valuation.pages.property.valuation.results',
  })

  const formatNumber = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: 'compact',
  })
  const { format: formatDate } = useShortDateFormatter()

  const {
    data: {
      multipleArrayPropertyUuidsData = [],
      propertyValuationsByArrayData = [],
      multiArrayPropertyValuationsData = [],
    } = {},
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  // Freezing forces camelization onto all object keys, which breaks some re-used data access code.
  // Since the original data is snake case, this solves freezing issues.
  const propertiesData = useMemo(
    () =>
      snakecaseKeys(multipleArrayPropertyUuidsData?.[selectedDealIndex]?.data?.properties ?? []),
    [multipleArrayPropertyUuidsData, selectedDealIndex],
  )

  const { selectedPropertyUuid, selectedPropertyDisplayId, selectedPropertyName } =
    useAnnualReviewDealOverviewSelectedProperty({
      propertiesData,
      propertyMetadataTileCode:
        ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES.PROPERTY_PORTFOLIO_SUBSECTION,
    })

  useEffect(() => {
    dispatch(
      setSourceRender({
        tileId,
        sourceRender: { propertyId: selectedPropertyDisplayId },
      }),
    ) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPropertyDisplayId])

  const preparedValuationResultData = useCallback(
    ({ uuid: currentPropertyUuid }) => {
      const selectedValuations = propertyValuationsByArrayData?.find(
        (property) => property.propertyUuid[0] === currentPropertyUuid,
      )?.valuations
      return prepareValuationResultsData(snakecaseKeys(selectedValuations ?? []))
    },
    [propertyValuationsByArrayData],
  )

  const renderHeader = useCallback(
    ({ uuid: currentPropertyUuid }) => {
      const propertyValuations = multiArrayPropertyValuationsData?.find(
        (property) =>
          !isNil(property?.valuations?.find(({ uuid }) => uuid === currentPropertyUuid)),
      )?.valuations?.[0]

      const marketValue = propertyValuations?.['marketValue']
      const marketCurrency = marketValue?.valueAmount.currency
      const marketNumber = marketValue?.valueAmount.number
      const marketDate = marketValue?.keyDate
      const mortgageValue = propertyValuations?.['mortgageLendingValue']
      const mortgageCurrency = mortgageValue?.valueAmount?.currency
      const mortgageNumber = mortgageValue?.valueAmount.number
      const mortgageDate = mortgageValue?.keyDate

      return (
        <>
          {preparedValuationResultData({ uuid: currentPropertyUuid }).length !== 0 && (
            <FlexBox className={styles.spacingWrapper} direction={FlexBoxDirection.Row}>
              <FlexBox direction={FlexBoxDirection.Column}>
                <Title level={TitleLevel.H6} className={styles.titleBottomSpacing}>{`${t(
                  'card.header.market-value',
                )} | ${marketCurrency ? marketCurrency : '-'}`}</Title>
                <Title level={TitleLevel.H2}>
                  {!isNil(marketNumber) ? formatNumber(marketNumber) : '-'}
                </Title>
                <Title level={TitleLevel.H6}>{marketDate ? formatDate(marketDate) : '-'}</Title>
              </FlexBox>
              <FlexBox className={styles.flexBoxLeftSpacing} direction={FlexBoxDirection.Column}>
                <Title level={TitleLevel.H6} className={styles.titleBottomSpacing}>{`${t(
                  'card.header.mortage-landing-value',
                )} | ${mortgageCurrency ? mortgageCurrency : '-'}`}</Title>
                <Title level={TitleLevel.H2}>
                  {!isNil(mortgageNumber) ? formatNumber(mortgageNumber) : '-'}
                </Title>
                <Title level={TitleLevel.H6}>{mortgageDate ? formatDate(mortgageDate) : '-'}</Title>
              </FlexBox>
            </FlexBox>
          )}
        </>
      )
    },
    [formatDate, formatNumber, multiArrayPropertyValuationsData, preparedValuationResultData, t],
  )

  const renderContent = useCallback(
    (currentProperty) => {
      const currentPreparedValuationResultData = preparedValuationResultData(currentProperty)
      return (
        <div key={currentProperty?.uuid}>
          {isPdfView ? (
            <AnnualReviewPropertySelectPdfView {...currentProperty} />
          ) : (
            <AnnualReviewDealOverviewPropertySelect
              propertiesData={propertiesData}
              propertyMetadataTileCode={
                ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES.PROPERTY_PORTFOLIO_SUBSECTION
              }
            />
          )}
          <DecisionPaperTileListSeparator />
          {renderHeader(currentProperty)}
          <DecisionPaperTileListSeparator />
          <div className={styles.spacingWrapper}>
            {!!currentProperty?.uuid && currentPreparedValuationResultData && (
              <PropertyValuationResultsHistoryChart
                preparedValuationResultData={currentPreparedValuationResultData}
              />
            )}
          </div>
        </div>
      )
    },
    [isPdfView, preparedValuationResultData, propertiesData, renderHeader],
  )

  return isPdfView
    ? propertiesData?.map((property) => renderContent(property))
    : renderContent({
        uuid: selectedPropertyUuid,
        id: selectedPropertyDisplayId,
        description: selectedPropertyName,
      })
}

MultipleFactSheetValuationHistoryV1.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultipleFactSheetValuationHistoryV1
