import { TextAlign } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { UI5_TABLE_CELL_PADDING } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/display-and-edit-table/constants'
import { useBooleanToTextFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'
import { filterTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/sorted-tables/useFilters'

export const ValuationResultsTableColumns = {
  valuationType: 'valuationType',
  method: 'method',
  regulatoryRelevant: 'regulatoryRelevant',
  result: 'result',
  keyDate: 'keyDate',
  validFrom: 'validFrom',
  validTo: 'validTo',
}

const usePropertyValuationResultsTableColumns = ({ valuationTypeEnum, methodEnum }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.valuation-results.pages.property.valuation.results',
  })
  const formatBoolean = useBooleanToTextFormatter()

  const columnDefinitions = useMemo(
    () => [
      {
        title: t('table.title-row.valuation-type'),
        columnKey: ValuationResultsTableColumns.valuationType,
        filter: filterTypes.OF_ENUM_TYPE,
        additionalFilterOptions: {
          enumValues: valuationTypeEnum,
        },
        demandPopin: false,
        minWidth: 200,
      },
      {
        title: t('table.title-row.result'),
        popinText: t('table.title-row.result'),
        columnKey: ValuationResultsTableColumns.result,
        sortingDisabled: true,
        demandPopin: true,
        minWidth: 200,
        filter: filterTypes.BETWEEN_NUMBERS,
        alignment: TextAlign.End,
      },
      {
        title: t('table.title-row.key-date'),
        columnKey: ValuationResultsTableColumns.keyDate,
        filter: filterTypes.BETWEEN_DATES,
        demandPopin: true,
        minWidth: 125,
        popinText: t('table.title-row.key-date'),
        alignment: TextAlign.End,
      },
      {
        title: t('table.title-row.valid-from'),
        columnKey: ValuationResultsTableColumns.validFrom,
        filter: filterTypes.BETWEEN_DATES,
        demandPopin: true,
        minWidth: 125,
        popinText: t('table.title-row.valid-from'),
        alignment: TextAlign.End,
      },
      {
        title: t('table.title-row.valid-to'),
        columnKey: ValuationResultsTableColumns.validTo,
        filter: filterTypes.BETWEEN_DATES,
        demandPopin: true,
        minWidth: 125,
        popinText: t('table.title-row.valid-to'),
        alignment: TextAlign.End,
      },
      {
        title: t('table.title-row.regulatory-relevant'),
        columnKey: ValuationResultsTableColumns.regulatoryRelevant,
        filter: filterTypes.OF_ENUM_TYPE,
        additionalFilterOptions: {
          enumValues: Object.fromEntries(
            [true, false].map((value) => [formatBoolean(value), formatBoolean(value)]),
          ),
        },
        demandPopin: true,
        minWidth: 125,
        popinText: t('table.title-row.regulatory-relevant'),
      },
      {
        title: t('table.title-row.method'),
        columnKey: ValuationResultsTableColumns.method,
        filter: filterTypes.OF_ENUM_TYPE,
        additionalFilterOptions: {
          enumValues: methodEnum,
        },
        popinText: t('table.title-row.method'),
        demandPopin: true,
        minWidth: 250,
      },
    ],
    [formatBoolean, methodEnum, t, valuationTypeEnum],
  )

  return useMemo(() => {
    let minWidthSum = 0
    return {
      columnDefinitions: columnDefinitions.map((column) => {
        // we still add each column's minWidth to the sum,
        // because we need the overall width relative to the table width for the breakpoint
        minWidthSum += (column?.minWidth ?? 0) + UI5_TABLE_CELL_PADDING
        return {
          ...column,
          minWidth: minWidthSum,
        }
      }),
    }
  }, [columnDefinitions])
}

export default usePropertyValuationResultsTableColumns
