import compact from 'lodash.compact'
import { useMemo } from 'react'
import useBusinessPartnerCustomFieldConfig from 'components/domains/business-partners/tile/general-information/useBusinessPartnerCustomFieldConfig'
import useBusinessPartnerCustomFields from 'components/domains/business-partners/tile/general-information/useBusinessPartnerCustomFields'
import useLookupBackendFieldName from 'components/domains/business-partners/tile/risk-monitoring/useLookupBackendFieldName'
import { useConfig } from 'hooks/config/useConfig'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'

const ENTITY_TYPE = 'BUSINESS_PARTNER'
const FOLLOW_UP_DATE_KEY = 'FOLLOW_UP_DATE'

/**
 * @param {object} params
 * @param {string} params.tileId
 * @param {object} params.entityRef
 * @param {string} params.entityRef.entityId
 */
const useCriticalFacilityData = ({ tileId, entityRef: { entityId: businessPartnerId } }) => {
  const {
    data: config,
    isLoading: isConfigLoading,
    isError: isConfigError,
    error: configError,
  } = useConfig()

  const fields = useMemo(
    () => config?.businessPartner?.customFields?.criticalFacility?.fields ?? [],
    [config?.businessPartner?.customFields?.criticalFacility?.fields],
  )

  const fieldMapping = useMemo(
    () => config?.businessPartner?.customFields?.fieldMapping ?? [],
    [config?.businessPartner?.customFields?.fieldMapping],
  )

  const followUpDateFieldName = useLookupBackendFieldName(fieldMapping ?? [], FOLLOW_UP_DATE_KEY, {
    enabled: !isConfigLoading && !isConfigError,
  })

  const {
    data: { customFieldMetadata } = {},
    isLoading: isMetadataLoading,
    isError: isMetadataError,
    error: metadataError,
  } = useBusinessPartnerCustomFieldConfig(ENTITY_TYPE)

  const orderedAndFilteredFieldMetadata = useMemo(
    () => compact(fields.map((field) => customFieldMetadata?.find(({ key }) => key === field))),
    [customFieldMetadata, fields],
  )

  const {
    data: { customFields } = {},
    isLoading: isCustomFieldsLoading,
    isError: isCustomFieldsError,
    error: customFieldsError,
  } = useBusinessPartnerCustomFields(businessPartnerId)

  // HINT: we should only have to filter / sort one of the custom fields or the field metadata,
  //       but then we're dependent on the direction in which they are joined
  const orderedAndFilteredCustomFieldData = useMemo(
    () => compact(fields.map((field) => customFields?.find(({ key }) => key === field))),
    [customFields, fields],
  )

  const {
    isSomeValueError: isError,
    isSomeValueLoading: isLoading,
    error,
  } = useAutomaticTileHookHelper({
    tileId,
    loadingValues: [isConfigLoading, isMetadataLoading, isCustomFieldsLoading],
    errorValues: [isConfigError, isMetadataError, isCustomFieldsError],
    errorDetails: [configError, metadataError, customFieldsError],
  })

  return {
    isLoading,
    isError,
    error,
    data: {
      followUpDateFieldName,
      orderedAndFilteredFieldMetadata,
      orderedAndFilteredCustomFieldData,
      sourceRender: {
        businessPartnerId,
      },
    },
  }
}

export default useCriticalFacilityData
