import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react/dist'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isMajorVersion } from 'api/decision-paper/decisionPaperApi'
import DecisionPaperWorkingVersionPopover from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperWorkingVersionPopover'

const buttonId = 'workingVersionButtonId'

const DecisionPaperWorkingVersionButton = ({
  lastEditedOn,
  majorVersionLastSyncedOn,
  minorVersionLastSyncedOn,
  synchronizationStatus,
  versions,
  templateCode,
  templateName,
  templateIsCustomized,
  disabled,
  showFreezeButton,
  existingDecisionPaperTemplate,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper',
  })
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const lastVersion = useMemo(() => versions.slice(-1)[0].version, [versions])

  const workingVersionLabel = useMemo(
    () =>
      isMajorVersion(lastVersion)
        ? t('working-major-version-popover')
        : t('working-minor-version-popover'),
    [lastVersion, t],
  )

  const popoverHeader = useMemo(
    () =>
      isMajorVersion(lastVersion)
        ? t('working-version-popover.header-text-working-version-major', {
            versionNumber: lastVersion,
          })
        : t('working-version-popover.header-text-working-version-minor', {
            versionNumber: lastVersion,
          }),
    [lastVersion, t],
  )

  return (
    <>
      <Button
        id={buttonId}
        design={ButtonDesign.Transparent}
        onClick={() => setIsPopoverOpen(true)}
        icon="slim-arrow-down"
        iconEnd
      >
        {workingVersionLabel}
      </Button>
      <DecisionPaperWorkingVersionPopover
        headerText={popoverHeader}
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        lastEditedOn={lastEditedOn}
        majorVersionLastSyncedOn={majorVersionLastSyncedOn}
        minorVersionLastSyncedOn={minorVersionLastSyncedOn}
        openerId={buttonId}
        synchronizationStatus={synchronizationStatus}
        versions={versions}
        templateCode={templateCode}
        templateName={templateName}
        templateIsCustomized={templateIsCustomized}
        isMajorVersion={isMajorVersion(lastVersion)}
        disabled={disabled}
        showFreezeButton={showFreezeButton}
        existingDecisionPaperTemplate={existingDecisionPaperTemplate}
      />
    </>
  )
}

DecisionPaperWorkingVersionButton.propTypes = {
  lastEditedOn: PropTypes.string,
  majorVersionLastSyncedOn: PropTypes.string,
  minorVersionLastSyncedOn: PropTypes.string,
  synchronizationStatus: PropTypes.string.isRequired,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      version: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  ).isRequired,
  templateCode: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired,
  templateIsCustomized: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  showFreezeButton: PropTypes.bool,
  existingDecisionPaperTemplate: PropTypes.shape({
    code: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        subsections: PropTypes.arrayOf(
          PropTypes.shape({
            code: PropTypes.string.isRequired,
            tiles: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string.isRequired })),
          }),
        ),
        tiles: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string.isRequired })),
      }),
    ),
  }),
}

export default DecisionPaperWorkingVersionButton
