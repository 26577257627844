import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useEditDecisionStageVoterCondition = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(
    ({ decisionStageId, eventId, voterConditionId, condition }) =>
      patch({
        path: `/events/${eventId}/decision-stages/${decisionStageId}/conditions/decision-stage-voter-conditions/${voterConditionId}`,
        body: {
          condition,
        },
      }),
    mutationOptions,
  )
}

export default useEditDecisionStageVoterCondition
