import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Link,
  LinkDesign,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/change-overview/DealChangeOverviewChangedInformationCell.module.css'
import { LINK_TYPES } from 'components/domains/deals/change-overview/constants'
import ModularTableCell from 'components/domains/properties/modular-table/ModularTableCell'
import LoadingContent from 'components/ui/content/LoadingContent'
import useChangedInformationCellData from 'hooks/services/deals/change-overview/useChangedInformationCellData'
import { DealContext } from 'routes/deals/DealContext'

const DealChangeOverviewChangedInformationCell = ({
  changeLog,
  renderPageLinkOnly = false,
  renderPagePrefix = true,
  linkDesign = LinkDesign.Default,
}) => {
  const { t: tDealChangeOverview } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.change-overview',
  })

  const DEFAULT_PAGE_LINK_PREFIX = renderPagePrefix ? tDealChangeOverview('prefix.deals') : ''

  const {
    deal: { displayId: dealDisplayId, dealUuid },
  } = useContext(DealContext)

  const { isLoading: isLoadingChangedInformation, data: changedInformationData } =
    useChangedInformationCellData({
      dealUuid,
      dealDisplayId,
      changeLog,
    })

  const renderFlexboxWithContent = (content) => (
    <FlexBox
      className={styles['entityLink']}
      direction={FlexBoxDirection.Column}
      justifyContent={FlexBoxJustifyContent.Center}
    >
      {content}
    </FlexBox>
  )

  const renderPageText = () => `${DEFAULT_PAGE_LINK_PREFIX}${t(changeLog?.pageKey)}`

  const renderPageWithLink = () => {
    const link = (
      <Link design={linkDesign} href={changedInformationData?.link?.parsedLink} target="_blank">
        {renderPageText()}
      </Link>
    )
    return renderFlexboxWithContent(link)
  }

  const renderPageWithoutLink = () => {
    const text = <Text>{renderPageText()}</Text>
    return renderFlexboxWithContent(text)
  }

  const entityText = changedInformationData?.entity?.name
    ? `${tDealChangeOverview(`entities.${changedInformationData?.entity?.type}`)} - ${
        changedInformationData?.entity?.name
      }`
    : tDealChangeOverview(`entities.${changedInformationData?.entity?.type}`)

  const renderEntityWithLink = () => {
    const link = (
      <Link
        className={styles['entityName']}
        design={linkDesign}
        href={changedInformationData?.link?.parsedLink}
        target="_blank"
      >
        {entityText}
      </Link>
    )
    return renderFlexboxWithContent(link)
  }

  const renderEntityWithoutLink = () => {
    const text = <Text className={styles['entityName']}>{entityText}</Text>
    return renderFlexboxWithContent(text)
  }

  const renderLink = () => {
    const isValidLink = changedInformationData?.link?.parsedLink !== null
    if (isValidLink) {
      if (renderPageLinkOnly) {
        return renderPageWithLink()
      }
      const linkType = changedInformationData?.link?.type
      const entityNotExist = !changedInformationData?.entity?.name
      if (linkType === LINK_TYPES.ENTITY && entityNotExist) {
        return renderEntityWithoutLink()
      }
      switch (linkType) {
        case LINK_TYPES.ENTITY:
          return renderEntityWithLink()
        case LINK_TYPES.PAGE:
          return renderPageWithLink()
        default:
          return renderPageWithoutLink()
      }
    } else {
      return renderPageWithoutLink()
    }
  }

  return (
    <ModularTableCell wrapText={true}>
      <LoadingContent
        isLoading={isLoadingChangedInformation}
        contentKey="changeOverviewChangedInformationLink"
      >
        {renderLink()}
      </LoadingContent>
    </ModularTableCell>
  )
}
DealChangeOverviewChangedInformationCell.propTypes = {
  changeLog: PropTypes.shape({
    entityId: PropTypes.string,
    entityType: PropTypes.string,
    cardKey: PropTypes.string,
    pageKey: PropTypes.string,
    action: PropTypes.string,
  }).isRequired,
  renderPageLinkOnly: PropTypes.bool,
  renderPagePrefix: PropTypes.bool,
  linkDesign: PropTypes.string,
}
export default DealChangeOverviewChangedInformationCell
