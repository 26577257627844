import { IllustrationMessageSize, IllustrationMessageType } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'

const ErrorDealDoesNotExist = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.content.not.available',
  })
  return (
    <CenteredIllustratedMessage
      titleText={t('title')}
      subtitleText={t('subtitle')}
      name={IllustrationMessageType.UnableToLoad}
      size={IllustrationMessageSize.Spot}
    />
  )
}

export default ErrorDealDoesNotExist
