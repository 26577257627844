import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateDecisionPaperVersion = (mutationOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ eventId, templateCode, eventIdToImportDataFrom, templateStructure = null }) => {
      const { data } = await post({
        path: `/events/${eventId}/decision-paper/versions/current`,
        body: {
          template_code: templateCode,
          event_id_to_import_data_from: eventIdToImportDataFrom,
          customized_template_structure: templateStructure
            ? snakecaseKeys(templateStructure)
            : null,
        },
      })
      return data
    },
    mutationOptions,
  )
}

export default useCreateDecisionPaperVersion
