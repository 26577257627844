import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'

const useAssociatedDealsData = ({
  dealsFinanced = [],
  collateralDeals = [],
  financedObjectAndCollateralDeals = [],
  backshopDealIds = [],
}) => {
  const financedData = dealsFinanced.map((financedDeal) => ({
    id: financedDeal.dealId,
    name: financedDeal.name,
    status: financedDeal.status,
    type: 'financed-object',
    isWorkingVersion: financedDeal.workingVersion === WorkingVersionType.WORKING_VERSION,
  }))

  const collateralData = collateralDeals.map((collateralDeal) => ({
    id: collateralDeal.dealId,
    name: collateralDeal.name,
    status: collateralDeal.status,
    type: 'collateral',
    isWorkingVersion: collateralDeal.isWorkingVersion,
  }))

  const financedObjectAndCollateralData = financedObjectAndCollateralDeals.map(
    (financedObjectAndCollateralDeal) => ({
      id: financedObjectAndCollateralDeal.dealId,
      name: financedObjectAndCollateralDeal.name,
      status: financedObjectAndCollateralDeal.status,
      type: 'financed-object-and-collateral',
      isWorkingVersion:
        financedObjectAndCollateralDeal.workingVersion === WorkingVersionType.WORKING_VERSION,
    }),
  )

  const backshopDealData = backshopDealIds.map((backshopDeal) => ({
    id: backshopDeal,
    type: 'backshop-deal',
  }))

  return [
    ...financedData,
    ...collateralData,
    ...financedObjectAndCollateralData,
    ...backshopDealData,
  ]
}

export default useAssociatedDealsData
