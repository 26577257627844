export const handleNewDecisionDateChanged = (
  { target: { liveValue: newChosenDecisionDate } },
  setNewDecisionDate,
) => {
  if (!newChosenDecisionDate) {
    setNewDecisionDate('')
    return
  }

  setNewDecisionDate(newChosenDecisionDate)
}
