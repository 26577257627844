import { FlexBox, FlexBoxDirection, ObjectStatus } from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { ErrorDataUnavailableInCell } from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/errors/ErrorDataUnavailableInCell'
import SmallLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/loading/SmallLoadingWrapper'

const propTypes = {
  /**
   * The state of the status element, changing the color of the badge.
   *
   * The state should be one of the UI5 ValueState enum values.
   */
  statusState: PropTypes.string,
  /** The status value to display as the component's label. */
  statusValue: PropTypes.string,
  /** Defines whether a loading spinner is shown. */
  isLoading: PropTypes.bool,
  /** Defines whether a loading spinner is shown. */
  isFetching: PropTypes.bool,
  /** Defines whether an error is shown. */
  isError: PropTypes.bool,
  /** Allows to define a custom error component. Defaults to undefined so that `<ErrorDataUnavailableInCell />` is shown. */
  errorToDisplay: PropTypes.node,
  /** Defines additional styling to the outmost div of the cell's content. */
  className: PropTypes.string,
}

/**
 * Shared Status Cell for usage within the Responsive and Analytical Table.
 *
 * @typedef Props
 * @property {ValueState} statusState The state of the status element, changing the color of the badge.
 * @property {String} statusValue The status value to display as the component's label.
 * @property {Boolean} isLoading Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} isFetching Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} isError Defines whether an error is shown.
 * @property {React.ReactNode} errorToDisplay Allows to define a custom error component. Defaults to undefined so that <ErrorDataUnavailableInCell /> is shown.
 * @property {String} className Defines additional styling to the outmost div of the cell's content.
 */

/**
 * @param {Props} props Note for Storybook: cf. code for complete JSDoc
 */
const StatusCell = ({
  statusState,
  statusValue,
  isLoading = false,
  isFetching = false,
  isError = false,
  className,
  errorToDisplay,
}) => {
  const renderContent = () => {
    if (isNil(statusState) || isNil(statusValue)) {
      return <div />
    }
    return (
      <FlexBox direction={FlexBoxDirection.Column} className={compact([className]).join(' ')}>
        <ObjectStatus inverted state={statusState}>
          {statusValue}
        </ObjectStatus>{' '}
      </FlexBox>
    )
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading || isFetching}
      isError={isError}
      error={errorToDisplay ?? <ErrorDataUnavailableInCell />}
      renderContent={renderContent}
    />
  )
}

StatusCell.propTypes = propTypes

export default StatusCell
