import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Link,
  LinkDesign,
  ObjectStatus,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { getObjectStatusForEventStatus } from 'api/events/status'
import DecisionStageStatus from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageStatus'
import styles from 'components/domains/business-partners/tile/arrears/current-approvals/CurrentApprovalsTile.module.css'
import useApprovalAndDecisionPaperStatus, {
  ACTIVE,
  NO_INFORMATION,
  LOCKED,
  IN_SYNC,
  OUT_OF_SYNC,
  EVENT_MISSING,
  DECISION_PAPER_MISSING,
} from 'components/domains/business-partners/tile/arrears/current-approvals/useApprovalAndDecisionPaperStatus'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'

const PLACE_HOLDER = '-'

const CurrentApprovalsTile = ({ arrearApprovalLocked, event }) => {
  const { t } = useTranslation('translation')
  const { t: tArrearApproval } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.in-approval',
  })

  const { approvalStatus, decisionPaperSyncStatus } = useApprovalAndDecisionPaperStatus({
    arrearApprovalLocked,
    event,
  })

  const renderApprovalStatus = useCallback(() => {
    let approvalStatusObject
    if (approvalStatus() === LOCKED) {
      approvalStatusObject = (
        <ObjectStatus inverted state={ValueState.None}>
          {tArrearApproval('locked')}
        </ObjectStatus>
      )
    } else if (approvalStatus() === ACTIVE) {
      approvalStatusObject = (
        <ObjectStatus inverted state={ValueState.Success}>
          {tArrearApproval('active')}
        </ObjectStatus>
      )
    } else if (approvalStatus() === EVENT_MISSING) {
      approvalStatusObject = PLACE_HOLDER
    }
    return (
      <SmallLoadingWrapper
        isLoading={approvalStatus() === NO_INFORMATION}
        isError={false}
        error={''}
        renderContent={() => (
          <div id="approvalStatus" className={styles['status-padding']}>
            {approvalStatusObject}
          </div>
        )}
      />
    )
  }, [approvalStatus, tArrearApproval])

  const renderDecisionPaperStatus = useCallback(() => {
    let decisionPaperStatusObject
    if (decisionPaperSyncStatus() === OUT_OF_SYNC) {
      decisionPaperStatusObject = (
        <ObjectStatus inverted state={ValueState.Success}>
          {tArrearApproval('decision-status.out-of-sync')}
        </ObjectStatus>
      )
    } else if (decisionPaperSyncStatus() === IN_SYNC) {
      decisionPaperStatusObject = (
        <ObjectStatus inverted state={ValueState.Information}>
          {tArrearApproval('decision-status.in-sync')}
        </ObjectStatus>
      )
    } else if (
      decisionPaperSyncStatus() === EVENT_MISSING ||
      decisionPaperSyncStatus() === DECISION_PAPER_MISSING
    ) {
      decisionPaperStatusObject = PLACE_HOLDER
    }
    return (
      <SmallLoadingWrapper
        isError={false}
        isLoading={decisionPaperSyncStatus() === NO_INFORMATION}
        error={''}
        renderContent={() => (
          <div id="decisionPaperSyncStatus" className={styles['status-padding']}>
            {decisionPaperStatusObject}
          </div>
        )}
      />
    )
  }, [decisionPaperSyncStatus, tArrearApproval])

  const getJoinedClassName = (classNames) =>
    classNames.map((className) => styles[className]).join(' ')

  const eventStatusObject = getObjectStatusForEventStatus(event?.status)

  return (
    <div className={styles['current-approvals-tile']}>
      <div className={getJoinedClassName(['grid-area-label-business-event', 'label'])}>
        <FlexBox justifyContent={FlexBoxJustifyContent.Start} direction={FlexBoxDirection.Column}>
          <div className={styles['status-title-padding']}>{tArrearApproval('status')}</div>
          <div>{tArrearApproval('business-event')}</div>
        </FlexBox>
      </div>
      <div className={styles['grid-area-business-event-id']}>
        <FlexBox justifyContent={FlexBoxJustifyContent.End} direction={FlexBoxDirection.Column}>
          {renderApprovalStatus()}
          <div className={styles['status-padding']}>
            <Link
              design={LinkDesign.Emphasized}
              href={`/business-events-and-tasks/business-events/${event?.id}`}
              target="_blank"
              wrappingType={WrappingType.Normal}
            >
              {event?.info?.name ?? PLACE_HOLDER}
            </Link>
          </div>
          <div className={styles['status-padding']}>{event?.displayId}</div>
        </FlexBox>
      </div>
      <div className={getJoinedClassName(['grid-area-label-status', 'label'])}>
        <FlexBox justifyContent={FlexBoxJustifyContent.End} direction={FlexBoxDirection.Column}>
          <div className={styles['status-title-padding']}>
            {tArrearApproval('business-event-status')}
          </div>
          <div className={styles['status-title-padding']}>{tArrearApproval('decision-status')}</div>
          <div className={styles['status-title-padding']}>
            {tArrearApproval('decision-paper-status')}
          </div>
        </FlexBox>
      </div>
      <div className={styles['grid-area-business-event-and-decision-status']}>
        <FlexBox justifyContent={FlexBoxJustifyContent.End} direction={FlexBoxDirection.Column}>
          <div className={styles['status-padding']}>
            {event ? (
              <ObjectStatus inverted state={eventStatusObject?.objectStatus}>
                {t(eventStatusObject?.translationKey)}
              </ObjectStatus>
            ) : (
              PLACE_HOLDER
            )}
          </div>
          <div className={styles['status-padding']}>
            {event ? <DecisionStageStatus eventId={event?.id} /> : PLACE_HOLDER}
          </div>
          <div className={styles['status-padding']}>{renderDecisionPaperStatus()}</div>
        </FlexBox>
      </div>
    </div>
  )
}

CurrentApprovalsTile.propTypes = {
  arrearApprovalLocked: PropTypes.bool,
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayId: PropTypes.string.isRequired,
    info: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    status: PropTypes.string.isRequired,
  }),
}

export default CurrentApprovalsTile
