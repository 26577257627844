export const AsTenantCardTableGrouping = {
  None: 'NONE',
}

export const TenancyCardTableColumnId = {
  property: 'property',
  financingStatus: 'financingStatus',
  deal: 'deal',
  city: 'city',
  area: 'area-sft-sqm',
  pieces: 'area-pieces',
  contractedRent: 'contracted-rent',
  wault: 'wault',
}

export const TenancyTableRowType = {
  deal: 'DEAL',
  property: 'PROPERTY',
  segment: 'SEGMENT',
  financingStatus: 'FINANCING_STATUS',
}
