import {
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const DecisionStageEmptyPage = ({ isPermissionError }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage',
  })

  if (isPermissionError) {
    return (
      <IllustratedMessage
        name={IllustrationMessageType.UnableToLoad}
        size={IllustrationMessageSize.Spot}
        titleText={tNoPrefix('app.permission-error.title')}
        subtitleText={tNoPrefix('app.permission-error.subtitle')}
      />
    )
  }
  return (
    <IllustratedMessage
      name={IllustrationMessageType.UnableToLoad}
      size={IllustrationMessageSize.Spot}
      titleText={t('loading-error.title')}
      subtitleText={t('loading-error.description')}
    />
  )
}

DecisionStageEmptyPage.propTypes = {
  isPermissionError: PropTypes.bool.isRequired,
}

export default DecisionStageEmptyPage
