import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionsMassEditOperations } from 'api/conditions/conditions'
import LoadingDropdown from 'components/ui/dropdown/LoadingDropdown'
import useApprovalLevels from 'hooks/services/conditions/config/useApprovalLevels'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const ConditionsMassEditApprovalLevelSelect = ({ onChange, disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.dialogs.mass-edit',
  })
  const {
    entityRef: { entityType },
  } = useContext(ConditionsContext)
  const { isLoading, isError, data: approvalLevelData } = useApprovalLevels({ entityType })

  const loader = useCallback(
    () => ({
      isError,
      isLoading,
      data: [
        {
          code: conditionsMassEditOperations.keep,
          displayName: t(`operations.${conditionsMassEditOperations.keep}`),
        },
        {
          code: conditionsMassEditOperations.clear,
          displayName: t(`operations.${conditionsMassEditOperations.clear}`),
        },
        ...(approvalLevelData
          ? approvalLevelData.approvalLevels.map(({ code, name }) => ({ code, displayName: name }))
          : []),
      ],
    }),
    [approvalLevelData, isError, isLoading, t],
  )

  const handleChange = useCallback(
    (selection) => {
      if (selection === conditionsMassEditOperations.keep) {
        onChange({ operation: conditionsMassEditOperations.keep })
        return
      }
      if (selection === conditionsMassEditOperations.clear) {
        onChange({ operation: conditionsMassEditOperations.clear })
        return
      }
      onChange({ operation: conditionsMassEditOperations.replace, value: selection })
    },
    [onChange],
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label>{t('labels.approval-level')}</Label>
      <LoadingDropdown
        id="conditions-mass-edit-approval-level-select"
        useLoader={loader}
        onChange={handleChange}
        value={conditionsMassEditOperations.keep}
        disabled={disabled}
      />
    </FlexBox>
  )
}

ConditionsMassEditApprovalLevelSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default ConditionsMassEditApprovalLevelSelect
