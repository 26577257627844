import { IllustrationMessageSize, IllustrationMessageType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-history/PropertyValuationResultsHistoryChart.module.css'
import PropertyValuationResultsHistoryLineChartChildren from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-history/PropertyValuationResultsHistoryLineChartChildren'
import usePrepareValuationHistoryData from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-history/usePrepareValuationHistoryData'
import GenericChart from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/deal-specifics/shared/ui/charts/GenericChart'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/card/EmptyCardContent'

const PropertyValuationResultsHistoryChart = ({ valuationsData }) => {
  const {
    preparedValuationsHistoryData,
    presentValuationTypes,
    getInterpolatedValuationValueByCodeAndKeyDate,
  } = usePrepareValuationHistoryData(valuationsData)

  if (preparedValuationsHistoryData?.length === 0) {
    return (
      <EmptyCardContent
        illustrationName={IllustrationMessageType.UnableToLoad}
        size={IllustrationMessageSize.Scene}
      />
    )
  }

  return (
    <div
      id={`div-valuation-results-history`}
      className={styles.valuationResultsHistoryChartWrapper}
    >
      <GenericChart
        lineChartData={preparedValuationsHistoryData}
        lineChartChildren={PropertyValuationResultsHistoryLineChartChildren}
        additionalLineChartArguments={{
          presentValuationTypes,
          getInterpolatedValuationValueByCodeAndKeyDate,
        }}
        customChartSettings={{ SHOW_ZOOM: false }}
        additionalChartProperties={{
          margin: { bottom: 28 },
        }}
      />
    </div>
  )
}

PropertyValuationResultsHistoryChart.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  valuationsData: PropTypes.array.isRequired,
}

export default PropertyValuationResultsHistoryChart
