import camelize from 'camelize'
import _ from 'lodash'
import {
  valuationClassification,
  valuationRelevance,
} from 'api/property/valuation/valuationRequests'
import usePropertyValuations from 'hooks/services/properties/valuations/usePropertyValuations'

const useLatestMatchingValuationForPropertyMonitoring = (propertyUuid) => {
  const valuationResponse = usePropertyValuations(propertyUuid)

  if (valuationResponse.isLoading || valuationResponse.isError) {
    return { ...valuationResponse, data: undefined }
  }

  const camelizedValuations = camelize(valuationResponse.data.valuations)

  const filteredValuationsRelevantForPropertyMonitoring = camelizedValuations.filter(
    ({ classification, regulatoryRelevance }) =>
      classification === valuationClassification.marketValue &&
      regulatoryRelevance === valuationRelevance.relevant,
  )

  if (filteredValuationsRelevantForPropertyMonitoring.length === 0) {
    return { ...valuationResponse, data: undefined }
  }

  const latestValuation = _.orderBy(
    filteredValuationsRelevantForPropertyMonitoring,
    ['validFrom'],
    ['desc'],
  )[0]

  return { ...valuationResponse, data: latestValuation }
}

export default useLatestMatchingValuationForPropertyMonitoring
