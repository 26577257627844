import {
  ComboBox,
  ComboBoxItem,
  FlexBox,
  FlexBoxDirection,
  Icon,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { valuationRequestMultiEditOperations } from 'api/property/valuation/valuationRequests'
import DatePickerWithoutMinWidth from 'components/ui/date-picker/DatePickerWithoutMinWidth'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const ValuationRequestMultEditDateSelectField = ({ id, onChange, disabled, maxDate }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.properties.valuation.multi-edit.operations',
  })
  const { localePattern, format: dateFormat, parse } = useShortDateFormatter()
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [selectedOperation, setSelectedOperation] = useState(
    valuationRequestMultiEditOperations.keep,
  )
  const [selectedDate, setSelectedDate] = useState()

  const formattedDate = useMemo(
    () => (selectedDate ? dateFormat(selectedDate) : undefined),
    [dateFormat, selectedDate],
  )
  const comboBoxValue = useMemo(
    () => formattedDate ?? t(selectedOperation),
    [formattedDate, selectedOperation, t],
  )

  const onDatePickerMountChange = useCallback((datePickerRef) => datePickerRef?.openPicker(), [])
  const onSelectedOperationChanged = useCallback(
    ({ detail: { item: selectedComboBoxItem } }) => {
      const operation = selectedComboBoxItem.getAttribute('data-operation')
      setShowDatePicker(false)
      setSelectedOperation(operation)
      switch (operation) {
        case valuationRequestMultiEditOperations.keep:
          setSelectedDate()
          onChange({ operation: valuationRequestMultiEditOperations.keep })
          break
        case valuationRequestMultiEditOperations.clear:
          setSelectedDate()
          onChange({ operation: valuationRequestMultiEditOperations.clear })
          break
        case valuationRequestMultiEditOperations.replace:
          setSelectedDate(parse(selectedComboBoxItem.getAttribute('text'), localePattern))
          onChange({ operation: valuationRequestMultiEditOperations.replace, selectedDate })
          break
      }
    },
    [localePattern, onChange, parse, selectedDate],
  )
  const onComboBoxClick = useCallback(() => {
    setShowDatePicker(true)
  }, [])
  const onDateChanged = useCallback(
    ({ detail: { value: localeDate } }) => {
      const parsedDate = parse(localeDate, localePattern)
      setSelectedDate(parsedDate)
      setSelectedOperation(valuationRequestMultiEditOperations.replace)
      onChange({ operation: valuationRequestMultiEditOperations.replace, value: parsedDate })
    },
    [localePattern, parse, onChange],
  )
  const onDatePickerBlur = useCallback(() => {
    setShowDatePicker(false)
  }, [])

  const comboBoxItems = useMemo(
    () => [
      <ComboBoxItem
        key="KEEP"
        text={t(valuationRequestMultiEditOperations.keep)}
        data-operation={valuationRequestMultiEditOperations.keep}
      />,
      <ComboBoxItem
        key="CLEAR"
        text={t(valuationRequestMultiEditOperations.clear)}
        data-operation={valuationRequestMultiEditOperations.clear}
      />,
      ...(formattedDate
        ? [
            <ComboBoxItem
              key="custom-selection"
              text={formattedDate}
              data-operation={valuationRequestMultiEditOperations.replace}
            />,
          ]
        : []),
    ],
    [t, formattedDate],
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      {!showDatePicker && (
        <ComboBox
          id={`${id}-combo-box`}
          disabled={disabled}
          value={comboBoxValue}
          icon={
            <Icon id={`${id}-value-help-icon`} name="appointment-2" onClick={onComboBoxClick} />
          }
          onSelectionChange={onSelectedOperationChanged}
        >
          {comboBoxItems}
        </ComboBox>
      )}
      {showDatePicker && (
        <DatePickerWithoutMinWidth
          ref={onDatePickerMountChange}
          value={formattedDate}
          maxDate={maxDate}
          onChange={onDateChanged}
          formatPattern={localePattern}
          onBlur={onDatePickerBlur}
          placeholder={comboBoxValue}
        />
      )}
    </FlexBox>
  )
}

ValuationRequestMultEditDateSelectField.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  maxDate: PropTypes.string,
}

export default ValuationRequestMultEditDateSelectField
