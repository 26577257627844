import { Title, TitleLevel } from '@fioneer/ui5-webcomponents-react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/RelationshipsTile.module.css'
import Card from 'components/ui/card/Card'
import ClickableCardHeader from 'components/ui/card/ClickableCardHeader'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import Entity from 'components/ui/data/Entity'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationships'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

const RelationshipsTile = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-partner.units',
  })
  const { id: businessPartnerId } = useContext(BusinessPartnerContext)
  const {
    data: { unitRelationships = [] } = {},
    isLoading,
    isError,
  } = useBusinessPartnerRelationships(businessPartnerId)

  const relationshipsWithHeadAndOrMembers = unitRelationships
    .filter(({ relationships }) =>
      relationships?.some(({ head, members }) => head || members?.length),
    )
    .map(({ id, name, relationships }) => ({
      id,
      name,
      headRelationships: relationships.map(({ head }) => head),
    }))

  const renderRelationshipsList = () =>
    relationshipsWithHeadAndOrMembers?.length ? (
      relationshipsWithHeadAndOrMembers.map(({ id: unitId, name, headRelationships }) => {
        const isHead = headRelationships?.some(({ id }) => id === businessPartnerId)
        const title = isHead ? t('head-of', { name: name }) : t('member-of', { name: name })
        return (
          <div key={name} className={styles.relationship}>
            <Title level={TitleLevel.H6} className={styles.relationshipTitle}>
              {title}
            </Title>
            {headRelationships.map(({ id: headPartnerId, fullName }) => (
              <div key={headPartnerId} className={styles.partnerGrid}>
                <Entity
                  id={headPartnerId}
                  name={fullName}
                  link={`/business-partners/${headPartnerId}/relationships#${unitId}`}
                  className={styles.partnerEntity}
                />
              </div>
            ))}
          </div>
        )
      })
    ) : (
      <EmptyCardContent />
    )

  return (
    <Card
      header={<ClickableCardHeader titleText={t('title')} href="relationships" />}
      className={styles.relationshipsTile}
    >
      <RequestStateResolver
        isError={isError}
        isLoading={isLoading}
        renderContent={renderRelationshipsList}
        errorToDisplay={<ErrorDataUnavailableInContent />}
      />
    </Card>
  )
}

export default RelationshipsTile
