import { Button } from '@fioneer/ui5-webcomponents-react'
import { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConditionsChangelogDialog from 'components/domains/conditions/dialogs/changelog/ConditionsChangelogDialog'
import { ENTITIES } from 'components/domains/deals/change-overview/constants'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

const OpenConditionsChangelogAction = () => {
  const { id: businessPartnerId } = useContext(BusinessPartnerContext)
  const { t } = useTranslation('translation', {
    keyPrefix:
      'components.business-partner.conditions.header-actions.open-conditions-changelog-action',
  })

  const [isCreateEventDialogOpen, setIsCreateEventDialogOpen] = useState(false)

  const handleOpenDialogButtonClick = useCallback(() => {
    setIsCreateEventDialogOpen(true)
  }, [])

  return (
    <>
      <Button onClick={handleOpenDialogButtonClick}>{t('button')}</Button>
      {isCreateEventDialogOpen && (
        <ConditionsChangelogDialog
          isOpen={isCreateEventDialogOpen}
          setIsOpen={setIsCreateEventDialogOpen}
          relatedEntityType={ENTITIES.BUSINESS_PARTNER}
          relatedEntityId={businessPartnerId}
          relatedEntityDisplayId={businessPartnerId}
        />
      )}
    </>
  )
}

OpenConditionsChangelogAction.propTypes = {}

export default OpenConditionsChangelogAction
