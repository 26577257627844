import isEmpty from 'lodash.isempty'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useContext } from 'react'
import { conditionsEntityTypes, conditionTypes } from 'api/conditions/conditions'
import { isMissingPermissionError } from 'api/requests'
import ConditionsTileToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/ConditionsTileToolbar'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/internal/InternalConditionsTileEditMode.module.css'
import MissingPermissionErrorTileContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/generic/MissingPermissionErrorTileContent'
import ConditionsTable from 'components/domains/conditions/overview/ConditionsTable'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useConditionTableColumns from 'hooks/services/conditions/tables/useConditionTableColumns'
import useConditions from 'hooks/services/conditions/useConditions'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const emptyObject = {}

const InternalConditionsTileEditMode = ({
  metaData,
  onMetaDataChange,
  shownColumns,
  shownSubcomponents,
  subcomponentHeight,
  tileCode,
}) => {
  const { event } = useContext(BusinessEventsAndTasksContext)
  const entityType = event?.entityRef?.entityType
  const entityId = event?.entityRef?.entityId

  const isDeal = entityType === conditionsEntityTypes.deal

  const {
    data: { dealUuid: dealUuidByTileCode } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({
    dealUuid: entityId,
    tileCode,
    options: { enabled: isDeal && !!entityId },
  })

  const { selectedConditions = emptyObject } = metaData || emptyObject
  const {
    columnSelection,
    filters,
    setFilters,
    sortableColumns,
    sorting,
    onUpdateSorting,
    searchParam,
    setSearchParam,
    mappedBackendFilters,
  } = useConditionTableColumns({
    shownColumns,
    conditionType: conditionTypes.internal,
    entityType,
  })

  const isFilterOrSearchApplied = useMemo(
    () =>
      filters.filter(({ value, emptyValue }) => !!value && !isEqual(value, emptyValue)).length !==
        0 || searchParam !== '',
    [filters, searchParam],
  )

  const {
    isFetching: isConditionsFetching,
    isError: isConditionsError,
    data: internalConditionsResult,
    error: conditionsError,
    fetchNextPage,
  } = useConditions(
    conditionTypes.internal,
    { entityId: isDeal ? dealUuidByTileCode : entityId, entityType },
    mappedBackendFilters,
    {
      sortBy: sorting.sortingKey,
      orderBy: sorting.sortDescending ? 'desc' : 'asc',
    },
    { enabled: isDeal ? !!dealUuidByTileCode : !!entityId },
  )

  const onConditionSelectionChanged = useCallback(
    (selection) => {
      if (isEmpty(selection)) {
        onMetaDataChange(null)
        return
      }
      onMetaDataChange({ selectedConditions: selection })
    },
    [onMetaDataChange],
  )

  const renderInternalConditionsTable = useCallback(
    () => (
      <div className={styles.tableWrapper}>
        <ConditionsTileToolbar
          numberOfConditions={internalConditionsResult?.total || 0}
          filters={filters}
          setFilters={setFilters}
          searchParam={searchParam}
          onUpdateSearchParam={setSearchParam}
          sorting={{
            columnKey: sorting.sortBy,
            sortBy: sorting.sortingKey,
            isSortingAscending: !sorting.sortDescending,
            sortableColumns,
            onUpdateSorting,
          }}
          isEditMode
        />
        <ConditionsTable
          isLoading={(isDeal && isDealUuidFetching) || isConditionsFetching}
          conditions={internalConditionsResult?.internalConditions}
          columns={columnSelection}
          isFilterOrSearchApplied={isFilterOrSearchApplied}
          fetchNextPage={fetchNextPage}
          conditionType={conditionTypes.internal}
          onConditionSelectionChanged={onConditionSelectionChanged}
          selectedConditions={selectedConditions}
          shownSubcomponents={shownSubcomponents}
          subcomponentHeight={subcomponentHeight}
        />
      </div>
    ),
    [
      internalConditionsResult?.total,
      internalConditionsResult?.internalConditions,
      filters,
      setFilters,
      searchParam,
      setSearchParam,
      sorting.sortBy,
      sorting.sortingKey,
      sorting.sortDescending,
      sortableColumns,
      onUpdateSorting,
      isDeal,
      isDealUuidFetching,
      isConditionsFetching,
      columnSelection,
      isFilterOrSearchApplied,
      fetchNextPage,
      onConditionSelectionChanged,
      selectedConditions,
      shownSubcomponents,
      subcomponentHeight,
    ],
  )

  if (isConditionsError && isMissingPermissionError(conditionsError)) {
    return <MissingPermissionErrorTileContent />
  }

  return (
    <RequestStateResolver
      isLoading={false}
      isError={(isDeal && isDealUuidError) || isConditionsError}
      renderContent={renderInternalConditionsTable}
      errorToDisplay={<ErrorDataUnavailableInContent />}
    />
  )
}

InternalConditionsTileEditMode.propTypes = {
  tileCode: PropTypes.string.isRequired,
  metaData: PropTypes.shape({}),
  onMetaDataChange: PropTypes.func.isRequired,
  shownColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  shownSubcomponents: PropTypes.shape({
    description: PropTypes.bool,
  }),
  subcomponentHeight: PropTypes.number.isRequired,
}

export default InternalConditionsTileEditMode
