import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  marketName: '',
  marketCode: '',
  marketDescription: '',
  marketCountry: '',
  marketPolygon: [],
  marketExternalId: '',
  marketExternalIdType: '',
}

export const marketCreationSlice = createSlice({
  name: 'market/creation',
  initialState,
  reducers: {
    setMarketName: (state, { payload }) => {
      state.marketName = payload
    },
    setMarketCode: (state, { payload }) => {
      state.marketCode = payload
    },
    setMarketDescription: (state, { payload }) => {
      state.marketDescription = payload
    },
    setMarketCountry: (state, { payload }) => {
      state.marketCountry = payload
    },
    setMarketPolygon: (state, { payload }) => {
      state.marketPolygon = payload
    },
    setMarketExternalId: (state, { payload }) => {
      state.marketExternalId = payload
    },
    setMarketExternalIdType: (state, { payload }) => {
      state.marketExternalIdType = payload
    },
    resetMarketCreation: () => initialState,
  },
})

export const {
  setMarketName,
  setMarketCode,
  setMarketDescription,
  setMarketCountry,
  setMarketPolygon,
  setMarketExternalId,
  setMarketExternalIdType,
  resetMarketCreation,
} = marketCreationSlice.actions

export default marketCreationSlice.reducer
