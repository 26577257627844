import { Input, InputType, Label, Option, Select } from '@fioneer/ui5-webcomponents-react'
import { useCallback } from 'react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/FactSheetValuationFactorAndScenarios.module.css'
import FormattedNumberInput from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/shared/input/FormattedNumberInput'

const useFactSheetValuationFactorAndScenariosEditRow = ({
  handleOnChange,
  selectOptions,
  currentContent,
}) => {
  const getSelect = useCallback(
    (selectedOption, rowNumber) => (
      <Select
        onChange={(e) => {
          const optionKey = e.detail.selectedOption.dataset.value
          const option = selectOptions.find((selectOption) => selectOption.key === optionKey)
          if (option) {
            const newLabel = Array.isArray(option.label) ? option.label[0] : option.label
            handleOnChange({ option: optionKey, label: newLabel }, rowNumber)
          } else {
            handleOnChange({ option: '', label: '' }, rowNumber)
          }
        }}
      >
        <Option />
        {selectOptions?.map((option) => (
          <Option key={option.key} data-value={option.key} selected={option.key === selectedOption}>
            {option.displayValue}
          </Option>
        ))}
      </Select>
    ),
    [handleOnChange, selectOptions],
  )

  const getLabelOptions = useCallback(
    (displayValues, rowNumber) =>
      displayValues.map((displayValue, index) => (
        <Option
          key={displayValue + index}
          data-value={displayValue}
          selected={displayValue === currentContent[rowNumber].label}
        >
          {displayValue}
        </Option>
      )),
    [currentContent],
  )

  const getInput = useCallback(
    (value, selectedOption, rowNumber) => {
      if (selectedOption?.inputType === InputType.Number) {
        const showLabelDropDown = Array.isArray(selectedOption.label)
        return (
          <>
            <FormattedNumberInput
              value={value}
              className={styles.numberInput}
              onInput={(number) => handleOnChange({ content: number }, rowNumber)}
              icon={!showLabelDropDown && <Label>{currentContent[rowNumber].label}</Label>}
            />
            {showLabelDropDown && (
              <Select
                className={styles.labelSelect}
                onChange={(e) =>
                  handleOnChange({ label: e.detail.selectedOption.dataset.value }, rowNumber)
                }
              >
                {getLabelOptions(selectedOption?.label, rowNumber)}
              </Select>
            )}
          </>
        )
      }
      return (
        <Input
          value={value}
          onInput={(e) => handleOnChange({ content: e.target.value?.trim() }, rowNumber)}
          type={selectedOption?.inputType || InputType.Text}
          icon={<Label>{selectedOption?.label}</Label>}
        />
      )
    },
    [currentContent, getLabelOptions, handleOnChange],
  )

  return useCallback(
    (selectedOption, inputValue, rowNumber) => [
      getSelect(selectedOption?.key, rowNumber),
      getInput(inputValue, selectedOption, rowNumber),
    ],
    [getSelect, getInput],
  )
}
export default useFactSheetValuationFactorAndScenariosEditRow
