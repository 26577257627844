import { ToolbarSeparator } from '@fioneer/ui5-webcomponents-react'

/**
 * Separates the provided segments of header actions with a `ToolbarSeparator` while filtering out falsy elements.
 * @param {(JSX.Element | false | undefined | null)[]} segments
 * @returns {JSX.Element[]} separated header actions
 */
export default function createSegmentedHeaderActions(...segments) {
  const toolbarSeparatorFactory = (() => {
    let toolbarSeparatorCount = 0
    return function toolbarSeparator() {
      return <ToolbarSeparator key={`toolbar-separator-${++toolbarSeparatorCount}`} />
    }
  })()

  return segments.reduce((separatedActions, segment) => {
    const filteredSegment = segment.filter((element) => !!element)

    if (separatedActions.length && filteredSegment.length) {
      separatedActions.push(toolbarSeparatorFactory())
    }

    separatedActions.push(...filteredSegment)
    return separatedActions
  }, [])
}
