import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import PropTypes from 'prop-types'
import BusinessPartnerSearchDialog from 'components/domains/business-partners/BusinessPartnerSearchDialog'
import useBusinessPartnerCreateDialogOptions from 'components/domains/rentroll/useBusinessPartnerCreateDialogOptions'

const RentRollBusinessPartnerSearchDialog = ({
  open,
  type,
  initialSearch,
  onClose,
  onChange,
  withCreateOption = true,
  translatedTypeName,
  initialRoles = [],
  initialExcludeInactive = false,
}) => {
  const createDialogOptions = useBusinessPartnerCreateDialogOptions(type)

  const handleChange = (selection = []) => {
    const firstBusinessPartner = selection[0]
    if (firstBusinessPartner) {
      onChange?.(firstBusinessPartner)
      return
    }
    onChange?.()
  }

  return (
    <BusinessPartnerSearchDialog
      open={open}
      type={type}
      initialSearch={initialSearch}
      onClose={onClose}
      onChange={handleChange}
      withCreateOption={withCreateOption}
      createDialogOptions={createDialogOptions}
      translatedTypeName={translatedTypeName}
      initialRoles={initialRoles}
      initialExcludeInactive={initialExcludeInactive}
      isMultiSelect={false}
    />
  )
}

RentRollBusinessPartnerSearchDialog.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.oneOf(['tenant', 'brand-franchise-provider', 'business-partner']),
  initialSearch: PropTypes.string,
  initialRoles: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  withCreateOption: PropTypes.bool,
  translatedTypeName: PropTypes.string,
  initialExcludeInactive: PropTypes.bool,
}

export default RentRollBusinessPartnerSearchDialog
