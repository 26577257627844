import { Link } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import getDocumentsFromRoot from 'components/domains/documents/documents-table/getDocumentsFromRoot'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useRequirementsCellEditMode from 'hooks/services/conditions/edit-mode/useRequirementsCellEditMode'
import useDocuments from 'hooks/services/documents/useDocuments'

const RequirementsTableAttachmentCell = ({ requirementId, rowId, toggleRowExpanded }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.columns.attachment',
  })

  const {
    data: documentsData,
    isLoading,
    isError,
  } = useDocuments({ entityId: requirementId, entityType: DocumentTypes.Requirement })

  const { isEditModeForAnyRow } = useRequirementsCellEditMode({
    requirementId,
  })

  const expandRowOnClick = useCallback(() => {
    toggleRowExpanded(rowId, true)
  }, [rowId, toggleRowExpanded])

  const disabled = useMemo(() => isEditModeForAnyRow, [isEditModeForAnyRow])

  const documents = useMemo(() => getDocumentsFromRoot(documentsData), [documentsData])

  const renderContent = useCallback(
    () => (
      <Link disabled={disabled} onClick={expandRowOnClick}>
        {t('link-label', { numberOfAttachments: documents.length })}
      </Link>
    ),
    [disabled, documents.length, expandRowOnClick, t],
  )

  return (
    <RequestStateResolver
      isLoading={isLoading}
      isError={isError}
      errorToDisplay={<ErrorDataUnavailableInContent />}
      renderContent={renderContent}
    />
  )
}

RequirementsTableAttachmentCell.propTypes = {
  requirementId: PropTypes.string.isRequired,
  rowId: PropTypes.string.isRequired,
  toggleRowExpanded: PropTypes.func.isRequired,
}

export default RequirementsTableAttachmentCell
