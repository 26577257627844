import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedRows: {
    selectedEventIds: {},
  },
  errorRows: {},
  rowsHaveError: false,
}

export const eventOverviewSlice = createSlice({
  name: 'event-overview',
  initialState,
  reducers: {
    selectEvent: (state, { payload: { eventId, status } }) => {
      state.selectedRows.selectedEventIds = {
        ...state.selectedRows.selectedEventIds,
        [eventId]: { status },
      }
    },
    selectAllEvents: (state, { payload: events }) => {
      state.selectedRows.selectedEventIds = {}
      events.forEach(({ eventId, status }) => {
        state.selectedRows.selectedEventIds = {
          ...state.selectedRows.selectedEventIds,
          [eventId]: { status },
        }
      })
    },
    deselectEvent: (state, { payload: eventId }) => {
      delete state.selectedRows.selectedEventIds[eventId]
    },
    clearEventSelection: (state) => {
      state.selectedRows.selectedEventIds = {}
    },
    eventsRowHasError: (state, { payload: { id, error } }) => {
      state.errorRows = {
        ...state.errorRows,
        [id]: { error },
      }
      state.rowsHaveError = true
    },
    resetEventsRowErrors: (state, { payload: { ids } }) => {
      for (const id of ids) {
        delete state.errorRows[id]
      }
      state.rowsHaveError = Object.keys(state.errorRows).length > 0
    },
    reset: () => initialState,
  },
})

export const {
  selectEvent,
  selectAllEvents,
  deselectEvent,
  clearEventSelection,
  eventsRowHasError,
  resetEventsRowErrors,
  reset,
} = eventOverviewSlice.actions

export default eventOverviewSlice.reducer
