import PropTypes from 'prop-types'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/DisplayCardViewItemView'

const dash = '-'

const SponsorGuarantorOverviewLabeledValue = ({ label, value }) => (
  <DisplayCardViewItemView>
    <DisplayCardViewInfoItemView label={label} value={value ?? dash} />
  </DisplayCardViewItemView>
)

SponsorGuarantorOverviewLabeledValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export default SponsorGuarantorOverviewLabeledValue
