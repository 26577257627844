import { FilterGroupItem, Icon, Input } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/documents/DocumentSelectFilterBar.module.css'
import DocumentTypeInput from 'components/domains/documents/DocumentTypeInput'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import StaffMemberAutocompleteInput from 'components/ui/input/StaffMemberAutocompleteInput'
import DashboardFilterBar from 'components/ui/page/DashboardFilterBar'
import countActiveFilters from 'components/ui/page/countActiveFilters'

/** @typedef {import('components/domains/documents/useFilteredDocuments').Filters} Filters */

const propTypes = {
  entity: PropTypes.shape({
    /** The entity's (unique) ID. **Currently ignored**. */
    id: PropTypes.string.isRequired,
    /** The entity's Display ID. Will be displayed on the screen. */
    displayId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(DocumentTypes)).isRequired,
  }).isRequired,
  onChange: PropTypes.func,
}
/**
 * @typedef {{onChange?: (newFilters: Filters) => void}} overrides
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 */
const DocumentSelectFilterBar = ({ entity, onChange = () => {} }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'components.documents.filter-bar' })
  const [filters, setFilters] = useState(/** @type {Filters} */ ({}))
  const activeFiltersCount = countActiveFilters(filters)

  const [staffMemberName, setStaffMemberName] = useState(
    /** @type {string | undefined} */ (undefined),
  )

  const filterOptions = {
    name: { visibleOnBar: true },
    entity: { visibleOnBar: true },
    type: { visibleOnBar: true },
    creator: { visibleOnBar: true },
  }

  const handleGo = () => {
    onChange(filters)
  }

  /**
   * @param {object} [newStaffMember]
   * @param {string} [newStaffMember.id]
   * @param {string} [newStaffMember.fullName]
   */
  const handleStaffMemberSelect = (newStaffMember) => {
    setFilters((old) => ({ ...old, creator: newStaffMember?.id }))
    setStaffMemberName(newStaffMember?.fullName)
  }

  return (
    <DashboardFilterBar
      activeFiltersCount={activeFiltersCount}
      filterOptions={filterOptions}
      setDialogOpen={() => {}}
      onGo={handleGo}
    >
      <FilterGroupItem
        key="name"
        label={t('name')}
        className={[styles.hiddenLabel, styles.nameGroupItem].join(' ')}
      >
        <Input
          name="name"
          icon={<Icon name="search" />}
          value={filters.name}
          onInput={(e) => setFilters((old) => ({ ...old, name: e.target.value }))}
        />
      </FilterGroupItem>
      <FilterGroupItem
        key="entity"
        label={t('label', { keyPrefix: 'components.document-upload', context: entity.type })}
      >
        <Input
          name="entity"
          readonly
          value={t('entity-formatted', { id: entity.displayId, name: entity.name })}
        />
      </FilterGroupItem>
      <FilterGroupItem key="type" label={t('type')}>
        <DocumentTypeInput
          value={filters.type ?? ''}
          onChange={(documentType) => setFilters((old) => ({ ...old, type: documentType }))}
          entityRefs={[entity]}
        />
      </FilterGroupItem>
      <FilterGroupItem key="creator" label={t('creator')}>
        <StaffMemberAutocompleteInput
          staffMemberName={staffMemberName}
          onStaffMemberSelect={handleStaffMemberSelect}
        />
      </FilterGroupItem>
    </DashboardFilterBar>
  )
}

DocumentSelectFilterBar.propTypes = propTypes

export default DocumentSelectFilterBar
