import { isMissingPermissionError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useMarket = (marketId, getAllKpiValues = false, filterKpisForMonitoring = false) => {
  const queryParams = new URLSearchParams()
  if (getAllKpiValues) {
    queryParams.append('get_all_kpi_values', true)
  }
  if (filterKpisForMonitoring) {
    queryParams.append('filter_kpis_for_monitoring', true)
  }
  return useCamelizedResponse(
    useRequest({
      path: `/markets/${marketId}?${queryParams.toString()}`,
      translated: true,
      useCache: true,
      keys: [
        'markets',
        marketId,
        `getAllKpiValues=${getAllKpiValues}`,
        `filterKpisForMonitoring=${filterKpisForMonitoring}`,
      ],
      options: {
        retry: (failureCount, error) => {
          if (isMissingPermissionError(error)) {
            return false
          }
          if (failureCount >= 1) {
            return false
          }
          return true
        },
      },
    }),
  )
}
export default useMarket
