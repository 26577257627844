import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useCovenantById = (covenantUuid, options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/covenants/${covenantUuid}`,
      translated: true,
      useCache: true,
      keys: ['covenants', covenantUuid],
      options: { enabled: !!covenantUuid, ...options },
    }),
  )

export default useCovenantById
