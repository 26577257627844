import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Select,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styles from 'components/domains/business-events-and-tasks/events/events-summary-card/EventsSummaryCardHeader.module.css'

const EventsSummaryCardHeader = ({ title, titleLinkPath, selectOptions, handleFilterChange }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.events.events-summary-card.header',
  })

  const onSelectChange = useCallback(
    ({
      detail: {
        selectedOption: { value },
      },
    }) => {
      handleFilterChange(value)
    },
    [handleFilterChange],
  )

  const renderSelect = useMemo(() => {
    if (isNil(selectOptions)) {
      return
    }
    return (
      <Select className={styles.select} onChange={onSelectChange}>
        {selectOptions}
      </Select>
    )
  }, [onSelectChange, selectOptions])

  return (
    <div className={styles.header}>
      <FlexBox
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        alignItems={FlexBoxAlignItems.Center}
      >
        <Link className={styles.headerText} to={titleLinkPath}>
          {title ?? t('title')}
        </Link>
        {renderSelect}
      </FlexBox>
    </div>
  )
}

EventsSummaryCardHeader.propTypes = {
  title: PropTypes.string,
  titleLinkPath: PropTypes.string.isRequired,
  selectOptions: PropTypes.array,
  handleFilterChange: PropTypes.func,
}

export default EventsSummaryCardHeader
