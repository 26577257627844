import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionProcessConditionsDecisionStatus } from 'api/decision-process/decisionProcessConditionsApi'
import SemanticButton, { SemanticButtonDesign } from 'components/ui/button/SemanticButton'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import useEditDecisionStageConditions from 'hooks/services/business-events-and-tasks/decision-process/stages/conditions/useEditDecisionStageConditions'
import useOverarchingVoteError from 'hooks/services/business-events-and-tasks/decision-process/stages/error-checks/useOverarchingVoteError'

const DecisionStageConditionApprovalApproveButton = ({ decisionStageId, eventId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'decision-stage.conditions.vote-options.approve',
  })
  const { mutate: editDecisionStage } = useEditDecisionStageConditions()
  const showMessageBox = useShowMessageBox()
  const queryClient = useQueryClient()

  const { onError } = useOverarchingVoteError()

  const onApproveSuccess = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Success,
      titleText: t('success.title'),
      children: t('success.text'),
      actions: [MessageBoxActions.OK],
    })
    queryClient.invalidateQueries(['events', eventId, 'decision-stages'])
  }, [showMessageBox, eventId, queryClient, t])

  const onApproveConfirm = useCallback(() => {
    editDecisionStage(
      {
        decisionStageId,
        eventId,
        decisionStatus: decisionProcessConditionsDecisionStatus.approved,
      },
      { onSuccess: onApproveSuccess, onError },
    )
  }, [editDecisionStage, decisionStageId, eventId, onApproveSuccess, onError])

  const onApprove = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Confirm,
      titleText: t('action'),
      children: t('confirmation.text'),
      actions: [
        <Button key="button-approve" design={ButtonDesign.Emphasized} onClick={onApproveConfirm}>
          {t('action')}
        </Button>,
        MessageBoxActions.Cancel,
      ],
    })
  }, [showMessageBox, onApproveConfirm, t])

  return (
    <SemanticButton design={SemanticButtonDesign.Success} onClick={onApprove}>
      {t('action')}
    </SemanticButton>
  )
}

DecisionStageConditionApprovalApproveButton.propTypes = {
  eventId: PropTypes.string.isRequired,
  decisionStageId: PropTypes.string.isRequired,
}

export default DecisionStageConditionApprovalApproveButton
