import isEqual from 'lodash.isequal'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import RequirementsTable from 'components/domains/conditions/overview/requirements/RequirementsTable'
import RequirementsTableToolbar from 'components/domains/conditions/overview/requirements/RequirementsTableToolbar'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useRequirements from 'hooks/services/conditions/requirements/useRequirements'
import useRequirementsTableColumns from 'hooks/services/conditions/tables/useRequirementsTableColumns'
import { reset as resetRequirementTableErrors } from 'redux/slices/conditions/requirementsTableSlice'
import styles from 'routes/conditions/ConditionRequirementsPage.module.css'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const ConditionRequirementsPage = () => {
  const {
    entityRef: { entityType, entityId },
  } = useContext(ConditionsContext)
  const { t } = useTranslation('translation', { keyPrefix: 'components.requirements.table' })
  const dispatch = useDispatch()

  const {
    columnSelection,
    setColumnSelection,
    filters,
    setFilters,
    sortableColumns,
    sorting,
    onUpdateSorting,
    searchParam,
    setSearchParam,
    mappedBackendFilters,
  } = useRequirementsTableColumns({ entityType, entityId })

  const {
    isLoading,
    isError,
    data: requirementsResult,
    fetchNextPage,
  } = useRequirements({ entityType, entityId }, mappedBackendFilters, {
    sortBy: sorting.sortingKey,
    orderBy: sorting.sortDescending ? 'desc' : 'asc',
  })

  useEffect(
    () => () => {
      dispatch(resetRequirementTableErrors())
    },
    [dispatch],
  )

  const isFilterOrSearchApplied = useMemo(
    () =>
      filters.filter(({ value, emptyValue }) => !!value && !isEqual(value, emptyValue)).length !==
        0 || searchParam !== '',
    [filters, searchParam],
  )

  const renderRequirementsTable = useCallback(
    () => (
      <div className={styles.content}>
        <RequirementsTableToolbar
          numberOfRequirements={requirementsResult?.total ?? 0}
          columnSelection={columnSelection}
          setColumnSelection={setColumnSelection}
          filters={filters}
          setFilters={setFilters}
          searchParam={searchParam}
          onUpdateSearchParam={setSearchParam}
          sorting={{
            columnKey: sorting.sortBy,
            isSortingAscending: !sorting.sortDescending,
            sortableColumns,
            onUpdateSorting,
          }}
        />
        <RequirementsTable
          isLoading={isLoading}
          requirements={requirementsResult?.requirements}
          columns={columnSelection}
          isFilterOrSearchApplied={isFilterOrSearchApplied}
          fetchNextPage={fetchNextPage}
        />
      </div>
    ),
    [
      requirementsResult?.total,
      requirementsResult?.requirements,
      columnSelection,
      setColumnSelection,
      filters,
      setFilters,
      searchParam,
      setSearchParam,
      sorting.sortBy,
      sorting.sortDescending,
      sortableColumns,
      onUpdateSorting,
      isLoading,
      isFilterOrSearchApplied,
      fetchNextPage,
    ],
  )
  return (
    <Card>
      <div className={styles.cardWrapper}>
        <RequestStateResolver
          isLoading={false}
          isError={isError}
          renderContent={renderRequirementsTable}
          errorToDisplay={
            <ErrorDataUnavailableInContent
              titleText={t('error.title')}
              subtitleText={t('error.description')}
            />
          }
        />
      </div>
    </Card>
  )
}

ConditionRequirementsPage.propTypes = {}

export default ConditionRequirementsPage
