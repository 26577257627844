import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/portfolio/rent-roll/analysis/PropertyPortfolioRentRollAnalysisTable.module.css'
import RentRollKeyDatesDropdown from 'components/domains/properties/rent-roll/comparison/RentRollKeyDatesDropdown'
import Card from 'components/ui/card/Card'
import CardHeaderWithButtons from 'components/ui/card/CardHeaderWithButtons'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import AnalyticalTableNoDataComponent from 'components/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import { usePortfolioRentRollAnalysisColumns } from 'hooks/services/properties/portfolio/usePortfolioRentRollAnalysisColumns'
import { usePortfolioRentRollAnalysisRows } from 'hooks/services/properties/portfolio/usePortfolioRentRollAnalysisRows'
import { PropertyPortfolioContext } from 'routes/properties/portfolio/PropertyPortfolioContext'

const PropertyPortfolioRentRollAnalysisTable = ({ keyDate }) => {
  const { properties } = useContext(PropertyPortfolioContext)
  const propertyUuids = properties?.map((property) => property.uuid)

  const tableRef = useRef()

  const { t: tAnalysis } = useTranslation('translation', {
    keyPrefix: 'pages.properties.portfolio.rent-roll.analysis',
  })

  const {
    isLoading: isLoadingColumns,
    isError: isErrorColumns,
    data: columns,
  } = usePortfolioRentRollAnalysisColumns()

  const {
    isLoading: isLoadingRows,
    isError: isErrorRows,
    data: rows,
  } = usePortfolioRentRollAnalysisRows({ keyDate })

  const isLoading = isLoadingColumns || isLoadingRows
  const isError = isErrorColumns || isErrorRows

  return (
    <Card
      header={
        <CardHeaderWithButtons title={tAnalysis('title')}>
          <RentRollKeyDatesDropdown propertyUuids={propertyUuids} />
        </CardHeaderWithButtons>
      }
    >
      <RequestStateResolver
        center
        isLoading={isLoading}
        isError={isError}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        renderContent={() => (
          <FlexBox direction={FlexBoxDirection.Column} className={styles.tablePadding}>
            <AnalyticalTableWithToolbar
              ref={tableRef}
              columns={columns}
              data={rows}
              headerRowHeight={40}
              rowHeight={140}
              loading={false}
              adjustTableHeightOnPopIn
              disableColumnPopover
              groupable
              filterable
              sortable
              minRows={1}
              title={tAnalysis('sub-title')}
              noDataText={tAnalysis('no-data')}
              NoDataComponent={() => (
                <AnalyticalTableNoDataComponent
                  isLoading={false}
                  isError={false}
                  tableId={'portfolio-rent-roll-analysis-table'}
                />
              )}
              reactTableOptions={{ autoResetSelectedRows: false }}
            />
          </FlexBox>
        )}
      />
    </Card>
  )
}
PropertyPortfolioRentRollAnalysisTable.propTypes = {
  keyDate: PropTypes.string,
}
export default PropertyPortfolioRentRollAnalysisTable
