// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZgAVbV1ir66OTXu4djol {
  margin-top: 4px;
  color: var(--sapContent_LabelColor) !important;
}

.diYtaZ0Mg07VXJ4cieE6 {
  padding: 16px;
}

.dAB8mDUQGBGxCtA2rHiH {
  text-align: center;
  color: var(--sapTextColor);
}`, "",{"version":3,"sources":["webpack://./src/components/ui/card/EmptyCard.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,8CAA8C;AAChD;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,0BAA0B;AAC5B","sourcesContent":[".cwp-empty-card-subtitle {\n  margin-top: 4px;\n  color: var(--sapContent_LabelColor) !important;\n}\n\n.cwp-empty-card-wrapper {\n  padding: 16px;\n}\n\n.title {\n  text-align: center;\n  color: var(--sapTextColor);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cwp-empty-card-subtitle": `ZgAVbV1ir66OTXu4djol`,
	"cwp-empty-card-wrapper": `diYtaZ0Mg07VXJ4cieE6`,
	"title": `dAB8mDUQGBGxCtA2rHiH`
};
export default ___CSS_LOADER_EXPORT___;
