import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Option,
  Select,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrearsApprovalChecklist/ArrearsApprovalChecklistTileEdit.module.css'
import {
  CHECKLIST_ITEMS,
  CHECK_VALUE_NO,
  CHECK_VALUE_YES,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrearsApprovalChecklist/constant'

const CheckValueSelect = ({ checkListItem, content, handleOnSelectChange, t }) => (
  <Select
    id={`${checkListItem}-select`}
    onChange={handleOnSelectChange}
    className={styles.editComponent}
  >
    <Option value={undefined} selected={isNil(content[checkListItem])}>
      {''}
    </Option>
    <Option value={CHECK_VALUE_YES} selected={content[checkListItem] === CHECK_VALUE_YES}>
      {t('checklist-item.value.yes')}
    </Option>
    <Option value={CHECK_VALUE_NO} selected={content[checkListItem] === CHECK_VALUE_NO}>
      {t('checklist-item.value.no')}
    </Option>
  </Select>
)

CheckValueSelect.propTypes = {
  checkListItem: PropTypes.string.isRequired,
  content: PropTypes.shape({
    ratingUpdateNecessary: PropTypes.string,
    accountManagerOneInformed: PropTypes.string,
    syndicationPartnerInformed: PropTypes.string,
    guarantorInformed: PropTypes.string,
  }),
  handleOnSelectChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const ArrearsApprovalCheckListTileEdit = ({ onChange, content }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.arrears-approval-checklist',
  })

  const hanleOnSelectChange = useCallback(
    (checkListItem) => (event) => {
      const newValue = event.detail.selectedOption.value
      onChange({ ...content, [checkListItem]: newValue })
    },
    [content, onChange],
  )

  const checkListItemEdit = (labelKey, checkListItem) => (
    <>
      <Label>{t(labelKey)}</Label>
      <CheckValueSelect
        checkListItem={checkListItem}
        content={content}
        handleOnSelectChange={hanleOnSelectChange(checkListItem)}
        t={t}
      />
    </>
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.wrapper}>
      {CHECKLIST_ITEMS.map(({ labelKey, checkListItem }) => (
        <FlexBox
          direction={FlexBoxDirection.Row}
          justifyContent={FlexBoxJustifyContent.SpaceBetween}
          alignItems={FlexBoxAlignItems.Center}
          className={styles.row}
          key={`${labelKey}-edit`}
        >
          {checkListItemEdit(labelKey, checkListItem)}
        </FlexBox>
      ))}
    </FlexBox>
  )
}

ArrearsApprovalCheckListTileEdit.propTypes = {
  content: PropTypes.shape({
    ratingUpdateNecessary: PropTypes.string,
    accountManagerOneInformed: PropTypes.string,
    syndicationPartnerInformed: PropTypes.string,
    guarantorInformed: PropTypes.string,
  }),
  onChange: PropTypes.func,
}

export default ArrearsApprovalCheckListTileEdit
