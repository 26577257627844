import { TextArea } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/comment/AddNewCommentField.module.css'
import CWPCKEditorWithSaveButtonAndCancelPopover from 'components/ui/rich-text-editor/CWPCKEditorWithSaveButtonAndCancelPopover'

const AddNewCommentField = ({ className, onSave, isEditMode, setIsEditMode }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.add-new-comment-field',
  })

  const handleOnClick = useCallback(() => {
    setIsEditMode(true)
  }, [setIsEditMode])

  if (!isEditMode) {
    return <TextArea onClick={handleOnClick} placeholder={t('text')} className={styles.text} />
  }
  return (
    <CWPCKEditorWithSaveButtonAndCancelPopover
      autofocus
      className={className}
      placeholderText={t('text')}
      onSave={onSave}
      onCancel={() => setIsEditMode(false)}
    />
  )
}

AddNewCommentField.propTypes = {
  className: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  setIsEditMode: PropTypes.func.isRequired,
}

export default AddNewCommentField
