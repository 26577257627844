import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import ArrearsInApprovalTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/ArrearsInApprovalTable'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/ArrearsInApprovalTile.module.css'

const ArrearsInApprovalTile = ({ tileId }) => {
  const {
    data: {
      sourceRender,
      products,
      arrears,
      productHierarchy,
      arrearApprovals,
      arrearApprovalEvents,
      arrearClassifications,
      allDealsData,
    },
    version,
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  return (
    <div className={styles.table}>
      <ArrearsInApprovalTable
        businessPartnerId={sourceRender.businessPartnerId}
        products={products}
        arrears={arrears}
        productHierarchy={productHierarchy}
        arrearApprovals={arrearApprovals}
        arrearApprovalEvents={arrearApprovalEvents}
        arrearClassifications={arrearClassifications}
        allDealsData={allDealsData}
        tileVersion={version}
      />
    </div>
  )
}

ArrearsInApprovalTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default ArrearsInApprovalTile
