import { BusyIndicatorSize, WrappingType } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { entityTypes } from 'api/conditions/conditions'
import { isNotFoundError } from 'api/requests'
import { ChangelogDialogContext } from 'components/domains/conditions/dialogs/changelog/ChangelogDialogContext'
import { ENTITIES } from 'components/domains/deals/change-overview/constants'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import Link from 'components/ui/link/Link'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useRequirements from 'hooks/services/conditions/requirements/useRequirements'
import paths from 'routes/paths'

const ConditionRequirementChangelogRequirementLink = ({ requirementId, action }) => {
  const { relatedEntityType, relatedEntityId, relatedEntityDisplayId, hideNameForEntityId } =
    useContext(ChangelogDialogContext)
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.dialog.changelog.table.entities',
  })
  const entityType = useMemo(() => {
    switch (relatedEntityType) {
      case ENTITIES.DEAL:
        return entityTypes.deal
      case ENTITIES.BUSINESS_PARTNER:
        return entityTypes.businesspartner
      default:
        return null
    }
  }, [relatedEntityType])
  const isEnabled =
    action !== 'DELETED' &&
    action !== 'REQUIREMENT_DISCARDED' &&
    !isNil(entityType) &&
    hideNameForEntityId !== requirementId
  const {
    data: requirements,
    isFetching,
    isError,
    error,
  } = useRequirements(
    { entityId: relatedEntityId, entityType },
    { requirementId },
    { enabled: isEnabled },
  )

  const isTechnicalError = isError && !isNotFoundError(error)
  const requirement = requirements?.requirements?.[0]

  const entityTypeName = t('requirement')

  const linkPrefix = useMemo(() => {
    switch (relatedEntityType) {
      case ENTITIES.DEAL:
        return `/${paths.deals}/${relatedEntityDisplayId}`
      case ENTITIES.BUSINESS_PARTNER:
        return `/${paths.businessPartners}/${relatedEntityDisplayId}`
      default:
        return null
    }
  }, [relatedEntityDisplayId, relatedEntityType])

  const renderContent = useCallback(() => {
    if (isNil(requirement)) {
      return entityTypeName
    }
    const link = `${linkPrefix}/conditions?req_externalConditionId=${requirement?.condition?.id}#requirements`
    const name = `${entityTypeName} - ${requirement?.info?.name}`
    return (
      <Link href={link} target={'_blank'} wrappingType={WrappingType.Normal}>
        {name}
      </Link>
    )
  }, [entityTypeName, linkPrefix, requirement])

  if (!isEnabled) {
    return entityTypeName
  }

  return (
    <RequestStateResolver
      isLoading={isFetching}
      isError={isTechnicalError}
      busyIndicatorSize={BusyIndicatorSize.Small}
      errorToDisplay={
        <>
          {entityTypeName} <ErrorDataUnavailableInCell />
        </>
      }
      renderContent={renderContent}
    />
  )
}

ConditionRequirementChangelogRequirementLink.propTypes = {
  action: PropTypes.string.isRequired,
  requirementId: PropTypes.string.isRequired,
}

export default ConditionRequirementChangelogRequirementLink
