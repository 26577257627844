import PropTypes from 'prop-types'
import { useContext } from 'react'
import KpiOverviewCard from 'components/domains/kpis/KpiOverviewCard'
import KpiOverviewChartCard from 'components/domains/kpis/KpiOverviewChartCard'
import KpiAdjustmentCards from 'components/domains/kpis/adjustment/KpiAdjustmentCards'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { PropertyContext } from 'routes/properties/PropertyContext'

export const PropertyKpiOverviewCard = () => {
  const { property } = useContext(PropertyContext)

  return (
    <KpiOverviewCard
      referenceEntityType={cwpEntityTypes.PROPERTY}
      referenceEntityId={property.uuid}
    />
  )
}

export const PropertyKpiOverviewChartCard = ({ pageData } = {}) => {
  const { property } = useContext(PropertyContext)

  return (
    <KpiOverviewChartCard
      referenceEntityType={cwpEntityTypes.PROPERTY}
      referenceEntityId={property.uuid}
      kpiId={pageData?.kpiId}
    />
  )
}

PropertyKpiOverviewChartCard.propTypes = {
  pageData: PropTypes.shape({
    kpiId: PropTypes.string,
  }),
}

export const PropertyKpiAdjustmentCards = ({ pageData } = {}) => {
  const { property } = useContext(PropertyContext)

  return (
    <KpiAdjustmentCards
      referenceEntityType={cwpEntityTypes.PROPERTY}
      referenceEntityId={property.uuid}
      kpiId={pageData?.kpiId}
    />
  )
}

PropertyKpiAdjustmentCards.propTypes = {
  pageData: PropTypes.shape({
    kpiId: PropTypes.string,
  }),
}
