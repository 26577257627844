import { ObjectStatus, Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { TILE_VERSION as tileVersion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/CollateralAgreementsTableCell.module.css'
import { EUR_CURRENCY } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/syndicationStructure/utils/constants'
import Entity from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/ui/data/Entity'
import { useCustomizableCurrencyFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'
import { ConfigContext } from 'hooks/config/useConfig'

export const renderCagCell = ({ cell, t }) => {
  const { isBold, value, displayId, hasWarning } = cell.value ?? {}
  const { depth } = cell.row ?? { depth: 0 }

  return (
    <div className={[styles[`commitmentCell-${depth}`], isBold && styles.textBold].join(' ')}>
      <div className={styles.commitmentCellSingleElement}>
        <div>
          {!isNil(value) && (
            <div>
              <Text
                wrapping={false}
                className={[isBold && styles.textBold, styles.textWithOverflowHandling].join(' ')}
              >
                {value}
              </Text>
            </div>
          )}
          {displayId && (
            <div>
              <Text
                wrapping={false}
                className={[isBold && styles.textLabelColor, styles.textWithOverflowHandling].join(
                  ' ',
                )}
              >
                {displayId}
              </Text>
            </div>
          )}
        </div>
        {hasWarning && (
          <div className={styles.warningLabel}>
            {t('pages.deals.collateral-agreements-table.missing-information.label')}
          </div>
        )}
      </div>
    </div>
  )
}

export const renderBooleanCell = ({ cell, t }) => {
  const value = cell.value

  if (value === '') {
    return <></>
  }

  const text = value ? t('formatters.boolean.yes') : t('formatters.boolean.no')
  return <Text wrapping={false}>{text}</Text>
}

export const renderDefaultCell = ({ cell }) => <Text>{cell.value}</Text>

export const StatusColumnCell = ({ cell, t }) => {
  const systemStatus = cell?.row?.original?.systemStatus
  const version = cell?.row?.original?.tileVersion
  const statusMappingV2 = cell?.row?.original?.statusMapping ?? {}

  const {
    legacyDecisionPaperValues: {
      collateralAgreements: { systemStatus: systemStatusMapping = {} } = {},
    } = {},
  } = useContext(ConfigContext)

  const isV1Tile = version === tileVersion.V1

  const systemStatusMappingVersionized = isV1Tile ? systemStatusMapping : statusMappingV2

  if (cell.value) {
    return (
      <ObjectStatus inverted state={ValueState.None}>
        {cell.value}
      </ObjectStatus>
    )
  }

  if (!systemStatus?.id) {
    return <></>
  }
  const state = (() => {
    switch (systemStatus.id) {
      case systemStatusMappingVersionized.created:
        return ValueState.Information

      case systemStatusMappingVersionized.active:
        return ValueState.Success

      case systemStatusMappingVersionized.inactive:
        return ValueState.Error

      default:
        return ValueState.None
    }
  })()
  return (
    <ObjectStatus inverted state={state}>
      {t(systemStatus.name, {
        keyPrefix: 'pages.deals.collateral-agreements-table',
        defaultValue: systemStatus.name,
      })}
    </ObjectStatus>
  )
}

StatusColumnCell.propTypes = {
  cell: PropTypes.object.isRequired,
}

/**
 * @param {object} params
 * @param {object} params.cell
 * @param {object} [params.cell.value]
 * @param {{value: number, currency: string}} [params.cell.value.cagValue]
 * @param {{value: number, currency: string}} [params.cell.value.cagValueInHeadQuarterCurrency]
 * @param {boolean} [params.cell.value.isBold]
 * @param {(value: number, currency: string, options?: object | undefined) => string} params.formatMoney
 */
export const renderMoneyColumnCell = ({ cell, formatMoney }) => {
  const { isBold, cagValue, cagValueInHeadQuarterCurrency } = cell.value ?? {}

  const isSameCurrency = cagValue?.currency === cagValueInHeadQuarterCurrency?.currency

  return (
    <div className={[styles.moneyCell, isBold && styles.textBold].join(' ')}>
      <div className={styles.moneyCellSingleElement}>
        <div>
          {!isNil(cagValue) && (
            <div className={styles.caretCursor}>
              {formatMoney(cagValue.value, cagValue.currency || EUR_CURRENCY, {
                currencyDisplay: 'code',
              })}
            </div>
          )}
          {!isNil(cagValueInHeadQuarterCurrency) && !isSameCurrency && (
            <div className={`${styles.textLabelColor} ${styles.caretCursor}`}>
              {formatMoney(
                cagValueInHeadQuarterCurrency.value,
                cagValueInHeadQuarterCurrency.currency,
                {
                  currencyDisplay: 'code',
                },
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const renderValueCell = ({ cell, formatMoney }) => {
  const isBold = !!cell.value.isBold
  const value = cell.value.cagValue?.value
  const currency = cell.value.cagValue?.currency
  const hqValue = cell.value?.cagValueInHeadQuarterCurrency?.value
  const hqCurrency = cell.value?.cagValueInHeadQuarterCurrency?.currency

  const isSameCurrency = currency === hqCurrency

  return (
    <div className={[styles.moneyCell, isBold && styles.textBold].join(' ')}>
      <div className={styles.moneyCellSingleElement}>
        <div>
          {!isNil(value) && currency && (
            <div className={styles.caretCursor}>
              {formatMoney(value, currency || EUR_CURRENCY, {
                currencyDisplay: 'code',
              })}
            </div>
          )}
          {!isNil(hqValue) && !isSameCurrency && (
            <div className={`${styles.textLabelColor} ${styles.caretCursor}`}>
              {formatMoney(hqValue, hqCurrency, {
                currencyDisplay: 'code',
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const CollateralAgreementsTableCell = (props) => {
  const formatMoney = useCustomizableCurrencyFormatter()
  const { t: tFromHook } = useTranslation('decisionPaper')

  return props.renderCell({ cell: props.cell, formatMoney, t: tFromHook })
}

CollateralAgreementsTableCell.propTypes = {
  cell: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  renderCell: PropTypes.func.isRequired,
}

const collateralAgreementsTableCellProps = {
  cell: PropTypes.shape({
    value: PropTypes.shape({
      noOfExistingBusinesses: PropTypes.number,
      noOfNewBusinesses: PropTypes.number,
    }),
  }).isRequired,
}

/** @param {PropTypes.InferProps<typeof collateralAgreementsTableCellProps>} props */
export const FinancialProductsCell = ({ cell }) => {
  const { t: tCagTable } = useTranslation('decisionPaper', {
    keyPrefix: 'components.collateral-agreements.receivables-table',
  })

  if (!cell.value) return false

  return (
    <div className={styles.financialProductsCell}>
      <Text>{`${tCagTable('new-business')}:`}</Text>
      <Text>{cell?.value?.noOfNewBusinesses ?? 0}</Text>
      <Text>{`${tCagTable('existing-business')}:`}</Text>
      <Text>{cell?.value?.noOfExistingBusinesses ?? 0}</Text>
    </div>
  )
}

FinancialProductsCell.propTypes = collateralAgreementsTableCellProps

const nameCellWithLinkedEntityAndDisplayIdProps = {
  cell: PropTypes.shape({
    value: PropTypes.shape({
      name: PropTypes.string,
      displayId: PropTypes.string,
      id: PropTypes.string,
    }),
  }).isRequired,
  path: PropTypes.string.isRequired,
}

/** @param {PropTypes.InferProps<typeof nameCellWithLinkedEntityAndDisplayIdProps>} props */
export const NameCellWithLinkedEntityAndDisplayId = ({ cell: { value }, path }) => (
  <Entity name={value?.name} id={value?.displayId} link={`${path}/${value?.id}`} openInNewTab />
)

NameCellWithLinkedEntityAndDisplayId.propTypes = nameCellWithLinkedEntityAndDisplayIdProps
