import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DecisionProcessCreateStageDialog from 'components/domains/business-events-and-tasks/decision-process/stages/DecisionProcessCreateStageDialog'

const DecisionProcessCreateStageButton = () => {
  const { t: tNoPrefix } = useTranslation('translation')
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.create-stage',
  })

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)

  const handleCreateButtonClicked = useCallback(() => {
    setIsAddDialogOpen(true)
  }, [])

  return (
    <>
      <Button design={ButtonDesign.Default} onClick={handleCreateButtonClicked}>
        {t('button.text')}
      </Button>
      {isAddDialogOpen && (
        <DecisionProcessCreateStageDialog
          isOpen={isAddDialogOpen}
          setIsOpen={setIsAddDialogOpen}
          headerText={t('dialog.header-text')}
          submitButtonText={tNoPrefix('buttons.create')}
          successNotification={t('dialog.success-notification')}
        />
      )}
    </>
  )
}

export default DecisionProcessCreateStageButton
