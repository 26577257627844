import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useAddRequirementWatcher = (options) => {
  const { post } = useAccessTokenRequest()
  return useMutation(async ({ conditionId, requirementId, userId }) => {
    const { data } = await post({
      path: `/conditions/external/${conditionId}/requirements/${requirementId}/watchers`,
      body: {
        user_id: userId,
      },
    })
    return data
  }, options)
}
export default useAddRequirementWatcher
