import { useContext } from 'react'
import { useSelector } from 'react-redux'
import useCurrentPropertiesKpis from 'hooks/services/properties/kpis/useCurrentPropertiesKpis'
import { PropertyContext } from 'routes/properties/PropertyContext'

/**
 * Fetch properties kpis for cards on the property rent roll overview page.
 * As properties kpis are used sereval times on the page, we want to ensure
 * that the query is called with consistent arguments, so that it can be
 * deduplicated.
 */
const useCurrentRentRollOverviewSinglePropertyKpis = () => {
  const {
    property: { uuid: propertyUuid, currency_code: propertyCurrencyCode },
  } = useContext(PropertyContext)

  const selectedCurrency = useSelector(
    (state) => state.properties.commonCurrency.selectedCommonCurrency,
  )

  const currentPropertiesKpis = useCurrentPropertiesKpis([propertyUuid], {
    currency: selectedCurrency || propertyCurrencyCode,
    withTenantGranularity: true,
  })

  return {
    ...currentPropertiesKpis,
    data: currentPropertiesKpis?.data?.[0] ?? null,
  }
}

export default useCurrentRentRollOverviewSinglePropertyKpis
