import {
  Text,
  ObjectStatus,
  TableCell,
  TableRow,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Entity from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/components/ui/data/Entity'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/deal/OpsMemoDealTeamInternalMembersTile.module.css'

const OpsMemoDealTeamInternalMembersTableRow = ({
  userId,
  sapRelationName,
  leader,
  fullName,
  team,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.deal-team.information',
  })

  return (
    <TableRow key={userId}>
      <TableCell className={styles.nameCell}>
        <Entity name={fullName} id={userId} />
      </TableCell>
      <TableCell className={styles.teamCell}>
        <Text>{team}</Text>
      </TableCell>
      <TableCell className={styles.nameCell}>
        <Text>{sapRelationName}</Text>
      </TableCell>
      <TableCell>
        {leader && (
          <ObjectStatus inverted state={ValueState.Information}>
            {t('property.leading')}
          </ObjectStatus>
        )}
      </TableCell>
    </TableRow>
  )
}

OpsMemoDealTeamInternalMembersTableRow.propTypes = {
  userId: PropTypes.string.isRequired,
  sapRelationName: PropTypes.string,
  leader: PropTypes.bool,
  fullName: PropTypes.string,
  team: PropTypes.string,
}

export default OpsMemoDealTeamInternalMembersTableRow
