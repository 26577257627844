import { useMemo } from 'react'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useMultipleDealProperties from 'hooks/services/deals/properties/useMultipleDealProperties'
import useMultipleArrayPropertyUuids from 'hooks/services/properties/useMultipleArrayPropertyUuids'
import useMultipleCwpPropertyValuations from 'hooks/services/properties/valuations/useMultipleCwpPropertyValuations'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const useMultipleAnnualReviewFactSheetValuation = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { dealsData, dealUuids } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  // get an array of property uuids for each deal
  const {
    data: multipleDealPropertiesData,
    isLoading: isMultipleDealPropertiesLoading,
    isError: isMultipleDealPropertiesError,
  } = useCombinedQueryResults(
    useMultipleDealProperties({
      dealUuids: dealUuids,
    }) ?? {},
    { forceDataReturn: false },
  )

  const isFollowUpRequestEnabled = useMemo(
    () =>
      !isMultipleDealPropertiesLoading &&
      !isMultipleDealPropertiesError &&
      multipleDealPropertiesData &&
      multipleDealPropertiesData.some((property) => property.dealProperties?.length > 0),
    [isMultipleDealPropertiesError, isMultipleDealPropertiesLoading, multipleDealPropertiesData],
  )

  // create an array of arrays of property uuids related to a deal id
  const propertyUuidsArrays = useMemo(
    () =>
      isFollowUpRequestEnabled
        ? multipleDealPropertiesData.map((dealProperty) =>
            dealProperty.dealProperties.map((property) => property.propertyUuid),
          )
        : [],
    [isFollowUpRequestEnabled, multipleDealPropertiesData],
  )

  const flatPropertyUuidsArray = useMemo(
    () => (isFollowUpRequestEnabled ? propertyUuidsArrays.flat().map((uuid) => [uuid]) : []),
    [isFollowUpRequestEnabled, propertyUuidsArrays],
  )

  // array version of usePropertyUuids:
  // get an array of responses for each properties array (related to a deal id),
  // so one entry per deal on the highest array level
  const {
    data: multipleArrayPropertyUuidsData,
    isLoading: isMultipleArrayPropertyUuidsLoading,
    isError: isMultipleArrayPropertyUuidsError,
  } = useMultipleArrayPropertyUuids({
    propertyUuidsArrays,
    options: { enabled: isFollowUpRequestEnabled },
  })

  // array version of useCwpPropertyValuations:
  const {
    data: multiplePropertyValuationsData,
    isLoading: isMultiplePropertyValuationsLoading,
    isError: isMultiplePropertyValuationsError,
  } = useCombinedQueryResults(
    useMultipleCwpPropertyValuations({
      propertyUuids: flatPropertyUuidsArray,
      options: { enabled: isFollowUpRequestEnabled },
    }) ?? {},
    { forceDataReturn: false },
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isAnnualReviewDealsLoading,
      isMultipleDealPropertiesLoading,
      isMultipleArrayPropertyUuidsLoading,
      isMultiplePropertyValuationsLoading,
    ],
    errorValues: [
      isAnnualReviewDealsError,
      isMultipleDealPropertiesError,
      isMultipleArrayPropertyUuidsError,
      isMultiplePropertyValuationsError,
    ],
    errorDetails: [annualReviewDealsError],
    tileId,
  })

  const propertyIds = useMemo(
    () =>
      dealUuids.map((dealUuid, index) => {
        const propertiesArrayData = multipleArrayPropertyUuidsData?.[index]?.data?.properties
        return propertiesArrayData?.[0]?.id
      }),
    [dealUuids, multipleArrayPropertyUuidsData],
  )

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        multipleArrayPropertyUuidsData,
        multiplePropertyValuationsData,
        sourceRender: { propertyId: propertyIds[0] },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealsData,
    multipleArrayPropertyUuidsData,
    multiplePropertyValuationsData,
    propertyIds,
    error,
  ])
}

export default useMultipleAnnualReviewFactSheetValuation
