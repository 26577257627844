/**
 * Simple helper function to convert a sentence cased letter string to snake case
 * Example: "My name is" => my_name_is
 * @param {*} str String
 * @returns String
 */
export const toSnakeFromSentenceCase = (str) => str.toLowerCase().replace(/ /g, '_')

/**
 * Simple helper function to convert a sentence cased letter string to kebap case
 * Example: "My name is" => my-name-is
 * @param {*} str String
 * @returns String
 */
export const toKebapFromSentenceCase = (str) => str.toLowerCase().replace(/ /g, '-')

/**
 * Simple helper function to convert a sentence cased letter string to camel case
 * Example: "My name is" => myNameIs
 * @param {*} str String
 * @returns String
 */
export const toCamelCaseFromSentenceCase = (str) =>
  str.toLowerCase().replace(/\s+(.)/g, (_, group1) => group1.toUpperCase())
