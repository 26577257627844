import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useDeleteRentRollWorkingVersionColumnMapping = (options) => {
  const { post: deleteRrwvColumnMapping } = useAccessTokenRequest()

  return useMutation(
    async ({ propertyUuids }) =>
      await deleteRrwvColumnMapping({
        path: `/properties/rent-roll/working-version/column-mapping/delete`,
        body: {
          property_uuids: propertyUuids,
        },
      }),
    options,
  )
}
