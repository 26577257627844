import { Grid } from '@fioneer/ui5-webcomponents-react'
import { useContext } from 'react'
import styles from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparison.module.css'
import PropertyRentRollComparisonCard from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonCard'
import PropertyRentRollComparisonCurrentRentCard from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonCurrentRentCard'
import PropertyRentRollComparisonCurrentRentPerUomCard from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonCurrentRentPerUomCard'
import PropertyRentRollComparisonMessageBox from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonMessageBox'
import PropertyRentRollComparisonVacancyCard from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonVacancyCard'
import PropertyRentRollComparisonWaultCard from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonWaultCard'
import { CurrentRentRollContext } from 'components/domains/properties/rent-roll/comparison/constants'
import CWPLayout from 'components/ui/layout/CWPLayout'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useRentRoll } from 'hooks/services/properties/useRentRoll'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyRentRollComparison = () => {
  const { property } = useContext(PropertyContext)
  const {
    isLoading: isLoadingRentRoll,
    isError: isErrorRentRoll,
    data: currentRentRoll,
  } = useRentRoll(property.uuid)

  return (
    <RequestStateResolver
      isLoading={isLoadingRentRoll}
      isError={isErrorRentRoll}
      center={true}
      renderContent={() => (
        <CurrentRentRollContext.Provider value={currentRentRoll}>
          <CWPLayout>
            <CWPLayout.Full>
              <PropertyRentRollComparisonMessageBox />
            </CWPLayout.Full>
            <CWPLayout.Full>
              <Grid defaultSpan="XL3 L3 M12 S12" hSpacing={'16px'} className={styles.grid}>
                <PropertyRentRollComparisonCurrentRentCard />
                <PropertyRentRollComparisonCurrentRentPerUomCard />
                <PropertyRentRollComparisonVacancyCard />
                <PropertyRentRollComparisonWaultCard />
              </Grid>
            </CWPLayout.Full>
            <CWPLayout.Full>
              <PropertyRentRollComparisonCard />
            </CWPLayout.Full>
          </CWPLayout>
        </CurrentRentRollContext.Provider>
      )}
    />
  )
}

export default PropertyRentRollComparison
