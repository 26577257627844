import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreatePeriodicalChecks = (options) => {
  const { put } = useAccessTokenRequest()

  return useMutation(async ({ bpId }) => {
    const response = await put({
      path: `/covenants/businesspartners/${bpId}/periodical-checks`,
    })
    return response
  }, options)
}

export default useCreatePeriodicalChecks
