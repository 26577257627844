import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/conditions/overview/requirements/RequirementsTableEmpty.module.css'

const RequirementsTableEmpty = ({ isFilterOrSearchApplied }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.requirements.table' })

  const calculateText = useMemo(() => {
    if (isFilterOrSearchApplied) return t('no-filter-results')
    return t('empty')
  }, [isFilterOrSearchApplied, t])

  return (
    <FlexBox
      alignItems={FlexBoxAlignItems.Center}
      justifyContent={FlexBoxJustifyContent.Center}
      className={styles.noData}
    >
      <Text>{calculateText}</Text>
    </FlexBox>
  )
}

RequirementsTableEmpty.propTypes = {
  isFilterOrSearchApplied: PropTypes.bool.isRequired,
}

export default RequirementsTableEmpty
