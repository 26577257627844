import { Button, ButtonDesign, Modals, Text } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import useReadPropertyValuationExcel from 'components/domains/properties/valuation/valuation-results/buttons/useReadPropertyValuationExcel'
import FileUploaderButton from 'components/ui/FileUploaderButton'
import MessageBoxWithExpandableDetailsShowDetails from 'components/ui/dialog/MessageBoxWithExpandableDetailsShowDetails'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import {
  useShowMessageBox,
  MessageBoxActions,
  MessageBoxTypes,
} from 'components/ui/message-box/MessageBox'
import { useMultiplePropertyValuationCreation } from 'hooks/services/properties/valuations/creation/useMultiplePropertyValuationCreation'
import { addHighlightedValuation } from 'redux/slices/properties/valuationResultsTableSlice'

const PropertyValuationResultsUploadButton = ({ propertyUuid, propertyCurrencyCode, disabled }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.valuation.results.upload',
  })
  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()
  const dispatch = useDispatch()

  const createValuationMutation = useMultiplePropertyValuationCreation({
    propertyId: propertyUuid,
    onSuccess: (createdValuations = []) => {
      createdValuations
        .filter(({ status }) => status === 'fulfilled')
        .forEach(({ value }) => {
          dispatch(addHighlightedValuation(value.valuationId))
        })
      showToast({ children: t('success.text') })
    },
    onError: (error) => {
      showMessageBox({
        type: MessageBoxTypes.Error,
        titleText: t('error.title'),
        children: (
          <div>
            <Text>{t('error.default')}</Text>
            {error && <MessageBoxWithExpandableDetailsShowDetails messageDetails={error} />}
          </div>
        ),
        actions: [MessageBoxActions.OK],
      })
    },
  })

  const onClickAdd = useCallback(
    (valuationResults) => {
      createValuationMutation.mutateAsync({
        valuationResults: valuationResults.map(
          ({ valueAmount: { currency, number }, ...rest }) => ({
            ...rest,
            value: number,
            currency,
          }),
        ),
      })
    },
    [createValuationMutation],
  )

  const handleUploadSuccess = useCallback(
    (valuationResults) => {
      showMessageBox(
        {
          type: MessageBoxTypes.Confirm,
          titleText: t('confirmation.title'),
          children: t('confirmation.text', { valuationResultCount: valuationResults?.length }),
          actions: [
            <Button
              key="button-add"
              design={ButtonDesign.Emphasized}
              onClick={() => onClickAdd(valuationResults)}
            >
              {t('confirmation.buttons.add')}
            </Button>,
            MessageBoxActions.Cancel,
          ],
        },
        document.body,
      )
    },
    [onClickAdd, showMessageBox, t],
  )

  const { data: readPropertyValuationExcel, isFetching, isError } = useReadPropertyValuationExcel()

  const handleUploadError = useCallback(
    (errorMessage) =>
      showMessageBox(
        {
          type: MessageBoxTypes.Error,
          titleText: t('error.title'),
          children: isEmpty(errorMessage) ? t('error.default') : errorMessage,
          actions: [MessageBoxActions.OK],
        },
        document.body,
      ),
    [showMessageBox, t],
  )
  const handleUploadButtonClicked = (event) =>
    new Promise((resolve) => {
      const file = event?.target?.files?.[0]
      // If user clicks on cancel, no file is passed
      if (!file) {
        return
      }
      const reader = new FileReader()
      reader.addEventListener('load', (loadEvent) => {
        readPropertyValuationExcel({
          loadEvent,
          currencyCode: propertyCurrencyCode,
          onUploadSuccess: handleUploadSuccess,
          onUploadError: handleUploadError,
        })
        resolve()
      })
      reader.readAsArrayBuffer(file)
    })

  return (
    <RequestStateResolver
      isLoading={isFetching}
      isError={isError}
      errorToDisplay={<ErrorDataUnavailableInContent />}
      renderContent={() => (
        <FileUploaderButton
          onChange={handleUploadButtonClicked}
          design={ButtonDesign.Transparent}
          disabled={disabled}
          text={tNoPrefix('buttons.upload')}
          acceptedFileTypes={['.xls', '.xlsx']}
        />
      )}
    />
  )
}

PropertyValuationResultsUploadButton.propTypes = {
  propertyUuid: PropTypes.string.isRequired,
  propertyCurrencyCode: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

export default PropertyValuationResultsUploadButton
