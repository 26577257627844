import { ObjectStatus, ValueState, Link, LinkDesign } from '@fioneer/ui5-webcomponents-react'
import intersectionWith from 'lodash.intersectionwith'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import uniqBy from 'lodash.uniqby'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import styles from 'components/domains/properties/overview/associated-deals/AssociatedDealCard.module.css'
import DisplayCardView, { hideOptions } from 'components/ui/card/DisplayCardView'
import useDealsCollateralInformationByPropertyUuids from 'hooks/services/deals/collateral/useDealsCollateralInformationByPropertyUuids'
import useDeals from 'hooks/services/deals/useDeals'
import useAssociatedDealsData from 'hooks/services/properties/associated-deals/useAssociatedDealsData'
import useBackshopDealsForProperty from 'hooks/services/properties/associated-deals/useBackshopDealsForProperty'
import { DEAL_STATUS_RANKS } from 'routes/deals/financing/financingConstants'
import paths from 'routes/paths'
import { PropertyContext } from 'routes/properties/PropertyContext'

const EXISTING_BUSINESS = 'EXISTING_BUSINESS'
const NEW_BUSINESS = 'NEW_BUSINESS'

const dealHasCorrectLinkedTranche = (deal) =>
  (deal.rank >= DEAL_STATUS_RANKS.SIGNED &&
    deal.rank < DEAL_STATUS_RANKS.DEAD &&
    deal.linkedTranche.dataSource === EXISTING_BUSINESS) ||
  ((deal.rank < DEAL_STATUS_RANKS.SIGNED || deal.rank === DEAL_STATUS_RANKS.DEAD) &&
    deal.linkedTranche.dataSource === NEW_BUSINESS)

const AssociatedDealCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property-overview.associated-deal',
  })
  const { property: { uuid: propertyUuid } = {} } = useContext(PropertyContext)

  const navigate = useNavigate()

  const emptyContent = useMemo(
    () => ({ title: t('empty.title'), subtitle: t('empty.subtitle') }),
    [t],
  )
  const errorContent = useMemo(
    () => ({ title: t('error.title'), subtitle: t('error.subtitle') }),
    [t],
  )

  const {
    data: { deals: financedDeals = [] } = {},
    isLoading: isLoadingFinancedObject,
    isError: isErrorFinancedObject,
  } = useDeals({
    filter: {
      propertyUuids: [propertyUuid],
      workingVersion: [WorkingVersionType.LIVE, WorkingVersionType.WORKING_VERSION],
    },
  })

  const {
    data: { data: { collateralDealsByProperties = [] } = {} } = {},
    isLoading: isLoadingDealsCollateralProperties,
    isError: isErrorDealsCollateralProperties,
  } = useDealsCollateralInformationByPropertyUuids([propertyUuid])

  const {
    backshopDealIds = [],
    isError: isBackshopDealsError,
    isLoading: isBackshopDealsLoading,
  } = useBackshopDealsForProperty({
    propertyUuid,
  })

  const dealCollateralProperties = !isEmpty(collateralDealsByProperties)
    ? collateralDealsByProperties[0]?.collateralDeals
    : []
  const filteredDealCollateralProperties = dealCollateralProperties.filter((deal) =>
    dealHasCorrectLinkedTranche(deal),
  )

  const financedObjectAndCollateralDeals =
    intersectionWith(
      financedDeals,
      filteredDealCollateralProperties,
      (a, b) => a.dealUuid === b.dlId,
    ) ?? []
  const financedObjectAndCollateralDealUuids = financedObjectAndCollateralDeals.map(
    ({ dealUuid }) => dealUuid,
  )

  const uniqFilteredDealCollateralProperties = uniqBy(
    filteredDealCollateralProperties.filter(
      ({ dlId }) => !financedObjectAndCollateralDealUuids.includes(dlId),
    ),
    'dlId',
  )

  const associatedDealsData = useAssociatedDealsData({
    dealsFinanced: financedDeals.filter(
      ({ dealUuid }) => !financedObjectAndCollateralDealUuids.includes(dealUuid),
    ),
    collateralDeals: uniqFilteredDealCollateralProperties,
    financedObjectAndCollateralDeals,
    backshopDealIds,
  })

  const isBackshopDeal = (dealType) => dealType === 'backshop-deal'

  const fieldDefinitions = useMemo(() => {
    const associatedDealsFieldDefinitions = []

    associatedDealsData.forEach((deal, index) => {
      const isLastElement = index === associatedDealsData.length - 1
      const dealId = deal.id
      if (isBackshopDeal(deal.type)) {
        associatedDealsFieldDefinitions.push(
          {
            label: t('deal-id'),
            value: deal.id,
            hideOption: hideOptions.hideWhenEmpty,
          },
          {
            label: t('property-function'),
            value: t(deal.type),
            hideOption: hideOptions.hideWhenEmpty,
          },
          !isLastElement
            ? {
                customInfoComponent: <div className={styles.separator} />,
              }
            : { customInfoComponent: <></> },
        )
      } else {
        const isWorkingVersionDeal = deal.isWorkingVersion
        const workingVersionDealLinkSuffix = isWorkingVersionDeal ? '?working-version=true' : ''

        associatedDealsFieldDefinitions.push(
          {
            label: t('deal-name'),
            value: (
              <Link
                href={`/${paths.deals}/${dealId}${workingVersionDealLinkSuffix}`}
                target="_blank"
                design={LinkDesign.Emphasized}
              >
                {isWorkingVersionDeal
                  ? t('working-version-deal', { dealName: deal.name })
                  : deal.name}
              </Link>
            ),
            hideOption: hideOptions.hideWhenEmpty,
          },
          {
            label: '',
            value: dealId,
            hideOption: hideOptions.hideWhenEmpty,
          },
          {
            label: t('deal-status'),
            value: (
              <ObjectStatus inverted state={ValueState.Information}>
                {deal.status}
              </ObjectStatus>
            ),
            hideOption: hideOptions.hideWhenEmpty,
          },
          {
            label: t('property-function'),
            value: t(deal.type),
            hideOption: hideOptions.hideWhenEmpty,
          },
          isLastElement
            ? { customInfoComponent: <></> }
            : {
                customInfoComponent: <div className={styles.separator} />,
              },
        )
      }
    })

    return associatedDealsFieldDefinitions
  }, [associatedDealsData, t])

  const calculateHeaderTitle = useCallback(
    () => (associatedDealsData.length === 1 ? t('title.one.deal') : t('title')),
    [associatedDealsData.length, t],
  )

  const associatedDealEmpty = fieldDefinitions?.every(
    (field) => isNil(field.value) || isNil(field.label),
  )

  // navigation
  const onHeaderClick = () => {
    if (associatedDealsData.length === 1 && !isBackshopDeal(associatedDealsData[0].type)) {
      const dealId = associatedDealsData[0].id
      const associatedDealPath = `/${paths.deals}/${dealId}`
      navigate(associatedDealPath)
    } else {
      const dealsPath = `/${paths.deals}`
      navigate(dealsPath)
    }
  }

  const isLoading = useMemo(
    () => isLoadingFinancedObject || isLoadingDealsCollateralProperties || isBackshopDealsLoading,
    [isBackshopDealsLoading, isLoadingDealsCollateralProperties, isLoadingFinancedObject],
  )

  const isError = useMemo(
    () => isErrorFinancedObject || isErrorDealsCollateralProperties || isBackshopDealsError,
    [isBackshopDealsError, isErrorDealsCollateralProperties, isErrorFinancedObject],
  )

  return (
    <DisplayCardView
      cardHeaderTitle={calculateHeaderTitle()}
      emptyContent={emptyContent}
      isEmpty={associatedDealEmpty}
      isError={isError}
      errorContent={errorContent}
      isEditable={false}
      isLoading={isLoading}
      fieldDefinitions={fieldDefinitions}
      onHeaderClick={onHeaderClick}
      setEditMode={() => {}}
    />
  )
}

export default AssociatedDealCard
