import { useRequest } from 'hooks/services/baseService'

/**
 * NOTE: If you are trying to get the first relevant valuations by classifications (e.g. Market Value, Mortgage Lending Value etc.), then please use the following hook:
 * hooks/services/properties/valuations/useMultiPropertyValuations
 * @param {UUID} propertyId
 * @returns list of valuations for a given property
 */
const usePropertyValuations = (propertyId) =>
  useRequest({
    path: `/properties/${propertyId}/valuations`,
    translated: true,
    useCache: true,
    keys: ['valuations', propertyId],
    options: { enabled: !!propertyId },
  })

export default usePropertyValuations
