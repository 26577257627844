import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { mapTranchesToTableStructure } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/request/details/utils/drawdownDealStructureUtils'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useTranches from 'hooks/services/deals/covenants/useTranches'
import useSyndication from 'hooks/services/deals/syndication/useSyndication'
import useDealMini from 'hooks/services/deals/useDealMini'
import mapCounterparties from 'routes/deals/syndication/syndicationStructure/utils/backendToTableStructure/mapCounterparties'
import mapOwnShare from 'routes/deals/syndication/syndicationStructure/utils/backendToTableStructure/mapOwnShare'
import mapTotal from 'routes/deals/syndication/syndicationStructure/utils/backendToTableStructure/mapTotal'
import {
  ROW_TYPE_COUNTERPARTY,
  ROW_TYPE_OWN_SHARE,
  ROW_TYPE_ROLE,
} from 'routes/deals/syndication/syndicationStructure/utils/constants'
import mergeBackendAndEditData from 'routes/deals/syndication/syndicationStructure/utils/mergeBackendAndEditData'

const getBusinessPartnerIdFromRow = (row) => {
  switch (row.rowType) {
    case ROW_TYPE_COUNTERPARTY:
      return row.partyRoleTranche?.value?.id
    case ROW_TYPE_OWN_SHARE:
      return row.partyRoleTranche?.value
  }
}

const useDrawdownDealStructure = ({ entityRef: { entityId: dealUuid } }, tileId) => {
  const {
    isLoading: isDealLoading,
    isError: isDealError,
    data: dealData,
    error: dealError,
  } = useDealMini(dealUuid)

  const {
    isLoading: isSyndicationLoading,
    isError: isSyndicationError,
    data: syndicationData,
    error: syndicationError,
  } = useSyndication(dealUuid)

  const syndicationBackendTableData = useMemo(
    () => [
      ...mapTotal(syndicationData?.total),
      ...mapOwnShare(syndicationData?.ownShare),
      ...mapCounterparties(syndicationData?.counterparties, [], false),
    ],
    [syndicationData],
  )

  const businessPartnerIds = []
  // mergeBackendAndEditData not only merges the data but removes section headers without entries
  const syndicationTableData = mergeBackendAndEditData(syndicationBackendTableData, []).map(
    (row) => {
      if (row.isSectionHeader) {
        return row
      }

      const businessPartnerId = getBusinessPartnerIdFromRow(row)
      if (businessPartnerId) {
        businessPartnerIds.push(businessPartnerId)
      }
      const roles = row?.subRows
        ?.filter(({ rowType }) => rowType === ROW_TYPE_ROLE)
        ?.map(({ partyRoleTranche: { value } }) => value)
      return {
        commitment: row.commitment,
        partyRoleTranche: { ...row.partyRoleTranche, roles: isEmpty(roles) ? undefined : roles },
        rootRowId: row.rootRowId,
        rowHeight: row.rowHeight,
        rowType: row.rowType,
      }
    },
  )

  const {
    isLoading: isBusinessPartnersLoading,
    isError: isBusinessPartnersError,
    data: businessPartnerData,
    error: businessPartnerError,
  } = useBusinessPartnerMiniByIds(businessPartnerIds)

  const businessPartnerNamesById = useMemo(
    () =>
      businessPartnerData?.businessPartnerMinis?.reduce(
        (accumulator, businessPartner) => ({
          ...accumulator,
          [businessPartner.id]: businessPartner.fullName,
        }),
        {},
      ),
    [businessPartnerData?.businessPartnerMinis],
  )

  // when dealUuid is undefined the hook is not enabled
  const isTranchesHookEnabled = useMemo(
    () => !isSyndicationLoading && !isSyndicationError && isEmpty(syndicationTableData),
    [isSyndicationLoading, isSyndicationError, syndicationTableData],
  )
  const {
    isLoading: isTranchesLoading,
    isError: isTranchesError,
    data: tranchesData,
    error: tranchesError,
  } = useTranches({ dealUuid: isTranchesHookEnabled ? dealUuid : undefined })

  const tranchesTableData = useMemo(() => mapTranchesToTableStructure(tranchesData), [tranchesData])

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isDealLoading,
      isSyndicationLoading,
      !isEmpty(businessPartnerIds) && isBusinessPartnersLoading,
      isTranchesHookEnabled && isTranchesLoading,
    ],
    errorValues: [isDealError, isSyndicationError, isBusinessPartnersError, isTranchesError],
    errorDetails: [dealError, syndicationError, businessPartnerError, tranchesError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueLoading || isSomeValueError) {
      return { isLoading: isSomeValueLoading, isError: isSomeValueError, error }
    }
    const tableData = isTranchesHookEnabled ? tranchesTableData : syndicationTableData
    return {
      isLoading: false,
      isError: false,
      data: {
        tableData,
        businessPartnerNamesById,
        sourceRender: { dealDisplayId: dealData?.dealId },
      },
    }
  }, [
    businessPartnerNamesById,
    dealData?.dealId,
    error,
    isSomeValueError,
    isSomeValueLoading,
    syndicationTableData,
    isTranchesHookEnabled,
    tranchesTableData,
  ])
}

export default useDrawdownDealStructure
