import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import PropertyValuationTable from 'components/domains/properties/general-information/valuation/PropertyValuationTable'
import useFormatValuationsData from 'components/domains/properties/general-information/valuation/useFormatValuationData'
import DisplayAndEditCard from 'components/ui/card/DisplayAndEditCard'
import useCwpPropertyValuations from 'hooks/services/properties/valuations/useCwpPropertyValuations'
import { usePutPropertyValuations } from 'hooks/services/properties/valuations/usePutPropertyValuations'
import { useValuationCreateMethodCodes } from 'hooks/services/properties/valuations/useValuationCreateMethodCodes'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyValuationCard = () => {
  const {
    property: {
      uuid: propertyUuid,
      currency_code: propertyCurrencyCode,
      change_request_exist_indicator: changeRequestExistIndicator,
      allowed_operations: allowedOperations,
    },
  } = useContext(PropertyContext)
  const { t } = useTranslation()
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()

  const isAllowedPropertyValuationsUpdate =
    !changeRequestExistIndicator &&
    !!allowedOperations?.allowed_operations?.includes('PropertyValuation_Update')

  const {
    isLoading: isLoadingValuationCreateMethodCodes,
    isError: isErrorValuationCreateMethodCodes,
    data: valuationCreateMethodCodes,
  } = useValuationCreateMethodCodes()

  const {
    isFetching: isFetchingValuations,
    isError: isErrorValuations,
    data: valuationsData,
  } = useCwpPropertyValuations({ propertyUuid: propertyUuid })

  const { valuationsForReadMode, valuationsForEditMode } = useFormatValuationsData({
    valuationsData,
    valuationCreateMethodCodes,
    isDataLoading: isLoadingValuationCreateMethodCodes || isFetchingValuations,
    propertyCurrencyCode,
  })

  const {
    mutate: putValuations,
    isSuccess: isSuccessPutValuations,
    isError: isErrorPutValuations,
    error: errorCreateOrUpdateValuations,
    isLoading: isLoadingPutValuations,
  } = usePutPropertyValuations({
    onSuccess: () => {
      queryClient.invalidateQueries(['properties'])
      showToast({
        children: t('toast.changes-saved'),
      })
    },
    onError: () => {
      queryClient.invalidateQueries(['properties', propertyUuid, 'cwp-valuations'])
    },
  })

  const fieldDefinitions = [
    {
      label: 'valuations-table-read',
      isShownInDisplay: true,
      customInfoComponent: (
        <PropertyValuationTable isEditMode={false} valuations={valuationsForReadMode} />
      ),
      isShownInEdit: false,
    },
    {
      label: 'valuations-table-edit',
      isShownInDisplay: false,
      renderCustomEditComponent: ({ handleOnChange }) => (
        <PropertyValuationTable
          isEditMode={true}
          valuations={valuationsForEditMode}
          handleOnChange={handleOnChange}
        />
      ),
      isShownInEdit: true,
    },
  ]

  const getValuationPayload = (value, calculationMethodCode) => ({
    calculation_method_code: calculationMethodCode,
    value_amount: {
      number: value,
      currency: propertyCurrencyCode,
    },
  })

  const getCreateOrUpdatePayload = (valuation, value) => {
    if (!isNaN(value)) {
      const valueOrDefault = value || 0
      return getValuationPayload(valueOrDefault, valuation.externalMethodCode)
    }
    return null
  }

  const saveChanges = (changes) => {
    const valuationsToCreateOrUpdate = Object.entries(changes)
      .filter(([_, value]) => value !== null)
      .reduce((acc, [cwpCode, valuationValue]) => {
        const valuation = valuationsForEditMode.find((v) => v.cwpCode === cwpCode)
        const putValuationPayload = getCreateOrUpdatePayload(valuation, valuationValue)
        return putValuationPayload ? [...acc, putValuationPayload] : acc
      }, [])
    putValuations({
      property_uuid: propertyUuid,
      valuations: valuationsToCreateOrUpdate.filter(Boolean),
    })
  }

  const isLoading =
    isLoadingValuationCreateMethodCodes || isFetchingValuations || isLoadingPutValuations
  const isError = isErrorValuationCreateMethodCodes || isErrorValuations

  return (
    <DisplayAndEditCard
      cardHeaderTitle={t('pages.property.general-information.valuation.card.title')}
      isLoading={isLoading}
      isSaveDisabled={isLoading}
      isError={isError}
      saveHookIsSuccess={isSuccessPutValuations}
      saveHookIsError={isErrorPutValuations}
      saveHookError={errorCreateOrUpdateValuations}
      fieldDefinitions={fieldDefinitions}
      saveChanges={saveChanges}
      isEditable={isAllowedPropertyValuationsUpdate}
    />
  )
}
export default PropertyValuationCard
