import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
  Table,
  TableColumn,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { kpisAllowedOperations } from 'api/kpis/kpisAllowedOperations'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import styles from 'components/domains/kpis/adjustment/KpiAdjustmentTable.module.css'
import KpiAdjustmentTableDisplayRow from 'components/domains/kpis/adjustment/KpiAdjustmentTableDisplayRow'
import KpiAdjustmentTableEditRow from 'components/domains/kpis/adjustment/KpiAdjustmentTableEditRow'
import useKpiAdjustmentTableConfig from 'components/domains/kpis/adjustment/useKpiAdjustmentTableConfig'
import { rowKeyNewRow } from 'components/ui/tables/display-and-edit-table/constants'
import useCreateKpiAdjustment from 'hooks/services/kpis/useCreateKpiAdjustment'
import useDeleteKpiAdjustment from 'hooks/services/kpis/useDeleteKpiAdjustment'
import useKpiAllowedOperations from 'hooks/services/kpis/useKpiAllowedOperations'
import useUpdateKpiAdjustment from 'hooks/services/kpis/useUpdateKpiAdjustment'

const KpiAdjustmentTable = ({
  referenceEntityType,
  referenceEntityId,
  kpi,
  allAdjustments = [],
}) => {
  const { t } = useTranslation('translation')

  const today = useMemo(() => new Date(), [])

  const { data: { allowedOperations = [] } = {} } = useKpiAllowedOperations()
  const userIsAllowedToEdit =
    allowedOperations?.includes(kpisAllowedOperations.updateKpiAdjustment) ?? false

  const [isAddRow, setIsAddRow] = useState(false)

  const [editRows, setEditRows] = useState([])
  const findEditRow = (rowKey) => ({ ...editRows.find((editRow) => editRow.rowKey === rowKey) })
  const findAdjustment = (rowKey) => ({ ...kpi.adjustments.find(({ id }) => id === rowKey) })

  const getCurrentValue = (rowKey, fieldName) => {
    const editRow = findEditRow(rowKey)
    const originalAdjustment = findAdjustment(rowKey)
    return editRow[fieldName] ?? originalAdjustment[fieldName]
  }

  const removeChangeFromEditRows = (rowKey) => {
    setEditRows([...editRows.filter((editRow) => editRow.rowKey !== rowKey)])
    if (rowKey === rowKeyNewRow) {
      setIsAddRow(false)
    }
  }

  const getNewEditRow = ({ rowKey, parameter, value, oldRow }) => {
    const newRow = oldRow ? { ...oldRow } : { rowKey: rowKey }
    newRow[parameter] = value
    return newRow
  }

  const updateEditRow = (rowKey, parameter, value) => {
    if (isEmpty(findEditRow(rowKey))) {
      const newEditRow = getNewEditRow({ rowKey, parameter, value })
      setEditRows([...editRows, newEditRow])
    } else {
      const editRowsUpdated = editRows.map((oldRow) => {
        if (oldRow.rowKey !== rowKey) {
          return { ...oldRow }
        }
        return getNewEditRow({ rowKey, parameter, value, oldRow })
      })
      setEditRows([...editRowsUpdated])
    }
  }

  const { columnDefinition, tableData, newRow } = useKpiAdjustmentTableConfig({
    kpi,
    allAdjustments,
    today,
    getCurrentValue,
    updateEditRow,
  })

  const allTableData = () => (isAddRow ? [newRow, ...tableData] : [...tableData])

  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const showErrorMessageBox = useShowErrorMessageBox()
  const showMessageBox = Modals.useShowMessageBox()

  const deleteKpiAdjustment = useDeleteKpiAdjustment({
    onSuccess: () => {
      queryClient.invalidateQueries(['kpis', referenceEntityType, referenceEntityId])
      showToast({ children: t('toast.changes-saved') }, document.body)
    },
    onError: async (error) => {
      const errorJson = await error.response.json()
      showErrorMessageBox({ message: t('error.update.title'), error: errorJson })
    },
  })

  const createKpiAdjustment = useCreateKpiAdjustment({
    onSuccess: () => {
      queryClient.invalidateQueries(['kpis', referenceEntityType, referenceEntityId])
      showToast({ children: t('toast.changes-saved') }, document.body)
    },
    onError: async (error) => {
      const errorJson = await error.response.json()
      showErrorMessageBox({ message: t('error.update.title'), error: errorJson })
    },
  })

  const updateKpiAdjustment = useUpdateKpiAdjustment({
    onSuccess: () => {
      queryClient.invalidateQueries(['kpis', referenceEntityType, referenceEntityId])
      showToast({ children: t('toast.changes-saved') }, document.body)
    },
    onError: async (error) => {
      const errorJson = await error.response.json()
      showErrorMessageBox({ message: t('error.update.title'), error: errorJson })
    },
  })

  const handleDeleteRow = (rowKey) => {
    deleteKpiAdjustment.mutate({ adjustmentId: rowKey })
  }

  const onSaveAdjustment = (rowKey) => {
    const changedRow = findEditRow(rowKey)
    if (rowKey === rowKeyNewRow) {
      const adjustment = {
        code: kpi.code,
        value: changedRow.value,
        reference_entity: {
          type: referenceEntityType,
          id: referenceEntityId,
        },
        valid: {
          from: changedRow.validFrom,
          to: changedRow.validTo,
        },
        comment: changedRow.comment,
      }
      createKpiAdjustment.mutate({ adjustment: adjustment })
    } else {
      const adjustment = {
        value: changedRow['value'],
        comment: changedRow['comment'],
        validFrom: changedRow['validFrom'],
        validTo: changedRow['validTo'],
      }
      updateKpiAdjustment.mutate({ adjustmentId: rowKey, adjustment: adjustment })
    }
    removeChangeFromEditRows(rowKey)
  }

  const handleSaveRow = (rowKey, isRetrospective, isOverlapping) => {
    if (isRetrospective)
      showMessageBox(
        {
          type: MessageBoxTypes.Confirm,
          titleText: t('components.kpis.details.adjustments.retrospective-adjustment-dialog.title'),
          children: isOverlapping
            ? t('components.kpis.details.adjustments.retrospective-adjustment-dialog.overlapping')
            : t('components.kpis.details.adjustments.retrospective-adjustment-dialog'),
          actions: [
            <Button
              key="button-confirm"
              design={ButtonDesign.Emphasized}
              onClick={() => onSaveAdjustment(rowKey)}
            >
              {t('buttons.confirm')}
            </Button>,
            MessageBoxActions.Cancel,
          ],
        },
        document.body,
      )
    else onSaveAdjustment(rowKey)
  }

  return (
    <>
      <FlexBox
        className={styles.addAdjustmentRow}
        direction={FlexBoxDirection.Row}
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        alignItems={FlexBoxAlignItems.Center}
      >
        <Text>{t('components.kpis.details.adjustments.adjustments')}</Text>
        {userIsAllowedToEdit && (
          <Button
            design={ButtonDesign.Transparent}
            disabled={isAddRow}
            onClick={() => {
              setIsAddRow(true)
              setEditRows([...editRows, { rowKey: rowKeyNewRow }])
            }}
          >
            {t('buttons.add')}
          </Button>
        )}
      </FlexBox>
      <FlexBox>
        <Table
          className={styles.defaultCursor}
          noDataText={t('components.table.no-data')}
          columns={[
            ...columnDefinition.map(({ columnKey, title, alignment, demandPopin, minWidth }) => (
              <TableColumn
                key={columnKey}
                demandPopin={demandPopin}
                popinText={title}
                minWidth={minWidth}
              >
                <FlexBox justifyContent={alignment}>{title}</FlexBox>
              </TableColumn>
            )),
            <TableColumn key={'editDeleteButtons'} />,
          ]}
        >
          {allTableData().map((row) => {
            const editRow = findEditRow(row.rowKey)
            // edit row has no changes if it contains only rowKey value
            const noChanges = Object.keys(editRow).length === 1

            if (editRow.rowKey) {
              return (
                <KpiAdjustmentTableEditRow
                  key={row.rowKey}
                  rowData={row}
                  columnDefinition={columnDefinition}
                  noChanges={noChanges}
                  onCancel={() => removeChangeFromEditRows(row.rowKey)}
                  onSave={() => handleSaveRow(row.rowKey, row.isRetrospective, row.isOverlapping)}
                />
              )
            } else {
              return (
                <KpiAdjustmentTableDisplayRow
                  key={row.rowKey}
                  rowData={row}
                  columnDefinition={columnDefinition}
                  userIsAllowedToEdit={userIsAllowedToEdit}
                  onEdit={() => setEditRows([...editRows, { rowKey: row.rowKey }])}
                  onDelete={() => handleDeleteRow(row.rowKey)}
                />
              )
            }
          })}
        </Table>
      </FlexBox>
    </>
  )
}

KpiAdjustmentTable.propTypes = {
  referenceEntityType: PropTypes.string.isRequired,
  referenceEntityId: PropTypes.string.isRequired,
  kpi: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    unit: PropTypes.shape({
      type: PropTypes.string,
      code: PropTypes.string,
    }),
    adjustments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.number,
        validFrom: PropTypes.string,
        validTo: PropTypes.string,
        updatedBy: PropTypes.string,
        updatedAt: PropTypes.string,
        comment: PropTypes.string,
      }),
    ),
  }).isRequired,
  allAdjustments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      kpiCode: PropTypes.string,
      kpiName: PropTypes.string,
      validFrom: PropTypes.string,
      validTo: PropTypes.string,
    }),
  ),
}

export default KpiAdjustmentTable
