import { useState } from 'react'
import { useRequest } from 'hooks/services/baseService'

const NOT_FOUND = 404

export const useRentRollByKeyDateAndValidFrom = (propertyUuid, keyDate, validFrom) => {
  const [isNotFoundError, setNotFoundError] = useState(false)

  const {
    data: rentRoll,
    isError: isError,
    isLoading: isLoading,
  } = useRequest({
    path: `/properties/${propertyUuid}/rent-roll/key-date/${keyDate}/valid-from/${validFrom}`,
    translated: true,
    options: {
      retry: (_, error) => {
        const isNotFound = error.response.status === NOT_FOUND
        setNotFoundError(isNotFound)
        return !isNotFound
      },
    },
  })

  return {
    data: rentRoll,
    isError,
    isLoading,
    isNotFoundError: isNotFoundError,
  }
}
