/**
 * Takes a hook error response, reads the body and formats a JSON string describing the error
 * @param error a hook error response
 * @returns Json string describing the error
 */
export const formatHookError = async (error) => {
  let errorResponseJson
  try {
    errorResponseJson = await error.response.json()
  } catch (e2) {
    errorResponseJson = undefined
  }
  const errorMessageJson = {
    status: error?.response?.status,
    statusText: error?.response?.statusText,
    url: error?.response?.url,
    errorDetails: errorResponseJson,
  }
  const errorMessageString = JSON.stringify(errorMessageJson, null, 2)

  return errorMessageString
}
