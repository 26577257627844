import { Input, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

const hyphen = '-'

const MarketProxiesTableNameCell = ({ isEditing, proxySetting }) => {
  if (!isEditing) {
    return <Text>{proxySetting.name || hyphen}</Text>
  }

  return <Input readonly value={proxySetting.name} />
}

MarketProxiesTableNameCell.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  proxySetting: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
}

export default MarketProxiesTableNameCell
