import { CardHeader, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { noSelectionValueCode } from 'components/domains/business-events-and-tasks/decision-paper/DecisionPaperTemplateDropdown'
import DecisionPaperTemplateGroup from 'components/domains/business-events-and-tasks/decision-paper/DecisionPaperTemplateGroup'
import DecisionPaperTable from 'components/domains/business-events-and-tasks/events/creation/DecisionPaperTable'
import styles from 'components/domains/business-events-and-tasks/events/creation/EventCreationDecisionPaperTemplateTile.module.css'
import Card from 'components/ui/card/Card'
import LabeledSwitch from 'components/ui/input/LabeledSwitch'
import {
  setDecisionPaperTemplateCode,
  setEventIdToImportDataFrom,
  setCustomizedDecisionPaperTemplateStructure,
} from 'redux/slices/events/eventCreationSlice'

const EventCreationDecisionPaperTemplateTile = ({ eventCode, entityType, entityId, disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.events.creation.cards.decision-paper',
  })
  const dispatch = useDispatch()
  const [displayTable, setDisplayTable] = useState(false)
  const [selectedDecisionPaper, setSelectedDecisionPaper] = useState(undefined)
  const decisionPaperTemplateCode = useSelector(
    (state) => state.events.eventCreation.decisionPaperTemplateCode,
  )

  const handleSwitchClicked = useCallback(() => {
    setDisplayTable(!displayTable)
    dispatch(setEventIdToImportDataFrom(undefined))
    setSelectedDecisionPaper(undefined)
  }, [setDisplayTable, displayTable, dispatch])

  const handleChange = useCallback(
    ({ templateCode, customizedTemplateStructure, hasChangesToBaseTemplate }) => {
      setDisplayTable(false)
      if (!templateCode || templateCode === noSelectionValueCode) {
        dispatch(setEventIdToImportDataFrom(undefined))
        setSelectedDecisionPaper(undefined)
        dispatch(setDecisionPaperTemplateCode(null))
        return
      }
      dispatch(setDecisionPaperTemplateCode(templateCode))
      dispatch(
        setCustomizedDecisionPaperTemplateStructure(
          hasChangesToBaseTemplate ? customizedTemplateStructure : null,
        ),
      )
      dispatch(setEventIdToImportDataFrom(undefined))
      setSelectedDecisionPaper(undefined)
    },
    [dispatch],
  )

  const onRadioChange = useCallback(
    (eventId) => () => {
      dispatch(setEventIdToImportDataFrom(eventId))
      setSelectedDecisionPaper(eventId)
    },
    [dispatch],
  )

  const header = <CardHeader titleText={t('title')} subtitleText={t('description')} />

  return (
    <Card
      header={header}
      className={disabled ? styles.disabled : ''}
      {...(!!disabled && { inert: '' })}
    >
      <div className={styles.cardContentWrapper}>
        <DecisionPaperTemplateGroup
          onChange={handleChange}
          templateCode={decisionPaperTemplateCode}
          eventCode={eventCode}
          entityId={entityId}
          entityType={entityType}
        />
        {decisionPaperTemplateCode !== null && (
          <>
            <Text className={styles.textPadding}>{t('import-data')}</Text>
            <LabeledSwitch
              onChange={handleSwitchClicked}
              checked={displayTable}
              checkedText={t('synchronization.on')}
              uncheckedText={t('synchronization.off')}
              isLoading={false}
            />

            {displayTable && (
              <DecisionPaperTable
                entityId={entityId}
                onChange={onRadioChange}
                selectedDecisionPaper={selectedDecisionPaper}
                setSelectedDecisionPaper={setSelectedDecisionPaper}
                isEventCreation
              />
            )}
          </>
        )}
      </div>
    </Card>
  )
}

EventCreationDecisionPaperTemplateTile.propTypes = {
  eventCode: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

export default EventCreationDecisionPaperTemplateTile
