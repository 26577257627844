import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteDecisionPaperAttachment = (mutationOptions) => {
  const { delete: deleteCall } = useAccessTokenRequest()
  return useMutation(
    ({ eventId, attachmentId }) =>
      deleteCall({
        path: `/events/${eventId}/decision-paper/versions/current/attachments/${attachmentId}`,
      }),
    mutationOptions,
  )
}

export default useDeleteDecisionPaperAttachment
