import { useMutation } from '@tanstack/react-query'
import snakeCase from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateRiskIndicatorGroupById = ({ onSuccess, onError }) => {
  const { patch } = useAccessTokenRequest()

  return useMutation(
    /**
     * @param {object} params
     * @param {string} [params.businessPartnerId] ID of the business partner.
     * @param {string} [params.riskIndicatorGroupId] ID of the risk indicator group.
     */
    async ({ businessPartnerId, riskIndicatorGroupId, ...body }) => {
      if (!businessPartnerId || !riskIndicatorGroupId) {
        return
      }
      await patch({
        path: `/risk-monitoring/business-partners/${businessPartnerId}/risk-indicator-groups/${riskIndicatorGroupId}`,
        body: snakeCase(body),
      })
    },
    { onSuccess, onError },
  )
}

export default useUpdateRiskIndicatorGroupById
