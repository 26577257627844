import {
  FlexBox,
  FlexBoxDirection,
  Link,
  LinkDesign,
  MessageStrip,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { entityTypes } from 'api/events/status'
import { isConflictError } from 'api/requests'
import DecisionProcessAlcDialog from 'components/domains/business-events-and-tasks/decision-process/alc/DecisionProcessAlcDialog'
import styles from 'components/domains/business-events-and-tasks/decision-process/alc/DecisionProcessAlcMessageStrip.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useAuthorityLevelCalculatorAuthorityLevel from 'hooks/services/business-partners/authority-level-calculator/useAuthorityLevelCalculatorAuthorityLevel'
import useDeal from 'hooks/services/deals/useDeal'
import paths from 'routes/paths'

const dash = '-'

const DecisionProcessAlcMessageStrip = ({ eventId, entityId, entityType }) => {
  const { t } = useTranslation('translation', {
    keyPrefix:
      'components.business-events-and-tasks.decision-process.overview.alc-info.message-strip',
  })
  const [errorTitle, setErrorTitle] = useState(undefined)

  let dealUuid
  // The legacy/standard case was to only render the message strip for deals.
  // Now, the message is also rendered for certain events on business partner level, but without some additional info
  if (entityType === entityTypes.deal) {
    dealUuid = entityId
  }

  const {
    isLoading: isDealLoading,
    isError: isDealError,
    data: deal,
  } = useDeal(dealUuid, { enabled: !!dealUuid })
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const openAlcInfoDialog = useCallback(() => {
    setIsDialogOpen(true)
  }, [])

  const { borrowerBpId } = deal ?? {}

  const {
    isLoading: isAuthorityLevelLoading,
    isError: isAuthorityLevelError,
    error: authorityLevelError,
    data: authorityLevelData,
  } = useAuthorityLevelCalculatorAuthorityLevel(
    { eventId },
    {
      enabled: !!eventId,
    },
  )

  const { authorityLevel, warningMessage, unit } = authorityLevelData ?? {}

  // some deal based events miss the information to show the additional info dialog,
  // for which the presence of the custom warning message is an indicator
  const isDealBasedMessageStrip = useMemo(
    () => !isNil(dealUuid) && isNil(warningMessage),
    [warningMessage, dealUuid],
  )

  const renderErrorContent = useMemo(() => {
    if (isAuthorityLevelError && isConflictError(authorityLevelError)) {
      authorityLevelError?.response
        ?.clone()
        ?.json()
        .then((response) => {
          setErrorTitle(response?.title)
        })

      if (!isNil(errorTitle)) {
        return <Text className={styles.errorLabel}>{errorTitle}</Text>
      }
    }

    return <Text className={styles.errorLabel}>{t('error')}</Text>
  }, [authorityLevelError, errorTitle, isAuthorityLevelError, t])

  const renderContent = useCallback(
    () => (
      <>
        <Text className={styles.authorityLevel} wrapping={true} renderWhitespace={true}>
          {authorityLevel?.authorityLevelName || dash}
        </Text>
        {isDealBasedMessageStrip && !isNil(unit) && (
          <>
            &nbsp;
            <Link onClick={openAlcInfoDialog}>{t('buttons.learn-more')}</Link>
          </>
        )}
      </>
    ),
    [authorityLevel?.authorityLevelName, isDealBasedMessageStrip, openAlcInfoDialog, t, unit],
  )

  const renderNote = useCallback(() => {
    if (isDealBasedMessageStrip) {
      return <div>{t('note')}</div>
    } else if (!isNil(warningMessage)) {
      return <div>{t('note-general', { message: warningMessage })}</div>
    }
  }, [isDealBasedMessageStrip, t, warningMessage])

  const isLoading = useMemo(
    () => (!!dealUuid && isDealLoading) || isAuthorityLevelLoading,
    [dealUuid, isAuthorityLevelLoading, isDealLoading],
  )
  const isError = useMemo(
    () => (!!dealUuid && isDealError) || isAuthorityLevelError,
    [dealUuid, isAuthorityLevelError, isDealError],
  )

  return (
    <MessageStrip hideCloseButton>
      <FlexBox className={styles.messageWrapper} direction={FlexBoxDirection.Column}>
        <div className={styles.messageWrapper}>
          {t('text')}
          <SmallLoadingWrapper
            error={renderErrorContent}
            isError={isError}
            isLoading={isLoading}
            renderContent={renderContent}
          />
        </div>
        {renderNote()}
        <Text>
          <Link
            href={`/${paths.businessPartners}/${
              dealUuid ? borrowerBpId : entityId
            }/authority-level-calculator`}
            target="_blank"
            design={LinkDesign.Emphasized}
          >
            {t('alc-link')}
          </Link>
        </Text>
      </FlexBox>
      {isDialogOpen && isDealBasedMessageStrip && (
        <DecisionProcessAlcDialog
          isOpen={isDialogOpen}
          setIsOpen={setIsDialogOpen}
          dealUuid={dealUuid}
          borrowerBpId={borrowerBpId}
          eventId={eventId}
        />
      )}
    </MessageStrip>
  )
}

DecisionProcessAlcMessageStrip.propTypes = {
  eventId: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
}

export default DecisionProcessAlcMessageStrip
