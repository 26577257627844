import { t } from 'i18next'
import isNil from 'lodash.isnil'
import createSectionHeaderRow from 'routes/deals/syndication/syndicationStructure/utils/backendToTableStructure/createSectionHeaderRow'
import mapTotalTranches from 'routes/deals/syndication/syndicationStructure/utils/backendToTableStructure/mapTotalTranches'
import {
  DEFAULT_DISPLAY_ROW_HEIGHT,
  DEFAULT_DISPLAY_TEXT_HEIGHT,
  ROOT_ROW_ID_TOTAL,
} from 'routes/deals/syndication/syndicationStructure/utils/constants'

const mapTotal = (data) => {
  if (!data) {
    return []
  }

  const isConvertedCurrencyAvailable =
    !isNil(data.totalCommitmentHeadquarter) &&
    data.totalCommitment?.currency !== data.totalCommitmentHeadquarter?.currency

  const calculatedRowHeight =
    DEFAULT_DISPLAY_ROW_HEIGHT + (isConvertedCurrencyAvailable ? DEFAULT_DISPLAY_TEXT_HEIGHT : 0)
  const rootRowId = ROOT_ROW_ID_TOTAL

  const content = {
    rootRowId,
    partyRoleTranche: {
      value: t('pages.deals.syndicationStructure-table.subSection.deal-label'),
      isBold: true,
    },
    commitment: {
      value: data.totalCommitment,
      valueHeadquarter: data.totalCommitmentHeadquarter,
      isBold: true,
    },
    amountPA: {
      value: data.amountPerAnnum,
      valueHeadquarter: data.amountPerAnnumHeadquarter,
      isBold: true,
    },
    amountTerm: {
      value: data.amountTerm,
      valueHeadquarter: data.amountTermHeadquarter,
      isBold: true,
    },
    signingDate: {
      value: data.signingDate,
      isBold: true,
    },
    subRows: mapTotalTranches(data.tranches, rootRowId),
    rowHeight: calculatedRowHeight,
  }

  return [createSectionHeaderRow('total'), content]
}

export default mapTotal
