import { DatePicker, ValueState } from '@fioneer/ui5-webcomponents-react'
import cloneDeep from 'lodash.clonedeep'
import merge from 'lodash.merge'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { monitoringItemShape } from 'components/domains/business-partners/periodical-checks/monitoring-information/PeriodicalMonitoringPropTypes'
import { isDateValid } from 'components/domains/business-partners/periodical-checks/next-periodical-check/useValidateNextPeriodicalCheck'
import { CardEditItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const NextPeriodicalCheckEdit = ({ formState, updateFormState, configurationForCovenant }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-partners.periodical-checks.monitoring-card',
  })
  const { parse: parseDate, localePattern } = useShortDateFormatter()
  const { dueDate = {} } = formState ?? {}
  const { withDocumentsRequest } = configurationForCovenant ?? {}

  const onValueChange = (value) => {
    const oldFormState = cloneDeep(formState)
    const newFormState = merge(oldFormState, value)
    updateFormState(newFormState)
  }

  return (
    <CardSection>
      <CardEditItem
        required
        id="key-date"
        label={t('key-date-test-date')}
        value={
          <DatePicker
            value={dueDate?.keyDate}
            valueState={!isDateValid(dueDate?.keyDate) ? ValueState.Error : ValueState.None}
            formatPattern={localePattern}
            onChange={(event) =>
              onValueChange({
                dueDate: {
                  keyDate: parseDate(event.target.value, localePattern) ?? event.target.value,
                },
              })
            }
          />
        }
      />
      {withDocumentsRequest && (
        <CardEditItem
          required
          id="target-delivery-date"
          label={t('target-delivery-date')}
          value={
            <DatePicker
              value={dueDate?.targetDeliveryDate}
              valueState={
                !isDateValid(dueDate?.targetDeliveryDate) ? ValueState.Error : ValueState.None
              }
              formatPattern={localePattern}
              onChange={(event) =>
                onValueChange({
                  dueDate: {
                    targetDeliveryDate:
                      parseDate(event.target.value, localePattern) ?? event.target.value,
                  },
                })
              }
            />
          }
        />
      )}
      <CardEditItem
        id="check-date"
        required
        label={t('check-due-date')}
        value={
          <DatePicker
            value={dueDate?.checkDate}
            valueState={!isDateValid(dueDate?.checkDate) ? ValueState.Error : ValueState.None}
            formatPattern={localePattern}
            onChange={(event) =>
              onValueChange({
                dueDate: {
                  checkDate: parseDate(event.target.value, localePattern) ?? event.target.value,
                },
              })
            }
          />
        }
      />
    </CardSection>
  )
}

NextPeriodicalCheckEdit.propTypes = {
  configurationForCovenant: PropTypes.shape({
    withDocumentsRequest: PropTypes.bool,
  }),
  formState: monitoringItemShape,
  updateFormState: PropTypes.func.isRequired,
}

export default NextPeriodicalCheckEdit
