import {
  List,
  CustomListItem,
  Popover,
  PopoverPlacementType,
  PopoverHorizontalAlign,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import VersionsHistoryListItem from 'components/ui/button/VersionsHistoryListItem'
import styles from 'components/ui/button/VersionsHistoryPopover.module.css'

const VersionsHistoryPopover = ({
  versions,
  opener,
  isOpen,
  setIsOpen,
  ListItemComponent = VersionsHistoryListItem,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ui.buttons.versions-history.popover',
  })

  return createPortal(
    <Popover
      opener={opener}
      open={isOpen}
      onAfterClose={() => setIsOpen(false)}
      headerText={t('title')}
      placementType={PopoverPlacementType.Bottom}
      horizontalAlign={PopoverHorizontalAlign.Right}
      className={styles.popover}
    >
      <List className={styles.popoverContent}>
        {versions.map(
          ({ version, lastUpdatedAt, lastUpdatedBy, onItemClicked, isCurrent, customProps }) => (
            <CustomListItem
              key={`version-${version}`}
              onClick={() => {
                onItemClicked()
                setIsOpen(false)
              }}
              className={styles.popoverListItem}
            >
              <ListItemComponent
                version={version}
                isCurrent={isCurrent}
                lastUpdatedAt={lastUpdatedAt}
                lastUpdatedBy={lastUpdatedBy}
                {...customProps}
              />
            </CustomListItem>
          ),
        )}
      </List>
    </Popover>,
    document.body,
  )
}

VersionsHistoryPopover.propTypes = {
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      version: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      lastUpdatedAt: PropTypes.string.isRequired,
      lastUpdatedBy: PropTypes.string.isRequired,
      onItemClicked: PropTypes.func.isRequired,
      isCurrent: PropTypes.bool,
      customProps: PropTypes.object,
    }),
  ).isRequired,
  opener: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  ListItemComponent: PropTypes.any,
}

export default VersionsHistoryPopover
