import {
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Icon,
  TableCell,
  TableRow,
} from '@fioneer/ui5-webcomponents-react'
import { PropTypes } from 'prop-types'
import { alignTableContent } from 'components/domains/properties/modular-table/ModularTable'

const ModularTableRowWithCheckboxAndArrow = ({
  rowData,
  columns = [],
  checkboxComponent,
  withArrow = true,
  withCheckBox = true,
}) => (
  <TableRow key={`${rowData.rowKey}`} data-id={rowData.rowKey} {...rowData.rowProperties}>
    {withCheckBox && (
      <TableCell key={`${rowData.tableKey}-select`}>
        {alignTableContent(checkboxComponent, FlexBoxAlignItems.Start)}
      </TableCell>
    )}
    {columns.map(({ columnKey, alignment }) => (
      <TableCell key={`${rowData.tableKey}-${columnKey}`}>
        {alignTableContent(rowData[columnKey].cellComponent, alignment)}
      </TableCell>
    ))}
    {withArrow && (
      <TableCell key={`${rowData.tableKey}-arrow`}>
        {alignTableContent(<Icon name="slim-arrow-right" />, FlexBoxAlignItems.End)}
      </TableCell>
    )}
  </TableRow>
)

ModularTableRowWithCheckboxAndArrow.propTypes = {
  rowData: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      columnKey: PropTypes.string.isRequired,
      sortingDisabled: PropTypes.bool,
      renderColumnContent: PropTypes.func,
      title: PropTypes.string,
      onClick: PropTypes.func,
      wrapText: PropTypes.bool,
      alignment: PropTypes.oneOf([
        FlexBoxJustifyContent.Start,
        FlexBoxJustifyContent.Center,
        FlexBoxJustifyContent.End,
      ]),
    }),
  ),
  checkboxComponent: PropTypes.node.isRequired,
  withArrow: PropTypes.bool,
  withCheckBox: PropTypes.bool,
}

export default ModularTableRowWithCheckboxAndArrow
