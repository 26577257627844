import camelize from 'camelize'
import { useRequest } from 'hooks/services/baseService'

export const useBusinessSegments = (options) => {
  const result = useRequest({
    path: '/deals/configurations/business-segments',
    translated: true,
    options,
  })

  return {
    ...result,
    data: camelize(result?.data),
  }
}

export const useDealTypes = (businessSegmentCode, options) => {
  const { isLoading, isFetching, isError, data } = useBusinessSegments(options)

  if (isLoading || isFetching || isError) return { isLoading, isFetching, isError, data }

  const dealTypes = businessSegmentCode
    ? data?.find(({ code }) => code === businessSegmentCode)?.dealTypes ?? []
    : data
        ?.map((segment) => segment.dealTypes)
        .flat(1)
        .filter((item, index, self) => self.findIndex(({ code }) => code === item.code) === index)
  return { isLoading, isFetching, isError, data: dealTypes }
}
