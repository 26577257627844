const getDealIdsAndNames = (t, items) =>
  items?.map((item) => ({
    id: item.kpiDeal.dealUuid,
    name: `${item?.kpiDeal?.dealName}${
      item?.kpiDeal?.isBPDeal ? ` (${t('rows.section.deal.thisBpsDeal')})` : ''
    }${
      item?.kpiDeal?.isWorkingVersion
        ? ` [${t('rows.section.deal.dealVersion.workingVersion')}]`
        : ''
    }<br/>(${item?.kpiDeal?.dealDisplayId})${
      item?.kpiDeal?.isExternal ? ` - ${t('external')}` : ''
    }`,
  }))

export default getDealIdsAndNames
