import {
  Carousel,
  CarouselArrowsPlacement,
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/financed-assets-information/MultiplePortfolioFinancedAssetsImagesSection.module.css'
import { ImageEnlargedDialog } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/financed-assets-information/shared/ImageEnlargedDialog'
import DecisionPaperPropertyImage from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/DecisionPaperPropertyImage'

const NoImageComponent = ({ className }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.portfolio-financed-assets-information.images',
  })
  return (
    <IllustratedMessage
      size={IllustrationMessageSize.Spot}
      titleText={t('empty.title')}
      subtitleText={t('empty.subtitle')}
      name={IllustrationMessageType.NoData}
      className={className}
    />
  )
}

NoImageComponent.propTypes = {
  className: PropTypes.string,
}

const MultiplePortfolioFinancedAssetsImagesSection = ({
  imageData,
  isPdfView,
  setSelectedImageIndex,
  propertyUuid = '',
}) => {
  const [enlargedImage, setEnlargedImage] = useState({ title: '', source: '' })
  const [openEnlargedDialog, setOpenEnlargedDialog] = useState(false)
  const onEnlargeImage = useCallback((source, title) => {
    setEnlargedImage({ source, title })
    setOpenEnlargedDialog(true)
  }, [])

  const renderContent = useCallback(() => {
    if (!imageData || imageData.length === 0) {
      return <NoImageComponent className={styles.illustratedMessageGlobal} />
    }

    const renderNoImage = (index) => (
      <NoImageComponent
        key={`property-image-${index}`}
        className={styles.illustratedMessageInCarousel}
      />
    )

    const renderPropertyImage = (image, index) => (
      <DecisionPaperPropertyImage
        key={`property-image-${index}`}
        imageTitle={image?.imageDescription ?? ''}
        altText={image?.imageTitle ?? ''}
        image={image?.imageData}
        onEnlargeImage={onEnlargeImage}
        isPdfView={isPdfView}
      />
    )

    const mappedImages = imageData?.map((image, index) =>
      image?.noImage ? renderNoImage(index) : renderPropertyImage(image, index),
    )

    const getPropertyImages = (selectedPropertyUuid) =>
      imageData.map((image, index) => {
        if (image.propertyUuid === selectedPropertyUuid) {
          return !image || image.noImage ? renderNoImage(index) : renderPropertyImage(image, index)
        }
      })

    return isPdfView ? (
      getPropertyImages(propertyUuid)
    ) : (
      <>
        <Carousel
          cyclic
          arrowsPlacement={CarouselArrowsPlacement.Content}
          onNavigate={(event) => setSelectedImageIndex(event.detail.selectedIndex)}
          className={styles.carousel}
        >
          {mappedImages}
        </Carousel>
        {openEnlargedDialog &&
          createPortal(
            <ImageEnlargedDialog
              enlargedImage={enlargedImage}
              setOpenEnlargedDialog={setOpenEnlargedDialog}
              openEnlargedDialog={openEnlargedDialog}
            />,
            document.body,
          )}
      </>
    )
  }, [
    imageData,
    isPdfView,
    propertyUuid,
    openEnlargedDialog,
    enlargedImage,
    onEnlargeImage,
    setSelectedImageIndex,
  ])

  return renderContent()
}

MultiplePortfolioFinancedAssetsImagesSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  imageData: PropTypes.array.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  setSelectedImageIndex: PropTypes.func.isRequired,
  propertyUuid: PropTypes.string,
}

export default MultiplePortfolioFinancedAssetsImagesSection
