import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import { flatMapDeep } from 'lodash'
import { useCallback, useMemo } from 'react'
import useCurrentApprovalsTableData from 'components/domains/business-partners/tile/arrears/current-approvals/useCurrentApprovalsTableData'
import { useRecursivelyExtractApprovalItems } from 'components/domains/business-partners/tile/arrears/current-approvals/useRecursivelyExtractApprovalItems'
import { ENTITY_TYPES } from 'routes/business-partners/arrears/constants'

//TODO: refactor hook to adapt both first render validation for table data and on change validation for row data

export const useCurrentApprovalsValidationSummary = (isStartApprovalProcess) => {
  const { data: tableData } = useCurrentApprovalsTableData(isStartApprovalProcess)

  const hasDirectApprovalItems = useCallback(
    (row) => row.subRows?.some((subRow) => subRow.entityType === ENTITY_TYPES.APPROVALITEM),
    [],
  )

  const recursivelyExtractProducts = useCallback((row) => {
    if (row.entityType === ENTITY_TYPES.APPROVALITEM) {
      return []
    }
    return [...row.subRows.flatMap((subRow) => recursivelyExtractProducts(subRow)), ...[row]]
  }, [])

  const approvalItems = useRecursivelyExtractApprovalItems(tableData)

  const products = useMemo(
    () =>
      flatMapDeep(
        tableData?.map((row) =>
          row?.subRows?.flatMap((subRow) => recursivelyExtractProducts(subRow)),
        ),
      ).filter((row) => hasDirectApprovalItems(row)),
    [hasDirectApprovalItems, recursivelyExtractProducts, tableData],
  )

  const valueStateSum = useMemo(() => {
    const valueStateApprovalAmounts = approvalItems.map(
      (item) => item.rowData.approvalItem.approvalAmount.valueState,
    )

    const valueStateApprovedUntil = products.map((item) => item.rowData.approvedUntil.valueState)
    const valueStateClassification = products.map(
      (item) => item.rowData.classificationCode.valueState,
    )
    const valueStates = [
      ...valueStateApprovalAmounts,
      ...valueStateApprovedUntil,
      ...valueStateClassification,
    ]
    return {
      errorSum: valueStates.filter((valueState) => valueState === ValueState.Error).length,
      warningSum: valueStates.filter((valueState) => valueState === ValueState.Warning).length,
    }
  }, [approvalItems, products])

  return useMemo(
    () => ({
      errorSum: valueStateSum.errorSum,
      warningSum: valueStateSum.warningSum,
    }),
    [valueStateSum.errorSum, valueStateSum.warningSum],
  )
}
