import {
  FlexBox,
  FlexBoxAlignItems,
  Label,
  MessageBoxTypes,
  MessageStrip,
  MessageStripDesign,
  Modals,
  ObjectStatus,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isDateEditable } from 'api/events/eventAllowedOperations'
import { eventStatus, getObjectStatusForEventStatus, taskStatus } from 'api/events/status'
import BusinessObjectLink, {
  BusinessObjectLinkCustomRender,
} from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import DecisionStageStatus from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageStatus'
import DecisionStagesActiveName from 'components/domains/business-events-and-tasks/decision-stage/DecisionStagesActiveName'
import DisplayCardView, { hideOptions } from 'components/ui/card/DisplayCardView'
import EditCardItem from 'components/ui/card/EditCardItem'
import EditCardView from 'components/ui/card/EditCardView'
import StaffMemberAutocompleteInput from 'components/ui/input/StaffMemberAutocompleteInput'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useUpdateEvent from 'hooks/services/business-events-and-tasks/events/useUpdateEvent'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import styles from 'routes/business-events-and-tasks/events/EventDetail.module.css'

const loadingKeys = {
  assignee: 'assignee',
  businessObject: 'businessObject',
}

const EventGeneralInformationTile = ({
  eventId,
  eventDisplayId,
  entityRef: { entityType, entityId },
  info: {
    creationDate,
    currentDueDate,
    originalDueDate,
    closingDate,
    assigneeId,
    status,
    eventCreationAction,
  },
  showEditButton,
  disabled,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.event-information' })
  const { t: tNoKeyPrefix } = useTranslation()
  const { t: statusTranslation } = useTranslation()
  const { parse, localePattern, format } = useShortDateFormatter()
  const { allowedOperations } = useContext(BusinessEventsAndTasksContext)
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false)
  const [editedAssigneeId, setEditedAssigneeId] = useState(assigneeId)
  const [editModeHasChanges, setEditModeHasChanges] = useState(false)
  const queryClient = useQueryClient()
  const [loadingErrors, setLoadingErrors] = useState({})

  const handleBusinessObjectLoadingError = useCallback(
    () => setLoadingErrors((errors) => ({ ...errors, [loadingKeys.businessObject]: true })),
    [],
  )

  const tileHasErrors = useMemo(
    () => Object.values(loadingErrors).some((isError) => isError),
    [loadingErrors],
  )

  const { translationKey: statusTranslationKey, objectStatus } =
    getObjectStatusForEventStatus(status)

  const {
    data: assigneeUser,
    isError: isUserByIdError,
    isFetching: isAssigneeFetching,
  } = useStaffMemberById(editedAssigneeId)
  const { mutate: updateEventMutation } = useUpdateEvent()
  const showMessageBox = useShowMessageBox()
  useEffect(() => {
    if (isUserByIdError) {
      setLoadingErrors((errors) => ({ ...errors, [loadingKeys.assignee]: true }))
    } else {
      setLoadingErrors((errors) => ({ ...errors, [loadingKeys.assignee]: false }))
    }
  }, [isAssigneeFetching, isUserByIdError])

  const handleOnAssigneeChange = useCallback(
    ({ id = null } = {}) => {
      setEditedAssigneeId(id)
      setEditModeHasChanges(id !== assigneeId)
    },
    [assigneeId],
  )

  const renderObjectStatus = useCallback(
    () => (
      <FlexBox alignItems={FlexBoxAlignItems.Start}>
        <ObjectStatus inverted state={objectStatus}>
          {statusTranslation(statusTranslationKey)}
        </ObjectStatus>
      </FlexBox>
    ),
    [objectStatus, statusTranslation, statusTranslationKey],
  )

  const onEditClicked = () => {
    setIsEditModeEnabled(true)
  }
  const onCancelClicked = () => {
    setEditedAssigneeId(assigneeId)
    setEditModeHasChanges(false)
    setIsEditModeEnabled(false)
  }

  const setAssigneeTouched = () => {
    setEditModeHasChanges(true)
    setEditedAssigneeId(null)
  }

  const renderAssigneeWithSuggestions = useCallback(
    () => (
      <StaffMemberAutocompleteInput
        onStaffMemberSelect={handleOnAssigneeChange}
        staffMemberName={assigneeUser?.fullName ?? ''}
        searchMinLength={1}
        suggestionsLimit={null}
        showClearIcon
        onSuggestionItemPreview={() => setAssigneeTouched()}
        onInput={(event) => event.target.value === '' && setAssigneeTouched()}
      />
    ),
    [assigneeUser?.fullName, handleOnAssigneeChange],
  )

  const entityLink = useMemo(
    () => (
      <BusinessObjectLink
        errorCallback={handleBusinessObjectLoadingError}
        entityId={entityId}
        entityType={entityType}
        customRender={BusinessObjectLinkCustomRender.singleLine}
      />
    ),
    [entityId, entityType, handleBusinessObjectLoadingError],
  )

  const decisionStageName = useMemo(() => <DecisionStagesActiveName eventId={eventId} />, [eventId])

  const decisionStageStatus = useMemo(() => <DecisionStageStatus eventId={eventId} />, [eventId])

  const renderListErrorStrip = useCallback(
    () => (
      <MessageStrip hideCloseButton design={MessageStripDesign.Negative}>
        {t('error.list-has-error')}
      </MessageStrip>
    ),
    [t],
  )
  const tileErrorMessageStrip = useMemo(
    () => ({
      customEditComponent: (
        <div className={styles.editListErrorWrapper}>{renderListErrorStrip()}</div>
      ),
      customInfoComponent: <div className={styles.listErrorWrapper}>{renderListErrorStrip()}</div>,
    }),
    [renderListErrorStrip],
  )

  const assigneeValue = useMemo(() => {
    if (isUserByIdError) {
      return <Label className={styles.errorText}>{t('error.assignee')}</Label>
    }
    return assigneeUser?.fullName
  }, [assigneeUser?.fullName, isUserByIdError, t])

  const fieldDefinitions = useMemo(
    () => [
      ...(tileHasErrors ? [tileErrorMessageStrip] : []),
      {
        name: 'creationDateField',
        label: t('label.creation'),
        value: format(creationDate),
        isShownInEdit: true,
        isShownInDisplay: true,
        editComponentType: 'DatePicker',
        editComponentProps: { disabled: true },
      },
      {
        name: 'closingDateField',
        label:
          status === eventStatus.aborted
            ? t('label.closing-cancelled')
            : t('label.closing-completed'),
        value: closingDate ? format(closingDate) : '',
        isShownInEdit: true,
        isShownInDisplay: true,
        hideOption: hideOptions.hideWhenEmpty,
        editComponentType: 'DatePicker',
        editComponentProps: { disabled: true },
      },
      {
        name: 'currentDueDateField',
        label: t('label.due-date.current'),
        value: currentDueDate ? format(currentDueDate) : '',
        isShownInEdit: true,
        isShownInDisplay: true,
        hideOption: hideOptions.hideWhenEmpty,
        editComponentType: 'DatePicker',
        editComponentProps: {
          disabled:
            status === eventStatus.drafted ||
            isDateEditable(allowedOperations, eventCreationAction),
        },
      },
      {
        name: 'originalDueDateField',
        label: t('label.due-date.original'),
        value: originalDueDate ? format(originalDueDate) : '',
        isShownInEdit: true,
        isShownInDisplay: true,
        hideOption: hideOptions.hideWhenEmpty,
        editComponentType: 'DatePicker',
        editComponentProps: {
          disabled:
            status !== eventStatus.drafted ||
            isDateEditable(allowedOperations, eventCreationAction),
        },
      },
      {
        name: 'assigneeField',
        label: t('label.assignee'),
        value: assigneeValue,
        isShownInEdit: true,
        isShownInDisplay: true,
        hideOption: hideOptions.hideWhenEmpty,
        renderCustomEditComponent: () => (
          <EditCardItem
            label={t('label.assignee')}
            editComponent={renderAssigneeWithSuggestions()}
          />
        ),
      },
      {
        name: 'statusField',
        label: t('label.status'),
        value: renderObjectStatus(),
        isShownInEdit: true,
        isShownInDisplay: true,
        renderCustomEditComponent: () => (
          <EditCardItem label={t('label.status')} editComponent={renderObjectStatus()} />
        ),
      },
      {
        name: 'businessEventIdField',
        label: t('label.business-event-id'),
        value: eventDisplayId,
        isShownInEdit: true,
        isShownInDisplay: true,
        editComponentType: 'Input',
        editComponentProps: { readonly: true },
      },
      {
        name: 'businessObjectTypeField',
        label: t('label.business-object-type'),
        value: tNoKeyPrefix(`events.entity-type.${entityType}`),
        isShownInEdit: true,
        isShownInDisplay: true,
        editComponentType: 'Input',
        editComponentProps: { readonly: true },
      },
      {
        name: 'businessObjectField',
        label: t('label.business-object'),
        value: entityLink,
        isShownInEdit: true,
        isShownInDisplay: true,
        renderCustomEditComponent: () => (
          <EditCardItem label={t('label.business-object')} editComponent={entityLink} />
        ),
      },
      {
        name: 'decisionStageNameField',
        label: t('label.decisionStageName'),
        value: decisionStageName,
        isShownInEdit: false,
        isShownInDisplay: true,
      },
      {
        name: 'decisionStageStatusField',
        label: t('label.decisionStageStatus'),
        value: decisionStageStatus,
        isShownInEdit: false,
        isShownInDisplay: true,
      },
      {
        name: 'creationType',
        label: t('label.creationType'),
        value: eventCreationAction ? t(`value.creationType.${eventCreationAction}`) : '',
        isShownInEdit: false,
        isShownInDisplay: true,
        hideOption: hideOptions.hideWhenEmpty,
      },
    ],
    [
      allowedOperations,
      assigneeValue,
      closingDate,
      creationDate,
      currentDueDate,
      decisionStageName,
      decisionStageStatus,
      entityLink,
      entityType,
      eventCreationAction,
      eventDisplayId,
      format,
      originalDueDate,
      renderAssigneeWithSuggestions,
      renderObjectStatus,
      status,
      t,
      tNoKeyPrefix,
      tileErrorMessageStrip,
      tileHasErrors,
    ],
  )

  const onSaveSuccess = useCallback(() => {
    setIsEditModeEnabled(false)
    Modals.showToast({ children: tNoKeyPrefix('toast.changes-saved') })
    setEditModeHasChanges(false)
    queryClient.invalidateQueries(['events'])
  }, [queryClient, tNoKeyPrefix])

  const onSaveError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: tNoKeyPrefix('components.cards.save-error'),
    })
  }, [showMessageBox, tNoKeyPrefix])

  const onSaveClicked = useCallback(
    ({ currentDueDateField, originalDueDateField }) => {
      const newAssignee = assigneeId !== editedAssigneeId ? editedAssigneeId : undefined
      const calculateDueDate = (field, dueDate) => {
        if (field !== dueDate) {
          if (field === '') return null
          else return parse(field, localePattern)
        }
        return undefined
      }
      const currentDueDateIso = calculateDueDate(currentDueDateField, currentDueDate)
      const originalDueDateIso = calculateDueDate(originalDueDateField, originalDueDate)

      updateEventMutation(
        {
          eventId,
          info: {
            currentDueDate: currentDueDateIso,
            originalDueDate: originalDueDateIso,
            assignee: newAssignee,
          },
        },
        {
          onSuccess: onSaveSuccess,
          onError: onSaveError,
        },
      )
    },
    [
      assigneeId,
      editedAssigneeId,
      currentDueDate,
      originalDueDate,
      updateEventMutation,
      eventId,
      onSaveSuccess,
      onSaveError,
      parse,
      localePattern,
    ],
  )

  if (isEditModeEnabled) {
    return (
      <EditCardView
        cardHeaderTitle={t('title')}
        fieldDefinitions={fieldDefinitions}
        onSaveClicked={onSaveClicked}
        onCancelClicked={onCancelClicked}
        setEditModeHasChanges={setEditModeHasChanges}
        editModeHasChanges={editModeHasChanges}
        isEditable={isEditModeEnabled}
        values={{
          creationDateField: creationDate,
          closingDateField: closingDate,
          currentDueDateField: currentDueDate,
          originalDueDateField: originalDueDate,
          businessObjectTypeField: tNoKeyPrefix(`events.entity-type.${entityType}`),
          businessEventIdField: eventDisplayId,
        }}
        className={disabled ? styles.disabled : ''}
        {...(!!disabled && { inert: '' })}
      />
    )
  }
  return (
    <DisplayCardView
      cardHeaderTitle={t('title')}
      fieldDefinitions={fieldDefinitions}
      setEditMode={onEditClicked}
      isEditable={showEditButton}
      className={disabled ? styles.disabled : ''}
      {...(!!disabled && { inert: '' })}
    />
  )
}

EventGeneralInformationTile.propTypes = {
  disabled: PropTypes.bool.isRequired,
  eventId: PropTypes.string.isRequired,
  eventDisplayId: PropTypes.string.isRequired,
  entityRef: PropTypes.shape({
    entityType: PropTypes.string.isRequired,
    entityId: PropTypes.string.isRequired,
  }).isRequired,
  info: PropTypes.shape({
    creationDate: PropTypes.string.isRequired,
    closingDate: PropTypes.string,
    currentDueDate: PropTypes.string,
    originalDueDate: PropTypes.string,
    assigneeId: PropTypes.string,
    eventCreationAction: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    tasks: PropTypes.arrayOf(
      PropTypes.shape({ status: PropTypes.oneOf(Object.values(taskStatus)) }),
    ),
  }),
  showEditButton: PropTypes.bool.isRequired,
}

export default EventGeneralInformationTile
