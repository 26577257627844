import {
  BusyIndicatorSize,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Text,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/covenants/check-results-card/CheckResultsCardHeader.module.css'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useNumberFormatter } from 'hooks/i18n/useI18n'

const testId = (id) =>
  process.env.NODE_ENV !== 'production' ? { 'data-testid': `check-results-card-header__${id}` } : {}

const NO_CONTENT_PLACEHOLDER = '-'

const CheckResultsCardHeader = ({
  headerAction,
  isLoading,
  isError,
  name,
  unit,
  value,
  ...props
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.results.check-results',
  })

  const formatNumber = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: 'compact',
  })

  return (
    <div {...props}>
      <FlexBox
        direction={FlexBoxDirection.Row}
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        alignItems={FlexBoxAlignItems.Center}
      >
        <Title level={TitleLevel.H5} className={styles.title}>
          {t('title')}
        </Title>
        <RequestStateResolver
          isLoading={isLoading}
          isError={isError}
          errorToDisplay={<ErrorDataUnavailableInCell />}
          busyIndicatorSize={BusyIndicatorSize.Small}
          renderContent={() => <div>{headerAction}</div>}
        />
      </FlexBox>
      <FlexBox direction={FlexBoxDirection.Row} className={styles.labelPadding}>
        <RequestStateResolver
          isLoading={isLoading}
          isError={isError}
          errorToDisplay={<ErrorDataUnavailableInCell />}
          busyIndicatorSize={BusyIndicatorSize.Small}
          renderContent={() => (
            <Text className={styles.textColor} {...testId('name-and-unit')}>
              {name ? `${name} | ${unit}` : NO_CONTENT_PLACEHOLDER}
            </Text>
          )}
        />
      </FlexBox>
      <FlexBox
        direction={FlexBoxDirection.Row}
        alignItems={FlexBoxAlignItems.Baseline}
        className={styles.valuePadding}
        {...testId('result-value')}
      >
        <RequestStateResolver
          isLoading={isLoading}
          isError={isError}
          errorToDisplay={<ErrorDataUnavailableInCell />}
          busyIndicatorSize={BusyIndicatorSize.Small}
          renderContent={() => (
            <Title level={TitleLevel.H1}>
              {value ? formatNumber(value) : NO_CONTENT_PLACEHOLDER}
            </Title>
          )}
        />
      </FlexBox>
    </div>
  )
}

CheckResultsCardHeader.propTypes = {
  headerAction: PropTypes.oneOfType([() => null, PropTypes.element]),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  name: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.number,
}

export default CheckResultsCardHeader
