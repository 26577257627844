import { TextAlign, VerticalAlign } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MultiCollateralAgreementsTableCell,
  renderCagCell,
  renderMoneyColumnCell,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/financing-structure/collateral-agreements/MultiCollateralAgreementsTableCell'

const useMultiCollateralAgreementsTileColumnDefinitions = () => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.collateral-agreements.table',
  })

  return useMemo(
    () =>
      [
        {
          Header: t('cag-label'),
          accessor: 'cag',
          renderCell: renderCagCell,
        },
        {
          Header: t('value-label'),
          accessor: 'cagValue',
          hAlign: TextAlign.Right,
          minWidth: 200,
          renderCell: renderMoneyColumnCell,
        },
        {
          Header: t('nominal-value-label'),
          accessor: 'cagNominalValue',
          hAlign: TextAlign.Right,
          minWidth: 200,
          renderCell: renderMoneyColumnCell,
        },
      ].map((element) => ({
        disableDragAndDrop: true,
        disableResizing: false,
        Cell: (cellProps) =>
          MultiCollateralAgreementsTableCell({
            ...cellProps,
            renderCell: element.renderCell,
          }),
        vAlign: VerticalAlign.Middle,
        ...element,
      })),
    [t],
  )
}

export default useMultiCollateralAgreementsTileColumnDefinitions
