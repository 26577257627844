import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useKpiAllowedOperations = (options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: '/kpis/allowed-operations',
      useCache: true,
      keys: ['kpis', 'allowed-operations'],
      options: {
        ...options,
      },
    }),
  )

export default useKpiAllowedOperations
