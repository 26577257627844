import isEmpty from 'lodash.isempty'
import orderBy from 'lodash.orderby'
import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useRiskIndicators from 'hooks/services/risk-monitoring/useRiskIndicators'
import useRiskIndicatorsDescription from 'hooks/services/risk-monitoring/useRiskIndicatorsDescription'

const useRiskMonitoringDescription = (
  { id: businessEventId, entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: riskIndicatorsGroups,
    isFetching: isFetchingRiskIndicators,
    isError: isErrorRiskIndicators,
    error: errorRiskIndicators,
  } = useRiskIndicators({
    businessEventId,
    businessPartnerId,
  })
  const groupId = riskIndicatorsGroups?.riskIndicatorGroups?.[0]?.groupId

  const {
    data: riskIndicatorDescriptions,
    isFetching: isFetchingRiskIndicatorDescription,
    isError: isErrorRiskIndicatorDescription,
    error: errorRiskIndicatorDescription,
  } = useRiskIndicatorsDescription({
    businessPartnerId,
    groupId,
  })

  const versions = useMemo(() => {
    if (isEmpty(riskIndicatorDescriptions?.availableVersions)) {
      return []
    }
    return riskIndicatorDescriptions?.availableVersions
  }, [riskIndicatorDescriptions])
  const sortedVersions = orderBy(versions, 'version', ['desc'])
  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isFetchingRiskIndicators, isFetchingRiskIndicatorDescription],
    errorValues: [isErrorRiskIndicators, isErrorRiskIndicatorDescription],
    errorDetails: [errorRiskIndicators, errorRiskIndicatorDescription],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        latestDescription: sortedVersions?.[0]?.description,
        sourceRender: {
          businessPartnerId,
          riskIndicatorGroupId:
            riskIndicatorsGroups?.riskIndicatorGroups?.[0]?.groupDisplayId ?? '',
        },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    sortedVersions,
    businessPartnerId,
    riskIndicatorsGroups?.riskIndicatorGroups,
    error,
  ])
}

export default useRiskMonitoringDescription
