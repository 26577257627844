import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import DecisionProcessAlcBooleanField from 'components/domains/business-events-and-tasks/decision-process/alc/helper/DecisionProcessAlcBooleanField'
import DecisionProcessLabeledLoadingContent from 'components/domains/business-events-and-tasks/decision-process/alc/helper/DecisionProcessLabeledLoadingContent'
import useRiskConformity from 'hooks/services/deals/risk-conformity/useRiskConformity'

const DecisionProcessAlcComplianceRiskWithStrategy = ({ dealUuid }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.overview.alc-info.dialog',
  })

  const dealRiskConformityResponse = useRiskConformity(dealUuid)
  const renderComplianceRiskWithStrategy = useCallback(
    (riskConformity) => <DecisionProcessAlcBooleanField value={riskConformity.compliance} />,
    [],
  )

  return (
    <DecisionProcessLabeledLoadingContent
      response={dealRiskConformityResponse}
      renderCallback={renderComplianceRiskWithStrategy}
      label={t('fields.compliance-risk-with-strategy')}
    />
  )
}

DecisionProcessAlcComplianceRiskWithStrategy.propTypes = {
  dealUuid: PropTypes.string.isRequired,
}

export default DecisionProcessAlcComplianceRiskWithStrategy
