import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ForbearanceAndUtpQuestionareContext } from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/ForbearanceAndUtpQuestionareTile'
import style from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/ForbearancePreCheckSection.module.css'
import {
  BooleanQuestion,
  QuestionKeys,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/forbearanceAndUtpQuestionareSchema'
import {
  convertToBoolean,
  getResultBanner,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/forbearanceQuestionareResults'
import CommentQuestion from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/shared/checksheet/CommentQuestion'
import DropDownQuestion from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/shared/checksheet/DropDownQuestion'
import NonEditableQuestion from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/shared/checksheet/NonEditableQuestion'
import PanelSection from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/shared/checksheet/PanelSection'

const ForbearancePreCheckSection = ({
  isEditMode,
  allSelectableOptions,
  onChange,
  currentAnswers,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.forbearance-and-utp-questionare.tile.forbearance-precheck-section',
  })
  const { t: tQuestionare } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.forbearance-and-utp-questionare',
  })
  const { t: tOptionsForbearanceUtp } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.forbearance-and-utp-questionare.options.forbearance-utp-check',
  })

  const { questionareState, setQuestionareState } = useContext(ForbearanceAndUtpQuestionareContext)
  const { shouldBeCollapsed } = questionareState

  const { boolOptions } = allSelectableOptions

  const onValuesChange = (question, newValue) => {
    const changedAnswersCopy = {
      ...currentAnswers,
      [QuestionKeys.Q11_comment]: {
        ...currentAnswers[QuestionKeys.Q11_comment],
        isRequired:
          question === QuestionKeys.Q11 && newValue !== '-'
            ? !convertToBoolean(newValue)
            : currentAnswers[QuestionKeys.Q11_comment].isRequired,
      },
      [QuestionKeys.Q12_comment]: {
        ...currentAnswers[QuestionKeys.Q12_comment],
        isRequired:
          question === QuestionKeys.Q12 && newValue !== '-'
            ? !convertToBoolean(newValue)
            : currentAnswers[QuestionKeys.Q12_comment].isRequired,
      },
    }

    changedAnswersCopy[question] = {
      value: newValue || null,
      isRequired: currentAnswers[question]?.isRequired,
    }

    const answersWithResolutions = {
      ...changedAnswersCopy,
      [QuestionKeys.Q13]: {
        ...changedAnswersCopy[QuestionKeys.Q13],
        value:
          changedAnswersCopy[QuestionKeys.Q11]?.value === BooleanQuestion.YES ||
          changedAnswersCopy[QuestionKeys.Q12]?.value === BooleanQuestion.YES
            ? 'required'
            : 'notRequired',
      },
    }

    const isFirstSectionAnswered = answersWithResolutions[QuestionKeys.Q13].value === 'notRequired'

    onChange(
      JSON.stringify({
        answers: {
          ...answersWithResolutions,
          [QuestionKeys.Q224_comment]: {
            ...answersWithResolutions[QuestionKeys.Q224_comment],
            isRequired: !isFirstSectionAnswered,
          },
          [QuestionKeys.Q231_comment]: {
            ...answersWithResolutions[QuestionKeys.Q231_comment],
            isRequired: !isFirstSectionAnswered,
          },
        },
      }),
    )
  }

  const getValue = (target, options) => options?.find((o) => o?.value === target)
  console.log('currentAnswers ', currentAnswers)
  useEffect(() => {
    if (!isEditMode && currentAnswers[QuestionKeys.Q13].value) {
      setQuestionareState((p) => ({
        ...p.questionareState,
        shouldBeCollapsed: true,
      }))
    }
  }, [currentAnswers, isEditMode, setQuestionareState])

  return (
    <>
      <PanelSection header={t('header')} section collapsed={shouldBeCollapsed}>
        <PanelSection header={t('Q11.header')} subSection>
          <DropDownQuestion
            questionKey={QuestionKeys.Q11}
            selectedValue={getValue(currentAnswers[QuestionKeys.Q11]?.value, boolOptions)}
            label={t('Q11.question')}
            isEditMode={isEditMode}
            selectOptions={boolOptions}
            onChange={onValuesChange}
          />
          <CommentQuestion
            isRequired={currentAnswers[QuestionKeys.Q11_comment].isRequired}
            questionKey={QuestionKeys.Q11_comment}
            value={currentAnswers[QuestionKeys.Q11_comment]?.value}
            label={tQuestionare('labels.comment')}
            isEditMode={isEditMode}
            onChange={onValuesChange}
          />
        </PanelSection>
        <PanelSection header={t('Q12.header')} subSection>
          <DropDownQuestion
            questionKey={QuestionKeys.Q12}
            selectedValue={getValue(currentAnswers[QuestionKeys.Q12]?.value, boolOptions)}
            label={
              <>
                <div>{t('Q12.question')}</div>
                <div className={style.optionsPadding}>{t('Q12.options')}</div>
              </>
            }
            onChange={onValuesChange}
            isEditMode={isEditMode}
            selectOptions={boolOptions}
          />
          <CommentQuestion
            isRequired={currentAnswers[QuestionKeys.Q12_comment].isRequired}
            questionKey={QuestionKeys.Q12_comment}
            value={currentAnswers[QuestionKeys.Q12_comment]?.value}
            label={tQuestionare('labels.comment')}
            isEditMode={isEditMode}
            onChange={onValuesChange}
          />
        </PanelSection>
        <PanelSection header={t('Q13.header')} subSection>
          <NonEditableQuestion
            label={tQuestionare('labels.result')}
            value={getResultBanner({
              rules: [
                currentAnswers[QuestionKeys.Q11]?.value === BooleanQuestion.YES,
                currentAnswers[QuestionKeys.Q12]?.value === BooleanQuestion.YES,
              ],
              values: [
                currentAnswers[QuestionKeys.Q11]?.value,
                currentAnswers[QuestionKeys.Q12]?.value,
              ],
              greenBanner: tOptionsForbearanceUtp('not-required'),
              redBanner: tOptionsForbearanceUtp('required'),
            })}
            isEditMode={isEditMode}
          />
        </PanelSection>
      </PanelSection>
    </>
  )
}

ForbearancePreCheckSection.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  currentAnswers: PropTypes.object.isRequired,
  allSelectableOptions: PropTypes.shape({
    [PropTypes.string]: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
        displayValue: PropTypes.node,
      }),
    ),
  }).isRequired,
}

export default ForbearancePreCheckSection
