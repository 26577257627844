import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useMassUpdateExternalConditions = (mutateOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({
      conditionIds,
      editedFields: {
        category,
        typeCode,
        refNumber,
        references,
        visibilityCodes,
        assignee,
        covenantCheck,
        externalAssignee,
        statusCode,
      },
    }) => {
      const { data } = await post({
        path: '/conditions/external/bulk-edit',
        body: snakecaseKeys({
          conditionIds,
          editedFields: {
            info: {
              assignee,
              externalAssigneeEmail: externalAssignee,
              covenantCheckId: covenantCheck,
              category,
              refNumber,
              type: typeCode ? { code: typeCode } : undefined,
              visibilities: visibilityCodes
                ? visibilityCodes.map((visibilityCode) => ({ code: visibilityCode }))
                : visibilityCodes,
            },
            status: statusCode ? { code: statusCode } : undefined,
            references: references
              ? {
                  entityIds: references.entityIds,
                  entityType: references.entityType,
                }
              : references,
          },
        }),
      })
      return camelize(data)
    },
    mutateOptions,
  )
}

export default useMassUpdateExternalConditions
