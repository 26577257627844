import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { INACTIVE } from 'components/domains/properties/rent-roll/working-version/PropertyRentRollWorkingVersionPublish'

export const useActivateInactiveBusinessPartners = (options = {}) => {
  const { patch } = useAccessTokenRequest()
  const queryClient = useQueryClient()

  const getInactiveBusinessPartners = (businessPartners) =>
    businessPartners.filter((businessPartner) => businessPartner?.status === INACTIVE)

  const obtainKeys = (businessPartnerId) => ['businesspartners', businessPartnerId]
  return useMutation(
    async (businessPartners) => {
      if (!businessPartners?.length) {
        return []
      }
      const inactiveBusinessPartners = getInactiveBusinessPartners(businessPartners)
      await Promise.all(
        inactiveBusinessPartners.map(async (businessPartner) => {
          await patch({
            path: `/businesspartners/${businessPartner.id}/statuses`,
            body: 'ACTIVE',
          })
        }),
      )
      return inactiveBusinessPartners
    },
    {
      ...options,
      onSettled: async (data, _error) =>
        data.forEach((activatedBusinessPartner) => {
          queryClient.invalidateQueries(obtainKeys(activatedBusinessPartner.id))
        }),
    },
  )
}
