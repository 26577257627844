import { AnalyticalTable, TextAlign } from '@fioneer/ui5-webcomponents-react'
import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TILE_VERSION as tileVersion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/exposure/by-product-class/BaselTwoByProductClassTile.module.css'
import getAssetClassRowsWithAssetSubClasses from 'components/domains/business-events-and-tasks/decision-paper/tiles/exposure/by-product-class/shared/getAssetClassRowsWithAssetSubClasses'
import {
  getOrdinalPositionForRiskFigureTypes,
  sortByOrdinalPosition,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/exposure/by-product-class/shared/getOrdinalPositionForRiskFigureTypes'
import { useCustomizableCurrencyFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'
import { ConfigContext } from 'hooks/config/useConfig'

const BaselTwoByProductClassTile = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.by-product-classes',
  })
  const {
    data: {
      assetClassesConfig,
      assetSubClassesConfig,
      riskFigureTypesConfig,
      riskFigures,
      assetClasses,
      riskFigureDisplayNamesMap,
      columnTypesToDisplay: columnTypesToDisplayFromData,
    },
    version,
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const config = useContext(ConfigContext)
  const isV1Tile = version === tileVersion.V1
  const columnTypesToDisplay = isV1Tile
    ? config?.riskFigureTypes ?? []
    : columnTypesToDisplayFromData
  const fallbackCurrency = riskFigures.risk_figures?.total?.unit ?? 'EUR'
  const formatCurrency = useCustomizableCurrencyFormatter()

  const getDisplayNameForClassType = (classTypeKey) =>
    riskFigureDisplayNamesMap[classTypeKey] ?? classTypeKey

  const orderedRiskFigures = sortByOrdinalPosition(
    getOrdinalPositionForRiskFigureTypes(riskFigures, camelize(riskFigureTypesConfig)),
  ).map(([name, value]) => ({ ...value, name }))

  const columnDefinitions = [
    {
      Header: t('product-class'),
      id: 'product-class',
      accessor: (row) => getDisplayNameForClassType(row.assetClass),
    },
    ...orderedRiskFigures
      .filter((figure) => columnTypesToDisplay.includes(figure.name))
      .map((figure) => ({
        Header: getDisplayNameForClassType(figure.name),
        id: figure.name,
        accessor: (row) =>
          formatCurrency(row[figure.name]?.value ?? 0, row[figure.name]?.unit ?? fallbackCurrency),
        hAlign: TextAlign.End,
      })),
  ]

  const hasAssetClasses = !isEmpty(assetClasses)
  const rows = hasAssetClasses
    ? [
        ...getAssetClassRowsWithAssetSubClasses({
          assetClasses,
          assetClassesConfig: assetClassesConfig?.riskFigureCategories,
          assetSubClassesConfig: assetSubClassesConfig?.riskFigureCategories,
        }),
        {
          assetClass: t('total'),
          ...riskFigures,
        },
      ]
    : []

  return (
    <AnalyticalTable
      title={t('title')}
      columns={columnDefinitions}
      data={rows}
      nrOfEntries={hasAssetClasses ? rows.length - 1 : 0}
      minRows={1}
      groupable={false}
      sortable={false}
      filterable={false}
      subRowsKey="subRows"
      isTreeTable
      className={`sapUiSmallMargin ${styles.riskFigureTable} tree-table`}
      reactTableOptions={{
        autoResetExpanded: false,
      }}
    />
  )
}

BaselTwoByProductClassTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}
export default BaselTwoByProductClassTile
