import z from 'zod'

export const createMonitoringEventResponseSchema = z.object({
  id: z.string(),
  info: z.object({
    code: z.string(),
    name: z.string(),
    creationDate: z.coerce.date(),
    assignee: z.string().nullish(),
  }),
  entityRef: z.object({
    entityId: z.string(),
    entityType: z.string(),
  }),
  displayId: z.string(),
  status: z.string(),
})
