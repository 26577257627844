import { Icon } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DealAutocompleteInput from 'components/domains/deals/deal-search/DealAutocompleteInput'
import { DealFilterKeys } from 'components/domains/deals/deal-search/DealFilterBar'
import DealSearchDialog from 'components/domains/deals/deal-search/DealSearchDialog'

const propTypes = {
  isAllowedToEdit: PropTypes.bool,
  value: PropTypes.shape({
    /** Unique identifier */
    id: PropTypes.string,
    /** Human-readable pseudo-unique identifier */
    displayId: PropTypes.string,
    name: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  initialFilterValues: PropTypes.shape({
    [PropTypes.oneOf(Object.values(DealFilterKeys))]: PropTypes.string,
  }),
  readOnlyFilters: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DealFilterKeys))),
  onChange: PropTypes.func,
  /** Initial value of the DealAutocompleteInput */
  initialSearch: PropTypes.string,
  disabled: PropTypes.bool,
}

/**
 * @typedef {object} deal
 * @property {string} [id]
 * @property {string} [displayId]
 * @property {string} [name]
 *
 * @typedef {object} state
 * @property {boolean} isOpen
 * @property {string} searchText
 *
 * @typedef {object} openDialog
 * @property {'OPEN_DIALOG'} type
 *
 * @typedef {object} closeDialog
 * @property {'CLOSE_DIALOG'} type
 *
 * @typedef {object} changeDeal
 * @property {'CHANGE_DEAL'} type
 * @property {deal} [payload]
 *
 * @typedef {object} searchDeal
 * @property {'SEARCH'} type
 * @property {string} payload
 *
 * @typedef {openDialog | closeDialog | changeDeal | searchDeal} action
 *
 * @param {state} state
 * @param {action} action
 * @returns {state}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_DIALOG':
      return { ...state, isOpen: true }
    case 'CLOSE_DIALOG':
      return { ...state, isOpen: false }
    case 'CHANGE_DEAL':
      return {
        ...state,
        searchText: action.payload ? `${action.payload?.name} - ${action.payload?.displayId}` : '',
      }
    case 'SEARCH':
      return { ...state, searchText: action.payload }
  }
}

/**
 * @typedef {object} overrides
 * @property {(deal?: deal) => void} [onChange]
 * @property {deal} [value]
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 */
const DealInput = ({
  isAllowedToEdit,
  initialSearch,
  initialFilterValues = {},
  readOnlyFilters = [],
  value,
  placeholder,
  onChange = () => {},
  disabled,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'components.collaterals.deal-input' })
  const [initiallySelectedDeals, setInitiallySelectedDeals] = useState([])

  const [state, dispatch] = useReducer(reducer, {
    isOpen: false,
    searchText: initialSearch ?? '',
  })

  useEffect(() => {
    dispatch({ type: 'CHANGE_DEAL', payload: value })
  }, [value])

  /**
   *
   * @param {object} [event]
   * @param {string} [event.dealId]
   * @param {string} [event.dealUuid]
   * @param {string} [event.dealName]
   */
  const handleSelect = (event) => {
    const newDeal = !!event && {
      id: event.dealUuid,
      displayId: event.dealId,
      name: event.dealName,
      status: event.dealStatus,
    }
    dispatch({
      type: 'CHANGE_DEAL',
      payload: newDeal,
    })
    onChange(newDeal)
  }

  const autoCompleteFilterValues = Object.keys(initialFilterValues).includes(
    DealFilterKeys.WorkingVersion,
  )
    ? { workingVersion: initialFilterValues[DealFilterKeys.WorkingVersion] }
    : undefined
  return (
    <>
      <DealAutocompleteInput
        onDealSuggestionSelected={handleSelect}
        icon={
          isAllowedToEdit && (
            <Icon name="value-help" onClick={() => dispatch({ type: 'OPEN_DIALOG' })} />
          )
        }
        additionalDealFilterValues={autoCompleteFilterValues}
        readonly={!isAllowedToEdit}
        placeholder={placeholder ?? t('placeholder')}
        value={state.searchText}
        // HINT: onInput is already used by the component, so we update onChange instead
        onChange={(event) => dispatch({ type: 'SEARCH', payload: event.target.value ?? '' })}
        showClearIcon
        disabled={disabled}
      />
      <DealSearchDialog
        // HINT: remount the dialog to override the search value kept in state
        key={state.searchText}
        initialFilterValues={{
          // HINT: only searching by a single field is supported, since the filters are "AND"ed together
          ...initialFilterValues,
          [DealFilterKeys.Name]:
            (state.searchText || initialFilterValues?.[DealFilterKeys.Name]) ?? '',
        }}
        readOnlyFilters={readOnlyFilters}
        isOpen={state.isOpen}
        isMultiSelect={false}
        setIsOpen={() => {}}
        onClose={() => dispatch({ type: 'CLOSE_DIALOG' })}
        onSelected={(selectedDeals) => {
          // HINT: ignore all but the first deal, because we're using a single select dialog
          /** @type {{dealUuid: string, dealName: string, dealId: string}} */
          const deal = selectedDeals[0]
          handleSelect(deal)
          setInitiallySelectedDeals(selectedDeals)
        }}
        initiallySelectedDeals={initiallySelectedDeals}
      />
    </>
  )
}

DealInput.propTypes = propTypes

export default DealInput
