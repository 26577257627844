import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  RentRollAreaCell,
  RentRollCurrentNetRentCell,
  RentRollRentalUnitCell,
  RentRollRentContractedYearCell,
  RentRollRentStartCell,
  RentRollSegmentAndUsageTypeCell,
  RentRollTenantCell,
} from 'components/domains/properties/rent-roll/details/table/PropertyRentRollDetailsTableCells'
import { renderAnalyticalTableCell } from 'components/ui/tables/analytical/AnalyticalTableCell'

export const useRentRollColumns = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.rent-roll.table',
  })

  return useMemo(() => {
    const defaultDisables = {
      disableDragAndDrop: true,
      disableGlobalFilter: true,
      disableFilters: true,
      disableSortBy: true,
      disableGroupBy: true,
    }

    const tableHelpers = {
      t,
    }
    return [
      {
        // This is the column where the expand-icon is rendered.
        Header: '',
        accessor: 'expand',
        id: 'expand',
        maxWidth: 32,
        disableResizing: true,
        ...defaultDisables,
      },
      {
        Header: t('column.rental-unit'),
        accessor: 'rentalUnit.name',
        id: 'name',
        Cell: renderAnalyticalTableCell(RentRollRentalUnitCell, tableHelpers),
        minWidth: 250,
        ...defaultDisables,
      },
      {
        Header: t('column.tenant'),
        accessor: 'rentalUnit.tenant',
        id: 'tenant_name',
        Cell: renderAnalyticalTableCell(RentRollTenantCell, tableHelpers),
        minWidth: 200,
        ...defaultDisables,
      },
      {
        Header: t('column.segment'),
        accessor: 'rentalUnit.segment',
        id: 'segment_uuid',
        Cell: renderAnalyticalTableCell(RentRollSegmentAndUsageTypeCell, tableHelpers),
        minWidth: 200,
        ...defaultDisables,
      },
      {
        Header: t('column.area'),
        accessor: 'rentalUnit.area',
        id: 'rental_unit_area',
        Cell: renderAnalyticalTableCell(RentRollAreaCell, tableHelpers),
        hAlign: 'right',
        minWidth: 200,
        ...defaultDisables,
      },
      {
        Header: t('column.annulized-contracted-rent'),
        accessor: 'rentalUnit.rentContractedYear',
        id: 'rent_contracted_year',
        Cell: renderAnalyticalTableCell(RentRollRentContractedYearCell, tableHelpers),
        hAlign: 'right',
        minWidth: 200,
        ...defaultDisables,
      },
      {
        Header: t('column.annualized-current-rent'),
        accessor: 'rentalUnit.rentCurrentYear',
        id: 'current_net_rent',
        Cell: renderAnalyticalTableCell(RentRollCurrentNetRentCell, tableHelpers),
        hAlign: 'right',
        minWidth: 200,
        ...defaultDisables,
      },
      {
        Header: t('column.rent-start'),
        accessor: 'rentalUnit.rentStartDate',
        id: 'rent_start_date',
        Cell: renderAnalyticalTableCell(RentRollRentStartCell, tableHelpers),
        hAlign: 'right',
        minWidth: 200,
        ...defaultDisables,
      },
    ]
  }, [t])
}
