import PropTypes from 'prop-types'
import styles from 'components/domains/properties/portfolio/overview/PropertyPortfolioValuationsCard.module.css'
import PropertyPortfolioValuationsTable from 'components/domains/properties/portfolio/overview/PropertyPortfolioValuationsTable'
import useMapPropertyPortfolioValuations from 'components/domains/properties/portfolio/overview/useMapPropertyPortfolioValuations'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useMultiPropertyValuationsDecisionPaper from 'hooks/services/properties/valuations/useMultiPropertyValuationsDecisionPaper'

const PropertyPortfolioValuationsCard = ({ properties }) => {
  const propertyUuids = properties.map((property) => property.uuid)

  const {
    data: { sum: valuationSums = {} } = {},
    isFetching: isFetchingValuations,
    isError: isErrorValuations,
  } = useMultiPropertyValuationsDecisionPaper(
    propertyUuids,
    ['sum'],
    undefined,
    {
      enabled: !!propertyUuids.length,
    },
    ['city', 'country', 'propertyType', 'tegovaRating'],
  )

  const preparedValuations = useMapPropertyPortfolioValuations({ valuationSums })

  return (
    <Card>
      <div className={isFetchingValuations ? styles.requestSpacingWrapper : ''}>
        <RequestStateResolver
          isLoading={isFetchingValuations}
          isError={isErrorValuations}
          renderContent={() => (
            <PropertyPortfolioValuationsTable
              preparedValuations={preparedValuations}
              totalNumberOfProperties={propertyUuids?.length}
            />
          )}
          center
          errorToDisplay={<ErrorDataUnavailableInContent />}
        />
      </div>
    </Card>
  )
}

PropertyPortfolioValuationsCard.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
}

export default PropertyPortfolioValuationsCard
