import { MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DecisionPaperManualTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/DecisionPaperManualTile'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useUpdateManualTile from 'hooks/services/business-events-and-tasks/decision-papers/tiles/manual/useUpdateManualTile'
import { showCurrentTileVersion } from 'redux/slices/decision-paper/tilesOverviewSlice'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionPaperManualTileLoadingWrapper = ({
  Tile,
  tileId,
  title: tileTitle,
  tileCode,
  subType,
  viewConfig,
  isPdfView = false,
  currentVersion,
  showConfirmationForLinks,
  isEditableInMinorVersion,
  lastAvailableVersion,
  ...tileProps
}) => {
  const {
    event: { id: eventId },
  } = useContext(BusinessEventsAndTasksContext)
  const { t: t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.tiles',
  })
  const showMessageBox = Modals.useShowMessageBox()
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSaveEnabled, setIsSaveEnabled] = useState(true)
  const [currentContent, setCurrentContent] = useState()
  const [hasContentChanges, setHasContentChanges] = useState(false)

  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { mutate: updateManualTile } = useUpdateManualTile()

  const onCancelClicked = useCallback(() => {
    setIsEditMode(false)
    setCurrentContent(tileStatus?.data?.data)
    setHasContentChanges(false)
  }, [tileStatus])

  const onEditClicked = useCallback(() => {
    setIsEditMode(true)
  }, [])

  const onSaveClicked = useCallback(() => {
    updateManualTile(
      { eventId, tileId, data: currentContent },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            'events',
            eventId,
            'decision-paper',
            'versions',
            'current',
            'tiles',
            tileId,
          ])
          setIsEditMode(false)
          dispatch(showCurrentTileVersion(tileId))
        },
        onError: () => {
          showMessageBox({
            type: MessageBoxTypes.Error,
            children: t('rich-text.update.error'),
          })
        },
      },
    )
  }, [t, showMessageBox, updateManualTile, currentContent, eventId, tileId, queryClient, dispatch])

  useEffect(() => {
    if (tileStatus?.data?.data) {
      setCurrentContent(tileStatus.data.data)
    }
  }, [tileStatus])

  const handleChange = useCallback(
    (content) => {
      setCurrentContent(content)
    },
    [setCurrentContent],
  )

  const renderContent = useCallback(
    () => (
      <Tile
        tileId={tileId}
        isEditMode={isEditMode}
        isPdfView={isPdfView}
        currentContent={currentContent}
        onChange={handleChange}
        setIsSaveEnabled={setIsSaveEnabled}
        setHasContentChanges={setHasContentChanges}
        showConfirmationForLinks={showConfirmationForLinks}
        currentVersion={currentVersion}
        {...tileProps}
      />
    ),
    [
      tileId,
      isEditMode,
      isPdfView,
      currentContent,
      handleChange,
      showConfirmationForLinks,
      currentVersion,
      tileProps,
    ],
  )

  return (
    <>
      <DecisionPaperManualTile
        titleText={tileTitle}
        tileId={tileId}
        subType={subType}
        tileCode={tileCode}
        viewConfig={viewConfig}
        isPdfView={isPdfView}
        isEditMode={isEditMode}
        onSaveClicked={onSaveClicked}
        onCancelClicked={onCancelClicked}
        onEditClicked={onEditClicked}
        hasChanges={hasContentChanges}
        isSaveEnabled={isSaveEnabled}
        currentVersion={currentVersion}
        isEditableInMinorVersion={isEditableInMinorVersion}
        lastAvailableVersion={lastAvailableVersion}
      >
        <LoadingStateWrapper
          isLoading={
            !tileStatus || tileStatus.isLoading || (!tileStatus.data && !tileStatus.isError)
          }
          loadingTitle=" "
          loadingDescription={t('loading')}
          errorTitle={t('loading.error')}
          errorDescription={t('loading.error.description')}
          errorDetails=" "
          isError={!!tileStatus && tileStatus.isError}
          renderContent={renderContent}
        />
      </DecisionPaperManualTile>
    </>
  )
}

DecisionPaperManualTileLoadingWrapper.propTypes = {
  Tile: PropTypes.func.isRequired,
  tileId: PropTypes.string.isRequired,
  tileCode: PropTypes.string.isRequired,
  subType: PropTypes.string,
  viewConfig: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool,
  currentVersion: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
  isEditableInMinorVersion: PropTypes.bool.isRequired,
  lastAvailableVersion: PropTypes.string.isRequired,
}

export default DecisionPaperManualTileLoadingWrapper
