import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CardHeaderLastEditedText } from 'components/domains/deals/card/CardHeaderLastEditedText'
import DealCashflowScenarioGeneralInformationCardDisplay from 'components/domains/deals/deal-cashflow/general-information/DealCashflowScenarioGeneralInformationCardDisplay'
import DealCashflowScenarioGeneralInformationCardEdit from 'components/domains/deals/deal-cashflow/general-information/DealCashflowScenarioGeneralInformationCardEdit'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import Card from 'components/ui/card/Card'
import { ToggleEditMode } from 'components/ui/card/CardHeaderWithEditMode'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useCashFlowScenario } from 'hooks/services/deals/cashflow/useCashFlowScenario'
import useUpdateCashflowScenario from 'hooks/services/deals/cashflow/useUpdateCashflowScenario'
import { DealContext } from 'routes/deals/DealContext'

export const DealCashflowScenarioGeneralInformationCard = ({ isAllowedToEdit, ...props }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.cashFlow.details.general-information',
  })
  const {
    deal: { dealUuid: dealUuid },
  } = useContext(DealContext)
  const { cashflowId } = useParams()

  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const showErrorMessageBox = useShowErrorMessageBox()

  const [isEditMode, setIsEditMode] = useState(false)
  const [isFormValid, setIsFormValid] = useState(true)
  const [formStateInitialized, setFormStateInitialized] = useState(false)
  const [formState, setFormState] = useState({
    name: null,
    description: null,
  })

  const {
    data: cashflowScenario = {},
    isLoading,
    isError,
  } = useCashFlowScenario(dealUuid, cashflowId)

  useEffect(() => {
    if (isLoading || isError || formStateInitialized) return
    setFormState({ name: cashflowScenario.name, description: cashflowScenario.description })
    setFormStateInitialized(true)
  }, [formStateInitialized, isError, isLoading, cashflowScenario])

  useEffect(() => {
    if (formState.name) {
      setIsFormValid(true)
      return
    }
    setIsFormValid(false)
  }, [formState, setIsFormValid])

  const updateFormState = (newState) => {
    setFormState((prevState) => ({
      ...prevState,
      ...newState,
    }))
  }

  const onCancelChanges = () => {
    setIsEditMode(false)
    setFormState({ name: cashflowScenario.name, description: cashflowScenario.description })
  }

  const onUpdateSuccess = () => {
    showToast({ children: t('update.success') }, document.body)
    queryClient.invalidateQueries('deals', dealUuid, 'cashflow-scenarios')
    setIsEditMode(false)
  }
  const onUpdateError = async (error) => {
    const { errors: [errorResponse] = [] } = await error.response.json()
    showErrorMessageBox({ message: t('update.error'), error: errorResponse })
  }
  const updateCovenant = useUpdateCashflowScenario({
    onSuccess: onUpdateSuccess,
    onError: onUpdateError,
  })
  const onSaveChanges = () => {
    updateCovenant.mutate({ dealUuid, cashflowScenarioUuid: cashflowId, data: formState })
  }

  return (
    <Card
      header={
        <CardHeaderLastEditedText
          title={t('title')}
          email={cashflowScenario?.lastUpdatedBy}
          timestamp={cashflowScenario?.lastUpdatedAt}
          actions={
            !isLoading &&
            !isError &&
            isAllowedToEdit && (
              <ToggleEditMode
                isEditMode={isEditMode}
                onToggle={() => setIsEditMode(true)}
                onSave={onSaveChanges}
                onCancel={onCancelChanges}
                checkSaveDisabled={() => !isFormValid}
              />
            )
          }
        />
      }
      {...props}
    >
      <RequestStateResolver
        center
        isLoading={!!isLoading}
        isError={!!isError}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        renderContent={() =>
          isEditMode ? (
            <DealCashflowScenarioGeneralInformationCardEdit
              cashflowScenario={cashflowScenario}
              formState={formState}
              updateFormState={updateFormState}
            />
          ) : (
            <DealCashflowScenarioGeneralInformationCardDisplay
              cashflowScenario={cashflowScenario}
            />
          )
        }
      />
    </Card>
  )
}

DealCashflowScenarioGeneralInformationCard.propTypes = {
  isAllowedToEdit: PropTypes.bool,
}
