import PropTypes from 'prop-types'
import { useMemo } from 'react'
import DecisionPaperTileHeader from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileHeader'
import DecisionPaperSources from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/sources/DecisionPaperSources'

const DecisionPaperAutomaticTileWithMetaDataHeader = ({
  tileId,
  tileCode,
  titleText,
  isExpanded,
  isPdfView,
  actions,
  isEditEnabled,
  allowCollapse = true,
  onCollapse,
  onExpand,
  currentVersion,
}) => {
  const headerActions = useMemo(
    () => (isPdfView || !isEditEnabled ? undefined : actions),
    [isEditEnabled, isPdfView, actions],
  )

  const sourceComponent = useMemo(
    () => <DecisionPaperSources tileId={tileId} tileCode={tileCode} isPdfView={isPdfView} />,
    [tileId, tileCode, isPdfView],
  )

  return (
    <DecisionPaperTileHeader
      titleText={titleText}
      isPdfView={isPdfView}
      isExpanded={isExpanded}
      source={sourceComponent}
      additionalActions={headerActions}
      onCollapse={onCollapse}
      onExpand={onExpand}
      allowCollapse={allowCollapse}
      currentVersion={currentVersion}
    />
  )
}

DecisionPaperAutomaticTileWithMetaDataHeader.propTypes = {
  tileId: PropTypes.string.isRequired,
  tileCode: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  actions: PropTypes.element,
  isEditEnabled: PropTypes.bool.isRequired,
  allowCollapse: PropTypes.bool,
  onCollapse: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  currentVersion: PropTypes.string,
}

export default DecisionPaperAutomaticTileWithMetaDataHeader
