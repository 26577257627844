import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import AnnualReviewDealOverviewBorrowerOverviewAddress from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/involved-parties/AnnualReviewDealOverviewBorrowerOverviewAddress'
import AnnualReviewDealOverviewBorrowerOverviewAppreciation from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/involved-parties/AnnualReviewDealOverviewBorrowerOverviewAppreciation'
import AnnualReviewDealOverviewBorrowerOverviewFinancialIndicators from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/involved-parties/AnnualReviewDealOverviewBorrowerOverviewFinancialIndicators'
import AnnualReviewDealOverviewBorrowerOverviewInformation from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/involved-parties/AnnualReviewDealOverviewBorrowerOverviewInformation'
import AnnualReviewDealOverviewBorrowerOverviewRatings from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/involved-parties/AnnualReviewDealOverviewBorrowerOverviewRatings'
import MultipleDealSelectionPdfViewWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/wrapper/MultipleDealSelectionPdfViewWrapper'

const AnnualReviewDealOverviewBorrowerOverviewTile = ({
  tileId,
  showConfirmationForLinks,
  isPdfView,
}) => {
  const contentCreator = useCallback(
    ({ selectedDealIndex }) => (
      <FlexBox direction={FlexBoxDirection.Column}>
        <AnnualReviewDealOverviewBorrowerOverviewInformation
          tileId={tileId}
          showConfirmationForLinks={showConfirmationForLinks}
          selectedDealIndex={selectedDealIndex}
        />
        <AnnualReviewDealOverviewBorrowerOverviewAddress
          tileId={tileId}
          selectedDealIndex={selectedDealIndex}
        />
        <AnnualReviewDealOverviewBorrowerOverviewRatings
          tileId={tileId}
          selectedDealIndex={selectedDealIndex}
        />
        <AnnualReviewDealOverviewBorrowerOverviewFinancialIndicators
          tileId={tileId}
          selectedDealIndex={selectedDealIndex}
        />
        <AnnualReviewDealOverviewBorrowerOverviewAppreciation
          tileId={tileId}
          selectedDealIndex={selectedDealIndex}
        />
      </FlexBox>
    ),
    [showConfirmationForLinks, tileId],
  )

  return (
    <MultipleDealSelectionPdfViewWrapper
      tileId={tileId}
      contentCreator={contentCreator}
      isPdfView={isPdfView}
    />
  )
}

AnnualReviewDealOverviewBorrowerOverviewTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default AnnualReviewDealOverviewBorrowerOverviewTile
