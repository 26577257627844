import { useCallback, useMemo } from 'react'
import { isNotFoundError } from 'api/requests'
import useStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useStaffMemberByObjectIdOrEmail'
import { useAdjustmentTypesConfig } from 'hooks/services/deals/deal-adjustment/useAdjustmentTypesConfig'
import { useAdjustments } from 'hooks/services/deals/deal-adjustment/useAdjustments'
import useDeal from 'hooks/services/deals/useDeal'
import { useDealHeaders } from 'hooks/services/deals/useDealHeaders'

export const useWorkingVersionDetails = ({ entityRef: { entityId: dealWVUuid } }) => {
  const {
    data: { dealId, name, createdAt, createdBy } = {},
    isError: isErrorDeal,
    isFetching: isFetchingDeal,
  } = useDeal(dealWVUuid)

  const {
    isFetching: isFetchingHeaders,
    isError: isErrorHeaders,
    data: { live: { name: liveVersionName } = {} } = {},
  } = useDealHeaders(dealId)

  const {
    data: { adjustments = [] } = {},
    isError: isErrorAdjustments,
    isFetching: isFetchingAdjustments,
  } = useAdjustments({ dealUuid: dealWVUuid, enabled: !!dealWVUuid })

  const {
    data: { adjustmentTypes = [] } = {},
    isFetching: isFetchingAdjustmentTypes,
    isError: isErrorAdjustmentTypes,
  } = useAdjustmentTypesConfig()

  const {
    data: staffMember,
    isFetching: isFetchingStaffMember,
    isError: isErrorStaffMember,
    error: staffMemberError,
  } = useStaffMemberByObjectIdOrEmail({ objectIdOrEmail: createdBy })
  const isStaffMemberCriticalResolutionError =
    createdBy !== undefined && isErrorStaffMember && !isNotFoundError(staffMemberError)

  const getAdjustmentTypeTranslation = useCallback(
    (type) =>
      adjustmentTypes.find((adjustmentType) => adjustmentType.code === type)?.displayName ?? type,
    [adjustmentTypes],
  )

  const getStaffMember = useCallback(
    () => staffMember?.fullName ?? createdBy,
    [staffMember, createdBy],
  )

  const path = useMemo(() => `/deals/${dealId}?working-version=true`, [dealId])

  const isLoading = useMemo(
    () =>
      isFetchingDeal ||
      isFetchingHeaders ||
      isFetchingAdjustments ||
      isFetchingAdjustmentTypes ||
      isFetchingStaffMember,
    [
      isFetchingAdjustmentTypes,
      isFetchingAdjustments,
      isFetchingDeal,
      isFetchingHeaders,
      isFetchingStaffMember,
    ],
  )

  const isError = useMemo(
    () =>
      isErrorDeal ||
      isErrorHeaders ||
      isErrorAdjustments ||
      isErrorAdjustmentTypes ||
      isStaffMemberCriticalResolutionError,
    [
      isErrorAdjustmentTypes,
      isErrorAdjustments,
      isErrorDeal,
      isErrorHeaders,
      isStaffMemberCriticalResolutionError,
    ],
  )

  return isLoading || isError
    ? { data: undefined, isLoading, isError }
    : {
        data: {
          sourceRender: {
            path,
          },
          dealId,
          live: {
            name: liveVersionName,
          },
          workingVersion: {
            name,
            createdAt,
            createdBy: getStaffMember(createdBy),
            isCreatedByError: isErrorStaffMember,
          },
          adjustments: {
            comment: adjustments[0]?.comment,
            types: adjustments[0]?.types?.map((type) => getAdjustmentTypeTranslation(type)),
          },
        },
        isError: false,
        isLoading: false,
      }
}
