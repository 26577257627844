import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import CheckSheetDecisionProcessOverviewTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/decision-overview/CheckSheetDecisionProcessOverviewTable'

const CheckSheetDecisionOverview = ({ tileId, showConfirmationForLinks }) => {
  const {
    data: { decisionStages },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  return (
    <CheckSheetDecisionProcessOverviewTable
      decisionStages={decisionStages}
      showConfirmationForLinks={showConfirmationForLinks}
    />
  )
}

CheckSheetDecisionOverview.propTypes = {
  tileId: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
}

export default CheckSheetDecisionOverview
