import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/RiskMonitoringDescriptionTile.module.css'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/card/EmptyCardContent'
import CollapsibleRichTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/rich-text/CollapsibleRichTextView'

export const MAX_COLLAPSED_COMMENT_LENGTH = 300

const RiskMonitoringDescriptionTile = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.risk-indicators.risk-indicators-description',
  })
  const { data } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const { latestDescription } = data

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.commentWrapper}>
      {latestDescription ? (
        <CollapsibleRichTextView
          text={latestDescription ?? ''}
          characterLimit={MAX_COLLAPSED_COMMENT_LENGTH}
        />
      ) : (
        <EmptyCardContent title={t('title')} subtitle={t('no-data')} />
      )}
    </FlexBox>
  )
}

RiskMonitoringDescriptionTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}
export default RiskMonitoringDescriptionTile
