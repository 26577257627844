import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useCashflowsExtensionFields = (options) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/configurations/cashflows/extension-fields`,
      useCache: true,
      translated: true,
      keys: ['deals', 'configurations', 'cashflows', 'extension-fields'],
      ...options,
    }),
  )
