import { FlexBoxAlignItems } from '@fioneer/ui5-webcomponents-react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useDealChangeOverviewTableColumnDefinitions = () => {
  const { t: t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.change-overview.table.columns',
  })

  const staticColumnDefinitions = useMemo(
    () =>
      [
        {
          title: t('changed-information'),
          columnKey: 'changedInformation',
          sortFieldName: 'entity_type',
          popinText: t('changed-information'),
          isSelectableForHiding: false,
        },
        {
          title: t('changed-page'),
          columnKey: 'pageKey',
          sortFieldName: 'page_key',
          minWidth: 1200,
          demandPopin: true,
          popinText: t('changed-page'),
        },
        {
          title: t('changed-card'),
          columnKey: 'cardKey',
          sortFieldName: 'card_key',
          minWidth: 1400,
          demandPopin: true,
          popinText: t('changed-card'),
        },
        {
          title: t('action'),
          columnKey: 'action',
          sortFieldName: 'action_type',
          minWidth: 1100,
          demandPopin: true,
          popinText: t('action'),
        },
        {
          title: t('changed-fields'),
          columnKey: 'changedFields',
          minWidth: 1000,
          demandPopin: true,
          isVisible: false,
          popinText: t('changed-fields'),
        },
        {
          title: t('changed-at'),
          columnKey: 'triggeredAt',
          sortFieldName: 'triggered_at',
          minWidth: 1000,
          demandPopin: true,
          popinText: t('changed-at'),
          alignment: FlexBoxAlignItems.End,
        },
        {
          title: t('changed-by'),
          columnKey: 'triggeredBy',
          sortFieldName: 'triggered_by',
          minWidth: 900,
          demandPopin: true,
          popinText: t('changed-by'),
        },
      ].map(
        ({
          isVisible = true,
          isSelectableForHiding = true,
          sortFieldName = false,
          ...otherColumnProperties
        }) => ({
          isVisible,
          isSelectableForHiding,
          sortFieldName,
          ...otherColumnProperties,
        }),
      ),
    [t],
  )

  const [tableColumns, setTableColumns] = useState()

  useEffect(() => {
    setTableColumns(staticColumnDefinitions)
  }, [staticColumnDefinitions])

  return [tableColumns, setTableColumns]
}

export default useDealChangeOverviewTableColumnDefinitions
