const isGroupCriticalityLowerThanIndicators = ({
  selectedCriticality,
  riskIndicators = [],
  riskIndicatorCriticalityTypeCodes = [],
}) => {
  // Type codes also need to be covered in between the static values defined in the FE.
  // So if a cell type code 70 is given, and the FE only has 60 and 80 defined, the lower local boundary of 60 is returned.
  // Therefore, the type code object are transformed into an array and sorted by code value.
  const typeCodeArray = riskIndicatorCriticalityTypeCodes?.toSorted(
    (a, b) => Number(a?.code) - Number(b?.code),
  )

  return riskIndicators?.some(
    ({ currentCriticality: { criticalityTypeCode: indicatorCriticalityTypeCode } = {} } = {}) => {
      // The type code that is the next highest to the current cell type code is selected,
      // where, in case of the current cell type code already being the largest type code defined, it is its own upper boundary.
      let upperTypeCodeBoundaryIndex = typeCodeArray.findIndex(
        (status) => Number(status?.code) > Number(indicatorCriticalityTypeCode),
      )
      if (upperTypeCodeBoundaryIndex === -1) {
        upperTypeCodeBoundaryIndex = typeCodeArray.length
      }
      // Either the type code from the BE matches the FE defined one exactly,
      // or the one directly before the upper local boundary is returned,
      // or the lowest boundary is returned, if the upper local boundary equals the lowest absolute boundary.
      const typeCode = (
        typeCodeArray.find((status) => status?.code === indicatorCriticalityTypeCode) ||
        typeCodeArray?.[upperTypeCodeBoundaryIndex - 1] ||
        typeCodeArray?.[0]
      )?.code

      return typeCode > selectedCriticality
    },
  )
}

export default isGroupCriticalityLowerThanIndicators
