import { FlexBox, FlexBoxDirection, Text } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import DecisionPaperLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/links/DecisionPaperLeaveConfirmationLink'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/details/shared/routes/deals/syndication/syndicationStructure/SyndicationStructureTableCell.module.css'
import {
  ROW_TYPE_COUNTERPARTY,
  ROW_TYPE_OWN_SHARE,
  ROW_TYPE_TRANCHE,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/details/shared/routes/deals/syndication/syndicationStructure/utils/constants'
import paths from 'routes/paths'

const PartyCell = ({
  cell,
  row,
  dealDisplayId,
  businessPartnerNamesById,
  showConfirmationForLinks,
}) => {
  const { isBold, value, trancheDisplayId, roles } = cell.value

  switch (row.original?.rowType) {
    case ROW_TYPE_COUNTERPARTY:
      return (
        <FlexBox direction={FlexBoxDirection.Column}>
          <DecisionPaperLeaveConfirmationLink
            name={businessPartnerNamesById[value.id]}
            link={`/${paths.businessPartners}/${value.id}`}
            showConfirmationForLinks={showConfirmationForLinks}
          />
          <Text wrapping={true} className={styles.textWithOverflowHandling}>
            {value.id}
          </Text>
        </FlexBox>
      )
    case ROW_TYPE_TRANCHE:
      return (
        <DecisionPaperLeaveConfirmationLink
          name={value}
          link={`/deals/${dealDisplayId}/financing/tranches/${trancheDisplayId}`}
          showConfirmationForLinks={showConfirmationForLinks}
        />
      )
    case ROW_TYPE_OWN_SHARE:
      return (
        <FlexBox direction={FlexBoxDirection.Column}>
          <Text
            wrapping={true}
            className={[styles.textBold, styles.textWithOverflowHandling].join(' ')}
          >
            {businessPartnerNamesById?.[value] ?? value}
          </Text>
          {!isEmpty(roles) && (
            <Text wrapping={true} className={styles.textWithOverflowHandling}>
              {`(${[...roles].sort((a, b) => String(a).localeCompare(String(b))).join(', ')})`}
            </Text>
          )}
        </FlexBox>
      )
  }

  return (
    <Text
      wrapping={false}
      className={[isBold ? styles.textBold : undefined, styles.textWithOverflowHandling].join(' ')}
    >
      {value}
    </Text>
  )
}

PartyCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.shape({
      isBold: PropTypes.bool,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          id: PropTypes.string,
        }),
      ]),
      trancheDisplayId: PropTypes.string,
      roles: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  row: PropTypes.shape({
    original: PropTypes.shape({
      rowType: PropTypes.string,
    }),
  }).isRequired,
  dealDisplayId: PropTypes.string,
  businessPartnerNamesById: PropTypes.objectOf(PropTypes.string),
  showConfirmationForLinks: PropTypes.bool.isRequired,
}

export default PartyCell
