import {
  Button,
  ButtonDesign,
  Dialog,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  MessageBoxTypes,
  Modals,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-process/setups/DecisionProcessSelectSetupDialog.module.css'
import DecisionProcessSelectSetupList from 'components/domains/business-events-and-tasks/decision-process/setups/DecisionProcessSelectSetupList'
import useCreateDecisionSetup from 'hooks/services/business-events-and-tasks/decision-process/setups/useCreateDecisionSetup'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionProcessSelectSetupDialog = ({ isOpen, setIsOpen, decisionSetupTemplates }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.select-setup.dialog',
  })
  const { t: tNoPrefix } = useTranslation('translation')

  const {
    event: { id: eventId },
  } = useContext(BusinessEventsAndTasksContext)
  const showMessageBox = Modals.useShowMessageBox()
  const showToast = Modals.useShowToast()
  const { mutate: createDecisionSetup } = useCreateDecisionSetup()

  const queryClient = useQueryClient()
  const [selectedSetupTemplateId, setSelectedSetupTemplateId] = useState(
    decisionSetupTemplates[0]?.id,
  )
  const selectButtonDisabled = useMemo(() => !selectedSetupTemplateId, [selectedSetupTemplateId])

  const handleCreateSetupSuccess = useCallback(() => {
    showToast({ children: t('success-notification') })
    queryClient.invalidateQueries(['events', eventId, 'decision-stages'])
    setIsOpen(false)
  }, [showToast, t, queryClient, eventId, setIsOpen])

  const handleCreateSetupError = useCallback(() => {
    showMessageBox({ type: MessageBoxTypes.Error, children: t('error.description') })
  }, [showMessageBox, t])

  const handleSelectButtonClicked = useCallback(() => {
    createDecisionSetup(
      { eventId, setupTemplateId: selectedSetupTemplateId },
      {
        onSuccess: handleCreateSetupSuccess,
        onError: handleCreateSetupError,
      },
    )
  }, [
    eventId,
    selectedSetupTemplateId,
    createDecisionSetup,
    handleCreateSetupSuccess,
    handleCreateSetupError,
  ])

  const handleCancelButtonClicked = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const handleDialogClosed = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const footer = useMemo(
    () => (
      <FlexBox
        className={styles.footerContainer}
        direction={FlexBoxDirection.Row}
        alignItems={FlexBoxAlignItems.Center}
        justifyContent={FlexBoxJustifyContent.End}
      >
        <Button
          design={ButtonDesign.Emphasized}
          onClick={handleSelectButtonClicked}
          disabled={selectButtonDisabled}
        >
          {tNoPrefix('buttons.select')}
        </Button>
        <Button
          className={styles.cancelButton}
          design={ButtonDesign.Transparent}
          onClick={handleCancelButtonClicked}
        >
          {tNoPrefix('buttons.cancel')}
        </Button>
      </FlexBox>
    ),
    [selectButtonDisabled, handleSelectButtonClicked, handleCancelButtonClicked, tNoPrefix],
  )

  return createPortal(
    <Dialog
      open={isOpen}
      headerText={t('header.text')}
      footer={footer}
      draggable
      resizable
      onAfterClose={handleDialogClosed}
    >
      <DecisionProcessSelectSetupList
        onSelectionChange={setSelectedSetupTemplateId}
        decisionSetupTemplates={decisionSetupTemplates}
        selectedSetupTemplateId={selectedSetupTemplateId}
      />
    </Dialog>,
    document.body,
  )
}

DecisionProcessSelectSetupDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  decisionSetupTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      stageTemplates: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string.isRequired,
          decisionType: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
}

export default DecisionProcessSelectSetupDialog
