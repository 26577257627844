import { useSearchParams } from 'react-router-dom'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * Requests all covenant monitoring items for a deal
 *
 * @param mainEntityId {string} ID of the main entity
 * @param mainEntityType {string} type of the main entity
 * @param options {object} request options
 */
const useDealCovenantMonitoringItems = (mainEntityType, mainEntityId, options = {}) => {
  const [queryParams] = useSearchParams()
  queryParams.append('main_entity_type', mainEntityType)
  queryParams.append('main_entity_id', mainEntityId)
  return useCamelizedResponse(
    useRequest({
      path: `/covenants/monitoring-information/items?${queryParams.toString()}`,
      translated: true,
      keys: ['covenants', 'monitoring', 'items', mainEntityType, mainEntityId],
      options: {
        enabled: !!mainEntityId && !!mainEntityType,
        retry: 1,
        ...options,
      },
    }),
  )
}

export default useDealCovenantMonitoringItems
