import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperTileListItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListItem'

const FpoToolTile = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'fpo-tool-tile',
  })

  const {
    data: { fpoTools },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const { fpoResult, fpoComment } = fpoTools

  const getDisplayedValue = (value) => (isEmpty(value) ? '-' : value)

  return (
    <>
      <DecisionPaperTileListItem
        label={t('fpo-result')}
        value={getDisplayedValue(fpoResult)}
        isFirstItem
      />
      <DecisionPaperTileListItem label={t('fpo-comment')} value={getDisplayedValue(fpoComment)} />
    </>
  )
}

FpoToolTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}
export default FpoToolTile
