import { t } from 'i18next'
import isNil from 'lodash.isnil'
import omit from 'lodash.omit'

const FEE_TYPE_CODE_AGENCY = 'AgencyFee'
const FEE_FREQUENCY_TYPE_ONCE = 'Once'

const mapOpsMemoCounterpartySyndicationSharesSkims = (syndicationShare, tranchesData) => {
  const {
    fees = [],
    pricing,
    interestConditions,
  } = tranchesData.find((tranche) => tranche?.trancheId === syndicationShare.trancheId) ?? {}

  const isSyndicationSkimNull = (skim) =>
    Object.values(omit(skim, ['skimTypeShortText', 'isSpacingForLineBreakNeeded'])).every((value) =>
      isNil(value),
    )

  const customerMargin = () => {
    const syndicationCustomerMargin = syndicationShare?.customerMargin?.rate ?? 0
    const customerMarginsArray = []

    interestConditions?.items.forEach((item) => {
      customerMarginsArray.push({
        skimTypeShortText: t('decision-paper.tiles.syndication-structure.customer-margin-label'),
        amountTerm: null,
        amountTermHeadquarter: null,
        rate: item?.customerMargin - syndicationCustomerMargin,
        frequency: null,
        paymentDate: item?.validFrom,
        isSpacingForLineBreakNeeded: false,
        skimId: null,
      })
    })

    return customerMarginsArray
  }

  const grossInterestMargin = () => ({
    skimTypeShortText: t('decision-paper.tiles.syndication-structure.gross-interest-margin-label'),
    amountTerm: null,
    amountTermHeadquarter: null,
    rate: pricing?.grossInterestMargin3mRef || pricing?.grossInterestMarginOn || undefined,
    frequency: null,
    paymentDate: null,
    isSpacingForLineBreakNeeded: true,
    skimId: null,
  })

  const fundingSpreadTranche = () => ({
    skimTypeShortText: t('decision-paper.tiles.syndication-structure.funding-spread-tranche-label'),
    amountTerm: null,
    amountTermHeadquarter: null,
    rate: pricing?.fundingSpreadTranche3mRef || pricing?.fundingSpreadTrancheOn || undefined,
    frequency: null,
    paymentDate: null,
    isSpacingForLineBreakNeeded: true,
    skimId: null,
  })

  const fundingSpreadCustomer = () => ({
    skimTypeShortText: t(
      'decision-paper.tiles.syndication-structure.funding-spread-customer-label',
    ),
    amountTerm: null,
    amountTermHeadquarter: null,
    rate: pricing?.fundingSpreadCustomer || undefined,
    frequency: null,
    paymentDate: null,
    isSpacingForLineBreakNeeded: false,
    skimId: null,
  })

  const fundingSpreadDrawdown = () => ({
    skimTypeShortText: t(
      'decision-paper.tiles.syndication-structure.funding-spread-drawdown-label',
    ),
    amountTerm: null,
    amountTermHeadquarter: null,
    rate: pricing?.fundingSpreadDrawdown3mRef || pricing?.fundingSpreadDrawdownOn || undefined,
    frequency: null,
    paymentDate: null,
    isSpacingForLineBreakNeeded: true,
    skimId: null,
  })

  const syndicationTrancheFees = () => {
    const { syndicationSkims: skims = [], amountShare: syndicationAmountShare } =
      syndicationShare ?? {}
    const calculatedSyndicationTrancheFees = []

    const sortedFees = [...fees]?.sort((a, b) => {
      const dateA = new Date(a?.firstPaymentDate)
      const dateB = new Date(b?.firstPaymentDate)
      return dateA - dateB
    })

    sortedFees?.forEach((fee) => {
      const feeSkim = skims?.find((skim) => skim?.skimId === fee?.id) ?? {}

      if (fee.feeTypeCode === FEE_TYPE_CODE_AGENCY || !feeSkim) {
        return
      }

      const {
        amountTerm: feeSkimAmount,
        amountTermHeadquarter: feeSkimAmountHeadquarter,
        rate: feeSkimRate = 0,
      } = feeSkim

      const syndicationTrancheFeeAmount =
        fee?.amountTotal?.amount * syndicationAmountShare - (feeSkimAmount?.amount ?? 0)
      const syndicationTrancheFeeAmountHeadquarter =
        fee?.amountTotalHeadquarter?.amount * syndicationAmountShare -
        (feeSkimAmountHeadquarter?.amount ?? 0)

      const showAmount =
        !!fee?.amountTotal?.amount && fee?.paymentFrequencyType === FEE_FREQUENCY_TYPE_ONCE
      const showRate = !!fee?.amountShare

      calculatedSyndicationTrancheFees.push({
        skimTypeShortText: fee.feeTypeShortText,
        amountTerm: showAmount
          ? {
              amount: syndicationTrancheFeeAmount,
              currency: fee?.amountTotal?.currency,
            }
          : undefined,
        amountTermHeadquarter: showAmount
          ? {
              amount: syndicationTrancheFeeAmountHeadquarter,
              currency: fee?.amountTotalHeadquarter?.currency,
            }
          : undefined,
        rate: showRate ? fee?.amountShare - feeSkimRate : undefined,
        frequency: fee.paymentFrequencyTypeShortText,
        paymentDate: fee?.firstPaymentDate,
        isSpacingForLineBreakNeeded: false,
        skimId: fee?.id,
      })
    })

    return calculatedSyndicationTrancheFees
  }

  const syndicationSkims = [
    ...customerMargin(),
    grossInterestMargin(),
    fundingSpreadTranche(),
    fundingSpreadCustomer(),
    fundingSpreadDrawdown(),
    ...syndicationTrancheFees(),
  ].filter((skim) => !isSyndicationSkimNull(skim))

  const isSpacingForLineBreakNeeded = syndicationSkims.some(
    (skim) => skim.isSpacingForLineBreakNeeded,
  )

  return { syndicationSkims, isSpacingForLineBreakNeeded }
}

export default mapOpsMemoCounterpartySyndicationSharesSkims
