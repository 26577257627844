import { MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingButton from 'components/ui/button/LoadingButton'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useCreateMonitoringEvent from 'hooks/services/risk-monitoring/useCreateMonitoringEvent'

const propTypes = {
  businessPartnerId: PropTypes.string,
  riskIndicatorGroupId: PropTypes.string,
  disabled: PropTypes.bool,
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const CreateMonitoringEventAction = ({ businessPartnerId, riskIndicatorGroupId, disabled }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner.risk-monitoring' })
  const { t: tNoPrefix } = useTranslation()
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()
  const createMonitoringEvent = useCreateMonitoringEvent({
    onSuccess: () => {
      queryClient.invalidateQueries([
        'risk-monitoring',
        'business-partners',
        businessPartnerId,
        'risk-indicator-groups',
      ])
      showToast(
        {
          children: t('create-monitoring-event.success-toast.message'),
        },
        document.body,
      )
    },
    onError: (error) => {
      showMessageBox({ type: MessageBoxTypes.Error, children: error })
    },
  })

  const handleConfirm = () => {
    setIsConfirmationDialogOpen(false)
    createMonitoringEvent.mutate({ businessPartnerId, riskIndicatorGroupId })
  }

  const handleCancel = () => {
    setIsConfirmationDialogOpen(false)
  }

  const handleClick = () => {
    setIsConfirmationDialogOpen(true)
  }

  return (
    <LoadingButton
      isLoading={isNil(businessPartnerId)}
      isError={false}
      onClick={handleClick}
      disabled={disabled}
      renderContent={() => (
        <>
          {t('create-monitoring-event')}
          <ConfirmationDialog
            isOpen={isConfirmationDialogOpen}
            handleConfirmation={handleConfirm}
            handleCancel={handleCancel}
            confirmButtonText={tNoPrefix('buttons.ok')}
            confirmationMessage={t('create-monitoring-event.confirmation-dialog.message')}
          />
        </>
      )}
    />
  )
}

CreateMonitoringEventAction.propTypes = propTypes

export default CreateMonitoringEventAction
