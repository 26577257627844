import { useContext } from 'react'
import ArrearsPastApprovalsTable from 'components/domains/business-partners/tile/arrears/past-approvals/ArrearsPastApprovalsTable'
import styles from 'components/domains/business-partners/tile/arrears/past-approvals/ArrearsPastApprovalsTable.module.css'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInTable } from 'components/ui/errors/ErrorDataUnavailableInTable'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { BusinessPartnerArrearsContext } from 'routes/business-partners/arrears/BusinessPartnerArrearsContext'

const ArrearsPastApprovalsCard = () => {
  const { arrearApprovalEvents, arrearApprovals, isError, isLoading } = useContext(
    BusinessPartnerArrearsContext,
  )
  const events = arrearApprovalEvents?.filter(({ status }) => status === 'COMPLETED')

  const renderContent = () => (
    <ArrearsPastApprovalsTable events={events} arrearApprovals={arrearApprovals} />
  )
  return (
    <Card>
      <div className={styles.tableWrapper}>
        <RequestStateResolver
          isLoading={isLoading}
          isError={isError}
          errorToDisplay={<ErrorDataUnavailableInTable />}
          renderContent={renderContent}
          center
        />
      </div>
    </Card>
  )
}

export default ArrearsPastApprovalsCard
