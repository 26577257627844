import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useDeleteRentRollWorkingVersion = (options) => {
  const { delete: deleteRentRollWorkingVersion } = useAccessTokenRequest()

  return useMutation(
    async ({ propertyUuids }) =>
      await deleteRentRollWorkingVersion({
        path: `/properties/rent-roll/working-version`,
        body: {
          property_uuids: [...propertyUuids],
        },
      }),
    options,
  )
}
