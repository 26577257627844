import { TableCell, TableRow } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PropertyLink from 'components/domains/business-events-and-tasks/PropertyLink'
import {
  AddButtonCell,
  RentRollLinkCell,
  SegmentCheckboxCell,
  ValuationResultsLinkCell,
} from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsGroupCells'
import styles from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsTable.module.css'
import {
  isDeletable,
  findSegmentKpisByPropertyUuid,
} from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsUtils'

const PropertyRentRollSegmentsTableGroupRow = ({
  properties,
  groupedSegments,
  selectedSegments,
  multiPropertySegmentKpis,
  propertyUuid,
  isInEdit,
  onAdd,
  onSelectAllByProperty,
  onUnselectAllByProperty,
}) => {
  // Hooks
  const { t: tSegments } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.segments',
  })

  // Functions
  const findPropertyIdByPropertyUuid = (uuid) =>
    properties.find((property) => property.uuid === uuid)?.id

  const isAllSelectedByPropertyUuid = (uuid) => {
    const segmentsByProperty = groupedSegments.filter(
      (segment) =>
        segment.property_uuid === uuid &&
        isDeletable(
          segment?.uuid,
          findSegmentKpisByPropertyUuid(segment.property_uuid, multiPropertySegmentKpis),
        ),
    )
    return (
      segmentsByProperty.length > 0 &&
      segmentsByProperty.every((segment) => selectedSegments.includes(segment.uuid))
    )
  }

  const isPropertyCheckBoxSelectable = (uuid) => {
    const segmentsByProperty = groupedSegments.filter((segment) => segment.property_uuid === uuid)
    return segmentsByProperty.some((segment) =>
      isDeletable(
        segment?.uuid,
        findSegmentKpisByPropertyUuid(segment.property_uuid, multiPropertySegmentKpis),
      ),
    )
  }

  // Handlers
  const handleAllSelectChangeByPropertyUuid = (e, uuid) => {
    if (e.target.checked) {
      onSelectAllByProperty(uuid)
    } else {
      onUnselectAllByProperty(uuid)
    }
  }

  return (
    <TableRow className={styles.tableGroupRow} key={propertyUuid}>
      <TableCell>
        {isInEdit && (
          <SegmentCheckboxCell
            selectable={isPropertyCheckBoxSelectable(propertyUuid)}
            checked={isAllSelectedByPropertyUuid(propertyUuid)}
            onCheck={(e) => handleAllSelectChangeByPropertyUuid(e, propertyUuid)}
          />
        )}
        {!isInEdit && <PropertyLink propertyUuid={propertyUuid} customRender={'SINGLE_LINE'} />}
      </TableCell>
      <TableCell>
        {isInEdit && <PropertyLink propertyUuid={propertyUuid} customRender={'SINGLE_LINE'} />}
        {!isInEdit && (
          <ValuationResultsLinkCell
            tSegments={tSegments}
            propertyId={findPropertyIdByPropertyUuid(propertyUuid)}
          />
        )}
      </TableCell>
      <TableCell>
        {isInEdit && (
          <ValuationResultsLinkCell
            tSegments={tSegments}
            propertyId={findPropertyIdByPropertyUuid(propertyUuid)}
          />
        )}
        {!isInEdit && (
          <RentRollLinkCell
            tSegments={tSegments}
            propertyId={findPropertyIdByPropertyUuid(propertyUuid)}
          />
        )}
      </TableCell>
      <TableCell>
        {isInEdit && (
          <RentRollLinkCell
            tSegments={tSegments}
            propertyId={findPropertyIdByPropertyUuid(propertyUuid)}
          />
        )}
      </TableCell>
      <TableCell />
      <TableCell>
        {isInEdit && <AddButtonCell propertyUuid={propertyUuid} onAdd={onAdd} />}
      </TableCell>
    </TableRow>
  )
}
PropertyRentRollSegmentsTableGroupRow.propTypes = {
  properties: PropTypes.array.isRequired,
  propertyUuid: PropTypes.string.isRequired,
  groupedSegments: PropTypes.array,
  selectedSegments: PropTypes.array,
  multiPropertySegmentKpis: PropTypes.any,
  isInEdit: PropTypes.bool.isRequired,
  onAdd: PropTypes.func,
  onSelectAllByProperty: PropTypes.func.isRequired,
  onUnselectAllByProperty: PropTypes.func.isRequired,
}
export default PropertyRentRollSegmentsTableGroupRow
