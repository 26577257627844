import { ListItemType, StandardListItem, SuggestionItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  DEFAULT_EVENT_INPUT_MAX_SUGGESTIONS,
  DEFAULT_EVENT_INPUT_SEARCH_MIN_LENGTH,
} from 'components/domains/business-events-and-tasks/events/input/constants'
import { useEvents } from 'hooks/services/business-events-and-tasks/events/useEvents'

const propTypes = {
  loadingText: PropTypes.string,
  errorText: PropTypes.string,
  emptyResultsText: PropTypes.string,
  typeMoreText: PropTypes.string,
  currentSearchValue: PropTypes.string.isRequired,
  maxSuggestions: PropTypes.number,
  searchMinLength: PropTypes.number,
  /** These values pre-apply filters to every event request and cannot be disabled by the user */
  preFilterValues: PropTypes.shape({
    searchValue: PropTypes.string,
    assigneeId: PropTypes.string,
    currentDueDateRange: PropTypes.string,
    originalDueDateRange: PropTypes.string,
    status: PropTypes.arrayOf(PropTypes.string),
    entityType: PropTypes.string,
    entityId: PropTypes.string,
  }),
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const EventSearchSuggestions = ({
  loadingText,
  errorText,
  emptyResultsText,
  typeMoreText,
  currentSearchValue,
  preFilterValues,
  maxSuggestions = DEFAULT_EVENT_INPUT_MAX_SUGGESTIONS,
  searchMinLength = DEFAULT_EVENT_INPUT_SEARCH_MIN_LENGTH,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.events.input.search',
  })

  const {
    isLoading,
    isError,
    data: { events: eventSuggestions = [] } = {},
  } = useEvents(
    {},
    { ...preFilterValues, searchValue: currentSearchValue },
    { limit: maxSuggestions },
  )

  if (isLoading) {
    return (
      <SuggestionItem
        key="suggestion-loading"
        text={loadingText || t('loading')}
        type={ListItemType.Inactive}
      />
    )
  }
  if (isError) {
    return (
      <SuggestionItem
        key="suggestion-error"
        text={errorText || t('error')}
        type={ListItemType.Inactive}
      />
    )
  }
  if (eventSuggestions.length === 0) {
    return (
      <SuggestionItem
        key="suggestion-empty"
        text={emptyResultsText || t('empty')}
        type={ListItemType.Inactive}
      />
    )
  }
  if (!currentSearchValue || currentSearchValue.length < searchMinLength) {
    return (
      <StandardListItem type={ListItemType.Inactive}>
        {typeMoreText || t('type-more', { minLength: searchMinLength })}
      </StandardListItem>
    )
  }

  return eventSuggestions?.map(({ id, displayId, info }) => (
    <SuggestionItem
      key={`suggestion-${id}`}
      text={`${info?.name} (${displayId})`}
      data-id={id}
      data-display-id={displayId}
      data-name={info?.name}
    />
  ))
}

EventSearchSuggestions.propTypes = propTypes

export default EventSearchSuggestions
