import {
  AnalyticalTable,
  AnalyticalTableScaleWidthMode,
  AnalyticalTableSelectionBehavior,
  AnalyticalTableSelectionMode,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/deal-uses-and-sources/DealSourcesAndUsesTiles.module.css'
import useDealSourcesColumns from 'hooks/services/business-events-and-tasks/decision-papers/tiles/deal-uses-and-sources/useDealSourcesColumns'

const emptyRowElement = { isEmptyCell: true }

const singleCurrencyRowHeight = 52
const multipleCurrencyRowHeight = 52

const calculateRowHeight = ({
  original,
  headquarter,
  fillRowsDataset,
  areAllCurrenciesTheSame,
}) => {
  if (areAllCurrenciesTheSame) {
    return singleCurrencyRowHeight
  }
  if (
    fillRowsDataset &&
    fillRowsDataset.original &&
    fillRowsDataset.headquarter &&
    fillRowsDataset.original.currency !== fillRowsDataset.headquarter.currency
  ) {
    return multipleCurrencyRowHeight
  }
  if (!original && !headquarter) {
    return singleCurrencyRowHeight
  }
  return original?.currency === headquarter?.currency
    ? singleCurrencyRowHeight
    : multipleCurrencyRowHeight
}

const fillDataWithRowHeight = ({ data, dataToFillRows, areAllCurrenciesTheSame }) =>
  [...data].map((currentValue, index) => {
    const rowHeight = calculateRowHeight({
      original: currentValue.original,
      headquarter: currentValue.headquarter,
      fillRowsDataset: dataToFillRows[index],
      areAllCurrenciesTheSame,
    })
    return { ...currentValue, rowHeight }
  })

const DealSourcesAndUsesTiles = ({ data, dataToFillRows, areAllCurrenciesTheSame }) => {
  const { columns } = useDealSourcesColumns()

  const filledData = useMemo(() => {
    if (data.length >= dataToFillRows.length) {
      return data
    }
    const internalFilledData = [...data]
    const filledRows = Array.from(
      { length: dataToFillRows.length - internalFilledData.length },
      () => emptyRowElement,
    )
    return [...data.slice(0, -1), ...filledRows, ...data.slice(-1)]
  }, [data, dataToFillRows])

  const dataWithRowHeight = useMemo(
    () => fillDataWithRowHeight({ data: filledData, dataToFillRows, areAllCurrenciesTheSame }),
    [filledData, dataToFillRows, areAllCurrenciesTheSame],
  )

  const reactTableOptions = useMemo(
    () => ({
      areAllCurrenciesTheSame,
    }),
    [areAllCurrenciesTheSame],
  )

  return (
    <div className={styles.tableWrapper}>
      <AnalyticalTable
        className={styles.table}
        columns={columns}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Smart}
        selectionBehavior={AnalyticalTableSelectionBehavior.RowOnly}
        selectionMode={AnalyticalTableSelectionMode.None}
        sortable={false}
        filterable={false}
        groupable={false}
        infiniteScroll={false}
        isTreeTable={false}
        data={dataWithRowHeight}
        visibleRows={dataWithRowHeight.length}
        minRows={1}
        rowHeight={'individual'}
        headerRowHeight={32}
        reactTableOptions={reactTableOptions}
      />
    </div>
  )
}

DealSourcesAndUsesTiles.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataToFillRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  areAllCurrenciesTheSame: PropTypes.bool.isRequired,
}

export default DealSourcesAndUsesTiles
