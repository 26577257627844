import { useTranslation } from 'react-i18next'
import PropertyRentRollComparisonDeviation from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonDeviation'
import { COMPARABLE_STATUS } from 'components/domains/properties/rent-roll/comparison/constants'
import LoadingContent from 'components/ui/content/LoadingContent'

const usePropertyRentRollComparisonMetric = ({
  isLoading,
  isError,
  formatter,
  primaryValue,
  comparisonValue,
  unit,
}) => {
  const { t: tComparison } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.comparison',
  })

  return {
    label: tComparison('delta'),
    value: (
      <LoadingContent
        contentKey={`property-rent-roll-comparison-metric`}
        isLoading={isLoading}
        isError={isError}
      >
        <PropertyRentRollComparisonDeviation
          comparableStatus={COMPARABLE_STATUS.COMPARABLE}
          primaryValue={primaryValue}
          comparisonValue={comparisonValue}
          label={''}
          formatter={formatter}
          unit={unit}
          showStandardValue={false}
        />
      </LoadingContent>
    ),
  }
}

export default usePropertyRentRollComparisonMetric
