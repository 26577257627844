import {
  Icon,
  Input,
  SuggestionGroupItem,
  SuggestionItem,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import '@ui5/webcomponents-icons/dist/AllIcons.js'
import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { sapBusinessPartnerRoles } from 'api/property/sapBusinessPartnerRoles'
import BusinessPartnerCreateDialog from 'components/domains/business-partners/BusinessPartnerCreateDialog'
import styles from 'components/domains/properties/rent-roll/working-version/PropertyRentalUnitBusinessPartnerInput.module.css'
import { ValueStateMessage } from 'components/domains/properties/rent-roll/working-version/ValueStateMessage'
import { openInputPicker } from 'components/domains/properties/rent-roll/working-version/openInputPicker'
import RentRollBusinessPartnerSearchDialog from 'components/domains/rentroll/RentRollBusinessPartnerSearchDialog'
import useBusinessPartnerCreateDialogOptions from 'components/domains/rentroll/useBusinessPartnerCreateDialogOptions'
import { useMatchingBusinessPartnersByNameOrId } from 'hooks/services/business-partners/searchBusinessPartners'
import { useSapBusinessPartnerRoles } from 'hooks/services/properties/useSapBusinessPartnerRoles'

const ValueHelpIcon = ({ id, handleOnClick, ...otherIconProperties }) => (
  <Icon id={id} name="value-help" onClick={handleOnClick} {...otherIconProperties} />
)

ValueHelpIcon.propTypes = {
  id: PropTypes.string,
  handleOnClick: PropTypes.func,
}

const createNewTenantId = 'createNewTenant'
const initialExcelValueId = 'initialExcelValueId'

export const getRolesForType = ({ type, availableRoles }) => {
  switch (type) {
    case 'brand-franchise-provider':
      return availableRoles
        .filter(({ role }) => role === sapBusinessPartnerRoles.brandingFranchiseProviderRole)
        .map(({ businessPartnerRoleCode }) => businessPartnerRoleCode)

    default:
      return []
  }
}

const getSuggestionItemTranslationKeyForType = (type) => {
  switch (type) {
    case 'brand-franchise-provider':
      return 'create-new-brand-franchise-provider.label'

    case 'tenant':
      return 'create-new-tenant.label'

    default:
      return ''
  }
}

const initialSorting = { columnKey: 'id', orderBy: 'asc' }

const PropertyRentalUnitBusinessPartnerInput = forwardRef(
  (
    {
      type,
      onKeyDown = () => {},
      onChange,
      value,
      valueState,
      valueStateMessage,
      className,
      originalExcelValue,
    },
    externalRef,
  ) => {
    const originalExcelDisplayValue = useMemo(() => originalExcelValue, [originalExcelValue])

    const inputRef = useRef()
    useImperativeHandle(externalRef, () => inputRef?.current)
    const { data: { configSapBusinessPartnerRoles = [] } = {} } = useSapBusinessPartnerRoles()
    const preselectedRoles = getRolesForType({
      type,
      availableRoles: configSapBusinessPartnerRoles,
    })

    const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false)
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
    const createDialogOptions = useBusinessPartnerCreateDialogOptions(type)

    const businessPartnerName = value?.name ?? ''

    const { t } = useTranslation('translation', {
      keyPrefix: 'pages.property.rent-roll.working-version',
    })
    const { t: tRentRollTable } = useTranslation('translation', {
      keyPrefix: 'pages.property.rent-roll.table',
    })

    const translateSortSettingToSortParameter = (sortSetting) =>
      `${sortSetting?.orderBy === 'asc' ? '+' : '-'}${sortSetting?.columnKey}`

    // suppressing loading / error state since the auto-selecting is a nice-to-have
    const { data: { businessPartners = [] } = {} } = useMatchingBusinessPartnersByNameOrId({
      searchKey: businessPartnerName,
      minLength: 3,
      roles: preselectedRoles,
      excludeInactive: true,
      sort: translateSortSettingToSortParameter(initialSorting),
    })

    // Different handling than after creating via create-dialog because of CWP-5761
    // The tenant id needs to be reset, when there was a previous tenant selection (via search dialog) and the tenant name is deleted afterwards.
    const handleSearchDialogChange = ({ name, id, roles, status, orgName1 }) => {
      onChange({
        name: name ?? '',
        id: id ?? '',
        roles: roles ?? [],
        status: status ?? '',
        orgName1: orgName1 ?? '',
      })
    }

    const handleOnInput = (e) => onChange({ name: e.target.value, id: '', roles: [] })

    const handleSelectedSuggestion = (event) => {
      const { id, name } = event.detail.item.dataset
      if (id === createNewTenantId) {
        setIsCreateDialogOpen(true)
      } else if (id === initialExcelValueId) {
        onChange({ name })
        // Adds the action to the end of the React render queue.
        // As a result, the picker is opened after all previous renderings have been done
        // and no error is thrown.
        setTimeout(() => openInputPicker(inputRef), 0)
      } else {
        const { roles, status } = businessPartners.find((bp) => bp.id === id) ?? {}
        onChange({ id, name, roles, status })
      }
    }

    const handleSearchDialogClose = () => setIsSearchDialogOpen(false)

    const handleFocus = (e) => {
      e.stopPropagation()
      if (!businessPartnerName?.length) return
      openInputPicker(inputRef)
    }

    const initialExcelValueSuggestions = useMemo(
      () =>
        originalExcelDisplayValue
          ? [
              <SuggestionGroupItem text={tRentRollTable('source-value.label')} key={'grouped'} />,
              <SuggestionItem
                key={initialExcelValueId}
                data-id={initialExcelValueId}
                data-name={originalExcelDisplayValue}
                text={originalExcelDisplayValue}
                style={{ backgroundColor: 'red' }}
              />,
            ]
          : [],
      [originalExcelDisplayValue, tRentRollTable],
    )

    const optionGroupItem = useMemo(
      () =>
        originalExcelDisplayValue && !isEmpty(businessPartners) ? (
          <SuggestionGroupItem text={tRentRollTable('options.label')} key={'options'} />
        ) : null,
      [businessPartners, originalExcelDisplayValue, tRentRollTable],
    )
    const suggestions = useMemo(
      () =>
        [
          ...initialExcelValueSuggestions,
          optionGroupItem,
          ...businessPartners.map(({ id, name }) => (
            <SuggestionItem
              key={id}
              text={name}
              additionalText={id}
              data-id={id}
              data-name={name}
            />
          )),
        ].filter(Boolean),
      [businessPartners, initialExcelValueSuggestions, optionGroupItem],
    )
    const suggestionItemText = t(getSuggestionItemTranslationKeyForType(type))

    return (
      <>
        <Input
          ref={inputRef}
          noTypeahead
          showSuggestions
          onSuggestionItemSelect={handleSelectedSuggestion}
          className={[styles.input, className ?? ''].join(' ')}
          value={value?.name ?? ''}
          valueState={valueState}
          valueStateMessage={<ValueStateMessage name={valueStateMessage} />}
          onKeyDown={onKeyDown}
          onInput={handleOnInput}
          onFocus={handleFocus}
          icon={
            <ValueHelpIcon
              className={styles.actionIcon}
              handleOnClick={() => setIsSearchDialogOpen(true)}
            />
          }
        >
          {isEmpty(suggestions) && (
            <SuggestionItem key="no-data" text={tRentRollTable('businesspartner.search.no-data')} />
          )}
          {suggestions && [
            ...suggestions,
            <SuggestionItem
              key={createNewTenantId}
              data-id={createNewTenantId}
              additionalText={suggestionItemText}
              additionalTextState={ValueState.Information}
            />,
          ]}
        </Input>
        {isSearchDialogOpen &&
          createPortal(
            <RentRollBusinessPartnerSearchDialog
              open={isSearchDialogOpen}
              initialSearch={businessPartnerName}
              onClose={handleSearchDialogClose}
              initialRoles={preselectedRoles}
              initialExcludeInactive={true}
              type={type}
              onChange={handleSearchDialogChange}
            />,
            document.body,
          )}
        {isCreateDialogOpen &&
          createPortal(
            <BusinessPartnerCreateDialog
              {...createDialogOptions}
              open={isCreateDialogOpen}
              onClose={() => setIsCreateDialogOpen(false)}
              onAfterCreate={onChange}
            />,
            document.body,
          )}
      </>
    )
  },
)

PropertyRentalUnitBusinessPartnerInput.displayName = 'PropertyRentalUnitBusinessPartnerInput'

PropertyRentalUnitBusinessPartnerInput.propTypes = {
  type: PropTypes.oneOf(['tenant', 'brand-franchise-provider']).isRequired,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  valueState: PropTypes.string,
  valueStateMessage: PropTypes.string,
  className: PropTypes.string,
  originalExcelValue: PropTypes.string,
}

export default PropertyRentalUnitBusinessPartnerInput
