import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateDecisionStageVoter = () => {
  const { post } = useAccessTokenRequest()

  return useMutation(({ eventId, decisionStageId, voterId, voteTypeCode }) =>
    post({
      path: `/events/${eventId}/decision-stages/${decisionStageId}/voters`,
      body: {
        user_id: voterId,
        vote_type: {
          code: voteTypeCode,
        },
      },
    }),
  )
}

export default useCreateDecisionStageVoter
