import PropTypes from 'prop-types'
import { useAddressFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-area/shared/useI18n'

/**
 * Formats the address depending on the language used
 *
 * @param {string} country
 * @param {string} street
 * @param {string} houseNumber
 * @param {string} zipCode
 * @param {string} city
 * @param {string} state
 *
 */
const FormattedAddress = ({ country, street, houseNumber, zipCode, city, state }) => {
  const parseAddress = useAddressFormatter()

  const { firstLine, secondLine, thirdLine, fourthLine } = parseAddress({
    country,
    street,
    houseNumber,
    zipCode,
    city,
    state,
  })

  return (
    <>
      <div>{firstLine}</div>
      <div>{secondLine}</div>
      <div>{thirdLine}</div>
      <div>{fourthLine}</div>
    </>
  )
}

FormattedAddress.propTypes = {
  country: PropTypes.string,
  street: PropTypes.string,
  houseNumber: PropTypes.string,
  zipCode: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
}

export default FormattedAddress
