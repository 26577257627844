import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useEventsMiniByIdList = ({ eventIds = [], options = {} }) => {
  const results = useRequestsMemo(
    useRequests({
      requests: eventIds.map((eventId) => ({
        path: `/events/${eventId}/mini`,
        keys: ['events', eventId, 'mini'],
      })),
      useCache: true,
      options: {
        enabled: eventIds.length > 0,
        ...options,
      },
    }),
  )

  return useMemo(
    () => ({
      isLoading: results?.some(({ isLoading }) => isLoading),
      isError: results?.some(({ isError }) => isError),
      isFetching: results?.some(({ isFetching }) => isFetching),
      data: camelize(results?.map(({ data } = {}, index) => ({ id: eventIds[index], ...data }))),
    }),
    [eventIds, results],
  )
}

export default useEventsMiniByIdList
