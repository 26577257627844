import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultipleGetPricing = ({ dealUuids, options = {} }) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: dealUuids.map((dealUuid) => ({
        path: `/deals/${dealUuid}/pricing`,
        keys: ['deals', dealUuid, 'pricing'],
      })),
      translated: true,
      useCache: true,
      options: { enabled: !isEmpty(dealUuids), ...options },
    }),
  )

  return useMemo(
    () =>
      responses.map((response, index) => ({
        ...response,
        data: {
          dealUuid: dealUuids[index],
          // camelizing keys which contains numbers could cause potential formatting errors
          // e.g. string_value_2 -> stringValue2 -> string_value_2
          // since pricing meta property contains custom fields where we do not have control over key names
          // camelizing the response could potentially cause errors
          pricing: response.data,
        },
      })),
    [dealUuids, responses],
  )
}

export default useMultipleGetPricing
