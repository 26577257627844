import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ReferenceEntitiesView, { entitiesQuantity } from 'components/ui/data/ReferenceEntitiesView'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import paths from 'routes/paths'

const CovenantCheckSelectionHelperCovenantsColumn = ({
  dealUuidToIdMap,
  covenants,
  isLoading,
  isError,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.covenant-check-selection.table',
  })
  const covenantsReferenceEntitiesProps = useMemo(() => {
    if (isNil(covenants)) {
      return null
    }
    if (covenants.length === 1) {
      return {
        quantity: entitiesQuantity.single,
        name: covenants[0].name,
        link: `/${paths.deals}/${dealUuidToIdMap[covenants[0].mainEntity.id]}/${
          dealDetailPaths.covenants
        }/${covenants[0].covenantUuid}`,
      }
    }
    return {
      quantity: entitiesQuantity.multiple,
      listItems: covenants.map((covenant) => ({
        id: covenant.covenantUuid,
        name: covenant.name,
        individualLinkBasePath: `/${paths.deals}/${dealUuidToIdMap[covenant.mainEntity.id]}/${
          dealDetailPaths.covenants
        }`,
      })),
      linkBasePath: '',
      linkText: t('link.covenants'),
      disableLink: false,
    }
  }, [covenants, dealUuidToIdMap, t])
  const renderContent = useCallback(
    () => <ReferenceEntitiesView {...covenantsReferenceEntitiesProps} />,
    [covenantsReferenceEntitiesProps],
  )
  if (covenants?.length === 0) {
    return
  }
  return (
    <RequestStateResolver
      renderContent={renderContent}
      isLoading={isLoading}
      isError={isError}
      errorToDisplay={<ErrorDataUnavailableInCell />}
    />
  )
}

CovenantCheckSelectionHelperCovenantsColumn.propTypes = {
  dealUuidToIdMap: PropTypes.objectOf(PropTypes.string).isRequired,
  covenants: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      covenantUuid: PropTypes.string.isRequired,
      mainEntity: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
}

export default CovenantCheckSelectionHelperCovenantsColumn
