import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CovenantPeriodValues } from 'components/domains/deals/covenants/limit-cards/model/CovenantPeriodValues'

const EMPTY = ''

const CovenantPeriodSelect = ({ value, onChange, disabled, ...props }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.limit-card',
  })

  const isOptionSelected = (option) => option === value

  const onSelectionChange = (event) => onChange?.(event.detail.selectedOption.value)

  return (
    <Select onChange={onSelectionChange} id="period-select" disabled={disabled} {...props}>
      <Option value={EMPTY} selected={isOptionSelected(EMPTY)}>
        {EMPTY}
      </Option>
      <Option
        value={CovenantPeriodValues.Year}
        selected={isOptionSelected(CovenantPeriodValues.Year)}
      >
        {t('year')}
      </Option>
      <Option
        value={CovenantPeriodValues.Quarter}
        selected={isOptionSelected(CovenantPeriodValues.Quarter)}
      >
        {t('quarter')}
      </Option>
      <Option
        value={CovenantPeriodValues.Month}
        selected={isOptionSelected(CovenantPeriodValues.Month)}
      >
        {t('month')}
      </Option>
    </Select>
  )
}

CovenantPeriodSelect.propTypes = {
  value: PropTypes.oneOf([...Object.values(CovenantPeriodValues), PropTypes.oneOf([EMPTY])]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export default CovenantPeriodSelect
