import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import LimitBaseTable from 'components/domains/deals/covenants/limit-cards/LimitBaseTable'
import styles from 'components/domains/deals/covenants/limit-cards/display-tables/DisplayTableStyles.module.css'
import { limitShape } from 'components/domains/deals/covenants/limit-cards/display-tables/FinancialLimitDisplayTable'
import useFormatRelativeLimit from 'components/domains/deals/covenants/limit-cards/useFormatRelativeLimit'
import useNonFinancialColumns from 'components/domains/deals/covenants/limit-cards/useNonFinancialColumns'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const NonFinancialLimitDisplayTable = ({
  absoluteLimits = [],
  relativeLimits = [],
  actions = [],
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.limit-card',
  })
  const { format: formatDate } = useShortDateFormatter()
  const { format: formatRelativeLimit } = useFormatRelativeLimit()

  const isLimitAbsolute = absoluteLimits?.length > 0
  const limits = isLimitAbsolute ? absoluteLimits : relativeLimits

  const { columnDefinitions } = useNonFinancialColumns({ isEditMode: false })

  const tableData = limits?.map((limit) => ({
    rowKey: isLimitAbsolute ? limit.clvaId : limit.clvrId,
    covenantHoliday: {
      content: limit.isCovenantHoliday && t('covenant-holiday'),
      props: limit.isCovenantHoliday ? { className: styles.borderLeft } : undefined,
    },
    validFrom: {
      content: isLimitAbsolute
        ? formatDate(limit.validFrom)
        : formatRelativeLimit(limit.period, limit.validFrom),
    },
    validTo: {
      content: isLimitAbsolute
        ? formatDate(limit.validTo)
        : formatRelativeLimit(limit.period, limit.validTo),
    },
  }))

  return (
    <LimitBaseTable
      columnDefinitions={columnDefinitions}
      tableData={tableData}
      actions={actions}
      title={t('limits')}
    />
  )
}

NonFinancialLimitDisplayTable.propTypes = {
  absoluteLimits: PropTypes.arrayOf(limitShape),
  relativeLimits: PropTypes.arrayOf(limitShape),
  actions: PropTypes.arrayOf(PropTypes.element),
}

export default NonFinancialLimitDisplayTable
