import camelize from 'camelize'
import { useRequest } from 'hooks/services/baseService'

export const useAssetSubClasses = () => {
  const { data, ...restOfResponse } = useRequest({
    path: '/risk-figures/configurations/asset-sub-classes',
    keys: ['risk-figures', 'configurations', 'asset-sub-classes'],
  })

  return { ...restOfResponse, data: camelize(data) }
}
