import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TILE_VERSION as tileVersion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import FactSheetKeyFactsTileViewModeV1 from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/keyFacts/FactSheetKeyFactsTileViewModeV1'
import FactSheetKeyFactsTileViewModeV2 from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/keyFacts/FactSheetKeyFactsTileViewModeV2'

const FactSheetKeyFactsTile = ({ tileId }) => {
  const { version } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const factSheetKeyFactsTileViewModeForVersion = useMemo(() => {
    switch (version) {
      case tileVersion.V1:
        return <FactSheetKeyFactsTileViewModeV1 tileId={tileId} />
      default:
        return <FactSheetKeyFactsTileViewModeV2 tileId={tileId} />
    }
  }, [tileId, version])

  return factSheetKeyFactsTileViewModeForVersion
}

export default FactSheetKeyFactsTile
