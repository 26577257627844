/** @param {import("hooks/services/collaterals/useAggregatedCollateralAgreements").Cluster} cagCluster */
const getCagSubRows = (cagCluster, idsWithWarning, hasWarning, statusMapping) =>
  Object.values(cagCluster.cagTypes).map((cagType) => ({
    cag: {
      id: '',
      isBold: false,
      value: cagType.cagType.name,
    },
    systemStatus: {
      id: '',
      value: '',
    },
    statusMapping,
    cagValue: {
      cagValueInHeadQuarterCurrency: cagType.cagAggregatedValueInHeadQuarterCurrency,
      cagValue: undefined,
      isBold: true,
    },
    cagNominalValue: {
      cagValueInHeadQuarterCurrency: cagType.cagAggregatedNominalValueInHeadQuarterCurrency,
      cagValue: undefined,
      isBold: true,
    },
    externalLandCharge: '',
    assetsCount: '',
    subRows: cagType.agreements.map((agreement) => ({
      cag: {
        id: agreement.id,
        displayId: agreement.displayId,
        isBold: true,
        value: agreement.name,
        hasWarning,
      },
      status: idsWithWarning.includes(agreement.id) ? 'Warning' : '',
      systemStatus: agreement.systemStatus,
      statusMapping,
      cagValue: {
        cagValueInHeadQuarterCurrency: agreement.cagValueInHeadQuarterCurrency,
        cagValue: agreement.cagValue,
        isBold: false,
      },
      cagNominalValue: {
        cagValueInHeadQuarterCurrency: agreement.cagNominalValueInHeadQuarterCurrency,
        cagValue: agreement.cagNominalValue,
        isBold: false,
      },
      externalLandCharge: !!agreement.externalLandCharge,
      noOfFinancialProducts:
        (agreement.newBusinessReceivables?.length ?? 0) +
        (agreement.existingBusinessReceivables?.length ?? 0),
      assetsCount: agreement.assetsCount,
      subRows: [],
    })),
  }))

const getSortOrder = ({ cagClusterConfig = {}, cagClusterId } = {}) => {
  const clusterKey = Object.keys(cagClusterConfig).find(
    (key) => cagClusterConfig[key]?.id === cagClusterId.toString(),
  )

  return cagClusterConfig[clusterKey]?.sortingOrder ?? Number.MAX_SAFE_INTEGER
}

/** @param {import("hooks/services/collaterals/useAggregatedCollateralAgreements").Aggregated} aggregatedCollateralAgreements */
export const mapAggregatedCollateralAgreementsToTableData = ({
  aggregatedCollateralAgreements,
  idsWithWarning = [],
  cagClusterConfig,
  areRowsSectionHeader = true,
  isTotalSectionHeader = false,
  isNormalRowBold = true,
  hasWarning = false,
  tileVersion,
  statusMapping,
}) => {
  if (!aggregatedCollateralAgreements?.cagClusters) {
    return []
  }

  return [
    ...Object.values(aggregatedCollateralAgreements.cagClusters).map((cagCluster) => ({
      isSectionHeader: areRowsSectionHeader,
      topLevelSortValue: getSortOrder({
        cagClusterConfig,
        cagClusterId: cagCluster.cagCluster.id,
      }),
      cag: {
        id: '',
        isBold: isNormalRowBold,
        value: cagCluster.cagCluster.name,
      },
      systemStatus: '',
      cagValue: {
        cagValueInHeadQuarterCurrency: cagCluster.cagAggregatedValueInHeadQuarterCurrency,
        cagValue: undefined,
        isBold: isNormalRowBold,
      },
      cagNominalValue: {
        cagValueInHeadQuarterCurrency: cagCluster.cagAggregatedNominalValueInHeadQuarterCurrency,
        cagValue: undefined,
        isBold: isNormalRowBold,
      },
      externalLandCharge: '',
      assetsCount: '',
      subRows: getCagSubRows(cagCluster, idsWithWarning, hasWarning, statusMapping),
    })),
    {
      isSectionHeader: isTotalSectionHeader,
      topLevelSortValue: 0,
      cag: {
        id: 'total',
        isBold: true,
        value: 'Total',
      },
      systemStatus: '',
      tileVersion,
      statusMapping,
      cagValue: {
        cagValueInHeadQuarterCurrency:
          aggregatedCollateralAgreements.cagAggregatedTotalValueInHeadQuarterCurrency,
        isBold: true,
      },
      cagNominalValue: {
        cagValueInHeadQuarterCurrency:
          aggregatedCollateralAgreements.cagAggregatedTotalNominalValueInHeadQuarterCurrency,
        isBold: true,
      },
      externalLandCharge: '',
      assetsCount: '',
      subRows: [],
    },
  ]
}
