import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'

const mapCustomerSpecificAttributesData = ({
  borrowerId,
  multipleCustomerSpecificAttributes,
  dealIdTrancheIdPairsByBusinessPartner,
}) => {
  const ids = dealIdTrancheIdPairsByBusinessPartner[borrowerId]

  if (isEmpty(ids) || isNil(multipleCustomerSpecificAttributes)) {
    return { hasSpecializedLending: false, hasSecuritzation: false }
  }

  const hasSpecializedLending = ids.some(
    ({ trancheId, dealId }) =>
      multipleCustomerSpecificAttributes.find(
        (customerSpecificAttributes) =>
          customerSpecificAttributes.trancheId === trancheId &&
          customerSpecificAttributes.dealId === dealId,
      )?.customFields?.SpecializedLending?.value,
  )

  const hasSecuritization = ids.some(
    ({ trancheId, dealId }) =>
      multipleCustomerSpecificAttributes.find(
        (customerSpecificAttributes) =>
          customerSpecificAttributes.trancheId === trancheId &&
          customerSpecificAttributes.dealId === dealId,
      )?.customFields?.Securitization?.value,
  )

  return { hasSpecializedLending, hasSecuritization }
}

export default mapCustomerSpecificAttributesData
