import { useMemo } from 'react'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useDealTeam from 'hooks/services/deals/deal-team/useDealTeam'
import useDealTeamUsersByIds from 'hooks/services/deals/deal-team/useDealTeamUsersByIds'
import useDeal from 'hooks/services/deals/useDeal'

const useOpsMemoDealTeamInternalMembers = ({ entityRef: { entityId: dealUuid } }, _, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    data: dealData,
    isFetching: isFetchingDeal,
    isError: isErrorDeal,
  } = useDeal(dealUuidByTileCode)
  const dealDisplayId = useMemo(
    () => (!isFetchingDeal && !isErrorDeal ? dealData?.dealId : undefined),
    [dealData?.dealId, isErrorDeal, isFetchingDeal],
  )

  const {
    data: { staffMembers = [] } = {},
    isFetching: isFetchingDealTeam,
    isError: isErrorDealTeam,
  } = useDealTeam({ dealUuid: dealUuidByTileCode })

  const userIds = staffMembers.map(({ userId }) => userId)
  const {
    data: userData = [],
    isSomeFetching: isFetchingUsers,
    isSomeError: isErrorUsers,
  } = useDealTeamUsersByIds(userIds)

  const sourcePathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?working-version=true' : ''),
    [isWorkingVersionDataTile],
  )

  return useMemo(() => {
    const isLoading = isDealUuidFetching || isFetchingDeal || isFetchingDealTeam || isFetchingUsers
    const isError = isDealUuidError || isErrorDeal || isErrorDealTeam || isErrorUsers

    if (isLoading || isError) {
      return { isLoading, isError, data: undefined }
    }
    const getUserById = (id) =>
      userData.find((user) => user?.id === id) ?? { fullName: id, team: '' }

    const dealTeamData = staffMembers.map((member) => ({
      ...member,
      user: getUserById(member.userId),
    }))

    const data = {
      dealTeamData: dealTeamData || {},
      sourceRender: { dealDisplayId, sourcePathSuffix },
    }

    return {
      isLoading: false,
      isError: false,
      data,
    }
  }, [
    dealDisplayId,
    isDealUuidError,
    isDealUuidFetching,
    isErrorDeal,
    isErrorDealTeam,
    isErrorUsers,
    isFetchingDeal,
    isFetchingDealTeam,
    isFetchingUsers,
    sourcePathSuffix,
    staffMembers,
    userData,
  ])
}

export default useOpsMemoDealTeamInternalMembers
