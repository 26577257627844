import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/impairment-check/Section.module.css'

const propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
}
/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const Section = ({ label, children }) => (
  <section className={styles.section}>
    <Text className={styles.label}>{label}</Text>
    {children}
  </section>
)

Section.propTypes = propTypes

export default Section
