import { MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import MessageBoxWithExpandableDetails from 'components/ui/dialog/MessageBoxWithExpandableDetails'

/**
 * https://fioneer.atlassian.net/browse/CWP-13200 | Move to correct folder (components/ui/message-box)
 */
const ErrorMessageBoxWithExpandableDetails = ({
  messageSummary,
  messageDetails,
  isOpen,
  onClose,
}) => {
  const tryJsonParse = (maybeJSON) => {
    let parsed
    try {
      parsed = JSON.parse(maybeJSON)
    } catch (e) {
      parsed = {}
    }
    return parsed
  }

  const summaryJson = tryJsonParse(messageDetails)
  const traceDetailsJson = tryJsonParse(summaryJson?.errorDetails?.trace_details)

  const messageExtension =
    traceDetailsJson?.error?.message ||
    summaryJson?.errorDetails?.message ||
    summaryJson?.errorDetails?.detail ||
    summaryJson?.errorDetails?.title

  const additionalDetails = traceDetailsJson?.error?.details?.map((detail) => detail.message) ?? []

  const extendedSummary = [messageSummary, messageExtension, ...additionalDetails]
    .filter((message) => message?.trim())
    .join('\n\n')

  return (
    <MessageBoxWithExpandableDetails
      messageSummary={extendedSummary}
      messageDetails={messageDetails}
      messageBoxType={MessageBoxTypes.Error}
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

ErrorMessageBoxWithExpandableDetails.propTypes = {
  messageSummary: PropTypes.string,
  messageDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
}

export default ErrorMessageBoxWithExpandableDetails
