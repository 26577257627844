import { InputType } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrencyCodes } from 'hooks/services/properties/useCurrencyCodes'

const DEFAULT_CURRENCY = 'EUR'

const useFactSheetValuationScenarioOptions = () => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.fact-sheet-valuation-scenarios.option',
  })
  const { data: currencyCodesData } = useCurrencyCodes()

  const currencyCodes = useMemo(() => {
    const currencies = currencyCodesData
      ? currencyCodesData?.currency_codes.map((currencyCode) => currencyCode.display_name)
      : [DEFAULT_CURRENCY]
    //always set default currency as first (default) option
    const currenciesWithoutDefaultOption = currencies.filter((cur) => cur !== DEFAULT_CURRENCY)
    return [DEFAULT_CURRENCY, ...currenciesWithoutDefaultOption]
  }, [currencyCodesData])

  const optionList = [
    ['cap-rate', '%', InputType.Number],
    ['cap-rate-building', '%', InputType.Number],
    ['cap-rate-going-concern', '%', InputType.Number],
    ['cap-rate-terminal', '%', InputType.Number],
    ['capitalized-ground-rent', currencyCodes, InputType.Number],
    ['initial-rate-of-return', '%', InputType.Number],
    ['multiplier-gross-curr-rent', '', InputType.Number],
    ['multiplier-gross-market-rent', '', InputType.Number],
    ['overall-rate', '%', InputType.Number],
    ['value-alter-completion', currencyCodes, InputType.Number],
    ['value-as-is', currencyCodes, InputType.Number],
    ['value-capital', currencyCodes, InputType.Number],
    ['value-free-of-encumbrances', currencyCodes, InputType.Number],
    ['value-land', currencyCodes, InputType.Number],
    ['value-leased-fee', currencyCodes, InputType.Number],
    ['value-loan', currencyCodes, InputType.Number],
    ['value-property-total', currencyCodes, InputType.Number],
    ['value-reinstatement-pfandbg', currencyCodes, InputType.Number],
    ['value-residual', currencyCodes, InputType.Number],
    ['value-special-assumptions', currencyCodes, InputType.Number],
    ['value-stabilized', currencyCodes, InputType.Number],
    ['value-vacant-possession', currencyCodes, InputType.Number],
    ['yield-discount', '%', InputType.Number],
    ['yield-equivalent', '%', InputType.Number],
    ['yield-exit', '%', InputType.Number],
    ['yield-gross-initial', '%', InputType.Number],
    ['yield-net-initial', '%', InputType.Number],
    ['yield-reversionary', '%', InputType.Number],
    ['other', '', InputType.Text],
  ]
  return optionList.map(([option, label, inputType]) => ({
    key: option,
    displayValue: t(option),
    label,
    inputType,
  }))
}

export default useFactSheetValuationScenarioOptions
