import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useCwpBusinessPartnerRoles = (options) =>
  useCamelizedResponse(
    useRequest({
      path: '/properties/sap-business-partner-roles',
      translated: true,
      options,
    }),
  )
