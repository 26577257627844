import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateChineseWall = () => {
  const { put } = useAccessTokenRequest()

  return useMutation(async ({ dealUuid, chineseWall }) => {
    const { data } = await put({
      path: `/deals/${dealUuid}/chinese-wall`,
      body: chineseWall,
    })
    return data
  })
}

export default useUpdateChineseWall
