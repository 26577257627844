import { useQueryClient } from '@tanstack/react-query'
import { uniqBy } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useGetAllRentRollWorkingVersionOptions from 'hooks/services/properties/rent-roll/working-version/useGetAllRentRollWorkingVersionOptions'
import { useCreateSegment } from 'hooks/services/properties/segments/useCreateSegment'
import useMultiplePropertiesSegments from 'hooks/services/properties/segments/useMultiplePropertiesSegments'
import useMultiProperties from 'hooks/services/properties/useMultiProperties'

export const useMatchOrCreateSegmentsToRentalUnits = (propertyUuids, onMappingComplete) => {
  const {
    isLoading: segmentsIsLoading,
    isError: segmentsIsError,
    data: segmentsData,
  } = useMultiplePropertiesSegments(propertyUuids)

  const propertyUuidsRequestParam = propertyUuids.map((id) => ({
    id,
  }))
  const {
    isLoading: propertiesIsLoading,
    isError: propertiesIsError,
    data: propertiesData,
  } = useMultiProperties(propertyUuidsRequestParam)

  const properties = useMemo(() => propertiesData?.properties ?? [], [propertiesData])
  const getDefaultAreaMeasureUnitCodeByPropertyUuid = useCallback(
    (propertyUuid) =>
      properties.find((property) => property.uuid === propertyUuid)?.areaMeasureUnitCode,
    [properties],
  )

  const { segmentUsageTypeCodes } = useGetAllRentRollWorkingVersionOptions()

  const segmentPostErrors = useRef(0)

  const [matchingStarted, setMatchingStarted] = useState(false)

  const [triggerMatchingAgain, setTriggerMatchingAgain] = useState(false)

  const queryClient = useQueryClient()

  const initialTableData = useRef([])

  const createSegment = useCreateSegment({
    onSuccess: () => {
      queryClient.invalidateQueries(['segments', ...propertyUuids])
    },
    onError: () => {
      segmentPostErrors.current = segmentPostErrors.current + 1
    },
  })

  const finishSegmentMatchingProcess = useCallback(
    (tableData) => {
      setMatchingStarted(false)
      segmentPostErrors.current = 0
      onMappingComplete(tableData)
    },
    [onMappingComplete],
  )

  const findSegmentUsageTypeByKeyOrReturnNull = useCallback(
    (usageTypeKey) =>
      segmentUsageTypeCodes?.find(
        (segmentUsageType) => segmentUsageType.key && usageTypeKey === segmentUsageType.key,
      ),
    [segmentUsageTypeCodes],
  )

  const filterSegmentsByUsageTypeKeyAndPropertyUuid = useCallback(
    (usageTypeKey, propertyUuid) =>
      segmentsData?.segments.filter(
        (segment) =>
          segment.usage_type_code === usageTypeKey && propertyUuid === segment.property_uuid,
      ),
    [segmentsData?.segments],
  )

  const createAllSegmentsInList = useCallback(
    (segmentsList) =>
      uniqBy(segmentsList, (segment) => [segment.key, segment.property_uuid].join()).forEach(
        (segmentToCreate) => {
          createSegment.mutate({
            property_uuid: segmentToCreate.property_uuid,
            segment: {
              name: segmentToCreate.display_name,
              usage_type_code: segmentToCreate.key,
              area_measure_unit_code: getDefaultAreaMeasureUnitCodeByPropertyUuid(
                segmentToCreate.property_uuid,
              ),
            },
          })
        },
      ),
    [createSegment, getDefaultAreaMeasureUnitCodeByPropertyUuid],
  )

  const segmentMatchingComplete = useCallback((createSegmentList) => {
    const isFinished =
      createSegmentList.length === 0 ||
      uniqBy(createSegmentList, (segment) => [segment.key, segment.property_uuid].join()).length ===
        segmentPostErrors.current
    if (isFinished) {
      return true
    } else {
      setTriggerMatchingAgain(true)
      return false
    }
  }, [])

  const isLoading = segmentsIsLoading || propertiesIsLoading
  const isError = segmentsIsError || propertiesIsError

  const matchSegments = useCallback(
    (tableData, createSegmentsIfNecessary = false) => {
      if (isError) {
        finishSegmentMatchingProcess(tableData)
        return
      }
      if (isLoading) {
        return
      }

      const segmentsToCreate = []
      const partiallyMatchedTableData = tableData.map((row) => {
        const resultRow = { ...row }
        const legitSegmentUsageTypeInRow = findSegmentUsageTypeByKeyOrReturnNull(
          resultRow['segment_usage_type_id'],
        )

        if (legitSegmentUsageTypeInRow && row.property_uuid && !row.segment_uuid) {
          const matchingSegments = filterSegmentsByUsageTypeKeyAndPropertyUuid(
            legitSegmentUsageTypeInRow.key,
            row.property_uuid,
          )

          if (matchingSegments.length === 1) {
            resultRow['segment_uuid'] = matchingSegments[0].uuid
          } else if (matchingSegments.length === 0) {
            segmentsToCreate.push({
              ...legitSegmentUsageTypeInRow,
              property_uuid: row.property_uuid,
            })
          }
          //if more then one matching segment is found, the user will have to decide later and nothing is done here
        }
        return resultRow
      })

      if (segmentMatchingComplete(segmentsToCreate)) {
        //all matchable Segments have been matched -> call onComplete function and finish the mapping process
        finishSegmentMatchingProcess(partiallyMatchedTableData)
        return
      }
      if (createSegmentsIfNecessary) {
        createAllSegmentsInList(segmentsToCreate)
      }
    },
    [
      isError,
      isLoading,
      segmentMatchingComplete,
      finishSegmentMatchingProcess,
      findSegmentUsageTypeByKeyOrReturnNull,
      filterSegmentsByUsageTypeKeyAndPropertyUuid,
      createAllSegmentsInList,
    ],
  )

  const matchOrCreateSegments = useCallback(
    (tableData) => {
      initialTableData.current = tableData
      segmentPostErrors.current = 0
      setMatchingStarted(true)
      matchSegments(tableData, true)
    },
    [matchSegments],
  )

  const tryMatchingUntilAllSegmentMappingsComplete = () => {
    if (matchingStarted || triggerMatchingAgain) {
      setTriggerMatchingAgain(false)
      matchSegments(initialTableData.current)
    }
  }

  useEffect(tryMatchingUntilAllSegmentMappingsComplete, [
    matchSegments,
    matchingStarted,
    triggerMatchingAgain,
  ])

  return {
    matchOrCreateSegments,
  }
}
