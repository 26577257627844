import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import snakecaseKeys from 'snakecase-keys'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-history/MultipleFactSheetValuationHistoryV2.module.css'
import PropertyValuationResultsHistoryChart from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-history/PropertyValuationResultsHistoryChart'
import PropertyValuationResultsHistoryChartHeader from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-history/PropertyValuationResultsHistoryChartHeader'
import AnnualReviewDealOverviewPropertySelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-selection/AnnualReviewDealOverviewPropertySelect'
import AnnualReviewPropertySelectPdfView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-selection/AnnualReviewPropertySelectPdfView'
import useAnnualReviewDealOverviewSelectedProperty from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/utils/useAnnualReviewDealOverviewSelectedProperty'
import { ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/constants'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const MultipleFactSheetValuationHistoryV2 = ({ tileId, selectedDealIndex, isPdfView = false }) => {
  const dispatch = useDispatch()

  const {
    data: { multipleArrayPropertyUuidsData = [], multiplePropertyValuationsData = [] } = {},
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const propertiesData = useMemo(
    () =>
      snakecaseKeys(multipleArrayPropertyUuidsData?.[selectedDealIndex]?.data?.properties ?? []),
    [multipleArrayPropertyUuidsData, selectedDealIndex],
  )

  const { selectedPropertyUuid, selectedPropertyDisplayId, selectedPropertyName } =
    useAnnualReviewDealOverviewSelectedProperty({
      propertiesData,
      propertyMetadataTileCode:
        ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES.PROPERTY_PORTFOLIO_SUBSECTION,
    })

  useEffect(() => {
    dispatch(
      setSourceRender({
        tileId,
        sourceRender: { propertyId: selectedPropertyDisplayId },
      }),
    ) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPropertyDisplayId])

  const renderContent = useCallback(
    (currentProperty) => {
      const { uuid: currentPropertyUuid } = currentProperty ?? {}

      const selectedValuationsData = multiplePropertyValuationsData?.find(
        (property) => property.propertyUuid[0] === currentPropertyUuid,
      )?.cwpValuations

      return (
        <div key={currentPropertyUuid}>
          {isPdfView ? (
            <AnnualReviewPropertySelectPdfView {...currentProperty} />
          ) : (
            <AnnualReviewDealOverviewPropertySelect
              propertiesData={propertiesData}
              propertyMetadataTileCode={
                ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES.PROPERTY_PORTFOLIO_SUBSECTION
              }
            />
          )}
          <DecisionPaperTileListSeparator />
          <PropertyValuationResultsHistoryChartHeader valuationsData={selectedValuationsData} />
          <DecisionPaperTileListSeparator />
          <div className={styles.spacingWrapper}>
            {!!currentProperty?.uuid && (
              <PropertyValuationResultsHistoryChart valuationsData={selectedValuationsData} />
            )}
          </div>
        </div>
      )
    },
    [isPdfView, multiplePropertyValuationsData, propertiesData],
  )

  return isPdfView
    ? propertiesData?.map((property) => renderContent(property))
    : renderContent({
        uuid: selectedPropertyUuid,
        id: selectedPropertyDisplayId,
        description: selectedPropertyName,
      })
}

MultipleFactSheetValuationHistoryV2.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultipleFactSheetValuationHistoryV2
