import { useMemo } from 'react'
import { dealMiniSchema } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/useDealMini.schema'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDealMini = (dealUuid, options = { enabled: !!dealUuid }) => {
  const response = useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/mini`,
      translated: true,
      useCache: true,
      keys: ['deals', dealUuid, 'mini'],
      options,
    }),
  )
  const data = useMemo(() => response.data && dealMiniSchema.parse(response.data), [response.data])
  return useMemo(() => ({ ...response, data }), [data, response])
}

export default useDealMini
