import {
  Modals,
  PopoverPlacementType,
  Button,
  ButtonDesign,
  MessageBoxActions,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo, useCallback, useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CancelPopoverContent } from 'components/ui/button/CancelButtonWithPopover'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import EditAndDeleteTableCell from 'components/ui/tables/sorted-tables/EditAndDeleteTableCell'
import { useCreateRequirementReminder } from 'hooks/services/conditions/requirements/reminders/useCreateRequirementReminder'
import useDeleteRequirementReminder from 'hooks/services/conditions/requirements/reminders/useDeleteRequirementReminder'
import useEditRequirementReminder from 'hooks/services/conditions/requirements/reminders/useEditRequirementReminder'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const popoverId = 'reminder-cancel-popover-id'

const RequirementReminderActionCells = ({
  isEditModeForAnyRow,
  isAddMode,
  setIsAddMode,
  showEditButton,
  showDeleteButton,
  setIsEditModeForReminderId,
  isEditModeForReminderId,
  values,
  requirementId,
  hasChanges,
  setHasChanges,
  reminderId,
  onEditButtonClicked,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.requirements.reminders.table',
  })
  const {
    entityRef: { entityType, entityId },
  } = useContext(ConditionsContext)
  const popoverRef = useRef()
  const queryClient = useQueryClient()
  const showPopover = Modals.useShowPopover()
  const showMessageBox = useShowMessageBox()

  const { mutate: createReminder } = useCreateRequirementReminder()
  const { mutate: editReminder } = useEditRequirementReminder()
  const { mutate: deleteReminder } = useDeleteRequirementReminder()

  const isEditModeForCurrentRow = useCallback(
    (id) => id === isEditModeForReminderId,
    [isEditModeForReminderId],
  )

  const isSaveButtonDisabled = useMemo(() => {
    if (!hasChanges) return true
    if (isNil(values?.method) || isNil(values?.date)) return true
  }, [hasChanges, values])

  const onError = useCallback(() => {
    showMessageBox({ type: MessageBoxTypes.Error, children: t('error.description') })
  }, [showMessageBox, t])

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries([
      'conditions',
      'external',
      'requirements',
      requirementId,
      'reminders',
    ])
    queryClient.invalidateQueries(['conditions', 'external', entityType, entityId, 'requirements'])
    setIsAddMode(false)
    setIsEditModeForReminderId()
    setHasChanges(false)
  }, [
    entityId,
    entityType,
    queryClient,
    requirementId,
    setHasChanges,
    setIsAddMode,
    setIsEditModeForReminderId,
  ])

  const onSaveButtonClicked = useCallback(() => {
    if (isAddMode) {
      createReminder(
        {
          requirementId,
          date: values.date,
          method: values.method,
          comment: values.comment,
        },
        {
          onSuccess,
          onError,
        },
      )
      return
    }
    editReminder(
      {
        requirementId,
        reminderId: isEditModeForReminderId,
        reminder: {
          date: values.date,
          method: values.method,
          comment: values.comment,
        },
      },
      {
        onSuccess,
        onError,
      },
    )
  }, [
    isAddMode,
    createReminder,
    requirementId,
    values,
    onSuccess,
    onError,
    isEditModeForReminderId,
    editReminder,
  ])

  const onConfirmDeletion = useCallback(() => {
    deleteReminder(
      {
        requirementId,
        reminderId,
      },
      {
        onSuccess,
        onError,
      },
    )
  }, [deleteReminder, onError, onSuccess, reminderId, requirementId])

  const onDeleteButtonClicked = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Confirm,
      children: t('delete.confirmation.body'),
      titleText: t('delete.confirmation.title'),
      actions: [
        <Button key="button-confirm" design={ButtonDesign.Emphasized} onClick={onConfirmDeletion}>
          {t('delete.confirmation.button')}
        </Button>,
        MessageBoxActions.Cancel,
      ],
    })
  }, [onConfirmDeletion, showMessageBox, t])

  const onCancelConfirmed = useCallback(() => {
    popoverRef.current.close()
    setIsAddMode(false)
    setIsEditModeForReminderId()
    setHasChanges(false)
  }, [setHasChanges, setIsAddMode, setIsEditModeForReminderId])

  const onSaveCancelButtonClicked = useCallback(() => {
    if (hasChanges) {
      popoverRef.current = showPopover({
        opener: popoverId,
        placementType: PopoverPlacementType.Top,
        children: <CancelPopoverContent onCancelClicked={onCancelConfirmed} />,
      })
      return
    }
    setIsAddMode(false)
    setIsEditModeForReminderId(undefined)
  }, [hasChanges, onCancelConfirmed, setIsAddMode, setIsEditModeForReminderId, showPopover])

  return (
    <EditAndDeleteTableCell
      cancelButtonId={popoverId}
      showEditButton={showEditButton}
      showDeleteButton={showDeleteButton}
      isEditModeForCurrentRow={isEditModeForCurrentRow(reminderId)}
      isEditModeForAnyRow={isEditModeForAnyRow}
      isEditButtonDisabled={isEditModeForAnyRow}
      isDeleteButtonDisabled={isEditModeForAnyRow}
      isSaveButtonDisabled={isSaveButtonDisabled}
      onCancelButtonClicked={onSaveCancelButtonClicked}
      onSaveButtonClicked={onSaveButtonClicked}
      onEditButtonClicked={onEditButtonClicked}
      onDeleteButtonClicked={onDeleteButtonClicked}
    />
  )
}

RequirementReminderActionCells.propTypes = {
  values: PropTypes.shape({
    date: PropTypes.string,
    method: PropTypes.string,
    comment: PropTypes.string,
  }),
  isEditModeForAnyRow: PropTypes.bool.isRequired,
  isAddMode: PropTypes.bool.isRequired,
  setIsAddMode: PropTypes.func.isRequired,
  showEditButton: PropTypes.bool.isRequired,
  showDeleteButton: PropTypes.bool.isRequired,
  setIsEditModeForReminderId: PropTypes.func.isRequired,
  isEditModeForReminderId: PropTypes.string,
  requirementId: PropTypes.string.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  setHasChanges: PropTypes.func.isRequired,
  reminderId: PropTypes.string,
  onEditButtonClicked: PropTypes.func.isRequired,
}
export default RequirementReminderActionCells
