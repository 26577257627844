import find from 'lodash.find'
import { useMemo } from 'react'
import { entityTypes } from 'api/events/status'
import useBusinessPartnerCustomFieldConfig from 'components/domains/business-partners/tile/general-information/useBusinessPartnerCustomFieldConfig'
import useBusinessPartnerCustomFields from 'components/domains/business-partners/tile/general-information/useBusinessPartnerCustomFields'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useDeal from 'hooks/services/deals/useDeal'

const BUSINESS_PARTNER_ENTITY_TYPE = 'BUSINESS_PARTNER'
const FPO_RESULT = 'FPO_RESULT'
const FPO_COMMENT = 'FPO_COMMENT'

const useFpoToolTile = ({ entityRef: { entityId: uuid, entityType } }, tileId, tileCode) => {
  const isDeal = useMemo(() => entityType === entityTypes.deal, [entityType])

  const {
    data: { dealUuid: dealUuidByTileCode } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({
    dealUuid: uuid,
    tileCode,
    options: { enabled: isDeal && !!uuid },
    isWorkingVersionInfoInSourcePath: true,
  })

  const {
    data: dealData,
    isFetching: isDealFetching,
    isError: isDealError,
    error: dealError,
  } = useDeal(dealUuidByTileCode)

  let borrowerId

  switch (entityType) {
    case entityTypes.businesspartner:
      borrowerId = uuid
      break
    case entityTypes.deal:
      borrowerId = dealData?.borrowerBpId
      break
    default:
      borrowerId = ''
  }

  const {
    data: fields,
    isFetching: isBusinessPartnerCustomFieldsFetching,
    isError: isBusinessPartnerCustomFieldsError,
    error: businessPartnerCustomFieldsError,
  } = useBusinessPartnerCustomFields(borrowerId)

  const {
    data: metadata,
    isLoading: isMetadataLoading,
    isError: isMetadataError,
    error: metadataError,
  } = useBusinessPartnerCustomFieldConfig(BUSINESS_PARTNER_ENTITY_TYPE)

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isDealUuidFetching,
      isDealFetching,
      isBusinessPartnerCustomFieldsFetching,
      isMetadataLoading,
    ],
    errorValues: [
      isDealUuidError,
      isDealError,
      isBusinessPartnerCustomFieldsError,
      isMetadataError,
    ],
    errorDetails: [dealError, businessPartnerCustomFieldsError, metadataError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueLoading || isSomeValueError) {
      return { isLoading: isSomeValueLoading, isError: isSomeValueError, error }
    }

    const customFields = fields?.customFields ?? []
    const customFieldMetadata = metadata?.customFieldMetadata ?? []

    const codeFpoResult = find(customFields, (c) => c.key === FPO_RESULT)?.value
    const metaFpoResult = find(customFieldMetadata, (c) => c.key === FPO_RESULT)?.enumValues
    const fpoResult = find(metaFpoResult, (c) => c.code === codeFpoResult)?.displayName

    const fpoComment = find(customFields, (c) => c.key === FPO_COMMENT)?.value

    return {
      isLoading: false,
      isError: false,
      data: {
        fpoTools: {
          fpoResult,
          fpoComment,
        },
        sourceRender: {
          businessPartnerId: borrowerId,
        },
      },
    }
  }, [borrowerId, error, fields, isSomeValueError, isSomeValueLoading, metadata])
}

export default useFpoToolTile
