import { Label } from '@fioneer/ui5-webcomponents-react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/pd-rating/BusinessPartnerRatingsOverviewTile.module.css'
import { externalRatingsForOverview as externalRatingsPropTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/pd-rating/propTypes'
import SortedTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/pd-rating/shared/ui/tables/sorted-tables/SortedTable'
import sortByAgency from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/pd-rating/sortByAgency'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const RiskIndicatorCheckExternalRatingsOverview = ({ externalRatings }) => {
  const { format: formatDate } = useShortDateFormatter()
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.risk-monitoring.pd-rating.pages.business-partner.ratings',
  })

  const columnDefinitions = [
    {
      title: t('external.agency'),
      columnKey: 'agency',
    },
    {
      title: t('external.grade'),
      columnKey: 'grade',
    },
    {
      title: t('external.valid-from'),
      columnKey: 'validFrom',
    },
    {
      title: t('external.outlook'),
      columnKey: 'outlook',
    },
    {
      title: t('external.action-type'),
      columnKey: 'actionType',
    },
  ]

  const text = (value) => ({
    value,
    cellComponent: <Label>{value}</Label>,
  })
  const dateCell = (value) => ({
    value,
    cellComponent: <Label>{formatDate(value)}</Label>,
  })
  const tableData = externalRatings
    .filter(({ validFrom, validTo }) => {
      const today = DateTime.now().toISODate()
      return validFrom <= today && today <= validTo
    })
    .map(({ agency, validFrom, grade, outlook, actionType }) => ({
      rowKey: agency?.name,
      agency: text(agency?.name),
      validFrom: dateCell(validFrom),
      grade: text(grade),
      outlook: text(outlook?.name),
      actionType: text(actionType),
    }))

  return (
    <>
      <Label className={styles.title}>{t('external')}</Label>
      <SortedTable
        columnDefinitions={columnDefinitions}
        tableData={tableData}
        noDataText={t('external.no-data')}
        customOrderFunction={sortByAgency}
      />
    </>
  )
}

RiskIndicatorCheckExternalRatingsOverview.propTypes = {
  externalRatings: externalRatingsPropTypes.isRequired,
}

export default RiskIndicatorCheckExternalRatingsOverview
