import { FlexBox, Table, TableCell, TableRow } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import EmptyTileContent from 'components/domains/business-events-and-tasks/decision-paper/EmptyTileContent'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/specifics-development/MilestonesTile.module.css'
import createColumns from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/specifics-development/shared/createColumns'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const MilestonesTile = ({ tileId }) => {
  const tileState = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const {
    data: { tableData, noMilestones },
  } = tileState

  const { t: tMilestones } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.milestones',
  })

  const { format: formatDate } = useShortDateFormatter()

  const formattedTableData = useMemo(
    () =>
      tableData?.map(({ targetDate: noFormatTargetDate, ...other }) => ({
        ...other,
        targetDate: {
          value: noFormatTargetDate.value ? formatDate(noFormatTargetDate.value) : '-',
        },
      })),
    [formatDate, tableData],
  )

  const columnDefinitions = useMemo(
    () => [
      {
        columnKey: 'milestone',
        title: tMilestones('milestone'),
      },
      {
        columnKey: 'category',
        title: tMilestones('category'),
      },
      {
        columnKey: 'targetDate',
        title: tMilestones('targetDate'),
      },
    ],
    [tMilestones],
  )

  const renderCells = (tableRow) =>
    columnDefinitions.map((colDef) => (
      <TableCell key={`${tableRow.rowKey}-${colDef.columnKey}`}>
        {tableRow[colDef.columnKey].value}
      </TableCell>
    ))

  const mapTableRows = () =>
    formattedTableData.map((tableRow) => (
      <TableRow key={tableRow.rowKey}>{renderCells(tableRow)}</TableRow>
    ))

  if (noMilestones) {
    return <EmptyTileContent />
  }
  return (
    <FlexBox>
      <Table
        id="decision-paper-milestones-table"
        className={styles.tableWrapper}
        columns={createColumns(columnDefinitions)}
      >
        {mapTableRows()}
      </Table>
    </FlexBox>
  )
}

MilestonesTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default MilestonesTile
