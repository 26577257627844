import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import styles from 'components/ui/tables/cells/TextCell.module.css'

const propTypes = {
  /** The text value to display in the component. */
  text: PropTypes.string,
  /**
   * Options to modify how the Cell will look like.
   *
   * - `isBold` Display the text in bold font. Defaults to false.
   * - `hasOverflowEllipsis` Displays overflow ellipsis, if the parent cell container has limited height and width. Defaults to false.
   * - `characterLimit` Defines the maximum length of characters shown before the string is collapsed. Defaults to undefined.
   */
  options: PropTypes.shape({
    isBold: PropTypes.bool,
    hasOverflowEllipsis: PropTypes.bool,
    characterLimit: PropTypes.number,
  }),
  /** Defines whether a loading spinner is shown. */
  isLoading: PropTypes.bool,
  /** Defines whether a loading spinner is shown. */
  isFetching: PropTypes.bool,
  /** Defines whether an error is shown. */
  isError: PropTypes.bool,
  /** Allows to define a custom error component. Defaults to undefined so that `<ErrorDataUnavailableInCell />` is shown. */
  errorToDisplay: PropTypes.node,
  /** Defines additional styling to the outmost div of the cell's content. */
  className: PropTypes.string,
}

/**
 * Shared Text Cell for usage within the Responsive and Analytical Table.
 *
 * @typedef Props
 * @property {String} [text] The text value to display in the component.
 * @property {Object} [options] Options to modify how the Cell will look like.
 * @property {Boolean} [options.isBold] Display the text in bold font. Defaults to false.
 * @property {Boolean} [options.hasOverflowEllipsis] Displays overflow ellipsis, if the parent cell container has limited height and width. Defaults to false.
 * @property {Number} [options.characterLimit] Defines the maximum length of characters shown before the string is collapsed. Defaults to undefined.
 * @property {Boolean} [isLoading] Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} [isFetching] Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} [isError] Defines whether an error is shown.
 * @property {React.ReactNode} [errorToDisplay] Allows to define a custom error component. Defaults to undefined so that <ErrorDataUnavailableInCell /> is shown.
 * @property {String} [className] Defines additional styling to the outmost div of the cell's content.
 */

/**
 * @param {Props} props Note for Storybook: cf. code for complete JSDoc
 */
const TextCell = ({
  text,
  options: { isBold = false, characterLimit, hasOverflowEllipsis = false } = {},
  isLoading = false,
  isFetching = false,
  isError = false,
  className,
  errorToDisplay,
}) => {
  const renderContent = () => {
    if (isNil(text)) {
      return <div />
    }

    return (
      <div
        className={compact([
          hasOverflowEllipsis ? styles.overflowWrapper : '',
          isBold ? styles.textBold : '',
          className,
        ]).join(' ')}
      >
        {isNil(characterLimit) ? text : `${text?.substring(0, characterLimit)}...`}
      </div>
    )
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading || isFetching}
      isError={isError}
      error={errorToDisplay ?? <ErrorDataUnavailableInCell />}
      renderContent={renderContent}
    />
  )
}

TextCell.propTypes = propTypes

export default TextCell
