import { Option, Select, Text, ValueState, Input } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { rowKeyNewRow } from 'components/ui/tables/display-and-edit-table/constants'

const PropertyExternalIdTableConfig = ({
  externalIdTypes,
  externalIds,
  getCurrentFieldValue,
  checkIfAnythingChanged,
  checkIsRowValid,
  updateEditRow,
  tExternalIdTable,
}) => {
  const evaluateValueState = (value) => {
    if (!value?.trim()) {
      return { isError: true, errorMessage: tExternalIdTable('error.id.empty') }
    }
    return { isError: false }
  }

  const evaluateExternalIdTypeState = (externalIdType) => {
    if (!externalIdType || externalIdType === 'empty_option') {
      return { isError: true, errorMessage: tExternalIdTable('error.type.empty') }
    }
    return { isError: false }
  }

  const renderValueStateMessage = (valueStateObject) =>
    valueStateObject.isError && <Text>{valueStateObject.errorMessage}</Text>

  const getExternalIdType = (externalIdTypeKey) =>
    (externalIdTypes ?? []).find((type) => type.key === externalIdTypeKey)?.displayName

  const renderExternalIdTypeSelect = (rowKey, originalValue) => {
    const valueStateObject = evaluateExternalIdTypeState(
      getCurrentFieldValue({ rowKey, originalValue, fieldName: 'extCode' }),
    )

    return (
      <Select
        id={`${rowKey}_external_id_type`}
        valueState={valueStateObject.isError ? ValueState.Error : ValueState.None}
        valueStateMessage={renderValueStateMessage(valueStateObject)}
        onChange={(event) => updateEditRow(rowKey, 'extCode', event.detail.selectedOption.id)}
      >
        <Option key={'empty-option'} id={'empty_option'}>
          {''}
        </Option>
        {(externalIdTypes ?? []).map((externalIdType) => (
          <Option
            key={externalIdType.key}
            id={externalIdType.key}
            selected={originalValue === externalIdType.key}
          >
            {externalIdType.displayName}
          </Option>
        ))}
      </Select>
    )
  }
  const renderInput = (rowKey, originalValue) => {
    const valueStateObject = evaluateValueState(
      getCurrentFieldValue({ rowKey, originalValue, fieldName: 'id' }),
    )
    const maxInputLength = 60
    return (
      <Input
        id={`${rowKey}_external_id_id`}
        valueState={valueStateObject.isError ? ValueState.Error : ValueState.None}
        valueStateMessage={renderValueStateMessage(valueStateObject)}
        value={originalValue}
        onInput={(event) => {
          updateEditRow(rowKey, 'id', event.target.value)
        }}
        maxlength={maxInputLength}
      />
    )
  }
  const renderOnlyReadInput = (rowKey, originalValue) => (
    <Input id={`${rowKey}_external_id_description`} value={originalValue} disabled={true} />
  )

  const columnDefinitions = [
    { columnKey: 'id', title: tExternalIdTable('id') },
    { columnKey: 'extCode', title: tExternalIdTable('type') },
    { columnKey: 'extDescription', title: tExternalIdTable('description') },
  ]

  const tableData = externalIds.map((row, index) => {
    const rowKey = `externalId_row_${index}`
    return {
      rowKey: rowKey,
      originalRowData: row,
      id: {
        cellContentReadMode: row.id,
        cellContentEditMode: renderInput(rowKey, row.id),
      },
      extCode: {
        cellContentReadMode: getExternalIdType(row.extCode),
        cellContentEditMode: renderExternalIdTypeSelect(rowKey, row.extCode),
      },
      extDescription: {
        cellContentReadMode: row['extDescription'],
        cellContentEditMode: renderOnlyReadInput(rowKey, row.extDescription),
      },
      isValid: checkIsRowValid(rowKey, row),
      hasChanges: checkIfAnythingChanged(rowKey, row),
    }
  })

  const newRow = {
    rowKey: rowKeyNewRow,
    id: {
      cellContentEditMode: renderInput(rowKeyNewRow, ''),
    },
    extCode: {
      cellContentEditMode: renderExternalIdTypeSelect(rowKeyNewRow, ''),
    },
    extDescription: {
      cellContentEditMode: renderOnlyReadInput(rowKeyNewRow, ''),
    },
    isValid: checkIsRowValid(rowKeyNewRow, {}),
  }

  return { tableData, newRow, columnDefinitions }
}

PropertyExternalIdTableConfig.propTypes = {
  externalIdTypes: PropTypes.array.isRequired,
  externalIds: PropTypes.array.isRequired,
  getCurrentFieldValue: PropTypes.func.isRequired,
  checkIfAnythingChanged: PropTypes.func.isRequired,
  checkIsRowValid: PropTypes.func.isRequired,
  updateEditRow: PropTypes.func.isRequired,
  tExternalIdTable: PropTypes.func.isRequired,
}

export default PropertyExternalIdTableConfig
