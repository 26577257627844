import z from 'zod'
import { VALUE_TYPE_CODES } from 'components/domains/business-partners/tile/risk-monitoring/utils/typeCodes'

const riskIndicatorCriticalitySchema = z.object({
  criticalityTypeCode: z.string(),
  criticalityShortText: z.string().nullish(),
  criticalityDate: z.string().nullish(),
})

const riskIndicatorReferenceSchema = z.object({
  referenceTypeCode: z.string(),
  referenceEntityId: z.string(),
  referenceEntityDisplayId: z.string(),
  referenceEntityName: z.string().nullish(),
})

const percentageValue = z.object({
  valueTypeCode: z.literal(VALUE_TYPE_CODES.PERCENTAGE),
  value: z.number(),
  threshold: z.number().nullish(),
  violationType: z.string().nullish(),
})

const stringValue = z.object({
  valueTypeCode: z.literal(VALUE_TYPE_CODES.STRING),
  value: z.string(),
  threshold: z.string().nullish(),
  violationType: z.string().nullish(),
})

const dateValue = z.object({
  valueTypeCode: z.literal(VALUE_TYPE_CODES.DATE),
  value: z.string().datetime(),
  threshold: z.string().datetime().nullish(),
  violationType: z.string().nullish(),
})

const dateTimeValue = z.object({
  valueTypeCode: z.literal(VALUE_TYPE_CODES.DATE_TIME),
  value: z.string().datetime(),
  threshold: z.string().datetime().nullish(),
  violationType: z.string().nullish(),
})

const numberValue = z.object({
  valueTypeCode: z.literal(VALUE_TYPE_CODES.NUMBER),
  value: z.number(),
  threshold: z.number().nullish(),
  violationType: z.string().nullish(),
})

const booleanValue = z.object({
  valueTypeCode: z.literal(VALUE_TYPE_CODES.BOOLEAN),
  value: z.boolean(),
  threshold: z.boolean().nullish(),
  violationType: z.string().nullish(),
})

const currencyValue = z.object({
  valueTypeCode: z.literal(VALUE_TYPE_CODES.CURRENCY),
  value: z.number(),
  unit: z.string(),
  threshold: z.number().nullish(),
  violationType: z.string().nullish(),
})

const measurementValue = z.object({
  valueTypeCode: z.literal(VALUE_TYPE_CODES.MEASUREMENT),
  value: z.number(),
  unit: z.string(),
  threshold: z.number().nullish(),
  violationType: z.string().nullish(),
})

const integerValue = z.object({
  valueTypeCode: z.literal('INTEGER'),
  value: z.number().int(),
  threshold: z.number().int().nullish(),
  violationType: z.string().nullish(),
})

const enumValue = z.object({
  valueTypeCode: z.literal('ENUM'),
  value: z.string(),
  threshold: z.string().nullish(),
  violationType: z.string().nullish(),
})

const riskIndicatorValueSchema = z.union([
  percentageValue,
  stringValue,
  dateValue,
  dateTimeValue,
  numberValue,
  booleanValue,
  currencyValue,
  measurementValue,
  integerValue,
  enumValue,
])
/** @typedef {z.TypeOf<typeof riskIndicatorValueSchema>} RiskIndicatorValue */

const riskIndicatorOriginInformationSchema = z.object({
  useCase: z.string().nullish(),
  currentValueLink: z.string().nullish(),
  jsonPath: z.string().nullish(),
})

export const riskIndicatorEventEntrySchema = z.object({
  id: z.string(),
  external: z.boolean(),
})

export const riskIndicatorGroupCriticalitySchema = z.object({
  id: z.string(),
  criticalityTypeCode: z.string(),
  createdOn: z.string().nullish(),
})

export const riskIndicatorSchema = z.object({
  id: z.string(),
  riskIndicatorTypeCode: z.string(),
  riskIndicatorName: z.string(),
  displayLink: z.string().nullish(),
  isAutomated: z.boolean(),
  reference: riskIndicatorReferenceSchema.nullish(),
  primaryReference: riskIndicatorReferenceSchema.nullish(),
  currentCriticality: riskIndicatorCriticalitySchema.nullish(),
  value: riskIndicatorValueSchema.nullish(),
  originInformation: riskIndicatorOriginInformationSchema.nullish(),
})

/** @typedef {z.TypeOf<typeof riskIndicatorSchema>} RiskIndicator */

export const riskIndicatorGroupSchema = z.object({
  groupTypeCode: z.string(),
  groupId: z.string(),
  groupDisplayId: z.string(),
  groupName: z.string(),
  statusTypeCode: z.string(),
  statusShortText: z.string().nullish(),
  currentCriticality: riskIndicatorCriticalitySchema.nullish(),
  currentBusinessEvent: z
    .object({
      id: z.string().nullish(),
      displayId: z.string().nullish(),
      name: z.string().nullish(),
    })
    .nullish(),
  riskIndicators: riskIndicatorSchema.array().nullish(),
  nextCheckDate: z.string().nullish(),
  lastUpdated: z.object({
    email: z.string().nullish(),
    lastUpdatedOn: z.string().datetime().nullish(),
  }),
  events: riskIndicatorEventEntrySchema.array().nullish(),
  criticalities: riskIndicatorGroupCriticalitySchema.array().nullish(),
})

/** @typedef {z.TypeOf<typeof riskIndicatorGroupSchema>} RiskIndicatorGroup */

export const riskMonitoringSchema = z.object({
  riskIndicatorGroups: riskIndicatorGroupSchema.array().nullish(),
})

/** @typedef {z.TypeOf<typeof riskMonitoringSchema>} RiskMonitoring */

const detailedRiskIndicatorSchema = z.object({
  id: z.string(),
  riskIndicatorTypeCode: z.string(),
  riskIndicatorName: z.string(),
  displayLink: z.string().nullish(),
  isAutomated: z.boolean(),
  reference: riskIndicatorReferenceSchema.nullish(),
  primaryReference: riskIndicatorReferenceSchema.nullish(),
  initialCriticality: riskIndicatorCriticalitySchema.nullish(),
  currentCriticality: riskIndicatorCriticalitySchema.nullish(),
  maximumCriticality: riskIndicatorCriticalitySchema.nullish(),
  value: riskIndicatorValueSchema.nullish(),
  originInformation: riskIndicatorOriginInformationSchema.nullish(),
})

export const riskIndicatorDetailsSchema = z.object({
  riskIndicators: detailedRiskIndicatorSchema.array(),
})

/** @typedef {z.TypeOf<typeof riskIndicatorDetailsSchema>} RiskIndicatorDetails */

const descriptionsSchema = z.object({
  id: z.string().optional(),
  availableVersions: z
    .array(
      z.object({
        id: z.string(),
        description: z.string(),
        version: z.number(),
        lastUpdated: z.object({
          email: z.string(),
          lastUpdatedOn: z.string(),
        }),
      }),
    )
    .optional(),
})

export const riskIndicatorDescriptionSchema = descriptionsSchema
