import cloneDeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'
import { columnTypes } from 'routes/business-partners/BusinessPartnerGroupExposures'

/**
 * @typedef {object} RiskFigure
 * @property {number} value
 * @property {string} unit
 * @property {string} keyDate
 * @property {string} version
 *
 * @typedef {Object.<string, RiskFigure>} RiskFigures
 *
 * @typedef {object} MemberRiskFigures
 * @property {string} memberId
 * @property {RiskFigures} riskFigures
 *
 * @typedef {object} ClassType
 * @property {string} displayName
 *
 * @typedef {Object.<string, ClassType>} ClassTypes
 *
 * @typedef {object} GroupExposure
 * @property {string} displayName
 * @property {RiskFigures} totals
 * @property {RiskFigures} headRiskFigures
 * @property {MemberRiskFigures[]} members
 *
 * @typedef {object} GroupExposures
 * @property {GroupExposure[]} exposureUnits
 * @property {string} headId
 * @property {ClassTypes} classTypes
 *
 * @typedef {object} TableDataMember
 * @property {string} id
 * @property {RiskFigure} currentExposure
 * @property {RiskFigure} grossFutureExposure
 * @property {RiskFigure} grossFutureExposureLimit
 * @property {RiskFigure} netFutureExposure
 * @property {RiskFigure} settlementRisk
 * @property {RiskFigure} settlementRiskLimit
 *
 * @typedef {object} TableDataTotal
 * @property {boolean} isTotal
 * @property {boolean} isSectionHeader
 * @property {RiskFigure} currentExposure
 * @property {RiskFigure} grossFutureExposure
 * @property {RiskFigure} grossFutureExposureLimit
 * @property {RiskFigure} netFutureExposure
 * @property {RiskFigure} settlementRisk
 * @property {RiskFigure} settlementRiskLimit
 *
 * @typedef {object} ExposureData
 * @property {(TableDataMember | TableDataTotal)[]} tableData
 * @property {RiskFigures} headRiskFigures
 *
 * @typedef {Object.<string, ExposureData} MappedGroupExposures
 *
 * @param {GroupExposures} groupExposure
 * @returns {MappedGroupExposures}
 */
export const mapGroupExposureToTableData = (groupExposure, riskFigureTypes) => {
  if (!groupExposure) return {}

  const { riskFigureCategories = [] } = riskFigureTypes || {}
  const columnTypesToFilterFor = columnTypes

  const appendOrder = (riskFigures) =>
    Object.entries(riskFigures).reduce((acc, [key, value]) => {
      acc[key] = cloneDeep(value)
      const category = riskFigureCategories.find((_category) => _category.name === key)
      if (category) {
        acc[key].ordinalPosition = category.ordinalPosition
      }
      return acc
    }, {})

  const result = groupExposure.exposureUnits.reduce((acc, exposureUnit) => {
    const membersData = exposureUnit.members
      .filter(
        ({ riskFigures }) =>
          !isEmpty(riskFigures) && columnTypesToFilterFor.some((key) => !!riskFigures[key]?.value),
      )
      .map(({ memberId, riskFigures }) => ({
        id: memberId,
        ...riskFigures,
      }))

    const totalRiskFigures = {
      ...exposureUnit.totals,
      isTotal: true,
      isSectionHeader: true,
    }

    return {
      ...acc,
      [exposureUnit.internalName]: {
        displayName: exposureUnit.displayName,
        tableData: [...membersData, totalRiskFigures],
        headRiskFigures: appendOrder(exposureUnit.headRiskFigures),
      },
    }
  }, {})

  return result
}
