import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/shared/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/shared/DisplayCardViewItemView'
import {
  useBooleanToTextFormatter,
  useCustomizableCurrencyFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/shared/useI18n'

const dash = '-'

const CheckSheetRegulatoryRequirementsStatisticsTile = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.check-sheet.regulatory-requirements-statistics',
  })

  const {
    data: {
      securization,
      specialicedLending,
      leveragedTransaction,
      shadowBankingEntity,
      smeIndicator,
      kycLastCheckDate,
      kycNextCheckDate,
    },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const formatBoolean = useBooleanToTextFormatter()
  const formatMoney = useCustomizableCurrencyFormatter()
  const { format: formatDate } = useShortDateFormatter()

  return (
    <>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.securization')}
          value={!isNil(securization) ? formatBoolean(securization) : dash}
          isFirstItem
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.specialiced-lending')}
          value={!isNil(specialicedLending) ? formatBoolean(specialicedLending) : dash}
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.leveraged-transaction')}
          value={!isNil(leveragedTransaction) ? formatBoolean(leveragedTransaction) : dash}
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.shadow-banking-entity')}
          value={!isNil(shadowBankingEntity) ? formatBoolean(shadowBankingEntity) : dash}
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.sme-indicator')}
          value={
            !isNil(smeIndicator) ? formatMoney(smeIndicator.value, smeIndicator.currency) : dash
          }
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.kyc-last-check-date')}
          value={kycLastCheckDate ? formatDate(kycLastCheckDate) : dash}
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('fields.kyc-next-check-date')}
          value={kycNextCheckDate ? formatDate(kycNextCheckDate) : dash}
        />
      </DisplayCardViewItemView>
    </>
  )
}

CheckSheetRegulatoryRequirementsStatisticsTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default CheckSheetRegulatoryRequirementsStatisticsTile
