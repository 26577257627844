import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import BorrowerOverviewAddress from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/borrower/BorrowerOverviewAddress'
import BorrowerOverviewAppreciation from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/borrower/BorrowerOverviewAppreciation'
import BorrowerOverviewFinancialIndicators from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/borrower/BorrowerOverviewFinancialIndicators'
import BorrowerOverviewInformation from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/borrower/BorrowerOverviewInformation'
import BorrowerOverviewRatings from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/borrower/BorrowerOverviewRatings'

const BorrowerOverviewTile = ({ tileId, showConfirmationForLinks }) => (
  <FlexBox direction={FlexBoxDirection.Column}>
    <BorrowerOverviewInformation
      tileId={tileId}
      showConfirmationForLinks={showConfirmationForLinks}
    />
    <BorrowerOverviewAddress tileId={tileId} />
    <BorrowerOverviewRatings tileId={tileId} />
    <BorrowerOverviewFinancialIndicators tileId={tileId} />
    <BorrowerOverviewAppreciation tileId={tileId} />
  </FlexBox>
)

BorrowerOverviewTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
}

export default BorrowerOverviewTile
