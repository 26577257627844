import z from 'zod'
/**
 * @typedef {z.TypeOf<typeof createdOnSchema>} CreatedOn
 */
export const createdOnSchema = z.object({
  createdBy: z.string().nullish(),
  createdOn: z.string().nullish(),
})
export const currentBusinessEventSchema = z.object({
  id: z.string().nullish(),
  displayId: z.string().nullish(),
  name: z.string().nullish(),
})

/** @typedef {z.TypeOf<typeof currentBusinessEventSchema>} CurrentBusinessEvent */

export const forbearanceMeasureVersionSchema = z.object({
  id: z.string(),
  lifecycleStatusTypeCode: z.string(),
  lifecycleStatusTypeShortText: z.string().nullish(),
  version: z.string(),
  customerSigningDate: z.string().nullish(),
  startDate: z.string().nullish(),
  endDate: z.string().nullish(),
  comment: z.string().nullish(),
  createdOn: createdOnSchema.optional(),
  currentBusinessEvent: currentBusinessEventSchema.nullish(),
})
/** @typedef {z.TypeOf<typeof forbearanceMeasureVersionSchema>} ForbearanceMeasureVersion */

export const forbearanceMeasuresSchema = z.object({
  forbearanceMeasures: z.array(
    z.object({
      id: z.string(),
      forbearanceMeasureTypeCode: z.string(),
      forbearanceMeasureShortText: z.string(),
      postfix: z.string(),
      lifecycleStatusTypeCode: z.string(),
      lifecycleStatusTypeShortText: z.string().nullish(),
      startDate: z.string().nullish(),
      endDate: z.string().nullish(),
      createdOn: createdOnSchema.optional(),
      forbearanceMeasureVersions: forbearanceMeasureVersionSchema.array(),
    }),
  ),
})
/** @typedef {z.TypeOf<typeof forbearanceMeasuresSchema>} ForbearanceMeasures */
/** @typedef {ForbearanceMeasures["forbearanceMeasures"][0]} ForbearanceMeasure */
