import { useMemo } from 'react'
import useMultipleRegulatoryInformation from 'components/domains/business-partners/tile/general-information/regulatoryInformation/useMultipleRegulatoryInformation'
import useMultipleArrearApprovalsByBusinessPartnerId from 'hooks/services/arrears/useMultipleArrearApprovalsByBusinessPartnerId'
import mapBaselTwoAutomaticCheckSheetData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/basel-two/automatic-check-sheet/mappers/mapBaselTwoAutomaticCheckSheetData'
import extractDealIdTrancheIdPairs from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/shared/automatic-checksheet/mappers/extractDealIdTrancheIdPairs'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useAuthorityLevelCalculatorAuthorityLevel from 'hooks/services/business-partners/authority-level-calculator/useAuthorityLevelCalculatorAuthorityLevel'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import useMultipleTranchesByBusinessPartner from 'hooks/services/business-partners/tranches/useMultipleTranchesByBusinessPartner'
import useMultipleCustomerSpecificAttributes from 'hooks/services/deals/financing/customer-specific-attributes/useMultipleCustomerSpecificAttributes'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const useBaselTwoAutomaticCheckSheet = (
  { id: eventId, entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: businessPartner,
    isLoading: isBusinessPartnerLoading,
    isError: isBusinessPartnerError,
    error: businessPartnerError,
  } = useBusinessPartnerById(businessPartnerId)

  const businessPartnerName = businessPartner?.fullName

  // as this is reused from the AnnualReviewAutomaticCheckSheet, the hooks still need to be called with arrays
  // therefore an array with one single value, the business partner, is used
  const borrowersData = useMemo(
    () => [{ borrowerId: businessPartnerId, borrowerName: businessPartnerName }],
    [businessPartnerId, businessPartnerName],
  )

  const borrowerIds = [businessPartnerId]

  const {
    isLoading: isMultipleRegulatoryInformationLoading,
    // commented out because for now that endpoint returns a 404
    // even if the request was successful and there simply is no data
    // isError: isMultipleRegulatoryInformationError,
    data: multipleRegulatoryInformationData,
  } = useCombinedQueryResults(useMultipleRegulatoryInformation({ businessPartnerIds: borrowerIds }))

  const {
    isLoading: isMultipleTranchesByBusinessPartnerLoading,
    isError: isMultipleTranchesByBusinessPartnerError,
    data: multipleTranchesByBusinessPartnerData,
  } = useCombinedQueryResults(
    useMultipleTranchesByBusinessPartner({ businessPartnerIds: borrowerIds }),
  )

  const { dealIdTrancheIdPairsByBusinessPartner, allDealIdTrancheIdPairs } =
    extractDealIdTrancheIdPairs({ multipleTranchesByBusinessPartnerData })

  const {
    data: multipleCustomerSpecificAttributes,
    isLoading: isMultipleCustomerSpecificAttributesLoading,
    error: isMultipleCustomerSpecificAttributesError,
  } = useCombinedQueryResults(
    useMultipleCustomerSpecificAttributes({
      dealIdTrancheIdPairs: allDealIdTrancheIdPairs,
    }),
  )

  const {
    data: multipleArrearApprovalsData,
    isLoading: isMultipleArrearApprovalsLoading,
    isError: isMultipleArrearApprovalsError,
  } = useMultipleArrearApprovalsByBusinessPartnerId({ businessPartnerIds: borrowerIds })

  const {
    data: authorityLevelData,
    isLoading: isAuthorityLevelLoading,
    isError: isAuthorityLevelError,
  } = useAuthorityLevelCalculatorAuthorityLevel({ eventId })

  const borrowers = useMemo(
    () =>
      isBusinessPartnerLoading &&
      isMultipleRegulatoryInformationLoading &&
      isMultipleArrearApprovalsLoading &&
      isAuthorityLevelLoading
        ? []
        : mapBaselTwoAutomaticCheckSheetData({
            borrowersData,
            multipleRegulatoryInformationData,
            multipleArrearApprovalsData,
            authorityLevelData,
            multipleCustomerSpecificAttributes,
            dealIdTrancheIdPairsByBusinessPartner,
          }),
    [
      authorityLevelData,
      borrowersData,
      dealIdTrancheIdPairsByBusinessPartner,
      isAuthorityLevelLoading,
      isBusinessPartnerLoading,
      isMultipleArrearApprovalsLoading,
      isMultipleRegulatoryInformationLoading,
      multipleArrearApprovalsData,
      multipleCustomerSpecificAttributes,
      multipleRegulatoryInformationData,
    ],
  )

  const sourceRender = useMemo(() => ({ businessPartnerId }), [businessPartnerId])

  const { isSomeValueError, isSomeValueLoading, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isBusinessPartnerLoading,
      isMultipleRegulatoryInformationLoading,
      isMultipleTranchesByBusinessPartnerLoading,
      isMultipleCustomerSpecificAttributesLoading,
      isMultipleArrearApprovalsLoading,
      isAuthorityLevelLoading,
    ],
    errorValues: [
      isBusinessPartnerError,
      // commented out because for now that endpoint returns a 404
      // even if the request was successful and there simply is no data
      // isMultipleRegulatoryInformationError,
      isMultipleArrearApprovalsError,
      isMultipleTranchesByBusinessPartnerError,
      isMultipleCustomerSpecificAttributesError,
      isAuthorityLevelError,
    ],
    errorDetails: [businessPartnerError],
    tileId,
  })

  const result = useMemo(() => {
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    // When there is no metadata, this thing will stay disabled and therefore loading --> check after the other finished loading
    // whether now the selected conditions are loading.
    if (isBusinessPartnerLoading) {
      return {
        isLoading: true,
        isError: false,
      }
    }

    // Everything has loaded --> return the GKTO relationships
    return {
      isLoading: false,
      isError: false,
      data: {
        borrowers: JSON.stringify(borrowers),
        sourceRender,
        businessPartnerId,
      },
    }
  }, [
    isBusinessPartnerLoading,
    isSomeValueLoading,
    isSomeValueError,
    borrowers,
    sourceRender,
    businessPartnerId,
    error,
  ])

  return result
}

export default useBaselTwoAutomaticCheckSheet
