import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateCovenant = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()

  return useMutation(
    async ({ covenant }) => {
      const response = await post({
        path: `/covenants`,
        body: snakecaseKeys(covenant, { deep: true }),
      })
      return { ...response, data: camelize(response?.data) }
    },
    { onSuccess, onError },
  )
}

export default useCreateCovenant
