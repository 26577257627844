import {
  CheckBox,
  ComboBox,
  ComboBoxItem,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import AssigneeEditField from 'components/domains/business-events-and-tasks/events/creation/AssigneeEditField'
import styles from 'components/domains/business-events-and-tasks/events/creation/EventCreationEditTaskDialog.module.css'
import Dialog, { DialogPrimaryButton, DialogSize } from 'components/ui/dialog/Dialog'
import EditableDatePickerComponent from 'components/ui/input/EditableDatePickerComponent'
import CWPCKEditor from 'components/ui/rich-text-editor/CWPCKEditor'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { addTask, editTask } from 'redux/slices/events/eventCreationSlice'

const maxCharacterAmount = 2000

const EventCreationEditTaskDialog = ({ taskId, isDefaultTask, isOpen, setIsOpen }) => {
  const { parse, localePattern } = useShortDateFormatter()
  const { t: tNoPrefix } = useTranslation('translation')
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.events.creation.dialogs.edit-task',
  })
  const dispatch = useDispatch()
  const { tasks, defaultTasks } = useSelector((state) => state.events.eventCreation)
  const taskToEdit = tasks.find((task) => task.id === taskId)

  const [formErrors, setFormErrors] = useState({})
  const [name, setName] = useState('')
  const [assignee, setAssignee] = useState()
  const [description, setDescription] = useState('')
  const [usedCharacters, setUsedCharacters] = useState(0)
  const [isMandatory, setIsMandatory] = useState(false)
  const [dueDate, setDueDate] = useState('')

  useEffect(() => {
    if (taskToEdit) {
      setName(taskToEdit.info.name)
      setAssignee(taskToEdit.info.assignee)
      setDescription(taskToEdit.info.description)
      setIsMandatory(taskToEdit.info.isMandatory)
      setDueDate(taskToEdit.info.dueDate)
    }
  }, [taskToEdit])

  const handleSave = useCallback(() => {
    const updatedTask = {
      id: taskId,
      info: { name, assignee, description, isMandatory, dueDate },
    }
    if (taskToEdit) {
      dispatch(editTask(updatedTask))
    } else {
      dispatch(addTask({ info: updatedTask.info }))
    }
    setIsOpen(false)
  }, [assignee, description, dispatch, dueDate, isMandatory, name, setIsOpen, taskId, taskToEdit])

  const handleTaskNameChange = useCallback(
    ({ target: { value: newTaskName } }) => {
      const template = defaultTasks.find(({ name: taskName }) => taskName === newTaskName)
      setFormErrors((oldState) => ({ ...oldState, taskName: newTaskName === '' }))

      if (template) {
        setName(template.name)
        setDescription(template.description)
        setIsMandatory(template.isMandatory)
        setDueDate(template.dueDate ?? '')
        return
      }
      setName(newTaskName)
    },
    [defaultTasks],
  )

  const handleIsMandatoryChange = useCallback((event) => {
    setIsMandatory(event.target.checked)
  }, [])

  const handleDueDateChange = useCallback(
    (event) => {
      const date = parse(event.detail.value, localePattern)
      setDueDate(date)
    },
    [localePattern, parse],
  )

  const handleDescriptionChange = useCallback((newDescription) => {
    setDescription(newDescription)
  }, [])

  const handleAssigneeChange = useCallback((newAssignee) => {
    setAssignee(newAssignee)
  }, [])

  const handleOnClear = useCallback(() => {
    setAssignee()
  }, [])

  const onUsedCharactersChanged = useCallback((newUsedCharacters) => {
    setUsedCharacters(newUsedCharacters)
  }, [])

  const isSaveButtonDisabled =
    name === '' || formErrors.taskName || usedCharacters > maxCharacterAmount

  const valueState = useMemo(
    () => (formErrors.taskName ? ValueState.Error : ValueState.None),
    [formErrors?.taskName],
  )

  const onAfterClose = useCallback(() => {
    setFormErrors((oldState) => ({ ...oldState, taskName: false }))
    setIsOpen(false)
  }, [setIsOpen])

  const onInput = useCallback(({ target: { value: newTaskName } }) => {
    setFormErrors((oldState) => ({ ...oldState, taskName: newTaskName === '' }))
  }, [])

  return (
    <Dialog
      headerText={t('title')}
      open={isOpen}
      onAfterClose={onAfterClose}
      primaryButton={
        <DialogPrimaryButton disabled={isSaveButtonDisabled} onClick={handleSave}>
          {tNoPrefix('buttons.save')}
        </DialogPrimaryButton>
      }
      size={DialogSize.L}
    >
      <FlexBox direction={FlexBoxDirection.Column} className={styles.dialogWrapper}>
        <FlexBox direction={FlexBoxDirection.Column}>
          <Label for="task-name-combo-box" required={true}>
            {t('labels.task-name')}
          </Label>
          <FlexBox
            justifyContent={FlexBoxJustifyContent.SpaceBetween}
            alignItems={FlexBoxAlignItems.Center}
          >
            <ComboBox
              id="task-name-combo-box"
              value={name}
              onChange={handleTaskNameChange}
              onInput={onInput}
              readonly={isDefaultTask}
              required
              valueState={valueState}
              valueStateMessage={<Text>{t('error-states.task-name')}</Text>}
            >
              {defaultTasks.map(({ name: taskName }) => (
                <ComboBoxItem key={taskName} text={taskName} />
              ))}
            </ComboBox>
            <CheckBox
              text={t('labels.is-mandatory')}
              checked={isMandatory}
              onChange={handleIsMandatoryChange}
              className={styles.mandatoryCheckBox}
              readonly={isDefaultTask}
            />
          </FlexBox>
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column}>
          <Label for="assignee-input">{t('labels.assignee')}</Label>
          <AssigneeEditField
            assignee={assignee}
            onClear={handleOnClear}
            onAssigneeChange={handleAssigneeChange}
          />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column}>
          <Label for="due-date-picker">{t('labels.due-date')}</Label>
          <EditableDatePickerComponent
            id="due-date-picker"
            value={dueDate ? dueDate : ''}
            editable={true}
            onChange={handleDueDateChange}
          />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column}>
          <Label for="description-input">{t('labels.description')}</Label>
          <CWPCKEditor
            setCurrentContent={handleDescriptionChange}
            currentContent={description ? description : ''}
            maxCharacterAmount={maxCharacterAmount}
            onUsedCharactersChanged={onUsedCharactersChanged}
          />
        </FlexBox>
      </FlexBox>
    </Dialog>
  )
}

EventCreationEditTaskDialog.propTypes = {
  taskId: PropTypes.number.isRequired,
  isDefaultTask: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default EventCreationEditTaskDialog
