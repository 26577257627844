import { TableGrowingMode, Label, TableRowType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DistanceToMarketCell from 'components/domains/markets/DistanceToMarketCell'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'

const AssignableMarketsTable = ({ markets, onLoadMore, handleRowClick, noDataText }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.markets.assignable-markets-dialog.table',
  })
  const { t: tNoPrefix } = useTranslation()

  const columns = useMemo(
    () => [
      {
        title: t('market-name'),
        columnKey: 'marketName',
      },
      {
        title: t('market-type'),
        columnKey: 'marketType',
      },
      {
        title: t('market-property-distance'),
        columnKey: 'marketToPropertyDistance',
      },
    ],
    [t],
  )

  const tableData = useMemo(
    () =>
      markets.markets.map(({ id, info: { name }, marketType: { value }, distanceInfo }) => ({
        rowKey: `assignable-market-${id}`,
        rowProperties: {
          type: TableRowType.Active,
          'market-id': id,
          'market-name': name,
        },
        marketName: {
          cellComponent: <Label>{name}</Label>,
        },
        marketType: {
          cellComponent: <Label>{value}</Label>,
        },
        marketToPropertyDistance: {
          cellComponent: (
            <DistanceToMarketCell
              distance={distanceInfo?.distanceToProperty}
              propertyInMarketArea={distanceInfo?.propertyInMarketArea}
            />
          ),
        },
      })),
    [markets],
  )

  const handleRowClickInternal = useCallback(
    ({ detail: { row: clickedTableRow } }) => {
      const marketId = clickedTableRow.getAttribute('market-id')
      const marketName = clickedTableRow.getAttribute('market-name')
      handleRowClick(marketId, marketName)
    },
    [handleRowClick],
  )

  const renderContent = useMemo(
    () => (
      <SortedTable
        columnDefinitions={columns}
        tableData={tableData}
        additionalTableProperties={{
          stickyColumnHeader: true,
          onRowClick: handleRowClickInternal,
        }}
        toolbarConfig={{
          title: t('toolbar.title'),
          showColumnSelection: false,
        }}
        noDataText={noDataText}
        paginationConfig={{
          growing: tableData.length < markets.total ? TableGrowingMode.Button : undefined,
          growingButtonText: tNoPrefix('buttons.more'),
          growingButtonSubtext: `[${tableData.length} / ${markets.total}]`,
          totalNumberOfItems: markets.total,
          loadMore: onLoadMore,
        }}
      />
    ),
    [
      columns,
      handleRowClickInternal,
      markets.total,
      noDataText,
      onLoadMore,
      t,
      tNoPrefix,
      tableData,
    ],
  )

  return renderContent
}

AssignableMarketsTable.propTypes = {
  markets: PropTypes.object.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  noDataText: PropTypes.string.isRequired,
  handleRowClick: PropTypes.func.isRequired,
}

export default AssignableMarketsTable
