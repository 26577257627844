import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { entityTypes } from 'api/events/status'
import styles from 'components/domains/business-events-and-tasks/BusinessObjectLink.module.css'
import LinkWithId from 'components/domains/business-events-and-tasks/LinkWithId'
import Entity from 'components/ui/data/Entity'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useMultiProperties from 'hooks/services/properties/useMultiProperties'
import paths from 'routes/paths'

const PropertyLink = ({
  propertyUuid,
  customRender,
  errorCallback,
  CustomComponent,
  customComponentProperties,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.business-object-link',
  })
  const { data: properties, isLoading, isError } = useMultiProperties([{ id: propertyUuid }])

  const isErrorOrEmptyResponse = useMemo(() => {
    if (isLoading || isError) {
      return isError
    }
    if (!properties?.properties || properties.properties.length === 0) {
      return true
    }
    return false
  }, [isError, isLoading, properties?.properties])

  useEffect(() => {
    if (isErrorOrEmptyResponse) {
      errorCallback?.({ hasError: true, type: 'PROPERTY' })
    }
  }, [isErrorOrEmptyResponse, errorCallback])

  const errorLabel = <div className={styles.errorLabel}>{t('error.description')}</div>

  const renderContent = () => {
    const { id: propertyId, description: propertyName } = properties.properties[0]
    const link = `/${paths.properties}/${propertyId}`

    if (CustomComponent) {
      return (
        <CustomComponent
          {...customComponentProperties}
          entityType={entityTypes.property}
          name={propertyName}
          id={propertyId}
          link={link}
        />
      )
    }

    switch (customRender) {
      case 'SINGLE_LINE':
        return <LinkWithId href={link} name={propertyName} id={propertyId} />
      case 'CLIPBOARD':
        return (
          <EntityTypeAndIdWithClipboard
            entityType={entityTypes.property}
            name={propertyName}
            id={propertyId}
          />
        )
      default:
        return <Entity name={propertyName} link={link} id={propertyId} openInNewTab />
    }
  }

  if (isErrorOrEmptyResponse && CustomComponent) {
    return <CustomComponent {...customComponentProperties} hasError />
  }

  return (
    <SmallLoadingWrapper
      isError={isErrorOrEmptyResponse}
      isLoading={isLoading}
      error={errorLabel}
      renderContent={renderContent}
    />
  )
}

PropertyLink.propTypes = {
  propertyUuid: PropTypes.string.isRequired,
  customRender: PropTypes.oneOf(['CLIPBOARD', 'SINGLE_LINE']),
  errorCallback: PropTypes.func,
  CustomComponent: PropTypes.func,
  customComponentProperties: PropTypes.object,
}

export default PropertyLink
