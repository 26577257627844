import sortBy from 'lodash.sortby'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const defaultMaxDisplayedItems = 4

const sumUpItemValues = (items) =>
  items.reduce((groupedValue, item) => groupedValue + item.value, 0)

/**
 * @param {Array<{ name: string, value: number }>} chartData
 * @param {() => { getColorForId: (id: string) => string, getGroupedColor: () => string }} useColors
 * @param {{ maxDisplayedItems?: number, aggregrateGroupedValues?: (items: Array<{ name: string, value: number }>) => number }} [options]
 * @return {Array<{ name: string, value: number, color: string }>} the grouped chart data
 */
const useGroupedChartData = (
  chartData,
  useColors,
  { maxDisplayedItems = defaultMaxDisplayedItems, aggregrateGroupedValues = sumUpItemValues } = {},
) => {
  const { t: tCharts } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.property.rent-roll.overview.charts',
  })

  const { getColorForId, getGroupedColor } = useColors()

  return useMemo(() => {
    const sortedChartData = sortBy(chartData, (item) => item.value).reverse()
    const shouldGroupItems = chartData.length > maxDisplayedItems

    const displayedItems = shouldGroupItems
      ? sortedChartData.slice(0, maxDisplayedItems - 1)
      : sortedChartData
    const itemsToGroup = shouldGroupItems ? sortedChartData.slice(maxDisplayedItems - 1) : []

    const displayItemsWithColor = displayedItems.map((item) => ({
      ...item,
      color: getColorForId(item.name),
    }))

    if (itemsToGroup.length) {
      displayItemsWithColor.push({
        name: tCharts('grouped-categories.title', {
          count: itemsToGroup.length,
        }),
        value: aggregrateGroupedValues(itemsToGroup),
        color: getGroupedColor(),
      })
    }

    return displayItemsWithColor
  }, [
    aggregrateGroupedValues,
    chartData,
    getColorForId,
    getGroupedColor,
    maxDisplayedItems,
    tCharts,
  ])
}

export default useGroupedChartData
