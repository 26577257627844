import { Label, WrappingType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/data/ReferenceEntities.module.css'
import ReferenceEntitiesView, { entitiesQuantity } from 'components/ui/data/ReferenceEntitiesView'
import { ReferenceEntityType } from 'components/ui/data/ReferenceEntityType'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useTranches from 'hooks/services/deals/covenants/useTranches'
import { useMultipleDealsByUuidMini } from 'hooks/services/deals/useMultipleDealsByUuidMini'
import { useMultiplePropertiesByUuid } from 'hooks/services/properties/useMultiplePropertiesByUuid'
import { DealContext } from 'routes/deals/DealContext'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'
import paths from 'routes/paths'

const propTypes = {
  /** the type of the entities */
  type: PropTypes.oneOf(Object.values(ReferenceEntityType)),
  /** the IDs of the entities */
  ids: PropTypes.arrayOf(PropTypes.string),
  /** callback function invoked when error occurs  */
  onError: PropTypes.func,
  /** styles to add to the outermost element */
  entityClassName: PropTypes.string,
  /** Custom component that will be rendered after resolving the entities */
  CustomComponent: PropTypes.func,
  /** Custom component properties */
  customComponentProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

/**  maps reference entity to datasource*/
export const referenceEntityToDataSource = [
  { key: ReferenceEntityType.TrancheNewBusiness, dataSource: DATA_SOURCES.NEW_BUSINESS },
  {
    key: ReferenceEntityType.TrancheExistingBusiness,
    dataSource: DATA_SOURCES.EXISTING_BUSINESS,
  },
]

export const mapReferenceEntitiesToViewProps = ({
  ids,
  id,
  name,
  link,
  linkText,
  listItems,
  linkBasePath,
  linkSuffix,
  workingVersion,
}) => {
  if (ids.length === 0) {
    return {
      quantity: entitiesQuantity.none,
    }
  }

  if (ids.length === 1) {
    return {
      quantity: entitiesQuantity.single,
      id,
      name,
      link,
      linkSuffix,
      workingVersion,
    }
  }

  return {
    quantity: entitiesQuantity.multiple,
    linkText,
    listItems,
    link,
    linkSuffix,
    linkBasePath,
    workingVersion,
  }
}

const useReferenceEntities = ({ type, ids, dealUuid, displayId }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.covenants' })

  const trancheBasePath = `/${paths.deals}/${displayId}/${dealDetailPaths.financing}/tranches`
  const businessPartnersBasePath = `/${paths.businessPartners}`
  const dealBasePath = `/${paths.deals}`
  const propertyBasePath = `/${paths.properties}`

  let dataSource = undefined
  if (type === ReferenceEntityType.TrancheNewBusiness) {
    dataSource = DATA_SOURCES.NEW_BUSINESS
  } else if (type === ReferenceEntityType.TrancheExistingBusiness) {
    dataSource = DATA_SOURCES.EXISTING_BUSINESS
  }

  const {
    data: { tranches = [] } = {},
    isLoading: isLoadingTranches,
    isFetching: isFetchingTranches,
    isError: isErrorTranches,
  } = useTranches({
    dealUuid: dealUuid,
    dataSource: dataSource,
    options: {
      enabled:
        (type === ReferenceEntityType.TrancheNewBusiness ||
          type === ReferenceEntityType.TrancheExistingBusiness) &&
        ids.length > 0 &&
        !!dealUuid,
    },
  })

  const mappedTranches = useMemo(() => {
    if (type === ReferenceEntityType.TrancheNewBusiness) {
      return ids.map((id) => {
        const matchingTranche = tranches?.find(({ trancheId }) => id === trancheId) ?? {}
        return {
          id: matchingTranche.displayId,
          name: matchingTranche.trancheName || matchingTranche.displayId,
        }
      })
    }
    if (type === ReferenceEntityType.TrancheExistingBusiness) {
      return ids.map((id) => {
        const matchingTranche =
          tranches?.find(({ externalContractId }) => externalContractId?.includes(id)) ?? {}
        return {
          id: matchingTranche.externalContractId?.[0],
          name: matchingTranche.externalContractId?.[0],
        }
      })
    }
    return []
  }, [ids, tranches, type])

  const {
    data: businessPartnerData,
    isLoading: isLoadingBusinessPartners,
    isFetching: isFetchingBusinessPartners,
    isError: isErrorBusinessPartners,
  } = useBusinessPartnerMiniByIds(ids, {
    enabled: type === ReferenceEntityType.BusinessPartner && ids.length > 0,
  })

  const businessPartners = businessPartnerData?.businessPartnerMinis?.map(({ id, fullName }) => ({
    id,
    name: fullName,
  }))

  const dealResponses = useMultipleDealsByUuidMini(ids, {
    enabled: type === ReferenceEntityType.Deal && ids.length > 0,
  })

  const isLoadingDeals = dealResponses.some(({ isLoading }) => isLoading)
  const isFetchingDeals = dealResponses.some(({ isFetching }) => isFetching)
  const isErrorDeals = dealResponses.some(({ isError }) => isError)

  const deals = dealResponses?.map(({ data }) => ({
    id: data?.dealId,
    name: data?.name,
    workingVersion: data?.workingVersion,
  }))

  const propertyResponses = useMultiplePropertiesByUuid(ids, {
    enabled: type === ReferenceEntityType.Property && ids.length > 0,
  })

  const properties = propertyResponses.map((response) => ({
    id: response.data?.id,
    name: response.data?.description,
  }))

  const isLoadingProperties = propertyResponses.some((response) => response.isLoading)
  const isErrorProperties = propertyResponses.some((response) => response.isError)
  const isFetchingProperties = propertyResponses.some((response) => response.isFetching)

  const calculateLoadingAndErrorState = () => {
    if (
      type === ReferenceEntityType.TrancheNewBusiness ||
      type === ReferenceEntityType.TrancheExistingBusiness
    ) {
      return {
        isLoading: isLoadingTranches && isFetchingTranches,
        isError: !isFetchingTranches && isErrorTranches,
      }
    }
    if (type === ReferenceEntityType.BusinessPartner) {
      return {
        isLoading: isLoadingBusinessPartners && isFetchingBusinessPartners,
        isError: !isFetchingBusinessPartners && isErrorBusinessPartners,
      }
    }
    if (type === ReferenceEntityType.Deal) {
      return {
        isLoading: isLoadingDeals && isFetchingDeals,
        isError: !isFetchingDeals && isErrorDeals,
      }
    }
    if (type === ReferenceEntityType.Property) {
      return {
        isLoading: isLoadingProperties && isFetchingProperties,
        isError: !isLoadingProperties && isErrorProperties,
      }
    }
    return {
      isLoading: false,
      isError: false,
    }
  }

  const calculateData = () => {
    switch (type) {
      case ReferenceEntityType.TrancheNewBusiness:
        return mapReferenceEntitiesToViewProps({
          ids,
          type,
          id: mappedTranches?.[0]?.id,
          name: mappedTranches?.[0]?.name,
          link: `${trancheBasePath}/${mappedTranches?.[0]?.id}`,
          linkSuffix: '?dataSource=newBusiness',
          linkText: t('tranches'),
          listItems: mappedTranches,
          linkBasePath: trancheBasePath,
        })
      case ReferenceEntityType.TrancheExistingBusiness:
        return mapReferenceEntitiesToViewProps({
          ids,
          type,
          id: mappedTranches?.[0]?.id,
          name: mappedTranches?.[0]?.name,
          link: `${trancheBasePath}/${mappedTranches?.[0]?.id}`,
          linkSuffix: '?dataSource=existingBusiness',
          linkText: t('tranches'),
          listItems: mappedTranches,
          linkBasePath: trancheBasePath,
        })
      case ReferenceEntityType.BusinessPartner:
        return mapReferenceEntitiesToViewProps({
          ids,
          type,
          id: businessPartners?.[0]?.id,
          name: businessPartners?.[0]?.name,
          link: `${businessPartnersBasePath}/${businessPartners?.[0]?.id}`,
          linkText: t('business-partners'),
          listItems: businessPartners,
          linkBasePath: businessPartnersBasePath,
        })
      case ReferenceEntityType.Deal:
        return mapReferenceEntitiesToViewProps({
          ids,
          type,
          id: deals?.[0]?.id,
          name: deals?.[0]?.name,
          workingVersion: deals?.[0]?.workingVersion,
          link: `${dealBasePath}/${deals?.[0]?.id}`,
          linkText: t('deals'),
          listItems: deals,
          linkBasePath: dealBasePath,
        })
      case ReferenceEntityType.Property:
        return mapReferenceEntitiesToViewProps({
          ids,
          type,
          id: properties?.[0]?.id,
          name: properties?.[0]?.name,
          link: `${propertyBasePath}/${properties?.[0]?.id}`,
          linkText: t('properties'),
          listItems: properties,
          linkBasePath: propertyBasePath,
        })
      default:
        return {}
    }
  }

  return {
    ...calculateLoadingAndErrorState(),
    data: calculateData(),
  }
}

const emptyArray = []
const noop = () => {}

const ReferenceEntities = ({
  type,
  ids = emptyArray,
  entityClassName,
  onError = noop,
  CustomComponent,
  customComponentProps,
}) => {
  const { deal: { dealUuid, displayId } = {} } = useContext(DealContext) || {}
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.covenants' })

  const {
    isError,
    isLoading,
    data: referenceEntitiesProps,
  } = useReferenceEntities({ type, ids, dealUuid, displayId })

  useEffect(() => {
    if (isError) {
      onError({ hasError: true, type, ids })
    }
  }, [isError, onError, type, ids])

  const renderContent = useCallback(() => {
    if (!CustomComponent) {
      return <ReferenceEntitiesView {...referenceEntitiesProps} entityClassName={entityClassName} />
    }
    return (
      <CustomComponent
        {...customComponentProps}
        {...referenceEntitiesProps}
        entityClassName={entityClassName}
      />
    )
  }, [CustomComponent, customComponentProps, entityClassName, referenceEntitiesProps])

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      error={
        <Label className={styles.errorLabel} wrappingType={WrappingType.Normal}>
          {t('data-error')}
        </Label>
      }
      renderContent={renderContent}
    />
  )
}

ReferenceEntities.propTypes = propTypes

export default ReferenceEntities
