import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useEditMarketInformation = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(
    ({
      marketId,
      marketName,
      marketDescription,
      marketTypeCode,
      marketExternalId,
      marketExternalIdType,
    }) =>
      patch({
        path: `/markets/${marketId}`,
        body: {
          info: {
            name: marketName,
            description: marketDescription,
          },
          market_type: {
            code: marketTypeCode,
          },
          external_id: marketExternalId === '' ? null : marketExternalId,
          external_id_type: marketExternalIdType === '' ? null : marketExternalIdType,
        },
      }),
    mutationOptions,
  )
}

export default useEditMarketInformation
