import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useUpdateAdjustments = ({ dealUuid, onSuccess, onError, ...rest }) => {
  const { put } = useAccessTokenRequest()
  return useMutation(
    async (adjustment) => {
      await put({
        path: `/deals/${dealUuid}/adjustments`,
        body: snakecaseKeys(adjustment, { deep: true }),
      })
    },
    { onSuccess, onError, ...rest },
  )
}
