import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useTaskCreation = (mutateOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(async ({ eventId, name, description, isMandatory, assignee, dueDate }) => {
    const { data } = await post({
      path: `/events/${eventId}/tasks`,
      body: {
        info: {
          name: name,
          description: description,
          is_mandatory: isMandatory,
          assignee: assignee,
          original_due_date: dueDate,
        },
      },
    })
    return data
  }, mutateOptions)
}
