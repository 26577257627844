import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const RETRIES = 3
const currentVersion = 'current'

const useDecisionPaperVersion = ({ eventId, versionNumber }, options) => {
  const version = versionNumber ? versionNumber : currentVersion

  return useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/decision-paper/versions/${version}`,
      useCache: true,
      keys: ['events', eventId, 'decision-paper', 'versions', version],
      options: {
        retry: (failureCount, error) => {
          if (isNotFoundError(error) || isMissingPermissionError(error)) {
            return false
          }
          if (failureCount < RETRIES) {
            return true
          }
          return false
        },
        ...options,
      },
    }),
  )
}

export default useDecisionPaperVersion
