import { Label, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const TableDescriptionSubcomponent = ({ description }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.conditions.table',
  })

  const textOrInputComponent = useMemo(
    () => <Text wrapping>{description ? description : '-'}</Text>,
    [description],
  )
  return (
    <div>
      <Label>{t('label.description')}</Label>
      <div>{textOrInputComponent}</div>
    </div>
  )
}

TableDescriptionSubcomponent.propTypes = {
  description: PropTypes.string,
}

export default TableDescriptionSubcomponent
