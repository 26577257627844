import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import camelize from 'camelize'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { marketValuationConfirmationStatus } from 'api/property/monitoring/propertyMonitoringConfiguration'
import MarketValueConfirmationStatusChangeDialog from 'components/domains/properties/monitoring/market-valuation/MarketValueConfirmationStatusChangeDialog'
import useUpdateMarketValueConfirmation from 'hooks/services/properties/monitoring/useUpdateMarketValueConfirmation'

const MarketValueConfirmationStatusChangeButton = ({
  marketValueConfirmationId,
  eventId,
  statusForChange,
  onStatusChangeSuccess,
  onStatusChangeError,
  translationPrefix,
  buttonDesign,
  maxCommentLength,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: translationPrefix,
  })
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleOpenDialog = () => setIsDialogOpen(true)
  const handleDialogCancel = () => setIsDialogOpen(false)

  const { mutate: updateMarketValueConfirmation, isLoading } = useUpdateMarketValueConfirmation({
    onSuccess: (response) => {
      const responseData = camelize(response?.data)
      setIsDialogOpen(false)
      onStatusChangeSuccess(responseData, eventId)
    },
    onError: () => {
      onStatusChangeError()
    },
  })

  const onChangeStatus = useCallback(
    ({ shouldCompleteBusinessEvent: completeLinkedEvent, comment: updatedComment }) =>
      updateMarketValueConfirmation({
        id: marketValueConfirmationId,
        comment: updatedComment,
        completeLinkedEvent,
        status: statusForChange,
      }),
    [marketValueConfirmationId, updateMarketValueConfirmation, statusForChange],
  )

  return (
    <>
      <Button design={buttonDesign} onClick={handleOpenDialog}>
        {t('button')}
      </Button>
      {isDialogOpen && (
        <MarketValueConfirmationStatusChangeDialog
          isCallLoading={isLoading}
          isOpen={isDialogOpen}
          translationPrefix={translationPrefix}
          onCancel={handleDialogCancel}
          onChangeStatus={onChangeStatus}
          maxCommentLength={maxCommentLength}
        />
      )}
    </>
  )
}

MarketValueConfirmationStatusChangeButton.propTypes = {
  marketValueConfirmationId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  statusForChange: PropTypes.oneOf([
    marketValuationConfirmationStatus.confirmed,
    marketValuationConfirmationStatus.notConfirmed,
  ]).isRequired,
  onStatusChangeSuccess: PropTypes.func.isRequired,
  onStatusChangeError: PropTypes.func.isRequired,
  translationPrefix: PropTypes.string.isRequired,
  buttonDesign: PropTypes.oneOf([ButtonDesign.Positive, ButtonDesign.Negative]),
  maxCommentLength: PropTypes.number,
}

export default MarketValueConfirmationStatusChangeButton
