import { Icon, Input, SuggestionItem, ValueState } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessPartnerSearchDialog from 'components/domains/business-partners/BusinessPartnerSearchDialog'
import styles from 'components/domains/business-partners/search-input/BusinessPartnerSearchInput.module.css'
import { openInputPicker } from 'components/domains/properties/rent-roll/working-version/openInputPicker'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import { useMatchingBusinessPartnersByNameOrId } from 'hooks/services/business-partners/searchBusinessPartners'

const valueHelpPropTypes = {
  onClick: PropTypes.func,
}

/**
 * @typedef {object} valueHelpOverrides
 * @property {import('react').MouseEventHandler<HTMLElement>} onClick
 *
 * @typedef {Omit<Parameters<typeof Icon>[0], keyof PropTypes.InferProps<typeof valueHelpPropTypes>>} restProps
 *
 * @param {Omit<PropTypes.InferProps<typeof valueHelpPropTypes>, keyof valueHelpOverrides> & valueHelpOverrides & restProps} props
 */
const ValueHelpIcon = ({ onClick, ...otherIconProperties }) => (
  <Icon name="value-help" onClick={onClick} {...otherIconProperties} />
)

ValueHelpIcon.propTypes = valueHelpPropTypes

/**
 * @typedef {object} sorting
 * @property {string} columnKey
 * @property {'asc' | 'desc'} orderBy
 */
/**
 * @type {sorting}
 */
const INITIAL_SORTING = { columnKey: 'id', orderBy: 'asc' }

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  className: PropTypes.string,
  preselectedRoles: PropTypes.arrayOf(PropTypes.string.isRequired),
  isRequired: PropTypes.bool,
  withCreateOption: PropTypes.bool,
}

/**
 * @typedef {import('@fioneer/ui5-webcomponents-react').InputDomRef} InputDomRef
 * @typedef {import('@ui5/webcomponents/dist/Input.js').InputSuggestionItemSelectEventDetail} InputSuggestionItemSelectEventDetail
 *
 * @typedef {object} businessPartner
 * @property {string} [name]
 * @property {string} [id]
 * @property {string[]} roles
 * @property {string} [status]
 *
 * @typedef {object} overrides
 * @property {(businessPartner: businessPartner) => void} onChange
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 */
const BusinessPartnerSearchInput = ({
  onChange,
  value,
  className,
  preselectedRoles,
  isRequired,
  withCreateOption,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.business-partner.search-input',
  })

  const inputRef = useRef(
    /** @type {import('@fioneer/ui5-webcomponents-react').InputDomRef | null} */ (null),
  )

  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(true)

  /** @param {sorting} [sortSetting] */
  const translateSortSettingToSortParameter = (sortSetting) =>
    `${sortSetting?.orderBy === 'asc' ? '+' : '-'}${sortSetting?.columnKey}`

  const { data: { fullName: businessPartnerName = value?.name ?? '' } = {} } =
    useBusinessPartnerById(value?.id)

  const { data: { businessPartners = [] } = {} } = useMatchingBusinessPartnersByNameOrId({
    searchKey: businessPartnerName,
    minLength: 3,
    excludeInactive: true,
    roles: preselectedRoles ?? [],
    sort: translateSortSettingToSortParameter(INITIAL_SORTING),
  })

  /** @param {businessPartner[]} selectedBusinessPartners */
  const handleSearchDialogChange = (selectedBusinessPartners) => {
    const { name, id, roles, status } = selectedBusinessPartners[0]
    onChange({ name: name ?? '', id: id ?? '', roles: roles ?? [], status: status ?? '' })
  }

  /** @param {import('@fioneer/ui5-webcomponents-react').Ui5CustomEvent<InputDomRef, never>} e */
  const handleInput = (e) => {
    setShowSuggestions(true)
    onChange({ name: e.target.value, id: '', roles: [] })
  }

  /** @param {import('@fioneer/ui5-webcomponents-react').Ui5CustomEvent<InputDomRef, InputSuggestionItemSelectEventDetail>} event */
  const handleSelectedSuggestion = (event) => {
    const { id, name } = event.detail.item.dataset
    const { roles, status } = businessPartners.find((bp) => bp.id === id) ?? {}
    onChange({ id, name, roles, status })
  }

  const handleSearchDialogClose = () => {
    setIsSearchDialogOpen(false)
    setShowSuggestions(false)
  }

  /** @type {import('react').FocusEventHandler<HTMLElement>} */
  const handleFocus = (e) => {
    e.stopPropagation()
    if (!businessPartnerName?.length) return
    openInputPicker(inputRef)
  }

  const suggestions = businessPartners.map(({ id, name }) => (
    <SuggestionItem key={id} text={name} additionalText={id} data-id={id} data-name={name} />
  ))

  const businessPartnerValueState = useMemo(() => {
    if (!value?.id && (!isEmpty(value?.name) || isRequired)) {
      return ValueState.Error
    }
    return ValueState.None
  }, [isRequired, value?.id, value?.name])

  return (
    <>
      <Input
        id={'business-partner-search-input'}
        ref={inputRef}
        noTypeahead
        showSuggestions
        onSuggestionItemSelect={handleSelectedSuggestion}
        className={[styles.input, className ?? ''].join(' ')}
        value={businessPartnerName}
        valueState={businessPartnerValueState}
        valueStateMessage={<span>{t('hint.empty')}</span>}
        onInput={handleInput}
        onFocus={handleFocus}
        icon={
          <ValueHelpIcon
            className={styles.actionIcon}
            onClick={() => setIsSearchDialogOpen(true)}
          />
        }
      >
        {suggestions && showSuggestions && [...suggestions]}
      </Input>
      <BusinessPartnerSearchDialog
        open={isSearchDialogOpen}
        initialSearch={businessPartnerName}
        onClose={handleSearchDialogClose}
        initialExcludeInactive={true}
        onChange={handleSearchDialogChange}
        withCreateOption={!!withCreateOption}
        initialRoles={preselectedRoles ?? []}
      />
    </>
  )
}

BusinessPartnerSearchInput.propTypes = propTypes

export default BusinessPartnerSearchInput
