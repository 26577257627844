import {
  Button,
  ButtonDesign,
  Select,
  Option,
  FlexBox,
  FlexBoxDirection,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
} from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsHeaderActions.module.css'
import { PERIODS } from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsUtils'

const PropertyRentRollSegmentsHeaderActions = ({
  isMultiProperty,
  inEditMode,
  period,
  onPeriodChange,
  onAdd,
  onDelete,
  selectedSegments,
}) => {
  const { t: tSegments } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.segments',
  })

  const selectPeriodButton = useMemo(
    () => (
      <Select
        classNames={styles.actionsSelect}
        onChange={(e) => onPeriodChange(e?.detail?.selectedOption?.dataset?.value)}
      >
        <Option
          key={PERIODS.ANNUALLY}
          data-value={PERIODS.ANNUALLY}
          selected={period === PERIODS.ANNUALLY}
        >
          {tSegments(PERIODS.ANNUALLY)}
        </Option>
        <Option
          key={PERIODS.MONTHLY}
          data-value={PERIODS.MONTHLY}
          selected={period === PERIODS.MONTHLY}
        >
          {tSegments(PERIODS.MONTHLY)}
        </Option>
      </Select>
    ),
    [period, tSegments, onPeriodChange],
  )

  /* READ MODE  */
  if (!inEditMode) {
    return <div>{selectPeriodButton}</div>
  }

  /* EDIT MODE */
  return (
    <FlexBox
      direction={FlexBoxDirection.Row}
      alignItems={FlexBoxAlignItems.Center}
      justifyContent={FlexBoxJustifyContent.End}
    >
      {!isMultiProperty && (
        <Button
          id={'segment-table-add-action'}
          className={styles.actionsButton}
          onClick={onAdd}
          design={ButtonDesign.Transparent}
        >
          {t('buttons.add')}
        </Button>
      )}
      <Button
        id={'segment-table-delete-action'}
        disabled={selectedSegments.length < 1}
        className={styles.actionsButton}
        onClick={() => {
          if (selectedSegments.length < 1) return
          onDelete()
        }}
        design={ButtonDesign.Transparent}
      >
        {t('buttons.delete')}
      </Button>
      {selectPeriodButton}
    </FlexBox>
  )
}
PropertyRentRollSegmentsHeaderActions.propTypes = {
  isMultiProperty: PropTypes.bool,
  inEditMode: PropTypes.bool,
  period: PropTypes.string,
  onPeriodChange: PropTypes.func,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  selectedSegments: PropTypes.array,
}

export default PropertyRentRollSegmentsHeaderActions
