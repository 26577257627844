import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import get from 'lodash.get'
import isNil from 'lodash.isnil'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { getKeyPathToProceedWith } from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/questionnaireStructure'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/message-box/MessageBox'
import set from 'utils/set'

/**
 * @typedef {Parameters<typeof getKeyPathToProceedWith>[2]} proceedWith
 *
 * @typedef {object} question
 * @property {question[]} [children]
 * @property {string} key
 * @property {proceedWith} proceedWith
 * @property {boolean} [alwaysVisible]
 * @property {boolean} [hint]
 * @property {Parameters<typeof import('components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/questionnaireStructure').getInfoMessageTypesToDisplay>[2]} [infos]
 *
 *
 * @param {object} params
 * @param {object} params.currentAnswers
 * @param {Record<string, any>} params.currentComments
 * @param {question[]} params.baseStructure
 * @param {(changes: string) => void} params.onChange
 */
export const useQuestionnaireHelpers = ({
  currentAnswers,
  currentComments,
  baseStructure = [],
  possibleEndings = [],
  onChange,
}) => {
  const showMessageBox = useShowMessageBox()
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.new-business-general',
  })

  const calculateEnabledQuestions = useCallback(
    /**
     * @param {object} results
     * @param {question[]} structure
     * @param {string[]} walkingKeyPath
     */
    (results, structure, walkingKeyPath = [], previousAcc = {}) =>
      structure.reduce((acc, child) => {
        if (child?.children?.length) {
          acc = calculateEnabledQuestions(
            results,
            child.children,
            [...walkingKeyPath, child.key],
            acc,
          )
        } else {
          const childKeyPath = [...walkingKeyPath, child.key]
          // Activate the present question if alwaysVisible is set (e.g. first question)
          if (child.alwaysVisible) acc = set({ ...acc }, childKeyPath, true)
          // Activate question that comes next based on the given answer
          const keyPath = getKeyPathToProceedWith(childKeyPath, results, child.proceedWith)
          if (keyPath) acc = set({ ...acc }, keyPath, true)
        }
        return acc
      }, previousAcc),
    [],
  )

  /**
   * Builds the data structure to store question results or enabled questions
   *
   *
   * @typedef {{[x: string]: result | boolean?}} result
   *
   * @param {question[]} structure
   * @returns {result}
   *
   * Example result:
   * ```js
   * const result = {
   *   SAQA: {
   *     SAQ1: {
   *       'SAQ1-1': null,
   *     },
   *     SAQ2: {
   *       SAQ2: null,
   *     },
   *   },
   * }
   * ```
   */
  const buildResults = (structure = []) =>
    structure.reduce((acc, child) => {
      const childStructure = child?.children?.length ? buildResults(child.children) : null

      return { ...acc, [child.key]: childStructure }
    }, {})

  /**
   * @param {string[]} degradingKeyPath
   * @param {question} structure
   * @param {string[]} keyPath
   *
   * @returns {ReturnType<typeof getKeyPathToProceedWith>}
   */
  const findProceedWith = (
    degradingKeyPath,
    structure = { children: baseStructure, key: '', proceedWith: [], alwaysVisible: false },
    keyPath = degradingKeyPath,
  ) => {
    if (degradingKeyPath.length && structure.children) {
      return findProceedWith(
        degradingKeyPath.slice(1),
        structure.children.find((child) => child.key === degradingKeyPath[0]),
        keyPath,
      )
    } else {
      return getKeyPathToProceedWith(keyPath, currentAnswers, structure.proceedWith)
    }
  }

  /**
   * Returns whether at least one of the given values was found at least once at some level of the `answerStructure`.
   * This function allows to expand tree items, that have answered / enabled questions.
   *
   * @param {object} answerStructure the structure to evaluate, e.g.
   * ```js
   * {
   *   SAQA: {
   *     SAQ1: {
   *       'SAQ1-1': null,
   *     },
   *     SAQ2: {
   *       SAQ2: true,
   *     },
   *   },
   * }
   * ```
   * @param {boolean[] | ('yes' | 'no' | 'indeterminable')[]} values values to look for
   * @param {boolean} accumulatingResult accumulation of the boolean result (used in recursion)
   * @returns {boolean} whether any matching value was found at some level in the `answerStructure`
   */
  const hasAnswerStructureNestedValue = (answerStructure, values, accumulatingResult = false) => {
    if (!answerStructure) {
      return accumulatingResult
    }

    const topLevelValues = Object.values(answerStructure)
    return (
      topLevelValues?.reduce((acc, value) => {
        if (values.includes(value)) {
          return true
        }
        if (typeof value === 'object') {
          return hasAnswerStructureNestedValue(value, values, acc)
        }
        return acc
      }, false) || accumulatingResult
    )
  }

  /**
   * @param {string[]} keyPath
   * @param {object} questionResults
   */
  const findResult = (keyPath, questionResults) => get(questionResults, keyPath)

  /**
   * Recursive function to reset all answers to questions that follow a first specified question.
   * This allows complex decision trees, where questions can enable questions before them (e.g. question 3 enables question 1)
   *
   * @param {string[]} keyPath defines the question that got the updated answer
   * @param {object} resetChangeSet needs to be a structured copy of the current results
   * @returns {object} result object that has reset all questions that followed the first keyPath
   */
  const resetQuestionsInTree = (keyPath, resetChangeSet) => {
    const proceedingKeyPath = findProceedWith(keyPath)
    const resultOfProceeding = findResult(proceedingKeyPath ?? [], currentAnswers)
    if (!isNil(resultOfProceeding)) {
      set(resetChangeSet, keyPath, null)
      return resetQuestionsInTree(proceedingKeyPath ?? [], resetChangeSet)
    } else {
      return set(resetChangeSet, keyPath, null)
    }
  }

  /**
   * Changes the property at `keyPath` in `currentState` deeply to `answer`
   *
   * Returns a new object
   *
   * @param {Record<string, any>} currentState
   * @param {string[]} keyPath
   * @param {any} answer
   */
  const changeProperty = (currentState, keyPath, answer) => ({
    ...set(currentState, keyPath, answer),
  })

  /**
   * Calls the recursive reset-function, applies the updated answer to the first question
   *
   * @param {string[]} keyPath defines the question that got the updated answer
   * @param {boolean} updatedAnswer updated answer of the question specified in keyPath
   */
  const resetFollowUpQuestions = (keyPath, updatedAnswer) => {
    const resultsWithoutWrongAnswers = resetQuestionsInTree(
      keyPath,
      structuredClone(currentAnswers),
    )
    return changeProperty(resultsWithoutWrongAnswers, keyPath, updatedAnswer)
  }

  /**
   * @param {string[]} keyPath
   * @param {proceedWith} proceedWith
   */
  const checkNextQuestionAlreadyAnswered = (keyPath, proceedWith) => {
    const keyPathSucceedingQuestion = getKeyPathToProceedWith(keyPath, currentAnswers, proceedWith)
    return (
      !!keyPathSucceedingQuestion && !isNil(findResult(keyPathSucceedingQuestion, currentAnswers))
    )
  }

  const emitChange = useCallback(
    ({ answers = currentAnswers, comments = currentComments, result }) => {
      if (result === undefined) {
        onChange(JSON.stringify({ answers, comments }))
      }
      onChange(JSON.stringify({ answers, result, comments }))
    },
    [currentAnswers, currentComments, onChange],
  )

  const getActiveEnding = useCallback(
    (answers) => {
      const availableEndingsKeys = possibleEndings.map(({ key }) => key)
      return Object.entries(calculateEnabledQuestions(answers, baseStructure)).find(
        ([key, value]) => availableEndingsKeys.includes(key) && value === true,
      )?.[0]
    },
    [baseStructure, calculateEnabledQuestions, possibleEndings],
  )

  const getQuestionnaireResult = useCallback(
    (answers) => {
      const ending = possibleEndings.find(({ key }) => key === getActiveEnding(answers))
      return ending?.checkResultName && typeof ending?.checkResultStatus === 'boolean'
        ? { [ending.checkResultName]: ending.checkResultStatus }
        : undefined
    },
    [getActiveEnding, possibleEndings],
  )

  /**
   * Reset all proceeding questions by recursively getting all following questions / answers
   *
   * @param {string[]} keyPath defines the question that got the updated answer
   * @param {boolean} answer updated answer of the question specified in keyPath
   */
  const onConfirmResetQuestions = (keyPath, answer) =>
    emitChange({
      answers: resetFollowUpQuestions(keyPath, answer),
      result: getQuestionnaireResult(resetFollowUpQuestions(keyPath, answer)),
    })

  /**
   * @param {string[]} keyPath
   * @param {boolean} answer
   * @param {proceedWith} proceedWith
   * @param {import('react').RefObject<import('@fioneer/ui5-webcomponents-react').RadioButtonDomRef>} currentlySelectedRadioButtonRef
   */
  const handleOnChange = (keyPath, answer, proceedWith, currentlySelectedRadioButtonRef) => {
    if (!currentAnswers) {
      const initialAnswers = buildResults(baseStructure)

      return emitChange({
        answers: changeProperty(initialAnswers, keyPath, answer),
        result: getQuestionnaireResult(changeProperty(initialAnswers, keyPath, answer)),
      })
    }
    if (checkNextQuestionAlreadyAnswered(keyPath, proceedWith)) {
      // Upon click, the UI5 RadioButton immediately marks that button `checked`. `onChange`, however, is only executed
      // afterwards. This leads to the wrong button being checked when the user cancels the confirmation modal. Hence,
      // we check the radio button that was previously selected again here and rely on the rerender upon update of the
      // current content when the user confirms the update for marking the newly selected button as `checked`.
      if (currentlySelectedRadioButtonRef?.current) {
        currentlySelectedRadioButtonRef.current.checked = true
      }
      // open confirmation dialog, reset following questions only if confirmed
      return showMessageBox(
        {
          type: MessageBoxTypes.Confirm,
          titleText: t('reset.title'),
          children: t('reset.message'),
          actions: [
            <Button
              key="button-confirm"
              design={ButtonDesign.Emphasized}
              onClick={() => onConfirmResetQuestions(keyPath, answer)}
            >
              {t('reset.confirm-button')}
            </Button>,
            MessageBoxActions.Cancel,
          ],
        },
        document.body,
      )
    }
    return emitChange({
      answers: changeProperty(currentAnswers, keyPath, answer),
      result: getQuestionnaireResult(changeProperty(currentAnswers, keyPath, answer)),
    })
  }

  /**
   * Handles updates on comments (user-provided additional information) of a question.
   * @param {string[]} keyPath the key path identifying the question
   * @param {object} questionComment the question comments
   */
  const handleCommentChange = (keyPath, questionComment) => {
    emitChange({ comments: changeProperty(currentComments ?? {}, keyPath, questionComment) })
  }

  return {
    calculateEnabledQuestions,
    buildResults,
    hasAnswerStructureNestedValue,
    findResult,
    handleOnChange,
    handleCommentChange,
  }
}
