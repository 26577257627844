import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useInsuranceTypes = () =>
  useCamelizedResponse(
    useRequest({
      path: `/properties/insurance-types`,
      useCache: true,
      keys: ['property-insurance-type'],
      options: {
        retry: 0,
      },
      translated: true,
    }),
  )
