import { useTranslation } from 'react-i18next'
import PropertyRentRollOverviewWaultCardContent from 'components/domains/properties/rent-roll/overview/wault/PropertyRentRollOverviewWaultCardContent'
import Card from 'components/ui/card/Card'
import CardHeaderWithMetrics from 'components/ui/card/CardHeaderWithMetrics'
import { useNumberFormatter } from 'hooks/i18n/useI18n'
import useCurrentRentRollOverviewPropertyKpis from 'hooks/services/properties/kpis/useCurrentRentRollOverviewPropertyKpis'

const PropertyRentRollOverviewWaultCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.wault',
  })

  const {
    isLoading: isLoadingPropertyKpis,
    isError: isErrorPropertyKpis,
    data: propertyKpiData,
  } = useCurrentRentRollOverviewPropertyKpis()

  const setValueOrUndefined = (value) => (value ? value : undefined)

  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const primaryMetric = {
    label: t('break'),
    value: setValueOrUndefined(formatNumber(propertyKpiData?.waultToBreakInYears)),
    unit: t('unit'),
  }

  const expiryDate = propertyKpiData?.waultToExpiryInYears
    ? formatNumber(propertyKpiData.waultToExpiryInYears) + ' ' + t('unit')
    : undefined
  const secondaryMetrics = [{ label: t('expiry'), value: expiryDate }]

  return (
    <Card
      header={
        <CardHeaderWithMetrics
          title={t('title')}
          primaryMetric={primaryMetric}
          secondaryMetrics={secondaryMetrics}
          referenceDate={propertyKpiData?.keyDate}
          isLoading={isLoadingPropertyKpis}
          isError={isErrorPropertyKpis}
        />
      }
    >
      <PropertyRentRollOverviewWaultCardContent />
    </Card>
  )
}

export default PropertyRentRollOverviewWaultCard
