import sortBy from 'lodash.sortby'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useUsageTypeColors } from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/UsageTypeColorContext'

export const MAX_DISPLAYED_USAGE_TYPES = 4
export const GROUPED_USAGE_TYPES_ID = 'Other'

/**
 * takes historical KPI data from the `useHistoricalRentRollKpisByUsageType` hook
 * and selector functions to get metric values from usage type KPIs and property KPIs
 * and transforms the KPI data into props for a cartesian chart component
 *
 * @typedef {object} RentRollKpisByUsageType
 * @property {string} keyDate
 * @property {object} kpis
 *
 * @typedef {RentRollKpisByUsageType[]} HistoricalRentRollKpisByUsageType
 *
 * @typedef {object} ChartDataPoint
 * @property {number} date
 *
 * @typedef {object} ChartProps
 * @property {ChartDataPoint[]} chartData
 * @property {{ id: string, name: string, color: string }[]} usageTypes
 *
 * @param {HistoricalRentRollKpisByUsageType} data
 * @param {object} options
 * @param {function} options.getMetricValueForUsageTypeKpi
 * @param {function} options.getPropertyMetrics
 * @param {function} options.aggregateGroupedUsageTypeValues
 * @returns {ChartProps}
 */
const useHistoricalUsageTypeChartData = (
  data,
  { getMetricValueForUsageTypeKpi, getPropertyMetrics, aggregateGroupedUsageTypeValues },
) => {
  const { getColorForId, getGroupedColor } = useUsageTypeColors()

  const { t } = useTranslation('decisionPaper')

  return useMemo(() => {
    if (!data || data?.length === 0)
      return {
        chartData: [],
        usageTypes: [],
      }

    const getNameForUsageType = (usageTypeKpi) =>
      usageTypeKpi?.segmentUsageTypeName ?? usageTypeKpi?.segmentUsageTypeCode

    const displayedUsageTypes = (() => {
      const usageTypesWithNonEmptyMetric = new Map()

      for (const rentRoll of data) {
        for (const usageTypeKpi of rentRoll.kpis.segmentUsageTypeRentRollKpis || []) {
          if (getMetricValueForUsageTypeKpi(usageTypeKpi) > 0) {
            usageTypesWithNonEmptyMetric.set(
              usageTypeKpi.segmentUsageTypeCode,
              getNameForUsageType(usageTypeKpi),
            )
          }
        }
      }

      if (usageTypesWithNonEmptyMetric.size <= MAX_DISPLAYED_USAGE_TYPES) {
        return usageTypesWithNonEmptyMetric
      }

      // if there are more usage types than what can be displayed,
      // use the ones with the highest metric value from the most
      // current rent roll
      const newestRentRoll = data.at(-1)

      const newestRentRollUsageTypeKpis = newestRentRoll.kpis.segmentUsageTypeRentRollKpis.filter(
        (usageTypeKpis) => usageTypesWithNonEmptyMetric.has(usageTypeKpis.segmentUsageTypeCode),
      )

      const newestRentRollUsageTypeKpisSortedByMetricDesc = sortBy(
        newestRentRollUsageTypeKpis,
        getMetricValueForUsageTypeKpi,
      ).reverse()

      const displayedRentRollUsageTypeEntries = newestRentRollUsageTypeKpisSortedByMetricDesc
        .slice(0, MAX_DISPLAYED_USAGE_TYPES - 1)
        .map((usageTypeKpi) => [
          usageTypeKpi.segmentUsageTypeCode,
          getNameForUsageType(usageTypeKpi),
        ])

      const groupedUsageTypesCount =
        usageTypesWithNonEmptyMetric.size - displayedRentRollUsageTypeEntries.length

      const groupedUsageTypesEntry = [
        GROUPED_USAGE_TYPES_ID,
        t('pages.property.rent-roll.overview.charts.grouped-categories.title', {
          count: groupedUsageTypesCount,
        }),
      ]

      displayedRentRollUsageTypeEntries.push(groupedUsageTypesEntry)

      return new Map(displayedRentRollUsageTypeEntries)
    })()

    // we pre-fill all displayed usage types with zero values, because the area chart component
    // does not display correctly if field values are missing in some data points
    const initialUsageTypeMetrics = Object.fromEntries(
      [...displayedUsageTypes.keys()].map((key) => [key, 0]),
    )

    const getUsageTypeMetrics = (rentRollKpisByUsageType) => {
      const otherValues = []
      const usageTypeMetrics = rentRollKpisByUsageType?.reduce(
        (vacancyByUsageType, usageTypeKpi) => {
          const usageTypeId = usageTypeKpi.segmentUsageTypeCode
          const usageTypeMetricValue = getMetricValueForUsageTypeKpi(usageTypeKpi)
          const shouldDisplayUsageType = displayedUsageTypes.has(usageTypeId)

          if (shouldDisplayUsageType) {
            vacancyByUsageType[usageTypeId] = usageTypeMetricValue
          } else if (usageTypeMetricValue > 0) {
            otherValues.push(usageTypeMetricValue)
          }

          return vacancyByUsageType
        },
        { ...initialUsageTypeMetrics },
      )
      // add usage type value to "others" data point, aggregation function needs to be provided
      // because percentages / money amounts need to be aggregated differently
      if (otherValues.length) {
        usageTypeMetrics[GROUPED_USAGE_TYPES_ID] = aggregateGroupedUsageTypeValues(otherValues)
      }
      return usageTypeMetrics
    }

    const chartData = data.map(({ keyDate, kpis }) => ({
      date: new Date(keyDate).valueOf(),
      ...getPropertyMetrics(kpis),
      ...getUsageTypeMetrics(kpis.segmentUsageTypeRentRollKpis),
    }))

    const usageTypes = Array.from(displayedUsageTypes.entries()).map(([id, name]) => ({
      id,
      name,
      color: id === GROUPED_USAGE_TYPES_ID ? getGroupedColor() : getColorForId(id),
    }))
    return { chartData, usageTypes }
  }, [
    aggregateGroupedUsageTypeValues,
    data,
    getColorForId,
    getGroupedColor,
    getMetricValueForUsageTypeKpi,
    getPropertyMetrics,
    t,
  ])
}

export default useHistoricalUsageTypeChartData
