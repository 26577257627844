import { BusyIndicator, BusyIndicatorSize } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/screens/LoadingScreen.module.css'

const LoadingScreen = ({ title, description, size = BusyIndicatorSize.Large, className }) => (
  <div className={`${styles.centered} ${className || ''}`.trim()}>
    <BusyIndicator active title={title} text={description} size={size} />
  </div>
)

LoadingScreen.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
}

export default LoadingScreen
