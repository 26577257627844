import * as UI5React from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import { Children, useEffect, useId, useRef } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import ErrorBoundary from 'components/ui/errors/ErrorBoundary'
import styles from 'components/ui/layout/CWPLayout.module.css'
import Masonry from 'lib/masonry/masonry'

const propTypes = {
  children: PropTypes.node,
  overview: PropTypes.bool,
  sixColumns: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.shape({}),
}

/**
 * Responsive layout to be used on all CWP pages.
 *
 * Use the `overview` prop to have a three column layout. That should be used on overview pages.
 *
 * Use the `sixColumns` prop to have a six column layout.
 * This is needed if you need a 50%:50% and 33%:67% distribution at the same time. (e.g. DealFinancingTrancheDetails.jsx)
 *
 * Use `CWPLayout.Aligner` to align elements by their top edges.
 *
 * Example:
 * ```jsx
 * <SomeCard />
 * <CWPLayout.Aligner />
 * <FirstAlignedCard />
 * <SecondAlignedCard />
 * ```
 *
 * Use `CWPLayout.Full` to stretch an element over the whole width, no matter what the amount of columns is.
 *
 * Example:
 * ```jsx
 * <CWPLayout.Full>
 *   <SomeCard />
 * </CWPLayout.Full>
 * ```
 *
 * Use `CWPLayout.TwoThirds` to stretch a card over two columns when using the `overview` or `sixColumns` mode.
 *
 * Example:
 * ```jsx
 * <CWPLayout.TwoThirds>
 *   <SomeCard />
 * </CWPLayout.TwoThirds>
 * ```
 *
 * There is also `CWPLayout.OneThird`. Specifying this is only necessary in `sixColumns` layout,
 * but not in `overview` (but you can use it anyway).
 *
 * Use `CWPLayout.Half` to stretch a card over two and a half columns in the `overview` or `sixColumns` mode.
 *
 * Example:
 * ```jsx
 * <CWPLayout.Half>
 *   <SomeCard />
 * </CWPLayout.Half>
 * ```
 *
 * **When you need to add the responsiveness yourself:**
 *
 * Use the `--layout-columns-<x>` variables and `calc()` statements. The value of these variables is `1`
 * if there are exactly `x` columns, `0` otherwise
 * [Explanation](https://css-tricks.com/dry-switching-with-css-variables-the-difference-of-one-declaration/)
 *
 * Keep in mind to also include the gap (`--sapUiSmallSpace`) between cards in your calculations.
 *
 * Example:
 * ```css
 * .card {
 *   width:
 *     calc(
 *       var(--layout-columns-1) * 100% +
 *       var(--layout-columns-2) * (50% - (var(--sapUiSmallSpace) / 2)) +
 *       var(--layout-columns-3) * (33.3333% - var(--sapUiSmallSpace) * 2 / 3)
 *     )
 * }
 * ```
 * @typedef {object} overrides
 * @property {import('react').CSSProperties} [style]
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 */
const CWPLayout = ({ children, overview, sixColumns, className, style }) => {
  const masonry = useRef(/** @type {Masonry | undefined} */ (undefined))
  const containerRef = useRef(/** @type {HTMLDivElement?} */ (null))
  const id = useId()

  useEffect(() => {
    masonry.current = new Masonry(`[data-id="${id}"].${styles.gridLayout}`, {
      columnWidth: `[data-id="${id}"] > .${styles.gridSizer}`,
      gutter: `[data-id="${id}"] > .${styles.gridGutter}`,
      percentPosition: true,
      itemSelector: `[data-id="${id}"].${styles.gridLayout} > :not(:is(.${styles.gridSizer}, .${styles.gridGutter}))`,
      transitionDuration: 0,
    })
  }, [id])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      // HINT: the entries contain the resized elements,
      //       but we can't be sure they're actually masonry elements
      //    => layout all elements
      requestAnimationFrame(() => {
        masonry.current?.layout?.()
      })
    })

    const mutationObserver = new MutationObserver(() => {
      // HINT: the mutations contain the added / removed elements,
      //       but we can't be sure they're actually masonry elements
      //    => reload the items from the masonry
      masonry.current?.reloadItems?.()

      resizeObserver.disconnect()

      masonry.current?.getItemElements?.()?.forEach((item) => {
        item && resizeObserver.observe(item)
      })

      // HINT: also perform a re-layout, since there might not be a resize event
      requestAnimationFrame(() => {
        masonry.current?.layout?.()
      })
    })

    containerRef.current && mutationObserver.observe(containerRef.current, { childList: true })

    // HINT: initial layout and observing resizes
    requestAnimationFrame(() => {
      resizeObserver.disconnect()
      masonry.current?.reloadItems?.()

      masonry.current?.getItemElements?.()?.forEach((item) => {
        item && resizeObserver.observe(item)
      })

      masonry.current?.layout?.()
    })

    return () => {
      resizeObserver.disconnect()
      mutationObserver.disconnect()
    }
  }, [])

  return (
    <div
      className={compact([
        overview && styles.overview,
        sixColumns && styles.sixColumns,
        styles.gridLayout,
        className,
      ]).join(' ')}
      style={style}
      data-id={id}
      ref={containerRef}
    >
      <div className={styles.gridSizer} />
      <div className={styles.gridGutter} />
      {Children.map(children, (child, index) => (
        <ErrorBoundary fallbackProps={{ Wrapper: UI5React.Card }} key={index}>
          {child}
        </ErrorBoundary>
      ))}
    </div>
  )
}

CWPLayout.Aligner = () => <div className={styles.aligner} />
CWPLayout.Aligner.displayName = 'CWPLayout.Aligner'

CWPLayout.OneThird = ({ children }) => (
  <div className={styles.oneThird}>
    <ErrorBoundary fallbackProps={{ Wrapper: UI5React.Card }}>{children}</ErrorBoundary>
  </div>
)
CWPLayout.OneThird.displayName = 'CWPLayout.OneThird'

CWPLayout.OneThird.propTypes = {
  children: PropTypes.node,
}

CWPLayout.TwoThirds = ({ children }) => (
  <div className={styles.twoThirds}>
    <ErrorBoundary fallbackProps={{ Wrapper: UI5React.Card }}>{children}</ErrorBoundary>
  </div>
)
CWPLayout.TwoThirds.displayName = 'CWPLayout.TwoThirds'

CWPLayout.TwoThirds.propTypes = {
  children: PropTypes.node,
}

CWPLayout.Half = ({ children }) => (
  <div className={styles.half}>
    <ErrorBoundary fallbackProps={{ Wrapper: UI5React.Card }}>{children}</ErrorBoundary>
  </div>
)
CWPLayout.Half.displayName = 'CWPLayout.Half'

CWPLayout.Half.propTypes = {
  children: PropTypes.node,
}

CWPLayout.Full = ({ children }) => (
  <div className={styles.full}>
    <ErrorBoundary fallbackProps={{ Wrapper: UI5React.Card }}>{children}</ErrorBoundary>
  </div>
)
CWPLayout.Full.displayName = 'CWPLayout.FullWidth'

CWPLayout.Full.propTypes = {
  children: PropTypes.node,
}

CWPLayout.propTypes = propTypes

export default CWPLayout
