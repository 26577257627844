import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteCondition = (mutateOptions) => {
  const { delete: deleteCall } = useAccessTokenRequest()
  return useMutation(
    ({ conditionId, conditionType }) =>
      deleteCall({
        path: `/conditions/${conditionType}/${conditionId}`,
      }),
    mutateOptions,
  )
}

export default useDeleteCondition
