import PropTypes from 'prop-types'
import { useContext } from 'react'
import { CurrentRentRollContext } from 'components/domains/properties/rent-roll/comparison/constants'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useRentRoll } from 'hooks/services/properties/useRentRoll'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyRentRollComparisonCardLoadingWrapper = ({ children }) => {
  const { property } = useContext(PropertyContext)
  const {
    isLoading: isLoadingRentRoll,
    isError: isErrorRentRoll,
    data: currentRentRoll,
  } = useRentRoll(property.uuid)

  return (
    <RequestStateResolver
      isLoading={isLoadingRentRoll}
      isError={isErrorRentRoll}
      center={true}
      errorToDisplay={<ErrorDataUnavailableInContent />}
      renderContent={() => (
        <CurrentRentRollContext.Provider value={currentRentRoll}>
          {children}
        </CurrentRentRollContext.Provider>
      )}
    />
  )
}

PropertyRentRollComparisonCardLoadingWrapper.propTypes = {
  children: PropTypes.node,
}

export default PropertyRentRollComparisonCardLoadingWrapper
