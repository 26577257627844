import { useQuery } from '@tanstack/react-query'
import camelize from 'camelize'
import { useMemo } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import documentTypeSchema from 'hooks/services/documents/documentTypeSchema'

/**
 * Retrieves document types for the given entity references.
 *
 * @typedef {object} EntityRef
 * @property {string?} [id] - The ID of the entity.
 * @property {string?} [type] - The type of the entity. Use `DocumentTypes`.
 *
 * @param {object} params
 * @param {EntityRef[]} params.entityRefs
 */

const useGetDocumentTypes = ({ entityRefs }) => {
  const { post } = useAccessTokenRequest()

  const result = useQuery(
    ['documents', 'types', ...entityRefs.map((entityRef) => entityRef.id)],
    async () => {
      const { data } = await post({
        path: '/documents/types',
        body: {
          entity_refs: entityRefs,
        },
      })
      return camelize(data)
    },
    {
      retry: false,
      enabled: !!entityRefs && entityRefs.length > 0,
    },
  )

  return useMemo(
    () => ({
      ...result,
      data: documentTypeSchema.parse(result.data),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [result.data, result.isLoading, result.isError],
  )
}

export default useGetDocumentTypes
