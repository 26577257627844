import { useMemo } from 'react'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useMultiplePortfolioAppreciation from 'hooks/services/deals/portfolio/appreciation/useMultiplePortfolioAppreciation'

const usePropertyAssessments = ({ entityRef: { entityId: businessPartnerId } }, tileId) => {
  const {
    data: { selectedDeal, dealsData } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const { selectedDealDisplayId } = selectedDeal ?? {}
  const dealUuids = dealsData?.map((dealData) => dealData.dealUuid)

  const {
    data: multiplePortfolioAppreciationData,
    isMultiplePortfolioLoading,
    isMultiplePortfolioError,
    error: multipleCustomAssessmentsVersionsError,
  } = useMultiplePortfolioAppreciation({
    dealUuids,
  })

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isMultiplePortfolioLoading, isAnnualReviewDealsLoading],
    errorValues: [isMultiplePortfolioError, isAnnualReviewDealsError],
    errorDetails: [annualReviewDealsError, multipleCustomAssessmentsVersionsError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueLoading || isSomeValueError) {
      return { isLoading: isSomeValueLoading, isError: isSomeValueError, error }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        multiplePropertyAssessments: multiplePortfolioAppreciationData.map((property, index) => ({
          propertyAssessments: property,
          dealUuid: dealUuids[index],
        })),
        sourceRender: {
          dealDisplayId: selectedDealDisplayId,
        },
        dealsData,
      },
    }
  }, [
    isSomeValueLoading,
    isSomeValueError,
    multiplePortfolioAppreciationData,
    selectedDealDisplayId,
    dealsData,
    error,
    dealUuids,
  ])
}

export default usePropertyAssessments
