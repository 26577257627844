import {
  DateRangePicker,
  FilterGroupItem,
  Input,
  Option,
  Select,
} from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { entityTypes } from 'api/events/status'
import AssigneeFilter from 'components/domains/business-events-and-tasks/events/filterbar/AssigneeFilter'
import EventBusinessObjectFilter from 'components/domains/business-events-and-tasks/events/filterbar/EventBusinessObjectFilter'
import EventStatusComboBox from 'components/domains/business-events-and-tasks/events/filterbar/EventStatusComboBox'
import EventTypeSelect from 'components/domains/business-events-and-tasks/events/filterbar/EventTypeSelect'
import DashboardFilterBar from 'components/ui/page/DashboardFilterBar'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const parseDueDateRange = (dueDateRange) => ({
  startDate: dueDateRange.split(' - ')[0]?.trim(),
  endDate: dueDateRange.split(' - ')[1]?.trim(),
})

const filterOptions = {
  searchFilter: { visibleOnBar: true },
  eventType: { visibleOnBar: true },
  businessObject: { visibleOnBar: true },
  businessObjectType: { visibleOnBar: true },
  assigneeId: { visibleOnBar: true },
  originalDueDateRange: { visibleOnBar: true },
  currentDueDateRange: { visibleOnBar: true },
  status: { visibleOnBar: true },
}

const defaultVisibleFilters = Object.keys(filterOptions) //default is to show all filters

const propTypes = {
  isDialogOpen: PropTypes.bool,
  onGo: PropTypes.func,
  initialFilterValues: PropTypes.shape({
    searchValue: PropTypes.string,
    assigneeId: PropTypes.string,
    currentDueDateRange: PropTypes.string,
    originalDueDateRange: PropTypes.string,
    status: PropTypes.arrayOf(PropTypes.string),
    entityType: PropTypes.string,
    entityId: PropTypes.string,
    eventCode: PropTypes.string,
  }),
  visibleFilters: PropTypes.arrayOf(PropTypes.string),
  readOnlyFilters: PropTypes.arrayOf(PropTypes.string),
  customEventCodeFilterList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
    }),
  ),
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const EventSearchDialogFilterBar = ({
  isDialogOpen = false,
  onGo,
  initialFilterValues = {},
  readOnlyFilters = [],
  visibleFilters = defaultVisibleFilters,
  customEventCodeFilterList = [],
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.events.search.dialog.filter-bar',
  })
  const { t: tNoKeyPrefix } = useTranslation()
  const { localePattern, format, parse } = useShortDateFormatter()

  const [filterParams, setFilterParams] = useState(initialFilterValues)
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false)

  // Sometimes initialFilterValues is undefined on first render, thus setting an incorrect filterParams state.
  // Set the filter params to the initialFilterValues only if there are no existing filter params yet
  useEffect(() => {
    if (isEmpty(filterParams) && !isNil(initialFilterValues)) {
      setFilterParams(initialFilterValues)
    }
  }, [filterParams, initialFilterValues])

  // Set and reset filter params when dialog opens/closes
  useEffect(() => {
    setFilterParams(initialFilterValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen])

  const handleKeyDown = (event) => {
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && !isFilterDialogOpen) {
      event.stopPropagation()
      onGo(filterParams)
    }
  }

  const onBusinessObjectChange = (event) => {
    if (event === '') {
      setFilterParams({
        ...filterParams,
        entityType: '',
        entityId: '',
        entityName: '',
      })
    } else {
      setFilterParams({
        ...filterParams,
        entityType: event.entityType,
        entityId: event.entityId,
        entityName: event.entityName,
      })
    }
  }

  const formatDateRangePickerValue = (dueDateRange) => {
    if (!dueDateRange) return ''
    const { startDate, endDate } = parseDueDateRange(dueDateRange)
    return `${format(startDate)} - ${format(endDate)}`
  }

  const possibleFilterBarItems = {
    searchFilter: (
      <FilterGroupItem label={t('search')} key="searchFilter">
        <Input
          id="event-search-input"
          placeholder={t('search')}
          value={filterParams?.searchValue ?? ''}
          onInput={({ target: { value: searchValue } }) => {
            setFilterParams({ ...filterParams, searchValue })
          }}
          onKeyDown={handleKeyDown}
          showClearIcon
        />
      </FilterGroupItem>
    ),
    businessObject: (
      <FilterGroupItem key="businessObject" label={t('event-business-object')}>
        <EventBusinessObjectFilter
          disabled={readOnlyFilters.includes('businessObject')}
          onBusinessObjectChange={onBusinessObjectChange}
          businessObjectName={filterParams?.entityName}
        />
      </FilterGroupItem>
    ),
    businessObjectType: (
      <FilterGroupItem key="businessObjectType" label={t('business-object-type')}>
        <Select
          id="event-search-entity-type-select"
          disabled={
            readOnlyFilters.includes('businessObject') ||
            readOnlyFilters.includes('businessObjectType')
          }
          onChange={({
            detail: {
              selectedOption: { value: entityRefType },
            },
          }) => {
            setFilterParams({ ...filterParams, entityType: entityRefType })
          }}
          value={filterParams.entityType}
        >
          {[
            <Option key={-1} value={''} selected={filterParams.entityType === ''} />,
            ...Object.keys(entityTypes).map((key) => (
              <Option
                key={`entity-type-${key}`}
                value={entityTypes[key]}
                selected={filterParams.entityType === entityTypes[key]}
              >
                {tNoKeyPrefix(`events.entity-type.${entityTypes[key]}`)}
              </Option>
            )),
          ]}
        </Select>
      </FilterGroupItem>
    ),
    assigneeId: (
      <FilterGroupItem key="assigneeId" label={t('assignee')}>
        <AssigneeFilter
          onAssigneeChange={(assignee) =>
            setFilterParams({
              ...filterParams,
              assigneeId: assignee,
            })
          }
          assigneeId={filterParams.assigneeId}
        />
      </FilterGroupItem>
    ),
    originalDueDateRange: (
      <FilterGroupItem key="originalDueDateRange" label={t('original-due-date')}>
        <DateRangePicker
          id="events-search-original-date-range-picker"
          formatPattern={localePattern}
          placeholder=""
          onChange={({ detail: { value: originalDueDateRange } }) => {
            if (!originalDueDateRange) {
              setFilterParams({
                ...filterParams,
                originalDueDateRange: '',
              })
              return
            }
            const { startDate, endDate } = parseDueDateRange(originalDueDateRange)
            const ISOdueDateStart = parse(startDate, localePattern)
            const ISOdueDateEnd = parse(endDate, localePattern)
            setFilterParams({
              ...filterParams,
              originalDueDateRange: `${ISOdueDateStart} - ${ISOdueDateEnd}`,
            })
          }}
          value={formatDateRangePickerValue(filterParams.originalDueDateRange)}
        />
      </FilterGroupItem>
    ),
    currentDueDateRange: (
      <FilterGroupItem key="currentDueDateRange" label={t('current-due-date')}>
        <DateRangePicker
          id="events-search-current-date-range-picker"
          placeholder=""
          formatPattern={localePattern}
          onChange={({ detail: { value: currentDueDateRange } }) => {
            if (!currentDueDateRange) {
              setFilterParams({
                ...filterParams,
                currentDueDateRange: '',
              })
              return
            }
            const { startDate, endDate } = parseDueDateRange(currentDueDateRange)
            const ISOdueDateStart = parse(startDate, localePattern)
            const ISOdueDateEnd = parse(endDate, localePattern)
            setFilterParams({
              ...filterParams,
              currentDueDateRange: `${ISOdueDateStart} - ${ISOdueDateEnd}`,
            })
          }}
          value={formatDateRangePickerValue(filterParams.currentDueDateRange)}
        />
      </FilterGroupItem>
    ),
    status: (
      <FilterGroupItem key="status" label={t('status')}>
        <EventStatusComboBox
          id="events-search-status-select"
          selected={filterParams.status ?? []}
          onSelectionChange={(eventStatusValues) =>
            setFilterParams({
              ...filterParams,
              status: eventStatusValues,
            })
          }
        />
      </FilterGroupItem>
    ),
    ...((customEventCodeFilterList?.length > 0 ||
      (!isNil(filterParams?.entityType) && filterParams?.entityType !== '')) && {
      eventType: (
        <FilterGroupItem key="eventType" label={t('event-type')}>
          <EventTypeSelect
            eventCode={filterParams.eventCode}
            entityType={filterParams?.entityType}
            onChange={(selectedEventCode) => {
              setFilterParams({
                ...filterParams,
                eventCode: selectedEventCode,
              })
            }}
            customEventCodeFilterList={customEventCodeFilterList}
          />
        </FilterGroupItem>
      ),
    }),
  }

  const filterBarItems =
    compact(visibleFilters.map((filterField) => possibleFilterBarItems[filterField])) ?? []

  return (
    <DashboardFilterBar
      filterOptions={filterOptions}
      activeFiltersCount={filterBarItems.length}
      onGo={() => onGo(filterParams)}
      setDialogOpen={setIsFilterDialogOpen}
    >
      {filterBarItems}
    </DashboardFilterBar>
  )
}

EventSearchDialogFilterBar.propTypes = propTypes

export default EventSearchDialogFilterBar
