import { Suspense, lazy, useContext } from 'react'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import LazyLoadingFallbackCard from 'components/ui/card/LazyLoadingFallbackCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import useDeal from 'hooks/services/deals/useDeal'
import lazyRetry from 'lazyRetry'
import { DealContext } from 'routes/deals/DealContext'
import { DEAL_STATUS_RANKS } from 'routes/deals/financing/financingConstants'

const DealConsolidationPointsCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealConsolidationPointsCard" */ 'components/domains/deals/limit-check/consolidation-points/DealConsolidationPointsCard.jsx'
      ),
    'DealConsolidationPointsCard',
  ),
)

const DealReservationCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealReservationCard" */ 'components/domains/deals/limit-check/reservations/DealReservationCard'
      ),

    'DealReservationCard',
  ),
)

const DealFinancingOverviewReservationTab = () => {
  const { deal: { dealUuid, workingVersion } = {} } = useContext(DealContext)
  const { data: { dealRank } = {} } = useDeal(dealUuid)

  return (
    <CWPLayout>
      <CWPLayout.Full>
        {dealRank < DEAL_STATUS_RANKS.SIGNED && workingVersion === WorkingVersionType.LIVE && (
          <Suspense fallback={<LazyLoadingFallbackCard />}>
            <DealConsolidationPointsCard dealUuid={dealUuid} />
          </Suspense>
        )}
      </CWPLayout.Full>
      <CWPLayout.Full>
        <Suspense fallback={<LazyLoadingFallbackCard />}>
          <DealReservationCard dealUuid={dealUuid} />
        </Suspense>
      </CWPLayout.Full>
    </CWPLayout>
  )
}

export default DealFinancingOverviewReservationTab
