import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import BreachCell from 'components/domains/deals/covenants/shared/BreachCell'
import StatusCell from 'components/domains/deals/covenants/shared/StatusCell'
import { useBreachTranslation } from 'components/domains/deals/covenants/shared/useBreachTranslations'
import { useBooleanToTextFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'

const PeriodicalCheckGeneralInformationDisplay = ({
  periodicalCheck,
  monitoringItem,
  hasDocumentRequirements,
  showBreach,
  newCheckDueDate,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.periodical-checks.general-information',
  })
  const { format: formatDate } = useShortDateFormatter()
  const formatBoolean = useBooleanToTextFormatter()
  const { getTranslationForBreach } = useBreachTranslation()

  return (
    <CardSection>
      <CardShowItem label={t('label.periodical-check-name')} value={periodicalCheck?.name} />
      <CardShowItem
        label={t('label.periodical-check-type')}
        value={monitoringItem?.covenant?.name}
      />
      <CardShowItem
        label={t('label.status')}
        value={<StatusCell status={monitoringItem?.status} />}
      />
      <CardShowItem
        label={t('label.check-due-date')}
        value={formatDate(monitoringItem?.dueDate?.checkDate)}
      />
      {hasDocumentRequirements && (
        <CardShowItem
          label={t('label.target-delivery-date')}
          value={formatDate(monitoringItem?.dueDate?.targetDeliveryDate)}
        />
      )}
      {hasDocumentRequirements && (
        <CardShowItem
          label={t('label.actual-delivery-date')}
          value={formatDate(monitoringItem?.actualDeliveryDate)}
        />
      )}
      {hasDocumentRequirements && (
        <CardShowItem
          label={t('label.new-target-delivery-date')}
          value={formatDate(monitoringItem?.newTargetDeliveryDate)}
        />
      )}
      {!hasDocumentRequirements && (
        <CardShowItem label={t('label.new-check-due-date')} value={formatDate(newCheckDueDate)} />
      )}
      {showBreach && (
        <CardShowItem
          label={t('label.breach')}
          value={
            monitoringItem?.breach === 'POSTPONED' || !monitoringItem?.breach ? (
              getTranslationForBreach(monitoringItem?.breach)
            ) : (
              <BreachCell breach={monitoringItem?.breach} />
            )
          }
        />
      )}
      <CardShowItem label={t('label.waived')} value={formatBoolean(monitoringItem?.waived)} />
    </CardSection>
  )
}

PeriodicalCheckGeneralInformationDisplay.propTypes = {
  periodicalCheck: PropTypes.shape({ name: PropTypes.string }),
  monitoringItem: PropTypes.shape({
    covenant: PropTypes.shape({
      name: PropTypes.string,
    }),
    dueDate: PropTypes.shape({
      checkDate: PropTypes.string,
      targetDeliveryDate: PropTypes.string,
    }),
    status: PropTypes.string,
    actualDeliveryDate: PropTypes.string,
    newTargetDeliveryDate: PropTypes.string,
    breach: PropTypes.string,
    resultOfBreach: PropTypes.shape({ name: PropTypes.string }),
    waived: PropTypes.bool,
  }),
  newCheckDueDate: PropTypes.string,
  hasDocumentRequirements: PropTypes.bool,
  showBreach: PropTypes.bool,
}

export default PeriodicalCheckGeneralInformationDisplay
