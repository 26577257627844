import { ObjectStatus } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  decisionProcessConditionsDecisionStatus,
  getObjectStatusforDecisionStageConditionsStatus,
} from 'api/decision-process/decisionProcessConditionsApi'

const ConditionsInformationDecisionStatusObject = ({ decisionStatus }) => {
  const { t: tNoPrefix } = useTranslation()
  const { translationKey, objectStatus } =
    getObjectStatusforDecisionStageConditionsStatus(decisionStatus)

  return (
    <ObjectStatus inverted state={objectStatus}>
      {tNoPrefix(translationKey)}
    </ObjectStatus>
  )
}

ConditionsInformationDecisionStatusObject.propTypes = {
  decisionStatus: PropTypes.oneOf(Object.values(decisionProcessConditionsDecisionStatus)),
}

export default ConditionsInformationDecisionStatusObject
