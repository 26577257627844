import findKey from 'lodash.findkey'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { useCallback, useMemo } from 'react'
import {
  valuationClassification,
  valuationRelevance,
} from 'api/property/valuation/valuationRequests'

// all the valuation types to display in the chart
export const GRAPH_DISPLAY_VALUATION_TYPES = [
  'marketValue',
  'sellOffMarketValue',
  'mortgageLendingValue',
  'sellOffMortgageLendingValue',
  'currentStateMortgageLendingValue',
]

const GRAPH_DISPLAY_VALUATION_CODES = GRAPH_DISPLAY_VALUATION_TYPES.map(
  (type) => valuationClassification?.[type],
).filter(Boolean)

const filterValuationType = ({ cwpCode } = {}) => GRAPH_DISPLAY_VALUATION_CODES.includes(cwpCode)

const filterValuationHistory = ({ regulatoryRelevance } = {}) =>
  regulatoryRelevance === valuationRelevance.relevant

const getValuesForHistoryItem = (cwpCode, valuationHistoryItem) => {
  const valueObject = Object.fromEntries(GRAPH_DISPLAY_VALUATION_TYPES.map((type) => [type, null]))
  const valuationCodeKey = findKey(valuationClassification, (value) => value === cwpCode)
  if (valuationCodeKey) {
    valueObject[valuationCodeKey] = valuationHistoryItem?.valueAmount?.number
  }
  return valueObject
}

const usePrepareValuationHistoryData = (valuations = []) => {
  const presentValuationTypes = new Set()

  const valuationsHistoryData = valuations
    ?.filter(filterValuationType)
    ?.flatMap(({ cwpCode, historicalValues } = {}) =>
      historicalValues?.filter(filterValuationHistory)?.map((valuationHistoryItem) => {
        const valuationCodeKey = findKey(valuationClassification, (value) => value === cwpCode)
        if (valuationCodeKey) {
          presentValuationTypes.add(valuationCodeKey)
        }

        return {
          ...getValuesForHistoryItem(cwpCode, valuationHistoryItem),
          dateUnixTimestamp: new Date(valuationHistoryItem?.keyDate).getTime(),
          keyDate: valuationHistoryItem?.keyDate,
          currency: valuationHistoryItem?.valueAmount?.currency,
          cwpCode,
        }
      }),
    )
    ?.filter(Boolean)
    ?.toSorted((a, b) => new Date(a.keyDate) - new Date(b.keyDate))

  const getInterpolatedValuationValueByCodeAndKeyDate = useCallback(
    (code, keyDate) => {
      const relevantValuationItems = valuationsHistoryData.filter(
        (valuation) =>
          !isNil(valuation?.[code]) &&
          new Date(valuation.keyDate).getTime() <= new Date(keyDate).getTime(),
      )
      return relevantValuationItems?.at(-1)?.[code]
    },
    [valuationsHistoryData],
  )

  const presentValuationTypesArray = Array.from(presentValuationTypes) ?? []
  const lastValuationHistoryItem = valuationsHistoryData.at(-1)
  const lastEnrichedValuationHistoryValues = Object.fromEntries(
    presentValuationTypesArray.map((valuationType) => [
      valuationType,
      getInterpolatedValuationValueByCodeAndKeyDate(
        valuationType,
        lastValuationHistoryItem?.keyDate,
      ),
    ]),
  )

  const preparedValuationsHistoryData = useMemo(
    () =>
      !isEmpty(valuationsHistoryData)
        ? [
            ...valuationsHistoryData.slice(0, -1),
            {
              ...lastValuationHistoryItem,
              ...lastEnrichedValuationHistoryValues,
            },
          ]
        : [],
    [lastEnrichedValuationHistoryValues, lastValuationHistoryItem, valuationsHistoryData],
  )

  return {
    preparedValuationsHistoryData,
    presentValuationTypes: presentValuationTypesArray,
    getInterpolatedValuationValueByCodeAndKeyDate,
  }
}

export default usePrepareValuationHistoryData
