import camelize from 'camelize'
import { useMemo } from 'react'
import { useConfig } from 'hooks/config/useConfig'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useAssetClasses } from 'hooks/services/business-partners/risk-figures/useAssetClasses'
import { useAssetSubClasses } from 'hooks/services/business-partners/risk-figures/useAssetSubClasses'
import { useRiskFigureTypes } from 'hooks/services/business-partners/risk-figures/useRiskFigureTypes'
import { useRiskFigures } from 'hooks/services/business-partners/risk-figures/useRiskFigures'

const useBaselTwoByProductClass = ({ entityRef: { entityId: businessPartnerId } }, tileId) => {
  const {
    isLoading: isAssetClassesLoading,
    isError: isAssetClassesError,
    data: assetClassesConfig,
  } = useAssetClasses()

  const {
    isLoading: isAssetSubClassesLoading,
    isError: isAssetSubClassesError,
    data: assetSubClassesConfig,
  } = useAssetSubClasses()

  const {
    isLoading: isRiskFigureTypesLoading,
    isError: isRiskFigureTypesError,
    data: riskFigureTypesConfig,
  } = useRiskFigureTypes()

  const { isLoading: isConfigLoading, isError: isConfigError, data: config } = useConfig()

  const {
    isLoading: isLoadingRiskFigures,
    isError: isErrorRiskFigures,
    data: {
      risk_figures: riskFigures = {},
      class_types: classTypes = {},
      asset_classes: assetClasses = {},
    } = {},
  } = useRiskFigures({ businessPartnerId })

  const riskFigureDisplayNamesMap = Object.entries(classTypes).reduce(
    (dictionary, [key, value]) => ({ ...dictionary, [key]: value.display_name }),
    {},
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isAssetClassesLoading,
      isAssetSubClassesLoading,
      isRiskFigureTypesLoading,
      isLoadingRiskFigures,
      isConfigLoading,
    ],
    errorValues: [
      isAssetClassesError,
      isAssetSubClassesError,
      isRiskFigureTypesError,
      isErrorRiskFigures,
      isConfigError,
    ],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    const columnTypesToDisplay = config?.riskFigureTypes ?? []
    return {
      isLoading: false,
      isError: false,
      data: {
        assetClassesConfig,
        assetSubClassesConfig,
        columnTypesToDisplay,
        riskFigureTypesConfig,
        riskFigures: camelize(riskFigures),
        assetClasses,
        riskFigureDisplayNamesMap,
        sourceRender: {
          businessPartnerId,
        },
      },
    }
  }, [
    assetClasses,
    assetClassesConfig,
    assetSubClassesConfig,
    businessPartnerId,
    config?.riskFigureTypes,
    error,
    isSomeValueError,
    isSomeValueLoading,
    riskFigureDisplayNamesMap,
    riskFigureTypesConfig,
    riskFigures,
  ])
}

export default useBaselTwoByProductClass
