import { useTranslation } from 'react-i18next'
import { arrearsPermissions } from 'api/arrears/ArrearsPermissions'
import { hasUserRequiredOperations } from 'api/helper'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import ArrearsBcaAmountWarningBanner from 'components/domains/business-partners/tile/arrears/arrears-bca-amount-warning-banner/ArrearsBcaAmountWarningBanner'
import ArrearsBpPdLevelBanner from 'components/domains/business-partners/tile/arrears/arrears-bp-level-banner/ArrearsBpPdLevelBanner'
import ArrearsCommentsCard from 'components/domains/business-partners/tile/arrears/comments/ArrearsCommentsCard'
import CurrentApprovalsCard from 'components/domains/business-partners/tile/arrears/current-approvals/CurrentApprovalsCard'
import CurrentArrearsCard from 'components/domains/business-partners/tile/arrears/current-arrears/CurrentArrearsCard'
import ArrearsKpiCards from 'components/domains/business-partners/tile/arrears/kpis/ArrearsKpiCards'
import ArrearsPastApprovalsCard from 'components/domains/business-partners/tile/arrears/past-approvals/ArrearsPastApprovalsCard'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Page from 'components/ui/page/Page'
import useArrearsAllowedOperations from 'hooks/services/arrears/useArrearsAllowedOperations'
import { BusinessPartnerArrearsContextProvider } from 'routes/business-partners/arrears/BusinessPartnerArrearsContext'

const BusinessPartnerArrearsPage = () => {
  const { t } = useTranslation()

  const {
    data: { allowedOperations = [] } = {},
    isLoading,
    isError,
  } = useArrearsAllowedOperations()

  const requiredOperations = [arrearsPermissions.readApprovals, arrearsPermissions.readArrears]
  const requiredCommentReadOperations = [arrearsPermissions.readArrearComments]
  const hasPermissions = hasUserRequiredOperations(requiredOperations, allowedOperations ?? [])
  const hasCommentReadPermission = hasUserRequiredOperations(
    requiredCommentReadOperations,
    allowedOperations ?? [],
  )
  const renderContent = () => {
    if (hasPermissions) {
      return (
        <BusinessPartnerArrearsContextProvider>
          <CWPLayout>
            <CWPLayout.Full>
              <ArrearsBpPdLevelBanner />
            </CWPLayout.Full>
            <CWPLayout.Full>
              <ArrearsBcaAmountWarningBanner />
            </CWPLayout.Full>
            <CWPLayout.Full>
              <ArrearsKpiCards />
            </CWPLayout.Full>
            <CWPLayout.Full>
              <CurrentArrearsCard />
            </CWPLayout.Full>
            <CWPLayout.Full>
              <CurrentApprovalsCard />
            </CWPLayout.Full>
            {hasCommentReadPermission && (
              <CWPLayout.Full>
                <ArrearsCommentsCard />
              </CWPLayout.Full>
            )}
            <CWPLayout.Full>
              <ArrearsPastApprovalsCard />
            </CWPLayout.Full>
          </CWPLayout>
        </BusinessPartnerArrearsContextProvider>
      )
    } else {
      return (
        <CenteredIllustratedMessage
          name="UnableToLoad"
          size="Spot"
          titleText={t('app.permission-error.title')}
          subtitleText={t('app.permission-error.subtitle')}
        />
      )
    }
  }

  return (
    <Page
      isError={isError}
      isLoading={isLoading}
      renderHeaderTitle={() => (
        <BusinessPartnerHeader
          breadcrumbs={[{ text: t('navigation.item.title.business-partner-arrears') }]}
        />
      )}
      renderContent={renderContent}
    />
  )
}

BusinessPartnerArrearsPage.propTypes = {}

export default BusinessPartnerArrearsPage
