import { Button, ButtonDesign, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useCopyConditions from 'hooks/services/conditions/useCopyConditions'
import { reset, highlightConditionRow } from 'redux/slices/conditions/conditionsTableSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const CopySelectedConditionsButton = ({ disabled, conditionType }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.buttons.duplicate-condition',
  })
  const {
    entityRef: { entityType, entityId },
  } = useContext(ConditionsContext)
  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { mutate: copyConditions } = useCopyConditions()

  const selectedConditionIds = useSelector(
    (state) => state.conditions.conditionsTable.selectedRows.selectedConditionIds,
  )

  const onDuplicateSuccess = useCallback(
    (_data) => {
      dispatch(reset())
      queryClient.invalidateQueries(['conditions', conditionType, entityType, entityId])
      _data.forEach(({ id: createdConditionId }) => {
        dispatch(highlightConditionRow(createdConditionId))
      })
      showToast({ children: t('success') })
    },
    [dispatch, queryClient, conditionType, entityType, entityId, showToast, t],
  )

  const onDuplicateError = useCallback(() => {
    showMessageBox({ type: MessageBoxTypes.Error, children: t('error') })
  }, [showMessageBox, t])

  const onDuplicateButtonClicked = useCallback(() => {
    const conditionIds = Object.keys(selectedConditionIds)
    copyConditions(
      { conditionType: conditionType, conditionIds },
      { onSuccess: onDuplicateSuccess, onError: onDuplicateError },
    )
  }, [conditionType, selectedConditionIds, copyConditions, onDuplicateSuccess, onDuplicateError])

  const isDeleteButtonDisabled = useMemo(
    () => disabled || isEmpty(selectedConditionIds),
    [disabled, selectedConditionIds],
  )

  return (
    <>
      <Button
        disabled={isDeleteButtonDisabled}
        design={ButtonDesign.Transparent}
        onClick={onDuplicateButtonClicked}
      >
        {tNoPrefix('buttons.duplicate')}
      </Button>
    </>
  )
}

CopySelectedConditionsButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  conditionType: PropTypes.oneOf(['internal', 'external']).isRequired,
}

export default CopySelectedConditionsButton
