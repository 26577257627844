import {
  CardHeader,
  IllustratedMessage,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { Fragment, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import CWPLayout from 'components/ui/layout/CWPLayout'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useCovenantDefinitions from 'hooks/services/deals/covenants/useCovenantDefinitions'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import useMapDetailTileCodes from 'routes/business-partners/periodical-checks/periodical-checks-layout/useMapDetailTileCodes'
import useMapOverviewTileCodes from 'routes/business-partners/periodical-checks/periodical-checks-layout/useMapOverviewTileCodes'

/**
 * @enum
 */
export const periodicalChecksLayoutPages = {
  OVERVIEW: 'OVERVIEW',
  DETAIL: 'DETAIL',
}

const createCWPLayoutWrapper = (columnSpan, children) => {
  if (columnSpan === 1) {
    return <CWPLayout.Half>{children}</CWPLayout.Half>
  }
  return <CWPLayout.Full>{children}</CWPLayout.Full>
}

const UnsupportedCard = ({ tileCode }) => {
  const { t } = useTranslation()
  return (
    <Card
      header={
        <CardHeader
          titleText={t('pages.business-partner.periodical-checks.unsupported-tile-code', {
            tileCode,
          })}
        />
      }
    >
      <IllustratedMessage name={IllustrationMessageType.SimpleNotFoundMagnifier} />
    </Card>
  )
}
UnsupportedCard.propTypes = {
  tileCode: PropTypes.string,
}

const PeriodicalChecksLayout = ({
  covenantUuid,
  covenantCheckUuid,
  configDefinitionUuid,
  currentPage,
}) => {
  const { t } = useTranslation()
  const { id: businessPartnerId, fullName: businessPartnerName } =
    useContext(BusinessPartnerContext)
  const {
    data: { covenantDefinitions = [] } = {},
    isLoading: isLoadingDefinitions,
    isError: isErrorDefinitions,
  } = useCovenantDefinitions()

  const currentCovenantDefinition = covenantDefinitions.find(
    ({ configCovenantDefinitionUuid }) => configCovenantDefinitionUuid === configDefinitionUuid,
  )

  const currentViewTemplate = useMemo(() => {
    switch (currentPage) {
      case periodicalChecksLayoutPages.OVERVIEW:
        return currentCovenantDefinition?.overviewViewTemplates ?? []
      case periodicalChecksLayoutPages.DETAIL:
        return currentCovenantDefinition?.detailViewTemplates ?? []
      default:
        return []
    }
  }, [
    currentCovenantDefinition?.detailViewTemplates,
    currentCovenantDefinition?.overviewViewTemplates,
    currentPage,
  ])

  const { mapOverviewTileCodeToComponent } = useMapOverviewTileCodes(
    covenantUuid,
    currentCovenantDefinition,
  )
  const { mapDetailTileCodeToComponent } = useMapDetailTileCodes(
    covenantCheckUuid,
    businessPartnerId,
    businessPartnerName,
    currentCovenantDefinition,
  )

  const mapTileCodeToComponent = (tileCode) => {
    switch (currentPage) {
      case periodicalChecksLayoutPages.OVERVIEW:
        return mapOverviewTileCodeToComponent(tileCode) ?? <UnsupportedCard tileCode={tileCode} />
      case periodicalChecksLayoutPages.DETAIL:
        return mapDetailTileCodeToComponent(tileCode) ?? <UnsupportedCard tileCode={tileCode} />
      default:
        return <UnsupportedCard tileCode={currentPage} />
    }
  }

  const componentsToBeShown = currentViewTemplate
    .sort((a, b) => Number(a.index) - Number(b.index))
    .map(({ tileCode, columnSpan }) => {
      const component = mapTileCodeToComponent(tileCode)
      return createCWPLayoutWrapper(Number(columnSpan), component)
    })

  return (
    <RequestStateResolver
      isLoading={isLoadingDefinitions}
      isError={isErrorDefinitions}
      center
      errorToDisplay={<ErrorDataUnavailableInContent />}
      renderContent={() =>
        componentsToBeShown.length ? (
          <CWPLayout>
            {componentsToBeShown.map((resolvedComponent, index) => (
              <Fragment key={index}>{resolvedComponent}</Fragment>
            ))}
          </CWPLayout>
        ) : (
          <IllustratedMessage
            name={IllustrationMessageType.NoData}
            titleText={t('components.cards.empty.title')}
            subtitleText={t('components.cards.empty.subtitle')}
          />
        )
      }
    />
  )
}

PeriodicalChecksLayout.propTypes = {
  covenantUuid: PropTypes.string,
  covenantCheckUuid: PropTypes.string,
  configDefinitionUuid: PropTypes.string.isRequired,
  currentPage: PropTypes.oneOf(Object.keys(periodicalChecksLayoutPages)).isRequired,
}

export default PeriodicalChecksLayout
