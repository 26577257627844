import { TextAlign } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import { useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/exposure/ByProductClassCard.module.css'
import RiskFigurePropTypes from 'components/domains/business-partners/exposure/RiskFigurePropTypes'
import getAssetClassRowsWithAssetSubClasses from 'components/domains/business-partners/exposure/getAssetClassRowsWithAssetSubClasses'
import {
  getOrdinalPositionForRiskFigureTypes,
  sortByOrdinalPosition,
} from 'components/domains/business-partners/exposure/getOrdinalPositionForRiskFigureTypes'
import Card from 'components/ui/card/Card'
import AnalyticalTableNoDataComponent from 'components/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import { useCollapseExpandTableActions } from 'components/ui/tables/analytical/useCollapseExpandTableActions'
import { ConfigContext } from 'hooks/config/useConfig'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import { useAssetClasses } from 'hooks/services/business-partners/risk-figures/useAssetClasses'
import { useAssetSubClasses } from 'hooks/services/business-partners/risk-figures/useAssetSubClasses'
import { useRiskFigureTypes } from 'hooks/services/business-partners/risk-figures/useRiskFigureTypes'

const ByProductClassCard = ({
  riskFigures = {},
  riskFigureDisplayNamesMap = {},
  assetClasses = {},
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.by-product-class',
  })
  const config = useContext(ConfigContext)
  const columnTypesToDisplay = config?.riskFigureTypes ?? []
  const formatCurrency = useCustomizableCurrencyFormatter()
  const {
    isLoading: isAssetClassesLoading,
    isError: isAssetClassesError,
    data: assetClassesConfig,
  } = useAssetClasses()
  const {
    isLoading: isAssetSubClassesLoading,
    isError: isAssetSubClassesError,
    data: assetSubClassesConfig,
  } = useAssetSubClasses()
  const {
    isLoading: isRiskFigureTypesLoading,
    isError: isRiskFigureTypesError,
    data: riskFigureTypesConfig,
  } = useRiskFigureTypes()

  const fallbackCurrency = riskFigures?.total?.unit ?? 'EUR'

  const getDisplayNameForClassType = (classTypeKey) =>
    riskFigureDisplayNamesMap[classTypeKey] ?? classTypeKey

  const orderedRiskFigures = sortByOrdinalPosition(
    getOrdinalPositionForRiskFigureTypes(riskFigures, riskFigureTypesConfig),
  ).map(([name, value]) => ({ ...value, name }))

  const columnDefinitions = [
    {
      Header: t('product-class'),
      id: 'product-class',
      accessor: (row) => getDisplayNameForClassType(row.assetClass),
    },
    ...orderedRiskFigures
      .filter((figure) => columnTypesToDisplay.includes(figure.name))
      .map((figure) => ({
        Header: getDisplayNameForClassType(figure.name),
        id: figure.name,
        accessor: (row) =>
          formatCurrency(row[figure.name]?.value ?? 0, row[figure.name]?.unit ?? fallbackCurrency),
        hAlign: TextAlign.End,
      })),
  ]

  const tableRef = useRef(null)
  const { handleOnRowExpand, additionalTableActions: collapseExpandActions = [] } =
    useCollapseExpandTableActions(tableRef)

  const renderTable = () => {
    const hasAssetClasses = !isEmpty(assetClasses)
    const rows = hasAssetClasses
      ? [
          ...getAssetClassRowsWithAssetSubClasses({
            assetClasses,
            assetClassesConfig: assetClassesConfig?.riskFigureCategories,
            assetSubClassesConfig: assetSubClassesConfig?.riskFigureCategories,
          }),
          {
            assetClass: t('total'),
            ...riskFigures,
            isSectionHeader: true,
          },
        ]
      : []
    return (
      <Card>
        <div className={styles.tableWrapper}>
          <AnalyticalTableWithToolbar
            id={'by-product-class'}
            title={t('product-classes')}
            columns={columnDefinitions}
            data={rows}
            nrOfEntries={hasAssetClasses ? rows.length - 1 : 0}
            minRows={1}
            groupable={false}
            sortable={false}
            filterable={false}
            subRowsKey="subRows"
            isTreeTable
            className={`${styles.riskFigureTable} tree-table`}
            additionalActions={collapseExpandActions}
            onRowExpandChange={(event) => handleOnRowExpand(event.detail.row)}
            ref={tableRef}
            reactTableOptions={{
              autoResetExpanded: false,
            }}
            NoDataComponent={() => (
              <AnalyticalTableNoDataComponent
                isLoading={
                  isAssetClassesLoading || isAssetSubClassesLoading || isRiskFigureTypesLoading
                }
                isError={isAssetClassesError || isAssetSubClassesError || isRiskFigureTypesError}
                tableId={'by-product-class'}
              />
            )}
          />
        </div>
      </Card>
    )
  }

  return renderTable()
}

ByProductClassCard.propTypes = {
  riskFigures: RiskFigurePropTypes.RiskFigures,
  riskFigureDisplayNamesMap: RiskFigurePropTypes.RiskFiguresDisplay,
  assetClasses: RiskFigurePropTypes.AssetClasses,
}

export default ByProductClassCard
