import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const usePropertyComment = ({ propertyUuid }) =>
  useCamelizedResponse(
    useRequest({
      path: `/properties/${propertyUuid}/comment`,
      keys: ['properties', propertyUuid, 'comment'],
    }),
  )

export default usePropertyComment
