import camelize from 'camelize'
import _ from 'lodash'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from 'components/domains/properties/overview/PropertyRentRollInfoCard.module.css'
import PropertyRentRollOverviewOccupancyCardPieChart from 'components/domains/properties/rent-roll/overview/occupancy/PropertyRentRollOverviewOccupancyCardPieChart'
import DisplayCardView, { hideOptions } from 'components/ui/card/DisplayCardView'
import {
  useAreaMeasurementUnitFormatter,
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  usePercentageFormatter,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'
import useCurrentPropertiesKpis from 'hooks/services/properties/kpis/useCurrentPropertiesKpis'
import useCurrentSegmentKpis from 'hooks/services/properties/kpis/useCurrentSegmentKpis'
import { useSegments } from 'hooks/services/properties/segments/useSegments'
import { PropertyContext } from 'routes/properties/PropertyContext'
import { getYearOrYears } from 'utils/YearUnitUtil'

const PropertyRentRollInfoCard = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property-overview.tenancy-tile' })
  const { property: { uuid: propertyUuid } = {} } = useContext(PropertyContext)

  const {
    data: kpiData,
    isError: isErrorKpis,
    isLoading: isLoadingKpis,
  } = useCurrentPropertiesKpis([propertyUuid])
  const propertyKpis = !isErrorKpis && !isLoadingKpis ? kpiData[0] : {}

  const {
    data: segmentsData,
    isError: isErrorSegments,
    isLoading: isLoadingSegments,
  } = useSegments({ propertyUuid })
  const segments = !isErrorSegments && !isLoadingSegments ? segmentsData?.segments : []

  const {
    data: segmentsKpisData,
    isError: isErrorSegementsKpis,
    isLoading: isLoadingSegmentsKpis,
  } = useCurrentSegmentKpis(
    propertyUuid,
    segments.map((segment) => segment?.uuid),
  )
  const segmentsKpis =
    !isErrorSegementsKpis && !isLoadingSegmentsKpis ? camelize(segmentsKpisData?.kpis) : []

  const usageTypesList = !_.isEmpty(segmentsKpis)
    ? segmentsKpis.map((segment) =>
        segment?.totalAreaSurface?.value ? segment?.usageTypeName : undefined,
      )
    : []
  const usageTypes = _.uniq(usageTypesList).filter((value) => value)
  const usageTypesValue = !_.isEmpty(usageTypes) ? usageTypes.join(', ') : ''

  const { pathname } = useLocation()
  const navigate = useNavigate()
  const routeToRentRoll = `${pathname}/rent-roll`
  const handleHeaderClick = () => {
    navigate(routeToRentRoll)
  }

  const formatNumber = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const formatWault = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const formatCurrency = useCustomizableCurrencyFormatter()
  const formatArea = useAreaMeasurementUnitFormatter()
  const formatPercent = usePercentageFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const { format: formatDate } = useShortDateFormatter()

  const setCurrencyOrUndefined = (value, currency) =>
    value && currency ? formatCurrency(value, currency) : undefined
  const setUnitOrUndefined = (value, unit) =>
    value && unit ? `${formatNumber(value)} ${formatArea(unit)}` : undefined
  const setYearOrUndefined = (value) =>
    value ? `${formatWault(value)} ${getYearOrYears(value)}` : undefined

  const currentRentValue = setCurrencyOrUndefined(
    propertyKpis?.annualizedCurrentRent?.number,
    propertyKpis?.annualizedCurrentRent?.currency,
  )
  const totalAreaValue = setUnitOrUndefined(
    propertyKpis?.totalAreaSurface?.value,
    propertyKpis?.totalAreaSurface?.measurementUnit,
  )

  const currentRentPerUomNumber = propertyKpis?.annualizedCurrentRentPerUom?.number
  const currentRentPerUomValue = setCurrencyOrUndefined(
    currentRentPerUomNumber,
    propertyKpis?.annualizedCurrentRentPerUom?.currency,
  )
  const marketRentPerUomNumber = propertyKpis?.totalMarketRentPerUom?.number
  const marketRentPerUomValue = setCurrencyOrUndefined(
    marketRentPerUomNumber,
    propertyKpis?.totalMarketRentPerUom?.currency,
  )

  const currentAndMarketRentPerUomValue =
    currentRentPerUomValue && marketRentPerUomValue
      ? `${currentRentPerUomValue} / ${marketRentPerUomValue}`
      : undefined

  const overOrUnderRentedValue = formatPercent(currentRentPerUomNumber / marketRentPerUomNumber)
  const occupancyRateNumber =
    (propertyKpis?.totalAreaSurface?.value - propertyKpis?.vacancySurface?.value) /
    propertyKpis?.totalAreaSurface?.value
  const occupancyRateValue = formatPercent(occupancyRateNumber)

  const chartFieldDefinition = [
    {
      customInfoComponent: (
        <div className={styles.pieChart}>
          <PropertyRentRollOverviewOccupancyCardPieChart showHeader={true} />
        </div>
      ),
    },
  ]
  const baseFieldDefinitions = [
    {
      label: t('usage-types'),
      value: usageTypesValue,
      hideOption: hideOptions.hideWhenEmpty,
    },
    {
      label: t('current-rent'),
      value: currentRentValue,
      hideOption: hideOptions.hideWhenEmpty,
    },
    {
      label: t('total-area'),
      value: totalAreaValue,
      hideOption: hideOptions.hideWhenEmpty,
    },
    {
      label: `${t('current-and-market-rent-per-uom')} ${formatArea(
        propertyKpis?.totalAreaSurface?.measurementUnit,
      )}`,
      value: currentAndMarketRentPerUomValue,
      hideOption: hideOptions.hideWhenEmpty,
    },
    {
      label: t('over-or-under-rented'),
      value: overOrUnderRentedValue,
      hideOption: hideOptions.hideWhenEmpty,
    },
    {
      label: t('occupancy-rate'),
      value: occupancyRateValue,
      hideOption: hideOptions.hideWhenEmpty,
    },
    {
      label: t('wault-break'),
      value: setYearOrUndefined(propertyKpis?.waultToBreakInYears),
      hideOption: hideOptions.hideWhenEmpty,
    },
    {
      label: t('key-date'),
      value: formatDate(propertyKpis?.keyDate),
      hideOption: hideOptions.hideWhenEmpty,
    },
  ]
  const fieldDefinitions = [...chartFieldDefinition, ...baseFieldDefinitions]
  const rentRollEmpty = baseFieldDefinitions.every((item) => !item.value)

  return (
    <DisplayCardView
      cardHeaderTitle={t('title')}
      onHeaderClick={handleHeaderClick}
      isEditable={false}
      setEditMode={() => {}}
      fieldDefinitions={fieldDefinitions}
      isEmpty={rentRollEmpty}
      isError={isErrorKpis || isErrorSegementsKpis || isErrorSegments}
      isLoading={isLoadingKpis || isLoadingSegments || isLoadingSegmentsKpis}
    />
  )
}

export default PropertyRentRollInfoCard
