import { lazy, Suspense, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import LazyLoadingFallbackCard from 'components/ui/card/LazyLoadingFallbackCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Page from 'components/ui/page/Page'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import lazyRetry from 'lazyRetry'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import paths from 'routes/business-partners/paths'

const LendingTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "LendingTile" */ 'components/domains/business-partners/tile/financial-product/lending/LendingTile'
      ),
    'LendingTile',
  ),
)

const ReposTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ReposTile" */ 'components/domains/business-partners/tile/financial-product/repos/ReposTile'
      ),
    'ReposTile',
  ),
)

const MoneyMarketsTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MoneyMarketsTile" */ 'components/domains/business-partners/tile/financial-product/moneyMarkets/MoneyMarketsTile'
      ),
    'MoneyMarketsTile',
  ),
)

const DerivativesTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DerivativesTile" */ 'components/domains/business-partners/tile/financial-product/derivatives/DerivativesTile'
      ),
    'DerivativesTile',
  ),
)

const BondsTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "BondsTile" */ 'components/domains/business-partners/tile/financial-product/bonds/BondsTile'
      ),
    'BondsTile',
  ),
)

const PipelineTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "PipelineTile" */ 'components/domains/business-partners/tile/financial-product/pipeline/PipelineTile'
      ),
    'PipelineTile',
  ),
)

const OtherLendingTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "OtherLendingTile" */ 'components/domains/business-partners/tile/financial-product/other-lending/OtherLendingTile'
      ),
    'OtherLendingTile',
  ),
)

const OtherTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "OtherTile" */ 'components/domains/business-partners/tile/financial-product/other/OtherTile'
      ),
    'OtherTile',
  ),
)

const AccountsTile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AccountsTile" */ 'components/domains/business-partners/tile/financial-product/accounts/AccountsTile'
      ),
    'AccountsTile',
  ),
)

const borrowerRole = { id: 'Borrower' }

const BusinessPartnerFinancialProductOverviewPage = () => {
  const businessPartner = useContext(BusinessPartnerContext)
  const { t } = useTranslation()

  const businessPartnerFound = () => Boolean(businessPartner?.id)
  const hasRoleBorrower = businessPartner.roles?.some((role) => role.id === borrowerRole.id)

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={businessPartner.id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [businessPartner.id],
  )

  const renderContent = () => (
    <>
      {businessPartnerFound() ? (
        <CWPLayout overview>
          {hasRoleBorrower && (
            <>
              <Suspense fallback={<LazyLoadingFallbackCard />}>
                <CWPLayout.Full>
                  <LendingTile />
                </CWPLayout.Full>
              </Suspense>
              <Suspense fallback={<LazyLoadingFallbackCard />}>
                <CWPLayout.Full>
                  <AccountsTile isCreditAccountsCard />
                </CWPLayout.Full>
              </Suspense>
              <Suspense fallback={<LazyLoadingFallbackCard />}>
                <CWPLayout.Full>
                  <AccountsTile />
                </CWPLayout.Full>
              </Suspense>
              <Suspense fallback={<LazyLoadingFallbackCard />}>
                <CWPLayout.Full>
                  <PipelineTile />
                </CWPLayout.Full>
              </Suspense>
              <Suspense fallback={<LazyLoadingFallbackCard />}>
                <CWPLayout.Full>
                  <DerivativesTile />
                </CWPLayout.Full>
              </Suspense>
              <Suspense fallback={<LazyLoadingFallbackCard />}>
                <CWPLayout.Full>
                  <MoneyMarketsTile />
                </CWPLayout.Full>
              </Suspense>
              <Suspense fallback={<LazyLoadingFallbackCard />}>
                <CWPLayout.Full>
                  <BondsTile />
                </CWPLayout.Full>
              </Suspense>
              <Suspense fallback={<LazyLoadingFallbackCard />}>
                <CWPLayout.Full>
                  <ReposTile />
                </CWPLayout.Full>
              </Suspense>
              <Suspense fallback={<LazyLoadingFallbackCard />}>
                <CWPLayout.Full>
                  <OtherLendingTile />
                </CWPLayout.Full>
              </Suspense>
              <Suspense fallback={<LazyLoadingFallbackCard />}>
                <CWPLayout.Full>
                  <OtherTile />
                </CWPLayout.Full>
              </Suspense>
            </>
          )}
        </CWPLayout>
      ) : (
        <>
          {t('pages.business-partner.not-found')}
          {<NavLink to={paths.businessPartners}>{t('pages.business-partner.search')}</NavLink>}
        </>
      )}
    </>
  )

  return (
    <Page
      isError={false}
      isLoading={false}
      renderHeaderTitle={() => (
        <BusinessPartnerHeader
          breadcrumbs={[
            { text: t('navigation.item.title.business-partner-financial-product-overview') },
          ]}
          actions={
            <>
              <CreateBusinessPartnerEventAction />
              {markFavoriteAction}
            </>
          }
        />
      )}
      renderContent={renderContent}
      id="businessPartnerFinancialProductOverviewId"
    />
  )
}

BusinessPartnerFinancialProductOverviewPage.propTypes = {}

export default BusinessPartnerFinancialProductOverviewPage
