import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/RiskIndicatorsDetailedDecisionPaper.module.css'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/card/EmptyCardContent'
import SortedTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/tables/sorted-tables/SortedTable'
import useDetailedRiskIndicatorTableDataDecisionPaper from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/useDetailedRiskIndicatorTableDataDecisionPaper'
import useRiskIndicatorDetailedColumnsDecisionPaper from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/useRiskIndicatorDetailedColumnsDecisionPaper'

const propTypes = {
  tileId: PropTypes.string.isRequired,
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const RiskIndicatorsDetailedDecisionPaper = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.tiles.risk-monitoring.risk-indicators',
  })
  const { t: tNoPrefix } = useTranslation('decisionPaper')

  const { data } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { riskIndicators, requestedValues, typeCodes, isNoData } = data

  const columns = useRiskIndicatorDetailedColumnsDecisionPaper(typeCodes)
  const { tableData } = useDetailedRiskIndicatorTableDataDecisionPaper(
    riskIndicators,
    requestedValues,
    typeCodes,
  )

  return (
    <div className={styles.tableWrapper}>
      <SortedTable
        columnDefinitions={columns}
        tableData={tableData}
        renderEmptyTableComponent={
          isNoData
            ? () => (
                <EmptyCardContent
                  size={'Spot'}
                  title={tNoPrefix('tiles.empty.title')}
                  subtitle={tNoPrefix('tiles.empty.subtitle')}
                  illustrationName={'NoData'}
                />
              )
            : undefined
        }
        toolbarConfig={{
          title: t('title'),
          sorting: { columnKey: 'riskIndicator', isSortingAscending: true },
        }}
      />
    </div>
  )
}

RiskIndicatorsDetailedDecisionPaper.propTypes = propTypes

export default RiskIndicatorsDetailedDecisionPaper
