import { ValueState } from '@fioneer/ui5-webcomponents-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useValidateHelpers from 'components/domains/deals/covenants/limit-cards/edit-mode/useValidateHelpers'
import { ValueStateMessage } from 'components/domains/properties/rent-roll/working-version/ValueStateMessage'

const useValidateFinancialFields = ({ formState = {}, isLimitAbsolute, setIsValid }) => {
  const { t: tNoPrefix } = useTranslation('translation')
  const [errors, setErrors] = useState({})

  const {
    checkValidToAbsolute,
    checkValidToRelative,
    checkValidFromRelative,
    checkValidFromAbsolute,
  } = useValidateHelpers(formState)

  const validateFields = useCallback(() => {
    let validationErrors = {
      absoluteLimits: [],
      relativeLimits: [],
    }

    if (!formState.configCovenantBreachResultUuid) {
      validationErrors = {
        ...validationErrors,
        configCovenantBreachResultUuid: tNoPrefix('form.validation.required'),
      }
    }

    if (isLimitAbsolute && formState?.absoluteLimits?.length) {
      for (const [index, localLimit] of formState.absoluteLimits.entries()) {
        validationErrors = {
          ...validationErrors,
          absoluteLimits: [
            ...validationErrors.absoluteLimits.slice(0, index),
            {
              ...validationErrors.absoluteLimits[index],
              validFrom: checkValidFromAbsolute(localLimit.validFrom, index),
              validTo: checkValidToAbsolute(localLimit.validTo, index),
            },
            ...validationErrors.absoluteLimits.slice(index + 1, -1),
          ],
        }
      }
    }

    if (!isLimitAbsolute && formState?.relativeLimits?.length) {
      for (const [index, localLimit] of formState.relativeLimits.entries()) {
        validationErrors = {
          ...validationErrors,
          relativeLimits: [
            ...validationErrors.relativeLimits.slice(0, index),
            {
              ...validationErrors.relativeLimits[index],
              validFrom: checkValidFromRelative(localLimit.validFrom, index),
              validTo: checkValidToRelative(localLimit.validTo, index),
              period: !localLimit.period ? tNoPrefix('form.validation.required') : undefined,
            },
            ...validationErrors.relativeLimits.slice(index + 1, -1),
          ],
        }
      }
    }

    const isResultOfBreachValid = !validationErrors?.configCovenantBreachResultUuid
    const isAbsoluteLimitsValid = !validationErrors.absoluteLimits.some((limit) =>
      Object.values(limit).some((value) => !!value),
    )
    const isRelativeLimitsValid = !validationErrors.relativeLimits.some((limit) =>
      Object.values(limit).some((value) => !!value),
    )
    setIsValid(isAbsoluteLimitsValid && isRelativeLimitsValid && isResultOfBreachValid)

    setErrors(validationErrors)
    return { errors: validationErrors }
  }, [
    checkValidFromAbsolute,
    checkValidFromRelative,
    checkValidToAbsolute,
    checkValidToRelative,
    formState.absoluteLimits,
    formState.configCovenantBreachResultUuid,
    formState.relativeLimits,
    isLimitAbsolute,
    setIsValid,
    tNoPrefix,
  ])

  const getValueStateAndMessage = (field, index) => {
    const message =
      errors?.[isLimitAbsolute ? 'absoluteLimits' : 'relativeLimits']?.[index]?.[field]
    return {
      valueState: message ? ValueState.Error : ValueState.None,
      valueStateMessage: message ? <ValueStateMessage name={message} /> : undefined,
    }
  }

  return { validateFields, getValueStateAndMessage }
}

export default useValidateFinancialFields
