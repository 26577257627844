import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import DecisionPaperTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTile'
import DecisionPaperAutomaticTileHeader from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/DecisionPaperAutomaticTileHeader'
import useIsDecisionPaperTileExpanded from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useIsDecisionPaperTileExpanded'
import { collapseTile, expandTile } from 'redux/slices/decision-paper/tilesOverviewSlice'

const DecisionPaperAutomaticTile = ({
  tileId,
  tileCode,
  titleText,
  viewConfig: { columnSpan, isDefaultExpanded },
  isPdfView,
  children,
  currentVersion,
}) => {
  const { isExpanded } = useIsDecisionPaperTileExpanded({ tileId, isPdfView, isDefaultExpanded })
  const dispatch = useDispatch()

  const handleCollapse = useCallback(() => dispatch(collapseTile(tileId)), [dispatch, tileId])
  const handleExpand = useCallback(() => dispatch(expandTile(tileId)), [dispatch, tileId])

  const header = (
    <DecisionPaperAutomaticTileHeader
      tileId={tileId}
      titleText={titleText}
      tileCode={tileCode}
      isPdfView={isPdfView}
      isExpanded={isExpanded}
      onCollapse={handleCollapse}
      onExpand={handleExpand}
      currentVersion={currentVersion}
    />
  )

  return (
    <DecisionPaperTile columnSpan={columnSpan} header={header} isExpanded={isExpanded}>
      {children}
    </DecisionPaperTile>
  )
}

DecisionPaperAutomaticTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  tileCode: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired,
  viewConfig: PropTypes.shape({
    columnSpan: PropTypes.number.isRequired,
    isDefaultExpanded: PropTypes.bool.isRequired,
  }).isRequired,
  isPdfView: PropTypes.bool.isRequired,
  children: PropTypes.any,
  currentVersion: PropTypes.string.isRequired,
}

export default DecisionPaperAutomaticTile
