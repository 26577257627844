import PropTypes from 'prop-types'
import { createContext, useContext } from 'react'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import { useBusinessPartnerArrears } from 'routes/business-partners/arrears/useBusinessPartnerArrears'

const BusinessPartnerArrearsContext = createContext(undefined)

const BusinessPartnerArrearsContextProvider = ({ children }) => {
  const { id: businessPartnerId } = useContext(BusinessPartnerContext)

  const {
    isLoading,
    isError,
    products,
    arrears,
    productHierarchy,
    arrearApprovals,
    arrearApprovalEvents,
    arrearClassifications,
    businessPartnerPdLevel,
    allowedOperations,
    arrearsWithoutOverpayments,
  } = useBusinessPartnerArrears(businessPartnerId)

  return (
    <BusinessPartnerArrearsContext.Provider
      value={{
        isLoading,
        isError,
        businessPartnerId,
        arrears,
        productHierarchy,
        arrearApprovals,
        arrearApprovalEvents,
        products,
        arrearClassifications,
        businessPartnerPdLevel,
        allowedOperations,
        arrearsWithoutOverpayments,
      }}
    >
      {children}
    </BusinessPartnerArrearsContext.Provider>
  )
}
BusinessPartnerArrearsContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export { BusinessPartnerArrearsContext, BusinessPartnerArrearsContextProvider }
