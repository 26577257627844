import PropTypes from 'prop-types'
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import z from 'zod'
import SearchMultiComboBox from 'components/ui/multi-combo-box/SearchMultiComboBox'

const inputValueToUuidArray = (inputValue) => {
  try {
    return z.array(z.string().uuid()).parse(inputValue.split(', '))
  } catch (err) {
    return []
  }
}

const comboBoxItemsToInputValue = (items) => items.map(({ value }) => value).join(', ')

const EMPTY_INITIAL_DATA = []

/**
 * specialized version of SearchMultiComboBox that can receive UUIDs as initial value
 * to fetch and create the initially selected items
 */
const PropertiesSearchMultiComboBox = forwardRef(
  (
    {
      value = '',
      onChange,
      useInitialDataLoader = () => EMPTY_INITIAL_DATA,
      useSearchResultsLoader,
      ...otherProps
    },
    ref,
  ) => {
    const valueUuids = useMemo(() => inputValueToUuidArray(value), [value])

    const hasSetInitialItems = useRef(false)
    const [selectedItems, setSelectedItems] = useState(() => [])
    const { data: initiallySelectedData, isLoading } = useInitialDataLoader(valueUuids)

    useEffect(() => {
      if (initiallySelectedData !== undefined && hasSetInitialItems.current === false) {
        setSelectedItems(initiallySelectedData)
        hasSetInitialItems.current = true
      }
    }, [initiallySelectedData])

    useEffect(() => {
      if (hasSetInitialItems.current && !value) {
        setSelectedItems([])
      }
    }, [value])

    const onSelectionChange = useCallback(
      (nextSelectedItems) => {
        setSelectedItems(nextSelectedItems)
        if (onChange) {
          onChange(comboBoxItemsToInputValue(nextSelectedItems))
        }
      },
      [onChange],
    )

    return (
      <SearchMultiComboBox
        ref={ref}
        noTypeahead
        onSelectionChange={onSelectionChange}
        selectedItems={selectedItems}
        useLoader={useSearchResultsLoader}
        disabled={isLoading}
        {...otherProps}
      />
    )
  },
)

PropertiesSearchMultiComboBox.displayName = 'PropertiesSearchMultiComboBox'

PropertiesSearchMultiComboBox.propTypes = {
  value: PropTypes.string,
  useInitialDataLoader: PropTypes.func,
  useSearchResultsLoader: PropTypes.func.isRequired,
  onChange: PropTypes.func,
}

export default PropertiesSearchMultiComboBox
