import { TableGrowingMode, ToolbarSpacer } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ModularTable from 'components/domains/properties/modular-table/ModularTable'
import ModularTableColumn from 'components/domains/properties/modular-table/ModularTableColumn'
import ModularTableRow from 'components/domains/properties/modular-table/ModularTableRowWithCheckbox'
import ModularTableTitle from 'components/domains/properties/modular-table/ModularTableTitle'
import ModularTableToolbar from 'components/domains/properties/modular-table/toolbar/ModularToolbar'
import ModularToolbarColumnSortSettingsOnSearchParams from 'components/domains/properties/modular-table/toolbar/ModularToolbarColumnSortSettingsOnSearchParams'
import ModularToolbarColumnVisibleSettings from 'components/domains/properties/modular-table/toolbar/ModularToolbarColumnVisibleSettings'
import useDealChangeOverviewTableData from 'hooks/services/deals/change-overview/useDealChangeOverviewTableData'

const DealChangeOverviewTable = ({
  changeLogs = [],
  staffMember = {},
  tableColumns = [],
  setTableColumns = () => {},
  pagination = {
    limit: 0,
    offset: 0,
    total: 0,
  },
  onLoadMore = () => {},
}) => {
  const { t: tDealChangeOverview } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.change-overview',
  })

  const paginationConfig = {
    growing: pagination.limit < pagination.total ? TableGrowingMode.Button : TableGrowingMode.None,
    growingButtonText: t('components.ui.tables.sorted-tables.growing-button-text'),
    growingButtonSubtext: '[ ' + pagination.limit + ' / ' + pagination.total + ' ]',
    totalNumberOfItems: pagination.total,
    loadMore: onLoadMore,
  }

  const visibleColumns = tableColumns.filter(
    ({ isVisible, isSelectableForHiding }) => isVisible || !isSelectableForHiding,
  )

  const tableColumnsComponents = [
    ...visibleColumns.map(({ columnKey, title, alignment, wrapText, ...additionalColumnProps }) => (
      <ModularTableColumn
        key={columnKey}
        title={title}
        alignment={alignment}
        wrapText={wrapText}
        {...additionalColumnProps}
      />
    )),
  ]

  const tableData = useDealChangeOverviewTableData({
    changeLogs,
    staffMember,
  })

  return (
    <>
      <ModularTableToolbar>
        <ModularTableTitle
          title={tDealChangeOverview('table.title')}
          nrOfEntries={pagination.total}
        />
        <ToolbarSpacer />
        <ModularToolbarColumnVisibleSettings columns={tableColumns} setColumns={setTableColumns} />
        <ModularToolbarColumnSortSettingsOnSearchParams columns={tableColumns} />
      </ModularTableToolbar>
      <ModularTable
        additionalTableProperties={{
          id: 'deal-change-overview-table',
        }}
        columns={tableColumnsComponents}
        noDataText={tDealChangeOverview('table.no-data')}
        paginationConfig={paginationConfig}
      >
        {tableData.map((row) => {
          const { uuid } = row.original
          return (
            <ModularTableRow
              key={uuid}
              rowData={row}
              columns={visibleColumns}
              checkboxComponent={<></>}
              withArrow={false}
              withCheckBox={false}
            />
          )
        })}
      </ModularTable>
    </>
  )
}
DealChangeOverviewTable.propTypes = {
  changeLogs: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      entityId: PropTypes.string,
      entityType: PropTypes.string,
      action: PropTypes.string,
      pageKey: PropTypes.string,
      pageLink: PropTypes.string,
      cardKey: PropTypes.string,
      triggeredAt: PropTypes.string,
      triggeredBy: PropTypes.string,
    }),
  ),
  // The keys of this object are dynamic
  // eslint-disable-next-line react/forbid-prop-types
  staffMember: PropTypes.object,
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      columnKey: PropTypes.string,
      sortFieldName: PropTypes.string,
      minWidth: PropTypes.number,
      demandPopin: PropTypes.bool,
      popinText: PropTypes.string,
    }),
  ),
  setTableColumns: PropTypes.func,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
    total: PropTypes.number,
  }),
  onLoadMore: PropTypes.func,
}

export default DealChangeOverviewTable
