import { lazy, Suspense, useContext } from 'react'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import { businessEventsAllowedOperations } from 'api/events/eventAllowedOperations'
import { entityTypes } from 'api/events/status'
import LazyLoadingFallbackCard from 'components/ui/card/LazyLoadingFallbackCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import useEventsAllowedOperations from 'hooks/services/business-events-and-tasks/events/useEventsAllowedOperations'
import lazyRetry from 'lazyRetry'
import { DealContext } from 'routes/deals/DealContext'

const EventsSummaryCardWrapper = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "EventsSummaryCardWrapper" */ 'components/domains/business-events-and-tasks/events/events-summary-card/EventsSummaryCardWrapper'
      ),
    'EventsSummaryCardWrapper',
  ),
)
const ExternalTeamMembers = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ExternalTeamMembers" */ 'components/domains/deals/overview/external-team-members/ExternalTeamMembers'
      ),
    'ExternalTeamMembers',
  ),
)
const InternalTeamMembers = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "InternalTeamMembers" */ 'components/domains/deals/overview/internal-team-members/InternalTeamMembers'
      ),
    'InternalTeamMembers',
  ),
)
const MilestonesCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MilestonesCard" */ 'components/domains/deals/overview/milestones/MilestonesCard'
      ),
    'MilestonesCard',
  ),
)
const StatusHistory = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "StatusHistory" */ 'components/domains/deals/overview/status-history/StatusHistory'
      ),
    'StatusHistory',
  ),
)

const DealOverviewManagerTab = () => {
  const {
    deal: { displayId, dealUuid, status: dealStatus },
    allowedOperations,
  } = useContext(DealContext)
  const isAllowedToUpdateDealMilestones = allowedOperations.includes(
    dealsPermissions.updateDealMilestones,
  )
  const isAllowedToUpdateDealStatus = allowedOperations.includes(dealsPermissions.updateDealStatus)

  const {
    data: allowedEventsOperationsData,
    isLoading: allowedEventsOperationsIsLoading,
    isError: allowedEventsOperationsIsError,
  } = useEventsAllowedOperations()

  const eventReadAllowed = !!allowedEventsOperationsData?.allowed_operations?.includes(
    businessEventsAllowedOperations.readEvent,
  )

  const displayCreateEventButton = !!allowedEventsOperationsData?.allowed_operations.includes(
    businessEventsAllowedOperations.createEvent,
  )

  return (
    <CWPLayout>
      <CWPLayout.Half>
        <Suspense fallback={<LazyLoadingFallbackCard data-testid="StatusHistory-fallback" />}>
          <StatusHistory
            dealUuid={dealUuid}
            dlDisplayId={displayId}
            dealStatus={dealStatus}
            isAllowedToEdit={isAllowedToUpdateDealStatus}
          />
        </Suspense>
      </CWPLayout.Half>
      <CWPLayout.Half>
        <Suspense fallback={<LazyLoadingFallbackCard data-testid="MilestonesCard-fallback" />}>
          <MilestonesCard dealUuid={dealUuid} isAllowedToEdit={isAllowedToUpdateDealMilestones} />
        </Suspense>
      </CWPLayout.Half>
      {!allowedEventsOperationsIsLoading && !allowedEventsOperationsIsError && eventReadAllowed && (
        <CWPLayout.Full>
          <Suspense
            fallback={<LazyLoadingFallbackCard data-testid="EventsSummaryCardWrapper-fallback" />}
          >
            <EventsSummaryCardWrapper
              entity={{
                entityDisplayId: displayId,
                entityType: entityTypes.deal,
                entityName: 'propertyName',
                entityId: dealUuid,
              }}
              entityType={entityTypes.property}
              displayCreateEventButton={displayCreateEventButton}
            />
          </Suspense>
        </CWPLayout.Full>
      )}

      <CWPLayout.Full>
        <Suspense fallback={<LazyLoadingFallbackCard data-testid="InternalTeamMembers-fallback" />}>
          <InternalTeamMembers dealUuid={dealUuid} />
        </Suspense>
      </CWPLayout.Full>
      <CWPLayout.Full>
        <Suspense fallback={<LazyLoadingFallbackCard data-testid="ExternalTeamMembers-fallback" />}>
          <ExternalTeamMembers dealUuid={dealUuid} />
        </Suspense>
      </CWPLayout.Full>
    </CWPLayout>
  )
}

export default DealOverviewManagerTab
