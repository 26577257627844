import sortBy from 'lodash.sortby'
import uniqBy from 'lodash.uniqby'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import CardSection from 'components/domains/deals/card/CardSection'
import styles from 'components/domains/deals/covenants/check-results-card/CheckResultsCard.module.css'
import CheckResultsCardHeader from 'components/domains/deals/covenants/check-results-card/CheckResultsCardHeader'
import CheckResultsChart from 'components/domains/deals/covenants/check-results-card/CheckResultsChart'
import CovenantSelect from 'components/domains/deals/covenants/check-results-card/CovenantSelect'
import useCheckResultsChartData from 'components/domains/deals/covenants/check-results-card/useCheckResultsChartData'
import useSegmentedChartData from 'components/domains/deals/covenants/check-results-card/useSegmentedChartData'
import Card from 'components/ui/card/Card'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import { ErrorDataUnavailableInTable } from 'components/ui/errors/ErrorDataUnavailableInTable'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'

const CheckResultsCard = ({ dealUuid, preSelectedCovenantUuid = '' }) => {
  const [selectedCovenantUuid, setSelectedCovenantUuid] = useState(preSelectedCovenantUuid)
  const [isInitialCovenantSelected, setIsInitialCovenantSelected] = useState(false)

  const {
    financialMonitoringItems,
    lastMonitoringItemWithResult,
    displayUnit,
    name,
    chartData,
    isLoading,
    isError,
  } = useCheckResultsChartData({ dealUuid, selectedCovenantUuid })

  const segmentedChartData = useSegmentedChartData(chartData, lastMonitoringItemWithResult)

  const dropDownOptions = uniqBy(
    financialMonitoringItems.map(({ covenant }) => ({
      covenantUuid: covenant.covenantUuid,
      name: covenant.name,
    })),
    'covenantUuid',
  )
  const sortedDropDownOptions = sortBy(dropDownOptions, 'name')

  useEffect(() => {
    if (!!isInitialCovenantSelected || !!preSelectedCovenantUuid) return

    setSelectedCovenantUuid(sortedDropDownOptions?.[0]?.covenantUuid)
    setIsInitialCovenantSelected(!!sortedDropDownOptions?.[0]?.covenantUuid)
  }, [isInitialCovenantSelected, preSelectedCovenantUuid, sortedDropDownOptions])

  return (
    <Card
      header={
        <CheckResultsCardHeader
          className={styles.header}
          name={name}
          unit={displayUnit}
          value={lastMonitoringItemWithResult?.result?.value}
          isLoading={isLoading}
          isError={isError}
          headerAction={
            !preSelectedCovenantUuid ? (
              <CovenantSelect
                options={sortedDropDownOptions}
                value={selectedCovenantUuid}
                onChange={setSelectedCovenantUuid}
                className={styles.minWidthSelect}
              />
            ) : null
          }
        />
      }
    >
      <CardSection>
        <RequestStateResolver
          center
          isLoading={isLoading}
          isError={isError}
          errorToDisplay={<ErrorDataUnavailableInTable />}
          renderContent={() =>
            chartData.length === 0 ? (
              <EmptyCardContent />
            ) : (
              <div className={styles.chartContainer}>
                <CheckResultsChart chartData={chartData} segmentedChartData={segmentedChartData} />
              </div>
            )
          }
        />
      </CardSection>
    </Card>
  )
}

CheckResultsCard.propTypes = {
  dealUuid: PropTypes.string,
  preSelectedCovenantUuid: PropTypes.string,
}

export default CheckResultsCard
