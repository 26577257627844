import { FlexBox, FlexBoxDirection, Title, TitleLevel } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/button/VersionsHistoryListItem.module.css'
import StaffMemberByObjectIdOrEmail from 'components/ui/staff-member/StaffMemberByObjectIdOrEmail'
import LastEditedText from 'components/ui/text/LastEditedText'

const VersionsHistoryListItem = ({ version, isCurrent, lastUpdatedAt, lastUpdatedBy }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ui.buttons.versions-history.item',
  })

  const versionText = useMemo(
    () => (isCurrent ? t('version.name.current', { version }) : t('version.name', { version })),
    [isCurrent, version, t],
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Title level={TitleLevel.H5} className={styles.listItemTitle}>
        {versionText}
      </Title>
      <LastEditedText lastUpdatedAt={lastUpdatedAt}>
        <StaffMemberByObjectIdOrEmail objectIdOrEmail={lastUpdatedBy} />
      </LastEditedText>
    </FlexBox>
  )
}

VersionsHistoryListItem.propTypes = {
  version: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isCurrent: PropTypes.bool,
  lastUpdatedAt: PropTypes.string.isRequired,
  lastUpdatedBy: PropTypes.string.isRequired,
}

export default VersionsHistoryListItem
