import { useRequest } from 'hooks/services/baseService'

export const usePropertyMonitoring = (propertyUuid) =>
  useRequest({
    path: `/property-monitoring?property_id=${propertyUuid}`,
    useCache: true,
    keys: ['property-monitoring', propertyUuid],
    options: {
      retry: 0,
    },
  })
