import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { FINANCIAL_PRODUCT_BUSINESS_TYPES } from 'components/domains/deals/collaterals/details/receivablesConstants'
import { CREDIT_SYSTEMS } from 'routes/deals/collaterals/receivables/constants'

const useAddReceivable = (mutationOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({
      collateralId,
      financialProductId,
      portionId,
      priority,
      ranking,
      dataSource,
      creditSystem,
    }) => {
      const queryParams = new URLSearchParams()
      queryParams.append('dataSource', dataSource ?? FINANCIAL_PRODUCT_BUSINESS_TYPES.NEW_BUSINESS)
      queryParams.append('creditSystem', creditSystem ?? CREDIT_SYSTEMS.TRANCHES)

      await post({
        path: `/collateral-agreements/${collateralId}/receivables?${queryParams.toString()}`,
        body: {
          financial_product_id: financialProductId,
          portion_id: portionId,
          priority: priority,
          ranking: ranking,
        },
      })
    },
    mutationOptions,
  )
}

export default useAddReceivable
