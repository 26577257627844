import { TableMode } from '@fioneer/ui5-webcomponents-react'
import orderBy from 'lodash.orderby'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import SortedTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/sorted-tables/SortedTable'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/valuation-results/PropertyValuationResultsTable.module.css'
import useMapPropertyValuationResultsTableData from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/valuation-results/useMapPropertyValuationResultsTableData'
import usePropertyValuationResultsTableColumns from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/valuation-results/usePropertyValuationResultsTableColumns'

const PropertyValuationResultsTable = ({ valuationResults, isDecisionPaperPdfView = false }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.valuation-results.pages.property.valuation.results.table',
  })

  const methodEnum = useMemo(
    () =>
      Object.fromEntries(
        valuationResults.map(({ calculation_method_name: method }) => [method, method]),
      ),
    [valuationResults],
  )

  const { columnDefinitions } = usePropertyValuationResultsTableColumns({ methodEnum })

  const { tableData } = useMapPropertyValuationResultsTableData({
    valuationResults,
    isAddMode: false,
    currentEditedValues: [],
    highlightedValuations: [],
  })

  const sortedTableData = orderBy(tableData, ['validFrom.value', 'method.value'], ['desc', 'asc'])

  return (
    <SortedTable
      columnDefinitions={columnDefinitions}
      tableData={sortedTableData}
      toolbarConfig={{}}
      additionalTableProperties={{
        stickyColumnHeader: true,
        className: isDecisionPaperPdfView ? styles.valuationsTablePdfView : styles.valuationsTable,
        mode: TableMode.None,
      }}
      noDataText={t('no-data')}
    />
  )
}

PropertyValuationResultsTable.propTypes = {
  valuationResults: PropTypes.arrayOf(
    PropTypes.shape({
      calculation_method_name: PropTypes.string,
    }),
  ).isRequired,
  isDecisionPaperPdfView: PropTypes.bool,
}

export default PropertyValuationResultsTable
