import { cwpEntityTypes } from 'constants/cwpEntityTypes'

export const conditionsEntityTypes = {
  deal: cwpEntityTypes.DEAL,
  businessPartner: cwpEntityTypes.BUSINESS_PARTNER,
}

export const conditionsStatusTypes = {
  open: 'OPEN',
  inProgress: 'IN_PROGRESS',
  done: 'DONE',
}

export const conditionsMassEditOperations = {
  clear: 'clear',
  keep: 'keep',
  replace: 'replace',
}

export const conditionTypes = {
  external: 'external',
  internal: 'internal',
}

export const conditionExternalTypes = {
  precedent: 'CONDITION_PRECEDENT',
  subsequent: 'CONDITION_SUBSEQUENT',
}

export const conditionInternalTypes = {
  signing: 'INTERNAL_CONDITION_TO_SIGNING',
  drawdown: 'INTERNAL_CONDITION_TO_DRAWDOWN',
  dealCommittee: 'INTERNAL_CONDITION_TO_DEAL_COMMITTEE',
  existingBusiness: 'INTERNAL_CONDITION_EXISTING_BUSINESS',
  creditDecision: 'INTERNAL_CONDITION_TO_CREDIT_DECISION',
  postDrawdown: 'INTERNAL_CONDITION_POST_DRAWDOWN',
}

export const entityTypes = {
  property: 'PROPERTY',
  businesspartner: 'BUSINESS_PARTNER',
  deal: 'DEAL',
}

export const requirementStatusTypes = {
  open: 'OPEN',
  inProgress: 'IN_PROGRESS',
  done: 'DONE',
}
