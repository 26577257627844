import { Table, TableRow, TableColumn, TableCell } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/manual-checksheet/BaselTwoManualCheckSheetTable.module.css'
import BaselTwoManualCheckSheetTableCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/manual-checksheet/BaselTwoManualCheckSheetTableCell'

const BaselTwoManualCheckSheetTable = ({ currentAnswers }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.basel-two.checksheet.manual',
  })

  return (
    <Table
      id="BaselTwoCheckSheetTable"
      className={styles.BaselTwoCheckSheetTable}
      columns={
        <>
          <TableColumn />
          <TableColumn />
        </>
      }
      hideNoData={true}
    >
      {Object.entries(currentAnswers || {}).map(([key, value]) => (
        <TableRow key={key}>
          <TableCell>{t(key)}</TableCell>
          <TableCell>
            <BaselTwoManualCheckSheetTableCell question={key} value={value} />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}

BaselTwoManualCheckSheetTable.propTypes = {
  currentAnswers: PropTypes.shape({}),
}

export default BaselTwoManualCheckSheetTable
