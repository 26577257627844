import { useTranslation } from 'react-i18next'

const useBreachRuleTranslations = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.deals.covenants.breach' })

  const getTranslationForBreachRule = (breachRule) =>
    ({
      LIMIT_LESS_THAN_RESULT: t('limit-less-than-result'),
      LIMIT_GREATER_THAN_RESULT: t('limit-greater-than-result'),
      LIMIT_LESS_EQUAL_THAN_RESULT: t('limit-less-equal-than-result'),
      LIMIT_GREATER_EQUAL_THAN_RESULT: t('limit-greater-equal-than-result'),
    }[breachRule] ?? '')

  return { getTranslationForBreachRule }
}

export default useBreachRuleTranslations
