import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import FurtherDetailsPieChartsCard from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/FurtherDetailsPieChartsCard'
import {
  TenantColorProvider,
  useTenantColors,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/TenantColorContext'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'

const FurtherDetailsGranularityTenantIndustryTileV1 = ({ tileId, isPdfView }) => {
  const {
    data: { multiPropertyKpisByGranularity, noProperties },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { t: tTenantIndustry } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.further-details',
  })

  const getTenantIndustryRentRollKpis = (multiPropertyKpis) =>
    multiPropertyKpis?.tenantIndustryRentRollKpis ?? []

  const getTenantIndustryName = (rentRollKpi) =>
    rentRollKpi?.tenantIndustryKeyDescription ?? tTenantIndustry('none')

  if (noProperties) {
    return <NoPropertyAssignedToDealInfo />
  }
  return (
    <FurtherDetailsPieChartsCard
      colorProvider={TenantColorProvider}
      useColors={useTenantColors}
      getRentRollKpis={getTenantIndustryRentRollKpis}
      getValuations={() => {}}
      getNameFromKpi={getTenantIndustryName}
      granularityValues={{
        multiPropertyKpisByGranularity,
        multiPropertyValuationsByGranularity: {},
      }}
      isPdfView={isPdfView}
    />
  )
}

FurtherDetailsGranularityTenantIndustryTileV1.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default FurtherDetailsGranularityTenantIndustryTileV1
