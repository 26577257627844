import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionStageTypes } from 'api/decision-process/decisionProcessApi'

const useDecisionStageName = ({ name, decisionStageType }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.decision-type',
  })

  return useMemo(() => {
    switch (decisionStageType) {
      case decisionStageTypes.attendance:
        return t('attendance.name', { name })
      case decisionStageTypes.circulation:
        return t('circulation.name', { name })
      default:
        return name
    }
  }, [name, decisionStageType, t])
}

export default useDecisionStageName
