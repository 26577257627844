import { FlexBox, FlexBoxDirection, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/OpsMemoTrancheDetailsCard.module.css'
import CustomerOptionsTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/CustomerOptionsTable'
import mapCustomerOptionsBackendData from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/mapCustomerOptionsBackendData'
import mergeBackendAndEditData from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/mergeBackendAndEditData'

const OpsMemoTrancheEmbeddedOptionsCard = ({ embeddedOptions }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.ops-memo-tranche-details.embedded-options',
  })

  const isEditMode = false
  const editOptions = {}

  const backendTableData = mapCustomerOptionsBackendData(embeddedOptions, isEditMode)
  const mergedTableData = mergeBackendAndEditData(backendTableData, editOptions)

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.card}>
      <Text className={styles.cardHeaderTitle}>{t('embedded-options')}</Text>

      <FlexBox direction={FlexBoxDirection.Column} className={styles.tableWrapper}>
        <CustomerOptionsTable
          isEditMode={isEditMode}
          editOptions={editOptions}
          setEditOptions={() => {}}
          backendTableData={backendTableData}
          mergedTableData={mergedTableData}
        />
      </FlexBox>
    </FlexBox>
  )
}

OpsMemoTrancheEmbeddedOptionsCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  embeddedOptions: PropTypes.object.isRequired,
}

export default OpsMemoTrancheEmbeddedOptionsCard
