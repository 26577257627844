import { FlexBoxAlignItems } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'

const useMonitoringItemsColumnDefinitions = ({
  isPeriodicalCheck,
  hasDocumentRequirements,
  statusEnum,
  breachEnum,
  covenantCheckName,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.monitoring-items-card',
  })

  return [
    {
      columnKey: 'monitoring-item',
      title: isPeriodicalCheck
        ? t('periodical-check', { covenantCheckName })
        : t('monitoring-item'),
      isSelectableForHiding: false,
      demandPopin: false,
      filter: filterTypes.CONTAINS,
    },
    ...(isPeriodicalCheck
      ? []
      : [
          {
            columnKey: 'covenant-check',
            title: t('covenant-check'),
            popinText: t('covenant-check'),
            demandPopin: true,
            minWidth: 600,
            filter: filterTypes.CONTAINS,
          },
        ]),
    {
      columnKey: 'status',
      title: t('status'),
      popinText: t('status'),
      demandPopin: true,
      sortingDisabled: true,
      minWidth: 600,
      filter: filterTypes.OF_ENUM_TYPE,
      additionalFilterOptions: { enumValues: statusEnum },
    },
    {
      columnKey: 'business-event',
      title: t('business-event'),
      popinText: t('business-event'),
      demandPopin: true,
      minWidth: 700,
      filter: filterTypes.CONTAINS,
    },
    {
      columnKey: 'check-date',
      title: t('check-date'),
      popinText: t('check-date'),
      demandPopin: true,
      minWidth: 750,
      filter: filterTypes.BETWEEN_DATES,
      alignment: FlexBoxAlignItems.End,
    },
    ...(hasDocumentRequirements
      ? [
          {
            columnKey: 'target-delivery-date',
            title: t('target-delivery-date'),
            popinText: t('target-delivery-date'),
            demandPopin: true,
            minWidth: 750,
            filter: filterTypes.BETWEEN_DATES,
            alignment: FlexBoxAlignItems.End,
          },
        ]
      : []),
    {
      columnKey: 'breach',
      title: t('breach'),
      popinText: t('breach'),
      demandPopin: true,
      minWidth: 800,
      filter: filterTypes.OF_ENUM_TYPE,
      additionalFilterOptions: { enumValues: breachEnum },
    },
    ...(isPeriodicalCheck
      ? [
          {
            title: '',
            columnKey: 'arrow',
            sortingDisabled: true,
            alignment: FlexBoxAlignItems.End,
            isSelectableForHiding: false,
          },
        ]
      : []),
  ]
}

export default useMonitoringItemsColumnDefinitions
