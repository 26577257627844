import { useContext } from 'react'
import CollateralAgreementTile from 'components/domains/rights/details/CollateralAgreementTile'
import useCollateralPropertiesTableData from 'hooks/services/collaterals/useCollateralPropertiesTableData'
import { useMapCollateralPropertiesToTableData } from 'hooks/services/collaterals/useMapCollateralToTableData'
import useCollateralsPropertiesConfig from 'hooks/services/deals/collateral/tables/useCollateralsPropertiesConfig'
import useChargesByUuid from 'hooks/services/properties/useChargesByUuid'
import { PropertyContext } from 'routes/properties/PropertyContext'

const CollateralAgreementWrapperTile = () => {
  const { property } = useContext(PropertyContext)

  const { data: cagData } = useChargesByUuid(property.uuid)
  const cagIds = cagData?.charges.map((cag) => cag.cagId) ?? []

  const hooks = {
    useTableData: useCollateralPropertiesTableData,
    useTableColumnConfig: useCollateralsPropertiesConfig,
    useMapTableData: useMapCollateralPropertiesToTableData,
  }

  const translationKey = 'pages.property-overview.insurances.collateral-agreement'

  return <CollateralAgreementTile id={cagIds} hooks={hooks} translationKey={translationKey} />
}

export default CollateralAgreementWrapperTile
