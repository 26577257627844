import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import TableWrappingTextCell from 'components/domains/conditions/overview/table-cells/generic/TableWrappingTextCell'
import ConditionDealExternalAssigneeSelect from 'components/domains/conditions/shared/assignee/ConditionDealExternalAssigneeSelect'
import useRequirementsCellAddMode from 'hooks/services/conditions/edit-mode/useRequirementsCellAddMode'
import useRequirementsCellEditMode from 'hooks/services/conditions/edit-mode/useRequirementsCellEditMode'
import { requirementExternalAssigneeChanged } from 'redux/slices/conditions/requirementsTableSlice'

const RequirementsTableDealExternalAssigneeSubcomponent = ({ requirementId, externalAssignee }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.columns.external-assignee',
  })
  const dispatch = useDispatch()
  const { isAddModeForCurrentRow } = useRequirementsCellAddMode({
    requirementId,
  })
  const { isEditModeForCurrentRow } = useRequirementsCellEditMode({
    requirementId,
  })

  const handleOnAssigneeChange = useCallback(
    (newAssignee) => {
      dispatch(requirementExternalAssigneeChanged({ externalAssignee: newAssignee }))
    },
    [dispatch],
  )

  return (
    <div>
      <Label>{t('label')}</Label>
      {isAddModeForCurrentRow || isEditModeForCurrentRow ? (
        <div>
          <ConditionDealExternalAssigneeSelect
            externalAssignee={externalAssignee?.name || externalAssignee?.email}
            onAssigneeChange={handleOnAssigneeChange}
          />
        </div>
      ) : (
        <div>
          <TableWrappingTextCell value={externalAssignee?.name || externalAssignee?.email} />
        </div>
      )}
    </div>
  )
}

RequirementsTableDealExternalAssigneeSubcomponent.propTypes = {
  requirementId: PropTypes.string,
  externalAssignee: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string.isRequired,
  }),
}

export default RequirementsTableDealExternalAssigneeSubcomponent
