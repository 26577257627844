import isEmpty from 'lodash.isempty'

const requirementNameChanged = (
  state,
  { payload: { conditionIndex, requirementIndex, name: newName } },
) => {
  if (
    !state.conditions[conditionIndex] ||
    !state.conditions[conditionIndex].requirements[requirementIndex]
  ) {
    return
  }
  state.conditions[conditionIndex].requirements[requirementIndex].name = newName

  delete state.errorRows[conditionIndex]?.[requirementIndex]?.name
  if (isEmpty(state.errorRows[conditionIndex]?.[requirementIndex])) {
    delete state.errorRows[conditionIndex]?.[requirementIndex]
  }
  if (isEmpty(state.errorRows[conditionIndex])) {
    delete state.errorRows[conditionIndex]
  }
}

export default requirementNameChanged
