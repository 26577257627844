import z from 'zod'

/**
 * @typedef {z.TypeOf<typeof customFieldsSchema>} CustomFields
 */
export const customFieldsSchema = z.object({
  value: z.string().nullish(),
  type: z.string().nullish(),
  categoryShortText: z.string().nullish(),
})

/**
 * @typedef {z.TypeOf<typeof currentBusinessEventSchema>} CurrentBusinessEvent
 */
export const currentBusinessEventSchema = z.object({
  id: z.string().nullish(),
  displayId: z.string().nullish(),
  name: z.string().nullish(),
})

/**
 * @typedef {z.TypeOf<typeof createdOnSchema>} CreatedOn
 */
export const createdOnSchema = z.object({
  createdBy: z.string().nullish(),
  createdOn: z.string().nullish(),
})

/**
 * @typedef {z.TypeOf<typeof forbearanceStatusVersion>} ForbearanceStatusVersion
 */
export const forbearanceStatusVersion = z.object({
  id: z.string(),
  version: z.string(),
  lifecycleStatusTypeCode: z.string(),
  lifecycleStatusShortText: z.string(),
  prioritizedForbearanceMeasureTypeCode: z.string().nullish(),
  prioritizedForbearanceMeasureShortText: z.string().nullish(),
  createdOn: createdOnSchema,
  startDate: z.string().nullish(),
  forbearanceStatusTypeCode: z.string(),
  currentBusinessEvent: currentBusinessEventSchema.nullish(),
  customFields: z.record(customFieldsSchema).nullish(),
})

/**
 * @typedef {z.TypeOf<typeof forbearanceStatusesSchema>} ForbearanceStatuses
 * @property {ForbearanceStatusVersion[]} forbearanceStatusVersions
 */
export const forbearanceStatusesSchema = z.object({
  forbearanceStatuses: z
    .array(
      z.object({
        id: z.string(),
        forbearanceStatusTypeCode: z.string(),
        forbearanceStatusShortText: z.string(),
        version: z.string(),
        lifecycleStatusTypeCode: z.string(),
        lifecycleStatusShortText: z.string(),
        createdOn: createdOnSchema.nullish(),
        forbearanceStatusVersions: forbearanceStatusVersion.array(),
      }),
    )
    .optional(),
})
