import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import LoadingMultiComboBox from 'components/ui/multi-combo-box/LoadingMultiComboBox'
import useDeprecatedValues from 'hooks/common/useDeprecatedValues'
import useVisibilities from 'hooks/services/conditions/config/useVisibilities'
import { conditionVisibilitiesChanged } from 'redux/slices/conditions/conditionsTableSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const ConditionsTableExternalVisibilitiesEditCell = ({ visibilities: conditionVisibilities }) => {
  const dispatch = useDispatch()
  const {
    entityRef: { entityType },
  } = useContext(ConditionsContext)
  const { data: { visibilities: availableVisibilities = [] } = {}, ...useVisibilitiesResult } =
    useVisibilities({ includeDeprecated: false, entityType })

  const { deprecatedValues } = useDeprecatedValues({
    availableValues: availableVisibilities,
    selectedValues: conditionVisibilities,
  })

  const visibilitiesResult = useMemo(
    () => ({
      ...useVisibilitiesResult,
      data: {
        visibilities: [...deprecatedValues, ...availableVisibilities],
      },
    }),
    [availableVisibilities, deprecatedValues, useVisibilitiesResult],
  )

  const visibilitiesLoader = useCallback(() => visibilitiesResult, [visibilitiesResult])

  const visibilityCodes = conditionVisibilities.map(({ code }) => code)
  const onSelectionChange = useCallback(
    ({ detail: { items: newSelectedVisibilities } }) => {
      const newVisibilities = newSelectedVisibilities.map((item) => item.getAttribute('data-value'))
      dispatch(conditionVisibilitiesChanged({ visibilities: newVisibilities }))
    },
    [dispatch],
  )

  return (
    <LoadingMultiComboBox
      useLoader={visibilitiesLoader}
      selected={visibilityCodes}
      optionDisplayName={'name'}
      selectionName={'visibilities'}
      onSelectionChange={onSelectionChange}
    />
  )
}

ConditionsTableExternalVisibilitiesEditCell.propTypes = {
  visibilities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default ConditionsTableExternalVisibilitiesEditCell
