import { FlexBox, FlexBoxDirection, Grid, Text } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import TextField from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/fields/TextField'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/OpsMemoTrancheDetailsCard.module.css'
import {
  useNumberFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const OpsMemoTrancheAdditionalInterestInformationCard = ({ tranche }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.ops-memo-tranche-details.additional-interest-information',
  })
  const formatPercentage = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })
  const { format: formatDate } = useShortDateFormatter()

  const renderMinimumAllInRate = () => {
    const contractualFloorStrike = tranche?.additionalInterestInformation?.contractualFloorStrike
    let minimumAllInRate = 0

    if (!isNil(contractualFloorStrike)) {
      const interestConditionsItems = tranche?.interestConditions?.items
        .filter((item) => item.validFrom && item.customerMargin)
        .sort((a, b) => new Date(a.validFrom) - new Date(b.validFrom))

      const customerMargin = interestConditionsItems[0]?.customerMargin || 0

      minimumAllInRate = contractualFloorStrike + customerMargin
    }

    return <TextField label={t('minimum-all-in-rate')} value={formatPercentage(minimumAllInRate)} />
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.card}>
      <Text className={styles.cardHeaderTitle}>{t('additional-interest-information')}</Text>

      <Grid defaultSpan="XL6 L6 M12 S12">
        <FlexBox direction={FlexBoxDirection.Column}>
          <TextField
            label={t('end-of-fixed-interest-period')}
            value={
              tranche?.additionalInterestInformation?.endOfTheFixedInterestPeriod
                ? formatDate(tranche?.additionalInterestInformation?.endOfTheFixedInterestPeriod)
                : ''
            }
            isFirstItem={true}
          />

          <TextField
            label={t('contractual-floor-strike')}
            value={
              !isNil(tranche?.additionalInterestInformation?.contractualFloorStrike)
                ? formatPercentage(tranche?.additionalInterestInformation?.contractualFloorStrike)
                : ''
            }
          />

          {renderMinimumAllInRate()}

          <TextField
            label={t('penalty-interest')}
            value={tranche?.additionalInterestInformation?.penaltyInterest}
          />

          <TextField
            label={t('holiday-calendar')}
            value={tranche?.additionalInterestInformation?.holidayCalendar}
          />

          <TextField
            label={t('rolling-convention')}
            value={tranche?.additionalInterestInformation?.dateRollingConventionShortText}
          />
        </FlexBox>

        <FlexBox direction={FlexBoxDirection.Column}>
          <TextField
            label={t('deviating-interest-payment-date')}
            value={tranche?.additionalInterestInformation?.deviatingInterestPaymentDate}
            isFirstItem={true}
          />

          <TextField
            label={t('average-interest-rate-calculation')}
            value={tranche?.additionalInterestInformation?.averageInterestRateCalculationShortText}
          />

          <TextField
            label={t('lock-out-period')}
            value={tranche?.additionalInterestInformation?.lockOutPeriod}
          />

          <TextField
            label={t('lookback-period')}
            value={tranche?.additionalInterestInformation?.lookbackPeriod}
          />

          <TextField
            label={t('observation-period')}
            value={tranche?.additionalInterestInformation?.observationPeriod}
          />
        </FlexBox>
      </Grid>
    </FlexBox>
  )
}

OpsMemoTrancheAdditionalInterestInformationCard.propTypes = {
  tranche: PropTypes.shape({
    additionalInterestInformation: PropTypes.shape({
      contractualFloorStrike: PropTypes.number,
      endOfTheFixedInterestPeriod: PropTypes.string,
      penaltyInterest: PropTypes.string,
      holidayCalendar: PropTypes.string,
      dateRollingConventionShortText: PropTypes.string,
      deviatingInterestPaymentDate: PropTypes.string,
      averageInterestRateCalculationShortText: PropTypes.string,
      lockOutPeriod: PropTypes.string,
      lookbackPeriod: PropTypes.string,
      observationPeriod: PropTypes.string,
    }),
    interestConditions: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          validFrom: PropTypes.string,
          customerMargin: PropTypes.number,
        }),
      ),
    }),
  }),
}

export default OpsMemoTrancheAdditionalInterestInformationCard
