import { ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import LoadingButton from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/button/LoadingButton'

const propTypes = {
  /** The unique identifier of this button. Although this is optional in many cases, it is recommended to use it. */
  id: PropTypes.string,
  /** The label text that is placed within the button area. */
  label: PropTypes.string,
  /**
   * The icon specified by its name from the SAP-icons font library.
   *
   * If a label is given as well, its default position is before the text. This can be controlled by custom buttonProps `iconEnd`.
   * Without a label it is placed in the middle of the button.
   */
  icon: PropTypes.string,
  /** A function executed when clicking on the button. */
  onClick: PropTypes.func,
  /**
   * Options to modify how the Cell will look like.
   *
   * - `design` Changing the button design to any of the enum values from the UI5 ButtonDesign. Defaults to ButtonDesign.Transparent.
   * - `disabled` Disables the entire cell, prohibiting onClick and visually indicating this state. Defaults to false.
   * - `buttonProps` Custom button props passed directly to the UI5 Button component. Defaults to an empty object.
   */
  options: PropTypes.shape({
    design: PropTypes.string,
    disabled: PropTypes.bool,
    buttonProps: PropTypes.shape({}),
  }),
  /** Defines whether a loading spinner is shown. */
  isLoading: PropTypes.bool,
  /** Defines whether a loading spinner is shown. */
  isFetching: PropTypes.bool,
  /** Defines whether an error is shown. */
  isError: PropTypes.bool,
  /** Defines additional styling passed to the UI5 Button component. */
  className: PropTypes.string,
}

/**
 * Shared Button Cell for usage within the Responsive and the Analytical Table.
 *
 * It renders a single button, based on the LoadingButton component, which itself defaults to the UI5 Button if no request states are provided.
 *
 * @typedef Props
 * @property {String} id The unique identifier of this button.
 * @property {String} label The label text that is placed within the button area.
 * @property {String} icon The icon specified by its name from the SAP-icons font library.
 * @property {Function} onClick A function executed when clicking on the button.
 * @property {Object} options Options to modify how the Cell will look like.
 * @property {ButtonDesign} options.design Changing the button design to any of the enum values from the UI5 ButtonDesign. Defaults to ButtonDesign.Transparent.
 * @property {Boolean} options.disabled Disables the entire cell, prohibiting onClick and visually indicating this state. Defaults to false.
 * @property {Object} options.buttonProps Custom button props passed directly to the UI5 Button component. Defaults to an empty object.
 * @property {Boolean} isLoading Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} isFetching Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} isError Defines whether an error is shown.
 * @property {String} className Defines additional styling passed to the UI5 Button component.
 */

/**
 * @param {Props} props Note for Storybook: cf. code for complete JSDoc
 */
const ButtonCell = ({
  id,
  label,
  icon,
  onClick = () => {},
  options: { design = ButtonDesign.Transparent, disabled = false, buttonProps = {} } = {},
  isLoading = false,
  isFetching = false,
  isError = false,
  className,
}) => {
  if (isNil(label) && isNil(icon)) {
    return <div />
  }
  return (
    <LoadingButton
      id={id}
      icon={icon}
      renderContent={() => label}
      design={design}
      disabled={disabled}
      onClick={onClick}
      isLoading={isLoading || isFetching}
      isError={isError}
      {...buttonProps}
      className={className}
    />
  )
}

ButtonCell.propTypes = propTypes

export default ButtonCell
