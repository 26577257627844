import PropTypes from 'prop-types'
import styles from 'components/domains/properties/general-information/rating/PropertyRatingTile.module.css'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const PropertyRatingTile = ({ keyDate, validFrom, tRating }) => {
  const { format: formatDate } = useShortDateFormatter()

  const getFormattedDate = (date) => (date ? formatDate(date) : null)

  const getJoinedClassName = (classNames) =>
    classNames.map((className) => styles[className]).join(' ')

  return (
    <div className={styles['rating-tile']}>
      <div className={getJoinedClassName(['grid-area-label-key-date', 'rating-label'])}>
        {tRating('key-date')}
      </div>
      <div className={getJoinedClassName(['grid-area-key-date'])}>{getFormattedDate(keyDate)}</div>
      <div className={getJoinedClassName(['grid-area-label-valid-from', 'rating-label'])}>
        {tRating('valid-from-date')}
      </div>
      <div className={getJoinedClassName(['grid-area-valid-from'])}>
        {getFormattedDate(validFrom)}
      </div>
    </div>
  )
}

PropertyRatingTile.propTypes = {
  keyDate: PropTypes.string,
  validFrom: PropTypes.string,
  tRating: PropTypes.func.isRequired,
}

export default PropertyRatingTile
