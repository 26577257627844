import isNil from 'lodash.isnil'
import {
  TABLE_ROW_WITH_HEADQUARTER_CURRENCY_HEIGHT,
  TABLE_ROW_WITHOUT_HEADQUARTER_CURRENCY_HEIGHT,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche/constants'

const mapSummaryData = ({ summary, summaryHeadquarter }) => {
  let primary
  let secondary

  if (!isNil(summary) && isNil(summaryHeadquarter)) {
    primary = summary
  } else if (isNil(summary) && !isNil(summaryHeadquarter)) {
    primary = summaryHeadquarter
  } else {
    primary = summary
    secondary = summaryHeadquarter
  }

  return {
    totalCommitment: {
      primary: {
        value: primary.totalCommitment,
        currency: primary.currency,
      },
      secondary: {
        value: secondary?.totalCommitment,
        currency: secondary?.currency,
      },
    },
    commitment: {
      primary: {
        value: primary.ownCommitment,
        currency: primary.currency,
      },
      secondary: {
        value: secondary?.ownCommitment,
        currency: secondary?.currency,
      },
      share: primary.ownCommitmentShare,
    },
    syndicatedAmountTotal: {
      primary: {
        value: primary.totalCommitment - primary.ownCommitment,
        currency: primary.currency,
      },
      secondary: {
        value: secondary ? secondary?.totalCommitment - secondary?.ownCommitment : undefined,
        currency: secondary?.currency,
      },
      share: 1 - primary.ownCommitmentShare,
    },
    outstanding: {
      primary: {
        value: primary.outstanding,
        currency: primary.currency,
      },
      secondary: {
        value: secondary?.outstanding,
        currency: secondary?.currency,
      },
    },
    available: {
      primary: {
        value: primary.available,
        currency: primary.currency,
      },
      secondary: {
        value: secondary?.available,
        currency: secondary?.currency,
      },
    },
    rowHeight:
      primary && secondary && primary?.currency !== secondary?.currency
        ? TABLE_ROW_WITH_HEADQUARTER_CURRENCY_HEIGHT
        : TABLE_ROW_WITHOUT_HEADQUARTER_CURRENCY_HEIGHT,
  }
}

export default mapSummaryData
