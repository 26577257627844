import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Link,
  Text,
  Toolbar,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import paths from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-area/shared/paths'
import FormattedAddress from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-area/shared/ui/FormattedAddress'
import SmallLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-area/shared/ui/SmallLoadingWrapper'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-area/shared/useI18n'

const PortfolioFinancedAssetsAreaMarker = ({ marker }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix:
      'components.business-events-and-tasks.decision-papers.tiles.financed-asset-area.marker.popup',
  })
  const { format: formatDate } = useShortDateFormatter()

  const renderMarketValue = () => <Text>{marker.valuation.data}</Text>

  // inline styling because css styling would be overwritten by ui5 styling
  return (
    <>
      <FlexBox direction={FlexBoxDirection.Column}>
        <Link href={`/${paths.properties}/${marker.id}`} target="_blank" rel="noreferrer">
          <b>{marker.name}</b>
        </Link>
        <Text>{marker.id}</Text>
        <Toolbar style={{ height: '10px', marginBottom: '6px' }} />
      </FlexBox>
      <FlexBox
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        style={{ marginBottom: '.5rem' }}
      >
        <Label>{t('address')}</Label>
        {marker.address ? (
          <FlexBox alignItems={FlexBoxAlignItems.End} direction={FlexBoxDirection.Column}>
            <FormattedAddress
              country={marker.address.country}
              street={marker.address.street}
              houseNumber={marker.address.houseNumber}
              zipCode={marker.address.zipCode}
              city={marker.address.city}
            />
          </FlexBox>
        ) : (
          <></>
        )}
      </FlexBox>
      <FlexBox
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        style={{ marginBottom: '.5rem' }}
      >
        <Label>{t('type')}</Label>
        <Text style={{ textAlign: 'end' }}>{marker.propertyType}</Text>
      </FlexBox>
      <FlexBox
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        style={{ marginBottom: '.5rem' }}
      >
        <Label>{t('creation-date')}</Label>
        <Text>{formatDate(marker.creationDate)}</Text>
      </FlexBox>
      <FlexBox
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        style={{ marginBottom: '.5rem' }}
      >
        <Label>{t('market-value')}</Label>
        <SmallLoadingWrapper
          isLoading={marker.valuation.isLoading}
          isError={marker.valuation.isError}
          error={t('market-value.error')}
          renderContent={renderMarketValue}
        />
      </FlexBox>
    </>
  )
}

PortfolioFinancedAssetsAreaMarker.propTypes = {
  marker: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    address: PropTypes.shape({
      country: PropTypes.string,
      street: PropTypes.string,
      houseNumber: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
    }),
    propertyType: PropTypes.string.isRequired,
    creationDate: PropTypes.string,
    valuation: PropTypes.shape({
      isLoading: PropTypes.bool.isRequired,
      isError: PropTypes.bool.isRequired,
      data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
  }).isRequired,
}

export default PortfolioFinancedAssetsAreaMarker
