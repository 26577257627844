import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import ConditionsTableExternalVisibilitiesEditCell from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableExternalVisibilitiesEditCell'
import useConditionsCellEditMode from 'hooks/services/conditions/edit-mode/useConditionsCellEditMode'

const ConditionsTableExternalVisibilitiesCell = ({ value: { visibilities, conditionId } }) => {
  const reducedVisibilities = useMemo(
    () =>
      visibilities.reduce((currentVisibilityString, { name: visibilityName }, currentIndex) => {
        if (currentIndex === 0) {
          return visibilityName
        }
        return `${currentVisibilityString} • ${visibilityName}`
      }, ''),
    [visibilities],
  )

  const { isEditModeForCurrentRow } = useConditionsCellEditMode({
    conditionId,
  })

  if (isEditModeForCurrentRow) {
    return <ConditionsTableExternalVisibilitiesEditCell visibilities={visibilities} />
  }
  return <Text wrapping>{reducedVisibilities}</Text>
}

ConditionsTableExternalVisibilitiesCell.propTypes = {
  value: PropTypes.shape({
    conditionId: PropTypes.string.isRequired,
    visibilities: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
}

export default ConditionsTableExternalVisibilitiesCell
