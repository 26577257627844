import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import useGetDocumentTypes from 'hooks/services/documents/useGetDocumentTypes'
import {
  resetRequirementRowHasError,
  requirementRowHasError,
} from 'redux/slices/conditions/requirementsTableSlice'

const useRequirementsTableDocumentTypeErrors = ({
  references,
  entityType,
  entityId,
  currentDocumentType,
  requirementId,
}) => {
  const dispatch = useDispatch()

  const documentTypesEntityRefs = useMemo(() => {
    switch (entityType) {
      case DocumentTypes.BusinessPartner:
        return {
          entityRefs: [
            { type: DocumentTypes.Requirement, id: 'no-id' },
            { type: entityType, id: entityId },
          ],
        }

      case DocumentTypes.Deal:
        if (references?.entityIds && references?.entityIds.length > 0) {
          return {
            entityRefs: [
              { type: DocumentTypes.Requirement, id: 'no-id' },
              ...references.entityIds.map((id) => ({ type: references.entityType, id })),
            ],
          }
        }

        return {
          entityRefs: [
            { type: DocumentTypes.Requirement, id: 'no-id' },
            { type: entityType, id: entityId },
          ],
        }
      default:
        break
    }
  }, [entityId, entityType, references])

  const { data: documentTypes, isLoading, isError } = useGetDocumentTypes(documentTypesEntityRefs)

  const isDocumentTypeInvalid = useMemo(() => {
    if (isLoading) {
      return false
    }
    return (
      isError || !currentDocumentType || !documentTypes.documentTypes.includes(currentDocumentType)
    )
  }, [isLoading, isError, currentDocumentType, documentTypes?.documentTypes])

  useEffect(() => {
    if (isDocumentTypeInvalid) {
      dispatch(requirementRowHasError({ id: requirementId, error: { hasError: true } }))
      return
    }
    dispatch(resetRequirementRowHasError({ id: requirementId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDocumentTypeInvalid])

  return useMemo(
    () => ({
      isDocumentTypeInvalid,
    }),
    [isDocumentTypeInvalid],
  )
}

export default useRequirementsTableDocumentTypeErrors
