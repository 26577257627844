import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js'
import {
  Button,
  ButtonDesign,
  Dialog,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Input,
  Modals,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useContext, useState, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from 'components/domains/deals/deal-cashflow/CreateCashflowScenarioDialog.module.css'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import { useGetAndAppendWorkingVersionSearchParam } from 'components/domains/deals/useGetAndAppendWorkingVersionSearchParam'
import LoadingButton from 'components/ui/button/LoadingButton'
import Labeled from 'components/ui/data/Labeled'
import TextAreaWithMaxCharacterRestriction from 'components/ui/input/TextAreaWithMaxCharacterRestriction'
import { useFormMapped } from 'hooks/form/useFormMapped'
import { useFormValidations } from 'hooks/form/useFormValidations'
import useCreateCashflowScenario from 'hooks/services/deals/cashflow/useCreateCashflowScenario'
import { DealContext } from 'routes/deals/DealContext'

const cashflowDescriptionMaxLength = 200
const cashflowNameMaxLength = 75

const DialogMode = {
  CREATE_CASHFLOW: 'CREATE_CASHFLOW',
  DUPLICATE_CASHFLOW: 'DUPLICATE_CASHFLOW',
}

const CreateCashflowScenarioDialog = ({
  isOpen,
  setIsOpen,
  dealId,
  existingScenarioUuidForCopy,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.cashflow.create-scenario',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const { required } = useFormValidations()
  const { deal: { dealUuid: dealUuid } = {} } = useContext(DealContext)
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const showToast = Modals.useShowToast()
  const showErrorMessageBox = useShowErrorMessageBox()

  const { appendWorkingVersionSearchParam } = useGetAndAppendWorkingVersionSearchParam()

  const [isCreationLoading, setIsCreationLoading] = useState(false)
  const currentDialogMode = existingScenarioUuidForCopy
    ? DialogMode.DUPLICATE_CASHFLOW
    : DialogMode.CREATE_CASHFLOW
  const isDialogMode = (queryValue) => queryValue === currentDialogMode

  /* Form & Dialog Methods */
  const formMethods = useForm({ mode: 'onChange' })
  const {
    reset: resetState,
    formState: { isValid },
    register,
    handleSubmit,
  } = useFormMapped(formMethods)

  const registerName = register('name', {
    validate: {
      required: required(),
    },
  })

  const onClose = () => {
    resetState()
    setIsOpen(false)
  }

  /* Sumbit Handling */
  const onSubmitSuccess = (responseBody) => {
    queryClient.invalidateQueries('deals', dealUuid, 'cashflow-scenarios')
    const newCashflowScenarioUuid = responseBody.cashflowScenarioUuid
    showToast(
      {
        children: isDialogMode(DialogMode.CREATE_CASHFLOW)
          ? t('toast-message.success')
          : t('toast-message.success-duplicate'),
      },
      document.body,
    )
    navigate(
      appendWorkingVersionSearchParam(`/deals/${dealId}/cashflows/${newCashflowScenarioUuid}`),
    )
  }
  const onSubmitError = async (error) => {
    const message = isDialogMode(DialogMode.CREATE_CASHFLOW)
      ? t('toast-message.error')
      : t('toast-message.error-duplicate')
    const { errors: [errorResponse] = [] } = await error.response.json()
    setIsCreationLoading(false)
    showErrorMessageBox({ message, error: errorResponse })
  }
  const createCashflowScenario = useCreateCashflowScenario({
    onSuccess: onSubmitSuccess,
    onError: onSubmitError,
  })
  const createAction = (values) => createCashflowScenario.mutateAsync(values)
  const onSubmit = useCallback(
    (cfScenario) => {
      setIsCreationLoading(true)
      const cfScenarioClone = JSON.parse(JSON.stringify(cfScenario))
      if (isDialogMode(DialogMode.DUPLICATE_CASHFLOW)) {
        cfScenarioClone['copyFromExistingScenario'] = {
          originalScenarioUuid: existingScenarioUuidForCopy,
        }
      }
      createAction({ dealUuid: dealUuid, cfScenario: cfScenarioClone })
    },
    [dealUuid, existingScenarioUuidForCopy],
  )

  /* Layout */
  const dialogFooter = (
    <FlexBox
      className={styles['dialog-footer']}
      justifyContent={FlexBoxJustifyContent.End}
      alignItems={FlexBoxAlignItems.Center}
    >
      <LoadingButton
        id="save-button"
        design={ButtonDesign.Emphasized}
        isLoading={isCreationLoading}
        isError={false}
        renderContent={() =>
          isDialogMode(DialogMode.CREATE_CASHFLOW)
            ? tNoPrefix('buttons.save')
            : tNoPrefix('buttons.duplicate')
        }
        onClick={handleSubmit(onSubmit)}
        type="submit"
        disabled={!isValid || isCreationLoading}
      />
      <Button
        id="cancel-button"
        design={ButtonDesign.Transparent}
        onClick={onClose}
        disabled={isCreationLoading}
      >
        {tNoPrefix('buttons.cancel')}
      </Button>
    </FlexBox>
  )

  return createPortal(
    <Dialog
      id="deal-cashflow-create-cashflow-dialog"
      open={isOpen}
      onBeforeClose={onClose}
      onClose={onClose}
      headerText={isDialogMode(DialogMode.CREATE_CASHFLOW) ? t('title') : t('title-duplicate')}
      footer={dialogFooter}
      draggable
      resizable
      className={styles['create-cashflow-scenario-dialog']}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Labeled label={t('label.scenario-name')} vertical showColon required>
          <Input
            id="create-scenario-name-input"
            disabled={isCreationLoading}
            maxlength={cashflowNameMaxLength}
            {...registerName}
            onInput={registerName.onChange}
          />
        </Labeled>
        <Labeled label={t('label.scenario-description')} vertical showColon>
          <TextAreaWithMaxCharacterRestriction
            id="create-scenario-description-textarea"
            disabled={isCreationLoading}
            maxLength={cashflowDescriptionMaxLength}
            rows="9"
            growing="false"
            {...register('description', {
              handlerMappings: {
                onChange: (handler) => ({ onInput: (_, evt) => handler(evt) }),
              },
            })}
          />
        </Labeled>
      </form>
    </Dialog>,
    document.body,
  )
}

CreateCashflowScenarioDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  dealId: PropTypes.string.isRequired,
  existingScenarioUuidForCopy: PropTypes.string,
}

export default CreateCashflowScenarioDialog
