import {
  BusyIndicatorSize,
  CheckBox,
  FlexBox,
  FlexBoxJustifyContent,
  Label,
  TableCell,
  TableRow,
  TableRowType,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { monitoringItemShape } from 'components/domains/deals/covenant-check-detail/checked-covenants/CheckedCovenantPropTypes'
import styles from 'components/domains/deals/covenant-check-detail/checked-covenants/CheckedCovenantsTable.module.css'
import BreachCell from 'components/domains/deals/covenants/shared/BreachCell'
import LimitCell from 'components/domains/deals/covenants/shared/LimitCell'
import StatusCell from 'components/domains/deals/covenants/shared/StatusCell'
import { useBreachTranslation } from 'components/domains/deals/covenants/shared/useBreachTranslations'
import useCwpEntityToMainEntity from 'components/domains/deals/covenants/shared/useCwpEntityToMainEntity'
import TableRowActionButtons from 'components/domains/deals/tables/TableRowActionButtons'
import Entity from 'components/ui/data/Entity'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import useDeal from 'hooks/services/deals/useDeal'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import { editButtonStateEnum } from 'routes/deals/covenant-checks/useCalculateEditButtonState'
import paths from 'routes/paths'

const CheckedCovenantDisplayRow = ({
  monitoringItem,
  entityType,
  onEditClick,
  editButtonState,
  ...props
}) => {
  const { t: tNoPrefix } = useTranslation('translation')
  const { format: formatDate } = useShortDateFormatter()
  const { cwpEntityToMainEntity } = useCwpEntityToMainEntity()
  const {
    covenant,
    status,
    dueDate,
    newTargetDeliveryDate,
    softLimit,
    hardLimit,
    result,
    breach,
    resultOfBreach,
    waived,
  } = monitoringItem ?? {}

  const isCovenantDealMainEntityType = covenant?.mainEntity?.type === mainEntityTypes.DEAL
  const dealUuid = isCovenantDealMainEntityType ? covenant.mainEntity.id : null
  const { data: dealData, isLoading, isFetching, isError: isDealError } = useDeal(dealUuid)
  const isDealLoading = isLoading && isFetching
  let navigationLink
  switch (covenant?.mainEntity?.type) {
    case mainEntityTypes.DEAL:
      navigationLink = `/${paths.deals}/${dealData?.dealId}/${dealDetailPaths.covenants}/${covenant?.covenantUuid}`
      break
    case mainEntityTypes.BUSINESSPARTNER:
      navigationLink = `/${paths.businessPartners}/${covenant?.mainEntity?.id}/covenants/${covenant?.covenantUuid}`
      break
  }
  const getCovenantTypeTranslation = (type) =>
    ({
      FINANCIAL: tNoPrefix('pages.deals.covenants.financial'),
      NON_FINANCIAL: tNoPrefix('pages.deals.covenants.non-financial'),
    }[type] ?? '')
  const { getTranslationForBreach } = useBreachTranslation()

  const renderNoData = () => (
    <Label className={styles.errorText}>
      {tNoPrefix('components.deals.covenants.checked-covenants.no-data')}
    </Label>
  )

  const renderBreachCell = () => {
    if (isNil(breach)) {
      return renderNoData()
    }
    if (breach === 'POSTPONED') {
      return getTranslationForBreach(breach)
    }
    return (
      <BreachCell
        breach={breach}
        popoverContent={
          <Text className={styles.errorText}>
            {tNoPrefix('components.deals.covenants.monitoring-items-card.result-of-breach')}
            {resultOfBreach?.name}
          </Text>
        }
      />
    )
  }

  return (
    <TableRow
      className={
        breach && !['NO', 'POSTPONED'].includes(breach) ? styles.markLeftBorder : undefined
      }
      type={TableRowType.Inactive}
      {...props}
    >
      <TableCell>
        <RequestStateResolver
          isLoading={isDealLoading}
          isError={isDealError}
          errorToDisplay={<ErrorDataUnavailableInCell />}
          busyIndicatorSize={BusyIndicatorSize.Small}
          renderContent={() => (
            <Entity
              name={covenant?.name}
              link={navigationLink}
              openInNewTab={cwpEntityToMainEntity[entityType] !== covenant?.mainEntity?.type}
              onClick={() => {}}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Text>{getCovenantTypeTranslation(covenant?.type)}</Text>
      </TableCell>
      <TableCell>
        <StatusCell status={status} />
      </TableCell>
      <TableCell>
        <FlexBox justifyContent={FlexBoxJustifyContent.End}>
          <Text>{formatDate(dueDate?.targetDeliveryDate)}</Text>
        </FlexBox>
      </TableCell>
      <TableCell>
        <FlexBox justifyContent={FlexBoxJustifyContent.End}>
          <Text>{formatDate(newTargetDeliveryDate)}</Text>
        </FlexBox>
      </TableCell>
      <TableCell>
        <FlexBox justifyContent={FlexBoxJustifyContent.End}>
          <LimitCell
            value={softLimit?.value}
            currencyCode={softLimit?.currencyCode}
            unit={softLimit?.unit}
          />
        </FlexBox>
      </TableCell>
      <TableCell>
        <FlexBox justifyContent={FlexBoxJustifyContent.End}>
          <LimitCell
            value={hardLimit?.value}
            currencyCode={hardLimit?.currencyCode}
            unit={hardLimit?.unit}
          />
        </FlexBox>
      </TableCell>
      <TableCell>
        <FlexBox justifyContent={FlexBoxJustifyContent.End}>
          {isNil(result?.value) && covenant?.type === 'FINANCIAL' ? (
            renderNoData()
          ) : (
            <LimitCell
              value={result?.value}
              currencyCode={result?.currencyCode}
              unit={result?.unit}
            />
          )}
        </FlexBox>
      </TableCell>
      <TableCell>{renderBreachCell()}</TableCell>
      <TableCell>
        <CheckBox checked={!!waived} disabled />
      </TableCell>
      <TableCell>
        <TableRowActionButtons
          isEditable={editButtonState !== editButtonStateEnum.HIDDEN}
          isEditDisabled={editButtonState === editButtonStateEnum.DISABLED}
          isDeletable={false}
          onEdit={onEditClick}
        />
      </TableCell>
    </TableRow>
  )
}

CheckedCovenantDisplayRow.propTypes = {
  monitoringItem: monitoringItemShape,
  entityType: PropTypes.oneOf([cwpEntityTypes.DEAL, cwpEntityTypes.BUSINESS_PARTNER]),
  onEditClick: PropTypes.func.isRequired,
  isAllowedToEdit: PropTypes.bool,
  editButtonState: PropTypes.oneOf(Object.values(editButtonStateEnum)),
}

export default CheckedCovenantDisplayRow
