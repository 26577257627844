import PropTypes from 'prop-types'
import { forwardRef, useMemo } from 'react'
import PropertiesSearchMultiComboBox from 'components/domains/properties/properties-search/filterbar/properties-search-multi-combo-box/PropertiesSearchMultiComboBox'
import useMarkets from 'hooks/services/markets/useMarkets'
import useMarketsByIds from 'hooks/services/markets/useMarketsByIds'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

export const DISPLAYED_SEARCH_RESULTS_COUNT = 25

const marketToComboBoxItem = ({ id, info, area }) => ({
  value: id,
  text: info?.name,
  additionalText: area?.country?.name,
})

const useMultipleMarketsByUuid = (marketIds = []) => {
  const results = useCombinedQueryResults(useMarketsByIds(marketIds))

  return useMemo(
    () => ({
      ...results,
      data: results.data?.map(marketToComboBoxItem),
    }),
    [results],
  )
}

const useMarketSearchByName = (searchString) => {
  const hasSearchString = searchString.length > 0

  const marketsQuery = useMemo(
    () => [
      undefined,
      { needsFilters: true, minLength: 1, searchFilter: searchString },
      { keepPreviousData: true },
    ],
    [searchString],
  )

  const marketsResult = useMarkets(...marketsQuery)

  return useMemo(
    () => ({
      isLoading: hasSearchString && (marketsResult.isLoading || marketsResult.isFetching),
      isError: marketsResult.isError,
      data: (marketsResult.data?.markets ?? [])
        .slice(0, DISPLAYED_SEARCH_RESULTS_COUNT)
        .map(marketToComboBoxItem),
    }),
    [marketsResult, hasSearchString],
  )
}

const MarketSearchMultiComboBox = forwardRef(({ value = '', onChange, ...otherProps }, ref) => (
  <PropertiesSearchMultiComboBox
    {...otherProps}
    ref={ref}
    value={value}
    onChange={onChange}
    useInitialDataLoader={useMultipleMarketsByUuid}
    useSearchResultsLoader={useMarketSearchByName}
  />
))

MarketSearchMultiComboBox.displayName = 'MarketSearchMultiComboBox'

MarketSearchMultiComboBox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default MarketSearchMultiComboBox
