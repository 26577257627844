import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Icon,
  Label,
  MultiInput,
  Token,
} from '@fioneer/ui5-webcomponents-react'
import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import uniqBy from 'lodash.uniqby'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessPartnerCreateDialog from 'components/domains/business-partners/BusinessPartnerCreateDialog'
import styles from 'components/domains/business-partners/BusinessPartnerSearchDialog.module.css'
import BusinessPartnerSearchTable from 'components/domains/business-partners/BusinessPartnerSearchTable'
import Dialog, {
  DialogPrimaryButton,
  DialogSecondaryButton,
  DialogSize,
} from 'components/ui/dialog/Dialog'

const noManualInputAllowedLength = 0

const BusinessPartnerSearchDialog = ({
  open,
  initialSearch,
  onClose,
  onChange,
  withCreateOption = true,
  createDialogOptions = {},
  translatedTypeName,
  initialRoles = [],
  initialBusinessPartners = [],
  lockedSelectedBusinessPartners = [],
  initialExcludeInactive = false,
  isMultiSelect = false,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.business-partner.dialog-search',
  })
  const { t: tNoPrefix } = useTranslation()

  // locked business partners can also be part of the initially selected business partners, but should not be shown twice
  const uniqueSelectedBusinessPartners = useMemo(
    () =>
      uniqBy(
        [...(initialBusinessPartners ?? []), ...(lockedSelectedBusinessPartners ?? [])],
        'id',
      ) ?? [],
    [initialBusinessPartners, lockedSelectedBusinessPartners],
  )
  const [selectedBusinessPartners, setSelectedBusinessPartners] = useState(
    uniqueSelectedBusinessPartners,
  )

  const [isBeforeSearch, setIsBeforeSearch] = useState(!initialSearch)
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)

  const translatedType = translatedTypeName ?? t('business-partner')

  const isBusinessPartnerLocked = useCallback(
    (id) =>
      lockedSelectedBusinessPartners?.some(
        ({ id: lockedBusinessPartnerId } = {}) => lockedBusinessPartnerId === id,
      ),
    [lockedSelectedBusinessPartners],
  )

  const handleSelect = useCallback(
    (newSelection) => {
      setIsBeforeSearch(true)
      onChange?.(newSelection)
      onClose?.()
    },
    [onChange, onClose],
  )

  const handleCancel = useCallback(() => {
    setIsBeforeSearch(true)
    onClose?.()
  }, [onClose])

  const handleCreateClick = useCallback(() => {
    setIsCreateDialogOpen(true)
  }, [])

  const handleCreateChange = useCallback(
    (newBusinessPartner) => {
      const newSelectedBusinessPartners = [...selectedBusinessPartners, newBusinessPartner]
      setSelectedBusinessPartners(newSelectedBusinessPartners)
      handleSelect(newSelectedBusinessPartners)
    },
    [handleSelect, selectedBusinessPartners],
  )

  const handleSelectionChange = useCallback(
    (businessPartners) => {
      // remove locked selected business partners and then append it later on
      const businessPartnersWithoutLocked = businessPartners?.filter(
        ({ id }) => !isBusinessPartnerLocked(id),
      )

      setSelectedBusinessPartners([
        ...(lockedSelectedBusinessPartners ?? []),
        ...(businessPartnersWithoutLocked ?? []),
      ])

      // Select the last added business partner and close the dialog in single select case
      if (!isMultiSelect) {
        handleSelect([businessPartners?.at(-1)])
      }
    },
    [handleSelect, isBusinessPartnerLocked, isMultiSelect, lockedSelectedBusinessPartners],
  )

  useEffect(() => {
    if (open) {
      setSelectedBusinessPartners(uniqueSelectedBusinessPartners)
    }
    // since this dialog is not always rendered conditionally we need to trigger this effect
    // when the open flag changes but not necessarily when the
    // initialBusinessPartners change (that would magically select or deselect BPs without the user clicking somewhere)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const selectedBusinessPartnersToken = useMemo(
    () =>
      selectedBusinessPartners.map(({ id, name }) => {
        if (isBusinessPartnerLocked(id)) {
          // remove deletion options for locked selected business partners
          return (
            <Token
              key={id}
              text={name}
              data-id={id}
              closeIcon={<div className={styles.emptyIcon} />}
            />
          )
        }

        return <Token key={id} text={name} data-id={id} closeIcon={<Icon name="decline" />} />
      }),
    [isBusinessPartnerLocked, selectedBusinessPartners],
  )

  const handleTokenDelete = useCallback(
    ({ detail: { token } }) => {
      const idToDelete = token.dataset.id

      // do not delete locked selected business partners
      if (isBusinessPartnerLocked(idToDelete)) {
        return
      }

      setSelectedBusinessPartners((prev) => [...prev.filter(({ id }) => id !== idToDelete)])
    },
    [isBusinessPartnerLocked],
  )

  const handleClearAllSelectedBusinessPartners = useCallback(() => {
    // reset selected business partners to locked selected business partners (since they should never be removed)
    setSelectedBusinessPartners(lockedSelectedBusinessPartners ?? [])
  }, [lockedSelectedBusinessPartners])

  const renderFooter = () =>
    isMultiSelect && (
      <FlexBox
        direction={FlexBoxDirection.Column}
        justifyContent={FlexBoxJustifyContent.End}
        className={styles.footerWrapper}
      >
        <>
          <Label className={styles.titleLabel}>
            {t('selected-business-partners', {
              type: translatedType,
              count: selectedBusinessPartners?.length,
            })}
          </Label>
          <FlexBox alignItems={FlexBoxAlignItems.Center}>
            <MultiInput
              className={styles.multiInput}
              maxlength={noManualInputAllowedLength}
              tokens={selectedBusinessPartnersToken}
              onTokenDelete={handleTokenDelete}
            />
            <Button
              icon="decline"
              design={ButtonDesign.Transparent}
              onClick={handleClearAllSelectedBusinessPartners}
            />
          </FlexBox>
        </>
      </FlexBox>
    )

  return (
    <>
      <Dialog
        open={open && !isCreateDialogOpen}
        size={DialogSize.XL}
        className={styles.businessPartnerSearchDialog}
        headerText={t('title', {
          type: translatedType,
        })}
        onBeforeClose={(e) => e.detail.escPressed && handleCancel()}
        primaryButton={
          isMultiSelect && (
            <DialogPrimaryButton
              onClick={() => handleSelect(selectedBusinessPartners)}
              disabled={!selectedBusinessPartners?.length}
            >
              {tNoPrefix('buttons.ok')}
            </DialogPrimaryButton>
          )
        }
        secondaryButton={
          <>
            {withCreateOption && (
              <DialogSecondaryButton onClick={handleCreateClick}>
                {tNoPrefix('buttons.create')}
              </DialogSecondaryButton>
            )}
          </>
        }
        closeButton={
          <DialogSecondaryButton onClick={handleCancel}>
            {tNoPrefix('buttons.cancel')}
          </DialogSecondaryButton>
        }
      >
        <FlexBox direction={FlexBoxDirection.Column} className={styles.dialogContentWrapper}>
          <BusinessPartnerSearchTable
            initialSearch={initialSearch}
            initialIsBeforeSearch={isBeforeSearch}
            onChange={handleSelectionChange}
            initialRoles={initialRoles}
            selectedBusinessPartners={selectedBusinessPartners}
            lockedSelectedBusinessPartners={lockedSelectedBusinessPartners}
            initialExcludeInactive={initialExcludeInactive}
            isMultiSelect={isMultiSelect}
          />
          {renderFooter()}
        </FlexBox>
      </Dialog>

      {withCreateOption && (
        <BusinessPartnerCreateDialog
          {...createDialogOptions}
          open={isCreateDialogOpen}
          onClose={() => setIsCreateDialogOpen(false)}
          onAfterCreate={handleCreateChange}
        />
      )}
    </>
  )
}

BusinessPartnerSearchDialog.propTypes = {
  open: PropTypes.bool,
  createDialogOptions: PropTypes.shape({
    title: PropTypes.string,
    creationRole: PropTypes.string,
  }),
  initialSearch: PropTypes.string,
  initialRoles: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  withCreateOption: PropTypes.bool,
  translatedTypeName: PropTypes.string,
  initialExcludeInactive: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  initialBusinessPartners: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  lockedSelectedBusinessPartners: PropTypes.array,
}

export default BusinessPartnerSearchDialog
