import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { monitoringItemShape } from 'components/domains/business-partners/periodical-checks/monitoring-information/PeriodicalMonitoringPropTypes'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const NextPeriodicalCheckDisplay = ({
  monitoringItem: { dueDate } = {},
  configurationForCovenant,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-partners.periodical-checks.monitoring-card',
  })
  const { format: formatDate } = useShortDateFormatter()
  const { withDocumentsRequest } = configurationForCovenant

  return (
    <CardSection>
      <CardShowItem
        id="monitoring-item-key-date"
        label={t('key-date-test-date')}
        value={formatDate(dueDate?.keyDate)}
      />
      {withDocumentsRequest && (
        <CardShowItem
          id="monitoring-item-target-delivery-date"
          label={t('target-delivery-date')}
          value={formatDate(dueDate?.targetDeliveryDate)}
        />
      )}
      <CardShowItem
        id="monitoring-item-check-date"
        label={t('check-due-date')}
        value={formatDate(dueDate?.checkDate)}
      />
    </CardSection>
  )
}

NextPeriodicalCheckDisplay.propTypes = {
  monitoringItem: monitoringItemShape,
  configurationForCovenant: PropTypes.shape({
    withDocumentsRequest: PropTypes.bool,
  }),
}

export default NextPeriodicalCheckDisplay
