import { FlexBox, FlexBoxDirection, Grid, Text } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/OpsMemoTrancheDetailsCard.module.css'
import { OpsMemoTranchePricingCustomFieldListItem } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/fields/OpsMemoTranchePricingCustomFieldListItem'
import OpsMemoTranchePricingListItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/fields/OpsMemoTranchePricingListItem'
import {
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const OpsMemoTranchePricingCard = ({ tranche }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.ops-memo-tranche-details.pricing',
  })
  const formatPercentage = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })
  const formatMoney = useCustomizableCurrencyFormatter()

  const renderFormattedPercentageValue = (value) => (!isNil(value) ? formatPercentage(value) : null)

  const renderMoneyValue = (value) =>
    !isNil(value?.amount) && value.currency && formatMoney(value.amount, value.currency)

  const renderAmountWithHeadquarter = ({ label, value, valueHeadquarter, isBold }) => (
    <>
      <OpsMemoTranchePricingListItem
        label={label}
        value={
          !isNil(value?.amount) && value.currency ? formatMoney(value.amount, value.currency) : ''
        }
        isBold={isBold}
      />
      {valueHeadquarter?.currency !== value?.currency && (
        <OpsMemoTranchePricingListItem
          value={renderMoneyValue(valueHeadquarter)}
          isBold={isBold}
          isConvertedAmount={true}
        />
      )}
    </>
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.card}>
      <Text className={styles.cardHeaderTitle}>{t('pricing')}</Text>

      <Grid defaultSpan="XL4 L4 M12 S12">
        <FlexBox direction={FlexBoxDirection.Column}>
          <Text className={styles.cardSectionTitle}>{t('general-information')}</Text>

          <OpsMemoTranchePricingListItem
            label={t('general-information.customer-margin-average')}
            value={renderFormattedPercentageValue(tranche.pricing.customerMarginAverage)}
            isBold={true}
            isSectionTitle={false}
          />
          <OpsMemoTranchePricingListItem
            label={t('general-information.gross-interest-margin3m-euribor')}
            value={renderFormattedPercentageValue(tranche.pricing.grossInterestMargin3mEuribor)}
            isBold={false}
          />
          <OpsMemoTranchePricingListItem
            label={t('general-information.gross-interest-margin-on')}
            value={renderFormattedPercentageValue(tranche?.pricing?.grossInterestMarginOn)}
          />
          <OpsMemoTranchePricingListItem
            label={t('general-information.gross-revenue-margin3m-euribor')}
            value={renderFormattedPercentageValue(tranche?.pricing?.grossRevenueMargin3mEuribor)}
          />
          <OpsMemoTranchePricingListItem
            label={t('general-information.gross-revenue-margin-on')}
            value={renderFormattedPercentageValue(tranche?.pricing?.grossRevenueMarginOn)}
          />
          <OpsMemoTranchePricingListItem
            label={t('general-information.funding-spread-drawdown3m-ref')}
            value={renderFormattedPercentageValue(tranche?.pricing?.fundingSpreadDrawdown3mRef)}
            isBold={true}
          />
          <OpsMemoTranchePricingListItem
            label={t('general-information.funding-spread-drawdown-on')}
            value={renderFormattedPercentageValue(tranche?.pricing?.fundingSpreadDrawdownOn)}
            isBold={true}
          />
          <OpsMemoTranchePricingListItem
            label={t('general-information.net-revenue-margin')}
            value={renderFormattedPercentageValue(tranche?.pricing?.netRevenueMargin)}
            isBold={true}
          />

          <OpsMemoTranchePricingListItem
            label={t('general-information.full-cost')}
            isLabelOnly={true}
            isBold={true}
            isSectionTitle={true}
          />
          <OpsMemoTranchePricingListItem
            label={t('general-information.full-cost.economic-profit-margin')}
            value={renderFormattedPercentageValue(tranche?.pricing?.economicProfitMarginFullCost)}
          />
          {renderAmountWithHeadquarter({
            label: t('general-information.full-cost.economic-profit-amount'),
            value: tranche?.pricing?.economicProfitAmountFullCost,
            valueHeadquarter: tranche?.pricing?.economicProfitAmountFullCostHeadquarter,
          })}
          <OpsMemoTranchePricingListItem
            label={t('general-information.full-cost.roe-cap')}
            value={renderFormattedPercentageValue(tranche?.pricing?.roeCapFullCost)}
          />

          <OpsMemoTranchePricingListItem
            label={t('general-information.direct-cost')}
            isLabelOnly={true}
            isBold={true}
            isSectionTitle={true}
          />
          <OpsMemoTranchePricingListItem
            label={t('general-information.direct-cost.economic-profit-margin')}
            value={renderFormattedPercentageValue(tranche?.pricing?.economicProfitMarginDirectCost)}
          />
          {renderAmountWithHeadquarter({
            label: t('general-information.direct-cost.economic-profit-amount'),
            value: tranche?.pricing?.economicProfitAmountDirectCost,
            valueHeadquarter: tranche?.pricing?.economicProfitAmountDirectCostHeadquarter,
          })}
          <OpsMemoTranchePricingListItem
            label={t('general-information.lower-cost')}
            isLabelOnly={true}
            isBold={true}
            isSectionTitle={true}
          />
          <OpsMemoTranchePricingListItem
            label={t('general-information.lower-cost.economic-profit-margin')}
            value={renderFormattedPercentageValue(tranche?.pricing?.economicProfitMarginLowerCost)}
          />
          <OpsMemoTranchePricingListItem
            label={t('general-information.lower-cost.economic-profit-amount')}
            value={formatMoney(
              tranche?.pricing?.economicProfitAmountLowerCost?.amount,
              tranche?.pricing?.economicProfitAmountLowerCost?.currency,
            )}
          />
        </FlexBox>

        <FlexBox direction={FlexBoxDirection.Column}>
          <Text className={styles.cardSectionTitle}>{t('additional-information')}</Text>

          <OpsMemoTranchePricingListItem
            label={t('additional-information.gross-interest-margin3m-ref')}
            value={renderFormattedPercentageValue(tranche?.pricing?.grossInterestMargin3mRef)}
          />
          <OpsMemoTranchePricingListItem
            label={t('additional-information.funding-spread-tranche3m-ref')}
            value={renderFormattedPercentageValue(tranche?.pricing?.fundingSpreadTranche3mRef)}
          />
          <OpsMemoTranchePricingListItem
            label={t('additional-information.funding-spread-tranche-on')}
            value={renderFormattedPercentageValue(tranche?.pricing?.fundingSpreadTrancheOn)}
          />
          <OpsMemoTranchePricingListItem
            label={t('additional-information.funding-spread-customer')}
            value={renderFormattedPercentageValue(tranche?.pricing?.fundingSpreadCustomer)}
          />
          <OpsMemoTranchePricingListItem
            label={t('additional-information.ir-margin3m-ref')}
            value={renderFormattedPercentageValue(tranche?.pricing?.irMargin3mRef)}
          />
          <OpsMemoTranchePricingListItem
            label={t('additional-information.ir-margin-on')}
            value={renderFormattedPercentageValue(tranche?.pricing?.irMarginOn)}
          />
          <OpsMemoTranchePricingListItem
            label={t('additional-information.transfer-margin')}
            value={renderFormattedPercentageValue(tranche?.pricing?.transferMargin)}
          />
          <OpsMemoTranchePricingListItem
            label={t('additional-information.loss-given-default-margin-of-conservatism')}
            value={renderFormattedPercentageValue(
              tranche?.pricing?.lossGivenDefaultMarginOfConservatism,
            )}
          />
        </FlexBox>

        <FlexBox direction={FlexBoxDirection.Column}>
          <Text className={styles.cardSectionTitle}>{t('status-information')}</Text>

          {Object.values(tranche?.pricing?.customFields)
            ?.sort((a, b) => a?.order - b?.order)
            .filter((customField) => customField.shown === true)
            .map((customField) => (
              <OpsMemoTranchePricingCustomFieldListItem
                customField={customField}
                key={customField?.categoryShortText}
              />
            ))}
        </FlexBox>
      </Grid>
    </FlexBox>
  )
}

OpsMemoTranchePricingCard.propTypes = {
  tranche: PropTypes.shape({
    pricing: PropTypes.shape({
      customFields: PropTypes.object,
      fundingSpreadTranche3mRef: PropTypes.number,
      fundingSpreadTrancheOn: PropTypes.number,
      fundingSpreadDrawdown3mRef: PropTypes.number,
      fundingSpreadDrawdownOn: PropTypes.number,
      fundingSpreadCustomer: PropTypes.number,
      grossInterestMargin3mRef: PropTypes.number,
      grossInterestMarginOn: PropTypes.number,
      grossInterestMargin3mEuribor: PropTypes.number,
      irMargin3mRef: PropTypes.number,
      irMarginOn: PropTypes.number,
      netRevenueMargin: PropTypes.number,
      transferMargin: PropTypes.number,
      economicProfitAmountDirectCost: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
      economicProfitAmountDirectCostHeadquarter: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
      economicProfitMarginDirectCost: PropTypes.number,
      economicProfitAmountFullCost: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
      economicProfitAmountFullCostHeadquarter: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
      economicProfitMarginFullCost: PropTypes.number,
      economicProfitAmountLowerCost: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
      economicProfitAmountLowerCostHeadquarter: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
      economicProfitMarginLowerCost: PropTypes.number,
      roeCapFullCost: PropTypes.number,
      grossRevenueMargin3mEuribor: PropTypes.number,
      customerMarginAverage: PropTypes.number,
      grossRevenueMarginOn: PropTypes.number,
      lossGivenDefaultMarginOfConservatism: PropTypes.number,
    }),
  }),
}

export default OpsMemoTranchePricingCard
