import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useMarketConfigurationExternalIdTypes = (options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: '/markets/configurations/external-id-types',
      useCache: true,
      keys: ['markets', 'configuration', 'external-id-types'],
      options: options,
    }),
  )

export default useMarketConfigurationExternalIdTypes
