import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const tExpiringLeasesChartXAxisValueKeyPrefix =
  'pages.property.rent-roll.overview.expiring-leases.chart.x-axis.value'

/**
 * @param {string} keyDate ISO8601 date string
 * @return {number | undefined} position on the x-Axis between 0 and 1 if today is after the key date, otherwise `undefined`
 */
const getTodayXAxisPosition = (keyDate) => {
  if (!keyDate) return undefined

  const FIRST_YEAR_QUARTERS = 4
  const LAST_X_AXIS_ITEM_INDEX = 14 // we have 15 data points
  const todayDistanceFromKeyDateInYears = DateTime.now()
    .diff(DateTime.fromISO(keyDate), 'years')
    .toObject().years

  // for our x-axis with two bars displayed on each data point,
  // the first point actually is not at x-position 0, but in the center
  // of the first data point's area, so there is half a data point area's
  // width of padding to each side, which we have to take into account for
  // our calculation, as the x-axis of the numerical type actually starts
  // at zero and ends at 100 per cent of the width.
  const mapToPaddedAxisRange = (val) =>
    (val * LAST_X_AXIS_ITEM_INDEX + 1 / 2) / (LAST_X_AXIS_ITEM_INDEX + 1)

  // if the current date is before the key date,
  // we don't show it as it's outside of the displayed range
  if (todayDistanceFromKeyDateInYears < 0) {
    return undefined
  }

  // the last x-Axis item is "10+ years", which means infinity.
  // so if the current date is more than 10 years away, from the
  // key date, we set it to the end of the axis right away.
  if (todayDistanceFromKeyDateInYears > 10) {
    return mapToPaddedAxisRange(1)
  }

  // for other cases, map the position to fit the x-axis scale:
  // [0, 0.25, 0.5, 0.75, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, Infinity]

  if (todayDistanceFromKeyDateInYears < 1) {
    return mapToPaddedAxisRange(
      (todayDistanceFromKeyDateInYears * FIRST_YEAR_QUARTERS) / LAST_X_AXIS_ITEM_INDEX,
    )
  }

  // values between 1 and 10:
  return mapToPaddedAxisRange(
    (todayDistanceFromKeyDateInYears + FIRST_YEAR_QUARTERS - 1) / LAST_X_AXIS_ITEM_INDEX,
  )
}

const usePropertyRentRollOverviewExpiringLeasesChartData = (propertyKpiData) => {
  const { t: tExpiringLeasesChartXAxisValue, i18n } = useTranslation('translation', {
    keyPrefix: tExpiringLeasesChartXAxisValueKeyPrefix,
  })

  return useMemo(() => {
    const keyDate = propertyKpiData?.keyDate ?? ''
    const currentRent = propertyKpiData?.annualizedCurrentRent?.number ?? 0
    const currency = propertyKpiData?.annualizedCurrentRent?.currency ?? ''
    const expiringLeasesInfo = propertyKpiData?.expiringLeases ?? []

    const isEmpty = expiringLeasesInfo.length === 0

    const initialDataPoint = {
      name: '0',
      percentRemaningBreak: 1,
      percentRemaningExpiry: 1,
      currentRentExpiringBreak: 0,
      currentRentExpiringExpiry: 0,
    }

    const expiringLeases = isEmpty
      ? []
      : [
          initialDataPoint,
          ...expiringLeasesInfo.map(
            ({
              currentRentExpiringBreak,
              currentRentExpiringExpiry,
              remainingCurrentRentBreak,
              remainingCurrentRentExpiry,
              label,
            }) => {
              const name = i18n.exists(`${tExpiringLeasesChartXAxisValueKeyPrefix}.${label}`)
                ? tExpiringLeasesChartXAxisValue(label)
                : label

              return {
                name,
                percentRemaningBreak: remainingCurrentRentBreak.number / currentRent,
                percentRemaningExpiry: remainingCurrentRentExpiry.number / currentRent,
                currentRentExpiringBreak: currentRentExpiringBreak.number,
                currentRentExpiringExpiry: currentRentExpiringExpiry.number,
              }
            },
          ),
        ]

    const todayXAxisPosition = getTodayXAxisPosition(keyDate)

    return { currentRent, expiringLeases, currency, isEmpty, todayXAxisPosition }
  }, [i18n, propertyKpiData, tExpiringLeasesChartXAxisValue])
}

export default usePropertyRentRollOverviewExpiringLeasesChartData
