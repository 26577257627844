import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'

export const decisionProcessConditionsDecisionStatus = {
  pending: 'PENDING',
  inProgress: 'IN_PROGRESS',
  inProgressRequestDiscarded: 'IN_PROGRESS_REQUEST_DISCARDED',
  approved: 'APPROVED',
}

const objectStatusForConditionsDecisionStatus = {
  [decisionProcessConditionsDecisionStatus.pending]: {
    translationKey: 'decision-stage.conditions.status.pending',
    objectStatus: ValueState.None,
  },
  [decisionProcessConditionsDecisionStatus.inProgress]: {
    translationKey: 'decision-stage.conditions.status.in-progress',
    objectStatus: ValueState.Information,
  },
  [decisionProcessConditionsDecisionStatus.inProgressRequestDiscarded]: {
    translationKey: 'decision-stage.conditions.status.in-progress',
    objectStatus: ValueState.Information,
  },
  [decisionProcessConditionsDecisionStatus.approved]: {
    translationKey: 'decision-stage.conditions.status.approved',
    objectStatus: ValueState.Success,
  },
}

const defaultDecisionStageConditionsDecisionStatusObjectStatus = {
  translationKey: 'decision-stage.conditions.status.unknown',
  objectStatus: ValueState.None,
}

export const getObjectStatusforDecisionStageConditionsStatus = (conditionsStatus) =>
  objectStatusForConditionsDecisionStatus[conditionsStatus] ??
  defaultDecisionStageConditionsDecisionStatusObjectStatus

export const decisionStageConditionsVoteOptions = {
  approve: 'APPROVE',
}
