import {
  AnalyticalTableScaleWidthMode,
  FlexBox,
  FlexBoxAlignItems,
  Icon,
  IllustratedMessage,
  Label,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/OpsMemoSyndicationStructureTile.module.css'
import OpsMemoSyndicationTrancheSelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/OpsMemoSyndicationTrancheSelect'
import mapSyndicationBackendDataToTableStructure from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/mapSyndicationBackendDataToTableStructure'
import mergeBackendAndEditData from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/mergeBackendAndEditData'
import useOpsMemoSyndicationStructureTableColumnDefinitions from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/useOpsMemoSyndicationStructureTableColumnDefinitions'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche/shared/ui/tables/analytical/AnalyticalTableWithToolbar'
import AnalyticalTableScalingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/AnalyticalTableScalingWrapper'

const OpsMemoSyndicationStructureTile = ({
  tileId,
  isPdfView = false,
  showConfirmationForLinks = false,
}) => {
  const {
    data: {
      syndicationData = {},
      tranchesData: { tranches = [] },
      sourceRender: { dealDisplayId },
      businessPartnerResolvedNames,
    },
    version: tileVersion,
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.syndication-structure',
  })
  const { t: tNoPrefix } = useTranslation('decisionPaper')

  const [isCollapseIconDisabled, setIsCollapseIconDisabled] = useState(true)

  const [syndicationTranche, setSyndicationTranche] = useState(
    tranches.map((tranche) => tranche.trancheId),
  )

  const tableRef = useRef(null)

  /*
  Necessary because pdfView needs to render multiple tables, so multiple refs need to be created.
  However, hooks must only be called at the top level of the component, not in the loop over the tables.
*/
  const getMap = () => {
    if (!tableRef.current) {
      tableRef.current = new Map()
    }
    return tableRef.current
  }

  const expandOrCollapseSubRows = (isExpanded) => {
    const map = getMap()
    const node = map.get(0)
    node?.toggleAllRowsExpanded(isExpanded)
    setIsCollapseIconDisabled(!isExpanded)
  }
  useEffect(() => {
    if (isPdfView) {
      const map = getMap()
      for (let i = 0; i < tranches.length; i++) {
        const node = map.get(i)
        node?.toggleAllRowsExpanded(true)
      }
    }
  }, [isPdfView, tranches.length])

  const tableData = useMemo(() => {
    const { backendTableData } = mapSyndicationBackendDataToTableStructure(
      tNoPrefix,
      syndicationData,
      tranches,
      true,
    )
    const filteredByTranche = (displayedTranches = syndicationTranche) =>
      backendTableData.map((party) => ({
        ...party,
        subRows: party?.subRows?.map((role) => ({
          ...role,
          subRows: role?.subRows?.filter((tranche) =>
            displayedTranches.includes(tranche.trancheId),
          ),
        })),
      }))

    if (isPdfView) {
      return tranches.map((tranche) =>
        mergeBackendAndEditData(filteredByTranche(tranche.trancheId), []),
      )
    } else return [mergeBackendAndEditData(filteredByTranche(), [])]
  }, [isPdfView, syndicationData, syndicationTranche, tranches, tNoPrefix])

  const additionalTableActions = isPdfView
    ? []
    : [
        <FlexBox alignItems={FlexBoxAlignItems.Baseline} key="select-syndiactionTranche">
          <Label>{t('tranche-dropdown-label')}:&nbsp;</Label>
          <OpsMemoSyndicationTrancheSelect
            syndicationTranche={syndicationTranche}
            setSyndicationTranche={setSyndicationTranche}
            tranches={tranches}
          />
        </FlexBox>,
        <Icon
          className={styles.collapseIcon}
          key="collapse-subrows"
          name="collapse-all"
          interactive
          onClick={() => expandOrCollapseSubRows(false)}
          disabled={isCollapseIconDisabled}
        />,
        <Icon
          className={styles.expandIcon}
          key="expand-subrows"
          name="expand-all"
          interactive
          onClick={() => expandOrCollapseSubRows(true)}
        />,
      ]

  const columns = useOpsMemoSyndicationStructureTableColumnDefinitions({
    dealDisplayId,
    showConfirmationForLinks,
    tileVersion,
    businessPartnerResolvedNames,
  })

  return (
    <AnalyticalTableScalingWrapper
      isPdfView={isPdfView}
      tableWidth={getMap().get(0)?.totalColumnsWidth ?? 0}
    >
      {tableData.map((data, index) => (
        <AnalyticalTableWithToolbar
          key={index}
          title={`${t('title')} ${isPdfView ? `- ${tranches[index]?.trancheName}` : ''}`}
          nrOfEntries={data?.filter((row) => !row?.isSectionHeader)?.length ?? 0}
          id="syndicationStructure-table"
          sortable={false}
          minRows={0}
          columns={columns}
          data={data}
          additionalActions={additionalTableActions}
          className={styles.tableWrapper}
          overscanCountHorizontal={99}
          // high number of visible rows fixes re-rendering of height on expand
          visibleRows={99}
          // rowHeight needs to be set to a non-empty string to not show an empty row at the end
          rowHeight={'individual'}
          headerRowHeight={30}
          isTreeTable={true}
          isPdfView={isPdfView}
          scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
          withRowHighlight={false}
          NoDataComponent={() => (
            <IllustratedMessage
              name="NoData"
              // Unfortunately, the library's default title contains two apostrophes:
              // "There''s no data yet
              // Therefore a custom title is used
              titleText={t('no-data-title')}
              subtitleText={t('no-data-text')}
            />
          )}
          reactTableOptions={{
            autoResetPage: false,
            autoResetExpanded: false,
            autoResetSelectedRows: false,
            autoResetSortBy: false,
            autoResetFilters: false,
            selectSubRows: false,
            isEditable: false,
          }}
          ref={(node) => {
            const map = getMap()
            if (node) {
              map.set(index, node)
            } else {
              map.delete(index)
            }
          }}
        />
      ))}
    </AnalyticalTableScalingWrapper>
  )
}

OpsMemoSyndicationStructureTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  showConfirmationForLinks: PropTypes.bool,
}
export default OpsMemoSyndicationStructureTile
