import { MultiComboBox, MultiComboBoxItem, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Labeled from 'components/ui/data/Labeled'
import NumberInput from 'components/ui/input/NumberInput'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { standardMappings, useFormMapped } from 'hooks/form/useFormMapped'
import { useFormValidations } from 'hooks/form/useFormValidations'
import useCountriesForLoadingDropdown from 'hooks/services/business-partners/config/useCountriesForLoadingDropdown'
import { useTypecodes } from 'hooks/services/properties/useTypecodes'

const CreatePortfolioSubStep = ({ enabled }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals' })
  const { data: countries } = useCountriesForLoadingDropdown()
  const { register, control } = useFormMapped()
  const { required, min, max, minLength, pattern } = useFormValidations({ enabled })

  return (
    <>
      <Labeled
        label={t('dialog.property.input.label.properties-count')}
        showColon
        required
        vertical
        style={{ gridColumn: 'span 1' }}
      >
        <NumberInput
          id="number-of-properties"
          required
          {...register('portfolio.numberOfProperties', {
            validate: {
              required: required(),
              min: min(1),
              // eslint-disable-next-line no-magic-numbers
              max: max(9999),
              pattern: pattern(/^[0-9]+$/g),
            },
            valueAsNumber: true,
          })}
        />
      </Labeled>
      <Labeled
        label={t('dialog.property.input.label.main-property-type')}
        showColon
        required
        vertical
        style={{ gridColumn: 'span 1' }}
      >
        <LoadingSelect
          id="typecodes-loading-select"
          loadingHook={useTypecodes}
          selectionName="typecodes"
          optionKeyName="key"
          optionDisplayName="display_name"
          {...register('portfolio.mainPropertyTypeCode', {
            ...standardMappings.LoadingSelect,
            validate: {
              required: required(),
            },
          })}
        />
      </Labeled>
      <Labeled
        label={t('dialog.property.input.label.countries')}
        showColon
        required
        vertical
        style={{ gridColumn: 'span 1' }}
      >
        <Controller
          control={control}
          name="portfolio.countryCodes"
          rules={{
            validate: {
              required: required(),
              minLength: minLength(1),
            },
          }}
          render={({
            field: { onChange, onBlur, name, value },
            fieldState: { invalid, error },
          }) => (
            <MultiComboBox
              id="countries-multi-combo-box"
              style={{ width: '100%' }}
              name={name}
              valueState={invalid ? 'Error' : undefined}
              valueStateMessage={<Text wrapping>{error?.message}</Text>}
              onBlur={onBlur}
              onSelectionChange={async (evt) => {
                await onChange(evt?.detail?.items?.map?.((type) => type.dataset.key))
                await onBlur()
              }}
            >
              {countries?.map(({ code, displayName }) => (
                <MultiComboBoxItem
                  key={code}
                  text={displayName}
                  selected={value?.includes?.(code)}
                  data-key={code}
                />
              ))}
            </MultiComboBox>
          )}
        />
      </Labeled>
    </>
  )
}

export default CreatePortfolioSubStep

CreatePortfolioSubStep.propTypes = {
  enabled: PropTypes.bool,
}
