import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import FurtherDetailsPieChartsCard, {
  chartsWithValuations,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/FurtherDetailsPieChartsCard'
import {
  TenantColorProvider,
  useTenantColors,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/TenantColorContext'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/card/EmptyCardContent'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const MultipleFurtherDetailsGranularityTegovaRatingTile = ({
  tileId,
  isPdfView,
  selectedDealIndex,
}) => {
  const dispatch = useDispatch()

  const {
    data: {
      multiplePropertyGranularityPieChartData = [],
      sourcePaths = [],
      sourceRender,
      dealsData,
    },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const selectedDealDisplayId = dealsData[selectedDealIndex]?.dealDisplayId

  useEffect(() => {
    dispatch(setSourceRender({ tileId, sourceRender: { path: sourcePaths?.[selectedDealIndex] } }))
  }, [dispatch, selectedDealDisplayId, selectedDealIndex, sourcePaths, tileId])

  useEffect(() => {
    if (isNil(sourceRender)) {
      dispatch(
        setSourceRender({ tileId, sourceRender: { path: sourcePaths?.[selectedDealIndex] } }),
      )
    }
  }, [dispatch, selectedDealDisplayId, selectedDealIndex, sourcePaths, sourceRender, tileId])

  const {
    multiPropertyKpisByGranularity,
    multiPropertyValuationsByGranularity,
    isMultiProperty,
    noProperties,
  } = useMemo(() => {
    const propertyGranularityPieChartData =
      multiplePropertyGranularityPieChartData?.[selectedDealIndex] ?? {}

    return {
      multiPropertyKpisByGranularity:
        propertyGranularityPieChartData?.multiPropertyKpisByGranularity
          ?.keyDateToMultiPropertyRentRollKpis?.[0]?.kpis,
      multiPropertyValuationsByGranularity:
        propertyGranularityPieChartData?.multiPropertyValuationsByGranularity,
      isMultiProperty: propertyGranularityPieChartData?.isMultiProperty,
      noProperties: !propertyGranularityPieChartData?.properties,
    }
  }, [multiplePropertyGranularityPieChartData, selectedDealIndex])

  const { t: tRating } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.further-details-granularity',
  })

  const getTegovaRatingRentRollKpis = (multiPropertyKpis) =>
    multiPropertyKpis?.tegovaRatingRentRollKpis ?? []

  const getTegovaRatingValuations = (multiPropertyValuations) => {
    const valuations = multiPropertyValuations?.granularities?.tegovaRating ?? []
    let valuationsWithFullName
    if (!isEmpty(valuations)) {
      valuationsWithFullName = valuations.map((valuation) => ({
        ...valuation,
        displayName: valuation.code
          ? `${valuation.code} - ${valuation.displayName}`
          : tRating('none'),
      }))
    } else {
      valuationsWithFullName = []
    }
    return valuationsWithFullName
  }

  const getTegovaRatingName = (rentRollKpi) => {
    const rating = rentRollKpi?.tegovaRatingCode
    return rating ? `${rating} - ${tRating(`tegova-rating.${rating}`)}` : tRating('none')
  }

  if (noProperties) {
    return <NoPropertyAssignedToDealInfo />
  }
  if (!isMultiProperty) {
    return <EmptyCardContent />
  }
  return (
    <FurtherDetailsPieChartsCard
      colorProvider={TenantColorProvider}
      useColors={useTenantColors}
      getRentRollKpis={getTegovaRatingRentRollKpis}
      getValuations={getTegovaRatingValuations}
      getNameFromKpi={getTegovaRatingName}
      granularityValues={{
        multiPropertyKpisByGranularity,
        multiPropertyValuationsByGranularity,
      }}
      chartOptions={chartsWithValuations}
      isPdfView={isPdfView}
    />
  )
}

MultipleFurtherDetailsGranularityTegovaRatingTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultipleFurtherDetailsGranularityTegovaRatingTile
