import PropTypes from 'prop-types'
import TableWrappingTextCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/generic/TableWrappingTextCell'

const ConditionsTableInternalApprovalLevelCell = ({ value: { approvalLevelName } }) => (
  <TableWrappingTextCell value={approvalLevelName} />
)

ConditionsTableInternalApprovalLevelCell.propTypes = {
  value: PropTypes.shape({
    approvalLevelName: PropTypes.string,
  }).isRequired,
}

export default ConditionsTableInternalApprovalLevelCell
