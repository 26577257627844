import orderBy from 'lodash.orderby'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import VersionsHistoryButton from 'components/ui/button/VersionsHistoryButton'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'

const defaultButtonId = 'versions-history-button'

export const sortingFields = {
  lastUpdated: 'lastUpdated.lastUpdatedOn',
  version: 'version',
}

const VersionsHistoryButtonLoadingWrapper = ({
  versionData,
  onItemClicked,
  buttonId = defaultButtonId,
  sortingField = sortingFields.lastUpdated,
}) => {
  const { t } = useTranslation('translation')

  const formattedVersions = useMemo(() => {
    const sortedVersions = orderBy(versionData, sortingField, ['desc'])
    return sortedVersions?.map(({ version, lastUpdated }) => {
      const isCurrent = version === sortedVersions[0].version
      const { name, lastUpdatedOn } = lastUpdated
      return {
        version,
        lastUpdatedBy: name,
        lastUpdatedAt: lastUpdatedOn,
        isCurrent,
        onItemClicked: () => onItemClicked(version),
      }
    })
  }, [onItemClicked, sortingField, versionData])

  const renderContent = useCallback(() => {
    if (formattedVersions.length !== 0) {
      return <VersionsHistoryButton versions={formattedVersions} buttonId={buttonId} />
    }
  }, [formattedVersions, buttonId])

  return (
    <SmallLoadingWrapper
      error={t('components.ui.buttons.versions-history.loading.error')}
      renderContent={renderContent}
      isError={false}
      isLoading={false}
    />
  )
}

VersionsHistoryButtonLoadingWrapper.propTypes = {
  versionData: PropTypes.array.isRequired,
  onItemClicked: PropTypes.func.isRequired,
  buttonId: PropTypes.string,
  sortingField: PropTypes.oneOf(Object.values(sortingFields)),
}

export default VersionsHistoryButtonLoadingWrapper
