import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateReservation = ({ onSuccess, onError, onSettled }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({
      comment,
      countryCode,
      currencyCode,
      dealUuid,
      endTimestamp,
      externalProductTypeCode,
      externalMainPropertyTypeCode,
      number,
      startTimestamp,
      status,
    }) => {
      const { data } = await post({
        path: `/deals/${dealUuid}/credit-reservations`,
        body: {
          credit_reservations: [
            snakecaseKeys({
              comment,
              countryCode,
              endTimestamp,
              externalProductTypeCode,
              externalMainPropertyTypeCode,
              amount: { number, currencyCode },
              startTimestamp,
              status,
            }),
          ],
        },
      })
      return {
        data: camelize(data),
      }
    },
    { onSuccess, onError, onSettled },
  )
}

export default useCreateReservation
