export const TABLE_CELL_DEFAULT_ITEMS_GAP = 8
export const TABLE_TEXT_DEFAULT_HEIGHT = 16
export const TABLE_TEXT_DEFAULT_MARGIN = 8

const NUMBER_OF_TEXT_LINES_IN_ROW_WITHOUT_HEADQUARTER_CURRENCY = 2
const NUMBER_OF_TEXT_LINES_IN_ROW_WITH_HEADQUARTER_CURRENCY = 3

export const TABLE_ROW_WITHOUT_HEADQUARTER_CURRENCY_HEIGHT =
  TABLE_TEXT_DEFAULT_HEIGHT * NUMBER_OF_TEXT_LINES_IN_ROW_WITHOUT_HEADQUARTER_CURRENCY +
  TABLE_TEXT_DEFAULT_MARGIN * 2 +
  TABLE_CELL_DEFAULT_ITEMS_GAP
export const TABLE_ROW_WITH_HEADQUARTER_CURRENCY_HEIGHT =
  TABLE_TEXT_DEFAULT_HEIGHT * NUMBER_OF_TEXT_LINES_IN_ROW_WITH_HEADQUARTER_CURRENCY +
  TABLE_TEXT_DEFAULT_MARGIN * 2 +
  TABLE_CELL_DEFAULT_ITEMS_GAP

const NUMBER_OF_LINES_IN_HEADER_ROW = 2

export const TABLE_HEADER_ROW_HEIGHT =
  TABLE_TEXT_DEFAULT_HEIGHT * NUMBER_OF_LINES_IN_HEADER_ROW + TABLE_TEXT_DEFAULT_MARGIN * 2
