const mapRatingDevelopmentRisksChances = (data) => {
  if (!data?.developmentRiskAndChances) return []

  const devRiskAndChances = data.developmentRiskAndChances
  const content = [
    {
      rootRowId: 'developmentRiskAndChances',
      criteriaClass: 'developmentRiskAndChances',
      rating: devRiskAndChances.developmentRiskAndChances,
      subRows: [
        {
          criteriaClass: 'lettingAndSelling',
          rating: devRiskAndChances?.lettingAndSelling,
        },
        {
          criteriaClass: 'planningAndPermits',
          rating: devRiskAndChances?.planningAndPermits,
        },
        {
          criteriaClass: 'constructionAndCosts',
          rating: devRiskAndChances?.constructionAndCosts,
        },
      ],
    },
  ]

  return content
}

export default mapRatingDevelopmentRisksChances
