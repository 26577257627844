import {
  Icon,
  IllustratedMessage,
  AnalyticalTableScaleWidthMode,
  AnalyticalTableSelectionBehavior,
  AnalyticalTableSelectionMode,
  AnalyticalTableHooks,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useRef, useState, useMemo, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/OpsMemoCollateralAgreementsTile.module.css'
import { countNumberOfSubRows } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/hooks/services/collaterals/countNumberOfSubRows'
import { mapAggregatedCollateralAgreementsToTableData } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/hooks/services/collaterals/mapAggregatedCollateralAgreementsToTableData'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/ui/tables/analytical/AnalyticalTableWithToolbar'
import useOpsMemoCollateralAgreementsTableColumnDefinitions from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/useOpsMemoCollateralAgreementsTableColumnDefinitions'
import AnalyticalTableScalingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/AnalyticalTableScalingWrapper'
import useDecisionPaperLinkConfirmationDialog from 'hooks/services/business-events-and-tasks/decision-papers/useDecisionPaperLinkConfirmationDialog'
import paths from 'routes/paths'

const OpsMemoCollateralAgreementsTile = ({
  tileId,
  isPdfView = false,
  showConfirmationForLinks = false,
}) => {
  const {
    data: {
      aggregatedCollateralAgreementsData: aggregatedCollateralAgreementsData = {},
      sourceRender: { dealDisplayId },
      statusMapping,
    },
    version,
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.collaterals.collateral-agreements-table',
  })

  const tableRef = useRef(null)
  const [isCollapseIconDisabled, setIsCollapseIconDisabled] = useState(true)
  const [tableWidth, setTableWidth] = useState(tableRef?.current?.totalColumnsWidth ?? 0)

  useEffect(() => {
    setTableWidth(tableRef?.current?.totalColumnsWidth ?? 0)
  }, [tableRef?.current?.totalColumnsWidth])

  const columnDefinitions = useOpsMemoCollateralAgreementsTableColumnDefinitions()

  const mappedTableData = useMemo(
    () =>
      aggregatedCollateralAgreementsData &&
      mapAggregatedCollateralAgreementsToTableData({
        aggregatedCollateralAgreements: aggregatedCollateralAgreementsData,
        tileVersion: version,
        statusMapping,
      }),
    [aggregatedCollateralAgreementsData, statusMapping, version],
  )

  const nrOfEntries = useMemo(() => countNumberOfSubRows(mappedTableData), [mappedTableData])

  const handleOnRowExpand = (row) => {
    const tableState = tableRef?.current?.state
    const expandedRowCountBeforeStateUpdate =
      tableState?.expanded && Object.keys(tableState.expanded).length
    if (expandedRowCountBeforeStateUpdate > 1 || !row.isExpanded) {
      setIsCollapseIconDisabled(false)
    } else {
      setIsCollapseIconDisabled(true)
    }
  }

  const expandOrCollapseSubRows = (isExpanded) => {
    tableRef?.current?.toggleAllRowsExpanded(isExpanded)
    setIsCollapseIconDisabled(!isExpanded)
  }

  const showCollateralPage = useCallback(
    (event) => {
      if (event.detail.row.values?.cag?.id) {
        const cagId = String(event.detail.row.values.cag.id)
        const link = `/${paths.deals}/${dealDisplayId}/collaterals/${cagId}`
        window.open(link, '_blank')
      }
    },
    [dealDisplayId],
  )

  const { showConfirmationDialog } = useDecisionPaperLinkConfirmationDialog({
    onConfirm: showCollateralPage,
  })

  useEffect(() => {
    if (isPdfView) {
      tableRef.current?.toggleAllRowsExpanded(true)
    }
  }, [isPdfView])

  const additionalTableActions = [
    <Icon
      className={styles.collapseIcon}
      key="collapse-subrows"
      name="collapse-all"
      interactive
      onClick={() => expandOrCollapseSubRows(false)}
      disabled={isCollapseIconDisabled}
    />,
    <Icon
      className={styles.expandIcon}
      key="expand-subrows"
      name="expand-all"
      interactive
      onClick={() => expandOrCollapseSubRows(true)}
    />,
  ]

  const [_sortDirection, setSortDirection] = useState('') // required workaround - see: https://fioneer.atlassian.net/browse/CWP-6046
  const handleSort = ({ detail }) => setSortDirection(detail.sortDirection)

  const isNonLeafRow = (row) => !isEmpty(row.subRows) || row.values?.cag?.id === 'total'

  return (
    <AnalyticalTableScalingWrapper isPdfView={isPdfView} tableWidth={tableWidth}>
      <AnalyticalTableWithToolbar
        title={t('title')}
        nrOfEntries={nrOfEntries}
        className={styles.tableWrapper}
        additionalActions={!isPdfView ? additionalTableActions : []}
        id="collateralAgreements-table"
        sortable={!isPdfView}
        isPdfView={isPdfView}
        minRows={0}
        columns={columnDefinitions}
        overscanCountHorizontal={99}
        rowHeight={50}
        visibleRows={99}
        headerRowHeight={30}
        onRowExpandChange={(event) => handleOnRowExpand(event.detail.row)}
        onRowClick={showConfirmationForLinks ? showConfirmationDialog : showCollateralPage}
        data={mappedTableData ?? []}
        isTreeTable={true}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
        withRowHighlight={false}
        selectionBehavior={AnalyticalTableSelectionBehavior.RowOnly}
        selectionMode={AnalyticalTableSelectionMode.SingleSelect}
        NoDataComponent={() => (
          <IllustratedMessage
            name="NoData"
            titleText={t('no-data-title')}
            subtitleText={t('no-data-text')}
          />
        )}
        reactTableOptions={{
          autoResetPage: false,
          autoResetExpanded: false,
          autoResetSelectedRows: false,
          autoResetSortBy: false,
          autoResetFilters: false,
          selectSubRows: false,
          initialState: { sortBy: [{ id: 'cag', desc: false }] },
        }}
        onSort={handleSort}
        ref={tableRef}
        tableHooks={[AnalyticalTableHooks.useRowDisableSelection(isNonLeafRow)]}
      />
    </AnalyticalTableScalingWrapper>
  )
}

OpsMemoCollateralAgreementsTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  showConfirmationForLinks: PropTypes.bool,
}

export default OpsMemoCollateralAgreementsTile
