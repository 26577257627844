import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import styles from 'components/domains/properties/portfolio/overview/portfolio-table-bp-popover/BusinessPartnerPopoverItem.module.css'
import Entity from 'components/ui/data/Entity'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import paths from 'routes/paths'

const BusinessPartnerPopoverItem = ({
  businessPartnerId,
  businessPartnerFunctionName,
  businessPartnersResponse,
}) => {
  const { data: { businessPartnerMinis = [] } = {}, isFetching } = businessPartnersResponse ?? {}
  const businessPartnerName = useMemo(() => {
    const bp = businessPartnerMinis?.find(
      (resolvedBusinessPartner) => businessPartnerId === resolvedBusinessPartner.id,
    )
    return bp?.fullName ?? '-'
  }, [businessPartnerId, businessPartnerMinis])

  return (
    <CardShowItem
      label={
        <SmallLoadingWrapper
          isLoading={isFetching}
          isError={false}
          error={<></>}
          renderContent={() => (
            <Entity
              className={styles.entity}
              additionalClassNamesForLink={styles.entityLink}
              name={businessPartnerName}
              id={businessPartnerId}
              link={`/${paths.businessPartners}/${businessPartnerId}`}
              openInNewTab
            />
          )}
        />
      }
      value={businessPartnerFunctionName}
    />
  )
}

BusinessPartnerPopoverItem.propTypes = {
  businessPartnerId: PropTypes.string.isRequired,
  businessPartnerFunctionName: PropTypes.string,
  businessPartnersResponse: PropTypes.shape({
    data: PropTypes.shape({
      businessPartnerMinis: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.string, fullName: PropTypes.string }),
      ),
    }),
    isFetching: PropTypes.bool,
  }),
}

export default BusinessPartnerPopoverItem
