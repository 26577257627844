import { FlexBox, FlexBoxDirection, Label, Text } from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import PropType from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/shared-components/MoneyWithShareCell.module.css'
import SmallLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/ui/loading/SmallLoadingWrapper'
import {
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const MoneyWithShareCell = ({
  originalCurrency,
  originalAmount,
  headquarterCurrency,
  headquarterAmount,
  share,
  isBold = false,
  alignRight = false,
  isLoading = false,
  isError = false,
}) => {
  const formatMoney = useCustomizableCurrencyFormatter()
  const formatFraction = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })

  const { t } = useTranslation('decisionPaper')

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      error={
        <div className={styles.errorText}>
          {t('decision-paper.unit-overview.components.error.data-unavailable.cell.title-text')}
        </div>
      }
      renderContent={() => (
        <FlexBox
          direction={FlexBoxDirection.Column}
          className={compact([styles.twoLineCell, alignRight && styles.alignRight]).join(' ')}
        >
          <div>
            <div className={compact([styles.caretCursor, isBold && styles.textBold]).join(' ')}>
              {formatMoney(originalAmount, originalCurrency, {
                currencyDisplay: 'code',
              })}
            </div>
            {!isNil(headquarterCurrency) && originalCurrency !== headquarterCurrency && (
              <div
                className={compact([
                  styles.textLabelColor,
                  styles.caretCursor,
                  isBold && styles.textBold,
                ]).join(' ')}
              >
                {formatMoney(headquarterAmount, headquarterCurrency, {
                  currencyDisplay: 'code',
                })}
              </div>
            )}
          </div>
          {!isNil(share) && (
            <div className={styles.caretCursor}>
              <Label showColon>{t('share')}</Label>&nbsp;
              <Text>{formatFraction(share)}</Text>
            </div>
          )}
        </FlexBox>
      )}
    />
  )
}

MoneyWithShareCell.propTypes = {
  originalCurrency: PropType.string,
  originalAmount: PropType.number,
  headquarterCurrency: PropType.string,
  headquarterAmount: PropType.number,
  share: PropType.number,
  isBold: PropType.bool,
  alignRight: PropType.bool,
  isLoading: PropType.bool,
  isError: PropType.bool,
}

export default MoneyWithShareCell
