import {
  CustomListItem,
  FlexBox,
  FlexBoxDirection,
  Label,
  List,
  ListItemType,
  ListMode,
  ListSeparators,
  Popover,
  PopoverPlacementType,
  StandardListItem,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import StaffMemberAutocompleteInput from 'components/ui/input/StaffMemberAutocompleteInput'
import styles from 'components/ui/watcher/WatcherMenuPopover.module.css'
import WatcherMenuWatchers from 'components/ui/watcher/WatcherMenuWatchers'

const WatcherMenuPopover = ({
  isOpen,
  opener,
  onAfterClose,
  userId,
  watchers,
  ownWatchingUser,
  onUserSubscribe,
  onUserUnsubscribe,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.ui.watcher' })
  const [selectedStaffMember, setSelectedStaffMember] = useState()

  // This is a hack since StaffMemberAutocompleteInput calls the selection callback
  // not once but depending on how the selection was made twice.
  // With this we are only listening to changes of the selected staff member,
  // independent whether he was selected twice or only once.
  useEffect(() => {
    if (selectedStaffMember?.id) {
      onUserSubscribe(selectedStaffMember.id)
      setSelectedStaffMember()
    }
  }, [selectedStaffMember]) // eslint-disable-line react-hooks/exhaustive-deps

  const buttonIcon = ownWatchingUser ? 'hide' : 'show'
  const subscribeUnsubscribeText = useMemo(
    () => (ownWatchingUser ? t('unsubscribe') : t('subscribe')),
    [t, ownWatchingUser],
  )

  const onSubscribeUnsubscribeClick = useCallback(() => {
    if (ownWatchingUser) {
      onUserUnsubscribe(ownWatchingUser.id)
      return
    }
    onUserSubscribe(userId)
  }, [onUserUnsubscribe, userId, onUserSubscribe, ownWatchingUser])

  const onStaffMemberSelect = useCallback((staffMember) => {
    setSelectedStaffMember(staffMember)
  }, [])

  return createPortal(
    <Popover
      open={isOpen}
      opener={opener}
      onAfterClose={onAfterClose}
      placementType={PopoverPlacementType.Bottom}
      className={styles.menuPopover}
    >
      <List mode={ListMode.None} separators={ListSeparators.Inner} className={styles.popoverList}>
        <StandardListItem
          icon={buttonIcon}
          type={ListItemType.Active}
          className={styles.popoverListItem}
          onClick={onSubscribeUnsubscribeClick}
        >
          {subscribeUnsubscribeText}
        </StandardListItem>
        <CustomListItem type={ListItemType.Inactive}>
          <FlexBox
            direction={FlexBoxDirection.Column}
            fitContainer
            className={styles.addWatcherFlexbox}
          >
            <Label>{t('add-watcher.label')}</Label>
            <StaffMemberAutocompleteInput
              staffMemberName={selectedStaffMember?.fullName}
              placeholder={t('search.placeholder')}
              onStaffMemberSelect={onStaffMemberSelect}
            />
          </FlexBox>
        </CustomListItem>
        <WatcherMenuWatchers
          watchers={watchers}
          onUserUnsubscribe={onUserUnsubscribe}
          userId={userId}
        />
      </List>
    </Popover>,
    document.body,
  )
}

WatcherMenuPopover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  opener: PropTypes.string.isRequired,
  onAfterClose: PropTypes.func.isRequired,
  ownWatchingUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
  }),
  watchers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
    }),
  ).isRequired,
  userId: PropTypes.string.isRequired,
}

export default WatcherMenuPopover
