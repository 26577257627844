import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { assessmentTypes } from 'components/domains/business-partners/tile/assessments/assessmentTypes'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useTextualAssessments } from 'hooks/services/business-partners/assessments/useTextualAssessments'
import useAuthorityLevelCalculatorList from 'hooks/services/business-partners/authority-level-calculator/useAuthorityLevelCalculatorList'

const useAnnualReviewGamStatement = ({ entityRef: { entityId: businessPartnerId } }, tileId) => {
  const {
    data: authorityLevelCalculatorListData,
    isLoading: isAuthorityLevelCalculatorDataLoading,
    isError: isAuthorityLevelCalculatorDataError,
    error: authorityLevelCalculatorError,
  } = useAuthorityLevelCalculatorList(businessPartnerId)

  // Since there should not be multiple gcc units, the first also should be the primary/only one.
  // If no GCC unit is present, the standalone unit or (as fallback) the decision paper entity itself is used.
  const authorityLevelCalculatorUnitToLoad = useMemo(() => {
    if (authorityLevelCalculatorListData?.gcc?.length > 0) {
      return authorityLevelCalculatorListData?.gcc[0]?.unitHeadBpId
    } else if (authorityLevelCalculatorListData?.standalone?.length > 0) {
      return authorityLevelCalculatorListData?.standalone[0]?.unitHeadBpId
    }
    return businessPartnerId
  }, [
    authorityLevelCalculatorListData?.gcc,
    authorityLevelCalculatorListData?.standalone,
    businessPartnerId,
  ])

  // BackOffice === GamStatement === B-Vote
  const {
    data: textualAssessmentData,
    isLoading: isTextualAssessmentLoading,
    isError: isTextualAssessmentError,
    error: textualAssessementError,
  } = useTextualAssessments(
    { businessPartnerId: authorityLevelCalculatorUnitToLoad, type: assessmentTypes.BackOffice },
    { enabled: !!authorityLevelCalculatorUnitToLoad },
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isAuthorityLevelCalculatorDataLoading, isTextualAssessmentLoading],
    errorValues: [isAuthorityLevelCalculatorDataError, isTextualAssessmentError],
    errorDetails: [authorityLevelCalculatorError, textualAssessementError],
    tileId,
  })

  const backofficeAssessment = useMemo(() => {
    if (isEmpty(textualAssessmentData?.availableVersions)) {
      return undefined
    }
    const { availableVersions } = textualAssessmentData
    // Sort descending by version number to return the content with highest version
    const latestAssessment = [...availableVersions].sort(
      ({ version: firstVersion }, { version: secondVersion }) => secondVersion - firstVersion,
    )[0]
    return latestAssessment?.content
  }, [textualAssessmentData])

  return useMemo(() => {
    if (isSomeValueError || isSomeValueLoading) {
      return {
        isLoading: isSomeValueLoading,
        isError: isSomeValueError,
        error,
      }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        sourceRender: { businessPartnerId: authorityLevelCalculatorUnitToLoad },
        backofficeAssessment,
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    authorityLevelCalculatorUnitToLoad,
    backofficeAssessment,
    error,
  ])
}

export default useAnnualReviewGamStatement
