import { Table, TableCell, TableColumn, TableRow } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

/**
 * A table component that displays the pre-validations before the property creation requests are fired.
 * This table is rendered in an error dialog when at least one validation fails.
 * @param {Object} props - The props object for the component.
 * @param {Object} props.groupedErrors - An object containing the errors grouped by type.
 * @param {Array<Error>} props.groupedErrors.errors - An array of objects representing the errors of a specific type.
 * @param {string} props.groupedErrors.errors.type - The type of the error.
 * @param {Object} props.groupedErrors.errors.data - The data associated with the error.
 * @param {number} props.groupedErrors.errors.data.column - The column number of the error.
 * @param {number} props.groupedErrors.errors.data.row - The row number of the error.
 * @param {string} props.groupedErrors.errors.data.header - The header of the column where the error occurred.
 * @param {string} props.groupedErrors.errors.data.value - The value of the cell where the error occurred.
 * @returns {JSX.Element} - A JSX element representing the MultiPropertyUploadValidationErrorTable component.
 */
const MultiPropertyUploadValidationErrorTable = ({ groupedErrors }) => {
  const { t: tMultiPropertyUpload } = useTranslation('translation', {
    keyPrefix: 'pages.property-upload',
  })
  return (
    <Table
      columns={
        <>
          <TableColumn>{tMultiPropertyUpload('column.error-type')}</TableColumn>
          <TableColumn>{tMultiPropertyUpload('column.column-index')}</TableColumn>
          <TableColumn>{tMultiPropertyUpload('column.row-index')}</TableColumn>
          <TableColumn>{tMultiPropertyUpload('column.column-header-string')}</TableColumn>
          <TableColumn>{tMultiPropertyUpload('column.value')}</TableColumn>
        </>
      }
    >
      {Object.entries(groupedErrors).map(([_, errors], groupIndex) =>
        errors.map((row, rowIndex) => (
          <TableRow key={`${groupIndex}-${rowIndex}`}>
            <TableCell key={`${rowIndex}-type`}>{tMultiPropertyUpload(row.type)}</TableCell>
            <TableCell key={`${rowIndex}-column`}>{row.data.column}</TableCell>
            <TableCell key={`${rowIndex}-row`}>{row.data.row}</TableCell>
            <TableCell key={`${rowIndex}-header`}>{row.data.header}</TableCell>
            <TableCell key={`${rowIndex}-value`}>{row.data.value}</TableCell>
          </TableRow>
        )),
      )}
    </Table>
  )
}
MultiPropertyUploadValidationErrorTable.propTypes = {
  groupedErrors: PropTypes.object.isRequired,
}
export default MultiPropertyUploadValidationErrorTable
