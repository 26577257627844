import {
  TABLE_ROW_WITH_HEADQUARTER_CURRENCY_HEIGHT,
  TABLE_ROW_WITHOUT_HEADQUARTER_CURRENCY_HEIGHT,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche/shared/constants'
import mapTrancheDrawdownData from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche/utils/mapTrancheDrawdownData'

const mapTrancheData = (
  t,
  {
    rank,
    trancheId,
    displayId,
    trancheName,
    externalContractId,
    productTypeCode,
    productTypeShortText,
    loanTypeCode,
    loanTypeShortText,
    maturity,
    repaymentTypeCode,
    repaymentTypeShortText,
    repaymentRate,
    customerMargin,
    interestRateTypeCode,
    interestRateTypeShortText,
    creationTimestamp,
    ownCommitmentShare,
    ownShare,
    ownShareHeadquarter,
    totalCommitment,
    totalCommitmentHeadquarter,
    drawdowns,
  },
) => {
  const hasHeadquarterCurrencyEntry =
    totalCommitmentHeadquarter?.currency !== totalCommitment?.currency
  return {
    rank,
    tranche: {
      name: trancheName,
      displayId,
      externalContractId,
    },
    loanTypeCode: {
      key: loanTypeCode,
      text: loanTypeShortText,
    },
    productTypeCode: {
      key: productTypeCode,
      text: productTypeShortText,
    },
    totalCommitment: {
      original: {
        value: totalCommitment?.amount,
        currency: totalCommitment?.currency,
      },
      converted: {
        value: totalCommitmentHeadquarter?.amount,
        currency: totalCommitmentHeadquarter?.currency,
      },
    },
    commitment: {
      original: {
        value: ownShare?.commitment,
        currency: ownShare?.currency,
      },
      converted: {
        value: ownShareHeadquarter?.commitment,
        currency: ownShareHeadquarter?.currency,
      },
      share: ownCommitmentShare,
    },
    syndicatedAmountTotal: {
      original: {
        value: totalCommitment?.amount - ownShare?.commitment,
        currency: ownShare?.currency,
      },
      converted: {
        value: totalCommitmentHeadquarter?.amount - ownShareHeadquarter?.commitment,
        currency: ownShareHeadquarter?.currency,
      },
      share: 1 - ownCommitmentShare,
    },
    outstanding: {
      original: {
        value: ownShare?.outstanding,
        currency: ownShare?.currency,
      },
      converted: {
        value: ownShareHeadquarter?.outstanding,
        currency: ownShareHeadquarter?.currency,
      },
    },
    available: {
      original: {
        value: ownShare?.available,
        currency: ownShare?.currency,
      },
      converted: {
        value: ownShareHeadquarter?.available,
        currency: ownShareHeadquarter?.currency,
      },
    },
    maturity,
    repayment: {
      key: repaymentTypeCode,
      text: repaymentTypeShortText,
      rate: repaymentRate,
    },
    customerMargin,
    interestRateTypeCode: {
      key: interestRateTypeCode,
      text: interestRateTypeShortText,
    },
    creationTimestamp,
    trancheId,
    rowHeight: hasHeadquarterCurrencyEntry
      ? TABLE_ROW_WITH_HEADQUARTER_CURRENCY_HEIGHT
      : TABLE_ROW_WITHOUT_HEADQUARTER_CURRENCY_HEIGHT,
    subRows: drawdowns?.map((drawdown) => mapTrancheDrawdownData(t, drawdown)),
  }
}

export default mapTrancheData
