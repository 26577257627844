import { covenantChecksBreachMap } from 'components/domains/deals/covenants/covenant-checks-table/useCovenantChecksTableData'

const useCovenantChecksTableEnumns = ({ data, t }) => {
  const { statusEnum, breachEnumns, monitoringReferenceTypeEnumns } = data.reduce(
    (accumulate, current) => ({
      // Status
      statusEnum: { ...accumulate.statusEnum, [current?.status]: t(`enum.${current?.status}`) },
      // Breach
      breachEnumns: {
        ...accumulate.breachEnumns,
        [current?.breach]: t(`enum.${covenantChecksBreachMap.get(current?.breach)}`),
      },
      // MonitoringReferenceType
      monitoringReferenceTypeEnumns: {
        ...accumulate.monitoringReferenceTypeEnumns,
        [current?.monitoringReferenceEntity?.type]: t(
          `enum.${current?.monitoringReferenceEntity?.type}`,
        ),
      },
    }),
    { statusEnum: {}, breachEnumns: {}, monitoringReferenceTypeEnumns: {} },
  )
  delete statusEnum.undefined
  delete breachEnumns.undefined
  delete monitoringReferenceTypeEnumns.undefined
  return { statusEnum, breachEnumns, monitoringReferenceTypeEnumns }
}

export default useCovenantChecksTableEnumns
