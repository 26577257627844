import {
  IllustrationMessageSize,
  IllustrationMessageType,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/rent-roll/overview/occupancy/PropertyRentRollOverviewOccupancyCardPieChart.module.css'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import DonutChart from 'components/ui/charts/donut-chart/DonutChart'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useNumberFormatter, useAreaMeasurementUnitFormatter } from 'hooks/i18n/useI18n'
import usePropertyTenantsPieChartData from 'hooks/services/properties/kpis/charts/usePropertyTenantsPieChartData'
import useCurrentRentRollOverviewPropertyKpis from 'hooks/services/properties/kpis/useCurrentRentRollOverviewPropertyKpis'

export const PIE_CELL_COLOR_VACANCY = '#C53F38'
export const PIE_CELL_COLOR_OWNER_OCCUPIED = '#D97B49'
export const MAX_DISPLAYED_TENANTS = 4

const getTenantUsedAreaSurface = (tenantKpi) => tenantKpi?.letSurface?.value ?? 0

const useOccupancyChartData = (propertyKpiData) => {
  const { t: tOccupancy } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.occupancy',
  })

  const {
    data: tenantsPieChartData,
    isLoading,
    isError,
  } = usePropertyTenantsPieChartData({
    propertyKpiData,
    getMetricFromTenantKpi: getTenantUsedAreaSurface,
    maxDisplayedTenants: MAX_DISPLAYED_TENANTS,
  })

  const totalAreaSurface = propertyKpiData?.totalAreaSurface?.value ?? 0
  const vacancySurface = propertyKpiData?.vacancySurface?.value ?? 0
  const ownerOccupiedSurface = propertyKpiData?.selfSurface?.value ?? 0
  const areaUnit = propertyKpiData?.totalAreaSurface?.measurementUnit

  const occupancyPieChartData = [...tenantsPieChartData]

  if (vacancySurface > 0) {
    occupancyPieChartData.push({
      value: vacancySurface,
      name: tOccupancy('pie-chart.vacant'),
      color: PIE_CELL_COLOR_VACANCY,
    })
  }

  if (ownerOccupiedSurface > 0) {
    occupancyPieChartData.push({
      value: ownerOccupiedSurface,
      name: tOccupancy('pie-chart.owner-occupied'),
      color: PIE_CELL_COLOR_OWNER_OCCUPIED,
    })
  }

  const isInvalidData = totalAreaSurface <= 0

  return {
    data: occupancyPieChartData,
    areaUnit,
    isLoading,
    isError,
    isEmpty: occupancyPieChartData.length === 0,
    isInvalidData,
  }
}

const PropertyRentRollOverviewOccupancyCardPieChart = ({ showHeader = false }) => {
  const { t: tPieChart } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.occupancy.pie-chart',
  })

  const {
    isLoading: isLoadingKpis,
    isError: isErrorKpis,
    data: propertyKpis,
  } = useCurrentRentRollOverviewPropertyKpis()

  const {
    data: occupancyChartData,
    areaUnit,
    isLoading: isLoadingChartData,
    isError: isErrorChartData,
    isInvalidData,
    isEmpty,
  } = useOccupancyChartData(propertyKpis)

  const formatNumber = useNumberFormatter({ maximumFractionDigits: 2, minimumFractionDigits: 2 })
  const formatAreaUnit = useAreaMeasurementUnitFormatter()
  const formatTooltipArea = useCallback(
    (value) => `${formatNumber(value)} ${formatAreaUnit(areaUnit)}`,
    [areaUnit, formatAreaUnit, formatNumber],
  )

  const isLoading = isLoadingKpis || isLoadingChartData
  const isError = isErrorKpis || isErrorChartData

  // handle an edge case where the total area surface of the property is zero, which would
  // result in an empty chart. In this case we simply show nothing.
  const shouldHideChart = !isError && !isLoading && !isEmpty && isInvalidData

  if (shouldHideChart) return null

  return (
    <div className={styles.chartContainer}>
      <LoadingStateWrapper
        isLoading={isLoading}
        isError={isError}
        errorTitle={tPieChart('error.title')}
        errorDescription={tPieChart('error.subtitle')}
        errorDetails=" "
        loadingDescription={tPieChart('loading')}
      >
        {isEmpty ? (
          <EmptyCardContent
            title={tPieChart('empty.title')}
            subtitle={tPieChart('empty.subtitle')}
            illustrationName={IllustrationMessageType.UnableToLoad}
            size={IllustrationMessageSize.Scene}
          />
        ) : (
          <>
            {showHeader && (
              <Title level={TitleLevel.H4} className={styles.header}>
                {tPieChart('header')}
              </Title>
            )}
            <DonutChart data={occupancyChartData} tooltipValueFormatter={formatTooltipArea} />
          </>
        )}
      </LoadingStateWrapper>
    </div>
  )
}

PropertyRentRollOverviewOccupancyCardPieChart.propTypes = {
  showHeader: PropType.bool,
}

export default PropertyRentRollOverviewOccupancyCardPieChart
