import {
  BusyIndicatorSize,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { propertyAllowedOperations } from 'api/property/propertyAllowedOperations'
import PropertyRentRollKpiChartsDataProvider from 'components/domains/properties/common/PropertyRentRollKpiChartsDataProvider'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import NavigationL2 from 'components/ui/navigation/NavigationL2'
import { useURLSearchParams } from 'hooks/router/useURLSearchParams'
import useCurrentRentRollOverviewMultiPropertyKpis from 'hooks/services/properties/kpis/useCurrentRentRollOverviewMultiPropertyKpis'
import { useMultiPropertyViewById } from 'hooks/services/properties/useMultiPropertyViewById'
import { usePropertiesPermissions } from 'hooks/services/properties/usePropertiesPermissions'
import usePropertyUuids from 'hooks/services/properties/usePropertyUuids'
import { PropertyPortfolioContext } from 'routes/properties/portfolio/PropertyPortfolioContext'

export const PORTFOLIO_VIEW_QUERY_PARAM = 'portfolio-view-id'
export const multiPropertyViewDefault = {
  uuid: '',
  propertyUuids: [],
}

const buildPortfolioNavItems = (multiPropertyView) => {
  const searchParams = new URLSearchParams()
  searchParams.append(PORTFOLIO_VIEW_QUERY_PARAM, multiPropertyView.uuid)
  const qs = searchParams.toString()

  return [
    {
      path: `overview?${qs}`,
      translationToken: 'pages.property-overview.overview',
    },
    {
      path: `rent-roll?${qs}`,
      translationToken: 'pages.property-overview.rent-roll',
    },
    {
      path: `valuation-requests?${qs}`,
      translationToken: 'pages.property-overview.valuation-requests',
    },
  ]
}

const PropertyPortfolioWrapper = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.properties' })
  const urlSearchParams = useURLSearchParams()
  const multiPropertyViewId = urlSearchParams.get(PORTFOLIO_VIEW_QUERY_PARAM)

  const { data: multiPropertyViewReturned, isError: isErrorMultiPropertyView } =
    useMultiPropertyViewById(multiPropertyViewId)

  const multiPropertyView = multiPropertyViewReturned ?? multiPropertyViewDefault

  const {
    isError: isErrorPermissions,
    isLoading: isLoadingPermissions,
    data: propertyPermissions,
  } = usePropertiesPermissions()

  const allowedOperations = useMemo(
    () => propertyPermissions?.allowed_operations ?? [],
    [propertyPermissions?.allowed_operations],
  )

  const {
    data,
    isLoading: isLoadingProperties,
    isError: isErrorProperties,
  } = usePropertyUuids(multiPropertyView.propertyUuids)

  const properties = useMemo(() => data?.data?.properties ?? [], [data?.data?.properties])

  const renderContent = useCallback(() => {
    const hasPropertyReadPermission = allowedOperations.includes(
      propertyAllowedOperations.propertyRead,
    )

    if (!hasPropertyReadPermission) {
      return (
        <CenteredIllustratedMessage
          name={IllustrationMessageType.UnableToLoad}
          size={IllustrationMessageSize.Spot}
          titleText={t('permissions.error.title')}
          subtitleText={t('permissions.error.sub-title')}
        />
      )
    }

    const navLink = buildPortfolioNavItems(multiPropertyView)

    const hasPropertyValuationReadPermission = allowedOperations.includes(
      propertyAllowedOperations.propertyValuationRead,
    )

    if (!hasPropertyValuationReadPermission) {
      navLink.pop()
    }

    return (
      <PropertyPortfolioContext.Provider value={{ properties, allowedOperations }}>
        <PropertyRentRollKpiChartsDataProvider
          usePropertyRentRollKpiChartsData={useCurrentRentRollOverviewMultiPropertyKpis}
        >
          <NavigationL2 navItems={navLink} />
        </PropertyRentRollKpiChartsDataProvider>
      </PropertyPortfolioContext.Provider>
    )
  }, [allowedOperations, multiPropertyView, properties, t])

  return (
    <RequestStateResolver
      isError={
        isErrorProperties ||
        isErrorPermissions ||
        isErrorMultiPropertyView ||
        (!isLoadingProperties && properties?.length !== multiPropertyView.propertyUuids?.length)
      }
      errorToDisplay={
        <CenteredIllustratedMessage
          name={IllustrationMessageType.UnableToLoad}
          size={IllustrationMessageSize.Spot}
          titleText={t('portfolio.loading-error.title')}
          subtitleText={t('portfolio.loading-error.description')}
        />
      }
      isLoading={isLoadingProperties || isLoadingPermissions}
      center
      busyIndicatorSize={BusyIndicatorSize.Medium}
      renderContent={renderContent}
    />
  )
}

export default PropertyPortfolioWrapper
