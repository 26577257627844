import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { requirementStatusTypes } from 'api/conditions/conditions'
import TableStatusCell from 'components/domains/conditions/overview/table-cells/view/TableStatusCell'
import useDeprecatedValues from 'hooks/common/useDeprecatedValues'
import useRequirementsStatus from 'hooks/services/conditions/config/useRequirementsStatus'
import useRequirementsCellAddMode from 'hooks/services/conditions/edit-mode/useRequirementsCellAddMode'
import useRequirementsCellEditMode from 'hooks/services/conditions/edit-mode/useRequirementsCellEditMode'
import { requirementStatusChanged } from 'redux/slices/conditions/requirementsTableSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const RequirementsTableStatusCell = ({
  value: {
    requirementId,
    status: { type: statusType, name: statusName, code: statusCode },
  },
}) => {
  const dispatch = useDispatch()

  const {
    entityRef: { entityType },
  } = useContext(ConditionsContext)

  const {
    isLoading,
    isError,
    data: statusData,
    ...useRequirementsStatusResult
  } = useRequirementsStatus({ entityType, includeDeprecated: false })

  const editedRequirementStatusCode = useSelector(
    (state) => state.conditions.requirementsTable.editedRow.currentValues?.status?.code,
  )

  const { isAddModeForCurrentRow } = useRequirementsCellAddMode({
    requirementId,
  })
  const { isEditModeForCurrentRow } = useRequirementsCellEditMode({
    requirementId,
  })

  const onChangeSelection = useCallback(
    (newStatusCode, newStatusType) => {
      dispatch(requirementStatusChanged({ status: { code: newStatusCode, type: newStatusType } }))
    },
    [dispatch],
  )

  const availableValues = useMemo(() => statusData?.status ?? [], [statusData?.status])

  const { deprecatedValues } = useDeprecatedValues({
    availableValues: availableValues,
    selectedValues: [{ name: statusName, code: statusCode }],
  })
  const statusDataWithDeprecated = useMemo(
    () => ({
      ...useRequirementsStatusResult,
      isLoading,
      isError,
      data: {
        status: [...deprecatedValues, ...availableValues],
      },
    }),
    [availableValues, deprecatedValues, isError, isLoading, useRequirementsStatusResult],
  )
  const statusLoader = useCallback(() => statusDataWithDeprecated, [statusDataWithDeprecated])

  const { actualStatusType, actualStatusName } = useMemo(() => {
    if (isNil(statusData) || !isAddModeForCurrentRow) {
      return { actualStatusType: statusType, actualStatusName: statusName }
    }
    const { status } = statusData
    const defaultTypeOpenStatusList = status.filter(
      ({ type, isDefaultStatusForType }) =>
        isDefaultStatusForType && type === requirementStatusTypes.open,
    )
    if (defaultTypeOpenStatusList.length !== 1) {
      return { actualStatusType: statusType, actualStatusName: statusName }
    }
    return {
      actualStatusType: defaultTypeOpenStatusList[0].type,
      actualStatusName: defaultTypeOpenStatusList[0].name,
    }
  }, [statusData, statusType, statusName, isAddModeForCurrentRow])

  return (
    <TableStatusCell
      showEmptyOption={false}
      statusType={actualStatusType}
      statusName={actualStatusName}
      editedStatusCode={editedRequirementStatusCode}
      loadingHook={statusLoader}
      onChange={onChangeSelection}
      isEditMode={isEditModeForCurrentRow}
      disabled={isAddModeForCurrentRow}
    />
  )
}

RequirementsTableStatusCell.propTypes = {
  value: PropTypes.shape({
    requirementId: PropTypes.string,
    status: PropTypes.shape({
      type: PropTypes.oneOf(Object.values(requirementStatusTypes)),
      name: PropTypes.string,
      code: PropTypes.string,
    }),
  }),
}

export default RequirementsTableStatusCell
