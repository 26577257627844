import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const usePutProperty = ({ onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()

  const getStringOrUndefinedIfNotSet = (attribute) => (attribute ? attribute : undefined)
  const getNumberOrUndefinedIfNotSet = (attribute) =>
    attribute && !isNaN(attribute) ? Number(attribute) : undefined
  const getMoneyOrUndefinedIfNotSet = (value, currency) =>
    value && currency && !isNaN(value)
      ? {
          number: value,
          currency: currency,
        }
      : undefined

  return useMutation(
    async ({ forceUpdate, ...property }) => {
      const { data } = await put({
        path: `/properties`,
        body: {
          force_update: forceUpdate,
          property: {
            type_code: getStringOrUndefinedIfNotSet(property.typeCode),
            area_measure_unit_code: getStringOrUndefinedIfNotSet(property.areaMeasureUnitCode),
            description: getStringOrUndefinedIfNotSet(property.description),
            total_area_measure: getNumberOrUndefinedIfNotSet(property.totalArea),
            currency_code: getStringOrUndefinedIfNotSet(property.currencyCode),
            real_estate_usage_code: getStringOrUndefinedIfNotSet(property.realEstateUsageCode),
            market_value_amount: getMoneyOrUndefinedIfNotSet(
              property.marketValue,
              property.currencyCode,
            ),
            address: {
              street_name: getStringOrUndefinedIfNotSet(property.streetName),
              house_id: getStringOrUndefinedIfNotSet(property.houseNumber),
              postal_code: getStringOrUndefinedIfNotSet(property.postalCode),
              city_name: getStringOrUndefinedIfNotSet(property.cityName),
              country_code: getStringOrUndefinedIfNotSet(property.countryCode),
            },
            geo_location: {
              latitude: getStringOrUndefinedIfNotSet(property.latitude),
              longitude: getStringOrUndefinedIfNotSet(property.longitude),
            },
            usage: {
              usage_code: getStringOrUndefinedIfNotSet(property.typeCodeMinor),
              utilization_code: getStringOrUndefinedIfNotSet(property.utilization),
            },
            construction_completion_date: getStringOrUndefinedIfNotSet(
              property.constructionCompletionDate,
            ),
            construction_completion_percent: getStringOrUndefinedIfNotSet(
              property.constructionCompletionPercent,
            ),
            actual_rent_amount: getMoneyOrUndefinedIfNotSet(
              property.actualRentAmount,
              property.currencyCode,
            ),
          },
        },
      })
      return camelize(data)
    },
    { onSuccess, onError },
  )
}
