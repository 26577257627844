import { Button } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropertyPortfolioRentRollCardTable from 'components/domains/properties/portfolio/rent-roll/PropertyPortfolioRentRollCardTable'
import PropertyRentRollExcelExporter from 'components/domains/properties/rent-roll/excel-export/PropertyRentRollExcelExporter'
import CreateRentRollWorkingVersionButton from 'components/domains/properties/rent-roll/working-version/PropertyRentRollCreateWorkingVersionButton'
import PropertyRentRollWorkingVersionEdit from 'components/domains/properties/rent-roll/working-version/PropertyRentRollWorkingVersionEdit'
import PropertyRentRollExcelUpload from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollExcelUpload'
import ExcelUploadContextProvider from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollExcelUploadContext'
import Card from 'components/ui/card/Card'
import CardHeaderWithButtons from 'components/ui/card/CardHeaderWithButtons'
import { useRentRolls } from 'hooks/services/properties/rent-roll/useRentRolls'
import { useMultiPropertyViewByPropertyUuids } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuids'
import { PropertyPortfolioContext } from 'routes/properties/portfolio/PropertyPortfolioContext'

export const PropertyPortfolioRentRollCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.properties.portfolio.rent-roll',
  })
  const { properties } = useContext(PropertyPortfolioContext)
  const propertyUuids = useMemo(() => properties.map(({ uuid }) => uuid), [properties])
  const { data: multiPropertyViewData } = useMultiPropertyViewByPropertyUuids(propertyUuids)

  const {
    isLoading: isRentRollsLoading,
    isError: isRentRollsError,
    data: rentRolls,
  } = useRentRolls(propertyUuids, { enabled: !isEmpty(propertyUuids) })

  const rentalUnits = useMemo(
    () =>
      isRentRollsError || isRentRollsLoading || !rentRolls
        ? []
        : rentRolls?.flatMap(({ rental_units }) => rental_units),
    [isRentRollsError, isRentRollsLoading, rentRolls],
  )

  return (
    <Card>
      <CardHeaderWithButtons title={`${t('properties-card.title')} (${properties.length})`}>
        {multiPropertyViewData && (
          <PropertyRentRollExcelExporter
            properties={properties}
            multiPropertyViewId={multiPropertyViewData.data?.uuid}
          >
            {({ onClick }) => (
              <Button onClick={onClick}>{t('properties-card.excel-export-button')}</Button>
            )}
          </PropertyRentRollExcelExporter>
        )}
        <ExcelUploadContextProvider>
          <PropertyRentRollExcelUpload propertyUuids={propertyUuids} />
        </ExcelUploadContextProvider>
        <CreateRentRollWorkingVersionButton rentalUnits={rentalUnits} properties={properties} />
        <PropertyRentRollWorkingVersionEdit properties={properties} />
      </CardHeaderWithButtons>
      <PropertyPortfolioRentRollCardTable properties={properties} />
    </Card>
  )
}
