import { AnalyticalTableScaleWidthMode } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/ForbearanceMeasuresTable.module.css'
import { TABLE_HEADER_ROW_HEIGHT } from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/domains/business-partners/tile/risk-monitoring/forbearance/constants'
import AnalyticalTableNoDataComponent from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/analytical/AnalyticalTableWithToolbar'
import { useCollapseExpandTableActions } from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/analytical/useCollapseExpandTableActions'
import useForbearanceMeasuresTableColumnDefinitions from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/useForbearanceMeasuresTableColumnDefinitions'

const NoDataComponent = () => (
  <AnalyticalTableNoDataComponent
    tableId="forbearanceMeasuresTable"
    isLoading={false}
    isError={false}
  />
)

const propTypes = {
  tileId: PropTypes.string.isRequired,
}
/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const ForbearanceMeasuresTable = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.forbearance.measures-table',
  })
  const tableRef = useRef(null)

  const { tableData = [], nrOfMeasures } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId].data,
  )

  const { handleOnRowExpand, additionalTableActions, expandOrCollapseSubRows } =
    useCollapseExpandTableActions(tableRef)

  const columns = useForbearanceMeasuresTableColumnDefinitions()

  // expand rows as default
  useEffect(() => {
    expandOrCollapseSubRows(true)
  }, [expandOrCollapseSubRows])

  return (
    <div className={styles.tableWrapper}>
      <AnalyticalTableWithToolbar
        title={t('title')}
        nrOfEntries={nrOfMeasures}
        additionalActions={additionalTableActions}
        onRowExpandChange={(event) => handleOnRowExpand(event.detail.row)}
        id="forbearanceMeasuresTable"
        sortable={false}
        minRows={0}
        columns={columns}
        overscanCountHorizontal={99}
        // high number of visible rows fixes re-rendering of height on expand
        visibleRows={99}
        // rowHeight needs to be set to a non-empty string to not show an empty row at the end
        rowHeight="individual"
        headerRowHeight={TABLE_HEADER_ROW_HEIGHT}
        data={tableData}
        isTreeTable={true}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
        NoDataComponent={NoDataComponent}
        reactTableOptions={{
          autoResetPage: false,
          autoResetExpanded: false,
          autoResetSelectedRows: false,
          autoResetSortBy: false,
          autoResetFilters: false,
          selectSubRows: false,
        }}
        ref={tableRef}
      />
    </div>
  )
}

ForbearanceMeasuresTable.propTypes = propTypes

export default ForbearanceMeasuresTable
