import isNil from 'lodash.isnil'
import sum from 'lodash.sum'
import uniq from 'lodash.uniq'
import upperFirst from 'lodash.upperfirst'
import { useContext } from 'react'
import { TODAY } from 'components/domains/business-partners/tile/arrears/kpis/ArrearsKpiCards'
import useBusinessPartnerArrearByBusinessPartnerId from 'hooks/services/arrears/useBusinessPartnerArrearByBusinessPartnerId'
import { BusinessPartnerArrearsContext } from 'routes/business-partners/arrears/BusinessPartnerArrearsContext'
import { ENTITY_TYPES } from 'routes/business-partners/arrears/constants'
import { getItemsWithKey } from 'utils/getItemsWithKey'

const HEADQUARTER_CURRENCY = 'EUR'

const getCurrencies = (arr, itemKey) => {
  const currencies = uniq(getItemsWithKey(arr, itemKey))

  if (currencies.length === 1) {
    return { isUniqCurrency: true, currency: currencies[0] }
  } else {
    return { isUniqCurrency: false, currency: HEADQUARTER_CURRENCY }
  }
}

const getSumAmountAndCurrency = (arr, currencyKey, itemKey) => {
  let sumAmount
  const currencies = getCurrencies(arr, currencyKey)
  if (currencies.isUniqCurrency) {
    sumAmount = sum(getItemsWithKey(arr, itemKey))
  } else {
    const headquarterItemKey = `headquarter${upperFirst(itemKey)}`
    sumAmount = sum(getItemsWithKey(arr, headquarterItemKey))
  }

  return { sumAmount, currency: currencies.currency }
}

const getSumAmountAndCurrencyByProducts = (products, accessors) => {
  let originalCurrentClosedCommitment = 0
  let headquarterCurrentClosedCommitment = 0

  const uniqueOriginalCurrencyOrNull = getCurrencies(products, accessors.originalCurrency)

  const isTranche = (product) => product.type === ENTITY_TYPES.TRANCHE
  const isCreditAccount = (product) => product.type === ENTITY_TYPES.BCA && product.isCreditAccount
  products.forEach((product) => {
    if (isTranche(product) || isCreditAccount(product)) {
      originalCurrentClosedCommitment += product[accessors.originalValue] ?? 0
      headquarterCurrentClosedCommitment += product[accessors.headquarterValue] ?? 0
    }
  })

  if (uniqueOriginalCurrencyOrNull.isUniqCurrency) {
    return {
      amount: originalCurrentClosedCommitment,
      currency: uniqueOriginalCurrencyOrNull.currency,
    }
  } else {
    return {
      amount: headquarterCurrentClosedCommitment,
      currency: HEADQUARTER_CURRENCY,
    }
  }
}

const getApprovedItemFromApprovalStatuses = (approvalStatuses) => {
  const approvedItem = approvalStatuses.find((status) => status.approvalStatus === 'APPROVED')
  if (!isNil(approvedItem)) {
    return approvedItem.arrearApprovalItemOrNull
  }
  const partiallyApprovedItem = approvalStatuses.find(
    (status) => status.approvalStatus === 'PARTIALLY_APPROVED',
  )
  if (!isNil(partiallyApprovedItem)) {
    return partiallyApprovedItem.arrearApprovalItemOrNull
  }
  return null
}

const getArrearsKpiCardsData = (arrears, bpArrearData, products) => {
  const approvedArrears = arrears.filter(
    ({ approvalStatusList }) =>
      approvalStatusList?.includes('APPROVED') ||
      approvalStatusList?.includes('PARTIALLY_APPROVED'),
  )
  const approvalItemsForApprovedArrears = approvedArrears
    .map((arrear) => getApprovedItemFromApprovalStatuses(arrear.approvalStatuses))
    .filter(Boolean)

  const arrearsAmountAndCurrency = getSumAmountAndCurrency(arrears, 'currency', 'arrearAmount')
  const overpaymentAmountAndCurrency = getSumAmountAndCurrency(
    arrears,
    'currency',
    'overpaymentAmount',
  )

  const approvedAmount = sum(getItemsWithKey(approvalItemsForApprovedArrears, 'approvalAmount'))

  const currentClosedCommitmentAndCurrency = getSumAmountAndCurrencyByProducts(products, {
    originalValue: 'currentClosedCommitment',
    originalCurrency: 'currentClosedCommitmentCurrency',
    headquarterValue: 'headquarterCurrentClosedCommitment',
    headquarterCurrency: 'headquarterCurrentClosedCommitmentCurrency',
  })

  const outstandingAndCurrency = getSumAmountAndCurrencyByProducts(products, {
    originalValue: 'outstandingOwnShare',
    originalCurrency: 'ownShareCurrency',
    headquarterValue: 'headquarterOutstandingOwnShare',
    headquarterCurrency: 'headquarterOwnShareCurrency',
  })

  const arrearsKeyDate = arrears?.[0]?.keyDate ?? null
  const bpArrearsKeyDate = bpArrearData?.keyDate ?? null

  return [
    {
      cardTitle: 'arrear-amount',
      unit: { value: arrearsAmountAndCurrency.currency, isCurrency: true },
      kpiValue: arrearsAmountAndCurrency.sumAmount,
      keyDate: arrearsKeyDate,
    },
    {
      cardTitle: 'overpayment',
      unit: { value: overpaymentAmountAndCurrency.currency, isCurrency: true },
      kpiValue: overpaymentAmountAndCurrency.sumAmount,
      keyDate: arrearsKeyDate,
    },
    {
      cardTitle: 'approved-amount',
      unit: { value: HEADQUARTER_CURRENCY, isCurrency: true },
      kpiValue: approvedAmount,
      keyDate: TODAY,
    },
    {
      cardTitle: 'commitment',
      unit: { value: currentClosedCommitmentAndCurrency?.currency, isCurrency: true },
      kpiValue: currentClosedCommitmentAndCurrency?.amount,
      keyDate: TODAY,
    },
    {
      cardTitle: 'outstanding',
      unit: { value: outstandingAndCurrency?.currency, isCurrency: true },
      kpiValue: outstandingAndCurrency?.amount,
      keyDate: TODAY,
    },
    {
      cardTitle: 'days-of-grace',
      unit: { value: 'unit.days', isCurrency: false },
      kpiValue: bpArrearData?.pastDueDefaultDays,
      secondaryMetrics: [{ label: 'default-quote', value: bpArrearData?.dodQuota }],
      keyDate: bpArrearsKeyDate,
    },
  ]
}

export const useArrearsKpiCardsData = () => {
  const {
    businessPartnerId,
    arrears,
    products,
    isError: isErrorArrear,
    isLoading: isLoadingArrear,
  } = useContext(BusinessPartnerArrearsContext)

  const {
    data: bpArrearData,
    isLoading: isLoadingBpArrear,
    isError: isErrorBpArrear,
  } = useBusinessPartnerArrearByBusinessPartnerId({
    businessPartnerId,
  })

  const initialKpisData = [
    {
      cardTitle: 'arrear-amount',
    },
    {
      cardTitle: 'overpayment',
    },
    {
      cardTitle: 'approved-amount',
    },
    {
      cardTitle: 'commitment',
    },
    {
      cardTitle: 'outstanding',
    },
    {
      cardTitle: 'days-of-grace',
    },
  ]

  const isLoading = isLoadingArrear || isLoadingBpArrear
  const isError = isErrorArrear || isErrorBpArrear

  if (isLoading || isError) {
    return { isLoading, isError, data: initialKpisData }
  } else {
    return {
      isLoading,
      isError,
      data: getArrearsKpiCardsData(arrears, bpArrearData, products),
    }
  }
}
