import PropTypes from 'prop-types'
import { TextWithExpandableDetail } from 'components/domains/deals/message/TextWithExpandableDetail'
import styles from 'components/domains/deals/message/TextWithExpandableJson.module.css'

const textWithJsonDetailListProps = {
  className: PropTypes.string,
  text: PropTypes.string,
  json: PropTypes.instanceOf(Object),
}

/**
 * move to ui-components | https://fioneer.atlassian.net/browse/CWP-13200
 */
const TextWithExpandableJson = ({ text, json, ...props }) => {
  const renderDetail = () => {
    if (!json) return
    return (
      <div className={styles.json}>
        <pre>{JSON.stringify(json, null, 2)}</pre>
      </div>
    )
  }

  return <TextWithExpandableDetail {...props} text={text} detail={renderDetail()} />
}

TextWithExpandableJson.propTypes = textWithJsonDetailListProps

export default TextWithExpandableJson
