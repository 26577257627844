import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteRequirementReminder = (mutateOptions) => {
  const { delete: deleteCall } = useAccessTokenRequest()
  return useMutation(
    ({ requirementId, reminderId }) =>
      deleteCall({
        path: `/conditions/external/requirements/${requirementId}/reminders/${reminderId}`,
      }),
    mutateOptions,
  )
}

export default useDeleteRequirementReminder
