import { FlexBox, FlexBoxDirection, TextArea } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionStageVoteOptions } from 'api/decision-process/decisionProcessApi'
import styles from 'components/domains/business-events-and-tasks/decision-stage/approval/voter/buttons/DecisionStageApprovalVoterDeclineButton.module.css'
import SemanticButton, { SemanticButtonDesign } from 'components/ui/button/SemanticButton'
import DialogPrimaryButton from 'components/ui/dialog/DialogPrimaryButton'
import MessageBox, {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import useIndividualVoteCheck from 'hooks/services/business-events-and-tasks/decision-process/stages/error-checks/useIndividualVoteCheck'
import useEditDecisionStageVoter from 'hooks/services/business-events-and-tasks/decision-process/stages/useEditDecisionStageVoter'

const DecisionStageApprovalVoterDeclineButton = ({
  decisionStageId,
  eventId,
  decisionStageVoterId,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'decision-stage.vote-options.voter.decline',
  })
  const [declineReason, setDeclineReason] = useState('')
  const [isDeclineMessageBoxOpen, setIsDeclineMessageBoxOpen] = useState(false)
  const { mutate: editDecisionStageVote } = useEditDecisionStageVoter()
  const showMessageBox = useShowMessageBox()

  const queriesToInvalidate = useMemo(() => ['events', eventId, 'decision-stages'], [eventId])

  const linkTo = useMemo(
    () =>
      `/business-events-and-tasks/business-events/${eventId}/decision-stages/${decisionStageId}`,
    [decisionStageId, eventId],
  )

  const { onSuccess } = useIndividualVoteCheck({
    successText: t('success.text'),
    successTitle: t('success.title'),
    queriesToInvalidate,
    linkTo,
  })

  const onDeclineError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('error'),
    })
  }, [showMessageBox, t])

  const onDeclineConfirm = useCallback(() => {
    setIsDeclineMessageBoxOpen(false)
    editDecisionStageVote(
      {
        decisionStageId,
        eventId,
        id: decisionStageVoterId,
        voteStatusCode: decisionStageVoteOptions.decline,
        declineReason: declineReason === '' ? undefined : declineReason,
      },
      { onSuccess, onError: onDeclineError },
    )
  }, [
    editDecisionStageVote,
    decisionStageId,
    eventId,
    decisionStageVoterId,
    declineReason,
    onSuccess,
    onDeclineError,
  ])

  const onInputDeclineReason = useCallback(({ target: { value: newDeclineReason } }) => {
    setDeclineReason(newDeclineReason)
  }, [])

  const declineConfirmButton = useMemo(
    () => (
      <DialogPrimaryButton key="decline-button" onClick={onDeclineConfirm}>
        {t('action')}
      </DialogPrimaryButton>
    ),
    [onDeclineConfirm, t],
  )

  const onDecline = useCallback(() => {
    setIsDeclineMessageBoxOpen(true)
  }, [])

  const onCloseDecline = useCallback(() => {
    setIsDeclineMessageBoxOpen(false)
    setDeclineReason('')
  }, [])

  return (
    <>
      <SemanticButton design={SemanticButtonDesign.Error} onClick={onDecline}>
        {t('action')}
      </SemanticButton>
      {isDeclineMessageBoxOpen && (
        <MessageBox
          open={isDeclineMessageBoxOpen}
          type={MessageBoxTypes.Confirm}
          titleText={t('action')}
          actions={[declineConfirmButton, MessageBoxActions.Cancel]}
          onClose={onCloseDecline}
        >
          <FlexBox direction={FlexBoxDirection.Column} className={styles.dialogContent}>
            <div>{t('confirmation.text')}</div>
            <TextArea
              placeholder={t('confirmation.text-area.placeholder')}
              value={declineReason}
              onInput={onInputDeclineReason}
            />
          </FlexBox>
        </MessageBox>
      )}
    </>
  )
}

DecisionStageApprovalVoterDeclineButton.propTypes = {
  eventId: PropTypes.string.isRequired,
  decisionStageId: PropTypes.string.isRequired,
  decisionStageVoterId: PropTypes.string.isRequired,
}

export default DecisionStageApprovalVoterDeclineButton
