import { ComboBox, ComboBoxItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useCountriesForLoadingDropdown from 'hooks/services/business-partners/config/useCountriesForLoadingDropdown'

const CountryBusinessPartnerCreationSelect = ({ id, value, onChange, ...props }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.loading-dropdown' })
  const { data = [], isLoading, isError } = useCountriesForLoadingDropdown()

  const options = useMemo(() => {
    switch (true) {
      case isLoading:
        return [<ComboBoxItem key="loading" data-value="loading" text={t('loading')} />]
      case isError:
        return [<ComboBoxItem key="error" data-value="error" text={t('error')} />]
      default:
        return data.map(({ code, displayName }) => (
          <ComboBoxItem key={code} data-value={code} text={displayName} />
        ))
    }
  }, [isLoading, isError, data, t])

  const displayValue = useMemo(() => data.find(({ code }) => code === value), [data, value])

  return (
    <ComboBox
      {...props}
      id={id}
      value={displayValue?.displayName}
      onSelectionChange={(event) => {
        onChange(event.detail.item.dataset.value)
      }}
      onFocus={(event) => event.stopPropagation()}
    >
      <ComboBoxItem key="placeholder" data-value="" />
      {options}
    </ComboBox>
  )
}

CountryBusinessPartnerCreationSelect.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CountryBusinessPartnerCreationSelect
