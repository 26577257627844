import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

const CustomerOptionsSelect = ({ handleOnChange, selectedKey, options }) => {
  const optionsArray = Object.entries(options)

  return (
    <Select
      onChange={(event) => {
        const newSelectedKey = event.detail.selectedOption.dataset.id
        handleOnChange({ value: newSelectedKey, isValid: true })
      }}
    >
      {/*TODO: CWP-8932: Implement correct empty/null option*/}
      <Option />
      {optionsArray.map(([key, displayName], index) => (
        <Option key={`key-${key}-index-${index}`} data-id={key} selected={key === selectedKey}>
          {displayName}
        </Option>
      ))}
    </Select>
  )
}

CustomerOptionsSelect.propTypes = {
  selectedKey: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired,
  // the options object has keys that are provided by the backend and can be everything
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object.isRequired,
}

export default CustomerOptionsSelect
