import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  ToolbarSeparator,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/arrears/current-arrears/CurrentArrearsTable.module.css'
import CurrentArrearsTableStartApprovalProcessDialog from 'components/domains/business-partners/tile/arrears/current-arrears/CurrentArrearsTableStartApprovalProcessDialog'
import useCurrentArrearsTableColumns from 'components/domains/business-partners/tile/arrears/current-arrears/useCurrentArrearsTableColumns'
import useCurrentArrearsTableData from 'components/domains/business-partners/tile/arrears/current-arrears/useCurrentArrearsTableData'
import useStartApprovalProcessButton from 'components/domains/business-partners/tile/arrears/current-arrears/useStartApprovalProcessButton'
import AnalyticalTableNoDataComponent from 'components/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import { useCollapseExpandTableActions } from 'components/ui/tables/analytical/useCollapseExpandTableActions'

const CurrentArrearsTable = ({ arrearsCount }) => {
  const tableRef = useRef(null)

  const { t: tBusinessPartnerArrearsTable } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.current-arrears.table',
  })

  const tableColumns = useCurrentArrearsTableColumns()

  const { data: tableData } = useCurrentArrearsTableData()

  const {
    additionalTableActions: collapseExpandActions = [],
    expandOrCollapseSubRows,
    handleOnRowExpand,
  } = useCollapseExpandTableActions(tableRef)

  useEffect(() => {
    expandOrCollapseSubRows(true)
  }, [tableRef?.current?.data, expandOrCollapseSubRows])

  const { showStartApprovalButton } = useStartApprovalProcessButton()
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false)

  const additionalTableActions = [
    ...(showStartApprovalButton
      ? [
          <FlexBox
            key={'start-approval-button-and-separator'}
            alignItems={FlexBoxAlignItems.Center}
          >
            <Button
              id={'start-approval-process-button'}
              key="arrears-table-start-approval-process"
              design={ButtonDesign.Transparent}
              className={styles.startApprovalButton}
              onClick={() => setIsApprovalModalOpen(true)}
            >
              {tBusinessPartnerArrearsTable('start-approval-process')}
            </Button>
            <ToolbarSeparator key="toobar-separator" />
          </FlexBox>,
        ]
      : []),
    ...collapseExpandActions,
  ]

  return (
    <>
      <AnalyticalTableWithToolbar
        ref={tableRef}
        title={tBusinessPartnerArrearsTable('title')}
        id="businessPartnerArrearsTable"
        minRows={0}
        columns={tableColumns}
        data={tableData ?? []}
        // high number of visible rows fixes re-rendering of height on expand
        overscanCountHorizontal={999}
        visibleRows={999}
        // rowHeight needs to be set to a non-empty string to not show an empty row at the end
        rowHeight={'individual'}
        headerRowHeight={32}
        NoDataComponent={() => (
          <AnalyticalTableNoDataComponent
            tableId={'current-arrears-table'}
            isLoading={false}
            isError={false}
          />
        )}
        additionalActions={additionalTableActions}
        onRowExpandChange={(event) => handleOnRowExpand(event.detail.row)}
        nrOfEntries={arrearsCount}
        filterable={false}
        sortable={false}
        withRowHighlight={true}
        loading={false}
        disableColumnPopover
        showNrOfEntries
        isTreeTable
      />
      <CurrentArrearsTableStartApprovalProcessDialog
        isApprovalModalOpen={isApprovalModalOpen}
        setIsApprovalModalOpen={setIsApprovalModalOpen}
      />
    </>
  )
}
CurrentArrearsTable.propTypes = {
  arrearsCount: PropTypes.number.isRequired,
}

export default CurrentArrearsTable
