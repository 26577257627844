import PropTypes from 'prop-types'
import { useContext } from 'react'
import { decisionPaperTileTypes } from 'api/decision-paper/decisionPaperApi'
import { getCurrentTileVersionForTileCode } from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/availableTiles'
import useReduxTileLoaderEffects from 'hooks/services/business-events-and-tasks/decision-papers/redux-loader/useReduxTileLoaderEffects'
import useManualTileLoader from 'hooks/services/business-events-and-tasks/decision-papers/tiles/manual/useManualTileLoader'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionPaperReduxStateManualTileLiveLoader = ({
  id: tileId,
  code: tileCode,
  viewConfig,
  isEditableInMinorVersion,
}) => {
  const event = useContext(BusinessEventsAndTasksContext).event
  const currentTileVersion = getCurrentTileVersionForTileCode(tileCode)

  const { isLoading, isError, data, error } = useManualTileLoader({
    eventId: event.id,
    tileId,
  })

  useReduxTileLoaderEffects({
    tileId,
    isDefaultExpanded: viewConfig.isDefaultExpanded,
    isLoading,
    isError,
    data,
    error,
    type: decisionPaperTileTypes.manual,
    isEditableInMinorVersion,
    tileVersion: currentTileVersion,
  })
}

DecisionPaperReduxStateManualTileLiveLoader.propTypes = {
  id: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  viewConfig: PropTypes.shape({
    isDefaultExpanded: PropTypes.bool.isRequired,
  }).isRequired,
}

export default DecisionPaperReduxStateManualTileLiveLoader
