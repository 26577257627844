import {
  Menu,
  MenuItem,
  FlexBox,
  BusyIndicator,
  BusyIndicatorSize,
  FlexBoxDirection,
  FlexBoxJustifyContent,
} from '@fioneer/ui5-webcomponents-react'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import LoadingButton from 'components/ui/button/LoadingButton'
import useCurrentPropertiesKpis from 'hooks/services/properties/kpis/useCurrentPropertiesKpis'
import usePropertyRentRollCurrencyButtonCurrencies from 'hooks/services/properties/rent-roll/usePropertyRentRollCurrencyButtonCurrencies'
import { setSelectedCommonCurrency } from 'redux/slices/properties/commonCurrencySlice'
import { PropertyContext } from 'routes/properties/PropertyContext'

function useDefaultCurrency(property) {
  // Without the currency provided, the property rent roll KPI endpoint provides KPIs based on the rental units'
  // currency if unambiguous or the property (HQ) currency otherwise. This logic should also determine the default for
  // the currency button.
  const { isLoading, isError, data } = useCurrentPropertiesKpis([property.uuid])

  return {
    isLoading,
    isError,
    data:
      data?.[0]?.annualizedCurrentRent?.currency || data?.[0]?.annualizedContractedRent?.currency,
  }
}

const PropertyRentRollCurrencyButton = () => {
  const dispatch = useDispatch()

  const refMenu = useRef()
  const refButton = useRef()

  const { property } = useContext(PropertyContext)

  const [selectedRentRollCurrency, setSelectedRentRollCurrency] = useState()
  const [isSelectedByUser, setIsSelectedByUser] = useState(false)

  const {
    isLoading: isMenuCurrenciesLoading,
    isError: isMenuCurrenciesError,
    data: menuCurrencies,
  } = usePropertyRentRollCurrencyButtonCurrencies(property.uuid)

  const {
    isLoading: isDefaultCurrencyLoading,
    isError: isDefaultCurrencyError,
    data: defaultCurrency,
  } = useDefaultCurrency(property)

  const menuItems = useMemo(
    () =>
      menuCurrencies?.map((currency) => (
        <MenuItem
          key={currency}
          id={`rent-roll-currency-button-menu-item-${currency}`}
          style={{ width: '100%' }}
          text={currency}
        />
      )),
    [menuCurrencies],
  )

  const handleShowMenu = () => {
    refMenu.current?.showAt(refButton.current)
  }
  const handleMenuItemClick = (e) => {
    setIsSelectedByUser(true)
    setSelectedRentRollCurrency(e?.detail?.text)
  }

  useEffect(() => {
    if (selectedRentRollCurrency) {
      dispatch(setSelectedCommonCurrency(selectedRentRollCurrency))
    }
  }, [dispatch, selectedRentRollCurrency])

  useEffect(() => {
    if (!isSelectedByUser && defaultCurrency) {
      setSelectedRentRollCurrency(defaultCurrency)
    }
  }, [isSelectedByUser, defaultCurrency])

  /**
   * Render busy indicator when loading
   */
  if (isMenuCurrenciesLoading || isDefaultCurrencyLoading) {
    return (
      <FlexBox direction={FlexBoxDirection.Column} justifyContent={FlexBoxJustifyContent.Center}>
        <BusyIndicator size={BusyIndicatorSize.Small} active delay={0} />
      </FlexBox>
    )
  }

  // check if should display default currency
  const defaultCurrencyNotSet = !defaultCurrency && !isDefaultCurrencyError && isMenuCurrenciesError

  return (
    <LoadingButton
      id="rent-roll-currency-button"
      ref={refButton}
      isError={defaultCurrencyNotSet || isDefaultCurrencyError}
      onClick={handleShowMenu}
      renderContent={() => (
        <>
          {selectedRentRollCurrency}
          <Menu id="rent-roll-currency-button-menu" ref={refMenu} onItemClick={handleMenuItemClick}>
            {menuItems}
          </Menu>
        </>
      )}
      icon="slim-arrow-down"
      iconEnd
    />
  )
}
export default PropertyRentRollCurrencyButton
