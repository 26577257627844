import { DEFAULT_DISPLAY_ROW_HEIGHT } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/constants'

const createSectionHeaderRow = (t, i18nSubSectionTypeCode) => ({
  partyRoleTranche: {
    value: t(`pages.deals.syndicationStructure-table.subSection.${i18nSubSectionTypeCode}-label`),
    isBold: true,
  },
  isSectionHeader: true,
  subSectionTypeCode: i18nSubSectionTypeCode,
  rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT,
})

export default createSectionHeaderRow
