import { MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'
import { stringTemplate } from 'utils/stringTemplate'

const VisualRulesDialog = ({ isOpen, onClose = () => {}, businessPartnerId, linkTemplate }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.business-partner-ratings' })

  const link = stringTemplate(linkTemplate, { businessPartnerId })

  const openInNewTab = (url) => {
    window.open(url, '_blank')
  }

  const handleConfirmation = () => {
    onClose()
    openInNewTab(link)
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <>
      {createPortal(
        <ConfirmationDialog
          confirmationTitle={t('open-in-vr-title')}
          handleConfirmation={handleConfirmation}
          handleCancel={handleCancel}
          isOpen={isOpen}
          confirmationMessage={t('open-in-vr-text')}
          confirmButtonText={t('button-confirm')}
          messageBoxType={MessageBoxTypes.Information}
        />,
        document.body,
      )}
    </>
  )
}

VisualRulesDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  businessPartnerId: PropTypes.string,
  linkTemplate: PropTypes.string,
}

export default VisualRulesDialog
