import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionStageTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/decision-overview/shared/decisionProcessApi'

const useDecisionStageName = ({ name, decisionStageType }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.decision-process.decision-type',
  })

  return useMemo(() => {
    switch (decisionStageType) {
      case decisionStageTypes.attendance:
        return t('attendance.name', { name })
      case decisionStageTypes.circulation:
        return t('circulation.name', { name })
      default:
        return name
    }
  }, [name, decisionStageType, t])
}

export default useDecisionStageName
