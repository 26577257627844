import { isEmpty, sumBy } from 'lodash'
import { useMemo } from 'react'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useMultipleDealProperties from 'hooks/services/deals/properties/useMultipleDealProperties'
import { useTegovaRating } from 'hooks/services/properties/rating/useTegovaRating'
import { useMultiPropertyViewByPropertyUuidsArrays } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuidsArrays'
import useMultiArrayPropertyValuations from 'hooks/services/properties/valuations/useMultiArrayPropertyValuations'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const getRatingClasses = (tegovaRating) => ({
  market: tegovaRating?.market?.market,
  location: tegovaRating?.location?.location,
  property: tegovaRating?.property?.property,
  cashflow: tegovaRating?.cashflow?.cashflow,
  relett: tegovaRating?.cashflow?.lettingProspects,
  thirdParty: tegovaRating?.cashflow?.usabilityByThirdParties,
  total: tegovaRating?.total,
})

const checkAllRatingsZero = (tegovaRating) => Object.values(tegovaRating).every((rating) => !rating)

const getWeightedRating = (rating, marketValue, marketValueSum) => {
  if (!rating || !marketValue || !marketValueSum) {
    return 0
  }
  return (marketValue / marketValueSum) * rating
}

const getRatings = (tegovaRatingResult, valuations, isMultiProperty) => {
  if (!isMultiProperty) {
    return getRatingClasses(tegovaRatingResult?.[0])
  }
  const weightedTegovaRatingsList = []
  const tegovaRatingNotEmptyList = []

  const getMarketValue = (singleTegovaRating) => {
    const propertyUuid = singleTegovaRating.propertyUuid
    return valuations?.[propertyUuid]?.MARKET_VALUE?.value_amount?.number
  }

  let marketValueSum = 0

  tegovaRatingResult.map((tegovaRating) => {
    if (!isEmpty(tegovaRating)) {
      const tegovaRatingClass = getRatingClasses(tegovaRating)
      if (!checkAllRatingsZero(tegovaRatingClass)) {
        const marketValue = getMarketValue(tegovaRating)
        marketValueSum = marketValue ? marketValueSum + marketValue : marketValueSum
        tegovaRatingNotEmptyList.push(tegovaRating)
      }
    }
  })
  if (isEmpty(tegovaRatingNotEmptyList)) {
    return undefined
  }

  tegovaRatingNotEmptyList.forEach((tegovaRating) => {
    const singleWeightedTegovaRating = []
    const ratingClass = getRatingClasses(tegovaRating)
    const marketValue = getMarketValue(tegovaRating)

    Object.entries(ratingClass).forEach(([ratingLabel, rating]) => {
      const weightedRating = getWeightedRating(rating, marketValue, marketValueSum)

      singleWeightedTegovaRating.push({
        [ratingLabel]: parseFloat(weightedRating),
      })
    })
    weightedTegovaRatingsList.push(...singleWeightedTegovaRating)
  })

  const getWeightedRatings = (ratingClass) => sumBy(weightedTegovaRatingsList, ratingClass)

  return {
    market: getWeightedRatings('market'),
    location: getWeightedRatings('location'),
    property: getWeightedRatings('property'),
    cashflow: getWeightedRatings('cashflow'),
    relett: getWeightedRatings('relett'),
    thirdParty: getWeightedRatings('thirdParty'),
    total: getWeightedRatings('total'),
  }
}

const useMultipleFactSheetTegovaRatings = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { dealsData, dealUuids } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  // get an array of property uuids for each deal
  const {
    data: multipleDealPropertiesData,
    isLoading: isMultipleDealPropertiesLoading,
    isError: isMultipleDealPropertiesError,
    error: multipleDealPropertiesError,
  } = useCombinedQueryResults(
    useMultipleDealProperties({
      dealUuids,
    }) ?? {},
  )

  const isFollowUpRequestEnabled = useMemo(
    () =>
      !isMultipleDealPropertiesLoading &&
      !isMultipleDealPropertiesError &&
      multipleDealPropertiesData &&
      multipleDealPropertiesData.some((property) => property.dealProperties?.length > 0),
    [isMultipleDealPropertiesError, isMultipleDealPropertiesLoading, multipleDealPropertiesData],
  )

  // create an array of arrays of property uuids related to a deal id
  const multiplePropertyUuidLists = useMemo(
    () =>
      isFollowUpRequestEnabled
        ? multipleDealPropertiesData?.map((dealProperty) =>
            dealProperty.dealProperties.map((property) => property.propertyUuid),
          )
        : [],
    [isFollowUpRequestEnabled, multipleDealPropertiesData],
  )

  const flatMultiplePropertyUuidLists = multiplePropertyUuidLists?.flat()

  const {
    data: flatTegovaRatingData,
    isLoading: isTegovaRatingLoading,
    isError: isTegovaRatingError,
  } = useTegovaRating(flatMultiplePropertyUuidLists, {
    enabled: isFollowUpRequestEnabled,
  })

  const multipleTegovaRatingData = multiplePropertyUuidLists?.map((propertyUuidList) =>
    propertyUuidList?.flatMap((propertyUuid) =>
      flatTegovaRatingData?.tegovaRatings?.filter(
        (tegovaRating) => tegovaRating?.propertyUuid === propertyUuid,
      ),
    ),
  )

  const {
    data: multiArrayPropertyValuationsData = [],
    isLoading: isMultiArrayPropertyValuationsLoading,
    isError: isMultiArrayPropertyValuationsError,
  } = useMultiArrayPropertyValuations({
    propertyUuidsArrays: multiplePropertyUuidLists,
    includeAggregates: ['SUM'],
    preferredCurrency: undefined,
    options: {
      enabled: isFollowUpRequestEnabled,
    },
  })

  const multipleWeightedTegovaRating = useMemo(
    () =>
      multipleTegovaRatingData.map((tegovaRatingData, index) =>
        getRatings(
          tegovaRatingData,
          multiArrayPropertyValuationsData?.[index]?.data?.valuations,
          multiplePropertyUuidLists[index]?.length > 1,
        ),
      ),
    [multiArrayPropertyValuationsData, multiplePropertyUuidLists, multipleTegovaRatingData],
  )

  const multipleEmptyTegovaRating = multipleWeightedTegovaRating.map((weightedTegovaRating) =>
    weightedTegovaRating ? checkAllRatingsZero(weightedTegovaRating) : true,
  )

  // array version of useMultiPropertyViewByPropertyUuids:
  // in case of multiple properties per array, fetch also the portfolio uuid used in the source link
  const {
    data: multiplePropertyViewByPropertyUuidsArraysData,
    isLoading: isMultiPropertyViewByPropertyUuidsArraysLoading,
    isError: isMultiPropertyViewByPropertyUuidsArraysError,
  } = useMultiPropertyViewByPropertyUuidsArrays({
    propertyUuidsArrays: multiplePropertyUuidLists,
    options: { enabled: isFollowUpRequestEnabled },
  })

  const sourcePaths = useMemo(
    () =>
      dealUuids?.map((dealUuid, index) => {
        const propertiesArrayData = multipleDealPropertiesData?.[index]?.dealProperties
        const multiPropertiesArrayViewUuid =
          multiplePropertyViewByPropertyUuidsArraysData?.[index]?.data?.uuid
        return propertiesArrayData?.length > 1
          ? `/properties/portfolio/overview?portfolio-view-id=${multiPropertiesArrayViewUuid}`
          : `/properties/${propertiesArrayData?.[0]?.propertyUuid}/general-information#rating-tab`
      }),
    [dealUuids, multiplePropertyViewByPropertyUuidsArraysData, multipleDealPropertiesData],
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isAnnualReviewDealsLoading,
      isMultipleDealPropertiesLoading,
      isTegovaRatingLoading && isFollowUpRequestEnabled,
      isMultiArrayPropertyValuationsLoading,
      isMultiPropertyViewByPropertyUuidsArraysLoading,
    ],
    errorValues: [
      isAnnualReviewDealsError,
      isMultipleDealPropertiesError,
      isTegovaRatingError,
      isMultiArrayPropertyValuationsError,
      isMultiPropertyViewByPropertyUuidsArraysError,
    ],
    errorDetails: [annualReviewDealsError, multipleDealPropertiesError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        multipleWeightedTegovaRating,
        multipleEmptyTegovaRating,
        noProperties: !isFollowUpRequestEnabled,
        sourcePaths,
        sourceRender: { path: `${sourcePaths[0]}` },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealsData,
    multipleWeightedTegovaRating,
    multipleEmptyTegovaRating,
    isFollowUpRequestEnabled,
    sourcePaths,
    error,
  ])
}
export default useMultipleFactSheetTegovaRatings
