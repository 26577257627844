import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useContext, useRef, useState } from 'react'
import 'components/ui/card/Card.css'
import 'components/domains/properties/PropertyCards.css'
import { useTranslation } from 'react-i18next'
import PropertyInfoCardOverwriteTypeDialog from 'components/domains/properties/general-information/info/PropertyInfoCardOverwriteTypeDialog'
import DisplayAndEditCard from 'components/ui/card/DisplayAndEditCard'
import { hideOptions } from 'components/ui/card/DisplayCardView'
import editComponentTypes from 'components/ui/card/editComponentTypes'
import { useAreaMeasurementUnitFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'
import { usePropertyTypeUsageTypeAllowedCombinations } from 'hooks/services/properties/usePropertyTypeUsageTypeAllowedCombinations'
import { useTypeCodesMinor } from 'hooks/services/properties/useTypeCodesMinor'
import { useTypecodes } from 'hooks/services/properties/useTypecodes'
import { useUpdatePropertyDetails } from 'hooks/services/properties/useUpdatePropertyDetails'
import { useUtilizationCodes } from 'hooks/services/properties/useUtilizationCodes'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyInfoCard = ({ allowUserToEdit = true }) => {
  const {
    property: {
      description: propertyName,
      type_code: propertyType,
      type_name: propertyTypeDisplayName,
      area_measure_unit_code: propertyUom,
      currency_code: propertyCurrency,
      usage: propertyUsage,
      uuid: propertyUuid,
      system_administrative_data: propertySystemAdministrativeData,
      change_request_exist_indicator: changeRequestExistIndicator,
      allowed_operations,
    },
  } = useContext(PropertyContext)

  const allowedOperations = allowed_operations?.allowed_operations ?? []
  const isAllowedMasterDataEdit =
    allowUserToEdit &&
    !changeRequestExistIndicator &&
    allowedOperations.includes('PropertyMasterData_Update')

  const { t } = useTranslation()
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()
  const updateProperty = useUpdatePropertyDetails({
    onSuccess: () => {
      queryClient.invalidateQueries(['properties'])
      showToast({ children: t('toast.changes-saved') })
    },
  })
  const { format: formatDate } = useShortDateFormatter()
  const typeCodes = useTypecodes()
  const typeCodesMinor = useTypeCodesMinor()
  const typeCodesMinorTypeCodesAllowedCombinations = usePropertyTypeUsageTypeAllowedCombinations()

  const utilizationCodes = useUtilizationCodes()
  const formatAreaUnit = useAreaMeasurementUnitFormatter()

  const getUtilizationName = (utilizationCode) =>
    utilizationCodes?.data?.utilization_codes?.find((element) => element.key === utilizationCode)
      ?.display_name

  const getTypeMinorName = (typeCodeMinor) =>
    typeCodesMinor?.data?.type_codes_minor?.find((element) => element.key === typeCodeMinor)
      ?.display_name

  const [currentPropertyType, setCurrentPropertyType] = useState(propertyType)

  const onEditChanges = (fieldChange) => {
    if (Object.prototype.hasOwnProperty.call(fieldChange, 'propertyType')) {
      setCurrentPropertyType(fieldChange.propertyType)
    }
  }

  const cancelChanges = () => {
    setCurrentPropertyType(propertyType)
  }

  const getPossibleTypeCodesMinor = (typeCode = currentPropertyType) => {
    const allowedMinorTypeCodes =
      typeCodesMinorTypeCodesAllowedCombinations?.data?.allowedCombinations
        ?.filter((combination) => combination.realEstateTypeCode === typeCode)
        ?.map((combination) => combination.realEstateUsageCode)

    return Array.isArray(typeCodesMinor?.data?.type_codes_minor)
      ? [
          '',
          ...typeCodesMinor.data.type_codes_minor.filter((typeCodeMinor) =>
            allowedMinorTypeCodes?.includes(typeCodeMinor.key),
          ),
        ]
      : []
  }

  const propertyDetailValues = {
    propertyTypeMinor: propertyUsage?.usage_code,
    propertyTypeMinorDisplayName: getTypeMinorName(propertyUsage.usage_code),
    propertyUtilization: propertyUsage?.utilization_code,
    propertyUtilizationDisplayName: getUtilizationName(propertyUsage.utilization_code),
    propertyCurrency,
    propertyName,
    propertyType,
    propertyTypeDisplayName,
    propertyUom,
    propertyCreationDate: propertySystemAdministrativeData?.creation_date_time,
  }

  const fieldDefinitions = [
    {
      value: { sectionTitle: t('components.property-info-card.section-title.basic-information') },
      isSectionTitle: true,
      isShownInDisplay: true,
      isShownInEdit: true,
    },
    {
      label: t('components.property-info-card.label.property-name'),
      name: 'propertyName',
      value: propertyDetailValues?.propertyName,
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      label: t('components.property-info-card.label.property-name'),
      name: 'propertyName',
      value: propertyDetailValues?.propertyName,
      isShownInDisplay: false,
      customInfoComponent: null,
      isShownInEdit: true,
      customEditComponent: null,
      editComponentType: editComponentTypes.Input,
      editComponentProps: { type: 'string' },
      isMandatory: true,
      editComponentSelectOptions: null,
    },
    {
      label: t('components.property-info-card.label.property-type'),
      name: 'propertyTypeDisplay',
      value: propertyDetailValues?.propertyTypeDisplayName,
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: true,
      customInfoComponent: null,
      isShownInEdit: false,
      customEditComponent: null,
      editComponentType: null,
      editComponentProps: null,
      isMandatory: true,
      editComponentSelectOptions: null,
    },
    {
      label: t('components.property-info-card.label.property-type'),
      name: 'propertyType',
      value: propertyDetailValues?.propertyType,
      isShownInDisplay: false,
      customInfoComponent: null,
      isShownInEdit: true,
      customEditComponent: null,
      editComponentType: editComponentTypes.Select,
      editComponentProps: {},
      isMandatory: true,
      editComponentSelectOptions:
        !typeCodes.isError && !typeCodes.isLoading ? typeCodes?.data?.typecodes : [],
    },
    {
      label: t('components.property-info-card.label.property-type-minor'),
      name: 'propertyTypeMinorDisplay',
      value: propertyDetailValues?.propertyTypeMinorDisplayName,
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      label: t('components.property-info-card.label.property-type-minor'),
      name: 'propertyTypeMinor',
      value: propertyDetailValues?.propertyTypeMinor,
      isShownInDisplay: false,
      customInfoComponent: null,
      isShownInEdit: true,
      customEditComponent: null,
      editComponentType: editComponentTypes.Select,
      editComponentProps: {},
      isMandatory: false,
      editComponentSelectOptions:
        !typeCodesMinor.isLoading && !typeCodesMinor.isError && getPossibleTypeCodesMinor(),
    },
    {
      label: t('components.property-info-card.label.property-utilization'),
      name: 'propertyUtilizationDisplay',
      value: propertyDetailValues?.propertyUtilizationDisplayName,
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      label: t('components.property-info-card.label.property-utilization'),
      name: 'propertyUtilization',
      value: propertyDetailValues?.propertyUtilization,
      isShownInDisplay: false,
      isShownInEdit: true,
      editComponentType: editComponentTypes.Select,
      isMandatory: false,
      editComponentSelectOptions:
        !utilizationCodes.isLoading &&
        !utilizationCodes.isError &&
        Array.isArray(utilizationCodes?.data?.utilization_codes)
          ? ['', ...utilizationCodes.data.utilization_codes]
          : [],
    },
    {
      label: t('components.property-info-card.label.property-creation-date'),
      name: 'propertyCreationDate',
      value: formatDate(propertyDetailValues?.propertyCreationDate),
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      value: { sectionTitle: t('components.property-info-card.section-title.measurement-units') },
      isSectionTitle: true,
      isShownInDisplay: true,
      isShownInEdit: true,
    },
    {
      label: t('components.property-info-card.label.property-currency'),
      name: 'propertyCurrency',
      value: propertyDetailValues?.propertyCurrency,
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: true,
      customInfoComponent: null,
      isShownInEdit: true,
      customEditComponent: null,
      editComponentType: editComponentTypes.Input,
      editComponentProps: { readonly: true },
      isMandatory: false,
      editComponentSelectOptions: null,
    },
    {
      label: t('components.property-info-card.label.property-uom'),
      name: 'propertyUom',
      value: formatAreaUnit(propertyDetailValues.propertyUom),
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: true,
      customInfoComponent: null,
      isShownInEdit: true,
      customEditComponent: null,
      editComponentType: editComponentTypes.Input,
      editComponentProps: { readonly: true },
      isMandatory: false,
      editComponentSelectOptions: null,
    },
  ]

  const [typeChangeDialogIsOpen, setTypeChangeDialogIsOpen] = useState(false)
  const isHandlingSaveOverwriteValue = useRef(undefined)
  const [changesForBackend, setChangesForBackend] = useState({})

  const onAcceptTypeCodeChange = () => {
    isHandlingSaveOverwriteValue.current = undefined
    updateProperty.mutate({
      property_uuid: propertyUuid,
      property: changesForBackend,
    })
    setTypeChangeDialogIsOpen(false)
    setChangesForBackend({})
  }
  const cancelTypeCodeChange = () => {
    isHandlingSaveOverwriteValue.current = false
    setTypeChangeDialogIsOpen(false)
  }

  const transformChangesForBackend = (changes) => {
    const typeCodeMinor =
      getPossibleTypeCodesMinor(changes.propertyType).filter(
        (possibleCode) => possibleCode.key === changes.propertyTypeMinor,
      ).length > 0
        ? changes.propertyTypeMinor
        : null
    return {
      typeCode: changes.propertyType,
      description: changes.propertyName,
      utilizationCode: changes.propertyUtilization,
      typeCodeMinor,
    }
  }

  const saveChanges = (changes) => {
    const transformedChanges = transformChangesForBackend(changes)
    if (transformedChanges.typeCode !== propertyDetailValues.propertyType) {
      isHandlingSaveOverwriteValue.current = false
      setChangesForBackend(transformedChanges)
      setTypeChangeDialogIsOpen(true)
    } else {
      updateProperty.mutate({
        property_uuid: propertyUuid,
        property: transformedChanges,
      })
    }
  }

  return (
    <>
      <DisplayAndEditCard
        cardHeaderTitle={t('components.property-info-card.title')}
        saveHookIsSuccess={updateProperty.isSuccess}
        saveHookIsError={updateProperty.isError}
        saveHookError={updateProperty.error}
        fieldDefinitions={fieldDefinitions}
        saveChanges={saveChanges}
        onEditChanges={onEditChanges}
        cancelChanges={cancelChanges}
        isEmpty={false}
        isNotAllowed={false}
        isEditable={isAllowedMasterDataEdit}
        isHandlingSaveOverwrite={isHandlingSaveOverwriteValue.current}
      />
      <PropertyInfoCardOverwriteTypeDialog
        isOpen={typeChangeDialogIsOpen}
        onCancel={cancelTypeCodeChange}
        onConfirm={onAcceptTypeCodeChange}
      />
    </>
  )
}

PropertyInfoCard.propTypes = {
  allowUserToEdit: PropTypes.bool,
}

export default PropertyInfoCard
