import {
  FlexBox,
  FlexBoxAlignItems,
  Label,
  SegmentedButton,
  SegmentedButtonItem,
  Toolbar,
  ToolbarDesign,
  ToolbarSeparator,
  ToolbarSpacer,
  ToolbarStyle,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { conditionTypes } from 'api/conditions/conditions'
import { ConditionTablesGeneralPropTypes } from 'components/domains/conditions/overview/ConditionTablePropTypes'
import styles from 'components/domains/conditions/overview/ConditionsTableToolbar.module.css'
import ConditionsTableToolbarFilterDialog from 'components/domains/conditions/overview/ConditionsTableToolbarFilterDialog'
import ExternalConditionsToolbarButtons from 'components/domains/conditions/overview/buttons/external-conditions/ExternalConditionsToolbarButtons'
import ConditionsExportButton from 'components/domains/conditions/overview/buttons/generic/ConditionsExportButton'
import InternalConditionsToolbarButtons from 'components/domains/conditions/overview/buttons/internal-conditions/InternalConditionsToolbarButtons'
import FilterInfoBar from 'components/ui/tables/toolbar/FilterInfoBar'
import tableToolBarStyles from 'components/ui/tables/toolbar/TablesToolbar.module.css'
import ToolbarColumnSelection from 'components/ui/tables/toolbar/ToolbarColumnSelection'
import ToolbarSearching from 'components/ui/tables/toolbar/ToolbarSearching'
import ToolbarSorting from 'components/ui/tables/toolbar/ToolbarSorting'
import { columnSelectionPropTypes } from 'components/ui/tables/toolbar/propTypes'

const ConditionsTableToolbar = ({
  switchActiveConditionSelection,
  totalNumberOfInternalConditions,
  totalNumberOfExternalConditions,
  currentlySelectedConditionType,
  columnSelection,
  setColumnSelection,
  filters,
  setFilters,
  searchParam,
  onUpdateSearchParam,
  sorting,
  mappedBackendFilters,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.conditions.table.toolbar' })
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false)
  const isAnyRowInEditMode = useSelector(
    (state) => !!state.conditions.conditionsTable.editedRow.conditionId,
  )

  return (
    <>
      <Toolbar
        design={ToolbarDesign.Auto}
        toolbarStyle={ToolbarStyle.Clear}
        className={tableToolBarStyles.toolbar}
      >
        <Label className={tableToolBarStyles.titleLabel}>{t('title')}</Label>
        <ToolbarSeparator />
        <SegmentedButton
          className={styles.internalExternalButton}
          onSelectionChange={switchActiveConditionSelection}
        >
          <SegmentedButtonItem
            className={styles.internalExternalButtonItem}
            pressed={currentlySelectedConditionType === conditionTypes.external}
            disabled={isAnyRowInEditMode}
          >
            {t('buttons.external', { numberOfConditions: totalNumberOfExternalConditions })}
          </SegmentedButtonItem>
          <SegmentedButtonItem
            className={styles.internalExternalButtonItem}
            pressed={currentlySelectedConditionType === conditionTypes.internal}
            disabled={isAnyRowInEditMode}
          >
            {t('buttons.internal', { numberOfConditions: totalNumberOfInternalConditions })}
          </SegmentedButtonItem>
        </SegmentedButton>
        <ToolbarSpacer />
        <ToolbarSearching
          searching={{ searchParam, onUpdateSearchParam }}
          disabled={isAnyRowInEditMode}
        />
        {currentlySelectedConditionType === conditionTypes.external && (
          <ExternalConditionsToolbarButtons disabled={isAnyRowInEditMode} />
        )}
        {currentlySelectedConditionType === conditionTypes.internal && (
          <InternalConditionsToolbarButtons disabled={isAnyRowInEditMode} />
        )}
        <ToolbarSeparator />
        <FlexBox className={styles.flexToolbarItems} alignItems={FlexBoxAlignItems.Start}>
          <ToolbarSorting sorting={sorting} disabled={isAnyRowInEditMode} />
          <ConditionsTableToolbarFilterDialog
            isOpen={isFilterDialogOpen}
            disabled={isAnyRowInEditMode}
            setIsOpen={setIsFilterDialogOpen}
            filters={filters}
            setFilters={setFilters}
          />
          <ToolbarColumnSelection
            columnSelection={columnSelection}
            setColumnSelection={setColumnSelection}
            disabled={isAnyRowInEditMode}
          />
          <ConditionsExportButton
            conditionType={currentlySelectedConditionType}
            mappedBackendFilters={mappedBackendFilters}
            sortBy={sorting.sortBy}
            orderBy={sorting.isSortingAscending ? 'asc' : 'desc'}
          />
        </FlexBox>
      </Toolbar>
      <FilterInfoBar setIsFilterDialogOpen={setIsFilterDialogOpen} filters={filters} />
    </>
  )
}

ConditionsTableToolbar.propTypes = {
  ...ConditionTablesGeneralPropTypes,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      columnKey: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
    }),
  ).isRequired,
  setFilters: PropTypes.func.isRequired,
  searchParam: PropTypes.string,
  onUpdateSearchParam: PropTypes.func.isRequired,
  sorting: PropTypes.any.isRequired,
  columnSelection: columnSelectionPropTypes.isRequired,
  setColumnSelection: PropTypes.func.isRequired,
  mappedBackendFilters: PropTypes.object,
}

export default ConditionsTableToolbar
