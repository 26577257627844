import { FlexBox, FlexBoxDirection, Label, Text, TextArea } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/input/TextAreaWithMaxCharacterRestriction.module.css'

const TextAreaWithMaxCharacterRestriction = forwardRef(
  ({ value, maxLength, onInput = (_) => {}, label, readOnly, ...props }, ref) => {
    const { t } = useTranslation()
    const onInputInternal = (evt) => onInput(evt?.target?.value, evt)
    return (
      <FlexBox direction={FlexBoxDirection.Column}>
        {label && <Label>{label}</Label>}
        <TextArea
          value={value}
          className={styles.charactersTextArea}
          maxlength={maxLength}
          onInput={onInputInternal}
          ref={ref}
          readonly={readOnly}
          {...props}
        />
        <Text className={styles.charactersRemaining}>
          {t('components.ui.input.text-area.characters-remaining', {
            charactersRemaining: maxLength - value.length,
          })}
        </Text>
      </FlexBox>
    )
  },
)

TextAreaWithMaxCharacterRestriction.displayName = 'TextAreaWithMaxCharacterRestriction'
TextAreaWithMaxCharacterRestriction.defaultProps = {
  value: '',
  readOnly: false,
}
TextAreaWithMaxCharacterRestriction.propTypes = {
  value: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onInput: PropTypes.func,
  label: PropTypes.string,
}

export default TextAreaWithMaxCharacterRestriction
