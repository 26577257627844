import { Modals, StandardListItem, Text } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useCreateNewMinorVersion from 'hooks/services/business-events-and-tasks/decision-papers/useCreateNewMinorVersion'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionPaperStartMinorVersionListItem = ({
  isCreatingVersionLoading,
  setIsCreatingVersionLoading,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper.minor-version',
  })

  const {
    event: { id: eventId },
  } = useContext(BusinessEventsAndTasksContext)

  const [queryParams, setQueryParams] = useSearchParams()

  const queryClient = useQueryClient()

  const showToast = Modals.useShowToast()
  const showMessageBox = useShowMessageBox()
  const { mutate: createMinorVersion } = useCreateNewMinorVersion()

  const handleSuccess = useCallback(() => {
    showToast({
      children: t('create.success.toast-message'),
    })
    setIsCreatingVersionLoading(false)
    queryParams.delete('version')
    setQueryParams(queryParams)
    queryClient.invalidateQueries(['events', eventId])
  }, [showToast, t, setIsCreatingVersionLoading, queryParams, setQueryParams, queryClient, eventId])

  const handleError = useCallback(() => {
    setIsCreatingVersionLoading(false)
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('create.error.description'),
    })
  }, [setIsCreatingVersionLoading, showMessageBox, t])

  const handleButtonClick = useCallback(() => {
    setIsCreatingVersionLoading(true)
    createMinorVersion({ eventId }, { onSuccess: handleSuccess, onError: handleError })
  }, [setIsCreatingVersionLoading, createMinorVersion, eventId, handleSuccess, handleError])

  return (
    <>
      {/* passing 'disabled' as property to StandardListItem does unfortunately not work because it does not rerender itself when 
              the variable changes, therefore this workaround is needed */}
      {!isCreatingVersionLoading && (
        <StandardListItem onClick={handleButtonClick}>
          <Text className={'sapUiSmallMargin'}>{t('button.start-version')}</Text>
        </StandardListItem>
      )}
      {isCreatingVersionLoading && (
        <StandardListItem disabled={true}>
          <Text className={'sapUiSmallMargin'}>{t('button.start-version')}</Text>
        </StandardListItem>
      )}
    </>
  )
}

DecisionPaperStartMinorVersionListItem.propTypes = {
  isCreatingVersionLoading: PropTypes.bool.isRequired,
  setIsCreatingVersionLoading: PropTypes.func.isRequired,
}

export default DecisionPaperStartMinorVersionListItem
