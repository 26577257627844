import { lazy, Suspense, useContext } from 'react'
import LazyLoadingFallbackCard from 'components/ui/card/LazyLoadingFallbackCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import lazyRetry from 'lazyRetry'
import { DealContext } from 'routes/deals/DealContext'

const DealOverviewCollateralsCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealOverviewCollateralsCard" */ 'components/domains/deals/overview/collaterals/DealOverviewCollateralsCard.jsx'
      ),
    'DealOverviewCollateralsCard',
  ),
)

const DealOverviewInformationCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealOverviewInformationCard" */ 'components/domains/deals/overview/DealOverviewInformationCard'
      ),
    'DealOverviewInformationCard',
  ),
)
const DealPropertiesOverviewInformationCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealPropertiesOverviewInformationCard" */ 'components/domains/deals/overview/properties/DealPropertiesOverviewInformationCard'
      ),
    'DealPropertiesOverviewInformationCard',
  ),
)
const DealOverviewCashflowMainScenarioChartCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealOverviewCashflowMainScenarioChartCard" */ 'components/domains/deals/overview/cashflow/DealOverviewCashflowMainScenarioChartCard'
      ),
    'DealOverviewCashflowMainScenarioChartCard',
  ),
)
const CovenantCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "CovenantCard" */ 'components/domains/deals/covenants/covenant-card/CovenantCard'
      ),
    'CovenantCard',
  ),
)
const DealOverviewConditionsCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealOverviewConditionsCard" */ 'components/domains/deals/overview/conditions/DealOverviewConditionsCard'
      ),
    DealOverviewConditionsCard,
  ),
)
const FinancingCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "FinancingCard" */ 'components/domains/deals/financing/financing-card/FinancingCard'
      ),
    'FinancingCard',
  ),
)
const DealChangeOverviewCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealChangeOverviewCard" */ 'components/domains/deals/change-overview/change-overview-card/DealChangeOverviewCard.jsx'
      ),
    'DealChangeOverviewCard',
  ),
)

const DealOverviewSyndicationCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealOverviewSyndicationCard" */ 'components/domains/deals/overview/syndication/DealOverviewSyndicationCard.jsx'
      ),
    'DealOverviewSyndicationCard',
  ),
)

// default selected chart values defined in https://fioneer.atlassian.net/browse/CWP-2909
const PRESELECTED_KPI_CHART = 'DSC_STD'

const DealOverviewSummaryTab = () => {
  const { deal } = useContext(DealContext)
  return (
    <CWPLayout overview>
      <Suspense fallback={<LazyLoadingFallbackCard />}>
        <DealOverviewInformationCard dealUuid={deal.dealUuid} />
      </Suspense>

      <Suspense fallback={<LazyLoadingFallbackCard />}>
        <FinancingCard />
      </Suspense>

      <Suspense fallback={<LazyLoadingFallbackCard />}>
        <DealOverviewCashflowMainScenarioChartCard
          dealUuid={deal.dealUuid}
          defaultKpiCode={PRESELECTED_KPI_CHART}
        />
      </Suspense>

      <Suspense fallback={<LazyLoadingFallbackCard />}>
        <DealPropertiesOverviewInformationCard dealUuid={deal.dealUuid} />
      </Suspense>

      <Suspense fallback={<LazyLoadingFallbackCard />}>
        <DealOverviewCollateralsCard dealUuid={deal.dealUuid} dealDisplayId={deal.displayId} />
      </Suspense>

      <Suspense fallback={<LazyLoadingFallbackCard />}>
        <DealOverviewConditionsCard dealUuid={deal.dealUuid} />
      </Suspense>

      <Suspense fallback={<LazyLoadingFallbackCard />}>
        <DealOverviewSyndicationCard />
      </Suspense>

      <Suspense fallback={<LazyLoadingFallbackCard />}>
        <CovenantCard />
      </Suspense>

      <Suspense fallback={<LazyLoadingFallbackCard />}>
        <DealChangeOverviewCard />
      </Suspense>
    </CWPLayout>
  )
}
export default DealOverviewSummaryTab
