import { lazy, Suspense, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import { useDealWorkingVersionToolbarButtons } from 'components/domains/deals/deal-adjustment/useDealWorkingVersionToolbarButtons'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import LazyLoadingFallbackCard from 'components/ui/card/LazyLoadingFallbackCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import lazyRetry from 'lazyRetry'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'
import DealCollateralInformationCard from 'routes/deals/collaterals/DealCollateralInformationCard'
import CollateralAgreementCreateButton from 'routes/deals/collaterals/creation/CollateralAgreementCreateButton'

const CollateralAgreementsCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "CollateralAgreementsCard" */ 'routes/deals/collaterals/CollateralAgreementsCard'
      ),
    'CollateralAgreementsCard',
  ),
)

const DealCollaterals = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.detail.collaterals' })
  const {
    deal: { dealUuid },
    allowedOperations,
  } = useContext(DealContext)
  const { toggleWorkingVersionButton, manageWorkingVersionButton } =
    useDealWorkingVersionToolbarButtons()

  const breadcrumb = { text: t('title') }

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [dealUuid],
  )

  const isEditAllowedForDeal = allowedOperations?.includes(dealsPermissions.createCag)

  const headerActions = useMemo(
    () =>
      createSegmentedHeaderActions(
        [toggleWorkingVersionButton, manageWorkingVersionButton],
        [
          isEditAllowedForDeal && (
            <CollateralAgreementCreateButton key="collateral-agreement-create-button" />
          ),
        ],
        [<CreateDealEventAction key="create-event-action" />, markFavoriteAction],
      ),
    [
      isEditAllowedForDeal,
      manageWorkingVersionButton,
      markFavoriteAction,
      toggleWorkingVersionButton,
    ],
  )

  return (
    <DealDetailsWrapper childBreadcrumbs={breadcrumb} actions={headerActions}>
      <CWPLayout overview>
        <Suspense fallback={<LazyLoadingFallbackCard />}>
          <CWPLayout.Full>
            <DealCollateralInformationCard
              data-testid="DealCollateralInformationCard"
              dealUuid={dealUuid}
              allowedOperations={allowedOperations}
            />
          </CWPLayout.Full>
        </Suspense>
        <Suspense
          fallback={<LazyLoadingFallbackCard data-testid="CollateralAgreementsCard-fallback" />}
        >
          <CWPLayout.Full>
            <CollateralAgreementsCard />
          </CWPLayout.Full>
        </Suspense>
      </CWPLayout>
    </DealDetailsWrapper>
  )
}

export default DealCollaterals
