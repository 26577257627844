import { FlexBoxJustifyContent } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { UI5_TABLE_CELL_PADDING } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/display-and-edit-table/constants'
import { filterTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/tables/sorted-tables/useFilters'

/**
 * @typedef {import('components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/tables/sorted-tables/SortedTablePropTypes').SortedTableProps} SortedTableProps
 * @typedef {NonNullable<NonNullable<SortedTableProps['columnDefinitions']>[0]>} ColumnDefinition
 */

/**
 * @param {{code: string; shortText: string}[]} [criticalityConfiguration]
 */
const useRiskIndicatorDetailedColumnsDecisionPaper = (criticalityConfiguration) => {
  const enumValues = Object.fromEntries(
    criticalityConfiguration?.map(({ code, shortText } = {}) => [code, shortText]) ?? [],
  )
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.tiles.risk-monitoring.risk-indicators.table.label',
  })

  const columns = useMemo(
    () =>
      /** @satisfies {ColumnDefinition[]} */ ([
        {
          columnKey: 'riskIndicator',
          title: t('risk-indicator'),
          filter: filterTypes.CONTAINS,
          minWidth: 200,
        },
        {
          title: t('reference'),
          columnKey: 'reference',
          filter: filterTypes.CONTAINS,
          minWidth: 200,
        },
        {
          title: t('initialCriticality'),
          columnKey: 'initialCriticality',
          filter: filterTypes.OF_ENUM_TYPE,
          additionalFilterOptions: {
            enumValues,
          },
          minWidth: 120,
        },
        {
          title: t('currentCriticality'),
          columnKey: 'currentCriticality',
          filter: filterTypes.OF_ENUM_TYPE,
          additionalFilterOptions: {
            enumValues,
          },
          minWidth: 120,
        },
        {
          title: t('maximumCriticality'),
          columnKey: 'maximumCriticality',
          filter: filterTypes.OF_ENUM_TYPE,
          additionalFilterOptions: {
            enumValues,
          },
          minWidth: 120,
        },
        {
          title: t('currentValue'),
          columnKey: 'currentValue',
          alignment: FlexBoxJustifyContent.End,
          filter: filterTypes.BETWEEN_NUMBERS,
          minWidth: 150,
        },
        {
          title: t('description'),
          columnKey: 'description',
          filter: filterTypes.CONTAINS,
          minWidth: 200,
        },
      ]),
    [enumValues, t],
  )

  return useMemo(() => {
    let minWidthSum = 0
    return columns.map((column) => {
      // we still add each column's minWidth to the sum,
      // because we need the overall width relative to the table width for the breakpoint
      minWidthSum += (column.minWidth ?? 0) + UI5_TABLE_CELL_PADDING
      return {
        ...column,
        demandPopin: true,
        popinText: column?.title,
        minWidth: minWidthSum,
      }
    })
  }, [columns])
}

export default useRiskIndicatorDetailedColumnsDecisionPaper
