import { TableGrowingMode, AnalyticalTableSelectionMode } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/deal-search/DealSearchTable.module.css'
import {
  useDealSearchColumnDefinitions,
  useDealSearchTableData,
} from 'components/domains/deals/deal-search/useDealSearchTableHelper'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import useMultipleStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useMultipleStaffMemberByObjectIdOrEmail'

const columnKeyToDealDataMap = {
  deal: 'deal_id',
  businessSegment: 'businessSegmentCode',
  dealType: 'dealType',
  originationPlatform: 'originationTeamCode',
  originationTeam: 'originationTeamCode',
  status: 'status',
  createdAt: 'created_at',
  createdBy: 'created_by',
}

export const DealSearchTableMode = {
  SingleSelect: AnalyticalTableSelectionMode.SingleSelect,
  MultiSelect: AnalyticalTableSelectionMode.MultiSelect,
  NavigateOnClick: 'None',
}

export const DealSearchTableColumn = {
  DEAL: 'deal',
  BUSINESSSEGMENT: 'businessSegment',
  DEALTYPE: 'dealType',
  ORIGINATIONPLATFORM: 'originationPlatform',
  ORIGINATIONTEAM: 'originationTeam',
  STATUS: 'status',
  CREATEDAT: 'createdAt',
  CREATEDBY: 'createdBy',
}

/**
 * DealSearchTable
 * - mode: DealSearchTableMode
 * - columnsRestrictedTo: null | Array<DealSearchTableColumn>
 */
const DealSearchTable = ({
  deals,
  mode,
  orderField,
  orderDirection,
  onSortingChanged,
  onSelectionChanged = () => {},
  selectedDealUuids = [],
  lockedSelectedDeals = [],
  columnsRestrictedTo = null,
  additionalToolbarProperties = {},
  showInitPlaceholder = false,
  pagination,
  isAllowedToReadDeal,
  loadMore,
  isError,
  isLoading,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals' })
  const { t: tNoPrefix } = useTranslation()

  const showMoreButton = pagination.limit < pagination.total
  const growingMode = showMoreButton ? TableGrowingMode.Button : TableGrowingMode.None
  const growingButtonText = tNoPrefix('components.ui.tables.sorted-tables.growing-button-text')
  const growingButtonSubtext = `[ ${pagination.limit} / ${pagination.total} ]`

  const { data: staffMembers = {} } = useMultipleStaffMemberByObjectIdOrEmail({
    objectIdOrEmailAddressList: deals.map((deal) => deal.userCreated),
  })

  const noDataText = isError ? t('table.error') : t('table.no-data')

  const customOrderFunction = (tableData) => tableData

  const customOrderCallback = (columnKey, localOrderDirection) => {
    const newOrderField = columnKeyToDealDataMap[columnKey]
    onSortingChanged(newOrderField, localOrderDirection)
  }

  const sortingColumnKey = Object.keys(columnKeyToDealDataMap).find(
    (key) => columnKeyToDealDataMap[key] === orderField,
  )

  const mapTableData = useDealSearchTableData({
    selectedDealUuids,
    lockedSelectedDeals,
    staffMembers,
    isAllowedToReadDeal,
    mode,
    onSelectionChanged,
  })

  const columnDefinitions = useDealSearchColumnDefinitions({ mode, columnsRestrictedTo })
  const tableData = mapTableData(deals)

  const tableProperties = () => ({
    mode,
    stickyColumnHeader: true,
    onSelectionChange: onSelectionChanged,
    busy: isLoading,
  })

  return (
    <div className={`${!isAllowedToReadDeal && styles['default-cursor']}`}>
      <SortedTable
        columnDefinitions={columnDefinitions}
        customOrderCallback={customOrderCallback}
        customOrderFunction={customOrderFunction}
        tableData={tableData}
        toolbarConfig={{
          title: tNoPrefix('pages.deals.subtitle'),
          sorting: {
            columnKey: sortingColumnKey,
            isSortingAscending: orderDirection === 'asc',
          },
          ...additionalToolbarProperties,
        }}
        noDataText={noDataText}
        paginationConfig={{
          growing: growingMode,
          growingButtonText: growingButtonText,
          growingButtonSubtext: growingButtonSubtext,
          totalNumberOfItems: pagination?.total,
          loadMore: loadMore,
        }}
        additionalTableProperties={tableProperties()}
        {...(showInitPlaceholder
          ? { tableData: [], noDataText: t('table.init-placeholder-text'), paginationConfig: {} }
          : {})}
      />
    </div>
  )
}

export default DealSearchTable

DealSearchTable.propTypes = {
  deals: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  additionalToolbarProperties: PropTypes.object,
  mode: PropTypes.oneOf(Object.values(DealSearchTableMode)),
  onSortingChanged: PropTypes.func.isRequired,
  onSelectionChanged: PropTypes.func,
  selectedDealUuids: PropTypes.arrayOf(PropTypes.string),
  lockedSelectedDeals: PropTypes.arrayOf(
    PropTypes.shape({
      dealId: PropTypes.string.isRequired,
      dealName: PropTypes.string.isRequired,
      dealUuid: PropTypes.string.isRequired,
    }),
  ),
  columnsRestrictedTo: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DealSearchTableColumn))),
  orderField: PropTypes.string.isRequired,
  orderDirection: PropTypes.oneOf(['asc', 'desc']),
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
  isAllowedToReadDeal: PropTypes.bool,
  loadMore: PropTypes.func,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  showInitPlaceholder: PropTypes.bool,
}
