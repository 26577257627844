export const DEFAULT_DISPLAY_ROW_HEIGHT = 30
export const DEFAULT_DISPLAY_TEXT_HEIGHT = 16
export const DEFAULT_DISPLAY_HEIGHT_WITHOUT_TEXT =
  DEFAULT_DISPLAY_ROW_HEIGHT - DEFAULT_DISPLAY_TEXT_HEIGHT
export const DEFAULT_DISPLAY_TEXT_GAP = 8
export const DEFAULT_DISPLAY_LINK_HEIGHT = 18

export const DEFAULT_DISPLAY_CELL_PADDING = DEFAULT_DISPLAY_ROW_HEIGHT - DEFAULT_DISPLAY_TEXT_HEIGHT

export const DEFAULT_DISPLAY_ENTITY_TEXT_HEIGHT =
  DEFAULT_DISPLAY_LINK_HEIGHT + DEFAULT_DISPLAY_TEXT_HEIGHT

export const TABLE_HEADER_ROW_HEIGHT = DEFAULT_DISPLAY_ENTITY_TEXT_HEIGHT + DEFAULT_DISPLAY_TEXT_GAP

export const ROW_TYPE_KPI = 'kpi'
export const ROW_TYPE_BUSINESS_PARTNER = 'businessPartner'
export const ROW_TYPE_STATUS = 'status'
export const ROW_TYPE_MONETARY_VALUE = 'monetaryValue'
export const ROW_TYPE_DATE = 'date'
export const ROW_TYPE_COMPLIANCE_WITH_RISK_STRATEGY = 'complianceWithRiskStrategy'
export const ROW_TYPE_LENDERS = 'lenders'
export const ROW_TYPE_SPONSORS = 'sponsors'
export const ROW_TYPE_PERCENTAGE = 'percentage'
export const ROW_TYPE_GREEN_SCORE = 'greenScore'
export const ROW_TYPE_DEAL = 'deal'
export const ROW_TYPE_PROPERTY = 'property'

export const LTV_CODE = 'LTV'
export const DY_CODE = 'DY'
export const ISC_CODE = 'ISC'
export const DSC_CODE = 'DSC'
export const CPLTV_CODE = 'CPLTV'
export const LTC_CODE = 'LTC'

export const PROPERTY_PBB_GREEN_SCORE = 'YY_PBB_GREEN_SCORE'
export const PROPERTY_PBB_GREEN_SCORE_SORT_MAX = 'max'
