import {
  Input,
  MultiComboBox,
  MultiComboBoxItem,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BusinessPartnerSearchMultiInput from 'components/domains/deals/covenants/create-dialog/BusinessPartnerSearchMultiInput'
import styles from 'components/domains/deals/covenants/create-dialog/ReferenceEntitySelect.module.css'
import { ReferenceEntityType } from 'components/ui/data/ReferenceEntityType'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import { useFormMapped } from 'hooks/form/useFormMapped'
import { useFormValidations } from 'hooks/form/useFormValidations'

const ReferenceEntitySelect = ({
  referenceEntityType,
  tranches = [],
  isLoadingTranches = true,
  isErrorTranches = false,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.covenants' })
  const { required, minLength } = useFormValidations()
  const { control } = useFormMapped()

  if (
    referenceEntityType === ReferenceEntityType.TrancheNewBusiness ||
    referenceEntityType === ReferenceEntityType.TrancheExistingBusiness
  ) {
    return (
      <SmallLoadingWrapper
        isLoading={isLoadingTranches}
        isError={isErrorTranches}
        error={t('data-error')}
        renderContent={() => (
          <Controller
            control={control}
            name="referenceEntity.ids"
            rules={{
              validate: {
                required: required(),
                minLength: minLength(1),
              },
            }}
            render={({
              field: { onChange, onBlur, name, value },
              fieldState: { invalid, error },
            }) => (
              <MultiComboBox
                id="tranches-multi-dropdown"
                name={name}
                className={styles.fullWidth}
                valueState={invalid ? ValueState.Error : undefined}
                valueStateMessage={<Text wrapping>{error?.message}</Text>}
                onSelectionChange={async (event) => {
                  await onChange(event?.detail?.items?.map?.((type) => type.dataset.key))
                  await onBlur()
                }}
              >
                {referenceEntityType === ReferenceEntityType.TrancheNewBusiness &&
                  tranches.map(({ trancheId, trancheName, displayId }) => (
                    <MultiComboBoxItem
                      key={trancheId}
                      data-key={trancheId}
                      text={`${trancheName} (${displayId})`}
                      selected={value?.includes(trancheId)}
                    />
                  ))}
                {referenceEntityType === ReferenceEntityType.TrancheExistingBusiness &&
                  tranches.map(({ externalContractId }) => (
                    <MultiComboBoxItem
                      key={externalContractId?.[0]}
                      data-key={externalContractId?.[0]}
                      text={externalContractId?.[0]}
                      selected={value?.includes(externalContractId?.[0])}
                    />
                  ))}
              </MultiComboBox>
            )}
          />
        )}
      />
    )
  }
  if (referenceEntityType === ReferenceEntityType.BusinessPartner) {
    return (
      <Controller
        control={control}
        name="referenceEntity.ids"
        rules={{
          validate: {
            required: required(),
            minLength: minLength(1),
          },
        }}
        render={({ field: { onChange, onBlur, name, value }, fieldState: { invalid, error } }) => (
          <BusinessPartnerSearchMultiInput
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            value={value}
            valueState={invalid ? ValueState.Error : undefined}
            valueStateMessage={<Text wrapping>{error?.message}</Text>}
          />
        )}
      />
    )
  }
  return <Input disabled />
}

ReferenceEntitySelect.propTypes = {
  referenceEntityType: PropTypes.string,
  tranches: PropTypes.arrayOf(
    PropTypes.shape({
      trancheId: PropTypes.string,
      trancheName: PropTypes.string,
      displayId: PropTypes.string,
      externalContractId: PropTypes.string,
    }),
  ),
  isLoadingTranches: PropTypes.bool,
  isErrorTranches: PropTypes.bool,
}

export default ReferenceEntitySelect
