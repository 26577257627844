export const mapChangedRequirementsToBECall = ({
  currentValues,
  changedFields,
  parseDate = (date) => date,
}) => {
  let info
  if (
    changedFields.name ||
    changedFields.description ||
    changedFields.assignee ||
    changedFields.externalAssignee ||
    changedFields.refNumber ||
    changedFields.documentType ||
    changedFields.dueDate
  ) {
    info = {
      name: changedFields.name ? currentValues.name : undefined,
      description: changedFields.description ? currentValues.description : undefined,
      assignee: changedFields.assignee ? currentValues.assignee : undefined,
      externalAssigneeEmail: changedFields.externalAssignee
        ? currentValues.externalAssignee
        : undefined,
      refNumber: changedFields.refNumber ? currentValues.refNumber : undefined,
      documentType: changedFields.documentType ? currentValues.documentType : undefined,
      dueDate: changedFields.dueDate ? parseDate(currentValues.dueDate) : undefined,
    }
  }

  return {
    info,
    status: changedFields.status ? { code: currentValues.status.code } : undefined,
    externalConditionId: changedFields.condition ? currentValues.condition.id : undefined,
  }
}

export const mapRequirementsToBackendCall = ({ currentValues, parseDate = (date) => date }) => {
  const result = { ...currentValues }
  if (currentValues.dueDate) {
    result.dueDate = parseDate(currentValues.dueDate)
  }
  return result
}
