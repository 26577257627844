import { FlexBox } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import EmptyTileContent from 'components/domains/business-events-and-tasks/decision-paper/EmptyTileContent'
import FormattedTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/custom-assessment/latest-custom-assesment/shared/FormattedTextView'

const LatestCustomAssessmentTile = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles',
  })
  const { data } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  if (isEmpty(data?.text)) {
    return <EmptyTileContent title={t('empty.title')} subtitle={t('empty.subtitle')} />
  }

  return (
    <FlexBox>
      <FormattedTextView formattedText={data?.text} />
    </FlexBox>
  )
}

LatestCustomAssessmentTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default LatestCustomAssessmentTile
