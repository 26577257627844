import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useExternalSynchronizeReservationsTrigger = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ dealUuid, reservationUuids }) => {
      await post({
        path: `/pbb-custom-service/deals/${dealUuid}/limit-management/synchronize`,
        body: {
          credit_reservation_uuids: reservationUuids,
        },
      })
    },
    { onSuccess, onError },
  )
}

export default useExternalSynchronizeReservationsTrigger
