import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/conditions/shared/ConditionsTableSingleReferenceView.module.css'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'

const hyphen = '-'

const ConditionsTableSingleReferenceView = ({
  quantity,
  entityType,
  id,
  name,
  link,
  workingVersion,
}) => {
  const { t: tEntityTypes } = useTranslation('translation', { keyPrefix: 'entity-types' })
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.single-reference-view',
  })

  const isWorkingVersion = workingVersion === WorkingVersionType.WORKING_VERSION

  const translatedEntityType = entityType ? tEntityTypes(entityType) : hyphen

  const noneEntity = <Text className={styles.name}>{hyphen}</Text>

  const singleEntityName = isWorkingVersion ? t('working-version', { name }) : name
  const singleEntityLink = isWorkingVersion ? `${link}?working-version=true` : link
  const singleEntity = (
    <>
      <Text wrapping>
        <a
          href={singleEntityLink}
          target="_blank"
          className={[styles.link, 'sapFontBoldFamily', 'ui5-link-root'].join(' ')}
          rel="noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          {`${singleEntityName}`}
        </a>
        <span>{` (${id})`}</span>
      </Text>
    </>
  )

  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      className={styles.outer}
      justifyContent={FlexBoxJustifyContent.Center}
    >
      {!quantity || quantity === 'NONE' ? noneEntity : singleEntity}
      <Text wrapping>
        <span className={styles.referenceEntity}>{t('reference-type')}</span>
        {` ${translatedEntityType}`}
      </Text>
    </FlexBox>
  )
}

ConditionsTableSingleReferenceView.propTypes = {
  entityType: PropTypes.string,
  quantity: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  link: PropTypes.string,
  workingVersion: PropTypes.string,
}

export default ConditionsTableSingleReferenceView
