import {
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { decisionProcessMinutesDecisionStatus } from 'api/decision-process/decisionProcessMinutesApi'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import DecisionStageMinutesApprovalContent from 'components/domains/business-events-and-tasks/decision-stage/minutes/approval/DecisionStageMinutesApprovalContent'
import DecisionStageMinutesApprovalHeader from 'components/domains/business-events-and-tasks/decision-stage/minutes/approval/DecisionStageMinutesApprovalHeader'
import TabPage from 'components/ui/page/TabPage'
import useDecisionStageMinutesApproval from 'hooks/services/business-events-and-tasks/decision-process/stages/minutes/approval/useDecisionStageMinutesApproval'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionStageMinutesApproval = () => {
  const {
    event: { id: eventId },
    allowedOperations,
  } = useContext(BusinessEventsAndTasksContext)
  const { decisionStageId, voterId } = useParams()
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-stage.minutes.approval',
  })

  const hasVoteUpdatePermission = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.updateDecisionStageVote),
    [allowedOperations],
  )
  const {
    isLoading,
    isError,
    data: decisionStageMinutesData,
  } = useDecisionStageMinutesApproval(
    { eventId, decisionStageId, voterId },
    { enabled: hasVoteUpdatePermission },
  )

  const decisionStageMinutesInWrongStatus =
    decisionStageMinutesData?.decisionStageMinutesData.info.decisionStatus ===
      decisionProcessMinutesDecisionStatus.pending ||
    decisionStageMinutesData?.decisionStageMinutesData.info.decisionStatus ===
      decisionProcessMinutesDecisionStatus.inProgressRequestDiscarded

  const userIsNotAVoter = !decisionStageMinutesData?.userVote

  const hideVoterPage =
    decisionStageMinutesInWrongStatus || userIsNotAVoter || !hasVoteUpdatePermission

  const content = useMemo(() => {
    if (isLoading || isError) {
      return <div />
    }
    if (decisionStageMinutesInWrongStatus) {
      return (
        <IllustratedMessage
          titleText={t('wrong-status.title')}
          subtitleText={t('wrong-status.subtitle')}
          name={IllustrationMessageType.NoTasks}
          size={IllustrationMessageSize.Scene}
        />
      )
    }
    if (userIsNotAVoter) {
      return (
        <IllustratedMessage
          titleText={t('wrong-user.title')}
          subtitleText={t('wrong-user.subtitle')}
          name={IllustrationMessageType.NoTasks}
          size={IllustrationMessageSize.Scene}
        />
      )
    }
    return (
      <DecisionStageMinutesApprovalContent
        decisionStage={decisionStageMinutesData.decisionStageData}
        decisionStageMinutes={decisionStageMinutesData.decisionStageMinutesData}
        voterStatusCode={decisionStageMinutesData.userVote?.status.code}
        decisionStageId={decisionStageId}
      />
    )
  }, [
    isLoading,
    isError,
    decisionStageMinutesInWrongStatus,
    userIsNotAVoter,
    decisionStageMinutesData,
    t,
    decisionStageId,
  ])

  if (!hasVoteUpdatePermission) {
    return (
      <IllustratedMessage
        titleText={tNoPrefix('app.permission-error.title')}
        subtitleText={tNoPrefix('app.permission-error.subtitle')}
        name={IllustrationMessageType.UnableToLoad}
        size={IllustrationMessageSize.Spot}
      />
    )
  }

  return (
    <TabPage
      isLoading={isLoading}
      isError={isError}
      isEmpty
      headerTitle={
        <DecisionStageMinutesApprovalHeader
          hideVoterPage={hideVoterPage}
          decisionStageMinutesVoterId={decisionStageMinutesData?.userVote?.id}
          voterStatusCode={decisionStageMinutesData?.userVote?.status.code}
        />
      }
    >
      {content}
    </TabPage>
  )
}

DecisionStageMinutesApproval.propTypes = {}

export default DecisionStageMinutesApproval
