import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ArrearsInApprovalApprovalApprovedUntilDateCell,
  ArrearsInApprovalApprovalCommentCell,
  ArrearsInApprovalApprovalMoneyCell,
  ArrearsInApprovalArrearCell,
  ArrearsInApprovalArrearClassificationCell,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/ArrearsInApprovalTableCells'
import { renderArrearsInApprovalAnalyticalTableCell } from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/shared/analytical/ArrearsInApprovalAnalyticalTableCell'
import {
  useArrearsInApprovalCustomizableCurrencyFormatter,
  useArrearsInApprovalShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/shared/i18n/useArrearsInApprovalI18n'

const useArrearsInApprovalTableColumns = ({ tileVersion }) => {
  const { t: tWithPrefix } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.arrears-in-approval.pages.business-partner.arrears.in-approval',
  })

  const formatCurrency = useArrearsInApprovalCustomizableCurrencyFormatter()
  const { format: formatDate } = useArrearsInApprovalShortDateFormatter()

  const tableHelpers = useMemo(
    () => ({
      formatCurrency,
      formatDate,
      tileVersion,
    }),
    [formatCurrency, formatDate, tileVersion],
  )

  const defaultDisables = useMemo(
    () => ({
      disableResizing: false,
      disableDragAndDrop: false,
      disableGlobalFilter: true,
      disableGroupBy: true,
    }),
    [],
  )

  return useMemo(
    () => [
      {
        Header: tWithPrefix('arrears'),
        id: 'arrears',
        accessor: 'rowData.approvalItem.arrearType',
        Cell: renderArrearsInApprovalAnalyticalTableCell(ArrearsInApprovalArrearCell, tableHelpers),
        ...defaultDisables,
      },
      {
        Header: tWithPrefix('approval-amount'),
        Cell: renderArrearsInApprovalAnalyticalTableCell(
          ArrearsInApprovalApprovalMoneyCell,
          tableHelpers,
        ),
        id: 'approval-amount',
        accessor: 'sumData.approvedAmount',
        hAlign: 'End',
        ...defaultDisables,
      },
      {
        Header: tWithPrefix('approved-until'),
        id: 'approved-until',
        accessor: 'rowData.approvedUntil',
        Cell: renderArrearsInApprovalAnalyticalTableCell(
          ArrearsInApprovalApprovalApprovedUntilDateCell,
          tableHelpers,
        ),
        hAlign: 'End',
        ...defaultDisables,
      },
      {
        Header: tWithPrefix('arrears-classification'),
        id: 'arrears-classification',
        accessor: 'rowData.approvalItem.classification.code',
        Cell: renderArrearsInApprovalAnalyticalTableCell(
          ArrearsInApprovalArrearClassificationCell,
          tableHelpers,
        ),
        ...defaultDisables,
      },
      {
        Header: tWithPrefix('comment'),
        id: 'comment',
        accessor: 'rowData.comment',
        Cell: renderArrearsInApprovalAnalyticalTableCell(
          ArrearsInApprovalApprovalCommentCell,
          tableHelpers,
        ),
        ...defaultDisables,
      },
    ],
    [defaultDisables, tWithPrefix, tableHelpers],
  )
}
export default useArrearsInApprovalTableColumns
