import isEmpty from 'lodash.isempty'

const conditionReferencesChanged = (state, { payload: { references: newReferences } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }
  state.editedRow.currentValues.references = newReferences
  if (newReferences === state.editedRow.initialValues.references) {
    delete state.editedRow.changedFields.references
    return
  }
  state.editedRow.changedFields.references = true
}

export default conditionReferencesChanged
