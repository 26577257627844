import { Label } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import { useCallback } from 'react'
import { useAreaMeasurementUnitFormatter, useNumberFormatter } from 'hooks/i18n/useI18n'

const endOfListValue = -1

export const useAreaCell = () => {
  const formatNumber = useNumberFormatter()
  const formatAreaUnit = useAreaMeasurementUnitFormatter()

  const renderAreaCell = useCallback(
    ({ value, measurementUnit } = {}) => {
      const hasValue = !isNil(value)
      return {
        value: value ?? endOfListValue,
        cellComponent: hasValue ? (
          <Label>{`${formatNumber(value)} ${formatAreaUnit(measurementUnit)}`}</Label>
        ) : (
          ''
        ),
      }
    },
    [formatNumber, formatAreaUnit],
  )

  return { renderAreaCell }
}
