import isEmpty from 'lodash.isempty'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFocusElement } from 'redux/slices/conditions/conditionCreationSlice'

const useFocusEffect = ({ conditionIndex, requirementIndex, componentRef }) => {
  const focusElement = useSelector((state) => state.conditions.conditionsCreation.focusElement)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isEmpty(focusElement)) {
      return
    }
    if (
      focusElement.conditionIndex === conditionIndex &&
      focusElement.requirementIndex === requirementIndex
    ) {
      // We have to wait for all currently queued events to finish so that we can get the focus.
      // Otherwise e.g. a closing of a popover was still in progress which leads to the input
      // field not getting the focus.
      setTimeout(() => {
        componentRef.current.focus()
      })
      dispatch(setFocusElement())
    }
  }, [focusElement, conditionIndex, requirementIndex, dispatch, componentRef])
}

export default useFocusEffect
