import { Button, Dialog } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import uniqBy from 'lodash/uniqBy'
import uniq from 'lodash.uniq'
import PropType from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  brandingFranchisePartnerExpandedContentKey,
  tenantExpandedContentKey,
} from 'components/domains/properties/getPropertyRentRollWorkingVersionReferenceData'
import LoadingScreen from 'components/ui/screens/LoadingScreen'
import { useStaffMemberSelf } from 'hooks/services/business-partners/staff-members/useStaffMemberSelf'
import { useActivateInactiveBusinessPartners } from 'hooks/services/business-partners/status/useActivateInactiveBusinessPartners'
import { useAppendTenantRoleToTenants } from 'hooks/services/properties/rent-roll/working-version/useAppendTenantRoleToTenants'
import { useCreateRentRoll } from 'hooks/services/properties/useCreateRentRoll'

/**
 * Additional notes: RRWV is deleted in backend after successful publish
 */

export const INACTIVE = 'INACTIVE'

/**
 * use ui-components dialog | https://fioneer.atlassian.net/browse/CWP-13200
 */
const PropertyRentRollWorkingVersionPublish = ({
  segments,
  rentRollWorkingVersion,
  handleSaveChanges,
  setErrorDialog,
  disableButton,
}) => {
  const { propertyUuids, rentalUnits } = rentRollWorkingVersion
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const [isPublishing, setIsPublishing] = useState(false)
  const { data: userData } = useStaffMemberSelf()
  const creatorId = userData?.id

  const getAllPresentBusinessPartners = useCallback(
    () =>
      uniqBy(
        rentalUnits
          .map((rentalUnit) => [
            rentalUnit[tenantExpandedContentKey],
            rentalUnit[brandingFranchisePartnerExpandedContentKey],
          ])
          .flat()
          .filter(Boolean),
        'id',
      ),
    [rentalUnits],
  )

  const getExpandedBusinessPartnersOfWorkingVersion = useCallback(
    (tenantsOnly = false) => {
      const businessPartners = getAllPresentBusinessPartners()
      const rentalUnitMapper = tenantsOnly
        ? (rentalUnit) => [rentalUnit.tenant_id]
        : (rentalUnit) => [rentalUnit.tenant_id, rentalUnit.branding_franchise_partner_id]
      const businessPartnersInWorkingVersion = rentalUnits
        .map(rentalUnitMapper)
        .flat()
        .filter(Boolean)
      return uniq(
        businessPartnersInWorkingVersion.map((businessPartnerFieldValue) =>
          businessPartners.find(
            (expandedBusinessPartner) => businessPartnerFieldValue === expandedBusinessPartner.id,
          ),
        ),
      )
    },
    [getAllPresentBusinessPartners, rentalUnits],
  )

  const activateBusinessPartners = useActivateInactiveBusinessPartners({
    onError: (error) => {
      activateBusinessPartners.reset()
      setIsPublishing(false)
      setErrorDialog(true, error)
    },
  })
  const appendTenantRoleToTenants = useAppendTenantRoleToTenants({
    onError: (error) => {
      appendTenantRoleToTenants.reset()
      setIsPublishing(false)
      setErrorDialog(true, error)
    },
  })

  const removeRelatedQueries = () => {
    queryClient.removeQueries(['rent-roll-working-version', ...propertyUuids.sort()])
    propertyUuids.map((propertyUuid) => {
      queryClient.removeQueries(['rent-roll', propertyUuid])
      queryClient.removeQueries(['historical-rent-roll-key-dates', propertyUuid])
      queryClient.removeQueries(['segment-kpis', propertyUuid])
    })
    queryClient.removeQueries(['properties-kpis'])
    queryClient.removeQueries(['usage-type-kpis'])
    queryClient.removeQueries(['multi-property-kpis', ...propertyUuids])
  }

  const publishRentRoll = useCreateRentRoll({
    onSuccess: () => {
      setIsPublishing(false)
      removeRelatedQueries()
      navigate(pathname.replace('/working-version', `${search}#details`))
    },
    onError: (error) => {
      setIsPublishing(false)
      setErrorDialog(true, error)
    },
  })

  const getSegmentName = useCallback(
    (segmentUuid) => segments.find((segment) => segment.uuid === segmentUuid)?.name ?? null,
    [segments],
  )

  const addSegmentNames = useCallback(
    () =>
      rentalUnits.map((rentalUnit) => ({
        ...rentalUnit,
        segment_name: getSegmentName(rentalUnit.segment_uuid),
      })),
    [getSegmentName, rentalUnits],
  )

  useEffect(() => {
    if (activateBusinessPartners.isSuccess) {
      const previousInactiveBps = activateBusinessPartners.variables
      // When a BP switches from inactive to active, all roles are stripped
      // This is not the case for the returned BPs of the previous call, so it's deleted manually
      previousInactiveBps.forEach((bp) => delete bp?.roles)
      appendTenantRoleToTenants.mutate({
        tenants: getExpandedBusinessPartnersOfWorkingVersion(true),
        previousInactiveBps,
      })
      activateBusinessPartners.reset()
    }
  }, [
    activateBusinessPartners,
    activateBusinessPartners.isSuccess,
    appendTenantRoleToTenants,
    getExpandedBusinessPartnersOfWorkingVersion,
  ])

  useEffect(() => {
    if (appendTenantRoleToTenants.isSuccess) {
      const rentRollWorkingVersionToPublish = {
        ...rentRollWorkingVersion,
        header: { ...rentRollWorkingVersion?.header, creator_id: creatorId },
        rentalUnits: addSegmentNames(),
      }
      queryClient.invalidateQueries(['businesspartners', 'minis'])
      publishRentRoll.mutate(rentRollWorkingVersionToPublish)
      appendTenantRoleToTenants.reset()
    }
  }, [
    activateBusinessPartners.isSuccess,
    addSegmentNames,
    appendTenantRoleToTenants,
    creatorId,
    getExpandedBusinessPartnersOfWorkingVersion,
    publishRentRoll,
    queryClient,
    rentRollWorkingVersion,
  ])

  const getInactiveBusinessPartners = (businessPartners) =>
    businessPartners.filter((businessPartner) => businessPartner?.status === INACTIVE)

  const handlePublish = () => {
    setIsPublishing(true)
    handleSaveChanges()
    const expandedBusinessPartners = getExpandedBusinessPartnersOfWorkingVersion()
    const inactiveBusinessPartners = getInactiveBusinessPartners(expandedBusinessPartners)
    activateBusinessPartners.mutate(inactiveBusinessPartners)
  }

  return (
    <>
      <Button
        id="publish-rent-roll"
        design="Emphasized"
        onClick={() => handlePublish()}
        disabled={disableButton}
      >
        {t('pages.property.rent-roll.working-version.button.publish')}
      </Button>
      {createPortal(
        <Dialog open={isPublishing}>
          <LoadingScreen
            title={t('app.loading.title')}
            description={t('app.loading.description')}
          />
        </Dialog>,
        document.body,
      )}
    </>
  )
}

PropertyRentRollWorkingVersionPublish.propTypes = {
  handleSaveChanges: PropType.func.isRequired,
  segments: PropType.array.isRequired,
  rentRollWorkingVersion: PropType.object.isRequired,
  setErrorDialog: PropType.func.isRequired,
  disableButton: PropType.bool.isRequired,
}

export default PropertyRentRollWorkingVersionPublish
