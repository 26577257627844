import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { isMissingPermissionError } from 'api/requests'
import { tileHasError } from 'redux/slices/decision-paper/tilesOverviewSlice'

/**
 * Helper to manage loading and error states of decision paper tiles.
 * Extends the response error object with a list of error details for each source (`error.errorDetails`)
 * as well as an `isAccessDeniedError` flag that indicates whether at least one source failed with
 * a permission error.
 *
 * Example usage in a dedicated automated tile loading hook:
 * ```js
 * const { isSomeValueLoading, isSomeValueError, error } =
 *   useAutomaticTileHookHelper({
 *     loadingValues: [isSourceOneLoading, isSourceTwoLoading],
 *     errorValues: [isSourceOneError, isSourceTwoError],
 *     errorDetails: [sourceOneError, sourceTwoError],
 *     tileId: "173c7247-94cc-4c46-b98c-41a7558dda2c",
 *   })
 * return useMemo(() => {
 *   if (isSomeValueError) return { isLoading: false, isError: true, error }
 *   if (isSomeValueLoading) return { isLoading: true, isError: false }
 *   return { isLoading: false, isError: false, data }
 * }, [isSomeValueError, isSomeValueLoading, error])
 * ```
 *
 * @param {object} options
 * @param {Array<boolean>} options.loadingValues array of boolean values signaling whether a source is loading
 * @param {Array<boolean>} options.errorValues array of boolean values signaling whether a source errored while loading
 * @param {Array<unknown>} options.errorDetails array of response error objects
 * @param {String} options.tileId id of the tile for which the data is retrieved
 * @returns {{isSomeValueLoading: boolean, isSomeValueError: boolean, error: {errorDetails: Array<object>, isAccessDeniedError: boolean}}}
 */
const useAutomaticTileHookHelper = ({ loadingValues, errorValues, errorDetails, tileId }) => {
  const dispatch = useDispatch()

  const isSomeValueLoading = useMemo(
    () => loadingValues?.some((value) => value) || false,
    [loadingValues],
  )
  const isSomeValueError = useMemo(
    () => errorValues?.some((value) => value) || false,
    [errorValues],
  )

  const updateErrorState = useCallback(() => {
    const errorObjects = errorDetails
      .filter((errorDetail) => errorDetail)
      .map(async (errorDetail) => {
        const response = await errorDetail.response.json()
        if (response.errors) {
          return {
            code: response.errors[0]?.code,
            title: response.errors[0]?.title,
          }
        }
        return {
          code: response.code,
          title: response.title,
        }
      })

    Promise.allSettled(errorObjects).then((resolvedErrorObjects) => {
      resolvedErrorObjects.forEach((resolvedError) => {
        if (resolvedError?.value) {
          dispatch(
            tileHasError({
              id: tileId,
              error: resolvedError.value,
            }),
          )
        }
      })
    })
  }, [dispatch, errorDetails, tileId])

  const error = useMemo(() => {
    if (isSomeValueError && errorDetails) {
      updateErrorState()
    }
    return {
      isAccessDeniedError: errorDetails?.some((errorDetail) =>
        isMissingPermissionError(errorDetail || {}),
      ),
    }
  }, [errorDetails, updateErrorState, isSomeValueError])

  return useMemo(
    () => ({
      isSomeValueLoading,
      isSomeValueError,
      error,
    }),
    [isSomeValueLoading, isSomeValueError, error],
  )
}

export default useAutomaticTileHookHelper
