import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import TableInputCell from 'components/domains/conditions/overview/table-cells/view/TableInputCell'
import useConditionsCellEditMode from 'hooks/services/conditions/edit-mode/useConditionsCellEditMode'
import { conditionRefNumberChanged } from 'redux/slices/conditions/conditionsTableSlice'

const ConditionsTableRefNumberCell = ({ value: { refNumber, conditionId } }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.columns.ref-number',
  })

  const dispatch = useDispatch()
  const editedConditionRefNumber = useSelector(
    (state) => state.conditions.conditionsTable.editedRow.currentValues?.refNumber,
  )
  const { isEditModeForCurrentRow } = useConditionsCellEditMode({
    conditionId,
  })

  const onInput = useCallback(
    (newRefNumber) => {
      dispatch(conditionRefNumberChanged({ refNumber: newRefNumber }))
    },
    [dispatch],
  )

  return (
    <TableInputCell
      input={isEditModeForCurrentRow ? editedConditionRefNumber : refNumber ?? ''}
      isEditMode={isEditModeForCurrentRow}
      onInput={onInput}
      placeholder={t('placeholder')}
    />
  )
}

ConditionsTableRefNumberCell.propTypes = {
  value: PropTypes.shape({
    conditionId: PropTypes.string.isRequired,
    refNumber: PropTypes.string,
  }).isRequired,
}

export default ConditionsTableRefNumberCell
