import { Icon, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import { useDocumentVersionUpload } from 'hooks/services/documents/useDocumentVersionUpload'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'
import AddDocumentVersionDialog from 'routes/documents/document-versions/AddDocumentVersionDialog'

const VersionCell = ({ cell }) => {
  const [isOpenAddNewVersionDialog, setIsOpenAddNewVersionDialog] = useState(false)
  const [file, setFile] = useState()
  const [deletionError, setDeletionError] = useState()
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false)
  const queryClient = useQueryClient()

  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.documents.detail.versions-table',
  })

  const handleIconOnClick = () => {
    setFile(undefined)
    setIsOpenAddNewVersionDialog(true)
  }

  const handleUploadSuccess = () => {
    queryClient.invalidateQueries(['documents', 'versions', cell.row.original.id])
    Modals.showToast({ children: <span>{t('upload-success')}</span> })
    setFile(undefined)
  }

  const handleUploadError = async (error) => {
    setDeletionError(await formatHookError(error))
    setIsErrorMessageOpen(true)
    setFile(undefined)
  }

  const handleClose = () => {
    setIsErrorMessageOpen(false)
  }

  const { mutate: uploadDocumentVersion } = useDocumentVersionUpload({
    onSuccess: handleUploadSuccess,
    onError: handleUploadError,
    documentId: cell.row.original.id,
  })

  const handleUploadNewVersion = ({ file: fileToUpload, comment }) => {
    setIsOpenAddNewVersionDialog(false)
    uploadDocumentVersion({
      file: fileToUpload,
      comment,
      version: '',
    })
  }

  return (
    <>
      {cell.value && <Icon name="add" interactive onClick={handleIconOnClick} />}
      {isOpenAddNewVersionDialog && (
        <AddDocumentVersionDialog
          open={isOpenAddNewVersionDialog}
          onAfterClose={() => setIsOpenAddNewVersionDialog(false)}
          onUploadNewVersion={handleUploadNewVersion}
          initialState={{
            documentName: cell.row.original?.name,
            documentType: cell.row.original?.type,
            keyDate: cell.row.original?.keyDate ?? null,
            file,
          }}
        />
      )}
      {isErrorMessageOpen &&
        createPortal(
          <ErrorMessageBoxWithExpandableDetails
            messageSummary={t('upload-error')}
            messageDetails={deletionError}
            isOpen={isErrorMessageOpen}
            onClose={handleClose}
          />,
          document.body,
        )}
    </>
  )
}

export default VersionCell

VersionCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        keyDate: PropTypes.string,
      }),
    }),
  }).isRequired,
}
