import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useArrearClassifications = () =>
  useCamelizedResponse(
    useRequest({
      path: `/arrears/configurations/arrear-classifications`,
      keys: ['arrears', 'configurations', 'arrear-classifications'],
    }),
  )
