import PropTypes from 'prop-types'

export const assetValuationOverviewFilters = PropTypes.arrayOf(
  PropTypes.shape({
    columnKey: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
  }),
)

export const assetValuationOverviewColumnSelectionPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    columnKey: PropTypes.string.isRequired,
    title: PropTypes.string,
    isVisible: PropTypes.bool,
    isSelectableForHiding: PropTypes.bool,
  }),
)
