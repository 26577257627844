import { z } from 'zod'

export const chargeBaseSchema = z.object({
  chargeId: z.string(),
  assetId: z.string(),
  assetType: z.object({
    id: z.string(),
    name: z.string(),
  }),
  assetManagementSystem: z.object({
    id: z.string(),
    name: z.string(),
  }),
  rankingOfCagsWithinAsset: z.number().int(),
  sequenceOfAssetsWithinCag: z.number().int(),
})

/** @typedef {import('zod').TypeOf<typeof chargeBaseSchema>} Charge */

const chargesBaseSchema = z.object({
  charges: z.array(chargeBaseSchema),
})

/** @typedef {import('zod').TypeOf<typeof chargesBaseSchema>} Charges */

const chargesSchema = chargesBaseSchema.optional()

export default chargesSchema
