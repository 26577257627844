import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import TableInputCell from 'components/domains/conditions/overview/table-cells/view/TableInputCell'
import useRequirementsCellAddMode from 'hooks/services/conditions/edit-mode/useRequirementsCellAddMode'
import useRequirementsCellEditMode from 'hooks/services/conditions/edit-mode/useRequirementsCellEditMode'
import {
  requirementNameChanged,
  requirementNameOnBlur,
} from 'redux/slices/conditions/requirementsTableSlice'

const RequirementsTableNameCell = ({ value: { requirementId, name } }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.columns.name',
  })

  const dispatch = useDispatch()

  const { isAddModeForCurrentRow } = useRequirementsCellAddMode({
    requirementId,
  })
  const { isEditModeForCurrentRow } = useRequirementsCellEditMode({
    requirementId,
  })
  const editedRequirementName = useSelector(
    (state) => state.conditions.requirementsTable.editedRow.currentValues?.name,
  )
  const hasNameError = useSelector(
    (state) => !!state.conditions.requirementsTable.editedRow.errorFields?.name,
  )

  const onInput = useCallback(
    (newName) => {
      dispatch(requirementNameChanged({ name: newName }))
    },
    [dispatch],
  )

  const onBlur = useCallback(() => {
    dispatch(requirementNameOnBlur())
  }, [dispatch])

  const inputValue = useMemo(() => {
    if (isAddModeForCurrentRow) {
      return ''
    }
    if (isEditModeForCurrentRow) {
      return editedRequirementName
    }
    return name
  }, [isAddModeForCurrentRow, isEditModeForCurrentRow, editedRequirementName, name])

  return (
    <TableInputCell
      input={inputValue}
      isEditMode={isAddModeForCurrentRow || isEditModeForCurrentRow}
      onBlur={onBlur}
      onInput={onInput}
      hasError={hasNameError}
      placeholder={t('placeholder')}
      errorText={t('error')}
    />
  )
}

RequirementsTableNameCell.propTypes = {
  value: PropTypes.shape({
    requirementId: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
}

export default RequirementsTableNameCell
