import * as UI5React from '@fioneer/ui5-webcomponents-react'
import { useModalsContext } from '@fioneer/ui5-webcomponents-react/dist/internal/ModalsContext'
import PropTypes from 'prop-types'
import { createRef, forwardRef, useCallback, useId } from 'react'
import { createPortal } from 'react-dom'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/financed-assets-information/shared/ui/MessageBox.module.css'

const { MessageBoxActions, MessageBoxTypes, PopupAccessibleRole } = UI5React

const messageBoxPropTypes = {
  /**
   * Defines the accessible name of the component.
   */
  accessibleName: PropTypes.string,
  /**
   * Defines the IDs of the elements that label the component.
   */
  accessibleNameRef: PropTypes.string,
  /**
   * Allows setting a custom role. Available options are:
   *
   * *   `Dialog`
   * *   `None`
   * *   `AlertDialog`
   */
  accessibleRole: PropTypes.oneOf(Object.values(UI5React.PopupAccessibleRole)),
  /**
   * Array of actions of the MessageBox. Those actions will be transformed into buttons in the `MessageBox` footer.
   *
   * __Note:__ Although this prop accepts all HTML Elements, it is strongly recommended that you only use `MessageBoxAction`s (text) or the `Button` component in order to preserve the intended.
   */
  actions: PropTypes.arrayOf(PropTypes.node),
  /**
   * Defines the content of the `MessageBox`.
   *
   * **Note:** Although this prop accepts HTML Elements, it is strongly recommended that you only use text in order to preserve the intended design and a11y capabilities.
   */
  children: PropTypes.node.isRequired,
  /**
   * CSS Class Name which will be appended to the most outer element of a component.
   * Use this prop carefully, overwriting CSS rules might break the component.
   */
  className: PropTypes.string,
  /**
   * Specifies which action of the created dialog will be emphasized.
   */
  emphasizedAction: PropTypes.oneOf(Object.values(UI5React.MessageBoxActions)),
  /**
   * A custom icon. If not present, it will be derived from the `MessageBox` type.
   */
  icon: PropTypes.node,
  /**
   * Defines the ID of the HTML Element or the `MessageBoxAction`, which will get the initial focus.
   */
  initialFocus: PropTypes.oneOf(Object.values(UI5React.MessageBoxActions)),
  /**
   * Fired after the component is opened. **This event does not bubble.**
   */
  onAfterOpen: PropTypes.func,
  /**
   * Fired before the component is closed. This event can be cancelled, which will prevent the popup from closing. **This event does not bubble.**
   */
  onBeforeClose: PropTypes.func,
  /**
   * Fired before the component is opened. This event can be cancelled, which will prevent the popup from opening. **This event does not bubble.**
   */
  onBeforeOpen: PropTypes.func,
  /**
   * Callback to be executed when the `MessageBox` is closed (either by pressing on one of the `actions` or by pressing the `ESC` key). `event.detail.action` contains the pressed action button.
   */
  onClose: PropTypes.func,
  /**
   * Indicates if the element is open
   */
  open: PropTypes.bool,
  /**
   * Determines where the MessageBox will be mounted. Defaults to the `<body>` element.
   */
  parentElement: PropTypes.instanceOf(HTMLElement),
  /**
   * Defines if the focus should be returned to the previously focused element, when the popup closes.
   */
  preventFocusRestore: PropTypes.bool,
  /**
   * A custom title for the MessageBox. If not present, it will be derived from the `MessageBox` type.
   */
  titleText: PropTypes.string,
  /**
   * Defines the type of the `MessageBox` with predefined title, icon, actions and a visual highlight color.
   */
  type: PropTypes.oneOf(Object.values(UI5React.MessageBoxTypes)),
}

/**
 * @typedef {PropTypes.InferProps<typeof messageBoxPropTypes>} MessageBoxProps
 * @typedef {UI5React.DialogDomRef} MessageBoxRef
 */

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<MessageBoxProps> & React.RefAttributes<MessageBoxRef>>}
 */
const MessageBox = forwardRef(
  (
    {
      className = '',
      children,
      parentElement = document.body,
      type = MessageBoxTypes.Error,
      ...otherProps
    },
    messageBoxRef,
  ) => {
    const messageBox = (
      <UI5React.MessageBox
        {...otherProps}
        ref={messageBoxRef}
        className={`${styles.messageBox} ${className}`}
        type={type}
        draggable={false}
        resizable={false}
        stretch={false}
      >
        {children}
      </UI5React.MessageBox>
    )

    return parentElement ? createPortal(messageBox, parentElement) : messageBox
  },
)

MessageBox.displayName = 'MessageBox'

MessageBox.propTypes = messageBoxPropTypes

/**
 * @returns {(props: MessageBoxProps, container?: HTMLElement) => { ref: MessageBoxRef, close: () => void }}
 */
const useShowMessageBox = () => {
  const { setModal } = useModalsContext()
  const id = useId()

  return useCallback(
    (props, container) => {
      const ref = createRef()

      if (!setModal) {
        throw new Error(
          'useShowMessageBox must be used in a component that is a child of the UI5 ThemeProvider',
        )
      }

      setModal({
        type: 'set',
        payload: {
          Component: MessageBox,
          props: {
            ...props,
            open: true,
            onClose: (event) => {
              if (typeof props.onClose === 'function') {
                props.onClose(event)
              }
              setModal({
                type: 'reset',
                payload: { id },
              })
            },
            parentElement: null,
          },
          ref,
          container,
          id,
        },
      })

      return {
        ref,
        close: () => {
          ref.current?.close()
        },
      }
    },
    [id, setModal],
  )
}

export { MessageBoxActions, MessageBoxTypes, PopupAccessibleRole, useShowMessageBox }

export default MessageBox
