import { MessageStrip, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

const propTypes = {
  staticData: PropTypes.shape({
    message: PropTypes.string.isRequired,
    design: PropTypes.oneOf(Object.values(MessageStripDesign)),
    hideIcon: PropTypes.bool,
    hideCloseButton: PropTypes.bool,
  }),
}

/**
 * @typedef {import('prop-types').InferProps<typeof propTypes>} Params
 * @param {Params} params
 * @returns {import('react').ReactNode}
 */
const DynamicMessageStrip = ({ staticData: { message, hideCloseButton, hideIcon, design } }) => (
  <MessageStrip design={design} hideIcon={hideIcon} hideCloseButton={hideCloseButton}>
    {message}
  </MessageStrip>
)

DynamicMessageStrip.propTypes = propTypes

export default DynamicMessageStrip
