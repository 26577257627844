import Request from 'api/requests'

export const getAccessTokenFromLocalStorage = () => {
  const foundLocalStorageKey = Object.keys(localStorage).find((localStorageKey) =>
    localStorageKey.startsWith('oidc.user'),
  )
  const { access_token: accessToken } = JSON.parse(localStorage.getItem(foundLocalStorageKey)) ?? {}

  return accessToken
}

const downloadImage = async ({ imageUrl }) => {
  const accessToken = getAccessTokenFromLocalStorage()
  const { data } = await Request.get({ accessToken, path: imageUrl, isFullPath: true })
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(data)
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data)
    }
  })
}

export default downloadImage
