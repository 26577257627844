import PropTypes from 'prop-types'
import { useCallback } from 'react'
import MultipleDealSelectionPdfViewWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/wrapper/MultipleDealSelectionPdfViewWrapper'
import RiskMonitoringStandardCollateralPackageTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/RiskMonitoringStandardCollateralPackageTile'

const RiskMonitoringStandardCollateralPackageTileSelectionWrapper = ({ tileId, isPdfView }) => {
  const contentCreator = useCallback(
    ({ selectedDealIndex }) => (
      <RiskMonitoringStandardCollateralPackageTile
        tileId={tileId}
        selectedDealIndex={selectedDealIndex}
        isPdfView={isPdfView}
      />
    ),
    [isPdfView, tileId],
  )
  return (
    <MultipleDealSelectionPdfViewWrapper
      tileId={tileId}
      contentCreator={contentCreator}
      isPdfView={isPdfView}
    />
  )
}

RiskMonitoringStandardCollateralPackageTileSelectionWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default RiskMonitoringStandardCollateralPackageTileSelectionWrapper
