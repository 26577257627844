import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const usePropertyDeleteValuation = (options) => {
  const { delete: deleteValuation } = useAccessTokenRequest()

  return useMutation(
    async ({ propertyUuid, valuationRequestId }) =>
      await deleteValuation({
        path: `/properties/${propertyUuid}/valuation-requests/${valuationRequestId}`,
      }),
    options,
  )
}

export default usePropertyDeleteValuation
