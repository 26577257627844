import { Button, ButtonDesign, Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import get from 'lodash.get'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { conditionTypes } from 'api/conditions/conditions'
import LoadingComboBox from 'components/ui/combobox/LoadingComboBox'
import useExternalTypes from 'hooks/services/conditions/config/useExternalTypes'
import useInternalTypes from 'hooks/services/conditions/config/useInternalTypes'
import useAnalyticalTableIndexHelper from 'hooks/services/conditions/tables/useAnalyticalTableIndexHelper'
import {
  conditionTypeChanged,
  conditionRowHasError,
} from 'redux/slices/conditions/conditionCreationSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const ConditionCreationTypeCell = ({
  row: {
    id,
    index,
    depth,
    original: { type: typeCode },
  },
  conditionType,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.dialog.external-condition-creation.table.type-cell',
  })
  // This is a hack that the select was not able to render the valueState after the valueState was deleted
  // and came back again. Therefore I am setting a key which get increased when the documentType changes. With
  // this the react component is considered new which leads to the error not occur.
  const [internalKey, setInternalKey] = useState(0)
  const dispatch = useDispatch()
  const { isConditionsCell, conditionIndex } = useAnalyticalTableIndexHelper({
    id,
    index,
    depth,
  })
  const {
    entityRef: { entityType },
  } = useContext(ConditionsContext)

  useEffect(() => {
    setInternalKey((key) => key + 1)
  }, [typeCode])

  const onTypeSelectionChanged = useCallback(
    ({ code: newTypeCode }) => {
      dispatch(conditionTypeChanged({ conditionIndex, type: newTypeCode }))
      if (newTypeCode === '') {
        dispatch(
          conditionRowHasError({
            conditionIndex,
            field: 'type',
            error: { warning: false },
            showError: true,
          }),
        )
      }
    },
    [dispatch, conditionIndex],
  )

  const hasInputError = useSelector(
    (state) => !!state.conditions.conditionsCreation.errorRows[conditionIndex]?.type,
  )

  const showInputError = useSelector(
    (state) => !!state.conditions.conditionsCreation.errorRows[conditionIndex]?.type?.showError,
  )

  const useLoader = ({ useTypes, ...params }) => {
    const { data, ...rest } = useTypes(params)
    return {
      ...rest,
      data: get(data, 'types')?.map(({ code, name }) => ({ code, displayName: name })),
    }
  }
  const loadingHookParams = useMemo(
    () => ({
      entityType,
      includeDeprecated: false,
      useTypes: conditionType === conditionTypes.external ? useExternalTypes : useInternalTypes,
    }),
    [conditionType, entityType],
  )

  const onClearButtonClicked = useCallback(() => {
    onTypeSelectionChanged({ code: '' })
  }, [onTypeSelectionChanged])

  const valueState = hasInputError && showInputError ? ValueState.Error : ValueState.None
  const isErrorState = hasInputError && showInputError

  if (!isConditionsCell) {
    return
  }
  return (
    <LoadingComboBox
      key={`${conditionIndex}-type-select-${internalKey}`}
      id={`${conditionIndex}-type-select`}
      placeholder={t('placeholder')}
      value={{ code: typeCode }}
      useLoader={useLoader}
      input={loadingHookParams}
      onChange={onTypeSelectionChanged}
      optionKeyName="code"
      optionDisplayName="name"
      selectionName="types"
      isErrorState={isErrorState}
      valueState={valueState}
      valueStateMessage={<Text>{t('error')}</Text>}
      icon={
        typeCode && (
          <Button icon="decline" design={ButtonDesign.Transparent} onClick={onClearButtonClicked} />
        )
      }
    />
  )
}

ConditionCreationTypeCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    depth: PropTypes.number.isRequired,
    original: PropTypes.shape({
      type: PropTypes.string,
    }).isRequired,
  }).isRequired,
  conditionType: PropTypes.oneOf(Object.values(conditionTypes)).isRequired,
}

export default ConditionCreationTypeCell
