import { useCallback, useEffect, useRef } from 'react'

/**
 * Returns a function that calls the provided callback after the specified delay,
 * and passes its arguments to the callback.
 *
 * @function
 * @template {(...*) => void} CallbackT
 * @param {CallbackT} callback - Callback that should be called after the delay
 * @param {number} delay - Delay in ms
 * @returns {CallbackT} - Delayed callback
 */
export const useDelay = (callback, delay) => {
  const timeoutIdRef = useRef(null)

  // Cleanup running timeout:
  useEffect(
    () => () => {
      if (timeoutIdRef.current !== null) {
        clearTimeout(timeoutIdRef.current)
      }
    },
    [],
  )

  return useCallback(
    (...args) => {
      if (timeoutIdRef.current !== null) {
        clearTimeout(timeoutIdRef.current)
      }
      timeoutIdRef.current = setTimeout(() => callback(...args), delay)
    },
    [callback, delay],
  )
}
