import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import KeyInternalContactsTile from 'components/domains/business-partners/tile/general-information/keyInternalContact/KeyInternalContactsTile'
import PropertyKeyInternalContactFunctionsMultiSelect from 'components/domains/properties/overview/keyInternalContacts/PropertyKeyInternalContactFunctionsMultiSelect'
import useHandlePropertyKeyInternalContacts from 'components/domains/properties/overview/keyInternalContacts/useHandlePropertyKeyInternalContacts'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyKeyInternalContactsCard = ({ allowUserToEdit = true }) => {
  const {
    property: {
      change_request_exist_indicator: changeRequestExistIndicator,
      allowed_operations: allowed_operations,
    },
  } = useContext(PropertyContext)

  const allowedOperations = allowed_operations?.allowed_operations ?? []
  const userIsAllowedToEdit =
    allowUserToEdit &&
    !changeRequestExistIndicator &&
    allowedOperations.includes('PropertyBpInternalRoles_Update')

  const {
    keyInternalContacts,
    addEmptyKeyInternalContact,
    saveKeyInternalContacts,
    editKeyInternalContact,
    removeKeyInternalContact,
    resetKeyInternalContacts,
    isLoading,
    isErrorDialogOpen,
    setIsErrorDialogOpen,
    errorMessage,
  } = useHandlePropertyKeyInternalContacts()

  const renderMultiSelectComponent = useCallback(
    (data) => (
      <PropertyKeyInternalContactFunctionsMultiSelect
        userId={data?.userId}
        selected={data?.selected}
        onSelectionChange={data?.onSelectionChange}
      />
    ),
    [],
  )

  return (
    <KeyInternalContactsTile
      hasEditPermissions={userIsAllowedToEdit}
      keyInternalContacts={keyInternalContacts}
      addEmptyKeyInternalContact={addEmptyKeyInternalContact}
      saveKeyInternalContacts={saveKeyInternalContacts}
      editKeyInternalContact={editKeyInternalContact}
      removeKeyInternalContact={removeKeyInternalContact}
      resetKeyInternalContacts={resetKeyInternalContacts}
      isErrorDialogOpen={isErrorDialogOpen}
      setIsErrorDialogOpen={setIsErrorDialogOpen}
      errorMessage={errorMessage}
      isLoading={isLoading}
      renderMultiSelectComponent={renderMultiSelectComponent}
    />
  )
}

PropertyKeyInternalContactsCard.propTypes = {
  allowUserToEdit: PropTypes.bool,
}

export default PropertyKeyInternalContactsCard
