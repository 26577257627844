import { FlexBox, FlexBoxDirection, Link, Popover } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import CardSection from 'components/domains/deals/card/CardSection'
import BusinessPartnerPopoverItem from 'components/domains/properties/portfolio/overview/portfolio-table-bp-popover/BusinessPartnerPopoverItem'
import KeyInternalContactPopoverItem from 'components/domains/properties/portfolio/overview/portfolio-table-bp-popover/KeyInternalContactPopoverItem'
import styles from 'components/domains/properties/portfolio/overview/portfolio-table-bp-popover/PortfolioTableBusinessPartnerPopover.module.css'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import { usePropertyPartners } from 'hooks/services/properties/partners/usePropertyPartners'

const PortfolioTableBusinessPartnerPopover = ({ propertyUuid }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.portfolio.table.business-partner-popover',
  })
  const [popoverIsOpen, setPopoverIsOpen] = useState(false)

  const uniqueId = crypto.randomUUID()
  const popoverLinkId = `popover-link-${uniqueId}`

  const {
    isFetching: isFetchingInternalPartners,
    isError: isErrorInternalPartners,
    data: internalPartners,
  } = usePropertyPartners(propertyUuid, 'internal')
  const {
    isFetching: isFetchingExternalPartners,
    isError: isErrorExternalPartners,
    data: externalPartners,
  } = usePropertyPartners(propertyUuid, 'external')

  const isFetching = useMemo(
    () => isFetchingExternalPartners || isFetchingInternalPartners,
    [isFetchingExternalPartners, isFetchingInternalPartners],
  )
  const isError = useMemo(
    () => isErrorInternalPartners || isErrorExternalPartners,
    [isErrorExternalPartners, isErrorInternalPartners],
  )

  const externalBusinessPartnerResponse = useBusinessPartnerMiniByIds(
    externalPartners?.business_partners?.map((bp) => bp.party_id) ?? [],
  )

  const listItems = useMemo(() => {
    const partnersList = []
    if (internalPartners?.business_partners?.length) {
      partnersList.push(
        <CardSection
          title={t('section.internal')}
          key={'key-internal-contacts'}
          className={styles.sectionTitle}
          isSmallTitle
        >
          <div className={styles.spacer} />
          {internalPartners?.business_partners.map((partner) => (
            <KeyInternalContactPopoverItem
              key={partner.party_id}
              staffMemberId={partner.party_id}
              staffMemberFunction={partner.business_partner_function_name}
            />
          ))}
        </CardSection>,
      )
    }
    if (externalPartners?.business_partners?.length) {
      partnersList.push(
        <CardSection
          title={t('section.external')}
          key={'business-partners'}
          className={styles.section}
          isSmallTitle
        >
          <div className={styles.spacer} />
          {externalPartners?.business_partners.map((partner) => (
            <BusinessPartnerPopoverItem
              key={partner.party_id}
              businessPartnerId={partner.party_id}
              businessPartnerFunctionName={partner.business_partner_function_name}
              businessPartnersResponse={externalBusinessPartnerResponse}
            />
          ))}
        </CardSection>,
      )
    }
    return partnersList
  }, [
    externalBusinessPartnerResponse,
    externalPartners?.business_partners,
    internalPartners?.business_partners,
    t,
  ])

  const renderContent = () => {
    if (isEmpty(listItems)) {
      return <div />
    }
    const count =
      internalPartners.business_partners.length + externalPartners.business_partners.length
    return (
      <>
        <Link
          id={popoverLinkId}
          onClick={() => {
            setPopoverIsOpen(true)
          }}
        >
          {count}
        </Link>
        {createPortal(
          <Popover
            id={`popover-${uniqueId}`}
            opener={popoverLinkId}
            open={popoverIsOpen}
            onAfterClose={() => {
              setPopoverIsOpen(false)
            }}
            headerText={t('title', { count })}
            className={styles.popover}
          >
            <FlexBox direction={FlexBoxDirection.Column}>
              {listItems.map((item, index) => (
                <React.Fragment key={index}>{item}</React.Fragment>
              ))}
            </FlexBox>
          </Popover>,
          document.body,
        )}
      </>
    )
  }

  return (
    <SmallLoadingWrapper
      isLoading={isFetching}
      isError={isError}
      error={<ErrorDataUnavailableInCell />}
      renderContent={renderContent}
    />
  )
}

PortfolioTableBusinessPartnerPopover.propTypes = {
  propertyUuid: PropTypes.string.isRequired,
}

export default PortfolioTableBusinessPartnerPopover
