import {
  Button,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  Popover,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'

export const defaultPopoverStaticConfig = {
  cancel: {
    message: t('display-and-edit-table.popover-static-config.cancel.message'),
    button: t('display-and-edit-table.popover-static-config.cancel.button'),
  },
  delete: {
    message: t('display-and-edit-table.popover-static-config.delete.message'),
    button: t('display-and-edit-table.popover-static-config.delete.button'),
  },
}

export const DisplayAndEditTablePopover = ({
  isOpen,
  closePopover,
  rowKey,
  mode,
  cardType,
  message,
  handleButtonClick,
  buttonText,
}) => (
  <Popover
    opener={isOpen ? `${rowKey}-${mode}-${cardType}` : '<no-opener>'}
    placementType="Top"
    open={isOpen}
    onAfterClose={() => closePopover()}
  >
    <FlexBox alignItems={FlexBoxAlignItems.Center} direction={FlexBoxDirection.Column} fitContainer>
      <Text style={{ paddingBottom: '6px', textAlign: 'center' }}>{message}</Text>
      <Button
        onClick={() => {
          handleButtonClick()
          closePopover()
        }}
      >
        {buttonText}
      </Button>
    </FlexBox>
  </Popover>
)

DisplayAndEditTablePopover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closePopover: PropTypes.func,
  rowKey: PropTypes.string,
  mode: PropTypes.string,
  message: PropTypes.string,
  handleButtonClick: PropTypes.func,
  buttonText: PropTypes.string,
}
