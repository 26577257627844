import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useOriginationTeams = (businessSegmentCode, options = {}) => {
  const baseUri = '/deals/configurations/origination-teams'
  const path = businessSegmentCode ? `${baseUri}?businessSegment=${businessSegmentCode}` : baseUri
  return useCamelizedResponse(
    useRequest({
      path,
      translated: true,
      useCache: true,
      keys: ['deals', 'configurations', 'origination-teams', businessSegmentCode],
      options: {
        ...options,
      },
    }),
  )
}

export const useOriginationPlatforms = (businessSegmentCode, options = {}) => {
  const {
    data: originationTeams,
    isLoading,
    isError,
  } = useOriginationTeams(businessSegmentCode, options)
  if (!originationTeams) return { isLoading, isError, data: undefined }
  const originationPlatforms = [...new Set(originationTeams.map(({ platform }) => platform))]
  return { isLoading, isError, data: originationPlatforms }
}
