import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FurtherDetailsPieChartsCard from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/FurtherDetailsPieChartsCard'
import {
  UsageTypeColorProvider,
  useUsageTypeColors,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/UsageTypeColorContext'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const MultipleFurtherDetailsGranularityUsageTypeTile = ({
  tileId,
  isPdfView,
  selectedDealIndex,
}) => {
  const dispatch = useDispatch()

  const {
    data: {
      multiplePropertyGranularityPieChartData = [],
      sourcePaths = [],
      sourceRender,
      dealsData,
    } = {},
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const selectedDealDisplayId = dealsData[selectedDealIndex]?.dealDisplayId

  useEffect(() => {
    dispatch(setSourceRender({ tileId, sourceRender: { path: sourcePaths?.[selectedDealIndex] } }))
  }, [dispatch, selectedDealDisplayId, selectedDealIndex, sourcePaths, tileId])

  useEffect(() => {
    if (isNil(sourceRender)) {
      dispatch(
        setSourceRender({ tileId, sourceRender: { path: sourcePaths?.[selectedDealIndex] } }),
      )
    }
  }, [dispatch, selectedDealDisplayId, selectedDealIndex, sourcePaths, sourceRender, tileId])

  const { multiPropertyKpisByGranularity, usageTypeCodes, noProperties } = useMemo(() => {
    const propertyGranularityPieChartData =
      multiplePropertyGranularityPieChartData?.[selectedDealIndex] ?? {}

    return {
      multiPropertyKpisByGranularity:
        propertyGranularityPieChartData?.multiPropertyKpisByGranularity
          ?.keyDateToMultiPropertyRentRollKpis?.[0]?.kpis,
      usageTypeCodes: propertyGranularityPieChartData?.usageTypeCodes?.segmentUsageTypes,
      noProperties: !propertyGranularityPieChartData?.properties,
    }
  }, [multiplePropertyGranularityPieChartData, selectedDealIndex])

  const getUsageTypeRentRollKpis = (multiPropertyKpis) =>
    multiPropertyKpis?.segmentUsageTypeRentRollKpis ?? []

  const getUsageTypeName = (rentRollKpi) =>
    usageTypeCodes.find((usageTypeCode) => usageTypeCode.key === rentRollKpi?.segmentUsageTypeCode)
      ?.displayName

  if (noProperties) {
    return <NoPropertyAssignedToDealInfo />
  }
  return (
    <FurtherDetailsPieChartsCard
      colorProvider={UsageTypeColorProvider}
      useColors={useUsageTypeColors}
      getRentRollKpis={getUsageTypeRentRollKpis}
      getValuations={() => {}}
      getNameFromKpi={getUsageTypeName}
      granularityValues={{
        multiPropertyKpisByGranularity,
        multiPropertyValuationsByGranularity: {},
      }}
      isPdfView={isPdfView}
    />
  )
}

MultipleFurtherDetailsGranularityUsageTypeTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultipleFurtherDetailsGranularityUsageTypeTile
