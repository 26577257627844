import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Entity from 'components/ui/data/Entity'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import styles from 'routes/business-partners/GroupExposureBusinessPartnerTableColumnCell.module.css'

export const NameCell = ({ cell }) => {
  const id = cell.value
  const { data: { fullName = '' } = {} } = useBusinessPartnerById(id)
  const { t } = useTranslation('translation', { keyPrefix: 'pages.business-partner' })
  return cell.row.original.isTotal ? (
    <div className={styles.highlightTotal}>{t('total')}</div>
  ) : (
    <Entity name={fullName} id={id} link={`/business-partners/${id}`} openInNewTab />
  )
}

NameCell.propTypes = {
  cell: PropTypes.object.isRequired,
}

export const renderCurrency = ({ cell }) => {
  const { isTotal } = cell.row.original

  return <div className={isTotal ? styles.highlightTotal : undefined}>{cell.value}</div>
}

export const GroupExposureBusinessPartnerTableColumnCell = (props) => {
  const formatMoney = useCustomizableCurrencyFormatter()

  return props.renderCell({ cell: props.cell, formatMoney })
}

GroupExposureBusinessPartnerTableColumnCell.propTypes = {
  cell: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  original: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  renderCell: PropTypes.func,
}
