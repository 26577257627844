import { Text } from '@fioneer/ui5-webcomponents-react'
import get from 'lodash.get'
import { useFormContext, useFormState, useWatch } from 'react-hook-form'
import set from 'utils/set'

export const useFormMapped = (existingForm) => {
  const formContext = useFormContext()
  const form = existingForm || formContext
  const { register, getValues, control, setValue, trigger } = form
  const { errors } = useFormState({ control })
  const formValues = {
    ...useWatch({ control }),
    ...getValues(),
  }

  const registerMapped = (
    registerName,
    { handlerMappings = {}, displayAs = (v) => v, errorAsComponent = true, ...options } = {},
  ) => {
    const { onChange, onBlur, name, ref, ...rest } = register(registerName, options)
    const value = displayAs(get(formValues, registerName))
    const fieldError = get(errors, registerName)
    const {
      onChange: onChangeMapping = 'onChange',
      onBlur: onBlurMapping = 'onBlur',
      value: valueMapping = 'value',
      valueState: valueStateMapping = 'valueState',
      valueStateMessage: valueStateMessageMapping = 'valueStateMessage',
      name: nameMapping = 'name',
      ref: refMapping = 'ref',
    } = handlerMappings

    let fieldProps = { ...rest }
    const setMapping = (mapping, handler) => {
      if (typeof mapping === 'string') {
        set(fieldProps, mapping, handler)
        return true
      }
      if (typeof mapping === 'function') {
        fieldProps = { ...fieldProps, ...mapping(handler, form) }
        return true
      }
      return false
    }

    let errorMessage = undefined
    if (fieldError?.message) {
      errorMessage = errorAsComponent ? (
        <Text wrapping>{fieldError?.message}</Text>
      ) : (
        fieldError?.message
      )
    }

    const refExists = setMapping(refMapping, ref)
    setMapping(onBlurMapping, async (e) => {
      const event = e || { target: { name: registerName, value } }
      const result = await onBlur(event)
      await trigger(registerName)
      return result
    })
    setMapping(
      onChangeMapping,
      refExists
        ? onChange
        : async (v) => {
            await setValue(registerName, v)
            await trigger(registerName)
          },
    )
    setMapping(valueMapping, value ?? '')
    setMapping(valueStateMapping, fieldError ? 'Error' : null)
    setMapping(valueStateMessageMapping, errorMessage)
    setMapping(nameMapping, name)

    return fieldProps
  }
  return { ...form, register: registerMapped }
}

export const standardMappings = {
  NativeInput: {
    handlerMappings: {
      valueState: false,
      valueStateMessage: false,
    },
  },
  LoadingSelect: {
    setValueAs: (value) => value || undefined,
    handlerMappings: {
      value: 'selectedKey',
      onChange: (_, { setValue, trigger }) => ({
        onChange: async (e, value) => {
          await setValue(e.target.name, value)
          await trigger(e.target.name)
        },
      }),
      onBlur: (handler) => ({ onBlur: () => handler() }),
    },
  },
}
