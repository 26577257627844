import { Input, ValueState } from '@fioneer/ui5-webcomponents-react'
import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/input/InputWithSearchSuggestions.module.css'

/**
 * Component to render an input field with search suggestions
 *
 * @param initialValue initial string value that is shown in label
 * @param renderSuggestions function that renders suggestions as UI-5 SuggestionItem components
 * @param handleOnChange function that gets called with event as parameter when input value changes
 * @param setSearchKey  function to update the search key when search field input changes
 * @param isSuggestionsError boolean that shows if suggestions loading failed
 */
const InputWithSearchSuggestions = ({
  initialValue,
  renderSuggestions,
  handleOnChange,
  handleOnClear,
  setSearchKey,
  isSuggestionsError,
  errorMessage,
  displayPlaceholderText = true,
  ...props
}) => {
  const { t } = useTranslation()

  const handleOnInputFieldInput = (input) => {
    setSearchKey(input)
    if (input === '') handleOnClear()
  }

  return (
    <Input
      className={styles.inputWithSearchSuggestions}
      showSuggestions
      placeholder={
        displayPlaceholderText
          ? t('components.input-with-search-suggestions.search-input.placeholder')
          : ''
      }
      value={initialValue}
      onInput={(event) => handleOnInputFieldInput(event.target.value)}
      onSuggestionItemSelect={(event) => handleOnChange(event)}
      showClearIcon
      valueState={isSuggestionsError ? ValueState.Error : ValueState.None}
      valueStateMessage={
        <span>
          {t(errorMessage ?? 'components.input-with-search-suggestions.search-input.error-message')}
        </span>
      }
      {...props}
    >
      {!isSuggestionsError && renderSuggestions()}
    </Input>
  )
}

InputWithSearchSuggestions.propTypes = {
  initialValue: PropTypes.string,
  errorMessage: PropTypes.string,
  renderSuggestions: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnClear: PropTypes.func,
  setSearchKey: PropTypes.func.isRequired,
  isSuggestionsError: PropTypes.bool,
  displayPlaceholderText: PropTypes.bool,
}

export default InputWithSearchSuggestions
