import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const usePutPropertyValuations = (options) => {
  const { put } = useAccessTokenRequest()

  const getMoneyOrNullIfNotSet = ({ number, currency }) =>
    currency && number !== null && !isNaN(number)
      ? {
          number: Number(number),
          currency: currency,
        }
      : null

  const assertCorrectMoneyValue = (valuations) =>
    valuations.map((valuation) => ({
      ...valuation,
      value_amount: getMoneyOrNullIfNotSet(valuation.value_amount),
    }))

  return useMutation(async ({ property_uuid, valuations }) => {
    const { data } = await put({
      path: `/properties/${property_uuid}/valuations`,
      body: assertCorrectMoneyValue(valuations),
    })
    return camelize(data)
  }, options)
}
