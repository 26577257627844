import { ButtonDesign, Modals, PopoverPlacementType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/tables/SaveAndCancelButton.module.css'
import { CancelPopoverContent } from 'components/ui/button/CancelButtonWithPopover'
import LoadingButton from 'components/ui/button/LoadingButton'

const saveAndCancelButtonsProps = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  showCancelPopover: PropTypes.bool,
  saveDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  id: PropTypes.string,
}

const SaveAndCancelButton = ({
  onSave = () => {},
  onCancel = () => {},
  showCancelPopover = false,
  saveDisabled = false,
  isLoading = false,
  id = '',
  ...props
}) => {
  const { t } = useTranslation('translation')
  const popoverRef = useRef()
  const showPopover = Modals.useShowPopover()

  const buttonPrefix = id ? `${id}-` : id

  const cancel = () => {
    if (showCancelPopover) {
      popoverRef.current = showPopover({
        opener: `${buttonPrefix}cancel-button`,
        placementType: PopoverPlacementType.Top,
        children: (
          <CancelPopoverContent
            onCancelClicked={() => {
              popoverRef.current.close()
              onCancel()
            }}
          />
        ),
      })
      return
    }
    onCancel()
  }

  return (
    <div {...props} className={styles.buttonContainer}>
      <LoadingButton
        id={`${buttonPrefix}save-button`}
        isLoading={isLoading}
        design={ButtonDesign.Emphasized}
        onClick={onSave}
        disabled={saveDisabled}
        renderContent={() => t('buttons.save')}
      />
      <LoadingButton
        id={`${buttonPrefix}cancel-button`}
        isLoading={isLoading}
        design={ButtonDesign.Transparent}
        onClick={cancel}
        renderContent={() => t('buttons.cancel')}
      />
    </div>
  )
}

SaveAndCancelButton.propTypes = saveAndCancelButtonsProps

export default SaveAndCancelButton
