import { ValueState } from '@fioneer/ui5-webcomponents-react'
import {
  brandingFranchisePartnerExpandedContentKey,
  tenantExpandedContentKey,
} from 'components/domains/properties/getPropertyRentRollWorkingVersionReferenceData'

const appendRowNumberAndExpandFieldsToRentalUnit = (rentalUnits) =>
  rentalUnits.map((rentalUnit, idx) => ({
    ...rentalUnit,
    row_number: idx + 1,
    [tenantExpandedContentKey]: undefined,
    [brandingFranchisePartnerExpandedContentKey]: undefined,
  }))

const appendValidationFields = (row, fieldsWithoutValidation = []) => {
  const mappedRow = {}
  Object.keys(row).forEach((fieldName) => {
    if (fieldsWithoutValidation.includes(fieldName)) {
      mappedRow[fieldName] = row[fieldName]
    } else {
      mappedRow[fieldName] = {
        value: row[fieldName],
        valueState: ValueState.None,
        valueStateMessage: '',
      }
    }
  })
  return mappedRow
}

export const appendValidationFieldsToHeader = (header) => appendValidationFields(header)

export const appendValidationFieldsToRentalUnit = (rentalUnit, fieldsWithoutValidation) =>
  appendValidationFields(rentalUnit, fieldsWithoutValidation)

export const appendValidationFieldsToRentalUnits = (rentalUnits) =>
  rentalUnits.map((rentalUnit) => appendValidationFieldsToRentalUnit(rentalUnit, ['row_number']))

export const initRentRollWorkingVersion = (rentRollWorkingVersion) => {
  if (!rentRollWorkingVersion) return {}

  const { uuid, header, rental_units } = rentRollWorkingVersion

  const headerWithValidationFields = appendValidationFields(header)
  const rentalUnitsWithRowNumberAndValidationFields = appendValidationFieldsToRentalUnits(
    appendRowNumberAndExpandFieldsToRentalUnit(rental_units),
  )

  return {
    uuid,
    header: headerWithValidationFields,
    rental_units: rentalUnitsWithRowNumberAndValidationFields,
    auxiliary_fields: {
      rental_units_length: {
        value: rentalUnitsWithRowNumberAndValidationFields.length,
        valueState: ValueState.None,
        valueStateMessage: '',
      },
    },
  }
}

const getValues = (row, fieldsWithoutValidation = []) => {
  const rowValues = {}
  Object.keys(row).forEach((fieldName) => {
    if (fieldsWithoutValidation.includes(fieldName)) {
      rowValues[fieldName] = row[fieldName]
    } else {
      rowValues[fieldName] = row[fieldName].value
    }
  })
  return rowValues
}

export const getHeaderValues = (header) => getValues(header)

export const getRentalUnitsValues = (rentalUnits) =>
  rentalUnits.map((rentalUnit) => getValues(rentalUnit, ['row_number']))

export const rentalUnitWorkingVersionRowKeyPrefix = 'rental-unit-working-version-row-'
export const getRentalUnitWorkingVersionRowKey = (rowNumber) =>
  `${rentalUnitWorkingVersionRowKeyPrefix}${rowNumber}`

export const getRentalUnitWorkingVersionFieldId = (rowKey, contentKey) => `${rowKey}-${contentKey}`

export const headerWorkingVersionRowKeyPrefix = 'header-working-version-row-'
export const getHeaderWorkingVersionFieldId = (contentKey) =>
  `${headerWorkingVersionRowKeyPrefix}${contentKey}`
