import { FlexBox } from '@fioneer/ui5-webcomponents-react'
import propTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import {
  decisionProcessMinutesDecisionStatus,
  getButtonForDecisionStageMinutesVoteOption,
} from 'api/decision-process/decisionProcessMinutesApi'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import styles from 'components/domains/business-events-and-tasks/decision-stage/minutes/DecisionStageMinutesApprovalHeaderButtons.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useDecisionStageMinutesVoteOptions from 'hooks/services/business-events-and-tasks/decision-process/stages/minutes/useDecisionStageMinutesVoteOptions'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionStageMinutesApprovalHeaderButtons = ({ decisionStageId, decisionStatus }) => {
  const {
    event: { id: eventId },
    allowedOperations,
  } = useContext(BusinessEventsAndTasksContext)
  const hasDecisionStageVotePermissions = allowedOperations.includes(
    businessEventAllowedOperations.updateDecisionStageVote,
  )

  const isDecisionStageMinutesInProgressStatus = useMemo(
    () => decisionStatus === decisionProcessMinutesDecisionStatus.inProgress,
    [decisionStatus],
  )

  const { isLoading, isError, data } = useDecisionStageMinutesVoteOptions(
    {
      eventId,
      decisionStageId,
    },
    { enabled: hasDecisionStageVotePermissions && isDecisionStageMinutesInProgressStatus },
  )

  const renderButtons = useCallback(
    () => (
      <FlexBox className={styles.buttonWrapper}>
        {data.voteOptions.map(({ code }) => {
          const ButtonComponent = getButtonForDecisionStageMinutesVoteOption(code)
          return (
            <ButtonComponent
              key={`vote-option-${code}`}
              eventId={eventId}
              decisionStageId={decisionStageId}
            />
          )
        })}
      </FlexBox>
    ),
    [data, decisionStageId, eventId],
  )

  if (!hasDecisionStageVotePermissions || !isDecisionStageMinutesInProgressStatus) {
    return
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      error=""
      renderContent={renderButtons}
    />
  )
}

DecisionStageMinutesApprovalHeaderButtons.propTypes = {
  decisionStageId: propTypes.string.isRequired,
  decisionStatus: propTypes.oneOf(Object.values(decisionProcessMinutesDecisionStatus)).isRequired,
}

export default DecisionStageMinutesApprovalHeaderButtons
