import PropTypes from 'prop-types'
import { DealCashflowDebtAndEquityCard } from 'components/domains/deals/deal-cashflow/result-charts/DealCashflowDebtAndEquityCard'
import { periodValues } from 'components/domains/deals/deal-cashflow/results/PeriodSelect'
import useCashflowScenarioResultsById from 'hooks/services/deals/cashflow/useCashflowScenarioResultsById'

const DealCashflowDebtAndEquityCardWrapper = ({ dealUuid, cashflowScenarioUuid }) => {
  const {
    data: { calculatedKpis = [], calculatedResults = [] } = {},
    isLoading,
    isError,
  } = useCashflowScenarioResultsById(
    {
      dealUuid,
      cashflowId: cashflowScenarioUuid,
      datasetPeriod: periodValues.quarters,
    },
    { enabled: !!dealUuid && !!cashflowScenarioUuid },
  )

  return (
    <DealCashflowDebtAndEquityCard
      isLoading={isLoading}
      isError={isError}
      calculatedKpis={calculatedKpis}
      calculatedResults={calculatedResults}
    />
  )
}

DealCashflowDebtAndEquityCardWrapper.propTypes = {
  dealUuid: PropTypes.string.isRequired,
  cashflowScenarioUuid: PropTypes.string.isRequired,
}

export default DealCashflowDebtAndEquityCardWrapper
