import isNil from 'lodash.isnil'
import uniq from 'lodash.uniq'
import { useContext } from 'react'
import {
  buildRowsDataRecursive,
  isFinancialProduct,
  isSecondLevelHierarchy,
  trimRowsDeep,
} from 'components/domains/business-partners/tile/arrears/ArrearsUtil'
import { BusinessPartnerArrearsContext } from 'routes/business-partners/arrears/BusinessPartnerArrearsContext'
import {
  ARREARS_HEADQUARTER_CURRENCY,
  ARREAR_APPROVAL_STATUS,
  APPROVAL_EVENT_STATUS,
  ENTITY_TYPES,
  OVERPAYMENT,
} from 'routes/business-partners/arrears/constants'
import set from 'utils/set'

export const ROWHEIGHT_QUAD_CURRENCY = 98
export const ROWHEIGHT_DUAL_CURRENCY = 58
export const ROWHEIGHT_SINGLE_CURRENCY = 32

/**
 * -----------
 * This hook takes the neccessary data from the BusinessPartnerArrearsContext and transforms it
 * into table data rows in a tree structure with the calculated sums for the money values.
 *
 * The tree structures is as follows:
 *  -> Deal
 *    -> n Tranches
 *      -> (Up to 4 Arrears)
 *      -> n Drawings
 *        -> (Up to 4 Arrears)
 *  -> Deal
 *    -> n Tranches
 *      -> (Up to 4 Arrears)
 *      -> n Drawings
 *        -> (Up to 4 Arrears)
 *  -> Deal
 *    ...and so on
 *
 * @returns table data for the arrears table
 */
const useCurrentArrearsTableData = () => {
  const {
    businessPartnerId: businessPartnerId,
    arrears: arrearsFromContext,
    productHierarchy: productHierarchyFromContext,
    arrearApprovals: arrearApprovalsFromContext,
    arrearApprovalEvents: approvalEventsFromContext,
    products: productsFromContext,
    isLoading: isLoadingContext,
    isError: isErrorContext,
  } = useContext(BusinessPartnerArrearsContext)

  const findApprovalEventByApproval = (approval) =>
    approvalEventsFromContext.find((approvalEvent) => approvalEvent.id === approval.eventId)

  const completedApprovals = arrearApprovalsFromContext?.filter((approval) => {
    const approvalEvent = findApprovalEventByApproval(approval)
    return approvalEvent?.status === APPROVAL_EVENT_STATUS.COMPLETED
  })

  const findApprovalItemByApprovalAndArrear = (approval, arrear) =>
    approval.arrearApprovalItems.find(
      (approvalItem) => approvalItem.arrearExternalId === arrear.externalId,
    )

  const isArrearApproved = (arrear) =>
    arrear.approvalStatusList?.includes(ARREAR_APPROVAL_STATUS.APPROVED) ||
    arrear.approvalStatusList?.includes(ARREAR_APPROVAL_STATUS.PARTIALLY_APPROVED)

  const findCompletedApprovalByApprovedArrear = (approvedArrear) =>
    completedApprovals.find(
      (approval) => !!findApprovalItemByApprovalAndArrear(approval, approvedArrear),
    )

  const findApprovedItemByArrearOrNull = (arrear) => {
    if (isArrearApproved(arrear)) {
      const completedArrearApproval = findCompletedApprovalByApprovedArrear(arrear)
      if (completedArrearApproval) {
        return findApprovalItemByApprovalAndArrear(completedArrearApproval, arrear)
      }
    }
    return null
  }

  const findArrearsByRow = ({ entityId, secondaryEntityId, entityType }) => {
    const arrearsByProduct = []
    arrearsFromContext.forEach((arrear) => {
      if (
        (arrear.product.id === entityId || arrear.product.id === secondaryEntityId) &&
        arrear.product.type.code === entityType
      ) {
        arrearsByProduct.push(arrear)
      }
    })
    return arrearsByProduct
  }

  const extendArrearWithDaysOverdue = (arrear) => {
    const ONE_SECOND_IN_MS = 1000
    const ONE_HOUR_IN_SECONDS = 3600
    const ONE_DAY_IN_HOURS = 24
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const bookingDate = new Date(arrear.bookingDate)
    bookingDate.setHours(0, 0, 0, 0)
    const daysOverdue = Math.round(
      (today.getTime() - bookingDate.getTime()) /
        (ONE_SECOND_IN_MS * ONE_HOUR_IN_SECONDS * ONE_DAY_IN_HOURS),
    )
    return {
      ...arrear,
      daysOverdue,
    }
  }

  const extendRowDataWithArrearAndApprovalItem = (row) => {
    const arrearsByRow = findArrearsByRow(row)
    arrearsByRow.forEach((arrear) => {
      const arrearApprovalItemOrNull = findApprovedItemByArrearOrNull(arrear)
      const arrearRow = {
        entityId: arrear.externalId,
        entityType: ENTITY_TYPES.ARREAR,
        rowData: {
          arrear: extendArrearWithDaysOverdue(arrear),
          arrearApprovalItem: arrearApprovalItemOrNull,
          product: {},
        },
        sumData: {},
        subRows: [],
      }
      row.subRows.push(arrearRow)
    })
  }

  const getArrearCurrenciesFromSubRowsRecursive = (subRows, arrearCurrencies = []) => {
    subRows.forEach((subRow) => {
      if (subRow.entityType === ENTITY_TYPES.ARREAR) {
        arrearCurrencies.push(subRow.rowData.arrear.currency)
      }
      getArrearCurrenciesFromSubRowsRecursive(subRow.subRows, arrearCurrencies)
    })
    return arrearCurrencies
  }

  const sumArrearDataHeadquarterAndOriginalRecursive = (
    subRows,
    originalCurrency,
    arrearSumData = {
      arrearAmount: {
        headquarterSum: 0,
        originalSum: 0,
        originalCurrency: originalCurrency,
      },
      arrearAmountOwnShare: {
        headquarterSum: 0,
        originalSum: 0,
        originalCurrency: originalCurrency,
      },
      overpaymentAmount: {
        headquarterSum: 0,
        originalSum: 0,
        originalCurrency: originalCurrency,
      },
    },
  ) => {
    subRows.forEach((subRow) => {
      if (subRow.entityType === ENTITY_TYPES.ARREAR) {
        arrearSumData.arrearAmount.headquarterSum +=
          subRow.rowData.arrear.headquarterArrearAmount ?? 0
        arrearSumData.arrearAmount.originalSum += subRow.rowData.arrear.arrearAmount ?? 0
        arrearSumData.overpaymentAmount.headquarterSum +=
          subRow.rowData.arrear.headquarterOverpaymentAmount ?? 0
        arrearSumData.overpaymentAmount.originalSum += subRow.rowData.arrear.overpaymentAmount ?? 0
        arrearSumData.arrearAmountOwnShare.headquarterSum +=
          subRow.rowData.arrear.headquarterOwnShare ?? 0
        arrearSumData.arrearAmountOwnShare.originalSum += subRow.rowData.arrear.ownShare ?? 0
        if (subRow.rowData.arrear.headquarterOwnShare !== null) {
          arrearSumData.arrearAmountOwnShare.hasOwnShareSum = true
        }
      }
      sumArrearDataHeadquarterAndOriginalRecursive(subRow.subRows, originalCurrency, arrearSumData)
    })
    return arrearSumData
  }

  const sumArrearDataHeadquarterOnlyRecursive = (
    subRows,
    arrearSumData = {
      arrearAmount: {
        headquarterSum: 0,
        originalSum: null,
      },
      arrearAmountOwnShare: {
        headquarterSum: 0,
        originalSum: null,
      },
      overpaymentAmount: {
        headquarterSum: 0,
        originalSum: null,
      },
    },
  ) => {
    subRows.forEach((subRow) => {
      if (subRow.entityType === ENTITY_TYPES.ARREAR) {
        arrearSumData.arrearAmount.headquarterSum +=
          subRow.rowData.arrear.headquarterArrearAmount ?? 0
        arrearSumData.arrearAmountOwnShare.headquarterSum +=
          subRow.rowData.arrear.headquarterOwnShare ?? 0
        arrearSumData.overpaymentAmount.headquarterSum +=
          subRow.rowData.arrear.headquarterOverpaymentAmount ?? 0
        if (subRow.rowData.arrear.headquarterOwnShare !== null) {
          arrearSumData.arrearAmountOwnShare.hasOwnShareSum = true
        }
      }
      sumArrearDataHeadquarterOnlyRecursive(subRow.subRows, arrearSumData)
    })
    return arrearSumData
  }

  const getCurrenciesForSubRowsRecursive = (row, getCurrenciesFn) => {
    const currencies = getCurrenciesFn(row.subRows)
    return uniq(currencies)
  }

  const isConstantCurrencyAndNotHeadquarter = (uniqueCurrencies) =>
    uniqueCurrencies.length === 1 && uniqueCurrencies[0] !== ARREARS_HEADQUARTER_CURRENCY

  const sumArrearDataRecursive = (rowsData) => {
    rowsData.forEach((row) => {
      if (row.subRows.length > 0) {
        const uniqueCurrencies = getCurrenciesForSubRowsRecursive(
          row,
          getArrearCurrenciesFromSubRowsRecursive,
        )
        if (isConstantCurrencyAndNotHeadquarter(uniqueCurrencies)) {
          set(
            row,
            'sumData.arrear',
            sumArrearDataHeadquarterAndOriginalRecursive(row.subRows, uniqueCurrencies[0]),
          )
        } else {
          set(row, 'sumData.arrear', sumArrearDataHeadquarterOnlyRecursive(row.subRows))
        }
        sumArrearDataRecursive(row.subRows)
      }
    })
  }

  const sumArrearApprovalItemDataHeadquarterOnlyRecursive = (
    subRows,
    arrearApprovalItemSumData = {
      approvalAmount: {
        headquarterSum: 0,
      },
    },
  ) => {
    subRows.forEach((subRow) => {
      if (subRow.entityType === ENTITY_TYPES.ARREAR) {
        arrearApprovalItemSumData.approvalAmount.headquarterSum +=
          subRow.rowData.arrearApprovalItem?.approvalAmount ?? 0
      }
      sumArrearApprovalItemDataHeadquarterOnlyRecursive(subRow.subRows, arrearApprovalItemSumData)
    })
    return arrearApprovalItemSumData
  }

  const sumArrearApprovalItemDataHeadquarterAndOriginalRecursive = (
    subRows,
    originalCurrency,
    arrearApprovalItemSumData = {
      approvalAmount: {
        headquarterSum: 0,
        originalSum: 0,
        originalCurrency: originalCurrency,
      },
    },
  ) => {
    subRows.forEach((subRow) => {
      if (subRow.entityType === ENTITY_TYPES.ARREAR) {
        arrearApprovalItemSumData.approvalAmount.headquarterSum +=
          subRow.rowData.arrearApprovalItem?.approvalAmount ?? 0
        arrearApprovalItemSumData.approvalAmount.originalSum +=
          subRow.rowData.arrearApprovalItem?.foreignApprovalAmount ?? 0
      }
      sumArrearApprovalItemDataHeadquarterAndOriginalRecursive(
        subRow.subRows,
        originalCurrency,
        arrearApprovalItemSumData,
      )
    })
    return arrearApprovalItemSumData
  }

  const sumArrearApprovalItemDataRecursive = (rowsData) => {
    rowsData.forEach((row) => {
      if (row.subRows.length > 0) {
        const uniqueCurrencies = getCurrenciesForSubRowsRecursive(
          row,
          getArrearCurrenciesFromSubRowsRecursive,
        )
        if (isConstantCurrencyAndNotHeadquarter(uniqueCurrencies)) {
          set(
            row,
            'sumData.arrearApprovalItem',
            sumArrearApprovalItemDataHeadquarterAndOriginalRecursive(
              row.subRows,
              uniqueCurrencies[0],
            ),
          )
        } else {
          set(
            row,
            'sumData.arrearApprovalItem',
            sumArrearApprovalItemDataHeadquarterOnlyRecursive(row.subRows),
          )
        }
        sumArrearApprovalItemDataRecursive(row.subRows)
      }
    })
  }

  const getProductDataCurrenciesFromSubRowsRecursive = (
    subRows,
    currencyAccessor,
    currencies = [],
  ) => {
    subRows.forEach((subRow) => {
      if (isSecondLevelHierarchy(subRow)) {
        currencies.push(subRow.rowData.product?.[currencyAccessor])
      }
      getProductDataCurrenciesFromSubRowsRecursive(subRow.subRows, currencies)
    })
    return currencies
  }

  const sumProductDataHeadquarterAndOriginalRecursive = (
    subRows,
    originalCurrency,
    accessors,
    productSumData = {
      headquarterSum: 0,
      originalSum: 0,
      originalCurrency: originalCurrency,
    },
    isOwnSharePart,
  ) => {
    subRows.forEach((subRow) => {
      if (isSecondLevelHierarchy(subRow)) {
        productSumData.headquarterSum += subRow.rowData.product?.[accessors.headquarterField] ?? 0
        productSumData.originalSum += subRow.rowData.product?.[accessors.originalField] ?? 0
        if (subRow.rowData.product?.[accessors.headquarterField] !== null && isOwnSharePart) {
          productSumData.hasOwnShareSum = true
        }
      }
      sumProductDataHeadquarterAndOriginalRecursive(
        subRow.subRows,
        originalCurrency,
        accessors,
        productSumData,
        isOwnSharePart,
      )
    })
    return productSumData
  }

  const sumProductDataHeadquarterOnlyRecursive = (
    subRows,
    accessors,
    productSumData = {
      headquarterSum: 0,
      originalSum: null,
    },
    isOwnSharePart,
  ) => {
    subRows.forEach((subRow) => {
      if (isSecondLevelHierarchy(subRow)) {
        productSumData.headquarterSum += subRow.rowData.product?.[accessors.headquarterField] ?? 0
        if (subRow.rowData.product?.[accessors.headquarterField] !== null && isOwnSharePart) {
          productSumData.hasOwnShareSum = true
        }
      }
      sumProductDataHeadquarterOnlyRecursive(
        subRow.subRows,
        accessors,
        productSumData,
        isOwnSharePart,
      )
    })
    return productSumData
  }

  const sumProductDataRecursive = (rowsData, accessors, isOwnSharePart) => {
    rowsData.forEach((row) => {
      if (row.entityType === ENTITY_TYPES.DEAL) {
        const uniqueCurrencies = getCurrenciesForSubRowsRecursive(row, (rows) =>
          getProductDataCurrenciesFromSubRowsRecursive(rows, accessors.currency),
        )
        if (isConstantCurrencyAndNotHeadquarter(uniqueCurrencies)) {
          set(
            row,
            accessors.sumField,
            sumProductDataHeadquarterAndOriginalRecursive(
              row.subRows,
              uniqueCurrencies[0],
              accessors,
              isOwnSharePart,
            ),
          )
        } else {
          set(
            row,
            accessors.sumField,
            sumProductDataHeadquarterOnlyRecursive(row.subRows, accessors, isOwnSharePart),
          )
        }
        sumProductDataRecursive(row.subRows)
      }
    })
  }

  const sumRowsData = (rowsData) => {
    sumArrearDataRecursive(rowsData)
    sumArrearApprovalItemDataRecursive(rowsData)
    sumProductDataRecursive(rowsData, {
      currency: 'currentClosedCommitmentCurrency',
      sumField: 'sumData.product.currentClosedCommitment',
      headquarterField: 'headquarterCurrentClosedCommitment',
      originalField: 'currentClosedCommitment',
    })
    sumProductDataRecursive(rowsData, {
      currency: 'ownShareCurrency',
      sumField: 'sumData.product.currentClosedCommitmentOwnShare',
      headquarterField: 'headquarterCurrentClosedCommitmentOwnShare',
      originalField: 'currentClosedCommitmentOwnShare',
      isOwnSharePart: true,
    })
    sumProductDataRecursive(rowsData, {
      currency: 'ownShareCurrency',
      sumField: 'sumData.product.outstandingOwnShare',
      headquarterField: 'headquarterOutstandingOwnShare',
      originalField: 'outstandingOwnShare',
    })
    sumProductDataRecursive(rowsData, {
      currency: 'ownShareCurrency',
      sumField: 'sumData.product.availableOwnShare',
      headquarterField: 'headquarterAvailableOwnShare',
      originalField: 'availableOwnShare',
    })
  }

  const isDealOrMultiStatus = (row) =>
    row.entityType === ENTITY_TYPES.DEAL || row.rowData.arrear?.approvalStatusList?.length > 1

  const checkAndSetRowHeight = (row) => {
    const isArrearRow = row.entityType === ENTITY_TYPES.ARREAR

    // Helper functions for checkAndSetRowHeight
    const hasArrearAmountOwnShare = (_row) => {
      if (isArrearRow) {
        return !isNil(row.rowData.arrear?.ownShare)
      } else {
        return !!row.sumData.arrear?.arrearAmountOwnShare?.hasOwnShareSum
      }
    }
    const hasCommitmentOwnShare = (_row) => {
      if (isFinancialProduct(_row.entityType)) {
        return !isNil(_row.rowData.product?.currentClosedCommitmentOwnShare)
      } else if (_row.entityType === ENTITY_TYPES.DEAL) {
        return !!row.sumData.product?.currentClosedCommitmentOwnShare?.hasOwnShareSum
      }
      return false
    }

    const isArrearForeignCurrency = isArrearRow
      ? row.rowData.arrear?.currency !== ARREARS_HEADQUARTER_CURRENCY
      : !isNil(row.sumData.arrear?.arrearAmount?.originalSum)
    const isCommitmentForeignCurrency =
      !isArrearRow && !isNil(row.sumData.product?.currentClosedCommitment?.originalSum)

    const hasOwnShare = hasArrearAmountOwnShare(row) || hasCommitmentOwnShare(row)
    const isForeignCurrency = isArrearForeignCurrency || isCommitmentForeignCurrency

    if (hasOwnShare && isForeignCurrency) {
      row.rowHeight = ROWHEIGHT_QUAD_CURRENCY
    } else if (isForeignCurrency || hasOwnShare || isDealOrMultiStatus(row)) {
      row.rowHeight = ROWHEIGHT_DUAL_CURRENCY
    } else {
      row.rowHeight = ROWHEIGHT_SINGLE_CURRENCY
    }
  }

  const extendRowsWithRowHeight = (rowsData) => {
    rowsData.forEach((row) => {
      checkAndSetRowHeight(row)
      extendRowsWithRowHeight(row.subRows)
    })
  }

  const shouldHaveSubRowsOrIsArrear = (row) =>
    !!row.subRows.length || row.entityType === ENTITY_TYPES.ARREAR

  const shouldNotBeOverpayment = (row) =>
    row.entityType !== ENTITY_TYPES.ARREAR || row.rowData.arrear.type.code !== OVERPAYMENT

  const buildRowsData = () => {
    const rowsData = buildRowsDataRecursive({
      businessPartnerId,
      products: productsFromContext,
      productHierarchy: productHierarchyFromContext,
      extendRowsWithAdditionalData: extendRowDataWithArrearAndApprovalItem,
    })
    sumRowsData(rowsData)
    extendRowsWithRowHeight(rowsData)
    const trimmedRowsWithOverpayments = trimRowsDeep(rowsData, shouldHaveSubRowsOrIsArrear)
    const trimmedRowsWithoutOverpayments = trimRowsDeep(
      trimmedRowsWithOverpayments,
      shouldNotBeOverpayment,
    )
    return trimmedRowsWithoutOverpayments
  }

  const result = {
    isLoading: isLoadingContext,
    isError: isErrorContext,
    data: [],
  }

  if (isLoadingContext || isErrorContext) {
    return result
  } else {
    return {
      ...result,
      data: buildRowsData(),
    }
  }
}

export default useCurrentArrearsTableData
