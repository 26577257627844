import { ObjectPageSection } from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import OtherRelationships from 'components/domains/business-partners/OtherRelationships'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import UnitTab from 'components/domains/business-partners/tile/relationships/UnitTab'
import { capitalizeStringWithExclusion } from 'components/domains/util/capitalizeStringWithExclusion'
import TabPage from 'components/ui/page/TabPage'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationships'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

const OTHER_RELATIONSHIPS_KEY = 'other'
const excludeRelationshipWords = ['of', 'the']

const BusinessPartnerRelationships = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'components.business-partner.units' })
  const businessPartner = useContext(BusinessPartnerContext)
  const {
    data: { unitRelationships = [], otherRelationships = [] } = {},
    isLoading,
    isError,
  } = useBusinessPartnerRelationships(businessPartner.id)

  const { t: navigationT } = useTranslation(undefined, { keyPrefix: 'navigation.item.title' })

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={businessPartner.id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [businessPartner.id],
  )

  const renderHeaderTitle = () => (
    <BusinessPartnerHeader
      breadcrumbs={[{ text: navigationT('business-partner-relationships') }]}
      actions={
        <>
          <CreateBusinessPartnerEventAction />
          {markFavoriteAction}
        </>
      }
    />
  )

  return (
    <TabPage
      isError={isError}
      isLoading={isLoading}
      headerTitle={renderHeaderTitle()}
      defaultSectionId={unitRelationships[0]?.id ?? OTHER_RELATIONSHIPS_KEY}
    >
      {unitRelationships.map(({ id, name, relationships }) => (
        <ObjectPageSection
          key={id}
          id={id}
          titleText={capitalizeStringWithExclusion({
            string: name,
            exclusions: excludeRelationshipWords,
          })}
        >
          {relationships.map(({ head, members }) => (
            <UnitTab head={head} members={members} type={name} key={id + head.id} />
          ))}
        </ObjectPageSection>
      ))}
      <ObjectPageSection
        id={OTHER_RELATIONSHIPS_KEY}
        key={OTHER_RELATIONSHIPS_KEY}
        titleText={t('other-relationships')}
      >
        <OtherRelationships relationships={otherRelationships} />
      </ObjectPageSection>
    </TabPage>
  )
}

export default BusinessPartnerRelationships
