import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import { collateralAgreementsSchema } from 'hooks/services/collaterals/collateralAgreementSchema'

/**
 * Get all collateral agreements associated deal id
 * @param {Object} params
 * @param {string[]} [params.ids]
 * @param {boolean} [params.showNewBusiness]
 * @param {Object} [params.options]
 */
const useCollateralAgreementsByTrancheIds = ({
  ids = [],
  showNewBusiness = true,
  options,
} = {}) => {
  const keys = [
    'collateral-agreements',
    'tranche-ids',
    showNewBusiness ? 'new-business' : 'existing-business',
    ...ids,
  ]
  const params = new URLSearchParams()
  params.append('tranche_ids', ids.join(','))
  params.append('show_new_business', showNewBusiness ? 'true' : 'false')

  const result = useRequest({
    path: `/collateral-agreements?${params.toString()}`,
    translated: true,
    options: { enabled: !!ids.length, ...options },
    keys,
  })

  const parsed = useMemo(
    () => collateralAgreementsSchema.optional().safeParse(camelize(result.data)),
    [result.data],
  )

  if (!parsed.success) {
    return { ...result, data: undefined, isError: true, error: parsed.error }
  }

  return { ...result, data: parsed.data }
}

export default useCollateralAgreementsByTrancheIds
