import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import getPropertyRentRollWorkingVersionExcelLocalValueMapping from 'components/domains/properties/rent-roll/working-version/excel-upload/getPropertyRentRollWorkingVersionExcelLocalValueMapping'

export const useDeriveAndSaveExcelValueMappingRules = ({ onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()
  return useMutation(
    async ({ rentRollWorkingVersion }) => {
      const { property_uuids, rental_units } = rentRollWorkingVersion
      const localValueMappingRules =
        getPropertyRentRollWorkingVersionExcelLocalValueMapping(rental_units)
      if (localValueMappingRules.field_mapping_rules.length > 0) {
        const { data } = await put({
          path: '/properties/rent-roll/working-version/local-value-mapping',
          body: {
            property_uuids: property_uuids,
            rent_roll_working_version_field_mapping_dto: { ...localValueMappingRules },
          },
        })
        return data
      }
      return undefined
    },
    {
      onSuccess,
      onError,
    },
  )
}
