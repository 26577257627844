import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateCashflowScenario = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ dealUuid, cashflowScenarioUuid }) => {
      await post({
        path: `/pbb-custom-service/deals/${dealUuid}/cashflow-scenarios`,
        body: snakecaseKeys({ cashflowScenarioUuid }),
      })
    },
    { onSuccess, onError },
  )
}

export default useCreateCashflowScenario
