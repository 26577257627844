import { useMemo } from 'react'
import { isNotFoundError } from 'api/requests'
import useRegulatoryInformation from 'components/domains/business-partners/tile/general-information/regulatoryInformation/useRegulatoryInformations'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import useTranchesWithSubEntities from 'hooks/services/deals/financing/useTranchesWithSubEntities'
import useDeal from 'hooks/services/deals/useDeal'

const calculateSpecialicedLending = (tranches) => {
  if (!tranches || tranches.length === 0) {
    return
  }
  return !tranches.some((tranche) => {
    const specializedLendingValue = tranche.customFields?.customFields?.SpecializedLending?.value
    return specializedLendingValue === false || specializedLendingValue === null
  })
}

const calculateSecurization = (tranches) => {
  if (!tranches || tranches.length === 0) {
    return
  }
  return tranches.some(
    (tranche) => tranche.customFields?.customFields?.Securitization?.value === true,
  )
}

const useCheckSheetRegulatoryRequirementsStatistics = (
  { entityRef: { entityId: dealUuid } },
  tileId,
  tileCode,
) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({
    dealUuid,
    tileCode,
    isWorkingVersionInfoInSourcePath: true,
  })

  const {
    data: dealData,
    isFetching: isDealFetching,
    isError: isDealError,
    error: dealError,
  } = useDeal(dealUuidByTileCode)

  const {
    data: borrowerData,
    isFetching: isBorrowerFetching,
    isError: isBorrowerError,
    error: borrowerError,
  } = useBusinessPartnerById(dealData?.borrowerBpId)

  const {
    data: regulatoryInformationsData,
    isFetching: isRegulatoryInformationsFetching,
    isError: isRegulatoryInformationsError,
    error: regulatoryInformationsError,
  } = useRegulatoryInformation({ businessPartnerId: dealData?.borrowerBpId })

  const {
    isFetching: isTranchesFetching,
    isError: isTranchesError,
    data: tranchesData,
    error: tranchesError,
  } = useTranchesWithSubEntities({ dealUuid: dealUuidByTileCode })

  const noRegulatoryInformationsFoundError =
    regulatoryInformationsError && isNotFoundError(regulatoryInformationsError)

  const normalizedIsRegulatoryInformationsError = useMemo(() => {
    if (isRegulatoryInformationsError) {
      if (isNotFoundError(regulatoryInformationsError)) {
        return false
      }
      return true
    }
    return false
  }, [isRegulatoryInformationsError, regulatoryInformationsError])

  const sourcePathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?working-version=true' : ''),
    [isWorkingVersionDataTile],
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isDealUuidFetching,
      isDealFetching,
      isRegulatoryInformationsFetching,
      isBorrowerFetching,
      isTranchesFetching,
    ],
    errorValues: [
      isDealUuidError,
      isDealError,
      normalizedIsRegulatoryInformationsError,
      isBorrowerError,
      isTranchesError,
    ],
    errorDetails: [dealError, regulatoryInformationsError, borrowerError, tranchesError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        sourceRender: {
          dealDisplayId: dealData.dealId,
          businessPartnerId: dealData.borrowerBpId,
          sourcePathSuffix,
        },
        leveragedTransaction: noRegulatoryInformationsFoundError
          ? null
          : regulatoryInformationsData.leveragedTransaction,
        shadowBankingEntity: noRegulatoryInformationsFoundError
          ? null
          : regulatoryInformationsData.shadowBankingEntity,
        smeIndicator:
          !noRegulatoryInformationsFoundError && regulatoryInformationsData.smeIndicator
            ? {
                value: regulatoryInformationsData.smeIndicator.value,
                currency: regulatoryInformationsData.smeIndicator.currency,
              }
            : null,
        kycLastCheckDate: borrowerData.kyc?.lastCheckDate,
        kycNextCheckDate: borrowerData.kyc?.nextCheckDate,
        securization: calculateSecurization(tranchesData?.tranches),
        specialicedLending: calculateSpecialicedLending(tranchesData?.tranches),
      },
    }
  }, [
    isSomeValueError,
    error,
    isSomeValueLoading,
    dealData,
    regulatoryInformationsData,
    noRegulatoryInformationsFoundError,
    borrowerData,
    tranchesData,
    sourcePathSuffix,
  ])
}

export default useCheckSheetRegulatoryRequirementsStatistics
