const sortDealsByOwnAndExternal = (deals) =>
  [...deals].sort((a, b) => Number(a?.isExternal ?? false) - Number(b?.isExternal ?? false))

export const createDealName = ({
  nonExternalString,
  externalString,
  entityTranslation,
  dealName,
  dealDisplayId,
  isExternal,
  isWorkingVersion,
  workingVersionLabel,
}) =>
  `${dealName} (${entityTranslation} ${dealDisplayId})${
    isWorkingVersion ? ` [${workingVersionLabel}]` : ''
  }${isExternal ? externalString : nonExternalString}`

const mapDeals = (t, deals) =>
  deals.map((deal) => ({
    key: deal?.dealUuid,
    name: createDealName({
      nonExternalString: '',
      externalString: ` - ${t('tiles.annual-review.deal-overview.external')}`,
      entityTranslation: t('entity-types.DEAL'),
      dealName: deal?.dealName,
      dealDisplayId: deal?.dealDisplayId,
      isExternal: deal?.isExternal,
      isWorkingVersion: deal?.isWorkingVersion,
      workingVersionLabel: t('tiles.annual-review.deal-overview.deal-version.working-version'),
    }),
  }))

const createDealSelectOptions = (t, deals) => mapDeals(t, sortDealsByOwnAndExternal(deals))

export default createDealSelectOptions
