import {
  ComboBox,
  ComboBoxItem,
  DatePicker,
  FlexBox,
  FlexBoxDirection,
  Icon,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { eventsAndTasksMassEditOperations } from 'api/events/events'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const BusinessEventsAndTasksMassEditDateSelect = ({ id, onChange, disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.mass-edit-dialog',
  })
  const { localePattern, format: dateFormat, parse } = useShortDateFormatter()
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [currentSelection, setCurrentSelection] = useState(t(`operations.keep`))
  const datePickerRef = useRef()
  const [dueDate, setDueDate] = useState()

  useEffect(() => {
    if (showDatePicker && datePickerRef.current) {
      datePickerRef.current.openPicker()
    }
  }, [showDatePicker, datePickerRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectedOperationChanged = useCallback(
    ({ target }) => {
      const selection = target.getAttribute('value')
      switch (selection) {
        case t(`operations.keep`):
          setShowDatePicker(false)
          setDueDate()
          setCurrentSelection(t(`operations.keep`))
          onChange({ operation: eventsAndTasksMassEditOperations.keep })
          return
        case t(`operations.clear`):
          setShowDatePicker(false)
          setDueDate()
          setCurrentSelection(t(`operations.clear`))
          onChange({ operation: eventsAndTasksMassEditOperations.clear })
          return
      }
    },
    [onChange, t],
  )

  const onDateChanged = useCallback(
    ({ detail: { value: localeDate } }) => {
      const parsedDate = parse(localeDate, localePattern)
      setDueDate(parsedDate)
      onChange({ operation: eventsAndTasksMassEditOperations.replace, value: parsedDate })
      setCurrentSelection(localeDate)
    },
    [localePattern, parse, onChange],
  )

  const comboBoxItems = useMemo(() => {
    const items = [
      <ComboBoxItem
        key="KEEP"
        text={t(`operations.keep`)}
        data-operation={eventsAndTasksMassEditOperations.keep}
      />,
      <ComboBoxItem
        key="CLEAR"
        text={t(`operations.clear`)}
        data-operation={eventsAndTasksMassEditOperations.clear}
      />,
    ]
    if (dueDate) {
      items.push(<ComboBoxItem key="custom-selection" text={dueDate} />)
    }
    return items
  }, [t, dueDate])

  const onDatePickerBlur = useCallback(() => {
    setShowDatePicker(false)
  }, [])

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      {!showDatePicker && (
        <ComboBox
          id={`${id}-combo-box`}
          disabled={disabled}
          value={currentSelection}
          icon={
            <Icon
              id={`${id}-value-help-icon`}
              name="appointment-2"
              onClick={() => {
                setShowDatePicker(true)
              }}
            />
          }
          onChange={handleSelectedOperationChanged}
        >
          {comboBoxItems}
        </ComboBox>
      )}
      {showDatePicker && (
        <DatePicker
          ref={datePickerRef}
          value={dateFormat(dueDate)}
          onChange={onDateChanged}
          formatPattern={localePattern}
          onBlur={onDatePickerBlur}
          placeholder={currentSelection}
        />
      )}
    </FlexBox>
  )
}

BusinessEventsAndTasksMassEditDateSelect.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default BusinessEventsAndTasksMassEditDateSelect
