import {
  FlexBox,
  FlexBoxDirection,
  Label,
  TableRowType,
  FlexBoxAlignItems,
  Link,
} from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getObjectStatusForEventStatus } from 'api/events/status'
import BusinessObjectLink, {
  BusinessObjectLinkCustomRender,
} from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import AssigneeTableCell from 'components/domains/business-events-and-tasks/events/AssigneeTableCell'
import EventOverviewTableCheckbox from 'components/domains/business-events-and-tasks/events/EventOverviewTableCheckbox'
import styles from 'components/domains/business-events-and-tasks/events/dialog/EventSearchTable.module.css'
import DateCell from 'components/ui/tables/cells/DateCell'
import entityStyles from 'components/ui/tables/cells/EntityCell.module.css'
import StatusCell from 'components/ui/tables/cells/StatusCell'
import paths from 'routes/paths'

const EMPTY_VALUE_STRING = '-'

const useEventSearchTableData = (events, selectedEventIds) => {
  const { t: tNoPrefix } = useTranslation()

  const renderBusinessEvent = useCallback(
    ({ eventId, eventName, entityId, entityType, eventDisplayId }) => (
      <FlexBox direction={FlexBoxDirection.Column}>
        <FlexBox
          direction={FlexBoxDirection.Row}
          alignItems={FlexBoxAlignItems.Center}
          className={styles.businessEventLink}
        >
          <Link
            href={`/${paths.businessEventsAndTasks}/business-events/${eventId}`}
            target={'_blank'}
            wrappingType="None"
            className={compact([entityStyles.textWithOverflowHandling, entityStyles.textBold]).join(
              ' ',
            )}
          >
            {eventName}
          </Link>
          <>&nbsp;</>
          {`(${eventDisplayId})`}
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Row}>
          {tNoPrefix('events.business-object')}
          <>&nbsp;</>
          <BusinessObjectLink
            entityId={entityId}
            entityType={entityType}
            customRender={BusinessObjectLinkCustomRender.singleLine}
          />
        </FlexBox>
        {`${tNoPrefix('events.business-object-type')} ${tNoPrefix(
          `events.entity-type.${entityType}`,
        )}`}
      </FlexBox>
    ),
    [tNoPrefix],
  )

  const renderDueDates = useCallback(
    (currentDueDate, originalDueDate) => (
      <FlexBox direction={FlexBoxDirection.Column}>
        {currentDueDate ? <DateCell date={currentDueDate} /> : <>{EMPTY_VALUE_STRING}</>}
        <FlexBox direction={FlexBoxDirection.Row}>
          <Label showColon={true}>
            {tNoPrefix(
              'components.business-events-and-tasks.events.search.dialog.table.label.originalDueDate',
            )}
          </Label>
          &nbsp;
          {originalDueDate ? <DateCell date={originalDueDate} /> : <>{EMPTY_VALUE_STRING}</>}
        </FlexBox>
      </FlexBox>
    ),
    [tNoPrefix],
  )

  const renderStatus = useCallback(
    (status) => {
      const { translationKey: statusTranslationKey, objectStatus } =
        getObjectStatusForEventStatus(status)
      return <StatusCell statusValue={tNoPrefix(statusTranslationKey)} statusState={objectStatus} />
    },
    [tNoPrefix],
  )

  return useMemo(
    () =>
      events.map(
        ({
          id,
          displayId,
          status,
          info: { name, assignee, originalDueDate, currentDueDate },
          entityRef: { entityType, entityId },
        }) => ({
          rowKey: `search-events-table-${id}`,
          rowProperties: {
            type: TableRowType.Active,
            selected: selectedEventIds.includes(id),
            'data-event-id': id,
            'data-event-display-id': displayId,
            'data-event-name': name,
          },
          checkbox: {
            cellComponent: <EventOverviewTableCheckbox eventId={id} status={status} />,
          },
          businessEvent: {
            cellComponent: renderBusinessEvent({
              eventId: id,
              eventName: name,
              entityId,
              entityType,
              eventDisplayId: displayId,
            }),
          },
          assignee: {
            cellComponent: assignee ? (
              <AssigneeTableCell assigneeId={assignee} />
            ) : (
              <Label>{'-'}</Label>
            ),
          },
          currentDueDate: {
            cellComponent: renderDueDates(currentDueDate, originalDueDate),
          },
          status: {
            cellComponent: renderStatus(status),
          },
        }),
      ),
    [events, renderBusinessEvent, renderDueDates, renderStatus],
  )
}

export default useEventSearchTableData
