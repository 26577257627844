import * as L from 'leaflet'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { TileLayer, Polygon, useMap, FeatureGroup, Marker, Popup } from 'react-leaflet'
import CustomPropertyMarkerPopup from 'components/ui/map/internal/CustomPropertyMarkerPopup'
import { MarkerColors } from 'components/ui/map/internal/markers/MarkerColors'

/**
 * Re-centers and zooms into either the center or such
 * that the bounding box fits into the displayed map.
 *
 * @param {*} map the leaflet map
 * @param {*} center position which should be the center of the map
 * @param {*} bounds optional bounding box
 */
const updateCenterAndZoom = (map, center, bounds, zoom) => {
  if (bounds === undefined || bounds.length === 0) {
    map.setView(center, zoom)
  } else {
    map.fitBounds(bounds)
  }
}

const xSize = 30
const xAnchor = xSize / 2
const y = 40
const ySize = y
const yAnchor = y
const DEFAULT_ZOOM = 15

const LeafletTileLayerWrapper = ({
  color,
  polygon,
  center,
  zoom,
  bounds,
  markers,
  isEditMode,
  propertyData,
  propertyIsLoading,
  propertyIsError,
  newPolygonPoints,
  isEditingPolygonEnabled,
  editControl,
  uploadedPolygon,
  disabled = false,
  MarkerPopup = CustomPropertyMarkerPopup,
}) => {
  const map = useMap()
  const IconSize = new L.Point(xSize, ySize)
  const IconAnchor = new L.Point(xAnchor, yAnchor)
  let setZoom
  setZoom = !zoom ? (setZoom = DEFAULT_ZOOM) : (setZoom = zoom) // set a default zoom of 15 if no zoom level is handed in

  useEffect(() => {
    updateCenterAndZoom(map, center, bounds, setZoom)
  }, [map, center, bounds]) //eslint-disable-line react-hooks/exhaustive-deps

  // For some reason the Leaflet Map showed some grey boxes since it seems
  // like Leaflet is not aware about the bounding boxes. Even with the useEffect
  // componentDidMount the map size did not reset. With this timeout we give
  // Leaflet the chance to do all async stuff and afterwards invalidate the size
  // again to match the bounding boxes
  // Note, that after invalidating the size we need to re-center and zoom back in.
  useEffect(() => {
    setTimeout(() => {
      map.invalidateSize()
      updateCenterAndZoom(map, center, bounds, setZoom)
    })
  }, [center, bounds]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (disabled) {
      map._handlers.forEach((handler) => handler.disable())
    }
  })

  const getMarkerIcon = (markerColor) => {
    if (markerColor === MarkerColors.Grey) {
      return new L.Icon({
        iconUrl: require('components/ui/map/internal/markers/grey-marker.png'),
        iconSize: IconSize,
        iconAnchor: IconAnchor,
      })
    }

    return new L.Icon({
      iconUrl: require('components/ui/map/internal/markers/blue-marker.png'),
      iconSize: IconSize,
      iconAnchor: IconAnchor,
    })
  }

  return (
    <>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {
        !isEditMode && <Polygon pathOptions={color} positions={polygon} />
        // this is needed so that the polygon can be edited, the polygon is not found by the feature group otherwise
      }
      <FeatureGroup>
        {
          isEditMode &&
            !uploadedPolygon &&
            polygon.map((singlePolygon, index = 0) => (
              <Polygon key={index} pathOptions={color} positions={singlePolygon} />
            ))
          // this is needed so that the polygon can be edited, the polygon is not found by the feature group otherwise
        }
        {
          isEditMode &&
            uploadedPolygon &&
            uploadedPolygon.map((singlePolygon, index = 0) => (
              <Polygon key={index} pathOptions={color} positions={singlePolygon} />
            ))
          /* this is needed so that the uploaded polygon is shown instead of the previous polygon,
          otherwise the old polygon is displayed but the uploaded polygon would be saved. */
        }
        {markers &&
          markers.map((marker, index = 0) => (
            <Marker
              id={index}
              key={index}
              position={[marker.location.lat, marker.location.lng]}
              icon={getMarkerIcon(marker.color)}
            >
              <Popup>
                <MarkerPopup
                  marker={marker}
                  propertyData={propertyData}
                  propertyIsLoading={propertyIsLoading}
                  propertyIsError={propertyIsError}
                />
              </Popup>
            </Marker>
          ))}
        {
          isEditMode &&
            newPolygonPoints &&
            newPolygonPoints.length === 0 &&
            editControl(true, false)
          // this is needed so that the toolbar does not disappear after creating or uploading a new polygon
        }
        {
          isEditMode &&
            newPolygonPoints &&
            newPolygonPoints.length !== 0 &&
            isEditingPolygonEnabled &&
            editControl(false, true)
          // this is needed so that the toolbar does not disappear after creating or uploading a new polygon
        }
        {
          isEditMode &&
            newPolygonPoints &&
            newPolygonPoints.length !== 0 &&
            !isEditingPolygonEnabled &&
            editControl(false, false)
          // this is needed so that the toolbar does not disappear after creating or uploading a new polygon
        }
      </FeatureGroup>
    </>
  )
}

LeafletTileLayerWrapper.propTypes = {
  color: PropTypes.object,
  polygon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  center: PropTypes.array,
  zoom: PropTypes.number,
  bounds: PropTypes.array,
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      address: PropTypes.exact({
        country: PropTypes.string,
        street: PropTypes.string,
        houseNumber: PropTypes.string,
        zipCode: PropTypes.string,
        city: PropTypes.string,
      }),
      propertyType: PropTypes.string,
      location: PropTypes.exact({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      }),
      color: PropTypes.string,
    }),
  ),
  isEditMode: PropTypes.bool,
  isEditingPolygonEnabled: PropTypes.bool,
  newPolygonPoints: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
      ),
    ),
  ),
  editControl: PropTypes.func,
  propertyData: PropTypes.shape({
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
        financingStatusCode: PropTypes.string,
      }),
    ),
  }),
  propertyIsLoading: PropTypes.bool,
  propertyIsError: PropTypes.bool,
  uploadedPolygon: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
      ),
    ),
  ),
  disabled: PropTypes.bool,
  MarkerPopup: PropTypes.func,
}

export default LeafletTileLayerWrapper
