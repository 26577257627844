import { Text, TextAlign } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TenancyCardTableColumnId,
  TenancyTableRowType,
} from 'components/domains/business-partners/tile/tenancy/tenant-unit-table/AsTenantTableConstants'
import TenancyDealCell from 'components/domains/business-partners/tile/tenancy/tenant-unit-table/table-cells/TenancyDealCell'
import TenancyFinancingStatusCell from 'components/domains/business-partners/tile/tenancy/tenant-unit-table/table-cells/TenancyFinancingStatusCell'
import TenancyPropertySegmentCell from 'components/domains/business-partners/tile/tenancy/tenant-unit-table/table-cells/TenancyPropertySegmentCell'
import TenancyValueWithUnitAndShareCell from 'components/domains/business-partners/tile/tenancy/tenant-unit-table/table-cells/TenancyValueWithUnitAndShareCell'
import { tenancyHeaderInformationValueType } from 'hooks/services/business-partners/tenancy/tenancyHeaderInformationValueType'

const defaultColumnProperties = {
  disableSortBy: true,
  disableFilters: true,
}
const defaultDealColumnWidth = 350

export const useAsTenantCardTableColumns = ({ groupedBy }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.business-partner.tenancy.as-tenant',
  })

  const segmentColumn = useMemo(
    () => ({
      ...defaultColumnProperties,
      Header: t('columns.segment'),
      id: TenancyCardTableColumnId.property,
      accessor: (row) => ({
        rowType: row.rowType,
        deal: row.deal,
        property: row.property,
        segmentUsageType: row.segmentUsageType,
        financingStatusCode: row.financingStatusCode,
      }),
      Cell: TenancyPropertySegmentCell,
      width: groupedBy === TenancyCardTableColumnId.deal ? undefined : defaultDealColumnWidth,
    }),
    [groupedBy, t],
  )
  const financingStatusColumn = useMemo(
    () => ({
      ...defaultColumnProperties,
      Header: () => <Text wrapping>{t('columns.financing-status')}</Text>,
      groupLabel: t('columns.financing-status'),
      sortByLabel: t('columns.financing-status'),
      id: TenancyCardTableColumnId.financingStatus,
      minWidth: 145,
      accessor: ({ property, rowType }, _) => {
        if (
          rowType === TenancyTableRowType.financingStatus ||
          rowType === TenancyTableRowType.property
        ) {
          return property
        }
      },
      isGroupable: true,
      groupByOrder: 2,
      Cell: TenancyFinancingStatusCell,
    }),
    [t],
  )
  const dealColumn = useMemo(
    () => ({
      ...defaultColumnProperties,
      Header: t('columns.deal'),
      id: TenancyCardTableColumnId.deal,
      accessor: (row) => row.deals,
      Cell: TenancyDealCell,
      isGroupable: true,
      groupByOrder: 1,
    }),
    [t],
  )
  const cityColumn = useMemo(
    () => ({
      ...defaultColumnProperties,
      id: 'city',
      Header: t('columns.city'),
      accessor: (row) => row.property?.address?.cityName,
      minWidth: 125,
    }),
    [t],
  )
  const rentedAreaSizeColumn = useMemo(
    () => ({
      ...defaultColumnProperties,
      Header: () => <Text wrapping>{t('columns.rental-area-size')}</Text>,
      groupLabel: t('columns.rental-area-size'),
      sortByLabel: t('columns.rental-area-size'),
      hAlign: TextAlign.End,
      id: TenancyCardTableColumnId.area,
      minWidth: 120,
      accessor: (row) => ({
        kpiValueType: tenancyHeaderInformationValueType.area,
        data: row.rentedArea,
        isBold: row.rowType !== TenancyTableRowType.segment,
      }),
      Cell: TenancyValueWithUnitAndShareCell,
    }),
    [t],
  )
  const rentedAreaPiecesColumn = useMemo(
    () => ({
      ...defaultColumnProperties,
      Header: () => <Text wrapping>{t('columns.rental-area-pieces')}</Text>,
      groupLabel: t('columns.rental-area-pieces'),
      sortByLabel: t('columns.rental-area-pieces'),
      hAlign: TextAlign.End,
      id: TenancyCardTableColumnId.pieces,
      minWidth: 120,
      accessor: (row) => ({
        kpiValueType: tenancyHeaderInformationValueType.pieces,
        data: row.rentedAreaPieces,
        isBold: row.rowType !== TenancyTableRowType.segment,
      }),
      Cell: TenancyValueWithUnitAndShareCell,
    }),
    [t],
  )
  const contractedRentColumn = useMemo(
    () => ({
      ...defaultColumnProperties,
      Header: () => <Text wrapping>{t('columns.contracted-rent')}</Text>,
      groupLabel: t('columns.contracted-rent'),
      sortByLabel: t('columns.contracted-rent'),
      hAlign: TextAlign.End,
      id: TenancyCardTableColumnId.contractedRent,
      minWidth: 165,
      accessor: (row) => ({
        kpiValueType: tenancyHeaderInformationValueType.currency,
        data: row.annualizedRent,
        isBold: row.rowType !== TenancyTableRowType.segment,
      }),
      Cell: TenancyValueWithUnitAndShareCell,
    }),
    [t],
  )
  const waultColumn = useMemo(
    () => ({
      ...defaultColumnProperties,
      id: TenancyCardTableColumnId.wault,
      Header: () => <Text wrapping>{t('columns.wault-break')}</Text>,
      groupLabel: t('columns.wault-break'),
      sortByLabel: t('columns.wault-break'),
      hAlign: TextAlign.End,
      minWidth: 112,
      accessor: (row) => ({
        kpiValueType: tenancyHeaderInformationValueType.years,
        data: row.waultBreak,
        isBold: row.rowType !== TenancyTableRowType.segment,
      }),
      Cell: TenancyValueWithUnitAndShareCell,
    }),
    [t],
  )

  /**
   * @typedef {{isGroupable: boolean, groupLabel: ReactElement}} ColumnGrouping
   * @typedef {import('@fioneer/ui5-webcomponents-react').AnalyticalTableColumnDefinition & ColumnGrouping} ColumnDefinition
   */
  const columns = useMemo(
    () =>
      /** @satisfies {ColumnDefinition[]} */ ([
        segmentColumn,
        financingStatusColumn,
        dealColumn,
        cityColumn,
        rentedAreaSizeColumn,
        rentedAreaPiecesColumn,
        contractedRentColumn,
        waultColumn,
      ]),
    [
      cityColumn,
      contractedRentColumn,
      dealColumn,
      financingStatusColumn,
      rentedAreaPiecesColumn,
      rentedAreaSizeColumn,
      segmentColumn,
      waultColumn,
    ],
  )

  return {
    columns,
  }
}
