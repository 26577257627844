import { useTranslation } from 'react-i18next'
import CardHeaderWithMetrics from 'components/ui/card/CardHeaderWithMetrics'
import useAnnualizedCurrentRentPrimaryMetric from 'hooks/services/properties/kpis/useAnnualizedCurrentRentPrimaryMetric'

import useCurrentRentRollOverviewPropertyKpis from 'hooks/services/properties/kpis/useCurrentRentRollOverviewPropertyKpis'

const PropertyRentRollOverviewExpiringLeasesCardHeader = ({ ...props }) => {
  const { t: tExpiringLeases } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.expiring-leases',
  })

  const {
    isLoading: isLoadingPropertyKpis,
    isError: isErrorPropertyKpis,
    data: propertyKpiData,
  } = useCurrentRentRollOverviewPropertyKpis()

  const primaryMetric = useAnnualizedCurrentRentPrimaryMetric(propertyKpiData)

  const rentRollKeyDate = propertyKpiData?.keyDate

  return (
    <CardHeaderWithMetrics
      title={tExpiringLeases('title')}
      primaryMetric={primaryMetric}
      referenceDate={rentRollKeyDate}
      isError={isErrorPropertyKpis}
      isLoading={isLoadingPropertyKpis}
      {...props}
    />
  )
}

export default PropertyRentRollOverviewExpiringLeasesCardHeader
