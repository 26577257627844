import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import AsTenantCard from 'components/domains/business-partners/tile/tenancy/AsTenantCard'
import TenantUnitTiles from 'components/domains/business-partners/tile/tenancy/TenantUnitTiles'
import AsBrandingOrFranchiseProviderCard from 'components/domains/business-partners/tile/tenancy/as-branding-or-franchise-provider/AsBrandingOrFranchiseProviderCard'
import { useTenancyCardData } from 'components/domains/business-partners/tile/tenancy/useTenancyCardData'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import Page from 'components/ui/page/Page'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationships'
import { useAsBrandingOrFranchiseProviderData } from 'hooks/services/business-partners/useAsBrandingOrFranchiseProviderData'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import BusyIndicatorWithText from 'routes/business-partners/loading/BusyIndicatorWithText'
import paths from 'routes/business-partners/paths'

const tenantRole = { id: 'Tenant' }
const brandOrFranchiseProviderRole = { id: 'Brand-or-Franchise-Provider' }
const businessPartnerRoleBrandingFranchise = 'branding-franchise'

const BusinessPartnerTenancyPage = () => {
  const businessPartner = useContext(BusinessPartnerContext)
  const { t } = useTranslation()

  const businessPartnerFound = () => Boolean(businessPartner?.id)
  const hasRoleTenant = businessPartner?.roles?.some(({ id }) => id === tenantRole.id)
  const hasRoleBrandOrFranchiseProvider = businessPartner?.roles?.some(
    ({ id }) => id === brandOrFranchiseProviderRole.id,
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={businessPartner.id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [businessPartner.id],
  )

  const renderHeaderTitle = () => (
    <BusinessPartnerHeader
      breadcrumbs={[{ text: t('navigation.item.title.business-partner-tenancy'), href: '' }]}
      actions={
        <>
          <CreateBusinessPartnerEventAction />
          {markFavoriteAction}
        </>
      }
    />
  )

  const {
    data: tenancyCardData,
    isFetching: isTenancyCardDataFetching,
    isLoading: isTenancyCardDataLoading,
    isError: isTenancyCardDataError,
  } = useTenancyCardData({ businessPartnerId: businessPartner.id })
  const {
    data: relationshipData,
    isLoading: isRelationshipsLoading,
    isError: isRelationshipsError,
  } = useBusinessPartnerRelationships(businessPartner.id)
  const {
    tableData: asBrandingOrFranchiseProviderTableData = {},
    totalRentInHeadQuarterCurrency,
    totalRentInNonHeadQuarterCurrency,
    totalRentedAreaInHeadQuarterMeasurementUnit,
    totalRentedAreaInNonHeadQuarterMeasurementUnit,
    numberOfRentedProperties,
    isLoading: isBrandingOrFranchiseProviderDataLoading,
    isError: isBrandingOrFranchiseProviderDataError,
  } = useAsBrandingOrFranchiseProviderData(
    businessPartner.id,
    businessPartnerRoleBrandingFranchise,
    hasRoleBrandOrFranchiseProvider,
  )

  const renderContent = () => (
    <>
      {businessPartnerFound() ? (
        <BusyIndicatorWithText
          text={t('pages.business-partner.tenancy.loading-text')}
          title={t('pages.business-partner.tenancy.loading-title')}
          isLoading={
            isTenancyCardDataLoading ||
            isTenancyCardDataFetching ||
            isRelationshipsLoading ||
            (isBrandingOrFranchiseProviderDataLoading && hasRoleBrandOrFranchiseProvider)
          }
          isError={
            isTenancyCardDataError || isRelationshipsError || isBrandingOrFranchiseProviderDataError
          }
          errorToDisplay={<ErrorDataUnavailableInContent />}
          renderContent={() => (
            <div>
              {hasRoleTenant && <AsTenantCard tenancyCardData={tenancyCardData} />}
              {<TenantUnitTiles relationshipData={relationshipData} />}
              {hasRoleBrandOrFranchiseProvider && (
                <AsBrandingOrFranchiseProviderCard
                  tableData={asBrandingOrFranchiseProviderTableData}
                  totalRentInHeadQuarterCurrency={totalRentInHeadQuarterCurrency}
                  totalRentInNonHeadQuarterCurrency={totalRentInNonHeadQuarterCurrency}
                  totalRentedAreaInHeadQuarterMeasurementUnit={
                    totalRentedAreaInHeadQuarterMeasurementUnit
                  }
                  totalRentedAreaInNonHeadQuarterMeasurementUnit={
                    totalRentedAreaInNonHeadQuarterMeasurementUnit
                  }
                  numberOfRentedProperties={numberOfRentedProperties}
                />
              )}
            </div>
          )}
        />
      ) : (
        <>
          {t('pages.business-partner.not-found')}{' '}
          {<NavLink to={paths.businessPartners}>{t('pages.business-partner.search')}</NavLink>}
        </>
      )}
    </>
  )

  return (
    <Page
      isError={false}
      isLoading={false}
      renderHeaderTitle={renderHeaderTitle}
      renderContent={renderContent}
      id="businessPartnerTenancyId"
    />
  )
}

BusinessPartnerTenancyPage.propTypes = {}

export default BusinessPartnerTenancyPage
