import { Text, WrappingType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { QuestionLayout } from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/forbearanceAndUtpQuestionareSchema'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/shared/checksheet/CommentQuestion.module.css'
import Question from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/shared/checksheet/Question'
import questionStyle from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/shared/checksheet/Question.module.css'
import RichTextTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/RichTextTile'

const propTypes = {
  questionKey: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isEditMode: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
}
/**
 * @typedef {object} overrides
 * @property {(newValue: string) => void} [onChange]
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 */
const CommentQuestion = ({
  questionKey,
  label,
  value,
  isEditMode,
  onChange,
  isPdfView,
  isRequired,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.forbearance-and-utp-questionare',
  })

  return (
    <Question
      isEditMode={isEditMode}
      label={label}
      layout={QuestionLayout.COLUMN}
      isRequired={isRequired}
      wrappingType={WrappingType.None}
    >
      {!isEditMode && !value && <Text className={questionStyle.value}>{t('empty-value')}</Text>}
      <div
        className={`${
          isEditMode ? styles.commentQuestionWrapper : styles.commentQuestionWrapperNoEdit
        }`}
      >
        <RichTextTile
          isPdfView={isPdfView}
          isEditMode={isEditMode}
          currentContent={value}
          onChange={(content) => onChange?.(questionKey, content)}
          className={styles.value}
        />
      </div>
    </Question>
  )
}

CommentQuestion.propTypes = propTypes

export default CommentQuestion
