import {
  BusyIndicatorSize,
  IllustratedMessage,
  IllustrationMessageType,
  ObjectPageSection,
} from '@fioneer/ui5-webcomponents-react'
import sortBy from 'lodash.sortby'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import Page from 'components/ui/page/Page'
import TabPage from 'components/ui/page/TabPage'
import LoadingScreen from 'components/ui/screens/LoadingScreen'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useCreatePeriodicalChecks from 'hooks/services/business-partners/periodical-checks/useCreatePeriodicalChecks'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import { useCovenantAllowedOperations } from 'hooks/services/deals/covenants/useCovenantAllowedOperations'
import { useCovenants } from 'hooks/services/deals/covenants/useCovenants'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import { CovenantContext } from 'routes/business-partners/CovenantContext'
import PeriodicalChecksLayout, {
  periodicalChecksLayoutPages,
} from 'routes/business-partners/periodical-checks/periodical-checks-layout/PeriodicalChecksLayout'

const PeriodicalChecksTabs = () => {
  const { id } = useContext(BusinessPartnerContext)
  const { t } = useTranslation()
  const [isCreatePeriodicalChecksLoading, setIsCreatePeriodicalChecksLoading] = useState(true)
  const [isCreatePeriodicalChecksError, setIsCreatePeriodicalChecksError] = useState(false)
  const [mutationTrigger, setMutationTrigger] = useState(false)
  const { hash } = useLocation()
  const [hasNavigatedToHash, setHasNavigatedToHash] = useState(false)
  const navigate = useNavigate()

  const {
    isError: isErrorAllowedOperationsCovenant,
    data: { allowedOperations: covenantAllowedOperations = [] } = {},
  } = useCovenantAllowedOperations(hash?.slice(1))

  const createPeriodicalChecks = useCreatePeriodicalChecks({
    onSuccess: () => setIsCreatePeriodicalChecksLoading(false),
    onError: () => {
      setIsCreatePeriodicalChecksLoading(false)
      setIsCreatePeriodicalChecksError(true)
    },
  })

  useEffect(() => {
    if (mutationTrigger) {
      createPeriodicalChecks.mutate({ bpId: id })
      setMutationTrigger(false)
    }
    // Should be done once, when the component is initialized
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationTrigger])

  /**
   * Because the application runs in Strict Mode, Effects are executed twice in development (https://react.dev/reference/react/StrictMode)
   * As a result, the mutation cannot be called in the useEffect below directly.
   * By updating a state and building a second useEffect on top, it is ensured, that the mutation is only called once.
   */
  useEffect(() => {
    setMutationTrigger(true)
    setIsCreatePeriodicalChecksLoading(true)
  }, [])

  const {
    isFetching: isFetchingCovenants,
    isError: isErrorCovenants,
    data: { covenants = [] } = {},
  } = useCovenants({
    mainEntityId: id,
    mainEntityType: mainEntityTypes.BUSINESSPARTNER,
    options: {
      enabled: !(isCreatePeriodicalChecksLoading || isCreatePeriodicalChecksError),
    },
  })
  const sortedCovenants = sortBy(covenants, ({ covenantDefinition: { index } = {} }) => index)

  useEffect(() => {
    if (hash || hasNavigatedToHash) return
    if (sortedCovenants.length) {
      navigate({ hash: `#${sortedCovenants?.[0]?.covenantUuid}` }, { replace: true })
      setHasNavigatedToHash(true)
    }
  }, [hash, sortedCovenants, navigate, hasNavigatedToHash])

  const isError = useMemo(
    () => isErrorCovenants || isCreatePeriodicalChecksError || isErrorAllowedOperationsCovenant,
    [isCreatePeriodicalChecksError, isErrorCovenants, isErrorAllowedOperationsCovenant],
  )
  const isLoading = useMemo(() => isFetchingCovenants, [isFetchingCovenants])

  const breadcrumbs = useMemo(
    () => (id ? [{ text: t('navigation.item.title.periodical-checks') }] : []),
    [id, t],
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [id],
  )

  // ObjectPageSection must be a direct child of TabPage with no intermediary component.
  // Therefore the RequestStateResolver is partially rebuilt here
  const renderContent = () => {
    if (isError) return <ErrorDataUnavailableInContent />
    if (isLoading) return <LoadingScreen title={''} size={BusyIndicatorSize.Medium} />
    if (covenants?.length === 0)
      return (
        <IllustratedMessage
          name={IllustrationMessageType.NoData}
          titleText={t('components.cards.empty.title')}
          subtitleText={t('components.cards.empty.subtitle')}
        />
      )

    return sortedCovenants.map(({ covenantUuid, covenantDefinition }, index) => (
      <ObjectPageSection key={index} id={covenantUuid} titleText={covenantDefinition?.name}>
        <PeriodicalChecksLayout
          configDefinitionUuid={covenantDefinition?.configCovenantDefinitionUuid}
          currentPage={periodicalChecksLayoutPages.OVERVIEW}
          covenantUuid={covenantUuid}
        />
      </ObjectPageSection>
    ))
  }

  const renderHeader = () => (
    <BusinessPartnerHeader
      actions={[
        <CreateBusinessPartnerEventAction key={'create-event-action'} />,
        markFavoriteAction,
      ]}
      breadcrumbs={breadcrumbs}
    />
  )

  if (isCreatePeriodicalChecksLoading) {
    return (
      <Page
        isError={false}
        isLoading={false}
        renderHeaderTitle={renderHeader}
        renderContent={() => <LoadingScreen title={''} size={BusyIndicatorSize.Medium} />}
        id="businessPartnerPeriodicalChecksLoading"
      />
    )
  }
  return (
    <CovenantContext.Provider value={{ allowedOperations: covenantAllowedOperations }}>
      <TabPage isEmpty={covenants?.length === 0} headerTitle={renderHeader()}>
        {renderContent()}
      </TabPage>
    </CovenantContext.Provider>
  )
}

PeriodicalChecksTabs.propTypes = {}

export default PeriodicalChecksTabs
