import isNil from 'lodash.isnil'
import {
  DEFAULT_DISPLAY_TEXT_GAP,
  DEFAULT_DISPLAY_ROW_HEIGHT,
  ROW_TYPE_COUNTERPARTY,
  DEFAULT_DISPLAY_TEXT_HEIGHT,
  DEFAULT_DISPLAY_COUNTERPARTY_ROW_HEIGHT,
  STATUS_TYPE_CODE_SIGNED,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/constants'
import createSectionHeaderRow from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/createSectionHeaderRow'
import mapCounterpartyRoles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/mapCounterpartyRoles'
import { syndicationTypeCodesForTable } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/syndicationTypeCodes'

const mapCounterparties = (t, counterparties, tranchesData = [], isOpsMemo = false) => {
  if (!counterparties) {
    return []
  }

  const counterpartiesMapped = {}
  counterparties.forEach((counterparty) => {
    const calculatedRowHeight =
      DEFAULT_DISPLAY_ROW_HEIGHT +
      (!isNil(counterparty.amountTotalHeadquarter) &&
      counterparty.amountTotalHeadquarter?.currency !== counterparty.amountTotal?.currency
        ? DEFAULT_DISPLAY_TEXT_HEIGHT
        : 0) +
      (!isNil(counterparty.amountShare)
        ? DEFAULT_DISPLAY_TEXT_HEIGHT + DEFAULT_DISPLAY_TEXT_GAP
        : 0)
    const rootRowId = `${counterparty.syndicationTypeShortText}-${counterparty.businessPartnerId}`

    const content = {
      rowType: ROW_TYPE_COUNTERPARTY,
      rootRowId,
      isInEditMode: false,
      syndicationTypeCode: counterparty.syndicationTypeCode,
      businessPartnerId: counterparty.businessPartnerId,
      subSectionLabel: counterparty.syndicationTypeShortText,
      partyRoleTranche: {
        value: { id: counterparty.businessPartnerId },
        isBold: true,
      },
      commitment: {
        value: counterparty.amountTotal,
        valueHeadquarter: counterparty.amountTotalHeadquarter,
        share: counterparty.amountShare,
        isBold: true,
      },
      status: {
        code: counterparty.statusTypeCode,
        shortText: counterparty.statusTypeShortText,
      },
      signingDate: {
        value: counterparty.signingDate,
        isBold: true,
        isValidDate: true,
      },
      rowHeight: Math.max(calculatedRowHeight, DEFAULT_DISPLAY_COUNTERPARTY_ROW_HEIGHT),
      subRows: mapCounterpartyRoles(
        t,
        counterparty.roles ?? [],
        rootRowId,
        tranchesData,
        isOpsMemo,
      ),
    }

    if (!isOpsMemo || counterparty.statusTypeCode >= STATUS_TYPE_CODE_SIGNED) {
      const syndicationTypeCode = counterparty.syndicationTypeCode
      if (!Object.keys(counterpartiesMapped).includes(syndicationTypeCode)) {
        counterpartiesMapped[syndicationTypeCode] = []
      }
      counterpartiesMapped[syndicationTypeCode].push(content)
    }
  })

  const result = []
  syndicationTypeCodesForTable.forEach((syndicationTypeCode) => {
    result.push(createSectionHeaderRow(t, syndicationTypeCode))
    if (Object.keys(counterpartiesMapped).includes(syndicationTypeCode)) {
      result.push(...counterpartiesMapped[syndicationTypeCode])
    }
  })
  return result
}

export default mapCounterparties
