import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCopyConditions = (mutateOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(async ({ conditionIds, conditionType }) => {
    const { data } = await post({
      path: `/conditions/${conditionType}/bulk-copy`,
      body: {
        condition_ids: conditionIds,
      },
    })
    return data
  }, mutateOptions)
}

export default useCopyConditions
