import z from 'zod'
import { valueHelpEntrySchema } from 'hooks/services/valueHelpsSchema'

export const moneySchema = z.object({
  value: z.number(),
  currency: z.string(),
})

/** @typedef {import('zod').TypeOf<typeof moneySchema>} Money */

const regulatoryInformationSchema = z.object({
  federalBankId: z.string().nullish(),
  companyClassification: valueHelpEntrySchema.nullish(),
  smeIndicator: moneySchema.nullish(),
  leveragedTransaction: z.boolean().nullish(),
  mifidClassification: valueHelpEntrySchema.nullish(),
  emirClassification: valueHelpEntrySchema.nullish(),
  amlRiskClassification: valueHelpEntrySchema.nullish(),
  shadowBankingEntity: z.boolean().nullish(),
})

/** @typedef {import('zod').TypeOf<typeof regulatoryInformationSchema>} RegulatoryInformation */

export default regulatoryInformationSchema
