import { ComboBox, ComboBoxItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionsMassEditOperations } from 'api/conditions/conditions'

const ConditionsMassEditSelectWithInput = ({
  selectableOperations,
  preselectedOperation,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.dialogs.mass-edit',
  })

  const options = useMemo(
    () =>
      selectableOperations.map((operation) => (
        <ComboBoxItem
          key={operation}
          data-operation={operation}
          text={t(`operations.${operation}`)}
        />
      )),
    [selectableOperations, t],
  )

  const handleChange = useCallback(
    (event) => {
      const operation = event.detail?.item?.getAttribute('data-operation')
      const value = event.target.getAttribute('value')
      if (selectableOperations.includes(operation)) {
        onChange({ operation, value })
        return
      }
      onChange({ operation: conditionsMassEditOperations.replace, value })
    },
    [selectableOperations, onChange],
  )

  return (
    <ComboBox
      onSelectionChange={handleChange}
      onInput={handleChange}
      value={preselectedOperation ? t(`operations.${preselectedOperation}`) : ''}
      disabled={disabled}
    >
      {options}
      <ComboBoxItem text="" />
    </ComboBox>
  )
}

ConditionsMassEditSelectWithInput.propTypes = {
  selectableOperations: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(conditionsMassEditOperations)),
  ),
  onChange: PropTypes.func.isRequired,
  preselectedOperation: PropTypes.oneOf(Object.values(conditionsMassEditOperations)),
  disabled: PropTypes.bool,
}

export default ConditionsMassEditSelectWithInput
