import { CardHeader } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import EventsSummaryCard from 'components/domains/business-events-and-tasks/events/events-summary-card/EventsSummaryCard'
import styles from 'components/domains/deals/covenant-check-detail/historical-events/HistoricalMonitoringEventsCard.module.css'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useEventsByIdList from 'hooks/services/business-events-and-tasks/events/useEventsByIdList'
import useCovenantCheckHistoricalEvents from 'hooks/services/deals/covenants/checks/useCovenantCheckHistoricalEvents'

const HistoricalMonitoringEventsCard = ({ covenantCheckUuid }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.historical-events',
  })
  const {
    data: { historicalEvents } = {},
    isFetching: isFetchingHistoricalEventIds,
    isError: isErrorHistoricalEventIds,
  } = useCovenantCheckHistoricalEvents({ covenantCheckUuid })

  const covenantCheckHistoricalEventIds = useMemo(
    () => historicalEvents?.map((event) => event.eventId) || [],
    [historicalEvents],
  )
  const {
    data: eventData = [],
    isFetching: isFetchingEvents,
    isError: isErrorEvents,
  } = useEventsByIdList(covenantCheckHistoricalEventIds)

  const isLoading = useMemo(
    () => isFetchingHistoricalEventIds || isFetchingEvents,
    [isFetchingEvents, isFetchingHistoricalEventIds],
  )
  return (
    <Card header={<CardHeader titleText={t('title')} />}>
      <div className={isLoading ? styles.loadingCardContent : ''}>
        <RequestStateResolver
          isLoading={isLoading}
          isError={isErrorHistoricalEventIds || isErrorEvents}
          errorToDisplay={<ErrorDataUnavailableInContent />}
          renderContent={() => <EventsSummaryCard events={eventData} />}
          center
        />
      </div>
    </Card>
  )
}

HistoricalMonitoringEventsCard.propTypes = {
  covenantCheckUuid: PropTypes.string,
}

export default HistoricalMonitoringEventsCard
