import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import DecisionStageLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/components/domains/business-events-and-tasks/decision-stage/DecisionStageLeaveConfirmationLink'
import Entity from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/components/ui/data/Entity'
import paths from 'routes/paths'

const BusinessEventWithoutTypeTableCell = ({
  eventName,
  eventId,
  eventDisplayId,
  showConfirmationForLinks,
}) => {
  const eventHref = `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}`

  return showConfirmationForLinks ? (
    <FlexBox direction={FlexBoxDirection.Column}>
      <DecisionStageLeaveConfirmationLink name={eventName} link={eventHref} />
      {eventDisplayId}
    </FlexBox>
  ) : (
    <Entity name={eventName} link={eventHref} id={eventDisplayId} />
  )
}

BusinessEventWithoutTypeTableCell.propTypes = {
  eventName: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  eventDisplayId: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
}

export default BusinessEventWithoutTypeTableCell
