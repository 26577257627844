import {
  Select,
  Option,
  FlexBox,
  FlexBoxAlignItems,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import useCheckCycleTranslations from 'components/domains/deals/covenants/covenants-table/useCheckCycleTranslations'
import styles from 'components/domains/deals/covenants/monitoring-information-card/CheckCycleInputSelect.module.css'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'

const EMPTY = ''

const CheckCycleInputSelect = ({
  value,
  onChange,
  isModifiable = true,
  selectableCheckCycles = [],
}) => {
  const { CheckCycleTranslations } = useCheckCycleTranslations()
  const { t } = useTranslation('translation')

  const { number, cycle } = value ?? {}

  const onChangeInternal = (changedValue) => {
    onChange({
      number,
      cycle,
      ...changedValue,
    })
  }

  const cycleOptions = selectableCheckCycles?.length
    ? selectableCheckCycles
    : Object.keys(CheckCycleTranslations)

  return (
    <FlexBox alignItems={FlexBoxAlignItems.Center} className={styles.container}>
      <div>{t('pages.deals.covenants.every')}</div>
      <FormattedNumberInput
        minimumFractionDigits={0}
        maximumFractionDigits={0}
        className={styles.numberInput}
        value={number}
        valueState={!number ? ValueState.Error : ValueState.None}
        onChange={(parsedNumber) => onChangeInternal({ number: parsedNumber })}
        readonly={!isModifiable}
      />
      <Select
        valueState={!cycle ? ValueState.Error : ValueState.None}
        onChange={(event) => onChangeInternal({ cycle: event.detail.selectedOption.value })}
        disabled={!isModifiable}
      >
        <Option value={EMPTY} selected={isNil(cycle)}>
          {EMPTY}
        </Option>
        {cycleOptions.map((checkCycle) => (
          <Option key={checkCycle} value={checkCycle} selected={cycle === checkCycle}>
            {CheckCycleTranslations[checkCycle] ?? ''}
          </Option>
        ))}
      </Select>
    </FlexBox>
  )
}

CheckCycleInputSelect.propTypes = {
  value: PropTypes.shape({
    number: PropTypes.number,
    cycle: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  isModifiable: PropTypes.bool,
  selectableCheckCycles: PropTypes.arrayOf(PropTypes.string),
}

export default CheckCycleInputSelect
