import { BusyIndicatorSize, Input, ValueState } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessPartnerSearchInput from 'components/domains/business-partners/search-input/BusinessPartnerSearchInput'
import { ReferenceEntityType } from 'components/ui/data/ReferenceEntityType'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import useDeal from 'hooks/services/deals/useDeal'

const useMonitoringReference = ({ type, id }) => {
  const {
    data: deal = {},
    isLoading: isLoadingDeal,
    isFetching: isFetchingDeal,
    isError: isErrorDeal,
  } = useDeal(id, { enabled: !!id && type === ReferenceEntityType.Deal })

  const {
    data: businessPartner = {},
    isLoading: isLoadingBusinessPartner,
    isFetching: isFetchingBusinessPartner,
    isError: isErrorBusinessPartner,
  } = useBusinessPartnerById(id, {
    enabled: !!id && type === ReferenceEntityType.BusinessPartner,
  })

  const calculateDataAndLoadingState = useCallback(() => {
    if (type === ReferenceEntityType.Deal) {
      return { isLoading: isLoadingDeal && isFetchingDeal, isError: isErrorDeal, data: deal }
    }
    if (type === ReferenceEntityType.BusinessPartner) {
      return {
        isLoading: isLoadingBusinessPartner && isFetchingBusinessPartner,
        isError: isErrorBusinessPartner,
        data: businessPartner,
      }
    }
    return {
      isLoading: false,
      isError: false,
      data: {},
    }
  }, [
    businessPartner,
    deal,
    isErrorBusinessPartner,
    isErrorDeal,
    isFetchingBusinessPartner,
    isFetchingDeal,
    isLoadingBusinessPartner,
    isLoadingDeal,
    type,
  ])

  return { ...calculateDataAndLoadingState() }
}

const MonitoringReferenceInput = ({ type, id, onChange, ...props }) => {
  const { t } = useTranslation('translation')

  const supportedTypes = [ReferenceEntityType.Deal, ReferenceEntityType.BusinessPartner]
  const { isLoading, isError, data } = useMonitoringReference({ type, id })

  const [searchInputValue, setSearchInputValue] = useState({ id: id })

  useEffect(() => {
    setSearchInputValue({ id: id })
  }, [id])
  const handleBusinessPartnerInputChange = (businessPartner) => {
    setSearchInputValue(businessPartner)
    // onChange is called by BusinessPartnerSearchInput on every input but with an empty string as id
    if (!isEmpty(businessPartner.id)) {
      onChange(businessPartner.id)
    }
  }

  if (!supportedTypes.includes(type)) {
    return <Input readonly valueState={ValueState.Error} />
  }

  return (
    <RequestStateResolver
      isLoading={isLoading}
      isError={isError}
      errorToDisplay={<Input readonly value={t('pages.deals.covenants.data-error')} {...props} />}
      busyIndicatorSize={BusyIndicatorSize.Small}
      renderContent={() => {
        if (type === ReferenceEntityType.Deal)
          return <Input readonly value={`${data.name} (${data.dealId})`} {...props} />

        if (type === ReferenceEntityType.BusinessPartner)
          return (
            <BusinessPartnerSearchInput
              value={searchInputValue}
              onChange={handleBusinessPartnerInputChange}
              isRequired
            />
          )
      }}
    />
  )
}

MonitoringReferenceInput.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default MonitoringReferenceInput
