import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const RETRIES = 3

const useEventMini = (eventId) =>
  useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/mini`,
      useCache: true,
      keys: ['events', eventId, 'mini'],
      options: {
        retry: (failureCount, error) => {
          if (isNotFoundError(error) || isMissingPermissionError(error)) {
            return false
          }
          if (failureCount < RETRIES) {
            return true
          }
          return false
        },
      },
    }),
  )

export default useEventMini
