import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultipleCashflowScenarioResultsById = ({
  dealUuidCashflowIdPairs,
  datasetPeriod,
  options = {},
}) => {
  const queryParams = new URLSearchParams({ dataset_period: datasetPeriod })

  const responses = useRequestsMemo(
    useRequests({
      requests: dealUuidCashflowIdPairs.map(({ dealUuid, cashflowId }) => ({
        path: `/deals/${dealUuid}/cashflow-scenarios/${cashflowId}/cashflow?${queryParams.toString()}`,
        keys: [
          'deals',
          dealUuid,
          'cashflow-scenarios',
          cashflowId,
          'cashflow',
          queryParams.toString(),
        ],
      })),
      translated: true,
      useCache: true,
      options: { enabled: !isEmpty(dealUuidCashflowIdPairs), ...options },
    }),
  )

  return useMemo(
    () =>
      responses.map((response, index) => ({
        ...response,
        data: {
          dealUuid: dealUuidCashflowIdPairs[index].dealUuid,
          cashflowId: dealUuidCashflowIdPairs[index].cashflowId,
          cashflowScenario: camelize(response.data),
        },
      })),
    [dealUuidCashflowIdPairs, responses],
  )
}

export default useMultipleCashflowScenarioResultsById
