import { useRequest } from 'hooks/services/baseService'

// We can not camelize the response since metadata might contain e.g. uuids as keys
// and camelizing would destroy them.
const useTileMetaData = ({ eventId, tileId }) =>
  useRequest({
    path: `/events/${eventId}/decision-paper/versions/current/tiles/${tileId}/meta-data`,
    useCache: true,
    keys: [
      'events',
      eventId,
      'decision-paper',
      'versions',
      'current',
      'tiles',
      tileId,
      'meta-data',
    ],
  })

export default useTileMetaData
