import {
  Button,
  Dialog,
  FlexBox,
  FlexBoxDirection,
  Label,
  Title,
  Icon,
  FlexBoxJustifyContent,
  ButtonDesign,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import 'components/ui/dialog/ErrorDialog.css'
import '@ui5/webcomponents-icons/dist/error.js'
import { createPortal } from 'react-dom'

/**
 * @deprecated: Use `MessageBox` from `@fioneer/ui5-webcomponents-react` instead. https://fioneer.atlassian.net/browse/CWP-13200
 *
 * Generic error dialog to display when an error has occurred.
 * @param {Function} onRetry Function to retry the call that caused the error, does not render retry button if undefined
 * @param {boolean} isOpen whether the dialog is open
 * @param {Function} setIsOpen function to set the dialog open settings
 * @param {object} texts translated texts
 * @param {string} id id to disting buttons
 */
const ErrorDialog = ({ onRetry, isOpen, setIsOpen, texts, id = '' }) => {
  const goToTryAgain = () => {
    setIsOpen(false)
    onRetry()
  }

  const goToCancel = () => {
    setIsOpen(false)
  }

  return createPortal(
    <Dialog
      id={`dialog-error${id && `-${id}`}`}
      open={isOpen}
      onAfterClose={goToCancel}
      header={
        <FlexBox
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
          justifyContent={FlexBoxJustifyContent.Start}
          direction={FlexBoxDirection.Row}
          fitContainer
        >
          <Icon name="error" style={{ color: 'red', paddingTop: '5px', paddingRight: '5px' }} />
          <Title level="H2" value-state="Error">
            {texts.title}
          </Title>
        </FlexBox>
      }
      footer={
        <div id="error-button-div">
          <FlexBox direction={FlexBoxDirection.Row}>
            <FlexBox style={{ paddingRight: '5px' }}>
              {onRetry ? (
                <Button
                  id={`error-button-try-again${id && `-${id}`}`}
                  onClick={goToTryAgain}
                  design={ButtonDesign.Emphasized}
                >
                  {texts.retryButton}
                </Button>
              ) : (
                <></>
              )}
            </FlexBox>
            <Button id={`error-button-cancel${id && `-${id}`}`} onClick={goToCancel}>
              {texts.cancelButton}
            </Button>
          </FlexBox>
        </div>
      }
    >
      <FlexBox direction={FlexBoxDirection.Column} style={{ width: '500px' }}>
        <Label style={{ paddingBottom: '16px' }} wrapping-type="Normal">
          {texts.description}
        </Label>
      </FlexBox>
    </Dialog>,
    document.body,
  )
}

ErrorDialog.propTypes = {
  onRetry: PropTypes.func,
  texts: PropTypes.shape({
    retryButton: PropTypes.string,
    cancelButton: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default ErrorDialog
