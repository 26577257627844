import camelize from 'camelize'
import { useMemo } from 'react'
import { isNotFoundError } from 'api/requests'
import { useRequests } from 'hooks/services/baseService'
import { staffMembersSchema } from 'hooks/services/business-partners/staff-members/staffMemberSchema'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const UUID_REG_EXP = '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$'
const uuidRegExp = RegExp(UUID_REG_EXP, 'i') // i = ignore case, as the UUID_REG_EXP does not contain A-Z
const isUuid = (idOrEmail) => !!idOrEmail && uuidRegExp.test(idOrEmail)
const isEmail = (idOrEmail) => !!idOrEmail && idOrEmail.includes('@')

/**
 * Get multiple staff members by their object ids or email addresses. The list may consist of
 * a mix of email addresses and object ids.
 *
 * @param {object} data
 * @param {string[]} data.objectIdOrEmailAddressList
 * @param {object} [options]
 */
const useMultipleStaffMemberByObjectIdOrEmail = ({ objectIdOrEmailAddressList }, options) => {
  //deduplicate
  const uniqueObjectIdOrEmailAddressList = Array.from(new Set(objectIdOrEmailAddressList))
  const validObjectIdOrEmailAddressList = uniqueObjectIdOrEmailAddressList.filter(
    (objectIdOrEmail) => isUuid(objectIdOrEmail) || isEmail(objectIdOrEmail),
  )
  const requests = validObjectIdOrEmailAddressList.map((objectIdOrEmail) => {
    let searchType
    if (isUuid(objectIdOrEmail)) {
      searchType = 'OIDS'
    } else if (isEmail(objectIdOrEmail)) {
      searchType = 'EMAIL'
    } else {
      // Unreachable due to filter above
      searchType = 'INVALID'
    }
    const params = new URLSearchParams()
    params.append('searchType', searchType)
    params.append('q', objectIdOrEmail)
    return {
      keys: ['users', searchType, objectIdOrEmail],
      path: `/users?${params.toString()}`,
      requestOptions: {
        isThrottled: true,
      },
    }
  })

  const responseList = useRequestsMemo(
    useRequests({
      requests: requests,
      useCache: true,
      options: options,
    }),
  )

  const isSomeDataLoading = useMemo(
    () => responseList.some(({ isLoading }) => isLoading),
    [responseList],
  )
  const isSomeDataFetching = useMemo(
    () => responseList.some(({ isFetching }) => isFetching),
    [responseList],
  )
  const isSomeDataError = useMemo(() => responseList.some(({ isError }) => isError), [responseList])
  const isAllError = useMemo(
    () => (responseList.length !== 0 ? responseList.every(({ isError }) => isError) : false),
    [responseList],
  )

  const isNameNotFoundError = useMemo(
    () =>
      responseList.length !== 0
        ? responseList.some(({ error }) => error && isNotFoundError(error))
        : false,
    [responseList],
  )

  // Map requested object ids or emails to StaffMembers
  // response array has same order as request array https://tanstack.com/query/v4/docs/react/reference/useQueries
  // return validObjectIdOrEmailAddressList.reduce((acc, objectIdOrEmail, index) => {
  return useMemo(
    () => ({
      isSomeLoading: isSomeDataLoading,
      isSomeFetching: isSomeDataFetching,
      isSomeError: isSomeDataError,
      isAllError: isAllError,
      isNameNotFoundError: isNameNotFoundError,
      data: validObjectIdOrEmailAddressList.reduce((acc, objectIdOrEmail, index) => {
        acc[objectIdOrEmail] =
          staffMembersSchema.parse(camelize(responseList[index]?.data))?.staffMembers?.[0] ?? null
        return acc
      }, /** @type {{[objectIdOrEmail: string]: import('hooks/services/business-partners/staff-members/staffMemberSchema').StaffMember | null}} */ ({})),
    }),
    [
      responseList,
      isAllError,
      isSomeDataError,
      isNameNotFoundError,
      isSomeDataLoading,
      isSomeDataFetching,
      validObjectIdOrEmailAddressList,
    ],
  )
}

export default useMultipleStaffMemberByObjectIdOrEmail
