import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import BusinessEventsAndTasksMassEditDateSelect from 'components/domains/business-events-and-tasks/mass-edit/BusinessEventsAndTasksMassEditDateSelect'

const BusinessEventsAndTasksMassEditOriginalDueDateSelect = ({ onChange, disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.mass-edit-dialog',
  })

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label>{t('labels.original-due-date')}</Label>
      <BusinessEventsAndTasksMassEditDateSelect
        onChange={onChange}
        disabled={disabled}
        id="event-mass-edit-original-due-date"
      />
    </FlexBox>
  )
}

BusinessEventsAndTasksMassEditOriginalDueDateSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default BusinessEventsAndTasksMassEditOriginalDueDateSelect
