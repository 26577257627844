import { Label, WrappingType } from '@fioneer/ui5-webcomponents-react'
import { number, shape, string } from 'prop-types'
import { useDealCashFlowNumberFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/i18n/useDealCashFlowI18n'

export const DealCashFlowFormattedParameterValueText = ({
  id,
  className,
  parameter: { value, unit, decimalPlaces } = {},
}) => {
  const formatNumber = useDealCashFlowNumberFormatter({
    minimumFractionDigits: decimalPlaces ?? 0,
    maximumFractionDigits: decimalPlaces ?? 0,
  })

  return (
    <Label id={id} className={className} wrappingType={WrappingType.Normal}>
      {formatNumber(value)} {unit}
    </Label>
  )
}

DealCashFlowFormattedParameterValueText.propTypes = {
  id: string,
  className: string,
  parameter: shape({
    value: number,
    unit: string,
    decimalPlaces: number,
  }).isRequired,
}
