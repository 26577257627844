import { useEffect, useState } from 'react'
import downloadDocument from 'components/domains/documents/downloadDocument'
import useConfirmationDialogState from 'components/domains/documents/useConfirmationDialogState'
import useEloDownload from 'hooks/services/markets/useEloDownload'

/**
 * @param {object} params
 * @param {string} params.guid
 * @param {string} params.fileName
 * @param {string} [params.versionGuid]
 * @param {string} [documentName]
 * @param {string} [version]
 */
const useDownloadDocument = ({ guid, fileName, versionGuid = '', documentName, version }) => {
  // HINT: this is not set to false when clicking cancel, since we've already made the request anyways
  const [shouldFetchDocument, setShouldFetchDocument] = useState(false)
  const [shouldDownloadDocument, setShouldDownloadDocument] = useState(false)

  const { isLoading, isError, data, isFetching } = useEloDownload({
    documentId: guid,
    versionGuid,
    enabled: shouldFetchDocument,
  })

  useEffect(() => {
    if (!data) return
    if (!shouldDownloadDocument) return

    setShouldDownloadDocument(false)
    downloadDocument({ guid, fileName, body: data, versionGuid, documentName, version })
  }, [data, fileName, guid, shouldDownloadDocument, versionGuid, documentName, version])

  const handleConfirm = () => {
    setShouldDownloadDocument(true)
  }

  const handleOpen = () => {
    setShouldFetchDocument(true)
  }

  const handleCancel = () => {
    setShouldDownloadDocument(false)
  }

  // HINT: we could split this up, but downloading a document should always trigger a confirmation dialog
  const {
    handleCancel: handleConfirmationDialogCancel,
    handleConfirm: handleConfirmationDialogConfirm,
    handleOpen: handleConfirmationDialogOpen,
    isOpen,
  } = useConfirmationDialogState({
    onConfirm: handleConfirm,
    onOpen: handleOpen,
    onCancel: handleCancel,
  })

  return {
    isLoading: isLoading && isFetching,
    isError,
    handleConfirmationDialogOpen,
    handleConfirmationDialogCancel,
    /**
     * Please respect `isLoading` / `isError` before calling this function.
     * If you don't, nothing might happen
     */
    handleConfirmationDialogConfirm,
    isConfirmationDialogOpen: isOpen,
  }
}

export default useDownloadDocument
