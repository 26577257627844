import find from 'lodash.find'
import isEqual from 'lodash.isequal'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { conditionsEntityTypes, conditionTypes } from 'api/conditions/conditions'
import {
  availableFilterTypes,
  filterTypes,
} from 'components/domains/conditions/overview/ConditionsTableToolbarFilterDialog'
import ConditionsTableActionsCell from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableActionsCell'
import ConditionsTableAssigneeCell from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableAssigneeCell'
import ConditionsTableCategoryCell from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableCategoryCell'
import ConditionsTableExternalVisibilitiesCell from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableExternalVisibilitiesCell'
import ConditionsTableInternalApprovalCell from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableInternalApprovalLevelCell'
import ConditionsTableNameCell from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableNameCell'
import ConditionsTableRefNumberCell from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableRefNumberCell'
import ConditionsTableReferencesCell from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableReferencesCell'
import ConditionsTableReferencesDecisionPaperCell from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableReferencesDecisionPaperCell'
import ConditionsTableStatusCell from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableStatusCell'
import ConditionsTableTypeCell from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableTypeCell'
import TableWatcherCell from 'components/domains/conditions/overview/table-cells/generic/TableWatcherCell'
import { useLanguage, useShortDateFormatter } from 'hooks/i18n/useI18n'
import useApprovalLevels from 'hooks/services/conditions/config/useApprovalLevels'
import useExternalStatus from 'hooks/services/conditions/config/useExternalStatus'
import useExternalTypes from 'hooks/services/conditions/config/useExternalTypes'
import useInternalStatus from 'hooks/services/conditions/config/useInternalStatus'
import useInternalTypes from 'hooks/services/conditions/config/useInternalTypes'
import useVisibilities from 'hooks/services/conditions/config/useVisibilities'
import { CONDITIONS_CELL_WIDTHS } from 'hooks/services/conditions/tables/contants'
import {
  getValueFromQueryParams,
  conditionFilterPrefixes,
} from 'hooks/services/conditions/tables/useConditionFilterQuery'

const cellDefaults = {
  disableDragAndDrop: true,
  disableGlobalFilter: true,
  disableFilters: true,
  disableSortBy: true,
  disableGroupBy: true,
  isSelectableForHiding: true,
  sortingDisabled: false,
}

const defaultSorting = {
  [conditionTypes.external]: {
    sortDescending: false,
    sortingKey: [
      'condition_status',
      'condition_type',
      'condition_category',
      'condition_ref_number',
      'condition_name',
    ],
  },
  [conditionTypes.internal]: {
    sortBy: 'type',
    sortDescending: false,
    sortingKey: 'condition_type',
  },
}

const getActionCellWidth = (currentLanguage, isEditModeForAnyRow) => {
  if (isEditModeForAnyRow && currentLanguage.includes('de')) {
    return CONDITIONS_CELL_WIDTHS.ACTIONS_EDIT_GERMAN
  } else if (isEditModeForAnyRow) {
    return CONDITIONS_CELL_WIDTHS.ACTIONS_EDIT_ENGLISH
  }
  return CONDITIONS_CELL_WIDTHS.ACTIONS_DISPLAY
}

const calculateInitialColumnSelection = (columns, defaultHiddenColumns) =>
  columns.map((column) => {
    const preparedColumn = {
      ...column,
      isVisible: true,
      columnKey: column.id,
      title: column.Header,
    }
    if (defaultHiddenColumns.includes(column.id)) {
      return { ...preparedColumn, isVisible: false }
    }
    return preparedColumn
  })

const calculateInitialFilterSelection = (columnSelection, queryParams) =>
  columnSelection
    .filter(({ filter }) => !!filter)
    .map(
      ({
        columnKey,
        filter: { type, useLoadingHook, selectionName, filterKey, label, buildLoadingHookParams },
        Header,
      }) => ({
        columnKey,
        type,
        filterKey,
        value: getValueFromQueryParams({
          type,
          filterKey,
          prefix: conditionFilterPrefixes.conditions,
          queryParams,
        }),
        emptyValue: filterTypes[type].emptyValue,
        label: label ?? Header,
        useLoadingHook,
        buildLoadingHookParams,
        selectionName,
      }),
    )

const filterColumnsBasedOnEntityType =
  (entityType) =>
  ({ onlyVisibleForEntityTypes }) => {
    if (!onlyVisibleForEntityTypes) {
      return true
    }
    return onlyVisibleForEntityTypes.includes(entityType)
  }

const emptyArray = []

const useConditionTableColumns = ({
  shownColumns,
  defaultHiddenColumns = emptyArray,
  conditionType = conditionTypes.external,
  entityType,
  tileVersion,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.conditions.table' })
  const { format: formatIsoDate } = useShortDateFormatter()
  const [queryParams] = useSearchParams()

  const editedRowConditionId = useSelector(
    (state) => state.conditions.conditionsTable.editedRow.conditionId,
  )
  const isEditModeForAnyRow = useMemo(() => !!editedRowConditionId, [editedRowConditionId])
  const currentLanguage = useLanguage()

  const buildDefaultLoadingHookParams = useCallback(
    () => ({ entityType, includeDeprecated: true }),
    [entityType],
  )

  const allColumns = useMemo(
    () => ({
      name: {
        ...cellDefaults,
        Header: t('columns.name'),
        accessor: ({ id: conditionId, info: { name } }) => ({ name, conditionId }),
        id: 'name',
        minWidth: 220,
        sortingKey: 'condition_name',
        Cell: ConditionsTableNameCell,
        filter:
          conditionType === conditionTypes.external
            ? {
                type: availableFilterTypes.condition,
                filterKey: 'externalConditionId',
                label: t('filter.condition'),
              }
            : undefined,
      },
      category: {
        ...cellDefaults,
        Header: t('columns.category'),
        accessor: ({ id: conditionId, info: { category } }) => ({ category, conditionId }),
        id: 'category',
        sortingKey: 'condition_category',
        Cell: ConditionsTableCategoryCell,
      },
      type: {
        ...cellDefaults,
        Header: t('columns.type'),
        accessor: ({
          id: conditionId,
          info: {
            type: { name: typeName, code: typeCode },
          },
        }) => ({ typeName, typeCode, conditionId }),
        id: 'type',
        filter: {
          type: availableFilterTypes.loadingDropdownSingleSelect,
          useLoadingHook:
            conditionType === conditionTypes.internal ? useInternalTypes : useExternalTypes,
          buildLoadingHookParams: buildDefaultLoadingHookParams,
          selectionName: 'types',
          filterKey: 'type',
        },
        sortingKey: 'condition_type',
        Cell: ConditionsTableTypeCell,
      },
      refNumber: {
        ...cellDefaults,
        Header: t('columns.ref-number'),
        accessor: ({ id: conditionId, info: { refNumber } }) => ({ refNumber, conditionId }),
        id: 'refNumber',
        sortingKey: 'condition_ref_number',
        Cell: ConditionsTableRefNumberCell,
      },
      approvalLevel: {
        ...cellDefaults,
        Header: t('columns.approval-level'),
        accessor: ({ id: conditionId, info: { approvalLevel } }) => ({
          approvalLevelName: approvalLevel?.name,
          approvalLevelCode: approvalLevel?.code,
          conditionId,
        }),
        id: 'approvalLevel',
        filter: {
          type: availableFilterTypes.loadingDropdownSingleSelect,
          useLoadingHook: useApprovalLevels,
          buildLoadingHookParams: buildDefaultLoadingHookParams,
          selectionName: 'approvalLevels',
          filterKey: 'approvalLevel',
        },
        sortingKey: 'condition_approval_level',
        Cell: ConditionsTableInternalApprovalCell,
      },
      references: {
        ...cellDefaults,
        Header: t('columns.references'),
        accessor: ({ id, references }) => ({
          id,
          references,
        }),
        id: 'references',
        filter: {
          type: availableFilterTypes.referencesMultiSelect,
          filterKey: 'references',
        },
        Cell: ConditionsTableReferencesCell,
        sortingDisabled: true,
        minWidth: 250,
      },
      // This is a custom cell just for the decision paper since loading the entityNames differs for the decision paper and the
      // live external conditions on the conditions page
      referencesDecisionPaper: {
        ...cellDefaults,
        Header: tileVersion === '1' ? t('columns.reference') : t('columns.references'),
        accessor: ({ id, references, businessObjectRef }) => ({
          id,
          references,
          businessObjectRef,
        }),
        id: 'referencesDecisionPaper',
        Cell: ConditionsTableReferencesDecisionPaperCell,
        filter: {
          type: availableFilterTypes.referencesMultiSelect,
          filterKey: 'references',
        },
        sortingDisabled: true,
      },
      visibilities: {
        ...cellDefaults,
        Header: t('columns.visibilities'),
        accessor: ({ id: conditionId, info: { visibilities } }) => ({ visibilities, conditionId }),
        id: 'visibilities',
        filter: {
          type: availableFilterTypes.loadingDropdownMultiComboBox,
          useLoadingHook: useVisibilities,
          buildLoadingHookParams: buildDefaultLoadingHookParams,
          selectionName: 'visibilities',
          filterKey: 'visibilities',
        },
        Cell: ConditionsTableExternalVisibilitiesCell,
        sortingDisabled: true,
        minWidth: 120,
      },
      assignee: {
        ...cellDefaults,
        Header: t('columns.assignee'),
        accessor: ({ id, info: { assignee } }) => ({ id, assignee }),
        id: 'assignee',
        filter: {
          type: availableFilterTypes.assignee,
          filterKey: 'assignee',
        },
        minWidth: 160,
        Cell: ConditionsTableAssigneeCell,
        sortingDisabled: true,
      },
      covenantCheck: {
        ...cellDefaults,
        Header: t('columns.covenant-check'),
        id: 'covenantCheck',
        filter: {
          type: availableFilterTypes.covenantCheck,
          filterKey: 'covenantCheck',
        },
        minWidth: 90,
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
      externalAssignee: {
        ...cellDefaults,
        Header: t('columns.external-assignee'),
        id: 'externalAssignee',
        onlyVisibleForEntityTypes: [conditionsEntityTypes.deal],
        filter: {
          type: availableFilterTypes.externalAssignee,
          filterKey: 'externalAssignee',
        },
        minWidth: 100,
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
      creationDate: {
        ...cellDefaults,
        Header: t('columns.creation-date'),
        accessor: ({ info: { creationDate } }) => formatIsoDate(creationDate),
        id: 'creationDate',
        filter: {
          type: availableFilterTypes.betweenDates,
          filterKey: 'creationDate',
        },
        sortingKey: 'condition_creation_date',
        isSelectableForHiding: false,
      },
      status: {
        ...cellDefaults,
        Header: t('columns.status'),
        accessor: ({ status: { type, name, code }, requirements, id: conditionId }) => ({
          status: { type, name, code },
          requirements,
          conditionId,
        }),
        id: 'status',
        filter: {
          type: availableFilterTypes.loadingDropdownMultiComboBox,
          useLoadingHook:
            conditionType === conditionTypes.internal ? useInternalStatus : useExternalStatus,
          buildLoadingHookParams: buildDefaultLoadingHookParams,
          selectionName: 'status',
          filterKey: 'status',
        },
        minWidth: 100,
        sortingKey: 'condition_status',
        Cell: ConditionsTableStatusCell,
      },
      watcher: {
        ...cellDefaults,
        Header: '',
        accessor: ({ id: conditionId, watchers }) => ({
          watchers,
          conditionId,
          watcherType: conditionType,
        }),
        id: 'watcher',
        Cell: TableWatcherCell,
        width: CONDITIONS_CELL_WIDTHS.WATCHER,
        disableResizing: true,
        isSelectableForHiding: false,
        sortingDisabled: true,
      },
      actions: {
        ...cellDefaults,
        Header: '',
        accessor: '',
        id: 'actions',
        Cell: ConditionsTableActionsCell,
        width: getActionCellWidth(currentLanguage, isEditModeForAnyRow),
        disableResizing: true,
        isSelectableForHiding: false,
        sortingDisabled: true,
      },
    }),
    [
      t,
      conditionType,
      buildDefaultLoadingHookParams,
      tileVersion,
      currentLanguage,
      isEditModeForAnyRow,
      formatIsoDate,
    ],
  )

  const columns = useMemo(
    () =>
      shownColumns
        .map((columnKey) => allColumns[columnKey])
        .filter(filterColumnsBasedOnEntityType(entityType)),
    [allColumns, entityType, shownColumns],
  )

  const [columnSelection, setColumnSelection] = useState(
    calculateInitialColumnSelection(columns, defaultHiddenColumns),
  )

  useEffect(() => {
    const alteredColumnSelection = columnSelection.map((column) => {
      if (column.id === 'actions') {
        return {
          ...column,
          width: getActionCellWidth(currentLanguage, isEditModeForAnyRow),
        }
      }
      return column
    })
    setColumnSelection(alteredColumnSelection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentLanguage,
    isEditModeForAnyRow,
    //columnSelection <-- is left out on purpose to not trigger an infinite loop
  ])

  const [filters, setFilters] = useState(
    calculateInitialFilterSelection(columnSelection, queryParams),
  )
  const [searchParam, setSearchParam] = useState('')
  const [sorting, setSorting] = useState(defaultSorting[conditionType])
  const sortableColumns = useMemo(
    () => columnSelection.filter(({ sortingDisabled }) => !sortingDisabled),
    [columnSelection],
  )

  const onUpdateSorting = useCallback(
    ({ sortBy, sortDescending }) => {
      const selectedColumn = find(sortableColumns, ['title', sortBy])
      setSorting({
        sortBy: selectedColumn?.columnKey ?? defaultSorting[conditionType].sortBy,
        sortDescending,
        sortingKey: selectedColumn?.sortingKey ?? defaultSorting[conditionType].sortingKey,
      })
    },
    [sortableColumns, conditionType],
  )

  const mappedBackendFilters = useMemo(() => {
    const calculatedFilters = {}
    filters.forEach(({ filterKey, value, emptyValue }) => {
      if (isEqual(value, emptyValue)) {
        return
      }
      if (filterKey === 'creationDate') {
        if (value.lowerBound) {
          calculatedFilters['creationDateFrom'] = value.lowerBound
        }
        if (value.upperBound) {
          calculatedFilters['creationDateUntil'] = value.upperBound
        }
        return
      }
      if (filterKey === 'covenantCheck') {
        calculatedFilters['covenantCheck'] = value.covenantCheckUuid
        return
      }
      calculatedFilters[filterKey] = value
    })
    if (searchParam) {
      calculatedFilters.searchFilter = searchParam
    }
    return calculatedFilters
  }, [filters, searchParam])

  return useMemo(
    () => ({
      columns,
      columnSelection,
      setColumnSelection,
      filters,
      setFilters,
      sortableColumns,
      sorting,
      onUpdateSorting,
      searchParam,
      setSearchParam,
      mappedBackendFilters,
    }),
    [
      columns,
      columnSelection,
      filters,
      setFilters,
      sorting,
      onUpdateSorting,
      sortableColumns,
      searchParam,
      setSearchParam,
      mappedBackendFilters,
    ],
  )
}

export default useConditionTableColumns
