import { t } from 'i18next'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import createSectionHeaderRow from 'routes/deals/syndication/syndicationStructure/utils/backendToTableStructure/createSectionHeaderRow'
import {
  DEFAULT_DISPLAY_ROW_HEIGHT,
  DEFAULT_DISPLAY_TEXT_GAP,
  DEFAULT_DISPLAY_TEXT_HEIGHT,
  ROW_TYPE_OWN_SHARE,
  ROOT_ROW_ID_OWN_SHARE,
  ROOT_ROW_ID_TOTAL,
} from 'routes/deals/syndication/syndicationStructure/utils/constants'

const mapTotal = (totalCommitment, totalCommitmentHeadquarter, currency, currencyHeadquarter) => ({
  rootRowId: ROOT_ROW_ID_TOTAL,
  partyRoleTranche: {
    value: t('pages.deals.syndicationStructure-table.subSection.deal-label'),
    isBold: true,
  },
  commitment: {
    value: { amount: totalCommitment, currency },
    valueHeadquarter: { amount: totalCommitmentHeadquarter, currency: currencyHeadquarter },
    isBold: true,
  },
  rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT,
})

const mapOwnShare = (
  ownCommitment,
  ownCommitmentHeadquarter,
  currency,
  currencyHeadquarter,
  ownCommitmentShare,
) => ({
  rowType: ROW_TYPE_OWN_SHARE,
  rootRowId: ROOT_ROW_ID_OWN_SHARE,
  partyRoleTranche: {
    value: t(
      'components.business-events-and-tasks.decision-paper.tiles.drawdown-deal-structure.columns.commitment.rows.own-share',
    ),
    isBold: true,
  },
  commitment: {
    value: { amount: ownCommitment, currency },
    valueHeadquarter: { amount: ownCommitmentHeadquarter, currency: currencyHeadquarter },
    share: ownCommitmentShare,
    isBold: true,
  },
  rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT + DEFAULT_DISPLAY_TEXT_HEIGHT + DEFAULT_DISPLAY_TEXT_GAP,
})

export const mapTranchesToTableStructure = (data) => {
  if (isNil(data) || isEmpty(data?.summaryHeadquarter)) {
    return []
  }
  const { summary, summaryHeadquarter } = data
  const hasSummary = !isNil(summary)
  const { totalCommitment, currency } = hasSummary ? summary : summaryHeadquarter
  const { totalCommitment: totalCommitmentHeadquarter, currency: currencyHeadquarter } =
    summaryHeadquarter
  const { ownCommitment, ownCommitmentShare } = hasSummary ? summary : summaryHeadquarter
  const { ownCommitment: ownCommitmentHeadquarter } = summaryHeadquarter

  return [
    createSectionHeaderRow('total'),
    mapTotal(totalCommitment, totalCommitmentHeadquarter, currency, currencyHeadquarter),
    createSectionHeaderRow('ownShare'),
    mapOwnShare(
      ownCommitment,
      ownCommitmentHeadquarter,
      currency,
      currencyHeadquarter,
      ownCommitmentShare,
    ),
  ]
}
