import {
  BusyIndicator,
  BusyIndicatorSize,
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxDirection,
  Label,
  Popover,
  PopoverPlacementType,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { neededOperationsForDecisionPaperUpdate } from 'api/events/eventAllowedOperations'
import { isEventClosed } from 'api/events/status'
import { hasUserRequiredOperations } from 'api/helper'
import DecisionPaperTileHeader from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileHeader'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/DecisionPaperManualTileHeader.module.css'
import StaffMemberByObjectIdOrEmail from 'components/ui/staff-member/StaffMemberByObjectIdOrEmail'
import LastEditedText from 'components/ui/text/LastEditedText'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const hasDecisionPaperUpdatePermissions = (allowedOperations) =>
  hasUserRequiredOperations(neededOperationsForDecisionPaperUpdate, allowedOperations)

const DecisionPaperManualTileHeader = ({
  titleText,
  tileStatus,
  isExpanded,
  isPdfView,
  isEditMode,
  isEditEnabled,
  onEditClicked,
  onSaveClicked,
  hasChanges,
  isSaveEnabled = true,
  showSource = true,
  showLastEdited = true,
  allowCollapse = true,
  onCancelClicked,
  onCollapse,
  onExpand,
  currentVersion,
  additionalEditActions,
}) => {
  const {
    event: { status: eventStatus },
    allowedOperations,
  } = useContext(BusinessEventsAndTasksContext)
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.tiles.rich-text',
  })

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const showEditButton = useMemo(
    () => hasDecisionPaperUpdatePermissions(allowedOperations) && !isEventClosed(eventStatus),
    [allowedOperations, eventStatus],
  )

  const handleEditClicked = useCallback(
    (clickEvent) => {
      if (!isExpanded) onExpand()
      onEditClicked(clickEvent)
    },
    [isExpanded, onExpand, onEditClicked],
  )

  const editButton = useMemo(
    () =>
      showEditButton && (
        <Button
          key="tile-edit-button"
          onClick={handleEditClicked}
          design={ButtonDesign.Transparent}
        >
          {tNoPrefix('buttons.edit')}
        </Button>
      ),
    [handleEditClicked, tNoPrefix, showEditButton],
  )

  const handleCancel = useCallback(() => {
    if (!hasChanges) {
      onCancelClicked()
      return
    }
    setIsPopoverOpen(true)
  }, [hasChanges, onCancelClicked, setIsPopoverOpen])

  const onAfterClose = useCallback(() => {
    setIsPopoverOpen(false)
  }, [setIsPopoverOpen])

  const onButtonClick = useCallback(() => {
    onCancelClicked()
    setIsPopoverOpen(false)
  }, [onCancelClicked, setIsPopoverOpen])

  const cancelButton = useMemo(
    () => (
      <div key="cancel-button-wrapper">
        {hasChanges &&
          createPortal(
            <Popover
              open={isPopoverOpen}
              onAfterClose={onAfterClose}
              opener="cancel-button"
              placementType={PopoverPlacementType.Top}
            >
              <FlexBox direction={FlexBoxDirection.Column}>
                <Text wrapping className={styles.cancelPopoverText}>
                  {t('cancel.confirmation')}
                </Text>
                <Button onClick={onButtonClick}>{tNoPrefix('buttons.discard')}</Button>
              </FlexBox>
            </Popover>,
            document.body,
          )}
        <Button id="cancel-button" onClick={handleCancel} design={ButtonDesign.Transparent}>
          {tNoPrefix('buttons.cancel')}
        </Button>
      </div>
    ),
    [hasChanges, isPopoverOpen, handleCancel, t, tNoPrefix, onAfterClose, onButtonClick],
  )

  const isSaveButtonDisabled = useMemo(
    () => !hasChanges || !isSaveEnabled,
    [hasChanges, isSaveEnabled],
  )

  const saveButton = useMemo(
    () => (
      <Button
        key="save-button"
        onClick={onSaveClicked}
        design={ButtonDesign.Emphasized}
        disabled={isSaveButtonDisabled}
      >
        {tNoPrefix('buttons.save')}
      </Button>
    ),
    [onSaveClicked, tNoPrefix, isSaveButtonDisabled],
  )

  const actions = useMemo(() => {
    if (isPdfView) {
      return
    }
    if (!isEditEnabled) {
      return additionalEditActions
    }
    if (isEditMode) {
      return [saveButton, cancelButton]
    }
    return [...(additionalEditActions ?? []), editButton]
  }, [
    additionalEditActions,
    saveButton,
    cancelButton,
    editButton,
    isEditMode,
    isEditEnabled,
    isPdfView,
  ])

  const customSource = useMemo(() => {
    if (!tileStatus || tileStatus.isLoading) {
      return (
        <Label>
          {showSource && t('source')}
          <BusyIndicator active delay={0} size={BusyIndicatorSize.Small} />
        </Label>
      )
    }
    if (tileStatus.isError || !tileStatus.data?.lastUpdatedBy || !tileStatus.data?.lastUpdatedAt) {
      return showSource ? <Label>{t('source-no-edited')}</Label> : <></>
    }
    return (
      <>
        {showLastEdited && (
          <LastEditedText
            preText={showSource ? t('source') : ''}
            lastUpdatedAt={tileStatus.data.lastUpdatedAt}
            wrapping
          >
            <StaffMemberByObjectIdOrEmail objectIdOrEmail={tileStatus.data.lastUpdatedBy} />
          </LastEditedText>
        )}
      </>
    )
  }, [t, showSource, tileStatus, showLastEdited])

  return (
    <DecisionPaperTileHeader
      titleText={titleText}
      isPdfView={isPdfView}
      isExpanded={isExpanded}
      customSource={customSource}
      additionalActions={actions}
      onCollapse={onCollapse}
      onExpand={onExpand}
      allowCollapse={!isEditMode && allowCollapse}
      currentVersion={currentVersion}
    />
  )
}

DecisionPaperManualTileHeader.propTypes = {
  titleText: PropTypes.string.isRequired,
  tileStatus: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      lastUpdatedBy: PropTypes.string,
      lastUpdatedAt: PropTypes.string,
    }),
  }),
  isPdfView: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isEditEnabled: PropTypes.bool.isRequired,
  onEditClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  isSaveEnabled: PropTypes.bool,
  allowCollapse: PropTypes.bool,
  showSource: PropTypes.bool,
  showLastEdited: PropTypes.bool,
  onCancelClicked: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  currentVersion: PropTypes.string,
  additionalEditActions: PropTypes.array,
}

export default DecisionPaperManualTileHeader
