import {
  ROW_TYPE_TOTAL,
  TABLE_ROW_TOTAL_ROW_HEIGHT,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/constants'

const mapTotalValues = (t, totalValues) => {
  const importedColumns = (existing, pipeline, total) => ({
    source: [
      t('pages.business-partner.authority-level-calculator.table.columns.source.existing'),
      t('pages.business-partner.authority-level-calculator.table.columns.source.pipeline'),
      t('pages.business-partner.authority-level-calculator.table.columns.source.total'),
    ],
    amount: [
      {
        amount: existing?.amount?.amount,
        currency: existing?.amount?.currency,
      },
      {
        amount: pipeline?.amount?.amount,
        currency: pipeline?.amount?.currency,
      },
      {
        amount: total?.amount?.amount,
        currency: total?.amount?.currency,
      },
    ],
    pdRegulatory: [existing?.pdRegulatory, pipeline?.pdRegulatory, total?.pdRegulatory],
    pdRegulatoryClass: [
      existing?.pdRegulatoryClass,
      pipeline?.pdRegulatoryClass,
      total?.pdRegulatoryClass,
    ],
    lgdRegulatory: [existing?.lgdRegulatory, pipeline?.lgdRegulatory, total?.lgdRegulatory],
  })

  const adjustedColumns = (total) => ({
    adjustedAmount: [
      null,
      null,
      {
        amount: total?.adjustedAmount?.amount,
        currency: total?.adjustedAmount?.currency,
      },
    ],
    adjustedAmountInAlcCurrency: [
      null,
      null,
      {
        amount: total?.adjustedAmountInAlcCurrency?.amount,
        currency: total?.adjustedAmountInAlcCurrency?.currency,
      },
    ],
    adjustedPdRegulatory: [null, null, total?.adjustedPdRegulatory],
    adjustedLgdRegulatory: [null, null, total?.adjustedLgdRegulatory],
  })

  const calculatedColumns = (existing, pipeline, total) => ({
    pdCalculation: [null, null, total?.pdCalculation],
    pdRegulatoryClassCalculation: [null, null, total?.pdCalculationClass],
    lgdCalculation: [null, null, total?.lgdCalculation],
    elUnweighted: [
      null,
      null,
      {
        amount: total?.elUnweighted?.amount,
        currency: total?.elUnweighted?.currency,
      },
    ],
    elWeighted: [
      null,
      null,
      {
        amount: total?.elWeighted?.amount,
        currency: total?.elWeighted?.currency,
      },
    ],
    elBps: [null, null, total?.elRate],
    creditAuthorityLimit: [
      {
        amount: existing?.creditAuthorityLimit?.amount,
        currency: existing?.creditAuthorityLimit?.currency,
      },
      {
        amount: pipeline?.creditAuthorityLimit?.amount,
        currency: pipeline?.creditAuthorityLimit?.currency,
      },
      {
        amount: total?.creditAuthorityLimit?.amount,
        currency: total?.creditAuthorityLimit?.currency,
      },
    ],
    elClass: [existing?.elClass, pipeline?.elClass, total?.elClass],
  })

  return {
    rowHeight: TABLE_ROW_TOTAL_ROW_HEIGHT,
    isSectionHeader: true,
    rowType: ROW_TYPE_TOTAL,
    businessPartnerProductClass: t(
      'pages.business-partner.authority-level-calculator.table.columns.source.total',
    ),
    ...importedColumns(
      totalValues?.totalExistingValues?.totalImportedExistingValues,
      totalValues?.totalPipelineValues?.totalImportedPipelineValues,
      totalValues?.totalImportedValues,
    ),
    ...adjustedColumns(totalValues?.totalAdjustedValues),
    ...calculatedColumns(
      totalValues?.totalExistingValues?.totalExistingCalculatedValues,
      totalValues?.totalPipelineValues?.totalPipelineCalculatedValues,
      totalValues?.totalCalculatedValues,
    ),
  }
}

export default mapTotalValues
