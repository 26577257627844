import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js'
import {
  CheckBox,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Icon,
  Input,
  ResponsiveGridLayout,
  Text,
  ToolbarSeparator,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/creation-dialog/property-step/CreatePropertySubStep.module.css'
import DealAddressAutocompleteInput from 'components/domains/deals/creation-dialog/property-step/DealAddressAutocompleteInput'
import PropertiesSearchDialog from 'components/domains/properties/properties-search/dialog/PropertiesSearchDialog'
import Labeled from 'components/ui/data/Labeled'
import ExistingPropertyMessageBox from 'components/ui/feedback/ExistingPropertyInfoMessage'
import InputWithClearIcon from 'components/ui/input/InputWithClearIcon'
import NumberInput from 'components/ui/input/NumberInput'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { standardMappings, useFormMapped } from 'hooks/form/useFormMapped'
import { useFormValidations } from 'hooks/form/useFormValidations'
import { useAreaMeasurementUnitFormatter } from 'hooks/i18n/useI18n'
import { useAreaUnitOfMeasureCodesNoPcs } from 'hooks/services/properties/useAreaUnitOfMeasureCodesNoPcs'
import { useCountryCodes } from 'hooks/services/properties/useCountryCodes'
import { useCurrencyCodes } from 'hooks/services/properties/useCurrencyCodes'
import { useTypecodes } from 'hooks/services/properties/useTypecodes'
import {
  EXISTING_PROPERTY_INDICATOR,
  generatePropertyFilterObject,
  generatePropertyInfoMessage,
} from 'utils/propertyAddressCheck'
import { noSpecialCharactersRegEx } from 'utils/specialCharacters'

const displayUndefinedAsEmpty = (value) => value ?? ''

// property object input
const formatProperty = ({ description: propertyName, id: propertyId }) => {
  if (!propertyName) {
    return ''
  }
  return propertyId ? `${propertyName} (${propertyId})` : propertyName
}

const getPropertyNameFromFormattedProperty = (value) => {
  const matches = value.match(/^(.+) \(.+\)$/)
  return matches?.[1] ?? value
}

const CreatePropertySubStep = ({
  enabled,
  addressFilter,
  isCreatingProperty,
  propertiesWithSameAddressFilter,
  onUpdateAddressFilter,
  setIsCreatingProperty,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.dialog.property',
  })
  const { register, setValue, trigger, getValues, control } = useFormMapped()
  const { required, pattern } = useFormValidations({ enabled })
  const [manualAddressInputEnabled, setManualAddressInputEnabled] = useState(false)
  const [infoMessage, setInfoMessage] = useState(EXISTING_PROPERTY_INDICATOR.NONE)
  const messageContainerRef = useRef(null)
  const formatArea = useAreaMeasurementUnitFormatter()
  const displayAreaCode = (areaCode) => formatArea(areaCode) ?? ''

  const generateInfoMessage = useCallback(
    ({ zipCode, country, street, city, houseId }) => {
      setInfoMessage(
        generatePropertyInfoMessage(
          zipCode,
          country,
          street,
          city,
          houseId,
          propertiesWithSameAddressFilter,
        ),
      )
    },
    [propertiesWithSameAddressFilter, setInfoMessage],
  )

  const triggerAddressCheck = useCallback(() => {
    generateInfoMessage(addressFilter)
  }, [addressFilter, generateInfoMessage])

  useEffect(() => {
    isCreatingProperty && triggerAddressCheck()
  }, [
    addressFilter,
    propertiesWithSameAddressFilter,
    generateInfoMessage,
    triggerAddressCheck,
    isCreatingProperty,
  ])

  const onAddressFieldUpdateHandler = (key, value) => {
    const keySuffix = key.slice(key.lastIndexOf('.') + 1)
    onUpdateAddressFilter(generatePropertyFilterObject(keySuffix, value, addressFilter))
    messageContainerRef?.current?.scrollIntoView()
  }
  const [propertyNameInputValue, setPropertyNameInputValue] = useState('')

  const onCountryChange = async (e, value) => {
    await setValue(e.target.name, value)
    await setValue(
      'portfolio.properties[0].address.countryName',
      e?.detail?.selectedOption?.textContent,
    )
    await trigger(e.target.name)
    onAddressFieldUpdateHandler('portfolio.properties[0].address.countryCode', value)
  }

  const onAddressChange = async (e) => {
    await setValue(e.target.name, e.target.value)
    onAddressFieldUpdateHandler(e.target.name, e.target.value)
  }

  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false)

  const handleIconClick = () => setIsSearchDialogOpen(true)

  const property = getValues('portfolio.properties[0]') || {}
  const propertyUuid = property?.uuid
  const propertyName = property?.description

  useEffect(() => {
    setPropertyNameInputValue(formatProperty(property))
  }, [property])

  const propertyNotExistingValueStateMessage =
    propertyName && !propertyUuid ? t('property-does-not-exist') : null

  const calculatePropertyValueState = (isInvalid) => {
    if (propertyNotExistingValueStateMessage) return ValueState.Information
    if (isInvalid) return ValueState.Error
    return ValueState.None
  }

  const handleSelectedProperty = (selectedProperties) => {
    const currentSelectedProperty = selectedProperties[0]

    setValue('portfolio.properties[0].uuid', currentSelectedProperty.uuid)
    setValue('portfolio.properties[0].id', currentSelectedProperty.id)

    setValue('portfolio.properties[0].typeCode', currentSelectedProperty.type_code)
    setValue('portfolio.properties[0].description', currentSelectedProperty.description)
    setValue('portfolio.properties[0].currencyCode', currentSelectedProperty.currency_code)
    setValue(
      'portfolio.properties[0].areaMeasureUnitCode',
      currentSelectedProperty.area_measure_unit_code,
    )

    setValue(
      'portfolio.properties[0].address.countryCode',
      currentSelectedProperty.address.country_code,
    )
    setValue(
      'portfolio.properties[0].address.countryName',
      currentSelectedProperty.address.country_name,
    )
    setValue(
      'portfolio.properties[0].address.postalCode',
      currentSelectedProperty.address.postal_code,
    )
    setValue('portfolio.properties[0].address.cityName', currentSelectedProperty.address.city_name)
    setValue(
      'portfolio.properties[0].address.streetName',
      currentSelectedProperty.address.street_name,
    )
    setValue('portfolio.properties[0].address.houseId', currentSelectedProperty.address.house_id)

    setValue(
      'portfolio.properties[0].geoLocation.latitude',
      currentSelectedProperty.geo_location.latitude,
    )
    setValue(
      'portfolio.properties[0].geoLocation.longitude',
      currentSelectedProperty.geo_location.longitude,
    )

    setPropertyNameInputValue(formatProperty(currentSelectedProperty))
    setManualAddressInputEnabled(false)
    setIsCreatingProperty(false)
    setInfoMessage(EXISTING_PROPERTY_INDICATOR.NONE)
    trigger()
  }

  const onBlur = () => {
    setPropertyNameInputValue(formatProperty(property))
    !propertyUuid && setIsCreatingProperty(true)
    onAddressFieldUpdateHandler('portfolio.properties[0].address', property?.address)
  }

  const onFocus = () => {
    setPropertyNameInputValue(getPropertyNameFromFormattedProperty(propertyNameInputValue))
  }

  const onInput = (event) => {
    setPropertyNameInputValue(event.target.value)
  }

  const onChange = (event) => {
    setValue('portfolio.properties[0].description', event.target.value)
    setValue('portfolio.properties[0].uuid', undefined)
    setValue('portfolio.properties[0].id', undefined)
    trigger()
  }

  const parseValue = (value) => {
    const parsed = parseFloat(value)
    if (isNaN(parsed)) return undefined
    return parsed
  }

  return (
    <>
      <ResponsiveGridLayout
        columnsS={2}
        columnsM={4}
        columnsL={4}
        columnsXL={4}
        columnGap="1rem"
        rowGap="1rem"
        className={styles.propertyForm}
      >
        <Labeled label={t('property-name')} required showColon vertical>
          <Controller
            control={control}
            name="portfolio.properties[0].description"
            rules={{
              validate: {
                required: required(),
              },
            }}
            render={({ field: { name }, fieldState: { invalid, error } }) => (
              <InputWithClearIcon
                id="create-deal-property-name"
                icon={
                  <Icon
                    id="open-property-search-dialog"
                    name="value-help"
                    onClick={handleIconClick}
                    className={styles.actionIcon}
                  />
                }
                value={propertyNameInputValue}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onInput={onInput}
                maxLength={30}
                name={name}
                valueState={calculatePropertyValueState(invalid)}
                valueStateMessage={
                  <Text wrapping>{propertyNotExistingValueStateMessage || error?.message}</Text>
                }
              />
            )}
          />
        </Labeled>
        <Labeled label={t('property-type')} required showColon vertical>
          <LoadingSelect
            id="create-deal-property-type"
            loadingHook={useTypecodes}
            selectionName="typecodes"
            {...register('portfolio.properties[0].typeCode', {
              ...standardMappings.LoadingSelect,
              validate: {
                required: required(),
              },
              shouldUnregister: true,
              displayAs: displayUndefinedAsEmpty,
            })}
            disabled={!!propertyUuid}
          />
        </Labeled>
        <Labeled label={t('currency')} required showColon vertical>
          <LoadingSelect
            id="create-deal-property-currency"
            loadingHook={useCurrencyCodes}
            selectionName="currency_codes"
            {...register('portfolio.properties[0].currencyCode', {
              ...standardMappings.LoadingSelect,
              validate: {
                required: required(),
              },
              shouldUnregister: true,
              displayAs: displayUndefinedAsEmpty,
            })}
            disabled={!!propertyUuid}
          />
        </Labeled>
        <Labeled label={t('unit-area')} required showColon vertical>
          <LoadingSelect
            id="create-deal-property-area-unit"
            loadingHook={useAreaUnitOfMeasureCodesNoPcs}
            {...register('portfolio.properties[0].areaMeasureUnitCode', {
              ...standardMappings.LoadingSelect,
              validate: {
                required: required(),
              },
              shouldUnregister: true,
              displayAs: displayUndefinedAsEmpty,
            })}
            disabled={!!propertyUuid}
            optionDisplayName={'key'}
            formatDisplayName={displayAreaCode}
          />
        </Labeled>
        <ToolbarSeparator className={styles.separator} />
        <Labeled
          label={t('address.search')}
          for="create-deal-property-address-search"
          showColon
          vertical
          className={styles.fullWidth}
        >
          <FlexBox
            id="create-deal-property-address"
            className={styles.addressSearchBox}
            direction={FlexBoxDirection.Row}
            alignItems={FlexBoxAlignItems.Start}
            justifyContent={FlexBoxJustifyContent.SpaceBetween}
          >
            <DealAddressAutocompleteInput
              id="create-deal-property-address-search"
              basePath="portfolio.properties[0]"
              icon={<Icon name="search" />}
              suggestionItemProps={{ icon: 'map' }}
              placeholder={t('address.search.placeholder')}
              disabled={manualAddressInputEnabled || !!propertyUuid}
              onAddressSelectedCustomHandler={(value) =>
                onAddressFieldUpdateHandler('portfolio.properties[0].address', value)
              }
            />
            <CheckBox
              id="create-deal-property-address-edit-manually"
              onChange={() => setManualAddressInputEnabled(!manualAddressInputEnabled)}
              checked={manualAddressInputEnabled}
              text={t('address.search.manual')}
              className={styles.addressInputModeToggle}
              disabled={!!propertyUuid}
            />
          </FlexBox>
        </Labeled>
        <Labeled
          label={t('address.country')}
          required
          showColon
          vertical
          className={styles.fullWidth}
        >
          <LoadingSelect
            id="create-deal-property-address-country"
            loadingHook={useCountryCodes}
            selectionName="country_codes"
            disabled={!manualAddressInputEnabled}
            {...register('portfolio.properties[0].address.countryCode', {
              ...standardMappings.LoadingSelect,
              validate: {
                required: required(),
              },
              shouldUnregister: true,
              displayAs: displayUndefinedAsEmpty,
            })}
            onChange={onCountryChange}
          />
        </Labeled>
        <input
          type="hidden"
          {...register('portfolio.properties[0].address.countryName', {
            handlerMappings: {
              valueState: false,
              valueStateMessage: false,
            },
            shouldUnregister: true,
            displayAs: displayUndefinedAsEmpty,
          })}
          style={{ display: 'none' }}
        />
        <Labeled
          label={t('address.zip')}
          showColon
          vertical
          className={styles.quarterWidth}
          required
        >
          <Input
            id="create-deal-property-address-zip"
            disabled={!manualAddressInputEnabled}
            {...register('portfolio.properties[0].address.postalCode', {
              validate: {
                required: required(),
                pattern: pattern(noSpecialCharactersRegEx),
              },
              shouldUnregister: true,
              displayAs: displayUndefinedAsEmpty,
            })}
            onChange={onAddressChange}
          />
        </Labeled>
        <Labeled
          label={t('address.city')}
          required
          showColon
          vertical
          className={styles.threeQuarterWidth}
        >
          <Input
            id="create-deal-property-address-city"
            disabled={!manualAddressInputEnabled}
            {...register('portfolio.properties[0].address.cityName', {
              validate: {
                required: required(),
                pattern: pattern(noSpecialCharactersRegEx),
              },
              shouldUnregister: true,
              displayAs: displayUndefinedAsEmpty,
            })}
            onChange={onAddressChange}
          />
        </Labeled>
        <Labeled
          label={t('address.street')}
          showColon
          vertical
          className={styles.threeQuarterWidth}
        >
          <Input
            id="create-deal-property-address-street"
            disabled={!manualAddressInputEnabled}
            {...register('portfolio.properties[0].address.streetName', {
              validate: {
                pattern: pattern(noSpecialCharactersRegEx),
              },
              shouldUnregister: true,
              displayAs: displayUndefinedAsEmpty,
            })}
            onChange={onAddressChange}
          />
        </Labeled>
        <Labeled label={t('address.no')} showColon vertical className={styles.quarterWidth}>
          <Input
            id="create-deal-property-address-no"
            disabled={!manualAddressInputEnabled}
            {...register('portfolio.properties[0].address.houseId', {
              validate: {
                pattern: pattern(noSpecialCharactersRegEx),
              },
              shouldUnregister: true,
              displayAs: displayUndefinedAsEmpty,
            })}
            onChange={onAddressChange}
          />
        </Labeled>
        <Labeled label={t('geolocation.lat')} showColon vertical>
          <NumberInput
            id="create-deal-property-address-latitude"
            disabled={!manualAddressInputEnabled}
            {...register('portfolio.properties[0].geoLocation.latitude', {
              shouldUnregister: true,
              setValueAs: parseValue,
              displayAs: displayUndefinedAsEmpty,
            })}
          />
        </Labeled>
        <Labeled label={t('geolocation.long')} showColon vertical>
          <NumberInput
            id="create-deal-property-address-longitude"
            disabled={!manualAddressInputEnabled}
            {...register('portfolio.properties[0].geoLocation.longitude', {
              shouldUnregister: true,
              setValueAs: parseValue,
              displayAs: displayUndefinedAsEmpty,
            })}
          />
        </Labeled>
        <FlexBox className={styles.fullWidth} ref={messageContainerRef}>
          <ExistingPropertyMessageBox infoMessage={infoMessage} className={'full-width'} />
        </FlexBox>
      </ResponsiveGridLayout>
      {createPortal(
        <PropertiesSearchDialog
          isOpen={isSearchDialogOpen}
          setIsOpen={setIsSearchDialogOpen}
          allowMultiSelect={false}
          onAccept={handleSelectedProperty}
        />,
        document.body,
      )}
    </>
  )
}

CreatePropertySubStep.propTypes = {
  enabled: PropTypes.bool,
  addressFilter: PropTypes.object,
  isCreatingProperty: PropTypes.bool.isRequired,
  onUpdateAddressFilter: PropTypes.func.isRequired,
  propertiesWithSameAddressFilter: PropTypes.array.isRequired,
  setIsCreatingProperty: PropTypes.func.isRequired,
}

export default CreatePropertySubStep
