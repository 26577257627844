import PropTypes from 'prop-types'
import QuestionnaireTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/QuestionnaireTile'
import {
  availableEndings,
  questionnaireStructure,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/securitization/securitizationQuestionnaireStructure'

const SecuritizationQuestionnaireTile = ({
  tileId,
  currentContent,
  onChange,
  isEditMode,
  isPdfView,
  setHasContentChanges,
}) => (
  <QuestionnaireTile
    tileId={tileId}
    currentContent={currentContent}
    onChange={onChange}
    isEditMode={isEditMode}
    isPdfView={isPdfView}
    setHasContentChanges={setHasContentChanges}
    questionnaireStructure={questionnaireStructure}
    availableEndings={availableEndings}
    questionTranslationPrefix="tiles.nbcs.securitization"
  />
)

SecuritizationQuestionnaireTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  currentContent: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
}

export default SecuritizationQuestionnaireTile
