import { FlexBox, FlexBoxAlignItems, FlexBoxJustifyContent } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { assetValuationOverviewFilters } from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/toolbar/assetValuationOverviewPropTypes'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/toolbar/filter/AssetValuationOverviewBetweenFilter.module.css'
import DateFilterInput from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/toolbar/filter/AssetValuationOverviewDateFilterInput'

const BetweenDatesFilter = ({ value, selectedFilters, setSelectedFilters, columnKey }) => (
  <FlexBox
    justifyContent={FlexBoxJustifyContent.SpaceBetween}
    alignItems={FlexBoxAlignItems.Center}
    style={{ width: '100%', gap: '10px' }}
  >
    <DateFilterInput
      value={value?.lowerBound}
      maxDate={value?.upperBound}
      targetName={'lowerBound'}
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      columnKey={columnKey}
    />
    <span className={styles.dateInputSeparator}>-</span>
    <DateFilterInput
      value={value?.upperBound}
      minDate={value?.lowerBound}
      targetName={'upperBound'}
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      columnKey={columnKey}
    />
  </FlexBox>
)

BetweenDatesFilter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  selectedFilters: assetValuationOverviewFilters.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  columnKey: PropTypes.string.isRequired,
}

export default BetweenDatesFilter
