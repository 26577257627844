import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Text,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/kpis/KpiCardHeader.module.css'
import useVersionedKpiUtils from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/kpis/useVersionedKpiUtils'
import { useNumberFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const EMPTY_VALUE = '-'

const KpiCardHeader = ({ title, kpi, actions = [], tileVersion }) => {
  const { t } = useTranslation('decisionPaper', { keyPrefix: 'components.kpis.chart.header' })

  const formatNumber = useNumberFormatter({ notation: 'compact' })
  const {
    getCurrentValueForTimeSeries,
    getLastUltimoForTimeSeries,
    KpiThresholdTypes,
    getTimeSeriesForKpi,
  } = useVersionedKpiUtils(tileVersion)

  const timeSeries = getTimeSeriesForKpi(kpi)
  const kpiValue = getCurrentValueForTimeSeries(timeSeries)?.value
  const lastUltimo = getLastUltimoForTimeSeries(timeSeries)?.value

  const getDisplayUnit = (unit) =>
    (unit?.type === 'PERCENT' ? t('unit.percent') : unit?.code) ?? EMPTY_VALUE

  return (
    <div className={styles.header}>
      <FlexBox
        className={styles.firstRow}
        direction={FlexBoxDirection.Row}
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        alignItems={FlexBoxAlignItems.Center}
      >
        <Title level={TitleLevel.H5}>{title}</Title>
        {actions}
      </FlexBox>
      <FlexBox direction={FlexBoxDirection.Row}>
        <Text>{kpi.name ? `${kpi.name} | ${getDisplayUnit(kpi.unit)}` : EMPTY_VALUE}</Text>
      </FlexBox>
      <FlexBox
        className={styles.metrics}
        direction={FlexBoxDirection.Row}
        alignItems={FlexBoxAlignItems.End}
      >
        <FlexBox
          className={styles.primaryMetric}
          direction={FlexBoxDirection.Row}
          alignItems={FlexBoxAlignItems.Baseline}
        >
          <Title level={TitleLevel.H1}>{kpiValue ? formatNumber(kpiValue) : EMPTY_VALUE}</Title>
        </FlexBox>

        <FlexBox
          className={styles.secondaryMetrics}
          direction={FlexBoxDirection.Row}
          alignItems={FlexBoxAlignItems.Start}
          justifyContent={FlexBoxJustifyContent.End}
        >
          <FlexBox
            className={styles.secondaryMetric}
            direction={FlexBoxDirection.Column}
            alignItems={FlexBoxAlignItems.End}
          >
            <Title wrappingType="Normal" level={TitleLevel.H6}>
              {t('last-ultimo')}
            </Title>
            <Text>{lastUltimo ? formatNumber(lastUltimo) : EMPTY_VALUE}</Text>
          </FlexBox>
          <FlexBox
            className={styles.secondaryMetric}
            direction={FlexBoxDirection.Column}
            alignItems={FlexBoxAlignItems.End}
          >
            <Title wrappingType="Normal" level={TitleLevel.H6}>
              {t('thresholds')}
            </Title>
            {kpi.thresholds?.length ? (
              kpi.thresholds.map(({ name, comparisonType, value }) => (
                <Text key={name}>{`${name}: ${KpiThresholdTypes[comparisonType]} ${value} %`}</Text>
              ))
            ) : (
              <Text>{EMPTY_VALUE}</Text>
            )}
          </FlexBox>
          <FlexBox
            className={styles.secondaryMetric}
            direction={FlexBoxDirection.Column}
            alignItems={FlexBoxAlignItems.End}
          >
            <Title wrappingType="Normal" level={TitleLevel.H6}>
              {t('critical-changes')}
            </Title>
            {kpi?.criticalChanges?.length ? (
              kpi.criticalChanges.map(({ name, comparisonType, value, deltaTime }) => (
                <Text
                  key={name}
                >{`${name}: ${KpiThresholdTypes[comparisonType]} ${value}% in ${deltaTime}d`}</Text>
              ))
            ) : (
              <Text>{EMPTY_VALUE}</Text>
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </div>
  )
}

KpiCardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  kpi: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    unit: PropTypes.shape({
      type: PropTypes.string,
      code: PropTypes.string,
    }),
    thresholds: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        comparisonType: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    criticalChanges: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        comparisonType: PropTypes.string,
        value: PropTypes.number,
        deltaTime: PropTypes.number,
      }),
    ),
  }).isRequired,
  actions: PropTypes.arrayOf(PropTypes.node),
  tileVersion: PropTypes.string,
}
export default KpiCardHeader
