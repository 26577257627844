import camelize from 'camelize'
import { doGet } from 'lib/locationiq/requests'

/**
 * @typedef {Object} AutocompleteAddress
 * @property {string} city
 * @property {string} country
 * @property {string} name
 * @property {string} road
 * @property {string} house_number
 * @property {string} postcode
 */

/**
 * The autocomplete response
 * @typedef {Object} AutocompleteResponse
 * @property {string} latitude
 * @property {string} longitude
 * @property {string} displayName
 * @property {string} displayPlace
 * @property {number} importance
 * @property {string} icon
 * @property {AutocompleteAddress} address
 */

/**
 * @returns {AutocompleteResponse}
 */
const parseAutocompleteResponse = ({
  lat,
  lon,
  display_name,
  display_place,
  importance,
  icon,
  address,
  ...rest
}) => ({
  latitude: lat,
  longitude: lon,
  displayName: display_name,
  displayPlace: display_place,
  importance: importance,
  icon: icon,
  address: address,
  ...rest,
})

/**
 * For more details, see https://locationiq.com/docs#autocomplete
 * @param {string} url
 * @param {string} apikey
 * @returns {function({query: string, limit: number, includeAddressDetails?: boolean, language?: 'en'|'de'|string}): Promise<AutocompleteResponse>}
 */
const getAutocomplete =
  (url, apikey) =>
  ({ query, limit, language, includeAddressDetails = false, ...rest }) =>
    doGet(url, apikey, {
      normalizecity: 1,
      normalizeaddress: 1,
      dedupe: 1,
      ...rest,
      q: query,
      limit,
      addressdetails: includeAddressDetails ? 1 : 0,
      format: 'json',
      'accept-language': language,
    }).then((items) => items.map(parseAutocompleteResponse))

/**
 * @typedef {Object} ReverseGeocodingAddress
 * @property {string} city
 * @property {string} country
 * @property {string} name
 * @property {string} road
 * @property {string} country_code
 * @property {string} postcode
 */

/**
 * The reverse geocoding response
 * @typedef {Object} ReverseGeocodingResponse
 * @property {string} latitude
 * @property {string} longitude
 * @property {string} displayName
 * @property {ReverseGeocodingResponse} address
 */

/**
 * @returns {AutocompleteResponse}
 */
const parseReverseGeocodingResponse = ({ lat, lon, ...rest }) => ({
  latitude: lat,
  longitude: lon,
  ...camelize(rest),
})

/**
 * For more details, see https://locationiq.com/docs#reverse-geocoding
 * @param {string} url
 * @param {string} apikey
 * @returns {function({query: string}): Promise<ReverseGeocodingResponse>}
 */
const getReverseGeocoding =
  (url, apikey) =>
  ({ lat, lon, ...rest }) =>
    doGet(url, apikey, { normalizeaddress: 1, ...rest, lat, lon, format: 'json' }).then((item) =>
      parseReverseGeocodingResponse(item),
    )

const LocationiqClient = ({ urls, apikey }) => {
  const { autocomplete: autocompleteUrl, reverseGeocoding: reverseGeocodingUrl } = urls
  return {
    getAutocomplete: getAutocomplete(autocompleteUrl, apikey),
    getReverseGeocoding: getReverseGeocoding(reverseGeocodingUrl, apikey),
  }
}

export default LocationiqClient
