import { useMemo, useCallback, useState } from 'react'

const useTableHeightHelper = ({ rowHeight, subcomponentHeight, maximumHeight, dataLength = 0 }) => {
  const [numberOfExpandedColumns, setNumberOfExpandedColumns] = useState(0)

  const onExpandChange = useCallback(({ expandedRows }) => {
    const newNumberOfExpandedColumns =
      expandedRows?.reduce(
        (currentValue, { isExpanded }) => (isExpanded ? currentValue + 1 : currentValue),
        0,
      ) || 0
    // Without setTimeout we get the error
    // react-dom.development.js:74 Warning: Cannot update a component (`ConditionsTable`) while rendering a different component (`AnalyticalTable`). To locate the bad setState() call inside `AnalyticalTable`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render
    // Therefore flushing the queue before updating the state.
    setTimeout(() => {
      setNumberOfExpandedColumns(newNumberOfExpandedColumns)
    })
  }, [])

  const tableHeight = useMemo(() => {
    const rowsHeight = dataLength * rowHeight
    const subcomponentsHeight = numberOfExpandedColumns * subcomponentHeight
    const overallTableHeight = rowsHeight + subcomponentsHeight
    return Math.min(overallTableHeight, maximumHeight)
  }, [dataLength, maximumHeight, numberOfExpandedColumns, rowHeight, subcomponentHeight])

  return useMemo(
    () => ({
      onExpandChange,
      numberOfExpandedColumns,
      tableHeight,
    }),
    [numberOfExpandedColumns, onExpandChange, tableHeight],
  )
}

export default useTableHeightHelper
