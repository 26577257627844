import camelize from 'camelize'
import { useMemo } from 'react'
import { isNotFoundError, responseCodes } from 'api/requests'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const RETRIES = 3

const useMultipleCustomAssessmentVersions = ({ uuids, assessmentCode, entityType }) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: uuids.map((d) => {
        const queryParams = new URLSearchParams()

        const keys = ['central-data', 'assessments', entityType, d, assessmentCode]

        queryParams.append('entity_id', d)
        queryParams.append('entity_type', entityType)
        queryParams.append('code', assessmentCode)

        return {
          path: `/central-data/assessments?${queryParams}`,
          useCache: true,
          keys: keys,
        }
      }),
      options: {
        retry: (failureCount, error) => !(isNotFoundError(error) || failureCount >= RETRIES),
      },
    }),
  )

  return useMemo(
    () => ({
      data: responses.map((result, index) => ({
        uuid: uuids[index],
        ...camelize(result.data),
      })),
      isMultipleCustomAssessmentVersionsLoading: responses.some((result) => result.isLoading),
      isMultipleCustomAssessmentVersionsError: responses.some((result) =>
        result.error?.response?.status === responseCodes.NOT_FOUND ? false : result.isError,
      ),
      error: responses.map((result) => result.error),
    }),
    [uuids, responses],
  )
}

export default useMultipleCustomAssessmentVersions
