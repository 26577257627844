import PropTypes from 'prop-types'
import { memo, useEffect, useRef, useState } from 'react'
import useFilterTypes from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/tables/sorted-tables/useFilters'
import FilterDialogFilterItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/tables/toolbar/FilterDialogFilterItem'
import FilterDialogView from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/tables/toolbar/FilterDialogView'
import BetweenDatesFilter from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/tables/toolbar/filter/BetweenDatesFilter'
import BetweenNumbersFilter from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/tables/toolbar/filter/BetweenNumbersFilter'
import EnumSelectInput from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/tables/toolbar/input/EnumSelectInput'
import { MultiselectInput } from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/tables/toolbar/input/MultiselectInput'
import TextInput from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/tables/toolbar/input/TextInput'
import { filters as filterPropTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/tables/toolbar/propTypes'

/**
 * Modal Dialog with a set of filters to set.
 * @param isOpen
 * @param setIsOpen
 * @param filters List of filters consisting of
 *    columnKey: key of the column to filters,
 *    type: filter type. Possible: 'CONTAINS', 'BETWEEN_DATES', 'BETWEEN_NUMBERS', 'OF_ENUM_TYPE', 'MULTISELECT_ENUM', 'CUSTOM'
 *    label: label of the column to filter,
 *    value: the selected value to filter for
 *    additionalFilterOptions: {
 *      CustomComponent: the component to be rendered when the type is CUSTOM, the additionalFilterOptions are passed as props
 *      customFilterFunction({searchValue, cell}): sets the condition for the elements to be filtered in the CustomComponent when the type is CUSTOM. See how it is defined in useFilters.js
 *      enumValues: an array of strings used as options for the type EnumSelectInput and MultiselectInput
 *      }
 * @param setFilters Callback when the filters change
 * @param onSubmit Callback when filters have been submitted
 * @returns
 */
const FilterDialog = memo(
  ({ isOpen, setIsOpen, filters, setFilters, onSubmit: _onSubmit = () => {} }) => {
    const {
      CONTAINS,
      BETWEEN_DATES,
      BETWEEN_NUMBERS,
      OF_ENUM_TYPE,
      MULTISELECT_ENUM,
      MULTISELECT_ENUM_IN_LIST,
      CUSTOM,
      filterForType,
    } = useFilterTypes()
    const [selectedFilters, setSelectedFilters] = useState(filters)

    useEffect(() => {
      setSelectedFilters(filters)
    }, [filters])

    const selectRefs = useRef({})
    const multiSelectRefs = useRef({})

    const onCancel = () => {
      setSelectedFilters(filters)
      setIsOpen(false)
    }

    const onSubmit = () => {
      setIsOpen(false)
      setFilters(selectedFilters)
      _onSubmit({ nrOfSelectedFilters: selectedFilters.filter(({ value }) => !!value).length })
    }

    const clearSelectValues = () => {
      selectedFilters.forEach((filter) => {
        selectRefs.current[filter.columnKey]?.options.forEach(
          (option) =>
            (option.selected = option.textContent === filterForType(filter.type).emptyValue),
        )
      })
    }
    const clearMultiSelections = () => {
      selectedFilters.map((filter) => {
        if (multiSelectRefs.current[filter.columnKey]) {
          multiSelectRefs.current[filter.columnKey].selectedValues.forEach(
            (selectedValue) => (selectedValue.selected = false),
          )
        }
      })
    }

    const onReset = () => {
      clearSelectValues()
      clearMultiSelections()

      setSelectedFilters(
        selectedFilters.map((filter) => ({
          ...filter,
          value: filterForType(filter.type).emptyValue,
        })),
      )
    }

    const isNoFilterEntered = selectedFilters.every(({ emptyValue, value }) => {
      if (typeof value === 'object' && !Array.isArray(value)) {
        for (const key in value) {
          if (value[key] !== emptyValue[key]) {
            return false
          }
        }
        return true
      } else return value === emptyValue || !value
    })

    const hasInvalidInputs = selectedFilters.some(
      ({ type, valueValidities = {} }) =>
        type === BETWEEN_NUMBERS.type && Object.values(valueValidities).includes(false),
    )

    const renderFilters = () =>
      selectedFilters.map(({ columnKey, type, label, value, additionalFilterOptions }) => {
        const wrapFilter = (filter) => (
          <FilterDialogFilterItem key={columnKey} label={label}>
            {filter}
          </FilterDialogFilterItem>
        )
        switch (type) {
          case CONTAINS.type:
            // case MULTI_OBJECT_CONTAINS.type:
            return wrapFilter(
              <TextInput
                value={value}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                columnKey={columnKey}
              />,
            )
          case BETWEEN_DATES.type:
            return wrapFilter(
              <BetweenDatesFilter
                value={value}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                columnKey={columnKey}
              />,
            )
          case BETWEEN_NUMBERS.type:
            return wrapFilter(
              <BetweenNumbersFilter
                value={value}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                columnKey={columnKey}
              />,
            )

          case OF_ENUM_TYPE.type: {
            return wrapFilter(
              <EnumSelectInput
                initialValue={value}
                enumValues={additionalFilterOptions.enumValues}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                selectRefs={selectRefs}
                columnKey={columnKey}
              />,
            )
          }
          case MULTISELECT_ENUM.type:
          case MULTISELECT_ENUM_IN_LIST.type: {
            return wrapFilter(
              <MultiselectInput
                enumValues={additionalFilterOptions.enumValues}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                selectRefs={multiSelectRefs}
                columnKey={columnKey}
                {...additionalFilterOptions}
              />,
            )
          }
          case CUSTOM.type: {
            return wrapFilter(
              <additionalFilterOptions.CustomComponent
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                columnKey={columnKey}
                {...additionalFilterOptions}
              />,
            )
          }

          default:
            // eslint-disable-next-line no-console
            console.warn(`unknown filter type "${type}"`)
            return <div key={columnKey} />
        }
      })

    return (
      <FilterDialogView
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onReset={onReset}
        isNoFilterEntered={isNoFilterEntered}
        hasInvalidInputs={hasInvalidInputs}
      >
        {renderFilters()}
      </FilterDialogView>
    )
  },
)
FilterDialog.displayName = 'FilterDialog'
FilterDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  filters: filterPropTypes.isRequired,
  setFilters: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default FilterDialog
