import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropType from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessPartnerSearchInput from 'components/domains/business-partners/search-input/BusinessPartnerSearchInput'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'
import { useConfig } from 'hooks/config/useConfig'

/**
 * @typedef {PropType.InferProps<typeof ValuationRequestTableValuerWithSuggestionsSelect.propTypes>} ValuationRequestTableValuerWithSuggestionsSelect
 */
const ValuationRequestTableValuerWithSuggestionsSelect = ({
  propertyUuid,
  valuationRequests,
  valuer,
  onValuerChange,
  showConfirmationDialog = true,
}) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property.valuation.requests' })
  const { data: config } = useConfig()

  const appraiserRoleCode = config?.properties?.appraiserRoleCode

  const [searchInputValue, setSearchInputValue] = useState({ id: valuer })
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)

  useEffect(() => {
    setSearchInputValue({ id: valuer })
  }, [valuer])

  const checkForRotationPrinciple = useCallback(
    (newBusinessPartnerId) => {
      if (isNil(propertyUuid)) {
        return false
      }
      return valuationRequests.find(
        ({ valuer: existingValuer, property: { uuid } }) =>
          propertyUuid === uuid &&
          valuer !== existingValuer &&
          existingValuer === newBusinessPartnerId,
      )
    },
    [propertyUuid, valuationRequests, valuer],
  )

  const handleConfirmation = useCallback(() => {
    setIsConfirmationDialogOpen(false)
  }, [])

  const handleConfirmationCancel = useCallback(
    ({ detail: { action } }) => {
      if (action !== 'cancel') {
        return
      }
      onValuerChange('')
      setSearchInputValue({ id: '' })
      setIsConfirmationDialogOpen(false)
    },
    [onValuerChange],
  )

  const handleValuerChange = useCallback(
    (newValuer) => {
      onValuerChange(newValuer)
      if (checkForRotationPrinciple(newValuer)) {
        setIsConfirmationDialogOpen(true)
      }
    },
    [checkForRotationPrinciple, onValuerChange],
  )

  const handleOnChange = useCallback(
    (businessPartner) => {
      setSearchInputValue(businessPartner)
      // onChange is called by BusinessPartnerSearchInput on every input but with an empty string as id
      // to prevent rotation principle check on every keystroke, we have to check for this
      if (!isEmpty(businessPartner.id)) {
        handleValuerChange(businessPartner.id)
      }
    },
    [handleValuerChange],
  )

  return (
    <>
      <BusinessPartnerSearchInput
        value={searchInputValue}
        onChange={handleOnChange}
        preselectedRoles={appraiserRoleCode ? [`${appraiserRoleCode}`] : []}
      />
      {showConfirmationDialog && (
        <ConfirmationDialog
          isOpen={isConfirmationDialogOpen}
          setIsOpen={setIsConfirmationDialogOpen}
          handleConfirmation={handleConfirmation}
          handleCancel={handleConfirmationCancel}
          confirmationMessage={t('table.rotation-principle.confirmation-message.description')}
          confirmationTitle={t('table.rotation-principle.confirmation-message.title')}
          confirmButtonText={tNoPrefix('buttons.confirm')}
        />
      )}
    </>
  )
}

ValuationRequestTableValuerWithSuggestionsSelect.propTypes = {
  valuationRequests: PropType.arrayOf(
    PropType.shape({
      valuer: PropType.string,
      property: PropType.shape({ uuid: PropType.string }),
    }),
  ),
  propertyUuid: PropType.string,
  valuer: PropType.string.isRequired,
  onValuerChange: PropType.func.isRequired,
  showConfirmationDialog: PropType.bool,
}

export default ValuationRequestTableValuerWithSuggestionsSelect
