const determineEventCompletionNotPossibleMessage = ({
  hasOpenMandatoryTasks,
  hasOpenDecisionStage,
} = {}) => {
  if (!hasOpenMandatoryTasks) {
    return 'error-message.open-decision-stage'
  }
  if (hasOpenDecisionStage) {
    return 'error-message.open-mandatory-tasks-and-decision-stage'
  }
  return 'error-message.open-mandatory-tasks'
}

export default determineEventCompletionNotPossibleMessage
