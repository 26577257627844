import {
  Button,
  ButtonDesign,
  Label,
  List,
  ListMode,
  ListSeparators,
  StandardListItem,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import dayjs from 'dayjs'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import EntityCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/EntityCell'
import FinancingTermsTrancheDetailsMultiLineEntry from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/FinancingTermsTrancheDetailsMultiLineEntry'
import FinancingTermsTrancheDetailsPopover from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/FinancingTermsTrancheDetailsPopover'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/FinancingTermsTrancheDetailsTableCell.module.css'
import {
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/shared/hooks/i18n/useI18n'
import DecisionStageLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/decision-stage-leave-confirmation/DecisionStageLeaveConfirmationLink'
import paths from 'routes/paths'

const renderFooterCell = (props, formatMoney, formatFraction, t) => {
  const columnKey = props.cell.column.id

  const renderMoneyWithShareCell = ({ hasShare = true }) => {
    const { value: primaryValue, currency: primaryCurrency } = props.cell.value.primary ?? {}
    const { value: secondaryValue, currency: secondaryCurrency } = props.cell.value.secondary ?? {}
    const { share } = props.cell.value

    if (isNil(primaryValue)) return <div className={styles.caretCursor} />

    return (
      <div className={styles.multiLineCell}>
        <div>
          <Text className={`${styles.caretCursor}, ${styles.textBold}`}>
            {formatMoney(primaryValue, primaryCurrency, {
              currencyDisplay: 'code',
            })}
          </Text>
          {!isNil(secondaryValue) && (
            <div className={`${styles.textLabelColor} ${styles.caretCursor}`}>
              {formatMoney(secondaryValue, secondaryCurrency, {
                currencyDisplay: 'code',
              })}
            </div>
          )}
        </div>
        {hasShare && (
          <div className={styles.caretCursor}>
            <Label showColon>{t('property.share-label')}</Label>&nbsp;
            <Text>{formatFraction(share)}</Text>
          </div>
        )}
      </div>
    )
  }

  switch (columnKey) {
    case 'tranche':
      return <Text className={styles.textBold}>{t('property.total-label')}</Text>
    case 'commitment':
      return renderMoneyWithShareCell({})
    case 'outstandingAmount':
      return renderMoneyWithShareCell({ hasShare: false })
    default:
      return <div />
  }
}

const renderTableCell = (
  props,
  formatMoney,
  formatFraction,
  formatDate,
  formatDuration,
  t,
  isPopoverOpen,
  setIsPopoverOpen,
  isPdfView = false,
  showConfirmationForLinks = false,
) => {
  const columnKey = props.cell.column.id

  const renderTrancheCell = () => {
    const { name, externalContractId } = props.cell.value
    return (
      <div className={styles.trancheCell}>
        {!isNil(name) && (
          <Text className={`${styles.caretCursor} ${styles.textBold} ${styles.overflowEllipsis}`}>
            {name}
          </Text>
        )}
        <Text className={styles.caretCursor}>{externalContractId}</Text>
      </div>
    )
  }

  const renderBorrowerCell = () => {
    const { businessPartnerName, businessPartnerId } = props.cell.value ?? {}
    return showConfirmationForLinks ? (
      <DecisionStageLeaveConfirmationLink
        name={businessPartnerName ?? ''}
        link={`/business-partners/${businessPartnerId}`}
      />
    ) : (
      <EntityCell
        options={{ openInNewTab: true }}
        name={businessPartnerName}
        id={businessPartnerId}
        link={`/${paths.businessPartners}/${businessPartnerId}`}
      />
    )
  }

  const renderCellWithWordBreak = () => (
    <div className={styles.wordBreak}>{props.cell.value.text ?? ''}</div>
  )

  const renderMoneyWithShareCell = ({ hasShare = true }) => {
    const { value: originalValue, currency: originalCurrency } = props.cell.value.original ?? {}
    const { value: convertedValue, currency: convertedCurrency } = props.cell.value.converted ?? {}
    const { share } = props.cell.value ?? {}
    return (
      <div className={styles.multiLineCell}>
        <div>
          <div className={styles.caretCursor}>
            {!isNil(originalValue)
              ? formatMoney(originalValue, originalCurrency, {
                  currencyDisplay: 'code',
                })
              : '-'}
          </div>
          {!isNil(convertedValue) && (
            <div className={`${styles.textLabelColor} ${styles.caretCursor}`}>
              {formatMoney(convertedValue, convertedCurrency, {
                currencyDisplay: 'code',
              })}
            </div>
          )}
        </div>

        {hasShare && (
          <div className={styles.caretCursor}>
            <Label showColon>{t('property.share-label')}</Label>&nbsp;
            <Text>{formatFraction(share)}</Text>
          </div>
        )}
      </div>
    )
  }

  const renderAvailability = () => {
    const { latestDrawingDate, closingDate } = props.cell.value || undefined
    const expectedSignedDate = closingDate ? dayjs(closingDate) : undefined
    const newLatestDrawingDate = dayjs(latestDrawingDate)
    const availability =
      expectedSignedDate && newLatestDrawingDate
        ? newLatestDrawingDate.diff(expectedSignedDate, 'month', true)
        : 0
    return (
      <div className={styles.caretCursor}>
        {availability && availability >= 0 ? formatDuration(availability) : '-'}
      </div>
    )
  }

  const renderDrawdownDates = () => {
    const { firstDrawdownDate, latestDrawingDate } = props.cell.value ?? {}
    return (
      <div className={styles.multiLineCell}>
        <div className={styles.caretCursor}>
          <Label showColon>{t('property.first-drawdown-label')}</Label>&nbsp;
          {firstDrawdownDate ? formatDate(firstDrawdownDate) : '-'}
        </div>
        <div className={styles.caretCursor}>
          <Label showColon>{t('property.last-drawing-label')}</Label>&nbsp;
          {latestDrawingDate ? formatDate(latestDrawingDate) : '-'}
        </div>
      </div>
    )
  }

  const renderMaturity = () => {
    const { maturityDate, closingDate } = props.cell.value || undefined
    const parsedMaturityDate = maturityDate ? dayjs(maturityDate) : undefined
    const expectedSignedDate = closingDate ? dayjs(closingDate) : undefined
    const yearDiff =
      parsedMaturityDate && expectedSignedDate
        ? parsedMaturityDate.diff(expectedSignedDate, 'year', true)
        : undefined

    return (
      <div className={styles.multiLineCell}>
        <div className={styles.caretCursor}>
          {parsedMaturityDate ? formatDate(parsedMaturityDate.format()) : '-'}
        </div>
        {!isNil(yearDiff) && (
          <div className={styles.caretCursor}>
            <Label showColon>{t('property.year-label')}</Label>&nbsp;
            <Text>{yearDiff > 0 ? formatDuration(yearDiff) : '-'}</Text>
          </div>
        )}
      </div>
    )
  }

  const renderLastExtensionOption = () => {
    const { ultimateMaximumExtensionDate, closingDate } = props.cell.value || undefined
    const lastExtensionOption = ultimateMaximumExtensionDate
      ? dayjs(ultimateMaximumExtensionDate)
      : undefined
    const expectedSignedDate = closingDate ? dayjs(closingDate) : undefined
    const yearDiff =
      lastExtensionOption && expectedSignedDate
        ? lastExtensionOption.diff(expectedSignedDate, 'year', true)
        : undefined

    return (
      <div className={styles.multiLineCell}>
        <div className={styles.caretCursor}>
          {lastExtensionOption ? formatDate(lastExtensionOption.format()) : '-'}
        </div>
        {!isNil(yearDiff) && (
          <div className={styles.caretCursor}>
            <Label showColon>{t('property.year-label')}</Label>&nbsp;
            <Text>{yearDiff > 0 ? formatDuration(yearDiff) : '-'}</Text>
          </div>
        )}
      </div>
    )
  }

  const renderRepayment = () => {
    const content = props.cell.value
      ?.map((el) => {
        const { validFrom, amortizationConditionItemTypeShortText, rate } = el || undefined
        return {
          from: isNil(validFrom) ? undefined : formatDate(validFrom),
          type: amortizationConditionItemTypeShortText,
          rate: isNil(rate) ? undefined : formatFraction(rate),
        }
      })
      ?.sort((a, b) => {
        const dateA = new Date(a?.from)
        const dateB = new Date(b?.from)
        return dateA - dateB
      })

    if (props.cell.value.length > 1) {
      const buttonId = `repayment-popover-${props.row.id}`

      if (isPdfView) {
        return (
          <List separators={ListSeparators.Inner} mode={ListMode.None} className={styles.pdfList}>
            {content.map(({ from, type, rate }, index) => (
              <StandardListItem key={index}>
                <FinancingTermsTrancheDetailsMultiLineEntry from={from} type={type} rate={rate} />
              </StandardListItem>
            ))}
          </List>
        )
      }
      return (
        <>
          <Button
            id={buttonId}
            className={styles.trancheDetailsPopover}
            design={ButtonDesign.Transparent}
            onClick={() => setIsPopoverOpen(true)}
          >{`(${props.cell.value.length})`}</Button>
          <FinancingTermsTrancheDetailsPopover
            openerId={buttonId}
            isPopoverOpen={isPopoverOpen}
            setIsPopoverOpen={setIsPopoverOpen}
            content={content ?? []}
          />
        </>
      )
    } else if (props.cell.value.length === 1) {
      return <FinancingTermsTrancheDetailsMultiLineEntry {...(content[0] || {})} />
    } else {
      return <Text>{'-'}</Text>
    }
  }

  const renderInterestRateType = () => {
    const content = props.cell.value
      ?.map((el) => {
        const { validFrom, interestRateShortText, customerMargin } = el || undefined
        return {
          from: isNil(validFrom) ? undefined : formatDate(validFrom),
          type: interestRateShortText,
          rate: isNil(customerMargin) ? undefined : formatFraction(customerMargin),
        }
      })
      ?.sort((a, b) => {
        const dateA = new Date(a?.from)
        const dateB = new Date(b?.from)
        return dateA - dateB
      })

    if (props.cell.value.length > 1) {
      const buttonId = `interest-rate-type-popover-${props.row.id}`

      if (isPdfView) {
        return (
          <List separators={ListSeparators.Inner} mode={ListMode.None} className={styles.pdfList}>
            {content.map(({ from, type, rate }, index) => (
              <StandardListItem key={index}>
                <FinancingTermsTrancheDetailsMultiLineEntry from={from} type={type} rate={rate} />
              </StandardListItem>
            ))}
          </List>
        )
      }

      return (
        <>
          <Button
            id={buttonId}
            className={styles.trancheDetailsPopover}
            design={ButtonDesign.Transparent}
            onClick={() => setIsPopoverOpen(true)}
          >{`(${props.cell.value.length})`}</Button>
          <FinancingTermsTrancheDetailsPopover
            openerId={buttonId}
            isPopoverOpen={isPopoverOpen}
            setIsPopoverOpen={setIsPopoverOpen}
            content={content ?? []}
          />
        </>
      )
    } else if (props.cell.value.length === 1) {
      return <FinancingTermsTrancheDetailsMultiLineEntry {...(content[0] || {})} />
    } else {
      return <Text>{'-'}</Text>
    }
  }

  const renderPercentageCell = () => (
    <div className={styles.caretCursor}>
      {!isNil(props.cell.value) ? formatFraction(props.cell.value) : '-'}
    </div>
  )

  const renderCell = () => <div className={styles.caretCursor}>{props.cell.value ?? ''}</div>

  switch (columnKey) {
    case 'rank':
      return renderCell()
    case 'tranche':
      return renderTrancheCell()
    case 'borrower':
      return renderBorrowerCell()
    case 'loanType':
      return renderCellWithWordBreak()
    case 'outstandingAmount':
      return renderMoneyWithShareCell({ hasShare: false })
    case 'commitment':
      return renderMoneyWithShareCell({})
    case 'availability':
      return renderAvailability()
    case 'maturity':
      return renderMaturity()
    case 'drawdownDates':
      return renderDrawdownDates()
    case 'lastExtensionOption':
      return renderLastExtensionOption()
    case 'repayment':
      return renderRepayment()
    case 'interestRateType':
      return renderInterestRateType()
    case 'netRevenueMargin':
    case 'riskReturnRatio':
      return renderPercentageCell()
    default:
      return <div />
  }
}

const FinancingTermsTrancheDetailsTableCell = ({
  isPdfView = false,
  showConfirmationForLinks = false,
  ...props
}) => {
  const formatMoney = useCustomizableCurrencyFormatter()
  const { format: formatDate } = useShortDateFormatter()
  const formatFraction = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })
  const formatDuration = useNumberFormatter({
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  })

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.financing-terms.tranche-details',
  })

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const rowIndex = props.row.index
  const dataAmount = props.data.length

  if (dataAmount >= 2 && rowIndex === dataAmount - 1) {
    return renderFooterCell(props, formatMoney, formatFraction, t)
  } else {
    return renderTableCell(
      props,
      formatMoney,
      formatFraction,
      formatDate,
      formatDuration,
      t,
      isPopoverOpen,
      setIsPopoverOpen,
      isPdfView,
      showConfirmationForLinks,
    )
  }
}

FinancingTermsTrancheDetailsTableCell.propTypes = {
  cell: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  isPdfView: PropTypes.bool,
  showConfirmationForLinks: PropTypes.bool,
}

export default FinancingTermsTrancheDetailsTableCell
