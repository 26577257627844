import ValueState from '@ui5/webcomponents-base/dist/types/ValueState'
import { useTranslation } from 'react-i18next'

const PROPERTY_FINANCING_STATUS = {
  NOT_FINANCED_NO_DEAL: '00',
  NOT_FINANCED_DEAD_DEAL: '01',
  PIPELINE_FINANCING: '02',
  ACTIVE_FINANCING: '03',
  REPAID_FINANCING: '04',
}

const PROPERTY_CAG_STATUS = {
  NO_COLLATERAL_NO_CAG: '00',
  NO_COLLATERAL_DEAD_DEAL: '01',
  PIPELINE_COLLATERAL: '02',
  ACTIVE_COLLATERAL: '03',
  INACTIVE_COLLATERAL_REAPAID_DEAL: '04',
  ACTIVE_COLLATERAL_REPAID_DEAL: '05',
  ACTIVE_COLLATERAL_NO_DEAL: '06',
  ACTIVE_COLLATERAL_DEAD_DEAL: '07',
}

export const usePropertyStatus = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.properties.header.status.text',
  })

  const objectStatusForFinancingStatus = {
    [PROPERTY_FINANCING_STATUS.NOT_FINANCED_NO_DEAL]: {
      text: t('financing-status.not-financed-no-deal'),
      valueState: ValueState.None,
    },
    [PROPERTY_FINANCING_STATUS.NOT_FINANCED_DEAD_DEAL]: {
      text: t('financing-status.not-financed-dead-deal'),
      valueState: ValueState.None,
    },
    [PROPERTY_FINANCING_STATUS.PIPELINE_FINANCING]: {
      text: t('financing-status.pipeline-financing'),
      valueState: ValueState.Information,
    },
    [PROPERTY_FINANCING_STATUS.ACTIVE_FINANCING]: {
      text: t('financing-status.active-financing'),
      valueState: ValueState.Success,
    },
    [PROPERTY_FINANCING_STATUS.REPAID_FINANCING]: {
      text: t('financing-status.repaid-financing'),
      valueState: ValueState.None,
    },
  }

  const objectStatusForCAGStatus = {
    [PROPERTY_CAG_STATUS.NO_COLLATERAL_NO_CAG]: {
      text: t('cag-status.no-collateral-no-cag'),
      valueState: ValueState.None,
    },
    [PROPERTY_CAG_STATUS.NO_COLLATERAL_DEAD_DEAL]: {
      text: t('cag-status.no-collateral-dead-deal'),
      valueState: ValueState.None,
    },
    [PROPERTY_CAG_STATUS.PIPELINE_COLLATERAL]: {
      text: t('cag-status.pipeline-collateral'),
      valueState: ValueState.Information,
    },
    [PROPERTY_CAG_STATUS.ACTIVE_COLLATERAL]: {
      text: t('cag-status.active-collateral'),
      valueState: ValueState.Success,
    },
    [PROPERTY_CAG_STATUS.INACTIVE_COLLATERAL_REAPAID_DEAL]: {
      text: t('cag-status.inactive-collateral-repaid-deal'),
      valueState: ValueState.None,
    },
    [PROPERTY_CAG_STATUS.ACTIVE_COLLATERAL_REPAID_DEAL]: {
      text: t('cag-status.active-collateral-repaid-deal'),
      valueState: ValueState.None,
    },
    [PROPERTY_CAG_STATUS.ACTIVE_COLLATERAL_NO_DEAL]: {
      text: t('cag-status.active-collateral-no-deal'),
      valueState: ValueState.None,
    },
    [PROPERTY_CAG_STATUS.ACTIVE_COLLATERAL_DEAD_DEAL]: {
      text: t('cag-status.active-collateral-dead-deal'),
      valueState: ValueState.None,
    },
  }

  return {
    objectStatusForFinancingStatus,
    objectStatusForCAGStatus,
    PROPERTY_FINANCING_STATUS,
    PROPERTY_CAG_STATUS,
  }
}
