import {
  FlexBox,
  MessageBoxTypes,
  Button,
  ButtonDesign,
  TableRow,
} from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import MessageBox from 'components/ui/message-box/MessageBox'
import TableCell from 'components/ui/tables/cells/TableCell'
import { DisplayAndEditTableActionCell } from 'components/ui/tables/display-and-edit-table/DisplayAndEditTableActionCell'
import {
  defaultPopoverStaticConfig,
  DisplayAndEditTablePopover,
} from 'components/ui/tables/display-and-edit-table/DisplayAndEditTablePopover'
import { rowKeyNewRow } from 'components/ui/tables/display-and-edit-table/constants'

export const DisplayAndEditTableRow = ({
  tableRow,
  columnDefinitions,
  handleSaveRow,
  handleDeleteRow,
  handleCancelEditRow,
  userIsAllowedToEdit,
  cardType,
  popoverStaticConfig,
  cancelAddRow,
  checkIsValidReturnErrorMessage,
  actionCellStyles,
  isValid = true,
  hasChanges = true,
  isWritingToBackend,
  hideEditButton,
  hideDeleteButton,
  collapseActionsWhenSize,
}) => {
  const [isEdit, setIsEdit] = useState(tableRow.rowKey === rowKeyNewRow)
  const toggleEditRow = () => setIsEdit(!isEdit)

  const [popoverConfig, setPopoverConfig] = useState({ isOpen: false })
  const [messageBoxConfig, setMessageBoxConfig] = useState({ isOpen: false })

  const customRowActions = useMemo(
    () => tableRow?.rowProperties?.customRowActions ?? [],
    [tableRow?.rowProperties?.customRowActions],
  )

  const alignContent = (content, alignment) =>
    alignment ? <FlexBox justifyContent={alignment}>{content}</FlexBox> : content

  const handleCancelClick = () => {
    const rowKey = tableRow.rowKey
    if (!hasChanges) {
      rowKey === rowKeyNewRow ? cancelAddRow() : toggleEditRow()
      handleCancelEditRow(rowKey)
    } else {
      setPopoverConfig({
        rowKey: rowKey,
        isOpen: true,
        mode: 'cancel',
        message: popoverStaticConfig?.cancel?.message ?? defaultPopoverStaticConfig.cancel.message,
        buttonText: popoverStaticConfig?.cancel?.button ?? defaultPopoverStaticConfig.cancel.button,
        handleButtonClick: () => {
          rowKey === rowKeyNewRow ? cancelAddRow() : toggleEditRow()
          handleCancelEditRow(rowKey)
        },
      })
    }
  }

  const handleDeleteClick = () => {
    const rowKey = tableRow.rowKey
    setMessageBoxConfig({
      isOpen: true,
      message: popoverStaticConfig?.delete?.message ?? defaultPopoverStaticConfig.delete.message,
      title:
        popoverStaticConfig?.delete?.title ??
        popoverStaticConfig?.delete?.button ??
        defaultPopoverStaticConfig.delete.button,
      type: MessageBoxTypes.Warning,
      actions: [
        <Button
          key="delete"
          design={ButtonDesign.Emphasized}
          onClick={() => {
            handleDeleteRow(rowKey)
          }}
        >
          {popoverStaticConfig.delete.button ?? defaultPopoverStaticConfig.delete.button}
        </Button>,
        <Button key="cancel">{t('buttons.cancel')}</Button>,
      ],
    })
  }

  const handleSaveClick = () => {
    const rowKey = tableRow.rowKey
    const error = checkIsValidReturnErrorMessage(rowKey)

    if (error.isError !== true) {
      handleSaveRow(rowKey)
      if (rowKey === rowKeyNewRow) {
        cancelAddRow()
      } else if (isNil(isWritingToBackend)) {
        toggleEditRow()
      }
    } else {
      setPopoverConfig({
        rowKey,
        isOpen: true,
        mode: 'save',
        message: error.errorMessage,
        buttonText: t('buttons.ok'),
        handleButtonClick: () => {},
      })
    }
  }

  const renderCells = () => {
    let cells

    if (!isEdit) {
      cells = columnDefinitions.map((colDef) => (
        <TableCell
          key={tableRow.rowKey + '-' + colDef.columnKey}
          {...tableRow[colDef.columnKey].cellReadModeProps}
        >
          {alignContent(tableRow[colDef.columnKey].cellContentReadMode, colDef.alignment)}
        </TableCell>
      ))
    } else {
      cells = columnDefinitions.map((colDef) => (
        <TableCell
          key={tableRow.rowKey + '-' + colDef.columnKey}
          {...tableRow[colDef.columnKey].cellEditModeProps}
        >
          {alignContent(tableRow[colDef.columnKey].cellContentEditMode, colDef.alignment)}
        </TableCell>
      ))
    }

    const actionCell = (
      <DisplayAndEditTableActionCell
        key={`${tableRow.rowKey}-actionCell`}
        rowKey={tableRow.rowKey}
        handleSaveClick={handleSaveClick}
        handleCancelClick={handleCancelClick}
        handleEditClick={toggleEditRow}
        handleDeleteClick={handleDeleteClick}
        cardType={cardType}
        isValid={isValid}
        isEdit={isEdit}
        isWritingToBackend={isWritingToBackend}
        rowChanged={hasChanges}
        styles={actionCellStyles}
        userIsAllowedToEdit={userIsAllowedToEdit}
        hideEditButton={hideEditButton}
        hideDeleteButton={hideDeleteButton}
        customRowActions={customRowActions}
        collapseActionsWhenSize={collapseActionsWhenSize}
      />
    )
    return [...cells, actionCell]
  }

  return (
    <>
      {createPortal(
        <DisplayAndEditTablePopover
          isOpen={popoverConfig.isOpen}
          closePopover={() => setPopoverConfig({ isOpen: false })}
          rowKey={popoverConfig.rowKey}
          cardType={cardType}
          mode={popoverConfig.mode}
          message={popoverConfig.message}
          handleButtonClick={popoverConfig.handleButtonClick}
          buttonText={popoverConfig.buttonText}
        />,
        document.body,
      )}
      {createPortal(
        <MessageBox
          titleText={messageBoxConfig.title}
          open={messageBoxConfig.isOpen}
          type={messageBoxConfig.type}
          onClose={() => setMessageBoxConfig({ isOpen: false })}
          actions={messageBoxConfig.actions}
        >
          {messageBoxConfig.message ?? ''}
        </MessageBox>,
        document.body,
      )}
      <TableRow key={tableRow.rowKey} {...tableRow.rowProperties}>
        {renderCells()}
      </TableRow>
    </>
  )
}

DisplayAndEditTableRow.propTypes = {
  columnDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      columnKey: PropTypes.string.isRequired,
      title: PropTypes.string,
    }),
  ),
  tableRow: PropTypes.object.isRequired,
  handleSaveRow: PropTypes.func.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
  handleCancelEditRow: PropTypes.func.isRequired,
  cancelAddRow: PropTypes.func.isRequired,
  cardType: PropTypes.string,
  checkIsValidReturnErrorMessage: PropTypes.func.isRequired,
  userIsAllowedToEdit: PropTypes.bool.isRequired,
  popoverStaticConfig: PropTypes.object.isRequired,
  isValid: PropTypes.bool,
  hasChanges: PropTypes.bool,
  actionCellStyles: PropTypes.shape({
    editModeCell: PropTypes.string,
    readModeCell: PropTypes.string,
    editModeButtons: PropTypes.string,
    readModeButtons: PropTypes.string,
  }),
  isWritingToBackend: PropTypes.bool,
  hideEditButton: PropTypes.bool,
  hideDeleteButton: PropTypes.bool,
  collapseActionsWhenSize: PropTypes.number,
}
