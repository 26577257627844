import { Icon } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React from 'react'

const propTypes = {
  /** Defines additional styling to the outmost div of the cell's content. */
  className: PropTypes.string,
}

/**
 * Shared Navigation Cell for usage within the Responsive and Analytical Table.
 *
 * @typedef Props
 * @property {String} className Defines additional styling to the outmost div of the cell's content.
 */

/**
 * @param {Props} props Note for Storybook: cf. code for complete JSDoc
 */
const NavigationCell = ({ className } = {}) => (
  <div className={className ?? ''}>
    <Icon slot="icon" name="navigation-right-arrow" />
  </div>
)

NavigationCell.propTypes = propTypes

export default NavigationCell
