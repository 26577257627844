import {
  Label,
  Toolbar,
  ToolbarDesign,
  ToolbarSpacer,
  ToolbarStyle,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConditionsTableToolbarFilterDialog from 'components/domains/conditions/overview/ConditionsTableToolbarFilterDialog'
import FilterInfoBar from 'components/ui/tables/toolbar/FilterInfoBar'
import tableToolBarStyles from 'components/ui/tables/toolbar/TablesToolbar.module.css'
import ToolbarSearching from 'components/ui/tables/toolbar/ToolbarSearching'
import ToolbarSorting from 'components/ui/tables/toolbar/ToolbarSorting'

const ConditionsTileToolbar = ({
  numberOfConditions,
  filters,
  setFilters,
  searchParam,
  onUpdateSearchParam,
  sorting,
  isEditMode,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.conditions.internal.toolbar',
  })
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false)

  return (
    <>
      <Toolbar
        design={ToolbarDesign.Auto}
        toolbarStyle={ToolbarStyle.Clear}
        className={tableToolBarStyles.toolbar}
      >
        <Label className={tableToolBarStyles.titleLabel}>
          {t('title', { numberOfConditions })}
        </Label>
        {isEditMode && (
          <>
            <ToolbarSpacer />
            <ToolbarSearching searching={{ searchParam, onUpdateSearchParam }} />
            <ToolbarSorting sorting={sorting} />
            <ConditionsTableToolbarFilterDialog
              isOpen={isFilterDialogOpen}
              setIsOpen={setIsFilterDialogOpen}
              filters={filters}
              setFilters={setFilters}
            />
          </>
        )}
      </Toolbar>
      {isEditMode && (
        <FilterInfoBar setIsFilterDialogOpen={setIsFilterDialogOpen} filters={filters} />
      )}
    </>
  )
}

ConditionsTileToolbar.propTypes = {
  numberOfConditions: PropTypes.number.isRequired,
  columnSelection: PropTypes.arrayOf(PropTypes.string),
  setColumnSelection: PropTypes.func,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      columnKey: PropTypes.string,
      type: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    }),
  ).isRequired,
  setFilters: PropTypes.func.isRequired,
  searchParam: PropTypes.string.isRequired,
  onUpdateSearchParam: PropTypes.func.isRequired,
  sorting: PropTypes.shape({
    columnKey: PropTypes.string,
    isSortingAscending: PropTypes.bool,
    sortableColumns: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        columnKey: PropTypes.string.isRequired,
      }),
    ).isRequired,
    onUpdateSorting: PropTypes.func.isRequired,
  }),
  isEditMode: PropTypes.bool.isRequired,
}

export default ConditionsTileToolbar
