import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import { useEditDocumentGeneralInformation } from 'hooks/services/documents/useEditDocumentGeneralInformation'
import usePatchDocumentReferences, {
  patchDocumentReferencesOperations,
} from 'hooks/services/documents/usePatchDocumentReferences'

const useMoveDocumentsForRequirements = ({ documentType, requirementId }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', { keyPrefix: 'components.requirements.table.drop' })
  const { mutate: mutateDocumentReferences } = usePatchDocumentReferences()
  const { mutate: changeDocumentGeneralInformation } = useEditDocumentGeneralInformation()
  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()

  const showGenericError = useCallback(() => {
    showMessageBox({ type: MessageBoxTypes.Error, children: tNoPrefix('error.default') })
  }, [showMessageBox, tNoPrefix])

  const showProcessError = useCallback(() => {
    showMessageBox({ type: MessageBoxTypes.Error, children: t('single-document.move.error') })
    queryClient.invalidateQueries(['documents'])
  }, [queryClient, showMessageBox, t])

  const onAddDocumentReferenceSuccess = useCallback(
    ({ requirementIdOfDraggedDocument }) =>
      () => {
        showToast({ children: tNoPrefix('toast.changes-saved') })
        queryClient.invalidateQueries(['documents', DocumentTypes.Requirement, requirementId])
        requirementIdOfDraggedDocument &&
          queryClient.invalidateQueries([
            'documents',
            DocumentTypes.Requirement,
            requirementIdOfDraggedDocument,
          ])
      },
    [queryClient, showToast, tNoPrefix, requirementId],
  )

  const handleAddDocumentReference = useCallback(
    ({ documentId, requirementIdOfDraggedDocument }) => {
      mutateDocumentReferences(
        {
          entityRefs: [{ type: DocumentTypes.Requirement, id: requirementId }],
          operation: patchDocumentReferencesOperations.add,
          documentId,
        },
        {
          onSuccess: onAddDocumentReferenceSuccess({ requirementIdOfDraggedDocument }),
          onError: showProcessError,
        },
      )
    },
    [mutateDocumentReferences, onAddDocumentReferenceSuccess, requirementId, showProcessError],
  )

  const onSuccessDocumentTypeChanged = useCallback(
    ({ documentId, requirementIdOfDraggedDocument }) =>
      () => {
        handleAddDocumentReference({ documentId, requirementIdOfDraggedDocument })
      },
    [handleAddDocumentReference],
  )

  const handleDocumentTypeNeedsChange = useCallback(
    ({
      documentId,
      documentName,
      documentKeyDate,
      newDocumentType,
      requirementIdOfDraggedDocument,
    }) => {
      changeDocumentGeneralInformation(
        { documentId, name: documentName, type: newDocumentType, keyDate: documentKeyDate },
        {
          onSuccess: onSuccessDocumentTypeChanged({ documentId, requirementIdOfDraggedDocument }),
          onError: showProcessError,
        },
      )
    },
    [changeDocumentGeneralInformation, onSuccessDocumentTypeChanged, showProcessError],
  )

  const onRemoveDocumentReferencesSuccess = useCallback(
    ({
        documentId,
        documentName,
        documentKeyDate,
        newDocumentType,
        requirementIdOfDraggedDocument,
      }) =>
      () => {
        if (documentType !== newDocumentType) {
          handleDocumentTypeNeedsChange({
            documentId,
            documentName,
            documentKeyDate,
            newDocumentType,
            requirementIdOfDraggedDocument,
          })
          return
        }
        handleAddDocumentReference({ documentId, requirementIdOfDraggedDocument })
      },
    [documentType, handleAddDocumentReference, handleDocumentTypeNeedsChange],
  )

  const handleMove = useCallback(
    ({
        documentId,
        documentName,
        documentKeyDate,
        newDocumentType,
        requirementIdOfDraggedDocument,
      }) =>
      () => {
        mutateDocumentReferences(
          {
            entityRefs: [{ type: DocumentTypes.Requirement, id: requirementIdOfDraggedDocument }],
            operation: patchDocumentReferencesOperations.remove,
            documentId,
          },
          {
            onSuccess: onRemoveDocumentReferencesSuccess({
              documentId,
              documentName,
              documentKeyDate,
              newDocumentType,
              requirementIdOfDraggedDocument,
            }),
            onError: showGenericError,
          },
        )
      },
    [mutateDocumentReferences, onRemoveDocumentReferencesSuccess, showGenericError],
  )

  return useMemo(() => ({ handleMove }), [handleMove])
}

export default useMoveDocumentsForRequirements
