import PropTypes from 'prop-types'
import React from 'react'
import LoadingMultiComboBox from 'components/ui/multi-combo-box/LoadingMultiComboBox'
import useCountriesForLoadingDropdown from 'hooks/services/business-partners/config/useCountriesForLoadingDropdown'

const CountryComboBox = React.forwardRef(
  ({ id, onSelectionChange, selectedValues: selected = [] }, _ref) => (
    <LoadingMultiComboBox
      id={id}
      useLoader={useCountriesForLoadingDropdown}
      selected={selected}
      onSelectionChange={onSelectionChange}
      onFocus={(event) => event.stopPropagation()}
    />
  ),
)

CountryComboBox.displayName = 'CountryComboBox'

CountryComboBox.propTypes = {
  id: PropTypes.string,
  onSelectionChange: PropTypes.func,
  selectedValues: PropTypes.array,
}

export default CountryComboBox
