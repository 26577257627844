import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessExposureTile from 'components/domains/business-partners/tile/exposure/BusinessExposureTile'

const CreditAuthorityLimitOverview = ({
  creditAuthorityLimitContribution,
  pipelineExposure,
  totalLabel,
  isLoading,
  isError,
  errorStatus,
  errorCode,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.business-partner' })
  const { t: tNoPrefix } = useTranslation()

  const calculateDataSectionOne = useMemo(() => {
    const isValid = (value) => !isNil(value) && value !== 0

    const creditContributionValid = isValid(creditAuthorityLimitContribution?.value)
    const pipelineExposureValid = isValid(pipelineExposure?.value)

    const calculatedValue =
      creditContributionValid && pipelineExposureValid
        ? creditAuthorityLimitContribution.value + pipelineExposure.value
        : null

    const dataSectionOne = [
      ...(creditContributionValid ? [creditAuthorityLimitContribution] : []),
      ...(pipelineExposureValid ? [pipelineExposure] : []),
      ...(!isNil(calculatedValue)
        ? [
            {
              value: calculatedValue,
              unit: creditAuthorityLimitContribution?.unit,
              label: totalLabel,
            },
          ]
        : []),
    ]

    return dataSectionOne
  }, [creditAuthorityLimitContribution, pipelineExposure, totalLabel])

  const conflictError = 409

  const conflictErrorTitle = useMemo(() => {
    if (errorStatus === conflictError) {
      if (errorCode === 'ALC_BU_CALC_ERROR') {
        return tNoPrefix('components.cards.error.bu-error-title')
      } else {
        return tNoPrefix('components.cards.error.conflict-error-title')
      }
    }
    return ''
  }, [errorStatus, conflictError, errorCode, tNoPrefix])

  const conflictErrorText = useMemo(() => {
    if (errorStatus === conflictError) {
      if (errorCode === 'ALC_BU_CALC_ERROR') {
        return tNoPrefix('components.cards.error.bu-error-subtitle')
      } else {
        return tNoPrefix('components.cards.error.conflict-error-subtitle')
      }
    }
    return ''
  }, [errorStatus, conflictError, errorCode, tNoPrefix])

  return (
    <BusinessExposureTile
      title={t('total-exposure')}
      sections={[calculateDataSectionOne]}
      isLoading={isLoading}
      isError={isError}
      conflictErrorTitle={conflictErrorTitle}
      conflictErrorText={conflictErrorText}
    />
  )
}

CreditAuthorityLimitOverview.propTypes = {
  creditAuthorityLimitContribution: PropTypes.shape({
    value: PropTypes.number,
    unit: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  pipelineExposure: PropTypes.shape({
    value: PropTypes.number,
    unit: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  totalLabel: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  errorStatus: PropTypes.number,
  errorCode: PropTypes.string,
}

export default CreditAuthorityLimitOverview
