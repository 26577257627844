import { DatePicker, ValueState } from '@fioneer/ui5-webcomponents-react'
import cloneDeep from 'lodash.clonedeep'
import merge from 'lodash.merge'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { monitoringInformationShape } from 'components/domains/business-partners/periodical-checks/monitoring-information/PeriodicalMonitoringPropTypes'
import { isDateValid } from 'components/domains/business-partners/periodical-checks/monitoring-information/useValidateMonitoringInformation'
import { CardEditItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import CheckCycleInputSelect from 'components/domains/deals/covenants/monitoring-information-card/CheckCycleInputSelect'
import RelativeDateComparisonEdit from 'components/domains/deals/covenants/monitoring-information-card/RelativeDateComparisonEdit'
import LabeledSwitch from 'components/ui/input/LabeledSwitch'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const PeriodicalMonitoringInformationEdit = ({
  formState,
  updateFormState,
  configurationForCovenant,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-partners.periodical-checks.monitoring-card',
  })
  const { parse: parseDate, localePattern } = useShortDateFormatter()

  const {
    checkRequired,
    firstDueDate: {
      keyDate: monitoringKeyDate,
      targetDeliveryDateBaseData: monitoringTargetDeliveryDateBaseData,
      checkDateBaseData: monitoringCheckDateBaseData,
    } = {},
    checkCycle,
  } = formState
  const {
    withDocumentsRequest,
    checkCycle: { modifiable: isCheckCycleModifiable } = {},
    existingCheckCycles,
  } = configurationForCovenant ?? { checkCycle: { modifiable: true } }

  const onMonitoringInformationValueChange = (value) => {
    const oldFormState = cloneDeep(formState)
    const newFormState = merge(oldFormState, value)
    updateFormState(newFormState)
  }

  return (
    <CardSection>
      <CardEditItem
        id="check-required"
        label={t('check-required')}
        required
        value={
          <LabeledSwitch
            isLoading={false}
            checked={!!checkRequired}
            onChange={(event) =>
              onMonitoringInformationValueChange({ checkRequired: !!event.target.checked })
            }
          />
        }
      />
      {!!checkRequired && (
        <>
          <CardEditItem
            required
            id="monitoring-key-date"
            label={t('first-key-date-test-date')}
            value={
              <DatePicker
                value={monitoringKeyDate}
                valueState={!isDateValid(monitoringKeyDate) ? ValueState.Error : ValueState.None}
                formatPattern={localePattern}
                onChange={(event) =>
                  onMonitoringInformationValueChange({
                    firstDueDate: {
                      keyDate: parseDate(event.target.value, localePattern) ?? event.target.value,
                    },
                  })
                }
              />
            }
          />
          {withDocumentsRequest && (
            <CardEditItem
              required
              id="monitoring-target-delivery-date"
              label={t('target-delivery-date')}
              value={
                <RelativeDateComparisonEdit
                  value={monitoringTargetDeliveryDateBaseData}
                  isRequired
                  onChange={(data) =>
                    onMonitoringInformationValueChange({
                      firstDueDate: { targetDeliveryDateBaseData: data },
                    })
                  }
                  referenceDateTranslation={t('key-date')}
                />
              }
            />
          )}
          <CardEditItem
            required
            id="monitoring-check-due-date"
            label={t('check-due-date')}
            value={
              <RelativeDateComparisonEdit
                value={monitoringCheckDateBaseData}
                isRequired
                onChange={(data) =>
                  onMonitoringInformationValueChange({
                    firstDueDate: { checkDateBaseData: data },
                  })
                }
                referenceDateTranslation={
                  withDocumentsRequest ? t('target-delivery-date') : t('key-date-test-date')
                }
                isDirectionSelectDisabled
              />
            }
          />
          <CardEditItem
            required
            id="check-cycle"
            label={t('check-cycle')}
            value={
              <CheckCycleInputSelect
                isModifiable={isCheckCycleModifiable}
                selectableCheckCycles={existingCheckCycles}
                value={checkCycle}
                onChange={(data) => onMonitoringInformationValueChange({ checkCycle: data })}
              />
            }
          />
        </>
      )}
    </CardSection>
  )
}

PeriodicalMonitoringInformationEdit.propTypes = {
  formState: monitoringInformationShape,
  updateFormState: PropTypes.func.isRequired,
  configurationForCovenant: PropTypes.shape({
    withDocumentsRequest: PropTypes.bool,
  }),
}

export default PeriodicalMonitoringInformationEdit
