import camelize from 'camelize'
import { useRequests } from 'hooks/services/baseService'

export const useMultiplePropertiesByUuid = (propertyUuids, options) => {
  const requests = propertyUuids.map((uuid) => ({
    path: `/properties/${uuid}`,
    translated: true,
    useCache: true,
    keys: ['properties', uuid],
  }))

  const results = useRequests({
    requests,
    options,
  })

  return results.map((result) => ({
    ...result,
    data: camelize(result.data),
  }))
}
