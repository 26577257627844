import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { UI5_TABLE_CELL_PADDING } from 'components/ui/tables/display-and-edit-table/constants'

const getDefaultColumnDefinition = (columnKey, t) => ({
  columnKey: columnKey,
  title: t(columnKey),
  popinText: t(columnKey),
  demandPopin: true,
})

const useEventSearchDialogColumnDefinitions = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.events.search.dialog.table.label',
  })
  const columns = useMemo(
    () => ({
      businessEvent: {
        minWidth: 500,
      },
      assignee: {
        minWidth: 160,
      },
      currentDueDate: {
        minWidth: 250,
      },
      status: {
        minWidth: 150,
      },
    }),
    [],
  )
  return useMemo(() => {
    let minWidthSum = 0
    return Object.keys(columns).map((columnKey) => {
      // we still add each column's minWidth to the sum,
      // because we need the overall width relative to the table width for the breakpoint
      minWidthSum += (columns[columnKey].minWidth ?? 0) + UI5_TABLE_CELL_PADDING
      return {
        ...getDefaultColumnDefinition(columnKey, t),
        ...columns[columnKey],
        minWidth: minWidthSum,
      }
    })
  }, [columns, t])
}

export default useEventSearchDialogColumnDefinitions
