import { Label, Link, Text } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardHeaderLastEditedText } from 'components/domains/deals/card/CardHeaderLastEditedText'
import styles from 'components/domains/deals/card/CardItem.module.css'
import CardSection from 'components/domains/deals/card/CardSection'
import CashFlowScenarioCardHeaderSelectAction from 'components/domains/deals/covenant-check-detail/cash-flow-scenario/CashFlowScenarioCardHeaderSelectAction'
import CashFlowScenarioCardTable from 'components/domains/deals/covenant-check-detail/cash-flow-scenario/CashFlowScenarioCardTable'
import { periodValues } from 'components/domains/deals/deal-cashflow/results/PeriodSelect'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import Card from 'components/ui/card/Card'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import { ErrorDataUnavailableInTable } from 'components/ui/errors/ErrorDataUnavailableInTable'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import { useCashFlowScenario } from 'hooks/services/deals/cashflow/useCashFlowScenario'
import useCashflowGroups from 'hooks/services/deals/cashflow/useCashflowGroups'
import useCashflowScenarioResultsById from 'hooks/services/deals/cashflow/useCashflowScenarioResultsById'
import useCovenantCheckById from 'hooks/services/deals/covenants/useCovenantCheckById'
import useDeleteCovenantCheckLinkedCashFlowScenario from 'hooks/services/deals/covenants/useDeleteCovenantCheckLinkedCashFlowScenario'
import useUpdateCovenantCheckLinkedCashFlowScenario from 'hooks/services/deals/covenants/useUpdateCovenantCheckLinkedCashFlowScenario'
import useDealMini from 'hooks/services/deals/useDealMini'
import { editButtonStateEnum } from 'routes/deals/covenant-checks/useCalculateEditButtonState'

const CashFlowScenarioCard = ({ covenantCheckUuid, selectButtonState }) => {
  const {
    isLoading: isLoadingCovenantCheck,
    isError: isErrorCovenantCheck,
    data: {
      lastUpdatedBy,
      lastUpdatedAt,
      keyDate,
      linkedCashflowScenarioUuid,
      covenantMonitoringItems,
    } = {},
  } = useCovenantCheckById(covenantCheckUuid)

  const dealUuid = covenantMonitoringItems?.[0]?.covenant?.mainEntity?.id

  const {
    data: { name: cashFlowScenarioName } = {},
    isLoading: isLoadingScenario,
    isError: isErrorScenario,
    isFetching: isFetchingScenario,
  } = useCashFlowScenario(dealUuid, linkedCashflowScenarioUuid, {
    enabled: !!linkedCashflowScenarioUuid && !!dealUuid,
  })

  const {
    data: { calculatedKpis = [] } = {},
    isLoading: isLoadingResults,
    isError: isErrorResults,
    isFetching: isFetchingResults,
  } = useCashflowScenarioResultsById(
    {
      dealUuid: dealUuid,
      cashflowId: linkedCashflowScenarioUuid,
      datasetPeriod: periodValues.years,
    },
    { enabled: !!linkedCashflowScenarioUuid && !!dealUuid },
  )

  const {
    data: { groups = [] } = {},
    isLoading: isLoadingGroups,
    isError: isErrorGroups,
  } = useCashflowGroups()

  const {
    data: { dealId } = {},
    isLoading: isLoadingDeal,
    isError: isErrorDeal,
    isFetching: isFetchingDeal,
  } = useDealMini(dealUuid)

  const { t: tCashFlow } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.checked-covenants.cash-flow-scenario',
  })

  const [cashFlowScenario, setCashFlowScenario] = useState({
    selectedCashFlowScenarioUuid: linkedCashflowScenarioUuid,
  })

  const queryClient = useQueryClient()
  const showErrorMessageBox = useShowErrorMessageBox()

  const onSaveSuccess = useCallback(() => {
    queryClient.invalidateQueries(['covenants', 'covenant-checks', covenantCheckUuid])
  }, [covenantCheckUuid, queryClient])

  const onSaveError = async (error) => {
    const { errors: [errorResponse] = [] } = await error.response.json()
    showErrorMessageBox({ message: tCashFlow('update-link-error'), error: errorResponse })
  }

  const { mutate: updateLinkedCashFlowScenario } = useUpdateCovenantCheckLinkedCashFlowScenario({
    onSuccess: onSaveSuccess,
    onError: onSaveError,
  })
  const { mutate: deleteLinkedCashFlowScenario } = useDeleteCovenantCheckLinkedCashFlowScenario({
    onSuccess: onSaveSuccess,
    onError: onSaveError,
  })

  const handleSaveSelectedScenario = useCallback(() => {
    if (cashFlowScenario?.selectedCashFlowScenarioUuid) {
      updateLinkedCashFlowScenario({
        covenantCheckUuid,
        cashflowScenario: {
          cashflowScenarioUuid: cashFlowScenario?.selectedCashFlowScenarioUuid,
        },
      })
    } else {
      deleteLinkedCashFlowScenario({ covenantCheckUuid })
    }
  }, [
    cashFlowScenario?.selectedCashFlowScenarioUuid,
    covenantCheckUuid,
    deleteLinkedCashFlowScenario,
    updateLinkedCashFlowScenario,
  ])

  const isLoadingCashflow =
    (isLoadingScenario && isFetchingScenario) || (isLoadingResults && isFetchingResults)

  const isLoading = isLoadingCovenantCheck || isLoadingGroups || isLoadingCashflow
  const isError = isErrorCovenantCheck || isErrorResults || isErrorGroups || isErrorScenario

  const cashFlowNameLink = useCallback(
    () =>
      !!dealUuid && !!linkedCashflowScenarioUuid ? (
        <Link
          id={`${dealUuid}-${linkedCashflowScenarioUuid}-link`}
          href={`/deals/${dealId}/cashflows/${linkedCashflowScenarioUuid}`}
          target="_blank"
        >
          {cashFlowScenarioName}
        </Link>
      ) : (
        tCashFlow('name.empty')
      ),
    [cashFlowScenarioName, dealId, dealUuid, linkedCashflowScenarioUuid, tCashFlow],
  )

  const cashFlowNameError = <Text>{tCashFlow('name.error')}</Text>

  return (
    <Card
      header={
        <CardHeaderLastEditedText
          title={tCashFlow('card.title')}
          email={lastUpdatedBy}
          timestamp={lastUpdatedAt}
          actions={
            <CashFlowScenarioCardHeaderSelectAction
              dealUuid={dealUuid}
              linkedCashflowScenarioUuid={linkedCashflowScenarioUuid}
              cashFlowScenario={cashFlowScenario}
              setCashFlowScenario={setCashFlowScenario}
              handleSaveSelectedScenario={handleSaveSelectedScenario}
              selectButtonState={selectButtonState}
            />
          }
        />
      }
    >
      <RequestStateResolver
        isLoading={isLoading}
        isError={isError}
        errorToDisplay={<ErrorDataUnavailableInTable />}
        center
        renderContent={() =>
          linkedCashflowScenarioUuid ? (
            <>
              <CardSection>
                <div className={styles.cardItem}>
                  <Label id="cash-flow-scenario-name" className={styles.label} wrapping>
                    {tCashFlow('name')}
                  </Label>
                  <SmallLoadingWrapper
                    id={`${cashFlowScenarioName}-loader`}
                    isLoading={isLoadingDeal && isFetchingDeal}
                    isError={isErrorDeal}
                    error={cashFlowNameError}
                    renderContent={cashFlowNameLink}
                    showErrorInHover
                  />
                </div>
              </CardSection>
              <CardSection>
                <CashFlowScenarioCardTable
                  keyDate={keyDate}
                  calculatedKpis={calculatedKpis}
                  groups={groups}
                />
              </CardSection>
            </>
          ) : (
            <EmptyCardContent
              title={tCashFlow('empty-text')}
              subtitle={tCashFlow('empty-text.subtitle')}
            />
          )
        }
      />
    </Card>
  )
}

CashFlowScenarioCard.propTypes = {
  covenantCheckUuid: PropTypes.string.isRequired,
  selectButtonState: PropTypes.oneOf(Object.values(editButtonStateEnum)),
}

export default CashFlowScenarioCard
