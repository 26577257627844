import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  MessageStrip,
  MessageStripDesign,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CardSection from 'components/domains/deals/card/CardSection'
import KpiCardHeader from 'components/domains/kpis/KpiCardHeader'
import styles from 'components/domains/kpis/adjustment/KpiAdjustmentCard.module.css'
import KpiAdjustmentTable from 'components/domains/kpis/adjustment/KpiAdjustmentTable'
import Card from 'components/ui/card/Card'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const sortAdjustmentByDate = (date1, date2) => {
  if (date1 < date2 || isNaN(date1)) return 1
  else return -1
}

const KpiAdjustmentCard = ({
  kpi,
  referenceEntityType,
  referenceEntityId,
  allAdjustments = [],
  isSubKpi,
}) => {
  const { t } = useTranslation('translation')

  const { format: formatDate } = useShortDateFormatter()

  const today = useMemo(() => new Date(), [])

  const activeAdjustment = useMemo(
    () =>
      allAdjustments
        .sort((adjustment1, adjustment2) => {
          const keyDateA = new Date(adjustment1.validTo).getTime()
          const keyDateB = new Date(adjustment2.validTo).getTime()
          return sortAdjustmentByDate(keyDateA, keyDateB)
        })
        .find((adjustment) => {
          const validFrom = new Date(adjustment.validFrom)
          const validTo = new Date(adjustment.validTo)
          return today >= validFrom && today <= validTo
        }),
    [allAdjustments, today],
  )

  const kpiHeaderTitle = isSubKpi
    ? t('components.kpis.details.adjustments.sub-kpi')
    : t('components.kpis.details.adjustments.kpi')

  return (
    <Card header={<KpiCardHeader kpi={kpi} title={kpiHeaderTitle} />}>
      <CardSection>
        <FlexBox
          className={styles.messageStrip}
          direction={FlexBoxDirection.Row}
          alignItems={FlexBoxAlignItems.Center}
        >
          {activeAdjustment && activeAdjustment.kpiCode !== kpi.code && (
            <MessageStrip hideCloseButton design={MessageStripDesign.Warning}>
              {t('components.kpis.adjustments.message-strip', {
                validTo: formatDate(activeAdjustment.validTo),
                kpiName: activeAdjustment.kpiName,
              })}
            </MessageStrip>
          )}
        </FlexBox>
        <KpiAdjustmentTable
          referenceEntityType={referenceEntityType}
          referenceEntityId={referenceEntityId}
          kpi={kpi}
          allAdjustments={allAdjustments}
        />
      </CardSection>
    </Card>
  )
}

KpiAdjustmentCard.propTypes = {
  kpi: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    unit: PropTypes.shape({
      type: PropTypes.string,
      code: PropTypes.string,
    }),
    adjustments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.number,
        validFrom: PropTypes.string,
        validTo: PropTypes.string,
        updatedBy: PropTypes.string,
        updatedAt: PropTypes.string,
        comment: PropTypes.string,
      }),
    ),
  }),
  referenceEntityType: PropTypes.string,
  referenceEntityId: PropTypes.string,
  allAdjustments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      kpiCode: PropTypes.string,
      kpiName: PropTypes.string,
      validFrom: PropTypes.string,
      validTo: PropTypes.string,
    }),
  ),
  isSubKpi: PropTypes.bool.isRequired,
}

export default KpiAdjustmentCard
