const FORMAT_TO_ICON_NAME = new Map([
  ['-', 'document'],
  ['doc', 'doc-attachment'],
  ['docx', 'doc-attachment'],
  ['docm', 'doc-attachment'],
  ['dot', 'doc-attachment'],
  ['dotm', 'doc-attachment'],
  ['ppt', 'ppt-attachment'],
  ['pptx', 'ppt-attachment'],
  ['pptm', 'ppt-attachment'],
  ['pps', 'ppt-attachment'],
  ['pdf', 'pdf-attachment'],
  ['txt', 'attachment-text-file'],
  ['png', 'attachment-photo'],
  ['zip', 'attachment-zip-file'],
  ['jpg', 'attachment-photo'],
  ['jpeg', 'attachment-photo'],
  ['tiff', 'attachment-photo'],
  ['svg', 'attachment-photo'],
  ['psd', 'attachment-photo'],
  ['eps', 'attachment-photo'],
  ['ai', 'attachment-photo'],
  ['indd', 'attachment-photo'],
  ['raw', 'attachment-photo'],
  ['xls', 'excel-attachment'],
  ['xlsx', 'excel-attachment'],
  ['xlsm', 'excel-attachment'],
  ['xlsb', 'excel-attachment'],
  ['xltx', 'excel-attachment'],
  ['xltm', 'excel-attachment'],
  ['xlt', 'excel-attachment'],
  ['xml', 'excel-attachment'],
  ['xla', 'excel-attachment'],
  ['xlw', 'excel-attachment'],
  ['xlr', 'excel-attachment'],
  ['xlam', 'excel-attachment'],
  ['msg', 'email'],
  ['eml', 'email'],
  ['pst', 'email'],
  ['mbox', 'email'],
  ['ost', 'email'],
  ['edb', 'email'],
])

export default FORMAT_TO_ICON_NAME
