import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultipleCwpPropertyValuations = ({ propertyUuids, options = {} }) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: propertyUuids?.map((propertyUuid) => ({
        keys: ['properties', propertyUuid, 'cwp-valuations'],
        path: `/properties/${propertyUuid}/cwp-valuations`,
      })),
      translated: true,
      useCache: true,
      options: { enabled: !isEmpty(propertyUuids), ...options },
    }) ?? [],
  )

  return useMemo(
    () =>
      responses?.map((result, index) => ({
        ...result,
        data: { propertyUuid: propertyUuids[index], ...camelize(result.data) },
      })),
    [propertyUuids, responses],
  )
}

export default useMultipleCwpPropertyValuations
