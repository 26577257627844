import { useRequest } from 'hooks/services/baseService'

const useMarketsAllowedOperations = () =>
  useRequest({
    path: '/markets/allowed-operations',
    useCache: true,
    keys: ['markets', 'permissions'],
  })

export default useMarketsAllowedOperations
