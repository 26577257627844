import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useEventEntityNameSuggestions = (entityType, search) =>
  useCamelizedResponse(
    useRequest({
      path: `/events/entity-suggestions?entity_type=${entityType}&search=${search}`,
      useCache: true,
      keys: ['events', 'entity-types', entityType, search],
    }),
  )

export default useEventEntityNameSuggestions
