import {
  FlexBox,
  FlexBoxJustifyContent,
  Link,
  ObjectStatus,
  Table,
  TableCell,
  TableColumn,
  TableRow,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'

import orderBy from 'lodash.orderby'
import sumBy from 'lodash.sumby'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DecisionStageStatus from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageStatus'
import useApprovalAndDecisionPaperStatus, {
  IN_SYNC,
  OUT_OF_SYNC,
} from 'components/domains/business-partners/tile/arrears/current-approvals/useApprovalAndDecisionPaperStatus'
import styles from 'components/domains/business-partners/tile/arrears/past-approvals/ArrearsPastApprovalsTable.module.css'
import TablesToolbar from 'components/ui/tables/toolbar/TablesToolbar'
import { useCustomizableCurrencyFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'

const PLACE_HOLDER = '-'
const CURRENCY = 'EUR'

const MultilineItem = ({ linkText, text, eventId }) => (
  <div className={styles.textTop}>
    <Link href={`/business-events-and-tasks/business-events/${eventId}`} target="_blank">
      {linkText}
    </Link>
    <br />
    <Text wrapping={true} className={styles.multiText}>
      {text ?? PLACE_HOLDER}
    </Text>
  </div>
)
MultilineItem.propTypes = {
  text: PropTypes.string,
  linkText: PropTypes.string,
  eventId: PropTypes.string,
}

const Item = ({ text, textRight }) => (
  <FlexBox justifyContent={textRight ? FlexBoxJustifyContent.End : FlexBoxJustifyContent.Start}>
    <Text wrapping={true}>{text ?? PLACE_HOLDER}</Text>
  </FlexBox>
)
Item.propTypes = {
  text: PropTypes.string,
  textRight: PropTypes.bool,
}

const DecisionSyncStatus = ({ eventId }) => {
  const { decisionPaperSyncStatus } = useApprovalAndDecisionPaperStatus({ event: { id: eventId } })
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.in-approval',
  })
  if (decisionPaperSyncStatus() === OUT_OF_SYNC) {
    return (
      <ObjectStatus inverted state={ValueState.Success}>
        {t('decision-status.out-of-sync')}
      </ObjectStatus>
    )
  }
  if (decisionPaperSyncStatus() === IN_SYNC) {
    return (
      <ObjectStatus inverted state={ValueState.None}>
        {t('decision-status.in-sync')}
      </ObjectStatus>
    )
  }
  return PLACE_HOLDER
}
DecisionSyncStatus.propTypes = {
  eventId: PropTypes.string,
}

const Status = ({ status, t }) =>
  status ? (
    <ObjectStatus state={ValueState.Success} inverted>
      {t('completed-status')}
    </ObjectStatus>
  ) : (
    PLACE_HOLDER
  )
Status.propTypes = {
  status: PropTypes.string,
  t: PropTypes.func,
}

const ArrearsPastApprovalsTable = ({ events, arrearApprovals }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.past-approvals.table',
  })

  const formatMoney = useCustomizableCurrencyFormatter()
  const moneyFormatter = (money) => (money ? formatMoney(money, CURRENCY) : PLACE_HOLDER)

  const { format: formatDate } = useShortDateFormatter()

  const columnDefinitions = [
    {
      columnKey: 'businessEvent',
      title: t('column.business-event'),
      demandPopin: false,
    },
    {
      columnKey: 'approvalDate',
      title: t('column.approval-date'),
      className: styles.textRightColumn,
      demandPopin: true,
      popinText: t('column.approval-date'),
      minWidth: 500,
    },
    {
      columnKey: 'businessEventStatus',
      title: t('column.business-event-status'),
      demandPopin: true,
      popinText: t('column.business-event-status'),
      minWidth: 500,
    },
    {
      columnKey: 'decisionStatus',
      title: t('column.decision-status'),
      demandPopin: true,
      popinText: t('column.decision-status'),
      minWidth: 500,
    },
    {
      columnKey: 'decisionSyncStatus',
      title: t('column.decision-sync-status'),
      demandPopin: true,
      popinText: t('column.decision-sync-status'),
      minWidth: 500,
    },
    {
      columnKey: 'approvedAmount',
      title: t('column.approved-amount'),
      className: styles.textRightColumn,
      demandPopin: true,
      popinText: t('column.approved-amount'),
      minWidth: 500,
    },
  ]

  const columns = columnDefinitions.map((columnDef) => (
    <TableColumn
      key={columnDef.columnKey}
      className={columnDef?.className}
      demandPopin={columnDef.demandPopin}
      popinText={columnDef.popinText}
      minWidth={columnDef.minWidth}
    >
      {columnDef.title}
    </TableColumn>
  ))

  const getApprovalItems = (event) =>
    arrearApprovals.find(({ eventId }) => eventId === event.id)?.arrearApprovalItems ?? []

  events.map(
    (event) => (event['approvedAmount'] = sumBy(getApprovalItems(event), 'approvalAmount')),
  )

  return (
    <>
      <TablesToolbar title={t('title')} nrOfEntries={events?.length} />
      <Table columns={columns} noDataText={t('no-data')}>
        {orderBy(events, ['info.closingDate', 'name'], ['desc', 'desc']).map(
          ({ id, displayId, info: { name, closingDate }, status, approvedAmount }) => (
            <TableRow key={displayId}>
              <TableCell data-column="business-event">
                <MultilineItem text={displayId} linkText={name} eventId={id} />
              </TableCell>
              <TableCell data-column="approval-date">
                <Item text={formatDate(closingDate)} textRight={true} />
              </TableCell>
              <TableCell data-column="business-event-status">
                <Status status={status} t={t} />
              </TableCell>
              <TableCell data-column="decision-status">
                <DecisionStageStatus eventId={id} />
              </TableCell>
              <TableCell data-column="decision-sync-status">
                <DecisionSyncStatus eventId={id} />
              </TableCell>
              <TableCell data-column="approved-amount">
                <Item text={moneyFormatter(approvedAmount)} textRight={true} />
              </TableCell>
            </TableRow>
          ),
        )}
      </Table>
    </>
  )
}

ArrearsPastApprovalsTable.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      displayId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      decisionStatus: PropTypes.string,
      entityRef: PropTypes.shape({
        entityId: PropTypes.string,
        entityType: PropTypes.string,
      }),
      info: PropTypes.shape({
        assignee: PropTypes.string,
        code: PropTypes.string,
        name: PropTypes.string,
        closingDate: PropTypes.string,
        creationDate: PropTypes.string,
        currentDueDate: PropTypes.string,
        originalDueDate: PropTypes.string,
      }),
    }),
  ).isRequired,
  arrearApprovals: PropTypes.arrayOf(
    PropTypes.shape({
      businessPartnerId: PropTypes.string.isRequired,
      createdAt: PropTypes.string,
      createdBy: PropTypes.string,
      eventId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      updatedAt: PropTypes.string,
      updatedBy: PropTypes.string,
      arrearApprovalItems: PropTypes.arrayOf(
        PropTypes.shape({
          approvalAmount: PropTypes.number,
          approvedUntil: PropTypes.string,
          arrearExternalId: PropTypes.string,
          classification: PropTypes.shape({
            code: PropTypes.string,
            name: PropTypes.string,
          }),
          comment: PropTypes.string,
          createdAt: PropTypes.string,
          createdBy: PropTypes.string,
          id: PropTypes.string,
          updatedAt: PropTypes.string,
          updatedBy: PropTypes.string,
          product: PropTypes.shape({
            id: PropTypes.string,
            type: PropTypes.shape({
              code: PropTypes.string,
              name: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
  ).isRequired,
}

export default ArrearsPastApprovalsTable
