import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardEditItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import LimitBaseTable from 'components/domains/deals/covenants/limit-cards/LimitBaseTable'
import { limitShape } from 'components/domains/deals/covenants/limit-cards/display-tables/FinancialLimitDisplayTable'
import styles from 'components/domains/deals/covenants/limit-cards/edit-mode/EditTableStyles.module.css'
import CovenantPeriodSelect from 'components/domains/deals/covenants/limit-cards/edit-mode/components/CovenantPeriodSelect'
import ResultOfBreachSelect from 'components/domains/deals/covenants/limit-cards/edit-mode/components/ResultOfBreachSelect'
import useModifyLimits from 'components/domains/deals/covenants/limit-cards/edit-mode/useModifyLimits'
import useValidateNonFinancialFields from 'components/domains/deals/covenants/limit-cards/edit-mode/useValidateNonFinancialFields'
import useNonFinancialColumns from 'components/domains/deals/covenants/limit-cards/useNonFinancialColumns'
import DatePickerWithoutMinWidth from 'components/ui/date-picker/DatePickerWithoutMinWidth'
import LabeledSwitch from 'components/ui/input/LabeledSwitch'
import NumberInput from 'components/ui/input/NumberInput'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const NonFinancialLimitEditTable = ({
  formState = {},
  isLimitAbsolute,
  updateFormState,
  setIsValid,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.limit-card',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const { localePattern, parse: parseDate } = useShortDateFormatter()

  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const limits = useMemo(
    () => (isLimitAbsolute ? formState.absoluteLimits : formState.relativeLimits) ?? [],
    [formState.absoluteLimits, formState.relativeLimits, isLimitAbsolute],
  )

  const { validateFields, getValueStateAndMessage } = useValidateNonFinancialFields({
    formState,
    isLimitAbsolute,
    setIsValid,
  })
  useEffect(() => {
    validateFields()
  }, [validateFields, formState])

  const { addLimit, buildLimit, editLimits, updateAllPeriods, deleteLimits, editResultOfBreach } =
    useModifyLimits({
      isLimitAbsolute,
      updateFormState,
      formState,
    })
  const { columnDefinitions } = useNonFinancialColumns({ isEditMode: true })

  const getFirstPeriod = useCallback(() => limits?.[0]?.period, [limits])

  const handleAddRow = () => {
    const newLimit = buildLimit(
      {
        period: isLimitAbsolute ? undefined : getFirstPeriod(),
      },
      isLimitAbsolute,
    )
    addLimit(newLimit)
  }

  const deleteRows = () => {
    deleteLimits(selectedRowKeys)
    setSelectedRowKeys([])
  }

  const tableData = limits.map((limit, index) => {
    const rowKey = (isLimitAbsolute ? limit.clvaId : limit.clvrId) ?? index
    return {
      rowKey,
      covenantHoliday: {
        content: (
          <LabeledSwitch
            isLoading={false}
            onChange={() => editLimits(rowKey, { isCovenantHoliday: !limit.isCovenantHoliday })}
            checked={limit.isCovenantHoliday}
            checkedText={t('covenant-holiday.on')}
            uncheckedText={t('covenant-holiday.off')}
          />
        ),
      },
      validFrom: {
        content: isLimitAbsolute ? (
          <DatePickerWithoutMinWidth
            fixedWidth="120px"
            formatPattern={localePattern}
            value={limit.validFrom}
            {...getValueStateAndMessage('validFrom', index)}
            onChange={(event) =>
              editLimits(rowKey, {
                validFrom: parseDate(event.target.value, localePattern) ?? event.target.value,
              })
            }
          />
        ) : (
          <>
            <CovenantPeriodSelect
              disabled={index !== 0}
              value={limit.period}
              {...getValueStateAndMessage('period', index)}
              onChange={updateAllPeriods}
              className={styles.smallPeriodSelect}
            />
            <NumberInput
              value={limit.validFrom}
              {...getValueStateAndMessage('validFrom', index)}
              onChange={(event) => editLimits(rowKey, { validFrom: Number(event.target.value) })}
              className={styles.smallInputWidth}
            />
          </>
        ),
      },
      validTo: {
        content: isLimitAbsolute ? (
          <DatePickerWithoutMinWidth
            fixedWidth="120px"
            formatPattern={localePattern}
            value={limit.validTo}
            {...getValueStateAndMessage('validTo', index)}
            onChange={(event) =>
              editLimits(rowKey, {
                validTo: parseDate(event.target.value, localePattern) ?? event.target.value,
              })
            }
          />
        ) : (
          <>
            <CovenantPeriodSelect
              disabled
              value={limit.period}
              className={styles.smallPeriodSelect}
            />
            <NumberInput
              value={limit.validTo}
              {...getValueStateAndMessage('validTo', index)}
              onChange={(event) => editLimits(rowKey, { validTo: Number(event.target.value) })}
              className={styles.smallInputWidth}
            />
          </>
        ),
      },
    }
  })

  return (
    <>
      <CardSection>
        <CardEditItem
          label={t('result-of-breach')}
          required
          value={
            <ResultOfBreachSelect
              value={formState?.configCovenantBreachResultUuid}
              onChange={editResultOfBreach}
              {...getValueStateAndMessage('configCovenantBreachResultUuid')}
            />
          }
        />
      </CardSection>
      <CardSection>
        <LimitBaseTable
          columnDefinitions={columnDefinitions}
          tableData={tableData}
          title={t('limits')}
          actions={[
            <Button key="add" id="add" onClick={handleAddRow} design={ButtonDesign.Transparent}>
              {tNoPrefix('buttons.add')}
            </Button>,
            <Button
              key="delete"
              id="delete"
              onClick={deleteRows}
              disabled={!selectedRowKeys.length > 0}
              design={ButtonDesign.Transparent}
            >
              {tNoPrefix('buttons.delete')}
            </Button>,
          ]}
          isMultiSelect={true}
          onSelectionChange={setSelectedRowKeys}
        />
      </CardSection>
    </>
  )
}

NonFinancialLimitEditTable.propTypes = {
  formState: PropTypes.shape({
    configCovenantBreachResultUuid: PropTypes.string,
    relativeLimits: PropTypes.arrayOf(limitShape),
    absoluteLimits: PropTypes.arrayOf(limitShape),
  }),
  isLimitAbsolute: PropTypes.bool.isRequired,
  updateFormState: PropTypes.func.isRequired,
  setIsValid: PropTypes.func.isRequired,
}

export default NonFinancialLimitEditTable
