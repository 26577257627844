import { Button, ButtonDesign, Label, Text } from '@fioneer/ui5-webcomponents-react'
import { element, func, number, oneOfType, string } from 'prop-types'
import { Children, cloneElement, Fragment, isValidElement, useId } from 'react'
import { useTranslation } from 'react-i18next'

export const DealCreationReviewRow = ({ id, label, text, children, onEdit }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.dialog' })

  const randomId = useId()
  const textValue = text || '-'
  const child = isValidElement(children) ? Children.only(children) : <Text>{textValue}</Text>
  const childId = id || child.props?.id || `deal-creation-review-row-${randomId}`
  const childClass = onEdit ? 'review-row-col-with-edit' : 'review-row-col'

  const handleOnClick = () => {
    onEdit()
  }

  return (
    <Fragment key={childId}>
      <Label for={childId} className="review-row-label" showColon>
        {label}
      </Label>
      {cloneElement(child, { id: childId, className: childClass })}
      {onEdit && (
        <Button
          design={ButtonDesign.Transparent}
          onClick={handleOnClick}
          className="review-row-edit-button"
        >
          {t('button.edit')}
        </Button>
      )}
    </Fragment>
  )
}

DealCreationReviewRow.propTypes = {
  id: string,
  label: oneOfType([element, string]).isRequired,
  text: oneOfType([number, string]),
  children: element,
  onEdit: func,
}
