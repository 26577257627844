import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useConditionsAllowedOperations = () =>
  useCamelizedResponse(
    useRequest({
      path: '/conditions/allowed-operations',
      useCache: true,
      keys: ['conditions', 'permissions'],
    }),
  )

export default useConditionsAllowedOperations
