import { FlexBox, FlexBoxDirection, Text } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/RiskMonitoringStandardCollateralPackageTile.module.css'
import CollapsibleRichTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/rich-text/CollapsibleRichTextView'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const CHARACTER_LIMIT = 1000
const NON_TRANSLATED_LABEL_TEXT = 'Deviation from standard:'
const NON_TRANSLATED_ON_TEXT = 'No'
const NON_TRANSLATED_OFF_TEXT = 'Yes'

const RiskMonitoringStandardCollateralPackageTile = ({ tileId, selectedDealIndex }) => {
  const dispatch = useDispatch()
  const { dealsData, dealCollateralInformationData, sourcePaths } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId].data,
  )

  const selectedDealUuid = dealsData[selectedDealIndex]?.dealUuid

  useEffect(() => {
    dispatch(
      setSourceRender({ tileId, sourceRender: { path: `${sourcePaths?.[selectedDealIndex]}` } }),
    )
  }, [selectedDealIndex, dealsData, dispatch, sourcePaths, tileId])

  const collateralInformation = useMemo(
    () =>
      dealCollateralInformationData?.find(
        (dealCollateralInformation) => dealCollateralInformation?.dealUuid === selectedDealUuid,
      ),
    [dealCollateralInformationData, selectedDealUuid],
  )

  const deviationInformation = useMemo(
    () => (
      <div>
        <Text className={styles.text}>{NON_TRANSLATED_LABEL_TEXT}</Text>
        <Text>
          {collateralInformation?.isStandardPackage
            ? NON_TRANSLATED_ON_TEXT
            : NON_TRANSLATED_OFF_TEXT}
        </Text>
      </div>
    ),
    [collateralInformation],
  )

  const latestNote = useMemo(() => {
    if (!collateralInformation?.notes?.length) return null

    const latestNoteVersion = collateralInformation.notes.reduce(
      (prev, current) => (prev && prev.version > current.version ? prev : current),
      undefined,
    )
    return latestNoteVersion?.note
  }, [collateralInformation])

  const textView = useMemo(
    () => (
      <div className={styles.borderDiv}>
        <CollapsibleRichTextView text={latestNote} characterLimit={CHARACTER_LIMIT} />
      </div>
    ),
    [latestNote],
  )

  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      className={styles.riskMonitoringDealSelectionWrapper}
    >
      {!isEmpty(dealsData) && (
        <>
          {deviationInformation}
          {latestNote && textView}
        </>
      )}
    </FlexBox>
  )
}

RiskMonitoringStandardCollateralPackageTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default RiskMonitoringStandardCollateralPackageTile
