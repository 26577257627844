const requirementRowHasError = (
  state,
  { payload: { conditionIndex, requirementIndex, field, error, showError } },
) => {
  const previousConditionRowErrors = state.errorRows[conditionIndex]
  const previousRequirementRowErrors = state.errorRows[conditionIndex]?.[requirementIndex] ?? {}

  const newRequirementRowErrors = {
    ...previousRequirementRowErrors,
    [field]: { error, showError },
  }

  state.errorRows[conditionIndex] = {
    ...previousConditionRowErrors,
    [requirementIndex]: newRequirementRowErrors,
  }
}

export default requirementRowHasError
