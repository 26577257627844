import { TextAlign, VerticalAlign } from '@fioneer/ui5-webcomponents-react'

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MultiExistingBusinessSyndicationAuthorizedCommitmentCell,
  MultiExistingBusinessSyndicationFinancialProductCell,
  MultiExistingBusinessSyndicationFinancialRoleCell,
  MultiExistingBusinessSyndicationOutstandingCell,
  MultiExistingBusinessSyndicationRankCell,
  MultiExistingBusinessSyndicationMandatorySyndicationCell,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/financing-structure/syndication/MultiExistingBusinessSyndicationStructureTableCells'
import { renderAnalyticalTableCell } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/analytical/AnalyticalTableCell'
import {
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'

const useMultiExistingBusinessSyndicationStructureColumnDefinitions = ({ financialRolesData }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.existing-business-syndication-structure.table',
  })

  const formatMoney = useCustomizableCurrencyFormatter()
  const formatNumber = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const { format: formatDate } = useShortDateFormatter()

  return useMemo(
    () =>
      [
        {
          Header: t('columns.financial-product-or-party'),
          id: 'financialProduct',
          Cell: renderAnalyticalTableCell(MultiExistingBusinessSyndicationFinancialProductCell, {
            t,
          }),
          minWidth: 200,
          width: 350,
        },
        {
          Header: t('columns.financial-role'),
          id: 'financialRole',
          hAlign: TextAlign.Right,
          Cell: renderAnalyticalTableCell(MultiExistingBusinessSyndicationFinancialRoleCell, {
            t,
            financialRolesData,
          }),
          width: 150,
        },
        {
          Header: t('columns.authorized-commitment'),
          id: 'authorizedCommitment',
          hAlign: TextAlign.Right,
          Cell: renderAnalyticalTableCell(
            MultiExistingBusinessSyndicationAuthorizedCommitmentCell,
            {
              t,
            },
          ),
          minWidth: 250,
        },
        {
          Header: t('columns.outstanding'),
          id: 'outstanding',
          hAlign: TextAlign.Right,
          Cell: renderAnalyticalTableCell(MultiExistingBusinessSyndicationOutstandingCell, { t }),
          minWidth: 250,
        },
        {
          Header: t('columns.rank'),
          id: 'rank',
          hAlign: TextAlign.Right,
          Cell: renderAnalyticalTableCell(MultiExistingBusinessSyndicationRankCell, { t }),
          width: 150,
        },
        {
          Header: t('columns.mandatory-syndication'),
          id: 'mandatorySyndication',
          hAlign: TextAlign.Right,
          Cell: renderAnalyticalTableCell(
            MultiExistingBusinessSyndicationMandatorySyndicationCell,
            {
              t,
              formatMoney,
              formatNumber,
              formatDate,
            },
          ),
          width: 225,
        },
      ].map((element) => ({
        disableDragAndDrop: true,
        disableResizing: false,
        vAlign: VerticalAlign.Middle,
        ...element,
      })),
    [financialRolesData, formatDate, formatMoney, formatNumber, t],
  )
}

export default useMultiExistingBusinessSyndicationStructureColumnDefinitions
