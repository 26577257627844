import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteRequirementComment = (mutationOptions) => {
  const { delete: deleteComment } = useAccessTokenRequest()

  return useMutation(async ({ requirementId, commentId }) => {
    const { data } = await deleteComment({
      path: `/conditions/external/requirements/${requirementId}/comments/${commentId}`,
      keys: ['conditions', 'external', 'requirements', requirementId, 'comments', commentId],
    })
    return data
  }, mutationOptions)
}

export default useDeleteRequirementComment
