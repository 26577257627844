import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'

export const decisionProcessMinutesDecisionStatus = {
  pending: 'PENDING',
  inProgress: 'IN_PROGRESS',
  inProgressRequestDiscarded: 'IN_PROGRESS_REQUEST_DISCARDED',
  approved: 'APPROVED',
}

const objectStatusForMinutesDecisionStatus = {
  [decisionProcessMinutesDecisionStatus.pending]: {
    translationKey: 'decision-stage.minutes.status.pending',
    objectStatus: ValueState.None,
  },
  [decisionProcessMinutesDecisionStatus.inProgress]: {
    translationKey: 'decision-stage.minutes.status.in-progress',
    objectStatus: ValueState.Information,
  },
  [decisionProcessMinutesDecisionStatus.inProgressRequestDiscarded]: {
    translationKey: 'decision-stage.minutes.status.in-progress',
    objectStatus: ValueState.Information,
  },
  [decisionProcessMinutesDecisionStatus.approved]: {
    translationKey: 'decision-stage.minutes.status.approved',
    objectStatus: ValueState.Success,
  },
}

const defaultDecisionStageMinutesDecisionStatusObjectStatus = {
  translationKey: 'decision-stage.minutes.status.unknown',
  objectStatus: ValueState.None,
}

export const getObjectStatusforDecisionStageMinutesStatus = (minutesStatus) =>
  objectStatusForMinutesDecisionStatus[minutesStatus] ??
  defaultDecisionStageMinutesDecisionStatusObjectStatus

export const decisionStageMinutesVoteOptions = {
  approve: 'APPROVE',
}
