import { Text } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessObjectLink from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import ReferenceEntitiesView from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/shared/table/table-cells/conditions/ReferenceEntitiesView'
import { ReferenceEntityType } from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/shared/table/table-cells/conditions/ReferenceEntityType'
import { cwpEntityTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/shared/table/table-cells/conditions/cwpEntityTypes'
import { mapReferenceEntitiesToViewProps } from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/shared/table/table-cells/conditions/mapReferenceEntitiesToViewProps'
import paths from 'routes/paths'

const dealBasePath = `/${paths.deals}`
const propertyBasePath = `/${paths.properties}`
const businessPartnersBasePath = `/${paths.businessPartners}`

const mapEntity = (entity) => ({
  id: entity?.displayId,
  name: entity?.entityName,
})

const ConditionsTableReferencesDecisionPaperCell = ({
  value: { references, businessObjectRef },
  isPdfView,
}) => {
  const { t } = useTranslation('decisionPaper', { keyPrefix: 'pages.deals.covenants' })
  const mappedReferenceEntitiesProps = useMemo(() => {
    if (!isNil(businessObjectRef) || isNil(references)) {
      return {}
    }
    switch (references.entityType) {
      case cwpEntityTypes.DEAL:
        return mapReferenceEntitiesToViewProps({
          ids: references.entityIds,
          type: ReferenceEntityType.Deal,
          id: references.resolvedEntityInformation?.[0]?.displayId,
          name: references.resolvedEntityInformation?.[0]?.entityName,
          link: `${dealBasePath}/${references.resolvedEntityInformation?.[0]?.displayId}`,
          linkText: t('deals'),
          listItems: references.resolvedEntityInformation?.map(mapEntity),
          linkBasePath: dealBasePath,
        })
      case cwpEntityTypes.PROPERTY:
        return mapReferenceEntitiesToViewProps({
          ids: references.entityIds,
          type: ReferenceEntityType.Property,
          id: references.resolvedEntityInformation?.[0]?.displayId,
          name: references.resolvedEntityInformation?.[0]?.entityName,
          link: `${propertyBasePath}/${references.resolvedEntityInformation?.[0]?.displayId}`,
          linkText: t('properties'),
          listItems: references.resolvedEntityInformation?.map(mapEntity),
          linkBasePath: propertyBasePath,
        })
      case cwpEntityTypes.BUSINESS_PARTNER:
        return mapReferenceEntitiesToViewProps({
          ids: references.entityIds,
          type: ReferenceEntityType.BusinessPartner,
          id: references.resolvedEntityInformation?.[0]?.displayId,
          name: references.resolvedEntityInformation?.[0]?.entityName,
          link: `${businessPartnersBasePath}/${references.resolvedEntityInformation?.[0]?.displayId}`,
          linkText: t('business-partners'),
          listItems: references.resolvedEntityInformation?.map(mapEntity),
          linkBasePath: businessPartnersBasePath,
        })
    }
  }, [businessObjectRef, references, t])

  if (!isNil(businessObjectRef)) {
    return (
      <BusinessObjectLink
        entityId={businessObjectRef.entityId}
        entityType={businessObjectRef.entityType}
      />
    )
  }

  if (isNil(references)) {
    return <Text>{'-'}</Text>
  }

  return <ReferenceEntitiesView {...mappedReferenceEntitiesProps} isPdfView={isPdfView} />
}

ConditionsTableReferencesDecisionPaperCell.propTypes = {
  value: PropTypes.shape({
    references: PropTypes.shape({
      entityIds: PropTypes.arrayOf(PropTypes.string.isRequired),
      entityType: PropTypes.string.isRequired,
      resolvedEntityInformation: PropTypes.arrayOf(
        PropTypes.shape({
          entityId: PropTypes.string.isRequired,
          displayId: PropTypes.string.isRequired,
          entityName: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
    businessObjectRef: PropTypes.shape({
      entityId: PropTypes.string.isRequired,
      entityType: PropTypes.string.isRequired,
    }),
  }).isRequired,
  isPdfView: PropTypes.bool,
}

export default ConditionsTableReferencesDecisionPaperCell
