import { useCountries } from 'hooks/services/business-partners/config/useCountries'

/**
 * wrapper for useCountries to be used with a LoadingDropdown
 *
 * - renames the fields
 * - removes one level of nesting in the response
 */
const useCountriesForLoadingDropdown = (...args) => {
  const result = useCountries(...args)
  return result.data
    ? {
        ...result,
        data: [
          ...result.data.countries.map(({ id, name }) => ({
            code: id,
            displayName: name,
          })),
        ],
      }
    : result
}

export default useCountriesForLoadingDropdown
