import {
  Button,
  ButtonDesign,
  Card,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  IconDesign,
  Link,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useMemo, useCallback } from 'react'
import { useDrag } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableAttachmentCard.module.css'
import DocumentDownload from 'components/domains/documents/DocumentDownload'
import DocumentIcon from 'components/domains/documents/DocumentIcon'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import { dragTypes } from 'dragTypes'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import {
  patchDocumentReferencesOperations,
  usePatchDocumentReferencesForDocument,
} from 'hooks/services/documents/usePatchDocumentReferencesForDocument'
import { getVersionFileName } from 'routes/documents/document-versions/DocumentVersionsTable'

const RequirementsTableAttachmentCard = ({
  requirementId,
  showDeleteDocumentButton,
  documentId,
  documentName,
  documentType,
  fileExtension,
  keyDate,
  versions,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.attachments-card',
  })
  const { format: formatDate } = useShortDateFormatter()
  const queryClient = useQueryClient()

  const versionGuid = versions?.find((version) => version.isWorkVersion).guid

  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: dragTypes.documentRequirement,
    item: {
      requirementId,
      documentId,
      documentType,
      documentName,
      documentKeyDate: keyDate,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  const showMessageBox = useShowMessageBox()

  const formattedKeyDate = useMemo(
    () => (keyDate ? formatDate(keyDate) : '-'),
    [keyDate, formatDate],
  )

  const handleDeleteSuccess = useCallback(() => {
    queryClient.invalidateQueries(['documents', DocumentTypes.Requirement, requirementId])
  }, [queryClient, requirementId])

  const handleDeleteError = useCallback(() => {
    showMessageBox({ type: MessageBoxTypes.Error, children: t('error.description') })
  }, [showMessageBox, t])

  const removeRequirementsMutation = usePatchDocumentReferencesForDocument({
    documentId,
    onSuccess: handleDeleteSuccess,
    onError: handleDeleteError,
  })

  const handleDeleteReference = useCallback(() => {
    removeRequirementsMutation.mutate({
      entityRefs: [{ type: DocumentTypes.Requirement, id: requirementId }],
      operation: patchDocumentReferencesOperations.remove,
    })
  }, [removeRequirementsMutation, requirementId])

  const onDeleteClick = useCallback(() => {
    showMessageBox(
      {
        type: MessageBoxTypes.Warning,
        titleText: t('delete-dialog.title'),
        children: t('delete-dialog.body'),
        actions: [
          <Button
            key="button-confirm"
            design={ButtonDesign.Emphasized}
            onClick={handleDeleteReference}
          >
            {t('confirm-button.text')}
          </Button>,
          MessageBoxActions.Cancel,
        ],
      },
      document.body,
    )
  }, [showMessageBox, handleDeleteReference, t])

  return (
    <Card className={isDragging ? styles.cardDragging : styles.card} ref={dragRef}>
      <FlexBox
        className={styles.rowFlexBox}
        alignItems={FlexBoxAlignItems.Center}
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
      >
        <FlexBox>
          <DocumentIcon
            className={styles.documentIcon}
            format={fileExtension}
            design={IconDesign.Information}
          />
          <FlexBox direction={FlexBoxDirection.Column} className={styles.columnFlexBox}>
            <Link className={styles.link} target="_blank" href={`/documents/${documentId}`}>
              {documentName}
            </Link>
            <Text>{t('key-date', { keyDate: formattedKeyDate })}</Text>
          </FlexBox>
        </FlexBox>
        <FlexBox>
          <DocumentDownload
            type="button"
            documentId={documentId}
            fileName={getVersionFileName(documentName, fileExtension)}
            documentVersionGuid={versionGuid}
            design={ButtonDesign.Transparent}
          />
          {showDeleteDocumentButton && (
            <Button design={ButtonDesign.Transparent} icon="decline" onClick={onDeleteClick} />
          )}
        </FlexBox>
      </FlexBox>
    </Card>
  )
}
RequirementsTableAttachmentCard.propTypes = {
  requirementId: PropTypes.string.isRequired,
  showDeleteDocumentButton: PropTypes.bool.isRequired,
  documentId: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  documentName: PropTypes.string.isRequired,
  fileExtension: PropTypes.string.isRequired,
  keyDate: PropTypes.string,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      guid: PropTypes.string,
      isWorkingVersion: PropTypes.bool,
      versionExt: PropTypes.string,
    }),
  ),
}

export default RequirementsTableAttachmentCard
