import {
  AnalyticalTableScaleWidthMode,
  FlexBox,
  FlexBoxDirection,
  MessageStrip,
  MessageStripDesign,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/AnnualReviewUnitOverviewTable.module.css'
import { TABLE_HEADER_ROW_HEIGHT } from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/constants'
import getDealIdsAndNames from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/getDealIdsAndNames'
import {
  useAreaMeasurementUnitFormatter,
  useNumberFormatter,
  usePercentageFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/hooks/i18n/useI18n'
import mapUnitOverviewData from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/mapUnitOverviewData'
import sortUnitDealsData from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/sortUnitDealsData'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/ui/tables/analytical/AnalyticalTableWithToolbar'
import { useCollapseExpandTableActions } from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/ui/tables/analytical/useCollapseExpandTableActions'
import useUnitOverviewTableColumnDefinitions from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/useUnitOverviewTableColumnDefinitions'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'

const AnnualReviewUnitOverviewTable = ({
  unitOverviewData,
  typeCodesConfig,
  countryCodesConfig,
  multipleDealDetailsResponses,
  multipleAggregatedDealPortfolioResponses,
  multiPropertyKpisResponses,
  businessPartnersData,
  lendersResponses,
  multipleSyndicationsExistingBusinessResponses,
  customerBusinessPartnerIdResponse,
  businessPartnersSyndicationData,
  complianceWithRiskStrategyResponses,
  multipleDealTrancheResponses,
  multipleDealsPricingResponses,
  multipleKpisResponses,
  multipleForbearanceStatusesResponses,
  multipleCovenantMonitoringItems,
  multipleBusinessPartnerCustomFields,
  multiplePropertyCustomFieldsByPropertyUuidsData,
  businessPartnerCustomFieldConfigMetadata,
  businessPartnerId,
}) => {
  const { t: tWithPrefix } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.unit-overview.table',
  })

  const formatYears = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'unit',
    unit: 'year',
    unitDisplay: 'long',
  })

  const formatPercentages = usePercentageFormatter()

  const formatNumbers = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const formatAreaUnit = useAreaMeasurementUnitFormatter()

  const enrichedUnitDealsData = useMemo(
    () =>
      unitOverviewData?.unitDeals
        ? unitOverviewData.unitDeals?.map(({ kpiDeal }) => {
            const currentDealDetails = multipleDealDetailsResponses.find(
              ({ data }) => data?.dealUuid === kpiDeal?.dealUuid,
            )
            return {
              kpiDeal: {
                ...kpiDeal,
                isWorkingVersion:
                  currentDealDetails?.data?.workingVersion === WorkingVersionType.WORKING_VERSION,
                isBPDeal: kpiDeal?.bpId === businessPartnerId,
                businessPartnerId: parseInt(kpiDeal?.bpId, 10) || 0, // added for sorting purposes
              },
            }
          })
        : [],
    [multipleDealDetailsResponses, unitOverviewData?.unitDeals, businessPartnerId],
  )

  const getPropertyTypeName = useCallback(
    (propertyTypeCode) => typeCodesConfig.find(({ key }) => propertyTypeCode === key)?.display_name,
    [typeCodesConfig],
  )

  const getCountryName = useCallback(
    (countryCode) => countryCodesConfig.find(({ key }) => countryCode === key)?.display_name,
    [countryCodesConfig],
  )

  const getBusinessPartnerFullName = useCallback(
    (bpId) =>
      businessPartnersData
        ? businessPartnersData?.businessPartnerMinis
            ?.map((e) => ({ [e?.id]: e?.fullName }))
            .reduce((prev, curr) => Object.assign(prev, curr), {})[bpId] ?? ''
        : undefined,
    [businessPartnersData],
  )

  const getSyndicationBusinessPartnerFullName = useCallback(
    (bpId) =>
      businessPartnersSyndicationData?.businessPartnerMinis.find(({ id }) => id === bpId)?.fullName,
    [businessPartnersSyndicationData],
  )

  // Unfortunately, lodash's sortBy does not work as expected with the current sorting logic.
  // as bpId is a string, it is sorted lexicographically, so 10 comes before 2.
  // even when converting bpId to number, the sorting is not as expected.
  const sortedEnrichedUnitDealsData = sortUnitDealsData(enrichedUnitDealsData)

  const columnSelection = useUnitOverviewTableColumnDefinitions(
    getDealIdsAndNames(tWithPrefix, sortedEnrichedUnitDealsData),
  )

  const tableRef = useRef(null)
  const {
    handleOnRowExpand,
    additionalTableActions: collapseExpandActions = [],
    expandOrCollapseSubRows,
  } = useCollapseExpandTableActions(tableRef)

  const tableData = useMemo(() => {
    if (!isEmpty(unitOverviewData)) {
      return mapUnitOverviewData({
        unitOverviewData,
        multipleDealDetailsResponses,
        multipleAggregatedDealPortfolioResponses,
        complianceWithRiskStrategyResponses,
        lendersResponses,
        multipleSyndicationsExistingBusinessResponses,
        customerBusinessPartnerIdResponse,
        getSyndicationBusinessPartnerFullName,
        multipleDealTrancheResponses,
        multipleDealsPricingResponses,
        getCountryName,
        getPropertyTypeName,
        multiPropertyKpisResponses,
        getBusinessPartnerFullName,
        multipleKpisResponses,
        multipleForbearanceStatusesResponses,
        multipleCovenantMonitoringItems,
        multipleBusinessPartnerCustomFields,
        multiplePropertyCustomFieldsByPropertyUuidsData,
        businessPartnerCustomFieldConfigMetadata,
        formatters: { formatYears, formatPercentages, formatNumbers, formatAreaUnit },
        t: tWithPrefix,
      })
    }

    return []
  }, [
    unitOverviewData,
    multipleDealDetailsResponses,
    multipleAggregatedDealPortfolioResponses,
    complianceWithRiskStrategyResponses,
    lendersResponses,
    multipleSyndicationsExistingBusinessResponses,
    customerBusinessPartnerIdResponse,
    getSyndicationBusinessPartnerFullName,
    multipleDealTrancheResponses,
    multipleDealsPricingResponses,
    getCountryName,
    getPropertyTypeName,
    multiPropertyKpisResponses,
    getBusinessPartnerFullName,
    multipleKpisResponses,
    multipleForbearanceStatusesResponses,
    multipleCovenantMonitoringItems,
    multipleBusinessPartnerCustomFields,
    multiplePropertyCustomFieldsByPropertyUuidsData,
    businessPartnerCustomFieldConfigMetadata,
    formatYears,
    formatPercentages,
    formatNumbers,
    formatAreaUnit,
    tWithPrefix,
  ])

  // expand rows as default
  useEffect(() => {
    expandOrCollapseSubRows(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const highlightErroneousRows = (row) => {
    if (row.rowHasError) {
      return ValueState.Error
    } else {
      return ValueState.None
    }
  }

  return (
    <div className={styles.tableWrapper}>
      <FlexBox className={styles.wrapperFlexBox} direction={FlexBoxDirection.Column}>
        {tableData?.some((row) => row.subRows?.some((subRow) => subRow.rowHasError)) && (
          <MessageStrip hideCloseButton design={MessageStripDesign.Negative}>
            {tWithPrefix('rows-have-error')}
          </MessageStrip>
        )}
        <AnalyticalTableWithToolbar
          ref={tableRef}
          showNrOfEntries={false}
          additionalActions={collapseExpandActions}
          title={tWithPrefix('header')}
          sortable={false}
          id="unit-overview-table"
          className={styles['unit-overview-table']}
          minRows={0}
          columns={columnSelection.length ? columnSelection : [{ accessor: 'empty' }]}
          // high number of visible rows fixes re-rendering of height on expand
          visibleRows={99}
          overscanCountHorizontal={99}
          // rowHeight needs to be set to a non-empty string to not show an empty row at the end
          rowHeight={'individual'}
          headerRowHeight={TABLE_HEADER_ROW_HEIGHT}
          onRowExpandChange={(event) => handleOnRowExpand(event.detail.row)}
          data={tableData}
          isTreeTable={true}
          scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
          disableColumnPopover={true}
          showColumnSelection={false}
          withRowHighlight={true}
          highlightField={highlightErroneousRows}
          reactTableOptions={{
            autoResetPage: false,
            autoResetExpanded: false,
            autoResetSortBy: false,
            autoResetSelectedRows: false,
          }}
        />
      </FlexBox>
    </div>
  )
}

AnnualReviewUnitOverviewTable.propTypes = {
  unitOverviewData: PropTypes.shape({
    unitDeals: PropTypes.arrayOf(
      PropTypes.shape({
        kpiDeal: PropTypes.shape({
          dealUuid: PropTypes.string,
          dealName: PropTypes.string,
          dealDisplayId: PropTypes.string,
        }),
      }),
    ),
  }),
  multipleDealDetailsResponses: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({}),
    }),
  ),
  typeCodesConfig: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      display_name: PropTypes.string,
    }),
  ),
  countryCodesConfig: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      display_name: PropTypes.string,
    }),
  ),
  multipleAggregatedDealPortfolioResponses: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({}),
    }),
  ),
  multiPropertyKpisResponses: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({}),
    }),
  ),
  businessPartnersData: PropTypes.shape({
    businessPartnerMinis: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        fullName: PropTypes.string,
      }),
    ),
  }),
  lendersResponses: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({}),
    }),
  ),
  multipleSyndicationsExistingBusinessResponses: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({}),
    }),
  ),
  customerBusinessPartnerIdResponse: PropTypes.shape({
    data: PropTypes.shape({}),
  }),
  businessPartnersSyndicationData: PropTypes.shape({
    businessPartnerMinis: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        fullName: PropTypes.string,
      }),
    ),
  }),
  complianceWithRiskStrategyResponses: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({}),
    }),
  ),
  multipleDealTrancheResponses: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({}),
    }),
  ),
  multipleDealsPricingResponses: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({}),
    }),
  ),
  multipleKpisResponses: PropTypes.arrayOf(PropTypes.shape({})),
  multipleForbearanceStatusesResponses: PropTypes.arrayOf(PropTypes.shape({})),
  multipleCovenantMonitoringItems: PropTypes.arrayOf(PropTypes.shape({})),
  multipleBusinessPartnerCustomFields: PropTypes.arrayOf(PropTypes.shape({})),
  multiplePropertyCustomFieldsByPropertyUuidsData: PropTypes.arrayOf(PropTypes.shape({})),
  businessPartnerCustomFieldConfigMetadata: PropTypes.arrayOf(PropTypes.shape({})),
  businessPartnerId: PropTypes.string,
}

export default AnnualReviewUnitOverviewTable
