import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useEditMarketArea = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(
    async ({ marketId, marketPolygonCoordinates }) =>
      patch({
        path: `/markets/${marketId}`,
        body: {
          area: {
            polygon_coordinates: marketPolygonCoordinates,
          },
        },
      }),
    mutationOptions,
  )
}

export default useEditMarketArea
