import isEmpty from 'lodash.isempty'

const requirementsDueDateChanged = (state, { payload: { dueDate: newDueDate } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }

  state.editedRow.currentValues.dueDate = newDueDate

  if (newDueDate === state.editedRow.initialValues.dueDate) {
    delete state.editedRow.changedFields.dueDate
    return
  }
  state.editedRow.changedFields.dueDate = true
}

export default requirementsDueDateChanged
