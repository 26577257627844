import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/links/DecisionPaperLeaveConfirmationLink'
import BorrowerOverviewLabeledValue from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/borrower/BorrowerOverviewLabeledValue'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/kam-gam-statement-gcc/GccInvolvedPartiesKamGamStatementGccTile.module.css'

const GccInvolvedPartiesKamGamStatementGccTile = ({ tileId, showConfirmationForLinks }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.kam-gam-statement',
  })

  const { data } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const { authorityLevelData } = data ?? {}

  const nameLabel = useMemo(
    () => (authorityLevelData?.isStandaloneBusinessPartner ? t('standalone.name') : t('name')),
    [authorityLevelData?.isStandaloneBusinessPartner, t],
  )
  const idLabel = useMemo(
    () => (authorityLevelData?.isStandaloneBusinessPartner ? t('standalone.id') : t('id')),
    [authorityLevelData?.isStandaloneBusinessPartner, t],
  )

  const businessPartnerAvailable =
    !isNil(authorityLevelData?.businessPartnerName) && !isNil(authorityLevelData?.businessPartnerId)

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.contentWrapper}>
      <BorrowerOverviewLabeledValue
        label={nameLabel}
        value={
          businessPartnerAvailable ? (
            <DecisionPaperLeaveConfirmationLink
              name={authorityLevelData.businessPartnerName}
              link={`/business-partners/${authorityLevelData.businessPartnerId}`}
              showConfirmationForLinks={showConfirmationForLinks}
            />
          ) : undefined
        }
      />
      <BorrowerOverviewLabeledValue label={idLabel} value={authorityLevelData?.businessPartnerId} />
    </FlexBox>
  )
}

GccInvolvedPartiesKamGamStatementGccTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
}

export default GccInvolvedPartiesKamGamStatementGccTile
