import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

/**
 * This hook takes a list of values to convert and one key date for all requested conversions. The
 * requested conversions contains an optional id, the source currency, target currency and the value itself.
 *
 * The response data structure is the same as the request but with converted values.
 *
 * HINT: You can give one requested conversion an id to identify it again on the return value.
 *
 * The request structure is follows:
 *
 * {
 *  keyDate: "2024-01-01", // nullable, LocalDate.now() is the default in the backend.
 *  currencyConversionValues: [
 *    {
 *      id: "optional id",
 *      value: 123.45,
 *      sourceCurrency: "EUR",
 *      targetCurrency: "USD",
 *    }
 *  ]
 * }
 *
 * The keys will be converted to snake case.
 *
 * The hook will return an error when:
 *  - A currency is not supported by the backend.
 *  - No conversion rates are returned by s4 for a specific currency.
 *
 * @returns {{mutate}} mutate function to call the bulk endpoint.
 *
 */
const useCurrencyConversion = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()

  const filterObsoleteConversions = (currencyConversionValues) =>
    currencyConversionValues.filter(
      (currencyConversionValue) =>
        currencyConversionValue.sourceCurrency !== currencyConversionValue.targetCurrency,
    )

  return useMutation(
    async ({ keyDate, currencyConversionValues }) => {
      const { data } = await post({
        path: `/central-data/currency-conversions`,
        body: snakecaseKeys(
          {
            keyDate,
            currencyConversionValues: filterObsoleteConversions(currencyConversionValues),
          },
          { deep: true },
        ),
      })

      return { data: camelize(data) }
    },
    { onSuccess, onError },
  )
}
export default useCurrencyConversion
