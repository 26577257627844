import { isNotFoundError } from 'api/requests'
import { useInfiniteRequest } from 'hooks/services/baseService'
import { useInfiniteRequestsWithTotalMemo } from 'hooks/services/queryHelper'

const useCompatibleMarkets = ({
  searchFilter,
  marketTypes = [],
  propertyCountry,
  propertyType,
  propertyLat,
  propertyLng,
  propertyId,
}) => {
  const queryParams = new URLSearchParams()
  queryParams.append('property_country', propertyCountry)
  queryParams.append('property_type', propertyType)
  queryParams.append('property_id', propertyId)

  if (searchFilter) {
    queryParams.append('search_filter', searchFilter)
  }
  if (propertyLat) {
    queryParams.append('property_lat', propertyLat)
  }
  if (propertyLng) {
    queryParams.append('property_lng', propertyLng)
  }
  if (marketTypes.length > 0) queryParams.append('market_types', marketTypes.join(','))

  const response = useInfiniteRequest({
    path: `/markets/suggestions`,
    translated: true,
    useCache: true,
    queryParams,
    keys: ['markets', 'suggestions'],
    options: {
      retry: (_, error) => {
        if (isNotFoundError(error)) {
          return false
        }
        return true
      },
    },
  })
  return useInfiniteRequestsWithTotalMemo(response, 'markets')
}

export default useCompatibleMarkets
