import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styles from 'components/domains/properties/valuation/table/table-cells/ValuationRequestCell.module.css'
import Entity from 'components/ui/data/Entity'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useEventMini from 'hooks/services/business-events-and-tasks/events/useEventMini'
import { valuationRowHasError } from 'redux/slices/valuation/valuationRequestsTableSlice'

const ValuationRequestBusinessEventLoader = ({ eventId, valuationRequestId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.valuation.requests.table.row',
  })
  const { data: event, isLoading, isError } = useEventMini(eventId)

  const dispatch = useDispatch()

  useEffect(() => {
    if (isError) {
      dispatch(valuationRowHasError({ id: valuationRequestId, error: { hasError: true } }))
    }
  }, [isError, dispatch, eventId, valuationRequestId])

  const errorComponent = useMemo(
    () => (
      <Text wrapping className={styles.error}>
        {t('error')}
      </Text>
    ),
    [t],
  )

  const renderCell = useCallback(
    () => (
      <Entity
        name={event.info.name}
        link={`/business-events-and-tasks/business-events/${eventId}`}
        id={event.displayId}
        openInNewTab
      />
    ),
    [event?.displayId, event?.info?.name, eventId],
  )

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderCell}
      error={errorComponent}
    />
  )
}

ValuationRequestBusinessEventLoader.propTypes = {
  eventId: PropTypes.string.isRequired,
  valuationRequestId: PropTypes.string.isRequired,
}

export default ValuationRequestBusinessEventLoader
