import isNil from 'lodash.isnil'
import { useCallback, useMemo } from 'react'
import useExtractedHeadquarterCurrency from 'hooks/services/business-events-and-tasks/decision-papers/tiles/deal-uses-and-sources/helper/useExtractedHeadquarterCurrency'
import useBankCustomerAccounts from 'hooks/services/deals/financing/bank-customer-accounts/useBankCustomerAccounts'
import useBankCustomerAccountsAmounts from 'hooks/services/deals/financing/bank-customer-accounts/useBankCustomerAccountsAmounts'

/**
 * Book balance / external limit / remaining / utilization
 * Book balance can be negative or positive. External limit is always positive and describes how far the book balance can go into the negative.
 * Because of that, remaining is the SUM of both. Utilization describes how much the limit has been utilized.
 *
 * @param {string} dealUuid
 * @param {boolean} displayCreditAccounts - if true, only display credit accounts / if false, only display all other accounts
 * @returns {{isFetching: boolean, isError: boolean, data: Array}} tableData incl. loading and error states
 */
const useBankCustomerAccountsTableData = (dealUuid, displayCreditAccounts, displayAll = false) => {
  const { isHeadquarterCurrencyFetching, isHeadquarterCurrencyError, headquarterCurrency } =
    useExtractedHeadquarterCurrency()

  const {
    data: { bankCustomerAccounts: bcaData = [] } = {},
    isFetching: isFetchingBca,
    isError: isErrorBca,
  } = useBankCustomerAccounts({
    dealUuid,
    includeCreditAccounts: displayAll || displayCreditAccounts,
    includeOtherAccounts: displayAll || !displayCreditAccounts,
  })

  const isFetchingBcaOrCurrency = isFetchingBca || isHeadquarterCurrencyFetching
  const isErrorBcaOrCurrency = isErrorBca || isHeadquarterCurrencyError

  const bcaIds = bcaData?.map((bca) => bca?.id)?.filter(Boolean)

  const {
    data: { data: { bcaAmounts: bcaAmountsData = [] } = {} } = {},
    isFetching: isFetchingBcaAmounts,
  } = useBankCustomerAccountsAmounts({ bcaIds: bcaIds })

  const expandedBcaData = useMemo(() => {
    if (isFetchingBcaOrCurrency || isErrorBcaOrCurrency) {
      return []
    }
    return bcaData.map((bca) => {
      const amountsForBca = bcaAmountsData.find((amounts) => amounts.bcaId === bca.id) ?? {}
      return { ...bca, amounts: { ...amountsForBca } }
    })
  }, [bcaAmountsData, bcaData, isErrorBcaOrCurrency, isFetchingBcaOrCurrency])

  const getUtilization = (bookBalance, externalLimit) => {
    if (externalLimit.value > 0) {
      return bookBalance.value / externalLimit.value
    } else {
      return null // We do not want to display utilization when external limit is zero or less
    }
  }

  const getTableMoneyOrDefault = useCallback(
    ({ money, currency, isHeadquarter = false }) => {
      const defaultMoney = {
        value: 0,
        currency: isHeadquarter ? headquarterCurrency : currency,
      }
      if (isNil(money?.amount) || isNil(money?.currency)) return defaultMoney
      return { value: money.amount, currency: money.currency }
    },
    [headquarterCurrency],
  )

  // HINT: In getTableMoneyOrDefault, the money value attribute "amount" is transformed into "value" for the table.
  const mapToTableData = useCallback(
    ({
      bankArea,
      externalAccountNumber,
      productTypeName,
      accountDescription,
      amounts: {
        bookBalance,
        headquarterBookBalance,
        externalLimit,
        headquarterExternalLimit,
        remaining,
        headquarterRemaining,
        errorMessage,
      } = {},
      maturity, // TODO: get from bca amounts when available
      id,
      currency,
      productStatus,
    }) => {
      const externalLimitOrDefault = getTableMoneyOrDefault({
        money: externalLimit,
        currency: currency,
      })
      const headquarterExternalLimitOrDefault = getTableMoneyOrDefault({
        money: headquarterExternalLimit,
        currency: currency,
      })
      const remainingOrDefault = getTableMoneyOrDefault({
        money: remaining,
        currency: currency,
      })
      const headquarterRemainingOrDefault = getTableMoneyOrDefault({
        money: headquarterRemaining,
        currency: currency,
      })
      const bookBalanceOrDefault = getTableMoneyOrDefault({
        money: bookBalance,
        currency: currency,
      })
      const headquarterBookBalanceOrDefault = getTableMoneyOrDefault({
        money: headquarterBookBalance,
        currency: currency,
        isHeadquarter: true,
      })
      const isDataUnavailable = !isNil(errorMessage)
      return {
        bankArea: bankArea,
        accountNumber: externalAccountNumber,
        productType: productTypeName,
        bookBalance: {
          original: bookBalanceOrDefault,
          headquarterCurrency: headquarterBookBalanceOrDefault,
          isDataUnavailable,
        },
        externalLimit: {
          original: externalLimitOrDefault,
          headquarterCurrency: headquarterExternalLimitOrDefault,
          isDataUnavailable,
        },
        remaining: {
          original: remainingOrDefault,
          headquarterCurrency: headquarterRemainingOrDefault,
          isDataUnavailable,
        },
        utilization: getUtilization(bookBalanceOrDefault, externalLimitOrDefault),
        description: accountDescription,
        maturityDate: maturity,
        displayNavigation: true,
        bcaId: id,
        productStatus: productStatus,
      }
    },
    [getTableMoneyOrDefault],
  )
  const tableData = useMemo(
    () => expandedBcaData.map(mapToTableData),
    [expandedBcaData, mapToTableData],
  )

  return {
    isFetching: isFetchingBcaOrCurrency,
    isError: isErrorBcaOrCurrency,
    isFetchingAdditionalData: isFetchingBcaAmounts,
    data: tableData,
  }
}

export default useBankCustomerAccountsTableData
