import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const RETRIES = 3

const useRelevantDecisionPapersByEntityId = (entityId) =>
  useCamelizedResponse(
    useRequest({
      path: `/events/relevant-decision-papers?entity_id=${entityId}`,
      useCache: true,
      keys: ['events', 'decision-papers', entityId],
      options: {
        retry: (failureCount, error) => {
          if (isNotFoundError(error) || isMissingPermissionError(error)) {
            return false
          }
          if (failureCount < RETRIES) {
            return true
          }
          return false
        },
      },
    }),
  )

export default useRelevantDecisionPapersByEntityId
