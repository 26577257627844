import mapTrancheDetailsData from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/utils/mapTrancheDetailsData'
import mapTranchesDetailsSummaryData from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/utils/mapTranchesDetailsSummaryData'

const mapTranchesDetailsData = (
  trancheData,
  expectedSigningDate,
  isPdfView = false,
  allBusinessPartnersData = [],
) => {
  let tranches = [
    ...(trancheData?.tranches?.map((tranche) =>
      mapTrancheDetailsData(tranche, expectedSigningDate, isPdfView, allBusinessPartnersData),
    ) ?? []),
  ]
  if ((trancheData.summary || trancheData.summaryHeadquarter) && trancheData.tranches.length > 1)
    tranches = [
      ...tranches,
      mapTranchesDetailsSummaryData({
        summary: trancheData.summary,
        summaryHeadquarter: trancheData.summaryHeadquarter,
      }),
    ]
  return tranches
}

export default mapTranchesDetailsData
