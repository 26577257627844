import { compact } from 'lodash'
import { useMemo } from 'react'
import {
  ACTIVE,
  APPROVED,
  PROPOSED,
} from 'components/domains/business-partners/tile/risk-monitoring/forbearance/constants'
import mapForbearanceStatus from 'components/domains/business-partners/tile/risk-monitoring/forbearance/statuses/mappers/mapForbearanceStatus'
import mapUserUuidsFromForbearanceStatus from 'components/domains/business-partners/tile/risk-monitoring/forbearance/statuses/mappers/mapUserUuidsFromForbearanceStatus'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useMultipleStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useMultipleStaffMemberByObjectIdOrEmail'
import useDealMini from 'hooks/services/deals/useDealMini'
import useForbearanceStatuses from 'hooks/services/risk-monitoring/forbearance/statuses/useForbearanceStatuses'

/**
 * @param {object} params
 * @param {object} params.entityRef
 * @param {string} params.entityRef.entityId
 */
const useForbearanceStatusTableData = ({ entityRef: { entityId, entityType } }) => {
  const {
    data: { borrowerBpId } = {},
    isLoading: isDealLoading,
    isError: isDealError,
  } = useDealMini(entityType === cwpEntityTypes.DEAL ? entityId : undefined)

  const businessPartnerId = entityType === cwpEntityTypes.DEAL ? borrowerBpId : entityId

  const {
    data: forbearanceStatusOverviewData,
    isLoading: isForbearanceStatusesLoading,
    isError: isForbearanceStatusesError,
  } = useForbearanceStatuses({
    businessPartnerId,
  })

  const uuids = mapUserUuidsFromForbearanceStatus(forbearanceStatusOverviewData)
  const {
    data: staffMembers,
    isSomeLoading: isStaffMemberLoading,
    isSomeError: isStaffMemberError,
  } = useMultipleStaffMemberByObjectIdOrEmail({ objectIdOrEmailAddressList: uuids })

  const tableData = useMemo(
    () =>
      forbearanceStatusOverviewData &&
      mapForbearanceStatus(forbearanceStatusOverviewData, staffMembers),
    [forbearanceStatusOverviewData, staffMembers],
  )

  const isProposedApprovedOrActive = ({ status: { statusValue } }) =>
    statusValue === PROPOSED || statusValue === ACTIVE || statusValue === APPROVED

  const filteredRowsByProposedOrActiveStatus = useMemo(() => {
    const validRows = tableData?.filter((row) => isProposedApprovedOrActive(row))
    return validRows.map((row) => ({
      ...row,
      subRows: compact(row?.subRows?.filter((subRow) => isProposedApprovedOrActive(subRow))),
    }))
  }, [tableData])

  return {
    isLoading: isDealLoading || isForbearanceStatusesLoading || isStaffMemberLoading,
    isError: isDealError || isForbearanceStatusesError || isStaffMemberError,
    data: {
      tableData: filteredRowsByProposedOrActiveStatus,
      sourceRender: { businessPartnerId },
    },
  }
}

export default useForbearanceStatusTableData
