import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import determineValueStateBasedOnStatus from 'routes/business-partners/determineValueState'

const useBusinessPartnerStatus = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner' })
  const { status } = useContext(BusinessPartnerContext) ?? { status: '' }

  return {
    label: t('status'),
    text: t(status, {
      keyPrefix: 'pages.business-partner.general-information.status',
      defaultValue: '',
    }),
    valueState: determineValueStateBasedOnStatus(status),
  }
}

export default useBusinessPartnerStatus
