import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useIndicatorForFinancialDifficultiesOptions = () => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix:
      'decision-paper.forbearance-and-utp-questionare.options.indicator-for-financial-difficulties',
  })

  return useMemo(
    () => [
      {
        key: '-',
        value: '-',
        displayValue: t('empty-value'),
      },
      {
        key: 'given',
        value: 'given',
        displayValue: t('given'),
      },
      {
        key: 'noGiven',
        value: 'noGiven',
        displayValue: t('no-given'),
      },
    ],
    [t],
  )
}

export default useIndicatorForFinancialDifficultiesOptions
