import { TextAlign } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/exposure/RegulatoryExposureTile.module.css'
import Card from 'components/ui/card/Card'
import AnalyticalTableNoDataComponent from 'components/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import { useCustomizableCurrencyFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'
import { useLargeExposureThresholds } from 'hooks/services/business-partners/risk-figures/useLargeExposureThresholds'

const RegulatoryExposureTile = ({
  exposureAtDefault,
  exposureBeforeCreditRiskMitigation,
  exposureAfterCreditRiskMitigation,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.exposure.regulatory-exposure',
  })
  const { format: formatDate } = useShortDateFormatter()
  const formatCurrency = useCustomizableCurrencyFormatter()

  const { isLoading, isError, data: largeExposureThresholds } = useLargeExposureThresholds()

  const columnDefinitions = [
    {
      Header: t('column.label'),
      id: 'label',
      accessor: (row) => ({
        label: row.label ?? '',
        isSectionHeader: row.isSectionHeader,
      }),
      hAlign: TextAlign.Start,
      minWidth: 360,
      Cell: (row) => {
        if (!row.value.label) return ''
        return (
          <div className={row.value.isSectionHeader ? styles.sectionHeader : ''}>
            {row.value.label}
          </div>
        )
      },
    },
    {
      Header: t('column.amount'),
      id: 'amount',
      accessor: 'amount',
      hAlign: TextAlign.End,
      width: 150,
    },
    {
      Header: t('column.key-date'),
      id: 'keyDate',
      accessor: 'keyDate',
      hAlign: TextAlign.End,
      width: 150,
    },
  ]

  const mapExposureEntryToTableDataEntry = ({ label, value, unit, keyDate }) => ({
    rowKey: label,
    label,
    amount: formatCurrency(value, unit),
    keyDate: formatDate(keyDate),
  })

  const renderContent = () => {
    const { largeExposureLimit, largeExposureReportingThreshold, reportingDate } =
      largeExposureThresholds || {}
    const tableData = []
    let nrOfSections = 0
    if (!isNil(exposureAtDefault.value)) {
      tableData.push(
        { isSectionHeader: true, label: t('regulatory-exposure') },
        mapExposureEntryToTableDataEntry(exposureAtDefault),
      )
      nrOfSections++
    }
    if (!isNil(exposureBeforeCreditRiskMitigation.value)) {
      tableData.push(
        { isSectionHeader: true, label: t('large-exposure-threshold.title') },
        mapExposureEntryToTableDataEntry({
          ...largeExposureReportingThreshold,
          keyDate: reportingDate,
          label: t('large-exposure-threshold.label'),
        }),
        mapExposureEntryToTableDataEntry(exposureBeforeCreditRiskMitigation),
      )
      nrOfSections++
    }
    if (!isNil(exposureAfterCreditRiskMitigation.value)) {
      tableData.push(
        { isSectionHeader: true, label: t('large-exposure-limit.title') },
        mapExposureEntryToTableDataEntry({
          ...largeExposureLimit,
          keyDate: reportingDate,
          label: t('large-exposure-limit.label'),
        }),
        mapExposureEntryToTableDataEntry(exposureAfterCreditRiskMitigation),
      )
      nrOfSections++
    }
    return (
      <Card>
        <div className={styles.tableWrapper}>
          <AnalyticalTableWithToolbar
            id="regulatoryExposureTile"
            className={styles.table}
            title={t('title')}
            columns={columnDefinitions}
            data={tableData}
            nrOfEntries={tableData.length - nrOfSections}
            minRows={1}
            groupable={false}
            sortable={false}
            filterable={false}
            withRowHighlight={true}
            NoDataComponent={() => (
              <AnalyticalTableNoDataComponent
                isLoading={isLoading}
                isError={isError}
                tableId="regulatoryExposureTile"
              />
            )}
          />
        </div>
      </Card>
    )
  }

  return renderContent()
}

const RegulatoryExposurePropType = PropTypes.shape({
  value: PropTypes.number,
  unit: PropTypes.string,
  label: PropTypes.string,
  keyDate: PropTypes.string,
})
RegulatoryExposureTile.propTypes = {
  exposureBeforeCreditRiskMitigation: RegulatoryExposurePropType.isRequired,
  exposureAtDefault: RegulatoryExposurePropType.isRequired,
  exposureAfterCreditRiskMitigation: RegulatoryExposurePropType.isRequired,
}

export default RegulatoryExposureTile
