import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDecisionPaperTemplates = ({ eventCode, entityType, entityId }) => {
  const params = new URLSearchParams()
  params.append('event_code', eventCode)
  params.append('entity_type', entityType)
  params.append('entity_id', entityId)

  return useCamelizedResponse(
    useRequest({
      path: `/events/decision-paper-templates?${params.toString()}`,
      useCache: true,
      keys: ['events', 'decision-paper-templates', eventCode, entityType, entityId],
    }),
  )
}

// Leaving this here for test purposes
// import camelize from 'camelize'
// import { getDecisionPaperTemplatesTestdata } from 'test/resources/events-and-tasks/decisionPaper'

// const useDecisionPaperTemplates = () => ({
//   isLoading: false,
//   isError: false,
//   data: camelize(getDecisionPaperTemplatesTestdata),
// })

export default useDecisionPaperTemplates
