import { Grid, Title } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

const errorScreenStyle = {
  textAlign: 'center',
  color: 'var(--sapErrorColor)',
}

const formatError = (error) => {
  if (error instanceof Error) {
    return error.toString()
  } else if (typeof error === 'string') {
    return error
  } else {
    return JSON.stringify(error)
  }
}

/**
 * @deprecated | use DefaultErrorPage instead | https://fioneer.atlassian.net/browse/CWP-13200
 */
const ErrorScreen = ({ title, description, error }) => (
  <Grid
    position="Center"
    defaultIndent="XL0 L0 M0 S0"
    defaultSpan="XL12 L12 M12 S12"
    vSpacing="1rem"
  >
    <div style={errorScreenStyle}>
      <Title>{title}</Title>
    </div>
    <div style={errorScreenStyle}>
      <div>{description}</div>
      {error && <div>{formatError(error)}</div>}
    </div>
  </Grid>
)

ErrorScreen.propTypes = {
  error: PropTypes.any,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default ErrorScreen
