import { useMemo } from 'react'
import { multiPropertyValuations } from 'api/property/valuation/valuations'
import { isBadRequestError } from 'api/requests'
import { MarkerColors } from 'components/ui/map/internal/markers/MarkerColors'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useMultipleDealProperties from 'hooks/services/deals/properties/useMultipleDealProperties'
import useMultipleMarketArea from 'hooks/services/markets/useMultipleMarketArea'
import useMultiplePropertyIdForAssignedMarket from 'hooks/services/markets/useMultiplePropertyIdForAssignedMarket'
import { useMultiPropertyViewByPropertyUuidsArrays } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuidsArrays'
import useMultipleArrayPropertyUuids from 'hooks/services/properties/useMultipleArrayPropertyUuids'
import useMultiArrayPropertyValuations from 'hooks/services/properties/valuations/useMultiArrayPropertyValuations'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const getPropertyValuation = (valuationsData, propertyUuid, formatCurrency) => {
  const marketValueData =
    valuationsData?.valuations?.[propertyUuid]?.[multiPropertyValuations.marketValue]?.value_amount

  if (!marketValueData) {
    return { isLoading: false, isError: false, data: '-' }
  }
  return {
    isLoading: false,
    isError: false,
    data: formatCurrency(marketValueData.number, marketValueData.currency),
  }
}

const mapMarketPropertyMarkersData = (
  propertyInformation,
  marketPropertiesValuationsResult,
  formatCurrency,
  singlePropertyUuid,
) => {
  const address = propertyInformation.address
    ? {
        country: propertyInformation.address?.country_name,
        street: propertyInformation.address?.street_name,
        houseNumber: propertyInformation.address?.house_id,
        zipCode: propertyInformation.address?.postal_code,
        city: propertyInformation.address?.city_name,
      }
    : undefined
  return {
    uuid: propertyInformation.uuid,
    id: propertyInformation.id,
    name: propertyInformation.description,
    address: address,
    location: {
      lat: Number(propertyInformation.geo_location?.latitude),
      lng: Number(propertyInformation.geo_location?.longitude),
    },
    propertyType: propertyInformation.type_name,
    creationDate: propertyInformation.system_administrative_data?.creation_date_time,
    color:
      !singlePropertyUuid || singlePropertyUuid === propertyInformation.uuid
        ? MarkerColors.Blue
        : MarkerColors.Grey,
    valuation: getPropertyValuation(
      marketPropertiesValuationsResult,
      propertyInformation.uuid,
      formatCurrency,
    ),
  }
}

const buildPropertyMarkers = (
  propertiesResult,
  valuationsResult,
  formatCurrency,
  singlePropertyUuid,
) => {
  let missingGeoLocations = 0
  if (propertiesResult) {
    const markers = propertiesResult.properties
      ?.filter((property) => {
        if (
          !property?.geo_location?.latitude ||
          !property?.geo_location?.longitude ||
          (property.geo_location?.latitude === '0' && property.geo_location?.longitude === '0')
        ) {
          missingGeoLocations++
          return false
        }
        return true
      })
      .map((property) =>
        mapMarketPropertyMarkersData(
          property,
          valuationsResult,
          formatCurrency,
          singlePropertyUuid,
        ),
      )
    return { markers, missingGeoLocations }
  }
  return []
}

const useMultiplePortfolioFinancedAssetsArea = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const formatCurrency = useCustomizableCurrencyFormatter()

  const {
    data: { dealsData, dealUuids } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  // get an array of property uuids for each deal
  const {
    data: multipleDealPropertiesData,
    isLoading: isMultipleDealPropertiesLoading,
    isError: isMultipleDealPropertiesError,
  } = useCombinedQueryResults(
    useMultipleDealProperties({
      dealUuids,
    }) ?? {},
    { forceDataReturn: false },
  )

  const isFollowUpRequestEnabled = useMemo(
    () =>
      !isMultipleDealPropertiesLoading &&
      !isMultipleDealPropertiesError &&
      multipleDealPropertiesData &&
      multipleDealPropertiesData.some((property) => property.dealProperties?.length > 0),
    [isMultipleDealPropertiesError, isMultipleDealPropertiesLoading, multipleDealPropertiesData],
  )

  // create an array of arrays of property uuids related to a deal id
  const multiplePropertyUuidLists = useMemo(
    () =>
      isFollowUpRequestEnabled
        ? multipleDealPropertiesData?.map((dealProperty) =>
            dealProperty.dealProperties.map((property) => property.propertyUuid),
          )
        : [],
    [isFollowUpRequestEnabled, multipleDealPropertiesData],
  )

  // array version of usePropertyUuids:
  // get an array of responses for each properties array (related to a deal id),
  // so one entry per deal on the highest array level
  const {
    data: multipleArrayPropertyUuidsData = [],
    isLoading: isMultipleArrayPropertyUuidsLoading,
    isError: isMultipleArrayPropertyUuidsError,
  } = useMultipleArrayPropertyUuids({
    propertyUuidsArrays: multiplePropertyUuidLists,
    options: { enabled: isFollowUpRequestEnabled },
  })

  const {
    data: multiArrayPropertyValuationsData = [],
    isLoading: isMultiArrayPropertyValuationsLoading,
    isError: isMultiArrayPropertyValuationsError,
  } = useMultiArrayPropertyValuations({
    propertyUuidsArrays: multiplePropertyUuidLists,
    includeAggregates: undefined,
    preferredCurrency: undefined,
    options: {
      enabled: isFollowUpRequestEnabled,
    },
  })

  // array version of useMultiPropertyViewByPropertyUuids:
  // in case of multiple properties per array, fetch also the portfolio uuid used in the source link
  const {
    data: multiplePropertyViewByPropertyUuidsArraysData = [],
    isLoading: isMultiPropertyViewByPropertyUuidsArraysLoading,
    isError: isMultiPropertyViewByPropertyUuidsArraysError,
  } = useMultiPropertyViewByPropertyUuidsArrays({
    propertyUuidsArrays: multiplePropertyUuidLists,
    options: { enabled: isFollowUpRequestEnabled },
  })

  const markerPropertyUuids = multiplePropertyUuidLists?.map((propertieUuids) => [
    propertieUuids[0],
  ])

  const {
    data: multipleAssignedMarketData = [],
    isLoading: isPropertyIdForAssignedMarketLoading,
    isError: isPropertyIdForAssignedMarketError,
  } = useCombinedQueryResults(
    useMultiplePropertyIdForAssignedMarket({ multiplePropertyIdLists: markerPropertyUuids }),
    { forceDataReturn: true },
  )

  const assignedMarketIds = multipleAssignedMarketData.map(({ markets }) => markets?.[0]?.id)
  const assignedMarketPropertieUuids =
    multipleAssignedMarketData?.flatMap(({ markets }) =>
      markets?.map(({ properties } = {}) => properties?.map(({ id } = {}) => id)),
    ) ?? []

  const multipleMarketAreaResponse = useMultipleMarketArea({ marketIds: assignedMarketIds })

  const multipleMarketAreaResponseWithMitigatedBadRequestErrors = multipleMarketAreaResponse?.map(
    (marketAreaResponse) => ({
      ...marketAreaResponse,
      isError: marketAreaResponse?.isError && !isBadRequestError(marketAreaResponse?.error),
    }),
  )

  const {
    data: multipleMarketAreaData = [],
    isLoading: isMultipleMarketAreaLoading,
    isError: isMultipleMarketAreaError,
  } = useCombinedQueryResults(multipleMarketAreaResponseWithMitigatedBadRequestErrors, {
    forceDataReturn: true,
  })

  const {
    data: multipleAssignedMarketPropertiesData = [],
    isLoading: isAssignedMarketPropertiesLoading,
    isError: isAssignedMarketPropertiesError,
  } = useMultipleArrayPropertyUuids({
    propertyUuidsArrays: assignedMarketPropertieUuids,
    options: { enabled: assignedMarketPropertieUuids?.length > 0 },
  })

  const multiplePropertyMarkersAndGeoLocation = useMemo(
    () =>
      multiplePropertyUuidLists?.map((propertyUuidList, index) =>
        buildPropertyMarkers(
          propertyUuidList?.length > 1
            ? multipleArrayPropertyUuidsData?.[index]?.data
            : multipleAssignedMarketPropertiesData?.[index]?.data,
          multiArrayPropertyValuationsData?.[index]?.data,
          formatCurrency,
          propertyUuidList?.length > 1 ? null : propertyUuidList[0],
        ),
      ),
    [
      formatCurrency,
      multiArrayPropertyValuationsData,
      multipleArrayPropertyUuidsData,
      multipleAssignedMarketPropertiesData,
      multiplePropertyUuidLists,
    ],
  )

  const sourcePaths = useMemo(
    () =>
      dealUuids?.map((dealUuid, index) => {
        const propertiesArrayData = multipleDealPropertiesData?.[index]?.dealProperties
        const multiPropertiesArrayViewUuid =
          multiplePropertyViewByPropertyUuidsArraysData?.[index]?.data?.uuid

        return propertiesArrayData?.length > 1
          ? `/properties/portfolio/overview?portfolio-view-id=${multiPropertiesArrayViewUuid}`
          : `/properties/${multipleArrayPropertyUuidsData?.[index]?.data?.properties?.[0]?.id}`
      }),
    [
      dealUuids,
      multipleDealPropertiesData,
      multiplePropertyViewByPropertyUuidsArraysData,
      multipleArrayPropertyUuidsData,
    ],
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isAnnualReviewDealsLoading,
      isMultipleDealPropertiesLoading,
      isMultipleArrayPropertyUuidsLoading,
      isMultiArrayPropertyValuationsLoading,
      isMultiPropertyViewByPropertyUuidsArraysLoading,
      isPropertyIdForAssignedMarketLoading,
      isMultipleMarketAreaLoading,
      isAssignedMarketPropertiesLoading,
    ],
    errorValues: [
      isAnnualReviewDealsError,
      isMultipleDealPropertiesError,
      isMultipleArrayPropertyUuidsError,
      isMultiArrayPropertyValuationsError,
      isMultiPropertyViewByPropertyUuidsArraysError,
      isPropertyIdForAssignedMarketError,
      isMultipleMarketAreaError,
      isAssignedMarketPropertiesError,
    ],
    errorDetails: [annualReviewDealsError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        multiplePropertyMarkers: multiplePropertyMarkersAndGeoLocation?.map(
          ({ markers }) => markers,
        ),
        multipleMissingGeoLocations: multiplePropertyMarkersAndGeoLocation?.map(
          ({ missingGeoLocations }) => missingGeoLocations,
        ),
        multipleTotalAmountOfProperties: multipleArrayPropertyUuidsData?.map(
          (propertyUuidsData) => propertyUuidsData?.data?.properties.length,
        ),
        multipleMarketAreaData,
        noProperties: !isFollowUpRequestEnabled,
        sourcePaths,
        sourceRender: { path: `${sourcePaths[0]}` },
      },
    }
  }, [
    dealsData,
    error,
    isFollowUpRequestEnabled,
    isSomeValueError,
    isSomeValueLoading,
    multipleArrayPropertyUuidsData,
    multipleMarketAreaData,
    multiplePropertyMarkersAndGeoLocation,
    sourcePaths,
  ])
}

export default useMultiplePortfolioFinancedAssetsArea
