import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import EntityCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/EntityCell'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/shared-components/LenderCell.module.css'
import TextCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/shared-components/TextCell'
import SmallLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/ui/loading/SmallLoadingWrapper'
import { usePercentageFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const LenderCell = ({ isLoading = false, isError = false, data = {}, className }) => {
  const { t } = useTranslation('decisionPaper')
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })
  const { t: tUnitOverview } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.unit-overview-table',
  })

  const renderLenders = () => {
    if (isEmpty(data?.lenders)) {
      return <></>
    }

    return data?.lenders.map((lender, index) => {
      if (!lender?.businessPartnerFullName) {
        return (
          <TextCell
            key={index}
            isLoading={isLoading}
            isError={isError}
            value={`${tUnitOverview('anonymous')} ${
              !isNil(lender?.amountShare) && `(${formatPercentage(Number(lender?.amountShare))})`
            }`}
          />
        )
      } else
        return (
          <EntityCell
            key={index}
            name={lender?.businessPartnerFullName}
            link={`/business-partners/${lender?.businessPartnerId}`}
            id={!isNil(lender?.amountShare) && `(${formatPercentage(Number(lender?.amountShare))})`}
            options={{ isNameBold: true }}
          />
        )
    })
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      error={
        <div className={styles.errorText}>
          {t('components.error.data-unavailable.cell.title-text')}
        </div>
      }
      renderContent={() => <div className={className}>{renderLenders()}</div>}
    />
  )
}

LenderCell.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  data: PropTypes.shape({
    lenders: PropTypes.arrayOf(
      PropTypes.shape({
        businessPartnerFullName: PropTypes.string,
        amountShare: PropTypes.number,
      }),
    ),
  }),
  className: PropTypes.string,
}

export default LenderCell
