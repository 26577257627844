import { useMemo } from 'react'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useDealProperties from 'hooks/services/deals/properties/useDealProperties'
import { useMultiPropertyViewByPropertyUuids } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuids'
import usePropertyUuids from 'hooks/services/properties/usePropertyUuids'
import useMultiPropertyValuations from 'hooks/services/properties/valuations/useMultiPropertyValuations'

export const getValuationData = (valuationData = {}) => {
  const marketValueDates = { keyDates: [], reportDates: [] }
  const mortgageLendingValueDates = { keyDates: [], reportDates: [] }
  const purchasePriceDates = { keyDates: [], reportDates: [] }
  Object.keys(valuationData?.valuations || {}).forEach((propertyUuid) => {
    const marketValue = valuationData.valuations[propertyUuid]?.MARKET_VALUE
    const mortgageLendingValue = valuationData.valuations[propertyUuid]?.MORTGAGE_LENDING_VALUE
    const purchasePrice = valuationData.valuations[propertyUuid]?.PURCHASE_PRICE
    if (marketValue) {
      marketValue.key_date && marketValueDates.keyDates.push(marketValue.key_date)
      marketValue.valid_from && marketValueDates.reportDates.push(marketValue.valid_from)
    }
    if (mortgageLendingValue) {
      mortgageLendingValue.key_date &&
        mortgageLendingValueDates.keyDates.push(mortgageLendingValue.key_date)
      mortgageLendingValue.valid_from &&
        mortgageLendingValueDates.reportDates.push(mortgageLendingValue.valid_from)
    }
    if (purchasePrice) {
      purchasePrice?.key_date && purchasePriceDates.keyDates.push(purchasePrice.key_date)
      purchasePrice?.valid_from && purchasePriceDates.reportDates.push(purchasePrice.valid_from)
    }
  })
  return {
    marketValue: { value: valuationData.SUM?.MARKET_VALUE, dates: marketValueDates },
    mortgageLendingValue: {
      value: valuationData.SUM?.MORTGAGE_LENDING_VALUE,
      dates: mortgageLendingValueDates,
    },
    purchasePrice: { value: valuationData.SUM?.PURCHASE_PRICE, dates: purchasePriceDates },
  }
}

export const useFactSheetValuation = ({ entityRef: { entityId: dealUuid } }, _, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({
    dealUuid,
    tileCode,
  })

  const dealPropertiesResponse = useDealProperties({
    dealUuid: dealUuidByTileCode,
  })
  const isFollowUpRequestEnabled = useMemo(
    () =>
      !dealPropertiesResponse.isFetching &&
      !dealPropertiesResponse.isError &&
      dealPropertiesResponse?.data &&
      dealPropertiesResponse?.data?.dealProperties?.length > 0,
    [dealPropertiesResponse],
  )
  const propertyUuids = useMemo(
    () =>
      isFollowUpRequestEnabled
        ? dealPropertiesResponse.data.dealProperties.map((property) => property.propertyUuid)
        : [],
    [dealPropertiesResponse, isFollowUpRequestEnabled],
  )

  const propertyInformationResult = usePropertyUuids(propertyUuids, {
    enabled: isFollowUpRequestEnabled,
  })

  const isMultiProperty = useMemo(
    () => propertyInformationResult?.data?.data?.properties?.length > 1,
    [propertyInformationResult],
  )
  const multiPropertyViewUuidResponse = useMultiPropertyViewByPropertyUuids(propertyUuids, {
    enabled: isFollowUpRequestEnabled && isMultiProperty,
  })
  const multiPropertyViewUuid = useMemo(
    () => multiPropertyViewUuidResponse?.data?.data?.uuid,
    [multiPropertyViewUuidResponse],
  )
  const propertyValuationResult = useMultiPropertyValuations(propertyUuids, ['SUM'], undefined, {
    enabled: isFollowUpRequestEnabled,
  })
  const sourcePath = useMemo(
    () =>
      isMultiProperty
        ? `/properties/portfolio/overview?portfolio-view-id=${multiPropertyViewUuid}`
        : `/properties/${propertyInformationResult?.data?.data?.properties?.[0]?.id}/valuation`,
    [isMultiProperty, multiPropertyViewUuid, propertyInformationResult],
  )

  return useMemo(() => {
    if (
      !isDealUuidFetching &&
      !isDealUuidError &&
      !dealPropertiesResponse.isFetching &&
      !dealPropertiesResponse.isError &&
      (!dealPropertiesResponse.data.dealProperties ||
        dealPropertiesResponse.data.dealProperties.length === 0)
    ) {
      return {
        isLoading: false,
        isError: false,
        data: {
          noProperties: true,
        },
      }
    }
    const isLoading =
      isDealUuidFetching ||
      dealPropertiesResponse.isFetching ||
      propertyInformationResult.isFetching ||
      propertyValuationResult.isFetching ||
      (isMultiProperty && multiPropertyViewUuidResponse.isFetching)

    const isError =
      isDealUuidError ||
      dealPropertiesResponse.isError ||
      propertyInformationResult.isError ||
      propertyValuationResult.isError ||
      (isMultiProperty && multiPropertyViewUuidResponse.isError)

    const valuationInformation = {
      ...getValuationData(propertyValuationResult?.data),
    }

    const data = {
      valuationInformation,
      sourceRender: { path: `${sourcePath}` },
      noProperties: false,
    }

    return {
      isLoading: isLoading,
      isError: isError,
      data,
    }
  }, [
    isDealUuidFetching,
    isDealUuidError,
    dealPropertiesResponse,
    propertyInformationResult,
    propertyValuationResult,
    isMultiProperty,
    multiPropertyViewUuidResponse,
    sourcePath,
  ])
}
