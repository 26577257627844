import {
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

const EmptyDecisionPaper = ({ titleText, subtitleText }) => (
  <IllustratedMessage
    name={IllustrationMessageType.NoTasks}
    titleText={titleText}
    subtitleText={subtitleText}
    size={IllustrationMessageSize.Scene}
  />
)

EmptyDecisionPaper.propTypes = {
  titleText: PropTypes.string.isRequired,
  subtitleText: PropTypes.string.isRequired,
}

export default EmptyDecisionPaper
