import { useQuery } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const getId = (property) => property.id
const parseProperties = (properties) => properties.map(getId)

const useMultiProperties = (propertyUuids, queryOptions = {}) => {
  const parsedPropertiesIdArray = parseProperties(propertyUuids)
  const { post } = useAccessTokenRequest()
  return useCamelizedResponse(
    useQuery({
      queryKey: ['properties', parsedPropertiesIdArray],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        const { data } = await post({
          path: '/properties/uuids',
          body: {
            property_uuids: parsedPropertiesIdArray,
          },
        })
        return data
      },
      ...queryOptions,
    }),
  )
}

export default useMultiProperties
