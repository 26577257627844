import {
  Icon,
  MessageBox,
  MessageBoxActions,
  MessageBoxTypes,
} from '@fioneer/ui5-webcomponents-react/dist'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'

const propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const OpenRatingIcon = ({ to, className }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner-ratings' })
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const openInNewTab = () => {
    window.open(to, '_blank')
  }

  const handleClose = (e) => {
    setIsConfirmOpen(false)
    if (e.detail.action === MessageBoxActions.OK) {
      openInNewTab()
    }
  }

  const handleClick = (e) => {
    e.preventDefault()
    setIsConfirmOpen(true)
  }

  return (
    <>
      <Icon name="action" interactive className={className ?? undefined} onClick={handleClick} />
      {createPortal(
        <MessageBox
          type={MessageBoxTypes.Information}
          open={isConfirmOpen}
          actions={[MessageBoxActions.OK, MessageBoxActions.Close]}
          onClose={handleClose}
        >
          {t('open-rating-message')}
        </MessageBox>,
        document.body,
      )}
    </>
  )
}

OpenRatingIcon.propTypes = propTypes

export default OpenRatingIcon
