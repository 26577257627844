import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const TODAY = new Date().toISOString().substring(0, 10)

const useBusinessPartnerArrearByBusinessPartnerId = ({ businessPartnerId }, options = {}) => {
  const queryParams = new URLSearchParams()
  queryParams.append('key-date', TODAY)

  return useCamelizedResponse(
    useRequest({
      path: `/arrears/business-partner-arrear/${businessPartnerId}?${queryParams}`,
      keys: ['arrears', 'business-partner-arrear', businessPartnerId],
      options,
    }),
  )
}

export default useBusinessPartnerArrearByBusinessPartnerId
