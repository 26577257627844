import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import { currenciesSchema } from 'hooks/services/collaterals/currenciesSchema'

const useCurrencies = () => {
  const response = useRequest({
    path: '/collateral-agreements/value-helps/currencies',
    useCache: true,
    keys: ['collateral-agreements', 'currencies'],
  })

  const data = useMemo(
    () => (response.data ? currenciesSchema.parse(camelize(response.data)) : undefined),
    [response.data],
  )

  return { ...response, data }
}

export default useCurrencies
