import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/comment/CommentEntry.module.css'
import CommentOptionButton from 'components/ui/comment/CommentOptionButton'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import ShortenedRichText from 'components/ui/rich-text/ShortenedRichText'
import CWPCKEditorWithSaveButtonAndCancelPopover from 'components/ui/rich-text-editor/CWPCKEditorWithSaveButtonAndCancelPopover'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useStaffMemberByObjectIdOrEmail'

const CommentEntry = ({
  ownerId,
  commentText,
  creationTime,
  lastModifiedAt,
  showCommentActions,
  onDeleteComment,
  onUpdateComment,
  isEditMode,
  setIsEditMode,
  commentClassName,
}) => {
  const {
    data: staffMember,
    isLoading: isStaffMemberLoading,
    isError: isStaffMemberError,
  } = useStaffMemberByObjectIdOrEmail({ objectIdOrEmail: ownerId })
  const { format: formatDateAndTime } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
  })
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ui.comment.entry',
  })

  const commentTextToDisplay = useMemo(() => {
    const staffMemberContent = staffMember?.fullName ?? ownerId
    return `<strong> ${staffMemberContent}: </strong>` + commentText
  }, [commentText, ownerId, staffMember?.fullName])

  const renderStaffMember = useCallback(
    () => <ShortenedRichText description={commentTextToDisplay} maxDescriptionDisplaySize={240} />,
    [commentTextToDisplay],
  )

  const handleEditClicked = useCallback(() => {
    setIsEditMode(true)
  }, [setIsEditMode])

  const handleCancel = useCallback(() => {
    setIsEditMode(false)
  }, [setIsEditMode])

  return isEditMode ? (
    <CWPCKEditorWithSaveButtonAndCancelPopover
      initialContent={commentText}
      onSave={onUpdateComment}
      onCancel={handleCancel}
    />
  ) : (
    <FlexBox
      direction={FlexBoxDirection.Row}
      alignItems={FlexBoxAlignItems.Stretch}
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      className={commentClassName}
    >
      <FlexBox direction={FlexBoxDirection.Column} className={styles.commentWrapper}>
        <SmallLoadingWrapper
          isLoading={isStaffMemberLoading}
          isError={isStaffMemberError}
          error={renderStaffMember()}
          renderContent={renderStaffMember}
        />
        <Label className={styles.commentTime}>
          {formatDateAndTime(creationTime)}
          {lastModifiedAt && t('last.modified', { date: formatDateAndTime(lastModifiedAt) })}
        </Label>
      </FlexBox>
      {showCommentActions && (
        <FlexBox direction={FlexBoxDirection.Column}>
          <CommentOptionButton
            onDeleteClicked={onDeleteComment}
            onEditClicked={handleEditClicked}
          />
        </FlexBox>
      )}
    </FlexBox>
  )
}

CommentEntry.propTypes = {
  ownerId: PropTypes.string.isRequired,
  commentText: PropTypes.string.isRequired,
  creationTime: PropTypes.string.isRequired,
  lastModifiedAt: PropTypes.string,
  showCommentActions: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  setIsEditMode: PropTypes.func.isRequired,
  onDeleteComment: PropTypes.func.isRequired,
  onUpdateComment: PropTypes.func.isRequired,
  commentClassName: PropTypes.string,
}

export default CommentEntry
