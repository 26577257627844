import { TextAlign, VerticalAlign } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import FinancingTermsTrancheDetailsTableCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/FinancingTermsTrancheDetailsTableCell'

const useAnnualReviewFinancingTermsTrancheDetailsTableColumnDefinitions = ({
  isPdfView,
  showConfirmationForLinks,
  hasLastExtensionOption,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.financing-terms.tranche-details',
  })

  return useMemo(
    () =>
      [
        {
          Header: t('property.tranche-label'),
          accessor: 'tranche',
          minWidth: 230,
        },
        {
          Header: t('property.loan-type-label'),
          accessor: 'loanType',
          width: 150,
        },
        {
          Header: t('property.borrower-label'),
          accessor: 'borrower',
          width: 230,
        },
        {
          Header: () => (
            <Trans i18nKey="decision-paper.tiles.financing-terms.tranche-details.property.commitment-own-share-label" />
          ),
          accessor: 'commitment',
          hAlign: TextAlign.Right,
          width: 180,
        },
        {
          Header: t('property.outstanding-amount-label'),
          accessor: 'outstandingAmount',
          hAlign: TextAlign.Right,
          width: 180,
        },
        {
          Header: () => (
            <Trans i18nKey="decision-paper.tiles.financing-terms.tranche-details.property.drawdown-dates-label" />
          ),
          accessor: 'drawdownDates',
          hAlign: TextAlign.Right,
          width: 200,
        },
        {
          Header: t('property.availability-label'),
          accessor: 'availability',
          hAlign: TextAlign.Right,
          width: 150,
        },
        {
          Header: t('property.maturity-label'),
          accessor: 'maturity',
          hAlign: TextAlign.Right,
          width: 120,
        },
        {
          Header: () => (
            <Trans i18nKey="decision-paper.tiles.financing-terms.tranche-details.property.last-extension-label" />
          ),
          accessor: 'lastExtensionOption',
          hAlign: TextAlign.Right,
          width: 150,
          isVisible: hasLastExtensionOption,
        },
        {
          Header: t('property.repayment-label'),
          accessor: 'repayment',
          width: 150,
        },
        {
          Header: () => (
            <Trans i18nKey="decision-paper.tiles.financing-terms.tranche-details.property.interest-rate-label" />
          ),
          accessor: 'interestRateType',
          width: 150,
        },
        {
          Header: () => (
            <Trans i18nKey="decision-paper.tiles.financing-terms.tranche-details.property.net-revenue-label" />
          ),
          accessor: 'netRevenueMargin',
          hAlign: TextAlign.Right,
          width: 100,
        },
      ].map((element) => ({
        disableDragAndDrop: true,
        disableResizing: false,
        Cell: (cellProps) =>
          FinancingTermsTrancheDetailsTableCell({
            isPdfView,
            showConfirmationForLinks,
            ...cellProps,
          }),
        vAlign: VerticalAlign.Top,
        ...element,
      })),
    [hasLastExtensionOption, isPdfView, showConfirmationForLinks, t],
  )
}

export default useAnnualReviewFinancingTermsTrancheDetailsTableColumnDefinitions
