import {
  AnalyticalTableHooks,
  AnalyticalTableScaleWidthMode,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/ForbearanceStatusTable.module.css'
import { TABLE_ROW_HEIGHT } from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/domains/business-partners/tile/risk-monitoring/forbearance/constants'
import AnalyticalTableNoDataComponent from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/analytical/AnalyticalTableWithToolbar'
import { useCollapseExpandTableActions } from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/analytical/useCollapseExpandTableActions'
import useForbearanceStatusTableColumnDefinitions from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/useForbearanceStatusTableColumnDefinitions'

const propTypes = {
  tileId: PropTypes.string.isRequired,
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const ForbearanceStatusTable = ({ tileId }) => {
  const { tableData = [], nrOfEntries = 0 } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId].data,
  )

  const { t: tTable } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.business-partner.risk-monitoring.forbearance.status-table',
  })

  const tableRef = useRef(null)
  const columns = useForbearanceStatusTableColumnDefinitions()

  const {
    handleOnRowExpand,
    additionalTableActions: collapseExpandActions = [],
    expandOrCollapseSubRows,
  } = useCollapseExpandTableActions(tableRef)

  // expand rows as default
  useEffect(() => {
    expandOrCollapseSubRows(true)
  }, [expandOrCollapseSubRows])

  return (
    <div className={styles.tableWrapper}>
      <AnalyticalTableWithToolbar
        id="forbearance-status-table"
        title={tTable('title')}
        ref={tableRef}
        loading={false}
        data={tableData || []}
        nrOfEntries={tableData?.length || nrOfEntries}
        columns={columns}
        minRows={0}
        headerRowHeight={TABLE_ROW_HEIGHT}
        // rowHeight needs to be set to a non-empty string to not show an empty row at the end
        rowHeight={'individual'}
        // high number of visible rows fixes re-rendering of height on expand
        visibleRows={99}
        overscanCountHorizontal={99}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
        isTreeTable
        sortable={false}
        filterable
        onRowExpandChange={(event) => handleOnRowExpand(event.detail.row)}
        tableHooks={[AnalyticalTableHooks.useIndeterminateRowSelection()]}
        additionalActions={collapseExpandActions}
        NoDataComponent={() => (
          <AnalyticalTableNoDataComponent
            isLoading={false}
            isError={false}
            tableId="forbearance-status-table"
          />
        )}
        reactTableOptions={{
          autoResetPage: false,
          autoResetExpanded: false,
          autoResetSelectedRows: false,
          autoResetSortBy: false,
          autoResetFilters: false,
          selectSubRows: true,
        }}
      />
    </div>
  )
}

ForbearanceStatusTable.propTypes = propTypes

export default ForbearanceStatusTable
