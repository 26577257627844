import { ObjectStatus, TableRowType } from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BcaSearchTableColumn,
  BcaSearchTableMode,
} from 'components/domains/deals/financing/bank-customer-accounts/bca-search-dialog/BcaSearchTable'
import styles from 'components/domains/deals/financing/bank-customer-accounts/bca-search-dialog/BcaSearchTable.module.css'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import EntityCell from 'components/ui/tables/cells/EntityCell'
import MoneyCell from 'components/ui/tables/cells/MoneyCell'
import paths from 'routes/paths'

export const useBcaSearchTableData = ({
  selectedBcaIds = [],
  lockedSelectedBcas = [],
  deal,
  mode,
  onSelectionChanged,
} = {}) => {
  const isBcaLockedByBankAreaAndAccountNumber = useCallback(
    (bankArea, accountNumber) =>
      lockedSelectedBcas?.some(
        ({ bankArea: lockedBankArea, accountNumber: lockedAccountNumber } = {}) =>
          lockedBankArea &&
          bankArea &&
          lockedAccountNumber &&
          accountNumber &&
          lockedBankArea === bankArea &&
          lockedAccountNumber === accountNumber,
      ),
    [lockedSelectedBcas],
  )

  const isBcaByLockedBcaId = useCallback(
    (bcaId) =>
      lockedSelectedBcas?.some(
        ({ bcaId: lockedBcaId } = {}) => lockedBcaId && bcaId && lockedBcaId === bcaId,
      ),
    [lockedSelectedBcas],
  )

  const isBcaLocked = useCallback(
    (bca = {}) => {
      const { bcaId, bankArea, accountNumber } = bca
      return (
        isBcaLockedByBankAreaAndAccountNumber(bankArea, accountNumber) || isBcaByLockedBcaId(bcaId)
      )
    },
    [isBcaByLockedBcaId, isBcaLockedByBankAreaAndAccountNumber],
  )

  return (bcas) =>
    bcas.map((bca) => {
      const { bcaId, bankArea, accountNumber, productStatus, bookBalance } = bca ?? {}
      const dealDisplayId = deal?.displayId
      const dealName = deal?.name
      const dealStatus = deal?.status

      const productStatusName = productStatus?.translation

      const bookBalanceOriginal = {
        amount: bookBalance?.original?.value,
        currency: bookBalance?.original?.currency,
      }
      const bookBalanceHeadquarter = {
        amount: bookBalance?.headquarterCurrency?.value,
        currency: bookBalance?.headquarterCurrency?.currency,
      }
      return {
        rowKey: `bca-table-row-${bcaId}`,
        rowProperties: {
          onClick: () => {
            if (mode !== BcaSearchTableMode.NavigateOnClick) {
              mode === BcaSearchTableMode.MultiSelect &&
                onSelectionChanged(
                  {
                    detail: {
                      selectedRows: [
                        {
                          dataset: {
                            bcaId: bcaId,
                            bankArea: bankArea,
                            accountNumber: accountNumber,
                          },
                        },
                      ],
                    },
                  },
                  true,
                )
              return
            }
          },
          selected: selectedBcaIds.includes(bcaId),
          className: isBcaLocked(bca) ? styles.disabledRow : '',
          type: TableRowType.Active,
          'data-bca-id': bcaId, // This is the technical id which consists of the bankArea and internalAccountNumber
          'data-bank-area': bankArea,
          'data-account-number': accountNumber, // This is the externalAccountNumber
        },
        account: {
          value: accountNumber,
          cellComponent: (
            <EntityCell
              name={accountNumber}
              link={`/${paths.deals}/${dealDisplayId}/financing/bank-customer-accounts/${accountNumber}`}
              options={{ isNameBold: true, openInNewTab: true }}
            />
          ),
        },
        accountStatus: {
          value: productStatusName,
          cellComponent: (
            <ObjectStatus
              inverted
              state={productStatusName ? ValueState.Information : ValueState.None}
            >
              {productStatusName}
            </ObjectStatus>
          ),
        },
        deal: {
          value: dealDisplayId,
          cellComponent: (
            <EntityCell
              name={dealName}
              id={dealDisplayId}
              link={`/deals/${dealDisplayId}`}
              options={{ isNameBold: true, openInNewTab: true }}
            />
          ),
        },
        dealStatus: {
          value: dealStatus,
          cellComponent: (
            <ObjectStatus inverted state={dealStatus ? ValueState.Information : ValueState.None}>
              {dealStatus}
            </ObjectStatus>
          ),
        },
        bookBalance: {
          value: 'bookBalance',
          cellComponent: (
            <MoneyCell
              primary={bookBalanceOriginal}
              secondary={bookBalanceHeadquarter}
              isError={bookBalance?.isDataUnavailable}
              errorToDisplay={<ErrorDataUnavailableInCell />}
            />
          ),
        },
      }
    })
}

export const useBcaSearchColumnDefinitions = ({ columnsRestrictedTo = null } = {}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.financing.bank-customer-accounts.bca-search',
  })

  const columnDefinitions = [
    {
      title: t('table.account'),
      columnKey: BcaSearchTableColumn.ACCOUNT,
      sortingDisabled: true,
    },
    {
      title: t('table.deal'),
      columnKey: BcaSearchTableColumn.DEAL,
      sortingDisabled: true,
    },
    {
      title: t('table.deal-status'),
      columnKey: BcaSearchTableColumn.DEAL_STATUS,
      sortingDisabled: true,
    },
    {
      title: t('table.account-status'),
      columnKey: BcaSearchTableColumn.ACCOUNT_STATUS,
      sortingDisabled: true,
    },
    {
      title: t('table.book-balance'),
      columnKey: BcaSearchTableColumn.BOOK_BALANCE,
      sortingDisabled: true,
    },
  ]

  if (columnsRestrictedTo !== null) {
    return columnDefinitions.filter((column) => columnsRestrictedTo.includes(column.columnKey))
  }
  return columnDefinitions
}
