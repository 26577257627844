import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  Text,
  Title,
  TitleLevel,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/EmptyNewBusinessCheckContent.module.css'

const EmptyNewBusinessCheckContent = () => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.nbcs.empty-content',
  })
  return (
    <FlexBox
      alignItems={FlexBoxAlignItems.Center}
      fitContainer
      className={styles.wrapper}
      direction={FlexBoxDirection.Column}
    >
      <Title className={styles.title} level={TitleLevel.H5} wrappingType={WrappingType.Normal}>
        {t('title')}
      </Title>
      <Text className={styles.subtitle} wrapping>
        {t('subtitle')}
      </Text>
    </FlexBox>
  )
}

export default EmptyNewBusinessCheckContent
