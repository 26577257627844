import camelize from 'camelize'
import { useRequest } from 'hooks/services/baseService'

const useIndustries = () => {
  const response = useRequest({
    path: '/businesspartners/value-helps/industrysectors',
    keys: ['industries'],
  })
  return { ...response, data: camelize(response.data) }
}

export default useIndustries
