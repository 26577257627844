import { ObjectPageSection } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { marketAllowedOperations } from 'api/markets/marketAllowedOperations'
import { propertyMonitoringProxiesAllowedOperations } from 'api/property/monitoring/propertyMonitoringConfiguration'
import Header from 'components/ui/page/Header'
import Page from 'components/ui/page/Page'
import TabPage from 'components/ui/page/TabPage'
import RecentVisitWrapper from 'components/ui/recent-visit/RecentVisitWrapper'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useMarket from 'hooks/services/markets/useMarket'
import useMarketsAllowedOperations from 'hooks/services/markets/useMarketsAllowedOperations'
import usePropertyMonitoringProxiesAllowedOperations from 'hooks/services/properties/monitoring/proxies/usePropertyMonitoringProxiesAllowedOperations'
import MarketDetailArchiveButton from 'routes/markets/MarketDetailArchiveButton'
import MarketDetailExportButton from 'routes/markets/MarketDetailExportButton'
import MarketDetailManager from 'routes/markets/sections/MarketDetailManager'
import MarketDetailSummary from 'routes/markets/sections/MarketDetailSummary'

const managerSectionId = 'market-overview-manager'
const summarySectionId = 'market-overview-summary'

const MarketDetail = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.markets.detail' })
  const { marketId } = useParams()

  const {
    data: marketData,
    isLoading: isMarketLoading,
    isError: isMarketError,
    error: marketError,
  } = useMarket(marketId)
  const {
    data: allowedOperationsData,
    isLoading: isAllowedOperationsLoading,
    isError: isAllowedOperationsError,
    error: allowedOperationsError,
  } = useMarketsAllowedOperations()
  const {
    data: proxiesAllowedOperationsData,
    isLoading: isProxiesAllowedOperationsLoading,
    isError: isProxiesAllowedOperationsError,
    error: proxiesAllowedOperationsError,
  } = usePropertyMonitoringProxiesAllowedOperations()

  const isLoading =
    isMarketLoading || isAllowedOperationsLoading || isProxiesAllowedOperationsLoading
  const isError = isMarketError || isAllowedOperationsError || isProxiesAllowedOperationsError
  const error = marketError ?? allowedOperationsError ?? proxiesAllowedOperationsError
  const allowedOperations = useMemo(
    () => allowedOperationsData?.allowed_operations ?? [],
    [allowedOperationsData?.allowed_operations],
  )
  const proxiesAllowedOperations = proxiesAllowedOperationsData ?? []

  const hasProxyReadAllowedOperation = proxiesAllowedOperations.includes(
    propertyMonitoringProxiesAllowedOperations.proxySettingsRead,
  )
  const displayMarket = allowedOperations.includes(marketAllowedOperations.viewMarket)
  const archivingAllowed = allowedOperations.includes(marketAllowedOperations.archiveMarket)
  const displayAndArchiveMarket = displayMarket && archivingAllowed

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={marketId}
        entityType={cwpEntityTypes.MARKET}
      />
    ),
    [marketId],
  )

  const pageTitle = marketData ? marketData.info.name : t('page-name')

  const archiveMarketAction = useMemo(
    () =>
      displayAndArchiveMarket && !!marketData && !isLoading && !isError ? (
        <MarketDetailArchiveButton marketId={marketId} />
      ) : undefined,
    [displayAndArchiveMarket, isError, isLoading, marketData, marketId],
  )
  const exportMarketPropertiesButton = useMemo(
    () =>
      displayAndArchiveMarket && !!marketData && !isLoading && !isError ? (
        <MarketDetailExportButton marketId={marketId} />
      ) : undefined,
    [displayAndArchiveMarket, isError, isLoading, marketData, marketId],
  )

  const breadcrumbs = useMemo(
    () => [
      {
        text: t('breadcrumb.market-overview'),
        href: '../',
      },
      {
        text: pageTitle,
      },
    ],
    [pageTitle, t],
  )

  const headerTitle = useMemo(
    () => (
      <Header
        title={pageTitle}
        breadcrumbs={breadcrumbs}
        actions={
          <>
            {archiveMarketAction} {exportMarketPropertiesButton} {markFavoriteAction}
          </>
        }
      />
    ),
    [archiveMarketAction, breadcrumbs, exportMarketPropertiesButton, markFavoriteAction, pageTitle],
  )

  const marketSummaryContent = useMemo(
    () => (
      <MarketDetailSummary
        market={marketData}
        allowedOperations={allowedOperations}
        isLoading={isLoading}
        isError={isError}
        error={error}
      />
    ),
    [allowedOperations, error, isError, isLoading, marketData],
  )

  const pageContent = useMemo(
    () =>
      hasProxyReadAllowedOperation ? (
        <TabPage id="market-overview-page" headerTitle={headerTitle} includeUrlQuery={true}>
          <ObjectPageSection
            key={summarySectionId}
            id={summarySectionId}
            titleText={t('sections.summary')}
          >
            {marketSummaryContent}
          </ObjectPageSection>

          <ObjectPageSection
            key={managerSectionId}
            id={managerSectionId}
            titleText={t('sections.manager')}
          >
            <MarketDetailManager
              marketId={marketId}
              isLoading={isLoading}
              isError={isError}
              error={error}
            />
          </ObjectPageSection>
        </TabPage>
      ) : (
        <Page
          id="market-overview-page"
          renderHeaderTitle={() => headerTitle}
          renderContent={() => marketSummaryContent}
          isLoading={false}
          isError={false}
        />
      ),
    [
      error,
      hasProxyReadAllowedOperation,
      headerTitle,
      isError,
      isLoading,
      marketId,
      marketSummaryContent,
      t,
    ],
  )

  return (
    <RecentVisitWrapper entityId={marketId} entityType={cwpEntityTypes.MARKET}>
      {pageContent}
    </RecentVisitWrapper>
  )
}

export default MarketDetail
