import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { calculateSyndicationRowHeight } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/calculateSyndicationRowHeight'
import { ROW_TYPE_TRANCHE } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/constants'
import { convertDecimalToBps } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/convertBps'
import mapOpsMemoCounterpartySyndicationSharesSkims from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/mapOpsMemoCounterpartySyndicationSharesSkims'

const mapCounterpartySyndicationShares = (
  t,
  syndicationShares,
  rootRowId,
  tranchesData = [],
  isOpsMemo = false,
) =>
  syndicationShares.map((syndicationShare) => {
    let skimsWithCustomerMargin
    const isSpacingForHQCurrencyNeeded =
      !isNil(syndicationShare?.amountTotalHeadquarter) &&
      syndicationShare?.amountTotal?.currency !== syndicationShare?.amountTotalHeadquarter?.currency
    let isSpacingForLineBreakNeeded = false

    if (!isEmpty(tranchesData) && isOpsMemo) {
      const { syndicationSkims, isSpacingForLineBreakNeeded: isCellSpacingForLineBreak } =
        mapOpsMemoCounterpartySyndicationSharesSkims(t, syndicationShare, tranchesData)
      skimsWithCustomerMargin = syndicationSkims
      isSpacingForLineBreakNeeded = isCellSpacingForLineBreak
    } else {
      skimsWithCustomerMargin = (
        isEmpty(syndicationShare.customerMargin) ? [] : [syndicationShare.customerMargin]
      ).concat(syndicationShare.syndicationSkims ?? [])
    }

    const rowHeight = calculateSyndicationRowHeight(
      skimsWithCustomerMargin.length,
      syndicationShare,
      isSpacingForLineBreakNeeded,
    )

    return {
      rootRowId,
      partyRoleTranche: {
        value: syndicationShare.name,
        trancheDisplayId: syndicationShare.trancheDisplayId,
      },
      commitment: {
        value: syndicationShare.amountTotal,
        valueHeadquarter: syndicationShare.amountTotalHeadquarter,
        share: syndicationShare.amountShare,
      },
      consortiumRank: {
        value: syndicationShare.consortiumRank,
      },
      type: skimsWithCustomerMargin?.map((skim) => ({
        code: skim.skimTypeCode,
        value: skim.skimTypeShortText,
        skimId: skim.skimId,
      })),
      amountPA: skimsWithCustomerMargin?.map((skim) => ({
        value: skim.amountPerAnnum,
        valueHeadquarter: skim.amountPerAnnumHeadquarter,
        skimId: skim.skimId,
      })),
      amountTerm: skimsWithCustomerMargin?.map((skim) => ({
        value: skim.amountTerm,
        valueHeadquarter: skim.amountTermHeadquarter,
        skimId: skim.skimId,
      })),
      rate: skimsWithCustomerMargin?.map((skim) => ({
        value: isOpsMemo ? skim.rate : convertDecimalToBps(skim.rate),
        skimId: skim.skimId,
      })),
      ...(!isEmpty(tranchesData) &&
        isOpsMemo && {
          frequency: skimsWithCustomerMargin?.map((skim) => ({
            value: skim.frequency,
            skimId: skim.skimId,
          })),
          paymentDate: skimsWithCustomerMargin?.map((skim) => ({
            value: skim.paymentDate,
            skimId: skim.skimId,
          })),
        }),
      rowHeight,
      isSpacingForHQCurrencyNeeded: isSpacingForHQCurrencyNeeded || isSpacingForLineBreakNeeded,
      trancheId: syndicationShare.trancheId,
      rowType: ROW_TYPE_TRANCHE,
    }
  })

export default mapCounterpartySyndicationShares
