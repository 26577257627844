import { Label, ValueState } from '@fioneer/ui5-webcomponents-react'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import styles from 'routes/deals/collaterals/creation/CollateralAgreementCreationDialog.module.css'
import CustomFormItem from 'routes/deals/collaterals/creation/CustomFormItem'

// This component is implicitly tested by CollateralAgreementCreationDialog.unit.test.jsx
const CagValueFormItem = ({ cagValue, setCagValue, required, disabled }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.detail.collaterals' })
  const [valueState, setValueState] = useState(ValueState.None)

  useEffect(() => {
    if (cagValue) {
      setValueState(ValueState.None)
    }
  }, [cagValue])

  const handleValueStateChange = (value) => {
    if (isNil(value)) {
      setValueState(ValueState.Error)
    } else {
      setValueState(ValueState.None)
    }
  }

  return (
    <CustomFormItem className={styles.columnItem}>
      <Label showColon for="cag-value-input" required={required}>
        {t('fields.value')}
      </Label>
      <FormattedNumberInput
        value={cagValue}
        id="cag-value-input"
        onChange={(value) => {
          handleValueStateChange(value)
          setCagValue(value)
        }}
        onBlur={(value) => {
          handleValueStateChange(value)
        }}
        valueState={valueState}
        valueStateMessage={<span>{t('fields.value.validation.required')}</span>}
        disabled={disabled}
      />
    </CustomFormItem>
  )
}

CagValueFormItem.propTypes = {
  cagValue: PropTypes.number,
  setCagValue: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default CagValueFormItem
