import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { conditionsStatusTypes } from 'api/conditions/conditions'
import useDeprecatedValues from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/shared/hooks/common/useDeprecatedValues'
import TableStatusCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/view/TableStatusCell'

const noOp = () => {}

const ConditionsTableStatusCell = ({
  value: {
    status: { type: statusType, code: statusCode, name: statusName },
    requirements,
  },
  statusValues = [],
}) => {
  const { deprecatedValues } = useDeprecatedValues({
    availableValues: statusValues,
    selectedValues: [{ name: statusName, code: statusCode }],
  })

  const statusLoader = useCallback(
    () => ({
      isLoading: false,
      isError: false,
      data: {
        status: [...deprecatedValues, ...statusValues],
      },
    }),
    [statusValues, deprecatedValues],
  )

  return (
    <TableStatusCell
      showEmptyOption={false}
      statusType={statusType}
      statusName={statusName}
      requirements={requirements}
      loadingHook={statusLoader}
      onChange={noOp}
      isEditMode={false}
    />
  )
}

ConditionsTableStatusCell.propTypes = {
  value: PropTypes.shape({
    conditionId: PropTypes.string.isRequired,
    status: PropTypes.shape({
      type: PropTypes.oneOf(Object.values(conditionsStatusTypes)).isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }).isRequired,
    requirements: PropTypes.shape({
      total: PropTypes.number.isRequired,
      done: PropTypes.number.isRequired,
    }),
  }).isRequired,
  statusValues: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(Object.values(conditionsStatusTypes)).isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }),
  ),
}

export default ConditionsTableStatusCell
