import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/disbursement/DisbursementTile.module.css'
import DisbursementTileDateField from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/disbursement/fields/DisbursementTileDateField'
import DisbursementTileInputField from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/disbursement/fields/DisbursementTileInputField'
import DisbursementTileMonetaryValueField from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/disbursement/fields/DisbursementTileMonetaryValueField'

const contentKeys = {
  amountGross: 'amountGross',
  amountNet: 'amountNet',
  basicAgreementNumber: 'basicAgreementNumber',
  currency: 'currency',
  drawdownDate: 'drawdownDate',
  pbbFees: 'pbbFees',
  fixedToDate: 'fixedToDate',
  thirdPartyFees: 'thirdPartyFees',
  recipient: 'recipient',
}

const DisbursementTile = ({
  tileId,
  currentContent,
  onChange,
  isEditMode,
  isPdfView,
  setHasContentChanges,
  setIsSaveEnabled,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.disbursement',
  })

  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const showEditMode = useMemo(() => !isPdfView && isEditMode, [isEditMode, isPdfView])

  const initialNumberInputValidities = {
    grossAmount: true,
    pbbFees: true,
    thirdPartyFees: true,
  }
  const [numberInputValidities, setNumberInputValidities] = useState(initialNumberInputValidities)

  const updateNumberInputValidities = (key, isValidNumber) =>
    setNumberInputValidities((prevInvalidInputs) => ({
      ...prevInvalidInputs,
      [key]: isValidNumber,
    }))

  useEffect(() => {
    setIsSaveEnabled(Object.values(numberInputValidities).every(Boolean))
  }, [numberInputValidities, setIsSaveEnabled])

  const updateContent = useCallback(
    (updatedContent) => {
      const hasChanges = !isEqual(tileStatus?.data?.data, updatedContent)
      setHasContentChanges(hasChanges)
      onChange(updatedContent)
    },
    [onChange, setHasContentChanges, tileStatus?.data?.data],
  )

  const handleOnChange = useCallback(
    (key, newValue) => {
      updateContent({ ...currentContent, [key]: newValue })
    },
    [currentContent, updateContent],
  )

  const handleOnBasicAgreementNumberChange = useCallback(
    (newValue) => handleOnChange(contentKeys.basicAgreementNumber, newValue),
    [handleOnChange],
  )

  const handleOnDrawdownDateChange = useCallback(
    (newValue) => handleOnChange(contentKeys.drawdownDate, newValue),
    [handleOnChange],
  )

  const handleOnFixedToDateDateChange = useCallback(
    (newValue) => handleOnChange(contentKeys.fixedToDate, newValue),
    [handleOnChange],
  )

  const handleOnGrossAmountChange = useCallback(
    (newValue, isValidNumber) => {
      updateNumberInputValidities('grossAmount', isValidNumber)
      if (!isValidNumber) {
        updateContent({
          ...currentContent,
          [contentKeys.amountGross]: newValue,
        })
        return
      }
      if (isNil(newValue)) {
        updateContent({
          ...currentContent,
          [contentKeys.amountNet]: null,
          [contentKeys.amountGross]: newValue,
        })
        return
      }
      if (!isNil(currentContent?.pbbFees) && !isNil(currentContent?.thirdPartyFees)) {
        const newAmountNetValue = newValue - currentContent.pbbFees - currentContent.thirdPartyFees
        updateContent({
          ...currentContent,
          [contentKeys.amountNet]: newAmountNetValue,
          [contentKeys.amountGross]: newValue,
        })
        return
      }
      handleOnChange(contentKeys.amountGross, newValue)
    },
    [currentContent, handleOnChange, updateContent],
  )

  const handleOnPbbFeesChange = useCallback(
    (newValue, isValidNumber) => {
      updateNumberInputValidities('pbbFees', isValidNumber)
      if (!isValidNumber) {
        updateContent({
          ...currentContent,
          [contentKeys.pbbFees]: newValue,
        })
        return
      }
      if (isNil(newValue)) {
        updateContent({
          ...currentContent,
          [contentKeys.amountNet]: null,
          [contentKeys.pbbFees]: newValue,
        })
        return
      }
      if (!isNil(currentContent?.amountGross) && !isNil(currentContent?.thirdPartyFees)) {
        const newAmountNetValue =
          currentContent.amountGross - newValue - currentContent.thirdPartyFees
        updateContent({
          ...currentContent,
          [contentKeys.amountNet]: newAmountNetValue,
          [contentKeys.pbbFees]: newValue,
        })
        return
      }
      handleOnChange(contentKeys.pbbFees, newValue)
    },
    [currentContent, handleOnChange, updateContent],
  )

  const handleOnThirdPartyFeesChange = useCallback(
    (newValue, isValidNumber) => {
      updateNumberInputValidities('thirdPartyFees', isValidNumber)
      if (!isValidNumber) {
        updateContent({
          ...currentContent,
          [contentKeys.thirdPartyFees]: newValue,
        })
        return
      }
      if (isNil(newValue)) {
        updateContent({
          ...currentContent,
          [contentKeys.amountNet]: null,
          [contentKeys.thirdPartyFees]: newValue,
        })
        return
      }
      if (!isNil(currentContent?.amountGross) && !isNil(currentContent?.pbbFees)) {
        const newAmountNetValue = currentContent.amountGross - currentContent.pbbFees - newValue
        updateContent({
          ...currentContent,
          [contentKeys.amountNet]: newAmountNetValue,
          [contentKeys.thirdPartyFees]: newValue,
        })
        return
      }
      handleOnChange(contentKeys.thirdPartyFees, newValue)
    },
    [currentContent, handleOnChange, updateContent],
  )

  const handleOnCurrencyChange = useCallback(
    (newValue) => handleOnChange(contentKeys.currency, newValue),
    [handleOnChange],
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.tileWrapper}>
      <DisbursementTileInputField
        label={t('label.basic-agreement-number')}
        value={currentContent?.basicAgreementNumber}
        onChange={handleOnBasicAgreementNumberChange}
        isEditMode={showEditMode}
      />
      <DisbursementTileDateField
        label={t('label.drawdown-date')}
        value={currentContent?.drawdownDate}
        isEditMode={showEditMode}
        onChange={handleOnDrawdownDateChange}
      />
      <DisbursementTileDateField
        label={t('label.fixed-to-date')}
        value={currentContent?.fixedToDate}
        isEditMode={showEditMode}
        onChange={handleOnFixedToDateDateChange}
      />
      <DisbursementTileMonetaryValueField
        label={t('label.amount-gross')}
        value={currentContent?.amountGross}
        currency={currentContent?.currency}
        isEditMode={showEditMode}
        onChange={handleOnGrossAmountChange}
        onCurrencyChange={handleOnCurrencyChange}
        isCurrencySelectEnabled={true}
      />
      <DisbursementTileMonetaryValueField
        label={t('label.pbb-fees')}
        value={currentContent?.pbbFees}
        currency={currentContent?.currency}
        isEditMode={showEditMode}
        onChange={handleOnPbbFeesChange}
      />
      <DisbursementTileMonetaryValueField
        label={t('label.third-party-fees')}
        value={currentContent?.thirdPartyFees}
        currency={currentContent?.currency}
        isEditMode={showEditMode}
        onChange={handleOnThirdPartyFeesChange}
      />
      <DisbursementTileMonetaryValueField
        label={t('label.amount-net')}
        value={currentContent?.amountNet}
        currency={currentContent?.currency}
        isEditMode={showEditMode}
        disabled={true}
      />
    </FlexBox>
  )
}

DisbursementTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  currentContent: PropTypes.shape({
    amountGross: PropTypes.number,
    amountNet: PropTypes.number,
    basicAgreementNumber: PropTypes.string,
    currency: PropTypes.string,
    drawdownDate: PropTypes.string,
    pbbFees: PropTypes.number,
    fixedToDate: PropTypes.string,
    thirdPartyFees: PropTypes.number,
  }),
  onChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
  setIsSaveEnabled: PropTypes.func.isRequired,
}

export default DisbursementTile
