import { Grid, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/rent-roll/details/table/PropertyRentRollSubComponent.module.css'

export const PropertyRentRollSubComponent = ({ row }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.rent-roll.table.label',
  })
  const getCellComponentByFieldName = (fieldName) => <>{row.original[fieldName]?.cellComponent}</>
  return (
    <Grid defaultSpan="XL2 L2 M2 S2" className={styles['subrow-grid']}>
      <Label>{`${t('lease-break')}:`}</Label>
      <>{getCellComponentByFieldName('lease_break_date')}</>
      <Label>{`${t('branding-franchise.name')}:`}</Label>
      <>{getCellComponentByFieldName('branding_franchise')}</>
      <Label>{`${t('rent-dispute')}:`}</Label>
      <>{getCellComponentByFieldName('rent_dispute')}</>

      <Label>{`${t('no-lease-expiry')}:`}</Label>
      <>{getCellComponentByFieldName('no_lease_expiry')}</>
      <Label>{`${t('comment')}:`}</Label>
      <>{getCellComponentByFieldName('lease_comment')}</>
      <Label>{`${t('anonymous-tenant')}:`}</Label>
      <>{getCellComponentByFieldName('anonymous_tenant')}</>

      <Label>{`${t('rent-arrear')}:`}</Label>
      <>{getCellComponentByFieldName('rent_arrears')}</>
      <Label>{`${t('number-of-units')}:`}</Label>
      <>{getCellComponentByFieldName('number_of_units')}</>
    </Grid>
  )
}

PropertyRentRollSubComponent.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.object.isRequired,
  }),
}
