import { useState } from 'react'
import { useCollateralAgreementCreation } from 'hooks/services/collaterals/useCollateralAgreementCreation'
import { CREDIT_SYSTEMS } from 'routes/deals/collaterals/receivables/constants'
import useAddReceivable from 'routes/deals/collaterals/receivables/useAddReceivable'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'

const DEFAULTS = {
  priority: 1,
  ranking: 1,
  portion: '1',
}

export const RECEIVABLE_ENDPOINT = '/receivables'

export const useComposedCollateralAgreementCreation = ({ onSuccess, onError }) => {
  const collateralCreationMutation = useCollateralAgreementCreation({})
  const addReceivableMutation = useAddReceivable()

  const [isFetchingComposed, setIsFetchingComposed] = useState(false)

  const mutate = async ({
    name,
    type,
    value,
    currency,
    collateralProviderId,
    newBusinessTranches,
    existingBusinessTranches,
    bcas,
    propertyUuids = [],
    dataSource,
  }) => {
    setIsFetchingComposed(true)
    const newBusinessReceivableIds = newBusinessTranches.map(({ trancheId }) => trancheId)
    const existingBusinessReceivableIds = existingBusinessTranches.map(({ receivableId }) => ({
      id: receivableId,
      creditSystem: CREDIT_SYSTEMS.TRANCHES,
    }))
    const bcaReceivableIds = bcas.map(({ bankArea, accountNumber }) => ({
      id: `${bankArea}/${accountNumber}`,
      creditSystem: CREDIT_SYSTEMS.BCA,
    }))
    let createdCollateral
    try {
      // First, create the collateral agreement
      createdCollateral = await collateralCreationMutation.mutateAsync({
        name,
        type,
        value,
        currency,
        collateralProviderId,
        financialProductIds:
          dataSource === DATA_SOURCES.NEW_BUSINESS ? newBusinessReceivableIds : [],
        propertyUuids,
      })

      // Add receivables for each existing business tranche and bca receivables
      if (dataSource === DATA_SOURCES.EXISTING_BUSINESS && createdCollateral.data?.id) {
        const combinedReceivables = existingBusinessReceivableIds.concat(bcaReceivableIds)
        for (const receivable of combinedReceivables) {
          const { id, creditSystem } = receivable
          // The backend can't handle multiple requests for this one
          await addReceivableMutation.mutateAsync({
            collateralId: createdCollateral.data.id,
            financialProductId: id,
            portionId: DEFAULTS.portion,
            priority: DEFAULTS.priority,
            ranking: DEFAULTS.ranking,
            dataSource,
            creditSystem,
          })
        }
      }

      setIsFetchingComposed(false)
      onSuccess(createdCollateral?.data)
    } catch (error) {
      setIsFetchingComposed(false)
      onError({ createdCollateral: createdCollateral?.data, error })
    }
  }

  return {
    mutate,
    isFetching: collateralCreationMutation.isFetching || isFetchingComposed,
    // Error is done by onError callback
  }
}
