import {
  Button,
  Dialog,
  FlexBox,
  FlexBoxDirection,
  Icon,
  ButtonDesign,
  Bar,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/deals/financing/tranche-create/errorDialog/ErrorDialog.module.css'
import '@ui5/webcomponents-icons/dist/error.js'

// TODO CWP-2967: taken from the generic ErrorDialog, should try to replace the generic with this one, as it looks better
// and uses no inline styling
const ErrorDialog = ({ onRetry, isOpen, setIsOpen, texts }) => {
  const goToTryAgain = (e) => {
    setIsOpen(false)
    onRetry(e)
  }

  const goToCancel = () => {
    setIsOpen(false)
  }

  return (
    <Dialog
      id="tranche-create-error-dialog"
      className={styles.trancheErrorDialog}
      open={isOpen}
      header={
        <FlexBox
          className={styles.trancheErrorDialogHeader}
          justifyContent="Start"
          alignItems="Center"
        >
          <Icon name="error" className={styles.trancheErrorDialogHeaderIcon} />
          <Text className={styles.trancheErrorDialogHeaderText}>{texts.title}</Text>
        </FlexBox>
      }
      footer={
        <Bar
          endContent={
            <>
              {onRetry && (
                <Button
                  id="tranche-create-error-dialog-try-again-button"
                  onClick={goToTryAgain}
                  design={ButtonDesign.Emphasized}
                >
                  {texts.retryButton}
                </Button>
              )}
              <Button
                id="tranche-create-error-dialog-cancel-button"
                onClick={goToCancel}
                design="Transparent"
                className={styles.fixFooterButtonsAlignment}
              >
                {texts.cancelButton}
              </Button>
            </>
          }
        />
      }
    >
      <FlexBox direction={FlexBoxDirection.Column}>
        <Text wrapping>
          {typeof texts.description === 'object' ? (
            <div className={styles.trancheErrorDescription}>
              {texts.description.map((e) => (
                <div key={e}>{e}</div>
              ))}
            </div>
          ) : (
            <>{texts.description}</>
          )}
        </Text>
      </FlexBox>
    </Dialog>
  )
}

ErrorDialog.propTypes = {
  onRetry: PropTypes.func,
  texts: PropTypes.shape({
    retryButton: PropTypes.string,
    cancelButton: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default ErrorDialog
