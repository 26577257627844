import { isEmpty } from 'lodash'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styles from 'components/domains/properties/properties-search/dialog/PropertiesSearchTable.module.css'
import PropertiesSearchDialogFilterBar from 'components/domains/properties/properties-search/filterbar/PropertiesSearchDialogFilterBar'
import PropertiesTable from 'components/domains/properties/properties-search/table/PropertiesTable'
import { usePropertiesInfiniteRequest } from 'hooks/services/properties/useProperties'
import usePropertiesColumnDefinitions from 'hooks/services/properties/usePropertiesColumnDefinitions'
import { usePropertiesPermissions } from 'hooks/services/properties/usePropertiesPermissions'

const defaultInitialFilterCriteria = {
  propertyName: '',
  propertyId: '',
  country: '',
  propertyTypes: '',
  city: '',
  dealId: '',
  zipCode: '',
  marketId: '',
  financingStatusCode: [],
  cagStatusCode: [],
}

const columnKeysToShow = [
  'description',
  'typeName',
  'country',
  'zipCode',
  'city',
  'street',
  'market',
  'financingStatus',
  'cagStatus',
]

const paginationDelta = 50

const PropertiesSearchTable = ({
  allowMultiSelect = true,
  selectedProperties,
  lockedSelectedProperties = [],
  onSelectionChange,
  initialFilterCriteria = defaultInitialFilterCriteria,
  hiddenFilters = [],
  propertyIdsToHide = [],
  startSearchOnNonEmptyFilterCriteria = false,
  additionalFilterBarProperties = {},
}) => {
  const [intermediateFilterCriteria, setIntermediateFilterCriteria] = useState({
    ...initialFilterCriteria,
  })

  const [finalFilterCriteria, setFinalFilterCriteria] = useState({ ...initialFilterCriteria })

  const [recordNumberShown, setRecordNumberShown] = useState(paginationDelta)

  const [searchStarted, setSearchStarted] = useState(false)
  const [searchStartedAndDone, setSearchStartedAndDone] = useState(false)

  useEffect(() => {
    if (
      Object.values(initialFilterCriteria ?? {}).some((filter) => !isEmpty(filter)) &&
      startSearchOnNonEmptyFilterCriteria
    ) {
      setSearchStarted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetSettings = useCallback(() => {
    setRecordNumberShown(paginationDelta)
    setFinalFilterCriteria({ ...initialFilterCriteria })
  }, [initialFilterCriteria])

  const onFilter = useCallback(() => {
    resetSettings()
    setFinalFilterCriteria({ ...intermediateFilterCriteria })
    setSearchStarted(true)
  }, [intermediateFilterCriteria, resetSettings])

  const {
    data: propertiesPaginated,
    isFetching: isLoadingProperties,
    isError: isErrorProperties,
    fetchNextPage,
  } = usePropertiesInfiniteRequest({
    filter: { ...finalFilterCriteria },
    options: { enabled: searchStarted },
    sort: '+description',
    paginationDelta: paginationDelta,
  })

  useEffect(() => {
    if (!searchStartedAndDone && searchStarted && !isLoadingProperties) {
      setSearchStartedAndDone(true)
    }
  }, [isLoadingProperties, searchStartedAndDone, searchStarted])

  const propertiesData = useMemo(
    () => propertiesPaginated?.pages?.flatMap((page) => [...page.properties]),
    [propertiesPaginated?.pages],
  )

  const propertiesDataTotal = useMemo(
    () => (propertiesPaginated?.pages?.length ? propertiesPaginated.pages[0].pagination.total : 0),
    [propertiesPaginated?.pages],
  )

  const propertiesPagination = useMemo(
    () =>
      searchStartedAndDone && !isLoadingProperties && !isErrorProperties && propertiesData?.length
        ? { limit: Math.min(recordNumberShown, propertiesData?.length), total: propertiesDataTotal }
        : { limit: 0, total: 0 },
    [
      isErrorProperties,
      isLoadingProperties,
      propertiesData?.length,
      propertiesDataTotal,
      recordNumberShown,
      searchStartedAndDone,
    ],
  )

  const onLoadMore = useCallback(() => {
    if (recordNumberShown + paginationDelta > propertiesData?.length) {
      fetchNextPage()
    }
    setRecordNumberShown(Math.min(recordNumberShown + paginationDelta, propertiesDataTotal))
  }, [fetchNextPage, propertiesData?.length, propertiesDataTotal, recordNumberShown])

  const [columnDefinitions] = usePropertiesColumnDefinitions()

  const reducedTableColumns = useCallback(
    () => [
      ...columnKeysToShow.map((columnKey) => ({
        ...columnDefinitions?.find((columnDefinition) => columnDefinition.columnKey === columnKey),
        minWidth: 0,
      })),
    ],
    [columnDefinitions],
  )

  const {
    isLoading: isLoadingAllowedOperations,
    isError: isErrorAllowedOperations,
    data: { allowed_operations: allowedOperations = [] } = {},
  } = usePropertiesPermissions()

  const shownProperties = useMemo(
    () =>
      propertiesData
        ?.slice(0, recordNumberShown)
        .filter((property) => !propertyIdsToHide.includes(property.uuid)) ?? [],
    [propertiesData, recordNumberShown, propertyIdsToHide],
  )

  const totalNonFilteredProperties = useMemo(
    () => propertiesPagination?.total - propertiesData?.length + shownProperties?.length || 0,
    [propertiesPagination, propertiesData, shownProperties],
  )

  const fixedDropdownValues = useMemo(
    () => ({
      countries:
        initialFilterCriteria.country !== defaultInitialFilterCriteria.country
          ? [initialFilterCriteria.country]
          : undefined,
      propertyTypes:
        initialFilterCriteria.propertyTypes !== defaultInitialFilterCriteria.propertyTypes
          ? initialFilterCriteria.propertyTypes?.split(',')
          : undefined,
    }),
    [initialFilterCriteria],
  )

  const isLoading = isLoadingProperties || isLoadingAllowedOperations
  const isError = isErrorProperties || isErrorAllowedOperations

  return (
    <>
      <PropertiesSearchDialogFilterBar
        filterCriteria={intermediateFilterCriteria}
        setFilterCriteria={setIntermediateFilterCriteria}
        onFilter={onFilter}
        fixedDropdownValues={fixedDropdownValues}
        hiddenFilters={hiddenFilters}
        additionalFilterBarProperties={additionalFilterBarProperties}
      />
      <div
        className={compact([
          styles.propertiesTableWrapper,
          searchStartedAndDone && !isLoading && styles.fixTableHeight,
        ]).join(' ')}
      >
        <PropertiesTable
          properties={shownProperties}
          tableColumns={reducedTableColumns()}
          pagination={{
            ...propertiesPagination,
            total: searchStartedAndDone ? totalNonFilteredProperties : 0,
          }}
          setSelectedProperties={onSelectionChange}
          selectedProperties={selectedProperties}
          lockedSelectedProperties={lockedSelectedProperties}
          onLoadMore={onLoadMore}
          allowedOperations={allowedOperations}
          isLoading={isLoading}
          isError={isError}
          hasConfigurableColumns={false}
          navigateToPropertyOnRowClick={false}
          allowMultiSelect={allowMultiSelect}
          searchStartedAndDone={searchStartedAndDone}
        />
      </div>
    </>
  )
}

PropertiesSearchTable.propTypes = {
  allowMultiSelect: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  selectedProperties: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  lockedSelectedProperties: PropTypes.arrayOf(PropTypes.object),
  onSelectionChange: PropTypes.func.isRequired,
  initialFilterCriteria: PropTypes.shape({
    propertyName: PropTypes.string,
    propertyId: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    dealId: PropTypes.string,
    zipCode: PropTypes.string,
    marketId: PropTypes.string,
    financingStatusCode: PropTypes.arrayOf(PropTypes.string),
    cagStatusCode: PropTypes.arrayOf(PropTypes.string),
  }),
  hiddenFilters: PropTypes.arrayOf(PropTypes.string),
  propertyIdsToHide: PropTypes.arrayOf(PropTypes.string),
  startSearchOnNonEmptyFilterCriteria: PropTypes.bool,
  additionalFilterBarProperties: PropTypes.shape({}),
}

export default PropertiesSearchTable
