import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { marketAllowedOperations } from 'api/markets/marketAllowedOperations'
import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import MarketsKpiTable from 'components/domains/markets/detail/MarketsKpiTable'
import MarketsPropertiesTable from 'components/domains/markets/detail/MarketsPropertiesTable'
import MarketDescriptionCard from 'components/domains/markets/detail/cards/MarketDescriptionCard'
import MarketGeneralInformationCard from 'components/domains/markets/detail/cards/MarketGeneralInformationCard'
import MarketMapCard from 'components/domains/markets/detail/cards/MarketMapCard'
import MarketReportsCard from 'components/domains/markets/detail/cards/MarketReportsCard'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import CWPLayout from 'components/ui/layout/CWPLayout'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'

const MarketDetailSummary = ({ market, allowedOperations, isLoading, isError, error }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.markets.detail' })
  const { t: tNoPrefix } = useTranslation()

  const {
    displayMarket,
    displayEditGeneralInfoButton,
    displayEditKpiButton,
    displayUnassignProperty,
  } = useMemo(
    () => ({
      displayMarket: allowedOperations.includes(marketAllowedOperations.viewMarket),
      displayEditGeneralInfoButton: allowedOperations.includes(
        marketAllowedOperations.editMarketGeneralInfo,
      ),
      displayEditKpiButton: allowedOperations.includes(marketAllowedOperations.editMarketKpis),
      displayUnassignProperty: allowedOperations.includes(
        marketAllowedOperations.assignPropertyToMarket,
      ),
    }),
    [allowedOperations],
  )

  const renderMarketDetailSummary = useCallback(() => {
    if (!displayMarket || !market)
      return (
        <CenteredIllustratedMessage
          name="UnableToLoad"
          size="Spot"
          titleText={tNoPrefix('app.permission-error.title')}
          subtitleText={tNoPrefix('app.permission-error.subtitle')}
        />
      )

    const {
      id: marketId,
      info: { name: marketName, description: marketDescription },
      area: {
        country: { code: marketCountryCode, name: marketCountry },
      },
      properties,
      marketType: { code: marketTypeCode, value: marketType },
      externalId,
      externalIdType,
    } = market

    return (
      <CWPLayout>
        <CWPLayout.Full>
          <CWPLayout>
            <CWPLayout.Half>
              <MarketGeneralInformationCard
                marketId={marketId}
                marketName={marketName}
                marketType={marketType}
                marketCountry={marketCountry}
                marketTypeCode={marketTypeCode}
                marketExternalId={externalId || ''}
                marketExternalIdType={externalIdType || ''}
                showEditButton={displayEditGeneralInfoButton}
              />
            </CWPLayout.Half>
            <CWPLayout.Half>
              <MarketDescriptionCard
                marketId={marketId}
                marketDescription={marketDescription || ''}
                showEditButton={displayEditGeneralInfoButton}
              />
            </CWPLayout.Half>
          </CWPLayout>
        </CWPLayout.Full>

        <CWPLayout.Full>
          <CWPLayout>
            <CWPLayout.Half>
              <MarketMapCard
                marketExists={market !== null}
                marketId={marketId}
                properties={properties}
                showEditButton={displayEditGeneralInfoButton}
              />
            </CWPLayout.Half>
            <CWPLayout.Half>
              <MarketsPropertiesTable
                marketId={marketId}
                marketCountryCode={marketCountryCode}
                properties={properties}
                showUnassignButton={displayUnassignProperty}
              />
            </CWPLayout.Half>
          </CWPLayout>
        </CWPLayout.Full>

        <CWPLayout.Full>
          <CWPLayout>
            <CWPLayout.Half>
              <MarketsKpiTable marketId={marketId} showEditButton={displayEditKpiButton} />
            </CWPLayout.Half>
            <CWPLayout.Half>
              <MarketReportsCard marketId={marketId} marketName={marketName} />
            </CWPLayout.Half>
          </CWPLayout>
        </CWPLayout.Full>
      </CWPLayout>
    )
  }, [
    displayMarket,
    displayEditGeneralInfoButton,
    displayEditKpiButton,
    displayUnassignProperty,
    market,
    tNoPrefix,
  ])

  return (
    <LoadingStateWrapper
      isError={isError && !isMissingPermissionError(error)}
      isLoading={isLoading}
      isNotFoundError={isError && isNotFoundError(error)}
      renderContent={renderMarketDetailSummary}
      errorDescription={t('error.description')}
      errorDetails={t('error.details')}
      errorTitle={t('error.title')}
    />
  )
}

MarketDetailSummary.propTypes = {
  market: PropTypes.shape({
    id: PropTypes.string.isRequired,
    info: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    }).isRequired,
    area: PropTypes.shape({
      country: PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    properties: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.string.isRequired,
      }),
    ),
    marketType: PropTypes.shape({
      code: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    externalId: PropTypes.string,
    externalIdType: PropTypes.string,
  }),
  allowedOperations: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  error: PropTypes.shape({ response: PropTypes.shape({ status: PropTypes.number }) }),
}

export default MarketDetailSummary
