import { Input, Option, Select } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'components/domains/markets/detail/cards/MarketMicroProxiesTable.module.css'
import {
  marketProxyColumn,
  useMarketProxyColumns,
  useMarketProxyTableData,
} from 'components/domains/markets/detail/cards/helper/useMarketProxyColumns'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import {
  setDirection,
  setMonitoring,
  setOverrideSettings,
  setThreshold,
  setThresholdType,
} from 'redux/slices/markets/marketMicroProxiesSlice'

const MarketMicroProxiesTable = ({
  propertyTypes,
  selectedPropertyTypeCode,
  onPropertyTypeSelect,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.markets.detail.manager.market-proxies.table',
  })

  const dispatch = useDispatch()
  const proxiesState = useSelector((state) => state.markets.marketMicroProxies)

  const onPropertyTypeChange = useCallback(
    ({ detail: { selectedOption } }) =>
      onPropertyTypeSelect(selectedOption.getAttribute('data-property-type-code')),
    [onPropertyTypeSelect],
  )

  const proxyColumns = useMarketProxyColumns()

  const hasProxies = useMemo(
    () =>
      proxiesState.proxies.some(
        ({ defaultProxy, overrideProxy }) => !isNil(defaultProxy) || !isNil(overrideProxy),
      ),
    [proxiesState.proxies],
  )
  const onChange = useCallback(
    ({ column, key, value }) => {
      switch (column) {
        case marketProxyColumn.setting: {
          dispatch(setOverrideSettings({ key, value }))
          break
        }
        case marketProxyColumn.monitoring: {
          dispatch(setMonitoring({ key, value }))
          break
        }
        case marketProxyColumn.thresholdValue: {
          dispatch(setThreshold({ key, value }))
          break
        }
        case marketProxyColumn.thresholdType: {
          dispatch(setThresholdType({ key, value }))
          break
        }
        case marketProxyColumn.direction: {
          dispatch(setDirection({ key, value }))
          break
        }
      }
      if (column === marketProxyColumn.setting) {
        dispatch(setOverrideSettings({ key, value }))
      }
      if (column === marketProxyColumn.monitoring) {
        dispatch(setMonitoring({ key, value }))
      }
      if (column === marketProxyColumn.thresholdValue) {
        dispatch(setThreshold({ key, value }))
      }
      if (column === marketProxyColumn.thresholdType) {
        dispatch(setThresholdType({ key, value }))
      }
      if (column === marketProxyColumn.direction) {
        dispatch(setDirection({ key, value }))
      }
    },
    [dispatch],
  )

  const tableData = useMarketProxyTableData({
    proxiesState,
    hasProxies,
    isEditing: proxiesState.isEditing,
    onChange,
  })

  const selectedPropertyType = useMemo(
    () => propertyTypes.find(({ key }) => key === selectedPropertyTypeCode),
    [propertyTypes, selectedPropertyTypeCode],
  )

  const additionalActions = useMemo(
    () => [
      proxiesState.isEditing ? (
        <Input
          key="property-type-input"
          value={selectedPropertyType?.display_name}
          readonly
          className={styles.propertyTypeSelect}
        />
      ) : (
        <Select
          key="property-type-select"
          onChange={onPropertyTypeChange}
          className={styles.propertyTypeSelect}
        >
          {propertyTypes.map(({ key, display_name }) => (
            <Option
              key={key}
              data-property-type-code={key}
              selected={key === selectedPropertyTypeCode}
            >
              {display_name}
            </Option>
          ))}
        </Select>
      ),
    ],
    [
      proxiesState.isEditing,
      selectedPropertyType?.display_name,
      onPropertyTypeChange,
      propertyTypes,
      selectedPropertyTypeCode,
    ],
  )

  return (
    <SortedTable
      columnDefinitions={proxyColumns}
      tableData={tableData}
      additionalTableProperties={{
        stickyColumnHeader: true,
        className: styles.tableProperties,
      }}
      toolbarConfig={{
        showColumnSelection: false,
        additionalActions: additionalActions,
        title: t('toolbar.title.micro'),
      }}
      noDataText={t('no-data.micro')}
    />
  )
}

MarketMicroProxiesTable.propTypes = {
  propertyTypes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      display_name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  selectedPropertyTypeCode: PropTypes.string,
  onPropertyTypeSelect: PropTypes.func.isRequired,
}

export default MarketMicroProxiesTable
