import { Label } from '@fioneer/ui5-webcomponents-react'
import AnalyticalTableCell from 'components/ui/tables/analytical/AnalyticalTableCell'

export const RentalUnitCell = (
  <AnalyticalTableCell>
    {({ rental_unit_name, occupancy_status_id }, { t }) => (
      <>
        <>{rental_unit_name?.cellComponent}</>
        <Label>{`${t('label.occupancy')}:`}</Label>
        <>{occupancy_status_id?.cellComponent}</>
      </>
    )}
  </AnalyticalTableCell>
)

export const PropertyCell = (
  <AnalyticalTableCell>
    {({ property_uuid, property_id }, { t }) => (
      <>
        <>{property_uuid?.cellComponent}</>
        <Label>{`${t('label.property-id')}:`}</Label>
        <>{property_id?.cellComponent}</>
      </>
    )}
  </AnalyticalTableCell>
)

export const TenantCell = (
  <AnalyticalTableCell>
    {({ tenant_name, tenant_id }, { t }) => (
      <>
        <>{tenant_name?.cellComponent}</>
        <Label>{`${t('label.tenant-id')}:`}</Label>
        <>{tenant_id?.cellComponent}</>
      </>
    )}
  </AnalyticalTableCell>
)

export const SegmentAndUsageTypeCell = (
  <AnalyticalTableCell>
    {({ segment_usage_type_id, segment_uuid }, { t }) => (
      <>
        <>{segment_uuid?.cellComponent}</>
        <Label>{`${t('label.usage-type')}:`}</Label>
        <>{segment_usage_type_id?.cellComponent}</>
      </>
    )}
  </AnalyticalTableCell>
)

export const AreaCell = (
  <AnalyticalTableCell>
    {({ rental_unit_area, rental_unit_area_uom_id }, { t }) => (
      <>
        <>{rental_unit_area?.cellComponent}</>
        <Label>{`${t('label.UoM')}:`}</Label>
        <>{rental_unit_area_uom_id?.cellComponent}</>
      </>
    )}
  </AnalyticalTableCell>
)

export const RentContractedYearCell = (
  <AnalyticalTableCell>
    {({ rent_contracted_year, rental_unit_currency_id }, { t }) => (
      <>
        <>{rent_contracted_year?.cellComponent}</>
        <Label>{`${t('label.currency')}:`}</Label>
        <>{rental_unit_currency_id?.cellComponent}</>
      </>
    )}
  </AnalyticalTableCell>
)

export const CurrentNetRentCell = (
  <AnalyticalTableCell>
    {({ current_net_rent }) => (
      <>
        <>{current_net_rent?.cellComponent}</>
      </>
    )}
  </AnalyticalTableCell>
)

export const RentStartCell = (
  <AnalyticalTableCell>
    {({ rent_start_date, lease_start_date }, { t }) => (
      <>
        {rent_start_date?.cellComponent}
        <Label>{`${t('label.lease-start')}:`}</Label>
        {lease_start_date?.cellComponent}
      </>
    )}
  </AnalyticalTableCell>
)

export const LeaseEndCell = (
  <AnalyticalTableCell>
    {({ lease_break_date, lease_expiry_date }, { t }) => (
      <>
        {lease_break_date?.cellComponent}
        <Label>{`${t('label.lease-expiry')}:`}</Label>
        {lease_expiry_date?.cellComponent}
      </>
    )}
  </AnalyticalTableCell>
)

export const MultiPropertyDateCell = (
  <AnalyticalTableCell>
    {({ rent_start_date, lease_break_date }, { t }) => (
      <>
        {rent_start_date?.cellComponent}
        <Label>{`${t('column.lease-break')}:`}</Label>
        {lease_break_date?.cellComponent}
      </>
    )}
  </AnalyticalTableCell>
)
