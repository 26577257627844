import PropTypes from 'prop-types'
import { covenantLimitProps } from 'components/domains/deals/covenants/covenants-table/CovenantsTable'

export const monitoringItemShape = PropTypes.shape({
  covenantMonitoringUuid: PropTypes.string,
  covenant: PropTypes.shape({
    type: PropTypes.string,
    covenantUuid: PropTypes.string,
    dealUuid: PropTypes.string,
    name: PropTypes.string,
  }),
  status: PropTypes.string,
  dueDate: PropTypes.shape({
    keyDate: PropTypes.string,
    targetDeliveryDate: PropTypes.string,
    checkDate: PropTypes.string,
  }),
  newTargetDeliveryDate: PropTypes.string,
  softLimit: covenantLimitProps,
  hardLimit: covenantLimitProps,
  result: covenantLimitProps,
  breach: PropTypes.string,
  breachRule: PropTypes.string,
  waived: PropTypes.bool,
})
