import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteRequirements = (mutateOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    ({ requirementIds }) =>
      post({
        path: `/conditions/external/requirements/bulk-delete`,
        body: {
          requirement_ids: requirementIds,
        },
      }),
    mutateOptions,
  )
}

export default useDeleteRequirements
