import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateMarketValueConfirmation = (mutateOptions) => {
  const { patch } = useAccessTokenRequest()

  return useMutation(
    ({ id, comment, status, completeLinkedEvent }) =>
      patch({
        path: `/property-monitoring/market-value-confirmations/${id}`,
        body: snakecaseKeys({
          info: {
            comment,
            status,
          },
          completeLinkedEvent,
        }),
      }),
    mutateOptions,
  )
}

export default useUpdateMarketValueConfirmation
