import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useAssignPropertiesToMarket = ({ onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()
  return useMutation(
    async ({ propertyIds, marketId }) => {
      const searchParams = new URLSearchParams(
        propertyIds.map((propertyId) => ['property_id', propertyId]),
      )

      const { data } = await put({
        path: `/markets/${marketId}/properties?${searchParams}`,
      })
      return data
    },
    { onSuccess, onError },
  )
}

export default useAssignPropertiesToMarket
