import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { businessEventsAllowedOperations } from 'api/events/eventAllowedOperations'
import UserApprovalsCard from 'components/domains/business-events-and-tasks/events/decision-stage/UserApprovalsCard'
import UserDecisionStageVotesCard from 'components/domains/business-events-and-tasks/events/decision-stage/UserDecisionStageVotesCard'
import UserEventsSummaryCard from 'components/domains/business-events-and-tasks/events/events-summary-card/UserEventsSummaryCard'
import UserTasksSummaryCard from 'components/domains/business-events-and-tasks/tasks/tasks-summary/UserTasksSummaryCard'
import BusinessPartnersUserFavoritesAndRecentVisitsCard from 'components/domains/business-partners/user-favorite/BusinessPartnersUserFavoritesAndRecentVisitsCard'
import DealsUserFavoritesAndRecentVisitsCard from 'components/domains/deals/user-favorite/DealsUserFavoritesAndRecentVisitsCard'
import MarketsUserFavoritesAndRecentVisitsCard from 'components/domains/markets/user-favorite/MarketsUserFavoritesAndRecentVisitsCard'
import PropertiesUserFavoritesAndRecentVisitsCard from 'components/domains/properties/user-favorite/PropertiesUserFavoritesAndRecentVisitsCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Section from 'components/ui/page/Section'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useEventsAllowedOperations from 'hooks/services/business-events-and-tasks/events/useEventsAllowedOperations'
import { useStaffMemberSelf } from 'hooks/services/business-partners/staff-members/useStaffMemberSelf'

const LandingPageSectionWithCWPLayout = ({ children, titleText }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.home.section',
  })
  return (
    <Section titleText={t(titleText)}>
      <CWPLayout>{children}</CWPLayout>
    </Section>
  )
}

LandingPageSectionWithCWPLayout.propTypes = {
  children: PropTypes.node.isRequired,
  titleText: PropTypes.string.isRequired,
}

export const LandingPagePropertiesAndMarketsSection = () => (
  <LandingPageSectionWithCWPLayout titleText={'properties-and-markets'}>
    <PropertiesUserFavoritesAndRecentVisitsCard />
    <MarketsUserFavoritesAndRecentVisitsCard />
  </LandingPageSectionWithCWPLayout>
)

export const LandingPageBusinessPartnersAndDealsSection = () => (
  <LandingPageSectionWithCWPLayout titleText={'businesspartners-and-deals'}>
    <BusinessPartnersUserFavoritesAndRecentVisitsCard />
    <DealsUserFavoritesAndRecentVisitsCard />
  </LandingPageSectionWithCWPLayout>
)

export const LandingPageBusinessEventsAndTasksSection = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.home.section',
  })

  const {
    data: allowedEventsOperationsData,
    isLoading: allowedEventsOperationsIsLoading,
    isError: allowedEventsOperationsIsError,
  } = useEventsAllowedOperations()

  const {
    isLoading: userStaffMemberIsLoading,
    isError: userStaffMemberIsError,
    data: currentUser,
  } = useStaffMemberSelf()

  const eventReadAllowed = !!allowedEventsOperationsData?.allowed_operations?.includes(
    businessEventsAllowedOperations.readEvent,
  )
  const taskReadAllowed = !!allowedEventsOperationsData?.allowed_operations?.includes(
    businessEventsAllowedOperations.readTask,
  )
  const stageReadAllowed = !!allowedEventsOperationsData?.allowed_operations?.includes(
    businessEventsAllowedOperations.decisionStageRead,
  )

  const currentUserId = currentUser?.id

  const renderContent = useCallback(
    () => (
      <CWPLayout>
        {eventReadAllowed && <UserEventsSummaryCard eventAssigneeId={currentUserId} />}
        {taskReadAllowed && <UserTasksSummaryCard taskAssigneeId={currentUserId} />}
        {stageReadAllowed && <UserDecisionStageVotesCard userId={currentUserId} />}
        {stageReadAllowed && <UserApprovalsCard userId={currentUserId} />}
      </CWPLayout>
    ),
    [currentUserId, eventReadAllowed, stageReadAllowed, taskReadAllowed],
  )

  return (
    <Section titleText={t('businessevents-and-tasks')}>
      <LoadingStateWrapper
        isLoading={allowedEventsOperationsIsLoading || userStaffMemberIsLoading}
        isError={allowedEventsOperationsIsError || userStaffMemberIsError}
        renderContent={renderContent}
      />
    </Section>
  )
}
