import { Label } from '@fioneer/ui5-webcomponents-react'
import '@ui5/webcomponents-icons/dist/AllIcons.js'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { valuationStatus } from 'api/property/valuation/valuationRequests'
import DocumentSelectDialog from 'components/domains/documents/DocumentSelectDialog'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import DocumentUploadDialog from 'components/domains/documents/DocumentUploadDialog'
import ValuationRequestFileDrop from 'components/domains/properties/valuation/table/ValuationRequestFileDrop'
import styles from 'components/domains/properties/valuation/table/ValuationRequestsTable.module.css'
import ValuationRequestTableReportDisplay from 'components/domains/properties/valuation/table/table-cells/display/ValuationRequestTableReportDisplay'
import ValuationRequestTableSelectReportButtonTypes from 'components/domains/properties/valuation/table/table-cells/edit/ValuationRequestTableSelectReportButtonTypes'
import {
  ValuationsRequestsTableReasonSelect,
  valuationReasonsShape,
} from 'components/domains/properties/valuation/table/table-cells/edit/ValuationRequestsTableReasonSelect'
import ValuationRequestsTableSelectReportButton from 'components/domains/properties/valuation/table/table-cells/edit/ValuationRequestsTableSelectReportButton'
import ValuationRequestsTableValuationTypeSelect from 'components/domains/properties/valuation/table/table-cells/edit/ValuationRequestsTableValuationTypeSelect'
import useCanFileDrop from 'components/ui/document/useCanFileDrop'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import findDocument from 'hooks/services/documents/findDocument'
import useDocument from 'hooks/services/documents/useDocument'
import useDocuments from 'hooks/services/documents/useDocuments'
import { patchDocumentReferencesOperations } from 'hooks/services/documents/usePatchDocumentReferencesForDocument'

/** @param {PropTypes.InferProps<typeof propTypes>} */
const noop = () => {}

export const ValuationRequestReport = ({
  isEditingRow = false,
  isEditing = false,
  valuationRequest,
  handleOnEditReasonChange = noop,
  handleOnEditValuationTypeChange = noop,
  handleOnEditReportChange = noop,
  valuationReasons,
  editValuation,
  propertyId,
  propertyDisplayId,
  propertyName,
  fileUpload,
  setFileUpload,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.property.valuation.requests' })

  const { id, info: { status } = {} } = valuationRequest ?? {}

  const [lastUploadedFileId, setLastUploadedFileId] = useState('')
  const {
    data: root,
    isLoading,
    isError,
  } = useDocuments({ entityId: id, entityType: DocumentTypes.ValuationRequest })
  const { data } = useDocument({ documentId: lastUploadedFileId })
  const [isDocumentUploadOpen, setIsDocumentUploadOpen] = useState(false)
  const [isDocumentSelectOpen, setIsDocumentSelectOpen] = useState(false)
  const [isKeepDocumentUploadMounted, setIsKeepDocumentUploadMounted] = useState(false)
  /** @type {{id: string, name: string, type: string | undefined}[]} */
  const [initialFiles, setInitialFiles] = useState(/** @type {FileList | undefined} */ (undefined))

  const showDocumentUploadDialog = useMemo(
    () => isDocumentUploadOpen || isKeepDocumentUploadMounted,
    [isDocumentUploadOpen, isKeepDocumentUploadMounted],
  )

  const handleDocumentSelectionChange = useCallback((newDocumentId) => {
    setLastUploadedFileId(newDocumentId)
  }, [])

  useEffect(() => {
    const shouldUpdateReport = Boolean(
      lastUploadedFileId &&
        data &&
        (!editValuation?.valuationReport?.report ||
          data.id !== editValuation?.valuationReport?.report?.id) &&
        isEditingRow,
    )

    if (shouldUpdateReport) {
      handleOnEditReportChange({ report: data, operation: patchDocumentReferencesOperations.add })
    }
  }, [
    data,
    editValuation?.valuationReport?.report,
    handleOnEditReportChange,
    isEditingRow,
    lastUploadedFileId,
    valuationRequest,
  ])

  useEffect(() => {
    setLastUploadedFileId('')
  }, [isEditingRow])

  const document = useMemo(
    () =>
      data && editValuation?.valuationReport?.report?.id === data.id ? data : findDocument(root),
    [data, editValuation?.valuationReport?.report?.id, root],
  )

  const showReport =
    editValuation?.valuationReport?.operation !== patchDocumentReferencesOperations.remove

  /** @param {{type: string, valuationId: string}} params */
  const handleReportSelect = useCallback(({ type }) => {
    switch (type) {
      case ValuationRequestTableSelectReportButtonTypes.Select:
        setIsDocumentSelectOpen(true)
        break
      case ValuationRequestTableSelectReportButtonTypes.Upload:
        setIsDocumentUploadOpen(true)
        setIsKeepDocumentUploadMounted(true)
        break
      default:
        throw new Error(`Unknown menu type: "${type}"`)
    }
  }, [])

  const handleDocumentFileDrop = useCallback((files) => {
    setInitialFiles(files)
    setIsDocumentUploadOpen(true)
    setIsKeepDocumentUploadMounted(true)
  }, [])

  const onUploadDialogUploadFinished = useCallback((fileId) => {
    setIsKeepDocumentUploadMounted(false)
    setLastUploadedFileId(fileId)
  }, [])

  const onUploadDialogClose = useCallback(() => setInitialFiles(undefined), [])

  const uploadDialogInitialState = useMemo(
    () => ({
      selectedEntities: [
        {
          id: propertyId,
          name: propertyName,
          type: DocumentTypes.Property,
        },
        { id: valuationRequest?.id, name: '', type: DocumentTypes.ValuationRequest },
      ],
      files: initialFiles,
    }),
    [initialFiles, propertyId, propertyName, valuationRequest?.id],
  )

  const { isOver, canDrop, dropRef } = useCanFileDrop()

  useEffect(() => {
    if (!canDrop) {
      return setFileUpload({
        isOverById: [],
        canDrop: false,
      })
    }
    if (isOver) {
      const newId = fileUpload.isOverById.includes(valuationRequest.id)
        ? fileUpload.isOverById
        : [...fileUpload.isOverById, valuationRequest.id]

      setFileUpload({
        isOverById: newId,
        canDrop: true,
      })
    } else {
      const ids = fileUpload.isOverById.filter((requestId) => requestId !== valuationRequest.id)
      setFileUpload({
        isOverById: ids,
        canDrop: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canDrop, isOver, setFileUpload, valuationRequest])

  return (
    <LoadingStateWrapper isLoading={isLoading} isError={isError}>
      {isEditingRow && isEditing ? (
        <>
          {document && showReport ? (
            <ValuationRequestTableReportDisplay
              isEditing={isEditing}
              document={document}
              onUnlinkReport={() =>
                handleOnEditReportChange({
                  report: document,
                  operation: patchDocumentReferencesOperations.remove,
                })
              }
            />
          ) : (
            <ValuationRequestsTableSelectReportButton
              className={styles.selectReportButton}
              onSelect={(e) => {
                handleReportSelect({ type: e.detail.item.dataset.buttonId, valuationId: id })
              }}
            />
          )}
          <div className={styles.reportCellGrid}>
            <Label showColon>{t('table.table-cells.label.reason')}</Label>
            <ValuationsRequestsTableReasonSelect
              valuationReasons={valuationReasons}
              reason={editValuation.reason}
              onChange={handleOnEditReasonChange}
              disabled={status === valuationStatus.completed}
            />
            <Label showColon>{t('table.table-cells.label.valuation-type')}</Label>
            <ValuationRequestsTableValuationTypeSelect
              valuationReasons={valuationReasons}
              reason={editValuation.reason}
              valuationType={editValuation.valuationType}
              onChange={handleOnEditValuationTypeChange}
              disabled={status === valuationStatus.completed}
            />
            {isDocumentSelectOpen && (
              <DocumentSelectDialog
                open={isDocumentSelectOpen}
                onClose={() => setIsDocumentSelectOpen(false)}
                onChange={handleDocumentSelectionChange}
                propertyId={propertyId}
                entity={{
                  id: propertyId,
                  name: propertyName,
                  displayId: propertyDisplayId,
                  type: DocumentTypes.Property,
                }}
              />
            )}
          </div>
        </>
      ) : (
        <ValuationRequestTableReportDisplay isEditing={isEditing} document={document} />
      )}
      <div ref={dropRef}>
        {!document && status !== valuationStatus.cancelled && (
          <ValuationRequestFileDrop
            onDrop={handleDocumentFileDrop}
            customContainerStyles={styles.customFileDropContainerStyles}
            isOver={isOver}
            canDrop={canDrop}
          />
        )}
        {showDocumentUploadDialog && (
          <DocumentUploadDialog
            isOpen={isDocumentUploadOpen}
            setIsOpen={setIsDocumentUploadOpen}
            type={DocumentTypes.Property}
            initialState={uploadDialogInitialState}
            onClose={onUploadDialogClose}
            onUploadFinished={onUploadDialogUploadFinished}
          />
        )}
      </div>
    </LoadingStateWrapper>
  )
}

export const valuationReportShape = PropTypes.shape({
  report: PropTypes.shape({
    id: PropTypes.string,
  }),
  operation: PropTypes.string,
})

export const valuationRequestShape = PropTypes.shape({
  id: PropTypes.string,
  reason: PropTypes.string,
  propertyId: PropTypes.string,
  valuationType: PropTypes.string,
  status: PropTypes.string,
  note: PropTypes.string,
  reportDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  valuer: PropTypes.string,
  valuationReport: valuationReportShape,
})

const propTypes = {
  isEditing: PropTypes.bool,
  isEditingRow: PropTypes.bool,
  valuationRequest: PropTypes.shape({
    id: PropTypes.string,
    reason: PropTypes.string,
    type: PropTypes.string,
    info: PropTypes.shape({
      status: PropTypes.string,
    }),
  }),
  handleOnEditReasonChange: PropTypes.func,
  handleOnEditValuationTypeChange: PropTypes.func,
  valuationReasons: valuationReasonsShape,
  editValuation: PropTypes.shape({
    reason: PropTypes.string,
    valuationType: PropTypes.string,
    valuationRequest: valuationRequestShape,
    valuationReport: valuationReportShape,
  }),
  handleOnEditReportChange: PropTypes.func.isRequired,
  propertyId: PropTypes.string,
  propertyDisplayId: PropTypes.string,
  propertyName: PropTypes.string,
  fileUpload: PropTypes.shape({
    canDrop: PropTypes.bool,
    isOverById: PropTypes.arrayOf(PropTypes.string),
  }),
  setFileUpload: PropTypes.func.isRequired,
}

ValuationRequestReport.propTypes = propTypes
