import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import regulatoryInformationSchema from 'components/domains/business-partners/tile/general-information/regulatoryInformation/regulatoryInformationSchema'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultipleRegulatoryInformation = ({ businessPartnerIds, options = {} }) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: businessPartnerIds.map((businessPartnerId) => ({
        path: `/businesspartners/${businessPartnerId}/regulatory-informations`,
        keys: ['businesspartners', businessPartnerId, 'regulatory-informations'],
      })),
      translated: true,
      useCache: true,
      options: { ...options, enabled: !isEmpty(businessPartnerIds) },
    }),
  )

  return useMemo(
    () =>
      responses.map((response, index) => ({
        ...response,
        data: {
          businessPartnerId: businessPartnerIds[index],
          ...regulatoryInformationSchema.optional().parse(camelize(response.data)),
        },
      })),
    [businessPartnerIds, responses],
  )
}

export default useMultipleRegulatoryInformation
