import { ResponsiveGridLayout } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import DecisionProcessOverviewTable from 'components/domains/business-events-and-tasks/decision-process/DecisionProcessOverviewTable'
import Card from 'components/ui/card/Card'

const DecisionProcessOverviewPage = ({ decisionStages }) => (
  <ResponsiveGridLayout
    columnsXL={1}
    columnsL={1}
    columnsM={1}
    columnsS={1}
    columnGap="1rem"
    rowGap="1rem"
  >
    <Card>
      <DecisionProcessOverviewTable decisionStages={decisionStages} />
    </Card>
  </ResponsiveGridLayout>
)

DecisionProcessOverviewPage.propTypes = {
  decisionStages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      info: PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        decisionType: PropTypes.string.isRequired,
        decisionStatus: PropTypes.string.isRequired,
        decisionDate: PropTypes.string,
      }),
    }),
  ).isRequired,
}

export default DecisionProcessOverviewPage
