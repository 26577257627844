import { Icon, TextAlign, VerticalAlign } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CollateralAgreementsTableCell,
  StatusColumnCell,
  renderCagCell,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/CollateralAgreementsTableCell'
import { useCollateralAgreementsTableColumnSort } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/shared/useCollateralAgreementsTableColumnSort'

const useOpsMemoCollateralAgreementsTableColumnDefinitions = () => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.collaterals.collateral-agreements-table',
  })

  const { sortByStringValue, sortByType } = useCollateralAgreementsTableColumnSort()

  return useMemo(
    () =>
      [
        {
          Header: t('cag-label'),
          accessor: 'cag',
          minWidth: 280,
          renderCell: renderCagCell,
          sortType: sortByStringValue,
        },
        {
          Header: t('status-label'),
          accessor: 'status',
          hAlign: TextAlign.Left,
          minWidth: 150,
          renderCell: StatusColumnCell,
          sortType: sortByType,
        },
        {
          Header: '',
          accessor: 'navigation',
          hAlign: TextAlign.Right,
          disableSortBy: true,
          disableResizing: true,
          renderCell: ({ cell }) =>
            isEmpty(cell.row.subRows) &&
            cell.row.values?.cag?.id &&
            cell.row.values?.cag?.id !== 'total' ? (
              // Setting pointer event to none to allow the click event to be passed to the row
              <Icon name="slim-arrow-right" style={{ pointerEvents: 'none' }} />
            ) : (
              <div />
            ),
          width: 1, // the value is not important, but width has to be set
        },
      ].map((element) => ({
        disableDragAndDrop: true,
        disableResizing: false,
        Cell: (cellProps) =>
          CollateralAgreementsTableCell({
            ...cellProps,
            renderCell: element.renderCell,
          }),
        vAlign: VerticalAlign.Middle,
        ...element,
      })),
    [t, sortByStringValue, sortByType],
  )
}

export default useOpsMemoCollateralAgreementsTableColumnDefinitions
