import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const usePutPropertyImages = ({ onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()

  return useMutation(
    async ({ property_uuid, property_images }) => {
      const { data } = await put({
        path: `/properties/${property_uuid}/images`,
        body: property_images,
      })
      return data
    },
    { onSuccess, onError },
  )
}
