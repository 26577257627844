import { FlexBox, FlexBoxDirection, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/appendix/borrower-selection/AnnualReviewBorrowerSelectionTile.module.css'
import CloseableMessageStrip from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/feedback/CloseableMessageStrip'
import LoadingSelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/select/LoadingSelect'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import { setSourceRender, setTileMetadata } from 'redux/slices/decision-paper/tilesOverviewSlice'

const AnnualReviewBorrowerSelectionTile = ({ tileId, isPdfView }) => {
  const dispatch = useDispatch()
  const { t: tNoPrefix } = useTranslation('decisionPaper')

  const { gktoMembers: borrowersData } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId].data,
  )

  const { id: preSelectedBorrowerId, name: preSelectedBorrowerName } = borrowersData?.[0] ?? {}

  const borrowersMetadata = useSelector(
    (state) =>
      state.decisionPaper.tilesOverview.tileMetadata?.[
        availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsBorrowerSelection
      ],
  )

  useEffect(() => {
    dispatch(
      setSourceRender({
        tileId,
        sourceRender: { businessPartnerId: borrowersMetadata?.selectedBorrowerId },
      }),
    )
  }, [borrowersMetadata?.selectedBorrowerId, dispatch, tileId])

  useEffect(() => {
    if (isNil(borrowersMetadata)) {
      dispatch(
        setTileMetadata({
          tileCode: availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsBorrowerSelection,
          metadata: {
            selectedBorrowerId: preSelectedBorrowerId,
            selectedBorrowerName: preSelectedBorrowerName,
            allBorrowers: borrowersData,
          },
        }),
      )
    }
  }, [dispatch, borrowersMetadata, preSelectedBorrowerId, preSelectedBorrowerName, borrowersData])

  const handleOnChange = useCallback(
    (_event, selectedBorrowerId) => {
      const { name: selectedBorrowerName } =
        borrowersData.find((businessPartner) => businessPartner?.id === selectedBorrowerId) ?? {}

      dispatch(
        setTileMetadata({
          tileCode: availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsBorrowerSelection,
          metadata: { selectedBorrowerId, selectedBorrowerName, allBorrowers: borrowersData },
        }),
      )
    },
    [borrowersData, dispatch],
  )

  const borrowerSelectData =
    borrowersData?.map((borrower) => ({
      key: borrower?.id,
      name: `${borrower?.name} (${borrower?.id})`,
    })) ?? []

  const renderBorrowerSelect = () => {
    if (isEmpty(borrowersData)) {
      return (
        <CloseableMessageStrip design={MessageStripDesign.Information} hideCloseButton={true}>
          {tNoPrefix('components.business-partner.associated-borrowers.no-borrowers')}
        </CloseableMessageStrip>
      )
    } else if (isPdfView) {
      return (
        <CloseableMessageStrip design={MessageStripDesign.Information} hideCloseButton={true}>
          {tNoPrefix('tiles.borrower-selection.pdf-view')}
        </CloseableMessageStrip>
      )
    }
    return (
      <LoadingSelect
        id="annualReviewBorrower-loadingSelect"
        className={styles.borrowerSelect}
        loadingHook={() => ({
          isLoading: false,
          isError: false,
          data: borrowerSelectData,
        })}
        selectedKey={borrowersMetadata?.selectedBorrowerId}
        showEmptyOption={isNil(borrowersData)}
        optionDisplayName="name"
        optionKeyName="key"
        onChange={handleOnChange}
      />
    )
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.borrowerSelectionWrapper}>
      {renderBorrowerSelect()}
    </FlexBox>
  )
}

AnnualReviewBorrowerSelectionTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default AnnualReviewBorrowerSelectionTile
