import { ValueState } from '@fioneer/ui5-webcomponents-react'

export const propertyMonitoringStatus = {
  activeManual: 'ACTIVE_MANUAL',
  activeManualForced: 'ACTIVE_MANUAL_FORCED',
  activeAutomated: 'ACTIVE_AUTOMATED',
  inactive: 'INACTIVE',
  paused: 'PAUSED',
}

export const propertyMonitoringProxyValues = {
  default: 'DEFAULT',
  marketSpecific: 'SPECIFIC',
}

export const propertyMonitoringThresholdType = {
  relative: 'RELATIVE',
  absolute: 'ABSOLUTE',
}

export const propertyMonitoringCriticalMovementDirection = {
  up: 'UP',
  down: 'DOWN',
}

export const propertyMonitoringPermissions = {
  read: 'property-monitoring-read',
  editSettings: 'property-monitoring-edit-settings',
  resumeAutomated: 'property-monitoring-resume-automated',
  activateDeactivate: 'property-monitoring-activate-deactivate',
  exportLog: 'property-monitoring-export-monitoring-log',
  confirmationUpdate: 'property-monitoring-mv-confirmation-update',
}

export const propertyMonitoringProxiesAllowedOperations = {
  proxySettingsRead: 'property-monitoring-proxy-settings-read',
  proxySettingsUpdate: 'property-monitoring-proxy-settings-update',
}

export const monitoringStatusTranslationKeys = {
  [propertyMonitoringStatus.activeManual]: 'property-monitoring.status.active-manual',
  [propertyMonitoringStatus.activeManualForced]: 'property-monitoring.status.active-manual',
  [propertyMonitoringStatus.activeAutomated]: 'property-monitoring.status.active-automated',
  [propertyMonitoringStatus.inactive]: 'property-monitoring.status.inactive',
  [propertyMonitoringStatus.paused]: 'property-monitoring.status.paused',
}

export const proxySettingsTranslationKeys = {
  [propertyMonitoringProxyValues.default]: 'property-monitoring.proxies.default-thresholds',
  [propertyMonitoringProxyValues.marketSpecific]: 'property-monitoring.proxies.market-specific',
}

export const marketValuationConfirmationStatus = {
  pending: 'PENDING',
  confirmed: 'CONFIRMED',
  notConfirmed: 'NOT_CONFIRMED',
}

const objectStatusForMarketValuationConfirmationStatus = {
  [marketValuationConfirmationStatus.pending]: {
    translationKey: 'property-monitoring.market-valuation-confirmation.status.pending',
    objectStatus: ValueState.None,
  },
  [marketValuationConfirmationStatus.confirmed]: {
    translationKey: 'property-monitoring.market-valuation-confirmation.status.confirmed',
    objectStatus: ValueState.Success,
  },
  [marketValuationConfirmationStatus.notConfirmed]: {
    translationKey: 'property-monitoring.market-valuation-confirmation.status.not-confirmed',
    objectStatus: ValueState.Error,
  },
  default: {
    translationKey: 'property-monitoring.market-valuation-confirmation.status.unknown',
    objectStatus: ValueState.None,
  },
}

export const getObjectStatusForMarketValuationConfirmationStatus = (status) =>
  objectStatusForMarketValuationConfirmationStatus[status] !== undefined
    ? objectStatusForMarketValuationConfirmationStatus[status]
    : objectStatusForMarketValuationConfirmationStatus.default
