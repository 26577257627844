import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import { useConfig } from 'hooks/config/useConfig'
import mapMonitoringItemsPerCovenantUuid from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/appendix/periodical-checks/checks/mapMonitoringItemsPerCovenantUuid'
import extractTileCodeKeyFromTileCode from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/appendix/periodical-checks/shared/extractTileCodeKeyFromTileCode'
import mapCovenantUuidsPerPeriodicalCheckTypePerBusinessPartner from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/appendix/periodical-checks/shared/mapCovenantUuidsPerPeriodicalCheckTypePerBusinessPartner'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useEventsByIdList from 'hooks/services/business-events-and-tasks/events/useEventsByIdList'
import useBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationships'
import useMultipleCovenantMonitoringItemsByCovenantUuid from 'hooks/services/deals/covenants/monitorings/useMultipleCovenantMonitoringItemsByCovenantUuid'
import useMultipleCovenantsByBusinessPartnerId from 'hooks/services/deals/covenants/useMultipleCovenantsByBusinessPartnerId'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const JOINT_ACCOUNT_CODE = 'YJR080'

const useAnnualReviewPeriodicalCheckMonitoringItems = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
  tileCode,
) => {
  const { data: config, isLoading: isConfigLoading, isError: isConfigError } = useConfig()

  const keyMapping = useMemo(
    () => config?.decisionPaper?.covenants?.periodicalChecks?.keyMapping ?? [],
    [config?.decisionPaper?.covenants?.periodicalChecks?.keyMapping],
  )

  const covenantNameKeysByTileCodeKey = Object.fromEntries(
    keyMapping.map(({ tileCodeKey, covenantNameKey }) => [tileCodeKey, covenantNameKey]),
  )

  const {
    data: businessPartnerRelationships,
    isLoading: isBusinessPartnerRelationshipsLoading,
    isError: isBusinessPartnerRelationshipsError,
    error: businessPartnerRelationshipsError,
  } = useBusinessPartnerRelationships(businessPartnerId)

  const gktoUnitRelationship = businessPartnerRelationships?.unitRelationships.find(
    (unitRelationship) => unitRelationship.id === JOINT_ACCOUNT_CODE,
  )
  const gktoRelationships = gktoUnitRelationship?.relationships.find(
    (relationship) => relationship.head.id === businessPartnerId,
  )
  // gktoMembers are borrowers
  const borrowersData = compact(
    [
      {
        borrowerId: businessPartnerId,
      },
    ].concat(
      gktoRelationships?.members.map((member) => ({
        borrowerId: member.id,
      })),
    ),
  )

  const borrowerIds = borrowersData.map((borrower) => borrower.borrowerId)
  const {
    isLoading: isLoadingCovenants,
    isError: isErrorCovenants,
    data: businessPartnerIdAndCovenantsPairs = [],
  } = useCombinedQueryResults(
    useMultipleCovenantsByBusinessPartnerId({
      businessPartnerIds: borrowerIds,
    }),
  )

  const { covenantUuidsPerPeriodicalCheckTypePerBusinessPartner, covenantUuids } = useMemo(
    () =>
      mapCovenantUuidsPerPeriodicalCheckTypePerBusinessPartner(businessPartnerIdAndCovenantsPairs),
    [businessPartnerIdAndCovenantsPairs],
  )

  const {
    isLoading: isLoadingCovenantMonitoringItems,
    isError: isErrorCovenantMonitoringItems,
    data: covenantUuidAndCovenantMonitoringItemsPairs = [],
  } = useCombinedQueryResults(
    useMultipleCovenantMonitoringItemsByCovenantUuid({
      covenantUuids,
    }),
    { forceDataReturn: true },
  )

  const monitoringItemsPerCovenantUuid = useMemo(
    () => mapMonitoringItemsPerCovenantUuid(covenantUuidAndCovenantMonitoringItemsPairs),
    [covenantUuidAndCovenantMonitoringItemsPairs],
  )

  const eventIds = compact(
    compact(Object.values(monitoringItemsPerCovenantUuid).flat()).map(
      (item) => item?.linkedCovenantCheck?.linkedEventId,
    ),
  )
  const {
    data: events = [],
    isLoading: isLoadingEventsByIdList,
    isError: isErrorEventsByIdList,
  } = useEventsByIdList(eventIds)

  const borrowersMetadata = useSelector(
    (state) =>
      state.decisionPaper.tilesOverview.tileMetadata?.[
        availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsBorrowerSelection
      ],
  )
  const isBorrowerSelectionDropdownTileUsedInDecisionPaper = useSelector((state) =>
    Object.keys(state.decisionPaper.tilesOverview.tileMetadata ?? {}).includes(
      availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsBorrowerSelection,
    ),
  )

  const { selectedBorrowerId } = isBorrowerSelectionDropdownTileUsedInDecisionPaper
    ? borrowersMetadata ?? {}
    : {
        selectedBorrowerId: businessPartnerId,
      }
  const tileCodeKey = extractTileCodeKeyFromTileCode(tileCode)
  const covenantUuid = useMemo(
    () =>
      covenantUuidsPerPeriodicalCheckTypePerBusinessPartner?.[selectedBorrowerId]?.[
        covenantNameKeysByTileCodeKey?.[tileCodeKey]
      ],
    [
      covenantNameKeysByTileCodeKey,
      covenantUuidsPerPeriodicalCheckTypePerBusinessPartner,
      selectedBorrowerId,
      tileCodeKey,
    ],
  )

  const sourceRender = useMemo(
    () => ({
      path: !isNil(covenantUuid)
        ? `/business-partners/${selectedBorrowerId}/periodical-checks#${covenantUuid}`
        : `/business-partners/${selectedBorrowerId}/periodical-checks`,
    }),
    [covenantUuid, selectedBorrowerId],
  )

  const { isSomeValueError, isSomeValueLoading, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isConfigLoading,
      isBusinessPartnerRelationshipsLoading,
      isLoadingCovenants,
      isLoadingCovenantMonitoringItems,
      isLoadingEventsByIdList,
    ],
    errorValues: [
      isConfigError,
      isBusinessPartnerRelationshipsError,
      isErrorCovenants,
      isErrorCovenantMonitoringItems,
      isErrorEventsByIdList,
    ],
    errorDetails: [businessPartnerRelationshipsError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    return {
      isLoading: false,
      isError: false,
      data: {
        sourceRender,
        businessPartnerId,
        monitoringItemsPerCovenantUuid: JSON.stringify(monitoringItemsPerCovenantUuid),
        covenantUuidsPerPeriodicalCheckTypePerBusinessPartner: JSON.stringify(
          covenantUuidsPerPeriodicalCheckTypePerBusinessPartner,
        ),
        covenantNameKeysByTileCodeKey: JSON.stringify(covenantNameKeysByTileCodeKey),
        events,
      },
    }
  }, [
    isSomeValueLoading,
    isSomeValueError,
    sourceRender,
    businessPartnerId,
    monitoringItemsPerCovenantUuid,
    covenantUuidsPerPeriodicalCheckTypePerBusinessPartner,
    covenantNameKeysByTileCodeKey,
    events,
    error,
  ])
}

export default useAnnualReviewPeriodicalCheckMonitoringItems
