import { useMemo } from 'react'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { getValuationData } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/fact-sheet/useFactSheetValuation'
import useMultipleDealProperties from 'hooks/services/deals/properties/useMultipleDealProperties'
import { useMultiPropertyViewByPropertyUuidsArrays } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuidsArrays'
import useMultipleArrayPropertyUuids from 'hooks/services/properties/useMultipleArrayPropertyUuids'
import useMultiArrayPropertyValuations from 'hooks/services/properties/valuations/useMultiArrayPropertyValuations'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const useMultipleFactSheetValuation = ({ entityRef: { entityId: businessPartnerId } }, tileId) => {
  const {
    data: { dealsData, dealUuids } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  // get an array of property uuids for each deal
  const {
    data: multipleDealPropertiesData,
    isLoading: isMultipleDealPropertiesLoading,
    isError: isMultipleDealPropertiesError,
    error: multipleDealPropertiesError,
  } = useCombinedQueryResults(
    useMultipleDealProperties({
      dealUuids,
    }) ?? {},
  )

  const isFollowUpRequestEnabled = useMemo(
    () =>
      !isMultipleDealPropertiesLoading &&
      !isMultipleDealPropertiesError &&
      multipleDealPropertiesData &&
      multipleDealPropertiesData.some((property) => property.dealProperties?.length > 0),
    [isMultipleDealPropertiesError, isMultipleDealPropertiesLoading, multipleDealPropertiesData],
  )

  // create an array of arrays of property uuids related to a deal id
  const propertyUuidsArrays = useMemo(
    () =>
      isFollowUpRequestEnabled
        ? multipleDealPropertiesData.map((dealProperty) =>
            dealProperty.dealProperties.map((property) => property.propertyUuid),
          )
        : [],
    [isFollowUpRequestEnabled, multipleDealPropertiesData],
  )

  // array version of usePropertyUuids:
  // get an array of responses for each properties array (related to a deal id),
  // so one entry per deal on the highest array level
  const {
    data: multipleArrayPropertyUuidsData,
    isLoading: isMultipleArrayPropertyUuidsLoading,
    isError: isMultipleArrayPropertyUuidsError,
  } = useMultipleArrayPropertyUuids({
    propertyUuidsArrays,
    options: { enabled: isFollowUpRequestEnabled },
  })

  // array version of useMultiPropertyViewByPropertyUuids:
  // in case of multiple properties per array, fetch also the portfolio uuid used in the source link
  const {
    data: multiPropertyViewByPropertyUuidsArraysData,
    isLoading: isMultiPropertyViewByPropertyUuidsArraysLoading,
    isError: isMultiPropertyViewByPropertyUuidsArraysError,
  } = useMultiPropertyViewByPropertyUuidsArrays({
    propertyUuidsArrays,
    options: { enabled: isFollowUpRequestEnabled },
  })

  // array version of useMultiPropertyValuations:
  // get an array of responses for each properties array (related to a deal id),
  // so one entry per deal on the highest array level
  const {
    data: multiArrayPropertyValuationsData,
    isLoading: isMultiArrayPropertyValuationsLoading,
    isError: isMultiArrayPropertyValuationsError,
  } = useMultiArrayPropertyValuations({
    propertyUuidsArrays,
    includeAggregates: ['SUM'],
    preferredCurrency: undefined,
    options: {
      enabled: isFollowUpRequestEnabled,
    },
  })

  const multipleValuationInformation = useMemo(
    () =>
      dealUuids.map((dealUuid, index) =>
        getValuationData(multiArrayPropertyValuationsData?.[index]?.data),
      ),
    [dealUuids, multiArrayPropertyValuationsData],
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isAnnualReviewDealsLoading,
      isMultipleDealPropertiesLoading,
      isMultipleArrayPropertyUuidsLoading,
      isMultiPropertyViewByPropertyUuidsArraysLoading,
      isMultiArrayPropertyValuationsLoading,
    ],
    errorValues: [
      isAnnualReviewDealsError,
      isMultipleDealPropertiesError,
      isMultipleArrayPropertyUuidsError,
      isMultiPropertyViewByPropertyUuidsArraysError,
      isMultiArrayPropertyValuationsError,
    ],
    errorDetails: [annualReviewDealsError, multipleDealPropertiesError],
    tileId,
  })

  const sourcePaths = useMemo(
    () =>
      dealUuids.map((dealUuid, index) => {
        const propertiesArrayData = multipleArrayPropertyUuidsData?.[index]?.data?.properties
        const multiPropertiesArrayViewUuid =
          multiPropertyViewByPropertyUuidsArraysData?.[index]?.data?.uuid
        return propertiesArrayData?.length > 1
          ? `/properties/portfolio/overview?portfolio-view-id=${multiPropertiesArrayViewUuid}`
          : `/properties/${propertiesArrayData?.[0]?.id}/valuation`
      }),
    [dealUuids, multiPropertyViewByPropertyUuidsArraysData, multipleArrayPropertyUuidsData],
  )

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        multipleValuationInformation,
        hasNoProperties: !isFollowUpRequestEnabled,
        sourcePaths,
        sourceRender: { path: `${sourcePaths[0]}` },
      },
    }
  }, [
    dealsData,
    error,
    isFollowUpRequestEnabled,
    isSomeValueError,
    isSomeValueLoading,
    multipleValuationInformation,
    sourcePaths,
  ])
}

export default useMultipleFactSheetValuation
