import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { decisionStageStatus as possibleDecisionStageStatus } from 'api/decision-process/decisionProcessApi'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import useDeleteDecisionStageFlow from 'hooks/services/business-events-and-tasks/decision-process/flows/useDeleteDecisionStageFlow'
import useDiscardDecisionFlow from 'hooks/services/business-events-and-tasks/decision-process/flows/useDiscardDecisionFlow'
import useRequestDecisionFlow from 'hooks/services/business-events-and-tasks/decision-process/flows/useRequestDecisionFlow'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import { eventsAndTasksPaths } from 'routes/business-events-and-tasks/BusinessEventsAndTasksRoutes'
import paths from 'routes/paths'

const useDecisionStageDetailButtons = ({
  decisionStageId,
  decisionStageStatus,
  decisionPaperVersion,
}) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage',
  })
  const {
    event: { id: eventId },
    allowedOperations,
  } = useContext(BusinessEventsAndTasksContext)
  const navigate = useNavigate()

  const hasDeleteDecisionStagePermission = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.deleteDecisionStage),
    [allowedOperations],
  )
  const hasUpdateDecisionStagePermission = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.updateDecisionStage),
    [allowedOperations],
  )

  const onDeleteSuccessCallback = useCallback(() => {
    navigate(
      `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}/${eventsAndTasksPaths.eventDetail.decisionProcess}`,
    )
  }, [navigate, eventId])

  const { onRequestDecisionButtonClicked } = useRequestDecisionFlow({
    eventId,
    decisionPaperVersion,
    decisionStageId,
  })

  const { onDiscardDecisionButtonClicked } = useDiscardDecisionFlow({
    eventId,
    decisionStageId,
  })

  const { handleDeleteClicked } = useDeleteDecisionStageFlow({
    stageId: decisionStageId,
    eventId,
    successCallback: onDeleteSuccessCallback,
  })

  const requestDecisionButton = useMemo(
    () =>
      hasUpdateDecisionStagePermission && (
        <Button
          key="requestDecisionButton"
          design={ButtonDesign.Emphasized}
          onClick={onRequestDecisionButtonClicked}
        >
          {t('buttons.request-decision')}
        </Button>
      ),
    [t, hasUpdateDecisionStagePermission, onRequestDecisionButtonClicked],
  )

  const deleteStageButton = useMemo(
    () =>
      hasDeleteDecisionStagePermission && (
        <Button
          key="deleteStageButton"
          design={ButtonDesign.Transparent}
          onClick={handleDeleteClicked}
        >
          {tNoPrefix('buttons.delete')}
        </Button>
      ),
    [tNoPrefix, handleDeleteClicked, hasDeleteDecisionStagePermission],
  )

  const discardDecisionButton = useMemo(
    () =>
      hasUpdateDecisionStagePermission && (
        <Button
          key="discardDecisionButton"
          design={ButtonDesign.Transparent}
          onClick={onDiscardDecisionButtonClicked}
        >
          {t('buttons.discard-decision')}
        </Button>
      ),
    [t, hasUpdateDecisionStagePermission, onDiscardDecisionButtonClicked],
  )

  const buttons = useMemo(() => {
    if (
      decisionStageStatus === possibleDecisionStageStatus.planned ||
      decisionStageStatus === possibleDecisionStageStatus.inProgressRequestDiscarded
    ) {
      return [requestDecisionButton, deleteStageButton]
    }

    if (decisionStageStatus === possibleDecisionStageStatus.inProgress) {
      return [discardDecisionButton]
    }
    return []
  }, [decisionStageStatus, deleteStageButton, discardDecisionButton, requestDecisionButton])

  return useMemo(
    () => ({
      buttons,
    }),
    [buttons],
  )
}

export default useDecisionStageDetailButtons
