import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useCashflowsParameterDefinitions = () =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/configurations/cashflows/parameters`,
      useCache: true,
      keys: ['deals', 'configuration', 'cashflows', 'parameters'],
    }),
  )

export default useCashflowsParameterDefinitions
