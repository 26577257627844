import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useCashflowGroups = () =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/configurations/cashflows/groups`,
      translated: true,
      keys: ['deals', 'configurations', 'cashflows', 'groups'],
    }),
  )

export default useCashflowGroups
