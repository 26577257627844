import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

/**
 * Error component when data is unavailable or could not be loaded in a table.
 *
 * @param {object} props
 * @param {string?} [props.titleText] is the title translation text, defaults to "Data is currently not available."
 * @param {string?} [props.subtitleText] is the subtitle translation text, defaults to "Please try again later. If the error persists, contact your system administrator."
 */

const ErrorDataUnavailableInTable = ({ titleText = null, subtitleText = null }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.error.data-unavailable.table',
  })

  const titleTextToDisplay = titleText ?? t('title-text')
  const subtitleTextToDisplay = subtitleText ?? t('subtitle-text')

  return (
    <FlexBox direction={FlexBoxDirection.Column} alignItems={FlexBoxAlignItems.Center}>
      <Text>{`${titleTextToDisplay}`}</Text>
      <Text>{`${subtitleTextToDisplay}`}</Text>
    </FlexBox>
  )
}

ErrorDataUnavailableInTable.propTypes = {
  titleText: PropTypes.string,
  subtitleText: PropTypes.string,
}

export { ErrorDataUnavailableInTable }
