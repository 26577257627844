import { Link } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { useState } from 'react'
import styles from 'components/ui/rich-text/CollapsibleRichTextView.module.css'
import FormattedTextView from 'components/ui/rich-text-editor/FormattedTextView'

const CollapsibleRichTextView = ({ text, characterLimit, ...otherProps }) => {
  const [isTextExpanded, setIsTextExpanded] = useState(false)

  const toggleShowMore = () => {
    setIsTextExpanded(!isTextExpanded)
  }

  const renderContent = () =>
    text?.length <= characterLimit || isTextExpanded ? text : text?.substring(0, characterLimit)

  return (
    <div className={styles.wrapper}>
      <FormattedTextView formattedText={renderContent()} {...otherProps} />
      {text?.length > characterLimit && (
        <Link className={styles.toggleShowMoreLink} onClick={toggleShowMore}>
          {isTextExpanded ? t('buttons.less') : t('buttons.more')}
        </Link>
      )}
    </div>
  )
}

CollapsibleRichTextView.propTypes = {
  text: PropTypes.string,
  characterLimit: PropTypes.number,
}

export default CollapsibleRichTextView
