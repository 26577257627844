import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { TILE_VERSION } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import TenancyOverviewTileTableV1 from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/TenancyOverviewTileTableV1'
import TenancyOverviewTileTableV2 from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/TenancyOverviewTileTableV2'

const TenancyOverviewTileTableWrapper = ({ tileId, ...otherProps }) => {
  const { version } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const tenancyOverviewTileTable = useMemo(() => {
    switch (version) {
      case TILE_VERSION.V1:
        return <TenancyOverviewTileTableV1 {...otherProps} />
      default:
        return <TenancyOverviewTileTableV2 {...otherProps} />
    }
  }, [tileId, version, ...Object.values(otherProps)])

  return tenancyOverviewTileTable
}

TenancyOverviewTileTableWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default TenancyOverviewTileTableWrapper
