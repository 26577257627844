import { CardHeader, FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionProcessConditionsDecisionStatus } from 'api/decision-process/decisionProcessConditionsApi'
import ConditionsInformationDecisionStatusObject from 'components/domains/business-events-and-tasks/decision-stage/conditions/information/ConditionsInformationDecisionStatusObject'
import Card from 'components/ui/card/Card'
import DisplayCardViewInfoItemView from 'components/ui/card/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/ui/card/DisplayCardViewItemView'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const ConditionsInformationCard = ({ decisionStatus, decisionDate }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.conditions.information',
  })
  const { format: formatDate } = useShortDateFormatter()

  const header = useMemo(() => <CardHeader titleText={t('header.text')} />, [t])

  const decisionDateDisplayValue = useMemo(
    () => (decisionDate ? formatDate(decisionDate) : '-'),
    [decisionDate, formatDate],
  )

  const renderContent = useCallback(
    () => (
      <FlexBox direction={FlexBoxDirection.Column}>
        <DisplayCardViewItemView>
          <DisplayCardViewInfoItemView
            isFirstItem={true}
            label={t('label.status')}
            value={<ConditionsInformationDecisionStatusObject decisionStatus={decisionStatus} />}
          />
        </DisplayCardViewItemView>
        <DisplayCardViewItemView>
          <DisplayCardViewInfoItemView label={t('label.date')} value={decisionDateDisplayValue} />
        </DisplayCardViewItemView>
      </FlexBox>
    ),
    [decisionStatus, decisionDateDisplayValue, t],
  )

  return (
    <Card header={header}>
      <LoadingStateWrapper isError={false} isLoading={false} renderContent={renderContent} />
    </Card>
  )
}

ConditionsInformationCard.propTypes = {
  decisionStatus: PropTypes.oneOf(Object.values(decisionProcessConditionsDecisionStatus))
    .isRequired,
  decisionDate: PropTypes.string,
}

export default ConditionsInformationCard
