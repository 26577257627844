import { responseCodes } from 'api/requests'

export const DEFAULT_RETRY_COUNT = 2

/**
 * Determines if a failed HTTP request, represented by a status code,
 * should be retried based on its failure count and the type of error.
 *
 * @param {number} failureCount - The number of times the request has failed.
 * @param {object} error - The Error object from the HTTP request.
 * @returns {boolean} Returns true if the failed request should be retried, false otherwise.
 */
export const evaluateRetry = (failureCount, error) => {
  const { status } = error.response ?? {}
  switch (status) {
    case responseCodes.BAD_REQUEST:
    case responseCodes.UNAUTHORIZED:
    case responseCodes.FORBIDDEN:
    case responseCodes.METHOD_NOT_ALLOWED:
    case responseCodes.CONFLICT:
    case responseCodes.NOT_FOUND:
      // no retry for client or explicit errors
      return false

    case responseCodes.REQUEST_TIMEOUT:
      // retry for request timeout errors 408
      return true

    case responseCodes.INTERNAL_SERVER_ERROR:
    case responseCodes.BAD_GATEWAY:
    case responseCodes.SERVICE_UNAVAILABLE:
    case responseCodes.GATEWAY_TIMEOUT:
      // retry 2 more times if an unexpected (server) error occurs, 3 in total
      return failureCount < DEFAULT_RETRY_COUNT

    default:
      // no retry
      return false
  }
}
