import {
  CheckBox,
  ComboBox,
  ComboBoxGroupItem,
  ComboBoxItem,
  Input,
  Option,
  Select,
  Label,
  InputType,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import PropertyRentalUnitBusinessPartnerInput from 'components/domains/properties/rent-roll/working-version/PropertyRentalUnitBusinessPartnerInput'
import { ValueStateMessage } from 'components/domains/properties/rent-roll/working-version/ValueStateMessage'
import styles from 'components/domains/properties/rent-roll/working-version/rental-units-table/PropertyRentalUnitsWorkingVersionTableInput.module.css'
import { getRentalUnitWorkingVersionFieldId } from 'components/domains/properties/rent-roll/working-version/utils/rentRollWorkingVersionMappers'
import DatePickerWithoutMinWidth from 'components/ui/date-picker/DatePickerWithoutMinWidth'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import SelectWithFooter from 'components/ui/select/SelectWithFooter'
import { useNumberFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'
import {
  getDisplayNameByKey,
  getDisplayNameOrReturnKey,
  getKeyOrReturnDisplayName,
} from 'hooks/services/properties/rent-roll/working-version/useGetAllRentRollWorkingVersionOptions'

const MAX_LENGTH_RRWV_TEXT_INPUT = 255

const preventEventPropagation = (event) => event.stopPropagation()

const valueStatePropType = PropTypes.oneOf(Object.values(ValueState))

const joinClassNames = (classNames = []) => classNames.filter(Boolean).join(' ')

// Path shapes of the respective UI5 icons, so that we don't have to render ui5-icon
// web components in the ReadModeInput, which already causes a slight performance hit.
// To add another icon, inspect the rendered `ui5-icon` from the UI5 input component
// and copy the shape from the `svg path` element in its shadow DOM
export const readModeIcons = {
  'appointment-2':
    'M32 481V65q0-14 9.5-23T64 33h64V1h32v32h192V1h32v32h64q14 0 23 9t9 23v416q0 14-9 23t-23 9H64q-13 0-22.5-9T32 481zm416 0V129H64v352h384zM256 193q14 0 23 9t9 23-9 23-23 9-23-9-9-23 9-23 23-9zM128 321q14 0 23 9t9 23-9 23-23 9-23-9-9-23 9-23 23-9zm256-128q14 0 23 9t9 23-9 23-23 9-23-9-9-23 9-23 23-9zm0 128q14 0 23 9t9 23-9 23-23 9-23-9-9-23 9-23 23-9zm-128 0q14 0 23 9t9 23-9 23-23 9-23-9-9-23 9-23 23-9zM96 225q0-14 9-23t23-9 23 9 9 23-9 23-23 9-23-9-9-23zM384 97V65h-32v32h32zM128 65v32h32V65h-32z',
  'slim-arrow-down':
    'M420.5 187q11-12 23 0 5 5 5 11t-5 11l-165 165q-10 9-23 9t-22-9l-166-165q-5-5-5-11.5t5-11.5 11.5-5 11.5 5l160 160q5 6 11 0z',
  'value-help':
    'M470 41q10 9 10 23v192q0 12-10 22t-22 10H256q-14 0-23-10-9-9-9-22V64q0-14 9-23t23-9h192q13 0 22 9zm-22 23H256v192h192V64zm-96 256h32v128q0 14-9 23t-23 9H64q-12 0-23-9-9-9-9-23V160q0-14 9-23t23-9h128v32H64v288h288V320z',
}

export const ReadModeInput = forwardRef(
  (
    {
      editModeComponent: EditModeComponent,
      readModeValue,
      readModeIcon = '',
      ...editModeComponentProps
    },
    forwardedRef,
  ) => {
    const [isEditing, setIsEditing] = useState(false)
    const hasClickedIcon = useRef(false)
    const childInputRef = useRef(null)
    useImperativeHandle(forwardedRef, () => childInputRef.current)
    const enableEditMode = useCallback((e) => {
      const userClickedOnIcon = e.target instanceof SVGSVGElement
      if (userClickedOnIcon) {
        hasClickedIcon.current = true
      }
      setIsEditing(true)
    }, [])

    const {
      icon: editModeIcon,
      className = '',
      valueState,
      value,
      readonly = false,
      disabled = false,
      placeholder = '',
    } = editModeComponentProps
    const hasIcon = !!(readModeIcon || editModeIcon)
    const showsPlaceholder = !readModeValue && !value && placeholder.length > 0
    const displayValue = (readModeValue ?? value) && `${readModeValue ?? value}`
    const readModeClassName = joinClassNames([
      styles['fake-input'],
      className,
      hasIcon && styles['has-icon'],
    ])

    const icon = useMemo(() => {
      if (readModeIcon && readModeIcon in readModeIcons)
        return (
          <svg
            className={joinClassNames([
              styles['fake-input__icon'],
              styles['fake-input__icon--interactive'],
            ])}
            preserveAspectRatio="xMidYMid meet"
            tabIndex={0}
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g role="presentation">
              <path d={readModeIcons[readModeIcon]} />
            </g>
          </svg>
        )
      if (editModeIcon) return <span className={styles['fake-input__icon']}>{editModeIcon}</span>
      return null
    }, [editModeIcon, readModeIcon])

    useEffect(() => {
      if (isEditing) {
        const triggerEditModeComponentAction = async () => {
          const inputElement = childInputRef.current
          // wait for UI5 input inner component DOM nodes to be available
          await inputElement?._domRefReadyPromise

          if (hasClickedIcon.current) {
            const iconElement =
              inputElement?.querySelector('ui5-icon') ??
              inputElement?.shadowRoot?.querySelector('ui5-icon')
            iconElement?.click()
          } else {
            // fixes a bug in the UI5 InputSuggestions class where on opening an input popover
            // it is not checked whether it has been defined yet, so focusing the input would
            // result in an error. Hence we first wait for the popover to initialize.
            await inputElement?.Suggestions?._getSuggestionPopover?.()
            inputElement?.focus()
          }
        }

        triggerEditModeComponentAction()
      }
    }, [isEditing])

    return isEditing ? (
      <EditModeComponent
        onKeyDown={preventEventPropagation}
        {...editModeComponentProps}
        ref={childInputRef}
      />
    ) : (
      <div
        tabIndex={0}
        onFocus={enableEditMode}
        className={readModeClassName}
        data-value-state={valueState}
        data-readonly={readonly}
        data-disabled={disabled}
        data-shows-placeholder={showsPlaceholder}
      >
        <span className={styles['fake-input__value']}>{displayValue || placeholder}</span>
        {icon}
      </div>
    )
  },
)

ReadModeInput.displayName = 'ReadModeInput'

ReadModeInput.propTypes = {
  // ReadModeInput props:
  editModeComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  readModeValue: PropTypes.string,
  readModeIcon: PropTypes.oneOf(Object.keys(readModeIcons)),
  // EditModeComponent props that are used by ReadModeInput to recreate the original look / behavior:
  className: PropTypes.string,
  valueState: valueStatePropType,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  icon: PropTypes.node,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
}

export const TableSelect = ({ rowKey, contentKey, options, valueObj, handleOnChange }) => {
  const { value, valueState, valueStateMessage } = valueObj
  const readModeValue = useMemo(
    () => (options ?? []).find((option) => option.key === value)?.display_name,
    [options, value],
  )
  return (
    <ReadModeInput
      editModeComponent={Select}
      className={styles['table-select']}
      id={getRentalUnitWorkingVersionFieldId(rowKey, contentKey)}
      valueState={valueState}
      valueStateMessage={<ValueStateMessage name={valueStateMessage} />}
      readModeValue={readModeValue}
      readModeIcon="slim-arrow-down"
      onChange={(event) =>
        handleOnChange(rowKey, contentKey, event.detail.selectedOption.dataset.id)
      }
    >
      <Option key={''} data-id={''} selected={true} />
      {options?.map((option) => (
        <Option
          key={`${rowKey}-${contentKey}-${option.key}`}
          data-id={option.key}
          selected={option.key === value}
        >
          {option.display_name}
        </Option>
      ))}
    </ReadModeInput>
  )
}
TableSelect.propTypes = {
  rowKey: PropTypes.string.isRequired,
  contentKey: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  valueObj: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func.isRequired,
}

export const TableComboBox = ({
  rowKey,
  contentKey,
  options,
  valueObj,
  originalExcelValue,
  handleOnChange,
}) => {
  const { value, valueState, valueStateMessage } = valueObj
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property.rent-roll.table' })
  const originalExcelValueExists = originalExcelValue !== null

  const getOriginalExcelValueToDisplay = () =>
    getDisplayNameOrReturnKey({ key: originalExcelValue, options: options })

  const getValueToDisplay = () => {
    if (originalExcelValueExists && value === originalExcelValue) {
      return getOriginalExcelValueToDisplay()
    }
    return getDisplayNameByKey({ key: value, options: options })
  }

  const getUpdateValue = (event) =>
    getKeyOrReturnDisplayName({ displayName: event.target.value, options: options }) || null

  return (
    <ReadModeInput
      editModeComponent={ComboBox}
      readModeIcon="slim-arrow-down"
      id={getRentalUnitWorkingVersionFieldId(rowKey, contentKey)}
      loading={options?.length}
      valueState={valueState}
      valueStateMessage={<ValueStateMessage name={valueStateMessage} />}
      value={getValueToDisplay()}
      onChange={(event) => handleOnChange(rowKey, contentKey, getUpdateValue(event))}
    >
      {originalExcelValueExists && (
        <>
          <ComboBoxGroupItem
            key={`${rowKey}-${contentKey}-source-value-group`}
            text={t('source-value.label')}
          />
          <ComboBoxItem
            key={`${rowKey}-${contentKey}-source-value-group-item`}
            text={getOriginalExcelValueToDisplay()}
          />
          <ComboBoxGroupItem text={t('options.label')} />
        </>
      )}
      <ComboBoxItem key={`${rowKey}-${contentKey}-empty-option`} text="" />
      {options?.map((option) => (
        <ComboBoxItem key={`${rowKey}-${contentKey}-${option.key}`} text={option.display_name} />
      ))}
    </ReadModeInput>
  )
}

export default TableComboBox

TableComboBox.propTypes = {
  rowKey: PropTypes.string.isRequired,
  contentKey: PropTypes.string.isRequired,
  valueObj: PropTypes.object.isRequired,
  originalExcelValue: PropTypes.string,
  options: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
}

export const TableDatePicker = ({ rowKey, contentKey, valueObj, handleOnChange }) => {
  const { localePattern, format, parse } = useShortDateFormatter()
  const { value, valueState, valueStateMessage } = valueObj
  const valueOnDisplay = value ?? ''
  const handleDatePickerUpdate = (event) => {
    const dateValue = event.detail.valid
      ? parse(event.detail.value, localePattern)
      : event.detail.value

    handleOnChange(rowKey, contentKey, dateValue)
  }

  return (
    <ReadModeInput
      editModeComponent={DatePickerWithoutMinWidth}
      readModeValue={format(valueOnDisplay)}
      readModeIcon="appointment-2"
      id={getRentalUnitWorkingVersionFieldId(rowKey, contentKey)}
      className={styles['date-picker']}
      value={valueOnDisplay}
      valueState={valueState}
      valueStateMessage={<ValueStateMessage name={valueStateMessage} />}
      formatPattern={localePattern}
      placeholder={localePattern}
      onFocus={preventEventPropagation}
      onChange={handleDatePickerUpdate}
    />
  )
}

TableDatePicker.propTypes = {
  rowKey: PropTypes.string.isRequired,
  contentKey: PropTypes.string.isRequired,
  valueObj: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func.isRequired,
}

export const TableCheckBox = ({ rowKey, contentKey, valueObj, handleOnChange }) => {
  const { value, valueState, valueStateMessage } = valueObj
  return (
    <CheckBox
      id={getRentalUnitWorkingVersionFieldId(rowKey, contentKey)}
      checked={value}
      onChange={(event) => {
        handleOnChange(rowKey, contentKey, event.target.checked)
      }}
      valueState={valueState}
      valueStateMessage={<ValueStateMessage name={valueStateMessage} />}
      className={styles['table-checkbox']}
    />
  )
}

TableCheckBox.propTypes = {
  rowKey: PropTypes.string.isRequired,
  contentKey: PropTypes.string.isRequired,
  valueObj: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func.isRequired,
}

export const TableSelectWithFooter = ({
  rowKey,
  contentKey,
  options,
  valueObj,
  handleOnChange,
  footer,
  disabled = false,
}) => {
  const { value, valueState, valueStateMessage } = valueObj
  const readModeValue = useMemo(
    () => (options ?? []).find((option) => option.key === value)?.display_name,
    [options, value],
  )
  return (
    <ReadModeInput
      editModeComponent={SelectWithFooter}
      readModeValue={readModeValue}
      readModeIcon="slim-arrow-down"
      className={styles['table-select']}
      id={getRentalUnitWorkingVersionFieldId(rowKey, contentKey)}
      valueState={valueState}
      valueStateMessage={<ValueStateMessage name={valueStateMessage} />}
      onFocus={preventEventPropagation}
      onChange={(event) =>
        handleOnChange(rowKey, contentKey, event.detail.selectedOption.dataset.id)
      }
      footer={footer}
      disabled={disabled}
    >
      {options.map((option) => (
        <Option
          key={`${rowKey}-${contentKey}-${option.key}`}
          data-id={option.key}
          selected={option.key === value}
        >
          {option.display_name}
        </Option>
      ))}
    </ReadModeInput>
  )
}

TableSelectWithFooter.propTypes = {
  rowKey: PropTypes.string.isRequired,
  contentKey: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  valueObj: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  footer: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
}

export const TableInput = ({
  rowKey,
  contentKey,
  inputType,
  valueObj,
  handleOnChange,
  readonly = false,
  labelAsIcon,
  ...additionalProps
}) => {
  const inputRef = useRef()
  const minimumFractionDigits = contentKey === 'number_of_units' ? 0 : 2
  const maximumFractionDigits = minimumFractionDigits
  const formatNumber = useNumberFormatter({ minimumFractionDigits, maximumFractionDigits })
  const { value, valueState, valueStateMessage } = valueObj

  if (labelAsIcon) {
    additionalProps = {
      icon: <Label style={{ color: 'var(--sapContent_LabelColor)' }}>{labelAsIcon}</Label>,
      ...additionalProps,
    }
  }

  const id = getRentalUnitWorkingVersionFieldId(rowKey, contentKey)

  const commonProps = {
    className:
      inputType === InputType.Number ? styles['table-input-number'] : styles['table-input'],
    id,
    key: id,
    valueState,
    valueStateMessage: <ValueStateMessage name={valueStateMessage} />,
    onFocus: preventEventPropagation,
    readonly,
  }

  const isFormattedNumberInput = inputType === InputType.Number && !contentKey.includes('id')
  const renderValue = inputType === InputType.Number && isNaN(value) ? '' : value
  const input = isFormattedNumberInput ? (
    <ReadModeInput
      editModeComponent={FormattedNumberInput}
      readModeValue={formatNumber(value)}
      {...commonProps}
      value={value}
      onChange={(parsedValue) =>
        handleOnChange(rowKey, contentKey, parsedValue, inputRef.current?.isValid)
      }
      minimumFractionDigits={minimumFractionDigits}
      maximumFractionDigits={maximumFractionDigits}
      {...additionalProps}
      ref={inputRef}
    />
  ) : (
    <ReadModeInput
      editModeComponent={Input}
      {...commonProps}
      value={renderValue}
      onChange={(event) => handleOnChange(rowKey, contentKey, event.target.value)}
      type={inputType}
      maxlength={MAX_LENGTH_RRWV_TEXT_INPUT}
      {...additionalProps}
    />
  )
  return input
}

TableInput.propTypes = {
  rowKey: PropTypes.string.isRequired,
  contentKey: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  valueObj: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func,
  readonly: PropTypes.bool,
  labelAsIcon: PropTypes.string,
}

export const TablePropertyRentalUnitBusinessPartnerInput = (props) => (
  <ReadModeInput
    editModeComponent={PropertyRentalUnitBusinessPartnerInput}
    readModeValue={props.value?.name ?? ''}
    readModeIcon="value-help"
    {...props}
  />
)

TablePropertyRentalUnitBusinessPartnerInput.propTypes =
  PropertyRentalUnitBusinessPartnerInput.propTypes
