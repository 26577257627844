import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getSourcesForTileCode } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import DecisionPaperMoreSourcesLinkWithPopover from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/sources/DecisionPaperMoreSourcesLinkWithPopover'
import DecisionPaperSourceLink from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/sources/DecisionPaperSourceLink'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/sources/DecisionPaperSources.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'

const groupSources = (sources) => {
  if (!sources || sources.length === 0) {
    return [[], []]
  }
  if (sources.length <= 2) {
    return [sources, []]
  }
  return [sources.slice(0, 1), sources.slice(1)]
}

const DecisionPaperSources = ({ tileId, tileCode, isPdfView }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.tiles.header',
  })

  const tileState = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const sources = getSourcesForTileCode(tileCode)

  const [primarySources, moreSources] = useMemo(() => groupSources(sources), [sources])

  const renderedSources = useMemo(
    () =>
      primarySources.map((source) => (
        <span
          className={styles.sourceLinkDot}
          key={`source-tile-${tileId}-${source.nameTranslationKey}-${source.path}`}
        >
          <DecisionPaperSourceLink
            sourceRender={tileState?.data?.sourceRender}
            source={source}
            isPdfView={isPdfView}
          />
        </span>
      )),
    [primarySources, tileId, isPdfView, tileState?.data?.sourceRender],
  )

  const renderSource = useCallback(
    () => (
      <div className={styles.sources}>
        {renderedSources}
        <DecisionPaperMoreSourcesLinkWithPopover
          tileId={tileId}
          sources={moreSources}
          isPdfView={isPdfView}
        />
      </div>
    ),
    [isPdfView, moreSources, renderedSources, tileId],
  )

  return (
    <SmallLoadingWrapper
      isLoading={!tileState || tileState.isLoading || (!tileState.data && !tileState.isError)}
      isError={!!tileState && !!tileState.isError}
      renderContent={renderSource}
      error={<Label>{t('source.error')}</Label>}
    />
  )
}

DecisionPaperSources.propTypes = {
  tileId: PropTypes.string.isRequired,
  tileCode: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default DecisionPaperSources
