import camelize from 'camelize'
import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import { useRequest } from 'hooks/services/baseService'
import { riskMonitoringSchema } from 'hooks/services/risk-monitoring/riskIndicatorSchema'

/**
 * @param {object} params
 * @param {"OPEN"|"MONITORED"|"ACCEPTED"} [params.type]
 * @param {string} [params.businessPartnerId]
 * @param {string} [params.riskIndicatorGroupDisplayId]
 * @param {string} [params.statusTypeCode]
 * @param {string} [params.businessEventId]
 * @param {"statusTypeCode"} [params.sortBy]
 * @param {"asc"|"desc"} [params.sortDirection]
 * @param {object} [params.options]
 */
const useRiskIndicators = ({
  type,
  businessPartnerId,
  riskIndicatorGroupDisplayId,
  statusTypeCode,
  businessEventId,
  sortBy,
  sortDirection,
  options = {},
}) => {
  const params = new URLSearchParams()
  if (type) params.append('groupTypeCode', type)
  if (riskIndicatorGroupDisplayId) params.append('groupDisplayId', riskIndicatorGroupDisplayId)
  if (statusTypeCode) params.append('statusTypeCode', statusTypeCode)
  if (businessEventId) params.append('businessEventId', businessEventId)
  if (sortBy) params.append('sortBy', sortBy)
  if (sortDirection) params.append('orderBy', sortDirection)

  // HINT: `params.size` seems to return `undefined` even if there are params
  const paramsString = [...params.keys()].length ? `?${params.toString()}` : ''

  const result = useRequest({
    path: `/risk-monitoring/business-partners/${businessPartnerId}/risk-indicator-groups${paramsString}`,
    keys: compact([
      'risk-monitoring',
      'business-partners',
      businessPartnerId,
      'risk-indicator-groups',
      type,
      riskIndicatorGroupDisplayId,
    ]),
    translated: true,
    options: {
      ...options,
      enabled: !isNil(businessPartnerId),
    },
  })

  const parsed = riskMonitoringSchema.optional().safeParse(camelize(result.data))

  if (!parsed.success) {
    // eslint-disable-next-line no-console
    console.error('Risk Indicators parsing error', parsed.error)
    return { ...result, data: undefined, isError: true, error: parsed.error }
  }

  return {
    ...result,
    data: parsed.data,
  }
}

export default useRiskIndicators
