import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionsMassEditOperations } from 'api/conditions/conditions'
import BusinessObjectSelectionInput from 'components/domains/conditions/business-object/BusinessObjectSelectionInput'
import CovenantCheckSelectionHelperDialog from 'components/domains/conditions/covenant-check-selection/CovenantCheckSelectionHelperDialog'
import MassEditOperationSelect from 'components/domains/conditions/dialogs/mass-editing/MassEditOperationSelect'

const ConditionsMassEditCovenantCheckSelect = ({ onChange, disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.dialogs.mass-edit',
  })

  const [showInputField, setShowInputField] = useState(false)
  const [selectedCovenantCheck, setSelectedCovenantCheck] = useState(undefined)
  const [isHelperDialogOpen, setIsHelperDialogOpen] = useState(false)

  const handleSelectedOperationChanged = useCallback(
    (selection) => {
      switch (selection) {
        case conditionsMassEditOperations.keep:
          setShowInputField(false)
          onChange({ operation: conditionsMassEditOperations.keep })
          return
        case conditionsMassEditOperations.clear:
          setShowInputField(false)
          onChange({ operation: conditionsMassEditOperations.clear })
          return
        case conditionsMassEditOperations.replace:
          setShowInputField(true)
          onChange({ operation: conditionsMassEditOperations.replace, value: null })
      }
    },
    [onChange],
  )

  const onClickCovenantCheckSelection = useCallback(() => {
    setIsHelperDialogOpen(true)
  }, [])

  const onClearCovenantCheckSelection = useCallback(() => {
    onChange({
      operation: conditionsMassEditOperations.replace,
      value: undefined,
    })
    setSelectedCovenantCheck(undefined)
  }, [onChange])

  const onCancelCovenantCheckSelection = useCallback(() => {
    setIsHelperDialogOpen(false)
  }, [])

  const onSelectionChanged = useCallback(
    ({ covenantCheck }) => {
      setSelectedCovenantCheck(covenantCheck)
      onChange({
        operation: conditionsMassEditOperations.replace,
        value: covenantCheck?.covenantCheckUuid,
      })
      setIsHelperDialogOpen(false)
    },
    [setSelectedCovenantCheck, onChange, setIsHelperDialogOpen],
  )

  const CovenantCheckInputField = useMemo(
    () => (
      <>
        <BusinessObjectSelectionInput
          name={selectedCovenantCheck?.name}
          onClick={onClickCovenantCheckSelection}
          onClear={onClearCovenantCheckSelection}
          disabled={disabled}
          fallbackText={'covenant-fallback'}
        />
        <CovenantCheckSelectionHelperDialog
          isOpen={isHelperDialogOpen}
          onCancel={onCancelCovenantCheckSelection}
          onSelect={onSelectionChanged}
        />
      </>
    ),
    [
      selectedCovenantCheck?.name,
      onClickCovenantCheckSelection,
      onClearCovenantCheckSelection,
      disabled,
      isHelperDialogOpen,
      onCancelCovenantCheckSelection,
      onSelectionChanged,
    ],
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label>{t('labels.covenant-check')}</Label>
      <MassEditOperationSelect
        idPrefix="conditions-covenant-check"
        selectableOperations={[
          conditionsMassEditOperations.keep,
          conditionsMassEditOperations.replace,
          conditionsMassEditOperations.clear,
        ]}
        onChange={handleSelectedOperationChanged}
        disabled={disabled}
      />
      {showInputField && CovenantCheckInputField}
    </FlexBox>
  )
}

ConditionsMassEditCovenantCheckSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default ConditionsMassEditCovenantCheckSelect
