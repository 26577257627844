import useArrearComments from 'hooks/services/arrears/useArrearComments'

const useArrearsReportingSummary = ({ entityRef: { entityId: businessPartnerId } } = {}) => {
  const {
    data: { availableVersions = [] } = {},
    isFetching: isLoading,
    isError,
  } = useArrearComments({ businessPartnerId })

  const latestVersion = availableVersions?.[0] ?? {}

  return {
    isLoading,
    isError,
    data:
      !isLoading && !isError
        ? {
            latestVersion,
            sourceRender: { businessPartnerId },
          }
        : undefined,
  }
}

export default useArrearsReportingSummary
