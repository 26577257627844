import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useMarketArea = (marketId, options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/markets/${marketId}/area`,
      useCache: true,
      keys: ['market-area', marketId],
      options: options,
    }),
  )

export default useMarketArea
