import { ObjectStatus, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHref, useParams } from 'react-router-dom'
import {
  decisionStageVoterStatus,
  getObjectStatusForDecisionStageStatus,
  getObjectStatusForDecisionStageVoteStatus,
  decisionStageStatus as possibleDecisionStageStatus,
  decisionStageVoterStatus as possibleDecisionStageVoterStatus,
} from 'api/decision-process/decisionProcessApi'
import {
  decisionProcessMinutesDecisionStatus,
  getObjectStatusforDecisionStageMinutesStatus,
} from 'api/decision-process/decisionProcessMinutesApi'
import BusinessObjectLink from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import DecisionStageLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageLeaveConfirmationLink'
import DisplayCardView from 'components/ui/card/DisplayCardView'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import eventsAndTasksPaths from 'routes/business-events-and-tasks/eventsAndTasksPaths'
import paths from 'routes/paths'
const noop = () => {}

const hyphen = '-'

const DecisionStageMinutesApprovalInformationCard = ({
  decisionPaperVersion,
  decisionStageName,
  decisionStageStatus,
  decisionStageDecisionDate,
  decisionStageMinutesStatus,
  voterStatus,
}) => {
  const {
    event: {
      id: eventId,
      info: { name: eventName },
      entityRef: { entityId, entityType },
    },
  } = useContext(BusinessEventsAndTasksContext)
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.approval-card',
  })
  const { format: formatDate } = useShortDateFormatter()
  const { decisionStageId } = useParams()
  const eventHref = useHref(`/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}`)
  const decisionPaperHref = useHref(
    `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}/${eventsAndTasksPaths.eventDetail.decisionPaper}?version=${decisionPaperVersion}`,
  )
  const decisionStageHref = useHref(
    `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}/decision-stages/${decisionStageId}`,
  )
  const {
    translationKey: decisionStageStatusTranslationsKey,
    objectStatus: decisionStageStatusObjectStatus,
  } = getObjectStatusForDecisionStageStatus(decisionStageStatus)
  const {
    translationKey: decisionStageMinutesStatusTranslationsKey,
    objectStatus: decisionStageMinutesStatusObjectStatus,
  } = getObjectStatusforDecisionStageMinutesStatus(decisionStageMinutesStatus)
  const { translationKey: voterStatusTranslationKey, objectStatus: voterStatusObjectStatus } =
    getObjectStatusForDecisionStageVoteStatus(voterStatus)

  const fieldDefinitions = useMemo(() => {
    const fields = [
      {
        value: <Text>{tNoPrefix(`events.entity-type.${entityType}`)}</Text>,
        label: t('label.business-object-type'),
      },
      {
        value: (
          <BusinessObjectLink
            entityId={entityId}
            entityType={entityType}
            CustomComponent={DecisionStageLeaveConfirmationLink}
          />
        ),
        label: t('label.business-object'),
      },
      {
        value: <DecisionStageLeaveConfirmationLink name={eventName} link={eventHref} />,
        label: t('label.event-name'),
      },
      {
        value: decisionPaperVersion ? (
          <DecisionStageLeaveConfirmationLink
            name={t('decision-paper-version', { decisionPaperVersion })}
            link={decisionPaperHref}
          />
        ) : (
          <Text>{hyphen}</Text>
        ),
        label: t('label.linked-decision-paper'),
      },
      {
        value: (
          <DecisionStageLeaveConfirmationLink name={decisionStageName} link={decisionStageHref} />
        ),
        label: t('label.decision-stage'),
      },
      {
        value: (
          <Text wrapping>
            {decisionStageDecisionDate ? formatDate(decisionStageDecisionDate) : hyphen}
          </Text>
        ),
        label: t('label.stage-decision-date'),
      },
      {
        value: (
          <ObjectStatus inverted state={decisionStageStatusObjectStatus}>
            {tNoPrefix(decisionStageStatusTranslationsKey)}
          </ObjectStatus>
        ),
        label: t('label.decision-status'),
      },
      {
        value: (
          <ObjectStatus inverted state={decisionStageMinutesStatusObjectStatus}>
            {tNoPrefix(decisionStageMinutesStatusTranslationsKey)}
          </ObjectStatus>
        ),
        label: t('label.minutes-decision-status'),
      },
      ...(voterStatus === decisionStageVoterStatus.ignored
        ? []
        : [
            {
              value: (
                <ObjectStatus inverted state={voterStatusObjectStatus}>
                  {tNoPrefix(voterStatusTranslationKey)}
                </ObjectStatus>
              ),
              label: t('label.minutes-individual-vote-status'),
            },
          ]),
    ]

    return fields
  }, [
    entityId,
    entityType,
    t,
    eventName,
    eventHref,
    decisionPaperVersion,
    decisionPaperHref,
    decisionStageName,
    decisionStageHref,
    decisionStageStatusObjectStatus,
    tNoPrefix,
    decisionStageStatusTranslationsKey,
    voterStatus,
    voterStatusObjectStatus,
    voterStatusTranslationKey,
    decisionStageDecisionDate,
    formatDate,
    decisionStageMinutesStatusObjectStatus,
    decisionStageMinutesStatusTranslationsKey,
  ])
  return (
    <DisplayCardView
      cardHeaderTitle={t('title')}
      isEditable={false}
      fieldDefinitions={fieldDefinitions}
      setEditMode={noop}
    />
  )
}

DecisionStageMinutesApprovalInformationCard.displayName =
  'DecisionStageMinutesApprovalInformationCard'

DecisionStageMinutesApprovalInformationCard.propTypes = {
  decisionPaperVersion: PropTypes.string,
  decisionStageName: PropTypes.string.isRequired,
  decisionStageStatus: PropTypes.oneOf(Object.values(possibleDecisionStageStatus)).isRequired,
  decisionStageMinutesStatus: PropTypes.oneOf(Object.values(decisionProcessMinutesDecisionStatus))
    .isRequired,
  voterStatus: PropTypes.oneOf(Object.values(possibleDecisionStageVoterStatus)).isRequired,
  decisionStageDecisionDate: PropTypes.string,
}

export default DecisionStageMinutesApprovalInformationCard
