import {
  DynamicPage,
  DynamicPageHeader,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import MultiPropertyUpload from 'components/domains/properties/multi-property-upload/MultiPropertyUpload'
import PropertiesFilterBar from 'components/domains/properties/properties-search/filterbar/PropertiesFilterBar'
import PropertiesTable from 'components/domains/properties/properties-search/table/PropertiesTable'
import PropertyCreate from 'components/domains/properties/property-create/PropertyCreate'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInTable } from 'components/ui/errors/ErrorDataUnavailableInTable'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import Header from 'components/ui/page/Header'
import { useProperties } from 'hooks/services/properties/useProperties'
import usePropertiesColumnDefinitions from 'hooks/services/properties/usePropertiesColumnDefinitions'
import { usePropertiesPermissions } from 'hooks/services/properties/usePropertiesPermissions'
import '@ui5/webcomponents/dist/Assets.js'
import { useTypecodes } from 'hooks/services/properties/useTypecodes'
import { useInfiniteLoading } from 'hooks/services/useInfiniteLoading'
import styles from 'routes/properties/PropertySearchPage.module.css'

const getOrderSymbol = (orderBy) => (orderBy === 'desc' ? '-' : '+')
const getSortString = (sortBy, orderBy) =>
  sortBy && orderBy ? `${getOrderSymbol(orderBy)}${sortBy}` : ''

const initialSorting = {
  sortBy: 'creation_date_time',
  orderBy: 'desc',
}

const PropertySearchPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.properties' })
  const { t: tNoPrefix } = useTranslation()
  const [query] = useSearchParams({
    sort: getSortString(initialSorting.sortBy, initialSorting.orderBy),
  })

  const {
    isLoading: isLoadingAllowedOperations,
    isError: isErrorAllowedOperations,
    data: propertyPermissions,
  } = usePropertiesPermissions()
  const allowedOperations = propertyPermissions?.allowed_operations ?? []

  const isAllowedPropertyListRead = allowedOperations.includes('PropertyList_Read')
  const isAllowedPropertyCreate = allowedOperations.includes('Property_Create')

  const propertyName = query.get('property_name')
  const propertyId = query.get('property_id')
  const country = query.get('country')
  const propertyTypes = query.get('property_types')
  const constructionYear = query.get('construction_year')
  const zipCode = query.get('zip_code')
  const street = query.get('street')
  const city = query.get('city')
  const dealId = query.get('deal_id')
  const marketId = query.get('market_id')
  const otherExternalId = query.get('other_external_id')
  const sort = query.get('sort')
  const financingStatusCode = query.get('financing_status_code')
  const cagStatusCode = query.get('cag_status_code')

  const paginationDelta = 50
  const {
    isLoading: isLoadingProperties,
    isError: isErrorProperties,
    data: propertiesData,
    loadMore: loadMoreProperties,
  } = useInfiniteLoading({
    requestHook: useProperties,
    requestHookArgs: {
      filter: {
        propertyName,
        propertyId,
        country,
        propertyTypes,
        constructionYear,
        zipCode,
        street,
        city,
        dealId,
        marketId,
        otherExternalId,
        financingStatusCode,
        cagStatusCode,
      },
      sort,
    },
    dataListElement: 'properties',
    paginationDelta: paginationDelta,
  })

  const properties = !isLoadingProperties && !isErrorProperties ? propertiesData.properties : []
  const propertiesPagination =
    !isLoadingProperties && !isErrorProperties && propertiesData.pagination
      ? propertiesData.pagination
      : {
          limit: 0,
          total: 0,
        }

  const [tableColumns, setTableColumns] = usePropertiesColumnDefinitions()
  const [selectedProperties, setSelectedProperties] = useState([])

  const {
    isLoading: isLoadingTypecodes,
    isError: isErrorTypecodes,
    data: typecodesData,
  } = useTypecodes()
  const typecodes = !isLoadingTypecodes && !isErrorTypecodes ? typecodesData.typecodes : []

  const pageTitle = isAllowedPropertyCreate ? (
    <Header
      title={t('property-search.title')}
      actions={
        <>
          <PropertyCreate />
          <MultiPropertyUpload disable={!isAllowedPropertyCreate} />
        </>
      }
      breadcrumbs={[{ text: t('title') }]}
    />
  ) : (
    <Header title={t('title')} breadcrumbs={[{ text: t('title') }]} />
  )

  const noPermissionMessage = (
    <CenteredIllustratedMessage
      name={IllustrationMessageType.UnableToLoad}
      size={IllustrationMessageSize.Spot}
      titleText={tNoPrefix('app.permission-error.title')}
      subtitleText={tNoPrefix('app.permission-error.subtitle')}
    />
  )
  const propertiesTableCardWithHeader = (
    <Card>
      <div>
        <DynamicPageHeader className={styles['dynamic-page-header']}>
          <PropertiesFilterBar
            typecodes={typecodes}
            setSelectedProperties={setSelectedProperties}
          />
        </DynamicPageHeader>
      </div>
      <div className={styles['table-wrapper']}>
        <RequestStateResolver
          isLoading={isLoadingAllowedOperations || isLoadingProperties}
          isError={isErrorAllowedOperations}
          errorToDisplay={<ErrorDataUnavailableInTable />}
          center
          renderContent={() => (
            <PropertiesTable
              properties={properties}
              tableColumns={tableColumns}
              setTableColumns={setTableColumns}
              pagination={propertiesPagination}
              setSelectedProperties={setSelectedProperties}
              selectedProperties={selectedProperties}
              onLoadMore={loadMoreProperties}
              allowedOperations={allowedOperations}
              isError={isErrorProperties}
            />
          )}
        />
      </div>
    </Card>
  )

  return (
    <DynamicPage
      id="property-search-dynamic-page"
      className={styles['dynamic-page']}
      showHideHeaderButton={false}
      headerContentPinnable={false}
      headerTitle={pageTitle}
    >
      {(isAllowedPropertyListRead && propertiesTableCardWithHeader) || noPermissionMessage}
    </DynamicPage>
  )
}

export default PropertySearchPage
