import {
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Icon,
  Link,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import DocumentDownload from 'components/domains/documents/DocumentDownload'
import { getVersionFileName } from 'routes/documents/document-versions/DocumentVersionsTable'

/**
 * @param {object} props
 * @param {boolean} props.isEditing
 * @param {(object | undefined)} props.document
 * @param {string} props.document.id
 * @param {string} props.document.name
 * @param {string} props.reason
 * @param {string} props.valuationType
 */
const ValuationRequestTableReportDisplay = ({ isEditing, document, onUnlinkReport }) => {
  const url = `/documents/${document?.id}`
  const versionGuid = document?.versions?.find((version) => version.isWorkVersion).guid
  const activeVersion = document?.versions?.find((version) => version.isWorkVersion)
  const activeFileName =
    activeVersion && getVersionFileName(document?.name, activeVersion.versionExt)

  return (
    <>
      {document ? (
        <FlexBox
          justifyContent={FlexBoxJustifyContent.SpaceBetween}
          alignItems={FlexBoxAlignItems.Center}
        >
          <Link
            onClick={(e) => {
              e.preventDefault()
              window.open(url)
            }}
            href={url}
          >
            {document.name}
          </Link>
          {isEditing ? (
            <Icon name="decline" interactive onClick={onUnlinkReport} />
          ) : (
            <DocumentDownload
              type="button"
              documentId={document.id}
              documentVersionGuid={versionGuid}
              fileName={activeFileName}
              design={ButtonDesign.Transparent}
            />
          )}
        </FlexBox>
      ) : (
        <Text>{'-'}</Text>
      )}
    </>
  )
}

ValuationRequestTableReportDisplay.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  document: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  onUnlinkReport: PropTypes.func,
}

export default ValuationRequestTableReportDisplay
