import { ComboBox, ComboBoxItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import useIndustries from 'hooks/services/business-partners/config/useIndustries'

const IndustryInput = forwardRef(({ industry, setIndustry, ...props }, _ref) => {
  const { data: { industrySectors = {} } = {}, isLoading } = useIndustries()

  return (
    <div
      id="information-tile-industry-input"
      data-testid="industry-wrapper"
      title={industry?.sectorId ? `${industry.sectorId} - ${industry.keyDescription}` : ''}
    >
      <ComboBox
        {...props}
        loading={isLoading}
        onFocus={(event) => event.stopPropagation()}
        onChange={(event) => {
          const industryText = event.target.value
          const textItems = industryText.split(' - ')
          setIndustry({ sectorId: textItems[0], keyDescription: textItems[1] })
        }}
        value={
          industry?.sectorId
            ? `${industry.sectorId} - ${industrySectors[industry.sectorId] ?? ''}`
            : ''
        }
      >
        {Object.entries(industrySectors)
          // HINT: sort industries by display name
          .sort((a, b) => String(a[1]).localeCompare(String(b[1])))
          .map(([id, name]) => (
            <ComboBoxItem key={id} text={`${id} - ${name}`} />
          ))}
      </ComboBox>
    </div>
  )
})

IndustryInput.displayName = 'IndustryInput'

IndustryInput.propTypes = {
  industry: PropTypes.shape({
    sectorId: PropTypes.string,
    keyDescription: PropTypes.string,
  }),
  setIndustry: PropTypes.func.isRequired,
}

export default IndustryInput
