import { CardHeader } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DealCashflowAnalyticalTable } from 'components/domains/deals/deal-cashflow/analytical-table/DealCashflowAnalyticalTable'
import styles from 'components/domains/deals/deal-cashflow/results/CashflowResultTable.module.css'
import useCalculateTableData from 'components/domains/deals/deal-cashflow/results/useCalculateTableData'
import Card from 'components/ui/card/Card'

const CashflowResultTable = ({
  calculatedMeasures,
  title,
  lastEditedText,
  selectedPeriod,
  displayDayOne,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.cashflow.details.results',
  })

  const { structuredData, calculatedColumns } = useCalculateTableData({
    calculatedMeasures,
    selectedPeriod,
    displayDayOne,
  })

  return (
    <Card
      header={<CardHeader titleText={title} subtitleText={lastEditedText} />}
      className={styles.marginBottom}
    >
      <DealCashflowAnalyticalTable
        className={styles.wrapper}
        columns={calculatedColumns}
        title={t('items')}
        data={structuredData}
        nrOfEntries={calculatedMeasures.length}
        headerRowHeight={48}
      />
    </Card>
  )
}

CashflowResultTable.propTypes = {
  title: PropTypes.string.isRequired,
  calculatedMeasures: PropTypes.array.isRequired,
  lastEditedText: PropTypes.string,
  selectedPeriod: PropTypes.string.isRequired,
  displayDayOne: PropTypes.bool.isRequired,
}

export default CashflowResultTable
