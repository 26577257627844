import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/leveraged-transaction/debt-per-ebitda-comment/DebtPerEBITDACommentSection.module.css'

const DebtPerEBITDACommentSection = ({ heading, children }) => (
  <div className={styles.commentSection}>
    <div className={styles.heading}>{heading}</div>
    {children}
  </div>
)

DebtPerEBITDACommentSection.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default DebtPerEBITDACommentSection
