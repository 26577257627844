import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

/**
 * @typedef {'SUM'} Aggregate
 * @typedef {'CITY' | 'COUNTRY' | 'PROPERTY_TYPE'} Granularity
 */

/**
 *
 * @param {Array<string>} propertyIds - UUIDs of properties to get valuations for
 * @param {Array<Aggregate>} [includeAggregates=[]] - Included aggregates
 * @param {string} [preferredCurrency] - Preferred currency
 * @param {object} [options] - Options for useRequest
 * @param {Array<Granularity>} [includeGranularities=[]] - Included aggregates
 * @returns {{isLoading: boolean, isFetching: boolean, isError: boolean, data: *}}
 */
const useMultiPropertyValuations = (
  propertyIds = [],
  includeAggregates = [],
  preferredCurrency,
  options = {},
  includeGranularities = [],
) => {
  const { post } = useAccessTokenRequest()
  const { data, isLoading, isFetching, isError, error } = useQuery({
    queryKey: [
      'properties',
      'multi-valuations',
      propertyIds.sort((a, b) => a.localeCompare(b)),
      includeAggregates,
      preferredCurrency,
      includeGranularities,
    ],
    refetchOnWindowFocus: false,
    queryFn: () =>
      post({
        path: `/properties/valuations`,
        body: {
          property_uuids: propertyIds.filter(Boolean),
          include_aggregates: includeAggregates,
          preferred_currency: preferredCurrency,
          include_granularities: includeGranularities,
        },
      }),
    ...options,
  })

  return useMemo(
    () => ({
      isLoading,
      isFetching,
      isError,
      error,
      data: data?.data,
    }),
    [isLoading, isFetching, isError, error, data?.data],
  )
}

export default useMultiPropertyValuations
