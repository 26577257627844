import { ValueState } from '@fioneer/ui5-webcomponents-react'

export const valuationStatus = {
  draft: 'DRAFT',
  created: 'CREATED',
  instructed: 'INSTRUCTED',
  completed: 'COMPLETED',
  cancelled: 'CANCELLED',
  externalTransferFailed: 'EXTERNAL_TRANSFER_FAILED',
}

export const valuationStatusTranslated = {
  draft: 'valuation-requests.status.draft',
  created: 'valuation-requests.status.created',
  instructed: 'valuation-requests.status.instructed',
  completed: 'valuation-requests.status.completed',
  cancelled: 'valuation-requests.status.cancelled',
  externalTransferFailed: 'valuation-requests.status.external_transfer_failed',
}

export const valuationClassification = Object.freeze({
  marketValue: 'MARKET_VALUE',
  mortgageLendingValue: 'MORTGAGE_LENDING_VALUE',
  reinstatementCost: 'REINSTATEMENT_COST',
  purchasePrice: 'PURCHASE_PRICE',
  other: 'OTHER',
  preliminaryMarketValue: 'PRELIMINARY_MARKET_VALUE',
  sellOffMarketValue: 'SELL_OFF_MARKET_VALUE',
  currentStateMarketValue: 'CURRENT_STATE_MARKET_VALUE',
  preliminaryMortgageLendingValue: 'PRELIMINARY_MORTGAGE_LENDING_VALUE',
  sellOffMortgageLendingValue: 'SELL_OFF_MORTGAGE_LENDING_VALUE',
  currentStateMortgageLendingValue: 'CURRENT_STATE_MORTGAGE_LENDING_VALUE',
})

export const valuationClassificationTranslated = Object.freeze({
  MARKET_VALUE: 'valuation-requests.classification.market-value',
  MORTGAGE_LENDING_VALUE: 'valuation-requests.classification.mortgage-lending-value',
  REINSTATEMENT_COST: 'valuation-requests.classification.reinstatement-cost',
  PURCHASE_PRICE: 'valuation-requests.classification.purchase-price',
  OTHER: 'valuation-requests.classification.other',
  PRELIMINARY_MARKET_VALUE: 'valuation-requests.classification.preliminary-market-value',
  SELL_OFF_MARKET_VALUE: 'valuation-requests.classification.sell-off-market-value',
  CURRENT_STATE_MARKET_VALUE: 'valuation-requests.classification.current-state-market-value',
  PRELIMINARY_MORTGAGE_LENDING_VALUE:
    'valuation-requests.classification.preliminary-mortgage-lending-value',
  SELL_OFF_MORTGAGE_LENDING_VALUE:
    'valuation-requests.classification.sell-off-mortgage-lending-value',
  CURRENT_STATE_MORTGAGE_LENDING_VALUE:
    'valuation-requests.classification.current-state-mortgage-lending-value',
})

export const valuationClassificationAbbreviationsTranslated = Object.freeze({
  MARKET_VALUE: 'valuation-requests.classification.abbreviation.market-value',
  MORTGAGE_LENDING_VALUE: 'valuation-requests.classification.abbreviation.mortgage-lending-value',
  PRELIMINARY_MARKET_VALUE:
    'valuation-requests.classification.abbreviation.preliminary-market-value',
  SELL_OFF_MARKET_VALUE: 'valuation-requests.classification.abbreviation.sell-off-market-value',
  CURRENT_STATE_MARKET_VALUE:
    'valuation-requests.classification.abbreviation.current-state-market-value',
  PRELIMINARY_MORTGAGE_LENDING_VALUE:
    'valuation-requests.classification.abbreviation.preliminary-mortgage-lending-value',
  SELL_OFF_MORTGAGE_LENDING_VALUE:
    'valuation-requests.classification.abbreviation.sell-off-mortgage-lending-value',
  CURRENT_STATE_MORTGAGE_LENDING_VALUE:
    'valuation-requests.classification.abbreviation.current-state-mortgage-lending-value',
})

export const valuationRelevance = Object.freeze({
  relevant: 'RELEVANT',
  nonRelevant: 'NON_RELEVANT',
})

export const objectStatusForValuationStatus = {
  [valuationStatus.draft]: ValueState.None,
  [valuationStatus.created]: ValueState.None,
  [valuationStatus.instructed]: ValueState.Information,
  [valuationStatus.completed]: ValueState.Success,
  [valuationStatus.cancelled]: ValueState.Error,
  [valuationStatus.externalTransferFailed]: ValueState.Error,
  default: ValueState.None,
}

export const valuationRequestMultiEditOperations = {
  clear: 'clear-values',
  keep: 'keep-values',
  replace: 'replace-values',
}

export const valuationRequestCreationError = {
  eventCreationFailedError: 'EVENT_CREATION_FAILED_ERROR',
}

const defaultObjectErrorForRequestCreation = {
  translationKey: 'unknown',
}
const objectErrorForRequestCreation = {
  [valuationRequestCreationError.eventCreationFailedError]: {
    translationKey: 'event-creation-failed',
  },
}

export const getObjectErrorForRequestCreation = (errorCode) =>
  objectErrorForRequestCreation[errorCode] ?? defaultObjectErrorForRequestCreation
