import PropTypes from 'prop-types'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useEvent from 'hooks/services/business-events-and-tasks/events/useEvent'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const EventProvider = ({ eventId, children }) => {
  const { isLoading, isError, data: event } = useEvent(eventId)

  const renderContent = () => (
    <BusinessEventsAndTasksContext.Provider
      value={{ event: event, allowedOperations: event.AllowedOperations }}
    >
      {children}
    </BusinessEventsAndTasksContext.Provider>
  )

  return (
    <LoadingStateWrapper isLoading={isLoading} isError={isError} renderContent={renderContent} />
  )
}

EventProvider.propTypes = {
  eventId: PropTypes.string.isRequired,
  children: PropTypes.any,
}

export default EventProvider
