import { Text, Button, ButtonDesign, IconDesign } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useMemo, useContext, useCallback, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { isEventClosed } from 'api/events/status'
import DocumentIcon from 'components/domains/documents/DocumentIcon'
import DocumentFileDrop from 'components/ui/document/DocumentFileDrop'
import useCanFileDrop from 'components/ui/document/useCanFileDrop'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import useCreateDecisionPaperAttachment from 'hooks/services/business-events-and-tasks/decision-papers/useCreateDecisionPaperAttachment'
import useDecisionPaperAttachments from 'hooks/services/business-events-and-tasks/decision-papers/useDecisionPaperAttachments'
import useDeleteDecisionPaperAttachment from 'hooks/services/business-events-and-tasks/decision-papers/useDeleteDecisionPaperAttachment'
import useMediaBlobDownload from 'hooks/services/media/useMediaBlobDownload'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import AttachmentsDownloadAllButton from 'routes/business-events-and-tasks/decision-paper/attachments/AttachmentsDownloadAllButton'
import AttachmentsUploadButton from 'routes/business-events-and-tasks/decision-paper/attachments/AttachmentsUploadButton'
import styles from 'routes/business-events-and-tasks/decision-paper/attachments/DecisionPaperAttachmentsTable.module.css'

const getFileFormat = (filename) =>
  filename.includes('.') ? filename.split('.').slice(-1, Infinity).join('.') : filename

const DecisionPaperAttachmentsTable = ({
  isEditView = false,
  setIsErrorDialogOpen,
  setEventError,
  highlightRow,
  decisionPaperVersion,
  setHighlightRow,
  resetRowHighlights,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-paper-attachments-table',
  })

  const { event } = useContext(BusinessEventsAndTasksContext)
  const queryClient = useQueryClient()

  const createDecisionPaperAttachmentMutation = useCreateDecisionPaperAttachment()

  const {
    isLoading,
    isError,
    data: attachmentsData,
  } = useDecisionPaperAttachments({ eventId: event.id, versionNumber: decisionPaperVersion })

  const { mutate: deleteAttachment } = useDeleteDecisionPaperAttachment()
  const { download } = useMediaBlobDownload()

  const handleError = useCallback(() => {
    setIsErrorDialogOpen(true)
  }, [setIsErrorDialogOpen])

  const handleDownloadClicked = useCallback(
    (mediaBlobId, name) => {
      resetRowHighlights()
      download({ mediaBlobId, fileName: name }, { onError: handleError })
    },
    [download, handleError, resetRowHighlights],
  )

  const handleDeleteSuccess = useCallback(() => {
    queryClient.invalidateQueries([
      'events',
      event.id,
      'decision-paper',
      'versions',
      'current',
      'attachments',
    ])
  }, [event.id, queryClient])

  const handleDeleteClicked = useCallback(
    (id) => {
      resetRowHighlights()
      deleteAttachment(
        { eventId: event.id, attachmentId: id },
        { onSuccess: handleDeleteSuccess, onError: handleError },
      )
    },
    [deleteAttachment, event.id, handleError, handleDeleteSuccess, resetRowHighlights],
  )

  const decisionPaperAttachmentsColumns = [
    {
      title: t('name'),
      columnKey: 'name',
      style: { width: '70%' },
    },
    {
      title: t('format'),
      columnKey: 'format',
      style: { width: '10%' },
    },
    {
      columnKey: 'download',
      style: { width: '10%' },
    },
  ]
  if (isEditView) {
    decisionPaperAttachmentsColumns.push({
      columnKey: 'delete',
      style: { width: '10%' },
    })
  }

  const prepareFilesBody = (newFilesObject) => {
    const fileArray = []
    // looping over newFiles like this because the object looks like this: newFilesObject: {0: File, 1: File, length: 2}
    for (let i = 0; i < newFilesObject.length; i++) {
      fileArray.push(newFilesObject[i])
    }
    return fileArray
  }

  const handleOnDrop = (newFiles) => {
    const filesBody = prepareFilesBody(newFiles)
    resetRowHighlights()

    createDecisionPaperAttachmentMutation.mutate(
      {
        eventId: event.id,
        files: filesBody,
      },
      {
        onSuccess: ({ attachments }) => {
          const attachmentIds = attachments.map(({ id }) => id)
          setHighlightRow(attachmentIds)
          queryClient.invalidateQueries([
            'events',
            event.id,
            'decision-paper',
            'versions',
            'current',
            'attachments',
          ])
        },
        onError: async ({ response }) => {
          const responseJson = await response.json()
          setEventError(responseJson.code)
          setIsErrorDialogOpen(true)
        },
      },
    )
  }

  const mappedTableData = useMemo(
    () =>
      attachmentsData?.attachments.map(({ id, name, mediaBlobId }) => ({
        rowKey: id,
        rowProperties: highlightRow.includes(id) ? { className: styles.hightlightNewRow } : {},
        download: {
          cellComponent: (
            <Button
              icon={'download'}
              design={ButtonDesign.Transparent}
              onClick={() => handleDownloadClicked(mediaBlobId, name)}
            />
          ),
        },
        delete: {
          cellComponent: (
            <Button
              icon={'delete'}
              design={ButtonDesign.Transparent}
              onClick={() => handleDeleteClicked(id)}
            />
          ),
        },
        name: {
          cellComponent: <Text>{name}</Text>,
        },
        format: {
          cellComponent: (
            <DocumentIcon
              format={getFileFormat(name)}
              design={IconDesign.Neutral}
              style={{ marginLeft: '12px' }}
            />
          ),
        },
      })),
    [attachmentsData?.attachments, handleDeleteClicked, handleDownloadClicked, highlightRow],
  )

  const attachmentBlobIdsAndNames = useMemo(
    () =>
      attachmentsData?.attachments.map(({ mediaBlobId, name }) => ({
        mediaBlobId,
        fileName: name,
      })),
    [attachmentsData],
  )

  const renderButtons = useCallback(() => {
    const tableButtons = []
    if (!isMobile) {
      tableButtons.push(
        <AttachmentsDownloadAllButton
          key={'download-all'}
          attachmentBlobIdsAndNames={attachmentBlobIdsAndNames}
          setIsErrorDialogOpen={setIsErrorDialogOpen}
          resetRowHighlights={resetRowHighlights}
        />,
      )
    }
    if (isEditView && !isEventClosed(event.status)) {
      tableButtons.push(
        <AttachmentsUploadButton
          key={'upload'}
          eventId={event.id}
          setIsErrorDialogOpen={setIsErrorDialogOpen}
          setEventError={setEventError}
          setHighlightRow={setHighlightRow}
          resetRowHighlights={resetRowHighlights}
        />,
      )
    }
    return tableButtons
  }, [
    attachmentBlobIdsAndNames,
    setIsErrorDialogOpen,
    resetRowHighlights,
    isEditView,
    event.id,
    event.status,
    setEventError,
    setHighlightRow,
  ])

  const { isOver, canDrop, dropRef } = useCanFileDrop()

  const toastRef = useRef(null)

  const renderContent = () => (
    <div ref={dropRef} className={styles.content}>
      <SortedTable
        tableData={mappedTableData}
        columnDefinitions={decisionPaperAttachmentsColumns}
        noDataText={isEditView ? t('no-data') : t('no-data-view-mode')}
        toolbarConfig={{
          title: t('title'),
          showColumnSelection: false,
          additionalActions: renderButtons(),
        }}
      />
      {isEditView && (
        <DocumentFileDrop onDrop={handleOnDrop} isOver={isOver} canDrop={canDrop} ref={toastRef} />
      )}
    </div>
  )

  return (
    <LoadingStateWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderContent}
      errorDescription={t('loading.error.description')}
      errorTitle={t('loading.error.title')}
      errorDetails={''}
    />
  )
}

DecisionPaperAttachmentsTable.propTypes = {
  isEditView: PropTypes.bool,
  setIsErrorDialogOpen: PropTypes.func.isRequired,
  setEventError: PropTypes.func.isRequired,
  decisionPaperVersion: PropTypes.string,
  highlightRow: PropTypes.arrayOf(PropTypes.string).isRequired,
  setHighlightRow: PropTypes.func.isRequired,
  resetRowHighlights: PropTypes.func.isRequired,
}

export default DecisionPaperAttachmentsTable
