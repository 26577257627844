import { MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import { Answer } from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/questionnaireStructure'

const Q1_1_KEY_PATH = ['Q1', 'Q1-1']
const Q2_1_KEY_PATH = ['Q2', 'Q2-1']
const Q3_1_KEY_PATH = ['Q3', 'Q3-1']
const Q4_1_KEY_PATH = ['Q4', 'Q4-1']
const Q5_1_KEY_PATH = ['Q5', 'Q5-1']
const Q5_2_KEY_PATH = ['Q5', 'Q5-2']
const Q6_1_1_KEY_PATH = ['Q6', 'Q6-1', 'Q6-1-1']
const Q6_1_2_KEY_PATH = ['Q6', 'Q6-1', 'Q6-1-2']
const Q6_2_1_KEY_PATH = ['Q6', 'Q6-2', 'Q6-2-1']
const Q6_3_1_KEY_PATH = ['Q6', 'Q6-3', 'Q6-3-1']
const Q7_1_KEY_PATH = ['Q7', 'Q7-1']
const Q7_2_KEY_PATH = ['Q7', 'Q7-2']

const PASS_KEY = 'PASS'
const PASS_KEY_PATH = [PASS_KEY]
const FAIL_TRADSEC_KEY = 'FAIL-TRADSEC'
const FAIL_TRADSEC_KEY_PATH = [FAIL_TRADSEC_KEY]
const FAIL_SYNSEC_KEY = 'FAIL-SYNSEC'
const FAIL_SYNSEC_KEY_PATH = [FAIL_SYNSEC_KEY]
const FAIL_REVSEC_KEY = 'FAIL-REVSEC'
const FAIL_REVSEC_KEY_PATH = [FAIL_REVSEC_KEY]

const traditionalSecuritizationCondition = [{ keys: [Q6_1_2_KEY_PATH], result: Answer.YES }]
const syntheticSecuritizationCondition = [{ keys: [Q6_2_1_KEY_PATH], result: Answer.YES }]
const revolvingSecuritizationCondition = [{ keys: [Q6_3_1_KEY_PATH], result: Answer.YES }]

export const questionnaireStructure = [
  {
    key: 'Q1',
    children: [
      {
        key: 'Q1-1',
        alwaysVisible: true,
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q1_1_KEY_PATH], result: Answer.YES }] },
          { key: Q2_1_KEY_PATH },
        ],
      },
    ],
  },
  {
    key: 'Q2',
    children: [
      {
        key: 'Q2-1',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q2_1_KEY_PATH], result: Answer.YES }] },
          { key: Q3_1_KEY_PATH },
        ],
      },
    ],
  },
  {
    key: 'Q3',
    children: [
      {
        key: 'Q3-1',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q3_1_KEY_PATH], result: Answer.NO }] },
          { key: Q4_1_KEY_PATH },
        ],
      },
    ],
  },
  {
    key: 'Q4',
    children: [
      {
        key: 'Q4-1',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q4_1_KEY_PATH], result: Answer.NO }] },
          { key: Q5_1_KEY_PATH },
        ],
      },
    ],
  },
  {
    key: 'Q5',
    children: [
      {
        key: 'Q5-1',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q5_1_KEY_PATH], result: Answer.NO }] },
          { key: Q5_2_KEY_PATH },
        ],
      },
      {
        key: 'Q5-2',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q5_2_KEY_PATH], result: Answer.NO }] },
          { key: Q6_1_1_KEY_PATH },
        ],
      },
    ],
  },
  {
    key: 'Q6',
    children: [
      {
        key: 'Q6-1',
        children: [
          {
            key: 'Q6-1-1',
            proceedWith: [
              {
                key: Q6_1_2_KEY_PATH,
                condition: [{ keys: [Q6_1_1_KEY_PATH], result: Answer.YES }],
              },
              { key: Q6_2_1_KEY_PATH },
            ],
            infos: [
              {
                type: 'INF-CONTQ62',
                condition: [{ keys: [Q6_1_1_KEY_PATH], result: Answer.NO }],
              },
            ],
          },
          {
            key: 'Q6-1-2',
            proceedWith: [
              {
                key: Q7_1_KEY_PATH,
                condition: [{ keys: [Q6_1_2_KEY_PATH], result: Answer.YES }],
              },
              { key: Q6_2_1_KEY_PATH },
            ],
            infos: [
              {
                type: 'INF-TRADSECCONTQ7',
                condition: [{ keys: [Q6_1_2_KEY_PATH], result: Answer.YES }],
              },
            ],
          },
        ],
      },
      {
        key: 'Q6-2',
        children: [
          {
            key: 'Q6-2-1',
            proceedWith: [
              {
                key: Q7_1_KEY_PATH,
                condition: [{ keys: [Q6_2_1_KEY_PATH], result: Answer.YES }],
              },
              { key: Q6_3_1_KEY_PATH },
            ],
            infos: [
              {
                type: 'INF-SYNSECCONTQ7',
                condition: [{ keys: [Q6_2_1_KEY_PATH], result: Answer.YES }],
              },
            ],
          },
        ],
      },
      {
        key: 'Q6-3',
        children: [
          {
            key: 'Q6-3-1',
            proceedWith: [
              {
                key: PASS_KEY_PATH,
                condition: [{ keys: [Q6_3_1_KEY_PATH], result: Answer.NO }],
              },
              { key: Q7_1_KEY_PATH },
            ],
            infos: [
              {
                type: 'INF-REVSECCONTQ7',
                condition: [{ keys: [Q6_3_1_KEY_PATH], result: Answer.YES }],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: 'Q7',
    children: [
      {
        key: 'Q7-1',
        proceedWith: [
          { key: Q7_2_KEY_PATH, condition: [{ keys: [Q7_1_KEY_PATH], result: Answer.NO }] },
          { key: FAIL_TRADSEC_KEY_PATH, condition: traditionalSecuritizationCondition },
          { key: FAIL_SYNSEC_KEY_PATH, condition: syntheticSecuritizationCondition },
          { key: FAIL_REVSEC_KEY_PATH, condition: revolvingSecuritizationCondition },
        ],
        infos: [
          {
            type: 'INF-LEGALCOMMENT',
            condition: [{ keys: [Q7_1_KEY_PATH], result: Answer.YES }],
          },
        ],
      },
      {
        key: 'Q7-2',
        proceedWith: [
          { key: FAIL_TRADSEC_KEY_PATH, condition: traditionalSecuritizationCondition },
          { key: FAIL_SYNSEC_KEY_PATH, condition: syntheticSecuritizationCondition },
          { key: FAIL_REVSEC_KEY_PATH, condition: revolvingSecuritizationCondition },
        ],
        infos: [{ type: 'INF-LEGALCOMMENT' }],
      },
    ],
  },
]

export const availableEndings = [
  { key: PASS_KEY, messageStripDesign: MessageStripDesign.Positive },
  { key: FAIL_TRADSEC_KEY, messageStripDesign: MessageStripDesign.Warning },
  { key: FAIL_SYNSEC_KEY, messageStripDesign: MessageStripDesign.Warning },
  { key: FAIL_REVSEC_KEY, messageStripDesign: MessageStripDesign.Warning },
]
