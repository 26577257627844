import { useContext } from 'react'
import { arrearsPermissions } from 'api/arrears/ArrearsPermissions'
import { eventStatus } from 'api/events/status'
import useApprovalAndDecisionPaperStatus, {
  NO_INFORMATION,
  OUT_OF_SYNC,
} from 'components/domains/business-partners/tile/arrears/current-approvals/useApprovalAndDecisionPaperStatus'
import { BusinessPartnerArrearsContext } from 'routes/business-partners/arrears/BusinessPartnerArrearsContext'

const useStartApprovalProcessButton = () => {
  const {
    arrearsWithoutOverpayments,
    arrearApprovals,
    arrearApprovalEvents,
    businessPartnerPdLevel,
    allowedOperations,
  } = useContext(BusinessPartnerArrearsContext)

  const hasPermission = allowedOperations.includes(arrearsPermissions.writeApprovals)

  const latestApproval = arrearApprovals?.[0]

  const correspondingApprovalEvent = arrearApprovalEvents?.find(
    (event) => event?.id === latestApproval?.eventId,
  )

  const { decisionPaperSyncStatus } = useApprovalAndDecisionPaperStatus({
    arrearApprovalLocked: latestApproval?.isLocked,
    event: correspondingApprovalEvent,
  })

  const syncStatus = decisionPaperSyncStatus()

  const isBpPdLevelBelowLimit = businessPartnerPdLevel?.isBelowLimit ?? true
  const hasArrears = arrearsWithoutOverpayments?.length > 0

  const syncStatusNotOutOfSyncAndNotLoading =
    syncStatus !== OUT_OF_SYNC && syncStatus !== NO_INFORMATION
  const approvalAndEventExists = latestApproval && correspondingApprovalEvent
  const noApprovalExists = !latestApproval
  const approvalExistsAndIsOngoingAndNotLocked =
    approvalAndEventExists &&
    correspondingApprovalEvent.status === eventStatus.ongoing &&
    !latestApproval.isLocked &&
    syncStatusNotOutOfSyncAndNotLoading
  const approvalExistsAndIsLocked = approvalAndEventExists && latestApproval.isLocked

  return {
    showStartApprovalButton:
      hasArrears &&
      isBpPdLevelBelowLimit &&
      hasPermission &&
      (noApprovalExists || approvalExistsAndIsOngoingAndNotLocked || approvalExistsAndIsLocked),
    noApprovalExists,
    approvalExistsAndIsOngoingAndNotLocked,
    approvalExistsAndIsLocked,
  }
}

export default useStartApprovalProcessButton
