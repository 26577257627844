import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { Children, cloneElement, isValidElement } from 'react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/data/Labeled.module.css'

const RADIX = 36
const randomId = () => 'labeled-' + (Math.random() + 1).toString(RADIX).substring(2)

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  id: PropTypes.string,
  required: PropTypes.bool,
  showColon: PropTypes.bool,
  wrappingType: PropTypes.oneOf(['None', 'Normal']),
  className: PropTypes.string,
  vertical: PropTypes.bool,
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const Labeled = ({
  children,
  label,
  id,
  required,
  showColon,
  wrappingType,
  className = '',
  vertical = false,
  ...props
}) => {
  const child = isValidElement(children) ? Children.only(children) : <span>{children}</span>
  const idValue = id || child.props?.id || randomId()
  const containerClass = vertical ? styles.verticalLabel : styles.horizontalLabel
  return (
    <div className={`${containerClass} ${className}`}>
      <Label
        for={id}
        required={required}
        showColon={showColon}
        wrappingType={wrappingType}
        {...props}
      >
        {label}
      </Label>
      {!vertical && <>&nbsp;</>}
      {cloneElement(child, { id: idValue })}
    </div>
  )
}

Labeled.propTypes = propTypes

export default Labeled
