import groupBy from 'lodash.groupby'
import sortBy from 'lodash.sortby'

export const KpiThresholdTypes = {
  GREATER_THAN: '>',
  GREATER_OR_EQUAL_THAN: '≥',
  LESS_THAN: '<',
  LESS_OR_EQUAL_THAN: '≤',
}

export const getKpiForestFromKpiList = (kpiList = []) => {
  const kpiMap = {}
  kpiList.forEach((kpi) => (kpiMap[kpi.id] = { ...kpi, children: [] }))

  kpiList.forEach((kpi) => {
    kpi.parents.forEach((parentId) => kpiMap[parentId].children.push(kpiMap[kpi.id]))
  })

  return Object.values(kpiMap).filter((kpi) => kpi.parents.length === 0)
}

export const getTimeSeriesForKpi = (kpiTree) => {
  const allKpiValues = kpiTree?.values ?? []
  const adjustments = kpiTree?.adjustments ?? []
  const subKpiAdjustments = kpiTree?.children?.map((kpi) => kpi.adjustments)?.flat() ?? []
  const adjustmentIds = adjustments.map(({ id }) => id)

  const allCurrentKeyDateValues = []

  const keyDateGroups = groupBy(allKpiValues, 'keyDate')
  Object.entries(keyDateGroups).forEach(([_, keyDateValues]) => {
    if (keyDateValues.length === 1) {
      allCurrentKeyDateValues.push(keyDateValues[0])
    }
    if (keyDateValues.length > 1) {
      const currentKeyDateValue = sortBy(keyDateValues, 'validFrom').reverse()[0]
      allCurrentKeyDateValues.push(currentKeyDateValue)
    }
  })

  return allCurrentKeyDateValues.map((valueEntry) => {
    const date = new Date(valueEntry.keyDate)

    // find an adjustment for this value -> use updatedBy if found
    const adjustment = adjustments
      .concat(subKpiAdjustments)
      .find(({ validFrom, validTo }) => date >= new Date(validFrom) && date <= new Date(validTo))

    // use value if it is a direct adjustment
    const isDirectedAdjustment = adjustmentIds.includes(adjustment?.id)

    // simple time series structure for drawing chart
    return {
      value: isDirectedAdjustment ? adjustment.value : valueEntry.value,
      keyDate: valueEntry.keyDate,
      updatedBy: adjustment?.updatedBy,
      calculatedValue: valueEntry.value,
      adjustedValue: isDirectedAdjustment ? adjustment.value : null,
      comment: isDirectedAdjustment ? adjustment.comment ?? null : null,
    }
  })
}

export const getCurrentValueForTimeSeries = (timeSeries) => {
  const today = new Date()
  return timeSeries
    .filter(({ keyDate }) => new Date(keyDate) <= today)
    .toSorted((a, b) => new Date(b.keyDate) - new Date(a.keyDate))
    .find(() => true)
}

export const getLastUltimoForTimeSeries = (timeSeries) => {
  const today = new Date()
  // last ultimo = last day of last month
  const lastUltimoDate = new Date(today.getFullYear(), today.getMonth(), 0).toDateString()
  return timeSeries.find(({ keyDate }) => new Date(keyDate).toDateString() === lastUltimoDate)
}
