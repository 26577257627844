import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDealProperties = ({ dealUuid, options = {} }) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/properties`,
      useCache: true,
      keys: ['deals', dealUuid, 'properties'],
      options: { enabled: !!dealUuid, ...options },
    }),
  )

export default useDealProperties
