import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/events/dialog/EventCreationDialog.module.css'
import EventEntityRefNameSearch from 'components/domains/business-events-and-tasks/events/input/EventEntityRefNameSearch'
import LoadingDropdown from 'components/ui/dropdown/LoadingDropdown'
import useEventEntityTypes from 'hooks/services/business-events-and-tasks/events/useEventEntityTypes'

const EventCreationEntityForm = ({
  preselectedEntity,
  setSelectedEntityId,
  setSelectedEventType,
  selectedEntityType,
  setSelectedEntityType,
  setIsSelectedEntityWorkingVersion = () => {},
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dialog.event-creation' })

  const {
    isLoading: isEntityTypesLoading,
    isError: isEntityTypesError,
    data: entityTypesData,
  } = useEventEntityTypes()

  useEffect(() => {
    if (preselectedEntity && setSelectedEntityId) {
      setSelectedEntityId(preselectedEntity.entityId)
      setSelectedEntityType(preselectedEntity.entityType)
      setIsSelectedEntityWorkingVersion(preselectedEntity.isWorkingVersion)
    }
  }, [
    preselectedEntity,
    setIsSelectedEntityWorkingVersion,
    setSelectedEntityId,
    setSelectedEntityType,
  ])

  const entityTypesLoaderForLoadingDropdown = () => {
    if (isEntityTypesLoading || isEntityTypesError) {
      return { isLoading: isEntityTypesLoading, isError: isEntityTypesError, data: undefined }
    }

    return {
      isLoading: false,
      isError: false,
      data: entityTypesData.entity_types.map(({ code, value }) => ({ code, displayName: value })),
    }
  }

  const onSelectedEntityTypeChanged = (entityType) => {
    setSelectedEntityType(entityType)
    setSelectedEntityId()
    setSelectedEventType()
  }

  const onEntitySuggestionSelected = useCallback(
    (entityId, isWorkingVersion) => {
      if (!entityId) {
        setSelectedEntityId()
        setIsSelectedEntityWorkingVersion(false)
        setSelectedEventType()
        return
      }
      setSelectedEntityId(entityId)
      setIsSelectedEntityWorkingVersion(isWorkingVersion)
    },
    [setIsSelectedEntityWorkingVersion, setSelectedEntityId, setSelectedEventType],
  )

  return (
    <>
      <div className={styles['subtitle']}>{t('subtitle')}</div>
      <FlexBox direction={FlexBoxDirection.Column} className={styles.flexGrid}>
        <Label showColon required>
          {t('select.business-object-type')}
        </Label>
        <LoadingDropdown
          id="event-creation-business-object-type"
          disabled={!!preselectedEntity}
          value={selectedEntityType}
          onChange={setSelectedEntityId && onSelectedEntityTypeChanged}
          useLoader={entityTypesLoaderForLoadingDropdown}
          initialDropdownValue={''}
          className={styles.inputWidth}
        />
      </FlexBox>
      <FlexBox direction={FlexBoxDirection.Column} className={styles.flexGrid}>
        <EventEntityRefNameSearch
          entityType={selectedEntityType}
          preselectedEntity={preselectedEntity}
          inputTitle={t('select.business-object-ref')}
          loadingText={t('select.business-object-ref.loading')}
          errorText={t('select.business-object-ref.error')}
          typeMoreText={t('select.business-object-ref.type-more')}
          emptyResultsText={t('select.business-object-ref.empty')}
          required
          onEntityRefNameSelected={setSelectedEntityType && onEntitySuggestionSelected}
        />
      </FlexBox>
    </>
  )
}

EventCreationEntityForm.propTypes = {
  setSelectedEntityId: PropTypes.func,
  setSelectedEventType: PropTypes.func.isRequired,
  selectedEntityType: PropTypes.string,
  setSelectedEntityType: PropTypes.func,
  setIsSelectedEntityWorkingVersion: PropTypes.func,
  preselectedEntity: PropTypes.shape({
    entityDisplayId: PropTypes.string,
    entityName: PropTypes.string,
    entityId: PropTypes.string,
    entityType: PropTypes.string,
    isWorkingVersion: PropTypes.bool,
  }),
}

export default EventCreationEntityForm
