import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDealCollateralInformation = ({ dealUuid }) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/collateral`,
      keys: ['deals', dealUuid, 'collateral'],
      options: {
        enabled: !!dealUuid,
      },
    }),
  )

export default useDealCollateralInformation
