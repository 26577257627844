import isNil from 'lodash.isnil'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useBankCustomerAccountsByBankAreaAndAccountNumber = ({
  bankArea,
  externalAccountNumber,
  options = {},
}) =>
  useCamelizedResponse(
    useRequest({
      path: `/financing-structures/bank-customer-accounts/${bankArea}/${externalAccountNumber}`,
      keys: ['bank-customer-accounts', bankArea, externalAccountNumber],
      options: { enabled: !isNil(bankArea) && !isNil(externalAccountNumber), ...options },
      translated: true,
    }),
  )

export default useBankCustomerAccountsByBankAreaAndAccountNumber
