import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useBusinessPartnerPdLevel = (businessPartnerId) =>
  useCamelizedResponse(
    useRequest({
      path: `/arrears/business-partner-pd-levels/${businessPartnerId}`,
      keys: ['arrears', 'business-partner-pd-levels', businessPartnerId],
      options: {
        enabled: !!businessPartnerId,
      },
    }),
  )

export default useBusinessPartnerPdLevel
