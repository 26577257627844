import { TableRowType } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  valuationClassificationTranslated,
  valuationRelevance,
} from 'api/property/valuation/valuationRequests'
import PropertyValuationResultsDateInputCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-results/cells/PropertyValuationResultsDateInputCell'
import PropertyValuationResultsMethodCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-results/cells/PropertyValuationResultsMethodCell'
import PropertyValuationResultsRegulatoryRelevantCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-results/cells/PropertyValuationResultsRegulatoryRelevantCell'
import PropertyValuationResultsValuationTypeCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-results/cells/PropertyValuationResultsValuationTypeCell'
import PropertyValuationResultsValueAmountCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-results/cells/PropertyValuationResultsValueAmountCell'
import { ValuationResultsTableColumns } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-results/usePropertyValuationResultsTableColumns'
import { useBooleanToTextFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'

const useMapPropertyValuationResultsTableData = ({ valuationResults }) => {
  const { t } = useTranslation('decisionPaper')
  const formatBoolean = useBooleanToTextFormatter()

  const tableData = useMemo(
    () =>
      valuationResults.map(
        ({
          valuationId,
          externalCalculationMethodName,
          classification,
          regulatoryRelevance,
          keyDate,
          valueAmount: { number, currency },
          validFrom,
          validTo,
        }) => ({
          rowKey: `valuation-result-${valuationId}`,
          rowProperties: {
            type: TableRowType.Inactive,
            'data-result-id': valuationId,
          },
          [ValuationResultsTableColumns.valuationType]: {
            value: t(valuationClassificationTranslated?.[classification]),
            cellComponent: (
              <PropertyValuationResultsValuationTypeCell valuationType={classification} />
            ),
          },
          [ValuationResultsTableColumns.method]: {
            value: externalCalculationMethodName,
            cellComponent: (
              <PropertyValuationResultsMethodCell
                calculationMethodName={externalCalculationMethodName}
              />
            ),
          },
          [ValuationResultsTableColumns.keyDate]: {
            value: keyDate,
            cellComponent: <PropertyValuationResultsDateInputCell date={keyDate} />,
          },
          [ValuationResultsTableColumns.result]: {
            value: number,
            cellComponent: (
              <PropertyValuationResultsValueAmountCell valueAmount={number} currency={currency} />
            ),
          },
          [ValuationResultsTableColumns.validFrom]: {
            value: validFrom,
            cellComponent: <PropertyValuationResultsDateInputCell date={validFrom} />,
          },
          [ValuationResultsTableColumns.validTo]: {
            value: validTo,
            cellComponent: <PropertyValuationResultsDateInputCell date={validTo} />,
          },
          [ValuationResultsTableColumns.regulatoryRelevant]: {
            value: formatBoolean(regulatoryRelevance === valuationRelevance.relevant),
            cellComponent: (
              <PropertyValuationResultsRegulatoryRelevantCell
                regulatoryRelevance={regulatoryRelevance}
              />
            ),
          },
        }),
      ),
    [formatBoolean, valuationResults, t],
  )

  return useMemo(() => ({ tableData }), [tableData])
}

export default useMapPropertyValuationResultsTableData
