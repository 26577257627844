import isNil from 'lodash.isnil'
import { useTranslation } from 'react-i18next'
import { rowKeyNewRow } from 'components/ui/tables/display-and-edit-table/constants'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const EMPTY_STRING = ''
const isEmptyOrNil = (value) => isNil(value) || value === ''

const useValidateKpiAdjustmentTable = ({ kpi, allAdjustments, today, getCurrentValue }) => {
  const { format: formatDate } = useShortDateFormatter()
  const { t } = useTranslation('translation')

  const validity = kpi.daysOfAllowedRetrospectiveAdjustments ?? 0

  const yesterday = new Date()
  yesterday.setDate(today.getDate() - 1)

  const firstValidDate = new Date()
  firstValidDate.setDate(today.getDate() - validity)
  firstValidDate.setHours(0, 0, 0)

  const getCurrentDateValues = (rowKey) => ({
    validFrom: getCurrentValue(rowKey, 'validFrom'),
    validTo: getCurrentValue(rowKey, 'validTo'),
  })

  const isDateInPast = (date) => new Date(date) < new Date().setHours(0, 0, 0)

  const isValueValid = (rowKey) => {
    const currentValue = getCurrentValue(rowKey, 'value')
    return !isEmptyOrNil(currentValue)
  }

  const isValidFromOrToDateOverlapping = (
    rowKey,
    currentDate,
    shouldFilterRetrospective,
    validFromDate,
  ) => {
    const overlappingAdjustments = allAdjustments
      .filter(({ id }) => id !== rowKey)
      .filter(
        ({ validFrom, validTo }) =>
          shouldFilterRetrospective
            ? isDateInPast(validFrom) && isDateInPast(validTo) // only retrospective adjustments
            : !(isDateInPast(validFrom) && isDateInPast(validTo)), // only prospective adjustments
      )
      .filter(
        ({ validFrom, validTo }) =>
          (new Date(validFrom) <= currentDate && currentDate <= new Date(validTo)) ||
          (!!validFromDate &&
            validFromDate <= new Date(validFrom) &&
            currentDate >= new Date(validTo)),
      )

    return overlappingAdjustments.length > 0
  }

  const validateDateProspectiveAdjustment = (rowKey, fieldName, currentValue) => {
    const currentDate = new Date(currentValue)
    const validFromDate = new Date(getCurrentDateValues(rowKey).validFrom)

    // dates should NOT be in the past for prospective adjustment
    if (isDateInPast(currentDate))
      return {
        isValid: false,
        message: t('components.kpis.details.adjustments.error.invalid-prospective-date'),
      }

    // validFrom should not be inside another adjustment window
    if (fieldName === 'validFrom') {
      if (isValidFromOrToDateOverlapping(rowKey, currentDate))
        return {
          isValid: false,
          message: t('components.kpis.details.adjustments.error.overlapping-adjustment'),
        }
    }

    // validTo should not be before validFrom, inside another adjustment window and should wrap another adjustment window
    if (fieldName === 'validTo') {
      if (currentDate < validFromDate)
        return {
          isValid: false,
          message: t('components.kpis.details.adjustments.error.invalid-valid-to'),
        }
      if (isValidFromOrToDateOverlapping(rowKey, currentDate, false, validFromDate))
        return {
          isValid: false,
          message: t('components.kpis.details.adjustments.error.overlapping-adjustment'),
        }
    }

    return { isValid: true, message: EMPTY_STRING }
  }

  const validateDateRetrospectiveAdjustment = (rowKey, fieldName, currentValue) => {
    const currentDate = new Date(currentValue)
    const validFromDate = new Date(getCurrentDateValues(rowKey).validFrom)

    // dates should be in the past for retrospective adjustment
    if (!isDateInPast(currentDate))
      return {
        isValid: false,
        message: t('components.kpis.details.adjustments.error.invalid-retrospective-date'),
      }

    // validFrom should be in the past up to first valid date
    if (fieldName === 'validFrom') {
      if (currentDate < firstValidDate)
        return {
          isValid: false,
          message: t('components.kpis.details.adjustments.error.invalid-valid-from', {
            validFrom: formatDate(firstValidDate.toISOString()),
          }),
        }

      // validTo should be after validFrom
    } else if (fieldName === 'validTo') {
      if (currentDate < validFromDate)
        return {
          isValid: false,
          message: t('components.kpis.details.adjustments.error.invalid-valid-to'),
        }
    }

    return { isValid: true, message: EMPTY_STRING }
  }

  const isRetrospectiveAdjustment = (rowKey) => {
    const { validFrom } = getCurrentDateValues(rowKey)

    return !!validFrom && isDateInPast(validFrom) && rowKey === rowKeyNewRow
  }

  const validateDate = (rowKey, fieldName) => {
    const currentValue = getCurrentValue(rowKey, fieldName)

    if (!currentValue)
      return {
        isValid: false,
        message: t('components.kpis.details.adjustments.error.invalid-date'),
      }

    // only prospective adjustments that can be edited
    if (rowKey !== rowKeyNewRow) {
      return validateDateProspectiveAdjustment(rowKey, fieldName, currentValue)
    }

    // newly created adjustments can be both prospective or retrospective
    return isRetrospectiveAdjustment(rowKey)
      ? validateDateRetrospectiveAdjustment(rowKey, fieldName, currentValue)
      : validateDateProspectiveAdjustment(rowKey, fieldName, currentValue)
  }

  // a row is valid if value, validFrom and validTo are valid
  // in case validation is performed on an existing ongoing adjustment, then validFrom does not need to be validated - it is already in the past, but it is valid
  const isRowValid = (rowKey, isOngoingAdjustment) =>
    isValueValid(rowKey) &&
    (isOngoingAdjustment || validateDate(rowKey, 'validFrom').isValid) &&
    validateDate(rowKey, 'validTo').isValid

  const isRetrospectiveAdjustmentOverlapping = (rowKey) => {
    const { validFrom, validTo } = getCurrentDateValues(rowKey)
    const validFromDate = new Date(validFrom)
    const validToDate = new Date(validTo)

    return (
      isRetrospectiveAdjustment(rowKey) &&
      (isValidFromOrToDateOverlapping(rowKey, validFromDate, true) ||
        isValidFromOrToDateOverlapping(rowKey, validToDate, true, validFromDate))
    )
  }

  // only prospective adjustments can be edited, and for them user can not select dates in the past
  const getValidFromDateLimits = (rowKey) => ({
    minDate:
      rowKey !== rowKeyNewRow
        ? formatDate(today.toISOString())
        : formatDate(firstValidDate.toISOString()),
  })

  const getValidToDateLimits = (rowKey) => {
    const { validFrom } = getCurrentDateValues(rowKey)

    if (validFrom) {
      const validFromDate = new Date(validFrom)

      if (isRetrospectiveAdjustment(rowKey)) {
        return {
          minDate: formatDate(
            new Date(Math.max(firstValidDate.getTime(), validFromDate.getTime())).toISOString(),
          ),
          maxDate: formatDate(yesterday.toISOString()),
        }
      } else {
        return {
          minDate: formatDate(
            new Date(Math.max(today.getTime(), validFromDate.getTime())).toISOString(),
          ),
        }
      }
    } else
      return {
        minDate: formatDate(today.toISOString()),
      }
  }

  return {
    isDateInPast,
    isRowValid,
    isRetrospectiveAdjustment,
    isRetrospectiveAdjustmentOverlapping,
    validateDate,
    getValidFromDateLimits,
    getValidToDateLimits,
  }
}

export default useValidateKpiAdjustmentTable
