import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { propertyMonitoringStatus } from 'api/property/monitoring/propertyMonitoringConfiguration'
import PropertyMonitoringSettingsBaselineDate from 'components/domains/properties/monitoring/settings/PropertyMonitoringSettingsBaselineDate'
import PropertyMonitoringSettingsConfirmationDialog from 'components/domains/properties/monitoring/settings/PropertyMonitoringSettingsConfirmationDialog'
import PropertyMonitoringSettingsSwitch from 'components/domains/properties/monitoring/settings/PropertyMonitoringSettingsSwitch'
import DialogContent from 'components/domains/properties/monitoring/settings/dialog/DialogContent'
import PropertyMonitoringSettingsDialogView from 'components/domains/properties/monitoring/settings/dialog/PropertyMonitoringSettingsDialogView'

const isMonitoringActivated = (monitoringStatus) =>
  monitoringStatus === propertyMonitoringStatus.activeAutomated ||
  monitoringStatus === propertyMonitoringStatus.activeManual ||
  monitoringStatus === propertyMonitoringStatus.activeManualForced ||
  monitoringStatus === propertyMonitoringStatus.paused

const isMonitoringAutomated = (monitoringStatus, baselineDate) =>
  monitoringStatus === propertyMonitoringStatus.activeAutomated ||
  (monitoringStatus === propertyMonitoringStatus.paused && !!baselineDate)

const isInputDateInvalid = (inputDate) => {
  const todaysDate = new Date()
  return !inputDate || inputDate > todaysDate
}

const PropertyMonitoringSettingsDialog = ({
  monitoringStatus,
  setIsDialogOpen,
  isDialogOpen,
  onSettingsSaved,
  previouslySetBaselineDate,
  isPropertyAssignedToMarket,
  note,
  monitoringPermissions,
  baselineSuggestion,
}) => {
  const monitoringCurrentlyActivated = isMonitoringActivated(monitoringStatus)
  const monitoringCurrentlyAutomated = isMonitoringAutomated(
    monitoringStatus,
    previouslySetBaselineDate,
  )
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
  const [editedMonitoringActivatedState, setEditedMonitoringActivatedState] = useState(
    monitoringCurrentlyActivated,
  )
  const [editedMonitoringAutomatedState, setEditedMonitoringAutomatedState] = useState(
    monitoringCurrentlyAutomated,
  )
  const [editedBaselineDate, setEditedBaselineDate] = useState(previouslySetBaselineDate)
  const [editedNote, setEditedNote] = useState(note)

  useEffect(() => {
    setEditedMonitoringActivatedState(monitoringCurrentlyActivated)
    setEditedBaselineDate(previouslySetBaselineDate)
    setEditedMonitoringAutomatedState(monitoringCurrentlyAutomated)
    setEditedNote(note)
  }, [monitoringCurrentlyActivated, monitoringCurrentlyAutomated, previouslySetBaselineDate, note])

  const isConfirmationDialogNeeded = () =>
    monitoringCurrentlyActivated && !editedMonitoringActivatedState

  const handleSettingsOnSave = () => {
    if (isConfirmationDialogNeeded()) {
      setIsConfirmationDialogOpen(true)
      setIsDialogOpen(false)
      return
    }
    setIsDialogOpen(false)

    onSettingsSaved({
      monitoringActivated: editedMonitoringActivatedState,
      baselineDate: editedMonitoringAutomatedState ? editedBaselineDate : undefined,
      note: editedNote,
    })
  }

  const isSaveButtonEnabled = () => {
    if (
      editedMonitoringAutomatedState &&
      (!editedBaselineDate || isInputDateInvalid(new Date(editedBaselineDate)))
    ) {
      return false
    }
    if (editedMonitoringActivatedState !== monitoringCurrentlyActivated) {
      return true
    }
    if (editedMonitoringAutomatedState !== monitoringCurrentlyAutomated) {
      return true
    }
    if (editedNote !== note) {
      return true
    }
    if (
      editedMonitoringAutomatedState &&
      !isInputDateInvalid(new Date(editedBaselineDate)) &&
      editedBaselineDate !== previouslySetBaselineDate
    ) {
      return true
    }
    return false
  }

  const handleConfirmation = () => {
    setIsConfirmationDialogOpen(false)
    onSettingsSaved({
      monitoringActivated: editedMonitoringActivatedState,
      baselineDate: editedBaselineDate,
      note: editedNote,
    })
  }

  const handleConfirmationCancel = () => {
    setIsConfirmationDialogOpen(false)
    setIsDialogOpen(true)
  }

  const handleMonitoringActivatedChanged = () => {
    setEditedMonitoringActivatedState(!editedMonitoringActivatedState)
  }

  const handleMonitoringAutomatedChanged = () => {
    setEditedMonitoringAutomatedState(!editedMonitoringAutomatedState)
    setEditedBaselineDate(previouslySetBaselineDate)
  }

  const onNoteChanged = (changedNote) => {
    setEditedNote(changedNote)
  }

  return (
    <>
      <PropertyMonitoringSettingsDialogView
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        isSaveButtonShown={true}
        isSaveButtonEnabled={isSaveButtonEnabled()}
        handleSettingsOnSave={handleSettingsOnSave}
        isMonitoringActivated={monitoringCurrentlyActivated}
        monitoringPermissions={monitoringPermissions}
      >
        <DialogContent>
          <PropertyMonitoringSettingsSwitch
            monitoringActivatedState={editedMonitoringActivatedState}
            onMonitoringActivatedStateChange={handleMonitoringActivatedChanged}
            monitoringAutomatedState={editedMonitoringAutomatedState}
            onMonitoringAutomatedStateChange={handleMonitoringAutomatedChanged}
            isPropertyAssignedToMarket={isPropertyAssignedToMarket}
            note={editedNote}
            onNoteChanged={onNoteChanged}
            monitoringPermissions={monitoringPermissions}
          />
          {editedMonitoringActivatedState && editedMonitoringAutomatedState && (
            <PropertyMonitoringSettingsBaselineDate
              isAutomaticMonitoringActivated={monitoringCurrentlyAutomated}
              previouslySetBaselineDate={previouslySetBaselineDate}
              baselineSuggestion={baselineSuggestion}
              handleBaselineDateChange={setEditedBaselineDate}
            />
          )}
        </DialogContent>
      </PropertyMonitoringSettingsDialogView>
      <PropertyMonitoringSettingsConfirmationDialog
        handleConfirmation={handleConfirmation}
        handleConfirmationCancel={handleConfirmationCancel}
        isConfirmationDialogOpen={isConfirmationDialogOpen}
        setIsConfirmationDialogOpen={setIsConfirmationDialogOpen}
      />
    </>
  )
}

PropertyMonitoringSettingsDialog.propTypes = {
  monitoringStatus: PropTypes.oneOf(Object.values(propertyMonitoringStatus)).isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  setIsDialogOpen: PropTypes.func.isRequired,
  onSettingsSaved: PropTypes.func.isRequired,
  previouslySetBaselineDate: PropTypes.string,
  isPropertyAssignedToMarket: PropTypes.bool.isRequired,
  note: PropTypes.string,
  monitoringPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  baselineSuggestion: PropTypes.exact({
    hasErrorLoading: PropTypes.bool.isRequired,
    suggestionDate: PropTypes.string,
  }).isRequired,
}

export default PropertyMonitoringSettingsDialog
