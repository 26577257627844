import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { isNotFoundError } from 'api/requests'
import { periodValues } from 'components/domains/deals/deal-cashflow/results/PeriodSelect'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useTileMetaData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/meta-data/useTileMetaData'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import { useCashFlowScenario } from 'hooks/services/deals/cashflow/useCashFlowScenario'
import useCashflowGroups from 'hooks/services/deals/cashflow/useCashflowGroups'
import useCashflowScenarioResultsById from 'hooks/services/deals/cashflow/useCashflowScenarioResultsById'
import useCashflowsObjectDefinitions from 'hooks/services/deals/cashflow/useCashflowsObjectDefinitions'
import useDealMini from 'hooks/services/deals/useDealMini'

const PROPERTY_AND_PORTFOLIO_OBJECT_TYPE = ['PORTFOLIO', 'PROPERTY']
const GLOBAL_OBJECT_TYPE = ['GLOBAL']
export const CASH_FLOW_START_DATE = 'cash_flow_start_date'
export const APPLIED_LEASE_END = 'Applied Lease End'
export const CASH_FLOW_START_DATE_LABEL = 'Cash Flow Start Date'
const PROPERTY_AND_PORTFOLIO_PARAMETERS = [
  'Re-let/ Reviewed',
  'New Lease Length',
  'Rent-Free Period',
  'Void Period',
  'Void Costs',
  'Empty Rates (UK only)',
]

const getFilteredUsedParameter = (usedParameters, internalObjectTypeArray) =>
  usedParameters.filter((cashFlowObject) =>
    internalObjectTypeArray.includes(cashFlowObject?.objectDefinition?.internalObjectType),
  )

const useCashFlowAnalysis = (
  { id: eventId, entityRef: { entityId: dealUuid } },
  tileId,
  tileCode,
) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    data: dealData,
    isFetching: isDealFetching,
    isError: isDealError,
  } = useDealMini(dealUuidByTileCode)

  const { format: formatDate } = useShortDateFormatter()

  const {
    data: tileMetaDataSnakeCase,
    isFetching: isTileMetaDataFetching,
    isError: isTileMetaDataError,
  } = useTileMetaData({ eventId, tileId })

  const tileMetaData = camelize(tileMetaDataSnakeCase)
  const selectedScenarioUuid = tileMetaData?.metaData?.selectedCashFlowScenarioUuid

  const {
    data: { calculatedKpis: periodYearsCalculatedKpis = [], usedParameters = [] } = {},
    isFetching: isCashflowScenarioPeriodYearsResultFetching,
    isError: isCashflowScenarioPeriodYearsResultError,
    error: cashflowScenarioPeriodYearsResultError,
  } = useCashflowScenarioResultsById(
    {
      dealUuid: dealUuidByTileCode,
      cashflowId: selectedScenarioUuid,
      datasetPeriod: periodValues.years,
    },
    {
      enabled:
        !isTileMetaDataError &&
        !isTileMetaDataFetching &&
        !!selectedScenarioUuid &&
        !!dealUuidByTileCode,
    },
  )

  const {
    data: { calculatedKpis: periodQuatersCalculatedKpis = [], calculatedResults = [] } = {},
    isFetching: isCashflowScenarioPeriodQuartersResultFetching,
    isError: isCashflowScenarioPeriodQuatersResultError,
    error: cashflowScenarioPeriodQuartersResultError,
  } = useCashflowScenarioResultsById(
    {
      dealUuid: dealUuidByTileCode,
      cashflowId: selectedScenarioUuid,
      datasetPeriod: periodValues.quarters,
    },
    {
      enabled:
        !isTileMetaDataError &&
        !isTileMetaDataFetching &&
        !!selectedScenarioUuid &&
        !!dealUuidByTileCode,
    },
  )
  const {
    data: { groups = [] } = {},
    isFetching: isFetchingGroups,
    isError: isErrorGroups,
  } = useCashflowGroups()

  const {
    data: { objects: config = [] } = {},
    isFetching: isFetchingConfig,
    isError: isErrorConfig,
  } = useCashflowsObjectDefinitions()

  const {
    data: { extensionFields: cashFlowScenarioInfo = [] } = {},
    isFetching: isFetchingCashFlowScenario,
    isError: isErrorCashFlowScenario,
    error: cashFlowScenarioError,
  } = useCashFlowScenario(dealUuidByTileCode, selectedScenarioUuid)

  const cashFlowStartDate = cashFlowScenarioInfo.filter(
    (field) => field?.fieldName === CASH_FLOW_START_DATE,
  )?.[0]

  const parameterCashFlowStartDate = [
    {
      cashflowParameterDefinition: { name: CASH_FLOW_START_DATE_LABEL },
      cashflowParameterUnit: { name: formatDate(cashFlowStartDate?.value) ?? '' },
      value: null,
    },
  ]

  const usedParameterGlobal = getFilteredUsedParameter(usedParameters, GLOBAL_OBJECT_TYPE)?.[0]
  const parameterAppliedLeaseEnd =
    usedParameterGlobal?.parameters?.filter(
      (parameter) => parameter.cashflowParameterDefinition.name === APPLIED_LEASE_END,
    ) ?? []

  const filteredUsedParameterGlobal = !isEmpty(usedParameterGlobal)
    ? [
        {
          ...usedParameterGlobal,
          parameters: [...parameterCashFlowStartDate, ...parameterAppliedLeaseEnd],
        },
      ]
    : []

  const usedParametersPropertyAndPortfolio = getFilteredUsedParameter(
    usedParameters,
    PROPERTY_AND_PORTFOLIO_OBJECT_TYPE,
  )
  const filteredUsedParametersPropertyAndPortfolio = usedParametersPropertyAndPortfolio.map(
    (usedParameter) => {
      const filteredParameters = usedParameter.parameters.filter((parameter) =>
        PROPERTY_AND_PORTFOLIO_PARAMETERS.includes(parameter.cashflowParameterDefinition.name),
      )
      const getParameterName = (parameter) => parameter.cashflowParameterDefinition.name

      return {
        ...usedParameter,
        parameters: filteredParameters.sort(
          (a, b) =>
            PROPERTY_AND_PORTFOLIO_PARAMETERS.indexOf(getParameterName(a)) -
            PROPERTY_AND_PORTFOLIO_PARAMETERS.indexOf(getParameterName(b)),
        ),
      }
    },
  )
  const filteredUsedParameters = [
    ...filteredUsedParameterGlobal,
    ...filteredUsedParametersPropertyAndPortfolio,
  ]

  const dealId = dealData?.dealId
  const sourceRender = {
    dealDisplayId: dealId,
    sourcePathSuffix: isWorkingVersionDataTile ? '?working-version=true' : '',
  }

  const cashflowScenarioPeriodYearsNotFound =
    isCashflowScenarioPeriodYearsResultError &&
    isNotFoundError(cashflowScenarioPeriodYearsResultError)
  const cashflowScenarioPeriodQuartersNotFound =
    isCashflowScenarioPeriodQuatersResultError &&
    isNotFoundError(cashflowScenarioPeriodQuartersResultError)
  const cashFlowScenarioNotFound = isErrorCashFlowScenario && isNotFoundError(cashFlowScenarioError)

  const isLoading =
    (isTileMetaDataFetching ||
      isCashflowScenarioPeriodYearsResultFetching ||
      isCashflowScenarioPeriodQuartersResultFetching ||
      isDealUuidFetching ||
      isDealFetching ||
      isFetchingGroups ||
      isFetchingConfig ||
      isFetchingCashFlowScenario) &&
    !isTileMetaDataError &&
    !!selectedScenarioUuid
  const isError =
    isTileMetaDataError ||
    (isCashflowScenarioPeriodYearsResultError && !cashflowScenarioPeriodYearsNotFound) ||
    (isCashflowScenarioPeriodQuatersResultError && !cashflowScenarioPeriodQuartersNotFound) ||
    isDealUuidError ||
    isDealError ||
    isErrorGroups ||
    isErrorConfig ||
    (isErrorCashFlowScenario && !cashFlowScenarioNotFound)

  return {
    isLoading,
    isError,
    data: !(isError || isLoading)
      ? {
          cashflowScenarioPeriodNotFound:
            cashflowScenarioPeriodYearsNotFound ||
            cashflowScenarioPeriodQuartersNotFound ||
            cashFlowScenarioNotFound,
          periodYearsCalculatedKpis,
          periodQuatersCalculatedKpis,
          usedParameters: filteredUsedParameters,
          calculatedResults,
          groups,
          config,
          dealId,
          dealUuid,
          sourceRender,
          tileMetaData,
        }
      : undefined,
  }
}

export default useCashFlowAnalysis
