import { Button } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import { useMultiPropertyViewByPropertyUuids } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuids'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'
import paths from 'routes/paths'
import { PORTFOLIO_VIEW_QUERY_PARAM } from 'routes/properties/portfolio/PropertyPortfolioWrapper'

const PortfolioShowButton = ({ selectedProperties }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false)
  const [saveErrorDetails, setSaveErrorDetails] = useState(undefined)
  const asyncSetSaveErrorDetails = async (error) =>
    setSaveErrorDetails(await formatHookError(error))

  const { refetch } = useMultiPropertyViewByPropertyUuids(selectedProperties, {
    enabled: false,
    onSuccess: ({ data: multiPropertyView }) => {
      const qs = new URLSearchParams()
      qs.append(PORTFOLIO_VIEW_QUERY_PARAM, multiPropertyView.uuid)
      navigate(`/${paths.properties}/portfolio/overview?${qs}`)
    },
    onError: (error) => {
      setIsErrorMessageOpen(true)
      asyncSetSaveErrorDetails(error)
    },
  })

  const portfolioViewPossible = selectedProperties.length > 1

  const handleOnClick = () => {
    if (portfolioViewPossible) {
      refetch()
    }
  }

  return (
    <>
      <Button disabled={!portfolioViewPossible} onClick={handleOnClick}>
        {t('pages.property.portfolio.show.button')}
      </Button>
      {createPortal(
        <>
          {isErrorMessageOpen && (
            <ErrorMessageBoxWithExpandableDetails
              messageSummary={t('components.properties.multi-property-view.error')}
              messageDetails={saveErrorDetails}
              isOpen={isErrorMessageOpen}
              onClose={() => {
                setIsErrorMessageOpen(false)
              }}
            />
          )}
        </>,
        document.body,
      )}
    </>
  )
}
PortfolioShowButton.propTypes = {
  selectedProperties: PropTypes.array.isRequired,
}

export default PortfolioShowButton
