import isNil from 'lodash.isnil'
import orderBy from 'lodash.orderby'
import { propertyLinkedDealCodes } from 'api/property/linked-deal/propertyLinkedDeal'
import {
  TenancyCardTableColumnId,
  TenancyTableRowType,
} from 'components/domains/business-partners/tile/tenancy/tenant-unit-table/AsTenantTableConstants'

const getFinancingStatusOrdering = (financingStatusCode) => {
  const codeToOrderingMap = {
    [propertyLinkedDealCodes.linkedToActiveDeal]: 1,
    [propertyLinkedDealCodes.linkedToPipelineDeal]: 2,
  }
  return codeToOrderingMap[financingStatusCode] ?? Number.POSITIVE_INFINITY
}

const fallbackSortDirection = 'desc'
const fallbackSortingKey = 'annualizedRent.value'
const orderByFallback = (rows, sortKeys, sortDirections) =>
  orderBy(rows, [...sortKeys, fallbackSortingKey], [...sortDirections, fallbackSortDirection])
const orderByDefault = (rows, sortKeys) =>
  orderBy(
    rows,
    [fallbackSortingKey, ...sortKeys],
    // default ordering is hardcoded to be descending annualizedRent, ascending the sorting tie breaker(s)
    [fallbackSortDirection, ...sortKeys.map(() => 'asc')],
  )

const getSortDirectionEntries = (activeSorting) =>
  activeSorting.isSortingAscending ? 'asc' : 'desc'

const sortFinancingStatusRows = ({ rows, activeSorting }) => {
  const columnKey = activeSorting.columnKey
  const sortDirection = getSortDirectionEntries(activeSorting)
  const financingStatusSortAccessor = (row) => getFinancingStatusOrdering(row.financingStatusCode)
  switch (columnKey) {
    case TenancyCardTableColumnId.financingStatus:
      return orderByFallback(rows, [financingStatusSortAccessor], [sortDirection])
    // financing status sorting is special: Always sort by financingStatus ascending
    // Tie-breaker should not be needed as the table is used currently but default fallback sorting
    // is added to be safe. Only exception is explicitly sorting by the financing status (see above)
    default:
      return orderBy(
        rows,
        [financingStatusSortAccessor, fallbackSortingKey],
        ['asc', fallbackSortDirection],
      )
  }
}
const sortDealRows = ({ rows, activeSorting }) => {
  const columnKey = activeSorting.columnKey
  const sortDirection = getSortDirectionEntries(activeSorting)
  const dealNameAccessor = (row) => row.deal?.name?.toLowerCase()
  switch (columnKey) {
    case TenancyCardTableColumnId.area:
      return orderByFallback(rows, ['rentedArea.value'], [sortDirection])
    case TenancyCardTableColumnId.deal:
      return orderByFallback(rows, [dealNameAccessor], [sortDirection])
    case TenancyCardTableColumnId.pieces:
      return orderByFallback(rows, ['rentedAreaPieces.value'], [sortDirection])
    case TenancyCardTableColumnId.wault:
      return orderByFallback(rows, ['waultBreak.value'], [sortDirection])
    case TenancyCardTableColumnId.contractedRent:
      return orderBy(rows, ['annualizedRent.value', dealNameAccessor], [sortDirection, 'asc'])
    default:
      return orderByDefault(rows, [dealNameAccessor])
  }
}

const sortPropertyRows = ({ rows, activeSorting }) => {
  const columnKey = activeSorting.columnKey
  const sortDirection = getSortDirectionEntries(activeSorting)
  const cityNameAccessor = (row) => row.property?.address?.cityName?.toLowerCase()
  const propertyNameAccessor = (row) => row.property?.description?.toLowerCase()
  const financingStatusSortAccessor = (row) =>
    getFinancingStatusOrdering(row.property?.financingStatusCode)
  const dealCountAccessor = (row) => row.deals.length
  const dealsNameAccessor = (row) => {
    if (row.deals.length > 1) {
      return ''
    }
    return row.deals[0]?.name.toLowerCase()
  }
  // sort deals sub-properties
  const sortedDealRows = rows.map((row) => ({
    ...row,
    deals: orderBy(row.deals, (deal) => deal?.name?.toLowerCase(), ['asc']),
  }))
  switch (columnKey) {
    case TenancyCardTableColumnId.area:
      return orderByFallback(sortedDealRows, ['rentedArea.value'], [sortDirection])
    case TenancyCardTableColumnId.city:
      return orderByFallback(sortedDealRows, [cityNameAccessor], [sortDirection])
    case TenancyCardTableColumnId.deal:
      return orderByFallback(
        sortedDealRows,
        [dealCountAccessor, dealsNameAccessor],
        [sortDirection, sortDirection],
      )
    case TenancyCardTableColumnId.financingStatus:
      return orderByFallback(sortedDealRows, [financingStatusSortAccessor], [sortDirection])
    case TenancyCardTableColumnId.pieces:
      return orderByFallback(sortedDealRows, ['rentedAreaPieces.value'], [sortDirection])
    case TenancyCardTableColumnId.property:
      return orderByFallback(sortedDealRows, [propertyNameAccessor], [sortDirection])
    case TenancyCardTableColumnId.wault:
      return orderByFallback(sortedDealRows, ['waultBreak.value'], [sortDirection])
    case TenancyCardTableColumnId.contractedRent:
      return orderBy(
        sortedDealRows,
        ['annualizedRent.value', propertyNameAccessor],
        [sortDirection, 'asc'],
      )
    default:
      return orderByDefault(sortedDealRows, [propertyNameAccessor])
  }
}

const sortSegmentUsageTypeRows = ({ rows, activeSorting }) => {
  const columnKey = activeSorting.columnKey
  const sortDirection = getSortDirectionEntries(activeSorting)
  const segmentNameAccessor = (row) => row.segmentUsageType.name.toLowerCase()
  switch (columnKey) {
    case TenancyCardTableColumnId.area:
      return orderByFallback(rows, ['rentedArea.value'], [sortDirection])
    case TenancyCardTableColumnId.pieces:
      return orderByFallback(rows, ['rentedAreaPieces.value'], [sortDirection])
    case TenancyCardTableColumnId.property:
      return orderByFallback(rows, [segmentNameAccessor], [sortDirection])
    case TenancyCardTableColumnId.wault:
      return orderByFallback(rows, ['waultBreak.value'], [sortDirection])
    case TenancyCardTableColumnId.contractedRent:
      return orderBy(rows, ['annualizedRent.value', segmentNameAccessor], [sortDirection, 'asc'])
    default:
      return orderByDefault(rows, [segmentNameAccessor])
  }
}

export const sortAsTenantTableData = ({ tableData, activeSorting }) => {
  if (isNil(tableData)) {
    return tableData
  }
  if (tableData.length === 0) {
    return tableData
  }
  const withSortedSubRows = tableData.map((row) => {
    if (isNil(row.subRows)) {
      return row
    }
    return { ...row, subRows: sortAsTenantTableData({ tableData: row.subRows, activeSorting }) }
  })
  const rowType = tableData[0].rowType
  switch (rowType) {
    case TenancyTableRowType.deal:
      return sortDealRows({ rows: withSortedSubRows, activeSorting })
    case TenancyTableRowType.financingStatus:
      return sortFinancingStatusRows({ rows: withSortedSubRows, activeSorting })
    case TenancyTableRowType.property:
      return sortPropertyRows({ rows: withSortedSubRows, activeSorting })
    case TenancyTableRowType.segment:
      return sortSegmentUsageTypeRows({ rows: withSortedSubRows, activeSorting })
    default:
      return tableData
  }
}
