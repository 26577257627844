import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useGetMultipleRequirements = ({ conditionIds, options = {} }) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: conditionIds.map((conditionId) => {
        const urlSearchParams = new URLSearchParams()
        urlSearchParams.append('offset', '0')
        urlSearchParams.append('limit', '1')
        urlSearchParams.append('sort_by', 'requirement_creation_date')
        urlSearchParams.append('order_by', 'asc')
        urlSearchParams.append('condition_uuid', conditionId)

        return {
          path: `/conditions/external/requirements?${urlSearchParams.toString()}`,
          translated: true,
          useCache: true,
          keys: ['conditions', 'external', conditionId, 'requirements'],
          options: {
            retry: 1,
            ...options,
          },
        }
      }),
    }),
  )

  return useMemo(
    () => ({
      isLoading: responses?.some(({ isLoading }) => isLoading),
      isError: responses?.some(({ isError }) => isError),
      isFetching: responses?.some(({ isFetching }) => isFetching),
      data: responses.map((response) => ({
        ...response,
        data: {
          ...camelize(response.data),
        },
      })),
    }),
    [responses],
  )
}

export default useGetMultipleRequirements
