import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useUpdatePropertyExternalId = ({ onSuccess, onError }) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(
    async ({ propertyUuid, originalId, originalTypeCode, updatedExternalId }) => {
      const { data } = await patch({
        path: `/properties/${propertyUuid}/external-ids/${originalId}/${originalTypeCode}`,
        body: {
          id: updatedExternalId.id,
          type: updatedExternalId.type,
        },
      })
      return camelize(data)
    },
    { onSuccess, onError },
  )
}
