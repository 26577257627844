import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useMMyyyy from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/general-information/useMMyyyy'
import CustomFieldsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/critical-facility/shared/CustomFieldsTile'

const propTypes = {
  tileId: PropTypes.string.isRequired,
}
/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const CriticalFacilityTile = ({ tileId }) => {
  const {
    followUpDateFieldName,
    orderedAndFilteredFieldMetadata,
    orderedAndFilteredCustomFieldData,
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId].data)

  const { format: formatMMyyyy, pattern } = useMMyyyy()

  /** @typedef {NonNullable<Parameters<typeof CustomFieldsTile>[0]['refineFieldDefinitions']>[string]} refineFieldDefinition */

  const refineFollowUpDateField = followUpDateFieldName
    ? {
        [followUpDateFieldName]:
          /** @type {refineFieldDefinition} */
          (fieldDefinition) => ({
            ...fieldDefinition,
            editComponentProps: {
              formatPattern: pattern,
            },
            value: formatMMyyyy(fieldDefinition.rawValue),
            formattedValue: formatMMyyyy(fieldDefinition.rawValue) || '-',
          }),
      }
    : {}

  return (
    <CustomFieldsTile
      customFields={orderedAndFilteredCustomFieldData ?? []}
      config={orderedAndFilteredFieldMetadata ?? []}
      refineFieldDefinitions={{
        ...refineFollowUpDateField,
      }}
      fieldDefinitionOverrides={{
        isShownInDisplay: true,
        isShownInEdit: false,
      }}
      cardHeaderTitle=""
      saveChanges={() => {}}
    />
  )
}

CriticalFacilityTile.propTypes = propTypes

export default CriticalFacilityTile
