import { array, bool, string } from 'prop-types'
import { DealCashflowAnalyticalTable } from 'components/domains/deals/deal-cashflow/analytical-table/DealCashflowAnalyticalTable'
import { calculateRowHeightDisplayMode } from 'components/domains/deals/deal-cashflow/parameters/calculateRowHeight'
import { useCashflowParametersColumns } from 'components/domains/deals/deal-cashflow/parameters/useCashflowParametersColumns'
import styles from 'components/domains/deals/deal-cashflow/used-parameters/DealCashflowUsedParametersTable.module.css'

export const DealCashflowUsedParametersTable = ({
  data = [],
  config = [],
  titleText,
  isPdfViewOnDecisionPaper = false,
}) => {
  const columns = useCashflowParametersColumns(isPdfViewOnDecisionPaper)
  let nrOfEntries = 0

  const reduceParameters = (parameters, parameterConfig) =>
    parameters.reduce(
      (result, { cashflowParameterDefinition, cashflowParameterUnit, value }) => {
        const { decimalPlaces } =
          parameterConfig
            ?.find(({ ccpdId }) => ccpdId === cashflowParameterDefinition.ccpdId)
            ?.units?.find(({ ccpuId }) => ccpuId === cashflowParameterUnit.ccpuId) ?? {}
        result.parameter.push({ label: cashflowParameterDefinition.name })
        result.value.push({ value, unit: cashflowParameterUnit.name, decimalPlaces })
        return result
      },
      { parameter: [], value: [] },
    )

  const mapRow = ({ objectName, objectDefinition, parameters }) => {
    nrOfEntries++
    return {
      name: objectName,
      type: objectDefinition.name,
      rowHeight: calculateRowHeightDisplayMode(parameters),
      ...reduceParameters(parameters, config),
    }
  }

  const tableData = data.map((item) => {
    const rowObject = mapRow(item)
    rowObject.subRows = item.children?.map(mapRow)

    return rowObject
  })

  return (
    <DealCashflowAnalyticalTable
      className={styles['used-parameters-table']}
      title={titleText}
      columns={columns}
      data={tableData}
      nrOfEntries={nrOfEntries}
      visibleRows={nrOfEntries}
      customRowHeight
      isPdfViewOnDecisionPaper={isPdfViewOnDecisionPaper}
    />
  )
}

DealCashflowUsedParametersTable.propTypes = {
  data: array.isRequired,
  config: array.isRequired,
  titleText: string.isRequired,
  isPdfViewOnDecisionPaper: bool,
}
