import isNil from 'lodash.isnil'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useVisibilities = ({ entityType, includeDeprecated } = {}) => {
  const queryParams = new URLSearchParams()
  if (entityType) {
    queryParams.append('entity_type', entityType)
  }
  if (!isNil(includeDeprecated)) {
    queryParams.append('include_deprecated', includeDeprecated)
  }
  return useCamelizedResponse(
    useRequest({
      path: `/conditions/configurations/external-condition-visibilities?${queryParams}`,
      useCache: true,
      translated: true,
      keys: [
        'conditions',
        'configurations',
        'external',
        'visibilities',
        entityType,
        includeDeprecated,
      ],
    }),
  )
}

export default useVisibilities
