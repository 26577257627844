import { useMemo } from 'react'

const useCurrencyHelper = ({
  mappedTranchesValues,
  mappedSyndicationValues,
  mappedOtherSources,
  mappedUsesWithShare,
}) => {
  const availableCurrencies = useMemo(() => {
    const currenciesSet = new Set()

    if (mappedTranchesValues) {
      currenciesSet.add(mappedTranchesValues.original.currency)
    }

    mappedSyndicationValues?.forEach(({ original }) => {
      if (original?.currency) {
        currenciesSet.add(original.currency)
      }
    })

    mappedUsesWithShare?.forEach(({ original }) => {
      if (original?.currency) {
        currenciesSet.add(original.currency)
      }
    })

    mappedOtherSources?.forEach(({ original }) => {
      if (original?.currency) {
        currenciesSet.add(original.currency)
      }
    })

    return Array.from(currenciesSet)
  }, [mappedTranchesValues, mappedSyndicationValues, mappedOtherSources, mappedUsesWithShare])

  const areAllCurrenciesTheSame = useMemo(
    () => availableCurrencies.length <= 1,
    [availableCurrencies],
  )

  return useMemo(
    () => ({
      availableCurrencies,
      areAllCurrenciesTheSame,
    }),
    [availableCurrencies, areAllCurrenciesTheSame],
  )
}

export default useCurrencyHelper
