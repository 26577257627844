import isNil from 'lodash.isnil'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import { setTileMetadata } from 'redux/slices/decision-paper/tilesOverviewSlice'

const useAnnualReviewDealOverviewSelectedProperty = ({
  propertiesData = [],
  propertyMetadataTileCode,
}) => {
  const dispatch = useDispatch()

  const {
    uuid: preSelectedPropertyUuid,
    description: preSelectedPropertyName,
    id: preSelectedPropertyDisplayId,
  } = propertiesData?.[0] ?? {}

  const propertyMetadata = useSelector(
    (state) => state.decisionPaper.tilesOverview.tileMetadata?.[propertyMetadataTileCode],
  )

  const dealMetadata = useSelector(
    (state) =>
      state.decisionPaper.tilesOverview.tileMetadata?.[
        availableAutomaticTiles.annualReviewDealSelection
      ],
  )

  // since we do not have a single shared property select tile (like for deals),
  // we set a default for the metadata, in case there is none, in this hook.
  useEffect(() => {
    if (isNil(propertyMetadata) && !isNil(preSelectedPropertyUuid)) {
      dispatch(
        setTileMetadata({
          tileCode: propertyMetadataTileCode,
          metadata: {
            selectedPropertyUuid: preSelectedPropertyUuid,
            selectedPropertyName: preSelectedPropertyName,
            selectedPropertyDisplayId: preSelectedPropertyDisplayId,
          },
        }),
      )
    }
  }, [
    dispatch,
    preSelectedPropertyUuid,
    preSelectedPropertyName,
    preSelectedPropertyDisplayId,
    propertyMetadata,
    propertyMetadataTileCode,
  ])

  // if a different deal is selected, we also need to reset the property metadata
  useEffect(() => {
    if (!isNil(propertyMetadata) && !isNil(preSelectedPropertyUuid)) {
      dispatch(
        setTileMetadata({
          tileCode: propertyMetadataTileCode,
          metadata: {
            selectedPropertyUuid: preSelectedPropertyUuid,
            selectedPropertyName: preSelectedPropertyName,
            selectedPropertyDisplayId: preSelectedPropertyDisplayId,
          },
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dealMetadata, // this is important!
  ])

  const propertyIndex = propertiesData
    ?.map((property) => property?.uuid)
    .indexOf(propertyMetadata?.selectedPropertyUuid)

  return isNil(propertyMetadata)
    ? {
        selectedPropertyUuid: preSelectedPropertyUuid,
        selectedPropertyName: preSelectedPropertyName,
        selectedPropertyDisplayId: preSelectedPropertyDisplayId,
        selectedPropertyIndex: 0,
      }
    : { ...propertyMetadata, selectedPropertyIndex: propertyIndex }
}

export default useAnnualReviewDealOverviewSelectedProperty
