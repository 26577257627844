import { useCallback, useEffect, useState, useRef, useMemo } from 'react'

export const ANIMATION_DURATION = 1500 // duration of the animation in ms
export const ANIMATION_DELAY = 0 // time after which the animation starts in ms

const useChartAnimation = (shouldAnimate = true) => {
  const [disableAnimation, setDisableAnimation] = useState(false)

  const isAnimationActive = shouldAnimate && !disableAnimation

  // fixes a bug in recharts where sometimes the animation end callback
  // on the bars is not called, which causes the bar labels to not appear
  // although the animation has actually finished. We simply disable component
  // animations after they have finished anyway, which makes the labels appear.
  const disableAnimationTimeoutRef = useRef(null)

  const handleAnimationStart = useCallback(() => {
    if (!disableAnimationTimeoutRef.current) {
      disableAnimationTimeoutRef.current = setTimeout(() => {
        setDisableAnimation(true)
      }, ANIMATION_DELAY + ANIMATION_DURATION)
    }
  }, [])

  useEffect(
    () => () => {
      clearTimeout(disableAnimationTimeoutRef.current)
      disableAnimationTimeoutRef.current = null
    },
    [],
  )

  return useMemo(
    () => ({
      isAnimationActive,
      animationDuration: ANIMATION_DURATION,
      animationBegin: ANIMATION_DELAY,
      onAnimationStart: handleAnimationStart,
    }),
    [handleAnimationStart, isAnimationActive],
  )
}

export default useChartAnimation
