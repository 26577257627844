import { ObjectPageSection } from '@fioneer/ui5-webcomponents-react'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CustomAssessments from 'components/domains/custom-assessments/CustomAssessments'
import { useDealWorkingVersionToolbarButtons } from 'components/domains/deals/deal-adjustment/useDealWorkingVersionToolbarButtons'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useCustomAssessmentsHelper from 'hooks/services/central-data/custom-assessments/useCustomAssessmentsHelper'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'
import DealGeneralInformationOverviewTab from 'routes/deals/general-information/DealGeneralInformationOverviewTab'

const assessmentPage = 'GENERAL_INFORMATION'

const DealGeneralInformation = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals' })
  const {
    deal: { dealUuid },
  } = useContext(DealContext)
  const { toggleWorkingVersionButton, manageWorkingVersionButton } =
    useDealWorkingVersionToolbarButtons()

  const {
    isFetching: isCustomAssessmentsFetching,
    isError: isCustomAssessmentsError,
    showCustomAssessments,
  } = useCustomAssessmentsHelper({
    entityType: cwpEntityTypes.DEAL,
    page: assessmentPage,
  })

  const breadcrumb = useMemo(
    () => ({
      text: t('general-information'),
    }),
    [t],
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [dealUuid],
  )

  const headerActions = createSegmentedHeaderActions(
    [toggleWorkingVersionButton, manageWorkingVersionButton],
    [<CreateDealEventAction key="create-event-action" />, markFavoriteAction],
  )

  const showCustomAssessmentsSubsection = useMemo(
    () => showCustomAssessments || isCustomAssessmentsFetching || isCustomAssessmentsError,
    [showCustomAssessments, isCustomAssessmentsFetching, isCustomAssessmentsError],
  )

  const customAssessmentsSubsection = useMemo(() => {
    if (!showCustomAssessmentsSubsection) {
      return
    }
    return (
      <CustomAssessments
        entityId={dealUuid}
        entityType={cwpEntityTypes.DEAL}
        page={assessmentPage}
      />
    )
  }, [dealUuid, showCustomAssessmentsSubsection])

  const renderPageContent = useCallback(() => {
    if (!showCustomAssessmentsSubsection) {
      return (
        <DealDetailsWrapper
          isTabPage={false}
          additionalProperties={{ includeUrlQuery: true }}
          childBreadcrumbs={breadcrumb}
          actions={headerActions}
        >
          <DealGeneralInformationOverviewTab />
        </DealDetailsWrapper>
      )
    }
    return (
      <DealDetailsWrapper
        isTabPage
        additionalProperties={{ includeUrlQuery: true, defaultSectionId: 'overview-tab' }}
        childBreadcrumbs={breadcrumb}
        actions={headerActions}
      >
        <ObjectPageSection id={'overview-tab'} titleText={t('general-information.overview-tab')}>
          <DealGeneralInformationOverviewTab />
        </ObjectPageSection>
        <ObjectPageSection
          id={'assessments-tab'}
          titleText={t('general-information.assessments-tab')}
        >
          {customAssessmentsSubsection}
        </ObjectPageSection>
      </DealDetailsWrapper>
    )
  }, [breadcrumb, customAssessmentsSubsection, headerActions, showCustomAssessmentsSubsection, t])

  return <>{renderPageContent()}</>
}

export default DealGeneralInformation
