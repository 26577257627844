import find from 'lodash.find'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useMultipleDealsById } from 'hooks/services/deals/useMultipleDealsById'
import { useMultipleDealsByUuidMini } from 'hooks/services/deals/useMultipleDealsByUuidMini'
import useCombinedUserFavoritesAndRecentVisits from 'hooks/services/user-profile/useCombinedUserFavoritesAndRecentVisits'
import { getUniqueIds } from 'utils/userFavoriteUtils'

const someAreLoading = (requests) => requests.some((req) => req.isLoading)

const requestTypes = {
  NORMAL: 'normal',
  MINI: 'mini',
}

const mapDealToFavoriteAndRecentVisit = (favsAndRecVisits = [], deals = []) =>
  favsAndRecVisits.map((favAndRecVisit) => {
    const foundDeals = deals.filter((deal) => deal.dealUuid === favAndRecVisit.entityId)
    const foundDeal = find(foundDeals, (deal) => deal.requestType === requestTypes.NORMAL)
    const foundDealMini = find(foundDeals, (deal) => deal.requestType === requestTypes.MINI)

    if (!foundDealMini && !foundDeal) {
      return {
        ...favAndRecVisit,
        notFound: true,
      }
    }

    if (!foundDeal) {
      return {
        ...favAndRecVisit,
        ...foundDealMini,
        notFound: false,
      }
    }

    return {
      ...favAndRecVisit,
      ...foundDeal,
      notFound: false,
    }
  })

const accessDealDataAndMapRequestType = (requests, requestType) =>
  requests.filter(({ data }) => !!data).map(({ data }) => ({ ...data, requestType }))

const useGetAndEnrichFavoritesAndRecentVisitsWithDeals = () => {
  const getDealFavsAndRecVisits = useCombinedUserFavoritesAndRecentVisits({
    entityType: cwpEntityTypes.DEAL,
  })
  const unqiqueDealIds = getUniqueIds(getDealFavsAndRecVisits.data)
  const reqOptions = { retry: false }
  const getAllDealsMini = useMultipleDealsByUuidMini(unqiqueDealIds, reqOptions)
  const getAllDeals = useMultipleDealsById(unqiqueDealIds, reqOptions)

  const isLoading =
    getDealFavsAndRecVisits.isLoading ||
    someAreLoading(getAllDeals) ||
    someAreLoading(getAllDealsMini)
  const isError = getDealFavsAndRecVisits.isError
  const data =
    isLoading || isError
      ? undefined
      : mapDealToFavoriteAndRecentVisit(getDealFavsAndRecVisits.data, [
          ...accessDealDataAndMapRequestType(getAllDeals, requestTypes.NORMAL),
          ...accessDealDataAndMapRequestType(getAllDealsMini, requestTypes.MINI),
        ])

  return {
    isLoading,
    isError,
    data,
  }
}

export default useGetAndEnrichFavoritesAndRecentVisitsWithDeals
