import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Option,
  Select,
  TextArea,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/handover/general-deal-information/HandoverCoverPoolStatusTileContentEdit.module.css'
import {
  IN_COVERPOOL,
  NOT_IN_COVERPOOL,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/handover/general-deal-information/constants'

const HandoverCoverPoolStatusTileContentEdit = ({ onChange, content }) => {
  const textAreaRef = useRef(null)

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.handover.coverpool-status',
  })

  const handleOnCoverpoolStatusSelectChange = useCallback(
    (event) => {
      const newValue = event.detail.selectedOption.value
      onChange({ ...content, coverpoolStatus: newValue })
    },
    [content, onChange],
  )

  const handleOnCommentChange = useCallback(
    ({ target: { value: newValue } }) => {
      onChange({ ...content, comment: newValue })
    },
    [content, onChange],
  )

  const CoverpoolStatusSelect = () => (
    <Select onChange={handleOnCoverpoolStatusSelectChange} className={styles.editComponent}>
      <Option value={undefined} selected={isNil(content.coverpoolStatus)}>
        {''}
      </Option>
      <Option value={IN_COVERPOOL} selected={content.coverpoolStatus === IN_COVERPOOL}>
        {t('coverpool-status.inCoverpool')}
      </Option>
      <Option value={NOT_IN_COVERPOOL} selected={content.coverpoolStatus === NOT_IN_COVERPOOL}>
        {t('coverpool-status.notInCoverpool')}
      </Option>
    </Select>
  )

  // Fixes error "Cannot read properties of null (reading 'querySelector')" from ui5's TextArea._onfocusout
  // In this case no, shadow-root is available so undefined is used as fallback instead and checked by lodash.isNil
  // Otherwise, functionality is the same.
  useEffect(() => {
    if (typeof textAreaRef.current._onfocusout === 'function') {
      textAreaRef.current._onfocusout = (e) => {
        const eTarget = e?.relatedTarget
        const focusedOutToValueStateMessage =
          eTarget?.shadowRoot?.querySelector('.ui5-valuestatemessage-root') ?? undefined
        textAreaRef.current.focused = false
        if (!isNil(focusedOutToValueStateMessage)) {
          textAreaRef.current._openValueStateMsgPopover = false
        }
      }
    }
  }, [textAreaRef?.current?._onfocusout])

  const CommentFieldTextArea = () => (
    <TextArea
      data-testid={'comment-field-text-area'}
      ref={textAreaRef}
      className={styles.editComponent}
      value={content.comment}
      onChange={handleOnCommentChange}
    />
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.wrapper}>
      <FlexBox
        direction={FlexBoxDirection.Row}
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        alignItems={FlexBoxAlignItems.Center}
        className={styles.row}
      >
        <Label>{t('coverpool-status.label')}</Label>
        <CoverpoolStatusSelect />
      </FlexBox>
      <FlexBox
        direction={FlexBoxDirection.Row}
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        alignItems={FlexBoxAlignItems.Center}
        className={styles.row}
      >
        <Label>{t('comment.label')}</Label>
        <CommentFieldTextArea />
      </FlexBox>
    </FlexBox>
  )
}

HandoverCoverPoolStatusTileContentEdit.propTypes = {
  onChange: PropTypes.func.isRequired,
  content: PropTypes.shape({
    coverpoolStatus: PropTypes.string,
    comment: PropTypes.string,
  }),
}

export default HandoverCoverPoolStatusTileContentEdit
