import { Button, MessageBoxActions, MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import DialogPrimaryButton from 'components/ui/dialog/DialogPrimaryButton'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import { ConfigContext } from 'hooks/config/useConfig'
import { stringTemplate } from 'utils/stringTemplate'

const OpenInBcaButton = ({ externalAccountNumber, bankArea }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.financing.bank-customer-accounts.open-bca-button',
  })
  const { t: tNoPrefix } = useTranslation()

  const config = useContext(ConfigContext)

  const linkTemplate = config?.deals?.linktobca ?? ''
  const link = stringTemplate(linkTemplate, { externalAccountNumber, bankArea })
  const showMessageBox = useShowMessageBox()

  const onConfirm = () => {
    window.open(link, '_blank')
  }

  const handleClick = () => {
    showMessageBox(
      {
        type: MessageBoxTypes.Information,
        titleText: t('message-box.title'),
        children: t('message-box.text'),
        actions: [
          <DialogPrimaryButton key="button-confirm" onClick={onConfirm}>
            {tNoPrefix('buttons.ok')}
          </DialogPrimaryButton>,
          MessageBoxActions.Cancel,
        ],
      },
      document.body,
    )
  }

  return <Button onClick={handleClick}>{t('title')}</Button>
}

OpenInBcaButton.propTypes = {
  externalAccountNumber: PropTypes.string.isRequired,
  bankArea: PropTypes.string.isRequired,
}

export default OpenInBcaButton
