import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Table,
  TableColumn,
  TableRow,
  TableCell,
  Label,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import sortBy from 'lodash.sortby'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { MAX_COLLAPSED_COMMENT_LENGTH } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche/financingConstants'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/OpsMemoTrancheDetailsCard.module.css'
import TextShowMoreWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/TextShowMoreWrapper'
import {
  useNumberFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const OpsMemoTrancheInterestConditionsCard = ({ tranche }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.ops-memo-tranche-details.interest-conditions',
  })
  const { t: tTable } = useTranslation('decisionPaper', {
    keyPrefix: 'components.table',
  })
  const formatPercentage = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })
  const { format: formatDate } = useShortDateFormatter()

  const items = tranche?.interestConditions?.items
  const comment = tranche?.interestConditions?.comment

  const renderTableColumn = (title) => (
    <TableColumn>
      <div className={styles.tableCellPadding}>{title}</div>
    </TableColumn>
  )

  const renderTableCell = (value) => (
    <TableCell className={styles.tableCellPadding}>{value}</TableCell>
  )

  const renderTableColumns = () => (
    <>
      {renderTableColumn(t('interest-rate-type-code'))}
      {renderTableColumn(t('valid-from'))}
      {renderTableColumn(t('payment-frequency'))}
      {renderTableColumn(t('interest-index-name'))}
      {renderTableColumn(t('reference-rate'))}
      {renderTableColumn(t('customer-margin'))}
      {renderTableColumn(t('all-in-interest-rate'))}
      {renderTableColumn(t('calculation-method'))}
    </>
  )

  const renderTableRows = () =>
    items?.length > 0 &&
    sortBy(items, 'validFrom')?.map((item) => (
      <TableRow key={item?.id}>
        {renderTableCell(item?.interestRateTypeCode)}
        {renderTableCell(item?.validFrom && formatDate(item.validFrom))}
        {renderTableCell(item?.paymentFrequency)}
        {renderTableCell(item?.interestIndexName)}
        {renderTableCell(item?.referenceRate)}
        {renderTableCell(!isNil(item?.customerMargin) && formatPercentage(item.customerMargin))}
        {renderTableCell(
          !isNil(item?.allInInterestRate) && formatPercentage(item.allInInterestRate),
        )}
        {renderTableCell(item?.calculationMethod)}
      </TableRow>
    ))
  const renderCommentSection = () => (
    <FlexBox
      fitContainer
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      className={styles.commentWrapper}
    >
      <Label>{t('comment')}</Label>
      <div className={styles.commentText}>
        <FlexBox
          direction={FlexBoxDirection.Column}
          justifyContent={FlexBoxJustifyContent.Center}
          alignItems={FlexBoxAlignItems.End}
        >
          {comment && (
            <TextShowMoreWrapper
              text={comment}
              characterLimit={MAX_COLLAPSED_COMMENT_LENGTH}
              shouldFormatHtml={true}
            />
          )}
        </FlexBox>
      </div>
    </FlexBox>
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.card}>
      <Text className={styles.cardHeaderTitle}>
        {t('interest-conditions')} {`(${items?.length})`}
      </Text>

      <FlexBox direction={FlexBoxDirection.Column} className={styles.tableWrapper}>
        <Table
          columns={renderTableColumns()}
          noDataText={tTable('no-data')}
          className={styles.table}
        >
          {renderTableRows()}
        </Table>
        {renderCommentSection()}
      </FlexBox>
    </FlexBox>
  )
}

OpsMemoTrancheInterestConditionsCard.propTypes = {
  tranche: PropTypes.shape({
    interestConditions: PropTypes.shape({
      comment: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          interestRateTypeCode: PropTypes.string,
          validFrom: PropTypes.string,
          paymentFrequency: PropTypes.string,
          interestIndexName: PropTypes.string,
          referenceRate: PropTypes.string,
          customerMargin: PropTypes.number,
          allInInterestRate: PropTypes.number,
          calculationMethod: PropTypes.string,
        }),
      ),
    }),
  }),
}

export default OpsMemoTrancheInterestConditionsCard
