import { Tab, TabContainer, TabLayout, TabsOverflowMode } from '@fioneer/ui5-webcomponents-react'
import { addCustomCSSWithScoping } from '@fioneer/ui5-webcomponents-react/dist/internal/addCustomCSSWithScoping'
import isNil from 'lodash.isnil'
import { lazy, Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useSearchParams } from 'react-router-dom'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import LazyLoadingFallbackCard from 'components/ui/card/LazyLoadingFallbackCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Page from 'components/ui/page/Page'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import {
  GCC_UNIT_ID,
  REVIEW_UNIT_ID,
  STANDALONE_BP_UNIT_ID,
} from 'hooks/services/business-partners/authority-level-calculator/constants'
import useAuthorityLevelCalculatorList from 'hooks/services/business-partners/authority-level-calculator/useAuthorityLevelCalculatorList'
import lazyRetry from 'lazyRetry'
import styles from 'routes/business-partners/BusinessPartnerAuthorityLevelCalculatorPage.module.css'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import paths from 'routes/business-partners/paths'

const AuthorityLevelCalculatorCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AuthorityLevelCalculatorCard" */
        'components/domains/business-partners/tile/authority-level-calculator/AuthorityLevelCalculatorCard'
      ),
    'AuthorityLevelCalculatorCard',
  ),
)
const AuthorityLevelCalculatorCommentCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AuthorityLevelCalculatorCommentCard" */
        'components/domains/business-partners/tile/authority-level-calculator/AuthorityLevelCalculatorCommentCard'
      ),
    'AuthorityLevelCalculatorCommentCard',
  ),
)
const AuthorityLevelCalculatorLargeExposureCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AuthorityLevelCalculatorLargeExposureCard" */
        'components/domains/business-partners/tile/authority-level-calculator/AuthorityLevelCalculatorLargeExposureCard'
      ),
    'AuthorityLevelCalculatorLargeExposureCard',
  ),
)

const BusinessPartnerAuthorityLevelCalculatorPage = () => {
  addCustomCSSWithScoping(
    'ui5-tabcontainer',
    `
    :host .ui5-tc__header {
      padding: 0;
      background-color: unset;
      box-shadow: unset;
      margin-left: -16px;
    }
    `,
  )

  const businessPartner = useContext(BusinessPartnerContext)
  const { t } = useTranslation()

  const [selectedTab, setSelectedTab] = useState()
  const [selectedUnit, setSelectedUnit] = useState()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data, isLoading, isError } = useAuthorityLevelCalculatorList(businessPartner?.id)

  const businessPartnerFound = () => Boolean(businessPartner?.id)

  const getSelectedUnit = useCallback(
    (unitTypeId, unitHeadBpId) => {
      if (!unitTypeId || !unitHeadBpId) {
        searchParams.delete('unitTypeId')
        searchParams.delete('unitHeadBpId')
        setSearchParams(searchParams)
        return data.gcc[0]
      }

      const gccUnit = data.gcc.find(
        (unit) => unit.unitTypeId === unitTypeId && unit.unitHeadBpId === unitHeadBpId,
      )

      const reviewUnit = data.review.find(
        (unit) => unit.unitTypeId === unitTypeId && unit.unitHeadBpId === unitHeadBpId,
      )

      const standaloneUnit = data.standalone.find(
        (unit) => unit.unitTypeId === unitTypeId && unit.unitHeadBpId === unitHeadBpId,
      )

      return gccUnit ?? reviewUnit ?? standaloneUnit
    },
    [data, searchParams, setSearchParams],
  )

  useEffect(() => {
    const unitTypeId = searchParams.get('unitTypeId')
    const unitHeadBpId = searchParams.get('unitHeadBpId')

    const setUnitAndTab = (unitId, unitData) => {
      setSelectedTab(unitId)
      setSelectedUnit(unitData)
    }

    if (unitTypeId && unitHeadBpId && !selectedUnit) {
      const preselectedUnit = getSelectedUnit(unitTypeId, unitHeadBpId)
      if (!isNil(preselectedUnit)) {
        setUnitAndTab(unitTypeId, preselectedUnit)
      }
    } else if ((!unitTypeId || !unitHeadBpId) && !selectedUnit) {
      if (data?.gcc.length > 0) {
        setUnitAndTab(GCC_UNIT_ID, data.gcc[0])
      } else if (data?.review.length > 0) {
        setUnitAndTab(REVIEW_UNIT_ID, data.review[0])
      } else if (data?.standalone.length > 0) {
        setUnitAndTab(STANDALONE_BP_UNIT_ID, data.standalone[0])
      }
    }
  }, [getSelectedUnit, searchParams, selectedUnit, data])

  useEffect(() => {
    const unitTypeId = searchParams.get('unitTypeId')
    const unitHeadBpId = searchParams.get('unitHeadBpId')

    if (unitTypeId || unitHeadBpId) {
      setSearchParams({ unitTypeId: unitTypeId, unitHeadBpId: unitHeadBpId })
    }
  }, [searchParams, setSearchParams])

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={businessPartner.id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [businessPartner.id],
  )

  const renderHeaderTitle = () => (
    <BusinessPartnerHeader
      breadcrumbs={[
        { text: t('navigation.item.title.business-partner-authority-level-calculator') },
      ]}
      actions={
        <>
          <CreateBusinessPartnerEventAction key="create-event-action" /> {markFavoriteAction}
        </>
      }
    />
  )

  const renderTile = () => (
    <>
      {businessPartnerFound() ? (
        <CWPLayout overview>
          <Suspense fallback={<LazyLoadingFallbackCard />}>
            <CWPLayout.Full>
              <AuthorityLevelCalculatorCard selectedUnit={selectedUnit} />
            </CWPLayout.Full>
          </Suspense>
          <Suspense fallback={<LazyLoadingFallbackCard />}>
            <CWPLayout.Full>
              <AuthorityLevelCalculatorCommentCard selectedUnit={selectedUnit} />
            </CWPLayout.Full>
          </Suspense>
          <Suspense fallback={<LazyLoadingFallbackCard />}>
            <CWPLayout.Half>
              <AuthorityLevelCalculatorLargeExposureCard selectedUnit={selectedUnit} />
            </CWPLayout.Half>
          </Suspense>
        </CWPLayout>
      ) : (
        <>
          {t('pages.business-partner.not-found')}{' '}
          {<NavLink to={paths.businessPartners}>{t('pages.business-partner.search')}</NavLink>}
        </>
      )}
    </>
  )

  const renderContent = () =>
    businessPartnerFound() ? (
      <>
        <TabContainer
          tabsOverflowMode={TabsOverflowMode.Scrollable}
          tabLayout={TabLayout.Inline}
          collapsed={true}
          fixed={true}
          className={styles.tabContainer}
          onTabSelect={(e) => {
            const unitTypeId = e?.detail?.tab?.getAttribute('id')
            const unitHeadBpId = e?.detail?.tab?.getAttribute('unitHeadBpId')
            const unit = getSelectedUnit(unitTypeId, unitHeadBpId)
            setSelectedUnit(unit)
            setSearchParams({ unitTypeId: unitTypeId, unitHeadBpId: unitHeadBpId })
          }}
        >
          {data.gcc.length > 0 && (
            <Tab
              data-testid={GCC_UNIT_ID}
              selected={selectedTab === GCC_UNIT_ID}
              subTabs={
                <>
                  {data.gcc.map((item) => (
                    <Tab
                      key={item.unitTypeId}
                      text={item.unitHeadBpFullName}
                      id={item.unitTypeId}
                      unitHeadBpId={item.unitHeadBpId}
                    />
                  ))}
                </>
              }
              text={t('pages.business-partner.authority-level-calculator.gcc-unit')}
            />
          )}
          {data.review.length > 0 && (
            <Tab
              data-testid={REVIEW_UNIT_ID}
              selected={selectedTab === REVIEW_UNIT_ID}
              subTabs={
                <>
                  {data.review.map((item) => (
                    <Tab
                      key={item.unitTypeId}
                      text={item.unitHeadBpFullName}
                      id={item.unitTypeId}
                      unitHeadBpId={item.unitHeadBpId}
                    />
                  ))}
                </>
              }
              text={t('pages.business-partner.authority-level-calculator.review-unit')}
            />
          )}
          {data.standalone.length > 0 && (
            <Tab
              data-testid={STANDALONE_BP_UNIT_ID}
              selected={selectedTab === STANDALONE_BP_UNIT_ID}
              subTabs={
                <>
                  {data.standalone.map((item) => (
                    <Tab
                      key={item.unitTypeId}
                      text={item.unitHeadBpFullName}
                      id={item.unitTypeId}
                      unitHeadBpId={item.unitHeadBpId}
                    />
                  ))}
                </>
              }
              text={t('pages.business-partner.authority-level-calculator.standalone-unit')}
            />
          )}
        </TabContainer>
        {renderTile()}
      </>
    ) : (
      <>
        {t('pages.business-partner.not-found')}
        {<NavLink to={paths.businessPartners}>{t('pages.business-partner.search')}</NavLink>}
      </>
    )

  return (
    <Page
      isError={isError}
      isLoading={isLoading}
      renderHeaderTitle={renderHeaderTitle}
      renderContent={renderContent}
      id="businessPartnerAuthorityLevelCalculatorPageId"
    />
  )
}

BusinessPartnerAuthorityLevelCalculatorPage.propTypes = {}

export default BusinessPartnerAuthorityLevelCalculatorPage
