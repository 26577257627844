import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'

const useMultipleTranchesByBusinessPartner = ({
  businessPartnerIds,
  onlyActiveTranches,
  dataSource = DATA_SOURCES.EXISTING_BUSINESS,
  options = {},
}) => {
  const queryParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams()

    if (dataSource) urlSearchParams.append('dataSource', dataSource)

    if (onlyActiveTranches) urlSearchParams.append('onlyActiveTranches', onlyActiveTranches)

    return urlSearchParams
  }, [dataSource, onlyActiveTranches])

  // HINT: `params.size` seems to return `undefined` even if there are params
  const paramsString = [...queryParams.keys()].length ? `?${queryParams.toString()}` : ''

  const responses = useRequestsMemo(
    useRequests({
      requests: businessPartnerIds.map((businessPartnerId) => ({
        path: `/financing-structures/business-partners/${businessPartnerId}/tranches${paramsString}`,
        keys: [
          'businesspartners',
          businessPartnerId,
          'tranches',
          'dataSource',
          dataSource,
          'onlyActiveTranches',
          onlyActiveTranches,
        ],
      })),
      useCache: true,
      options: {
        ...options,
        enabled: !isEmpty(businessPartnerIds),
      },
    }),
  )

  return useMemo(
    () =>
      responses.map((response, index) => ({
        ...response,
        data: {
          businessPartnerId: businessPartnerIds[index],
          ...camelize(response.data),
        },
      })),
    [businessPartnerIds, responses],
  )
}

export default useMultipleTranchesByBusinessPartner
