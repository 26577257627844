import {
  MessageStrip,
  MessageStripDesign,
  ObjectStatus,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DisplayAndEditCard from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/critical-facility/shared/DisplayAndEditCard'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/shared/i18n/useI18n'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/RiskIndicatorsGroupGeneralInformationDecisionPaper.module.css'

const propTypes = {
  tileId: PropTypes.string.isRequired,
}

const EMPTY_FIELD = '-'

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const RiskIndicatorsGroupGeneralInformationDecisionPaper = ({ tileId }) => {
  const {
    data: {
      group = undefined,
      statusField: statusFieldValues = [],
      criticalityField: criticalityFieldValues = [],
      isGroupCriticalityLowerThanIndicators = false,
      isNoData = true,
    } = {},
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const { t } = useTranslation('decisionPaper', {
    keyPrefix:
      'tiles.business-partner.risk-monitoring.risk-indicators.group-details.general-information',
  })
  const { format: formatDate } = useShortDateFormatter()

  const { groupName, nextCheckDate } = group ?? {}

  const criticalityWarningField = {
    name: 'criticalityWarning',
    label: '',
    customInfoComponent: (
      <MessageStrip
        className={styles.criticalityWarning}
        hideCloseButton
        design={MessageStripDesign.Warning}
      >
        {t('warning.banner.criticality')}
      </MessageStrip>
    ),
    isShownInDisplay: true,
    isShownInEdit: false,
  }

  const statusField = useMemo(
    () => [
      {
        ...statusFieldValues[0],
        formattedValue: <ObjectStatus {...statusFieldValues[0].statusProps} />,
      },
    ],
    [statusFieldValues],
  )

  const criticalityField = useMemo(
    () => [
      {
        ...criticalityFieldValues[0],
        formattedValue: <ObjectStatus {...criticalityFieldValues[0].statusProps} />,
        customValueState: isGroupCriticalityLowerThanIndicators
          ? {
              valueState: ValueState.Warning,
              valueStateMessage: <div>{t('warning.banner.criticality')}</div>,
            }
          : undefined,
      },
    ],
    [criticalityFieldValues, isGroupCriticalityLowerThanIndicators, t],
  )

  const groupNameField = {
    name: 'groupName',
    label: t('group-name'),
    value: groupName ?? EMPTY_FIELD,

    isShownInDisplay: true,
    isShownInEdit: false,
  }

  const nextCheckDateField = [
    {
      name: 'nextCheckDate',
      label: t('next-check-date'),
      value: nextCheckDate ? formatDate(nextCheckDate) : EMPTY_FIELD,
      isShownInDisplay: true,
      isShownInEdit: false,
    },
  ]

  const fieldDefinitions = [
    ...(isGroupCriticalityLowerThanIndicators ? [criticalityWarningField] : []),
    groupNameField,
    ...statusField,
    ...criticalityField,
    ...nextCheckDateField,
  ]

  return (
    <DisplayAndEditCard
      cardHeaderTitle=""
      saveChanges={() => {}}
      fieldDefinitions={fieldDefinitions}
      isLoading={false}
      isEmpty={isNoData}
      isError={false}
      isEditable={false}
      className={styles.card}
    />
  )
}

RiskIndicatorsGroupGeneralInformationDecisionPaper.propTypes = propTypes

export default RiskIndicatorsGroupGeneralInformationDecisionPaper
