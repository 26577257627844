import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import DecisionStageMinutesPage from 'components/domains/business-events-and-tasks/decision-stage/minutes/DecisionStageMinutesPage'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useDecisionStageMinutes from 'hooks/services/business-events-and-tasks/decision-process/stages/minutes/useDecisionStageMinutes'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionStageMinutesPageLoadingWrapper = ({ decisionStageId }) => {
  const {
    event: { id: eventId },
  } = useContext(BusinessEventsAndTasksContext)
  const {
    isError,
    isLoading,
    data: decisionStageMinutes,
  } = useDecisionStageMinutes({ eventId, decisionStageId })

  const renderContent = useCallback(
    () => (
      <DecisionStageMinutesPage
        decisionStageMinutes={decisionStageMinutes}
        decisionStageId={decisionStageId}
      />
    ),
    [decisionStageId, decisionStageMinutes],
  )
  return (
    <LoadingStateWrapper isLoading={isLoading} isError={isError} renderContent={renderContent} />
  )
}

DecisionStageMinutesPageLoadingWrapper.propTypes = {
  decisionStageId: PropTypes.string.isRequired,
}

export default DecisionStageMinutesPageLoadingWrapper
