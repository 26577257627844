import { useMemo } from 'react'
import { Navigate } from 'react-router'
import { useLocation } from 'react-router-dom'

/* Redirect component to redirect from /decision-stages/ to /decision-process/decision-stages/ 
This component makes sure that the route change is backwards compatible and the user is redirected to the correct path. 
Done as part https://fioneer.atlassian.net/browse/CWP-11499
*/
const DecisionStageRedirect = () => {
  const { pathname } = useLocation()

  const newPath = useMemo(
    () => pathname.replace('/decision-stages/', '/decision-process/decision-stages/'),
    [pathname],
  )

  return <Navigate replace to={newPath} />
}

export default DecisionStageRedirect
