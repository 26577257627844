import { WrappingType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import Link from 'components/ui/link/Link'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'
import { DealContext } from 'routes/deals/DealContext'
import paths from 'routes/paths'

const CONTRACT_CHECK = 'CONTRACT_CHECK'

const ConditionsCovenantCheckLink = ({
  covenantCheckId,
  covenantCheckName,
  covenantDefinitionCheckType,
}) => {
  const {
    entityRef: { entityId, entityType },
  } = useContext(ConditionsContext)
  const dealContext = useContext(DealContext)

  const isDeal = entityType === cwpEntityTypes.DEAL
  const dealId = dealContext?.deal?.displayId
  const periodicalCheckHref = `/${paths.businessPartners}/${entityId}/periodical-checks/${covenantCheckId}`
  const isCovenant = covenantDefinitionCheckType === CONTRACT_CHECK
  const covenantCheckPart = `covenants/covenant-checks/${covenantCheckId}`
  const covenantCheckHref = isDeal
    ? `/${paths.deals}/${dealId}/${covenantCheckPart}`
    : `/${paths.businessPartners}/${entityId}/${covenantCheckPart}`
  const href = isCovenant ? covenantCheckHref : periodicalCheckHref

  return (
    <Link href={href} wrappingType={WrappingType.Normal}>
      {covenantCheckName}
    </Link>
  )
}

ConditionsCovenantCheckLink.propTypes = {
  covenantCheckId: PropTypes.string,
  covenantCheckName: PropTypes.string,
  covenantDefinitionCheckType: PropTypes.string,
}

export default ConditionsCovenantCheckLink
