import {
  FlexBox,
  FlexBoxAlignItems,
  ObjectStatus,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/sponsor/AsSponsorCardTableCell.module.css'
import Unit from 'components/domains/business-partners/tile/sponsor/Unit'
import Entity from 'components/ui/data/Entity'
import PopoverCell from 'components/ui/tables/cells/PopoverCell'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import { STANDALONE_BP_UNIT_ID } from 'hooks/services/business-partners/unit-overview/constants'

// fix imprecise js floating point summation
// eslint-disable-next-line no-magic-numbers
const sum = (a, b, positions = 8) => {
  const factor = Math.pow(10, positions)
  return (a.toFixed(positions) * factor + b.toFixed(positions) * factor) / factor
}

const renderCell = (cell, row, formatMoney, isAggregated, t) => {
  const columnKey = cell.column.id

  const renderEntityCell = ({ isBorrower, openInNewTab }) => {
    const { isGrouped, value } = cell ?? {}
    let id, name, link

    if (isNil(value)) {
      // case necessary, because Entity cells return "-" for empty/undefined values
      return <></>
    } else if (isBorrower) {
      id = value
      name = isGrouped ? row.leafRows[0]?.original?.borrower?.name : row?.original?.borrower?.name
      link = `/business-partners/${id}/`
    } else {
      ;({ id, name } = value ?? {})
      link = `/deals/${id}/`
    }

    return (
      <FlexBox
        alignItems={FlexBoxAlignItems.Start}
        className={isGrouped ? styles.entityCellWithMargin : styles.entityCell}
      >
        <Entity
          id={id}
          name={name}
          link={link}
          openInNewTab={openInNewTab}
          className={`${styles.entityWrapper} ${isGrouped && styles.entityRowCountOffset}`}
          additionalClassNamesForLink={`sapInformativeColor ${styles.textWithOverflow}`}
        />
        {isGrouped && (
          <span className={styles.entityRowCount}>{` (${row?.leafRows?.length})`}</span>
        )}
      </FlexBox>
    )
  }

  const renderUnitsCell = () => {
    const { value } = cell ?? {}
    const bpId = row.original?.borrower?.id

    return (
      <PopoverCell
        linkText={t('units-label')}
        listItems={value
          ?.filter(({ id }) => id !== STANDALONE_BP_UNIT_ID)
          ?.map(({ id, name, headFullName }) => (
            <Unit key={id} bpId={bpId} id={id} type={name} head={{ name: headFullName }} />
          ))}
      />
    )
  }

  const renderCreationYearCell = () => {
    const { isGrouped, value } = cell ?? {}
    if (!isNil(value)) {
      return (
        <FlexBox alignItems={FlexBoxAlignItems.Start}>
          <Text className={isGrouped && styles.textBold}>{value}</Text>
          {isGrouped && <span className={styles.rowCount}>{` (${row?.leafRows?.length})`}</span>}
        </FlexBox>
      )
    }
  }

  const renderMoneyCell = () => {
    if (isAggregated) {
      const { leafRows } = row ?? {}
      const headquarterCurrencyCode =
        leafRows[0]?.original?.dealCommitment?.valueHeadquarter?.currency

      let sumCommitment = 0
      leafRows?.forEach((leafRow) => {
        const { amount: commitmentAmount } =
          leafRow?.original?.dealCommitment?.valueHeadquarter ?? {}
        if (commitmentAmount) {
          sumCommitment = sum(commitmentAmount, sumCommitment)
        }
      })

      if (sumCommitment) {
        return (
          <div className={styles.caretCursor}>
            {formatMoney(sumCommitment, headquarterCurrencyCode, {
              currencyDisplay: 'code',
            })}
          </div>
        )
      }
    }

    const { value, valueHeadquarter } = cell.value ?? {}
    if (isNil(value) || value?.amount === 0) {
      return <div>{'-'}</div>
    }

    return (
      <div>
        <div className={styles.caretCursor}>
          {formatMoney(value.amount, value.currency, {
            currencyDisplay: 'code',
          })}
        </div>
        {!isNil(valueHeadquarter) && valueHeadquarter?.currency !== value?.currency && (
          <div className={`${styles.textLabelColor} ${styles.caretCursor}`}>
            {formatMoney(valueHeadquarter?.amount, valueHeadquarter?.currency, {
              currencyDisplay: 'code',
            })}
          </div>
        )}
      </div>
    )
  }
  const renderStatusCell = () => {
    const { isGrouped, value } = cell ?? {}
    if (!isNil(value)) {
      return (
        <FlexBox alignItems={FlexBoxAlignItems.Start}>
          <ObjectStatus inverted state={value ? ValueState.Information : ValueState.None}>
            {value}
          </ObjectStatus>
          {isGrouped && <span className={styles.rowCount}>{` (${row?.leafRows?.length})`}</span>}
        </FlexBox>
      )
    }
  }

  const renderAccountManagerCell = () => <Text>{cell.value}</Text>

  switch (columnKey) {
    case 'borrower.id':
      return renderEntityCell({ isBorrower: true, openInNewTab: false })
    case 'deal':
      return renderEntityCell({ isBorrower: false, openInNewTab: true })
    case 'units':
      return renderUnitsCell()
    case 'creationYear':
      return renderCreationYearCell()
    case 'dealCommitment':
      return renderMoneyCell()
    case 'status':
      return renderStatusCell()
    case 'accountManager':
      return renderAccountManagerCell()
    default:
      return <Text>{cell.value}</Text>
  }
}

const AsSponsorCardTableCell = ({ cell, row, isAggregated = false }) => {
  const formatMoney = useCustomizableCurrencyFormatter()
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner.sponsor.table' })

  return renderCell(cell, row, formatMoney, isAggregated, t)
}

AsSponsorCardTableCell.propTypes = {
  cell: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  isAggregated: PropTypes.bool,
}

export default AsSponsorCardTableCell
