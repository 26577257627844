export const defaultToolbarConfig = {
  items: [
    'heading',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'link',
    '|',
    'bulletedList',
    'numberedList',
    'todoList',
    'outdent',
    'indent',
    '|',
    'blockQuote',
    'insertTable',
    '|',
    'undo',
    'redo',
    'removeFormat',
    '|',
    'fontColor',
    'fontBackgroundColor',
    'alignment',
    'fontFamily',
    'fontSize',
  ],
  shouldNotGroupWhenFull: true,
}

export const toolbarConfigWithImages = {
  items: [
    'heading',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'link',
    '|',
    'imageInsert',
    'imageUpload',
    '|',
    'blockQuote',
    'insertTable',
    '|',
    'undo',
    'redo',
    'removeFormat',
    '|',
    'fontColor',
    'fontBackgroundColor',
    'alignment',
    'fontFamily',
    'fontSize',
    '|',
    'bulletedList',
    'numberedList',
    'todoList',
    'outdent',
    'indent',
  ],
  shouldNotGroupWhenFull: true,
}

export const minimalToolbarConfig = {
  items: ['bold', 'italic', 'underline', 'alignment', 'heading'],
  shouldNotGroupWhenFull: true,
}
