import { Input, Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import styles from 'components/domains/conditions/overview/table-cells/view/TableInputCell.module.css'

const TableInputCell = ({
  input,
  isEditMode,
  onInput,
  onBlur,
  hasError,
  errorText,
  placeholder,
  useTextStyling = true,
  ...props
}) => {
  const onInputInternal = useCallback(
    ({ target: { value: newInput } }) => {
      onInput(newInput)
    },
    [onInput],
  )
  const onBlurInternal = useCallback(() => {
    onBlur()
  }, [onBlur])

  // This is a hack for the React Table. It seems like react table listens to the space event and stops
  // the propagation of this event to other components like the input. With this the event is not handed to
  // other components but the input which solves the behaviour for us.
  const onKeyDown = useCallback((event) => {
    if (event.code === 'Space' || event.code === 'ArrowRight' || event.code === 'ArrowLeft') {
      event.stopPropagation()
    }
  }, [])
  const onFocus = useCallback((event) => {
    event.stopPropagation()
  }, [])

  if (isEditMode) {
    return (
      <Input
        value={input}
        placeholder={placeholder}
        onInput={onInputInternal}
        onBlur={onBlur && onBlurInternal}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        valueState={hasError ? ValueState.Error : ValueState.None}
        valueStateMessage={<Text wrapping>{errorText}</Text>}
        {...props}
      />
    )
  }
  return (
    <Text className={useTextStyling ? styles.title : ''} wrapping>
      {input}
    </Text>
  )
}

TableInputCell.propTypes = {
  input: PropTypes.string,
  isEditMode: PropTypes.bool.isRequired,
  onInput: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  hasError: PropTypes.bool,
  placeholder: PropTypes.string,
  errorText: PropTypes.string,
  useTextStyling: PropTypes.bool,
}

export default TableInputCell
