import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import ConditionAssigneeDisplay from 'components/domains/conditions/shared/assignee/ConditionAssigneeDisplay'
import ConditionAssigneeSelect from 'components/domains/conditions/shared/assignee/ConditionAssigneeSelect'
import useRequirementsCellAddMode from 'hooks/services/conditions/edit-mode/useRequirementsCellAddMode'
import useRequirementsCellEditMode from 'hooks/services/conditions/edit-mode/useRequirementsCellEditMode'
import {
  requirementAssigneeChanged,
  requirementRowHasError,
} from 'redux/slices/conditions/requirementsTableSlice'

const RequirementsTableAssigneeCell = ({ value: { id: requirementId, assignee: assigneeId } }) => {
  const dispatch = useDispatch()
  const { isAddModeForCurrentRow } = useRequirementsCellAddMode({
    requirementId,
  })
  const { isEditModeForCurrentRow } = useRequirementsCellEditMode({
    requirementId,
  })

  const handleOnAssigneeChange = useCallback(
    (newAssignee) => {
      dispatch(requirementAssigneeChanged({ assignee: newAssignee }))
    },
    [dispatch],
  )

  const handleRequestError = useCallback(() => {
    dispatch(requirementRowHasError({ id: requirementId, error: { hasError: true } }))
  }, [dispatch, requirementId])

  return isAddModeForCurrentRow || isEditModeForCurrentRow ? (
    <ConditionAssigneeSelect
      assigneeId={assigneeId}
      onRequestError={handleRequestError}
      onAssigneeChange={handleOnAssigneeChange}
    />
  ) : (
    <ConditionAssigneeDisplay assigneeId={assigneeId} onRequestError={handleRequestError} />
  )
}

RequirementsTableAssigneeCell.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string,
    assignee: PropTypes.string,
  }).isRequired,
}

export default RequirementsTableAssigneeCell
