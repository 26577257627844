import { get, set, isNil } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { extractApprovalStatusList } from 'components/domains/business-partners/tile/arrears/ArrearsUtil'
import { useArrearApprovalsByBusinessPartnerId } from 'hooks/services/arrears/useArrearApprovalsByBusinessPartnerId'
import { useArrearClassifications } from 'hooks/services/arrears/useArrearClassifications'
import useArrearsAllowedOperations from 'hooks/services/arrears/useArrearsAllowedOperations'
import { useArrearsByBusinessPartnerId } from 'hooks/services/arrears/useArrearsByBusinessPartnerId'
import useBusinessPartnerPdLevel from 'hooks/services/arrears/useBusinessPartnerPdLevel'
import { useEvents } from 'hooks/services/business-events-and-tasks/events/useEvents'
import useDealsWithTranchesAndDrawdownsByBorrower from 'hooks/services/business-partners/financial-product/lending/useDealsWithTranchesAndDrawdownsByBorrower'
import useCurrencyConversionWithAllValues from 'hooks/services/central-data/useCurrencyConversionWithAllValues'
import useBankCustomerAccountsAmounts from 'hooks/services/deals/financing/bank-customer-accounts/useBankCustomerAccountsAmounts'
import useBankCustomerAccountsByBorrower from 'hooks/services/deals/financing/bank-customer-accounts/useBankCustomerAccountsByBorrower'
import useNonLoanProductsByBorrower from 'hooks/services/deals/financing/useNonLoanProductsByBorrower'
import {
  ARREARS_HEADQUARTER_CURRENCY,
  ENTITY_TYPES,
  EXTENDING_STATES,
  NO_DEAL,
  OVERPAYMENT,
} from 'routes/business-partners/arrears/constants'

const ARREAR_APPROVAL = 'ARREAR_APPROVAL'
export const BUSINESS_PARTNER = 'BUSINESS_PARTNER'

const ORIGINAL_VALUE_ACCESSORS = {
  ARREAR_AMOUNT: 'arrearAmount',
  OVERPAYMENT_AMOUNT: 'overpaymentAmount',
  CURRENT_CLOSED_COMMITMENT: 'currentClosedCommitment',
  ARREAR_AMOUNT_OWN_SHARE: 'ownShare',
  APPROVAL_AMOUNT: 'approvalAmount',
}

const ORIGINAL_CURRENCY_ACCESSORS = {
  ARREAR: 'currency',
  CURRENT_CLOSED_COMMITMENT: 'currentClosedCommitmentCurrency',
}

const FOREIGN_CURRENCY_ACCESSORS = {
  APPROVAL_ITEM: 'foreignCurrency',
}

const HEADQUARTER_VALUE_ACCESSORS = {
  ARREAR_AMOUNT: 'headquarterArrearAmount',
  OVERPAYMENT_AMOUNT: 'headquarterOverpaymentAmount',
  CURRENT_CLOSED_COMMITMENT: 'headquarterCurrentClosedCommitment',
  ARREAR_AMOUNT_OWN_SHARE: 'headquarterOwnShare',
}

const FOREIGN_VALUE_ACCESSORS = {
  APPROVAL_AMOUNT: 'foreignApprovalAmount',
}

const CREDIT_ACCOUNT = 'CREDIT_ACCOUNT'

const EVENTS_SORT = { sortBy: 'info.original_due_date', orderBy: 'desc' }

// TODO: make this hook shorter and less complex when time
export const useBusinessPartnerArrears = (businessPartnerId) => {
  const arrears = useArrearsByBusinessPartnerId(businessPartnerId)
  const arrearApprovals = useArrearApprovalsByBusinessPartnerId(businessPartnerId)
  const arrearApprovalEvents = useEvents(
    EVENTS_SORT,
    {
      searchValue: ARREAR_APPROVAL,
      entityType: BUSINESS_PARTNER,
      entityId: businessPartnerId,
    },
    {
      limit: 100,
    },
  )
  const dealsWithContracts = useDealsWithTranchesAndDrawdownsByBorrower(businessPartnerId)
  const nonLoanProducts = useNonLoanProductsByBorrower(businessPartnerId)
  const bankCustomerAccounts = useBankCustomerAccountsByBorrower({ borrowerId: businessPartnerId })

  const businessPartnerPdLevel = useBusinessPartnerPdLevel(businessPartnerId)

  const { data: { allowedOperations = [] } = {} } = useArrearsAllowedOperations()

  const getBankCustomerAccountIds = useCallback(() => {
    const bankCustomerAccountIds = []
    bankCustomerAccounts?.data?.bankCustomerAccounts?.forEach((bcaInBorrower) => {
      bankCustomerAccountIds.push(bcaInBorrower.id)
    })
    bankCustomerAccounts?.data?.dealsWithBankCustomerAccounts?.forEach(
      (dealWithBankCustomerAccounts) => {
        dealWithBankCustomerAccounts.bankCustomerAccounts.forEach((bcaInDeal) => {
          bankCustomerAccountIds.push(bcaInDeal.id)
        })
      },
    )
    return bankCustomerAccountIds
  }, [bankCustomerAccounts])

  const bankCustomerAccountAmounts = useBankCustomerAccountsAmounts({
    bcaIds: getBankCustomerAccountIds(),
  })

  const [products, setProducts] = useState([])

  const [convertedValues, setConvertedValues] = useState([])
  const [extendingState, setExtendingState] = useState(EXTENDING_STATES.INITIAL)

  const isExtendingStatus = useMemo(
    () => ({
      initial: extendingState === EXTENDING_STATES.INITIAL,
      error: extendingState === EXTENDING_STATES.ERROR,
      converting: extendingState === EXTENDING_STATES.CONVERTING,
      converted: extendingState === EXTENDING_STATES.CONVERTED,
      setting: extendingState === EXTENDING_STATES.SETTING,
      set: extendingState === EXTENDING_STATES.SET,
    }),
    [extendingState],
  )

  const { mutate: convertCurrencyValues } = useCurrencyConversionWithAllValues({
    onSuccess: ({ data }) => {
      const currencyConversionValues = data.currencyConversionValues
      setConvertedValues(() => [...currencyConversionValues])
      setExtendingState(EXTENDING_STATES.CONVERTED)
    },
    onError: () => {
      setExtendingState(EXTENDING_STATES.ERROR)
    },
  })

  const arrearClassifications = useArrearClassifications()

  const isLoadingData = useMemo(
    () =>
      arrears.isFetching ||
      arrearApprovals.isFetching ||
      arrearApprovalEvents.isFetching ||
      arrearClassifications.isLoading ||
      businessPartnerPdLevel.isLoading ||
      dealsWithContracts.isFetching ||
      nonLoanProducts.isFetching ||
      bankCustomerAccounts.isFetching ||
      (bankCustomerAccountAmounts.isFetching &&
        bankCustomerAccounts.isSuccess &&
        bankCustomerAccounts?.data?.bankCustomerAccounts.length > 0),
    [
      arrears.isFetching,
      arrearApprovals.isFetching,
      arrearApprovalEvents.isFetching,
      arrearClassifications.isLoading,
      businessPartnerPdLevel.isLoading,
      dealsWithContracts.isFetching,
      nonLoanProducts.isFetching,
      bankCustomerAccounts.isFetching,
      bankCustomerAccounts.isSuccess,
      bankCustomerAccounts?.data?.bankCustomerAccounts.length,
      bankCustomerAccountAmounts.isFetching,
    ],
  )

  const isErrorData = useMemo(
    () =>
      arrears.isError ||
      arrearApprovals.isError ||
      arrearApprovalEvents.isError ||
      arrearClassifications.isError ||
      dealsWithContracts.isError ||
      nonLoanProducts.isError ||
      bankCustomerAccounts.isError,
    [
      arrears.isError,
      arrearApprovals.isError,
      arrearApprovalEvents.isError,
      arrearClassifications.isError,
      dealsWithContracts.isError,
      nonLoanProducts.isError,
      bankCustomerAccounts.isError,
    ],
  )

  /**
   * Reset the conversion state when there was already a conversion and
   * the data is reloading e.g. when query will be invalidated.
   */
  useEffect(() => {
    if (isLoadingData) {
      setExtendingState(EXTENDING_STATES.INITIAL)
      setConvertedValues(() => [])
    }
  }, [isLoadingData])

  // Method for generate a unique id for the id of the currency conversion payload
  const getConversionIdByAttribute = (entity, attribute) => `${entity.id}_${attribute}`

  // Helper method to create the payloads for the currency conversion requests.
  // When no currency accessor given, headquarter is used.
  // When the actual value to convert is null, we filter them out.
  const getValuesToConvert = useCallback(
    ({ entities, valueAccessor, sourceCurrencyAccessor, targetCurrencyAccessor }) =>
      entities
        ?.map((entity) => {
          const id = getConversionIdByAttribute(entity, valueAccessor)
          const value = get(entity, valueAccessor)
          const sourceCurrency = sourceCurrencyAccessor
            ? get(entity, sourceCurrencyAccessor)
            : ARREARS_HEADQUARTER_CURRENCY
          const targetCurrency = targetCurrencyAccessor
            ? get(entity, targetCurrencyAccessor)
            : ARREARS_HEADQUARTER_CURRENCY
          if (!isNil(value)) {
            return { id, value, sourceCurrency, targetCurrency }
          }
        })
        ?.filter(Boolean) ?? [],
    [],
  )

  const getCorrespondingArrear = useCallback(
    (approvalItem) =>
      arrears?.data?.arrears?.find((arrear) => arrear.externalId === approvalItem.arrearExternalId),
    [arrears],
  )

  const currentApprovalItems = useMemo(
    () => arrearApprovals?.data?.arrearApprovals?.[0]?.arrearApprovalItems ?? [],
    [arrearApprovals?.data?.arrearApprovals],
  )

  const extendCurrentApprovalItemsByArrearCurrency = useCallback(() => {
    currentApprovalItems.forEach((approvalItem) => {
      const correspondingArrear = getCorrespondingArrear(approvalItem)
      set(
        approvalItem,
        FOREIGN_CURRENCY_ACCESSORS.APPROVAL_ITEM,
        correspondingArrear?.currency ?? ARREARS_HEADQUARTER_CURRENCY,
      )
    })
  }, [currentApprovalItems, getCorrespondingArrear])

  /**
   * When the data is loaded without any errors and is not converted yet,
   * then we want to convert the money values of the entities (arrears and products) and converting them
   * to the respective payload format for the currency conversion hook.
   *
   * HINT: arrear approval items must not be converted to headquarter, because the values are always in headquarter currency (EUR).
   *
   * After a successful conversion, we set the converted values to the state.
   */
  const shouldConvertCurrencyValues = useMemo(
    () => !isLoadingData && !isErrorData && isExtendingStatus.initial,
    [isExtendingStatus.initial, isErrorData, isLoadingData],
  )
  if (shouldConvertCurrencyValues) {
    setExtendingState(EXTENDING_STATES.CONVERTING)

    extendCurrentApprovalItemsByArrearCurrency()

    // Collect and convert all money values of the given entities to respective payload format: {id, value, sourceCurrency, targetCurrency}.
    const valuesToConvert = getValuesToConvert({
      entities: arrears.data?.arrears,
      valueAccessor: ORIGINAL_VALUE_ACCESSORS.ARREAR_AMOUNT,
      sourceCurrencyAccessor: ORIGINAL_CURRENCY_ACCESSORS.ARREAR,
      targetCurrencyAccessor: null,
    })
      .concat(
        getValuesToConvert({
          entities: arrears.data?.arrears,
          valueAccessor: ORIGINAL_VALUE_ACCESSORS.OVERPAYMENT_AMOUNT,
          sourceCurrencyAccessor: ORIGINAL_CURRENCY_ACCESSORS.ARREAR,
          targetCurrencyAccessor: null,
        }),
      )
      .concat(
        getValuesToConvert({
          entities: arrears.data?.arrears,
          valueAccessor: ORIGINAL_VALUE_ACCESSORS.ARREAR_AMOUNT_OWN_SHARE,
          sourceCurrencyAccessor: ORIGINAL_CURRENCY_ACCESSORS.ARREAR,
          targetCurrencyAccessor: null,
        }),
      )
      .concat(
        getValuesToConvert({
          entities: currentApprovalItems,
          valueAccessor: ORIGINAL_VALUE_ACCESSORS.APPROVAL_AMOUNT,
          sourceCurrencyAccessor: null,
          targetCurrencyAccessor: FOREIGN_CURRENCY_ACCESSORS.APPROVAL_ITEM,
        }),
      )
    // Get the converted values
    convertCurrencyValues({
      keyDate: null,
      currencyConversionValues: valuesToConvert,
    })
  }

  const getBcaAmountOrNull = ({ bcaId }) =>
    bankCustomerAccountAmounts?.data?.data?.bcaAmounts?.find((amount) => amount.bcaId === bcaId)

  const getBankCustomerAccountBookBalanceOrNull = ({ bcaAmount, headquarter = false }) =>
    headquarter ? bcaAmount?.headquarterBookBalance : bcaAmount?.bookBalance

  const getBankCustomerAccountExternalLimitOrNull = ({ bcaAmount, headquarter = false }) =>
    headquarter ? bcaAmount?.headquarterExternalLimit : bcaAmount?.externalLimit

  const getTrancheOrDrawingContractId = (product, type) => {
    if (type === ENTITY_TYPES.TRANCHE) {
      return product?.externalContractId?.[0]
    } else if (type === ENTITY_TYPES.DRAWING) {
      return product?.externalContractId
    }
  }

  const getAvailableAmount = (externalLimitAmount, bookBalanceAmount) => {
    if (isNil(externalLimitAmount) && (isNil(bookBalanceAmount) || bookBalanceAmount === 0))
      return 0
    if (isNil(externalLimitAmount)) return -bookBalanceAmount
    if (isNil(bookBalanceAmount)) return externalLimitAmount
    return externalLimitAmount - bookBalanceAmount
  }

  const getCommitmentOwnShareOrNull = (total, ownShare) =>
    !isNil(ownShare) && total !== ownShare ? ownShare : null

  const getProduct = (product, type, dealDisplayId) => {
    const base = {
      dealDisplayId,
      type: type,
    }
    switch (type) {
      case ENTITY_TYPES.DRAWING:
      case ENTITY_TYPES.TRANCHE: {
        const commitment = product?.totalCommitment?.amount
        const headquarterCommitment = product?.totalCommitmentHeadquarter?.amount
        const commitmentOwnShare = product?.ownShare?.commitment
        const headquarterCommitmentOwnShare = product?.ownShareHeadquarter?.commitment
        return {
          ...base,
          id: getTrancheOrDrawingContractId(product, type),
          currentClosedCommitment: commitment,
          currentClosedCommitmentCurrency: product?.totalCommitment?.currency,
          headquarterCurrentClosedCommitment: headquarterCommitment,
          headquarterCurrentClosedCommitmentCurrency: product?.totalCommitmentHeadquarter?.currency,
          currentClosedCommitmentOwnShare: getCommitmentOwnShareOrNull(
            commitment,
            commitmentOwnShare,
          ),
          headquarterCurrentClosedCommitmentOwnShare: getCommitmentOwnShareOrNull(
            headquarterCommitment,
            headquarterCommitmentOwnShare,
          ),
          outstandingOwnShare: product?.ownShare?.outstanding,
          availableOwnShare: product?.ownShare?.available,
          headquarterOutstandingOwnShare: product?.ownShareHeadquarter?.outstanding,
          headquarterAvailableOwnShare: product?.ownShareHeadquarter?.available,
          ownShareCurrency: product?.ownShare?.currency,
          headquarterOwnShareCurrency: product?.ownShareHeadquarter?.currency,
        }
      }
      // NOTE: There are no ownShare values for trades/non loans!
      case ENTITY_TYPES.NON_LOAN:
        return {
          ...base,
          id: product?.tradeId,
          currentClosedCommitment: product?.currentNotional?.amount,
          currentClosedCommitmentCurrency: product?.currentNotional?.currency,
          headquarterCurrentClosedCommitment: product?.currentNotionalHeadquarter?.amount,
          headquarterCurrentClosedCommitmentCurrency: product?.currentNotionalHeadquarter?.currency,
        }
      case ENTITY_TYPES.BCA: {
        const bcaAmount = getBcaAmountOrNull({ bcaId: product?.id })
        const latestExternalLimit = getBankCustomerAccountExternalLimitOrNull({
          bcaAmount,
          headquarter: false,
        })
        const headquarterLatestExternalLimit = getBankCustomerAccountExternalLimitOrNull({
          bcaAmount,
          headquarter: true,
        })
        const bookBalance = getBankCustomerAccountBookBalanceOrNull({
          bcaAmount,
          headquarter: false,
        })
        const headquarterBookBalance = getBankCustomerAccountBookBalanceOrNull({
          bcaAmount,
          headquarter: true,
        })
        const isDataUnavailable = !isNil(bcaAmount?.errorMessage)
        return {
          ...base,
          id: product?.id,
          currentClosedCommitment: latestExternalLimit?.amount,
          currentClosedCommitmentCurrency: latestExternalLimit?.currency,
          headquarterCurrentClosedCommitment: headquarterLatestExternalLimit?.amount,
          headquarterCurrentClosedCommitmentCurrency: headquarterLatestExternalLimit?.currency,
          outstandingOwnShare: bookBalance?.amount,
          availableOwnShare: getAvailableAmount(latestExternalLimit?.amount, bookBalance?.amount),
          headquarterOutstandingOwnShare: headquarterBookBalance?.amount,
          headquarterAvailableOwnShare: getAvailableAmount(
            headquarterLatestExternalLimit?.amount,
            headquarterBookBalance?.amount,
          ),
          ownShareCurrency: bookBalance?.currency,
          headquarterOwnShareCurrency: headquarterBookBalance?.currency,
          isCreditAccount: product?.accountTypeCode === CREDIT_ACCOUNT,
          isErrorBcaAmounts: isDataUnavailable,
        }
      }
    }
  }

  const deriveProducts = () => {
    setProducts([])
    dealsWithContracts?.data?.deals?.forEach((dealWithContracts) => {
      dealWithContracts?.tranches?.forEach((tranche) => {
        setProducts((prev) => [
          ...prev,
          getProduct(tranche, ENTITY_TYPES.TRANCHE, dealWithContracts.dealDisplayId),
        ])
        tranche?.drawdowns?.forEach((drawdown) => {
          setProducts((prev) => [
            ...prev,
            getProduct(drawdown, ENTITY_TYPES.DRAWING, dealWithContracts.dealDisplayId),
          ])
        })
      })
    })
    nonLoanProducts?.data?.deals?.forEach((dealWithNonLoanProducts) => {
      dealWithNonLoanProducts.trades?.forEach((nonLoanProduct) => {
        setProducts((prev) => [
          ...prev,
          getProduct(
            nonLoanProduct,
            ENTITY_TYPES.NON_LOAN,
            dealWithNonLoanProducts.dealDisplayId ?? NO_DEAL,
          ),
        ])
      })
    })
    bankCustomerAccounts?.data?.bankCustomerAccounts?.forEach((bca) => {
      setProducts((prev) => [...prev, getProduct(bca, ENTITY_TYPES.BCA, NO_DEAL)])
    })
    bankCustomerAccounts?.data?.dealsWithBankCustomerAccounts?.forEach(
      (dealWithBankCustomerAccounts) => {
        dealWithBankCustomerAccounts.bankCustomerAccounts?.forEach((bca) => {
          setProducts((prev) => [
            ...prev,
            getProduct(bca, ENTITY_TYPES.BCA, dealWithBankCustomerAccounts.dealDisplayId),
          ])
        })
      },
    )
  }

  /**
   * When the data is loaded without errors and we have converted values without setting them yet, we want to
   * add an additional "headquarter<money_attribute>" (e.g. headquarterArrearAmount in arrear)
   * attribute to the existing loaded entities, by getting the correct conversion through the
   * generated id.
   */
  const shouldExtendArrearsAndProductsData = !isLoadingData && isExtendingStatus.converted
  if (shouldExtendArrearsAndProductsData) {
    setExtendingState(EXTENDING_STATES.SETTING)
    // Helper methods.
    const getConvertedValuesById = (id) =>
      convertedValues.find((convertedValue) => convertedValue.id === id)
    const extendArrearsByHeadquarterAndApprovalStatusList = () => {
      arrears.data?.arrears.forEach((arrear) => {
        set(
          arrear,
          HEADQUARTER_VALUE_ACCESSORS.ARREAR_AMOUNT,
          getConvertedValuesById(
            getConversionIdByAttribute(arrear, ORIGINAL_VALUE_ACCESSORS.ARREAR_AMOUNT),
          )?.value ?? get(arrear, ORIGINAL_VALUE_ACCESSORS.ARREAR_AMOUNT),
        )
        set(
          arrear,
          HEADQUARTER_VALUE_ACCESSORS.OVERPAYMENT_AMOUNT,
          getConvertedValuesById(
            getConversionIdByAttribute(arrear, ORIGINAL_VALUE_ACCESSORS.OVERPAYMENT_AMOUNT),
          )?.value ?? get(arrear, ORIGINAL_VALUE_ACCESSORS.OVERPAYMENT_AMOUNT),
        )
        set(
          arrear,
          HEADQUARTER_VALUE_ACCESSORS.ARREAR_AMOUNT_OWN_SHARE,
          getConvertedValuesById(
            getConversionIdByAttribute(arrear, ORIGINAL_VALUE_ACCESSORS.ARREAR_AMOUNT_OWN_SHARE),
          )?.value ?? get(arrear, ORIGINAL_VALUE_ACCESSORS.ARREAR_AMOUNT_OWN_SHARE),
        )
        set(arrear, 'approvalStatusList', extractApprovalStatusList(arrear.approvalStatuses))
      })
    }

    const extendCurrentApprovalItemsByForeignCurrencies = () => {
      currentApprovalItems.forEach((approvalItem) => {
        set(
          approvalItem,
          FOREIGN_VALUE_ACCESSORS.APPROVAL_AMOUNT,
          getConvertedValuesById(
            getConversionIdByAttribute(approvalItem, ORIGINAL_VALUE_ACCESSORS.APPROVAL_AMOUNT),
          )?.value ?? get(approvalItem, ORIGINAL_VALUE_ACCESSORS.APPROVAL_AMOUNT),
        )
      })
    }

    // Set the converted values from state to the existing loaded entites.
    deriveProducts()
    extendArrearsByHeadquarterAndApprovalStatusList()
    extendCurrentApprovalItemsByForeignCurrencies()
    setExtendingState(EXTENDING_STATES.SET)
  }

  const isError = isErrorData || isExtendingStatus.error
  const isLoading = !isExtendingStatus.set && !isError

  const arrearsWithoutOverpayments = useMemo(
    () => arrears.data?.arrears?.filter((arrear) => arrear.type.code !== OVERPAYMENT) ?? [],
    [arrears.data?.arrears],
  )

  return {
    isLoading,
    isError,
    products,
    arrears: arrears.data?.arrears ?? [],
    arrearsWithoutOverpayments: arrearsWithoutOverpayments,
    productHierarchy: arrears.data?.productHierarchy ?? [],
    arrearApprovals: arrearApprovals.data?.arrearApprovals ?? [],
    arrearApprovalEvents: arrearApprovalEvents.data?.events ?? [],
    arrearClassifications: arrearClassifications.data ?? {},
    businessPartnerPdLevel: businessPartnerPdLevel.data ?? {},
    allowedOperations: allowedOperations ?? [],
  }
}
