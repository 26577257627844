import { MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import MessageBoxWithExpandableDetails from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/dialog/MessageBoxWithExpandableDetails'

/**
 * https://fioneer.atlassian.net/browse/CWP-13200 | Move to correct folder (components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/message-box)
 */
const ErrorMessageBoxWithExpandableDetails = ({
  messageSummary,
  messageDetails,
  isOpen,
  onClose,
}) => {
  const tryJsonParse = (maybeJSON) => {
    let parsed
    try {
      parsed = JSON.parse(maybeJSON)
    } catch (e) {
      parsed = {}
    }
    return parsed
  }

  const summaryJson = tryJsonParse(messageDetails)
  const traceDetailsJson = tryJsonParse(summaryJson?.errorDetails?.trace_details)

  const messageExtension =
    traceDetailsJson?.error?.message ||
    summaryJson?.errorDetails?.message ||
    summaryJson?.errorDetails?.detail ||
    summaryJson?.errorDetails?.title

  const extendedSummary = messageExtension
    ? `${messageSummary}\n\n ${messageExtension}`
    : messageSummary

  return (
    <MessageBoxWithExpandableDetails
      messageSummary={extendedSummary}
      messageDetails={messageDetails}
      messageBoxType={MessageBoxTypes.Error}
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

ErrorMessageBoxWithExpandableDetails.propTypes = {
  messageSummary: PropTypes.string,
  messageDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
}

export default ErrorMessageBoxWithExpandableDetails
