import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteDecisionStage = () => {
  const { delete: doDelete } = useAccessTokenRequest()

  return useMutation(({ eventId, stageId }) =>
    doDelete({
      path: `/events/${eventId}/decision-stages/${stageId}`,
    }),
  )
}

export default useDeleteDecisionStage
