import isNil from 'lodash.isnil'
import {
  TABLE_ROW_WITH_HEADQUARTER_CURRENCY_HEIGHT,
  TABLE_ROW_WITHOUT_HEADQUARTER_CURRENCY_HEIGHT,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/shared/tranchesOverview/constants'

const mapTranchesDetailsSummaryData = ({ summary, summaryHeadquarter }) => {
  let primary
  let secondary

  if (!isNil(summary) && isNil(summaryHeadquarter)) {
    primary = summary
  } else if (isNil(summary) && !isNil(summaryHeadquarter)) {
    primary = summaryHeadquarter
  } else {
    primary = summary
    secondary = summary?.currency !== summaryHeadquarter?.currency ? summaryHeadquarter : undefined
  }

  return {
    commitment: {
      primary: {
        value: primary?.ownCommitment,
        currency: primary?.currency,
      },
      secondary: {
        value: secondary?.ownCommitment,
        currency: secondary?.currency,
      },
      share: primary?.ownCommitmentShare,
    },
    outstandingAmount: {
      primary: {
        value: primary?.outstanding,
        currency: primary?.currency,
      },
      secondary: {
        value: secondary?.outstanding,
        currency: secondary?.currency,
      },
    },
    rowHeight:
      primary && secondary
        ? TABLE_ROW_WITH_HEADQUARTER_CURRENCY_HEIGHT
        : TABLE_ROW_WITHOUT_HEADQUARTER_CURRENCY_HEIGHT,
  }
}

export default mapTranchesDetailsSummaryData
