import { Table, TableColumn, FlexBox } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes, { object } from 'prop-types'
import styles from 'components/ui/tables/display-and-edit-table/DisplayAndEditTable.module.css'
import { defaultPopoverStaticConfig } from 'components/ui/tables/display-and-edit-table/DisplayAndEditTablePopover'
import { DisplayAndEditTableRow } from 'components/ui/tables/display-and-edit-table/DisplayAndEditTableRow'
import {
  TABLE_ACTION_BUTTON_WIDTH,
  UI5_TABLE_CELL_PADDING,
} from 'components/ui/tables/display-and-edit-table/constants'

const alignContent = (content, alignment) =>
  alignment ? <FlexBox justifyContent={alignment}>{content}</FlexBox> : content

const DisplayAndEditTable = ({
  columnDefinitions,
  tableData,
  userIsAllowedToEdit = false,
  cancelAddRow,
  cardType,
  handleDeleteRow,
  handleSaveRow,
  handleCancelEditRow,
  popoverStaticConfig = defaultPopoverStaticConfig,
  checkIsValidReturnErrorMessage = () => ({ isError: false }),
  additionalTableStyles = '',
  actionCellStyles,
  isWritingToBackend,
  collapseActionsWhenSize,
}) => {
  const renderTableColumns = () => {
    let minWidthSum = 0

    const colDefs = [
      ...columnDefinitions.map((colDef) => {
        const minWidth = colDef.minWidth
        const hasPopin = !!colDef.hasPopin

        // we still add each column's minWidth to the sum,
        // because we need the overall width relative to the table width for the breakpoint
        minWidthSum += (minWidth ?? 0) + UI5_TABLE_CELL_PADDING

        const popinDef = {
          demandPopin: true,
          minWidth: minWidthSum + (userIsAllowedToEdit ? TABLE_ACTION_BUTTON_WIDTH : 0),
          popinText: colDef.title,
        }

        return (
          <TableColumn
            key={colDef.columnKey}
            className={colDef.style}
            {...(hasPopin && { ...popinDef })}
          >
            {alignContent(`${colDef.title}`, colDef.alignment)}
          </TableColumn>
        )
      }),
    ]
    const editDeleteCol = <TableColumn key={'editDeleteButtons'}>{''}</TableColumn>
    return [...colDefs, editDeleteCol]
  }

  const mapTableRows = () =>
    tableData.map((row) => (
      <DisplayAndEditTableRow
        key={row.rowKey}
        tableRow={row}
        isValid={row.isValid}
        hasChanges={row.hasChanges}
        columnDefinitions={columnDefinitions}
        cardType={cardType}
        handleSaveRow={handleSaveRow}
        handleDeleteRow={handleDeleteRow}
        handleCancelEditRow={handleCancelEditRow}
        userIsAllowedToEdit={userIsAllowedToEdit}
        cancelAddRow={cancelAddRow}
        checkIsValidReturnErrorMessage={checkIsValidReturnErrorMessage}
        popoverStaticConfig={popoverStaticConfig}
        actionCellStyles={actionCellStyles}
        isWritingToBackend={isWritingToBackend}
        hideEditButton={!!row.hideEditButton}
        hideDeleteButton={!!row.hideDeleteButton}
        collapseActionsWhenSize={collapseActionsWhenSize}
      />
    ))

  return (
    <Table
      columns={renderTableColumns()}
      className={`${styles.table} ${additionalTableStyles}`}
      stickyColumnHeader={true}
      noDataText={t('components.table.no-data')}
      {...(!!isWritingToBackend && { inert: '' })}
    >
      {mapTableRows()}
    </Table>
  )
}

DisplayAndEditTable.propTypes = {
  columnDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      columnKey: PropTypes.string.isRequired,
      title: PropTypes.string,
      hasPopin: PropTypes.bool,
      minWidth: PropTypes.number,
    }),
  ).isRequired,
  tableData: PropTypes.arrayOf(object).isRequired,
  userIsAllowedToEdit: PropTypes.bool,
  handleDeleteRow: PropTypes.func.isRequired,
  handleSaveRow: PropTypes.func.isRequired,
  handleCancelEditRow: PropTypes.func.isRequired,
  popoverStaticConfig: PropTypes.object,
  cancelAddRow: PropTypes.func.isRequired,
  checkIsValidReturnErrorMessage: PropTypes.func,
  additionalTableStyles: PropTypes.string,
  actionCellStyles: PropTypes.shape({
    editModeCell: PropTypes.string,
    readModeCell: PropTypes.string,
    editModeButtons: PropTypes.string,
    readModeButtons: PropTypes.string,
  }),
  isWritingToBackend: PropTypes.bool,
  collapseActionsWhenSize: PropTypes.number,
}
export default DisplayAndEditTable
