import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ValuationRequestMultiEditDateSelect from 'components/domains/properties/valuation/mass-edit/fields/ValuationRequestMultiEditDateSelectField'
import Labeled from 'components/ui/data/Labeled'

const valuationDateFieldId = 'valuationRequestMultiEditValuationDueDateField'

const ValuationRequestMultiEditValuationDueDateField = ({ onChange, disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.properties.valuation.multi-edit.fields',
  })

  return (
    <Labeled id={valuationDateFieldId} label={t('due-date.label')} vertical showColon>
      <ValuationRequestMultiEditDateSelect
        id={valuationDateFieldId}
        onChange={onChange}
        disabled={disabled}
      />
    </Labeled>
  )
}

ValuationRequestMultiEditValuationDueDateField.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default ValuationRequestMultiEditValuationDueDateField
