import { ObjectStatus, Text } from '@fioneer/ui5-webcomponents-react'
import find from 'lodash.find'
import { useNavigate } from 'react-router-dom'
import useGetAndEnrichFavoritesAndRecentVisitsWithProperties from 'components/domains/properties/user-favorite/useGetAndEnrichFavoritesAndRecentVisitsWithProperties'
import EntityCell from 'components/ui/tables/cells/EntityCell'
import UserFavoritesAndRecentVisitsCard from 'components/ui/user-favorite/UserFavoritesAndRecentsCard'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import paths from 'routes/paths'

const columnNames = {
  NAME_AND_ID: 'property-name-and-id',
  PROPERTY_TYPE: 'property-type',
  COUNTRY: 'property-country',
  CITY: 'property-city',
  PROPERTY_STATUS: 'property-status',
}

const renderPropertyNameAndId = (description, id) => (
  <EntityCell
    name={description}
    link={`/properties/${id}`}
    id={id}
    options={{ isNameBold: true }}
  />
)

const renderPropertyTextInfo = (info) => <Text>{info}</Text>
const renderPropertyStatus = (text, valueState) =>
  text && valueState ? (
    <ObjectStatus inverted state={valueState}>
      {text}
    </ObjectStatus>
  ) : null

const renderIfFound = (notFound, component) => (notFound ? null : component)
const renderPropertyTextInfoIfFound = (info, notFound) =>
  renderIfFound(notFound, renderPropertyTextInfo(info))

const mapDataToTableRow = ({
  id,
  description,
  cityName,
  countryName,
  typeName,
  propertyStatus,
  notFound,
  ...otherProps
}) => ({
  [columnNames.NAME_AND_ID]: renderIfFound(notFound, renderPropertyNameAndId(description, id)),
  [columnNames.PROPERTY_TYPE]: renderPropertyTextInfoIfFound(typeName, notFound),
  [columnNames.COUNTRY]: renderPropertyTextInfoIfFound(countryName, notFound),
  [columnNames.CITY]: renderPropertyTextInfoIfFound(cityName, notFound),
  [columnNames.PROPERTY_STATUS]: renderIfFound(
    notFound,
    renderPropertyStatus(propertyStatus?.text, propertyStatus?.valueState),
  ),
  notFound,
  ...otherProps,
})

const mapDataToTableRows = (data) => data?.map(mapDataToTableRow) ?? []

const PropertiesUserFavoritesAndRecentVisitsCard = () => {
  const propertiesUserFavoritesAndRecentVisitsResult =
    useGetAndEnrichFavoritesAndRecentVisitsWithProperties()
  const navigate = useNavigate()
  const handleOnRowClick = (uuid) => {
    const foundProperty = find(
      propertiesUserFavoritesAndRecentVisitsResult.data,
      (item) => item.uuid === uuid,
    )

    if (foundProperty) {
      navigate(`/${paths.properties}/${foundProperty.uuid}`)
    }
  }
  const handleOnHeaderClick = () => navigate(`/${paths.properties}`)

  return (
    <UserFavoritesAndRecentVisitsCard
      entityType={cwpEntityTypes.PROPERTY}
      title="property-title"
      subtitle="property-subtitle"
      isLoading={propertiesUserFavoritesAndRecentVisitsResult.isLoading}
      isError={propertiesUserFavoritesAndRecentVisitsResult.isError}
      rowsData={mapDataToTableRows(propertiesUserFavoritesAndRecentVisitsResult.data)}
      columnNames={Object.values(columnNames)}
      handleOnHeaderClick={handleOnHeaderClick}
      handleOnRowClick={handleOnRowClick}
    />
  )
}

export default PropertiesUserFavoritesAndRecentVisitsCard
