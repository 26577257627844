import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CashflowResultTable from 'components/domains/deals/deal-cashflow/results/CashflowResultTable'
import PeriodSelect, {
  periodValues,
} from 'components/domains/deals/deal-cashflow/results/PeriodSelect'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Section from 'components/ui/page/Section'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useStaffMemberByObjectIdOrEmail'
import { useCashFlowScenario } from 'hooks/services/deals/cashflow/useCashFlowScenario'
import useCashflowGroups from 'hooks/services/deals/cashflow/useCashflowGroups'
import useCashflowScenarioResultsById from 'hooks/services/deals/cashflow/useCashflowScenarioResultsById'
import { UserProfileContext } from 'routes/UserProfileContext'
import { useLastEditedText } from 'routes/deals/financing/utils/useLastEditedText'

const CashflowResults = ({ dealUuid, cashflowId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.cashflow.details.results',
  })
  const { zoneId } = useContext(UserProfileContext) ?? {}
  const [period, setPeriod] = useState(periodValues.years)

  const {
    data: { groups = [] } = {},
    isLoading: isLoadingGroups,
    isError: isErrorGroups,
  } = useCashflowGroups()

  const {
    data: { calculatedResults = [], calculatedKpis = [], availableDatasetPeriods = [] } = {},
    isLoading: isLoadingResults,
    isError: isErrorResults,
  } = useCashflowScenarioResultsById({ dealUuid, cashflowId, datasetPeriod: period })

  const { data: { cashflowResultLastUpdatedBy, cashflowResultLastUpdatedAt } = {} } =
    useCashFlowScenario(dealUuid, cashflowId)

  const { data: staffMember } = useStaffMemberByObjectIdOrEmail({
    objectIdOrEmail: cashflowResultLastUpdatedBy,
  })

  const lastEditedText = useLastEditedText(
    staffMember?.fullName || cashflowResultLastUpdatedBy,
    cashflowResultLastUpdatedAt,
    zoneId,
  )

  const filterRelevantCalculatedResults = (cashflowTypes) =>
    calculatedResults.filter((calculatedResult) =>
      cashflowTypes.some(({ cctId }) => cctId === calculatedResult.cashflowType.cctId),
    )

  const filterRelevantCalculatedKpis = (kpis) =>
    calculatedKpis.reduce((filteredKpis, calculatedKpi) => {
      const filteredCalculatedKpis = kpis.filter(
        (kpi) => kpi.ckpiId === calculatedKpi.cashflowKpi.ckpiId,
      )

      for (const filteredKpi of filteredCalculatedKpis) {
        calculatedKpi.cashflowKpi.name = filteredKpi.name
        filteredKpis.push(calculatedKpi)
      }
      return filteredKpis
    }, [])

  const renderTables = () =>
    groups.map(({ ccgId, name, cashflowTypes, kpis, displayDayOne }) => {
      const filteredMeasures = cashflowTypes.length
        ? filterRelevantCalculatedResults(cashflowTypes)
        : filterRelevantCalculatedKpis(kpis)
      return (
        <CWPLayout.Full key={ccgId}>
          <CashflowResultTable
            title={name}
            calculatedMeasures={filteredMeasures}
            lastEditedText={lastEditedText}
            selectedPeriod={period}
            displayDayOne={!!displayDayOne}
          />
        </CWPLayout.Full>
      )
    })

  return (
    <Section
      titleText={t('title')}
      actions={
        <PeriodSelect
          value={period}
          onChange={setPeriod}
          availablePeriods={availableDatasetPeriods}
        />
      }
    >
      <CWPLayout>
        <LoadingStateWrapper
          isLoading={isLoadingResults || isLoadingGroups}
          isError={isErrorResults || isErrorGroups}
          renderContent={renderTables}
        />
      </CWPLayout>
    </Section>
  )
}

CashflowResults.propTypes = {
  dealUuid: PropTypes.string.isRequired,
  cashflowId: PropTypes.string.isRequired,
}

export default CashflowResults
