import { AnalyticalTableSelectionMode } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { conditionTypes } from 'api/conditions/conditions'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/internal/InternalConditionsTileViewMode.module.css'
import DecisionPaperConditionsTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/DecisionPaperConditionsTable'
import DecisionPaperConditionsTileToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/DecisionPaperConditionsTileToolbar'
import useDecisionPaperConditionTableColumns from 'hooks/services/business-events-and-tasks/decision-papers/tiles/conditions/useDecisionPaperConditionTableColumns'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const noop = () => {}

const InternalConditionsTileViewMode = ({
  tileId,
  shownColumns,
  isPdfView,
  shownSubcomponents,
  subcomponentHeight,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.internal-conditions',
  })
  const {
    data: { selectedConditions, statusValues },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const { event } = useContext(BusinessEventsAndTasksContext)

  const { columnSelection } = useDecisionPaperConditionTableColumns({
    shownColumns,
    conditionType: conditionTypes.internal,
    entityType: event?.entityRef?.entityType,
  })

  if (isPdfView) {
    columnSelection.forEach((column) => {
      delete column.minWidth
      delete column.width
    })
  }

  return (
    <div className={styles.tableWrapper}>
      <DecisionPaperConditionsTileToolbar numberOfConditions={selectedConditions.length} />
      <DecisionPaperConditionsTable
        isLoading={false}
        conditions={selectedConditions}
        columns={columnSelection}
        isFilterOrSearchApplied={false}
        fetchNextPage={noop}
        conditionType={conditionTypes.internal}
        statusValues={statusValues}
        selectionMode={AnalyticalTableSelectionMode.None}
        emptyText={t('empty')}
        alwaysShowDescription={isPdfView}
        shownSubcomponents={shownSubcomponents}
        subcomponentHeight={subcomponentHeight}
      />
    </div>
  )
}

InternalConditionsTileViewMode.propTypes = {
  tileId: PropTypes.string.isRequired,
  shownColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  isPdfView: PropTypes.bool.isRequired,
  shownSubcomponents: PropTypes.shape({
    description: PropTypes.bool,
  }),
  subcomponentHeight: PropTypes.number.isRequired,
}

export default InternalConditionsTileViewMode
