import { Label, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import DisplayAndEditCard from 'components/ui/card/DisplayAndEditCard'
import { hideOptions } from 'components/ui/card/DisplayCardView'
import editComponentTypes from 'components/ui/card/editComponentTypes'
import { usePercentageFormatter } from 'hooks/i18n/useI18n'
import { useCosts } from 'hooks/services/properties/costs/useCosts'
import { useUpdatePropertyDetails } from 'hooks/services/properties/useUpdatePropertyDetails'
import { PropertyContext } from 'routes/properties/PropertyContext'

const ConstructionDatesCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.property-construction-and-dates-card',
  })

  const formatPercent = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const { t: tNoKeyPrefix } = useTranslation()
  const constructionYearKey = 'constructionYear'
  const modernizationYearKey = 'modernizationYear'
  const completionPercentKey = 'completionPercent'

  const {
    property: {
      uuid: propertyUuid,
      construction_completion_percent: constructionCompletionPercent,
      construction_completion_date: constructionCompletionDate,
      change_request_exist_indicator: changeRequestExistsIndicator,
      allowed_operations,
    },
  } = useContext(PropertyContext)
  const { data: propertyCosts } = useCosts([propertyUuid])
  const allowedOperations = allowed_operations?.allowed_operations ?? []
  const isAllowedPropertyMasterDataEdit =
    !changeRequestExistsIndicator && allowedOperations.includes('PropertyMasterData_Update')

  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()
  const updateProperty = useUpdatePropertyDetails({
    onSuccess: () => {
      queryClient.invalidateQueries(['properties'])
      showToast({ children: tNoKeyPrefix('toast.changes-saved') })
    },
  })

  const getYearFromDate = (date) => (date ? date.split('-')[0] : '')

  const validateYear = (year) => /^[0-9]{4}$/.test(year) || year.length === 0

  const validatePercent = (percent) => (percent <= 100 && percent >= 0) || percent.length === 0

  const completionPercentExists = (value) => value !== undefined && value > 0

  const checkAndHandleInvalidValues = (key, value, validator, isFocussed, setInvalidFormState) => {
    if (!validator(value) && !isFocussed) {
      setInvalidFormState((oldState) => [...oldState, key])
    } else if (validator(value)) {
      setInvalidFormState((oldState) => oldState.filter((element) => element !== key))
    }
  }

  const handleEditChanges = (changes, isMandatory, setInvalidFormState, isFocussed) => {
    const key = Object.keys(changes)[0]
    if (key === constructionYearKey || key === modernizationYearKey) {
      checkAndHandleInvalidValues(key, changes[key], validateYear, isFocussed, setInvalidFormState)
    }
    if (key === completionPercentKey) {
      checkAndHandleInvalidValues(
        key,
        changes[key],
        validatePercent,
        isFocussed,
        setInvalidFormState,
      )
    }
  }

  const parseYearToDate = (year) => (year ? `${year}-01-01` : '')

  const handleSaveChanges = (changes) => {
    const changesForBackend = {
      constructionCompletionPercent: changes[completionPercentKey],
      constructionCompletionDate: parseYearToDate(changes[constructionYearKey]),
    }
    updateProperty.mutate({
      property_uuid: propertyUuid,
      property: changesForBackend,
    })
  }

  const fieldDefinitions = [
    {
      label: t('label.construction-year'),
      name: constructionYearKey,
      value: getYearFromDate(constructionCompletionDate),
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: true,
      isShownInEdit: true,
      editComponentType: editComponentTypes.Input,
      editComponentProps: {
        maxLength: 4,
      },
      isMandatory: false,
    },
    {
      label: t('label.completion-degree'),
      name: `${completionPercentKey}Display`,
      hideOption: hideOptions.hideWhenEmpty,
      value: completionPercentExists(constructionCompletionPercent)
        ? formatPercent(constructionCompletionPercent / 100)
        : '',
      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      label: t('label.completion-degree'),
      name: completionPercentKey,
      value: constructionCompletionPercent,
      isShownInDisplay: false,
      isShownInEdit: true,
      editComponentType: editComponentTypes.FormattedNumberInput,
      editComponentProps: {
        icon: <Label style={{ color: 'var(--sapContent_LabelColor)' }}>{'%'}</Label>,
      },
    },
    {
      label: t('label.modernization-year'),
      name: modernizationYearKey,
      value: getYearFromDate(
        propertyCosts?.costsWithLastModernizationDate?.[0]?.lastModernizationDate,
      ),
      hideOption: hideOptions.hideWhenEmpty,
      isShownInDisplay: true,
      isShownInEdit: true,
      editComponentProps: {
        readonly: true,
        type: 'Number',
      },
      editComponentType: editComponentTypes.Input,
      isMandatory: false,
    },
  ]
  return (
    <DisplayAndEditCard
      cardHeaderTitle={t('title')}
      fieldDefinitions={fieldDefinitions}
      saveChanges={handleSaveChanges}
      onEditChanges={handleEditChanges}
      isEditable={isAllowedPropertyMasterDataEdit}
      saveHookIsSuccess={updateProperty.isSuccess}
      saveHookIsError={updateProperty.isError}
      saveHookError={updateProperty.error}
    />
  )
}

export default ConstructionDatesCard
