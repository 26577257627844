import {
  AnalyticalTableScaleWidthMode,
  Icon,
  IllustratedMessage,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/analytical/AnalyticalTableWithToolbar'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/businesspartner/shared/CurrentArrearsTable.module.css'
import AnalyticalTableScalingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/AnalyticalTableScalingWrapper'

const NoDataComponent = () => {
  const { t: tBusinessPartnerArrearsTable } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.business-partner.arrears.current-arrears.table',
  })
  return (
    <IllustratedMessage
      name={IllustrationMessageType.NoData}
      titleText={tBusinessPartnerArrearsTable('no-data-title')}
      subtitleText={tBusinessPartnerArrearsTable('no-data-text')}
    />
  )
}

const propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool,
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      id: PropTypes.string,
      accessor: PropTypes.string,
      Cell: PropTypes.node,
      minWidth: PropTypes.number,
      hAlign: PropTypes.string,
      disableResizing: PropTypes.bool,
      disableDragAndDrop: PropTypes.bool,
      disableGlobalFilter: PropTypes.bool,
      disableGroupBy: PropTypes.bool,
    }),
  ),
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const CurrentArrearsTable = ({ tileId, isPdfView, tableColumns }) => {
  const {
    data: { tableData, arrearsCount },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const tableRef = useRef(null)
  const [tableWidth, setTableWidth] = useState(tableRef?.current?.totalColumnsWidth ?? 0)

  const { t: tBusinessPartnerArrearsTable } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.business-partner.arrears.current-arrears.table',
  })

  const expandOrCollapseSubRows = useCallback((isExpanded) => {
    tableRef?.current?.toggleAllRowsExpanded?.(isExpanded)
  }, [])

  useEffect(() => {
    if (tableData) {
      expandOrCollapseSubRows(true)
    }
  }, [expandOrCollapseSubRows, tableData])

  useEffect(() => {
    setTableWidth(tableRef?.current?.totalColumnsWidth ?? 0)
  }, [tableRef?.current?.totalColumnsWidth])

  const additionalTableActions = [
    <Icon
      id={`arrears-table-collapse`}
      interactive
      className={styles.icon}
      name="collapse-all"
      key={'collapse-subrows'}
      onClick={() => expandOrCollapseSubRows(false)}
    />,
    <Icon
      id={`arrears-table-expand`}
      interactive
      className={styles.icon}
      name="expand-all"
      key={'expand-subrows'}
      onClick={() => expandOrCollapseSubRows(true)}
    />,
  ]

  return (
    <AnalyticalTableScalingWrapper isPdfView={isPdfView} tableWidth={tableWidth}>
      <div className={styles.tableWrapper}>
        <AnalyticalTableWithToolbar
          ref={tableRef}
          toolbarClassName={styles.toolbarPadding}
          title={tBusinessPartnerArrearsTable('title')}
          id="businessPartnerArrearsTable"
          minRows={0}
          columns={tableColumns}
          data={tableData ?? []}
          // high number of visible rows fixes re-rendering of height on expand
          overscanCountHorizontal={999}
          visibleRows={999}
          // rowHeight needs to be set to a non-empty string to not show an empty row at the end
          rowHeight={'individual'}
          headerRowHeight={32}
          NoDataComponent={NoDataComponent}
          additionalActions={isPdfView ? [] : additionalTableActions}
          filterable={!isPdfView}
          sortable={!isPdfView}
          scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
          disableColumnPopover={true}
          withRowHighlight={false}
          nrOfEntries={arrearsCount}
          showNrOfEntries={true}
          loading={false}
          isTreeTable={true}
        />
      </div>
    </AnalyticalTableScalingWrapper>
  )
}
CurrentArrearsTable.propTypes = propTypes

export default CurrentArrearsTable
