import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useKpis = (referenceEntityType, referenceEntityId, options = {}) => {
  const params = new URLSearchParams()
  params.append('reference_entity_type', referenceEntityType)
  params.append('reference_entity_id', referenceEntityId)

  return useCamelizedResponse(
    useRequest({
      path: `/kpis?${params.toString()}`,
      useCache: true,
      keys: ['kpis', referenceEntityType, referenceEntityId],
      options: {
        enabled: !!referenceEntityType && !!referenceEntityId,
        ...options,
      },
    }),
  )
}

export default useKpis
