import { ObjectStatus } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { eventStatus } from 'api/events/status'
import { getObjectStatusForMarketValuationConfirmationStatus } from 'api/property/monitoring/propertyMonitoringConfiguration'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useEventMini from 'hooks/services/business-events-and-tasks/events/useEventMini'

const MarketValueConfirmationStatusCell = ({ confirmationStatus, eventId }) => {
  const { t: tNoPrefix } = useTranslation()

  const { isFetching, data: event } = useEventMini(eventId)

  const { translationKey: statusTranslationKey, objectStatus } = useMemo(
    () => getObjectStatusForMarketValuationConfirmationStatus(confirmationStatus),
    [confirmationStatus],
  )

  const renderContent = useCallback(() => {
    if (event?.status === eventStatus.aborted) {
      return <></>
    }
    return (
      <ObjectStatus inverted state={objectStatus}>
        {tNoPrefix(statusTranslationKey)}
      </ObjectStatus>
    )
  }, [event?.status, objectStatus, statusTranslationKey, tNoPrefix])

  return (
    // in case of an error we want to display the confirmation status so no error handling needed here.
    <SmallLoadingWrapper
      isLoading={isFetching}
      isError={false}
      error={<></>}
      renderContent={renderContent}
    />
  )
}

MarketValueConfirmationStatusCell.propTypes = {
  confirmationStatus: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
}

export default MarketValueConfirmationStatusCell
