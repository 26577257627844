import PropTypes from 'prop-types'
import { useCallback } from 'react'
import MultipleDealSelectionPdfViewWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/wrapper/MultipleDealSelectionPdfViewWrapper'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import RiskMonitoringCollateralAgreementsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/RiskMonitoringCollateralAgreementsTile'

const RiskMonitoringCollateralAgreementsTileSelectionWrapper = ({ tileId, isPdfView }) => {
  const contentCreator = useCallback(
    ({ selectedDealIndex }) => (
      <RiskMonitoringCollateralAgreementsTile
        tileId={tileId}
        selectedDealIndex={selectedDealIndex}
        isPdfView={isPdfView}
      />
    ),
    [isPdfView, tileId],
  )
  return (
    <MultipleDealSelectionPdfViewWrapper
      tileId={tileId}
      contentCreator={contentCreator}
      isPdfView={isPdfView}
      dealSelectionTileCode={
        availableAutomaticTiles.monitoredRiskIndicatorDealSpecificsDealSelection
      }
    />
  )
}

RiskMonitoringCollateralAgreementsTileSelectionWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default RiskMonitoringCollateralAgreementsTileSelectionWrapper
