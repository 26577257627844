import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import { eventStatus } from 'api/events/status'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'

const createOptionTranslations = {
  [eventStatus.drafted]: 'save-as-draft',
  [eventStatus.ongoing]: 'publish',
}

const useHandleWorkingVersionEventCreation = ({
  createEventMutation,
  setIsPublishing,
  setIsSecondStageOpen,
  firstStageResult,
  setIsConfirmBoxOpen,
}) => {
  const showMessageBox = useShowMessageBox()
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'dialog.event-creation.deal-working-version',
  })

  const onConfirm = (secondStageResult) => {
    setIsPublishing(true)
    setIsSecondStageOpen(true)
    createEventMutation.mutate({
      dealUuid: firstStageResult.entityId,
      body: {
        eventTypeCode: firstStageResult.eventType,
        decisionPaperTemplateCode: secondStageResult.decisionPaperTemplate,
        eventIdToImportDataFrom: secondStageResult.eventIdToImportDataFrom,
        eventStatus: secondStageResult.eventStatus,
      },
    })
  }
  const onCancel = () => {
    setIsSecondStageOpen(true)
  }

  const handleCreate = ({
    eventStatus: eventStatusCreateOption,
    selectedTemplate,
    eventIdToImportDataFrom,
    customizedDecisionPaperTemplateStructure,
  } = {}) => {
    setIsSecondStageOpen(false)
    setIsConfirmBoxOpen(true)
    showMessageBox(
      {
        onClose: () => setIsConfirmBoxOpen(false),
        type: MessageBoxTypes.Confirm,
        titleText: t('confirm.title'),
        children: (
          <span>
            <span>{t(`confirm.body.${createOptionTranslations[eventStatusCreateOption]}`)}</span>
            <span>{'\n\n'}</span>
            <span>{t('confirm.body')}</span>
          </span>
        ),
        actions: [
          <Button
            key="button-confirm"
            design={ButtonDesign.Emphasized}
            onClick={() =>
              onConfirm({
                eventStatus: eventStatusCreateOption,
                decisionPaperTemplate: selectedTemplate,
                // Customization of the template will be implemented at a later point
                // For now, this property is a placeholder for the information in the future
                customizedTemplateStructure: customizedDecisionPaperTemplateStructure,
                eventIdToImportDataFrom,
              })
            }
          >
            {tNoPrefix('buttons.confirm')}
          </Button>,
          <Button key="button-cancel" design={ButtonDesign.Transparent} onClick={onCancel}>
            {tNoPrefix('buttons.cancel')}
          </Button>,
        ],
      },
      document.body,
    )
  }

  return handleCreate
}

export default useHandleWorkingVersionEventCreation
