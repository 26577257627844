import {
  AnalyticalTableScaleWidthMode,
  FlexBox,
  FlexBoxDirection,
  IllustratedMessage,
  Text,
  TextAlign,
  VerticalAlign,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import FinancingTermsTrancheDetailsTableCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/FinancingTermsTrancheDetailsTableCell'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/FinancingTermsTrancheDetailsTile.module.css'
import mapTranchesDetailsData from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/utils/mapTranchesDetailsData'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche/shared/ui/tables/analytical/AnalyticalTableWithToolbar'
import AnalyticalTableScalingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/AnalyticalTableScalingWrapper'

const EXPECTED_SIGNED_DATE_TYPE = 'EXP_SIGN'
const tableWidthCalculationPadding = 25

// get the latest date value of every milestone with correct type
const expectedSigningDate = (milestonesData) => {
  let latestDate = new Date(null) // 1970-01-01T00:00:00.000Z
  let latestDateString = undefined

  milestonesData?.forEach((milestone) => {
    if (milestone.typeCode === EXPECTED_SIGNED_DATE_TYPE && milestone?.targetDate) {
      const tempDate = new Date(milestone?.targetDate)
      if (tempDate > latestDate) {
        latestDate = tempDate
        latestDateString = milestone?.targetDate
      }
    }
  })

  return latestDateString
}

const FinancingTermsTrancheDetailsTile = ({ tileId, isPdfView }) => {
  const tileData = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const trancheData = useMemo(
    () => tileData?.data?.trancheData ?? {},
    [tileData?.data?.trancheData],
  )
  const milestonesData = tileData?.data?.milestonesData

  const [hasLastExtensionOption, setHasLastExtensionOption] = useState(true)
  const tableRef = useRef(null)
  const [tableWidth, setTableWidth] = useState(tableRef?.current?.totalColumnsWidth ?? 0)

  useEffect(() => {
    const newTableWidth = (tableRef?.current?.totalColumnsWidth ?? 0) + tableWidthCalculationPadding
    setTableWidth(newTableWidth)
  }, [tableRef?.current?.totalColumnsWidth])

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.financing-terms.tranche-details',
  })

  const renderWrappedTextCell = useCallback((label) => <Text wrapping>{t(label)}</Text>, [t])

  const columnDefinitions = useMemo(
    () =>
      [
        {
          Header: renderWrappedTextCell('property.rank-label'),
          accessor: 'rank',
          hAlign: TextAlign.Right,
          width: 70,
        },
        {
          Header: renderWrappedTextCell('property.tranche-label'),
          accessor: 'tranche',
          minWidth: 250,
        },
        {
          Header: renderWrappedTextCell('property.loan-type-label'),
          accessor: 'loanType',
          width: 145,
        },
        {
          Header: renderWrappedTextCell('property.commitment-own-share-label'),
          accessor: 'commitment',
          hAlign: TextAlign.Right,
          width: 170,
        },
        {
          Header: renderWrappedTextCell('property.availability-label'),
          accessor: 'availability',
          hAlign: TextAlign.Right,
          width: 110,
        },
        {
          Header: renderWrappedTextCell('property.maturity-label'),
          accessor: 'maturity',
          hAlign: TextAlign.Right,
          width: 120,
        },
        {
          Header: renderWrappedTextCell('property.last-extension-label'),

          accessor: 'lastExtensionOption',
          hAlign: TextAlign.Right,
          width: 150,
          isVisible: hasLastExtensionOption,
        },
        {
          Header: renderWrappedTextCell('property.repayment-label'),
          accessor: 'repayment',
          width: 150,
        },
        {
          Header: renderWrappedTextCell('property.interest-rate-label'),
          accessor: 'interestRateType',
          width: 150,
        },
        {
          Header: renderWrappedTextCell('property.net-revenue-label'),
          accessor: 'netRevenueMargin',
          hAlign: TextAlign.Right,
          width: 100,
        },
        {
          Header: renderWrappedTextCell('property.risk-return-ratio-label'),
          accessor: 'riskReturnRatio',
          hAlign: TextAlign.Right,
          width: 100,
        },
      ].map((element) => ({
        disableDragAndDrop: true,
        disableResizing: false,
        Cell: (cellProps) => FinancingTermsTrancheDetailsTableCell({ isPdfView, ...cellProps }),
        vAlign: VerticalAlign.Top,
        ...element,
      })),
    [hasLastExtensionOption, isPdfView, renderWrappedTextCell],
  )

  const tableData = useMemo(() => {
    if (isEmpty(trancheData)) {
      return []
    }
    let mappedTranchesData = mapTranchesDetailsData(
      trancheData,
      expectedSigningDate(milestonesData),
      isPdfView,
    )
    const lastExtensionOptionExists = mappedTranchesData.some(
      (tranches) =>
        tranches?.lastExtensionOption?.ultimateMaximumExtensionDate &&
        tranches?.lastExtensionOption?.closingDate,
    )
    setHasLastExtensionOption(lastExtensionOptionExists)

    if (mappedTranchesData.length > 1) {
      mappedTranchesData = [
        ...mappedTranchesData.slice(0, -1).sort((a, b) => a?.rank - b?.rank),
        mappedTranchesData.at(-1),
      ]
    }
    return mappedTranchesData
  }, [isPdfView, milestonesData, trancheData])

  return (
    <AnalyticalTableScalingWrapper isPdfView={isPdfView} tableWidth={tableWidth}>
      <FlexBox direction={FlexBoxDirection.Column} className={styles.tableWrapper}>
        <AnalyticalTableWithToolbar
          nrOfEntries={trancheData?.tranches?.length ?? 0}
          title={t('table.title')}
          id="tranche-details-table"
          className={styles['trancheDetails-table']}
          sortable={false}
          minRows={0}
          columns={columnDefinitions}
          // high number of visible rows fixes re-rendering of height on expand
          visibleRows={99}
          overscanCountHorizontal={99}
          // rowHeight needs to be set to a non-empty string to not show an empty row at the end
          rowHeight={'individual'}
          headerRowHeight={50}
          data={tableData}
          isTreeTable={false}
          scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
          withRowHighlight={false}
          isPdfView={isPdfView}
          NoDataComponent={() => (
            <IllustratedMessage
              name="NoData"
              titleText={t('table.no-data-title')}
              subtitleText={t('table.no-data-text')}
            />
          )}
          ref={tableRef}
        />
      </FlexBox>
    </AnalyticalTableScalingWrapper>
  )
}

FinancingTermsTrancheDetailsTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}
export default FinancingTermsTrancheDetailsTile
