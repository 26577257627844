import { useCallback, useMemo } from 'react'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'

export const mapEntityTypeFilters = (ids, entityType) => {
  if (ids === '') return []
  return ids.split(',').map((entityId) => ({ entityId, entityType }))
}

export const parseDueDateRange = (dueDateRange) => ({
  startDate: dueDateRange.split(' - ')[0].trim(),
  endDate: dueDateRange.split(' - ')[1].trim(),
})

export const deleteSingleReference = (entityId, filteredIds) =>
  filteredIds
    .split(',')
    .filter((id) => id !== entityId)
    .join(',')

export const checkIfAtLeastTwoNotEmpty = (str1, str2, str3) =>
  [str1, str2, str3].filter((str) => !!str).length >= 2

export const concatIds = (...strings) => strings.filter((str) => !!str).join(',')

/* We want to force the entityType filter, if no filter is set but only one entity is being filtered for. 
This is so that on the of chance an id is not unique across services, we only get the results that fit our search. */
export const setEntityTypeIfEmpty = (bpIds, propertyIds, dealIds, entityTypeFilter) => {
  if (entityTypeFilter !== '' || checkIfAtLeastTwoNotEmpty(bpIds, propertyIds, dealIds)) {
    return entityTypeFilter
  }
  if (bpIds !== '') return cwpEntityTypes.BUSINESS_PARTNER
  if (propertyIds !== '') return cwpEntityTypes.PROPERTY
  if (dealIds !== '') return cwpEntityTypes.DEAL
}

export const useReferenceFilterHandler = ({ setFilterParams, filterParams }) => {
  const handleReferenceFilter = useCallback(
    (entityType, ids) => {
      switch (entityType) {
        case cwpEntityTypes.BUSINESS_PARTNER:
          setFilterParams({
            ...filterParams,
            bp_ids: ids,
          })
          break
        case cwpEntityTypes.PROPERTY:
          setFilterParams({
            ...filterParams,
            property_ids: ids,
          })
          break
        case cwpEntityTypes.DEAL:
          setFilterParams({
            ...filterParams,
            deal_ids: ids,
          })
          break
      }
    },
    [filterParams, setFilterParams],
  )

  const onClearSelections = useCallback(
    (entityType) => {
      handleReferenceFilter(entityType, '')
    },
    [handleReferenceFilter],
  )

  const onDeleteReferences = useCallback(
    (deletedReference) => {
      let filteredIds
      switch (deletedReference.entityType) {
        case cwpEntityTypes.BUSINESS_PARTNER:
          filteredIds = filterParams.bp_ids
          break
        case cwpEntityTypes.PROPERTY:
          filteredIds = filterParams.property_ids
          break
        case cwpEntityTypes.DEAL:
          filteredIds = filterParams.deal_ids
          break
      }
      filteredIds = deleteSingleReference(deletedReference.entityId, filteredIds)

      handleReferenceFilter(deletedReference.entityType, filteredIds)
    },
    [filterParams, handleReferenceFilter],
  )

  const handleSearchDialogSelectionChanges = useCallback(
    (selectedEntities) => {
      const ids = selectedEntities.map((entity) => entity.entityId).join(',')
      handleReferenceFilter(selectedEntities[0].entityType, ids)
    },
    [handleReferenceFilter],
  )

  return useMemo(
    () => ({ handleSearchDialogSelectionChanges, onClearSelections, onDeleteReferences }),
    [handleSearchDialogSelectionChanges, onClearSelections, onDeleteReferences],
  )
}
