import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import orderBy from 'lodash.orderby'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/loan-market-statement/shared/ui/card/EmptyCardContent'
import { MAX_COLLAPSED_COMMENT_LENGTH } from 'components/domains/business-events-and-tasks/decision-paper/tiles/loan-market-statement/shared/ui/constants/syndicationConstants'
import CollapsibleRichTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/loan-market-statement/shared/ui/rich-text/CollapsibleRichTextView'
import FormattedTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/loan-market-statement/shared/ui/rich-text-editor/FormattedTextView'

const LoanMarketStatementSyndicationStatementTile = ({ tileId, isPdfView }) => {
  const {
    data: { syndicationStatementData: { availableVersions } = {} },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.loan-market-statement.components',
  })

  const sortedVersions = orderBy(availableVersions, 'lastUpdated.lastUpdatedOn', ['desc'])
  const currentVersion = sortedVersions[0]?.statement ?? ''

  if (currentVersion !== '') {
    return (
      <FlexBox direction={FlexBoxDirection.Column}>
        {isPdfView ? (
          <FormattedTextView formattedText={currentVersion} />
        ) : (
          <CollapsibleRichTextView
            text={currentVersion}
            characterLimit={MAX_COLLAPSED_COMMENT_LENGTH}
          />
        )}
      </FlexBox>
    )
  }
  return <EmptyCardContent title={t('cards.empty.title')} subtitle={t('cards.empty.subtitle')} />
}

LoanMarketStatementSyndicationStatementTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool,
}

export default LoanMarketStatementSyndicationStatementTile
