import {
  Button,
  ButtonDesign,
  CheckBox,
  Label,
  MessageStrip,
  MessageStripDesign,
  ObjectStatus,
  SuggestionItem,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isDateEditable } from 'api/events/eventAllowedOperations'
import {
  getObjectStatusForTaskStatus,
  eventStatus as possibleEventStatus,
  taskStatus as possibleTaskStatus,
} from 'api/events/status'
import DisplayCardView from 'components/ui/card/DisplayCardView'
import EditCardItem from 'components/ui/card/EditCardItem'
import EditCardView from 'components/ui/card/EditCardView'
import InputWithSearchSuggestions from 'components/ui/input/InputWithSearchSuggestions'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useEditTask from 'hooks/services/business-events-and-tasks/tasks/useEditTask'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'
import useStaffMembers from 'hooks/services/business-partners/staff-members/useStaffMembers'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import styles from 'routes/business-events-and-tasks/tasks/TaskGeneralInformationCard.module.css'

const filterNonAllowedStatusTranstitions = (value) =>
  value.key !== possibleTaskStatus.aborted.toLocaleLowerCase()

const TaskGeneralInformationCard = ({
  taskId,
  eventId,
  eventCreationAction,
  name,
  isMandatory,
  assignee,
  currentDueDate,
  originalDueDate,
  status,
  eventStatus,
  isEditable,
}) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', { keyPrefix: 'pages.task-detail.information' })
  const queryClient = useQueryClient()
  const { format, localePattern, parse } = useShortDateFormatter()
  const { t: tCommon } = useTranslation()
  const showMessageBox = useShowMessageBox()
  const { allowedOperations } = useContext(BusinessEventsAndTasksContext)
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false)
  const [editFields, setEditFields] = useState({})
  const [userSearchKey, setUserSearchKey] = useState('')
  const editTaskMutation = useEditTask()
  const checkBoxText = t('mandatory.checkbox')

  const { data: assigneeUser, isError: isUserByIdError } = useStaffMemberById(assignee)
  const { data: suggestions, isError: isSuggestionsError } = useStaffMembers({
    name: userSearchKey,
    minLength: 1,
  })

  const renderSuggestions = useCallback(() => {
    const suggestionsItems = []
    suggestions?.staffMembers.forEach((user, index) => {
      suggestionsItems.push(
        <SuggestionItem key={index} text={user.fullName} data-user-id={user.id} />,
      )
    })
    return suggestionsItems
  }, [suggestions?.staffMembers])

  const getStatusOptions = useMemo(() => {
    const statusOptions = []
    Object.keys(possibleTaskStatus).forEach((statusObj) => {
      statusOptions.push({ key: statusObj, display_name: tCommon(`tasks.status.${statusObj}`) })
    })
    if (isMandatory) return statusOptions.filter(filterNonAllowedStatusTranstitions)

    return statusOptions
  }, [isMandatory, tCommon])

  const handleOnAssigneeChange = useCallback((event) => {
    const userId = event.detail.item.getAttribute('data-user-id')
    setEditFields((oldState) => ({
      ...oldState,
      ['assignee']: userId,
    }))
  }, [])

  const handleOnClear = useCallback(() => {
    setEditFields((oldState) => ({
      ...oldState,
      ['assignee']: null,
    }))
  }, [])

  const assigneeUserField = useMemo(() => {
    if (isUserByIdError) {
      return <Label className={styles.errorText}>{t('error.assignee')}</Label>
    }
    return assigneeUser?.fullName || '-' // TODO need to figure out how to deal with loadingstates with the EditCard Component
  }, [assigneeUser, isUserByIdError, t])

  const renderAssigneeWithSuggestions = useMemo(
    () => (
      <InputWithSearchSuggestions
        initialValue={assigneeUser ? assigneeUser.fullName : ''}
        renderSuggestions={renderSuggestions}
        handleOnChange={handleOnAssigneeChange}
        handleOnClear={handleOnClear}
        setSearchKey={setUserSearchKey}
        isSuggestionsError={isSuggestionsError}
      />
    ),
    [assigneeUser, handleOnAssigneeChange, handleOnClear, isSuggestionsError, renderSuggestions],
  )

  const renderListErrorStrip = useCallback(
    () => (
      <MessageStrip hideCloseButton design={MessageStripDesign.Negative}>
        {t('error.list-has-error')}
      </MessageStrip>
    ),
    [t],
  )
  const tileErrorMessageStrip = useMemo(
    () => ({
      customEditComponent: (
        <div className={styles.editListErrorWrapper}>{renderListErrorStrip()}</div>
      ),
      customInfoComponent: <div className={styles.listErrorWrapper}>{renderListErrorStrip()}</div>,
    }),
    [renderListErrorStrip],
  )

  const taskGeneralInformationFields = useMemo(
    () => [
      ...(isUserByIdError ? [tileErrorMessageStrip] : []),
      {
        label: t('name'),
        name: 'name',
        value: name,
        editComponentType: 'Input',
        editComponentProps: {
          readOnly: true,
        },
      },
      {
        name: 'mandatory',
        value: <CheckBox readonly={true} text={checkBoxText} checked={isMandatory} />,
        editComponentType: 'CheckBox',
        editComponentProps: {
          readonly: true,
          checked: isMandatory,
        },
      },
      {
        name: 'assigneeField',
        label: t('label.assignee'),
        value: assigneeUserField,
        renderCustomEditComponent: () => (
          <EditCardItem label={t('label.assignee')} editComponent={renderAssigneeWithSuggestions} />
        ),
      },
      {
        name: 'currentDueDate',
        label: t('current-due-date'),
        value: currentDueDate ? format(currentDueDate) : '-',
        editComponentType: 'DatePicker',
        editComponentProps: {
          disabled:
            eventStatus === possibleEventStatus.drafted ||
            isDateEditable(allowedOperations, eventCreationAction),
        },
      },
      {
        name: 'originalDueDate',
        label: t('original-due-date'),
        value: originalDueDate ? format(originalDueDate) : '-',
        editComponentType: 'DatePicker',
        editComponentProps: {
          disabled:
            eventStatus !== possibleEventStatus.drafted ||
            isDateEditable(allowedOperations, eventCreationAction),
        },
      },
      {
        name: 'status',
        label: t('status'),
        value: (
          <ObjectStatus inverted state={getObjectStatusForTaskStatus(status).objectStatus}>
            {tCommon(getObjectStatusForTaskStatus(status).translationKey)}
          </ObjectStatus>
        ),
        editComponentType: 'Select',
        editComponentSelectOptions: getStatusOptions,
        editComponentProps: {
          disabled: eventStatus === 'DRAFTED',
        },
      },
    ],
    [
      allowedOperations,
      assigneeUserField,
      checkBoxText,
      currentDueDate,
      eventCreationAction,
      eventStatus,
      format,
      getStatusOptions,
      isMandatory,
      isUserByIdError,
      name,
      originalDueDate,
      renderAssigneeWithSuggestions,
      status,
      t,
      tCommon,
      tileErrorMessageStrip,
    ],
  )

  const handleSaveConfirmation = useCallback(() => {
    editTaskMutation.mutate(
      {
        taskId,
        eventId,
        assignee: editFields.assignee,
        status: editFields.status,
        currentDueDate: editFields.currentDueDate,
        originalDueDate: editFields.originalDueDate,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['events'])
          setIsEditModeEnabled(false)
        },
        onError: () => {
          showMessageBox({
            type: MessageBoxTypes.Error,
            children: tNoPrefix('components.cards.save-error'),
          })
        },
      },
    )
  }, [
    editFields.assignee,
    editFields.currentDueDate,
    editFields.originalDueDate,
    editFields.status,
    editTaskMutation,
    eventId,
    queryClient,
    showMessageBox,
    tNoPrefix,
    taskId,
  ])

  const onSaveClicked = useCallback(() => {
    if (
      editFields.status === possibleTaskStatus.aborted ||
      editFields.status === possibleTaskStatus.completed
    ) {
      showMessageBox({
        type: MessageBoxTypes.Confirm,
        children: t('confirmation.message'),
        actions: [
          <Button
            key="button-confirm"
            design={ButtonDesign.Emphasized}
            onClick={handleSaveConfirmation}
          >
            {tNoPrefix('buttons.save')}
          </Button>,
          MessageBoxActions.Cancel,
        ],
      })
      return
    }
    handleSaveConfirmation()
  }, [editFields.status, handleSaveConfirmation, showMessageBox, t, tNoPrefix])

  const onCancelClicked = useCallback(() => {
    setEditFields({})
    setIsEditModeEnabled(false)
  }, [])

  const onEditClicked = useCallback(() => {
    setEditFields({})
    setIsEditModeEnabled(true)
  }, [])

  const dispatchChange = useCallback(
    (event) => {
      const [key, value] = Object.entries(event)[0]
      let parsedValue
      switch (key) {
        case 'status':
          parsedValue = value.toUpperCase()
          break
        case 'currentDueDate':
        case 'originalDueDate':
          parsedValue = value ? parse(value, localePattern) : null
      }

      setEditFields((oldState) => ({
        ...oldState,
        [key]: parsedValue,
      }))
    },
    [localePattern, parse],
  )

  const isSaveButtonEnabled = useMemo(() => {
    if (
      (editFields.assignee !== undefined && editFields.assignee !== assignee) ||
      (editFields.status !== undefined && editFields.status !== status) ||
      (editFields.currentDueDate !== undefined && editFields.currentDueDate !== currentDueDate) ||
      (editFields.originalDueDate !== undefined && editFields.originalDueDate !== originalDueDate)
    ) {
      return true
    }
    return false
  }, [
    assignee,
    currentDueDate,
    editFields.assignee,
    editFields.currentDueDate,
    editFields.originalDueDate,
    editFields.status,
    originalDueDate,
    status,
  ])

  const values = useMemo(
    () => ({
      name,
      status: status.toLowerCase(),
      mandatory: checkBoxText,
      currentDueDate,
      originalDueDate,
    }),
    [checkBoxText, currentDueDate, name, originalDueDate, status],
  )

  if (isEditModeEnabled) {
    return (
      <>
        <EditCardView
          cardHeaderTitle={t('title')}
          fieldDefinitions={taskGeneralInformationFields}
          onSaveClicked={onSaveClicked}
          onCancelClicked={onCancelClicked}
          setEditModeHasChanges={() => {}}
          editModeHasChanges={isSaveButtonEnabled}
          onChange={dispatchChange}
          values={values}
        />
      </>
    )
  }
  return (
    <DisplayCardView
      cardHeaderTitle={t('title')}
      fieldDefinitions={taskGeneralInformationFields}
      setEditMode={onEditClicked}
      isEditable={isEditable}
    />
  )
}

TaskGeneralInformationCard.propTypes = {
  taskId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  eventCreationAction: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isMandatory: PropTypes.bool.isRequired,
  assignee: PropTypes.string,
  currentDueDate: PropTypes.string,
  originalDueDate: PropTypes.string,
  status: PropTypes.string.isRequired,
  eventStatus: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
}

export default TaskGeneralInformationCard
