import {
  CheckBox,
  FlexBox,
  FlexBoxJustifyContent,
  Label,
  TableCell,
  TableRow,
  TableRowType,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { covenantsOfCheckMonitoringItemShape } from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/DecisionPaperCheckedCovenantPropTypes'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/DecisionPaperCheckedCovenantsTable.module.css'
import CovenantsOfCheckBreachCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/CovenantsOfCheckBreachCell'
import CovenantsOfCheckLimitCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/CovenantsOfCheckLimitCell'
import { mainEntityTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/MainEntityTypes'
import DecisionPaperEntity from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/data/CovenantsOfCheckEntity'
import { useCovenantsOfCheckShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/i18n/useCovenantsOfCheckI18n'
import { useCovenantsOfCheckBreachTranslation } from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/useCovenantOfCheckBreachTranslations'
import useCwpEntityToMainEntity from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/useCwpEntityToMainEntity'
import { cwpEntityTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/constants/cwpEntityTypes'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import paths from 'routes/paths'

const DecisionPaperCheckedCovenantDisplayRow = ({
  monitoringItem,
  entityType,
  dealId,
  ...props
}) => {
  const { t: tNoPrefix } = useTranslation('decisionPaper')
  const { format: formatDate } = useCovenantsOfCheckShortDateFormatter()
  const { cwpEntityToMainEntity } = useCwpEntityToMainEntity()
  const { getCovenantsOfCheckTranslationForBreach } = useCovenantsOfCheckBreachTranslation()
  const {
    covenant,
    dueDate,
    newTargetDeliveryDate,
    softLimit,
    hardLimit,
    result,
    breach,
    resultOfBreach,
    waived,
  } = monitoringItem ?? {}

  let navigationLink
  switch (covenant?.mainEntity?.type) {
    case mainEntityTypes.DEAL:
      navigationLink = `/${paths.deals}/${dealId}/${dealDetailPaths.covenants}/${covenant?.covenantUuid}`
      break
    case mainEntityTypes.BUSINESSPARTNER:
      navigationLink = `/${paths.businessPartners}/${covenant?.mainEntity?.id}/covenants/${covenant?.covenantUuid}`
      break
  }
  const getCovenantTypeTranslation = (type) =>
    ({
      FINANCIAL: tNoPrefix('tiles.covenants-of-check.pages.deals.covenants.financial'),
      NON_FINANCIAL: tNoPrefix('tiles.covenants-of-check.pages.deals.covenants.non-financial'),
    }[type] ?? '')

  const renderNoData = () => (
    <Label className={styles.errorText}>
      {tNoPrefix('tiles.covenants-of-check.components.deals.covenants.checked-covenants.no-data')}
    </Label>
  )

  const renderBreachCell = () => {
    if (isNil(breach)) {
      return renderNoData()
    }
    if (breach === 'POSTPONED') {
      return getCovenantsOfCheckTranslationForBreach(breach)
    }
    return (
      <CovenantsOfCheckBreachCell
        breach={breach}
        popoverContent={
          <Text className={styles.errorText}>
            {tNoPrefix(
              'tiles.covenants-of-check.components.deals.covenants.monitoring-items-card.result-of-breach',
            )}
            {resultOfBreach?.name}
          </Text>
        }
      />
    )
  }

  return (
    <TableRow
      className={
        breach && !['NO', 'POSTPONED'].includes(breach) ? styles.markLeftBorder : undefined
      }
      type={TableRowType.Inactive}
      {...props}
    >
      <TableCell>
        <DecisionPaperEntity
          name={covenant?.name}
          link={navigationLink}
          openInNewTab={cwpEntityToMainEntity[entityType] !== covenant?.mainEntity?.type}
          onClick={() => {}}
        />
      </TableCell>
      <TableCell>
        <Text>{getCovenantTypeTranslation(covenant?.type)}</Text>
      </TableCell>
      <TableCell>
        <FlexBox justifyContent={FlexBoxJustifyContent.End}>
          <Text>{formatDate(dueDate?.targetDeliveryDate)}</Text>
        </FlexBox>
      </TableCell>
      <TableCell>
        <FlexBox justifyContent={FlexBoxJustifyContent.End}>
          <Text>{formatDate(newTargetDeliveryDate)}</Text>
        </FlexBox>
      </TableCell>
      <TableCell>
        <FlexBox justifyContent={FlexBoxJustifyContent.End}>
          <CovenantsOfCheckLimitCell
            value={softLimit?.value}
            currencyCode={softLimit?.currencyCode}
            unit={softLimit?.unit}
          />
        </FlexBox>
      </TableCell>
      <TableCell>
        <FlexBox justifyContent={FlexBoxJustifyContent.End}>
          <CovenantsOfCheckLimitCell
            value={hardLimit?.value}
            currencyCode={hardLimit?.currencyCode}
            unit={hardLimit?.unit}
          />
        </FlexBox>
      </TableCell>
      <TableCell>
        <FlexBox justifyContent={FlexBoxJustifyContent.End}>
          {isNil(result?.value) && covenant?.type === 'FINANCIAL' ? (
            renderNoData()
          ) : (
            <CovenantsOfCheckLimitCell
              value={result?.value}
              currencyCode={result?.currencyCode}
              unit={result?.unit}
            />
          )}
        </FlexBox>
      </TableCell>
      <TableCell>{renderBreachCell()}</TableCell>
      <TableCell>
        <CheckBox disabled checked={!!waived} />
      </TableCell>
    </TableRow>
  )
}

DecisionPaperCheckedCovenantDisplayRow.propTypes = {
  monitoringItem: covenantsOfCheckMonitoringItemShape,
  dealId: PropTypes.string,
  entityType: PropTypes.oneOf([cwpEntityTypes.DEAL, cwpEntityTypes.BUSINESS_PARTNER]),
}

export default DecisionPaperCheckedCovenantDisplayRow
