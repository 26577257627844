import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import PropTypes from 'prop-types'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'components/domains/properties/valuation/valuation-results/PropertyValuationResultsTable.module.css'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import useTableInputSelectionWorkaround from 'hooks/table/useTableInputSelectionWorkaround'
import { valuationValueAmountChanged } from 'redux/slices/properties/valuationResultsTableSlice'

const PropertyValuationResultsValueAmountCell = ({ valueAmount, isAddMode, currency }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.valuation.results.table.form-fields.value-amount',
  })
  const inputRef = useRef()
  const formatCurrency = useCustomizableCurrencyFormatter({
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const dispatch = useDispatch()

  const handleOnChange = useCallback(
    (newValue) => {
      dispatch(valuationValueAmountChanged({ valueAmount: newValue }))
    },
    [dispatch],
  )
  const handleBlur = useCallback(() => {
    dispatch(valuationValueAmountChanged({ valueAmount: valueAmount }))
  }, [dispatch, valueAmount])

  const hasError = useSelector(
    (state) => state.properties.valuationResults.editedRow?.errorFields?.valueAmount,
  )
  const isTouched = useSelector(
    (state) => state.properties.valuationResults.editedRow?.touchedFields?.valueAmount,
  )
  useTableInputSelectionWorkaround(inputRef)

  if (isAddMode) {
    return (
      <FlexBox
        className={styles.formattedNumberInputWrapper}
        justifyContent={FlexBoxJustifyContent.End}
        alignItems={FlexBoxAlignItems.Center}
      >
        <Text>{currency}</Text>
        <FormattedNumberInput
          ref={inputRef}
          value={valueAmount}
          onChange={handleOnChange}
          onBlur={handleBlur}
          valueState={hasError && isTouched ? ValueState.Error : ValueState.None}
          valueStateMessage={<Text wrapping>{t('value-state.error')}</Text>}
        />
      </FlexBox>
    )
  }

  if (!valueAmount || !currency) {
    return null
  }
  return <Text>{formatCurrency(valueAmount, currency)}</Text>
}

PropertyValuationResultsValueAmountCell.propTypes = {
  valueAmount: PropTypes.number,
  currency: PropTypes.string,
  isAddMode: PropTypes.bool.isRequired,
}

export default PropertyValuationResultsValueAmountCell
