import compact from 'lodash.compact'
import uniq from 'lodash.uniq'
import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import { useFrontendPagination } from 'hooks/services/deals/useFrontendPagination'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * @enum
 */
const statusToValue = {
  ACTIVE: 1,
  POSTPONED: 2,
  PLANNED: 3,
  CLOSED: 4,
  DEFAULT: 5,
}

const extendItemsWithStatusOrder = (items = []) =>
  items.map((item) => ({
    ...item,
    statusOrder: statusToValue[item.status] ?? statusToValue.DEFAULT,
  }))

const extendItemsWithStatusOrderAndBPName = (items = [], businessPartnerData = []) =>
  items.map((item) => {
    const referenceEntityId = item?.monitoringReferenceEntity?.id
    const businessPartnerName =
      businessPartnerData.find((bp) => bp?.id === referenceEntityId)?.fullName ?? ''
    return {
      ...item,
      statusOrder: statusToValue[item.status] ?? statusToValue.DEFAULT,
      monitoringReferenceEntity: {
        ...item.monitoringReferenceEntity,
        nameWithId: referenceEntityId ? `${businessPartnerName}-${referenceEntityId}` : '',
      },
    }
  })

const initialCovenantChecksSort = [
  { orderField: 'statusOrder', orderDirection: 'asc' },
  { orderField: 'firstDeliveryDate', orderDirection: 'asc' },
]

/**
 * Request covenant checks
 *
 * @param {mainEntityTypes} mainEntityType - Type of the entity the covenant check is attached to
 * @param {string} [mainEntityId] - Identifier of the main entity
 * @param {mainEntityTypes} [monitoringReferenceEntityType] - Type of the entity that is monitored by the covenant check
 * @param {string} [eventId] - Referenced eventId
 * @param {string} [monitoringReferenceEntityId] - Identifier of the entity that is monitored by the covenant check
 * @returns {object} Camelized response containing the covenant checks
 */
const useCovenantChecks = ({
  mainEntityId,
  mainEntityType,
  monitoringReferenceEntityType,
  monitoringReferenceEntityId,
  eventId,
  options = {},
}) => {
  const queryParams = new URLSearchParams()
  queryParams.append('main_entity_type', mainEntityType)
  if (mainEntityId) {
    queryParams.append('main_entity_id', mainEntityId)
  }
  if (monitoringReferenceEntityType) {
    queryParams.append('monitoring_reference_entity_type', monitoringReferenceEntityType)
  }
  if (monitoringReferenceEntityId) {
    queryParams.append('monitoring_reference_entity_id', monitoringReferenceEntityId)
  }
  if (eventId) queryParams.append('event_id', eventId)

  return useCamelizedResponse(
    useRequest({
      path: `/covenants/covenant-checks?${queryParams.toString()}`,
      useCache: true,
      translated: true,
      keys: [
        'covenants',
        'covenant-checks',
        mainEntityType,
        mainEntityId,
        monitoringReferenceEntityType,
        monitoringReferenceEntityId,
        eventId,
      ],
      options: { enabled: !!mainEntityType, ...options },
    }),
  )
}

export const useCovenantChecksWithFrontendPagination = ({
  mainEntityId,
  mainEntityType,
  monitoringReferenceEntityType,
  monitoringReferenceEntityId,
  sort = {},
  pagination = {},
}) => {
  const covenantChecksDataKey = 'covenantChecks'
  const result = useCovenantChecks({
    mainEntityType,
    mainEntityId,
    monitoringReferenceEntityType,
    monitoringReferenceEntityId,
  })
  const covenantChecksList = result?.data?.[covenantChecksDataKey] ?? []
  const referenceEntityIds = compact(
    uniq(
      covenantChecksList.map(
        (covenantCheckData) => covenantCheckData?.monitoringReferenceEntity?.id,
      ),
    ),
  )
  const bpQueryEnabled =
    monitoringReferenceEntityType === mainEntityTypes.BUSINESSPARTNER &&
    referenceEntityIds.length > 0

  const businessPartnersResult = useBusinessPartnerMiniByIds(referenceEntityIds, {
    enabled: bpQueryEnabled,
  })

  const extendedItems =
    monitoringReferenceEntityType === mainEntityTypes.BUSINESSPARTNER
      ? extendItemsWithStatusOrderAndBPName(
          result?.data?.[covenantChecksDataKey],
          businessPartnersResult?.data?.businessPartnerMinis,
        )
      : extendItemsWithStatusOrder(result?.data?.[covenantChecksDataKey])
  const extendedResult = useMemo(
    () => ({
      ...result,
      isLoading: result.isLoading || (bpQueryEnabled && businessPartnersResult.isLoading),
      isFetching: result.isFetching || businessPartnersResult.isFetching,
      isError: result.isError || businessPartnersResult.isError,
      data: {
        ...result.data,
        [covenantChecksDataKey]: extendedItems,
      },
    }),
    [extendedItems, result, businessPartnersResult, bpQueryEnabled],
  )

  return useFrontendPagination({
    fetchResult: extendedResult,
    dataKey: covenantChecksDataKey,
    initialSort: initialCovenantChecksSort,
    sort: sort,
    pagination: pagination,
  })
}

export default useCovenantChecks
