import PropTypes from 'prop-types'
import ConditionsTableTypeEditCell from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableTypeEditCell'
import TableWrappingTextCell from 'components/domains/conditions/overview/table-cells/generic/TableWrappingTextCell'
import useConditionsCellEditMode from 'hooks/services/conditions/edit-mode/useConditionsCellEditMode'

const ConditionsTableTypeCell = ({ value: { typeName, typeCode, conditionId }, conditionType }) => {
  const { isEditModeForCurrentRow } = useConditionsCellEditMode({
    conditionId,
  })

  if (isEditModeForCurrentRow) {
    return (
      <ConditionsTableTypeEditCell
        conditionType={conditionType}
        typeCode={typeCode}
        typeName={typeName}
      />
    )
  }
  return <TableWrappingTextCell value={typeName} />
}

ConditionsTableTypeCell.propTypes = {
  value: PropTypes.shape({
    conditionId: PropTypes.string.isRequired,
    typeName: PropTypes.string.isRequired,
    typeCode: PropTypes.string.isRequired,
  }).isRequired,
  conditionType: PropTypes.string.isRequired,
}

export default ConditionsTableTypeCell
