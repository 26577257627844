import {
  Icon,
  Link,
  Modals,
  MessageBoxTypes,
  MessageBoxActions,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/fields/MoneyPercentageField.module.css'
import {
  usePercentageFormatter,
  useCustomizableCurrencyFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/shared/hooks/i18n/useI18n'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/shared/ui/card/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/shared/ui/card/DisplayCardViewItemView'

const MoneyPercentageField = ({
  label,
  amount,
  currency,
  percentage,
  isFirstItem,
  hasSeparator,
  hasError,
  showMarginLabel = true,
}) => {
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })
  const { t } = useTranslation('decisionPaper', { keyPrefix: 'tiles.financing-terms' })
  const formatMoney = useCustomizableCurrencyFormatter()
  const showMessageBox = Modals.useShowMessageBox()
  const marginLabel = t('pricing.margin')

  const handleShowErrorsDialog = () => {
    if (hasError) {
      showMessageBox(
        {
          type: MessageBoxTypes.Warning,
          titleText: t('errors-dialog.title'),
          children: t('errors-dialog.message'),
          actions: [MessageBoxActions.Close],
          draggable: true,
          resizable: false,
        },
        document.body,
      )
    }
  }

  const renderValues = () => {
    let formattedValue = '-'

    if (!isNil(amount) && currency) {
      formattedValue = formatMoney(amount, currency, {
        currencyDisplay: 'code',
      })
    }

    const marginLabelString = ` ${marginLabel}: `

    if (percentage) {
      formattedValue = `${formattedValue} ${hasSeparator ? ' / ' : ''}${
        showMarginLabel ? marginLabelString : ''
      }(${formatPercentage(percentage)})`
    }

    return formattedValue
  }

  return (
    <DisplayCardViewItemView>
      <DisplayCardViewInfoItemView label={label} isFirstItem={isFirstItem} value={renderValues()} />
      {hasError && (
        <div className={styles.warningIcon}>
          <Link onClick={handleShowErrorsDialog}>
            <Icon name="alert" />
          </Link>
        </div>
      )}
    </DisplayCardViewItemView>
  )
}

MoneyPercentageField.propTypes = {
  label: PropTypes.string,
  amount: PropTypes.number,
  currency: PropTypes.string,
  percentage: PropTypes.number,
  isFirstItem: PropTypes.bool,
  hasSeparator: PropTypes.bool,
  hasError: PropTypes.bool,
  showMarginLabel: PropTypes.bool,
}

export default MoneyPercentageField
