import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionProcessConditionsDecisionStatus } from 'api/decision-process/decisionProcessConditionsApi'
import DecisionStageConditionButtons from 'components/domains/business-events-and-tasks/decision-stage/conditions/DecisionStageConditionButtons'
import DecisionStageConditionsApprovalHeaderButtons from 'components/domains/business-events-and-tasks/decision-stage/conditions/DecisionStageConditionsApprovalHeaderButtons'
import styles from 'components/domains/business-events-and-tasks/decision-stage/conditions/DecisionStageConditionsPage.module.css'
import DecisionStageConditionsTable from 'components/domains/business-events-and-tasks/decision-stage/conditions/DecisionStageConditionsTable'
import DecisionStageConditionsVotersTable from 'components/domains/business-events-and-tasks/decision-stage/conditions/DecisionStageConditionsVotersTable'
import ConditionsInformationCard from 'components/domains/business-events-and-tasks/decision-stage/conditions/information/ConditionsInformationCard'
import Card from 'components/ui/card/Card'
import CWPLayout from 'components/ui/layout/CWPLayout'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionStageConditionsPage = ({ decisionStageId, decisionStageConditions }) => {
  const {
    event: { id: eventId },
  } = useContext(BusinessEventsAndTasksContext)
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.conditions',
  })

  const {
    info: { decisionStatus, decisionDate },
    decisionStageVoterConditions,
  } = decisionStageConditions

  return (
    <CWPLayout>
      <CWPLayout.Full>
        <FlexBox
          alignItems={FlexBoxAlignItems.Center}
          direction={FlexBoxDirection.Row}
          justifyContent={FlexBoxJustifyContent.SpaceBetween}
          className={styles.header}
        >
          <Title level={TitleLevel.H4} className={styles.headerText}>
            {t('title')}
          </Title>
          <FlexBox
            alignItems={FlexBoxAlignItems.Center}
            direction={FlexBoxDirection.Row}
            justifyContent={FlexBoxJustifyContent.End}
            className={styles.gapTiny}
          >
            <DecisionStageConditionsApprovalHeaderButtons
              decisionStageId={decisionStageId}
              decisionStatus={decisionStatus}
            />
            <DecisionStageConditionButtons
              decisionStageId={decisionStageId}
              decisionStatus={decisionStatus}
            />
          </FlexBox>
        </FlexBox>
      </CWPLayout.Full>
      <ConditionsInformationCard decisionStatus={decisionStatus} decisionDate={decisionDate} />
      <CWPLayout.Full>
        <Card>
          <DecisionStageConditionsVotersTable
            decisionStageId={decisionStageId}
            decisionStatus={decisionStatus}
          />
        </Card>
      </CWPLayout.Full>
      <CWPLayout.Full>
        <DecisionStageConditionsTable
          eventId={eventId}
          decisionStageId={decisionStageId}
          decisionStageVoterConditions={decisionStageVoterConditions}
          decisionStatus={decisionStatus}
        />
      </CWPLayout.Full>
    </CWPLayout>
  )
}

DecisionStageConditionsPage.propTypes = {
  decisionStageId: PropTypes.string.isRequired,
  decisionStageConditions: PropTypes.shape({
    id: PropTypes.string.isRequired,
    info: PropTypes.shape({
      decisionStatus: PropTypes.oneOf(Object.values(decisionProcessConditionsDecisionStatus))
        .isRequired,
      decisionDate: PropTypes.string,
    }).isRequired,
    decisionStageVoterConditions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        condition: PropTypes.string.isRequired,
        userId: PropTypes.string.isRequired,
        creationTime: PropTypes.string.isRequired,
        lastUpdatedAt: PropTypes.string,
        lastUpdatedBy: PropTypes.string,
      }),
    ).isRequired,
  }),
}

export default DecisionStageConditionsPage
