import camelize from 'camelize'
import snakecaseKeys from 'snakecase-keys'

const postPropertyValuationCreation = async ({
  postMethod,
  propertyId,
  calculationMethodCode,
  value,
  currency,
  keyDate,
  validFrom,
  validTo,
}) => {
  const { data } = await postMethod({
    path: `/properties/${propertyId}/valuations`,
    body: snakecaseKeys({
      calculationMethodCode,
      valueAmount: {
        number: value,
        currency,
      },
      keyDate,
      validFrom,
      validTo,
    }),
  })
  return camelize(data)
}

export default postPropertyValuationCreation
