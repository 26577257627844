import { FlexBox, Icon } from '@fioneer/ui5-webcomponents-react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import ErrorBoundary from 'components/ui/errors/ErrorBoundary'
import styles from 'components/ui/navigation/NavigationL2.module.css'
import NavigationL2Context from 'components/ui/navigation/NavigationL2Context'

const navItemClassNames = { sapUiSmallMargin: true, [styles.subMenuItem]: true }

/**
 * Renders a collapsible L2 Navigation Bar to the right of the L1 Navigation on the left
 *
 * `navItems: {path: string, translationToken: string, testIsActive: (activePath: string) => boolean}[]`: The navigation's links
 *
 * `isInitiallyExpanded: bool`: Is the navigation expanded by default (`true`)
 */
const NavigationL2 = ({ navItems, isInitiallyExpanded = true }) => {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded)
  const { pathname: locationPathname } = useLocation()

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  const toggleIcon = (newIsExpanded) => {
    const iconName = newIsExpanded ? 'slim-arrow-left' : 'slim-arrow-right'
    const iconClasses = [styles.railNavBarToggle]
    if (newIsExpanded) iconClasses.push(styles.expanded)

    return <Icon name={iconName} className={iconClasses.join(' ')} onClick={toggleExpanded} />
  }

  const l2NavClasses = classNames({
    sapScrollBar: true,
    [styles.subMenuContainer]: true,
    [styles.expanded]: isExpanded,
  })

  const navLinks = useMemo(
    () =>
      navItems?.map((item) => (
        <NavLink
          key={item.path}
          to={`${item.path}`}
          className={({ isActive }) =>
            classNames({
              ...navItemClassNames,
              active:
                typeof item.testIsActive === 'function'
                  ? item.testIsActive?.(locationPathname)
                  : isActive,
            })
          }
          end={item.path === ''}
        >
          {t(item.translationToken)}
        </NavLink>
      )),
    [locationPathname, navItems, t],
  )

  return (
    <FlexBox className={styles.l2NavWrapper}>
      <div className={l2NavClasses}>
        <div className={styles.l2NavItemsContainer}>{navLinks}</div>
      </div>
      {toggleIcon(isExpanded)}
      <div className={[styles.l2Content, 'sapScrollBar'].join(' ')}>
        <NavigationL2Context.Provider value={{ isExpanded }}>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </NavigationL2Context.Provider>
      </div>
    </FlexBox>
  )
}

NavigationL2.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      translationToken: PropTypes.string.isRequired,
      testIsActive: PropTypes.func,
    }),
  ),
  isInitiallyExpanded: PropTypes.bool,
}

export default NavigationL2
