import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getObjectStatusForEventStatus } from 'api/events/status'
import BusinessObjectLink, {
  BusinessObjectLinkCustomRender,
} from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import Header from 'components/ui/page/Header'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import styles from 'routes/business-events-and-tasks/decision-paper/DecisionPaper.module.css'
import paths from 'routes/paths'

const DecisionPaperHeader = ({ headerActions }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper',
  })

  const { event } = useContext(BusinessEventsAndTasksContext)
  const {
    id: eventId,
    info: { name: eventName },
    entityRef: { entityId, entityType },
    status: eventStatus,
  } = event

  const breadcrumbs = useMemo(
    () => [
      {
        text: t('breadcrumb-back'),
        href: `/${paths.businessEventsAndTasks}`,
      },
      {
        text: eventName,
        href: `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}`,
      },
      {
        text: tNoPrefix('breadcrumbs.business-events-and-tasks.decision-paper'),
      },
    ],
    [eventName, eventId, t, tNoPrefix],
  )

  const { translationKey: statusTranslationKey, objectStatus } = useMemo(
    () => getObjectStatusForEventStatus(eventStatus),
    [eventStatus],
  )

  const eventStatusForHeader = useMemo(
    () => ({
      text: tNoPrefix(statusTranslationKey),
      valueState: objectStatus,
    }),
    [tNoPrefix, objectStatus, statusTranslationKey],
  )

  const subtitle = useMemo(
    () => (
      <BusinessObjectLink
        entityId={entityId}
        entityType={entityType}
        customRender={BusinessObjectLinkCustomRender.clipboard}
      />
    ),
    [entityId, entityType],
  )

  return (
    <Header
      title={eventName}
      breadcrumbs={breadcrumbs}
      status={eventStatusForHeader}
      subtitle={subtitle}
      actions={headerActions}
      className={styles.pageHeader}
    />
  )
}

DecisionPaperHeader.propTypes = {
  headerActions: PropTypes.node,
}

export default DecisionPaperHeader
