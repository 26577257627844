import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import defaultCellStyles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/cells/DefaultCellStyles.module.css'
import { useCustomizableCurrencyFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'

const MoneyCell = ({ value, isBold = false, numberSuffix }) => {
  const formatMoney = useCustomizableCurrencyFormatter()

  if (!Array.isArray(value)) {
    value = [value]
  }

  // PBB custom logic
  const convertAmount = (amount, customNumberSuffix) => {
    const kDivider = 1_000
    const mDivider = 1_000_000
    switch (customNumberSuffix) {
      case 'k':
        return amount / kDivider
      case 'M':
        return amount / mDivider
      default:
        return amount
    }
  }

  // PBB custom logic
  const concatenateNumberSuffix = (amountCurrencyString, customNumberSuffix) => {
    if (isNil(customNumberSuffix)) {
      return amountCurrencyString
    }
    const regex = /(\d+)(?!.*\d)/
    return amountCurrencyString.replace(regex, (s) => s + customNumberSuffix)
  }

  return (
    <div className={defaultCellStyles.columnFlexBox}>
      {value.map((item, index) => (
        <div key={index} className={isBold ? defaultCellStyles.boldFont : ''}>
          {!isNil(item?.amount) ? (
            concatenateNumberSuffix(
              formatMoney(convertAmount(item.amount, numberSuffix), item.currency, {
                currencyDisplay: 'code',
              }),
              numberSuffix,
            )
          ) : (
            <>&nbsp;</>
          )}
        </div>
      ))}
    </div>
  )
}

MoneyCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.shape({ name: PropTypes.string, currency: PropTypes.string }),
    PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, currency: PropTypes.string })),
  ]).isRequired,
  isBold: PropTypes.bool,
  numberSuffix: PropTypes.string,
}
export default MoneyCell
