// counts all rows that do not have subrows
/**
 * @typedef {{subRows: Row[]}} Row
 * @param {Row[]} [rows]
 * @returns {number}
 */
const countNrOfEntries = (rows) => {
  if (!rows) return 0
  return rows.reduce((count, current) => {
    if (current.subRows.length > 0) {
      return count + countNrOfEntries(current.subRows)
    } else {
      return count + 1
    }
  }, 0)
}

// subtracting -1 since we dont want to count the total row
/** @param {Row[]} [mappedTableData] */
export const countNumberOfSubRows = (mappedTableData) =>
  Math.max(countNrOfEntries(mappedTableData) - 1, 0)
