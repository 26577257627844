import { Link, WrappingType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PropertyRentRollOverviewCurrentRentText from 'components/domains/properties/rent-roll/overview/current-rent/PropertyRentRollOverviewCurrentRentText'
import styles from 'components/domains/properties/rent-roll/overview/current-rent/PropertyRentRollOverviewPrimaryTenant.module.css'
import LoadingContent from 'components/ui/content/LoadingContent'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'

const PropertyRentRollOverviewPrimaryTenant = ({ primaryTenantId }) => {
  const { t: tCurrentRent } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.current-rent',
  })

  const {
    isLoading: isLoadingBusinessPartner,
    isError: isErrorBusinessPartner,
    data: businessPartnerData,
  } = useBusinessPartnerMiniByIds([primaryTenantId])
  const businessPartner = businessPartnerData?.businessPartnerMinis?.find(
    (bp) => bp.id === primaryTenantId,
  )

  const renderBusinessPartnerLink = () => {
    if (businessPartner?.fullName) {
      return (
        <Link
          className={styles.primaryTenantLink}
          wrappingType={WrappingType.Normal}
          href={`/business-partners/${primaryTenantId}`}
          target="_blank"
        >
          {businessPartner?.fullName}
        </Link>
      )
    } else {
      return <PropertyRentRollOverviewCurrentRentText text={tCurrentRent('not-allocated')} />
    }
  }

  if (primaryTenantId) {
    return (
      <LoadingContent
        contentKey="primary-tenant"
        isError={isErrorBusinessPartner}
        isLoading={isLoadingBusinessPartner}
        errorContent={<PropertyRentRollOverviewCurrentRentText text={tCurrentRent('error')} />}
      >
        {renderBusinessPartnerLink()}
      </LoadingContent>
    )
  } else {
    return tCurrentRent('anonymous-tenant')
  }
}
PropertyRentRollOverviewPrimaryTenant.propTypes = {
  primaryTenantId: PropTypes.string,
}
export default PropertyRentRollOverviewPrimaryTenant
