const useMapPropertyPortfolioValuations = ({ valuationSums = {} }) => {
  const valuationsGroupedByCategory = Object.entries(valuationSums ?? {}).reduce(
    (valuationGroups, item) => {
      const [valuationType, valuationSum] = item
      const mappedValuationObject = {
        ...valuationSum,
        cwpCode: valuationType,
      }
      valuationGroups[valuationSum?.category] = [
        ...(valuationGroups[valuationSum?.category] ?? []),
        mappedValuationObject,
      ]
      return valuationGroups
    },
    {},
  )

  return (
    Object.entries(valuationsGroupedByCategory).flatMap(([category, valuationSumData]) => [
      { category, isSectionHeader: true },
      ...valuationSumData,
    ]) ?? []
  )
}

export default useMapPropertyPortfolioValuations
