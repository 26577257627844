import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const MonitoringReferenceTypeSelect = ({ value, onChange }) => {
  const { t } = useTranslation('translation')

  const MonitoringReferenceTypeTranslations = {
    DEAL: t('pages.deals.table.deal'),
    BUSINESSPARTNER: t('pages.deals.covenants.business-partner'),
  }

  return (
    <Select onChange={(event) => onChange(event.detail.selectedOption.value)}>
      {Object.keys(MonitoringReferenceTypeTranslations).map((type) => (
        <Option key={type} value={type} selected={value === type}>
          {MonitoringReferenceTypeTranslations[type]}
        </Option>
      ))}
    </Select>
  )
}

MonitoringReferenceTypeSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default MonitoringReferenceTypeSelect
