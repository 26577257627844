import PropTypes from 'prop-types'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'

/**
 * @param {object} options
 * @param {string} options.entityName
 * @param {string[]} options.entityColors
 * @param {string} options.groupedEntitiesColor
 * @returns {object}
 */
const createChartColorContext = ({ entityName, entityColors, groupedEntitiesColor }) => {
  const providerName = `${entityName}ColorProvider`
  const hookName = `use${entityName}Colors`

  const noProviderErrorFn = () => {
    throw new Error(
      `'${hookName}' can only be used in a component that is a child of <${providerName}>`,
    )
  }

  const ChartColorContext = createContext({
    getColorForId: noProviderErrorFn,
    getGroupedColor: noProviderErrorFn,
  })

  const ChartColorContextProvider = ({ children, triggerResetColors = false }) => {
    const [allEntityColors, setAllEntityColors] = useState([...entityColors])
    const [chartColorByIdMap, setChartColorByIdMap] = useState(new Map())

    useEffect(() => {
      triggerResetColors && setAllEntityColors([...entityColors])
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerResetColors])

    const getGroupedColor = useCallback(() => groupedEntitiesColor, [])

    const getColorForId = useCallback(
      (id) => {
        if (!chartColorByIdMap.has(id)) {
          const temporaryColors = allEntityColors
          const nextColor = temporaryColors.shift()

          if (!nextColor) {
            // eslint-disable-next-line no-console
            console.error(
              `${providerName}: Attempted to assign more colors than available (total available: ${entityColors}, used: ${chartColorByIdMap.size})`,
            )
          }

          setAllEntityColors(temporaryColors)
          setChartColorByIdMap(chartColorByIdMap.set(id, nextColor))
        }

        return chartColorByIdMap.get(id)
      },
      [allEntityColors, chartColorByIdMap],
    )

    return (
      <ChartColorContext.Provider value={{ getColorForId, getGroupedColor }}>
        {children}
      </ChartColorContext.Provider>
    )
  }

  ChartColorContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
    triggerResetColors: PropTypes.bool,
  }

  ChartColorContextProvider.displayName = providerName

  const useChartColor = () => useContext(ChartColorContext)

  return {
    [providerName]: ChartColorContextProvider,
    [hookName]: useChartColor,
  }
}

export default createChartColorContext
