import {
  TextAlign,
  VerticalAlign,
  FlexBox,
  FlexBoxDirection,
  WrappingType,
  Label,
} from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedParameterValueText } from 'components/domains/deals/deal-cashflow/parameters/FormattedParameterValueText'
import styles from 'components/domains/deals/deal-cashflow/parameters/useCashflowParametersColumns.module.css'

export const useCashflowParametersColumns = (isPdfView = false) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.cashflow.used-params',
  })

  const colWidthValues = useMemo(
    () =>
      isPdfView
        ? {
            type: 150,
            parameter: 300,
            value: 200,
          }
        : {
            type: 150,
            parameter: 400,
            value: 350,
          },
    [isPdfView],
  )

  return [
    {
      Header: t('cash-flow-object'),
      accessor: 'name',
      Cell: (cellProps) => (
        <Label className={styles['object-cell']} wrappingType={WrappingType.Normal}>
          {cellProps.cell.value}
        </Label>
      ),
      disableDragAndDrop: true,
      vAlign: VerticalAlign.Top,
    },
    {
      Header: t('object-type'),
      accessor: 'type',
      width: colWidthValues['type'],
      disableDragAndDrop: true,
      vAlign: VerticalAlign.Top,
      Cell: (cellProps) => (
        <Label className={styles['object-type-cell']} wrappingType={WrappingType.Normal}>
          {cellProps.cell.value}
        </Label>
      ),
    },
    {
      Header: t('parameter'),
      accessor: 'parameter',
      width: colWidthValues['parameter'],
      disableDragAndDrop: true,
      vAlign: VerticalAlign.Top,
      Cell: (cellProps) => (
        <FlexBox direction={FlexBoxDirection.Column} className={styles['parameter-cell']}>
          {cellProps.cell.value?.map((parameter, idx) => (
            <Label key={idx} wrappingType={WrappingType.Normal}>
              {parameter.label}
            </Label>
          ))}
        </FlexBox>
      ),
    },
    {
      Header: t('value'),
      accessor: 'value',
      width: colWidthValues['value'],
      hAlign: TextAlign.Right,
      disableDragAndDrop: true,
      vAlign: VerticalAlign.Top,
      Cell: (cellProps) => (
        <FlexBox direction={FlexBoxDirection.Column} className={styles['value-cell']}>
          {cellProps.cell.value?.map((parameter, idx) => (
            <FormattedParameterValueText
              key={idx}
              parameter={parameter}
              className={styles['value-element-cell']}
            />
          ))}
        </FlexBox>
      ),
    },
  ]
}
