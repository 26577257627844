import { Label, ObjectStatus, ValueState } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { decisionPaperSynchronizationStatus } from 'api/decision-paper/decisionPaperApi'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useCurrentDecisionPaper from 'hooks/services/business-events-and-tasks/decision-papers/useCurrentDecisionPaper'

const SynchronizationStatus = ({ eventId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.monitoring-event-card',
  })

  const {
    data: decisionPaper,
    isFetching: isLoadingDecisionPaper,
    isError: isErrorDecisionPaper,
  } = useCurrentDecisionPaper(eventId, {
    enabled: !!eventId,
  })

  return (
    <SmallLoadingWrapper
      isLoading={isLoadingDecisionPaper}
      isError={isErrorDecisionPaper}
      error={<Label>{'-'}</Label>}
      renderContent={() => {
        if (decisionPaper?.synchronizationStatus === decisionPaperSynchronizationStatus.live) {
          return (
            <ObjectStatus inverted state={ValueState.Success}>
              {t('in-sync')}
            </ObjectStatus>
          )
        }
        return (
          <ObjectStatus inverted state={ValueState.Error}>
            {t('not-in-sync')}
          </ObjectStatus>
        )
      }}
    />
  )
}

SynchronizationStatus.propTypes = {
  eventId: PropTypes.string,
}

export default SynchronizationStatus
