import { AnalyticalTable, FlexBox } from '@fioneer/ui5-webcomponents-react'
import { isArray, isObject } from 'lodash'
import get from 'lodash.get'
import isEmpty from 'lodash.isempty'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/analytical/AssetValuationOverviewAnalyticalTableWithToolbar.module.css'
import DragAndDropZone from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/analytical/AssetValuationOverviewDragAndDropZone'
import TablesToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/toolbar/AssetValuationOverviewTablesToolbar'
import useFilters from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/useAssetValuationOverviewFilters'

const buildFilter = (filterType, filterForType, additionalFilterOptions) => {
  if (!filterType) return (rows) => rows
  const { filterFunction } = filterForType(filterType)

  // The current filter mechanism only matches leaf rows if the table is a tree table
  const filterRows = (rows, accessors, filterValue) =>
    rows.filter((row) =>
      isEmpty(row.subRows)
        ? filterFunction({
            cell: { value: row.values[accessors[0]] },
            searchValue: filterValue,
            additionalFilterOptions,
          })
        : filterRows(row.subRows, accessors, filterValue)?.length,
    )
  return filterRows
}

const getAdditionalFilterOptions = (additionalFilterOptions, isLoadingEnumFilterValues) => {
  if (!additionalFilterOptions) {
    return {}
  }
  const expandedAdditionalFilterOptions = {
    ...additionalFilterOptions,
    enumValues: !isLoadingEnumFilterValues ? additionalFilterOptions?.enumValues : {},
  }
  return { additionalFilterOptions: expandedAdditionalFilterOptions }
}

const isFilterUnset = (value) => value === null || value === undefined || value === ''

const itemsAreInvalid = (items) => !items || items.every((item) => isFilterUnset(item))

const transformToCorrectEmptyValue = (filter) => {
  const transformed = { ...filter }
  if (!filter || get(filter, 'value') === null || isFilterUnset(filter.value)) {
    transformed.value = ''
    return transformed
  }

  const { value } = filter
  if (isObject(value)) {
    if (isFilterUnset(value.lowerBound)) {
      transformed.value.lowerBound = ''
    }
    if (isFilterUnset(value.upperBound)) {
      transformed.value.upperBound = ''
    }
  }

  if (isArray(value) && itemsAreInvalid(value)) {
    transformed.value = []
  }
  return transformed
}

const buildFiltering = (tableRef, filterForType, isLoadingEnumFilterValues = false) => {
  if (!tableRef?.current) {
    return null
  }
  const { allColumns, state = {}, setAllFilters } = tableRef.current
  const { filters: filterState = [] } = state
  const filters = allColumns
    .filter(({ canFilter }) => canFilter)
    .sort((a, b) => (a.dialogOrder || 0) - (b.dialogOrder || 0))
    .map(
      ({
        id,
        Header,
        filterLabel,
        filterType = 'CONTAINS',
        filterFunction,
        additionalFilterOptions,
      }) => ({
        value: filterState.find((filter) => filter.id === id)?.value,
        ...filterForType(filterType),
        ...(filterFunction ? { filterFunction } : {}),
        ...getAdditionalFilterOptions(additionalFilterOptions, isLoadingEnumFilterValues),
        columnKey: id,
        label: filterLabel || Header,
      }),
    )
  if (filters.length < 1) {
    return null
  }
  return {
    filters,
    setFilters: (oldFilters) => {
      // Allows previously expanded rows with subcomponents to be expanded in their height when rendering them again after filtering
      delete tableRef.current.state?.subComponentsHeight
      const convertedFilters = oldFilters
        .map(transformToCorrectEmptyValue)
        .filter((it) => !!it.value && !isEqual(it.value, it.emptyValue))
        .map(({ columnKey, value }) => ({ id: columnKey, value }))
      setAllFilters(convertedFilters)
    },
  }
}

const buildSorting = (tableRef) => {
  if (!tableRef?.current) {
    return null
  }
  const { allColumns, state = {}, setSortBy } = tableRef.current
  const { sortBy = [] } = state
  const sortableColumns = allColumns
    .filter(({ canSort }) => canSort)
    .filter(({ hide }) => !hide)
    .sort((a, b) => (a.dialogOrder || 0) - (b.dialogOrder || 0))
    .map(({ id, sortByLabel, Header }) => ({ columnKey: id, title: sortByLabel || Header }))
  if (sortableColumns.length < 1) {
    return null
  }
  return {
    columnKey: sortBy[0]?.id,
    isSortingAscending: !sortBy[0]?.desc,
    onUpdateSorting: ({ sortBy: updatedSortBy, sortDescending }) => {
      // Allows previously expanded rows with subcomponents to be expanded in their height when rendering them again after sorting
      delete tableRef.current.state?.subComponentsHeight
      const columnKey = sortableColumns.find((column) => column.title === updatedSortBy)?.columnKey
      setSortBy([{ id: columnKey, desc: sortDescending }])
    },
    sortableColumns: sortableColumns,
  }
}

export const buildAssetValuationOverviewSortingWithHiddenColumns = (tableRef) => {
  if (!tableRef?.current) {
    return null
  }

  const { allColumns, state = {}, setSortBy } = tableRef.current
  const { sortBy = [] } = state

  const sortableColumns = allColumns
    .filter(({ canSort }) => canSort)
    .sort((a, b) => (a.dialogOrder || 0) - (b.dialogOrder || 0))
    .map(({ id, sortByLabel, Header }) => ({ columnKey: id, title: sortByLabel || Header }))

  if (sortableColumns.length === 0) {
    return null
  }

  const onUpdateSorting = ({ sortBy: updatedSortBy, sortDescending }) => {
    // Allows previously expanded rows with subcomponents to be expanded in their height when rendering them again after sorting
    delete tableRef.current.state?.subComponentsHeight
    const columnKey = sortableColumns.find((column) => column.title === updatedSortBy)?.columnKey
    setSortBy([{ id: columnKey, desc: sortDescending }])
  }

  return {
    columnKey: sortBy[0]?.id,
    isSortingAscending: !sortBy[0]?.desc,
    onUpdateSorting: onUpdateSorting,
    sortableColumns: sortableColumns,
  }
}

const AnalyticalTableWithToolbar = React.forwardRef(
  (
    {
      title,
      columns,
      data,
      nrOfEntries,
      calculateNrOfEntries,
      showNrOfEntries = true,
      className,
      style,
      tableClassName,
      toolbarClassName,
      groupable,
      filterable,
      showColumnSelection = false,
      customSorting,
      handleOnDrop,
      dragAndDrop = false,
      hasDocumentUploadPermission = false,
      minRows = 0,
      disableColumnPopover = false,
      reactTableOptions = {},
      isPdfView = false,
      ...additionalTableConfig
    },
    ref,
  ) => {
    const tableRef = useRef()
    useImperativeHandle(ref, () => tableRef?.current)

    const { filterForType: _filterForType } = useFilters()
    const filterForType = useRef(_filterForType)

    const [updateGroupingTo, setUpdateGroupingTo] = useState(null)
    const [grouping, setGrouping] = useState(null)
    const [isInitialGrouping, setIsInitialGrouping] = useState(true)

    const updateActiveGrouping = useCallback(
      (activeColumns) => {
        if (groupable && tableRef.current) {
          setGrouping((prev) => ({
            ...prev,
            groups: prev.groups.map((group) => ({
              ...group,
              isGrouped: activeColumns.includes(group.id),
            })),
          }))
        }
      },
      [groupable],
    )

    const extractAndSetGrouping = useCallback(() => {
      if (groupable && tableRef.current) {
        const { allColumns, toggleHideColumn, state = {} } = tableRef.current
        const { hiddenColumns = [] } = state
        const groups = allColumns
          .filter(({ canGroupBy }) => canGroupBy)
          .sort((a, b) => (a.dialogOrder || 0) - (b.dialogOrder || 0))
          .map(({ groupByLabel, Header, id, isGrouped }) => ({
            label: groupByLabel || Header,
            id,
            isGrouped: !!isGrouped,
          }))
        setGrouping({
          groups,
          setGroupBy: (column) => {
            if (hiddenColumns.includes(column)) {
              toggleHideColumn(column)
            }
            const value = column ? [column] : []
            setUpdateGroupingTo(value)
            updateActiveGrouping(value)
          },
        })
      }
    }, [groupable, updateActiveGrouping])

    useEffect(() => {
      if (updateGroupingTo) {
        const { setGroupBy } = tableRef.current
        setGroupBy(updateGroupingTo)
        setUpdateGroupingTo(null)
        updateActiveGrouping([])
      }
      if (isInitialGrouping) {
        extractAndSetGrouping()
        setIsInitialGrouping(false)
      }
    }, [updateGroupingTo, extractAndSetGrouping, updateActiveGrouping, isInitialGrouping])

    const [sorting, setSorting] = useState(customSorting)
    useEffect(() => {
      if (customSorting) {
        setSorting(customSorting)
      } else {
        setSorting(buildSorting(tableRef))
      }
    }, [customSorting, setSorting])

    const { additionalActions, isLoadingEnumFilterValues, ...additionalTableConfigWithoutActions } =
      additionalTableConfig

    const checkFilterChangedTimeout = 1000
    const filters = useRef()
    const [filteredTableRows, setFilteredTableRows] = useState(undefined)
    useEffect(() => {
      const updateTablesToolbarInterval = setInterval(() => {
        if (tableRef.current?.state?.filters !== filters.current) {
          setFilteredTableRows(tableRef.current?.filteredRows)
          filters.current = tableRef.current?.state?.filters
        }
      }, checkFilterChangedTimeout)
      return () => {
        clearInterval(updateTablesToolbarInterval)
      }
    }, [])

    const [filtering, setFiltering] = useState(undefined)
    useEffect(() => {
      if (!filterable) return
      setFiltering(buildFiltering(tableRef, filterForType.current, isLoadingEnumFilterValues))
    }, [filterForType, filterable, isLoadingEnumFilterValues, filteredTableRows])

    useLayoutEffect(() => {
      if (tableRef.current) {
        const { allColumns, setHiddenColumns, state } = tableRef.current
        const { groupBy = [] } = state
        const hiddenColumns = allColumns
          .filter(({ hide }) => hide)
          .map(({ id }) => id)
          .filter((id) => !groupBy.includes(id))
        setHiddenColumns(hiddenColumns)
      }
      if (disableColumnPopover) {
        require('components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/analytical/AssetValuationOverviewDisableColumnHeaderModalPopover.module.css')
      }
    }, [disableColumnPopover, sorting, grouping, filtering])

    const parsedColumns = useMemo(
      () =>
        filtering
          ? columns.map(({ filterType, additionalFilterOptions, ...rest }) => ({
              filter: buildFilter(filterType, filterForType.current, additionalFilterOptions),
              filterType,
              ...(additionalFilterOptions ? { additionalFilterOptions } : {}),
              ...rest,
            }))
          : columns,
      [columns, filtering],
    )

    const isFilterApplied = !!tableRef.current?.state?.filters.length
    const nrOfFilteredTableRows = isFilterApplied ? filteredTableRows?.length : null
    const nrOfAllRows = data.length

    const calculatedNrOfEntries = useMemo(() => {
      if (typeof calculateNrOfEntries === 'function') {
        if (isFilterApplied) return calculateNrOfEntries(filteredTableRows)
        return calculateNrOfEntries(data)
      }
      return nrOfEntries
    }, [calculateNrOfEntries, data, nrOfEntries, filteredTableRows, isFilterApplied])

    /**
     * Expands all subRows, but only when subRows exist.
     * This distinction is necessary, because rowSubComponents also get expanded and this is not wanted after filtering.
     */
    const onSubmitFilters = ({ nrOfSelectedFilters }) => {
      const hasSubrows = tableRef.current.rows?.some((row) => !isEmpty(row.subRows))
      if (nrOfSelectedFilters > 0 && hasSubrows) {
        tableRef?.current?.toggleAllRowsExpanded(true)
      }
    }

    /** @param {any[]} cols */
    const getColumnSelection = (cols) =>
      cols.map((col) => ({ ...col, columnKey: col.id ?? col.accessor }))

    /**
     * @typedef {object} columnSelection
     * @property {string} columnKey
     * @property {string?} [title]
     * @property {boolean?} [isVisible]
     * @property {boolean?} [isSelectableForHiding]
     */
    const [columnSelection, setColumnSelection] = useState(
      /** @type {columnSelection[]} */ (getColumnSelection(parsedColumns)),
    )

    useEffect(() => {
      setColumnSelection(getColumnSelection(parsedColumns))
    }, [parsedColumns])

    const visibleColumns = useMemo(
      () => columnSelection.filter(({ isVisible }) => isVisible ?? true),
      [columnSelection],
    )

    const numberOfEntriesToDisplay = showNrOfEntries
      ? calculatedNrOfEntries ?? nrOfFilteredTableRows ?? nrOfAllRows
      : undefined

    return (
      <FlexBox className={className} style={style} direction="Column">
        <TablesToolbar
          className={toolbarClassName}
          title={title}
          nrOfEntries={numberOfEntriesToDisplay}
          sorting={!isPdfView ? sorting : undefined}
          filtering={filtering}
          grouping={grouping}
          toolbarStyle="Standard"
          additionalActions={additionalActions}
          onSubmitFilters={onSubmitFilters}
          columnSelection={columnSelection}
          setColumnSelection={setColumnSelection}
          showColumnSelection={showColumnSelection}
        />
        {dragAndDrop && hasDocumentUploadPermission && (
          <DragAndDropZone handleOnDrop={handleOnDrop} />
        )}
        <AnalyticalTable
          minRows={minRows}
          className={`${tableClassName || ''} ${styles['analytical-table']} ${
            additionalTableConfig?.isTreeTable ? 'tree-table' : ''
          } ${isPdfView ? 'pdf-view' : ''}`.trim()}
          columns={showColumnSelection ? visibleColumns : parsedColumns}
          data={data}
          groupable={groupable}
          filterable={filterable}
          {...additionalTableConfigWithoutActions}
          tableInstance={tableRef}
          reactTableOptions={{
            autoResetHiddenColumns: false,
            ...reactTableOptions,
          }}
        />
      </FlexBox>
    )
  },
)

AnalyticalTableWithToolbar.displayName = 'AnalyticalTableWithToolbar'

AnalyticalTableWithToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  nrOfEntries: PropTypes.number,
  calculateNrOfEntries: PropTypes.func,
  showNrOfEntries: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  tableClassName: PropTypes.string,
  toolbarClassName: PropTypes.string,
  reactTableOptions: PropTypes.object,
  groupable: PropTypes.bool,
  filterable: PropTypes.bool,
  showColumnSelection: PropTypes.bool,
  isPdfView: PropTypes.bool,
  minRows: PropTypes.number,
  customSorting: PropTypes.shape({
    columnKey: PropTypes.string.isRequired,
    isSortingAscending: PropTypes.bool,
    sortableColumns: PropTypes.arrayOf(
      PropTypes.shape({ title: PropTypes.string, columnKey: PropTypes.string }),
    ).isRequired,
    onUpdateSorting: PropTypes.func.isRequired,
  }),
  disableColumnPopover: PropTypes.bool,
  handleOnDrop: PropTypes.func,
  dragAndDrop: PropTypes.bool,
}

export default AnalyticalTableWithToolbar
