import { FlexBox, FlexBoxDirection, Link } from '@fioneer/ui5-webcomponents-react'
import parse from 'html-react-parser'
import orderBy from 'lodash.orderby'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/custom-assessment/esg-statement/EsgStatementTile.module.css'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/custom-assessment/esg-statement/shared/EmptyCardContent'
import ImageView from 'components/domains/business-events-and-tasks/decision-paper/tiles/custom-assessment/esg-statement/shared/ImageView'

const characterAmount = 1000

const EsgStatementTile = ({ tileId, selectedDealIndex }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.portfolio-financed-assets-area',
  })

  const { data: { multipleEsgStatements = [] } = {} } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId],
  )
  const { esgStatements } = multipleEsgStatements?.[selectedDealIndex] ?? {}
  const lastEsgStatement = orderBy(esgStatements?.versions, (v) => v.updatedAt, 'desc')[0]?.text

  const [isTextExpanded, setIsTextExpanded] = useState(false)

  const toggleShowMore = () => {
    setIsTextExpanded(!isTextExpanded)
  }

  const renderContent = () =>
    lastEsgStatement?.length <= characterAmount || isTextExpanded
      ? lastEsgStatement
      : lastEsgStatement?.substring(0, characterAmount)

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.commentWrapper}>
      {lastEsgStatement ? (
        <div className={styles.wrapper}>
          <div className={`ck-content ${styles.contentWrapper}`}>
            {parse(renderContent(), {
              replace: ({ name, attribs }) => {
                if (name === 'img') {
                  return <ImageView {...attribs} />
                }
              },
            })}
          </div>
          {lastEsgStatement?.length > characterAmount && (
            <Link className={styles.toggleShowMoreLink} onClick={toggleShowMore}>
              {isTextExpanded ? t('buttons.less') : t('buttons.more')}
            </Link>
          )}
        </div>
      ) : (
        <EmptyCardContent />
      )}
    </FlexBox>
  )
}

EsgStatementTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}
export default EsgStatementTile
