import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import DecisionStageApprovalUnknownButton from 'components/domains/business-events-and-tasks/decision-stage/approval/shared/DecisionStageApprovalUnknownButton'
import DecisionStageMinutesApprovalApproveButton from 'components/domains/business-events-and-tasks/decision-stage/minutes/approval/buttons/DecisionStageMinutesApprovalApproveButton'
import DecisionStageMinutesApprovalVoterApproveButton from 'components/domains/business-events-and-tasks/decision-stage/minutes/approval/buttons/voter/DecisionStageMinutesApprovalVoterApproveButton'

export const decisionProcessMinutesDecisionStatus = {
  pending: 'PENDING',
  inProgress: 'IN_PROGRESS',
  inProgressRequestDiscarded: 'IN_PROGRESS_REQUEST_DISCARDED',
  approved: 'APPROVED',
}

const objectStatusForMinutesDecisionStatus = {
  [decisionProcessMinutesDecisionStatus.pending]: {
    translationKey: 'decision-stage.minutes.status.pending',
    objectStatus: ValueState.None,
  },
  [decisionProcessMinutesDecisionStatus.inProgress]: {
    translationKey: 'decision-stage.minutes.status.in-progress',
    objectStatus: ValueState.Information,
  },
  [decisionProcessMinutesDecisionStatus.inProgressRequestDiscarded]: {
    translationKey: 'decision-stage.minutes.status.in-progress',
    objectStatus: ValueState.Information,
  },
  [decisionProcessMinutesDecisionStatus.approved]: {
    translationKey: 'decision-stage.minutes.status.approved',
    objectStatus: ValueState.Success,
  },
}

const defaultDecisionStageMinutesDecisionStatusObjectStatus = {
  translationKey: 'decision-stage.minutes.status.unknown',
  objectStatus: ValueState.None,
}

export const getObjectStatusforDecisionStageMinutesStatus = (minutesStatus) =>
  objectStatusForMinutesDecisionStatus[minutesStatus] ??
  defaultDecisionStageMinutesDecisionStatusObjectStatus

export const decisionStageMinutesVoteOptions = {
  approve: 'APPROVE',
}

const defaultDecisionStageMinutesVoteOptionButton = DecisionStageApprovalUnknownButton

const buttonForDecisionStageMinutesVoteOptions = {
  [decisionStageMinutesVoteOptions.approve]: DecisionStageMinutesApprovalApproveButton,
}

export const getButtonForDecisionStageMinutesVoteOption = (decisionStageMinutesVoteOption) =>
  buttonForDecisionStageMinutesVoteOptions[decisionStageMinutesVoteOption] ??
  defaultDecisionStageMinutesVoteOptionButton

export const decisionStageMinutesVoterVoteOptions = {
  approve: 'APPROVE',
}

const defaultDecisionStageMinutesVoterVoteOptionButton = DecisionStageApprovalUnknownButton

const buttonForDecisionStageMinutesVoterVoteOptions = {
  [decisionStageMinutesVoterVoteOptions.approve]: DecisionStageMinutesApprovalVoterApproveButton,
}

export const getButtonForDecisionStageMinutesVoterVoteOption = (
  decisionStageMinutesVoterVoteOption,
) =>
  buttonForDecisionStageMinutesVoterVoteOptions[decisionStageMinutesVoterVoteOption] ??
  defaultDecisionStageMinutesVoterVoteOptionButton
