const useGetTrancheWithHighestCommitment = (tranches) => {
  let highestCommitment = Number.MIN_SAFE_INTEGER
  let displayedTranche = undefined
  tranches?.forEach((tranche) => {
    const commitment =
      tranche?.totalCommitmentHeadquarter?.amount ?? tranche?.totalCommitment?.amount

    if (commitment && commitment > highestCommitment) {
      highestCommitment = commitment
      displayedTranche = tranche
    }
  })
  return displayedTranche
}

export default useGetTrancheWithHighestCommitment
