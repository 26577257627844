import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PropertyRentRollLiveKpisCard from 'components/domains/properties/rent-roll/working-version/live-kpis/PropertyRentRollLiveKpisCard'
import styles from 'components/domains/properties/rent-roll/working-version/live-kpis/PropertyRentRollLiveKpisCard.module.css'
import { useAreaMeasurementUnitFormatter, useNumberFormatter } from 'hooks/i18n/useI18n'
import useCurrentMultiPropertyKpis from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpis'

const PropertyRentRollLiveKpisCards = ({
  kpis,
  isErrorLiveKpis,
  isLoadingLiveKpis,
  propertyUuids,
}) => {
  const { annualizedCurrentRent, totalAreaSurface, vacancySurface, waultToBreakInYears } = kpis

  const {
    data: dataCurrentKpis,
    isError: isErrorCurrentKpis,
    isLoading: isLoadingCurrentKpis,
  } = useCurrentMultiPropertyKpis(propertyUuids)
  const currentKpis = !isErrorCurrentKpis && !isLoadingCurrentKpis ? dataCurrentKpis : []

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.rent-roll-working-version.live-kpis-card',
  })
  const formatArea = useAreaMeasurementUnitFormatter()
  const formatNumber = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: 'compact',
  })
  const formatYear = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const getValueOrUndefined = (value) => (value ? value : undefined)
  const getUnitOrUndefined = (unit) => (unit ? formatArea(unit) : undefined)

  const cards = [
    {
      title: t('current-rent'),
      workingVersionMetric: {
        unit: getValueOrUndefined(annualizedCurrentRent?.currency),
        value: getValueOrUndefined(annualizedCurrentRent?.number),
      },
      currentMetricValue: getValueOrUndefined(currentKpis?.annualizedCurrentRent?.number),
      format: formatNumber,
    },
    {
      title: t('total-area'),
      workingVersionMetric: {
        unit: getUnitOrUndefined(totalAreaSurface?.measurementUnit),
        value: getValueOrUndefined(totalAreaSurface?.value),
      },
      currentMetricValue: getValueOrUndefined(currentKpis?.totalAreaSurface?.value),
      format: formatNumber,
    },
    {
      title: t('vacancy'),
      workingVersionMetric: {
        unit: '%',
        value: getValueOrUndefined(vacancySurface?.percent * 100),
      },
      currentMetricValue: getValueOrUndefined(currentKpis?.vacancySurface?.percent * 100),
      format: formatNumber,
    },
    {
      title: t('wault-break'),
      workingVersionMetric: {
        unit: t('wault-break.unit'),
        value: getValueOrUndefined(waultToBreakInYears),
      },
      currentMetricValue: getValueOrUndefined(currentKpis?.waultToBreakInYears),
      format: formatYear,
    },
  ]

  return (
    <FlexBox direction={FlexBoxDirection.Row} className={styles.rowGap}>
      {cards.map((card) => (
        <PropertyRentRollLiveKpisCard
          className={styles.columnWidth}
          key={card.title}
          title={card.title}
          workingVersionMetric={card.workingVersionMetric}
          currentMetricValue={card.currentMetricValue}
          format={card.format}
          isLoading={isLoadingLiveKpis || isLoadingCurrentKpis}
          isError={isErrorLiveKpis || isErrorCurrentKpis}
        />
      ))}
    </FlexBox>
  )
}

PropertyRentRollLiveKpisCards.propTypes = {
  propertyUuids: PropTypes.arrayOf(PropTypes.string.isRequired),
  kpis: PropTypes.shape({
    totalAreaSurface: PropTypes.shape({
      value: PropTypes.number,
      measurementUnit: PropTypes.string,
    }),
    annualizedCurrentRent: PropTypes.shape({
      number: PropTypes.number,
      currency: PropTypes.string,
    }),
    vacancySurface: PropTypes.shape({
      value: PropTypes.number,
      measurementUnit: PropTypes.string,
      percent: PropTypes.number,
    }),
    waultToBreakInYears: PropTypes.number,
  }),
  isErrorLiveKpis: PropTypes.bool,
  isLoadingLiveKpis: PropTypes.bool,
}

export default PropertyRentRollLiveKpisCards
