import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
} from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/ratings/InternalRatingsOverview.module.css'
import { internalRatings as internalRatingsPropTypes } from 'components/domains/business-partners/tile/ratings/propTypes'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

export const findActiveRating = (internalRatings) =>
  internalRatings?.find(({ isActive }) => !!isActive)
const validToValuesToHide = ['2999-12-31', '9999-12-31']
const InternalRatingsOverview = ({ internalRatings }) => {
  const activeRating = findActiveRating(internalRatings)
  const { format: formatDate } = useShortDateFormatter()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.ratings',
  })

  const renderRows = () => {
    const createRowDefinitions = (rating) => {
      const result = [
        {
          label: t('internal.method'),
          value: rating.method?.name,
        },
        {
          label: t('internal.valid-from'),
          value: formatDate(rating.validFrom),
        },
        {
          label: t('internal.valid-to'),
          value: formatDate(rating.validTo),
          hide: validToValuesToHide.includes(rating.validTo),
        },
        {
          label: t('internal.class.overview'),
          value: rating.ratingClass,
        },
      ]
      return rating.customAttributes?.PDLetterGrade
        ? [
            ...result,
            { label: t('internal.letter-grade'), value: rating.customAttributes.PDLetterGrade },
          ]
        : result
    }

    return createRowDefinitions(activeRating).map(
      ({ label, value, hide }) =>
        !hide && (
          <FlexBox
            key={label}
            direction={FlexBoxDirection.Row}
            justifyContent={FlexBoxJustifyContent.SpaceBetween}
            className="sapUiTinyMarginTop"
          >
            <Label>{label}</Label>
            {value}
          </FlexBox>
        ),
    )
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <FlexBox
        direction={FlexBoxDirection.Row}
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        className="sapUiTinyMarginTop"
      >
        <Label className={styles.title}>{t('internal')}</Label>
      </FlexBox>
      {activeRating ? (
        renderRows()
      ) : (
        <FlexBox
          direction={FlexBoxDirection.Row}
          justifyContent={FlexBoxJustifyContent.Center}
          className="sapUiTinyMarginTop"
        >
          <Label className={styles.text}>{t('internal.no-data')}</Label>
        </FlexBox>
      )}
    </FlexBox>
  )
}

InternalRatingsOverview.propTypes = {
  internalRatings: internalRatingsPropTypes.isRequired,
}

export default InternalRatingsOverview
