import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/appendix/manual-checksheet/AnnualReviewManualCheckSheetTile.module.css'
import MonitoringItemsTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/periodical-checks/checks/table/MonitoringItemsTable'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-reporting-summary/shared/DecisionPaperEmptyCardContent'
import extractTileCodeKeyFromTileCode from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/appendix/periodical-checks/shared/extractTileCodeKeyFromTileCode'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const AnnualReviewPeriodicalCheckChecksTile = ({ tileId, tileCode, selectedBorrowerId }) => {
  const { t: tNoPrefix } = useTranslation('decisionPaper')

  const tileCodeKey = extractTileCodeKeyFromTileCode(tileCode)
  const dispatch = useDispatch()
  const [sorting, setSorting] = useState({})

  const onSortingChanged = (orderField, orderDirection) => {
    setSorting({ orderDirection, orderField })
  }

  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { events } = tileStatus?.data ?? {}

  // includes a failsafe for frozen decision papers where these objects were not stringified before
  const monitoringItemsPerCovenantUuid = useMemo(
    () =>
      !isNil(tileStatus?.data?.monitoringItemsPerCovenantUuid) &&
      typeof tileStatus?.data?.monitoringItemsPerCovenantUuid === 'string'
        ? JSON.parse(tileStatus?.data?.monitoringItemsPerCovenantUuid ?? '')
        : {},
    [tileStatus?.data?.monitoringItemsPerCovenantUuid],
  )

  // includes a failsafe for frozen decision papers where these objects were not stringified before
  const covenantUuidsPerPeriodicalCheckTypePerBusinessPartner = useMemo(
    () =>
      !isNil(tileStatus?.data?.covenantUuidsPerPeriodicalCheckTypePerBusinessPartner) &&
      typeof tileStatus?.data?.covenantUuidsPerPeriodicalCheckTypePerBusinessPartner === 'string'
        ? JSON.parse(tileStatus?.data?.covenantUuidsPerPeriodicalCheckTypePerBusinessPartner ?? '')
        : {},
    [tileStatus?.data?.covenantUuidsPerPeriodicalCheckTypePerBusinessPartner],
  )

  // includes a failsafe for frozen decision papers where these objects were not stringified before
  const covenantNameKeysByTileCodeKey = useMemo(
    () =>
      !isNil(tileStatus?.data?.covenantNameKeysByTileCodeKey) &&
      typeof tileStatus?.data?.covenantNameKeysByTileCodeKey === 'string'
        ? JSON.parse(tileStatus?.data?.covenantNameKeysByTileCodeKey ?? '')
        : {},
    [tileStatus?.data?.covenantNameKeysByTileCodeKey],
  )

  const covenantUuid = useMemo(
    () =>
      covenantUuidsPerPeriodicalCheckTypePerBusinessPartner?.[selectedBorrowerId]?.[
        covenantNameKeysByTileCodeKey?.[tileCodeKey]
      ],
    [
      covenantNameKeysByTileCodeKey,
      covenantUuidsPerPeriodicalCheckTypePerBusinessPartner,
      selectedBorrowerId,
      tileCodeKey,
    ],
  )

  useEffect(() => {
    dispatch(
      setSourceRender({
        tileId,
        sourceRender: {
          path: !isNil(covenantUuid)
            ? `/business-partners/${selectedBorrowerId}/periodical-checks#${covenantUuid}`
            : `/business-partners/${selectedBorrowerId}/periodical-checks`,
        },
      }),
    )
  }, [dispatch, covenantUuid, selectedBorrowerId, tileId])

  const monitoringItems = monitoringItemsPerCovenantUuid[covenantUuid]

  if (isNil(monitoringItems)) {
    return (
      <EmptyCardContent
        title={tNoPrefix('components.cards.empty.title')}
        subtitle={tNoPrefix('components.cards.empty.subtitle')}
      />
    )
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.wrapper}>
      <MonitoringItemsTable
        monitoringItems={monitoringItems}
        linkedEvents={events}
        orderField={sorting.orderField}
        orderDirection={sorting.orderDirection}
        onSortingChanged={onSortingChanged}
      />
    </FlexBox>
  )
}

AnnualReviewPeriodicalCheckChecksTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  tileCode: PropTypes.string.isRequired,
  selectedBorrowerId: PropTypes.string.isRequired,
}

export default AnnualReviewPeriodicalCheckChecksTile
