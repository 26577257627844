import find from 'lodash.find'
import isEmpty from 'lodash.isempty'
import orderBy from 'lodash.orderby'

const filterRiskFigures = (assetClasses) => {
  const filteredData = {}

  for (const key in assetClasses) {
    const riskFigures = assetClasses[key].risk_figures

    // Add a check to ensure riskFigures is not null or undefined
    const hasNonZeroValues = riskFigures && Object.values(riskFigures).some(({ value }) => !!value)

    if (!isEmpty(riskFigures) && hasNonZeroValues) {
      filteredData[key] = assetClasses[key]
    }
  }

  return filteredData
}

const getAssetClassRowsWithAssetSubClasses = ({
  assetClasses,
  assetClassesConfig = [],
  assetSubClassesConfig = [],
}) => {
  const filteredAssetClasses = filterRiskFigures(assetClasses)

  const rows = Object.keys(filteredAssetClasses).map((assetClassKey) => {
    const { risk_figures: riskFiguresForClass, asset_sub_classes: assetSubClasses } =
      assetClasses[assetClassKey]

    const row = {
      assetClass: assetClassKey,
      ...riskFiguresForClass,
    }
    if (!isEmpty(assetSubClasses)) {
      row.subRows = getAssetClassRowsWithAssetSubClasses({
        assetClasses: assetSubClasses,
        assetClassesConfig,
        assetSubClassesConfig,
      })
    }
    const classOrdinalPosition =
      find(assetClassesConfig, { name: assetClassKey })?.ordinalPosition ||
      find(assetSubClassesConfig, { name: assetClassKey })?.ordinalPosition
    row.ordinalPosition = classOrdinalPosition
    return row
  })

  return orderBy(rows, ['ordinalPosition'])
}

export default getAssetClassRowsWithAssetSubClasses
