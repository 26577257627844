import { Input, Label, Option, Select, Text } from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { propertyMonitoringThresholdType } from 'api/property/monitoring/propertyMonitoringConfiguration'

const hyphen = '-'

const thresholdTypes = [
  propertyMonitoringThresholdType.absolute,
  propertyMonitoringThresholdType.relative,
]

const MarketProxiesTableThresholdTypeCell = ({ isEditing, onChange, proxySetting, errorState }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.markets.detail.manager.market-proxies',
  })
  const isOverride = proxySetting.isOverride
  const defaultThresholdType = proxySetting.defaultProxy?.thresholdType
  const isMonitored = proxySetting.overrideProxy?.isMonitored ?? true
  const thresholdType =
    isOverride && proxySetting.overrideProxy
      ? proxySetting.overrideProxy.thresholdType
      : defaultThresholdType

  const handleSelectionChange = useCallback(
    ({ target: { selectedOption } }) => {
      onChange(proxySetting.key, selectedOption.getAttribute('data-threshold-type'))
    },
    [onChange, proxySetting.key],
  )

  const thresholdTypeOptions = useMemo(() => {
    const options = thresholdTypes.map((thresholdTypeOption) => (
      <Option
        key={thresholdTypeOption}
        selected={thresholdTypeOption === thresholdType}
        data-threshold-type={thresholdTypeOption}
      >
        {t(`table.threshold-type.${thresholdTypeOption}`)}
      </Option>
    ))
    if (isNil(thresholdType)) {
      return [<Option key={'empty-option'} selected />, ...options]
    }
    return options
  }, [t, thresholdType])

  if (!isEditing) {
    return <Text>{thresholdType ? t(`table.threshold-type.${thresholdType}`) : hyphen}</Text>
  }

  if (!isOverride || !isMonitored) {
    return (
      <Input readonly value={thresholdType ? t(`table.threshold-type.${thresholdType}`) : hyphen} />
    )
  }

  const valueState = errorState.hasError && errorState.touched ? ValueState.Error : ValueState.None
  return (
    <>
      <Select
        onBlur={handleSelectionChange}
        onChange={handleSelectionChange}
        valueState={valueState}
        valueStateMessage={<Text>{t('error-messages.threshold-type')}</Text>}
      >
        {thresholdTypeOptions}
      </Select>
      <br />
      <Label showColon>{t('default-value')}</Label>{' '}
      <Text>
        {defaultThresholdType ? t(`table.threshold-type.${defaultThresholdType}`) : hyphen}
      </Text>
    </>
  )
}

MarketProxiesTableThresholdTypeCell.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  proxySetting: PropTypes.shape({
    key: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    isOverride: PropTypes.bool.isRequired,
    defaultProxy: PropTypes.shape({
      thresholdType: PropTypes.oneOf(Object.values(propertyMonitoringThresholdType)),
    }),
    overrideProxy: PropTypes.shape({
      thresholdType: PropTypes.oneOf(Object.values(propertyMonitoringThresholdType)),
      isMonitored: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  errorState: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
  }).isRequired,
}

export default MarketProxiesTableThresholdTypeCell
