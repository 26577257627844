import { useMutation } from '@tanstack/react-query'
import isNil from 'lodash.isnil'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useMassUpdateInternalConditions = (mutateOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    ({
      conditionIds,
      editedFields: { category, typeCode, refNumber, approvalLevelCode, assignee, statusCode },
    }) =>
      post({
        path: '/conditions/internal/bulk-edit',
        body: snakecaseKeys({
          conditionIds,
          editedFields: {
            info: {
              approvalLevel: isNil(approvalLevelCode)
                ? approvalLevelCode
                : { code: approvalLevelCode },
              assignee,
              category,
              refNumber,
              type: typeCode ? { code: typeCode } : undefined,
            },
            status: statusCode ? { code: statusCode } : undefined,
          },
        }),
      }),
    mutateOptions,
  )
}

export default useMassUpdateInternalConditions
