import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import CardHeaderWithButtons from 'components/ui/card/CardHeaderWithButtons'

const PropertyRatingCardHeader = ({ tRating, userIsAllowedToEdit = true }) => (
  <CardHeaderWithButtons title={tRating('title')} subTitle={tRating('subtitle')}>
    {userIsAllowedToEdit && [
      <Button key="createButton" design={ButtonDesign.Default} onClick={() => {}}>
        {tRating('create')}
      </Button>,
      <Button key="editButton" design={ButtonDesign.Transparent} onClick={() => {}}>
        {tRating('edit')}
      </Button>,
    ]}
  </CardHeaderWithButtons>
)

PropertyRatingCardHeader.propTypes = {
  tRating: PropTypes.func.isRequired,
  userIsAllowedToEdit: PropTypes.bool,
}

export default PropertyRatingCardHeader
