import {
  FlexBox,
  FlexBoxDirection,
  Label,
  Option,
  Select,
  Text,
  TextAlign,
  TextArea,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import _ from 'lodash'
import { DateTime } from 'luxon'
import PropTypes, { object } from 'prop-types'
import { useRef, useState } from 'react'
import styles from 'components/domains/properties/general-information/costs/PropertyCostsCardTable.module.css'
import DatePickerWithoutMinWidth from 'components/ui/date-picker/DatePickerWithoutMinWidth'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import CardWithDisplayAndEditTable from 'components/ui/tables/display-and-edit-table/CardWithDisplayAndEditTable'
import { rowKeyNewRow } from 'components/ui/tables/display-and-edit-table/constants'
import { useCustomizableCurrencyFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'
import { useCostCodes } from 'hooks/services/properties/useCostCodes'

const COST_AMOUNT_LIMIT = 1000000000000000

const PropertyCostsCardTable = ({
  cardTitle,
  costsOnProperty,
  currency,
  isLoading,
  isError,
  handleDeleteRow,
  handleSaveRow,
  handleUpdateRow,
  tCostsCardTable,
  userIsAllowedToEdit,
}) => {
  const { data: costCodesData } = useCostCodes()
  const costCodes = costCodesData ? costCodesData.cost_codes : []

  const { format, localePattern } = useShortDateFormatter()
  const sortCostsByDate = (date1, date2) => {
    if (date1 < date2 || isNaN(date1)) return 1
    else return -1
  }

  const doFormatCurrency = useCustomizableCurrencyFormatter()
  const formatCurrency = (value) => doFormatCurrency(value, currency)

  //a valid editRow object has the properties { rowKey, cost_type_code, actual_cost_amount }
  const [editRows, setEditRows] = useState([])

  const removeChangeFromEditRows = (rowKey) =>
    setEditRows([...editRows.filter((editRow) => editRow.rowKey !== rowKey)])

  const handleCancelEdit = (rowKey) => {
    removeChangeFromEditRows(rowKey)
  }

  const findEditRow = (rowKey) => ({ ...editRows.find((editRow) => editRow.rowKey === rowKey) })
  const handleSave = (rowKey) => {
    const rowToSave = findEditRow(rowKey)
    const costObjectToSave = {
      costTypeCode: rowToSave.costTypeCode,
      costTypeCodeName: rowToSave.costTypeCodeName,
      costDescription: rowToSave.costDescription,
      costKeyDate: rowToSave.costKeyDate,
      actualCostAmount: rowToSave.actualCostAmount,
    }
    setEditRows([...editRows.filter((editRow) => editRow.rowKey !== rowKey)])
    if (rowKey === rowKeyNewRow) {
      handleSaveRow({ ...costObjectToSave })
    } else {
      handleUpdateRow(rowKey, { ...costObjectToSave })
    }
  }

  const getNewEditRow = ({ rowKey, parameter, value, oldRow }) => {
    const newRow = oldRow ? { ...oldRow } : { rowKey: rowKey }
    newRow[parameter] = value
    return newRow
  }

  const updateEditRow = (rowKey, parameter, value) => {
    //if the row is new in editMode, no corresponding editRow object exists yet -> Create and add it to the state array
    if (_.isEmpty(findEditRow(rowKey))) {
      const newEditRow = getNewEditRow({ rowKey, parameter, value })
      setEditRows([...editRows, newEditRow])
    } else {
      //if the row was already in editMode, change the corresponding entry in the state and leave the rest as it was
      const editRowsUpdated = editRows.map((oldRow) => {
        if (oldRow.rowKey !== rowKey) {
          return { ...oldRow }
        }
        return getNewEditRow({ rowKey, parameter, value, oldRow })
      })
      setEditRows([...editRowsUpdated])
    }
  }

  const getCurrentFieldValue = ({ rowKey, originalValue, accessor }) => {
    const editRow = findEditRow(rowKey)
    return accessor in editRow ? editRow[accessor] : originalValue
  }

  const checkIfAnythingChanged = (rowKey, costOnProperty) => {
    let changeDetected = false
    const editRow = findEditRow(rowKey)
    Object.entries(editRow).forEach(([key, value]) => {
      if (key !== 'rowKey' && costOnProperty[key] !== value) {
        changeDetected = true
      }
    })
    return changeDetected
  }

  const isInvalidLocalDate = (keyDate) => {
    if (!keyDate) {
      return false
    }
    const parsedIsoDate = DateTime.fromISO(keyDate).setLocale(localePattern)
    const parsedDateInLocalFormat = DateTime.fromFormat(keyDate, localePattern)
    if (parsedIsoDate.isValid || parsedDateInLocalFormat.isValid) {
      return false
    }
    return true
  }

  const [validNumberInputs, setValidNumberInputs] = useState({})
  const formattedNumberInputRef = useRef(null)

  const updateNumberInputValidity = (rowKey, isValid) => {
    setValidNumberInputs((prevValidNumberInputs) => ({
      ...prevValidNumberInputs,
      [rowKey]: isValid,
    }))
  }
  const isInvalidActualCostAmount = (costAmount) => costAmount >= COST_AMOUNT_LIMIT

  const checkIsRowValid = (rowKey, costOnProperty) => {
    if (!checkIfAnythingChanged(rowKey, costOnProperty)) {
      return false
    }
    if (rowKey in validNumberInputs && !validNumberInputs[rowKey]) {
      return false
    }

    const costType = getCurrentFieldValue({
      rowKey,
      originalValue: costOnProperty?.costTypeCode,
      accessor: 'costTypeCode',
    })
    const originalKeyDateValue = costOnProperty?.costKeyDate
    const keyDate = getCurrentFieldValue({
      rowKey,
      originalValue: format(originalKeyDateValue),
      accessor: 'costKeyDate',
    })
    const actualCostAmount = getCurrentFieldValue({
      rowKey,
      originalValue: costOnProperty?.actualCostAmount?.number,
      accessor: 'actualCostAmount',
    })
    if (
      !costType ||
      costType === 'emptyOption' ||
      isInvalidLocalDate(keyDate) ||
      isInvalidActualCostAmount(actualCostAmount)
    ) {
      return false
    }
    return true
  }

  const evaluateCostTypeCodeState = (costTypeCode) => {
    if (!costTypeCode || costTypeCode === 'emptyOption') {
      return { isError: true, errorMessage: tCostsCardTable('error.cost-type-empty') }
    }
    return { isError: false }
  }

  const evaluateDateState = (keyDate) => {
    if (isInvalidLocalDate(keyDate)) {
      return {
        isError: true,
        errorMessage: `${tCostsCardTable('error.invalid-date')} ${localePattern}`,
      }
    }
    return { isError: false }
  }

  const renderSelectOptions = (costCode) => {
    const emptyOption = <Option key={'emptyOption'} id={'emptyOption'} />
    const options = costCodes
      ? costCodes.map((costType) => (
          <Option
            key={costType.key}
            id={costType.key}
            className={styles.cellInput}
            selected={costType.key === costCode}
            value={costType}
          >
            {costType.display_name}
          </Option>
        ))
      : []

    return [emptyOption, ...options]
  }

  const onSelectFunctionChange = (rowKey, costTypeCode) => {
    updateEditRow(rowKey, 'costTypeCode', costTypeCode)
  }

  const renderSelectCell = (costOnProperty) => {
    const rowKey = costOnProperty.rowKey
    const valueStateObject = evaluateCostTypeCodeState(
      getCurrentFieldValue({
        rowKey,
        originalValue: costOnProperty?.costTypeCode,
        accessor: 'costTypeCode',
      }),
    )
    return (
      <Select
        id={'select_type_' + rowKey}
        className={styles.cellInput}
        valueState={valueStateObject.isError ? ValueState.Error : ValueState.None}
        valueStateMessage={
          valueStateObject.errorMessage && <Text>{valueStateObject.errorMessage}</Text>
        }
        onChange={(event) => {
          onSelectFunctionChange(rowKey, event.detail.selectedOption.id)
        }}
      >
        {renderSelectOptions(costOnProperty?.costTypeCode)}
      </Select>
    )
  }

  const renderInputDescription = ({ rowKey, description }) => {
    const descriptionMaxLength = 30
    const currentDescription = getCurrentFieldValue({
      rowKey,
      originalValue: description,
      accessor: 'costDescription',
    })
    const currentDescriptionLength = currentDescription ? currentDescription.length : 0
    const subtext = `${descriptionMaxLength - currentDescriptionLength} ${tCostsCardTable(
      'character-remaining',
    )}`

    return (
      <FlexBox direction={FlexBoxDirection.Column}>
        <TextArea
          id={`textArea_description_${rowKey}`}
          className={styles.cellInput}
          value={description}
          maxlength={descriptionMaxLength}
          onInput={(event) => updateEditRow(rowKey, 'costDescription', event.target.value)}
        />
        <Text className={styles.text}>{subtext}</Text>
      </FlexBox>
    )
  }

  const renderDateCell = ({ rowKey, keyDate }) => {
    const valueStateObject = evaluateDateState(
      getCurrentFieldValue({
        rowKey,
        originalValue: keyDate,
        accessor: 'costKeyDate',
      }),
    )
    return (
      <DatePickerWithoutMinWidth
        id={'date_' + rowKey}
        className={styles.cellDate}
        formatPattern={localePattern}
        onChange={(event) => updateEditRow(rowKey, 'costKeyDate', event.detail.value)}
        value={keyDate}
        valueState={valueStateObject.isError ? ValueState.Error : ValueState.None}
        valueStateMessage={
          valueStateObject.errorMessage && <Text>{valueStateObject.errorMessage}</Text>
        }
        placeholder={localePattern}
      />
    )
  }

  const renderInputActualCost = ({ rowKey, actualCostNumber }) => {
    const additionalProps = {
      icon: <Label className={styles.inputLabel}>{currency}</Label>,
    }
    const currentValueActualCostAmount = getCurrentFieldValue({
      rowKey,
      originalValue: actualCostNumber,
      accessor: 'actualCostAmount',
    })
    return (
      <FormattedNumberInput
        id={'input_cost_' + rowKey}
        value={actualCostNumber}
        className={styles.costAmountInput}
        onFocus={(event) => {
          event.stopPropagation()
        }}
        onChange={(parsedValue) => {
          updateEditRow(rowKey, 'actualCostAmount', parsedValue)
          updateNumberInputValidity(rowKey, formattedNumberInputRef?.current?.isValid)
        }}
        valueState={
          isInvalidActualCostAmount(currentValueActualCostAmount)
            ? ValueState.Error
            : ValueState.None
        }
        valueStateMessage={<span>{tCostsCardTable('cost-amount-too-high')}</span>}
        ref={formattedNumberInputRef}
        {...additionalProps}
      />
    )
  }

  const columnDefinitions = [
    {
      columnKey: 'costType',
      title: tCostsCardTable('cost-type'),
    },
    {
      columnKey: 'description',
      title: tCostsCardTable('description'),
    },
    {
      columnKey: 'keyDate',
      title: tCostsCardTable('key-date'),
      alignment: TextAlign.End,
    },
    {
      columnKey: 'actualCost',
      title: tCostsCardTable('actual-cost'),
      alignment: TextAlign.End,
    },
  ]

  const shouldHideEditAndDeleteRow = (costOnProperty) =>
    !costCodes.some((costCode) => costCode.key === costOnProperty.costTypeCode)

  const tableData = () =>
    costsOnProperty
      ?.map((costOnProperty) => ({
        ...costOnProperty,
      }))
      .sort((costA, costB) => {
        const keyDateA = new Date(costA.costKeyDate).getTime()
        const keyDateB = new Date(costB.costKeyDate).getTime()
        const dateCompare = sortCostsByDate(keyDateA, keyDateB)
        return dateCompare
      })
      .map((costOnProperty) => ({
        rowKey: costOnProperty.rowKey,
        costType: {
          cellContentReadMode: costOnProperty.costTypeCodeName,
          cellContentEditMode: renderSelectCell(costOnProperty),
        },
        description: {
          cellContentReadMode: costOnProperty.costDescription,
          cellContentEditMode: renderInputDescription({
            rowKey: costOnProperty.rowKey,
            description: costOnProperty.costDescription,
          }),
        },
        keyDate: {
          cellContentReadMode: format(costOnProperty.costKeyDate),
          cellContentEditMode: renderDateCell({
            rowKey: costOnProperty.rowKey,
            keyDate: costOnProperty.costKeyDate,
          }),
        },
        actualCost: {
          cellContentReadMode: formatCurrency(costOnProperty.actualCostAmount?.number),
          cellContentEditMode: renderInputActualCost({
            rowKey: costOnProperty.rowKey,
            actualCostNumber: costOnProperty.actualCostAmount?.number,
          }),
        },
        isValid: checkIsRowValid(costOnProperty.rowKey, costOnProperty),
        hasChanges: checkIfAnythingChanged(costOnProperty.rowKey, costOnProperty),
        hideEditButton: shouldHideEditAndDeleteRow(costOnProperty),
        hideDeleteButton: shouldHideEditAndDeleteRow(costOnProperty),
      }))

  const newRow = {
    rowKey: rowKeyNewRow,
    costType: {
      cellContentEditMode: renderSelectCell({ rowKey: rowKeyNewRow }),
    },
    description: {
      cellContentEditMode: renderInputDescription({ rowKey: rowKeyNewRow }),
    },
    keyDate: {
      cellContentEditMode: renderDateCell({ rowKey: rowKeyNewRow }),
    },
    actualCost: {
      cellContentEditMode: renderInputActualCost({ rowKey: rowKeyNewRow }),
    },
    isValid: checkIsRowValid(rowKeyNewRow, {}),
    hasChanges: checkIfAnythingChanged(rowKeyNewRow, {}),
  }

  return (
    <CardWithDisplayAndEditTable
      cardTitle={cardTitle}
      columnDefinitions={columnDefinitions}
      tableData={[...tableData()]}
      newRow={newRow}
      isLoading={isLoading}
      isError={isError}
      userIsAllowedToEdit={userIsAllowedToEdit}
      handleDeleteRow={handleDeleteRow}
      handleSaveRow={(rowKey) => {
        handleSave(rowKey)
        removeChangeFromEditRows(rowKey)
      }}
      handleCancelEditRow={handleCancelEdit}
      popoverStaticConfig={{
        cancel: {
          message: tCostsCardTable('cancelmessage'),
          button: tCostsCardTable('discard.button'),
        },
        delete: {
          message: tCostsCardTable('deleterow'),
          button: t('buttons.delete'),
        },
      }}
    />
  )
}
PropertyCostsCardTable.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  costsOnProperty: PropTypes.arrayOf(object).isRequired,
  currency: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
  handleSaveRow: PropTypes.func.isRequired,
  handleUpdateRow: PropTypes.func.isRequired,
  tCostsCardTable: PropTypes.func.isRequired,
  userIsAllowedToEdit: PropTypes.bool.isRequired,
}

export default PropertyCostsCardTable
