import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

export const useMultipleDealTranchesWithSubEntities = ({ dealUuids, dataSource, options = {} }) => {
  const queryParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams()

    if (dataSource) {
      urlSearchParams.append('dataSource', dataSource)
    }

    return urlSearchParams
  }, [dataSource])

  const paramsString = [...queryParams.keys()].length ? `?${queryParams.toString()}` : ''

  const responses = useRequestsMemo(
    useRequests({
      requests: dealUuids.map((dealUuid) => ({
        path: `/financing-structures/deals/${dealUuid}/tranches-with-sub-entities${paramsString}`,
        keys: ['deals', dealUuid, 'tranches', 'tranchesWithSubEntities'],
      })),
      translated: true,
      useCache: true,
      options: { ...options, enabled: !isEmpty(dealUuids) },
    }),
  )

  return useMemo(
    () =>
      responses.map((result, index) => ({
        ...result,
        data: { dealUuid: dealUuids[index], ...camelize(result.data) },
      })),
    [dealUuids, responses],
  )
}
