import {
  Button,
  Title,
  Bar,
  Dialog,
  Icon,
  FlexBox,
  Input,
  Text,
  TableCell,
  TableRow,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js'
import { ExcelUploadContext } from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollExcelUploadContext'
import { findFirstContentRow } from 'components/domains/properties/rent-roll/working-version/excel-upload/commonRentRollExcelUploadFunctions'

const MAPPINGFROMSCRATCH = 'mappingFromScratch'
const MAPPINGFROMEXISTINGSOURCE = 'mappingFromExistingSource'

const PropertyRentRollExcelChooseHeaderRowDialog = ({
  initiateExcelToTableMapping,
  columnMappingExists,
}) => {
  const excelUploadContext = useContext(ExcelUploadContext)
  const {
    isChooseHeaderRowDialogOpen,
    defaultRowNumberHeader,
    closeChooseHeaderRowDialog,
    rowNumberHeader,
    setRowNumberHeader,
    inputExcelData,
    openAdjustMappingDialog,
  } = excelUploadContext
  const { t: tExcelUpload } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.excel-upload',
  })
  const checkRowEmpty = (rowNum) => {
    if (inputExcelData?.length <= rowNum) {
      return true
    }
    let allEntriesNull = true
    Object.values(inputExcelData[rowNum]).forEach((content) => {
      if (content !== undefined && content !== null) allEntriesNull = false
    })
    return allEntriesNull
  }
  // Excel row numeration starts with 1, not 0
  const firstExcelRowNumber = 1
  const [chosenHeaderRowNumber, setChosenHeaderRowNumber] = useState(firstExcelRowNumber)

  // Sets the chosen row number to the input row number from former mappings (stored in backend)
  useEffect(() => {
    setChosenHeaderRowNumber(rowNumberHeader ? rowNumberHeader + 1 : 1)
  }, [rowNumberHeader])

  // Resets the chosen header row number, to the initially identified header row, when column mapping is discarded
  useEffect(() => {
    !columnMappingExists && setChosenHeaderRowNumber(defaultRowNumberHeader + 1 || 1)
  }, [columnMappingExists, defaultRowNumberHeader])
  const [isInvalidInput, setIsInvalidInput] = useState(false)

  const handleOnInputChange = (chosenInput) => {
    if (isNaN(chosenInput) || !parseInt(chosenInput) || parseInt(chosenInput) < 1) {
      setIsInvalidInput(true)
    } else {
      setChosenHeaderRowNumber(parseInt(chosenInput))
      setIsInvalidInput(false)
    }
  }

  const handleConfirmInput = () => {
    //rows as seen by the user inside the excel start at row 1. Since the input is converted to an array, we start counting at 0. hence the minus 1 operation
    if (!(isInvalidInput || checkRowEmpty(chosenHeaderRowNumber - 1))) {
      const rowNumberHeaderRow = chosenHeaderRowNumber - 1
      const rowNumberFirstContentRow = findFirstContentRow(rowNumberHeaderRow, inputExcelData)
      initiateExcelToTableMapping({
        headerRow: inputExcelData[rowNumberHeaderRow],
        nextRow: inputExcelData[rowNumberFirstContentRow],
        mode: columnMappingExists ? MAPPINGFROMEXISTINGSOURCE : MAPPINGFROMSCRATCH,
      })
      setRowNumberHeader(rowNumberHeaderRow)
      closeChooseHeaderRowDialog()
      openAdjustMappingDialog()
    }
  }

  const getHeaderRowContent = (rowNum) => {
    // eslint-disable-next-line no-magic-numbers
    const headerRow = Object.values(inputExcelData[rowNum]).slice(0, 5)
    return (
      <>
        <Text>{tExcelUpload('choose-header-row.content')}</Text>
        <TableRow>
          {headerRow
            .filter((content) => !!content)
            .map((content, index) => (
              <TableCell key={content ? `${content}-${index}` : index}>{content}</TableCell>
            ))}
          <TableCell>{'...'}</TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <>
      <Dialog
        open={isChooseHeaderRowDialogOpen}
        onAfterClose={closeChooseHeaderRowDialog}
        header={
          <Bar
            startContent={
              <>
                <Icon name="sap-icon://sys-help-2" />
                <Title>{tExcelUpload('choose-header-row.title')}</Title>
              </>
            }
          />
        }
        footer={
          <Bar
            endContent={
              <>
                <Button
                  design="Emphasized"
                  data-testid={'accept-button'}
                  onClick={handleConfirmInput}
                >
                  {tExcelUpload('accept-button')}
                </Button>
                <Button data-testid={'abort-button'} onClick={closeChooseHeaderRowDialog}>
                  {tExcelUpload('abort-button')}
                </Button>
              </>
            }
          />
        }
      >
        <FlexBox style={{ marginBottom: '10px' }}>
          <Text>{tExcelUpload('choose-header-row.text')}</Text>
        </FlexBox>
        <Input
          data-testid="input"
          value={chosenHeaderRowNumber}
          onInput={(event) => handleOnInputChange(event.target.value)}
        />
        <Text style={{ color: 'red' }}>
          {isInvalidInput && tExcelUpload('choose-header-row.warning')}
          {!isInvalidInput &&
            checkRowEmpty(chosenHeaderRowNumber - 1) &&
            tExcelUpload('choose-header-row.no-content')}
        </Text>
        {inputExcelData?.length > 0 &&
        !(isInvalidInput || checkRowEmpty(chosenHeaderRowNumber - 1)) ? (
          getHeaderRowContent(chosenHeaderRowNumber - 1)
        ) : (
          <></>
        )}
      </Dialog>
    </>
  )
}
PropertyRentRollExcelChooseHeaderRowDialog.propTypes = {
  initiateExcelToTableMapping: PropTypes.func.isRequired,
  columnMappingExists: PropTypes.bool.isRequired,
}
export default PropertyRentRollExcelChooseHeaderRowDialog
