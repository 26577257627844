import PropTypes from 'prop-types'
import QuestionnaireTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/QuestionnaireTile'
import {
  possibleEndings,
  shadowBankingStructure,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/shadow-banking/shadow-banking-questionnaire-version-2/shadowBankingQuestionStructureVersion2'

const ShadowBankingQuestionnaireTileVersion2 = ({
  tileId,
  currentContent,
  onChange,
  isEditMode,
  isPdfView,
  setHasContentChanges,
}) => (
  <QuestionnaireTile
    tileId={tileId}
    currentContent={currentContent}
    onChange={onChange}
    isEditMode={isEditMode}
    isPdfView={isPdfView}
    setHasContentChanges={setHasContentChanges}
    questionnaireStructure={shadowBankingStructure}
    availableEndings={possibleEndings}
    questionTranslationPrefix="tiles.nbcs.shadow-banking.version-2"
  />
)

ShadowBankingQuestionnaireTileVersion2.propTypes = {
  tileId: PropTypes.string.isRequired,
  currentContent: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
}

export default ShadowBankingQuestionnaireTileVersion2
