import {
  Button,
  ButtonDesign,
  Popover,
  PopoverPlacementType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import PropertyRentRollWorkingVersionValidationResultsCalculation from 'components/domains/properties/rent-roll/working-version/validation-results-summary/PropertyRentRollWorkingVersionValidationResultsCalculation'
import styles from 'components/domains/properties/rent-roll/working-version/validation-results-summary/PropertyRentRollWorkingVersionValidationResultsSummary.module.css'
import PropertyRentRollWorkingVersionValidationResultsTabContainer from 'components/domains/properties/rent-roll/working-version/validation-results-summary/PropertyRentRollWorkingVersionValidationResultsTabContainer'

const PropertyRentRollWorkingVersionValidationResultsSummary = ({
  headerValues,
  rentalUnitsValues,
  auxiliaryFieldValues,
  selectRows,
}) => {
  const [summaryIsOpen, setSummaryIsOpen] = useState(false)

  const handleSelectRows = (rowNumbers) => {
    selectRows(rowNumbers)
    setSummaryIsOpen(false)
  }

  const togglePopover = () => setSummaryIsOpen((isOpen) => !isOpen)

  return (
    <PropertyRentRollWorkingVersionValidationResultsCalculation
      headerValues={headerValues}
      rentalUnitsValues={rentalUnitsValues}
      auxiliaryFieldValues={auxiliaryFieldValues}
    >
      {(summarizedValidationResults, amountOfInconsistenciesByState) => {
        const { totalWarnings, totalErrors } = amountOfInconsistenciesByState
        const totalAmountOfInconsistencies = totalWarnings + totalErrors
        const inconsistenciesExist = totalAmountOfInconsistencies > 0
        const errorsExist = totalErrors > 0

        const getButtonIcon = () => (errorsExist ? 'error' : 'alert')
        const getButtonStyles = () =>
          errorsExist ? styles['button-error'] : styles['button-warning']

        const popoverOpener = 'rrwv-validation-results-summary-button'

        return (
          inconsistenciesExist && (
            <>
              <Button
                id={popoverOpener}
                design={ButtonDesign.Emphasized}
                className={getButtonStyles()}
                onClick={togglePopover}
                icon={getButtonIcon()}
              >
                {errorsExist ? totalErrors : totalWarnings}
              </Button>
              {createPortal(
                <Popover
                  id="rrwv-validation-results-summary-popover"
                  className={styles.popover}
                  opener={popoverOpener}
                  placementType={PopoverPlacementType.Bottom}
                  open={summaryIsOpen}
                  onAfterClose={() => setSummaryIsOpen(false)}
                  hideArrow
                >
                  <PropertyRentRollWorkingVersionValidationResultsTabContainer
                    key={JSON.stringify(summarizedValidationResults)}
                    summarizedValidationResults={summarizedValidationResults}
                    amountOfInconsistenciesByState={amountOfInconsistenciesByState}
                    handleSelectRows={handleSelectRows}
                  />
                </Popover>,
                document.body,
              )}
            </>
          )
        )
      }}
    </PropertyRentRollWorkingVersionValidationResultsCalculation>
  )
}

PropertyRentRollWorkingVersionValidationResultsSummary.propTypes = {
  headerValues: PropTypes.object,
  rentalUnitsValues: PropTypes.array,
  auxiliaryFieldValues: PropTypes.object,
  selectRows: PropTypes.func,
}

export default PropertyRentRollWorkingVersionValidationResultsSummary
