import camelize from 'camelize'
import { countBy, uniq, isEmpty } from 'lodash'
import sum from 'lodash.sum'
import { useCallback, useMemo } from 'react'
import {
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  usePercentageFormatter,
} from 'hooks/i18n/useI18n'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useDealProperties from 'hooks/services/deals/properties/useDealProperties'
import { useCosts } from 'hooks/services/properties/costs/useCosts'
import useCurrentMultiPropertyKpis from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpis'
import { useMultiPropertyViewByPropertyUuids } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuids'
import usePropertyDetailedUuids from 'hooks/services/properties/usePropertyDetailedUuids'
import { useTypecodes } from 'hooks/services/properties/useTypecodes'
import useMultiPropertyValuationsDecisionPaper from 'hooks/services/properties/valuations/useMultiPropertyValuationsDecisionPaper'

export const useFactSheetKeyFacts = ({ entityRef: { entityId: dealUuid } }, _, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({
    dealUuid,
    tileCode,
  })

  const dealPropertiesResult = useDealProperties({
    dealUuid: dealUuidByTileCode,
  })

  const isPropertyRequestEnabled = useMemo(
    () =>
      !dealPropertiesResult.isFetching &&
      !dealPropertiesResult.isError &&
      !!dealPropertiesResult?.data &&
      dealPropertiesResult?.data?.dealProperties?.length > 0,
    [dealPropertiesResult],
  )

  const propertyUuidList = useMemo(
    () =>
      isPropertyRequestEnabled
        ? dealPropertiesResult.data.dealProperties?.map((property) => property.propertyUuid)
        : [],
    [dealPropertiesResult, isPropertyRequestEnabled],
  )

  const propertiesResult = usePropertyDetailedUuids(propertyUuidList, {
    enabled: isPropertyRequestEnabled,
  })
  const {
    isFetching: propertiesIsLoading,
    isError: propertiesIsError,
    data: propertiesData,
  } = propertiesResult

  const {
    isFetching: valuationsIsLoading,
    isError: valuationsIsError,
    data: { sum: valuationSum = {} } = {},
  } = useMultiPropertyValuationsDecisionPaper(propertyUuidList, ['sum'], undefined, {
    enabled: !!propertyUuidList.length,
  })

  const {
    isFetching: typeCodesIsLoading,
    isError: typeCodesIsError,
    data: typeCodesData,
  } = useTypecodes()

  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })

  const checkIsMultiProperty = propertyUuidList.length > 1

  const getFullYear = (date) => new Date(date).getFullYear()
  const getYearRange = useCallback((yearList) => {
    const uniqYearList = uniq(yearList?.map((year) => getFullYear(year)))
    const sortedYearList = uniqYearList
      ?.filter((date) => !!date)
      .sort((a, b) => new Date(a) - new Date(b))

    if (isEmpty(sortedYearList)) {
      return []
    }
    return sortedYearList.length === 1
      ? [sortedYearList[0]]
      : [sortedYearList[0], sortedYearList.pop()]
  }, [])

  const getTypeCodeName = (typeCodeList, typeCode) =>
    (typeCodeList ?? []).find(({ key }) => key === typeCode)?.displayName

  const propertiesInformation = useMemo(() => {
    const properties =
      !propertiesIsLoading && !propertiesIsError && propertiesData?.data?.properties
        ? camelize(propertiesData?.data?.properties)
        : []

    const majorTypeCodes = []
    const countryNameSet = new Set()
    const constructionYearList = []
    const annualRentalIncome = []

    properties.forEach((property) => {
      majorTypeCodes.push(property?.typeCode)
      countryNameSet.add(property?.address?.countryName)
      constructionYearList.push(property?.constructionCompletionDate)
      annualRentalIncome.push(property?.actualRentAmount?.number)
    })

    //Counts how often a typeCode is present, sorts the typeCode counts in descending order and picks the typeCode that has the highest count.
    const majorTypeCode = Object.entries(countBy(majorTypeCodes)).sort(
      (a, b) => b[1] - a[1],
    )?.[0]?.[0]
    const majorTypeName = getTypeCodeName(camelize(typeCodesData?.typecodes), majorTypeCode)

    const constructionYearValues = getYearRange(constructionYearList)

    const marketValue = valuationSum?.marketValue?.number
    const sumAnnualRentalIncome = sum(annualRentalIncome.filter(Boolean))
    const multiplier =
      marketValue && sumAnnualRentalIncome ? marketValue / sumAnnualRentalIncome : 0

    const formattedMultiplier = multiplier ? formatNumber(multiplier) : 0
    const initialYield = multiplier ? formatPercentage(1 / multiplier) : 0

    const singlePropertyResult = {
      majorTypeName,
      subType: properties[0]?.subType ?? '',
      constructionYear: [getFullYear(constructionYearList?.[0])],
      countryName: Array.from(countryNameSet),
      address: properties[0]?.address,
      id: properties[0]?.id,
      multiplier: formattedMultiplier,
      initialYield,
    }
    const multiPropertyResult = {
      majorTypeName,
      constructionYear: constructionYearValues,
      countryName: Array.from(countryNameSet),
      nrOfProperties: propertyUuidList.length,
      multiplier: formattedMultiplier,
      initialYield,
    }
    return checkIsMultiProperty ? multiPropertyResult : singlePropertyResult
  }, [
    checkIsMultiProperty,
    formatNumber,
    formatPercentage,
    getYearRange,
    propertiesData?.data?.properties,
    propertiesIsError,
    propertiesIsLoading,
    propertyUuidList.length,
    typeCodesData?.typecodes,
    valuationSum?.marketValue?.number,
  ])

  const propertiesCostsResult = useCosts(propertyUuidList, {
    enabled: isPropertyRequestEnabled,
  })

  const modernizationYear = useMemo(() => {
    const modernizationYearList = []
    propertiesCostsResult?.data?.costsWithLastModernizationDate.forEach((cost) => {
      if (cost?.lastModernizationDate) {
        modernizationYearList.push(cost?.lastModernizationDate)
      }
    })
    return getYearRange(modernizationYearList)
  }, [getYearRange, propertiesCostsResult])

  const {
    isFetching: currentKpisIsLoading,
    isError: currentKpisIsError,
    data: currentKpisData,
  } = useCurrentMultiPropertyKpis(propertyUuidList, {}, { enabled: isPropertyRequestEnabled })

  const formatCurrency = useCustomizableCurrencyFormatter()

  const currentKpis = !currentKpisIsError && !currentKpisIsLoading ? currentKpisData : []
  const kpiRentValues = useMemo(() => {
    const getFormattedValueOrUndefined = (value, currency) =>
      value && currency ? formatCurrency(value, currency) : undefined
    return {
      annualizedCurrentRent: getFormattedValueOrUndefined(
        currentKpis?.annualizedCurrentRent?.number,
        currentKpis?.annualizedCurrentRent?.currency,
      ),
      marketRent: getFormattedValueOrUndefined(
        currentKpis?.totalMarketRent?.number,
        currentKpis?.totalMarketRent?.currency,
      ),
    }
  }, [
    currentKpis?.annualizedCurrentRent?.currency,
    currentKpis?.annualizedCurrentRent?.number,
    currentKpis?.totalMarketRent?.currency,
    currentKpis?.totalMarketRent?.number,
    formatCurrency,
  ])

  const multiPropertyViewResponse = useMultiPropertyViewByPropertyUuids(propertyUuidList, {
    enabled: isPropertyRequestEnabled && checkIsMultiProperty,
  })
  const multiPropertyViewId = useMemo(
    () => multiPropertyViewResponse?.data?.data?.uuid,
    [multiPropertyViewResponse],
  )

  const sourcePath = useMemo(
    () =>
      checkIsMultiProperty
        ? `/properties/portfolio/overview?portfolio-view-id=${multiPropertyViewId}`
        : `/properties/${propertiesInformation.id}`,
    [checkIsMultiProperty, multiPropertyViewId, propertiesInformation.id],
  )

  return useMemo(() => {
    const isLoading =
      isDealUuidFetching ||
      dealPropertiesResult.isFetching ||
      propertiesIsLoading ||
      currentKpisIsLoading ||
      typeCodesIsLoading ||
      multiPropertyViewResponse.isFetching ||
      propertiesCostsResult.isFetching ||
      valuationsIsLoading
    const isError =
      isDealUuidError ||
      dealPropertiesResult.isError ||
      propertiesIsError ||
      currentKpisIsError ||
      typeCodesIsError ||
      (checkIsMultiProperty && multiPropertyViewResponse.isError) ||
      propertiesCostsResult.isError ||
      valuationsIsError
    if (
      !isDealUuidFetching &&
      !isDealUuidError &&
      !dealPropertiesResult.isFetching &&
      !dealPropertiesResult.isError &&
      (!dealPropertiesResult?.data?.dealProperties ||
        dealPropertiesResult?.data?.dealProperties?.length === 0)
    ) {
      return {
        isLoading: false,
        isError: false,
        data: {
          noProperties: true,
        },
      }
    }
    return {
      isLoading,
      isError,
      data: {
        propertiesInformation,
        modernizationYear,
        kpiRentValues,
        isMultiProperty: checkIsMultiProperty,
        sourceRender: { path: `${sourcePath}` },
        noProperties: false,
      },
    }
  }, [
    checkIsMultiProperty,
    currentKpisIsError,
    currentKpisIsLoading,
    dealPropertiesResult?.data?.dealProperties,
    dealPropertiesResult.isError,
    dealPropertiesResult.isFetching,
    isDealUuidFetching,
    isDealUuidError,
    kpiRentValues,
    modernizationYear,
    multiPropertyViewResponse.isError,
    multiPropertyViewResponse.isFetching,
    propertiesCostsResult.isError,
    propertiesCostsResult.isFetching,
    propertiesInformation,
    propertiesIsError,
    propertiesIsLoading,
    sourcePath,
    typeCodesIsError,
    typeCodesIsLoading,
    valuationsIsError,
    valuationsIsLoading,
  ])
}
