import { Text } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/financing-structure/standard-collateral-package/MultipleCollateralAgreementsTile.module.css'
import CollapsibleRichTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/rich-text/CollapsibleRichTextView'
import FormattedTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/rich-text-editor/FormattedTextView'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const CHARACTER_LIMIT = 1000
const NON_TRANSLATED_LABEL_TEXT = 'Deviation from standard:'
const NON_TRANSLATED_ON_TEXT = 'No'
const NON_TRANSLATED_OFF_TEXT = 'Yes'

const MultipleCollateralAgreementsTile = ({ tileId, isPdfView, selectedDealIndex }) => {
  const dispatch = useDispatch()

  const { data: { multiDealCollateralInformationData = [], sourceRender, dealsData } = {} } =
    useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const selectedDealDisplayId = dealsData[selectedDealIndex]?.dealDisplayId

  const collateralInformation = useMemo(
    () => multiDealCollateralInformationData?.[selectedDealIndex] ?? {},
    [multiDealCollateralInformationData, selectedDealIndex],
  )

  useEffect(() => {
    dispatch(setSourceRender({ tileId, sourceRender: { dealDisplayId: selectedDealDisplayId } }))
  }, [dispatch, selectedDealDisplayId, tileId])

  useEffect(() => {
    if (isNil(sourceRender)) {
      dispatch(setSourceRender({ tileId, sourceRender: { dealDisplayId: selectedDealDisplayId } }))
    }
  }, [dispatch, selectedDealDisplayId, sourceRender, tileId])

  const deviationInformation = useMemo(
    () => (
      <div>
        <Text className={styles.text}>{NON_TRANSLATED_LABEL_TEXT}</Text>
        <Text>
          {collateralInformation?.isStandardPackage
            ? NON_TRANSLATED_ON_TEXT
            : NON_TRANSLATED_OFF_TEXT}
        </Text>
      </div>
    ),
    [collateralInformation],
  )

  const latestNote = useMemo(() => {
    if (!collateralInformation?.notes?.length) return null

    const latestNoteVersion = collateralInformation.notes.reduce(
      (prev, current) => (prev && prev.version > current.version ? prev : current),
      undefined,
    )
    return latestNoteVersion?.note
  }, [collateralInformation])

  const textView = useMemo(
    () => (
      <div className={styles.borderDiv}>
        {isPdfView ? (
          <FormattedTextView formattedText={latestNote} />
        ) : (
          <CollapsibleRichTextView text={latestNote} characterLimit={CHARACTER_LIMIT} />
        )}
      </div>
    ),
    [isPdfView, latestNote],
  )

  return (
    <>
      {deviationInformation}
      {latestNote && textView}
    </>
  )
}

MultipleCollateralAgreementsTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultipleCollateralAgreementsTile
