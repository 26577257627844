import isEmpty from 'lodash.isempty'

const conditionTypeChanged = (state, { payload: { type: newType } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }
  state.editedRow.currentValues.type = newType
  delete state.editedRow.errorFields.type
  if (newType === state.editedRow.initialValues.type) {
    delete state.editedRow.changedFields.type
    return
  }
  state.editedRow.changedFields.type = true
}

export default conditionTypeChanged
