import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
} from '@fioneer/ui5-webcomponents-react'
import { ticks as ticksCalculation } from 'd3-array'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CartesianGrid, Legend, Line, Tooltip, XAxis, YAxis } from 'recharts'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/components/RiskIndicatorsGroupCriticalityLineChartChildrenDecisionPaper.module.css'
import Card from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/card/Card'
import Labeled from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/data/Labeled'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const propTypes = {
  additionalLineChartArguments: PropTypes.shape({
    valueBoundaries: PropTypes.shape({
      yAxis: PropTypes.shape({ yDataMin: PropTypes.number, yDataMax: PropTypes.number }),
    }),
    riskIndicatorCriticalityStatusTypeCodes: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        shortText: PropTypes.string,
      }),
    ),
  }),
}

const numberOfAxisTicksX = 5
const defaultNumberOfAxisTicksY = 4

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const RiskIndicatorsGroupCriticalityLineChartChildrenDecisionPaper = ({
  additionalLineChartArguments,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.risk-indicators.criticality',
  })
  const { format: formatDate } = useShortDateFormatter()
  const formatUnixDate = (timestamp) => formatDate(DateTime.fromMillis(timestamp).toISODate())

  const {
    valueBoundaries: {
      yAxis: { yDataMin, yDataMax },
    },
    riskIndicatorCriticalityStatusTypeCodes = [],
    isPdfView = false,
  } = additionalLineChartArguments ?? {}

  // there should be as many y-axis ticks as there are possible criticality statuses in the y value range
  const numberOfAxisTicksY =
    riskIndicatorCriticalityStatusTypeCodes?.filter(
      ({ code }) => yDataMin <= code && code <= yDataMax,
    )?.length ?? defaultNumberOfAxisTicksY

  const yAxisTicks = useMemo(
    () => ticksCalculation(yDataMin, yDataMax, numberOfAxisTicksY - 1),
    [yDataMax, yDataMin, numberOfAxisTicksY],
  )

  const getCriticalityShortText = useCallback(
    (typeCode) =>
      riskIndicatorCriticalityStatusTypeCodes.find(({ code }) => code === `${typeCode}`)
        ?.shortText ?? '',
    [riskIndicatorCriticalityStatusTypeCodes],
  )

  const renderTooltip = ({ payload }) => {
    const { dataKey, payload: { dateUnixTimestamp, criticalityValue } = {} } = payload?.[0] ?? {}

    if (dataKey === 'businessEventValue') {
      return (
        <Card>
          <FlexBox
            direction={FlexBoxDirection.Column}
            justifyContent={FlexBoxJustifyContent.Start}
            className={styles.tooltip}
          >
            <FlexBox direction={FlexBoxDirection.Column}>
              <Labeled showColon label={t('label.closing-date')}>
                {formatUnixDate(dateUnixTimestamp)}
              </Labeled>
            </FlexBox>
          </FlexBox>
        </Card>
      )
    } else if (dataKey === 'criticalityValue') {
      return (
        <Card className={styles.tooltip}>
          <FlexBox
            direction={FlexBoxDirection.Column}
            justifyContent={FlexBoxJustifyContent.Start}
            className={styles.tooltip}
          >
            <FlexBox direction={FlexBoxDirection.Column}>
              <Labeled showColon label={t('label.criticality-date')}>
                {formatUnixDate(dateUnixTimestamp)}
              </Labeled>
              <Labeled showColon label={t('label.criticality')}>
                {getCriticalityShortText(criticalityValue)}
              </Labeled>
            </FlexBox>
          </FlexBox>
        </Card>
      )
    }
  }

  return (
    <>
      <CartesianGrid
        horizontal={true}
        vertical={false}
        stroke="#E5E5E5"
        className={styles['cartesianGrid']}
      />
      <Line
        connectNulls
        key="line-criticality"
        name={t('label.criticality')}
        type="stepAfter"
        isAnimationActive={false}
        dataKey="criticalityValue"
        stroke="#3b0ac6" // sapContent_Illustrative_Color16
        strokeWidth={1.2}
        dot={false}
      />
      <Line
        stroke="none"
        key="line-business-events"
        name={t('label.business-events')}
        isAnimationActive={false}
        dataKey="businessEventValue"
        dot={{ fill: '#bb0000' /* sapNegativeColor */, r: 4 }}
      />
      <XAxis
        dataKey="dateUnixTimestamp"
        tickLine={false}
        axisLine={true}
        interval="preserveStartEnd"
        allowDecimals={false}
        height={70}
        angle={-45}
        textAnchor="end"
        tickCount={numberOfAxisTicksX}
        type="number"
        tick={{ fontSize: 13 }}
        padding={{ left: 10, right: 10 }}
        tickFormatter={
          (dateUnixTimestamp) => `${formatUnixDate(dateUnixTimestamp)} ` // empty character added so that the tick at the end does not get cut off
        }
        domain={['dataMin', 'dataMax']}
      />
      <YAxis
        type="number"
        tickLine={false}
        axisLine={false}
        tickFormatter={getCriticalityShortText}
        width={90}
        interval={0} // all ticks shown
        ticks={yAxisTicks}
        domain={[yAxisTicks[0], yAxisTicks[yAxisTicks.length - 1]]}
        padding={{ top: 20, bottom: 20 }}
      />
      {!isPdfView && <Tooltip cursor={true} content={renderTooltip} />}
      <Legend
        layout="horizontal"
        align="center"
        verticalAlign="bottom"
        iconType="plainline"
        formatter={(value, entry) => {
          // Since the business events are only dots on the line graph and have no stroke/line set,
          // their default legend item would have no icon.
          // Therefore, a custom legend item is rendered only for business events
          if (entry?.dataKey === 'businessEventValue') {
            return (
              <FlexBox direction={FlexBoxDirection.Row} alignItems={FlexBoxAlignItems.Baseline}>
                <span className={`${styles.legend} ${styles.businessEventLegend}`}>{value}</span>
              </FlexBox>
            )
          }
          return <span className={styles.legend}>{value}</span>
        }}
      />
    </>
  )
}

RiskIndicatorsGroupCriticalityLineChartChildrenDecisionPaper.propTypes = propTypes

export default RiskIndicatorsGroupCriticalityLineChartChildrenDecisionPaper
