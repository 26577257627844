import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import TableInputCell from 'components/domains/conditions/overview/table-cells/view/TableInputCell'
import useRequirementsCellAddMode from 'hooks/services/conditions/edit-mode/useRequirementsCellAddMode'
import useRequirementsCellEditMode from 'hooks/services/conditions/edit-mode/useRequirementsCellEditMode'
import { requirementRefNumberChanged } from 'redux/slices/conditions/requirementsTableSlice'

const RequirementsTableRefNumberCell = ({ value: { refNumber, requirementId } }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.columns.ref-number',
  })

  const dispatch = useDispatch()
  const editedRequirementRefNumber = useSelector(
    (state) => state.conditions.requirementsTable.editedRow.currentValues?.refNumber,
  )
  const { isAddModeForCurrentRow } = useRequirementsCellAddMode({
    requirementId,
  })
  const { isEditModeForCurrentRow } = useRequirementsCellEditMode({
    requirementId,
  })

  const onInput = useCallback(
    (newRefNumber) => {
      dispatch(requirementRefNumberChanged({ refNumber: newRefNumber }))
    },
    [dispatch],
  )

  const inputValue = useMemo(() => {
    if (isAddModeForCurrentRow || isEditModeForCurrentRow) {
      return editedRequirementRefNumber
    }
    return refNumber ?? ''
  }, [isAddModeForCurrentRow, isEditModeForCurrentRow, editedRequirementRefNumber, refNumber])

  return (
    <TableInputCell
      input={inputValue}
      isEditMode={isEditModeForCurrentRow}
      onInput={onInput}
      placeholder={t('placeholder')}
    />
  )
}

RequirementsTableRefNumberCell.propTypes = {
  value: PropTypes.shape({
    requirementId: PropTypes.string,
    refNumber: PropTypes.string,
  }).isRequired,
}

export default RequirementsTableRefNumberCell
