// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Upl4yKGz2SSNyQh88oTT {
    width: auto !important;
    max-width: 80%;
}

.bDYGOXcrOjkzWxjjAqfA {
    margin-bottom: var(--sapUiSmallSpace);
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/errors/DefaultErrorPage.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,qCAAqC;AACzC","sourcesContent":[".errorDetails {\n    width: auto !important;\n    max-width: 80%;\n}\n\n.wrapper {\n    margin-bottom: var(--sapUiSmallSpace);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorDetails": `Upl4yKGz2SSNyQh88oTT`,
	"wrapper": `bDYGOXcrOjkzWxjjAqfA`
};
export default ___CSS_LOADER_EXPORT___;
