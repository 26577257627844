import cloneDeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import merge from 'lodash.merge'
import { DateTime, Duration } from 'luxon'
import { Direction } from 'components/domains/deals/covenants/shared/useDirectionTranslation'
import { temporalUnitEnum } from 'components/domains/deals/covenants/shared/useTemporalUnitTranslation'

const unitToDurationUnit = (unit) =>
  ({
    [temporalUnitEnum.DAYS]: 'days',
    [temporalUnitEnum.WEEKS]: 'weeks',
    [temporalUnitEnum.MONTHS]: 'months',
  }[unit] ?? '')

const useEnrichDueDate = () => {
  const subtractBaseDataFromDate = (inputDate, baseData) => {
    const unit = unitToDurationUnit(baseData?.temporalUnit)
    if (!unit) return null
    if (isEmpty(baseData)) return null
    if (isNil(baseData?.amountToAdd)) return null
    if (!baseData?.direction) return null

    const dateToSubtractFrom = DateTime.fromISO(inputDate)
    const amount =
      baseData?.direction === Direction.BEFORE ? baseData?.amountToAdd * -1 : baseData?.amountToAdd
    const durationToSubtract = Duration.fromObject({
      [unit]: amount,
    })

    return dateToSubtractFrom.minus(durationToSubtract).toISODate()
  }

  const addBaseDataToDate = (inputDate, baseData) => {
    if (baseData?.direction === null) return null

    const invertAmountToAdd = (direction) => {
      if (direction === Direction.AFTER) return Direction.BEFORE
      if (direction === Direction.BEFORE) return Direction.AFTER
      return null
    }

    const invertedBaseData = merge(cloneDeep(baseData), {
      direction: invertAmountToAdd(baseData?.direction),
    })

    return subtractBaseDataFromDate(inputDate, invertedBaseData)
  }

  const enrichDueDate = ({ monitoringInformation, dueDate, hasDocumentRequest }) => {
    if (hasDocumentRequest) return dueDate

    const calculatedTargetDeliveryDate = subtractBaseDataFromDate(
      dueDate?.checkDate,
      monitoringInformation?.firstDueDate?.checkDateBaseData,
    )
    if (!calculatedTargetDeliveryDate) return dueDate

    return { ...dueDate, targetDeliveryDate: calculatedTargetDeliveryDate }
  }

  return { enrichDueDate, subtractBaseDataFromDate, addBaseDataToDate }
}

export default useEnrichDueDate
