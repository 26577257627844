import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import SmallLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/ui/loading/SmallLoadingWrapper'

const ComplianceWithRiskStrategyCell = ({ isLoading = false, isError = false, data = {} }) => {
  const { t: tBoolean } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.unit-overview.components.formatters.boolean',
  })

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      error={tBoolean('no')}
      renderContent={() => (
        <>
          {tBoolean(data?.compliance ? 'yes' : 'no')}
          {data?.riskStrategy && ` (${data.riskStrategy})`}
        </>
      )}
    />
  )
}

ComplianceWithRiskStrategyCell.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  data: PropTypes.shape({
    compliance: PropTypes.bool,
  }),
}

export default ComplianceWithRiskStrategyCell
