import { SuggestionItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import InputWithSearchSuggestions from 'components/ui/input/InputWithSearchSuggestions'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'
import useStaffMembers from 'hooks/services/business-partners/staff-members/useStaffMembers'

const AssigneeSelectionFilter = ({ value, setSelectedFilters, columnKey }) => {
  const [userSearchKey, setUserSearchKey] = useState(value)

  useEffect(() => {
    if (!value) {
      setUserSearchKey('')
    }
  }, [value])

  const handleOnChange = useCallback(
    ({ detail: { item: selectedUser } }) => {
      const userId = selectedUser.getAttribute('data-user-id')
      setSelectedFilters((currentlySelectedFilters) =>
        currentlySelectedFilters.map((filter) => {
          if (filter.columnKey !== columnKey) {
            return filter
          }
          return { ...filter, value: userId }
        }),
      )
    },
    [columnKey, setSelectedFilters],
  )
  const handleOnClear = useCallback(() => {
    setSelectedFilters((currentlySelectedFilters) =>
      currentlySelectedFilters.map((filter) => {
        if (filter.columnKey !== columnKey) {
          return filter
        }
        return { ...filter, value: '' }
      }),
    )
  }, [columnKey, setSelectedFilters])

  const { data: assigneeUser } = useStaffMemberById(value)

  const { data: suggestions, isError: isSuggestionsError } = useStaffMembers({
    name: userSearchKey,
    minLength: 1,
  })

  const renderSuggestions = useCallback(() => {
    const suggestionsItems = []
    suggestions?.staffMembers.forEach((user, index) => {
      suggestionsItems.push(
        <SuggestionItem key={index} text={user.fullName} data-user-id={user.id} />,
      )
    })

    return suggestionsItems
  }, [suggestions])

  return (
    <InputWithSearchSuggestions
      initialValue={assigneeUser?.fullName ?? ''}
      renderSuggestions={renderSuggestions}
      handleOnChange={handleOnChange}
      handleOnClear={handleOnClear}
      setSearchKey={setUserSearchKey}
      isSuggestionsError={isSuggestionsError}
    />
  )
}

AssigneeSelectionFilter.propTypes = {
  value: PropTypes.string.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  columnKey: PropTypes.string.isRequired,
}

export default AssigneeSelectionFilter
