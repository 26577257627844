import { isEmpty } from 'lodash'
import maxBy from 'lodash.maxby'
import orderBy from 'lodash.orderby'
import { useEffect, useMemo } from 'react'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import styles from 'components/domains/deals/limit-check/reservations/DealReservationCard.module.css'
import { useExternalTypeMappingMethods } from 'components/domains/deals/limit-check/reservations/external-type/useExternalTypeMappingMethods'
import { useDealReservationCardActions } from 'components/domains/deals/limit-check/reservations/useDealReservationCardActions'
import useDealReservationConfigMaps from 'components/domains/deals/limit-check/reservations/useDealReservationConfigMaps'
import {
  useDealReservationCurrencyConversionData,
  CONVERSION_STATUS,
} from 'components/domains/deals/limit-check/reservations/useDealReservationCurrencyConversionData'
import { useDealReservationTableConfig } from 'components/domains/deals/limit-check/reservations/useDealReservationTableConfig'
import { rowKeyNewRow } from 'components/ui/tables/display-and-edit-table/constants'
import useFinancing from 'hooks/services/deals/financing/useFinancing'
import useAggregatedPortfolio from 'hooks/services/deals/portfolio/useAggregatedPortfolio'
import useDealProperties from 'hooks/services/deals/properties/useDealProperties'
import useReservations from 'hooks/services/deals/reservations/useReservations'
import useDeal from 'hooks/services/deals/useDeal'
import usePropertyUuids from 'hooks/services/properties/usePropertyUuids'
import { DEAL_STATUS_RANKS } from 'routes/deals/financing/financingConstants'

const RESERVED_STATUS = 'RESERVED'
const ALLOWED_BUSINESS_SEGMENT_CODE = 'REF'

export const useDealReservationCardData = (dealUuid) => {
  const cellStyles = `${styles.vAlignTop} ${styles.cellPadding}`
  const {
    data: { creditReservations: allReservations = [] } = {},
    isLoading: isLoadingReservations,
    isError: isErrorReservations,
  } = useReservations(dealUuid)

  const noOfReservations = useMemo(() => allReservations.length, [allReservations])

  const reservationUuids = useMemo(
    () => allReservations.map(({ creditReservationUuid }) => creditReservationUuid),
    [allReservations],
  )

  const lastUpdatedReservation = useMemo(
    () => maxBy(allReservations, 'lastUpdatedAt') ?? {},
    [allReservations],
  )

  const {
    data: { businessSegmentCode, dealRank, dealTypeCode, workingVersion } = {},
    isLoading: isLoadingDeal,
    isError: isErrorDeal,
  } = useDeal(dealUuid)

  const {
    data: { tranches = [] } = {},
    isLoading: isLoadingTranches,
    isError: isErrorTranches,
  } = useFinancing(dealUuid)

  const {
    data: { dealProperties = [] } = {},
    isLoading: isLoadingDealProperties,
    isError: isErrorDealProperties,
  } = useDealProperties({
    dealUuid,
  })

  const dealPropertiesData = useMemo(
    () => dealProperties.map(({ propertyUuid }) => propertyUuid),
    [dealProperties],
  )

  const {
    data: { data: { properties = [] } = {} } = {},
    isLoading: isLoadingProperties,
    isError: isErrorProperties,
  } = usePropertyUuids(dealPropertiesData, { enabled: !isEmpty(dealPropertiesData) }, true)

  const {
    data: { countryCodes = [], mainPropertyTypeCode } = {},
    isLoading: isLoadingPortfolio,
    isError: isErrorPortfolio,
  } = useAggregatedPortfolio(dealUuid)

  const {
    data: {
      calculateCommitmentPerProduct,
      calculateReservationPerProduct,
      getExternalPropertyType,
      getUniqueExternalPropertyTypes,
      getUniqueValuesFromReservations,
      hasReservationExpired,
    },
    isLoading: isLoadingConfigurations,
    isError: isErrorConfigurations,
  } = useExternalTypeMappingMethods(dealUuid)

  const {
    columnDefinitions,
    renderReservationIdViewCell,
    renderReservationIdEditCell,
    renderBusinessSegmentViewCell,
    renderBusinessSegmentEditCell,
    renderMainPropertyTypeViewCell,
    renderMainPropertyTypeEditCell,
    renderAmountViewCell,
    renderAmountEditCell,
    renderCommentViewCell,
    renderCommentEditCell,
  } = useDealReservationTableConfig()

  const {
    isLoadingExternalSynchronization,
    isValidReservation,
    isPreDealLimitCheckFinished,
    reservationUuid,
    getInitialValues,
    validateNewReservationField,
    getNewReservationValue,
    onChange,
    onCancel,
    onClose,
    onCancelReservation,
    onConfirmReservation,
    onDelete,
    onSave,
    onSynchronize,
    error,
  } = useDealReservationCardActions({
    dealUuid,
  })

  const preDealLimitCheckResult = useMemo(
    () =>
      allReservations.find(({ creditReservationUuid }) => creditReservationUuid === reservationUuid)
        ?.externalStatusMessage,
    [allReservations, reservationUuid],
  )

  const { statusConfigMap, warningConfigMap, isStatusResolved, resolveWarningMessage } =
    useDealReservationConfigMaps()

  const validReservations = useMemo(
    () =>
      allReservations.filter(
        ({ endTimestamp, status }) =>
          !hasReservationExpired(endTimestamp) && status === RESERVED_STATUS,
      ),
    [allReservations, hasReservationExpired],
  )

  const { convertingStatus, convertToDefaultCurrency } = useDealReservationCurrencyConversionData({
    validReservations,
  })

  const shouldPerformConversion = useMemo(
    () => convertingStatus === CONVERSION_STATUS.INITIAL && !!validReservations.length,
    [convertingStatus, validReservations.length],
  )

  useEffect(() => {
    shouldPerformConversion && convertToDefaultCurrency()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldPerformConversion])

  const isErrorConvertingCurrencies = useMemo(
    () => convertingStatus === CONVERSION_STATUS.ERROR,
    [convertingStatus],
  )

  const isError = useMemo(
    () =>
      isErrorReservations ||
      isErrorTranches ||
      isErrorConfigurations ||
      isErrorDeal ||
      isErrorDealProperties ||
      isErrorProperties ||
      isErrorPortfolio ||
      isErrorConvertingCurrencies,
    [
      isErrorReservations,
      isErrorTranches,
      isErrorConfigurations,
      isErrorDeal,
      isErrorDealProperties,
      isErrorProperties,
      isErrorPortfolio,
      isErrorConvertingCurrencies,
    ],
  )

  const isLoading = useMemo(
    () =>
      isLoadingTranches ||
      isLoadingConfigurations ||
      isLoadingDeal ||
      isLoadingDealProperties ||
      isLoadingProperties ||
      isLoadingPortfolio ||
      shouldPerformConversion,
    [
      isLoadingTranches,
      isLoadingConfigurations,
      isLoadingDeal,
      isLoadingDealProperties,
      isLoadingProperties,
      isLoadingPortfolio,
      shouldPerformConversion,
    ],
  )

  const isNewBusinessDeal = useMemo(() => dealRank < DEAL_STATUS_RANKS.SIGNED, [dealRank])

  const isCreatingSupported = useMemo(
    () => isNewBusinessDeal || workingVersion === WorkingVersionType.WORKING_VERSION,
    [isNewBusinessDeal, workingVersion],
  )

  const haveSinglePropertyTypeAndCountry = useMemo(() => {
    const noOfPropertyTypes = getUniqueExternalPropertyTypes(properties).length
    const noOfCountries = countryCodes.length

    return noOfPropertyTypes === 1 && noOfCountries === 1
  }, [countryCodes, getUniqueExternalPropertyTypes, properties])

  const isStatusCalculationSupported = useMemo(
    () =>
      isNewBusinessDeal &&
      workingVersion === WorkingVersionType.LIVE &&
      businessSegmentCode === ALLOWED_BUSINESS_SEGMENT_CODE &&
      haveSinglePropertyTypeAndCountry,
    [businessSegmentCode, haveSinglePropertyTypeAndCountry, isNewBusinessDeal, workingVersion],
  )

  const isStatusCalculationNotSupported = useMemo(
    () =>
      isNewBusinessDeal &&
      workingVersion === WorkingVersionType.LIVE &&
      businessSegmentCode === ALLOWED_BUSINESS_SEGMENT_CODE &&
      !haveSinglePropertyTypeAndCountry,
    [businessSegmentCode, haveSinglePropertyTypeAndCountry, isNewBusinessDeal, workingVersion],
  )

  const reservationPerProduct = useMemo(
    () => calculateReservationPerProduct(validReservations, statusConfigMap.RESERVED.code),
    [calculateReservationPerProduct, statusConfigMap.RESERVED.code, validReservations],
  )

  const commitmentPerProduct = useMemo(
    () => calculateCommitmentPerProduct(tranches, dealTypeCode),
    [calculateCommitmentPerProduct, dealTypeCode, tranches],
  )

  const productKeys = useMemo(() => Object.keys(commitmentPerProduct), [commitmentPerProduct])

  const calculatedReservationStatus = useMemo(() => {
    if (isStatusCalculationSupported) {
      // 1. reserved amount is EQUAL to commitment for all products: RESERVED
      if (
        isStatusResolved(
          statusConfigMap.RESERVED,
          productKeys,
          reservationPerProduct,
          commitmentPerProduct,
        )
      )
        return statusConfigMap.RESERVED.code

      // 2. reserved amount is LOWER than commitment for at least one product: PARTIALLY RESERVED
      if (
        isStatusResolved(
          statusConfigMap.PARTIALLY_RESERVED,
          productKeys,
          reservationPerProduct,
          commitmentPerProduct,
        )
      )
        return statusConfigMap.PARTIALLY_RESERVED.code

      // 3. reserved amount is GREATER than commitment for at least one products: OVERRESERVED
      if (
        isStatusResolved(
          statusConfigMap.OVERRESERVED,
          productKeys,
          reservationPerProduct,
          commitmentPerProduct,
        )
      )
        return statusConfigMap.OVERRESERVED.code

      // 4. reserved amount is EQUAL to zero for all products: NOT RESERVED
      if (isStatusResolved(statusConfigMap.NOT_RESERVED, productKeys, reservationPerProduct))
        return statusConfigMap.NOT_RESERVED.code

      return statusConfigMap.PARTIALLY_RESERVED.code
    }

    // deal rank is >= 100 : NOT SUPPORTED
    return statusConfigMap.NOT_SUPPORTED.code
  }, [
    isStatusCalculationSupported,
    statusConfigMap,
    isStatusResolved,
    productKeys,
    reservationPerProduct,
    commitmentPerProduct,
  ])

  const warningMessages = useMemo(() => {
    const { propertyTypesOfReservations, productTypesOfReservations, countryCodesOfReservations } =
      getUniqueValuesFromReservations(validReservations)
    const warnings = []

    if (isLoading || isError) {
      return warnings
    }

    if (isStatusCalculationSupported) {
      warnings.push(
        resolveWarningMessage(warningConfigMap.UNDER_RESERVED, calculatedReservationStatus),
      )
      warnings.push(
        resolveWarningMessage(
          warningConfigMap.COUNTRY_CHANGED,
          countryCodesOfReservations,
          countryCodes,
        ),
      )
      warnings.push(
        resolveWarningMessage(
          warningConfigMap.PRODUCT_CHANGED,
          productTypesOfReservations,
          productKeys,
        ),
      )
      warnings.push(
        resolveWarningMessage(
          warningConfigMap.PROPERTY_CHANGED,
          propertyTypesOfReservations,
          getExternalPropertyType(mainPropertyTypeCode),
        ),
      )
      warnings.push(
        resolveWarningMessage(
          warningConfigMap.OUTDATED_OR_OUT_OF_SYNC_RESERVATIONS,
          noOfReservations,
          validReservations.length,
        ),
      )
      warnings.push(resolveWarningMessage(warningConfigMap.NO_RESERVATIONS, noOfReservations))
    }

    if (isStatusCalculationNotSupported) {
      warnings.push(
        resolveWarningMessage(
          warningConfigMap.MULTI_COUNTRY_OR_PROPERTY_TYPE,
          countryCodes.length,
          getUniqueExternalPropertyTypes(properties).length,
        ),
      )
      warnings.push(resolveWarningMessage(warningConfigMap.STATUS_CALCULATION_NOT_SUPPORTED))
      warnings.push(resolveWarningMessage(warningConfigMap.NO_RESERVATIONS, noOfReservations))
    }

    if (workingVersion === WorkingVersionType.WORKING_VERSION) {
      warnings.push(resolveWarningMessage(warningConfigMap.STATUS_CALCULATION_NOT_SUPPORTED))
      warnings.push(resolveWarningMessage(warningConfigMap.NO_RESERVATIONS, noOfReservations))
    }

    return warnings.filter(Boolean)
  }, [
    getUniqueValuesFromReservations,
    validReservations,
    isLoading,
    isError,
    isStatusCalculationSupported,
    isStatusCalculationNotSupported,
    workingVersion,
    resolveWarningMessage,
    warningConfigMap,
    calculatedReservationStatus,
    countryCodes,
    productKeys,
    getExternalPropertyType,
    mainPropertyTypeCode,
    noOfReservations,
    getUniqueExternalPropertyTypes,
    properties,
  ])

  const newRow = {
    rowKey: rowKeyNewRow,
    isValid: isValidReservation,
    reservationId: {
      cellContentEditMode: renderReservationIdEditCell(),
      cellEditModeProps: {
        className: cellStyles,
      },
    },
    businessSegment: {
      cellContentEditMode: renderBusinessSegmentEditCell(
        onChange,
        validateNewReservationField,
        businessSegmentCode,
      ),
      cellEditModeProps: {
        className: cellStyles,
      },
    },
    propertyType: {
      cellContentEditMode: renderMainPropertyTypeEditCell(onChange, validateNewReservationField),
      cellEditModeProps: {
        className: cellStyles,
      },
    },
    reservedAmount: {
      cellContentEditMode: renderAmountEditCell(
        onChange,
        validateNewReservationField,
        getNewReservationValue,
        getInitialValues,
      ),
      cellEditModeProps: {
        className: cellStyles,
      },
    },
    comment: {
      cellContentEditMode: renderCommentEditCell(onChange),
      cellEditModeProps: {
        className: cellStyles,
      },
    },
  }

  const tableData = orderBy(allReservations, 'endTimestamp', 'desc').map(
    ({
      externalMainPropertyTypeCode,
      externalReservationId,
      externalStatusMessage,
      creditReservationUuid,
      status,
      externalProductTypeCode,
      countryCode,
      amount,
      startTimestamp,
      endTimestamp,
      comment,
    }) => {
      const currentRowKey = creditReservationUuid
      return {
        rowKey: currentRowKey,
        reservationId: {
          cellContentReadMode: renderReservationIdViewCell(
            externalReservationId,
            status,
            externalStatusMessage,
          ),
          cellReadModeProps: {
            className: cellStyles,
          },
        },
        businessSegment: {
          cellContentReadMode: renderBusinessSegmentViewCell(
            businessSegmentCode,
            externalProductTypeCode,
          ),
          cellReadModeProps: {
            className: cellStyles,
          },
        },
        propertyType: {
          cellContentReadMode: renderMainPropertyTypeViewCell(
            externalMainPropertyTypeCode,
            countryCode,
          ),
          cellReadModeProps: {
            className: cellStyles,
          },
        },
        reservedAmount: {
          cellContentReadMode: renderAmountViewCell(
            amount?.number,
            amount?.currencyCode,
            startTimestamp,
            endTimestamp,
          ),
          cellReadModeProps: {
            className: cellStyles,
          },
        },
        comment: {
          cellContentReadMode: renderCommentViewCell(comment),
          cellReadModeProps: {
            className: cellStyles,
          },
        },
        hideEditButton: true,
        hideDeleteButton: hasReservationExpired(endTimestamp),
      }
    },
  )

  return {
    tableData,
    lastUpdatedReservation,
    newRow,
    reservationUuids,
    columnDefinitions,
    calculatedReservationStatus,
    noOfReservations,
    preDealLimitCheckResult,
    warningMessages,
    cellStyles,
    actions: {
      onCancel,
      onClose,
      onCancelReservation,
      onConfirmReservation,
      onDelete,
      onSave,
      onSynchronize,
    },
    indicators: {
      isCreatingSupported,
      isStatusCalculationSupported,
      isPreDealLimitCheckFinished,
      isLoadingExternalSynchronization,
      isLoadingReservations,
      isErrorReservations,
      isLoading,
      isError,
    },
    error,
  }
}
