import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

/**
 * @deprecated
 * This hook uses a deprecated query parameter (property_id_for_assigned_market)
 * It needs to be replaced with POST /markets/properties
 * See usePropertyIdForAssignedMarket for reference and useMultipleArrayPropertyUuids for an example how it could work.
 */
const useMultiplePropertyIdForAssignedMarket = ({ multiplePropertyIdLists, options = {} }) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: multiplePropertyIdLists.map((propertyIds) => {
        const filterProperties = new URLSearchParams()
        propertyIds.forEach((propertyId) =>
          filterProperties.append('property_id_for_assigned_market', propertyId),
        )

        return {
          path: `/markets?${filterProperties.toString()}`,
          keys: ['markets', `propertyIds=${propertyIds}`],
        }
      }),
      translated: true,
      useCache: true,
      options: { enabled: !isEmpty(multiplePropertyIdLists), ...options },
    }),
  )

  return useMemo(
    () =>
      responses.map((result, index) => ({
        ...result,
        data: { propertyIds: multiplePropertyIdLists[index], ...camelize(result.data) },
      })),
    [multiplePropertyIdLists, responses],
  )
}

export default useMultiplePropertyIdForAssignedMarket
