import { IllustratedMessage, AnalyticalTableScaleWidthMode } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/collaterals/CollateralAgreementsTile.module.css'
import { AnalyticalTableWithToolbar } from 'components/domains/business-events-and-tasks/decision-paper/tiles/collaterals/shared/AnalyticalTableWithToolbar'
import { mapAggregatedCollateralAgreementsToTableData } from 'components/domains/business-events-and-tasks/decision-paper/tiles/collaterals/shared/mapAggregatedCollateralAgreementsToTableData'
import useCollateralAgreementsTileColumnDefinitions from 'components/domains/business-events-and-tasks/decision-paper/tiles/collaterals/shared/useCollateralAgreementsTileColumnDefinitions'

const CollateralAgreementsTile = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.collaterals.collateral-agreements-table',
  })

  const { data } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const mappedTableData = useMemo(
    () =>
      mapAggregatedCollateralAgreementsToTableData({
        aggregatedCollateralAgreements: data,
        areRowsSectionHeader: false,
        isTotalSectionHeader: true,
        isNormalRowBold: false,
      }),
    [data],
  )

  const columnDefinitions = useCollateralAgreementsTileColumnDefinitions()

  return (
    <AnalyticalTableWithToolbar
      title={t('title')}
      className={styles.table}
      loading={false}
      id="collateralAgreementsTile-table"
      sortable={false}
      minRows={0}
      columns={columnDefinitions}
      overscanCountHorizontal={99}
      rowHeight={50}
      visibleRows={99}
      headerRowHeight={30}
      data={mappedTableData ?? []}
      isTreeTable={false}
      scaleWidthMode={AnalyticalTableScaleWidthMode.Smart}
      withRowHighlight={false}
      NoDataComponent={() => (
        <IllustratedMessage
          name="NoData"
          titleText={t('no-data-title')}
          subtitleText={t('no-data-text')}
        />
      )}
    />
  )
}

CollateralAgreementsTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default CollateralAgreementsTile
