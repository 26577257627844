import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const usePropertyInsurances = (propertyUuid) =>
  useCamelizedResponse(
    useRequest({
      path: `/properties/${propertyUuid}/insurance`,
      useCache: true,
      keys: ['property-insurance', propertyUuid],
      options: {
        retry: 0,
      },
    }),
  )
