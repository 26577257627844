import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useEditRequirementComment = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(async ({ requirementId, commentId, comment }) => {
    const { data } = await patch({
      path: `/conditions/external/requirements/${requirementId}/comments/${commentId}`,
      body: {
        comment,
      },
    })
    return data
  }, mutationOptions)
}

export default useEditRequirementComment
