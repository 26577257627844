import { createSlice } from '@reduxjs/toolkit'
import {
  VIEW_OPTION_KPI_TYPE,
  VIEW_OPTION_PERIODS,
} from 'components/domains/properties/rent-roll/overview/structure-overview/constants'

const initialState = {
  period: VIEW_OPTION_PERIODS.ANNUALLY,
  kpiValues: VIEW_OPTION_KPI_TYPE.CURRENT_RET,
}

export const segmentsRentalIncomeSlice = createSlice({
  name: 'properties/rent-roll/segment/rental-income',
  initialState,
  reducers: {
    setKpiValues: (state, { payload }) => {
      state.kpiValues = payload
    },
    setPeriod: (state, { payload }) => {
      state.period = payload
    },
    resetPeriod: () => ({ ...initialState }),
  },
})

export const { setPeriod, setKpiValues, resetPeriod } = segmentsRentalIncomeSlice.actions

export default segmentsRentalIncomeSlice.reducer
