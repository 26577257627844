import {
  CustomListItem,
  Link,
  List,
  ListMode,
  ListSeparators,
  Popover,
} from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import styles from 'components/ui/tables/cells/PopoverCell.module.css'

const propTypes = {
  /** The text of the link that opens the popover. */
  linkText: PropTypes.string,
  /** The node items displayed in the popover. */
  listItems: PropTypes.arrayOf(PropTypes.node),
  /**
   * Options to modify how the Cell will look like.
   *
   * - `isNameBold` Display the list name in bold font. Defaults to false.
   * - `showPopoverHeader` Display a header for the popover list. Defaults to false.
   * - `popoverProps` Custom popover props passed directly to the popover component. Defaults to an empty object.
   */
  options: PropTypes.shape({
    isNameBold: PropTypes.bool,
    showPopoverHeader: PropTypes.bool,
    popoverProps: PropTypes.shape({}),
  }),
  /** Defines whether a loading spinner is shown. */
  isLoading: PropTypes.bool,
  /** Defines whether a loading spinner is shown. */
  isFetching: PropTypes.bool,
  /** Defines whether an error is shown. */
  isError: PropTypes.bool,
  /** Allows to define a custom error component. Defaults to undefined so that `<ErrorDataUnavailableInCell />` is shown. */
  errorToDisplay: PropTypes.node,
  /** Defines additional styling to the link component of the cell. */
  classNameLink: PropTypes.string,
  /** Defines additional styling to the popover component of the cell. */
  classNamePopover: PropTypes.string,
}

/**
 * Shared Popover Cell for usage within the Responsive and Analytical Table.
 *
 * By using a list inside a popover, this cell can display multiple entities in one table cell, each entity being one list item.
 *
 * @typedef Props
 * @property {String} [linkText] The text of the link that opens the popover.
 * @property {Array.<React.ReactNode>} [listItems] The node items displayed in the popover.
 * @property {Object} [options] Options to modify how the Cell will look like.
 * @property {Boolean} [options.isNameBold] Display the list name in bold font. Defaults to false.
 * @property {Boolean} [options.showPopoverHeader] Display a header for the popover list. Defaults to false.
 * @property {Object} [options.popoverProps] Custom popover props passed directly to the popover component. Defaults to an empty object.
 * @property {Boolean} [isLoading] Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} [isFetching] Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} [isError] Defines whether an error is shown.
 * @property {React.ReactNode} [errorToDisplay] Allows to define a custom error component. Defaults to undefined so that <ErrorDataUnavailableInCell /> is shown.
 * @property {String} [classNameLink] Defines additional styling to the link component of the cell.
 * @property {String} [classNamePopover] Defines additional styling to the popover component of the cell.
 */

/**
 * @param {Props} props Note for Storybook: cf. code for complete JSDoc
 */
const PopoverCell = ({
  linkText,
  listItems,
  options: { isNameBold = false, showPopoverHeader = false, popoverProps = {} } = {},
  isLoading = false,
  isFetching = false,
  isError = false,
  classNameLink,
  classNamePopover,
  errorToDisplay,
}) => {
  const [popoverIsOpen, setPopoverIsOpen] = useState(false)

  const uniqueId = crypto.randomUUID()
  const popoverLinkId = `popover-link-${uniqueId}`

  const renderContent = () => {
    if (isEmpty(listItems)) {
      return <div />
    }

    if (listItems.length === 1) {
      return listItems[0]
    }

    return (
      <div className={compact([isNameBold ? styles.textBold : '', classNameLink ?? '']).join(' ')}>
        <Link
          id={popoverLinkId}
          onClick={(e) => {
            setPopoverIsOpen(true)
            e.stopPropagation()
          }}
        >
          {`${linkText ?? ''} (${listItems?.length})`.trim()}
        </Link>
        {createPortal(
          <Popover
            id={`popover-${uniqueId}`}
            opener={popoverLinkId}
            open={popoverIsOpen}
            onAfterClose={() => {
              setPopoverIsOpen(false)
            }}
            headerText={showPopoverHeader ? `${linkText ?? ''} (${listItems?.length})`.trim() : ''}
            className={compact([styles.popover, classNamePopover ?? '']).join(' ')}
            {...popoverProps}
          >
            <List separators={ListSeparators.Inner} mode={ListMode.None}>
              {listItems.map((item, index) => (
                <CustomListItem key={index}>{item}</CustomListItem>
              ))}
            </List>
          </Popover>,
          document.body,
        )}
      </div>
    )
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading || isFetching}
      isError={isError}
      error={errorToDisplay ?? <ErrorDataUnavailableInCell />}
      renderContent={renderContent}
    />
  )
}

PopoverCell.propTypes = propTypes

export default PopoverCell
