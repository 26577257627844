import { ObjectPageSubSection, ObjectPageSection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import DecisionPaperPdfExportSectionHeader from 'components/domains/business-events-and-tasks/decision-paper/pdf-export/DecisionPaperPdfExportSectionHeader'
import DecisionPaperSubsectionAssessmentPdfWrapper from 'components/domains/business-events-and-tasks/decision-paper/pdf-export/DecisionPaperSubsectionAssessmentPdfWrapper'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import TileTypeWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/generic/TileTypeWrapper'
import styles from 'routes/business-events-and-tasks/decision-paper/pdf-export/DecisionPaperPdfExportContent.module.css'
import {
  hiddenTilesSelector,
  tilesSelector,
} from 'routes/business-events-and-tasks/decision-paper/selectors/decisionPaperSelectors'

const renderPageBreak = ({ keyPrefix }) => (
  <div key={`${keyPrefix}-page-break`} className={styles.pageBreak} />
)

const mapTiles = (
  keyPrefix,
  tile,
  index,
  currentVersion,
  lastAvailableVersion,
  synchronizationStatus,
) => (
  <div key={`${keyPrefix}-${index}-wrapper`}>
    {tile.code === availableAutomaticTiles.financedAssetArea && renderPageBreak({ keyPrefix })}
    <TileTypeWrapper
      key={`${keyPrefix}-${index}`}
      tile={{ ...tile, isPdfView: true }}
      currentVersion={currentVersion}
      lastAvailableVersion={lastAvailableVersion}
      showConfirmationForLinks={false}
      isEditableInMinorVersion={false}
      synchronizationStatus={synchronizationStatus}
    />
  </div>
)

const mapSubSections = (
  sectionId,
  currentVersion,
  {
    name: subsectionName,
    code: subsectionCode,
    tiles: subsectionTiles,
    id: subsectionId,
    hideAssessment,
  },
  lastAvailableVersion,
  isTileNotHidden,
  synchronizationStatus,
) => {
  const subsectionCalculatedId = `${sectionId}-subsection-${subsectionCode}`
  const mappedSubsectionTiles = subsectionTiles
    .filter(isTileNotHidden)
    .map((tile, index) =>
      mapTiles(
        `subsection-${subsectionCode}`,
        tile,
        index,
        currentVersion,
        lastAvailableVersion,
        synchronizationStatus,
      ),
    )
  return (
    <div key={`${subsectionCalculatedId}-wrapper`}>
      <ObjectPageSubSection
        key={subsectionCalculatedId}
        id={subsectionCalculatedId}
        titleText={subsectionName}
      >
        {mappedSubsectionTiles}
        {!hideAssessment && (
          <DecisionPaperSubsectionAssessmentPdfWrapper
            key={`${subsectionCalculatedId}-assessment`}
            tileId={`${subsectionCalculatedId}-assessment`}
            version={currentVersion}
            subsectionId={subsectionId}
            isPdfView={true}
          />
        )}
      </ObjectPageSubSection>
      {renderPageBreak({ keyPrefix: subsectionCalculatedId })}
    </div>
  )
}

const mapSections = (
  currentVersion,
  { name: sectionName, code: sectionCode, tiles: sectionTiles, subsections },
  lastAvailableVersion,
  isTileNotHidden,
  synchronizationStatus,
) => {
  const sectionId = `section-${sectionCode}`
  const mappedSectionTiles = sectionTiles
    .filter(isTileNotHidden)
    .map((tile, index) =>
      mapTiles(sectionId, tile, index, currentVersion, lastAvailableVersion, synchronizationStatus),
    )
  const mappedSubsections = subsections
    .filter(({ tiles: subsectionTiles }) => subsectionTiles.some(isTileNotHidden))
    .map((subsection) =>
      mapSubSections(
        sectionId,
        currentVersion,
        subsection,
        lastAvailableVersion,
        isTileNotHidden,
        synchronizationStatus,
      ),
    )
  return (
    <ObjectPageSection key={sectionId} id={sectionId} titleText={sectionName} hideTitleText={true}>
      <DecisionPaperPdfExportSectionHeader key={`${sectionId}-header`} titleText={sectionName} />
      {mappedSectionTiles}
      {mappedSubsections}
    </ObjectPageSection>
  )
}

const DecisionPaperPdfExportContent = ({ decisionPaper }) => {
  const tiles = useSelector(tilesSelector)
  const tileHiddenState = useSelector(hiddenTilesSelector)
  const decisionPaperSynchronizationStatus = decisionPaper?.synchronizationStatus

  const hiddenTileIds = useMemo(
    () => [
      ...Object.keys(tileHiddenState).filter((tileId) => tileHiddenState[tileId]?.isHidden),
      ...Object.keys(tiles).filter((tileId) => tiles[tileId]?.data?.isHidden),
    ],
    [tiles, tileHiddenState],
  )

  const lastAvailableVersion =
    decisionPaper.availableVersions[decisionPaper.availableVersions.length - 1].version
  const isTileNotHidden = ({ id }) => !hiddenTileIds?.includes(id)
  const sectionsWithShownSubSectionsAndTiles = decisionPaper.sections.filter(
    ({ subsections, tiles }) =>
      tiles.some(isTileNotHidden) ||
      subsections.some(({ tiles: subsectionTiles }) => subsectionTiles.some(isTileNotHidden)),
  )
  return (
    <>
      {sectionsWithShownSubSectionsAndTiles.map((section) =>
        mapSections(
          decisionPaper.currentVersion,
          section,
          lastAvailableVersion,
          isTileNotHidden,
          decisionPaperSynchronizationStatus,
        ),
      )}
    </>
  )
}

DecisionPaperPdfExportContent.propTypes = {
  decisionPaper: PropTypes.shape({
    availableVersions: PropTypes.arrayOf(
      PropTypes.shape({
        version: PropTypes.string.isRequired,
      }),
    ),
    currentVersion: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        subsections: PropTypes.arrayOf(
          PropTypes.shape({
            tiles: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string.isRequired,
              }),
            ),
          }),
        ),
        tiles: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
        ),
      }),
    ),
    synchronizationStatus: PropTypes.string,
  }),
}

export default DecisionPaperPdfExportContent
