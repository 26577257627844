import PropTypes from 'prop-types'
import ConditionAssigneeDisplay from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/assignee/ConditionAssigneeDisplay'

const ConditionsTableAssigneeCell = ({ value: { assignee } }) => (
  <ConditionAssigneeDisplay assignee={assignee} />
)

ConditionsTableAssigneeCell.propTypes = {
  value: PropTypes.shape({
    assignee: PropTypes.string,
  }).isRequired,
}

export default ConditionsTableAssigneeCell
