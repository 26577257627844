import { DatePicker, Input } from '@fioneer/ui5-webcomponents-react'
import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperTileListEditItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListEditItem'
import DecisionPaperTileListItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListItem'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-parameters/FactSheetValuationParameters.module.css'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-parameters/shared/i18n/useI18n'

const valuationParameters = {
  valuationId: 'valuationId',
  valuer: 'valuer',
  inspectionDate: 'inspectionDate',
  ownership: 'ownership',
}

const valuationParametersTranslationKeys = {
  valuationId: 'valuation-id',
  valuer: 'valuer',
  inspectionDate: 'inspection-date',
  ownership: 'ownership',
}

const PLACEHOLDER = '-'

const FactSheetValuationParameters = ({
  tileId,
  currentContent,
  onChange,
  isEditMode,
  isPdfView,
  setIsSaveEnabled,
  setHasContentChanges,
}) => {
  const { format, parse, localePattern } = useShortDateFormatter()
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.fact-sheet-valuation-parameters',
  })
  const tileState = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  useEffect(() => {
    setHasContentChanges(!isEqual(tileState?.data?.data, currentContent) && !isNil(currentContent))
  }, [currentContent, setHasContentChanges, tileState?.data?.data])

  const onInputChange = useCallback(
    (e, valuationParameter) => {
      onChange({ ...currentContent, [valuationParameter]: e.target.value })
      setIsSaveEnabled(true)
    },
    [currentContent, onChange, setIsSaveEnabled],
  )

  const onDatePickerChange = useCallback(
    (e, valuationParameter) => {
      const isoString = parse(e.target.value, localePattern)
      onChange({ ...currentContent, [valuationParameter]: isoString })
      setIsSaveEnabled(true)
    },
    [currentContent, localePattern, onChange, parse, setIsSaveEnabled],
  )

  const listEntries = useMemo(
    () =>
      Object.values(valuationParameters).map((key) => ({
        label: t(valuationParametersTranslationKeys[key]),
        editValue:
          key !== valuationParameters.inspectionDate ? (
            <Input value={currentContent?.[key]} onChange={(e) => onInputChange(e, key)} />
          ) : (
            <DatePicker
              value={currentContent?.[key]}
              className={styles.datePicker}
              onChange={(e) => onDatePickerChange(e, key)}
              formatPattern={localePattern}
            />
          ),
        viewValue:
          key !== valuationParameters.inspectionDate
            ? currentContent?.[key] || PLACEHOLDER
            : format(currentContent?.[key]) || PLACEHOLDER,
      })),
    [currentContent, format, localePattern, onDatePickerChange, onInputChange, t],
  )
  return listEntries.map((element, index) => {
    if (isEditMode && !isPdfView) {
      return (
        <DecisionPaperTileListEditItem
          key={`valuation-parameter-edit-${index}`}
          label={element.label}
          node={element.editValue}
          isFirstItem={!index}
        />
      )
    }
    return (
      <DecisionPaperTileListItem
        key={`valuation-parameter-view-${index}`}
        label={element.label}
        value={element.viewValue}
        isFirstItem={!index}
      />
    )
  })
}

FactSheetValuationParameters.propTypes = {
  tileId: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  currentContent: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  setIsSaveEnabled: PropTypes.func.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
}
export default FactSheetValuationParameters
