import { LatLng } from 'leaflet'

const polygonKeyword = 'POLYGON'
const multiPolygonKeyword = 'MULTIPOLYGON'

const removeSpacesAndSemicolons = (text) => text.split(/;| /)
const trimStringButCoordinates = (text) => text.replace(/[^0-9.-]/g, '')

const stringIncludesSpecificAmountOfCharacter = (text, character, amount) =>
  text.split(character).length - 1 === amount

const stringIncludesHigherAmountOfCharacter = (text, character, amount) =>
  text.split(character).length - 1 >= amount

const isSimplePolygon = (text) =>
  text.substring(0, polygonKeyword.length) === polygonKeyword &&
  stringIncludesSpecificAmountOfCharacter(text, '(', 2) &&
  stringIncludesSpecificAmountOfCharacter(text, ')', 2)

const isPolygonWithHoles = (text) =>
  text.substring(0, polygonKeyword.length) === polygonKeyword &&
  stringIncludesHigherAmountOfCharacter(text, '(', 2) &&
  stringIncludesHigherAmountOfCharacter(text, ')', 2)

const isMultiPolygon = (text) =>
  text.substring(0, multiPolygonKeyword.length) === multiPolygonKeyword

const createSimplePolygonFromWKT = (text) => {
  const rawData = removeSpacesAndSemicolons(text)
  const polygon = [[[]]]

  for (let i = 1; i < rawData.length; i += 2) {
    const lng = trimStringButCoordinates(rawData[i])
    const lat = trimStringButCoordinates(rawData[i + 1])
    const latLng = new LatLng(lat, lng)
    polygon[0][0].push(latLng)
  }
  return polygon
}

const splitTextBy = (text, splitString) => text.split(splitString)

const createPolygonWithHolesFromWKT = (text) => {
  const dividedRingAndHolesArray = splitTextBy(text, ')')

  const polygon = [[]]

  for (let ringIndex = 0; ringIndex < dividedRingAndHolesArray.length - 2; ++ringIndex) {
    const ringPolygon = []
    const coordinatesData = removeSpacesAndSemicolons(dividedRingAndHolesArray[ringIndex])

    for (let i = 1; i < coordinatesData.length; i += 2) {
      const lng = trimStringButCoordinates(coordinatesData[i])
      const lat = trimStringButCoordinates(coordinatesData[i + 1])
      const latLng = new LatLng(lat, lng)
      ringPolygon.push(latLng)
    }
    polygon[0].push(ringPolygon)
  }
  return polygon
}

const createMultiPolygonFromWKT = (text) => {
  const dividedPolygonArray = splitTextBy(text, '))')
  const polygonCoordinatesArray = dividedPolygonArray.map((polygon) => splitTextBy(polygon, ')'))

  const polygons = []

  for (let polygonIndex = 0; polygonIndex < polygonCoordinatesArray.length - 1; ++polygonIndex) {
    const polygon = []

    polygonCoordinatesArray[polygonIndex].forEach((element) => {
      const singleElementCoordinates = []
      const coordinatesData = removeSpacesAndSemicolons(element)

      for (let i = 1; i < coordinatesData.length; i += 2) {
        const lng = trimStringButCoordinates(coordinatesData[i])
        const lat = trimStringButCoordinates(coordinatesData[i + 1])
        const latLng = new LatLng(lat, lng)
        singleElementCoordinates.push(latLng)
      }
      polygon.push(singleElementCoordinates)
    })

    polygons.push(polygon)
  }
  return polygons
}

/* though the name of the method suggests the file must have the WKT format,
      this way of parsing works for all .txt files, that do not have any numbers or dots that
      do not belong to the LatLng.
      Example contents: https://en.wikipedia.org/wiki/Well-known_text_representation_of_geometry */
export const createPolygonsFromWKT = (text) => {
  if (isSimplePolygon(text)) return createSimplePolygonFromWKT(text)
  if (isPolygonWithHoles(text)) return createPolygonWithHolesFromWKT(text)
  if (isMultiPolygon(text)) return createMultiPolygonFromWKT(text)
  throw new Error('Geometry type not supported')
}
