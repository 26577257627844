import { useQuery } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useMultiPropertyViewByPropertyUuids = (propertyUuids, options = {}) => {
  const { post } = useAccessTokenRequest()
  const result = useQuery({
    queryKey: ['multiPropertyView', 'propertyUuids', ...propertyUuids],
    refetchOnWindowFocus: false,
    queryFn: () =>
      post({
        path: `/properties/multi-property-view`,
        body: { property_uuids: propertyUuids },
        enabled: false,
      }),
    ...options,
    enabled: !isNil(options.enabled)
      ? options.enabled && !isEmpty(propertyUuids)
      : !isEmpty(propertyUuids),
  })

  return useCamelizedResponse(result)
}
