import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useCashflowScenarioResultsById = ({ dealUuid, cashflowId, datasetPeriod }, options = {}) => {
  const queryParams = new URLSearchParams({ dataset_period: datasetPeriod })
  return useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/cashflow-scenarios/${cashflowId}/cashflow?${queryParams.toString()}`,
      translated: true,
      useCache: true,
      keys: [
        'deals',
        dealUuid,
        'cashflow-scenarios',
        cashflowId,
        'cashflow',
        queryParams.toString(),
      ],
      options,
    }),
  )
}

export default useCashflowScenarioResultsById
