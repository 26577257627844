import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import { riskIndicatorDetailsSchema } from 'hooks/services/risk-monitoring/riskIndicatorSchema'

/**
 * @param {string} [businessPartnerId]
 * @param {string} [groupId]
 */
const useDetailedRiskIndicatorsByGroupId = (businessPartnerId, groupId) => {
  const keys = [
    'risk-monitoring',
    'business-partners',
    businessPartnerId,
    'risk-indicator-groups',
    groupId,
    'detailed-indicators',
  ]

  const result = useRequest({
    path: `/risk-monitoring/business-partners/${businessPartnerId}/risk-indicator-groups/${groupId}/risk-indicators`,
    options: { enabled: !!businessPartnerId && !!groupId },
    keys,
  })

  const parsed = useMemo(
    () => riskIndicatorDetailsSchema.optional().safeParse(camelize(result.data)),
    [result.data],
  )

  if (!parsed.success) {
    return { ...result, data: undefined, isError: true, error: parsed.error }
  }

  return { ...result, data: parsed.data }
}

export default useDetailedRiskIndicatorsByGroupId
