import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import orderBy from 'lodash.orderby'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { ReferenceEntityType } from 'components/ui/data/ReferenceEntityType'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import paths from 'routes/paths'

const statusToOrderValue = {
  ACTIVE: 1,
  POSTPONED: 2,
  PLANNED: 3,
  CLOSED: 4,
}

const useMonitoringItemsForDealTableData = ({ covenantMonitoringItems }) => {
  const { pathname } = useLocation()

  return useMemo(() => {
    const tableData = covenantMonitoringItems.map((monitoringItem) => {
      const covenantCheckInformation = {}

      switch (monitoringItem?.linkedCovenantCheck?.monitoringReferenceEntity?.type) {
        case ReferenceEntityType.Deal:
          covenantCheckInformation.link = `${pathname}/${dealDetailPaths.covenantChecks}/${monitoringItem?.linkedCovenantCheck?.covenantCheckUuid}`
          covenantCheckInformation.openInNewTab = false
          break
        case ReferenceEntityType.BusinessPartner:
          covenantCheckInformation.link = `/${paths.businessPartners}/${monitoringItem?.linkedCovenantCheck?.monitoringReferenceEntity?.id}/covenants/covenant-checks/${monitoringItem?.linkedCovenantCheck?.covenantCheckUuid}`
          covenantCheckInformation.openInNewTab = true
          break
      }
      return {
        monitoringItem: {
          id: monitoringItem?.covenantMonitoringItemUuid,
          name: monitoringItem?.name,
          link: undefined,
        },
        covenantCheck: {
          nameWithId: `${monitoringItem?.linkedCovenantCheck?.covenantCheckName}: ${monitoringItem?.linkedCovenantCheck?.covenantCheckUuid}`,
          id: monitoringItem?.linkedCovenantCheck?.covenantCheckUuid,
          name: monitoringItem?.linkedCovenantCheck?.covenantCheckName,
          link: covenantCheckInformation.link,
          openInNewTab: covenantCheckInformation.openInNewTab,
        },
        status: {
          value: monitoringItem?.status,
          order: statusToOrderValue[monitoringItem?.status],
        },
        covenant: {
          nameWithId: `${monitoringItem?.covenant?.name}: ${monitoringItem?.covenant?.covenantUuid}`,
          id: monitoringItem?.covenant?.covenantUuid,
          name: monitoringItem?.covenant?.name,
          link: `${pathname}/${monitoringItem?.covenant?.covenantUuid}`,
        },
        targetDeliveryDate: monitoringItem?.dueDate?.targetDeliveryDate,
        softLimit: monitoringItem?.softLimit,
        hardLimit: monitoringItem?.hardLimit,
        result: monitoringItem?.result,
        breach: {
          value: monitoringItem?.breach,
          resultOfBreach: monitoringItem?.resultOfBreach?.name,
        },
        highlight:
          monitoringItem?.breach && monitoringItem?.breach !== 'NO'
            ? ValueState.Warning
            : undefined,
      }
    })
    return orderBy(tableData, ['status.order', 'targetDeliveryDate'], ['asc', 'asc'])
  }, [covenantMonitoringItems, pathname])
}

export default useMonitoringItemsForDealTableData
