import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DealCashflowChartWrapper from 'components/domains/deals/deal-cashflow/result-charts/chart-components/DealCashflowChartWrapper'
import { DealCashflowLineChart } from 'components/domains/deals/deal-cashflow/result-charts/chart-components/DealCashflowLineChart'
import useGetMaturityData from 'components/domains/deals/deal-cashflow/result-charts/useGetMaturityData'

export const DEAL_CASHFLOW_VACANCY_CONFIG = [
  {
    type: 'line',
    legend: 'legend.actual',
    legendType: 'plainline',
    color: '#2531D4',
    key: 'value',
  },
]

export const DEAL_CASHFLOW_FIXED_VACANCY_KPI = 'VR_EOP'

export const DealCashflowVacancyCard = ({
  isLoading,
  isError,
  calculatedKpis,
  isCard = true,
  metaData,
  showTitle,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.cashflow.financial-ratios',
  })
  const [selectedCashflowKpi, setSelectedCashflowKpi] = useState()

  const selectedData = calculatedKpis?.find(
    (data) => data.category === null && data.cashflowKpi.code === DEAL_CASHFLOW_FIXED_VACANCY_KPI,
  )
  const { maturityData = {} } = useGetMaturityData(selectedData)

  const headerProps = {
    selectedData,
    isLoading,
    isError,
    maturityData,
    setSelectedCashflowKpi,
    selectedCashflowKpi,
    isFixed: true,
    title: t('title.vacancy'),
    subtitlePrefix: t('subtitle.vacancy-rate'),
    showTitle: showTitle,
  }

  return (
    <DealCashflowChartWrapper
      isCard={isCard}
      isLoading={isLoading}
      isError={isError}
      isSelectedData={!!selectedData}
      isMetaData={!!metaData}
      headerProps={headerProps}
    >
      <DealCashflowLineChart
        data={selectedData}
        maturityData={maturityData}
        config={DEAL_CASHFLOW_VACANCY_CONFIG}
      />
    </DealCashflowChartWrapper>
  )
}

DealCashflowVacancyCard.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  calculatedKpis: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      cashflowKpi: PropTypes.shape({
        code: PropTypes.string,
      }),
    }),
  ),
  isCard: PropTypes.bool,
  metaData: PropTypes.object,
  showTitle: PropTypes.bool,
}
