import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import { riskIndicatorDescriptionSchema } from 'hooks/services/risk-monitoring/riskIndicatorSchema'

/**
 * @param {string} businessPartnerId
 * @param {string} groupId
 */
const useRiskIndicatorsDescription = ({ businessPartnerId, groupId }) => {
  const keys = [
    'risk-monitoring',
    'business-partners',
    businessPartnerId,
    'risk-indicator-groups',
    groupId,
    'descriptions',
  ]

  const result = useRequest({
    path: `/risk-monitoring/business-partners/${businessPartnerId}/risk-indicator-groups/${groupId}/descriptions`,
    options: { enabled: !!businessPartnerId && !!groupId },
    keys,
  })

  const parsed = useMemo(
    () => riskIndicatorDescriptionSchema.optional().safeParse(camelize(result.data)),
    [result.data],
  )

  if (!parsed.success) {
    return { ...result, data: undefined, isError: true, error: parsed.error }
  }

  return { ...result, data: parsed.data }
}

export default useRiskIndicatorsDescription
