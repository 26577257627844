import {
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import { isEventClosed, getObjectStatusForEventStatus } from 'api/events/status'
import BusinessObjectLink, {
  BusinessObjectLinkCustomRender,
} from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import DecisionProcessEmptyPage from 'components/domains/business-events-and-tasks/decision-process/DecisionProcessEmptyPage'
import DecisionProcessOverviewPage from 'components/domains/business-events-and-tasks/decision-process/DecisionProcessOverviewPage'
import DecisionProcessALCMessageStripWrapper from 'components/domains/business-events-and-tasks/decision-process/alc/DecisionProcessAlcMessageStripWrapper'
import DecisionProcessSelectSetupButton from 'components/domains/business-events-and-tasks/decision-process/setups/DecisionProcessSelectSetupButton'
import DecisionProcessCreateStageButton from 'components/domains/business-events-and-tasks/decision-process/stages/DecisionProcessCreateStageButton'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Header from 'components/ui/page/Header'
import TabPage from 'components/ui/page/TabPage'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useDecisionStages from 'hooks/services/business-events-and-tasks/decision-process/stages/useDecisionStages'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import styles from 'routes/business-events-and-tasks/decision-process/DecisionProcess.module.css'
import paths from 'routes/paths'

const sorting = { sortBy: 'decision_date', orderBy: 'asc' }
const DecisionProcess = () => {
  const { t: tNoPrefix } = useTranslation()

  const {
    event: {
      id: eventId,
      info: { name: eventName },
      entityRef: { entityId, entityType },
      status: eventStatus,
    },
    allowedOperations,
  } = useContext(BusinessEventsAndTasksContext)

  const { isLoading, isError, data: decisionStageData } = useDecisionStages({ eventId }, sorting)

  const { translationKey: statusTranslationKey, objectStatus } = useMemo(
    () => getObjectStatusForEventStatus(eventStatus),
    [eventStatus],
  )

  const hasDecisionStageReadPermissions = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.readDecisionStage),
    [allowedOperations],
  )
  const hasDecisionStageCreatePermissions = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.createDecisionStage),
    [allowedOperations],
  )

  const eventStatusForHeader = useMemo(
    () => ({
      text: tNoPrefix(statusTranslationKey),
      valueState: objectStatus,
    }),
    [tNoPrefix, objectStatus, statusTranslationKey],
  )

  const breadcrumbs = useMemo(
    () => [
      {
        text: tNoPrefix('breadcrumbs.business-events-and-tasks'),
        href: `/${paths.businessEventsAndTasks}`,
      },
      {
        text: eventName,
        href: `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}`,
      },
      {
        text: tNoPrefix('breadcrumbs.business-events-and-tasks.decision-process'),
      },
    ],
    [eventName, eventId, tNoPrefix],
  )

  const headerActions = useMemo(
    () =>
      hasDecisionStageCreatePermissions && !isEventClosed(eventStatus) ? (
        <>
          <DecisionProcessSelectSetupButton />
          <DecisionProcessCreateStageButton />
        </>
      ) : (
        <></>
      ),
    [hasDecisionStageCreatePermissions, eventStatus],
  )

  const subtitle = useMemo(
    () => (
      <BusinessObjectLink
        entityId={entityId}
        entityType={entityType}
        customRender={BusinessObjectLinkCustomRender.clipboard}
      />
    ),
    [entityId, entityType],
  )

  const renderContent = useCallback(() => {
    const { decisionStages } = decisionStageData
    const isEmpty = decisionStages.length === 0
    const header = (
      <Header
        title={eventName}
        breadcrumbs={breadcrumbs}
        actions={isEmpty ? headerActions : undefined}
        status={eventStatusForHeader}
        subtitle={subtitle}
        className={styles.pageHeader}
      />
    )
    return (
      <TabPage headerTitle={header} isEmpty={true}>
        <CWPLayout>
          <DecisionProcessALCMessageStripWrapper />
          <CWPLayout.Full>
            {isEmpty ? (
              <DecisionProcessEmptyPage />
            ) : (
              <DecisionProcessOverviewPage decisionStages={decisionStages} />
            )}
          </CWPLayout.Full>
        </CWPLayout>
      </TabPage>
    )
  }, [breadcrumbs, decisionStageData, eventName, eventStatusForHeader, subtitle, headerActions])

  if (!hasDecisionStageReadPermissions) {
    return (
      <IllustratedMessage
        size={IllustrationMessageSize.Spot}
        name={IllustrationMessageType.UnableToLoad}
        titleText={tNoPrefix('app.permission-error.title')}
        subtitleText={tNoPrefix('app.permission-error.subtitle')}
      />
    )
  }

  return (
    <LoadingStateWrapper isLoading={isLoading} isError={isError} renderContent={renderContent} />
  )
}

export default DecisionProcess
