import PropTypes from 'prop-types'
import { valuationRelevance } from 'api/property/valuation/valuationRequests'
import BooleanCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/BooleanCell'

const PropertyValuationResultsRegulatoryRelevantCell = ({ regulatoryRelevance }) => {
  const isRegulatoryRelevant = regulatoryRelevance === valuationRelevance.relevant

  return <BooleanCell value={isRegulatoryRelevant} />
}

PropertyValuationResultsRegulatoryRelevantCell.propTypes = {
  regulatoryRelevance: PropTypes.string,
}

export default PropertyValuationResultsRegulatoryRelevantCell
