const addConditions = (state, { payload: conditions }) => {
  const newConditions = conditions.map((condition) => ({
    name: condition.name ?? '',
    type: condition.type,
    category: condition.category,
    refNumber: condition.refNumber,
    approvalLevel: condition.approvalLevel,
    description: condition.description,
    expanded: condition?.expanded ?? false,
    possibleDocumentTypes: [],
    references: condition.references,
    requirements:
      condition.requirements?.map((requirement) => ({
        name: requirement.info?.name ?? '',
        documentType: requirement.info?.documentType ?? '',
        category: requirement.info?.category,
        refNumber: requirement.info?.refNumber,
        description: requirement.info?.description,
      })) ?? [],
  }))
  state.conditions = [...newConditions, ...state.conditions]
}

export default addConditions
