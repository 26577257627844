import { isConflictError, isInternalServerError, isMissingPermissionError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const RETRIES = 3

const useAuthorityLevelCalculatorAuthorityLevel = ({ eventId }, options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/pbb-custom-service/business-events/${eventId}/authority-level`,
      translated: true,
      keys: ['business-events', eventId, 'authority-level'],
      options: {
        retry: (failureCount, error) =>
          failureCount < RETRIES &&
          !isMissingPermissionError(error) &&
          !isInternalServerError(error) &&
          !isConflictError(error),
        ...options,
      },
    }),
  )

export default useAuthorityLevelCalculatorAuthorityLevel
