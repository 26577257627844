import isNil from 'lodash.isnil'

export const countDocuments = (nodes) => {
  if (!nodes?.length) return 0
  return nodes.reduce((acc, node) => {
    const isDocument = isNil(node.subFolders)
    if (isDocument) return acc + 1
    if (!node.subFolders.length) return acc
    return acc + countDocuments(node.subFolders)
  }, 0)
}
