import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import styles from 'components/ui/card/ClickableCardHeader.module.css'
import Link from 'components/ui/link/Link'

const propTypes = {
  titleText: PropTypes.string.isRequired,
  href: PropTypes.string,
  target: PropTypes.string,
  relative: PropTypes.oneOf(['path', 'route']),
  subtitle: PropTypes.node,
  actions: PropTypes.node,
}

/**
 * A simple lookalike for the UI5 `CardHeader`,
 * supporting link behavior (incl. Ctrl- / Cmd-Clicking)
 *
 * Created for the overview pages to link to a L2 page
 * @param {import('prop-types').InferProps<typeof propTypes>} props
 */
const ClickableCardHeader = ({ titleText, href, target, relative, subtitle, actions }) => {
  /** @type {import('react').MutableRefObject<HTMLElement | undefined>} */
  const ref = useRef()

  // HINT: set a style in the Shadow DOM
  useEffect(() => {
    const aElement = ref?.current?.shadowRoot?.querySelector('.ui5-link-root')
    if (aElement) {
      aElement.style.width = '100%'
    }
  }, [href]) // set the style whenever the link is re-rendered

  const content = (
    <div className={styles.cardHeader}>
      <div className={styles.firstRow}>
        <span className={styles.title}>{titleText}</span>
        <div>{actions}</div>
      </div>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </div>
  )

  if (!href) return content

  return (
    <Link href={href} target={target} relative={relative} ref={ref} className={styles.link}>
      {content}
    </Link>
  )
}

ClickableCardHeader.propTypes = propTypes

export default ClickableCardHeader
