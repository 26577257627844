import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Icon,
  Label,
  ObjectStatus,
  Popover,
  PopoverPlacementType,
  Title,
} from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import PropTypes from 'prop-types'
import { useId, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/page/StatusDetailsPopover.module.css'

const propTypes = {
  listOfStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string,
      valueState: PropTypes.oneOf(Object.values(ValueState)),
    }).isRequired,
  ),
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const StatusDetailsPopover = ({ listOfStatuses }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ui.page.status-details-popover',
  })
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const uniqueId = useId()
  const id = `status-popover-${uniqueId}`

  return (
    <FlexBox alignItems={FlexBoxAlignItems.Center} className={styles.popoverContainer}>
      <Icon
        id={id}
        name={'information'}
        className={isPopoverOpen ? styles.iconClicked : styles.icon}
        onClick={() => setIsPopoverOpen(true)}
      />
      {createPortal(
        <Popover
          opener={id}
          className={styles.popover}
          onAfterClose={() => setIsPopoverOpen(false)}
          header={
            <FlexBox
              alignItems={FlexBoxAlignItems.Center}
              justifyContent={FlexBoxJustifyContent.Start}
              direction={FlexBoxDirection.Row}
              className={styles.popoverHeader}
            >
              <Title className={styles.popoverTitle}>{t('title')}</Title>
            </FlexBox>
          }
          placementType={PopoverPlacementType.Right}
          open={isPopoverOpen}
        >
          <div className={styles.statusContainer}>
            {listOfStatuses?.map(({ label, valueState, text }, index) => (
              <FlexBox
                key={index}
                direction={FlexBoxDirection.Column}
                className={styles.statusEntity}
              >
                <Label>{label}</Label>
                <ObjectStatus inverted state={valueState ?? ValueState.Information}>
                  {text}
                </ObjectStatus>
              </FlexBox>
            ))}
          </div>
        </Popover>,
        document.body,
      )}
    </FlexBox>
  )
}

StatusDetailsPopover.propTypes = propTypes

export default StatusDetailsPopover
