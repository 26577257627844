import compact from 'lodash.compact'
import uniq from 'lodash.uniq'
import { useCallback, useMemo } from 'react'
import useMultiPropertyRentRolls from 'hooks/services/properties/useMultiPropertyRentRolls'

const HEADQUARTER_CURRENCY = 'EUR'
const HEADQUARTER_UOM = 'M2'

const usePortfolioUnits = ({
  properties,
  keyDatesWithValidFrom,
  includeCurrentRentRoll = false,
}) => {
  const { responses, isFetching } = useMultiPropertyRentRolls({
    propertyUuids: properties.map((property) => property.uuid),
    keyDatesWithValidFrom,
    includeCurrentRentRoll,
  })

  const combinedRentalUnits = responses.flatMap((response) => response?.data?.rentalUnits ?? [])

  const rentalUnitCurrencies = combinedRentalUnits.flatMap((rentalUnit) => [
    rentalUnit?.rentContractedYear?.currency,
    rentalUnit?.rentCurrentYear?.currency,
  ])
  const rentalUnitAreas = combinedRentalUnits.map((rentalUnit) => rentalUnit?.area?.measurementUnit)

  const getUniqueUnitOrHeadquarter = useCallback(
    (units, isCurrency) => {
      const uniqueUnits = uniq(compact(units))
      const headquarterUnit = isCurrency ? HEADQUARTER_CURRENCY : HEADQUARTER_UOM
      if (!isFetching) {
        return uniqueUnits.length === 1 ? uniqueUnits[0] : headquarterUnit
      } else {
        return null
      }
    },
    [isFetching],
  )

  const currency = getUniqueUnitOrHeadquarter(rentalUnitCurrencies, true)
  const uom = getUniqueUnitOrHeadquarter(rentalUnitAreas, false)

  return useMemo(
    () => ({
      isFetching,
      data: { currency, uom },
    }),
    [isFetching, uom, currency],
  )
}

export default usePortfolioUnits
