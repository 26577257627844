import { FlexBox, FlexBoxAlignItems, Label } from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ValuationRequestBusinessEventLoader from 'components/domains/properties/valuation/table/table-cells/display/ValuationRequestBusinessEventLoader'
import ValuationsRequestsTableValuerDisplay from 'components/domains/properties/valuation/table/table-cells/display/ValuationRequestsTableValuerDisplay'

const ValuationRequestBusinessEventDisplay = ({
  businessEvent,
  valuer,
  showValuer,
  valuationRequestId,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.valuation.requests.table.table-cells.label',
  })
  const renderedBusinessEvent = useMemo(
    () =>
      businessEvent ? (
        <ValuationRequestBusinessEventLoader
          eventId={businessEvent}
          valuationRequestId={valuationRequestId}
        />
      ) : (
        '-'
      ),
    [businessEvent, valuationRequestId],
  )

  if (showValuer) {
    return (
      <>
        {renderedBusinessEvent}
        <FlexBox alignItems={FlexBoxAlignItems.Center}>
          <Label showColon>{t('valuer')}</Label>&nbsp;
          <ValuationsRequestsTableValuerDisplay
            valuer={valuer}
            valuationRequestId={valuationRequestId}
          />
        </FlexBox>
      </>
    )
  }
  return renderedBusinessEvent
}

ValuationRequestBusinessEventDisplay.propTypes = {
  showValuer: PropType.bool.isRequired,
  businessEvent: PropType.string,
  valuer: PropType.string,
  valuationRequestId: PropType.string.isRequired,
}

export default ValuationRequestBusinessEventDisplay
