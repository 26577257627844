import { ConfigContext } from '@fioneer/cl-cwp-pbb-frontend-shared/api'
import {
  FlexBox,
  FlexBoxDirection,
  Input,
  Label,
  Text,
  ValueState,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { multiPropertyValuations } from 'api/property/valuation/valuations'
import styles from 'components/domains/deals/collaterals/details/ReceivablesCells.module.css'
import { FINANCIAL_PRODUCT_BUSINESS_TYPES } from 'components/domains/deals/collaterals/details/receivablesConstants'
import Entity from 'components/ui/data/Entity'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import NumberInput from 'components/ui/input/NumberInput'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import EntityCell from 'components/ui/tables/cells/EntityCell'
import MoneyCell from 'components/ui/tables/cells/MoneyCell'
import StatusCell from 'components/ui/tables/cells/StatusCell'
import TextCell from 'components/ui/tables/cells/TextCell'
import { useCustomizableCurrencyFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'
import useBankCustomerAccountsAmounts from 'hooks/services/deals/financing/bank-customer-accounts/useBankCustomerAccountsAmounts'
import useBankCustomerAccountsByBankAreaAndAccountNumber from 'hooks/services/deals/financing/bank-customer-accounts/useBankCustomerAccountsByBankAreaAndAccountNumber'
import useExternalTrancheDetails from 'hooks/services/deals/financing/useExternalTrancheDetails'
import useTranche from 'hooks/services/deals/financing/useTranche'
import useDealMini from 'hooks/services/deals/useDealMini'
import usePropertyUuids from 'hooks/services/properties/usePropertyUuids'
import useMultiPropertyValuations from 'hooks/services/properties/valuations/useMultiPropertyValuations'
import { DealContext } from 'routes/deals/DealContext'
import { renderMoneyColumnCell } from 'routes/deals/collaterals/CollateralAgreementsTableCell'
import useRightById from 'routes/deals/collaterals/assets/useRightById'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'
import paths from 'routes/paths'

/**
 * @param {string} [trancheId]
 * @param {string} [dataSource]
 */
export const processTrancheId = (trancheId, dataSource) => {
  if (trancheId && dataSource === DATA_SOURCES.EXISTING_BUSINESS) {
    const trancheIdParts = trancheId.split('/')

    const isBCA = trancheIdParts.length === 2
    if (isBCA) return trancheIdParts[1]

    const processedTrancheId = trancheIdParts[1]
    // HINT: Remove leading zeros
    return processedTrancheId?.replace(/^0+/, '')
  } else if (trancheId && dataSource === DATA_SOURCES.NEW_BUSINESS) {
    return trancheId
  }
  return null
}

const useProcessedTrancheId = (trancheId, dataSource) =>
  useMemo(() => processTrancheId(trancheId, dataSource), [trancheId, dataSource])

/** @param {string[]} [trancheIds] */
export const useProcessedTrancheIds = (trancheIds = [], specificDataSource) => {
  const [searchParams] = useSearchParams()

  const dataSource = useMemo(
    () => specificDataSource ?? searchParams.get('dataSource'),
    [searchParams, specificDataSource],
  )

  return useMemo(
    () => compact(trancheIds.map((trancheId) => processTrancheId(trancheId, dataSource))),
    [dataSource, trancheIds],
  )
}

export const ErrorLabel = ({ isError }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.collateral-agreements.receivables-table',
  })

  if (!isError) return ''

  return (
    <Label className={styles.errorLabel} wrappingType={WrappingType.Normal}>
      {t('error.data-unavailable')}
    </Label>
  )
}

ErrorLabel.propTypes = {
  isError: PropTypes.bool,
}

const financialProductCellPropTypes = {
  /** Tranche UUID */
  value: PropTypes.string,
  dataSource: PropTypes.string,
  isEdit: PropTypes.bool,
}

export const FinancialProductCell = ({ value, dataSource, isEdit }) => {
  const { deal } = useContext(DealContext) ?? {}

  const processedTrancheId = useProcessedTrancheId(value, dataSource)

  const {
    data: externalTranche,
    isFetching: isExternalTrancheFetching,
    isError: isExternalTrancheError,
  } = useExternalTrancheDetails(processedTrancheId, dataSource)

  const isDealContext = deal?.dealUuid === externalTranche?.dealId
  const dealUuid = isDealContext ? deal?.dealUuid : externalTranche?.dealId

  const {
    data: theRealDeal,
    isFetching: isDealFetching,
    isError: isDealError,
  } = useDealMini(isDealContext ? undefined : dealUuid)

  const dealId = isDealContext ? deal?.dealId : theRealDeal?.dealId

  const {
    data: tranche,
    isFetching: isTrancheFetching,
    isError: isTrancheError,
  } = useTranche(dealUuid, processedTrancheId, dataSource)

  const isError = isTrancheError || isExternalTrancheError || isDealError
  const isLoading = (isTrancheFetching || isExternalTrancheFetching || isDealFetching) && !isError

  const idToDisplay = externalTranche?.externalContractId?.[0] || tranche?.displayId

  if (isEdit) {
    return <Input value={idToDisplay} readonly={true} />
  } else {
    return (
      <SmallLoadingWrapper
        isError={isError}
        isLoading={isLoading}
        error={<ErrorLabel isError={isError} />}
        renderContent={() => (
          <Entity
            name={externalTranche?.trancheName ?? tranche?.trancheName}
            id={idToDisplay}
            link={
              dealId && idToDisplay
                ? `/deals/${dealId}/financing/tranches/${idToDisplay}?dataSource=${dataSource}`
                : undefined
            }
            openInNewTab
          />
        )}
      />
    )
  }
}

FinancialProductCell.propTypes = financialProductCellPropTypes

export const TrancheTypeCell = ({ trancheId, dataSource, isEdit }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.collateral-agreements.receivables-table',
  })
  const TRANCHE_TYPE_DEFAULT = t('tranche-types.tranche')
  const { trancheDetails } = useContext(ConfigContext)
  const processedTrancheId = useProcessedTrancheId(trancheId, dataSource)

  const {
    data: externalTranche,
    isFetching: isExternalTrancheFetching,
    isError: isExternalTrancheError,
  } = useExternalTrancheDetails(processedTrancheId, dataSource)

  const isError = isExternalTrancheError
  const isLoading = isExternalTrancheFetching

  const trancheTypeName = useMemo(() => {
    if (trancheId && !isLoading && !isError) {
      const trancheEntityTypeCode = externalTranche?.entityTypeCode
      const trancheTypeCodesConfig = trancheDetails?.trancheTypeCodes
      if (trancheTypeCodesConfig?.tranche === trancheEntityTypeCode) {
        return t('tranche-types.tranche')
      } else if (trancheTypeCodesConfig?.drawdown === trancheEntityTypeCode) {
        return t('tranche-types.drawdown')
      } else {
        // It's actually also the default of the backend.
        return TRANCHE_TYPE_DEFAULT
      }
    } else {
      return ''
    }
  }, [
    TRANCHE_TYPE_DEFAULT,
    externalTranche?.entityTypeCode,
    isError,
    isLoading,
    t,
    trancheDetails?.trancheTypeCodes,
    trancheId,
  ])

  if (isEdit) {
    return <Input value={trancheTypeName} readonly={true} />
  } else {
    return (
      <SmallLoadingWrapper
        isError={isError}
        isLoading={isLoading}
        error={<ErrorLabel isError={isError} />}
        renderContent={() => <Text>{trancheTypeName}</Text>}
      />
    )
  }
}
TrancheTypeCell.propTypes = {
  /** Tranche UUID */
  trancheId: PropTypes.string,
  /** Flag to display readonly input */
  isEdit: PropTypes.bool,
  dataSource: PropTypes.string,
}

export const BusinessTypeCell = ({ dataSource, isEdit }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.collateral-agreements.receivables-table.business-types',
  })

  const businessType = useMemo(() => {
    if (dataSource === DATA_SOURCES.EXISTING_BUSINESS) {
      return t('eb')
    } else if (dataSource === DATA_SOURCES.NEW_BUSINESS) {
      return t('nb')
    } else {
      return ''
    }
  }, [dataSource, t])

  if (isEdit) {
    return <Input value={businessType} readonly={true} />
  } else {
    return <Text>{businessType}</Text>
  }
}
BusinessTypeCell.propTypes = {
  /** Flag to display readonly input */
  isEdit: PropTypes.bool,
  dataSource: PropTypes.string,
}

export const BankCustomerAccountCell = ({ companyCode, contractNumber, isEdit }) => {
  const {
    data: bankCustomerAccountDetailsData,
    isFetching: isBankCustomerAccountDetailsFetching,
    isError: isBankCustomerAccountDetailsError,
  } = useBankCustomerAccountsByBankAreaAndAccountNumber({
    bankArea: companyCode,
    externalAccountNumber: contractNumber,
  })

  const { dealDisplayId } = bankCustomerAccountDetailsData ?? {}
  const entityLink = !isNil(dealDisplayId)
    ? `/${paths.deals}/${dealDisplayId}/financing/bank-customer-accounts/${contractNumber}`
    : undefined

  if (isEdit) {
    return <Input value={contractNumber} readonly={true} />
  } else {
    return (
      <EntityCell
        name={contractNumber}
        link={entityLink}
        options={{ isNameBold: true, openInNewTab: true }}
        isLoading={isBankCustomerAccountDetailsFetching}
        isError={isBankCustomerAccountDetailsError}
      />
    )
  }
}

BankCustomerAccountCell.propTypes = {
  /** BCA companyCode / bankArea */
  companyCode: PropTypes.string,
  /** BCA contractNumber / externalAccountNumber */
  contractNumber: PropTypes.string,
  isEdit: PropTypes.bool,
}

export const ProductStatusCell = ({ companyCode, contractNumber, businessType, isEdit }) => {
  const trancheId = useMemo(() => {
    if (businessType === FINANCIAL_PRODUCT_BUSINESS_TYPES.EXISTING_BUSINESS && !!contractNumber) {
      return contractNumber
    }
    return null
  }, [businessType, contractNumber])

  const {
    data: externalTranche,
    isFetching: isExternalTrancheFetching,
    isError: isExternalTrancheError,
  } = useExternalTrancheDetails(trancheId, businessType)

  const {
    data: bankCustomerAccountDetailsData,
    isFetching: isBankCustomerAccountDetailsFetching,
    isError: isBankCustomerAccountDetailsError,
  } = useBankCustomerAccountsByBankAreaAndAccountNumber({
    bankArea: companyCode,
    externalAccountNumber: contractNumber,
    options: { enabled: businessType === FINANCIAL_PRODUCT_BUSINESS_TYPES.BANK_CUSTOMER_ACCOUNTS },
  })

  const productStatus =
    businessType === FINANCIAL_PRODUCT_BUSINESS_TYPES.BANK_CUSTOMER_ACCOUNTS
      ? bankCustomerAccountDetailsData?.productStatus?.translation
      : externalTranche?.productStatus?.translation

  const isLoading = isExternalTrancheFetching || isBankCustomerAccountDetailsFetching
  const isError = isExternalTrancheError || isBankCustomerAccountDetailsError

  const renderStatusCellOrInput = () => {
    if (isEdit) {
      return <Input value={productStatus} readonly={true} />
    } else {
      return (
        <StatusCell
          isLoading={isLoading}
          isError={isError}
          statusValue={productStatus}
          statusState={ValueState.Information}
        />
      )
    }
  }

  const renderEmptyDivOrEmptyReadonlyInput = () => {
    if (isEdit) {
      return <Input value={''} readonly={true} />
    } else {
      return <></>
    }
  }

  const isLoadedWithoutErrorButEmpty = !isLoading && !isError && isEmpty(productStatus)
  return isLoadedWithoutErrorButEmpty
    ? renderEmptyDivOrEmptyReadonlyInput()
    : renderStatusCellOrInput()
}

ProductStatusCell.propTypes = {
  /** BCA companyCode / bankArea */
  companyCode: PropTypes.string,
  /** BCA contractNumber / externalAccountNumber */
  contractNumber: PropTypes.string,
  businessType: PropTypes.string,
  isEdit: PropTypes.bool,
}

export const DealCell = ({ trancheId, dataSource, isEdit }) => {
  const processedTrancheId = useProcessedTrancheId(trancheId, dataSource)

  const {
    data: externalTrancheDetails,
    isFetching: isExternalFetching,
    isError: isExternalError,
  } = useExternalTrancheDetails(processedTrancheId, dataSource)

  const {
    data: deal,
    isFetching: isDealFetching,
    isError: isDealError,
  } = useDealMini(externalTrancheDetails?.dealId)

  const isError = isExternalError || isDealError
  const isLoading = (isExternalFetching || isDealFetching) && !isError

  if (isEdit) {
    return <Input value={deal?.name} readonly={true} />
  } else {
    return (
      <SmallLoadingWrapper
        isLoading={isLoading}
        isError={isError}
        error={<ErrorLabel isError={isError} />}
        renderContent={() => (
          <Entity
            name={deal?.name}
            id={deal?.dealId}
            link={`/deals/${deal?.dealId}`}
            openInNewTab
          />
        )}
      />
    )
  }
}

DealCell.propTypes = {
  /** Tranche UUID */
  trancheId: PropTypes.string,
  dataSource: PropTypes.string,
  isEdit: PropTypes.bool,
}

/**
 * @param {object} params
 * @param {string} params.trancheId
 * @param {boolean} params.isEdit
 */
export const DealStatusCell = ({ trancheId, dataSource, isEdit }) => {
  const processedTrancheId = useProcessedTrancheId(trancheId, dataSource)

  const {
    data: externalTrancheDetails,
    isFetching: isExternalFetching,
    isError: isExternalError,
  } = useExternalTrancheDetails(processedTrancheId, dataSource)

  const {
    data: deal,
    isFetching: isDealFetching,
    isError: isDealError,
  } = useDealMini(externalTrancheDetails?.dealId)

  const isError = isExternalError || isDealError
  const isLoading = (isExternalFetching || isDealFetching) && !isError

  const dealStatus = deal?.status ?? ''

  if (isEdit) {
    return <Input value={dealStatus} readonly={true} />
  } else {
    return (
      <StatusCell
        isLoading={isLoading}
        isError={isError}
        statusValue={dealStatus}
        statusState={ValueState.Information}
      />
    )
  }
}

DealStatusCell.propTypes = {
  /** Tranche UUID */
  trancheId: PropTypes.string,
  /** Flag to display readonly input */
  isEdit: PropTypes.bool,
  dataSource: PropTypes.string,
}

export const BankCustomerAccountDealCell = ({
  companyCode,
  contractNumber,
  prefilledDeal,
  isEdit,
}) => {
  const {
    data: bankCustomerAccountDetailsData,
    isFetching: isBankCustomerAccountDetailsFetching,
    isError: isBankCustomerAccountDetailsError,
  } = useBankCustomerAccountsByBankAreaAndAccountNumber({
    bankArea: companyCode,
    externalAccountNumber: contractNumber,
  })

  const { dealUuid } = bankCustomerAccountDetailsData ?? {}
  const {
    data: dealData,
    isFetching: isDealFetching,
    isError: isDealError,
  } = useDealMini(dealUuid) ?? {}

  const deal = useMemo(() => dealData ?? prefilledDeal, [dealData, prefilledDeal])

  const entityLink = !isNil(deal?.dealId) ? `/${paths.deals}/${deal?.dealId}` : undefined

  const dealName = useMemo(() => {
    if (deal?.dealId) {
      return `${deal?.name} (${deal?.dealId})`
    }
  }, [deal])
  if (isEdit) {
    return <Input value={dealName} readonly={true} />
  } else {
    return (
      <EntityCell
        id={dealData?.dealId}
        name={dealData?.name}
        link={entityLink}
        isLoading={isBankCustomerAccountDetailsFetching || isDealFetching}
        isError={isBankCustomerAccountDetailsError || isDealError}
      />
    )
  }
}

BankCustomerAccountDealCell.propTypes = {
  /** BCA companyCode / bankArea */
  companyCode: PropTypes.string,
  /** BCA contractNumber / externalAccountNumber */
  contractNumber: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  prefilledDeal: PropTypes.object,
  isEdit: PropTypes.bool,
}

export const BankCustomerAccountDealStatusCell = ({ companyCode, contractNumber, isEdit }) => {
  const {
    data: bankCustomerAccountDetailsData,
    isFetching: isBankCustomerAccountDetailsFetching,
    isError: isBankCustomerAccountDetailsError,
  } = useBankCustomerAccountsByBankAreaAndAccountNumber({
    bankArea: companyCode,
    externalAccountNumber: contractNumber,
  })

  const { dealUuid } = bankCustomerAccountDetailsData ?? {}
  const {
    data: dealData,
    isFetching: isDealFetching,
    isError: isDealError,
  } = useDealMini(dealUuid) ?? {}

  const dealStatus = dealData?.status ?? ''

  if (isEdit) {
    return <Input value={dealStatus} readonly={true} />
  } else {
    return (
      <StatusCell
        isLoading={isBankCustomerAccountDetailsFetching || isDealFetching}
        isError={isBankCustomerAccountDetailsError || isDealError}
        statusValue={dealStatus}
        statusState={ValueState.Information}
      />
    )
  }
}

BankCustomerAccountDealStatusCell.propTypes = {
  /** BCA companyCode / bankArea */
  companyCode: PropTypes.string,
  /** BCA contractNumber / externalAccountNumber */
  contractNumber: PropTypes.string,
  isEdit: PropTypes.bool,
}

export const BankCustomerAccountDescriptionCell = ({ companyCode, contractNumber, isEdit }) => {
  const {
    data: bankCustomerAccountDetailsData,
    isFetching: isBankCustomerAccountDetailsFetching,
    isError: isBankCustomerAccountDetailsError,
  } = useBankCustomerAccountsByBankAreaAndAccountNumber({
    bankArea: companyCode,
    externalAccountNumber: contractNumber,
  })

  if (isEdit) {
    return <Input value={bankCustomerAccountDetailsData?.accountDescription} readonly={true} />
  } else {
    return (
      <TextCell
        text={bankCustomerAccountDetailsData?.accountDescription}
        isLoading={isBankCustomerAccountDetailsFetching}
        isError={isBankCustomerAccountDetailsError}
      />
    )
  }
}

BankCustomerAccountDescriptionCell.propTypes = {
  /** BCA companyCode / bankArea */
  companyCode: PropTypes.string,
  /** BCA contractNumber / externalAccountNumber */
  contractNumber: PropTypes.string,
  isEdit: PropTypes.bool,
}

const totalExternalCommitmentCellPropTypes = {
  trancheUuid: PropTypes.string,
  dataSource: PropTypes.string,
  isEdit: PropTypes.bool,
}
/** @param {PropTypes.InferProps<typeof totalExternalCommitmentCellPropTypes>} props */
export const TotalExternalCommitmentCell = ({ trancheUuid, dataSource, isEdit }) => {
  const processedTrancheId = useProcessedTrancheId(trancheUuid, dataSource)
  const {
    data: tranche,
    isFetching,
    isError,
  } = useExternalTrancheDetails(processedTrancheId, dataSource)

  const formatMoney = useCustomizableCurrencyFormatter()

  const originalTotalCommitment = tranche?.totalCommitment
  const headquarterTotalCommitment = tranche?.totalCommitmentHeadquarter

  if (isEdit) {
    return (
      <ReadonlyFormattedMoneyInput
        original={originalTotalCommitment}
        headquarter={headquarterTotalCommitment}
      />
    )
  } else {
    return (
      <SmallLoadingWrapper
        isError={isError}
        error={<ErrorDataUnavailableInCell />}
        isLoading={isFetching}
        renderContent={() =>
          renderMoneyColumnCell({
            cell: {
              value: {
                cagValue: {
                  value: tranche?.totalCommitment.amount,
                  currency: tranche?.totalCommitment.currency,
                },
              },
            },
            formatMoney,
          })
        }
      />
    )
  }
}

TotalExternalCommitmentCell.propTypes = totalExternalCommitmentCellPropTypes

export const BankCustomerAccountBookBalanceCell = ({ companyCode, contractNumber, isEdit }) => {
  const {
    data: bankCustomerAccountDetailsData = {},
    isFetching: isBcaDetailsFetching,
    isError: isBcaDetailsError,
  } = useBankCustomerAccountsByBankAreaAndAccountNumber({
    bankArea: companyCode,
    externalAccountNumber: contractNumber,
  })
  const {
    data: { data: { bcaAmounts: bcaAmountsData = [] } = {} } = {},
    isFetching: isBcaAmountsFetching,
    isError: isBcaAmountsError,
  } = useBankCustomerAccountsAmounts({
    bcaIds: compact([bankCustomerAccountDetailsData?.id]),
  })
  const bcaAmount =
    bcaAmountsData?.find((item) => item.bcaId === bankCustomerAccountDetailsData?.id) ?? {}

  const originalBookBalance = bcaAmount?.bookBalance
  const headquarterBookBalance = bcaAmount?.headquarterBookBalance

  if (isEdit) {
    return (
      <ReadonlyFormattedMoneyInput
        original={originalBookBalance}
        headquarter={headquarterBookBalance}
      />
    )
  } else {
    return (
      <MoneyCell
        primary={originalBookBalance}
        secondary={headquarterBookBalance}
        isLoading={isBcaAmountsFetching || isBcaDetailsFetching}
        isError={isBcaAmountsError || isBcaDetailsError}
        errorToDisplay={<ErrorDataUnavailableInCell />}
      />
    )
  }
}

BankCustomerAccountBookBalanceCell.propTypes = {
  /** BCA contractNumber/bankArea */
  companyCode: PropTypes.string,
  /** BCA contractNumber/externalAccountNumber */
  contractNumber: PropTypes.string,
  isEdit: PropTypes.bool,
}

const propertyCellPropTypes = {
  /** Tranche UUID */
  value: PropTypes.string,
  isEditMode: PropTypes.bool,
}
/** @param {PropTypes.InferProps<typeof propertyCellPropTypes>} props */
export const PropertyCell = ({ value, isEditMode = false } = {}) => {
  const {
    data: { data: { properties = [] } = {} } = {},
    isLoading,
    isError,
  } = usePropertyUuids([value])

  return isEditMode ? (
    <Input readonly={true} value={`${properties[0]?.description} (${properties[0]?.id})`} />
  ) : (
    <SmallLoadingWrapper
      isError={isError}
      isLoading={isLoading}
      error={<ErrorDataUnavailableInCell />}
      renderContent={() => (
        <Entity
          name={properties[0]?.description}
          id={properties[0]?.id}
          link={`/properties/${properties[0]?.id}`}
          openInNewTab
        />
      )}
    />
  )
}

PropertyCell.propTypes = propertyCellPropTypes

const marketValueCellPropTypes = {
  /** Tranche UUID */
  value: PropTypes.string,
  isEditMode: PropTypes.bool,
}
/** @param {PropTypes.InferProps<typeof totalCommitmentCellPropTypes>} props */
export const MarketValueCell = ({ value, isEditMode = false }) => {
  const {
    data: { data: { properties = [] } = {} } = {},
    isLoading: isPropertyLoading,
    isError: isErrorLoading,
  } = usePropertyUuids([value])

  const firstPropertyUuid = properties[0]?.uuid

  const {
    data: { valuations = [] } = {},
    isLoading: isValuationLoading,
    isError: isValuationError,
  } = useMultiPropertyValuations([firstPropertyUuid])

  const formatMoney = useCustomizableCurrencyFormatter()

  const valuationValue = useMemo(() => {
    const propertyValuations = valuations[firstPropertyUuid]
    if (!propertyValuations) {
      return null
    }

    const propertyValuation = propertyValuations[multiPropertyValuations.marketValue]
    if (!propertyValuation) {
      return null
    }

    return {
      value: propertyValuation.value_amount.number,
      currency: propertyValuation.value_amount.currency,
    }
  }, [valuations, firstPropertyUuid])

  return isEditMode ? (
    <FormattedNumberInput
      readonly={true}
      value={valuationValue?.value}
      icon={<Text>{valuationValue?.currency}</Text>}
    />
  ) : (
    <SmallLoadingWrapper
      isError={isErrorLoading || isValuationError}
      error={<ErrorDataUnavailableInCell />}
      isLoading={isPropertyLoading || isValuationLoading}
      renderContent={() =>
        renderMoneyColumnCell({
          cell: {
            value: {
              cagValue: valuationValue,
            },
          },
          formatMoney,
        })
      }
    />
  )
}

MarketValueCell.propTypes = marketValueCellPropTypes

const assetCellPropTypes = {
  /** Tranche UUID */
  value: PropTypes.string,
}
/** @param {PropTypes.InferProps<typeof assetCellPropTypes>} props */

export const AssetCell = ({ value }) => {
  const { collateralId } = useParams()
  const {
    data: assetData,
    isLoading: isAssetLoading,
    isError: isAssetError,
  } = useRightById(value, collateralId)

  return (
    <SmallLoadingWrapper
      isLoading={isAssetLoading}
      isError={isAssetError}
      error={''}
      renderContent={() => (
        <Entity
          name={assetData?.name}
          id={assetData?.displayId}
          link={`/rights/${assetData?.id}`}
          openInNewTab
        />
      )}
    />
  )
}

AssetCell.propTypes = assetCellPropTypes

const assetValueCellPropTypes = {
  /** Asset UUID */
  value: PropTypes.string,
}
/** @param {PropTypes.InferProps<typeof totalCommitmentCellPropTypes>} props */
export const AssetValueCell = ({ value }) => {
  const { collateralId } = useParams()
  const {
    data: assetData,
    isLoading: isAssetLoading,
    isError: isAssetError,
  } = useRightById(value, collateralId)

  const formatMoney = useCustomizableCurrencyFormatter()

  return (
    <SmallLoadingWrapper
      isError={isAssetError}
      error={''}
      isLoading={isAssetLoading}
      renderContent={() =>
        renderMoneyColumnCell({
          cell: {
            value: {
              cagValue: {
                value: assetData?.rightValue?.value,
                currency: assetData?.rightValue?.currency,
              },
              cagValueInHeadQuarterCurrency: {
                value: assetData?.rightValueHq?.value,
                currency: assetData?.rightValueHq?.currency,
              },
            },
          },
          formatMoney,
        })
      }
    />
  )
}

AssetValueCell.propTypes = assetValueCellPropTypes

const otherAssetValuesCellPropTypes = {
  /** Asset UUID */
  value: PropTypes.string,
  cellName: PropTypes.string,
  disabled: PropTypes.bool,
  onInput: PropTypes.func,
}

/**
 * @typedef {object} overrides
 * @property {Parameters<typeof Input>[0]['onInput']} [onInput]
 * @param {Omit<PropTypes.InferProps<typeof otherAssetValuesCellPropTypes>, keyof overrides> & overrides} props
 * */
export const OtherAssetValues = ({ value, cellName, disabled = false, onInput = () => {} }) => {
  const { collateralId } = useParams()

  const { format: formatDateFromIso } = useShortDateFormatter()
  /** @param {Date?} [date] */
  const formatDate = (date) => formatDateFromIso(date && DateTime.fromJSDate(date).toISODate())

  const {
    data: assetData,
    isLoading: isAssetLoading,
    isError: isAssetError,
  } = useRightById(value, collateralId)

  switch (cellName) {
    case 'assetName':
      return (
        <SmallLoadingWrapper
          isError={isAssetError}
          error={''}
          isLoading={isAssetLoading}
          renderContent={() =>
            disabled ? (
              <Input id={'assetName'} value={assetData?.name} disabled />
            ) : (
              assetData?.rightType?.name
            )
          }
        />
      )
    case 'assetType':
      return (
        <SmallLoadingWrapper
          isError={isAssetError}
          error={''}
          isLoading={isAssetLoading}
          renderContent={() =>
            disabled ? (
              <Input id={'assetType'} value={assetData?.rightType?.name} disabled />
            ) : (
              assetData?.rightType?.name
            )
          }
        />
      )
    case 'valuationDate':
      return (
        <SmallLoadingWrapper
          isError={isAssetError}
          error={''}
          isLoading={isAssetLoading}
          renderContent={() =>
            disabled ? (
              <Input
                id={'valuationDate'}
                value={formatDate(assetData?.lastValuationDate)}
                disabled
              />
            ) : (
              formatDate(assetData?.lastValuationDate)
            )
          }
        />
      )
    case 'accountBalance':
      return (
        <SmallLoadingWrapper
          isError={isAssetError}
          error={''}
          isLoading={isAssetLoading}
          renderContent={() =>
            disabled ? (
              <Input id={'accountBalance'} value={assetData?.rightValue?.value} disabled />
            ) : (
              assetData?.rightValue?.value
            )
          }
        />
      )
    case 'rankingOrder':
      return (
        <SmallLoadingWrapper
          isError={isAssetError}
          error={''}
          isLoading={isAssetLoading}
          renderContent={() => assetData?.rankingOrder}
        />
      )
    case 'editRankingOrder':
      return (
        <SmallLoadingWrapper
          isError={isAssetError}
          error={''}
          isLoading={isAssetLoading}
          renderContent={() => (
            <NumberInput
              id={'editRankingOrder'}
              value={assetData?.rankingOrder}
              onInput={onInput ?? undefined}
            />
          )}
        />
      )
    case 'sequence':
      return (
        <SmallLoadingWrapper
          isError={isAssetError}
          error={''}
          isLoading={isAssetLoading}
          renderContent={() => assetData?.sequence}
        />
      )
    case 'editSequence':
      return (
        <SmallLoadingWrapper
          isError={isAssetError}
          error={''}
          isLoading={isAssetLoading}
          renderContent={() => (
            <NumberInput
              id={'editSequence'}
              value={assetData?.sequence}
              onInput={onInput ?? undefined}
            />
          )}
        />
      )
    default:
      return null
  }
}

OtherAssetValues.propTypes = otherAssetValuesCellPropTypes

const ReadonlyFormattedMoneyInput = ({ original, headquarter }) => {
  const isBothCurrencySetAndUnequal =
    original?.currency && headquarter?.currency && original?.currency !== headquarter?.currency

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <FormattedNumberInput
        readonly={true}
        icon={<Label>{original?.currency}</Label>}
        value={original?.amount}
        valueState={ValueState.None}
      />
      {isBothCurrencySetAndUnequal && (
        <FormattedNumberInput
          readonly={true}
          icon={<Label>{headquarter?.currency}</Label>}
          value={headquarter?.amount}
          valueState={ValueState.None}
        />
      )}
    </FlexBox>
  )
}
ReadonlyFormattedMoneyInput.propTypes = {
  original: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  headquarter: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
}
