import { TextAlign, VerticalAlign } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import DateCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/cells/DateCell'
import EntityCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/cells/EntityCell'
import StatusCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/cells/StatusCell'
import TextCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/cells/TextCell'

const columnKeyPrefix = 'pages.business-partner.risk-monitoring.forbearance.status-table'

/**
 * @param {string} columnKey
 * @param {(key: string) => string} t
 */
const getDefaultColumnDefinition = (columnKey, t) => ({
  Header: <Trans i18nKey={`${columnKeyPrefix}.label.${columnKey}`} />,
  title: t(columnKey).replace('<br/>', ' '),
  sortByLabel: t(`label.${columnKey}`).replace('<br/>', ' '),
  accessor: columnKey,
})

const useForbearanceStatusTableColumnDefinitions = () => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: columnKeyPrefix,
  })

  const columns = useMemo(
    () => ({
      name: {
        width: 400,
        Header: <Trans i18nKey={`${columnKeyPrefix}.label.name`} />,
        Cell: ({ cell }) => TextCell({ text: cell?.value }),
      },
      status: {
        minWidth: 250,
        Header: <Trans i18nKey={`${columnKeyPrefix}.label.status`} />,
        Cell: ({ cell }) => StatusCell(cell?.value),
      },
      prioritizedForbearanceMeasure: {
        minWidth: 250,
        Header: <Trans i18nKey={`${columnKeyPrefix}.label.prioritized-forbearance-measure`} />,
        Cell: ({ cell }) => TextCell({ text: cell?.value }),
      },
      gracePeriodStartDate: {
        minWidth: 150,
        Header: <Trans i18nKey={`${columnKeyPrefix}.label.grace-period-start-date`} />,
        hAlign: TextAlign.Right,
        Cell: ({ cell }) => DateCell({ date: cell?.value }),
      },
      gracePeriodEndDate: {
        minWidth: 150,
        Header: <Trans i18nKey={`${columnKeyPrefix}.label.grace-period-end-date`} />,
        hAlign: TextAlign.Right,
        Cell: ({ cell }) => DateCell({ date: cell?.value }),
      },
      probationPeriodStartDate: {
        minWidth: 150,
        Header: <Trans i18nKey={`${columnKeyPrefix}.label.probation-period-start-date`} />,
        hAlign: TextAlign.Right,
        Cell: ({ cell }) => DateCell({ date: cell?.value }),
      },
      probationPeriodEndDate: {
        minWidth: 150,
        Header: <Trans i18nKey={`${columnKeyPrefix}.label.probation-period-end-date`} />,
        hAlign: TextAlign.Right,
        Cell: ({ cell }) => DateCell({ date: cell?.value }),
      },
      createdOn: {
        minWidth: 150,
        Header: <Trans i18nKey={`${columnKeyPrefix}.label.created-on`} />,
        hAlign: TextAlign.Right,
        Cell: ({ cell }) => DateCell({ date: cell?.value }),
      },
      createdBy: {
        minWidth: 250,
        Header: <Trans i18nKey={`${columnKeyPrefix}.label.created-by`} />,
        Cell: ({ cell }) => TextCell({ text: cell?.value }),
      },
      currentBusinessEvent: {
        minWidth: 300,
        Header: <Trans i18nKey={`${columnKeyPrefix}.label.current-business-event`} />,
        Cell: ({ cell }) => {
          const { id, name, link } = cell?.value || {}
          return EntityCell({
            name,
            id,
            link,
          })
        },
      },
    }),
    [],
  )

  return useMemo(
    () =>
      Object.keys(columns).map((columnKey) => ({
        ...getDefaultColumnDefinition(columnKey, t),
        disableDragAndDrop: true,
        disableResizing: false,
        vAlign: VerticalAlign.Middle,
        ...columns[columnKey],
      })),
    [columns, t],
  )
}

export default useForbearanceStatusTableColumnDefinitions
