import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PortfolioFinancedAssetsAreaMap from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-area/PortfolioFinancedAssetsAreaMap'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-area/shared/NoPropertyAssignedToDealInfo'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-area/shared/ui/EmptyCardContent'

const PortfolioFinancedAssetsAreaTile = ({ tileId, isPdfView }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.portfolio-financed-assets-area',
  })
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const {
    data: {
      propertyMarkers,
      noProperties,
      missingGeoLocations,
      totalAmountOfProperties,
      marketAreaData,
    } = {},
  } = tileStatus

  return useMemo(() => {
    if (noProperties) {
      return <NoPropertyAssignedToDealInfo />
    }
    if (!propertyMarkers || propertyMarkers?.length === 0) {
      return (
        <EmptyCardContent
          illustrationName="NoData"
          title={t('no-data.title')}
          subtitle={t('no-data.subtitle')}
        />
      )
    }
    return (
      <PortfolioFinancedAssetsAreaMap
        markers={propertyMarkers}
        disabled={isPdfView}
        missingLocations={missingGeoLocations}
        totalAmountOfProperties={totalAmountOfProperties}
        marketAreaData={marketAreaData}
      />
    )
  }, [
    isPdfView,
    marketAreaData,
    missingGeoLocations,
    noProperties,
    propertyMarkers,
    t,
    totalAmountOfProperties,
  ])
}

PortfolioFinancedAssetsAreaTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool,
}
export default PortfolioFinancedAssetsAreaTile
