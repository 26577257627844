import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useAdjustments = ({ dealUuid, includeWarnings = false, ...requestOptions }) => {
  const queryParams = new URLSearchParams()
  if (includeWarnings) {
    queryParams.append('include_warnings', 'true')
  }
  const queryString = queryParams.toString()
  return useCamelizedResponse(
    useRequest({
      ...requestOptions,
      path: `/deals/${dealUuid}/adjustments` + (queryString ? `?${queryString}` : ``),
      useCache: false,
      keys: ['deals', dealUuid, 'adjustments', includeWarnings],
    }),
  )
}
