import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Icon,
  ObjectStatus,
  TableRowType,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { decisionStageStatus } from 'api/decision-process/decisionProcessApi'
import {
  decisionProcessConditionsDecisionStatus,
  getObjectStatusforDecisionStageConditionsStatus,
} from 'api/decision-process/decisionProcessConditionsApi'
import {
  decisionProcessMinutesDecisionStatus,
  getObjectStatusforDecisionStageMinutesStatus,
} from 'api/decision-process/decisionProcessMinutesApi'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import { isEventClosed } from 'api/events/status'
import DecisionStageName from 'components/domains/business-events-and-tasks/common/DecisionStageName'
import DecisionProcessDeleteStageButton from 'components/domains/business-events-and-tasks/decision-process/DecisionProcessDeleteStageButton'
import styles from 'components/domains/business-events-and-tasks/decision-process/DecisionProcessOverviewTable.module.css'
import DecisionProcessOverviewTableStatusCell from 'components/domains/business-events-and-tasks/decision-process/DecisionProcessOverviewTableStatusCell'
import DecisionProcessCreateStageDialog from 'components/domains/business-events-and-tasks/decision-process/stages/DecisionProcessCreateStageDialog'
import DecisionStageDecisionPaperLink from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageDecisionPaperLink'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import paths from 'routes/paths'

const hyphen = '-'

const DecisionProcessOverviewTable = ({ decisionStages }) => {
  const {
    event: { id: eventId, status: eventStatus },
    allowedOperations,
  } = useContext(BusinessEventsAndTasksContext)
  const { t: tNoPrefix } = useTranslation('translation')
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.overview.table',
  })

  const navigate = useNavigate()
  const { format: formatDate } = useShortDateFormatter()
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
  const hasCreateDecisionStagePermission = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.createDecisionStage),
    [allowedOperations],
  )
  const hasDeleteDecisionStagePermission = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.deleteDecisionStage),
    [allowedOperations],
  )

  const columns = [
    {
      title: t('columns.name'),
      columnKey: 'name',
      sortingDisabled: true,
    },
    {
      title: t('columns.linkedDecisionPaper'),
      columnKey: 'linkedDecisionPaper',
      sortingDisabled: true,
    },
    {
      title: t('columns.decisionStatus'),
      columnKey: 'decisionStatus',
      sortingDisabled: true,
    },
    {
      title: t('columns.decisionDate'),
      columnKey: 'decisionDate',
      sortingDisabled: true,
    },
    {
      title: t('columns.conditions'),
      columnKey: 'conditions',
      sortingDisabled: true,
    },
    {
      title: t('columns.minutes'),
      columnKey: 'minutes',
      sortingDisabled: true,
    },
    {
      title: '',
      columnKey: 'actions',
      sortingDisabled: true,
      isSelectableForHiding: false,
    },
  ]

  const tableData = decisionStages.map(
    ({
      id,
      info: { name, decisionType, decisionStatus, decisionDate },
      decisionPaper,
      conditions: { decisionStatus: conditionsDecisionStatus },
      minutes: { decisionStatus: minutesDecisionStatus },
    }) => {
      const conditionObjectStatus = conditionsDecisionStatus
        ? getObjectStatusforDecisionStageConditionsStatus(conditionsDecisionStatus)
        : undefined
      const minutesObjectStatus = minutesDecisionStatus
        ? getObjectStatusforDecisionStageMinutesStatus(minutesDecisionStatus)
        : undefined
      return {
        rowKey: `decision-stage-${id}`,
        rowProperties: { type: TableRowType.Active, 'data-decision-stage-id': id },
        name: {
          cellComponent: <DecisionStageName name={name} decisionStageType={decisionType} />,
        },
        linkedDecisionPaper: {
          cellComponent: decisionPaper ? (
            <DecisionStageDecisionPaperLink
              eventId={eventId}
              decisionStageStatus={decisionStatus}
              decisionPaperVersion={decisionPaper.version}
            />
          ) : (
            <Text>{'-'}</Text>
          ),
        },
        decisionStatus: {
          cellComponent: <DecisionProcessOverviewTableStatusCell stageStatus={decisionStatus} />,
        },
        decisionDate: {
          cellComponent: <Text>{decisionDate ? formatDate(decisionDate) : hyphen}</Text>,
        },
        conditions: {
          cellComponent: conditionObjectStatus ? (
            <ObjectStatus inverted state={conditionObjectStatus.objectStatus}>
              {tNoPrefix(conditionObjectStatus.translationKey)}
            </ObjectStatus>
          ) : (
            <Text>{hyphen}</Text>
          ),
        },
        minutes: {
          cellComponent: minutesObjectStatus ? (
            <ObjectStatus inverted state={minutesObjectStatus.objectStatus}>
              {tNoPrefix(minutesObjectStatus.translationKey)}
            </ObjectStatus>
          ) : (
            <Text>{hyphen}</Text>
          ),
        },
        actions: {
          cellComponent: (
            <FlexBox
              alignItems={FlexBoxAlignItems.Center}
              justifyContent={FlexBoxJustifyContent.End}
              direction={FlexBoxDirection.Row}
            >
              {(decisionStatus === decisionStageStatus.planned ||
                decisionStatus === decisionStageStatus.inProgressRequestDiscarded) &&
                hasDeleteDecisionStagePermission &&
                !isEventClosed(eventStatus) && (
                  <DecisionProcessDeleteStageButton eventId={eventId} stageId={id} />
                )}
              <Icon name="slim-arrow-right" />
            </FlexBox>
          ),
        },
      }
    },
  )

  const handleAddButtonClicked = useCallback(() => {
    setIsAddDialogOpen(true)
  }, [setIsAddDialogOpen])

  const addButton = useMemo(
    () =>
      hasCreateDecisionStagePermission && !isEventClosed(eventStatus) ? (
        <Button
          key={'add-button'}
          design={ButtonDesign.Transparent}
          onClick={handleAddButtonClicked}
        >
          {tNoPrefix('buttons.add')}
        </Button>
      ) : null,
    [eventStatus, handleAddButtonClicked, hasCreateDecisionStagePermission, tNoPrefix],
  )

  const additionalTableProperties = {
    onRowClick: ({ detail: { row: clickedTableRow } }) => {
      const decisionStageId = clickedTableRow.getAttribute('data-decision-stage-id')
      navigate(
        `/${paths.businessEventsAndTasks}/business-events/${eventId}/decision-stages/${decisionStageId}`,
      )
    },
  }

  return (
    <div className={styles.tableWrapper}>
      <SortedTable
        columnDefinitions={columns}
        tableData={tableData}
        additionalTableProperties={additionalTableProperties}
        toolbarConfig={{
          title: t('title'),
          additionalActions: [addButton],
        }}
      />
      {isAddDialogOpen && (
        <DecisionProcessCreateStageDialog
          isOpen={isAddDialogOpen}
          setIsOpen={setIsAddDialogOpen}
          headerText={t('dialog.add-stage.header')}
          submitButtonText={tNoPrefix('buttons.add')}
          successNotification={t('dialog.add-stage.success-notification')}
        />
      )}
    </div>
  )
}

DecisionProcessOverviewTable.propTypes = {
  decisionStages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      info: PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        decisionType: PropTypes.string.isRequired,
        decisionStatus: PropTypes.string.isRequired,
        decisionDate: PropTypes.string,
      }),
      conditions: PropTypes.shape({
        decisionStatus: PropTypes.oneOf(Object.values(decisionProcessConditionsDecisionStatus)),
      }).isRequired,
      minutes: PropTypes.shape({
        decisionStatus: PropTypes.oneOf(Object.values(decisionProcessMinutesDecisionStatus)),
      }).isRequired,
    }),
  ).isRequired,
}

export default DecisionProcessOverviewTable
