import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'

const mapDealCollateralInformationData = ({
  borrowerId,
  dealIdsAndUuidsByBorrowerId,
  multipleDealCollateralInformationData,
}) => {
  const deals = dealIdsAndUuidsByBorrowerId[borrowerId]

  if (isEmpty(deals) || isNil(multipleDealCollateralInformationData)) {
    return { isSomeStandardPackageFalse: null }
  }

  const isSomeStandardPackageFalse = deals.some(
    ({ dealUuid }) =>
      multipleDealCollateralInformationData.find(
        (dealCollateralInformation) => dealCollateralInformation.dealUuid === dealUuid,
      ).isStandardPackage === false,
  )

  return {
    isSomeStandardPackageFalse,
  }
}

export default mapDealCollateralInformationData
