import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styles from 'components/domains/properties/rent-roll/details/table/PropertyRentRollDetailsTableCellComponent.module.css'
import {
  OCCUPANCY_TYPE_LET,
  OCCUPANCY_TYPE_VACANT,
} from 'components/domains/properties/rent-roll/working-version/utils/rentRollWorkingVersionConstants'
import {
  useAreaMeasurementUnitFormatter,
  useBooleanToTextFormatter,
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'

const NO_DATA_PLACEHOLDER = '-'
const PropertyRentRollDetailsTableCellComponent = ({ rentalUnit, contentKey, valueHelp }) => {
  const { t } = useTranslation()
  const formatArea = useAreaMeasurementUnitFormatter()
  const formatMoney = useCustomizableCurrencyFormatter()
  const { format: formatDate } = useShortDateFormatter()
  const formatBoolean = useBooleanToTextFormatter()
  const formatNumber = useNumberFormatter()

  const getOccupancyStatusById = (id) => {
    if (valueHelp.isErrorOccupancyStatus) {
      return { display_name: `<${t('pages.property.rent-roll.loading-error')}>` }
    }
    return valueHelp.occupancyStatusCodes?.find(({ key }) => key === id)
  }

  const renderCell = () => {
    const contentValue = rentalUnit[contentKey]
    return (
      <div>
        {!contentValue || typeof columnValue === 'object' ? NO_DATA_PLACEHOLDER : contentValue}
      </div>
    )
  }

  const renderNumberCell = () => {
    const columnValue = rentalUnit[contentKey]
    return (
      <div className={styles['rental-unit-cell-number']}>
        {!columnValue ? NO_DATA_PLACEHOLDER : formatNumber(columnValue)}
      </div>
    )
  }

  const renderBooleanCell = () => {
    const columnValue = rentalUnit[contentKey]
    return (
      <div>
        {typeof columnValue === 'boolean'
          ? formatBoolean(rentalUnit[contentKey])
          : NO_DATA_PLACEHOLDER}
      </div>
    )
  }

  const renderDateCell = () => {
    const columnValue = rentalUnit[contentKey]
    return <div>{!columnValue ? NO_DATA_PLACEHOLDER : formatDate(columnValue)}</div>
  }

  const renderBusinessPartnerCell = () => {
    const { id, name } = rentalUnit[contentKey] ?? {}
    if (!id) {
      return <div>{NO_DATA_PLACEHOLDER}</div>
    }
    return (
      <>
        <Link
          to={`/business-partners/${id}`}
          className={styles['rental-unit-cell-link']}
          target="_blank"
        >
          {name ?? `<${t('pages.property.rent-roll.loading-error')}>`}
        </Link>
        <div>{id}</div>
      </>
    )
  }

  const renderTenantCell = () => {
    const { occupancy_status, anonymous_tenant } = rentalUnit ?? {}
    const occupancyStatusType = getOccupancyStatusById(occupancy_status)?.type
    if (occupancyStatusType === OCCUPANCY_TYPE_VACANT) {
      return <div>{t('pages.property.rent-roll.table.tenant-vacant.label')}</div>
    }

    if (anonymous_tenant && occupancyStatusType === OCCUPANCY_TYPE_LET) {
      return <div>{t('pages.property.rent-roll.table.tenant-anonymous.label')}</div>
    }

    return renderBusinessPartnerCell()
  }

  const renderMoneyCell = () => {
    const { number, currency } = rentalUnit[contentKey] ?? {}
    return (
      <div className={styles['rental-unit-cell-number']}>
        {!number || !currency ? NO_DATA_PLACEHOLDER : `${formatMoney(number, currency)}`}
      </div>
    )
  }

  const renderAreaCell = () => {
    const { value, measurement_unit } = rentalUnit[contentKey] ?? {}
    return (
      <div className={styles['rental-unit-cell-number']}>
        {!value || !measurement_unit
          ? NO_DATA_PLACEHOLDER
          : `${formatNumber(value)} ${formatArea(measurement_unit)}`}
      </div>
    )
  }

  const renderSegmentCell = () => (
    <div className={styles['segment-cell']}>
      {!rentalUnit.segment_name ? NO_DATA_PLACEHOLDER : rentalUnit.segment_name}
    </div>
  )

  const renderOccupancyStatusCell = () => {
    const columnValue = rentalUnit[contentKey]
    const occupancyStatus = getOccupancyStatusById(columnValue)?.display_name
    return <div>{occupancyStatus ?? NO_DATA_PLACEHOLDER}</div>
  }
  switch (contentKey) {
    case 'occupancy_status':
      return renderOccupancyStatusCell()
    case 'tenant':
      return renderTenantCell()
    case 'segment_name':
      return renderSegmentCell()
    case 'area':
      return renderAreaCell()
    case 'rent_contracted_year':
    case 'rent_current_year':
    case 'rent_arrears':
      return renderMoneyCell()
    case 'branding_franchise':
      return renderBusinessPartnerCell()
    case 'rent_start_date':
    case 'lease_start_date':
    case 'lease_break_date':
    case 'lease_expiry_date':
      return renderDateCell()
    case 'anonymous_tenant':
    case 'no_lease_expiry':
    case 'rent_dispute':
      return renderBooleanCell()
    case 'number_of_units':
      return renderNumberCell()
    default:
      return renderCell()
  }
}

PropertyRentRollDetailsTableCellComponent.propTypes = {
  rentalUnit: PropTypes.object.isRequired,
  contentKey: PropTypes.string.isRequired,
}

export default PropertyRentRollDetailsTableCellComponent
