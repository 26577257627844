import '@ui5/webcomponents-fiori/dist/illustrations/sapIllus-Spot-UnableToLoad.js'
import { IllustratedMessage } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isTaskFinalized } from 'api/events/status'
import TasksTable from 'components/domains/business-events-and-tasks/tasks/table/TasksTable'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useEventTasks from 'hooks/services/business-events-and-tasks/events/useEventTasks'

const TasksTableLoadingWrapper = ({
  eventId,
  additionalActions,
  hideStatusColumn,
  setHasOpenMandatoryTasks,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.event-detail.tasks-table-loading-wrapper',
  })

  const { data: tasks, isLoading, isError } = useEventTasks(eventId)

  const [sorting, setSorting] = useState({ sortBy: 'info.current_due_date', orderBy: 'asc' })

  const onSortingChanged = useCallback((sortBy, orderBy) => {
    setSorting({ sortBy, orderBy })
  }, [])

  useEffect(() => {
    const hasOpenTasks = tasks?.tasks.some(
      (task) => task.info.isMandatory && !isTaskFinalized(task.status),
    )

    setHasOpenMandatoryTasks(hasOpenTasks)
  }, [setHasOpenMandatoryTasks, tasks?.tasks])

  const shownColumns = useMemo(
    () => [
      'checkbox',
      'name',
      'assignee',
      'description',
      'originalDueDate',
      'currentDueDate',
      ...(hideStatusColumn ? [] : ['statusText']),
      'watchers',
      'arrow',
    ],
    [hideStatusColumn],
  )

  const renderContent = useCallback(
    () => (
      <TasksTable
        tasks={tasks.tasks}
        onSortingChanged={onSortingChanged}
        sortBy={sorting.sortBy}
        orderBy={sorting.orderBy}
        showFilterInToolbar={true}
        shownColumns={shownColumns}
        noDataText={t('no-data')}
        additionalActions={additionalActions}
        useBackendOrdering={false}
      />
    ),
    [additionalActions, shownColumns, t, tasks, onSortingChanged, sorting],
  )

  const renderCustomErrorScreen = () => (
    <IllustratedMessage
      name="UnableToLoad"
      size="Dialog"
      titleText={t('error.title')}
      subtitleText={t('error.subtitle')}
    />
  )

  return (
    <LoadingStateWrapper
      isLoading={isLoading}
      loadingTitle={t('loading-title')}
      loadingDescription={t('loading-description')}
      isError={isError}
      renderContent={renderContent}
      renderCustomErrorScreen={renderCustomErrorScreen}
    />
  )
}

TasksTableLoadingWrapper.propTypes = {
  eventId: PropTypes.string.isRequired,
  additionalActions: PropTypes.arrayOf(PropTypes.element),
  hideStatusColumn: PropTypes.bool.isRequired,
  setHasOpenMandatoryTasks: PropTypes.func.isRequired,
}

export default TasksTableLoadingWrapper
