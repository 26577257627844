import { FlexBox, FlexBoxDirection, Grid, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import TextField from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/fields/TextField'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/OpsMemoTrancheDetailsCard.module.css'

const OpsMemoTrancheBorrowerDetailsCard = ({ deal, tranche, businessPartners }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.ops-memo-tranche-details.borrower-details',
  })
  const borrowerId = tranche?.borrowerId || deal?.borrowerBpId
  const businessPartner = businessPartners.find((bp) => bp?.id === borrowerId)

  const borrower = {
    name: businessPartner?.fullName,
    id: businessPartner?.id,
  }

  return (
    <Grid defaultSpan="XL6 L6 M6 S12" className={styles.card}>
      <FlexBox direction={FlexBoxDirection.Column}>
        <Text className={styles.cardHeaderTitle}>{t('borrower-details')}</Text>

        <TextField label={t('borrower-name')} value={borrower.name} isFirstItem={true} />
        <TextField label={t('borrower-id')} value={borrower.id} />
      </FlexBox>
    </Grid>
  )
}

OpsMemoTrancheBorrowerDetailsCard.propTypes = {
  deal: PropTypes.shape({
    borrowerName: PropTypes.string,
    borrowerBpId: PropTypes.string,
  }),
  tranche: PropTypes.shape({
    borrower: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  businessPartners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fullName: PropTypes.string,
    }),
  ),
}

export default OpsMemoTrancheBorrowerDetailsCard
