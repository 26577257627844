import { FlexBox, TableCell, TableRow, TableRowType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React from 'react'
import TableRowActionButtons from 'components/domains/deals/tables/TableRowActionButtons'
import { kpiColumnDefinitionShape, kpiRowDataShape } from 'components/domains/kpis/KpiPropTypes'

const KpiAdjustmentTableDisplayRow = ({
  rowData,
  columnDefinition,
  userIsAllowedToEdit,
  onEdit,
  onDelete,
}) => {
  const alignContent = (content, alignment) =>
    alignment ? <FlexBox justifyContent={alignment}>{content}</FlexBox> : content

  return (
    <TableRow key={rowData.rowKey} type={TableRowType.Inactive}>
      {columnDefinition.map((column) => (
        <TableCell key={`${rowData.rowKey}-${column.columnKey}`}>
          {alignContent(rowData[column.columnKey].cellContentReadMode, column.alignment)}
        </TableCell>
      ))}
      {userIsAllowedToEdit && (
        <TableCell>
          <TableRowActionButtons
            id={`${rowData.rowKey}-editDeleteButtons`}
            isEditable={rowData.isEditable}
            onEdit={onEdit}
            isDeletable={rowData.isDeletable}
            onDelete={onDelete}
            showDeletePopover={true}
          />
        </TableCell>
      )}
    </TableRow>
  )
}

KpiAdjustmentTableDisplayRow.propTypes = {
  rowData: kpiRowDataShape,
  columnDefinition: PropTypes.arrayOf(kpiColumnDefinitionShape),
  userIsAllowedToEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}

export default KpiAdjustmentTableDisplayRow
