import { MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isEventClosed } from 'api/events/status'
import DecisionPaperAutomaticTileWithMetaDataRenderer from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/with-meta-data/DecisionPaperAutomaticTileWithMetaDataRenderer'
import CashFlowTileHeaderSelectActions from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/with-meta-data/cash-flow/CashFlowTileHeaderSelectActions'
import CashFlowVacancyProjectionTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/CashFlowVacancyProjectionTile'
import CashFlowAssumptionsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/banks-case/CashFlowAssumptionsTile'
import CashFlowDebtAndEquityProjectionTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/banks-case/CashFlowDebtAndEquityProjectionTile'
import CashFlowFinancialRatiosTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/banks-case/CashFlowFinancialRatiosTile'
import CovenantsRatiosFinancialRatiosTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants-and-ratios/CovenantsRatiosFinancialRatiosTile'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useEditMultiTilesMetaData } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/meta-data/useEditMultiTilesMetaData'
import useEditTileMetaData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/meta-data/useEditTileMetaData'
import useTileMetaData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/meta-data/useTileMetaData'
import useIsDecisionPaperTileExpanded from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useIsDecisionPaperTileExpanded'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const CASH_FLOW_ASSUMPTION_ADDITIONAL_CASE = 'CASH_FLOW_ASSUMPTIONS_ADDITIONAL_CASE'
const CASH_FLOW_ASSUMPTION_ADDITIONAL_CASE_WV_LIVE = 'WV_LIVE_CASH_FLOW_ASSUMPTIONS_ADDITIONAL_CASE'
const CASH_FLOW_ASSUMPTION_BASE_CASE = 'CASH_FLOW_ASSUMPTIONS_BASE_CASE'
const CASH_FLOW_ASSUMPTION_BASE_CASE_WV_LIVE = 'WV_LIVE_CASH_FLOW_ASSUMPTIONS_BASE_CASE'
const CASH_FLOW_ASSUMPTION_STRESS_CASE = 'CASH_FLOW_ASSUMPTIONS_STRESS_CASE'
const CASH_FLOW_ASSUMPTION_STRESS_CASE_WV_LIVE = 'WV_LIVE_CASH_FLOW_ASSUMPTIONS_STRESS_CASE'
const CASH_FLOW_DEBT_AND_EQUITY_PROJECTION_ADDITIONAL_CASE =
  'CASH_FLOW_DEBT_EQUITY_PROJECTION_ADDITIONAL_CASE'
const CASH_FLOW_DEBT_AND_EQUITY_PROJECTION_ADDITIONAL_CASE_WV_LIVE =
  'WV_LIVE_CASH_FLOW_DEBT_EQUITY_PROJECTION_ADDITIONAL_CASE'
const CASH_FLOW_DEBT_AND_EQUITY_PROJECTION_BASE_CASE = 'CASH_FLOW_DEBT_EQUITY_PROJECTION_BASE_CASE'
const CASH_FLOW_DEBT_AND_EQUITY_PROJECTION_BASE_CASE_WV_LIVE =
  'WV_LIVE_CASH_FLOW_DEBT_EQUITY_PROJECTION_BASE_CASE'
const CASH_FLOW_DEBT_AND_EQUITY_PROJECTION_STRESS_CASE =
  'CASH_FLOW_DEBT_EQUITY_PROJECTION_STRESS_CASE'
const CASH_FLOW_DEBT_AND_EQUITY_PROJECTION_STRESS_CASE_WV_LIVE =
  'WV_LIVE_CASH_FLOW_DEBT_EQUITY_PROJECTION_STRESS_CASE'
const CASH_FLOW_FINANCIAL_RATIOS_ADDITIONAL_CASE = 'CASH_FLOW_FINANCIAL_RATIOS_ADDITIONAL_CASE'
const CASH_FLOW_FINANCIAL_RATIOS_ADDITIONAL_CASE_WV_LIVE =
  'WV_LIVE_CASH_FLOW_FINANCIAL_RATIOS_ADDITIONAL_CASE'
const CASH_FLOW_FINANCIAL_RATIOS_BASE_CASE = 'CASH_FLOW_FINANCIAL_RATIOS_BASE_CASE'
const CASH_FLOW_FINANCIAL_RATIOS_BASE_CASE_WV_LIVE = 'WV_LIVE_CASH_FLOW_FINANCIAL_RATIOS_BASE_CASE'
const CASH_FLOW_FINANCIAL_RATIOS_STRESS_CASE = 'CASH_FLOW_FINANCIAL_RATIOS_STRESS_CASE'
const CASH_FLOW_FINANCIAL_RATIOS_STRESS_CASE_WV_LIVE =
  'WV_LIVE_CASH_FLOW_FINANCIAL_RATIOS_STRESS_CASE'
const CASH_FLOW_VACANCY_PROJECTION = 'CASH_FLOW_VACANCY_PROJECTION'
const CASH_FLOW_VACANCY_PROJECTION_WV_LIVE = 'WV_LIVE_CASH_FLOW_VACANCY_PROJECTION'
const COVENANTS_RATIOS_FINANCIAL_RATIOS = 'COVENANTS_RATIOS_FINANCIAL_RATIOS'
const COVENANTS_RATIOS_FINANCIAL_RATIOS_WV_LIVE = 'WV_LIVE_COVENANTS_RATIOS_FINANCIAL_RATIOS'

const CashFlowTileLoadingWrapper = ({
  tileId,
  title: tileTitle,
  tileCode,
  viewConfig,
  isPdfView = false,
  currentVersion,
  synchronizationStatus,
  isEditableInMinorVersion,
  lastAvailableVersion,
  subsectionTilesIds,
  ...tileProps
}) => {
  const {
    event: { id: eventId, status: eventStatus },
  } = useContext(BusinessEventsAndTasksContext)

  const { isExpanded, expand } = useIsDecisionPaperTileExpanded({
    tileId,
    isPdfView,
    isDefaultExpanded: viewConfig.isDefaultExpanded,
  })

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.tiles',
  })
  const queryClient = useQueryClient()
  const showMessageBox = Modals.useShowMessageBox()

  const {
    data: tileMetaData,
    isLoading: isTileMetaDataLoading,
    isError: isTileMetaDataError,
  } = useTileMetaData({ eventId, tileId })

  const { mutate: selectTileMetaData } = useEditTileMetaData()
  const { mutate: selectTilesMetaData } = useEditMultiTilesMetaData()

  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const {
    data: { dealUuid: dealUuidByTileCode } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({ dealUuid: tileStatus?.data?.dealUuid, tileCode })

  const [metaDataContent, setMetaDataContent] = useState(tileMetaData?.meta_data)

  useEffect(() => {
    setMetaDataContent(tileMetaData?.meta_data)
  }, [tileMetaData?.meta_data])

  const hasChanges = useMemo(
    () => !isEqual(metaDataContent, tileMetaData?.meta_data),
    [metaDataContent, tileMetaData?.meta_data],
  )

  const onSaveSuccess = useCallback(() => {
    queryClient.invalidateQueries([
      'events',
      eventId,
      'decision-paper',
      'versions',
      'current',
      'tiles',
      tileId,
      'meta-data',
    ])
  }, [eventId, queryClient, tileId])

  const onSaveError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('meta-data.save.error.description'),
    })
  }, [showMessageBox, t])

  const handleSaveSelectedScenario = useCallback(() => {
    if (isEmpty(subsectionTilesIds)) {
      selectTileMetaData(
        { eventId, tileId, metaData: metaDataContent },
        {
          onSuccess: onSaveSuccess,
          onError: onSaveError,
        },
      )
    } else {
      const tileIds = subsectionTilesIds.subsectionTilesIds

      const metaData = tileIds.map((tileIdToUpdate) => ({
        tile_id: tileIdToUpdate,
        meta_data: metaDataContent,
      }))
      //invalidate queries should be changed after endpoint update with meta data in subsection
      selectTilesMetaData(
        { eventId, metaData },
        {
          onSuccess: () =>
            queryClient.invalidateQueries([
              'events',
              eventId,
              'decision-paper',
              'versions',
              'current',
              'tiles',
            ]),
          onError: onSaveError,
        },
      )
    }
  }, [
    eventId,
    metaDataContent,
    onSaveError,
    onSaveSuccess,
    queryClient,
    selectTileMetaData,
    selectTilesMetaData,
    subsectionTilesIds,
    tileId,
  ])

  const selectActions = useMemo(
    () =>
      !isEventClosed(eventStatus) && (
        <CashFlowTileHeaderSelectActions
          expand={expand}
          isExpanded={isExpanded}
          hasChanges={hasChanges}
          handleSaveSelectedScenario={handleSaveSelectedScenario}
          dealUuid={dealUuidByTileCode}
          metaDataContent={metaDataContent}
          setMetaDataContent={setMetaDataContent}
          lastSelectedScenario={tileMetaData?.meta_data}
        />
      ),
    [
      eventStatus,
      expand,
      isExpanded,
      hasChanges,
      handleSaveSelectedScenario,
      dealUuidByTileCode,
      metaDataContent,
      tileMetaData?.meta_data,
    ],
  )

  const renderContent = useCallback(() => {
    switch (tileCode) {
      case CASH_FLOW_VACANCY_PROJECTION:
      case CASH_FLOW_VACANCY_PROJECTION_WV_LIVE:
        return (
          <CashFlowVacancyProjectionTile tileId={tileId} isPdfView={isPdfView} {...tileProps} />
        )
      case CASH_FLOW_DEBT_AND_EQUITY_PROJECTION_ADDITIONAL_CASE:
      case CASH_FLOW_DEBT_AND_EQUITY_PROJECTION_ADDITIONAL_CASE_WV_LIVE:
      case CASH_FLOW_DEBT_AND_EQUITY_PROJECTION_BASE_CASE:
      case CASH_FLOW_DEBT_AND_EQUITY_PROJECTION_BASE_CASE_WV_LIVE:
      case CASH_FLOW_DEBT_AND_EQUITY_PROJECTION_STRESS_CASE:
      case CASH_FLOW_DEBT_AND_EQUITY_PROJECTION_STRESS_CASE_WV_LIVE:
        return (
          <CashFlowDebtAndEquityProjectionTile
            tileId={tileId}
            isPdfView={isPdfView}
            {...tileProps}
          />
        )
      case CASH_FLOW_ASSUMPTION_ADDITIONAL_CASE:
      case CASH_FLOW_ASSUMPTION_ADDITIONAL_CASE_WV_LIVE:
      case CASH_FLOW_ASSUMPTION_BASE_CASE:
      case CASH_FLOW_ASSUMPTION_BASE_CASE_WV_LIVE:
      case CASH_FLOW_ASSUMPTION_STRESS_CASE:
      case CASH_FLOW_ASSUMPTION_STRESS_CASE_WV_LIVE:
        return <CashFlowAssumptionsTile tileId={tileId} isPdfView={isPdfView} {...tileProps} />
      case CASH_FLOW_FINANCIAL_RATIOS_ADDITIONAL_CASE:
      case CASH_FLOW_FINANCIAL_RATIOS_ADDITIONAL_CASE_WV_LIVE:
      case CASH_FLOW_FINANCIAL_RATIOS_BASE_CASE:
      case CASH_FLOW_FINANCIAL_RATIOS_BASE_CASE_WV_LIVE:
      case CASH_FLOW_FINANCIAL_RATIOS_STRESS_CASE:
      case CASH_FLOW_FINANCIAL_RATIOS_STRESS_CASE_WV_LIVE:
        return <CashFlowFinancialRatiosTile tileId={tileId} isPdfView={isPdfView} {...tileProps} />
      case COVENANTS_RATIOS_FINANCIAL_RATIOS:
      case COVENANTS_RATIOS_FINANCIAL_RATIOS_WV_LIVE:
        return (
          <CovenantsRatiosFinancialRatiosTile
            tileId={tileId}
            isPdfView={isPdfView}
            {...tileProps}
          />
        )
    }
  }, [isPdfView, tileCode, tileId, tileProps])

  return (
    <DecisionPaperAutomaticTileWithMetaDataRenderer
      tileId={tileId}
      tileCode={tileCode}
      titleText={tileTitle}
      viewConfig={viewConfig}
      isPdfView={isPdfView}
      actions={selectActions}
      currentVersion={currentVersion}
      synchronizationStatus={synchronizationStatus}
      isEditableInMinorVersion={isEditableInMinorVersion}
      lastAvailableVersion={lastAvailableVersion}
    >
      <LoadingStateWrapper
        isLoading={
          isTileMetaDataLoading ||
          !tileStatus ||
          tileStatus.isLoading ||
          (!tileStatus.data && !tileStatus.isError) ||
          isDealUuidFetching
        }
        loadingDescription={t('loading')}
        isError={isTileMetaDataError || (!!tileStatus && tileStatus.isError) || isDealUuidError}
        errorTitle={t('loading.error')}
        errorDescription={t('loading.error.description')}
        renderContent={renderContent}
      />
    </DecisionPaperAutomaticTileWithMetaDataRenderer>
  )
}

CashFlowTileLoadingWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  tileCode: PropTypes.string.isRequired,
  viewConfig: PropTypes.shape({
    columnSpan: PropTypes.number.isRequired,
    isDefaultExpanded: PropTypes.bool.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool,
  currentVersion: PropTypes.string.isRequired,
  synchronizationStatus: PropTypes.string.isRequired,
  isEditableInMinorVersion: PropTypes.bool.isRequired,
  lastAvailableVersion: PropTypes.string.isRequired,
  subsectionTilesIds: PropTypes.shape({
    subsectionCode: PropTypes.string,
    subsectionTilesIds: PropTypes.array,
  }),
}

export default CashFlowTileLoadingWrapper
