import { Button, FlexBox, FlexBoxDirection, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-information/shared/DecisionPaperPropertyImage.module.css'

const DecisionPaperPropertyImage = ({ image, imageTitle, altText, onEnlargeImage, isPdfView }) => (
  <FlexBox className={styles.imageContainer} direction={FlexBoxDirection.Column}>
    {onEnlargeImage && !isPdfView && (
      <Button
        className={styles.fullScreenButton}
        icon="full-screen"
        onClick={() => onEnlargeImage(image, imageTitle)}
      />
    )}
    <img className={styles.image} alt={altText || imageTitle} src={image} />
    <Text className={styles.imageTitle}>{imageTitle}</Text>
  </FlexBox>
)

DecisionPaperPropertyImage.propTypes = {
  image: PropTypes.string.isRequired,
  imageTitle: PropTypes.string.isRequired,
  altText: PropTypes.string,
  onEnlargeImage: PropTypes.func,
  isPdfView: PropTypes.bool,
}

export default DecisionPaperPropertyImage
