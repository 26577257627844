import {
  Button,
  ButtonDesign,
  ComboBox,
  ComboBoxItem,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import useGetRequirements from 'hooks/services/conditions/requirements/useGetRequirements'
import useGetDocumentTypes from 'hooks/services/documents/useGetDocumentTypes'

const propTypes = {
  value: PropTypes.string, // if undefined error state will be set, if no error state should be set use empty string
  toBeIgnoredValues: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  entityRefs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
    }).isRequired,
  ).isRequired,
  valueState: PropTypes.oneOf(Object.values(ValueState)),
  valueStateMessage: PropTypes.string,
  emptyOptionText: PropTypes.string,
  disabled: PropTypes.bool,
  setErrorState: PropTypes.func,
}

/** @type {React.FC<Omit<PropTypes.InferProps<typeof propTypes>, "onChange"> & {onChange?: (documentType: string | undefined) => void}>} */
const DocumentTypeInput = forwardRef(
  (
    {
      value,
      toBeIgnoredValues,
      onChange = () => {},
      entityRefs,
      onError,
      valueState,
      valueStateMessage,
      emptyOptionText = '',
      disabled,
      setErrorState = () => {},
    },
    _ref,
  ) => {
    const { t } = useTranslation('translation', { keyPrefix: 'components.document-type-input' })

    const [parsedErrorMessage, setParsedErrorMessage] = useState(valueStateMessage ?? t('error'))

    const currentDocumentType = useSelector(
      (state) => state.conditions.requirementsTable.editedRow.currentValues?.documentType,
    )
    const currentConditionId = useSelector(
      (state) => state.conditions.requirementsTable.editedRow.currentValues?.condition?.id,
    )

    const {
      data: { documentTypes } = {},
      isLoading,
      isError,
      error,
    } = useGetDocumentTypes({ entityRefs })

    const {
      data: { requirements } = {},
      isFetching: isRequirementFetching,
      isError: isRequirementError,
    } = useGetRequirements({
      conditionId: currentConditionId,
    })

    // Can access the [0] requirement because they all always have the same document type for the dialog.
    const previouslySelectedDocumentType = requirements?.[0]?.info?.documentType

    const latestDocType = useMemo(() => {
      if (previouslySelectedDocumentType !== currentDocumentType) {
        return currentDocumentType
      }
      return previouslySelectedDocumentType
    }, [currentDocumentType, previouslySelectedDocumentType])

    const checkIfDocumentTypeExistsInAllValidTypes = useCallback(() => {
      if (previouslySelectedDocumentType === undefined && currentDocumentType === undefined) {
        return true
      }
      return documentTypes?.includes(latestDocType)
    }, [latestDocType, previouslySelectedDocumentType, currentDocumentType, documentTypes])

    const isDocumentTypeValueValid = useCallback(() => {
      if (value === '' || toBeIgnoredValues?.includes(value)) return true
      if (!documentTypes?.includes(value)) {
        setParsedErrorMessage(t('invalid-doc-type'))
      }
      return isLoading || documentTypes?.includes(value)
    }, [value, documentTypes, isLoading, t, toBeIgnoredValues])

    /**
     * hasBeenFocused controls whether or not to render the ComboBoxItems for
     * this document select. This is a performance optimization for cases where
     * many document types are available. In some cases, >1.500 document types
     * are returned and would be eagerly rendered for every DocumentTypeInput.
     * We have cases in the CWP where we display >20 DocumentTypeInputs at once,
     * each taking >0.4 seconds on modern systems to render.
     *
     * With this optimization to only render the ComboBoxItems once the Component
     * has initially been focuses (which includes click events to open the options
     * popover), the inital rendering time is reduced to ~0.03 seconds. There is a
     * slightly noticable delay when opening the options popover with this
     * performance optimization enabled. It still vastly improves the perceived
     * performance.
     */
    const [hasBeenFocused, setHasBeenFocused] = useState(false)
    const onComboBoxFocus = useCallback((event) => {
      event.stopPropagation()
      setHasBeenFocused(true)
    }, [])

    const internalValueState = useMemo(() => {
      if (isError || isRequirementError) {
        return ValueState.Error
      }
      if (valueState) {
        return valueState
      }
      if (!checkIfDocumentTypeExistsInAllValidTypes() || !isDocumentTypeValueValid()) {
        return ValueState.Error
      }
      return ValueState.None
    }, [
      isError,
      isRequirementError,
      valueState,
      checkIfDocumentTypeExistsInAllValidTypes,
      isDocumentTypeValueValid,
    ])

    useEffect(() => {
      if (!isNil(documentTypes)) {
        setErrorState(!documentTypes.includes(value))
      }
    }, [documentTypes, setErrorState, value])

    useEffect(() => {
      const parseError = async () => {
        if (!error?.response?.json) return
        // response is cloned because in some cases it is read before elsewhere, causing the error:
        // Failed to execute 'json' on 'Response': body stream already read
        const parsedError = await error.response.clone().json()
        setParsedErrorMessage(parsedError?.detail ?? valueStateMessage ?? t('error'))
      }
      if (isError || isRequirementError) {
        parseError()
        if (typeof onError === 'function') {
          onError()
        }
      }
    }, [error?.response, isError, onError, t, valueStateMessage, isRequirementError])

    const handleOnChange = useCallback(
      (event) => {
        onChange(event.target.value)
        setErrorState(!documentTypes?.includes(event.target.value))
      },
      [documentTypes, onChange, setErrorState],
    )

    const sortedDocumentTypes = useMemo(
      () =>
        documentTypes
          ?.sort((a, b) => a.localeCompare(b))
          .map((documentType) => <ComboBoxItem key={documentType} text={documentType} />),
      [documentTypes],
    )

    const onClearButtonClicked = useCallback(() => {
      onChange('')
    }, [onChange])

    const options = useMemo(() => {
      if (isLoading || isError || isRequirementFetching) {
        return [<ComboBoxItem key="noOptions" data-id="" text={emptyOptionText} />]
      } else {
        return hasBeenFocused && sortedDocumentTypes
      }
    }, [
      isLoading,
      isError,
      isRequirementFetching,
      emptyOptionText,
      hasBeenFocused,
      sortedDocumentTypes,
    ])

    return (
      <ComboBox
        value={(isLoading && isRequirementFetching && t('loading')) || (value ?? '')}
        onChange={handleOnChange}
        onFocus={onComboBoxFocus}
        valueState={internalValueState}
        valueStateMessage={<Text>{parsedErrorMessage}</Text>}
        disabled={disabled ?? undefined}
        icon={
          value &&
          value !== '' && (
            <Button
              icon="decline"
              design={ButtonDesign.Transparent}
              onClick={onClearButtonClicked}
            />
          )
        }
      >
        {options}
      </ComboBox>
    )
  },
)

DocumentTypeInput.displayName = 'DocumentTypeInput'

DocumentTypeInput.propTypes = propTypes

export default DocumentTypeInput
