import { useTranslation } from 'react-i18next'
import { useBreachTranslation } from 'components/domains/deals/covenants/shared/useBreachTranslations'

const useMonitoringItemsTableEnums = ({ monitoringItems }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.monitoring-items-card',
  })
  const { getTranslationForBreach } = useBreachTranslation()
  const { statusEnum, breachEnum } = monitoringItems.reduce(
    (accumulate, current) => ({
      // Status
      statusEnum: {
        ...accumulate.statusEnum,
        [current?.status]: t(`status.${current?.status?.toLowerCase()}`),
      },
      // Breach
      breachEnum: {
        ...accumulate.breachEnum,
        [current?.breach]: getTranslationForBreach(current?.breach),
      },
    }),
    { statusEnum: {}, breachEnum: {} },
  )
  delete statusEnum.undefined
  delete statusEnum.null
  delete breachEnum.undefined
  delete breachEnum.null
  return { statusEnum, breachEnum }
}

export default useMonitoringItemsTableEnums
