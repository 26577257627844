import { DynamicPageHeader } from '@fioneer/ui5-webcomponents-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { taskStatus as possibleTaskStatus } from 'api/events/status'
import {
  concatIds,
  checkIfAtLeastTwoNotEmpty,
  setEntityTypeIfEmpty,
} from 'components/domains/business-events-and-tasks/events/filterbar/EventsAndTasksFilterUtils'
import TaskManagerMultiEditDialog from 'components/domains/business-events-and-tasks/tasks/dialogs/TaskManagerMultiEditDialog'
import useMultipleTasksCommentButtonAndDialog from 'components/domains/business-events-and-tasks/tasks/dialogs/useMultipleTasksCommentButtonAndDialog'
import TaskTableEditMultipleButton from 'components/domains/business-events-and-tasks/tasks/table/TaskTableEditMultipleButton'
import TasksTable from 'components/domains/business-events-and-tasks/tasks/table/TasksTable'
import TasksFilterBar from 'components/domains/business-events-and-tasks/tasks/table/filterbar/TasksFilterBar'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import { useTasks } from 'hooks/services/business-events-and-tasks/tasks/useTasks'
import { useStaffMemberSelf } from 'hooks/services/business-partners/staff-members/useStaffMemberSelf'
import { reset } from 'redux/slices/tasks/taskTableSlice'
import queryParamNames from 'routes/business-events-and-tasks/queryParamNames.json'
import styles from 'routes/business-events-and-tasks/tasks/TaskManager.module.css'

const collectIds = (ids1, ids2, ids3) => concatIds(ids1, ids2, ids3)

const emptyArray = []

const calculateFilterOptions = (queryParams) => {
  const bpIds = queryParams.get(queryParamNames.bpIds)
  const propertyIds = queryParams.get(queryParamNames.propertyIds)
  const dealIds = queryParams.get(queryParamNames.dealIds)

  const ids = collectIds(bpIds, propertyIds, dealIds)

  let entityType = queryParams.get(queryParamNames.eventEntityRefType)
  entityType = setEntityTypeIfEmpty(bpIds, propertyIds, dealIds, entityType)

  return {
    searchValue: queryParams.get(queryParamNames.searchString),
    taskStatus: queryParams.get(queryParamNames.taskStatus),
    eventStatus: queryParams.get(queryParamNames.eventStatus),
    mandatory:
      queryParams.get(queryParamNames.mandatory) === 'all'
        ? null
        : queryParams.get(queryParamNames.mandatory),
    taskAssigneeId: queryParams.get(queryParamNames.taskAssigneeId),
    currentDueDateRange: queryParams.get(queryParamNames.currentDueDateRange),
    originalDueDateRange: queryParams.get(queryParamNames.originalDueDateRange),
    eventEntityRefType: entityType,
    eventEntityRef: ids,
  }
}

const filterApplied = (queryParams) => {
  const {
    searchValue,
    taskStatus,
    eventStatus,
    mandatory,
    taskAssigneeId,
    currentDueDateRange,
    originalDueDateRange,
    eventEntityRefType,
    bpIds,
    propertyIds,
    dealIds,
  } = calculateFilterOptions(queryParams)
  return !!(
    searchValue ||
    taskStatus ||
    eventStatus ||
    mandatory ||
    taskAssigneeId ||
    currentDueDateRange ||
    originalDueDateRange ||
    eventEntityRefType ||
    bpIds ||
    propertyIds ||
    dealIds
  )
}

const defaultQueryParams = {
  task_status: `${possibleTaskStatus.created},${possibleTaskStatus.ongoing}`,
}

const TaskManager = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.task-manager' })
  const [isEditMultipleDialogOpen, setIsEditMultipleDialogOpen] = useState(false)
  const { hash } = useLocation()
  const navigate = useNavigate()
  const [queryParams] = useSearchParams()
  const [sorting, setSorting] = useState({ sortBy: 'info.current_due_date', orderBy: 'asc' })
  const normalizedQueryParams = [...new Set(queryParams.keys())]
  const { commentMultipleButton, commentMultipleDialog } = useMultipleTasksCommentButtonAndDialog()
  const dispatch = useDispatch()

  const showMessageBox = useShowMessageBox()

  const {
    isLoading: userStaffMemberIsLoading,
    isError: userStaffMemberIsError,
    data: currentUser,
  } = useStaffMemberSelf()

  const currentAssignee = queryParams.get(queryParamNames.taskAssigneeId)
  const showMyTasks = currentAssignee === currentUser?.id

  const enableHooks = useMemo(
    () =>
      normalizedQueryParams.length !== 0 &&
      !checkIfAtLeastTwoNotEmpty(
        queryParams.get(queryParamNames.bpIds),
        queryParams.get(queryParamNames.propertyIds),
        queryParams.get(queryParamNames.dealIds),
      ),
    [normalizedQueryParams.length, queryParams],
  )

  const {
    data: tasksResponse,
    isError,
    isLoading,
    fetchNextPage,
  } = useTasks(
    sorting,
    { ...calculateFilterOptions(queryParams), taskAssigneeId: showMyTasks ? '' : currentAssignee },
    {},
    { enabled: enableHooks },
  )

  const myTasksEnabled = enableHooks && !userStaffMemberIsError && !userStaffMemberIsLoading
  const {
    data: myTasksResponse,
    isError: isErrorMyTasks,
    isLoading: isLoadingMyTasks,
    isFetching: isFetchingMyTasks,
    fetchNextPage: fetchMyNextPage,
  } = useTasks(
    sorting,
    { ...calculateFilterOptions(queryParams), taskAssigneeId: currentUser?.id },
    {},
    {
      enabled: myTasksEnabled,
    },
  )

  //same workaround as for events table. Workaround for: myTasks sometimes defaulting to common task response when hook fails.
  const enabledTasksResponse = myTasksEnabled ? myTasksResponse : undefined
  const enabledFetchingMyTasks = myTasksEnabled ? isFetchingMyTasks : true

  useEffect(() => {
    if (normalizedQueryParams.length === 0) {
      const searchParams = new URLSearchParams(Object.entries(defaultQueryParams))
      if (currentUser?.id) {
        searchParams.append(queryParamNames.taskAssigneeId, currentUser.id)
      }
      const routingURL = `?${searchParams.toString()}${hash}`
      navigate(routingURL)
    } else if (currentUser?.id && !queryParams.has(queryParamNames.taskAssigneeId)) {
      queryParams.append(queryParamNames.taskAssigneeId, currentUser.id)
      const routingURL = `?${queryParams.toString()}${hash}`
      navigate(routingURL)
    }
    return () => dispatch(reset())
  }, [currentUser?.id, queryParams]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userStaffMemberIsError) {
      showMessageBox({
        type: MessageBoxTypes.Error,
        children: t('self-information-error'),
        id: 'tasks-error-message-box',
        open: userStaffMemberIsError,
      })
    }
  }, [userStaffMemberIsError, showMessageBox, t])

  const onSortingChanged = useCallback((sortBy, orderBy) => {
    setSorting({ sortBy, orderBy })
  }, [])

  const calculatedNoDataText = useMemo(
    () => (filterApplied(queryParams) ? t('filter.empty') : t('filter.no-data')),
    [queryParams, t],
  )

  const onEditMultipleClicked = useCallback(() => {
    setIsEditMultipleDialogOpen(true)
  }, [])

  const editMultipleButton = useMemo(
    () => (
      <TaskTableEditMultipleButton
        key="task-table-edit-mulitple-button"
        onClick={onEditMultipleClicked}
      />
    ),
    [onEditMultipleClicked],
  )

  const onLoadMore = useCallback(() => {
    if (showMyTasks) {
      return fetchMyNextPage()
    }
    return fetchNextPage()
  }, [fetchMyNextPage, fetchNextPage, showMyTasks])

  const renderContent = useCallback(
    () => (
      <div className={styles.tableWrapper}>
        <TasksTable
          tasks={
            showMyTasks
              ? enabledTasksResponse?.tasks ?? emptyArray
              : tasksResponse?.tasks ?? emptyArray
          }
          onSortingChanged={onSortingChanged}
          sortBy={sorting.sortBy}
          orderBy={sorting.orderBy}
          onLoadMore={onLoadMore}
          maximumNumberOfTasks={tasksResponse?.total ?? 0}
          maximumNumberOfMyTasks={enabledTasksResponse?.total ?? 0}
          noDataText={calculatedNoDataText}
          shownColumns={[
            'checkbox',
            'name',
            'event',
            'assignee',
            'originalDueDate',
            'currentDueDate',
            'statusText',
            'watchers',
            'arrow',
          ]}
          additionalActions={[commentMultipleButton, editMultipleButton]}
          myTasksLoading={isErrorMyTasks || isLoadingMyTasks || enabledFetchingMyTasks}
          currentUserId={currentUser?.id}
        />
      </div>
    ),
    [
      showMyTasks,
      enabledTasksResponse?.tasks,
      enabledTasksResponse?.total,
      tasksResponse?.tasks,
      tasksResponse?.total,
      onSortingChanged,
      sorting.sortBy,
      sorting.orderBy,
      onLoadMore,
      calculatedNoDataText,
      commentMultipleButton,
      editMultipleButton,
      isErrorMyTasks,
      isLoadingMyTasks,
      enabledFetchingMyTasks,
      currentUser?.id,
    ],
  )

  return (
    <Card>
      <DynamicPageHeader className={styles['dynamic-page-header']}>
        <TasksFilterBar />
      </DynamicPageHeader>
      <RequestStateResolver
        isError={isError || isErrorMyTasks}
        isLoading={isLoading && enableHooks}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        renderContent={renderContent}
      />
      {isEditMultipleDialogOpen && (
        <TaskManagerMultiEditDialog
          isOpen={isEditMultipleDialogOpen}
          setIsOpen={setIsEditMultipleDialogOpen}
        />
      )}
      {commentMultipleDialog}
    </Card>
  )
}

export default TaskManager
