import orderBy from 'lodash.orderby'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/RiskIndicatorsGroupBusinessEvents.module.css'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/card/EmptyCardContent'
import SortedTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/tables/sorted-tables/SortedTable'
import useRiskIndicatorsGroupBusinessEventsColumnDefinitions from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/useRiskIndicatorsGroupBusinessEventsColumnDefinitions'
import useRiskIndicatorsGroupBusinessEventsTableData from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/useRiskIndicatorsGroupBusinessEventsTableData'

const RiskIndicatorsGroupBusinessEventsTile = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper')
  const { riskIndicatorGroupEventsData } =
    useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId].data) ?? {}

  const columns = useRiskIndicatorsGroupBusinessEventsColumnDefinitions()
  // table data is initially be sorted by creation date, with the latest event as the first row
  const tableData = orderBy(
    useRiskIndicatorsGroupBusinessEventsTableData(riskIndicatorGroupEventsData ?? []),
    ['creationDate.value', 'businessEvent.value'],
    ['desc', 'desc'],
  )

  return useMemo(
    () => (
      <div className={styles.tableWrapper}>
        <SortedTable
          columnDefinitions={columns}
          tableData={tableData}
          toolbarConfig={{
            title: t(
              'tiles.business-partner.risk-monitoring.risk-indicators.group-details.business-events.title',
            ),
            showColumnSelection: false,
          }}
          renderEmptyTableComponent={() => (
            <EmptyCardContent
              size={'Spot'}
              title={t(
                'tiles.risk-monitoring.risk-indicators-description.components.cards.empty.title',
              )}
              subtitle={t(
                'tiles.risk-monitoring.risk-indicators-description.components.cards.empty.subtitle',
              )}
              illustrationName={'NoData'}
            />
          )}
        />
      </div>
    ),
    [columns, t, tableData],
  )
}

RiskIndicatorsGroupBusinessEventsTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default RiskIndicatorsGroupBusinessEventsTile
