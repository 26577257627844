import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'

const useMultipleCustomerSpecificAttributes = ({
  dealIdTrancheIdPairs,
  dataSource = DATA_SOURCES.EXISTING_BUSINESS,
  options = {},
}) => {
  const queryParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams()

    if (dataSource) {
      urlSearchParams.append('dataSource', dataSource)
    }

    return urlSearchParams
  }, [dataSource])

  // HINT: `params.size` seems to return `undefined` even if there are params
  const paramsString = [...queryParams.keys()].length ? `?${queryParams.toString()}` : ''

  const responses = useRequestsMemo(
    useRequests({
      requests: dealIdTrancheIdPairs.map(({ dealId, trancheId }) => ({
        path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/custom-fields${paramsString}`,
        keys: ['deals', dealId, 'tranches', trancheId, 'custom-fields', 'dataSource', dataSource],
      })),
      translated: true,
      useCache: true,
      options: { ...options, enabled: !isEmpty(dealIdTrancheIdPairs) },
    }),
  )

  return useMemo(
    () =>
      responses.map((response, index) => ({
        ...response,
        data: {
          ...dealIdTrancheIdPairs[index],
          ...camelize(response.data),
        },
      })),
    [dealIdTrancheIdPairs, responses],
  )
}

export default useMultipleCustomerSpecificAttributes
