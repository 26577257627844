import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import sortBy from 'lodash.sortby'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessPartnerSelectionTable from 'components/domains/conditions/reference-selection/BusinessPartnerSelectionTable'
import DealSelectionTable from 'components/domains/conditions/reference-selection/DealSelectionTable'
import PropertySelectionTable from 'components/domains/conditions/reference-selection/PropertySelectionTable'
import ReferenceMultiSelectionInput from 'components/domains/conditions/reference-selection/ReferenceMultiSelectionInput'
import styles from 'components/domains/conditions/reference-selection/ReferenceSelectionHelperDialog.module.css'
import supportedReferenceTypesForConditionReferenceSelection from 'components/domains/conditions/reference-selection/supportedReferenceTypesForConditionReferenceSelection'
import Dialog, {
  DialogPrimaryButton,
  DialogSecondaryButton,
  DialogSize,
} from 'components/ui/dialog/Dialog'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useAnalyticalTableDialogFocusFix from 'hooks/analytical-table/useAnalyticalTableDialogFocusFix'
import useReferenceNames from 'hooks/services/conditions/reference-selection/useReferenceNames'

const mappedComponentForEntityType = {
  [cwpEntityTypes.BUSINESS_PARTNER]: BusinessPartnerSelectionTable,
  [cwpEntityTypes.DEAL]: DealSelectionTable,
  [cwpEntityTypes.PROPERTY]: PropertySelectionTable,
}

const emptyArray = []

const ReferenceSelectionHelperDialogContent = ({
  isOpen,
  onCancel,
  onSelect,
  selectedReferences = emptyArray,
  selectedReferenceType,
}) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.reference-object-selection',
  })
  const [currentSelectedReferences, setCurrentSelectedReferences] = useState(selectedReferences)
  const dialogRef = useRef()
  useAnalyticalTableDialogFocusFix({ dialogRef })

  useEffect(() => {
    setCurrentSelectedReferences(selectedReferences)
  }, [selectedReferences])

  const hasChanges = useMemo(() => {
    if (selectedReferences.length !== currentSelectedReferences.length) {
      return true
    }

    return !selectedReferences.every((selectedReference) =>
      currentSelectedReferences.some(
        (currentSelectedReference) =>
          currentSelectedReference.entityId === selectedReference.entityId &&
          currentSelectedReference.entityType === selectedReference.entityType,
      ),
    )
  }, [currentSelectedReferences, selectedReferences])

  const onCancelButtonClicked = useCallback(() => {
    setCurrentSelectedReferences(selectedReferences)
    onCancel()
  }, [selectedReferences, onCancel])

  const onEntityRemoved = useCallback(
    ({ entityId, entityType }) =>
      setCurrentSelectedReferences((currentlySelectedEntities) =>
        currentlySelectedEntities.filter(
          (currentlySelectedEntity) =>
            !(
              currentlySelectedEntity.entityId === entityId &&
              currentlySelectedEntity.entityType === entityType
            ),
        ),
      ),
    [],
  )

  const onSelectionChange = useCallback(
    (newSelection) => {
      const sortedNewSelection = sortBy(newSelection, ['entityName'])
      setCurrentSelectedReferences(sortedNewSelection)
    },
    [setCurrentSelectedReferences],
  )

  const renderedSelectionComponent = useMemo(() => {
    if (!isEmpty(selectedReferenceType)) {
      const Component = mappedComponentForEntityType[selectedReferenceType]
      return (
        <Component
          onSelectionChange={onSelectionChange}
          selectedReferences={currentSelectedReferences}
        />
      )
    }
  }, [currentSelectedReferences, onSelectionChange, selectedReferenceType])

  const onClearReferences = useCallback(() => {
    setCurrentSelectedReferences(emptyArray)
  }, [])

  const onMultiInputReferenceDelete = useCallback(
    ({ entityId, entityType }) => {
      onEntityRemoved({ entityType, entityId })
    },
    [onEntityRemoved],
  )

  const onAddButtonClicked = useCallback(() => {
    onSelect({ selectedReferences: currentSelectedReferences })
  }, [currentSelectedReferences, onSelect])

  return (
    <Dialog
      size={DialogSize.XL}
      closeButton={
        <DialogSecondaryButton onClick={onCancelButtonClicked}>
          {tNoPrefix('buttons.cancel')}
        </DialogSecondaryButton>
      }
      primaryButton={
        <DialogPrimaryButton onClick={onAddButtonClicked} disabled={!hasChanges}>
          {tNoPrefix('buttons.add')}
        </DialogPrimaryButton>
      }
      open={isOpen}
      onAfterClose={onCancel}
      headerText={t('title')}
      ref={dialogRef}
    >
      <FlexBox direction={FlexBoxDirection.Column} className={styles.outerContent}>
        <FlexBox direction={FlexBoxDirection.Column} className={styles.searchTable}>
          {renderedSelectionComponent}
        </FlexBox>
        {!isEmpty(selectedReferenceType) && (
          <FlexBox direction={FlexBoxDirection.Column} className={styles.searcResults}>
            <Label showColon>{t('results.label')}</Label>
            <ReferenceMultiSelectionInput
              selectedReferences={currentSelectedReferences}
              showValueHelperIcon={false}
              onDeleteReference={onMultiInputReferenceDelete}
              onClear={onClearReferences}
              resolveNames={false}
            />
          </FlexBox>
        )}
      </FlexBox>
    </Dialog>
  )
}

const ReferenceSelectionHelperDialogPropTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedReferences: PropTypes.arrayOf(
    PropTypes.shape({
      entityId: PropTypes.string.isRequired,
      entityType: PropTypes.oneOf(supportedReferenceTypesForConditionReferenceSelection),
      entityName: PropTypes.string,
    }),
  ),
  selectedReferenceType: PropTypes.string,
}

ReferenceSelectionHelperDialogContent.propTypes = ReferenceSelectionHelperDialogPropTypes

/**
 * The helper dialog itself needs to pre-load the entity names in order to sort them alphabetically.
 * Therefore this component is split up into ReferenceSelectionHelperDialog which loads the references
 * and ReferenceSelectionHelperDialogContent which renders the dialog content once the refereces are loaded.
 */
const ReferenceSelectionHelperDialog = ({
  isOpen,
  onCancel,
  onSelect,
  selectedReferences = emptyArray,
  selectedReferenceType,
}) => {
  const { resolvedReferences, isLoading, isError } = useReferenceNames({
    references: selectedReferences,
  })
  const renderContent = useCallback(
    () => (
      <ReferenceSelectionHelperDialogContent
        isOpen={isOpen}
        onCancel={onCancel}
        onSelect={onSelect}
        selectedReferences={resolvedReferences}
        selectedReferenceType={selectedReferenceType}
      />
    ),
    [isOpen, onCancel, onSelect, resolvedReferences, selectedReferenceType],
  )

  return (
    <RequestStateResolver
      renderContent={renderContent}
      isError={isError}
      isLoading={isLoading}
      errorToDisplay={<ErrorDataUnavailableInContent />}
    />
  )
}

ReferenceSelectionHelperDialog.propTypes = ReferenceSelectionHelperDialogPropTypes

export default ReferenceSelectionHelperDialog
