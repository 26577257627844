import isEmpty from 'lodash.isempty'
import sortBy from 'lodash.sortby'
import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'
import { assessmentTypes } from 'components/domains/business-partners/tile/assessments/assessmentTypes'
import { useConfig } from 'hooks/config/useConfig'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import { useMultipleTextualAssessments } from 'hooks/services/business-partners/assessments/useMultipleTextualAssessments'
import { useMultipleBusinessPartnersById } from 'hooks/services/business-partners/getBusinessPartners'
import useMultipleBusinessPartnerRatings from 'hooks/services/business-partners/ratings/useMultipleBusinessPartnerRatings'
import useMultipleBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useMultipleBusinessPartnerRelationships'
import useDealProperties from 'hooks/services/deals/properties/useDealProperties'
import useCurrentMultiPropertyKpis from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpis'
import useMultiPropertiesKpis from 'hooks/services/properties/kpis/useMultiPropertiesKpis'
import useMultiProperties from 'hooks/services/properties/useMultiProperties'
import { useMultiPropertyViewByPropertyUuids } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuids'

export const MAX_DISPLAYED_TENANTS = 5

export const DEFAULT_RATING_AGENCY_PRIORITY = ['1_S&P', '1_Moody', '1_Fitch']

const useCombinedQueryResults = (queryResults) =>
  useMemo(
    () => ({
      isError: queryResults.some(({ isError }) => isError),
      isFetching: queryResults.some(({ isFetching }) => isFetching),
      data: queryResults.map(({ data }) => data).filter((data) => !!data),
    }),
    [queryResults],
  )

const getLatestTextualAssessment = (textualAssessments) => {
  if (isEmpty(textualAssessments?.availableVersions)) {
    return undefined
  }
  const { availableVersions } = textualAssessments
  const latestAssessment = [...availableVersions].sort(
    ({ version: firstVersion }, { version: secondVersion }) => secondVersion - firstVersion,
  )[0]
  return latestAssessment?.content
}

const useTenantDetails = ({ entityRef: { entityId: dealUuid } }, _, tileCode) => {
  const { data: config, isFetching: isFetchingConfig, isError: isErrorConfig } = useConfig()

  const ratingAgencyPriority = useMemo(
    () => config?.ratingAgencyPriority ?? DEFAULT_RATING_AGENCY_PRIORITY,
    [config],
  )

  const sortExternalRatingsByAgencyPriority = useCallback(
    (a, b) =>
      (ratingAgencyPriority.includes(a.agency.id)
        ? ratingAgencyPriority.indexOf(a.agency.id)
        : ratingAgencyPriority.length) -
      (ratingAgencyPriority.includes(b.agency.id)
        ? ratingAgencyPriority.indexOf(b.agency.id)
        : ratingAgencyPriority.length),
    [ratingAgencyPriority],
  )

  const {
    data: { dealUuid: dealUuidByTileCode } = {},
    isFetching: isFetchingDealUuid,
    isError: isErrorDealUuid,
  } = useDealUuidByTileCode({ dealUuid, tileCode })

  const {
    data: dealProperties,
    isFetching: isFetchingDealProperties,
    isError: isErrorDealProperties,
  } = useDealProperties({ dealUuid: dealUuidByTileCode })

  const dealPropertyUuids = (dealProperties?.dealProperties ?? []).map(
    ({ propertyUuid }) => propertyUuid,
  )

  const dealHasProperties = dealPropertyUuids.length > 0
  const dealHasMultiProperties = dealPropertyUuids.length > 1

  const {
    data: multiPropertyView,
    isFetching: isFetchingMultiPropertyView,
    isError: isErrorMultiPropertyView,
  } = useMultiPropertyViewByPropertyUuids(dealPropertyUuids, {
    enabled: dealHasMultiProperties,
  })

  const {
    data: multiPropertyKpis,
    isFetching: isFetchingMultiPropertyKpis,
    isError: isErrorMultiPropertyKpis,
  } = useCurrentMultiPropertyKpis(
    dealPropertyUuids,
    { withTenantGranularity: true },
    { enabled: dealHasProperties },
  )

  const highestRentTenants = sortBy(
    (multiPropertyKpis?.tenantRentRollKpis ?? []).filter(({ tenant }) => !!tenant),
    ({ annualizedCurrentRent }) => annualizedCurrentRent.number,
  )
    .reverse()
    .slice(0, MAX_DISPLAYED_TENANTS)

  const highestRentTenantIds = highestRentTenants.map(({ tenant }) => tenant)

  const {
    data: businessPartners,
    isFetching: isFetchingBusinessPartners,
    isError: isErrorBusinessPartners,
  } = useCombinedQueryResults(useMultipleBusinessPartnersById(highestRentTenantIds))

  const {
    data: businessPartnerRatings,
    isFetching: isFetchingBusinessPartnerRatings,
    isError: isErrorBusinessPartnerRatings,
  } = useCombinedQueryResults(useMultipleBusinessPartnerRatings(highestRentTenantIds))

  const {
    data: textualAssessments,
    isFetching: isFetchingTextualAssessments,
    isError: isErrorTextualAssessments,
  } = useCombinedQueryResults(
    useMultipleTextualAssessments(
      {
        businessPartnerIds: highestRentTenantIds,
        type: assessmentTypes.Description,
      },
      { enabled: !isEmpty(highestRentTenantIds) },
    ),
  )

  const {
    data: businessPartnerRelationships,
    isFetching: isFetchingBusinessPartnerRelationships,
    isError: isErrorBusinessPartnerRelationships,
  } = useCombinedQueryResults(useMultipleBusinessPartnerRelationships(highestRentTenantIds))

  const {
    data: allProperties,
    isFetching: isFetchingAllProperties,
    isError: isErrorAllProperties,
  } = useMultiProperties(
    dealPropertyUuids.map((id) => ({ id })),
    { enabled: dealHasProperties },
  )

  const {
    data: allPropertiesKpis,
    isFetching: isFetchingAllPropertiesKpis,
    isError: isErrorAllPropertiesKpis,
  } = useMultiPropertiesKpis(
    dealPropertyUuids,
    { withTenantGranularity: true },
    { enabled: dealHasProperties },
  )

  const propertiesPath = useMemo(() => {
    if (dealHasMultiProperties) {
      const multiPropertyViewUuid = multiPropertyView?.data?.uuid
      return `/properties/portfolio/rent-roll?portfolio-view-id=${multiPropertyViewUuid}`
    }

    if (dealHasProperties) {
      const propertyId = allProperties?.properties?.[0]?.id
      return `/properties/${propertyId}/rent-roll`
    }

    return null
  }, [dealHasMultiProperties, dealHasProperties, multiPropertyView, allProperties])

  const isLoading =
    isFetchingConfig ||
    isFetchingDealUuid ||
    isFetchingDealProperties ||
    (dealHasProperties &&
      (isFetchingMultiPropertyKpis ||
        isFetchingBusinessPartners ||
        isFetchingBusinessPartnerRatings ||
        isFetchingTextualAssessments ||
        isFetchingBusinessPartnerRelationships ||
        isFetchingAllProperties ||
        isFetchingAllPropertiesKpis)) ||
    (dealHasMultiProperties && isFetchingMultiPropertyView)

  const isError =
    isErrorConfig ||
    isErrorDealUuid ||
    isErrorDealProperties ||
    (dealHasProperties &&
      (isErrorMultiPropertyKpis ||
        isErrorBusinessPartners ||
        isErrorBusinessPartnerRatings ||
        isErrorTextualAssessments ||
        isErrorBusinessPartnerRelationships ||
        isErrorAllProperties ||
        isErrorAllPropertiesKpis)) ||
    (dealHasMultiProperties && isErrorMultiPropertyView)

  const data = useMemo(() => {
    if (isLoading || isError) return undefined

    return {
      tenants: Object.fromEntries(
        highestRentTenantIds.map((tenantId, index) => {
          const businessPartner = businessPartners[index]
          const ratings = businessPartnerRatings[index]
          const relationships = businessPartnerRelationships[index]
          const kpis = (multiPropertyKpis?.tenantRentRollKpis ?? []).find(
            ({ tenant }) => tenant === tenantId,
          )

          const tenantPropertiesUuids = (allPropertiesKpis ?? [])
            .filter((propertyKpis) =>
              propertyKpis.key_date_to_rent_roll_kpis?.[0]?.kpis?.tenant_rent_roll_kpis?.some(
                (rentRollKpi) => rentRollKpi.tenant === tenantId,
              ),
            )
            .map((propertyKpis) => propertyKpis.property_uuid)

          const internalRating = (ratings?.internal ?? []).find((rating) => rating.isActive)

          const today = DateTime.now()

          const validExternalRatingsOrderedByRatingAgencyPriority = (ratings?.external ?? [])
            .filter((externalRating) => {
              const validFromDate = DateTime.fromISO(externalRating.validFrom)
              const validToDate = DateTime.fromISO(externalRating.validTo)
              return validFromDate <= today && validToDate >= today
            })
            .sort(sortExternalRatingsByAgencyPriority)

          const externalRanking = validExternalRatingsOrderedByRatingAgencyPriority[0]

          const tenantUnitHead = relationships?.unitRelationships?.find(
            ({ type }) => type === 'tenant',
          )?.relationships?.[0]?.head?.fullName

          return [
            tenantId,
            {
              id: tenantId,
              sortIndex: index,
              name: businessPartner?.fullName,
              industryCode:
                businessPartner?.industry &&
                `${businessPartner.industry.sectorId} – ${businessPartner.industry.keyDescription}`,
              tenantUnit: tenantUnitHead,
              internalRating: {
                class: internalRating?.ratingClass,
                method: internalRating?.method?.name,
                validFrom: internalRating?.validFrom,
              },
              externalRating: {
                grade: externalRanking?.grade,
                agency: externalRanking?.agency?.name,
                validFrom: externalRanking?.validFrom,
              },
              rentedArea: kpis?.totalAreaSurface,
              annualizedCurrentRent: kpis?.annualizedCurrentRent,
              appreciation: getLatestTextualAssessment(textualAssessments[index]),
              properties: tenantPropertiesUuids.map((propertyUuid) => {
                const tenantProperty = (allProperties?.properties ?? []).find(
                  (property) => property.uuid === propertyUuid,
                )
                const tenantPropertyKpis = allPropertiesKpis
                  .find((propertyKpis) => propertyKpis.property_uuid === propertyUuid)
                  ?.key_date_to_rent_roll_kpis?.[0]?.kpis?.tenant_rent_roll_kpis?.find(
                    (rentRollKpi) => rentRollKpi.tenant === tenantId,
                  )

                return {
                  id: tenantProperty?.id,
                  name: tenantProperty?.description,
                  city: tenantProperty?.address?.cityName,
                  country: tenantProperty?.address?.countryName,
                  rentedArea: tenantPropertyKpis?.total_area_surface,
                  annualizedCurrentRent: tenantPropertyKpis?.annualized_current_rent,
                }
              }),
            },
          ]
        }),
      ),
      sourceRender: propertiesPath &&
        highestRentTenantIds && {
          propertiesPath,
          businessPartnerId: highestRentTenantIds[0],
        },
    }
  }, [
    allProperties,
    allPropertiesKpis,
    textualAssessments,
    businessPartnerRatings,
    businessPartnerRelationships,
    businessPartners,
    highestRentTenantIds,
    isError,
    isLoading,
    multiPropertyKpis,
    sortExternalRatingsByAgencyPriority,
    propertiesPath,
  ])

  return useMemo(
    () => ({
      isLoading,
      isError,
      data,
    }),
    [isLoading, isError, data],
  )
}

export default useTenantDetails
