import { useMutation, useQueryClient } from '@tanstack/react-query'
import camelize from 'camelize'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateDealCollateralInformation = ({ dealUuid, onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()
  const queryClient = useQueryClient()

  const innerOnSuccess = (data) => {
    queryClient.invalidateQueries(['deals', dealUuid, 'collateral'])
    onSuccess?.(camelize(data))
  }

  return useMutation({
    mutationFn: async ({ isStandardPackage, note }) => {
      const { data } = await put({
        path: `/deals/${dealUuid}/collateral`,
        body: { is_standard_package: isStandardPackage, note: note },
      })
      return data
    },
    onSuccess: innerOnSuccess,
    onError,
  })
}

export default useUpdateDealCollateralInformation
