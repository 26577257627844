import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useMassUpdateRequirements = (mutateOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    ({
      requirementIds,
      editedFields: {
        condition,
        refNumber,
        documentType,
        assignee,
        externalAssignee,
        statusCode,
        dueDate,
      },
    }) =>
      post({
        path: '/conditions/external/requirements/bulk-edit',
        body: snakecaseKeys({
          requirementIds,
          editedFields: {
            info: {
              assignee,
              externalAssigneeEmail: externalAssignee,
              documentType,
              refNumber,
              dueDate,
            },
            status: statusCode ? { code: statusCode } : undefined,
            externalConditionId: condition,
          },
        }),
      }),
    mutateOptions,
  )
}

export default useMassUpdateRequirements
