import { Label, WrappingType } from '@fioneer/ui5-webcomponents-react'
import { Fragment, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import DualCurrency from 'components/domains/business-partners/dual-unit-value/DualCurrency'
import DualMeasurementValue from 'components/domains/business-partners/dual-unit-value/DualMeasurementValue'
import styles from 'components/domains/business-partners/tile/general-information/TenancyOverviewTile.module.css'
import Card from 'components/ui/card/Card'
import ClickableCardHeader from 'components/ui/card/ClickableCardHeader'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useRentalUnitsAsTenantInTableStructure } from 'hooks/services/properties/useRentalUnitsAsTenantInTableStructure'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

const TenancyOverviewTile = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.overview.tenancy-tile',
  })
  const { id: businessPartnerId } = useContext(BusinessPartnerContext)
  const {
    data: {
      totalRentedAreaInHeadQuarterMeasurementUnit,
      totalRentedAreaInNonHeadQuarterMeasurementUnit,
      totalRentInHeadQuarterCurrency,
      totalRentInNonHeadQuarterCurrency,
      numberOfRentedProperties,
      totalRentCurrentYearInHeadQuarterCurrency,
      totalRentCurrentYearInNoneHeadQuarterCurrency,
    },
    isLoading,
    isError,
  } = useRentalUnitsAsTenantInTableStructure(businessPartnerId)

  const fieldDefinitions = [
    {
      label: t('total-rented-area'),
      customComponent: totalRentedAreaInHeadQuarterMeasurementUnit && (
        <DualMeasurementValue
          primaryValue={totalRentedAreaInHeadQuarterMeasurementUnit.value}
          primaryMeasurementUnit={totalRentedAreaInHeadQuarterMeasurementUnit.measurementUnit}
          secondaryValue={totalRentedAreaInNonHeadQuarterMeasurementUnit?.value}
          secondaryMeasurementUnit={totalRentedAreaInNonHeadQuarterMeasurementUnit?.measurementUnit}
        />
      ),
    },
    {
      label: t('total-contracted-rent-p-a'),
      customComponent: totalRentInHeadQuarterCurrency && (
        <DualCurrency
          primaryValue={totalRentInHeadQuarterCurrency.number}
          primaryCurrency={totalRentInHeadQuarterCurrency.currency}
          secondaryValue={totalRentInNonHeadQuarterCurrency?.number}
          secondaryCurrency={totalRentInNonHeadQuarterCurrency?.currency}
        />
      ),
    },
    {
      label: t('total-contracted-net-rent'),
      customComponent: totalRentCurrentYearInHeadQuarterCurrency && (
        <DualCurrency
          primaryValue={totalRentCurrentYearInHeadQuarterCurrency.number}
          primaryCurrency={totalRentCurrentYearInHeadQuarterCurrency.currency}
          secondaryValue={totalRentCurrentYearInNoneHeadQuarterCurrency?.number}
          secondaryCurrency={totalRentCurrentYearInNoneHeadQuarterCurrency?.currency}
        />
      ),
    },
    {
      label: t('number-of-properties'),
      value: numberOfRentedProperties,
    },
  ]

  return (
    <Card
      header={
        <ClickableCardHeader
          titleText={t('title')}
          href={`/business-partners/${businessPartnerId}/tenancy`}
        />
      }
      className={styles.tenancyOverviewTile}
    >
      <LoadingStateWrapper isError={isError} isLoading={isLoading}>
        <div className={styles.tenancyOverviewTileDataGrid}>
          {fieldDefinitions
            .filter(({ value, customComponent }) => !!value || !!customComponent)
            .map(({ label, value, customComponent }) => (
              <Fragment key={label}>
                <Label wrappingType={WrappingType.Normal}>{label}</Label>
                {customComponent ? (
                  <>{customComponent}</>
                ) : (
                  <Label
                    wrappingType={WrappingType.Normal}
                    className={styles.tenancyOverviewTileDataGridValueCell}
                  >
                    {value}
                  </Label>
                )}
              </Fragment>
            ))}
        </div>
      </LoadingStateWrapper>
    </Card>
  )
}

export default TenancyOverviewTile
