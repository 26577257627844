import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperAutomaticTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/DecisionPaperAutomaticTile'
import MissingPermissionErrorTileContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/generic/MissingPermissionErrorTileContent'
import ErrorScreen from 'components/ui/screens/ErrorScreen'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'

const AutomaticTileLoadingWrapper = ({
  tileId,
  title: tileTitle,
  tileCode,
  viewConfig,
  Tile,
  isPdfView = false,
  currentVersion,
  showConfirmationForLinks,
  ...tileProps
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.tiles',
  })
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const renderComponent = useCallback(
    () => (
      <Tile
        tileId={tileId}
        tileCode={tileCode}
        isPdfView={isPdfView}
        showConfirmationForLinks={showConfirmationForLinks}
        {...tileProps}
      />
    ),
    [isPdfView, showConfirmationForLinks, tileCode, tileId, tileProps],
  )

  const renderCustomErrorScreen = useCallback(() => {
    if (tileStatus?.error?.isAccessDeniedError) {
      return <MissingPermissionErrorTileContent />
    }
    if (tileStatus?.error?.customErrorMessage) {
      return (
        <ErrorScreen title={t('loading.error')} description={tileStatus.error.customErrorMessage} />
      )
    }
    return <ErrorScreen title={t('loading.error')} description={t('loading.error.description')} />
  }, [tileStatus?.error, t])

  return (
    <DecisionPaperAutomaticTile
      titleText={tileTitle}
      tileId={tileId}
      tileCode={tileCode}
      viewConfig={viewConfig}
      isPdfView={isPdfView}
      currentVersion={currentVersion}
    >
      <LoadingStateWrapper
        isLoading={!tileStatus || tileStatus.isLoading || (!tileStatus.data && !tileStatus.isError)}
        loadingTitle=" "
        loadingDescription={t('loading')}
        isError={!!tileStatus && tileStatus.isError}
        renderContent={renderComponent}
        renderCustomErrorScreen={renderCustomErrorScreen}
      />
    </DecisionPaperAutomaticTile>
  )
}

AutomaticTileLoadingWrapper.propTypes = {
  Tile: PropTypes.func.isRequired,
  tileId: PropTypes.string.isRequired,
  tileCode: PropTypes.string.isRequired,
  viewConfig: PropTypes.object,
  title: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool,
  currentVersion: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
}

export default AutomaticTileLoadingWrapper
