import { FlexBox, MessageStrip, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ALCDataMissingMessageStrip.module.css'

const ALCDataMissingMessageStrip = () => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.shared.alc-data-missing-message-strip',
  })
  return (
    <FlexBox className={styles.messageStrip}>
      <MessageStrip hideCloseButton design={MessageStripDesign.Warning}>
        {t('message')}
      </MessageStrip>
    </FlexBox>
  )
}

ALCDataMissingMessageStrip.propTypes = {}

export default ALCDataMissingMessageStrip
