import { DatePicker } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

const DatePickerWithoutMinWidth = forwardRef(
  ({ fixedWidth, valueState, ...props }, forwardedRef) => {
    const [shouldRerender, setShouldRerender] = useState(false)
    const ref = useRef(null)
    useImperativeHandle(forwardedRef, () => ref.current)

    useEffect(() => {
      const inputInShadowRoot = ref.current?.shadowRoot?.querySelector('ui5-input')
      if (inputInShadowRoot) {
        inputInShadowRoot.style.minWidth = 0
        if (fixedWidth) {
          inputInShadowRoot.style.width = fixedWidth
        }
      }
      if (!shouldRerender) {
        setShouldRerender(true)
      }
    }, [shouldRerender, ref, fixedWidth])

    /**
     * The DatePicker has internal logic to set it's valueState.
     * When you try to set the valueState via props, it only works initially. If you enter another date, it will be overwritten.
     * Setting the valueState via the ref ensures that the valueState is displayed correctly.
     */
    if (ref?.current) {
      ref.current.valueState = valueState
    }

    return <DatePicker ref={ref} {...props} />
  },
)

DatePickerWithoutMinWidth.displayName = 'DatePickerWithoutMinWidth'

DatePickerWithoutMinWidth.propTypes = {
  fixedWidth: PropTypes.string,
  valueState: PropTypes.string,
}

export default DatePickerWithoutMinWidth
