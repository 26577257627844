import isNil from 'lodash.isnil'
import {
  DEFAULT_DISPLAY_HEIGHT_WITHOUT_TEXT,
  DEFAULT_DISPLAY_ROW_HEIGHT,
  DEFAULT_DISPLAY_TEXT_GAP,
  DEFAULT_DISPLAY_TEXT_HEIGHT,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/constants'

const rowHeightBasedOnFeeTypesCell = (
  isSpaceForHQCurrencyNeeded,
  numberOfSubRows,
  isSpacingForLineBreakNeeded = false,
) => {
  let result = DEFAULT_DISPLAY_HEIGHT_WITHOUT_TEXT + numberOfSubRows * DEFAULT_DISPLAY_TEXT_HEIGHT

  if (isSpaceForHQCurrencyNeeded || isSpacingForLineBreakNeeded) {
    result = result * 2
  }

  if (numberOfSubRows > 1) {
    result += (numberOfSubRows - 1) * DEFAULT_DISPLAY_TEXT_GAP

    if (isSpaceForHQCurrencyNeeded || isSpacingForLineBreakNeeded) {
      result -= 2 * DEFAULT_DISPLAY_TEXT_GAP
    }
  }

  return result
}

const rowHeightBasedOnCommitmentCell = (isSpaceForHQCurrencyNeeded, isShareInCommitmentCell) => {
  let result = DEFAULT_DISPLAY_ROW_HEIGHT

  if (isSpaceForHQCurrencyNeeded) {
    result += DEFAULT_DISPLAY_TEXT_HEIGHT
  }

  if (isShareInCommitmentCell) {
    result += DEFAULT_DISPLAY_TEXT_HEIGHT + DEFAULT_DISPLAY_TEXT_GAP
  }

  return result
}

export const calculateSyndicationRowHeight = (
  numberOfSubRows,
  trancheOrSyndicateShare,
  isSpacingForLineBreakNeeded = false,
) => {
  const isSpaceForHQCurrencyNeeded =
    trancheOrSyndicateShare.totalCommitment?.currency !==
      trancheOrSyndicateShare.totalCommitmentHeadquarter?.currency ||
    trancheOrSyndicateShare.amountTotal?.currency !==
      trancheOrSyndicateShare.amountTotalHeadquarter?.currency

  const isShareInCommitmentCell = !isNil(trancheOrSyndicateShare.amountShare)

  return Math.max(
    rowHeightBasedOnFeeTypesCell(
      isSpaceForHQCurrencyNeeded,
      numberOfSubRows,
      isSpacingForLineBreakNeeded,
    ),
    rowHeightBasedOnCommitmentCell(isSpaceForHQCurrencyNeeded, isShareInCommitmentCell),
  )
}
