import isEmpty from 'lodash.isempty'

const requirementDocumentTypeChanged = (
  state,
  { payload: { conditionIndex, requirementIndex, documentType: newDocumentType } },
) => {
  if (
    !state.conditions[conditionIndex] ||
    !state.conditions[conditionIndex].requirements[requirementIndex]
  ) {
    return
  }
  state.conditions[conditionIndex].requirements[requirementIndex].documentType = newDocumentType
  if (newDocumentType !== '') {
    delete state.errorRows[conditionIndex]?.[requirementIndex]?.documentType
    if (isEmpty(state.errorRows[conditionIndex]?.[requirementIndex])) {
      delete state.errorRows[conditionIndex]?.[requirementIndex]
    }
    if (isEmpty(state.errorRows[conditionIndex])) {
      delete state.errorRows[conditionIndex]
    }
  }
}

export default requirementDocumentTypeChanged
