import { AnalyticalCardHeader, NumericSideIndicator } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TILE_VERSION as tileVersion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/AssetValuationOverviewPropertyPortfolioOverviewTableCard.module.css'
import { getAssetValuationOverviewYearOrYears } from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/AssetValuationOverviewYearUnitUtil'
import {
  useAssetValuationOverviewAreaMeasurementUnitFormatter,
  useAssetValuationOverviewCustomizableCurrencyFormatter,
  useAssetValuationOverviewNumberFormatter,
  useAssetValuationOverviewPercentageFormatter,
  useAssetValuationOverviewShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/shared/i18n/useAssetValuationOverviewI18n'

const PropertiesOverviewTableCardHeader = ({
  totalValuation,
  multiPropertyKpis,
  isDecisionPaper = false,
  totalNumberOfProperties,
  version = tileVersion.V1,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.asset-valuation-overview.properties.portfolio.overview.aggregates',
  })
  const { t: tNoPrefix } = useTranslation('decisionPaper')
  const formatCompactNumber = useAssetValuationOverviewNumberFormatter({ notation: 'compact' })
  const formatPercent = useAssetValuationOverviewPercentageFormatter({
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })
  const formatCurrency = useAssetValuationOverviewCustomizableCurrencyFormatter({
    notation: 'compact',
  })
  const formatArea = useAssetValuationOverviewAreaMeasurementUnitFormatter()
  const { format: formatDate } = useAssetValuationOverviewShortDateFormatter()
  const formatNumber = useAssetValuationOverviewNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const formatCompact = (value, unit) => `${formatCompactNumber(value)} ${unit}`

  const showOrHide = (value, element) => (value ? element : <></>)

  const subtitleTextWithPropertyInformation = useMemo(() => {
    if (
      version === tileVersion.V3 &&
      !isNil(totalValuation?.marketValue?.numberOfProperties) &&
      !isNil(totalNumberOfProperties)
    ) {
      return `${t('market-value')}: ${t('property-sum-ratio', {
        contributingProperties: totalValuation?.marketValue?.numberOfProperties,
        totalProperties: totalNumberOfProperties,
      })}`
    }
    return t('market-value')
  }, [t, totalNumberOfProperties, totalValuation?.marketValue?.numberOfProperties, version])

  return (
    <AnalyticalCardHeader
      slot="test-header-analytic"
      titleText={t('title')}
      subtitleText={subtitleTextWithPropertyInformation}
      value={formatCompactNumber(totalValuation?.marketValue?.number)}
      unitOfMeasurement={totalValuation?.marketValue?.currency}
      className={isDecisionPaper ? styles.cardHeaderDecisionPaper : styles.cardHeader}
      id="property-portfolio-analytical-card-header"
    >
      {showOrHide(
        multiPropertyKpis?.keyDate,
        <NumericSideIndicator
          number={formatDate(multiPropertyKpis?.keyDate)}
          titleText={t('key-date')}
        />,
      )}
      {showOrHide(
        totalValuation?.mortgageLendingValue?.number,
        <NumericSideIndicator
          number={formatCurrency(
            totalValuation?.mortgageLendingValue?.number,
            totalValuation?.mortgageLendingValue?.currency,
          )}
          titleText={t('mortgage-lending-value')}
        />,
      )}
      {showOrHide(
        multiPropertyKpis?.totalAreaSurface?.value,
        <NumericSideIndicator
          number={`${formatCompact(
            multiPropertyKpis?.totalAreaSurface?.value,
            formatArea(multiPropertyKpis?.totalAreaSurface?.measurementUnit),
          )}`}
          titleText={t('lettable-area-size')}
        />,
      )}
      {showOrHide(
        multiPropertyKpis?.totalNumberOfUnits,
        <NumericSideIndicator
          number={formatCompactNumber(multiPropertyKpis?.totalNumberOfUnits)}
          titleText={t('lettable-area-no')}
        />,
      )}
      {showOrHide(
        multiPropertyKpis?.letSurface?.percent,
        <NumericSideIndicator
          number={formatPercent(multiPropertyKpis?.letSurface?.percent)}
          titleText={t('rented')}
        />,
      )}
      {showOrHide(
        multiPropertyKpis?.annualizedCurrentRent?.number,
        <NumericSideIndicator
          number={formatCurrency(
            multiPropertyKpis?.annualizedCurrentRent?.number,
            multiPropertyKpis?.annualizedCurrentRent?.currency,
          )}
          titleText={t('current-rental-income')}
        />,
      )}
      {showOrHide(
        multiPropertyKpis?.waultToBreakInYears,
        <NumericSideIndicator
          number={`${formatNumber(
            multiPropertyKpis?.waultToBreakInYears,
          )} ${getAssetValuationOverviewYearOrYears(
            tNoPrefix,
            multiPropertyKpis?.waultToBreakInYears,
          )}`}
          titleText={t('wault-break')}
        />,
      )}
      {showOrHide(
        multiPropertyKpis?.waultToExpiryInYears,
        <NumericSideIndicator
          number={`${formatNumber(
            multiPropertyKpis?.waultToExpiryInYears,
          )} ${getAssetValuationOverviewYearOrYears(
            tNoPrefix,
            multiPropertyKpis?.waultToExpiryInYears,
          )}`}
          titleText={t('wault-expiry')}
        />,
      )}
    </AnalyticalCardHeader>
  )
}
const numberAndCurrencyPropType = () =>
  PropTypes.shape({
    number: PropTypes.number,
    currency: PropTypes.string,
  })
const valueAndUnitPropType = () =>
  PropTypes.shape({
    value: PropTypes.number,
    measurementUnit: PropTypes.string,
  })
PropertiesOverviewTableCardHeader.propTypes = {
  totalValuation: PropTypes.shape({
    marketValue: numberAndCurrencyPropType(),
    mortgageLendingValue: numberAndCurrencyPropType(),
  }),
  multiPropertyKpis: PropTypes.shape({
    annualizedCurrentRent: numberAndCurrencyPropType(),
    keyDate: PropTypes.string,
    totalAreaSurface: valueAndUnitPropType(),
    letSurface: valueAndUnitPropType(),
    totalNumberOfUnits: PropTypes.number,
    waultToBreakInYears: PropTypes.number,
    waultToExpiryInYears: PropTypes.number,
  }),
  isDecisionPaper: PropTypes.bool,
  totalNumberOfProperties: PropTypes.number,
  version: PropTypes.string,
}

export default PropertiesOverviewTableCardHeader
