import { useMemo } from 'react'
import { useConfig } from 'hooks/config/useConfig'
import { useRequest } from 'hooks/services/baseService'
import { accessDtoSchema } from 'hooks/services/need-to-know/needToKnow.schema'
import { needToKnowDisabledAlwaysAllowedResponse } from 'hooks/services/need-to-know/needToKnowDisabledAlwaysAllowedResponse'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const usePropertyNeedToKnow = (propertyUuid) => {
  const { data: config } = useConfig()
  const isNeedToKnowDisabled = config?.needToKnowEnabled === false
  const response = useCamelizedResponse(
    useRequest({
      path: `/need2know/properties/${propertyUuid}/access`,
      translated: false,
      useCache: true,
      keys: ['need2know', 'properties', propertyUuid, 'access'],
      options: {
        enabled: !!propertyUuid && !isNeedToKnowDisabled,
        retry: 1,
      },
    }),
  )
  let result
  if (!isNeedToKnowDisabled) {
    const data = accessDtoSchema.nullish().parse(response.data)
    result = { ...response, data }
  } else {
    result = needToKnowDisabledAlwaysAllowedResponse
  }
  return useMemo(() => result, [result])
}

export default usePropertyNeedToKnow
