import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const REQUEST_RETRIES = 3

const useLeadingAuthorityLevelCalculator = (dealUuid) =>
  useCamelizedResponse(
    useRequest({
      path: `/pbb-custom-service/deals/${dealUuid}/authority-level-calculator/leading`,
      translated: true,
      keys: ['deals', dealUuid, 'authority-level-calculator', 'leading'],
      options: {
        retry: (failureCount, error) => {
          if (isNotFoundError(error) || isMissingPermissionError(error)) {
            return false
          }
          return failureCount < REQUEST_RETRIES
        },
        enabled: !!dealUuid,
      },
    }),
  )

export default useLeadingAuthorityLevelCalculator
