import {
  FlexBox,
  FlexBoxDirection,
  Icon,
  Label,
  ObjectStatus,
  TableRowType,
  Text,
  FlexBoxJustifyContent,
} from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getObjectStatusForEventStatus } from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/api/events/status'
import SortedTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/components/ui/tables/sorted-tables/SortedTable'
import useNavigate from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/components/ui/useNavigate'
import useCtrlOrMetaKeyPressed from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/components/useCtrlOrMetaPressed'
import BusinessEventWithoutTypeTableCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/events/BusinessEventWithoutTypeTableCell'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/events/EventOverviewTable.module.css'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/hooks/i18n/useI18n'
import useDecisionPaperLinkConfirmationDialog from 'hooks/services/business-events-and-tasks/decision-papers/useDecisionPaperLinkConfirmationDialog'
import paths from 'routes/paths'

const EventOverviewTable = ({
  events,
  maximumNumberOfEvents,
  shownColumns,
  noDataText,
  className,
  showConfirmationForLinks = false,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.events.components.event-overview.table',
  })
  const { t: tWithoutPrefix } = useTranslation('decisionPaper')
  const { format } = useShortDateFormatter()
  const navigate = useNavigate()

  const possibleEventColumns = useMemo(
    () => ({
      businessEventWithoutType: {
        title: t('business-event'),
        columnKey: 'businessEventWithoutType',
      },
      creationDate: {
        title: t('creation-date'),
        columnKey: 'creationDate',
        alignment: FlexBoxJustifyContent.End,
      },
      closingDate: {
        title: t('closing-date'),
        columnKey: 'closingDate',
        alignment: FlexBoxJustifyContent.End,
      },
      status: {
        title: t('status'),
        columnKey: 'status',
        demandPopin: true,
        minWidth: 900,
        popinText: t('status'),
      },
      arrow: { title: '', columnKey: 'arrow', sortingDisabled: true, isSelectableForHiding: false },
    }),
    [t],
  )
  const eventColumns = useMemo(
    () => shownColumns.map((columnKey) => possibleEventColumns[columnKey]),
    [possibleEventColumns, shownColumns],
  )

  const displayClosingDate = useCallback(
    (closingDate, currentDueDate) => {
      if (closingDate) return <Text>{format(closingDate)}</Text>

      if (currentDueDate)
        return (
          <FlexBox direction={FlexBoxDirection.Column} className={styles.dateAlignment}>
            <Text>{format(currentDueDate)}</Text>
            <Text>{t('forecasted')}</Text>
          </FlexBox>
        )

      return ''
    },
    [format, t],
  )

  const displayStatus = useCallback(
    (status) => {
      const { translationKey: statusTranslationKey, objectStatus } =
        getObjectStatusForEventStatus(status)
      return (
        <ObjectStatus inverted state={objectStatus}>
          {tWithoutPrefix(statusTranslationKey)}
        </ObjectStatus>
      )
    },
    [tWithoutPrefix],
  )
  const tableData = useMemo(
    () =>
      events.map(
        ({ id, displayId, status, info: { name, currentDueDate, creationDate, closingDate } }) => ({
          rowKey: `events-table-${id}`,
          rowProperties: {
            type: TableRowType.Active,
            'data-event-id': id,
          },
          businessEventWithoutType: {
            cellComponent: (
              <BusinessEventWithoutTypeTableCell
                eventName={name}
                eventId={id}
                eventDisplayId={displayId}
                showConfirmationForLinks={showConfirmationForLinks}
              />
            ),
          },
          creationDate: {
            cellComponent: (
              <div className={styles.dateAlignment}>
                <Text>{creationDate ? format(creationDate) : ''}</Text>
              </div>
            ),
          },
          closingDate: {
            cellComponent: displayClosingDate(closingDate, currentDueDate),
          },
          status: {
            cellComponent: <Label>{displayStatus(status)}</Label>,
          },
          arrow: {
            cellComponent: <Icon name="slim-arrow-right" />,
          },
        }),
      ),
    [displayClosingDate, displayStatus, events, format, showConfirmationForLinks],
  )

  const onConfirm = useCallback((eventId) => {
    const eventHref = `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}`

    window.open(eventHref, '_blank')
  }, [])

  const { showConfirmationDialog } = useDecisionPaperLinkConfirmationDialog({
    onConfirm: onConfirm,
  })

  const isCtrlOrMetaPressed = useCtrlOrMetaKeyPressed()

  const handleRowClick = (url, target) => {
    navigate(url, { target: target ? '_blank' : undefined })
  }

  const additionalTableProperties = {
    onRowClick: ({ detail: { row: clickedTableRow } }) => {
      const eventId = clickedTableRow.getAttribute('data-event-id')

      showConfirmationForLinks
        ? showConfirmationDialog(eventId)
        : handleRowClick(
            `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}`,
            isCtrlOrMetaPressed,
          )
    },
  }

  const toolbarConfigWithoutActions = useMemo(
    () => ({
      title: t('toolbar.title'),
      showColumnSelection: false,
    }),
    [t],
  )

  return (
    <div className={className}>
      <SortedTable
        columnDefinitions={eventColumns}
        tableData={tableData}
        additionalTableProperties={additionalTableProperties}
        toolbarConfig={toolbarConfigWithoutActions}
        noDataText={noDataText}
        paginationConfig={{
          totalNumberOfItems: maximumNumberOfEvents,
        }}
      />
    </div>
  )
}

EventOverviewTable.propTypes = {
  events: PropType.array.isRequired,
  maximumNumberOfEvents: PropType.number.isRequired,
  shownColumns: PropType.arrayOf(
    PropType.oneOf(['businessEventWithoutType', 'creationDate', 'closingDate', 'status', 'arrow']),
  ),
  noDataText: PropType.string.isRequired,
  className: PropType.string,
  showConfirmationForLinks: PropType.bool,
}

export default EventOverviewTable
