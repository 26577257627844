import { useMemo } from 'react'
import { isNotFoundError } from 'api/requests'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useCurrentDecisionPaperByIdList from 'hooks/services/business-events-and-tasks/decision-papers/useCurrentDecisionPaperByIdList'
import useDecisionStagesByIdList from 'hooks/services/business-events-and-tasks/decision-process/stages/useDecisionStagesByIdList'
import useEventsByIdList from 'hooks/services/business-events-and-tasks/events/useEventsByIdList'
import useStaffMemberByIds from 'hooks/services/business-partners/staff-members/useStaffMemberByIds'
import useRiskIndicatorGroupById from 'hooks/services/risk-monitoring/useRiskIndicatorGroupById'
import useRiskIndicators from 'hooks/services/risk-monitoring/useRiskIndicators'

const EMPTY_PROPERTY_STRING = '-'

const useRiskMonitoringBusinessEvents = (
  { id: businessEventId, entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: riskIndicatorsData,
    isFetching: isRiskIndicatorsFetching,
    isError: isRiskIndicatorsError,
    error: riskIndicatorsError,
  } = useRiskIndicators({
    businessEventId,
    businessPartnerId,
  })

  const group = riskIndicatorsData?.riskIndicatorGroups?.[0]

  const {
    data: riskIndicatorGroupData,
    isFetching: isRiskIndicatorGroupFetching,
    isError: isRiskIndicatorGroupError,
    error: riskIndicatorGroupError,
  } = useRiskIndicatorGroupById(businessPartnerId, group?.groupId)

  const groupEventIds = riskIndicatorGroupData?.events?.map((event) => event.id)

  const {
    data: eventsData,
    isFetching: isEventsFetching,
    isError: isEventsError,
  } = useEventsByIdList(groupEventIds)

  const assigneeIds = eventsData?.map((event) => event?.info?.assignee)

  const {
    data: assigneesData,
    isFetching: isAssigneesFetching,
    isError: isAssigneesError,
  } = useStaffMemberByIds(assigneeIds)

  const decisionPapersData = useCurrentDecisionPaperByIdList({ eventIds: groupEventIds }) ?? {}
  const decisionStagesData = useDecisionStagesByIdList({ eventIds: groupEventIds }) ?? {}

  const isDecisionPapersFetching = decisionPapersData.some((response) => response.isFetching)
  const isDecisionPapersError = decisionPapersData.some(
    (response) => !!response.isError && !isNotFoundError(response?.error),
  )
  const isDecisionStagesFetching = decisionStagesData.some(
    (response) => response.isFetching && !response.isFetched,
  )
  const isDecisionStagesError = decisionStagesData.some((response) => response.isError)

  const riskIndicatorGroupEventsData = eventsData?.map((event) => {
    const { fullName: assigneeName } =
      assigneesData?.find((assignee) => assignee.id === event?.info?.assignee) ?? {}
    const {
      data: { synchronizationStatus } = {},
      isFetching: isDecisionPaperFetching,
      isError: isDecisionPaperError,
      error: decisionPaperError,
    } = decisionPapersData?.find((decisionPaper) => decisionPaper?.data?.eventId === event?.id) ??
    {}

    const {
      data: { decisionStages: decisionStageData } = {},
      isFetching: isDecisionStageFetching,
      isError: isDecisionStageError,
      error: decisionStageError,
    } = decisionStagesData?.find((decisionStage) => decisionStage?.data?.eventId === event?.id) ??
    {}

    const decisionDate =
      decisionStageData?.reduce(
        (a, b) => (new Date(a?.info?.decisionDate) > new Date(b?.info?.decisionDate) ? a : b),
        0,
      )?.info?.decisionDate ?? EMPTY_PROPERTY_STRING

    return {
      businessEvent: { id: event?.id, name: event?.info?.name, displayId: event?.displayId },
      creationDate: event?.info?.creationDate,
      dueDate: event?.info?.currentDueDate ?? EMPTY_PROPERTY_STRING,
      assignee: { name: assigneeName, isLoading: isAssigneesFetching, isError: isAssigneesError },
      businessEventStatus: event?.status,
      decisionDate: {
        date: decisionDate,
        isLoading: isDecisionStageFetching,
        isError: isNotFoundError(decisionStageError ?? {}) ? false : isDecisionStageError,
      },
      decisionStages: decisionStageData ?? [],
      decisionPaperStatus: {
        status: synchronizationStatus,
        isLoading: isDecisionPaperFetching,
        isError: isNotFoundError(decisionPaperError ?? {}) ? false : isDecisionPaperError,
      },
    }
  })

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isRiskIndicatorsFetching,
      isRiskIndicatorGroupFetching,
      isEventsFetching,
      isAssigneesFetching,
      isDecisionPapersFetching,
      isDecisionStagesFetching,
    ],
    errorValues: [
      isRiskIndicatorsError,
      isRiskIndicatorGroupError,
      isEventsError,
      isAssigneesError,
      isDecisionPapersError,
      isDecisionStagesError,
    ],
    errorDetails: [riskIndicatorsError, riskIndicatorGroupError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }
    return {
      isLoading: isSomeValueLoading,
      isError: isSomeValueError,
      error,
      data: {
        riskIndicatorGroupEventsData,
        sourceRender: {
          businessPartnerId,
          riskIndicatorGroupId: group?.groupDisplayId ?? '',
        },
      },
    }
  }, [
    businessPartnerId,
    error,
    group?.groupDisplayId,
    isSomeValueError,
    isSomeValueLoading,
    riskIndicatorGroupEventsData,
  ])
}

export default useRiskMonitoringBusinessEvents
