import {
  Bar,
  BarDesign,
  Button,
  ButtonDesign,
  Dialog,
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Title,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'

const PropertyMonitoringSettingsConfirmationDialog = ({
  handleConfirmation,
  handleConfirmationCancel,
  isConfirmationDialogOpen,
  setIsConfirmationDialogOpen,
}) => {
  const { t } = useTranslation()
  return createPortal(
    <Dialog
      footer={
        <Bar
          design={BarDesign.Footer}
          endContent={
            <>
              <Button design={ButtonDesign.Emphasized} onClick={handleConfirmation}>
                {t('buttons.save')}
              </Button>
              <Button onClick={handleConfirmationCancel}>{t('buttons.cancel')}</Button>
            </>
          }
        />
      }
      header={
        <FlexBox
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
          justifyContent={FlexBoxJustifyContent.Start}
          fitContainer
        >
          <Title level="H2">{t('dialog.monitoring-settings-confirmation.title')}</Title>
        </FlexBox>
      }
      // needed when exiting via "esc" or click on the screen somewhere
      onAfterClose={() => {
        setIsConfirmationDialogOpen(false)
      }}
      open={isConfirmationDialogOpen}
    >
      <FlexBox direction={FlexBoxDirection.Column} style={{ width: '500px' }}>
        <Label style={{ paddingBottom: '16px' }} wrapping-type="Normal">
          {t('dialog.monitoring-settings-confirmation.message')}
        </Label>
      </FlexBox>
    </Dialog>,
    document.body,
  )
}

PropertyMonitoringSettingsConfirmationDialog.propTypes = {
  handleConfirmation: PropTypes.func.isRequired,
  handleConfirmationCancel: PropTypes.func.isRequired,
  isConfirmationDialogOpen: PropTypes.bool.isRequired,
  setIsConfirmationDialogOpen: PropTypes.func.isRequired,
}

export default PropertyMonitoringSettingsConfirmationDialog
