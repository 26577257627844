import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionStageTypes } from 'api/decision-process/decisionProcessApi'
import LoadingComboBox from 'components/ui/combobox/LoadingComboBox'
import useDecisionStageTemplates from 'hooks/services/business-events-and-tasks/decision-process/templates/useDecisionStageTemplates'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

export const noSelectionCode = 'decision-process-empty-selection-value-code'

const DecisionProcessStageSelect = ({ value = noSelectionCode, onChange }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process',
  })

  const {
    info: { code: eventCode },
  } = useContext(BusinessEventsAndTasksContext).event

  const {
    isLoading,
    isError,
    data: decisionStageTemplatesData,
  } = useDecisionStageTemplates({ eventCode })

  const getStageDisplayName = useCallback(
    (decisionType, stageName) => {
      switch (decisionType) {
        case decisionStageTypes.attendance:
          return t('decision-type.attendance.name', { name: stageName })
        case decisionStageTypes.circulation:
          return t('decision-type.circulation.name', { name: stageName })
        default:
          return stageName
      }
    },
    [t],
  )

  const getUniqueStages = useCallback(() => {
    if (isLoading || isError) {
      return { isLoading, isError }
    }
    const { stageTemplates } = decisionStageTemplatesData

    const result = {
      isLoading: false,
      isError: false,
      data: [
        ...stageTemplates.map(({ id, name, decisionType }) => ({
          code: id,
          displayName: getStageDisplayName(decisionType, name),
        })),
      ],
    }
    return result
  }, [isLoading, isError, decisionStageTemplatesData, getStageDisplayName])

  const onClearButtonClicked = useCallback(() => {
    onChange({ code: noSelectionCode })
  }, [onChange])

  const displayName = getStageDisplayName(value)
  const comboboxValue = { code: value, displayName }

  return (
    <LoadingComboBox
      id="decision-process-stage-select"
      value={comboboxValue}
      onChange={onChange}
      useLoader={getUniqueStages}
      loadingText={t('stage-templates.loading.description')}
      errorText={t('stage-templates.loading.error.description')}
      placeholder={t('create-stage.select.placeholder')}
      icon={
        value &&
        value !== noSelectionCode && (
          <Button icon="decline" design={ButtonDesign.Transparent} onClick={onClearButtonClicked} />
        )
      }
    />
  )
}

DecisionProcessStageSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default DecisionProcessStageSelect
