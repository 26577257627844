import i18n from 'i18next'
import RequestError from 'api/RequestError'
import { getConfig } from 'api/config'
import { limitGetRequest } from 'api/pLimitFactory'

export const responseCodes = Object.freeze({
  OK: 200,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  GONE: 410,
  UNAUTHORIZED: 401,
  METHOD_NOT_ALLOWED: 405,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
})

const sanitizeFileName = (fileName) => {
  const name = fileName.slice(1, -1)
  return name.replace(/^.*[\\/]/, '')
}

/**
 * Helper method for obtaining the filename sent in the 'content-disposition' header.
 * @param headers response headers
 * @returns {String} filename in the 'content-disposition' header or an empty string.
 */
const extractFileNameFromHeaders = (headers) => {
  const sContentDisp = headers.get('content-disposition')
  if (!sContentDisp) {
    return ''
  }

  const parts = sContentDisp.split(';').map((x) => x.trim())
  const fileName = parts.find((x) => x.startsWith('filename'))
  if (fileName && fileName.split('=').length === 2) {
    return sanitizeFileName(fileName.split('=')[1].trim())
  } else {
    return ''
  }
}

const parseResponseBody = async (response) => {
  const responseContentType = response.headers.get('content-type')
  return responseContentType?.includes('application/json')
    ? { data: await response.json(), status: response.status }
    : {
        data: new File([await response.blob()], extractFileNameFromHeaders(response.headers), {
          type: responseContentType,
        }),
        status: response.status,
        fileName: extractFileNameFromHeaders(response.headers),
      }
}

/**
 * Executes fetch request for given path and options.
 * @param {object} params
 * @param {string} [params.accessToken] the access token to use for authorization
 * @param {string} params.path the request path
 * @param {object} [params.options] fetch request options
 * @param {Record<string, string>} [params.headers] the headers to use
 * @param {string} [params.contentType] the contentType to use, defaults to 'application/json'
 * @param {boolean} [params.isFullPath]
 */
const executeFetch = async ({
  accessToken,
  path,
  options,
  headers,
  contentType,
  isFullPath = false,
  language = i18n.language,
}) => {
  const { baseUrl } = await getConfig()
  const timeZoneOffset = new Date().getTimezoneOffset()
  const requestHeaders = {
    Accept: 'application/json',
    'Accept-Language': language,
    'x-timezone-offset': timeZoneOffset,
    ...headers,
  }
  if (accessToken) {
    requestHeaders['Authorization'] = `Bearer ${accessToken}`
  }
  if (contentType) {
    requestHeaders['Content-Type'] = contentType
  }
  if (headers?.['Content-Type']) {
    requestHeaders['Content-Type'] = headers['Content-Type']
  }
  const requestOptions = {
    headers: requestHeaders,
    redirect: 'error',
    mode: window.location.origin.includes(baseUrl) ? 'same-origin' : 'cors',
    referrerPolicy: 'no-referrer',
    ...options,
  }
  const url = isFullPath ? path : `${baseUrl}${path}`
  const response = await fetch(url, requestOptions)
  if (response.ok) {
    if (response.status !== responseCodes.NO_CONTENT) {
      return parseResponseBody(response)
    }
    return { data: {} }
  }

  throw new RequestError(`Request to '${url}' failed`, response)
}

/**
 * Intercepts the fetch API to use an updated access token.
 *
 * Underlying problem: When the access token changes (silent token refresh, see
 * AuthenticationWrapper), cached requests executed before the token renewal
 * will continue using an old access token.
 * Because of that every refetch will result in being unauthorized.
 * Adding the access token to the query cache keys is NOT an alternative
 * because a silent token refresh would result in the whole page beeing reloaded.
 * The user would loose all inputs.
 *
 * The interceptor can be called multiple times with individual access tokens.
 * Only the newest access token will be used to set the Authorization header.
 * Also there is an option to disable the interceptor when the user is signed out
 * (parameter userSignedOut)
 *
 * @param {object} params
 * @param {string} [params.accessToken] the updated access token
 * @param {string} [params.userSignedOut] disables the interceptor when set to true
 */
export const fetchAPIRequestInterceptor = ({ accessToken, userSignedOut = false } = {}) => {
  if (!accessToken && !userSignedOut) return

  const { fetch: originalFetch } = window
  window.fetch = async (...args) => {
    const [resource, options, hasAlreadyBeenIntercepted] = args
    if (!hasAlreadyBeenIntercepted && !userSignedOut) {
      options.headers['Authorization'] = `Bearer ${accessToken}`
    }
    const fetchHasBeenIntercepted = true
    const response = await originalFetch(resource, options, fetchHasBeenIntercepted)
    return response
  }
}

/**
 * Performs an authenticated GET request to the backend service API for the
 * specified path with the currently selected locale as the accept-language
 * header. Overrides for current app state are provided but not required.
 * @param {object} params
 * @param {string} [params.accessToken] the JWT to authenticate the request with
 * @param {string} params.path the resource path (e.g. 'deal-types')
 * @param {Record<string, string>} [params.headers] the headers to use
 * @param {boolean} [params.isThrottled] whether the request should be throttled
 * @param {boolean} [params.isFullPath] whether the path is a full URL
 * @param {string} [params.language] the language to use for the request
 */
const get = ({
  accessToken,
  path,
  headers,
  isThrottled = false,
  isFullPath = false,
  language = i18n.language,
}) => {
  if (!isThrottled) {
    return executeFetch({
      accessToken,
      path,
      options: { method: 'GET' },
      headers,
      isFullPath,
      language,
    })
  }

  return limitGetRequest(() =>
    executeFetch({ accessToken, path, options: { method: 'GET' }, headers, isFullPath, language }),
  )
}

/**
 * Performs an authenticated POST request to the backend service API for the
 * specified path and body and the currently selected locale as the accept-language
 * header. Overrides for current app state are provided but not required.
 * @param {object} params
 * @param {string} [params.accessToken] the JWT to authenticate the request with
 * @param {string} params.path the resource path (e.g. 'deals')
 * @param {any} [params.body] the object to be sent in the request payload
 * @param {Record<string, string>} [params.headers] the headers to use
 * @param {boolean} [params.isJson]
 */
const post = ({ accessToken, path, body, headers, isJson = true }) => {
  const options = {
    method: 'POST',
    body: isJson ? JSON.stringify(body) : body,
  }
  const contentType = 'application/json'
  return executeFetch({ accessToken, path, options, contentType, headers })
}

/**
 * Performs an authenticated POST request to the backend service API for the
 * specified path and body and the currently selected locale as the accept-language
 * header. Overrides for current app state are provided but not required. This is specifically used
 * for example a file upload where we need to set no content type because the browser automatically
 * sets the content type to 'multipart/form-data' and boundaries for it
 * @param accessToken the JWT to authenticate the request with
 * @param path the resource path (e.g. 'deals')
 * @param body the object to be sent in the request payload
 */
const postFileList = ({ accessToken, path, body }) => {
  const options = {
    method: 'POST',
    body,
  }
  return executeFetch({ accessToken, path, options })
}

/**
 * Performs an authenticated PATCH request to the backend service API for the
 * specified path and body and the currently selected locale as the accept-language
 * header. Overrides for current app state are provided but not required.
 * @param accessToken the JWT to authenticate the request with
 * @param path the resource path (e.g. 'deals')
 * @param body the object to be sent in the request payload (see http://jsonpatch.com for the format)
 * @param headers the headers to use
 */
const patch = ({ accessToken, path, body, headers }) => {
  const options = {
    method: 'PATCH',
    body: JSON.stringify(body),
  }
  const contentType = 'application/merge-patch+json'
  return executeFetch({ accessToken, path, options, headers, contentType })
}

/**
 * Performs an authenticated PUT request to the backend service API for the
 * specified path and body and the currently selected locale as the accept-language
 * header. Overrides for current app state are provided but not required.
 * @param {object} params
 * @param {string} [params.accessToken] the JWT to authenticate the request with
 * @param {string} params.path the resource path (e.g. 'deals')
 * @param {any} params.body the object to be sent in the request payload
 * @param {Record<string, string>} [params.headers] the headers to use
 */
const put = ({ accessToken, path, body, headers }) => {
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
  }
  const contentType = 'application/json'
  return executeFetch({ accessToken, path, options, contentType, headers })
}

/**
 * Performs an authenticated DELETE request to the backend service API for the
 * specified path and body and the currently selected locale as the accept-language
 * header. Overrides for current app state are provided but not required.
 * @param accessToken the JWT to authenticate the request with
 * @param path the resource path (e.g. 'deals')
 * @param body the object to be sent in the request payload
 * @param headers the headers to use
 * @returns {Promise<Response>} the JSON response
 */
const _delete = ({ accessToken, path, body, headers }) => {
  const options = {
    method: 'DELETE',
    body: body ? JSON.stringify(body) : undefined,
  }
  const contentType = body ? 'application/json' : undefined
  return executeFetch({ accessToken, path, options, contentType, headers })
}

// create a request API to allow for easily changing/modifying centralized
// request preparation by request type
const Request = {
  get,
  post,
  postFileList,
  patch,
  put,
  delete: _delete,
}

export default Request

export const isNotFoundError = (error) => error.response?.status === responseCodes.NOT_FOUND
export const isMissingPermissionError = (error) =>
  error.response?.status === responseCodes.FORBIDDEN
export const isGatewayTimeoutError = (error) =>
  error.response?.status === responseCodes.GATEWAY_TIMEOUT
export const isInternalServerError = (error) =>
  error.response?.status === responseCodes.INTERNAL_SERVER_ERROR
export const isConflictError = (error) => error.response?.status === responseCodes.CONFLICT
export const isBadRequestError = (error) => error.response?.status === responseCodes.BAD_REQUEST
export const isUnauthorizedError = (error) => error.response?.status === responseCodes.UNAUTHORIZED
export const isNoContentError = (error) => error.response?.status === responseCodes.NO_CONTENT
export const isGoneError = (error) => error.response?.status === responseCodes.GONE
export const isBadGatewayError = (error) => error.response?.status === responseCodes.BAD_GATEWAY
export const isServiceUnavailableError = (error) =>
  error.response?.status === responseCodes.SERVICE_UNAVAILABLE
export const isRequestTimeoutError = (error) =>
  error.response?.status === responseCodes.REQUEST_TIMEOUT
export const isMethodNotAllowedError = (error) =>
  error.response?.status === responseCodes.METHOD_NOT_ALLOWED
