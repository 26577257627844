// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* stylelint-disable indentation */
.TeJkICVsvL8absa9XN2g {
    --sapTile_TitleTextColor: var(--sapButton_Lite_TextColor);
}

.GeFcNSdpIo5sGJGP3Cd0 {
    margin-right: 8px;
}

.rfA_dSUeeeudOTo3wAOc {
    margin: var(--sapUiSmallSpace) var(--sapUiSmallSpace) 0;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/card/CardHeaderWithEditMode.module.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;IACI,yDAAyD;AAC7D;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,uDAAuD;AAC3D","sourcesContent":["/* stylelint-disable indentation */\n.interactiveCardHeader {\n    --sapTile_TitleTextColor: var(--sapButton_Lite_TextColor);\n}\n\n.toggle-edit-mode-save {\n    margin-right: 8px;\n}\n\n.messageStripContainer {\n    margin: var(--sapUiSmallSpace) var(--sapUiSmallSpace) 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"interactiveCardHeader": `TeJkICVsvL8absa9XN2g`,
	"toggle-edit-mode-save": `GeFcNSdpIo5sGJGP3Cd0`,
	"messageStripContainer": `rfA_dSUeeeudOTo3wAOc`
};
export default ___CSS_LOADER_EXPORT___;
