import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useCreatePropertyExternalId = ({ onSuccess = () => {}, onError = () => {} }) => {
  const isValidExternalIdIdentifier = (createdExternalId) =>
    createdExternalId?.id && createdExternalId?.type
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ propertyUuid, createdExternalId }) => {
      if (isValidExternalIdIdentifier(createdExternalId)) {
        const { data } = await post({
          path: `/properties/${propertyUuid}/external-ids`,
          body: {
            id: createdExternalId.id,
            type: createdExternalId.type,
          },
        })
        return data
      }
      return null
    },
    { onSuccess, onError },
  )
}
