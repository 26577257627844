import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useEditDocumentGeneralInformation = (options) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(async ({ documentId, name, type, keyDate }) => {
    const { data } = await patch({
      path: `/documents/${documentId}`,
      body: {
        document_name: name,
        document_type: type,
        key_date: keyDate,
      },
    })
    return camelize(data)
  }, options)
}
