import { ValueState } from '@fioneer/ui5-webcomponents-react'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'

export const eventStatus = {
  drafted: 'DRAFTED',
  ongoing: 'ONGOING',
  completed: 'COMPLETED',
  aborted: 'ABORTED',
}

export const taskStatus = {
  created: 'CREATED',
  ongoing: 'ONGOING',
  completed: 'COMPLETED',
  aborted: 'ABORTED',
}

export const entityTypes = {
  deal: cwpEntityTypes.DEAL,
  property: cwpEntityTypes.PROPERTY,
  businesspartner: cwpEntityTypes.BUSINESS_PARTNER,
}

const objectStatusForEventStatus = {
  [eventStatus.drafted]: {
    translationKey: 'events.status.drafted',
    objectStatus: ValueState.None,
  },
  [eventStatus.ongoing]: {
    translationKey: 'events.status.ongoing',
    objectStatus: ValueState.Information,
  },
  [eventStatus.completed]: {
    translationKey: 'events.status.completed',
    objectStatus: ValueState.Success,
  },
  [eventStatus.aborted]: {
    translationKey: 'events.status.aborted',
    objectStatus: ValueState.Error,
  },
  default: {
    translationKey: 'events.status.unknown',
    objectStatus: ValueState.None,
  },
}

const objectStatusForTaskStatus = {
  [taskStatus.created]: {
    translationKey: 'tasks.status.created',
    objectStatus: ValueState.None,
  },
  [taskStatus.ongoing]: {
    translationKey: 'tasks.status.ongoing',
    objectStatus: ValueState.Information,
  },
  [taskStatus.completed]: {
    translationKey: 'tasks.status.completed',
    objectStatus: ValueState.Success,
  },
  [taskStatus.aborted]: {
    translationKey: 'tasks.status.aborted',
    objectStatus: ValueState.Error,
  },
  default: {
    translationKey: 'tasks.status.unknown',
    objectStatus: ValueState.None,
  },
}

export const getObjectStatusForEventStatus = (status) =>
  objectStatusForEventStatus[status] !== undefined
    ? objectStatusForEventStatus[status]
    : objectStatusForEventStatus.default

export const getObjectStatusForTaskStatus = (status) =>
  objectStatusForTaskStatus[status] !== undefined
    ? objectStatusForTaskStatus[status]
    : objectStatusForTaskStatus.default

export const isEventClosed = (status) =>
  status === eventStatus.completed || status === eventStatus.aborted

export const isTaskFinalized = (status) =>
  status === taskStatus.completed || status === taskStatus.cancelled
