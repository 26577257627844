import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react/dist'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DecisonPaperFrozenVersionPopover from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperFrozenVersionPopover'

const buttonId = 'frozenButtonId'

const DecisionPaperFrozenVersionButton = ({
  versionNumber,
  majorVersionLastSyncedOn,
  minorVersionLastSyncedOn,
  lastFrozenOn,
  versions,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper',
  })
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const versionName = t('working-version-popover.header-text-other-versions', { versionNumber })

  return (
    <>
      <Button
        id={buttonId}
        design={ButtonDesign.Transparent}
        onClick={() => setIsPopoverOpen(true)}
        icon="slim-arrow-down"
        iconEnd
      >
        {versionName}
      </Button>
      <DecisonPaperFrozenVersionPopover
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        versionName={versionName}
        lastFrozenOn={lastFrozenOn}
        majorVersionLastSyncedOn={majorVersionLastSyncedOn}
        minorVersionLastSyncedOn={minorVersionLastSyncedOn}
        openerId={buttonId}
        versions={versions}
        versionNumber={versionNumber}
      />
    </>
  )
}

DecisionPaperFrozenVersionButton.propTypes = {
  versionNumber: PropTypes.string.isRequired,
  majorVersionLastSyncedOn: PropTypes.string,
  minorVersionLastSyncedOn: PropTypes.string,
  lastFrozenOn: PropTypes.string.isRequired,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      version: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default DecisionPaperFrozenVersionButton
