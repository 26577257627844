import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useEditDecisionStageMinutesVoter = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(
    ({ decisionStageId, eventId, voterId, id, voteTypeCode, voteStatusCode }) =>
      patch({
        path: `/events/${eventId}/decision-stages/${decisionStageId}/minutes/voters/${id}`,
        body: {
          user_id: voterId,
          vote_type: voteTypeCode
            ? {
                code: voteTypeCode,
              }
            : undefined,
          vote_option: voteStatusCode,
        },
      }),
    mutationOptions,
  )
}

export default useEditDecisionStageMinutesVoter
