import { Button, FlexBox } from '@fioneer/ui5-webcomponents-react'
import { createSelector } from '@reduxjs/toolkit'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/SectionHeader.module.css'
import { collapseTiles, expandTiles } from 'redux/slices/decision-paper/tilesOverviewSlice'

const areAllTilesCollapsedSelector = createSelector(
  [(state) => state.decisionPaper.tilesOverview.tiles, (_, tileIds) => tileIds],
  (tiles, tileIds) => tileIds.filter((tileId) => tiles[tileId]?.isExpanded).length === 0,
)

const SectionHeader = ({ tileIds }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.sections.header',
  })
  const dispatch = useDispatch()

  const areAllTilesCollapsed = useSelector((state) => areAllTilesCollapsedSelector(state, tileIds))

  const handleExpandAllClick = useCallback(() => {
    dispatch(expandTiles(tileIds))
  }, [tileIds, dispatch])

  const expandAllButton = useMemo(
    () => <Button onClick={handleExpandAllClick}>{t('buttons.expand-all')}</Button>,
    [handleExpandAllClick, t],
  )

  const handleCollapseAllClick = useCallback(() => {
    dispatch(collapseTiles(tileIds))
  }, [tileIds, dispatch])

  const collapseAllButton = useMemo(
    () => <Button onClick={handleCollapseAllClick}>{t('buttons.collapse-all')}</Button>,
    [handleCollapseAllClick, t],
  )

  const expansionStateButton = areAllTilesCollapsed ? expandAllButton : collapseAllButton

  return <FlexBox className={styles.header}>{expansionStateButton}</FlexBox>
}

SectionHeader.propTypes = {
  tileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default SectionHeader
