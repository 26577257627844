import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Entity from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/data/Entity'
import BooleanCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/BooleanCell'
import DateCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/DateCell'
import MoneyCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/MoneyCell'
import NumberCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/NumberCell'
import PercentageCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/PercentageCell'
import TextCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/TextCell'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/RiskIndicatorsDetailed.module.css'
import CollapsibleTextCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/risk-monitoring/CollapsibleTextCell'
import StatusWithDateCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/risk-monitoring/StatusWithDateCell'
import { RISK_INDICATOR_VIOLATION_TYPES } from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/utils/constants'
import resolveTypeCodeForStatus from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/utils/resolveTypeCodeForStatus'
import {
  REFERENCE_TYPE_CODES,
  RISK_INDICATOR_TYPE_CODE_CATEGORIES,
  VALUE_TYPE_CODES,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/utils/typeCodes'
import Labeled from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/data/Labeled'
import paths from 'routes/paths'

const renderValueCellWithThreshold = ({
  valueType,
  value,
  threshold,
  violationType,
  isLoading = false,
  t,
}) => {
  let renderComponent = () => {}
  switch (valueType) {
    case VALUE_TYPE_CODES.PERCENTAGE:
      renderComponent = (cellValue) => <PercentageCell value={cellValue} isLoading={isLoading} />
      break
    case VALUE_TYPE_CODES.DATE:
    case VALUE_TYPE_CODES.DATE_TIME:
      renderComponent = (cellValue) => <DateCell date={cellValue} isLoading={isLoading} />
      break
    case VALUE_TYPE_CODES.ENUM:
      renderComponent = (cellValue) => <TextCell text={cellValue} isLoading={isLoading} />
      break
    case VALUE_TYPE_CODES.STRING:
      renderComponent = (cellValue) => <TextCell text={cellValue} isLoading={isLoading} />
      break
    case VALUE_TYPE_CODES.NUMBER:
      renderComponent = (cellValue) => <NumberCell number={cellValue} isLoading={isLoading} />
      break
    case VALUE_TYPE_CODES.INTEGER:
      renderComponent = (cellValue) => (
        <NumberCell
          number={cellValue}
          options={{ minimumFractionDigits: 0, maximumFractionDigits: 0 }}
          isLoading={isLoading}
        />
      )
      break
    case VALUE_TYPE_CODES.MEASUREMENT:
      renderComponent = (cellValue) => (
        <NumberCell number={cellValue.number} unit={cellValue.unit} isLoading={isLoading} />
      )
      break
    case VALUE_TYPE_CODES.BOOLEAN:
      renderComponent = (cellValue) => <BooleanCell value={cellValue} isLoading={isLoading} />
      break
    case VALUE_TYPE_CODES.CURRENCY:
      renderComponent = (cellValue) => <MoneyCell primary={cellValue} isLoading={isLoading} />
      break
  }

  const renderThreshold = () => {
    if (violationType === RISK_INDICATOR_VIOLATION_TYPES.CRITICAL_CHANGE) {
      return <Text className={styles.criticalChangeLabel}>{t('label.criticalChange')}</Text>
    } else if (!isNil(threshold) || violationType === RISK_INDICATOR_VIOLATION_TYPES.THRESHOLD) {
      return (
        <Labeled label={t('label.threshold')} showColon>
          {renderComponent(threshold)}
        </Labeled>
      )
    }
  }

  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      justifyContent={FlexBoxJustifyContent.End}
      alignItems={FlexBoxAlignItems.End}
      className={styles.valueFlexBox}
    >
      {renderComponent(value)}
      {renderThreshold()}
    </FlexBox>
  )
}

/**
 * @param {object} params
 * @param {string} params.typeCode
 * @param {any} [params.typeCodes]
 * @param {string?} [params.shortText]
 */
const mapCriticalityTypeCodeToStatus = ({ typeCode, shortText, typeCodes }) => {
  const status = resolveTypeCodeForStatus({
    typeCodes: {
      [RISK_INDICATOR_TYPE_CODE_CATEGORIES.CRITICALITY]: typeCodes,
    },
    category: RISK_INDICATOR_TYPE_CODE_CATEGORIES.CRITICALITY,
    cellTypeCode: typeCode,
  })

  return {
    statusState: status?.statusState ?? '',
    statusValue: status?.statusValue ?? shortText ?? '',
  }
}

const renderValueComponent = (cellValue, requestValue, t) => {
  const { valueTypeCode, value, threshold, unit, violationType } = cellValue ?? {}

  let mappedValue = requestValue?.value ?? value
  let mappedThreshold = threshold

  if (valueTypeCode === VALUE_TYPE_CODES.CURRENCY) {
    mappedValue = { amount: requestValue?.value ?? value, currency: unit }
    mappedThreshold = { amount: threshold, currency: unit }
  } else if (valueTypeCode === VALUE_TYPE_CODES.MEASUREMENT) {
    mappedValue = { number: requestValue?.value ?? value, unit }
    mappedThreshold = { number: threshold, unit }
  }

  return renderValueCellWithThreshold({
    valueType: valueTypeCode,
    value: mappedValue,
    threshold: mappedThreshold,
    violationType,
    isLoading: requestValue?.isLoading ?? false,
    t,
  })
}

const resolveEntityLink = ({ entityType, entityId }) => {
  switch (entityType) {
    case REFERENCE_TYPE_CODES.DEAL:
      return `/${paths.deals}/${entityId}`
    case REFERENCE_TYPE_CODES.BUSINESS_PARTNER:
      return `/${paths.businessPartners}/${entityId}`
    case REFERENCE_TYPE_CODES.PROPERTY:
      return `/${paths.properties}/${entityId}`
  }
}
/**
 *@typedef {import("hooks/services/risk-monitoring/riskIndicatorSchema").RiskIndicatorDetails} RiskIndicatorDetails
 * @param {RiskIndicatorDetails['riskIndicators']} riskIndicators
 * @param {RiskIndicatorDetails['requestedValues']} requestedValues
 * @param {any} [typeCodes]
 */
const useDetailedRiskIndicatorTableDataDecisionPaper = (
  riskIndicators,
  requestedValues,
  typeCodes,
) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.tiles.risk-monitoring.risk-indicators.table',
  })

  return useMemo(
    () => ({
      tableData: riskIndicators.map(
        (
          {
            id,
            riskIndicatorName,
            reference,
            initialCriticality,
            currentCriticality,
            maximumCriticality,
            displayLink,
            isAutomated,
            value,
          },
          index,
        ) => ({
          rowKey: id,
          riskIndicator: {
            cellComponent: (
              <Entity
                name={riskIndicatorName}
                className={styles.riskIndicator}
                link={displayLink}
                openInNewTab={true}
                additionalClassNamesForLink={styles.riskIndicator}
              />
            ),
            value: riskIndicatorName,
          },
          reference: {
            cellComponent: reference && (
              <Entity
                name={reference?.referenceEntityName}
                id={reference?.referenceEntityDisplayId}
                link={resolveEntityLink({
                  entityType: reference?.referenceTypeCode,
                  entityId: reference?.referenceEntityDisplayId,
                })}
                openInNewTab={true}
              />
            ),
            value: reference?.referenceEntityName,
          },
          initialCriticality: {
            cellComponent: initialCriticality && (
              <StatusWithDateCell
                {...mapCriticalityTypeCodeToStatus({
                  typeCode: initialCriticality.criticalityTypeCode,
                  shortText: initialCriticality.criticalityShortText,
                  typeCodes,
                })}
                date={initialCriticality.criticalityDate}
              />
            ),
            value: initialCriticality?.criticalityTypeCode,
          },
          currentCriticality: {
            cellComponent: currentCriticality && (
              <StatusWithDateCell
                {...mapCriticalityTypeCodeToStatus({
                  typeCode: currentCriticality.criticalityTypeCode,
                  shortText: currentCriticality.criticalityShortText,
                  typeCodes,
                })}
                date={currentCriticality.criticalityDate}
              />
            ),
            value: currentCriticality?.criticalityTypeCode,
          },
          maximumCriticality: {
            cellComponent: maximumCriticality && (
              <StatusWithDateCell
                {...mapCriticalityTypeCodeToStatus({
                  typeCode: maximumCriticality.criticalityTypeCode,
                  shortText: maximumCriticality.criticalityShortText,
                  typeCodes,
                })}
                date={maximumCriticality.criticalityDate}
              />
            ),
            value: maximumCriticality?.criticalityTypeCode,
          },
          currentValue: {
            cellComponent: isAutomated && renderValueComponent(value, requestedValues?.[index], t),
            value: isAutomated ? value?.value : undefined,
          },
          description: {
            cellComponent: value?.valueTypeCode === 'STRING' && !isAutomated && (
              <CollapsibleTextCell text={value.value} />
            ),
            value: value?.valueTypeCode === 'STRING' && !isAutomated ? value?.value : undefined,
          },
        }),
      ),
    }),
    [requestedValues, riskIndicators, t, typeCodes],
  )
}

export default useDetailedRiskIndicatorTableDataDecisionPaper
