import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { taskStatus } from 'api/events/status'

const TaskTableEditMultipleButton = ({ onClick }) => {
  const { t: tNoPrefix } = useTranslation()

  const selectedTasks = useSelector((state) => state.tasks.taskTable.selectedRows.selectedTaskIds)

  const selectedTaskList = useMemo(() => Object.values(selectedTasks), [selectedTasks])

  const isButtonDisabled =
    isEmpty(selectedTaskList) ||
    selectedTaskList.some(({ status }) =>
      [taskStatus.completed, taskStatus.aborted].includes(status),
    )

  return (
    <Button design={ButtonDesign.Transparent} onClick={onClick} disabled={isButtonDisabled}>
      {tNoPrefix('buttons.edit-multiple')}
    </Button>
  )
}

TaskTableEditMultipleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default TaskTableEditMultipleButton
