import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

/**
 * Hook that returns a react-query mutation for the user favorite.
 *
 * A mutation can be performed by calling useUserFavoriteCreate.mutate()
 * @param onSuccess Callback to call on success.
 * @param onError Callback to call on error.
 */
const useUserFavoriteCreate = (queryOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(async (userFavorite) => {
    await post({
      path: `/user-profile/favorite`,
      body: {
        entity_id: userFavorite.entityId,
        entity_type: userFavorite.entityType,
      },
    })
  }, queryOptions)
}

export default useUserFavoriteCreate
