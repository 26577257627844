import { Label, Toolbar, ToolbarDesign, ToolbarStyle } from '@fioneer/ui5-webcomponents-react'
import '@ui5/webcomponents-fiori/dist/SortItem'
import omit from 'lodash.omit'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/decision-overview/shared/table/TablesToolbar.module.css'
import '@ui5/webcomponents-icons/dist/AllIcons.js'

/**
Copy& own for the decision Paper. Just keeping the style of the title. No functionallity for grouping/sorting/fitlering.
**/
const TablesToolbar = ({
  title: toolbarTitle,
  nrOfEntries,
  style,
  className,
  ...toolbarOptions
}) => {
  const { t } = useTranslation('decisionPaper', { keyPrefix: 'components.ui.tables.toolbar' })

  const title =
    nrOfEntries !== undefined
      ? t('title', { title: toolbarTitle, nrOfEntries })
      : t('title-no-entry-number', { title: toolbarTitle })

  return (
    <>
      <Toolbar
        design={ToolbarDesign.Auto}
        toolbarStyle={ToolbarStyle.Clear}
        className={[styles.toolbar, className ?? ''].join(' ')}
        style={style}
        {...omit(toolbarOptions, 'className')}
      >
        <Label className={styles.titleLabel}>{title}</Label>
      </Toolbar>
    </>
  )
}

TablesToolbar.propTypes = {
  nrOfEntries: PropTypes.number,
  showColumnSelection: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default TablesToolbar
