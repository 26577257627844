import {
  ResponsiveGridLayout,
  SegmentedButton,
  SegmentedButtonItem,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CreatePortfolioSubStep from 'components/domains/deals/creation-dialog/property-step/CreatePortfolioSubStep'
import CreatePropertySubStep from 'components/domains/deals/creation-dialog/property-step/CreatePropertySubStep'
import Labeled from 'components/ui/data/Labeled'
import { useFormMapped } from 'hooks/form/useFormMapped'

export const FinancedObjectType = {
  PROPERTY: 'PROPERTY',
  PORTFOLIO: 'PORTFOLIO',
  NO_PROPERTY: 'NO_PROPERTY',
}

const DealCreationPropertyStep = ({
  enabled = true,
  wizardStep,
  addressFilter,
  isCreatingProperty,
  onUpdateAddressFilter,
  setIsCreatingProperty,
  propertiesWithSameAddressFilter,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals' })
  const { unregister, control } = useFormMapped()

  const financedObjectType = useWatch({
    control,
    name: 'financedObjectType',
    defaultValue: FinancedObjectType.PROPERTY,
  })

  useEffect(() => {
    if (financedObjectType !== FinancedObjectType.PROPERTY) {
      unregister(['portfolio.properties'])
      setIsCreatingProperty(false)
    }
    if (financedObjectType !== FinancedObjectType.PORTFOLIO) {
      unregister([
        'portfolio.numberOfProperties',
        'portfolio.mainPropertyTypeCode',
        'portfolio.countryCodes',
      ])
    }
  }, [financedObjectType, setIsCreatingProperty, unregister])

  return (
    <ResponsiveGridLayout
      columnsS={1}
      columnsM={2}
      columnsL={2}
      columnsXL={2}
      columnGap="1rem"
      rowGap="1rem"
      className="deal-creation-dialog-step"
    >
      <div style={{ gridColumn: 'span 2' }}>
        <Title level={TitleLevel.H4}>{t('dialog.property.title', { wizardStep })}</Title>
      </div>
      <div style={{ gridColumn: 'span 2' }}>
        <Labeled label={t('dialog.property.financed-object')} vertical showColon required>
          <Controller
            control={control}
            name="financedObjectType"
            render={({ field: { onChange, onBlur, name, value } }) => (
              <SegmentedButton
                id="deal-creation-financed-object"
                name={name}
                onSelectionChange={async (evt) => {
                  await onChange(evt?.detail?.selectedItem?.getAttribute('data-value'))
                  await onBlur()
                }}
                style={{ marginTop: '0.5rem', maxWidth: 270 }}
              >
                <SegmentedButtonItem
                  data-value={FinancedObjectType.PROPERTY}
                  pressed={value === FinancedObjectType.PROPERTY}
                >
                  {t('dialog.property')}
                </SegmentedButtonItem>
                <SegmentedButtonItem
                  data-value={FinancedObjectType.PORTFOLIO}
                  pressed={value === FinancedObjectType.PORTFOLIO}
                >
                  {t('dialog.property.portfolio')}
                </SegmentedButtonItem>
                <SegmentedButtonItem
                  data-value={FinancedObjectType.NO_PROPERTY}
                  pressed={value === FinancedObjectType.NO_PROPERTY}
                >
                  {t('dialog.property.no-property')}
                </SegmentedButtonItem>
              </SegmentedButton>
            )}
          />
        </Labeled>
      </div>
      {financedObjectType === FinancedObjectType.PROPERTY && (
        <CreatePropertySubStep
          enabled={enabled}
          addressFilter={addressFilter}
          isCreatingProperty={isCreatingProperty}
          onUpdateAddressFilter={onUpdateAddressFilter}
          propertiesWithSameAddressFilter={propertiesWithSameAddressFilter}
          setIsCreatingProperty={setIsCreatingProperty}
        />
      )}
      {financedObjectType === FinancedObjectType.PORTFOLIO && (
        <CreatePortfolioSubStep enabled={enabled} />
      )}
    </ResponsiveGridLayout>
  )
}

DealCreationPropertyStep.propTypes = {
  enabled: PropTypes.bool,
  wizardStep: PropTypes.number.isRequired,
  addressFilter: PropTypes.object.isRequired,
  isCreatingProperty: PropTypes.bool.isRequired,
  onUpdateAddressFilter: PropTypes.func.isRequired,
  setIsCreatingProperty: PropTypes.func,
  propertiesWithSameAddressFilter: PropTypes.array.isRequired,
}

export default DealCreationPropertyStep
