import {
  Button,
  MessageBoxTypes,
  MessageBoxActions,
  ButtonDesign,
  Modals,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { propertyMonitoringStatus } from 'api/property/monitoring/propertyMonitoringConfiguration'
import styles from 'components/domains/properties/PropertyUnassignMarketButton.module.css'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import { useUnassignPropertyToMarket } from 'hooks/services/markets/useUnassignPropertyToMarket'

const PropertyUnassignMarketButton = ({ propertyId, marketId, monitoringStatus }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.property-overview-market-info-card.unassign',
  })
  const { t: tMonitoringConfirmation } = useTranslation('translation', {
    keyPrefix: 'pages.markets.overview.assignable-markets-dialog',
  })
  const { t: tNoPrefix } = useTranslation()

  const queryClient = useQueryClient()
  const showMessageBox = useShowMessageBox()
  const showMessageBoxMonitoring = useShowMessageBox()
  const showToast = Modals.useShowToast()

  const onPropertyUnassignSuccess = useCallback(() => {
    showToast({ children: t('toast.unassign-success') })
    queryClient.invalidateQueries(['markets'])
    queryClient.invalidateQueries(['property-monitoring'])
    queryClient.invalidateQueries(['properties'])
  }, [queryClient, showToast, t])

  const onPropertyUnassignError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: tMonitoringConfirmation('post-error.description'),
    })
  }, [showMessageBox, tMonitoringConfirmation])

  const { mutate: unassignPropertyFromMarket } = useUnassignPropertyToMarket({
    onSuccess: onPropertyUnassignSuccess,
    onError: onPropertyUnassignError,
  })

  const handleMonitoringConfirmationClicked = useCallback(() => {
    unassignPropertyFromMarket({
      propertyId,
      marketId,
    })
  }, [marketId, propertyId, unassignPropertyFromMarket])

  const handleConfirmationButtonClick = useCallback(() => {
    if (monitoringStatus === propertyMonitoringStatus.activeAutomated) {
      showMessageBoxMonitoring(
        {
          type: MessageBoxTypes.Confirm,
          titleText: tMonitoringConfirmation('confirmation.title'),
          children: tMonitoringConfirmation('confirmation.message'),
          actions: [
            <Button
              key="button-confirm"
              design={ButtonDesign.Emphasized}
              onClick={handleMonitoringConfirmationClicked}
            >
              {tNoPrefix('buttons.confirm')}
            </Button>,
            MessageBoxActions.Cancel,
          ],
        },
        document.body,
      )
      return
    }
    unassignPropertyFromMarket({
      propertyId,
      marketId,
    })
  }, [
    handleMonitoringConfirmationClicked,
    marketId,
    monitoringStatus,
    propertyId,
    showMessageBoxMonitoring,
    tMonitoringConfirmation,
    tNoPrefix,
    unassignPropertyFromMarket,
  ])

  const handleUnsassignButtonClick = useCallback(() => {
    showMessageBox(
      {
        type: MessageBoxTypes.Confirm,
        titleText: t('confirmation.title'),
        children: t('confirmation.message'),
        actions: [
          <Button
            key="button-confirm"
            design={ButtonDesign.Emphasized}
            onClick={handleConfirmationButtonClick}
          >
            {t('button-text')}
          </Button>,
          MessageBoxActions.Cancel,
        ],
      },
      document.body,
    )
  }, [handleConfirmationButtonClick, showMessageBox, t])

  return (
    <>
      <Button
        key="unassign-property-from-market"
        id="unassign-property-from-market"
        design={ButtonDesign.Transparent}
        onClick={handleUnsassignButtonClick}
        className={styles.buttonDesign}
      >
        {t('button-text')}
      </Button>
    </>
  )
}

PropertyUnassignMarketButton.propTypes = {
  propertyId: PropTypes.string.isRequired,
  marketId: PropTypes.string.isRequired,
  monitoringStatus: PropTypes.oneOf(Object.values(propertyMonitoringStatus)),
}

export default PropertyUnassignMarketButton
