import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AreaCell,
  CurrentNetRentCell,
  LeaseEndCell,
  MultiPropertyDateCell,
  PropertyCell,
  RentalUnitCell,
  RentContractedYearCell,
  RentStartCell,
  SegmentAndUsageTypeCell,
  TenantCell,
} from 'components/domains/properties/rent-roll/working-version/rental-units-table/PropertyRentalUnitsWorkingVersionTableCells'
import {
  sortDate,
  sortString,
  sortNumber,
  sortSelectOptionsByDisplayValue,
} from 'components/domains/properties/rent-roll/working-version/rental-units-table/PropertyRentalUnitsWorkingVersionTableFunctions'
import { renderAnalyticalTableCell } from 'components/ui/tables/analytical/AnalyticalTableCell'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useGetAllRentRollWorkingVersionOptions from 'hooks/services/properties/rent-roll/working-version/useGetAllRentRollWorkingVersionOptions'

const usePropertyRentalUnitsWorkingVersionColumns = ({ segments, isMultiProperty }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.rent-roll-working-version.table',
  })
  const getEnumValuesFromCodes = (codes) => {
    const enumValues = {}
    codes.forEach((code) => {
      if (code.key && code.display_name) {
        enumValues[code.key] = code.display_name
      }
    })
    return enumValues
  }
  const getEnumValuesForSegments = (segmentCodes, propertyCodes) => {
    if (!isMultiProperty) {
      return getEnumValuesFromCodes(segmentCodes)
    }
    return segmentCodes.reduce((acc, code) => {
      const property = propertyCodes.find((propertyCode) => propertyCode.key === code.property_uuid)
      if (code?.key && code?.display_name && property?.selectDisplayValue) {
        acc[code.key] = `${property.selectDisplayValue} - ${code.display_name}`
      }
      return acc
    }, {})
  }

  const getEnumValuesForProperties = (properties) =>
    properties.reduce((acc, property) => {
      if (property.key && property.selectDisplayValue) {
        acc[property.key] = property.selectDisplayValue
      }
      return acc
    }, {})

  const options = useGetAllRentRollWorkingVersionOptions()
  const optionsOccupancy = options.occupancyStatusCodes
  const optionsUsageType = options.segmentUsageTypeCodes
  const optionsUom = options.areaMeasureUnitCodes
  const optionsCurrency = options.currencyCodes
  const optionsProperty = options.properties
  const optionsAnonymousTenant = useMemo(
    () => ({
      true: t('anonymous-tenant.true'),
      false: t('anonymous-tenant.false'),
    }),
    [t],
  )
  const preparedSegments = useMemo(
    () =>
      segments?.map((segment) => ({
        key: segment.uuid,
        display_name: segment.name,
        property_uuid: segment.property_uuid,
      })),
    [segments],
  )

  const filterSelectedRows = useCallback((rows, _columnIds, filterValue) => {
    if (!filterValue) {
      return rows
    }
    const selectedRowsArray = filterValue.split(',')
    return rows.filter((row) => selectedRowsArray?.includes(row.original.rowKey))
  }, [])

  const columnsWithCodesAsValues = useMemo(
    () => [
      'occupancy_status_id',
      'segment_uuid',
      'segment_usage_type_id',
      'rental_unit_area_uom_id',
      'rental_unit_currency_id',
      'property_uuid',
    ],
    [],
  )

  const columnsWithDates = useMemo(
    () => ['rent_start_date', 'lease_start_date', 'lease_break_date', 'lease_expiry_date'],
    [],
  )

  const { format } = useShortDateFormatter()
  const parseDateToLocaleFormat = useCallback((date) => format(date) ?? '', [format])

  /**
   * Normalizes strings, so they do not contain any diatrical marks (e.g. à => a, é => e).
   * 1. Split the diacritical marks from the characters
   * 2. Remove the unicode character range U+0300 - U+036F (https://en.wikipedia.org/wiki/Combining_Diacritical_Marks)
   *
   * @param {string} value
   * @returns string without diacritics
   */
  const removeAccents = (string) => string?.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

  /**
   * Compares a base string with a filter value. When the filter value matches the base string
   * partly or completely, the function returns true, else false.
   *
   * @param {string} string
   * @param {string} filterValue
   * @returns {boolean}
   */
  const stringIncludesFilterValue = useCallback(
    (string, filterValue) =>
      removeAccents(string)?.toLowerCase().includes(removeAccents(filterValue)?.toLowerCase()),
    [],
  )

  const containsFilterValue = useCallback(
    (columnName, column, filterValue) => {
      if (columnsWithCodesAsValues.includes(columnName)) {
        const optionsFromProps = column.cellComponent.props.options
        const displayName =
          optionsFromProps.find((option) => option.key === column?.value)?.display_name ??
          column?.value
        return displayName && stringIncludesFilterValue(displayName, filterValue)
      } else if (columnsWithDates.includes(columnName)) {
        const parsedDate = parseDateToLocaleFormat(column?.value)
        return parsedDate && parsedDate.includes(filterValue)
      } else {
        const valueIsString = typeof column?.value === 'string'
        return valueIsString && stringIncludesFilterValue(column.value, filterValue)
      }
    },
    [
      columnsWithCodesAsValues,
      columnsWithDates,
      parseDateToLocaleFormat,
      stringIncludesFilterValue,
    ],
  )

  const filterBySearchBar = useCallback(
    (rows, _columnIds, filterValue) =>
      rows.filter((row) =>
        Object.keys(row.original).some((columnName) =>
          containsFilterValue(columnName, row.original[columnName], filterValue),
        ),
      ),
    [containsFilterValue],
  )

  return useMemo(() => {
    const defaultDisables = {
      disableDragAndDrop: true,
      disableGlobalFilter: true,
      disableGroupBy: true,
    }

    const tableHelpers = {
      t,
    }

    const propertyColumns = isMultiProperty
      ? [
          {
            Header: t('column.property'),
            accessor: 'property_uuid.value',
            id: 'property_uuid',
            Cell: renderAnalyticalTableCell(PropertyCell, tableHelpers),
            sortType: sortSelectOptionsByDisplayValue,
            filterType: filterTypes.MULTISELECT_ENUM,
            additionalFilterOptions: {
              enumValues: getEnumValuesForProperties(optionsProperty),
            },
            minWidth: 130,
            dialogOrder: 1,
            ...defaultDisables,
          },
        ]
      : []

    const dateColumns = isMultiProperty
      ? [
          {
            Header: t('column.rent-start'),
            accessor: 'rent_start_date.value',
            id: 'rent_start_date',
            Cell: renderAnalyticalTableCell(MultiPropertyDateCell, tableHelpers),
            filterType: filterTypes.BETWEEN_DATES,
            sortType: sortDate,
            hAlign: 'right',
            minWidth: 130,
            dialogOrder: 12,
            ...defaultDisables,
          },
          {
            Header: t('column.lease-break'),
            accessor: 'lease_break_date.value',
            id: 'lease_break_date',
            filterType: filterTypes.BETWEEN_DATES,
            sortType: sortDate,
            hide: true,
            dialogOrder: 15,
            ...defaultDisables,
          },
        ]
      : [
          {
            Header: t('column.rent-start'),
            accessor: 'rent_start_date.value',
            id: 'rent_start_date',
            Cell: renderAnalyticalTableCell(RentStartCell, tableHelpers),
            filterType: filterTypes.BETWEEN_DATES,
            sortType: sortDate,
            hAlign: 'right',
            minWidth: 130,
            dialogOrder: 12,
            ...defaultDisables,
          },
          {
            Header: t('column.lease-break'),
            accessor: 'lease_break_date.value',
            id: 'lease_break_date',
            Cell: renderAnalyticalTableCell(LeaseEndCell, tableHelpers),
            filterType: filterTypes.BETWEEN_DATES,
            sortType: sortDate,
            hAlign: 'right',
            minWidth: 130,
            dialogOrder: 15,
            ...defaultDisables,
          },
        ]

    return [
      {
        // This is the column where the expand-icon is rendered.
        Header: '',
        accessor: 'expand',
        id: 'expand',
        maxWidth: 32,
        disableResizing: true,
        disableSortBy: true,
        disableFilters: true,
        ...defaultDisables,
      },
      {
        Header: t('column.rental-unit'),
        accessor: 'rental_unit_name.value',
        id: 'rental_unit_name',
        Cell: renderAnalyticalTableCell(RentalUnitCell, tableHelpers),
        filterType: filterTypes.CONTAINS,
        sortType: sortString,
        minWidth: 130,
        dialogOrder: 0,
        ...defaultDisables,
      },
      ...propertyColumns,
      {
        Header: t('label.occupancy'),
        accessor: 'occupancy_status_id.value',
        id: 'occupancy_status_id',
        filterType: filterTypes.MULTISELECT_ENUM,
        additionalFilterOptions: {
          enumValues: getEnumValuesFromCodes(optionsOccupancy),
        },
        sortType: sortSelectOptionsByDisplayValue,
        hide: true,
        dialogOrder: 2,
        ...defaultDisables,
      },
      {
        Header: t('column.tenant'),
        accessor: 'tenant_name.value',
        id: 'tenant_name',
        Cell: renderAnalyticalTableCell(TenantCell, tableHelpers),
        filterType: filterTypes.CONTAINS,
        sortType: sortString,
        minWidth: 130,
        dialogOrder: 3,
        ...defaultDisables,
      },
      {
        Header: t('label.anonymous-tenant'),
        accessor: 'anonymous_tenant.value',
        id: 'anonymous_tenant',
        filterType: filterTypes.MULTISELECT_ENUM,
        additionalFilterOptions: {
          enumValues: optionsAnonymousTenant,
        },
        hide: true,
        dialogOrder: 5,
        ...defaultDisables,
      },
      {
        Header: t('label.tenant-id'),
        accessor: 'tenant_id.value',
        id: 'tenant_id',
        filterType: filterTypes.CONTAINS,
        sortType: sortString,
        hide: true,
        dialogOrder: 4,
        ...defaultDisables,
      },
      {
        Header: t('column.segment'),
        accessor: 'segment_uuid.value',
        id: 'segment_uuid',
        Cell: renderAnalyticalTableCell(SegmentAndUsageTypeCell, tableHelpers),
        filterType: filterTypes.MULTISELECT_ENUM,
        additionalFilterOptions: {
          enumValues: getEnumValuesForSegments(preparedSegments, optionsProperty),
        },
        sortType: sortSelectOptionsByDisplayValue,
        minWidth: 130,
        dialogOrder: 6,
        ...defaultDisables,
      },
      {
        Header: t('label.usage-type'),
        accessor: 'segment_usage_type_id.value',
        id: 'segment_usage_type_id',
        filterType: filterTypes.MULTISELECT_ENUM,
        additionalFilterOptions: {
          enumValues: getEnumValuesFromCodes(optionsUsageType),
        },
        sortType: sortSelectOptionsByDisplayValue,
        hide: true,
        dialogOrder: 7,
        ...defaultDisables,
      },
      {
        Header: t('column.area'),
        accessor: 'rental_unit_area.value',
        id: 'rental_unit_area',
        Cell: renderAnalyticalTableCell(AreaCell, tableHelpers),
        filterType: filterTypes.BETWEEN_NUMBERS,
        minWidth: 130,
        sortType: sortNumber,
        hAlign: 'right',
        dialogOrder: 8,
        ...defaultDisables,
      },
      {
        Header: t('label.UoM'),
        accessor: 'rental_unit_area_uom_id.value',
        id: 'rental_unit_area_uom_id',
        filterType: filterTypes.MULTISELECT_ENUM,
        additionalFilterOptions: {
          enumValues: getEnumValuesFromCodes(optionsUom),
        },
        sortType: sortSelectOptionsByDisplayValue,
        hide: true,
        dialogOrder: 9,
        ...defaultDisables,
      },
      {
        Header: t('column.annulized-contracted-rent'),
        accessor: 'rent_contracted_year.value',
        id: 'rent_contracted_year',
        Cell: renderAnalyticalTableCell(RentContractedYearCell, tableHelpers),
        filterType: filterTypes.BETWEEN_NUMBERS,
        sortType: sortNumber,
        hAlign: 'right',
        minWidth: 130,
        dialogOrder: 10,
        ...defaultDisables,
      },
      {
        Header: t('label.currency'),
        accessor: 'rental_unit_currency_id.value',
        id: 'rental_unit_currency_id',
        filterType: filterTypes.MULTISELECT_ENUM,
        additionalFilterOptions: {
          enumValues: getEnumValuesFromCodes(optionsCurrency),
        },
        sortType: sortSelectOptionsByDisplayValue,
        hide: true,
        dialogOrder: 12,
        ...defaultDisables,
      },
      {
        Header: t('column.annualized-current-rent'),
        accessor: 'current_net_rent.value',
        id: 'current_net_rent',
        Cell: renderAnalyticalTableCell(CurrentNetRentCell),
        filterType: filterTypes.BETWEEN_NUMBERS,
        sortType: sortNumber,
        hAlign: 'right',
        minWidth: 130,
        dialogOrder: 11,
        ...defaultDisables,
      },
      {
        Header: t('label.lease-start'),
        accessor: 'lease_start_date.value',
        id: 'lease_start_date',
        filterType: filterTypes.BETWEEN_DATES,
        sortType: sortDate,
        hide: true,
        dialogOrder: 13,
        ...defaultDisables,
      },
      {
        Header: t('label.lease-expiry'),
        accessor: 'lease_expiry_date.value',
        id: 'lease_expiry_date',
        filterType: filterTypes.BETWEEN_DATES,
        sortType: sortDate,
        hide: true,
        ...defaultDisables,
        dialogOrder: 14,
      },
      ...dateColumns,
      {
        Header: t('label.search-bar'),
        accessor: 'searchBar',
        id: 'searchBar',
        hide: true,
        filter: filterBySearchBar,
        dialogOrder: 100, // large number, so this always appears at the bottom
        ...defaultDisables,
      },
      {
        Header: t('label.selected'),
        accessor: 'rowKey',
        id: 'rowKey',
        hide: true,
        filter: filterSelectedRows,
        dialogOrder: 101, // large number, so this always appears at the bottom
        ...defaultDisables,
      },
    ]
  }, [
    filterBySearchBar,
    filterSelectedRows,
    isMultiProperty,
    optionsAnonymousTenant,
    optionsCurrency,
    optionsOccupancy,
    optionsProperty,
    optionsUom,
    optionsUsageType,
    preparedSegments,
    t,
  ])
}

export default usePropertyRentalUnitsWorkingVersionColumns
