import {
  CustomListItem,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
  ListItemType,
  Text,
  Title,
  TitleLevel,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/EmptyCard.module.css'
import CustomizedIllustratedMessage from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/illustrated-message/CustomizedIllustratedMessage'

const EmptyCardContent = ({ title, subtitle, illustrationName, size, actions, className }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'components.ui.card.empty-card',
  })
  const renderedTitle = title ?? t('title')
  const renderedSubtitle = subtitle ?? t('subtitle')

  // We only want to render the CustomizedIllustratedMessage for the empty state of images or charts,
  // In order to be design compliant, these two parameters need to be set, otherwise a normal IllustratedMessage is rendered
  const IllustratedMessageComponent =
    size === IllustrationMessageSize.Scene &&
    illustrationName === IllustrationMessageType.UnableToLoad
      ? CustomizedIllustratedMessage
      : IllustratedMessage

  return (
    <CustomListItem type={ListItemType.Inactive} className={className}>
      {illustrationName ? (
        <IllustratedMessageComponent
          size={size}
          name={illustrationName}
          titleText={renderedTitle}
          subtitleText={renderedSubtitle}
        />
      ) : (
        <FlexBox
          alignItems={FlexBoxAlignItems.Center}
          fitContainer
          className={styles['cwp-empty-card-wrapper']}
          direction={FlexBoxDirection.Column}
        >
          {renderedTitle && (
            <Title
              level={TitleLevel.H5}
              wrappingType={WrappingType.Normal}
              className={styles.title}
            >
              {renderedTitle}
            </Title>
          )}
          {renderedSubtitle && (
            <Text
              className={styles['cwp-empty-card-subtitle']}
              wrapping={true}
              style={{ textAlign: 'center' }}
            >
              {renderedSubtitle}
            </Text>
          )}
          {actions}
        </FlexBox>
      )}
    </CustomListItem>
  )
}

EmptyCardContent.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  illustrationName: PropTypes.string,
  size: PropTypes.string,
  actions: PropTypes.element,
}

export default EmptyCardContent
