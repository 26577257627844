import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import documentPermissions from 'api/documents/DocumentPermissions'
import BusinessPartnerAssociatedBorrowers from 'components/domains/business-partners/related-documents/BusinessPartnerAssociatedBorrowers'
import { useDealWorkingVersionToolbarButtons } from 'components/domains/deals/deal-adjustment/useDealWorkingVersionToolbarButtons'
import DealDocumentsTable from 'components/domains/deals/documents/DealDocumentsTable'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import DocumentUploadDialog from 'components/domains/documents/DocumentUploadDialog'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import Section from 'components/ui/page/Section'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useDocuments from 'hooks/services/documents/useDocuments'
import useDocumentsAllowedOperations from 'hooks/services/documents/useDocumentsAllowedOperations'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'

const DealDocuments = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.detail.documents' })
  const { deal } = useContext(DealContext)
  const initialUploadedDocumentIds = []
  const [uploadedDocumentIds, setUploadedDocumentIds] = useState(initialUploadedDocumentIds)
  const {
    data: documentRoot = {},
    isLoading,
    isError,
  } = useDocuments({ entityId: deal.dealUuid, entityType: DocumentTypes.Deal })
  const { toggleWorkingVersionButton, manageWorkingVersionButton } =
    useDealWorkingVersionToolbarButtons()
  const [files, setFiles] = useState(undefined)
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false)

  const handleOnDrop = ({ files: newFiles } = {}) => {
    setFiles(newFiles)
    setIsUploadDialogOpen(true)
  }

  const breadcrumb = { text: t('title') }

  const {
    data: allowedOperationsData,
    isLoading: isLoadingAllowedOperations,
    isError: isAllowedOperationsError,
  } = useDocumentsAllowedOperations()

  const hasDocumentReadPermission =
    allowedOperationsData?.allowedOperations.includes(documentPermissions.Read) ?? false
  const hasDocumentUploadPermission =
    allowedOperationsData?.allowedOperations.includes(documentPermissions.Create) ?? false

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={deal.dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [deal.dealUuid],
  )

  const headerActions = useMemo(
    () =>
      createSegmentedHeaderActions(
        [toggleWorkingVersionButton, manageWorkingVersionButton],
        [<CreateDealEventAction key="create-event-action" />, markFavoriteAction],
      ),
    [manageWorkingVersionButton, markFavoriteAction, toggleWorkingVersionButton],
  )

  if (!hasDocumentReadPermission) return <></>
  return (
    <>
      <DocumentUploadDialog
        type={DocumentTypes.Deal}
        isOpen={isUploadDialogOpen}
        initialState={{
          open: undefined,
          files,
          defaultFilterCriteria: {
            property: { dealId: deal.dealUuid },
          },
          selectedEntities: [{ id: deal.dealUuid, name: deal.name, displayId: deal.displayId }],
        }}
        onUploadFinished={(newDocumentIds) => {
          setUploadedDocumentIds(newDocumentIds)
        }}
        onClose={() => {
          setIsUploadDialogOpen(false)
          setFiles(undefined)
        }}
      />
      <DealDetailsWrapper childBreadcrumbs={breadcrumb} actions={headerActions}>
        <RequestStateResolver
          isLoading={isLoading || isLoadingAllowedOperations}
          isError={isError || isAllowedOperationsError}
          errorToDisplay={<ErrorDataUnavailableInContent />}
          renderContent={() => (
            <>
              <BusinessPartnerAssociatedBorrowers />
              <Section titleText={t('header')}>
                <DealDocumentsTable
                  documentRoot={documentRoot}
                  isLoading={isLoading}
                  isError={isError}
                  uploadedDocumentIds={uploadedDocumentIds}
                  handleOnDrop={handleOnDrop}
                  hasDocumentUploadPermission={hasDocumentUploadPermission}
                />
              </Section>
            </>
          )}
        />
      </DealDetailsWrapper>
    </>
  )
}

export default DealDocuments
