import {
  MultiComboBox,
  MultiComboBoxGroupItem,
  MultiComboBoxItem,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/toolbar/input/MultiselectInput.module.css'
import { filters } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/toolbar/propTypes'

/**
 * Provides a MultiComboBox, that is used inside the FilterDialog (MULTISELECT_ENUM)
 *
 * @param {object} enumValues - Contains the options for the MultiComboBox
 * @param {array} selectedFilters - Currently selected filters
 * @param {function} setSelectedFilters - Setter of the currently selected filters
 * @param {object} selectRefs - Select refs for the MultiComboBox (to clear the selected options on reset)
 * @param {string} columnKey - Key of the table column
 * @param {object} additionalMultiComboBoxProps - Additional props that are passed to the MultiComboBox
 */
export const MultiselectInput = ({
  enumValues,
  selectedFilters,
  setSelectedFilters,
  selectRefs,
  columnKey,
  ...additionalMultiComboBoxProps
}) => {
  const CUSTOM_KEY = 'custom'
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'components.ui.tables.toolbar.filter.multi-select',
  })
  const sortByDisplayValues = (a, b) => {
    if (enumValues[a] > enumValues[b]) {
      return 1
    } else if (enumValues[a] < enumValues[b]) {
      return -1
    }
    return 0
  }
  const keyNames = Object.keys(enumValues).sort(sortByDisplayValues)
  const [customInputValues, setCustomInputValues] = useState([])

  const getOptions = () => {
    const enumOptions = keyNames.map((keyName) => (
      <MultiComboBoxItem key={keyName} text={enumValues[keyName]} data-id={keyName} />
    ))
    const customOptions = customInputValues.map((customValue, index) => (
      <MultiComboBoxItem
        key={`${CUSTOM_KEY}-${columnKey}-${index}`}
        text={customValue}
        data-id={`${CUSTOM_KEY}-${columnKey}-${index}`}
        selected={true}
      />
    ))
    return [
      customOptions.length && [
        <MultiComboBoxGroupItem text={t('custom-title')} key={'custom-title'} />,
        ...customOptions,
        <MultiComboBoxGroupItem text={t('options-title')} key={'options-title'} />,
      ],
      ...enumOptions,
    ]
  }

  const setFilter = (items) => {
    const filterValue = []
    items?.map((item) => {
      const id = item.dataset.id
      id.startsWith(CUSTOM_KEY) ? filterValue.push(item.text) : filterValue.push(id)
    })
    setSelectedFilters(
      selectedFilters.map((filter) =>
        filter.columnKey === columnKey ? { ...filter, value: filterValue } : filter,
      ),
    )
  }

  const setFilterForCreatedCustomOption = (filterText) => {
    const filterValueBeforeUpdate = selectedFilters.find(
      (filter) => filter.columnKey === columnKey,
    )?.value
    const updatedFilterValue = filterValueBeforeUpdate
      ? [...filterValueBeforeUpdate, filterText]
      : [filterText]
    setSelectedFilters(
      selectedFilters.map((filter) =>
        filter.columnKey === columnKey ? { ...filter, value: updatedFilterValue } : filter,
      ),
    )
  }

  const handleInputChange = (event) => {
    if (event.target.value?.trim() !== '' && !event.target.open) {
      setCustomInputValues((oldState) => [...oldState, event.target.value])
      setFilterForCreatedCustomOption(event.target.value)
    }
  }

  return (
    <MultiComboBox
      id="multiselect-filter"
      allowCustomValues
      noTypeahead
      ref={(ref) => (selectRefs.current[columnKey] = ref)}
      className={styles.multiComboBox}
      onChange={handleInputChange}
      onSelectionChange={(e) => {
        setFilter(e.detail.items)
      }}
      {...additionalMultiComboBoxProps}
    >
      {getOptions()}
    </MultiComboBox>
  )
}

MultiselectInput.propTypes = {
  enumValues: PropTypes.object.isRequired,
  selectedFilters: filters.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  selectRefs: PropTypes.object.isRequired,
  columnKey: PropTypes.string.isRequired,
  additionalMultiComboBoxProps: PropTypes.object,
}
