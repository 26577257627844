import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxDirection,
  Popover,
  PopoverPlacementType,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/button/CancelButtonWithPopover.module.css'

export const CancelPopoverContent = ({ onCancelClicked }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.ui.buttons.cancel.popover' })
  const { t: tNoPrefix } = useTranslation()

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Text wrapping className={styles.cancelPopoverText}>
        {t('cancel.confirmation')}
      </Text>
      <Button onClick={onCancelClicked}>{tNoPrefix('buttons.discard')}</Button>
    </FlexBox>
  )
}

CancelPopoverContent.propTypes = {
  onCancelClicked: PropTypes.func.isRequired,
}

const CancelButtonWithPopover = ({
  showPopover,
  onCancelClicked,
  id = 'cancelButtonWithPopover',
  ...props
}) => {
  const { t: tNoPrefix } = useTranslation('translation')

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const handleCancel = useCallback(
    (event) => {
      if (!showPopover) {
        onCancelClicked(event)
        return
      }
      setIsPopoverOpen(true)
    },
    [onCancelClicked, showPopover],
  )

  const onCancelClickedInternal = useCallback(
    (event) => {
      onCancelClicked(event)
      setIsPopoverOpen(false)
    },
    [onCancelClicked, setIsPopoverOpen],
  )

  return (
    <>
      {showPopover &&
        createPortal(
          <Popover
            open={isPopoverOpen}
            onAfterClose={() => setIsPopoverOpen(false)}
            opener={id}
            placementType={PopoverPlacementType.Top}
          >
            <CancelPopoverContent onCancelClicked={onCancelClickedInternal} />
          </Popover>,
          document.body,
        )}
      <Button {...props} id={id} onClick={handleCancel} design={ButtonDesign.Transparent}>
        {tNoPrefix('buttons.cancel')}
      </Button>
    </>
  )
}

CancelButtonWithPopover.propTypes = {
  showPopover: PropTypes.bool.isRequired,
  onCancelClicked: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default CancelButtonWithPopover
