import { flatMapDeep } from 'lodash'
import isEmpty from 'lodash.isempty'
import { useCallback, useMemo } from 'react'
import { ENTITY_TYPES } from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/shared/constants'

export const useRecursivelyExtractApprovalItems = (tableData) => {
  const recursivelyExtractApprovalItems = useCallback(
    (row) => [
      ...row.subRows.flatMap((subRow) =>
        !isEmpty(subRow.subRows) ? recursivelyExtractApprovalItems(subRow) : { ...subRow },
      ),
    ],
    [],
  )

  const approvalItems = useMemo(
    () =>
      flatMapDeep(
        tableData?.map((row) =>
          row?.subRows?.map((subRow) => recursivelyExtractApprovalItems(subRow)),
        ),
      ),
    [recursivelyExtractApprovalItems, tableData],
  )

  return approvalItems.filter((row) => row.entityType === ENTITY_TYPES.APPROVALITEM)
}
