import { Label, TableRowType } from '@fioneer/ui5-webcomponents-react'
import camelize from 'camelize'
import { useMemo } from 'react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/valuation-results/PropertyValuationResultsTable.module.css'
import PropertyValuationResultsDateInputCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/valuation-results/cells/PropertyValuationResultsDateInputCell'
import PropertyValuationResultsKeyDateCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/valuation-results/cells/PropertyValuationResultsKeyDateCell'
import PropertyValuationResultsValidFromCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/valuation-results/cells/PropertyValuationResultsValidFromCell'
import PropertyValuationResultsValueAmountCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/valuation-results/cells/PropertyValuationResultsValueAmountCell'
import { ValuationResultsTableColumns } from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/valuation-results/usePropertyValuationResultsTableColumns'

const useMapPropertyValuationResultsTableData = ({
  valuationResults,
  isAddMode,
  currentEditedValues,
  highlightedValuations,
}) => {
  const valuationData = useMemo(() => {
    const camelizedValuationResults = camelize(valuationResults)
    return isAddMode
      ? [currentEditedValues, ...camelizedValuationResults]
      : camelizedValuationResults
  }, [isAddMode, currentEditedValues, valuationResults])

  const tableData = useMemo(
    () =>
      valuationData.map(
        ({
          valuationId,
          calculationMethodName,
          keyDate: initialKeyDate,
          valueAmount: { number: initialValueAmount, currency },
          validFrom: initialValidFrom,
          validTo: initialValidTo,
        }) => {
          const isCurrentRowAddMode = isAddMode && valuationId === currentEditedValues?.valuationId
          const keyDate = isCurrentRowAddMode ? currentEditedValues?.keyDate : initialKeyDate
          const validFrom = isCurrentRowAddMode ? currentEditedValues?.validFrom : initialValidFrom
          const validTo = isCurrentRowAddMode ? currentEditedValues?.validTo : initialValidTo
          const valueAmount = isCurrentRowAddMode
            ? currentEditedValues?.valueAmount?.value
            : initialValueAmount
          const isRowHighlighted = highlightedValuations.includes(valuationId)
          return {
            rowKey: `valuation-result-${valuationId}`,
            rowProperties: {
              type: TableRowType.Inactive,
              'data-result-id': valuationId,
              className: isRowHighlighted ? styles.hightlightNewOrEditedRow : null,
            },
            [ValuationResultsTableColumns.method]: {
              value: calculationMethodName,
              cellComponent: <Label>{calculationMethodName}</Label>,
            },
            [ValuationResultsTableColumns.keyDate]: {
              value: keyDate,
              cellComponent: (
                <PropertyValuationResultsKeyDateCell
                  keyDate={keyDate}
                  isAddMode={isCurrentRowAddMode}
                />
              ),
            },
            [ValuationResultsTableColumns.result]: {
              value: valueAmount,
              cellComponent: (
                <PropertyValuationResultsValueAmountCell
                  valueAmount={valueAmount}
                  currency={currency}
                  isAddMode={isCurrentRowAddMode}
                />
              ),
            },
            [ValuationResultsTableColumns.validFrom]: {
              value: validFrom,
              cellComponent: (
                <PropertyValuationResultsValidFromCell
                  validFrom={validFrom}
                  isAddMode={isCurrentRowAddMode}
                />
              ),
            },
            [ValuationResultsTableColumns.validTo]: {
              value: validTo,
              cellComponent: (
                <PropertyValuationResultsDateInputCell
                  date={validTo}
                  onChange={() => {}}
                  isAddMode={isCurrentRowAddMode}
                />
              ),
            },
          }
        },
      ),
    [
      currentEditedValues?.keyDate,
      currentEditedValues?.validFrom,
      currentEditedValues?.validTo,
      currentEditedValues?.valuationId,
      currentEditedValues?.valueAmount?.value,
      highlightedValuations,
      isAddMode,
      valuationData,
    ],
  )

  return useMemo(() => ({ tableData }), [tableData])
}

export default useMapPropertyValuationResultsTableData
