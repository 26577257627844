import {
  AnalyticalTableScaleWidthMode,
  Icon,
  IllustratedMessage,
} from '@fioneer/ui5-webcomponents-react'
import { array, bool, number, string } from 'prop-types'
import { useCallback, useState } from 'react'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/analytical/DealCashFlowAnalyticalTableWithToolbar'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/analytical-table/DealCashFlowAnalyticalTable.module.css'

const defaultRowHeight = 32
export const DealCashFlowAnalyticalTable = ({
  className,
  title,
  columns = [],
  data = [],
  nrOfEntries,
  rowHeight = defaultRowHeight,
  headerRowHeight = defaultRowHeight,
  scaleWidthMode = AnalyticalTableScaleWidthMode.Smart,
  customRowHeight = false,
  isPdfViewOnDecisionPaper = false,
  ...additionalProperties
}) => {
  const [isCollapseIconDisabled, setIsCollapseIconDisabled] = useState(true)
  const [isExpandIconDisabled, setIsExpandIconDisabled] = useState(false)
  const [table, setTable] = useState(undefined)
  const [expandableRowsCount, setExpandableRowsCount] = useState(0)

  const tableRef = useCallback((node) => {
    if (node !== null) {
      setTable(node)
      const expandableRows = node?.rows?.filter((row) => row.canExpand)
      setExpandableRowsCount(expandableRows?.length ?? 0)
    }
  }, [])

  const handleOnRowExpand = ({ detail: { row } = {} }) => {
    const { state: { expanded } = {} } = table
    const expandedRowsCount = expanded ? Object.keys(expanded).length : 0

    setIsCollapseIconDisabled(row.isExpanded && expandedRowsCount === 1)
    setIsExpandIconDisabled(!row.isExpanded && expandedRowsCount + 1 === expandableRowsCount)
  }

  const expandSubRows = (isExpanded) => {
    table.toggleAllRowsExpanded(isExpanded)
    setIsCollapseIconDisabled(!isExpanded)
    setIsExpandIconDisabled(isExpanded)
  }

  const additionalTableActions =
    expandableRowsCount > 0 && !isPdfViewOnDecisionPaper
      ? [
          <Icon
            className={styles.collapseIcon}
            key="collapse-subrows"
            name="collapse-all"
            interactive
            onClick={() => expandSubRows(false)}
            disabled={isCollapseIconDisabled}
          />,
          <Icon
            className={styles.expandIcon}
            key="expand-subrows"
            name="expand-all"
            interactive
            onClick={() => expandSubRows(true)}
            disabled={isExpandIconDisabled}
          />,
        ]
      : []

  return (
    <AnalyticalTableWithToolbar
      ref={tableRef}
      className={`${styles.table} ${className}`}
      title={title}
      columns={columns}
      data={data}
      nrOfEntries={nrOfEntries}
      minRows={0}
      rowHeight={customRowHeight ? 'custom' : rowHeight}
      headerRowHeight={headerRowHeight}
      onRowExpandChange={handleOnRowExpand}
      isTreeTable
      sortable={false}
      scaleWidthMode={scaleWidthMode}
      withRowHighlight={false}
      reactTableOptions={{
        autoResetPage: false,
        autoResetExpanded: false,
        autoResetSelectedRows: false,
        selectSubRows: false,
      }}
      additionalActions={additionalTableActions}
      NoDataComponent={() => <IllustratedMessage name="NoEntries" />}
      {...additionalProperties}
    />
  )
}

DealCashFlowAnalyticalTable.propTypes = {
  className: string,
  title: string.isRequired,
  data: array.isRequired,
  columns: array.isRequired,
  nrOfEntries: number.isRequired,
  rowHeight: number,
  headerRowHeight: number,
  scaleWidthMode: string,
  customRowHeight: bool,
  isPdfViewOnDecisionPaper: bool,
}
