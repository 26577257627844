import { FlexBox, FlexBoxDirection, Option, Select } from '@fioneer/ui5-webcomponents-react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { propertyAllowedOperations } from 'api/property/propertyAllowedOperations'
import {
  VIEW_OPTION_KPI_TYPE,
  VIEW_OPTION_PERIODS,
} from 'components/domains/properties/rent-roll/overview/structure-overview/constants'
import styles from 'components/domains/properties/rent-roll/overview/structure-overview/rental-income/PropertyRentRollOverviewRentalIncomeCard.module.css'
import PropertyRentRollOverviewRentalIncomeTable from 'components/domains/properties/rent-roll/overview/structure-overview/rental-income/PropertyRentRollOverviewRentalIncomeTable'
import Card from 'components/ui/card/Card'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useSegments } from 'hooks/services/properties/segments/useSegments'
import { usePropertiesPermissions } from 'hooks/services/properties/usePropertiesPermissions'
import { setKpiValues, setPeriod } from 'redux/slices/properties/segmentsRentalIncomeSlice'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyRentRollOverviewRentalIncomeCard = () => {
  const { isLoading: isLoadingPermissions, data: propertyPermissions } = usePropertiesPermissions()
  const allowedPropertyOperations = propertyPermissions?.allowed_operations ?? []
  const isAllowedToReadProperty = allowedPropertyOperations.includes(
    propertyAllowedOperations.propertyRead,
  )

  const { t: tNoPrefix } = useTranslation('translation')
  const { t: tRentalIncome } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.rental-income',
  })

  const { property } = useContext(PropertyContext)

  const propertyUuid = property?.uuid

  const selectedCurrency = useSelector(
    (state) => state.properties.commonCurrency.selectedCommonCurrency,
  )

  const {
    isLoading: isLoadingSegments,
    isError: isErrorSegments,
    data: segmentsData,
  } = useSegments(
    { propertyUuid, preferredCurrency: selectedCurrency ?? property?.currency_code },
    {
      enabled: isAllowedToReadProperty,
    },
  )
  const segments = segmentsData?.segments ?? []

  const dispatch = useDispatch()

  const handleKpiTypeChange = (event) => {
    dispatch(setKpiValues(event?.detail?.selectedOption?.value || VIEW_OPTION_KPI_TYPE.CURRENT_RET))
  }

  const handlePeriodChange = (event) => {
    dispatch(setPeriod(event?.detail?.selectedOption?.value || VIEW_OPTION_PERIODS.ANNUALLY))
  }

  const toolbarSelectActions = [
    <Select
      key="rental-income-kpi-type-select"
      id="rental-income-kpi-type-select"
      onChange={handleKpiTypeChange}
    >
      <Option value={VIEW_OPTION_KPI_TYPE.CURRENT_RET}>
        {tRentalIncome('table.columns.current-values')}
      </Option>
      <Option value={VIEW_OPTION_KPI_TYPE.CONTRACTED_RENT}>
        {tRentalIncome('table.columns.contracted-values')}
      </Option>
    </Select>,
    <Select
      key="rental-income-period-select"
      id="rental-income-period-select"
      onChange={handlePeriodChange}
    >
      <Option value={VIEW_OPTION_PERIODS.ANNUALLY}>{tNoPrefix('labels.annually')}</Option>
      <Option value={VIEW_OPTION_PERIODS.MONTHLY}>{tNoPrefix('labels.monthly')}</Option>
    </Select>,
  ]

  return (
    <Card>
      <RequestStateResolver
        isLoading={isLoadingSegments || isLoadingPermissions}
        isError={isErrorSegments}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        center={true}
        renderContent={() => (
          <FlexBox direction={FlexBoxDirection.Column} className={styles.tablePadding}>
            <PropertyRentRollOverviewRentalIncomeTable
              property={property}
              segments={segments}
              hasPermission={isAllowedToReadProperty}
              toolbarSelectActions={toolbarSelectActions}
            />
          </FlexBox>
        )}
        renderCustomErrorScreen={() => (
          <EmptyCardContent
            title={tNoPrefix('components.cards.error.title')}
            subtitle={tNoPrefix('components.cards.error.subtitle')}
          />
        )}
      />
    </Card>
  )
}

export default PropertyRentRollOverviewRentalIncomeCard
