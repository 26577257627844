import { Input, ValueState } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState, forwardRef } from 'react'

const InputWithClearIcon = forwardRef(
  ({ onFocus = (_) => {}, onBlur = (_) => {}, valueState = undefined, ...rest }, _ref) => {
    const [showClearIcon, setShowClearIcon] = useState(false)

    const handleOnFocus = (event) => {
      setShowClearIcon(true)
      onFocus(event)
    }

    const isError = valueState === ValueState.Error

    const handleOnBlur = (event) => {
      setShowClearIcon(false)
      onBlur(event)
    }

    return (
      <Input
        showClearIcon={isError || showClearIcon}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        valueState={valueState}
        {...rest}
      />
    )
  },
)

InputWithClearIcon.displayName = 'InputWithClearIcon'

InputWithClearIcon.propTypes = {
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  valueState: PropTypes.string,
}
export default InputWithClearIcon
