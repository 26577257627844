import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedRows: {
    selectedTaskIds: {},
  },
  errorRows: {},
  rowsHaveError: false,
}

export const taskTableSlice = createSlice({
  name: 'task-table',
  initialState,
  reducers: {
    selectTask: (
      state,
      {
        payload: {
          id: taskId,
          status,
          info: { isMandatory },
          eventStatus,
        },
      },
    ) => {
      state.selectedRows.selectedTaskIds = {
        ...state.selectedRows.selectedTaskIds,
        [taskId]: { status, isMandatory, eventStatus },
      }
    },
    selectAllTasks: (state, { payload: tasks }) => {
      state.selectedRows.selectedTaskIds = {}
      tasks.forEach(
        ({ id: taskId, status, info: { isMandatory }, event: { status: eventStatus } }) => {
          state.selectedRows.selectedTaskIds = {
            ...state.selectedRows.selectedTaskIds,
            [taskId]: { status, isMandatory, eventStatus },
          }
        },
      )
    },
    deselectTask: (state, { payload: taskId }) => {
      delete state.selectedRows.selectedTaskIds[taskId]
    },
    clearTaskSelection: (state) => {
      state.selectedRows.selectedTaskIds = {}
    },
    tasksRowHasError: (state, { payload: { id, error } }) => {
      state.errorRows = {
        ...state.errorRows,
        [id]: { error },
      }
      state.rowsHaveError = true
    },
    resetTasksRowHasError: (state, { payload: { ids } }) => {
      for (const id of ids) {
        delete state.errorRows[id]
      }

      state.rowsHaveError = Object.keys(state.errorRows).length > 0
    },
    reset: () => initialState,
  },
})

export const {
  selectTask,
  selectAllTasks,
  deselectTask,
  clearTaskSelection,
  tasksRowHasError,
  resetTasksRowHasError,
  reset,
} = taskTableSlice.actions

export default taskTableSlice.reducer
