import {
  Title,
  TitleLevel,
  Option,
  Select,
  FlexBox,
  Text,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  FlexBoxAlignItems,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/deal-cashflow/result-charts/DealCashflowResultChartCardHeader.module.css'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import {
  useShortDateFormatter,
  useNumberFormatter,
  useCustomizableCurrencyFormatter,
} from 'hooks/i18n/useI18n'

export const DealCashflowResultChartCardHeader = ({
  selectedData,
  isLoading,
  isError,
  maturityData,
  handleOnChangeSelectedCategory,
  categories,
  cashflowKpis,
  setSelectedCashflowKpi,
  selectedCashflowKpi,
  selectedCategory,
  isFixed,
  title,
  showTitle = true,
  subtitlePrefix,
  currency,
  isOnDecisionPaper = false,
  isPdfViewOnDecisionPaper = false,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.cashflow.financial-ratios',
  })
  const { t: tNoPrefix } = useTranslation()

  const { format: formatDate } = useShortDateFormatter()

  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    notation: 'compact',
  })
  const formatCurrency = useCustomizableCurrencyFormatter({ notation: 'compact' })

  const getMaturityValue = () => {
    if (currency) {
      return maturityData ? formatCurrency(maturityData.value, currency) : t('chart.empty-value')
    }

    return maturityData
      ? formatNumber(maturityData.value) + tNoPrefix('unit.percent')
      : t('chart.empty-value')
  }

  return (
    <FlexBox
      direction={FlexBoxDirection.Row}
      alignItems={FlexBoxAlignItems.End}
      justifyContent={FlexBoxJustifyContent.Start}
      className={
        !isOnDecisionPaper ? styles.cardHeaderInfos : styles.cardHeaderDecisionPaperBanksCase
      }
    >
      <FlexBox direction={FlexBoxDirection.Column}>
        {showTitle && (
          <Title className={styles.title} level={TitleLevel.H5}>
            {title}
          </Title>
        )}
        <Text className={styles.label}>
          {t('header.text.day1', {
            prefix: subtitlePrefix,
            date:
              selectedData && !isEmpty(selectedData)
                ? formatDate(selectedData.rawValues?.[0]?.endDate)
                : t('chart.empty-value'),
            unit: currency ? tNoPrefix('unit.currency') : tNoPrefix('unit.percent'),
          })}
        </Text>
        <Title className={styles.value} wrappingType="Normal" level={TitleLevel.H1}>
          {selectedData && !isEmpty(selectedData)
            ? formatNumber(selectedData.rawValues?.[0]?.value)
            : t('chart.empty-value')}
        </Title>
      </FlexBox>
      <FlexBox
        direction={FlexBoxDirection.Column}
        alignItems={FlexBoxAlignItems.End}
        className={styles.cardHeaderInfosRight}
      >
        {!isFixed && (
          <LoadingStateWrapper isLoading={isLoading} isError={isError}>
            <div className={styles.selectWrapper}>
              {!isPdfViewOnDecisionPaper ? (
                <>
                  <Select
                    onChange={({
                      detail: {
                        selectedOption: { value },
                      },
                    }) => {
                      handleOnChangeSelectedCategory(value)
                    }}
                    id="category-select"
                    className={styles.selectCategory}
                  >
                    {categories.map((category) => (
                      <Option
                        key={category}
                        value={category}
                        selected={category === selectedCategory}
                      >
                        {category || t('no-category')}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    onChange={({
                      detail: {
                        selectedOption: { value },
                      },
                    }) => {
                      setSelectedCashflowKpi(value)
                    }}
                    id="cashflow-kpi-select"
                    className={styles.selectCashflowKpi}
                  >
                    {cashflowKpis.map((cashflowKpi) => (
                      <Option
                        key={cashflowKpi.ckpiId}
                        value={cashflowKpi.ckpiId}
                        selected={cashflowKpi.ckpiId === selectedCashflowKpi}
                      >
                        {cashflowKpi.name}
                      </Option>
                    ))}
                  </Select>
                </>
              ) : (
                <>
                  <Text className={styles.selectCategory}>
                    {selectedCategory || t('no-category')}
                  </Text>
                  <Text>
                    {
                      cashflowKpis.find((cashflowKpi) => cashflowKpi.ckpiId === selectedCashflowKpi)
                        ?.name
                    }
                  </Text>
                </>
              )}
            </div>
          </LoadingStateWrapper>
        )}
        <Text className={styles.label}>
          {t('header.text.maturity', {
            date: maturityData.endDate ? formatDate(maturityData.endDate) : t('chart.empty-value'),
          })}
        </Text>
        <Text className={styles.value}>{getMaturityValue()}</Text>
      </FlexBox>
    </FlexBox>
  )
}

DealCashflowResultChartCardHeader.propTypes = {
  selectedData: PropTypes.object,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  maturityData: PropTypes.object,
  handleOnChangeSelectedCategory: PropTypes.func,
  categories: PropTypes.array,
  cashflowKpis: PropTypes.array,
  setSelectedCashflowKpi: PropTypes.func,
  selectedCashflowKpi: PropTypes.string,
  selectedCategory: PropTypes.string,
  isFixed: PropTypes.bool,
  title: PropTypes.string,
  showTitle: PropTypes.bool,
  subtitlePrefix: PropTypes.string,
  currency: PropTypes.string,
  isOnDecisionPaper: PropTypes.bool,
  isPdfViewOnDecisionPaper: PropTypes.bool,
}
