import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { decisionPaperSynchronizationStatus } from 'api/decision-paper/decisionPaperApi'
import useDecisionStageName from 'components/domains/business-events-and-tasks/common/useDecisionStageName'
import EmptyDecisionPaper from 'components/domains/business-events-and-tasks/decision-paper/EmptyDecisionPaper'
import DecisionPaperPdfExportHeader from 'components/domains/business-events-and-tasks/decision-paper/pdf-export/DecisionPaperPdfExportHeader'
import DecisionPaperPdfExportPermissionWrapper from 'components/domains/business-events-and-tasks/decision-paper/pdf-export/DecisionPaperPdfExportPermissionWrapper'
import EventProvider from 'components/domains/business-events-and-tasks/decision-paper/pdf-export/EventProvider'
import DecisionPaperReduxStateLoaderWrapper from 'components/domains/business-events-and-tasks/decision-paper/redux-loader/DecisionPaperReduxStateLoaderWrapper'
import DecisionStageApprovalCardView from 'components/domains/business-events-and-tasks/decision-stage/approval/DecisionStageApprovalCardView'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useDecisionPaperVersion from 'hooks/services/business-events-and-tasks/decision-papers/useDecisionPaperVersion'
import useDecisionStageApproval from 'hooks/services/business-events-and-tasks/decision-process/stages/approval/useDecisionStageApproval'
import styles from 'routes/business-events-and-tasks/decision-paper/pdf-export/DecisionPaperPdfExport.module.css'
import DecisionPaperPdfExportContent from 'routes/business-events-and-tasks/decision-paper/pdf-export/DecisionPaperPdfExportContent'
import { someTilesAreLoadingSelector } from 'routes/business-events-and-tasks/decision-paper/selectors/decisionPaperSelectors'
import { getLatestDecisionPaperSyncAndFreezeDates } from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperLastSyncDateUtils'

// Timeout for automatic printing function to allow rendering of the map and tables
const printingTimeout = 5000

const DecisionPaperPdfExport = () => {
  const [queryParams] = useSearchParams()
  const eventId = queryParams.get('event_id')
  const decisionStageId = queryParams.get('stage_id')
  const versionNumber = queryParams.get('version')
  const voterId = queryParams.get('voter_id')
  const [isPrintButtonEnabled, setIsPrintButtonEnabled] = useState(false)

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper.pdf-export',
  })

  const noTilesAvailable = useSelector((state) => isEmpty(state.decisionPaper.tilesOverview.tiles))
  const anyTileLoading = useSelector((state) =>
    Object.values(state.decisionPaper.tilesOverview.tiles).some(({ isLoading }) => isLoading),
  )
  const anyTileHasError = useSelector((state) =>
    Object.values(state.decisionPaper.tilesOverview.tiles).some(({ isError }) => isError),
  )
  const someTilesAreLoading = useSelector(someTilesAreLoadingSelector)

  useEffect(() => {
    if (noTilesAvailable || anyTileLoading || anyTileHasError || someTilesAreLoading) {
      return
    }
    setTimeout(() => {
      setIsPrintButtonEnabled(true)
    }, printingTimeout)
  }, [anyTileHasError, anyTileLoading, noTilesAvailable, someTilesAreLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  const {
    isLoading: isDecisionPaperLoading,
    isError: isDecisionPaperError,
    data: decisionPaper,
  } = useDecisionPaperVersion({ eventId, versionNumber })

  const enableApprovalHook = useMemo(() => !isNil(decisionStageId), [decisionStageId])

  const { latestMajorVersionSyncDate, latestMinorVersionSyncDate, frozenDate } = useMemo(() => {
    if (!decisionPaper) return {}

    return getLatestDecisionPaperSyncAndFreezeDates(
      decisionPaper?.availableVersions,
      decisionPaper?.currentVersion,
    )
  }, [decisionPaper])

  const {
    isLoading: isStageApprovalLoading,
    isError: isStageApprovalError,
    data: decisionStageApprovalData,
  } = useDecisionStageApproval(
    { eventId, decisionStageId, voterId },
    { enabled: enableApprovalHook },
  )
  const decisionStageName = useDecisionStageName({
    name: decisionStageApprovalData?.decisionStageData.info.name,
    decisionStageType: decisionStageApprovalData?.decisionStageData.info.decisionType,
  })

  const isStageApprovalLoadingInternal = useMemo(
    () => isStageApprovalLoading && enableApprovalHook,
    [enableApprovalHook, isStageApprovalLoading],
  )

  const renderContent = useCallback(
    () => (
      <>
        <DecisionPaperPdfExportHeader
          isFrozen={
            decisionPaper?.synchronizationStatus === decisionPaperSynchronizationStatus.frozen
          }
          version={decisionPaper?.currentVersion}
          frozenDate={decisionPaper?.frozenDate}
          isPrintButtonEnabled={isPrintButtonEnabled}
        />
        <div className={styles.decisionPaperWrapper}>
          {!isNil(decisionStageId) && (
            <DecisionStageApprovalCardView
              decisionPaperVersion={decisionPaper?.currentVersion}
              frozenOn={frozenDate}
              majorVersionSyncDate={latestMajorVersionSyncDate}
              minorVersionSyncDate={latestMinorVersionSyncDate}
              decisionStageName={decisionStageName}
              decisionStageStatus={decisionStageApprovalData.decisionStageData.info.decisionStatus}
              voterStatus={decisionStageApprovalData.userVote.status.code}
              declineReason={decisionStageApprovalData.userVote.declineReason}
              decisionDate={decisionStageApprovalData.decisionStageData.info.decisionDate}
              templateName={decisionStageApprovalData.decisionPaperData.name}
            />
          )}
          <DecisionPaperPdfExportContent decisionPaper={decisionPaper} />
        </div>
      </>
    ),
    [
      decisionPaper,
      decisionStageApprovalData,
      decisionStageId,
      decisionStageName,
      frozenDate,
      isPrintButtonEnabled,
      latestMajorVersionSyncDate,
      latestMinorVersionSyncDate,
    ],
  )

  return (
    <EventProvider eventId={eventId}>
      <DecisionPaperPdfExportPermissionWrapper>
        <DecisionPaperReduxStateLoaderWrapper decisionPaper={decisionPaper} />
        {noTilesAvailable && isNil(decisionStageId) ? (
          <EmptyDecisionPaper titleText={t('empty')} subtitleText={t('empty.subtitle')} />
        ) : (
          <RequestStateResolver
            isLoading={isDecisionPaperLoading || anyTileLoading || isStageApprovalLoadingInternal}
            isError={isDecisionPaperError || anyTileHasError || isStageApprovalError}
            renderContent={renderContent}
            errorToDisplay={
              <ErrorDataUnavailableInContent
                titleText={t('loading.error.title')}
                subtitleText={t('loading.error.description')}
              />
            }
          />
        )}
      </DecisionPaperPdfExportPermissionWrapper>
    </EventProvider>
  )
}

export default DecisionPaperPdfExport
