import { AnalyticalTable, FlexBox, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/collaterals/shared/AnalyticalTableWithToolbar.module.css'

/* A copy and own of the analytical table but without the toolbar */
export const AnalyticalTableWithToolbar = React.forwardRef(
  (
    {
      title,
      columns,
      data,
      className,
      style,
      tableClassName,
      groupable,
      filterable,
      minRows = 0,
      disableColumnPopover = false,
      isPdfView = false,
      ...additionalTableConfig
    },
    ref,
  ) => {
    const tableRef = useRef()
    useImperativeHandle(ref, () => tableRef?.current)

    const [updateGroupingTo, setUpdateGroupingTo] = useState(null)
    const [grouping, setGrouping] = useState(null)
    const [isInitialGrouping, setIsInitialGrouping] = useState(true)

    const updateActiveGrouping = useCallback(
      (activeColumns) => {
        if (groupable && tableRef.current) {
          setGrouping((prev) => ({
            ...prev,
            groups: prev.groups.map((group) => ({
              ...group,
              isGrouped: activeColumns.includes(group.id),
            })),
          }))
        }
      },
      [groupable],
    )

    const extractAndSetGrouping = useCallback(() => {
      if (groupable && tableRef.current) {
        const { allColumns, toggleHideColumn, state = {} } = tableRef.current
        const { hiddenColumns = [] } = state
        const groups = allColumns
          .filter(({ canGroupBy }) => canGroupBy)
          .sort((a, b) => (a.dialogOrder || 0) - (b.dialogOrder || 0))
          .map(({ groupByLabel, Header, id, isGrouped }) => ({
            label: groupByLabel || Header,
            id,
            isGrouped: !!isGrouped,
          }))
        setGrouping({
          groups,
          setGroupBy: (column) => {
            if (hiddenColumns.includes(column)) {
              toggleHideColumn(column)
            }
            const value = column ? [column] : []
            setUpdateGroupingTo(value)
            updateActiveGrouping(value)
          },
        })
      }
    }, [groupable, updateActiveGrouping])

    useEffect(() => {
      if (updateGroupingTo) {
        const { setGroupBy } = tableRef.current
        setGroupBy(updateGroupingTo)
        setUpdateGroupingTo(null)
        updateActiveGrouping([])
      }
      if (isInitialGrouping) {
        extractAndSetGrouping()
        setIsInitialGrouping(false)
      }
    }, [updateGroupingTo, extractAndSetGrouping, updateActiveGrouping, isInitialGrouping])

    useLayoutEffect(() => {
      if (tableRef.current) {
        const { allColumns, setHiddenColumns, state } = tableRef.current
        const { groupBy = [] } = state
        const hiddenColumns = allColumns
          .filter(({ hide }) => hide)
          .map(({ id }) => id)
          .filter((id) => !groupBy.includes(id))
        setHiddenColumns(hiddenColumns)
      }
      if (disableColumnPopover) {
        require('components/ui/tables/analytical/DisableColumnHeaderModalPopover.module.css')
      }
    }, [disableColumnPopover, grouping])

    return (
      <FlexBox className={className} style={style} direction="Column">
        <Label className={styles.titleLabel}>{title}</Label>
        <AnalyticalTable
          minRows={minRows}
          className={`${tableClassName || ''} ${styles['analytical-table']} ${
            additionalTableConfig?.isTreeTable ? 'tree-table' : ''
          } ${isPdfView ? 'pdf-view' : ''}`.trim()}
          columns={columns}
          data={data}
          groupable={groupable}
          filterable={filterable}
          tableInstance={tableRef}
          reactTableOptions={{
            autoResetHiddenColumns: false,
          }}
        />
      </FlexBox>
    )
  },
)

AnalyticalTableWithToolbar.displayName = 'AnalyticalTableWithToolbar'

AnalyticalTableWithToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  tableClassName: PropTypes.string,
  toolbarClassName: PropTypes.string,
  groupable: PropTypes.bool,
  filterable: PropTypes.bool,
  isPdfView: PropTypes.bool,
  minRows: PropTypes.number,
  disableColumnPopover: PropTypes.bool,
}
