import {
  FlexBox,
  FlexBoxDirection,
  Table,
  TableColumn,
  TableRow,
  TableCell,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/OpsMemoTrancheDetailsCard.module.css'
import {
  useNumberFormatter,
  useShortDateFormatter,
  useCustomizableCurrencyFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const AGENT_ROLE_KEY = 'Agent'

const OpsMemoTrancheFeesCard = ({ tranche, memberRoleTypeCodes }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.ops-memo-tranche-details.fees',
  })
  const { t: tTable } = useTranslation('decisionPaper', {
    keyPrefix: 'components.table',
  })
  const formatPercentage = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })
  const { format: formatDate } = useShortDateFormatter()
  const formatMoney = useCustomizableCurrencyFormatter()

  const fees = tranche?.fees?.slice()?.sort((a, b) => {
    const dateA = new Date(a?.firstPaymentDate)
    const dateB = new Date(b?.firstPaymentDate)
    return dateA - dateB
  })

  const renderMoneyValue = (value) =>
    !isNil(value?.amount) && value.currency && formatMoney(value.amount, value.currency)

  const renderTableColumn = (title) => (
    <TableColumn>
      <div className={styles.tableCellPadding}>{title}</div>
    </TableColumn>
  )

  const renderTableCell = (value) => (
    <TableCell className={styles.tableCellPadding}>{value}</TableCell>
  )

  const renderAmountToBeBooked = (value, valueHeadquarter) => {
    const agentRoleDisplayName = memberRoleTypeCodes?.find(
      (typeCode) => typeCode.key === AGENT_ROLE_KEY,
    )?.displayName

    if (isNil(value?.amount)) {
      return <TableCell />
    } else if (isNil(tranche?.financialRole) || tranche?.financialRole === agentRoleDisplayName) {
      return (
        <TableCell className={styles.tableCellPadding}>
          <FlexBox direction={FlexBoxDirection.Column}>
            <div>{renderMoneyValue(value)}</div>
            <div className={styles.textConvertedAmount}>
              {valueHeadquarter?.currency !== value?.currency && renderMoneyValue(valueHeadquarter)}
            </div>
          </FlexBox>
        </TableCell>
      )
    } else {
      const calculatedValue = {
        amount: Number(value?.amount * tranche?.ownCommitmentShare) ?? undefined,
        currency: value?.currency,
      }
      const calculatedValueHeadquarter = {
        amount: Number(valueHeadquarter?.amount * tranche?.ownCommitmentShare) ?? undefined,
        currency: valueHeadquarter?.currency,
      }
      return (
        <TableCell className={styles.tableCellPadding}>
          <FlexBox direction={FlexBoxDirection.Column}>
            <div>{renderMoneyValue(calculatedValue)}</div>
            <div className={styles.textConvertedAmount}>
              {valueHeadquarter?.currency !== value?.currency &&
                renderMoneyValue(calculatedValueHeadquarter)}
            </div>
          </FlexBox>
        </TableCell>
      )
    }
  }

  const renderTableColumns = () => (
    <>
      {renderTableColumn(t('fee-type'))}
      {renderTableColumn(t('first-payment-date'))}
      {renderTableColumn(t('payment-frequency'))}
      {renderTableColumn(t('amount-total'))}
      {renderTableColumn(t('amount-share'))}
      {renderTableColumn(t('comment'))}
    </>
  )

  const renderTableRows = () =>
    fees?.length > 0 &&
    fees?.map((item) => (
      <TableRow key={item?.id}>
        {renderTableCell(item?.feeTypeShortText)}
        {renderTableCell(item?.firstPaymentDate && formatDate(item.firstPaymentDate))}
        {renderTableCell(item?.paymentFrequencyTypeShortText)}
        {renderAmountToBeBooked(item?.amountTotal, item?.amountTotalHeadquarter)}
        {renderTableCell(formatPercentage(item?.amountShare))}
        {renderTableCell(item?.comment)}
      </TableRow>
    ))

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.card}>
      <Text className={styles.cardHeaderTitle}>
        {t('fees')} {`(${fees?.length})`}
      </Text>

      <FlexBox direction={FlexBoxDirection.Column} className={styles.tableWrapper}>
        <Table
          columns={renderTableColumns()}
          noDataText={tTable('no-data')}
          className={styles.table}
        >
          {renderTableRows()}
        </Table>
      </FlexBox>
    </FlexBox>
  )
}

OpsMemoTrancheFeesCard.propTypes = {
  tranche: PropTypes.shape({
    financialRole: PropTypes.string,
    fees: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        feeTypeCode: PropTypes.string,
        feeTypeShortText: PropTypes.string,
        isTotalAndSharedDependent: PropTypes.bool,
        paymentFrequencyType: PropTypes.string,
        paymentFrequencyTypeShortText: PropTypes.string,
        amountTotal: PropTypes.shape({
          amount: PropTypes.number,
          currency: PropTypes.string,
        }),
        amountTotalHeadquarter: PropTypes.shape({
          amount: PropTypes.number,
          currency: PropTypes.string,
        }),
        amountShare: PropTypes.number,
        comment: PropTypes.string,
        firstPaymentDate: PropTypes.string,
      }),
    ),
    ownCommitmentShare: PropTypes.number,
  }),
  memberRoleTypeCodes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      displayName: PropTypes.string,
    }),
  ),
}

export default OpsMemoTrancheFeesCard
