import { Input } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/covenants/create-dialog/CreateCovenantForm.module.css'
import ReferenceEntitySelect from 'components/domains/deals/covenants/create-dialog/ReferenceEntitySelect'
import CurrencySelect from 'components/domains/deals/covenants/shared/CurrencySelect'
import Labeled from 'components/ui/data/Labeled'
import TextAreaWithMaxCharacterRestriction from 'components/ui/input/TextAreaWithMaxCharacterRestriction'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { standardMappings, useFormMapped } from 'hooks/form/useFormMapped'
import { useFormValidations } from 'hooks/form/useFormValidations'

const descriptionMaxLength = 500

const CreateCovenantForm = ({
  covenantDefinitions = [],
  isLoadingDefinitions,
  isErrorDefinitions,
  tranches = [],
  isLoadingTranches,
  isErrorTranches,
  referenceEntityTypeData = [],
  onSubmit,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.covenants' })

  const { required } = useFormValidations()
  const { register, watch, setValue } = useFormMapped()

  const selectedDefinitionUuid = watch('configCovenantDefinitionUuid')
  const isCovenantFinancial =
    covenantDefinitions.find(
      ({ configCovenantDefinitionUuid }) => configCovenantDefinitionUuid === selectedDefinitionUuid,
    )?.type === 'FINANCIAL'
  const covenantUnit = covenantDefinitions.find(
    ({ configCovenantDefinitionUuid }) => configCovenantDefinitionUuid === selectedDefinitionUuid,
  )?.unit
  const isCovenantUnitCurrency = covenantUnit === 'CURRENCY'
  const referenceEntityType = watch('referenceEntity.type')

  const getCovenantUnitInput = () => {
    if (isCovenantFinancial && !isCovenantUnitCurrency) {
      return <Input id="percentage-input" disabled value={'%'} />
    } else if (isCovenantFinancial && isCovenantUnitCurrency) {
      return (
        <CurrencySelect
          disabled={!selectedDefinitionUuid || !isCovenantFinancial}
          registerName="currencyCode"
        />
      )
    } else {
      return <Input disabled value={''} />
    }
  }

  return (
    <form onSubmit={onSubmit} className={styles.form}>
      <Labeled label={t('covenant')} showColon vertical required>
        <LoadingSelect
          id="covenant"
          loadingHook={() => ({
            isLoading: isLoadingDefinitions,
            isError: isErrorDefinitions,
            data: covenantDefinitions,
          })}
          optionDisplayName="name"
          optionKeyName="configCovenantDefinitionUuid"
          {...register('configCovenantDefinitionUuid', {
            ...standardMappings.LoadingSelect,
            handlerMappings: {
              ...standardMappings.LoadingSelect.handlerMappings,
              onChange: (_, { trigger }) => ({
                onChange: async (e, value) => {
                  await setValue(e.target.name, value)
                  await setValue('currencyCode', undefined)
                  await trigger(e.target.name)
                },
              }),
            },
            validate: {
              required: required(),
            },
          })}
        />
      </Labeled>

      <Labeled label={t('create.description')} showColon vertical>
        <TextAreaWithMaxCharacterRestriction
          id="description"
          maxLength={descriptionMaxLength}
          rows="5"
          {...register('description', {
            handlerMappings: {
              onChange: (handler) => ({ onInput: (_, evt) => handler(evt) }),
            },
          })}
        />
      </Labeled>

      <Labeled
        label={t('create.unit')}
        showColon
        vertical
        required={selectedDefinitionUuid && isCovenantFinancial && covenantUnit === 'CURRENCY'}
      >
        {getCovenantUnitInput()}
      </Labeled>

      <Labeled label={t('create.reference-entity-type')} showColon vertical required>
        <LoadingSelect
          id="reference-entity-type"
          loadingHook={() => ({
            isLoading: false,
            isError: false,
            data: referenceEntityTypeData,
          })}
          selectionName="referenceEntityTypes"
          optionKeyName="key"
          optionDisplayName="value"
          {...register('referenceEntity.type', {
            ...standardMappings.LoadingSelect,
            validate: {
              required: required(),
            },
            handlerMappings: {
              ...standardMappings.LoadingSelect.handlerMappings,
              onChange: (_, { trigger }) => ({
                onChange: async (e, value) => {
                  await setValue(e.target.name, value)
                  await setValue('referenceEntity.ids', [])
                  await trigger(e.target.name)
                },
              }),
            },
          })}
        />
      </Labeled>

      <Labeled label={t('reference-entity')} showColon vertical required>
        <ReferenceEntitySelect
          referenceEntityType={referenceEntityType}
          isLoadingTranches={isLoadingTranches}
          isErrorTranches={isErrorTranches}
          tranches={tranches}
        />
      </Labeled>
    </form>
  )
}

CreateCovenantForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  covenantDefinitions: PropTypes.array,
  isLoadingDefinitions: PropTypes.bool,
  isErrorDefinitions: PropTypes.bool,
  tranches: PropTypes.array,
  isLoadingTranches: PropTypes.bool,
  isErrorTranches: PropTypes.bool,
  referenceEntityTypeData: PropTypes.object,
}

export default CreateCovenantForm
