import { Button, ButtonDesign, CardHeader, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { propertyMonitoringProxiesAllowedOperations } from 'api/property/monitoring/propertyMonitoringConfiguration'
import styles from 'components/domains/markets/detail/cards/MarketMacroProxiesCard.module.css'
import MarketMicroProxiesTable from 'components/domains/markets/detail/cards/MarketMicroProxiesTable'
import { sortMarketProxies } from 'components/domains/markets/detail/cards/helper/marketProxiesHelper.js'
import CancelButtonWithPopover from 'components/ui/button/CancelButtonWithPopover'
import LoadingButton from 'components/ui/button/LoadingButton'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInTable } from 'components/ui/errors/ErrorDataUnavailableInTable'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import useMarketCompatiblePropertyTypes from 'hooks/services/markets/useMarketCompatiblePropertyTypes'
import usePropertyMonitoringProxiesAllowedOperations from 'hooks/services/properties/monitoring/proxies/usePropertyMonitoringProxiesAllowedOperations'
import { useMarketMicroProxies } from 'hooks/services/properties/monitoring/useMarketMicroProxies'
import { useUpdateMarketMicroProxies } from 'hooks/services/properties/monitoring/useUpdateMarketMicroProxies.js'
import { useTypecodes } from 'hooks/services/properties/useTypecodes'
import {
  cancelEditing,
  endEditing,
  setProxies,
  startEditing,
} from 'redux/slices/markets/marketMicroProxiesSlice'

const MarketMicroProxiesCard = ({ marketId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.markets.detail.manager.market-proxies',
  })
  const { t: tNoPrefix } = useTranslation()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()
  const { mutate: updateMicroProxies, isLoading: isSaveLoading } = useUpdateMarketMicroProxies()
  const {
    data: marketMicroProxiesData,
    isLoading: isMarketMicroProxiesDataLoading,
    isError: isMarketMicroProxiesDataError,
  } = useMarketMicroProxies({ marketId })
  const {
    data: marketCompatiblePropertyTypesData,
    isLoading: isMarketCompatiblePropertyTypesLoading,
    isError: isMarketCompatiblePropertyTypesError,
  } = useMarketCompatiblePropertyTypes({ marketId })
  const {
    data: propertyTypesData,
    isLoading: isPropertyTypesLoading,
    isError: isPropertyTypesError,
  } = useTypecodes()
  const {
    data: proxiesAllowedOperations,
    isLoading: isProxiesAllowedOperationsLoading,
    isError: isProxiesAllowedOperationsError,
  } = usePropertyMonitoringProxiesAllowedOperations()
  const hasEditPermissions = (proxiesAllowedOperations ?? []).includes(
    propertyMonitoringProxiesAllowedOperations.proxySettingsUpdate,
  )

  const compatiblePropertyTypeCodes = useMemo(
    () => new Set((marketCompatiblePropertyTypesData?.propertyTypes ?? []).map(({ code }) => code)),
    [marketCompatiblePropertyTypesData?.propertyTypes],
  )

  const isEditing = useSelector((state) => state.markets.marketMicroProxies.isEditing)
  const hasUnsavedChanges = useSelector(
    (state) => state.markets.marketMicroProxies.hasUnsavedChanges,
  )
  const hasErrors = useSelector((state) => state.markets.marketMicroProxies.hasErrors)

  const microProxies = useSelector((state) => state.markets.marketMicroProxies.proxies)

  const handleSaveSuccess = useCallback(() => {
    queryClient.invalidateQueries(['property-monitoring', 'proxies', 'micros', marketId])
    dispatch(endEditing())
    showToast({ children: t('save.success.text') })
  }, [queryClient, marketId, dispatch, showToast, t])

  const handleSaveError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      titleText: t('save.error.title'),
      children: t('save.error.text'),
      actions: [MessageBoxActions.OK],
    })
  }, [showMessageBox, t])

  const handleEditButtonClicked = useCallback(() => {
    dispatch(startEditing())
  }, [dispatch])
  const handleCancelButtonClicked = useCallback(() => {
    dispatch(cancelEditing())
  }, [dispatch])
  const saveProxies = useCallback(() => {
    const editedProxies = microProxies
      .filter(({ overrideProxy, isOverride }) => isOverride && !isNil(overrideProxy))
      .map(
        ({ key: type, overrideProxy: { threshold, thresholdType, direction, isMonitored } }) => ({
          type,
          threshold,
          thresholdType,
          direction,
          isMonitored,
        }),
      )
    updateMicroProxies(
      { marketId, microProxies: editedProxies },
      {
        onSuccess: handleSaveSuccess,
        onError: handleSaveError,
      },
    )
  }, [microProxies, updateMicroProxies, marketId, handleSaveSuccess, handleSaveError])

  const showSaveDialog = useCallback(
    () =>
      showMessageBox({
        type: MessageBoxTypes.Confirm,
        titleText: t('save.confirmation.title'),
        children: t('save.confirmation.text'),
        actions: [
          <Button key="button-confirm" design={ButtonDesign.Emphasized} onClick={saveProxies}>
            {tNoPrefix('buttons.save')}
          </Button>,
          MessageBoxActions.Cancel,
        ],
      }),
    [saveProxies, showMessageBox, t, tNoPrefix],
  )

  const propertyTypes = useMemo(
    () =>
      (propertyTypesData?.typecodes ?? [])
        .filter(({ key }) => compatiblePropertyTypeCodes.has(key))
        .sort(),
    [compatiblePropertyTypeCodes, propertyTypesData?.typecodes],
  )

  const [selectedPropertyTypeCode, setSelectedPropertyTypeCode] = useState(propertyTypes[0]?.key)

  useEffect(() => {
    if (isNil(selectedPropertyTypeCode) && propertyTypes.length > 0) {
      setSelectedPropertyTypeCode(propertyTypes[0].key)
    }
  }, [propertyTypes, propertyTypesData?.typecodes, selectedPropertyTypeCode])

  useEffect(() => {
    if (isNil(marketMicroProxiesData) || isNil(marketCompatiblePropertyTypesData)) {
      return
    }

    const filteredDefaultProxySettings =
      marketMicroProxiesData.defaultMicroProxySettings.find(
        ({ propertyType }) => propertyType === selectedPropertyTypeCode,
      )?.settings ?? []
    const defaultProxySettings = Object.fromEntries(
      filteredDefaultProxySettings.map(({ type, ...proxy }) => [type, proxy]),
    )
    const overrideProxySettings = Object.fromEntries(
      marketMicroProxiesData.overrideMicroProxySettings.map(({ type, ...proxy }) => [type, proxy]),
    )
    const microProxyData = marketMicroProxiesData.availableMicroProxies
      .map(({ type, name, unit }) => ({
        key: type,
        name: name,
        unit: unit,
        isOverride: !isNil(overrideProxySettings[type]),
        defaultProxy: defaultProxySettings[type],
        overrideProxy: overrideProxySettings[type],
      }))
      .sort(sortMarketProxies)

    dispatch(setProxies(microProxyData))
  }, [
    compatiblePropertyTypeCodes,
    dispatch,
    marketCompatiblePropertyTypesData,
    marketMicroProxiesData,
    selectedPropertyTypeCode,
  ])

  const renderMicroProxiesTable = useCallback(
    () => (
      <div className={styles.tableWrapper} {...(isSaveLoading && { inert: '' })}>
        <MarketMicroProxiesTable
          propertyTypes={propertyTypes}
          selectedPropertyTypeCode={selectedPropertyTypeCode}
          onPropertyTypeSelect={setSelectedPropertyTypeCode}
        />
      </div>
    ),
    [isSaveLoading, propertyTypes, selectedPropertyTypeCode],
  )
  const saveButtonContent = useCallback(() => tNoPrefix('buttons.save'), [tNoPrefix])
  const headerActions = useMemo(
    () =>
      isEditing ? (
        [
          <LoadingButton
            renderContent={saveButtonContent}
            design={ButtonDesign.Emphasized}
            key="saveProxies"
            onClick={showSaveDialog}
            isLoading={isSaveLoading}
            disabled={hasErrors || !hasUnsavedChanges}
          />,
          <CancelButtonWithPopover
            key="cancelEditing"
            onCancelClicked={handleCancelButtonClicked}
            showPopover={hasUnsavedChanges}
            disabled={isSaveLoading}
          />,
        ]
      ) : (
        <Button design={ButtonDesign.Transparent} onClick={handleEditButtonClicked}>
          {tNoPrefix('buttons.edit')}
        </Button>
      ),
    [
      handleCancelButtonClicked,
      handleEditButtonClicked,
      hasErrors,
      hasUnsavedChanges,
      isEditing,
      isSaveLoading,
      saveButtonContent,
      showSaveDialog,
      tNoPrefix,
    ],
  )

  return (
    <Card
      header={
        <CardHeader titleText={t('title.micro')} action={hasEditPermissions && headerActions} />
      }
    >
      <RequestStateResolver
        isLoading={
          isPropertyTypesLoading ||
          isMarketCompatiblePropertyTypesLoading ||
          isMarketMicroProxiesDataLoading ||
          isProxiesAllowedOperationsLoading
        }
        isError={
          isPropertyTypesError ||
          isMarketCompatiblePropertyTypesError ||
          isMarketMicroProxiesDataError ||
          isProxiesAllowedOperationsError
        }
        center
        errorToDisplay={<ErrorDataUnavailableInTable />}
        renderContent={renderMicroProxiesTable}
      />
    </Card>
  )
}

MarketMicroProxiesCard.propTypes = {
  marketId: PropTypes.string.isRequired,
}

export default MarketMicroProxiesCard
