import { MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import BusinessEventsAndTasksMassEditDialog from 'components/domains/business-events-and-tasks/mass-edit/BusinessEventsAndTasksMassEditDialog'
import useMassEditTasks from 'hooks/services/business-events-and-tasks/tasks/useMassEditTasks'
import { reset } from 'redux/slices/tasks/taskTableSlice'

const TaskManagerMultiEditDialog = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.tasks.dialogs.mass-edit' })
  const showMessageBox = Modals.useShowMessageBox()
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()
  const { mutate: massEditTasks, isLoading } = useMassEditTasks()

  const dispatch = useDispatch()

  const selectedTasks = useSelector((state) => state.tasks.taskTable.selectedRows.selectedTaskIds)

  const mappedTasksForMassEdit = useMemo(
    () =>
      Object.entries(selectedTasks).map(([id, { status, isMandatory, eventStatus }]) => ({
        id,
        status,
        isMandatory,
        eventStatus,
      })),
    [selectedTasks],
  )

  const onSaveSuccess = useCallback(() => {
    queryClient.invalidateQueries(['events'])
    setIsOpen(false)
    showToast({ children: t('success') })
    dispatch(reset())
  }, [setIsOpen, showToast, queryClient, t, dispatch])
  const onSaveError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('error'),
      draggable: true,
      resizable: true,
    })
  }, [showMessageBox, t])

  const onAfterClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const onSave = useCallback(
    (editedFields) => {
      massEditTasks(
        { taskIds: mappedTasksForMassEdit.map(({ id }) => id), editedFields },
        { onSuccess: onSaveSuccess, onError: onSaveError },
      )
    },
    [massEditTasks, mappedTasksForMassEdit, onSaveSuccess, onSaveError],
  )

  return (
    <BusinessEventsAndTasksMassEditDialog
      eventsOrTasks={mappedTasksForMassEdit}
      isOpen={isOpen}
      onAfterClose={onAfterClose}
      onSave={onSave}
      isLoading={isLoading}
      isEventList={false}
    />
  )
}

TaskManagerMultiEditDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default TaskManagerMultiEditDialog
