import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ErrorDataUnavailableInCell } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/errors/ErrorDataUnavailableInCell'
import SmallLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/loading/SmallLoadingWrapper'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/BpsCell.module.css'
import NumberCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/NumberCell'

const propTypes = {
  /**
   * The value to be formatted and displayed as the BPS.
   *
   * The value will be multiplied by 10000.
   *
   * The value can either be a single value (number) or an array of values, in order to display multiple rows.
   */
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number]),
  /**
   * Options to modify how the Cell will look like.
   *
   * - `isBold` Display the BPS value and unit in bold font. Defaults to false.
   * - `maximumFractionDigits` Defines the maximum digits of the BPS value. Defaults to 1.
   * - `minimumFractionDigits` Defines the minimum digits of the BPS value. Defaults to 1.
   */
  options: PropTypes.shape({
    isBold: PropTypes.bool,
    maximumFractionDigits: PropTypes.number,
    minimumFractionDigits: PropTypes.number,
  }),
  /** Defines whether a loading spinner is shown. */
  isLoading: PropTypes.bool,
  /** Defines whether a loading spinner is shown. */
  isFetching: PropTypes.bool,
  /** Defines whether an error is shown. */
  isError: PropTypes.bool,
  /** Allows to define a custom error component. Defaults to undefined so that `<ErrorDataUnavailableInCell />` is shown. */
  errorToDisplay: PropTypes.node,
  /** Defines additional styling to the outmost div of the cell's content. */
  className: PropTypes.string,
}

/**
 * Shared Bps Cell for usage within the Responsive and the Analytical Table.
 *
 * It displays a BPS value with its unit. It is a wrapper around the NumberCell.
 *
 * @typedef Props
 * @property {Number} value The value to be formatted and displayed as the BPS.
 * @property {Object} options Options to modify how the Cell will look like.
 * @property {Boolean} options.isBold Display the BPS value and unit in bold font. Defaults to false.
 * @property {Boolean} options.maximumFractionDigits Defines the maximum digits of the BPS value. Defaults to 1.
 * @property {Boolean} options.minimumFractionDigits Defines the minimum digits of the BPS value. Defaults to 1.
 * @property {Boolean} isLoading Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} isFetching Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} isError Defines whether an error is shown.
 * @property {React.ReactNode} errorToDisplay Allows to define a custom error component. Defaults to undefined so that <ErrorDataUnavailableInCell /> is shown.
 * @property {String} className Defines additional styling to the outmost div of the cell's content.
 */

/**
 * @param {Props} props Note for Storybook: cf. code for complete JSDoc
 */
const bpsMultiplierDivider = 10000

const BpsCell = ({
  value,
  options: { isBold, maximumFractionDigits, minimumFractionDigits },
  isLoading = false,
  isFetching = false,
  isError = false,
  className,
  errorToDisplay,
}) => {
  const { t } = useTranslation('decisionPaper')

  if (isNil(value)) {
    return <div />
  }

  if (!Array.isArray(value)) {
    value = [value]
  }

  const renderNumberCell = (item) => {
    if (isNil(item)) {
      return <>&nbsp;</>
    }

    return (
      <NumberCell
        number={Number(item * bpsMultiplierDivider)}
        unit={t('bps')}
        options={{
          isBold,
          showWhitespaceBeforeUnit: true,
          maximumFractionDigits,
          minimumFractionDigits,
        }}
      />
    )
  }

  const renderContent = () => (
    <FlexBox
      direction={FlexBoxDirection.Column}
      className={compact([styles.contentWrapper, className]).join(' ')}
    >
      {value.map((item, index) => (
        <div key={index}>{renderNumberCell(item)}</div>
      ))}
    </FlexBox>
  )

  return (
    <SmallLoadingWrapper
      isLoading={isLoading || isFetching}
      isError={isError}
      error={errorToDisplay ?? <ErrorDataUnavailableInCell />}
      renderContent={renderContent}
    />
  )
}

BpsCell.propTypes = propTypes

BpsCell.defaultProps = {
  options: {
    isBold: false,
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  },
}

export default BpsCell
