import { TextAlign, VerticalAlign } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import DateCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/cells/DateCell'
import EntityCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/cells/EntityCell'
import StatusCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/cells/StatusCell'
import TextCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/cells/TextCell'

const columnKeyPrefix = 'tiles.forbearance.measures-table.columns'

const getDefaultColumnDefinition = (columnKey, tColumns) => ({
  Header: () => <Trans ns="decisionPaper" i18nKey={`${columnKeyPrefix}.${columnKey}`} />,
  title: tColumns(columnKey).replace('<br/>', ''),
  accessor: columnKey,
  width: 200,
})

const useForbearanceMeasuresTableColumnDefinitions = () => {
  const { t: tColumns } = useTranslation('decisionPaper', {
    keyPrefix: columnKeyPrefix,
  })

  const allColumns = useMemo(
    () => ({
      forbearanceMeasureVersion: {
        ...getDefaultColumnDefinition('forbearanceMeasureVersion', tColumns),
        width: 400,
        Cell: ({ cell }) => TextCell({ text: cell.value }),
      },
      status: {
        ...getDefaultColumnDefinition('status', tColumns),
        Cell: ({ cell }) => StatusCell({ ...cell.value }),
      },
      customerSigningDate: {
        ...getDefaultColumnDefinition('customerSigningDate', tColumns),
        hAlign: TextAlign.End,
        Cell: ({ cell }) => DateCell({ date: cell.value }),
      },
      startDate: {
        ...getDefaultColumnDefinition('startDate', tColumns),
        hAlign: TextAlign.End,
        Cell: ({ cell }) => DateCell({ date: cell.value }),
      },
      endDate: {
        ...getDefaultColumnDefinition('endDate', tColumns),
        hAlign: TextAlign.End,
        Cell: ({ cell }) => DateCell({ date: cell.value }),
      },
      createdOn: {
        ...getDefaultColumnDefinition('createdOn', tColumns),
        hAlign: TextAlign.Right,
        Cell: ({ cell }) => DateCell({ date: cell?.value }),
      },
      createdBy: {
        ...getDefaultColumnDefinition('createdBy', tColumns),
        Cell: ({ cell }) => TextCell({ text: cell?.value }),
      },
      comment: {
        ...getDefaultColumnDefinition('comment', tColumns),
        width: 500,
        Cell: ({ cell }) => TextCell({ text: cell.value }),
      },
      currentBusinessEvent: {
        ...getDefaultColumnDefinition('currentBusinessEvent', tColumns),
        minWidth: 300,
        Cell: ({ cell: { value } }) => EntityCell({ ...value }),
      },
    }),
    [tColumns],
  )

  const columnDefaults = useMemo(
    () => ({
      disableDragAndDrop: true,
      disableResizing: false,
      vAlign: VerticalAlign.Middle,
      disableSortBy: true,
    }),
    [],
  )

  const columns = useMemo(
    () =>
      Object.values(allColumns).map((column) => ({
        ...columnDefaults,
        ...column,
      })),
    [allColumns, columnDefaults],
  )

  return columns
}

export default useForbearanceMeasuresTableColumnDefinitions
