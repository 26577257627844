import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateMarketKpis = (mutationOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(async ({ marketId, kpiData }) => {
    const { data } = await post({
      path: `/markets/${marketId}/kpis`,
      body: {
        kpis: kpiData,
      },
    })
    return data
  }, mutationOptions)
}

export default useUpdateMarketKpis
