import { Modals, Toast } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import documentVersionPermissions from 'api/documents/DocumentVersionPermissions'
import styles from 'components/domains/documents/documents-table/DocumentsTableRowWrapper.module.css'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import { MessageBoxActions, MessageBoxTypes } from 'components/ui/message-box/MessageBox'
import useAnalyticalTableWrapperStyles from 'hooks/analytical-table/useAnalyticalTableWrapperStyles'
import { useDocumentVersionUpload } from 'hooks/services/documents/useDocumentVersionUpload'
import useDocumentsAllowedOperations from 'hooks/services/documents/useDocumentsAllowedOperations'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'
import AddDocumentVersionDialog from 'routes/documents/document-versions/AddDocumentVersionDialog'

/**
 * A wrapper component for a row in the documents table. It provides drag and drop functionality for uploading new document versions, and displays an icon when a file is being dragged over the row.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child elements to be wrapped by this component.
 * @param {string} props.height - The height of the row.
 * @param {Object} props.row - The row data object.
 * @param {string} props.row.original.id - The ID of the document.
 * @param {string} props.row.original.name - The name of the document.
 * @param {string} props.row.original.type - The type of the document.
 * @param {string} props.row.original.keyDate - The key date of the document.
 *
 * @returns {ReactNode} The rendered component.
 */
const DocumentsTableRowWrapper = ({ children, height, row }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.documents.detail.versions-table',
  })
  const [isOpenAddNewVersionDialog, setIsOpenAddNewVersionDialog] = useState(false)
  const [file, setFile] = useState()
  const [deletionError, setDeletionError] = useState()
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false)
  const queryClient = useQueryClient()
  const { wrapperStyles, setWrapper } = useAnalyticalTableWrapperStyles()

  const dropZoneRef = useRef(null)

  const { data: { allowedOperations: allowedDocumentOperations = [] } = {} } =
    useDocumentsAllowedOperations()

  const hasDocumentVersionCreatePermission = useMemo(
    () => allowedDocumentOperations?.includes(documentVersionPermissions.create),
    [allowedDocumentOperations],
  )

  const handleUploadSuccess = () => {
    queryClient.invalidateQueries(['documents'])
    Modals.showToast({ children: <span>{t('upload-success')}</span> })
    setFile(undefined)
  }

  const handleUploadError = async (error) => {
    setDeletionError(await formatHookError(error))
    setIsErrorMessageOpen(true)
    setFile(undefined)
  }

  const { mutate: uploadDocumentVersion } = useDocumentVersionUpload({
    onSuccess: handleUploadSuccess,
    onError: handleUploadError,
    documentId: row.original.id,
  })

  const handleUploadNewVersion = ({ file: fileToUpload, comment }) => {
    setIsOpenAddNewVersionDialog(false)
    uploadDocumentVersion({
      file: fileToUpload,
      comment,
      version: '',
    })
  }

  const isLeafRow = row.subRows.length === 0

  const handleDrop = useCallback(
    (newFiles) => {
      if (!isLeafRow) {
        return
      }

      if (newFiles?.files?.length > 1) {
        Modals.showMessageBox({
          actions: [MessageBoxActions.OK],
          type: MessageBoxTypes.Error,
          children: <span>{t('multi-document-error')}</span>,
        })
        return
      }
      const newFile = newFiles?.files?.[0]

      setFile(newFile)
      setIsOpenAddNewVersionDialog(true)
    },
    [isLeafRow, t],
  )

  const [{ isOver, canDrop }, dropRef] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop: handleDrop,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [],
  )

  const wrapperRef = useCallback(
    (element) => {
      dropRef(element)
      setWrapper(element)
    },
    [dropRef, setWrapper],
  )

  useEffect(() => {
    if (canDrop || isOver) {
      dropZoneRef?.current?.show()
    }
  }, [canDrop, isOver, dropZoneRef])

  const handleClose = () => {
    setIsErrorMessageOpen(false)
  }

  const wrapperClassName = classNames({
    [styles.isOver]: isOver && canDrop && isLeafRow,
    [styles.isNotOver]: !isOver && canDrop && isLeafRow,
    [styles.container]: true,
  })

  const computedWrapperStyles = useMemo(
    () => ({
      ...wrapperStyles,
      height,
    }),
    [height, wrapperStyles],
  )

  if (!hasDocumentVersionCreatePermission) {
    return children
  }

  return (
    <div className={wrapperClassName} style={computedWrapperStyles} ref={wrapperRef}>
      {canDrop && isOver && isLeafRow && (
        <Toast ref={dropZoneRef} placementType="Bottom" duration={60000}>
          {t('version-dropzone-upload-message')}
        </Toast>
      )}
      {children}
      {isOpenAddNewVersionDialog && (
        <AddDocumentVersionDialog
          open={isOpenAddNewVersionDialog}
          onAfterClose={() => setIsOpenAddNewVersionDialog(false)}
          onUploadNewVersion={handleUploadNewVersion}
          initialState={{
            documentName: row.original?.name,
            documentType: row.original?.type,
            keyDate: row.original?.keyDate ?? null,
            file,
          }}
        />
      )}
      {createPortal(
        <>
          {isErrorMessageOpen && (
            <ErrorMessageBoxWithExpandableDetails
              messageSummary={t('upload-error')}
              messageDetails={deletionError}
              isOpen={isErrorMessageOpen}
              onClose={handleClose}
            />
          )}
        </>,
        document.body,
      )}
    </div>
  )
}

DocumentsTableRowWrapper.propTypes = {
  children: PropTypes.any,
  height: PropTypes.string,
  requirementId: PropTypes.string,
  documentType: PropTypes.string,
  row: PropTypes.shape({
    subRows: PropTypes.arrayOf(PropTypes.object),
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      keyDate: PropTypes.string,
    }),
  }),
}

export default DocumentsTableRowWrapper
