import useStaffMembers from 'hooks/services/business-partners/staff-members/useStaffMembers'

const useStaffMembersWithFilteredResults = ({ staffMemberSuggestionsFilter, ...otherParams }) => {
  const result = useStaffMembers(otherParams)

  if (typeof staffMemberSuggestionsFilter !== 'function') return result

  const { data = {}, ...restOfResult } = result
  return {
    ...restOfResult,
    data: { staffMembers: data.staffMembers?.filter(staffMemberSuggestionsFilter) },
  }
}

export default useStaffMembersWithFilteredResults
