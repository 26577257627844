import { createSlice } from '@reduxjs/toolkit'
import closeConditionCommentDialogImport from 'redux/slices/conditions/actions/conditions-table/closeConditionCommentDialog'
import conditionApprovalLevelChangedImport from 'redux/slices/conditions/actions/conditions-table/conditionApprovalLevelChanged'
import conditionCategoryChangedImport from 'redux/slices/conditions/actions/conditions-table/conditionCategoryChanged'
import conditionReferencesChangedImport from 'redux/slices/conditions/actions/conditions-table/conditionReferencesChanged'
import conditionNameTypeImport from 'redux/slices/conditions/actions/conditions-table/conditionTypeChanged'
import conditionVisibilitiesChangedImport from 'redux/slices/conditions/actions/conditions-table/conditionVisibilitiesChanged'
import openConditionCommentDialogImport from 'redux/slices/conditions/actions/conditions-table/openConditionCommentDialog'
import setSelectedConditionRowsImport from 'redux/slices/conditions/actions/conditions-table/setSelectedConditionRows'
import startConditionEditModeImport from 'redux/slices/conditions/actions/conditions-table/startConditionEditMode'
import assigneeChangedImport from 'redux/slices/conditions/actions/shared/assigneeChanged'
import covenantCheckChangedImport from 'redux/slices/conditions/actions/shared/covenantCheckChanged'
import descriptionChanged from 'redux/slices/conditions/actions/shared/descriptionChanged'
import externalAssigneeChangedImport from 'redux/slices/conditions/actions/shared/externalAssigneeChanged'
import highlightRow from 'redux/slices/conditions/actions/shared/highlightRow'
import nameChangedImport from 'redux/slices/conditions/actions/shared/nameChanged'
import nameOnBlurImport from 'redux/slices/conditions/actions/shared/nameOnBlur'
import refNumberChangedImport from 'redux/slices/conditions/actions/shared/refNumberChanged'
import rowHasError from 'redux/slices/conditions/actions/shared/rowHasError'
import statusChanged from 'redux/slices/conditions/actions/shared/statusChanged'

const initialState = {
  errorRows: {},
  rowsHaveAnError: false,
  highlightRows: {},
  selectedRows: {
    selectedRowIds: {},
    selectedConditionIds: {},
  },
  editedRow: {},
  commentDialog: {
    isOpen: false,
  },
}

export const conditionsTableSlice = createSlice({
  name: 'conditions-table',
  initialState,
  reducers: {
    conditionRowHasError: rowHasError,
    highlightConditionRow: highlightRow,
    startConditionEditMode: startConditionEditModeImport,
    conditionDescriptionChanged: descriptionChanged,
    conditionNameChanged: nameChangedImport,
    conditionNameOnBlur: nameOnBlurImport,
    conditionCategoryChanged: conditionCategoryChangedImport,
    conditionVisibilitiesChanged: conditionVisibilitiesChangedImport,
    conditionRefNumberChanged: refNumberChangedImport,
    conditionTypeChanged: conditionNameTypeImport,
    conditionReferencesChanged: conditionReferencesChangedImport,
    conditionStatusChanged: statusChanged,
    conditionApprovalLevelChanged: conditionApprovalLevelChangedImport,
    setSelectedConditionRows: setSelectedConditionRowsImport,
    conditionAssigneeChanged: assigneeChangedImport,
    conditionCovenantCheckChanged: covenantCheckChangedImport,
    conditionExternalAssigneeChanged: externalAssigneeChangedImport,
    closeConditionCommentDialog: closeConditionCommentDialogImport,
    openConditionCommentDialog: openConditionCommentDialogImport,
    stopConditionEditMode: (state) => {
      state.editedRow = {}
    },
    reset: () => initialState,
  },
})

export const {
  conditionRowHasError,
  highlightConditionRow,
  startConditionEditMode,
  stopConditionEditMode,
  conditionNameChanged,
  conditionNameOnBlur,
  conditionRefNumberChanged,
  conditionCategoryChanged,
  conditionVisibilitiesChanged,
  conditionTypeChanged,
  conditionReferencesChanged,
  conditionTypeOnBlur,
  conditionApprovalLevelChanged,
  conditionAssigneeChanged,
  conditionCovenantCheckChanged,
  conditionExternalAssigneeChanged,
  conditionDescriptionChanged,
  setSelectedConditionRows,
  conditionStatusChanged,
  closeConditionCommentDialog,
  openConditionCommentDialog,
  reset,
} = conditionsTableSlice.actions

export default conditionsTableSlice.reducer
