import {
  LandingPageBusinessEventsAndTasksSection,
  LandingPageBusinessPartnersAndDealsSection,
  LandingPagePropertiesAndMarketsSection,
} from 'components/domains/home/LandingPageSections'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useUserProfile from 'hooks/services/user-profile/useUserProfile'
import { homePagePreferences } from 'routes/user-profile/homePagePreferences'

const ArrangeLandingPageSections = () => {
  const { isLoading, isError, data } = useUserProfile()

  const sections = {
    businessEventsAndTasks: (view) => (
      <LandingPageBusinessEventsAndTasksSection
        key={`landing-page-events-and-tasks-section-${view.toLowerCase()}`}
      />
    ),
    businessPartnersAndDeals: (view) => (
      <LandingPageBusinessPartnersAndDealsSection
        key={`landing-page-partners-and-deals-section-${view.toLowerCase()}`}
      />
    ),
    propertiesAndMarkets: (view) => (
      <LandingPagePropertiesAndMarketsSection
        key={`landing-page-properties-and-markets-section-${view.toLowerCase()}`}
      />
    ),
  }
  const startPageType = data?.startPageType

  const defaultView = [
    sections.businessEventsAndTasks(homePagePreferences.NEUTRAL),
    sections.businessPartnersAndDeals(homePagePreferences.NEUTRAL),
    sections.propertiesAndMarkets(homePagePreferences.NEUTRAL),
  ]

  const renderContent = () => {
    switch (startPageType) {
      case homePagePreferences.NEUTRAL:
        return defaultView
      case homePagePreferences.DEAL_CENTRIC:
        return [
          sections.businessPartnersAndDeals(homePagePreferences.DEAL_CENTRIC),
          sections.businessEventsAndTasks(homePagePreferences.DEAL_CENTRIC),
          sections.propertiesAndMarkets(homePagePreferences.DEAL_CENTRIC),
        ]
      case homePagePreferences.PROPERTY_CENTRIC:
        return [
          sections.propertiesAndMarkets(homePagePreferences.PROPERTY_CENTRIC),
          sections.businessEventsAndTasks(homePagePreferences.PROPERTY_CENTRIC),
          sections.businessPartnersAndDeals(homePagePreferences.PROPERTY_CENTRIC),
        ]
      default:
        return defaultView
    }
  }

  return (
    <LoadingStateWrapper isLoading={isLoading} isError={isError} renderContent={renderContent} />
  )
}

export default ArrangeLandingPageSections
