import { ARREARS_IN_APPROVAL_ENTITY_TYPES } from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/constants'
import set from 'utils/set'

const NO_DEAL = 'NO_DEAL'

// Hint: Here is no check with "secondaryXId" necessary, because in this case we have on both side the primary id.
// Only if we check the product id with an arrear or arrear approval item we have also to check for the secondaryProductId
const findProductByIdAndType = (productsFromContext, productId, productType) =>
  productsFromContext.find(({ id, type }) => id === productId && type === productType)

const unshiftNoDealRowOnFirstHierarchyLevel = (productHierarchy, hierarchyLevel) => {
  let productHierarchyResult = [...productHierarchy]
  if (hierarchyLevel === 0) {
    const noDealRow = productHierarchyResult.find(
      (hierarchyItem) => hierarchyItem.productId === NO_DEAL,
    )
    if (noDealRow) {
      productHierarchyResult = productHierarchyResult.filter(
        (hierarchyItem) => hierarchyItem.productId !== NO_DEAL,
      )
      productHierarchyResult.unshift(noDealRow)
    }
  }
  return productHierarchyResult
}

export const isFinancialProduct = (entityType) =>
  entityType === ARREARS_IN_APPROVAL_ENTITY_TYPES.DRAWING ||
  entityType === ARREARS_IN_APPROVAL_ENTITY_TYPES.TRANCHE ||
  entityType === ARREARS_IN_APPROVAL_ENTITY_TYPES.NON_LOAN ||
  entityType === ARREARS_IN_APPROVAL_ENTITY_TYPES.BCA

const extendRowDataWithProduct = (productsFromContext, row) => {
  const { entityId, entityType } = row
  if (isFinancialProduct(entityType)) {
    set(row, 'rowData.product', findProductByIdAndType(productsFromContext, entityId, entityType))
  }
}

export const arrearsInApprovalBuildRowsDataRecursive = ({
  businessPartnerId,
  products,
  productHierarchy,
  allDealsData,
  extendRowsWithAdditionalData = () => {},
  hierarchyLevel = 0,
  isFirstLevel = true,
  dealId = null,
}) =>
  unshiftNoDealRowOnFirstHierarchyLevel(productHierarchy, hierarchyLevel).map(
    ({ productId, secondaryProductId, productTypeCode, childProducts: childHierarchy }) => {
      const rowDealId =
        isFirstLevel && productTypeCode === ARREARS_IN_APPROVAL_ENTITY_TYPES.DEAL
          ? productId
          : dealId
      const row = {
        businessPartnerId,
        dealId: rowDealId,
        deal: allDealsData.find(({ dealUuid }) => dealUuid === rowDealId),
        isSectionHeader: isFirstLevel,
        hierarchyLevel,
        entityId: productId,
        secondaryEntityId: secondaryProductId,
        entityType: productTypeCode,
        rowData: {},
        sumData: {},
        subRows: [],
      }
      extendRowDataWithProduct(products, row)
      extendRowsWithAdditionalData(row)
      return {
        ...row,
        subRows: [
          ...row.subRows,
          ...arrearsInApprovalBuildRowsDataRecursive({
            businessPartnerId,
            products,
            productHierarchy: childHierarchy,
            allDealsData,
            extendRowsWithAdditionalData,
            hierarchyLevel: hierarchyLevel + 1,
            isFirstLevel: false,
            dealId: row.dealId,
          }),
        ],
      }
    },
  )

const wasSubRowsTrimmed = (row, shouldShowRow) => {
  const filteredSubRows = row.subRows.filter((subRow) => shouldShowRow(subRow))
  const wasTrimmed = filteredSubRows.length < row.subRows.length
  row.subRows = [...filteredSubRows]
  return wasTrimmed
}

const trimRowsRecursive = (row, shouldShowRow = () => true) => {
  let wasTrimmed = false
  do {
    wasTrimmed = wasSubRowsTrimmed(row, shouldShowRow)
  } while (row.subRows.some((subRow) => trimRowsRecursive(subRow, shouldShowRow)))
  return wasTrimmed
}

export const arrearsInApprovalTrimRowsDeep = (rows, shouldShowRow = () => true) => {
  rows.forEach((row) => trimRowsRecursive(row, shouldShowRow))
  return rows.filter((row) => shouldShowRow(row))
}

export const arrearsInApprovalExtractApprovalStatusList = (approvalStatuses) =>
  approvalStatuses?.map((approvalStatusObj) => approvalStatusObj.approvalStatus) ?? []
