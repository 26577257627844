import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const sortByTargetDate = (a, b) => {
  if (a.targetDate < b.targetDate) {
    return 1
  } else if (a.targetDate > b.targetDate) {
    return -1
  }
  return 0
}

const useGetMilestones = ({ dealUuid }) => {
  const result = useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/milestones`,
      keys: ['deals', dealUuid, 'milestones'],
      options: { enabled: !!dealUuid },
    }),
  )

  return {
    ...result,
    data: {
      ...result.data,
      milestones: result?.data?.milestones?.slice().sort(sortByTargetDate),
    },
  }
}

export default useGetMilestones
