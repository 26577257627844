import isEmpty from 'lodash.isempty'

const valuationValidToChanged = (state, { payload: { validTo: newValidTo } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }
  state.editedRow.currentValues.validTo = newValidTo
  state.editedRow.touchedFields.validTo = true

  if (newValidTo === state.editedRow.initialValues.validTo) {
    delete state.editedRow.changedFields.validTo
    return
  }

  state.editedRow.changedFields.validTo = true
}

export default valuationValidToChanged
