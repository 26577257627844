import { useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { getButtonForDecisionStageVoterVoteOption } from 'api/decision-process/decisionProcessApi'
import useDecisionStageApprovalVoterVoteOptions from 'hooks/services/business-events-and-tasks/decision-process/stages/approval/useDecisionStageApprovalVoterVoteOptions'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const useDecisionStageIndividualVoteButtons = ({ decisionStageVoterId }) => {
  const {
    event: { id: eventId },
  } = useContext(BusinessEventsAndTasksContext)

  const { decisionStageId } = useParams()
  const { isLoading, isError, data, error } = useDecisionStageApprovalVoterVoteOptions(
    {
      eventId,
      decisionStageId,
      decisionStageVoterId,
    },
    { enabled: !!decisionStageVoterId },
  )

  const buttons = useMemo(() => {
    if (isLoading || isError) {
      return []
    }

    return data.voteOptions.map(({ code }) => {
      const ButtonComponent = getButtonForDecisionStageVoterVoteOption(code)
      return (
        <ButtonComponent
          key={`vote-option-${code}`}
          eventId={eventId}
          decisionStageId={decisionStageId}
          decisionStageVoterId={decisionStageVoterId}
        />
      )
    })
  }, [data?.voteOptions, decisionStageId, decisionStageVoterId, eventId, isError, isLoading])

  return useMemo(
    () => ({
      isLoading,
      isError,
      buttons,
      error,
    }),
    [buttons, error, isError, isLoading],
  )
}

export default useDecisionStageIndividualVoteButtons
