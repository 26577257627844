import { useRequest } from 'hooks/services/baseService'
/** * Returns all businesspartners belonging to property
 * parameter specification can be: undefined, "external", "internal"
 * example data: 
 * {
    "business_partner_function_codes": [
        {
            "business_partner_function_code": "010001",
            "business_partner_function_name": "Eigentümer"
        },
        {
            "business_partner_function_code": "010003",
            "business_partner_function_name": "Kontoinhaber"
        },
 * @returns {{isLoading: boolean, isError: boolean, data: business_partner_function_codes}}
 */

export const usePropertyPartnerFunctions = (specification) => {
  const path = specification
    ? `/properties/business_partner_function_codes/${specification}`
    : `/properties/business_partner_function_codes`
  return useRequest({
    path: `${path}`,
    translated: true,
    keys: ['properties', 'business_partner_function_codes', specification],
  })
}
