import {
  ButtonDesign,
  FlexBox,
  FlexBoxDirection,
  Label,
  MessageStrip,
  MessageStripDesign,
  Modals,
  PopoverPlacementType,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState'
import PropTypes from 'prop-types'
import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { handleNewDecisionDateChanged } from 'components/domains/business-events-and-tasks/decision-stage/approval/overarching/buttons/DecisionStageApprovalOverarchingButtonUtils'
import styles from 'components/domains/business-events-and-tasks/decision-stage/approval/shared/DecisionStageApprovalApproveWithConditionsDialog.module.css'
import { CancelPopoverContent } from 'components/ui/button/CancelButtonWithPopover'
import Dialog, {
  DialogPrimaryButton,
  DialogSecondaryButton,
  DialogSize,
} from 'components/ui/dialog/Dialog'
import EditableDatePickerComponent from 'components/ui/input/EditableDatePickerComponent'
import CWPCKEditor, { emptyContent } from 'components/ui/rich-text-editor/CWPCKEditor'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const cancelButtonId = 'cancel-button-approve-condition'

const byWayOfAttendance = 'BY_WAY_OF_ATTENDANCE'

const DecisionStageApprovalApproveWithConditionsDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  currentCondition,
  setCurrentCondition,
  onApproveWithConditions,
  decisionStageType,
  newDecisionDate,
  setNewDecisionDate,
  showWarningLabel = false,
}) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'decision-stage.vote-options.approve-with-conditions-dialog',
  })
  const popoverRef = useRef()
  const showPopover = Modals.useShowPopover()
  const { format, localePattern } = useShortDateFormatter()
  const today = format(new Date().toISOString().substring(0, 10), localePattern)
  const [isDateValid, setIsDateValid] = useState(true)

  const onApproveConfirm = useCallback(() => {
    onApproveWithConditions()
    setIsDialogOpen(false)
  }, [onApproveWithConditions, setIsDialogOpen])

  const onAfterClose = useCallback(() => {
    setIsDialogOpen(false)
  }, [setIsDialogOpen])

  const isApproveWithConditionsConfirmationButtonDisabled =
    currentCondition === emptyContent ||
    (decisionStageType === byWayOfAttendance && newDecisionDate === '') ||
    !isDateValid

  const closeDialog = useCallback(() => {
    popoverRef.current?.close()
    setIsDialogOpen(false)
  }, [setIsDialogOpen])

  const handleDateChange = useCallback((event) => {
    handleNewDecisionDateChanged(event, setNewDecisionDate)
    setIsDateValid(event.detail.valid)
  }, [])

  const onCancelButtonClicked = useCallback(
    (event) => {
      if (!isApproveWithConditionsConfirmationButtonDisabled) {
        event.preventDefault()
        popoverRef.current = showPopover({
          placementType: PopoverPlacementType.Top,
          opener: cancelButtonId,
          children: <CancelPopoverContent onCancelClicked={closeDialog} />,
        })
        return
      }
      closeDialog()
    },
    [isApproveWithConditionsConfirmationButtonDisabled, showPopover, closeDialog],
  )

  if (!isDialogOpen) {
    return
  }

  return (
    <Dialog
      headerText={t('action')}
      onAfterClose={onAfterClose}
      onBeforeClose={onCancelButtonClicked}
      open={isDialogOpen}
      size={DialogSize.L}
      closeButton={
        <DialogSecondaryButton id={cancelButtonId} onClick={onCancelButtonClicked}>
          {tNoPrefix('buttons.cancel')}
        </DialogSecondaryButton>
      }
      primaryButton={
        <DialogPrimaryButton
          design={ButtonDesign.Emphasized}
          onClick={onApproveConfirm}
          disabled={isApproveWithConditionsConfirmationButtonDisabled}
        >
          {tNoPrefix('buttons.approve')}
        </DialogPrimaryButton>
      }
    >
      {decisionStageType === byWayOfAttendance && (
        <FlexBox direction={FlexBoxDirection.Column} style={{ paddingTop: '11px' }}>
          <Label showColon for="decision-date-picker" required>
            {t('label.decision-date')}
          </Label>
          <EditableDatePickerComponent
            style={{ width: '100%' }}
            id="decision-date-picker"
            value={newDecisionDate}
            maxDate={today}
            onChange={handleDateChange}
            valueState={newDecisionDate && isDateValid ? ValueState.None : ValueState.Error}
            valueStateMessage={<Text>{t('error-states.decision-date')}</Text>}
            editable
          />
        </FlexBox>
      )}
      <FlexBox direction={FlexBoxDirection.Column}>
        {showWarningLabel && (
          <MessageStrip
            design={MessageStripDesign.Warning}
            hideCloseButton
            className={styles.warningLabel}
          >
            {t('warning-label')}
          </MessageStrip>
        )}
        <Label required>{t('label')}</Label>
        <CWPCKEditor
          currentContent={currentCondition}
          setCurrentContent={setCurrentCondition}
          placeholderText={t('placeholder')}
        />
      </FlexBox>
    </Dialog>
  )
}

DecisionStageApprovalApproveWithConditionsDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  setIsDialogOpen: PropTypes.func.isRequired,
  currentCondition: PropTypes.string.isRequired,
  setCurrentCondition: PropTypes.func.isRequired,
  onApproveWithConditions: PropTypes.func.isRequired,
  decisionStageType: PropTypes.string.isRequired,
  newDecisionDate: PropTypes.string.isRequired,
  setNewDecisionDate: PropTypes.func.isRequired,
}

export default DecisionStageApprovalApproveWithConditionsDialog
