import isEmpty from 'lodash.isempty'

const nameOnBlur = (state) => {
  if (isEmpty(state.editedRow)) {
    return
  }
  if (state.editedRow.currentValues.name === '') {
    state.editedRow.errorFields.name = true
  }
}

export default nameOnBlur
