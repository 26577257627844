import { MessageStripDesign, FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { propertyLinkedDealCodes } from 'api/property/linked-deal/propertyLinkedDeal'
import styles from 'components/domains/business-partners/tile/tenancy/AsTenantCard.module.css'
import TenancyLabelValueList from 'components/domains/business-partners/tile/tenancy/TenancyLabelValueList'
import CloseableMessageStrip from 'components/ui/feedback/CloseableMessageStrip'
import { useTenantBottomHeaderInformation } from 'hooks/services/business-partners/tenancy/useTenantBottomHeaderInformation'
import { useTenantTopHeaderInformation } from 'hooks/services/business-partners/tenancy/useTenantTopHeaderInformation'
const AsTenantCardHeader = ({ rentalUnits, propertyDeals, financingStatus }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.tenancy.as-tenant',
  })

  const rentalUnitsByFinancingStatusActive =
    rentalUnits?.[propertyLinkedDealCodes.linkedToActiveDeal]
  const rentalUnitsByFinancingStatusPipeline =
    rentalUnits?.[propertyLinkedDealCodes.linkedToPipelineDeal]

  const topHeader = useTenantTopHeaderInformation(
    rentalUnitsByFinancingStatusActive,
    rentalUnitsByFinancingStatusPipeline,
    propertyDeals,
  )

  const bottomHeader = useTenantBottomHeaderInformation(
    rentalUnitsByFinancingStatusActive?.matchingRentalUnits ?? [],
    rentalUnitsByFinancingStatusPipeline?.matchingRentalUnits ?? [],
  )

  return (
    <>
      <FlexBox direction={FlexBoxDirection.Column}>
        <CloseableMessageStrip
          design={MessageStripDesign.Information}
          className={styles.messageStrip}
        >
          {t('information-message-strip')}
        </CloseableMessageStrip>
      </FlexBox>
      <div className={[styles.grid, 'sapUiSmallMargin'].join(' ')}>
        <TenancyLabelValueList rows={topHeader} financingStatus={financingStatus} />
      </div>
      <div className={styles.border} />
      <div className={[styles.grid, 'sapUiSmallMargin'].join(' ')}>
        <TenancyLabelValueList rows={bottomHeader} financingStatus={financingStatus} />
      </div>
    </>
  )
}

export default AsTenantCardHeader

AsTenantCardHeader.propTypes = {
  rentalUnits: PropTypes.shape({
    LinkedToActiveDeal: PropTypes.shape({
      matchingRentalUnits: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    LinkedToPipelineDeal: PropTypes.shape({
      matchingRentalUnits: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  propertyDeals: PropTypes.shape({}),
  financingStatus: PropTypes.shape({
    active: PropTypes.shape({
      code: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }).isRequired,
    pipeline: PropTypes.shape({
      code: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }).isRequired,
  }),
}
