import { Input, SuggestionItem, Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import throttle from 'lodash.throttle'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStaffMembersWithFilteredResults from 'hooks/services/business-partners/staff-members/useStaffMembersWithFilteredResults'

const DEFAULT_SUGGESTIONS_LIMIT = 10
const DEFAULT_SEARCH_MIN_LENGTH = 3
const DEFAULT_THROTTLE_TIMEOUT = 300

const propTypes = {
  staffMemberName: PropTypes.string,
  onInput: PropTypes.func,
  onStaffMemberSelect: PropTypes.func,
  suggestionsLimit: PropTypes.number,
  searchMinLength: PropTypes.number,
  throttleTimeout: PropTypes.number,
  errorMessage: PropTypes.string,
  staffMemberSuggestionsFilter: PropTypes.func,
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const StaffMemberAutocompleteInput = ({
  staffMemberName = '',
  onInput = () => {},
  onStaffMemberSelect = () => {},
  suggestionsLimit = DEFAULT_SUGGESTIONS_LIMIT,
  searchMinLength = DEFAULT_SEARCH_MIN_LENGTH,
  throttleTimeout = DEFAULT_THROTTLE_TIMEOUT,
  errorMessage,
  staffMemberSuggestionsFilter,
  ...inputOptions
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'components.ui.input.staff-member-autocomplete',
  })

  const [search, setSearch] = useState('')
  const [isError, setIsError] = useState(false)
  const setSearchThrottled = useMemo(
    () => throttle(setSearch, throttleTimeout, { trailing: true }),
    [throttleTimeout],
  )

  const {
    isLoading,
    isError: isSearchError,
    data: { staffMembers: staffMemberSuggestions = [] } = {},
  } = useStaffMembersWithFilteredResults({
    staffMemberSuggestionsFilter,
    name: search,
    limit: suggestionsLimit,
    minLength: searchMinLength,
  })

  const getStaffMemberMatch = (searchValue, searchKey = 'fullName') =>
    staffMemberSuggestions.find(({ [searchKey]: staffMemberKey }) => staffMemberKey === searchValue)

  const isValid = (staffMember) => isLoading || !search || !!staffMember?.fullName

  const handleOnInput = (event) => {
    setSearchThrottled(event.target.value)
    onInput(event)
  }

  const handleOnChange = (event) => {
    const selectedStaffMember = getStaffMemberMatch(event.target.value)
    onStaffMemberSelect(selectedStaffMember)
    setIsError(!isValid(selectedStaffMember))
  }

  const handleOnSuggestionItemSelect = (event) => {
    const staffMemberId = event.detail?.item.getAttribute('additional-text')
    if (!staffMemberId) {
      event.target.value = ''
      return
    }
    const selectedStaffMember = getStaffMemberMatch(staffMemberId, 'id')
    onStaffMemberSelect(selectedStaffMember)
  }

  const valueState = isError || isSearchError ? ValueState.Error : ValueState.None

  const valueStateMessage =
    errorMessage ?? isSearchError ? t('search-error.description') : t('error.description')

  const isNoData = !isLoading && staffMemberSuggestions.length === 0

  return (
    <Input
      showSuggestions
      value={staffMemberName ?? undefined}
      onInput={handleOnInput}
      onChange={handleOnChange}
      onSuggestionItemSelect={handleOnSuggestionItemSelect}
      valueState={valueState}
      valueStateMessage={<Text wrapping>{valueStateMessage}</Text>}
      {...inputOptions}
    >
      {isLoading && <SuggestionItem key="loading" text={t('loading')} />}
      {isNoData && <SuggestionItem key="no-data" text={t('no-data')} />}
      {staffMemberSuggestions.map(({ id, fullName }) => (
        <SuggestionItem key={id} text={fullName} additionalText={id} />
      ))}
    </Input>
  )
}

//StaffMemberAutocompleteInput.displayName = 'StaffMemberAutocompleteInput'

StaffMemberAutocompleteInput.propTypes = propTypes

export default StaffMemberAutocompleteInput
