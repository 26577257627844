import {
  List,
  ListItemType,
  Panel,
  StandardListItem,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/general-information/RolesTile.module.css'
import Card from 'components/ui/card/Card'
import ClickableCardHeader from 'components/ui/card/ClickableCardHeader'

const RolesTile = ({ roles, showLink = true }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-partner.roles',
  })

  return (
    <Card
      header={
        <ClickableCardHeader
          titleText={t('title')}
          href={showLink ? 'general-information' : undefined}
        />
      }
      className={styles.rolesTile}
    >
      {roles.length ? (
        roles.map(({ name, id, backEndRoles }) => (
          <Panel
            header={
              <Title className={styles.rolesHeader} level={TitleLevel.H6}>
                {name}
              </Title>
            }
            key={id}
          >
            <List>
              {backEndRoles?.map(({ name: backEndRoleName, id: backEndRoleId }) => (
                <StandardListItem key={backEndRoleId} type={ListItemType.Inactive}>
                  {backEndRoleName}
                </StandardListItem>
              ))}
            </List>
          </Panel>
        ))
      ) : (
        <div className="sapUiSmallMargin text-centered">
          <span> {t('no-relevant-roles')} </span>
        </div>
      )}
    </Card>
  )
}

RolesTile.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      backend_roles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  showLink: PropTypes.bool,
}

export default RolesTile
