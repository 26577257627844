import PropTypes from 'prop-types'
import { useCallback } from 'react'
import MultipleBorrowerSelectionPdfViewWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/appendix/wrapper/MultipleBorrowerSelectionPdfViewWrapper'
import AnnualReviewPeriodicalCheckChecksTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/periodical-checks/checks/AnnualReviewPeriodicalCheckChecksTile'

const AnnualReviewPeriodicalCheckChecksTileWrapper = ({ tileId, tileCode, isPdfView }) => {
  const contentCreator = useCallback(
    ({ selectedBorrowerId }) => (
      <AnnualReviewPeriodicalCheckChecksTile
        tileId={tileId}
        tileCode={tileCode}
        selectedBorrowerId={selectedBorrowerId}
      />
    ),
    [tileCode, tileId],
  )

  return (
    <MultipleBorrowerSelectionPdfViewWrapper
      tileId={tileId}
      contentCreator={contentCreator}
      isPdfView={isPdfView}
    />
  )
}

AnnualReviewPeriodicalCheckChecksTileWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  tileCode: PropTypes.string.isRequired,
}

export default AnnualReviewPeriodicalCheckChecksTileWrapper
