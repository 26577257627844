import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

export const useMultipleDealsByUuidMini = (dealUuids, options = {}) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: dealUuids.map((dealUuid) => ({
        path: `/deals/${dealUuid}/mini`,
        keys: ['deals', dealUuid, 'mini'],
      })),
      translated: true,
      useCache: true,
      options,
    }),
  )
  return useMemo(
    () => responses.map((result) => ({ ...result, data: camelize(result.data) })),
    [responses],
  )
}
