import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  resetPropertiesPagination: false,
}

export const resetPropertiesPaginationSlice = createSlice({
  name: 'properties/pagination',
  initialState,
  reducers: {
    setResetPropertiesPagination: (state, { payload }) => {
      state.resetPropertiesPagination = payload
    },
  },
})

export const { setResetPropertiesPagination } = resetPropertiesPaginationSlice.actions

export default resetPropertiesPaginationSlice.reducer
