import { FlexBoxJustifyContent, IllustratedMessage, Label } from '@fioneer/ui5-webcomponents-react'
import zip from 'lodash.zip'
import zipWith from 'lodash.zipwith'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { findActiveRating } from 'components/domains/business-partners/tile/ratings/InternalRatingsOverview'
import styles from 'components/domains/business-partners/tile/tenancy/TenantUnitTile.module.css'
import { useAreaCell } from 'components/domains/business-partners/tile/tenancy/tenant-unit-table/useAreaCell'
import Card from 'components/ui/card/Card'
import Entity from 'components/ui/data/Entity'
import entityStyles from 'components/ui/data/Entity.module.css'
import LabelValueList from 'components/ui/data/LabelValueList'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'
import {
  useAreaMeasurementUnitFormatter,
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
} from 'hooks/i18n/useI18n'
import { useMultipleBusinessPartnersById } from 'hooks/services/business-partners/getBusinessPartners'
import useMultipleBusinessPartnerRatings from 'hooks/services/business-partners/ratings/useMultipleBusinessPartnerRatings'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'
import { useTenantUnitTableData } from 'hooks/services/properties/useTenantUnitTableData'

const findHeadQuarterCurrency = (data) =>
  data?.find((element) => element?.totalRentInHeadQuarterCurrency?.currency)
    ?.totalRentInHeadQuarterCurrency.currency

const findHeadQuarterMeasurementUnitArea = (data) =>
  data?.find((element) => element?.totalRentedAreaInHeadQuarterMeasurementUnit?.measurementUnit)
    ?.totalRentedAreaInHeadQuarterMeasurementUnit.measurementUnit

const businessPartnerAggregation = (accumulator, currentValue) =>
  currentValue
    ? {
        totalRentInHeadQuarterCurrency:
          accumulator.totalRentInHeadQuarterCurrency +
          (currentValue.totalRentInHeadQuarterCurrency?.number ?? 0),
        totalRentedAreaInHeadQuarterMeasurementUnit:
          accumulator.totalRentedAreaInHeadQuarterMeasurementUnit +
          (currentValue.totalRentedAreaInHeadQuarterMeasurementUnit?.value ?? 0),
        numberOfRentedProperties:
          accumulator.numberOfRentedProperties + currentValue.numberOfRentedProperties,
        totalNumberOfRentedAreaPieces:
          accumulator.totalNumberOfRentedAreaPieces +
          (currentValue.totalNumberOfRentedAreaPieces?.value ?? 0),
      }
    : accumulator

/**
 * Component to display an overview of a tenant unit and its members.
 * @param tenantUnitHead heading member of the tenant unit
 *   - id: BusinessPartnerId of the member
 *   - fullName
 * @param members List of members of the tenant unit
 *   - id: BusinessPartnerId of the member
 *   - fullName
 *   - country
 * @param tenantAccountManager tenant account manager of the tenant unit
 */
const TenantUnitTile = ({ tenantUnitHead, members, accountManager }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.tenancy.tenant-unit-tiles',
  })
  const formatNumber = useNumberFormatter()
  const formatCurrency = useCustomizableCurrencyFormatter()
  const formatAreaUnit = useAreaMeasurementUnitFormatter()
  const { renderAreaCell } = useAreaCell()

  const memberIds = members.map(({ id }) => id)

  const membersResponses = useMultipleBusinessPartnersById(memberIds)
  const isMembersError = membersResponses.some(({ isError }) => isError)
  const isMembersLoading = membersResponses.some(({ isLoading }) => isLoading)
  const membersData = membersResponses.map(({ data }) => data).filter((data) => !!data)

  const {
    data: tenantUnitData,
    isLoading: isTenantUnitLoading,
    isError: isTenantUnitError,
  } = useTenantUnitTableData(memberIds)

  // HINT: Can't use `isLoading` here, because it is `true`
  //       when calling a disabled endpoint for the first time
  const {
    data: tenantAccountManager,
    isFetching: isStaffMemberFetching,
    isError: isStaffMemberError,
  } = useStaffMemberById(accountManager?.id)

  const ratingsResponses = useMultipleBusinessPartnerRatings(memberIds)
  const ratingsData = ratingsResponses.map(({ data }) => data)
  const isRatingsError = ratingsResponses.some(({ isError }) => isError)
  const isRatingsLoading = ratingsResponses.some(({ isLoading }) => isLoading)

  const activeRatingsMap = useMemo(
    () =>
      new Map(
        zip(memberIds, ratingsData).map(([memberId, ratings]) => [
          memberId,
          findActiveRating(ratings?.internal),
        ]),
      ),
    [memberIds, ratingsData],
  )

  const isError =
    isMembersError ||
    (accountManager?.id && isStaffMemberError) ||
    isTenantUnitError ||
    isRatingsError
  const isLoading =
    isMembersLoading || isStaffMemberFetching || isTenantUnitLoading || isRatingsLoading

  const headQuarterCurrency = findHeadQuarterCurrency(tenantUnitData)
  const headQuarterMeasurementUnitArea = findHeadQuarterMeasurementUnitArea(tenantUnitData)
  const {
    totalRentInHeadQuarterCurrency,
    totalRentedAreaInHeadQuarterMeasurementUnit,
    numberOfRentedProperties,
    totalNumberOfRentedAreaPieces,
  } =
    tenantUnitData?.reduce(businessPartnerAggregation, {
      totalRentInHeadQuarterCurrency: 0,
      totalRentedAreaInHeadQuarterMeasurementUnit: 0,
      numberOfRentedProperties: 0,
      totalNumberOfRentedAreaPieces: 0,
    }) ?? {}

  const currencyCell = (value) => ({
    value: value?.number,
    cellComponent: formatCurrency(value?.number, value?.currency, { currencyDisplay: 'code' }),
  })

  const text = (value) => ({
    value,
    cellComponent: <Label>{value}</Label>,
  })

  const unitMemberTableData = zipWith(
    members,
    tenantUnitData,
    ({ id, fullName, country }, member) => ({
      id,
      fullName,
      country,
      ...member,
    }),
  ).map(
    (
      {
        id,
        fullName,
        country,
        numberOfRentedProperties: memberNumberOfRentedProperties,
        totalRentInHeadQuarterCurrency: memberTotalRentInHeadQuarterCurrency,
        totalRentedAreaInHeadQuarterMeasurementUnit:
          memberTotalRentedAreaInHeadQuarterMeasurementUnit,
        totalNumberOfRentedAreaPieces: memberTotalNumberOfRentedAreaPieces,
      },
      index,
    ) => ({
      businessPartner: {
        value: fullName + id,
        cellComponent: <Entity name={fullName} id={id} link={`/business-partners/${id}`} />,
      },
      businessPartnerName: text(fullName),
      country: text(country),
      rowKey: `member-table-row-${index}`,
      rent: currencyCell(memberTotalRentInHeadQuarterCurrency),
      areaSize: renderAreaCell(memberTotalRentedAreaInHeadQuarterMeasurementUnit),
      areaNumber: text(formatNumber(memberTotalNumberOfRentedAreaPieces?.value)),
      nrOfProperties: text(formatNumber(memberNumberOfRentedProperties)),
      industry: text(
        membersData?.find(({ id: memberId }) => memberId === id)?.industry?.keyDescription,
      ),
      pdClass: text(activeRatingsMap?.get(id)?.ratingClass),
    }),
  )

  const labelValueMapLeft = [
    {
      label: t('annualized-rent'),
      value: formatCurrency(totalRentInHeadQuarterCurrency, headQuarterCurrency, {
        currencyDisplay: 'code',
      }),
    },
    {
      label: t('rented-area'),
      value: `${formatNumber(totalRentedAreaInHeadQuarterMeasurementUnit)} ${formatAreaUnit(
        headQuarterMeasurementUnitArea,
      )}`,
    },
    { label: t('rented-area-number'), value: formatNumber(totalNumberOfRentedAreaPieces) },
  ]

  const labelValueMapRight = [
    { label: t('number-of-properties'), value: formatNumber(numberOfRentedProperties) },
    {
      label: accountManager?.displayName,
      ...(tenantAccountManager
        ? {
            value: tenantAccountManager.fullName,
            linkTo: `/business-partners/${tenantUnitHead.id}#manager-tab`,
          }
        : {}),
    },
    ...(tenantAccountManager ? [{ value: tenantAccountManager?.id, omitMargin: true }] : []),
  ]

  const unitMemberColumnDefinitions = [
    {
      title: t('members.table.business-partner.label'),
      columnKey: 'businessPartner',
      filter: filterTypes.CONTAINS,
    },
    {
      title: t('members.table.country.label'),
      columnKey: 'country',
      filter: filterTypes.CONTAINS,
    },
    {
      title: t('members.table.industry.label'),
      columnKey: 'industry',
      filter: filterTypes.CONTAINS,
    },
    {
      title: t('members.table.contracted-rent.label'),
      columnKey: 'rent',
      alignment: FlexBoxJustifyContent.End,
      filter: filterTypes.BETWEEN_NUMBERS,
    },
    {
      title: t('members.table.rented-area-in-size.label'),
      columnKey: 'areaSize',
      alignment: FlexBoxJustifyContent.End,
      filter: filterTypes.BETWEEN_NUMBERS,
    },
    {
      title: t('members.table.rented-area-in-number.label'),
      columnKey: 'areaNumber',
      alignment: FlexBoxJustifyContent.End,
      filter: filterTypes.BETWEEN_NUMBERS,
    },
    {
      title: t('members.table.nr-of-properties.label'),
      columnKey: 'nrOfProperties',
      alignment: FlexBoxJustifyContent.End,
      filter: filterTypes.BETWEEN_NUMBERS,
    },
    {
      title: t('members.table.pd-class.label'),
      columnKey: 'pdClass',
      alignment: FlexBoxJustifyContent.End,
      filter: filterTypes.BETWEEN_NUMBERS,
    },
  ]

  const header = (
    <div className="sapUiSmallMargin">
      <div className="sapFontLargeSize sapUiTinyMarginBottom">{t('tenant-unit')}</div>
      <Link
        to={`/business-partners/${tenantUnitHead.id}`}
        className={[
          entityStyles.nameInline,
          'sapFontSize sapFontBoldFamily sapInformativeColor',
        ].join(' ')}
        data-testid="tenant-unit-tile-full-name-text"
      >
        {tenantUnitHead.fullName}
      </Link>
      <span> {`(${tenantUnitHead.id})`}</span>
    </div>
  )

  const renderEmptyTableComponent = () => (
    <IllustratedMessage name="NoData" titleText={t('empty')} />
  )

  const renderContent = () => (
    <Card className={styles.card} header={header}>
      <div className="sapUiSmallMargin">
        <div className={styles.tenantUnitGrid}>
          <LabelValueList rows={labelValueMapLeft} />
          <LabelValueList rows={labelValueMapRight} />
        </div>
      </div>
      <div className={[styles.tenantUnitTile, 'sapUiSmallMargin'].join(' ')}>
        <SortedTable
          columnDefinitions={unitMemberColumnDefinitions}
          tableData={unitMemberTableData}
          renderEmptyTableComponent={renderEmptyTableComponent}
          toolbarConfig={{
            title: t('unit-members'),
            sorting: {
              columnKey: 'businessPartner',
            },
          }}
        />
      </div>
    </Card>
  )

  return (
    <LoadingStateWrapper renderContent={renderContent} isLoading={isLoading} isError={isError} />
  )
}

TenantUnitTile.propTypes = {
  tenantUnitHead: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
  }).isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      fullName: PropTypes.string,
      country: PropTypes.string,
    }),
  ),
  accountManager: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
  }),
}

export default TenantUnitTile
