import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useDealProperties from 'hooks/services/deals/properties/useDealProperties'
import useDealMini from 'hooks/services/deals/useDealMini'
import { useEnrichPropertyWithStatus } from 'hooks/services/properties/useEnrichPropertyWithStatus'
import usePropertyUuids from 'hooks/services/properties/usePropertyUuids'
import useMultiPropertyValuationsDecisionPaper from 'hooks/services/properties/valuations/useMultiPropertyValuationsDecisionPaper'

const useOpsMemoPropertiesTable = ({ entityRef: { entityId: dealUuid } }, tileId, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
    errors: dealUuidErrors,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    data: dealData,
    isFetching: isFetchingDealData,
    isError: isErrorDealData,
    error: dealError,
  } = useDealMini(dealUuidByTileCode)

  const {
    data: propertiesData,
    isFetching: isFetchingPropertiesData,
    isError: isErrorPropertiesData,
    error: propertiesError,
  } = useDealProperties({ dealUuid: dealUuidByTileCode })

  const dealDisplayId = dealData?.dealId

  const isPropertyRequestEnabled = propertiesData?.dealProperties?.length > 0

  const propertyUuids = useMemo(
    () =>
      isPropertyRequestEnabled
        ? propertiesData?.dealProperties.map((item) => item.propertyUuid)
        : [],
    [propertiesData, isPropertyRequestEnabled],
  )

  const {
    data: valuationsData,
    isFetching: isValuationsFetching,
    isError: isValuationsError,
    error: valuationsError,
  } = useMultiPropertyValuationsDecisionPaper(propertyUuids, ['sum'], null, {
    enabled: isPropertyRequestEnabled,
  })

  const {
    data: { data: { properties = [] } = {} } = {},
    isFetching: isFetchingPropertiesListData,
    isError: isErrorPropertiesListData,
    error: propertiesListError,
  } = usePropertyUuids(propertyUuids, { enabled: isPropertyRequestEnabled }, true)

  const propertiesWithStauts = useEnrichPropertyWithStatus(properties)

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isDealUuidFetching,
      isFetchingDealData,
      isFetchingPropertiesData,
      isValuationsFetching && isPropertyRequestEnabled,
      isFetchingPropertiesListData && isPropertyRequestEnabled,
    ],
    errorValues: [
      isDealUuidError,
      isErrorDealData,
      isErrorPropertiesData,
      isValuationsError,
      isErrorPropertiesListData,
    ],
    errorDetails: [
      ...dealUuidErrors,
      dealError,
      propertiesError,
      valuationsError,
      propertiesListError,
    ],
    tileId,
  })

  const sourcePathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?working-version=true' : ''),
    [isWorkingVersionDataTile],
  )

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error, data: undefined }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false, data: undefined }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        properties: propertiesWithStauts ?? [],
        valuations: valuationsData?.valuations ?? [],
        sourceRender: { dealDisplayId, sourcePathSuffix },
      },
    }
  }, [
    dealDisplayId,
    error,
    isSomeValueError,
    isSomeValueLoading,
    propertiesWithStauts,
    sourcePathSuffix,
    valuationsData?.valuations,
  ])
}

export default useOpsMemoPropertiesTable
