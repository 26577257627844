import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { eventStatus } from 'api/events/status'
import { marketValuationConfirmationStatus } from 'api/property/monitoring/propertyMonitoringConfiguration'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useEventMini from 'hooks/services/business-events-and-tasks/events/useEventMini'

const MarketValueConfirmationUpdateActionCell = ({
  eventId,
  actionButtons,
  confirmationStatus,
}) => {
  const { data: event, isFetching, isError } = useEventMini(eventId)
  const renderCell = useCallback(() => {
    const shouldShowActionButtons =
      event.status !== eventStatus.aborted &&
      confirmationStatus === marketValuationConfirmationStatus.pending
    return shouldShowActionButtons ? actionButtons : <></>
  }, [actionButtons, confirmationStatus, event?.status])
  return (
    <RequestStateResolver
      isLoading={isFetching}
      isError={isError}
      errorToDisplay={<ErrorDataUnavailableInContent />}
      renderContent={renderCell}
    />
  )
}

MarketValueConfirmationUpdateActionCell.propTypes = {
  eventId: PropTypes.string.isRequired,
  actionButtons: PropTypes.element.isRequired,
  confirmationStatus: PropTypes.string.isRequired,
}

export default MarketValueConfirmationUpdateActionCell
