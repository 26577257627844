import { TableGrowingMode } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/conditions/dialogs/changelog/table/ConditionRequirementChangelogTable.module.css'
import useConditionsChangelogColumns from 'components/domains/conditions/dialogs/changelog/table/useConditionsChangelogColumns'
import useMapConditionsChangelogDataToTableData from 'components/domains/conditions/dialogs/changelog/table/useMapConditionsChangelogDataToTableData'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'

const ConditionRequirementChangelogTable = ({
  changeLogData,
  totalNumberOfItems,
  isFetching,
  loadMore,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.dialog.changelog.table',
  })

  const { tableColumns } = useConditionsChangelogColumns()

  const { tableData } = useMapConditionsChangelogDataToTableData({
    changeLogData,
  })

  const toolbarConfig = useMemo(
    () => ({
      title: t('title'),
      className: styles.tableToolbarStyling,
      showColumnSelection: false,
    }),
    [t],
  )

  return (
    <SortedTable
      columnDefinitions={tableColumns}
      tableData={tableData}
      additionalTableProperties={{
        busy: isFetching,
        stickyColumnHeader: true,
        className: styles.scrollingTable,
      }}
      toolbarConfig={toolbarConfig}
      paginationConfig={{
        loadMore,
        totalNumberOfItems,
        growing:
          totalNumberOfItems === tableData.length ? TableGrowingMode.None : TableGrowingMode.Scroll,
      }}
      noDataText={t('no-data')}
    />
  )
}

ConditionRequirementChangelogTable.propTypes = {
  changeLogData: PropTypes.arrayOf(
    PropTypes.shape({
      entityId: PropTypes.string.isRequired,
      entityType: PropTypes.string.isRequired,
      action: PropTypes.string.isRequired,
      changedFields: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  isFetching: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  totalNumberOfItems: PropTypes.number,
}

export default ConditionRequirementChangelogTable
