import { FlexBox, FlexBoxDirection, Label, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/deal-specifics/shared/MonitoredRiskIndicatorPropertySelectPdfView.module.css'

const MonitoredRiskIndicatorPropertySelectPdfView = ({ id, description }) => {
  const { t } = useTranslation('decisionPaper')
  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.annualReviewSelectionWrapper}>
      <Label showColon>{t('app.entity.type.name.property')}</Label>
      <Text>{`${description ?? '-'} (${id ?? '-'})`}</Text>
    </FlexBox>
  )
}

MonitoredRiskIndicatorPropertySelectPdfView.propTypes = {
  id: PropTypes.string,
  description: PropTypes.string,
}

export default MonitoredRiskIndicatorPropertySelectPdfView
