import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * Get a staff member by their S4 Username
 */
const useStaffMemberByUserId = ({ s4UserId }, options) => {
  const params = new URLSearchParams()
  params.append('searchType', 'S4_USER')
  params.append('q', s4UserId)
  return useCamelizedResponse(
    useRequest({
      path: `/users?${params.toString()}`,
      keys: ['users', 's4user', s4UserId],
      options,
    }),
  )
}

export default useStaffMemberByUserId
