import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import AsSponsorCard from 'components/domains/business-partners/tile/sponsor/AsSponsorCard'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import Header from 'components/ui/page/Header'
import Page from 'components/ui/page/Page'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import determineValueStateBasedOnStatus from 'routes/business-partners/determineValueState'
import paths from 'routes/business-partners/paths'

const sponsorRole = { id: 'Sponsor' }

const BusinessPartnerSponsorPage = () => {
  const businessPartner = useContext(BusinessPartnerContext)
  const { t } = useTranslation()

  const businessPartnerFound = () => Boolean(businessPartner?.id)
  const hasRoleSponsor = businessPartner.roles?.some(({ id }) => id === sponsorRole.id)

  const generateBreadcrumbs = () => {
    if (businessPartner?.id) {
      const breadcrumbLink = '/business-partners/' + businessPartner.id
      return [
        { text: t('navigation.item.title.business-partners'), href: '/business-partners' },
        { text: businessPartner.fullName, href: breadcrumbLink },
        { text: t('navigation.item.title.business-partner-sponsor') },
      ]
    }
    return []
  }

  const subtitle = <EntityTypeAndIdWithClipboard id={businessPartner.id ?? ''} />

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={businessPartner.id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [businessPartner.id],
  )

  const renderHeaderTitle = () => (
    <Header
      title={businessPartner.fullName ?? ''}
      id={businessPartner.id}
      subtitle={subtitle}
      breadcrumbs={generateBreadcrumbs()}
      status={{
        text: businessPartner.status
          ? t(businessPartner.status, {
              keyPrefix: 'pages.business-partner.general-information.status',
            })
          : '',
        valueState: determineValueStateBasedOnStatus(businessPartner.status),
      }}
      actions={
        <>
          <CreateBusinessPartnerEventAction />, {markFavoriteAction}
        </>
      }
    />
  )

  const renderContent = () => (
    <>
      {businessPartnerFound() ? (
        <div>{hasRoleSponsor && <AsSponsorCard />}</div>
      ) : (
        <>
          {t('pages.business-partner.not-found')}{' '}
          {<NavLink to={paths.businessPartners}>{t('pages.business-partner.search')}</NavLink>}
        </>
      )}
    </>
  )

  return (
    <Page
      isError={false}
      isLoading={false}
      renderHeaderTitle={renderHeaderTitle}
      renderContent={renderContent}
      id="businessPartnerSponsorId"
    />
  )
}

BusinessPartnerSponsorPage.propTypes = {}

export default BusinessPartnerSponsorPage
