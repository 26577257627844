import {
  BusyIndicator,
  BusyIndicatorSize,
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Popover,
  PopoverPlacementType,
  Text,
  Toolbar,
  ToolbarSpacer,
  ToolbarStyle,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/card/VersionedDisplayAndEditTileHeader.module.css'

/**
 *
 * @param tileText header text of the card
 * @param tileStatus status object containing loading/error state and last changed information
 * @param isEditMode
 * @param isEditEnabled
 * @param onEditClicked function to trigger when edit button is clicked
 * @param onSaveClicked function to trigger when save button is clicked
 * @param hasChanges true if edit content changed
 * @param isSaveEnabled
 * @param showLastEdited
 * @param showSubtitle whether or not to display a subtitle under the header title
 * @param subtitleFormatter formatting function for subtitle.
 * Defaults to useLastEditedText, which uses plain names instead of email.
 * @param onCancelClicked function to trigger when cancel button is clicked in edit mode
 * @param additionalEditActions array of version change related components.
 * Usually version select and back to current version.
 * @param id to identify the correct position for button popups if multiple VersionedDisplayAndEditCard are used.
 */
const VersionedDisplayAndEditCardHeader = ({
  tileText,
  tileStatus,
  isEditMode,
  isEditEnabled,
  onEditClicked,
  onSaveClicked,
  hasChanges,
  isSaveEnabled = true,
  showLastEdited = true,
  showSubtitle = true,
  subtitleFormatter,
  onCancelClicked,
  additionalEditActions,
  id,
}) => {
  const { t } = useTranslation('translation')

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const showEditButton = useMemo(() => true, [])

  const subtitle = subtitleFormatter(tileStatus.data?.lastUpdatedBy, tileStatus.data?.lastUpdatedAt)

  const handleEditClicked = useCallback(
    (clickEvent) => {
      onEditClicked(clickEvent)
    },
    [onEditClicked],
  )

  const editButton = useMemo(
    () =>
      showEditButton && (
        <Button
          key="tile-edit-button"
          onClick={handleEditClicked}
          design={ButtonDesign.Transparent}
        >
          {t('buttons.edit')}
        </Button>
      ),
    [handleEditClicked, showEditButton, t],
  )

  const handleCancel = useCallback(() => {
    if (!hasChanges) {
      onCancelClicked()
      return
    }
    setIsPopoverOpen(true)
  }, [hasChanges, onCancelClicked, setIsPopoverOpen])

  const onAfterClose = useCallback(() => {
    setIsPopoverOpen(false)
  }, [setIsPopoverOpen])

  const onButtonClick = useCallback(() => {
    onCancelClicked()
    setIsPopoverOpen(false)
  }, [onCancelClicked, setIsPopoverOpen])

  const cancelButton = useMemo(
    () => (
      <div key="cancel-button-wrapper">
        {hasChanges &&
          createPortal(
            <Popover
              open={isPopoverOpen}
              onAfterClose={onAfterClose}
              opener={`cancel-button-${id}`}
              placementType={PopoverPlacementType.Top}
            >
              <FlexBox direction={FlexBoxDirection.Column}>
                <Text wrapping className={styles.cancelPopoverText}>
                  {t('components.ui.card.versioned-display-edit-card.cancel.confirmation')}
                </Text>
                <Button onClick={onButtonClick}>{t('buttons.discard')}</Button>
              </FlexBox>
            </Popover>,
            document.body,
          )}
        <Button id={`cancel-button-${id}`} onClick={handleCancel} design={ButtonDesign.Transparent}>
          {t('buttons.cancel')}
        </Button>
      </div>
    ),
    [hasChanges, isPopoverOpen, handleCancel, t, onAfterClose, onButtonClick, id],
  )

  const isSaveButtonDisabled = useMemo(
    () => !hasChanges || !isSaveEnabled,
    [hasChanges, isSaveEnabled],
  )

  const saveButton = useMemo(
    () => (
      <Button
        key="save-button"
        onClick={onSaveClicked}
        design={ButtonDesign.Emphasized}
        disabled={isSaveButtonDisabled}
      >
        {t('buttons.save')}
      </Button>
    ),
    [onSaveClicked, t, isSaveButtonDisabled],
  )

  const actions = useMemo(() => {
    if (!isEditEnabled) {
      return additionalEditActions
    }
    if (isEditMode) {
      return [saveButton, cancelButton]
    }
    return [...(additionalEditActions ?? []), editButton]
  }, [additionalEditActions, saveButton, cancelButton, editButton, isEditMode, isEditEnabled])

  const source = useMemo(() => {
    if (!tileStatus || tileStatus.isLoading) {
      return (
        <Label>
          <BusyIndicator active delay={0} size={BusyIndicatorSize.Small} />
        </Label>
      )
    }
    if (!tileStatus.isError && tileStatus.data?.lastUpdatedBy && tileStatus.data?.lastUpdatedAt) {
      return (
        <>
          {showLastEdited && (
            <div data-testid="versioned-edit-card-source" className={styles.sourceLabel}>
              {subtitle}
            </div>
          )}
        </>
      )
    }
  }, [tileStatus, showLastEdited, subtitle])

  return (
    <FlexBox
      direction={FlexBoxDirection.Row}
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      className={styles.header}
    >
      <FlexBox
        direction={FlexBoxDirection.Column}
        justifyContent={FlexBoxJustifyContent.Center}
        className={styles.headerFlexBox}
      >
        <div className={styles.headerText} role="heading">
          {tileText}
        </div>
        {source && showSubtitle && <div className={styles.subtitleHeaderText}>{source}</div>}
      </FlexBox>
      <Toolbar toolbarStyle={ToolbarStyle.Clear} className={styles.sourceFlexBox}>
        <ToolbarSpacer />
        {actions}
      </Toolbar>
    </FlexBox>
  )
}

VersionedDisplayAndEditCardHeader.propTypes = {
  tileText: PropTypes.string.isRequired,
  tileStatus: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      lastUpdatedBy: PropTypes.string,
      lastUpdatedAt: PropTypes.string,
    }),
  }),
  isEditMode: PropTypes.bool.isRequired,
  isEditEnabled: PropTypes.bool.isRequired,
  onEditClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  isSaveEnabled: PropTypes.bool,
  showLastEdited: PropTypes.bool,
  showSubtitle: PropTypes.bool,
  subtitleFormatter: PropTypes.func.isRequired,
  onCancelClicked: PropTypes.func.isRequired,
  additionalEditActions: PropTypes.node,
  id: PropTypes.string,
}

export default VersionedDisplayAndEditCardHeader
