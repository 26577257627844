export const covenantPermissions = {
  readContractCovenant: 'CovenantContract_Read',
  createContractCovenant: 'CovenantContract_Create',
  updateContractCovenant: 'CovenantContract_Update',
  deleteContractCovenant: 'CovenantContract_Delete',
  updateCovenantContractMonitoringInformation: 'CovenantContractMonitoringInformation_Update',
  readCovenantContractMonitoring: 'CovenantContractMonitoring_Read',
  updateCovenantContractMonitoring: 'CovenantContractMonitoring_Update',
  // Periodical checks
  readPeriodicalCheck: 'CovenantPeriodicalChecks_Read',
  updatePeriodicalCheck: 'CovenantPeriodicalChecks_Update',
  deletePeriodicalCheck: 'CovenantPeriodicalChecks_Delete',
  updatePeriodicalCheckMonitoringInformation:
    'CovenantPeriodicalChecksMonitoringInformation_Update',
  readPeriodicalCheckMonitoring: 'CovenantPeriodicalChecksMonitoring_Read',
  updatePeriodicalCheckMonitoring: 'CovenantPeriodicalChecksMonitoring_Update',
}
