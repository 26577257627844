import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const DecisionProcessAlcBooleanField = ({ value }) => {
  const { t: tBoolean } = useTranslation('translation', {
    keyPrefix: 'formatters.boolean',
  })
  return value ? tBoolean('yes') : tBoolean('no')
}

DecisionProcessAlcBooleanField.propTypes = {
  value: PropTypes.bool,
}

export default DecisionProcessAlcBooleanField
