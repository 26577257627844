import z from 'zod'
import { isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'

const RETRIES = 2

/**
 * @param {object} params
 * @param {string} params.documentId
 * @param {boolean} [params.enabled]
 * @param {string} [params.versionGuid]
 */
const useEloDownload = ({ documentId, enabled, versionGuid }) => {
  let path = `/documents/${documentId}/full-documents`

  if (versionGuid) {
    const queryParams = new URLSearchParams({ version_guid: versionGuid })
    path = `${path}?${queryParams.toString()}`
  }
  const result = useRequest({
    path,
    keys: ['documents', documentId, 'raw', versionGuid],
    options: {
      enabled,
      retry: (failureCount, error) => {
        if (isNotFoundError(error)) {
          return false
        }
        return failureCount < RETRIES
      },
    },
  })

  const parsedData = z.instanceof(File).optional().safeParse(result.data)

  if (!parsedData.success) {
    // eslint-disable-next-line no-console
    console.error(`Couldn't parse the body for the document ${documentId}`, parsedData.error)
    return { ...result, data: undefined, isError: true, error: parsedData.error }
  }

  return { ...result, data: parsedData.data }
}

export default useEloDownload
