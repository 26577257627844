// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.p4CIVet3K_ZvTCFe0xTS::part(native-li) {
  padding: 0;
}

.p4CIVet3K_ZvTCFe0xTS {
  min-height: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/card/DisplayCardViewItemView.module.css"],"names":[],"mappings":";AACA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;AACf","sourcesContent":["\n.customListItem::part(native-li) {\n  padding: 0;\n}\n\n.customListItem {\n  min-height: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customListItem": `p4CIVet3K_ZvTCFe0xTS`
};
export default ___CSS_LOADER_EXPORT___;
