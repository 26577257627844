import {
  FlexBox,
  Icon,
  Input,
  Option,
  Select,
  SuggestionItem,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import { t } from 'i18next'
import _ from 'lodash'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import uniq from 'lodash.uniq'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import styles from 'components/domains/business-partners/entity-management/BusinessPartnersForEntityTable.module.css'
import RentRollBusinessPartnerSearchDialog from 'components/domains/rentroll/RentRollBusinessPartnerSearchDialog'
import EntityCell from 'components/ui/tables/cells/EntityCell'
import CardWithDisplayAndEditTable from 'components/ui/tables/display-and-edit-table/CardWithDisplayAndEditTable'
import { rowKeyNewRow } from 'components/ui/tables/display-and-edit-table/constants'
import { businessPartner } from 'routes/deals/collaterals/cardTypes'
import paths from 'routes/paths'

const getPartnerPartyIds = (partnerList) =>
  uniq(partnerList.filter((partner) => !!partner.party_id).map((partner) => partner.party_id))

const propTypes = {
  cardTitle: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
  handleSaveRow: PropTypes.func.isRequired,
  handleUpdateRow: PropTypes.func.isRequired,
  tPartnerCardTable: PropTypes.func.isRequired,
  renderMessageStrip: PropTypes.func,
  partnerFunctionMode: PropTypes.string.isRequired,
  userIsAllowedToEdit: PropTypes.bool.isRequired,
  hooks: PropTypes.shape({
    useBusinessPartnerFunctions: PropTypes.func.isRequired,
    useBusinessPartnersByIds: PropTypes.func.isRequired,
    getBusinessPartnerSuggestions: PropTypes.func.isRequired,
  }).isRequired,
  businessPartnersOnEntity: PropTypes.arrayOf(
    PropTypes.shape({
      rowKey: PropTypes.string,
      business_partner_function_code: PropTypes.string,
      party_id: PropTypes.string,
    }),
  ),
  getPartnerIds: PropTypes.func,
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const BusinessPartnersForEntityTable = ({
  cardTitle,
  businessPartnersOnEntity,
  isLoading,
  isError,
  renderMessageStrip = () => undefined,
  handleDeleteRow,
  handleSaveRow,
  handleUpdateRow,
  tPartnerCardTable,
  partnerFunctionMode,
  userIsAllowedToEdit,
  hooks,
  getPartnerIds = getPartnerPartyIds,
}) => {
  const { useBusinessPartnerFunctions, useBusinessPartnersByIds, getBusinessPartnerSuggestions } =
    hooks
  const { data: partnerFunctionCodes } = useBusinessPartnerFunctions(partnerFunctionMode)

  const getPartnerFunctions = useCallback(
    () =>
      partnerFunctionCodes?.business_partner_function_codes.sort((functionA, functionB) =>
        functionA.business_partner_function_name.localeCompare(
          functionB.business_partner_function_name,
        ),
      ),
    [partnerFunctionCodes?.business_partner_function_codes],
  )

  //a valid editRow object has the properties { rowKey, business_partner_function, partner { id } }
  const [editRows, setEditRows] = useState([])

  const [isSearchBusinessPartnerDialogOpen, setIsSearchBusinessPartnerDialogOpen] = useState(false)
  const [searchBusinessPartnerDialogRowKey, setSearchBusinessPartnerDialogRowKey] = useState('')

  const removeChangeFromEditRows = (rowKey) =>
    setEditRows([...editRows.filter((editRow) => editRow.rowKey !== rowKey)])

  const handleCancelEdit = (rowKey) => {
    removeChangeFromEditRows(rowKey)
  }

  const findEditRow = useCallback(
    (rowKey) => ({ ...editRows.find((editRow) => editRow.rowKey === rowKey) }),
    [editRows],
  )

  const {
    data: partners,
    isLoading: isLoadingBusinessPartners,
    isError: isErrorBusinessPartners,
  } = useBusinessPartnersByIds(getPartnerIds(businessPartnersOnEntity), {
    enabled: businessPartnersOnEntity.length > 0,
  })

  const [partnerSearchKey, setPartnerSearchKey] = useState('')

  const handleSave = (rowKey) => {
    const rowToSave = findEditRow(rowKey)
    const partnerObjectToSave = {
      party_id: rowToSave.partner.id,
      business_partner_function_code: rowToSave.business_partner_function,
    }
    setEditRows([...editRows.filter((editRow) => editRow.rowKey !== rowKey)])
    if (rowKey === rowKeyNewRow) {
      handleSaveRow({ ...partnerObjectToSave })
    } else {
      handleUpdateRow(rowKey, partnerObjectToSave)
    }
  }

  const getMatchingPartner = useCallback(
    (businessPartnerOnEntity) => {
      const partnersToSearch =
        partnerFunctionMode === 'external' ? _.get(partners, 'businessPartnerMinis') : partners
      return partnersToSearch?.find(
        (partner) =>
          partner.id === businessPartnerOnEntity.party_id ||
          partner.id === businessPartnerOnEntity.display_id,
      )
    },
    [partnerFunctionMode, partners],
  )

  const renderSuggestions = useMemo(() => {
    const suggestions = getBusinessPartnerSuggestions(partnerSearchKey)?.map((partner, index) => {
      let name
      const id = partner.id
      if (partnerFunctionMode === 'external') {
        name = partner.name
      } else if (partnerFunctionMode === 'internal') {
        name = partner.fullName
      }
      return (
        <SuggestionItem
          key={index}
          id={`${name}_${id}_suggestion`}
          data-partner-id={id}
          text={name}
          additionalText={id}
        />
      )
    })
    return isEmpty(suggestions) ? (
      <SuggestionItem key="no-data" text={tPartnerCardTable('businesspartner.search.no-data')} />
    ) : (
      suggestions
    )
  }, [getBusinessPartnerSuggestions, partnerFunctionMode, partnerSearchKey, tPartnerCardTable])

  const enrichWithPartnerIdIfPossible = useCallback(
    (newRow) => {
      const existingRow = businessPartnersOnEntity.find(
        (businessPartnerOnEntity) => businessPartnerOnEntity.rowKey === newRow.rowKey,
      )
      if (existingRow) {
        return { ...newRow, partner: { id: existingRow.party_id } }
      }
      return newRow
    },
    [businessPartnersOnEntity],
  )

  const getNewEditRow = useCallback(
    ({ rowKey, parameter, value, oldRow }) => {
      let newRow = oldRow
        ? { ...oldRow }
        : { rowKey: rowKey, business_partner_function: null, partner: {} }
      newRow = enrichWithPartnerIdIfPossible(newRow)
      newRow[parameter] = value
      return newRow
    },
    [enrichWithPartnerIdIfPossible],
  )

  const updateEditRow = useCallback(
    (rowKey, parameter, value) => {
      //if the row is new in editMode, no corresponding editRow object exists yet -> Create and add it to the state array
      if (_.isEmpty(findEditRow(rowKey))) {
        const newEditRow = getNewEditRow({ rowKey, parameter, value })
        setEditRows([...editRows, newEditRow])
      } else {
        //if the row was already in editMode, change the corresponding entry in the state and leave the rest as it was
        const editRowsUpdated = editRows.map((oldRow) => {
          if (oldRow.rowKey !== rowKey) {
            return { ...oldRow }
          }
          return getNewEditRow({ rowKey, parameter, value, oldRow })
        })
        setEditRows([...editRowsUpdated])
      }
    },
    [editRows, findEditRow, getNewEditRow],
  )

  const onSuggestionItemSelect = useCallback(
    (rowKey, { detail: { item: selectedSuggestion } }) => {
      const entityId = selectedSuggestion.getAttribute('data-partner-id')
      updateEditRow(rowKey, 'partner', { id: entityId })
    },
    [updateEditRow],
  )

  const openSearchBusinessPartnerDialog = (rowKey) => {
    setIsSearchBusinessPartnerDialogOpen(true)
    setSearchBusinessPartnerDialogRowKey(rowKey)
  }

  const getValidationErrorResult = useCallback(
    (errorKey) => ({
      isError: true,
      errorMessage: tPartnerCardTable(errorKey),
    }),
    [tPartnerCardTable],
  )

  const validationRules = {
    DUPLICATED_FUNCTION: {
      predicate: (rowToCheck) =>
        businessPartnersOnEntity.find(
          (businessPartnerOnEntity) =>
            businessPartnerOnEntity.business_partner_function_code ===
              rowToCheck.business_partner_function &&
            businessPartnerOnEntity.party_id === rowToCheck.partner?.id,
        ),
      message: 'save.error.alreadyexists',
    },
    FUNCTION_MISSING: {
      predicate: (rowToCheck, isRowValidation) =>
        rowToCheck.business_partner_function === 'emptyOption' ||
        (!rowToCheck.business_partner_function && isRowValidation),
      message: 'save.error.functionmissing',
    },
    PARTNER_NOT_FOUND: {
      predicate: (rowToCheck, isRowValidation) =>
        (!isEmpty(rowToCheck) && !isEmpty(rowToCheck.partner) && !rowToCheck.partner?.id) ||
        (!rowToCheck.partner?.id && isRowValidation),
      message: 'save.error.idnotfound',
    },
  }

  const evaluateValueStatePartnerFunctionField = useCallback(
    (rowKey, isRowValidation) => {
      const rowToCheck = findEditRow(rowKey)

      if (validationRules.FUNCTION_MISSING.predicate(rowToCheck, isRowValidation)) {
        return getValidationErrorResult(validationRules.FUNCTION_MISSING.message)
      }
      if (validationRules.DUPLICATED_FUNCTION.predicate(rowToCheck, isRowValidation)) {
        return getValidationErrorResult(validationRules.DUPLICATED_FUNCTION.message)
      }
      return { isError: false }
    },
    [
      findEditRow,
      getValidationErrorResult,
      validationRules.DUPLICATED_FUNCTION,
      validationRules.FUNCTION_MISSING,
    ],
  )

  const evaluateValueStatePartnerField = useCallback(
    (rowKey, isRowValidation = false) => {
      const rowToCheck = findEditRow(rowKey)

      if (validationRules.PARTNER_NOT_FOUND.predicate(rowToCheck, isRowValidation)) {
        return getValidationErrorResult(validationRules.PARTNER_NOT_FOUND.message)
      }
      if (validationRules.DUPLICATED_FUNCTION.predicate(rowToCheck, isRowValidation)) {
        return getValidationErrorResult(validationRules.DUPLICATED_FUNCTION.message)
      }
      return { isError: false }
    },
    [
      findEditRow,
      getValidationErrorResult,
      validationRules.DUPLICATED_FUNCTION,
      validationRules.PARTNER_NOT_FOUND,
    ],
  )

  const renderValueStateMessage = (valueStateObject) => <Text>{valueStateObject.errorMessage}</Text>

  const checkIsValidReturnErrorMessage = useCallback(
    (rowKey) => {
      const partnerInvalid = evaluateValueStatePartnerField(rowKey, true).isError
      const partnerFunctionInvalid = evaluateValueStatePartnerFunctionField(rowKey, true).isError
      return { isError: partnerInvalid || partnerFunctionInvalid }
    },
    [evaluateValueStatePartnerField, evaluateValueStatePartnerFunctionField],
  )

  const renderInputWithSuggestions = useCallback(
    ({ rowKey, partner, isEdit = true }) => {
      const rowInEditMode = findEditRow(rowKey)
      const partnerNameOnDisplay = rowInEditMode.partner?.name ?? partner?.fullName ?? ''
      const valueStateObject = evaluateValueStatePartnerField(rowKey)
      return (
        <Input
          showSuggestions
          id={'input_' + rowKey}
          value={partnerNameOnDisplay}
          valueState={valueStateObject.isError ? ValueState.Error : ValueState.None}
          valueStateMessage={rowKey === rowKeyNewRow && renderValueStateMessage(valueStateObject)}
          className={styles['input']}
          onInput={(event) => {
            setPartnerSearchKey(event.target.value)
          }}
          onBlur={(event) => {
            if (isEmpty(event.target.value) && !event.target.hasSuggestionItemSelected) {
              updateEditRow(rowKey, 'partner', { id: null })
            }
          }}
          onChange={(event) => {
            if (isEmpty(event.target.value) || !event.target.hasSuggestionItemSelected) {
              updateEditRow(rowKey, 'partner', { id: null })
            }
          }}
          onSuggestionItemSelect={(event) => onSuggestionItemSelect(rowKey, event)}
          icon={
            partnerFunctionMode === 'external' &&
            isEdit && (
              <Icon
                name="value-help"
                id={`externalBP_${rowKey}_icon`}
                onClick={() => openSearchBusinessPartnerDialog(rowKey)}
              />
            )
          }
          showClearIcon
          // BP should only be editable for new entries
          readonly={rowKey !== rowKeyNewRow}
        >
          {renderSuggestions}
        </Input>
      )
    },
    [
      evaluateValueStatePartnerField,
      findEditRow,
      onSuggestionItemSelect,
      partnerFunctionMode,
      renderSuggestions,
      updateEditRow,
    ],
  )

  const renderSelectOptions = useCallback(
    (businessPartnerFunctionCode) => {
      const partnerFunctions = getPartnerFunctions()
      const emptyOption = (
        <Option
          key={'emptyOption'}
          data-id={'emptyOption'}
          data-testid={'emptyOption'}
          selected={true}
        />
      )
      const options = partnerFunctions
        ? partnerFunctions.map((partnerFunction) => (
            <Option
              key={partnerFunction.business_partner_function_code}
              data-id={partnerFunction.business_partner_function_code}
              data-testid={partnerFunction.business_partner_function_code}
              selected={
                partnerFunction.business_partner_function_code === businessPartnerFunctionCode
              }
              value={partnerFunction}
            >
              {partnerFunction.business_partner_function_name}
            </Option>
          ))
        : []
      return [emptyOption, ...options]
    },
    [getPartnerFunctions],
  )

  const onSelectFunctionChange = useCallback(
    (rowKey, businessPartnerFunction) => {
      updateEditRow(rowKey, 'business_partner_function', businessPartnerFunction)
    },
    [updateEditRow],
  )

  const renderPartnerCell = useCallback(
    (partner) => (
      <EntityCell
        id={partner?.id}
        isError={isErrorBusinessPartners}
        isLoading={isLoadingBusinessPartners}
        name={partner?.fullName}
        link={
          partnerFunctionMode === 'external' ? `/${paths.businessPartners}/${partner?.id}` : null
        }
        className={styles.businessPartnerCell}
      />
    ),
    [isErrorBusinessPartners, isLoadingBusinessPartners, partnerFunctionMode],
  )

  const renderSelectCell = useCallback(
    (businessPartnerOnEntity) => {
      const rowKey = businessPartnerOnEntity.rowKey
      const valueStateObject = evaluateValueStatePartnerFunctionField(rowKey)

      const handleSelectChange = (event) => {
        onSelectFunctionChange(rowKey, event.detail.selectedOption.dataset.id)
      }

      return (
        <FlexBox>
          <Select
            id={`${rowKey}-function-select`}
            data-testid={`${rowKey}-function-select`}
            valueState={valueStateObject.isError ? ValueState.Error : ValueState.None}
            valueStateMessage={renderValueStateMessage(valueStateObject)}
            className={styles.select}
            onChange={handleSelectChange}
            onBlur={(event) => {
              if (
                isNil(event.detail.selectedOption?.dataset?.id) &&
                isEmpty(event.target.selectedItem)
              ) {
                updateEditRow(rowKey, 'business_partner_function', 'emptyOption')
              }
            }}
          >
            {renderSelectOptions(businessPartnerOnEntity?.business_partner_function_code)}
          </Select>
        </FlexBox>
      )
    },
    [evaluateValueStatePartnerFunctionField, onSelectFunctionChange, renderSelectOptions],
  )

  const checkIfAnythingChanged = useCallback(
    (rowKey, businessPartnerOnEntity) => {
      const editRow = findEditRow(rowKey)
      return Object.entries(editRow).some(([key, value]) => {
        if (key === 'rowKey') return false
        return businessPartnerOnEntity[key] !== value
      })
    },
    [findEditRow],
  )

  const newRowIsChanged = () => {
    const newRow = findEditRow(rowKeyNewRow)
    const partnerFunctionEmpty =
      !newRow.business_partner_function || newRow.business_partner_function === 'emptyOption'
    const partnerEmpty = !newRow.partner?.id
    return !(partnerFunctionEmpty && partnerEmpty)
  }

  const columnDefinitions = [
    {
      columnKey: 'partner',
      title: tPartnerCardTable('partner'),
      minWidth: 175,
      hasPopin: false,
    },
    {
      columnKey: 'function',
      title: tPartnerCardTable('function'),
      minWidth: 125,
      hasPopin: true,
    },
  ]

  const tableData = useMemo(
    () =>
      businessPartnersOnEntity
        ?.map((businessPartnerOnEntity) => {
          const matchingPartner = getMatchingPartner(businessPartnerOnEntity)
          return {
            ...businessPartnerOnEntity,
            matchingPartner: matchingPartner,
          }
        })
        .sort((partnerA, partnerB) => {
          const nameCompare = partnerA.matchingPartner?.fullName.localeCompare(
            partnerB.matchingPartner?.fullName,
          )
          const functionCompare = partnerA.business_partner_function_name.localeCompare(
            partnerB.business_partner_function_name,
          )
          return nameCompare === 0 ? functionCompare : nameCompare
        })
        .map((businessPartnerOnEntity) => ({
          rowKey: businessPartnerOnEntity.rowKey,
          partner: {
            cellContentReadMode: renderPartnerCell(businessPartnerOnEntity?.matchingPartner),
            cellContentEditMode: renderInputWithSuggestions({
              rowKey: businessPartnerOnEntity.rowKey,
              partner: businessPartnerOnEntity.matchingPartner,
              isEdit: businessPartnerOnEntity.rowKey === rowKeyNewRow,
            }),
          },
          function: {
            cellContentReadMode: businessPartnerOnEntity.business_partner_function_name,
            cellContentEditMode: renderSelectCell(businessPartnerOnEntity),
          },
          isValid: !checkIsValidReturnErrorMessage(businessPartnerOnEntity.rowKey).isError,
          hasChanges: checkIfAnythingChanged(
            businessPartnerOnEntity.rowKey,
            businessPartnerOnEntity,
          ),
        })),
    [
      businessPartnersOnEntity,
      checkIfAnythingChanged,
      checkIsValidReturnErrorMessage,
      getMatchingPartner,
      renderInputWithSuggestions,
      renderPartnerCell,
      renderSelectCell,
    ],
  )

  const newRow = {
    rowKey: rowKeyNewRow,
    partner: {
      cellContentEditMode: renderInputWithSuggestions({
        rowKey: rowKeyNewRow,
      }),
    },
    function: {
      cellContentEditMode: renderSelectCell({ rowKey: rowKeyNewRow }),
    },
    isValid: !checkIsValidReturnErrorMessage(rowKeyNewRow).isError,
    hasChanges: newRowIsChanged(),
  }

  return (
    <>
      <CardWithDisplayAndEditTable
        cardTitle={cardTitle}
        columnDefinitions={columnDefinitions}
        tableData={tableData}
        nrOfEntries={tableData?.length ?? 0}
        isLoading={isLoading}
        cardType={businessPartner}
        isError={isError}
        userIsAllowedToEdit={userIsAllowedToEdit}
        newRow={newRow}
        handleDeleteRow={handleDeleteRow}
        handleSaveRow={(rowKey) => {
          handleSave(rowKey)
          removeChangeFromEditRows(rowKey)
        }}
        handleCancelEditRow={handleCancelEdit}
        popoverStaticConfig={{
          cancel: {
            message: tPartnerCardTable('cancelmessage'),
            button: tPartnerCardTable('discard.button'),
          },
          delete: {
            message: tPartnerCardTable('deleterow'),
            button: t('buttons.delete'),
          },
        }}
        checkIsValidReturnErrorMessage={checkIsValidReturnErrorMessage}
        cardHeaderMessageStrip={renderMessageStrip(businessPartnersOnEntity)}
      />

      <RentRollBusinessPartnerSearchDialog
        open={isSearchBusinessPartnerDialogOpen}
        initialSearch={''}
        onChange={(changedBusinessPartner) => {
          updateEditRow(searchBusinessPartnerDialogRowKey, 'partner', changedBusinessPartner)
          setIsSearchBusinessPartnerDialogOpen(false)
        }}
        onClose={() => setIsSearchBusinessPartnerDialogOpen(false)}
        withCreateOption={false}
        translatedTypeName={tPartnerCardTable('partner')}
      />
    </>
  )
}

BusinessPartnersForEntityTable.propTypes = propTypes

export default BusinessPartnersForEntityTable
