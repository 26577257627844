import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showCurrentTileVersion } from 'redux/slices/decision-paper/tilesOverviewSlice'

const BackToCurrentVersionButton = ({ tileId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix:
      'components.business-events-and-tasks.decision-papers.buttons.back-to-current-version',
  })
  const dispatch = useDispatch()

  const handleButtonClicked = useCallback(() => {
    dispatch(showCurrentTileVersion(tileId))
  }, [dispatch, tileId])

  return (
    <Button design={ButtonDesign.Emphasized} onClick={handleButtonClicked}>
      {t('text')}
    </Button>
  )
}

BackToCurrentVersionButton.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default BackToCurrentVersionButton
