import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CardHeaderWithMetrics from 'components/ui/card/CardHeaderWithMetrics'
import { useAreaMeasurementUnitFormatter, useNumberFormatter } from 'hooks/i18n/useI18n'
import useCurrentRentRollOverviewPropertyKpis from 'hooks/services/properties/kpis/useCurrentRentRollOverviewPropertyKpis'

const useOccupancyCardHeaderData = (data) => {
  const { value: totalAreaValue, measurementUnit: areaUnit } = data?.totalAreaSurface ?? {}
  const referenceDate = data?.keyDate
  const vacancyAreaValue = data?.vacancySurface?.value
  const occupancyRate = (totalAreaValue - vacancyAreaValue) / totalAreaValue

  const formatNumber = useNumberFormatter({ maximumFractionDigits: 2, minimumFractionDigits: 2 })
  const formattedOccupancyRatePercentage = useMemo(
    () => (Number.isFinite(occupancyRate) ? formatNumber(occupancyRate * 100) : ''),
    [formatNumber, occupancyRate],
  )

  const formatAreaUnit = useAreaMeasurementUnitFormatter()
  const formattedArea =
    totalAreaValue && areaUnit && `${formatNumber(totalAreaValue)} ${formatAreaUnit(areaUnit)}`

  return {
    occupancyRate: occupancyRate ? formattedOccupancyRatePercentage : undefined,
    referenceDate: referenceDate,
    area: totalAreaValue ? formattedArea : undefined,
  }
}

const PropertyRentRollOverviewOccupancyCardHeader = ({ ...otherProps }) => {
  const { isLoading, isError, data: propertyKpis } = useCurrentRentRollOverviewPropertyKpis()

  const { occupancyRate, referenceDate, area } = useOccupancyCardHeaderData(propertyKpis)

  const { t: tOccupancy } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.occupancy',
  })

  return (
    <CardHeaderWithMetrics
      {...otherProps}
      isLoading={isLoading}
      isError={isError}
      title={tOccupancy('title')}
      primaryMetric={{
        label: tOccupancy('subtitle'),
        value: occupancyRate,
        unit: '%',
      }}
      secondaryMetrics={[
        {
          label: tOccupancy('area'),
          value: area,
        },
      ]}
      referenceDate={referenceDate}
    />
  )
}

export default PropertyRentRollOverviewOccupancyCardHeader
