import {
  CustomListItem,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  ListItemType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperTileListItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListItem'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/keyFacts/FactSheetKeyFactsTileViewModeV1.module.css'
import FormattedAddress from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/keyFacts/shared/FormattedAddress'

const NO_DATA_PLACEHOLDER = '-'

const FactSheetKeyFactsTileViewModeV1 = ({ tileId }) => {
  const tileState = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const {
    data: {
      propertiesInformation,
      modernizationYear,
      kpiRentValues,
      isMultiProperty,
      noProperties,
    },
  } = tileState

  const { t: tKeyFacts } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.fact-sheet-key-facts',
  })

  const getValueOrPlaceholder = (value) => (value ? value : NO_DATA_PLACEHOLDER)

  const getYearOrPlaceholder = (yearList) =>
    yearList?.length > 1 ? `${yearList[0]} - ${yearList[1]}` : getValueOrPlaceholder(yearList?.[0])

  const getCountryOrPlaceholder = (countryList) => {
    if (countryList?.length > 1) {
      return countryList.join(', ')
    }
    return getValueOrPlaceholder(countryList?.[0])
  }

  if (noProperties) {
    return <NoPropertyAssignedToDealInfo />
  }
  return (
    <>
      <DecisionPaperTileListItem
        label={tKeyFacts('major-type')}
        value={getValueOrPlaceholder(propertiesInformation?.majorTypeName)}
        isFirstItem
      />
      {!isMultiProperty && (
        <DecisionPaperTileListItem
          label={tKeyFacts('sub-type')}
          value={getValueOrPlaceholder(propertiesInformation?.subType)}
        />
      )}
      <DecisionPaperTileListItem
        label={tKeyFacts('construction-year')}
        value={getYearOrPlaceholder(propertiesInformation?.constructionYear)}
      />
      <DecisionPaperTileListItem
        label={tKeyFacts('modernization-year')}
        value={getYearOrPlaceholder(modernizationYear)}
      />
      <DecisionPaperTileListItem
        label={isMultiProperty ? tKeyFacts('countries') : tKeyFacts('country')}
        value={getCountryOrPlaceholder(propertiesInformation?.countryName)}
      />
      {!isMultiProperty && (
        <CustomListItem type={ListItemType.Inactive}>
          <FlexBox fitContainer justifyContent={FlexBoxJustifyContent.SpaceBetween}>
            <Label>{tKeyFacts('address')}</Label>
            <FlexBox
              alignItems={FlexBoxAlignItems.End}
              direction={FlexBoxDirection.Column}
              className={styles.address}
            >
              <FormattedAddress
                country={propertiesInformation?.address?.countryName}
                street={propertiesInformation?.address?.streetName}
                houseNumber={propertiesInformation?.address?.houseId}
                zipCode={propertiesInformation?.address?.postalCode}
                city={propertiesInformation?.address?.cityName}
              />
            </FlexBox>
          </FlexBox>
        </CustomListItem>
      )}
      {isMultiProperty && (
        <DecisionPaperTileListItem
          label={tKeyFacts('nr-of-properties')}
          value={getValueOrPlaceholder(propertiesInformation?.nrOfProperties)}
        />
      )}
      <DecisionPaperTileListItem
        label={tKeyFacts('annualized-current-rent')}
        value={getValueOrPlaceholder(kpiRentValues?.annualizedCurrentRent)}
      />
      <div className={styles.lastItem}>
        <DecisionPaperTileListItem
          label={tKeyFacts('market-rent')}
          value={getValueOrPlaceholder(kpiRentValues?.marketRent)}
        />
      </div>
    </>
  )
}

FactSheetKeyFactsTileViewModeV1.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default FactSheetKeyFactsTileViewModeV1
