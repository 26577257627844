import orderBy from 'lodash.orderby'
import uniqBy from 'lodash.uniqby'
import { useContext } from 'react'
import AssociatedBusinessPartnerBorrower from 'components/domains/business-partners/related-documents/AssociatedBusinessPartnerBorrower'
import { useConfig } from 'hooks/config/useConfig'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationships'
import useDealMini from 'hooks/services/deals/useDealMini'
import { DealContext } from 'routes/deals/DealContext'

const BusinessPartnerAssociatedBorrowers = () => {
  const { deal } = useContext(DealContext)
  const {
    data: dealData,
    isLoading: useDealIsLoading,
    isError: useDealIsError,
  } = useDealMini(deal.dealUuid)
  const borrowerBpId = dealData?.borrowerBpId

  const {
    data: businessPartnerMiniData,
    isLoading: byIdsIsLoading,
    isError: byIdsIsError,
  } = useBusinessPartnerMiniByIds(borrowerBpId ? [borrowerBpId] : [])
  const borrowers = businessPartnerMiniData?.businessPartnerMinis ?? []

  const { data: config, isLoading: isConfigLoading, isError: isConfigError } = useConfig()
  const {
    data: relationships,
    isLoading: relationshipIsLoading,
    isError: relationshipIsError,
  } = useBusinessPartnerRelationships(dealData?.borrowerBpId)
  const borrowerRelationships =
    relationships?.unitRelationships.filter(
      (relationship) =>
        relationship.id === config?.businessPartner?.sapRelationshipIds.jointAccount ||
        relationship.id === config?.businessPartner?.sapRelationshipIds.civilLawAssociation,
    ) || []
  const borrowerIsHead = borrowerRelationships?.some((relationship) =>
    relationship?.relationships.some((rl) => rl.head?.id === dealData?.borrowerBpId),
  )

  const secondaryBorrowers = orderBy(
    uniqBy(
      borrowerIsHead
        ? borrowerRelationships
            .map((relationship) =>
              relationship.relationships
                .map((rl) => rl.members?.map(({ id, fullName }) => ({ id, fullName })))
                .flat(),
            )
            .flat()
        : borrowerRelationships
            .map((relationship) =>
              relationship.relationships.map((rl) => ({
                id: rl.head?.id,
                fullName: rl.head?.fullName,
              })),
            )
            .flat() || [],
      'id',
    ),
    ['fullName'],
    ['asc'],
  )

  return (
    <AssociatedBusinessPartnerBorrower
      businessPartners={[...borrowers, ...secondaryBorrowers]}
      isLoading={useDealIsLoading || byIdsIsLoading || relationshipIsLoading || isConfigLoading}
      isError={useDealIsError || byIdsIsError || relationshipIsError || isConfigError}
    />
  )
}

export default BusinessPartnerAssociatedBorrowers
