import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/conditions/overview/ConditionsTableEmpty.module.css'

const ConditionsTableEmpty = ({ isFilterOrSearchApplied, overrideText }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.conditions.table' })

  const calculatedText = useMemo(() => {
    if (overrideText) {
      return overrideText
    }
    if (isFilterOrSearchApplied) {
      return t('no-filter-results')
    }
    return t('empty')
  }, [isFilterOrSearchApplied, t, overrideText])

  return (
    <FlexBox
      justifyContent={FlexBoxJustifyContent.Center}
      alignItems={FlexBoxAlignItems.Center}
      className={styles.noData}
    >
      <Text wrapping>{calculatedText}</Text>
    </FlexBox>
  )
}

ConditionsTableEmpty.propTypes = {
  isFilterOrSearchApplied: PropTypes.bool.isRequired,
  overrideText: PropTypes.string,
}

export default ConditionsTableEmpty
