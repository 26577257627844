import PropTypes from 'prop-types'
import { useCallback, useMemo, useRef, useState } from 'react'
import documentPermissions from 'api/documents/DocumentPermissions'
import CardSection from 'components/domains/deals/card/CardSection'
import styles from 'components/domains/deals/covenant-check-detail/check-documents/CheckDocumentsCard.module.css'
import CovenantCheckDocumentsTable from 'components/domains/deals/covenant-check-detail/check-documents/CovenantCheckDocumentsTable'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import Card from 'components/ui/card/Card'
import DocumentFileDrop from 'components/ui/document/DocumentFileDrop'
import useCanFileDrop from 'components/ui/document/useCanFileDrop'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useDocuments from 'hooks/services/documents/useDocuments'
import useDocumentsAllowedOperations from 'hooks/services/documents/useDocumentsAllowedOperations'

const CheckDocumentsCard = ({ referenceEntity, covenantCheckUuid }) => {
  const {
    data: allowedOperationsData,
    isLoading: isLoadingAllowedOperations,
    isError: isAllowedOperationsError,
  } = useDocumentsAllowedOperations()
  const hasDocumentReadPermission =
    allowedOperationsData?.allowedOperations.includes(documentPermissions.Read) ?? false
  const hasDocumentUploadPermission =
    allowedOperationsData?.allowedOperations.includes(documentPermissions.Create) ?? false

  const {
    data: documents,
    isLoading,
    isError,
  } = useDocuments({
    entityId: referenceEntity.id,
    entityType: referenceEntity.entityType,
  })

  const [files, setFiles] = useState(undefined)
  const handleOnDrop = (newFiles) => {
    setFiles(newFiles)
  }
  const getCovenantCheckDocuments = useCallback((baseDocuments, covenantCheckDocuments = []) => {
    if (baseDocuments?.subFolders?.length) {
      baseDocuments.subFolders.forEach((subFolder) => {
        getCovenantCheckDocuments(subFolder, covenantCheckDocuments)
      })
    }
    if (baseDocuments?.documents?.length) {
      baseDocuments.documents.forEach((document) => {
        if (
          document.entityRefs.some((entityRef) => entityRef.type === DocumentTypes.CovenantCheck)
        ) {
          covenantCheckDocuments.push(document)
        }
      })
    }
    return covenantCheckDocuments
  }, [])

  const documentRoot = useMemo(
    () => ({ documents: getCovenantCheckDocuments(documents) }),
    [documents, getCovenantCheckDocuments],
  )

  const { isOver, canDrop, dropRef } = useCanFileDrop()

  const toastRef = useRef(null)

  if (!hasDocumentReadPermission) return <></>
  return (
    <Card>
      <CardSection>
        <RequestStateResolver
          isLoading={isLoading || isLoadingAllowedOperations}
          isError={isError || isAllowedOperationsError}
          errorToDisplay={<ErrorDataUnavailableInContent />}
          center
          renderContent={() => (
            <div ref={dropRef} className={styles.content}>
              <div>
                <CovenantCheckDocumentsTable
                  allowUploadDocument={hasDocumentUploadPermission}
                  uploadInitialState={{ files }}
                  covenantCheckId={covenantCheckUuid}
                  referenceEntity={referenceEntity}
                  documents={documentRoot}
                  onUploadSuccess={() => setFiles(undefined)}
                />
              </div>
              {hasDocumentUploadPermission && (
                <DocumentFileDrop
                  onDrop={handleOnDrop}
                  isOver={isOver}
                  canDrop={canDrop}
                  ref={toastRef}
                />
              )}
            </div>
          )}
        />
      </CardSection>
    </Card>
  )
}

CheckDocumentsCard.propTypes = {
  covenantCheckUuid: PropTypes.string.isRequired,
  referenceEntity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    documentType: PropTypes.oneOf([DocumentTypes.Deal, DocumentTypes.BusinessPartner]),
    entityType: PropTypes.oneOf([cwpEntityTypes.DEAL, cwpEntityTypes.BUSINESS_PARTNER]),
  }),
}

export default CheckDocumentsCard
