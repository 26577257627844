export default {
  dashboard: '',
  businessEventsAndTasks: 'business-events-and-tasks',
  businessEvents: 'business-events',
  deals: 'deals',
  events: 'events',
  home: 'home',
  markets: 'markets',
  entitlements: 'entitlements',
  properties: 'properties',
  propertyDashboard: 'property-dashboard',
  businessPartners: 'business-partners',
  userProfile: 'user-profile',
  documents: 'documents/:documentId',
  rights: 'rights/:rightId',
  notifications: 'notifications',
  otherApps: 'other-applications',
  decisionPaperPdfExport: 'decision-paper-pdf-export',
  decisionStagePdfExport: 'decision-stage-pdf-export',
  collaterals: 'collaterals/:collateralId',
  riskMonitoring: 'risk-monitoring',
}
