import {
  Button,
  ButtonDesign,
  Modals,
  MessageBoxTypes,
  MessageBoxActions,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import LabeledSwitch from 'components/ui/input/LabeledSwitch'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useUpdateCashflowScenario from 'hooks/services/deals/cashflow/useUpdateCashflowScenario'

const DealCashflowDetailsMainToggle = ({ dealUuid, cashflowScenarioUuid, isMainScenario }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.detail.cashflow' })

  const showToast = Modals.useShowToast()
  const showMessageBox = useShowMessageBox()
  const showErrorMessageBox = useShowErrorMessageBox()
  const queryClient = useQueryClient()

  const updateOnSuccess = () => {
    showToast({
      duration: 5000,
      children: isMainScenario
        ? t('details.toast.main-scenario-flag-unset')
        : t('details.toast.main-scenario-flag-set'),
    })
    queryClient.invalidateQueries(['deals', dealUuid, 'cashflow-scenarios'])
  }
  const updateOnError = async (error) => {
    const { errors: [errorResponse] = [] } = await error.response.json()
    showErrorMessageBox({ message: t('dialog.error.main-scenario-error'), error: errorResponse })
  }

  const { mutate: updateCashflowScenarioSession } = useUpdateCashflowScenario({
    onSuccess: updateOnSuccess,
    onError: updateOnError,
  })

  const toggleOnChange = () =>
    showMessageBox(
      {
        type: MessageBoxTypes.Confirm,
        children: isMainScenario ? (
          <>
            {t('main.confirm.text.unset')}
            <br />
            <br />
            {t('main.confirm.text.unset.details')}
          </>
        ) : (
          <>
            {t('main.confirm.text.set')}
            <br />
            <br />
            {t('main.confirm.text.set.details')}
          </>
        ),
        actions: [
          <Button
            key="button-confirm"
            design={ButtonDesign.Emphasized}
            onClick={() =>
              updateCashflowScenarioSession({
                dealUuid,
                cashflowScenarioUuid,
                data: {
                  isMainScenario: !isMainScenario,
                },
              })
            }
          >
            {t('main.button.confirm')}
          </Button>,
          MessageBoxActions.Cancel,
        ],
      },
      document.body,
    )

  return (
    <LabeledSwitch
      checkedText={t('main.toggle.on')}
      uncheckedText={t('main.toggle.off')}
      checked={isMainScenario}
      onChange={toggleOnChange}
      isLoading={false}
    />
  )
}

DealCashflowDetailsMainToggle.propTypes = {
  cashflowScenarioUuid: PropTypes.string.isRequired,
  dealUuid: PropTypes.string.isRequired,
  isMainScenario: PropTypes.bool.isRequired,
}

export default DealCashflowDetailsMainToggle
