// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../../common.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-info-card-item {
  padding: 16px;
}

.property-info-card-item-edit-mode-first {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}

.property-info-card-item-edit-mode {
  padding-left: 16px;
  padding-right: 16px;
}

.property-monitoring-info-card-item {
  padding: 16px;
}

.property-information-card-text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
}

.property-info-card-item-last {
  padding: 16px;
}

.property-address-card-item {
  padding: 16px;
}

.map-wrapper {
  position: sticky;
  height: 650px;
}

ui5-input {
  width: 100%;
}

ui5-select {
  width: 100%;
}

.display-card-item {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
}

.edit-input-field {
  margin: auto;
}

.edit-multiple-input-fields {
  min-width: fit-content;
}

.card-edit-mode-wrapper {
  padding-top: 16px;
  padding-bottom: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/card/DisplayAndEditCard.css"],"names":[],"mappings":"AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,qBAAqB;EACrB,QAAQ;AACV;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":["@import url(\"../../../common.css\");\n\n.property-info-card-item {\n  padding: 16px;\n}\n\n.property-info-card-item-edit-mode-first {\n  padding-left: 16px;\n  padding-right: 16px;\n  padding-top: 16px;\n}\n\n.property-info-card-item-edit-mode {\n  padding-left: 16px;\n  padding-right: 16px;\n}\n\n.property-monitoring-info-card-item {\n  padding: 16px;\n}\n\n.property-information-card-text-box {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-end;\n  gap: 8px;\n}\n\n.property-info-card-item-last {\n  padding: 16px;\n}\n\n.property-address-card-item {\n  padding: 16px;\n}\n\n.map-wrapper {\n  position: sticky;\n  height: 650px;\n}\n\nui5-input {\n  width: 100%;\n}\n\nui5-select {\n  width: 100%;\n}\n\n.display-card-item {\n  padding-left: 16px;\n  padding-right: 16px;\n  padding-bottom: 8px;\n}\n\n.edit-input-field {\n  margin: auto;\n}\n\n.edit-multiple-input-fields {\n  min-width: fit-content;\n}\n\n.card-edit-mode-wrapper {\n  padding-top: 16px;\n  padding-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
