import { isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const RETRIES = 3

const noImagesResult = { isDisabledResponse: true }

const useTileImageLoader = (
  { eventId, tileId, tileVersion, version },
  { enabled, ...options } = { enabled: true },
) => {
  const currentVersion = version ? version : 'current'
  const versionForPath = tileVersion ? tileVersion : 'current'
  const response = useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/decision-paper/versions/${currentVersion}/tiles/${tileId}/versions/${versionForPath}/images`,
      useCache: true,
      keys: [
        'events',
        eventId,
        'decision-paper',
        'versions',
        currentVersion,
        'tiles',
        tileId,
        'versions',
        versionForPath,
        'images',
      ],
      options: {
        retry: (failureCount, error) => {
          if (isNotFoundError(error)) {
            return false
          }
          if (failureCount < RETRIES) {
            return true
          }
          return false
        },
        enabled,
        ...options,
      },
    }),
  )

  if (!enabled) {
    return noImagesResult
  }
  return response
}

export default useTileImageLoader
