import { useRequest } from 'hooks/services/baseService'

/**
 * Returns all property currency codes and their localized display name
 * The Typecodes structure is as follows:
 * // TODO: Add example
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: CurrencyCodes}}
 *
 */
export const useCurrencyCodes = (options) =>
  useRequest({
    path: `/properties/currency_codes`,
    translated: true,
    options,
  })
