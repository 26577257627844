import { Title, TitleLevel } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import styles from 'components/domains/deals/covenants/check-results-card/CheckResultsChartTooltip.module.css'
import LimitCell from 'components/domains/deals/covenants/shared/LimitCell'
import StatusCell from 'components/domains/deals/covenants/shared/StatusCell'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const CheckResultsChartTooltip = ({ active, payload = [], label }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.results.check-results',
  })
  const { format: formatDate } = useShortDateFormatter()

  if (active && payload && payload.length) {
    const { hardLimit, result, softLimit, unit, currencyCode, status } = payload[0].payload
    const labelAsIsoDate = new Date(label)?.toISOString()
    return (
      <div className={styles.tooltip}>
        <Title className={styles.title} level={TitleLevel.H6}>
          {formatDate(labelAsIsoDate)}
        </Title>
        <CardShowItem
          label={t('soft-limit')}
          value={
            softLimit && <LimitCell value={softLimit} unit={unit} currencyCode={currencyCode} />
          }
        />
        <CardShowItem
          label={t('hard-limit')}
          value={
            hardLimit && <LimitCell value={hardLimit} unit={unit} currencyCode={currencyCode} />
          }
        />
        <CardShowItem
          label={t('result')}
          value={result && <LimitCell value={result} unit={unit} currencyCode={currencyCode} />}
        />
        <CardShowItem label={t('status')} value={<StatusCell status={status} />} />
      </div>
    )
  }
  return null
}

CheckResultsChartTooltip.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.number,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        hardLimit: PropTypes.number,
        result: PropTypes.number,
        softLimit: PropTypes.number,
        unit: PropTypes.string,
        currencyCode: PropTypes.string,
        status: PropTypes.string,
      }),
    }),
  ),
}

export default CheckResultsChartTooltip
