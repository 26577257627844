import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import CreatePropertyEventAction from 'components/domains/properties/header-actions/CreatePropertyEventAction'
import ConfigurableComponentsPage from 'components/ui/configurable-components-page/ConfigurableComponentsPage'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { availableDynamicPagesEnum } from 'hooks/page-config/useGetPageConfig'
import { PropertyContext } from 'routes/properties/PropertyContext'
import PropertyPage from 'routes/properties/PropertyPage'

const PropertyDocumentsDynamic = ({
  id,
  propertyDescription,
  breadcrumbs,
  status,
  additionalStatuses,
}) => {
  const { property } = useContext(PropertyContext)

  const subtitle = <EntityTypeAndIdWithClipboard id={property.id} />

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="property-details-user-favorite-icon"
        entityId={property.uuid}
        entityType={cwpEntityTypes.PROPERTY}
      />
    ),
    [property.uuid],
  )

  return (
    <ConfigurableComponentsPage
      pageCode={availableDynamicPagesEnum.PropertiesDocuments}
      renderContent={({ children }) => (
        <PropertyPage
          pageTitle={propertyDescription}
          id={id}
          subtitle={subtitle}
          breadcrumbs={breadcrumbs}
          status={status}
          additionalStatuses={additionalStatuses}
          actions={createSegmentedHeaderActions([
            <CreatePropertyEventAction key="create-event-action" />,
            markFavoriteAction,
          ])}
        >
          {children}
        </PropertyPage>
      )}
    />
  )
}
PropertyDocumentsDynamic.propTypes = {
  id: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ).isRequired,
  status: PropTypes.shape({
    text: PropTypes.string.isRequired,
    valueState: PropTypes.string,
  }).isRequired,
  additionalStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string.isRequired,
      valueState: PropTypes.string,
    }),
  ),
}

export default PropertyDocumentsDynamic
