import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/properties/monitoring/market-valuation/table-cells/UserTableCell.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'

const UserTableCell = ({ userId, errorMessage }) => {
  const { isFetching, isError, data: userData } = useStaffMemberById(userId)

  const renderContent = () => <Text>{userData?.fullName ?? userId}</Text>

  const error = <Text className={styles.errorText}>{errorMessage}</Text>

  return (
    <SmallLoadingWrapper
      isLoading={isFetching}
      isError={isError}
      renderContent={renderContent}
      error={error}
    />
  )
}

UserTableCell.propTypes = {
  userId: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
}

export default UserTableCell
