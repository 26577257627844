import { useContext } from 'react'
import { PropertyRentRollKpiChartsDataContext } from 'components/domains/properties/common/PropertyRentRollKpiChartsDataProvider'

/**
 * @typedef {ReturnType<typeof import('hooks/services/properties/common.schema').default.RentRollKPIsDTO>} CurrentRentRollOverviewPropertyKpisSchema
 * @typedef {import('zod').infer<CurrentRentRollOverviewPropertyKpisSchema>} CurrentRentRollOverviewPropertyKpis
 *
 * @typedef {object} CurrentRentRollOverviewPropertyKpisResult
 * @property {CurrentRentRollOverviewPropertyKpis | null} data
 * @property {boolean} isLoading
 * @property {boolean} isError
 *
 * @returns {CurrentRentRollOverviewPropertyKpisResult}
 */
const useCurrentRentRollOverviewPropertyKpis = () => {
  const { usePropertyRentRollKpiChartsData } = useContext(PropertyRentRollKpiChartsDataContext)

  if (usePropertyRentRollKpiChartsData) return usePropertyRentRollKpiChartsData()

  throw new Error(
    'useCurrentRentRollOverviewPropertyKpis: must be used inside a child component of `PropertyRentRollKpiChartsDataProvider`',
  )
}

export default useCurrentRentRollOverviewPropertyKpis
