import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { kpisAllowedOperations } from 'api/kpis/kpisAllowedOperations'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import KpiOverviewCard from 'components/domains/kpis/KpiOverviewCard'
import KpiOverviewChartCard from 'components/domains/kpis/KpiOverviewChartCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Page from 'components/ui/page/Page'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import KpiPermissionWrapper from 'routes/kpis/KpiPermissionWrapper'

const BusinessPartnerKpiOverviewPage = () => {
  const { t } = useTranslation()
  const { id } = useContext(BusinessPartnerContext)

  const generateBreadcrumbs = () => {
    if (id) {
      return [{ text: t('navigation.item.title.business-partner-kpis') }]
    }
    return []
  }

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [id],
  )

  const renderContent = () => (
    <KpiPermissionWrapper requiredOperation={kpisAllowedOperations.readKpi}>
      <CWPLayout>
        <CWPLayout.Full>
          <KpiOverviewCard
            referenceEntityType={cwpEntityTypes.BUSINESS_PARTNER}
            referenceEntityId={id}
          />
        </CWPLayout.Full>
        <CWPLayout.Full>
          <KpiOverviewChartCard
            referenceEntityType={cwpEntityTypes.BUSINESS_PARTNER}
            referenceEntityId={id}
          />
        </CWPLayout.Full>
      </CWPLayout>
    </KpiPermissionWrapper>
  )

  return (
    <Page
      isError={false}
      isLoading={false}
      renderHeaderTitle={() => (
        <BusinessPartnerHeader
          actions={[
            <CreateBusinessPartnerEventAction key={'create-event-action'} />,
            markFavoriteAction,
          ]}
          breadcrumbs={generateBreadcrumbs()}
        />
      )}
      renderContent={renderContent}
    />
  )
}

export default BusinessPartnerKpiOverviewPage
