import { MultiComboBox, MultiComboBoxItem } from '@fioneer/ui5-webcomponents-react'
import get from 'lodash.get'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * Renders a multiComboBox with support for a hook using `useQuery` to load the
 * dropdown values. Loading and error messages are rendered based on the
 * `useQuery` return values.
 * @param id the id of the `<MultiComboBox>` node
 * @param useLoader the hook which returns { data, isLoading, isError } corresponding to the return object of `useQuery`.
 * The returned data must be a list of objects with the `code` and `displayName` keys. In case that the orginial useQuery
 * hook does not return the data in the expected format, the re-formatting should be done in a parent component, e.g, FilterMarketTypesComboBox.
 * @param input parameter used by the given useLoader hook
 * @param initialValue initial combobox value can be set to empty string
 * @param selected the initial selected values
 * @param onSelectionChange the change handler
 * @param {...props} props is referenced by the MultiComboBox component
 */
const LoadingMultiComboBox = ({
  id,
  useLoader,
  input,
  initialValue,
  selected,
  onSelectionChange,
  selectionName,
  optionKeyName,
  optionDisplayName,
  ...props
}) => {
  const { data, isError, isLoading } = useLoader(input)
  const { t } = useTranslation('translation', { keyPrefix: 'components.loading-multi-combo-box' })

  const selectionKey = optionKeyName || 'code'
  const selectionDisplayName = optionDisplayName || 'displayName'

  const parsedData = useMemo(() => {
    if (selectionName) {
      return get(data, selectionName, [])
    }
    return data
  }, [data, selectionName])

  const options = useMemo(() => {
    if (isLoading) {
      return [<MultiComboBoxItem key="loading" text={t('loading')} />]
    }
    if (isError) {
      return [<MultiComboBoxItem key="error" text={t('error')} />]
    }
    return parsedData.map((item) => (
      <MultiComboBoxItem
        key={item[selectionKey]}
        data-value={item[selectionKey]}
        selected={selected?.includes(item[selectionKey])}
        text={item[selectionDisplayName]}
      >
        {item[selectionDisplayName]}
      </MultiComboBoxItem>
    ))
  }, [isLoading, isError, parsedData, selected, t, selectionKey, selectionDisplayName])

  if (isLoading || isError || initialValue !== undefined) {
    options.unshift(
      <MultiComboBoxItem key="initialSelect" data-value={initialValue}>
        {initialValue}
      </MultiComboBoxItem>,
    )
  }

  return (
    <MultiComboBox id={id} onSelectionChange={onSelectionChange} {...props}>
      {options}
    </MultiComboBox>
  )
}

LoadingMultiComboBox.propTypes = {
  id: PropTypes.string,
  useLoader: PropTypes.func.isRequired,
  input: PropTypes.any,
  initialValue: PropTypes.string,
  selected: PropTypes.array,
  onSelectionChange: PropTypes.func.isRequired,
  selectionName: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  optionKeyName: PropTypes.string,
  optionDisplayName: PropTypes.string,
}

export default LoadingMultiComboBox
