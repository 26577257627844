import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/covenant-check-detail/checked-covenants/CheckedCovenantsTable.module.css'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'

const PERCENT = 'PERCENT'

const LimitValueEditCell = ({ limit, ...props }) => {
  const { t: tNoPrefix } = useTranslation('translation')

  return (
    <div className={styles.limitValuesEdit}>
      <FormattedNumberInput value={limit?.value} {...props} />
      <Text>
        {limit?.unit === PERCENT
          ? tNoPrefix('components.deals.covenants.limit-card.unit.percent')
          : limit?.currencyCode}
      </Text>
    </div>
  )
}

LimitValueEditCell.propTypes = {
  limit: PropTypes.shape({
    value: PropTypes.number,
    unit: PropTypes.string,
    currencyCode: PropTypes.string,
  }),
}

export default LimitValueEditCell
