import { IllustrationMessageSize, IllustrationMessageType } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/rent-roll/overview/vacancy-over-time/PropertyRentRollOverviewVacancyOverTimeCardContent.module.css'
import PropertyRentRollOverviewVacancyOverTimeChart from 'components/domains/properties/rent-roll/overview/vacancy-over-time/PropertyRentRollOverviewVacancyOverTimeChart'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useHistoricalRentRollKpisByUsageType from 'hooks/services/properties/kpis/charts/useHistoricalRentRollKpisByUsageType'
import useHistoricalUsageTypeChartData from 'hooks/services/properties/kpis/charts/useHistoricalUsageTypeChartData'

const getVacancyForUsageType = (usageTypeKpi) => usageTypeKpi?.vacancySurface?.percent ?? 0

const getTotalPropertyVacancy = (propertyKpis) => ({
  total: propertyKpis?.vacancySurface?.percent ?? 0,
})

const aggregateGroupedUsageTypeValues = (otherValues) =>
  otherValues?.length ? otherValues.reduce((acc, curr) => acc + curr) / otherValues.length : 0

const PropertyRentRollOverviewVacancyOverTimeCardContent = () => {
  const { t: tVacancyOverTimeChart } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.vacancy-over-time.chart',
  })

  const { data, isLoading, isError } = useHistoricalRentRollKpisByUsageType()
  const { chartData, usageTypes } = useHistoricalUsageTypeChartData(data, {
    getMetricValueForUsageTypeKpi: getVacancyForUsageType,
    getPropertyMetrics: getTotalPropertyVacancy,
    aggregateGroupedUsageTypeValues: aggregateGroupedUsageTypeValues,
  })
  const isEmpty = chartData.length === 0

  return (
    <div className={styles.chartContainer}>
      <LoadingStateWrapper
        isLoading={isLoading}
        isError={isError}
        errorTitle={tVacancyOverTimeChart('error.title')}
        errorDescription={tVacancyOverTimeChart('error.subtitle')}
        errorDetails=" "
        loadingDescription={tVacancyOverTimeChart('loading')}
      >
        {isEmpty ? (
          <EmptyCardContent
            title={tVacancyOverTimeChart('empty.title')}
            subtitle={tVacancyOverTimeChart('empty.subtitle')}
            illustrationName={IllustrationMessageType.UnableToLoad}
            size={IllustrationMessageSize.Scene}
          />
        ) : (
          <PropertyRentRollOverviewVacancyOverTimeChart data={chartData} usageTypes={usageTypes} />
        )}
      </LoadingStateWrapper>
    </div>
  )
}

export default PropertyRentRollOverviewVacancyOverTimeCardContent
