import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useLockArrearApprovalById = ({ onSuccess = () => {}, onError = () => {} }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async (arrearApprovalId) => {
      const { data } = await post({
        path: `/arrears/approvals/${arrearApprovalId}/lock`,
      })
      return data
    },
    { onSuccess, onError },
  )
}
