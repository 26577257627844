import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateEvent = () => {
  const { patch } = useAccessTokenRequest()

  return useMutation(
    async ({
      eventId,
      status,
      info: { currentDueDate, originalDueDate, assignee, description } = {},
    }) => {
      const body = { status }

      if (
        currentDueDate !== undefined ||
        originalDueDate !== undefined ||
        assignee !== undefined ||
        description !== undefined
      ) {
        body.info = {
          current_due_date: currentDueDate,
          original_due_date: originalDueDate,
          assignee,
          description,
        }
      }
      const { data } = await patch({
        path: `/events/${eventId}`,
        body,
      })

      return data
    },
  )
}

export default useUpdateEvent
