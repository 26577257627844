import {
  Button,
  ButtonDesign,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
  ToolbarSeparator,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import { CardHeaderLastEditedText } from 'components/domains/deals/card/CardHeaderLastEditedText'
import styles from 'components/domains/deals/deal-cashflow/input-parameters/DealCashflowInputParametersCard.module.css'
import DealCashflowInputParametersTable from 'components/domains/deals/deal-cashflow/input-parameters/DealCashflowInputParametersTable'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import Card from 'components/ui/card/Card'
import { ToggleEditMode } from 'components/ui/card/CardHeaderWithEditMode'
import CloseableMessageStrip from 'components/ui/feedback/CloseableMessageStrip'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useCashflowScenarioInputParametersById from 'hooks/services/deals/cashflow/useCashflowScenarioInputParametersById'
import useCashflowsObjectDefinitions from 'hooks/services/deals/cashflow/useCashflowsObjectDefinitions'

import useRefreshInitialSegmentInputParameters from 'hooks/services/deals/cashflow/useRefreshInitialSegmentInputParameters'
import useUpdateCashflowScenarioInputParameters from 'hooks/services/deals/cashflow/useUpdateCashflowScenarioInputParameters'
import { DealContext } from 'routes/deals/DealContext'

const DealCashflowInputParametersCard = ({
  cashflowScenario: {
    dealUuid,
    cashflowScenarioUuid,
    lastUpdatedBy,
    cashflowInputLastUpdatedBy,
    cashflowInputLastUpdatedAt,
    lastUpdatedAt,
    frozen,
  },
}) => {
  const { t } = useTranslation('translation')
  const { allowedOperations } = useContext(DealContext)
  const [isEditMode, setIsEditMode] = useState(false)
  const [editData, setEditData] = useState({})
  const changedEditData = useRef()
  const [valueState, setValueState] = useState(ValueState.None)
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const queryClient = useQueryClient()
  const showMessageBox = useShowMessageBox()
  const showErrorMessageBox = useShowErrorMessageBox()
  const showToast = Modals.useShowToast()

  // get input parameter data
  const {
    data = {},
    isLoading: isLoadingCashflowInputParameters,
    isError: isErrorCashflowInputParameters,
    refetch,
  } = useCashflowScenarioInputParametersById(
    {
      dealUuid,
      cashflowId: cashflowScenarioUuid,
    },
    {
      enabled: !!dealUuid && !!cashflowScenarioUuid,
    },
  )
  const { isPropertyDataInSync } = data

  // get input parameter config
  const {
    data: config = {},
    isLoading: isLoadingConfig,
    isError: isErrorConfig,
  } = useCashflowsObjectDefinitions()

  const flatAndMergeCashflowObjects = useCallback(
    ({ parameters, coId }) => ({
      coId,
      parameters: parameters.reduce(
        (
          result,
          { cashflowParameterDefinition: { ccpdId }, cashflowParameterUnit: { ccpuId }, value },
          index,
        ) => ({
          ...result,
          [ccpdId]: {
            ccpdId: ccpdId,
            ccpuId: editData[coId]?.[ccpdId]?.unit || ccpuId,
            value: editData[coId]?.[ccpdId]?.value || value,
            index,
          },
        }),
        {},
      ),
    }),
    [editData],
  )

  const initEditData = useCallback(() => {
    const cfScenarioInputParams = {}

    data.cashflowInputParameters.forEach((co) => {
      cfScenarioInputParams[co.coId] = flatAndMergeCashflowObjects(co)
      co.children.forEach(
        (childObj) =>
          (cfScenarioInputParams[childObj.coId] = flatAndMergeCashflowObjects(childObj)),
      )
    })

    changedEditData.current = cfScenarioInputParams
    setEditData(cfScenarioInputParams)
  }, [data.cashflowInputParameters, flatAndMergeCashflowObjects])

  useEffect(() => {
    if (
      !data.cashflowInputParameters ||
      data.cashflowInputParameters.every(
        (co) =>
          editData[co.coId] &&
          co.parameters.every(
            (parameter) =>
              editData[co.coId].parameters[parameter.cashflowParameterDefinition.ccpdId],
          ),
      )
    ) {
      return
    }
    initEditData()
  }, [
    data,
    flatAndMergeCashflowObjects,
    setEditData,
    editData,
    isLoadingCashflowInputParameters,
    initEditData,
  ])

  const updateCashflowInputParams = useUpdateCashflowScenarioInputParameters({
    onSuccess: () => {
      queryClient.invalidateQueries([
        'deals',
        dealUuid,
        'cashflow-scenarios',
        cashflowScenarioUuid,
        'cashflow',
      ])
      queryClient.invalidateQueries(['deals', dealUuid, 'cashflow-scenarios', cashflowScenarioUuid])
      setIsEditMode(false)
      setIsSaveLoading(false)
    },
    onError: async (error) => {
      setIsSaveLoading(false)
      const { errors: [errorResponse] = [] } = await error.response.json()
      showErrorMessageBox({
        message: t('components.deals.cashflow.input-params.error.text.something-went-wrong'),
        error: errorResponse,
      })
    },
  })

  const onSetEditData = (changes = {}, forceSetEditData) => {
    let parameterChange = false
    const cfScenarioInputParams = { ...changedEditData.current }

    Object.keys(changes).forEach((coId) => {
      cfScenarioInputParams[coId].parameters = {
        ...cfScenarioInputParams[coId].parameters,
        ...changedEditData.current[coId].parameters,
      }

      Object.keys(changes[coId].parameters).forEach((param) => {
        if (param === 'new' && changes[coId].parameters[param].ccpdId) {
          cfScenarioInputParams[coId].parameters[changes[coId].parameters[param].ccpdId] = {
            ...changes[coId].parameters[param],
          }

          parameterChange = true
          return delete cfScenarioInputParams[coId].parameters.new
        }

        cfScenarioInputParams[coId].parameters[param] = {
          ...cfScenarioInputParams[coId].parameters[param],
          ...changes[coId].parameters[param],
        }
      })
    })

    changedEditData.current = cfScenarioInputParams

    if (forceSetEditData || parameterChange) {
      return setEditData(cfScenarioInputParams)
    }
  }

  const handleOnClickSave = () => {
    let invalidData = false

    const cfScenarioInputParams = Object.keys(changedEditData.current).map((coId) => {
      const parameters = Object.keys(changedEditData.current[coId].parameters)
        .filter(
          (ccpdId) =>
            !changedEditData.current[coId].parameters[ccpdId].isRemoved && ccpdId !== 'new',
        )
        .map((ccpdId) => {
          if (changedEditData.current[coId].parameters[ccpdId].value >= 0) {
            return changedEditData.current[coId].parameters[ccpdId]
          }
          invalidData = true
        })

      return {
        coId,
        parameters,
      }
    })

    if (invalidData) {
      showMessageBox({
        children: t('components.deals.cashflow.input-params.error-invalid-input'),
      })
      setValueState(ValueState.Error)
      return
    }

    setIsSaveLoading(true)
    setEditData(changedEditData.current)

    updateCashflowInputParams.mutate({ dealUuid, cashflowScenarioUuid, cfScenarioInputParams })
  }
  const handleOnClickCancel = () => {
    setEditData({})
    setIsEditMode(false)
  }

  const messageStripPropertyDataNotInSync = () =>
    !isPropertyDataInSync && (
      <div
        data-testid="cashflow-input-parameters-message-strip-input-parameters-not-in-sync"
        className={styles['message-stripe-wrapper']}
      >
        <CloseableMessageStrip>
          {t('components.deals.cashflow.input-params.error.input-parameters-not-in-sync')}
        </CloseableMessageStrip>
      </div>
    )

  const dealCashflowInputParametersTable = () => (
    <DealCashflowInputParametersTable
      cashflowInputParameters={data.cashflowInputParameters}
      isEditMode={isEditMode}
      editData={editData}
      setEditData={onSetEditData}
      valueState={valueState}
      config={config}
      isSaveLoading={isSaveLoading}
    />
  )

  const onRefreshSuccess = () => {
    showToast({
      children: t('components.deals.cashflow.refresh-params.toast.success'),
    })
    //TODO replace refetch logic because it only treats the symptoms and not the underlying problem
    // then() is necessary to bring it to the next render cycle
    refetch().then(() => {
      setEditData({})
    })
    queryClient.invalidateQueries(dealUuid)
  }
  const onRefreshError = async (error) => {
    const { errors: [errorResponse] = [] } = await error.response.json()
    showErrorMessageBox({
      message: t('components.deals.cashflow.refresh-params.error.text'),
      error: errorResponse,
    })
  }

  const refreshInitialSegmentInputParameters = useRefreshInitialSegmentInputParameters({
    onSuccess: onRefreshSuccess,
    onError: onRefreshError,
  })

  const showRefreshConfirmationMessageBox = () =>
    showMessageBox(
      {
        type: MessageBoxTypes.Confirm,
        children: t('components.deals.cashflow.refresh-params.confirmation.text'),
        actions: [
          <Button
            id={'refreshSegmentParametersButtonConfirm'}
            key="button-confirm"
            design={ButtonDesign.Emphasized}
            onClick={() => {
              refreshInitialSegmentInputParameters.mutate({
                dealUuid,
                cashflowScenarioUuid,
              })
            }}
          >
            {t('buttons.confirm')}
          </Button>,
          MessageBoxActions.Cancel,
        ],
      },
      document.body,
    )

  const refreshSegmentsButton = () => (
    <Button
      id={'refreshSegmentParametersButton'}
      className={styles.refreshSegmentParametersButton}
      design={ButtonDesign.Transparent}
      onClick={showRefreshConfirmationMessageBox}
    >
      {t('components.deals.cashflow.refresh-params.title')}
    </Button>
  )

  return (
    <Card
      header={
        <CardHeaderLastEditedText
          title={t('components.deals.cashflow.input-params.title')}
          email={cashflowInputLastUpdatedBy || lastUpdatedBy}
          timestamp={cashflowInputLastUpdatedAt || lastUpdatedAt}
          actions={
            !isLoadingCashflowInputParameters &&
            !isLoadingConfig &&
            !isErrorCashflowInputParameters &&
            !isErrorConfig &&
            !frozen &&
            isPropertyDataInSync &&
            allowedOperations.includes(dealsPermissions.updateCashflowScenario) && (
              <>
                {refreshSegmentsButton()}
                <ToolbarSeparator />
                <ToggleEditMode
                  isEditMode={isEditMode}
                  onToggle={() => setIsEditMode(true)}
                  onSave={handleOnClickSave}
                  onCancel={handleOnClickCancel}
                  isSaveLoading={isSaveLoading}
                />
              </>
            )
          }
        />
      }
    >
      <LoadingStateWrapper
        isError={isErrorCashflowInputParameters || isErrorConfig}
        isLoading={isLoadingCashflowInputParameters || isLoadingConfig}
        renderContent={() => (
          <>
            {messageStripPropertyDataNotInSync()}
            {dealCashflowInputParametersTable()}
          </>
        )}
      />
    </Card>
  )
}

DealCashflowInputParametersCard.propTypes = {
  cashflowScenario: PropTypes.shape({
    dealUuid: PropTypes.string.isRequired,
    cashflowScenarioUuid: PropTypes.string.isRequired,
    lastUpdatedBy: PropTypes.string,
    cashflowInputLastUpdatedBy: PropTypes.string,
    cashflowInputLastUpdatedAt: PropTypes.string,
    lastUpdatedAt: PropTypes.string,
    frozen: PropTypes.bool.isRequired,
  }).isRequired,
}

export default DealCashflowInputParametersCard
