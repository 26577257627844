import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useBusinessPartnerUnitOverview = ({
  businessPartnerId,
  unitId,
  unitHeadId,
  options = {},
}) => {
  const result = useCamelizedResponse(
    useRequest({
      path:
        unitId && unitHeadId
          ? `/businesspartners/${businessPartnerId}/unit-overview?unitHeadBpId=${unitHeadId}&unitId=${unitId}`
          : `/businesspartners/${businessPartnerId}/unit-overview`,
      translated: false,
      keys:
        unitId && unitHeadId
          ? [
              'businesspartners',
              businessPartnerId,
              'unit-overview',
              'unitHeadBpId',
              unitHeadId,
              'unitId',
              unitId,
            ]
          : ['businesspartners', businessPartnerId, 'unit-overview'],
      options: {
        enabled: !!businessPartnerId,
        ...options,
      },
    }),
  )

  return {
    ...result,
    data: result?.data,
  }
}

export default useBusinessPartnerUnitOverview
