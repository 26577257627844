import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CurrentRentRollContext } from 'components/domains/properties/rent-roll/comparison/constants'
import usePropertiesKpis from 'hooks/services/properties/kpis/usePropertiesKpis'

const usePropertyRentRollComparisonKpis = (property) => {
  const selectedCurrency =
    useSelector((state) => state.properties.commonCurrency.selectedCommonCurrency) ?? undefined

  const currentRentRoll = useContext(CurrentRentRollContext)
  const { key_date: currentRentRollKeyDate } = currentRentRoll

  const rentRollToCompare = useSelector(
    (state) => state.properties.compareRentRoll.rentRollToCompare,
  )

  const keyDateToCompare = useMemo(
    () => (!isNil(rentRollToCompare?.index) ? rentRollToCompare?.keyDate : null),
    [rentRollToCompare],
  )

  const {
    isLoading: isLoadingRentRollKpis,
    isError: isErrorRentRollKpis,
    data: propertiesRentRollKpis,
  } = usePropertiesKpis([property.uuid], {
    currency: selectedCurrency,
    keyDates: compact([currentRentRollKeyDate, keyDateToCompare]),
  })

  const findKeyDateKpiByIndex = (index) =>
    propertiesRentRollKpis?.[0]?.keyDateToRentRollKpis?.[index]?.kpis

  return {
    currentKeyDate: currentRentRollKeyDate,
    comparisonKeyDate: keyDateToCompare,
    isLoading: isLoadingRentRollKpis,
    isError: isErrorRentRollKpis,
    currentRentRollKpis: findKeyDateKpiByIndex(0),
    comparisonRentRollKpis: findKeyDateKpiByIndex(1),
  }
}
export default usePropertyRentRollComparisonKpis
