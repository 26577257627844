import { uniq } from 'lodash'
import { DateTime } from 'luxon'
import { useLimitManagementConfigurations } from 'hooks/services/deals/limit-management/useLimitManagementConfigurations'

export const DEFAULT_CURRENCY = 'EUR'

export const useExternalTypeMappingMethods = (dealUuid) => {
  const {
    data: { productTypeMappings = [], propertyTypeMappings = [] } = {},
    isLoading: isLoadingConfigurations,
    isError: isErrorConfigurations,
  } = useLimitManagementConfigurations(dealUuid)

  const hasReservationExpired = (endTimestamp) => DateTime.now().toISO() > endTimestamp

  const getAmountInDefaultCurrency = (amount, amountInEUR) =>
    amount?.currencyCode === DEFAULT_CURRENCY ? amount?.number : amountInEUR

  const getExternalProductType = (productTypeCode, loanTypeCode, dealTypeCode) =>
    productTypeMappings?.find(
      (productTypeMapping) =>
        productTypeMapping.cwpProductTypeCode === productTypeCode &&
        productTypeMapping.cwpLoanTypeCode === loanTypeCode &&
        productTypeMapping.dealTypeCode === dealTypeCode,
    )?.externalProductTypeCode

  const getExternalPropertyType = (propertyTypeCode) =>
    propertyTypeMappings?.find(
      (propertyTypeMapping) => propertyTypeMapping.cwpPropertyTypeCode === propertyTypeCode,
    )?.externalPropertyTypeCode

  const getUniqueExternalPropertyTypes = (propertiesData) =>
    uniq(
      propertiesData
        .filter(({ typeCode }) => !!getExternalPropertyType(typeCode))
        .map(({ typeCode }) => getExternalPropertyType(typeCode)),
    )

  const getUniqueValues = (reservations, field) =>
    uniq(reservations.map((reservation) => reservation[field]))

  const getUniqueValuesFromReservations = (reservations) => ({
    countryCodesOfReservations: getUniqueValues(reservations, 'countryCode'),
    productTypesOfReservations: getUniqueValues(reservations, 'externalProductTypeCode'),
    propertyTypesOfReservations: getUniqueValues(reservations, 'externalMainPropertyTypeCode'),
  })

  const calculateCommitmentPerProduct = (tranches, dealTypeCode) =>
    tranches?.reduce((acc, { productTypeCode, loanTypeCode, ownShareHeadquarter }) => {
      const externalProductTypeCode = getExternalProductType(
        productTypeCode,
        loanTypeCode,
        dealTypeCode,
      )

      if (externalProductTypeCode) {
        !acc[externalProductTypeCode]
          ? (acc[externalProductTypeCode] = ownShareHeadquarter?.commitment)
          : (acc[externalProductTypeCode] += ownShareHeadquarter?.commitment)
      }

      return acc
    }, {})

  const calculateReservationPerProduct = (creditReservations) =>
    creditReservations?.reduce((acc, { amount, amountInEUR, externalProductTypeCode }) => {
      const amountInDefaultCurrency = getAmountInDefaultCurrency(amount, amountInEUR) ?? 0
      !acc[externalProductTypeCode]
        ? (acc[externalProductTypeCode] = amountInDefaultCurrency)
        : (acc[externalProductTypeCode] += amountInDefaultCurrency)

      return acc
    }, {})

  return {
    data: {
      calculateCommitmentPerProduct,
      calculateReservationPerProduct,
      getExternalPropertyType,
      getUniqueExternalPropertyTypes,
      getUniqueValuesFromReservations,
      hasReservationExpired,
    },
    isLoading: isLoadingConfigurations,
    isError: isErrorConfigurations,
  }
}
