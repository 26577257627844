import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  invalidEditStates: [],
}

export const segmentsTableSlice = createSlice({
  name: 'properties/segments-table',
  initialState,
  reducers: {
    addInvalidEditState: (state, { payload }) => {
      if (
        !state.invalidEditStates.find(
          (item) => item.uuid === payload.uuid && item.attribute === payload.attribute,
        )
      ) {
        state.invalidEditStates = [...state.invalidEditStates, { ...payload }]
      }
    },
    removeInvalidEditState: (state, { payload }) => {
      state.invalidEditStates = [
        ...state.invalidEditStates.filter(
          (item) => item.uuid !== payload.uuid || item.attribute !== payload.attribute,
        ),
      ]
    },
    removeUuidsFromInvalidEditStates: (state, { payload }) => {
      state.invalidEditStates = [
        ...state.invalidEditStates.filter((item) => !payload.includes(item.uuid)),
      ]
    },
    resetInvalidEditState: () => ({ ...initialState }),
  },
})

export const {
  addInvalidEditState,
  removeInvalidEditState,
  removeUuidsFromInvalidEditStates,
  resetInvalidEditState,
} = segmentsTableSlice.actions

export default segmentsTableSlice.reducer
