import { Icon, Input } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const BusinessObjectSelectionInput = ({
  name,
  onClick,
  onClear,
  disabled,
  fallbackText = 'fallback',
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.business-object-selection',
  })

  const nameOrFallback = useMemo(() => {
    if (name) {
      return name
    }
    return t(fallbackText)
  }, [name, t, fallbackText])

  const showClearIcon = useMemo(() => !!name, [name])

  const onInput = useCallback(
    ({ target: { value: newInput } }) => {
      if (newInput === '') {
        onClear()
      }
    },
    [onClear],
  )

  const onKeyDown = useCallback((event) => {
    event.stopPropagation()
    event.preventDefault()
  }, [])

  return (
    <Input
      value={nameOrFallback}
      showClearIcon={showClearIcon}
      onInput={onInput}
      onKeyDown={onKeyDown}
      icon={<Icon name="value-help" onClick={onClick} />}
      disabled={disabled}
    />
  )
}

BusinessObjectSelectionInput.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fallbackText: PropTypes.string,
}

export default BusinessObjectSelectionInput
