import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import FurtherDetailsPieChartsCard, {
  chartsWithValuations,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/FurtherDetailsPieChartsCard'
import {
  UsageTypeColorProvider,
  useUsageTypeColors,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/UsageTypeColorContext'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/ui/EmptyCardContent'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'

const FurtherDetailsGranularityPropertyTypeTile = ({ tileId, isPdfView }) => {
  const {
    data: {
      multiPropertyKpisByGranularity,
      multiPropertyValuationsByGranularity,
      isMultiProperty,
      noProperties,
    },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const getPropertyTypeRentRollKpis = (multiPropertyKpis) =>
    multiPropertyKpis?.propertyTypeRentRollKpis ?? []

  const getPropertyTypeValuations = (multiPropertyValuations) =>
    multiPropertyValuations?.granularities?.propertyType ?? []

  const getPropertyTypeName = (rentRollKpi) => rentRollKpi?.propertyTypeName

  if (noProperties) {
    return <NoPropertyAssignedToDealInfo />
  }
  if (!isMultiProperty) {
    return <EmptyCardContent />
  }
  return (
    <FurtherDetailsPieChartsCard
      colorProvider={UsageTypeColorProvider}
      useColors={useUsageTypeColors}
      getRentRollKpis={getPropertyTypeRentRollKpis}
      getValuations={getPropertyTypeValuations}
      getNameFromKpi={getPropertyTypeName}
      granularityValues={{ multiPropertyKpisByGranularity, multiPropertyValuationsByGranularity }}
      chartOptions={chartsWithValuations}
      isPdfView={isPdfView}
    />
  )
}

FurtherDetailsGranularityPropertyTypeTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default FurtherDetailsGranularityPropertyTypeTile
