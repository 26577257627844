import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteCovenantCheckLinkedCashFlowScenario = ({ onSuccess, onError }) => {
  const { delete: deleteLinkedScenario } = useAccessTokenRequest()

  return useMutation(
    async ({ covenantCheckUuid }) => {
      await deleteLinkedScenario({
        path: `/covenants/covenant-checks/${covenantCheckUuid}/cashflow-scenarios-links`,
        options: { enabled: !!covenantCheckUuid },
      })
    },
    { onSuccess, onError },
  )
}

export default useDeleteCovenantCheckLinkedCashFlowScenario
