import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import EventsAndTasksCommentMultipleButton from 'components/domains/business-events-and-tasks/common/comments/EventsAndTasksCommentMultipleButton'
import EventsAndTasksCommentMultipleDialog from 'components/domains/business-events-and-tasks/common/comments/EventsAndTasksCommentMultipleDialog'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import { usePublishEventsComment } from 'hooks/services/business-events-and-tasks/events/comments/usePublishEventsComment'

const useMultipleEventsCommentButtonAndDialog = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.comment-multiple',
  })
  const [isCommentMultipleDialogOpen, setIsCommentMultipleDialogOpen] = useState(false)
  const [commentValue, setCommentValue] = useState('')
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const showMessageBox = useShowMessageBox()

  const selectedEvents = useSelector(
    (state) => state.events.eventOverview.selectedRows.selectedEventIds,
  )
  const selectedEventIds = useMemo(() => Object.keys(selectedEvents), [selectedEvents])

  const { mutate: mutateSaveEventsComments, isLoading } = usePublishEventsComment({
    onSuccess: () => {
      setIsCommentMultipleDialogOpen(false)
      setCommentValue('')
      showToast({ children: t('save.success.events', { numberOfEvents: selectedEventIds.length }) })
      selectedEventIds.forEach((eventId) => {
        queryClient.invalidateQueries(['events', eventId, 'comments'])
      })
    },
    onError: () => {
      showMessageBox({
        type: MessageBoxTypes.Error,
        titleText: t('save.error.title'),
        children: t('save.error.description'),
      })
    },
  })

  const onSaveClicked = useCallback(() => {
    mutateSaveEventsComments({ eventIds: selectedEventIds, comment: commentValue })
  }, [commentValue, mutateSaveEventsComments, selectedEventIds])

  const onCommentMultipleClicked = useCallback(() => {
    setIsCommentMultipleDialogOpen(true)
  }, [])
  const commentMultipleButton = useMemo(
    () => (
      <EventsAndTasksCommentMultipleButton
        key="event-overview-edit-multiple-button"
        selectedEntities={selectedEventIds}
        onClick={onCommentMultipleClicked}
      />
    ),
    [onCommentMultipleClicked, selectedEventIds],
  )

  const commentMultipleDialog = useMemo(
    () =>
      isCommentMultipleDialogOpen && (
        <EventsAndTasksCommentMultipleDialog
          isDialogOpen={isCommentMultipleDialogOpen}
          setIsDialogOpen={setIsCommentMultipleDialogOpen}
          onSaveClicked={onSaveClicked}
          commentValue={commentValue}
          setCommentValue={setCommentValue}
          isSaveLoading={isLoading}
        />
      ),
    [commentValue, isCommentMultipleDialogOpen, isLoading, onSaveClicked],
  )

  return useMemo(
    () => ({ commentMultipleButton, commentMultipleDialog }),
    [commentMultipleButton, commentMultipleDialog],
  )
}

export default useMultipleEventsCommentButtonAndDialog
