import { Panel, Text } from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import { useLayoutEffect, useRef } from 'react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/shared/checksheet/PanelSection.module.css'

const PanelSection = ({
  header,
  children,
  section = false,
  subSection = false,
  subSubSection = false,
  description = '',
  noBorder = false,
  boldHeader = false,
  collapsed = true,
}) => {
  const ref = useRef()
  const { current: { shadowRoot = undefined } = {} } = ref

  useLayoutEffect(() => {
    // dirty, because ui5 forgot to add part=navigator & part=step-content to the webcomponent
    if (shadowRoot && !shadowRoot?.querySelector('.panelSection')) {
      const styleEl = document.createElement('style')
      styleEl.innerHTML = `
      .ui5-panel-content {
          padding: 0 !important;
      }
      .ui5-panel-header {
        border-bottom: none !important;
      }
      .ui5-li-text-wrapper {
        white-space: break-spaces;
      }
      `
      shadowRoot.appendChild(styleEl)
    }
  }, [shadowRoot])

  const sectionsClassNames = [
    `${subSection && !noBorder ? styles.panelSectionBorder : styles.panelNoBorder}`,
    `${subSection ? styles.panelSubSection : ''}`,
    `${subSubSection ? styles.panelSubSubSection : ''}`,
  ]

  return (
    <div>
      <Panel
        ref={ref}
        header={
          <Text className={`${(section || boldHeader) && styles.headerStyle}`}>{header}</Text>
        }
        collapsed={collapsed}
        noAnimation={true}
        className={compact(['panelSection', ...sectionsClassNames]).join(' ')}
      >
        {description && <div className={styles.panelSectionDescription}>{description}</div>}
        {children}
      </Panel>
    </div>
  )
}

PanelSection.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.node,
  children: PropTypes.node,
  noBorder: PropTypes.bool,
  section: PropTypes.bool,
  subSection: PropTypes.bool,
  subSubSection: PropTypes.bool,
  boldHeader: PropTypes.bool,
  collapsed: PropTypes.bool,
}

export default PanelSection
