import {
  IllustratedMessage,
  MessageBoxTypes,
  Button,
  MessageBoxActions,
  ButtonDesign,
  Modals,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useState, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { propertyMonitoringStatus } from 'api/property/monitoring/propertyMonitoringConfiguration'
import { isNotFoundError } from 'api/requests'
import AssignableMarketsFilterBar from 'components/domains/markets/AssignableMarketsFilterBar'
import AssignableMarketsTable from 'components/domains/markets/AssignableMarketsTable'
import Dialog from 'components/ui/dialog/Dialog'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import ErrorScreen from 'components/ui/screens/ErrorScreen'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useAssignPropertyToMarket } from 'hooks/services/markets/useAssignPropertyToMarket'
import useCompatibleMarkets from 'hooks/services/markets/useCompatibleMarkets'
import { PropertyContext } from 'routes/properties/PropertyContext'

const AssignableMarketsDialog = ({ isOpen, setIsOpen, propertyId, monitoringStatus }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.markets.overview.assignable-markets-dialog',
  })
  const { t: tNoPrefix } = useTranslation()

  const initialValues = {
    searchFilter: '',
    marketTypes: [],
  }
  const [filterParams, setFilterParams] = useState(initialValues)

  const queryClient = useQueryClient()
  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()

  const { property } = useContext(PropertyContext)

  const {
    isError,
    isLoading,
    data: compatibleMarketsData,
    error,
    fetchNextPage,
  } = useCompatibleMarkets({
    searchFilter: filterParams.searchFilter,
    marketTypes: filterParams.marketTypes,
    propertyCountry: property.address.country_code,
    propertyType: property.type_code,
    propertyLat: property.geo_location.latitude,
    propertyLng: property.geo_location.longitude,
    propertyId: property.uuid,
  })

  const onSucessAssign = useCallback(() => {
    showToast({ children: t('toast.assign-success') })
    queryClient.invalidateQueries(['markets'])
    queryClient.invalidateQueries(['property-monitoring'])
    queryClient.invalidateQueries(['properties'])
    setIsOpen(false)
  }, [queryClient, setIsOpen, showToast, t])

  const onErrorAssign = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('post-error.description'),
    })
    setIsOpen(false)
  }, [setIsOpen, showMessageBox, t])

  const { mutate: assignPropertyToMarket } = useAssignPropertyToMarket({
    onSuccess: onSucessAssign,
    onError: onErrorAssign,
  })

  const handleConfirmationDialogConfirmation = useCallback(
    (marketId) => () => {
      assignPropertyToMarket({
        propertyId,
        marketId,
      })
    },
    [assignPropertyToMarket, propertyId],
  )

  const handleOnRowClicked = useCallback(
    (marketId) => {
      if (monitoringStatus === propertyMonitoringStatus.activeAutomated) {
        showMessageBox(
          {
            type: MessageBoxTypes.Confirm,
            titleText: t('confirmation.title'),
            children: t('confirmation.message'),
            actions: [
              <Button
                key="button-confirm"
                design={ButtonDesign.Emphasized}
                onClick={handleConfirmationDialogConfirmation(marketId)}
              >
                {tNoPrefix('buttons.confirm')}
              </Button>,
              MessageBoxActions.Cancel,
            ],
          },
          document.body,
        )
        return
      }
      assignPropertyToMarket({
        propertyId,
        marketId,
      })
    },
    [
      assignPropertyToMarket,
      handleConfirmationDialogConfirmation,
      monitoringStatus,
      propertyId,
      showMessageBox,
      t,
      tNoPrefix,
    ],
  )

  const renderCustomErrorScreen = useCallback(() => {
    if (isOpen && error && isNotFoundError(error)) {
      return <IllustratedMessage name="NoData" titleText={t('empty')} />
    }

    return <ErrorScreen title={t('error.title')} description={t('error.description')} />
  }, [error, isOpen, t])

  const handleOnGo = useCallback((filters) => {
    setFilterParams(filters)
  }, [])

  const calculateNoDataText = useMemo(() => {
    if (filterParams.searchFilter !== '' || filterParams.marketTypes.length !== 0) {
      return t('no-filtered-data')
    }
    return t('no-data')
  }, [filterParams, t])

  const renderContent = useCallback(
    () => (
      <AssignableMarketsTable
        markets={compatibleMarketsData}
        onLoadMore={fetchNextPage}
        noDataText={calculateNoDataText}
        handleRowClick={handleOnRowClicked}
      />
    ),
    [calculateNoDataText, compatibleMarketsData, fetchNextPage, handleOnRowClicked],
  )

  const handleAfterClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  return (
    <Dialog open={isOpen} headerText={t('title')} onAfterClose={handleAfterClose}>
      <AssignableMarketsFilterBar
        filterParams={filterParams}
        onGo={handleOnGo}
        propertyType={property.type_code}
      />
      <LoadingStateWrapper
        isError={isError}
        isLoading={isLoading}
        renderContent={renderContent}
        renderCustomErrorScreen={renderCustomErrorScreen}
      />
    </Dialog>
  )
}

AssignableMarketsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  propertyId: PropTypes.string.isRequired,
  monitoringStatus: PropTypes.oneOf(Object.values(propertyMonitoringStatus)),
}

export default AssignableMarketsDialog
