import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperTileListItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListItem'
import { useCustomizableCurrencyFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const BaselTwoSettlementRiskOverviewTile = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.settlement-risk-overview.fields',
  })
  const formatCurrency = useCustomizableCurrencyFormatter()

  const {
    data: { riskFiguresElements },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const { sr, srl } = riskFiguresElements.riskFigures ?? {}

  const getDisplayedValue = (riskFigure) => {
    const formattedValue = formatCurrency(riskFigure?.value, riskFigure?.unit)
    return isEmpty(formattedValue) ? '-' : formattedValue
  }

  return (
    <>
      <DecisionPaperTileListItem
        label={t('settlement-risk-limit')}
        value={getDisplayedValue(srl)}
        isFirstItem
      />
      <DecisionPaperTileListItem label={t('settlement-risk')} value={getDisplayedValue(sr)} />
    </>
  )
}

BaselTwoSettlementRiskOverviewTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}
export default BaselTwoSettlementRiskOverviewTile
