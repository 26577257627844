import { useQueries } from '@tanstack/react-query'
import camelize from 'camelize'
import { useMemo } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useArrayMemo } from 'hooks/services/queryHelper'

/**
 * Retrieve property data for each property uuids array element.
 * Handle multiple API requests for retrieving properties based on their uuids.
 * @param propertyUuidsArrays Array of arrays of property uuids, one hook result for each parent array element
 * @param options Additional query options to pass to the useQueries hook
 * @param shouldBeCamelized Flag to determine if the response data should be transformed to camel case
 * @returns {{isLoading: boolean, isError: boolean, data: *}}
 */
const useMultipleArrayPropertyUuids = ({
  propertyUuidsArrays,
  options = {},
  shouldBeCamelized = false,
}) => {
  const { post } = useAccessTokenRequest()

  const queries = propertyUuidsArrays.map((propertyUuids) => ({
    enabled: propertyUuids?.length > 0,
    queryKey: ['properties', 'uuids', ...propertyUuids],
    refetchOnWindowFocus: false,
    queryFn: () =>
      post({
        path: `/properties/uuids`,
        body: { property_uuids: propertyUuids },
      }),
    retry: false,
    ...options,
  }))

  const responses = useQueries({ queries })
  const isAnyRequestLoading = useMemo(
    () => responses.some(({ isLoading: isPropertyLoading }) => isPropertyLoading),
    [responses],
  )
  const isAnyRequestError = responses.some(({ isError: isPropertyError }) => isPropertyError)

  const propertiesArrays = useArrayMemo(
    responses.map(({ data, isLoading, isError, isFetching }) => ({
      ...(shouldBeCamelized ? camelize(data) : data),
      isLoading,
      isError,
      isFetching,
    })),
  )

  return useMemo(
    () => ({ data: propertiesArrays, isLoading: isAnyRequestLoading, isError: isAnyRequestError }),
    [isAnyRequestError, isAnyRequestLoading, propertiesArrays],
  )
}

export default useMultipleArrayPropertyUuids
