import { useContext } from 'react'
import useFinancing from 'hooks/services/deals/financing/useFinancing'
import { useLimitManagementConfigurations } from 'hooks/services/deals/limit-management/useLimitManagementConfigurations'
import useDeal from 'hooks/services/deals/useDeal'
import { DealContext } from 'routes/deals/DealContext'

const PRODUCT_TYPE_CODE = 'externalProductTypeCode'

export const useExternalTypeCodes = () => {
  const {
    deal: { dealUuid: dealUuid },
  } = useContext(DealContext)
  const {
    data: { propertyTypeMappings = [], productTypeMappings = [] } = {},
    isLoading: isLoadingLimitConfigurations,
    isError: isErrorLimitConfigurations,
  } = useLimitManagementConfigurations()
  const {
    data: { dealTypeCode } = {},
    isLoading: isLoadingDeal,
    isError: isErrorDeal,
  } = useDeal(dealUuid)
  const {
    data: { tranches = [] } = {},
    isLoading: isLoadingTranches,
    isError: isErrorTranches,
  } = useFinancing(dealUuid)

  const isProductMapping = (typeCode) => typeCode === PRODUCT_TYPE_CODE
  const isApplicableProductMapping = (externalTypeCodeEntity) =>
    !!tranches?.find(
      ({ loanTypeCode, productTypeCode }) =>
        externalTypeCodeEntity.cwpLoanTypeCode === loanTypeCode &&
        externalTypeCodeEntity.cwpProductTypeCode === productTypeCode &&
        externalTypeCodeEntity.dealTypeCode === dealTypeCode,
    )

  const isDeprecated = (externalTypeCodeEntity) => externalTypeCodeEntity.deprecated
  const isRelevantMapping = (externalTypeCodeEntity, typeCode) =>
    isProductMapping(typeCode) ? isApplicableProductMapping(externalTypeCodeEntity) : true

  const reduceExternalTypeCodes = (externalTypeCodes, typeCode) =>
    externalTypeCodes.reduce((acc, externalTypeCodeEntity) => {
      if (
        isRelevantMapping(externalTypeCodeEntity, typeCode) &&
        !isDeprecated(externalTypeCodeEntity)
      )
        !acc.find(({ key }) => key === externalTypeCodeEntity[typeCode]) &&
          acc.push({ key: externalTypeCodeEntity[typeCode] })

      return acc
    }, [])

  return {
    data: {
      propertyTypes: reduceExternalTypeCodes(propertyTypeMappings, 'externalPropertyTypeCode'),
      productTypes: reduceExternalTypeCodes(productTypeMappings, 'externalProductTypeCode'),
    },
    isLoading: isLoadingLimitConfigurations || isLoadingDeal || isLoadingTranches,
    isError: isErrorLimitConfigurations || isErrorDeal || isErrorTranches,
  }
}
