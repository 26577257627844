import {
  Button,
  MessageBox,
  MessageBoxActions,
  MessageBoxTypes,
} from '@fioneer/ui5-webcomponents-react/dist'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'routes/business-partners/OpenPegaButton.module.css'

/**
 * @typedef Props
 * @property {string} to The link to PEGA
 */
/**
 * @type {React.FC<Props>}
 */
const OpenInPegaButton = ({ to }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.business-partner.general-information.pega',
  })
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const handleClick = () => {
    setIsConfirmOpen(true)
  }

  const handleMessageBoxClose = (e) => {
    setIsConfirmOpen(false)
    if (e.detail.action === MessageBoxActions.OK) {
      window.open(to, '_blank')
    }
  }

  return (
    <>
      <Button onClick={handleClick} className={styles.button}>
        {t('button')}
      </Button>
      {createPortal(
        <MessageBox
          open={isConfirmOpen}
          onClose={handleMessageBoxClose}
          type={MessageBoxTypes.Information}
          actions={[MessageBoxActions.OK, MessageBoxActions.Close]}
        >
          {t('confirmation-message')}
        </MessageBox>,
        document.body,
      )}
    </>
  )
}

OpenInPegaButton.propTypes = {
  to: PropTypes.string,
}

export default OpenInPegaButton
