import { FlexBox, FlexBoxDirection, ResponsiveGridLayout } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import {
  decisionStageStatus as possibleDecisionStageStatus,
  decisionStageVoterStatus as possibleDecisionStageVoterStatus,
} from 'api/decision-process/decisionProcessApi'
import { decisionProcessMinutesDecisionStatus } from 'api/decision-process/decisionProcessMinutesApi'
import useDecisionStageName from 'components/domains/business-events-and-tasks/common/useDecisionStageName'
import styles from 'components/domains/business-events-and-tasks/decision-stage/conditions/approval/DecisionStageConditionsApprovalContent.module.css'
import DecisionStageMinutesCard from 'components/domains/business-events-and-tasks/decision-stage/minutes/DecisionStageMinutesCard'
import DecisionStageMinutesApprovalInformationCard from 'components/domains/business-events-and-tasks/decision-stage/minutes/approval/DecisionStageMinutesApprovalInformationCard'
const numberOfColumns = 2

const DecisionStageMinutesApprovalContent = ({
  decisionStage,
  decisionStageMinutes,
  decisionStageId,
  voterStatusCode,
}) => {
  const decisionStageName = useDecisionStageName({
    name: decisionStage.info.name,
    decisionStageType: decisionStage.info.decisionType,
  })

  const {
    info: { decisionStatus, minutes, lastUpdatedAt, lastUpdatedBy },
  } = decisionStageMinutes

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.pageWrapper}>
      <ResponsiveGridLayout
        columnsXL={numberOfColumns}
        columnsL={numberOfColumns}
        columnsM={numberOfColumns}
        columnsS={numberOfColumns}
        columnGap="1rem"
        rowGap="1rem"
      >
        <DecisionStageMinutesApprovalInformationCard
          decisionPaperVersion={decisionStage.decisionPaper?.version}
          decisionStageName={decisionStageName}
          decisionStageStatus={decisionStage.info.decisionStatus}
          decisionStageDecisionDate={decisionStage.info.decisionDate}
          decisionStageMinutesStatus={decisionStatus}
          voterStatus={voterStatusCode}
        />
      </ResponsiveGridLayout>
      <DecisionStageMinutesCard
        decisionStageId={decisionStageId}
        minutes={minutes}
        lastUpdatedAt={lastUpdatedAt}
        lastUpdatedBy={lastUpdatedBy}
        decisionStatus={decisionStatus}
      />
    </FlexBox>
  )
}

DecisionStageMinutesApprovalContent.propTypes = {
  decisionStage: PropTypes.shape({
    info: PropTypes.shape({
      name: PropTypes.string.isRequired,
      decisionType: PropTypes.string.isRequired,
      decisionStatus: PropTypes.oneOf(Object.values(possibleDecisionStageStatus)).isRequired,
      decisionDate: PropTypes.string,
    }).isRequired,
    decisionPaper: PropTypes.shape({
      version: PropTypes.string,
    }),
  }).isRequired,
  decisionStageMinutes: PropTypes.shape({
    info: PropTypes.shape({
      decisionStatus: PropTypes.oneOf(Object.values(decisionProcessMinutesDecisionStatus))
        .isRequired,
      decisionDate: PropTypes.string,
      minutes: PropTypes.string.isRequired,
      lastUpdatedAt: PropTypes.string,
      lastUpdatedBy: PropTypes.string,
    }).isRequired,
  }).isRequired,
  voterStatusCode: PropTypes.oneOf(Object.values(possibleDecisionStageVoterStatus)).isRequired,
  decisionStageId: PropTypes.string.isRequired,
}

export default DecisionStageMinutesApprovalContent
