import {
  Option,
  Select,
  SegmentedButton,
  SegmentedButtonItem,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionProcessMinutesDecisionStatus } from 'api/decision-process/decisionProcessMinutesApi'
import styles from 'components/domains/business-events-and-tasks/events/decision-stage/UserDecisionStageVotesCard.module.css'
import UserDecisionStageVotesCardHeader from 'components/domains/business-events-and-tasks/events/decision-stage/UserDecisionStageVotesCardHeader'
import UserDecisionStageVotesCardTable from 'components/domains/business-events-and-tasks/events/decision-stage/UserDecisionStageVotesCardTable'
import Card from 'components/ui/card/Card'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useAllDecisionStageVoters } from 'hooks/services/business-events-and-tasks/decision-papers/useAllDecisionStageVoters'

const limit = 5
const statusFilterOptions = {
  inProgress: [decisionProcessMinutesDecisionStatus.inProgress],
  inProgressAndApproved: [
    decisionProcessMinutesDecisionStatus.inProgress,
    decisionProcessMinutesDecisionStatus.approved,
  ],
}
const views = {
  condition: 'condition',
  minute: 'minute',
}

const UserDecisionStageVotesCard = ({ userId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.homepage.my-approvals',
  })
  const [statusFilter, setStatusFilter] = useState(statusFilterOptions.inProgress)
  const [tableView, setTableView] = useState(views.condition)
  const filter = { status: statusFilter, userId }

  const {
    isLoading: isLoadingMinutes,
    isError: isErrorMinutes,
    data: minutesData,
    fetchNextPage: fetchNextPageMinutes,
  } = useAllDecisionStageVoters('minutes', filter, { limit })

  const {
    isLoading: isLoadingConditions,
    isError: isErrorConditions,
    data: conditionsData,
    fetchNextPage: fetchNextPageConditions,
  } = useAllDecisionStageVoters('conditions', filter, { limit })

  const selectedFilter = !statusFilter[0].includes(decisionProcessMinutesDecisionStatus.approved)
  const selectTasksOptions = useMemo(
    () => [
      <Option
        key={'status-dropdown-filter-default'}
        selected={selectedFilter}
        value={statusFilterOptions.inProgress}
      >
        {t('status-filter.in-progress')}
      </Option>,
      <Option
        key={'status-dropdown-filter'}
        selected={!selectedFilter}
        value={statusFilterOptions.inProgressAndApproved}
      >
        {t('status-filter.in-progress-plus-approved')}
      </Option>,
    ],
    [selectedFilter, t],
  )

  const handleFilterChange = useCallback((event) => {
    setStatusFilter([event.detail.selectedOption.value])
  }, [])

  const statusSelect = useMemo(
    () => (
      <Select
        onChange={handleFilterChange}
        className={styles.select}
        key="homepage-approvals-status-select"
      >
        {selectTasksOptions}
      </Select>
    ),
    [handleFilterChange, selectTasksOptions],
  )

  const handleConditionViewSwitch = useCallback(() => {
    setTableView(views.condition)
  }, [])

  const handleMinuteViewSwitch = useCallback(() => {
    setTableView(views.minute)
  }, [])

  const viewSwitchSegmentButton = useMemo(
    () => (
      <SegmentedButton key="homepage-my-approvals-segment-switch-button">
        <SegmentedButtonItem
          pressed={tableView === views.condition}
          onClick={handleConditionViewSwitch}
        >
          {t('buttons.conditions', { numberOfConditions: conditionsData?.total })}
        </SegmentedButtonItem>
        <SegmentedButtonItem
          pressed={tableView === views.minute}
          disabled={isLoadingMinutes || isErrorMinutes}
          onClick={handleMinuteViewSwitch}
        >
          {t('buttons.minutes', { numberOfMinutes: minutesData?.total })}
        </SegmentedButtonItem>
      </SegmentedButton>
    ),
    [
      conditionsData?.total,
      handleConditionViewSwitch,
      handleMinuteViewSwitch,
      isErrorMinutes,
      isLoadingMinutes,
      minutesData?.total,
      t,
      tableView,
    ],
  )

  const approvalsCardHeader = useMemo(
    () => <UserDecisionStageVotesCardHeader titleText={t('title')} />,
    [t],
  )

  const onLoadMore = useCallback(() => {
    if (tableView === views.condition) {
      return fetchNextPageConditions()
    }
    fetchNextPageMinutes()
  }, [fetchNextPageConditions, fetchNextPageMinutes, tableView])

  const renderContent = useCallback(
    () => (
      <UserDecisionStageVotesCardTable
        voterData={tableView === views.condition ? conditionsData : minutesData}
        additionalActions={statusSelect}
        additionalActionsNextToTitle={viewSwitchSegmentButton}
        onLoadMore={onLoadMore}
        noDataText={t('no-data')}
        shownColumns={[
          'approvals',
          'entityRef',
          'event',
          'decisionStage',
          'approvalDate',
          'approvalStatus',
        ]}
        tableTitle={t('title')}
        approvalView={tableView}
      />
    ),
    [tableView, conditionsData, minutesData, statusSelect, viewSwitchSegmentButton, onLoadMore, t],
  )

  return (
    <Card header={approvalsCardHeader}>
      <LoadingStateWrapper
        isLoading={isLoadingConditions || isLoadingMinutes}
        isError={isErrorConditions || isErrorMinutes}
        renderContent={renderContent}
      />
    </Card>
  )
}

UserDecisionStageVotesCard.propTypes = {
  userId: PropTypes.string.isRequired,
}

export default UserDecisionStageVotesCard
