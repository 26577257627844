import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const RETRIES = 3

const useRiskConformity = (dealUuid) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/risk-conformity`,
      useCache: true,
      keys: ['deals', dealUuid, 'risk-conformity'],
      options: {
        enabled: !!dealUuid,

        retry: (failureCount, error) => {
          if (isNotFoundError(error) || isMissingPermissionError(error)) {
            return false
          }
          if (failureCount < RETRIES) {
            return true
          }
          return false
        },
      },
    }),
  )

export default useRiskConformity
