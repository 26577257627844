import { Link, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ForbearanceAndUtpQuestionareContext } from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/ForbearanceAndUtpQuestionareTile'
import style from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/ForbearanceAndUtpSection.module.css'
import {
  BooleanQuestion,
  ColorQuestion,
  definitionOfDefaultLink,
  internalInstructionForbearanceLink,
  internalInstructionLink,
  marketReportLink,
  pscCreditAuthorities,
  QuestionKeys,
  refCreditAuthorities,
  ShrinkValue,
  triggerEventOverviewLink,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/forbearanceAndUtpQuestionareSchema'
import { getResultBanner } from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/forbearanceQuestionareResults'
import CommentQuestion from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/shared/checksheet/CommentQuestion'
import DropDownQuestion from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/shared/checksheet/DropDownQuestion'
import NonEditableQuestion from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/shared/checksheet/NonEditableQuestion'
import PanelSection from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/shared/checksheet/PanelSection'

const ForbearanceAndUtpSection = ({
  isEditMode,
  allSelectableOptions,
  onChange,
  currentAnswers,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.forbearance-and-utp-questionare.tile.forbearance-and-utp-check',
  })
  const { t: tQuestionare } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.forbearance-and-utp-questionare',
  })
  const { t: tIndicatorForFinancialDifficulties } = useTranslation('decisionPaper', {
    keyPrefix:
      'decision-paper.forbearance-and-utp-questionare.options.indicator-for-financial-difficulties',
  })
  const { t: tIndicatorForUtp } = useTranslation('decisionPaper', {
    keyPrefix: 'decision-paper.forbearance-and-utp-questionare.options.indicator-for-utp',
  })

  const { questionareState } = useContext(ForbearanceAndUtpQuestionareContext)
  const { shouldBeCollapsed } = questionareState

  const { boolOptions, colorOptions, irrefutableForbearanceMeasureOptions, finalResultOptions } =
    allSelectableOptions

  const onValuesChange = (question, newValue) => {
    const changedAnswersCopy = { ...currentAnswers }

    changedAnswersCopy[question] = {
      ...currentAnswers[question],
      value: newValue || null,
      isRequired: currentAnswers[question]?.isRequired,
    }

    onChange(
      JSON.stringify({
        answers: changedAnswersCopy,
      }),
    )
  }
  const getValue = (target, options) => options?.find((o) => o?.value === target)

  return (
    <>
      <PanelSection
        header={t('Q2.header')}
        section
        description={
          <>
            <Text renderWhitespace={true}>{t('Q2.description')}</Text>
            <div className={style.linkPadding}>
              <Trans
                i18nKey="decision-paper.forbearance-and-utp-questionare.tile.forbearance-and-utp-check.Q2.more-information"
                components={[
                  <Link
                    key="internal-instruction-forbearance-link"
                    href={internalInstructionForbearanceLink}
                  />,
                ]}
              />
            </div>
          </>
        }
        collapsed={!shouldBeCollapsed}
      >
        <PanelSection header={t('Q21.header')} subSection boldHeader>
          <PanelSection header={t('Q211.header')} subSubSection>
            <DropDownQuestion
              questionKey={QuestionKeys.Q211}
              selectedValue={getValue(currentAnswers[QuestionKeys.Q211]?.value, boolOptions)}
              label={t('Q211.question')}
              isEditMode={isEditMode}
              selectOptions={boolOptions}
              onChange={onValuesChange}
            />
            <NonEditableQuestion
              label={tQuestionare('labels.result')}
              value={getResultBanner({
                rules: [currentAnswers[QuestionKeys.Q211]?.value === BooleanQuestion.YES],
                values: [currentAnswers[QuestionKeys.Q211]?.value],
                greenBanner: tIndicatorForFinancialDifficulties('no-given'),
                redBanner: tIndicatorForFinancialDifficulties('given'),
              })}
              isEditMode={isEditMode}
            />
            <CommentQuestion
              questionKey={QuestionKeys.Q211_comment}
              value={currentAnswers[QuestionKeys.Q211_comment]?.value}
              label={tQuestionare('labels.comment')}
              isEditMode={isEditMode}
              onChange={onValuesChange}
            />
          </PanelSection>
          <PanelSection header={t('Q212.header')} subSubSection>
            <DropDownQuestion
              questionKey={QuestionKeys.Q212}
              selectedValue={getValue(currentAnswers[QuestionKeys.Q212]?.value, boolOptions)}
              label={t('Q212.question')}
              isEditMode={isEditMode}
              selectOptions={boolOptions}
              onChange={onValuesChange}
            />
            <NonEditableQuestion
              label={
                <Trans
                  i18nKey="decision-paper.forbearance-and-utp-questionare.tile.forbearance-and-utp-check.Q212.more-information"
                  components={[
                    <Link key="trigger-event-overview-link" href={triggerEventOverviewLink} />,
                    <Link
                      key="internal-instruction-forbearance-link"
                      href={internalInstructionLink}
                    />,
                  ]}
                />
              }
              isEditMode={isEditMode}
            />
            <NonEditableQuestion
              label={tQuestionare('labels.result')}
              value={getResultBanner({
                rules: [currentAnswers[QuestionKeys.Q212]?.value === BooleanQuestion.YES],
                values: [currentAnswers[QuestionKeys.Q212]?.value],
                greenBanner: tIndicatorForFinancialDifficulties('no-given'),
                redBanner: tIndicatorForFinancialDifficulties('given'),
              })}
              isEditMode={isEditMode}
            />
            <CommentQuestion
              questionKey={QuestionKeys.Q212_comment}
              value={currentAnswers[QuestionKeys.Q212_comment]?.value}
              label={tQuestionare('labels.comment')}
              isEditMode={isEditMode}
              onChange={onValuesChange}
            />
          </PanelSection>
          <PanelSection header={t('Q213.header')} subSubSection>
            <DropDownQuestion
              questionKey={QuestionKeys.Q213}
              selectedValue={getValue(currentAnswers[QuestionKeys.Q213]?.value, boolOptions)}
              label={
                <>
                  <div>{t('Q213.question')}</div>
                  <div className={style.optionsPadding}>{t('Q213.options')}</div>
                </>
              }
              isEditMode={isEditMode}
              selectOptions={boolOptions}
              onChange={onValuesChange}
            />
            <NonEditableQuestion
              label={tQuestionare('labels.result')}
              value={getResultBanner({
                rules: [currentAnswers[QuestionKeys.Q213]?.value === BooleanQuestion.YES],
                values: [currentAnswers[QuestionKeys.Q213]?.value],
                greenBanner: tIndicatorForFinancialDifficulties('no-given'),
                redBanner: tIndicatorForFinancialDifficulties('given'),
              })}
              isEditMode={isEditMode}
            />
            <CommentQuestion
              questionKey={QuestionKeys.Q213_comment}
              value={currentAnswers[QuestionKeys.Q213_comment]?.value}
              label={tQuestionare('labels.comment')}
              isEditMode={isEditMode}
              onChange={onValuesChange}
            />
          </PanelSection>
          <PanelSection header={t('Q214.header')} subSubSection>
            <DropDownQuestion
              questionKey={QuestionKeys.Q214}
              selectedValue={getValue(currentAnswers[QuestionKeys.Q214]?.value, boolOptions)}
              label={t('Q214.question')}
              isEditMode={isEditMode}
              selectOptions={boolOptions}
              onChange={onValuesChange}
            />
            <NonEditableQuestion
              label={
                <Trans
                  i18nKey="decision-paper.forbearance-and-utp-questionare.tile.forbearance-and-utp-check.Q214.more-information"
                  components={[
                    <Link key="trigger-event-overview-link" href={definitionOfDefaultLink} />,
                    <Link
                      key="internal-instruction-forbearance-link"
                      href={triggerEventOverviewLink}
                    />,
                  ]}
                />
              }
              isEditMode={isEditMode}
            />
            <NonEditableQuestion
              label={tQuestionare('labels.result')}
              value={getResultBanner({
                rules: [currentAnswers[QuestionKeys.Q214]?.value === BooleanQuestion.YES],
                values: [currentAnswers[QuestionKeys.Q214]?.value],
                greenBanner: tIndicatorForFinancialDifficulties('no-given'),
                redBanner: tIndicatorForFinancialDifficulties('given'),
              })}
              isEditMode={isEditMode}
            />
            <CommentQuestion
              questionKey={QuestionKeys.Q214_comment}
              value={currentAnswers[QuestionKeys.Q214_comment]?.value}
              label={tQuestionare('labels.comment')}
              isEditMode={isEditMode}
              onChange={onValuesChange}
            />
          </PanelSection>
          <PanelSection header={t('Q215.header')} subSubSection>
            <DropDownQuestion
              questionKey={QuestionKeys.Q215}
              selectedValue={getValue(currentAnswers[QuestionKeys.Q215]?.value, colorOptions)}
              label={t('Q215.question')}
              isEditMode={isEditMode}
              selectOptions={colorOptions}
              onChange={onValuesChange}
            />
            <NonEditableQuestion
              label={
                <Trans
                  i18nKey="decision-paper.forbearance-and-utp-questionare.tile.forbearance-and-utp-check.Q215.more-information"
                  components={[<Link key="trigger-event-overview-link" href={marketReportLink} />]}
                />
              }
              isEditMode={isEditMode}
            />
            <NonEditableQuestion
              label={tQuestionare('labels.result')}
              value={getResultBanner({
                rules: [
                  currentAnswers[QuestionKeys.Q215]?.value === ColorQuestion.YELLOW,
                  currentAnswers[QuestionKeys.Q215]?.value === ColorQuestion.RED,
                ],
                values: [currentAnswers[QuestionKeys.Q215]?.value],
                greenBanner: tIndicatorForFinancialDifficulties('no-given'),
                redBanner: tIndicatorForFinancialDifficulties('given'),
              })}
              isEditMode={isEditMode}
            />
            <CommentQuestion
              questionKey={QuestionKeys.Q215_comment}
              value={currentAnswers[QuestionKeys.Q215_comment]?.value}
              label={tQuestionare('labels.comment')}
              isEditMode={isEditMode}
              onChange={onValuesChange}
            />
          </PanelSection>
        </PanelSection>

        <PanelSection
          header={t('Q22.header')}
          subSection
          boldHeader
          description={
            <>
              <Text renderWhitespace={true}>{t('Q22.description')}</Text>
              <div className={style.linkPadding}>
                <Trans
                  i18nKey="decision-paper.forbearance-and-utp-questionare.tile.forbearance-and-utp-check.Q22.more-information"
                  components={[
                    <Link key="ref-credit-authorities" href={refCreditAuthorities} />,
                    <Link key="psc-credit-authorities" href={pscCreditAuthorities} />,
                  ]}
                />
              </div>
            </>
          }
        >
          <PanelSection header={t('Q221.header')} subSubSection>
            <DropDownQuestion
              questionKey={QuestionKeys.Q221}
              selectedValue={getValue(
                currentAnswers[QuestionKeys.Q221]?.value,
                irrefutableForbearanceMeasureOptions,
              )}
              label={t('Q221.question')}
              isEditMode={isEditMode}
              selectOptions={irrefutableForbearanceMeasureOptions}
              onChange={onValuesChange}
              highWidth
              shrinkValue={ShrinkValue.LARGE}
            />
            <NonEditableQuestion
              label={
                <Trans
                  i18nKey="decision-paper.forbearance-and-utp-questionare.tile.forbearance-and-utp-check.Q215.more-information"
                  components={[<Link key="trigger-event-overview-link" href={marketReportLink} />]}
                />
              }
              isEditMode={isEditMode}
            />
            <NonEditableQuestion
              label={tQuestionare('labels.result')}
              value={getResultBanner({
                rules: [currentAnswers[QuestionKeys.Q221]?.value !== BooleanQuestion.NO],
                values: [currentAnswers[QuestionKeys.Q221]?.value],
                greenBanner: tIndicatorForFinancialDifficulties('no-given'),
                redBanner: tIndicatorForFinancialDifficulties('given'),
              })}
              isEditMode={isEditMode}
            />
            <CommentQuestion
              questionKey={QuestionKeys.Q221_comment}
              value={currentAnswers[QuestionKeys.Q221_comment]?.value}
              label={tQuestionare('labels.comment')}
              isEditMode={isEditMode}
              onChange={onValuesChange}
            />
          </PanelSection>
          <PanelSection header={t('Q222.header')} subSubSection>
            <DropDownQuestion
              questionKey={QuestionKeys.Q222}
              selectedValue={getValue(currentAnswers[QuestionKeys.Q222]?.value, boolOptions)}
              label={t('Q222.question')}
              isEditMode={isEditMode}
              selectOptions={boolOptions}
              onChange={onValuesChange}
            />
            <NonEditableQuestion
              label={tQuestionare('labels.result')}
              value={getResultBanner({
                rules: [currentAnswers[QuestionKeys.Q222]?.value === BooleanQuestion.YES],
                values: [currentAnswers[QuestionKeys.Q222]?.value],
                greenBanner: tIndicatorForFinancialDifficulties('no-given'),
                redBanner: tIndicatorForFinancialDifficulties('given'),
              })}
              isEditMode={isEditMode}
            />
            <CommentQuestion
              questionKey={QuestionKeys.Q222_comment}
              value={currentAnswers[QuestionKeys.Q222_comment]?.value}
              label={tQuestionare('labels.comment')}
              isEditMode={isEditMode}
              onChange={onValuesChange}
            />
          </PanelSection>
          <PanelSection header={t('Q223.header')} subSubSection>
            <DropDownQuestion
              questionKey={QuestionKeys.Q223}
              selectedValue={getValue(currentAnswers[QuestionKeys.Q223]?.value, boolOptions)}
              label={t('Q223.question')}
              isEditMode={isEditMode}
              selectOptions={boolOptions}
              onChange={onValuesChange}
            />
            <NonEditableQuestion
              label={
                <Trans
                  i18nKey="decision-paper.forbearance-and-utp-questionare.tile.forbearance-and-utp-check.Q223.more-information"
                  components={[
                    <Link
                      key="trigger-event-overview-link"
                      href={internalInstructionForbearanceLink}
                    />,
                  ]}
                />
              }
              isEditMode={isEditMode}
            />
            <NonEditableQuestion
              label={tQuestionare('labels.result')}
              value={getResultBanner({
                rules: [currentAnswers[QuestionKeys.Q223]?.value === BooleanQuestion.NO],
                values: [currentAnswers[QuestionKeys.Q223]?.value],
                greenBanner: tIndicatorForUtp('not-applicable'),
                redBanner: tIndicatorForUtp('indication'),
              })}
              isEditMode={isEditMode}
            />
            <CommentQuestion
              questionKey={QuestionKeys.Q223_comment}
              value={currentAnswers[QuestionKeys.Q223_comment]?.value}
              label={tQuestionare('labels.comment')}
              isEditMode={isEditMode}
              onChange={onValuesChange}
            />
          </PanelSection>
          <PanelSection header={t('Q224.header')} subSubSection>
            <NonEditableQuestion label={t('Q224.question')} isEditMode={isEditMode} />
            <CommentQuestion
              isRequired={currentAnswers[QuestionKeys.Q224_comment]?.isRequired}
              questionKey={QuestionKeys.Q224_comment}
              value={currentAnswers[QuestionKeys.Q224_comment]?.value}
              label={tQuestionare('labels.comment')}
              isEditMode={isEditMode}
              onChange={onValuesChange}
            />
          </PanelSection>
        </PanelSection>

        <PanelSection header={t('Q23.header')} subSection boldHeader noBorder>
          <PanelSection header={t('Q231.header')} subSubSection>
            <DropDownQuestion
              questionKey={QuestionKeys.Q231}
              selectedValue={getValue(currentAnswers[QuestionKeys.Q231]?.value, finalResultOptions)}
              label={t('Q231.question')}
              isEditMode={isEditMode}
              selectOptions={finalResultOptions}
              onChange={onValuesChange}
            />
            <CommentQuestion
              isRequired={currentAnswers[QuestionKeys.Q231_comment]?.isRequired}
              questionKey={QuestionKeys.Q231_comment}
              value={currentAnswers[QuestionKeys.Q231_comment]?.value}
              label={tQuestionare('labels.comment')}
              isEditMode={isEditMode}
              onChange={onValuesChange}
            />
          </PanelSection>
        </PanelSection>
      </PanelSection>
    </>
  )
}

ForbearanceAndUtpSection.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  currentAnswers: PropTypes.object.isRequired,
  allSelectableOptions: PropTypes.shape({
    boolOptions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
        displayValue: PropTypes.node,
      }),
    ),
    colorOptions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
        displayValue: PropTypes.node,
      }),
    ),
    irrefutableForbearanceMeasureOptions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
        displayValue: PropTypes.node,
      }),
    ),
    finalResultOptions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
        displayValue: PropTypes.node,
      }),
    ),
  }).isRequired,
}

export default ForbearanceAndUtpSection
