import { Button, ButtonDesign, MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import MessageBox from 'components/ui/message-box/MessageBox'
import 'components/ui/dialog/ConfirmationDialog.css'
import '@ui5/webcomponents-icons/dist/AllIcons.js'

const propTypes = {
  id: PropTypes.string,
  handleConfirmation: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  confirmationMessage: PropTypes.string.isRequired,
  confirmationTitle: PropTypes.string,
  confirmButtonText: PropTypes.string,
  messageBoxType: PropTypes.oneOf(Object.values(MessageBoxTypes)),
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const ConfirmationDialog = ({
  id,
  handleConfirmation,
  handleCancel,
  isOpen,
  confirmationMessage,
  confirmationTitle,
  confirmButtonText,
  messageBoxType = MessageBoxTypes.Confirm,
  ...props
}) => {
  const { t } = useTranslation()
  if (!confirmButtonText) confirmButtonText = t('buttons.save')
  if (!confirmationTitle) confirmationTitle = t('components.confirmation-dialog.title')

  return createPortal(
    <MessageBox
      id={id}
      titleText={confirmationTitle}
      type={messageBoxType}
      actions={[
        <Button
          id="confirmation-dialog-button-confirm"
          design={ButtonDesign.Emphasized}
          onClick={handleConfirmation}
          data-action="confirm"
          key="confirm"
        >
          {confirmButtonText}
        </Button>,
        <Button
          id="confirmation-dialog-button-cancel"
          design={ButtonDesign.Transparent}
          onClick={handleCancel}
          key="cancel"
          data-action="cancel"
          style={{ marginRight: '16px' }}
        >
          {t('buttons.cancel')}
        </Button>,
      ]}
      onClose={handleCancel}
      open={isOpen}
      style={{ width: '320px' }}
      {...props}
    >
      {confirmationMessage}
    </MessageBox>,
    document.body,
  )
}

ConfirmationDialog.propTypes = propTypes

export default ConfirmationDialog
