import {
  FlexBox,
  FlexBoxDirection,
  NumericSideIndicator,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { convertScreamingSnakeCaseToTitle } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/helper/convertScreamingSnakeCaseToTitle'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-history/PropertyValuationResultsHistoryChartHeader.module.css'
import {
  valuationClassificationAbbreviationsTranslated,
  valuationClassificationTranslated,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/domains/property/valuation/valuationRequests'
import {
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const EMPTY_STRING = '-'

const PropertyValuationsChartHeaderItem = ({ code, valuationData = {}, isLeftAligned = true }) => {
  const { t: tNoPrefix } = useTranslation('decisionPaper')
  const formatCurrency = useCustomizableCurrencyFormatter({ notation: 'compact' })
  const formatCompactNumber = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: 'compact',
  })
  const { format: formatDate } = useShortDateFormatter()

  const getValuationCodeTranslation = ({ isAbbreviation = false }) => {
    const translationKey = isAbbreviation
      ? valuationClassificationAbbreviationsTranslated?.[code]
      : valuationClassificationTranslated?.[code]
    return translationKey ? tNoPrefix(translationKey) : convertScreamingSnakeCaseToTitle(code)
  }

  if (isLeftAligned) {
    return (
      <FlexBox direction={FlexBoxDirection.Column}>
        <Title level={TitleLevel.H6} className={styles.titleBottomSpacing}>
          {code
            ? `${getValuationCodeTranslation({
                isAbbreviation: false,
              })} (${getValuationCodeTranslation({
                isAbbreviation: true,
              })}) | ${valuationData?.currency ? valuationData.currency : EMPTY_STRING}`
            : ''}
        </Title>
        <Title level={TitleLevel.H1}>
          {!isNil(valuationData?.number) ? formatCompactNumber(valuationData.number) : EMPTY_STRING}
        </Title>
        <Title level={TitleLevel.H6}>
          {valuationData?.keyDate ? formatDate(valuationData.keyDate) : EMPTY_STRING}
        </Title>
      </FlexBox>
    )
  }
  return (
    <NumericSideIndicator
      titleText={getValuationCodeTranslation({ isAbbreviation: true })}
      number={
        !isNil(valuationData?.number)
          ? formatCurrency(valuationData?.number, valuationData?.currency)
          : EMPTY_STRING
      }
    />
  )
}

PropertyValuationsChartHeaderItem.propTypes = {
  code: PropTypes.string,
  valuationData: PropTypes.shape({
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currency: PropTypes.string,
    keyDate: PropTypes.string,
  }),
  isLeftAligned: PropTypes.bool,
}

export default PropertyValuationsChartHeaderItem
