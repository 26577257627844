import { Button, ButtonDesign, FileUploader } from '@fioneer/ui5-webcomponents-react/dist'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import useCreateDecisionPaperAttachment from 'hooks/services/business-events-and-tasks/decision-papers/useCreateDecisionPaperAttachment'

const AttachmentsUploadButton = ({
  eventId,
  setIsErrorDialogOpen,
  setEventError,
  setHighlightRow,
  resetRowHighlights,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-paper-attachments-table',
  })

  const queryClient = useQueryClient()

  const createDecisionPaperAttachmentMutation = useCreateDecisionPaperAttachment()

  const prepareFilesBody = (newFilesObject) => {
    const fileArray = []
    // looping over newFiles like this because the object looks like this: newFilesObject: {0: File, 1: File, length: 2}
    for (let i = 0; i < newFilesObject.length; i++) {
      fileArray.push(newFilesObject[i])
    }
    return fileArray
  }

  const handleFileUpload = (event) =>
    new Promise(() => {
      resetRowHighlights()
      const newFiles = event.target.files

      const filesBody = prepareFilesBody(newFiles)

      createDecisionPaperAttachmentMutation.mutate(
        {
          eventId,
          files: filesBody,
        },
        {
          onSuccess: ({ attachments }) => {
            const attachmentIds = attachments.map(({ id }) => id)
            setHighlightRow(attachmentIds)
            queryClient.invalidateQueries([
              'events',
              eventId,
              'decision-paper',
              'versions',
              'current',
              'attachments',
            ])
          },
          onError: async ({ response }) => {
            const responseJson = await response.json()
            setEventError(responseJson.code)
            setIsErrorDialogOpen(true)
          },
        },
      )
    })

  return (
    <FileUploader
      hideInput
      onChange={handleFileUpload}
      multiple={true}
      onClick={(e) => e.stopPropagation()}
    >
      <Button design={ButtonDesign.Transparent}>{t('upload')}</Button>
    </FileUploader>
  )
}

AttachmentsUploadButton.propTypes = {
  eventId: PropTypes.string.isRequired,
  setIsErrorDialogOpen: PropTypes.func.isRequired,
  setEventError: PropTypes.func.isRequired,
  setHighlightRow: PropTypes.func.isRequired,
  resetRowHighlights: PropTypes.func.isRequired,
}

export default AttachmentsUploadButton
