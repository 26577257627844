import { Link } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/dialog/MessageBoxWithExpandableDetailsShowDetails.module.css'

const MessageBoxWithExpandableDetailsShowDetails = ({ messageDetails }) => {
  const { t } = useTranslation()
  const [showDetails, setShowDetails] = useState(false)
  const expandDetails = useCallback(() => setShowDetails(true), [])

  if (showDetails) {
    return <pre className={styles['message-details']}>{messageDetails}</pre>
  }

  return (
    <Link className={styles['toggle-message-details-link']} onClick={expandDetails}>
      {t('buttons.show-details')}
    </Link>
  )
}

MessageBoxWithExpandableDetailsShowDetails.propTypes = {
  messageDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default MessageBoxWithExpandableDetailsShowDetails
