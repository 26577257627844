import { MessageBoxActions, Modals, Text } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ErrorDialog from 'components/domains/deals/financing/tranche-create/errorDialog/ErrorDialog'
import styles from 'components/domains/deals/financing/tranche-delete/TrancheDeleteDialog.module.css'
import Dialog, { DialogSize } from 'components/ui/dialog/Dialog'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import MessageBox, { MessageBoxTypes } from 'components/ui/message-box/MessageBox'
import useDeleteTranche from 'hooks/services/deals/financing/useDeleteTranche'
import useTrancheByDisplayId from 'hooks/services/deals/financing/useTrancheByDisplayId'
import { DealContext } from 'routes/deals/DealContext'
import paths from 'routes/paths'

const TrancheDeleteDialog = ({ onCloseDeleteTrancheDialog, showDeleteTrancheDialog }) => {
  const { t } = useTranslation()
  const { deal } = useContext(DealContext)
  const { trancheDisplayId } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const { data: resolvedTrancheData } = useTrancheByDisplayId(deal.dealUuid, trancheDisplayId)

  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)

  const queryClient = useQueryClient()

  const handleOnCloseDialog = () => {
    onCloseDeleteTrancheDialog()
  }

  const onSuccessNavigate = (displayId) =>
    navigate(`/${paths.deals}/${displayId}/financing?${searchParams.toString()}`)

  const deleteTranche = useDeleteTranche({
    onSuccess: () => {
      setIsLoadingDelete(false)
      Modals.showToast({
        children: <Text>{t('pages.deals.tranche-delete.delete-tranche-successful')}</Text>,
      })
      handleOnCloseDialog()
      onSuccessNavigate(deal.displayId)
      // First remove the query for the now deleted tranche, otherwise invalidating will
      // init a refetch and then result in a 404
      queryClient.removeQueries(['deals', deal.dealUuid, 'tranches', resolvedTrancheData.trancheId])
      queryClient.invalidateQueries(['deals', deal.dealUuid, 'tranches'])
      queryClient.invalidateQueries(['deals', deal.dealUuid, 'syndication'])
    },
    onError: () => {
      setIsErrorDialogOpen(true)
    },
  })

  const handleOnDeleteTranche = (e) => {
    setIsLoadingDelete(true)
    e.preventDefault()
    deleteTranche.mutate({ trancheId: resolvedTrancheData.trancheId, dealId: deal.dealUuid })
  }

  const handleRetryAfterError = (e) => {
    handleOnDeleteTranche(e)
  }

  const handleClose = (e) => {
    switch (e.detail.action) {
      case MessageBoxActions.Delete:
        handleOnDeleteTranche(e)
        break
      case MessageBoxActions.Cancel:
        handleOnCloseDialog()
        break
      default:
        break
    }
  }

  return (
    <>
      <MessageBox
        className={styles.trancheDeleteDialog}
        open={showDeleteTrancheDialog && !isLoadingDelete}
        onBeforeClose={(e) => {
          if (isLoadingDelete) {
            e.preventDefault()
            return
          }
          if (e.detail.escPressed) {
            e.preventDefault()
          }
        }}
        type={MessageBoxTypes.Warning}
        emphasizedAction={MessageBoxActions.Delete}
        actions={[MessageBoxActions.Delete, MessageBoxActions.Cancel]}
        titleText={t('pages.deals.tranche-delete.delete-dialog-title')}
        onClose={handleClose}
      >
        {t('pages.deals.tranche-delete.delete-dialog-text')}
      </MessageBox>
      <Dialog
        headerText={t('pages.deals.tranche-delete.loading-dialog-text')}
        open={isLoadingDelete}
        size={DialogSize.S}
        closeButton={<></>}
      >
        <div className={styles.loadingDialog}>
          <RequestStateResolver
            isLoading
            isError={false}
            renderContent={() => {}}
            errorToDisplay=""
            center
          />
        </div>
      </Dialog>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        onRetry={(e) => handleRetryAfterError(e)}
        onBeforeClose={(e) => {
          if (e.detail.escPressed) {
            e.preventDefault()
          }
        }}
        texts={{
          cancelButton: t('pages.deals.tranche-delete.error-dialog.button-cancel'),
          retryButton: t('pages.deals.tranche-delete.error-dialog.button-try-again'),
          title: t('pages.deals.tranche-delete.error-dialog.title'),
          description: t('pages.deals.tranche-delete.error-dialog.description', {
            returnObjects: true,
          }),
        }}
      />
    </>
  )
}

TrancheDeleteDialog.propTypes = {
  onCloseDeleteTrancheDialog: PropTypes.func.isRequired,
  showDeleteTrancheDialog: PropTypes.bool.isRequired,
}

export default TrancheDeleteDialog
