import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import Page from 'components/ui/page/Page'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useKpis from 'hooks/services/kpis/useKpis'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import KpiDetailsContent from 'routes/kpis/KpiDetailsContent'

const BusinessPartnerKpiDetailsPage = () => {
  const { t } = useTranslation()
  const { id: bpId } = useContext(BusinessPartnerContext)

  const referenceEntityType = cwpEntityTypes.BUSINESS_PARTNER
  const referenceEntityId = bpId
  const {
    data: { kpis = [] } = {},
    isError,
    isFetching,
  } = useKpis(referenceEntityType, referenceEntityId)

  const { kpiId } = useParams()
  const kpi = kpis.find(({ id }) => id === kpiId)

  const generateBreadcrumbs = () => {
    if (bpId) {
      return [
        { text: t('navigation.item.title.business-partner-kpis'), href: 'kpis' },
        { text: kpi?.name ?? kpiId },
      ]
    }
    return []
  }

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={referenceEntityId}
        entityType={referenceEntityType}
      />
    ),
    [referenceEntityType, referenceEntityId],
  )

  const renderContent = () => (
    <KpiDetailsContent
      referenceEntityType={referenceEntityType}
      referenceEntityId={referenceEntityId}
      kpiId={kpiId}
      isFetching={isFetching}
      isError={isError}
    />
  )

  return (
    <Page
      isError={isFetching}
      isLoading={isError}
      renderHeaderTitle={() => (
        <BusinessPartnerHeader
          actions={[
            <CreateBusinessPartnerEventAction key={'create-event-action'} />,
            markFavoriteAction,
          ]}
          breadcrumbs={generateBreadcrumbs()}
        />
      )}
      renderContent={renderContent}
    />
  )
}

export default BusinessPartnerKpiDetailsPage
