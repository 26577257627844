import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  decisionPaperSynchronizationStatus,
  decisionPaperTileTypes,
  isMajorVersion,
} from 'api/decision-paper/decisionPaperApi'
import DecisionPaperReduxStateAutomaticTileFrozenLoader from 'components/domains/business-events-and-tasks/decision-paper/redux-loader/automatic/DecisionPaperReduxStateAutomaticTileFrozenLoader'
import DecisionPaperReduxStateAutomaticTileLiveLoader from 'components/domains/business-events-and-tasks/decision-paper/redux-loader/automatic/DecisionPaperReduxStateAutomaticTileLiveLoader'
import DecisionPaperReduxStateManualTileLoader from 'components/domains/business-events-and-tasks/decision-paper/redux-loader/manual/DecisionPaperReduxStateManualTileLoader'
import { getLiveHookForTileCode } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import { allTilesInitialized } from 'redux/slices/decision-paper/tilesOverviewSlice'

const componentsForTileTypes = {
  [decisionPaperTileTypes.automatic]: {
    live: DecisionPaperReduxStateAutomaticTileLiveLoader,
    frozen: DecisionPaperReduxStateAutomaticTileFrozenLoader,
  },
  [decisionPaperTileTypes.automaticWithMetaData]: {
    live: DecisionPaperReduxStateAutomaticTileLiveLoader,
    frozen: DecisionPaperReduxStateAutomaticTileFrozenLoader,
  },
  [decisionPaperTileTypes.manual]: {
    live: DecisionPaperReduxStateManualTileLoader,
    frozen: DecisionPaperReduxStateManualTileLoader,
  },
}

const isAutomaticTileType = (tileType) =>
  [decisionPaperTileTypes.automatic, decisionPaperTileTypes.automaticWithMetaData].includes(
    tileType,
  )

const DecisionPaperReduxStateLoaderWrapper = ({ decisionPaper }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (!decisionPaper) return
    dispatch(allTilesInitialized())
  }, [decisionPaper, dispatch])

  return useMemo(() => {
    if (!decisionPaper) {
      return
    }
    const { sections } = decisionPaper

    const isMinorVersion = !isMajorVersion(
      decisionPaper.availableVersions[decisionPaper.availableVersions.length - 1].version,
    )

    const allTiles = []
    sections.forEach(({ tiles: sectionTiles, subsections }) => {
      const sectionTileList = sectionTiles.map((sectionTile) => ({
        ...sectionTile,
        isEditableInMinorVersion: false,
      }))
      allTiles.push(...sectionTileList)
      subsections.forEach(({ tiles: subsectionTiles, isEditableInMinorVersion }) => {
        const subsectionTileList = subsectionTiles.map((subsectionTile) => ({
          ...subsectionTile,
          isEditableInMinorVersion,
        }))
        allTiles.push(...subsectionTileList)
      })
    })

    return allTiles.map((tile) => {
      const LiveComponent = componentsForTileTypes[tile.type]?.live
      const FrozenComponent = componentsForTileTypes[tile.type]?.frozen
      if (
        (isAutomaticTileType(tile.type) && !getLiveHookForTileCode(tile.code)) ||
        !LiveComponent ||
        !FrozenComponent
      ) {
        return
      }
      const componentKey = `decision-paper-frozen-redux-${tile.id}-${decisionPaper?.currentVersion}-${decisionPaper?.synchronizationStatus}`
      if (
        decisionPaper.synchronizationStatus === decisionPaperSynchronizationStatus.stopped ||
        decisionPaper.synchronizationStatus === decisionPaperSynchronizationStatus.frozen ||
        (isMinorVersion && !tile.isEditableInMinorVersion)
      ) {
        return <FrozenComponent key={componentKey} {...tile} />
      }
      return <LiveComponent key={componentKey} {...tile} />
    })
  }, [decisionPaper])
}

DecisionPaperReduxStateLoaderWrapper.propTypes = {
  decisionPaper: PropTypes.shape({
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        tiles: PropTypes.array.isRequired,
        subsections: PropTypes.arrayOf(
          PropTypes.shape({
            tiles: PropTypes.array.isRequired,
          }),
        ).isRequired,
      }),
    ).isRequired,
    synchronizationStatus: PropTypes.string.isRequired,
    currentVersion: PropTypes.string.isRequired,
  }),
}

export default DecisionPaperReduxStateLoaderWrapper
