import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import {
  decisionStageVoterStatus,
  getObjectStatusForDecisionStageVoteStatus,
} from 'api/decision-process/decisionProcessApi'
import { decisionProcessConditionsDecisionStatus } from 'api/decision-process/decisionProcessConditionsApi'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import styles from 'components/domains/business-events-and-tasks/decision-stage/conditions/DecisionStageConditionsVotersTable.module.css'
import VotersTableActionCell, {
  possibleActions,
} from 'components/domains/business-events-and-tasks/decision-stage/table-cells/VotersTableActionCell'
import DecisionStageVotersTable from 'components/domains/business-events-and-tasks/decision-stage/voters-table/DecisionStageVotersTable'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useCreateDecisionStageConditionsVoter from 'hooks/services/business-events-and-tasks/decision-process/stages/conditions/useCreateDecisionStageConditionsVoter'
import useDecisionStageConditionsVoteTypes from 'hooks/services/business-events-and-tasks/decision-process/stages/conditions/useDecisionStageConditionsVoteTypes'
import useDecisionStageConditionsVoters from 'hooks/services/business-events-and-tasks/decision-process/stages/conditions/useDecisionStageConditionsVoters'
import useDeleteDecisionStageConditionsVoter from 'hooks/services/business-events-and-tasks/decision-process/stages/conditions/useDeleteDecisionStageConditionsVoter'
import useEditDecisionStageConditionsVoter from 'hooks/services/business-events-and-tasks/decision-process/stages/conditions/useEditDecisionStageConditionsVoter'
import useRemindConditionsVoter from 'hooks/services/business-events-and-tasks/decision-process/stages/conditions/useRemindConditionsVoter'
import { useStaffMemberSelf } from 'hooks/services/business-partners/staff-members/useStaffMemberSelf'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const toApprovalHref = 'conditions/approval/voters'

const calculateRenderedAction = ({
  decisionStatus,
  isOwnUser,
  voterStatusCode,
  hasVoteUpdatePermission,
  isPdfView,
}) => {
  if (isPdfView) {
    return
  }

  if (
    decisionStatus === decisionProcessConditionsDecisionStatus.pending ||
    decisionStatus === decisionProcessConditionsDecisionStatus.inProgressRequestDiscarded
  ) {
    return possibleActions.showEditCells
  }

  if (
    decisionStatus === decisionProcessConditionsDecisionStatus.inProgress &&
    !isOwnUser &&
    voterStatusCode === decisionStageVoterStatus.pending
  ) {
    return possibleActions.showRemindVoteButton
  }

  if (isOwnUser && hasVoteUpdatePermission) {
    return possibleActions.showToApprovalButton
  }
}

const DecisionStageConditionsVotersTable = ({
  decisionStageId,
  decisionStatus,
  isPdfView = false,
}) => {
  const {
    event: { id: eventId },
    allowedOperations,
  } = useContext(BusinessEventsAndTasksContext)
  const {
    isError,
    isLoading,
    data: votersData,
  } = useDecisionStageConditionsVoters({ eventId, decisionStageId })

  const {
    data: staffMember,
    isLoading: isStaffMemberLoading,
    isError: isStaffMemberError,
  } = useStaffMemberSelf()

  const hasUpdateDecisionStagePermission = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.updateDecisionStage),
    [allowedOperations],
  )

  const hasVoteUpdatePermission = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.updateDecisionStageVote),
    [allowedOperations],
  )

  const { mutate: deleteVoter } = useDeleteDecisionStageConditionsVoter()
  const { mutate: createVoter } = useCreateDecisionStageConditionsVoter()
  const { mutate: editVoter } = useEditDecisionStageConditionsVoter()

  const showActionButtons = useMemo(
    () =>
      !isPdfView &&
      hasUpdateDecisionStagePermission &&
      (decisionStatus === decisionProcessConditionsDecisionStatus.pending ||
        decisionStatus === decisionProcessConditionsDecisionStatus.inProgressRequestDiscarded),
    [hasUpdateDecisionStagePermission, decisionStatus, isPdfView],
  )

  const renderActionCell = useCallback(
    ({
      voterCancelButtonId,
      isEditModeForCurrentRow,
      isEditButtonDisabled,
      isDeleteButtonDisabled,
      onSaveCancelButtonClicked,
      onSaveButtonClicked,
      isSaveButtonDisabled,
      onEditButtonClicked,
      onDeleteButtonClicked,
      voterId,
      voterStatusCode,
      userId,
    }) => {
      const isOwnUser = userId === staffMember.id

      return (
        <VotersTableActionCell
          voterCancelButtonId={voterCancelButtonId}
          isEditModeForCurrentRow={isEditModeForCurrentRow}
          isEditButtonDisabled={isEditButtonDisabled}
          isDeleteButtonDisabled={isDeleteButtonDisabled}
          onSaveCancelButtonClicked={onSaveCancelButtonClicked}
          onSaveButtonClicked={onSaveButtonClicked}
          isSaveButtonDisabled={isSaveButtonDisabled}
          onEditButtonClicked={onEditButtonClicked}
          onDeleteButtonClicked={onDeleteButtonClicked}
          showActionButtons={showActionButtons}
          voterId={voterId}
          decisionStageId={decisionStageId}
          toApprovalHref={`${toApprovalHref}/${voterId}`}
          useRemindVoterMutationHook={useRemindConditionsVoter}
          renderedAction={calculateRenderedAction({
            decisionStatus,
            isOwnUser,
            voterStatusCode,
            hasVoteUpdatePermission,
            isPdfView,
          })}
        />
      )
    },
    [
      showActionButtons,
      decisionStageId,
      staffMember,
      hasVoteUpdatePermission,
      decisionStatus,
      isPdfView,
    ],
  )

  const renderApproversTable = useCallback(
    () => (
      <DecisionStageVotersTable
        voters={votersData.voters}
        eventId={eventId}
        decisionStageId={decisionStageId}
        deleteVoter={deleteVoter}
        createVoter={createVoter}
        editVoter={editVoter}
        useVoteTypes={useDecisionStageConditionsVoteTypes}
        renderActionCell={renderActionCell}
        getObjectStatusForVoteStatus={getObjectStatusForDecisionStageVoteStatus}
        showAddButton={showActionButtons}
        isPdfView={isPdfView}
      />
    ),
    [
      decisionStageId,
      eventId,
      deleteVoter,
      createVoter,
      editVoter,
      renderActionCell,
      votersData,
      showActionButtons,
      isPdfView,
    ],
  )

  return (
    <div className={styles.tableWrapper}>
      <LoadingStateWrapper
        isLoading={isLoading || isStaffMemberLoading}
        isError={isError || isStaffMemberError}
        renderContent={renderApproversTable}
      />
    </div>
  )
}

DecisionStageConditionsVotersTable.propTypes = {
  decisionStageId: PropTypes.string.isRequired,
  decisionStatus: PropTypes.oneOf(Object.values(decisionProcessConditionsDecisionStatus))
    .isRequired,
  isPdfView: PropTypes.bool,
}

export default DecisionStageConditionsVotersTable
