import { FlexBoxJustifyContent } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getMostRecentFinanceReports } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/exposure/getMostRecentFinanceReports'
import DateCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/DateCell'
import MoneyCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/MoneyCell'
import NumberCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/NumberCell'
import { UI5_TABLE_CELL_PADDING } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/display-and-edit-table/constants'
import SortedTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/periodical-checks/checks/table/shared/sorted-tables/SortedTable'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/total-debt-bundesbank/TotalDebtBundesbankTile.module.css'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/card/EmptyCardContent'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const amountOfDisplayedReports = 5

const getDefaultColumnDefinition = (columnKey, t) => ({
  columnKey: columnKey,
  title: t(columnKey),
  popinText: t(columnKey),
  demandPopin: true,
})

const TotalDebtBundesbankTile = ({ tileId, selectedBorrowerId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.total-debt-bundesbank.table',
  })
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { multipleFinanceReportsData } = tileStatus?.data ?? {}

  const financeReportsData = useMemo(
    () =>
      multipleFinanceReportsData.find(({ businessPartnerId: bpId }) => bpId === selectedBorrowerId),
    [multipleFinanceReportsData, selectedBorrowerId],
  )

  useEffect(() => {
    dispatch(
      setSourceRender({
        tileId,
        sourceRender: {
          businessPartnerId: selectedBorrowerId,
        },
      }),
    )
  }, [dispatch, selectedBorrowerId, tileId])

  const columns = useMemo(() => {
    const rawColumns = {
      repDate: { minWidth: 110, alignment: FlexBoxJustifyContent.End },
      totalDebt: { minWidth: 180, alignment: FlexBoxJustifyContent.End },
      noOfCreditors: { minWidth: 90, alignment: FlexBoxJustifyContent.End },
    }

    let minWidthSum = 0

    return Object.keys(rawColumns).map((columnKey) => {
      // we still add each column's minWidth to the sum,
      // because we need the overall width relative to the table width for the breakpoint
      minWidthSum += (rawColumns[columnKey].minWidth ?? 0) + UI5_TABLE_CELL_PADDING
      return {
        ...getDefaultColumnDefinition(columnKey, t),
        ...rawColumns[columnKey],
        minWidth: minWidthSum,
      }
    })
  }, [t])

  const sortedEvents = useMemo(
    () => getMostRecentFinanceReports(financeReportsData?.largeExposures, amountOfDisplayedReports),
    [financeReportsData?.largeExposures],
  )

  const tableData =
    sortedEvents?.map((item, index) => ({
      rowKey: index,
      repDate: { value: item.reportingDate, cellComponent: <DateCell date={item.reportingDate} /> },
      totalDebt: {
        value: item.totalDebt,
        cellComponent: <MoneyCell primary={{ amount: item.totalDebt, currency: item.currency }} />,
      },
      noOfCreditors: {
        value: item.numberOfCreditors,
        cellComponent: (
          <NumberCell
            number={item.numberOfCreditors}
            options={{ minimumFractionDigits: 0, maximumFractionDigits: 0 }}
          />
        ),
      },
    })) || []

  return (
    <div className={styles.tableWrapper}>
      <SortedTable
        columnDefinitions={columns}
        tableData={tableData}
        renderEmptyTableComponent={() => <EmptyCardContent />}
      />
    </div>
  )
}

TotalDebtBundesbankTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  selectedBorrowerId: PropTypes.string.isRequired,
}

export default TotalDebtBundesbankTile
