import camelize from 'camelize'
import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import uniq from 'lodash.uniq'
import { useMemo } from 'react'
import { useInfiniteRequest, useRequests } from 'hooks/services/baseService'

/**
 * process the paginated response
 *
 * merges the business partner arrays and returns the last page's total
 *
 * `camelize`s the resulting object
 */
const processResponse = (response) => {
  if (!response.data) return undefined
  return camelize(
    response.data.pages.reduce((acc, { business_partners, total }) => ({
      business_partners: [...acc.business_partners, ...business_partners],
      total,
    })),
  )
}

/**
 * process the response
 *
 * associates the BP Search results to the search keys
 *
 * `camelize`s the resulting object
 */
const processResponses = (searchKeys, responses) =>
  responses.reduce(
    (acc, response, index) => ({
      ...acc,
      [searchKeys[index]]: camelize(response.data),
    }),
    {},
  )

export const useMatchingBusinessPartners = (
  {
    q = '',
    id = '',
    street = '',
    city = '',
    countries = [],
    excludeInactive = true,
    roles = [],
    sort = '',
  },
  options,
) => {
  const params = new URLSearchParams()
  params.append('exclude_inactive', '' + excludeInactive)
  if (q) params.append('q', q)
  if (id) params.append('id', id)
  if (street) params.append('street', street)
  if (sort) params.append('sort', sort)
  if (roles?.length) params.append('roles', roles)
  if (countries?.length) params.append('countries', countries)
  if (city) params.append('city', city)

  const result = useInfiniteRequest({
    path: `/businesspartners`,
    translated: true,
    queryParams: params,
    options,
  })
  return { ...result, data: processResponse(result) }
}

export const useMatchingBusinessPartnersByNameOrId = ({
  searchKey = '',
  street = '',
  city = '',
  countries = '',
  roles = [],
  minLength = 0,
  sort = '',
  excludeInactive,
} = {}) => {
  const hasMinLength = searchKey !== null && searchKey.length >= minLength

  const params = new URLSearchParams()
  if (searchKey) params.append('q', searchKey)
  if (street) params.append('street', street)
  if (roles?.length) params.append('roles', roles)
  if (countries?.length) params.append('countries', countries)
  if (city) params.append('city', city)
  if (sort) params.append('sort', sort)
  if (!isNil(excludeInactive)) params.append('exclude_inactive', '' + excludeInactive)

  const result = useInfiniteRequest({
    path: `/businesspartners`,
    translated: true,
    queryParams: params,
    options: { enabled: searchKey ? hasMinLength : false },
  })
  return { ...result, data: processResponse(result) }
}

/**
 * Get matching tenants for an array of search keys
 * Returns an object that associates unique, non-empty search keys with the matching business partners
 */
export const useUniqueMatchingBusinessPartners = ({ searchKeys, searchScore }) => {
  const deduplicatedSearchKeys = uniq(compact(searchKeys))
  const requests = deduplicatedSearchKeys.map((searchKey) => {
    const queryParams = new URLSearchParams()
    queryParams.append('q', searchKey)
    queryParams.append('sort', '+id')
    if (!isNil(searchScore)) queryParams.append('score', searchScore)
    const path = `/businesspartners?${queryParams.toString()}`
    return { path }
  })

  const responses = useRequests({
    requests: requests,
    options: { enabled: !!deduplicatedSearchKeys.length },
  })

  const data = processResponses(deduplicatedSearchKeys, responses)
  const isLoading = responses.some((response) => response.isLoading)
  const isError = responses.some((response) => response.isError)
  const totalAmountOfRequests = useMemo(
    () => deduplicatedSearchKeys.length,
    [deduplicatedSearchKeys.length],
  )
  const finishedRequests = useMemo(
    () => responses.filter((response) => !response.isLoading).length,
    [responses],
  )

  return {
    data,
    isLoading,
    isError,
    totalAmountOfRequests,
    finishedRequests,
  }
}
