import { MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CloseableMessageStrip from 'components/ui/feedback/CloseableMessageStrip'

const DealReservationWarningSection = ({ messages }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.limit-check.reservations.warnings',
  })

  return messages?.length ? (
    <React.Fragment>
      {messages.map((warningMessage, index) => (
        <CloseableMessageStrip key={index} design={MessageStripDesign.Warning}>
          {t(warningMessage)}
        </CloseableMessageStrip>
      ))}
    </React.Fragment>
  ) : (
    <React.Fragment />
  )
}

DealReservationWarningSection.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string),
}

export default DealReservationWarningSection
