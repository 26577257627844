import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import SharedAuthorityLevelCalculatorComment from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/authority-level-calculator/SharedAuthorityLevelCalculatorComment'
import SharedAuthorityLevelCalculatorHeader from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/authority-level-calculator/SharedAuthorityLevelCalculatorHeader'
import SharedAuthorityLevelCalculatorTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/authority-level-calculator/SharedAuthorityLevelCalculatorTable'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/authority-level-calculator/SharedAuthorityLevelCalculatorTile.module.css'

const SharedAuthorityLevelCalculatorTile = ({
  tileId,
  isPdfView = false,
  showConfirmationForLinks = false,
  columnDefinitionsFunction,
}) => {
  const tileState = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const {
    data: {
      authorityLevelCalculatorTableData,
      authorityLevelCalculatorHeaderData,
      authorityLevelCalculatorCommentData,
      dealBorrowerBpId,
    },
  } = tileState

  return (
    <>
      <SharedAuthorityLevelCalculatorHeader
        showConfirmationForLinks={showConfirmationForLinks}
        {...authorityLevelCalculatorHeaderData}
      />
      <DecisionPaperTileListSeparator />
      <div className={styles.tableWrapper}>
        <SharedAuthorityLevelCalculatorTable
          isPdfView={isPdfView}
          showConfirmationForLinks={showConfirmationForLinks}
          data={authorityLevelCalculatorTableData}
          dealBorrowerBpId={dealBorrowerBpId}
          columnDefinitionsFunction={columnDefinitionsFunction}
        />
      </div>
      {authorityLevelCalculatorCommentData && (
        <>
          <DecisionPaperTileListSeparator />
          <SharedAuthorityLevelCalculatorComment
            isPdfView={isPdfView}
            comment={authorityLevelCalculatorCommentData}
          />
        </>
      )}
    </>
  )
}

SharedAuthorityLevelCalculatorTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool,
  showConfirmationForLinks: PropTypes.bool,
  columnDefinitionsFunction: PropTypes.func.isRequired,
}

export default SharedAuthorityLevelCalculatorTile
