import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import AnnualReviewDealOverviewPropertySelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-selection/AnnualReviewDealOverviewPropertySelect'
import AnnualReviewPropertySelectPdfView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-selection/AnnualReviewPropertySelectPdfView'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/rental-income-over-time/MultiRentalIncomeOverTimeTile.module.css'
import PropertyRentRollOverviewRentalIncomeOverTimeChart from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared/PropertyRentRollOverviewRentalIncomeOverTimeChart'
import {
  useNumberFormatter,
  useCustomizableCurrencyFormatter,
  useAreaMeasurementUnitFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared/hooks/i18n/useI18n'
import useHistoricalUsageTypeChartData from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared/useHistoricalUsageTypeChartData'
import useAnnualReviewDealOverviewSelectedProperty from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/utils/useAnnualReviewDealOverviewSelectedProperty'
import { ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/constants'
import CardHeaderWithMetrics from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/card/CardHeaderWithMetrics'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/tegovaRating/shared/EmptyCardContent'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const getHistoricalUsageTypeChartData = (parameters, historicalUsageTypeChartDataHook, accessors) =>
  historicalUsageTypeChartDataHook(parameters, accessors)

const MultiRentalIncomeOverTimeTile = ({ tileId, selectedDealIndex, isPdfView = false }) => {
  const dispatch = useDispatch()

  const {
    data: { propertyRentRollOverviewByDealUuid, dealsData },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const selectedDealUuid = dealsData[selectedDealIndex]?.dealUuid

  const dealData =
    Object.values(propertyRentRollOverviewByDealUuid).find(
      ({ dealUuid }) => dealUuid === selectedDealUuid,
    ) ?? {}

  const { properties } = dealData

  const { selectedPropertyUuid, selectedPropertyDisplayId, selectedPropertyName } =
    useAnnualReviewDealOverviewSelectedProperty({
      propertiesData: properties,
      propertyMetadataTileCode: ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES.TENANCY_RENT_ROLL,
    })

  useEffect(() => {
    dispatch(
      setSourceRender({
        tileId,
        sourceRender: { propertyId: selectedPropertyDisplayId },
      }),
    )
  }, [dispatch, selectedPropertyDisplayId, tileId])

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.property.rent-roll.overview.rental-income-over-time',
  })

  const formatArea = useAreaMeasurementUnitFormatter()
  const formatCurrency = useCustomizableCurrencyFormatter({ notation: 'compact' })
  const formatNumber = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: 'compact',
  })

  const setValueOrUndefined = useCallback(
    (value) => (value ? formatNumber(value) : undefined),
    [formatNumber],
  )
  const setUnitOrUndefined = useCallback((unit) => (unit ? formatArea(unit) : '-'), [formatArea])
  const setCurrencyOrUndefined = useCallback(
    (value, currency) => (value && currency ? formatCurrency(value, currency) : undefined),
    [formatCurrency],
  )

  const getAnnualizedCurrentRentForUsageType = (usageTypeKpi) =>
    usageTypeKpi?.annualizedCurrentRent?.number ?? 0

  const getTotalPropertyVacancy = (propertyKpis) => ({
    marketRentInclVacancy: propertyKpis?.totalMarketRent?.number ?? 0,
    marketRentExclVacancy: propertyKpis?.letMarketRent?.number ?? 0,
  })
  const aggregateGroupedUsageTypeValues = (otherValues) =>
    otherValues?.reduce((acc, curr) => acc + curr, 0) ?? 0

  const renderContent = useCallback(
    (currentProperty) => {
      const currentPropertyUuid = currentProperty?.uuid

      const propertyData =
        dealData.propertyKpis?.find(({ propertyUuid }) => propertyUuid === currentPropertyUuid)
          ?.keyDateToRentRollKpis?.[0] ?? {}

      const propertyKpiData = { keyDate: propertyData.keyDate, ...propertyData.kpis }

      const historicalPropertyKpiData =
        dealData.historicalPropertyKpis?.find(
          ({ propertyUuid }) => propertyUuid === currentPropertyUuid,
        )?.keyDateToRentRollKpis ?? []

      const primaryMetric = {
        label: t('current-rent'),
        value: setValueOrUndefined(propertyKpiData?.annualizedCurrentRent?.number),
        unit: propertyKpiData?.annualizedCurrentRent?.currency,
      }

      const unit = setUnitOrUndefined(propertyKpiData?.totalAreaSurface?.measurementUnit)
      const secondaryMetrics = [
        {
          label: t('contracted-rent'),
          value: setCurrencyOrUndefined(
            propertyKpiData?.annualizedContractedRent?.number,
            propertyKpiData?.annualizedContractedRent?.currency,
          ),
        },
        {
          label: t('current-rent-per-uom') + unit,
          value: setCurrencyOrUndefined(
            propertyKpiData?.annualizedCurrentRentPerUom?.number,
            propertyKpiData?.annualizedCurrentRentPerUom?.currency,
          ),
        },
        {
          label: t('market-rent-per-uom') + unit + t('incl-vacancy'),
          value: setCurrencyOrUndefined(
            propertyKpiData?.totalMarketRentPerUom?.number,
            propertyKpiData?.totalMarketRentPerUom?.currency,
          ),
        },
      ]

      const { chartData, usageTypes } = getHistoricalUsageTypeChartData(
        historicalPropertyKpiData,
        useHistoricalUsageTypeChartData,
        {
          getMetricValueForUsageTypeKpi: getAnnualizedCurrentRentForUsageType,
          getPropertyMetrics: getTotalPropertyVacancy,
          aggregateGroupedUsageTypeValues: aggregateGroupedUsageTypeValues,
        },
      )

      const currency = historicalPropertyKpiData?.[0]?.kpis?.totalMarketRent?.currency
      const isEmpty = chartData.length === 0

      return (
        <div key={currentProperty?.uuid}>
          {isPdfView ? (
            <AnnualReviewPropertySelectPdfView {...currentProperty} />
          ) : (
            <AnnualReviewDealOverviewPropertySelect
              propertiesData={properties}
              propertyMetadataTileCode={
                ANNUAL_REVIEW_DEAL_OVERVIEW_SUBSECTION_CODES.TENANCY_RENT_ROLL
              }
            />
          )}
          <DecisionPaperTileListSeparator />
          <div className={styles.cardHeader}>
            <CardHeaderWithMetrics
              title={t('title')}
              primaryMetric={primaryMetric}
              secondaryMetrics={secondaryMetrics}
              referenceDate={propertyKpiData?.keyDate}
              isLoading={false}
              isError={false}
            />
          </div>
          {isEmpty ? (
            <EmptyCardContent title={t('chart.empty.title')} subtitle={t('chart.empty.subtitle')} />
          ) : (
            <div className={styles.chartContainer}>
              <PropertyRentRollOverviewRentalIncomeOverTimeChart
                data={chartData}
                usageTypes={usageTypes}
                currency={currency}
              />
            </div>
          )}
        </div>
      )
    },
    [
      dealData.historicalPropertyKpis,
      dealData.propertyKpis,
      isPdfView,
      properties,
      setCurrencyOrUndefined,
      setUnitOrUndefined,
      setValueOrUndefined,
      t,
    ],
  )

  return isPdfView
    ? properties?.map((property) => renderContent(property))
    : renderContent({
        uuid: selectedPropertyUuid,
        id: selectedPropertyDisplayId,
        description: selectedPropertyName,
      })
}

MultiRentalIncomeOverTimeTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultiRentalIncomeOverTimeTile
