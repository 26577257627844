const setFocusElement = (state, { payload: focusElement }) => {
  if (!focusElement) {
    state.focusElement = {}
    return
  }
  const { conditionIndex, requirementIndex, fieldName } = focusElement
  if (!state.conditions[conditionIndex]) {
    return
  }

  state.focusElement = {
    conditionIndex,
    requirementIndex,
    fieldName,
  }
}

export default setFocusElement
