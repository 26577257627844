const calculateEconomicProfitCost = ({
  fullCost: economicProfitAmountFullCost,
  directCost: economicProfitAmountDirectCost,
  lowerCost: economicProfitAmountLowerCost,
}) => {
  const fullCost = economicProfitAmountFullCost ?? 0
  const directCost = economicProfitAmountDirectCost ?? 0
  const lowerCost = economicProfitAmountLowerCost ?? 0
  return {
    isDirectCostNegative: fullCost < 0 && directCost < 0 && lowerCost > 0,
    isFullCostNegative: fullCost < 0 && directCost > 0,
    isLowerCostNegative: fullCost < 0 && directCost < 0 && lowerCost < 0,
  }
}

export default calculateEconomicProfitCost
