import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { covenantPermissions } from 'api/deals/covenantPermissions'
import { CardHeaderLastEditedText } from 'components/domains/deals/card/CardHeaderLastEditedText'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import ChangeToAbsoluteLimitsButton from 'components/domains/deals/covenants/limit-cards/ChangeToAbsoluteLimits'
import FinancialLimitDisplayTable from 'components/domains/deals/covenants/limit-cards/display-tables/FinancialLimitDisplayTable'
import NonFinancialLimitDisplayTable from 'components/domains/deals/covenants/limit-cards/display-tables/NonFinancialLimitDisplayTable'
import FinancialLimitEditTable from 'components/domains/deals/covenants/limit-cards/edit-mode/FinancialLimitEditTable'
import NonFinancialLimitEditTable from 'components/domains/deals/covenants/limit-cards/edit-mode/NonFinancialLimitEditTable'
import useModifyLimits from 'components/domains/deals/covenants/limit-cards/edit-mode/useModifyLimits'
import { CovenantLimitType } from 'components/domains/deals/covenants/limit-cards/model/CovenantLimitType'
import { hasAbsoluteLimits } from 'components/domains/deals/covenants/limit-cards/model/CovenantRelativityType'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import Card from 'components/ui/card/Card'
import { ToggleEditMode } from 'components/ui/card/CardHeaderWithEditMode'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useCovenantById from 'hooks/services/deals/covenants/useCovenantById'
import useCovenantLimitsById from 'hooks/services/deals/covenants/useCovenantLimitsById'
import useUpdateLimits from 'hooks/services/deals/covenants/useUpdateLimits'
import { CovenantContext } from 'routes/business-partners/CovenantContext'

const emptyLimit = {
  relativeLimits: [],
  absoluteLimits: [],
}

const CovenantLimitCard = ({ covenantId, cardType, title, ...props }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.limit-card',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const showErrorMessageBox = useShowErrorMessageBox()
  const { allowedOperations: covenantAllowedOperations = [] } = useContext(CovenantContext)
  const isAllowedToUpdateCovenant = covenantAllowedOperations.includes(
    covenantPermissions.updateContractCovenant,
  )

  const [isEditMode, setIsEditMode] = useState(false)
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)

  const [formState, setFormState] = useState()
  const [isFormValid, setIsFormValid] = useState(true)

  const {
    isLoading,
    isError,
    data: { covenantLimits = [] } = {},
  } = useCovenantLimitsById(covenantId)

  const {
    isLoading: isLoadingCovenant,
    isFetching: isFetchingCovenant,
    isError: isErrorCovenant,
    data: { covenantDefinition: { unit } = {}, currencyCode } = {},
  } = useCovenantById(covenantId)

  const [currentDisplayLimit, setCurrentDisplayLimit] = useState(emptyLimit)
  useEffect(() => {
    if (isLoading || isError) return
    setCurrentDisplayLimit(
      covenantLimits.filter(({ limitType }) => limitType === cardType)?.[0] ?? emptyLimit,
    )
  }, [covenantLimits, isLoading, isError, cardType])
  const [isLimitAbsolute, setIsLimitAbsolute] = useState(hasAbsoluteLimits(currentDisplayLimit))
  useEffect(() => {
    setIsLimitAbsolute(hasAbsoluteLimits(currentDisplayLimit))
  }, [currentDisplayLimit])

  /* Edit Mode: Formstate Management */
  const mapLimitToFormState = useCallback(
    (srcLimit) => ({
      configCovenantBreachResultUuid: srcLimit?.resultOfBreach?.configCovenantBreachResultUuid,
      absoluteLimits: srcLimit?.absoluteLimits,
      relativeLimits: srcLimit?.relativeLimits,
    }),
    [],
  )
  const initializeFormState = (srcLimit) => {
    setFormState(mapLimitToFormState(srcLimit))
  }
  const { buildLimit, cleanLimits } = useModifyLimits({
    isLimitAbsolute: isLimitAbsolute,
    updateFormState: setFormState,
    formState,
  })
  const initializeFormStateAtLeastOneRow = (initializeAsAbsoluteLimit) => {
    const currentLimits = currentDisplayLimit
      ? [...currentDisplayLimit[initializeAsAbsoluteLimit ? 'absoluteLimits' : 'relativeLimits']]
      : []
    if (currentLimits.length <= 0) {
      const newLimit = buildLimit(
        {
          unit: unit,
          currencyCode: currencyCode,
        },
        initializeAsAbsoluteLimit,
      )
      currentLimits.push(newLimit)
    }
    initializeFormState({
      ...currentDisplayLimit,
      [initializeAsAbsoluteLimit ? 'absoluteLimits' : 'relativeLimits']: currentLimits,
    })
  }

  /* Handler Methods */
  const onUpdateSuccess = useCallback(() => {
    showToast({ children: t('update.success') }, document.body)
    queryClient.invalidateQueries('covenants', covenantId, 'limits')
    if (cardType === CovenantLimitType.SoftLimit || CovenantLimitType.HardLimit) {
      queryClient.invalidateQueries('covenants', covenantId, 'monitoring', 'items')
      queryClient.invalidateQueries('covenants', 'monitoring', 'items')
    }
    setIsEditMode(false)
  }, [showToast, t, queryClient, covenantId, cardType])
  const onUpdateError = async (error) => {
    const { errors: [errorResponse] = [] } = await error.response.json()
    showErrorMessageBox({ message: t('update.error'), error: errorResponse })
  }
  const updateLimits = useUpdateLimits({
    onSuccess: onUpdateSuccess,
    onError: onUpdateError,
  })
  const executeUpdate = () => {
    cleanLimits()
    updateLimits.mutate({
      covenantUuid: covenantId,
      limit: { ...formState, limitType: cardType },
    })
  }

  const onSaveChanges = () => {
    if (cardType === CovenantLimitType.SoftLimit || cardType === CovenantLimitType.HardLimit) {
      setIsConfirmationDialogOpen(true)
    } else {
      executeUpdate()
    }
  }

  const onCancelEdit = () => {
    setIsLimitAbsolute(hasAbsoluteLimits(currentDisplayLimit)) // reset because potentially tampered by absolute conversion
    setIsEditMode(false)
  }

  const switchToEditMode = () => {
    if (!isEditMode) {
      //CardHeaderWithEditMode calls this for the reverse direction (when on save)
      initializeFormStateAtLeastOneRow(isLimitAbsolute)
      setIsEditMode(true)
    }
  }

  const onConvertedToAbsoluteLimits = (newAbsoluteLimits) => {
    setIsLimitAbsolute(true) //override to absolute limits
    if (newAbsoluteLimits.length > 0) {
      initializeFormState({
        ...currentDisplayLimit,
        relativeLimits: [],
        absoluteLimits: [...newAbsoluteLimits],
      })
    } else {
      initializeFormStateAtLeastOneRow(true)
    }
    setIsEditMode(true)
  }

  /* Render */
  return (
    <>
      <Card
        header={
          <CardHeaderLastEditedText
            title={title}
            email={currentDisplayLimit?.lastUpdatedBy}
            timestamp={currentDisplayLimit?.lastUpdatedAt}
            actions={
              !isLoading &&
              !isLoadingCovenant &&
              !isFetchingCovenant &&
              !isErrorCovenant &&
              !isError &&
              isAllowedToUpdateCovenant && (
                <ToggleEditMode
                  isEditMode={isEditMode}
                  onToggle={switchToEditMode}
                  onSave={onSaveChanges}
                  onCancel={onCancelEdit}
                  buttonIdPrefix="covenant-limit"
                  checkSaveDisabled={() => !isFormValid}
                />
              )
            }
          />
        }
        {...props}
      >
        <LoadingStateWrapper isLoading={!!isLoading} isError={!!isError}>
          {isEditMode ? (
            <>
              {(cardType === CovenantLimitType.SoftLimit ||
                cardType === CovenantLimitType.HardLimit) && (
                <FinancialLimitEditTable
                  formState={formState}
                  isLimitAbsolute={isLimitAbsolute}
                  updateFormState={setFormState}
                  setIsValid={setIsFormValid}
                  unit={unit}
                  currencyCode={currencyCode}
                />
              )}
              {cardType === CovenantLimitType.NonFinancialLimit && (
                <NonFinancialLimitEditTable
                  formState={formState}
                  isLimitAbsolute={isLimitAbsolute}
                  updateFormState={setFormState}
                  setIsValid={setIsFormValid}
                />
              )}
            </>
          ) : (
            <>
              <CardSection>
                <CardShowItem
                  label={t('result-of-breach')}
                  value={currentDisplayLimit.resultOfBreach?.name}
                />
              </CardSection>
              <CardSection>
                {(cardType === CovenantLimitType.SoftLimit ||
                  cardType === CovenantLimitType.HardLimit) && (
                  <FinancialLimitDisplayTable
                    absoluteLimits={currentDisplayLimit.absoluteLimits}
                    relativeLimits={currentDisplayLimit.relativeLimits}
                    actions={
                      isAllowedToUpdateCovenant && !hasAbsoluteLimits(currentDisplayLimit)
                        ? [
                            <ChangeToAbsoluteLimitsButton
                              key="1"
                              relativeLimitList={currentDisplayLimit.relativeLimits ?? []}
                              onConvertedToAbsoluteLimitList={onConvertedToAbsoluteLimits}
                            />,
                          ]
                        : []
                    }
                  />
                )}
                {cardType === CovenantLimitType.NonFinancialLimit && (
                  <NonFinancialLimitDisplayTable
                    absoluteLimits={currentDisplayLimit.absoluteLimits}
                    relativeLimits={currentDisplayLimit.relativeLimits}
                    actions={
                      isAllowedToUpdateCovenant && !hasAbsoluteLimits(currentDisplayLimit)
                        ? [
                            <ChangeToAbsoluteLimitsButton
                              key="1"
                              relativeLimitList={currentDisplayLimit.relativeLimits ?? []}
                              onConvertedToAbsoluteLimitList={onConvertedToAbsoluteLimits}
                            />,
                          ]
                        : []
                    }
                  />
                )}
              </CardSection>
            </>
          )}
        </LoadingStateWrapper>
      </Card>
      <ConfirmationDialog
        confirmationMessage={`${t('update.save-changes')}\n\n ${t('update.confirmation')}`}
        confirmButtonText={tNoPrefix('buttons.confirm')}
        isOpen={isConfirmationDialogOpen}
        handleConfirmation={executeUpdate}
        handleCancel={() => setIsConfirmationDialogOpen(false)}
      />
    </>
  )
}

CovenantLimitCard.propTypes = {
  covenantId: PropTypes.string.isRequired,
  cardType: PropTypes.oneOf(Object.values(CovenantLimitType)).isRequired,
  title: PropTypes.string.isRequired,
}

export default CovenantLimitCard
