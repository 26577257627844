import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import { eventStatus as possibleEventStatus } from 'api/events/status'
import AddTaskDialog from 'components/domains/business-events-and-tasks/tasks/dialogs/AddTaskDialog'
import TaskManagerMultiEditDialog from 'components/domains/business-events-and-tasks/tasks/dialogs/TaskManagerMultiEditDialog'
import useMultipleTasksCommentButtonAndDialog from 'components/domains/business-events-and-tasks/tasks/dialogs/useMultipleTasksCommentButtonAndDialog'
import TaskTableEditMultipleButton from 'components/domains/business-events-and-tasks/tasks/table/TaskTableEditMultipleButton'
import TasksTableLoadingWrapper from 'components/domains/business-events-and-tasks/tasks/table/TasksTableLoadingWrapper'
import Card from 'components/ui/card/Card'
import { reset } from 'redux/slices/tasks/taskTableSlice'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import styles from 'routes/business-events-and-tasks/events/EventDetail.module.css'

const isDraftStatus = (status) => status === possibleEventStatus.drafted
const isOngoingStatus = (status) => status === possibleEventStatus.ongoing

const EventDetailTaskTable = ({
  disabled,
  eventId,
  eventStatus,
  hideStatusColumn,
  setHasOpenMandatoryTasks,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.tasks',
  })
  const dispatch = useDispatch()
  const [isAddTaskDialogOpen, setIsAddTaskDialogOpen] = useState(false)
  const { allowedOperations } = useContext(BusinessEventsAndTasksContext)
  const [isEditMultipleDialogOpen, setIsEditMultipleDialogOpen] = useState(false)
  const { commentMultipleButton, commentMultipleDialog } = useMultipleTasksCommentButtonAndDialog()

  const taskUpdateAllowed = allowedOperations?.includes(businessEventAllowedOperations.updateTask)
  const eventUpdateAllowed = allowedOperations?.includes(businessEventAllowedOperations.updateEvent)

  const showAddButton =
    eventUpdateAllowed && (isDraftStatus(eventStatus) || isOngoingStatus(eventStatus))

  useEffect(
    () => () => {
      dispatch(reset())
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const additionalActionsArray = useMemo(() => {
    const additionalActions = []

    if (taskUpdateAllowed) {
      additionalActions.push(
        commentMultipleButton,

        <TaskTableEditMultipleButton
          key="task-table-edit-mulitple-button"
          onClick={() => {
            setIsEditMultipleDialogOpen(true)
          }}
        />,
      )
    }

    if (showAddButton) {
      additionalActions.push(
        <Button
          key="add-task-button"
          design={ButtonDesign.Transparent}
          onClick={() => setIsAddTaskDialogOpen(true)}
        >
          {t('buttons.add')}
        </Button>,
      )
    }

    return additionalActions
  }, [taskUpdateAllowed, showAddButton, commentMultipleButton, t])

  return (
    <>
      <Card className={disabled ? styles.disabled : ''} {...(!!disabled && { inert: '' })}>
        <div className={styles['table-wrapper']}>
          <TasksTableLoadingWrapper
            eventId={eventId}
            additionalActions={additionalActionsArray}
            hideStatusColumn={hideStatusColumn}
            setHasOpenMandatoryTasks={setHasOpenMandatoryTasks}
          />
        </div>
      </Card>
      {showAddButton && (
        <AddTaskDialog
          eventId={eventId}
          isOpen={isAddTaskDialogOpen}
          setIsOpen={setIsAddTaskDialogOpen}
        />
      )}
      {isEditMultipleDialogOpen && (
        <TaskManagerMultiEditDialog
          isOpen={isEditMultipleDialogOpen}
          setIsOpen={setIsEditMultipleDialogOpen}
        />
      )}
      {commentMultipleDialog}
    </>
  )
}

EventDetailTaskTable.propTypes = {
  disabled: PropTypes.bool.isRequired,
  eventId: PropTypes.string.isRequired,
  eventStatus: PropTypes.string.isRequired,
  hideStatusColumn: PropTypes.bool.isRequired,
  setHasOpenMandatoryTasks: PropTypes.func.isRequired,
}

export default EventDetailTaskTable
