import get from 'lodash.get'
import { YEAR_UNIT } from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonDeviation'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { getYearOrYears } from 'utils/YearUnitUtil'

const usePortfolioRentRollAnalysisComparisonMetrics = ({
  isInComparison,
  keyDates,
  kpis,
  valueAccessor,
  unitAccessor,
  fallbackUnit,
  factor = 1,
}) => {
  const getUnit = (unit, value) => (unit === YEAR_UNIT ? getYearOrYears(value) : unit)
  const { format: formatDate } = useShortDateFormatter()
  const currentValue = get(kpis.current, valueAccessor) * factor
  const comparisonValue = get(kpis.comparison, valueAccessor) * factor
  const currentUnit =
    getUnit(get(kpis.current, unitAccessor), currentValue) ?? getUnit(fallbackUnit, currentValue)
  const comparisonUnit =
    getUnit(get(kpis.comparison, unitAccessor), comparisonValue) ??
    getUnit(fallbackUnit, comparisonValue)
  return {
    primary: {
      label: formatDate(keyDates.current),
      value: currentValue,
      unit: currentUnit,
    },
    secondary: isInComparison
      ? {
          label: formatDate(keyDates.comparison),
          value: comparisonValue,
          unit: comparisonUnit,
        }
      : null,
  }
}

export default usePortfolioRentRollAnalysisComparisonMetrics
