import {
  Link,
  MessageStrip,
  MessageStripDesign,
  ObjectStatus,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperTileListItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListItem'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-bp-summary/ArrearsBusinessPartnerSummaryTile.module.css'
import { TILE_VERSION } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import {
  useCustomizableCurrencyFormatter,
  usePercentageFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const PLACE_HOLDER = '-'

const ArrearsBusinessPartnerSummaryTile = ({ tileId }) => {
  const {
    data: {
      businessPartner: { businessPartnerId, businessPartnerName } = {},
      ratingClass,
      validFrom,
      riskMonitoringStatus,
      arrearsAmountAndCurrency,
      overpaymentAmountAndCurrency,
      currentClosedCommitmentTotal,
      currentClosedCommitmentLoans,
      currentClosedCommitmentLoansOwnShare,
      outstandingLoansOwnShare,
      availableLoansOwnShare,
      daysOfGrace,
      valutaDate,
      defaultQuote,
      isErrorBcaAmounts,
    },
    version,
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.arrears-bp-summary',
  })

  const { format: formatDate } = useShortDateFormatter()
  const formatMoney = useCustomizableCurrencyFormatter()
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })

  const getValueOrPlaceholder = (value) => value ?? PLACE_HOLDER
  const getDateOrPlaceholder = (date) => (date ? formatDate(date) : PLACE_HOLDER)
  const getMoneyOrPlaceholder = (amount, currency) =>
    amount && currency ? formatMoney(amount, currency) : PLACE_HOLDER
  const getPercentageOrPlaceholder = (percentage) =>
    percentage ? formatPercentage(percentage) : PLACE_HOLDER

  const currencyValueItems = [
    { label: t('arrears-amount'), value: arrearsAmountAndCurrency },
    { label: t('overpayment'), value: overpaymentAmountAndCurrency },
    { label: t('commitment-total'), value: currentClosedCommitmentTotal },
    { label: t('commitment-loans'), value: currentClosedCommitmentLoans },
    { label: t('commitment-loans-own-share'), value: currentClosedCommitmentLoansOwnShare },
    { label: t('outstanding-loans-own-share'), value: outstandingLoansOwnShare },
    { label: t('available-loans-own-share'), value: availableLoansOwnShare },
  ]

  const getCurrencyValueItems = () =>
    currencyValueItems.map(({ label, value }) => (
      <DecisionPaperTileListItem
        key={label}
        label={label}
        value={getMoneyOrPlaceholder(value?.amount, value?.currency)}
        additionalValue={
          value?.additionalAmount
            ? getMoneyOrPlaceholder(value?.additionalAmount, value?.additionalCurrency)
            : undefined
        }
        additionalTextValueIsNeutralColor={true}
      />
    ))

  const isAtLeastVersion2 = !!version && version !== TILE_VERSION.V1

  return (
    <div className={styles.wrapper}>
      {isErrorBcaAmounts && isAtLeastVersion2 && (
        <div className={styles.bcaAmountWarningWrapper}>
          <MessageStrip
            id={'bca-amount-warning'}
            className={styles.bcaAmountWarning}
            design={MessageStripDesign.Warning}
            hideCloseButton
          >
            <span>{t('bca-amount-warning')}</span>
          </MessageStrip>
        </div>
      )}
      <DecisionPaperTileListItem
        label={t('business-partner')}
        value={
          <Link
            target={'_blank'}
            href={`/business-partners/${businessPartnerId}`}
            className={styles.link}
          >
            {businessPartnerName}
          </Link>
        }
        additionalValue={getValueOrPlaceholder(businessPartnerId)}
      />
      <DecisionPaperTileListItem
        label={t('pd-regulatory')}
        value={getValueOrPlaceholder(ratingClass)}
      />
      <DecisionPaperTileListItem label={t('valid-from')} value={getDateOrPlaceholder(validFrom)} />
      <DecisionPaperTileListItem
        label={t('risk-monitoring-status')}
        value={
          !isEmpty(riskMonitoringStatus) ? (
            <ObjectStatus inverted state={riskMonitoringStatus?.valueState}>
              {riskMonitoringStatus?.text}
            </ObjectStatus>
          ) : (
            PLACE_HOLDER
          )
        }
      />
      {getCurrencyValueItems()}
      <DecisionPaperTileListItem
        label={t('days-of-grace')}
        value={getValueOrPlaceholder(daysOfGrace)}
      />
      <DecisionPaperTileListItem
        label={t('valuta-date')}
        value={getDateOrPlaceholder(valutaDate)}
      />
      <DecisionPaperTileListItem
        label={t('default-quote')}
        value={getPercentageOrPlaceholder(defaultQuote)}
      />
    </div>
  )
}

ArrearsBusinessPartnerSummaryTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default ArrearsBusinessPartnerSummaryTile
