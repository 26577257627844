import _ from 'lodash'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { decisionPaperTileTypes } from 'api/decision-paper/decisionPaperApi'
import useReduxTileImageLoaderEffects from 'hooks/services/business-events-and-tasks/decision-papers/redux-loader/useReduxTileImageLoaderEffects'
import useReduxTileLoaderEffects from 'hooks/services/business-events-and-tasks/decision-papers/redux-loader/useReduxTileLoaderEffects'
import useTileImageLoader from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useTileImageLoader'
import useTileLoader from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useTileLoader'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionPaperReduxStateAutomaticTileFrozenLoader = ({
  id: tileId,
  viewConfig,
  hasImages,
  isEditableInMinorVersion,
}) => {
  const event = useContext(BusinessEventsAndTasksContext).event
  const [queryParams] = useSearchParams()
  const versionParam = queryParams.get('version')

  const { isLoading, isError, data, error } = useTileLoader({
    eventId: event.id,
    tileId,
    version: versionParam,
  })
  const {
    isLoading: isImageLoading,
    isError: isImageError,
    data: { imageData } = {},
  } = useTileImageLoader(
    { eventId: event.id, tileId, version: versionParam },
    { enabled: hasImages },
  )

  useReduxTileLoaderEffects({
    tileId,
    isDefaultExpanded: viewConfig.isDefaultExpanded,
    isLoading,
    isError,
    error,
    data: data?.data,
    type: decisionPaperTileTypes.automatic,
    isEditableInMinorVersion,
    tileVersion: data?.version,
  })

  useReduxTileImageLoaderEffects({
    tileId,
    isDefaultLoader: !hasImages,
    isLoading: isImageLoading,
    isError: isImageError,
    data: imageData,
  })
}

DecisionPaperReduxStateAutomaticTileFrozenLoader.propTypes = {
  id: PropTypes.string.isRequired,
  viewConfig: PropTypes.shape({
    isDefaultExpanded: PropTypes.bool.isRequired,
  }).isRequired,
  hasImages: PropTypes.bool.isRequired,
}

export default DecisionPaperReduxStateAutomaticTileFrozenLoader
