import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const usePbbCustomServiceAllowedOperations = () =>
  useCamelizedResponse(
    useRequest({
      path: '/pbb-custom-service/allowed-operations',
      useCache: true,
      keys: ['pbbCustomService', 'permissions'],
    }),
  )

export default usePbbCustomServiceAllowedOperations
