import { ValueState } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import FormattedNumberInput from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/edit-mode-cells/FormattedNumberInput'
import isIntegerValid from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/edit-mode-cells/isIntegerValid'

const CustomerOptionsIntegerInput = ({ handleOnChange, value }) => {
  const handleInputUpdate = (number) => {
    const newValue = number ? Number(number) : ''
    handleOnChange({ value: newValue, isValid: isIntegerValid(newValue) })
  }
  const { t } = useTranslation('decisionPaper')

  return (
    <FormattedNumberInput
      onInput={handleInputUpdate}
      onBlur={(e) => handleInputUpdate(e.target.value)}
      value={value}
      valueState={isIntegerValid(value) ? ValueState.None : ValueState.Error}
      valueStateMessage={
        <span>{t('pages.deals.customerOptions-table.integer-label.hint.isInteger')}</span>
      }
      minimumFractionDigits={0}
      maximumFractionDigits={0}
    />
  )
}

CustomerOptionsIntegerInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleOnChange: PropTypes.func.isRequired,
}

export default CustomerOptionsIntegerInput
