import { useInfiniteRequest } from 'hooks/services/baseService'
import { useInfiniteRequestsWithTotalMemo } from 'hooks/services/queryHelper'

const useRequirementReminders = ({ requirementId }, { limit } = {}, options) => {
  const response = useInfiniteRequest({
    path: `/conditions/external/requirements/${requirementId}/reminders`,
    translated: true,
    useCache: true,
    keys: ['conditions', 'external', 'requirements', requirementId, 'reminders'],
    limit,
    options: {
      retry: 1,
      ...options,
    },
  })

  return useInfiniteRequestsWithTotalMemo(response, 'reminders')
}

export default useRequirementReminders
