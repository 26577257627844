import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import VersionedDisplayAndEditCard from 'components/ui/card/VersionedDisplayAndEditCard'
import VersionsHistoryButtonLoadingWrapper, {
  sortingFields,
} from 'components/ui/card/VersionsHistoryButtonLoadingWrapper'
import useContentVersions from 'components/ui/card/integrated-versioned-display-and-edit-card/useContentVersions'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useStaffMemberByObjectIdOrEmail'
import useTextEditorCurrentContent from 'hooks/text-editor/useTextEditorCurrentContent'

const IntegratedVersionedDisplayAndEditCard = ({
  getContentVersionsHook,
  getContentVersionsHookParameters,
  putContentUpdateHook,
  putContentUpdateHookParameters,
  cardTitle,
  hasEditPermission,
  id,
  sortingField,
}) => {
  const { t: tNoPrefix } = useTranslation('translation')

  const [displayedVersion, setDisplayedVersion] = useState(0)

  const onContentUpdateSuccess = ({ availableVersions = [] } = {}) => {
    const latestVersion = availableVersions[0]?.version
    setDisplayedVersion(latestVersion)
  }

  const {
    isLoading,
    isError,
    data: { availableVersions: contentVersions = [] } = {},
    mutate: updateContent,
    isUpdateError: isContentUpdateError,
    isUpdateSuccess: isContentUpdateSuccess,
  } = useContentVersions({
    getContentVersionsHook,
    getContentVersionsHookParameters,
    putContentUpdateHook,
    putContentUpdateHookParameters,
    onContentUpdateSuccess,
  })

  const latestVersion = useMemo(() => contentVersions[0]?.version, [contentVersions])

  const creationMetadata = useMemo(() => {
    const displayedEntry = contentVersions.find(({ version }) => version === displayedVersion)
    return {
      createdBy: displayedEntry?.createdBy ?? '',
      date: displayedEntry?.createdAt ?? '',
    }
  }, [displayedVersion, contentVersions])

  const { data: staffMember } = useStaffMemberByObjectIdOrEmail({
    objectIdOrEmail: creationMetadata?.createdBy,
  })
  const lastUpdatedBy = useMemo(
    () => staffMember?.fullName ?? creationMetadata?.createdBy,
    [staffMember, creationMetadata?.createdBy],
  )

  const getVersionContent = useCallback(
    (versionToShow) => {
      const versionContent = contentVersions.find(({ version }) => version === versionToShow)
      return versionContent?.content ?? ''
    },
    [contentVersions],
  )

  const { currentContent, setCurrentContent, hasChanges } = useTextEditorCurrentContent(
    getVersionContent(displayedVersion),
  )

  useEffect(() => {
    // latestVersion => most recent / most up-to-date version that is persisted
    // displayedVersion => the version displayed to the user / that served as the basis for the current editing
    // we set the displayedVersion to the latestVersion once the initial loading is finished
    setDisplayedVersion(latestVersion)
  }, [latestVersion])

  const handleSaveChanges = () => {
    updateContent({
      content: currentContent,
    })
  }

  const handleCancelChanges = () => {
    const originalContentOfDisplayedVersion = getVersionContent(displayedVersion)
    setCurrentContent(originalContentOfDisplayedVersion)
  }

  const handleBackToLatestVersionButtonClicked = useCallback(() => {
    setDisplayedVersion(latestVersion)
  }, [latestVersion])

  const handleVersionClicked = useCallback((selectedVersion) => {
    setDisplayedVersion(selectedVersion)
  }, [])

  const backToLatestVersionButton = useMemo(() => {
    const showBackToLatestVersionButton = !!displayedVersion && latestVersion > displayedVersion
    if (showBackToLatestVersionButton) {
      return (
        <Button
          design={ButtonDesign.Emphasized}
          key="back-to-latest-version-button"
          onClick={handleBackToLatestVersionButtonClicked}
        >
          {tNoPrefix('components.ui.buttons.versions-history.back-to-current-version.text')}
        </Button>
      )
    }
  }, [handleBackToLatestVersionButtonClicked, tNoPrefix, latestVersion, displayedVersion])
  const versionsHistoryButton = useMemo(
    () => (
      <VersionsHistoryButtonLoadingWrapper
        key="versions-history-button-wrapper"
        buttonId={`version-history-button-wrapper-${id}`}
        versionData={contentVersions.map((version) => ({
          ...version,
          lastUpdated: { name: version.createdBy, lastUpdatedOn: version.createdAt },
        }))}
        onItemClicked={handleVersionClicked}
        sortingField={sortingField}
      />
    ),
    [handleVersionClicked, contentVersions, id, sortingField],
  )
  const tileVersionActions = useMemo(
    () => [backToLatestVersionButton, versionsHistoryButton],
    [backToLatestVersionButton, versionsHistoryButton],
  )

  return (
    <LoadingStateWrapper isLoading={isLoading} isError={isError}>
      <VersionedDisplayAndEditCard
        id={id}
        tileText={cardTitle}
        currentContent={currentContent ?? ''}
        setCurrentContent={setCurrentContent}
        lastUpdatedBy={isEmpty(contentVersions) ? null : lastUpdatedBy}
        lastUpdatedAt={isEmpty(contentVersions) ? null : creationMetadata?.date}
        hasChanges={hasChanges}
        isEditEnabled={hasEditPermission}
        saveChanges={handleSaveChanges}
        cancelChanges={handleCancelChanges}
        saveHookIsSuccess={isContentUpdateSuccess}
        saveHookIsError={isContentUpdateError}
        additionalEditActions={tileVersionActions}
      />
    </LoadingStateWrapper>
  )
}

IntegratedVersionedDisplayAndEditCard.propTypes = {
  getContentVersionsHook: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  getContentVersionsHookParameters: PropTypes.object,
  putContentUpdateHook: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  putContentUpdateHookParameters: PropTypes.object,
  cardTitle: PropTypes.string.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  id: PropTypes.string,
  sortingField: PropTypes.oneOf(Object.values(sortingFields)),
}

export default IntegratedVersionedDisplayAndEditCard
