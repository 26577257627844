import camelize from 'camelize'
import isNil from 'lodash.isnil'
import { useBusinessPartnersByIds } from 'hooks/services/business-partners/getBusinessPartners'
import useMultiPropertiesKpis from 'hooks/services/properties/kpis/useMultiPropertiesKpis'
import { unitCodeIsPieces } from 'hooks/services/properties/useAreaUnitOfMeasureCodes'
import { useMultiplePropertiesByUuid } from 'hooks/services/properties/useMultiplePropertiesByUuid'
import { useRentalUnits } from 'hooks/services/properties/useRentalUnits'

const ANONYMOUS_TENANT = 'ANONYMOUS TENANT'

const getBusinessPartnerIdsToRentalUnitsMap = (rentalUnits = []) =>
  rentalUnits?.reduce((resultMap, rentalUnit) => {
    const tenantBusinessPartnerId = rentalUnit.tenant ? rentalUnit.tenant : ANONYMOUS_TENANT

    if (rentalUnit.propertyUuid && tenantBusinessPartnerId) {
      const rentalUnitsForBusinessPartner = resultMap.get(tenantBusinessPartnerId) ?? []
      rentalUnitsForBusinessPartner.push(rentalUnit)

      resultMap.set(tenantBusinessPartnerId, rentalUnitsForBusinessPartner)
    }

    return resultMap
  }, new Map())

const buildSubRow = ({
  propertiesForBusinessPartner,
  rentalUnitsForBusinessPartner,
  propertyRentRollKpis,
}) =>
  // each property builds a new row on second level
  propertiesForBusinessPartner.map((property) => {
    // find all rental units related to this property
    const rentalUnitsForProperty = rentalUnitsForBusinessPartner.filter(
      (rentalUnit) => rentalUnit.propertyUuid === property.uuid,
    )

    // sum up all values from the rental units for the specific property
    const rentedAreaValue = rentalUnitsForProperty.reduce(
      (sum, rentalUnit) => sum + (rentalUnit.areaInHeadQuarterMeasurementUnit?.value ?? 0),
      0,
    )
    const annualizedContractedRentValue = rentalUnitsForProperty.reduce(
      (sum, rentalUnit) => sum + (rentalUnit.rentContractedYearInHeadQuarterCurrency?.number ?? 0),
      0,
    )
    const parkingAreas = rentalUnitsForProperty.reduce((sum, rentalUnit) => {
      if (!unitCodeIsPieces(rentalUnit.area?.measurementUnit)) {
        return sum
      }
      return sum + (rentalUnit.area?.value ?? 0)
    }, 0)

    const rentRollKpisForProperty = propertyRentRollKpis.find(
      ({ propertyUuid }) => propertyUuid === property.uuid,
    )?.keyDateToRentRollKpis?.[0]?.kpis

    const propertyAnnualizedContractedRent =
      rentRollKpisForProperty?.annualizedContractedRent?.number

    const propertyTotalSurfaceArea = rentRollKpisForProperty?.totalAreaSurface?.value

    return {
      description: property.description,
      city: property.address?.cityName,
      id: property?.id,
      propertyUuid: property.uuid,
      areaPieces: parkingAreas,
      areaShare: propertyTotalSurfaceArea ? rentedAreaValue / propertyTotalSurfaceArea : undefined,
      rentedArea: {
        value: rentedAreaValue,
        measurementUnit: rentalUnitsForProperty.reduce(
          (measurementUnit, ru) =>
            measurementUnit ?? ru.areaInHeadQuarterMeasurementUnit?.measurementUnit,
          undefined,
        ),
      },
      annualizedContractedRentShare: propertyAnnualizedContractedRent
        ? annualizedContractedRentValue / propertyAnnualizedContractedRent
        : undefined,
      annualizedContractedRent: {
        value: annualizedContractedRentValue,
        currency: rentalUnitsForProperty.reduce(
          (currency, ru) => currency ?? ru.rentContractedYearInHeadQuarterCurrency?.currency,
          undefined,
        ),
      },
    }
  })

const buildTableData = ({
  businessPartnerToRentalUnitsArray,
  businessPartners,
  properties,
  propertyRentRollKpis,
}) =>
  // iterate per business partner which builds the top level rows of the table
  businessPartnerToRentalUnitsArray.map(([businessPartnerId, rentalUnitsForBusinessPartner]) => {
    const businessPartner = businessPartners.find(({ id }) => id === businessPartnerId)

    const propertyUuidsForBusinessPartner = new Set(
      rentalUnitsForBusinessPartner
        .map(({ propertyUuid }) => propertyUuid)
        .filter((propertyUuid) => !isNil(propertyUuid)),
    )

    const propertiesForBusinessPartner = properties.filter(
      ({ uuid }) =>
        propertyUuidsForBusinessPartner.has(uuid) &&
        rentalUnitsForBusinessPartner.some((rentalUnit) => rentalUnit.propertyUuid === uuid),
    )

    // build the sub rows summed up by the properties for a business partner
    const subRows = buildSubRow({
      propertiesForBusinessPartner,
      rentalUnitsForBusinessPartner,
      propertyRentRollKpis,
    })

    return {
      id: businessPartner?.id,
      isBusinessPartner: true,
      description: businessPartner?.fullName,

      rentedArea: {
        value: rentalUnitsForBusinessPartner.reduce(
          (sum, rentalUnit) => sum + (rentalUnit.areaInHeadQuarterMeasurementUnit?.value ?? 0),
          0,
        ),
        measurementUnit: rentalUnitsForBusinessPartner.reduce(
          (measurementUnit, rentalUnit) =>
            measurementUnit ?? rentalUnit.areaInHeadQuarterMeasurementUnit?.measurementUnit,
          undefined,
        ),
      },
      annualizedContractedRent: {
        value: rentalUnitsForBusinessPartner.reduce(
          (sum, rentalUnit) =>
            sum + (rentalUnit.rentContractedYearInHeadQuarterCurrency?.number ?? 0),
          0,
        ),
        currency: rentalUnitsForBusinessPartner.reduce(
          (currency, rentalUnit) =>
            currency ?? rentalUnit.rentContractedYearInHeadQuarterCurrency?.currency,
          undefined,
        ),
      },
      areaPieces: subRows.reduce((sum, subRow) => sum + (subRow.areaPieces ?? 0), 0),
      subRows,
    }
  })

export const useAsBrandingOrFranchiseProviderData = (
  businessPartnerId,
  role,
  hasRoleBrandOrFranchiseProvider,
) => {
  const {
    data: { rentalUnits, ...restOfRentalUnitsData } = {},
    isLoading: isLoadingRentalUnits,
    isError: isErrorRentalUnits,
  } = useRentalUnits(businessPartnerId, role, {
    enabled: hasRoleBrandOrFranchiseProvider,
  })

  // identify each unique business partner with function_type tenant/other and attach the related rental units to that BP
  const businessPartnerIdsToRentalUnitsMap = getBusinessPartnerIdsToRentalUnitsMap(rentalUnits)

  // identify all unique property UUIDs
  const propertyUuids = Array.from(
    new Set(
      Array.from(businessPartnerIdsToRentalUnitsMap?.values())
        .flat()
        .map((ru) => ru.propertyUuid),
    ),
  )

  // load information for all BPs
  const {
    data: businessPartners = [],
    isInitialLoading: isLoadingBusinessPartners,
    isError: isErrorBusinessPartners,
  } = useBusinessPartnersByIds(
    Array.from(businessPartnerIdsToRentalUnitsMap?.keys()).filter((id) => id !== ANONYMOUS_TENANT),
  )

  // load information for all properties
  const propertyResponses = useMultiplePropertiesByUuid(propertyUuids)
  const properties = propertyResponses
    .filter((response) => !!response.data)
    .map((response) => response.data)
  const isLoadingProperties = propertyResponses.some((response) => response.isLoading)
  const isErrorProperties = propertyResponses.some((response) => response.isError)

  // load KPIs/rent-roll for all properties
  const { data: propertyRentRollKpis = [] } = useMultiPropertiesKpis(propertyUuids)

  // convert BP-to-rental-units map into a two dimensional array for easier processing
  // array will be
  // [
  //   ["bp_1_id", [...rental_units_for_bp1]],
  //   ["bp_2_id", [...rental_units_for_bp2]],
  // ]
  const businessPartnerToRentalUnitsArray = Array.from(businessPartnerIdsToRentalUnitsMap.entries())

  // build the table based on the BP-to-rental-units-array and the preloaded information about BPs and properties
  const tableData = buildTableData({
    businessPartnerToRentalUnitsArray,
    businessPartners,
    properties,
    propertyRentRollKpis: camelize(propertyRentRollKpis),
  })

  return {
    tableData,
    isLoading: isLoadingRentalUnits || isLoadingProperties || isLoadingBusinessPartners,
    isError: isErrorRentalUnits || isErrorProperties || isErrorBusinessPartners,
    ...restOfRentalUnitsData,
  }
}
