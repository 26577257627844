import { Label, Text } from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'components/domains/properties/valuation/valuation-results/PropertyValuationResultsTable.module.css'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { useGenericValuationCreateMethodCodes } from 'hooks/services/properties/valuations/useGenericValuationCreateMethodCodes'
import { valuationMethodChanged } from 'redux/slices/properties/valuationResultsTableSlice'

const PropertyValuationResultsMethodCell = ({
  calculationMethodName,
  calculationMethodCode,
  isAddMode,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.valuation.results.table.form-fields.method-select',
  })
  const dispatch = useDispatch()

  const handleMethodChange = useCallback(
    ({ detail: { selectedOption } }) => {
      const newMethodCode = selectedOption?.getAttribute('data-id')
      dispatch(valuationMethodChanged({ calculationMethodCode: newMethodCode }))
    },
    [dispatch],
  )
  const handleBlur = useCallback(() => {
    dispatch(valuationMethodChanged({ calculationMethodCode: calculationMethodCode }))
  }, [calculationMethodCode, dispatch])

  const hasError = useSelector(
    (state) => state.properties.valuationResults.editedRow?.errorFields?.calculationMethodCode,
  )
  const isTouched = useSelector(
    (state) => state.properties.valuationResults.editedRow?.touchedFields?.calculationMethodCode,
  )
  const valuationCreateMethodCodesResult = useGenericValuationCreateMethodCodes({
    enabled: isAddMode,
  })
  const loadingHookResult = useMemo(
    () => ({ ...valuationCreateMethodCodesResult, isError: false }),
    [valuationCreateMethodCodesResult],
  )
  const isRequestError = valuationCreateMethodCodesResult.isError
  const loadingHook = useCallback(() => loadingHookResult, [loadingHookResult])

  const valueStateMessage = useMemo(() => {
    if (isRequestError) {
      return <Text>{t('request-error')}</Text>
    }
    if (hasError) {
      return <Text wrapping>{t('value-state.error')}</Text>
    }
  }, [hasError, isRequestError, t])

  if (!isAddMode) {
    return <Label>{calculationMethodName}</Label>
  }

  return (
    <>
      <LoadingSelect
        showEmptyOption={!calculationMethodCode}
        selectedKey={calculationMethodCode}
        loadingHook={loadingHook}
        onChange={handleMethodChange}
        optionKeyName="key"
        optionDisplayName="display_name"
        onBlur={handleBlur}
        valueState={(hasError && isTouched) || isRequestError ? ValueState.Error : ValueState.None}
        valueStateMessage={valueStateMessage}
      />
      {isRequestError && (
        <Text className={styles.valuationMethodRequestError}>{t('request-error')}</Text>
      )}
    </>
  )
}

PropertyValuationResultsMethodCell.propTypes = {
  calculationMethodName: PropTypes.string,
  calculationMethodCode: PropTypes.string,
  isAddMode: PropTypes.bool.isRequired,
}

export default PropertyValuationResultsMethodCell
