import { useCallback } from 'react'
import usePropertyTenantsChartData from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/current-rent/hooks/usePropertyTenantsChartData'
import { useTenantColors } from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/TenantColorContext'

/**
 * maximum amount of slices displayed in the donut chart,
 * larger amounts of tenants will be grouped
 */
export const DEFAULT_MAX_DISPLAYED_TENANTS = 4

/**
 * @typedef {object} UsePropertyTenantsPieChartDataOptions
 * @property {object} propertyKpiData the data to process
 * @property {function} getMetricFromTenantKpi function to extract the value from a tenant
 * @property {number} [maxDisplayedTenants]
 *
 * @param {UsePropertyTenantsPieChartDataOptions} options
 */
const usePropertyTenantsPieChartData = ({
  propertyKpiData,
  businessPartnerData,
  getMetricFromTenantKpi,
  maxDisplayedTenants = DEFAULT_MAX_DISPLAYED_TENANTS,
}) => {
  const { getColorForId, getGroupedColor } = useTenantColors()

  /**
   *  reduces an array of tenant KPIs into a single
   * chart data point with the sum of the rent of the
   * individual tenants and the name "Other ([tenantsCount])"
   */
  const groupedTenantsKpisToChartDataPoint = useCallback(
    (tenantKpis) => ({
      value: tenantKpis.reduce(
        (sumTenantsMetric, tenant) => sumTenantsMetric + getMetricFromTenantKpi(tenant),
        0,
      ),
      color: getGroupedColor(),
    }),
    [getGroupedColor, getMetricFromTenantKpi],
  )

  /**
   *  transforms a tenant KPIs entry into a chart data point
   */
  const tenantKpiToChartDataPoint = useCallback(
    (tenantKpi) => ({
      value: getMetricFromTenantKpi(tenantKpi),
      tenantId: tenantKpi.tenant,
      color: getColorForId(tenantKpi.tenant),
    }),
    [getColorForId, getMetricFromTenantKpi],
  )

  return usePropertyTenantsChartData({
    propertyKpiData,
    businessPartnerData,
    getMetricFromTenantKpi,
    tenantKpiToChartDataPoint,
    groupedTenantsKpisToChartDataPoint,
    maxDisplayedTenants,
    removeTenantsWithMetricValueZero: true,
  })
}

export default usePropertyTenantsPieChartData
