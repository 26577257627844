import { useRegions } from 'hooks/services/business-partners/config/useRegions'
/**
 * wrapper for useRegions to be used with a LoadingDropdown
 *
 * - renames the fields
 * - removes one level of nesting in the response
 */
const useRegionsForLoadingDropdown = (...args) => {
  const result = useRegions(...args)
  return result.data
    ? {
        ...result,
        data: result.data.regions.map(({ id, name }) => ({
          code: id,
          displayName: name,
        })),
      }
    : result
}

export default useRegionsForLoadingDropdown
