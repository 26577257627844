/**
 * @param {string} path
 */
const registerServiceWorker = async (path) => {
  if ('serviceWorker' in navigator) {
    try {
      await navigator.serviceWorker.register(path, {
        scope: './',
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Service Worker Registration failed', error)
    }
  }
}

export default registerServiceWorker
