import {
  FlexBox,
  FlexBoxDirection,
  Input,
  InputType,
  Label,
  Link,
  LinkDesign,
  Option,
  Select,
  Text,
  TextArea,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessPartnerAutocompleteInput from 'components/domains/deals/creation-dialog/BusinessPartnerAutocompleteInput'
import styles from 'components/domains/properties/insurances/PropertyInsurancesTable.module.css'
import DatePickerWithoutMinWidth from 'components/ui/date-picker/DatePickerWithoutMinWidth'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import { rowKeyNewRow } from 'components/ui/tables/display-and-edit-table/constants'
import { useNumberFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'

const PropertyInsurancesTableConfig = ({
  insuranceTypes,
  allowedInsuranceTypes,
  currencyCodes,
  insurers,
  insurances,
  getCurrentFieldValue,
  getCurrentInsurer,
  updateEditRow,
  checkIsRowValid,
  checkIfAnythingChanged,
}) => {
  const { t: tPropertyInsurancesTable } = useTranslation('translation', {
    keyPrefix: 'pages.property-overview.insurances.table',
  })

  const insuranceSumRef = useRef(null)
  const insurancePremiumRef = useRef(null)

  const evaluateValueState = (val, translationPath) => {
    if (!val) {
      return { isError: true, errorMessage: tPropertyInsurancesTable(`error.${translationPath}`) }
    }
    return { isError: false }
  }

  const evaluateAmountState = (val) => {
    if (!val || val <= 0) {
      return { isError: true, errorMessage: tPropertyInsurancesTable('error.amount-insured') }
    }
    return { isError: false }
  }

  const insurerWasManuallEnteredAndIsNotEmpty = (insurer) =>
    insurer && !insurer.id && insurer.enteredString

  const evaluateInsurerState = (insurer) => {
    if (insurerWasManuallEnteredAndIsNotEmpty(insurer)) {
      return { isError: true, errorMessage: tPropertyInsurancesTable('error.insurer') }
    }
    return { isError: false }
  }

  const getInsuranceTypeNameByCode = (insuranceCode) =>
    insuranceTypes?.insuranceTypes.find(
      (insuranceType) => insuranceType.insuranceType === insuranceCode,
    )?.insuranceName

  const renderInsuranceSelect = (rowKey, originalValue) => {
    const valueStateObject = evaluateValueState(
      getCurrentFieldValue({ rowKey, originalValue, fieldName: 'insuranceType' }),
      'insurance-type',
    )
    return (
      <Select
        id={`${rowKey}-insuranceTypeSelect`}
        valueState={valueStateObject.isError ? ValueState.Error : ValueState.None}
        valueStateMessage={
          valueStateObject.errorMessage && <Text>{valueStateObject.errorMessage}</Text>
        }
        onChange={(event) =>
          updateEditRow(rowKey, 'insuranceType', event.detail.selectedOption.dataset.id)
        }
      >
        <Option key={'empty-Option'} data-id={''}>
          {''}
        </Option>
        {allowedInsuranceTypes?.insuranceTypes.map((insuranceType) => (
          <Option
            key={insuranceType.insuranceType}
            id={insuranceType.insuranceType + '_' + rowKey}
            data-id={insuranceType.insuranceType}
            selected={originalValue === insuranceType.insuranceType}
          >
            {insuranceType.insuranceName}
          </Option>
        ))}
      </Select>
    )
  }

  const renderDescriptionField = ({ rowKey, originalValue }) => {
    const maxChars = 30
    const currentDescription = getCurrentFieldValue({
      rowKey,
      originalValue,
      fieldName: 'description',
    })
    const currentDescriptionLength = currentDescription?.length ?? 0
    const subtext = `${maxChars - currentDescriptionLength} ${tPropertyInsurancesTable(
      'characters-remaining',
    )}`
    return (
      <FlexBox direction="Column">
        <TextArea
          maxlength="30"
          id={`textArea_${rowKey}`}
          rows="2"
          onInput={(event) => updateEditRow(rowKey, 'description', event.target.value)}
          value={currentDescription}
        />
        <Text className={styles.text}>{subtext}</Text>
      </FlexBox>
    )
  }

  const getCurrencyString = (amount, currency) => `${amount} ${currency}`

  const renderValueStateMessage = (valueStateObject, isInternallyValid = true) =>
    isInternallyValid && valueStateObject.isError ? (
      <Text>{valueStateObject.errorMessage}</Text>
    ) : null

  const renderInput = ({ rowKey, fieldName, originalValue, inputType, iconName }) => {
    const className = inputType === InputType.Text ? '' : styles['input-number']
    const currentVal = getCurrentFieldValue({ rowKey, originalValue, fieldName })
    let valueStateObject
    if (fieldName === 'insuranceSumAmount') {
      valueStateObject = evaluateAmountState(currentVal)
    } else if (fieldName === 'insurerRemarks') {
      valueStateObject = evaluateValueState(currentVal, 'policy-id')
    } else {
      valueStateObject = { isError: false }
    }
    let input
    if (fieldName === 'insuranceSumAmount' || fieldName === 'insurancePremiumAmount') {
      const formattedNumberInputRef =
        fieldName === 'insuranceSumAmount' ? insuranceSumRef : insurancePremiumRef
      input = (
        <FormattedNumberInput
          className={styles['input-number']}
          valueState={valueStateObject.isError ? ValueState.Error : ValueState.None}
          valueStateMessage={renderValueStateMessage(
            valueStateObject,
            formattedNumberInputRef?.current?.isValid,
          )}
          id={`${rowKey}-${fieldName}`}
          value={parseFloat(originalValue)}
          onChange={(parsedValue) => {
            updateEditRow(rowKey, fieldName, parsedValue, formattedNumberInputRef?.current?.isValid)
          }}
          icon={iconName && <Label>{iconName}</Label>}
          ref={formattedNumberInputRef}
        />
      )
    } else {
      input = (
        <Input
          className={className}
          valueState={valueStateObject.isError ? ValueState.Error : ValueState.None}
          valueStateMessage={renderValueStateMessage(valueStateObject)}
          id={`${rowKey}-${fieldName}`}
          value={originalValue}
          type={inputType}
          onChange={(event) => updateEditRow(rowKey, fieldName, event.target.value)}
          icon={iconName && <Label>{iconName}</Label>}
        />
      )
    }
    return input
  }

  const renderCurrencySelect = ({ rowKey, originalValue }) => {
    const currentCurrency = getCurrentFieldValue({
      rowKey,
      originalValue,
      fieldName: 'insuranceCurrency',
    })
    const valueStateObject = evaluateValueState(currentCurrency, 'currency')
    return (
      <Select
        id={`${rowKey}-currency`}
        valueState={valueStateObject.isError ? ValueState.Error : ValueState.None}
        valueStateMessage={renderValueStateMessage(valueStateObject)}
        onChange={(event) =>
          updateEditRow(rowKey, 'insuranceCurrency', event.detail.selectedOption.dataset.id)
        }
      >
        {currencyCodes.map((currencyCode) => (
          <Option
            key={currencyCode}
            data-id={currencyCode}
            id={`${currencyCode}_${rowKey}`}
            selected={currentCurrency === currencyCode}
          >
            {currencyCode}
          </Option>
        ))}
      </Select>
    )
  }

  const renderAmountInsured = ({ rowKey, fieldName, originalValue, originalCurrency }) => (
    <FlexBox justifyContent="SpaceBetween">
      <FlexBox alignItems="Start" className={styles['flex-amount']}>
        {renderInput({
          rowKey,
          fieldName,
          originalValue,
        })}
      </FlexBox>
      <FlexBox alignItems="End" className={styles['flex-currency']}>
        {renderCurrencySelect({ rowKey, originalValue: originalCurrency })}
      </FlexBox>
    </FlexBox>
  )

  const { format, localePattern } = useShortDateFormatter()
  const formatNumber = useNumberFormatter({ minimumFractionDigits: 2 })

  const renderDatePicker = ({ rowKey, fieldName, originalValue }) => {
    const currentVal = getCurrentFieldValue({ rowKey, fieldName, originalValue })
    const valueStateObject = evaluateValueState(currentVal, 'date')
    return (
      <DatePickerWithoutMinWidth
        className={styles['date-picker']}
        valueState={valueStateObject.isError ? ValueState.Error : ValueState.None}
        valueStateMessage={renderValueStateMessage(valueStateObject)}
        id={`${rowKey}-${fieldName}-datepicker`}
        placeholder={localePattern}
        formatPattern={localePattern}
        value={originalValue}
        onChange={(event) => {
          updateEditRow(rowKey, fieldName, event.detail.value)
        }}
      />
    )
  }

  const getBusinessPartnerById = (bpId) => insurers?.find((insurer) => insurer.id === bpId)

  const renderInsurerReadMode = (row) => {
    const insurer = getBusinessPartnerById(row['partyId'])
    return (
      <FlexBox direction={FlexBoxDirection.Column}>
        <Link
          design={LinkDesign.Emphasized}
          href={`/business-partners/${insurer?.id}`}
          target="_blank"
          rel="noreferrer"
        >
          {insurer?.fullName}
        </Link>
        <Text>{insurer?.id}</Text>
      </FlexBox>
    )
  }

  const renderInsurerInput = (rowKey, row) => {
    const currentInsurer = getCurrentInsurer(rowKey, row)
    const valueStateObject = evaluateInsurerState(currentInsurer)
    const formatDisplayValue = (insurer) => {
      const fullName = insurer?.fullName || ''
      const id = insurer?.id
      return fullName && id ? `${fullName} (${id})` : fullName
    }

    return (
      <BusinessPartnerAutocompleteInput
        id={'insurer_' + rowKey}
        className={styles['insurer']}
        defaultValue={formatDisplayValue(currentInsurer)}
        onInput={(event) => {
          updateEditRow(rowKey, 'insurer', { id: '', enteredString: event.target.value })
        }}
        onBusinessPartnerSelected={(insurerId, { name }) =>
          updateEditRow(rowKey, 'insurer', { id: insurerId, fullName: name })
        }
        valueState={valueStateObject.isError ? ValueState.Error : ValueState.None}
        valueStateMessage={renderValueStateMessage(valueStateObject)}
      />
    )
  }

  const columnDefinitions = [
    {
      columnKey: 'insurance-type',
      title: tPropertyInsurancesTable('insurance-type'),
    },
    {
      columnKey: 'description',
      title: tPropertyInsurancesTable('description'),
    },
    {
      columnKey: 'amount-insured',
      title: tPropertyInsurancesTable('amount-insured'),
      alignment: 'End',
    },
    {
      columnKey: 'insurance-premium-amount',
      title: tPropertyInsurancesTable('insurance-premium-amount'),
      alignment: 'End',
    },
    {
      columnKey: 'start-date',
      title: tPropertyInsurancesTable('start-date'),
      alignment: 'End',
    },
    {
      columnKey: 'expiry-date',
      title: tPropertyInsurancesTable('expiry-date'),
      alignment: 'End',
    },
    {
      columnKey: 'insurer',
      title: tPropertyInsurancesTable('insurer'),
      marginTop: 0,
    },
    {
      columnKey: 'policy-id',
      title: tPropertyInsurancesTable('policy-id'),
    },
  ]

  const isNotAllowedInsuranceType = (insuranceTypeCode) =>
    !allowedInsuranceTypes?.insuranceTypes?.some(
      (allowedInsuranceType) => allowedInsuranceType.insuranceType === insuranceTypeCode,
    )

  const tableData = _.orderBy(
    insurances,
    ['insuranceSumAmount', 'insuranceStartDate'],
    ['desc', 'desc'],
  ).map((row, index) => {
    const rowKey = `row_${index}`
    const isNotAllowedInsuranceTypeCode = isNotAllowedInsuranceType(row['insuranceType'])
    return {
      rowKey: rowKey,
      originalRowData: row,
      'insurance-type': {
        cellContentReadMode: getInsuranceTypeNameByCode(row['insuranceType']),
        cellContentEditMode: renderInsuranceSelect(rowKey, row['insuranceType']),
        cellEditModeProps: {
          className: styles['cell-align-top'],
        },
      },
      description: {
        cellContentReadMode: row['description'],
        cellContentEditMode: renderDescriptionField({
          rowKey,
          originalValue: row['description'],
        }),
      },
      'amount-insured': {
        cellContentReadMode: getCurrencyString(
          formatNumber(row['insuranceSumAmount']),
          row['insuranceCurrency'],
        ),
        cellContentEditMode: renderAmountInsured({
          rowKey: rowKey,
          fieldName: 'insuranceSumAmount',
          originalValue: row['insuranceSumAmount'],
          originalCurrency: row['insuranceCurrency'],
        }),
        cellEditModeProps: {
          className: styles['cell-align-top'],
        },
      },
      'insurance-premium-amount': {
        cellContentReadMode: getCurrencyString(
          formatNumber(row['insurancePremiumAmount']),
          row['insuranceCurrency'],
        ),
        cellContentEditMode: renderInput({
          rowKey: rowKey,
          fieldName: 'insurancePremiumAmount',
          originalValue: row['insurancePremiumAmount'],
          inputType: InputType.Number,
          iconName: getCurrentFieldValue({
            rowKey,
            originalValue: row['insuranceCurrency'],
            fieldName: 'insuranceCurrency',
          }),
        }),
        cellEditModeProps: {
          className: styles['cell-align-top'],
        },
      },
      'start-date': {
        cellContentReadMode: format(row['insuranceStartDate'], localePattern),
        cellContentEditMode: renderDatePicker({
          rowKey: rowKey,
          originalValue: row['insuranceStartDate'],
          fieldName: 'insuranceStartDate',
        }),
        cellEditModeProps: {
          className: styles['cell-align-top'],
        },
      },
      'expiry-date': {
        cellContentReadMode: format(row['insuranceExpiryDate'], localePattern),
        cellContentEditMode: renderDatePicker({
          rowKey: rowKey,
          originalValue: row['insuranceExpiryDate'],
          fieldName: 'insuranceExpiryDate',
        }),
        cellEditModeProps: {
          className: styles['cell-align-top'],
        },
      },
      insurer: {
        cellContentReadMode: renderInsurerReadMode(row),
        cellContentEditMode: renderInsurerInput(rowKey, row),
        cellEditModeProps: {
          className: styles['cell-align-top'],
        },
      },
      'policy-id': {
        cellContentReadMode: row['insurerRemarks'],
        cellContentEditMode: renderInput({
          rowKey: rowKey,
          fieldName: 'insurerRemarks',
          originalValue: row['insurerRemarks'],
          inputType: InputType.Text,
        }),
        cellEditModeProps: {
          className: styles['cell-align-top'],
        },
      },
      isValid: checkIsRowValid(rowKey, row),
      hasChanges: checkIfAnythingChanged(rowKey, row),
      hideEditButton: isNotAllowedInsuranceTypeCode,
      hideDeleteButton: isNotAllowedInsuranceTypeCode,
    }
  })

  const newRow = {
    rowKey: rowKeyNewRow,
    'insurance-type': {
      cellContentEditMode: renderInsuranceSelect(rowKeyNewRow, ''),
      cellEditModeProps: {
        className: styles['cell-align-top'],
      },
    },
    description: {
      cellContentEditMode: renderDescriptionField({
        rowKey: rowKeyNewRow,
        fieldName: 'description',
        originalValue: '',
        inputType: InputType.Text,
      }),
    },
    'amount-insured': {
      cellContentEditMode: renderAmountInsured({
        rowKey: rowKeyNewRow,
        originalValue: '',
        fieldName: 'insuranceSumAmount',
        originalCurrency: '',
      }),
      cellEditModeProps: {
        className: styles['cell-align-top'],
      },
    },
    'insurance-premium-amount': {
      cellContentEditMode: renderInput({
        rowKey: rowKeyNewRow,
        row: {},
        fieldName: 'insurancePremiumAmount',
        originalValue: '',
        inputType: InputType.Number,
        iconName: getCurrentFieldValue({
          rowKey: rowKeyNewRow,
          originalValue: '',
          fieldName: 'insuranceCurrency',
        }),
      }),
      cellEditModeProps: {
        className: styles['cell-align-top'],
      },
    },
    'start-date': {
      cellContentEditMode: renderDatePicker({
        rowKey: rowKeyNewRow,
        originalValue: '',
        fieldName: 'insuranceStartDate',
      }),
      cellEditModeProps: {
        className: styles['cell-align-top'],
      },
    },
    'expiry-date': {
      cellContentEditMode: renderDatePicker({
        rowKey: rowKeyNewRow,
        originalValue: '',
        fieldName: 'insuranceExpiryDate',
      }),
      cellEditModeProps: {
        className: styles['cell-align-top'],
      },
    },
    insurer: {
      cellContentEditMode: renderInsurerInput(rowKeyNewRow, {}),
      cellEditModeProps: {
        className: styles['cell-align-top'],
      },
    },
    'policy-id': {
      cellContentEditMode: renderInput({
        rowKey: rowKeyNewRow,
        row: {},
        fieldName: 'insurerRemarks',
        originalValue: '',
        inputType: InputType.Text,
      }),
      cellEditModeProps: {
        className: styles['cell-align-top'],
      },
    },
    isValid: checkIsRowValid(rowKeyNewRow, {}),
  }

  return { columnDefinitions, tableData, newRow }
}

PropertyInsurancesTableConfig.propTypes = {
  insuranceTypes: PropTypes.shape({
    insuranceTypes: PropTypes.arrayOf(
      PropTypes.shape({
        insuranceCategory: PropTypes.string,
        insuranceName: PropTypes.string.isRequired,
        insuranceType: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  currencyCodes: PropTypes.array.isRequired,
  insurers: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      id: PropTypes.string.isRequired,
    }),
  ),
  insurances: PropTypes.arrayOf(
    PropTypes.shape({
      insuranceUuid: PropTypes.string.isRequired,
      description: PropTypes.string,
      insuranceCurrency: PropTypes.string,
      insurancePremiumAmount: PropTypes.number,
      insuranceSumAmount: PropTypes.number,
      insuranceType: PropTypes.string,
      insuranceStartDate: PropTypes.string,
      insuranceExpiryDate: PropTypes.string,
      insurerRemarks: PropTypes.string,
      partyId: PropTypes.string,
    }),
  ),
  getCurrentFieldValue: PropTypes.func.isRequired,
  getCurrentInsurer: PropTypes.func.isRequired,
  updateEditRow: PropTypes.func.isRequired,
  checkIsRowValid: PropTypes.func.isRequired,
  checkIfAnythingChanged: PropTypes.func.isRequired,
}

export default PropertyInsurancesTableConfig
