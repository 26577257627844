const editComponentTypes = Object.freeze({
  Input: 'Input',
  FormattedNumberInput: 'FormattedNumberInput',
  TextArea: 'TextArea',
  Select: 'Select',
  CheckBox: 'CheckBox',
  DatePicker: 'DatePicker',
  DatePickerWithoutMinWidth: 'DatePickerWithoutMinWidth',
  LoadingSelect: 'LoadingSelect',
})

export default editComponentTypes
