import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import { useTranslation } from 'react-i18next'
import {
  useShortDateFormatter,
  useNumberFormatter,
  useFormattedNumberParser,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/hooks/useI18n'
import editComponentTypes from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/card/editComponentTypes'
import { CUSTOM_FIELD_TYPES } from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/customfield/customFieldSchema'

/** @typedef {import("components/ui/customfield/customFieldSchema").CustomField} CustomField */
/** @typedef {import("components/ui/customfield/customFieldSchema").CustomFieldMetadata} CustomFieldMetadata */
/** @typedef {CustomField & CustomFieldMetadata} joined */

/** @typedef {Parameters<import('components/ui/card/DisplayAndEditCard').default>[0]['fieldDefinitions'][0] | {rawValue: CustomField['value']}} fieldDefinition */

const EMPTY_VALUE = '-'

/**
 * @param {boolean?} value
 * @param {(key: string) => string} t
 */
const formatBoolean = (value, t) => {
  switch (value) {
    case true:
      return t('yes')
    case false:
      return t('no')

    default:
      return ''
  }
}

const SHOW_MULTI_LINE_INPUT_THRESHOLD = 100

/** @param {joined} field */
const stringField = (field) => {
  const shouldRenderAsMultiLine = field.maxLength >= SHOW_MULTI_LINE_INPUT_THRESHOLD

  return /** @satisfies {fieldDefinition} */ ({
    name: field.key,
    label: field.displayName,
    value: /** @type {string} */ (field.value ?? ''),
    formattedValue: field.value ?? EMPTY_VALUE,
    rawValue: field.value,
    editComponentType: shouldRenderAsMultiLine
      ? editComponentTypes.TextArea
      : editComponentTypes.Input,
    editComponentProps: {
      maxLength: shouldRenderAsMultiLine ? field.maxLength : undefined,
    },
    isShownInDisplay: field.display,
    isShownInEdit: field.display,
  })
}

/** @param {boolean?} value */
const booleanToKey = (value) => {
  if (value === true) return 'true'
  if (value === false) return 'false'
  return ''
}

/**
 * @param {joined} field
 * @param {(key: string) => string} t
 */
const booleanField = (field, t) =>
  /** @satisfies {fieldDefinition} */ ({
    name: field.key,
    label: field.displayName,
    value: booleanToKey(/** @type {boolean?} */ (field.value)),
    rawValue: field.value,
    formattedValue: isNil(field.value)
      ? EMPTY_VALUE
      : formatBoolean(/** @type {boolean} */ (field.value), t),
    editComponentType: editComponentTypes.Select,
    editComponentSelectOptions: [
      { key: '', display_name: '' },
      { key: 'true', display_name: t('yes') },
      { key: 'false', display_name: t('no') },
    ],
    isShownInDisplay: field.display,
    isShownInEdit: field.display,
  })

/**
 * @param {joined} field
 * @param {(date: string) => string} formatDate
 */
const dateField = (field, formatDate) =>
  /** @satisfies {fieldDefinition} */ ({
    name: field.key,
    label: field.displayName,
    value: formatDate(/** @type {string?} */ (field.value) ?? ''),
    formattedValue: field.value ? formatDate(/** @type {string} */ (field.value)) : EMPTY_VALUE,
    rawValue: field.value,
    editComponentType: editComponentTypes.DatePicker,
    isShownInDisplay: field.display,
    isShownInEdit: field.display,
  })

/** @param {joined} field */
const enumField = (field) => {
  let enumValues = field.enumValues.map(({ code, displayName }) => ({
    key: code,
    display_name: displayName,
  }))
  if (!enumValues.some(({ key }) => key === '')) {
    enumValues = [{ key: '', display_name: '' }, ...enumValues]
  }

  return /** @satisfies {fieldDefinition} */ ({
    name: field.key,
    label: field.displayName,
    value: /** @type {string?} */ (field.value),
    rawValue: field.value,
    formattedValue:
      field.enumValues.find(({ code }) => code === field.value)?.displayName ??
      /** @type {string?} */ (field.value ?? EMPTY_VALUE),
    isShownInDisplay: field.display,
    isShownInEdit: field.display,
    editComponentType: editComponentTypes.Select,
    editComponentSelectOptions: enumValues,
  })
}

/**
 * @param {joined} field
 * @param {(input: number) => string} formatNumber
 */
const numberField = (field, formatNumber) =>
  /** @satisfies {fieldDefinition} */ ({
    name: field.key,
    label: field.displayName,
    value: formatNumber(/** @type {number} */ (field.value)),
    formattedValue: !isNil(field.value)
      ? formatNumber(/** @type {number} */ (field.value))
      : EMPTY_VALUE,
    rawValue: field.value,
    editComponentType: editComponentTypes.FormattedNumberInput,
    isShownInDisplay: field.display,
    isShownInEdit: field.display,
  })

/** @param {joined} field */
const integerField = (field) =>
  /** @satisfies {fieldDefinition} */ ({
    name: field.key,
    label: field.displayName,
    value: /** @type {number} */ (field.value),
    formattedValue: /** @type {number} */ (field.value ?? EMPTY_VALUE),
    rawValue: field.value,
    editComponentType: editComponentTypes.FormattedNumberInput,
    editComponentProps: {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    },
    isShownInDisplay: field.display,
    isShownInEdit: field.display,
  })

/**
 * Returns a function that parses a custom field value based on its type.
 *
 * Empty values become `null`.
 */
export const useParseCustomFieldValue = () => {
  const { parse: parseDate, localePattern } = useShortDateFormatter()
  const parseNumber = useFormattedNumberParser()
  /**
   * @param {any} value
   * @param {string} [type]
   */
  return (value, type) => {
    switch (type) {
      case CUSTOM_FIELD_TYPES.DATE:
        return parseDate(value, localePattern)
      case CUSTOM_FIELD_TYPES.ENUM:
      case CUSTOM_FIELD_TYPES.STRING:
      case CUSTOM_FIELD_TYPES.INTEGER:
        return value || null
      case CUSTOM_FIELD_TYPES.NUMBER:
        return parseNumber(value)
      case CUSTOM_FIELD_TYPES.BOOLEAN:
        if (value === 'true') return true
        if (value === 'false') return false
        return null
      default:
        return value
    }
  }
}

/**
 * @param {object} params
 * @param {CustomField[]} params.data
 * @param {CustomFieldMetadata[]} params.config
 */
const useCustomFieldDefinitions = ({ data, config }) => {
  const { t } = useTranslation('decisionPaper', { keyPrefix: 'formatters.boolean' })
  const { format: formatDate } = useShortDateFormatter()
  const formatNumber = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const joined = compact(
    data.map((field) => {
      const fieldConfig = config.find(({ key }) => key === field.key)
      if (!fieldConfig) return undefined

      return { ...field, ...fieldConfig }
    }),
  )

  return joined.map((field) => {
    switch (field.type) {
      case CUSTOM_FIELD_TYPES.STRING:
        return stringField(field)
      case CUSTOM_FIELD_TYPES.BOOLEAN:
        return booleanField(field, t)
      case CUSTOM_FIELD_TYPES.DATE:
        return dateField(field, formatDate)
      case CUSTOM_FIELD_TYPES.ENUM:
        return enumField(field)
      case CUSTOM_FIELD_TYPES.NUMBER:
        return numberField(field, formatNumber)
      case CUSTOM_FIELD_TYPES.INTEGER:
        return integerField(field)
      default:
        throw new Error(`Unknown field type: ${field.type}`)
    }
  })
}

export default useCustomFieldDefinitions
