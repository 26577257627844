import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * Get a staff member by their id
 */
const useStaffMemberById = (id) =>
  useCamelizedResponse(
    useRequest({
      path: `/users/${id}`,
      options: { enabled: !!id },
    }),
  )

export default useStaffMemberById
