import cloneDeep from 'lodash.clonedeep'
import groupBy from 'lodash.groupby'

const availableValuationTypes = [
  { cwpCode: 'MARKET_VALUE', calculationMethodCategory: 'MARKET_VALUE' },
  { cwpCode: 'MORTGAGE_LENDING_VALUE', calculationMethodCategory: 'MORTGAGE_LENDING_VALUE' },
  { cwpCode: 'REINSTATEMENT_COST', calculationMethodCategory: 'OTHER' },
  { cwpCode: 'PURCHASE_PRICE', calculationMethodCategory: 'PURCHASE_PRICE' },
  { cwpCode: 'OTHER', calculationMethodCategory: 'OTHER' },
  { cwpCode: 'PRELIMINARY_MARKET_VALUE', calculationMethodCategory: 'MARKET_VALUE' },
  { cwpCode: 'SELL_OFF_MARKET_VALUE', calculationMethodCategory: 'MARKET_VALUE' },
  { cwpCode: 'CURRENT_STATE_MARKET_VALUE', calculationMethodCategory: 'MARKET_VALUE' },
  {
    cwpCode: 'PRELIMINARY_MORTGAGE_LENDING_VALUE',
    calculationMethodCategory: 'MORTGAGE_LENDING_VALUE',
  },
  {
    cwpCode: 'SELL_OFF_MORTGAGE_LENDING_VALUE',
    calculationMethodCategory: 'MORTGAGE_LENDING_VALUE',
  },
  {
    cwpCode: 'CURRENT_STATE_MORTGAGE_LENDING_VALUE',
    calculationMethodCategory: 'MORTGAGE_LENDING_VALUE',
  },
]
const categoryOrder = ['MARKET_VALUE', 'PURCHASE_PRICE', 'MORTGAGE_LENDING_VALUE', 'OTHER']

const sortCategories = ([keyA], [keyB]) => {
  const indexA = categoryOrder.indexOf(keyA)
  const indexB = categoryOrder.indexOf(keyB)
  return indexA - indexB
}

const REGULATORY_RELEVANT = 'RELEVANT'

const useFormatValuationData = ({
  valuationsData,
  valuationCreateMethodCodes,
  isDataLoading,
  propertyCurrencyCode,
}) => {
  if (isDataLoading) return []
  /**
   * Adds information to the valuation object.
   * externalMethodCode: valuation method code of the latest valuation or the create method code
   * isEditable: determines if the valuation is editable (if it is not regulatory relevant or
   * there is no external method code)
   *
   * @param {Object} valuation
   * @returns valuation object with additional information
   */
  const addInfoToValuation = (_valuation) => {
    const valuation = cloneDeep(_valuation)
    const isEditable = valuation.currentValue?.regulatoryRelevance !== REGULATORY_RELEVANT
    const createMethod = valuationCreateMethodCodes?.find(
      (code) => code.createMethodCode === valuation.cwpCode,
    )
    // Choose the external method code of the latest valuation if available or the create method code
    const updateMethodCode = valuation.currentValue?.externalCalculationMethodCode
    const updateMethodName = valuation.currentValue?.externalCalculationMethodName
    const externalMethodCode = updateMethodCode || createMethod?.key
    const externalMethodName = updateMethodName || createMethod?.displayName
    valuation.externalMethodCode = externalMethodCode
    valuation.externalMethodName = externalMethodName

    valuation.isEditable = isEditable && !!externalMethodCode
    return valuation
  }

  const getBasicValuation = (type) => ({
    currentValue: {
      calculationMethodCategory: type.calculationMethodCategory,
      valueAmount: { number: undefined, currency: propertyCurrencyCode },
    },
    cwpCode: type.cwpCode,
  })

  const getValuationsData = (valuations) => {
    const groupedValuations = groupBy(valuations, 'currentValue.calculationMethodCategory')
    delete groupedValuations.undefined
    return Object.entries(groupedValuations)
      .sort(sortCategories)
      .reduce((acc, [calculationMethodCategory, _valuations]) => {
        const subRow = {
          calculationMethodCategory,
          isSubsection: true,
        }
        const valuationsWithAdditionalInfo = _valuations.map(addInfoToValuation)
        return [...acc, subRow, ...valuationsWithAdditionalInfo]
      }, [])
  }

  /**
   * In edit mode, we need to show all valuation types, even if they are not present in valuationsData.
   * That's why we need to expand the valuationsData with the missing valuation types.
   *
   * @returns valuationsData with all available valuation types
   */
  const getValuationsDataForEditMode = () => {
    const expandedValuations = availableValuationTypes.reduce((acc, type) => {
      const valuation = valuationsData?.cwpValuations.find((v) => v.cwpCode === type.cwpCode)
      const valuationWithAdditionalInfo = valuation
        ? addInfoToValuation(valuation)
        : addInfoToValuation(getBasicValuation(type))
      return [...acc, valuationWithAdditionalInfo]
    }, [])
    return getValuationsData(expandedValuations)
  }

  return {
    valuationsForReadMode: getValuationsData(valuationsData?.cwpValuations),
    valuationsForEditMode: getValuationsDataForEditMode(),
  }
}

export default useFormatValuationData
