import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import UnitMembersTable from 'components/domains/business-partners/tile/relationships/UnitMembersTable'
import styles from 'components/domains/business-partners/tile/relationships/UnitTab.module.css'
import { capitalizeStringWithExclusion } from 'components/domains/util/capitalizeStringWithExclusion'
import Card from 'components/ui/card/Card'
import Entity from 'components/ui/data/Entity'

const excludeRelationshipWords = ['of', 'the']

const UnitTabCardHeader = ({ type, name, id }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'components.business-partner.units.header' })
  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.header}>
      <div className={styles.unitType}>
        {capitalizeStringWithExclusion({
          string: type,
          exclusions: excludeRelationshipWords,
        })}
      </div>
      <Entity
        name={name}
        id={t('id', { id })}
        link={`/business-partners/${id}`}
        className={styles.entity}
      />
    </FlexBox>
  )
}

UnitTabCardHeader.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

const UnitTab = ({ head, members, type }) => (
  <Card
    header={<UnitTabCardHeader type={type} name={head.fullName} id={head.id} />}
    className={styles.card}
  >
    <div className={styles.cardContent}>
      <UnitMembersTable members={members} className={styles.unitMembersTable} />
    </div>
  </Card>
)

UnitTab.propTypes = {
  head: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
  }).isRequired,
  members: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  type: PropTypes.string.isRequired,
}

export default UnitTab
