import { MessageBox, MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import Card from 'components/ui/card/Card'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import styles from 'components/ui/card/VersionedDisplayAndEditCard.module.css'
import VersionedDisplayAndEditCardHeader from 'components/ui/card/VersionedDisplayAndEditCardHeader'
import CollapsibleRichTextView from 'components/ui/rich-text/CollapsibleRichTextView'
import CWPCKEditor, { emptyContent } from 'components/ui/rich-text-editor/CWPCKEditor'
import { getRawCharactersCount } from 'hooks/text-editor/useTextEditorCurrentContent'
import { useLastEditedText } from 'routes/deals/financing/utils/useLastEditedText'

/**
 * Component to render a rich text card with display and edit functionality,
 * and also a version history through a separate card header.
 *
 * @param tileText header text of the card
 * @param currentContent content to display (either in edit or view)
 * @param setCurrentContent setter of current content for edit mode
 * @param lastUpdatedBy author of the last updated change, usually name or email address
 * @param lastUpdatedAt ISO 8601 timestamp
 * @param hasChanges true if edit content changed
 * @param showSubtitle whether or not to display a subtitle under the header title
 * @param isEditEnabled
 * @param additionalEditActions array of version change related components
 * @param saveChanges function to save changes made in edit mode
 * @param cancelChanges function to cancel changes made in edit mode
 * @param saveHookIsSuccess default undefined, true if save of new version was successful
 * @param saveHookIsError default false, true if save of new version was unsuccessful
 * @param formattingOptions object containing max character lengths for display and edit mode,
 * and formatting function for source/subtitle
 * @param id to identify the correct position for button popups if multiple VersionedDisplayAndEditCard are used.
 */
const VersionedDisplayAndEditCard = ({
  tileText,
  currentContent,
  setCurrentContent,
  lastUpdatedBy,
  lastUpdatedAt,
  hasChanges,
  showSubtitle = true,
  isEditEnabled,
  additionalEditActions,
  saveChanges,
  cancelChanges = () => {},
  saveHookIsSuccess = false,
  saveHookIsError = false,
  formattingOptions,
  id,
}) => {
  const { t } = useTranslation('translation')

  const { viewMaxCharacterAmount, editMaxCharacterAmount, subtitleFormatter } = {
    ...formattingOptions,
  }

  const [isEditMode, setIsEditMode] = useState(false)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [isSaveEnabled, setIsSaveEnable] = useState(true)

  const setCurrentRichTextContent = useCallback(
    (currentRichTextContent) => {
      const newCurrentContent =
        currentRichTextContent === emptyContent ? '' : currentRichTextContent

      setCurrentContent(newCurrentContent)
      setIsSaveEnable(
        !editMaxCharacterAmount ||
          getRawCharactersCount(newCurrentContent) <= editMaxCharacterAmount,
      )
    },
    [editMaxCharacterAmount, setCurrentContent],
  )

  const tileStatus = useMemo(
    () => ({
      isLoading: false,
      isError: false,
      data: {
        lastUpdatedBy,
        lastUpdatedAt,
      },
    }),
    [lastUpdatedAt, lastUpdatedBy],
  )

  useEffect(() => {
    if (saveHookIsError) {
      setIsErrorDialogOpen(true)
    } else if (saveHookIsSuccess) {
      setIsEditMode(false)
    }
  }, [saveHookIsError, saveHookIsSuccess, t])

  const handleErrorDialogClose = useCallback(() => setIsErrorDialogOpen(false), [])

  const handleEditClicked = useCallback(() => setIsEditMode(true), [])

  const handleSaveClicked = useCallback(() => {
    saveChanges()
  }, [saveChanges])

  const handleCancelClicked = useCallback(() => {
    setIsEditMode(false)
    cancelChanges()
  }, [cancelChanges])

  const header = useMemo(
    () =>
      tileStatus && (
        <VersionedDisplayAndEditCardHeader
          id={id}
          key="versioned-display-and-edit-card-header"
          tileText={tileText}
          tileStatus={tileStatus}
          isEditMode={isEditMode}
          isEditEnabled={isEditEnabled}
          onSaveClicked={handleSaveClicked}
          onCancelClicked={handleCancelClicked}
          onEditClicked={handleEditClicked}
          showLastEdited={isEditEnabled}
          hasChanges={hasChanges}
          isSaveEnabled={isSaveEnabled}
          showSubtitle={showSubtitle}
          subtitleFormatter={subtitleFormatter || useLastEditedText}
          additionalEditActions={additionalEditActions}
        />
      ),
    [
      tileStatus,
      tileText,
      isEditMode,
      isEditEnabled,
      handleSaveClicked,
      handleCancelClicked,
      handleEditClicked,
      hasChanges,
      isSaveEnabled,
      showSubtitle,
      subtitleFormatter,
      additionalEditActions,
      id,
    ],
  )

  const renderEditComponent = () => (
    <div className={[styles.editCardItem, styles.editor].join(' ')}>
      <CWPCKEditor
        setCurrentContent={setCurrentRichTextContent}
        currentContent={currentContent}
        maxCharacterAmount={editMaxCharacterAmount}
      />
    </div>
  )

  const renderDisplayComponent = () => {
    if (currentContent !== '') {
      return (
        <CollapsibleRichTextView text={currentContent} characterLimit={viewMaxCharacterAmount} />
      )
    }
    return (
      <EmptyCardContent
        title={t('components.cards.empty.title')}
        subtitle={t('components.cards.empty.subtitle')}
      />
    )
  }

  return (
    <Card header={header} style={{ gridColumn: 'span 2' }}>
      {isEditMode ? renderEditComponent() : renderDisplayComponent()}
      {isErrorDialogOpen &&
        createPortal(
          <MessageBox
            data-testid="save-error-dialog"
            type={MessageBoxTypes.Error}
            open={isErrorDialogOpen}
            onClose={handleErrorDialogClose}
          >
            {t('components.ui.card.versioned-display-edit-card.update.error.description')}
          </MessageBox>,
          document.body,
        )}
    </Card>
  )
}

VersionedDisplayAndEditCard.propTypes = {
  tileText: PropTypes.string.isRequired,
  currentContent: PropTypes.string,
  setCurrentContent: PropTypes.func.isRequired,
  lastUpdatedBy: PropTypes.string,
  lastUpdatedAt: PropTypes.string,
  hasChanges: PropTypes.bool.isRequired,
  showSubtitle: PropTypes.bool,
  isEditEnabled: PropTypes.bool.isRequired,
  saveChanges: PropTypes.func.isRequired,
  cancelChanges: PropTypes.func,
  saveHookIsSuccess: PropTypes.bool,
  saveHookIsError: PropTypes.bool,
  additionalEditActions: PropTypes.node,
  formattingOptions: PropTypes.shape({
    viewMaxCharacterAmount: PropTypes.number,
    editMaxCharacterAmount: PropTypes.number,
    subtitleFormatter: PropTypes.func,
  }),
  id: PropTypes.string,
}

export default VersionedDisplayAndEditCard
