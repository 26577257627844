import {
  FlexBox,
  MessageBox,
  MessageBoxTypes,
  Modals,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import 'components/domains/properties/general-information/image/edit/PropertyImageEdit.css'
import { map, pick } from 'lodash'
import PropTypes from 'prop-types'
import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import PropertyImageEditDialog from 'components/domains/properties/general-information/image/edit/PropertyImageEditDialog'
import PropertyImageEditTable from 'components/domains/properties/general-information/image/edit/PropertyImageEditTable'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useCreateImage } from 'hooks/services/properties/images/useCreateImage'
import {
  PROPERTIES_KEY,
  PROPERTY_IMAGES_KEY,
  usePropertyImages,
} from 'hooks/services/properties/images/usePropertyImages'
import { usePutPropertyImages } from 'hooks/services/properties/images/usePutPropertyImages'

const useStateCallback = (initialState) => {
  const [state, setState] = useState(initialState)
  const cbRef = useRef(null) // init mutable ref container for callbacks

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb // store current, passed callback in ref
    setState(state)
  }, []) // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cb.current is `null` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state)
      cbRef.current = null // reset callback after execution
    }
  }, [state])

  return [state, setStateCallback]
}

const PropertyImageEdit = ({ propertyUuid, onChange }) => {
  /**
   * This is a regex for a file name. It includes: letters (with umlauts & accents), numbers, underscores, dots, spaces and hyphens.
   * After the dot it allows only alphanumerics (file extension)
   */
  const FILE_NAME_PATTERN = '^[\\w\u00C0-\u017F\\- ]+(\\.[\\w]+)?$'
  const MOVE_UP_DIRECTION = -1
  const MOVE_DOWN_DIRECTION = 1
  const PROPERTY_IMAGES_ATTRIBUTES = useMemo(
    () => ({
      IMAGE_UUID: 'image_uuid',
      URL: 'url',
      META: 'meta',
    }),
    [],
  )
  const MAX_FILE_SIZE = 8000000
  const TOAST_DURATION = 5000

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.general-information.image.edit',
  })
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()

  const [errorMessage, setErrorMessage] = useState()
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [propertyImageUuidToUpload, setPropertyImageUuidToUpload] = useState()

  const { isLoading: isUploading, mutate: createImage } = useCreateImage({
    onSuccess: (response) => {
      queryClient.invalidateQueries([PROPERTIES_KEY, propertyUuid, PROPERTY_IMAGES_KEY])
      // after upload the image, we also need to connect the image to a property_image
      setPropertyImageUuidToUpload(response.uuid)
    },
    onError: (e) => {
      console.log('Image upload failed: ', e)
      setErrorMessage(t('upload-failed'))
      setShowErrorMessage(true)
    },
  })
  const {
    isLoading: isLoadingImages,
    isError: isErrorImages,
    data: propertyImagesFromHook,
  } = usePropertyImages(propertyUuid)

  const { mutate: putPropertyImages } = usePutPropertyImages({
    onSuccess: () => {
      queryClient.invalidateQueries([PROPERTIES_KEY, propertyUuid, PROPERTY_IMAGES_KEY])
    },
    onError: (e) => {
      console.log('Failed to put property images', e)
      setErrorMessage(t('put-failed'))
      setShowErrorMessage(true)
    },
  })

  const [propertyImages, setPropertyImages] = useStateCallback([])

  const [propertyImageUuidToDelete, setPropertyImageUuidToDelete] = useState()
  const [propertyImageToMove, setPropertyImageToMove] = useState(null)
  const [propertyImagesInEdit, setPropertyImagesInEdit] = useState([])
  const [propertyImagesDescriptionBeforeSave, setPropertyImagesDescriptionsBeforeSave] = useState(
    [],
  )

  const [reloadImages, setReloadImages] = useState(true)
  const [isInitialChange, setIsInitialChange] = useState(true)

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const generatePutCreatePayload = (propertyImagesFromHook, imageUuid) => {
    const payload = propertyImagesFromHook.map((current) => ({
      uuid: current.uuid,
      image_uuid: current.image_uuid,
    }))
    if (imageUuid) {
      payload.push({
        image_uuid: imageUuid,
      })
    }
    return payload
  }

  const generatePutDeletePayload = (propertyImagesFromHook, imageUuid) =>
    propertyImagesFromHook.filter((element) => element.uuid !== imageUuid)

  const generatePutUpdatePayload = (propertyImages) =>
    propertyImages.map((current) => ({
      uuid: current.uuid,
      image_uuid: current.image_uuid,
      description: current.description,
    }))

  const propertyImagesStateChangeCallback = useCallback(
    (state) => {
      if (!isInitialChange) {
        const putPayload = generatePutUpdatePayload(state)
        onChange(PROPERTY_IMAGES_KEY, putPayload)
      }
      setIsInitialChange(false)
    },
    [isInitialChange, onChange],
  )

  /**
   * Makes sure after each reload and merge that the property image order attributes are correct and ordered
   * @param propertyImages
   * @returns reordered property images
   */
  const sortAndSetPropertyImagesOrder = (propertyImages) =>
    propertyImages
      .sort((a, b) => a.property_image_order - b.property_image_order)
      .map((e, i) => {
        e.property_image_order = i
        return e
      })

  /**
   * This method merges the previous state with the updated results from the usePropertyImages hook.
   * It merges everything except the property_image_order and the description,
   * because these properties are saved after a click on the "Save"-Button.
   */
  const mergePropertyImageStateAndHookResults = useCallback(
    (imagesFromState) => {
      const result = map(propertyImagesFromHook, (propertyImageFromHook) => {
        const propertyImageFromState = imagesFromState.find(
          (imageFromState) => imageFromState?.uuid === propertyImageFromHook.uuid,
        )
        if (propertyImageFromState) {
          return {
            ...propertyImageFromState,
            ...pick(propertyImageFromHook, [
              PROPERTY_IMAGES_ATTRIBUTES.IMAGE_UUID,
              PROPERTY_IMAGES_ATTRIBUTES.URL,
              PROPERTY_IMAGES_ATTRIBUTES.META,
            ]),
          }
        } else {
          return propertyImageFromHook
        }
      })
      return sortAndSetPropertyImagesOrder(result)
    },
    [PROPERTY_IMAGES_ATTRIBUTES, propertyImagesFromHook],
  )

  /**
   * If the result of the usePropertyImages hook changes, the images state should be updated & merged
   */
  useEffect(() => {
    setReloadImages(true)
  }, [propertyImagesFromHook])

  /**
   * If the images are reloaded, the state must be merged with the new data
   */
  useEffect(() => {
    if (!isLoadingImages && !isErrorImages && reloadImages) {
      setPropertyImages(mergePropertyImageStateAndHookResults, (s) =>
        propertyImagesStateChangeCallback(s),
      )
      setReloadImages(false)
    }
  }, [
    propertyImagesStateChangeCallback,
    isErrorImages,
    isLoadingImages,
    mergePropertyImageStateAndHookResults,
    reloadImages,
    setPropertyImages,
  ])

  /**
   * If the images data is reloaded and there is a property image uploaded, then execute the put request
   */
  useEffect(() => {
    if (!isLoadingImages && !isErrorImages && propertyImageUuidToUpload) {
      const putPayload = generatePutCreatePayload(propertyImagesFromHook, propertyImageUuidToUpload)
      if (putPayload) {
        putPropertyImages({
          property_uuid: propertyUuid,
          property_images: putPayload,
        })
      }
      setPropertyImageUuidToUpload(undefined)
    }
  }, [
    propertyImagesFromHook,
    isLoadingImages,
    isErrorImages,
    propertyImageUuidToUpload,
    putPropertyImages,
    propertyUuid,
  ])

  const cachePreviousImageDescriptionOnEdit = (image) => {
    const currentUuid = image?.uuid
    if (currentUuid) {
      const previousImageDescription = propertyImagesDescriptionBeforeSave.find(
        (image) => image.uuid === currentUuid,
      )
      if (!previousImageDescription) {
        setPropertyImagesDescriptionsBeforeSave((prev) => [
          ...prev,
          { uuid: currentUuid, description: image?.description },
        ])
      } else {
        setPropertyImagesDescriptionsBeforeSave((prev) =>
          prev.map((previous) => {
            if (previous.uuid === currentUuid) {
              previous.description = image?.description
            }
            return previous
          }),
        )
      }
    }
  }

  const updatePropertyImageDescription = (uuid, description) => {
    setPropertyImages(
      (prev) =>
        prev.map((image) => {
          if (image.uuid === uuid) {
            image.description = description
          }
          return image
        }),
      (s) => propertyImagesStateChangeCallback(s),
    )
  }

  const handleOnToggleDescriptionEdit = (image) => {
    const currentUuid = image?.uuid
    if (currentUuid) {
      const status = propertyImagesInEdit.find((uuid) => uuid === currentUuid)
      if (!status) {
        setPropertyImagesInEdit((prev) => [...prev, currentUuid])
      } else {
        setPropertyImagesInEdit((prev) => prev.filter((uuid) => uuid !== currentUuid))
      }
      cachePreviousImageDescriptionOnEdit(image)
    }
  }

  const handleOnDescriptionChange = (currentImage, value) => {
    const currentUuid = currentImage?.uuid
    const edited = propertyImages.find((image) => image.uuid === currentUuid)
    if (edited) {
      updatePropertyImageDescription(currentUuid, value)
    }
  }

  const handleOnResetDescription = (currentImage) => {
    const currentUuid = currentImage?.uuid
    const previousImageDescription = propertyImagesDescriptionBeforeSave.find(
      (image) => image.uuid === currentUuid,
    )
    const editedImage = propertyImages.find((image) => image.uuid === currentUuid)
    if (editedImage && previousImageDescription) {
      updatePropertyImageDescription(currentUuid, previousImageDescription.description)
    }
    setPropertyImagesInEdit((prev) => prev.filter((uuid) => uuid !== currentUuid))
  }

  const handleOnOpenDeleteDialog = (image) => {
    setPropertyImageUuidToDelete(image.uuid)
    setShowDeleteDialog(true)
  }

  const handleOnSelectionChange = (event) => {
    const selectedRows = event?.detail?.selectedRows
    const selectedRow =
      Array.isArray(selectedRows) && selectedRows.length > 0 ? selectedRows[0] : null
    if (selectedRow) {
      const selectedUuid = selectedRow?.cells[0]?.innerText?.trim()
      const image = propertyImages.find((image) => image.uuid === selectedUuid)
      setPropertyImageToMove(image)
    }
  }

  const isMoveUpDisabled =
    propertyImageToMove === null || propertyImageToMove?.property_image_order === 0
  const isMoveDownDisabled =
    propertyImageToMove === null ||
    propertyImageToMove?.property_image_order === propertyImages.length - 1

  const moveImage = (image, direction) => {
    const { property_image_order } = image
    const affectedImages = propertyImages.filter(
      (image) => image.property_image_order === property_image_order + direction,
    )
    if (affectedImages.length > 0) {
      const affectedImage = affectedImages[0]
      setPropertyImages(
        (prev) =>
          prev.map((image) => {
            if (image.uuid === propertyImageToMove.uuid) {
              image.property_image_order = property_image_order + direction
            }
            if (image.uuid === affectedImage.uuid) {
              image.property_image_order = property_image_order
            }
            return image
          }),
        (s) => propertyImagesStateChangeCallback(s),
      )
    }
  }

  const handleOnMoveUp = () => {
    if (propertyImageToMove) {
      const { property_image_order } = propertyImageToMove
      if (property_image_order === 0) {
        return
      }
      moveImage(propertyImageToMove, MOVE_UP_DIRECTION)
    }
  }

  const handleOnMoveDown = () => {
    if (propertyImageToMove) {
      const { property_image_order } = propertyImageToMove
      if (property_image_order === propertyImages.length - 1) {
        return
      }
      moveImage(propertyImageToMove, MOVE_DOWN_DIRECTION)
    }
  }

  const hasCorrectFileName = (fileName) => fileName.match(FILE_NAME_PATTERN)

  const handleOnUploadImage = async (e) => {
    if (e.target.files.length > 0) {
      showToast({ children: <Text>{t('in-upload')}</Text>, duration: TOAST_DURATION })
      const file = e.target.files[0]
      e.target.value = null
      const normalizedFileName = file.name.normalize()
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage(t('file-size-exceeded'))
        setShowErrorMessage(true)
        return
      }
      if (!hasCorrectFileName(normalizedFileName)) {
        setErrorMessage(t('invalid-file-name'))
        setShowErrorMessage(true)
        return
      }
      const fileReader = new FileReader()
      fileReader.onload = (e) => {
        try {
          const binaryString = e.target.result
          const array = new Uint8Array(binaryString.length)
          for (let i = 0; i < binaryString.length; i++) {
            array[i] = binaryString.charCodeAt(i)
          }
          const blob = new Blob([array], { type: 'image/jpeg' })
          const createImagePayload = {
            data: blob,
            contentType: file.type,
            fileName: normalizedFileName,
          }
          createImage({
            image: createImagePayload,
          })
        } catch (error) {
          console.error(error)
        }
      }
      fileReader.readAsBinaryString(file)
    } else {
      showToast({ children: <Text>{t('no-files')}</Text>, duration: TOAST_DURATION })
    }
  }

  const handleOnDelete = () => {
    if (propertyImageUuidToDelete) {
      const putPayload = generatePutDeletePayload(propertyImagesFromHook, propertyImageUuidToDelete)
      if (putPayload) {
        putPropertyImages({
          property_uuid: propertyUuid,
          property_images: putPayload,
        })
        setPropertyImageUuidToDelete(undefined)
      }
    }
  }

  const handleOnErrorMessageClose = () => {
    setShowErrorMessage(false)
  }

  return (
    <LoadingStateWrapper
      isError={isErrorImages}
      isLoading={isLoadingImages}
      renderContent={() => (
        <FlexBox className="property-image-edit">
          <PropertyImageEditTable
            images={propertyImages}
            editStatuses={propertyImagesInEdit}
            onImageDescriptionChange={handleOnDescriptionChange}
            onToggleEdit={handleOnToggleDescriptionEdit}
            isMoveDownDisabled={isMoveDownDisabled}
            isMoveUpDisabled={isMoveUpDisabled}
            onMoveDown={handleOnMoveDown}
            onMoveUp={handleOnMoveUp}
            onSelectionChange={handleOnSelectionChange}
            onOpenDeleteDialog={handleOnOpenDeleteDialog}
            onResetImageDescription={handleOnResetDescription}
            onUploadImage={handleOnUploadImage}
            isUploading={isUploading}
          />
          {createPortal(
            <>
              <PropertyImageEditDialog
                showDeleteDialog={showDeleteDialog}
                setShowDeleteDialog={setShowDeleteDialog}
                onDelete={handleOnDelete}
              />
              <MessageBox
                id="error-message"
                style={{ width: '320px' }}
                type={MessageBoxTypes.Error}
                open={showErrorMessage}
                onClose={handleOnErrorMessageClose}
              >
                {errorMessage}
              </MessageBox>
            </>,
            document.body,
          )}
        </FlexBox>
      )}
    />
  )
}
PropertyImageEdit.propTypes = {
  propertyUuid: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default PropertyImageEdit
