import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { isNotFoundError } from 'api/requests'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultipleTranchesWithSubEntities = ({ dealUuids, dataSource, options = {} }) => {
  const queryParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams()

    if (dataSource) {
      urlSearchParams.append('dataSource', dataSource)
    }

    return urlSearchParams
  }, [dataSource])

  const paramsString = [...queryParams.keys()].length ? `?${queryParams.toString()}` : ''

  const responses = useRequestsMemo(
    useRequests({
      requests: dealUuids.map((dealUuid) => ({
        path: `/financing-structures/deals/${dealUuid}/tranches-with-sub-entities${paramsString}`,
        keys: ['deals', dealUuid, 'tranches', 'tranchesWithSubEntities', 'dataSource', dataSource],
      })),
      translated: true,
      useCache: true,
      options: { enabled: !isEmpty(dealUuids), ...options },
    }),
  )

  return useMemo(
    () =>
      responses.map((response, index) => {
        const embeddedOptionsNotCamelized = []

        if (response?.data?.tranches) {
          for (const tranche of response.data.tranches) {
            embeddedOptionsNotCamelized.push({
              trancheId: tranche?.tranche_id,
              options: tranche?.options,
            })
          }
        }

        return {
          ...response,
          isError: response?.error && isNotFoundError(response.error) ? false : response.isError,
          data: {
            dealUuid: dealUuids[index],
            data: camelize(response.data) ?? [],
            embeddedOptions: embeddedOptionsNotCamelized,
          },
        }
      }),
    [dealUuids, responses],
  )
}

export default useMultipleTranchesWithSubEntities
