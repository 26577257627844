import { FlexBox, FlexBoxDirection, Link, Text } from '@fioneer/ui5-webcomponents-react'
import { element, string } from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/message/TextWithExpandableDetail.module.css'

const textWithExpandableDetailProps = {
  className: string,
  text: string.isRequired,
  detail: element,
}

/**
 * move this to ui-components | https://fioneer.atlassian.net/browse/CWP-13200
 */
export const TextWithExpandableDetail = ({ className, text, detail, ...props }) => {
  const [showDetails, setShowDetails] = useState(false)
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.message.text-with-expandable-detail',
  })

  if (!detail) {
    return (
      <Text {...props} className={className}>
        {text}
      </Text>
    )
  }

  const onShowDetailClick = () => {
    setShowDetails(true)
  }

  const renderDetail = () => {
    if (showDetails) return detail
    return <Link onClick={onShowDetailClick}>{t('details')}</Link>
  }

  return (
    <FlexBox
      {...props}
      className={`${styles.container} ${className}`}
      fitContainer
      direction={FlexBoxDirection.Column}
    >
      <Text>{text}</Text>
      {renderDetail()}
    </FlexBox>
  )
}

TextWithExpandableDetail.propTypes = textWithExpandableDetailProps
