import { IllustrationMessageType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import EmptyCard from 'components/ui/card/EmptyCard'

const TileUnknown = ({ tileCode }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.tiles.unknown',
  })
  return (
    <EmptyCard
      cardHeaderTitle={t('header')}
      title={t('title', { tileCode })}
      subtitle={t('description')}
      showCardHeaderActions={false}
      illustrationName={IllustrationMessageType.UnableToLoad}
    />
  )
}

TileUnknown.propTypes = {
  tileCode: PropTypes.string.isRequired,
}

export default TileUnknown
