import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

export const useMultipleRiskForbearanceStatuses = (
  dealUuidsWithBusinessPartner = [],
  options = {},
) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: dealUuidsWithBusinessPartner.map(({ businessPartnerId }) => ({
        path: `/risk-monitoring/business-partners/${businessPartnerId}/forbearance/statuses`,
        keys: [
          'risk-monitoring',
          'business-partners',
          businessPartnerId,
          'forbearance',
          'statuses',
        ],
        useCache: true,
      })),
      translated: true,
      options: { ...options, enabled: !isEmpty(dealUuidsWithBusinessPartner) },
    }),
  )

  return useMemo(
    () =>
      responses.map((result, index) => ({
        ...result,
        data: { dealUuid: dealUuidsWithBusinessPartner[index]?.dealUuid, ...camelize(result.data) },
      })),
    [dealUuidsWithBusinessPartner, responses],
  )
}
