import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useManualTileVersions = ({ eventId, decisionPaperVersion, tileId }) => {
  const version = decisionPaperVersion ? decisionPaperVersion : 'current'
  return useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/decision-paper/versions/${version}/tiles/${tileId}/manual/versions`,
      useCache: true,
      keys: [
        'events',
        eventId,
        'decision-paper',
        'versions',
        version,
        'tiles',
        tileId,
        'manual',
        'versions',
      ],
    }),
  )
}

export default useManualTileVersions
