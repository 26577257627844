import isNil from 'lodash.isnil'
import { useMemo } from 'react'
import useGetEventDefaultAssignees from 'hooks/services/business-events-and-tasks/events/useGetEventDefaultAssignees'
import useStaffMemberByIds from 'hooks/services/business-partners/staff-members/useStaffMemberByIds'

const useGetEventDefaultAssigneeStaffMembers = ({ eventCode, entityType, entityId }) => {
  const {
    data: defaultAssigneeData,
    isLoading: isDefaultAssigneeDataLoading,
    isError: isDefaultAssigneeDataError,
    error: defaultAssigneeError,
  } = useGetEventDefaultAssignees({ eventCode, entityType, entityId })

  const defaultAssigneeIds = useMemo(() => {
    if (isNil(defaultAssigneeData)) {
      return []
    }
    return Array.from(
      new Set([
        defaultAssigneeData.eventDefaultAssignee,
        ...defaultAssigneeData.taskDefaultAssignees.map(
          (taskAssignee) => taskAssignee.taskDefaultAssignee,
        ),
      ]),
    ).filter((assigneeId) => !isNil(assigneeId))
  }, [defaultAssigneeData])

  const {
    data: defaultAssigneesUsersData,
    isLoading: isDefaultAssigneeUsersLoading,
    isError: isDefaultAssigneeUsersError,
    error: defaultAssigneesUsersError,
  } = useStaffMemberByIds(defaultAssigneeIds, {
    enabled: !isNil(defaultAssigneeData) && defaultAssigneeIds.length > 0,
  })
  const isDefaultAssigneesLoading =
    isDefaultAssigneeDataLoading || (isDefaultAssigneeUsersLoading && defaultAssigneeIds.length > 0)
  const isDefaultAssigneesError = isDefaultAssigneeDataError || isDefaultAssigneeUsersError
  const defaultAssigneesErrors = [defaultAssigneeError, defaultAssigneesUsersError].filter(
    (error) => !isNil(error),
  )

  const defaultAssignees = useMemo(() => {
    if (isNil(defaultAssigneeData) || isNil(defaultAssigneesUsersData)) {
      return undefined
    }
    if (defaultAssigneeIds.length === 0) {
      return { eventAssignee: undefined, taskAssignees: {} }
    }
    const defaultAssigneeId = defaultAssigneeData.eventDefaultAssignee
    return {
      eventAssignee: isNil(defaultAssigneeId)
        ? undefined
        : defaultAssigneesUsersData.find(({ id }) => id === defaultAssigneeId),
      taskAssignees: Object.fromEntries(
        defaultAssigneeData.taskDefaultAssignees.map(({ templateId, taskDefaultAssignee }) => [
          templateId,
          defaultAssigneesUsersData.find(({ id }) => id === taskDefaultAssignee),
        ]),
      ),
    }
  }, [defaultAssigneeData, defaultAssigneeIds.length, defaultAssigneesUsersData])
  return useMemo(
    () => ({
      isLoading: isDefaultAssigneesLoading,
      isError: isDefaultAssigneesError,
      data: defaultAssignees,
      errors: defaultAssigneesErrors,
    }),
    [defaultAssignees, defaultAssigneesErrors, isDefaultAssigneesError, isDefaultAssigneesLoading],
  )
}

export default useGetEventDefaultAssigneeStaffMembers
