import useFilterByCreator from 'components/domains/documents/useFilterByCreator'
import useFilters from 'components/ui/tables/sorted-tables/useFilters'

/**
 * @typedef {import('hooks/services/documents/documentsSchema').Document} Document
 *
 * @typedef {object} Filters
 * @property {string} [Filters.name]
 * @property {string} [Filters.type]
 * @property {string} [Filters.creator]
 */
/**
 * @param {object} params
 * @param {Filters} params.filters
 * @param {Document[]} params.documents
 */
const useFilteredDocuments = ({ filters, documents }) => {
  const { CONTAINS } = useFilters()
  const {
    isLoading,
    isError,
    filterDocuments: filterDocumentsByCreator,
  } = useFilterByCreator(documents)

  /** @param {Document} document */
  const filterByName = ({ name }) => {
    if (!filters.name) return true
    return CONTAINS.filterFunction({ searchValue: filters.name, cell: { value: name } })
  }

  /** @param {Document} document */
  const filterByType = ({ type }) => {
    if (!filters.type) return true
    return type === filters.type
  }

  const filteredDocuments = filterDocumentsByCreator(filters.creator).filter(
    (document) => filterByName(document) && filterByType(document),
  )

  return { isLoading, isError, documents: filteredDocuments }
}

export default useFilteredDocuments
