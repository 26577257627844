import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import { cagTypesSchema } from 'hooks/services/collaterals/cagTypesSchema'

export const useCollateralAgreementTypes = () => {
  const response = useRequest({
    path: '/collateral-agreements/value-helps/cag-types',
    translated: true,
    useCache: true,
    keys: ['collateral-agreements', 'cag-types'],
  })

  const parsed = useMemo(
    () => cagTypesSchema.optional().safeParse(camelize(response.data)),
    [response.data],
  )

  if (!parsed.success) return { ...response, data: undefined, isError: true, error: parsed.error }

  return { ...response, data: parsed.data }
}
