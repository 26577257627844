import z from 'zod'

export const ratingProcedureSchema = z.object({
  methodId: z.string(),
  methodTxt: z.string(),
  internal: z.boolean(),
  modifiable: z.boolean(),
})

export const ratingProceduresSchema = z.object({
  ratingProcedures: ratingProcedureSchema.array(),
})
