import { Option, Select, ObjectStatus, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  objectStatusForValuationStatus,
  valuationStatus,
} from 'api/property/valuation/valuationRequests'
import styles from 'components/domains/properties/valuation/table/table-cells/ValuationRequestCell.module.css'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'

const isEditStatusDisabled = (status, option) => {
  if (status === valuationStatus.externalTransferFailed) {
    return option !== valuationStatus.externalTransferFailed && option !== valuationStatus.created
  }
  if (status === valuationStatus.draft) {
    return option !== valuationStatus.draft && option !== valuationStatus.created
  }
  return option === valuationStatus.draft || option === valuationStatus.externalTransferFailed
}

/**
 *  @typedef {PropType.InferProps<typeof ValuationsRequestsTableStatusCell.propTypes>} ValuationsRequestsTableStatusCell
 */
const ValuationsRequestsTableStatusCell = ({
  isEditing,
  originalStatus,
  editStatus,
  onChange,
  externalErrorMessage,
}) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.valuation.requests.cells.status',
  })
  const showMessageBox = useShowMessageBox()
  const handleOnEditStatusChange = useCallback(
    ({
      detail: {
        selectedOption: { value: selectedStatus },
      },
    }) => {
      onChange(selectedStatus)
    },
    [onChange],
  )

  const onClickExternalFailedText = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      titleText: t('error.external-message.title'),
      children: t('error.external-message', { externalErrorMessage }),
    })
  }, [externalErrorMessage, showMessageBox, t])

  if (isEditing) {
    return (
      <Select
        id="edit-valuation-request-status"
        disabled={
          originalStatus === valuationStatus.completed ||
          originalStatus === valuationStatus.cancelled
        }
        onChange={handleOnEditStatusChange}
      >
        {Object.values(valuationStatus).map((value) => (
          <Option
            key={value}
            value={value}
            disabled={isEditStatusDisabled(originalStatus, value)}
            selected={editStatus === value}
          >
            {tNoPrefix(`valuation-requests.status.${value.toLowerCase()}`)}
          </Option>
        ))}
      </Select>
    )
  }

  if (originalStatus === valuationStatus.externalTransferFailed) {
    return (
      <Text className={styles.error} onClick={onClickExternalFailedText}>
        {tNoPrefix(
          `valuation-requests.status.${valuationStatus.externalTransferFailed.toLowerCase()}`,
        )}
      </Text>
    )
  }

  return (
    <ObjectStatus inverted state={objectStatusForValuationStatus[originalStatus]}>
      {tNoPrefix(`valuation-requests.status.${originalStatus.toLowerCase()}`)}
    </ObjectStatus>
  )
}

ValuationsRequestsTableStatusCell.propTypes = {
  originalStatus: PropTypes.oneOf(Object.values(valuationStatus)).isRequired,
  editStatus: PropTypes.oneOf(Object.values(valuationStatus)).isRequired,
  isEditing: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  externalErrorMessage: PropTypes.string,
}

export default ValuationsRequestsTableStatusCell
