import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionStageVoteOptions } from 'api/decision-process/decisionProcessApi'
import SemanticButton, { SemanticButtonDesign } from 'components/ui/button/SemanticButton'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import useIndividualVoteCheck from 'hooks/services/business-events-and-tasks/decision-process/stages/error-checks/useIndividualVoteCheck'
import useEditDecisionStageVoter from 'hooks/services/business-events-and-tasks/decision-process/stages/useEditDecisionStageVoter'

const DecisionStageApprovalVoterSignButton = ({
  decisionStageId,
  eventId,
  decisionStageVoterId,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'decision-stage.vote-options.voter.sign',
  })
  const { mutate: editDecisionStageVote } = useEditDecisionStageVoter()
  const showMessageBox = useShowMessageBox()

  const queriesToInvalidate = useMemo(() => ['events', eventId, 'decision-stages'], [eventId])

  const linkTo = useMemo(
    () =>
      `/business-events-and-tasks/business-events/${eventId}/decision-stages/${decisionStageId}`,
    [decisionStageId, eventId],
  )

  const { onSuccess } = useIndividualVoteCheck({
    successText: t('success.text'),
    successTitle: t('success.title'),
    queriesToInvalidate,
    linkTo,
  })

  const onSignError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('error'),
    })
  }, [showMessageBox, t])

  const onSignConfirm = useCallback(() => {
    editDecisionStageVote(
      {
        decisionStageId,
        eventId,
        id: decisionStageVoterId,
        voteStatusCode: decisionStageVoteOptions.sign,
      },
      { onSuccess, onError: onSignError },
    )
  }, [
    editDecisionStageVote,
    decisionStageId,
    eventId,
    decisionStageVoterId,
    onSuccess,
    onSignError,
  ])

  const onSign = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Confirm,
      titleText: t('action'),
      children: t('confirmation.text'),
      actions: [
        <Button key="button-sign" design={ButtonDesign.Emphasized} onClick={onSignConfirm}>
          {t('action')}
        </Button>,
        MessageBoxActions.Cancel,
      ],
    })
  }, [showMessageBox, onSignConfirm, t])

  return (
    <SemanticButton design={SemanticButtonDesign.Success} onClick={onSign}>
      {t('action')}
    </SemanticButton>
  )
}

DecisionStageApprovalVoterSignButton.propTypes = {
  eventId: PropTypes.string.isRequired,
  decisionStageId: PropTypes.string.isRequired,
  decisionStageVoterId: PropTypes.string.isRequired,
}

export default DecisionStageApprovalVoterSignButton
