import { DynamicPage } from '@fioneer/ui5-webcomponents-react'
import '@ui5/webcomponents-icons/dist/AllIcons.js'
import '@ui5/webcomponents/dist/Assets.js'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ArrangeLandingPageSections from 'components/domains/home/ArrangeLandingPageSections'
import Header from 'components/ui/page/Header'

const LandingPageDefault = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.home' })
  const pageHeader = useMemo(
    () => <Header title={t('title')} breadcrumbs={[{ text: t('breadcrumb'), href: '.' }]} />,
    [t],
  )

  return (
    <DynamicPage headerTitle={pageHeader} showHideHeaderButton={false}>
      <ArrangeLandingPageSections />
    </DynamicPage>
  )
}

export default LandingPageDefault
