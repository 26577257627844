import {
  ButtonDesign,
  FlexBox,
  FlexBoxDirection,
  MessageBoxTypes,
  Modals,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { eventStatus } from 'api/events/status'
import EventSearchInputAndDialog from 'components/domains/business-events-and-tasks/events/input/EventSearchInputAndDialog'
import { RISK_INDICATOR_TYPE_CODE_CATEGORIES } from 'components/domains/business-partners/tile/risk-monitoring/utils/typeCodes'
import LoadingButton from 'components/ui/button/LoadingButton'
import Labeled from 'components/ui/data/Labeled'
import Dialog, { DialogPrimaryButton, DialogSize } from 'components/ui/dialog/Dialog'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useLinkRiskMonitoringEvent from 'hooks/services/risk-monitoring/useLinkRiskMonitoringEvent'
import useTypeCodes from 'hooks/services/risk-monitoring/useTypeCodes'

const propTypes = {
  businessPartnerId: PropTypes.string,
  riskIndicatorGroupId: PropTypes.string,
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const LinkRiskMonitoringEventAction = ({ businessPartnerId, riskIndicatorGroupId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.risk-monitoring.risk-indicators.dialogs.link-risk-indicator',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const queryClient = useQueryClient()
  const showMessageBox = useShowMessageBox()

  const showToast = Modals.useShowToast()

  //const { fullName: businessPartnerName } = useContext(BusinessPartnerContext)

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(undefined)

  const {
    data: { typeCodes: filterEventTypes = [] } = {},
    isLoading,
    isError,
  } = useTypeCodes(RISK_INDICATOR_TYPE_CODE_CATEGORIES.INDICATOR_GROUP_FILTER_EVENT_TYPE)

  const customEventCodeFilterList = useMemo(
    () => filterEventTypes?.map(({ code, shortText } = {}) => ({ code, name: shortText })),
    [filterEventTypes],
  )

  const linkRiskMonitoringEvent = useLinkRiskMonitoringEvent({
    onSuccess: () => {
      queryClient.invalidateQueries([
        'risk-monitoring',
        'business-partners',
        businessPartnerId,
        'risk-indicator-groups',
      ])
      showToast(
        {
          children: t('success-toast.message'),
        },
        document.body,
      )
    },
    onError: () => {
      showMessageBox({ type: MessageBoxTypes.Error, children: tNoPrefix('error.default') })
    },
  })

  const handleOnOpenDialog = () => {
    setIsDialogOpen(true)
  }

  const handleOnCloseDialog = () => {
    setIsDialogOpen(false)
    setSelectedEvent(undefined)
  }

  const handleLinkConfirm = () => {
    if (!isNil(selectedEvent?.id)) {
      linkRiskMonitoringEvent.mutate({
        businessPartnerId,
        riskIndicatorGroupId,
        eventId: selectedEvent?.id,
      })
      setIsDialogOpen(false)
      setSelectedEvent(undefined)
    }
  }

  const handleOnChange = ({ id, displayId, name } = {}) => {
    setSelectedEvent({ id, displayId, name })
  }

  return (
    <>
      <LoadingButton
        isLoading={isLoading}
        isError={isError}
        design={ButtonDesign.Default}
        onClick={handleOnOpenDialog}
        renderContent={() => t('buttons.link')}
      />
      <Dialog
        open={isDialogOpen}
        headerText={t('title')}
        size={DialogSize.M}
        resizable={false}
        primaryButton={
          <DialogPrimaryButton
            id="link-risk-indicator-dialog-submit-button"
            design="Emphasized"
            onClick={handleLinkConfirm}
            disabled={!selectedEvent}
          >
            {tNoPrefix('buttons.link')}
          </DialogPrimaryButton>
        }
        onBeforeClose={handleOnCloseDialog}
      >
        <FlexBox direction={FlexBoxDirection.Column}>
          <Labeled label={t('event')} showColon vertical data-testid="reference-labeled">
            <EventSearchInputAndDialog
              onChange={handleOnChange}
              value={selectedEvent}
              isInputFiltered={true}
              isDialogInputOnly={true}
              isAllowedToEdit={true}
              initialFilterValues={{
                // Disable until further notice
                // entityType: entityTypes.businesspartner,
                // entityId: businessPartnerId,
                // entityName: businessPartnerName,
                status: [eventStatus.drafted, eventStatus.ongoing],
              }}
              customEventCodeFilterList={customEventCodeFilterList}
              //Disable until further notice
              //readOnlyFilters={['businessObject']}
            />
          </Labeled>
        </FlexBox>
      </Dialog>
    </>
  )
}

LinkRiskMonitoringEventAction.propTypes = propTypes

export default LinkRiskMonitoringEventAction
