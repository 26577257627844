import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  Icon,
  Label,
  MessageStrip,
  MessageStripDesign,
  ObjectStatus,
  SegmentedButton,
  SegmentedButtonItem,
  TableGrowingMode,
  TableRowType,
  WrappingType,
  FlexBoxJustifyContent,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import classNames from 'classnames'
import isNil from 'lodash.isnil'
import lodashOrderBy from 'lodash.orderby'
import PropType from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getObjectStatusForTaskStatus, taskStatus } from 'api/events/status'
import BusinessObjectLink from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import DueDateWarningIcon from 'components/domains/business-events-and-tasks/common/DueDateWarningIcon'
import AssigneeTableCell from 'components/domains/business-events-and-tasks/events/AssigneeTableCell'
import BusinessEventTableCell from 'components/domains/business-events-and-tasks/events/BusinessEventTableCell'
import styles from 'components/domains/business-events-and-tasks/tasks/table/TasksTable.module.css'
import TasksTableAssigneeCustomFilter from 'components/domains/business-events-and-tasks/tasks/table/TasksTableAssigneeCustomFilter'
import TasksTableCheckbox from 'components/domains/business-events-and-tasks/tasks/table/TasksTableCheckbox'
import TasksTableDescriptionCell from 'components/domains/business-events-and-tasks/tasks/table/TasksTableDescriptionCell'
import TasksTableIsMandatoryCustomFilter from 'components/domains/business-events-and-tasks/tasks/table/TasksTableIsMandatoryCustomFilter'
import TasksTableSelectAllCheckbox from 'components/domains/business-events-and-tasks/tasks/table/TasksTableSelectAllCheckbox'
import TasksWatchersButton from 'components/domains/business-events-and-tasks/tasks/watchers/TasksWatchersButton'
import useNavigate from 'components/ui/link/useNavigate'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'
import useCtrlOrMetaKeyPressed from 'components/useCtrlOrMetaPressed'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { resetTasksRowHasError, tasksRowHasError } from 'redux/slices/tasks/taskTableSlice'
import paths from 'routes/paths'

const columnKeyToSortingKeyMap = {
  name: 'combined_task_name',
  isMandatory: 'info.is_mandatory',
  description: 'info.description',
  event: 'event.info.name',
  eventEntityRefType: 'event_entity_ref',
  eventEntityRefName: 'event_entity_ref',
  comment: 'info.comment',
  originalDueDate: 'info.original_due_date',
  currentDueDate: 'info.current_due_date',
  statusText: 'status',
  assignee: 'info.assignee',
}

const ascending = 'asc'
const emptyValue = ''

const statusOrderingEnum = {
  CREATED: 10,
  ONGOING: 20,
  COMPLETED: 30,
  ABORTED: 40,
}

const statusColumnKey = 'statusText'

const TasksTable = ({
  tasks,
  onSortingChanged,
  sortBy,
  orderBy,
  showFilterInToolbar = false,
  onLoadMore,
  maximumNumberOfTasks,
  maximumNumberOfMyTasks,
  sortingDisabled = false,
  shownColumns = [
    'checkbox',
    'name',
    'assignee',
    'isMandatory',
    'description',
    'event',
    'eventEntityRefType',
    'eventEntityRefName',
    'comment',
    'originalDueDate',
    'currentDueDate',
    'statusText',
    'watchers',
  ],
  noDataText,
  additionalActions,
  currentUserId,
  myTasksLoading,
  useBackendOrdering = true,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.tasks.table' })
  const { t: eventEntityTypeTranslation } = useTranslation('translation', {
    keyPrefix: 'events.entity-type',
  })

  const { t: taskStatusTranslation } = useTranslation()
  const { format } = useShortDateFormatter()
  const navigate = useNavigate()
  const [queryParams] = useSearchParams()
  const dispatch = useDispatch()
  const tableErrorRows = useSelector((state) => state.tasks.taskTable.errorRows)
  const showErrorStrip = useSelector((state) => state.tasks.taskTable.rowsHaveError)

  const handleRowError = useCallback(
    ({ rowId }) => {
      dispatch(tasksRowHasError({ id: rowId }))
    },
    [dispatch],
  )
  const handleRowErrorReset = useCallback(
    ({ rowId }) => {
      dispatch(resetTasksRowHasError({ ids: [rowId] }))
    },
    [dispatch],
  )

  // clean up old errors for tasks not shown anymore
  useEffect(() => {
    const taskIds = new Set(tasks.map(({ id }) => id))
    const taskIdsToDelete = Object.keys(tableErrorRows).filter((taskId) => !taskIds.has(taskId))
    if (taskIdsToDelete.length > 0) {
      dispatch(resetTasksRowHasError({ ids: taskIdsToDelete }))
    }
  }, [dispatch, tableErrorRows, tasks])

  const tableFilterTaskStatusEnum = useMemo(() => {
    const tableEnum = {}
    const keyNames = Object.keys(taskStatus)

    keyNames.forEach(
      (keyName) =>
        (tableEnum[keyName] = taskStatusTranslation(
          getObjectStatusForTaskStatus(taskStatus[keyName]).translationKey,
        )),
    )
    return tableEnum
  }, [taskStatusTranslation])

  const possibleTaskColumns = useMemo(
    () => ({
      checkbox: {
        renderColumnContent: () => <TasksTableSelectAllCheckbox tasks={tasks} />,
        columnKey: 'checkbox',
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
      name: {
        title: t('name'),
        columnKey: 'name',
        sortingDisabled,
        minWidth: 700,
        style: { width: '300px' },
        filter: showFilterInToolbar ? filterTypes.CUSTOM : undefined,
        additionalFilterOptions: {
          CustomComponent: TasksTableIsMandatoryCustomFilter,
          customFilterFunction: ({ searchValue, cell }) =>
            // needed because searchValue is a string and not a boolean
            searchValue === cell.isMandatory.toString(),
        },
        filterLabelText: t('mandatory'),
      },
      isMandatory: {
        title: t('mandatory'),
        columnKey: 'isMandatory',
        sortingDisabled: true,
        minWidth: 800,
        demandPopin: true,
        popinText: t('mandatory'),
      },
      description: {
        title: t('description'),
        columnKey: 'description',
        sortingDisabled: true,
        minWidth: 900,
        demandPopin: true,
        popinText: t('description'),
        style: { width: '550px' },
      },
      event: {
        title: t('event'),
        columnKey: 'event',
        sortingDisabled: true,
        minWidth: 1100,
        demandPopin: true,
        popinText: t('event'),
      },
      eventEntityRefType: {
        title: t('event-reference-type'),
        columnKey: 'eventEntityRefType',
        sortingDisabled: true,
        minWidth: 1200,
        demandPopin: true,
        popinText: t('event-reference-type'),
      },
      eventEntityRefName: {
        title: t('event-reference-name'),
        columnKey: 'eventEntityRefName',
        sortingDisabled: true,
        minWidth: 1200,
        demandPopin: true,
        popinText: t('event-reference-name'),
      },
      comment: {
        title: t('comment'),
        columnKey: 'comment',
        sortingDisabled: true,
        minWidth: 1000,
        demandPopin: true,
        popinText: t('comment'),
      },
      originalDueDate: {
        title: t('original-due-date'),
        columnKey: 'originalDueDate',
        minWidth: 700,
        demandPopin: true,
        popinText: t('original-due-date'),
        filter: showFilterInToolbar ? filterTypes.BETWEEN_DATES : undefined,
        alignment: FlexBoxJustifyContent.End,
      },
      currentDueDate: {
        title: t('current-due-date'),
        columnKey: 'currentDueDate',
        minWidth: 700,
        demandPopin: true,
        popinText: t('current-due-date'),
        filter: showFilterInToolbar ? filterTypes.BETWEEN_DATES : undefined,
        alignment: FlexBoxJustifyContent.End,
      },
      statusText: {
        title: t('status'),
        columnKey: 'statusText',
        style: { width: '120px' },
        filter: showFilterInToolbar ? filterTypes.OF_ENUM_TYPE : undefined,
        additionalFilterOptions: { enumValues: tableFilterTaskStatusEnum },
      },
      assignee: {
        title: t('assignee'),
        columnKey: 'assignee',
        sortingDisabled: true,
        minWidth: 700,
        demandPopin: true,
        popinText: t('assignee'),
        style: { width: '150px' },
        filter: showFilterInToolbar ? filterTypes.CUSTOM : undefined,
        additionalFilterOptions: {
          CustomComponent: TasksTableAssigneeCustomFilter,
          customFilterFunction: ({ searchValue, cell }) => searchValue === cell.value,
        },
      },
      watchers: {
        title: '',
        columnKey: 'watchers',
        sortingDisabled: true,
        alignment: FlexBoxAlignItems.End,
        isSelectableForHiding: false,
      },
      arrow: {
        title: '',
        columnKey: 'arrow',
        sortingDisabled: true,
        alignment: FlexBoxAlignItems.End,
        isSelectableForHiding: false,
      },
    }),
    [sortingDisabled, t, tasks, showFilterInToolbar, tableFilterTaskStatusEnum],
  )

  const tasksColumns = useMemo(
    () => shownColumns.map((columnKey) => possibleTaskColumns[columnKey]),
    [possibleTaskColumns, shownColumns],
  )

  const tableData = useMemo(
    () =>
      tasks.map(
        (
          {
            id,
            status,
            event: {
              id: eventId,
              entityRef: { entityType, entityId },
              info: { name: eventName },
              displayId: eventDisplayId,
              status: eventStatus,
            },
            info: {
              name,
              comment,
              originalDueDate,
              assignee,
              currentDueDate,
              isMandatory,
              description,
            },
          },
          index,
        ) => ({
          rowKey: `tasks-table-${id}_${index}`,
          rowProperties: {
            type: TableRowType.Active,
            'data-event-id': eventId,
            'data-task-id': id,
          },
          rowClassName: classNames({
            [styles.highlightErrorRow]: tableErrorRows[id],
          }),
          checkbox: {
            cellComponent: (
              <TasksTableCheckbox
                taskId={id}
                status={status}
                isMandatory={isMandatory}
                eventStatus={eventStatus}
              />
            ),
          },
          name: {
            cellComponent: (
              <FlexBox direction={FlexBoxDirection.Column}>
                <Label className={styles.columnTaskName} wrappingType={WrappingType.Normal}>
                  {name}
                </Label>
                {isMandatory && <Label className={styles.mandatoryLabel}>{t('mandatory')}</Label>}
              </FlexBox>
            ),
            value: name,
            isMandatory: isMandatory,
          },
          isMandatory: {
            cellComponent: (
              <Label>{isMandatory ? t('mandatory.true') : t('mandatory.false')}</Label>
            ),
          },
          description: {
            cellComponent: (
              <Label wrappingType={WrappingType.Normal}>
                <TasksTableDescriptionCell description={description ?? ''} />
              </Label>
            ),
          },
          event: {
            cellComponent: (
              <BusinessEventTableCell
                rowId={id}
                eventName={eventName}
                entityId={entityId}
                entityType={entityType}
                eventDisplayId={eventDisplayId}
                objectEntityType={entityType}
                errorCallback={handleRowError}
              />
            ),
          },
          eventEntityRefType: {
            cellComponent: (
              <Label wrappingType={WrappingType.Normal}>
                {eventEntityTypeTranslation(entityType)}
              </Label>
            ),
          },
          eventEntityRefName: {
            cellComponent: <BusinessObjectLink entityId={entityId} entityType={entityType} />,
          },
          comment: {
            cellComponent: (
              <Label wrappingType={WrappingType.Normal}>{comment ?? emptyValue}</Label>
            ),
          },
          originalDueDate: {
            cellComponent: (
              <div className={styles.dateAlignment}>
                <Text>{format(originalDueDate ?? emptyValue)}</Text>
              </div>
            ),
            value: originalDueDate,
          },
          currentDueDate: {
            cellComponent: (
              <div className={styles.dateAlignment}>
                <Text>{format(currentDueDate ?? emptyValue)}</Text>
                <DueDateWarningIcon
                  className={styles.dueDateWarningIcon}
                  taskStatus={status}
                  dueDate={currentDueDate}
                />
              </div>
            ),
            value: currentDueDate,
          },
          statusText: {
            cellComponent: (
              <ObjectStatus inverted state={getObjectStatusForTaskStatus(status).objectStatus}>
                {taskStatusTranslation(getObjectStatusForTaskStatus(status).translationKey)}
              </ObjectStatus>
            ),
            value: taskStatusTranslation(getObjectStatusForTaskStatus(status).translationKey),
            statusKey: status,
          },
          assignee: {
            cellComponent: assignee ? (
              <AssigneeTableCell
                rowId={id}
                assigneeId={assignee}
                errorCallback={handleRowError}
                resetErrorCallback={handleRowErrorReset}
              />
            ) : (
              <Label>{emptyValue}</Label>
            ),
            value: assignee,
          },
          watchers: {
            cellComponent: <TasksWatchersButton eventId={eventId} taskId={id} />,
          },
          arrow: {
            cellComponent: <Icon name="slim-arrow-right" />,
          },
        }),
      ),
    [
      eventEntityTypeTranslation,
      format,
      handleRowError,
      handleRowErrorReset,
      t,
      tableErrorRows,
      taskStatusTranslation,
      tasks,
    ],
  )

  const statusSorting = useCallback(
    (disorderedTableData, sortDirection) =>
      sortDirection === ascending
        ? disorderedTableData
            .slice()
            .sort(
              ({ statusText: { statusKey: firstKey } }, { statusText: { statusKey: secondKey } }) =>
                statusOrderingEnum[firstKey] - statusOrderingEnum[secondKey],
            )
        : disorderedTableData
            .slice()
            .sort(
              ({ statusText: { statusKey: firstKey } }, { statusText: { statusKey: secondKey } }) =>
                statusOrderingEnum[secondKey] - statusOrderingEnum[firstKey],
            ),
    [],
  )

  const customOrderFunction = useBackendOrdering
    ? (disorderedTableData) => disorderedTableData
    : (disorderedTableData, columnKey, sortDirection) =>
        columnKey === statusColumnKey
          ? statusSorting(disorderedTableData, sortDirection)
          : lodashOrderBy(disorderedTableData, `${columnKey}.value`, sortDirection)

  const customOrderCallback = useBackendOrdering
    ? (columnKey, newOrderBy) => {
        const newSortBy = columnKeyToSortingKeyMap[columnKey]
        if (!sortingDisabled) {
          onSortingChanged(newSortBy, newOrderBy)
        }
      }
    : undefined

  const isCtrlOrMetaPressed = useCtrlOrMetaKeyPressed()

  const handleRowClick = (url, target) => {
    navigate(url, { target: target ? '_blank' : undefined })
  }

  const additionalTableProperties = {
    onRowClick: ({ detail: { row: clickedTableRow } }) => {
      const taskId = clickedTableRow.getAttribute('data-task-id')
      const eventId = clickedTableRow.getAttribute('data-event-id')

      handleRowClick(
        `/${paths.businessEventsAndTasks}/business-events/${eventId}/tasks/${taskId}`,
        isCtrlOrMetaPressed,
      )
    },
  }

  // delete and append here because set with ...queryParams resets all existing params
  const handleAllTasksViewSwitch = useCallback(() => {
    queryParams.delete('task_assignee')
    queryParams.append('task_assignee', '')
    navigate({
      hash: 'tasks',
      search: `?${queryParams.toString()}#`,
    })
  }, [navigate, queryParams])

  const handleMyTasksViewSwitch = useCallback(() => {
    queryParams.delete('task_assignee')
    queryParams.append('task_assignee', currentUserId)
    navigate({
      hash: 'tasks',
      search: `?${queryParams.toString()}#`,
    })
  }, [queryParams, currentUserId, navigate])

  const currentAssignee = queryParams.get('task_assignee')
  const myTasksView = currentAssignee === currentUserId
  const currentTaskNumber = myTasksView ? maximumNumberOfMyTasks : maximumNumberOfTasks

  const segmentButton = useMemo(
    () => (
      <SegmentedButton key="event-overview-segment-switch-button">
        <SegmentedButtonItem pressed={!myTasksView} onClick={handleAllTasksViewSwitch}>
          {t('buttons.all-tasks', { numberOfTasks: maximumNumberOfTasks })}
        </SegmentedButtonItem>
        <SegmentedButtonItem
          pressed={myTasksView}
          disabled={myTasksLoading}
          onClick={handleMyTasksViewSwitch}
        >
          {t('buttons.my-tasks', { numberOfTasks: maximumNumberOfMyTasks })}
        </SegmentedButtonItem>
      </SegmentedButton>
    ),
    [
      maximumNumberOfTasks,
      maximumNumberOfMyTasks,
      t,
      handleAllTasksViewSwitch,
      handleMyTasksViewSwitch,
      myTasksView,
      myTasksLoading,
    ],
  )

  const sortingColumnKey = sortingDisabled
    ? 'noColumnKey'
    : Object.keys(columnKeyToSortingKeyMap).find((key) => columnKeyToSortingKeyMap[key] === sortBy)

  return (
    <>
      {showErrorStrip && (
        <MessageStrip
          hideCloseButton
          design={MessageStripDesign.Negative}
          className={styles.messageStrip}
        >
          {t('error.table-has-error')}
        </MessageStrip>
      )}
      <SortedTable
        key={`tasks-sorted-table-${tasksColumns.length}`}
        columnDefinitions={tasksColumns}
        tableData={tableData}
        customOrderFunction={customOrderFunction}
        customOrderCallback={customOrderCallback}
        toolbarConfig={{
          title: t('toolbar.title'),
          sorting: {
            columnKey: sortingColumnKey,
            isSortingAscending: orderBy === ascending,
          },
          additionalActions: additionalActions,
          dontShowNumberOfEntries: !isNil(myTasksLoading),
          additionalActionsNextToTitle: !isNil(myTasksLoading) ? [segmentButton] : [],
        }}
        additionalTableProperties={additionalTableProperties}
        noDataText={noDataText}
        paginationConfig={{
          growing: tableData.length < currentTaskNumber ? TableGrowingMode.Button : undefined,
          growingButtonText: t('button.load-more'),
          growingButtonSubtext: `[${tableData.length} / ${currentTaskNumber}]`,
          totalNumberOfItems: currentTaskNumber,
          loadMore: onLoadMore,
        }}
      />
    </>
  )
}

TasksTable.propTypes = {
  tasks: PropType.array.isRequired,
  onSortingChanged: PropType.func,
  sortBy: PropType.string,
  orderBy: PropType.oneOf(['asc', 'desc']),
  onLoadMore: PropType.func,
  maximumNumberOfTasks: PropType.number,
  maximumNumberOfMyTasks: PropType.number,
  sortingDisabled: PropType.bool,
  showFilterInToolbar: PropType.bool,
  shownColumns: PropType.arrayOf(
    PropType.oneOf([
      'checkbox',
      'name',
      'isMandatory',
      'description',
      'event',
      'eventEntityRefType',
      'eventEntityRefName',
      'comment',
      'currentDueDate',
      'originalDueDate',
      'statusIcon',
      'statusText',
      'assignee',
      'watchers',
      'arrow',
    ]),
  ),
  noDataText: PropType.string,
  additionalActions: PropType.arrayOf(PropType.element),
  currentUserId: PropType.string,
  myTasksLoading: PropType.bool,
  useBackendOrdering: PropType.bool,
}

export default TasksTable
