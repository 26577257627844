import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import React from 'react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/covenants-ratios/check-results/shared/LimitCell.module.css'
import {
  useCustomizableCurrencyFormatter,
  usePercentageFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'

const LimitCell = ({ unit, value, currencyCode }) => {
  const formatCurrency = useCustomizableCurrencyFormatter()
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  if (unit === 'CURRENCY') {
    return (
      <span className={styles.noWrap}>
        {formatCurrency(value, currencyCode, {
          currencyDisplay: 'code',
        })}
      </span>
    )
  }

  if (unit === 'PERCENT') {
    return !isNil(value) ? <span>{formatPercentage(value / 100)}</span> : null
  }
  return null
}

LimitCell.propTypes = {
  unit: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currencyCode: PropTypes.string,
}

export default LimitCell
