import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDealTeam = ({ dealUuid, options = {} }) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/deal-teams`,
      useCache: true,
      translated: true,
      keys: ['deals', dealUuid, 'deal-teams'],
      options: {
        enabled: !!dealUuid,
        ...options,
      },
    }),
  )

export default useDealTeam
