import { DateTime } from 'luxon'
import {
  getShortDatePattern,
  useFormatterCache,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'

const useMMyyyy = () =>
  useFormatterCache({ year: 'numeric', month: '2-digit' }, (locales, options) => {
    const formatter = Intl.DateTimeFormat(locales, options)
    const datePattern = getShortDatePattern(formatter)

    /** @param {string} value */
    const parse = (value) => DateTime.fromFormat(value, datePattern).toISODate()

    /** @param {string?} [isoString] */
    const format = (isoString) => {
      if (!isoString) return ''
      return formatter.format(new Date(isoString))
    }

    return { parse, format, pattern: datePattern }
  })

export default useMMyyyy
