import isNil from 'lodash.isnil'
import {
  validBreachRules,
  breachOptions,
} from 'components/domains/deals/covenant-check-detail/checked-covenants/constants'

const useCalculateBreach = () => {
  const getCalculatedBreach = (breachRule, hardLimitValue, softLimitValue, result) => {
    if (isNil(result) || isNil(breachRule)) return null
    if (!Object.values(validBreachRules).includes(breachRule)) return null

    if (breachRule)
      switch (breachRule) {
        case validBreachRules.LIMIT_LESS_THAN_RESULT: {
          if (!!hardLimitValue && hardLimitValue < result) return breachOptions.HARD
          if (!!softLimitValue && softLimitValue < result) return breachOptions.SOFT
          return breachOptions.NO
        }
        case validBreachRules.LIMIT_GREATER_THAN_RESULT: {
          if (!!hardLimitValue && hardLimitValue > result) return breachOptions.HARD
          if (!!softLimitValue && softLimitValue > result) return breachOptions.SOFT
          return breachOptions.NO
        }
        case validBreachRules.LIMIT_LESS_EQUAL_THAN_RESULT: {
          if (!!hardLimitValue && hardLimitValue <= result) return breachOptions.HARD
          if (!!softLimitValue && softLimitValue <= result) return breachOptions.SOFT
          return breachOptions.NO
        }
        case validBreachRules.LIMIT_GREATER_EQUAL_THAN_RESULT: {
          if (!!hardLimitValue && hardLimitValue >= result) return breachOptions.HARD
          if (!!softLimitValue && softLimitValue >= result) return breachOptions.SOFT
          return breachOptions.NO
        }
        default: {
          return breachOptions.NO
        }
      }
  }
  return { getCalculatedBreach }
}

export default useCalculateBreach
