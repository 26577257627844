import {
  FlexBox,
  FlexBoxDirection,
  MessageStrip,
  MessageStripDesign,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/links/DecisionPaperLeaveConfirmationLink'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/details/DrawdownBorrowerInformationTile.module.css'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/details/shared/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/details/shared/DisplayCardViewItemView'
import paths from 'routes/paths'

const dash = '-'

const DrawdownBorrowerInformationTile = ({ tileId, showConfirmationForLinks }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.drawdown-borrower-information',
  })
  const { data: tileData } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const borrowerNameItem = useMemo(() => {
    if (isNil(tileData?.borrowerName)) {
      return dash
    }
    if (isNil(tileData?.borrowerBpId)) {
      return tileData.borrowerName
    }
    const link = `/${paths.businessPartners}/${tileData.borrowerBpId}`
    return (
      <DecisionPaperLeaveConfirmationLink
        name={tileData.borrowerName}
        link={link}
        showConfirmationForLinks={showConfirmationForLinks}
      />
    )
  }, [tileData?.borrowerName, tileData?.borrowerBpId, showConfirmationForLinks])

  const borrowerBpId = useMemo(() => tileData?.borrowerBpId ?? dash, [tileData?.borrowerBpId])

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <FlexBox className={styles.borrowerInformationMessageStrip}>
        <MessageStrip hideCloseButton hideIcon design={MessageStripDesign.Warning}>
          {t('information')}
        </MessageStrip>
      </FlexBox>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          label={t('borrower.name')}
          value={borrowerNameItem}
          isFirstItem
        />
      </DisplayCardViewItemView>
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView label={t('borrower.bp-id')} value={borrowerBpId} />
      </DisplayCardViewItemView>
    </FlexBox>
  )
}

DrawdownBorrowerInformationTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
}

export default DrawdownBorrowerInformationTile
