import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useMarketArchiving = (mutateOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation((marketId) => {
    post({
      path: `/markets/${marketId}/archive`,
    })
  }, mutateOptions)
}

export default useMarketArchiving
