import PropTypes from 'prop-types'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import CashFlowTileLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/with-meta-data/cash-flow/CashFlowTileLoadingWrapper'
import CoverPoolAdjustmentsTileLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/with-meta-data/cover-pool/CoverPoolAdjustmentsTileLoadingWrapper'
import DealOverviewTileLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/with-meta-data/deal-overview/DealOverviewTileLoadingWrapper'
import ExternalConditionsTileLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/with-meta-data/external-conditions/ExternalConditionsTileLoadingWrapper'
import InternalConditionsTileLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/with-meta-data/internal-conditions/InternalConditionsTileLoadingWrapper'
import TileUnknown from 'components/domains/business-events-and-tasks/decision-paper/tiles/generic/TileUnknown'

const availableTiles = {
  [availableAutomaticTiles.cashFlowAssumptionsAdditionalCase]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowAssumptionsAdditionalCaseWVLive]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowAssumptionsBaseCase]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowAssumptionsBaseCaseWVLive]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowAssumptionsStressCase]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowAssumptionsStressCaseWVLive]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowDebtAndEquityProjectionAdditionalCase]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowDebtAndEquityProjectionAdditionalCaseWVLive]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowDebtAndEquityProjectionBaseCase]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowDebtAndEquityProjectionBaseCaseWVLive]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowDebtAndEquityProjectionStressCase]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowDebtAndEquityProjectionStressCaseWVLive]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowFinancialRatiosAdditionalCase]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowFinancialRatiosAdditionalCaseWVLive]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowFinancialRatiosBaseCase]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowFinancialRatiosBaseCaseWVLive]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowFinancialRatiosStressCase]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowFinancialRatiosStressCaseWVLive]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowVacancyProjection]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.cashFlowVacancyProjectionWVLive]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.covenantsRatiosFinancialRatios]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.covenantsRatiosFinancialRatiosWVLive]: {
    Wrapper: CashFlowTileLoadingWrapper,
  },
  [availableAutomaticTiles.coverPoolAdjustment]: {
    Wrapper: CoverPoolAdjustmentsTileLoadingWrapper,
  },
  [availableAutomaticTiles.externalConditions]: {
    Wrapper: ExternalConditionsTileLoadingWrapper,
  },
  [availableAutomaticTiles.internalConditions]: {
    Wrapper: InternalConditionsTileLoadingWrapper,
  },
  [availableAutomaticTiles.multipleTenancyRentRollVacancyProjection]: {
    Wrapper: DealOverviewTileLoadingWrapper,
  },
  [availableAutomaticTiles.multipleCashFlowAnalysisCashFlowFinancialRatiosBaseCase]: {
    Wrapper: DealOverviewTileLoadingWrapper,
  },
  [availableAutomaticTiles.multipleCashFlowAnalysisCashCashFlowDebtEquityProjectionBaseCase]: {
    Wrapper: DealOverviewTileLoadingWrapper,
  },
  [availableAutomaticTiles.multipleCashFlowAnalysisCashCashFlowAssumptionsBaseCase]: {
    Wrapper: DealOverviewTileLoadingWrapper,
  },
  [availableAutomaticTiles.multipleCashFlowAnalysisCashFlowFinancialRatiosStressCase]: {
    Wrapper: DealOverviewTileLoadingWrapper,
  },
  [availableAutomaticTiles.multipleCashFlowAnalysisCashFlowDebtEquityProjectionStressCase]: {
    Wrapper: DealOverviewTileLoadingWrapper,
  },
  [availableAutomaticTiles.multipleCashFlowAnalysisCashFlowAssumptionsStressCase]: {
    Wrapper: DealOverviewTileLoadingWrapper,
  },
}

const DecisionPaperAutomaticTileWithMetaDataTypeWrapper = ({ tileCode, ...tileProps }) => {
  const tileToRender = availableTiles[tileCode]
  if (!tileToRender) {
    return <TileUnknown tileCode={tileCode} />
  }

  return <tileToRender.Wrapper tileCode={tileCode} {...tileProps} />
}

DecisionPaperAutomaticTileWithMetaDataTypeWrapper.propTypes = {
  tileCode: PropTypes.string.isRequired,
}

export default DecisionPaperAutomaticTileWithMetaDataTypeWrapper
