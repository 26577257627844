import {
  Button,
  ButtonDesign,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useDiscardConditionDecision from 'hooks/services/business-events-and-tasks/decision-process/stages/conditions/useDiscardConditionDecision'

const useDiscardConditionDecisionFlow = ({ eventId, decisionStageId }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.conditions',
  })
  const showMessageBox = Modals.useShowMessageBox()
  const showToast = Modals.useShowToast()
  const { mutate: discardDecision } = useDiscardConditionDecision()
  const queryClient = useQueryClient()

  const onDiscardDecisionSuccess = useCallback(() => {
    queryClient.invalidateQueries(['events', eventId, 'decision-stages'])
    showToast({ children: t('discard.success') }, document.body)
  }, [showToast, t, queryClient, eventId])

  const onDiscardDecisionError = useCallback(() => {
    showMessageBox(
      {
        type: MessageBoxTypes.Error,
        children: t('discard.error'),
        draggable: true,
        resizable: true,
      },
      document.body,
    )
  }, [showMessageBox, t])

  const onDiscardDecisionConfirmed = useCallback(() => {
    discardDecision(
      {
        eventId,
        decisionStageId,
      },
      {
        onSuccess: onDiscardDecisionSuccess,
        onError: onDiscardDecisionError,
      },
    )
  }, [eventId, decisionStageId, onDiscardDecisionSuccess, onDiscardDecisionError, discardDecision])

  const onDiscardDecisionButtonClicked = useCallback(() => {
    showMessageBox(
      {
        titleText: t('discard.title'),
        children: <Text wrapping>{t('discard.text')}</Text>,
        draggable: true,
        resizable: true,
        type: MessageBoxTypes.Confirm,
        actions: [
          <Button
            key="discard-decision-condition"
            onClick={onDiscardDecisionConfirmed}
            design={ButtonDesign.Emphasized}
          >
            {tNoPrefix('buttons.discard')}
          </Button>,
          MessageBoxActions.Cancel,
        ],
      },
      document.body,
    )
  }, [showMessageBox, t, tNoPrefix, onDiscardDecisionConfirmed])

  return useMemo(
    () => ({
      onDiscardDecisionButtonClicked,
    }),
    [onDiscardDecisionButtonClicked],
  )
}

export default useDiscardConditionDecisionFlow
