import {
  FlexBoxAlignItems,
  Icon,
  IllustratedMessage,
  IllustrationMessageType,
  Label,
  TableRowType,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import CardSection from 'components/domains/deals/card/CardSection'
import {
  getCurrentValueForTimeSeries,
  getLastUltimoForTimeSeries,
  getTimeSeriesForKpi,
  KpiThresholdTypes,
} from 'components/domains/kpis/KpiUtils'
import Card from 'components/ui/card/Card'
import Labeled from 'components/ui/data/Labeled'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import StaffMemberByObjectIdOrEmail from 'components/ui/staff-member/StaffMemberByObjectIdOrEmail'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { useCustomizableCurrencyFormatter, usePercentageFormatter } from 'hooks/i18n/useI18n'
import useKpis from 'hooks/services/kpis/useKpis'

const KpiValue = ({ value, unit, createdBy, labelKey }) => {
  const { t } = useTranslation('translation')
  const formatCurrency = useCustomizableCurrencyFormatter()
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formattedValue = useMemo(() => {
    if (unit.type === 'CURRENCY') {
      return formatCurrency(value, unit?.code, {
        currencyDisplay: 'code',
      })
    }
    if (unit.type === 'PERCENT') {
      return !isNil(value) ? formatPercentage(value / 100) : ''
    }
  }, [formatCurrency, formatPercentage, unit, value])

  return (
    <>
      <Labeled label={t(labelKey)} showColon>
        {formattedValue ? `${formattedValue}` : '-'}
      </Labeled>
      <Labeled label={t('components.kpis.overview.table.source')} showColon>
        <>
          {createdBy && <StaffMemberByObjectIdOrEmail objectIdOrEmail={createdBy} />}
          {!createdBy && '-'}
        </>
      </Labeled>
    </>
  )
}
KpiValue.propTypes = {
  value: PropTypes.number,
  createdBy: PropTypes.string,
  labelKey: PropTypes.string,
  unit: PropTypes.shape({
    type: PropTypes.string,
    code: PropTypes.string,
  }),
}

const KpiOverviewCard = ({ referenceEntityType, referenceEntityId }) => {
  const { t } = useTranslation('translation')
  const navigate = useNavigate()

  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const {
    data: { kpis = [] } = {},
    isError,
    isFetching,
  } = useKpis(referenceEntityType, referenceEntityId)

  const columnDefinitions = [
    {
      columnKey: 'kpi',
      title: t('components.kpis.overview.table.name'),
      isSelectableForHiding: false,
      popinText: t('components.kpis.overview.table.name'),
      demandPopin: false,
    },
    {
      columnKey: 'currentValue',
      title: t('components.kpis.overview.table.values'),
      isSelectableForHiding: true,
      sortingDisabled: true,
      popinText: t('components.kpis.overview.table.values'),
      demandPopin: true,
      minWidth: 600,
    },
    {
      columnKey: 'lastMonthValue',
      title: '',
      isSelectableForHiding: false,
      sortingDisabled: true,
      popinText: '',
      demandPopin: true,
      minWidth: 600,
    },
    {
      columnKey: 'thresholds',
      title: t('components.kpis.overview.table.thresholds'),
      isSelectableForHiding: false,
      sortingDisabled: true,
      popinText: t('components.kpis.overview.table.thresholds'),
      demandPopin: true,
      minWidth: 600,
    },
    {
      columnKey: 'arrow',
      title: '',
      alignment: FlexBoxAlignItems.End,
      isSelectableForHiding: false,
      sortingDisabled: true,
      popinText: '',
      demandPopin: true,
      minWidth: 600,
    },
  ]

  const isMainKpi = useCallback(
    (kpiCode) => kpis?.find(({ code }) => code === kpiCode)?.parents?.length === 0,
    [kpis],
  )

  const getThresholdsForKpi = useCallback(
    (kpiCode) => kpis?.find(({ code }) => code === kpiCode)?.thresholds ?? [],
    [kpis],
  )

  const mainKpis = useMemo(
    () =>
      kpis
        .filter((kpi) => isMainKpi(kpi.code))
        .map((mainKpi) => ({ ...mainKpi, timeSeries: getTimeSeriesForKpi(mainKpi) })),
    [kpis, isMainKpi],
  )

  const currentValue = (kpiValue, unit) => (
    <KpiValue
      value={kpiValue?.value}
      unit={unit}
      createdBy={kpiValue?.updatedBy ?? kpiValue?.createdBy}
      labelKey={'components.kpis.overview.table.current'}
    />
  )
  const lastMonthValue = (kpiValue, unit) => (
    <KpiValue
      value={kpiValue?.value}
      unit={unit}
      createdBy={kpiValue?.updatedBy ?? kpiValue?.createdBy}
      labelKey={'components.kpis.overview.table.last-ultimo'}
    />
  )

  const tableData = mainKpis
    .filter(({ displayInOverview }) => displayInOverview === true)
    .map(({ id, name, code, timeSeries, unit }) => ({
      rowKey: `kpi-table-row-${code}`,
      rowProperties: {
        'kpi-id': id,
        type: TableRowType.Active,
        onClick: () => navigate(id),
      },
      kpi: {
        value: name,
        cellComponent: (
          <Label>
            <b>{name}</b>
          </Label>
        ),
      },
      currentValue: {
        cellComponent: currentValue(getCurrentValueForTimeSeries(timeSeries), unit),
      },
      lastMonthValue: {
        cellComponent: lastMonthValue(getLastUltimoForTimeSeries(timeSeries), unit),
      },
      thresholds: {
        cellComponent: (
          <>
            {getThresholdsForKpi(code).map((threshold) => (
              <Labeled key={threshold.name} label={threshold.name} showColon>{`${
                KpiThresholdTypes[threshold.comparisonType]
              } ${formatPercentage(threshold.value / 100)}`}</Labeled>
            ))}
          </>
        ),
      },
      arrow: {
        cellComponent: <Icon name="slim-arrow-right" />,
      },
    }))

  return (
    <Card>
      <RequestStateResolver
        isLoading={isFetching}
        isError={isError}
        center
        renderContent={() => (
          <CardSection>
            <SortedTable
              columnDefinitions={columnDefinitions}
              tableData={tableData}
              toolbarConfig={{
                title: t('components.kpis.overview.table.title'),
                showColumnSelection: false,
              }}
            />
          </CardSection>
        )}
        errorToDisplay={
          <IllustratedMessage
            name={IllustrationMessageType.UnableToLoad}
            titleText={t('app.loading.error.data-unavailable')}
          />
        }
      />
    </Card>
  )
}

KpiOverviewCard.propTypes = {
  referenceEntityType: PropTypes.string.isRequired,
  referenceEntityId: PropTypes.string.isRequired,
}

export default KpiOverviewCard
