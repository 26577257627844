import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import AssigneeSelectionFilter from 'components/domains/conditions/overview/filter-components/AssigneeSelectionFilter'
import ConditionSelectionFilter from 'components/domains/conditions/overview/filter-components/ConditionSelectionFilter'
import CovenantCheckFilter from 'components/domains/conditions/overview/filter-components/CovenantCheckFilter'
import ExternalAssigneeSelectionFilter from 'components/domains/conditions/overview/filter-components/ExternalAssigneeSelectionFilter'
import LoadingDropdownFilter from 'components/domains/conditions/overview/filter-components/LoadingDropdownFilter'
import LoadingMultiComboBoxFilter from 'components/domains/conditions/overview/filter-components/LoadingMultiComboBoxFilter'
import ReferencesMultiSelectionFilter from 'components/domains/conditions/overview/filter-components/ReferencesMultiSelectionFilter'
import { filterTypesEmptyValues } from 'components/ui/tables/sorted-tables/useFilters'
import FilterDialogFilterItem from 'components/ui/tables/toolbar/FilterDialogFilterItem'
import FilterDialogView from 'components/ui/tables/toolbar/FilterDialogView'
import BetweenDatesFilter from 'components/ui/tables/toolbar/filter/BetweenDatesFilter'
import {
  conditionFilterPrefixes,
  conditionServiceTypes,
} from 'hooks/services/conditions/tables/useConditionFilterQuery'

export const availableFilterTypes = {
  betweenDates: 'BETWEEN_DATES',
  assignee: 'ASSIGNEE',
  condition: 'CONDITION',
  externalAssignee: 'EXTERNAL_ASSIGNEE',
  loadingDropdownSingleSelect: 'LOADING_DROPDOWN_SINGLE_SELECT',
  loadingDropdownMultiComboBox: 'LOADING_DROPDOWN_MULTI_COMBO_BOX',
  covenantCheck: 'COVENANT_CHECK',
  referencesMultiSelect: 'REFERENCES_MULTI_SELECT',
}

export const filterTypes = {
  [availableFilterTypes.betweenDates]: {
    emptyValue: filterTypesEmptyValues.BETWEEN_DATES,
  },
  [availableFilterTypes.condition]: {
    emptyValue: '',
  },
  [availableFilterTypes.assignee]: {
    emptyValue: '',
  },
  [availableFilterTypes.externalAssignee]: {
    emptyValue: '',
  },
  [availableFilterTypes.loadingDropdownSingleSelect]: {
    emptyValue: '',
  },
  [availableFilterTypes.loadingDropdownMultiComboBox]: {
    emptyValue: [],
  },
  [availableFilterTypes.covenantCheck]: {
    emptyValue: undefined,
  },
  [availableFilterTypes.referencesMultiSelect]: {
    emptyValue: undefined,
  },
}

const trimLastQueryChar = (urlString) =>
  urlString.endsWith('&') || urlString.endsWith('?') ? urlString.slice(0, -1) : urlString

const ConditionsTableToolbarFilterDialog = ({
  isOpen,
  setIsOpen,
  filters,
  setFilters,
  disabled,
  tableType = conditionServiceTypes.conditions,
}) => {
  const [selectedFilters, setSelectedFilters] = useState(filters)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    setSelectedFilters(filters)
  }, [filters])

  const filterPrefix =
    tableType === conditionServiceTypes.conditions
      ? conditionFilterPrefixes.conditions
      : conditionFilterPrefixes.requirements

  const onCancel = useCallback(() => {
    setSelectedFilters(filters)
    setIsOpen(false)
  }, [setSelectedFilters, filters, setIsOpen])

  const calculateUrlBySelectedFilters = useCallback(
    (prefix) => {
      let calculatedFilterUrl = '?'
      selectedFilters.forEach(({ filterKey, value, emptyValue }) => {
        if (isEqual(value, emptyValue)) {
          return
        }
        if (filterKey === 'creationDate') {
          if (value.lowerBound) {
            calculatedFilterUrl =
              calculatedFilterUrl + prefix + `creationDateFrom=${value.lowerBound}&`
          }
          if (value.upperBound) {
            calculatedFilterUrl =
              calculatedFilterUrl + prefix + `creationDateUntil=${value.upperBound}&`
          }
          return
        }
        if (filterKey === 'dueDate') {
          if (value.lowerBound) {
            calculatedFilterUrl = calculatedFilterUrl + prefix + `dueDate=${value.lowerBound}&`
          }
          if (value.upperBound) {
            calculatedFilterUrl = calculatedFilterUrl + prefix + `dueDate=${value.upperBound}&`
          }
          return
        }
        if (filterKey === 'covenantCheck') {
          calculatedFilterUrl =
            calculatedFilterUrl + prefix + `${filterKey}=${value.covenantCheckUuid},${value.name}&`
          return
        }
        if (filterKey === 'references') {
          calculatedFilterUrl =
            calculatedFilterUrl +
            prefix +
            `referencesEntityType=${value.entityType}&` +
            prefix +
            `referencesEntityIds=${value.entityIds}&`
          return
        }
        calculatedFilterUrl = calculatedFilterUrl + prefix + `${filterKey}=${value}&`
      })
      return trimLastQueryChar(calculatedFilterUrl)
    },
    [selectedFilters],
  )

  const onSubmit = useCallback(() => {
    setIsOpen(false)
    setFilters(selectedFilters)
    const newQueryparams = calculateUrlBySelectedFilters(filterPrefix)

    const locationHash = location.hash
    navigate({
      hash: locationHash,
      search: newQueryparams,
    })
  }, [
    setIsOpen,
    setFilters,
    selectedFilters,
    navigate,
    location.hash,
    calculateUrlBySelectedFilters,
    filterPrefix,
  ])

  const onReset = useCallback(() => {
    setSelectedFilters(
      selectedFilters.map((filter) => ({
        ...filter,
        value: filterTypes[filter.type].emptyValue,
      })),
    )
  }, [selectedFilters])

  const isNoFilterEntered = useMemo(
    () => selectedFilters.every(({ value, type }) => isEqual(value, filterTypes[type].emptyValue)),
    [selectedFilters],
  )

  const renderedFilters = useMemo(
    () =>
      selectedFilters.map(
        ({
          columnKey,
          type,
          label,
          value,
          useLoadingHook,
          buildLoadingHookParams,
          selectionName,
        }) => {
          switch (type) {
            case availableFilterTypes.condition:
              return (
                <FilterDialogFilterItem key={columnKey} label={label}>
                  <ConditionSelectionFilter
                    value={value}
                    setSelectedFilters={setSelectedFilters}
                    columnKey={columnKey}
                  />
                </FilterDialogFilterItem>
              )
            case availableFilterTypes.betweenDates:
              return (
                <FilterDialogFilterItem key={columnKey} label={label}>
                  <BetweenDatesFilter
                    value={value}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    columnKey={columnKey}
                  />
                </FilterDialogFilterItem>
              )
            case availableFilterTypes.assignee:
              return (
                <FilterDialogFilterItem key={columnKey} label={label}>
                  <AssigneeSelectionFilter
                    value={value}
                    setSelectedFilters={setSelectedFilters}
                    columnKey={columnKey}
                  />
                </FilterDialogFilterItem>
              )
            case availableFilterTypes.externalAssignee:
              return (
                <FilterDialogFilterItem key={columnKey} label={label}>
                  <ExternalAssigneeSelectionFilter
                    value={value}
                    setSelectedFilters={setSelectedFilters}
                    columnKey={columnKey}
                  />
                </FilterDialogFilterItem>
              )
            case availableFilterTypes.covenantCheck:
              return (
                <FilterDialogFilterItem key={columnKey} label={label}>
                  <CovenantCheckFilter
                    value={value}
                    setSelectedFilters={setSelectedFilters}
                    columnKey={columnKey}
                  />
                </FilterDialogFilterItem>
              )
            case availableFilterTypes.loadingDropdownSingleSelect:
              return (
                <FilterDialogFilterItem key={columnKey} label={label}>
                  <LoadingDropdownFilter
                    value={value}
                    setSelectedFilters={setSelectedFilters}
                    columnKey={columnKey}
                    useLoadingHook={useLoadingHook}
                    loadingHookParams={buildLoadingHookParams?.(selectedFilters)}
                    selectionName={selectionName}
                  />
                </FilterDialogFilterItem>
              )
            case availableFilterTypes.loadingDropdownMultiComboBox:
              return (
                <FilterDialogFilterItem key={columnKey} label={label}>
                  <LoadingMultiComboBoxFilter
                    value={value}
                    setSelectedFilters={setSelectedFilters}
                    columnKey={columnKey}
                    useLoadingHook={useLoadingHook}
                    loadingHookParams={buildLoadingHookParams?.(selectedFilters)}
                    selectionName={selectionName}
                  />
                </FilterDialogFilterItem>
              )
            case availableFilterTypes.referencesMultiSelect:
              return (
                <FilterDialogFilterItem key={columnKey} label={label}>
                  <ReferencesMultiSelectionFilter
                    value={value}
                    setSelectedFilters={setSelectedFilters}
                    columnKey={columnKey}
                  />
                </FilterDialogFilterItem>
              )
          }
        },
      ),
    [selectedFilters],
  )

  const additionalIconProps = useMemo(
    () => ({
      disabled,
    }),
    [disabled],
  )

  return (
    <FilterDialogView
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onReset={onReset}
      isNoFilterEntered={isNoFilterEntered}
      additionalIconProps={additionalIconProps}
      disabled={disabled}
    >
      {renderedFilters}
    </FilterDialogView>
  )
}

ConditionsTableToolbarFilterDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      columnKey: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
      useLoadingHook: PropTypes.func,
      selectionName: PropTypes.string,
    }),
  ).isRequired,
  tableType: PropTypes.oneOf(['conditions', 'requirements']),
  setFilters: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default ConditionsTableToolbarFilterDialog
