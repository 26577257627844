import { Button, Grid, Title } from '@fioneer/ui5-webcomponents-react'
import { useAuth } from 'oidc-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { fetchAPIRequestInterceptor } from 'api/requests'
import LoadingScreen from 'components/ui/screens/LoadingScreen'

const authenticationWrapperStyle = {
  textAlign: 'center',
}

const AuthenticationWrapper = ({ children }) => {
  const { t } = useTranslation()
  const { userManager, isLoading, userData, signOut } = useAuth()

  const onLoginPressed = () => {
    localStorage.redirectUri = window.location.href
    userManager.signinRedirect()
  }

  // workaround:
  // automatic refresh provided by oidc-library does not include scope in refresh call
  // which is required (for AAD at least). Also, on failure, `userData` stays 'as is'
  // in react state, keeping the expired access token
  /* eslint-disable no-console */
  if (userManager && !userManager.events.tokenExpiryCallbacksRegistered) {
    const onTokenExpiring = () =>
      userManager
        .signinSilent({ scope: userManager.settings.scope }) // refresh with scope
        .then(({ access_token }) => {
          console.debug('Silent token renew successful.')
          fetchAPIRequestInterceptor({ accessToken: access_token })
        })
        .catch((err) => {
          console.error('Silent token renewal failed:', err?.message)
          fetchAPIRequestInterceptor({ userSignedOut: true })
          signOut() // signOut if refresh failed => remove userData from react state
        })
    userManager.events.addAccessTokenExpiring(onTokenExpiring)
    userManager.events.addAccessTokenExpired(onTokenExpiring)
    userManager.events.tokenExpiryCallbacksRegistered = true
    console.debug('Token expiry callbacks registered.')
  }
  /* eslint-enable no-console */

  if (userData) {
    return children
  }

  if (isLoading) {
    return (
      <LoadingScreen
        title={t('components.login.title')}
        description={t('components.login.description')}
      />
    )
  }

  return (
    <Grid
      position="Center"
      defaultIndent="XL0 L0 M0 S0"
      defaultSpan="XL12 L12 M12 S12"
      vSpacing="1rem"
      data-testid="login-loading"
    >
      <div style={authenticationWrapperStyle}>
        <Title data-testid="authentication-login-title">
          {t('components.authentication.login.title')}
        </Title>
      </div>
      <div style={authenticationWrapperStyle}>
        <div>
          <Button
            id="authentication-login-button"
            data-testid="authentication-login-button"
            design="Emphasized"
            onClick={onLoginPressed}
            style={{ float: 'center' }}
          >
            {t('components.authentication.login.button')}
          </Button>
        </div>
        <div data-testid="authentication-login">
          {t('components.authentication.login.description')}
        </div>
      </div>
    </Grid>
  )
}

AuthenticationWrapper.propTypes = {
  children: PropTypes.node,
}

export default AuthenticationWrapper
