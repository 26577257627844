import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  MessageBoxTypes,
  Modals,
  Title,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionProcessMinutesDecisionStatus } from 'api/decision-process/decisionProcessMinutesApi'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import styles from 'components/domains/business-events-and-tasks/decision-stage/minutes/DecisionStageMinutesCard.module.css'
import CancelButtonWithPopover from 'components/ui/button/CancelButtonWithPopover'
import Card from 'components/ui/card/Card'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import CWPCKEditor, { emptyContent } from 'components/ui/rich-text-editor/CWPCKEditor'
import { toolbarConfigWithImages } from 'components/ui/rich-text-editor/CWPCKEditorToolbarConfigurations'
import FormattedTextView from 'components/ui/rich-text-editor/FormattedTextView'
import StaffMemberByObjectIdOrEmail from 'components/ui/staff-member/StaffMemberByObjectIdOrEmail'
import LastEditedText from 'components/ui/text/LastEditedText'
import useEditDecisionStageMinutes from 'hooks/services/business-events-and-tasks/decision-process/stages/minutes/useEditDecisionStageMinutes'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionStageMinutesCard = ({
  decisionStageId,
  minutes,
  lastUpdatedAt,
  lastUpdatedBy,
  decisionStatus,
  isPdfView = false,
}) => {
  const {
    event: { id: eventId },
    allowedOperations,
  } = useContext(BusinessEventsAndTasksContext)
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.minutes.rtf-card',
  })

  const { mutate: editMinutes } = useEditDecisionStageMinutes()
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const showMessageBox = Modals.useShowMessageBox()
  const [isDataUploading, setIsDataUploading] = useState(false)

  const [isEditMode, setIsEditMode] = useState(false)
  const [editedMinutes, setEditedMinutes] = useState(minutes)

  const showEditButton = useMemo(
    () =>
      !isPdfView &&
      allowedOperations.includes(businessEventAllowedOperations.updateDecisionStageMinutes) &&
      (decisionStatus === decisionProcessMinutesDecisionStatus.pending ||
        decisionStatus === decisionProcessMinutesDecisionStatus.inProgressRequestDiscarded),
    [allowedOperations, decisionStatus, isPdfView],
  )

  const hasChanges = editedMinutes !== minutes

  const handleEditClicked = useCallback(() => {
    setEditedMinutes(minutes)
    setIsEditMode(true)
  }, [minutes])

  const onCancel = useCallback(() => {
    setIsEditMode(false)
    setEditedMinutes(minutes)
  }, [minutes])

  const onSaveSuccess = useCallback(() => {
    setIsEditMode(false)
    queryClient.invalidateQueries([
      'events',
      eventId,
      'decision-stages',
      decisionStageId,
      'minutes',
    ])
    showToast({ children: t('edit.success') })
  }, [queryClient, eventId, decisionStageId, showToast, t])

  const onSaveError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('edit.error'),
      draggable: true,
      resizable: true,
    })
  }, [showMessageBox, t])

  const onSaveClicked = useCallback(() => {
    editMinutes(
      { eventId, decisionStageId, minutes: editedMinutes },
      { onSuccess: onSaveSuccess, onError: onSaveError },
    )
  }, [editMinutes, decisionStageId, eventId, editedMinutes, onSaveError, onSaveSuccess])

  const editButton = useMemo(
    () =>
      showEditButton && (
        <Button onClick={handleEditClicked} design={ButtonDesign.Transparent}>
          {tNoPrefix('buttons.edit')}
        </Button>
      ),
    [handleEditClicked, tNoPrefix, showEditButton],
  )

  const cancelButton = useMemo(
    () => <CancelButtonWithPopover showPopover={hasChanges} onCancelClicked={onCancel} />,
    [hasChanges, onCancel],
  )

  const saveButton = useMemo(
    () => (
      <Button
        onClick={onSaveClicked}
        design={ButtonDesign.Emphasized}
        disabled={!hasChanges || isDataUploading}
      >
        {tNoPrefix('buttons.save')}
      </Button>
    ),
    [onSaveClicked, hasChanges, isDataUploading, tNoPrefix],
  )

  const actions = useMemo(() => {
    if (isEditMode) {
      return (
        <FlexBox className={styles.tinyGap}>
          {saveButton}
          {cancelButton}
        </FlexBox>
      )
    }
    return editButton
  }, [saveButton, cancelButton, editButton, isEditMode])

  const header = useMemo(
    () => (
      <FlexBox
        direction={FlexBoxDirection.Row}
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        alignItems={FlexBoxAlignItems.Center}
        className={styles.header}
      >
        <FlexBox direction={FlexBoxDirection.Row}>
          <FlexBox direction={FlexBoxDirection.Column} className={styles.headerFlexBox}>
            <Title className={styles.headerText} role="heading" part="title">
              {t('title')}
            </Title>
            {lastUpdatedAt && lastUpdatedBy && (
              <LastEditedText lastUpdatedAt={lastUpdatedAt}>
                <StaffMemberByObjectIdOrEmail objectIdOrEmail={lastUpdatedBy} />
              </LastEditedText>
            )}
          </FlexBox>
        </FlexBox>
        {actions}
      </FlexBox>
    ),
    [actions, lastUpdatedAt, lastUpdatedBy, t],
  )

  const cardContent = useMemo(() => {
    if (isEditMode) {
      return (
        <CWPCKEditor
          setCurrentContent={setEditedMinutes}
          currentContent={editedMinutes}
          toolbarConfig={toolbarConfigWithImages}
          setIsDataUploading={setIsDataUploading}
        />
      )
    }

    if (!minutes || minutes === emptyContent) {
      return <EmptyCardContent title={t('empty.title')} subtitle={t('empty.subtitle')} />
    }

    return <FormattedTextView formattedText={minutes} />
  }, [isEditMode, minutes, editedMinutes, setIsDataUploading, t])

  return <Card header={header}>{cardContent}</Card>
}

DecisionStageMinutesCard.propTypes = {
  decisionStageId: PropTypes.string.isRequired,
  minutes: PropTypes.string,
  lastUpdatedAt: PropTypes.string,
  lastUpdatedBy: PropTypes.string,
  decisionStatus: PropTypes.oneOf(Object.values(decisionProcessMinutesDecisionStatus)).isRequired,
  isPdfView: PropTypes.bool,
}

export default DecisionStageMinutesCard
