import {
  CustomListItem,
  FlexBox,
  FlexBoxJustifyContent,
  ListItemType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/fields/OpsMemoTranchePricingListItem.module.css'

const OpsMemoTranchePricingListItem = ({
  label,
  value,
  isBold,
  isSectionTitle = false,
  isConvertedAmount = false,
  isLabelOnly = false,
}) => (
  <CustomListItem
    className={isSectionTitle ? styles.sectionBold : styles.section}
    type={ListItemType.Inactive}
  >
    <FlexBox
      fitContainer
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      className={styles.boxWrapper}
    >
      <div className={isBold ? styles.textBold : ''}>{label}</div>
      {!isLabelOnly && (
        <FlexBox className={styles.valueWrapper}>
          <div
            className={`${isBold ? styles.textBold : ''} ${
              isConvertedAmount ? styles.textConvertedAmount : ''
            }`}
          >
            {value ? value : '-'}
          </div>
        </FlexBox>
      )}
    </FlexBox>
  </CustomListItem>
)

OpsMemoTranchePricingListItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  isBold: PropTypes.bool,
  isSectionTitle: PropTypes.bool,
  isConvertedAmount: PropTypes.bool,
  isLabelOnly: PropTypes.bool,
}

export default OpsMemoTranchePricingListItem
