import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TextField from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/fields/TextField'

const DealInformationTile = ({ tileId }) => {
  const tileState = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const dealData = tileState?.data?.dealData || {}
  const applicationTypesData = tileState?.data?.applicationTypesData || []
  const dealTypes = tileState?.data?.dealTypes || []
  const financialRole = tileState?.data?.financialRoleData
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.ops-memo-deal-information',
  })

  const renderApplicationType = () => {
    const applicationType = applicationTypesData.find(
      (type) => type.code === dealData?.applicationTypeCode,
    )

    return (
      <TextField
        label={t('application-type')}
        value={applicationType ? applicationType?.name : null}
      />
    )
  }

  const renderDealType = () => {
    const dealType = dealTypes.find((type) => type.code === dealData?.dealTypeCode)

    return <TextField label={t('deal-type')} value={dealType ? dealType?.name : null} />
  }

  return (
    <>
      <TextField label={t('deal-name')} value={dealData?.name} isFirstItem={true} />
      <TextField label={t('deal-id')} value={dealData?.dealId} />
      <TextField label={t('business-segment')} value={dealData?.businessSegmentCode} />
      {renderDealType()}
      {renderApplicationType()}
      <TextField label={t('sap-portfolio-identifier')} value={dealData?.bookingLocationCode} />
      <TextField label={t('financial-role')} value={financialRole} />
      <TextField label={t('contract-law')} value={dealData?.contractLaw} />
    </>
  )
}

DealInformationTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default DealInformationTile
