import { saveAs } from 'file-saver'
import { useCallback, useMemo } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

const useExportMarketProperties = () => {
  const { get } = useAccessTokenRequest()

  const downloadFunction = useCallback(
    ({ marketId, onError, onSuccess }) =>
      get({
        path: `/markets/${marketId}/properties/export`,
        headers: { Accept: mimeType },
      })
        .then(({ data, fileName }) => {
          saveAs(data, fileName)
          onSuccess?.(data, fileName)
        })
        .catch((error) => {
          onError?.(error)
        }),
    [get],
  )
  return useMemo(
    () => ({
      download: downloadFunction,
    }),
    [downloadFunction],
  )
}

export default useExportMarketProperties
