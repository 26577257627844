import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useCustomAssessments = ({ entityType, page }, options = {}) => {
  const queryParams = new URLSearchParams()
  const keys = ['central-data', 'assessments', 'codes', entityType]
  queryParams.append('entity_type', entityType)
  if (page) {
    queryParams.append('page', page)
    keys.push(page)
  }

  return useCamelizedResponse(
    useRequest({
      path: `/central-data/assessments/codes?${queryParams}`,
      useCache: true,
      keys,
      options,
    }),
  )
}

export default useCustomAssessments
