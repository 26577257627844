import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useGetMilestones from 'hooks/services/deals/milestones/useGetMilestones'
import { useGetMilestoneCategories } from 'hooks/services/deals/milestones/useMilestoneCategories'
import useDealMini from 'hooks/services/deals/useDealMini'

const useMilestones = ({ entityRef: { entityId: dealUuid } }, _, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    data: { milestones = [] } = {},
    isFetching: isFetchingMilestones,
    isError: isErrorMilestones,
  } = useGetMilestones({ dealUuid: dealUuidByTileCode })

  const {
    data: { options = [] } = {},
    isFetching: isFetchingMilestoneCategories,
    isError: isErrorMilestoneCategories,
  } = useGetMilestoneCategories()

  const {
    isFetching: isDealFetching,
    isError: isDealError,
    data: { dealId } = {},
  } = useDealMini(dealUuidByTileCode)

  const tableData = useMemo(() => {
    const findCategoryNameByCode = (milestoneCategoryCode) =>
      options.find(({ categoryCode }) => categoryCode === milestoneCategoryCode)?.categoryName ??
      milestoneCategoryCode
    const findMilestoneNameByCode = (milestoneCategoryCode, typeCode) =>
      options
        .find(({ categoryCode }) => categoryCode === milestoneCategoryCode)
        ?.types?.find(({ code }) => code === typeCode)?.name ?? typeCode

    return milestones.map(
      ({ milestoneUuid, categoryCode, customTypeName, typeCode, targetDate }) => ({
        rowKey: milestoneUuid
          ? `milestone-${milestoneUuid}`
          : `milestone-${typeCode}-${targetDate}`,
        milestone: { value: customTypeName ?? findMilestoneNameByCode(categoryCode, typeCode) },
        category: { value: findCategoryNameByCode(categoryCode) },
        targetDate: { value: targetDate },
      }),
    )
  }, [milestones, options])

  const sortedTableData = useMemo(
    () =>
      tableData.sort(
        (a, b) => new Date(a.targetDate.value || 0) - new Date(b.targetDate.value || 0),
      ),
    [tableData],
  )

  const sourcePath = useMemo(
    () =>
      isWorkingVersionDataTile
        ? `/deals/${dealId}?working-version=true#manager-tab`
        : `/deals/${dealId}#manager-tab`,
    [dealId, isWorkingVersionDataTile],
  )

  return useMemo(() => {
    const isLoading =
      isFetchingMilestones || isFetchingMilestoneCategories || isDealFetching || isDealUuidFetching
    const isError =
      isErrorMilestones || isErrorMilestoneCategories || isDealError || isDealUuidError

    if (isLoading || isError) {
      return { isLoading, isError, data: undefined }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        sourceRender: { path: `${sourcePath}` },
        tableData: sortedTableData,
        noMilestones: isEmpty(milestones),
      },
    }
  }, [
    isDealError,
    isDealFetching,
    isDealUuidError,
    isDealUuidFetching,
    isErrorMilestoneCategories,
    isErrorMilestones,
    isFetchingMilestoneCategories,
    isFetchingMilestones,
    milestones,
    sortedTableData,
    sourcePath,
  ])
}
export default useMilestones
