import { Modals, SegmentedButton, SegmentedButtonItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'
import styles from 'routes/deals/financing/trancheDetails/financing-header/NewAndExistingBusinessSwitch.module.css'
import paths from 'routes/paths'

const NewAndExistingBusinessSwitch = ({
  isNewBusinessEnabled,
  isExistingBusinessEnabled,
  trancheData,
  dealDisplayId,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.tranches.details.header.button',
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const showToast = Modals.useShowToast()

  useEffect(() => {
    if (!searchParams.get('dataSource')) {
      const updatedSearchParams = new URLSearchParams(searchParams.toString())
      updatedSearchParams.set('dataSource', DATA_SOURCES.EXISTING_BUSINESS)
      setSearchParams(updatedSearchParams.toString())
    }
  }, [searchParams, setSearchParams])

  const switchButton = (event) => {
    const selectedItem = event.detail?.selectedItem.getAttribute('data-key')

    if (selectedItem === 'existing-business-button') {
      showToast({ children: t('message.switch-to-existing-business-view') })
      if (trancheData) {
        navigate(
          `/${paths.deals}/${dealDisplayId}/financing/tranches/${trancheData?.externalContractId?.[0]}?dataSource=${DATA_SOURCES.EXISTING_BUSINESS}}`,
        )
      }
      setSearchParams({ dataSource: DATA_SOURCES.EXISTING_BUSINESS })
    } else {
      showToast({ children: t('message.switch-to-new-business-view') })
      if (trancheData) {
        // display_id´s from CML come with zeros sometimes. For example: "0000000000442",
        navigate(
          `/${paths.deals}/${dealDisplayId}/financing/tranches/${trancheData?.displayId.replace(
            /^0+/,
            '',
          )}?dataSource=${DATA_SOURCES.NEW_BUSINESS}}`,
        )
      }
      setSearchParams({ dataSource: DATA_SOURCES.NEW_BUSINESS })
    }
  }

  const isNewBusinessViewSet = searchParams.get('dataSource') === DATA_SOURCES.NEW_BUSINESS

  return (
    <SegmentedButton
      data-testid="new-and-existing-business-switch"
      className={styles.switchButton}
      onSelectionChange={switchButton}
    >
      <SegmentedButtonItem
        className={`${!isNewBusinessEnabled && styles.switchButtonDisabled}`}
        pressed={isNewBusinessViewSet}
        disabled={!isNewBusinessEnabled}
      >
        {t('new-business')}
      </SegmentedButtonItem>

      <SegmentedButtonItem
        className={`${
          !isExistingBusinessEnabled
            ? styles.switchButtonDisabled
            : styles.existingBusinessButtonItem
        }`}
        data-key="existing-business-button"
        pressed={!isNewBusinessViewSet}
        disabled={!isExistingBusinessEnabled}
      >
        {t('existing-business')}
      </SegmentedButtonItem>
    </SegmentedButton>
  )
}

NewAndExistingBusinessSwitch.defaultProps = {
  isNewBusinessEnabled: true,
  isExistingBusinessEnabled: true,
}

NewAndExistingBusinessSwitch.propTypes = {
  isNewBusinessEnabled: PropTypes.bool,
  isExistingBusinessEnabled: PropTypes.bool,
  trancheData: PropTypes.object,
  dealDisplayId: PropTypes.string,
}

export default NewAndExistingBusinessSwitch
