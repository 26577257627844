import {
  CheckBox,
  FlexBox,
  FlexBoxJustifyContent,
  Input,
  TableRow,
  TableRowType,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import BreachSelect from 'components/domains/deals/covenant-check-detail/checked-covenants/BreachSelect'
import { monitoringItemShape } from 'components/domains/deals/covenant-check-detail/checked-covenants/CheckedCovenantPropTypes'
import LimitValueEditCell from 'components/domains/deals/covenant-check-detail/checked-covenants/LimitValueEditCell'
import { breachOptions } from 'components/domains/deals/covenant-check-detail/checked-covenants/constants'
import useCalculateBreach from 'components/domains/deals/covenant-check-detail/checked-covenants/useCalculateBreach'
import { useBreachTranslation } from 'components/domains/deals/covenants/shared/useBreachTranslations'
import useCovenantTypeTranslations from 'components/domains/deals/covenants/shared/useCovenantTypeTranslations'
import useStatusTranslations from 'components/domains/deals/covenants/shared/useStatusTranslations'
import SaveAndCancelButton from 'components/domains/deals/tables/SaveAndCancelButton'
import DatePickerWithoutMinWidth from 'components/ui/date-picker/DatePickerWithoutMinWidth'
import TableCell from 'components/ui/tables/cells/TableCell'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const CheckedCovenantEditRowCells = ({
  monitoringItem,
  formState,
  updateFormState,
  isFormStateValid,
  isNewDeliveryDateValid,
  onCancel,
  executeUpdateMonitoringItem,
  ...props
}) => {
  const { getTranslationForType } = useCovenantTypeTranslations()
  const { getTranslationForBreach } = useBreachTranslation()
  const { localePattern, format: formatDate, parse: parseDate } = useShortDateFormatter()

  const {
    covenant,
    status,
    dueDate,
    newTargetDeliveryDate,
    softLimit,
    hardLimit,
    result,
    breach,
    breachRule,
    waived,
  } = monitoringItem ?? {}

  const { getTranslationForStatus } = useStatusTranslations()
  const { getCalculatedBreach } = useCalculateBreach()

  const calculatedBreach = useMemo(
    () => getCalculatedBreach(breachRule, hardLimit?.value, softLimit?.value, formState?.result),
    [breachRule, formState?.result, getCalculatedBreach, hardLimit?.value, softLimit?.value],
  )

  const isWaiverClickable = [breachOptions.YES, breachOptions.SOFT, breachOptions.HARD].includes(
    formState?.breach,
  )

  useEffect(() => {
    if (!breachRule) return
    if (formState?.newTargetDeliveryDate) return
    if (calculatedBreach === formState?.breach) return
    if (monitoringItem?.result?.value === formState?.result) return
    updateFormState({
      breach: calculatedBreach,
      waived: [breachOptions.YES, breachOptions.SOFT, breachOptions.HARD].includes(calculatedBreach)
        ? !!formState?.waived
        : false,
    })
  }, [
    breachRule,
    calculatedBreach,
    formState?.breach,
    formState?.newTargetDeliveryDate,
    formState?.result,
    formState?.waived,
    isWaiverClickable,
    monitoringItem?.result?.value,
    updateFormState,
  ])

  useEffect(() => {
    updateFormState({
      breach: calculatedBreach,
      waived: [breachOptions.YES, breachOptions.SOFT, breachOptions.HARD].includes(calculatedBreach)
        ? !!formState?.waived
        : false,
    })
  }, [calculatedBreach])

  const isCovenantTypeFinancial = covenant?.type === 'FINANCIAL'

  return (
    <TableRow type={TableRowType.Inactive} {...props}>
      <TableCell>
        <Input id="covenant" readonly value={`${covenant?.name}`} />
      </TableCell>
      <TableCell>
        <Input id="covenant-type" readonly value={getTranslationForType(covenant?.type)} />
      </TableCell>
      <TableCell>
        <Input id="status" readonly value={getTranslationForStatus(status)} />
      </TableCell>
      <TableCell>
        <FlexBox justifyContent={FlexBoxJustifyContent.End}>
          <Input id="delivery-date" readonly value={formatDate(dueDate?.targetDeliveryDate)} />
        </FlexBox>
      </TableCell>
      <TableCell>
        <FlexBox justifyContent={FlexBoxJustifyContent.End}>
          <DatePickerWithoutMinWidth
            fixedWidth="120px"
            id="new-target-delivery-date"
            value={formState?.newTargetDeliveryDate}
            valueState={
              !!formState?.newTargetDeliveryDate &&
              !isNewDeliveryDateValid &&
              !formState.breach &&
              !formState.result
                ? ValueState.Error
                : ValueState.None
            }
            formatPattern={localePattern}
            onChange={(event) =>
              updateFormState({
                newTargetDeliveryDate:
                  parseDate(event.target.value, localePattern) ?? event.target.value,
                result: null,
                breach: event.target.value ? 'POSTPONED' : null,
                waived: false,
              })
            }
          />
        </FlexBox>
      </TableCell>
      <TableCell>
        {isCovenantTypeFinancial && (
          <FlexBox justifyContent={FlexBoxJustifyContent.End}>
            <LimitValueEditCell id="soft-limit-value" limit={softLimit} readonly />
          </FlexBox>
        )}
      </TableCell>
      <TableCell>
        {isCovenantTypeFinancial && (
          <FlexBox justifyContent={FlexBoxJustifyContent.End}>
            <LimitValueEditCell id="hard-limit-value" limit={hardLimit} readonly />
          </FlexBox>
        )}
      </TableCell>
      <TableCell>
        {isCovenantTypeFinancial && (
          <FlexBox justifyContent={FlexBoxJustifyContent.End}>
            <LimitValueEditCell
              id="result-value"
              limit={{ ...result, value: formState?.result }}
              readonly={!!formState?.newTargetDeliveryDate}
              onChange={(value) => updateFormState({ result: value })}
            />
          </FlexBox>
        )}
      </TableCell>
      <TableCell>
        {formState?.breach === 'POSTPONED' && (
          <Input readonly value={getTranslationForBreach(formState.breach)} />
        )}
        {formState?.breach !== 'POSTPONED' && (
          <BreachSelect
            disabled={!!breachRule || !!formState?.newTargetDeliveryDate}
            onChange={(value) => updateFormState({ breach: value })}
            value={formState?.breach}
            isFinancial={isCovenantTypeFinancial}
          />
        )}
      </TableCell>
      <TableCell>
        <CheckBox
          disabled={!isWaiverClickable}
          checked={!!formState?.waived}
          onChange={() => updateFormState({ waived: !formState?.waived })}
        />
      </TableCell>
      <TableCell>
        <FlexBox justifyContent={FlexBoxJustifyContent.End}>
          <SaveAndCancelButton
            onSave={executeUpdateMonitoringItem}
            onCancel={onCancel}
            saveDisabled={!isFormStateValid}
            isLoading={false}
            showCancelPopover={
              formState?.breach !== breach ||
              formState?.result !== result?.value ||
              formState?.newTargetDeliveryDate !== newTargetDeliveryDate ||
              formState?.waived !== waived
            }
          />
        </FlexBox>
      </TableCell>
    </TableRow>
  )
}

CheckedCovenantEditRowCells.propTypes = {
  monitoringItem: monitoringItemShape,
  formState: PropTypes.shape({
    breach: PropTypes.string,
    result: PropTypes.number,
    newTargetDeliveryDate: PropTypes.string,
    waived: PropTypes.bool,
  }),
  updateFormState: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isFormStateValid: PropTypes.bool,
  isNewDeliveryDateValid: PropTypes.bool,
  executeUpdateMonitoringItem: PropTypes.func.isRequired,
}

export default CheckedCovenantEditRowCells
