import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

export const useMultipleDealsById = (dealUuids = [], options = {}) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: dealUuids.map((dealUuid) => ({
        path: `/deals/${dealUuid}`,
        keys: ['deals', dealUuid],
      })),
      options,
    }),
  )
  return useMemo(
    () => responses.map((result) => ({ ...result, data: camelize(result.data) })),
    [responses],
  )
}
