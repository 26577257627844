import { useState } from 'react'

/**
 * @param {object} [params]
 * @param {() => void} [params.onOpen]
 * @param {() => void} [params.onCancel]
 * @param {() => void} [params.onConfirm]
 * @param {boolean} [params.initiallyOpen]
 */
const useConfirmationDialogState = ({
  onOpen = () => {},
  onCancel = () => {},
  onConfirm = () => {},
  initiallyOpen = false,
} = {}) => {
  const [isOpen, setIsOpen] = useState(initiallyOpen)

  const handleOpen = () => {
    setIsOpen(true)
    onOpen()
  }

  const handleCancel = () => {
    setIsOpen(false)
    onCancel()
  }

  const handleConfirm = () => {
    setIsOpen(false)
    onConfirm()
  }

  return { handleCancel, handleConfirm, handleOpen, isOpen }
}

export default useConfirmationDialogState
