import { Table, TableColumn, TableRow } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsTable.module.css'
import PropertyRentRollSegmentsTableGroupRow from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsTableGroupRow'
import {
  isDeletable,
  findAreaMeasureUnitCodeByPropertyUuid,
  findSegmentKpisByPropertyUuid,
} from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsUtils'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import TableCell from 'components/ui/tables/cells/TableCell'
import {
  SEGMENT_TABLE_COLUMN_KEYS,
  useSegmentsTableColumnDefinitions,
} from 'hooks/services/properties/segments/segments-table/useSegmentsTableColumnDefinitions'
import { useSegmentUsageTypes } from 'hooks/services/properties/segments/useSegmentUsageTypes'

const PropertyRentRollSegmentsTable = ({
  inEditMode,
  properties,
  segments,
  segmentsInEdit,
  onSegmentInEditChange,
  multiPropertySegmentKpis,
  period,
  selectedSegments,
  onSelectSegment,
  onUnselectSegment,
  onSelectAll,
  onUnselectAll,
  onSelectAllByProperty,
  onUnselectAllByProperty,
  onAddByProperty,
}) => {
  const isMultiProperty = properties.length > 1
  const MARKET_RENT_AMOUNT = 'market_rent_amount'
  const VACANCY_COST_AMOUNT = 'vacancy_cost_amount'
  const propertyUuids = properties.map(({ uuid }) => uuid)

  // Hooks
  const { t: tSegments } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.segments',
  })

  const {
    isLoading: isLoadingSegmentUsageTypes,
    isError: isErrorSegmentUsageTypes,
    data: usageTypeCodes,
  } = useSegmentUsageTypes()

  // Functions
  const findSegmentInEdit = (segmentUuid) =>
    segmentsInEdit?.find((segmentInEdit) => segmentInEdit.uuid === segmentUuid)

  const updateSegmentInEdit = (segmentInEdit, updatedFields) => {
    Object.assign(segmentInEdit, updatedFields)
    onSegmentInEditChange(segmentInEdit)
  }

  // Multi property specific functions

  const findCurrencyCodeByPropertyUuid = (propertyUuid) => {
    const property = properties.find((p) => p.uuid === propertyUuid)
    if (properties.length === 1) {
      return property?.currency_code
    } else {
      return property?.headquarter_currency
    }
  }

  // Handlers
  const handleAllSelectChange = (e) => {
    if (e.target.checked) {
      onSelectAll()
    } else {
      onUnselectAll()
    }
  }

  const handleSelectChange = (segment, e) => {
    if (e.target.checked) {
      onSelectSegment(segment.uuid)
    } else {
      onUnselectSegment(segment.uuid)
    }
  }

  const handleNameChange = (segmentUuid, value) => {
    const segmentInEdit = findSegmentInEdit(segmentUuid)
    updateSegmentInEdit(segmentInEdit, { name: value })
  }

  const handleUsageTypeChange = (segmentUuid, value) => {
    const segmentInEdit = findSegmentInEdit(segmentUuid)
    const usageType = usageTypeCodes.find((usageTypeCode) => usageTypeCode.key === value)
    updateSegmentInEdit(segmentInEdit, {
      usage_type_code: value || null,
      usage_type_name: usageType?.display_name || null,
    })
  }

  const handleDescriptionChange = (segmentUuid, value) => {
    const segmentInEdit = findSegmentInEdit(segmentUuid)
    updateSegmentInEdit(segmentInEdit, { description: value })
  }

  const handleTotalAreaChange = (segmentUuid, value) => {
    const segmentInEdit = findSegmentInEdit(segmentUuid)
    updateSegmentInEdit(segmentInEdit, {
      total_area: value,
      area_measure_unit_code:
        segmentInEdit.area_measure_unit_code ||
        findAreaMeasureUnitCodeByPropertyUuid(segmentInEdit.property_uuid, properties),
    })
  }

  const handleAmountChange = (segmentUuid, value, amountKey) => {
    const segmentInEdit = findSegmentInEdit(segmentUuid)
    const currencyCode = findCurrencyCodeByPropertyUuid(segmentInEdit.property_uuid)
    updateSegmentInEdit(segmentInEdit, {
      [amountKey]: {
        // NOTE: There is currently a CMS defect, where it is not possible to set the value to 0
        number: value ?? 0, // Workaround until JsonNullable is used in the backend and the CMS defect is fixed
        currency: currencyCode,
      },
    })
  }

  // Dependent Hooks
  const tableColumnDefinitions = useSegmentsTableColumnDefinitions(
    properties,
    segments,
    segmentsInEdit,
    selectedSegments,
    multiPropertySegmentKpis,
    usageTypeCodes,
    period,
    handleAllSelectChange,
    handleSelectChange,
    handleNameChange,
    handleUsageTypeChange,
    handleDescriptionChange,
    handleTotalAreaChange,
    (segmentUuid, value) => handleAmountChange(segmentUuid, value, MARKET_RENT_AMOUNT),
    (segmentUuid, value) => handleAmountChange(segmentUuid, value, VACANCY_COST_AMOUNT),
  )

  // Rendering functions
  const renderEditOrViewCell = (...args) => {
    const [column, ...otherArgs] = args
    return inEditMode ? column.cellValueEdit(...otherArgs) : column.cellValue(...otherArgs)
  }

  const renderCellValue = (column, segment, segmentKpis) => {
    switch (column.columnKey) {
      case SEGMENT_TABLE_COLUMN_KEYS.SELECT: {
        const totalAreaSurface = segmentKpis?.kpis?.find((kpi) => kpi.segment_uuid === segment.uuid)
          ?.total_area_surface?.value
        const selectable = !totalAreaSurface || totalAreaSurface <= 0
        return renderEditOrViewCell(column, segment, selectable)
      }
      case SEGMENT_TABLE_COLUMN_KEYS.AREA_RENT_ROLL:
        return renderEditOrViewCell(column, segment, segmentKpis)
      default:
        return renderEditOrViewCell(column, segment)
    }
  }

  const renderTableRow = (segment, hideType) => (
    <TableRow key={segment.uuid}>
      {tableColumnDefinitions.map((column) => {
        if (!column[hideType]) {
          let additionalTableCellClassNames
          if (inEditMode) {
            additionalTableCellClassNames = !column.editModeTableCellClassNames
              ? ''
              : styles[column.editModeTableCellClassNames]
          } else {
            additionalTableCellClassNames = !column.viewModeTableCellClassNames
              ? ''
              : styles[column.viewModeTableCellClassNames]
          }
          return (
            <TableCell
              className={`${styles.tableCellPadding} ${additionalTableCellClassNames}`.trim()}
              key={`${segment.uuid}-${column.columnKey}`}
            >
              {renderCellValue(
                column,
                segment,
                findSegmentKpisByPropertyUuid(segment.property_uuid, multiPropertySegmentKpis),
              )}
            </TableCell>
          )
        }
      })}
    </TableRow>
  )

  const renderTableGroupRow = (propertyUuid, groupedSegments, isInEdit, onAdd) => (
    <React.Fragment key={propertyUuid}>
      <PropertyRentRollSegmentsTableGroupRow
        properties={properties}
        propertyUuid={propertyUuid}
        groupedSegments={groupedSegments}
        selectedSegments={selectedSegments}
        multiPropertySegmentKpis={multiPropertySegmentKpis}
        isInEdit={isInEdit}
        onAdd={onAdd}
        onSelectAllByProperty={onSelectAllByProperty}
        onUnselectAllByProperty={onUnselectAllByProperty}
      />
      {groupedSegments.map((segment) =>
        renderTableRow(segment, isInEdit ? 'hideInEdit' : 'hideInView'),
      )}
    </React.Fragment>
  )

  const renderTableGroupRowView = (propertyUuid, groupedSegments) =>
    renderTableGroupRow(propertyUuid, groupedSegments, false)

  const renderTableGroupRowEdit = (propertyUuid, groupedSegmentsInEdit) =>
    renderTableGroupRow(propertyUuid, groupedSegmentsInEdit, true, onAddByProperty)

  const groupByPropertyUuids = (segmentsToGroup) => {
    const initialPropertyUuidsToSegments = {}
    propertyUuids.forEach((propertyUuid) => {
      initialPropertyUuidsToSegments[propertyUuid] = []
    })
    return segmentsToGroup.reduce((propertyUuidsToSegments, segment) => {
      propertyUuidsToSegments[segment.property_uuid].push(segment)
      return propertyUuidsToSegments
    }, initialPropertyUuidsToSegments)
  }

  const renderTableRows = (
    <>
      {isMultiProperty &&
        Object.entries(
          inEditMode ? groupByPropertyUuids(segmentsInEdit) : groupByPropertyUuids(segments),
        ).map(([propertyUuid, groupedSegments]) =>
          inEditMode
            ? renderTableGroupRowEdit(propertyUuid, groupedSegments)
            : renderTableGroupRowView(propertyUuid, groupedSegments),
        )}
      {!isMultiProperty &&
        (inEditMode
          ? segmentsInEdit.map((segment) => renderTableRow(segment, 'hideInEdit'))
          : segments.map((segment) => renderTableRow(segment, 'hideInView')))}
    </>
  )

  const isSelectableWhenAtLeastOneDeletable = () =>
    segmentsInEdit.some((segment) =>
      isDeletable(
        segment?.uuid,
        findSegmentKpisByPropertyUuid(segment.property_uuid, multiPropertySegmentKpis),
      ),
    )

  return (
    <LoadingStateWrapper
      isLoading={isLoadingSegmentUsageTypes}
      isError={isErrorSegmentUsageTypes}
      renderContent={() => (
        <Table
          noDataText={tSegments('no-data')}
          className="property-rent-roll-segments-table"
          columns={tableColumnDefinitions.map((column) => {
            if (inEditMode && !column.hideInEdit) {
              if (column.columnKey === 'select') {
                return (
                  <TableColumn key={column.columnKey}>
                    {column.title(isSelectableWhenAtLeastOneDeletable())}
                  </TableColumn>
                )
              }
              return <TableColumn key={column.columnKey}>{column.title()}</TableColumn>
            } else if (!inEditMode && !column.hideInView) {
              return <TableColumn key={column.columnKey}>{column.title()}</TableColumn>
            }
          })}
        >
          {renderTableRows}
        </Table>
      )}
    />
  )
}
PropertyRentRollSegmentsTable.propTypes = {
  inEditMode: PropTypes.bool.isRequired,
  properties: PropTypes.array,
  segments: PropTypes.array,
  segmentsInEdit: PropTypes.array,
  onSegmentInEditChange: PropTypes.func.isRequired,
  multiPropertySegmentKpis: PropTypes.array,
  currencyCodes: PropTypes.array,
  areaMeasureUnitCodes: PropTypes.array,
  period: PropTypes.string,
  selectedSegments: PropTypes.array.isRequired,
  onSelectSegment: PropTypes.func.isRequired,
  onUnselectSegment: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onUnselectAll: PropTypes.func.isRequired,
  onSelectAllByProperty: PropTypes.func,
  onUnselectAllByProperty: PropTypes.func,
  onAddByProperty: PropTypes.func,
}
export default PropertyRentRollSegmentsTable
