const mapSelectedRowIds = (mergedTableData) => {
  let clusterId = 0
  let optionId = 0
  let attributeId = 0

  const selectedRowIds = {}

  mergedTableData.forEach((cluster) => {
    let hasInactiveOption = false
    cluster.subRows.forEach((option) => {
      if (option.isActive) {
        selectedRowIds[`${clusterId}.${optionId}`] = true
        option.subRows.forEach(() => {
          selectedRowIds[`${clusterId}.${optionId}.${attributeId}`] = true
          attributeId++
        })
        attributeId = 0
      } else {
        hasInactiveOption = true
      }
      optionId++
    })
    if (!hasInactiveOption) {
      selectedRowIds[`${clusterId}`] = true
    }

    clusterId++
    optionId = 0
    attributeId = 0
  })

  return selectedRowIds
}

export default mapSelectedRowIds
