import { Button, Menu, MenuItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectTypes from 'components/domains/properties/valuation/table/table-cells/edit/ValuationRequestTableSelectReportButtonTypes'

const buttonId = 'valuation-requests-table-select-report-button'

const ValuationRequestsTableSelectReportButton = ({ onSelect = () => {}, ...props }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.valuation.requests.table.buttons',
  })
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const onAddButtonClick = useCallback(() => {
    setIsMenuOpen(true)
  }, [])

  const handleMenuClose = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  return (
    <>
      <Button id={buttonId} onClick={onAddButtonClick} icon="slim-arrow-down" iconEnd {...props}>
        {t('select-report-button')}
      </Button>
      {isMenuOpen && (
        <Menu
          opener={buttonId}
          open={isMenuOpen}
          onItemClick={onSelect}
          onAfterClose={handleMenuClose}
        >
          <MenuItem text={t('select-report.select-document')} data-button-id={SelectTypes.Select} />
          <MenuItem text={t('select-report.upload-document')} data-button-id={SelectTypes.Upload} />
        </Menu>
      )}
    </>
  )
}

ValuationRequestsTableSelectReportButton.propTypes = {
  onSelect: PropTypes.func,
}

export default ValuationRequestsTableSelectReportButton
