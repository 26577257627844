import { isMissingPermissionError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useAuthorityLevelCalculatorComment = (businessPartnerId, unitHeadBpId, unitTypeId) =>
  useCamelizedResponse(
    useRequest({
      path: `/pbb-custom-service/businesspartners/${businessPartnerId}/authority-level-calculator-comment?unitHeadBpId=${unitHeadBpId}&unitTypeId=${unitTypeId}`,
      translated: true,
      keys: [
        'businesspartners',
        businessPartnerId,
        'authority-level-calculator-comment',
        'unitHead',
        unitHeadBpId,
        'unitType',
        unitTypeId,
      ],
      options: {
        enabled: !!unitTypeId && !!unitHeadBpId,
        retry: (_, error) => !isMissingPermissionError(error),
      },
    }),
  )

export default useAuthorityLevelCalculatorComment
