import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { valuationClassificationTranslated } from 'api/property/valuation/valuationRequests'
import { convertScreamingSnakeCaseToTitle } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/helper/convertScreamingSnakeCaseToTitle'
import TextCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/TextCell'

const PropertyValuationResultsValuationTypeCell = ({ valuationType }) => {
  const { t } = useTranslation('decisionPaper')

  const getTranslatedValuationType = (type) => {
    const translationKey = valuationClassificationTranslated?.[type]
    if (!translationKey) {
      return convertScreamingSnakeCaseToTitle(type)
    }
    return t(translationKey)
  }

  return <TextCell text={getTranslatedValuationType(valuationType)} />
}

PropertyValuationResultsValuationTypeCell.propTypes = {
  valuationType: PropTypes.string,
}

export default PropertyValuationResultsValuationTypeCell
