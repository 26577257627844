/* eslint-disable no-magic-numbers */
import { ObjectStatus, ValueState } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const renderDisplayModeRatingStatusColumnCell = (rating, tLabel) => {
  if (!rating) return null
  let state
  switch (rating) {
    case 1:
    case 2:
    case 3:
    case 4:
      state = ValueState.Success
      break
    case 5:
    case 6:
    case 7:
      state = ValueState.Warning
      break
    case 8:
    case 9:
    case 10:
      state = ValueState.Error
      break
    default:
      state = ValueState.None
      break
  }

  return (
    <ObjectStatus inverted state={state}>
      {tLabel(`${rating}`)}
    </ObjectStatus>
  )
}

const PropertyRatingTableCell = ({ rating }) => {
  const { t: tLabel } = useTranslation('translation', {
    keyPrefix: 'pages.property.rating.label',
  })
  return renderDisplayModeRatingStatusColumnCell(rating, tLabel)
}

PropertyRatingTableCell.propTypes = {
  rating: PropTypes.number,
}

export default PropertyRatingTableCell
