import PropTypes from 'prop-types'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'

const CurrencyCell = ({ cell: { value = {} } }) => {
  const formatCurrency = useCustomizableCurrencyFormatter()
  const money = formatCurrency(value.number, value.currency, { currencyDisplay: 'code' })

  return <>{money}</>
}

CurrencyCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.shape({
      number: PropTypes.number,
      currency: PropTypes.string,
    }),
  }).isRequired,
}

export default CurrencyCell
