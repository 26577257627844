const { useMemo } = require('react')
const { useTranslation } = require('react-i18next')

const useCustomizeDecisionPaperTemplateColumns = () => {
  const { t } = useTranslation('translation', {
    keyPrefix:
      'components.business-events-and-tasks.decision-papers.template-customization-dialog.table.columns',
  })
  return useMemo(
    () => [
      {
        Header: t('items'),
        accessor: 'name',
        disableDragAndDrop: true,
        disableResizing: true,
      },
      {
        Header: t('type'),
        accessor: 'type',
        disableDragAndDrop: true,
        disableResizing: true,
        width: 200,
      },
    ],
    [t],
  )
}

export default useCustomizeDecisionPaperTemplateColumns
