import { CustomListItem, ListItemType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/waiver/shared/DisplayCardViewItemView.module.css'

const DisplayCardViewItemView = ({ children }) => (
  <CustomListItem type={ListItemType.Inactive} className={styles.customListItem}>
    {children}
  </CustomListItem>
)

DisplayCardViewItemView.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}

export default DisplayCardViewItemView
