import { useMutation, useQueryClient } from '@tanstack/react-query'
import camelize from 'camelize'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const usePropertyCommentUpdate = ({ propertyUuid, onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()
  const queryClient = useQueryClient()

  const innerOnSuccess = (data) => {
    queryClient.invalidateQueries(['properties', propertyUuid, 'comment'])
    onSuccess?.(camelize(data))
  }

  return useMutation({
    mutationFn: async ({ content }) => {
      const { data } = await put({
        path: `/properties/${propertyUuid}/comment`,
        body: { content },
      })
      return data
    },
    onSuccess: innerOnSuccess,
    onError,
  })
}

export default usePropertyCommentUpdate
