import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  ComposedChart,
  Scatter,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { DefaultLegendContent } from 'recharts/es6/component/DefaultLegendContent'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared/PropertyRentRollOverviewRentalIncomeOverTimeChart.module.css'
import {
  useCurrencyFormatter,
  useNumberFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared/hooks/i18n/useI18n'
import useChartAnimation from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared/useChartAnimation'
import useHistoricalChartDataWithPadding from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared/useHistoricalChartDataWithPadding'
import useHistoricalChartXAxisTickConfig from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared/useHistoricalChartXAxisTickConfig'
import sharedChartStyles from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/ui/shared-chart-styles.module.css'

const STROKE_COLOR = '#FFF'
const AXIS_COLOR = '#9B9B9B'
const MARKET_RENT_INCL_VACANCY_COLOR = '#456270'
const MARKET_RENT_EXCL_VACANCY_COLOR = '#D3D9DC'

const marketRentKey = {
  inclVacancy: 'marketRentInclVacancy',
  exclVacancy: 'marketRentExclVacancy',
}

const marketRentKeys = Object.values(marketRentKey)

/**
 * by default the Recharts `Legend` component does not support
 * grouping legend intems into categories, therefore we need to
 * implement our own legend component
 */
const createLegendContent = (legendCategories) => {
  const LegendContent = (props) => (
    <div className={styles.legendContainer}>
      {legendCategories.map((category) => {
        const categoryPayload = props.payload.filter(category.payloadFilter)
        if (categoryPayload.length === 0) return null
        return (
          <div key={category.id} className={styles.legendCategory}>
            <p className={styles.legendCategoryTitle}>{category.title}</p>
            <DefaultLegendContent {...props} payload={categoryPayload} align="left" />
          </div>
        )
      })}
    </div>
  )

  LegendContent.propTypes = {
    payload: PropTypes.arrayOf(PropTypes.object),
  }

  return LegendContent
}

const PropertyRentRollOverviewRentalIncomeOverTimeChart = ({ data, usageTypes, currency }) => {
  const usageTypeKeys = useMemo(() => usageTypes.map(({ id }) => id), [usageTypes])

  const { dataWithEndPadding, TooltipForNonPaddingData } = useHistoricalChartDataWithPadding(
    data,
    usageTypeKeys,
  )
  const xAxisTickConfig = useHistoricalChartXAxisTickConfig(data)
  const animationProps = useChartAnimation()

  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 2,
    notation: 'compact',
  })

  const { format: formatDate } = useShortDateFormatter()
  const formatTooltipDate = useCallback(
    (timestamp) => formatDate(new Date(timestamp).toISOString()),
    [formatDate],
  )
  const formatTooltipRent = useCurrencyFormatter({ currency })

  const { t: tRentalIncomeOverTimeChartLegend } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.property.rent-roll.overview.rental-income-over-time.chart.legend',
  })

  const renderLegend = useMemo(() => {
    const legendCategories = [
      {
        id: 'marketRent',
        title: tRentalIncomeOverTimeChartLegend('market-rent'),
        payloadFilter: (legendItem) => marketRentKeys.includes(legendItem.dataKey),
      },
      {
        id: 'currentRentPerUsageType',
        title: tRentalIncomeOverTimeChartLegend('current-rent-per-usage-type'),
        payloadFilter: (legendItem) => !marketRentKeys.includes(legendItem.dataKey),
      },
    ]

    return createLegendContent(legendCategories)
  }, [tRentalIncomeOverTimeChartLegend])

  return (
    <ResponsiveContainer width="100%" aspect={2.2} maxHeight={400}>
      <ComposedChart
        data={dataWithEndPadding}
        margin={{ right: 20, top: 10 }}
        className={`${styles.rentalIncomeOverTimeChart} ${sharedChartStyles.cwpChart}`}
      >
        <CartesianGrid vertical={false} />

        <YAxis axisLine={false} tickLine={false} tickFormatter={formatNumber} />

        <XAxis
          dataKey="date"
          type="number"
          tickLine={false}
          domain={['dataMin', 'dataMax']}
          scale="time"
          {...xAxisTickConfig}
          padding={{
            left: 20,
            right: 20,
          }}
          axisLine={{
            stroke: AXIS_COLOR,
          }}
        />

        <Legend content={renderLegend} />

        {usageTypes.map(({ id: usageTypeId, name: usageTypeName, color: usageTypeColor }) => (
          <Area
            type="stepAfter"
            key={usageTypeId}
            dataKey={usageTypeId}
            fill={usageTypeColor}
            // recharts uses the "stroke" property to determine
            // the corresponding color in the legend
            stroke={usageTypeColor}
            // we actually want a different stroke color though,
            // hence we overwrite it via inline style
            style={{ stroke: STROKE_COLOR }}
            fillOpacity={1}
            strokeWidth={1}
            strokeOpacity={1}
            name={usageTypeName}
            stackId="usageType"
            legendType="square"
            {...animationProps}
          />
        ))}

        <Tooltip
          formatter={formatTooltipRent}
          labelFormatter={formatTooltipDate}
          content={TooltipForNonPaddingData}
        />

        <Scatter
          dataKey={marketRentKey.inclVacancy}
          fill={MARKET_RENT_INCL_VACANCY_COLOR}
          stroke={STROKE_COLOR}
          strokeWidth={1}
          name={tRentalIncomeOverTimeChartLegend('incl-vacancy')}
          {...animationProps}
        />

        <Scatter
          dataKey={marketRentKey.exclVacancy}
          fill={MARKET_RENT_EXCL_VACANCY_COLOR}
          stroke={STROKE_COLOR}
          strokeWidth={1}
          name={tRentalIncomeOverTimeChartLegend('excl-vacancy')}
          {...animationProps}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

PropertyRentRollOverviewRentalIncomeOverTimeChart.propTypes = {
  /** data points to be displayed in the chart */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      /** rent roll key date as unix timestamp */
      date: PropTypes.number.isRequired,
      marketRentInclVacancy: PropTypes.number.isRequired,
      marketRentExclVacancy: PropTypes.number.isRequired,
      /** additionally, there can be fields with rental income values for the individual usage types */
    }),
  ).isRequired,
  /** all usage types that will be display in the chart and the legend */
  usageTypes: PropTypes.arrayOf(
    PropTypes.shape({
      /** usage types id to match the data point rental income values */
      id: PropTypes.string.isRequired,
      /** name of the usage type that is displayed in the legend */
      name: PropTypes.string.isRequired,
      /** color of the usage type area */
      color: PropTypes.string.isRequired,
    }),
  ).isRequired,
  currency: PropTypes.string.isRequired,
}

export default PropertyRentRollOverviewRentalIncomeOverTimeChart
