import { useCallback, useContext } from 'react'
import { Contact } from 'components/domains/business-partners/tile/general-information/allowedOperations'
import KeyInternalContactFunctionsMultiSelect from 'components/domains/business-partners/tile/general-information/keyInternalContact/KeyInternalContactFunctionsMultiSelect'
import KeyInternalContactsTile from 'components/domains/business-partners/tile/general-information/keyInternalContact/KeyInternalContactsTile'
import useHandleKeyInternalContacts from 'components/domains/business-partners/tile/general-information/keyInternalContact/useHandleKeyInternalContacts'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

function KeyInternalContactsTileWrapper() {
  const { allowedOperations } = useContext(BusinessPartnerContext)
  const hasEditPermissions = !!allowedOperations?.includes(Contact.write)

  const {
    keyInternalContacts,
    addEmptyKeyInternalContact,
    saveKeyInternalContacts,
    editKeyInternalContact,
    removeKeyInternalContact,
    resetKeyInternalContacts,
    isErrorDialogOpen,
    setIsErrorDialogOpen,
    errorMessage,
    isLoading,
    isDirtyEdit,
  } = useHandleKeyInternalContacts()

  const renderMultiSelectComponent = useCallback(
    (data) => (
      <KeyInternalContactFunctionsMultiSelect
        userId={data?.userId}
        selected={data?.selected}
        onSelectionChange={data?.onSelectionChange}
      />
    ),
    [],
  )

  return (
    <KeyInternalContactsTile
      hasEditPermissions={hasEditPermissions}
      keyInternalContacts={keyInternalContacts}
      addEmptyKeyInternalContact={addEmptyKeyInternalContact}
      saveKeyInternalContacts={saveKeyInternalContacts}
      editKeyInternalContact={editKeyInternalContact}
      removeKeyInternalContact={removeKeyInternalContact}
      resetKeyInternalContacts={resetKeyInternalContacts}
      isErrorDialogOpen={isErrorDialogOpen}
      setIsErrorDialogOpen={setIsErrorDialogOpen}
      errorMessage={errorMessage}
      isLoading={isLoading}
      isDirtyEdit={isDirtyEdit}
      renderMultiSelectComponent={renderMultiSelectComponent}
    />
  )
}

KeyInternalContactsTileWrapper.propTypes = {}

export default KeyInternalContactsTileWrapper
