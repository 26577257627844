import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const RETRIES = 3

const useDecisionPaperSubsectionAssessmentVersions = ({
  eventId,
  decisionPaperVersion,
  subsectionId,
}) => {
  const version = decisionPaperVersion ? decisionPaperVersion : 'current'

  return useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/decision-paper/versions/${version}/subsections/${subsectionId}/assessment/versions`,
      useCache: true,
      keys: [
        'events',
        eventId,
        'decision-paper',
        'versions',
        version,
        'subsections',
        subsectionId,
        'assessment',
        'versions',
      ],
      options: {
        retry: (failureCount, error) => {
          if (isNotFoundError(error) || isMissingPermissionError(error)) {
            return false
          }
          if (failureCount < RETRIES) {
            return true
          }
          return false
        },
      },
    }),
  )
}

export default useDecisionPaperSubsectionAssessmentVersions
