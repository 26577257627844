import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useDecisionPaperAttachments from 'hooks/services/business-events-and-tasks/decision-papers/useDecisionPaperAttachments'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const useDecisionPaperButtons = ({
  handleExportClick,
  decisionPaperVersion,
  handleAttachmentsClicked,
}) => {
  const {
    event: { id: eventId },
  } = useContext(BusinessEventsAndTasksContext)

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper.attachments-popover',
  })

  const {
    isLoading,
    isError,
    data: attachmentsData,
  } = useDecisionPaperAttachments({ eventId, versionNumber: decisionPaperVersion })

  const buttons = useMemo(() => {
    if (isLoading || isError) {
      return []
    }

    return [
      <Button
        key="attachment-button"
        onClick={handleAttachmentsClicked}
        design={ButtonDesign.Transparent}
        icon="attachment"
      >
        {`${t('attachment')} ${t('number-of-attachments', {
          count: attachmentsData.attachments.length,
        })}`}
      </Button>,
      <Button
        key="print-paper"
        onClick={handleExportClick}
        design={ButtonDesign.Transparent}
        icon="print"
      >
        {t('print')}
      </Button>,
    ]
  }, [
    attachmentsData?.attachments.length,
    handleAttachmentsClicked,
    handleExportClick,
    isError,
    isLoading,
    t,
  ])

  return useMemo(
    () => ({
      isLoading,
      isError,
      buttons,
    }),
    [buttons, isError, isLoading],
  )
}

export default useDecisionPaperButtons
