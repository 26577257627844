import { TableGrowingMode } from '@fioneer/ui5-webcomponents-react'
import { AnalyticalTableSelectionMode } from '@fioneer/ui5-webcomponents-react/dist/enums/AnalyticalTableSelectionMode'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import useEventSearchDialogColumnDefinitions from 'components/domains/business-events-and-tasks/events/dialog/useEventSearchDialogColumnDefinitions'
import useEventSearchTableData from 'components/domains/business-events-and-tasks/events/dialog/useEventSearchTableData'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'

const propTypes = {
  events: PropTypes.array.isRequired,
  maximumNumberOfEvents: PropTypes.number.isRequired,
  selectedEventIds: PropTypes.arrayOf(PropTypes.string),
  isMultiSelect: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  onLoadMore: PropTypes.func,
  isFetching: PropTypes.bool,
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const EventSearchTable = ({
  events,
  maximumNumberOfEvents = events?.length ?? 0,
  selectedEventIds = [],
  isMultiSelect = false,
  onSelectionChange,
  onLoadMore,
  isFetching = false,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.events.search.dialog.table',
  })
  const { t: tNoPrefix } = useTranslation('translation')

  const columns = useEventSearchDialogColumnDefinitions() ?? []
  const tableData = useEventSearchTableData(events, selectedEventIds) ?? []

  const additionalTableProperties = {
    mode: isMultiSelect
      ? AnalyticalTableSelectionMode.MultiSelect
      : AnalyticalTableSelectionMode.SingleSelect,
    stickyColumnHeader: true,
    onSelectionChange: onSelectionChange,
  }

  return (
    <SortedTable
      columnDefinitions={columns}
      tableData={tableData}
      additionalTableProperties={additionalTableProperties}
      toolbarConfig={{ title: t('title'), showColumnSelection: false }}
      renderEmptyTableComponent={() => (
        <EmptyCardContent
          size={'Spot'}
          title={tNoPrefix('components.cards.empty.title')}
          subtitle={tNoPrefix('components.cards.empty.subtitle')}
          illustrationName={'NoData'}
        />
      )}
      paginationConfig={{
        growing: tableData.length < maximumNumberOfEvents ? TableGrowingMode.Button : undefined,
        growingButtonText: isFetching
          ? tNoPrefix('components.input.loading')
          : t('button.load-more'),
        growingButtonSubtext: `[${tableData.length} / ${maximumNumberOfEvents}]`,
        totalNumberOfItems: maximumNumberOfEvents,
        loadMore: onLoadMore,
      }}
    />
  )
}

EventSearchTable.propTypes = propTypes

export default EventSearchTable
