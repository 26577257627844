import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * Returns all available page translation keys in the change log set
 * The response body structure is as follows:
 * {
 *   "pageKeys": [{
 *     id: 'page-id',
 *     translation_key: 'page.translation.key',
 *  }, ...]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: pageKeys}}
 */
const useChangeLogPages = ({ entityId, entityType }) => {
  const queryParams = new URLSearchParams()
  if (entityId) queryParams.set('entity_id', entityId)
  if (entityType) queryParams.set('entity_type', entityType)

  return useCamelizedResponse(
    useRequest({
      path: `/traceability/configurations/page-keys?${queryParams.toString()}`,
      keys: ['traceability', 'configuration', 'page-keys', queryParams.toString()],
      translated: true,
      useCache: false,
    }),
  )
}
export default useChangeLogPages
