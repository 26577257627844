import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import usePropertyTenantsPieChartData from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/current-rent/hooks/usePropertyTenantsPieChartData'
import {
  useAreaMeasurementUnitFormatter,
  useNumberFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared/hooks/i18n/useI18n'
import DonutChart from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/ui/DonutChart'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/tegovaRating/shared/EmptyCardContent'

const PIE_CELL_COLOR_VACANCY = '#C53F38'
const PIE_CELL_COLOR_OWNER_OCCUPIED = '#D97B49'
const MAX_DISPLAYED_TENANTS = 4

const getTenantUsedAreaSurface = (tenantKpi) => tenantKpi?.letSurface?.value ?? 0

const useOccupancyChartData = (propertyKpiData, businessPartnerData) => {
  const { t: tOccupancy } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.property.rent-roll.overview.occupancy',
  })
  const { t: tCharts } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.property.rent-roll.overview.charts',
  })

  const {
    data: tenantsPieChartData = [],
    isLoading,
    isError,
  } = usePropertyTenantsPieChartData({
    propertyKpiData,
    getMetricFromTenantKpi: getTenantUsedAreaSurface,
    maxDisplayedTenants: MAX_DISPLAYED_TENANTS,
  })

  const tenantsPieChartDataWithBusinessPartnerNames = tenantsPieChartData.map((tenantData) => {
    const businessPartnerName =
      businessPartnerData?.businessPartnerMinis?.find((bp) => bp.id === tenantData?.tenantId)
        ?.fullName || tCharts('anonymous-tenant')
    return { ...tenantData, name: businessPartnerName }
  })

  const totalAreaSurface = propertyKpiData?.totalAreaSurface?.value ?? 0
  const vacancySurface = propertyKpiData?.vacancySurface?.value ?? 0
  const ownerOccupiedSurface = propertyKpiData?.selfSurface?.value ?? 0
  const areaUnit = propertyKpiData?.totalAreaSurface?.measurementUnit

  const occupancyPieChartData = [...tenantsPieChartDataWithBusinessPartnerNames]

  if (vacancySurface > 0) {
    occupancyPieChartData.push({
      value: vacancySurface,
      name: tOccupancy('pie-chart.vacant'),
      color: PIE_CELL_COLOR_VACANCY,
    })
  }

  if (ownerOccupiedSurface > 0) {
    occupancyPieChartData.push({
      value: ownerOccupiedSurface,
      name: tOccupancy('pie-chart.owner-occupied'),
      color: PIE_CELL_COLOR_OWNER_OCCUPIED,
    })
  }

  const isInvalidData = totalAreaSurface <= 0

  return {
    data: occupancyPieChartData,
    areaUnit,
    isLoading,
    isError,
    isEmpty: occupancyPieChartData.length === 0,
    isInvalidData,
  }
}

const TenancyOverviewOccupancyChartWrapper = ({ propertyKpiData, businessPartnerData }) => {
  const {
    data: occupancyChartData,
    areaUnit,
    isInvalidData,
    isEmpty,
  } = useOccupancyChartData(propertyKpiData, businessPartnerData)

  const formatNumber = useNumberFormatter({ maximumFractionDigits: 2, minimumFractionDigits: 2 })
  const formatAreaUnit = useAreaMeasurementUnitFormatter()
  const formatTooltipArea = useCallback(
    (value) => `${formatNumber(value)} ${formatAreaUnit(areaUnit)}`,
    [areaUnit, formatAreaUnit, formatNumber],
  )

  const { t: tPieChart } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.property.rent-roll.overview.occupancy.pie-chart',
  })

  return (
    <>
      {isEmpty || isInvalidData ? (
        <EmptyCardContent title={tPieChart('empty.title')} subtitle={tPieChart('empty.subtitle')} />
      ) : (
        <DonutChart data={occupancyChartData} tooltipValueFormatter={formatTooltipArea} />
      )}
    </>
  )
}

TenancyOverviewOccupancyChartWrapper.propTypes = {
  propertyKpiData: PropTypes.object,
  businessPartnerData: PropTypes.object,
}

export default TenancyOverviewOccupancyChartWrapper
