import PropTypes from 'prop-types'

export const internalRatings = PropTypes.arrayOf(
  PropTypes.shape({
    ratingClass: PropTypes.number,
    rate: PropTypes.number.isRequired,
    method: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    validFrom: PropTypes.string.isRequired,
    validTo: PropTypes.string.isRequired,
    // Custom fields are supposed to be an object with unknown properties
    // eslint-disable-next-line react/forbid-prop-types
    customAttributes: PropTypes.object,
  }).isRequired,
)

export const externalRatings = PropTypes.arrayOf(
  PropTypes.shape({
    agency: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    grade: PropTypes.string.isRequired,
    outlook: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    actionType: PropTypes.string.isRequired,
    validFrom: PropTypes.string.isRequired,
    validTo: PropTypes.string.isRequired,
  }).isRequired,
)

export const externalRatingsForOverview = PropTypes.arrayOf(
  PropTypes.shape({
    agency: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    grade: PropTypes.string,
    outlook: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    actionType: PropTypes.string,
    validFrom: PropTypes.string,
  }),
)
