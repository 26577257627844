/**
 * @typedef {import('components/domains/business-events-and-tasks/decision-paper/dialogs/customize-decision-paper/CustomizeDecisionPaperTemplateTable').RowSelection} RowSelection
 * @typedef {import('components/domains/business-events-and-tasks/decision-paper/dialogs/customize-decision-paper/CustomizeDecisionPaperTemplateTable').Template} Template
 */

import isNil from 'lodash.isnil'

/**
 * @param {Template} templateData
 * @param {Template} customization
 * @returns {RowSelection}
 */
export const getInitialRowSelection = (templateData, customization) => {
  if (isNil(templateData?.sections)) {
    return {}
  }
  const hasCustomization = !!customization
  const selectedRowIds = templateData.sections.flatMap((section, sectionIdx) => {
    const customizedSection = customization?.sections.find(({ code }) => code === section.code)
    if (customization && !customizedSection) {
      return []
    }
    const sectionTilesOffset = section.subsections.length
    return [
      `${sectionIdx}`,
      ...section.subsections.flatMap((subsection, subsectionIdx) => {
        const customizedSubsection = customizedSection?.subsections.find(
          ({ code }) => code === subsection.code,
        )
        if (hasCustomization && !customizedSubsection) {
          return []
        }
        const subsectionRowId = `${sectionIdx}.${subsectionIdx}`
        const subsectionHasAssessment = !subsection.hideAssessment
        const customizationHasAssessment = customizedSubsection?.hideAssessment !== true
        const hasAssessment = hasCustomization
          ? customizationHasAssessment
          : subsectionHasAssessment
        const assessmentEntry = hasAssessment
          ? [`${subsectionRowId}.${subsection.tiles.length}`]
          : []
        return [
          subsectionRowId,
          ...subsection.tiles.flatMap((tile, tileIdx) => {
            const customizedTile = customizedSubsection?.tiles.find(
              ({ code }) => code === tile.code,
            )
            if (hasCustomization && !customizedTile) {
              return []
            }
            return [`${subsectionRowId}.${tileIdx}`]
          }),
          ...assessmentEntry,
        ]
      }),
      ...section.tiles.flatMap((tile, tileIdx) => {
        const customizedTile = customizedSection?.tiles.find(({ code }) => code === tile.code)
        if (hasCustomization && !customizedTile) {
          return []
        }
        return [`${sectionIdx}.${tileIdx + sectionTilesOffset}`]
      }),
    ]
  })
  return Object.fromEntries(selectedRowIds.map((id) => [id, true]))
}

const emptyTemplateData = {
  code: undefined,
  sections: [],
}

/**
 * Create the customized template based on the original template configuration
 * and selected rows
 * @param {Template} templateData
 * @param {RowSelection} rowSelection
 */
export const createCustomizedTemplate = (templateData, rowSelection) => {
  if (isNil(templateData?.code) || isNil(templateData?.sections) || isNil(rowSelection)) {
    return emptyTemplateData
  }

  /**
   * @template ListItem
   * @param {ListItem[]} list
   * @param {string[]} path
   * @returns {ListItem[]}
   */
  const filterSelectedRows = (list, path, indexOffset = 0) =>
    list.filter((_item, index) => !!rowSelection[[...path, index + indexOffset].join('.')])

  return {
    code: templateData.code,
    sections: filterSelectedRows(
      templateData.sections.map((section, sectionIdx) => ({
        code: section.code,
        subsections: filterSelectedRows(
          section.subsections.map((subsection, subsectionIdx) => {
            const assessmentId = `${sectionIdx}.${subsectionIdx}.${subsection.tiles.length}`
            const hasAssessment = rowSelection[assessmentId] === true
            return {
              code: subsection.code,
              tiles: filterSelectedRows(subsection.tiles, [sectionIdx, subsectionIdx]).map(
                (tile) => ({ code: tile.code }),
              ),
              hideAssessment: !hasAssessment,
            }
          }),
          [sectionIdx],
        ),
        tiles: filterSelectedRows(
          section.tiles.map((tile) => ({ code: tile.code })),
          [sectionIdx],
          section.subsections.length,
        ),
      })),
      [],
    ),
  }
}

/**
 * Check if a template has been customized compared to the base template data. If two customized
 * template are passed instead, check if any changes were made between the two customized versions.
 * @param {Template} baseTemplateData
 * @param {Template} customizedTemplateData
 * @param {Template} otherCustomizedTemplateData optional second customized template data
 * @returns {boolean} whether the base template has been customized or not
 */
export const isTemplateCustomized = (
  baseTemplateData,
  customizedTemplateData,
  otherCustomizedTemplateData,
) => {
  const baseSelectedRowIds = getInitialRowSelection(baseTemplateData, otherCustomizedTemplateData)
  const customizedSelectedRowIds = getInitialRowSelection(baseTemplateData, customizedTemplateData)
  const baseSelection = Array.from(Object.keys(baseSelectedRowIds))
  const customziedSelection = Array.from(Object.keys(customizedSelectedRowIds))
  return !(
    baseSelection.every((id) => customizedSelectedRowIds[id]) &&
    customziedSelection.every((id) => baseSelectedRowIds[id])
  )
}
