import { FlexBoxJustifyContent } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  AlignedColumn,
  CenterAlignedMultiColumn,
} from 'components/domains/properties/rent-roll/overview/PropertyRentRollOverviewColumns'
import { VIEW_OPTION_KPI_TYPE } from 'components/domains/properties/rent-roll/overview/structure-overview/constants'

export const SEGMENT_RENTAL_INCOME_TABLE_COLUMNS = {
  NAME_AND_USAGE_TYPE: 'nameAndUsageType',
  MARKET_RENT: 'marketRent',
  CONTRACTED_VALUES: 'contractedValues',
  CURRENT_VALUES: 'currentValues',
  WAULT: 'wault',
}

export const useSegmentRentalIncomeTableColumns = () => {
  const { t: tRentalIncome } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.rental-income',
  })

  const selectedKpiValues = useSelector((state) => state.properties.segmentsRentalIncome.kpiValues)

  return [
    {
      columnKey: SEGMENT_RENTAL_INCOME_TABLE_COLUMNS.NAME_AND_USAGE_TYPE,
      renderColumnContent: () => (
        <AlignedColumn>{tRentalIncome('table.columns.name-and-usage-type')}</AlignedColumn>
      ),
    },
    {
      columnKey: SEGMENT_RENTAL_INCOME_TABLE_COLUMNS.MARKET_RENT,
      renderColumnContent: () => (
        <AlignedColumn alignment={FlexBoxJustifyContent.End}>
          {`${tRentalIncome('table.columns.market-rent')}`}
        </AlignedColumn>
      ),
    },
    {
      hide: selectedKpiValues !== VIEW_OPTION_KPI_TYPE.CONTRACTED_RENT,
      columnKey: SEGMENT_RENTAL_INCOME_TABLE_COLUMNS.CONTRACTED_VALUES,
      renderColumnContent: () => (
        <CenterAlignedMultiColumn
          subTitles={[
            tRentalIncome('table.columns.total-rent'),
            tRentalIncome('table.columns.rent'),
            tRentalIncome('table.columns.over-under-rented'),
          ]}
        />
      ),
    },
    {
      columnKey: SEGMENT_RENTAL_INCOME_TABLE_COLUMNS.CURRENT_VALUES,
      hide: selectedKpiValues !== VIEW_OPTION_KPI_TYPE.CURRENT_RET,
      renderColumnContent: () => (
        <CenterAlignedMultiColumn
          subTitles={[
            tRentalIncome('table.columns.total-rent'),
            tRentalIncome('table.columns.rent'),
            tRentalIncome('table.columns.over-under-rented'),
          ]}
        />
      ),
    },
    {
      columnKey: SEGMENT_RENTAL_INCOME_TABLE_COLUMNS.WAULT,
      renderColumnContent: () => (
        <AlignedColumn alignment={FlexBoxJustifyContent.End}>
          {tRentalIncome('table.columns.wault')}
        </AlignedColumn>
      ),
    },
  ]
}
