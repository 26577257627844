import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import DecisionStageApprovalUnknownButton from 'components/domains/business-events-and-tasks/decision-stage/approval/shared/DecisionStageApprovalUnknownButton'
import DecisionStageConditionApprovalApproveButton from 'components/domains/business-events-and-tasks/decision-stage/conditions/approval/buttons/DecisionStageConditionApprovalApproveButton'
import DecisionStageConditionsApprovalVoterApproveButton from 'components/domains/business-events-and-tasks/decision-stage/conditions/approval/voter/DecisionStageConditionsApprovalVoterApproveButton'

export const decisionProcessConditionsDecisionStatus = {
  pending: 'PENDING',
  inProgress: 'IN_PROGRESS',
  inProgressRequestDiscarded: 'IN_PROGRESS_REQUEST_DISCARDED',
  approved: 'APPROVED',
}

const objectStatusForConditionsDecisionStatus = {
  [decisionProcessConditionsDecisionStatus.pending]: {
    translationKey: 'decision-stage.conditions.status.pending',
    objectStatus: ValueState.None,
  },
  [decisionProcessConditionsDecisionStatus.inProgress]: {
    translationKey: 'decision-stage.conditions.status.in-progress',
    objectStatus: ValueState.Information,
  },
  [decisionProcessConditionsDecisionStatus.inProgressRequestDiscarded]: {
    translationKey: 'decision-stage.conditions.status.in-progress',
    objectStatus: ValueState.Information,
  },
  [decisionProcessConditionsDecisionStatus.approved]: {
    translationKey: 'decision-stage.conditions.status.approved',
    objectStatus: ValueState.Success,
  },
}

const defaultDecisionStageConditionsDecisionStatusObjectStatus = {
  translationKey: 'decision-stage.conditions.status.unknown',
  objectStatus: ValueState.None,
}

export const getObjectStatusforDecisionStageConditionsStatus = (conditionsStatus) =>
  objectStatusForConditionsDecisionStatus[conditionsStatus] ??
  defaultDecisionStageConditionsDecisionStatusObjectStatus

export const decisionStageConditionsVoteOptions = {
  approve: 'APPROVE',
}

const defaultDecisionStageConditionsVoteOptionButton = DecisionStageApprovalUnknownButton

const buttonForDecisionStageConditionsVoteOptions = {
  [decisionStageConditionsVoteOptions.approve]: DecisionStageConditionApprovalApproveButton,
}

export const getButtonForDecisionStageConditionsVoteOption = (decisionStageConditionsVoteOption) =>
  buttonForDecisionStageConditionsVoteOptions[decisionStageConditionsVoteOption] ??
  defaultDecisionStageConditionsVoteOptionButton

export const decisionStageConditionsVoterVoteOptions = {
  approve: 'APPROVE',
}

const defaultDecisionStageConditionsVoterVoteOptionButton = DecisionStageApprovalUnknownButton

const buttonForDecisionStageConditionsVoterVoteOptions = {
  [decisionStageConditionsVoterVoteOptions.approve]:
    DecisionStageConditionsApprovalVoterApproveButton,
}

export const getButtonForDecisionStageConditionsVoterVoteOption = (
  decisionStageConditionsVoterVoteOption,
) =>
  buttonForDecisionStageConditionsVoterVoteOptions[decisionStageConditionsVoterVoteOption] ??
  defaultDecisionStageConditionsVoterVoteOptionButton
