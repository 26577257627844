import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useGetEventDefaultAssignees = ({ eventCode, entityType, entityId }, options) => {
  const queryParams = new URLSearchParams({
    event_code: eventCode,
    entity_type: entityType,
    entity_id: entityId,
  })
  return useCamelizedResponse(
    useRequest({
      path: `/events/types/default-assignees?${queryParams}`,
      useCache: true,
      keys: ['events', 'types', 'default-assignees', eventCode, entityType, entityId],
      options,
    }),
  )
}

export default useGetEventDefaultAssignees
