import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import {
  Button,
  ButtonDesign,
  Dialog,
  Input,
  List,
  ListMode,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/deal-search/DealMobileSuggestionInput.module.css'
import DealSearchSuggestions from 'components/domains/deals/deal-search/DealSearchSuggestions'
import { dealFilterPropTypes } from 'hooks/services/deals/useDeals'

const DealMobileSuggestionInput = ({
  preSelectedValue,
  currentSearchValue,
  onSearchValueChanged,
  onSuggestionItemSelect,
  additionalDealFilterValues,
  ...props
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.deal-search.deal-autocomplete-input',
  })
  const { t: tNoPrefix } = useTranslation('translation')

  const [isSuggestionDialogOpen, setIsSuggestionDialogOpen] = useState(false)

  const onItemSelect = (event) => {
    const transformedEvent = {
      detail: { item: event?.detail?.selectedItems?.[0] },
    }
    onSuggestionItemSelect(transformedEvent)
    setIsSuggestionDialogOpen(false)
  }

  const onInputChange = ({ target: { value: search } }) => {
    onSearchValueChanged({ target: { value: search } })
  }

  return (
    <>
      <Input
        id="deal-search-outer-input"
        value={currentSearchValue}
        onInput={onInputChange}
        disabled={!!preSelectedValue}
        onFocus={() => setIsSuggestionDialogOpen(true)}
        onClick={() => setIsSuggestionDialogOpen(true)}
        {...props}
      />
      {isSuggestionDialogOpen &&
        createPortal(
          <Dialog
            id="deal-search-select-dialog"
            className={styles.selectDialog}
            header={<Title level={TitleLevel.H3}>{tNoPrefix('buttons.select')}</Title>}
            footer={
              <div className={styles.footer}>
                <Button
                  id="ok-button"
                  onClick={() => setIsSuggestionDialogOpen(false)}
                  design={ButtonDesign.Transparent}
                >
                  {tNoPrefix('buttons.ok')}
                </Button>
              </div>
            }
            open={isSuggestionDialogOpen}
            onAfterClose={() => setIsSuggestionDialogOpen(false)}
          >
            <Input
              id="search-dialog-input"
              value={currentSearchValue}
              onInput={onInputChange}
              disabled={!!preSelectedValue}
              className={styles.mobileInput}
              {...props}
            />
            <List mode={ListMode.SingleSelect} onSelectionChange={onItemSelect}>
              <DealSearchSuggestions
                loadingText={t('loading')}
                errorText={t('error')}
                emptyResultsText={t('empty')}
                typeMoreText={t('type-more')}
                currentSearchValue={currentSearchValue}
                additionalDealFilterValues={additionalDealFilterValues}
              />
            </List>
          </Dialog>,
          document.body,
        )}
    </>
  )
}

DealMobileSuggestionInput.propTypes = {
  currentSearchValue: PropTypes.string,
  onSearchValueChanged: PropTypes.func.isRequired,
  preSelectedValue: PropTypes.string,
  onSuggestionItemSelect: PropTypes.func.isRequired,
  additionalDealFilterValues: dealFilterPropTypes,
}

export default DealMobileSuggestionInput
