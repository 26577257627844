import { IllustratedMessage } from '@fioneer/ui5-webcomponents-react'
import '@ui5/webcomponents-fiori/dist/illustrations/sapIllus-Spot-UnableToLoad.js'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import CommentSection from 'components/domains/business-events-and-tasks/events/comment-section/CommentSection'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'

const CommentSectionLoadingWrapper = ({
  disabled = false,
  eventId,
  taskId,
  comments,
  onLoadMoreComments,
  isLoading,
  isError,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.comment-section.loading' })

  const renderContent = () => (
    <CommentSection
      disabled={disabled}
      eventId={eventId}
      taskId={taskId}
      comments={comments.comments}
      onLoadMoreComments={onLoadMoreComments}
      maxCommentsCount={comments.total}
    />
  )

  const renderCustomErrorScreen = () => (
    <IllustratedMessage
      name="UnableToLoad"
      size="Dialog"
      titleText={t('error.title')}
      subtitleText={t('error.subtitle')}
    />
  )

  return (
    <LoadingStateWrapper
      isLoading={isLoading}
      loadingTitle={t('loading-title')}
      loadingDescription={t('loading-description')}
      isError={isError}
      renderContent={renderContent}
      renderCustomErrorScreen={renderCustomErrorScreen}
    />
  )
}

CommentSectionLoadingWrapper.propTypes = {
  disabled: PropTypes.bool,
  eventId: PropTypes.string,
  taskId: PropTypes.string,
  comments: PropTypes.shape({
    comments: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
  }),
  onLoadMoreComments: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
}

export default CommentSectionLoadingWrapper
