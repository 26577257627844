/* eslint-disable no-console */
import { useQuery } from '@tanstack/react-query'
import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { responseCodes } from 'api/requests'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import {
  GetMultiPropertyRentRollKPIsRequestBody,
  MultiPropertyRentRollKPIsDTO,
} from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpis.schema'

const errorState = {
  data: undefined,
  isError: true,
  isLoading: false,
  isFetching: false,
}

const unfoldKpis = (rentRollKpis) => ({
  key_date: rentRollKpis.key_date,
  ...rentRollKpis.kpis,
})

/**
 * @param {import('zod').infer<ReturnType<typeof MultiPropertyRentRollKPIsDTO>>} multiPropertyKpis
 */
const getAllKeyDateKpisOrCurrent = (multiPropertyKpis, withAllKeyDates) => {
  if (withAllKeyDates) {
    return multiPropertyKpis?.key_date_to_multi_property_rent_roll_kpis?.map((rentRollKpis) =>
      unfoldKpis(rentRollKpis),
    )
  } else {
    const rentRollKpis = multiPropertyKpis?.key_date_to_multi_property_rent_roll_kpis?.[0]
    if (!rentRollKpis) return undefined
    return unfoldKpis(rentRollKpis)
  }
}

const useCurrentMultiPropertyKpis = (
  propertyUuids,
  {
    currency,
    areaUnitOfMeasurement,
    keyDates = null,
    withTenantGranularity = undefined,
    withCityGranularity = undefined,
    withPropertyTypeGranularity = undefined,
    withCountryGranularity = undefined,
    withTenantIndustryGranularity = undefined,
    withSegmentUsageTypeGranularity = undefined,
    withTegovaRatingGranularity = undefined,
    withAllKeyDates = false,
  } = {},
  queryOptions = {},
) => {
  const requestOptions = {
    property_uuids: propertyUuids,
    currency: currency,
    area_unit_of_measurement: areaUnitOfMeasurement,
    key_dates: keyDates,
    with_tenant_granularity: withTenantGranularity,
    with_city_granularity: withCityGranularity,
    with_property_type_granularity: withPropertyTypeGranularity,
    with_country_granularity: withCountryGranularity,
    with_tenant_industry_granularity: withTenantIndustryGranularity,
    with_segment_usage_type_granularity: withSegmentUsageTypeGranularity,
    with_tegova_rating_granularity: withTegovaRatingGranularity,
  }

  const { post } = useAccessTokenRequest()
  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: [
      'multi-property-kpis',
      ...propertyUuids.sort(),
      currency,
      areaUnitOfMeasurement,
      keyDates,
      {
        withTenantGranularity,
        withCityGranularity,
        withPropertyTypeGranularity,
        withCountryGranularity,
        withTenantIndustryGranularity,
        withSegmentUsageTypeGranularity,
        withTegovaRatingGranularity,
      },
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const parsedRequestOptions = GetMultiPropertyRentRollKPIsRequestBody.parse(requestOptions)

      return post({
        path: '/properties/kpis/multi-property',
        body: parsedRequestOptions,
      })
    },
    retry: 0,
    ...queryOptions,
    enabled: !isNil(queryOptions.enabled)
      ? queryOptions.enabled && !isEmpty(propertyUuids)
      : !isEmpty(propertyUuids),
  })
  if (isLoading || isFetching || isError) {
    return { isLoading, isFetching, isError, data: undefined }
  }

  try {
    const parsedResponse = MultiPropertyRentRollKPIsDTO(requestOptions, withAllKeyDates)
      .passthrough()
      .parse(data?.data)
    return {
      data: camelize(getAllKeyDateKpisOrCurrent(parsedResponse, withAllKeyDates)),
      isError: data?.status !== responseCodes.OK,
      isLoading: false,
      isFetching: false,
    }
  } catch (err) {
    console.error('useCurrentMultiPropertyKpis: response validation error:', err)
    return errorState
  }
}

export default useCurrentMultiPropertyKpis
