import { useMemo } from 'react'
import { mainEntityTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/MainEntityTypes'
import { cwpEntityTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/constants/cwpEntityTypes'

const useCwpEntityToMainEntity = () => {
  /**
   * @enum
   */
  const cwpEntityToMainEntity = useMemo(
    () => ({
      [cwpEntityTypes.BUSINESS_PARTNER]: mainEntityTypes.BUSINESSPARTNER,
      [cwpEntityTypes.DEAL]: mainEntityTypes.DEAL,
    }),
    [],
  )

  return { cwpEntityToMainEntity }
}

export default useCwpEntityToMainEntity
