import {
  CardHeader,
  Label,
  SegmentedButton,
  SegmentedButtonItem,
  Table,
  TableCell,
  TableColumn,
  TableRow,
  TableRowType,
  Toolbar,
  ToolbarDesign,
  ToolbarStyle,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Card from 'components/ui/card/Card'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import styles from 'components/ui/user-favorite/UserFavoritesAndRecentsCard.module.css'
import {
  FAVORITE_ITEM_TYPE,
  RECENT_VISIT_ITEM_TYPE,
} from 'hooks/services/user-profile/useCombinedUserFavoritesAndRecentVisits'

const getColumnsToRender = (columnNames, typeToShow, translate) => {
  const entityColumnsToRender = columnNames.map((columnName) => (
    <TableColumn key={`${typeToShow}-column-${columnName}`}>
      <Label>{translate(columnName)}</Label>
    </TableColumn>
  ))
  const emptyFavoriteIconColumn = <TableColumn />
  return typeToShow === FAVORITE_ITEM_TYPE
    ? [emptyFavoriteIconColumn, ...entityColumnsToRender]
    : entityColumnsToRender
}

/**
 * User favorites and recents card
 *
 * @param entityType {('DEAL'|'MARKET'|'BUSINESS_PARTNER'|'PROPERTY')} should be one of cwpEntityTypes
 * @param title {string} title text to display
 * @param subtitle {string} title text to display
 * @param isLoading {boolean} request state isLoading
 * @param isError {boolean} request state isError
 * @param rowsData {Array} rows to display, each row object must entityId, entityType, itemType, and notFound attributes.
 *                        In addition when notFound is set to true, the row fields are present matching the defined column names.
 * @param columnNames {Array} columns to display
 * @param handleOnHeaderClick {Function} callback function without parameters to handle click on card header
 * @param handleOnRowClick {Function} callback function with entityId as parameter to handle click on row
 */
const UserFavoritesAndRecentVisitsCard = ({
  entityType,
  title,
  subtitle,
  isLoading,
  isError,
  rowsData,
  columnNames,
  handleOnRowClick,
  handleOnHeaderClick,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.home.user-favs-and-recent-visits',
  })
  const [showFavorites, setShowFavorites] = useState(true)
  const typeToShow = showFavorites ? FAVORITE_ITEM_TYPE : RECENT_VISIT_ITEM_TYPE

  const cardHeader = (
    <CardHeader
      titleText={t(title)}
      subtitleText={t(subtitle)}
      interactive
      onClick={handleOnHeaderClick}
    />
  )

  const renderNotFoundError = (entityId) => (
    <span className={styles.notFoundError}>
      {t('error.not-found.title', { entityType: t(entityType.toLowerCase()), entityId })}
    </span>
  )

  const rowsToRender = rowsData
    .filter((rowData) => rowData.itemType === typeToShow)
    .map((rowData, rowIdx) => (
      <TableRow
        key={`${typeToShow}-row-${rowIdx}`}
        type={rowData.notFound ? TableRowType.Inactive : TableRowType.Active}
        entity-id={rowData.entityId}
      >
        {typeToShow === FAVORITE_ITEM_TYPE && (
          <TableCell>
            <div className={styles.iconCell}>
              <UserFavoriteIcon
                entityId={rowData.entityId}
                entityType={rowData.entityType}
                triggerRefetech={false}
              />
            </div>
          </TableCell>
        )}
        {rowData.notFound ? (
          <TableCell>{renderNotFoundError(rowData.entityId)}</TableCell>
        ) : (
          columnNames.map((columnName) => (
            <TableCell key={`${typeToShow}-row-${rowIdx}-column-${columnName}`}>
              {rowData[columnName] ?? '-'}
            </TableCell>
          ))
        )}
      </TableRow>
    ))

  const toggleViewsButton = useMemo(
    () => (
      <SegmentedButton>
        <SegmentedButtonItem
          pressed={typeToShow === FAVORITE_ITEM_TYPE}
          onClick={() => setShowFavorites(true)}
        >
          {t('button.favorites')}
        </SegmentedButtonItem>
        <SegmentedButtonItem
          pressed={typeToShow === RECENT_VISIT_ITEM_TYPE}
          onClick={() => setShowFavorites(false)}
        >
          {t('button.recent-visits')}
        </SegmentedButtonItem>
      </SegmentedButton>
    ),
    [t, typeToShow],
  )

  const tableTitle = t(title)

  const toolbarTitle = useMemo(
    () => (
      <Label className={styles.toolbarTitle}>
        {t('toolbar.title', { tableTitle, nrOfEntries: rowsToRender.length })}
      </Label>
    ),
    [rowsToRender.length, t, tableTitle],
  )

  const toolbarComponent = useMemo(
    () => (
      <Toolbar
        design={ToolbarDesign.Auto}
        toolbarStyle={ToolbarStyle.Clear}
        className={styles.toolbar}
      >
        {toolbarTitle}
        {toggleViewsButton}
      </Toolbar>
    ),
    [toggleViewsButton, toolbarTitle],
  )
  const tableComponent = useMemo(
    () => (
      <Table
        columns={getColumnsToRender(columnNames, typeToShow, t)}
        onRowClick={(e) => handleOnRowClick(e.detail.row.getAttribute('entity-id'))}
        noDataText={t('no-data-text')}
        stickyColumnHeader
        className={styles.table}
      >
        {rowsToRender}
      </Table>
    ),
    [columnNames, handleOnRowClick, rowsToRender, t, typeToShow],
  )

  const renderContent = () => (
    <div className={styles.cardContent}>
      {toolbarComponent}
      {tableComponent}
    </div>
  )

  return (
    <Card header={cardHeader}>
      <LoadingStateWrapper isLoading={isLoading} isError={isError} renderContent={renderContent} />
    </Card>
  )
}

UserFavoritesAndRecentVisitsCard.propTypes = {
  entityType: PropTypes.oneOf(['DEAL', 'MARKET', 'BUSINESS_PARTNER', 'PROPERTY']),
  title: PropTypes.string.isRequired,
  handleOnHeaderClick: PropTypes.func.isRequired,
  subtitle: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  // differs for each implementation
  // eslint-disable-next-line react/forbid-prop-types
  rowsData: PropTypes.array.isRequired,
  columnNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleOnRowClick: PropTypes.func.isRequired,
}

export default UserFavoritesAndRecentVisitsCard
