import get from 'lodash.get'
import { useTranslation } from 'react-i18next'

// Business defined. Two digits of precision for input + two digits of buffer
// so that converted values stay precise.
const CURRENCY_PRECISION = 4

export const useFormValidations = ({ enabled = true } = {}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'form.validation' })

  const validator = (condition, errorMessage) => !enabled || (condition ? true : errorMessage)

  /**
   * @returns {function(*): (string | false)}
   */
  const required = (requiredPath = null, customMessage = undefined) => {
    const hasValue = (value) => {
      if (value === undefined || value === null || Number.isNaN(value)) return false
      if (typeof value === 'string') return value.trim().length > 0
      if (Array.isArray(value)) return value.length > 0
      if (typeof value === 'object') return Object.keys(value).length > 0
      return true
    }
    if (requiredPath) {
      return (value) => validator(hasValue(get(value, requiredPath)), t('required'))
    }
    return (value) => validator(hasValue(value), customMessage ?? t('required'))
  }

  /**
   * @param minValue {number}
   * @returns {function(*): (string | false)}
   */
  const min = (minValue) => (value) => validator(value >= minValue, t('min', { minValue }))

  /**
   *
   * @returns {function(*): (string | true)}
   */
  const positive = (value) => validator(value > 0, t('positiveNumber'))

  /**
   * @param maxValue {number}
   * @returns {function(*): (string | false)}
   */
  const max = (maxValue) => (value) => validator(value <= maxValue, t('max', { maxValue }))

  /**
   * Validates, that the input is below the maximum safe number with the given
   * precision. Depends on the underlying JS implementation of floats.
   *
   * @param [precision] {number} - The required precision (digits after comma)
   * @returns {function(*): (string | false)}
   */
  const maxSafeNumber = (precision = 0) => {
    // Number.EPSILON is defined as one over the size of the mantissa, so the
    // following calculates the maximum safe number for a given exponent
    const maxSafe = Math.floor(1 / Number.EPSILON / 10 ** precision)
    return (value) => validator(value < maxSafe, t('maxSafeNumber'))
  }

  /**
   * Validates, that the input is below the maximum safe number for currency
   * inputs. Should be around 450.359.962.737, but is dependent on the
   * underlying JS implementation. Business wanted 4 digits of precision, two
   * for the actual input value + two in case we convert the currency.
   *
   * @returns {function(*): (string | false)}
   */
  const maxSafeMonetary = () => maxSafeNumber(CURRENCY_PRECISION)

  /**
   * @param minLengthValue {number}
   * @returns {function(*): (string | false)}
   */
  const minLength = (minLengthValue) => (value) =>
    validator(value?.length >= minLengthValue, t('minLength', { minLengthValue }))

  /**
   * @param maxLengthValue {number}
   * @returns {function(*): (string | false)}
   */
  const maxLength = (maxLengthValue) => (value) =>
    validator(value?.length <= maxLengthValue, t('maxLength', { maxLengthValue }))

  /**
   * @param pattern {RegExp}
   * @returns {function(*): (string | false)}
   */
  const patternValidator = (pattern) => (value) =>
    validator(!!String(value).match(pattern), t('pattern'))

  return {
    required,
    min,
    max,
    maxSafeNumber,
    maxSafeMonetary,
    minLength,
    maxLength,
    pattern: patternValidator,
    positive,
  }
}
