import {
  Button,
  ButtonDesign,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useRequestDecision from 'hooks/services/business-events-and-tasks/decision-process/stages/useRequestDecision'
import paths from 'routes/paths'

const useRequestDecisionFlow = ({ eventId, decisionPaperVersion, decisionStageId }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage',
  })
  const navigate = useNavigate()
  const showMessageBox = Modals.useShowMessageBox()
  const queryClient = useQueryClient()
  const { mutate: requestDecision } = useRequestDecision()
  const onRequestDecisionSuccess = useCallback(() => {
    queryClient.invalidateQueries(['events', eventId, 'decision-stages'])
    showMessageBox(
      {
        titleText: t('request.decision-paper-available.success.title'),
        children: <Text wrapping>{t('request.decision-paper-available.success.text')}</Text>,
        draggable: true,
        resizable: true,
        type: MessageBoxTypes.Success,
        actions: [MessageBoxActions.OK],
      },
      document.body,
    )
  }, [showMessageBox, t, queryClient, eventId])

  const onRequestDecisionError = useCallback(() => {
    showMessageBox(
      {
        type: MessageBoxTypes.Error,
        children: t('request.error'),
        draggable: true,
        resizable: true,
      },
      document.body,
    )
  }, [showMessageBox, t])

  const requestDecisionConfirmed = useCallback(() => {
    requestDecision(
      { eventId, decisionStageId, decisionPaperVersion },
      { onSuccess: onRequestDecisionSuccess, onError: onRequestDecisionError },
    )
  }, [
    requestDecision,
    eventId,
    decisionStageId,
    decisionPaperVersion,
    onRequestDecisionSuccess,
    onRequestDecisionError,
  ])

  const onOpenDecisionPaperClicked = useCallback(() => {
    navigate(`/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}/decision-paper`)
  }, [navigate, eventId])

  const onRequestDecisionButtonClicked = useCallback(() => {
    if (!decisionPaperVersion) {
      showMessageBox(
        {
          titleText: t('request.no-decision-paper.title'),
          children: <Text wrapping>{t('request.no-decision-paper.text')}</Text>,
          draggable: true,
          resizable: true,
          type: MessageBoxTypes.Warning,
          actions: [
            <Button
              key="open-decision-paper"
              onClick={onOpenDecisionPaperClicked}
              design={ButtonDesign.Emphasized}
            >
              {t('request.no-decision-paper.buttons.open-decision-paper')}
            </Button>,
            <Button key="" design={ButtonDesign.Default}>
              {tNoPrefix('buttons.cancel')}
            </Button>,
          ],
        },
        document.body,
      )
      return
    }
    showMessageBox(
      {
        titleText: t('request.decision-paper-available.title'),
        children: <Text wrapping>{t('request.decision-paper-available.text')}</Text>,
        draggable: true,
        resizable: true,
        type: MessageBoxTypes.Confirm,
        actions: [
          <Button
            key="request-decision-confirm"
            onClick={requestDecisionConfirmed}
            design={ButtonDesign.Emphasized}
          >
            {t('buttons.request-decision')}
          </Button>,
          MessageBoxActions.Cancel,
        ],
      },
      document.body,
    )
  }, [
    decisionPaperVersion,
    t,
    showMessageBox,
    tNoPrefix,
    onOpenDecisionPaperClicked,
    requestDecisionConfirmed,
  ])

  return useMemo(
    () => ({
      onRequestDecisionButtonClicked,
    }),
    [onRequestDecisionButtonClicked],
  )
}

export default useRequestDecisionFlow
