import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useUpdateGeolocation = ({ onSuccess, onError }) => {
  const { patch } = useAccessTokenRequest()

  return useMutation(
    async ({ property_uuid, force }) => {
      const queryParams = new URLSearchParams()
      if (force) queryParams.append('force', force)
      const { data } = await patch({
        path: `/properties/${property_uuid}/address/geocode?${queryParams.toString()}`,
      })
      return data
    },
    { onSuccess, onError },
  )
}
