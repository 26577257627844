import { array, func, object, string } from 'prop-types'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { DealCreationBusinessPartnerLink } from 'components/domains/deals/creation-dialog/review-step/DealCreationBusinessPartnerLink'
import { DealCreationReviewRow } from 'components/domains/deals/creation-dialog/review-step/DealCreationReviewRow'
import { useNumberFormatter } from 'hooks/i18n/useI18n'
import { useIsBorrower } from 'hooks/services/deals/borrower/useIsBorrower'
import { useLoanTypeCodes } from 'hooks/services/deals/financing/configGetters/useLoanTypeCodes'

export const DealCreationReviewCounterparty = ({
  sponsor = [],
  borrower = {},
  totalCommitment = {},
  loanType,
  onEdit,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.dialog.counterparty-and-financing',
  })
  const formatNumber = useNumberFormatter({ maximumFractionDigits: 2, minimumFractionDigits: 2 })
  const { data: { loanTypeCodes = [] } = {} } = useLoanTypeCodes()
  const { data: isBorrower } = useIsBorrower(borrower.bpId)

  const getLoanTypeNameByCode = (code) =>
    loanTypeCodes?.find((loanType) => loanType.key === code)?.display_name

  return (
    <Fragment>
      <DealCreationReviewRow id="deal-creation-review-counterparty-sponsor" label={t('sponsor')}>
        <DealCreationBusinessPartnerLink
          id="deal-creation-review-counterparty-sponsor-link"
          businessPartner={sponsor[0]}
        />
      </DealCreationReviewRow>
      <DealCreationReviewRow id="deal-creation-review-counterparty-borrower" label={t('borrower')}>
        <DealCreationBusinessPartnerLink
          id="deal-creation-review-counterparty-borrower-link"
          businessPartner={borrower}
          additionalInfo={!isBorrower ? t('new-borrower') : undefined}
        />
      </DealCreationReviewRow>
      <DealCreationReviewRow
        id="deal-creation-review-counterparty-commitment"
        label={t('commitment')}
        text={formatNumber(totalCommitment.number)}
      />
      <DealCreationReviewRow
        id="deal-creation-review-counterparty-currency"
        label={t('currency')}
        text={totalCommitment.currencyCode}
      />
      <DealCreationReviewRow
        id="deal-creation-review-counterparty-loan-type"
        label={t('loan-type')}
        text={getLoanTypeNameByCode(loanType)}
        onEdit={onEdit}
      />
    </Fragment>
  )
}

DealCreationReviewCounterparty.propTypes = {
  sponsor: array,
  borrower: object,
  totalCommitment: object,
  loanType: string,
  onEdit: func.isRequired,
}
