import { ButtonDesign, MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { STATUS_TYPE_CODES } from 'components/domains/business-partners/tile/risk-monitoring/utils/typeCodes'
import LoadingButton from 'components/ui/button/LoadingButton'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useRiskIndicatorGroupById from 'hooks/services/risk-monitoring/useRiskIndicatorGroupById'
import useUpdateRiskIndicatorGroupById from 'hooks/services/risk-monitoring/useUpdateRiskIndicatorGroupById'

const propTypes = {
  businessPartnerId: PropTypes.string,
  riskIndicatorGroupId: PropTypes.string,
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const CloseIndicatorGroupAction = ({ businessPartnerId, riskIndicatorGroupId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix:
      'pages.business-partner.risk-monitoring.risk-indicators.group-details.close-group-action',
  })
  const { t: tNoPrefix } = useTranslation()

  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
  const showMessageBox = useShowMessageBox()

  const {
    data: riskIndicatorGroupData,
    isLoading: isGroupDataLoading,
    isError: isGroupDataError,
  } = useRiskIndicatorGroupById(businessPartnerId, riskIndicatorGroupId)

  const { statusTypeCode } = riskIndicatorGroupData ?? {}

  const { mutate: updateRiskIndicatorGroup } = useUpdateRiskIndicatorGroupById({
    onSuccess: () => {
      queryClient.invalidateQueries([
        'risk-monitoring',
        'business-partners',
        businessPartnerId,
        'risk-indicator-groups',
      ])
      showToast(
        {
          children: t('toast.changes-saved'),
        },
        document.body,
      )
    },
    onError: (_error) => {
      showMessageBox({ type: MessageBoxTypes.Error, children: tNoPrefix('error.default') })
    },
  })

  const handleConfirm = () => {
    setIsConfirmationDialogOpen(false)

    updateRiskIndicatorGroup({
      businessPartnerId,
      riskIndicatorGroupId: riskIndicatorGroupId,
      statusTypeCode: STATUS_TYPE_CODES.CLOSED,
    })
  }

  const handleCancel = () => {
    setIsConfirmationDialogOpen(false)
  }

  const handleClick = () => {
    setIsConfirmationDialogOpen(true)
  }

  return (
    <LoadingButton
      design={ButtonDesign.Transparent}
      isLoading={isGroupDataLoading}
      isError={isGroupDataError}
      disabled={statusTypeCode === STATUS_TYPE_CODES.CLOSED}
      onClick={handleClick}
      renderContent={() => (
        <>
          {t('button')}
          <ConfirmationDialog
            isOpen={isConfirmationDialogOpen}
            handleConfirmation={handleConfirm}
            handleCancel={handleCancel}
            confirmButtonText={tNoPrefix('buttons.confirm')}
            confirmationMessage={t('confirmation.text')}
          />
        </>
      )}
    />
  )
}

CloseIndicatorGroupAction.propTypes = propTypes

export default CloseIndicatorGroupAction
