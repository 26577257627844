import {
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  Icon,
  IconDesign,
  Label,
  MessageBoxTypes,
  Modals,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import useShowDealErrorCodeMessageBox from 'components/domains/deals/creation-dialog/useShowDealErrorCodeMessageBox'
import EditDealAdjustmentDialogContent from 'components/domains/deals/deal-adjustment/edit/EditDealAdjustmentDialogContent'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import styles from 'components/domains/deals/deal-adjustment/publish/PublishWorkingVersionDialog.module.css'
import LoadingButton from 'components/ui/button/LoadingButton'
import Dialog from 'components/ui/dialog/Dialog'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useAdjustmentTypesConfig } from 'hooks/services/deals/deal-adjustment/useAdjustmentTypesConfig'
import { useAdjustments } from 'hooks/services/deals/deal-adjustment/useAdjustments'
import usePublishDealAdjustmentWorkingVersion from 'hooks/services/deals/deal-adjustment/usePublishDealAdjustmentWorkingVersion'
import useNavigateToWorkingVersion from 'routes/deals/useNavigateToWorkingVersion'

const PublishWorkingVersionDialog = forwardRef(
  (
    { dealUuid, i18nPrefix = 'components.deals.deal-adjustment.modal.publish', ...props },
    forwardedRef,
  ) => {
    const dialogRef = useRef()
    useImperativeHandle(forwardedRef, () => dialogRef.current)
    const { t } = useTranslation('translation', {
      keyPrefix: i18nPrefix,
    })
    const form = useForm({
      mode: 'onChange',
      defaultValues: {},
    })
    const {
      reset,
      handleSubmit,
      formState: { isValid },
    } = form

    const showToast = Modals.useShowToast()
    const showMessageBox = Modals.useShowMessageBox()
    const showDealErrorCodeMessageBox = useShowDealErrorCodeMessageBox()

    const switchToWorkingVersion = useNavigateToWorkingVersion()

    const {
      data: { adjustmentTypes } = {},
      isLoading: isAdjustmentTypesLoading,
      isError: isAdjustmentTypesError,
    } = useAdjustmentTypesConfig()
    const [adjustment, setAdjustment] = useState()
    const {
      isLoading: isAdjustmentsLoading,
      isError: isAdjustmentsError,
      refetch: refetchAdjustments,
    } = useAdjustments({
      dealUuid,
      options: {
        onSuccess: (adjustmentListData) => {
          const fetchedAdjustment = adjustmentListData?.adjustments?.[0]
          setAdjustment(fetchedAdjustment)
          reset(fetchedAdjustment)
        },
      },
    })

    const [isPublishLoading, setIsPublishLoading] = useState(false)
    const publishWorkingVersion = usePublishDealAdjustmentWorkingVersion({
      onSuccess: (response) => {
        // show any warnings
        if (response.data.warnings?.length) {
          showDealErrorCodeMessageBox({
            warnings: response.data.warnings,
            type: MessageBoxTypes.Warning,
          })
        }

        // success actions
        showToast({
          duration: 5000,
          children: t('saved'),
        })
        switchToWorkingVersion(WorkingVersionType.LIVE, true)

        // close
        setIsPublishLoading(false)
        dialogRef.current?.close?.()
      },
      onError: async (error) => {
        setIsPublishLoading(false)
        const response = await error?.response?.json()
        const { errors: [errorResponse] = [] } = response || {}
        showDealErrorCodeMessageBox({
          warnings: [errorResponse],
          fallbackWarningMessage: t('error'),
          type: MessageBoxTypes.Error,
        })
        setIsPublishLoading(false)
        dialogRef.current?.close?.()
      },
    })

    const verifyNotDeprecated = useCallback(() => {
      const configNotLoaded = isAdjustmentTypesLoading || isAdjustmentTypesError
      const deprecatedAdjustmentIsSelected = adjustmentTypes
        .filter((adjType) => adjType.deprecated)
        .map((adjType) => adjType.code)
        .some((deprecatedCode) => adjustment?.types.includes(deprecatedCode))
      if (configNotLoaded || deprecatedAdjustmentIsSelected) {
        showMessageBox({
          type: MessageBoxTypes.Error,
          children: t('error-deprecated'),
        })
        return false
      }
      return true
    }, [adjustment, setAdjustment, adjustmentTypes])
    const onSubmit = useCallback(() => {
      if (!verifyNotDeprecated()) return

      setIsPublishLoading(true)
      publishWorkingVersion.mutate({
        dealUuid: dealUuid,
      })
    }, [dealUuid, publishWorkingVersion, verifyNotDeprecated])

    const primaryButton = useMemo(
      () => (
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          isLoading={isPublishLoading}
          disabled={!isValid}
          design={ButtonDesign.Emphasized}
          renderContent={() => t('button-text')}
        />
      ),
      [handleSubmit, onSubmit, isPublishLoading, isValid, t],
    )

    const renderContent = () => (
      <EditDealAdjustmentDialogContent
        readonly={true}
        additionalFormElement={
          <span className={styles.publishDialogText}>
            <Trans i18nKey="components.deals.deal-adjustment.modal.publish.disclaimer-text" />
          </span>
        }
      />
    )

    const headerContent = useMemo(
      () => (
        <FlexBox alignItems={FlexBoxAlignItems.Center} className={styles.gap}>
          <Icon name="sys-help-2" design={IconDesign.Neutral} />
          <Label className={styles.title}>{t('title')}</Label>
        </FlexBox>
      ),
      [t],
    )

    return (
      <Dialog
        headerText={headerContent}
        headerClassName={styles.headerUnderline}
        ref={dialogRef}
        primaryButton={primaryButton}
        onBeforeOpen={() => refetchAdjustments()}
        {...props}
      >
        <FormProvider {...form}>
          <RequestStateResolver
            center
            errorToDisplay={<ErrorDataUnavailableInContent />}
            isLoading={isAdjustmentsLoading}
            isError={isAdjustmentsError}
            renderContent={renderContent}
          />
        </FormProvider>
      </Dialog>
    )
  },
)

PublishWorkingVersionDialog.displayName = 'PublishWorkingVersionDialog'
PublishWorkingVersionDialog.propTypes = {
  dealUuid: PropTypes.string.isRequired,
  i18nPrefix: PropTypes.string,
}

export default PublishWorkingVersionDialog
