const getValue = (rentalUnits, units) => {
  const getRent = (rentalUnit) =>
    units.length > 1
      ? rentalUnit.rentCurrentYearInHeadQuarterCurrency?.number ?? 0
      : rentalUnit.rentCurrentYear?.number ?? 0

  return rentalUnits.reduce((sum, rentalUnit) => sum + getRent(rentalUnit), 0)
}

const getUnit = (rentalUnits, units) => {
  if (units.length > 1) {
    return rentalUnits[0].rentCurrentYearInHeadQuarterCurrency?.currency
  } else {
    return units[0]
  }
}

// helper function used to calculate the wault value. Uses units instead of contextRentalUnits because wault takes care of using the correct values
export const calculateTenancyTotalRentCurrentYear = (rentalUnits, units) => {
  const value = getValue(rentalUnits, units)

  return {
    value: value !== 0 ? value : 1,
    unit: getUnit(rentalUnits, units),
    share: 0, // hardcoded, currently unavailable
  }
}
