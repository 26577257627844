import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useDeprecatedValues = ({ availableValues, selectedValues }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ui.input',
  })
  const deprecatedName = t('deprecated')
  return useMemo(() => {
    const deprecatedValues = selectedValues
      .filter(
        ({ code: selectedCode }) => !availableValues.some(({ code }) => code === selectedCode),
      )
      .map((visibility) => ({ ...visibility, name: `${visibility.name} (${deprecatedName})` }))

    return { deprecatedValues }
  }, [availableValues, deprecatedName, selectedValues])
}

export default useDeprecatedValues
