import PropTypes from 'prop-types'
import { useCallback } from 'react'
import CustomAssessmentTile from 'components/domains/custom-assessments/CustomAssessmentTile'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import CWPLayout from 'components/ui/layout/CWPLayout'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useCustomAssessmentsHelper from 'hooks/services/central-data/custom-assessments/useCustomAssessmentsHelper'

const CustomAssessments = ({ entityType, entityId, page }) => {
  const {
    customAssessments,
    isFetching: isCustomAssessmentsFetching,
    isError: isCustomAssessmentsError,
    isUpdateCustomAssessmentsAllowed,
  } = useCustomAssessmentsHelper({ entityType, page })

  const renderContent = useCallback(() => {
    const customAssessmentTiles = customAssessments.map(({ code, title }) => (
      <CWPLayout.Full key={`${entityType}-${code}-layout`}>
        <CustomAssessmentTile
          key={`${entityType}-${code}`}
          entityType={entityType}
          entityId={entityId}
          assessmentCode={code}
          title={title}
          hasEditPermission={isUpdateCustomAssessmentsAllowed}
        />
      </CWPLayout.Full>
    ))

    return <CWPLayout>{customAssessmentTiles}</CWPLayout>
  }, [customAssessments, entityId, entityType, isUpdateCustomAssessmentsAllowed])
  return (
    <RequestStateResolver
      isLoading={isCustomAssessmentsFetching}
      isError={isCustomAssessmentsError}
      renderContent={renderContent}
      errorToDisplay={<ErrorDataUnavailableInContent />}
    />
  )
}

CustomAssessments.propTypes = {
  entityType: PropTypes.oneOf(Object.values(cwpEntityTypes)).isRequired,
  entityId: PropTypes.string.isRequired,
  page: PropTypes.string,
}

export default CustomAssessments
