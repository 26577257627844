import { filterTypes } from 'components/domains/conditions/overview/ConditionsTableToolbarFilterDialog'

export const getValueFromQueryParams = ({ type, filterKey, prefix, queryParams }) => {
  if (filterKey === 'creationDate') {
    return {
      lowerBound: queryParams.get(prefix + 'creationDateFrom') ?? '',
      upperBound: queryParams.get(prefix + 'creationDateUntil') ?? '',
    }
  }

  const valueInUrl = queryParams.get(prefix + filterKey)
  if (filterKey === 'covenantCheck') {
    if (valueInUrl !== null) {
      const split = valueInUrl.split(',')
      return { covenantCheckUuid: split[0], name: split[1] }
    }
  }

  if (filterKey === 'status' || filterKey === 'visibilities') {
    if (valueInUrl !== null) {
      const split = valueInUrl.split(',')
      return split
    }
  }

  if (filterKey === 'references') {
    const entityType = queryParams.get(prefix + 'referencesEntityType')
    const entityIds = queryParams.get(prefix + 'referencesEntityIds')?.split(',')
    return entityType
      ? {
          entityType,
          entityIds,
        }
      : undefined
  }

  if (valueInUrl !== null) {
    return valueInUrl
  }
  return filterTypes[type].emptyValue
}

export const conditionFilterPrefixes = {
  conditions: 'con_',
  requirements: 'req_',
}

export const conditionServiceTypes = {
  conditions: 'conditions',
  requirements: 'requirements',
}
