import isNil from 'lodash.isnil'

const useValidateMonitoringInformationForm = ({ monitoringInformation = {} }) => {
  const validateBaseData = ({ amountToAdd, direction } = {}, isRequired) =>
    isRequired
      ? !isNil(amountToAdd) && amountToAdd >= 0 && !!direction
      : (!isNil(amountToAdd) && amountToAdd >= 0) || isNil(amountToAdd)

  const {
    monitoringReferenceEntity,
    firstDueDate: { keyDate } = {},
    checkCycle = {},
  } = monitoringInformation

  let { firstDueDate: { targetDeliveryDateBaseData = {}, checkDateBaseData = {} } = {} } =
    monitoringInformation
  if (!targetDeliveryDateBaseData) targetDeliveryDateBaseData = {}
  if (!checkDateBaseData) checkDateBaseData = {}

  const isMonitoringReferenceEntityValid =
    monitoringReferenceEntity?.type && monitoringReferenceEntity?.id
  const isKeyDateValid = Boolean(keyDate)
  const isTargetDeliveryDateBaseDataValid = validateBaseData(targetDeliveryDateBaseData, true)
  const isCheckDateBaseDataValid = validateBaseData(checkDateBaseData, false)
  const isCheckCycleValid = Boolean(checkCycle?.number) && Boolean(checkCycle?.cycle)

  return (
    isMonitoringReferenceEntityValid &&
    isKeyDateValid &&
    isTargetDeliveryDateBaseDataValid &&
    isCheckDateBaseDataValid &&
    isCheckCycleValid
  )
}

export default useValidateMonitoringInformationForm
