import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionsMassEditOperations } from 'api/conditions/conditions'
import MassEditOperationSelect from 'components/domains/conditions/dialogs/mass-editing/MassEditOperationSelect'
import EditableDatePickerComponent from 'components/ui/input/EditableDatePickerComponent'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const RequirementsMassEditDueDateSelect = ({ onChange, disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.dialogs.mass-edit',
  })
  const { parse, localePattern } = useShortDateFormatter()

  const [showInputField, setShowInputField] = useState(false)
  const [dueDateValue, setDueDateValue] = useState(undefined)

  const handleSelectedOperationChanged = useCallback(
    (selection) => {
      if (selection === conditionsMassEditOperations.keep) {
        setDueDateValue(undefined)
        setShowInputField(false)
        onChange({ operation: conditionsMassEditOperations.keep })
        return
      }
      onChange({ operation: conditionsMassEditOperations.replace, value: undefined })
      setShowInputField(true)
    },
    [onChange],
  )

  const handleChangeDueDate = ({ target: { liveValue: newDueDate } }) => {
    if (isNil(newDueDate)) {
      onChange({ operation: conditionsMassEditOperations.replace, value: undefined })
      return
    }

    setDueDateValue(newDueDate)
    onChange({
      operation: conditionsMassEditOperations.replace,
      value: parse(newDueDate, localePattern),
    })
  }

  const onFocus = useCallback((event) => {
    event.stopPropagation()
  }, [])

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label>{t('labels.due-date')}</Label>
      <MassEditOperationSelect
        idPrefix={'requirements-due-date'}
        selectableOperations={[
          conditionsMassEditOperations.keep,
          conditionsMassEditOperations.replace,
        ]}
        onChange={handleSelectedOperationChanged}
        disabled={disabled}
      />
      {showInputField && (
        <EditableDatePickerComponent
          onChange={handleChangeDueDate}
          onFocus={onFocus}
          value={dueDateValue}
          editable={true}
          placeholder={localePattern}
          formatPattern={localePattern}
        />
      )}
    </FlexBox>
  )
}

RequirementsMassEditDueDateSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default RequirementsMassEditDueDateSelect
