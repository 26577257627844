import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useDeleteCost = ({ onSuccess, onError }) => {
  const { delete: deleteCost } = useAccessTokenRequest()

  return useMutation(
    async ({ propertyUuid, costUuid }) => {
      await deleteCost({
        path: `/properties/${propertyUuid}/costs?cost-uuid=${costUuid}`,
      })
    },
    { onSuccess, onError },
  )
}
