import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useExternalConditionsUuids = (
  { externalConditionUuids = [], entityType, entityId },
  options,
) => {
  const { post } = useAccessTokenRequest()
  const queryFn = useCallback(async () => {
    const { data } = await post({
      path: '/conditions/external/uuids',
      body: {
        external_condition_ids: externalConditionUuids,
      },
    })
    return data
  }, [externalConditionUuids, post])
  return useCamelizedResponse(
    useQuery({
      queryKey: [
        'conditions',
        'external',
        'uuids',
        entityType,
        entityId,
        'uuids',
        ...externalConditionUuids,
      ],
      queryFn,
      retry: false,
      ...options,
    }),
  )
}

export default useExternalConditionsUuids
