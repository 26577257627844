import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  Table,
  TableCell,
  TableColumn,
  TableRow,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/FinancingTermsCommitmentFeeTile.module.css'
import {
  usePercentageFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/shared/hooks/i18n/useI18n'
import useGetTrancheWithHighestCommitment from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/utils/useGetTrancheWithHighestCommitment'

const FEE_TYPE_CODE_COMMITMENT = 'CommitmentFee'

const FinancingTermsCommitmentFeeTile = ({ tileId }) => {
  const tileData = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const trancheData = tileData?.data?.trancheData?.tranches ?? []

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.financing-terms.commitment-fee',
  })

  const { format: formatDate } = useShortDateFormatter()
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })

  const displayedTranche = useGetTrancheWithHighestCommitment(trancheData)

  // only display fees with correct type code, and only if at least one property is filled,
  // then sort by date (ascending)
  const displayedFees =
    displayedTranche?.fees
      .filter((fee) => fee.feeTypeCode === FEE_TYPE_CODE_COMMITMENT)
      .filter((fee) => !isNil(fee?.firstPaymentDate) || !isNil(fee.amountShare))
      .sort((a, b) => {
        const dateA = new Date(a?.firstPaymentDate)
        const dateB = new Date(b?.firstPaymentDate)
        return dateA - dateB
      }) ?? []

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.commitmentFeeCard}>
      <Table
        id="commitmentFeeTable"
        noDataText={t('property.no-data')}
        className={styles.commitmentFeeTable}
        columns={
          <>
            <TableColumn>
              <FlexBox justifyContent={FlexBoxAlignItems.End}>{t('property.from')}</FlexBox>
            </TableColumn>
            <TableColumn>
              <FlexBox justifyContent={FlexBoxAlignItems.End}>{t('property.rate')}</FlexBox>
            </TableColumn>
          </>
        }
      >
        {displayedFees.map((fee) => (
          <TableRow key={fee.id}>
            <TableCell>
              <FlexBox justifyContent={FlexBoxAlignItems.End}>
                {formatDate(fee?.firstPaymentDate) ?? '-'}
              </FlexBox>
            </TableCell>
            <TableCell>
              <FlexBox justifyContent={FlexBoxAlignItems.End}>
                {!isNil(fee?.amountShare) ? formatPercentage(Number(fee?.amountShare)) : '-'}
              </FlexBox>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </FlexBox>
  )
}

FinancingTermsCommitmentFeeTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default FinancingTermsCommitmentFeeTile
