import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/authority-level-calculator/cells/DefaultCellStyles.module.css'
import SmallLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/ui/loading/SmallLoadingWrapper'
import { useNumberFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const PercentageCell = ({
  value,
  isBold = false,
  isLoading = false,
  isError = false,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
}) => {
  const { t } = useTranslation('decisionPaper')
  const formatPercentage = useNumberFormatter({
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits,
    style: 'percent',
  })

  if (!Array.isArray(value)) {
    value = [value]
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      error={
        <div className={styles.errorText}>
          {t('decision-paper.unit-overview.components.error.data-unavailable.cell.title-text')}
        </div>
      }
      renderContent={() => (
        <div className={styles.columnFlexBox}>
          {value.map((item, index) => (
            <div key={index} className={isBold ? styles.boldFont : ''}>
              {!isNil(item) ? formatPercentage(item) : <>&nbsp;</>}
            </div>
          ))}
        </div>
      )}
    />
  )
}

PercentageCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  isBold: PropTypes.bool,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  maximumFractionDigits: PropTypes.number,
  minimumFractionDigits: PropTypes.number,
}

export default PercentageCell
