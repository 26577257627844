/**
 * A complete address consists of a road, a house number, a postcode, a city and a country code
 */
export const isCompleteAddress = (addr) =>
  addr?.postcode && addr?.city && addr?.road && addr?.houseNumber && addr?.countryCode

/**
 * Returns the geolocation, if the address is complete, returns an empty geo location otherwise.
 */
export const getGeoLocationOfCompleteAddress = (address, defaultOrdinate = '') =>
  isCompleteAddress(address)
    ? { longitude: address?.longitude, latitude: address?.latitude }
    : { longitude: defaultOrdinate, latitude: defaultOrdinate }
