import { AnalyticalTableScaleWidthMode, ToolbarSeparator } from '@fioneer/ui5-webcomponents-react'
import debounce from 'lodash.debounce'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useRef, useState } from 'react'
import styles from 'components/domains/documents/documents-table/DocumentsTable.module.css'
import DocumentsTableRowWrapper from 'components/domains/documents/documents-table/DocumentsTableRowWrapper'
import { countDocuments } from 'components/domains/documents/documents-table/countDocuments'
import filterRowsBySearchParam from 'components/domains/documents/documents-table/filterRowsBySearchParam'
import AnalyticalTableNoDataComponent from 'components/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import { useCollapseExpandTableActions } from 'components/ui/tables/analytical/useCollapseExpandTableActions'
import ToolbarSearching from 'components/ui/tables/toolbar/ToolbarSearching'

const documentsTablePropTypes = {
  columns: PropTypes.array.isRequired,
  folders: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  formatDate: PropTypes.func.isRequired,
  showColumnSelection: PropTypes.bool,
  className: PropTypes.string,
  handleOnDrop: PropTypes.func,
  hasDocumentUploadPermission: PropTypes.bool,
}

/**
 * Component to display folders and documents in a tree table view
 *
 * @param {PropTypes.InferProps<typeof documentsTablePropTypes> params}
 */
const DocumentsTable = ({
  columns,
  folders,
  title,
  formatDate,
  showColumnSelection = true,
  className = '',
  handleOnDrop,
  hasDocumentUploadPermission,
  ...props
}) => {
  const tableRef = useRef(null)

  const {
    handleOnRowExpand,
    additionalTableActions: collapseExpandActions = [],
    expandOrCollapseSubRows,
  } = useCollapseExpandTableActions(tableRef)

  const [searchParam, setSearchParam] = useState('')
  const toolbarSearching = useMemo(() => {
    const debounceValue = 300
    return {
      searchParam: '',
      onUpdateSearchParam: debounce((updatedValue) => {
        setSearchParam(updatedValue)
        expandOrCollapseSubRows(true)
      }, debounceValue),
    }
  }, [expandOrCollapseSubRows])

  const additionalTableActions = [
    <ToolbarSearching searching={toolbarSearching} key={'search-documents'} />,
    <ToolbarSeparator key={'separator-1'} />,
    ...collapseExpandActions,
  ]

  const getRowProps = useCallback(
    (rowProps, { row }) => [
      rowProps,
      {
        rowWrapper: ({ children, height }) => (
          <DocumentsTableRowWrapper height={height} row={row}>
            {children}
          </DocumentsTableRowWrapper>
        ),
        rowWrapperProps: {
          requirementId: row.original.id,
        },
      },
    ],
    [],
  )

  const useGetRowPropsHook = useCallback(
    (hooks) => {
      hooks.getRowProps.push(getRowProps)
    },
    [getRowProps],
  )

  return (
    <AnalyticalTableWithToolbar
      id="documents-table"
      className={`${styles.documentTable} tree-table ${className}`.trim()}
      title={title}
      columns={columns}
      calculateNrOfEntries={countDocuments}
      // using AnalyticalTableScaleWidthMode.Smart causes hidden columns to be shown
      // we need hidden columns for filtering. This might be a bug in our AnalyticalTableWithToolbar, or in UI5 or even in the underlying useTable hook
      scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
      additionalActions={additionalTableActions}
      highlightField="status"
      showColumnSelection={showColumnSelection}
      withRowHighlight={true}
      dragAndDrop={true}
      handleOnDrop={handleOnDrop}
      hasDocumentUploadPermission={hasDocumentUploadPermission}
      data={filterRowsBySearchParam({
        rows: folders,
        searchParam,
        formatDate,
      })}
      subRowsKey="subFolders"
      isTreeTable={true}
      rowHeight={32}
      minRows={1}
      headerRowHeight={30}
      onRowExpandChange={(event) => handleOnRowExpand(event.detail.row)}
      // disables reordering
      onColumnsReorder={() => {}}
      reactTableOptions={{
        autoResetPage: false,
        autoResetExpanded: false,
        autoResetSortBy: false,
        initialState: { sortBy: [{ id: 'name', desc: false }] },
        autoResetSelectedRows: false,
      }}
      ref={tableRef}
      filterable
      sortable
      NoDataComponent={() => (
        <AnalyticalTableNoDataComponent
          isLoading={false}
          isError={false}
          tableId={'documents-table'}
        />
      )}
      tableHooks={[useGetRowPropsHook]}
      overscanCount={20}
      {...props}
    />
  )
}

DocumentsTable.propTypes = documentsTablePropTypes

export default DocumentsTable
