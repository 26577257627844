import {
  BusyIndicator,
  BusyIndicatorSize,
  Button,
  ButtonDesign,
} from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import styles from 'components/ui/button/LoadingButton.module.css'

/**
 * `LoadingButton` can be used instead of the UI5 button for interactions that
 * can have a loading or an error state.
 *
 * Apart from the documented props belows, it takes on all the same props as the [UI5 Button](https://sap.github.io/ui5-webcomponents-react/?path=/docs/inputs-button--docs).
 */
const LoadingButton = forwardRef(({ isLoading, isError, renderContent, ...buttonProps }, ref) => {
  const { id: buttonId } = buttonProps

  const getLoadingButtonClassNames = (disabled, isLoading, design) => {
    const classNames = [styles.removeOpacity]

    if (disabled || isLoading) {
      switch (design) {
        case ButtonDesign.Default:
        case undefined:
          classNames.push(styles.defaultButtonDisabled)
          break
        case ButtonDesign.Emphasized:
          classNames.push(styles.emphasizedButtonDisabled)
          break
        case ButtonDesign.Positive:
          classNames.push(styles.positiveButtonDisabled)
          break
        case ButtonDesign.Negative:
          classNames.push(styles.negativeButtonDisabled)
          break
        case ButtonDesign.Attention:
          classNames.push(styles.attentionButtonDisabled)
          break
      }
    }
    return compact(classNames).join(' ')
  }

  const getBusyIndicatorClassNames = (design) => {
    const classNames = [styles.busyIndicator]

    switch (design) {
      case ButtonDesign.Emphasized:
        classNames.push(styles.emphasizedBusyIndicator)
        break
      case ButtonDesign.Attention:
        classNames.push(styles.attentionBusyIndicator)
        break
    }
    return compact(classNames).join(' ')
  }

  if (isLoading) {
    return (
      <Button
        {...buttonProps}
        id={`${buttonId}-loading`}
        disabled={buttonProps.disabled || isLoading}
        className={getLoadingButtonClassNames(buttonProps.disabled, isLoading, buttonProps.design)}
      >
        <BusyIndicator
          className={getBusyIndicatorClassNames(buttonProps.design)}
          delay={0}
          active
          size={BusyIndicatorSize.Small}
        />
        <div className={compact([buttonProps.className, isLoading && styles.hiddenText]).join(' ')}>
          {renderContent?.()}
        </div>
      </Button>
    )
  } else if (isError) {
    return <></>
  } else {
    return (
      <Button ref={ref} {...buttonProps}>
        {renderContent?.()}
      </Button>
    )
  }
})

LoadingButton.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  /** please provide a function that returns the children of the button */
  renderContent: PropTypes.func,
}

LoadingButton.displayName = 'LoadingButton'

export default LoadingButton
