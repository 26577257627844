import PropTypes from 'prop-types'
import Entity from 'components/ui/data/Entity'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useEvent from 'hooks/services/business-events-and-tasks/events/useEvent'
import paths from 'routes/paths'

const LoadingEventEntity = ({ eventId }) => {
  const event = useEvent(eventId)
  if (!eventId) return <></>
  const { info: eventInfo, displayId: eventDisplayId } = event.data || {}
  return (
    <SmallLoadingWrapper
      isLoading={event.isLoading && event.isFetching}
      isError={event.isError}
      renderContent={() => (
        <Entity
          name={eventInfo?.name}
          id={eventDisplayId}
          link={`/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}`}
          openInNewTab={true}
        />
      )}
    />
  )
}

LoadingEventEntity.propTypes = {
  eventId: PropTypes.string,
}

export default LoadingEventEntity
