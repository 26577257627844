import {
  ACTIVE,
  NO_FORBEARANCE,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/risk-monitoring/forbearance/constants'

export const mapForbearanceStatus = (dealUuid, multipleForbearanceStatusesData) => {
  const forbearanceStatusResponse = multipleForbearanceStatusesData?.find(
    ({ data: { dealUuid: responseDealUuid } }) => responseDealUuid === dealUuid,
  )

  const activeForbearanceStatus = forbearanceStatusResponse?.data?.forbearanceStatuses?.find(
    ({ lifecycleStatusTypeCode }) => lifecycleStatusTypeCode === ACTIVE,
  )

  return activeForbearanceStatus?.forbearanceStatusShortText ?? NO_FORBEARANCE
}
