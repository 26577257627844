import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import isNil from 'lodash.isnil'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateCovenantMonitoring = ({ onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()

  return useMutation(
    async ({ covenantUuid, monitoring }) => {
      const checkDateBaseData = monitoring.firstDueDate.checkDateBaseData
      if (isNil(checkDateBaseData?.amountToAdd) && !checkDateBaseData?.direction)
        delete monitoring.firstDueDate.checkDateBaseData

      const response = await put({
        path: `/covenants/${covenantUuid}/monitoring-information`,
        body: snakecaseKeys(monitoring, { deep: true }),
      })
      return { ...response, data: camelize(response?.data) }
    },
    { onSuccess, onError },
  )
}

export default useUpdateCovenantMonitoring
