import isEmpty from 'lodash.isempty'

const valuationKeyDateChanged = (state, { payload: { keyDate: newKeyDate } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }
  state.editedRow.currentValues.keyDate = newKeyDate
  state.editedRow.touchedFields.keyDate = true

  if (newKeyDate === state.editedRow.initialValues.keyDate) {
    delete state.editedRow.changedFields.keyDate
    return
  }

  state.editedRow.changedFields.keyDate = true
}

export default valuationKeyDateChanged
