import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useBusinessPartnerRelationshipUnitTypes = (options) =>
  useCamelizedResponse(
    useRequest({
      path: '/businesspartners/value-helps/unit-relationships',
      translated: true,
      useCache: true,
      keys: ['businesspartners', 'value-helps', 'unit-relationships'],
      options,
    }),
  )

export default useBusinessPartnerRelationshipUnitTypes
