import PropTypes from 'prop-types'
import DualCurrency from 'components/domains/business-partners/dual-unit-value/DualCurrency'
import DualMeasurementValue from 'components/domains/business-partners/dual-unit-value/DualMeasurementValue'
import { useNumberFormatter } from 'hooks/i18n/useI18n'

const fiscalFigureFormats = {
  money: 'money',
  percent: 'ratio',
  area: 'area',
}

const FiscalFigureCell = ({ cell: { value: cellValue = {} } = {} } = {}) => {
  const { value, unit, format } = cellValue
  const formatNumber = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  switch (format) {
    case fiscalFigureFormats.money:
      return <DualCurrency primaryValue={value} primaryCurrency={unit} />

    case fiscalFigureFormats.area:
      return <DualMeasurementValue primaryValue={value} primaryMeasurementUnit={unit} />

    default:
      return <div>{`${formatNumber(value)}${unit ?? ''}`.trim()}</div>
  }
}

FiscalFigureCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string,
      format: PropTypes.string,
    }),
  }),
}

export default FiscalFigureCell
