import PropTypes from 'prop-types'

const baseDataShape = PropTypes.shape({
  amountToAdd: PropTypes.number,
  unit: PropTypes.string,
  direction: PropTypes.string,
})

export const monitoringInformationShape = PropTypes.shape({
  checkRequired: PropTypes.bool,
  firstDueDate: PropTypes.shape({
    keyDate: PropTypes.string,
    targetDeliveryDateBaseData: baseDataShape,
    checkDateBaseData: baseDataShape,
  }),
  checkCycle: PropTypes.shape({
    number: PropTypes.number,
    cycle: PropTypes.string,
  }),
})

export const monitoringItemShape = PropTypes.shape({
  dueDate: PropTypes.shape({
    keyDate: PropTypes.string,
    checkDate: PropTypes.string,
    actualDeliveryDate: PropTypes.string,
  }),
})
