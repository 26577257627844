import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useBusinessPartnerAllowedOperations = (options) =>
  useCamelizedResponse(
    useRequest({
      path: `/businesspartners/allowed-operations`,
      useCache: true,
      keys: ['businesspartners', 'allowedOperations'],
      options: {
        ...options,
      },
    }),
  )
