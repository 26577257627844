import PropTypes from 'prop-types'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/shared/ui/card/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/shared/ui/card/DisplayCardViewItemView'

const TextField = ({ label, value, isFirstItem }) => (
  <DisplayCardViewItemView>
    <DisplayCardViewInfoItemView
      label={label}
      isFirstItem={isFirstItem}
      value={value ? value : '-'}
    />
  </DisplayCardViewItemView>
)

TextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  isFirstItem: PropTypes.bool,
}

export default TextField
