import PropTypes from 'prop-types'
import { useMemo } from 'react'

// mapping data to structure required by LoadingMultiComboBox
export const usePropertyKeyInternalContactFunctionsMapping = ({ isError, isLoading, data }) =>
  useMemo(
    () => ({
      data: data?.business_partner_function_codes.map(
        ({ business_partner_function_code, business_partner_function_name }) => ({
          code: business_partner_function_code,
          displayName: business_partner_function_name,
          modifiable: true,
        }),
      ),
      isLoading,
      isError,
    }),
    [data?.business_partner_function_codes, isError, isLoading],
  )

usePropertyKeyInternalContactFunctionsMapping.propTypes = {
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  data: PropTypes.shape({
    functions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }),
}
