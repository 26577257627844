import { Label, Link, LinkDesign, Text } from '@fioneer/ui5-webcomponents-react'
import parse from 'html-react-parser'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { TILE_VERSION as tileVersion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import DecisionStageLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/components/domains/business-events-and-tasks/decision-stage/DecisionStageLeaveConfirmationLink'
import OpsMemoSyndicationBusinessPartnerView from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/OpsMemoSyndicationBusinessPartnerView'
import OpsMemoSyndicationBusinessPartnerViewV1 from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/OpsMemoSyndicationBusinessPartnerViewV1'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/OpsMemoSyndicationStructureTableCell.module.css'
import {
  ROOT_ROW_ID_OWN_SHARE,
  ROW_TYPE_COUNTERPARTY,
  ROW_TYPE_OWN_SHARE,
  ROW_TYPE_ROLE,
  ROW_TYPE_TRANCHE,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/constants'
import {
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const renderDisplayModeCell = (
  cell,
  row,
  formatMoney,
  formatShare,
  formatRate,
  formatDate,
  t,
  dealDisplayId,
  showConfirmationForLinks,
  providedTileVersion,
  businessPartnerResolvedNames,
) => {
  const columnKey = cell.column.id

  const isSpacingForHQCurrencyNeeded = cell?.row?.original?.isSpacingForHQCurrencyNeeded

  const renderDisplayModePartyRoleTrancheColumnCell = () => {
    const { isBold, value, trancheDisplayId } = cell.value ?? {}

    switch (row.original?.rowType) {
      case ROW_TYPE_COUNTERPARTY:
        return providedTileVersion === tileVersion.V1 ? (
          <OpsMemoSyndicationBusinessPartnerViewV1
            businessPartnerId={value?.id}
            showConfirmationForLinks={showConfirmationForLinks}
          />
        ) : (
          <OpsMemoSyndicationBusinessPartnerView
            businessPartnerId={value?.id}
            showConfirmationForLinks={showConfirmationForLinks}
            businessPartnerResolvedNames={businessPartnerResolvedNames}
          />
        )
      case ROW_TYPE_TRANCHE:
        return (
          <>
            {showConfirmationForLinks ? (
              <DecisionStageLeaveConfirmationLink
                name={value}
                link={`/deals/${dealDisplayId}/financing/tranches/${trancheDisplayId}`}
              />
            ) : (
              <Link
                design={LinkDesign.Emphasized}
                href={`/deals/${dealDisplayId}/financing/tranches/${trancheDisplayId}`}
                target="_blank"
                wrappingType="Normal"
                className={styles.overflowEllipsis}
              >
                {value}
              </Link>
            )}
          </>
        )
      case ROW_TYPE_OWN_SHARE:
        return providedTileVersion === tileVersion.V1 ? (
          <OpsMemoSyndicationBusinessPartnerViewV1
            businessPartnerId={value}
            showLink={false}
            showConfirmationForLinks={showConfirmationForLinks}
          />
        ) : (
          <OpsMemoSyndicationBusinessPartnerView
            businessPartnerId={value}
            showLink={false}
            showConfirmationForLinks={showConfirmationForLinks}
            businessPartnerResolvedNames={businessPartnerResolvedNames}
          />
        )
    }

    return (
      <Text
        wrapping={false}
        className={[isBold ? styles.textBold : undefined, styles.textWithOverflowHandling].join(
          ' ',
        )}
      >
        {value}
      </Text>
    )
  }

  const renderDisplayModeTypeCell = () => {
    const array = Array.isArray(cell.value) ? cell.value : [cell.value]

    if (row.original?.rootRowId !== ROOT_ROW_ID_OWN_SHARE) {
      return (
        <div className={styles.commitmentCell}>
          {array.map((element, index) => {
            const { value } = element ?? {}
            return (
              <div key={index}>
                {!isNil(value) && (
                  <Text className={isSpacingForHQCurrencyNeeded ? styles.typeCell : undefined}>
                    {parse(value)}
                  </Text>
                )}
                {isNil(value) && (
                  <Text className={isSpacingForHQCurrencyNeeded ? styles.typeCell : undefined}>
                    &nbsp;
                  </Text>
                )}
              </div>
            )
          })}
        </div>
      )
    }
  }

  const renderDisplayModeConsortiumRankColumnCell = () => {
    const { value } = cell.value ?? {}
    return <Text>{value}</Text>
  }

  const renderDisplayModeSigningDateColumnCell = () => {
    const { isBold, value } = cell.value ?? {}
    return <Text className={isBold ? styles.textBold : undefined}>{formatDate(value)}</Text>
  }

  const renderDisplayModeShareColumnCell = () => {
    const array = Array.isArray(cell.value) ? cell.value : [cell.value]
    if (row.original?.rootRowId !== ROOT_ROW_ID_OWN_SHARE) {
      return (
        <div className={styles.commitmentCell}>
          {array.map((element, index) => {
            const { value } = element ?? {}
            return (
              <div key={index}>
                {!isNil(value) && (
                  <Text
                    className={isSpacingForHQCurrencyNeeded ? styles.textWithYMargin : undefined}
                  >
                    {formatRate(value)}
                  </Text>
                )}
                {isNil(value) && (
                  <div
                    className={isSpacingForHQCurrencyNeeded ? styles.textWithYMargin : undefined}
                  >
                    &nbsp;
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )
    }
  }

  const renderDisplayModeMoneyColumnCell = (hideOwnShare = false) => {
    const { isBold } = cell.value ?? {}

    const array = Array.isArray(cell.value) ? cell.value : [cell.value]

    if (
      ((row.original?.rootRowId === ROOT_ROW_ID_OWN_SHARE && !hideOwnShare) ||
        row.original?.rootRowId !== ROOT_ROW_ID_OWN_SHARE) &&
      (row.original?.rowType === ROW_TYPE_TRANCHE || row.original?.rowType === ROW_TYPE_ROLE)
    ) {
      return (
        <div className={[styles.commitmentCell, isBold ? styles.textBold : undefined].join(' ')}>
          {array.map((element, index) => {
            const { value, valueHeadquarter, share } = element ?? {}
            return (
              <div className={styles.commitmentCellSingleElement} key={index}>
                {!isNil(value) && (
                  <div>
                    <div className={styles.caretCursor}>
                      {formatMoney(value.amount, value.currency, {
                        currencyDisplay: 'code',
                      })}
                    </div>
                    {!isNil(valueHeadquarter) && valueHeadquarter?.currency !== value?.currency && (
                      <div className={`${styles.textLabelColor} ${styles.caretCursor}`}>
                        {formatMoney(valueHeadquarter?.amount, valueHeadquarter?.currency, {
                          currencyDisplay: 'code',
                        })}
                      </div>
                    )}
                  </div>
                )}
                {isNil(value) && (
                  <div>
                    <div>&nbsp;</div>
                    {isSpacingForHQCurrencyNeeded && <div>&nbsp;</div>}
                  </div>
                )}

                {!isNil(share) && (
                  <div className={styles.caretCursor}>
                    <Label showColon>{t('share-label')}</Label>
                    &nbsp;
                    <Text>{formatShare(share)}</Text>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )
    }
  }

  const renderDisplayModeFrequencyCell = () => {
    const array = Array.isArray(cell.value) ? cell.value : [cell.value]
    if (row.original?.rootRowId !== ROOT_ROW_ID_OWN_SHARE) {
      return (
        <div className={styles.commitmentCell}>
          {array.map((element, index) => {
            const { value } = element ?? {}
            return (
              <div key={index}>
                {!isNil(value) && (
                  <Text
                    className={isSpacingForHQCurrencyNeeded ? styles.textWithYMargin : undefined}
                  >
                    {value}
                  </Text>
                )}
                {isNil(value) && (
                  <div
                    className={isSpacingForHQCurrencyNeeded ? styles.textWithYMargin : undefined}
                  >
                    &nbsp;
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )
    }
  }

  const renderDisplayModePaymentDateCell = () => {
    const array = Array.isArray(cell.value) ? cell.value : [cell.value]
    if (row.original?.rootRowId !== ROOT_ROW_ID_OWN_SHARE) {
      return (
        <div className={styles.commitmentCell}>
          {array.map((element, index) => {
            const { value } = element ?? {}
            return (
              <div key={index}>
                {!isNil(value) && (
                  <Text
                    className={isSpacingForHQCurrencyNeeded ? styles.textWithYMargin : undefined}
                  >
                    {formatDate(value)}
                  </Text>
                )}
                {isNil(value) && (
                  <div
                    className={isSpacingForHQCurrencyNeeded ? styles.textWithYMargin : undefined}
                  >
                    &nbsp;
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )
    }
  }

  switch (columnKey) {
    case 'partyRoleTranche':
      return renderDisplayModePartyRoleTrancheColumnCell()
    case 'commitment':
      return renderDisplayModeMoneyColumnCell(false)
    case 'amountTerm':
      return renderDisplayModeMoneyColumnCell(true)
    case 'type':
      return renderDisplayModeTypeCell()
    case 'rate':
      return renderDisplayModeShareColumnCell()
    case 'signingDate':
      return renderDisplayModeSigningDateColumnCell()
    case 'consortiumRank':
      return renderDisplayModeConsortiumRankColumnCell()
    case 'frequency':
      return renderDisplayModeFrequencyCell()
    case 'paymentDate':
      return renderDisplayModePaymentDateCell()
    default:
      return <Text>{cell.value}</Text>
  }
}

const OpsMemoSyndicationStructureTableCell = ({
  cell,
  row,
  dealDisplayId,
  showConfirmationForLinks = false,
  tileVersion: providedTileVersion,
  businessPartnerResolvedNames,
}) => {
  const formatMoney = useCustomizableCurrencyFormatter()
  const formatSharePercent = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })
  const formatRatePercent = useNumberFormatter({
    maximumFractionDigits: 3,
    minimumFractionDigits: 3,
    style: 'percent',
  })

  const { format: formatDate } = useShortDateFormatter()
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.syndication-structure',
  })

  return renderDisplayModeCell(
    cell,
    row,
    formatMoney,
    formatSharePercent,
    formatRatePercent,
    formatDate,
    t,
    dealDisplayId,
    showConfirmationForLinks,
    providedTileVersion,
    businessPartnerResolvedNames,
  )
}

OpsMemoSyndicationStructureTableCell.propTypes = {
  cell: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  dealDisplayId: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool,
}

export default OpsMemoSyndicationStructureTableCell
