import orderBy from 'lodash.orderby'

/**
 *
 * Takes two arrays, one with regular elements and one with elements that have a fixed position where they should be in the resulting array.
 *
 * @param {Array<Object>} items - the array of items
 * @param {Array<Object} fixedPositionItems the array of items with fixed positions
 * @param {Number} filter[].fixedPosition - the target position of the item in the resulting array
 * @returns {Array<Object>} the merged array
 */
export const mergeItemsWithFixedPositionItems = ({ items = [], fixedPositionItems } = {}) => {
  const result = [...items]
  const deleteCount = 0
  orderBy(fixedPositionItems, ['fixedPosition']).forEach(({ fixedPosition, ...item }) => {
    result.splice(fixedPosition, deleteCount, item)
  })
  return result
}

/**
 *
 * Takes an array and an index and moves the element at that position one position to the front of the array.
 *
 * @template T
 * @param {Object} params
 * @param {Array<T>} params.items - the array of items
 * @param {Number} params.indexToMove - the index of the element that should be moved
 * @returns {Array<T>} the changed array
 */
export const moveItemUp = ({ items, indexToMove } = {}) => {
  if (indexToMove > 0 && indexToMove < items.length) {
    const itemToMove = items[indexToMove]
    const newItems = [
      ...items.slice(0, indexToMove - 1),
      itemToMove,
      items[indexToMove - 1],
      ...items.slice(indexToMove + 1),
    ]
    return newItems
  }
  return [...items]
}

/**
 *
 * Takes an array and an index and moves the element at that position one position to the back of the array.
 *
 * @template T
 * @param {Object} params
 * @param {Array<T>} params.items - the array of items
 * @param {Number} params.indexToMove - the index of the element that should be moved
 * @returns {Array<T>} the changed array
 */
export const moveItemDown = ({ items, indexToMove } = {}) => {
  if (indexToMove < items.length - 1 && indexToMove >= 0) {
    const itemToMove = items[indexToMove]
    const result = [
      ...items.slice(0, indexToMove),
      items[indexToMove + 1],
      itemToMove,
      ...items.slice(indexToMove + 2),
    ]
    return result
  }
  return [...items]
}
