import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDecisionPaperSubsectionComments = ({ eventId, version, subsectionId }) =>
  useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/decision-paper/versions/${version}/subsections/${subsectionId}/comments`,
      useCache: true,
      keys: [
        'events',
        eventId,
        'decision-paper',
        'versions',
        version,
        'subsections',
        subsectionId,
        'comments',
      ],
    }),
  )

export default useDecisionPaperSubsectionComments
