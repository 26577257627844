import { Icon } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { useStore } from 'react-redux'
import styles from 'components/domains/conditions/overview/requirements/RequirementsTableRowWrapper.module.css'
import DialogPrimaryButton from 'components/ui/dialog/DialogPrimaryButton'
import DialogSecondaryButton from 'components/ui/dialog/DialogSecondaryButton'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import { dragTypes } from 'dragTypes'
import useAnalyticalTableWrapperStyles from 'hooks/analytical-table/useAnalyticalTableWrapperStyles'
import useCopyDocumentsForRequirements from 'hooks/services/conditions/requirements/helper/useCopyDocumentsForRequirements'
import useMoveDocumentsForRequirements from 'hooks/services/conditions/requirements/helper/useMoveDocumentsForRequirements'

const RequirementsTableRowWrapper = ({ children, height, requirementId, documentType }) => {
  const { t: tButtons } = useTranslation('translation', { keyPrefix: 'buttons' })
  const { t } = useTranslation('translation', { keyPrefix: 'components.requirements.table.drop' })
  const showMessageBox = useShowMessageBox()
  const store = useStore()
  const { wrapperStyles, setWrapper } = useAnalyticalTableWrapperStyles()

  const { handleMove } = useMoveDocumentsForRequirements({
    documentType,
    requirementId,
  })
  const { handleCopy } = useCopyDocumentsForRequirements()

  const onDrop = useCallback(
    (item) => {
      const {
        documentId,
        documentType: newDocumentType,
        documentName,
        documentKeyDate,
        requirementId: requirementIdOfDraggedDocument,
      } = item

      const selectedRequirementIdsObject =
        store.getState().conditions.requirementsTable.selectedRows.selectedRequirementIds

      const selectedRequirementIds = Object.keys(selectedRequirementIdsObject)

      if (selectedRequirementIds.length > 1 && !!selectedRequirementIdsObject[requirementId]) {
        showMessageBox({
          title: t('multiple-documents.title'),
          children: t('multiple-documents.body'),
          actions: [
            <DialogPrimaryButton
              onClick={handleCopy({ documentId, selectedRequirementIds })}
              key="document-copy"
            >
              {tButtons('copy')}
            </DialogPrimaryButton>,
            <DialogSecondaryButton key="document-cancel">
              {tButtons('cancel')}
            </DialogSecondaryButton>,
          ],
          type: MessageBoxTypes.Confirm,
        })
        return
      }
      showMessageBox({
        title: t('single-document.title'),
        children: t('single-document.body'),
        actions: [
          <DialogSecondaryButton
            onClick={handleCopy({ documentId, selectedRequirementIds: [requirementId] })}
            key="document-copy"
          >
            {tButtons('copy')}
          </DialogSecondaryButton>,
          <DialogPrimaryButton
            onClick={handleMove({
              documentId,
              documentName,
              documentKeyDate,
              newDocumentType,
              requirementIdOfDraggedDocument,
            })}
            key="document-move"
          >
            {tButtons('move')}
          </DialogPrimaryButton>,
          <DialogSecondaryButton key="document-cancel">{tButtons('cancel')}</DialogSecondaryButton>,
        ],
        type: MessageBoxTypes.Confirm,
      })
    },
    [handleCopy, handleMove, requirementId, showMessageBox, store, t, tButtons],
  )

  const [{ isOver, canDrop }, dropRef] = useDrop(() => ({
    accept: dragTypes.documentRequirement,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: (item) => !!requirementId && requirementId !== item.requirementId,
  }))

  const setRef = useCallback(
    (element) => {
      setWrapper(element)
      dropRef(element)
    },
    [dropRef, setWrapper],
  )

  const wrapperClassName = useMemo(
    () => [isOver && canDrop ? styles.isOver : '', styles.rowWrapper].join(' ').trim(),
    [canDrop, isOver],
  )

  const computedWrapperStyles = useMemo(
    () => ({ ...wrapperStyles, height }),
    [height, wrapperStyles],
  )

  return (
    <div style={computedWrapperStyles} className={wrapperClassName} ref={setRef}>
      {children}
      {isOver && canDrop && <Icon name="pdf-attachment" className={styles.icon} />}
    </div>
  )
}

RequirementsTableRowWrapper.propTypes = {
  children: PropTypes.any,
  height: PropTypes.string,
  requirementId: PropTypes.string,
  documentType: PropTypes.string,
}

export default RequirementsTableRowWrapper
