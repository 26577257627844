import {
  CUSTOMER_OPTION_ROW_TYPES,
  OPTION_ATTRIBUTE_DATA_TYPES,
  DEFAULT_DISPLAY_ROW_HEIGHT,
  DEFAULT_EDIT_ROW_HEIGHT,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/customerOptionsConstants'

const mapAttributes = (attributes, optionId, isEditMode) => {
  let rowHeight = DEFAULT_DISPLAY_ROW_HEIGHT
  if (isEditMode) {
    rowHeight = DEFAULT_EDIT_ROW_HEIGHT
  }

  return attributes.map((attribute) => ({
    id: attribute.id,
    optionAttributeCode: attribute.option_attribute_code,
    clusterOptionAttribute: attribute.option_attribute_code_short_text,
    value: attribute.value,
    type: attribute.type,
    range: attribute.range,
    rowHeight:
      attribute.type === OPTION_ATTRIBUTE_DATA_TYPES.TEXT
        ? // eslint-disable-next-line no-magic-numbers
          DEFAULT_DISPLAY_ROW_HEIGHT * 3
        : rowHeight,
    rowType: CUSTOMER_OPTION_ROW_TYPES.OPTION_ATTRIBUTE,
    optionId,
  }))
}

const mapOptions = (options, isEditMode) =>
  options
    .map((option) => ({
      clusterOptionAttribute: option.option_type_code_short_text,
      id: option.id,
      isActive: option.is_active,
      optionTypeCode: option.option_type_code,
      rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT,
      rowType: CUSTOMER_OPTION_ROW_TYPES.OPTION,
      subRows: mapAttributes(option.attributes, option.id, isEditMode),
    }))
    .sort((a, b) =>
      a.clusterOptionAttribute.toLowerCase().localeCompare(b.clusterOptionAttribute.toLowerCase()),
    )

const mapCustomerOptionsBackendData = (backendData = {}, isEditMode) => {
  const result = []

  for (const [, cluster] of Object.entries(backendData.clustered_options || {})) {
    result.push({
      clusterOptionAttribute: cluster.cluster_short_text,
      rowType: CUSTOMER_OPTION_ROW_TYPES.CLUSTER,
      rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT,
      subRows: mapOptions(cluster.options, isEditMode),
      order: cluster.order,
    })
  }

  return result.sort((clusterA, clusterB) => clusterA.order - clusterB.order)
}
export default mapCustomerOptionsBackendData
