import { ButtonDesign, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessPartnerCreateForm, {
  businessPartnerProps,
} from 'components/domains/business-partners/BusinessPartnerCreateForm'
import LoadingButton from 'components/ui/button/LoadingButton'
import Dialog, { DialogSize } from 'components/ui/dialog/Dialog'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useBusinessPartnerCreate from 'hooks/services/business-partners/useBusinessPartnerCreate'

const businessPartnerCreationDialogProps = {
  title: PropTypes.string,
  size: PropTypes.oneOf(Object.values(DialogSize)),
  creationRole: PropTypes.string.isRequired,
  initialValues: businessPartnerProps,
  onAfterCreate: PropTypes.func,
  onClose: PropTypes.func,
}
const BusinessPartnerCreateDialog = ({
  title,
  size = DialogSize.M,
  creationRole,
  initialValues,
  onAfterCreate = (_) => {},
  onClose = () => {},
  ...dialogProps
}) => {
  const { t } = useTranslation('translation')
  const [businessPartner, setBusinessPartner] = useState({
    creationRole,
    ...initialValues,
  })
  const [isCreateButtonEnabled, setIsCreateButtonEnabled] = useState(false)
  const showErrorMessageBox = useShowMessageBox()

  const updateBusinessPartner = (changes) =>
    setBusinessPartner((prevState) => ({ ...prevState, ...changes }))

  const { mutate, isLoading } = useBusinessPartnerCreate({
    onSuccess: ({ fullName, ...rest }) => {
      setBusinessPartner({})
      onClose()
      onAfterCreate({ name: fullName, ...rest })
    },
    onError: async (error) => {
      const { detail } = await error.response.json()
      showErrorMessageBox({ type: MessageBoxTypes.Error, children: <Text>{detail}</Text> })
    },
  })

  const createBusinessPartner = () => {
    mutate(businessPartner)
  }

  return (
    <Dialog
      {...dialogProps}
      headerText={title ?? t('components.business-partner.dialog-create.title') ?? ''}
      size={size}
      primaryButton={
        <LoadingButton
          design={ButtonDesign.Emphasized}
          isLoading={isLoading}
          disabled={!isCreateButtonEnabled}
          onClick={createBusinessPartner}
          renderContent={() => t('buttons.create')}
        />
      }
      onAfterClose={onClose}
    >
      <BusinessPartnerCreateForm
        values={businessPartner}
        onChange={updateBusinessPartner}
        checkIsValid={setIsCreateButtonEnabled}
        isLoading={isLoading}
      />
    </Dialog>
  )
}

BusinessPartnerCreateDialog.propTypes = businessPartnerCreationDialogProps

export default BusinessPartnerCreateDialog
