import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import DecisionStageInformationDisplay from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/components/DecisionStageInformationDisplay'
import calculateDecisionStageToDisplay from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/components/DecisionStageToDisplayCalculator'

const CurrentDecisionStageTableCell = ({ decisionStages = [] }) =>
  useMemo(() => {
    if (decisionStages.length === 0) return <Label>{'-'}</Label>

    const decisionStageToDisplay = calculateDecisionStageToDisplay(decisionStages)
    return <DecisionStageInformationDisplay decisionStage={decisionStageToDisplay} />
  }, [decisionStages])

CurrentDecisionStageTableCell.propTypes = {
  decisionStages: PropTypes.array,
}

export default CurrentDecisionStageTableCell
