import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNumberFormatter } from 'hooks/i18n/useI18n'

/**
 * extracts the annualized current rent from property KPI data
 * and formats it to be an input for the `primaryMetric` prop of
 * the `CardHeaderWithMetrics` component
 */
const useAnnualizedCurrentRentPrimaryMetric = (propertyKpiData, label = null) => {
  // TODO adjust usages from properties to proeprtyKpiData
  const { t: tRentRollOverview } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.property.rent-roll.overview',
  })

  const formatAnnualizedCurrentRent = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: 'compact',
  })

  const primaryMetric = useMemo(() => {
    const annualizedCurrentRent = propertyKpiData?.annualizedCurrentRent
    const isNumberAndCurrencyGiven =
      annualizedCurrentRent?.number && annualizedCurrentRent?.currency

    const formattedAnnualizedCurrentRent = annualizedCurrentRent?.number
      ? formatAnnualizedCurrentRent(annualizedCurrentRent.number)
      : ''
    const annualizedCurrentRentUnit = annualizedCurrentRent?.currency ?? ''

    return {
      label: label ?? tRentRollOverview('annualized-current-rent'),
      value: isNumberAndCurrencyGiven ? formattedAnnualizedCurrentRent : null,
      unit: isNumberAndCurrencyGiven ? annualizedCurrentRentUnit : null,
    }
  }, [formatAnnualizedCurrentRent, label, propertyKpiData, tRentRollOverview])

  return primaryMetric
}

export default useAnnualizedCurrentRentPrimaryMetric
