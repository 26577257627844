import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteTranche = (options) => {
  const { delete: deleteTranche } = useAccessTokenRequest()

  return useMutation(
    async ({ dealId, trancheId }) =>
      await deleteTranche({
        path: `/financing-structures/deals/${dealId}/tranches/${trancheId}`,
        keys: ['deals', dealId, 'tranches'],
      }),
    options,
  )
}

export default useDeleteTranche
