import PropTypes from 'prop-types'
import { useCallback } from 'react'
import GenericConditionRequirementChangelogDialog from 'components/domains/conditions/dialogs/changelog/GenericConditionRequirementChangelogDialog'
import { ENTITIES } from 'components/domains/deals/change-overview/constants'
import useChangeLogPageIds from 'hooks/services/traceability-of-changes/useChangeLogPageIds'
import { SORT_DIRECTION } from 'hooks/services/traceability-of-changes/useChangeLogs'
import useEntityChangeLogs from 'hooks/services/traceability-of-changes/useEntityChangeLogs'

const ConditionsChangelogDialog = ({
  relatedEntityType,
  relatedEntityId,
  relatedEntityDisplayId,
  isOpen,
  setIsOpen,
}) => {
  const {
    isFetching: isPagesFetching,
    isLoading: isPagesLoading,
    isError: isPagesError,
    data: pageIdData,
  } = useChangeLogPageIds({
    entityType: relatedEntityType,
  })
  const {
    isFetching,
    isLoading,
    isError,
    data: changeLogData,
    fetchNextPage,
  } = useEntityChangeLogs(
    {
      relatedEntityType,
      relatedEntityId,
      entityTypes: [ENTITIES.EXTERNAL_CONDITION, ENTITIES.INTERNAL_CONDITION, ENTITIES.REQUIREMENT],
      pageIds: pageIdData?.pageIds,
      sorting: {
        field: 'triggered_at',
        direction: SORT_DIRECTION.DESC,
      },
    },
    {
      enabled: !isPagesFetching && !isPagesLoading && !isPagesError,
    },
  )

  const handleOnDialogClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  return (
    <GenericConditionRequirementChangelogDialog
      isOpen={isOpen}
      onAfterClose={handleOnDialogClose}
      isFetching={isFetching || isPagesFetching}
      isLoading={isLoading || isPagesLoading}
      isError={isError || isPagesError}
      changeLogData={changeLogData?.changeLogs}
      loadMore={fetchNextPage}
      totalNumberOfItems={changeLogData?.total}
      relatedEntityType={relatedEntityType}
      relatedEntityId={relatedEntityId}
      relatedEntityDisplayId={relatedEntityDisplayId}
    />
  )
}

ConditionsChangelogDialog.propTypes = {
  relatedEntityType: PropTypes.oneOf([ENTITIES.DEAL, ENTITIES.BUSINESS_PARTNER]),
  relatedEntityId: PropTypes.string.isRequired,
  relatedEntityDisplayId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default ConditionsChangelogDialog
