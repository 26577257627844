import { Modals, Text } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import '@ui5/webcomponents-fiori/dist/illustrations/EmptyList.js'
import { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useEditKeyInternalContacts from 'hooks/services/business-partners/config/useEditKeyInternalContacts'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

/**
 * Hook that encapsulates the logic for creating, editing and deleting key internal contacts
 */
const useHandleKeyInternalContacts = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { keyInternalContacts, id } = useContext(BusinessPartnerContext)
  const editKeyInternalContactsMutation = useEditKeyInternalContacts()

  const [keyInternalContactsState, setKeyInternalContactsState] = useState(keyInternalContacts)
  const [isDirtyEdit, setIsDirtyEdit] = useState(false)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setKeyInternalContactsState(keyInternalContacts)
  }, [keyInternalContacts])

  const notModifiableFunctionsWithUserId = keyInternalContacts?.map(({ userId, functions }) => ({
    userId,
    functions: functions.filter(({ modifiable }) => !modifiable),
  }))

  const mapKeyInternalContacts = (newKeyInternalContacts) =>
    newKeyInternalContacts
      .filter((keyInternalContact) => !!keyInternalContact.userId)
      .map(({ userId, functions }) => ({
        user_id: userId,
        functions: [
          ...functions,
          // We need to add the non modifiable functions to the request since the backend will remove them otherwise
          ...(notModifiableFunctionsWithUserId.filter(
            (notModifiableFunction) => notModifiableFunction.userId === userId,
          )[0]?.functions || []),
        ]
          // Remove duplicates that might have been introduced by the previous array concatenation
          .filter(
            (function1, function1Index, array) =>
              array.findIndex((function2) => function2.id === function1.id) === function1Index,
          )
          .map(({ id: functionId, name: functionName }) => ({
            id: functionId,
            name: functionName,
          })),
      }))

  // Add an empty key internal contact to the beginning of the array so they are shown first
  const addEmptyKeyInternalContact = () => {
    setKeyInternalContactsState([{ userId: '', functions: [] }, ...keyInternalContactsState])
  }

  const editKeyInternalContact = (keyInternalContactId, functions, index) => {
    const newKeyInternalContacts = keyInternalContactsState.map(
      // Only update the key internal contact that was edited, we need to use the index here
      // since we can have multiple new key internal contacts without id
      (keyInternalContact, localIndex) => {
        if (index === localIndex) {
          return {
            ...keyInternalContact,
            userId: keyInternalContactId,
            functions,
          }
        }
        return keyInternalContact
      },
    )
    setKeyInternalContactsState(newKeyInternalContacts)
    setIsDirtyEdit(true)
  }

  const removeKeyInternalContact = (index) => {
    const keyInternalContact = keyInternalContactsState.find(
      (_, localIndex) => localIndex === index,
    )
    editKeyInternalContact(keyInternalContact.userId, [], index)
  }

  const resetKeyInternalContacts = () => {
    setKeyInternalContactsState(keyInternalContacts)
    setIsDirtyEdit(false)
  }

  const saveKeyInternalContacts = () => {
    setIsLoading(true)

    editKeyInternalContactsMutation.mutate(
      {
        businessPartnerId: id,
        keyInternalContacts: mapKeyInternalContacts(keyInternalContactsState),
      },
      {
        onSuccess: () => {
          Modals.showToast({
            children: <Text>{t('toast.changes-saved')}</Text>,
          })
        },
        onError: async (error) => {
          const { message } = await error.response.json()
          setErrorMessage(message)
          setIsErrorDialogOpen(true)
        },
        onSettled: () => {
          queryClient.invalidateQueries(['businesspartners', id])
          setIsLoading(false)
        },
      },
    )
  }

  return {
    keyInternalContacts: keyInternalContactsState,
    addEmptyKeyInternalContact,
    editKeyInternalContact,
    removeKeyInternalContact,
    saveKeyInternalContacts,
    resetKeyInternalContacts,
    isErrorDialogOpen,
    isDirtyEdit,
    setIsErrorDialogOpen,
    errorMessage,
    isLoading,
  }
}

export default useHandleKeyInternalContacts
