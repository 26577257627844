import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

const CURRENCY_CODE_EUR = 'EUR'
const CURRENCY_CODES = [
  'AUD',
  'CAD',
  'CHF',
  'CZK',
  'DKK',
  CURRENCY_CODE_EUR,
  'GBP',
  'HKD',
  'HUF',
  'JPY',
  'NZD',
  'PLN',
  'RUB',
  'SDG',
  'SEK',
  'TRL',
  'USD',
  'ZAR',
]
export const DEBT_PER_EBITDA_COMMENT_CURRENCY_SELECTOR_DEFAULT_CURRENCY_CODE = CURRENCY_CODE_EUR

/*
 * Whenever this component is nested within a UI5 Tree, any time the drop-down of the Select is closed, an uncaught
 * error (`e.detail is null`) will be logged. This is likely due to some odd UI5-internal event handling and cannot be
 * prevented by us.
 */
const DebtPerEBITDACommentCurrencySelector = ({
  selectedCurrencyCode = DEBT_PER_EBITDA_COMMENT_CURRENCY_SELECTOR_DEFAULT_CURRENCY_CODE,
  onChange,
  ...props
}) => (
  <Select onChange={(event) => onChange(event.detail.selectedOption.value)} {...props}>
    {CURRENCY_CODES.map((currencyCode) => (
      <Option
        value={currencyCode}
        selected={currencyCode === selectedCurrencyCode}
        key={currencyCode}
      >
        {currencyCode}
      </Option>
    ))}
  </Select>
)

DebtPerEBITDACommentCurrencySelector.propTypes = {
  selectedCurrencyCode: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default DebtPerEBITDACommentCurrencySelector
