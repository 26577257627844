import {
  Card,
  CardHeader,
  FlexBox,
  FlexBoxJustifyContent,
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { marketAllowedOperations } from 'api/markets/marketAllowedOperations'
import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import PropertyAssignMarketButton from 'components/domains/properties/PropertyAssignMarketButton'
import PropertyMarketInfoCard from 'components/domains/properties/overview/PropertyMarketInfoCard'
import EmptyCard from 'components/ui/card/EmptyCard'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import '@ui5/webcomponents-fiori/dist/illustrations/sapIllus-Spot-UnableToLoad.js'
import useMarketPropertyAllowedOperations from 'hooks/services/markets/useMarketPropertyAllowedOperations'
import usePropertyIdForAssignedMarket from 'hooks/services/markets/usePropertyIdForAssignedMarket'
import { usePropertyMonitoring } from 'hooks/services/properties/monitoring/usePropertyMonitoring'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyMarketInfoCardWrapper = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property-overview.market-info' })
  const { property: { uuid: propertyUuid } = {} } = useContext(PropertyContext)

  const {
    isLoading,
    isError,
    data: marketData,
    error,
  } = usePropertyIdForAssignedMarket([propertyUuid])

  const {
    isLoading: monitoringIsLoading,
    isError: monitoringIsError,
    data: monitoringData,
    error: monitoringError,
  } = usePropertyMonitoring(propertyUuid)

  const {
    isFetching: allowedMarketPropertyOperationsIsFetching,
    isError: allowedMarketPropertyOperationsIsError,
    data: allowedMarketPropertyOperations,
  } = useMarketPropertyAllowedOperations({
    propertyUuid,
  })

  const monitoringStatus = monitoringData?.monitoring_status

  const displayAssignButton = !!allowedMarketPropertyOperations?.allowedOperations.includes(
    marketAllowedOperations.assignPropertyToMarket,
  )

  const CustomErrorScreen = () => (
    <Card header={<CardHeader titleText={t('title')} />}>
      <IllustratedMessage
        name={IllustrationMessageType.UnableToLoad}
        size={IllustrationMessageSize.Scene}
        titleText={t('error.title')}
        subtitleText={t('error.description')}
      />
    </Card>
  )

  const renderAssignMarketButton = () =>
    displayAssignButton && (
      <FlexBox justifyContent={FlexBoxJustifyContent.Center} style={{ marginTop: '16px' }}>
        <PropertyAssignMarketButton
          propertyId={propertyUuid}
          label={t('assign')}
          monitoringStatus={monitoringStatus}
        />
      </FlexBox>
    )
  const renderContent = () => {
    if (marketData.markets.length === 0) {
      return (
        <>
          <EmptyCard
            cardHeaderTitle={t('empty.header-title')}
            title={t('empty')}
            subtitle={t('empty.subtitle')}
            showCardHeaderActions={false}
            actions={renderAssignMarketButton()}
          />
        </>
      )
    }
    const market = marketData.markets[0] // hook either returns one market at position [0] or no market
    return (
      <PropertyMarketInfoCard
        propertyId={propertyUuid}
        marketId={market.id}
        marketName={market.info.name}
        marketType={market.market_type.value}
        marketPropertyIds={market.properties}
        monitoringStatus={monitoringStatus}
        displayAssignButton={displayAssignButton}
      />
    )
  }

  return (
    <RequestStateResolver
      isLoading={allowedMarketPropertyOperationsIsFetching || isLoading || monitoringIsLoading}
      isError={
        (isError && !isMissingPermissionError(error)) ||
        allowedMarketPropertyOperationsIsError ||
        (monitoringIsError &&
          !isMissingPermissionError(monitoringError) &&
          !isNotFoundError(monitoringError))
      }
      renderContent={renderContent}
      errorToDisplay={<CustomErrorScreen />}
    />
  )
}

export default PropertyMarketInfoCardWrapper
