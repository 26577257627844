import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import ConditionDealExternalAssigneeSelect from 'components/domains/conditions/shared/assignee/ConditionDealExternalAssigneeSelect'

const ExternalAssigneeSelectionFilter = ({ value, setSelectedFilters, columnKey }) => {
  const [userSearchKey, setUserSearchKey] = useState(value)

  useEffect(() => {
    if (!value) {
      setUserSearchKey('')
    }
  }, [value])

  const handleOnChange = useCallback(
    (newExternalAssignee) => {
      setSelectedFilters((currentlySelectedFilters) =>
        currentlySelectedFilters.map((filter) => {
          if (filter.columnKey !== columnKey) {
            return filter
          }
          return { ...filter, value: newExternalAssignee }
        }),
      )
    },
    [columnKey, setSelectedFilters],
  )

  return (
    <ConditionDealExternalAssigneeSelect
      externalAssignee={userSearchKey}
      onAssigneeChange={handleOnChange}
    />
  )
}

ExternalAssigneeSelectionFilter.propTypes = {
  value: PropTypes.string.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  columnKey: PropTypes.string.isRequired,
}

export default ExternalAssigneeSelectionFilter
