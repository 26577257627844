import mapCounterparties from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/mapCounterparties'
import mapOwnShare from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/mapOwnShare'
import mapTotal from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/mapTotal'
import { NO_SYNDICATION_TYPE } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/syndicationTypeCodes'

const mapSyndicationBackendDataToTableStructure = (
  t,
  syndicationData,
  tranchesData = [],
  isOpsMemo = false,
) => ({
  backendTableData: syndicationData
    ? [
        ...(!isOpsMemo ? mapTotal(t, syndicationData.total) : []),
        ...mapOwnShare(t, syndicationData.ownShare),
        ...mapCounterparties(t, syndicationData.counterparties, tranchesData, isOpsMemo),
      ]
    : [],
  headquarterCurrency: syndicationData?.total?.totalCommitment?.currency,
  tranches: syndicationData
    ? syndicationData?.total?.tranches.map((tranche) => ({
        trancheId: tranche.trancheId,
        name: tranche.name,
        totalCommitment: tranche.totalCommitment,
        skims: tranche?.fees.map((fee) => ({
          skimTypeCode: fee.skimTypeCode,
          skimTypeShortText: fee.skimTypeShortText,
          skimId: fee.skimId,
        })),
        fees: tranche?.fees.map((fee) => ({
          feeTypeCode: fee.feeTypeCode,
          feeTypeShortText: fee.feeTypeShortText,
          feeId: fee.feeId,
        })),
        customerMargin: {
          isCustomerMargin: true,
          skimTypeShortText: tranche.customerMargin?.skimTypeShortText,
          feeTypeShortText: tranche.customerMargin?.feeTypeShortText,
        },
      }))
    : [],
  tableHasCounterpartyWithNoSyndicationType: syndicationData?.counterparties?.some(
    (counterparty) => counterparty.syndicationTypeCode === NO_SYNDICATION_TYPE,
  ),
})

export default mapSyndicationBackendDataToTableStructure
