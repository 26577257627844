import ValueState from '@ui5/webcomponents-base/dist/types/ValueState'
import DecisionStageApprovalOverarchingApproveButton from 'components/domains/business-events-and-tasks/decision-stage/approval/overarching/buttons/DecisionStageApprovalOverarchingApproveButton'
import DecisionStageApprovalOverarchingApproveWithConditionsButton from 'components/domains/business-events-and-tasks/decision-stage/approval/overarching/buttons/DecisionStageApprovalOverarchingApproveWithConditionsButton'
import DecisionStageApprovalOverarchingDeclineButton from 'components/domains/business-events-and-tasks/decision-stage/approval/overarching/buttons/DecisionStageApprovalOverarchingDeclineButton'
import DecisionStageApprovalOverarchingReconveneButton from 'components/domains/business-events-and-tasks/decision-stage/approval/overarching/buttons/DecisionStageApprovalOverarchingReconveneButton'
import DecisionStageApprovalUnknownButton from 'components/domains/business-events-and-tasks/decision-stage/approval/shared/DecisionStageApprovalUnknownButton'
import DecisionStageApprovalVoterApproveButton from 'components/domains/business-events-and-tasks/decision-stage/approval/voter/buttons/DecisionStageApprovalVoterApproveButton'
import DecisionStageApprovalVoterApproveWithConditionsButton from 'components/domains/business-events-and-tasks/decision-stage/approval/voter/buttons/DecisionStageApprovalVoterApproveWithConditionsButton'
import DecisionStageApprovalVoterDeclineButton from 'components/domains/business-events-and-tasks/decision-stage/approval/voter/buttons/DecisionStageApprovalVoterDeclineButton'
import DecisionStageApprovalVoterReconveneButton from 'components/domains/business-events-and-tasks/decision-stage/approval/voter/buttons/DecisionStageApprovalVoterReconveneButton'
import DecisionStageApprovalVoterSignButton from 'components/domains/business-events-and-tasks/decision-stage/approval/voter/buttons/DecisionStageApprovalVoterSignButton'

export const decisionStageTypes = {
  attendance: 'BY_WAY_OF_ATTENDANCE',
  circulation: 'BY_WAY_OF_CIRCULATION',
}

export const decisionStageStatus = {
  planned: 'PLANNED',
  inProgress: 'IN_PROGRESS',
  inProgressRequestDiscarded: 'IN_PROGRESS_REQUEST_DISCARDED',
  approved: 'APPROVED',
  approvedWithConditions: 'APPROVED_WITH_CONDITIONS',
  declined: 'DECLINED',
  reconvened: 'RECONVENED',
}

const objectStatusForDecisionStageStatus = {
  [decisionStageStatus.planned]: {
    translationKey: 'decision-stage.status.planned',
    objectStatus: ValueState.None,
  },
  [decisionStageStatus.inProgress]: {
    translationKey: 'decision-stage.status.in-progress',
    objectStatus: ValueState.Information,
  },
  [decisionStageStatus.inProgressRequestDiscarded]: {
    translationKey: 'decision-stage.status.in-progress',
    objectStatus: ValueState.Information,
  },
  [decisionStageStatus.approved]: {
    translationKey: 'decision-stage.status.approved',
    objectStatus: ValueState.Success,
  },
  [decisionStageStatus.approvedWithConditions]: {
    translationKey: 'decision-stage.status.approved-with-conditions',
    objectStatus: ValueState.Warning,
  },
  [decisionStageStatus.declined]: {
    translationKey: 'decision-stage.status.declined',
    objectStatus: ValueState.Error,
  },
  [decisionStageStatus.reconvened]: {
    translationKey: 'decision-stage.status.reconvened',
    objectStatus: ValueState.None,
  },
}

const defaultDecisionStageStatusObjectStatus = {
  translationKey: 'decision-stage.status.unknown',
  objectStatus: ValueState.None,
}

export const getObjectStatusForDecisionStageStatus = (decisionStatus) =>
  objectStatusForDecisionStageStatus[decisionStatus] ?? defaultDecisionStageStatusObjectStatus

export const decisionStageVoterStatus = {
  planned: 'PLANNED',
  pending: 'PENDING',
  approved: 'APPROVED',
  approvedWithConditions: 'APPROVED_WITH_CONDITIONS',
  declined: 'DECLINED',
  reconvened: 'RECONVENED',
  signed: 'SIGNED_OFF',
  expired: 'EXPIRED',
  ignored: 'IGNORED',
}

const objectStatusForDecisionStageVoteStatus = {
  [decisionStageVoterStatus.planned]: {
    translationKey: 'decision-stage.vote.status.planned',
    objectStatus: ValueState.Information,
  },
  [decisionStageVoterStatus.pending]: {
    translationKey: 'decision-stage.vote.status.pending',
    objectStatus: ValueState.Information,
  },
  [decisionStageVoterStatus.approved]: {
    translationKey: 'decision-stage.vote.status.approved',
    objectStatus: ValueState.Success,
  },
  [decisionStageVoterStatus.approvedWithConditions]: {
    translationKey: 'decision-stage.vote.status.approved-with-conditions',
    objectStatus: ValueState.Warning,
  },
  [decisionStageVoterStatus.declined]: {
    translationKey: 'decision-stage.vote.status.declined',
    objectStatus: ValueState.Error,
  },
  [decisionStageVoterStatus.reconvened]: {
    translationKey: 'decision-stage.vote.status.reconvened',
    objectStatus: ValueState.None,
  },
  [decisionStageVoterStatus.signed]: {
    translationKey: 'decision-stage.vote.status.signed',
    objectStatus: ValueState.Success,
  },
  [decisionStageVoterStatus.expired]: {
    translationKey: 'decision-stage.vote.status.expired',
    objectStatus: ValueState.None,
  },
}

const defaultDecisionStageVoteStatusObjectStatus = {
  translationKey: 'decision-stage.vote.status.unknown',
  objectStatus: ValueState.None,
}

export const getObjectStatusForDecisionStageVoteStatus = (decisionVoteStatus) =>
  objectStatusForDecisionStageVoteStatus[decisionVoteStatus] ??
  defaultDecisionStageVoteStatusObjectStatus

export const decisionStageVoteOptions = {
  sign: 'SIGN',
  approve: 'APPROVE',
  approveWithConditions: 'APPROVE_WITH_CONDITIONS',
  decline: 'DECLINE',
  reconvene: 'RECONVENE',
}

const defaultDecisionStageOverarchingVoteOptionButton = DecisionStageApprovalUnknownButton

const buttonForDecisionStageOverarchingVoteOptions = {
  [decisionStageVoteOptions.approve]: DecisionStageApprovalOverarchingApproveButton,
  [decisionStageVoteOptions.approveWithConditions]:
    DecisionStageApprovalOverarchingApproveWithConditionsButton,
  [decisionStageVoteOptions.decline]: DecisionStageApprovalOverarchingDeclineButton,
  [decisionStageVoteOptions.reconvene]: DecisionStageApprovalOverarchingReconveneButton,
}

export const getButtonForDecisionStageOverarchingVoteOption = (
  decisionStageOverarchingVoteOption,
) =>
  buttonForDecisionStageOverarchingVoteOptions[decisionStageOverarchingVoteOption] ??
  defaultDecisionStageOverarchingVoteOptionButton

const defaultDecisionStageVoterVoteOptionButton = DecisionStageApprovalUnknownButton

const buttonForDecisionStageVoterVoteOptions = {
  [decisionStageVoteOptions.sign]: DecisionStageApprovalVoterSignButton,
  [decisionStageVoteOptions.approve]: DecisionStageApprovalVoterApproveButton,
  [decisionStageVoteOptions.approveWithConditions]:
    DecisionStageApprovalVoterApproveWithConditionsButton,
  [decisionStageVoteOptions.decline]: DecisionStageApprovalVoterDeclineButton,
  [decisionStageVoteOptions.reconvene]: DecisionStageApprovalVoterReconveneButton,
}

export const getButtonForDecisionStageVoterVoteOption = (decisionStageVoterVoteOption) =>
  buttonForDecisionStageVoterVoteOptions[decisionStageVoterVoteOption] ??
  defaultDecisionStageVoterVoteOptionButton
