import camelize from 'camelize'
import { useMemo } from 'react'
import { isMissingPermissionError } from 'api/requests'
import { useInfiniteRequest } from 'hooks/services/baseService'

const defaultSorting = {
  sortBy: 'market_name',
  orderBy: 'asc',
}

const processResponse = (response) => {
  if (!response.data) return undefined
  return response.data.pages.reduce((acc, { markets, total }) => ({
    markets: [...acc.markets, ...markets],
    total,
  }))
}

// Yes this could be a ternary expression, but then this would get kind of unreadable. This makes
// the conditions much clearer
const atLeastOneFilterHasValue = ({ searchFilter, marketTypes, marketCountry, minLength }) => {
  if (searchFilter.length >= minLength) {
    return true
  }
  if (marketTypes.length !== 0) {
    return true
  }
  if (marketCountry !== '') {
    return true
  }
  return false
}

const useMarkets = (
  { sortBy, orderBy } = defaultSorting,
  {
    searchFilter = '',
    marketTypes = [],
    marketCountry = '',
    needsFilters = false,
    minLength = 0,
  } = {},
  queryOptions = {},
) => {
  const queryParams = new URLSearchParams()

  queryParams.append('sort_by', sortBy)
  queryParams.append('order_by', orderBy)

  let isMarketsQueryEnabled = true

  if (
    needsFilters &&
    !atLeastOneFilterHasValue({ searchFilter, marketTypes, marketCountry, minLength })
  ) {
    isMarketsQueryEnabled = false
  }

  if (searchFilter) queryParams.append('search_filter', searchFilter)
  if (marketTypes.length > 0) queryParams.append('market_type', marketTypes.join(','))
  if (marketCountry) queryParams.append('market_country', marketCountry)

  const cachedKeys = ['markets']

  const response = useInfiniteRequest({
    path: '/markets',
    queryParams,
    translated: true,
    useCache: true,
    keys: cachedKeys,
    options: {
      retry: (_, error) => !isMissingPermissionError(error),
      enabled: isMarketsQueryEnabled,
      ...queryOptions,
    },
  })
  return useMemo(
    () => ({ ...response, data: camelize(processResponse(response)) }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      response.data,
      response.isLoading,
      response.isError,
      response.error,
      response.isFetchingNextPage,
    ],
  )
}

export default useMarkets
