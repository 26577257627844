import { FlexBoxAlignItems } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'

export const CovenantCheckSelectionTableColums = {
  covenantCheck: 'covenant-check',
  deals: 'deals',
  monitoringReferenceType: 'monitoring-reference-type',
  monitoringReference: 'monitoring-reference',
  covenants: 'covenants',
  monitoringEvent: 'monitoring-event',
  keyDate: 'key-date',
  status: 'status',
  firstDeliveryDate: 'first-delivery-date',
  breach: 'breach',
}

const useCovenantCheckSelectionTableColumnDefinitions = ({
  enums: { statusEnum, breachEnum, monitoringReferenceTypeEnum },
  mainEntityType,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.covenant-check-selection.table',
  })

  const dealColumn = useMemo(
    () =>
      mainEntityType === cwpEntityTypes.BUSINESS_PARTNER
        ? [
            {
              title: t('column.deals'),
              popinText: t('column.deals'),
              columnKey: CovenantCheckSelectionTableColums.deals,
              minWidth: 900,
              demandPopin: true,
              sortingDisabled: true,
            },
          ]
        : [],
    [mainEntityType, t],
  )

  return useMemo(
    () => [
      {
        title: t('column.covenant-check'),
        columnKey: CovenantCheckSelectionTableColums.covenantCheck,
        isSelectableForHiding: false,
        filter: filterTypes.CONTAINS,
      },
      ...dealColumn,
      {
        title: t('column.monitoring-reference-type'),
        popinText: t('column.monitoring-reference-type'),
        columnKey: CovenantCheckSelectionTableColums.monitoringReferenceType,
        isVisible: false,
        filter: filterTypes.OF_ENUM_TYPE,
        additionalFilterOptions: { enumValues: monitoringReferenceTypeEnum },
        minWidth: 900,
        demandPopin: true,
      },
      {
        title: t('column.monitoring-reference'),
        popinText: t('column.monitoring-reference'),
        columnKey: CovenantCheckSelectionTableColums.monitoringReference,
        isVisible: false,
        minWidth: 900,
        demandPopin: true,
      },
      {
        title: t('column.covenants'),
        popinText: t('column.covenants'),
        columnKey: CovenantCheckSelectionTableColums.covenants,
        minWidth: 900,
        demandPopin: true,
        sortingDisabled: true,
      },
      {
        title: t('column.monitoring-event'),
        popinText: t('column.monitoring-event'),
        columnKey: CovenantCheckSelectionTableColums.monitoringEvent,
        minWidth: 700,
        demandPopin: true,
        sortingDisabled: true,
      },
      {
        title: t('column.key-date'),
        popinText: t('column.key-date'),
        columnKey: CovenantCheckSelectionTableColums.keyDate,
        filter: filterTypes.BETWEEN_DATES,
        alignment: FlexBoxAlignItems.End,
        minWidth: 700,
        demandPopin: true,
      },
      {
        title: t('column.status'),
        popinText: t('column.status'),
        columnKey: CovenantCheckSelectionTableColums.status,
        filter: filterTypes.OF_ENUM_TYPE,
        additionalFilterOptions: { enumValues: statusEnum },
        minWidth: 700,
        demandPopin: true,
      },
      {
        title: t('column.first-delivery-date'),
        popinText: t('column.first-delivery-date'),
        columnKey: CovenantCheckSelectionTableColums.firstDeliveryDate,
        filter: filterTypes.BETWEEN_DATES,
        alignment: FlexBoxAlignItems.End,
        minWidth: 600,
        demandPopin: true,
      },
      {
        title: t('column.breach'),
        popinText: t('column.breach'),
        columnKey: CovenantCheckSelectionTableColums.breach,
        filter: filterTypes.OF_ENUM_TYPE,
        additionalFilterOptions: { enumValues: breachEnum },
        minWidth: 600,
        demandPopin: true,
      },
    ],
    [breachEnum, dealColumn, monitoringReferenceTypeEnum, statusEnum, t],
  )
}

export default useCovenantCheckSelectionTableColumnDefinitions
