import i18n from 'i18next'
import { useCallback, useEffect, useState } from 'react'

const DEFAULT_LOCALE = 'en-US'

export const useLanguage = () => {
  const [language, setLanguage] = useState(i18n.language)

  useEffect(() => {
    i18n.on('languageChanged', setLanguage)
    return () => i18n.off('languageChanged')
  }, [])

  return language || DEFAULT_LOCALE
}

/**
 * Returns an object containing the three or four lines of a formated address depending on the language
 */
export const useAddressFormatter = () => {
  const language = useLanguage()
  return useCallback(
    ({ country, street, houseNumber, zipCode, city, state }) => {
      const cityCommaAppended = city && state ? `${city},` : city

      switch (language) {
        case 'en-US':
          return {
            firstLine: [houseNumber, street].join(' '),
            secondLine: [cityCommaAppended, state, zipCode].join(' '),
            thirdLine: country,
          }
        case 'en-GB':
          return {
            firstLine: [houseNumber, street].join(' '),
            secondLine: city,
            thirdLine: zipCode,
            fourthLine: country,
          }
        case 'de-DE':
          return {
            firstLine: [street, houseNumber].join(' '),
            secondLine: [zipCode, city].join(' '),
            thirdLine: country,
          }
        default:
          return {
            firstLine: [houseNumber, street].join(' '),
            secondLine: [cityCommaAppended, state, zipCode].join(' '),
            thirdLine: country,
          }
      }
    },
    [language],
  )
}
