import { BusyIndicatorSize } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import CovenantChecksTable from 'components/domains/deals/covenants/covenant-checks-table/CovenantChecksTable'
import styles from 'components/domains/deals/covenants/covenant-checks-table/DealCovenantChecksCard.module.css'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInTable } from 'components/ui/errors/ErrorDataUnavailableInTable'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import { useCovenantChecksWithFrontendPagination } from 'hooks/services/deals/covenants/checks/useCovenantChecks'
import { useInfiniteLoading } from 'hooks/services/useInfiniteLoading'

const validEntities = [cwpEntityTypes.DEAL, cwpEntityTypes.BUSINESS_PARTNER]

const DealCovenantChecksCard = ({ isAllowedToReadCovenants, entityType, entityId }) => {
  const [sorting, setSorting] = useState({})
  const paginationDelta = 50

  const onSortingChanged = (orderField, orderDirection) => {
    setSorting({ orderDirection, orderField })
  }

  const requestHookArgForEntity = {
    [cwpEntityTypes.BUSINESS_PARTNER]: mainEntityTypes.BUSINESSPARTNER,
    [cwpEntityTypes.DEAL]: mainEntityTypes.DEAL,
  }[entityType]

  const {
    isFetching: isFetchingCovenantChecks,
    isError: isErrorCovenantChecks,
    data: {
      covenantChecks = [],
      pagination = {
        offset: 0,
        limit: 0,
        total: 0,
      },
    },
    loadMore: loadMoreCovenantChecks,
  } = useInfiniteLoading({
    requestHook: useCovenantChecksWithFrontendPagination,
    requestHookArgs: {
      mainEntityType: mainEntityTypes.DEAL,
      monitoringReferenceEntityType: requestHookArgForEntity,
      monitoringReferenceEntityId: entityId,
      sort: sorting,
    },
    dataListElement: 'covenantChecks',
    paginationDelta: paginationDelta,
  })

  return (
    <Card>
      <div className={styles.tableWrapper}>
        <RequestStateResolver
          center
          busyIndicatorSize={BusyIndicatorSize.Medium}
          isLoading={isFetchingCovenantChecks}
          isError={isErrorCovenantChecks}
          errorToDisplay={<ErrorDataUnavailableInTable />}
          renderContent={() => (
            <CovenantChecksTable
              covenantChecksData={covenantChecks}
              pagination={pagination}
              onSortingChanged={onSortingChanged}
              loadMore={loadMoreCovenantChecks}
              sorting={sorting}
              isAllowedToReadCovenants={isAllowedToReadCovenants}
              entityType={entityType}
            />
          )}
        />
      </div>
    </Card>
  )
}

DealCovenantChecksCard.propTypes = {
  isAllowedToReadCovenants: PropTypes.bool,
  entityType: PropTypes.oneOf(validEntities).isRequired,
  entityId: PropTypes.string.isRequired,
}

export default DealCovenantChecksCard
