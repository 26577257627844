import { InputType } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAreaMeasurementUnitFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-factor/shared/i18n/useI18n'
import {
  unitCodeIsPieces,
  useAreaMeasureUnitCodes,
} from 'hooks/services/properties/useAreaUnitOfMeasureCodes'
import { useCurrencyCodes } from 'hooks/services/properties/useCurrencyCodes'

const DEFAULT_CURRENCY = 'EUR'
const DEFAULT_UOM = 'M2'

const useFactSheetValuationFactorOptions = ({ isEditMode }) => {
  const formatAreaUnit = useAreaMeasurementUnitFormatter()

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.fact-sheet-valuation-factor.option',
  })

  const { data: areaMeasureUnitCodesData } = useAreaMeasureUnitCodes({ enabled: isEditMode })

  const { data: currencyCodesData } = useCurrencyCodes({ enabled: isEditMode })

  const areaMeasureUnitCodes = useMemo(() => {
    const uoms = areaMeasureUnitCodesData
      ? areaMeasureUnitCodesData?.measure_unit_codes
          ?.filter((code) => !unitCodeIsPieces(code.key))
          .map((code) => formatAreaUnit(code.key))
      : [formatAreaUnit(DEFAULT_UOM)]
    //always set default UoM as first (default) option
    const uomsWithoutDefaultOption = uoms.filter((unit) => unit !== formatAreaUnit(DEFAULT_UOM))
    return [formatAreaUnit(DEFAULT_UOM), ...uomsWithoutDefaultOption]
  }, [areaMeasureUnitCodesData, formatAreaUnit])

  const currencyCodes = useMemo(() => {
    const currencies = currencyCodesData
      ? currencyCodesData?.currency_codes.map((currencyCode) => currencyCode.display_name)
      : [DEFAULT_CURRENCY]
    //always set default currency as first (default) option
    const currenciesWithoutDefaultOption = currencies.filter((cur) => cur !== DEFAULT_CURRENCY)
    return [DEFAULT_CURRENCY, ...currenciesWithoutDefaultOption]
  }, [currencyCodesData])

  const currencyPerUomOptions = useMemo(
    () =>
      areaMeasureUnitCodes
        .map((measureUnitCode) =>
          currencyCodes.map((currencyCode) => `${currencyCode}/${measureUnitCode}`),
        )
        .flat(),
    [areaMeasureUnitCodes, currencyCodes],
  )

  const optionList = [
    ['cap-rate', '%', InputType.Number],
    ['capital-value', currencyPerUomOptions, InputType.Number],
    ['multiplier-current-rent', '', InputType.Number],
    ['multiplier-market-rent', '', InputType.Number],
    ['yield-discount', '%', InputType.Number],
    ['yield-equivalent', '%', InputType.Number],
    ['yield-exit', '%', InputType.Number],
    ['yield-market-rent', '%', InputType.Number],
    ['yield-gross-initial', '%', InputType.Number],
    ['yield-net-initial', '%', InputType.Number],
    ['yield-reversionary', '%', InputType.Number],
    ['other', '', InputType.Text],
  ]
  return optionList.map(([option, label, inputType]) => ({
    key: option,
    displayValue: t(option),
    label,
    inputType,
  }))
}

export default useFactSheetValuationFactorOptions
