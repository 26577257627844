import { useRequest } from 'hooks/services/baseService'

const useMarketCountries = () =>
  useRequest({
    path: '/markets/countries',
    translated: true,
    useCache: true,
    keys: ['market-countries'],
  })

export default useMarketCountries
