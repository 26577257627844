import {
  FlexBox,
  FlexBoxDirection,
  Label,
  Link,
  MessageStripDesign,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants-and-ratios/shared/CashFlowScenarioNameTile.module.css'
import CloseableMessageStrip from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants-and-ratios/shared/DealCashFlowCloseableMessageStrip'

const CashFlowScenarioNameTile = ({
  metaData,
  dealId,
  showMessageStrip = true,
  isPdfView = false,
}) => {
  const { t: tCashFlow } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.cash-flow',
  })

  const cashFlowScenarioName = !isPdfView ? (
    <Link
      href={`/deals/${dealId}/cashflows/${metaData?.selectedCashFlowScenarioUuid}`}
      target="_blank"
    >
      {metaData?.selectedCashFlowScenarioName}
    </Link>
  ) : (
    <Text>{metaData?.selectedCashFlowScenarioName}</Text>
  )

  return (
    <>
      <div className={styles.message}>
        {!metaData && showMessageStrip && (
          <CloseableMessageStrip
            design={MessageStripDesign.Information}
            hideCloseButton={isPdfView}
          >
            {tCashFlow('message-strip')}
          </CloseableMessageStrip>
        )}
      </div>
      <FlexBox direction={FlexBoxDirection.Row} className={styles.nameTileFlexBox}>
        <Label showColon>{tCashFlow('scenario-name')}</Label>
        {metaData?.selectedCashFlowScenarioName
          ? cashFlowScenarioName
          : tCashFlow('scenario-name.empty')}
      </FlexBox>
      <DecisionPaperTileListSeparator />
    </>
  )
}

CashFlowScenarioNameTile.propTypes = {
  metaData: PropTypes.shape({
    selectedCashFlowScenarioName: PropTypes.string,
    selectedCashFlowScenarioUuid: PropTypes.string,
  }),

  dealId: PropTypes.string.isRequired,
  showMessageStrip: PropTypes.bool,
  isPdfView: PropTypes.bool,
}

export default CashFlowScenarioNameTile
