import { FlexBox, FlexBoxJustifyContent, Text } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/conditions/dialogs/condition-creation/ConditionCreationTableEmpty.module.css'

const ConditionCreationTableEmpty = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.dialog.external-condition-creation.table',
  })
  return (
    <FlexBox justifyContent={FlexBoxJustifyContent.Center} className={styles.noData}>
      <Text>{t('empty')}</Text>
    </FlexBox>
  )
}

ConditionCreationTableEmpty.propTypes = {}

export default ConditionCreationTableEmpty
