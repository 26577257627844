import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useKpis from 'hooks/services/kpis/useKpis'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'
import KpiDetailsContent from 'routes/kpis/KpiDetailsContent'
import paths from 'routes/paths'

const DealKpiDetailsPage = () => {
  const { t } = useTranslation('translation')

  const {
    deal: { dealUuid, displayId },
  } = useContext(DealContext)

  const referenceEntityType = cwpEntityTypes.DEAL
  const referenceEntityId = dealUuid
  const {
    data: { kpis = [] } = {},
    isError,
    isFetching,
  } = useKpis(referenceEntityType, referenceEntityId)

  const { kpiId } = useParams()
  const kpi = kpis.find(({ id }) => id === kpiId)

  const breadcrumbs = useMemo(
    () => [
      {
        text: t('pages.deals.detail.kpis.breadcrumb'),
        href: `/${paths.deals}/${displayId}/kpis/`,
      },
      {
        text: kpi?.name ?? kpiId,
      },
    ],
    [t, displayId, kpi, kpiId],
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={dealUuid}
        entityType={referenceEntityType}
      />
    ),
    [dealUuid, referenceEntityType],
  )

  return (
    <DealDetailsWrapper
      childBreadcrumbs={breadcrumbs}
      actions={markFavoriteAction}
      showWVLink={false}
    >
      <KpiDetailsContent
        referenceEntityType={referenceEntityType}
        referenceEntityId={referenceEntityId}
        kpiId={kpiId}
        isFetching={isFetching}
        isError={isError}
      />
    </DealDetailsWrapper>
  )
}

export default DealKpiDetailsPage
