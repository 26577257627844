import { useContext } from 'react'
import AssociatedDeals from 'components/domains/business-partners/related-documents/AssociatedDeals'
import useDeals from 'hooks/services/deals/useDeals'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

const borrowerRole = { id: 'Borrower' }

const BusinessPartnerAssociatedDeals = () => {
  const { id: businessPartnerId, roles } = useContext(BusinessPartnerContext)
  const {
    data: { deals = [] } = {},
    isLoading,
    isError,
  } = useDeals({ filter: { borrowerId: businessPartnerId } })
  const hasRoleBorrower = roles?.some(({ id }) => id === borrowerRole.id)

  if (!hasRoleBorrower) return <div />

  return <AssociatedDeals deals={deals} isLoading={isLoading} isError={isError} />
}

export default BusinessPartnerAssociatedDeals
