import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import styles from 'components/ui/button/SemanticButton.module.css'

export const SemanticButtonDesign = {
  Success: 'success',
  Warning: 'warning',
  Error: 'error',
}

/**
 * @deprecated | use UI5-ButtonDesign (Attention/Positive/Negative) instead | https://fioneer.atlassian.net/browse/CWP-13200
 */
const SemanticButton = forwardRef(
  ({ design, children, className: originalClassName, ...props }, ref) => {
    const className = originalClassName ? `${originalClassName} ${styles[design]}` : styles[design]

    return (
      <Button design={ButtonDesign.Default} ref={ref} className={className} {...props}>
        {children}
      </Button>
    )
  },
)

SemanticButton.displayName = 'SemanticButton'

SemanticButton.propTypes = {
  design: PropTypes.oneOf(Object.values(SemanticButtonDesign)).isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default SemanticButton
