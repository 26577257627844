import { MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import PropertyRentRollExcelExportLoadingScreen from 'components/domains/properties/rent-roll/excel-export/PropertyRentRollExcelExportLoadingScreen'
import MessageBoxWithExpandableDetails from 'components/ui/dialog/MessageBoxWithExpandableDetails'
import useRentRollExcelExport from 'hooks/services/properties/rent-roll/excel-export/useRentRollExcelExport'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'

const PropertyRentRollExcelExporter = ({ properties, multiPropertyViewId, children }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.properties.rent-roll.excel-export',
  })
  const { download, isExportInProgress, isExportError, exportErrors, dismissErrors } =
    useRentRollExcelExport(properties, multiPropertyViewId)
  const [errorDetails, setErrorDetails] = useState()
  useEffect(() => {
    exportErrors?.length &&
      Promise.all(exportErrors.map(formatHookError)).then((errors) => {
        setErrorDetails(errors.join('\n\n\n'))
      })
  }, [exportErrors])

  return (
    <>
      {children({
        onClick: download,
      })}
      {isExportInProgress && (
        <PropertyRentRollExcelExportLoadingScreen isVisible={isExportInProgress} />
      )}
      {isExportError &&
        createPortal(
          <MessageBoxWithExpandableDetails
            messageSummary={t('error-dialog.title')}
            messageDetails={errorDetails}
            messageBoxType={MessageBoxTypes.Error}
            isOpen={isExportError}
            onClose={dismissErrors}
          />,
          document.body,
        )}
    </>
  )
}

PropertyRentRollExcelExporter.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
  multiPropertyViewId: PropTypes.string,
  children: PropTypes.func.isRequired,
}

export default PropertyRentRollExcelExporter
