import {
  FlexBox,
  FlexBoxDirection,
  Input,
  Label,
  ResponsiveGridLayout,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LoadingDropdown from 'components/ui/dropdown/LoadingDropdown'
import useMarketTypes from 'hooks/services/markets/useMarketTypes'
import { setMarketCode, setMarketName } from 'redux/slices/markets/marketCreationSlice'

const GeneralMarketInfoPanel = () => {
  const { marketName, marketCode } = useSelector((state) => state.markets.marketCreation)
  const { isError, isLoading, data: marketTypesData } = useMarketTypes()
  const [formErrors, setFormErrors] = useState({})
  const { t } = useTranslation('translation', { keyPrefix: 'pages.markets.overview.create-dialog' })
  const dispatch = useDispatch()

  const marketTypesResult = useMemo(() => {
    if (isLoading || isError) return { isLoading, isError, data: undefined }
    return {
      data: marketTypesData?.market_types?.map(({ code, value }) => ({ code, displayName: value })),
      isLoading: false,
      isError: false,
    }
  }, [isError, isLoading, marketTypesData?.market_types])
  const marketTypes = useCallback(() => marketTypesResult, [marketTypesResult])

  return (
    <>
      <ResponsiveGridLayout
        columnsXL={2}
        columnsL={2}
        columnsM={2}
        columnsS={2}
        columnGap="1rem"
        rowGap="0rem"
        style={{ marginLeft: '-16px', marginRight: '-16px' }}
      >
        <FlexBox direction={FlexBoxDirection.Column}>
          <Label showColon required>
            {t('info-panel.name-column')}
          </Label>
          <Input
            id="market-name"
            required
            value={marketName}
            onInput={(event) => {
              dispatch(setMarketName(event.target.value))
              if (event.target.value === '') {
                setFormErrors((oldState) => ({ ...oldState, marketName: true }))
                return
              }
              setFormErrors((oldState) => ({ ...oldState, marketName: false }))
            }}
            onBlur={({ target: { value: newMarketName } }) => {
              if (newMarketName === '') {
                setFormErrors((oldState) => ({ ...oldState, marketName: true }))
                return
              }
              setFormErrors((oldState) => ({ ...oldState, marketName: false }))
            }}
            valueState={formErrors.marketName ? ValueState.Error : ValueState.None}
            valueStateMessage={<Text>{t('market.name.error-state')}</Text>}
          />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column}>
          <Label showColon required>
            {t('info-panel.type-column')}
          </Label>
          <LoadingDropdown
            id="filter-market-type-select"
            value={marketCode}
            onChange={(value) => {
              dispatch(setMarketCode(value))
              if (value === '') {
                setFormErrors((oldState) => ({ ...oldState, marketCode: true }))
                return
              }
              setFormErrors((oldState) => ({ ...oldState, marketCode: false }))
            }}
            onBlur={(value) => {
              if (!value) {
                setFormErrors((oldState) => ({ ...oldState, marketCode: true }))
                return
              }
              setFormErrors((oldState) => ({ ...oldState, marketCode: false }))
            }}
            useLoader={marketTypes}
            initialDropdownValue={' '}
            valueState={formErrors.marketCode ? ValueState.Error : ValueState.None}
            style={{ width: '100%' }}
            valueStateMessage={<Text>{t('market.type.error-state')}</Text>}
          />
        </FlexBox>
      </ResponsiveGridLayout>
    </>
  )
}

export default GeneralMarketInfoPanel
