import { Link } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DecisionStageLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/financed-assets-information/shared/DecisionStageLeaveConfirmationLink'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/critical-facility/shared/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/critical-facility/shared/DisplayCardViewItemView'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/shared/ui/card/EmptyCardContent'
import paths from 'routes/paths'

const MultiplePropertyInformationSectionLine = ({ label, value, ...props }) => (
  <DisplayCardViewItemView>
    <DisplayCardViewInfoItemView label={label} value={value ? value : ''} {...props} />
  </DisplayCardViewItemView>
)

MultiplePropertyInformationSectionLine.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
}

const MultiplePropertyInformationSection = ({
  propertyInformation,
  showConfirmationForLinks,
  selectedProperty,
  isPdfView,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.financed-asset-information',
  })

  const propertyData =
    selectedProperty && Array.isArray(propertyInformation)
      ? propertyInformation.find((property) => property.uuid === selectedProperty)
      : propertyInformation

  const renderContent = () => {
    if (!propertyData) {
      return <EmptyCardContent />
    }

    const propertyNameLink = showConfirmationForLinks ? (
      <DecisionStageLeaveConfirmationLink
        name={propertyData.name}
        link={`/${paths.properties}/${propertyData.id}`}
      />
    ) : (
      <Link href={`/${paths.properties}/${propertyData.id}`} target="_blank">
        {propertyData.name}
      </Link>
    )

    const marketNameLink = showConfirmationForLinks ? (
      <DecisionStageLeaveConfirmationLink
        name={propertyData?.market?.name ?? '-'}
        link={`/${paths.markets}/${propertyData?.market?.id}`}
      />
    ) : (
      <Link href={`/${paths.markets}/${propertyData?.market?.id}`} target="_blank">
        {propertyData?.market?.name ?? '-'}
      </Link>
    )

    return (
      <>
        <MultiplePropertyInformationSectionLine
          label={t('label.property-name')}
          value={!isPdfView ? propertyNameLink : propertyData.name}
          isFirstItem
        />
        <MultiplePropertyInformationSectionLine
          label={t('label.property-id')}
          value={propertyData.id}
        />
        <MultiplePropertyInformationSectionLine
          label={t('label.property-type')}
          value={propertyData.type}
        />
        <MultiplePropertyInformationSectionLine
          label={t('label.country')}
          value={propertyData.address.country}
        />
        <MultiplePropertyInformationSectionLine
          label={t('label.postal-code')}
          value={propertyData.address.postalCode}
        />
        <MultiplePropertyInformationSectionLine
          label={t('label.city')}
          value={propertyData.address.city}
        />
        <MultiplePropertyInformationSectionLine
          label={t('label.street')}
          value={propertyData.address.street}
        />
        <MultiplePropertyInformationSectionLine
          label={t('label.house-id')}
          value={propertyData.address.houseId}
        />
        <MultiplePropertyInformationSectionLine
          label={t('label.market')}
          value={!isPdfView ? marketNameLink : propertyData?.market?.name}
        />
      </>
    )
  }

  return renderContent()
}

MultiplePropertyInformationSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  propertyInformation: PropTypes.array.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
  selectedProperty: PropTypes.string,
  isPdfView: PropTypes.bool.isRequired,
}

export default MultiplePropertyInformationSection
