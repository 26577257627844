import {
  Button,
  Dialog,
  Title,
  Bar,
  Option,
  Table,
  TableColumn,
  FlexBox,
  TableRow,
  TableCell,
  Select,
  FlexBoxJustifyContent,
  CheckBox,
} from '@fioneer/ui5-webcomponents-react'
import { escapeRegExp } from 'lodash'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import getPropertyRentalUnitsTableContentDefinitions from 'components/domains/properties/getPropertyRentRollWorkingVersionReferenceData'
import styles from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollAdjustExcelColumnMappingDialog.module.css'
import { ExcelUploadContext } from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollExcelUploadContext'
import { findFirstContentRow } from 'components/domains/properties/rent-roll/working-version/excel-upload/commonRentRollExcelUploadFunctions'
import { MONTHLY_CONTENT_KEY_MAP } from 'components/domains/properties/rent-roll/working-version/excel-upload/constants'
import getIsoDate from 'components/domains/properties/rent-roll/working-version/excel-upload/getIsoDate'
import getPropertyRentRollWorkingVersionExcelColumnMapping from 'components/domains/properties/rent-roll/working-version/excel-upload/getPropertyRentRollWorkingVersionExcelColumnMapping'
import {
  useNumberFormatter,
  useFormattedNumberParser,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'
import useGetAllRentRollWorkingVersionOptions from 'hooks/services/properties/rent-roll/working-version/useGetAllRentRollWorkingVersionOptions'

export const workingVersionContentKeysWithoutExcelMapping = [
  'segment_uuid',
  'tenant_id',
  'branding_franchise_partner_id',
  'rental_unit_id',
  'property_id',
]

/**
 *
 * @param {object} headerRow row from the Excel that contains the header
 * @returns copy of headerRow with the difference that duplicated values are numbered
 */
export const getHeaderFromInputExcelWithDuplicates = (headerRow) => {
  if (!headerRow || typeof headerRow !== 'object') return

  return Object.entries(headerRow).reduce((accumulator, currentValue) => {
    const numberOfOccurences = Object.values(accumulator).filter(
      (value) =>
        !isNil(value) &&
        (value === currentValue[1] ||
          new RegExp(`${escapeRegExp(currentValue[1])} \\[\\d\\]`).test(value)),
    ).length
    return numberOfOccurences === 0
      ? { ...accumulator, [currentValue[0]]: currentValue[1] }
      : {
          ...accumulator,
          [currentValue[0]]: `${currentValue[1]} [${numberOfOccurences}]`,
        }
  }, {})
}

const RentRollAdjustExcelColumnMappingDialog = ({
  handleAcceptMappingAndContinue,
  isMultiProperty,
}) => {
  const { t: tExcelUpload } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.excel-upload',
  })
  const { t: tRentRollTable } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.table',
  })
  const { localePattern, format: formatDate } = useShortDateFormatter()

  const excelUploadContext = useContext(ExcelUploadContext)
  const {
    isAdjustMappingDialogOpen,
    closeAdjustMappingDialog,
    inputExcelData,
    excelToTableMapping,
    setExcelToTableMapping,
    mappedExcelData,
    setMappedExcelData,
    rowNumberHeader,
    openChooseHeaderRowDialog,
  } = excelUploadContext

  const contentDefinitions = getPropertyRentalUnitsTableContentDefinitions(
    tRentRollTable,
    isMultiProperty,
  )

  const allOptions = useGetAllRentRollWorkingVersionOptions()
  const [checkedMonthlyValueContentKeys, setCheckedMonthlyValueContentKeys] = useState([])

  const initialExcelColTitles = () =>
    inputExcelData[rowNumberHeader]
      ? Object.values(getHeaderFromInputExcelWithDuplicates(inputExcelData[rowNumberHeader]))
      : []

  const excelColTitles = () => [tExcelUpload('unknown'), ...initialExcelColTitles()]

  /** Sets monthly value content keys in mapping
   *   If it is a monthly value --> the mapping is set
   *   If it is a yearly value --> the mapping is empty
   */
  const handleYearlyAndMonthlyValues = (contentKey, mapping, isMonthlyValue) => {
    const excelCol = isMonthlyValue ? mapping.get(contentKey)?.excelCol : ''
    const excelColContent = isMonthlyValue ? mapping.get(contentKey)?.excelColContent : ''
    mapping.set(MONTHLY_CONTENT_KEY_MAP.get(contentKey), {
      excelCol: excelCol ?? '',
      excelColContent: excelColContent ?? '',
    })
  }

  const handleMonthlyMappings = (mapping) => {
    checkedMonthlyValueContentKeys.forEach((contentKey) => {
      handleYearlyAndMonthlyValues(contentKey, mapping, true)
    })
  }

  const handleMonthlyCheckboxValueChange = (isMonthlyValue, contentKey) => {
    const newMapping = new Map(excelToTableMapping)
    const changeMonthlyValueState = isMonthlyValue
      ? (oldValues) => [...oldValues, contentKey]
      : (oldValues) => oldValues.filter((oldValue) => oldValue !== contentKey)
    setCheckedMonthlyValueContentKeys(changeMonthlyValueState)
    handleYearlyAndMonthlyValues(contentKey, newMapping, isMonthlyValue)
    setExcelToTableMapping(newMapping)
  }

  const handleMappingChange = (newMappingSource, conDef) => {
    const newMapping = new Map(excelToTableMapping)
    if (newMappingSource === tExcelUpload('unknown')) {
      newMapping.set(conDef.contentKey, {
        excelCol: '',
        excelColContent: '',
      })
    } else {
      const matchingEntry = Object.entries(
        getHeaderFromInputExcelWithDuplicates(inputExcelData[rowNumberHeader]),
      ).filter((entry) => entry[1] === newMappingSource)
      newMapping.set(conDef.contentKey, {
        excelCol: matchingEntry[0][0],
        excelColContent: matchingEntry[0][1],
      })
    }
    handleMonthlyMappings(newMapping)
    setExcelToTableMapping(newMapping)
    const { mapRowToMap } = getPropertyRentRollWorkingVersionExcelColumnMapping(
      newMapping,
      allOptions,
      localePattern,
    )
    const rowNumberFirstContentRow = findFirstContentRow(rowNumberHeader, inputExcelData)
    setMappedExcelData(new Map(mapRowToMap(inputExcelData[rowNumberFirstContentRow])))
  }

  const createOptions = (conDef) => {
    const excelMappedCol = excelToTableMapping.has(conDef.contentKey)
      ? excelToTableMapping.get(conDef.contentKey)['excelColContent']
      : null

    const options = excelColTitles()
      .filter((title) => (title ? title !== '__rowNum__' : false))
      .reduce((accumulator, currentValue) => {
        //if a row containing more then one identical field is chosen, only the first instance will be shown as an option
        if (accumulator.indexOf(currentValue) < 0) accumulator.push(currentValue)
        return accumulator
      }, [])
      .map((excelColTitle) => (
        <Option
          key={excelColTitle}
          data-id={excelColTitle}
          data-testid={excelColTitle}
          selected={excelColTitle ? excelColTitle === excelMappedCol : false}
        >
          {excelColTitle}
        </Option>
      ))
    return options
  }
  const getMonthlyCheckedStatus = (contentKey) =>
    excelToTableMapping.get(MONTHLY_CONTENT_KEY_MAP.get(contentKey))?.excelColContent !== ''

  const renderMonthlyCheckbox = (contentKey) =>
    Array.from(MONTHLY_CONTENT_KEY_MAP.keys()).includes(contentKey) ? (
      <div className={styles['monthly-values-checkbox-wrapper']}>
        <CheckBox
          id={`${contentKey}_checkbox`}
          className={styles['monthly-values-checkbox']}
          checked={getMonthlyCheckedStatus(contentKey)}
          onChange={(event) => handleMonthlyCheckboxValueChange(event.target.checked, contentKey)}
          text={tExcelUpload('column-mapping.monthly-value-label')}
        />
      </div>
    ) : (
      <></>
    )

  const parseNumber = useFormattedNumberParser()
  const formatNumberWithoutDecimals = useNumberFormatter({
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  const formatNumberWithDecimals = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const getFormattedExcelData = (conDef) => {
    if (!mappedExcelData.has(conDef.contentKey)) return ''
    const data = mappedExcelData.get(conDef.contentKey)
    if (conDef.dataType === 'Date') return formatDate(getIsoDate(data, localePattern)) || ''
    if (typeof data === 'number') {
      if (conDef.contentKey === 'number_of_units') {
        return formatNumberWithoutDecimals(parseNumber(data))
      } else return formatNumberWithDecimals(parseNumber(data))
    }
    return data
  }

  const mapTableRows = () =>
    contentDefinitions
      .filter(
        (conDef) =>
          conDef.title !== '__rowNum__' &&
          !workingVersionContentKeysWithoutExcelMapping.includes(conDef.contentKey),
      )
      .map((conDef) => (
        <TableRow
          id={`rr-excel-upload-dialog-mapping-field-${conDef.contentKey}`}
          key={conDef.contentKey}
        >
          <TableCell>{conDef.title}</TableCell>
          <TableCell className={styles['user-input-table-cell']}>
            <Select
              id={conDef.contentKey}
              className={styles['mapping-select']}
              onChange={(event) =>
                handleMappingChange(event.detail.selectedOption.dataset.id, conDef)
              }
            >
              {createOptions(conDef)}
            </Select>
            {renderMonthlyCheckbox(conDef.contentKey)}
          </TableCell>
          <TableCell>{getFormattedExcelData(conDef)}</TableCell>
        </TableRow>
      ))

  return (
    <>
      <Dialog
        draggable
        resizable
        className={styles['column-mapping-edit-dialog']}
        open={isAdjustMappingDialogOpen}
        onAfterClose={closeAdjustMappingDialog}
        header={
          <Bar>
            <Title>{tExcelUpload('mapping-adjust-dialog.title')}</Title>
          </Bar>
        }
        footer={
          <Bar
            endContent={
              <>
                <Button
                  id={'adjustMappingAcceptButton'}
                  design="Emphasized"
                  onClick={() => {
                    closeAdjustMappingDialog()
                    handleAcceptMappingAndContinue()
                  }}
                >
                  {tExcelUpload('accept-button')}
                </Button>
                <Button id={'adjustMappingCancelButton'} onClick={closeAdjustMappingDialog}>
                  {tExcelUpload('abort-button')}
                </Button>
              </>
            }
          />
        }
      >
        <FlexBox
          justifyContent={FlexBoxJustifyContent.End}
          className={styles['choose-header-row-container']}
        >
          <Button onClick={openChooseHeaderRowDialog}>
            {tExcelUpload('choose-header-row.button')}
          </Button>
        </FlexBox>
        <FlexBox>
          <Table
            columns={
              <>
                <TableColumn>{'Target Table Column'}</TableColumn>
                <TableColumn>{'Excel Column Name'}</TableColumn>
                <TableColumn>{'Example Value'}</TableColumn>
              </>
            }
            className={styles['column-mapping-table']}
          >
            {mapTableRows()}
          </Table>
        </FlexBox>
      </Dialog>
    </>
  )
}
RentRollAdjustExcelColumnMappingDialog.propTypes = {
  handleAcceptMappingAndContinue: PropTypes.func.isRequired,
  isMultiProperty: PropTypes.bool.isRequired,
}
export default RentRollAdjustExcelColumnMappingDialog
