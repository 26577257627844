export const DEFAULT_HEIGHT_PER_ROW = 155
export const DEFAULT_MAX_VALUATION_ROWS_SHOWN = 3
export const INDIVIDUAL_VALUATION_ROW_HEIGHT = 48

export const PROPERTY_PORTFOLIO_MARKET_VALUE_DISPLAY_VALUATION_TYPES = [
  'marketValue',
  'preliminaryMarketValue',
  'currentStateMarketValue',
  'sellOffMarketValue',
]
export const PROPERTY_PORTFOLIO_MORTGAGE_LENDING_VALUE_DISPLAY_VALUATION_TYPES = [
  'mortgageLendingValue',
  'preliminaryMortgageLendingValue',
  'currentStateMortgageLendingValue',
  'sellOffMortgageLendingValue',
]
