import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { propertyMonitoringCriticalMovementDirection } from 'api/property/monitoring/propertyMonitoringConfiguration'
import { calculateThreshold } from 'components/domains/properties/monitoring/monitoringProxyCalculations'
import useFormatPercentageOrValueWithUnit from 'components/domains/properties/monitoring/useFormatPercentageOrValueWithUnit'
import ClickableKpiChart from 'components/ui/charts/kpi-chart/ClickableKpiChart'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'

const PropertyMonitoringMicroKpiChartCell = ({
  kpiName,
  threshold,
  thresholdType,
  criticalMovementDirection,
  valueAtFixing,
  baselineDate,
  propertyKpiLoading: isLoading,
  propertyKpiError: isError,
  propertyKpiData,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.property-monitoring-proxies.table.kpi-chart',
  })
  const formatThreshold = useFormatPercentageOrValueWithUnit({
    numberFormatterProps: {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      trailingZeroDisplay: 'stripIfInteger',
    },
  })

  const renderCell = () => {
    const kpiData = propertyKpiData.kpis[kpiName]

    if (!kpiData) {
      return t('error')
    }

    const sortedKpiData = [...kpiData.values].reverse()
    const calculatedThreshold = calculateThreshold({
      threshold,
      thresholdType,
      criticalMovementDirection,
      baselineDate,
      valueAtFixing,
    })

    return (
      <ClickableKpiChart
        kpiName={kpiName}
        kpiData={sortedKpiData}
        kpiUnit={kpiData.unit}
        threshold={calculatedThreshold}
        thresholdLabel={formatThreshold({
          value: calculatedThreshold,
          type: thresholdType,
          unit: kpiData.unit,
        })}
        baselineDate={baselineDate}
      />
    )
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderCell}
      error={t('error')}
    />
  )
}

PropertyMonitoringMicroKpiChartCell.propTypes = {
  kpiName: PropTypes.string.isRequired,
  propertyUuid: PropTypes.string.isRequired,
  threshold: PropTypes.number.isRequired,
  thresholdType: PropTypes.string.isRequired,
  criticalMovementDirection: PropTypes.oneOf(
    Object.values(propertyMonitoringCriticalMovementDirection),
  ).isRequired,
  valueAtFixing: PropTypes.number,
  baselineDate: PropTypes.string,
  propertyKpiLoading: PropTypes.bool.isRequired,
  propertyKpiError: PropTypes.bool.isRequired,
  propertyKpiData: PropTypes.object,
}

export default PropertyMonitoringMicroKpiChartCell
