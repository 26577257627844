import { registerCards as registerCwpCustomCards } from '@fioneer/cl-cwp-pbb-frontend-custom'
import { useEffect } from 'react'
import {
  PropertyKpiOverviewCard,
  PropertyKpiOverviewChartCard,
  PropertyKpiAdjustmentCards,
} from 'components/domains/kpis/PropertyWrappedKpiCards'
import PropertyUnderReviewMessageStrip from 'components/domains/properties/common/PropertyUnderReviewMessageStrip'
import PropertyDocuments from 'components/domains/properties/documents/PropertyDocuments'
import PropertyAddressCard from 'components/domains/properties/general-information/address/PropertyAddressCard'
import AssessmentWrapperCard from 'components/domains/properties/general-information/assessment-wrapper/AssessmentWrapperCard'
import PropertyBusinessPartnerCard from 'components/domains/properties/general-information/businessPartners/PropertyBusinessPartnerCard'
import ConstructionDatesCard from 'components/domains/properties/general-information/construction/ConstructionDatesCard'
import PropertyCostsCard from 'components/domains/properties/general-information/costs/PropertyCostsCard'
import PropertyCustomFieldCard from 'components/domains/properties/general-information/customfield/PropertyCustomFieldCard'
import PropertyExternalIdCard from 'components/domains/properties/general-information/external-ids/PropertyExternalIdCard'
import PropertyImageCard from 'components/domains/properties/general-information/image/PropertyImageCard'
import PropertyInfoCard from 'components/domains/properties/general-information/info/PropertyInfoCard'
import PropertyKeyDataCard from 'components/domains/properties/general-information/keyData/PropertyKeyDataCard'
import PropertyRatingCard from 'components/domains/properties/general-information/rating/PropertyRatingCard'
import PropertyStatusCard from 'components/domains/properties/general-information/status/PropertyStatusCard'
import PropertyValuationCard from 'components/domains/properties/general-information/valuation/PropertyValuationCard'
import CollateralAgreementWrapperTile from 'components/domains/properties/insurances/CollateralAgreementWrapperTile'
import PropertyInsurancesTable from 'components/domains/properties/insurances/PropertyInsurancesTable'
import PropertyMonitoringInfoCard from 'components/domains/properties/monitoring/PropertyMonitoringInfoCard'
import PropertyMonitoringMessage from 'components/domains/properties/monitoring/PropertyMonitoringMessage'
import PropertyMonitoringProxiesMacro from 'components/domains/properties/monitoring/PropertyMonitoringProxiesMacro'
import PropertyMonitoringProxiesMicro from 'components/domains/properties/monitoring/PropertyMonitoringProxiesMicro'
import PropertyMonitoringProxyMessage from 'components/domains/properties/monitoring/PropertyMonitoringProxyMessage'
import MarketValueConfirmationCard from 'components/domains/properties/monitoring/market-valuation/MarketValueConfirmationCard'
import PropertyEventsSummaryCard from 'components/domains/properties/overview/PropertyEventsSummaryCard'
import PropertyInsurancesCard from 'components/domains/properties/overview/PropertyInsurancesCard'
import PropertyMarketInfoCardWrapper from 'components/domains/properties/overview/PropertyMarketInfoCardWrapper'
import PropertyMonitoringSummaryCard from 'components/domains/properties/overview/PropertyMonitoringSummaryCard'
import PropertyRentRollInfoCard from 'components/domains/properties/overview/PropertyRentRollInfoCard'
import PropertyValuationSummaryCard from 'components/domains/properties/overview/PropertyValuationSummaryCard'
import AssociatedDealCard from 'components/domains/properties/overview/associated-deals/AssociatedDealCard'
import PropertyGeneralInformationCard from 'components/domains/properties/overview/general-information-card/PropertyGeneralInformationCard'
import PropertyKeyInternalContactsCard from 'components/domains/properties/overview/keyInternalContacts/PropertyKeyInternalContactsCard'
import PropertyRentRollComparison from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparison'
import PropertyRentRollComparisonCard from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonCard'
import PropertyRentRollComparisonCurrentRentCard from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonCurrentRentCard'
import PropertyRentRollComparisonCurrentRentPerUomCard from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonCurrentRentPerUomCard'
import PropertyRentRollComparisonMessageBox from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonMessageBox'
import PropertyRentRollComparisonVacancyCard from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonVacancyCard'
import PropertyRentRollComparisonWaultCard from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonWaultCard'
import PropertyRentRollDetails from 'components/domains/properties/rent-roll/details/PropertyRentRollDetails'
import PropertyRentRollComparisonCardLoadingWrapper from 'components/domains/properties/rent-roll/dynamic/PropertyRentRollComparisonCardWrapper'
import PropertyRentRollOverviewCurrentRentCard from 'components/domains/properties/rent-roll/overview/current-rent/PropertyRentRollOverviewCurrentRentCard'
import PropertyRentRollOverviewExpiringLeasesCard from 'components/domains/properties/rent-roll/overview/expiring-leases/PropertyRentRollOverviewExpiringLeasesCard'
import PropertyRentRollOverviewOccupancyCard from 'components/domains/properties/rent-roll/overview/occupancy/PropertyRentRollOverviewOccupancyCard'
import PropertyRentRollOverviewRentalIncomeOverTimeCard from 'components/domains/properties/rent-roll/overview/rental-income-over-time/PropertyRentRollOverviewRentalIncomeOverTimeCard'
import PropertyRentRollOverviewAreaDetailsCard from 'components/domains/properties/rent-roll/overview/structure-overview/area-details/PropertyRentRollOverviewAreaDetailsCard'
import PropertyRentRollOverviewRentalIncomeCard from 'components/domains/properties/rent-roll/overview/structure-overview/rental-income/PropertyRentRollOverviewRentalIncomeCard'
import PropertyRentRollOverviewVacancyOverTimeCard from 'components/domains/properties/rent-roll/overview/vacancy-over-time/PropertyRentRollOverviewVacancyOverTimeCard'
import PropertyRentRollOverviewWaultCard from 'components/domains/properties/rent-roll/overview/wault/PropertyRentRollOverviewWaultCard'
import PropertyRentRollSegments from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegments'
import PropertyValuationsHistory from 'components/domains/properties/valuation/PropertyValuationsHistory'
import PropertyValuationsRequests from 'components/domains/properties/valuation/PropertyValuationsRequests'
import PropertyValuationsResults from 'components/domains/properties/valuation/PropertyValuationsResults'
import { useGetComponentRegistry } from 'components/ui/configurable-components-page/useComponentRegistry'
import i18next from 'i18n/setupI18n'
import {
  useManagerEventsSummaryDisplayHook,
  useOverviewMarketInfoDisplayHook,
  useOverviewValuationSummaryDisplayHook,
  useGeneralInformationPropertyValuationDisplayHook,
  useCollateralAgreementDisplayHook,
  usePropertyMonitoringProxyMessageDisplayHook,
} from 'routes/properties/useDisplayHooks'

/**
 * @enum
 */
export const PropertyCardCodesEnum = {
  OverviewGeneralInformation: 'OVERVIEW_GENERAL_INFORMATION',
  OverviewRentRollInformation: 'OVERVIEW_RENT_ROLL_INFORMATION',
  OverviewMarketInfoCardWrapper: 'OVERVIEW_MARKET_INFORMATION',
  OverviewAssociatedDeal: 'OVERVIEW_ASSOCIATED_DEAL',
  OverviewValuationSummary: 'OVERVIEW_VALUATION_SUMMARY',
  OverviewInsurances: 'OVERVIEW_INSURANCES',
  OverviewMonitoringSummary: 'OVERVIEW_MONITORING_SUMMARY',
  ManagerEventsSummary: 'MANAGER_EVENTS_SUMMARY',
  ManagerKeyInternalContacts: 'MANAGER_KEY_INTERNAL_CONTACTS',
  PropertyUnderReviewMessageStrip: 'PROPERTY_UNDER_REVIEW_MESSAGE_STRIP',
  RentalIncomeOverTime: 'RENTAL_INCOME_OVER_TIME',
  VacancyOverTime: 'VACANCY_OVER_TIME',
  CurrentRent: 'CURRENT_RENT',
  ExpiringLeases: 'EXPIRING_LEASES',
  Wault: 'WAULT',
  DescriptiveDetailsPerSegment: 'DESCRIPTIVE_DETAILS_PER_SEGMENT',
  RentalIncomeDetailsPerSegment: 'RENTAL_INCOME_DETAILS_PER_SEGMENT',
  RentRollSegments: 'RENT_ROLL_SEGMENTS',
  RentRollDetails: 'RENT_ROLL_DETAILS',
  RentRollAnalysis: 'RENT_ROLL_ANALYSIS',
  RentRollAnalysisLoadingWrapper: 'RENT_ROLL_ANALYSIS_LOADING_WRAPPER',
  RentRollAnalysisMessageStrip: 'RENT_ROLL_ANALYSIS_MESSAGE_STRIP',
  RentRollAnalysisCurrentRent: 'RENT_ROLL_ANALYSIS_CURRENT_RENT',
  RentRollAnalysisCurrentRentPerUom: 'RENT_ROLL_ANALYSIS_CURRENT_RENT_PER_UOM',
  RentRollAnalysisVacancy: 'RENT_ROLL_ANALYSIS_VACANCY',
  RentRollAnalysisWault: 'RENT_ROLL_ANALYSIS_WAULT',
  RentRollAnalysisComparison: 'RENT_ROLL_ANALYSIS_COMPARISON',
  GeneralInformationPropertyInformation: 'PROPERTY_INFORMATION',
  RentRollPropertyRentRollOverviewOccupancy: 'PROPERTY_RENT_ROLL_OVERVIEW_OCCUPANCY',
  GeneralInformationPropertyCustomFields: 'PROPERTY_CUSTOM_FIELDS',
  GeneralInformationPropertyAddress: 'PROPERTY_ADDRESS',
  GeneralInformationPropertyBusinessPartner: 'PROPERTY_BUSINESS_PARTNER',
  GeneralInformationConstructionDates: 'CONSTRUCTION_DATES',
  GeneralInformationPropertyExternalId: 'PROPERTY_EXTERNAL_ID',
  GeneralInformationPropertyImage: 'PROPERTY_IMAGE',
  GeneralInformationPropertyStatus: 'PROPERTY_STATUS',
  GeneralInformationPropertyCosts: 'PROPERTY_COSTS',
  GeneralInformationPropertyKeyData: 'PROPERTY_KEY_DATA',
  GeneralInformationPropertyValuation: 'PROPERTY_VALUATION',
  GeneralInformationPropertyRating: 'PROPERTY_RATING',
  GeneralInformationPropertyComment: 'PROPERTY_COMMENT',
  GeneralInformationPropertyUnderReviewMessageStrip: 'PROPERTY_UNDER_REVIEW_MESSAGE_STRIP',
  ValuationsHistory: 'PROPERTY_VALUATIONS_HISTORY',
  ValuationsResults: 'PROPERTY_VALUATIONS_RESULTS',
  ValuationsRequests: 'PROPERTY_VALUATIONS_REQUESTS',
  InsurancesCollateralsInsurances: 'PROPERTY_INSURANCES',
  InsurancesCollateralsAgreement: 'PROPERTY_COLLATERAL_AGREEMENT',
  InsurancesCollateralsCustomConfirmationInsurance: 'CUSTOM_CONFIRMATION_INSURANCE',
  KeyPerformanceIndicatorsOverview: 'KPI_OVERVIEW',
  KeyPerformanceIndicatorsOverviewChart: 'KPI_OVERVIEW_CHART',
  KeyPerformanceIndicatorsAdjustment: 'KPI_ADJUSTMENT',
  MonitoringMessage: 'PROPERTY_MONITORING_MESSAGE',
  MonitoringProxyMessage: 'PROPERTY_MONITORING_PROXY_MESSAGE',
  MonitoringGeneralInformation: 'PROPERTY_GENERAL_MONITORING_INFORMATION',
  MonitoringMarketValueConfirmations: 'PROPERTY_MARKET_VALUE_CONFIRMATIONS',
  MonitoringMicroeconomicProxies: 'PROPERTY_MICROECONOMIC_PROXIES',
  MonitoringMacroeconomicProxies: 'PROPERTY_MACROECONOMIC_PROXIES',
  Documents: 'PROPERTY_DOCUMENTS',
}

export const usePropertyDynamicCards = () => {
  const registry = useGetComponentRegistry()

  useEffect(() => {
    // register custom cards package
    registerCwpCustomCards(registry, i18next)

    // Overview pages
    registry.register(PropertyCardCodesEnum.OverviewGeneralInformation, {
      Component: PropertyGeneralInformationCard,
    })
    registry.register(PropertyCardCodesEnum.OverviewRentRollInformation, {
      Component: PropertyRentRollInfoCard,
    })
    registry.register(PropertyCardCodesEnum.OverviewAssociatedDeal, {
      Component: AssociatedDealCard,
    })
    registry.register(PropertyCardCodesEnum.OverviewMarketInfoCardWrapper, {
      Component: PropertyMarketInfoCardWrapper,
      componentDisplayHook: useOverviewMarketInfoDisplayHook,
    })
    registry.register(PropertyCardCodesEnum.OverviewValuationSummary, {
      Component: PropertyValuationSummaryCard,
      componentDisplayHook: useOverviewValuationSummaryDisplayHook,
    })
    registry.register(PropertyCardCodesEnum.OverviewInsurances, {
      Component: PropertyInsurancesCard,
    })
    registry.register(PropertyCardCodesEnum.OverviewMonitoringSummary, {
      Component: PropertyMonitoringSummaryCard,
    })
    registry.register(PropertyCardCodesEnum.ManagerEventsSummary, {
      Component: PropertyEventsSummaryCard,
      componentDisplayHook: useManagerEventsSummaryDisplayHook,
    })
    registry.register(PropertyCardCodesEnum.ManagerKeyInternalContacts, {
      Component: PropertyKeyInternalContactsCard,
    })
    registry.register(PropertyCardCodesEnum.PropertyUnderReviewMessageStrip, {
      Component: PropertyUnderReviewMessageStrip,
    })
    // General Information pages
    registry.register(PropertyCardCodesEnum.GeneralInformationPropertyInformation, {
      Component: PropertyInfoCard,
    })
    registry.register(PropertyCardCodesEnum.GeneralInformationPropertyCustomFields, {
      Component: PropertyCustomFieldCard,
    })
    registry.register(PropertyCardCodesEnum.GeneralInformationPropertyAddress, {
      Component: PropertyAddressCard,
    })
    registry.register(PropertyCardCodesEnum.GeneralInformationPropertyBusinessPartner, {
      Component: PropertyBusinessPartnerCard,
    })
    registry.register(PropertyCardCodesEnum.GeneralInformationConstructionDates, {
      Component: ConstructionDatesCard,
    })
    registry.register(PropertyCardCodesEnum.GeneralInformationPropertyExternalId, {
      Component: PropertyExternalIdCard,
    })
    registry.register(PropertyCardCodesEnum.GeneralInformationPropertyImage, {
      Component: PropertyImageCard,
    })
    registry.register(PropertyCardCodesEnum.GeneralInformationPropertyStatus, {
      Component: PropertyStatusCard,
    })
    registry.register(PropertyCardCodesEnum.GeneralInformationPropertyCosts, {
      Component: PropertyCostsCard,
    })
    registry.register(PropertyCardCodesEnum.GeneralInformationPropertyValuation, {
      Component: PropertyValuationCard,
      componentDisplayHook: useGeneralInformationPropertyValuationDisplayHook,
    })
    registry.register(PropertyCardCodesEnum.GeneralInformationPropertyKeyData, {
      Component: PropertyKeyDataCard,
    })
    registry.register(PropertyCardCodesEnum.GeneralInformationPropertyRating, {
      Component: PropertyRatingCard,
    })
    registry.register(PropertyCardCodesEnum.GeneralInformationPropertyComment, {
      Component: AssessmentWrapperCard,
    })
    // Rent Roll pages
    registry.register(PropertyCardCodesEnum.RentalIncomeOverTime, {
      Component: PropertyRentRollOverviewRentalIncomeOverTimeCard,
    })
    registry.register(PropertyCardCodesEnum.VacancyOverTime, {
      Component: PropertyRentRollOverviewVacancyOverTimeCard,
    })
    registry.register(PropertyCardCodesEnum.CurrentRent, {
      Component: PropertyRentRollOverviewCurrentRentCard,
    })
    registry.register(PropertyCardCodesEnum.ExpiringLeases, {
      Component: PropertyRentRollOverviewExpiringLeasesCard,
    })
    registry.register(PropertyCardCodesEnum.Wault, { Component: PropertyRentRollOverviewWaultCard })
    registry.register(PropertyCardCodesEnum.DescriptiveDetailsPerSegment, {
      Component: PropertyRentRollOverviewAreaDetailsCard,
    })
    registry.register(PropertyCardCodesEnum.RentalIncomeDetailsPerSegment, {
      Component: PropertyRentRollOverviewRentalIncomeCard,
    })
    registry.register(PropertyCardCodesEnum.RentRollSegments, {
      Component: PropertyRentRollSegments,
    })
    registry.register(PropertyCardCodesEnum.RentRollDetails, {
      Component: PropertyRentRollDetails,
    })
    registry.register(PropertyCardCodesEnum.RentRollAnalysis, {
      Component: PropertyRentRollComparison,
    })
    registry.register(PropertyCardCodesEnum.RentRollAnalysisLoadingWrapper, {
      Component: PropertyRentRollComparisonCardLoadingWrapper,
    })
    registry.register(PropertyCardCodesEnum.RentRollAnalysisMessageStrip, {
      Component: PropertyRentRollComparisonMessageBox,
    })
    registry.register(PropertyCardCodesEnum.RentRollAnalysisCurrentRent, {
      Component: PropertyRentRollComparisonCurrentRentCard,
    })
    registry.register(PropertyCardCodesEnum.RentRollAnalysisCurrentRentPerUom, {
      Component: PropertyRentRollComparisonCurrentRentPerUomCard,
    })
    registry.register(PropertyCardCodesEnum.RentRollAnalysisVacancy, {
      Component: PropertyRentRollComparisonVacancyCard,
    })
    registry.register(PropertyCardCodesEnum.RentRollAnalysisWault, {
      Component: PropertyRentRollComparisonWaultCard,
    })
    registry.register(PropertyCardCodesEnum.RentRollAnalysisComparison, {
      Component: PropertyRentRollComparisonCard,
    })
    registry.register(PropertyCardCodesEnum.RentRollPropertyRentRollOverviewOccupancy, {
      Component: PropertyRentRollOverviewOccupancyCard,
    })
    // Valuations pages
    registry.register(PropertyCardCodesEnum.ValuationsHistory, {
      Component: PropertyValuationsHistory,
    })
    registry.register(PropertyCardCodesEnum.ValuationsResults, {
      Component: PropertyValuationsResults,
    })
    registry.register(PropertyCardCodesEnum.ValuationsRequests, {
      Component: PropertyValuationsRequests,
    })
    // Insurances and Collaterals pages
    registry.register(PropertyCardCodesEnum.InsurancesCollateralsInsurances, {
      Component: PropertyInsurancesTable,
    })
    registry.register(PropertyCardCodesEnum.InsurancesCollateralsAgreement, {
      Component: CollateralAgreementWrapperTile,
      componentDisplayHook: useCollateralAgreementDisplayHook,
    })
    // Key Performance Indicators
    registry.register(PropertyCardCodesEnum.KeyPerformanceIndicatorsOverview, {
      Component: PropertyKpiOverviewCard,
    })
    registry.register(PropertyCardCodesEnum.KeyPerformanceIndicatorsOverviewChart, {
      Component: PropertyKpiOverviewChartCard,
    })
    registry.register(PropertyCardCodesEnum.KeyPerformanceIndicatorsAdjustment, {
      Component: PropertyKpiAdjustmentCards,
    })
    // Monitoring
    registry.register(PropertyCardCodesEnum.MonitoringMessage, {
      Component: PropertyMonitoringMessage,
    })
    registry.register(PropertyCardCodesEnum.MonitoringGeneralInformation, {
      Component: PropertyMonitoringInfoCard,
    })
    registry.register(PropertyCardCodesEnum.MonitoringMarketValueConfirmations, {
      Component: MarketValueConfirmationCard,
    })
    registry.register(PropertyCardCodesEnum.MonitoringProxyMessage, {
      Component: PropertyMonitoringProxyMessage,
      componentDisplayHook: usePropertyMonitoringProxyMessageDisplayHook,
    })
    registry.register(PropertyCardCodesEnum.MonitoringMicroeconomicProxies, {
      Component: PropertyMonitoringProxiesMacro,
    })
    registry.register(PropertyCardCodesEnum.MonitoringMacroeconomicProxies, {
      Component: PropertyMonitoringProxiesMicro,
    })
    // Documents
    registry.register(PropertyCardCodesEnum.Documents, {
      Component: PropertyDocuments,
    })
  }, [registry])
}
