import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useAllowedInsuranceTypes = ({ propertyTypeCode }) =>
  useCamelizedResponse(
    useRequest({
      path: `/properties/insurance-types/${propertyTypeCode}`,
      useCache: true,
      keys: ['allowed-property-insurance-type', propertyTypeCode],
      options: {
        retry: 0,
      },
      translated: true,
    }),
  )
