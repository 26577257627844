import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionsMassEditOperations } from 'api/conditions/conditions'
import LoadingDropdown from 'components/ui/dropdown/LoadingDropdown'
import useRequirementsStatus from 'hooks/services/conditions/config/useRequirementsStatus'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const RequirementsMassEditStatusSelect = ({ onChange, disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.dialogs.mass-edit',
  })
  const { t: tOperations } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.dialogs.mass-edit.operations',
  })

  const {
    entityRef: { entityType },
  } = useContext(ConditionsContext)

  const { isLoading, isError, data: statusCodeData } = useRequirementsStatus({ entityType })

  const loader = useCallback(
    () => ({
      isError,
      isLoading,
      data: [
        {
          code: conditionsMassEditOperations.keep,
          displayName: tOperations(conditionsMassEditOperations.keep),
        },
        ...(statusCodeData
          ? statusCodeData.status.map(({ code, name }) => ({ code, displayName: name }))
          : []),
      ],
    }),
    [isError, isLoading, statusCodeData, tOperations],
  )

  const handleChange = useCallback(
    (selection) => {
      if (selection === conditionsMassEditOperations.keep) {
        onChange({ operation: conditionsMassEditOperations.keep })
        return
      }
      if (isNil(statusCodeData)) {
        return
      }
      const { type, code } = statusCodeData.status.find(
        ({ code: statusCode }) => statusCode === selection,
      )
      onChange({ operation: conditionsMassEditOperations.replace, value: { type, code } })
    },
    [onChange, statusCodeData],
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label>{t('labels.status')}</Label>
      <LoadingDropdown
        id={'requirements-mass-edit-status-select'}
        useLoader={loader}
        onChange={handleChange}
        value={conditionsMassEditOperations.keep}
        disabled={disabled}
      />
    </FlexBox>
  )
}

RequirementsMassEditStatusSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default RequirementsMassEditStatusSelect
