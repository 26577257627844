import { ValueState } from '@fioneer/ui5-webcomponents-react'

export const propertyLinkedDealCodes = {
  linkedToTerminatedDeal: '00', //Dead Deal
  notLinkedToDeal: '01', // No Deal
  linkedToPipelineDeal: '02',
  linkedToActiveDeal: '03',
  repaidToDeal: '04',
}

export const objectStatusForPropertyLinkedDealByCode = {
  [propertyLinkedDealCodes.linkedToPipelineDeal]: {
    objectStatus: ValueState.Information,
  },
  [propertyLinkedDealCodes.linkedToActiveDeal]: {
    objectStatus: ValueState.Success,
  },
  default: {
    objectStatus: ValueState.None,
  },
}
