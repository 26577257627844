import { useMutation, useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import { useCallback } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import findDocument from 'hooks/services/documents/findDocument'
import useDocuments from 'hooks/services/documents/useDocuments'
import { patchDocumentReferencesOperations } from 'hooks/services/documents/usePatchDocumentReferencesForDocument'

export const useValuationRequestsPatchReport = ({
  onSuccess: _onSuccess,
  onError,
  valuationRequestId,
}) => {
  const { patch } = useAccessTokenRequest()
  const queryClient = useQueryClient()

  const { data: root } = useDocuments({
    entityId: valuationRequestId,
    entityType: DocumentTypes.ValuationRequest,
  })

  const initialReport = findDocument(root)

  const patchMutation = useMutation(
    async ({ documentId, operation }) => {
      if (isEmpty(valuationRequestId)) return

      await patch({
        path: `/documents/${documentId}/entity-refs`,
        body: {
          entity_refs: [{ id: valuationRequestId, type: DocumentTypes.ValuationRequest, name: '' }],
          op: operation,
        },
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['documents'])
        _onSuccess?.()
      },
      onError,
    },
  )

  return useCallback(
    (valuationReport) => {
      const editValuationReportId = valuationReport?.report?.id

      if (!editValuationReportId) {
        return
      }

      switch (valuationReport.operation) {
        case patchDocumentReferencesOperations.remove:
          patchMutation.mutate({
            documentId: editValuationReportId,
            operation: patchDocumentReferencesOperations.remove,
          })
          break
        case patchDocumentReferencesOperations.add:
          if (initialReport && editValuationReportId !== initialReport?.id) {
            patchMutation.mutate({
              documentId: initialReport?.id,
              operation: patchDocumentReferencesOperations.remove,
            })
          }
          patchMutation.mutate({
            documentId: editValuationReportId,
            operation: patchDocumentReferencesOperations.add,
          })
          break
        default:
          throw new Error(`Unknown patch mutation: "${valuationReport.operation}"`)
      }
    },
    [initialReport, patchMutation],
  )
}
