import PropTypes from 'prop-types'
import defaultCellStyles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/cells/DefaultCellStyles.module.css'

const TextCell = ({ value, isBold = false, hasLeftMargin = false }) => {
  if (!Array.isArray(value)) {
    value = [value]
  }

  return (
    <div
      className={[
        defaultCellStyles.columnFlexBox,
        hasLeftMargin ? defaultCellStyles.leftMargin : '',
      ]
        .join(' ')
        .trim()}
    >
      {value.map((item, index) => (
        <div key={index} className={isBold ? defaultCellStyles.boldFont : ''}>
          {item ? item : <>&nbsp;</>}
        </div>
      ))}
    </div>
  )
}

TextCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
  isBold: PropTypes.bool,
  hasLeftMargin: PropTypes.bool,
}
export default TextCell
