import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'

const KeyInternalContactPopoverItem = ({ staffMemberId, staffMemberFunction }) => {
  const { data: staffMember, isFetching } = useStaffMemberById(staffMemberId)
  const businessPartnerName = useMemo(() => staffMember?.fullName ?? '-', [staffMember?.fullName])

  return (
    <CardShowItem
      label={
        <SmallLoadingWrapper
          isLoading={isFetching}
          isError={false}
          error={<></>}
          renderContent={() => <Label>{businessPartnerName}</Label>}
        />
      }
      value={staffMemberFunction}
    />
  )
}

KeyInternalContactPopoverItem.propTypes = {
  staffMemberId: PropTypes.string.isRequired,
  staffMemberFunction: PropTypes.string,
}

export default KeyInternalContactPopoverItem
