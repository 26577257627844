import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import useDeleteDecisionStageFlow from 'hooks/services/business-events-and-tasks/decision-process/flows/useDeleteDecisionStageFlow'

const DecisionProcessDeleteStageButton = ({ stageId, eventId }) => {
  const { handleDeleteClicked } = useDeleteDecisionStageFlow({ stageId, eventId })

  return <Button design={ButtonDesign.Transparent} icon="delete" onClick={handleDeleteClicked} />
}

DecisionProcessDeleteStageButton.propTypes = {
  eventId: PropTypes.string.isRequired,
  stageId: PropTypes.string.isRequired,
}

export default DecisionProcessDeleteStageButton
