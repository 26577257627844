import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { decisionPaperVersion, isMajorVersion } from 'api/decision-paper/decisionPaperApi'
import DecisionPaperTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTile'
import BackToCurrentVersionButton from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/BackToCurrentVersionButton'
import DecisionPaperManualTileHeader from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/DecisionPaperManualTileHeader'
import VersionsHistoryButtonLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/VersionsHistoryButtonLoadingWrapper'
import { availableManualSubtiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/availableTiles'
import useIsDecisionPaperTileExpanded from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useIsDecisionPaperTileExpanded'
import { collapseTile, expandTile } from 'redux/slices/decision-paper/tilesOverviewSlice'

const DecisionPaperManualTile = ({
  tileId,
  tileCode,
  subType,
  titleText,
  viewConfig: { columnSpan, isDefaultExpanded },
  isPdfView,
  isEditMode,
  onEditClicked,
  onSaveClicked,
  hasChanges,
  isSaveEnabled,
  onCancelClicked,
  children,
  currentVersion,
  isEditableInMinorVersion,
  lastAvailableVersion,
}) => {
  const { isExpanded } = useIsDecisionPaperTileExpanded({ tileId, isPdfView, isDefaultExpanded })
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const dispatch = useDispatch()

  const handleCollapse = useCallback(() => dispatch(collapseTile(tileId)), [dispatch, tileId])
  const handleExpand = useCallback(() => dispatch(expandTile(tileId)), [dispatch, tileId])

  const displayedVersion = tileStatus?.displayedVersion
  const isWorkingVersion = currentVersion === decisionPaperVersion.workingVersion
  const isCurrentTileVersion = isNil(displayedVersion) || displayedVersion === 'current'

  const isEditEnabled = useMemo(() => {
    if (isWorkingVersion) {
      return isMajorVersion(lastAvailableVersion) || isEditableInMinorVersion
    }
    return false
  }, [isEditableInMinorVersion, isWorkingVersion, lastAvailableVersion])

  const backToCurrentVersionButton = useMemo(() => {
    if (!isCurrentTileVersion) {
      return <BackToCurrentVersionButton key="back-to-current-version-button" tileId={tileId} />
    }
    return
  }, [isCurrentTileVersion, tileId])
  const versionsHistoryButton = useMemo(() => {
    if (subType === availableManualSubtiles.richText) {
      return (
        <VersionsHistoryButtonLoadingWrapper
          key="versions-history-button-wrapper"
          tileId={tileId}
        />
      )
    }
    return
  }, [subType, tileId])
  const tileVersionActions = useMemo(
    () => (isWorkingVersion ? [backToCurrentVersionButton, versionsHistoryButton] : []),
    [isWorkingVersion, backToCurrentVersionButton, versionsHistoryButton],
  )

  const header = tileStatus && (
    <DecisionPaperManualTileHeader
      tileId={tileId}
      titleText={titleText}
      tileStatus={tileStatus}
      tileCode={tileCode}
      isPdfView={isPdfView}
      isExpanded={isExpanded}
      isEditMode={isEditMode}
      isEditEnabled={isEditEnabled}
      onSaveClicked={onSaveClicked}
      onCancelClicked={onCancelClicked}
      onEditClicked={onEditClicked}
      hasChanges={hasChanges}
      isSaveEnabled={isSaveEnabled}
      onCollapse={handleCollapse}
      onExpand={handleExpand}
      currentVersion={currentVersion}
      additionalEditActions={tileVersionActions}
    />
  )

  return (
    <DecisionPaperTile columnSpan={columnSpan} header={header} isExpanded={isExpanded}>
      {children}
    </DecisionPaperTile>
  )
}

DecisionPaperManualTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  tileCode: PropTypes.string.isRequired,
  subType: PropTypes.oneOf(Object.values(availableManualSubtiles)),
  titleText: PropTypes.string.isRequired,
  viewConfig: PropTypes.shape({
    columnSpan: PropTypes.number.isRequired,
    isDefaultExpanded: PropTypes.bool.isRequired,
  }).isRequired,
  isPdfView: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  isSaveEnabled: PropTypes.bool,
  onSaveClicked: PropTypes.func.isRequired,
  onEditClicked: PropTypes.func.isRequired,
  onCancelClicked: PropTypes.func.isRequired,
  children: PropTypes.any,
  currentVersion: PropTypes.string.isRequired,
  isEditableInMinorVersion: PropTypes.bool.isRequired,
  lastAvailableVersion: PropTypes.string.isRequired,
}

export default DecisionPaperManualTile
