import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useUserRecentVisitsCreate from 'hooks/services/user-profile/useUserRecentVisitsCreate'
import { getUserRecentVisitsGetKey } from 'hooks/services/user-profile/useUserRecentVisitsGet'

/**
 * RecentVisitWrapper component
 *
 * @param entityId {string} cwp entity id, e.g., dealUuid or propertyUuid
 * @param entityType {'DEAL' | 'MARKET' | 'PROPERTY' | 'BUSINESS_PARTNER'} one of cwpEntityTypes
 * @param children  children elements of the wrapper
 */
const RecentVisitWrapper = ({ entityId, entityType, children }) => {
  const { pathname, hash } = useLocation()
  const queryClient = useQueryClient()
  const createRecentViewed = useUserRecentVisitsCreate({
    onSuccess: () => {
      queryClient.invalidateQueries(getUserRecentVisitsGetKey(entityType))
    },
  })

  const updateRecentlyVisitList = useCallback(() => {
    createRecentViewed.mutate({ entityId, entityType })
  }, [createRecentViewed, entityId, entityType])

  // trigger update recent visit list endpoint only on path/hashname change when entityId is present
  useEffect(() => {
    entityId && updateRecentlyVisitList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, pathname, hash])

  return <React.Fragment>{children}</React.Fragment>
}

RecentVisitWrapper.propTypes = {
  entityId: PropTypes.string,
  entityType: PropTypes.oneOf(['DEAL', 'PROPERTY', 'MARKET', 'BUSINESS_PARTNER']).isRequired,
  children: PropTypes.element,
}

export default RecentVisitWrapper
