import { isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const REQUEST_RETRIES = 3

const useDealSponsorOverview = (businessPartnerId) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/sponsors/${businessPartnerId}`,
      keys: ['deals', 'sponsors', businessPartnerId],
      options: {
        enabled: !!businessPartnerId,
        retry: (failureCount, error) => {
          if (isNotFoundError(error)) {
            return false
          }
          return failureCount < REQUEST_RETRIES
        },
      },
    }),
  )

export default useDealSponsorOverview
