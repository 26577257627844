import { useRequest } from 'hooks/services/baseService'

const useRequirementReminderMethods = (options) =>
  useRequest({
    path: `/conditions/configurations/requirement-reminder-methods`,
    translated: true,
    useCache: true,
    keys: ['conditions', 'requirements', 'reminders', 'methods'],
    options: {
      retry: 1,
      ...options,
    },
  })

export default useRequirementReminderMethods
