export const businessEventsAllowedOperations = {
  readEvent: 'events-read',
  createEvent: 'event-create',
  readTask: 'tasks-read',
  createDecisionPaper: 'decision-paper-create',
  decisionStageRead: 'decision-stage-read',
}

export const businessEventAllowedOperations = {
  readEvent: 'events-read',
  updateEvent: 'event-update',
  automaticallyCreatedUpdateEvent: 'event-update-automatically-created',
  completeEvent: 'event-complete-update',
  cancelEvent: 'event-cancel-update',
  publishEvent: 'event-publish-update',
  readTask: 'tasks-read',
  updateTask: 'task-update',
  createDecisionStage: 'decision-stage-create',
  readDecisionStage: 'decision-stage-read',
  updateDecisionStage: 'decision-stage-update',
  deleteDecisionStage: 'decision-stage-delete',
  updateDecisionStageVote: 'decision-stage-vote-update',
  updateDecisionStageConditions: 'decision-stage-conditions-update',
  updateDecisionStageMinutes: 'decision-stage-minutes-update',
  readDecisionPaper: 'decision-paper-read',
  createDecisionPaper: 'decision-paper-create',
  updateDecisionPaper: 'decision-paper-update',
  decisionPaperTemplateUpdate: 'decision-paper-template-update',
  decisionPaperTemplateDelete: 'decision-paper-template-delete',
  decisionPaperSynchronizationUpdate: 'decision-paper-synchronization-update',
  decisionPaperFreeze: 'decision-paper-freeze-update',
}

export const eventCreationActionTypes = {
  manual: 'MANUAL',
  automated: 'AUTOMATED',
}

export const isDateEditable = (allowedOperations, eventCreationAction) =>
  eventCreationAction === eventCreationActionTypes.automated &&
  !allowedOperations.includes(businessEventAllowedOperations.automaticallyCreatedUpdateEvent)

export const neededOperationsForDecisionPaperRead = [
  businessEventAllowedOperations.readEvent,
  businessEventAllowedOperations.readDecisionPaper,
]

export const neededOperationsForDecisionPaperCreate = [
  businessEventAllowedOperations.readEvent,
  businessEventAllowedOperations.createDecisionPaper,
]

export const neededOperationsForDecisionPaperTemplateUpdate = [
  businessEventAllowedOperations.readEvent,
  businessEventAllowedOperations.decisionPaperTemplateUpdate,
]

export const neededOperationsForDecisionPaperTemplateDelete = [
  businessEventAllowedOperations.readEvent,
  businessEventAllowedOperations.decisionPaperTemplateDelete,
]

export const neededOperationsForDecisionPaperSynchronizationUpdate = [
  businessEventAllowedOperations.readEvent,
  businessEventAllowedOperations.decisionPaperSynchronizationUpdate,
]

export const neededOperationsForDecisionPaperUpdate = [
  businessEventAllowedOperations.readEvent,
  businessEventAllowedOperations.readDecisionPaper,
  businessEventAllowedOperations.updateDecisionPaper,
]
