import { ObjectStatus, ValueState } from '@fioneer/ui5-webcomponents-react'
import { BooleanQuestion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/forbearance-and-utp-questionare/forbearanceAndUtpQuestionareSchema'

export const convertToBoolean = (value) => {
  switch (value) {
    case BooleanQuestion.YES:
      return true
    case BooleanQuestion.NO:
      return false
    default:
      return false
  }
}

export const getResultBanner = ({ rules, redBanner, greenBanner, values }) => {
  const isAtLeasQuestionUnanswered = values.some((v) => v === '-' || v === null)
  if (isAtLeasQuestionUnanswered) return '-'

  const isAtLeasOneIndication = rules.some((r) => r)

  return isAtLeasOneIndication ? (
    <ObjectStatus inverted state={ValueState.Error}>
      {redBanner}
    </ObjectStatus>
  ) : (
    <ObjectStatus inverted state={ValueState.Success}>
      {greenBanner}
    </ObjectStatus>
  )
}
