import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

/**
 * Hook that returns a react-query mutation for the user favorite.
 *
 * A mutation can be performed by calling useUserFavoriteDelete.mutate()
 * @param onSuccess Callback to call on success.
 * @param onError Callback to call on error.
 */
const useUserFavoriteDelete = (queryOptions) => {
  const { delete: deleteUserFavorite } = useAccessTokenRequest()
  return useMutation(
    async (userFavoriteId) =>
      await deleteUserFavorite({
        path: `/user-profile/favorite/${userFavoriteId}`,
      }),
    queryOptions,
  )
}

export default useUserFavoriteDelete
