import { Grid, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/rent-roll/working-version/rental-units-table/PropertyRentalUnitsWorkingVersionTableSubComponent.module.css'

const RentalUnitTableSubComponent = ({ row, isMultiProperty }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.rent-roll-working-version.table',
  })

  const getCellComponentByFieldName = (fieldName) => <>{row.original[fieldName]?.cellComponent}</>
  return (
    <Grid defaultSpan="XL2 L2 M2 S2" hSpacing={0} vSpacing={0} className={styles['subrow-grid']}>
      <Label>{`${t('label.number-of-units')}:`}</Label>
      <>{getCellComponentByFieldName('number_of_units')}</>
      <Label>{`${t('label.anonymous-tenant')}:`}</Label>
      <>{getCellComponentByFieldName('anonymous_tenant')}</>
      <Label>{`${t('label.auxiliary-1')}:`}</Label>
      <>{getCellComponentByFieldName('auxiliary_column1')}</>

      <Label>{`${t('label.no-lease-expiry')}:`}</Label>
      <>{getCellComponentByFieldName('no_lease_expiry')}</>
      <Label>{`${t('label.rent-arrear')}:`}</Label>
      <>{getCellComponentByFieldName('rent_arrears')}</>
      <Label>{`${t('label.auxiliary-2')}:`}</Label>
      <>{getCellComponentByFieldName('auxiliary_column2')}</>

      <Label>{`${t('label.branding-franchise.name')}:`}</Label>
      <>{getCellComponentByFieldName('branding_franchise_partner_name')}</>
      <Label>{`${t('label.branding-franchise.id')}:`}</Label>
      <>{getCellComponentByFieldName('branding_franchise_partner_id')}</>
      <Label>{`${t('label.auxiliary-3')}:`}</Label>
      <>{getCellComponentByFieldName('auxiliary_column3')}</>

      <Label>{`${t('label.rent-dispute')}:`}</Label>
      <>{getCellComponentByFieldName('rent_dispute')}</>
      <Label>{`${t('label.lease-comment')}:`}</Label>
      <>{getCellComponentByFieldName('comment')}</>
      <Label>{`${t('label.auxiliary-4')}:`}</Label>
      <>{getCellComponentByFieldName('auxiliary_column4')}</>

      {isMultiProperty && <Label>{`${t('label.lease-start')}:`}</Label>}
      {isMultiProperty && <>{getCellComponentByFieldName('lease_start_date')}</>}
      {isMultiProperty && <Label>{`${t('label.lease-expiry')}:`}</Label>}
      {isMultiProperty && <>{getCellComponentByFieldName('lease_expiry_date')}</>}
    </Grid>
  )
}

RentalUnitTableSubComponent.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.object.isRequired,
  }),
  isMultiProperty: PropTypes.bool.isRequired,
}

export default RentalUnitTableSubComponent
