import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useProperty = (propertyUuid, shouldBeCamelized = true, options) => {
  const result = useRequest({
    path: `/properties/${propertyUuid}`,
    translated: true,
    useCache: true,
    keys: ['properties', propertyUuid],
    options: options,
  })

  const camelizedResponse = useCamelizedResponse(result)

  return shouldBeCamelized ? camelizedResponse : result
}
