import { saveAs } from 'file-saver'
import { useCallback, useMemo } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useMediaBlobDownload = () => {
  const { get } = useAccessTokenRequest()
  const downloadFunction = useCallback(
    ({ mediaBlobId, fileName }, { onError }) => {
      get({
        path: `/media/${mediaBlobId}`,
      })
        .then(({ data }) => {
          saveAs(data, fileName)
        })
        .catch((error) => {
          onError(error)
        })
    },
    [get],
  )
  return useMemo(
    () => ({
      download: downloadFunction,
    }),
    [downloadFunction],
  )
}

export default useMediaBlobDownload
