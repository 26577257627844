import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import TableWrappingTextCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/generic/TableWrappingTextCell'

const ConditionsTableDealExternalAssigneeSubcomponent = ({ externalAssignee }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.conditions.table.subcomponents.external-assignee',
  })

  return (
    <div>
      <Label>{t('label')}</Label>
      <div>
        <TableWrappingTextCell value={externalAssignee?.name || externalAssignee?.email} />
      </div>
    </div>
  )
}

ConditionsTableDealExternalAssigneeSubcomponent.propTypes = {
  externalAssignee: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string.isRequired,
  }),
}

export default ConditionsTableDealExternalAssigneeSubcomponent
