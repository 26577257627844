import React from 'react'
import { useTranslation } from 'react-i18next'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import Header from 'components/ui/page/Header'
import Page from 'components/ui/page/Page'
import paths from 'routes/paths'

const DealDetailsNoPermissions = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals',
  })

  const breadcrumbs = [
    { text: t('breadcrumbs.deals'), href: `/${paths.deals}` },
    { text: t('breadcrumbs.deal-details') },
  ]

  const headerTitle = <Header breadcrumbs={breadcrumbs} title={t('breadcrumbs.deal-details')} />

  return (
    <Page
      isLoading={false}
      renderHeaderTitle={() => headerTitle}
      isError={false}
      renderContent={() => (
        <CenteredIllustratedMessage
          name="UnableToLoad"
          size="Spot"
          titleText={t('not-allowed.title')}
          subtitleText={t('not-allowed.subtitle')}
        />
      )}
    />
  )
}

export default DealDetailsNoPermissions
