import { Outlet } from 'react-router-dom'
import RiskIndicatorGroupDetailsPage from 'components/domains/business-partners/tile/risk-monitoring/group-details/RiskIndicatorGroupDetailsPage'
import BusinessPartnerAssessments from 'routes/business-partners/BusinessPartnerAssessments'
import BusinessPartnerAuthorityLevelCalculatorPage from 'routes/business-partners/BusinessPartnerAuthorityLevelCalculatorPage'
import BusinessPartnerExposure from 'routes/business-partners/BusinessPartnerExposure'
import BusinessPartnerFinancialProductOverviewPage from 'routes/business-partners/BusinessPartnerFinancialProductOverviewPage'
import BusinessPartnerFiscalInformationPage from 'routes/business-partners/BusinessPartnerFiscalInformationPage'
import BusinessPartnerGeneralInformation from 'routes/business-partners/BusinessPartnerGeneralInformation'
import BusinessPartnerGroupExposures from 'routes/business-partners/BusinessPartnerGroupExposures'
import BusinessPartnerOverview from 'routes/business-partners/BusinessPartnerOverview'
import BusinessPartnerRatingsPage from 'routes/business-partners/BusinessPartnerRatingsPage'
import BusinessPartnerRelationships from 'routes/business-partners/BusinessPartnerRelationships'
import BusinessPartnerRiskMonitoringPage from 'routes/business-partners/BusinessPartnerRiskMonitoringPage'
import BusinessPartnerSponsorPage from 'routes/business-partners/BusinessPartnerSponsorPage'
import BusinessPartnerTenancyPage from 'routes/business-partners/BusinessPartnerTenancyPage'
import BusinessPartnerUnitOverviewPage from 'routes/business-partners/BusinessPartnerUnitOverviewPage'
import BusinessPartnerWrapper from 'routes/business-partners/BusinessPartnerWrapper'
import BusinessPartners from 'routes/business-partners/BusinessPartners'
import BusinessPartnerArrearsPage from 'routes/business-partners/arrears/BusinessPartnerArrearsPage'
import BusinessPartnerConditions from 'routes/business-partners/conditions/BusinessPartnerConditions'
import BusinessPartnerCovenantCheckPage from 'routes/business-partners/covenants/BusinessPartnerCovenantCheckPage'
import BusinessPartnerCovenantsPage from 'routes/business-partners/covenants/BusinessPartnerCovenantsPage'
import BusinessPartnerRelatedDocumentsPage from 'routes/business-partners/documents/BusinessPartnerRelatedDocumentsPage'
import paths from 'routes/business-partners/paths'
import PeriodicalCheckDetailPage from 'routes/business-partners/periodical-checks/PeriodicalCheckDetailPage'
import PeriodicalChecksTabs from 'routes/business-partners/periodical-checks/PeriodicalChecksTabs'
import BusinessPartnerKpiDetailsPage from 'routes/kpis/BusinessPartnerKpiDetailsPage'
import BusinessPartnerKpiOverviewPage from 'routes/kpis/BusinessPartnerKpiOverviewPage'

/* Check BusinessPartnerWrapper for BP role based restrictions on individual L2 links */
const navItems = [
  {
    path: '',
    translationToken: 'pages.business-partner.overview',
  },
  {
    path: 'general-information',
    translationToken: 'pages.business-partner.general-information',
  },
  {
    path: 'relationships',
    translationToken: 'pages.business-partner.relationships',
  },
  {
    path: 'exposure',
    translationToken: 'pages.business-partner.exposure',
  },
  {
    path: 'group-exposures',
    translationToken: 'pages.business-partner.group-exposures',
  },
  {
    path: 'financial-product-overview',
    translationToken: 'pages.business-partner.financial-product-overview',
  },
  {
    path: 'authority-level-calculator',
    translationToken: 'pages.business-partner.authority-level-calculator',
  },
  {
    path: 'ratings',
    translationToken: 'pages.business-partner.ratings',
  },
  {
    path: 'unit-overview',
    translationToken: 'pages.business-partner.unit-overview',
  },
  {
    path: 'sponsor',
    translationToken: 'pages.business-partner.sponsor',
  },
  {
    path: 'fiscal-information',
    translationToken: 'pages.business-partner.fiscal-information',
  },
  {
    path: 'tenancy',
    translationToken: 'pages.business-partner.tenancy',
  },
  {
    path: 'assessments',
    translationToken: 'pages.business-partner.assessments',
  },
  {
    path: 'conditions',
    translationToken: 'pages.conditions.l2-navigation',
  },
  {
    path: 'covenants',
    translationToken: 'pages.business-partner.covenants',
  },
  {
    path: 'periodical-checks',
    translationToken: 'pages.business-partner.periodical-checks',
  },
  {
    path: 'risk-monitoring',
    translationToken: 'pages.business-partner.risk-monitoring',
  },
  {
    path: 'arrears',
    translationToken: 'pages.business-partner.arrears',
  },
  {
    path: 'kpis',
    translationToken: 'pages.business-partner.kpis',
  },
  {
    path: 'documents',
    translationToken: 'pages.business-partner.documents',
  },
]

const BusinessPartnerRoutes = [
  { path: paths.businessPartners, index: true, element: <BusinessPartners /> },
  {
    path: paths.detail,
    element: <BusinessPartnerWrapper navItems={navItems} />,
    children: [
      { path: '', element: <BusinessPartnerOverview /> },
      { path: 'general-information', element: <BusinessPartnerGeneralInformation /> },
      {
        path: 'relationships/*',
        element: <BusinessPartnerRelationships />,
      },
      {
        path: 'exposure',
        element: <BusinessPartnerExposure />,
      },
      {
        path: 'financial-product-overview',
        element: <BusinessPartnerFinancialProductOverviewPage />,
      },
      {
        path: 'authority-level-calculator',
        element: <BusinessPartnerAuthorityLevelCalculatorPage />,
      },
      {
        path: 'ratings',
        element: <BusinessPartnerRatingsPage />,
      },
      {
        path: 'unit-overview',
        element: <BusinessPartnerUnitOverviewPage />,
      },
      {
        path: 'fiscal-information',
        element: <BusinessPartnerFiscalInformationPage />,
      },
      {
        path: 'tenancy',
        element: <BusinessPartnerTenancyPage />,
      },
      {
        path: 'assessments',
        element: <BusinessPartnerAssessments />,
      },
      {
        path: 'conditions',
        element: <BusinessPartnerConditions />,
      },
      {
        path: 'covenants',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <BusinessPartnerCovenantsPage />,
          },
          {
            path: 'covenant-checks/:covenantCheckUuid/*',
            element: <BusinessPartnerCovenantCheckPage />,
          },
        ],
      },
      {
        path: 'periodical-checks',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <PeriodicalChecksTabs />,
          },
          {
            path: ':periodicalCheckUuid',
            element: <PeriodicalCheckDetailPage />,
          },
        ],
      },
      {
        path: 'risk-monitoring',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <BusinessPartnerRiskMonitoringPage />,
          },
          {
            path: ':riskIndicatorGroupDisplayId',
            element: <RiskIndicatorGroupDetailsPage />,
          },
        ],
      },
      {
        path: 'arrears',
        element: <BusinessPartnerArrearsPage />,
      },
      {
        path: 'kpis',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <BusinessPartnerKpiOverviewPage />,
          },
          {
            path: ':kpiId/*',
            element: <BusinessPartnerKpiDetailsPage />,
          },
        ],
      },
      {
        path: 'documents',
        element: <BusinessPartnerRelatedDocumentsPage />,
      },
      {
        path: 'sponsor',
        element: <BusinessPartnerSponsorPage />,
      },
      {
        path: 'group-exposures',
        element: <BusinessPartnerGroupExposures />,
      },
    ],
  },
]

export { BusinessPartnerRoutes, navItems }
export default BusinessPartnerRoutes
