import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useTranchesWithSubEntities from 'hooks/services/deals/financing/useTranchesWithSubEntities'
import useSyndication from 'hooks/services/deals/syndication/useSyndication'
import useDeal from 'hooks/services/deals/useDeal'

const useOpsMemoSyndicationStructure = (
  { entityRef: { entityId: dealUuid } },
  tileId,
  tileCode,
) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
    errors: dealUuidErrors,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    data: dealData,
    isFetching: isDealFetching,
    isError: isDealError,
    error: dealError,
  } = useDeal(dealUuidByTileCode)

  const {
    data: syndicationData,
    isFetching: isSyndicationFetching,
    isError: isSyndicationError,
    error: syndicationError,
  } = useSyndication(dealUuidByTileCode, { enabled: !!dealUuidByTileCode })

  const {
    data: tranchesData,
    isFetching: isTranchesFetching,
    isError: isTranchesError,
    error: tranchesError,
  } = useTranchesWithSubEntities({ dealUuid: dealUuidByTileCode })

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isDealUuidFetching, isDealFetching, isSyndicationFetching, isTranchesFetching],
    errorValues: [isDealUuidError, isDealError, isSyndicationError, isTranchesError],
    errorDetails: [...dealUuidErrors, dealError, syndicationError, tranchesError],
    tileId,
  })

  const syndicationOwnShareBusinessPartnerId = syndicationData?.ownShare.businessPartnerId
  const counterPartiesBusinessPartnerIds = syndicationData?.counterparties.map(
    (counterparty) => counterparty.businessPartnerId,
  )

  const allBusinessPartnerIds = useMemo(() => {
    if (!syndicationOwnShareBusinessPartnerId || !counterPartiesBusinessPartnerIds) return undefined
    const ids = [syndicationOwnShareBusinessPartnerId]
    ids.push(...counterPartiesBusinessPartnerIds)
    return ids
  }, [counterPartiesBusinessPartnerIds, syndicationOwnShareBusinessPartnerId])

  const {
    data: businessPartnerData,
    isFetching: isBusinessPartnerFetching,
    isError: isBusinessPartnerError,
    error: businessPartnerError,
  } = useBusinessPartnerMiniByIds(allBusinessPartnerIds, {
    enabled: !isSomeValueLoading && !isSomeValueError,
  })

  const sourcePathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?dataSource=newBusiness&working-version=true' : ''),
    [isWorkingVersionDataTile],
  )

  return useMemo(() => {
    if (isSomeValueError || isBusinessPartnerError) {
      return {
        isLoading: false,
        isError: true,
        error: error ?? businessPartnerError,
        data: undefined,
      }
    }
    if (isSomeValueLoading || isBusinessPartnerFetching) {
      return { isLoading: true, isError: false, data: undefined }
    }

    const businessPartnerResolvedNames = businessPartnerData.businessPartnerMinis.map(
      (businessPartner) => ({
        id: businessPartner.id,
        name: businessPartner.fullName,
      }),
    )

    return {
      isLoading: false,
      isError: false,
      data: {
        syndicationData,
        tranchesData,
        sourceRender: {
          dealDisplayId: dealData?.dealId,
          sourcePathSuffix,
        },
        businessPartnerResolvedNames,
      },
    }
  }, [
    businessPartnerData,
    businessPartnerError,
    dealData?.dealId,
    error,
    isBusinessPartnerError,
    isBusinessPartnerFetching,
    isSomeValueError,
    isSomeValueLoading,
    sourcePathSuffix,
    syndicationData,
    tranchesData,
  ])
}

export default useOpsMemoSyndicationStructure
