import { registerI18nLoader } from '@ui5/webcomponents-base/dist/asset-registries/i18n.js'
import { setLanguage } from '@ui5/webcomponents-base/dist/config/Language'
import '@ui5/webcomponents/dist/Assets.js'
import i18n, { use } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { i18nextPlugin } from 'translation-check'
import decisionPaperDeTranslations from 'i18n/locales/de/decisionPaper.json'
import deTranslations from 'i18n/locales/de/translations.json'
import ui5DeTranslations from 'i18n/locales/de/ui5-translations.json'
import decisionPaperEnTranslations from 'i18n/locales/en/decisionPaper.json'
import enTranslations from 'i18n/locales/en/translations.json'
import ui5EnTranslations from 'i18n/locales/en/ui5-translations.json'

const SUPPORTED_LANGUAGES = {
  'en-US': {
    translation: enTranslations,
    decisionPaper: decisionPaperEnTranslations,
    code: 'en-US',
    name: 'English (US)',
  },
  'en-GB': {
    translation: enTranslations,
    decisionPaper: decisionPaperEnTranslations,
    code: 'en-GB',
    name: 'English (GB)',
  },
  de: {
    translation: deTranslations,
    decisionPaper: decisionPaperDeTranslations,
    code: 'de-DE',
    name: 'Deutsch',
  },
}

export const getSupportedLanguages = () =>
  Object.values(SUPPORTED_LANGUAGES).map((language) => language.code)

export const getSupportedLanguageResources = () => Object.values(SUPPORTED_LANGUAGES)

// replace ui5 internal translations
registerI18nLoader('@ui5/webcomponents-react', 'de-DE', async () => ui5DeTranslations)
registerI18nLoader('@ui5/webcomponents-react', 'en-US', async () => ui5EnTranslations)
registerI18nLoader('@ui5/webcomponents-react', 'en-GB', async () => ui5EnTranslations)

// HINT: import translation-check plugin during development
if (process.env.NODE_ENV === 'development') use(i18nextPlugin)
use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      debug: false,
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
      resources: SUPPORTED_LANGUAGES,
      react: {
        useSuspense: false,
      },
      fallbackLng: 'en-US',
    },
    () => {
      setLanguage(i18n.language)
    },
  )

export default i18n
