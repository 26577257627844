import isEmpty from 'lodash.isempty'
import orderBy from 'lodash.orderby'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import VersionsHistoryButton from 'components/ui/button/VersionsHistoryButton'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useDecisionPaperSubsectionAssessmentVersions from 'hooks/services/business-events-and-tasks/decision-papers/assessments/useDecisionPaperSubsectionAssessmentVersions'
import {
  addVersions,
  showTileVersion,
  showCurrentTileVersion,
} from 'redux/slices/decision-paper/tilesOverviewSlice'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const AssessmentVersionsHistoryButtonLoadingWrapper = ({ subsectionId }) => {
  const { t: t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.tiles.versions',
  })

  const dispatch = useDispatch()

  const {
    event: { id: eventId },
  } = useContext(BusinessEventsAndTasksContext)

  const tileId = useMemo(() => `${subsectionId}-assessment`, [subsectionId])

  const {
    isLoading,
    isError,
    data: assessmentVersionsHistoryData,
  } = useDecisionPaperSubsectionAssessmentVersions({
    eventId,
    subsectionId,
  })

  useEffect(() => {
    if (assessmentVersionsHistoryData) {
      dispatch(
        addVersions({
          tileId,
          versions: assessmentVersionsHistoryData.availableVersions,
        }),
      )
    }
  }, [assessmentVersionsHistoryData, dispatch, tileId])

  const versions = useMemo(() => {
    if (isLoading || isError || isEmpty(assessmentVersionsHistoryData?.availableVersions)) {
      return []
    }
    const sortedVersions = orderBy(
      assessmentVersionsHistoryData.availableVersions,
      ['lastUpdatedAt'],
      ['desc'],
    )
    return sortedVersions.map(({ version, lastUpdatedAt, lastUpdatedBy }) => {
      const isCurrent = version === sortedVersions[0].version
      return {
        version,
        lastUpdatedBy,
        lastUpdatedAt,
        isCurrent,
        onItemClicked: () =>
          isCurrent
            ? dispatch(showCurrentTileVersion(tileId))
            : dispatch(showTileVersion({ tileId, displayedVersion: version })),
      }
    })
  }, [isError, isLoading, assessmentVersionsHistoryData?.availableVersions, dispatch, tileId])

  const renderContent = useCallback(() => {
    if (versions.length !== 0) {
      return (
        <VersionsHistoryButton versions={versions} buttonId={`versions-history-button-${tileId}`} />
      )
    }
  }, [tileId, versions])

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      error={t('loading.error')}
      renderContent={renderContent}
    />
  )
}

AssessmentVersionsHistoryButtonLoadingWrapper.propTypes = {
  subsectionId: PropTypes.string.isRequired,
}

export default AssessmentVersionsHistoryButtonLoadingWrapper
