import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/rent-roll/details/PropertyRentRollDetailsTile.module.css'
import StaffMemberByUserId from 'components/ui/staff-member/StaffMemberByUserId'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const RentRollDetail = ({ label, value }) => (
  <FlexBox>
    <FlexBox direction={FlexBoxDirection.Column} className={styles.rentRollLabel} fitContainer>
      {label}
    </FlexBox>
    <FlexBox direction={FlexBoxDirection.Column} className={styles.rentRollTextValue} fitContainer>
      {value}
    </FlexBox>
  </FlexBox>
)

RentRollDetail.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const DescriptionRentRollDetail = ({ label, value }) => (
  <>
    <FlexBox className={styles.rentRollLabel}>{label}</FlexBox>
    <FlexBox className={styles.rentRollTextValue}>{value}</FlexBox>
  </>
)

DescriptionRentRollDetail.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
}

const PropertyRentRollDetailsTile = ({ rentRoll }) => {
  const { t } = useTranslation()
  const { format: formatDate } = useShortDateFormatter()
  const { format: formatDateAndTime } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
  })

  const { creation_user_account_id: creator, creation_date_time: creationTime } =
    rentRoll?.system_administrative_data ?? {}

  const getCreator = () =>
    (creator && <StaffMemberByUserId s4UserId={creator} />) ||
    `<${t('pages.property.rent-roll.loading-error')}>`
  const getCreationTime = () =>
    formatDateAndTime(creationTime) ?? `<${t('pages.property.rent-roll.loading-error')}>`
  const getKeyDate = () =>
    formatDate(rentRoll?.key_date) ?? `<${t('pages.property.rent-roll.loading-error')}>`

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.rentRollTile}>
      <FlexBox className={styles.rentRollDetailWrapper}>
        <FlexBox direction={FlexBoxDirection.Column} fitContainer>
          <RentRollDetail label={t('pages.properties.rent-roll.creator')} value={getCreator()} />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column} fitContainer>
          <RentRollDetail label={t('pages.properties.rent-roll.key-date')} value={getKeyDate()} />
        </FlexBox>
      </FlexBox>
      <FlexBox className={styles.rentRollDetailWrapper}>
        <FlexBox direction={FlexBoxDirection.Column} fitContainer>
          <RentRollDetail
            label={t('pages.properties.rent-roll.creation-time')}
            value={getCreationTime()}
          />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column} fitContainer />
      </FlexBox>
      <FlexBox className={styles.rentRollDetailWrapper}>
        <FlexBox
          direction={FlexBoxDirection.Column}
          fitContainer
          className={styles.descriptionWrapper}
        >
          <DescriptionRentRollDetail
            label={t('pages.properties.rent-roll.description')}
            value={rentRoll.description}
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

PropertyRentRollDetailsTile.propTypes = {
  rentRoll: PropTypes.object.isRequired,
}

export default PropertyRentRollDetailsTile
