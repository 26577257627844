import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNumberFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/hooks/i18n/useI18n'

const useSellingAmountShareField = ({ labelCode, name, isShownInDisplay, sellingAmountShare }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.syndications.intention-card',
  })

  const formatShare = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })

  const label = t(labelCode)

  return {
    label,
    name,
    value: formatShare(sellingAmountShare),

    isShownInDisplay: !!isShownInDisplay,
    isShownInEdit: false,
  }
}

useSellingAmountShareField.defaultProps = {
  labelCode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isShownInDisplay: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sellingAmountShare: PropTypes.object,
}

export default useSellingAmountShareField
