import compact from 'lodash.compact'
import get from 'lodash.get'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

/**
 * Try to load the most recent risk indicator values based on the risk indicator's originInformation.
 * Returns the response value reduced to the value indicated by the json path, always returns undefined for a specific
 * risk indicator if something is missing or fails to load, in which case the stale risk indicator value stored in the
 * risk monitoring service will be used.
 *
 * @param {Object[]} riskIndicators - array of risk indicators objects
 * @param {String} riskIndicators[].id - identifier of the risk indicator
 * @param {Object} riskIndicators[].originInformation - information about the risk indicator's origin
 * @param {String} riskIndicators[].originInformation.currentValueLink - API path to the risk indicator value source
 * @param {String} riskIndicators[].originInformation.jsonPath - json path for the object obtained by the value link
 */
const useValuesByOriginInformationRequests = ({ riskIndicators }) => {
  const originInformationRequests =
    compact(
      riskIndicators.map(({ id, originInformation } = {}) => {
        const { currentValueLink, jsonPath } = originInformation ?? {}
        const isCurrentValueRequestEnabled = !!currentValueLink && !!jsonPath

        // some value links might have double slashes due to incorrect formatting, which can be safely removed
        const sanitizedValueLink = currentValueLink?.replace(/\/\//g, '/')
        return isCurrentValueRequestEnabled
          ? {
              id: id,
              path: sanitizedValueLink,
              keys: compact(sanitizedValueLink?.split('/')),
            }
          : undefined
      }),
    ) ?? []

  const responses = useRequestsMemo(
    useRequests({
      requests: originInformationRequests.map(({ path, keys }) => ({
        path,
        keys,
      })),
    }),
  )

  return riskIndicators.map(({ id: riskIndicatorId, originInformation }) => {
    const requestIndex = originInformationRequests.findIndex(({ id }) => id === riskIndicatorId)
    const response = responses?.[requestIndex]

    if (response) {
      const {
        data: responseData,
        isLoading: isResponseLoading,
        isError: isResponseError,
      } = response
      const responseValue = get(responseData, originInformation?.jsonPath)
      return { value: responseValue, isLoading: isResponseLoading, isError: isResponseError }
    }
    return undefined
  })
}

export default useValuesByOriginInformationRequests
