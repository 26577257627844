import useUserFavoritesGet from 'hooks/services/user-profile/useUserFavoritesGet'
import useUserRecentVisitsGet from 'hooks/services/user-profile/useUserRecentVisitsGet'

export const FAVORITE_ITEM_TYPE = 'favorite'
export const RECENT_VISIT_ITEM_TYPE = 'recent-visit'

const mapItemType = (favsOrRecVisits, itemType) =>
  favsOrRecVisits?.map((favsOrRecVisit) => ({ ...favsOrRecVisit, itemType })) ?? []

const combineFavoritesAndRecentVisits = (favorites, recentVisits) => [
  ...mapItemType(favorites, FAVORITE_ITEM_TYPE),
  ...mapItemType(recentVisits, RECENT_VISIT_ITEM_TYPE),
]

/**
 * Hook that user favorites and recent visits combined and filtered by given entityType
 *
 * A mutation can be performed by calling useMutateUserProfile.mutate()
 * @param entityType {('DEAL'|'MARKET'|'BUSINESS_PARTNER'|'PROPERTY')} should be one of cwpEntityTypes
 * @returns {{data, isError, isLoading}}
 */
const useCombinedUserFavoritesAndRecentVisits = ({ entityType }) => {
  const getFavorites = useUserFavoritesGet(entityType)
  const getRecentVisits = useUserRecentVisitsGet(entityType)

  const someAreLoading = getFavorites.isLoading || getRecentVisits.isLoading
  const someAreError = getFavorites.isError || getRecentVisits.isError

  const returnDataWhenExist =
    someAreError || someAreLoading
      ? undefined
      : combineFavoritesAndRecentVisits(
          getFavorites.data.userFavorites,
          getRecentVisits.data.userRecentVisits,
        )

  return {
    isLoading: someAreLoading,
    isError: someAreError,
    data: returnDataWhenExist,
  }
}

export default useCombinedUserFavoritesAndRecentVisits
