import { saveAs } from 'file-saver'
import { useCallback, useMemo } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

const defaultEmptyFilter = {}
const defaultEmptySorting = {}

const useExportCondition = () => {
  const { get } = useAccessTokenRequest()

  const downloadFunction = useCallback(
    (
      { conditionType, entityId, entityType },
      {
        searchFilter,
        type,
        visibilities,
        assignee,
        status,
        statusTypes,
        creationDateFrom,
        creationDateUntil,
        businessObjectRef,
        approvalLevel,
      } = defaultEmptyFilter,
      { sortBy = 'condition_type', orderBy = 'asc' } = defaultEmptySorting,
      { onError, onSuccess },
    ) => {
      const urlSearchParams = new URLSearchParams()

      urlSearchParams.append('entity_type', entityType)
      urlSearchParams.append('entity_id', entityId)
      urlSearchParams.append('sort_by', sortBy)
      urlSearchParams.append('order_by', orderBy)

      if (searchFilter) {
        urlSearchParams.append('search_filter', searchFilter)
      }
      if (type) {
        urlSearchParams.append('type', type)
      }
      if (visibilities && visibilities.length > 0) {
        urlSearchParams.append('visibilities', visibilities.join(','))
      }
      if (assignee) {
        urlSearchParams.append('assignee', assignee)
      }
      if (status && status.length > 0) {
        urlSearchParams.append('status', status.join(','))
      }
      if (statusTypes && statusTypes.length > 0) {
        urlSearchParams.append('status_types', statusTypes.join(','))
      }
      if (creationDateFrom) {
        urlSearchParams.append('creation_date_from', creationDateFrom)
      }
      if (creationDateUntil) {
        urlSearchParams.append('creation_date_until', creationDateUntil)
      }
      if (approvalLevel) {
        urlSearchParams.append('approval_level', approvalLevel)
      }
      if (businessObjectRef) {
        urlSearchParams.append('business_object_ref_entity_id', businessObjectRef.entityId)
        urlSearchParams.append('business_object_ref_entity_type', businessObjectRef.entityType)
      }

      get({
        path: `/conditions/${conditionType}/export?${urlSearchParams.toString()}`,
        headers: { Accept: mimeType },
      })
        .then(({ data, fileName }) => {
          saveAs(data, fileName)
          onSuccess(data, fileName)
        })
        .catch((error) => {
          onError(error)
        })
    },
    [get],
  )
  return useMemo(
    () => ({
      download: downloadFunction,
    }),
    [downloadFunction],
  )
}

export default useExportCondition
