import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MultiplePortfolioFinancedAssetsImagesSection from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/financed-assets-information/MultiplePortfolioFinancedAssetsImagesSection'
import MultiplePropertyInformationSection from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/financed-assets-information/MultiplePropertyInformationSection'
import { ErrorDataUnavailableInContent } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/loading/RequestStateResolver'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const MultiplePortfolioFinancedAssetsInformationTile = ({
  tileId,
  selectedDealIndex,
  isPdfView,
  showConfirmationForLinks,
}) => {
  const dispatch = useDispatch()

  const { data: { multiplePropertyInformation = [], noProperties, sourcePaths } = {} } =
    useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const {
    data: multipleImageData,
    isLoading: isTileImagesLoading,
    isError: isTileImagesError,
  } = useSelector((state) => state.decisionPaper.imagesForTiles.tiles[tileId])

  useEffect(() => {
    dispatch(
      setSourceRender({ tileId, sourceRender: { path: `${sourcePaths?.[selectedDealIndex]}` } }),
    )
  }, [dispatch, selectedDealIndex, sourcePaths, tileId])

  const { propertyInformation, tileImages } = useMemo(
    () => ({
      propertyInformation: multiplePropertyInformation?.[selectedDealIndex],
      tileImages: multipleImageData?.[selectedDealIndex],
    }),
    [multipleImageData, multiplePropertyInformation, selectedDealIndex],
  )

  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const selectedProperty = useMemo(
    () => tileImages?.[selectedImageIndex]?.propertyUuid || propertyInformation?.[0]?.uuid,
    [propertyInformation, selectedImageIndex, tileImages],
  )

  if (noProperties || !propertyInformation) {
    return <NoPropertyAssignedToDealInfo />
  }

  return isPdfView ? (
    propertyInformation.map((property) => (
      <div key={property.uuid}>
        <RequestStateResolver
          isLoading={isTileImagesLoading}
          isError={isTileImagesError}
          center={true}
          renderContent={() => (
            <MultiplePortfolioFinancedAssetsImagesSection
              imageData={tileImages}
              isPdfView={isPdfView}
              setSelectedImageIndex={setSelectedImageIndex}
              propertyUuid={property.uuid}
            />
          )}
        />
        <MultiplePropertyInformationSection
          propertyInformation={propertyInformation}
          showConfirmationForLinks={showConfirmationForLinks}
          selectedProperty={property.uuid}
          isPdfView={isPdfView}
        />
      </div>
    ))
  ) : (
    <>
      <RequestStateResolver
        isLoading={isTileImagesLoading}
        isError={isTileImagesError}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        center={true}
        renderContent={() => (
          <MultiplePortfolioFinancedAssetsImagesSection
            imageData={tileImages}
            isPdfView={isPdfView}
            setSelectedImageIndex={setSelectedImageIndex}
          />
        )}
      />
      <MultiplePropertyInformationSection
        propertyInformation={propertyInformation}
        showConfirmationForLinks={showConfirmationForLinks}
        selectedProperty={selectedProperty}
        isPdfView={isPdfView}
      />
    </>
  )
}
MultiplePortfolioFinancedAssetsInformationTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultiplePortfolioFinancedAssetsInformationTile
