// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hswtbiUNQLHbNeYapM3g {
  color: var(--sapGroup_TitleTextColor);
  font-weight: bold;
  padding-left: 16px;
  margin-top: 8px;
  padding-top: 8px;
  padding-bottom: 16px;
}

.dku66JZSyv0Wb09MK_iR {
  border-top: 1px solid #d9d9d9;
  margin-top: 8px;
}

.tYmcK9y0mzhivMQmjrES {
  color: var(--sapGroup_TitleTextColor);
  padding-left: 16px;
  padding-bottom: 16px;
}

.tRnp27pIeBSp7pkRPwBK {
  padding-bottom: 8px;
}

.OobjFqrokw7qm6dvY6CE {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding-right: 16px;
  text-align: right;
  padding-top: 16px;
}

.E_yM_aXhuJpFqvNm6dV7 {
  margin: var(--sapUiSmallSpace) 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/card/DisplayCardView.module.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,qCAAqC;EACrC,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,qBAAqB;EACrB,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".displayAndEditTitle {\n  color: var(--sapGroup_TitleTextColor);\n  font-weight: bold;\n  padding-left: 16px;\n  margin-top: 8px;\n  padding-top: 8px;\n  padding-bottom: 16px;\n}\n\n.displayAndEditTitleSeparator {\n  border-top: 1px solid #d9d9d9;\n  margin-top: 8px;\n}\n\n.displayAndEditSubtitle {\n  color: var(--sapGroup_TitleTextColor);\n  padding-left: 16px;\n  padding-bottom: 16px;\n}\n\n.displayAndEditSubtitleSeparator {\n  padding-bottom: 8px;\n}\n\n.displayCardTextBox {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-end;\n  padding-right: 16px;\n  text-align: right;\n  padding-top: 16px;\n}\n\n.displayCardLoadingStateMargin {\n  margin: var(--sapUiSmallSpace) 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"displayAndEditTitle": `hswtbiUNQLHbNeYapM3g`,
	"displayAndEditTitleSeparator": `dku66JZSyv0Wb09MK_iR`,
	"displayAndEditSubtitle": `tYmcK9y0mzhivMQmjrES`,
	"displayAndEditSubtitleSeparator": `tRnp27pIeBSp7pkRPwBK`,
	"displayCardTextBox": `OobjFqrokw7qm6dvY6CE`,
	"displayCardLoadingStateMargin": `E_yM_aXhuJpFqvNm6dV7`
};
export default ___CSS_LOADER_EXPORT___;
