import { MessageStrip, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CardHeaderLastEditedText } from 'components/domains/deals/card/CardHeaderLastEditedText'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import styles from 'components/domains/deals/covenant-check-detail/checked-covenants/CheckedCovenantsCard.module.css'
import CheckedCovenantsTable from 'components/domains/deals/covenant-check-detail/checked-covenants/CheckedCovenantsTable'
import StatusCell from 'components/domains/deals/covenants/shared/StatusCell'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInTable } from 'components/ui/errors/ErrorDataUnavailableInTable'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useCovenantCheckById from 'hooks/services/deals/covenants/useCovenantCheckById'
import { editButtonStateEnum } from 'routes/deals/covenant-checks/useCalculateEditButtonState'

const CheckedCovenantsCard = ({ covenantCheckUuid, entityType, editButtonState }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.deals.covenants' })

  const { format: formatDate } = useShortDateFormatter()

  const {
    isFetching,
    isError,
    data: { covenantMonitoringItems = [], status, lastUpdatedBy, lastUpdatedAt, keyDate } = {},
  } = useCovenantCheckById(covenantCheckUuid)

  const showMissingData = covenantMonitoringItems.some(({ breach }) => isNil(breach))

  return (
    <Card
      header={
        <CardHeaderLastEditedText
          title={t('checked-covenants.title')}
          email={lastUpdatedBy}
          timestamp={lastUpdatedAt}
        />
      }
    >
      <RequestStateResolver
        isLoading={isFetching}
        isError={isError}
        errorToDisplay={<ErrorDataUnavailableInTable />}
        center
        renderContent={() => (
          <>
            <CardSection>
              <div className={styles.container}>
                <div className={styles.item}>
                  <CardShowItem
                    id="key-date"
                    label={t('checked-covenants.key-date')}
                    value={formatDate(keyDate)}
                  />
                </div>
                <div className={styles.item}>
                  <CardShowItem
                    id="status"
                    label={t('checked-covenants.status')}
                    value={<StatusCell status={status} />}
                  />
                </div>
              </div>
            </CardSection>
            <CardSection>
              {showMissingData && (
                <MessageStrip design={MessageStripDesign.Warning} hideCloseButton>
                  {t('checked-covenants.missing-data')}
                </MessageStrip>
              )}
              <CheckedCovenantsTable
                monitoringItems={covenantMonitoringItems}
                entityType={entityType}
                covenantCheckUuid={covenantCheckUuid}
                editButtonState={editButtonState}
              />
            </CardSection>
          </>
        )}
      />
    </Card>
  )
}

CheckedCovenantsCard.propTypes = {
  covenantCheckUuid: PropTypes.string.isRequired,
  entityType: PropTypes.oneOf([cwpEntityTypes.DEAL, cwpEntityTypes.BUSINESS_PARTNER]),
  isAllowedToEdit: PropTypes.bool,
  editButtonState: PropTypes.oneOf(Object.values(editButtonStateEnum)),
}

export default CheckedCovenantsCard
