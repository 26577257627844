import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  ResponsiveGridLayout,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import get from 'lodash.get'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import snakecaseKeys from 'snakecase-keys'
import AnnualReviewDealOverviewBorrowerOverviewLabeledValue from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/involved-parties/AnnualReviewDealOverviewBorrowerOverviewLabeledValue'
import {
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  useShortDateFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'
import { TILE_VERSION as tileVersion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/borrower/BorrowerOverviewTile.module.css'

const v1FinancialIndicators = {
  turnover: {
    label: 'Annual Turnover',
    key: 'turnover',
  },
  ebitda: {
    label: 'EBITDA',
    key: 'ebitda',
  },
  netResult: {
    label: 'Net Result',
    key: 'returns',
  },
  totalAssets: {
    label: 'Total Assets',
    key: 'total_assets',
  },
  equityCapital: {
    label: 'Equity Capital',
    key: 'equity_capital',
  },
  equityRatio: {
    label: 'Equity Ratio',
    key: 'equity_ratio',
  },
}

const AnnualReviewDealOverviewBorrowerOverviewFinancialIndicators = ({
  tileId,
  selectedDealIndex,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.bp-borrower-overview.financial-indicators',
  })
  const formatCurrency = useCustomizableCurrencyFormatter()
  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const { format: formatDate } = useShortDateFormatter()
  const { data: tileData, version } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId],
  )
  const { multipleFiscalData, financialIndicators: configuredFinancialIndicatorsFromState } =
    tileData ?? {}

  const fiscalData = useMemo(
    () => multipleFiscalData?.[selectedDealIndex] ?? {},
    [multipleFiscalData, selectedDealIndex],
  )
  const getFinancialIndicatorsForV1AndV2 = useCallback((passedFiscalData, financialIndicators) => {
    if (isNil(passedFiscalData.fiscal_figures)) {
      return undefined
    }
    return {
      keyDate: passedFiscalData.start_date,
      currency: passedFiscalData.currency,
      turnover: {
        label: financialIndicators.turnover.label,
        value: get(passedFiscalData.fiscal_figures, financialIndicators.turnover.key),
      },
      ebitda: {
        label: financialIndicators.ebitda.label,
        value: get(passedFiscalData.fiscal_figures, financialIndicators.ebitda.key),
      },
      netResult: {
        label: financialIndicators.netResult.label,
        value: get(passedFiscalData.fiscal_figures, financialIndicators.netResult.key),
      },
      totalAssets: {
        label: financialIndicators.totalAssets.label,
        value: get(passedFiscalData.fiscal_figures, financialIndicators.totalAssets.key),
      },
      equityCapital: {
        label: financialIndicators.equityCapital.label,
        value: get(passedFiscalData.fiscal_figures, financialIndicators.equityCapital.key),
      },
      equityRatio: {
        label: financialIndicators.equityRatio.label,
        value: get(passedFiscalData.fiscal_figures, financialIndicators.equityRatio.key),
      },
    }
  }, [])

  const financialIndicators = useMemo(() => {
    // Freezing forces camelization onto all object keys, which breaks the correct data access using this `getFinancialIndicatorsForOldVersions` helper function.
    // Since the original data is mostly simply snake case (expect for some irrelevant keys) this solves freezing issues.
    if (version === tileVersion.V1) {
      return getFinancialIndicatorsForV1AndV2(snakecaseKeys(fiscalData), v1FinancialIndicators)
    }
    if (version === tileVersion.V2) {
      return getFinancialIndicatorsForV1AndV2(
        snakecaseKeys(fiscalData),
        configuredFinancialIndicatorsFromState,
      )
    }
    return fiscalData?.financialIndicators
  }, [
    configuredFinancialIndicatorsFromState,
    fiscalData,
    getFinancialIndicatorsForV1AndV2,
    version,
  ])

  const content = useMemo(() => {
    if (isNil(financialIndicators)) {
      return (
        <FlexBox
          className={styles.listCardEmptyContent}
          alignItems={FlexBoxAlignItems.Center}
          justifyContent={FlexBoxJustifyContent.Center}
        >
          {t('empty')}
        </FlexBox>
      )
    }
    return (
      <ResponsiveGridLayout columnsXL={2} columnsL={2} columnsM={2} columnsS={2}>
        <FlexBox direction={FlexBoxDirection.Column}>
          {!isNil(financialIndicators.keyDate) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={t('key-date')}
              value={formatDate(financialIndicators.keyDate)}
            />
          )}
          {!isNil(financialIndicators.turnover?.value) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={financialIndicators.turnover.label}
              value={formatCurrency(
                financialIndicators.turnover.value.value,
                financialIndicators.turnover.value.unit,
              )}
            />
          )}
          {!isNil(financialIndicators.ebitda?.value) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={financialIndicators.ebitda.label}
              value={formatCurrency(
                financialIndicators.ebitda.value.value,
                financialIndicators.ebitda.value.unit,
              )}
            />
          )}
          {!isNil(financialIndicators.netResult?.value) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={financialIndicators.netResult.label}
              value={formatCurrency(
                financialIndicators.netResult.value.value,
                financialIndicators.netResult.value.unit,
              )}
            />
          )}
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column}>
          {!isNil(financialIndicators.totalAssets?.value) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={financialIndicators.totalAssets.label}
              value={formatCurrency(
                financialIndicators.totalAssets.value.value,
                financialIndicators.totalAssets.value.unit,
              )}
            />
          )}
          {!isNil(financialIndicators.equityCapital?.value) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={financialIndicators.equityCapital.label}
              value={formatCurrency(
                financialIndicators.equityCapital.value.value,
                financialIndicators.equityCapital.value.unit,
              )}
            />
          )}
          {!isNil(financialIndicators.equityRatio?.value) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={financialIndicators.equityRatio.label}
              value={`${formatNumber(financialIndicators.equityRatio.value.value)} ${
                financialIndicators.equityRatio.value.unit
              }`}
            />
          )}
        </FlexBox>
      </ResponsiveGridLayout>
    )
  }, [financialIndicators, formatCurrency, formatDate, formatNumber, t])

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.listCard}>
      <Text className={styles.listCardTitle}>{t('title')}</Text>
      {content}
    </FlexBox>
  )
}

AnnualReviewDealOverviewBorrowerOverviewFinancialIndicators.propTypes = {
  tileId: PropTypes.string.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default AnnualReviewDealOverviewBorrowerOverviewFinancialIndicators
