import { Label, Text } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/FinancingTermsTrancheDetailsTableCell.module.css'

const FinancingTermsTrancheDetailsMultiLineEntry = ({ from, type, rate }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.financing-terms.tranche-details',
  })
  return (
    <div className={styles.multiLineCell}>
      {!isNil(from) && (
        <div className={styles.caretCursor}>
          <Label showColon>{t('property.from-label')}</Label>&nbsp;
          <Text>{from}</Text>
        </div>
      )}
      {!isNil(type) && (
        <div className={styles.caretCursor}>
          <Label showColon>{t('property.type-label')}</Label>&nbsp;
          <Text>{type}</Text>
        </div>
      )}
      {!isNil(rate) && (
        <div className={styles.caretCursor}>
          <Label showColon>{t('property.rate-label')}</Label>&nbsp;
          <Text>{rate}</Text>
        </div>
      )}
    </div>
  )
}

FinancingTermsTrancheDetailsMultiLineEntry.propTypes = {
  from: PropTypes.string,
  type: PropTypes.string,
  rate: PropTypes.string,
}

export default FinancingTermsTrancheDetailsMultiLineEntry
