export const availableFilterTypes = {
  betweenDates: 'BETWEEN_DATES',
  assignee: 'ASSIGNEE',
  condition: 'CONDITION',
  externalAssignee: 'EXTERNAL_ASSIGNEE',
  loadingDropdownSingleSelect: 'LOADING_DROPDOWN_SINGLE_SELECT',
  loadingDropdownMultiComboBox: 'LOADING_DROPDOWN_MULTI_COMBO_BOX',
  covenantCheck: 'COVENANT_CHECK',
  referencesMultiSelect: 'REFERENCES_MULTI_SELECT',
}
