import {
  Button,
  ButtonDesign,
  IllustratedMessage,
  IllustrationMessageType,
  TextAlign,
} from '@fioneer/ui5-webcomponents-react'
import cloneDeep from 'lodash.clonedeep'
import groupBy from 'lodash.groupby'
import merge from 'lodash.merge'
import PropTypes from 'prop-types'
import { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/cashflow-scenario/CashFlowScenarioOfCheckTable.module.css'
import PercentageCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/cashflow-scenario/shared/PercentageCell'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/cashflow-scenario/shared/analytical/AnalyticalTableWithToolbar'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/cashflow-scenario/shared/i18n/useI18n'

const FINANCIAL_RATIOS = 'financial ratios'

const CashFlowScenarioCardTable = ({ keyDate, calculatedKpis, groups }) => {
  const { t: tCashFlow } = useTranslation('decisionPaper', {
    keyPrefix:
      'tiles.cashflow-of-check.components.deals.covenants.checked-covenants.cash-flow-scenario',
  })
  const { format: formatDate } = useShortDateFormatter()

  const tableRef = useRef(null)

  const expandOrCollapseSubRows = (isExpanded) => {
    tableRef?.current?.toggleAllRowsExpanded(isExpanded)
  }

  const columnDefinitions = useMemo(() => {
    const defaultDisables = {
      disableDragAndDrop: true,
      disableGlobalFilter: true,
      disableFilters: true,
      disableSortBy: true,
      disableGroupBy: true,
    }
    return [
      {
        id: 'parameter',
        Header: tCashFlow('table.kpi-result'),
        accessor: ({ categoryName, cashflowKpi }) => categoryName ?? cashflowKpi?.name,
        ...defaultDisables,
      },
      {
        id: 'keyDate',
        Header: `${tCashFlow('table.key-date')} (${formatDate(keyDate)})`,
        accessor: ({ rawValues }) => ({
          percentage: rawValues?.find(({ endDate }) => endDate === keyDate)?.value,
        }),
        Cell: PercentageCell,
        hAlign: TextAlign.End,
        ...defaultDisables,
      },
    ]
  }, [keyDate, formatDate, tCashFlow])

  const financialRatioKpis =
    groups.filter((group) => group.name.toLowerCase() === FINANCIAL_RATIOS)?.[0]?.kpis ?? []

  const filterRelevantCalculatedKpis = (kpis) =>
    calculatedKpis.reduce((filteredKpis, calculatedKpi) => {
      const filteredCalculatedKpis = kpis.filter(
        (kpi) => kpi.ckpiId === calculatedKpi.cashflowKpi.ckpiId,
      )
      for (const filteredKpi of filteredCalculatedKpis) {
        const enrichedCalculatedKpi = merge(cloneDeep(calculatedKpi), {
          cashflowKpi: { name: filteredKpi.name },
        })

        filteredKpis.push(enrichedCalculatedKpi)
      }
      return filteredKpis
    }, [])

  const calculatedMeasures = filterRelevantCalculatedKpis(financialRatioKpis)
  const groupedByCategory = groupBy(calculatedMeasures, 'category')

  const structuredData = []
  // to maintain order it is iterated over calculatedMeasures instead of iterating over category
  for (const result of calculatedMeasures) {
    // if no category given, the leaf should be at root level
    if (result.category === null) {
      structuredData.push(result)
    } else {
      // prevent duplicates
      if (!structuredData.find(({ categoryName }) => categoryName === result.category)) {
        structuredData.push({
          categoryName: result.category,
          subRows: groupedByCategory[result.category],
        })
      }
    }
  }

  const additionalTableActions = [
    <Button
      className={styles.collapseIcon}
      design={ButtonDesign.Transparent}
      icon="collapse-all"
      key={'collapse-subrows'}
      onClick={() => expandOrCollapseSubRows(false)}
    />,
    <Button
      className={styles.expandIcon}
      design={ButtonDesign.Transparent}
      icon="expand-all"
      key={'expand-subrows'}
      onClick={() => expandOrCollapseSubRows(true)}
    />,
  ]
  return (
    <AnalyticalTableWithToolbar
      ref={tableRef}
      className={styles.table}
      title={tCashFlow('table.title')}
      additionalActions={additionalTableActions}
      data={structuredData}
      nrOfEntries={calculatedMeasures.length}
      columns={columnDefinitions}
      isTreeTable={true}
      NoDataComponent={() => (
        <IllustratedMessage
          name={IllustrationMessageType.NoEntries}
          titleText={tCashFlow('empty-text')}
          subtitleText={tCashFlow('empty-text.subtitle')}
        />
      )}
    />
  )
}

CashFlowScenarioCardTable.propTypes = {
  keyDate: PropTypes.string,
  calculatedKpis: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
}

export default CashFlowScenarioCardTable
