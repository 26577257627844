import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateDealAdjustmentWorkingVersion = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ dealUuid, adjustment }) => {
      const response = await post({
        path: `/deals/${dealUuid}/working-version`,
        body: snakecaseKeys(adjustment, { deep: true }),
      })
      return { ...response, data: camelize(response?.data) }
    },
    { onSuccess, onError },
  )
}

export default useCreateDealAdjustmentWorkingVersion
