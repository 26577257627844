import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const RETRIES = 3

const useMarketCompatiblePropertyTypes = ({ marketId }) =>
  useCamelizedResponse(
    useRequest({
      path: `/markets/${marketId}/compatible-property-types`,
      useCache: true,
      keys: ['markets', marketId, 'compatible-property-types'],
      options: {
        retry: (failureCount, error) => {
          if (isNotFoundError(error) || isMissingPermissionError(error)) {
            return false
          }
          return failureCount < RETRIES
        },
      },
    }),
  )

export default useMarketCompatiblePropertyTypes
