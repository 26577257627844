import z from 'zod'

const monetaryValue = z.object({
  amount: z.number().nullish(),
  currency: z.string().nullish(),
})

const tradeConditionsSchema = z.object({
  conditionId: z.string().nullish(),
  amortization: z.string().nullish(),
  isin: z.string().nullish(),
  conditionType: z.string().nullish(),
  counterpartyId: z.string().nullish(),
  issuerId: z.string().nullish(),
  startDate: z.string().nullish(),
  maturityDate: z.string().nullish(),
  initialNotional: monetaryValue.nullish(),
  initialNotionalHeadquarter: monetaryValue.nullish(),
  currentNotional: monetaryValue.nullish(),
  currentNotionalHeadquarter: monetaryValue.nullish(),
  endOfFixedInterestPeriod: z.string().nullish(),
  currentRate: z.number().nullish(),
  strikeRate: z.number().nullish(),
  buyOrSell: z.string().nullish(),
  payOrReceive: z.string().nullish(),
  rateIndexName: z.string().nullish(),
  tenor: z.string().nullish(),
  spread: z.number().nullish(),
  rateType: z.string().nullish(),
})

const nonLoanProductsTradeSchema = z.object({
  tradeId: z.string().nullish(),
  recordSource: z.string().nullish(),
  businessDate: z.string().nullish(),
  productType: z.string().nullish(),
  productSubType: z.string().nullish(),
  isin: z.string().nullish(),
  limitSystemProductClass: z.string().nullish(),
  counterpartyId: z.string().nullish(),
  secondaryCounterpartyId: z.string().nullish(),
  issuerId: z.string().nullish(),
  mtm: monetaryValue.nullish(),
  mtmHeadquarter: monetaryValue.nullish(),
  initialNotional: monetaryValue.nullish(),
  initialNotionalHeadquarter: monetaryValue.nullish(),
  currentNotional: monetaryValue.nullish(),
  currentNotionalHeadquarter: monetaryValue.nullish(),
  startDate: z.string().nullish(),
  maturityDate: z.string().nullish(),
  buyOrSell: z.string().nullish(),
  payOrReceive: z.string().nullish(),
  amortization: z.string().nullish(),
  endOfFixedInterestPeriod: z.string().nullish(),
  currentRate: z.number().nullish(),
  strikeRate: z.number().nullish(),
  rateIndexName: z.string().nullish(),
  tenor: z.string().nullish(),
  spread: z.number().nullish(),
  rateType: z.string().nullish(),
  dealId: z.string().nullish(),
  conditions: tradeConditionsSchema.array().nullish(),
})

const nonLoanProductsBorrowerDealSchema = z.object({
  dealId: z.string().nullish(),
  dealDisplayId: z.string().nullish(),
  name: z.string().nullish(),
  trades: nonLoanProductsTradeSchema.array().nullish(),
})

export const nonLoanProductsDealSchema = z.object({
  trades: nonLoanProductsTradeSchema.array().nullish(),
  pagination: z.object({
    offset: z.number(),
    limit: z.number(),
    total: z.number(),
  }),
})

export const nonLoanProductsBorrowerSchema = z.object({
  deals: nonLoanProductsBorrowerDealSchema.array().nullish(),
})
