import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const usePropertyBulkDeleteValuation = (options) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ requestUuids }) =>
      await post({
        path: `/properties/valuation-requests/bulk-delete`,
        body: { request_uuids: requestUuids },
      }),
    options,
  )
}

export default usePropertyBulkDeleteValuation
