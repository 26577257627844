import { Button, FlexBox, FlexBoxAlignItems, Icon } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/watcher/WatcherButton.module.css'
import WatcherMenuPopover from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/watcher/WatcherMenuPopover'

const WatcherButton = ({ id, watchers, userId, onUserSubscribe, onUserUnsubscribe }) => {
  const [isPopoverMenuOpen, setIsPopoverMenuOpen] = useState(false)
  const ownWatchingUser = useMemo(
    () => watchers.find(({ userId: currentUserId }) => currentUserId === userId),
    [userId, watchers],
  )

  const buttonIcon = ownWatchingUser ? 'show' : 'hide'
  const numberOfWatchers = watchers.length

  const onWatcherButtonClick = useCallback(() => {
    setIsPopoverMenuOpen(true)
  }, [])

  const onAfterClose = useCallback(() => {
    setIsPopoverMenuOpen(false)
  }, [])

  return (
    <>
      <Button id={id} onClick={onWatcherButtonClick} icon={buttonIcon}>
        <FlexBox alignItems={FlexBoxAlignItems.Center}>
          <div>{numberOfWatchers}</div>
          <Icon className={styles.arrowDown} name="slim-arrow-down" />
        </FlexBox>
      </Button>
      <WatcherMenuPopover
        isOpen={isPopoverMenuOpen}
        opener={id}
        onAfterClose={onAfterClose}
        ownWatchingUser={ownWatchingUser}
        watchers={watchers}
        onUserSubscribe={onUserSubscribe}
        onUserUnsubscribe={onUserUnsubscribe}
        userId={userId}
      />
    </>
  )
}

WatcherButton.propTypes = {
  id: PropTypes.string.isRequired,
  watchers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
    }),
  ).isRequired,
  userId: PropTypes.string.isRequired,
  onUserSubscribe: PropTypes.func.isRequired,
  onUserUnsubscribe: PropTypes.func.isRequired,
}

export default WatcherButton
