import { useMutation, useQueryClient } from '@tanstack/react-query'
import camelize from 'camelize'
import { useTranslation } from 'react-i18next'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const CWP_BUSINESS_PARTNER_TYPE = 'Organization'

const isValid = (businessPartner) =>
  businessPartner?.creationRole && businessPartner.orgName1 && businessPartner.grouping

const useBusinessPartnerCreate = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  const queryClient = useQueryClient()
  const { i18n } = useTranslation()

  return useMutation(
    async (businessPartner = {}) => {
      if (!isValid(businessPartner)) return
      const { data } = await post({
        path: '/businesspartners',
        body: {
          type: CWP_BUSINESS_PARTNER_TYPE,
          ...snakecaseKeys(businessPartner),
        },
      })
      return camelize(data)
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['businesspartners', i18n.language])
        onSuccess(data)
      },
      onError,
    },
  )
}

export default useBusinessPartnerCreate
