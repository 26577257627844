import { Button, ButtonDesign, MessageBox, MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState, useCallback, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { getObjectErrorForEventRequest } from 'api/events/eventsRequestErrors'
import Dialog from 'components/ui/dialog/Dialog'
import DecisionPaperAttachmentsTable from 'routes/business-events-and-tasks/decision-paper/attachments/DecisionPaperAttachmentsTable'

const DecisionPaperAttachmentsDialog = ({
  isOpen,
  setIsOpen,
  setIsPopoverOpen,
  isEditView,
  decisionPaperVersion,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper.attachments-dialog',
  })
  const { t: tNoPrefix } = useTranslation('translation', {})

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [eventError, setEventError] = useState()

  const [highlightRow, setHighlightRow] = useState([])

  const resetRowHighlights = () => setHighlightRow([])

  const handleOnCloseErrorDialog = useCallback(() => {
    setIsErrorDialogOpen(false)
  }, [setIsErrorDialogOpen])

  const handleDialogClose = useCallback(() => {
    resetRowHighlights()
    setIsOpen(false)
    setIsPopoverOpen?.(false)
  }, [setIsPopoverOpen, setIsOpen])

  const errorDescription = useMemo(
    () => tNoPrefix(getObjectErrorForEventRequest(eventError).translationKey),
    [eventError, tNoPrefix],
  )

  return createPortal(
    <>
      <Dialog
        open={isOpen}
        headerText={t('header')}
        closeButton={
          <Button design={ButtonDesign.Transparent} onClick={handleDialogClose}>
            {tNoPrefix('buttons.close')}
          </Button>
        }
        onBeforeClose={handleDialogClose}
      >
        <DecisionPaperAttachmentsTable
          isEditView={isEditView}
          setIsErrorDialogOpen={setIsErrorDialogOpen}
          setEventError={setEventError}
          decisionPaperVersion={decisionPaperVersion}
          highlightRow={highlightRow}
          setHighlightRow={setHighlightRow}
          resetRowHighlights={resetRowHighlights}
        />
      </Dialog>
      <MessageBox
        draggable
        resizable
        open={isErrorDialogOpen}
        onClose={handleOnCloseErrorDialog}
        type={MessageBoxTypes.Error}
      >
        {errorDescription}
      </MessageBox>
    </>,
    document.body,
  )
}

DecisionPaperAttachmentsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setIsPopoverOpen: PropTypes.func,
  isEditView: PropTypes.bool,
  decisionPaperVersion: PropTypes.string,
}

export default DecisionPaperAttachmentsDialog
