import { Button, ButtonDesign, Modals } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import EditDealAdjustmentDialogContent from 'components/domains/deals/deal-adjustment/edit/EditDealAdjustmentDialogContent'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import LoadingButton from 'components/ui/button/LoadingButton'
import Dialog from 'components/ui/dialog/Dialog'
import DialogSecondaryButton from 'components/ui/dialog/DialogSecondaryButton'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import MessageBox, {
  MessageBoxActions,
  MessageBoxTypes,
} from 'components/ui/message-box/MessageBox'
import { useAdjustments } from 'hooks/services/deals/deal-adjustment/useAdjustments'
import { useUpdateAdjustments } from 'hooks/services/deals/deal-adjustment/useUpdateAdjustments'

const EditDealAdjustmentDialog = forwardRef(
  (
    { dealUuid, i18nPrefix = 'components.deals.deal-adjustment.modal.edit', ...props },
    forwardedRef,
  ) => {
    const dialogRef = useRef()
    useImperativeHandle(forwardedRef, () => dialogRef.current)
    const { t } = useTranslation('translation', {
      keyPrefix: i18nPrefix,
    })
    const { t: tNoPrefix } = useTranslation()
    const form = useForm({
      mode: 'onChange',
      defaultValues: {},
    })
    const {
      reset,
      handleSubmit,
      formState: { isValid },
    } = form

    const {
      isLoading,
      isError,
      refetch: refetchAdjustments,
    } = useAdjustments({
      dealUuid,
      options: {
        onSuccess: (adjustmentListData) => reset(adjustmentListData?.adjustments?.[0]),
      },
    })
    const { mutateAsync: updateAdjustments } = useUpdateAdjustments({ dealUuid })

    const showToast = Modals.useShowToast()
    const showErrorMessageBox = useShowErrorMessageBox()
    const onError = useCallback(
      async (error) => {
        const { errors: [errorResponse] = [] } = (await error.response?.json?.()) || {}
        showErrorMessageBox({ message: t('error'), error: errorResponse }, document.body)
      },
      [showErrorMessageBox, t],
    )
    const [isUpdateAdjustmentLoading, setIsUpdateAdjustmentLoading] = useState(false)
    const onSubmit = useCallback(
      async (adjustmentData) => {
        try {
          setIsUpdateAdjustmentLoading(true)
          await updateAdjustments(adjustmentData)
          setIsUpdateAdjustmentLoading(false)
          dialogRef.current?.close?.()
          showToast({ children: t('saved') })
        } catch (e) {
          setIsUpdateAdjustmentLoading(false)
          await onError(e)
        }
      },
      [onError, showToast, t, updateAdjustments],
    )

    const primaryButton = useMemo(
      () => (
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          isLoading={isUpdateAdjustmentLoading}
          disabled={!isValid}
          design={ButtonDesign.Emphasized}
          renderContent={() => t('submit')}
        />
      ),
      [handleSubmit, onSubmit, isUpdateAdjustmentLoading, isValid, t],
    )

    const [isCancelMsgBoxOpen, setIsCancelMsgBoxOpen] = useState(false)
    const cancelButton = useMemo(
      () => (
        <DialogSecondaryButton onClick={() => setIsCancelMsgBoxOpen(true)}>
          {tNoPrefix('buttons.cancel')}
        </DialogSecondaryButton>
      ),
      [tNoPrefix],
    )

    const onClickCancelConfirm = useCallback(() => {
      dialogRef.current?.close?.()
      setIsCancelMsgBoxOpen(false)
    }, [])

    const confirmButton = useMemo(
      () => (
        <Button
          id={'deal-wv-adjustment-dialog-confirm-discard-button'}
          key={'message-box-confirm-button'}
          design={ButtonDesign.Emphasized}
          onClick={onClickCancelConfirm}
        >
          {tNoPrefix('buttons.confirm')}
        </Button>
      ),
      [onClickCancelConfirm, tNoPrefix],
    )

    return (
      <>
        <Dialog
          headerText={t('title')}
          {...props}
          ref={dialogRef}
          primaryButton={primaryButton}
          closeButton={cancelButton}
          onBeforeOpen={() => refetchAdjustments()}
        >
          <FormProvider {...form}>
            <RequestStateResolver
              center
              errorToDisplay={<ErrorDataUnavailableInContent />}
              isLoading={isLoading}
              isError={isError}
              renderContent={() => <EditDealAdjustmentDialogContent />}
            />
          </FormProvider>
        </Dialog>
        <MessageBox
          actions={[confirmButton, MessageBoxActions.Cancel]}
          open={isCancelMsgBoxOpen}
          type={MessageBoxTypes.Warning}
          titleText={tNoPrefix('buttons.discard')}
          onClose={() => setIsCancelMsgBoxOpen(false)}
        >
          {tNoPrefix('components.ui.buttons.cancel.popover.cancel.confirmation')}
        </MessageBox>
      </>
    )
  },
)

EditDealAdjustmentDialog.displayName = 'EditDealAdjustmentDialog'
EditDealAdjustmentDialog.propTypes = {
  dealUuid: PropTypes.string.isRequired,
  i18nPrefix: PropTypes.string,
}

export default EditDealAdjustmentDialog
