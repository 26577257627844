import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import {
  decisionStageVoterStatus,
  getObjectStatusForDecisionStageVoteStatus,
} from 'api/decision-process/decisionProcessApi'
import { decisionProcessMinutesDecisionStatus } from 'api/decision-process/decisionProcessMinutesApi'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import styles from 'components/domains/business-events-and-tasks/decision-stage/minutes/DecisionStageMinutesVotersTable.module.css'
import VotersTableActionCell, {
  possibleActions,
} from 'components/domains/business-events-and-tasks/decision-stage/table-cells/VotersTableActionCell'
import DecisionStageVotersTable from 'components/domains/business-events-and-tasks/decision-stage/voters-table/DecisionStageVotersTable'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useCreateDecisionStageMinutesVoter from 'hooks/services/business-events-and-tasks/decision-process/stages/minutes/useCreateDecisionStageMinutesVoter'
import useDecisionStageMinutesVoteTypes from 'hooks/services/business-events-and-tasks/decision-process/stages/minutes/useDecisionStageMinutesVoteTypes'
import useDecisionStageMinutesVoters from 'hooks/services/business-events-and-tasks/decision-process/stages/minutes/useDecisionStageMinutesVoters'
import useDeleteDecisionStageMinutesVoter from 'hooks/services/business-events-and-tasks/decision-process/stages/minutes/useDeleteDecisionStageMinutesVoter'
import useEditDecisionStageMinutesVoter from 'hooks/services/business-events-and-tasks/decision-process/stages/minutes/useEditDecisionStageMinutesVoter'
import useRemindMinutesVoter from 'hooks/services/business-events-and-tasks/decision-process/stages/minutes/useRemindMinutesVoter'
import { useStaffMemberSelf } from 'hooks/services/business-partners/staff-members/useStaffMemberSelf'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const toApprovalHref = 'minutes/approval/voters'

const calculateRenderedAction = ({
  decisionStatus,
  isOwnUser,
  voterStatusCode,
  hasVoteUpdatePermission,
  isPdfView,
}) => {
  if (isPdfView) {
    return
  }

  if (
    decisionStatus === decisionProcessMinutesDecisionStatus.pending ||
    decisionStatus === decisionProcessMinutesDecisionStatus.inProgressRequestDiscarded
  ) {
    return possibleActions.showEditCells
  }

  if (
    decisionStatus === decisionProcessMinutesDecisionStatus.inProgress &&
    !isOwnUser &&
    voterStatusCode === decisionStageVoterStatus.pending
  ) {
    return possibleActions.showRemindVoteButton
  }

  if (isOwnUser && hasVoteUpdatePermission) {
    return possibleActions.showToApprovalButton
  }
}

const DecisionStageMinutesVotersTable = ({
  decisionStageId,
  decisionStatus,
  isPdfView = false,
}) => {
  const {
    event: { id: eventId },
    allowedOperations,
  } = useContext(BusinessEventsAndTasksContext)
  const {
    isLoading,
    isError,
    data: votersData,
  } = useDecisionStageMinutesVoters({ eventId, decisionStageId })

  const {
    data: staffMember,
    isLoading: isStaffMemberLoading,
    isError: isStaffMemberError,
  } = useStaffMemberSelf()

  const { mutate: deleteVoter } = useDeleteDecisionStageMinutesVoter()
  const { mutate: createVoter } = useCreateDecisionStageMinutesVoter()
  const { mutate: editVoter } = useEditDecisionStageMinutesVoter()

  const hasUpdateDecisionStagePermission = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.updateDecisionStage),
    [allowedOperations],
  )

  const hasVoteUpdatePermission = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.updateDecisionStageVote),
    [allowedOperations],
  )

  const showActionButtons =
    !isPdfView &&
    hasUpdateDecisionStagePermission &&
    (decisionStatus === decisionProcessMinutesDecisionStatus.pending ||
      decisionStatus === decisionProcessMinutesDecisionStatus.inProgressRequestDiscarded)

  const renderActionCell = useCallback(
    ({
      voterCancelButtonId,
      isEditModeForCurrentRow,
      isEditButtonDisabled,
      isDeleteButtonDisabled,
      onSaveCancelButtonClicked,
      onSaveButtonClicked,
      isSaveButtonDisabled,
      onEditButtonClicked,
      onDeleteButtonClicked,
      voterId,
      voterStatusCode,
      userId,
    }) => {
      const isOwnUser = userId === staffMember.id
      return (
        <VotersTableActionCell
          voterCancelButtonId={voterCancelButtonId}
          isEditModeForCurrentRow={isEditModeForCurrentRow}
          isEditButtonDisabled={isEditButtonDisabled}
          isDeleteButtonDisabled={isDeleteButtonDisabled}
          onSaveCancelButtonClicked={onSaveCancelButtonClicked}
          onSaveButtonClicked={onSaveButtonClicked}
          isSaveButtonDisabled={isSaveButtonDisabled}
          onEditButtonClicked={onEditButtonClicked}
          onDeleteButtonClicked={onDeleteButtonClicked}
          showActionButtons={showActionButtons}
          voterId={voterId}
          decisionStageId={decisionStageId}
          toApprovalHref={`${toApprovalHref}/${voterId}`}
          useRemindVoterMutationHook={useRemindMinutesVoter}
          renderedAction={calculateRenderedAction({
            decisionStatus,
            isOwnUser,
            voterStatusCode,
            hasVoteUpdatePermission,
            isPdfView,
          })}
        />
      )
    },
    [
      showActionButtons,
      decisionStageId,
      decisionStatus,
      staffMember,
      hasVoteUpdatePermission,
      isPdfView,
    ],
  )

  const renderVotersTable = useCallback(
    () => (
      <DecisionStageVotersTable
        voters={votersData.voters}
        eventId={eventId}
        decisionStageId={decisionStageId}
        deleteVoter={deleteVoter}
        createVoter={createVoter}
        editVoter={editVoter}
        useVoteTypes={useDecisionStageMinutesVoteTypes}
        renderActionCell={renderActionCell}
        getObjectStatusForVoteStatus={getObjectStatusForDecisionStageVoteStatus}
        showAddButton={showActionButtons}
        isPdfView={isPdfView}
      />
    ),
    [
      createVoter,
      decisionStageId,
      deleteVoter,
      editVoter,
      eventId,
      isPdfView,
      renderActionCell,
      showActionButtons,
      votersData,
    ],
  )

  return (
    <div className={styles.tableWrapper}>
      <LoadingStateWrapper
        isLoading={isLoading || isStaffMemberLoading}
        isError={isError || isStaffMemberError}
        renderContent={renderVotersTable}
      />
    </div>
  )
}

DecisionStageMinutesVotersTable.propTypes = {
  decisionStageId: PropTypes.string.isRequired,
  decisionStatus: PropTypes.oneOf(Object.values(decisionProcessMinutesDecisionStatus)).isRequired,
  isPdfView: PropTypes.bool,
}

export default DecisionStageMinutesVotersTable
