import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { collateralAgreementsSchema } from 'hooks/services/collaterals/collateralAgreementSchema'

const useCollateralPropertiesTableData = (ids) => {
  const responses = useRequests({
    requests: ids.map((id) => ({
      keys: ['collateral-agreements', id, 'properties'],
      path: `/collateral-agreements/${id}`,
      options: {
        enabled: !!id,
      },
    })),
    useCache: true,
  })

  const camelizedResponses = responses.map((r) => camelize(r.data))
  const isLoading = useMemo(() => responses?.some((response) => response.isLoading), [responses])
  const isError = useMemo(() => responses?.some((response) => response.isError), [responses])

  const data = useMemo(() => {
    // We have to wait till all requests are done
    if (isLoading) return []
    return collateralAgreementsSchema.parse({
      collateralAgreements: camelizedResponses,
    })?.collateralAgreements
  }, [camelizedResponses, isLoading])

  return {
    isLoading,
    isError,
    data,
  }
}

export default useCollateralPropertiesTableData
