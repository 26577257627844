import { useMutation } from '@tanstack/react-query'
import _ from 'lodash'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

export const useUpdateCost = ({ onSuccess, onError }) => {
  const { patch } = useAccessTokenRequest()

  const getStringOrUndefinedIfNotSet = (attribute) => {
    if (_.isUndefined(attribute)) return undefined
    if (attribute) return attribute
    else return null
  }

  const { parse, localePattern } = useShortDateFormatter()
  const getDateOrUndefinedOrNullIfNotSet = (attribute) => {
    if (_.isUndefined(attribute)) return undefined
    const formattedDate = parse(attribute, localePattern)
    if (attribute && !isNaN(Date.parse(formattedDate))) return formattedDate
    else return null
  }

  const getMoneyOrUndefinedIfNotSet = (value, currency) => {
    if (!value && value !== undefined) {
      return null
    }
    return currency && isFinite(value)
      ? {
          number: Number(value),
          currency: currency,
        }
      : undefined
  }

  return useMutation(
    async ({ propertyUuid, costUuid, cost, currency }) => {
      const { data } = await patch({
        path: `/properties/${propertyUuid}/costs/${costUuid}`,
        body: {
          cost_type_code: getStringOrUndefinedIfNotSet(cost.costTypeCode),
          cost_type_code_name: cost.costTypeCodeName,
          cost_description: getStringOrUndefinedIfNotSet(cost.costDescription),
          cost_key_date: getDateOrUndefinedOrNullIfNotSet(cost.costKeyDate),
          actual_cost_amount: getMoneyOrUndefinedIfNotSet(cost.actualCostAmount, currency),
        },
      })
      return data
    },
    { onSuccess, onError },
  )
}
