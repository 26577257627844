import PropTypes from 'prop-types'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/shared/hooks/i18n/useI18n'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/shared/ui/card/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/shared/ui/card/DisplayCardViewItemView'

const DateField = ({ label, value, isFirstItem }) => {
  const { format } = useShortDateFormatter()

  return (
    <DisplayCardViewItemView>
      <DisplayCardViewInfoItemView
        label={label}
        isFirstItem={isFirstItem}
        value={value ? format(value) : '-'}
      />
    </DisplayCardViewItemView>
  )
}

DateField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  isFirstItem: PropTypes.bool,
}

export default DateField
