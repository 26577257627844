import camelize from 'camelize'
import { useRequest } from 'hooks/services/baseService'

export const useRiskFigureTypes = () => {
  const { data, ...restOfResponse } = useRequest({
    path: '/risk-figures/configurations/risk-figure-types',
    keys: ['risk-figures', 'configurations', 'risk-figure-types'],
  })

  return { ...restOfResponse, data: camelize(data) }
}
