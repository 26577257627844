import { lazy, Suspense, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import Card from 'components/ui/card/Card'
import LazyLoadingFallbackCard from 'components/ui/card/LazyLoadingFallbackCard'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import CWPLayout from 'components/ui/layout/CWPLayout'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useDeal from 'hooks/services/deals/useDeal'
import usePbbCustomServiceAllowedOperations from 'hooks/services/pbb-custom-service/usePbbCustomServiceAllowedOperations'
import lazyRetry from 'lazyRetry'
import { DealContext } from 'routes/deals/DealContext'

const DealInformationCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealInformationCard" */ 'components/domains/deals/information/DealInformationCard'
      ),
    'DealInformationCard',
  ),
)
const DealPricingCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealPricingCard" */ 'components/domains/deals/pricing/DealPricingCard'
      ),
    'DealPricingCard',
  ),
)
const DealUsesCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealUsesCard" */ 'components/domains/deals/deal-uses/DealUsesCard'
      ),
    'DealUsesCard',
  ),
)
const DealRiskConformityCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealRiskConformityCard" */ 'components/domains/deals/risk-conformity/DealRiskConformityCard'
      ),
    'DealRiskConformityCard',
  ),
)
const DealCustomListCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealReportingCard" */ 'components/domains/deals/custom-list-card/DealCustomListCard'
      ),
    'DealCustomListCard',
  ),
)
const DealBorrowerCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealBorrowerCard" */ 'components/domains/deals/borrower/DealBorrowerCard'
      ),
    'DealBorrowerCard',
  ),
)
const DealBorrowerUnitsCard = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealBorrowerUnitsCard" */ 'components/domains/deals/borrower-units/DealBorrowerUnitsCard'
      ),
    'DealBorrowerUnitsCard',
  ),
)
const CustomEntity = {
  REPORTING: 'REPORTING',
}

const DealGeneralInformationOverviewTab = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals' })
  const {
    deal: { dealUuid },
    allowedOperations,
  } = useContext(DealContext)
  const { data: allowedOperationsPbb } = usePbbCustomServiceAllowedOperations()

  const isAllowedDealUsesUpdate = allowedOperations.includes(dealsPermissions.updateDealUses)
  const isAllowedDealRiskStrategyUpdate = allowedOperations.includes(
    dealsPermissions.updateDealRiskStrategy,
  )
  const isAllowedPricingRead = allowedOperations.includes(dealsPermissions.readPricing)
  const isAllowedPricingUpdate =
    allowedOperations.includes(dealsPermissions.updatePricing) &&
    allowedOperationsPbb?.allowedOperations.includes(dealsPermissions.updatePricingPBB)

  const { data: deal = {}, isLoading: isLoadingDeal, isError: isErrorDeal } = useDeal(dealUuid)

  const errorCard = (
    <Card>
      <ErrorDataUnavailableInContent />
    </Card>
  )

  return (
    <CWPLayout>
      <Suspense fallback={<LazyLoadingFallbackCard />}>
        <RequestStateResolver
          isLoading={isLoadingDeal}
          isError={isErrorDeal}
          errorToDisplay={errorCard}
          renderContent={() => <DealInformationCard deal={deal} />}
        />
      </Suspense>
      <Suspense fallback={<LazyLoadingFallbackCard />}>
        <RequestStateResolver
          isLoading={isLoadingDeal}
          isError={isErrorDeal}
          errorToDisplay={errorCard}
          renderContent={() => (
            <DealCustomListCard
              title={t('customer-specific.reporting.title')}
              entityType={CustomEntity.REPORTING}
              linkedEntityId={dealUuid}
              requiredPermissionForEdit={dealsPermissions.reportingUpdate}
            />
          )}
        />
      </Suspense>
      <Suspense fallback={<LazyLoadingFallbackCard />}>
        <RequestStateResolver
          isLoading={isLoadingDeal}
          isError={isErrorDeal}
          errorToDisplay={errorCard}
          renderContent={() => (
            <DealRiskConformityCard
              businessSegmentCode={deal.businessSegmentCode}
              isAllowedToUpdateDealRiskStrategy={isAllowedDealRiskStrategyUpdate}
            />
          )}
        />
      </Suspense>
      <Suspense fallback={<LazyLoadingFallbackCard />}>
        <RequestStateResolver
          isLoading={isLoadingDeal}
          isError={isErrorDeal}
          errorToDisplay={errorCard}
          renderContent={() => <DealBorrowerCard borrowerBpId={deal.borrowerBpId} />}
        />
      </Suspense>
      <Suspense fallback={<LazyLoadingFallbackCard />}>
        <RequestStateResolver
          isLoading={isLoadingDeal}
          isError={isErrorDeal}
          errorToDisplay={errorCard}
          renderContent={() => <DealBorrowerUnitsCard borrowerBpId={deal.borrowerBpId} />}
        />
      </Suspense>
      <Suspense fallback={<LazyLoadingFallbackCard />}>
        <RequestStateResolver
          isLoading={isLoadingDeal}
          isError={isErrorDeal}
          errorToDisplay={errorCard}
          renderContent={() => (
            <DealUsesCard dealUuid={dealUuid} isAllowedToUpdateDealUses={isAllowedDealUsesUpdate} />
          )}
        />
      </Suspense>
      {isAllowedPricingRead && (
        <Suspense fallback={<LazyLoadingFallbackCard />}>
          <RequestStateResolver
            isLoading={isLoadingDeal}
            isError={isErrorDeal}
            errorToDisplay={errorCard}
            renderContent={() => (
              <DealPricingCard isAllowedToUpdatePricing={isAllowedPricingUpdate} />
            )}
          />
        </Suspense>
      )}
    </CWPLayout>
  )
}

export default DealGeneralInformationOverviewTab
