import { useTranslation } from 'react-i18next'
import { useAreaMeasurementUnitFormatter, useAreaPiecesUnit } from 'hooks/i18n/useI18n'

/**
 * Returns functions to create various i18n PerUOM label, e.g. "Per sqm", "Per pcs", "Pro Stk."
 *  - getPerUomLabel: Segment -> per area label OR per pieces label
 *  - getPerAreaLabel: Area Unit Code -> per area label
 *  - getPerPiecesLabel: -> per pieces label
 * @returns {(function(*): (string))|*}
 */
export const usePerUomLabelFormatter = () => {
  const { t: tSegments } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.segments',
  })

  const formatArea = useAreaMeasurementUnitFormatter()
  const uomPieces = useAreaPiecesUnit()

  const getPerAreaLabel = (area_measure_unit_code) =>
    tSegments('per-uom', { uom: formatArea(area_measure_unit_code) })

  const getPerPiecesLabel = () => tSegments('per-uom', { uom: uomPieces })

  const getPerUomLabel = (segment) => {
    if (segment?.area_measure_unit_is_pieces) return getPerPiecesLabel()
    else return getPerAreaLabel(segment.area_measure_unit_code)
  }

  return {
    getPerUomLabel,
    getPerAreaLabel,
    getPerPiecesLabel,
  }
}
