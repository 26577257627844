import {
  SelectDialog,
  IllustratedMessage,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  Text,
  Tree,
  TreeItem,
  TreeItemCustom,
  ListMode,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useMemo, useCallback, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  decisionPaperSynchronizationStatus,
  isMajorVersion,
} from 'api/decision-paper/decisionPaperApi'
import { resetTiles } from 'redux/slices/decision-paper/tilesOverviewSlice'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import styles from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperChangeVersionDialog.module.css'
import { getLatestDecisionPaperSyncAndFreezeDates } from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperLastSyncDateUtils'
import DecisionPaperTemplateInformation from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperTemplateInformation'
import DecisionPaperVersionInformation from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperVersionInformation'

const filterVersionsForSearchString =
  (searchString) =>
  ({ version }) =>
    version.toLowerCase().includes(searchString)

const DecisionPaperChangeVersionDialog = ({ isOpen, setIsOpen, versions, setIsPopoverOpen }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper',
  })
  const dispatch = useDispatch()
  const [searchString, setSearchString] = useState('')
  const [, setQueryParams] = useSearchParams()
  const {
    event: { id: eventId },
  } = useContext(BusinessEventsAndTasksContext)

  const queryClient = useQueryClient()

  const onAfterClose = useCallback(() => {
    setIsOpen(false)
    setSearchString('')
    setIsPopoverOpen(false)
  }, [setIsOpen, setIsPopoverOpen])

  const handleSearch = useCallback(
    ({ target: { value: versionSearchString } }) => {
      setSearchString(versionSearchString)
    },
    [setSearchString],
  )

  const headerText = useMemo(() => <Text>{t('change-version.title')}</Text>, [t])

  const calculateVersionText = useCallback(
    (versionNumber, status) => {
      if (status === decisionPaperSynchronizationStatus.frozen) {
        return isMajorVersion(versionNumber)
          ? t('frozen-version.major', { versionNumber })
          : t('frozen-version.minor', { versionNumber })
      }
      return t('working-version-label', { versionNumber })
    },
    [t],
  )

  const handleClick = useCallback(
    (versionNumber) => {
      dispatch(resetTiles())
      queryClient.invalidateQueries(['events', eventId, 'decision-paper', 'versions'])
      const versionParam = { version: versionNumber }
      setQueryParams({ ...versionParam })
      setIsOpen(false)
    },
    [setIsOpen, setQueryParams, eventId, queryClient, dispatch],
  )

  const versionDetails = useCallback(
    (version) => {
      const { latestMajorVersionSyncDate, latestMinorVersionSyncDate } =
        getLatestDecisionPaperSyncAndFreezeDates(versions, version.version)

      return (
        <TreeItemCustom
          className={styles.treeItemCustomList}
          onClick={() => handleClick(version.version)}
          key={version.version}
          content={
            <FlexBox
              fitContainer
              alignItems={FlexBoxAlignItems.Start}
              direction={FlexBoxDirection.Column}
              className={styles.treeItemCustom}
            >
              <FlexBox>
                <Text>{calculateVersionText(version.version, version.status)}</Text>
              </FlexBox>
              <FlexBox direction={FlexBoxDirection.Column}>
                <FlexBox>
                  <DecisionPaperTemplateInformation
                    templateName={version.name}
                    isCustomized={version.isCustomized}
                    styleForDialog
                  />
                </FlexBox>
                <FlexBox>
                  <DecisionPaperVersionInformation
                    isMajorVersion={isMajorVersion(version.version)}
                    lastFrozenOn={version.frozenDate}
                    lastEditedOn={
                      version.status !== decisionPaperSynchronizationStatus.frozen
                        ? version.lastEditedDate
                        : null
                    }
                    majorVersionLastSyncedOn={latestMajorVersionSyncDate}
                    minorVersionLastSyncedOn={latestMinorVersionSyncDate}
                    styleForDialog
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          }
        />
      )
    },
    [calculateVersionText, handleClick, versions],
  )

  const grouppedMinorVersions = useCallback((filteredVersions) => {
    const versionMap = {}

    filteredVersions.forEach((version) => {
      const [majorString] = version.version.split('.')
      const major = +majorString

      if (!versionMap[major]) {
        versionMap[major] = []
      }

      versionMap[major].push(version)
    })

    return Object.entries(versionMap).sort(
      ([majorVersionA], [majorVersionB]) => majorVersionB - majorVersionA,
    )
  }, [])

  const frozenVersionCountLabel = useCallback(
    ({ versionNumber, minorVersionsCount }) =>
      minorVersionsCount === 1
        ? t('frozen-version.minor-version-count', {
            versionNumber,
          })
        : t('frozen-versions.minor-version-count', {
            versionNumber,
            minorVersionsCount,
          }),
    [t],
  )

  const treeCommonProps = useMemo(
    () => ({
      mode: ListMode.SingleSelect,
      onSelectionChange: (e) => {
        e?.stopPropagation()
      },
      onItemClick: (e) => {
        e?.detail?.item?.toggle()
      },
    }),
    [],
  )

  const renderContent = useCallback(() => {
    const filteredVersions = versions.filter(filterVersionsForSearchString(searchString))
    if (filteredVersions.length === 0)
      return (
        <IllustratedMessage
          name="NoData"
          titleText={t('empty-filter')}
          subtitleText={t('empty-filter.subtitle')}
        />
      )
    let workingVersion, frozenVersions
    const lastFilteredVersionsIndex = filteredVersions.length - 1
    if (
      filteredVersions[lastFilteredVersionsIndex].status !==
      decisionPaperSynchronizationStatus.frozen
    ) {
      workingVersion = (
        <Tree headerText={t('working-version')} {...treeCommonProps}>
          {versionDetails(filteredVersions[lastFilteredVersionsIndex])}
        </Tree>
      )
      filteredVersions.pop() //remove it so that only frozen versions remain
    }

    if (filteredVersions.length > 0) {
      frozenVersions = (
        <Tree headerText={t('frozen-versions')} {...treeCommonProps}>
          {grouppedMinorVersions(filteredVersions).map((versionsSplit, index) => (
            <TreeItem
              key={index}
              text={frozenVersionCountLabel({
                versionNumber: versionsSplit[0],
                minorVersionsCount: versionsSplit[1].length,
              })}
            >
              {versionsSplit[1].reverse().map((version) => versionDetails(version))}
            </TreeItem>
          ))}
        </Tree>
      )
    }

    return (
      <>
        {workingVersion}
        {frozenVersions}
      </>
    )
  }, [
    versions,
    searchString,
    t,
    versionDetails,
    grouppedMinorVersions,
    frozenVersionCountLabel,
    treeCommonProps,
  ])

  return (
    <SelectDialog
      open={isOpen}
      onAfterClose={onAfterClose}
      headerText={headerText}
      onSearch={handleSearch}
      className={styles.selectDialog}
      state="None"
      draggable
      resizable
    >
      {renderContent()}
    </SelectDialog>
  )
}

DecisionPaperChangeVersionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      version: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setIsPopoverOpen: PropTypes.func.isRequired,
}

export default DecisionPaperChangeVersionDialog
