import { Button, ButtonDesign, Modals } from '@fioneer/ui5-webcomponents-react'
import React, { lazy, Suspense, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import { useDealWorkingVersionToolbarButtons } from 'components/domains/deals/deal-adjustment/useDealWorkingVersionToolbarButtons'
import CreateCashflowScenarioDialog from 'components/domains/deals/deal-cashflow/CreateCashflowScenarioDialog'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import Card from 'components/ui/card/Card'
import LazyLoadingFallbackCard from 'components/ui/card/LazyLoadingFallbackCard'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useCashflowScenariosWithFrontendPagination from 'hooks/services/deals/cashflow/useCashflowScenarios'
import { useDealPermissions } from 'hooks/services/deals/useDealPermissions'
import { useInfiniteLoading } from 'hooks/services/useInfiniteLoading'
import lazyRetry from 'lazyRetry'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'
import styles from 'routes/deals/cashflow/DealCashflowOverview.module.css'

const DealCashflowTable = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DealCashflowTable" */
        'components/domains/deals/deal-cashflow/DealCashflowTable'
      ),
    'DealCashflowTable',
  ),
)

const setInitialSorting = (queryParams) => ({
  orderField: queryParams.get('orderField') ?? 'lastUpdatedAt',
  orderDirection: queryParams.get('orderDirection') ?? 'desc',
})

const DealCashflowOverview = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.detail.cashflow' })
  const { deal: { dealUuid, displayId } = {} } = useContext(DealContext)

  /* Modals & Toasts */
  const showToast = Modals.useShowToast()
  const [isCreationDialogOpen, setIsCreationDialogOpen] = useState(false)

  /* Filter & Sort Settings */
  const [queryParams, setQueryParams] = useSearchParams()
  const [sorting, setSorting] = useState(setInitialSorting(queryParams))
  const onSortingChanged = (orderField, orderDirection) => {
    setSorting({ orderDirection, orderField })
    queryParams.set('orderField', orderField)
    queryParams.set('orderDirection', orderDirection)
    setQueryParams(queryParams)
  }

  /* Permissions */
  const {
    isLoading: isLoadingAllowedOperations,
    isError: isErrorAllowedOperations,
    data: { allowedOperations = [] } = {},
  } = useDealPermissions(dealUuid)
  const { toggleWorkingVersionButton, manageWorkingVersionButton } =
    useDealWorkingVersionToolbarButtons()
  const hasPermission = (cfScenarioPermission) => allowedOperations?.includes(cfScenarioPermission)
  const isAllowedToCreateCashflowScenario = hasPermission(dealsPermissions.createCashflowScenario)

  const onCreateCashflowScenario = () => {
    setIsCreationDialogOpen(true)
  }

  /* Load Paginated Data  */
  const paginationDelta = 50
  const {
    isFetching: isFetchingCashflowScenarios,
    isError: isErrorCashflowScenarios,
    data: cashflowData,
    loadMore: loadMoreCashflowScenarios,
    isMaximumResultSizeExceeded,
  } = useInfiniteLoading({
    requestHook: useCashflowScenariosWithFrontendPagination,
    requestHookArgs: {
      dealUuid: dealUuid,
      sort: sorting,
    },
    dataListElement: 'cashflowScenarios',
    paginationDelta: paginationDelta,
  })
  const cashflowScenarioList =
    !isErrorCashflowScenarios && !isFetchingCashflowScenarios ? cashflowData.cashflowScenarios : []
  const pagination =
    !isErrorCashflowScenarios && !isFetchingCashflowScenarios
      ? cashflowData.pagination
      : {
          offset: 0,
          limit: 0,
          total: 0,
        }

  const isLoading = isFetchingCashflowScenarios || isLoadingAllowedOperations
  const isError = isErrorCashflowScenarios || isErrorAllowedOperations

  /* Toast if result size exceeded */
  useEffect(() => {
    if (!isFetchingCashflowScenarios && isMaximumResultSizeExceeded)
      showToast({
        duration: 10000,
        children: t('table.result-limit-reached', {
          limit: pagination.total,
        }),
      })
  }, [isFetchingCashflowScenarios, isMaximumResultSizeExceeded, pagination.total, showToast, t])

  const createCashflowScenarioAction = useMemo(
    () =>
      isAllowedToCreateCashflowScenario && (
        <Button
          id="create-cashflow-scenario"
          design={ButtonDesign.Emphasized}
          onClick={onCreateCashflowScenario}
          key="create-cashflow-scenario"
        >
          {t('button.create-scenario')}
        </Button>
      ),
    [isAllowedToCreateCashflowScenario, t],
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [dealUuid],
  )

  const headerActions = useMemo(
    () =>
      createSegmentedHeaderActions(
        [toggleWorkingVersionButton, manageWorkingVersionButton],
        [createCashflowScenarioAction],
        [<CreateDealEventAction key="create-event-action" />, markFavoriteAction],
      ),
    [
      createCashflowScenarioAction,
      manageWorkingVersionButton,
      markFavoriteAction,
      toggleWorkingVersionButton,
    ],
  )

  const breadcrumb = { text: t('title') }

  return (
    <DealDetailsWrapper childBreadcrumbs={breadcrumb} actions={headerActions}>
      <Card id="deal-cashflow-overview-card">
        <div className={styles['table-wrapper']}>
          <LoadingStateWrapper
            isError={isError}
            isLoading={isLoading}
            errorTitle={t('error.title')}
            errorDescription={t('error.description')}
          >
            <Suspense
              fallback={<LazyLoadingFallbackCard data-testid="DealCashflowTable-fallback" />}
            >
              <DealCashflowTable
                dealCashflowList={cashflowScenarioList}
                pagination={pagination}
                orderField={sorting.orderField ?? 'lastUpdatedAt'}
                orderDirection={sorting.orderDirection ?? 'desc'}
                onSortingChanged={onSortingChanged}
                isError={isErrorCashflowScenarios}
                loadMore={loadMoreCashflowScenarios}
                isAllowedToReadScenario={hasPermission(dealsPermissions.readCashflowScenario)}
              />
            </Suspense>
          </LoadingStateWrapper>
        </div>
      </Card>

      <CreateCashflowScenarioDialog
        isOpen={isCreationDialogOpen}
        setIsOpen={setIsCreationDialogOpen}
        dealId={displayId}
      />
    </DealDetailsWrapper>
  )
}

export default DealCashflowOverview
