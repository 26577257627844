import { useQueryClient } from '@tanstack/react-query'
import camelize from 'camelize'
import useCreateCustomAssessmentVersion from 'hooks/services/central-data/custom-assessments/useCreateCustomAssessmentVersion'

const useCreateCustomAssessmentVersionEditWrapper = ({
  entityId,
  entityType,
  code,
  versionsForDisplayAndEditCard,
  onSuccess: onPutContentSuccess,
}) => {
  const queryClient = useQueryClient()
  const { mutate, isSuccess, isError, isLoading } = useCreateCustomAssessmentVersion({
    entityId,
    entityType,
    code,
    onSuccess: (newVersion) => {
      const {
        text: content,
        updatedAt: createdAt,
        updatedBy: createdBy,
        version,
      } = camelize(newVersion)
      const availableVersions = [
        { version, content, createdBy, createdAt },
        ...versionsForDisplayAndEditCard,
      ]
      onPutContentSuccess({
        availableVersions,
      })
      queryClient.invalidateQueries(['central-data', 'assessments', entityType, entityId, code])
    },
  })
  return { mutate, isSuccess, isError, isLoading }
}

export default useCreateCustomAssessmentVersionEditWrapper
