import find from 'lodash.find'
import isEqual from 'lodash.isequal'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { conditionsEntityTypes } from 'api/conditions/conditions'
import {
  availableFilterTypes,
  filterTypes,
} from 'components/domains/conditions/overview/ConditionsTableToolbarFilterDialog'
import { TableWatcherCellType } from 'components/domains/conditions/overview/table-cells/generic/TableWatcherCell'
import TableWatcherCellWrapper from 'components/domains/conditions/overview/table-cells/generic/TableWatcherCellWrapper'
import RequirementsTableActionsCell from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableActionsCell'
import RequirementsTableAssigneeCell from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableAssigneeCell'
import RequirementsTableAttachmentCellWrapper from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableAttachmentCellWrapper'
import RequirementsTableConditionCell from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableConditionCell'
import RequirementsTableNameCell from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableNameCell'
import RequirementsTableRefNumberCell from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableRefNumberCell'
import RequirementsTableReferencesCell from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableReferencesCell'
import RequirementsTableStatusCell from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableStatusCell'
import { useLanguage } from 'hooks/i18n/useI18n'
import useExternalTypes from 'hooks/services/conditions/config/useExternalTypes'
import useRequirementsStatus from 'hooks/services/conditions/config/useRequirementsStatus'
import { REQUIREMENTS_CELL_WIDTHS } from 'hooks/services/conditions/tables/contants'
import {
  getValueFromQueryParams,
  conditionFilterPrefixes,
} from 'hooks/services/conditions/tables/useConditionFilterQuery'
import useGetDocumentTypes from 'hooks/services/documents/useGetDocumentTypes'

const cellDefaults = {
  disableDragAndDrop: true,
  disableGlobalFilter: true,
  disableFilters: true,
  disableSortBy: true,
  disableGroupBy: true,
  isSelectableForHiding: true,
  sortingDisabled: false,
}

const calculateInitialColumnSelection = (columns, hiddenColumns) =>
  columns.map((column) => {
    const preparedColumn = {
      ...column,
      isVisible: true,
      columnKey: column.id,
      title: column.Header,
    }
    if (hiddenColumns.includes(column.id)) {
      return { ...preparedColumn, isVisible: false }
    }
    return preparedColumn
  })

// adjust ordering of columns and filters here
const defaultShownColumns = [
  'name',
  'condition',
  'refNumber',
  'references',
  // Non displayed cells, needed for filtering
  'covenantCheck',
  'attachment',
  'assignee',
  'status',
  'watcher',
  'actions',
  // Non displayed cells, needed for filtering
  'dueDate',
  'externalAssignee',
  'documentType',
  'creationDate',
  'conditionType',
  'conditionCategory',
]

const defaultHiddenColumns = [
  'conditionType',
  'creationDate',
  'dueDate',
  'externalAssignee',
  'covenantCheck',
  'documentType',
  'conditionCategory',
]

const defaultInitialSorting = {
  sortBy: 'status',
  sortDescending: false,
  sortingKey: 'requirement_status',
}

const getActionCellWidth = (currentLanguage, isEditModeForAnyRow) => {
  if (isEditModeForAnyRow && currentLanguage.includes('de')) {
    return REQUIREMENTS_CELL_WIDTHS.ACTIONS_EDIT_GERMAN
  } else if (isEditModeForAnyRow) {
    return REQUIREMENTS_CELL_WIDTHS.ACTIONS_EDIT_ENGLISH
  }
  return REQUIREMENTS_CELL_WIDTHS.ACTIONS_DISPLAY
}

const filterColumnsBasedOnEntityType =
  (entityType) =>
  ({ onlyVisibleForEntityTypes }) => {
    if (!onlyVisibleForEntityTypes) {
      return true
    }
    return onlyVisibleForEntityTypes.includes(entityType)
  }

const calculateInitialFilterSelection = (columnSelection, queryParams) =>
  columnSelection
    .filter(({ filter }) => !!filter)
    .map(
      ({
        columnKey,
        filter: { type, useLoadingHook, buildLoadingHookParams, selectionName, filterKey },
        Header,
      }) => ({
        columnKey,
        type,
        filterKey,
        value: getValueFromQueryParams({
          type,
          filterKey,
          prefix: conditionFilterPrefixes.requirements,
          queryParams,
        }),
        emptyValue: filterTypes[type].emptyValue,
        label: Header,
        useLoadingHook,
        buildLoadingHookParams,
        selectionName,
      }),
    )

const useRequirementsTableColumns = ({
  shownColumns = defaultShownColumns,
  hiddenColumns = defaultHiddenColumns,
  entityId,
  entityType,
} = {}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.requirements.table' })
  const [queryParams] = useSearchParams()

  const editedRowRequirementId = useSelector(
    (state) => state.conditions.requirementsTable.editedRow.requirementId,
  )
  const isEditModeForAnyRow = useMemo(() => !!editedRowRequirementId, [editedRowRequirementId])
  const currentLanguage = useLanguage()

  const buildUseGetDocumentTypesParams = useCallback(
    (filters) => {
      const references = filters.find((filter) => filter.filterKey === 'references')?.value
      return {
        entityRefs: [
          { type: 'REQUIREMENT', id: 'no-id' },
          ...(references?.entityIds
            ? references.entityIds.map((id) => ({ type: references.entityType, id }))
            : [{ type: entityType, id: entityId }]),
        ],
      }
    },
    [entityId, entityType],
  )
  const buildDefaultLoadingHookParams = useCallback(
    () => ({ entityType, includeDeprecated: true }),
    [entityType],
  )

  const allColumns = useMemo(
    () => ({
      name: {
        ...cellDefaults,
        Header: t('columns.name'),
        accessor: ({ id: requirementId, info: { name } }) => ({ requirementId, name }),
        id: 'name',
        minWidth: 225,
        sortingKey: 'requirement_name',
        Cell: RequirementsTableNameCell,
      },
      condition: {
        ...cellDefaults,
        Header: t('columns.condition'),
        accessor: ({ id, condition }) => ({ id, condition }),
        id: 'condition',
        sortingKey: 'requirement_condition',
        Cell: RequirementsTableConditionCell,
        filter: {
          type: availableFilterTypes.condition,
          filterKey: 'externalConditionId',
        },
      },
      conditionType: {
        ...cellDefaults,
        Header: t('columns.condition-type'),
        id: 'conditionType',
        filter: {
          type: availableFilterTypes.loadingDropdownSingleSelect,
          useLoadingHook: useExternalTypes,
          buildLoadingHookParams: buildDefaultLoadingHookParams,
          selectionName: 'types',
          filterKey: 'conditionType',
        },
        sortingKey: 'requirement_condition_type',
        isSelectableForHiding: false,
      },
      conditionCategory: {
        ...cellDefaults,
        Header: t('columns.condition-category'),
        id: 'conditionCategory',
        sortingKey: 'requirement_condition_category',
        isSelectableForHiding: false,
      },
      references: {
        ...cellDefaults,
        Header: t('columns.references'),
        accessor: ({ id, condition: { references } }) => ({
          id,
          references,
        }),
        id: 'references',
        Cell: RequirementsTableReferencesCell,
        filter: {
          type: availableFilterTypes.referencesMultiSelect,
          filterKey: 'references',
        },
        sortingDisabled: true,
        minWidth: 230,
      },
      covenantCheck: {
        ...cellDefaults,
        Header: t('columns.covenant-check'),
        id: 'covenantCheck',
        filter: {
          type: availableFilterTypes.covenantCheck,
          filterKey: 'covenantCheck',
        },
        minWidth: 90,
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
      refNumber: {
        ...cellDefaults,
        Header: t('columns.ref-number'),
        accessor: ({ id: requirementId, info: { refNumber } }) => ({ refNumber, requirementId }),
        id: 'refNumber',
        sortingKey: 'requirement_ref_number',
        Cell: RequirementsTableRefNumberCell,
      },
      documentType: {
        ...cellDefaults,
        Header: t('columns.document-type'),
        id: 'documentType',
        filter: {
          type: availableFilterTypes.loadingDropdownSingleSelect,
          useLoadingHook: useGetDocumentTypes,
          buildLoadingHookParams: buildUseGetDocumentTypesParams,
          selectionName: 'documentTypes',
          filterKey: 'documentType',
        },
        sortingKey: 'requirement_document_type',
        isSelectableForHiding: false,
      },
      attachment: {
        ...cellDefaults,
        Header: t('columns.attachment'),
        accessor: ({ id }) => ({ id }),
        id: 'attachment',
        Cell: RequirementsTableAttachmentCellWrapper,
        sortingDisabled: true,
        minWidth: 100,
      },
      assignee: {
        ...cellDefaults,
        Header: t('columns.assignee'),
        accessor: ({ id, info: { assignee } }) => ({ id, assignee }),
        id: 'assignee',
        filter: {
          type: availableFilterTypes.assignee,
          filterKey: 'assignee',
        },
        minWidth: 160,
        Cell: RequirementsTableAssigneeCell,
        sortingDisabled: true,
      },
      externalAssignee: {
        ...cellDefaults,
        Header: t('columns.external-assignee'),
        id: 'externalAssignee',
        onlyVisibleForEntityTypes: [conditionsEntityTypes.deal],
        filter: {
          type: availableFilterTypes.externalAssignee,
          filterKey: 'externalAssignee',
        },
        minWidth: 100,
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
      creationDate: {
        ...cellDefaults,
        Header: t('columns.creation-date'),
        id: 'creationDate',
        filter: {
          type: availableFilterTypes.betweenDates,
          filterKey: 'creationDate',
        },
        sortingKey: 'requirement_creation_date',
        isSelectableForHiding: false,
      },
      dueDate: {
        ...cellDefaults,
        Header: t('columns.due-date'),
        id: 'dueDate',
        filter: {
          type: availableFilterTypes.betweenDates,
          filterKey: 'dueDate',
        },
        sortingKey: 'requirement_due_date',
        isSelectableForHiding: false,
      },
      status: {
        ...cellDefaults,
        Header: t('columns.status'),
        accessor: ({ id: requirementId, status: { type, name, code } }) => ({
          requirementId,
          status: { type, name, code },
        }),
        id: 'status',
        filter: {
          type: availableFilterTypes.loadingDropdownMultiComboBox,
          useLoadingHook: useRequirementsStatus,
          buildLoadingHookParams: buildDefaultLoadingHookParams,
          selectionName: 'status',
          filterKey: 'status',
        },
        minWidth: 100,
        sortingKey: 'requirement_status',
        Cell: RequirementsTableStatusCell,
      },
      watcher: {
        ...cellDefaults,
        Header: '',
        accessor: ({ watchers, id: requirementId, condition: { id: conditionId } }) => ({
          watchers,
          requirementId,
          conditionId,
          watcherType: TableWatcherCellType.requirement,
        }),
        id: 'watcher',
        Cell: TableWatcherCellWrapper,
        width: REQUIREMENTS_CELL_WIDTHS.WATCHER,
        disableResizing: true,
        isSelectableForHiding: false,
        sortingDisabled: true,
      },
      actions: {
        ...cellDefaults,
        Header: '',
        accessor: '',
        id: 'actions',
        Cell: RequirementsTableActionsCell,
        width: getActionCellWidth(currentLanguage, isEditModeForAnyRow),
        disableResizing: true,
        isSelectableForHiding: false,
        sortingDisabled: true,
      },
    }),
    [
      t,
      buildDefaultLoadingHookParams,
      buildUseGetDocumentTypesParams,
      currentLanguage,
      isEditModeForAnyRow,
    ],
  )

  const columns = useMemo(
    () =>
      shownColumns
        .map((columnKey) => allColumns[columnKey])
        .filter(filterColumnsBasedOnEntityType(entityType)),
    [allColumns, entityType, shownColumns],
  )
  const [columnSelection, setColumnSelection] = useState(
    calculateInitialColumnSelection(columns, hiddenColumns),
  )

  useEffect(() => {
    const alteredColumnSelection = columnSelection.map((column) => {
      if (column.id === 'actions') {
        return {
          ...column,
          width: getActionCellWidth(currentLanguage, isEditModeForAnyRow),
        }
      }
      return column
    })
    setColumnSelection(alteredColumnSelection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentLanguage,
    isEditModeForAnyRow,
    //columnSelection <-- is left out on purpose to not trigger an infinite loop
  ])

  const [filters, setFilters] = useState(
    calculateInitialFilterSelection(columnSelection, queryParams),
  )
  const [searchParam, setSearchParam] = useState('')
  const [sorting, setSorting] = useState(defaultInitialSorting)
  const sortableColumns = useMemo(
    () => columnSelection.filter(({ sortingDisabled }) => !sortingDisabled),
    [columnSelection],
  )

  const onUpdateSorting = useCallback(
    ({ sortBy, sortDescending }) => {
      const selectedColumn = find(sortableColumns, ['title', sortBy])
      setSorting({
        sortBy: selectedColumn.columnKey,
        sortDescending,
        sortingKey: selectedColumn.sortingKey,
      })
    },
    [sortableColumns],
  )

  const mappedBackendFilters = useMemo(() => {
    const calculatedFilters = {}
    filters.forEach(({ filterKey, value, emptyValue }) => {
      if (isEqual(value, emptyValue)) {
        return
      }
      if (filterKey === 'creationDate') {
        if (value.lowerBound) {
          calculatedFilters['creationDateFrom'] = value.lowerBound
        }
        if (value.upperBound) {
          calculatedFilters['creationDateUntil'] = value.upperBound
        }
        return
      }
      if (filterKey === 'dueDate') {
        if (value.lowerBound) {
          calculatedFilters['dueDateFrom'] = value.lowerBound
        }
        if (value.upperBound) {
          calculatedFilters['dueDateUntil'] = value.upperBound
        }
        return
      }
      if (filterKey === 'covenantCheck') {
        calculatedFilters['covenantCheck'] = value.covenantCheckUuid
        return
      }
      calculatedFilters[filterKey] = value
    })
    if (searchParam) {
      calculatedFilters.searchFilter = searchParam
    }
    return calculatedFilters
  }, [filters, searchParam])

  return useMemo(
    () => ({
      columns,
      columnSelection,
      setColumnSelection,
      filters,
      setFilters,
      sortableColumns,
      sorting,
      onUpdateSorting,
      searchParam,
      setSearchParam,
      mappedBackendFilters,
    }),
    [
      columns,
      columnSelection,
      filters,
      sortableColumns,
      sorting,
      onUpdateSorting,
      searchParam,
      setSearchParam,
      mappedBackendFilters,
    ],
  )
}

export default useRequirementsTableColumns
