import { Label, Option, Select, Text, WrappingType } from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/default-risk-indicators-forbearance-measures/Question.module.css'

const propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isEditMode: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
}
/**
 * @typedef {object} overrides
 * @property {(newValue: string) => void} [onChange]
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 */
const Question = ({ label, value, isEditMode, onChange }) => {
  const { t } = useTranslation('decisionPaper', { keyPrefix: 'formatters.boolean' })
  return (
    <div className={compact([styles.question, isEditMode && styles.editMode]).join(' ')}>
      <Label
        // HINT: Global class so that the counter has to be defined only once
        //       The selectors should assure that we don't affect other components
        className={'label'}
        wrappingType={WrappingType.Normal}
      >
        {label}
      </Label>
      {!isEditMode && <Text className={styles.value}>{t(value || 'empty-value')}</Text>}
      {isEditMode && (
        <Select
          onChange={(e) => onChange?.(e.detail.selectedOption.value)}
          className={styles.value}
        >
          <Option value="" selected={value === ''}>
            {t('empty-value')}
          </Option>
          <Option value="yes" selected={value === 'yes'}>
            {t('yes')}
          </Option>
          <Option value="no" selected={value === 'no'}>
            {t('no')}
          </Option>
        </Select>
      )}
    </div>
  )
}

Question.propTypes = propTypes

export default Question
