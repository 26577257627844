import {
  Button,
  ButtonDesign,
  DynamicPageHeader,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
  Link,
  MessageStrip,
  MessageStripDesign,
  Modals,
  Text,
  ToolbarSeparator,
} from '@fioneer/ui5-webcomponents-react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  decisionPaperSynchronizationStatus,
  decisionPaperVersion,
  isMajorVersion,
} from 'api/decision-paper/decisionPaperApi'
import {
  neededOperationsForDecisionPaperCreate,
  neededOperationsForDecisionPaperRead,
  neededOperationsForDecisionPaperSynchronizationUpdate,
  neededOperationsForDecisionPaperTemplateDelete,
  neededOperationsForDecisionPaperTemplateUpdate,
  neededOperationsForDecisionPaperUpdate,
} from 'api/events/eventAllowedOperations'
import { isEventClosed, eventStatus as possibleEventStatus } from 'api/events/status'
import { hasUserRequiredOperations } from 'api/helper'
import { isNotFoundError } from 'api/requests'
import EmptyDecisionPaper from 'components/domains/business-events-and-tasks/decision-paper/EmptyDecisionPaper'
import AddDecisionPaperDialog from 'components/domains/business-events-and-tasks/decision-paper/dialogs/AddDecisionPaperDialog'
import EditDecisionPaperDialog from 'components/domains/business-events-and-tasks/decision-paper/dialogs/EditDecisionPaperDialog'
import renderDecisionPaper from 'components/domains/business-events-and-tasks/decision-paper/functions/renderDecisionPaper'
import DecisionPaperReduxStateLoaderWrapper from 'components/domains/business-events-and-tasks/decision-paper/redux-loader/DecisionPaperReduxStateLoaderWrapper'
import MessageBoxWithExpandableDetails from 'components/ui/dialog/MessageBoxWithExpandableDetails'
import CloseableMessageStrip from 'components/ui/feedback/CloseableMessageStrip'
import LabeledSwitch from 'components/ui/input/LabeledSwitch'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { errorTypes as synchronizationErrorTypes } from 'hooks/services/business-events-and-tasks/decision-papers/routes/syncDecisionPaperUtilities'
import useDeleteDecisionPaperFlow from 'hooks/services/business-events-and-tasks/decision-papers/routes/useDeleteDecisionPaperFlow'
import useSyncDecisionPaperFlow from 'hooks/services/business-events-and-tasks/decision-papers/routes/useSyncDecisionPaperFlow'
import useDecisionPaperVersion from 'hooks/services/business-events-and-tasks/decision-papers/useDecisionPaperVersion'
import { resetTiles } from 'redux/slices/decision-paper/tilesOverviewSlice'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import styles from 'routes/business-events-and-tasks/decision-paper/DecisionPaper.module.css'
import DecisionPaperContext from 'routes/business-events-and-tasks/decision-paper/DecisionPaperContext'
import DecisionPaperHeader from 'routes/business-events-and-tasks/decision-paper/DecisionPaperHeader'
import DecisionPaperPage from 'routes/business-events-and-tasks/decision-paper/DecisionPaperPage'
import DecisionPaperSynchronizationDialogs from 'routes/business-events-and-tasks/decision-paper/DecisionPaperSynchronizationDialogs'
import DecisionPaperTileErrorDetails from 'routes/business-events-and-tasks/decision-paper/DecisionPaperTileErrorDetails'
import DecisionPaperAttachments from 'routes/business-events-and-tasks/decision-paper/attachments/DecisionPaperAttachments'
import {
  allTilesInitializedSelector,
  hiddenTilesSelector,
  someTilesAreLoadingSelector,
  someTilesHaveTechnicalErrorSelector,
  someTilesHavePermissionErrorSelector,
  tilesSelector,
} from 'routes/business-events-and-tasks/decision-paper/selectors/decisionPaperSelectors'
import DecisionPaperBackToCurrentButton from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperBackToCurrentButton'
import DecisionPaperFrozenVersionButton from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperFrozenVersionButton'
import { getLatestDecisionPaperSyncAndFreezeDates } from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperLastSyncDateUtils'
import DecisionPaperWorkingVersionButton from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperWorkingVersionButton'
import paths from 'routes/paths'

const DecisionPaper = () => {
  const { event, allowedOperations } = useContext(BusinessEventsAndTasksContext)
  const { id: eventId, status: eventStatus } = event
  const { t: tNoPrefix } = useTranslation()
  const dispatch = useDispatch()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper',
  })
  const navigate = useNavigate()
  const showToast = Modals.useShowToast()
  const showMessageBox = useShowMessageBox()
  const allTilesInitialized = useSelector(allTilesInitializedSelector)
  const someTilesAreLoading = useSelector(someTilesAreLoadingSelector)
  const someTilesHaveTechnicalError = useSelector(someTilesHaveTechnicalErrorSelector)
  const someTilesHavePermissionError = useSelector(someTilesHavePermissionErrorSelector)
  const tiles = useSelector(tilesSelector)
  const tileHiddenState = useSelector(hiddenTilesSelector)

  const hiddenTileIds = useMemo(
    () => [
      ...Object.keys(tileHiddenState).filter((tileId) => tileHiddenState[tileId]?.isHidden),
      ...Object.keys(tiles).filter((tileId) => tiles[tileId]?.data?.isHidden),
    ],
    [tiles, tileHiddenState],
  )

  const [queryParams] = useSearchParams()
  const currentVersion = queryParams.get('version')

  const {
    isLoading,
    isError,
    error,
    data: currentDecisionPaper,
  } = useDecisionPaperVersion({ eventId, versionNumber: currentVersion })

  const decisionPaper = currentDecisionPaper

  const [isCreationDialogOpen, setIsCreationDialogOpen] = useState(false)
  const [isEditTemplateDialogOpen, setIsEditTemplateDialogOpen] = useState(false)
  const [isShowTileErrorsMessageBoxOpen, setIsShowTileErrorsMessageBoxOpen] = useState(false)

  // Cleanup hook to clean the state once someone leaves the decision paper page
  useEffect(
    () => () => {
      dispatch(resetTiles())
    },
    [], //eslint-disable-line react-hooks/exhaustive-deps
  )

  // --- Delete flow --- //
  const onSuccessfulDelete = useCallback(() => {
    showToast({ children: t('delete.success') })
  }, [showToast, t])
  const { handleDeleteButtonPressed } = useDeleteDecisionPaperFlow({ eventId, onSuccessfulDelete })

  // --- Sync Flow --- //
  const onSuccessfulSynchronization = useCallback(
    (newStatus) =>
      showToast({
        children: t(`synchronization.success.${newStatus.toLowerCase()}`),
      }),
    [showToast, t],
  )
  const onSynchronizationError = useCallback(
    (synchronizationErrorDetails) => {
      const synchronizationErrorText =
        synchronizationErrorDetails.type === synchronizationErrorTypes.tilesLoading ||
        synchronizationErrorDetails.type === synchronizationErrorTypes.tilesError
          ? t('synchronization.error.tiles-general')
          : t('synchronization.error.technical')

      showMessageBox({
        type: MessageBoxTypes.Error,
        children: synchronizationErrorText,
        actions: [MessageBoxActions.Close],
        emphasizedAction: MessageBoxActions.Close,
      })
    },
    [showMessageBox, t],
  )
  const currentSynchronizationStatus = decisionPaper?.synchronizationStatus
  const {
    isDecisionPaperSynced,
    isSynchronizationConfirmationDialogOpen,
    isEditCallLoading,
    handleSynchronizationConfirmation,
    handleSynchronizationClicked,
    handleSynchronizationCancel,
  } = useSyncDecisionPaperFlow({
    eventId,
    onSynchronizationError,
    onSuccessfulSynchronization,
    currentSynchronizationStatus,
  })

  // --- Buttons and Callbacks ---

  const openCreateDialog = useCallback(() => {
    setIsCreationDialogOpen(true)
  }, [])
  const createDecisionPaperButton = useMemo(() => {
    if (!hasUserRequiredOperations(neededOperationsForDecisionPaperCreate, allowedOperations)) {
      return <></>
    }
    return (
      <Button design={ButtonDesign.Emphasized} onClick={openCreateDialog}>
        {tNoPrefix('buttons.create')}
      </Button>
    )
  }, [tNoPrefix, openCreateDialog, allowedOperations])
  const openEditTemplateDialog = () => {
    setIsEditTemplateDialogOpen(true)
  }
  const editDecisionPaperTemplateButton = useMemo(() => {
    if (
      !hasUserRequiredOperations(neededOperationsForDecisionPaperTemplateUpdate, allowedOperations)
    ) {
      return <></>
    }
    return (
      <Button design={ButtonDesign.Emphasized} onClick={openEditTemplateDialog}>
        {tNoPrefix('buttons.edit')}
      </Button>
    )
  }, [tNoPrefix, allowedOperations])

  const deleteDecisionPaperTemplateButton = useMemo(() => {
    if (
      !hasUserRequiredOperations(neededOperationsForDecisionPaperTemplateDelete, allowedOperations)
    ) {
      return <></>
    }
    return (
      <Button design={ButtonDesign.Transparent} onClick={handleDeleteButtonPressed}>
        {tNoPrefix('buttons.delete')}
      </Button>
    )
  }, [handleDeleteButtonPressed, tNoPrefix, allowedOperations])

  const isCurrentVersionLastestVersion = useMemo(() => {
    const lastAvailableVersion = decisionPaper?.availableVersions.slice(-1)[0].version

    return (
      decisionPaper?.currentVersion !== lastAvailableVersion &&
      decisionPaper?.currentVersion !== decisionPaperVersion.workingVersion
    )
  }, [decisionPaper?.availableVersions, decisionPaper?.currentVersion])

  const isCurrentVersionWorkingVersion = useMemo(
    () => decisionPaper?.currentVersion === decisionPaperVersion.workingVersion,
    [decisionPaper?.currentVersion],
  )

  const isLatestVersionMajorVersion = useMemo(() => {
    const lastAvailableVersion = decisionPaper?.availableVersions.slice(-1)[0].version
    return lastAvailableVersion && isMajorVersion(lastAvailableVersion)
  }, [decisionPaper?.availableVersions])

  const showSyncSwitch = useMemo(
    () =>
      isCurrentVersionWorkingVersion &&
      !isEventClosed(eventStatus) &&
      !someTilesHavePermissionError &&
      hasUserRequiredOperations(
        neededOperationsForDecisionPaperSynchronizationUpdate,
        allowedOperations,
      ),
    [isCurrentVersionWorkingVersion, eventStatus, someTilesHavePermissionError, allowedOperations],
  )

  const showFreezeButton = useMemo(
    () =>
      isCurrentVersionWorkingVersion &&
      !isEventClosed(eventStatus) &&
      !someTilesHavePermissionError,
    [isCurrentVersionWorkingVersion, eventStatus, someTilesHavePermissionError],
  )

  const areSyncSwitchAndFreezeButtonDisabled = useMemo(
    () => someTilesAreLoading || someTilesHaveTechnicalError,
    [someTilesAreLoading, someTilesHaveTechnicalError],
  )

  const { latestMajorVersionSyncDate, latestMinorVersionSyncDate } = useMemo(() => {
    if (!decisionPaper) return {}

    return getLatestDecisionPaperSyncAndFreezeDates(
      decisionPaper?.availableVersions,
      isCurrentVersionWorkingVersion
        ? decisionPaper?.availableVersions.slice(-1)[0].version
        : decisionPaper?.currentVersion,
    )
  }, [decisionPaper, isCurrentVersionWorkingVersion])

  const synchronizationStartStopSwitch = useMemo(
    () => (
      <>
        {isCurrentVersionLastestVersion && <DecisionPaperBackToCurrentButton />}
        {isCurrentVersionWorkingVersion && (
          <DecisionPaperWorkingVersionButton
            lastEditedOn={decisionPaper?.lastEditedDate}
            majorVersionLastSyncedOn={latestMajorVersionSyncDate}
            minorVersionLastSyncedOn={latestMinorVersionSyncDate}
            synchronizationStatus={decisionPaper.synchronizationStatus}
            versions={decisionPaper.availableVersions}
            templateCode={decisionPaper.code}
            templateName={decisionPaper.name}
            templateIsCustomized={decisionPaper.isCustomized}
            isMajorVersion={isLatestVersionMajorVersion}
            disabled={areSyncSwitchAndFreezeButtonDisabled}
            showFreezeButton={showFreezeButton}
            existingDecisionPaperTemplate={decisionPaper}
          />
        )}
        {currentSynchronizationStatus === decisionPaperSynchronizationStatus.frozen && (
          <DecisionPaperFrozenVersionButton
            versionNumber={decisionPaper.currentVersion}
            majorVersionLastSyncedOn={latestMajorVersionSyncDate}
            minorVersionLastSyncedOn={latestMinorVersionSyncDate}
            lastFrozenOn={decisionPaper?.frozenDate}
            versions={decisionPaper.availableVersions}
          />
        )}
        {showSyncSwitch && (
          <LabeledSwitch
            onChange={handleSynchronizationClicked}
            checked={isDecisionPaperSynced}
            checkedText={t('synchronization.on')}
            isLoading={isEditCallLoading}
            uncheckedText={t('synchronization.off')}
            disabled={isDecisionPaperSynced && areSyncSwitchAndFreezeButtonDisabled}
          />
        )}
      </>
    ),
    [
      isCurrentVersionLastestVersion,
      isCurrentVersionWorkingVersion,
      decisionPaper,
      isLatestVersionMajorVersion,
      areSyncSwitchAndFreezeButtonDisabled,
      showFreezeButton,
      currentSynchronizationStatus,
      latestMajorVersionSyncDate,
      latestMinorVersionSyncDate,
      showSyncSwitch,
      handleSynchronizationClicked,
      isDecisionPaperSynced,
      t,
      isEditCallLoading,
    ],
  )

  const handleExportClick = useCallback(() => {
    const versionForExport = decisionPaper?.currentVersion
    const exportQueryParams = new URLSearchParams()
    exportQueryParams.append('event_id', eventId)
    if (versionForExport && versionForExport !== decisionPaperVersion.workingVersion) {
      exportQueryParams.append('version', versionForExport)
    }
    window.open(`/${paths.decisionPaperPdfExport}?${exportQueryParams.toString()}`)
  }, [eventId, decisionPaper?.currentVersion])

  const isAttachmentsEditView = useMemo(
    () =>
      decisionPaper?.currentVersion === decisionPaperVersion.workingVersion &&
      hasUserRequiredOperations(neededOperationsForDecisionPaperUpdate, allowedOperations),
    [decisionPaper?.currentVersion, allowedOperations],
  )

  const exportButton = useMemo(
    () => (
      <DecisionPaperAttachments
        decisionPaperVersion={currentVersion}
        handleExportClick={handleExportClick}
        isEditView={isAttachmentsEditView}
      />
    ),
    [currentVersion, handleExportClick, isAttachmentsEditView],
  )

  const renderBasedOnEventStatusAndDecisionPaperResponse = useCallback(() => {
    // User has not the required permissions for this page
    if (!hasUserRequiredOperations(neededOperationsForDecisionPaperRead, allowedOperations)) {
      return {
        content: (
          <IllustratedMessage
            size={IllustrationMessageSize.Spot}
            name={IllustrationMessageType.UnableToLoad}
            titleText={tNoPrefix('app.permission-error.title')}
            subtitleText={tNoPrefix('app.permission-error.subtitle')}
          />
        ),
      }
    }
    // Error Loading Decision Paper or Decision paper loading
    if (isLoading || (isError && !isNotFoundError(error))) {
      return {
        content: (
          <LoadingStateWrapper
            loadingTitle={''}
            loadingDescription={t('loading')}
            errorTitle={t('loading.error')}
            errorDescription={t('loading.error.subtitle')}
            errorDetails={' '}
            isLoading={isLoading}
            isError={isError}
          />
        ),
      }
    }
    // No Decision Paper exist for the event (independent of the status)
    if (isError && isNotFoundError(error)) {
      // If event in status drafted or ongoing render the create button
      if (
        eventStatus === possibleEventStatus.drafted ||
        eventStatus === possibleEventStatus.ongoing
      ) {
        return {
          content: <EmptyDecisionPaper titleText={t('empty')} subtitleText={t('empty.subtitle')} />,
          headerActions: <>{createDecisionPaperButton}</>,
        }
      }
      // Otherwise render EmptyContent with no buttons and different texts
      return {
        content: (
          <EmptyDecisionPaper
            titleText={t('never-existed')}
            subtitleText={t('never-existed.subtitle')}
          />
        ),
      }
    }

    // Decision paper is loaded, but the redux state is not fully initialized to render all views
    // TODO: Remove me since this logic is no longer working and catched and the tile wrappers if the state is undefined.
    if (!allTilesInitialized) {
      return {
        content: (
          <LoadingStateWrapper
            loadingTitle={''}
            loadingDescription={t('loading')}
            isLoading={true}
            isError={false}
          />
        ),
        hasDecisionPaper: true,
      }
    }

    // Decision Paper exists and event in status DRAFT
    if (eventStatus === possibleEventStatus.drafted) {
      // Show Edit and Delete Buttons
      return {
        ...renderDecisionPaper({ decisionPaper, hiddenTileIds }),
        headerActions: (
          <>
            {editDecisionPaperTemplateButton}
            {deleteDecisionPaperTemplateButton}
            <ToolbarSeparator className={styles.actionSeparator} />
            {exportButton}
          </>
        ),
        hasDecisionPaper: true,
      }
    }

    if (eventStatus === possibleEventStatus.ongoing) {
      return {
        ...renderDecisionPaper({ decisionPaper, hiddenTileIds }),
        headerActions: (
          <FlexBox direction={FlexBoxDirection.Row} alignItems={FlexBoxAlignItems.Center}>
            {synchronizationStartStopSwitch}
            <ToolbarSeparator className={styles.actionSeparator} />
            {exportButton}
          </FlexBox>
        ),
        hasDecisionPaper: true,
      }
    }

    // For all other cases currently pdf export and version switch allowed
    return {
      ...renderDecisionPaper({ decisionPaper, hiddenTileIds }),
      headerActions: (
        <FlexBox direction={FlexBoxDirection.Row} alignItems={FlexBoxAlignItems.Center}>
          {synchronizationStartStopSwitch}
          <ToolbarSeparator className={styles.actionSeparator} />
          {exportButton}
        </FlexBox>
      ),
      hasDecisionPaper: true,
    }
  }, [
    allTilesInitialized,
    createDecisionPaperButton,
    decisionPaper,
    deleteDecisionPaperTemplateButton,
    editDecisionPaperTemplateButton,
    synchronizationStartStopSwitch,
    exportButton,
    error,
    eventStatus,
    isError,
    isLoading,
    allowedOperations,
    hiddenTileIds,
    tNoPrefix,
    t,
  ])

  // --- Render Page and Header ---
  const { headerActions, content, isSingleSectionAndSubsection, hasDecisionPaper } = useMemo(
    () => renderBasedOnEventStatusAndDecisionPaperResponse(),
    [renderBasedOnEventStatusAndDecisionPaperResponse],
  )

  const pathToOverview = `/${paths.businessEventsAndTasks}/business-events/${eventId}`
  const navigateToOverview = useCallback(
    (linkEvent) => {
      linkEvent.preventDefault()
      navigate(pathToOverview)
    },
    [navigate, pathToOverview],
  )

  const onLearnMoreClick = useCallback(() => {
    setIsShowTileErrorsMessageBoxOpen(true)
  }, [])

  const loadingAndErrorMessageStrips = useMemo(
    () => (
      <>
        {someTilesAreLoading && (
          <CloseableMessageStrip
            className={styles.tileErrorMessageStrip}
            design={MessageStripDesign.Information}
          >
            {t('message-strip.tiles-loading')}
          </CloseableMessageStrip>
        )}
        {someTilesHaveTechnicalError && (
          <CloseableMessageStrip
            className={styles.tileErrorMessageStrip}
            design={MessageStripDesign.Negative}
          >
            <Text>{t('message-strip.tiles-error')}</Text>
            {' ' /* empty space did not work in translation or inside ui5-text element*/}
            <Text className={styles.moreInformation} onClick={onLearnMoreClick}>
              {t('message-strip.tiles-error.learn-more')}
            </Text>
          </CloseableMessageStrip>
        )}
        {someTilesHavePermissionError && (
          <CloseableMessageStrip
            className={styles.tileErrorMessageStrip}
            design={MessageStripDesign.Warning}
          >
            <Text>{t('message-strip.tiles-permission-error-before-line-break')}</Text>
            <Text>{t('message-strip.tiles-permission-error-after-line-break')}</Text>
          </CloseableMessageStrip>
        )}
      </>
    ),
    [
      someTilesAreLoading,
      someTilesHaveTechnicalError,
      someTilesHavePermissionError,
      t,
      onLearnMoreClick,
    ],
  )

  const headerContent = useMemo(() => {
    if (hasDecisionPaper && eventStatus === possibleEventStatus.drafted)
      return (
        <DynamicPageHeader className={styles.messageStrip}>
          <FlexBox fitContainer>
            <MessageStrip design={MessageStripDesign.Information} hideCloseButton>
              {t('message-strip.draft-event.before-link')}
              <Link onClick={navigateToOverview} href={pathToOverview}>
                {t('message-strip.draft-event.link')}
              </Link>
              {t('message-strip.draft-event.after-link')}
            </MessageStrip>
          </FlexBox>
        </DynamicPageHeader>
      )

    if (currentSynchronizationStatus === decisionPaperSynchronizationStatus.frozen)
      return (
        <DynamicPageHeader className={styles.messageStrip}>
          <FlexBox fitContainer>
            <MessageStrip
              hideCloseButton
              design={MessageStripDesign.Information}
              className={styles.messageStrip}
            >
              {t('frozen-version.information')}
            </MessageStrip>
          </FlexBox>
        </DynamicPageHeader>
      )

    if (!isLatestVersionMajorVersion)
      return (
        <DynamicPageHeader className={styles.messageStrip}>
          <FlexBox fitContainer direction={FlexBoxDirection.Column}>
            <MessageStrip
              hideCloseButton
              design={MessageStripDesign.Information}
              className={styles.messageStrip}
            >
              {t('minor-version.information')}
            </MessageStrip>
            {loadingAndErrorMessageStrips}
          </FlexBox>
        </DynamicPageHeader>
      )

    return (
      <DynamicPageHeader className={styles.messageStrip}>
        <FlexBox fitContainer direction={FlexBoxDirection.Column}>
          {loadingAndErrorMessageStrips}
        </FlexBox>
      </DynamicPageHeader>
    )
  }, [
    eventStatus,
    hasDecisionPaper,
    navigateToOverview,
    pathToOverview,
    t,
    currentSynchronizationStatus,
    isLatestVersionMajorVersion,
    loadingAndErrorMessageStrips,
  ])

  const decisionPaperTitle = useMemo(
    () => <DecisionPaperHeader headerActions={headerActions} />,
    [headerActions],
  )

  const tileIdsWithErrors = useMemo(() => {
    const tileIds = Object.keys(tiles)
    if (tileIds.length === 0) return []

    return tileIds
      .filter((tileId) => tiles[tileId].isError)
      .map((tileId) => ({ tileId, error: tiles[tileId].error }))
  }, [tiles])

  const tileErrorDetails = useCallback(
    () =>
      tileIdsWithErrors.map((tileIdWithError) => (
        <DecisionPaperTileErrorDetails
          key={`decision-paper-tile-error-details-${tileIdWithError.tileId}`}
          sections={decisionPaper.sections}
          tileId={tileIdWithError.tileId}
          error={tileIdWithError?.error}
        />
      )),
    [decisionPaper, tileIdsWithErrors],
  )

  return (
    <DecisionPaperContext.Provider value={{ decisionPaper }}>
      <DecisionPaperPage
        headerTitle={decisionPaperTitle}
        headerContent={headerContent}
        displayTabBar={!isSingleSectionAndSubsection}
      >
        {content}
      </DecisionPaperPage>
      {hasDecisionPaper && (
        <DecisionPaperReduxStateLoaderWrapper
          decisionPaper={decisionPaper}
          key={`decision-paper-${decisionPaper?.currentVersion}-${decisionPaper?.synchronizationStatus}`}
        />
      )}
      {!hasDecisionPaper && (
        <AddDecisionPaperDialog isOpen={isCreationDialogOpen} setIsOpen={setIsCreationDialogOpen} />
      )}
      {hasDecisionPaper && (
        <EditDecisionPaperDialog
          isOpen={isEditTemplateDialogOpen}
          setIsOpen={setIsEditTemplateDialogOpen}
          templateCode={decisionPaper.code}
          existingDecisionPaperTemplate={decisionPaper}
        />
      )}
      <DecisionPaperSynchronizationDialogs
        isConfirmationDialogOpen={isSynchronizationConfirmationDialogOpen}
        onConfirm={handleSynchronizationConfirmation}
        onCancel={handleSynchronizationCancel}
      />
      <MessageBoxWithExpandableDetails
        messageSummary={t('tile-errors-box.summary')}
        messageDetails={tileErrorDetails()}
        messageBoxType={MessageBoxTypes.Error}
        isOpen={isShowTileErrorsMessageBoxOpen}
        onClose={() => {
          setIsShowTileErrorsMessageBoxOpen(false)
        }}
      />
    </DecisionPaperContext.Provider>
  )
}

export default DecisionPaper
