import { TextAlign, VerticalAlign } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import { columnTypes } from 'routes/business-partners/BusinessPartnerGroupExposures'
import {
  GroupExposureBusinessPartnerTableColumnCell,
  NameCell,
  renderCurrency,
} from 'routes/business-partners/GroupExposureBusinessPartnerTableColumnCell'

const useGroupExposureBusinessPartnerTableColumnDefinitions = (
  riskFigureDisplayNamesMap,
  groupExposure,
) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.business-partner' })
  const columnTypesToDisplay = columnTypes
  const getDisplayNameForClassType = (classTypeKey) =>
    riskFigureDisplayNamesMap[classTypeKey] ?? classTypeKey

  const formatCurrency = useCustomizableCurrencyFormatter()
  const fallbackCurrency = groupExposure?.total?.unit ?? 'EUR'

  const mapColumns = (riskFigureKey) => ({
    Header: getDisplayNameForClassType(riskFigureKey),
    id: riskFigureKey,
    accessor: (row) =>
      formatCurrency(row[riskFigureKey]?.value ?? 0, row[riskFigureKey]?.unit ?? fallbackCurrency),
    hAlign: TextAlign.End,
    renderCell: renderCurrency,
  })

  return [
    {
      Header: t('table-business-partner'),
      accessor: 'id',
      Cell: NameCell,
    },
    ...Object.keys(groupExposure)
      .filter((riskFigureKey) => columnTypesToDisplay.includes(riskFigureKey))
      .map(mapColumns)
      .sort(
        (a, b) =>
          (groupExposure[a.id]?.ordinalPosition || 0) - (groupExposure[b.id]?.ordinalPosition || 0),
      ),
  ].map((element) => ({
    disableDragAndDrop: true,
    Cell: (cellProps) =>
      GroupExposureBusinessPartnerTableColumnCell({
        ...cellProps,
        renderCell: element.renderCell,
      }),
    vAlign: VerticalAlign.Middle,
    ...element,
  }))
}

export default useGroupExposureBusinessPartnerTableColumnDefinitions
