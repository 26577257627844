import { Link, WrappingType } from '@fioneer/ui5-webcomponents-react'
import { first } from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PropertyRentRollOverviewCurrentRentText from 'components/domains/properties/rent-roll/overview/current-rent/PropertyRentRollOverviewCurrentRentText'
import styles from 'components/domains/properties/rent-roll/overview/current-rent/PropertyRentRollOverviewPrimaryTenantUnit.module.css'
import LoadingContent from 'components/ui/content/LoadingContent'
import useBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationships'

const PropertyRentRollOverviewPrimaryTenantUnit = ({ primaryTenantId }) => {
  const { t: tCurrentRent } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.current-rent',
  })

  const {
    isLoading: isLoadingBusinessPartnerRelationships,
    isError: isErrorBusinessPartnerRelationships,
    data: businessPartnerRelationshipsData,
  } = useBusinessPartnerRelationships(primaryTenantId)

  const getPrimaryTenantUnit = () => {
    const relationship = first(
      first(businessPartnerRelationshipsData?.unitRelationships)?.relationships,
    )
    return relationship?.head
  }

  const primaryTenantUnit = getPrimaryTenantUnit()

  const renderPrimaryTenantUnitLink = () => {
    if (primaryTenantUnit) {
      return (
        <Link
          className={styles.primaryTenantUnitLink}
          wrappingType={WrappingType.Normal}
          href={`/business-partners/${primaryTenantUnit?.id}`}
          target="_parent"
        >
          {primaryTenantUnit?.fullName}
        </Link>
      )
    } else {
      return <PropertyRentRollOverviewCurrentRentText text={tCurrentRent('not-allocated')} />
    }
  }

  if (primaryTenantId) {
    return (
      <>
        <LoadingContent
          contentKey="primary-tenant-unit"
          isError={isErrorBusinessPartnerRelationships}
          isLoading={isLoadingBusinessPartnerRelationships}
          errorContent={<PropertyRentRollOverviewCurrentRentText text={tCurrentRent('error')} />}
        >
          {renderPrimaryTenantUnitLink()}
        </LoadingContent>
      </>
    )
  } else {
    return <></>
  }
}
PropertyRentRollOverviewPrimaryTenantUnit.propTypes = {
  primaryTenantId: PropTypes.string,
}
export default PropertyRentRollOverviewPrimaryTenantUnit
