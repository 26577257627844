import {
  Text,
  FlexBox,
  Icon,
  FlexBoxDirection,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropertyRentRollWorkingVersionValidationResultsEntry from 'components/domains/properties/rent-roll/working-version/validation-results-summary/PropertyRentRollWorkingVersionValidationResultsEntry'
import styles from 'components/domains/properties/rent-roll/working-version/validation-results-summary/PropertyRentRollWorkingVersionValidationResultsTabContainer.module.css'

const flattenValidationResultsByState = (validationState, validationResults) =>
  Object.keys(validationResults[validationState]).map((message) => ({
    message,
    state: validationState,
    ...validationResults[validationState][message],
  }))

const PropertyRentRollWorkingVersionValidationResultsTabContainer = ({
  summarizedValidationResults,
  amountOfInconsistenciesByState: { totalErrors, totalWarnings },
  handleSelectRows,
}) => {
  const { t } = useTranslation()
  const [tabs, setTabs] = useState([
    {
      state: 'all',
      title: t('pages.rent-roll-working-version.validation.title.all'),
      icon: null,
      active: true,
    },
    {
      state: 'error',
      title: totalErrors,
      icon: 'error',
      active: false,
    },
    {
      state: 'warning',
      title: totalWarnings,
      icon: 'alert',
      active: false,
    },
  ])

  const switchTab = (tabState) => {
    setTabs((prevTabs) =>
      prevTabs.map((tab) => ({
        ...tab,
        active: tab.state === tabState,
      })),
    )
  }

  const selectedTab = tabs.find((tab) => tab.active === true)

  const validationResultsToDisplay =
    selectedTab.state === 'all'
      ? [
          ...flattenValidationResultsByState('error', summarizedValidationResults),
          ...flattenValidationResultsByState('warning', summarizedValidationResults),
        ]
      : flattenValidationResultsByState(selectedTab.state, summarizedValidationResults)

  const isTabSelected = (state) => state === selectedTab.state

  return (
    <FlexBox className={styles.container} direction={FlexBoxDirection.Column}>
      <FlexBox className={styles.tabs} direction={FlexBoxDirection.Row}>
        {tabs.map(({ title, icon, state }, idx) => (
          <FlexBox
            id={`rrwv-validation-results-tab-${state}`}
            className={styles[`tab${isTabSelected(state) ? '-selected' : ''}`]}
            key={`rrwv-validation-results-${idx}`}
            onClick={() => switchTab(state)}
            direction={FlexBoxDirection.Row}
            alignItems={FlexBoxAlignItems.Center}
            justifyContent={FlexBoxJustifyContent.SpaceBetween}
          >
            {icon && (
              <Icon
                name={icon}
                className={styles[`tab-icon-${state}${isTabSelected(state) ? '-selected' : ''}`]}
              />
            )}
            <Text className={styles[`tab-text${isTabSelected(state) ? '-selected' : ''}`]}>
              {title}
            </Text>
          </FlexBox>
        ))}
      </FlexBox>
      <FlexBox direction={FlexBoxDirection.Column} className={styles.content}>
        {validationResultsToDisplay.map((entry) => (
          <PropertyRentRollWorkingVersionValidationResultsEntry
            key={`validation-results-entry-${entry.message}`}
            validationResultsEntry={entry}
            handleSelectRows={handleSelectRows}
          />
        ))}
      </FlexBox>
    </FlexBox>
  )
}

PropertyRentRollWorkingVersionValidationResultsTabContainer.propTypes = {
  summarizedValidationResults: PropTypes.shape({
    warning: PropTypes.object,
    error: PropTypes.object,
  }),
  amountOfInconsistenciesByState: PropTypes.shape({
    totalErrors: PropTypes.number,
    totalWarnings: PropTypes.number,
  }),
  handleSelectRows: PropTypes.func,
}

export default PropertyRentRollWorkingVersionValidationResultsTabContainer
