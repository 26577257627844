import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import useUpdateMonitoringItemDueDate from 'hooks/services/deals/covenants/monitorings/useUpdateMonitoringItemDueDate'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'

const useUpdateNextPeriodicalCheck = ({
  covenantUuid,
  covenantCheckUuid,
  setIsSaveLoading,
  onSuccess,
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const showErrorMessageBox = useShowErrorMessageBox()

  const onError = async (error) => {
    const responseError = await formatHookError(error)
    showErrorMessageBox({
      message: t('components.cards.save-error'),
      error: responseError,
    })
    setIsSaveLoading(false)
  }
  const onSuccessInternal = () => {
    showToast(
      {
        children: t('pages.business-partner.periodical-checks.general-information.update.success'),
      },
      document.body,
    )
    queryClient.invalidateQueries('covenants', covenantUuid, 'monitoring', 'items')
    queryClient.invalidateQueries('covenants', 'monitoring', 'items')
    queryClient.invalidateQueries('covenants', 'covenant-checks', covenantCheckUuid)
    setIsSaveLoading(false)
    onSuccess()
  }

  return useUpdateMonitoringItemDueDate({
    onSuccess: onSuccessInternal,
    onError,
  })
}

export default useUpdateNextPeriodicalCheck
