import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import '@ui5/webcomponents-icons/dist/AllIcons.js'

const ValuationRequestEditMultipleButton = ({ ...props }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.valuation',
  })

  return (
    <Button {...props} design={ButtonDesign.Transparent}>
      {t('button.edit-multiple')}
    </Button>
  )
}

ValuationRequestEditMultipleButton.propTypes = {
  isDisabled: PropTypes.bool,
}

export default ValuationRequestEditMultipleButton
