import {
  Button,
  ButtonDesign,
  IllustratedMessage,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { covenantPermissions } from 'api/deals/covenantPermissions'
import CheckResultsCard from 'components/domains/deals/covenants/check-results-card/CheckResultsCard'
import DeleteCovenantConfirmationMessageBox from 'components/domains/deals/covenants/delete-confirmation/DeleteCovenantConfirmationMessageBox'
import CovenantGeneralInformationCard from 'components/domains/deals/covenants/general-information/CovenantGeneralInformationCard'
import CovenantLimitCard from 'components/domains/deals/covenants/limit-cards/CovenantLimitCard'
import { CovenantLimitType } from 'components/domains/deals/covenants/limit-cards/model/CovenantLimitType'
import MonitoringInformationCard from 'components/domains/deals/covenants/monitoring-information-card/MonitoringInformationCard'
import MonitoringItemsCard from 'components/domains/deals/covenants/monitoring-items-card/MonitoringItemsCard'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import CWPLayout from 'components/ui/layout/CWPLayout'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import useCovenantById from 'hooks/services/deals/covenants/useCovenantById'
import useCovenantDefinitions from 'hooks/services/deals/covenants/useCovenantDefinitions'
import { CovenantContext } from 'routes/business-partners/CovenantContext'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'
import styles from 'routes/deals/covenants/DealCovenantDetails.module.css'
import paths from 'routes/paths'

const DealCovenantDetails = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.covenants' })
  const { t: tNoPrefix } = useTranslation('translation')
  const { deal: { displayId, dealUuid, workingVersion } = {} } = useContext(DealContext)
  const { allowedOperations: covenantAllowedOperations = [] } = useContext(CovenantContext)
  const isWorkingVersion = useMemo(
    () => WorkingVersionType.WORKING_VERSION === workingVersion,
    [workingVersion],
  )

  const { covenantId } = useParams()

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)
  const toggleDeleteConfirmationBox = useCallback(
    (isOpen) => setIsDeleteConfirmationOpen(isOpen),
    [setIsDeleteConfirmationOpen],
  )

  const isAllowedToReadCovenant = covenantAllowedOperations.includes(
    covenantPermissions.readContractCovenant,
  )
  const isAllowedToDeleteCovenant = covenantAllowedOperations.includes(
    covenantPermissions.deleteContractCovenant,
  )
  const isAllowedToReadCovenantMonitoring = covenantAllowedOperations.includes(
    covenantPermissions.readCovenantContractMonitoring,
  )

  const {
    isFetching: isFetchingCovenant,
    isError: isErrorCovenant,
    data: {
      covenantDefinition: { name, type, configCovenantDefinitionUuid } = {},
      mainEntity: { id: mainEntityId, type: mainEntityType } = {},
    } = {},
  } = useCovenantById(covenantId)

  const {
    data: { covenantDefinitions = [] } = {},
    isLoading: isLoadingDefinitions,
    isError: isErrorDefinitions,
  } = useCovenantDefinitions()

  const currentCovenantDefinition = covenantDefinitions.find(
    ({ configCovenantDefinitionUuid: definitionUuid }) =>
      definitionUuid === configCovenantDefinitionUuid,
  )

  const isLoading = isFetchingCovenant || isLoadingDefinitions
  const isError = isErrorCovenant || isErrorDefinitions

  const isCovenantFinancial = type === 'FINANCIAL'
  const isCovenantInDeal = mainEntityId === dealUuid && mainEntityType === mainEntityTypes.DEAL

  const workingVersionSuffix = useMemo(
    () => (isWorkingVersion ? '?working-version=true' : ''),
    [isWorkingVersion],
  )

  const breadcrumbs = useMemo(
    () => [
      {
        text: t('subtitle'),
        href: `/${paths.deals}/${displayId}/covenants${workingVersionSuffix}`,
      },
      {
        text: name && isCovenantInDeal ? name : covenantId,
      },
    ],
    [t, displayId, workingVersionSuffix, name, isCovenantInDeal, covenantId],
  )

  const covenantDeleteAction = useMemo(
    () =>
      isAllowedToDeleteCovenant && (
        <Button
          key="delete"
          design={ButtonDesign.Transparent}
          id="delete-button"
          onClick={() => toggleDeleteConfirmationBox(true)}
        >
          {tNoPrefix('buttons.delete')}
        </Button>
      ),
    [isAllowedToDeleteCovenant, tNoPrefix, toggleDeleteConfirmationBox],
  )
  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [dealUuid],
  )

  const segmentActions = useMemo(
    () =>
      createSegmentedHeaderActions(
        [covenantDeleteAction],
        [<CreateDealEventAction key="create-event-action" />],
      ),
    [covenantDeleteAction],
  )

  const errorCard = (
    <Card>
      <ErrorDataUnavailableInContent />
    </Card>
  )

  const renderContent = () => {
    if (!isAllowedToReadCovenant) {
      return (
        <CenteredIllustratedMessage
          name="UnableToLoad"
          size="Spot"
          titleText={tNoPrefix('pages.deals.not-allowed.title')}
          subtitleText={tNoPrefix('pages.deals.not-allowed.subtitle')}
        />
      )
    }
    if (!isCovenantInDeal && !isLoading) {
      return (
        <IllustratedMessage
          name={IllustrationMessageType.NoData}
          titleText={tNoPrefix('components.cards.empty.title')}
          subtitleText={tNoPrefix('components.cards.empty.subtitle')}
        />
      )
    }

    return (
      <CWPLayout>
        <RequestStateResolver
          isLoading={isLoading}
          isError={isError}
          errorToDisplay={errorCard}
          renderContent={() => <CovenantGeneralInformationCard covenantId={covenantId} />}
        />
        {!isWorkingVersion && (
          <RequestStateResolver
            isLoading={isLoading}
            isError={isError}
            errorToDisplay={errorCard}
            renderContent={() =>
              isAllowedToReadCovenantMonitoring && (
                <CheckResultsCard preSelectedCovenantUuid={covenantId} dealUuid={dealUuid} />
              )
            }
          />
        )}
        <RequestStateResolver
          isLoading={isLoading}
          isError={isError}
          errorToDisplay={errorCard}
          renderContent={() =>
            isAllowedToReadCovenantMonitoring && (
              <MonitoringInformationCard
                covenantId={covenantId}
                configuration={currentCovenantDefinition}
              />
            )
          }
        />

        {isCovenantFinancial && (
          <CWPLayout.Half>
            <CovenantLimitCard
              covenantId={covenantId}
              cardType={CovenantLimitType.SoftLimit}
              title={t('soft-limits')}
              className={styles.marginBottom}
            />
            <CovenantLimitCard
              covenantId={covenantId}
              cardType={CovenantLimitType.HardLimit}
              title={t('hard-limits')}
            />
          </CWPLayout.Half>
        )}
        {!isCovenantFinancial && (
          <CovenantLimitCard
            covenantId={covenantId}
            cardType={CovenantLimitType.NonFinancialLimit}
            title={t('non-financial-limits')}
          />
        )}
        {!isWorkingVersion && (
          <RequestStateResolver
            isLoading={isLoading}
            isError={isError}
            errorToDisplay={errorCard}
            renderContent={() =>
              isAllowedToReadCovenantMonitoring && (
                <CWPLayout.Full>
                  <MonitoringItemsCard
                    covenantId={covenantId}
                    covenantMainEntityType={cwpEntityTypes.DEAL}
                    covenantHasDocumentRequirements={
                      currentCovenantDefinition?.withDocumentsRequest
                    }
                  />
                </CWPLayout.Full>
              )
            }
          />
        )}
      </CWPLayout>
    )
  }

  return (
    <DealDetailsWrapper
      childBreadcrumbs={breadcrumbs}
      actions={
        <>
          {segmentActions}, {markFavoriteAction}
        </>
      }
      showWVLink={false}
    >
      {renderContent()}
      <DeleteCovenantConfirmationMessageBox
        isOpen={isDeleteConfirmationOpen}
        toggleOpen={toggleDeleteConfirmationBox}
        dealDisplayId={displayId}
        covenantUuid={covenantId}
      />
    </DealDetailsWrapper>
  )
}

export default DealCovenantDetails
