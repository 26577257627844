import { Label, Text } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DualCurrency from 'components/domains/business-partners/dual-unit-value/DualCurrency'
import styles from 'components/domains/business-partners/tile/tenancy/as-branding-or-franchise-provider/AnnualizedContractedRentCell.module.css'
import { usePercentageFormatter } from 'hooks/i18n/useI18n'

const AnnualizedContractedRentCell = ({ value, currency, share }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.tenancy.as-branding-franchise-provider',
  })
  const formatPercent = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  if ((isNil(value) || isNil(currency)) && isNil(share)) return null

  return (
    <div>
      {!isNil(value) && !isNil(currency) && (
        <DualCurrency primaryCurrency={currency} primaryValue={value} />
      )}

      {!isNil(share) && (
        <div className={styles.keyValuePair}>
          <Label>{t('share')}</Label> <Text>{formatPercent(share)}</Text>
        </div>
      )}
    </div>
  )
}

AnnualizedContractedRentCell.propTypes = {
  value: PropTypes.number,
  currency: PropTypes.string,
  share: PropTypes.number,
}

export default AnnualizedContractedRentCell
