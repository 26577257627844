import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useAggregatedPortfolio = (dealUuid, options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/portfolio/aggregated`,
      useCache: true,
      keys: ['deals', dealUuid, 'portfolio', 'aggregated'],
      options: { enabled: !!dealUuid, ...options },
    }),
  )

export default useAggregatedPortfolio
