import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

export const useCreateCost = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()

  const getMoneyOrUndefinedIfNotSet = (value, currency) =>
    value && currency && !isNaN(value)
      ? {
          number: Number(value),
          currency: currency,
        }
      : undefined

  const { parse, localePattern } = useShortDateFormatter()
  return useMutation(
    async ({ propertyUuid, cost, currency }) => {
      const { data } = await post({
        path: `/properties/${propertyUuid}/costs`,
        body: {
          cost_type_code: cost.costTypeCode,
          cost_type_code_name: cost.costTypeCodeName,
          cost_description: cost.costDescription,
          cost_key_date: parse(cost.costKeyDate, localePattern),
          actual_cost_amount: getMoneyOrUndefinedIfNotSet(cost.actualCostAmount, currency),
        },
      })

      return data
    },
    { onSuccess, onError },
  )
}
