import { t } from 'i18next'
import isEmpty from 'lodash.isempty'
import { DateTime } from 'luxon'

const isANumber = (value) => /^-?\d+(\.\d+)?$/g.test(value)
const hasDecimalSeparator = (value) => /[.,]+/g.test(value)

const areConflictingRatings = (existingRating, newRating) => {
  const existingRatingValidFrom = DateTime.fromISO(existingRating.validFrom)
  const existingRatingValidTo = DateTime.fromISO(existingRating.validTo)
  const newRatingValidFrom = DateTime.fromISO(newRating.validFrom)
  const newRatingValidTo = DateTime.fromISO(newRating.validTo)

  const isNewRatingAfterExistingRating = newRatingValidFrom > existingRatingValidTo
  const isNewRatingBeforeExistingRating = newRatingValidTo < existingRatingValidFrom

  return !isNewRatingAfterExistingRating && !isNewRatingBeforeExistingRating
}

const isValidRatingGrade = ({ ratingGrade }) => {
  if (!isANumber(ratingGrade) || hasDecimalSeparator(ratingGrade)) return false
  const parsedGrade = parseInt(ratingGrade)
  return !!parsedGrade && parsedGrade > 0
}

const isValidToDateGreaterThanFromDate = (validFrom, validTo) => {
  const validFromDate = DateTime.fromISO(validFrom)
  const validToDate = DateTime.fromISO(validTo)
  return validFromDate < validToDate
}

const hasDateConflictWithExistingRatings = ({ existingRatings, newRating }) =>
  existingRatings.some((existingRating) => areConflictingRatings(existingRating, newRating))

/**
 * Returns the valid flag together with the error messages
 */
export const validateRating = ({ existingRatings = [], newRating = {} }) => {
  const errors = {}
  if (!newRating.methodId) errors.methodId = t('form.validation.required')
  if (!newRating.validFrom) errors.validFrom = t('form.validation.required')
  if (!newRating.validTo) errors.validTo = t('form.validation.required')

  if (!isANumber(newRating.pdPercentage)) errors.pdPercentage = t('form.validation.number')

  if (!newRating.ratingGrade || !isValidRatingGrade({ ratingGrade: newRating.ratingGrade })) {
    errors.ratingGrade = t('form.validation.positiveNumber')
  }

  if (newRating.validFrom && newRating.validTo) {
    if (!isValidToDateGreaterThanFromDate(newRating.validFrom, newRating.validTo)) {
      errors.validFrom = t('pages.business-partner.ratings.validation.valid-from-before-valid-to')
      errors.validTo = t('pages.business-partner.ratings.validation.valid-to-after-valid-from')
    }
  }

  if (
    newRating.methodId &&
    newRating.validFrom &&
    newRating.validTo &&
    hasDateConflictWithExistingRatings({ existingRatings, newRating })
  ) {
    errors.methodId = t('pages.business-partner.ratings.validation.conflicting-ratings')
    errors.validFrom = t('pages.business-partner.ratings.validation.conflicting-ratings')
    errors.validTo = t('pages.business-partner.ratings.validation.conflicting-ratings')
  }

  return {
    isValid: isEmpty(errors),
    errors,
  }
}
