import { Label, Text, WrappingType } from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/impairment-check/NumberQuestion.module.css'
import CagCurrencyInput from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/CurrencyQuestionCagCurrencyInput'

const propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isEditMode: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
}
/**
 * @typedef {object} overrides
 * @property {(newValue: string) => void} [onChange]
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 */
const CurrencyQuestion = ({ label, value, isEditMode, onChange }) => (
  <div className={compact([styles.question, isEditMode && styles.editMode]).join(' ')}>
    <Label className={styles.label} wrappingType={WrappingType.Normal}>
      {label}
    </Label>
    {!isEditMode && <Text className={styles.value}>{value}</Text>}
    {isEditMode && (
      <CagCurrencyInput
        className={styles.value}
        value={{ id: value }}
        onChange={(e) => onChange?.(e.detail.selectedOption.dataset.id ?? '')}
      />
    )}
  </div>
)

CurrencyQuestion.propTypes = propTypes

export default CurrencyQuestion
