import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { entityTypes } from 'api/events/status'
import styles from 'components/domains/business-events-and-tasks/BusinessObjectLink.module.css'
import LinkWithId from 'components/domains/business-events-and-tasks/LinkWithId'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import Entity from 'components/ui/data/Entity'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useDealMini from 'hooks/services/deals/useDealMini'
import paths from 'routes/paths'

const DealLink = ({
  dealUuid,
  customRender,
  errorCallback,
  CustomComponent,
  customComponentProperties,
}) => {
  const { t: t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.business-object-link',
  })
  const { data: deal, isLoading, isError } = useDealMini(dealUuid)

  useEffect(() => {
    if (isError) {
      errorCallback?.({ hasError: true, type: 'DEAL' })
    }
  }, [isError, errorCallback])

  const errorLabel = <div className={styles.errorLabel}>{t('error.description')}</div>

  const renderContent = () => {
    const isWorkingVersion = deal.workingVersion === WorkingVersionType.WORKING_VERSION
    const baseLink = `/${paths.deals}/${deal.dealId}`
    const link = isWorkingVersion ? `${baseLink}?working-version=true` : baseLink
    const name = isWorkingVersion ? t('deal.working-version', { dealName: deal.name }) : deal.name

    if (CustomComponent) {
      return (
        <CustomComponent
          {...customComponentProperties}
          entityType={entityTypes.deal}
          name={name}
          id={deal.dealId}
          link={link}
        />
      )
    }

    switch (customRender) {
      case 'SINGLE_LINE':
        return <LinkWithId href={link} name={name} id={deal.dealId} />

      case 'CLIPBOARD':
        return (
          <EntityTypeAndIdWithClipboard
            entityType={entityTypes.deal}
            name={name}
            id={deal.dealId}
          />
        )
      default:
        return <Entity name={name} link={link} id={deal.dealId} openInNewTab />
    }
  }

  if (isError && CustomComponent) {
    return <CustomComponent {...customComponentProperties} hasError />
  }

  return (
    <SmallLoadingWrapper
      isError={isError}
      isLoading={isLoading}
      error={errorLabel}
      renderContent={renderContent}
    />
  )
}

DealLink.propTypes = {
  dealUuid: PropTypes.string.isRequired,
  customRender: PropTypes.oneOf(['CLIPBOARD', 'SINGLE_LINE']),
  errorCallback: PropTypes.func,
  CustomComponent: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  customComponentProperties: PropTypes.object,
}

export default DealLink
