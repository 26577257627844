import PropTypes from 'prop-types'
import Header from 'components/ui/page/Header'
import Page from 'components/ui/page/Page'

const PropertyPage = ({
  children,
  pageTitle,
  subtitle,
  id,
  breadcrumbs,
  actions,
  status,
  additionalStatuses,
}) => (
  <Page
    renderHeaderTitle={() => (
      <Header
        title={pageTitle}
        id={id}
        subtitle={subtitle}
        breadcrumbs={breadcrumbs}
        actions={actions}
        status={status}
        additionalStatuses={additionalStatuses}
      />
    )}
    renderContent={() => children}
    isError={false}
    isLoading={false}
  />
)

PropertyPage.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  id: PropTypes.string,
  subtitle: PropTypes.any,
  children: PropTypes.any,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ),
  actions: PropTypes.element,
  status: PropTypes.shape({
    text: PropTypes.string.isRequired,
    valueState: PropTypes.string,
  }),
  additionalStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string.isRequired,
      valueState: PropTypes.string,
    }),
  ),
}
export default PropertyPage
