import { concat } from 'lodash'
import compact from 'lodash.compact'
import get from 'lodash.get'
import uniq from 'lodash.uniq'
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import useClassifyRentRollComparison from 'hooks/services/properties/kpis/comparison/useClassifyRentRollComparison'
import useMultiPropertySegmentKpis from 'hooks/services/properties/kpis/useMultiPropertySegmentKpis'
import usePortfolioUnits from 'hooks/services/properties/portfolio/usePortfolioUnits'
import { PropertyPortfolioContext } from 'routes/properties/portfolio/PropertyPortfolioContext'

export const usePortfolioRentRollAnalysisRows = ({ keyDate }) => {
  const { properties = [] } = useContext(PropertyPortfolioContext)

  const propertyUuids = properties.map((property) => property.uuid)

  const rentRollToCompare = useSelector(
    (state) => state.properties.compareRentRoll.rentRollToCompare,
  )

  const comparisonKeyDate = useMemo(() => rentRollToCompare?.keyDate, [rentRollToCompare])
  const comparisonValidFrom = useMemo(() => rentRollToCompare?.validFrom, [rentRollToCompare])

  const keyDates = compact(uniq([keyDate, comparisonKeyDate]))

  const keyDatesOrUndefined = keyDate ? keyDates : undefined

  const { isFetching: isFetchingPortfolioUnits, data: portfolioUnits } = usePortfolioUnits({
    properties,
    keyDatesWithValidFrom: [
      {
        keyDate: comparisonKeyDate,
        validFrom: comparisonValidFrom,
      },
    ],
    includeCurrentRentRoll: true,
  })

  const {
    isLoading: isLoadingKpis,
    isError: isErrorKpis,
    data: kpisData,
  } = useMultiPropertySegmentKpis(
    propertyUuids.map((uuid) => ({
      property_uuid: uuid,
      key_dates: keyDatesOrUndefined,
    })),
    {
      currency: portfolioUnits.currency,
      areaUnitOfMeasurement: portfolioUnits.uom,
      withTenantGranularity: true,
    },
  )

  const getSegmentKpisByRequestedKeyDate = (keyDateToSegmentKpis, requestedKeyDate) =>
    keyDateToSegmentKpis?.find((segmentKpis) => segmentKpis.requested_key_date === requestedKeyDate)
      ?.kpis

  const getTenantGranularityKpis = (propertyKpi, segmentKpi) =>
    segmentKpi?.tenant_rent_roll_kpis?.map((tenantKpi) => ({
      property_uuid: propertyKpi.property_uuid,
      segment_uuid: segmentKpi.segment_uuid,
      segment_name: segmentKpi.segment_name,
      segment_usage_type_code: segmentKpi.usage_type_code,
      segment_usage_type_name: segmentKpi.usage_type_name,
      ...tenantKpi,
    }))

  const getKpisByGivenKeyDate = (givenKeyDate) =>
    compact(
      kpisData?.flatMap((propertyKpi) =>
        getSegmentKpisByRequestedKeyDate(
          propertyKpi?.key_date_to_segment_rent_roll_kpis,
          givenKeyDate,
        )?.flatMap((segmentKpi) => getTenantGranularityKpis(propertyKpi, segmentKpi)),
      ) ?? [],
    )

  const currentKpisByPropertyAndTenantAndSegment = getKpisByGivenKeyDate(keyDate)
  const comparisonKpisByPropertyAndTenantAndSegment = getKpisByGivenKeyDate(comparisonKeyDate)

  const tenantIds = uniq(
    concat(
      currentKpisByPropertyAndTenantAndSegment.map((row) => row.tenant),
      comparisonKpisByPropertyAndTenantAndSegment.map((row) => row.tenant),
    ),
  )

  const findPropertyByUuid = (propertyUuid) =>
    properties.find((property) => property.uuid === propertyUuid)

  const findPropertyNameByUuid = (propertyUuid) =>
    findPropertyByUuid(propertyUuid)?.description ?? ''

  const findPropertyTypeNameByUuid = (propertyUuid) =>
    findPropertyByUuid(propertyUuid)?.type_name ?? ''

  const calculateSum = (toReduce, key) =>
    toReduce.reduce((acc, kpis) => acc + get(kpis, key) || 0, 0)

  const calculateShare = (kpis, row, accessor) => {
    const currentValue = get(row, accessor)
    const surfaceSum = calculateSum(kpis, accessor)
    if (!surfaceSum) {
      return 0
    }
    return (currentValue || 0) / surfaceSum
  }

  const convertKpisToRows = (tenantAndSegmentAndKpis) =>
    tenantAndSegmentAndKpis?.map((tenantAndSegmentAndKpi) => ({
      property: {
        uuid: tenantAndSegmentAndKpi?.property_uuid,
        name: findPropertyNameByUuid(tenantAndSegmentAndKpi?.property_uuid),
        typeName: findPropertyTypeNameByUuid(tenantAndSegmentAndKpi?.property_uuid),
      },
      tenant: {
        id: tenantAndSegmentAndKpi?.tenant,
        anonymous: tenantAndSegmentAndKpi?.tenant === '',
        segmentUuid: tenantAndSegmentAndKpi?.segment_uuid,
        segmentName: tenantAndSegmentAndKpi?.segment_name,
        usageType: tenantAndSegmentAndKpi?.segment_usage_type_name,
      },
      rentalUnits: {
        count: tenantAndSegmentAndKpi?.total_number_of_rental_units,
      },
      totalArea: {
        surface: tenantAndSegmentAndKpi?.total_area_surface,
        share: calculateShare(
          tenantAndSegmentAndKpis,
          tenantAndSegmentAndKpi,
          'total_area_surface.value',
        ),
        number_of_units: tenantAndSegmentAndKpi?.total_number_of_units,
      },
      annualizedCurrentRent: {
        value: tenantAndSegmentAndKpi?.annualized_current_rent,
        share: calculateShare(
          tenantAndSegmentAndKpis,
          tenantAndSegmentAndKpi,
          'annualized_current_rent.number',
        ),
        per_uom: tenantAndSegmentAndKpi?.annualized_current_rent_per_uom,
        per_pieces: tenantAndSegmentAndKpi?.annualized_current_rent_per_pieces,
      },
      wault: {
        expiry: tenantAndSegmentAndKpi?.wault_to_expiry_in_years,
        break: tenantAndSegmentAndKpi?.wault_to_break_in_years,
      },
    })) ?? []

  const {
    isLoading: isLoadingClassification,
    isError: isErrorClassification,
    data: classifiedComparisonData,
  } = useClassifyRentRollComparison({
    propertyUuids: propertyUuids,
    tenantIds: tenantIds,
    currentKeyDate: keyDate,
    comparisonKeyDate: comparisonKeyDate,
    kpis: {
      current: convertKpisToRows(currentKpisByPropertyAndTenantAndSegment),
      comparison: convertKpisToRows(comparisonKpisByPropertyAndTenantAndSegment),
    },
  })

  const isLoading = isLoadingClassification || isLoadingKpis || isFetchingPortfolioUnits
  const isError = isErrorClassification || isErrorKpis

  if (isLoading || isError) {
    return {
      isLoading,
      isError,
      data: [],
    }
  } else {
    return {
      isLoading: false,
      isError: false,
      data: classifiedComparisonData,
    }
  }
}
