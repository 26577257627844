const mapActiveCustomerOptionsBackendData = (backendTableData) => {
  const activeCustomerOptions = []

  for (const [, clusterRow] of Object.entries(backendTableData)) {
    const rowCopy = { ...clusterRow }
    if (clusterRow.subRows) {
      const activeOptions = rowCopy.subRows.filter((option) => option.isActive)

      if (activeOptions.length > 0) {
        rowCopy.subRows = activeOptions
        activeCustomerOptions.push(rowCopy)
      }
    }
  }

  return activeCustomerOptions
}

export default mapActiveCustomerOptionsBackendData
