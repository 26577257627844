import { AnalyticalTableScaleWidthMode, IllustratedMessage } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import { useContext, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import mapAsSponsorCardTableData from 'components/domains/business-partners/tile/sponsor/mapAsSponsorCardTableData'
import useAsSponsorCardTableColumnDefinitions from 'components/domains/business-partners/tile/sponsor/useAsSponsorCardTableColumnDefinitions'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import useDealSponsorOverview from 'hooks/services/deals/sponsor/useDealSponsorOverview'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

const AsSponsorCardTable = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.sponsor.table',
  })

  const businessPartnerId = useContext(BusinessPartnerContext)?.id
  const {
    data: { dealSponsors } = {},
    isLoading,
    isError,
  } = useDealSponsorOverview(businessPartnerId) || {}

  const tableRef = useRef(null)

  const columnDefinitions = useAsSponsorCardTableColumnDefinitions()

  const tableData = useMemo(() => {
    if (!isEmpty(dealSponsors)) {
      return mapAsSponsorCardTableData(dealSponsors)
    } else return []
  }, [dealSponsors])

  const NoDataComponent = () => (
    <LoadingStateWrapper isError={isError} isLoading={isLoading}>
      <IllustratedMessage
        name="NoData"
        titleText={t('no-data-title')}
        subtitleText={t('no-data-text')}
      />
    </LoadingStateWrapper>
  )

  return (
    <AnalyticalTableWithToolbar
      title={t('title')}
      id="as-sponsor-table"
      nrOfEntries={tableData?.length ?? 0}
      columns={columnDefinitions}
      data={tableData}
      minRows={0}
      rowHeight={50}
      headerRowHeight={30}
      visibleRows={99}
      overscanCountHorizontal={99}
      isTreeTable={false}
      withRowHighlight={false}
      groupable={true}
      sortable={true}
      disableColumnPopover={true}
      ref={tableRef}
      scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
      NoDataComponent={NoDataComponent}
      reactTableOptions={{
        autoResetPage: false,
        autoResetExpanded: false,
        autoResetSortBy: false,
        autoResetSelectedRows: false,
      }}
    />
  )
}

export default AsSponsorCardTable
