import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import {
  neededOperationsForCustomAssessmentRead,
  neededOperationsForCustomAssessmentUpdate,
} from 'api/central-data/centralDataAllowedOperations'
import { hasUserRequiredOperations } from 'api/helper'
import useCustomAssessments from 'hooks/services/central-data/custom-assessments/useCustomAssessments'
import useCentralDataAllowedOperations from 'hooks/services/central-data/useCentralDataAllowedOperations'

const useCustomAssessmentsHelper = ({ entityType, page }) => {
  const {
    data: centralDataAllowedOperationsData,
    isFetching: isCentralDataAllowedOperationsFetching,
    isLoading: isCentrslDataAllowedOperationsLoading,
    isError: isCentralDataAllowedOperationsError,
  } = useCentralDataAllowedOperations()

  const centralDataAllowedOperations = useMemo(
    () => centralDataAllowedOperationsData?.allowedOperations ?? [],
    [centralDataAllowedOperationsData],
  )

  const {
    isFetching: isCustomAssessmentsFetching,
    isLoading: isCustomAssementsLoading,
    isError: isCustomAssessmentsError,
    data: customAssessments,
  } = useCustomAssessments({ entityType, page })

  const isReadCustomAssessmentsAllowed = hasUserRequiredOperations(
    neededOperationsForCustomAssessmentRead,
    centralDataAllowedOperations,
  )
  const isUpdateCustomAssessmentsAllowed = hasUserRequiredOperations(
    neededOperationsForCustomAssessmentUpdate,
    centralDataAllowedOperations,
  )

  const showCustomAssessments = useMemo(
    () =>
      !(isEmpty(customAssessments?.customAssessmentCodes) && !isCustomAssessmentsFetching) &&
      isReadCustomAssessmentsAllowed,
    [
      customAssessments?.customAssessmentCodes,
      isCustomAssessmentsFetching,
      isReadCustomAssessmentsAllowed,
    ],
  )

  return useMemo(
    () => ({
      isFetching: isCustomAssessmentsFetching || isCentralDataAllowedOperationsFetching,
      isError: isCentralDataAllowedOperationsError || isCustomAssessmentsError,
      isLoading: isCentrslDataAllowedOperationsLoading || isCustomAssementsLoading,
      isReadCustomAssessmentsAllowed,
      isUpdateCustomAssessmentsAllowed,
      showCustomAssessments,
      customAssessments: customAssessments?.customAssessmentCodes || [],
    }),
    [
      isCustomAssessmentsFetching,
      isCentralDataAllowedOperationsFetching,
      isCentralDataAllowedOperationsError,
      isCustomAssessmentsError,
      isCentrslDataAllowedOperationsLoading,
      isCustomAssementsLoading,
      isReadCustomAssessmentsAllowed,
      isUpdateCustomAssessmentsAllowed,
      showCustomAssessments,
      customAssessments?.customAssessmentCodes,
    ],
  )
}

export default useCustomAssessmentsHelper
