import { Button, CardHeader, FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import CommentField from 'components/domains/business-events-and-tasks/events/comment-section/CommentField'
import styles from 'components/domains/business-events-and-tasks/events/comment-section/CommentSection.module.css'
import NewCommentField from 'components/domains/business-events-and-tasks/events/comment-section/NewCommentField'
import Card from 'components/ui/card/Card'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const minComments = 10

const CommentSection = ({
  disabled = false,
  eventId,
  taskId,
  comments,
  onLoadMoreComments,
  maxCommentsCount,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.comment-section' })
  const { allowedOperations } = useContext(BusinessEventsAndTasksContext)

  const hasUpdateCommentPermission =
    (taskId && allowedOperations?.includes(businessEventAllowedOperations.updateTask)) ||
    (!taskId && allowedOperations?.includes(businessEventAllowedOperations.updateEvent))

  const commentComponents = comments.map((comment, index) => (
    <CommentField
      key={`Comment-Field-${index}`}
      eventId={eventId}
      taskId={taskId}
      commentId={comment.id}
      ownerId={comment.ownerId}
      commentText={comment.comment}
      creationTime={comment.creationTime}
      lastModifiedAt={comment.lastModifiedAt}
      hasUpdateCommentPermission={hasUpdateCommentPermission}
    />
  ))

  return (
    <Card
      header={
        <CardHeader
          titleText={t('comments.title', {
            maxCommentsCount: maxCommentsCount,
          })}
        />
      }
      className={disabled ? styles.disabled : ''}
      {...(!!disabled && { inert: '' })}
    >
      <FlexBox className={styles.commentWrapper} direction={FlexBoxDirection.Column}>
        {hasUpdateCommentPermission && <NewCommentField eventId={eventId} taskId={taskId} />}
        {commentComponents}
      </FlexBox>
      {comments.length >= minComments && (
        <Button
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          onClick={() => onLoadMoreComments()}
          disabled={comments.length === maxCommentsCount}
        >
          {`${t('more')} [${comments.length} / ${maxCommentsCount}]`}
        </Button>
      )}
    </Card>
  )
}

CommentSection.propTypes = {
  disabled: PropTypes.bool,
  eventId: PropTypes.string,
  taskId: PropTypes.string,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      comment: PropTypes.string.isRequired,
      creationTime: PropTypes.string.isRequired,
      ownerId: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onLoadMoreComments: PropTypes.func.isRequired,
  maxCommentsCount: PropTypes.number,
}

export default CommentSection
