import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useIrrefutableForbearanceMeasureOptions = () => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix:
      'decision-paper.forbearance-and-utp-questionare.options.irrefutable-forbearance-measure',
  })

  return useMemo(
    () => [
      {
        key: '-',
        value: '-',
        displayValue: t('empty-value'),
      },
      {
        key: 'optionOne',
        value: 'optionOne',
        displayValue: t('option-one'),
      },
      {
        key: 'optionTwo',
        value: 'optionTwo',
        displayValue: t('option-two'),
      },
      {
        key: 'optionThree',
        value: 'optionThree',
        displayValue: t('option-three'),
      },
      {
        key: 'optionFour',
        value: 'optionFour',
        displayValue: t('option-four'),
      },
      {
        key: 'no',
        value: 'no',
        displayValue: t('no'),
      },
    ],
    [t],
  )
}

export default useIrrefutableForbearanceMeasureOptions
