import { Input } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { valuationClassificationTranslated } from 'api/property/valuation/valuationRequests'
import { convertScreamingSnakeCaseToTitle } from 'components/domains/properties/valuation/helper/convertScreamingSnakeCaseToTitle'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import TextCell from 'components/ui/tables/cells/TextCell'
import { useGenericValuationCreateMethodCodes } from 'hooks/services/properties/valuations/useGenericValuationCreateMethodCodes'

const PropertyValuationResultsValuationTypeCell = ({ valuationType, isAddMode }) => {
  const { t } = useTranslation()
  const currentValues = useSelector(
    (state) => state.properties.valuationResults.editedRow?.currentValues,
  )
  const {
    data: valuationCreateMethodCodesResult = [],
    isLoading,
    isError,
  } = useGenericValuationCreateMethodCodes({
    enabled: isAddMode,
  })

  const calculationMethodCode = valuationCreateMethodCodesResult?.find(
    ({ key }) => key === currentValues?.calculationMethodCode,
  )?.create_method_code

  const getTranslatedValuationType = (type) => {
    const translationKey = valuationClassificationTranslated?.[type]
    if (!translationKey) {
      return convertScreamingSnakeCaseToTitle(type)
    }
    return t(translationKey)
  }

  if (!isAddMode) {
    return <TextCell text={getTranslatedValuationType(valuationType)} />
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={() => (
        <Input readonly value={getTranslatedValuationType(calculationMethodCode)} />
      )}
    />
  )
}

PropertyValuationResultsValuationTypeCell.propTypes = {
  valuationType: PropTypes.string,
  isAddMode: PropTypes.bool.isRequired,
}

export default PropertyValuationResultsValuationTypeCell
