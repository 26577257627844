import { Link, Text } from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const defaultMaxTextDisplaySize = 400

const ShortenedPlainText = ({ text, maxTextDisplaySize = defaultMaxTextDisplaySize }) => {
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState(false)
  const trimmedText = useMemo(() => text.trim(), [text])

  const splitTextLines = useCallback((descriptionToSplit) => descriptionToSplit.split('\n'), [])
  const textLines = useMemo(() => splitTextLines(trimmedText), [trimmedText, splitTextLines])

  const shortenText = useCallback(
    (textToShorten) => textToShorten.substring(0, maxTextDisplaySize).trim(),
    [maxTextDisplaySize],
  )

  const shortenedText = useMemo(
    () => shortenText(textLines.slice(0, 2).join('\n')),
    [textLines, shortenText],
  )

  const toggleMoreButton = useCallback(
    (event) => {
      event.preventDefault()
      setShowMore(!showMore)
    },
    [showMore],
  )

  if (showMore || (textLines.length < 2 && textLines[0].length <= maxTextDisplaySize)) {
    return (
      <Text>
        {trimmedText}
        {showMore && (
          <Link href="" onClick={toggleMoreButton}>
            {t('buttons.less')}
          </Link>
        )}
      </Text>
    )
  }

  return (
    <Text>
      {shortenedText}
      {'... '}
      <Link href="" onClick={toggleMoreButton}>
        {t('buttons.more')}
      </Link>
    </Text>
  )
}

ShortenedPlainText.propTypes = {
  text: PropType.string.isRequired,
  maxTextDisplaySize: PropType.number,
}

export default ShortenedPlainText
