import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useCreateDeal = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()

  return useMutation(
    async (deal) => {
      const transformedDeal = {
        ...deal,
        dealTeam: {
          staffMembers: [
            {
              userId: deal.dealTeam.staffMembers[0].staffMember.id,
              relation: deal.dealTeam.staffMembers[0].relation,
              leader: deal.dealTeam.staffMembers[0].leader,
            },
          ],
        },
      }
      const { data } = await post({
        path: `/deals`,
        body: snakecaseKeys(transformedDeal, { deep: true }),
      })
      return data
    },
    { onSuccess, onError },
  )
}
