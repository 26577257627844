import PropTypes from 'prop-types'
import { MapContainer } from 'react-leaflet'
import styles from 'components/ui/map/Leaflet.module.css'
import 'components/ui/map/LeafletPopupStyle.css'
import {
  getBoundingBoxCenter,
  getBounds,
  polygonsToCoordArray,
} from 'components/ui/map/MapPolygonUtils'
import LeafletTileLayerWrapper from 'components/ui/map/internal/LeafletTileLayerWrapper'

export const DEFAULT_INITIAL_ZOOM_LEVEL = 15

const LeafletMapWithPolygonAndPropertyMarkers = ({
  latitude,
  longitude,
  color,
  polygon = [[[]]],
  initialZoomLevel = DEFAULT_INITIAL_ZOOM_LEVEL,
  markers,
  disabled,
  MarkerPopup,
}) => {
  let bounds = []
  let center = [latitude ?? 0, longitude ?? 0]
  if (polygon && polygon[0][0].length !== 0) {
    const coordArray = polygonsToCoordArray(polygon)
    if (!latitude || !longitude) {
      center = getBoundingBoxCenter(coordArray)
    }
    bounds = getBounds(coordArray)
  }

  return (
    <MapContainer
      className={styles.leafletContainer}
      center={center}
      zoom={initialZoomLevel}
      scrollWheelZoom={true}
    >
      <LeafletTileLayerWrapper
        color={color}
        polygon={polygon}
        markers={markers}
        center={center}
        bounds={bounds}
        disabled={disabled}
        MarkerPopup={MarkerPopup}
      />
    </MapContainer>
  )
}

LeafletMapWithPolygonAndPropertyMarkers.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  color: PropTypes.object,
  polygon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  initialZoomLevel: PropTypes.number,
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      address: PropTypes.exact({
        country: PropTypes.string,
        street: PropTypes.string,
        houseNumber: PropTypes.string,
        zipCode: PropTypes.string,
        city: PropTypes.string,
      }),
      propertyType: PropTypes.string,
      location: PropTypes.object,
      color: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
  MarkerPopup: PropTypes.func,
}

export default LeafletMapWithPolygonAndPropertyMarkers
