import { Modals } from '@fioneer/ui5-webcomponents-react/dist'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import useCreateNewMajorVersion from 'hooks/services/business-events-and-tasks/decision-papers/useCreateNewMajorVersion'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionPaperNewVersionDialog = ({ isOpen, setIsOpen, setIsCreatingVersionLoading }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper.new-major-version',
  })
  const queryClient = useQueryClient()
  const [, setQueryParams] = useSearchParams()

  const createNewMajorVersionMutation = useCreateNewMajorVersion()
  const { id: eventId } = useContext(BusinessEventsAndTasksContext).event

  const showToast = Modals.useShowToast()
  const showMessageBox = useShowMessageBox()

  const onNewVersionSuccess = useCallback(() => {
    setIsOpen(false)
    setQueryParams('') // remove all query params, so that current version gets loaded
    queryClient.invalidateQueries(['events', eventId])
    setIsCreatingVersionLoading(false)
    showToast({ children: t('new-major-version.success') })
  }, [eventId, queryClient, setIsCreatingVersionLoading, setIsOpen, setQueryParams, showToast, t])

  const onNewVersionError = useCallback(() => {
    setIsCreatingVersionLoading(false)
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('error'),
      actions: [MessageBoxActions.Close],
      emphasizedAction: MessageBoxActions.Close,
    })
  }, [setIsCreatingVersionLoading, showMessageBox, t])

  const handleOnCreate = useCallback(() => {
    setIsCreatingVersionLoading(true)
    createNewMajorVersionMutation.mutate(
      { eventId },
      {
        onSuccess: onNewVersionSuccess,
        onError: onNewVersionError,
      },
    )
  }, [
    createNewMajorVersionMutation,
    eventId,
    onNewVersionError,
    onNewVersionSuccess,
    setIsCreatingVersionLoading,
  ])

  const handleConfirmationCancel = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleConfirmation={handleOnCreate}
      handleCancel={handleConfirmationCancel}
      confirmationMessage={t('confirmation-message.description')}
      confirmationTitle={t('confirmation-message.title')}
      confirmButtonText={t('buttons.confirm')}
      draggable
      resizable
    />
  )
}

DecisionPaperNewVersionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setIsCreatingVersionLoading: PropTypes.func.isRequired,
}

export default DecisionPaperNewVersionDialog
