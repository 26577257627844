import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import DecisionProcessAlcBooleanField from 'components/domains/business-events-and-tasks/decision-process/alc/helper/DecisionProcessAlcBooleanField'
import DecisionProcessLabeledLoadingContent from 'components/domains/business-events-and-tasks/decision-process/alc/helper/DecisionProcessLabeledLoadingContent'
import useAuthorityLevelCalculatorAuthorityLevel from 'hooks/services/business-partners/authority-level-calculator/useAuthorityLevelCalculatorAuthorityLevel'

const DecisionProcessAlcOrganCredit = ({ eventId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.overview.alc-info.dialog',
  })
  const authorityLevelResponse = useAuthorityLevelCalculatorAuthorityLevel({ eventId })
  const renderOrganCredit = useCallback(
    (authorityLevel) => <DecisionProcessAlcBooleanField value={authorityLevel.isOrgankredit} />,
    [],
  )
  return (
    <DecisionProcessLabeledLoadingContent
      response={authorityLevelResponse}
      renderCallback={renderOrganCredit}
      label={t('fields.organ-credit')}
    />
  )
}

DecisionProcessAlcOrganCredit.propTypes = {
  eventId: PropTypes.string.isRequired,
}

export default DecisionProcessAlcOrganCredit
