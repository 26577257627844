import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/markets/detail/cards/MarketDescriptionCard.module.css'
import DisplayCardView from 'components/ui/card/DisplayCardView'
import EditCardView from 'components/ui/card/EditCardView'
import EmptyCard from 'components/ui/card/EmptyCard'
import ErrorDialog from 'components/ui/dialog/ErrorDialog'
import CollapsibleRichTextView from 'components/ui/rich-text/CollapsibleRichTextView'
import CWPCKEditor from 'components/ui/rich-text-editor/CWPCKEditor'
import useEditMarketInformation from 'hooks/services/markets/useEditMarketInformation'

const MarketDescriptionCard = ({ marketId, marketDescription, showEditButton }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.markets.detail.cards.description',
  })
  const { t: tWithoutPrefix } = useTranslation()
  const queryClient = useQueryClient()
  const [editedMarketDescription, setEditedMarketDescription] = useState(marketDescription)
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const { mutate: editMarket } = useEditMarketInformation()

  const onEditClicked = useCallback(() => {
    setIsEditModeEnabled(true)
  }, [])

  const onCancelClicked = useCallback(() => {
    setIsEditModeEnabled(false)
    setEditedMarketDescription(marketDescription)
  }, [marketDescription])

  const onSaveSuccess = useCallback(() => {
    queryClient.invalidateQueries(['markets'])
    setIsEditModeEnabled(false)
  }, [queryClient])

  const onSaveError = useCallback(() => {
    setIsErrorDialogOpen(true)
  }, [])

  const onSaveClicked = useCallback(() => {
    editMarket(
      {
        marketId,
        marketDescription: editedMarketDescription === '' ? null : editedMarketDescription,
      },
      {
        onSuccess: onSaveSuccess,
        onError: onSaveError,
      },
    )
  }, [editMarket, editedMarketDescription, marketId, onSaveError, onSaveSuccess])

  const onRetryClicked = useCallback(() => {
    setIsErrorDialogOpen(false)
    onSaveClicked()
  }, [onSaveClicked])

  const displayView = CollapsibleRichTextView({
    text: marketDescription,
  })

  const renderEditView = useCallback(
    () => (
      <div className={`${styles.descriptionCardEditContent} ${styles.editor}`}>
        <CWPCKEditor
          setCurrentContent={(content) => {
            setEditedMarketDescription(content)
          }}
          currentContent={editedMarketDescription}
        />
      </div>
    ),
    [editedMarketDescription],
  )

  const marketDescriptionFieldDefinitions = [
    {
      customInfoComponent: displayView,
      renderCustomEditComponent: renderEditView,
    },
  ]

  const isSaveButtonEnabled = marketDescription !== editedMarketDescription

  if (isEditModeEnabled) {
    return (
      <>
        <EditCardView
          cardHeaderTitle={t('title')}
          fieldDefinitions={marketDescriptionFieldDefinitions}
          onSaveClicked={onSaveClicked}
          onCancelClicked={onCancelClicked}
          setEditModeHasChanges={() => {}}
          editModeHasChanges={isSaveButtonEnabled}
        />
        {isErrorDialogOpen && (
          <ErrorDialog
            isOpen={isErrorDialogOpen}
            setIsOpen={setIsErrorDialogOpen}
            texts={{
              retryButton: tWithoutPrefix('buttons.try-again'),
              cancelButton: tWithoutPrefix('buttons.cancel'),
              title: t('error'),
              description: t('error.description'),
            }}
            onRetry={onRetryClicked}
          />
        )}
      </>
    )
  }

  if (!marketDescription) {
    return (
      <EmptyCard
        contentClassName={styles.descriptionCardEmptyContent}
        cardHeaderTitle={t('title')}
        onEditClicked={onEditClicked}
        showCardHeaderActions={showEditButton}
      />
    )
  }

  return (
    <DisplayCardView
      className={styles.descriptionCard}
      cardHeaderTitle={t('title')}
      fieldDefinitions={marketDescriptionFieldDefinitions}
      setEditMode={onEditClicked}
      isEditable={showEditButton}
    />
  )
}

MarketDescriptionCard.propTypes = {
  marketId: PropTypes.string.isRequired,
  marketDescription: PropTypes.string.isRequired,
  showEditButton: PropTypes.bool.isRequired,
}

export default MarketDescriptionCard
