import { Toolbar, ToolbarDesign, ToolbarStyle } from '@fioneer/ui5-webcomponents-react'
import { PropTypes } from 'prop-types'
import styles from 'components/domains/properties/modular-table/toolbar/ModularToolbar.module.css'

const ModularToolbar = ({ children }) => (
  <Toolbar className={styles.toolbar} design={ToolbarDesign.Auto} toolbarStyle={ToolbarStyle.Clear}>
    {children}
  </Toolbar>
)

ModularToolbar.propTypes = {
  children: PropTypes.node,
}

export default ModularToolbar
