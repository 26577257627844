import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

/**
 * Retrieve property data for each dealUuid provided
 * @param dealUuids An array of dealUuids to individually get the properties from
 * @param options
 * @returns {(*&{data: {dealUuid: *}})[]}
 */
const useMultipleDealProperties = ({ dealUuids, options = {} }) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: dealUuids.map((dealUuid) => ({
        path: `/deals/${dealUuid}/properties`,
        keys: ['deals', dealUuid, 'properties'],
      })),
      translated: true,
      useCache: true,
      options: { enabled: !isEmpty(dealUuids), ...options },
    }),
  )

  return useMemo(
    () =>
      responses.map((result, index) => ({
        ...result,
        data: { dealUuid: dealUuids[index], ...camelize(result.data) },
      })),
    [dealUuids, responses],
  )
}

export default useMultipleDealProperties
