import { FlexBox, FlexBoxAlignItems, ObjectStatus } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  decisionProcessMinutesDecisionStatus,
  getObjectStatusforDecisionStageMinutesStatus,
} from 'api/decision-process/decisionProcessMinutesApi'
import DisplayCardView from 'components/ui/card/DisplayCardView'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const DecisionStageMinutesInformationCard = ({ decisionDate, decisionStatus }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.minutes.info-card',
  })
  const { t: tNoPrefix } = useTranslation()
  const { format: formatDate } = useShortDateFormatter()

  const formattedVoteDate = useMemo(() => {
    if (!decisionDate) {
      return '-'
    }
    return formatDate(decisionDate)
  }, [decisionDate, formatDate])

  const renderedMinutesStatus = useMemo(() => {
    const { objectStatus, translationKey } =
      getObjectStatusforDecisionStageMinutesStatus(decisionStatus)

    return (
      <FlexBox alignItems={FlexBoxAlignItems.Start}>
        <ObjectStatus inverted state={objectStatus}>
          {tNoPrefix(translationKey)}
        </ObjectStatus>
      </FlexBox>
    )
  }, [decisionStatus, tNoPrefix])

  const fieldDefinitions = useMemo(
    () => [
      {
        label: t('label.approval-status'),
        name: 'approvalStatus',
        value: renderedMinutesStatus,
        isShownInDisplay: true,
      },
      {
        label: t('label.approval-date'),
        name: 'approvalDate',
        value: formattedVoteDate,
        isShownInDisplay: true,
      },
    ],
    [formattedVoteDate, renderedMinutesStatus, t],
  )

  return (
    <DisplayCardView
      fieldDefinitions={fieldDefinitions}
      cardHeaderTitle={t('title')}
      isEditable={false}
      setEditMode={() => {}}
    />
  )
}

DecisionStageMinutesInformationCard.propTypes = {
  decisionStatus: PropTypes.oneOf(Object.values(decisionProcessMinutesDecisionStatus)).isRequired,
  decisionDate: PropTypes.string,
}

export default DecisionStageMinutesInformationCard
