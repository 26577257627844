import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const FINANCIAL = 'FINANCIAL'

const useCheckResultsChartData = ({ covenantMonitoringItems = [], selectedCovenantUuid }) => {
  const { t: tNoPrefix } = useTranslation('decisionPaper')

  return useMemo(() => {
    const financialItems = covenantMonitoringItems.filter(
      ({ covenant }) => covenant?.type === FINANCIAL,
    )

    const filteredItemsByCovenantUuid = financialItems
      .filter(({ covenant }) => covenant?.covenantUuid === selectedCovenantUuid)
      .sort(({ dueDate: a }, { dueDate: b }) => new Date(a?.keyDate) - new Date(b?.keyDate))

    const monitoringItemWithName = filteredItemsByCovenantUuid.find(
      ({ covenant }) => !!covenant.name,
    )
    const unit =
      monitoringItemWithName?.softLimit?.unit ??
      monitoringItemWithName?.hardLimit?.unit ??
      monitoringItemWithName?.result?.unit

    const getDisplayUnit = (limit) =>
      limit?.unit === 'PERCENT' ? tNoPrefix('unit.percent') : limit?.currencyCode

    const displayUnit =
      getDisplayUnit(monitoringItemWithName?.softLimit) ??
      getDisplayUnit(monitoringItemWithName?.hardLimit)

    const chartData = filteredItemsByCovenantUuid.map(
      ({ covenantMonitoringItemUuid, hardLimit, softLimit, result, dueDate, status }) => ({
        covenantMonitoringItemUuid,
        keyDate: new Date(dueDate?.keyDate).valueOf(),
        hardLimit: hardLimit?.value,
        softLimit: softLimit?.value,
        result: result?.value,
        unit: unit,
        currencyCode: result?.currencyCode,
        status: status,
      }),
    )

    const lastItemWithResult = filteredItemsByCovenantUuid
      .filter(({ result }) => !!result?.value)
      .at(-1)

    return {
      financialMonitoringItems: financialItems,
      lastMonitoringItemWithResult: lastItemWithResult,
      name: monitoringItemWithName?.covenant?.name,
      displayUnit: displayUnit,
      chartData,
    }
  }, [covenantMonitoringItems, selectedCovenantUuid, tNoPrefix])
}

export default useCheckResultsChartData
