import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateDecisionSetup = () => {
  const { post } = useAccessTokenRequest()

  return useMutation(({ eventId, setupTemplateId }) =>
    post({
      path: `/events/${eventId}/decision-stages`,
      body: {
        decision_setup_template_id: setupTemplateId,
      },
    }),
  )
}

export default useCreateDecisionSetup
