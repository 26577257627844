import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react/dist'
import PropTypes from 'prop-types'
import { useState } from 'react'
import DecisionPaperAttachmentsPopover from 'routes/business-events-and-tasks/decision-paper/attachments/DecisionPaperAttachmentsPopover'

const openerId = 'attachments-popover'

const DecisionPaperAttachments = ({ handleExportClick, isEditView, decisionPaperVersion }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <>
      <Button
        id={openerId}
        design={ButtonDesign.Transparent}
        onClick={() => setIsPopoverOpen(true)}
        icon="overflow"
      />
      <DecisionPaperAttachmentsPopover
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        openerId={openerId}
        handleExportClick={handleExportClick}
        isEditView={isEditView}
        decisionPaperVersion={decisionPaperVersion}
      />
    </>
  )
}

DecisionPaperAttachments.propTypes = {
  handleExportClick: PropTypes.func.isRequired,
  decisionPaperVersion: PropTypes.string,
  isEditView: PropTypes.bool,
}

export default DecisionPaperAttachments
