import {
  DynamicPageTitle,
  Title,
  Label,
  Breadcrumbs,
  BreadcrumbsItem,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from 'components/ui/page/Header.module.css'

/**
 * @param {string} title the entity's name
 * @param {string} id the entity's ID
 * @param {element} subHeader subHeader item
 * @param {element} actions action items
 * @param {[{text: string, href: string?}]} breadcrumbs breadcrumb items
 * @param {children} children
 * @param {string} className
 */
const HeaderWithContent = React.forwardRef(
  ({ title, id, subHeader, breadcrumbs = [], actions, className, children }, _ref) => {
    const navigate = useNavigate()
    if (breadcrumbs.length) delete breadcrumbs.at(-1).href
    return (
      <DynamicPageTitle
        id={styles['header']}
        header={<Title level={TitleLevel.H1}>{title}</Title>}
        breadcrumbs={
          <Breadcrumbs
            onItemClick={(e) => {
              e.preventDefault()
              const href = e.detail.item.href
              if (href) navigate(e.detail.item.href)
            }}
            className={styles.breadcrumbs}
          >
            {breadcrumbs.map(({ text, href }, idx) => (
              <BreadcrumbsItem key={idx} href={href}>
                {text}
              </BreadcrumbsItem>
            ))}
          </Breadcrumbs>
        }
        subHeader={subHeader ? subHeader : <Label className={styles['header-id']}>{id}</Label>}
        actions={actions}
        className={className}
      >
        {children}
      </DynamicPageTitle>
    )
  },
)

HeaderWithContent.displayName = 'HeaderWithContent'
HeaderWithContent.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string,
  subHeader: PropTypes.element,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ),
  actions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default HeaderWithContent
