import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Card from 'components/ui/card/Card'
import CardHeaderWithMetrics from 'components/ui/card/CardHeaderWithMetrics'
import usePropertyRentRollComparisonMetric from 'hooks/services/properties/kpis/comparison/usePropertyRentRollComparisonMetric'

export const NO_CONTENT_PLACEHOLDER = '-'

/**
 * @typedef {import('react').ReactNode } ReactNode
 *
 * @typedef {object} PropertyRentRollLiveKpisCardProps
 * @property {ReactNode} title the card title text
 * @property {object} workingVersionMetric
 * @property {ReactNode} [workingVersionMetric.unit] the unit displayed above the metric after subtitle
 * @property {ReactNode} [workingVersionMetric.value] the formatted working version metric value
 * @property {ReactNode} currentMetricValue the formatted current metric value
 * @property {boolean} [isLoading] whether data is being fetched
 * @property {boolean} [isError] whether an error occurred during fetching the data
 * @property {ReactNode} [errorContent] custom fallback content to display if there was an error
 *
 * @param {PropertyRentRollLiveKpisCardProps} props
 */

const PropertyRentRollLiveKpisCard = ({
  title,
  workingVersionMetric,
  currentMetricValue,
  isLoading = false,
  isError = false,
  format,
  ...otherProps
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.rent-roll-working-version.live-kpis-card',
  })

  const comparisonMetric = usePropertyRentRollComparisonMetric({
    isLoading,
    isError,
    formatter: format,
    primaryValue: workingVersionMetric.value,
    comparisonValue: currentMetricValue,
  })

  const secondaryMetric = {
    label: t('current.title'),
    value: format(currentMetricValue),
  }
  const secondaryMetrics = [secondaryMetric, comparisonMetric]

  return (
    <div {...otherProps}>
      <Card>
        <CardHeaderWithMetrics
          title={title}
          primaryMetric={{
            label: `${t('working-version.title')} | ${
              workingVersionMetric.unit ?? NO_CONTENT_PLACEHOLDER
            }`,
            value: format(workingVersionMetric.value),
          }}
          secondaryMetrics={secondaryMetrics}
          showReferenceDate={false}
          isLoading={isLoading}
          isError={isError}
        />
      </Card>
    </div>
  )
}

PropertyRentRollLiveKpisCard.propTypes = {
  title: PropTypes.node.isRequired,
  workingVersionMetric: PropTypes.shape({
    unit: PropTypes.node,
    value: PropTypes.node,
  }).isRequired,
  currentMetricValue: PropTypes.node,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  errorContent: PropTypes.node,
  format: PropTypes.func.isRequired,
}

export default PropertyRentRollLiveKpisCard
