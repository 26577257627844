import { Input, InputType, Option, Select } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/disbursement/fields/DisbursementTileCurrencySelect.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import { useCurrencyCodes } from 'hooks/services/deals/financing/configGetters/useCurrencyCodes'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const DisbursementTileCurrencySelect = ({ disabled, value, onChange }) => {
  const { isLoading, isError, data } = useCamelizedResponse(useCurrencyCodes())

  const handleOnChange = useCallback(
    (event) => {
      const newCurrency = event.detail.selectedOption.value
      onChange(newCurrency)
    },
    [onChange],
  )

  const renderContent = useCallback(() => {
    if (disabled) {
      const displayValue =
        data?.currencyCodes.find((element) => element.key === value)?.displayName ?? value
      return (
        <Input
          className={styles.currencySelect}
          type={InputType.Text}
          value={displayValue}
          readonly={true}
        />
      )
    }
    return (
      <Select className={styles.currencySelect} onChange={handleOnChange} value={value}>
        <Option key={'empty'} value={''} selected={isNil(value) || value === ''}>
          {''}
        </Option>
        {data?.currencyCodes.map(({ key, displayName }) => (
          <Option key={key} value={key} selected={value === key}>
            {displayName}
          </Option>
        ))}
      </Select>
    )
  }, [data?.currencyCodes, disabled, handleOnChange, value])

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderContent}
      error={'error'}
    />
  )
}

DisbursementTileCurrencySelect.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default DisbursementTileCurrencySelect
