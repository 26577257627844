import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateCustomAssessmentVersion = ({ entityId, entityType, code, ...mutateOptions }) => {
  const { post } = useAccessTokenRequest()

  return useMutation(async ({ content: text }) => {
    const { data } = await post({
      path: '/central-data/assessments',
      body: snakecaseKeys({
        entityId,
        entityType,
        code,
        text,
      }),
    })

    return data
  }, mutateOptions)
}

export default useCreateCustomAssessmentVersion
