import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DocumentIcon from 'components/domains/documents/DocumentIcon'
import styles from 'components/domains/documents/DocumentSelectTable.module.css'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import entityToLink from 'components/domains/documents/entityToLink'
import Entity from 'components/ui/data/Entity'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useStaffMemberByObjectIdOrEmail'

const nameCellPropTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    }).isRequired,
  }).isRequired,
}
/** @param {PropTypes.InferProps<typeof nameCellPropTypes>} props */
export const NameCell = ({
  row: {
    original: { name, id },
  },
}) => <Entity name={name} link={`/documents/${id}`} openInNewTab />
NameCell.propTypes = nameCellPropTypes

const iconCellPropTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
}
/** @param {PropTypes.InferProps<typeof iconCellPropTypes>} props */
export const IconCell = ({ cell: { value } }) => <DocumentIcon format={value} />
IconCell.propTypes = iconCellPropTypes

const dateCellPropTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
}
/** @param {PropTypes.InferProps<typeof dateCellPropTypes>} props */
export const DateCell = ({ cell: { value } }) => {
  const { format } = useShortDateFormatter()
  return format(value)
}
DateCell.propTypes = dateCellPropTypes

const objectCellPropTypes = {
  cell: PropTypes.shape({
    value: PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayId: PropTypes.string.isRequired,
      type: PropTypes.oneOf(Object.values(DocumentTypes)).isRequired,
    }).isRequired,
  }).isRequired,
}
/** @param {PropTypes.InferProps<typeof objectCellPropTypes>} props */
export const ObjectCell = ({
  cell: {
    value: { name, displayId, type },
  },
}) => (
  <Entity name={name} id={displayId} link={entityToLink({ type, id: displayId })} openInNewTab />
)
ObjectCell.propTypes = objectCellPropTypes

const creatorCellPropTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
}
/** @param {PropTypes.InferProps<typeof creatorCellPropTypes>} props */
export const CreatorCell = ({ cell: { value: objectIdOrEmail } }) => {
  const {
    data: staffMember,
    isLoading,
    isError,
  } = useStaffMemberByObjectIdOrEmail({ objectIdOrEmail })

  const { t } = useTranslation(undefined, { keyPrefix: 'components.documents.select-table' })

  return (
    <SmallLoadingWrapper
      error={<span className={styles.creatorError}>{t('creator-error')}</span>}
      isError={false}
      isLoading={isLoading}
      renderContent={() =>
        isError ? (
          <span className={styles.creatorError} title={t('creator-error')}>
            {objectIdOrEmail}
          </span>
        ) : (
          staffMember?.fullName
        )
      }
    />
  )
}
CreatorCell.propTypes = creatorCellPropTypes
