import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { isNotFoundError } from 'api/requests'
import styles from 'components/domains/business-events-and-tasks/decision-process/alc/helper/DecisionProcessLabeledLoadingContent.module.css'
import Labeled from 'components/ui/data/Labeled'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'

const dash = '-'

const DecisionProcessLabeledLoadingContent = ({
  response: { isLoading, isError, data, error },
  label,
  renderCallback,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.alc.labeled-loading-content',
  })
  const renderContent = useCallback(
    () => <Text>{renderCallback(data)}</Text>,
    [data, renderCallback],
  )
  const isNotFound = useMemo(() => error && isNotFoundError(error), [error])
  return (
    <Labeled label={label} vertical showColon className={styles.labeledContent}>
      <SmallLoadingWrapper
        isLoading={isLoading}
        isError={!isLoading && isError}
        error={isNotFound ? dash : <Text className={styles.errorLabel}>{t('error')}</Text>}
        renderContent={renderContent}
      />
    </Labeled>
  )
}

DecisionProcessLabeledLoadingContent.propTypes = {
  response: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    error: PropTypes.object,
  }),
  label: PropTypes.string.isRequired,
  renderCallback: PropTypes.func.isRequired,
}

export default DecisionProcessLabeledLoadingContent
