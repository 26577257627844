import PropTypes from 'prop-types'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const conditionContextValue = {
  entityRef: {
    entityType: cwpEntityTypes.DEAL,
  },
}

const ConditionsDealContextProvider = ({ children }) => (
  <ConditionsContext.Provider value={conditionContextValue}>{children}</ConditionsContext.Provider>
)

ConditionsDealContextProvider.propTypes = {
  children: PropTypes.node,
}

export default ConditionsDealContextProvider
