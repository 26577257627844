import { Link } from '@fioneer/ui5-webcomponents-react'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import DocumentDownload from 'components/domains/documents/DocumentDownload'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { UserProfileContext } from 'routes/UserProfileContext'
import { getVersionFileName } from 'routes/documents/document-versions/DocumentVersionsTable'

const downloadPropTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string.isRequired,
        versions: PropTypes.arrayOf(
          PropTypes.shape({ guid: PropTypes.string, isWorkVersion: PropTypes.bool }),
        ),
        fileName: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}
/** @param {PropTypes.InferProps<typeof downloadPropTypes>} props */
export const CheckDocumentsDownload = ({ cell }) => {
  const row = cell.row.original
  const id = row?.id
  const versionGuid = row?.versions?.find((version) => version.isWorkVersion).guid
  const activeVersion = row?.versions?.find((version) => version.isWorkVersion)
  const activeFileName =
    activeVersion && getVersionFileName(cell.row?.original?.fileName, activeVersion.versionExt)

  return (
    <DocumentDownload
      type="icon"
      documentId={id}
      documentVersionGuid={versionGuid}
      fileName={activeFileName}
    />
  )
}

CheckDocumentsDownload.propTypes = downloadPropTypes

const namePropTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
      subRows: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    }),
    value: PropTypes.string,
  }).isRequired,
}
/** @param {PropTypes.InferProps<typeof namePropTypes>} props */
export const CheckDocumentsName = ({ cell }) => {
  const isLeafRow = cell.row?.subRows.length === 0
  if (!isLeafRow) return cell.value

  /** @param {string} [guid] */
  const toDocumentPage = (guid) => {
    if (!guid) return
    window.open(`/documents/${guid}`, '_blank')
  }

  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => toDocumentPage(cell?.row?.original?.id)}
    >
      {cell.value}
    </Link>
  )
}

CheckDocumentsName.propTypes = namePropTypes

/**
 * @param {DateTime} dateTime
 * @param {string} [locale]
 */
const formatDateTime = (dateTime, locale) =>
  dateTime.toLocaleString(
    {
      weekday: 'short',
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    },
    { locale },
  )

const dateCellPropTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
}
/** @param {PropTypes.InferProps<typeof dateCellPropTypes>} props */
export const CheckDocumentsDateCell = ({ cell: { value } }) => {
  const { format: formatDate } = useShortDateFormatter()
  const { language, zoneId } = useContext(UserProfileContext)
  return (
    value && (
      <span title={formatDateTime(DateTime.fromISO(value, { zone: zoneId }), language)}>
        {formatDate(value)}
      </span>
    )
  )
}

CheckDocumentsDateCell.propTypes = dateCellPropTypes
