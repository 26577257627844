import { useContext } from 'react'
import useCurrentMultiPropertyKpis from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpis'
import useMultiPropertyValuationsDecisionPaper from 'hooks/services/properties/valuations/useMultiPropertyValuationsDecisionPaper'
import { PropertyPortfolioContext } from 'routes/properties/portfolio/PropertyPortfolioContext'

const usePropertyPortfolioOverviewPieChartCardData = () => {
  const { properties } = useContext(PropertyPortfolioContext)

  const propertyUuids = properties.map((property) => property.uuid)

  const queryOptions = {
    enabled: properties.length > 0,
  }

  // CWP-16174: Improve performance and decrease load on backend system by using common queries for multiple use cases
  const {
    data: multiPropertyKpisByGranularity,
    isLoading: isLoadingMultiPropertyKpisKpis,
    isError: isErrorMultiPropertyKpisKpis,
  } = useCurrentMultiPropertyKpis(
    propertyUuids,
    {
      withCityGranularity: true,
      withCountryGranularity: true,
      withPropertyTypeGranularity: true,
      withTenantIndustryGranularity: true,
      withSegmentUsageTypeGranularity: true,
      withTegovaRatingGranularity: true,
    },
    queryOptions,
  )

  // CWP-16174: Improve performance and decrease load on backend system for Deal -> Financed objects page
  // Different tiles on the Deal -> Financed objects page require similar valuations and kpi data.
  // There are slightly different requirements for each tile with respect to needed aggregations and granularities.
  // We now request the same superset of valuation and kpi data at each location, so the result is just one single request
  // A further improvement idea might be: Move the valuation and kpi data to a context.
  // Look for similar comments like this to find other consolidated hook usages.
  // Some examples are:  DealPropertiesHeader, DealPropertiesTable, PropertyPortfolioInformationCard,
  // PropertyPortfolioOverviewTableCard and usePropertyPortfolioOverviewPieChartCardData
  const {
    data: multiPropertyValuationsByGranularity,
    isLoading: isLoadingMultiPropertyValuations,
    isError: isErrorMultiPropertyValuations,
  } = useMultiPropertyValuationsDecisionPaper(propertyUuids, ['sum'], undefined, queryOptions, [
    'city',
    'country',
    'propertyType',
    'tegovaRating',
  ])

  return {
    data: { multiPropertyKpisByGranularity, multiPropertyValuationsByGranularity },
    isLoading: isLoadingMultiPropertyKpisKpis || isLoadingMultiPropertyValuations,
    isError: isErrorMultiPropertyKpisKpis || isErrorMultiPropertyValuations,
  }
}

export default usePropertyPortfolioOverviewPieChartCardData
