import { MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import '@ui5/webcomponents-icons/dist/error.js'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionsAllowedOperations } from 'api/conditions/conditionsAllowedOperations'
import styles from 'components/domains/conditions/dialogs/comments/ConditionCommentDialog.module.css'
import CommentDialog from 'components/ui/comment/CommentDialog'
import CommentEntry from 'components/ui/comment/CommentEntry'
import 'components/ui/dialog/ErrorDialog.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import { useStaffMemberSelf } from 'hooks/services/business-partners/staff-members/useStaffMemberSelf'
import useConditionComments from 'hooks/services/conditions/comments/useConditionComments'
import useCreateConditionComment from 'hooks/services/conditions/comments/useCreateConditionComment'
import useDeleteConditionComment from 'hooks/services/conditions/comments/useDeleteConditionComment'
import useEditConditionComment from 'hooks/services/conditions/comments/useEditConditionComment'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const ConditionCommentDialog = ({ isOpen, setIsOpen, conditionId, conditionType }) => {
  const { mutate: createComment } = useCreateConditionComment()
  const { mutate: deleteComment } = useDeleteConditionComment()
  const { mutate: editComment } = useEditConditionComment()

  const { allowedOperations } = useContext(ConditionsContext)

  const hasEditPermission = useMemo(
    () => allowedOperations.includes(conditionsAllowedOperations.editCondition),
    [allowedOperations],
  )

  const {
    isLoading,
    isError,
    data: commentData,
  } = useConditionComments({
    conditionId,
    conditionType,
  })
  const queryClient = useQueryClient()
  const { t } = useTranslation('translation', { keyPrefix: 'components.conditions.comments' })
  const showMessageBox = Modals.useShowMessageBox()

  const [isEditMode, setIsEditMode] = useState(false)
  const [commentEditModeState, setCommentEditModeState] = useState({})
  const isCommentEditMode = useCallback(
    (commentId) => commentEditModeState[commentId] ?? false,
    [commentEditModeState],
  )
  const setIsCommentEditMode = useCallback(
    (commentId, isEditing) =>
      setCommentEditModeState({ ...commentEditModeState, [commentId]: isEditing }),
    [commentEditModeState],
  )

  const { data: userData } = useStaffMemberSelf()

  const handleDeleteComment = useCallback(
    (commentId) => {
      deleteComment(
        { commentId, conditionId, conditionType },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['conditions', conditionType])
          },
          onError: () => {
            showMessageBox({ type: MessageBoxTypes.Error, children: t('delete.error.description') })
          },
        },
      )
    },
    [deleteComment, conditionId, conditionType, queryClient, showMessageBox, t],
  )

  const handleUpdateComment = useCallback(
    ({ commentId, newComment }) => {
      editComment(
        {
          conditionType,
          conditionId,
          commentId,
          comment: newComment,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['conditions', conditionType, conditionId, 'comments'])
            setIsCommentEditMode(commentId, false)
          },
          onError: (_error) => {
            showMessageBox({ type: MessageBoxTypes.Error, children: t('edit.error.description') })
          },
        },
      )
    },
    [editComment, conditionType, conditionId, queryClient, setIsCommentEditMode, showMessageBox, t],
  )

  const handleCreateComment = useCallback(
    (comment) => {
      createComment(
        { conditionType, conditionId, comment },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['conditions', conditionType])
            setIsEditMode(false)
          },
          onError: () => {
            showMessageBox({ type: MessageBoxTypes.Error, children: t('create.error.description') })
          },
        },
      )
    },
    [createComment, conditionType, conditionId, queryClient, showMessageBox, t],
  )

  const renderComments = useCallback(() => {
    const { comments } = commentData
    const userMail = userData?.email
    return comments.map(
      ({ ownerId, creationTime, comment, id: commentId, lastModifiedAt }, index) => (
        <div key={index} className={styles.comment}>
          <CommentEntry
            ownerId={ownerId}
            commentText={comment}
            creationTime={creationTime}
            lastModifiedAt={lastModifiedAt}
            showCommentActions={hasEditPermission && userMail === ownerId}
            onDeleteComment={() => handleDeleteComment(commentId)}
            onUpdateComment={(newComment) => handleUpdateComment({ commentId, newComment })}
            isEditMode={isCommentEditMode(commentId)}
            setIsEditMode={(isEditing) => setIsCommentEditMode(commentId, isEditing)}
          />
        </div>
      ),
    )
  }, [
    commentData,
    userData?.email,
    hasEditPermission,
    isCommentEditMode,
    handleDeleteComment,
    handleUpdateComment,
    setIsCommentEditMode,
  ])

  return (
    <CommentDialog
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onCreateComment={handleCreateComment}
      showNewCommentCard={hasEditPermission}
    >
      <SmallLoadingWrapper
        isLoading={isLoading}
        isError={isError}
        error={t('loading.error.description')}
        renderContent={renderComments}
      />
    </CommentDialog>
  )
}

ConditionCommentDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  conditionId: PropTypes.string.isRequired,
  conditionType: PropTypes.string.isRequired,
}

export default ConditionCommentDialog
