import {
  CheckBox,
  FlexBox,
  Label,
  MessageStrip,
  MessageStripDesign,
  Table,
  TableCell,
  TableGrowingMode,
  TableRow,
  Toast,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import '@ui5/webcomponents-icons/dist/AllIcons.js'
import compact from 'lodash.compact'
import find from 'lodash.find'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { propertyAllowedOperations } from 'api/property/propertyAllowedOperations'
import {
  valuationStatus,
  valuationStatusTranslated,
} from 'api/property/valuation/valuationRequests'
import ValuationRequestCreationButton from 'components/domains/properties/valuation/ValuationRequestCreationButton'
import ValuationRequestDeleteMultipleButton from 'components/domains/properties/valuation/ValuationRequestDeleteMultipleButton'
import ValuationRequestEditMultipleButton from 'components/domains/properties/valuation/ValuationRequestEditMultipleButton'
import {
  getValuationReasonsObject as getValuationReason,
  valuationTypesDefaultAll as getValuationTypes,
} from 'components/domains/properties/valuation/ValuationRequestReasonsUtils'
import ValuationRequestMultiEditDialog from 'components/domains/properties/valuation/mass-edit/ValuationRequestMultiEditDialog'
import { propertiesShape } from 'components/domains/properties/valuation/panels/ValuationRequestCreationPanel'
import {
  ValuationRequestReport,
  valuationRequestShape,
} from 'components/domains/properties/valuation/table/ValuationRequestReport'
import styles from 'components/domains/properties/valuation/table/ValuationRequestsTable.module.css'
import ValuationRequestsValuerCustomFilter from 'components/domains/properties/valuation/table/ValuationRequestsValuerCustomFilter'
import ValuationRequestsTableActionsCell from 'components/domains/properties/valuation/table/table-cells/ValuationRequestsTableActionsCell'
import ValuationsRequestsTableDateCell from 'components/domains/properties/valuation/table/table-cells/ValuationRequestsTableDateCell'
import ValuationsRequestsTableStatusCell from 'components/domains/properties/valuation/table/table-cells/ValuationRequestsTableStatusCell'
import ShortenedPlainText from 'components/domains/properties/valuation/table/table-cells/display/ShortenedPlainText'
import ValuationRequestBusinessEventDisplay from 'components/domains/properties/valuation/table/table-cells/display/ValuationRequestBusinessEventDisplay'
import ValuationRequestBusinessPartnerDisplay from 'components/domains/properties/valuation/table/table-cells/display/ValuationRequestBusinessPartnerDisplay'
import ValuationRequestTableValuerWithSuggestionsSelect from 'components/domains/properties/valuation/table/table-cells/edit/ValuationRequestTableValuerWithSuggestionsSelect'
import ValuationsRequestsTableNoteCell from 'components/domains/properties/valuation/table/table-cells/edit/ValuationRequestsTableNoteEdit'
import { valuationReasonsShape } from 'components/domains/properties/valuation/table/table-cells/edit/ValuationRequestsTableReasonSelect'
import useValuationRequestsTableForm from 'components/domains/properties/valuation/table/useValuationRequestsTableForm'
import Entity from 'components/ui/data/Entity'
import FormattedAddress from 'components/ui/data/FormattedAddress'
import createColumns from 'components/ui/tables/sorted-tables/createColumns'
import useFilterTypes, { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'
import TablesToolbar from 'components/ui/tables/toolbar/TablesToolbar'
import { filters as filterProps } from 'components/ui/tables/toolbar/propTypes'
import { useAddressFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'
import { reset as resetValuationRequestsErrorState } from 'redux/slices/valuation/valuationRequestsTableSlice'
import paths from 'routes/paths'

const ValuationRequestsTruncateLength = 120

const renderValuer = ({ valuer, valuationRequestId }) =>
  valuer ? (
    <ValuationRequestBusinessPartnerDisplay
      valuerId={valuer}
      valuationRequestId={valuationRequestId}
    />
  ) : (
    '-'
  )

const columnKeyToValuationRequestDataMap = {
  reportDate: 'info.report_date',
  type: 'valuation_type',
  valuer: 'valuer',
  reason: 'reason',
  status: 'info.status',
}

export const allColumns = {
  checkbox: 'checkbox',
  propertyName: 'propertyName',
  propertyAddress: 'propertyAddress',
  propertyType: 'propertyType',
  report: 'report',
  reason: 'reason',
  type: 'type',
  businessEvent: 'businessEvent',
  valuer: 'valuer',
  dueDate: 'dueDate',
  status: 'status',
  note: 'note',
  reportDate: 'reportDate',
  editRow: 'editRow',
}
const defaultViewColumns = [
  allColumns.checkbox,
  allColumns.propertyName,
  allColumns.propertyAddress,
  allColumns.propertyType,
  allColumns.report,
  allColumns.reason,
  allColumns.type,
  allColumns.businessEvent,
  allColumns.valuer,
  allColumns.dueDate,
  allColumns.status,
  allColumns.note,
  allColumns.reportDate,
  allColumns.editRow,
]
const defaultEditColumns = [
  allColumns.checkbox,
  allColumns.propertyName,
  allColumns.propertyAddress,
  allColumns.propertyType,
  allColumns.report,
  allColumns.businessEvent,
  allColumns.dueDate,
  allColumns.status,
  allColumns.note,
  allColumns.reportDate,
  allColumns.editRow,
  allColumns.valuer,
]

const enter = 'Enter'
const dash = '-'

/** @param {string} status */
const isRowEditable = (status) =>
  status === valuationStatus.created ||
  status === valuationStatus.draft ||
  status === valuationStatus.instructed ||
  status === valuationStatus.externalTransferFailed ||
  status === valuationStatus.completed

/** @param {string} status */
const isRowRemovable = (status) => status === valuationStatus.draft

const ascending = 'asc'
const descending = 'desc'

/**
 *  @typedef {PropTypes.InferProps<typeof ValuationRequestsTable.propTypes>} ValuationRequestsTable
 */
const ValuationRequestsTable = ({
  properties,
  valuationRequests = [],
  onSortingChanged,
  setFilters,
  sortBy,
  orderBy,
  searchParam,
  setSearchParam,
  filters,
  valuationReasons,
  viewColumns = defaultViewColumns,
  editColumns = defaultEditColumns,
  noDataText,
  onLoadMore,
  totalValuationRequestAmount,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property.valuation.requests' })
  const { t: tNoPrefix } = useTranslation()
  const { editValuation, reset, patchValue, isDirty } = useValuationRequestsTableForm()
  const { format } = useShortDateFormatter()
  const parseAddress = useAddressFormatter()
  const { filterForType } = useFilterTypes()
  const dispatch = useDispatch()
  const [selectedIdsValuationRequests, setSelectedValuationRequests] = useState({})
  const [isEditMultipleDialogOpen, setIsEditMultipleDialogOpen] = useState(false)
  const [temporarySearchParam, setTemporarySearchParam] = useState(searchParam)
  const [fileUpload, setFileUpload] = useState({ canDrop: false, isOverById: [] })
  const toastRef = useRef(null)

  const filteredSelectedIdsValuationRequests = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(selectedIdsValuationRequests).filter(([valuationRequestId]) =>
          valuationRequests.some(({ id }) => id === valuationRequestId),
        ),
      ),
    [selectedIdsValuationRequests, valuationRequests],
  )

  useEffect(() => {
    //filter the deleted keys from selectedIdsValuationRequests and update state
    setSelectedValuationRequests(filteredSelectedIdsValuationRequests)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuationRequests])

  const selectedValuationRequestIds = useMemo(
    () => Object.keys(selectedIdsValuationRequests),
    [selectedIdsValuationRequests],
  )

  const formatAddress = useCallback(
    ({ countryCode, country, street, houseNumber, zipCode, city }) => {
      const { firstLine, secondLine, thirdLine } = parseAddress({
        countryCode,
        country,
        street,
        houseNumber,
        zipCode,
        city,
      })

      return `${firstLine} ${secondLine} ${thirdLine}`
    },
    [parseAddress],
  )
  const isEditing = useMemo(() => !!editValuation.id, [editValuation.id])
  const maxDate = useMemo(() => format(new Date().toISOString().slice(0, 10)), [format])

  const allValuationRequestsIds = useMemo(
    () =>
      Object.fromEntries(
        valuationRequests.map(
          ({
            id,
            info: { status },
            property: {
              allowedOperations: { allowedOperations },
            },
          }) => [id, { info: { status }, allowedOperations }],
        ),
      ),
    [valuationRequests],
  )
  const showToolbarCreateValuationButton = useMemo(
    () =>
      properties.every(
        ({ allowedOperations }) =>
          allowedOperations.includes(propertyAllowedOperations.propertyValuationRead) &&
          (allowedOperations.includes(propertyAllowedOperations.propertyValuationRequestCreate) ||
            allowedOperations.includes(
              propertyAllowedOperations.propertyValuationRequestDraftCreate,
            )),
      ),
    [properties],
  )
  const hasUpdatePermission = useCallback(
    (allowedOperations) =>
      allowedOperations.includes(propertyAllowedOperations.propertyValuationRequestUpdate),
    [],
  )

  const showEditAndDeleteMultipleButtons = useMemo(
    () => properties.some(({ allowedOperations }) => hasUpdatePermission(allowedOperations)),
    [properties, hasUpdatePermission],
  )

  const showCreateButton = useMemo(
    () =>
      properties.every(({ allowedOperations }) =>
        allowedOperations.includes(propertyAllowedOperations.propertyValuationRequestCreate),
      ),
    [properties],
  )

  const showErrorStrip = useSelector(
    (state) => state.request.valuationRequestsTable.rowsHaveAnError,
  )
  const valuationErrorRows = useSelector((state) => state.request.valuationRequestsTable.errorRows)
  const valuationHighlightRows = useSelector(
    (state) => state.request.valuationRequestsTable.highlightRows,
  )

  const sortingColumnKey = useMemo(
    () =>
      Object.keys(columnKeyToValuationRequestDataMap).find(
        (key) => columnKeyToValuationRequestDataMap[key] === sortBy,
      ),
    [sortBy],
  )

  const onEditMultipleClicked = useCallback(() => {
    setIsEditMultipleDialogOpen(true)
  }, [])

  const onAfterEditMultipleClosed = useCallback(() => {
    setIsEditMultipleDialogOpen(false)
  }, [])

  const tableAdditionalActions = useMemo(() => {
    const additionalActions = []

    if (showToolbarCreateValuationButton) {
      additionalActions.push(
        <ValuationRequestCreationButton
          properties={properties}
          key={'request-creation-button'}
          showCreateButton={showCreateButton}
        />,
      )
    }

    if (
      showEditAndDeleteMultipleButtons &&
      viewColumns.includes(allColumns.checkbox) &&
      editColumns.includes(allColumns.checkbox)
    ) {
      const selectedIdsValuationRequestsValues = Object.values(selectedIdsValuationRequests)

      additionalActions.push(
        <ValuationRequestEditMultipleButton
          key={'valuation-request-edit-multiple-button'}
          disabled={
            selectedIdsValuationRequestsValues.length === 0 ||
            selectedIdsValuationRequestsValues.some(
              ({ info: { status } }) => !isRowEditable(status),
            ) ||
            selectedIdsValuationRequestsValues.some(
              ({ allowedOperations }) => !hasUpdatePermission(allowedOperations),
            )
          }
          onClick={onEditMultipleClicked}
        />,
        <ValuationRequestDeleteMultipleButton
          key={'valuation-request-delete-multiple-button'}
          valuationRequestUuids={Object.keys(selectedIdsValuationRequests)}
          disabled={
            selectedIdsValuationRequestsValues.length === 0 ||
            selectedIdsValuationRequestsValues.some(
              ({ info: { status } }) => !isRowRemovable(status),
            ) ||
            selectedIdsValuationRequestsValues.some(
              ({ allowedOperations }) => !hasUpdatePermission(allowedOperations),
            )
          }
        />,
      )
    }

    return additionalActions
  }, [
    editColumns,
    hasUpdatePermission,
    onEditMultipleClicked,
    properties,
    selectedIdsValuationRequests,
    showCreateButton,
    showEditAndDeleteMultipleButtons,
    showToolbarCreateValuationButton,
    viewColumns,
  ])

  useEffect(
    () => () => {
      dispatch(resetValuationRequestsErrorState())
    },
    [dispatch],
  )
  const handleOnEditReasonChange = useCallback(
    (reason) => patchValue({ reason: reason, valuationType: '' }),
    [patchValue],
  )

  const handleOnEditValuationTypeChange = useCallback(
    (valuationType) => patchValue({ valuationType }),
    [patchValue],
  )

  const handleOnEditValuerChange = useCallback(
    (valuer) => {
      patchValue({ valuer })
    },
    [patchValue],
  )

  const handleOnEditStatusChange = useCallback(
    (newStatus) => patchValue({ status: newStatus }),
    [patchValue],
  )

  const handleOnEditNoteChange = useCallback(
    (newNote) => patchValue({ note: newNote }),
    [patchValue],
  )

  const handleOnEditValuationDateChange = useCallback(
    (newReportDate) => patchValue({ reportDate: newReportDate }),
    [patchValue],
  )

  const handleOnEditValuationDueDateChange = useCallback(
    (newDueDate) => patchValue({ dueDate: newDueDate }),
    [patchValue],
  )

  const handleOnEditReportChange = useCallback(
    (newReport) => patchValue({ valuationReport: newReport }),
    [patchValue],
  )

  const handleEditRow = useCallback(
    (valuationRequest) => {
      reset(valuationRequest)
    },
    [reset],
  )

  const handleEditCancel = useCallback(() => reset(), [reset])

  const handleOnHeaderCheckboxChange = useCallback(
    (event) => {
      event.target.checked
        ? setSelectedValuationRequests(allValuationRequestsIds)
        : setSelectedValuationRequests({})
    },
    [allValuationRequestsIds],
  )

  const translatedStatusEnum = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(valuationStatusTranslated).map(([status, translationKey]) => [
          status,
          tNoPrefix(translationKey),
        ]),
      ),
    [tNoPrefix],
  )

  const possibleValuationColumns = useMemo(
    () => ({
      checkbox: {
        renderColumnContent: () => (
          <CheckBox
            disabled={valuationRequests.length === 0 || isEditing}
            checked={
              valuationRequests.length > 0 &&
              Object.keys(filteredSelectedIdsValuationRequests).length === valuationRequests.length
            }
            onChange={handleOnHeaderCheckboxChange}
          />
        ),
        columnKey: 'checkbox',
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
      propertyName: {
        title: t('table.title-row.property-name'),
        columnKey: 'propertyName',
        sortingDisabled: true,
        additionalColumnCellProperties: { className: styles.valuationRequestTableCell },
      },
      propertyAddress: {
        title: t('table.title-row.property-address'),
        columnKey: 'propertyAddress',
        sortingDisabled: true,
        additionalColumnCellProperties: { className: styles.valuationRequestTableCell },
      },
      propertyType: {
        title: t('table.title-row.property-type'),
        columnKey: 'propertyType',
        sortingDisabled: true,
        additionalColumnCellProperties: { className: styles.valuationRequestTableCell },
      },
      report: {
        title: t('table.title-row.report'),
        columnKey: 'report',
        sortingDisabled: true,
        additionalColumnCellProperties: { className: styles.valuationRequestTableCell },
      },
      reason: {
        title: t('table.title-row.reason'),
        columnKey: 'reason',
        filter: filterTypes.OF_ENUM_TYPE,
        additionalColumnCellProperties: { className: styles.valuationRequestTableCell },
        additionalFilterOptions: {
          enumValues: getValuationReason(valuationReasons),
        },
      },
      type: {
        title: t('table.title-row.valuation-type'),
        columnKey: 'type',
        additionalColumnCellProperties: { className: styles.valuationRequestTableCell },
        filter: filterTypes.OF_ENUM_TYPE,
        additionalFilterOptions: {
          enumValues: getValuationTypes(valuationReasons.valuation_reasons, '', true),
        },
      },
      businessEvent: {
        title: t('table.title-row.business-event'),
        columnKey: 'businessEvent',
        additionalColumnCellProperties: { className: styles.valuationRequestTableCell },
        minWidth: 1400,
        demandPopin: true,
        sortingDisabled: true,
        popinText: t('table.title-row.business-event'),
      },
      valuer: {
        title: t('table.title-row.valuer'),
        columnKey: 'valuer',
        additionalColumnCellProperties: { className: styles.valuationRequestTableCell },
        filter: filterTypes.CUSTOM,
        additionalFilterOptions: {
          valuationRequests,
          CustomComponent: ValuationRequestsValuerCustomFilter,
        },
        minWidth: 1400,
        demandPopin: true,
        sortingDisabled: true,
        popinText: t('table.title-row.valuer'),
      },
      dueDate: {
        title: t('table.title-row.due-date'),
        columnKey: 'dueDate',
        filter: filterTypes.BETWEEN_DATES,
        additionalColumnCellProperties: { className: styles.valuationRequestTableDateCell },
        minWidth: 1200,
        demandPopin: true,
        popinText: t('table.title-row.due-date'),
      },
      status: {
        title: t('table.title-row.status'),
        columnKey: 'status',
        additionalColumnCellProperties: { className: styles.valuationRequestTableCell },
        filter: filterTypes.OF_ENUM_TYPE,
        additionalFilterOptions: { enumValues: translatedStatusEnum },
      },
      note: {
        title: t('table.title-row.note'),
        columnKey: 'note',
        additionalColumnCellProperties: { className: styles.valuationRequestTableCell },
        minWidth: 1200,
        demandPopin: true,
        sortingDisabled: true,
        popinText: t('table.title-row.note'),
      },
      reportDate: {
        title: t('table.title-row.report-date'),
        filter: filterTypes.BETWEEN_DATES,
        columnKey: 'reportDate',
        additionalColumnCellProperties: { className: styles.valuationRequestTableDateCell },
        minWidth: 1200,
        demandPopin: true,
        popinText: t('table.title-row.report-date'),
      },
      editRow: {
        title: '',
        columnKey: 'editRow',
        additionalColumnCellProperties: { className: styles.valuationRequestTableCell },
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
    }),
    [
      filteredSelectedIdsValuationRequests,
      handleOnHeaderCheckboxChange,
      isEditing,
      t,
      translatedStatusEnum,
      valuationReasons,
      valuationRequests,
    ],
  )

  const valuationColumns = useMemo(
    () =>
      isEditing
        ? editColumns.map((columnKey) => possibleValuationColumns[columnKey])
        : viewColumns.map((columnKey) => possibleValuationColumns[columnKey]),
    [editColumns, isEditing, possibleValuationColumns, viewColumns],
  )

  const onUpdateSorting = useCallback(
    ({ sortBy: callbackSortBy, sortDescending }) => {
      const selectedColumn = find(valuationColumns, ['title', callbackSortBy])
      if (selectedColumn.sortingDisabled) return

      const newSortBy = columnKeyToValuationRequestDataMap[selectedColumn.columnKey]
      const newOrderBy = sortDescending ? descending : ascending

      onSortingChanged({ sortBy: newSortBy, orderBy: newOrderBy })
    },
    [onSortingChanged, valuationColumns],
  )

  const [columnSelection, setColumnSelection] = useState(
    valuationColumns.map(
      ({
        columnKey,
        title,
        isVisible = true,
        isSelectableForHiding = true,
        sortingDisabled = false,
      }) => ({
        columnKey,
        title,
        isVisible,
        sortingDisabled,
        isSelectableForHiding,
      }),
    ),
  )

  const tableData = valuationRequests.map((valuationRequest) => {
    const {
      id,
      reason,
      type,
      businessEvent,
      valuer,
      info: { note, status, reportDate, externalErrorMessage, dueDate },
      property: {
        description: propertyName,
        id: propertyDisplayId,
        uuid: propertyUuid,
        address: { cityName, countryName, houseId, postalCode, streetName },
        typeName,
        allowedOperations: { allowedOperations },
      },
    } = valuationRequest
    const isEditRow = editValuation.id === id

    return {
      rowKey: `valuation-request-${id}`,
      rowClassName: compact([
        styles.row,
        `valuation-request-${id}`,
        valuationHighlightRows[id] ? styles.highlightNewRow : '',
        valuationErrorRows[id] ? styles.highlightErrorRow : '',
      ]).join(' '),
      checkbox: {
        cellComponent: (
          <CheckBox
            checked={id in selectedIdsValuationRequests}
            disabled={isEditing}
            onChange={(event) => {
              if (event.target.checked) {
                setSelectedValuationRequests((oldValue) => ({
                  ...oldValue,
                  [id]: { info: { status }, allowedOperations },
                }))
              } else {
                const newSelectedIdsValuationsRequests = { ...selectedIdsValuationRequests }
                delete newSelectedIdsValuationsRequests[id]
                setSelectedValuationRequests(newSelectedIdsValuationsRequests)
              }
            }}
          />
        ),
      },
      propertyName: {
        cellComponent: (
          <Entity
            link={`/${paths.properties}/${propertyUuid}`}
            name={propertyName}
            id={propertyDisplayId}
            openInNewTab
          />
        ),
      },
      propertyAddress: {
        value: formatAddress({
          country: countryName,
          street: streetName,
          houseNumber: houseId,
          zipCode: postalCode,
          city: cityName,
        }),
        cellComponent: (
          <FormattedAddress
            city={cityName}
            country={countryName}
            street={streetName}
            houseNumber={houseId}
            zipCode={postalCode}
          />
        ),
      },
      propertyType: {
        value: typeName,
        cellComponent: <Label wrappingType={WrappingType.Normal}>{typeName}</Label>,
      },
      report: {
        cellComponent: (
          <ValuationRequestReport
            isEditingRow={isEditRow}
            isEditing={isEditing}
            handleOnEditReasonChange={handleOnEditReasonChange}
            handleOnEditValuationTypeChange={handleOnEditValuationTypeChange}
            handleOnEditReportChange={handleOnEditReportChange}
            valuationRequest={valuationRequest}
            valuationReasons={valuationReasons}
            editValuation={editValuation}
            propertyId={propertyUuid}
            propertyDisplayId={propertyDisplayId}
            propertyName={propertyName}
            setFileUpload={setFileUpload}
            fileUpload={fileUpload}
          />
        ),
      },
      reason: {
        value: reason,
        cellComponent: <Label wrappingType={WrappingType.Normal}>{reason}</Label>,
      },
      type: {
        value: type,
        cellComponent: <Label wrappingType={WrappingType.Normal}>{type}</Label>,
      },
      businessEvent: {
        value: businessEvent,
        cellComponent: isEditRow ? (
          <>
            <ValuationRequestBusinessEventDisplay
              showValuer={false}
              businessEvent={businessEvent}
              valuationRequestId={id}
            />
          </>
        ) : (
          <ValuationRequestBusinessEventDisplay
            showValuer={isEditing}
            businessEvent={businessEvent}
            valuationRequestId={id}
            valuer={valuer}
          />
        ),
      },
      valuer: {
        value: valuer,
        cellComponent: isEditRow ? (
          <ValuationRequestTableValuerWithSuggestionsSelect
            propertyUuid={propertyUuid}
            valuationRequests={valuationRequests}
            valuer={editValuation.valuer}
            onValuerChange={handleOnEditValuerChange}
          />
        ) : (
          renderValuer({ valuer, valuationRequestId: id })
        ),
      },
      dueDate: {
        value: dueDate,
        cellComponent: (
          <ValuationsRequestsTableDateCell
            isEditing={isEditRow && status !== valuationStatus.completed}
            date={isEditRow ? editValuation.dueDate : dueDate}
            onChange={handleOnEditValuationDueDateChange}
          />
        ),
      },
      status: {
        value: status,
        cellComponent: (
          <ValuationsRequestsTableStatusCell
            isEditing={isEditRow}
            originalStatus={status}
            editStatus={isEditRow ? editValuation.status : status}
            externalErrorMessage={externalErrorMessage}
            onChange={handleOnEditStatusChange}
          />
        ),
      },
      note: {
        value: note,
        cellComponent: isEditRow ? (
          <ValuationsRequestsTableNoteCell
            note={isEditRow ? editValuation.note : note}
            onChange={handleOnEditNoteChange}
            disabled={status === valuationStatus.completed}
          />
        ) : (
          <ShortenedPlainText
            text={note ?? ''}
            maxTextDisplaySize={ValuationRequestsTruncateLength}
          />
        ),
      },
      reportDate: {
        value: reportDate,
        cellComponent: (
          <ValuationsRequestsTableDateCell
            isEditing={isEditRow}
            date={isEditRow ? editValuation.reportDate : reportDate}
            onChange={handleOnEditValuationDateChange}
            maxDate={maxDate}
          />
        ),
      },
      editRow: {
        cellComponent: hasUpdatePermission(allowedOperations) && (
          <ValuationRequestsTableActionsCell
            propertyUuids={properties.map(({ propertyUuid }) => propertyUuid)}
            valuationRequest={{
              id,
              reason,
              propertyId: propertyUuid,
              valuationType: type,
              status,
              dueDate,
              note,
              reportDate,
              valuer,
              report: null,
            }}
            isRowEditable={isRowEditable(status)}
            isRowRemovable={isRowRemovable(status)}
            editValuation={editValuation}
            isDirty={isDirty}
            onEdit={handleEditRow}
            onEditCancel={handleEditCancel}
          />
        ),
      },
    }
  })

  const alignContent = (content, alignment) =>
    alignment ? <FlexBox justifyContent={alignment}>{content}</FlexBox> : content

  const renderCells = (tableRow) =>
    valuationColumns.map(({ columnKey, alignment, additionalColumnCellProperties }) => (
      <TableCell key={tableRow.rowKey + dash + columnKey} {...additionalColumnCellProperties}>
        {alignContent(tableRow[columnKey].cellComponent, alignment)}
      </TableCell>
    ))
  const mapTableRows = () =>
    tableData.map((tableRow) => (
      <TableRow className={tableRow.rowClassName} key={tableRow.rowKey} data-key={tableRow.rowKey}>
        {renderCells(tableRow)}
      </TableRow>
    ))

  const filterOptions = useMemo(
    () =>
      filters.length !== 0
        ? filters
        : valuationColumns
            .filter(({ filter }) => !!filter)
            .map(({ filter, title, columnKey, additionalFilterOptions }) => ({
              value: '',
              ...filterForType(filter),
              columnKey,
              label: title,
              additionalFilterOptions,
            })),
    [filters, filterForType, valuationColumns],
  )

  const onKeyDown = useCallback(
    ({ code }) => {
      if (code === enter) {
        setSearchParam(temporarySearchParam)
      }
    },
    [setSearchParam, temporarySearchParam],
  )

  const sorting = useMemo(
    () => ({
      columnKey: sortingColumnKey,
      isSortingAscending: orderBy === ascending,
      onUpdateSorting,
      sortableColumns: valuationColumns
        .filter(({ sortingDisabled }) => !sortingDisabled)
        .map((column) => ({
          columnKey: column.columnKey,
          title: column.title,
        })),
    }),
    [onUpdateSorting, orderBy, valuationColumns, sortingColumnKey],
  )

  const searching = useMemo(
    () => ({
      searchParam: temporarySearchParam,
      onUpdateSearchParam: (newSearchParam) => {
        setTemporarySearchParam(newSearchParam)
      },
      onKeyDown: (event) => onKeyDown(event),
      onSearchIconClick: () => {
        setSearchParam(temporarySearchParam)
      },
    }),
    [temporarySearchParam, setSearchParam, onKeyDown],
  )
  useEffect(() => {
    if (fileUpload.canDrop) {
      toastRef?.current?.show()
    }
  }, [fileUpload])
  const dropText = fileUpload.isOverById.length >= 1 ? t('can-drop-message') : t('drop-message')

  return (
    <>
      {showErrorStrip && (
        <MessageStrip hideCloseButton design={MessageStripDesign.Negative}>
          {t('error.rows-have-error')}
        </MessageStrip>
      )}
      <TablesToolbar
        nrOfEntries={tableData.length}
        title={t('valuations-card.title')}
        columnSelection={columnSelection}
        setColumnSelection={setColumnSelection}
        additionalActions={tableAdditionalActions}
        filtering={{
          filters: filterOptions,
          setFilters,
        }}
        sorting={sorting}
        searching={searching}
      />
      <Table
        columns={createColumns(valuationColumns)}
        growing={
          tableData.length < totalValuationRequestAmount ? TableGrowingMode.Button : undefined
        }
        growingButtonText={tNoPrefix('buttons.more')}
        growingButtonSubtext={`[${tableData.length} / ${totalValuationRequestAmount}]`}
        onLoadMore={onLoadMore}
        noDataText={noDataText}
      >
        {mapTableRows()}
      </Table>
      {isEditMultipleDialogOpen && (
        <ValuationRequestMultiEditDialog
          valuationRequests={valuationRequests}
          selectedValuationRequestIds={selectedValuationRequestIds}
          valuationReasons={valuationReasons}
          onAfterClose={onAfterEditMultipleClosed}
          isOpen={isEditMultipleDialogOpen}
        />
      )}
      {fileUpload.canDrop &&
        createPortal(
          <Toast ref={toastRef} placementType="BottomCenter" duration={60000}>
            {dropText}
          </Toast>,
          document.body,
        )}
    </>
  )
}
const valuationRequestsShape = PropTypes.arrayOf(valuationRequestShape)

ValuationRequestsTable.propTypes = {
  valuationRequests: valuationRequestsShape.isRequired,
  onSortingChanged: PropTypes.func,
  setFilters: PropTypes.func,
  filters: filterProps.isRequired,
  sortBy: PropTypes.string.isRequired,
  orderBy: PropTypes.oneOf([ascending, descending]),
  searchParam: PropTypes.string.isRequired,
  setSearchParam: PropTypes.func,
  valuationReasons: valuationReasonsShape.isRequired,
  noDataText: PropTypes.string.isRequired,
  viewColumns: PropTypes.arrayOf(PropTypes.oneOf(Object.values(allColumns))),
  editColumns: PropTypes.arrayOf(PropTypes.oneOf(Object.values(allColumns))),
  allowedOperations: PropTypes.arrayOf(PropTypes.string),
  properties: propertiesShape.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  totalValuationRequestAmount: PropTypes.number.isRequired,
}

export default ValuationRequestsTable
