import { combineReducers } from '@reduxjs/toolkit'
import marketCreationReducer from 'redux/slices/markets/marketCreationSlice'
import marketMacroProxiesReducer from 'redux/slices/markets/marketMacroProxiesSlice'
import marketMicroProxiesReducer from 'redux/slices/markets/marketMicroProxiesSlice'

const marketsReducer = combineReducers({
  marketCreation: marketCreationReducer,
  marketMacroProxies: marketMacroProxiesReducer,
  marketMicroProxies: marketMicroProxiesReducer,
})

export default marketsReducer
