import camelize from 'camelize'
import { compact } from 'lodash'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

export const useMultipleDealTranches = (dealUuids, options = {}, dataSource) => {
  const queryParams = new URLSearchParams()
  if (dataSource) {
    queryParams.append('dataSource', dataSource)
  }
  queryParams.append('useCache', false)

  const responses = useRequestsMemo(
    useRequests({
      requests: dealUuids.map((dealUuid) => ({
        path: `/financing-structures/deals/${dealUuid}/tranches?${queryParams.toString()}`,
        keys: compact(['deals', dealUuid, 'tranches', queryParams.toString()]),
      })),
      translated: true,
      useCache: true,
      options: { ...options, enabled: !isEmpty(dealUuids) },
    }),
  )

  return useMemo(
    () =>
      responses.map((result, index) => ({
        ...result,
        data: { dealUuid: dealUuids[index], ...camelize(result.data) },
      })),
    [dealUuids, responses],
  )
}
