import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import postPropertyValuationCreation from 'hooks/services/properties/valuations/creation/postPropertyValuationCreation'

export const usePropertyValuationCreation = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ propertyId, calculationMethodCode, value, currency, keyDate, validFrom, validTo }) =>
      postPropertyValuationCreation({
        postMethod: post,
        propertyId,
        calculationMethodCode,
        value,
        currency,
        keyDate,
        validFrom,
        validTo,
      }),
    { onSuccess, onError },
  )
}
