import { useState } from 'react'
import { createPortal } from 'react-dom'
import PropertyCreateButton from 'components/domains/properties/property-create/PropertyCreateButton'
import PropertyCreateDialog from 'components/domains/properties/property-create/PropertyCreateDialog'

const PropertyCreate = () => {
  const [showDialog, setShowDialog] = useState(false)

  const handleOnShowDialog = () => {
    setShowDialog(true)
  }

  const handleOnCloseDialog = () => {
    setShowDialog(false)
  }

  return (
    <>
      <PropertyCreateButton onShowDialog={handleOnShowDialog} />
      {createPortal(
        <PropertyCreateDialog onCloseDialog={handleOnCloseDialog} showDialog={showDialog} />,
        document.body,
      )}
    </>
  )
}

export default PropertyCreate
