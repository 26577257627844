import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import DecisionProcessLabeledLoadingContent from 'components/domains/business-events-and-tasks/decision-process/alc/helper/DecisionProcessLabeledLoadingContent'
import useAuthorityLevelCalculatorAuthorityLevel from 'hooks/services/business-partners/authority-level-calculator/useAuthorityLevelCalculatorAuthorityLevel'

const dash = '-'
const DecisionProcessAlcTotalElClass = ({ eventId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.overview.alc-info.dialog',
  })

  const authorityLevelResponse = useAuthorityLevelCalculatorAuthorityLevel({ eventId })
  const renderTotalElClass = useCallback(
    (authorityLevel) => authorityLevel.expectedLossClass?.unitLevel || dash,
    [],
  )

  return (
    <DecisionProcessLabeledLoadingContent
      response={authorityLevelResponse}
      renderCallback={renderTotalElClass}
      label={t('fields.total-el-class')}
    />
  )
}

DecisionProcessAlcTotalElClass.propTypes = {
  eventId: PropTypes.string.isRequired,
}

export default DecisionProcessAlcTotalElClass
