import PropTypes from 'prop-types'
import { useCallback } from 'react'
import MultipleDealSelectionPdfViewWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/wrapper/MultipleDealSelectionPdfViewWrapper'
import AnnualReviewFinancingTermsTrancheDetailsTileForSelectedDeal from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/annual-review/AnnualReviewFinancingTermsTrancheDetailsTileForSelectedDeal'

const AnnualReviewFinancingTermsTrancheDetailsTile = ({
  tileId,
  isPdfView = false,
  showConfirmationForLinks = false,
}) => {
  const contentCreator = useCallback(
    ({ selectedDealIndex }) => (
      <AnnualReviewFinancingTermsTrancheDetailsTileForSelectedDeal
        tileId={tileId}
        selectedDealIndex={selectedDealIndex}
        isPdfView={isPdfView}
        showConfirmationForLinks={showConfirmationForLinks}
      />
    ),
    [isPdfView, showConfirmationForLinks, tileId],
  )

  return (
    <MultipleDealSelectionPdfViewWrapper
      tileId={tileId}
      contentCreator={contentCreator}
      isPdfView={isPdfView}
    />
  )
}

AnnualReviewFinancingTermsTrancheDetailsTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  showConfirmationForLinks: PropTypes.bool,
}

export default AnnualReviewFinancingTermsTrancheDetailsTile
