import { Text } from '@fioneer/ui5-webcomponents-react'
import { bool, string } from 'prop-types'
import { useContext } from 'react'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { UserProfileContext } from 'routes/UserProfileContext'

const timeOptions = { hour: '2-digit', minute: '2-digit' }
export const FormattedDateText = ({ dateString, showTime = false }) => {
  const { zoneId: timeZone } = useContext(UserProfileContext) ?? {}

  const options = showTime ? { ...timeOptions, timeZone } : { timeZone }

  const { format: formatDateAndTime } = useShortDateFormatter(options)

  return <Text>{formatDateAndTime(dateString)}</Text>
}

FormattedDateText.propTypes = {
  dateString: string,
  showTime: bool,
}
