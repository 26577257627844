import PropertyRentRollOverviewExpiringLeasesCardContent from 'components/domains/properties/rent-roll/overview/expiring-leases/PropertyRentRollOverviewExpiringLeasesCardContent'
import PropertyRentRollOverviewExpiringLeasesCardHeader from 'components/domains/properties/rent-roll/overview/expiring-leases/PropertyRentRollOverviewExpiringLeasesCardHeader'
import Card from 'components/ui/card/Card'

const PropertyRentRollOverviewExpiringLeasesCard = () => (
  <Card header={<PropertyRentRollOverviewExpiringLeasesCardHeader />}>
    <PropertyRentRollOverviewExpiringLeasesCardContent />
  </Card>
)

export default PropertyRentRollOverviewExpiringLeasesCard
