import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateConditionComment = (mutationOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(async ({ conditionId, comment, conditionType }) => {
    const { data } = await post({
      path: `/conditions/${conditionType}/${conditionId}/comments`,
      body: {
        comment,
      },
    })
    return data
  }, mutationOptions)
}

export default useCreateConditionComment
