import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import AnalyticalTableNoDataComponent from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/analytical/AnalyticalTableNoDataComponent'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/cover-pool/CoverPoolAdjustmentsTile.module.css'
import {
  TABLE_HEADER_ROW_HEIGHT,
  TABLE_ROW_HEIGHT,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/cover-pool/constants'
import useCoverPoolAdjustmentsTableColumns from 'components/domains/business-events-and-tasks/decision-paper/tiles/cover-pool/useCoverPoolAdjustmentsTableColumns'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/tables/analytical/AnalyticalTableWithToolbar'
import AnalyticalTableScalingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/AnalyticalTableScalingWrapper'

const CoverPoolAdjustmentsTile = ({ tileId, isPdfView, isEditMode, onMetaDataChange }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.cover-pool-adjustments',
  })
  // the selectedAdjustments array is identical to the metadata that we would get from the wrapper
  const { data: { selectedAdjustments = [], tableData = [] } = {} } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId],
  )

  const tableRef = useRef(null)
  const [tableWidth, setTableWidth] = useState(tableRef?.current?.totalColumnsWidth ?? 0)
  const [selectedRows, setSelectedRows] = useState(() => new Set(selectedAdjustments))

  useEffect(() => {
    setSelectedRows(() => new Set(selectedAdjustments))
  }, [selectedAdjustments, isEditMode])

  useEffect(() => {
    setTableWidth(tableRef?.current?.totalColumnsWidth ?? 0)
  }, [tableRef?.current?.totalColumnsWidth, tableData])

  const onRowSelect = useCallback((e, selectedPropertyUuid) => {
    if (e.target.checked) {
      setSelectedRows((prev) => new Set(prev ?? []).add(selectedPropertyUuid))
    } else {
      setSelectedRows((prev) => {
        const newSelectedRows = new Set(prev ?? [])
        newSelectedRows.delete(selectedPropertyUuid)
        return newSelectedRows
      })
    }
  }, [])

  const onAllRowsSelect = useCallback(
    (e) => {
      if (e.target.checked) {
        const allPropertyUuids = tableData?.map(({ property }) => property?.propertyUuid) ?? []
        setSelectedRows(new Set(allPropertyUuids))
      } else {
        setSelectedRows(new Set())
      }
    },
    [tableData],
  )

  useEffect(() => {
    onMetaDataChange(Array.from(selectedRows))
  }, [onMetaDataChange, selectedRows])

  const selectedAdjustmentsTableData = useMemo(
    () =>
      tableData?.filter(({ property: { propertyUuid } = {} } = {}) =>
        selectedAdjustments?.includes(propertyUuid),
      ),
    [selectedAdjustments, tableData],
  )

  const allRowsSelected = useMemo(
    () =>
      !isEditMode
        ? selectedAdjustmentsTableData.length > 0
        : selectedRows.size === tableData.length,
    [isEditMode, selectedAdjustmentsTableData.length, selectedRows.size, tableData.length],
  )

  const columns = useCoverPoolAdjustmentsTableColumns({
    tableData: isEditMode ? tableData : selectedAdjustmentsTableData,
    isEditMode,
    onRowSelect,
    onAllRowsSelect,
    selectedRows: Array.from(selectedRows),
    allRowsSelected,
  })

  return (
    <div className={styles.tableWrapper}>
      <AnalyticalTableScalingWrapper isPdfView={isPdfView} tableWidth={tableWidth}>
        <AnalyticalTableWithToolbar
          title={t('table.title')}
          columns={columns}
          data={isEditMode ? tableData : selectedAdjustmentsTableData}
          headerRowHeight={TABLE_HEADER_ROW_HEIGHT}
          rowHeight={TABLE_ROW_HEIGHT}
          adjustTableHeightOnPopIn
          disableColumnPopover={true}
          groupable={false}
          filterable={!isPdfView}
          sortable={!isPdfView}
          isPdfView={isPdfView}
          minRows={0}
          ref={tableRef}
          NoDataComponent={() => (
            <AnalyticalTableNoDataComponent
              isLoading={false}
              isError={false}
              tableId={'cover-pool-adjustments-table'}
            />
          )}
        />
      </AnalyticalTableScalingWrapper>
    </div>
  )
}

CoverPoolAdjustmentsTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  onMetaDataChange: PropTypes.func.isRequired,
}

export default CoverPoolAdjustmentsTile
