import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  ObjectStatus,
  Title,
  TitleLevel,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import maxBy from 'lodash.maxby'
import minBy from 'lodash.minby'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/shared/i18n/useI18n'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/RiskIndicatorsGroupCriticalityTile.module.css'
import RiskIndicatorsGroupCriticalityLineChartChildrenDecisionPaper from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/components/RiskIndicatorsGroupCriticalityLineChartChildrenDecisionPaper'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/card/EmptyCardContent'
import GenericChart from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/shared/ui/charts/GenericChart'
import { riskIndicatorStatusMapping } from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/domains/business-partners/tile/risk-monitoring/utils/typeCodes'

const propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

const EMPTY_VALUE_STRING = '-'

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const RiskIndicatorsGroupCriticalityTile = ({ tileId, isPdfView }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.risk-indicators.criticality',
  })
  const { format: formatDate } = useShortDateFormatter()

  const {
    riskIndicatorGroupData,
    criticalities = [],
    events = [],
    riskIndicatorCriticalityStatusTypeCodes,
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId].data) ?? {}

  const sortedCriticalities = useMemo(() => [...criticalities], [criticalities])
  // since the business events are only dates (x-value), but should still be rendered on the criticality line graph,
  // we assign each event's closing date the criticality (y-value) that was set in this time period
  sortedCriticalities.sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn))
  const mappedEventsToCriticality = useMemo(
    () =>
      compact(
        events?.map(({ info: { closingDate } = {} } = {}) => {
          if (!closingDate) {
            return
          }
          // the criticality of the last item in the list of criticalities up until the event's closing date
          const criticalityHistoryBeforeEvent = (
            sortedCriticalities?.findLast(({ createdOn }) => createdOn <= closingDate) ??
            sortedCriticalities?.[0]
          )?.criticalityTypeCode

          return {
            dateUnixTimestamp: new Date(closingDate).getTime(),
            businessEventValue: criticalityHistoryBeforeEvent,
          }
        }),
      ),
    [events, sortedCriticalities],
  )

  const lineChartData = sortedCriticalities
    .map(({ createdOn, criticalityTypeCode } = {}) => ({
      dateUnixTimestamp: new Date(createdOn).getTime(),
      criticalityValue: criticalityTypeCode,
    }))
    .concat(mappedEventsToCriticality)
    .sort((dateA, dateB) => dateA.dateUnixTimestamp - dateB.dateUnixTimestamp)

  const yDataMin = useMemo(
    () =>
      (sortedCriticalities && minBy(sortedCriticalities, 'criticalityTypeCode'))
        ?.criticalityTypeCode,
    [sortedCriticalities],
  )
  const yDataMax = useMemo(
    () =>
      (sortedCriticalities && maxBy(sortedCriticalities, 'criticalityTypeCode'))
        ?.criticalityTypeCode,
    [sortedCriticalities],
  )

  const renderHeader = useCallback(() => {
    const {
      currentCriticality: { criticalityDate, criticalityShortText, criticalityTypeCode } = {},
    } = riskIndicatorGroupData ?? {}
    return (
      <>
        <FlexBox
          className={styles.flexBoxSpacing}
          direction={FlexBoxDirection.Row}
          justifyContent={FlexBoxJustifyContent.SpaceBetween}
        >
          <FlexBox direction={FlexBoxDirection.Column} justifyContent={FlexBoxJustifyContent.Start}>
            <Title level={TitleLevel.H6} className={styles.titleBottomSpacing}>
              {t('label.current-criticality')}
            </Title>
            <ObjectStatus
              inverted
              state={
                riskIndicatorStatusMapping.CRITICALITY?.[criticalityTypeCode]?.valueState ||
                ValueState.None
              }
            >
              {criticalityShortText ?? EMPTY_VALUE_STRING}
            </ObjectStatus>
          </FlexBox>
          <FlexBox direction={FlexBoxDirection.Column} alignItems={FlexBoxAlignItems.End}>
            <Title level={TitleLevel.H6} className={styles.titleBottomSpacing}>
              {t('label.criticality-date')}
            </Title>
            <Title level={TitleLevel.H6}>
              {criticalityDate ? formatDate(criticalityDate) : EMPTY_VALUE_STRING}
            </Title>
          </FlexBox>
        </FlexBox>
      </>
    )
  }, [formatDate, riskIndicatorGroupData, t])

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      {renderHeader()}
      <DecisionPaperTileListSeparator />
      {lineChartData.length > 0 ? (
        <div id="criticality-history-graph" className={styles.criticalityHistoryWrapper}>
          <GenericChart
            lineChartData={lineChartData}
            lineChartChildren={RiskIndicatorsGroupCriticalityLineChartChildrenDecisionPaper}
            additionalLineChartArguments={{
              valueBoundaries: { yAxis: { yDataMin, yDataMax } },
              riskIndicatorCriticalityStatusTypeCodes,
              isPdfView,
            }}
            // default recharts tooltip functionality is used, eliminating the need for this prop
            renderChildrenOutsideLineChart={() => {}}
            customChartSettings={{ MAX_HEIGHT: 300 }}
          />
        </div>
      ) : (
        <EmptyCardContent />
      )}
    </FlexBox>
  )
}

RiskIndicatorsGroupCriticalityTile.propTypes = propTypes

export default RiskIndicatorsGroupCriticalityTile
