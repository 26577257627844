import {
  Button,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  Popover,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import DisplayCardView, {
  hideOptions,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/CriticalFacilityTileDisplayCardView'
import EditCardView from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/CriticalFacilityTileEditCardView'
import 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/card/Card.css'
import 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/PropertyCards.css'
import editComponentTypes from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/CriticalFacilityTileeditComponentTypes'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/domains/deals/collaterals/GeneralInformationTile.module.css'
import { formatHookError } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/useHookErrorResponseFormatter'
import ErrorMessageBoxWithExpandableDetails from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/message-box/ErrorMessageBoxWithExpandableDetails'

const displayAndEditCardPropTypes = {
  /** Title of the card */
  cardHeaderTitle: PropTypes.string.isRequired,
  /** Subtitle of the card */
  cardHeaderSubtitle: PropTypes.string,
  /** Passes additional props to the header in edit mode */
  additionalEditHeaderProps: PropTypes.oneOfType([PropTypes.object]),
  /** renders emtpyContent when true */
  isEmpty: PropTypes.bool,
  /** Required fields of edit card*/
  requiredFields: PropTypes.arrayOf(PropTypes.string),
  /** displayed message when isEmpty is true */
  emptyContent: PropTypes.shape({
    /** IllustratedMessageSize */
    size: PropTypes.string,
    /** Flag for enabling / disabling the illustration */
    hasIllustration: PropTypes.bool,
    /** title of the message */
    title: PropTypes.string,
    /** subtitle of the message */
    subtitle: PropTypes.string,
  }),
  /** renders errorContent when true (not to be confused with errors after save) */
  isError: PropTypes.bool,
  /** displayed message when isError is true */
  errorContent: PropTypes.shape({
    /** IllustratedMessageSize */
    size: PropTypes.string,
    /** Flag for enabling / disabling the illustration */
    hasIllustration: PropTypes.bool,
    /** title of the message */
    title: PropTypes.string,
    /** subtitle of the message */
    subtitle: PropTypes.string,
  }),
  /** renders notAllowedContent when true (precedent over empty state) */
  isNotAllowed: PropTypes.bool,
  /** displayed message when isNotAllowed is true */
  notAllowedContent: PropTypes.shape({
    /** IllustratedMessageSize */
    size: PropTypes.string,
    /** Flag for enabling / disabling the illustration */
    hasIllustration: PropTypes.bool,
    /** title of the message */
    title: PropTypes.string,
    /** subtitle of the message */
    subtitle: PropTypes.string,
  }),
  /** sets loading status (e.g. for initial loading of data) */
  isLoading: PropTypes.bool,
  /** sets custom flag for disabling save button */
  isSaveDisabled: PropTypes.bool,
  /** success status of the hook responsible for the save action */
  saveHookIsSuccess: PropTypes.bool,
  /** error status of the hook responsible for the save action */
  saveHookIsError: PropTypes.bool,
  /** The error returned by the save hook, if saveHookIsError is true. */
  saveHookError: PropTypes.oneOfType([PropTypes.object]),
  /** String describing the error */
  saveHookErrorSummary: PropTypes.string,
  /**
   * Array of objects defining display and edit view
   *
   * Important note: Not all properties inside the fieldDefinitions have to be set.
   * Everything besides isShownInDisplay/Edit is optional.
   */
  fieldDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      isShownInDisplay: PropTypes.bool.isRequired,
      isShownInEdit: PropTypes.bool.isRequired,
      label: PropTypes.string,
      name: PropTypes.string,
      formattedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
        PropTypes.bool,
      ]),
      hideOption: PropTypes.oneOf([hideOptions.alwaysHidden, hideOptions.hideWhenEmpty]),
      customInfoComponent: PropTypes.element,
      customEditComponent: PropTypes.element,
      renderCustomEditComponent: PropTypes.func,
      editComponentType: PropTypes.oneOf(Object.values(editComponentTypes)),
      editComponentProps: PropTypes.oneOfType([PropTypes.object]),
      isMandatory: PropTypes.bool,
      editComponentSelectOptions: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string.isRequired,
            display_name: PropTypes.string.isRequired,
          }).isRequired,
        ),
      ]),
      validationFunction: PropTypes.func,
      isSectionTitle: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  /** Function that gets called on save in edit view, gets the changes as parameter */
  saveChanges: PropTypes.func.isRequired,
  /** Function that gets called on cancel of edit view */
  cancelChanges: PropTypes.func,
  /** Function that gets called instead the default on change handling inside edit mode */
  onEditChanges: PropTypes.func,
  /** Whether or not the edit button is visible in display mode */
  isEditable: PropTypes.bool,
  /** Set the editing state from the outside */
  isEditing: PropTypes.bool,
  /** Shows "-" in display view when values are empty instead of hiding them */
  fillEmptyValuesWithPlaceholder: PropTypes.bool,
  /** CSS class that is passed to the underlying card components for display and edit */
  className: PropTypes.string,
  /** Possibility of setting isHandlingSave from outside. Can be left undefined if not needed */
  isHandlingSaveOverwrite: PropTypes.bool,
  /** Passes additional props to the content in edit mode */
  additionalContentProps: PropTypes.element,
  /** Function that gets called after the default on change handling inside edit mode */
  customOnChangeHandler: PropTypes.func,
}

/**
 * withStandardComponentIndicator prevents the description of the props to appear in the storybook.
 * That's why a additional export has been added
 * @param {PropTypes.InferProps<typeof displayAndEditCardPropTypes>} props
 */
export const DisplayAndEditCard = ({
  cardHeaderTitle,
  cardHeaderSubtitle,
  additionalEditHeaderProps = {},
  isNotAllowed = false,
  notAllowedContent,
  isEmpty = false,
  emptyContent,
  isError = false,
  errorContent,
  isLoading = false,
  isSaveDisabled,
  saveHookIsSuccess = false,
  saveHookIsError = false,
  saveHookError,
  saveHookErrorSummary,
  fieldDefinitions,
  saveChanges,
  cancelChanges = () => {},
  onEditChanges,
  isEditable = true,
  isEditing,
  fillEmptyValuesWithPlaceholder = false,
  className,
  isHandlingSaveOverwrite,
  requiredFields = [],
  additionalContentProps,
  customOnChangeHandler,
}) => {
  const { t } = useTranslation('decisionPaper')

  const initializeValues = useCallback(
    (fieldDefinitionsInput) =>
      Object.fromEntries(
        fieldDefinitionsInput
          .filter(({ name, value }) => name && value !== undefined)
          .map(({ name, value }) => [name, value]),
      ),
    [],
  )

  const [editFieldValues, setEditFieldValues] = useState(initializeValues(fieldDefinitions))
  const [isEditMode, setIsEditMode] = useState(false)
  const [isCancelPopoverOpen, setIsCancelPopoverOpen] = useState(false)
  const [editModeHasChanges, setEditModeHasChanges] = useState(false)
  const isHandlingSaveInternal = useRef(false)
  const isHandlingSave =
    isHandlingSaveOverwrite !== undefined ? isHandlingSaveOverwrite : isHandlingSaveInternal.current
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const saveHookErrorSummaryOrDefault = saveHookErrorSummary ?? t('components.cards.save-error')
  const [saveErrorDetails, setSaveErrorDetails] = useState(
    /** @type {string | undefined} */ (undefined),
  )
  const editFieldDefinitions = fieldDefinitions.filter((item) => item.isShownInEdit)

  /** @param {import('react').ReactNode} message */
  const warningMessage = (message) => <div className={styles.warningText}>{message}</div>

  const displayFieldDefinitions = fieldDefinitions
    .map((field) =>
      (requiredFields ?? []).includes(field.name) && !field.value
        ? {
            ...field,
            hideOptions: '',
            value: warningMessage(t('components.ui.card.display-edit-card.no-data')),
            valueState: ValueState.Warning,
          }
        : field,
    )
    .filter((item) => item.isShownInDisplay)

  // HINT: Update the internal editing state on receiving changes from the outside
  useEffect(() => {
    if (!isEditable) return
    setIsEditMode(!!isEditing)
  }, [isEditing, isEditable])

  useEffect(() => {
    // make sure to update the edit field values if the field definitions (and probably its values) changes
    setEditFieldValues(initializeValues(fieldDefinitions))
  }, [fieldDefinitions, initializeValues])

  useEffect(() => {
    if (saveHookIsError && isHandlingSaveInternal.current) {
      const asyncSetSaveErrorDetails = async () =>
        setSaveErrorDetails(await formatHookError(saveHookError))
      asyncSetSaveErrorDetails()
      setErrorMessageOpen(true)
      isHandlingSaveInternal.current = false
    }
    if (!saveHookIsError && saveHookIsSuccess && isHandlingSaveInternal.current) {
      setEditModeHasChanges(false)
      setIsEditMode(false)
      isHandlingSaveInternal.current = false
    }
  }, [saveHookError, saveHookIsError, saveHookIsSuccess])

  const handleSave = (values) => {
    if (editModeHasChanges) {
      saveChanges(values)
      isHandlingSaveInternal.current = true
    }
  }

  const handleCancel = () => {
    setIsCancelPopoverOpen(false)
    setEditModeHasChanges(false)
    setEditFieldValues(initializeValues(editFieldDefinitions))
    setIsEditMode(false)
    cancelChanges?.()
  }

  if (isEditMode) {
    return (
      <>
        <EditCardView
          cardHeaderTitle={cardHeaderTitle}
          cardHeaderSubtitle={cardHeaderSubtitle}
          additionalEditHeaderProps={additionalEditHeaderProps ?? undefined}
          setEditModeHasChanges={setEditModeHasChanges}
          editModeHasChanges={editModeHasChanges}
          values={editFieldValues}
          onSaveClicked={handleSave}
          onCancelClicked={() => {
            if (editModeHasChanges) setIsCancelPopoverOpen(true)
            else handleCancel()
          }}
          onChange={onEditChanges}
          isLoading={isHandlingSave ?? undefined}
          isSaveDisabled={isSaveDisabled ?? undefined}
          fieldDefinitions={editFieldDefinitions}
          className={className}
          requiredFields={requiredFields}
          additionalContentProps={additionalContentProps}
          customOnChangeHandler={customOnChangeHandler}
        />
        {createPortal(
          <>
            {errorMessageOpen && (
              <ErrorMessageBoxWithExpandableDetails
                messageSummary={saveHookErrorSummaryOrDefault}
                messageDetails={saveErrorDetails}
                isOpen={errorMessageOpen}
                onClose={() => {
                  setErrorMessageOpen(false)
                }}
              />
            )}
            <Popover
              opener={`${cardHeaderTitle}-editModeCancelButton`}
              placementType="Top"
              open={isCancelPopoverOpen}
              onAfterClose={() => setIsCancelPopoverOpen(false)}
            >
              <FlexBox
                alignItems={FlexBoxAlignItems.Center}
                direction={FlexBoxDirection.Column}
                fitContainer
              >
                <Text style={{ paddingBottom: '6px' }}>
                  {t('components.ui.card.display-edit-card.button.discard-message')}
                </Text>
                <Button onClick={handleCancel} style={{ width: '100%' }}>
                  {t('components.ui.card.display-edit-card.button.discard')}
                </Button>
              </FlexBox>
            </Popover>
          </>,
          document.body,
        )}
      </>
    )
  }
  return (
    <DisplayCardView
      cardHeaderTitle={cardHeaderTitle}
      cardHeaderSubtitle={cardHeaderSubtitle}
      setEditMode={setIsEditMode}
      isEmpty={isEmpty ?? undefined}
      emptyContent={emptyContent}
      isError={isError ?? undefined}
      errorContent={errorContent}
      isNotAllowed={isNotAllowed ?? undefined}
      notAllowedContent={notAllowedContent}
      isEditable={isEditable ?? undefined}
      isLoading={isLoading ?? undefined}
      fieldDefinitions={displayFieldDefinitions}
      fillEmptyValuesWithPlaceholder={fillEmptyValuesWithPlaceholder ?? undefined}
      className={className}
    />
  )
}

DisplayAndEditCard.displayName = 'DisplayAndEditCard'
DisplayAndEditCard.propTypes = displayAndEditCardPropTypes

export default DisplayAndEditCard
