import { AnalyticalTableScaleWidthMode } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/covenants/monitoring-items-card-deal/MonitoringItemsForDealTable.module.css'
import useMonitoringItemsForDealColumnDefinitions from 'components/domains/deals/covenants/monitoring-items-card-deal/useMonitoringItemsForDealColumnDefinitions'
import useMonitoringItemsForDealTableData from 'components/domains/deals/covenants/monitoring-items-card-deal/useMonitoringItemsForDealTableData'
import AnalyticalTableNoDataComponent from 'components/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'

const MonitoringItemsForDealTable = ({ covenantMonitoringItems, isLoading, isError }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.results.monitoring-items-card',
  })
  const columns = useMonitoringItemsForDealColumnDefinitions()
  const tableData = useMonitoringItemsForDealTableData({ covenantMonitoringItems })
  return (
    <AnalyticalTableWithToolbar
      id={'monitoring-items-for-deal-table'}
      nrOfEntries={covenantMonitoringItems?.length ?? 0}
      withRowHighlight
      highlightField={'highlight'}
      disableColumnPopover={true}
      className={styles.table}
      title={t('title')}
      columns={columns}
      data={tableData}
      rowHeight={50}
      headerRowHeight={32}
      groupable={true}
      filterable={true}
      scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
      NoDataComponent={() => (
        <AnalyticalTableNoDataComponent
          isLoading={isLoading}
          isError={isError}
          tableId="monitoring-items-for-deal-table"
        />
      )}
    />
  )
}

MonitoringItemsForDealTable.propTypes = {
  covenantMonitoringItems: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
}

export default MonitoringItemsForDealTable
