import { z } from 'zod'

export const baselTwoManualCheckSheetSchema = z.object({
  Q3: z.string(),
  Q9: z.string(),
  Q10: z.string(),
  Q11: z.string(),
  Q12: z.string(),
  Q13: z.string(),
  Q14: z.string(),
  Q15: z.string(),
  Q16: z.string(),
  Q18: z.string(),
  Q19: z.string(),
  Q20: z.string(),
  Q21: z.string().nullable(),
  Comment: z.string().nullable(),
})
