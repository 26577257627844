import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'

const statusChanged = (state, { payload: { status } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }

  if (isNil(status)) {
    state.editedRow.errorFields.status = true
    state.editedRow.currentValues.status = status
    return
  }
  const { code: newStatusCode, type: newStatusType } = status
  delete state.editedRow.errorFields.status
  state.editedRow.currentValues.status = { code: newStatusCode, type: newStatusType }

  if (newStatusCode === state.editedRow.initialValues.status.code) {
    delete state.editedRow.changedFields.status
    return
  }
  state.editedRow.changedFields.status = true
}

export default statusChanged
