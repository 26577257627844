import PropTypes from 'prop-types'
import useImageDownload from 'hooks/services/media/useImageDownload'

const formatStringToCamelCase = (str) => {
  const splitted = str.split('-')
  if (splitted.length === 1) return splitted[0]
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join('')
  )
}

export const getTenantDetailsStyleObjectFromString = (str = '') => {
  const style = {}
  str.split(';').forEach((el) => {
    const [property, value] = el.split(':')
    if (!property) return

    const formattedProperty = formatStringToCamelCase(property.trim())
    style[formattedProperty] = value.trim()
  })

  return style
}

const ImageView = ({ src, style, class: className, ...imageAttributes }) => {
  const imageBase64 = useImageDownload({ imageUrl: src })
  return (
    <img
      src={imageBase64}
      style={getTenantDetailsStyleObjectFromString(style)}
      className={className}
      {...imageAttributes}
    />
  )
}

ImageView.propTypes = {
  src: PropTypes.string,
  style: PropTypes.string,
  class: PropTypes.string,
}

export default ImageView
