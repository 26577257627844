import { useQueries } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useArrayMemo } from 'hooks/services/queryHelper'

/**
 *  Fetch property valuations using multiple arrays of propertyUUIDs.
 *  Handle multiple API requests for retrieving property valuations based on their uuids.
 * @param propertyUuidsArrays Array of arrays of property uuids, one hook result for each parent array element
 * @param includeAggregates
 * @param preferredCurrency
 * @param includeGranularities
 * @param options Additional query options to pass to the useQueries hook
 * @returns {{isLoading: boolean, isError: boolean, data: *}}
 */
const useMultiArrayPropertyValuations = ({
  propertyUuidsArrays,
  includeAggregates = [],
  preferredCurrency,
  includeGranularities = [],
  options = {},
}) => {
  const { post } = useAccessTokenRequest()

  const queries =
    propertyUuidsArrays?.map((propertyUuids) => ({
      enabled: propertyUuids?.length > 0,
      queryKey: [
        'properties',
        'multi-valuations',
        propertyUuids,
        includeAggregates,
        preferredCurrency,
        includeGranularities,
      ],
      refetchOnWindowFocus: false,
      queryFn: () =>
        post({
          path: `/properties/valuations`,
          body: {
            property_uuids: propertyUuids.filter(Boolean),
            include_aggregates: includeAggregates,
            preferred_currency: preferredCurrency,
            include_granularities: includeGranularities,
          },
        }),
      retry: false,
      ...options,
    })) ?? []

  const responses = useQueries({ queries })
  const isAnyRequestLoading = useMemo(
    () => responses.some(({ isLoading: isPropertyLoading }) => isPropertyLoading),
    [responses],
  )
  const isAnyRequestError = responses.some(({ isError: isPropertyError }) => isPropertyError)

  const propertiesArrays = useArrayMemo(
    responses.map(({ data }) => ({
      ...data,
    })),
  )

  return useMemo(
    () => ({ data: propertiesArrays, isLoading: isAnyRequestLoading, isError: isAnyRequestError }),
    [isAnyRequestError, isAnyRequestLoading, propertiesArrays],
  )
}

export default useMultiArrayPropertyValuations
