import { MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import CloseableMessageStrip from 'components/ui/feedback/CloseableMessageStrip'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyUnderReviewMessageStrip = ({ ...props }) => {
  const { t } = useTranslation()

  const { property } = useContext(PropertyContext)

  return (
    <>
      {property?.change_request_exist_indicator && (
        <CloseableMessageStrip design={MessageStripDesign.Warning} {...props}>
          {t('pages.property.message-strip.under-review')}
        </CloseableMessageStrip>
      )}
    </>
  )
}

export default PropertyUnderReviewMessageStrip
