import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useCovenantCheckActions = ({ covenantCheckUuid, options = {} } = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/covenants/covenant-checks/${covenantCheckUuid}/actions`,
      translated: true,
      keys: ['covenants', 'covenant-checks', covenantCheckUuid, 'actions'],
      options: { enabled: !!covenantCheckUuid, retry: 1, ...options },
    }),
  )

export default useCovenantCheckActions
