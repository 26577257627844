import { SegmentedButton, SegmentedButtonItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Labeled from 'components/ui/data/Labeled'
import { useBusinessSegments } from 'hooks/services/deals/configurations/useBusinessSegments'

export const DealCreationBusinessSegmentButtons = ({
  businessSegmentCode = '',
  onBusinessSegmentSelect = (_, __) => {},
  ...additionalProps
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.dialog.general-information',
  })

  const {
    isLoading: isLoadingBusinessSegments,
    isError: isErrorBusinessSegments,
    data: businessSegmentsData,
  } = useBusinessSegments()

  const businessSegments =
    !isErrorBusinessSegments && !isLoadingBusinessSegments ? businessSegmentsData : []

  const getProductType = (code) =>
    businessSegments.find((businessSegment) => businessSegment.code === code)?.loanProductType

  const handleSelectionChange = (event) => {
    const selectedBusinessSegmentCode = event.detail?.selectedItem.getAttribute('data-key')
    const productType = getProductType(selectedBusinessSegmentCode)
    onBusinessSegmentSelect(selectedBusinessSegmentCode, productType)
  }
  const widthOfOneBusinessSegment = 55
  return (
    <Labeled label={t('business-segment.label')} vertical showColon required>
      <SegmentedButton
        id="deal-creation-business-segment-buttons"
        onSelectionChange={handleSelectionChange}
        style={{ marginTop: '5px', maxWidth: businessSegments.length * widthOfOneBusinessSegment }}
        {...additionalProps}
      >
        {businessSegments.map(({ code }) => (
          <SegmentedButtonItem key={code} data-key={code} pressed={code === businessSegmentCode}>
            {code}
          </SegmentedButtonItem>
        ))}
      </SegmentedButton>
    </Labeled>
  )
}

DealCreationBusinessSegmentButtons.propTypes = {
  businessSegmentCode: PropTypes.string,
  onBusinessSegmentSelect: PropTypes.func,
}
