import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useExternalLoadConsolidationPointsManualReservationTrigger = ({
  onSuccess,
  onError,
  onSettled,
}) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ dealUuid, reservationUuid }) => {
      await post({
        path: `/pbb-custom-service/deals/${dealUuid}/limit-management/load-consolidation-points-manual-reservation`,
        body: {
          credit_reservation_uuid: reservationUuid,
        },
      })
    },
    { onSuccess, onError, onSettled },
  )
}

export default useExternalLoadConsolidationPointsManualReservationTrigger
