import isNil from 'lodash.isnil'
import { useMemo } from 'react'
import { isConflictError, isNotFoundError } from 'api/requests'
import useRegulatoryInformation from 'components/domains/business-partners/tile/general-information/regulatoryInformation/useRegulatoryInformations'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import calculateEconomicProfitCost from 'hooks/services/business-events-and-tasks/decision-papers/tiles/general-information/helper/calculateEconomicProfitCost'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useAuthorityLevelCalculatorAuthorityLevel from 'hooks/services/business-partners/authority-level-calculator/useAuthorityLevelCalculatorAuthorityLevel'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import { useLargeExposureThresholds } from 'hooks/services/business-partners/risk-figures/useLargeExposureThresholds'
import { useApplicationTypes } from 'hooks/services/deals/configurations/useApplicationTypes'
import useGetPricing from 'hooks/services/deals/pricing/useGetPricing'
import useRiskConformity from 'hooks/services/deals/risk-conformity/useRiskConformity'
import useDeal from 'hooks/services/deals/useDeal'

const useCheckSheetBasicCreditAuthorityLevel = (
  { id: businessEventId, entityRef: { entityId: dealUuid } },
  tileId,
  tileCode,
) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({
    dealUuid,
    tileCode,
    isWorkingVersionInfoInSourcePath: true,
  })

  const {
    data: dealData,
    isFetching: isDealFetching,
    isError: isDealError,
    error: dealError,
  } = useDeal(dealUuidByTileCode)

  const isLoadApplicationTypeCodesEnabled = !isNil(dealData?.applicationTypeCode)
  const {
    isFetching: isApplicationTypesFetching,
    isError: isApplicationTypesError,
    error: applicaitonTypesError,
    data: applicationTypesData,
  } = useApplicationTypes({ enabled: isLoadApplicationTypeCodesEnabled })

  const {
    data: dealPricingData,
    isFetching: isDealPricingFetching,
    isError: isDealPricingError,
    error: dealPricingError,
  } = useGetPricing({ dealUuid: dealUuidByTileCode })

  const {
    data: largeExposureThresholdsData,
    isFetching: isLargeExposureThresholdsFetching,
    isError: isLargeExposureThresholdsError,
    error: largeExposureThresholdsError,
  } = useLargeExposureThresholds()

  const {
    data: dealRiskConformityData,
    isFetching: isDealRiskConformityFetching,
    isError: isDealRiskConformityError,
    error: dealRiskConformityError,
  } = useRiskConformity(dealUuidByTileCode)

  const normalizedIsRiskConformityError = useMemo(() => {
    if (isDealRiskConformityError) {
      if (isNotFoundError(dealRiskConformityError)) {
        return false
      }
      return true
    }
    return false
  }, [isDealRiskConformityError, dealRiskConformityError])

  const {
    data: bpBorrowerData,
    isFetching: isBpBorrowerFetching,
    isError: isBpBorrowerError,
    error: bpBorrowerError,
  } = useBusinessPartnerMiniByIds(dealData?.borrowerBpId ? [dealData.borrowerBpId] : [])

  const {
    data: regulatoryInformationsData,
    isFetching: isRegulatoryInformationsFetching,
    isError: isRegulatoryInformationsError,
    error: regulatoryInformationsError,
  } = useRegulatoryInformation({ businessPartnerId: dealData?.borrowerBpId })

  const noRegulatoryInformationsFoundError =
    regulatoryInformationsError && isNotFoundError(regulatoryInformationsError)

  const normalizedIsRegulatoryInformationsError = useMemo(() => {
    if (isRegulatoryInformationsError) {
      if (isNotFoundError(regulatoryInformationsError)) {
        return false
      }
      return true
    }
    return false
  }, [isRegulatoryInformationsError, regulatoryInformationsError])

  const {
    data: authorityLevelData,
    isFetching: isDealAuthorityLevelFetching,
    isError: isDealAuthorityLevelError,
    error: dealAuthorityLevelError,
  } = useAuthorityLevelCalculatorAuthorityLevel(
    {
      eventId: businessEventId,
    },
    { enabled: !!dealData?.borrowerBpId },
  )

  const isBusinessErrorForAlcData = useMemo(
    () => isDealAuthorityLevelError && isConflictError(dealAuthorityLevelError),
    [isDealAuthorityLevelError, dealAuthorityLevelError],
  )

  const normalizedIsDealAuthorityLevelError = useMemo(() => {
    if (isDealAuthorityLevelError) {
      if (isConflictError(dealAuthorityLevelError)) {
        return false
      }
      return true
    }
    return false
  }, [dealAuthorityLevelError, isDealAuthorityLevelError])

  const sourcePathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?working-version=true' : ''),
    [isWorkingVersionDataTile],
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isDealUuidFetching,
      isDealFetching,
      isApplicationTypesFetching && isLoadApplicationTypeCodesEnabled,
      isDealAuthorityLevelFetching,
      isBpBorrowerFetching,
      isLargeExposureThresholdsFetching,
      isDealRiskConformityFetching,
      isRegulatoryInformationsFetching,
      isDealPricingFetching,
    ],
    errorValues: [
      isDealUuidError,
      isDealError,
      isApplicationTypesError,
      normalizedIsDealAuthorityLevelError,
      isBpBorrowerError,
      isLargeExposureThresholdsError,
      normalizedIsRiskConformityError,
      normalizedIsRegulatoryInformationsError,
      isDealPricingError,
    ],
    errorDetails: [
      dealError,
      applicaitonTypesError,
      dealAuthorityLevelError,
      bpBorrowerError,
      largeExposureThresholdsError,
      dealRiskConformityError,
      regulatoryInformationsError,
      dealPricingError,
    ],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }
    const applicationType = applicationTypesData?.find(
      ({ code }) => code === dealData?.applicationTypeCode,
    )?.name
    const { isDirectCostNegative, isFullCostNegative, isLowerCostNegative } =
      calculateEconomicProfitCost({
        fullCost: dealPricingData.values?.economic_profit_amount_full_cost?.number,
        directCost: dealPricingData.values?.economic_profit_amount_direct_cost?.number,
        lowerCost: dealPricingData.values?.economic_profit_amount_lower_cost?.number,
      })
    return {
      isLoading: false,
      isError: false,
      data: {
        authorityLevelName: authorityLevelData?.authorityLevel?.authorityLevelName,
        applicationType,
        reviewUnit: authorityLevelData?.unit
          ? {
              bpId: authorityLevelData?.unit.unitHeadBpId,
              bpName: authorityLevelData?.unit.unitHeadBpFullName,
            }
          : null,
        totalCreditAuthorityLimit: authorityLevelData?.creditAuthorityLimit?.unitLevel
          ? {
              value: authorityLevelData?.creditAuthorityLimit.unitLevel.amount,
              currency: authorityLevelData?.creditAuthorityLimit.unitLevel.currency,
            }
          : null,
        totalExpectedLossClass: authorityLevelData?.expectedLossClass?.unitLevel,
        borrower: {
          id: dealData.borrowerBpId,
          name: bpBorrowerData.businessPartnerMinis?.[0]?.fullName,
        },
        borrowerCreditAuthorityLimit: authorityLevelData?.creditAuthorityLimit?.unitMemberLevel
          ? {
              value: authorityLevelData?.creditAuthorityLimit.unitMemberLevel.amount,
              currency: authorityLevelData?.creditAuthorityLimit.unitMemberLevel.currency,
            }
          : null,
        borrowerExpectedLossClass: authorityLevelData?.expectedLossClass?.unitMemberLevel,
        largeExposureLimitFlag: authorityLevelData?.calculatedLargeExposureLimitFlag,
        largeExposureThresholdReporting: largeExposureThresholdsData.largeExposureReportingThreshold
          ? {
              value: largeExposureThresholdsData.largeExposureReportingThreshold.value,
              currency: largeExposureThresholdsData.largeExposureReportingThreshold.unit,
            }
          : null,
        largeExposureLimit: largeExposureThresholdsData.largeExposureLimit
          ? {
              value: largeExposureThresholdsData.largeExposureLimit.value,
              currency: largeExposureThresholdsData.largeExposureLimit.unit,
            }
          : null,
        complianceWithRiskStrategy: dealRiskConformityData?.compliance,
        amlRiskClassification: noRegulatoryInformationsFoundError
          ? null
          : regulatoryInformationsData.amlRiskClassification?.name,
        isOrgankredit: authorityLevelData?.isOrgankredit,
        isDirectCostNegative,
        isFullCostNegative,
        isLowerCostNegative,
        isBusinessErrorForAlcData,
        sourceRender: {
          businessPartnerId: dealData.borrowerBpId,
          dealDisplayId: dealData.dealId,
          sourcePathSuffix,
        },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    error,
    dealData,
    authorityLevelData,
    bpBorrowerData,
    largeExposureThresholdsData,
    dealRiskConformityData,
    regulatoryInformationsData,
    noRegulatoryInformationsFoundError,
    dealPricingData,
    isBusinessErrorForAlcData,
    applicationTypesData,
    sourcePathSuffix,
  ])
}

export default useCheckSheetBasicCreditAuthorityLevel
