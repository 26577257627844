import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import TenancyOverviewTileTableWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/TenancyOverviewTileTableWrapper'

export const timePeriodOptions = ['yearly', 'monthly']

/**
 * V1 and V2 are the same, except for the inner components TenancyOverviewTileV1,TenancyOverviewTileV2
 */
const MultiTenancyOverviewTileV1V2 = ({ tileId, isPdfView, selectedDealIndex }) => {
  const {
    data: { multipleTenancyOverviewData, dealsData },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const selectedDealUuid = dealsData[selectedDealIndex]?.dealUuid

  const [selectedTimePeriod, setSelectedTimePeriod] = useState(timePeriodOptions[0])

  const onTimePeriodChange = useCallback((event) => {
    const nextTimePeriod = event?.detail?.selectedOption?.dataset?.id
    if (nextTimePeriod) {
      setSelectedTimePeriod(nextTimePeriod)
    }
  }, [])

  let tenancyOverview = Object.values(
    multipleTenancyOverviewData.tenancyOverviewPerDealUuid ?? {},
  )?.find(({ dealUuid } = {}) => dealUuid === selectedDealUuid)

  if (isEmpty(tenancyOverview?.topTenants)) {
    tenancyOverview = undefined
  }

  // TenancyOverviewTileTable is reused because the tile is actually the same.
  // The only difference is that the data from the live hook contains information about every deal, therefore
  // this tile is copy and owned from TenancyOverviewTile.jsx
  return !isPdfView ? (
    <TenancyOverviewTileTableWrapper
      tenancyOverview={tenancyOverview}
      tileId={tileId}
      timePeriod={selectedTimePeriod}
      onTimePeriodChange={onTimePeriodChange}
      isPdfView={isPdfView}
    />
  ) : (
    timePeriodOptions.map((option) => (
      <TenancyOverviewTileTableWrapper
        key={`tenancy-overview-tile-pdf-view-${option}`}
        tileId={tileId}
        tenancyOverview={tenancyOverview}
        timePeriod={option}
        onTimePeriodChange={() => {}}
        isPdfView={isPdfView}
      />
    ))
  )
}

MultiTenancyOverviewTileV1V2.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultiTenancyOverviewTileV1V2
