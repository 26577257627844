import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/DisplayCardViewItemView'

const dash = '-'

const GeneralInformationUnderwriterField = ({ underwriter, loanAccountManager }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.general-information.tile.fields',
  })

  const underwriterLabel = useMemo(() => {
    if (!loanAccountManager && underwriter) {
      return t('underwriter')
    }
    if (loanAccountManager && !underwriter) {
      return t('loan-account-manager')
    }
    return t('underwriter-and-loan-account-manager')
  }, [loanAccountManager, t, underwriter])

  const underwriterValue = useMemo(
    () =>
      [underwriter?.fullName, loanAccountManager?.fullName].filter((name) => !!name).join(' / ') ||
      dash,
    [loanAccountManager?.fullName, underwriter?.fullName],
  )

  return (
    <DisplayCardViewItemView>
      <DisplayCardViewInfoItemView label={underwriterLabel} value={underwriterValue} />
    </DisplayCardViewItemView>
  )
}

GeneralInformationUnderwriterField.propTypes = {
  underwriter: PropTypes.shape({
    fullName: PropTypes.string,
  }),
  loanAccountManager: PropTypes.shape({
    fullName: PropTypes.string,
  }),
}

export default GeneralInformationUnderwriterField
