import {
  Bar,
  BarDesign,
  Button,
  ButtonDesign,
  Dialog,
  FlexBox,
  Icon,
  RadioButton,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/components/ui/tables/toolbar/GroupingDialog.module.css'
import toolbarStyles from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/components/ui/tables/toolbar/TablesToolbar.module.css'

const GroupingDialog = ({ groups, setGroupBy }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.events.components.ui.tables.toolbar.group',
  })
  const defaultGroup = groups.find(({ isGrouped }) => isGrouped)?.id
  const [selectedGroup, setSelectedGroup] = useState(defaultGroup)

  const onCancel = () => {
    setSelectedGroup(defaultGroup)
    setIsOpen(false)
  }

  const onSubmit = () => {
    setIsOpen(false)
    setGroupBy(selectedGroup)
  }

  const groupInputs = groups.map(({ id, label }) => (
    <RadioButton
      key={id}
      text={label}
      onChange={(evt) => evt?.target?.checked && setSelectedGroup(id)}
      checked={id === selectedGroup}
    />
  ))

  return (
    <>
      <Icon
        className={toolbarStyles.groupIcon}
        name="group-2"
        interactive
        onClick={() => setIsOpen(true)}
      />
      {createPortal(
        <Dialog
          footer={
            <Bar
              design={BarDesign.Footer}
              endContent={
                <>
                  <Button design={ButtonDesign.Emphasized} onClick={onSubmit}>
                    {t('submit')}
                  </Button>
                  <Button design={ButtonDesign.Transparent} onClick={onCancel}>
                    {t('cancel')}
                  </Button>
                </>
              }
            />
          }
          header={
            <div className={styles.groupingHeader}>
              <div className={styles.groupingHeaderContainer}>
                <div className={styles.groupingHeaderStart}>
                  <Title className={styles.groupingTitle} level={TitleLevel.H2}>
                    {t('header')}
                  </Title>
                </div>
                <div className={styles.groupingHeaderEnd} />
              </div>
            </div>
          }
          open={isOpen}
          onAfterClose={onCancel}
        >
          <FlexBox direction="Column">
            <RadioButton
              text={t('none')}
              onChange={() => setSelectedGroup(null)}
              checked={!selectedGroup}
              style={{ fontStyle: 'italic' }}
            />
            {groupInputs}
          </FlexBox>
        </Dialog>,
        document.body,
      )}
    </>
  )
}
GroupingDialog.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isGrouped: PropTypes.bool,
      label: PropTypes.string,
    }),
  ).isRequired,
  setGroupBy: PropTypes.func.isRequired,
}

export default GroupingDialog
