import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  ObjectStatus,
  ResponsiveGridLayout,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/links/DecisionPaperLeaveConfirmationLink'
import AnnualReviewDealOverviewBorrowerOverviewLabeledValue from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/involved-parties/AnnualReviewDealOverviewBorrowerOverviewLabeledValue'
import determineValueStateBasedOnAssessmentCategory from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/assessments/determineValueStateBasedOnAssessmentCategory'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/borrower/BorrowerOverviewTile.module.css'
import {
  convertLegalDataObjectToString,
  getLegalDataAndFoundationDatesFromMembers,
} from 'hooks/services/business-events-and-tasks/decision-papers/tiles/gcc-involved-parties/borrower/useBorrowerOverviewUtils'

const AnnualReviewDealOverviewBorrowerOverviewInformation = ({
  tileId,
  selectedDealIndex,
  showConfirmationForLinks,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.bp-borrower-overview.information',
  })
  const { format } = useShortDateFormatter()
  const { data: tileData } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const {
    multipleBusinessPartnersData,
    multipleBorrowerGroupMembersData,
    multipleRegulatoryInformationData,
    multipleAssessmentsData,
  } = tileData ?? {}

  const businessPartnerData = multipleBusinessPartnersData?.[selectedDealIndex]
  const borrowerGroupMembersData = multipleBorrowerGroupMembersData?.[selectedDealIndex]
  const regulatoryInformationData = multipleRegulatoryInformationData?.[selectedDealIndex]
  const assessmentData = multipleAssessmentsData?.[selectedDealIndex]

  const information = useMemo(() => {
    const { legalFormMap, foundationDatesMap } = getLegalDataAndFoundationDatesFromMembers(
      borrowerGroupMembersData ?? [],
    )
    const legalForm = !isEmpty(borrowerGroupMembersData)
      ? convertLegalDataObjectToString(legalFormMap)
      : businessPartnerData?.legalForm
    const foundationDates =
      isNil(foundationDatesMap.min) || isNil(foundationDatesMap.max)
        ? { min: businessPartnerData?.foundationDate, max: businessPartnerData?.foundationDate }
        : foundationDatesMap

    return isNil(businessPartnerData)
      ? undefined
      : {
          businessPartnerId: businessPartnerData?.id,
          name: businessPartnerData.fullName,
          legalForm: legalForm,
          foundationDates,
          industryInformation: businessPartnerData.industry,
          lastKycCheck: businessPartnerData.kyc?.lastCheckDate,
          kycResult: regulatoryInformationData?.amlRiskClassification?.name,
          overallQualityAssessment: {
            category: assessmentData?.assessments?.[0]?.category,
            level: assessmentData?.assessments?.[0]?.level,
            levelDisplayValue: assessmentData?.assessments?.[0]?.levelDisplayValue,
          },
        }
  }, [
    assessmentData?.assessments,
    borrowerGroupMembersData,
    businessPartnerData,
    regulatoryInformationData?.amlRiskClassification?.name,
  ])

  const foundationDateDisplayValue = useMemo(() => {
    if (isNil(information?.foundationDates?.min) && isNil(information?.foundationDates?.max)) {
      return undefined
    }
    const { foundationDates } = information
    if (isNil(information?.foundationDates?.max)) {
      return format(foundationDates.min)
    }
    if (isNil(information?.foundationDates?.min)) {
      return format(foundationDates.max)
    }
    const minAsDate = DateTime.fromISO(foundationDates.min)
    const maxAsDate = DateTime.fromISO(foundationDates.max)
    if (
      minAsDate.day === maxAsDate.day &&
      minAsDate.month === maxAsDate.month &&
      minAsDate.year === maxAsDate.year
    ) {
      return format(foundationDates.min)
    }
    return `${format(foundationDates.min)} to ${format(foundationDates.max)}`
  }, [format, information])

  const lastKycCheckValue = useMemo(() => {
    if (isEmpty(information?.kycResult) && isEmpty(information?.lastKycCheck)) {
      return undefined
    }
    if (isEmpty(information?.kycResult)) {
      return format(information.lastKycCheck)
    }
    if (isEmpty(information?.lastKycCheck)) {
      return information.kycResult
    }
    return `${format(information.lastKycCheck)} (${information.kycResult})`
  }, [information?.lastKycCheck, information?.kycResult, format])

  const businessPartnerNameValue = useMemo(() => {
    if (isEmpty(information?.name)) {
      return undefined
    }
    if (isEmpty(information.businessPartnerId)) {
      return information.name
    }
    return (
      <DecisionPaperLeaveConfirmationLink
        name={information.name}
        link={`/business-partners/${information.businessPartnerId}`}
        showConfirmationForLinks={showConfirmationForLinks}
      />
    )
  }, [information?.businessPartnerId, information?.name, showConfirmationForLinks])

  const content = useMemo(() => {
    if (isNil(information)) {
      return (
        <FlexBox
          className={styles.listCardEmptyContent}
          alignItems={FlexBoxAlignItems.Center}
          justifyContent={FlexBoxJustifyContent.Center}
        >
          {t('empty')}
        </FlexBox>
      )
    }
    return (
      <ResponsiveGridLayout columnsXL={2} columnsL={2} columnsM={2} columnsS={2}>
        <FlexBox direction={FlexBoxDirection.Column}>
          {!isEmpty(information?.name) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={t('business-partner-name')}
              value={businessPartnerNameValue}
            />
          )}
          {!isEmpty(information?.businessPartnerId) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={t('business-partner-id')}
              value={information.businessPartnerId}
            />
          )}
          {!isEmpty(information?.legalForm) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={t('legal-form')}
              value={information.legalForm}
            />
          )}
          {!isEmpty(foundationDateDisplayValue) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={t('foundation-date')}
              value={foundationDateDisplayValue}
            />
          )}
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column}>
          {!isNil(information?.industryInformation?.sectorId) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={t('industry-code')}
              value={`${information.industryInformation.sectorId} - ${information.industryInformation.keyDescription}`}
            />
          )}
          {!isEmpty(lastKycCheckValue) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={t('last-kyc-check')}
              value={lastKycCheckValue}
            />
          )}
          {!isNil(information?.overallQualityAssessment?.category) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={t('overall-quality-assessment')}
              value={
                <ObjectStatus
                  inverted
                  state={determineValueStateBasedOnAssessmentCategory(
                    information?.overallQualityAssessment?.category,
                  )}
                >
                  {`${information?.overallQualityAssessment?.level}-${information?.overallQualityAssessment?.levelDisplayValue}`}
                </ObjectStatus>
              }
            />
          )}
        </FlexBox>
      </ResponsiveGridLayout>
    )
  }, [businessPartnerNameValue, foundationDateDisplayValue, information, lastKycCheckValue, t])

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.listCard}>
      <Text className={styles.listCardTitle}>{t('title')}</Text>
      {content}
    </FlexBox>
  )
}

AnnualReviewDealOverviewBorrowerOverviewInformation.propTypes = {
  tileId: PropTypes.string.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
}

export default AnnualReviewDealOverviewBorrowerOverviewInformation
