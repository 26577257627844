import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import PortfolioFinancedAssetsImagesSection from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-information/PortfolioFinancedAssetsImagesSection'
import PropertyInformationSection from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-information/PropertyInformationSection'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-information/shared/NoPropertyAssignedToDealInfo'

const PortfolioFinancedAssetsInformationTile = ({
  tileId,
  isPdfView,
  showConfirmationForLinks,
}) => {
  const tileState = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const tileImages = useSelector((state) => state.decisionPaper.imagesForTiles.tiles[tileId])
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const selectedProperty = useMemo(
    () =>
      tileImages?.data?.propertyImages?.[selectedImageIndex]?.propertyUuid ||
      tileState?.data?.propertyInformation?.data?.[0]?.uuid,
    [selectedImageIndex, tileImages, tileState],
  )

  const propertyList = useMemo(() => tileState?.data?.propertyInformation?.data ?? [], [tileState])

  if (tileState?.data.noProperties) {
    return <NoPropertyAssignedToDealInfo />
  }

  return isPdfView ? (
    propertyList.map((property) => (
      <div key={property.uuid}>
        <PortfolioFinancedAssetsImagesSection
          tileId={tileId}
          isPdfView={isPdfView}
          setSelectedImageIndex={setSelectedImageIndex}
          propertyUuid={property.uuid}
        />
        <PropertyInformationSection
          tileId={tileId}
          showConfirmationForLinks={showConfirmationForLinks}
          selectedProperty={property.uuid}
          isPdfView={isPdfView}
        />
      </div>
    ))
  ) : (
    <>
      <PortfolioFinancedAssetsImagesSection
        tileId={tileId}
        isPdfView={isPdfView}
        setSelectedImageIndex={setSelectedImageIndex}
      />
      <PropertyInformationSection
        tileId={tileId}
        showConfirmationForLinks={showConfirmationForLinks}
        selectedProperty={selectedProperty}
        isPdfView={isPdfView}
      />
    </>
  )
}
PortfolioFinancedAssetsInformationTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
}

export default PortfolioFinancedAssetsInformationTile
