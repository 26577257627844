import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import FurtherDetailsPieChartsCard from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/FurtherDetailsPieChartsCard'
import {
  UsageTypeColorProvider,
  useUsageTypeColors,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/UsageTypeColorContext'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'

const FurtherDetailsGranularityUsageTypeTile = ({ tileId, isPdfView }) => {
  const {
    data: { multiPropertyKpisByGranularity, usageTypeCodes, noProperties },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const getUsageTypeRentRollKpis = (multiPropertyKpis) =>
    multiPropertyKpis?.segmentUsageTypeRentRollKpis ?? []

  const getUsageTypeName = (rentRollKpi) =>
    usageTypeCodes.find((usageTypeCode) => usageTypeCode.key === rentRollKpi?.segmentUsageTypeCode)
      ?.displayName

  if (noProperties) {
    return <NoPropertyAssignedToDealInfo />
  }
  return (
    <FurtherDetailsPieChartsCard
      colorProvider={UsageTypeColorProvider}
      useColors={useUsageTypeColors}
      getRentRollKpis={getUsageTypeRentRollKpis}
      getValuations={() => {}}
      getNameFromKpi={getUsageTypeName}
      granularityValues={{
        multiPropertyKpisByGranularity,
        multiPropertyValuationsByGranularity: {},
      }}
      isPdfView={isPdfView}
    />
  )
}

FurtherDetailsGranularityUsageTypeTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default FurtherDetailsGranularityUsageTypeTile
