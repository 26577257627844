import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useAreaMeasurementUnitFormatter,
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
} from 'hooks/i18n/useI18n'
import { tenancyHeaderInformationValueType } from 'hooks/services/business-partners/tenancy/tenancyHeaderInformationValueType'

export const useTenancyKpiValueFormatter = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.business-partner.tenancy.as-tenant',
  })
  const { t: tNoPrefix } = useTranslation()
  const formatAreaUnit = useAreaMeasurementUnitFormatter()
  const formatYears = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  })
  const formatPieces = useNumberFormatter({
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })
  const formatArea = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formatCurrency = useCustomizableCurrencyFormatter()
  return useCallback(
    ({ kpiValue, kpiValueType, unit }) => {
      if (isNaN(kpiValue)) {
        return '-'
      }
      switch (kpiValueType) {
        case tenancyHeaderInformationValueType.area:
          return `${formatArea(kpiValue)} ${formatAreaUnit(unit)}`
        case tenancyHeaderInformationValueType.currency:
          return formatCurrency(kpiValue, unit, { currencyDisplay: 'code' })
        case tenancyHeaderInformationValueType.pieces:
          return `${formatPieces(kpiValue)} ${tNoPrefix('formatters.area-measurement-unit.pcs')}`
        case tenancyHeaderInformationValueType.plain:
          return formatPieces(kpiValue)
        case tenancyHeaderInformationValueType.years:
          return `${formatYears(kpiValue)} ${t('wault.unit')}`
        default:
          return '-'
      }
    },
    [formatArea, formatAreaUnit, formatCurrency, formatPieces, formatYears, t, tNoPrefix],
  )
}
