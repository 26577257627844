import { Link } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/related-documents/AssociatedBusinessPartnerBorrowers.module.css'
import Card from 'components/ui/card/Card'
import Entity from 'components/ui/data/Entity'
import Section from 'components/ui/page/Section'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'

const maxInitialBorrowersToShow = 5
const maxBorrowerNameLength = 80
const borrowerNameSuffix = '…'

const buildBorrowerDisplayName = (name = '') => {
  if (name.length <= maxBorrowerNameLength) return name
  return `${name.substring(
    0,
    maxBorrowerNameLength - borrowerNameSuffix.length,
  )}${borrowerNameSuffix}`
}

const AssociatedBusinessPartnerBorrower = ({ businessPartners = [], isLoading, isError }) => {
  const [expanded, setExpanded] = useState(false)
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-partner.associated-borrower',
  })

  const toggleIsExpanded = () => setExpanded((prev) => !prev)

  const numberOfBorrowers = businessPartners.length
  const borrowersToShow = expanded
    ? businessPartners
    : businessPartners.slice(0, maxInitialBorrowersToShow)
  const showExpandLink = numberOfBorrowers > maxInitialBorrowersToShow
  const linkText = t(expanded ? 'collapse' : 'expand', { count: numberOfBorrowers })

  return (
    <Section
      titleText={t('title')}
      actions={
        showExpandLink && (
          <Link className={styles.expandLink} onClick={toggleIsExpanded}>
            {linkText}
          </Link>
        )
      }
    >
      <LoadingStateWrapper isLoading={isLoading} isError={isError}>
        {borrowersToShow.length ? (
          <div className={styles.entries}>
            {borrowersToShow.map(({ fullName, id }) => (
              <Card key={id}>
                <Entity
                  className={styles.entry}
                  id={id}
                  name={buildBorrowerDisplayName(fullName)}
                  link={`/business-partners/${id}/documents`}
                  openInNewTab
                />
              </Card>
            ))}
          </div>
        ) : (
          <div className={styles.noData}>
            <p className={styles.noDataTitle}>{t('no-borrower-title')}</p>
            <p className={styles.noDataSubtitle}>{t('no-borrower-subtitle')}</p>
          </div>
        )}
      </LoadingStateWrapper>
    </Section>
  )
}

AssociatedBusinessPartnerBorrower.propTypes = {
  businessPartners: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
}

export default AssociatedBusinessPartnerBorrower
