import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import PropertyFinancingStatusCell from 'components/domains/properties/common/PropertyFinancingStatusCell'

const tenancyFinancingStatusPropTypes = {
  cell: PropTypes.shape({
    value: PropTypes.shape({
      financingStatusCode: PropTypes.string,
    }),
  }).isRequired,
}
/** @param {PropTypes.InferProps<typeof tenancyFinancingStatusPropTypes>} props */
const TenancyFinancingStatusCell = ({ cell: { value } }) => {
  if (isNil(value)) {
    return
  }
  return <PropertyFinancingStatusCell isLoading={false} isError={false} propertyData={value} />
}

TenancyFinancingStatusCell.propTypes = tenancyFinancingStatusPropTypes

export default TenancyFinancingStatusCell
