export const homePagePreferencesTranslationKeys = {
  NEUTRAL: 'neutral',
  DEAL_CENTRIC: 'deal-centric',
  PROPERTY_CENTRIC: 'property-centric',
}

export const homePagePreferences = {
  NEUTRAL: 'NEUTRAL',
  DEAL_CENTRIC: 'DEAL_CENTRIC',
  PROPERTY_CENTRIC: 'PROPERTY_CENTRIC',
}
