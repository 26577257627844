import { useRequest } from 'hooks/services/baseService'

/**
 * Returns all property cost codes and their display name
 * The Costcodes structure is as follows:
 * {
 *   "cost_codes": [
 *      {
 *           "key": "000004",
 *           "display_name": "Kosten der Eintragung"
 *       },
 *       {
 *           "key": "000005",
 *           "display_name": "Nebenkosten"
 *       },
 *       [...]
 *   ]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: costCodes}}
 *
 */
export const useCostCodes = () =>
  useRequest({
    path: '/properties/cost-codes',
    translated: true,
  })
