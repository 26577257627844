import { ComboBox, ComboBoxItem, FilterGroupItem, Input } from '@fioneer/ui5-webcomponents-react'
import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js'
import clone from 'lodash.clone'
import isNil from 'lodash.isnil'
import negate from 'lodash.negate'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CountryComboBox from 'components/domains/business-partners/filterbar/CountryComboBox'
import TenantFilterRolesComboBox from 'components/domains/business-partners/filterbar/TenantFilterRolesComboBox'
import DashboardFilterBar from 'components/ui/page/DashboardFilterBar'
import countActiveFilters from 'components/ui/page/countActiveFilters'

const BusinessPartnerFilterBar = ({
  initialValues = {},
  onGo,
  additionalFilterBarProperties = {},
}) => {
  const filterOptions = {
    q: { visibleOnBar: true },
    id: { visibleOnBar: true },
    street: { visibleOnBar: true },
    city: { visibleOnBar: true },
    countries: { visibleOnBar: true },
    status: { visibleOnBar: true },
    excludeInactive: { visibleOnBar: true },
    roles: { visibleOnBar: true },
  }
  const { t } = useTranslation()
  const [filterParams, setFilterParams] = useState(initialValues)
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleTextInputChange = (event) => {
    const value = event.target.value.trim()
    event.target.value = value
    const key = event.target.name

    // If changes are made to a dialog input, ensure that the corresponding bar input is updated as well (if it exists)
    // This logic REQUIRES that every input has a valid ID otherwise an error will occur
    const barElement = document.querySelector(`#dashboard-filterbar #${event.target.id}`)
    const shouldUpdateBarElement = !isNil(barElement) && barElement !== event.target
    if (shouldUpdateBarElement) {
      barElement.value = value
    }

    setFilterParams({
      ...filterParams,
      [key]: value,
    })
  }

  const onEnter = (event) => {
    // Only perform search if enter is pressed in the filter bar (meaning filter dialog not open)
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && !dialogOpen) {
      event.stopPropagation()
      onGo(clone(filterParams))
    }
  }

  const handleMultiComboboxChange = ({ detail: { items } }, filterFieldName) => {
    const nonNilItems = items.map((element) => element.dataset.value).filter(negate(isNil))
    setFilterParams({ ...filterParams, [filterFieldName]: nonNilItems })
  }

  const handleGo = () => {
    onGo(clone(filterParams))
  }

  const handleCountrySelectionChange = ({ detail: { items } = {} }) => {
    const countries =
      items?.map(({ dataset }) => dataset.value).filter((countryCode) => !!countryCode) ?? []
    setFilterParams({ ...filterParams, countries: countries })
  }

  const handleStatusChange = (event) => {
    const isNewExcludeInactive = event.detail.item.dataset.value === 'exclude'
    setFilterParams({ ...filterParams, excludeInactive: isNewExcludeInactive })
  }

  const filters = [
    <FilterGroupItem label={t('pages.business-partner-search.filter.search-key.label')} key="q">
      <Input
        id="business-partner-dashboard-search-key"
        value={filterParams.q}
        onFocus={(event) => event.stopPropagation()}
        onInput={handleTextInputChange}
        name="q"
        onKeyDown={onEnter}
        showClearIcon
      />
    </FilterGroupItem>,
    <FilterGroupItem label={t('pages.business-partner-search.filter.id.label')} key="id">
      <Input
        id="business-partner-dashboard-id-filter"
        value={filterParams.id}
        onFocus={(event) => event.stopPropagation()}
        onInput={handleTextInputChange}
        name="id"
        onKeyDown={onEnter}
        showClearIcon
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={t('pages.business-partner-search.filter.country.label')}
      key="countries"
    >
      <CountryComboBox
        id="business-partner-dashboard-country-filter"
        selectedValues={filterParams.countries}
        onSelectionChange={handleCountrySelectionChange}
      />
    </FilterGroupItem>,
    <FilterGroupItem label={t('pages.business-partner-search.filter.city.label')} key="city">
      <Input
        id="business-partner-dashboard-city-filter"
        value={filterParams.city}
        onFocus={(event) => event.stopPropagation()}
        onInput={handleTextInputChange}
        name="city"
        onKeyDown={onEnter}
        showClearIcon
      />
    </FilterGroupItem>,
    <FilterGroupItem label={t('pages.business-partner-search.filter.street.label')} key="street">
      <Input
        id="business-partner-dashboard-street-filter"
        value={filterParams.street}
        onFocus={(event) => event.stopPropagation()}
        onInput={handleTextInputChange}
        name="street"
        onKeyDown={onEnter}
        showClearIcon
      />
    </FilterGroupItem>,
    <FilterGroupItem label={t('pages.business-partner-search.filter.roles.label')} key="roles">
      <TenantFilterRolesComboBox
        selectedRoles={filterParams.roles}
        handleMultiComboboxChange={handleMultiComboboxChange}
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={t('pages.business-partner-search.filter.exclude-inactive.label')}
      key="status"
    >
      <ComboBox
        id="business-partner-dashboard-status-filter"
        value={t(filterParams.excludeInactive ? 'exclude' : 'include', {
          keyPrefix: 'pages.business-partner-search.filter.exclude-inactive',
        })}
        onFocus={(event) => event.stopPropagation()}
        onSelectionChange={handleStatusChange}
      >
        <ComboBoxItem
          data-value="exclude"
          text={t('pages.business-partner-search.filter.exclude-inactive.exclude')}
        />
        <ComboBoxItem
          data-value="include"
          text={t('pages.business-partner-search.filter.exclude-inactive.include')}
        />
      </ComboBox>
    </FilterGroupItem>,
  ]

  return (
    <DashboardFilterBar
      filterOptions={filterOptions}
      activeFiltersCount={countActiveFilters(filterParams) + Number(filterParams.excludeInactive)}
      onGo={handleGo}
      setDialogOpen={setDialogOpen}
      additionalProperties={additionalFilterBarProperties}
    >
      {filters}
    </DashboardFilterBar>
  )
}

BusinessPartnerFilterBar.propTypes = {
  initialValues: PropTypes.shape({
    q: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    countries: PropTypes.array.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    excludeInactive: PropTypes.bool.isRequired,
  }),
  onGo: PropTypes.func.isRequired,
  additionalFilterBarProperties: PropTypes.shape({}),
}

export default BusinessPartnerFilterBar
