import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

/**
 *  @typedef {PropTypes.InferProps<typeof ValuationsRequestsTableReasonSelect.propTypes>} ValuationsRequestsTableReasonSelect
 */
export const ValuationsRequestsTableReasonSelect = ({
  reason,
  valuationReasons,
  onChange,
  disabled,
}) => {
  const handleOnReasonChange = useCallback(
    ({
      detail: {
        selectedOption: { value: selectedStatus },
      },
    }) => {
      onChange(selectedStatus)
    },
    [onChange],
  )

  return (
    <Select id="edit-valuation-request-reason" onChange={handleOnReasonChange} disabled={disabled}>
      <Option key={-1} value={''} selected={!reason} />
      {valuationReasons.valuation_reasons.map((entry) => (
        <Option key={entry.reason} value={entry.reason} selected={reason === entry.reason}>
          {entry.reason}
        </Option>
      ))}
    </Select>
  )
}

export const valuationReasonsShape = PropTypes.shape({
  valuation_reasons: PropTypes.arrayOf(
    PropTypes.shape({
      reason: PropTypes.string.isRequired,
    }),
  ),
})

ValuationsRequestsTableReasonSelect.propTypes = {
  valuationReasons: valuationReasonsShape,
  reason: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}
