import { useMemo } from 'react'

const useMappedUses = ({
  mappedUsesWithShare,
  totalSourcesAmount,
  totalSourcesAmountHeadquarter,
  availableCurrencies,
  headquarterCurrency,
  areAllCurrenciesTheSame,
}) =>
  useMemo(() => {
    const result = [...(mappedUsesWithShare || [])]

    const totalValueOriginal = {
      currency: availableCurrencies[0],
      value: totalSourcesAmount,
    }
    const totalValueHeadquarter = {
      currency: headquarterCurrency,
      value: totalSourcesAmountHeadquarter,
    }

    result.push({
      isSectionHeader: true,
      original: areAllCurrenciesTheSame ? totalValueOriginal : totalValueHeadquarter,
      headquarter: areAllCurrenciesTheSame ? totalValueOriginal : totalValueHeadquarter,
      share: 1,
    })

    return result
  }, [
    mappedUsesWithShare,
    totalSourcesAmount,
    totalSourcesAmountHeadquarter,
    availableCurrencies,
    headquarterCurrency,
    areAllCurrenciesTheSame,
  ])

export default useMappedUses
