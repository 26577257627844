import {
  FlexBoxJustifyContent,
  ObjectStatus,
  TableGrowingMode,
  TableMode,
  TableRowType,
} from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/BusinessPartnerTable.module.css'
import caseInsensitiveOrderBy from 'components/domains/business-partners/caseInsensitiveOrderBy'
import BusinessPartnerRolesView from 'components/domains/business-partners/roles/BusinessPartnerRolesView'
import IndustryCellComponent from 'components/domains/rentroll/IndustryCellComponent'
import Entity from 'components/ui/data/Entity'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import SortedTableErrorMessage from 'components/ui/tables/sorted-tables/SortedTableErrorMessage'
import { useNumberFormatter } from 'hooks/i18n/useI18n'
import determineValueStateBasedOnStatus from 'routes/business-partners/determineValueState'

const BusinessPartnerTable = ({
  businessPartners,
  onChange,
  isLoading,
  isError,
  hasNextPage,
  fetchNextPage,
  total,
  isMultiSelect = false,
  isBeforeSearch = false,
  selectedBusinessPartnerIds = [],
  lockedSelectedBusinessPartners = [],
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner-search' })
  const { t: noPrefixT } = useTranslation()
  const numberFormatter = useNumberFormatter()
  const disabledSort = true

  const isBusinessPartnerLocked = useCallback(
    (id) =>
      lockedSelectedBusinessPartners?.some(
        ({ id: lockedBusinessPartnerId } = {}) => lockedBusinessPartnerId === id,
      ),
    [lockedSelectedBusinessPartners],
  )

  const columnDefinitions = useMemo(
    () => [
      {
        title: t('table.score.label'),
        columnKey: 'score',
        isVisible: false,
      },
      {
        title: t('table.toolbar.title', { count: 1 }),
        columnKey: 'businessPartner',
      },
      {
        title: t('table.country.label'),
        columnKey: 'countries',
        sortingDisabled: disabledSort,
      },
      {
        title: t('table.postal-code.label'),
        columnKey: 'postalCode',
        sortingDisabled: disabledSort,
        alignment: FlexBoxJustifyContent.End,
      },
      {
        title: t('table.city.label'),
        columnKey: 'city',
        sortingDisabled: disabledSort,
      },
      {
        title: t('table.street.label'),
        columnKey: 'streetName',
        sortingDisabled: disabledSort,
      },
      {
        title: t('table.roles.label'),
        columnKey: 'roles',
        sortingDisabled: disabledSort,
      },
      {
        title: t('table.industry.label'),
        columnKey: 'industry',
        sortingDisabled: disabledSort,
      },
      {
        title: t('table.status.label'),
        columnKey: 'status',
        sortingDisabled: disabledSort,
      },
    ],
    [disabledSort, t],
  )

  const tableData = useMemo(
    () =>
      businessPartners.map(
        ({
          id,
          name,
          address: { country, postalCode, city, streetName },
          status,
          roles,
          searchScore,
        }) => ({
          rowKey: id,
          score: {
            cellComponent: <>{numberFormatter(searchScore)}</>,
            value: searchScore,
          },
          businessPartner: {
            cellComponent: (
              <Entity name={name} id={id} link={`/business-partners/${id}`} openInNewTab />
            ),
            value: name,
          },
          countries: {
            cellComponent: <>{country?.name}</>,
            value: country?.name,
          },
          postalCode: {
            cellComponent: <>{postalCode}</>,
            value: postalCode,
          },
          city: {
            cellComponent: <>{city}</>,
            value: city,
          },
          streetName: {
            cellComponent: <>{streetName}</>,
            value: streetName,
          },
          rowProperties: {
            name,
            id,
            selected: selectedBusinessPartnerIds.includes(id),
            type: isMultiSelect ? TableRowType.Inactive : TableRowType.Active,
            onClick: () => {
              if (isMultiSelect) {
                onChange([id], true)
              }
            },
            className: isBusinessPartnerLocked(id) ? styles.disabledRow : '',
          },
          roles: {
            cellComponent: (
              <BusinessPartnerRolesView
                roles={
                  roles?.map(({ name: roleName, ...role }) => ({
                    ...role,
                    name: noPrefixT(`components.business-partner.roles.${roleName}`),
                  })) ?? []
                }
              />
            ),
          },
          industry: { cellComponent: <IndustryCellComponent id={id} /> },
          status: {
            cellComponent: (
              <ObjectStatus inverted state={determineValueStateBasedOnStatus(status)}>
                {t(status, { keyPrefix: 'pages.business-partner.general-information.status' })}
              </ObjectStatus>
            ),
            value: status,
          },
        }),
      ),
    [
      businessPartners,
      numberFormatter,
      selectedBusinessPartnerIds,
      isMultiSelect,
      isBusinessPartnerLocked,
      t,
      onChange,
      noPrefixT,
    ],
  )

  const handleSelection = ({ detail }) => {
    onChange(
      detail.selectedRows?.map(({ attributes }) => attributes?.id?.value).filter((id) => !!id),
    )
  }

  return (
    <>
      {isError ? (
        <SortedTableErrorMessage />
      ) : (
        <div
          className={compact([
            styles.tableWrapper,
            !isBeforeSearch && !isLoading && styles.fixTableHeight,
          ]).join(' ')}
        >
          <SortedTable
            columnDefinitions={columnDefinitions}
            tableData={isBeforeSearch && !isLoading ? [] : tableData}
            customOrderFunction={caseInsensitiveOrderBy}
            noDataText={isBeforeSearch ? t('before-initial-search') : t('no-results')}
            additionalTableProperties={{
              stickyColumnHeader: true,
              onSelectionChange: handleSelection,
              mode: isMultiSelect ? TableMode.MultiSelect : TableMode.SingleSelect,
              onFocus: (event) => event.stopPropagation(),
              busy: isLoading,
            }}
            toolbarConfig={{
              title: t('table.toolbar.title', { count: tableData.length }),
              sorting: null,
              showColumnSelection: false,
            }}
            paginationConfig={
              isBeforeSearch
                ? {}
                : {
                    growing: hasNextPage ? TableGrowingMode.Button : undefined,
                    growingButtonText: t('growing-button-text'),
                    growingButtonSubtext: '[ ' + businessPartners.length + ' / ' + total + ' ]',
                    totalNumberOfItems: total,
                    loadMore: () => fetchNextPage(),
                  }
            }
          />
        </div>
      )}
    </>
  )
}

BusinessPartnerTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  businessPartners: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  hasNextPage: PropTypes.bool.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  isBeforeSearch: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  selectedBusinessPartnerIds: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  lockedSelectedBusinessPartners: PropTypes.array,
}

export default BusinessPartnerTable
