import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import { ratingProceduresSchema } from 'hooks/services/business-partners/ratings/ratingProcedureSchema'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/** @typedef {import('zod').infer<typeof ratingProceduresSchema>} RatingProcedures  */

/**
 * @returns {Omit<import('@tanstack/react-query').UseQueryResult, 'data'> & {data: (RatingProcedures|undefined)}} the result of the useRatingProcedures hook
 */
export const useRatingProcedures = () => {
  const result = useCamelizedResponse(
    useRequest({
      path: '/businesspartners/value-helps/rating-procedures',
      keys: ['f4helps', 'rating-procedures'],
    }),
  )

  const parsed = useMemo(
    () => ratingProceduresSchema.optional().safeParse(result.data),
    [result.data],
  )

  if (!parsed.success) {
    return { ...result, data: undefined, isError: true, error: parsed.error }
  }

  return { ...result, data: parsed.data }
}
