import {
  FlexBox,
  FlexBoxDirection,
  Input,
  Label,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/rent-roll/segment/segment-create/SegmentGroupedInput.module.css'
import { useAreaMeasurementUnitFormatter } from 'hooks/i18n/useI18n'

const SegmentTotalAreaInput = ({ totalArea, setTotalArea, areaMeasureUnit }) => {
  const formatArea = useAreaMeasurementUnitFormatter()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.rent-roll-working-version.segment-creation',
  })

  const handleOnInput = (e) => {
    const totalArea = e.target.value
    setTotalArea(totalArea)
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label>{t('total-area')}</Label>
      <Input
        id="segment-total-area-input"
        icon={
          <Label id="segment-total-area-input-label" className={styles.segmentInputLabel}>
            {formatArea(areaMeasureUnit)}
          </Label>
        }
        valueState={ValueState.None}
        value={totalArea}
        onInput={handleOnInput}
        type="Number"
      />
    </FlexBox>
  )
}
SegmentTotalAreaInput.propTypes = {
  totalArea: PropTypes.number,
  setTotalArea: PropTypes.func.isRequired,
  areaMeasureUnit: PropTypes.string.isRequired,
}
export default SegmentTotalAreaInput
