import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/financing-structure/syndication/MultiExistingBusinessSyndicationStructureTileForSelectedDeal.module.css'
import useMultiExistingBusinessSyndicationStructureColumnDefinitions from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/financing-structure/syndication/useMultiExistingBusinessSyndicationStructureColumnDefinitions'
import useMultiExistingBusinessSyndicationStructureTableData from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/financing-structure/syndication/useMultiExistingBusinessSyndicationStructureTableData'
import AnalyticalTableNoDataComponent from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/analytical/AnalyticalTableWithToolbar'
import { useCollapseExpandTableActions } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/analytical/useCollapseExpandTableActions'
import AnalyticalTableScalingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/AnalyticalTableScalingWrapper'

const MultiExistingBusinessSyndicationStructureTileForSelectedDeal = ({
  tileId,
  isPdfView,
  selectedDealIndex,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.existing-business-syndication-structure.table',
  })
  const tableRef = useRef(null)
  const [tableWidth, setTableWidth] = useState(tableRef?.current?.totalColumnsWidth ?? 0)

  useEffect(() => {
    setTableWidth(tableRef?.current?.totalColumnsWidth ?? 0)
  }, [tableRef?.current?.totalColumnsWidth])

  const {
    data: {
      multipleSyndicationsExistingBusinessData = [],
      financialRolesData = [],
      allBusinessPartnersData = [],
    } = {},
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const syndicationData = useMemo(
    () => multipleSyndicationsExistingBusinessData?.[selectedDealIndex] ?? {},
    [multipleSyndicationsExistingBusinessData, selectedDealIndex],
  )

  const {
    handleOnRowExpand,
    expandOrCollapseSubRows,
    additionalTableActions: collapseExpandActions = [],
  } = useCollapseExpandTableActions(tableRef)

  const tableData = useMultiExistingBusinessSyndicationStructureTableData({
    syndicationData,
    allBusinessPartnersData,
  })

  const columns = useMultiExistingBusinessSyndicationStructureColumnDefinitions({
    financialRolesData,
  })

  useEffect(() => {
    if (!isEmpty(tableRef?.current?.data) || syndicationData?.id) {
      expandOrCollapseSubRows(true)
    }
  }, [tableRef?.current?.data, selectedDealIndex, expandOrCollapseSubRows, syndicationData?.id])

  return (
    <AnalyticalTableScalingWrapper isPdfView={isPdfView} tableWidth={tableWidth}>
      <div className={styles.tableWrapper}>
        <AnalyticalTableWithToolbar
          title={t('title')}
          showNrOfEntries={false}
          ref={tableRef}
          rowHeight={'individual'}
          headerRowHeight={30}
          onRowExpandChange={(event) => handleOnRowExpand(event.detail.row)}
          additionalActions={collapseExpandActions}
          // high number of visible rows fixes re-rendering of height on expand
          visibleRows={99}
          sortable={false}
          filterable={false}
          id="existingBusinessSyndicationStructureTable"
          isTreeTable
          data={tableData}
          columns={columns}
          NoDataComponent={() => (
            <AnalyticalTableNoDataComponent
              isLoading={false}
              isError={false}
              tableId="existingBusinessSyndicationStructureTable"
            />
          )}
        />
      </div>
    </AnalyticalTableScalingWrapper>
  )
}

MultiExistingBusinessSyndicationStructureTileForSelectedDeal.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultiExistingBusinessSyndicationStructureTileForSelectedDeal
