const selectAllRowsBelongingToCondition = (state, { payload: { conditionIndex, gotSelected } }) => {
  if (gotSelected) {
    state.conditions[conditionIndex].requirements.forEach((_value, requirementIndex) => {
      state.selectedRows[`${conditionIndex}.${requirementIndex}`] = true
    })
    return
  }
  state.conditions[conditionIndex].requirements.forEach((_value, requirementIndex) => {
    delete state.selectedRows[`${conditionIndex}.${requirementIndex}`]
  })
}

export default selectAllRowsBelongingToCondition
