import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const getUserRecentVisitsGetKey = (entityType) => ['recent-visit', entityType]

/**
 * Hook that user recent visits filtered by given entityType
 *
 * @param entityType {('DEAL'|'MARKET'|'BUSINESS_PARTNER'|'PROPERTY')} should be one of cwpEntityTypes
 * @param queryOptions {Object}  are the react-query query options
 * @returns {{data, isError, isLoading}}
 */
const useUserRecentVisitsGet = (entityType, queryOptions = {}) => {
  const response = useRequest({
    path: `/user-profile/recent-visit?entity_type=${entityType}`,
    keys: getUserRecentVisitsGetKey(entityType),
    options: queryOptions,
  })
  return useCamelizedResponse(response)
}

export default useUserRecentVisitsGet
