import { Button, ButtonDesign, Dialog, Icon, Input, Title } from '@fioneer/ui5-webcomponents-react'
import iconDecline from '@ui5/webcomponents-icons/dist/decline.js'
import iconSearch from '@ui5/webcomponents-icons/dist/search.js'
import { useSyncRef } from '@ui5/webcomponents-react-base'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/periodical-checks/checks/table/shared/toolbar/ToolbarColumnSelectDialog.module.css'
import useAnalyticalTableDialogFocusFix from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/analytical-table/useAnalyticalTableDialogFocusFix'

const propTypes = {
  onAfterOpen: PropTypes.func,
  onConfirm: PropTypes.func,
  onAfterClose: PropTypes.func,
  onSearchInput: PropTypes.func,
  onSearchReset: PropTypes.func,
  onHandleClear: PropTypes.func,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
  children: PropTypes.node,
  open: PropTypes.bool,
}

/**
 *
 @param {PropTypes.InferProps<typeof propTypes>} props
 */
const ToolbarColumnSelectDialog = (props) => {
  const {
    children,
    onAfterOpen = () => {},
    onConfirm = () => {},
    onAfterClose = () => {},
    onSearchInput = () => {},
    onSearchReset = () => {},
    onHandleClear = () => {},
    onMoveUp = () => {},
    onMoveDown = () => {},
    ...rest
  } = props

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'components.table.personalization.columns.dialog',
  })
  const [searchValue, setSearchValue] = useState('')
  const [componentRef, selectDialogRef] = useSyncRef()

  const handleAfterOpen = (e) => {
    if (typeof onAfterOpen === 'function') {
      onAfterOpen(e)
    }
  }

  const handleSearchInput = (e) => {
    onSearchInput(e.target.value)
    setSearchValue(e.target.value)
  }

  const handleResetSearch = (e) => {
    onSearchReset(e)
    setSearchValue('')
  }

  const handleAfterClose = () => {
    onAfterClose()
    onSearchReset()
    setSearchValue('')
  }

  const handleClose = () => {
    selectDialogRef.current.close()
    handleAfterClose()
  }

  const handleConfirm = () => {
    if (typeof onConfirm === 'function') {
      onConfirm()
    }
    selectDialogRef.current.close()
  }

  const handleMoveSelectionUp = () => {
    onMoveUp()
  }

  const handleMoveSelectionDown = () => {
    onMoveDown()
  }

  useAnalyticalTableDialogFocusFix({ dialogRef: selectDialogRef })

  return (
    <Dialog
      {...rest}
      data-component-name="SelectDialog"
      ref={componentRef}
      className={styles.dialog}
      onAfterClose={handleAfterClose}
      onAfterOpen={handleAfterOpen}
    >
      <div className={styles.headerContent} slot="header">
        <Title className={styles.title}>{t('title')}</Title>
        <Button
          onClick={onHandleClear}
          design={ButtonDesign.Transparent}
          className={styles.clearBtn}
        >
          {t('clear')}
        </Button>

        <Input
          className={styles.input}
          value={searchValue}
          placeholder={t('search')}
          onInput={handleSearchInput}
          icon={
            <>
              {searchValue && (
                <Icon
                  title={t('reset')}
                  name={iconDecline}
                  interactive
                  onClick={handleResetSearch}
                  className={styles.inputIcon}
                />
              )}
              <Icon
                title={t('search')}
                name={iconSearch}
                interactive
                onClick={handleSearchInput}
                className={styles.inputIcon}
              />
            </>
          }
        />
        <div className={styles.arrows}>
          <Icon
            title={t('moveUp')}
            name="navigation-up-arrow"
            interactive
            onClick={handleMoveSelectionUp}
            slot="icon"
            className={styles.arrow}
          />
          <Icon
            title={t('moveDown')}
            name="navigation-down-arrow"
            interactive
            onClick={handleMoveSelectionDown}
            slot="icon"
            className={styles.arrow}
          />
        </div>
      </div>
      {children}
      <div slot="footer" className={styles.footer}>
        <Button onClick={handleConfirm} design={ButtonDesign.Emphasized}>
          {t('confirm')}
        </Button>

        <Button onClick={handleClose} design={ButtonDesign.Transparent}>
          {t('cancel')}
        </Button>
      </div>
    </Dialog>
  )
}

ToolbarColumnSelectDialog.displayName = 'ToolbarColumnSelectDialog'

ToolbarColumnSelectDialog.propTypes = propTypes

export default ToolbarColumnSelectDialog
