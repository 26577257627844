// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-select-error-text {
  font-size: var(--sapFontSize);
  padding: var(--sapUiTinySpace);
  text-align: center;
  color: var(--sapErrorColor);
}

.loading-select-text {
  padding: var(--sapUiTinySpace);
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/select/LoadingSelect.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,8BAA8B;EAC9B,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,8BAA8B;EAC9B,WAAW;AACb","sourcesContent":[".loading-select-error-text {\n  font-size: var(--sapFontSize);\n  padding: var(--sapUiTinySpace);\n  text-align: center;\n  color: var(--sapErrorColor);\n}\n\n.loading-select-text {\n  padding: var(--sapUiTinySpace);\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
