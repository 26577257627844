import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useMarketPropertyAllowedOperations = ({ propertyUuid, options = {} }) => {
  const queryParams = new URLSearchParams()
  queryParams.append('property_id', propertyUuid)
  return useCamelizedResponse(
    useRequest({
      path: `/markets/allowed-operations?${queryParams.toString()}`,
      keys: ['markets', 'allowed-operations', propertyUuid],
      cached: true,
      options,
    }),
  )
}

export default useMarketPropertyAllowedOperations
