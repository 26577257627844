import findKey from 'lodash.findkey'
import isNil from 'lodash.isnil'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CartesianGrid, Legend, Line, Tooltip, XAxis, YAxis } from 'recharts'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-history/PropertyValuationResultsHistoryLineChartChildren.module.css'
import PropertyValuationResultsHistoryLineChartTooltip from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-history/PropertyValuationResultsHistoryLineChartTooltip'
import {
  valuationClassification,
  valuationClassificationAbbreviationsTranslated,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/domains/property/valuation/valuationRequests'
import {
  useShortDateFormatter,
  useNumberFormatter,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const AXIS_COLOR = '#9B9B9B'
const LINE_DOT_RADIUS = 4

export const VALUATION_TYPE_COLOR_MAPPING = {
  marketValue: '#5899DA',
  sellOffMarketValue: '#ED4A7B',
  mortgageLendingValue: '#19A778',
  sellOffMortgageLendingValue: '#E8743B',
  currentStateMortgageLendingValue: '#945ECF',
}

const CustomizedDot = ({ cx, cy, stroke, payload: { cwpCode } = {}, dataKey }) => {
  const valuationCodeKey = findKey(valuationClassification, (value) => value === cwpCode)

  if (!!dataKey && valuationCodeKey === dataKey) {
    return (
      <svg>
        <circle cx={cx} cy={cy} r={LINE_DOT_RADIUS} fill={stroke} />
      </svg>
    )
  }

  return null
}

CustomizedDot.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  stroke: PropTypes.string,
  payload: PropTypes.shape({
    cwpCode: PropTypes.string,
  }),
  dataKey: PropTypes.string,
}

const PropertyValuationResultsHistoryLineChartChildren = ({ additionalLineChartArguments }) => {
  const { format: formatDate } = useShortDateFormatter()
  const formatNumber = useNumberFormatter({ notation: 'compact' })
  const formatUnixDate = (timestamp) => formatDate(DateTime.fromMillis(timestamp).toISODate())

  const { t } = useTranslation('decisionPaper')

  const { presentValuationTypes = [], getInterpolatedValuationValueByCodeAndKeyDate } =
    additionalLineChartArguments ?? {}

  const domainExtension = 1.25
  const yAxisDomain = [0, (dataMax) => dataMax * domainExtension]

  const renderTooltip = ({ payload }) => {
    const { dataKey, payload: { keyDate, currency } = {} } = payload?.[0] ?? {}

    if (!isNil(dataKey)) {
      const interpolatedValues = presentValuationTypes?.map((valuationType) => [
        valuationClassification?.[valuationType],
        getInterpolatedValuationValueByCodeAndKeyDate(valuationType, keyDate),
      ])

      return (
        <PropertyValuationResultsHistoryLineChartTooltip
          valuationValues={interpolatedValues}
          keyDate={keyDate}
          currency={currency}
        />
      )
    }
  }

  const renderCustomizedDot = (props) => <CustomizedDot {...props} />

  return (
    <>
      <CartesianGrid vertical={false} />
      {presentValuationTypes.map((type, index) => {
        const valuationTypeCode = valuationClassification?.[type]

        return (
          <Line
            connectNulls
            key={index}
            type="stepAfter"
            dataKey={type}
            name={t(valuationClassificationAbbreviationsTranslated?.[valuationTypeCode])}
            stroke={VALUATION_TYPE_COLOR_MAPPING?.[type]}
            strokeWidth={1.5}
            dot={renderCustomizedDot}
          />
        )
      })}
      <YAxis
        type="number"
        scale="linear"
        interval="preserveStartEnd"
        axisLine={false}
        tickLine={false}
        tickFormatter={formatNumber}
        domain={yAxisDomain}
      />
      <XAxis
        // we need to set a numerical x-axis type (default is categorical) since we might have overlapping time values
        type="number"
        dataKey="dateUnixTimestamp"
        // using a ['dataMin', 'dataMax'] domain would result in non-equidistant x-axis ticks,
        // instead 'auto' prepends some x-axis values in order to preserve equidistant ticks
        domain={['auto', 'auto']}
        tickLine={false}
        padding={{
          left: 20,
          right: 20,
        }}
        // in order to populate the x-axis we need to define a high maximum tick count and then restrict it via a higher minTickGap
        // with this, the x-axis is responsive while using numeric value types
        tickCount={20}
        minTickGap={60}
        angle={-45}
        // using "preserveStartEnd" would also allow non-equidistant ticks, and sadly, there is no "equidistantPreserveStartEnd"
        interval="equidistantPreserveStart"
        axisLine={{ stroke: AXIS_COLOR }}
        textAnchor="end"
        tickFormatter={
          (dateUnixTimestamp) => `${formatUnixDate(dateUnixTimestamp)} ` // empty character added so that the tick at the end does not get cut off
        }
      />
      <Tooltip cursor={true} content={renderTooltip} />
      <Legend
        wrapperStyle={{ marginBottom: '-40px' }}
        layout="horizontal"
        align="center"
        verticalAlign="bottom"
        iconType="line"
        formatter={(value) => <span className={styles.tooltipItem}>{value}</span>}
      />
    </>
  )
}

PropertyValuationResultsHistoryLineChartChildren.propTypes = {
  additionalLineChartArguments: PropTypes.shape({
    presentValuationTypes: PropTypes.arrayOf(PropTypes.string),
    getInterpolatedValuationValueByCodeAndKeyDate: PropTypes.func,
  }),
}

export default PropertyValuationResultsHistoryLineChartChildren
