import { Toast } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { FileDrop } from 'react-file-drop'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/document/DocumentFileDrop.module.css'

const DocumentFileDrop = React.forwardRef(
  (
    { onDrop, customContainerStyles, isOver = false, canDrop = false, isVersionDrop = false },
    ref,
  ) => {
    const { t } = useTranslation('translation', {
      keyPrefix: 'components.ui.document.document-file-drop',
    })

    useEffect(() => {
      if (canDrop) {
        ref?.current?.show()
      }
    }, [canDrop, ref])

    const dropText = isOver ? t('can-drop-message') : t('drop-message')
    const versionDropText = isOver ? t('version-can-drop-message') : t('drop-message')

    return (
      <>
        <FileDrop
          className={customContainerStyles || styles.container}
          targetClassName={styles.target}
          draggingOverFrameClassName={styles.draggingOverContainer}
          draggingOverTargetClassName={styles.draggingOverTarget}
          onDrop={onDrop}
        />
        {canDrop && (
          <Toast className={styles.toast} ref={ref} duration={60000}>
            {isVersionDrop ? versionDropText : dropText}
          </Toast>
        )}
      </>
    )
  },
)

DocumentFileDrop.displayName = 'DocumentFileDrop'

DocumentFileDrop.propTypes = {
  onDrop: PropTypes.func.isRequired,
  customContainerStyles: PropTypes.string,
  isOver: PropTypes.bool,
  canDrop: PropTypes.bool,
  isVersionDrop: PropTypes.bool,
}

export default DocumentFileDrop
