import { useTranslation } from 'react-i18next'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'

const useTranslatedWorkingVersionTypes = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.working-version-types',
  })
  return [
    {
      code: WorkingVersionType.LIVE,
      name: t('live'),
    },
    {
      code: WorkingVersionType.WORKING_VERSION,
      name: t('working-version'),
    },
  ]
}

export default useTranslatedWorkingVersionTypes
