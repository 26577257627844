import isEmpty from 'lodash.isempty'

const conditionApprovalLevelChanged = (state, { payload: { approvalLevel: newApprovalLevel } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }
  state.editedRow.currentValues.approvalLevel = newApprovalLevel
  delete state.editedRow.errorFields.approvalLevel
  if (newApprovalLevel === state.editedRow.initialValues.approvalLevel) {
    delete state.editedRow.changedFields.approvalLevel
    return
  }
  state.editedRow.changedFields.approvalLevel = true
}

export default conditionApprovalLevelChanged
