import {
  unitCodeIsPieces,
  useAreaMeasureUnitCodes,
} from 'hooks/services/properties//useAreaUnitOfMeasureCodes'

export const useAreaUnitOfMeasureCodesNoPcs = () => {
  const { isLoading, isError, data: areaMeasureUnitCodes } = useAreaMeasureUnitCodes()

  const areaMeasureUnitCodesNoPcs = areaMeasureUnitCodes?.measure_unit_codes.filter(
    (measureUnitCode) => !unitCodeIsPieces(measureUnitCode.key),
  )

  return { isLoading, isError, data: areaMeasureUnitCodesNoPcs }
}
