import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useTranchesWithSubEntities = ({ dealUuid, dataSource }) => {
  const queryParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams()

    if (dataSource) {
      urlSearchParams.append('dataSource', dataSource)
    }

    return urlSearchParams
  }, [dataSource])

  const paramsString = [...queryParams.keys()].length ? `?${queryParams.toString()}` : ''

  let result = useRequest({
    path: `/financing-structures/deals/${dealUuid}/tranches-with-sub-entities${paramsString}`,
    translated: true,
    keys: ['deals', dealUuid, 'tranches', 'tranchesWithSubEntities', 'dataSource', dataSource],
    options: {
      enabled: !!dealUuid,
    },
  })

  const embeddedOptionsNotCamelized = []

  if (result?.data?.tranches) {
    for (const tranche of result.data.tranches) {
      embeddedOptionsNotCamelized.push({
        trancheId: tranche?.tranche_id,
        options: tranche?.options,
      })
    }
  }

  result = useCamelizedResponse(result)

  return {
    ...result,
    data: result?.data,
    embeddedOptions: embeddedOptionsNotCamelized,
  }
}

export default useTranchesWithSubEntities
