import {
  AnalyticalTable,
  AnalyticalTableHooks,
  AnalyticalTableScaleWidthMode,
  AnalyticalTableScrollMode,
  AnalyticalTableSelectionBehavior,
  AnalyticalTableSelectionMode,
  FlexBox,
  FlexBoxDirection,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { conditionTypes } from 'api/conditions/conditions'
import styles from 'components/domains/conditions/dialogs/condition-creation/ConditionCreationTable.module.css'
import ConditionCreationTableEmpty from 'components/domains/conditions/dialogs/condition-creation/ConditionCreationTableEmpty'
import ConditionCreationToolbar from 'components/domains/conditions/dialogs/condition-creation/ConditionCreationToolbar'
import {
  getConditionIndexFromId,
  getRequirementIndexFromId,
} from 'hooks/services/conditions/tables/useAnalyticalTableIndexHelper'
import useConditionCreationTableColumns from 'hooks/services/conditions/tables/useConditionCreationTableColumns'
import {
  selectAllRowsBelongingToCondition,
  setAllRowsExpanded,
  setRowExpanded,
  setSelectedRows,
  toggleRowExpanded,
} from 'redux/slices/conditions/conditionCreationSlice'

const ConditionCreationTable = ({ conditionType }) => {
  const conditions = useSelector((state) => state.conditions.conditionsCreation.conditions)
  const selectedRows = useSelector((state) => state.conditions.conditionsCreation.selectedRows)
  const { columns } = useConditionCreationTableColumns({ conditionType })
  const dispatch = useDispatch()
  const tableRef = useRef()
  const tableDomRefForScrolling = useRef()

  // This is a hack for keeping the state of the Analytical Table in Sync with the
  // Redux state. Other solutions did not work as expected.
  useEffect(() => {
    tableRef.current?.toggleAllRowsSelected(false)
    Object.keys(selectedRows).forEach((rowId) => tableRef.current.toggleRowSelected(rowId, true))
  }, [selectedRows])

  const focusElement = useSelector((state) => state.conditions.conditionsCreation.focusElement)

  useEffect(() => {
    if (focusElement && focusElement.conditionIndex) {
      tableDomRefForScrolling.current.scrollToItem(
        focusElement.conditionIndex,
        AnalyticalTableScrollMode.center,
      )
    }
  }, [focusElement])

  const onRowExpandChange = useCallback(
    ({
      code: eventCode,
      detail: {
        row: { index: conditionIndex },
      },
    }) => {
      if (eventCode === 'Space') {
        return
      }
      dispatch(toggleRowExpanded(conditionIndex))
    },
    [dispatch],
  )

  const expandAndSubselectExternalConditionRow = useCallback(
    ({ rowId, selectedRowIds }) => {
      const conditionIndex = getConditionIndexFromId(rowId)
      const requirementIndex = getRequirementIndexFromId(rowId)

      // Requirement cell has been selected, do nothing
      if (requirementIndex !== -1) {
        return
      }

      // The current row is atm behind the selection. If it is not selected now, it got selected
      const gotConditionSelected = !!selectedRowIds[`${conditionIndex}`]
      if (gotConditionSelected) {
        dispatch(setRowExpanded({ conditionIndex, expanded: true }))
      }
      dispatch(
        selectAllRowsBelongingToCondition({ conditionIndex, gotSelected: gotConditionSelected }),
      )
    },
    [dispatch],
  )

  const onRowSelect = useCallback(
    ({ detail: { selectedRowIds, row, allRowsSelected } }) => {
      dispatch(setSelectedRows(selectedRowIds))

      if (conditionType === conditionTypes.internal) {
        return
      }

      if (allRowsSelected) {
        dispatch(setAllRowsExpanded())
        return
      }

      if (!row) {
        return
      }

      expandAndSubselectExternalConditionRow({ rowId: row.id, selectedRowIds })
    },
    [dispatch, conditionType, expandAndSubselectExternalConditionRow],
  )

  return (
    <FlexBox className={styles.tableWrapper} direction={FlexBoxDirection.Column}>
      <ConditionCreationToolbar conditionType={conditionType} />
      <AnalyticalTable
        ref={tableDomRefForScrolling}
        tableInstance={tableRef}
        className={styles.table}
        columns={columns}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
        selectionBehavior={AnalyticalTableSelectionBehavior.RowSelector}
        minRows={1}
        rowHeight={40}
        headerRowHeight={40}
        groupable={false}
        filterable={false}
        sortable={false}
        overscanCount={5}
        subRowsKey="requirements"
        onRowExpandChange={onRowExpandChange}
        onRowSelect={onRowSelect}
        data={conditions}
        isTreeTable
        reactTableOptions={{
          conditionType,
          autoResetSelectedRows: false,
          useControlledState: (state) =>
            useMemo(() => ({ ...state, selectedRowIds: selectedRows }), [state, selectedRows]), // eslint-disable-line react-hooks/exhaustive-deps
        }}
        selectionMode={AnalyticalTableSelectionMode.MultiSelect}
        NoDataComponent={ConditionCreationTableEmpty}
        tableHooks={[AnalyticalTableHooks.useManualRowSelect()]}
      />
    </FlexBox>
  )
}

ConditionCreationTable.propTypes = {
  conditionType: PropTypes.oneOf(Object.values(conditionTypes)),
}

export default ConditionCreationTable
