import camelize from 'camelize'
import { useMemo } from 'react'
import snakecaseKeys from 'snakecase-keys'
import { useRequest } from 'hooks/services/baseService'
import { forbearanceStatusesSchema } from 'hooks/services/risk-monitoring/forbearance/statuses/forbearanceStatusesSchema'

/**
 * @param {object} params
 * @param {string} params.businessPartnerId
 * @param {string} [params.sortBy]
 * @param {string} [params.orderBy]
 * @param {Omit<Parameters<typeof useRequest>[0]["options"], "enabled">} [params.options]
 */
const useForbearanceStatuses = ({ businessPartnerId, sortBy, orderBy, options = {} }) => {
  const params = new URLSearchParams()
  if (sortBy) params.append('sortBy', sortBy)
  if (orderBy) params.append('orderBy', orderBy)

  const paramsString = [...params.keys()].length ? `?${params.toString()}` : ''

  const keys = [
    'risk-monitoring',
    'business-partners',
    businessPartnerId,
    'forbearance',
    'statuses',
  ]

  const result = useRequest({
    path: `/risk-monitoring/business-partners/${businessPartnerId}/forbearance/statuses${paramsString}`,
    options: {
      ...options,
      enabled: !!businessPartnerId,
    },
    keys,
    translated: true,
  })

  const snakeCased = useMemo(() => (result.data ? snakecaseKeys(result.data) : {}), [result.data])
  const parsed = forbearanceStatusesSchema.optional().safeParse(camelize(snakeCased))

  if (!parsed.success) {
    // eslint-disable-next-line no-console
    console.error(parsed.error)
    return { ...result, data: undefined, isError: true, error: parsed.error }
  }

  return { ...result, data: parsed.data }
}

export default useForbearanceStatuses
