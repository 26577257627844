export const dealsPermissions = {
  readDealList: 'DealList_Read',
  readDeal: 'Deal_Read',
  createDeal: 'Deal_Create',
  updateDeal: 'Deal_Update',
  updateDealUses: 'DealUses_Update',
  updateDealRiskStrategy: 'DealRiskStrategy_Update',
  updateDealMilestones: 'DealMilestone_Update',
  updateDealStatus: 'DealStatus_Update',
  updateDealTeamInternal: 'DealTeamInternal_Update',
  updateDealTeamExternal: 'DealTeamExternal_Update',
  readCashflowScenario: 'DealCashflowScenario_Read',
  createCashflowScenario: 'DealCashflowScenario_Create',
  updateCashflowScenario: 'DealCashflowScenario_Update', //Update Cashflow Data (Input, Used, KPI Results, CF Results
  updateCashflowScenarioGeneralInformation: 'DealCashflowScenarioGeneralInformation_Update', // Update Cashflow Meta Info
  updateCashflowScenarioMainScenario: 'DealCashflowScenarioMainScenario_Update', // Update "Main Scenario" in Cashflow Meta Info
  updateCashflowScenarioPBB: 'PBBCashflow_Update',
  deleteCashflowScenario: 'DealCashflowScenario_Delete',
  readPricing: 'DealPricing_Read',
  updatePricing: 'DealPricing_Update',
  updatePricingPBB: 'PBBPricing_Update',
  updateDealProperty: 'DealPropertyAssignUnassign_Update',
  updateDealPropertySubportfolio: 'DealPropertySubportfolio_Update',
  reportDealConflict: 'DealReportConflict_Update',
  updateStandardCollateralPackage: 'DealStandardCollateralPackage_Update',
  reportingUpdate: 'DealReporting_Update',
  updatePortfolioAppreciation: 'DealPropertiesAppreciation_Update',
  updateCounterParty: 'DealCounterparty_Update',
  createCag: 'DealCollateralAgreementNewBusinessReceivables_Update',
  createDealWorkingVersion: 'DealWorkingVersion_Create',
  publishDealWorkingVersion: 'DealWorkingVersion_Publish',
  deleteDealWorkingVersion: 'DealWorkingVersion_Delete',
  loadConsolidationPointsPBB: 'PBBConsolidationPoints_Load',
  requestReservationPBB: 'PBBReservation_Request',
  createReservationPBB: 'PBBReservation_Create',
  deleteReservationPBB: 'PBBReservation_Delete',
  synchronizeReservationPBB: 'PBBReservation_Synchronize',
}
