import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import BusinessObjectSelectionInput from 'components/domains/conditions/business-object/BusinessObjectSelectionInput'
import CovenantCheckSelectionHelperDialog from 'components/domains/conditions/covenant-check-selection/CovenantCheckSelectionHelperDialog'

const CovenantCheckFilter = ({
  value: { name: covenantCheckName } = {},
  setSelectedFilters,
  columnKey,
}) => {
  const [isHelperDialogOpen, setIsHelperDialogOpen] = useState(false)

  const onCancelCovenantCheckSelection = useCallback(() => {
    setIsHelperDialogOpen(false)
  }, [])

  const onClickCovenantCheckSelection = useCallback(() => {
    setIsHelperDialogOpen(true)
  }, [])

  const onSelectionChanged = useCallback(
    ({ covenantCheck: { covenantCheckUuid, name } }) => {
      setSelectedFilters((currentlySelectedFilters) =>
        currentlySelectedFilters.map((filter) => {
          if (filter.columnKey !== columnKey) {
            return filter
          }
          return { ...filter, value: { covenantCheckUuid, name } }
        }),
      )
      setIsHelperDialogOpen(false)
    },
    [setSelectedFilters, columnKey],
  )

  const onClear = useCallback(() => {
    setSelectedFilters((currentlySelectedFilters) =>
      currentlySelectedFilters.map((filter) => {
        if (filter.columnKey !== columnKey) {
          return filter
        }
        return { ...filter, value: undefined }
      }),
    )
  }, [setSelectedFilters, columnKey])

  return (
    <>
      <BusinessObjectSelectionInput
        name={covenantCheckName}
        onClick={onClickCovenantCheckSelection}
        onClear={onClear}
        fallbackText={'covenant-fallback'}
      />

      <CovenantCheckSelectionHelperDialog
        isOpen={isHelperDialogOpen}
        onCancel={onCancelCovenantCheckSelection}
        onSelect={onSelectionChanged}
      />
    </>
  )
}

CovenantCheckFilter.propTypes = {
  value: PropTypes.shape({
    covenantCheckUuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  setSelectedFilters: PropTypes.func.isRequired,
  columnKey: PropTypes.string.isRequired,
}

export default CovenantCheckFilter
