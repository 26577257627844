import PropType from 'prop-types'
import ValuationRequestBusinessPartnerDisplay from 'components/domains/properties/valuation/table/table-cells/display/ValuationRequestBusinessPartnerDisplay'

/**
 *  @typedef {PropType.InferProps<typeof ValuationsRequestsTableValuerDisplay.propTypes>} ValuationsRequestsTableValuerCell
 */
const ValuationsRequestsTableValuerDisplay = ({ valuer, valuationRequestId }) =>
  valuer ? (
    <ValuationRequestBusinessPartnerDisplay
      valuerId={valuer}
      valuationRequestId={valuationRequestId}
    />
  ) : (
    '-'
  )

ValuationsRequestsTableValuerDisplay.propTypes = {
  valuer: PropType.string,
  valuationRequestId: PropType.string.isRequired,
}

export default ValuationsRequestsTableValuerDisplay
