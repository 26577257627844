import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDecisionStage = ({ eventId, decisionStageId }, options) =>
  useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/decision-stages/${decisionStageId}`,
      useCache: true,
      keys: ['events', eventId, 'decision-stages', decisionStageId],
      options,
    }),
  )

export default useDecisionStage
