import { useInfiniteRequest } from 'hooks/services/baseService'
import { useInfiniteRequestsWithTotalMemo } from 'hooks/services/queryHelper'

const useMarketValueConfirmations = (
  { propertyUuid: propertyId, orderBy, sortBy, limit },
  options,
) => {
  const queryParams = new URLSearchParams()
  const keys = ['property-monitoring', 'market-value-confirmations']
  if (propertyId) {
    queryParams.append('property_id', propertyId)
    keys.push(propertyId)
  }
  if (sortBy) {
    queryParams.append('sort_by', sortBy)
    keys.push(sortBy)
    queryParams.append('order_by', orderBy)
    keys.push(orderBy)
  }
  if (limit) {
    queryParams.append('limit', limit)
    keys.push(limit)
  }

  const result = useInfiniteRequest({
    path: '/property-monitoring/market-value-confirmations',
    queryParams,
    useCache: true,
    keys,
    options,
  })

  return useInfiniteRequestsWithTotalMemo(result, 'market_value_confirmations')
}

export default useMarketValueConfirmations
