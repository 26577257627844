import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Link,
  MessageBox,
  MessageBoxActions,
  MessageBoxTypes,
  MessageStrip,
  MessageStripDesign,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'

export const RELOAD_GEOCODE = 'RELOAD'
export const LOAD_GEOCODE = 'LOAD'
export const NO_VERIFY = 'NO_VERIFY'
export const SUCCESS = 'SUCCESS'

export const GeolocationMessageStrip = ({
  action,
  hasLink = true,
  handleUpdate,
  latitude,
  longitude,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.property-address-card.geolocation-message-box',
  })
  let messageStripContent = <></>
  let messageStripDesign = MessageStripDesign.Negative

  const [openMessageBox, setOpenMessageBox] = useState(false)
  const onSuccessClick = () => setOpenMessageBox(true)

  const googleLink = `https://www.google.com/maps/search/?api=1&query=${latitude}%2C${longitude}`
  const handleMessageBoxClose = (event) => {
    if (event.detail.action === MessageBoxActions.OK) {
      window.open(googleLink, '_blank')
    }
    setOpenMessageBox(false)
  }

  switch (action) {
    case SUCCESS:
      messageStripDesign = MessageStripDesign.Positive
      messageStripContent = (
        <>
          {t('success.title')}
          {hasLink && (
            <>
              <Link onClick={onSuccessClick}>{t('success.link')}</Link>
              {createPortal(
                <MessageBox
                  open={openMessageBox}
                  onClose={handleMessageBoxClose}
                  actions={[MessageBoxActions.OK, t('success.open-in-google.cancel')]}
                  type={MessageBoxTypes.Information}
                >
                  {t('success.open-in-google')}
                </MessageBox>,
                document.body,
              )}
            </>
          )}
        </>
      )
      break
    case LOAD_GEOCODE:
      messageStripContent = (
        <>
          {t('not-loaded.title')}
          {hasLink && <Link onClick={() => handleUpdate(false)}>{t('not-loaded.link')}</Link>}
        </>
      )
      break
    case RELOAD_GEOCODE:
      messageStripContent = (
        <>
          {t('no-match.title')}
          {hasLink && <Link onClick={() => handleUpdate(true)}>{t('no-match.link')}</Link>}
        </>
      )

      break
    case NO_VERIFY:
      messageStripDesign = MessageStripDesign.Information
      messageStripContent = <>{t('no-verify.title')}</>
  }
  return (
    <MessageStrip
      design={messageStripDesign}
      className="display-card-item"
      style={{ paddingTop: '16px', paddingBottom: '16px' }}
      hideCloseButton
    >
      <FlexBox
        fitContainer
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        alignItems={FlexBoxAlignItems.Center}
      >
        {messageStripContent}
      </FlexBox>
    </MessageStrip>
  )
}

GeolocationMessageStrip.propTypes = {
  action: PropTypes.string.isRequired,
  hasLink: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
}
