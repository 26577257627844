import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'

const DealCreationFormProvider = ({ children }) => {
  const form = useForm({ mode: 'onTouched' })
  return <FormProvider {...form}>{children}</FormProvider>
}

DealCreationFormProvider.propTypes = {
  children: PropTypes.element,
}

export default DealCreationFormProvider
