import { DynamicPageHeader } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { DealFilterBar, DealFilterKeys } from 'components/domains/deals/deal-search/DealFilterBar'
import styles from 'components/domains/deals/deal-search/DealSearchDialog.module.css'
import DealSearchTable, {
  DealSearchTableMode,
} from 'components/domains/deals/deal-search/DealSearchTable'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useDeals from 'hooks/services/deals/useDeals'
import { useInfiniteLoading } from 'hooks/services/useInfiniteLoading'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const initialSorting = {
  orderField: DealFilterKeys.Id,
  orderDirection: 'desc',
}
const limit = 50

const DealSelectionTable = ({ onSelectionChange, selectedReferences }) => {
  const { referencesMapping } = useContext(ConditionsContext)

  const initialFilterValues = useMemo(
    () => ({
      [DealFilterKeys.Borrower]: referencesMapping?.[cwpEntityTypes.DEAL]?.borrowerId,
    }),
    [referencesMapping],
  )

  const [filterParams, setFilterParams] = useState(initialFilterValues)
  const [sortingParams, setSortingParams] = useState(initialSorting)

  const mappedSelectedDealsToIds = useMemo(
    () => selectedReferences.map((reference) => reference.entityId),
    [selectedReferences],
  )

  const [selectedDeals, setSelectedDeals] = useState(mappedSelectedDealsToIds)

  useEffect(() => {
    setSelectedDeals(mappedSelectedDealsToIds)
  }, [mappedSelectedDealsToIds])

  const {
    isLoading: isLoadingDeals,
    isError: isErrorDeals,
    data: { deals: tableDeals = [], pagination = { offset: 0, limit: 0, total: 0 } } = {},
    loadMore: loadMoreDeals,
  } = useInfiniteLoading({
    requestHook: useDeals,
    requestHookArgs: {
      filter: {
        dealId: filterParams[DealFilterKeys.Id],
        dealName: filterParams[DealFilterKeys.Name],
        accountManager: filterParams[DealFilterKeys.AccountManager],
        businessSegments: filterParams[DealFilterKeys.BusinessSegments],
        dealTypes: filterParams[DealFilterKeys.Type],
        originationPlatform: filterParams[DealFilterKeys.OriginationPlatform],
        originationTeam: filterParams[DealFilterKeys.OriginationTeam],
        status: filterParams[DealFilterKeys.Status],
        borrowerId: filterParams[DealFilterKeys.Borrower],
        workingVersion: filterParams[DealFilterKeys.WorkingVersion],
      },
      sort: sortingParams,
    },
    dataListElement: 'deals',
    paginationDelta: limit,
  })

  const updateFilters = (newFilterParams, newSortingParams) => {
    setFilterParams(newFilterParams)
    setSortingParams(newSortingParams)
  }

  const handleFilterbarGo = (newFilters) => {
    updateFilters(newFilters, sortingParams)
  }

  const onSortingChanged = (orderField, orderDirection) => {
    const newSortingParams = { orderField, orderDirection }
    updateFilters(filterParams, newSortingParams)
  }

  const onSelectionChangeInternal = useCallback(
    (event) => {
      const selectedTableRows = event.detail.selectedRows
      const newlySelectedDeals = selectedTableRows.map(({ dataset: { dealUuid, dealName } }) => ({
        entityId: dealUuid,
        entityType: cwpEntityTypes.DEAL,
        entityName: dealName,
      }))
      onSelectionChange(newlySelectedDeals)
      setSelectedDeals(newlySelectedDeals)
    },
    [onSelectionChange],
  )

  return (
    <>
      <DynamicPageHeader>
        <DealFilterBar onGo={handleFilterbarGo} initialValues={initialFilterValues} />
      </DynamicPageHeader>
      <div className={styles['table-wrapper']}>
        <DealSearchTable
          deals={tableDeals}
          mode={DealSearchTableMode.MultiSelect}
          pagination={pagination}
          orderField={sortingParams.orderField}
          orderDirection={sortingParams.orderDirection}
          onSortingChanged={onSortingChanged}
          onSelectionChanged={onSelectionChangeInternal}
          selectedDealUuids={selectedDeals}
          isError={isErrorDeals}
          isLoading={isLoadingDeals}
          loadMore={loadMoreDeals}
        />
      </div>
    </>
  )
}

DealSelectionTable.propTypes = {
  selectedReferences: PropTypes.arrayOf(
    PropTypes.shape({
      entityId: PropTypes.string.isRequired,
      entityType: PropTypes.string.isRequired,
      displayId: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
}

export default DealSelectionTable
