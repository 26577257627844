import camelize from 'camelize'
import { useMemo } from 'react'
import { useInfiniteRequest } from 'hooks/services/baseService'

const type = {
  minutes: '/minutes',
  conditions: '/conditions',
  stage: '',
}

/* Stage type to determine the url: one of minutes/conditions/stage */
export const useAllDecisionStageVoters = (
  stageType,
  { status, userId } = {},
  { limit } = {},
  options,
) => {
  const queryParams = new URLSearchParams()
  const cacheKeys = ['events', stageType]

  if (userId) {
    queryParams.append('user_id', userId)
  }
  if (status) {
    status.forEach((singleStatus) => queryParams.append('status', singleStatus))
  }
  queryParams.append('sort_by', 'status_and_decision_date')
  queryParams.append('order_by', 'asc')

  const determineUrlType = useMemo(() => {
    switch (stageType) {
      case 'minutes':
        return type.minutes
      case 'conditions':
        return type.conditions
      default:
        return type.stage
    }
  }, [stageType])

  const result = useInfiniteRequest({
    path: `/events/decision-stages${determineUrlType}/voters`,
    queryParams: queryParams,
    useCache: true,
    keys: cacheKeys,
    limit,
    options,
  })

  const processedResponse = useMemo(() => {
    if (!result.data) return undefined
    return camelize(
      result.data.pages.reduce((acc, { records, total }) => ({
        records: [...acc.records, ...records],
        total,
      })),
    )
  }, [result])

  return { ...result, data: processedResponse }
}
