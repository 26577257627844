import find from 'lodash.find'
import groupBy from 'lodash.groupby'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { useMemo } from 'react'
import {
  TenancyCardTableColumnId,
  TenancyTableRowType,
} from 'components/domains/business-partners/tile/tenancy/tenant-unit-table/AsTenantTableConstants'
import { calculateTenancyTotalContractedRent } from 'hooks/services/business-partners/tenancy/calculateTenancyTotalContractedRent'
import { calculateTenancyTotalRentedArea } from 'hooks/services/business-partners/tenancy/calculateTenancyTotalRentedArea'
import { calculateTenancyTotalRentedAreaPieces } from 'hooks/services/business-partners/tenancy/calculateTenancyTotalRentedAreaPieces'
import { calculateTenancyWault } from 'hooks/services/business-partners/tenancy/calculateTenancyWault'

const getCalculatedValues = ({
  contextRentalUnits,
  rentalUnits,
  kpiData,
  utilization: { vacantUtilizationKeys, selfUtilizationKeys },
}) => ({
  rentedArea: calculateTenancyTotalRentedArea(
    contextRentalUnits,
    rentalUnits,
    kpiData?.totalAreaSurface?.value,
  ),
  rentedAreaPieces: calculateTenancyTotalRentedAreaPieces(rentalUnits, kpiData?.totalNumberOfUnits),
  waultBreak: calculateTenancyWault(
    contextRentalUnits,
    rentalUnits,
    vacantUtilizationKeys,
    selfUtilizationKeys,
  ),
  annualizedRent: calculateTenancyTotalContractedRent(
    contextRentalUnits,
    rentalUnits,
    kpiData?.annualizedContractedRent?.number,
  ),
})

const getSegmentsTableData = ({ rentalUnits, utilization, propertyKpi }) => {
  const groupedRentalUnits = groupBy(
    rentalUnits,
    ({ segmentUsageTypeCode }) => segmentUsageTypeCode,
  )

  return Object.values(groupedRentalUnits).map((segmentedRentalUnits) => {
    const segmentUsageType = {
      name: segmentedRentalUnits[0].segmentUsageTypeName,
      code: segmentedRentalUnits[0].segmentUsageTypeCode,
      count: segmentedRentalUnits.length,
    }

    const segmentKpi = find(propertyKpi?.segmentUsageTypeKpis, {
      segmentUsageTypeCode: segmentUsageType.code,
    })
    return {
      rowType: TenancyTableRowType.segment,
      segmentUsageType,
      ...getCalculatedValues({
        contextRentalUnits: rentalUnits,
        rentalUnits: segmentedRentalUnits,
        kpiData: segmentKpi,
        utilization,
      }),
    }
  })
}

const getPropertyTableData = ({
  property,
  propertyDeals,
  dealsMap,
  rentalUnits,
  propertyKpi,
  contextRentalUnits,
  utilization,
}) => {
  if (isEmpty(rentalUnits) || isNil(property)) {
    return []
  }
  const deals = (propertyDeals?.[property.uuid] ?? []).map((dealUuid) => dealsMap[dealUuid])
  const subRows = getSegmentsTableData({
    rentalUnits,
    utilization,
    propertyKpi,
  })
  return [
    {
      rowType: TenancyTableRowType.property,
      property,
      deals,
      subRows,
      ...getCalculatedValues({
        contextRentalUnits,
        rentalUnits,
        kpiData: propertyKpi,
        utilization,
      }),
    },
  ]
}

const getDealTableData = ({
  deal,
  dealProperties,
  propertiesMap,
  rentalUnits,
  contextRentalUnits,
  propertyKpis,
  dealKpi,
  utilization,
}) => {
  const propertyIds = dealProperties[deal.dealUuid]
  const subRows = propertyIds.flatMap((propertyId) => {
    const property = propertiesMap[propertyId]
    const propertyKpi = find(propertyKpis, { propertyUuid: propertyId })
    const propertyRentalUnits = rentalUnits.filter(
      (rentalUnit) => rentalUnit.propertyUuid === propertyId,
    )
    return getPropertyTableData({
      property,
      propertyDeals: {},
      dealsMap: {},
      propertyKpi,
      rentalUnits: propertyRentalUnits,
      contextRentalUnits: contextRentalUnits,
      utilization,
    })
  })

  if (isEmpty(subRows)) {
    return []
  }
  return [
    {
      rowType: TenancyTableRowType.deal,
      deal,
      subRows,
      ...getCalculatedValues({
        rentalUnits: rentalUnits,
        contextRentalUnits: contextRentalUnits,
        kpiData: dealKpi,
        utilization,
      }),
    },
  ]
}

const getFinancingStatusData = ({
  financingStatusCode,
  properties,
  propertyDeals,
  dealsMap,
  rentalUnits,
  propertyKpis,
  contextRentalUnits,
  utilization,
}) => {
  const subRows = properties.flatMap((property) => {
    const propertyId = property.uuid
    const propertyKpi = find(propertyKpis, { propertyUuid: propertyId })
    const propertyRentalUnits = rentalUnits.filter(
      (rentalUnit) => rentalUnit.propertyUuid === propertyId,
    )
    return getPropertyTableData({
      property,
      propertyDeals,
      dealsMap,
      propertyKpi,
      rentalUnits: propertyRentalUnits,
      contextRentalUnits: contextRentalUnits,
      utilization,
    })
  })
  const calculatedValues = getCalculatedValues({ contextRentalUnits, rentalUnits, utilization })
  return {
    rowType: TenancyTableRowType.financingStatus,
    financingStatusCode,
    subRows: subRows,
    ...calculatedValues,
    rentedArea: {
      ...calculatedValues.rentedArea,
      share: undefined,
    },
    rentedAreaPieces: {
      ...calculatedValues.rentedAreaPieces,
      share: undefined,
    },
    annualizedRent: {
      ...calculatedValues.annualizedRent,
      share: undefined,
    },
  }
}

const useAsTenantTableData = ({
  tenancyCardData: {
    dealProperties,
    propertyDeals,
    rentalUnitsData,
    dataByFinancingStatus,
    utilization,
  } = {},
  groupedBy,
} = {}) => {
  const allRentalUnits = useMemo(
    () => rentalUnitsData?.rentalUnits ?? [],
    [rentalUnitsData?.rentalUnits],
  )

  const propertyKpis = useMemo(
    () => rentalUnitsData?.propertyKpis ?? [],
    [rentalUnitsData?.propertyKpis],
  )

  const dealKpis = useMemo(() => rentalUnitsData?.dealKpis ?? [], [rentalUnitsData?.dealKpis])

  const allDeals = useMemo(
    () => Object.values(dataByFinancingStatus ?? {}).flatMap(({ matchingDeals }) => matchingDeals),
    [dataByFinancingStatus],
  )

  const allProperties = useMemo(
    () =>
      Object.values(dataByFinancingStatus ?? {}).flatMap(
        ({ matchingProperties }) => matchingProperties,
      ),
    [dataByFinancingStatus],
  )

  const dealsMap = useMemo(
    () => Object.fromEntries(allDeals.map((deal) => [deal.dealUuid, deal])),
    [allDeals],
  )
  const propertiesMap = useMemo(
    () => Object.fromEntries(allProperties.map((property) => [property.uuid, property])),
    [allProperties],
  )
  const propertiesRentalUnits = useMemo(() => {
    const propertiesRentalUnitsMap = new Map()
    allRentalUnits.forEach((rentalUnit) => {
      const propertyRentalUnits = propertiesRentalUnitsMap.get(rentalUnit.propertyUuid) ?? new Set()
      propertyRentalUnits.add(rentalUnit)
      propertiesRentalUnitsMap.set(rentalUnit.propertyUuid, propertyRentalUnits)
    })
    return Object.fromEntries(
      Array.from(propertiesRentalUnitsMap.entries()).map(([propertyUuid, rentalUnitsSet]) => [
        propertyUuid,
        Array.from(rentalUnitsSet),
      ]),
    )
  }, [allRentalUnits])

  const tableData = useMemo(() => {
    if (groupedBy === TenancyCardTableColumnId.deal) {
      return allDeals.flatMap((deal) => {
        const propertyIds = dealProperties[deal.dealUuid]
        const dealRentalUnits = allRentalUnits.filter(({ propertyUuid }) =>
          propertyIds.includes(propertyUuid),
        )
        const dealKpi = find(dealKpis, { dealUuid: deal.dealUuid })
        return getDealTableData({
          deal,
          dealProperties,
          propertiesMap,
          propertyKpis,
          dealKpi,
          rentalUnits: dealRentalUnits,
          contextRentalUnits: dealRentalUnits,
          utilization,
        })
      })
    }
    if (groupedBy === TenancyCardTableColumnId.financingStatus) {
      const financingStatusGroups = groupBy(
        allProperties,
        (property) => property.financingStatusCode,
      )
      return Object.entries(financingStatusGroups).map(([financingStatusCode, properties]) => {
        const propertyIds = properties.map(({ uuid }) => uuid)
        const financingStatusRentalUnits = allRentalUnits.filter(({ propertyUuid }) =>
          propertyIds.includes(propertyUuid),
        )
        return getFinancingStatusData({
          financingStatusCode,
          properties,
          propertyDeals,
          dealsMap,
          rentalUnits: financingStatusRentalUnits,
          propertyKpis,
          contextRentalUnits: financingStatusRentalUnits,
          utilization,
        })
      })
    }
    return allProperties.flatMap((property) => {
      const rentalUnits = propertiesRentalUnits[property.uuid]
      const propertyKpi = find(propertyKpis, { propertyUuid: property.uuid })
      return getPropertyTableData({
        property,
        propertyDeals,
        dealsMap,
        propertyKpi,
        rentalUnits,
        contextRentalUnits: rentalUnits,
        utilization,
      })
    })
  }, [
    groupedBy,
    allProperties,
    allDeals,
    dealProperties,
    allRentalUnits,
    dealKpis,
    propertiesMap,
    propertyKpis,
    utilization,
    propertiesRentalUnits,
    propertyDeals,
    dealsMap,
  ])

  return useMemo(
    () => ({
      tableData,
    }),
    [tableData],
  )
}

export default useAsTenantTableData
