import { FlexBox } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionPaperVersion } from 'api/decision-paper/decisionPaperApi'
import DecisionPaperSubsectionAssessmentTile from 'components/domains/business-events-and-tasks/decision-paper/assessment/DecisionPaperSubsectionAssessmentTile'
import styles from 'components/domains/business-events-and-tasks/decision-paper/pdf-export/DecisionPaperSubsectionAssessmentPdfWrapper.module.css'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useDecisionPaperSubsectionAssessment from 'hooks/services/business-events-and-tasks/decision-papers/assessments/useDecisionPaperSubsectionAssessment'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const currentVersion = 'current'

const DecisionPaperSubsectionAssessmentPdfWrapper = ({ tileId, version, subsectionId }) => {
  const assessmentVersion =
    version === decisionPaperVersion.workingVersion ? currentVersion : version

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.subsections.assessment',
  })

  const { id: eventId } = useContext(BusinessEventsAndTasksContext).event

  const {
    isLoading,
    isError,
    data: assessmentData,
  } = useDecisionPaperSubsectionAssessment({
    eventId,
    version: assessmentVersion,
    subsectionId,
  })

  const renderContent = () => {
    const { text, lastUpdatedBy, lastUpdatedAt } = assessmentData
    if (isNil(text)) {
      return <></>
    }
    return (
      <FlexBox className={styles.assessmentWrapper}>
        <DecisionPaperSubsectionAssessmentTile
          tileId={tileId}
          subsectionId={subsectionId}
          assessmentText={text}
          lastUpdatedBy={lastUpdatedBy}
          lastUpdatedAt={lastUpdatedAt}
          isPdfView={true}
          isEditEnabled={false}
          currentVersion={version}
        />
      </FlexBox>
    )
  }

  return (
    <LoadingStateWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderContent}
      errorDescription={t('loading.error.description')}
      errorTitle={t('loading.error.title')}
    />
  )
}

DecisionPaperSubsectionAssessmentPdfWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  subsectionId: PropTypes.string.isRequired,
}

export default DecisionPaperSubsectionAssessmentPdfWrapper
