import { MultiComboBox, MultiComboBoxItem } from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { forwardRef, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getObjectStatusForEventStatus,
  eventStatus as possibleEventStatus,
} from 'api/events/status'

const mappedEventStatus = [
  possibleEventStatus.drafted,
  possibleEventStatus.ongoing,
  possibleEventStatus.completed,
  possibleEventStatus.aborted,
]

const EventStatusComboBox = forwardRef(({ selected, onSelectionChange, onKeyDown, id }, _ref) => {
  const { t } = useTranslation()
  const mappedEventStatusOptions = useMemo(
    () =>
      mappedEventStatus.map((eventStatus, index) => (
        <MultiComboBoxItem
          key={`event-status-${eventStatus}-${index}`}
          data-key={eventStatus}
          text={t(getObjectStatusForEventStatus(eventStatus).translationKey)}
          selected={selected.includes(eventStatus)}
        />
      )),
    [selected, t],
  )
  const onStatusSelectionChange = useCallback(
    (evt) => {
      const {
        detail: { items: selectedStatusOptions },
      } = evt
      const keys = selectedStatusOptions.map((type) => type.dataset.key)
      onSelectionChange(keys)
    },
    [onSelectionChange],
  )

  return (
    <MultiComboBox
      id={id}
      onSelectionChange={onStatusSelectionChange}
      onKeyDown={onKeyDown}
      ref={_ref}
    >
      {mappedEventStatusOptions}
    </MultiComboBox>
  )
})

EventStatusComboBox.displayName = 'EventStatusComboBox'

EventStatusComboBox.propTypes = {
  selected: PropType.arrayOf(
    PropType.oneOf([
      possibleEventStatus.drafted,
      possibleEventStatus.ongoing,
      possibleEventStatus.completed,
      possibleEventStatus.aborted,
    ]),
  ).isRequired,
  onSelectionChange: PropType.func.isRequired,
  onKeyDown: PropType.func,
  id: PropType.string.isRequired,
}

export default EventStatusComboBox
