import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useEventsConfig = ({ options = {} } = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/events/configurations`,
      keys: ['events-configurations'],
      options,
      translated: true,
    }),
  )

export default useEventsConfig
