import { useTranslation } from 'react-i18next'

/**
 * @enum
 */
export const temporalUnitEnum = {
  DAYS: 'DAYS',
  WEEKS: 'WEEKS',
  MONTHS: 'MONTHS',
}

const useTemporalUnitTranslation = () => {
  const { t } = useTranslation('translation')
  const getTemporalUnitTranslation = (temporalUnit) =>
    ({
      DAYS: t('components.deals.covenants.monitoring-card.temporal-unit.days'),
      WEEKS: t('components.deals.covenants.monitoring-card.temporal-unit.weeks'),
      MONTHS: t('components.deals.covenants.monitoring-card.temporal-unit.months'),
    }[temporalUnit] ?? '')

  return { getTemporalUnitTranslation }
}

export default useTemporalUnitTranslation
