import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useDeletePropertyExternalId = ({ onSuccess, onError }) => {
  const { delete: deleteExternalId } = useAccessTokenRequest()
  return useMutation(
    async ({ propertyUuid, externalIdToDelete }) => {
      await deleteExternalId({
        path: `/properties/${propertyUuid}/external-ids/${externalIdToDelete.id}/${externalIdToDelete.extCode}`,
      })
    },
    { onSuccess, onError },
  )
}
