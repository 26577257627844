import { FlexBox, FlexBoxDirection, MessageStrip } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { MapContainer } from 'react-leaflet'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-area/PortfolioFinancedAssetsAreaMap.module.css'
import PortfolioFinancedAssetsAreaMarker from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-area/PortfolioFinancedAssetsAreaMarker'
import leafletStyles from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-area/shared/ui/Leaflet.module.css'
import LeafletTileLayerWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-area/shared/ui/LeafletTileLayerWrapper'
import {
  getBoundingBoxCenter,
  getBounds,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-area/shared/ui/MapPolygonUtils'

const DEFAULT_INITIAL_ZOOM_LEVEL = 15
const DEFAULT_MAP_PADDING = 0.06

const PortfolioFinancedAssetsAreaMap = ({
  markers,
  disabled,
  missingLocations,
  totalAmountOfProperties,
  marketAreaData,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.portfolio-financed-assets-area',
  })

  const marketPolygon =
    totalAmountOfProperties === 1 ? marketAreaData?.area?.polygonCoordinates : []
  const marketPolygonCoordinates = marketPolygon?.[0]?.[0]
    ? marketPolygon[0][0].map(({ lat, lng }) => [lat, lng])
    : []

  const markerCoordinates = markers.map(({ location }) => [location.lat, location.lng])
  const coordinatesArray = markerCoordinates.concat(marketPolygonCoordinates)
  const bounds = getBounds(coordinatesArray, false, DEFAULT_MAP_PADDING)
  const center = getBoundingBoxCenter(bounds)

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      {missingLocations > 0 && (
        <MessageStrip design="Negative" hideCloseButton className={styles.messageStrip}>
          {t('locations.warning', {
            count: missingLocations,
            total: totalAmountOfProperties,
          })}
        </MessageStrip>
      )}
      <div className={styles.mapWrapper}>
        <MapContainer
          className={leafletStyles.leafletContainer}
          center={center}
          zoom={DEFAULT_INITIAL_ZOOM_LEVEL}
          scrollWheelZoom={true}
        >
          <LeafletTileLayerWrapper
            markers={markers}
            center={center}
            bounds={bounds}
            polygon={marketPolygon}
            MarkerPopup={PortfolioFinancedAssetsAreaMarker}
            disabled={disabled}
          />
        </MapContainer>
      </div>
    </FlexBox>
  )
}

PortfolioFinancedAssetsAreaMap.propTypes = {
  markers: PropTypes.array,
  disabled: PropTypes.bool.isRequired,
  missingLocations: PropTypes.number.isRequired,
  totalAmountOfProperties: PropTypes.number.isRequired,
  marketAreaData: PropTypes.shape({
    area: PropTypes.shape({ polygonCoordinates: PropTypes.array }),
  }),
}

export default PortfolioFinancedAssetsAreaMap
