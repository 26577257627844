import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js'
import {
  Button,
  ButtonDesign,
  Dialog,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  WizardStep,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import DealCreationCancelMessageBox from 'components/domains/deals/creation-dialog/DealCreationCancelMessageBox'
import 'components/domains/deals/creation-dialog/DealCreationDialog.css'
import styles from 'components/domains/deals/creation-dialog/DealCreationDialog.module.css'
import StyledWizard from 'components/domains/deals/creation-dialog/StyledWizard'
import DealCreationCounterpartyStep from 'components/domains/deals/creation-dialog/counterparty-step/DealCreationCounterpartyStep'
import { useCounterpartyDefaults } from 'components/domains/deals/creation-dialog/counterparty-step/useCounterpartyDefaults'
import DealCreationGeneralInfoStep from 'components/domains/deals/creation-dialog/general-information-step/DealCreationGeneralInfoStep'
import { useGeneralInformationDefaults } from 'components/domains/deals/creation-dialog/general-information-step/useGeneralInformationDefaults'
import DealCreationPropertyStep from 'components/domains/deals/creation-dialog/property-step/DealCreationPropertyStep'
import { usePropertyDefaults } from 'components/domains/deals/creation-dialog/property-step/usePropertyDefaults'
import DealCreationReviewStep from 'components/domains/deals/creation-dialog/review-step/DealCreationReviewStep'
import { useDealCreationDependencies } from 'components/domains/deals/creation-dialog/useDealCreationDependencies'
import useShowDealErrorCodeMessageBox from 'components/domains/deals/creation-dialog/useShowDealErrorCodeMessageBox'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useCreateDeal } from 'hooks/services/deals/useCreateDeal'
import { useProperties } from 'hooks/services/properties/useProperties'

export const Step = {
  GENERAL_INFORMATION: 1,
  COUNTERPARTY_AND_FINANCING: 2,
  PROPERTY: 3,
  REVIEW: 4,
}

const ErrorMessage = {
  MISSING_PERMISSION: 'DL_MISSING_PERMISSIONS_FOR_LINKING_ACCOUNT_MANAGER',
}

const DealCreationDialog = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals' })
  const {
    reset: resetState,
    formState: { isValid },
    trigger,
    handleSubmit,
  } = useFormContext()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const setGeneralInformationDefaults = useGeneralInformationDefaults()
  const setCounterpartyDefaults = useCounterpartyDefaults()
  const setPropertyDefaults = usePropertyDefaults()
  const { isLoading, isError } = useDealCreationDependencies(isOpen)
  const [isCancelMessageBoxOpen, setIsCancelMessageBoxOpen] = useState(false)
  const [currentStep, setCurrentStep] = useState(Step.GENERAL_INFORMATION)
  const [appliedDefaults, setAppliedDefaults] = useState([])
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
  const [isCreationError, setIsCreationError] = useState(false)
  const [isDealCreationPending, setIsDealCreationPending] = useState(false)
  const [addressFilter, setAddressFilter] = useState({})
  const [isCreatingProperty, setIsCreatingProperty] = useState(true)

  const isPropertiesRequestEnabled =
    !!addressFilter?.country && !!addressFilter?.zipCode && !!addressFilter?.city
  const { data: { properties = [] } = {}, isFetching: isPropertyCheckLoading } = useProperties({
    filter: addressFilter,
    options: { enabled: isPropertiesRequestEnabled },
  })
  const showErrorMessageBox = useShowErrorMessageBox()
  const showDealCreationWarnings = useShowDealErrorCodeMessageBox()

  const isReady = !isLoading && !isError
  useEffect(() => {
    if (!isOpen && appliedDefaults.length > 0) {
      resetState()
      setAppliedDefaults([])
    }
  }, [isOpen, appliedDefaults, resetState])
  const applyDefault = useCallback(
    (step, handler) => {
      if (isOpen && isReady && currentStep === step && !appliedDefaults.includes(step)) {
        setAppliedDefaults((prev) => [...prev, step])
        handler()
      }
    },
    [isOpen, isReady, currentStep, appliedDefaults, setAppliedDefaults],
  )
  useEffect(() => {
    applyDefault(Step.GENERAL_INFORMATION, () => setGeneralInformationDefaults())
    applyDefault(Step.COUNTERPARTY_AND_FINANCING, () => setCounterpartyDefaults())
    applyDefault(Step.PROPERTY, () => setPropertyDefaults())
  }, [
    currentStep,
    setGeneralInformationDefaults,
    setCounterpartyDefaults,
    setPropertyDefaults,
    applyDefault,
    trigger,
  ])

  const setCurrentStepAndResetValidation = useCallback(
    (arg) => {
      resetState(undefined, { keepValues: true })
      setCurrentStep(arg)
    },
    [resetState, setCurrentStep],
  )

  const onDialogClose = () => {
    setIsOpen(false)
    setCurrentStepAndResetValidation(Step.GENERAL_INFORMATION)
    resetState()
    setAddressFilter({})
  }

  const handleCancelConfirmation = useCallback(onDialogClose, [onDialogClose])

  const handleCancel = useCallback(() => {
    if (isLoading || isError) {
      handleCancelConfirmation()
    } else {
      setIsCancelMessageBoxOpen(true)
    }
  }, [isLoading, isError, handleCancelConfirmation, setIsCancelMessageBoxOpen])

  const onBeforeClose = useCallback(
    (event) => {
      if (event?.detail?.escPressed) {
        event.preventDefault()
        handleCancel()
      }
    },
    [handleCancel],
  )

  const isStepActive = (value) => value === currentStep
  const isStepDisabled = (step) => step > currentStep + Number(isValid)
  const stepClassName = (step) => (isStepActive(step) ? '' : styles.hiddenStep)

  const onStepChange = (event) => {
    const pressedStep = event.detail.step.attributes?.['data-step']?.value
    if (pressedStep && (isValid || pressedStep < currentStep)) {
      setCurrentStepAndResetValidation(
        parseInt(event.detail.step.attributes['data-step'].value, 10),
      )
    }
  }

  const increaseStep = () => {
    setCurrentStepAndResetValidation(currentStep + 1)
  }

  const onDealCreated = ({ warnings, deal_id } = {}) => {
    setIsConfirmationDialogOpen(false)
    setIsDealCreationPending(false)
    queryClient.invalidateQueries(['deals'])
    onDialogClose()
    navigate(deal_id)
    if (warnings?.length) {
      showDealCreationWarnings({ warnings })
    }
  }

  const onDealCreatedError = async (_error) => {
    setIsCreationError(true)
    const { errors: [error] = [] } = (await _error.response.json()) ?? {}
    const message =
      error.code === ErrorMessage.MISSING_PERMISSION
        ? t('dialog.message.error-missing-permissions')
        : t('dialog.message.error')
    showErrorMessageBox({
      id: 'deal-creation-error',
      message,
      error,
      onClose: () => {
        setIsCreationError(false)
        setIsDealCreationPending(false)
        onDialogClose()
      },
    })
  }

  const createDeal = useCreateDeal({
    onSuccess: onDealCreated,
    onError: onDealCreatedError,
  })

  const submitCreateDeal = () => {
    setIsDealCreationPending(true)
    handleSubmit((deal) => createDeal.mutate(deal))()
  }

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false)
  }

  const openConfirmationDialog = (event) => {
    event.preventDefault()
    if (currentStep === Step.REVIEW) {
      setIsConfirmationDialogOpen(true)
    }
  }

  const updateAddressFilter = useCallback(
    (addressFilterObject) => {
      setAddressFilter({
        ...addressFilterObject,
      })
    },
    [setAddressFilter],
  )

  return (
    <>
      {createPortal(
        <Dialog
          id="deal-creation-dialog"
          onBeforeClose={onBeforeClose}
          open={isOpen}
          draggable
          resizable
          style={{ height: '600px', width: '680px' }}
          headerText={t('create.title')}
          footer={
            <FlexBox
              className={styles.dialogFooter}
              alignItems={FlexBoxAlignItems.Center}
              justifyContent={FlexBoxJustifyContent.End}
            >
              {currentStep > Step.GENERAL_INFORMATION && (
                <Button
                  id="previous-button"
                  design={ButtonDesign.Transparent}
                  onClick={() => setCurrentStepAndResetValidation(currentStep - 1)}
                >
                  {t('dialog.button.previous')}
                </Button>
              )}
              {(currentStep === Step.GENERAL_INFORMATION ||
                currentStep === Step.COUNTERPARTY_AND_FINANCING) && (
                <Button
                  id="next-button"
                  design={ButtonDesign.Emphasized}
                  onClick={increaseStep}
                  disabled={!isValid || isLoading || isError}
                >
                  {t('dialog.button.next')}
                </Button>
              )}
              {currentStep === Step.PROPERTY && (
                <Button
                  id="review-button"
                  design={ButtonDesign.Emphasized}
                  onClick={increaseStep}
                  disabled={
                    !isValid ||
                    isLoading ||
                    isError ||
                    (isPropertyCheckLoading && isCreatingProperty)
                  }
                >
                  {t('review')}
                </Button>
              )}
              {currentStep === Step.REVIEW && (
                <Button
                  id="create-button"
                  design={ButtonDesign.Emphasized}
                  disabled={!isValid || isLoading || isError || isDealCreationPending}
                  submits
                  onClick={openConfirmationDialog}
                >
                  {t('create')}
                </Button>
              )}
              <Button id="cancel-button" design={ButtonDesign.Transparent} onClick={handleCancel}>
                {t('dialog.button.cancel')}
              </Button>
              <DealCreationCancelMessageBox
                isOpen={isCancelMessageBoxOpen}
                setIsOpen={setIsCancelMessageBoxOpen}
                handleConfirmation={handleCancelConfirmation}
              />
            </FlexBox>
          }
        >
          <LoadingStateWrapper
            isLoading={isLoading || isDealCreationPending}
            isError={isError}
            renderContent={() =>
              isCreationError ? (
                <></>
              ) : (
                <form onSubmit={openConfirmationDialog}>
                  <StyledWizard onStepChange={onStepChange}>
                    <WizardStep
                      titleText={t('general-information')}
                      data-step={Step.GENERAL_INFORMATION}
                      selected={isStepActive(Step.GENERAL_INFORMATION)}
                      className={stepClassName(Step.GENERAL_INFORMATION)}
                    >
                      <DealCreationGeneralInfoStep wizardStep={Step.GENERAL_INFORMATION} />
                    </WizardStep>
                    <WizardStep
                      titleText={t('counterparty-and-financing')}
                      data-step={Step.COUNTERPARTY_AND_FINANCING}
                      selected={isStepActive(Step.COUNTERPARTY_AND_FINANCING)}
                      disabled={isStepDisabled(Step.COUNTERPARTY_AND_FINANCING)}
                      className={stepClassName(Step.COUNTERPARTY_AND_FINANCING)}
                    >
                      <DealCreationCounterpartyStep
                        enabled={currentStep >= Step.COUNTERPARTY_AND_FINANCING}
                        wizardStep={Step.COUNTERPARTY_AND_FINANCING}
                      />
                    </WizardStep>
                    <WizardStep
                      titleText={t('dialog.property')}
                      data-step={Step.PROPERTY}
                      selected={isStepActive(Step.PROPERTY)}
                      disabled={isStepDisabled(Step.PROPERTY)}
                      className={stepClassName(Step.PROPERTY)}
                    >
                      <DealCreationPropertyStep
                        enabled={currentStep >= Step.PROPERTY}
                        wizardStep={Step.PROPERTY}
                        addressFilter={addressFilter}
                        isCreatingProperty={isCreatingProperty}
                        propertiesWithSameAddressFilter={properties}
                        onUpdateAddressFilter={updateAddressFilter}
                        setIsCreatingProperty={setIsCreatingProperty}
                      />
                    </WizardStep>
                    <WizardStep
                      titleText={t('review')}
                      data-step={Step.REVIEW}
                      selected={isStepActive(Step.REVIEW)}
                      disabled={isStepDisabled(Step.REVIEW)}
                      className={stepClassName(Step.REVIEW)}
                      icon="list"
                    >
                      <DealCreationReviewStep setCurrentStep={setCurrentStep} />
                    </WizardStep>
                  </StyledWizard>
                </form>
              )
            }
          />
        </Dialog>,
        document.body,
      )}
      <ConfirmationDialog
        id="deal-creation-confirmation-dialog"
        handleCancel={closeConfirmationDialog}
        isOpen={isConfirmationDialogOpen}
        handleConfirmation={submitCreateDeal}
        confirmationMessage={t('confirm-message')}
        confirmationTitle={t('confirm')}
        confirmButtonText={t('confirm')}
      />
    </>
  )
}

DealCreationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default DealCreationDialog
