import { ValueState } from '@fioneer/ui5-webcomponents-react'

const determineValueStateBasedOnStatus = (status) => {
  switch (status) {
    case 'ACTIVE':
      return ValueState.Success
    case 'PROVISIONAL':
      return ValueState.Warning
    case 'INACTIVE':
      return ValueState.None
    case 'ARCHIVED':
      return ValueState.Error
    default:
      return ValueState.Information
  }
}

export default determineValueStateBasedOnStatus
