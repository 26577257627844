import { TextAlign, VerticalAlign } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CustomerOptionsTableCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/CustomerOptionsTableCell'

const useCustomerOptionsTableColumnDefinitions = ({ isEditMode }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.deals.customerOptions-table',
  })

  return useMemo(
    () =>
      [
        {
          Header: t('label.clusterOptionAttribute'),
          accessor: 'clusterOptionAttribute',
          minWidth: 450,
        },
        {
          Header: t('label.value'),
          accessor: 'value',
          hAlign: TextAlign.Right,
          width: 400,
        },
      ].map((element) => ({
        disableDragAndDrop: true,
        disableResizing: false,
        Cell: (cellProps) =>
          CustomerOptionsTableCell({
            props: cellProps,
            isEditMode,
          }),
        vAlign: VerticalAlign.Middle,
        ...element,
      })),
    [isEditMode, t],
  )
}

export default useCustomerOptionsTableColumnDefinitions
