import camelize from 'camelize'
import { useRequest } from 'hooks/services/baseService'

const useBusinessPartnerRoles = () => {
  const response = useRequest({
    path: `/businesspartners/value-helps/roles`,
    translated: true,
  })
  return { ...response, data: camelize(response.data) }
}

export default useBusinessPartnerRoles
