import { z } from 'zod'
import PropertiesService from 'hooks/services/properties/common.schema'

export const GetMultiPropertyRentRollKPIsRequestBody = z.object({
  property_uuids: z.array(z.string().uuid()),
  key_dates: z.array(z.string()).nullish(),
  currency: z.string().optional(),
  area_unit_of_measurement: z.string().optional(),
  with_tenant_granularity: z.boolean().optional(),
  with_city_granularity: z.boolean().optional(),
  with_property_type_granularity: z.boolean().optional(),
  with_country_granularity: z.boolean().optional(),
  with_tenant_industry_granularity: z.boolean().optional(),
  with_segment_usage_type_granularity: z.boolean().optional(),
  with_tegova_rating_granularity: z.boolean().optional(),
})

/**
 * @param {z.infer<typeof GetMultiPropertyRentRollKPIsRequestBody>} requestOptions
 */
export const MultiPropertyRentRollKPIsDTO = (requestOptions, withAllKeyDates) => {
  const zObjWithoutMax = z.array(
    z.object({
      key_date: z.string(),
      kpis: PropertiesService.RentRollKPIsDTO(requestOptions),
    }),
  )
  if (withAllKeyDates) {
    return z.object({
      property_uuids: z.array(z.string().uuid()),
      key_date_to_multi_property_rent_roll_kpis: zObjWithoutMax,
    })
  } else {
    return z.object({
      property_uuids: z.array(z.string().uuid()),
      key_date_to_multi_property_rent_roll_kpis: zObjWithoutMax.max(1),
    })
  }
}
