export const ARREARS_IN_APPROVAL_ARREARS_HEADQUARTER_CURRENCY = 'EUR'

export const ARREARS_IN_APPROVAL_ENTITY_TYPES = {
  DEAL: 'DEAL',
  TRANCHE: 'TRANCHE',
  DRAWING: 'DRAWING',
  ARREAR: 'ARREAR',
  APPROVALITEM: 'APPROVAL_ITEM',
  NON_LOAN: 'NON_LOAN',
  BCA: 'BCA',
}

export const ARREARS_IN_APPROVAL_ARREAR_APPROVAL_STATUS = {
  APPROVED: 'APPROVED',
  NOT_APPROVED: 'NOT_APPROVED',
  PARTIALLY_APPROVED: 'PARTIALLY_APPROVED',
  PARTIALLY_IN_APPROVAL: 'PARTIALLY_IN_APPROVAL',
  IN_APPROVAL: 'IN_APPROVAL',
}

export const ARREARS_IN_APPROVAL_APPROVAL_EVENT_STATUS = {
  COMPLETED: 'COMPLETED',
}

export const ARREARS_IN_APPROVAL_EXTENDING_STATES = {
  INITIAL: 'INITIAL',
  CONVERTING: 'CONVERTING',
  CONVERTED: 'CONVERTED',
  SETTING: 'SETTING',
  SET: 'SET',
  ERROR: 'ERROR',
}

export const OVERPAYMENT = 'OVERPAYMENT'
