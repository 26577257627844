import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Icon,
  IconDesign,
  ObjectStatus,
  Popover,
  PopoverPlacementType,
  Text,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/change-overview/adjustments-history-card/AdjustmentStatusWithWarnings.module.css'
import { useAdjustmentStatusTranslation } from 'components/domains/deals/change-overview/adjustments-history-card/useAdjustmentStatusTranslation'
import useDealErrorCodeTranslation from 'components/domains/deals/creation-dialog/useDealErrorCodeTranslation'

/**
 * @enum
 */
const statusToBadgeColor = {
  DELETED: ValueState.Error,
  PUBLISHED: ValueState.Success,
}

const AdjustmentStatusWithWarnings = ({ status, warnings }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.change-overview.adjustment-history',
  })
  const { getTranslationForAdjustmentStatus } = useAdjustmentStatusTranslation()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const id = crypto.randomUUID()
  const getWarningTextByCode = useDealErrorCodeTranslation()
  return (
    <div className={styles.container}>
      {!!status && (
        <ObjectStatus
          inverted
          className={styles.adjustmentStatus}
          state={statusToBadgeColor[status]}
        >
          {getTranslationForAdjustmentStatus(status)}
        </ObjectStatus>
      )}
      {!!warnings.length && (
        <Button
          id={id}
          design={ButtonDesign.Transparent}
          icon="alert"
          onClick={() => setIsPopoverOpen(true)}
          className={styles.popoverButton}
        />
      )}
      {createPortal(
        <Popover
          id="warning-popover"
          opener={id}
          placementType={PopoverPlacementType.Top}
          open={isPopoverOpen}
          onAfterClose={() => setIsPopoverOpen(false)}
          className={styles.popover}
          header={
            <FlexBox
              justifyContent={FlexBoxJustifyContent.Start}
              alignItems={FlexBoxAlignItems.Center}
              className={styles.header}
            >
              <Icon design={IconDesign.Critical} name="alert" />
              <Title level={TitleLevel.H5} className={styles.title}>
                {t('publish-warning')}
              </Title>
            </FlexBox>
          }
        >
          {warnings.map(({ dealErrorCode, dealErrorSubCode }, index) => (
            <React.Fragment key={index}>
              {!!index && <div className={styles.rowSeparator} />}
              <Text className={styles.warningText}>
                {getWarningTextByCode(dealErrorCode, dealErrorSubCode)}
              </Text>
            </React.Fragment>
          ))}
        </Popover>,
        document.body,
      )}
    </div>
  )
}

AdjustmentStatusWithWarnings.propTypes = {
  status: PropTypes.oneOf(['DELETED', 'PUBLISHED']),
  warnings: PropTypes.arrayOf(PropTypes.shape({ dealErrorCode: PropTypes.string })),
}

export default AdjustmentStatusWithWarnings
