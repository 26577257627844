import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Menu,
  MenuItem,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
  PopoverPlacementType,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { requirementStatusTypes } from 'api/conditions/conditions'
import {
  neededOperationsForConditionDelete,
  neededOperationsForConditionEdit,
} from 'api/conditions/conditionsAllowedOperations'
import { hasUserRequiredOperations } from 'api/helper'
import ConditionIndividualChangelogDialog from 'components/domains/conditions/dialogs/changelog/ConditionIndividualChangelogDialog'
import RequirementRemindersDialog from 'components/domains/conditions/dialogs/requirements/reminders/RequirementRemindersDialog'
import styles from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableActionsCell.module.css'
import {
  mapChangedRequirementsToBECall,
  mapRequirementsToBackendCall,
} from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableActionsCellHelper'
import { ENTITIES } from 'components/domains/deals/change-overview/constants'
import { CancelPopoverContent } from 'components/ui/button/CancelButtonWithPopover'
import LoadingButton from 'components/ui/button/LoadingButton'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useRequirementsCellAddMode from 'hooks/services/conditions/edit-mode/useRequirementsCellAddMode'
import useRequirementsCellEditMode from 'hooks/services/conditions/edit-mode/useRequirementsCellEditMode'
import useCreateRequirement from 'hooks/services/conditions/requirements/useCreateRequirement'
import useDeleteRequirement from 'hooks/services/conditions/requirements/useDeleteRequirement'
import useEditRequirement from 'hooks/services/conditions/requirements/useEditRequirement'
import useRequirementsTableDocumentTypeErrors from 'hooks/services/conditions/tables/useRequirementsTableDocumentTypeErrors'
import {
  highlightRequirementRow,
  startRequirementEditMode,
  stopRequirementAddMode,
  stopRequirementEditMode,
} from 'redux/slices/conditions/requirementsTableSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const requirementCancelId = 'requirement-cancel-button'

const RequirementsTableActionsCell = ({
  row: {
    original: { id: requirementId, ...requirement },
  },
}) => {
  const {
    allowedOperations,
    entityRef: { entityId, entityType },
  } = useContext(ConditionsContext)

  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.columns.action-cell',
  })
  const queryClient = useQueryClient()
  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()
  const showPopover = Modals.useShowPopover()
  const dispatch = useDispatch()
  const store = useStore()
  const saveCancelPopoverRef = useRef()
  const { mutate: editRequirement, isLoading: isEditLoading } = useEditRequirement()
  const { mutate: deleteRequirement, isLoading: isDeleteLoading } = useDeleteRequirement()
  const { mutate: createRequirement } = useCreateRequirement()
  const { parse, localePattern } = useShortDateFormatter()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isChangelogDialogOpen, setIsChangelogDialogOpen] = useState(false)
  const [isReminderDialogOpen, setIsOpenReminderDialog] = useState(false)

  const onActionsButtonClick = useCallback(() => {
    setIsMenuOpen(true)
  }, [])

  const closeActionsButtonMenu = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  useRequirementsTableDocumentTypeErrors({
    references: requirement.condition.references,
    entityType,
    entityId,
    currentDocumentType: requirement.info.documentType,
    requirementId,
  })

  const { isRequirementAddMode, isAddModeForCurrentRow } = useRequirementsCellAddMode({
    requirementId,
  })
  const { isEditModeForAnyRow, isEditModeForCurrentRow } = useRequirementsCellEditMode({
    requirementId,
  })

  const hasEditRowChanges = useSelector(
    (state) => !isEmpty(state.conditions.requirementsTable.editedRow.changedFields),
  )

  const hasEditRowErrors = useSelector(
    (state) => !isEmpty(state.conditions.requirementsTable.editedRow.errorFields),
  )

  const currentValues = useSelector(
    (state) => state.conditions.requirementsTable.editedRow.currentValues,
  )

  const isSaveButtonDisabled = useMemo(() => {
    const isSaveDisabled = !hasEditRowChanges || hasEditRowErrors
    if (!isAddModeForCurrentRow) {
      return isSaveDisabled
    }
    const { condition, name, documentType } = currentValues
    return isSaveDisabled || !condition.id || !name || !documentType
  }, [hasEditRowChanges, hasEditRowErrors, currentValues, isAddModeForCurrentRow])

  const onCallSuccess = useCallback(
    (toastMessage) => {
      queryClient.invalidateQueries(['conditions', 'external', entityType, entityId])
      showToast({ children: toastMessage })
    },
    [queryClient, entityType, entityId, showToast],
  )

  const handleAddRequirementSuccess = useCallback(
    (data) => {
      onCallSuccess(t('create.success'))
      dispatch(highlightRequirementRow(data.id))
      dispatch(stopRequirementAddMode())
    },
    [dispatch, onCallSuccess, t],
  )

  const handleAddRequirementError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      titleText: t('create.error.title'),
      children: t('create.error.description'),
      actions: [MessageBoxActions.Close],
    })
  }, [showMessageBox, t])

  const callAddRequirement = useCallback(() => {
    const { condition } = currentValues
    createRequirement(
      {
        conditionId: condition.id,
        requirementData: mapRequirementsToBackendCall({
          currentValues,
          parseDate: (date) => parse(date, localePattern),
        }),
      },
      {
        onSuccess: handleAddRequirementSuccess,
        onError: handleAddRequirementError,
      },
    )
  }, [
    currentValues,
    createRequirement,
    handleAddRequirementSuccess,
    handleAddRequirementError,
    parse,
    localePattern,
  ])

  const onEditSuccess = useCallback(() => {
    dispatch(stopRequirementEditMode())
    queryClient.invalidateQueries(['conditions', 'external'])
    showToast({ children: t('edit.success') })
  }, [dispatch, queryClient, showToast, t])

  const onEditError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('edit.error'),
    })
  }, [showMessageBox, t])

  const callEditRequirement = useCallback(() => {
    const currentRequirementValues =
      store.getState().conditions.requirementsTable.editedRow.currentValues
    const changedRequirementFields =
      store.getState().conditions.requirementsTable.editedRow.changedFields

    const mappedRequirementForMutationCall = mapChangedRequirementsToBECall({
      currentValues: currentRequirementValues,
      changedFields: changedRequirementFields,
      parseDate: (date) => parse(date, localePattern),
    })

    editRequirement(
      {
        conditionId: requirement.condition.id,
        requirementId,
        requirement: mappedRequirementForMutationCall,
      },
      { onSuccess: onEditSuccess, onError: onEditError },
    )
  }, [
    editRequirement,
    localePattern,
    onEditError,
    onEditSuccess,
    parse,
    requirement.condition.id,
    requirementId,
    store,
  ])

  const saveRequirement = useCallback(() => {
    if (isAddModeForCurrentRow) {
      callAddRequirement()
      return
    }
    callEditRequirement()
  }, [isAddModeForCurrentRow, callAddRequirement, callEditRequirement])

  const onEditSaveStatusChanged = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Confirm,
      titleText: t('edit.confirmation-status-change.title'),
      children: t('edit.confirmation-status-change.text'),
      actions: [
        <Button key="button-edit" design={ButtonDesign.Emphasized} onClick={callEditRequirement}>
          {tNoPrefix('buttons.save')}
        </Button>,
        <Button
          key="button-cancel"
          data-action={MessageBoxActions.Cancel}
          design={ButtonDesign.Transparent}
        >
          {tNoPrefix('buttons.cancel')}
        </Button>,
      ],
    })
  }, [showMessageBox, t, tNoPrefix, callEditRequirement])

  const onEditButtonClicked = useCallback(() => {
    dispatch(startRequirementEditMode({ requirementId, requirement }))
  }, [dispatch, requirementId, requirement])

  const onSaveButtonClicked = useCallback(() => {
    const editedRow = store.getState().conditions.requirementsTable.editedRow

    const isStatusChangedToDoneType =
      !!editedRow.changedFields.status &&
      editedRow.currentValues.status.type === requirementStatusTypes.done

    isStatusChangedToDoneType ? onEditSaveStatusChanged() : saveRequirement()
  }, [store, onEditSaveStatusChanged, saveRequirement])

  const stopAddMode = useCallback(() => {
    dispatch(stopRequirementAddMode())
  }, [dispatch])

  const dispatchStopRequirementEditMode = useCallback(() => {
    dispatch(stopRequirementEditMode(requirementId))
  }, [dispatch, requirementId])

  const cancelEdit = useCallback(() => {
    if (isAddModeForCurrentRow) {
      stopAddMode()
      return
    }
    dispatchStopRequirementEditMode()
  }, [isAddModeForCurrentRow, stopAddMode, dispatchStopRequirementEditMode])

  const onCancelConfirmed = useCallback(() => {
    saveCancelPopoverRef.current.close()
    cancelEdit()
  }, [cancelEdit])

  const onSaveCancelButtonClicked = useCallback(() => {
    if (hasEditRowChanges) {
      saveCancelPopoverRef.current = showPopover({
        opener: requirementCancelId,
        placementType: PopoverPlacementType.Top,
        children: <CancelPopoverContent onCancelClicked={onCancelConfirmed} />,
      })
      return
    }
    cancelEdit()
  }, [hasEditRowChanges, cancelEdit, showPopover, onCancelConfirmed])

  const onDeleteSuccess = useCallback(() => onCallSuccess(t('delete.success')), [onCallSuccess, t])

  const onDeleteError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('delete.error'),
    })
  }, [showMessageBox, t])

  const onDeleteConfirmed = useCallback(() => {
    deleteRequirement(
      { conditionId: requirement.condition.id, requirementId },
      { onSuccess: onDeleteSuccess, onError: onDeleteError },
    )
  }, [deleteRequirement, requirement.condition.id, requirementId, onDeleteSuccess, onDeleteError])

  const onDeleteButtonClicked = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Warning,
      titleText: t('delete.confirmation.title'),
      children: t('delete.confirmation.text'),
      actions: [
        <Button key="button-delete" design={ButtonDesign.Emphasized} onClick={onDeleteConfirmed}>
          {tNoPrefix('buttons.delete')}
        </Button>,
        <Button
          key="button-cancel"
          data-action={MessageBoxActions.Cancel}
          design={ButtonDesign.Transparent}
        >
          {tNoPrefix('buttons.cancel')}
        </Button>,
      ],
    })
  }, [showMessageBox, t, tNoPrefix, onDeleteConfirmed])

  const hasUserDeletePermission = useMemo(
    () => hasUserRequiredOperations(neededOperationsForConditionDelete, allowedOperations),
    [allowedOperations],
  )
  const hasUserEditPermission = useMemo(
    () => hasUserRequiredOperations(neededOperationsForConditionEdit, allowedOperations),
    [allowedOperations],
  )
  const isDoneStatus = useMemo(
    () => requirement.status.type === requirementStatusTypes.done,
    [requirement.status.type],
  )
  const showEditButton = useMemo(
    () => hasUserEditPermission && !isDoneStatus,
    [hasUserEditPermission, isDoneStatus],
  )
  const showDeleteButton = useMemo(
    () => hasUserDeletePermission && !isDoneStatus,
    [hasUserDeletePermission, isDoneStatus],
  )

  const showRemindersButton = useMemo(() => !isDoneStatus, [isDoneStatus])

  const editDeleteButtonDisabled = useMemo(
    () => isEditModeForAnyRow || isRequirementAddMode,
    [isEditModeForAnyRow, isRequirementAddMode],
  )

  const buttonId = `action-button-${requirementId}`
  const editButtonId = `edit-button-${requirementId}`
  const deleteButtonId = `delete-button-${requirementId}`
  const changelogButtonId = `changelog-button-${requirementId}`
  const remindersButtonId = `reminders-button-${requirementId}`

  const onMenuItemClick = useCallback(
    ({ detail: { item: pressedMenuItem } }) => {
      setIsMenuOpen(false)

      const pressedIdentifier = pressedMenuItem.getAttribute('data-button-id')
      switch (pressedIdentifier) {
        case editButtonId:
          onEditButtonClicked()
          break
        case deleteButtonId:
          onDeleteButtonClicked()
          break
        case changelogButtonId:
          setIsChangelogDialogOpen(true)
          break
        case remindersButtonId:
          setIsOpenReminderDialog(true)
      }
    },
    [
      changelogButtonId,
      deleteButtonId,
      editButtonId,
      onDeleteButtonClicked,
      onEditButtonClicked,
      remindersButtonId,
    ],
  )

  const renderContentLoadingButton = useCallback(() => tNoPrefix('buttons.save'), [tNoPrefix])

  if (isEditModeForCurrentRow) {
    return (
      <FlexBox
        fitContainer
        justifyContent={FlexBoxJustifyContent.End}
        alignItems={FlexBoxAlignItems.Center}
        className={styles.buttonContainer}
      >
        <LoadingButton
          design={ButtonDesign.Emphasized}
          onClick={onSaveButtonClicked}
          disabled={isSaveButtonDisabled}
          name="save-button"
          isLoading={isEditLoading || isDeleteLoading}
          renderContent={renderContentLoadingButton}
        />
        <Button
          id={requirementCancelId}
          design={ButtonDesign.Transparent}
          onClick={onSaveCancelButtonClicked}
          name="cancel-button"
        >
          {tNoPrefix('buttons.cancel')}
        </Button>
      </FlexBox>
    )
  }

  return (
    <>
      <FlexBox
        fitContainer
        justifyContent={FlexBoxJustifyContent.End}
        alignItems={FlexBoxAlignItems.Center}
      >
        <Button
          id={buttonId}
          role="button"
          name="more-actions-button"
          onClick={onActionsButtonClick}
          icon="overflow"
          design={ButtonDesign.Transparent}
        />
        {isMenuOpen && (
          <Menu
            opener={buttonId}
            open={isMenuOpen}
            onAfterClose={closeActionsButtonMenu}
            onItemClick={onMenuItemClick}
          >
            {showEditButton && (
              <MenuItem
                text={tNoPrefix('buttons.edit')}
                name="edit-button"
                data-button-id={editButtonId}
                disabled={editDeleteButtonDisabled}
              />
            )}
            {showRemindersButton && (
              <MenuItem
                text={t('menu.reminders')}
                name="reminders-button"
                data-button-id={remindersButtonId}
              />
            )}
            <MenuItem
              text={t('menu.changelog')}
              name="changelog-button"
              data-button-id={changelogButtonId}
            />
            {showDeleteButton && (
              <MenuItem
                text={tNoPrefix('buttons.delete')}
                name="delete-button"
                data-button-id={deleteButtonId}
                disabled={editDeleteButtonDisabled}
              />
            )}
          </Menu>
        )}
      </FlexBox>
      <RequirementRemindersDialog
        isOpen={isReminderDialogOpen}
        setIsOpen={setIsOpenReminderDialog}
        requirementId={requirementId}
        requirementStatus={requirement.status}
      />
      {isChangelogDialogOpen && (
        <ConditionIndividualChangelogDialog
          isOpen={isChangelogDialogOpen}
          setIsOpen={setIsChangelogDialogOpen}
          entityType={ENTITIES.REQUIREMENT}
          entityId={requirementId}
          relatedEntityType={entityType}
          relatedEntityId={entityId}
          relatedEntityDisplayId={entityId}
        />
      )}
    </>
  )
}

RequirementsTableActionsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string,
      condition: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  }),
}
export default RequirementsTableActionsCell
