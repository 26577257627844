import { Button, ButtonDesign, TableGrowingMode } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropType from 'prop-types'
import { useMemo, useContext, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { requirementStatusTypes } from 'api/conditions/conditions'
import {
  neededOperationsForRequirementReminderCreate,
  neededOperationsForRequirementReminderDelete,
  neededOperationsForRequirementReminderEdit,
} from 'api/conditions/conditionsAllowedOperations'
import { hasUserRequiredOperations } from 'api/helper'
import styles from 'components/domains/conditions/dialogs/requirements/reminders/RequirementRemindersTable.module.css'
import RequirementReminderActionCells from 'components/domains/conditions/dialogs/requirements/reminders/table-cells/RequirementReminderActionCells'
import RequirementReminderMethodCell from 'components/domains/conditions/dialogs/requirements/reminders/table-cells/RequirementReminderMethodCell'
import TableInputCell from 'components/domains/conditions/overview/table-cells/view/TableInputCell'
import EditableDatePickerComponent from 'components/ui/input/EditableDatePickerComponent'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const emptyRequirementReminder = {
  comment: undefined,
  date: undefined,
  method: {
    code: undefined,
  },
}

const RequirementRemindersTable = ({
  reminders,
  totalReminders,
  onLoadMore,
  requirementId,
  requirementStatus,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.requirements.reminders.table',
  })
  const { t: tNoPrefix } = useTranslation()
  const { allowedOperations } = useContext(ConditionsContext)
  const { format: formatDate, localePattern, parse } = useShortDateFormatter()
  const [isEditModeForReminderId, setIsEditModeForReminderId] = useState()
  const [values, setValues] = useState()
  const [hasChanges, setHasChanges] = useState(false)
  const [isAddMode, setIsAddMode] = useState(false)

  const isRequirementStatusDone = useMemo(
    () => requirementStatus === requirementStatusTypes.done,
    [requirementStatus],
  )

  const showAddButton = useMemo(
    () =>
      hasUserRequiredOperations(neededOperationsForRequirementReminderCreate, allowedOperations) &&
      !isRequirementStatusDone,
    [allowedOperations, isRequirementStatusDone],
  )

  const showEditButton = useMemo(
    () =>
      hasUserRequiredOperations(neededOperationsForRequirementReminderEdit, allowedOperations) &&
      !isRequirementStatusDone,
    [allowedOperations, isRequirementStatusDone],
  )

  const showDeleteButton = useMemo(
    () =>
      hasUserRequiredOperations(neededOperationsForRequirementReminderDelete, allowedOperations) &&
      !isRequirementStatusDone,
    [allowedOperations, isRequirementStatusDone],
  )

  const isEditModeForCurrentRow = useCallback(
    (id) => id === isEditModeForReminderId,
    [isEditModeForReminderId],
  )

  const isEditModeForAnyRow = useMemo(
    () => !isNil(isEditModeForReminderId) || isAddMode,
    [isAddMode, isEditModeForReminderId],
  )

  const columns = useMemo(
    () => [
      {
        title: t('date'),
        columnKey: 'date',
        isSelectableForHiding: false,
      },
      {
        title: t('method'),
        columnKey: 'method',
        isSelectableForHiding: false,
      },
      {
        title: t('comment'),
        columnKey: 'comment',
        isSelectableForHiding: false,
      },
      {
        title: '',
        columnKey: 'actions',
        isSelectableForHiding: false,
      },
    ],
    [t],
  )

  const remindersData = useMemo(() => {
    if (isAddMode) {
      return [emptyRequirementReminder, ...reminders]
    }
    return reminders
  }, [isAddMode, reminders])

  const onChangeMethod = useCallback(
    (newMethod) => {
      setValues({ ...values, method: newMethod })
      if (newMethod === '') {
        setHasChanges(false)
        return
      }
      setHasChanges(true)
    },
    [values],
  )

  const onChangeDate = useCallback(
    ({ target: { liveValue: newDueDate } }) => {
      setValues({ ...values, date: parse(newDueDate, localePattern) })
      setHasChanges(true)
    },
    [localePattern, parse, values],
  )

  const onCommentInput = useCallback(
    (newComment) => {
      setValues({ ...values, comment: newComment })
      setHasChanges(true)
    },
    [values],
  )

  const onEditButtonClicked = useCallback(
    (id, date, method, comment) => {
      setIsEditModeForReminderId(id)
      setValues({ comment: comment, date: date, method: method.code })
    },
    [setIsEditModeForReminderId],
  )

  const tableData = useMemo(
    () =>
      remindersData.map(({ id, date, method, comment }) => ({
        rowKey: `reminder-table-${id}`,
        date: {
          cellComponent: (
            <EditableDatePickerComponent
              onChange={onChangeDate}
              value={formatDate(date)}
              editable={isEditModeForCurrentRow(id)}
              placeholder={localePattern}
              formatPattern={localePattern}
            />
          ),
        },
        method: {
          cellComponent: (
            <RequirementReminderMethodCell
              onChange={onChangeMethod}
              currentValue={isEditModeForCurrentRow(id) ? method.code : method.name}
              isEditMode={isEditModeForCurrentRow(id)}
              initialDropdownValue={values?.method}
            />
          ),
        },
        comment: {
          cellComponent: (
            <TableInputCell
              input={comment}
              isEditMode={isEditModeForCurrentRow(id)}
              onInput={onCommentInput}
              className={styles.inputWidth}
              useTextStyling={false}
            />
          ),
        },
        actions: {
          cellComponent: (
            <RequirementReminderActionCells
              isEditModeForAnyRow={isEditModeForAnyRow}
              isAddMode={isAddMode}
              setIsAddMode={setIsAddMode}
              showEditButton={showEditButton}
              showDeleteButton={showDeleteButton}
              isEditModeForReminderId={isEditModeForReminderId}
              setIsEditModeForReminderId={setIsEditModeForReminderId}
              hasChanges={hasChanges}
              setHasChanges={setHasChanges}
              values={values}
              requirementId={requirementId}
              reminderId={id}
              onEditButtonClicked={() => onEditButtonClicked(id, date, method, comment)}
            />
          ),
        },
      })),
    [
      formatDate,
      hasChanges,
      isAddMode,
      isEditModeForAnyRow,
      isEditModeForCurrentRow,
      isEditModeForReminderId,
      localePattern,
      onChangeDate,
      onChangeMethod,
      onCommentInput,
      onEditButtonClicked,
      remindersData,
      requirementId,
      showDeleteButton,
      showEditButton,
      values,
    ],
  )

  const onAddReminderClicked = useCallback(() => {
    setIsAddMode(true)
    setValues({ date: undefined, method: undefined, comment: undefined })
  }, [])

  const additionalActions = useMemo(() => {
    if (showAddButton) {
      return [
        <Button
          key="add-reminder-button"
          onClick={onAddReminderClicked}
          design={ButtonDesign.Transparent}
          disabled={isAddMode || isEditModeForAnyRow}
        >
          {tNoPrefix('buttons.add')}
        </Button>,
      ]
    }
    return []
  }, [showAddButton, onAddReminderClicked, isAddMode, isEditModeForAnyRow, tNoPrefix])

  return (
    <SortedTable
      columnDefinitions={columns}
      tableData={tableData}
      toolbarConfig={{
        title: t('toolbar.title'),
        showColumnSelection: false,
        additionalActions: additionalActions,
      }}
      additionalTableProperties={{
        stickyColumnHeader: true,
        className: styles.tableProperties,
      }}
      noDataText={t('no-data')}
      paginationConfig={{
        growing: tableData.length < totalReminders ? TableGrowingMode.Button : undefined,
        growingButtonText: t('button.load-more'),
        growingButtonSubtext: `[${tableData.length} / ${totalReminders}]`,
        totalNumberOfItems: totalReminders,
        loadMore: onLoadMore,
      }}
    />
  )
}

RequirementRemindersTable.propTypes = {
  reminders: PropType.arrayOf(
    PropType.shape({
      id: PropType.string.isRequired,
      date: PropType.string.isRequired,
      comment: PropType.string,
      method: PropType.shape({
        code: PropType.string.isRequired,
        name: PropType.string.isRequired,
      }).isRequired,
    }),
  ),
  totalReminders: PropType.number.isRequired,
  onLoadMore: PropType.func,
  requirementId: PropType.string.isRequired,
  requirementStatus: PropType.string,
}

export default RequirementRemindersTable
