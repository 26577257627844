import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js'
import {
  Input,
  ResponsiveGridLayout,
  Text,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DealCreationBusinessSegmentButtons } from 'components/domains/deals/creation-dialog/general-information-step/DealCreationBusinessSegmentButtons'
import { DealCreationDealTypeDropdown } from 'components/domains/deals/creation-dialog/general-information-step/DealCreationDealTypeDropdown'
import Labeled from 'components/ui/data/Labeled'
import StaffMemberAutocompleteInput from 'components/ui/input/StaffMemberAutocompleteInput'
import TextAreaWithMaxCharacterRestriction from 'components/ui/input/TextAreaWithMaxCharacterRestriction'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { standardMappings, useFormMapped } from 'hooks/form/useFormMapped'
import { useFormValidations } from 'hooks/form/useFormValidations'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'
import { useOriginationTeams } from 'hooks/services/deals/configurations/useOriginationTeams'

const dealCommentMaxLength = 200
const staffMemberAccountManagerMixins = {
  relation: { internalDealTeamRelation: 'ACCOUNT_MANAGER' },
  leader: true,
}

const TeamSeparators = {
  PBB_ADD: '\n',
  CWP_DISPLAY: ', ',
}

const DealCreationGeneralInfoStep = ({ wizardStep }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.dialog.general-information',
  })
  const { register, control, getValues, setValue, watch } = useFormMapped()
  const { required } = useFormValidations()

  const staffMemberId = watch('dealTeam.staffMembers.0.staffMember.id')
  const {
    data: selectedStaffMember,
    isLoading,
    isError,
    isFetching,
  } = useStaffMemberById(staffMemberId)

  const businessSegmentCode = getValues('businessSegmentCode')
  const { data: originationTeams = [] } = useOriginationTeams(businessSegmentCode)

  const getOriginationTeam = useCallback(
    (code) => originationTeams.find((originationTeam) => originationTeam.code === code),
    [originationTeams],
  )
  const originationTeamCode = getValues('originationTeamCode')

  const getOriginationPlatformDisplayName = (team) => {
    if (!team) {
      return ''
    }
    return team.platform
  }

  // In PBB AD they separate Teams with new line. Since the team value is of type string
  // we have to replace the \n with ', '.
  const getAccountManagerTeam = () => {
    const value = getValues('dealTeam.staffMembers.0.staffMember.team')
    if (!value) return ''
    return value.replaceAll(TeamSeparators.PBB_ADD, TeamSeparators.CWP_DISPLAY)
  }

  const originationPlatform = useMemo(() => {
    const originationTeam = getOriginationTeam(originationTeamCode)
    return getOriginationPlatformDisplayName(originationTeam)
  }, [originationTeamCode, getOriginationTeam])

  useEffect(() => {
    if (!isLoading && !isFetching && !isError && staffMemberId) {
      setValue('dealTeam.staffMembers', [
        { staffMember: selectedStaffMember, ...staffMemberAccountManagerMixins },
      ])
    }
  }, [isLoading, isFetching, isError, selectedStaffMember, staffMemberId, setValue])

  return (
    <ResponsiveGridLayout
      columnsS={1}
      columnsM={2}
      columnsL={2}
      columnsXL={2}
      columnGap="1rem"
      rowGap="1rem"
      className="deal-creation-dialog-step"
    >
      <div style={{ gridColumn: 'span 2' }}>
        <Title level={TitleLevel.H4}>{t(`title`, { wizardStep })}</Title>
      </div>
      <div style={{ gridColumn: 'span 2' }}>
        <Controller
          control={control}
          name="businessSegmentCode"
          rules={{ required: true, deps: 'dealTypeCode' }}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <DealCreationBusinessSegmentButtons
              name={name}
              businessSegmentCode={value}
              onBusinessSegmentSelect={(code, productType) => {
                onChange(code)
                setValue('tranche.productType', productType)
                setValue('originationTeamCode', '')
              }}
              onBlur={onBlur}
            />
          )}
        />
      </div>
      <div>
        <Labeled label={t('deal-name.label')} vertical showColon required>
          <Input
            id="deal-creation-name-input"
            maxlength={75}
            {...register('name', {
              validate: {
                required: required(),
              },
            })}
          />
        </Labeled>
      </div>
      <div>
        <Labeled label={t('deal-type.label')} vertical showColon required>
          <DealCreationDealTypeDropdown />
        </Labeled>
      </div>
      <div>
        <Labeled label={t('account-manager.label')} vertical showColon required>
          <Controller
            control={control}
            name="dealTeam.staffMembers"
            rules={{
              validate: {
                required: required(),
                staffMemberSet: required('0.staffMember'),
              },
            }}
            render={({
              field: { onChange, onBlur, value, name },
              fieldState: { invalid, error },
            }) => (
              <StaffMemberAutocompleteInput
                id="deal-creation-account-manager-input"
                staffMemberName={value?.[0]?.staffMember?.fullName}
                onStaffMemberSelect={(staffMember) =>
                  onChange([{ staffMember, ...staffMemberAccountManagerMixins }])
                }
                errorMessage={t('account-manager.input-error')}
                onBlur={onBlur}
                name={name}
                valueState={invalid ? 'Error' : undefined}
                valueStateMessage={<Text wrapping>{error?.message}</Text>}
              />
            )}
          />
        </Labeled>
      </div>
      <div>
        <Labeled label={t('team.label')} vertical showColon>
          <Input id="deal-creation-team-input" readonly value={getAccountManagerTeam()} />
        </Labeled>
      </div>
      <div>
        <Labeled label={t('origination-team.label')} vertical showColon required>
          <LoadingSelect
            id="deal-creation-origination-team-dropdown"
            loadingHook={useOriginationTeams}
            loadingHookParams={getValues('businessSegmentCode')}
            optionKeyName="code"
            optionDisplayName="name"
            {...register('originationTeamCode', {
              ...standardMappings.LoadingSelect,
              handlerMappings: {
                ...standardMappings.LoadingSelect.handlerMappings,
                onChange: (_, { trigger }) => ({
                  onChange: async (e, value) => {
                    await setValue(e.target.name, value)
                    await trigger(e.target.name)
                  },
                }),
              },
              validate: {
                required: required(),
              },
            })}
          />
        </Labeled>
      </div>
      <div>
        <Labeled label={t('origination-platform.label')} vertical showColon>
          <Input
            id="deal-creation-origination-platform-input"
            readonly
            value={originationPlatform}
          />
        </Labeled>
      </div>
      <div style={{ gridColumn: 'span 2' }}>
        <Labeled label={t('comment.label')} vertical showColon>
          <TextAreaWithMaxCharacterRestriction
            id="deal-creation-comment-textarea"
            maxLength={dealCommentMaxLength}
            {...register('comment', {
              handlerMappings: {
                onChange: (handler) => ({ onInput: (_, evt) => handler(evt) }),
              },
            })}
          />
        </Labeled>
      </div>
    </ResponsiveGridLayout>
  )
}

export default DealCreationGeneralInfoStep

DealCreationGeneralInfoStep.propTypes = {
  wizardStep: PropTypes.number.isRequired,
}
