import { FlexBox, FlexBoxDirection, FormItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'routes/deals/collaterals/creation/CollateralAgreementCreationDialog.module.css'

// This component is implicitly tested by CollateralAgreementCreationDialog.unit.test.jsx
const CustomFormItem = ({ children }) => (
  <FormItem>
    <FlexBox direction={FlexBoxDirection.Column} className={styles.uploadFormItem}>
      {children}
    </FlexBox>
  </FormItem>
)

CustomFormItem.propTypes = { children: PropTypes.node.isRequired }

export default CustomFormItem
