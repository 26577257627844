import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/checksheets/AnnualReviewCheckSheetTableCell.module.css'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'
import RichTextTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/RichTextTile'

const AnnualReviewCheckSheetTableCell = ({ question, value, customAnswerTranslationPrefix }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix:
      customAnswerTranslationPrefix ?? 'tiles.annual-review.appendix.checksheet.table.cell',
  })

  const { format: formatDate } = useShortDateFormatter()

  const renderAmlRiskClassification = () => (!isNil(value) ? value : t('data-not-available'))

  const renderDefault = () => (!isNil(value) ? t(value) : t('empty-value'))

  const renderDatePicker = () => (!isNil(value) ? formatDate(value) : t('empty-value'))

  const renderRTE = () =>
    !isNil(value) ? (
      <RichTextTile
        isEditMode={false}
        isPdfView={true}
        currentContent={value}
        className={styles.richTextTile}
      />
    ) : (
      t('empty-value')
    )

  switch (question) {
    case 'Q6':
      return renderAmlRiskClassification()
    case 'Q20':
      return renderDatePicker()
    case 'Comment':
      return renderRTE()

    default:
      return renderDefault()
  }
}

AnnualReviewCheckSheetTableCell.propTypes = {
  question: PropTypes.string.isRequired,
  customAnswerTranslationPrefix: PropTypes.string,
  value: PropTypes.string,
}

export default AnnualReviewCheckSheetTableCell
