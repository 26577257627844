import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/conditions/shared/assignee/ConditionAssigneeDisplay.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'

const ConditionAssigneeDisplay = ({ onRequestError, assigneeId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.columns.assignee',
  })

  const { data: assigneeUser, isLoading, isError, error } = useStaffMemberById(assigneeId)

  useEffect(() => {
    if (isError) {
      onRequestError(error)
    }
  }, [error, isError, onRequestError])

  const errorComponent = useMemo(
    () => (
      <Text wrapping className={styles.error}>
        {t('error')}
      </Text>
    ),
    [t],
  )
  const renderContent = useCallback(
    () => <Text wrapping>{assigneeUser.fullName}</Text>,
    [assigneeUser],
  )

  if (!assigneeId) {
    return null
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderContent}
      error={errorComponent}
    />
  )
}

ConditionAssigneeDisplay.propTypes = {
  onRequestError: PropTypes.func.isRequired,
  assigneeId: PropTypes.string,
}

export default ConditionAssigneeDisplay
