import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDecisionStageConditionsApprovalVoterVoteOptions = (
  { eventId, decisionStageId, decisionStageConditionsVoterId },
  options,
) =>
  useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/decision-stages/${decisionStageId}/conditions/voters/${decisionStageConditionsVoterId}/vote-options`,
      useCache: true,
      keys: [
        'events',
        eventId,
        'decision-stages',
        decisionStageId,
        'conditions',
        'voters',
        decisionStageConditionsVoterId,
        'vote-options',
      ],
      options,
    }),
  )

export default useDecisionStageConditionsApprovalVoterVoteOptions
