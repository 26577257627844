import { FlexBox, FlexBoxAlignItems, Text } from '@fioneer/ui5-webcomponents-react'
import { convertToNumberIfValid } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/convertToNumberIfValid'
import {
  CUSTOMER_OPTION_ROW_TYPES,
  OPTION_ATTRIBUTE_DATA_TYPES,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/customerOptionsConstants'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/renderDisplayModeCell.module.css'

const renderDisplayModeCell = (props, formatDate, formatNumber) => {
  const { cell, row } = props

  const columnKey = cell.column.id
  const renderClusterOptionAttributeCell = () => {
    switch (row.original.rowType) {
      case CUSTOMER_OPTION_ROW_TYPES.CLUSTER: {
        return (
          <Text className={[styles.textBold, styles.overflowEllipsis].join(' ')}>{cell.value}</Text>
        )
      }
      case CUSTOMER_OPTION_ROW_TYPES.OPTION_ATTRIBUTE: {
        if (row.original.type === OPTION_ATTRIBUTE_DATA_TYPES.TEXT) {
          return (
            <FlexBox
              alignItems={FlexBoxAlignItems.Start}
              className={[
                styles.textCell,
                styles.overflowEllipsis,
                styles['overflowMarginTreeLevel-1'],
              ].join(' ')}
            >
              <Text>{cell.value}</Text>
            </FlexBox>
          )
        }
        return (
          <Text
            className={[styles.overflowEllipsis, styles['overflowMarginTreeLevel-1']].join(' ')}
          >
            {cell.value}
          </Text>
        )
      }
      default:
        return (
          <Text
            className={[styles.overflowEllipsis, styles['overflowMarginTreeLevel-2']].join(' ')}
          >
            {cell.value}
          </Text>
        )
    }
  }

  const renderValueCell = () => {
    if (
      row.original.rowType === CUSTOMER_OPTION_ROW_TYPES.OPTION_ATTRIBUTE &&
      row.original.type === OPTION_ATTRIBUTE_DATA_TYPES.DATE
    ) {
      return <Text>{formatDate(cell.value)}</Text>
    } else if (
      row.original.rowType === CUSTOMER_OPTION_ROW_TYPES.OPTION_ATTRIBUTE &&
      row.original.type === OPTION_ATTRIBUTE_DATA_TYPES.ENUM
    ) {
      return <Text>{cell.row.original.range[cell.value]}</Text>
    } else if (
      row.original.rowType === CUSTOMER_OPTION_ROW_TYPES.OPTION_ATTRIBUTE &&
      row.original.type === OPTION_ATTRIBUTE_DATA_TYPES.FLOAT
    ) {
      const value = cell.value ? formatNumber(convertToNumberIfValid(cell.value)) : cell.value
      return <Text>{value}</Text>
    }

    return <Text>{cell.value}</Text>
  }

  switch (columnKey) {
    case 'clusterOptionAttribute': {
      return renderClusterOptionAttributeCell()
    }
    case 'value': {
      return renderValueCell()
    }
  }
}

export default renderDisplayModeCell
