import AnnualReviewAutomaticCheckSheetTileWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/appendix/automatic-checksheet/AnnualReviewAutomaticCheckSheetTileWrapper'
import AnnualReviewBorrowerSelectionTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/appendix/borrower-selection/AnnualReviewBorrowerSelectionTile'
import ApprovalRequestAuthorityLevelCalculatorTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/approval-request/authority-level-calculator/ApprovalRequestAuthorityLevelCalculatorTile'
import MultipleCashFlowAssumptionsTileSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/cashflow-analysis/MultipleCashFlowAssumptionsTileSelectionWrapper'
import MultipleCashFlowDebtAndEquityProjectionTileSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/cashflow-analysis/MultipleCashFlowDebtAndEquityProjectionTileSelectionWrapper'
import MultipleCashFlowFinancialRatiosTileSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/cashflow-analysis/MultipleCashFlowFinancialRatiosTileSelectionWrapper'
import MultipleCovenantsRatiosCovenantChecksMonitoringTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/covenants-ratios/MultipleCovenantsRatiosCovenantChecksMonitoringTileDealSelectionWrapper'
import MultipleCovenantsRatiosCovenantChecksResultsTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/covenants-ratios/check-results/MultipleCovenantsRatiosCovenantChecksResultsTileDealSelectionWrapper'
import AnnualReviewDealOverviewSelectionTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/deal-selection/AnnualReviewDealOverviewSelectionTile'
import MultiCollateralAgreementsTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/financing-structure/collateral-agreements/MultiCollateralAgreementsTileDealSelectionWrapper'
import MultipleCollateralAgreementsTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/financing-structure/standard-collateral-package/MultipleCollateralAgreementsTileDealSelectionWrapper'
import MultiExistingBusinessSyndicationStructureTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/financing-structure/syndication/MultiExistingBusinessSyndicationStructureTile'
import MultipleGeneralInformationTileSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/general-information/MultipleGeneralInformationTileSelectionWrapper'
import AnnualReviewDealOverviewBorrowerOverviewTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/involved-parties/AnnualReviewDealOverviewBorrowerOverviewTile'
import AnnualReviewDealOverviewSponsorGuarantorOverviewTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/involved-parties/sponsor-guarantor-overview/AnnualReviewDealOverviewSponsorGuarantorOverviewTile'
import MultipleFactSheetTegovaRatingTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/MultipleFactSheetTegovaRatingTileDealSelectionWrapper'
import MultipleFactSheetValuationResultsDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/MultipleFactSheetValuationResultsDealSelectionWrapper'
import MultipleFactSheetValuationTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/MultipleFactSheetValuationTileDealSelectionWrapper'
import MultiplePortfolioFinancedAssetsAreaTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/MultiplePortfolioFinancedAssetsAreaTileDealSelectionWrapper'
import MultipleAssetValuationOverviewTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/asset-list/MultipleAssetValuationOverviewTileDealSelectionWrapper'
import MultipleFurtherDetailsGranularityCityOrCountryTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/fact-sheet-further-details/MultipleFurtherDetailsGranularityCityOrCountryTileDealSelectionWrapper'
import MultipleFurtherDetailsGranularityPropertyTypeTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/fact-sheet-further-details/MultipleFurtherDetailsGranularityPropertyTypeTileDealSelectionWrapper'
import MultipleFurtherDetailsGranularityTegovaRatingTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/fact-sheet-further-details/MultipleFurtherDetailsGranularityTegovaRatingTileDealSelectionWrapper'
import MultipleFurtherDetailsGranularityTenantIndustryTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/fact-sheet-further-details/MultipleFurtherDetailsGranularityTenantIndustryTileDealSelectionWrapper'
import MultipleFurtherDetailsGranularityUsageTypeTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/fact-sheet-further-details/MultipleFurtherDetailsGranularityUsageTypeTileDealSelectionWrapper'
import MultiplePortfolioFinancedAssetsInformationTileSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/financed-assets-information/MultiplePortfolioFinancedAssetsInformationTileSelectionWrapper'
import MultipleKeyFactsTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/key-facts/MultipleKeyFactsTileDealSelectionWrapper'
import MultipleFactSheetValuationHistoryDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/valuation-history/MultipleFactSheetValuationHistoryDealSelectionWrapper'
import MultiCurrentRentTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/current-rent/MultiCurrentRentTileDealSelectionWrapper'
import TenancyOverviewOccupancyTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/occupancy/TenancyOverviewOccupancyTileDealSelectionWrapper'
import TenancyOverviewVacancyOverTimeTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/occupancy/TenancyOverviewVacancyOverTimeTileDealSelectionWrapper'
import MultiRentalIncomeOverTimeTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/rental-income-over-time/MultiRentalIncomeOverTimeTileDealSelectionWrapper'
import MultiTenancyOverviewTileDealSelectionWrapperV1V2 from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/tenancy-overview/MultiTenancyOverviewTileDealSelectionWrapperV1V2'
import MultiTenancyOverviewExpiringLeasesTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/tenancy-overview-expiring-leases/MultiTenancyOverviewExpiringLeasesTileDealSelectionWrapper'
import MultipleCashFlowVacancyProjectionTileSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/vacancy-projection/MultipleCashFlowVacancyProjectionTileSelectionWrapper'
import BusinessPartnerGeneralDescriptionTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review-non-ref/BusinessPartnerGeneralDescriptionTile'
import BaselTwoAuthorityLevelCalculatorTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/authority-level-calculator/BaselTwoAuthorityLevelCalculatorTile'
import BaselTwoAutomaticCheckSheetTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/automatic-checksheet/BaselTwoAutomaticCheckSheetTile'
import BaselTwoCoverPoolInformation from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/cover-pool-information/BaselTwoCoverPoolInformation'
import BaselTwoCriticalFacilityTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/critical-facility/CriticalFacilityTile'
import AnnualReviewPeriodicalCheckChecksTileWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/periodical-checks/checks/AnnualReviewPeriodicalCheckChecksTileWrapper'
import AnnualReviewPeriodicalCheckCommentTileWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/periodical-checks/comment/AnnualReviewPeriodicalCheckCommentTileWrapper'
import TotalDebtBundesbankTileWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/total-debt-bundesbank/TotalDebtBundesbankTileWrapper'
import ArrearsBusinessPartnerSummaryTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-bp-summary/ArrearsBusinessPartnerSummaryTile'
import ArrearsInApprovalTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/ArrearsInApprovalTile'
import ArrearsReportingSummary from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-reporting-summary/ArrearsReportingSummary'
import CashFlowVacancyProjectionTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/CashFlowVacancyProjectionTile'
import CashFlowAssumptionsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/banks-case/CashFlowAssumptionsTile'
import CashFlowDebtAndEquityProjectionTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/banks-case/CashFlowDebtAndEquityProjectionTile'
import CashFlowFinancialRatiosTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/banks-case/CashFlowFinancialRatiosTile'
import CheckSheetBasicCreditAuthorityLevelTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/CheckSheetBasicCreditAuthorityLevelTile'
import CheckSheetDecisionOverview from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/CheckSheetDecisionOverview'
import CheckSheetRegulatoryRequirementsStatisticsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/CheckSheetRegulatoryRequirementsStatisticsTile'
import CollateralAgreementsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/collaterals/CollateralAgreementsTile'
import StandardCollateralPackageTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/collaterals/StandardCollateralPackageTile'
import ExternalConditionsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/external/ExternalConditionsTile'
import InternalConditionsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/internal/InternalConditionsTile'
import { TILE_VERSION as tileVersion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import CashFlowScenarioOfCheckTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/cashflow-scenario/CashFlowScenarioOfCheckTile'
import CovenantCheckCommentTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/comment/CovenantCheckCommentTile'
import CovenantsOfCheckTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/CovenantsOfCheckTile'
import CovenantsRatiosCovenantsOverviewTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants-and-ratios/CovenantsRatiosCovenantsOverviewTile'
import CovenantsRatiosFinancialRatiosTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants-and-ratios/CovenantsRatiosFinancialRatiosTile'
import CoverPoolAdjustmentsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/cover-pool/CoverPoolAdjustmentsTile'
import BorrowerEsgStatementTileWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/custom-assessment/borrower-esg-statement/BorrowerEsgStatementTileWrapper'
import ConstructionAssessmentTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/custom-assessment/construction-assessment/ConstructionAssessmentTileDealSelectionWrapper'
import EsgStatementTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/custom-assessment/esg-statement/EsgStatementTileDealSelectionWrapper'
import FinancialAnalysisTileWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/custom-assessment/financial-analysis/FinancialAnalysisTileWrapper'
import LatestCustomAssessmentTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/custom-assessment/latest-custom-assesment/LatestCustomAssessmentTile'
import PropertyAssessmentTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/custom-assessment/property-assessment/PropertyAssessmentTileDealSelectionWrapper'
import SensitivityAnalysisTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/custom-assessment/sensitivity-analysis/SensitivityAnalysisTileDealSelectionWrapper'
import DealSourcesTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/deal-uses-and-sources/DealSourcesTile'
import DealUsesTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/deal-uses-and-sources/DealUsesTile'
import EventHistoryTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/EventHistoryTile'
import BaselTwoByProductClassTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/exposure/by-product-class/BaselTwoByProductClassTile'
import BaselTwoSettlementRiskOverviewTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/exposure/settlement/BaselTwoSettlementRiskOverviewTile'
import FurtherDetailsGranularityCityOrCountryTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/FurtherDetailsGranularityCityOrCountryTile'
import FurtherDetailsGranularityPropertyTypeTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/FurtherDetailsGranularityPropertyTypeTile'
import FurtherDetailsGranularityTegovaRatingTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/FurtherDetailsGranularityTegovaRatingTile'
import FurtherDetailsGranularityTenantIndustryTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/FurtherDetailsGranularityTenantIndustryTile'
import FurtherDetailsGranularityUsageTypeTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/FurtherDetailsGranularityUsageTypeTile'
import KpiChartTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/KpiChartTileDealSelectionWrapper'
import FinancingTermsCommitmentFeeTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/FinancingTermsCommitmentFeeTile'
import FinancingTermsGeneralInformationTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/FinancingTermsGeneralInformationTile'
import FinancingTermsPrepaymentFeeTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/FinancingTermsPrepaymentFeeTile'
import FinancingTermsPricingTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/FinancingTermsPricingTile'
import FinancingTermsTrancheDetailsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/FinancingTermsTrancheDetailsTile'
import AnnualReviewFinancingTermsTrancheDetailsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/annual-review/AnnualReviewFinancingTermsTrancheDetailsTile'
import FpoToolTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/fpo-tool/FpoToolTile'
import GccInvolvedPartiesAuthorityLevelCalculatorTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/authority-level-calculator/GccInvolvedPartiesAuthorityLevelCalculatorTile'
import BorrowerOverviewTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/borrower/BorrowerOverviewTile'
import GamStatementTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/gam-statement/GamStatementTile'
import GccInvolvedPartiesKamGamStatementGccTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/kam-gam-statement-gcc/GccInvolvedPartiesKamGamStatementGccTile'
import KamStatementTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/kam-statement/KamStatementTile'
import SponsorGuarantorOverviewTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/SponsorGuarantorOverviewTile'
import AnnualReviewUnitOverviewTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/AnnualReviewUnitOverviewTile'
import DecisionPaperGeneralInformationTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/general-information/DecisionPaperGeneralInformationTile'
import LoanMarketStatementSyndicationIntentionTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/loan-market-statement/LoanMarketStatementSyndicationIntentionTile'
import LoanMarketStatementSyndicationStatementTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/loan-market-statement/LoanMarketStatementSyndicationStatementTile'
import OpsMemoCollateralAgreementsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/OpsMemoCollateralAgreementsTile'
import OpsMemoCollateralsLoanRelationTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/collaterals/OpsMemoCollateralsLoanRelationTile'
import OpsMemoDealTeamInformationTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/deal/OpsMemoDealTeamInformationTile'
import OpsMemoDealTeamInternalMembersTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/deal/OpsMemoDealTeamInternalMembersTile'
import DealInformationTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/deal-information/DealInformationTile'
import OpsMemoPropertiesTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/property/OpsMemoPropertiesTable'
import OpsMemoSyndicationPostSaleTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/OpsMemoSyndicationPostSaleTile'
import OpsMemoSyndicationStructureTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/OpsMemoSyndicationStructureTile'
import OpsMemoTranchesTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche/OpsMemoTranchesTable'
import OpsMemoTrancheDetails from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/OpsMemoTrancheDetails'
import PortfolioFinancedAssetsAreaTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-area/PortfolioFinancedAssetsAreaTile'
import PortfolioFinancedAssetsInformationTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-information/PortfolioFinancedAssetsInformationTile'
import PortfolioFinancedAssetsViewTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/portfolio/financed-assets-information/PortfolioFinancedAssetsViewTile'
import AssetValuationOverviewTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/asset-valuation-overview/AssetValuationOverviewTile'
import FactSheetKeyFactsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/keyFacts/FactSheetKeyFactsTile'
import FactSheetTegovaRatingTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/tegovaRating/FactSheetTegovaRatingTile'
import FactSheetValuationTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation/FactSheetValuationTile'
import MilestonesTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/specifics-development/MilestonesTile'
import DrawdownBorrowerInformationTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/details/DrawdownBorrowerInformationTile'
import DrawdownDealStructureTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/details/deal-structure-tile/DrawdownDealStructureTile'
import RiskIndicatorsDetailedDecisionPaper from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-indicators/RiskIndicatorsDetailedDecisionPaper'
import CriticalFacilityTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/CriticalFacilityTile'
import ForbearanceMeasuresTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/ForbearanceMeasuresTable'
import ForbearanceStatusTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/ForbearanceStatusTable'
import RiskMonitoringCollateralAgreementsTileSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/RiskMonitoringCollateralAgreementsTileSelectionWrapper'
import RiskMonitoringDealSelectionTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/RiskMonitoringDealSelectionTile'
import RiskMonitoringStandardCollateralPackageTileSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/RiskMonitoringStandardCollateralPackageTileSelectionWrapper'
import CurrentArrearsTableTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/businesspartner/CurrentArrearsTableTile'
import useCurrentArrearsTableData from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/businesspartner/shared/useCurrentArrearsTableData'
import RiskMonitoringDealSpecificsValuationHistorySelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/deal-specifics/RiskMonitoringDealSpecificsValuationHistorySelectionWrapper'
import RiskMonitoringDealSpecificsValuationSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/deal-specifics/RiskMonitoringDealSpecificsValuationSelectionWrapper'
import RiskIndicatorsGroupBusinessEventsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/RiskIndicatorsGroupBusinessEventsTile'
import RiskIndicatorsGroupCriticalityTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/RiskIndicatorsGroupCriticalityTile'
import RiskIndicatorsGroupGeneralInformationDecisionPaper from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/RiskIndicatorsGroupGeneralInformationDecisionPaper'
import RiskMonitoringDescriptionTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/RiskMonitoringDescriptionTile'
import BusinessPartnerRatingsOverviewTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/pd-rating/BusinessPartnerRatingsOverviewTile'
import useCriticalFacilityData from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/useCriticalFacilityData'
import useForbearanceMeasuresDecisionPaper from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/useForbearanceMeasuresDecisionPaper'
import useForbearanceStatusTableData from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/useForbearanceStatusTableData'
import UnitSelectionTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/unit-overview/UnitSelectionTile'
import useUnitSelectionOptions from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/unit-overview/useUnitSelectionOptions'
import TenancyOverviewExpiringLeasesTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/TenancyOverviewExpiringLeasesTile'
import TenancyOverviewTileV1V2 from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenancy-overview/TenancyOverviewTileV1V2'
import MultipleTenantDetailsTileDealSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenant-details/MultipleTenantDetailsTileDealSelectionWrapper'
import TenantDetailsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/tenancy-rent-roll/tenant-details/TenantDetailsTile'
import WorkingVersionDetailsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/working-version/general-information/WorkingVersionDetailsTile'
import useAnnualReviewAutomaticCheckSheet from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/appendix/automatic-check-sheet/useAnnualReviewAutomaticCheckSheet'
import useAnnualReviewPeriodicalCheckMonitoringItems from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/appendix/periodical-checks/checks/useAnnualReviewPeriodicalCheckMonitoringItems'
import useAnnualReviewPeriodicalCheckComment from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/appendix/periodical-checks/comment/useAnnualReviewPeriodicalCheckComment'
import useAnnualReviewCashFlowAnalysis from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/cashflow-analysis/useAnnualReviewCashFlowAnalysis'
import useAnnualReviewFactSheetKeyFacts from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/useAnnualReviewFactSheetKeyFacts'
import useExistingBusinessSyndicationStructure from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/syndication-structure/useExistingBusinessSyndicationStructure'
import useAnnualReviewTenancyRentRollExpiringLeases from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/useAnnualReviewTenancyRentRollExpiringLeases'
import useAnnualReviewTenancyRentRollPropertyTilesOverview from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/useAnnualReviewTenancyRentRollPropertyTilesOverview'
import useAnnualReviewTenancyRentRollTenancyOverview from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/useAnnualReviewTenancyRentRollTenancyOverview'
import useMultipleAnnualReviewTenantDetails from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/useMulltipleAnnualReviewTennantDetails'
import useAnnualReviewBorrowerOverview from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/useAnnualReviewBorrowerOverview'
import useAnnualReviewDealOverviewSelection from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/useAnnualReviewDealOverviewSelection'
import useAnnualReviewMultipleCovenantChecks from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/useAnnualReviewMultipleCovenantChecks'
import useAnnualReviewMultipleCovenantChecksResults from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/useAnnualReviewMultipleCovenantChecksResults'
import useAnnualReviewSponsorGuarantorOverview from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/useAnnualReviewSponsorGuarantorOverview'
import useCollateralAgreementsByDealMetaData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/useCollateralAgreementsByDealMetaData'
import useMultipleAnnualReviewFactSheetValuation from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/useMultipleAnnualReviewFactSheetValuation'
import useMultipleAnnualReviewFurtherDetailsPieChartCardData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/useMultipleAnnualReviewFurtherDetailsPieChartCardData'
import useMultipleFactSheetTegovaRatings from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/useMultipleFactSheetTegovaRatings'
import useMultipleFactSheetValuation from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/useMultipleFactSheetValuation'
import useMultiplePortfolioFinancedAssetsArea from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/useMultiplePortfolioFinancedAssetsArea'
import useMultiplePortfolioFinancedAssetsInformation from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/useMultiplePortfolioFinancedAssetsInformation'
import useMultiplePortfolioFinancedAssetsInformationImages from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/useMultiplePortfolioFinancedAssetsInformationImages'
import useMultipleStandardCollateralPackage from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/useMultipleStandardCollateralPackage'
import useAnnualReviewAssetValuationOverview from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/useAnnualReviewAssetValuationOverview'
import useAnnualReviewBorrowerSelection from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/useAnnualReviewBorrowerSelection'
import useAnnualReviewGamStatement from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/useAnnualReviewGamStatement'
import useAnnualReviewGeneralInformation from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/useAnnualReviewGeneralInformation'
import useAnnualReviewKamStatement from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/useAnnualReviewKamStatement'
import useAnnualReviewMultipleFinancingTerms from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/useAnnualReviewMultipleFinancingTerms'
import useAnnualReviewUnitDealOverview from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/useAnnualReviewUnitDealOverview'
import useBusinessPartnerGeneralDescription from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review-non-ref/useBusinessPartnerGeneralDescription'
import useBaselTwoAutomaticCheckSheet from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/basel-two/automatic-check-sheet/useBaselTwoAutomaticCheckSheet'
import useBaselTwoCoverPoolInformation from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/basel-two/useBaselTwoCoverPoolInformation'
import useBusinessPartnerBaselTwoAuthorityLevelCalculator from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/basel-two/useBusinessPartnerBaselTwoAuthorityLevelCalculator'
import useTotalDebtBundesbankTile from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/shared/useTotalDebtBundesbankTile'
import useCheckSheetBasicCreditAuthorityLevel from 'hooks/services/business-events-and-tasks/decision-papers/tiles/check-sheet/useCheckSheetBasicCreditAuthorityLevel'
import useCheckSheetDecisionOverview from 'hooks/services/business-events-and-tasks/decision-papers/tiles/check-sheet/useCheckSheetDecisionOverview'
import useCheckSheetRegulatoryRequirementsStatistics from 'hooks/services/business-events-and-tasks/decision-papers/tiles/check-sheet/useCheckSheetRegulatoryRequirementsStatistics'
import useOpsMemoCollateralOverview from 'hooks/services/business-events-and-tasks/decision-papers/tiles/collateral-overview/useOpsMemoCollateralOverview'
import useCollateralAgreements from 'hooks/services/business-events-and-tasks/decision-papers/tiles/collaterals/useCollateralAgreements'
import useStandardCollateralPackage from 'hooks/services/business-events-and-tasks/decision-papers/tiles/collaterals/useStandardCollateralPackage'
import useCashFlowScenarioOfCheck from 'hooks/services/business-events-and-tasks/decision-papers/tiles/covenants/cashflow-scenario/useCashFlowScenarioOfCheck'
import useCovenantCheckComment from 'hooks/services/business-events-and-tasks/decision-papers/tiles/covenants/comment/useCovenantCheckComment'
import useCovenantsOfCheck from 'hooks/services/business-events-and-tasks/decision-papers/tiles/covenants/covenants-of-check/useCovenantsOfCheck'
import useCovenantsRatiosCovenantsOverview from 'hooks/services/business-events-and-tasks/decision-papers/tiles/covenants-and-ratios/useCovenantsRatiosCovenantsOverview'
import useCovenantsRatiosFinancialRatios from 'hooks/services/business-events-and-tasks/decision-papers/tiles/covenants-and-ratios/useCovenantsRatiosFinancialRatios'
import useCoverPoolAdjustments from 'hooks/services/business-events-and-tasks/decision-papers/tiles/cover-pool/useCoverPoolAdjustments'
import useBorrowerEsgStatement from 'hooks/services/business-events-and-tasks/decision-papers/tiles/custom-assessments/borrorwer-esg-statement/useBorrowerEsgStatement'
import useMultipleConstructionAssessment from 'hooks/services/business-events-and-tasks/decision-papers/tiles/custom-assessments/construction-assessments/useMultipleConstructionAssessment'
import useMultipleEsgStatement from 'hooks/services/business-events-and-tasks/decision-papers/tiles/custom-assessments/esg-statement/useMultipleEsgStatement'
import useFinancialAnalysis from 'hooks/services/business-events-and-tasks/decision-papers/tiles/custom-assessments/financial-analysis/useFinancialAnalysis'
import usePropertyAssessments from 'hooks/services/business-events-and-tasks/decision-papers/tiles/custom-assessments/property-assessments/usePropertyAssessments'
import useMultipleSensitivityAnalysis from 'hooks/services/business-events-and-tasks/decision-papers/tiles/custom-assessments/sensitivity-analysis/useMultipleSensitivityAnalysis'
import useConstructionAssessment from 'hooks/services/business-events-and-tasks/decision-papers/tiles/deal/financed-objects/useConstructionAssessment'
import useEsgStatement from 'hooks/services/business-events-and-tasks/decision-papers/tiles/deal/financed-objects/useEsgStatement'
import useSensitivityAnalysis from 'hooks/services/business-events-and-tasks/decision-papers/tiles/deal/financed-objects/useSensitivityAnalysis'
import useDealSourcesAndUses from 'hooks/services/business-events-and-tasks/decision-papers/tiles/deal-uses-and-sources/useDealSourcesAndUses'
import useEventHistory from 'hooks/services/business-events-and-tasks/decision-papers/tiles/event/useEventHistory'
import useBaselTwoByProductClass from 'hooks/services/business-events-and-tasks/decision-papers/tiles/exposure/by-product-class/useBaselTwoByProductClass'
import useBaselTwoSettlementRisk from 'hooks/services/business-events-and-tasks/decision-papers/tiles/exposure/settlement/useBaselTwoSettlementRisk'
import { useFactSheetKeyFacts } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/fact-sheet/useFactSheetKeyFacts'
import { useFactSheetTegovaRatings } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/fact-sheet/useFactSheetTegovaRatings'
import { useFactSheetValuation } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/fact-sheet/useFactSheetValuation'
import useFurtherDetailsPieChartCardData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/fact-sheet-further-details/useFurtherDetailsPieChartCardData'
import useKpiChartTile from 'hooks/services/business-events-and-tasks/decision-papers/tiles/financial-ratio/kpi-chart/useKpiChartTile'
import useFinancingTerms from 'hooks/services/business-events-and-tasks/decision-papers/tiles/financing-terms/useFinancingTerms'
import useFpoToolTile from 'hooks/services/business-events-and-tasks/decision-papers/tiles/fpo-tool/useFpoToolTile'
import useBusinessPartnerGccAuthorityLevelCalculator from 'hooks/services/business-events-and-tasks/decision-papers/tiles/gcc-involved-parties/authority-level-calculator/useBusinessPartnerGccAuthorityLevelCalculator'
import useGccInvolvedPartiesAuthorityLevelCalculator from 'hooks/services/business-events-and-tasks/decision-papers/tiles/gcc-involved-parties/authority-level-calculator/useGccInvolvedPartiesAuthorityLevelCalculator'
import useGamStatement from 'hooks/services/business-events-and-tasks/decision-papers/tiles/gcc-involved-parties/borrower/gam-statement/useGamStatement'
import useKamStatement from 'hooks/services/business-events-and-tasks/decision-papers/tiles/gcc-involved-parties/borrower/kam-statement/useKamStatement'
import useBorrowerOverview from 'hooks/services/business-events-and-tasks/decision-papers/tiles/gcc-involved-parties/borrower/useBorrowerOverview'
import useGccInvolvedPartiesKamGamStatementGcc from 'hooks/services/business-events-and-tasks/decision-papers/tiles/gcc-involved-parties/kam-gam-statement-gcc/useGccInvolvedPartiesKamGamStatementGcc'
import { useSponsorGuarantorOverview } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/gcc-involved-parties/sponsor-guarantor/useSponsorGuarantorOverview'
import { useDecisionPaperGeneralInformation } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/general-information/useDecisionPaperGeneralInformation'
import useDrawdownBorrowerInformation from 'hooks/services/business-events-and-tasks/decision-papers/tiles/request/details/useDrawdownBorrowerInformation'
import useDrawdownDealStructure from 'hooks/services/business-events-and-tasks/decision-papers/tiles/request/details/useDrawdownDealStructure'
import useMonitoredRiskIndicatorCheckOverviewPdRating from 'hooks/services/business-events-and-tasks/decision-papers/tiles/risk-monitoring/useMonitoredRiskIndicatorCheckOverviewPdRating'
import useRiskIndicatorDealCollateralAgreements from 'hooks/services/business-events-and-tasks/decision-papers/tiles/risk-monitoring/useRiskIndicatorDealCollateralAgreements'
import useRiskIndicatorDealSelection from 'hooks/services/business-events-and-tasks/decision-papers/tiles/risk-monitoring/useRiskIndicatorDealSelection'
import useRiskIndicatorDealSpecificsValuation from 'hooks/services/business-events-and-tasks/decision-papers/tiles/risk-monitoring/useRiskIndicatorDealSpecificsValuation'
import useRiskIndicatorDealSpecificsValuationHistory from 'hooks/services/business-events-and-tasks/decision-papers/tiles/risk-monitoring/useRiskIndicatorDealSpecificsValuationHistory'
import useRiskIndicatorDealStandardCollateralPackage from 'hooks/services/business-events-and-tasks/decision-papers/tiles/risk-monitoring/useRiskIndicatorDealStandardCollateralPackage'
import useRiskIndicatorsDecisionPaper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/risk-monitoring/useRiskIndicatorsDecisionPaper'
import useRiskMonitoringBusinessEvents from 'hooks/services/business-events-and-tasks/decision-papers/tiles/risk-monitoring/useRiskMonitoringBusinessEvents'
import useRiskMonitoringCriticality from 'hooks/services/business-events-and-tasks/decision-papers/tiles/risk-monitoring/useRiskMonitoringCriticality'
import useRiskIndicatorsDescription from 'hooks/services/business-events-and-tasks/decision-papers/tiles/risk-monitoring/useRiskMonitoringDescription'
import useRiskMonitoringGeneralInformationDecisionPaper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/risk-monitoring/useRiskMonitoringGeneralInformationDecisionPaper'
import useArrearsBusinessPartnerSummary from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useArrearsBusinessPartnerSummary'
import useArrearsInformation from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useArrearsInformation'
import useArrearsReportingSummary from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useArrearsReportingSummary'
import { useAssetValuationOverview } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useAssetValuationOverview'
import useCashFlowAnalysis from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useCashFlowAnalysis'
import useExternalConditions from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useExternalConditions'
import useInternalConditions from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useInternalConditions'
import useLoanMarketStatement from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useLoanMarketStatement'
import useMilestones from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useMilestones'
import useOpsMemoDealInformation from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useOpsMemoDealInformation'
import useOpsMemoDealTeamInformation from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useOpsMemoDealTeamInformation'
import useOpsMemoDealTeamInternalMembers from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useOpsMemoDealTeamInternalMembers'
import useOpsMemoPropertiesTable from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useOpsMemoPropertiesTable'
import useOpsMemoSyndicationPostSale from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useOpsMemoSyndicationPostSale'
import useOpsMemoSyndicationStructure from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useOpsMemoSyndicationStructure'
import useOpsMemoTrancheDetails from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useOpsMemoTrancheDetails'
import useOpsMemoTranchesTable from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useOpsMemoTranchesTable'
import usePortfolioFinancedAssetsArea from 'hooks/services/business-events-and-tasks/decision-papers/tiles/usePortfolioFinancedAssetsArea'
import usePortfolioFinancedAssetsInformation from 'hooks/services/business-events-and-tasks/decision-papers/tiles/usePortfolioFinancedAssetsInformation'
import usePortfolioFinancedAssetsInformationImages from 'hooks/services/business-events-and-tasks/decision-papers/tiles/usePortfolioFinancedAssetsInformationImages'
import useTenancyOverview from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useTenancyOverview'
import useTenancyOverviewExpiringLeases from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useTenancyOverviewExpiringLeases'
import useTenantDetails from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useTenantDetails'
import { useWorkingVersionDetails } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/general-information/useWorkingVersionDetails'

// Can't use the paths here due to the fact that redux and the file get initialized before the Routes file.
// import paths from 'routes/paths'

const defaultImageLoader = () => ({ isDefaultLoader: true })

export const availableAutomaticTiles = {
  annualReviewAuthorityLevelCalculator: 'ANNUAL_REVIEW_AUTHORITY_LEVEL_CALCULATOR',
  annualReviewBaselTwoAuthorityLevelCalculator:
    'ANNUAL_REVIEW_BASEL_II_CONFIRMATION_AUTHORITY_LEVEL_CALCULATOR',
  annualReviewBaselTwoByProductClassTile:
    'ANNUAL_REVIEW_BASEL_II_CONFIRMATION_EXPOSURE_BY_PRODUCT_CLASSES',
  annualReviewBaselTwoConfirmationCoverPoolInformation:
    'ANNUAL_REVIEW_BASEL_II_CONFIRMATION_COVER_POOL_INFORMATION',
  annualReviewBaselTwoConfirmationCriticalFacilityStatus:
    'ANNUAL_REVIEW_BASEL_II_CONFIRMATION_CRITICAL_FACILITY_STATUS',
  annualReviewBaselTwoSettlementRiskOverview:
    'ANNUAL_REVIEW_BASEL_II_CONFIRMATION_SETTLEMENT_RISK_OVERVIEW',
  annualReviewBusinessPartnerPaperKpiChartsPerDeal: 'BP_PAPER_KPI_CHARTS_PER_DEAL',
  annualReviewDealSelection: 'DEAL_SELECTION_TILE',
  annualReviewGamStatement: 'ANNUAL_REVIEW_GAM_STATEMENT',
  annualReviewKamStatement: 'ANNUAL_REVIEW_KAM_STATEMENT',
  annualReviewUnitDealOverview: 'ANNUAL_REVIEW_UNIT_DEAL_OVERVIEW',
  arrearsBusinessPartnerSummary: 'ARREARS_BUSINESS_PARTNER_SUMMARY',
  arrearsInApproval: 'ARREARS_IN_APPROVAL',
  arrearsReportingSummary: 'ARREARS_REPORTING_SUMMARY',
  assetValuationOverview: 'ASSET_VALUATION_OVERVIEW',
  assetValuationOverviewWVLive: 'WV_LIVE_ASSET_VALUATION_OVERVIEW',
  authorityLevelCalculator: 'AUTHORITY_LEVEL_CALCULATOR',
  authorityLevelCalculatorWVLive: 'WV_LIVE_AUTHORITY_LEVEL_CALCULATOR',
  baselTwoAutomaticChecksheet: 'BASEL_II_AUTOMATIC_CHECK_SHEET',
  bpBorrowerOverview: 'BP_BORROWER_OVERVIEW',
  bpBorrowerOverviewWVLive: 'WV_LIVE_BP_BORROWER_OVERVIEW',
  bpSponsorGuarantorOverview: 'BP_SPONSOR_GUARANTOR_OVERVIEW',
  bpSponsorGuarantorOverviewWVLive: 'WV_LIVE_BP_SPONSOR_GUARANTOR_OVERVIEW',
  businessPartnerGeneralDescription: 'BUSINESS_PARTNER_GENERAL_DESCRIPTION',
  cashFlowAssumptionsAdditionalCase: 'CASH_FLOW_ASSUMPTIONS_ADDITIONAL_CASE',
  cashFlowAssumptionsAdditionalCaseWVLive: 'WV_LIVE_CASH_FLOW_ASSUMPTIONS_ADDITIONAL_CASE',
  cashFlowAssumptionsBaseCase: 'CASH_FLOW_ASSUMPTIONS_BASE_CASE',
  cashFlowAssumptionsBaseCaseWVLive: 'WV_LIVE_CASH_FLOW_ASSUMPTIONS_BASE_CASE',
  cashFlowAssumptionsStressCase: 'CASH_FLOW_ASSUMPTIONS_STRESS_CASE',
  cashFlowAssumptionsStressCaseWVLive: 'WV_LIVE_CASH_FLOW_ASSUMPTIONS_STRESS_CASE',
  cashFlowDebtAndEquityProjectionAdditionalCase: 'CASH_FLOW_DEBT_EQUITY_PROJECTION_ADDITIONAL_CASE',
  cashFlowDebtAndEquityProjectionAdditionalCaseWVLive:
    'WV_LIVE_CASH_FLOW_DEBT_EQUITY_PROJECTION_ADDITIONAL_CASE',
  cashFlowDebtAndEquityProjectionBaseCase: 'CASH_FLOW_DEBT_EQUITY_PROJECTION_BASE_CASE',
  cashFlowDebtAndEquityProjectionBaseCaseWVLive:
    'WV_LIVE_CASH_FLOW_DEBT_EQUITY_PROJECTION_BASE_CASE',
  cashFlowDebtAndEquityProjectionStressCase: 'CASH_FLOW_DEBT_EQUITY_PROJECTION_STRESS_CASE',
  cashFlowDebtAndEquityProjectionStressCaseWVLive:
    'WV_LIVE_CASH_FLOW_DEBT_EQUITY_PROJECTION_STRESS_CASE',
  cashFlowFinancialRatiosAdditionalCase: 'CASH_FLOW_FINANCIAL_RATIOS_ADDITIONAL_CASE',
  cashFlowFinancialRatiosAdditionalCaseWVLive: 'WV_LIVE_CASH_FLOW_FINANCIAL_RATIOS_ADDITIONAL_CASE',
  cashFlowFinancialRatiosBaseCase: 'CASH_FLOW_FINANCIAL_RATIOS_BASE_CASE',
  cashFlowFinancialRatiosBaseCaseWVLive: 'WV_LIVE_CASH_FLOW_FINANCIAL_RATIOS_BASE_CASE',
  cashFlowFinancialRatiosStressCase: 'CASH_FLOW_FINANCIAL_RATIOS_STRESS_CASE',
  cashFlowFinancialRatiosStressCaseWVLive: 'WV_LIVE_CASH_FLOW_FINANCIAL_RATIOS_STRESS_CASE',
  cashFlowVacancyProjection: 'CASH_FLOW_VACANCY_PROJECTION',
  cashFlowVacancyProjectionWVLive: 'WV_LIVE_CASH_FLOW_VACANCY_PROJECTION',
  checkSheetBasicCreditAuthorityLevel: 'CHECK_SHEET_BASIS_CREDIT_AUTHORITY_LEVEL',
  checkSheetBasicCreditAuthorityLevelWVLive: 'WV_LIVE_CHECK_SHEET_BASIS_CREDIT_AUTHORITY_LEVEL',
  checkSheetDecisionOverview: 'CHECK_SHEET_DECISION_OVERVIEW',
  checkSheetRegulatoryRequirementsStatistics: 'CHECK_SHEET_REGULATORY_REQUIREMENTS_STATISTICS',
  checkSheetRegulatoryRequirementsStatisticsWVLive:
    'WV_LIVE_CHECK_SHEET_REGULATORY_REQUIREMENTS_STATISTICS',
  collateralAgreements: 'COLLATERAL_AGREEMENTS',
  collateralAgreementsWVLive: 'WV_LIVE_COLLATERAL_AGREEMENTS',
  constructionAssessment: 'CONSTRUCTION_ASSESSMENT',
  constructionAssessmentWVLive: 'WV_LIVE_CONSTRUCTION_ASSESSMENT',
  covenantCheckCashFlowScenario: 'CASHFLOW_SCENARIO_OF_CHECK',
  covenantCheckComment: 'COVENANT_CHECK_COMMENT',
  covenantsOfCheckOverview: 'COVENANTS_OF_CHECK_OVERVIEW',
  covenantsRatiosCovenantsOverview: 'COVENANTS_RATIOS_COVENANTS_OVERVIEW',
  covenantsRatiosCovenantsOverviewWVLive: 'WV_LIVE_COVENANTS_RATIOS_COVENANTS_OVERVIEW',
  covenantsRatiosFinancialRatios: 'COVENANTS_RATIOS_FINANCIAL_RATIOS',
  covenantsRatiosFinancialRatiosWVLive: 'WV_LIVE_COVENANTS_RATIOS_FINANCIAL_RATIOS',
  coverPoolAdjustment: 'COVERPOOL_ADJUSTMENTS',
  dealSources: 'DEAL_SOURCES',
  dealSourcesWVLive: 'WV_LIVE_DEAL_SOURCES',
  dealUses: 'DEAL_USES',
  dealUsesWVLive: 'WV_LIVE_DEAL_USES',
  drawdownBorrowerInformation: 'DRAWDOWN_BORROWER_INFORMATION',
  drawdownDealStructure: 'DRAWDOWN_DEAL_STRUCTURE',
  esgStatement: 'ESG_STATEMENT',
  esgStatementWVLive: 'WV_LIVE_ESG_STATEMENT',
  eventHistory: 'EVENT_HISTORY',
  eventHistoryWVLive: 'WV_LIVE_EVENT_HISTORY',
  externalConditions: 'EXTERNAL_CONDITIONS',
  factSheetKeyFacts: 'FACT_SHEET_KEY_FACTS',
  factSheetKeyFactsWVLive: 'WV_LIVE_FACT_SHEET_KEY_FACTS',
  factSheetTegovaRating: 'FACT_SHEET_TEGOVA_RATING',
  factSheetTegovaRatingWVLive: 'WV_LIVE_FACT_SHEET_TEGOVA_RATING',
  factSheetValuation: 'FACT_SHEET_VALUATION',
  factSheetValuationWVLive: 'WV_LIVE_FACT_SHEET_VALUATION',
  financedAssetArea: 'FINANCED_ASSET_AREA',
  financedAssetAreaWVLive: 'WV_LIVE_FINANCED_ASSET_AREA',
  financedAssetInformation: 'FINANCED_ASSET_INFORMATION',
  financedAssetInformationWVLive: 'WV_LIVE_FINANCED_ASSET_INFORMATION',
  financedAssetView: 'FINANCED_ASSET_VIEW',
  financedAssetViewWVLive: 'WV_LIVE_FINANCED_ASSET_VIEW',
  financingTermsCommitmentFee: 'FINANCING_TERMS_COMMITMENT_FEE',
  financingTermsCommitmentFeeWVLive: 'WV_LIVE_FINANCING_TERMS_COMMITMENT_FEE',
  financingTermsGeneralInfo: 'FINANCING_TERMS_GENERAL_INFO',
  financingTermsGeneralInfoWVLive: 'WV_LIVE_FINANCING_TERMS_GENERAL_INFO',
  financingTermsPrepaymentFee: 'FINANCING_TERMS_PREPAYMENT_FEE',
  financingTermsPrepaymentFeeWVLive: 'WV_LIVE_FINANCING_TERMS_PREPAYMENT_FEE',
  financingTermsPricing: 'FINANCING_TERMS_PRICING',
  financingTermsPricingWVLive: 'WV_LIVE_FINANCING_TERMS_PRICING',
  financingTermsTrancheDetails: 'FINANCING_TERMS_TRANCHE_DETAILS',
  financingTermsTrancheDetailsWVLive: 'WV_LIVE_FINANCING_TERMS_TRANCHE_DETAILS',
  forbearanceMeasures: 'FORBEARANCE_MEASURES',
  forbearanceStatus: 'FORBEARANCE_STATUS',
  fpoTool: 'FPO_RESULT',
  fpoToolWVLive: 'WV_LIVE_FPO_RESULT',
  furtherDetailsGranularityCity: 'FACT_SHEET_FURTHER_DETAILS_GRANULARITY_CITY',
  furtherDetailsGranularityCityWVLive: 'WV_LIVE_FACT_SHEET_FURTHER_DETAILS_GRANULARITY_CITY',
  furtherDetailsGranularityIndustry: 'FACT_SHEET_FURTHER_DETAILS_GRANULARITY_INDUSTRY',
  furtherDetailsGranularityIndustryWVLive:
    'WV_LIVE_FACT_SHEET_FURTHER_DETAILS_GRANULARITY_INDUSTRY',
  furtherDetailsGranularityPropertyType: 'FACT_SHEET_FURTHER_DETAILS_GRANULARITY_PROPERTY_TYPE',
  furtherDetailsGranularityPropertyTypeWVLive:
    'WV_LIVE_FACT_SHEET_FURTHER_DETAILS_GRANULARITY_PROPERTY_TYPE',
  furtherDetailsGranularityTegova: 'FACT_SHEET_FURTHER_DETAILS_GRANULARITY_TEGOVA',
  furtherDetailsGranularityTegovaWVLive: 'WV_LIVE_FACT_SHEET_FURTHER_DETAILS_GRANULARITY_TEGOVA',
  furtherDetailsGranularityUsageType: 'FACT_SHEET_FURTHER_DETAILS_GRANULARITY_USAGE_TYPE',
  furtherDetailsGranularityUsageTypeWVLive:
    'WV_LIVE_FACT_SHEET_FURTHER_DETAILS_GRANULARITY_USAGE_TYPE',
  gamStatement: 'GAM_STATEMENT',
  gamStatementWVLive: 'WV_LIVE_GAM_STATEMENT',
  generalInformation: 'GENERAL_INFORMATION',
  generalInformationWVLive: 'WV_LIVE_GENERAL_INFORMATION',
  internalConditions: 'INTERNAL_CONDITIONS',
  issuesAndStrategy: 'MONITORED_RISK_INDICATOR_RISK_MONITORING_ISSUES_AND_STRATEGY',
  kamGamStatementGcc: 'KAM_GAM_STATEMENT_GCC',
  kamGamStatementGccWVLive: 'WV_LIVE_KAM_GAM_STATEMENT_GCC',
  kamStatement: 'KAM_STATEMENT',
  kamStatementWVLive: 'WV_LIVE_KAM_STATEMENT',
  loanMarketSyndicationIntention: 'SYNDICATION_INTENTION',
  loanMarketSyndicationIntentionWVLive: 'WV_LIVE_SYNDICATION_INTENTION',
  loanMarketSyndicationStatement: 'SYNDICATION_STATEMENT',
  loanMarketSyndicationStatementWVLive: 'WV_LIVE_SYNDICATION_STATEMENT',
  milestones: 'MILESTONES',
  milestonesWVLive: 'WV_LIVE_MILESTONES',
  monitoredRiskIndicatorAuthorityLevelCalculator:
    'MONITORED_RISK_INDICATOR_FINANCIAL_OVERVIEW_AUTHORITY_LEVEL_CALCULATOR',
  monitoredRiskIndicatorCheckOverviewPdRating:
    'MONITORED_RISK_INDICATOR_CHECK_OVERVIEW_AND_PD_RATING_PD_RATING',
  monitoredRiskIndicatorDealSpecificsCollateralAgreements:
    'MONITORED_RISK_INDICATOR_DEAL_SPECIFICS_COLLATERAL_AGREEMENTS',
  monitoredRiskIndicatorDealSpecificsDealSelection:
    'MONITORED_RISK_INDICATOR_DEAL_SPECIFICS_DEAL_SELECTION',
  monitoredRiskIndicatorDealSpecificsStandardCollateralPackage:
    'MONITORED_RISK_INDICATOR_DEAL_SPECIFICS_STANDARD_COLLATERAL_PACKAGE',
  monitoredRiskIndicatorDealSpecificsValuation: 'MONITORED_RISK_INDICATOR_DEAL_SPECIFICS_VALUATION',
  monitoredRiskIndicatorDealSpecificsValuationHistory:
    'MONITORED_RISK_INDICATOR_DEAL_SPECIFICS_VALUATION_HISTORY',
  monitoredRiskIndicatorFinancialOverviewCurrentArrears:
    'MONITORED_RISK_INDICATOR_FINANCIAL_OVERVIEW_CURRENT_ARREARS',
  monitoredRiskIndicatorRegulatoryChecksCurrentCriticalFacilityStatus:
    'MONITORED_RISK_INDICATOR_REGULATORY_CHECKS_CURRENT_CRITICAL_FACILITY_STATUS',
  monitoredRiskIndicatorRiskMonitoringBusinessEvents:
    'MONITORED_RISK_INDICATOR_RISK_MONITORING_BUSINESS_EVENTS',
  monitoredRiskIndicatorRiskMonitoringCriticality:
    'MONITORED_RISK_INDICATOR_RISK_MONITORING_CRITICALITY',
  monitoredRiskIndicatorRiskMonitoringGeneralInformation:
    'MONITORED_RISK_INDICATOR_RISK_MONITORING_GENERAL_INFORMATION',
  monitoredRiskIndicatorRiskMonitoringRiskIndicators:
    'MONITORED_RISK_INDICATOR_RISK_MONITORING_RISK_INDICATORS',
  multipleAppendixBusinessPartnerDetailsAnnualReviewChecks:
    'MULTIPLE_APPENDIX_BUSINESS_PARTNER_DETAILS_ANNUAL_REVIEW_CHECKS',
  multipleAppendixBusinessPartnerDetailsAnnualReviewComment:
    'MULTIPLE_APPENDIX_BUSINESS_PARTNER_DETAILS_ANNUAL_REVIEW_COMMENT',
  multipleAppendixBusinessPartnerDetailsAutomaticChecksheet:
    'MULTIPLE_APPENDIX_BUSINESS_PARTNER_DETAILS_AUTOMATIC_CHECK_SHEET',
  multipleAppendixBusinessPartnerDetailsBorrowerSelection:
    'MULTIPLE_APPENDIX_BUSINESS_PARTNER_DETAILS_BORROWER_SELECTION',
  multipleAppendixBusinessPartnerDetailsKwg18Checks:
    'MULTIPLE_APPENDIX_BUSINESS_PARTNER_DETAILS_KWG18_CHECKS',
  multipleAppendixBusinessPartnerDetailsKwg18Comment:
    'MULTIPLE_APPENDIX_BUSINESS_PARTNER_DETAILS_KWG18_COMMENT',
  multipleAppendixBusinessPartnerDetailsKycChecks:
    'MULTIPLE_APPENDIX_BUSINESS_PARTNER_DETAILS_KYC_CHECKS',
  multipleAppendixBusinessPartnerDetailsKycComment:
    'MULTIPLE_APPENDIX_BUSINESS_PARTNER_DETAILS_KYC_COMMENT',
  multipleAppendixBusinessPartnerDetailsRatingChecks:
    'MULTIPLE_APPENDIX_BUSINESS_PARTNER_DETAILS_RATING_CHECKS',
  multipleAppendixBusinessPartnerDetailsRatingComment:
    'MULTIPLE_APPENDIX_BUSINESS_PARTNER_DETAILS_RATING_COMMENT',
  multipleAssetList: 'MULTIPLE_ASSET_LIST',
  multipleBorrowerEsgStatement: 'MULTIPLE_APPENDIX_BORROWER_ESG_STATEMENT',
  multipleBpBorrowerOverview: 'MULTIPLE_BP_BORROWER_OVERVIEW',
  multipleBpSponsorGuarantor: 'MULTIPLE_BP_SPONSOR_GUARANTOR_OVERVIEW',
  multipleCashFlowAnalysisCashCashFlowAssumptionsBaseCase:
    'MULTIPLE_CASH_FLOW_ANALYSIS_CASH_CASH_FLOW_ASSUMPTIONS_BASE_CASE',
  multipleCashFlowAnalysisCashCashFlowDebtEquityProjectionBaseCase:
    'MULTIPLE_CASH_FLOW_ANALYSIS_CASH_CASH_FLOW_DEBT_EQUITY_PROJECTION_BASE_CASE',
  multipleCashFlowAnalysisCashFlowAssumptionsStressCase:
    'MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_ASSUMPTIONS_STRESS_CASE',
  multipleCashFlowAnalysisCashFlowDebtEquityProjectionStressCase:
    'MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_DEBT_EQUITY_PROJECTION_STRESS_CASE',
  multipleCashFlowAnalysisCashFlowFinancialRatiosBaseCase:
    'MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_FINANCIAL_RATIOS_BASE_CASE',
  multipleCashFlowAnalysisCashFlowFinancialRatiosStressCase:
    'MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_FINANCIAL_RATIOS_STRESS_CASE',
  multipleCollateralAgreements: 'MULTIPLE_COLLATERAL_AGREEMENTS',
  multipleConstructionAssessment: 'MULTIPLE_PROPERTY_PORTFOLIO_CONSTRUCTION_ASSESSMENT',
  multipleConstructionAssessmentWVLive:
    'WV_LIVE_MULTIPLE_PROPERTY_PORTFOLIO_CONSTRUCTION_ASSESSMENT',
  multipleCovenantsRatiosCovenantChecksMonitoring:
    'MULTIPLE_COVENANTS_RATIOS_COVENANT_CHECKS_MONITORING',
  multipleCovenantsRatiosCovenantChecksResults: 'MULTIPLE_COVENANTS_RATIOS_COVENANT_CHECKS_RESULTS',
  multipleEsgStatement: 'MULTIPLE_PROPERTY_PORTFOLIO_ESG_STATEMENT',
  multipleExistingBusinessSyndicationStructure: 'MULTIPLE_EXISTING_BUSINESS_SYNDICATION',
  multipleFinancedAssetArea: 'MULTIPLE_FINANCED_ASSET_AREA',
  multipleFinancedAssetInformation: 'MULTIPLE_FINANCED_ASSET_INFORMATION',
  multipleFinancialAnalysis: 'MULTIPLE_APPENDIX_FINANCIAL_ANALYSIS',
  multipleFinancingTermsTrancheDetails: 'MULTIPLE_FINANCING_TERMS_TRANCHE_DETAILS',
  multipleFurtherDetailsGranularityCity: 'MULTIPLE_FURTHER_DETAILS_GRANULARITY_CITY',
  multipleFurtherDetailsGranularityIndustry: 'MULTIPLE_FURTHER_DETAILS_GRANULARITY_INDUSTRY',
  multipleFurtherDetailsGranularityPropertyType:
    'MULTIPLE_FURTHER_DETAILS_GRANULARITY_PROPERTY_TYPE',
  multipleFurtherDetailsGranularityTegova: 'MULTIPLE_FURTHER_DETAILS_GRANULARITY_TEGOVA',
  multipleFurtherDetailsGranularityUsageType: 'MULTIPLE_FURTHER_DETAILS_GRANULARITY_USAGE_TYPE',
  multipleKeyFacts: 'MULTIPLE_KEY_FACTS',
  multiplePropertyAssessment: 'MULTIPLE_PROPERTY_PORTFOLIO_ASSESSMENT',
  multipleSensitivityAnalysis: 'MULTIPLE_PROPERTY_PORTFOLIO_SENSITIVITY_ANALYSIS',
  multipleStandardCollateralPackage: 'MULTIPLE_STANDARD_COLLATERAL_PACKAGE',
  multipleTegovaRating: 'MULTIPLE_TEGOVA_RATING',
  multipleTenancyRentRollCurrentRent: 'MULTIPLE_TENANCY_RENT_ROLL_CURRENT_RENT',
  multipleTenancyRentRollExpiringLeases: 'MULTIPLE_TENANCY_RENT_ROLL_EXPIRING_LEASES',
  multipleTenancyRentRollOccupancy: 'MULTIPLE_TENANCY_RENT_ROLL_OCCUPANCY',
  multipleTenancyRentRollRentalIncomeOverTime: 'MULTIPLE_TENANCY_RENT_ROLL_RENTAL_INCOME_OVER_TIME',
  multipleTenancyRentRollTenancyOverview: 'MULTIPLE_TENANCY_RENT_ROLL_TENANCY_OVERVIEW',
  multipleTenancyRentRollTenantDetails: 'MULTIPLE_TENANCY_RENT_ROLL_TENANT_DETAILS',
  multipleTenancyRentRollVacancyOverTime: 'MULTIPLE_TENANCY_RENT_ROLL_VACANCY_OVER_TIME',
  multipleTenancyRentRollVacancyProjection: 'MULTIPLE_TENANCY_RENT_ROLL_VACANCY_PROJECTION',
  multipleTransactionOverviewGeneralInformation: 'MULTIPLE_GENERAL_INFORMATION',
  multipleValuation: 'MULTIPLE_VALUATION',
  multipleValuationHistory: 'MULTIPLE_VALUATION_HISTORY',
  multipleValuationResults: 'MULTIPLE_VALUATION_RESULTS',
  nonRelevantRiskIndicatorFinancialOverviewAuthorityLevelCalculator:
    'NON_RELEVANT_RISK_INDICATOR_FINANCIAL_OVERVIEW_AUTHORITY_LEVEL_CALCULATOR',
  nonRelevantRiskIndicatorRiskMonitoringBusinessEvents:
    'NON_RELEVANT_RISK_INDICATOR_RISK_MONITORING_BUSINESS_EVENTS',
  nonRelevantRiskIndicatorRiskMonitoringCriticality:
    'NON_RELEVANT_RISK_INDICATOR_RISK_MONITORING_CRITICALITY',
  nonRelevantRiskIndicatorRiskMonitoringGeneralInformation:
    'NON_RELEVANT_RISK_INDICATOR_RISK_MONITORING_GENERAL_INFORMATION',
  nonRelevantRiskIndicatorRiskMonitoringIssuesAndStrategy:
    'NON_RELEVANT_RISK_INDICATOR_RISK_MONITORING_ISSUES_AND_STRATEGY',
  nonRelevantRiskIndicatorRiskMonitoringRiskIndicators:
    'NON_RELEVANT_RISK_INDICATOR_RISK_MONITORING_RISK_INDICATORS',
  opsMemoCollateralsAgreements: 'OPS_MEMO_COLLATERAL_AGREEMENTS_TABLE',
  opsMemoCollateralsAgreementsWVLive: 'WV_LIVE_OPS_MEMO_COLLATERAL_AGREEMENTS_TABLE',
  opsMemoCollateralsLoanToCagRelations: 'OPS_MEMO_LOAN_TO_CAG_RELATIONS_TABLE',
  opsMemoCollateralsLoanToCagRelationsWVLive: 'WV_LIVE_OPS_MEMO_LOAN_TO_CAG_RELATIONS_TABLE',
  opsMemoDealDetailsInfo: 'OPS_MEMO_DEAL_DETAILS_INFO',
  opsMemoDealDetailsInfoWVLive: 'WV_LIVE_OPS_MEMO_DEAL_DETAILS_INFO',
  opsMemoDealTeamInfo: 'OPS_MEMO_DEAL_TEAM_INFO',
  opsMemoDealTeamInfoWVLive: 'WV_LIVE_OPS_MEMO_DEAL_TEAM_INFO',
  opsMemoDealTeamMembers: 'OPS_MEMO_DEAL_TEAM_MEMBERS',
  opsMemoDealTeamMembersWVLive: 'WV_LIVE_OPS_MEMO_DEAL_TEAM_MEMBERS',
  opsMemoProperties: 'OPS_MEMO_PROPERTIES',
  opsMemoPropertiesWVLive: 'WV_LIVE_OPS_MEMO_PROPERTIES',
  opsMemoSyndicationPostSale: 'OPS_MEMO_SYNDICATION_POST_SALE',
  opsMemoSyndicationPostSaleWVLive: 'WV_LIVE_OPS_MEMO_SYNDICATION_POST_SALE',
  opsMemoSyndicationStructureTable: 'OPS_MEMO_SYNDICATION_STRUCTURE_TABLE',
  opsMemoSyndicationStructureTableWVLive: 'WV_LIVE_OPS_MEMO_SYNDICATION_STRUCTURE_TABLE',
  opsMemoTrancheDetails: 'OPS_MEMO_TRANCHE_DETAILS',
  opsMemoTrancheDetailsWVLive: 'WV_LIVE_OPS_MEMO_TRANCHE_DETAILS',
  opsMemoTranches: 'OPS_MEMO_TRANCHES',
  opsMemoTranchesWVLive: 'WV_LIVE_OPS_MEMO_TRANCHES',
  riskMonitoringForbearanceMeasures:
    'MONITORED_RISK_INDICATOR_REGULATORY_CHECKS_FORBEARANCE_MEASURES',
  riskMonitoringRegulatoryChecksForbearanceStatus:
    'MONITORED_RISK_INDICATOR_REGULATORY_CHECKS_FORBEARANCE_STATUS',
  sensitivityAnalysis: 'SENSITIVITY_ANALYSIS',
  sensitivityAnalysisWVLive: 'WV_LIVE_SENSITIVITY_ANALYSIS',
  standardCollateralPackage: 'STANDARD_COLLATERAL_PACKAGE',
  standardCollateralPackageWVLive: 'WV_LIVE_STANDARD_COLLATERAL_PACKAGE',
  tenancyOverview: 'TENANCY_OVERVIEW',
  tenancyOverviewExpiringLeases: 'EXPIRING_LEASES',
  tenancyOverviewExpiringLeasesWVLive: 'WV_LIVE_EXPIRING_LEASES',
  tenancyOverviewWVLive: 'WV_LIVE_TENANCY_OVERVIEW',
  tenantDetails: 'TENANT_DETAILS',
  tenantDetailsWVLive: 'WV_LIVE_TENANT_DETAILS',
  totalDebtBundesbank: 'TOTAL_DEBT_BUNDESBANK',
  unitSelectionTile: 'UNIT_SELECTION_TILE',
  workingVersionDetails: 'WORKING_VERSION_DETAILS',
}
/**
 * Each tile consists of 2 main parts.
 *
 * @typedef {object} source
 * @property {string} nameTranslationKey The translation key to translate the Header.
 * @property {string} path The path. Will be substituted with the results of the hook data and the link will be opened in a new tab
 *
 * @typedef {object} viewComponentProps
 * @property {string} tileId The id of the tile.
 * @property {boolean} isPdfView Whether the decision paper is in pdf view or not.
 * @property {boolean} isEditMode Whether the decision paper is in edit mode or not.
 *
 * @typedef {object} AutomaticTileConfig
 * @property {() => import('react').ReactNode} viewComponent the component that can handle the view of the tile.
 * @property {Function} liveHookLoader hook that can load the data when the decision paper is in live mode.
 * @property {Function} [liveImagesLoader] hook that can load the images when the decision paper is in live mode.
 * @property {source[]} sources Array of objects defining the optional source renders of each tile in the header of the card.
 */
/**
 * @satisfies {{[key in typeof availableAutomaticTiles[keyof typeof availableAutomaticTiles]]?: AutomaticTileConfig}}
 */
export const automaticTileCodeMap = {
  [availableAutomaticTiles.annualReviewAuthorityLevelCalculator]: {
    viewComponent: ApprovalRequestAuthorityLevelCalculatorTile,
    liveHookLoader: useBusinessPartnerGccAuthorityLevelCalculator,
    sources: [
      {
        nameTranslationKey: 'tiles.shared.authority-level-calculator',
        path: `/business-partners/\${businessPartnerId}/authority-level-calculator`,
      },
    ],
  },
  [availableAutomaticTiles.annualReviewBaselTwoAuthorityLevelCalculator]: {
    viewComponent: BaselTwoAuthorityLevelCalculatorTile,
    liveHookLoader: useBusinessPartnerBaselTwoAuthorityLevelCalculator,
    sources: [
      {
        nameTranslationKey: 'tiles.shared.authority-level-calculator',
        path: `/business-partners/\${businessPartnerId}/authority-level-calculator`,
      },
    ],
  },
  [availableAutomaticTiles.annualReviewBaselTwoByProductClassTile]: {
    viewComponent: BaselTwoByProductClassTile,
    liveHookLoader: useBaselTwoByProductClass,
    sources: [
      {
        nameTranslationKey: 'tiles.exposure',
        path: '/business-partners/${businessPartnerId}/exposure',
      },
    ],
    version: tileVersion.V2,
  },
  [availableAutomaticTiles.annualReviewBaselTwoConfirmationCoverPoolInformation]: {
    viewComponent: BaselTwoCoverPoolInformation,
    liveHookLoader: useBaselTwoCoverPoolInformation,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.cover-pool-information.source',
        path: `/deals/\${dealDisplayId}/financing`,
      },
    ],
  },
  [availableAutomaticTiles.annualReviewBaselTwoConfirmationCriticalFacilityStatus]: {
    viewComponent: BaselTwoCriticalFacilityTile,
    liveHookLoader: useCriticalFacilityData,
    sources: [
      {
        nameTranslationKey: 'tiles.business-partner.general-information',
        path: '/business-partners/${businessPartnerId}/general-information',
      },
    ],
  },
  [availableAutomaticTiles.annualReviewBaselTwoSettlementRiskOverview]: {
    viewComponent: BaselTwoSettlementRiskOverviewTile,
    liveHookLoader: useBaselTwoSettlementRisk,
    sources: [
      {
        nameTranslationKey: 'tiles.exposure',
        path: '/business-partners/${businessPartnerId}/exposure',
      },
    ],
  },
  [availableAutomaticTiles.annualReviewDealSelection]: {
    viewComponent: AnnualReviewDealOverviewSelectionTile,
    liveHookLoader: useAnnualReviewDealOverviewSelection,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.overview',
        path: '/deals/${dealDisplayId}',
      },
    ],
  },
  [availableAutomaticTiles.annualReviewGamStatement]: {
    viewComponent: GamStatementTile,
    liveHookLoader: useAnnualReviewGamStatement,
    sources: [
      {
        nameTranslationKey: 'tiles.gam-statement.source',
        path: `/business-partners/\${businessPartnerId}/assessments`,
      },
    ],
  },
  [availableAutomaticTiles.annualReviewKamStatement]: {
    viewComponent: KamStatementTile,
    liveHookLoader: useAnnualReviewKamStatement,
    sources: [
      {
        nameTranslationKey: 'tiles.kam-statement.source',
        path: `/business-partners/\${businessPartnerId}/assessments`,
      },
    ],
  },
  [availableAutomaticTiles.annualReviewUnitDealOverview]: {
    viewComponent: AnnualReviewUnitOverviewTile,
    liveHookLoader: useAnnualReviewUnitDealOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.unit-overview.source',
        path: `/business-partners/\${businessPartnerId}/unit-overview`,
      },
    ],
  },
  [availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsAnnualReviewChecks]: {
    viewComponent: AnnualReviewPeriodicalCheckChecksTileWrapper,
    liveHookLoader: useAnnualReviewPeriodicalCheckMonitoringItems,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.periodical-checks',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsAnnualReviewComment]: {
    viewComponent: AnnualReviewPeriodicalCheckCommentTileWrapper,
    liveHookLoader: useAnnualReviewPeriodicalCheckComment,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.periodical-checks',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsAutomaticChecksheet]: {
    viewComponent: AnnualReviewAutomaticCheckSheetTileWrapper,
    liveHookLoader: useAnnualReviewAutomaticCheckSheet,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.arrears',
        path: '/business-partners/${businessPartnerId}/arrears',
      },
      {
        nameTranslationKey: 'tiles.annual-review.sources.authority-level-calculator',
        path: '/business-partners/${businessPartnerId}/authority-level-calculator',
      },
      {
        nameTranslationKey: 'tiles.annual-review.sources.deals',
        path: '/deals?borrower_id=${businessPartnerId}',
      },
      {
        nameTranslationKey: 'tiles.annual-review.sources.regulatory-information',
        path: '/business-partners/${businessPartnerId}/general-information',
      },
    ],
  },
  [availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsBorrowerSelection]: {
    viewComponent: AnnualReviewBorrowerSelectionTile,
    liveHookLoader: useAnnualReviewBorrowerSelection,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.checksheet.sources.overview',
        path: '/business-partners/${businessPartnerId}',
      },
    ],
  },
  [availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsKwg18Checks]: {
    viewComponent: AnnualReviewPeriodicalCheckChecksTileWrapper,
    liveHookLoader: useAnnualReviewPeriodicalCheckMonitoringItems,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.periodical-checks',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsKwg18Comment]: {
    viewComponent: AnnualReviewPeriodicalCheckCommentTileWrapper,
    liveHookLoader: useAnnualReviewPeriodicalCheckComment,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.periodical-checks',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsKycChecks]: {
    viewComponent: AnnualReviewPeriodicalCheckChecksTileWrapper,
    liveHookLoader: useAnnualReviewPeriodicalCheckMonitoringItems,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.periodical-checks',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsKycComment]: {
    viewComponent: AnnualReviewPeriodicalCheckCommentTileWrapper,
    liveHookLoader: useAnnualReviewPeriodicalCheckComment,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.periodical-checks',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsRatingChecks]: {
    viewComponent: AnnualReviewPeriodicalCheckChecksTileWrapper,
    liveHookLoader: useAnnualReviewPeriodicalCheckMonitoringItems,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.periodical-checks',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsRatingComment]: {
    viewComponent: AnnualReviewPeriodicalCheckCommentTileWrapper,
    liveHookLoader: useAnnualReviewPeriodicalCheckComment,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.periodical-checks',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multipleAssetList]: {
    viewComponent: MultipleAssetValuationOverviewTileDealSelectionWrapper,
    liveHookLoader: useAnnualReviewAssetValuationOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.financed-objects',
        path: '/deals/${dealDisplayId}/financed-objects',
      },
    ],
    version: tileVersion.V3,
  },
  [availableAutomaticTiles.multipleBorrowerEsgStatement]: {
    viewComponent: BorrowerEsgStatementTileWrapper,
    liveHookLoader: useBorrowerEsgStatement,
    sources: [
      {
        nameTranslationKey: 'tiles.assessments',
        path: '/business-partners/${businessPartnerDisplayId}/assessments',
      },
    ],
  },
  [availableAutomaticTiles.multipleBpBorrowerOverview]: {
    viewComponent: AnnualReviewDealOverviewBorrowerOverviewTile,
    liveHookLoader: useAnnualReviewBorrowerOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.general-information',
        path: '/business-partners/${businessPartnerId}/general-information',
      },
      {
        nameTranslationKey: 'tiles.annual-review.sources.ratings',
        path: '/business-partners/${businessPartnerId}/ratings',
      },
      {
        nameTranslationKey: 'tiles.annual-review.sources.financial-indicators',
        path: '/business-partners/${businessPartnerId}/fiscal-information',
      },
      {
        nameTranslationKey: 'tiles.annual-review.sources.assessments',
        path: '/business-partners/${businessPartnerId}/assessments',
      },
    ],
    version: tileVersion.V3,
  },
  [availableAutomaticTiles.multipleBpSponsorGuarantor]: {
    viewComponent: AnnualReviewDealOverviewSponsorGuarantorOverviewTile,
    liveHookLoader: useAnnualReviewSponsorGuarantorOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.general-information',
        path: '/business-partners/${businessPartnerId}/general-information',
      },
      {
        nameTranslationKey: 'tiles.annual-review.sources.ratings',
        path: '/business-partners/${businessPartnerId}/ratings',
      },
      {
        nameTranslationKey: 'tiles.annual-review.sources.financial-indicators',
        path: '/business-partners/${businessPartnerId}/fiscal-information',
      },
      {
        nameTranslationKey: 'tiles.annual-review.sources.assessments',
        path: '/business-partners/${businessPartnerId}/assessments',
      },
    ],
  },
  [availableAutomaticTiles.annualReviewBusinessPartnerPaperKpiChartsPerDeal]: {
    viewComponent: KpiChartTileDealSelectionWrapper,
    liveHookLoader: useKpiChartTile,
    sources: [
      {
        nameTranslationKey: 'tiles.key-performance-indicators',
        path: '/deals/${dealDisplayId}/kpis',
      },
    ],
    version: tileVersion.V3,
  },
  [availableAutomaticTiles.arrearsBusinessPartnerSummary]: {
    viewComponent: ArrearsBusinessPartnerSummaryTile,
    liveHookLoader: useArrearsBusinessPartnerSummary,
    sources: [
      {
        nameTranslationKey: 'tiles.arrears.source',
        path: `/business-partners/\${businessPartnerId}/arrears`,
      },
    ],
    version: tileVersion.V2,
  },
  [availableAutomaticTiles.arrearsInApproval]: {
    viewComponent: ArrearsInApprovalTile,
    liveHookLoader: useArrearsInformation,
    sources: [
      {
        nameTranslationKey: 'tiles.arrears.source',
        path: `/business-partners/\${businessPartnerId}/arrears`,
      },
    ],
    version: tileVersion.V2,
  },
  [availableAutomaticTiles.arrearsReportingSummary]: {
    viewComponent: ArrearsReportingSummary,
    liveHookLoader: useArrearsReportingSummary,
    sources: [
      {
        nameTranslationKey: 'tiles.arrears.source',
        path: `/business-partners/\${businessPartnerId}/arrears`,
      },
    ],
  },
  [availableAutomaticTiles.assetValuationOverview]: {
    viewComponent: AssetValuationOverviewTile,
    liveHookLoader: useAssetValuationOverview,
    sources: [
      {
        nameTranslationKey: 'subsection.fact-sheet.source',
        path: `/deals/\${dealId}/financed-objects\${sourcePathSuffix}`,
      },
    ],
    version: tileVersion.V3,
  },
  [availableAutomaticTiles.assetValuationOverviewWVLive]: {
    viewComponent: AssetValuationOverviewTile,
    liveHookLoader: useAssetValuationOverview,
    sources: [
      {
        nameTranslationKey: 'subsection.fact-sheet.source',
        path: `/deals/\${dealId}/financed-objects`,
      },
    ],
    version: tileVersion.V3,
  },
  [availableAutomaticTiles.authorityLevelCalculator]: {
    viewComponent: GccInvolvedPartiesAuthorityLevelCalculatorTile,
    liveHookLoader: useGccInvolvedPartiesAuthorityLevelCalculator,
    sources: [
      {
        nameTranslationKey: 'tiles.shared.authority-level-calculator',
        path: `/business-partners/\${businessPartnerId}/authority-level-calculator`,
      },
    ],
  },
  [availableAutomaticTiles.authorityLevelCalculatorWVLive]: {
    viewComponent: GccInvolvedPartiesAuthorityLevelCalculatorTile,
    liveHookLoader: useGccInvolvedPartiesAuthorityLevelCalculator,
    sources: [
      {
        nameTranslationKey: 'tiles.shared.authority-level-calculator',
        path: `/business-partners/\${businessPartnerId}/authority-level-calculator`,
      },
    ],
  },
  [availableAutomaticTiles.baselTwoAutomaticChecksheet]: {
    viewComponent: BaselTwoAutomaticCheckSheetTile,
    liveHookLoader: useBaselTwoAutomaticCheckSheet,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.arrears',
        path: '/business-partners/${businessPartnerId}/arrears',
      },
      {
        nameTranslationKey: 'tiles.annual-review.sources.authority-level-calculator',
        path: '/business-partners/${businessPartnerId}/authority-level-calculator',
      },
      {
        nameTranslationKey: 'tiles.annual-review.sources.deals',
        path: '/deals?borrower_id=${businessPartnerId}',
      },
      {
        nameTranslationKey: 'tiles.annual-review.sources.regulatory-information',
        path: '/business-partners/${businessPartnerId}/general-information',
      },
    ],
  },
  [availableAutomaticTiles.bpBorrowerOverview]: {
    viewComponent: BorrowerOverviewTile,
    liveHookLoader: useBorrowerOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.bp-borrower-overview.source.general-information',
        path: `/business-partners/\${businessPartnerId}/general-information`,
      },
      {
        nameTranslationKey: 'tiles.bp-borrower-overview.source.ratings',
        path: `/business-partners/\${businessPartnerId}/ratings`,
      },
      {
        nameTranslationKey: 'tiles.bp-borrower-overview.source.financial-indicators',
        path: `/business-partners/\${businessPartnerId}/fiscal-information`,
      },
      {
        nameTranslationKey: 'tiles.bp-borrower-overview.source.assessments',
        path: `/business-partners/\${businessPartnerId}/assessments`,
      },
    ],
  },
  [availableAutomaticTiles.bpBorrowerOverviewWVLive]: {
    viewComponent: BorrowerOverviewTile,
    liveHookLoader: useBorrowerOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.bp-borrower-overview.source.general-information',
        path: `/business-partners/\${businessPartnerId}/general-information`,
      },
      {
        nameTranslationKey: 'tiles.bp-borrower-overview.source.ratings',
        path: `/business-partners/\${businessPartnerId}/ratings`,
      },
      {
        nameTranslationKey: 'tiles.bp-borrower-overview.source.financial-indicators',
        path: `/business-partners/\${businessPartnerId}/fiscal-information`,
      },
      {
        nameTranslationKey: 'tiles.bp-borrower-overview.source.assessments',
        path: `/business-partners/\${businessPartnerId}/assessments`,
      },
    ],
  },
  [availableAutomaticTiles.bpSponsorGuarantorOverview]: {
    viewComponent: SponsorGuarantorOverviewTile,
    liveHookLoader: useSponsorGuarantorOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.bp-sponsor-and-guarantor-overview.source',
        path: `/business-partners`,
      },
    ],
  },
  [availableAutomaticTiles.bpSponsorGuarantorOverviewWVLive]: {
    viewComponent: SponsorGuarantorOverviewTile,
    liveHookLoader: useSponsorGuarantorOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.bp-sponsor-and-guarantor-overview.source',
        path: `/business-partners`,
      },
    ],
  },
  [availableAutomaticTiles.businessPartnerGeneralDescription]: {
    viewComponent: BusinessPartnerGeneralDescriptionTile,
    liveHookLoader: useBusinessPartnerGeneralDescription,
    sources: [
      {
        nameTranslationKey: 'tiles.non-ref-annual-review.general-description.source',
        path: '/business-partners/${businessPartnerId}/assessments',
      },
    ],
  },
  [availableAutomaticTiles.cashFlowAssumptionsAdditionalCase]: {
    viewComponent: CashFlowAssumptionsTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowAssumptionsAdditionalCaseWVLive]: {
    viewComponent: CashFlowAssumptionsTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowAssumptionsBaseCase]: {
    viewComponent: CashFlowAssumptionsTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowAssumptionsBaseCaseWVLive]: {
    viewComponent: CashFlowAssumptionsTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowAssumptionsStressCase]: {
    viewComponent: CashFlowAssumptionsTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowAssumptionsStressCaseWVLive]: {
    viewComponent: CashFlowAssumptionsTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowDebtAndEquityProjectionAdditionalCase]: {
    viewComponent: CashFlowDebtAndEquityProjectionTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowDebtAndEquityProjectionAdditionalCaseWVLive]: {
    viewComponent: CashFlowDebtAndEquityProjectionTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowDebtAndEquityProjectionBaseCase]: {
    viewComponent: CashFlowDebtAndEquityProjectionTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowDebtAndEquityProjectionBaseCaseWVLive]: {
    viewComponent: CashFlowDebtAndEquityProjectionTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowDebtAndEquityProjectionStressCase]: {
    viewComponent: CashFlowDebtAndEquityProjectionTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowDebtAndEquityProjectionStressCaseWVLive]: {
    viewComponent: CashFlowDebtAndEquityProjectionTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowFinancialRatiosAdditionalCase]: {
    viewComponent: CashFlowFinancialRatiosTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowFinancialRatiosAdditionalCaseWVLive]: {
    viewComponent: CashFlowFinancialRatiosTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowFinancialRatiosBaseCase]: {
    viewComponent: CashFlowFinancialRatiosTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowFinancialRatiosBaseCaseWVLive]: {
    viewComponent: CashFlowFinancialRatiosTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowFinancialRatiosStressCase]: {
    viewComponent: CashFlowFinancialRatiosTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowFinancialRatiosStressCaseWVLive]: {
    viewComponent: CashFlowFinancialRatiosTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowVacancyProjection]: {
    viewComponent: CashFlowVacancyProjectionTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.cashFlowVacancyProjectionWVLive]: {
    viewComponent: CashFlowVacancyProjectionTile,
    liveHookLoader: useCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows`,
      },
    ],
  },
  [availableAutomaticTiles.checkSheetBasicCreditAuthorityLevel]: {
    viewComponent: CheckSheetBasicCreditAuthorityLevelTile,
    liveHookLoader: useCheckSheetBasicCreditAuthorityLevel,
    sources: [
      {
        nameTranslationKey:
          'tiles.check-sheet.basic-credit-authority-level.source.authority-level-calculator',
        path: `/business-partners/\${businessPartnerId}/authority-level-calculator`,
      },
      {
        nameTranslationKey:
          'tiles.check-sheet.basic-credit-authority-level.source.deal-general-information',
        path: `/deals/\${dealDisplayId}/general-information\${sourcePathSuffix}`,
      },
      {
        nameTranslationKey:
          'tiles.check-sheet.basic-credit-authority-level.source.bp-general-information',
        path: `/business-partners/\${businessPartnerId}/general-information`,
      },
      {
        nameTranslationKey: 'tiles.check-sheet.basic-credit-authority-level.source.exposure',
        path: `/business-partners/\${businessPartnerId}/exposure`,
      },
    ],
  },
  [availableAutomaticTiles.checkSheetBasicCreditAuthorityLevelWVLive]: {
    viewComponent: CheckSheetBasicCreditAuthorityLevelTile,
    liveHookLoader: useCheckSheetBasicCreditAuthorityLevel,
    sources: [
      {
        nameTranslationKey:
          'tiles.check-sheet.basic-credit-authority-level.source.authority-level-calculator',
        path: `/business-partners/\${businessPartnerId}/authority-level-calculator`,
      },
      {
        nameTranslationKey:
          'tiles.check-sheet.basic-credit-authority-level.source.deal-general-information',
        path: `/deals/\${dealDisplayId}/general-information`,
      },
      {
        nameTranslationKey:
          'tiles.check-sheet.basic-credit-authority-level.source.bp-general-information',
        path: `/business-partners/\${businessPartnerId}/general-information`,
      },
      {
        nameTranslationKey: 'tiles.check-sheet.basic-credit-authority-level.source.exposure',
        path: `/business-partners/\${businessPartnerId}/exposure`,
      },
    ],
  },
  [availableAutomaticTiles.checkSheetDecisionOverview]: {
    viewComponent: CheckSheetDecisionOverview,
    liveHookLoader: useCheckSheetDecisionOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.check-sheet.decision-overview.source',
        path: `/business-events-and-tasks/business-events/\${eventId}/decision-process`,
      },
    ],
  },
  [availableAutomaticTiles.checkSheetRegulatoryRequirementsStatistics]: {
    viewComponent: CheckSheetRegulatoryRequirementsStatisticsTile,
    liveHookLoader: useCheckSheetRegulatoryRequirementsStatistics,
    sources: [
      {
        nameTranslationKey:
          'tiles.check-sheet.regulatory-requirements-statistics.source.general-information',
        path: `/business-partners/\${businessPartnerId}/general-information`,
      },
      {
        nameTranslationKey: 'tiles.check-sheet.regulatory-requirements-statistics.source.financing',
        path: `/deals/\${dealDisplayId}/financing\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.checkSheetRegulatoryRequirementsStatisticsWVLive]: {
    viewComponent: CheckSheetRegulatoryRequirementsStatisticsTile,
    liveHookLoader: useCheckSheetRegulatoryRequirementsStatistics,
    sources: [
      {
        nameTranslationKey:
          'tiles.check-sheet.regulatory-requirements-statistics.source.general-information',
        path: `/business-partners/\${businessPartnerId}/general-information`,
      },
      {
        nameTranslationKey: 'tiles.check-sheet.regulatory-requirements-statistics.source.financing',
        path: `/deals/\${dealDisplayId}/financing`,
      },
    ],
  },
  [availableAutomaticTiles.collateralAgreements]: {
    viewComponent: CollateralAgreementsTile,
    liveHookLoader: useCollateralAgreements,
    sources: [
      {
        nameTranslationKey: 'tiles.collaterals.collateral-agreements.source',
        path: `/deals/\${dealDisplayId}/collaterals\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.collateralAgreementsWVLive]: {
    viewComponent: CollateralAgreementsTile,
    liveHookLoader: useCollateralAgreements,
    sources: [
      {
        nameTranslationKey: 'tiles.collaterals.collateral-agreements.source',
        path: `/deals/\${dealDisplayId}/collaterals`,
      },
    ],
  },
  [availableAutomaticTiles.constructionAssessment]: {
    viewComponent: LatestCustomAssessmentTile,
    liveHookLoader: useConstructionAssessment,
    sources: [
      {
        nameTranslationKey: 'tiles.construction-assessment',
        path: '/deals/${dealDisplayId}/financed-objects${sourcePathSuffix}#appreciation',
      },
    ],
  },
  [availableAutomaticTiles.constructionAssessmentWVLive]: {
    viewComponent: LatestCustomAssessmentTile,
    liveHookLoader: useConstructionAssessment,
    sources: [
      {
        nameTranslationKey: 'tiles.construction-assessment',
        path: '/deals/${dealDisplayId}/financed-objects#appreciation',
      },
    ],
  },
  [availableAutomaticTiles.covenantCheckCashFlowScenario]: {
    viewComponent: CashFlowScenarioOfCheckTile,
    liveHookLoader: useCashFlowScenarioOfCheck,
    sources: [
      {
        nameTranslationKey: 'tiles.covenants-of-check.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.covenantCheckComment]: {
    viewComponent: CovenantCheckCommentTile,
    liveHookLoader: useCovenantCheckComment,
    sources: [
      {
        nameTranslationKey: 'tiles.covenants-of-check.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.covenantsOfCheckOverview]: {
    viewComponent: CovenantsOfCheckTile,
    liveHookLoader: useCovenantsOfCheck,
    sources: [
      {
        nameTranslationKey: 'tiles.covenants-of-check.source',
        path: `\${path}`,
      },
    ],
  },

  [availableAutomaticTiles.covenantsRatiosCovenantsOverview]: {
    viewComponent: CovenantsRatiosCovenantsOverviewTile,
    liveHookLoader: useCovenantsRatiosCovenantsOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.covenants-ratios-covenants-overview.source',
        path: `/deals/\${dealId}/covenants\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.covenantsRatiosCovenantsOverviewWVLive]: {
    viewComponent: CovenantsRatiosCovenantsOverviewTile,
    liveHookLoader: useCovenantsRatiosCovenantsOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.covenants-ratios-covenants-overview.source',
        path: `/deals/\${dealId}/covenants`,
      },
    ],
  },
  [availableAutomaticTiles.covenantsRatiosFinancialRatios]: {
    viewComponent: CovenantsRatiosFinancialRatiosTile,
    liveHookLoader: useCovenantsRatiosFinancialRatios,
    sources: [
      {
        nameTranslationKey: 'tiles.covenants-ratios-financial-ratios.source',
        path: `/deals/\${dealDisplayId}/cashflows\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.covenantsRatiosFinancialRatiosWVLive]: {
    viewComponent: CovenantsRatiosFinancialRatiosTile,
    liveHookLoader: useCovenantsRatiosFinancialRatios,
    sources: [
      {
        nameTranslationKey: 'tiles.covenants-ratios-financial-ratios.source',
        path: `/deals/\${dealDisplayId}/cashflows`,
      },
    ],
  },
  [availableAutomaticTiles.coverPoolAdjustment]: {
    viewComponent: CoverPoolAdjustmentsTile,
    liveHookLoader: useCoverPoolAdjustments,
    sources: [
      {
        nameTranslationKey: 'tiles.financed-objects',
        path: '/deals/${dealDisplayId}/financed-objects',
      },
    ],
  },
  [availableAutomaticTiles.dealSources]: {
    viewComponent: DealSourcesTile,
    liveHookLoader: useDealSourcesAndUses,
    sources: [
      {
        nameTranslationKey: 'tiles.deal-uses-and-sources.sources.financing.source',
        path: '/deals/${dealDisplayId}/financing${financingPathSuffix}',
      },
      {
        nameTranslationKey: 'tiles.deal-uses-and-sources.sources.syndication.source',
        path: '/deals/${dealDisplayId}/syndication${financingPathSuffix}',
      },
    ],
  },
  [availableAutomaticTiles.dealSourcesWVLive]: {
    viewComponent: DealSourcesTile,
    liveHookLoader: useDealSourcesAndUses,
    sources: [
      {
        nameTranslationKey: 'tiles.deal-uses-and-sources.sources.financing.source',
        path: '/deals/${dealDisplayId}/financing',
      },
      {
        nameTranslationKey: 'tiles.deal-uses-and-sources.sources.syndication.source',
        path: '/deals/${dealDisplayId}/syndication',
      },
    ],
  },
  [availableAutomaticTiles.dealUses]: {
    viewComponent: DealUsesTile,
    liveHookLoader: useDealSourcesAndUses,
    sources: [
      {
        nameTranslationKey: 'tiles.deal-uses-and-sources.uses.source',
        path: '/deals/${dealDisplayId}/general-information${generalInformationPathSuffix}',
      },
    ],
  },
  [availableAutomaticTiles.dealUsesWVLive]: {
    viewComponent: DealUsesTile,
    liveHookLoader: useDealSourcesAndUses,
    sources: [
      {
        nameTranslationKey: 'tiles.deal-uses-and-sources.uses.source',
        path: '/deals/${dealDisplayId}/general-information',
      },
    ],
  },
  [availableAutomaticTiles.drawdownBorrowerInformation]: {
    viewComponent: DrawdownBorrowerInformationTile,
    liveHookLoader: useDrawdownBorrowerInformation,
    sources: [
      {
        nameTranslationKey: 'tiles.drawdown-borrower-information.source',
        path: '/deals/${dealDisplayId}/general-information',
      },
    ],
  },
  [availableAutomaticTiles.drawdownDealStructure]: {
    viewComponent: DrawdownDealStructureTile,
    liveHookLoader: useDrawdownDealStructure,
    sources: [
      {
        nameTranslationKey: 'tiles.drawdown-deal-structure.source',
        path: '/deals/${dealDisplayId}/syndication',
      },
    ],
  },
  [availableAutomaticTiles.esgStatement]: {
    viewComponent: LatestCustomAssessmentTile,
    liveHookLoader: useEsgStatement,
    sources: [
      {
        nameTranslationKey: 'tiles.esg-statement',
        path: '/deals/${dealDisplayId}/financed-objects${sourcePathSuffix}#appreciation',
      },
    ],
  },
  [availableAutomaticTiles.esgStatementWVLive]: {
    viewComponent: LatestCustomAssessmentTile,
    liveHookLoader: useEsgStatement,
    sources: [
      {
        nameTranslationKey: 'tiles.esg-statement',
        path: '/deals/${dealDisplayId}/financed-objects#appreciation',
      },
    ],
  },
  [availableAutomaticTiles.eventHistory]: {
    viewComponent: EventHistoryTile,
    liveHookLoader: useEventHistory,
    sources: [
      {
        nameTranslationKey: 'tiles.event-history.source',
        path: `/\${resourcePathPart}/\${displayId}/\${sourcePathSuffix}#manager-tab`,
      },
    ],
  },
  [availableAutomaticTiles.eventHistoryWVLive]: {
    viewComponent: EventHistoryTile,
    liveHookLoader: useEventHistory,
    sources: [
      {
        nameTranslationKey: 'tiles.event-history.source',
        path: `/\${resourcePathPart}/\${displayId}/#manager-tab`,
      },
    ],
  },
  [availableAutomaticTiles.externalConditions]: {
    viewComponent: ExternalConditionsTile,
    liveHookLoader: useExternalConditions,
    sources: [
      {
        nameTranslationKey: 'tiles.external-conditions.source',
        path: `/\${resourcePathPart}/\${displayId}/conditions?con_businessType=external`,
      },
    ],
    version: tileVersion.V2,
  },

  [availableAutomaticTiles.factSheetKeyFacts]: {
    viewComponent: FactSheetKeyFactsTile,
    liveHookLoader: useFactSheetKeyFacts,
    sources: [
      {
        nameTranslationKey: 'subsection.fact-sheet.source',
        path: `\${path}`,
      },
    ],
    version: tileVersion.V2,
  },
  [availableAutomaticTiles.factSheetKeyFactsWVLive]: {
    viewComponent: FactSheetKeyFactsTile,
    liveHookLoader: useFactSheetKeyFacts,
    sources: [
      {
        nameTranslationKey: 'subsection.fact-sheet.source',
        path: `\${path}`,
      },
    ],
    version: tileVersion.V2,
  },
  [availableAutomaticTiles.factSheetTegovaRating]: {
    viewComponent: FactSheetTegovaRatingTile,
    liveHookLoader: useFactSheetTegovaRatings,
    sources: [
      {
        nameTranslationKey: 'subsection.fact-sheet.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.factSheetTegovaRatingWVLive]: {
    viewComponent: FactSheetTegovaRatingTile,
    liveHookLoader: useFactSheetTegovaRatings,
    sources: [
      {
        nameTranslationKey: 'subsection.fact-sheet.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.factSheetValuation]: {
    viewComponent: FactSheetValuationTile,
    liveHookLoader: useFactSheetValuation,
    sources: [
      {
        nameTranslationKey: 'subsection.fact-sheet.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.factSheetValuationWVLive]: {
    viewComponent: FactSheetValuationTile,
    liveHookLoader: useFactSheetValuation,
    sources: [
      {
        nameTranslationKey: 'subsection.fact-sheet.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.financedAssetArea]: {
    viewComponent: PortfolioFinancedAssetsAreaTile,
    liveHookLoader: usePortfolioFinancedAssetsArea,
    sources: [
      {
        nameTranslationKey: 'tiles.portfolio-financed-assets-area.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.financedAssetAreaWVLive]: {
    viewComponent: PortfolioFinancedAssetsAreaTile,
    liveHookLoader: usePortfolioFinancedAssetsArea,
    sources: [
      {
        nameTranslationKey: 'tiles.portfolio-financed-assets-area.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.financedAssetInformation]: {
    viewComponent: PortfolioFinancedAssetsInformationTile,
    liveHookLoader: usePortfolioFinancedAssetsInformation,
    liveImagesLoader: usePortfolioFinancedAssetsInformationImages,
    sources: [
      {
        nameTranslationKey: 'tiles.portfolio-financed-assets-information.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.financedAssetInformationWVLive]: {
    viewComponent: PortfolioFinancedAssetsInformationTile,
    liveHookLoader: usePortfolioFinancedAssetsInformation,
    liveImagesLoader: usePortfolioFinancedAssetsInformationImages,
    sources: [
      {
        nameTranslationKey: 'tiles.portfolio-financed-assets-information.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.financedAssetView]: {
    viewComponent: PortfolioFinancedAssetsViewTile,
    liveHookLoader: usePortfolioFinancedAssetsInformation,
    liveImagesLoader: usePortfolioFinancedAssetsInformationImages,
    sources: [
      {
        nameTranslationKey: 'tiles.portfolio-financed-assets-information.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.financedAssetViewWVLive]: {
    viewComponent: PortfolioFinancedAssetsViewTile,
    liveHookLoader: usePortfolioFinancedAssetsInformation,
    liveImagesLoader: usePortfolioFinancedAssetsInformationImages,
    sources: [
      {
        nameTranslationKey: 'tiles.portfolio-financed-assets-information.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.financingTermsCommitmentFee]: {
    viewComponent: FinancingTermsCommitmentFeeTile,
    liveHookLoader: useFinancingTerms,
    sources: [
      {
        nameTranslationKey: 'tiles.financing-terms.commitment-fee',
        path: `/deals/\${dealDisplayId}/financing\${financingPathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.financingTermsCommitmentFeeWVLive]: {
    viewComponent: FinancingTermsCommitmentFeeTile,
    liveHookLoader: useFinancingTerms,
    sources: [
      {
        nameTranslationKey: 'tiles.financing-terms.commitment-fee',
        path: `/deals/\${dealDisplayId}/financing`,
      },
    ],
  },
  [availableAutomaticTiles.financingTermsGeneralInfo]: {
    viewComponent: FinancingTermsGeneralInformationTile,
    liveHookLoader: useFinancingTerms,
    sources: [
      {
        nameTranslationKey: 'tiles.deal-uses-and-sources.uses.source',
        path: '/deals/${dealDisplayId}/general-information${generalInformationPathSuffix}',
      },
      {
        nameTranslationKey: 'tiles.financing-terms.general-information',
        path: `/deals/\${dealDisplayId}/financing\${financingPathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.financingTermsGeneralInfoWVLive]: {
    viewComponent: FinancingTermsGeneralInformationTile,
    liveHookLoader: useFinancingTerms,
    sources: [
      {
        nameTranslationKey: 'tiles.deal-uses-and-sources.uses.source',
        path: '/deals/${dealDisplayId}/general-information',
      },
      {
        nameTranslationKey: 'tiles.financing-terms.general-information',
        path: `/deals/\${dealDisplayId}/financing`,
      },
    ],
  },
  [availableAutomaticTiles.financingTermsPrepaymentFee]: {
    viewComponent: FinancingTermsPrepaymentFeeTile,
    liveHookLoader: useFinancingTerms,
    sources: [
      {
        nameTranslationKey: 'tiles.financing-terms.prepayment-fee',
        path: `/deals/\${dealDisplayId}/financing\${financingPathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.financingTermsPrepaymentFeeWVLive]: {
    viewComponent: FinancingTermsPrepaymentFeeTile,
    liveHookLoader: useFinancingTerms,
    sources: [
      {
        nameTranslationKey: 'tiles.financing-terms.prepayment-fee',
        path: `/deals/\${dealDisplayId}/financing`,
      },
    ],
  },
  [availableAutomaticTiles.financingTermsPricing]: {
    viewComponent: FinancingTermsPricingTile,
    liveHookLoader: useFinancingTerms,
    sources: [
      {
        nameTranslationKey: 'tiles.deal-uses-and-sources.uses.source',
        path: '/deals/${dealDisplayId}/general-information${generalInformationPathSuffix}',
      },
      {
        nameTranslationKey: 'tiles.financing-terms.pricing',
        path: `/deals/\${dealDisplayId}/financing\${financingPathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.financingTermsPricingWVLive]: {
    viewComponent: FinancingTermsPricingTile,
    liveHookLoader: useFinancingTerms,
    sources: [
      {
        nameTranslationKey: 'tiles.deal-uses-and-sources.uses.source',
        path: '/deals/${dealDisplayId}/general-information',
      },
      {
        nameTranslationKey: 'tiles.financing-terms.pricing',
        path: `/deals/\${dealDisplayId}/financing`,
      },
    ],
  },
  [availableAutomaticTiles.financingTermsTrancheDetails]: {
    viewComponent: FinancingTermsTrancheDetailsTile,
    liveHookLoader: useFinancingTerms,
    sources: [
      {
        nameTranslationKey: 'tiles.financing-terms.tranche-details',
        path: `/deals/\${dealDisplayId}/financing\${financingPathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.financingTermsTrancheDetailsWVLive]: {
    viewComponent: FinancingTermsTrancheDetailsTile,
    liveHookLoader: useFinancingTerms,
    sources: [
      {
        nameTranslationKey: 'tiles.financing-terms.tranche-details',
        path: `/deals/\${dealDisplayId}/financing`,
      },
    ],
  },
  [availableAutomaticTiles.forbearanceMeasures]: {
    viewComponent: ForbearanceMeasuresTable,
    liveHookLoader: useForbearanceMeasuresDecisionPaper,
    sources: [
      {
        nameTranslationKey: 'tiles.forbearance-measures',
        path: '/business-partners/${businessPartnerId}/risk-monitoring#forbearance-tab',
      },
    ],
  },
  [availableAutomaticTiles.forbearanceStatus]: {
    viewComponent: ForbearanceStatusTable,
    liveHookLoader: useForbearanceStatusTableData,
    sources: [
      {
        nameTranslationKey: 'tiles.forbearance-status',
        path: '/business-partners/${businessPartnerId}/risk-monitoring#forbearance-tab',
      },
    ],
  },
  [availableAutomaticTiles.furtherDetailsGranularityCity]: {
    viewComponent: FurtherDetailsGranularityCityOrCountryTile,
    liveHookLoader: useFurtherDetailsPieChartCardData,
    sources: [
      {
        nameTranslationKey: 'tiles.further-details.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.furtherDetailsGranularityCityWVLive]: {
    viewComponent: FurtherDetailsGranularityCityOrCountryTile,
    liveHookLoader: useFurtherDetailsPieChartCardData,
    sources: [
      {
        nameTranslationKey: 'tiles.further-details.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.furtherDetailsGranularityIndustry]: {
    viewComponent: FurtherDetailsGranularityTenantIndustryTile,
    liveHookLoader: useFurtherDetailsPieChartCardData,
    sources: [
      {
        nameTranslationKey: 'tiles.further-details.source',
        path: `\${path}`,
      },
    ],
    version: tileVersion.V2,
  },
  [availableAutomaticTiles.furtherDetailsGranularityIndustryWVLive]: {
    viewComponent: FurtherDetailsGranularityTenantIndustryTile,
    liveHookLoader: useFurtherDetailsPieChartCardData,
    sources: [
      {
        nameTranslationKey: 'tiles.further-details.source',
        path: `\${path}`,
      },
    ],
    version: tileVersion.V2,
  },
  [availableAutomaticTiles.furtherDetailsGranularityPropertyType]: {
    viewComponent: FurtherDetailsGranularityPropertyTypeTile,
    liveHookLoader: useFurtherDetailsPieChartCardData,
    sources: [
      {
        nameTranslationKey: 'tiles.further-details.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.furtherDetailsGranularityPropertyTypeWVLive]: {
    viewComponent: FurtherDetailsGranularityPropertyTypeTile,
    liveHookLoader: useFurtherDetailsPieChartCardData,
    sources: [
      {
        nameTranslationKey: 'tiles.further-details.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.furtherDetailsGranularityTegova]: {
    viewComponent: FurtherDetailsGranularityTegovaRatingTile,
    liveHookLoader: useFurtherDetailsPieChartCardData,
    sources: [
      {
        nameTranslationKey: 'tiles.further-details.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.furtherDetailsGranularityTegovaWVLive]: {
    viewComponent: FurtherDetailsGranularityTegovaRatingTile,
    liveHookLoader: useFurtherDetailsPieChartCardData,
    sources: [
      {
        nameTranslationKey: 'tiles.further-details.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.furtherDetailsGranularityUsageType]: {
    viewComponent: FurtherDetailsGranularityUsageTypeTile,
    liveHookLoader: useFurtherDetailsPieChartCardData,
    sources: [
      {
        nameTranslationKey: 'tiles.further-details.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.furtherDetailsGranularityUsageTypeWVLive]: {
    viewComponent: FurtherDetailsGranularityUsageTypeTile,
    liveHookLoader: useFurtherDetailsPieChartCardData,
    sources: [
      {
        nameTranslationKey: 'tiles.further-details.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.gamStatement]: {
    viewComponent: GamStatementTile,
    liveHookLoader: useGamStatement,
    sources: [
      {
        nameTranslationKey: 'tiles.gam-statement.source',
        path: `/business-partners/\${businessPartnerId}/assessments`,
      },
    ],
  },
  [availableAutomaticTiles.gamStatementWVLive]: {
    viewComponent: GamStatementTile,
    liveHookLoader: useGamStatement,
    sources: [
      {
        nameTranslationKey: 'tiles.gam-statement.source',
        path: `/business-partners/\${businessPartnerId}/assessments`,
      },
    ],
  },
  [availableAutomaticTiles.generalInformation]: {
    viewComponent: DecisionPaperGeneralInformationTile,
    liveHookLoader: useDecisionPaperGeneralInformation,
    sources: [
      {
        nameTranslationKey: 'tiles.deal-uses-and-sources.sources.financing.source',
        path: '/deals/${dealDisplayId}',
      },
      {
        nameTranslationKey: 'tiles.general-information.sources.general-information.source',
        path: '/deals/${dealDisplayId}#manager-tab',
      },
      {
        nameTranslationKey: 'tiles.deal-uses-and-sources.sources.syndication.source',
        path: '/business-partners/${businessPartnerId}/authority-level-calculator',
      },
    ],
  },
  [availableAutomaticTiles.generalInformationWVLive]: {
    viewComponent: DecisionPaperGeneralInformationTile,
    liveHookLoader: useDecisionPaperGeneralInformation,
    sources: [
      {
        nameTranslationKey: 'tiles.deal-uses-and-sources.sources.financing.source',
        path: '/deals/${dealDisplayId}',
      },
      {
        nameTranslationKey: 'tiles.general-information.sources.general-information.source',
        path: '/deals/${dealDisplayId}#manager-tab',
      },
      {
        nameTranslationKey: 'tiles.deal-uses-and-sources.sources.syndication.source',
        path: '/business-partners/${businessPartnerId}/authority-level-calculator',
      },
    ],
  },
  [availableAutomaticTiles.internalConditions]: {
    viewComponent: InternalConditionsTile,
    liveHookLoader: useInternalConditions,
    sources: [
      {
        nameTranslationKey: 'tiles.internal-conditions.source',
        path: `/\${resourcePathPart}/\${displayId}/conditions?con_businessType=internal\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.issuesAndStrategy]: {
    viewComponent: RiskMonitoringDescriptionTile,
    liveHookLoader: useRiskIndicatorsDescription,
    sources: [
      {
        nameTranslationKey: 'tiles.risk-monitoring',
        path: '/business-partners/${businessPartnerId}/risk-monitoring/${riskIndicatorGroupId}',
      },
    ],
  },
  [availableAutomaticTiles.kamGamStatementGcc]: {
    viewComponent: GccInvolvedPartiesKamGamStatementGccTile,
    liveHookLoader: useGccInvolvedPartiesKamGamStatementGcc,
    sources: [
      {
        nameTranslationKey: 'tiles.kam-gam-statement-gcc.source',
        path: `/business-partners/\${businessPartnerId}/relationships`,
      },
    ],
  },
  [availableAutomaticTiles.kamGamStatementGccWVLive]: {
    viewComponent: GccInvolvedPartiesKamGamStatementGccTile,
    liveHookLoader: useGccInvolvedPartiesKamGamStatementGcc,
    sources: [
      {
        nameTranslationKey: 'tiles.kam-gam-statement-gcc.source',
        path: `/business-partners/\${businessPartnerId}/relationships`,
      },
    ],
  },
  [availableAutomaticTiles.kamStatement]: {
    viewComponent: KamStatementTile,
    liveHookLoader: useKamStatement,
    sources: [
      {
        nameTranslationKey: 'tiles.kam-statement.source',
        path: `/business-partners/\${businessPartnerId}/assessments`,
      },
    ],
  },
  [availableAutomaticTiles.kamStatementWVLive]: {
    viewComponent: KamStatementTile,
    liveHookLoader: useKamStatement,
    sources: [
      {
        nameTranslationKey: 'tiles.kam-statement.source',
        path: `/business-partners/\${businessPartnerId}/assessments`,
      },
    ],
  },
  [availableAutomaticTiles.loanMarketSyndicationIntention]: {
    viewComponent: LoanMarketStatementSyndicationIntentionTile,
    liveHookLoader: useLoanMarketStatement,
    sources: [
      {
        nameTranslationKey: 'tiles.loan-market-statement.syndication.source',
        path: `/deals/\${dealDisplayId}/syndication\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.loanMarketSyndicationIntentionWVLive]: {
    viewComponent: LoanMarketStatementSyndicationIntentionTile,
    liveHookLoader: useLoanMarketStatement,
    sources: [
      {
        nameTranslationKey: 'tiles.loan-market-statement.syndication.source',
        path: `/deals/\${dealDisplayId}/syndication`,
      },
    ],
  },
  [availableAutomaticTiles.loanMarketSyndicationStatement]: {
    viewComponent: LoanMarketStatementSyndicationStatementTile,
    liveHookLoader: useLoanMarketStatement,
    sources: [
      {
        nameTranslationKey: 'tiles.loan-market-statement.syndication.source',
        path: `/deals/\${dealDisplayId}/syndication\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.loanMarketSyndicationStatementWVLive]: {
    viewComponent: LoanMarketStatementSyndicationStatementTile,
    liveHookLoader: useLoanMarketStatement,
    sources: [
      {
        nameTranslationKey: 'tiles.loan-market-statement.syndication.source',
        path: `/deals/\${dealDisplayId}/syndication`,
      },
    ],
  },
  [availableAutomaticTiles.milestones]: {
    viewComponent: MilestonesTile,
    liveHookLoader: useMilestones,
    sources: [
      {
        nameTranslationKey: 'subsection.specifics-development.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.milestonesWVLive]: {
    viewComponent: MilestonesTile,
    liveHookLoader: useMilestones,
    sources: [
      {
        nameTranslationKey: 'subsection.specifics-development.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.monitoredRiskIndicatorAuthorityLevelCalculator]: {
    viewComponent: GccInvolvedPartiesAuthorityLevelCalculatorTile,
    liveHookLoader: useBusinessPartnerGccAuthorityLevelCalculator,
    sources: [
      {
        nameTranslationKey: 'tiles.shared.authority-level-calculator',
        path: `/business-partners/\${businessPartnerId}/authority-level-calculator`,
      },
    ],
  },
  [availableAutomaticTiles.monitoredRiskIndicatorCheckOverviewPdRating]: {
    viewComponent: BusinessPartnerRatingsOverviewTile,
    liveHookLoader: useMonitoredRiskIndicatorCheckOverviewPdRating,
    sources: [
      {
        nameTranslationKey: 'tiles.risk-monitoring.pd-rating.source',
        path: `/business-partners/\${businessPartnerId}/ratings`,
      },
    ],
  },
  [availableAutomaticTiles.monitoredRiskIndicatorDealSpecificsCollateralAgreements]: {
    viewComponent: RiskMonitoringCollateralAgreementsTileSelectionWrapper,
    liveHookLoader: useRiskIndicatorDealCollateralAgreements,
    sources: [
      {
        nameTranslationKey: 'tiles.collaterals.collateral-agreements.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.monitoredRiskIndicatorDealSpecificsDealSelection]: {
    viewComponent: RiskMonitoringDealSelectionTile,
    liveHookLoader: useRiskIndicatorDealSelection,
    sources: [
      {
        nameTranslationKey: 'tiles.risk-monitoring.sources.overview',
        path: '/deals/${dealDisplayId}',
      },
    ],
  },
  [availableAutomaticTiles.monitoredRiskIndicatorDealSpecificsStandardCollateralPackage]: {
    viewComponent: RiskMonitoringStandardCollateralPackageTileSelectionWrapper,
    liveHookLoader: useRiskIndicatorDealStandardCollateralPackage,
    sources: [
      {
        nameTranslationKey: 'tiles.collaterals.standard-collateral-package.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.monitoredRiskIndicatorDealSpecificsValuation]: {
    viewComponent: RiskMonitoringDealSpecificsValuationSelectionWrapper,
    liveHookLoader: useRiskIndicatorDealSpecificsValuation,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.properties',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.monitoredRiskIndicatorDealSpecificsValuationHistory]: {
    viewComponent: RiskMonitoringDealSpecificsValuationHistorySelectionWrapper,
    liveHookLoader: useRiskIndicatorDealSpecificsValuationHistory,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.properties',
        path: '/properties/${propertyId}/valuation',
      },
    ],
    version: tileVersion.V2,
  },
  [availableAutomaticTiles.monitoredRiskIndicatorFinancialOverviewCurrentArrears]: {
    viewComponent: CurrentArrearsTableTile,
    liveHookLoader: useCurrentArrearsTableData,
    sources: [
      {
        nameTranslationKey: 'tiles.current-arrears',
        path: '/business-partners/${businessPartnerId}/arrears',
      },
    ],
    version: tileVersion.V3,
  },
  [availableAutomaticTiles.monitoredRiskIndicatorRegulatoryChecksCurrentCriticalFacilityStatus]: {
    viewComponent: CriticalFacilityTile,
    liveHookLoader: useCriticalFacilityData,
    sources: [
      {
        nameTranslationKey: 'tiles.risk-monitoring',
        path: '/business-partners/${businessPartnerId}/risk-monitoring',
      },
    ],
  },
  [availableAutomaticTiles.monitoredRiskIndicatorRiskMonitoringBusinessEvents]: {
    viewComponent: RiskIndicatorsGroupBusinessEventsTile,
    liveHookLoader: useRiskMonitoringBusinessEvents,
    sources: [
      {
        nameTranslationKey: 'tiles.risk-monitoring',
        path: '/business-partners/${businessPartnerId}/risk-monitoring/${riskIndicatorGroupId}',
      },
    ],
  },
  [availableAutomaticTiles.monitoredRiskIndicatorRiskMonitoringCriticality]: {
    viewComponent: RiskIndicatorsGroupCriticalityTile,
    liveHookLoader: useRiskMonitoringCriticality,
    sources: [
      {
        nameTranslationKey: 'tiles.risk-monitoring',
        path: '/business-partners/${businessPartnerId}/risk-monitoring/${riskIndicatorGroupId}',
      },
    ],
  },
  [availableAutomaticTiles.monitoredRiskIndicatorRiskMonitoringGeneralInformation]: {
    viewComponent: RiskIndicatorsGroupGeneralInformationDecisionPaper,
    liveHookLoader: useRiskMonitoringGeneralInformationDecisionPaper,
    sources: [
      {
        nameTranslationKey: 'tiles.risk-monitoring',
        path: '/business-partners/${businessPartnerId}/risk-monitoring/${riskIndicatorGroupId}',
      },
    ],
  },
  [availableAutomaticTiles.fpoTool]: {
    viewComponent: FpoToolTile,
    liveHookLoader: useFpoToolTile,
    sources: [
      {
        nameTranslationKey: 'tiles.business-partner.general-information',
        path: '/business-partners/${businessPartnerId}/general-information',
      },
    ],
  },
  [availableAutomaticTiles.fpoToolWVLive]: {
    viewComponent: FpoToolTile,
    liveHookLoader: useFpoToolTile,
    sources: [
      {
        nameTranslationKey: 'tiles.business-partner.general-information',
        path: '/business-partners/${businessPartnerId}/general-information',
      },
    ],
  },
  [availableAutomaticTiles.monitoredRiskIndicatorRiskMonitoringRiskIndicators]: {
    viewComponent: RiskIndicatorsDetailedDecisionPaper,
    liveHookLoader: useRiskIndicatorsDecisionPaper,
    sources: [
      {
        nameTranslationKey: 'tiles.risk-monitoring',
        path: '/business-partners/${businessPartnerId}/risk-monitoring/${riskIndicatorGroupId}',
      },
    ],
  },
  [availableAutomaticTiles.multipleCashFlowAnalysisCashCashFlowAssumptionsBaseCase]: {
    viewComponent: MultipleCashFlowAssumptionsTileSelectionWrapper,
    liveHookLoader: useAnnualReviewCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows`,
      },
    ],
  },
  [availableAutomaticTiles.multipleCashFlowAnalysisCashCashFlowDebtEquityProjectionBaseCase]: {
    viewComponent: MultipleCashFlowDebtAndEquityProjectionTileSelectionWrapper,
    liveHookLoader: useAnnualReviewCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows`,
      },
    ],
  },
  [availableAutomaticTiles.multipleCashFlowAnalysisCashFlowAssumptionsStressCase]: {
    viewComponent: MultipleCashFlowAssumptionsTileSelectionWrapper,
    liveHookLoader: useAnnualReviewCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows`,
      },
    ],
  },
  [availableAutomaticTiles.multipleCashFlowAnalysisCashFlowDebtEquityProjectionStressCase]: {
    viewComponent: MultipleCashFlowDebtAndEquityProjectionTileSelectionWrapper,
    liveHookLoader: useAnnualReviewCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows`,
      },
    ],
  },
  [availableAutomaticTiles.multipleCashFlowAnalysisCashFlowFinancialRatiosBaseCase]: {
    viewComponent: MultipleCashFlowFinancialRatiosTileSelectionWrapper,
    liveHookLoader: useAnnualReviewCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows`,
      },
    ],
  },
  [availableAutomaticTiles.multipleCashFlowAnalysisCashFlowFinancialRatiosStressCase]: {
    viewComponent: MultipleCashFlowFinancialRatiosTileSelectionWrapper,
    liveHookLoader: useAnnualReviewCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows`,
      },
    ],
  },
  [availableAutomaticTiles.multipleCollateralAgreements]: {
    viewComponent: MultiCollateralAgreementsTileDealSelectionWrapper,
    liveHookLoader: useCollateralAgreementsByDealMetaData,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.collaterals',
        path: '/deals/${dealDisplayId}/collaterals',
      },
    ],
  },
  [availableAutomaticTiles.multipleConstructionAssessment]: {
    viewComponent: ConstructionAssessmentTileDealSelectionWrapper,
    liveHookLoader: useMultipleConstructionAssessment,
    sources: [
      {
        nameTranslationKey: 'tiles.financed-objects',
        path: '/deals/${dealDisplayId}/financed-objects${sourcePathSuffix}#appreciation',
      },
    ],
  },
  [availableAutomaticTiles.multipleConstructionAssessmentWVLive]: {
    viewComponent: ConstructionAssessmentTileDealSelectionWrapper,
    liveHookLoader: useMultipleConstructionAssessment,
    sources: [
      {
        nameTranslationKey: 'tiles.financed-objects',
        path: '/deals/${dealDisplayId}/financed-objects#appreciation',
      },
    ],
  },
  [availableAutomaticTiles.multipleCovenantsRatiosCovenantChecksMonitoring]: {
    viewComponent: MultipleCovenantsRatiosCovenantChecksMonitoringTileDealSelectionWrapper,
    liveHookLoader: useAnnualReviewMultipleCovenantChecks,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.covenants',
        path: '/deals/${dealDisplayId}/covenants#monitoring',
      },
    ],
  },
  [availableAutomaticTiles.multipleCovenantsRatiosCovenantChecksResults]: {
    viewComponent: MultipleCovenantsRatiosCovenantChecksResultsTileDealSelectionWrapper,
    liveHookLoader: useAnnualReviewMultipleCovenantChecksResults,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.covenants',
        path: '/deals/${dealDisplayId}/covenants#results',
      },
    ],
  },
  [availableAutomaticTiles.multipleEsgStatement]: {
    viewComponent: EsgStatementTileDealSelectionWrapper,
    liveHookLoader: useMultipleEsgStatement,
    sources: [
      {
        nameTranslationKey: 'tiles.financed-objects',
        path: '/deals/${dealDisplayId}/financed-objects#appreciation',
      },
    ],
  },
  [availableAutomaticTiles.multipleExistingBusinessSyndicationStructure]: {
    viewComponent: MultiExistingBusinessSyndicationStructureTile,
    liveHookLoader: useExistingBusinessSyndicationStructure,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.syndication',
        path: '/deals/${dealDisplayId}/syndication?dataSource=existingBusiness',
      },
    ],
  },
  [availableAutomaticTiles.multipleFinancedAssetArea]: {
    viewComponent: MultiplePortfolioFinancedAssetsAreaTileDealSelectionWrapper,
    liveHookLoader: useMultiplePortfolioFinancedAssetsArea,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.properties',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multipleFinancedAssetInformation]: {
    viewComponent: MultiplePortfolioFinancedAssetsInformationTileSelectionWrapper,
    liveHookLoader: useMultiplePortfolioFinancedAssetsInformation,
    liveImagesLoader: useMultiplePortfolioFinancedAssetsInformationImages,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.overview',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multipleFinancialAnalysis]: {
    viewComponent: FinancialAnalysisTileWrapper,
    liveHookLoader: useFinancialAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.assessments',
        path: '/business-partners/${businessPartnerDisplayId}/assessments',
      },
    ],
  },

  [availableAutomaticTiles.multipleFinancingTermsTrancheDetails]: {
    viewComponent: AnnualReviewFinancingTermsTrancheDetailsTile,
    liveHookLoader: useAnnualReviewMultipleFinancingTerms,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.financing',
        path: '/deals/${dealDisplayId}/financing',
      },
    ],
  },
  [availableAutomaticTiles.multipleFurtherDetailsGranularityCity]: {
    viewComponent: MultipleFurtherDetailsGranularityCityOrCountryTileDealSelectionWrapper,
    liveHookLoader: useMultipleAnnualReviewFurtherDetailsPieChartCardData,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.rent-roll',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multipleFurtherDetailsGranularityIndustry]: {
    viewComponent: MultipleFurtherDetailsGranularityTenantIndustryTileDealSelectionWrapper,
    liveHookLoader: useMultipleAnnualReviewFurtherDetailsPieChartCardData,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.rent-roll',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multipleFurtherDetailsGranularityPropertyType]: {
    viewComponent: MultipleFurtherDetailsGranularityPropertyTypeTileDealSelectionWrapper,
    liveHookLoader: useMultipleAnnualReviewFurtherDetailsPieChartCardData,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.rent-roll',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multipleFurtherDetailsGranularityTegova]: {
    viewComponent: MultipleFurtherDetailsGranularityTegovaRatingTileDealSelectionWrapper,
    liveHookLoader: useMultipleAnnualReviewFurtherDetailsPieChartCardData,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.rent-roll',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multipleFurtherDetailsGranularityUsageType]: {
    viewComponent: MultipleFurtherDetailsGranularityUsageTypeTileDealSelectionWrapper,
    liveHookLoader: useMultipleAnnualReviewFurtherDetailsPieChartCardData,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.rent-roll',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multipleKeyFacts]: {
    viewComponent: MultipleKeyFactsTileDealSelectionWrapper,
    liveHookLoader: useAnnualReviewFactSheetKeyFacts,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.properties',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multiplePropertyAssessment]: {
    viewComponent: PropertyAssessmentTileDealSelectionWrapper,
    liveHookLoader: usePropertyAssessments,
    sources: [
      {
        nameTranslationKey: 'tiles.financed-objects',
        path: '/deals/${dealDisplayId}/financed-objects#appreciation',
      },
    ],
  },
  [availableAutomaticTiles.multipleSensitivityAnalysis]: {
    viewComponent: SensitivityAnalysisTileDealSelectionWrapper,
    liveHookLoader: useMultipleSensitivityAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.financed-objects',
        path: '/deals/${dealDisplayId}/financed-objects#appreciation',
      },
    ],
  },
  [availableAutomaticTiles.multipleStandardCollateralPackage]: {
    viewComponent: MultipleCollateralAgreementsTileDealSelectionWrapper,
    liveHookLoader: useMultipleStandardCollateralPackage,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.collaterals',
        path: '/deals/${dealDisplayId}/collaterals',
      },
    ],
  },
  [availableAutomaticTiles.multipleTegovaRating]: {
    viewComponent: MultipleFactSheetTegovaRatingTileDealSelectionWrapper,
    liveHookLoader: useMultipleFactSheetTegovaRatings,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.properties',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multipleTenancyRentRollCurrentRent]: {
    viewComponent: MultiCurrentRentTileDealSelectionWrapper,
    liveHookLoader: useAnnualReviewTenancyRentRollPropertyTilesOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.properties',
        path: '/properties/${propertyId}/rent-roll',
      },
    ],
  },
  [availableAutomaticTiles.multipleTenancyRentRollExpiringLeases]: {
    viewComponent: MultiTenancyOverviewExpiringLeasesTileDealSelectionWrapper,
    liveHookLoader: useAnnualReviewTenancyRentRollExpiringLeases,
    sources: [
      {
        nameTranslationKey: 'tiles.tenancy-overview.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multipleTenancyRentRollOccupancy]: {
    viewComponent: TenancyOverviewOccupancyTileDealSelectionWrapper,
    liveHookLoader: useAnnualReviewTenancyRentRollPropertyTilesOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.properties',
        path: '/properties/${propertyId}/valuation',
      },
    ],
  },
  [availableAutomaticTiles.multipleTenancyRentRollRentalIncomeOverTime]: {
    viewComponent: MultiRentalIncomeOverTimeTileDealSelectionWrapper,
    liveHookLoader: useAnnualReviewTenancyRentRollPropertyTilesOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.properties',
        path: '/properties/${propertyId}/rent-roll',
      },
    ],
  },
  [availableAutomaticTiles.multipleTenancyRentRollTenancyOverview]: {
    viewComponent: MultiTenancyOverviewTileDealSelectionWrapperV1V2,
    liveHookLoader: useAnnualReviewTenancyRentRollTenancyOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.tenancy-overview.source',
        path: `\${path}`,
      },
    ],
    version: tileVersion.V2,
  },
  [availableAutomaticTiles.multipleTenancyRentRollTenantDetails]: {
    viewComponent: MultipleTenantDetailsTileDealSelectionWrapper,
    liveHookLoader: useMultipleAnnualReviewTenantDetails,
    sources: [
      {
        nameTranslationKey: 'tiles.tenant-details.source.properties',
        path: `\${propertiesPath}`,
      },
      {
        nameTranslationKey: 'tiles.tenant-details.source.business-partner',
        path: `/business-partners/\${businessPartnerId}/ratings`,
      },
    ],
  },
  [availableAutomaticTiles.multipleTenancyRentRollVacancyOverTime]: {
    viewComponent: TenancyOverviewVacancyOverTimeTileDealSelectionWrapper,
    liveHookLoader: useAnnualReviewTenancyRentRollPropertyTilesOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.properties',
        path: '/properties/${propertyId}/valuation',
      },
    ],
  },
  [availableAutomaticTiles.multipleTenancyRentRollVacancyProjection]: {
    viewComponent: MultipleCashFlowVacancyProjectionTileSelectionWrapper,
    liveHookLoader: useAnnualReviewCashFlowAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.cash-flow.source',
        path: `/deals/\${dealDisplayId}/cashflows`,
      },
    ],
  },
  [availableAutomaticTiles.multipleTransactionOverviewGeneralInformation]: {
    viewComponent: MultipleGeneralInformationTileSelectionWrapper,
    liveHookLoader: useAnnualReviewGeneralInformation,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.general-information',
        path: '/deals/${dealDisplayId}',
      },
      {
        nameTranslationKey: 'tiles.annual-review.sources.overview',
        path: '/deals/${dealDisplayId}#manager-tab',
      },
      {
        nameTranslationKey: 'tiles.annual-review.sources.authority-level-calculator',
        path: '/business-partners/${businessPartnerId}/authority-level-calculator',
      },
    ],
  },

  [availableAutomaticTiles.multipleValuation]: {
    viewComponent: MultipleFactSheetValuationTileDealSelectionWrapper,
    liveHookLoader: useMultipleFactSheetValuation,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.properties',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.multipleValuationHistory]: {
    viewComponent: MultipleFactSheetValuationHistoryDealSelectionWrapper,
    liveHookLoader: useMultipleAnnualReviewFactSheetValuation,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.properties',
        path: '/properties/${propertyId}/valuation',
      },
    ],
    version: tileVersion.V2,
  },
  [availableAutomaticTiles.multipleValuationResults]: {
    viewComponent: MultipleFactSheetValuationResultsDealSelectionWrapper,
    liveHookLoader: useMultipleAnnualReviewFactSheetValuation,
    sources: [
      {
        nameTranslationKey: 'tiles.annual-review.sources.properties',
        path: '/properties/${propertyId}/valuation',
      },
    ],
    version: tileVersion.V2,
  },
  [availableAutomaticTiles.nonRelevantRiskIndicatorFinancialOverviewAuthorityLevelCalculator]: {
    viewComponent: GccInvolvedPartiesAuthorityLevelCalculatorTile,
    liveHookLoader: useBusinessPartnerGccAuthorityLevelCalculator,
    sources: [
      {
        nameTranslationKey: 'tiles.shared.authority-level-calculator',
        path: `/business-partners/\${businessPartnerId}/authority-level-calculator`,
      },
    ],
  },
  [availableAutomaticTiles.nonRelevantRiskIndicatorRiskMonitoringBusinessEvents]: {
    viewComponent: RiskIndicatorsGroupBusinessEventsTile,
    liveHookLoader: useRiskMonitoringBusinessEvents,
    sources: [
      {
        nameTranslationKey: 'tiles.risk-monitoring',
        path: '/business-partners/${businessPartnerId}/risk-monitoring/${riskIndicatorGroupId}',
      },
    ],
  },
  [availableAutomaticTiles.nonRelevantRiskIndicatorRiskMonitoringCriticality]: {
    viewComponent: RiskIndicatorsGroupCriticalityTile,
    liveHookLoader: useRiskMonitoringCriticality,
    sources: [
      {
        nameTranslationKey: 'tiles.risk-monitoring',
        path: '/business-partners/${businessPartnerId}/risk-monitoring/${riskIndicatorGroupId}',
      },
    ],
  },
  [availableAutomaticTiles.nonRelevantRiskIndicatorRiskMonitoringGeneralInformation]: {
    viewComponent: RiskIndicatorsGroupGeneralInformationDecisionPaper,
    liveHookLoader: useRiskMonitoringGeneralInformationDecisionPaper,
    sources: [
      {
        nameTranslationKey: 'tiles.risk-monitoring',
        path: '/business-partners/${businessPartnerId}/risk-monitoring/${riskIndicatorGroupId}',
      },
    ],
  },

  [availableAutomaticTiles.nonRelevantRiskIndicatorRiskMonitoringIssuesAndStrategy]: {
    viewComponent: RiskMonitoringDescriptionTile,
    liveHookLoader: useRiskIndicatorsDescription,
    sources: [
      {
        nameTranslationKey: 'tiles.risk-monitoring',
        path: '/business-partners/${businessPartnerId}/risk-monitoring/${riskIndicatorGroupId}',
      },
    ],
  },
  [availableAutomaticTiles.nonRelevantRiskIndicatorRiskMonitoringRiskIndicators]: {
    viewComponent: RiskIndicatorsDetailedDecisionPaper,
    liveHookLoader: useRiskIndicatorsDecisionPaper,
    sources: [
      {
        nameTranslationKey: 'tiles.risk-monitoring',
        path: '/business-partners/${businessPartnerId}/risk-monitoring/${riskIndicatorGroupId}',
      },
    ],
  },
  [availableAutomaticTiles.opsMemoCollateralsAgreements]: {
    viewComponent: OpsMemoCollateralAgreementsTile,
    liveHookLoader: useOpsMemoCollateralOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.ops-memo-collaterals.source',
        path: `/deals/\${dealDisplayId}/collaterals\${sourcePathSuffix}`,
      },
    ],
    version: tileVersion.V2,
  },
  [availableAutomaticTiles.opsMemoCollateralsAgreementsWVLive]: {
    viewComponent: OpsMemoCollateralAgreementsTile,
    liveHookLoader: useOpsMemoCollateralOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.ops-memo-collaterals.source',
        path: `/deals/\${dealDisplayId}/collaterals`,
      },
    ],
  },
  [availableAutomaticTiles.opsMemoCollateralsLoanToCagRelations]: {
    viewComponent: OpsMemoCollateralsLoanRelationTile,
    liveHookLoader: useOpsMemoCollateralOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.ops-memo-collaterals.relations.collaterals.source',
        path: `/deals/\${dealDisplayId}/collaterals\${sourcePathSuffix}`,
      },
      {
        nameTranslationKey: 'tiles.ops-memo-collaterals.relations.financing.source',
        path: `/deals/\${dealDisplayId}/financing\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.opsMemoCollateralsLoanToCagRelationsWVLive]: {
    viewComponent: OpsMemoCollateralsLoanRelationTile,
    liveHookLoader: useOpsMemoCollateralOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.ops-memo-collaterals.relations.collaterals.source',
        path: `/deals/\${dealDisplayId}/collaterals`,
      },
      {
        nameTranslationKey: 'tiles.ops-memo-collaterals.relations.financing.source',
        path: `/deals/\${dealDisplayId}/financing`,
      },
    ],
  },
  [availableAutomaticTiles.opsMemoDealDetailsInfo]: {
    viewComponent: DealInformationTile,
    liveHookLoader: useOpsMemoDealInformation,
    sources: [
      {
        nameTranslationKey: 'tiles.ops-memo-deal-details-info.source',
        path: `/deals/\${dealDisplayId}/general-information\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.opsMemoDealDetailsInfoWVLive]: {
    viewComponent: DealInformationTile,
    liveHookLoader: useOpsMemoDealInformation,
    sources: [
      {
        nameTranslationKey: 'tiles.ops-memo-deal-details-info.source',
        path: `/deals/\${dealDisplayId}/general-information`,
      },
    ],
  },
  [availableAutomaticTiles.opsMemoDealTeamInfo]: {
    viewComponent: OpsMemoDealTeamInformationTile,
    liveHookLoader: useOpsMemoDealTeamInformation,
    sources: [
      {
        nameTranslationKey: 'tiles.ops-memo-deal-team-info.source',
        path: `/deals/\${dealDisplayId}/general-information\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.opsMemoDealTeamInfoWVLive]: {
    viewComponent: OpsMemoDealTeamInformationTile,
    liveHookLoader: useOpsMemoDealTeamInformation,
    sources: [
      {
        nameTranslationKey: 'tiles.ops-memo-deal-team-info.source',
        path: `/deals/\${dealDisplayId}/general-information`,
      },
    ],
  },
  [availableAutomaticTiles.opsMemoDealTeamMembers]: {
    viewComponent: OpsMemoDealTeamInternalMembersTile,
    liveHookLoader: useOpsMemoDealTeamInternalMembers,
    sources: [
      {
        nameTranslationKey: 'tiles.ops-memo-deal-team-members.source',
        path: `/deals/\${dealDisplayId}\${sourcePathSuffix}#manager-tab`,
      },
    ],
  },
  [availableAutomaticTiles.opsMemoDealTeamMembersWVLive]: {
    viewComponent: OpsMemoDealTeamInternalMembersTile,
    liveHookLoader: useOpsMemoDealTeamInternalMembers,
    sources: [
      {
        nameTranslationKey: 'tiles.ops-memo-deal-team-members.source',
        path: `/deals/\${dealDisplayId}#manager-tab`,
      },
    ],
  },
  [availableAutomaticTiles.opsMemoProperties]: {
    viewComponent: OpsMemoPropertiesTable,
    liveHookLoader: useOpsMemoPropertiesTable,
    sources: [
      {
        nameTranslationKey: 'tiles.ops-memo-properties.source',
        path: `/deals/\${dealDisplayId}/financed-objects\${sourcePathSuffix}`,
      },
    ],
    version: tileVersion.V2,
  },
  [availableAutomaticTiles.opsMemoPropertiesWVLive]: {
    viewComponent: OpsMemoPropertiesTable,
    liveHookLoader: useOpsMemoPropertiesTable,
    sources: [
      {
        nameTranslationKey: 'tiles.ops-memo-properties.source',
        path: `/deals/\${dealDisplayId}/financed-objects`,
      },
    ],
    version: tileVersion.V2,
  },
  [availableAutomaticTiles.opsMemoSyndicationPostSale]: {
    viewComponent: OpsMemoSyndicationPostSaleTile,
    liveHookLoader: useOpsMemoSyndicationPostSale,
    sources: [
      {
        nameTranslationKey: 'tiles.ops-memo-syndication-post-sale.source',
        path: `/deals/\${dealDisplayId}/syndication\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.opsMemoSyndicationPostSaleWVLive]: {
    viewComponent: OpsMemoSyndicationPostSaleTile,
    liveHookLoader: useOpsMemoSyndicationPostSale,
    sources: [
      {
        nameTranslationKey: 'tiles.ops-memo-syndication-post-sale.source',
        path: `/deals/\${dealDisplayId}/syndication`,
      },
    ],
  },
  [availableAutomaticTiles.opsMemoSyndicationStructureTable]: {
    viewComponent: OpsMemoSyndicationStructureTile,
    liveHookLoader: useOpsMemoSyndicationStructure,
    sources: [
      {
        nameTranslationKey: 'tiles.ops-memo-syndication-post-sale.source',
        path: `/deals/\${dealDisplayId}/syndication\${sourcePathSuffix}`,
      },
    ],
    version: tileVersion.V2,
  },
  [availableAutomaticTiles.opsMemoSyndicationStructureTableWVLive]: {
    viewComponent: OpsMemoSyndicationStructureTile,
    liveHookLoader: useOpsMemoSyndicationStructure,
    sources: [
      {
        nameTranslationKey: 'tiles.ops-memo-syndication-post-sale.source',
        path: `/deals/\${dealDisplayId}/syndication`,
      },
    ],
  },
  [availableAutomaticTiles.opsMemoTrancheDetails]: {
    viewComponent: OpsMemoTrancheDetails,
    liveHookLoader: useOpsMemoTrancheDetails,
    sources: [
      {
        nameTranslationKey: 'pages.deals.tranches-table.header',
        path: `/deals/\${dealId}/financing\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.opsMemoTrancheDetailsWVLive]: {
    viewComponent: OpsMemoTrancheDetails,
    liveHookLoader: useOpsMemoTrancheDetails,
    sources: [
      {
        nameTranslationKey: 'pages.deals.tranches-table.header',
        path: `/deals/\${dealId}/financing`,
      },
    ],
  },
  [availableAutomaticTiles.opsMemoTranches]: {
    viewComponent: OpsMemoTranchesTable,
    liveHookLoader: useOpsMemoTranchesTable,
    sources: [
      {
        nameTranslationKey: 'pages.deals.tranches-table.header',
        path: `/deals/\${dealDisplayId}/financing\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.opsMemoTranchesWVLive]: {
    viewComponent: OpsMemoTranchesTable,
    liveHookLoader: useOpsMemoTranchesTable,
    sources: [
      {
        nameTranslationKey: 'pages.deals.tranches-table.header',
        path: `/deals/\${dealDisplayId}/financing`,
      },
    ],
  },
  [availableAutomaticTiles.riskMonitoringForbearanceMeasures]: {
    viewComponent: ForbearanceMeasuresTable,
    liveHookLoader: useForbearanceMeasuresDecisionPaper,
    sources: [
      {
        nameTranslationKey: 'tiles.forbearance-measures',
        path: '/business-partners/${businessPartnerId}/risk-monitoring#forbearance-tab',
      },
    ],
  },
  [availableAutomaticTiles.riskMonitoringRegulatoryChecksForbearanceStatus]: {
    viewComponent: ForbearanceStatusTable,
    liveHookLoader: useForbearanceStatusTableData,
    sources: [
      {
        nameTranslationKey: 'tiles.forbearance-status',
        path: '/business-partners/${businessPartnerId}/risk-monitoring#forbearance-tab',
      },
    ],
  },
  [availableAutomaticTiles.sensitivityAnalysis]: {
    viewComponent: LatestCustomAssessmentTile,
    liveHookLoader: useSensitivityAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.sensitivity-analysis',
        path: '/deals/${dealDisplayId}/financed-objects${sourcePathSuffix}#appreciation',
      },
    ],
  },
  [availableAutomaticTiles.sensitivityAnalysisWVLive]: {
    viewComponent: LatestCustomAssessmentTile,
    liveHookLoader: useSensitivityAnalysis,
    sources: [
      {
        nameTranslationKey: 'tiles.sensitivity-analysis',
        path: '/deals/${dealDisplayId}/financed-objects#appreciation',
      },
    ],
  },
  [availableAutomaticTiles.standardCollateralPackage]: {
    viewComponent: StandardCollateralPackageTile,
    liveHookLoader: useStandardCollateralPackage,
    sources: [
      {
        nameTranslationKey: 'tiles.collaterals.standard-collateral-package.source',
        path: `/deals/\${dealDisplayId}/collaterals\${sourcePathSuffix}`,
      },
    ],
  },
  [availableAutomaticTiles.standardCollateralPackageWVLive]: {
    viewComponent: StandardCollateralPackageTile,
    liveHookLoader: useStandardCollateralPackage,
    sources: [
      {
        nameTranslationKey: 'tiles.collaterals.standard-collateral-package.source',
        path: `/deals/\${dealDisplayId}/collaterals`,
      },
    ],
  },
  [availableAutomaticTiles.tenancyOverview]: {
    viewComponent: TenancyOverviewTileV1V2,
    liveHookLoader: useTenancyOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.tenancy-overview.source',
        path: `\${path}`,
      },
    ],
    version: tileVersion.V2,
  },
  [availableAutomaticTiles.tenancyOverviewExpiringLeases]: {
    viewComponent: TenancyOverviewExpiringLeasesTile,
    liveHookLoader: useTenancyOverviewExpiringLeases,
    sources: [
      {
        nameTranslationKey: 'tiles.tenancy-overview.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.tenancyOverviewExpiringLeasesWVLive]: {
    viewComponent: TenancyOverviewExpiringLeasesTile,
    liveHookLoader: useTenancyOverviewExpiringLeases,
    sources: [
      {
        nameTranslationKey: 'tiles.tenancy-overview.source',
        path: `\${path}`,
      },
    ],
  },
  [availableAutomaticTiles.tenancyOverviewWVLive]: {
    viewComponent: TenancyOverviewTileV1V2,
    liveHookLoader: useTenancyOverview,
    sources: [
      {
        nameTranslationKey: 'tiles.tenancy-overview.source',
        path: `\${path}`,
      },
    ],
    version: tileVersion.V2,
  },
  [availableAutomaticTiles.tenantDetails]: {
    viewComponent: TenantDetailsTile,
    liveHookLoader: useTenantDetails,
    sources: [
      {
        nameTranslationKey: 'tiles.tenant-details.source.properties',
        path: `\${propertiesPath}`,
      },
      {
        nameTranslationKey: 'tiles.tenant-details.source.business-partner',
        path: `/business-partners/\${businessPartnerId}/ratings`,
      },
    ],
  },
  [availableAutomaticTiles.tenantDetailsWVLive]: {
    viewComponent: TenantDetailsTile,
    liveHookLoader: useTenantDetails,
    sources: [
      {
        nameTranslationKey: 'tiles.tenant-details.source.properties',
        path: `\${propertiesPath}`,
      },
      {
        nameTranslationKey: 'tiles.tenant-details.source.business-partner',
        path: `/business-partners/\${businessPartnerId}/ratings`,
      },
    ],
  },
  [availableAutomaticTiles.totalDebtBundesbank]: {
    viewComponent: TotalDebtBundesbankTileWrapper,
    liveHookLoader: useTotalDebtBundesbankTile,
    sources: [
      {
        nameTranslationKey: 'tiles.total-debt-bundesbank.source',
        path: '/business-partners/${businessPartnerId}/exposure',
      },
    ],
  },
  [availableAutomaticTiles.unitSelectionTile]: {
    viewComponent: UnitSelectionTile,
    liveHookLoader: useUnitSelectionOptions,
    sources: [],
  },
  [availableAutomaticTiles.workingVersionDetails]: {
    viewComponent: WorkingVersionDetailsTile,
    liveHookLoader: useWorkingVersionDetails,
    sources: [
      {
        nameTranslationKey: 'tiles.working-version-details.source',
        path: `\${path}`,
      },
    ],
  },
}

export const getCurrentTileVersionForTileCode = (tileCode) =>
  automaticTileCodeMap[tileCode]?.version
export const getLiveHookForTileCode = (tileCode) => automaticTileCodeMap[tileCode]?.liveHookLoader
export const getViewComponentForTileCode = (tileCode) =>
  automaticTileCodeMap[tileCode]?.viewComponent
export const getSourcesForTileCode = (tileCode) => automaticTileCodeMap[tileCode]?.sources
export const getLiveImagesForTileCode = (tileCode) => {
  if (!(tileCode in automaticTileCodeMap)) {
    return undefined
  }
  return automaticTileCodeMap[tileCode].liveImagesLoader
    ? automaticTileCodeMap[tileCode].liveImagesLoader
    : defaultImageLoader
}
