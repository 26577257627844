import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { resetInvalidEditState } from 'redux/slices/properties/segmentsTableSlice'

export const useSegmentsTableResetEditState = ({ segments, setSegmentsInEdit }) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    setSegmentsInEdit((_) => [...segments.map((segment) => ({ ...segment }))])
    dispatch(resetInvalidEditState())
  }, [dispatch, segments, setSegmentsInEdit])
}
