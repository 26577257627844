import { useMemo } from 'react'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'

const useCanFileDrop = () => {
  const [{ isOver, canDrop }, dropRef] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [],
  )

  return useMemo(() => ({ isOver, canDrop, dropRef }), [isOver, canDrop, dropRef])
}

export default useCanFileDrop
