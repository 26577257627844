import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ValuationRequestCreationDialog from 'components/domains/properties/valuation/ValuationRequestCreationDialog'
import '@ui5/webcomponents-icons/dist/AllIcons.js'
import { propertiesShape } from 'components/domains/properties/valuation/panels/ValuationRequestCreationPanel'

const ValuationRequestCreationButton = ({ properties, showCreateButton }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.property.valuation' })
  const [isCreationDialogOpen, setIsCreationDialogOpen] = useState(false)
  const onCreationButtonClicked = () => {
    setIsCreationDialogOpen(true)
  }

  return (
    <>
      <Button
        key="create-valuation-request"
        design={ButtonDesign.Default}
        onClick={onCreationButtonClicked}
      >
        {t('button.create')}
      </Button>
      {isCreationDialogOpen && (
        <ValuationRequestCreationDialog
          properties={properties}
          isOpen={isCreationDialogOpen}
          setIsOpen={setIsCreationDialogOpen}
          showCreateButton={showCreateButton}
        />
      )}
    </>
  )
}

ValuationRequestCreationButton.propTypes = {
  properties: propertiesShape.isRequired,
  showCreateDraftButton: PropTypes.bool,
  showCreateButton: PropTypes.bool,
}

export default ValuationRequestCreationButton
