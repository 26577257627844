import { Title, TitleLevel } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { TILE_VERSION as tileVersion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import stylesTooltip from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared//kpis/chart/KpiTooltip.module.css'
import { CardShowItem } from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/card/CardItem'
import KpiUnit from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/kpis/chart/KpiUnit'
import StaffMemberByObjectIdOrEmail from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/staff-member/StaffMemberByObjectIdOrEmail'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const EMPTY_VALUE = '-'

const KpiTimeSeriesTooltip = ({
  active,
  payload = [],
  label,
  unit,
  detailed = false,
  updatedByNames,
  tileVersion: providedTileVersion,
}) => {
  const { t } = useTranslation('decisionPaper', { keyPrefix: 'tiles.kpi-chart-tile' })
  const { format: formatDate } = useShortDateFormatter()

  const renderStaffMember = useCallback(
    (updatedBy) => {
      const resolvedName =
        updatedByNames && updatedByNames.find((namePair) => namePair.id === updatedBy)?.name
      return resolvedName ?? `${updatedBy} (${t('components.ui.user.unknown-user')})`
    },
    [t, updatedByNames],
  )

  if (active && payload && payload.length) {
    const { value, updatedBy, calculatedValue, adjustedValue, comment } = payload[0].payload

    const createdAtLabelAsIsoDate = label ? new Date(label)?.toISOString() : EMPTY_VALUE

    return (
      <div className={stylesTooltip.tooltip}>
        <Title className={stylesTooltip.title} level={TitleLevel.H6}>
          <b>{formatDate(createdAtLabelAsIsoDate)}</b>
        </Title>
        {detailed ? (
          <>
            <CardShowItem
              label={t('components.kpis.chart.tooltip.calculated-value')}
              value={<KpiUnit value={calculatedValue} unit={unit} />}
            />
            <CardShowItem
              label={t('components.kpis.chart.tooltip.adjusted-value')}
              value={<KpiUnit value={adjustedValue} unit={unit} />}
            />
            <CardShowItem label={t('components.kpis.chart.tooltip.comment')} value={comment} />
          </>
        ) : (
          <CardShowItem
            label={t('components.kpis.chart.tooltip.value')}
            value={<KpiUnit value={value} unit={unit} />}
          />
        )}
        {updatedBy && (
          <CardShowItem
            label={t('components.kpis.chart.tooltip.updated-by')}
            value={
              providedTileVersion === tileVersion.V1 ? (
                <StaffMemberByObjectIdOrEmail objectIdOrEmail={updatedBy} />
              ) : (
                renderStaffMember(updatedBy)
              )
            }
          />
        )}
      </div>
    )
  }
  return null
}

KpiTimeSeriesTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        value: PropTypes.number,
        updatedBy: PropTypes.string,
      }),
    }),
  ),
  label: PropTypes.string,
  unit: PropTypes.shape({
    type: PropTypes.string,
    code: PropTypes.string,
  }),
  detailed: PropTypes.bool,
  updatedByNames: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  tileVersion: PropTypes.string.isRequired,
}

export default KpiTimeSeriesTooltip
