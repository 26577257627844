const conditionAssigneeChanged = (
  state,
  { payload: { conditionIndex, assignee: newAssignee } },
) => {
  if (!state.conditions[conditionIndex]) {
    return
  }
  state.conditions[conditionIndex].assignee = newAssignee
  state.conditions[conditionIndex].requirements?.forEach((_value, requirementIndex) => {
    state.conditions[conditionIndex].requirements[requirementIndex].assignee = newAssignee
  })
}

export default conditionAssigneeChanged
