import { FlexBox, FlexBoxDirection, Input, Label } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/rent-roll/segment/segment-create/SegmentGroupedInput.module.css'
import { capitalizeStringWithExclusion } from 'components/domains/util/capitalizeStringWithExclusion'
import { useAreaMeasurementUnitFormatter } from 'hooks/i18n/useI18n'

const MONTHS_PER_YEAR = 12

const SegmentVacancyCostsInput = ({
  annuallyVacancyCosts,
  setAnnuallyVacancyCosts,
  currencyCode,
  areaMeasureUnit,
}) => {
  const NONE = 'none'
  const ANNUALLY = 'annually'
  const MONTHLY = 'monthly'

  const { t: tSegmentCreation } = useTranslation('translation', {
    keyPrefix: 'pages.rent-roll-working-version.segment-creation',
  })
  const formatArea = useAreaMeasurementUnitFormatter()

  const toFixedNumber = (number) => Number(number.toFixed(2))

  const [editMode, setEditMode] = useState(NONE)
  const [monthlyVacancyCosts, setMonthlyVacancyCosts] = useState(
    annuallyVacancyCosts ? toFixedNumber(Number(annuallyVacancyCosts) / MONTHS_PER_YEAR) : null,
  )
  const [isInitial, setIsInitial] = useState(true)

  useEffect(() => {
    if (annuallyVacancyCosts && editMode === ANNUALLY) {
      setMonthlyVacancyCosts(toFixedNumber(Number(annuallyVacancyCosts) / MONTHS_PER_YEAR))
      setIsInitial(false)
    } else if (!annuallyVacancyCosts) {
      setMonthlyVacancyCosts(null)
    }
  }, [annuallyVacancyCosts, editMode, isInitial])

  const getPerMeasurementLabel = () =>
    `${tSegmentCreation('vacancy-costs-per-measure-unit')} ${capitalizeStringWithExclusion({
      string: formatArea(areaMeasureUnit),
    })}:`

  const setOrCalculateAnnuallyVacancyCosts = (changedVacancyCosts, mode) => {
    if (mode === ANNUALLY) {
      setAnnuallyVacancyCosts(Number(changedVacancyCosts))
    } else if (mode === MONTHLY) {
      const fixedAnnualVacancyCosts = toFixedNumber(Number(changedVacancyCosts) * MONTHS_PER_YEAR)
      setAnnuallyVacancyCosts(fixedAnnualVacancyCosts)
    }
  }

  const handleOnInput = (e, mode) => {
    const changedVacancyCosts = e.target.value
    if (changedVacancyCosts) {
      setEditMode(mode)
      setOrCalculateAnnuallyVacancyCosts(changedVacancyCosts, mode)
    } else {
      setEditMode(NONE)
      setAnnuallyVacancyCosts(null)
    }
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label className={styles.segmentGroupedLabel}>{getPerMeasurementLabel()}</Label>
      <div>
        <Label>{`${t('labels.annually')}:`}</Label>
        <Input
          id="segment-vacancy-costs-input-annually"
          disabled={editMode === MONTHLY}
          icon={<Label className={styles.segmentInputLabel}>{currencyCode}</Label>}
          valueState={'None'}
          className={styles.segmentInput}
          value={annuallyVacancyCosts}
          onInput={(e) => handleOnInput(e, ANNUALLY)}
          onChange={() => setEditMode(NONE)}
          type="Number"
        />
      </div>
      <div>
        <Label>{`${t('labels.monthly')}:`}</Label>
        <Input
          id="segment-vacancy-costs-input-monthly"
          disabled={editMode === ANNUALLY}
          icon={<Label className={styles.segmentInputLabel}>{currencyCode}</Label>}
          valueState={'None'}
          className={styles.segmentInput}
          value={monthlyVacancyCosts}
          onInput={(e) => handleOnInput(e, MONTHLY)}
          onChange={() => setEditMode(NONE)}
          type="Number"
        />
      </div>
    </FlexBox>
  )
}
SegmentVacancyCostsInput.propTypes = {
  annuallyVacancyCosts: PropTypes.number,
  setAnnuallyVacancyCosts: PropTypes.func.isRequired,
  currencyCode: PropTypes.string.isRequired,
  areaMeasureUnit: PropTypes.string.isRequired,
}
export default SegmentVacancyCostsInput
