import { ButtonDesign, Button, DatePicker, Icon } from '@fioneer/ui5-webcomponents-react'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/covenants/limit-cards/ChangeToAbsoluteLimits.module.css'
import { CovenantPeriodValues } from 'components/domains/deals/covenants/limit-cards/model/CovenantPeriodValues'
import Labeled from 'components/ui/data/Labeled'
import Dialog, { DialogPrimaryButton, DialogSize } from 'components/ui/dialog/Dialog'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useDealMini from 'hooks/services/deals/useDealMini'
import { DealContext } from 'routes/deals/DealContext'

const MONTH_PERIOD_LENGTH = 1
const QUARTER_PERIOD_LENGTH = 3
const YEAR_PERIOD_LENGTH = 12

export const convertRelativeDateToAbsolute = (
  relativeDateFrom,
  relativeDateTo,
  referenceDateTimeParsed,
  period,
) => {
  let periodLength = 0
  if (period === CovenantPeriodValues.Month) periodLength = MONTH_PERIOD_LENGTH
  else if (period === CovenantPeriodValues.Quarter) periodLength = QUARTER_PERIOD_LENGTH
  else if (period === CovenantPeriodValues.Year) periodLength = YEAR_PERIOD_LENGTH

  const offsetValidFrom = {
    months: periodLength * parseInt(relativeDateFrom - 1),
    days: 0,
  }
  const offsetValidTo = {
    months: periodLength * parseInt(relativeDateTo),
    days: -1,
  }

  return {
    absValidFrom: referenceDateTimeParsed?.plus(offsetValidFrom)?.toISODate(),
    absValidTo: referenceDateTimeParsed?.plus(offsetValidTo)?.toISODate(),
  }
}

/************************
 * useConvertLimitsRelativeToAbsolute
 * @param relativeLimitList expects [{period:}]
 * @param referenceDateTime date
 *
 * maps a list of relative limits to a list of absolute limits
 */
export const convertLimitsRelativeToAbsolute = (relativeLimitList, referenceDateTime) =>
  (relativeLimitList || []).map((relLimit) => {
    const { absValidFrom, absValidTo } = convertRelativeDateToAbsolute(
      relLimit.validFrom,
      relLimit.validTo,
      referenceDateTime,
      relLimit.period,
    )
    const absoluteLimit = {
      clvaId: crypto.randomUUID(),
      ...relLimit,
      validFrom: absValidFrom,
      validTo: absValidTo,
    }
    if (absoluteLimit.clvrId) delete absoluteLimit.clvrId
    if (absoluteLimit.period) delete absoluteLimit.period
    return absoluteLimit
  })

/************************
 * Button & Modal
 */
const ChangeToAbsoluteLimitsButton = ({ relativeLimitList, onConvertedToAbsoluteLimitList }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.limit-card',
  })
  const { t: tNoPrefix } = useTranslation()
  const { localePattern, parse: parseDate } = useShortDateFormatter()
  const { deal: { dealUuid } = {} } = useContext(DealContext)

  /************************
   * Precalculate ReferenceDate from ActualSigningDate
   */
  const [referenceDateStr, setReferenceDateStr] = useState('')
  const { isLoading: isDealMiniLoading, data: dealMini } = useDealMini(dealUuid)
  const actualSigningDate = useMemo(
    () => dealMini?.actualSigningDate ?? '',
    [isDealMiniLoading, dealMini?.actualSigningDate],
  )
  useEffect(() => {
    if (actualSigningDate?.length > 0 && referenceDateStr.length <= 0)
      setReferenceDateStr(actualSigningDate)
  }, [actualSigningDate])
  const referenceDateTimeParsed = DateTime.fromISO(referenceDateStr)

  // Calc absolute limits for reference date
  const absoluteLimitList = convertLimitsRelativeToAbsolute(
    relativeLimitList,
    referenceDateTimeParsed,
  )

  /************************
   * UI Handlers
   */
  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleButtonClick = () => {
    setIsModalOpen(true)
  }

  const handleModalConfirmation = () => {
    if (onConvertedToAbsoluteLimitList) {
      onConvertedToAbsoluteLimitList(absoluteLimitList)
    }
    setIsModalOpen(false)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const onDateChanged = useCallback(
    ({ detail: { value: localeDate } }) => {
      const parsedDate = parseDate(localeDate, localePattern) ?? ''
      setReferenceDateStr(parsedDate)
    },
    [localePattern, parseDate],
  )

  /************************
   * Layout Button
   */
  const renderButton = () => (
    <Button
      id="change-to-absolute-limits-button"
      key="change-to-absolute-limits-button"
      design={ButtonDesign.Transparent}
      onClick={handleButtonClick}
    >
      {t('change-to-absolute-values.button')}
    </Button>
  )

  /************************
   * Layout Modal
   */

  const MODAL_POPUP_MODE = {
    //Popup behaviour when relative limits ARE available
    WITH_LIMITS: {
      isConfirmButtonDisabled: () => referenceDateStr.length <= 0,
      textKey: () => 'components.deals.covenants.limit-card.change-to-absolute-values.disclaimer',
      hasDatePicker: () => true,
    },
    //Popup behaviour when relative limits are NOT AVAILABLE
    WITHOUT_LIMITS: {
      isConfirmButtonDisabled: () => false,
      textKey: () =>
        'components.deals.covenants.limit-card.change-to-absolute-values.disclaimer-short',
      hasDatePicker: () => false,
    },
    current: () =>
      relativeLimitList?.length > 0
        ? MODAL_POPUP_MODE.WITH_LIMITS
        : MODAL_POPUP_MODE.WITHOUT_LIMITS,
    hasRelativeLimits: () => referenceDateStr.length <= 0,
  }

  const renderModal = () => (
    <Dialog
      data-testid="change-to-absolute-limits-dialog"
      id="change-to-absolute-limits-dialog"
      size={DialogSize.S}
      open={isModalOpen}
      onAfterClose={handleModalClose}
      className={styles.confirmationModal}
      resizable={false}
      headerText={
        <>
          <span>
            <Icon name="information" className={styles.iconInline} />
          </span>
          <span>{tNoPrefix('buttons.confirm')}</span>
        </>
      }
      primaryButton={
        <DialogPrimaryButton
          id="confirm-button"
          onClick={handleModalConfirmation}
          disabled={MODAL_POPUP_MODE.current().isConfirmButtonDisabled()}
        >
          {tNoPrefix('buttons.confirm')}
        </DialogPrimaryButton>
      }
    >
      <div>
        <Trans i18nKey={MODAL_POPUP_MODE.current().textKey()} />
      </div>
      {MODAL_POPUP_MODE.current().hasDatePicker() && (
        <Labeled label={t('change-to-absolute-values.reference-date')} vertical showColon required>
          <DatePicker
            value={referenceDateStr}
            onChange={onDateChanged}
            formatPattern={localePattern}
          />
        </Labeled>
      )}
    </Dialog>
  )

  return (
    <>
      {renderButton()}
      {renderModal()}
    </>
  )
}

ChangeToAbsoluteLimitsButton.propTypes = {
  relativeLimitList: PropTypes.arrayOf(
    PropTypes.shape({
      isCovenantHoliday: PropTypes.bool,
      value: PropTypes.any,
      unit: PropTypes.string,
      currencyCode: PropTypes.string,
      validFrom: PropTypes.number,
      validTo: PropTypes.number,
      period: PropTypes.oneOf(Object.values(CovenantPeriodValues)),
    }),
  ).isRequired,
  onConvertedToAbsoluteLimitList: PropTypes.func,
}

export default ChangeToAbsoluteLimitsButton
