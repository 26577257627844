import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateDealConflict = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, conflictDealUuid }) => {
      await post({
        path: `/deals/${dealUuid}/conflicts`,
        body: snakecaseKeys({ conflictDealUuid }),
      })
    },
    { onSuccess, onError },
  )
}

export default useCreateDealConflict
