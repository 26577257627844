import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react/dist'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { startRequirementAddMode } from 'redux/slices/conditions/requirementsTableSlice'

const RequirementAddButton = ({ disabled }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isRequirementAddMode = useSelector(
    (state) => state.conditions.requirementsTable.isRequirementAddMode,
  )

  const isButtonDisabled = disabled || isRequirementAddMode

  const handleButtonClicked = () => {
    dispatch(startRequirementAddMode())
  }

  return (
    <Button
      design={ButtonDesign.Transparent}
      onClick={handleButtonClicked}
      disabled={isButtonDisabled}
    >
      {t('buttons.add')}
    </Button>
  )
}

RequirementAddButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
}

export default RequirementAddButton
