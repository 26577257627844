import { Bar, Button, Dialog, Menu, MenuItem, Title } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEqual from 'lodash.isequal'
import PropType from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import CreateRentRollWorkingVersionLoadingScreen from 'components/domains/properties/rent-roll/working-version/CreateRentRollWorkingVersionLoadingScreen'
import {
  OCCUPANCY_TYPE_VACANT,
  OCCUPANCY_TYPE_SELF,
} from 'components/domains/properties/rent-roll/working-version/utils/rentRollWorkingVersionConstants'
import useValidateRentalUnitIds from 'hooks/services/properties/rent-roll/working-version/excel-upload/useValidateRentalUnitIds'
import useMultiplePropertiesSegments from 'hooks/services/properties/segments/useMultiplePropertiesSegments'
import { useCreateRentRollWorkingVersion } from 'hooks/services/properties/useCreateRentRollWorkingVersion'
import { useDeleteRentRollWorkingVersion } from 'hooks/services/properties/useDeleteRentRollWorkingVersion'
import { useExpandRentalUnitsWithBusinessPartners } from 'hooks/services/properties/useExpandRentalUnitsWithBusinessPartners'
import useRentRollWorkingVersionUuids from 'hooks/services/properties/useRentRollWorkingVersionUuids'
import useRentRollWorkingVersions from 'hooks/services/properties/useRentRollWorkingVersions'
import { useUtilizationCodes } from 'hooks/services/properties/useUtilizationCodes'

const occupancySelfOrVacant = [OCCUPANCY_TYPE_SELF, OCCUPANCY_TYPE_VACANT]

/**
 * use ui-components dialog | https://fioneer.atlassian.net/browse/CWP-13200
 */
const CreateRentRollWorkingVersionButton = ({ rentalUnits = [], properties }) => {
  // Hooks and Constants
  const isMultiProperty = properties.length > 1
  const { pathname, search } = useLocation()
  const navigate = useNavigate()
  const routeToWorkingVersion = pathname + '/working-version' + search

  const propertyUuids = properties?.map((property) => property.uuid)
  const rentRollWorkingVersions = useRentRollWorkingVersions(propertyUuids)?.data
  const rentRollWorkingVersionUuids = useRentRollWorkingVersionUuids(propertyUuids) // returns null if not exists
  const rentRollWorkingVersionExists = !!rentRollWorkingVersionUuids

  const { data: rentalUnitsWithBusinessPartners, isLoading: isLoadingBusinessPartners } =
    useExpandRentalUnitsWithBusinessPartners(rentalUnits)
  const {
    data: { utilization_codes: utilizationCodes } = {},
    isLoading: isLoadingUtilizationCodes,
    isError: isErrorUtilizationCodes,
  } = useUtilizationCodes()

  const {
    data: { segments },
    isLoading: isLoadingSegments,
    isError: isErrorSegments,
  } = useMultiplePropertiesSegments(propertyUuids)
  const refButton = useRef()
  const refMenu = useRef()
  const menuItemCreateEmptyWorkingVersion = 'create-empty-working-version-action'
  const menuItemCreateWorkingVersionFromRentRoll = 'create-working-version-from-rent-roll-action'
  const createWorkingVersionFromRentRollDisabled =
    isEqual(rentalUnits, []) ||
    isLoadingBusinessPartners ||
    isLoadingSegments ||
    isErrorSegments ||
    isLoadingUtilizationCodes ||
    isErrorUtilizationCodes

  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { validateAndAdjustRentalUnitIds } = useValidateRentalUnitIds()

  // useStates
  const [warningDialogState, setWarningDialogState] = useState({ isOpen: false, action: '' })
  const [deletedWorkingVersions, setDeletedWorkingVersions] = useState(0)
  const [createFromRentRollAfterDelete, setCreateFromRentRollAfterDelete] = useState()
  const [isOpenLoadingScreen, setIsOpenLoadingScreen] = useState(false)

  // Functions
  const handleCreateAndUpdateSuccess = async () => {
    await queryClient.invalidateQueries(['rent-roll-working-version', ...propertyUuids.sort()])
    navigate(routeToWorkingVersion)
  }

  const deleteRentRollWorkingVersion = useDeleteRentRollWorkingVersion({
    onSuccess: () => {
      setDeletedWorkingVersions((oldCount) => oldCount + 1)
    },
  })

  const createRentRollWorkingVersion = useCreateRentRollWorkingVersion({
    onSuccess: () => {
      handleCreateAndUpdateSuccess()
    },
    onSettled: () => {
      setIsOpenLoadingScreen(false)
    },
  })

  const getSegmentUuid = ({
    segment_usage_type_code: usageTypeCode,
    segment_name: name,
    property_uuid: propertyUuid,
  }) => {
    const matchingSegments = segments.filter(
      (segment) =>
        segment.property_uuid === propertyUuid &&
        segment.usage_type_code === usageTypeCode &&
        segment.name === name,
    )
    return matchingSegments.length === 1 ? matchingSegments[0]?.uuid : null
  }
  const getValueOrUndefined = useCallback((condition, value) => (condition ? value : undefined), [])
  const mapRentalUnitsToWorkingVersion = (cmsRentalUnits) =>
    cmsRentalUnits.map((rentalUnit) => {
      const isRented = !occupancySelfOrVacant.includes(
        utilizationCodes.find((code) => code.key === rentalUnit.occupancy_status)?.type,
      )
      return {
        // General
        property_uuid: rentalUnit.property_uuid,
        rental_unit_name: rentalUnit.name,
        occupancy_status_id: rentalUnit.occupancy_status,
        segment_uuid: getSegmentUuid(rentalUnit),
        segment_usage_type_id: rentalUnit?.segment_usage_type_code,
        // Measurement / Rent
        rental_unit_area: rentalUnit.area.value,
        rental_unit_area_uom_id: rentalUnit.area?.measurement_unit,
        rent_contracted_year: getValueOrUndefined(
          isRented,
          rentalUnit.rent_contracted_year?.number,
        ),
        current_net_rent: getValueOrUndefined(isRented, rentalUnit.rent_current_year?.number),
        rental_unit_currency_id: getValueOrUndefined(
          isRented,
          rentalUnit.rent_contracted_year?.currency,
        ),
        // Dates
        rent_start_date: rentalUnit.rent_start_date,
        lease_break_date: rentalUnit.lease_break_date,
        lease_expiry_date: rentalUnit.lease_expiry_date,
        lease_start_date: rentalUnit.lease_start_date,
        // Business partners
        tenant_id: rentalUnit.tenant.id,
        tenant_name: rentalUnit.tenant.name,
        branding_franchise_partner_name: rentalUnit.branding_franchise?.name,
        branding_franchise_partner_id: rentalUnit.branding_franchise?.id,

        number_of_units: rentalUnit.number_of_units,
        anonymous_tenant: rentalUnit.anonymous_tenant,
        no_lease_expiry: rentalUnit.no_lease_expiry,
        rent_arrears: rentalUnit.rent_arrears?.number,
        rent_dispute: rentalUnit.rent_dispute,
        comment: rentalUnit.lease_comment,
      }
    })

  const handleDeleteRentRollWorkingVersions = ({ withCopyOfRentRoll }) => {
    setIsOpenLoadingScreen(true)
    setCreateFromRentRollAfterDelete(withCopyOfRentRoll)
    rentRollWorkingVersions?.map((workingVersion) =>
      deleteRentRollWorkingVersion.mutate({ propertyUuids: workingVersion.property_uuids }),
    )
  }

  const handleCreateRentRollWorkingVersion = ({ withCopyOfRentRoll }) => {
    setIsOpenLoadingScreen(true)
    const header = {}
    const workingVersionRentalUnits = withCopyOfRentRoll
      ? mapRentalUnitsToWorkingVersion(rentalUnitsWithBusinessPartners)
      : []
    const validatedRentalUnits = validateAndAdjustRentalUnitIds(workingVersionRentalUnits)
    createRentRollWorkingVersion.mutate({
      property_uuids: propertyUuids,
      header: header,
      rental_units: validatedRentalUnits,
    })
  }

  // This useEffect is triggered, when a working version is deleted
  // When all working versions have been deleted, a new one should be created.
  useEffect(() => {
    if (deletedWorkingVersions === rentRollWorkingVersionUuids?.length) {
      handleCreateRentRollWorkingVersion({ withCopyOfRentRoll: createFromRentRollAfterDelete })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedWorkingVersions])

  const handleShowMenu = () => {
    refMenu.current.showAt(refButton.current)
  }

  const handleMenuItemClick = (event) => {
    switch (event.detail.item.id) {
      case menuItemCreateEmptyWorkingVersion:
        rentRollWorkingVersionExists
          ? setWarningDialogState({
              isOpen: true,
              action: menuItemCreateEmptyWorkingVersion,
            })
          : handleCreateRentRollWorkingVersion({ withCopyOfRentRoll: false })
        break
      case menuItemCreateWorkingVersionFromRentRoll:
        rentRollWorkingVersionExists
          ? setWarningDialogState({
              isOpen: true,
              action: menuItemCreateWorkingVersionFromRentRoll,
            })
          : handleCreateRentRollWorkingVersion({ withCopyOfRentRoll: true })
        break
    }
  }

  const renderRentRollExistsDialog = () => {
    const handleDialogExit = (confirmed) => {
      if (confirmed) {
        // Delete existing RRWVs and recreate with empty data
        if (warningDialogState.action === menuItemCreateEmptyWorkingVersion) {
          handleDeleteRentRollWorkingVersions({ withCopyOfRentRoll: false })
        }
        // Delete existing RRWV and recreate with data from rent roll (only for single property)
        if (warningDialogState.action === menuItemCreateWorkingVersionFromRentRoll) {
          handleDeleteRentRollWorkingVersions({ withCopyOfRentRoll: true })
        }
      }
      setWarningDialogState({
        isOpen: false,
        action: '',
      })
    }
    const confirmButton = (
      <Button
        id="overwrite-existing-rent-roll-continue"
        design="Emphasized"
        onClick={() => handleDialogExit(true)}
      >
        {t('pages.rent-roll-working-version.dialog.overwrite-existing-rent-roll.accept-button')}
      </Button>
    )
    const cancelButton = (
      <Button id="overwrite-existing-rent-roll-cancel" onClick={() => handleDialogExit(false)}>
        {t('pages.rent-roll-working-version.dialog.overwrite-existing-rent-roll.abort-button')}
      </Button>
    )

    return (
      <>
        {createPortal(
          <Dialog
            open={warningDialogState.isOpen}
            onAfterClose={() => handleDialogExit(false)}
            header={
              <Bar>
                <Title>
                  {t('pages.rent-roll-working-version.dialog.overwrite-existing-rent-roll.title')}
                </Title>
              </Bar>
            }
            footer={<Bar endContent={[confirmButton, cancelButton]} />}
          >
            {t('pages.rent-roll-working-version.dialog.overwrite-existing-rent-roll.body')}
          </Dialog>,
          document.body,
        )}
      </>
    )
  }

  return (
    <>
      <Button
        ref={refButton}
        id="create-rent-roll-working-version-button"
        onClick={() => {
          handleShowMenu()
        }}
        icon="slim-arrow-down"
        iconEnd
      >
        {t('pages.rent-roll-working-version.create-button')}
        <Menu
          id="create-rent-roll-working-version-menu"
          ref={refMenu}
          onItemClick={(item) => {
            handleMenuItemClick(item)
          }}
        >
          <MenuItem
            id={menuItemCreateEmptyWorkingVersion}
            style={{ width: '100%' }}
            text={t('pages.rent-roll-working-version.create-button.empty-rent-roll')}
          />
          <MenuItem
            id={menuItemCreateWorkingVersionFromRentRoll}
            disabled={createWorkingVersionFromRentRollDisabled}
            style={{ width: '100%' }}
            text={
              isMultiProperty
                ? t('pages.rent-roll-working-version.create-button.rent-rolls-copy')
                : t('pages.rent-roll-working-version.create-button.rent-roll-copy')
            }
          />
        </Menu>
      </Button>
      {renderRentRollExistsDialog()}
      {isOpenLoadingScreen &&
        createPortal(
          <CreateRentRollWorkingVersionLoadingScreen isLoading={isOpenLoadingScreen} />,
          document.body,
        )}
    </>
  )
}

CreateRentRollWorkingVersionButton.propTypes = {
  rentalUnits: PropType.array,
  properties: PropType.array,
}
export default CreateRentRollWorkingVersionButton
