import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import DualUnitValue from 'components/domains/business-partners/dual-unit-value/DualUnitValue'
import { useAreaMeasurementUnitFormatter, useNumberFormatter } from 'hooks/i18n/useI18n'

/**
 * Component to display a measurement value along an alternative value with a different measurement unit (e.g. primaryMeasurementUnit: 'sqm', secondaryMeasurementUnit: 'sqft')
 *
 * @param {object} params
 * @param {number} params.primaryValue the primary value
 * @param {string} params.primaryMeasurementUnit the primary measurement unit (e.g. 'sqm')
 * @param {number} [params.secondaryValue] the optional secondary value
 * @param {string} [params.secondaryMeasurementUnit] the optional secondary measurement (e.g. 'sqft')
 * @param {number} [params.maximumFractionDigits] the maximum fraction digits
 * @param {number} [params.minimumFractionDigits] the minimum fraction digits
 * @param {string} [params.className] a class name to add to the outermost element
 */
const DualMeasurementValue = ({
  primaryValue,
  primaryMeasurementUnit,
  secondaryValue,
  secondaryMeasurementUnit,
  maximumFractionDigits = 1,
  minimumFractionDigits = 1,
  className,
}) => {
  const formatNumber = useNumberFormatter({
    maximumFractionDigits,
    minimumFractionDigits,
  })
  const formatAreaUnit = useAreaMeasurementUnitFormatter()
  return (
    <DualUnitValue
      primaryValueWithUnit={`${formatNumber(primaryValue)} ${formatAreaUnit(
        primaryMeasurementUnit,
      )}`}
      secondaryValueWithUnit={
        isNil(secondaryValue)
          ? undefined
          : `${formatNumber(secondaryValue)} ${formatAreaUnit(secondaryMeasurementUnit)}`
      }
      className={className}
    />
  )
}

DualMeasurementValue.propTypes = {
  primaryValue: PropTypes.number.isRequired,
  primaryMeasurementUnit: PropTypes.string.isRequired,
  secondaryValue: PropTypes.number,
  secondaryMeasurementUnit: PropTypes.string,
  maximumFractionDigits: PropTypes.number,
  minimumFractionDigits: PropTypes.number,
  className: PropTypes.string,
}

export default DualMeasurementValue
