import {
  AnalyticalTableSelectionBehavior,
  AnalyticalTableSelectionMode,
  TextAlign,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CreatorCell,
  DateCell,
  IconCell,
  ObjectCell,
  NameCell,
} from 'components/domains/documents/DocumentSelectTableCells'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'

const propTypes = {
  onChange: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.shape({})),
  entity: PropTypes.shape({
    /** The entity's (unique) ID. **Currently ignored**. */
    id: PropTypes.string.isRequired,
    /** The entity's Display ID. Will be displayed on the screen. */
    displayId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(DocumentTypes)).isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
}

/**
 * @typedef {import('hooks/services/documents/documentsSchema').Document} Document
 * @typedef {{onChange?: (documentId?: string) => void, documents: Document[]}} overrides
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 * */
const DocumentSelectTable = ({ onChange = () => {}, documents, isLoading, isError, entity }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'components.documents.table' })

  const columns = [
    {
      id: 'id',
      Header: t('columns.name'),
      Cell: NameCell,
    },
    {
      id: 'entity',
      accessor: 'entity',
      Header: t('columns.entity'),
      Cell: ObjectCell,
    },
    {
      id: 'type',
      accessor: 'type',
      Header: t('columns.type'),
    },
    {
      id: 'fileExtension',
      accessor: 'fileExtension',
      Cell: IconCell,
      Header: t('columns.format'),
      hAlign: TextAlign.Center,
    },
    {
      id: 'keyDate',
      accessor: 'keyDate',
      Header: t('columns.key-date'),
      Cell: DateCell,
      hAlign: TextAlign.End,
    },
    {
      id: 'creator',
      accessor: 'creator',
      Header: t('columns.creator'),
      Cell: CreatorCell,
    },
  ]

  /** @param {{ detail: { row: {original: { id: string | undefined }} } }} e */
  const handleSelectionChange = (e) => {
    onChange(e.detail.row.original.id)
  }

  const tableData = useMemo(
    () => documents.map((document) => ({ ...document, entity })),
    [documents, entity],
  )

  return (
    <LoadingStateWrapper isLoading={isLoading} isError={isError}>
      <AnalyticalTableWithToolbar
        data={tableData}
        nrOfEntries={tableData.length}
        columns={columns}
        title={t('document', {
          keyPrefix: 'components.documents',
          count: 2,
        })}
        selectionMode={AnalyticalTableSelectionMode.SingleSelect}
        selectionBehavior={AnalyticalTableSelectionBehavior.RowOnly}
        rowHeight={50}
        onRowSelect={handleSelectionChange}
      />
    </LoadingStateWrapper>
  )
}

DocumentSelectTable.propTypes = propTypes

export default DocumentSelectTable
