import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  ResponsiveGridLayout,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import AnnualReviewDealOverviewBorrowerOverviewLabeledValue from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/involved-parties/AnnualReviewDealOverviewBorrowerOverviewLabeledValue'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/borrower/BorrowerOverviewTile.module.css'

const AnnualReviewDealOverviewBorrowerOverviewAddress = ({ tileId, selectedDealIndex }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.bp-borrower-overview.address',
  })
  const { data: tileData } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const { multipleBusinessPartnersData } = tileData ?? {}

  const businessPartnerData = multipleBusinessPartnersData?.[selectedDealIndex]
  const address = useMemo(
    () =>
      isNil(businessPartnerData?.address)
        ? undefined
        : {
            streetName: businessPartnerData.address.streetName,
            postalCode: businessPartnerData.address.postalCode,
            city: businessPartnerData.address.city,
            region: businessPartnerData.address.region,
            country: businessPartnerData.address.country,
          },
    [businessPartnerData?.address],
  )

  const content = useMemo(() => {
    if (isNil(address)) {
      return (
        <FlexBox
          className={styles.listCardEmptyContent}
          alignItems={FlexBoxAlignItems.Center}
          justifyContent={FlexBoxJustifyContent.Center}
        >
          {t('empty')}
        </FlexBox>
      )
    }
    return (
      <ResponsiveGridLayout columnsXL={2} columnsL={2} columnsM={2} columnsS={2}>
        <FlexBox direction={FlexBoxDirection.Column}>
          {!isNil(address?.streetName) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={t('street')}
              value={address.streetName}
            />
          )}
          {!isNil(address?.postalCode) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={t('postal-code')}
              value={address.postalCode}
            />
          )}
          {!isNil(address?.city) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={t('city')}
              value={address.city}
            />
          )}
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column}>
          {!isNil(address?.country?.name) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={t('country')}
              value={address.country.name}
            />
          )}
          {!isNil(address?.region?.name) && (
            <AnnualReviewDealOverviewBorrowerOverviewLabeledValue
              label={t('region')}
              value={address.region.name}
            />
          )}
        </FlexBox>
      </ResponsiveGridLayout>
    )
  }, [address, t])

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.listCard}>
      <Text className={styles.listCardTitle}>{t('title')}</Text>
      {content}
    </FlexBox>
  )
}

AnnualReviewDealOverviewBorrowerOverviewAddress.propTypes = {
  tileId: PropTypes.string.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default AnnualReviewDealOverviewBorrowerOverviewAddress
