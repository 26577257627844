import { lazy, Suspense, useContext } from 'react'
import LazyLoadingFallbackCard from 'components/ui/card/LazyLoadingFallbackCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import { DealContext } from 'routes/deals/DealContext'

const DealPropertiesAppreciationCard = lazy(() =>
  import(
    /* webpackChunkName: "DealPropertiesAppreciationCard" */ 'components/domains/deals/portfolio/appreciation/DealPropertiesAppreciationCard'
  ),
)

const DealPropertiesAppreciation = () => {
  const {
    deal: { dealUuid },
    allowedOperations,
  } = useContext(DealContext)

  return (
    <CWPLayout>
      <CWPLayout.Full>
        <Suspense fallback={<LazyLoadingFallbackCard />}>
          <DealPropertiesAppreciationCard
            dealUuid={dealUuid}
            allowedOperations={allowedOperations}
          />
        </Suspense>
      </CWPLayout.Full>
    </CWPLayout>
  )
}

export default DealPropertiesAppreciation
