import PropTypes from 'prop-types'
import { useMemo } from 'react'
import BusinessPartnerSearchDialog from 'components/domains/business-partners/BusinessPartnerSearchDialog'
import DealSearchDialog from 'components/domains/deals/deal-search/DealSearchDialog'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import MarketMultiSelectionDialog from 'components/domains/markets/market-selection/MarketMultiSelectionDialog'
import PropertiesSearchDialog from 'components/domains/properties/properties-search/dialog/PropertiesSearchDialog'

const PropertySelectDialog = ({
  open,
  value,
  lockedSelectedValues,
  defaultFilterCriteria,
  onChange,
  onClose,
}) => {
  const handleAccept = (newProperties) => {
    onChange?.(
      newProperties.map(({ uuid, description, id }) => ({
        id: uuid,
        name: description,
        displayId: id, // HINT: not explicitly supported by the component
      })),
    )
  }

  return (
    <PropertiesSearchDialog
      initialFilterCriteria={defaultFilterCriteria}
      initiallySelectedProperties={value.map(({ id, name, displayId }) => ({
        id: displayId,
        uuid: id,
        description: name,
      }))}
      lockedSelectedProperties={lockedSelectedValues?.map(({ id, name, displayId }) => ({
        id: displayId,
        uuid: id,
        description: name,
      }))}
      isOpen={open}
      onAccept={handleAccept}
      setIsOpen={(newOpen) => {
        if (!newOpen) onClose?.()
      }}
    />
  )
}

PropertySelectDialog.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      displayId: PropTypes.string,
    }).isRequired,
  ),
  lockedSelectedValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      displayId: PropTypes.string,
    }).isRequired,
  ),
  defaultFilterCriteria: PropTypes.shape({
    propertyName: PropTypes.string,
    propertyId: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    dealId: PropTypes.string,
    zipCode: PropTypes.string,
    marketId: PropTypes.string,
    financingStatusCode: PropTypes.arrayOf(PropTypes.string),
    cagStatusCode: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func,
  onClose: PropTypes.func,
}

const BusinessPartnerSelectDialog = ({ open, value, lockedSelectedValues, onChange, onClose }) => {
  const handleAccept = (newBusinessPartners) =>
    onChange(newBusinessPartners.map(({ id, name }) => ({ id, name })))

  return (
    <BusinessPartnerSearchDialog
      initialBusinessPartners={value}
      lockedSelectedBusinessPartners={lockedSelectedValues}
      open={open}
      type={'business-partner'}
      withCreateOption={false}
      onChange={handleAccept}
      onClose={onClose}
      isMultiSelect
    />
  )
}

BusinessPartnerSelectDialog.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ),
  lockedSelectedValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ),
  onChange: PropTypes.func,
  onClose: PropTypes.func,
}

const DealSelectDialog = ({ open, setIsOpen, onChange, onClose, value, lockedSelectedValues }) => {
  const namedDealReferences = useMemo(() => value?.filter(({ name }) => name) || [], [value])
  const namedInitialDealReferences = useMemo(
    () => lockedSelectedValues?.filter(({ name }) => name) || [],
    [lockedSelectedValues],
  )
  const otherReferences = useMemo(() => value?.filter(({ name }) => !name) || [], [value])
  const handleSelect = (deals) => {
    onChange([
      ...deals.map(({ dealUuid, dealName, dealId }) => ({
        id: dealUuid,
        name: dealName,
        displayId: dealId,
      })),
      ...otherReferences,
    ])
  }
  const initiallySelectedDeals = useMemo(
    () =>
      namedDealReferences.map(({ id, name, displayId } = {}) => ({
        dealUuid: id,
        dealName: name,
        dealId: displayId,
      })),
    [namedDealReferences],
  )
  const lockedSelectedDeals = useMemo(
    () =>
      namedInitialDealReferences?.map(({ id, name, displayId } = {}) => ({
        dealUuid: id,
        dealName: name,
        dealId: displayId,
      })),
    [namedInitialDealReferences],
  )
  return (
    <DealSearchDialog
      isOpen={open}
      setIsOpen={setIsOpen}
      onSelected={handleSelect}
      isMultiSelect={true}
      onClose={onClose}
      initiallySelectedDeals={initiallySelectedDeals}
      lockedSelectedDeals={lockedSelectedDeals}
    />
  )
}

DealSelectDialog.propTypes = {
  open: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ),
  lockedSelectedValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ),
}

const EntitySelectionDialog = ({
  type,
  open,
  openAdditionalDialog,
  value,
  lockedSelectedValues = [],
  additionalValue,
  defaultFilterCriteria,
  onChange,
  onAdditionalChange,
  onCancel,
  onClose,
  setIsOpen,
}) => {
  switch (type) {
    case DocumentTypes.Market:
      return (
        <MarketMultiSelectionDialog
          isOpen={open}
          selectedMarkets={value}
          onSelectionChange={(e) => {
            onChange?.(e)
          }}
          onCancel={(e) => {
            onCancel?.(e)
          }}
          onClose={onClose}
        />
      )

    case DocumentTypes.Property:
      return (
        <PropertySelectDialog
          open={open}
          value={value}
          lockedSelectedValues={lockedSelectedValues}
          defaultFilterCriteria={defaultFilterCriteria?.property}
          onChange={onChange}
          onCancel={onCancel}
          onClose={onClose}
        />
      )
    //Since there is currently no DealSearch dialog, the dialog is disabled for the time being
    //Because a Deal can have multiple properties attached to it, the Deal case will also incorporate the PropertyDialog
    case DocumentTypes.Deal:
      return openAdditionalDialog ? (
        <PropertySelectDialog
          open={openAdditionalDialog}
          value={additionalValue}
          onChange={onAdditionalChange}
          defaultFilterCriteria={defaultFilterCriteria?.property}
          onCancel={onCancel}
          onClose={onClose}
        />
      ) : (
        <DealSelectDialog
          open={open}
          onChange={onChange}
          onCancel={onCancel}
          onClose={onClose}
          setIsOpen={setIsOpen}
          value={value}
          lockedSelectedValues={lockedSelectedValues}
        />
      )

    case DocumentTypes.BusinessPartner:
      return (
        <BusinessPartnerSelectDialog
          open={open}
          value={value}
          lockedSelectedValues={lockedSelectedValues}
          onChange={onChange}
          onCancel={onCancel}
          onClose={onClose}
        />
      )
    default:
      throw new Error(`Unsupported entity type: ${type}`)
  }
}

EntitySelectionDialog.propTypes = {
  type: PropTypes.oneOf(Object.values(DocumentTypes)).isRequired,
  open: PropTypes.bool,
  openAdditionalDialog: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  lockedSelectedValues: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  additionalValue: PropTypes.any,
  defaultFilterCriteria: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    property: PropTypes.object,
  }),
  onChange: PropTypes.func,
  onAdditionalChange: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  setIsOpen: PropTypes.func,
}

export default EntitySelectionDialog
