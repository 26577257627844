import { Button, ButtonDesign, Icon, ToolbarSeparator } from '@fioneer/ui5-webcomponents-react'
import debounce from 'lodash.debounce'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/rent-roll/working-version/rental-units-table/PropertyRentalUnitsWorkingVersionTable.module.css'
import ToolbarSearching from 'components/ui/tables/toolbar/ToolbarSearching'

export const usePropertyRentalUnitsWorkingVersionAdditionalActions = ({
  tableRef,
  isNoRowSelected,
  handleAdd,
  handleDelete,
  setIsCollapseIconDisabled,
  isCollapseIconDisabled,
  setEditDialogIsOpen,
}) => {
  const { t: tButton } = useTranslation('translation', { keyPrefix: 'buttons' })

  const expandOrCollapseSubRows = useCallback(
    (isExpanded) => {
      tableRef?.current?.toggleAllRowsExpanded(isExpanded)
      setIsCollapseIconDisabled(!isExpanded)
    },
    [setIsCollapseIconDisabled, tableRef],
  )
  const resetRowStateBeforeFiltering = useCallback(
    (collapseSubRows = false) => {
      // Allows previously expanded rows with subcomponents to be expanded in their height when rendering them again after filtering
      delete tableRef?.current?.state?.subComponentsHeight
      if (collapseSubRows) {
        expandOrCollapseSubRows(false)
      }
    },
    [expandOrCollapseSubRows, tableRef],
  )

  const toggleFilterBySelected = () => {
    const selectedRows = tableRef?.current?.selectedFlatRows
    const isFilteredBySelected = tableRef?.current?.state.filters.some(
      (filter) => filter.id === 'rowKey',
    )
    resetRowStateBeforeFiltering(isFilteredBySelected)
    if (isFilteredBySelected) {
      tableRef?.current?.setAllFilters((oldFilters) =>
        oldFilters.filter((filter) => filter.id !== 'rowKey'),
      )
    } else {
      const rowKeys = selectedRows.map((row) => row.original.rowKey)
      tableRef?.current?.setFilter('rowKey', rowKeys.join(','))
    }
  }

  const toolbarSearching = useMemo(() => {
    const debounceValue = 300
    return {
      searchParam: '',
      onUpdateSearchParam: debounce((updatedValue) => {
        resetRowStateBeforeFiltering(!updatedValue)
        if (updatedValue) {
          tableRef?.current?.setFilter('searchBar', updatedValue)
        } else {
          tableRef?.current?.setAllFilters((oldFilters) =>
            oldFilters.filter((filter) => filter.id !== 'searchBar'),
          )
        }
      }, debounceValue),
    }
  }, [resetRowStateBeforeFiltering, tableRef])

  const additionalTableActions = [
    <ToolbarSearching searching={toolbarSearching} key={'search-rental-units'} />,
    <ToolbarSeparator key={'separator-1'} />,
    <Button
      key={'add-rental-unit-button'}
      id="rental-units-working-version-add-button"
      onClick={handleAdd}
      design={ButtonDesign.Transparent}
    >
      {tButton('add')}
    </Button>,
    <Button
      key={'edit-rental-units-button'}
      id="rental-units-working-version-edit-button"
      disabled={isNoRowSelected}
      onClick={() => setEditDialogIsOpen(true)}
      design={ButtonDesign.Transparent}
    >
      {tButton('edit')}
    </Button>,
    <Button
      key={'delete-rental-units-button'}
      id="rental-units-working-version-delete-button"
      disabled={isNoRowSelected}
      onClick={handleDelete}
      design={ButtonDesign.Transparent}
    >
      {tButton('delete')}
    </Button>,
    <ToolbarSeparator key={'separator-2'} />,
    <Icon
      className={styles.filterSelectedIcon}
      key={'filter-selected'}
      name="multi-select"
      disabled={isNoRowSelected}
      interactive
      onClick={() => toggleFilterBySelected()}
    />,
    <ToolbarSeparator key={'separator-3'} />,
    <Icon
      className={styles.collapseIcon}
      key={'collapse-subrows'}
      name="collapse-all"
      interactive
      onClick={() => expandOrCollapseSubRows(false)}
      disabled={isCollapseIconDisabled}
    />,
    <Icon
      className={styles.expandIcon}
      key={'expand-subrows'}
      name="expand-all"
      interactive
      onClick={() => expandOrCollapseSubRows(true)}
    />,
  ]
  return additionalTableActions
}
