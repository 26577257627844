import { MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cardStyles from 'components/ui/card/DisplayAndEditCard.module.css'
import DisplayCardView from 'components/ui/card/DisplayCardView'
import EditCardView from 'components/ui/card/EditCardView'
import EmptyCard from 'components/ui/card/EmptyCard'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import CWPCKEditor from 'components/ui/rich-text-editor/CWPCKEditor'
import FormattedTextView from 'components/ui/rich-text-editor/FormattedTextView'
import useUpdateEvent from 'hooks/services/business-events-and-tasks/events/useUpdateEvent'
import styles from 'routes/business-events-and-tasks/events/EventDetail.module.css'

const maxCharacterAmount = 1000

const EventDescriptionTile = ({ eventId, description, showEditButton, disabled }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.event-description' })
  const { t: tNoPrefix } = useTranslation()
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false)
  const [editedDescription, setEditedDescription] = useState(description)
  const [usedCharacters, setUsedCharacters] = useState(0)
  const queryClient = useQueryClient()
  const showMessageBox = useShowMessageBox()
  const { mutate: updateEvent } = useUpdateEvent()

  const onUsedCharactersChanged = useCallback((characterCount) => {
    setUsedCharacters(characterCount)
  }, [])

  const renderEditView = useCallback(
    () => (
      <div className={cardStyles.displayCardItem}>
        <CWPCKEditor
          setCurrentContent={setEditedDescription}
          currentContent={editedDescription}
          maxCharacterAmount={maxCharacterAmount}
          onUsedCharactersChanged={onUsedCharactersChanged}
        />
      </div>
    ),
    [editedDescription, onUsedCharactersChanged],
  )

  const displayView = useMemo(
    () => (
      <div className={cardStyles.displayCardItem}>
        <FormattedTextView formattedText={description} />
      </div>
    ),
    [description],
  )

  const onEditClicked = useCallback(() => {
    setIsEditModeEnabled(true)
  }, [])

  const onCancelClicked = useCallback(() => {
    setIsEditModeEnabled(false)
    setEditedDescription(description)
  }, [description])

  const onSaveSuccess = useCallback(() => {
    setIsEditModeEnabled(false)
    Modals.showToast({ children: tNoPrefix('toast.changes-saved') })
    queryClient.invalidateQueries(['events', eventId])
  }, [eventId, queryClient, tNoPrefix])

  const onSaveError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: tNoPrefix('components.cards.save-error'),
    })
  }, [showMessageBox, tNoPrefix])

  const onSaveClicked = useCallback(() => {
    updateEvent(
      { eventId, info: { description: editedDescription } },
      {
        onSuccess: onSaveSuccess,
        onError: onSaveError,
      },
    )
  }, [editedDescription, eventId, onSaveError, onSaveSuccess, updateEvent])

  const fieldDefinitions = useMemo(
    () => [
      {
        customInfoComponent: displayView,
        renderCustomEditComponent: renderEditView,
      },
    ],
    [displayView, renderEditView],
  )

  const isSaveButtonEnabled = useMemo(
    () => description !== editedDescription && usedCharacters <= maxCharacterAmount,
    [description, editedDescription, usedCharacters],
  )

  if (isEditModeEnabled) {
    return (
      <>
        <EditCardView
          cardHeaderTitle={t('title')}
          fieldDefinitions={fieldDefinitions}
          onSaveClicked={onSaveClicked}
          onCancelClicked={onCancelClicked}
          setEditModeHasChanges={() => {}}
          editModeHasChanges={isSaveButtonEnabled}
          className={disabled ? styles.disabled : ''}
          {...(!!disabled && { inert: '' })}
        />
      </>
    )
  }

  if (isEmpty(description)) {
    return (
      <EmptyCard
        cardHeaderTitle={t('title')}
        onEditClicked={onEditClicked}
        showCardHeaderActions={showEditButton}
        className={disabled ? styles.disabled : ''}
        {...(!!disabled && { inert: '' })}
      />
    )
  }
  return (
    <DisplayCardView
      cardHeaderTitle={t('title')}
      fieldDefinitions={fieldDefinitions}
      setEditMode={onEditClicked}
      isEditable={showEditButton}
      className={disabled ? styles.disabled : ''}
      {...(!!disabled && { inert: '' })}
    />
  )
}

EventDescriptionTile.propTypes = {
  disabled: PropTypes.bool.isRequired,
  eventId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  showEditButton: PropTypes.bool.isRequired,
}

export default EventDescriptionTile
