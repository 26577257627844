import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDecisionSetupTemplates = ({ eventCode }) => {
  const params = new URLSearchParams()
  params.append('event_code', eventCode)

  return useCamelizedResponse(
    useRequest({
      path: `/events/decision-setup-templates?${params.toString()}`,
      useCache: true,
      keys: ['events', 'decision-setup-templates', eventCode],
    }),
  )
}

export default useDecisionSetupTemplates
