import { MessageStripDesign, Option, Select } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { decisionPaperSynchronizationStatus } from 'api/decision-paper/decisionPaperApi'
import CloseableMessageStrip from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/feedback/CloseableMessageStrip'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/unit-overview/UnitSelectionTile.module.css'
import { setTileMetadata } from 'redux/slices/decision-paper/tilesOverviewSlice'
import DecisionPaperContext from 'routes/business-events-and-tasks/decision-paper/DecisionPaperContext'

const UnitSelectionTile = ({ tileId, isPdfView }) => {
  const { options = [], selectedUnit: selectedUnitFromHook } =
    useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId].data) ?? {}

  const { decisionPaper: { synchronizationStatus } = {} } = useContext(DecisionPaperContext) ?? {}
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.unit-selection',
  })

  const isDecisionPaperFrozenOrStopped =
    synchronizationStatus === decisionPaperSynchronizationStatus.frozen ||
    synchronizationStatus === decisionPaperSynchronizationStatus.stopped

  const dispatch = useDispatch()

  // set a default selected unit of null,
  // which is also used to disable the loading state of other tiles consuming the metadata, if it is not overwritten
  useEffect(() => {
    dispatch(
      setTileMetadata({
        tileCode: availableAutomaticTiles.unitSelectionTile,
        metadata: { selectedUnit: null },
      }),
    ) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const preSelectedUnit = useMemo(
    () =>
      isNil(selectedUnitFromHook?.typeId)
        ? {
            typeId: options[0]?.unitTypeId,
            headId: options[0]?.unitHeadBpId,
          }
        : selectedUnitFromHook,
    [options, selectedUnitFromHook],
  )

  const { selectedUnit: selectedUnitMetadata } =
    useSelector(
      (state) =>
        state.decisionPaper.tilesOverview.tileMetadata?.[availableAutomaticTiles.unitSelectionTile],
    ) ?? {}

  const selectedUnit = isDecisionPaperFrozenOrStopped ? preSelectedUnit : selectedUnitMetadata

  useEffect(() => {
    if (!isNil(preSelectedUnit?.typeId) && isNil(selectedUnit?.typeId)) {
      dispatch(
        setTileMetadata({
          tileCode: availableAutomaticTiles.unitSelectionTile,
          metadata: { selectedUnit: preSelectedUnit },
        }),
      )
    }
  }, [dispatch, preSelectedUnit, selectedUnit])

  if (isPdfView) {
    const selectedUnitName = options.find(
      ({ unitTypeId, unitHeadBpId } = {}) =>
        unitTypeId === selectedUnit?.typeId && unitHeadBpId === selectedUnit?.headId,
    )?.unitHeadBpFullName
    return selectedUnitName ? (
      <div className={styles.unitSelectionWrapper}>{selectedUnitName ?? ''}</div>
    ) : (
      <div className={styles.unitSelectionWrapper}>
        <CloseableMessageStrip design={MessageStripDesign.Information} hideCloseButton={true}>
          {t('pdf-view')}
        </CloseableMessageStrip>
      </div>
    )
  }

  return (
    <Select
      onChange={(e) => {
        const newSelectedUnit = {
          typeId: e.detail.selectedOption.dataset.type,
          headId: e.detail.selectedOption.dataset.head,
        }
        dispatch(
          setTileMetadata({
            tileCode: availableAutomaticTiles.unitSelectionTile,
            metadata: { selectedUnit: newSelectedUnit },
          }),
        )
      }}
      disabled={isDecisionPaperFrozenOrStopped}
      className={styles.select}
    >
      {options
        .filter(({ standalone }) => !standalone)
        .map(
          ({
            unitHeadBpFullName: headName,
            unitHeadBpId: headId,
            unitTypeId: typeId,
            unitTypeName: typeName,
          }) => (
            <Option
              key={typeId + headId}
              selected={selectedUnit?.typeId === typeId && selectedUnit?.headId === headId}
              additionalText={typeName}
              data-type={typeId}
              data-head={headId}
            >
              {headName}
            </Option>
          ),
        )}
    </Select>
  )
}

UnitSelectionTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default UnitSelectionTile
