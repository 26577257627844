import camelize from 'camelize'
import { useRequest } from 'hooks/services/baseService'
import rightsSchema from 'hooks/services/collaterals/rightsSchema'

/**
 * @param {string} [id] Right ID
 * @param {string} [collateralId] Collateral ID
 */
const useRightById = (rightId, collateralId = '') => {
  const keys = ['rights', rightId, collateralId]
  const path = collateralId ? `/rights/${rightId}?cagId=${collateralId}` : `/rights/${rightId}`

  const result = useRequest({
    keys,
    path,
    options: { enabled: !!rightId },
  })

  return {
    ...result,
    data: rightsSchema.parse(camelize(result.data)),
  }
}

export default useRightById
