import { IllustratedMessage } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import RiskFigurePropTypes from 'components/domains/business-partners/exposure/RiskFigurePropTypes'
import Card from 'components/ui/card/Card'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import styles from 'routes/business-partners/GroupExposureBusinessPartnerTable.module.css'
import useGroupExposureBusinessPartnerTableColumnDefinitions from 'routes/business-partners/useGroupExposureBusinessPartnerTableColumnDefinitions'

const NoData = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner' })

  return (
    <IllustratedMessage
      name="NoData"
      titleText={t('no-data-title')}
      subtitleText={t('no-data-text')}
    />
  )
}

const GroupExposureBusinessPartnerTable = ({
  tableData,
  riskFigureDisplayNamesMap,
  groupExposure,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner' })

  const columnDefinitions = useGroupExposureBusinessPartnerTableColumnDefinitions(
    riskFigureDisplayNamesMap,
    groupExposure,
  )

  return (
    <Card>
      <AnalyticalTableWithToolbar
        className={styles.tableMargin}
        title={t('by-bp-table-title')}
        columns={columnDefinitions}
        //The total row is counted as an entry, so we need to subtract it from the total number of entries
        nrOfEntries={Math.max(tableData.length - 1, 0)}
        data={tableData}
        rowHeight={50}
        headerRowHeight={30}
        sortable={false}
        NoDataComponent={NoData}
      />
    </Card>
  )
}

GroupExposureBusinessPartnerTable.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      currentExposure: PropTypes.shape({ value: PropTypes.number, unit: PropTypes.string }),
      grossFutureExposure: PropTypes.shape({ value: PropTypes.number, unit: PropTypes.string }),
      grossFutureExposureLimit: PropTypes.shape({
        value: PropTypes.number,
        unit: PropTypes.string,
      }),
      netFutureExposure: PropTypes.shape({ value: PropTypes.number, unit: PropTypes.string }),
      settlementRisk: PropTypes.shape({ value: PropTypes.number, unit: PropTypes.string }),
      settlementRiskLimit: PropTypes.shape({ value: PropTypes.number, unit: PropTypes.string }),
    }).isRequired,
  ),
  riskFigureDisplayNamesMap: RiskFigurePropTypes.RiskFiguresDisplay.isRequired,
  groupExposure: RiskFigurePropTypes.RiskFigures.isRequired,
}

export default GroupExposureBusinessPartnerTable
