import z from 'zod'

export const CUSTOM_FIELD_TYPES = Object.freeze({
  STRING: 'STRING',
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  ENUM: 'ENUM',
  NUMBER: 'NUMBER',
  INTEGER: 'INTEGER',
})

export const customFieldTypeSchema = z.enum([
  CUSTOM_FIELD_TYPES.STRING,
  CUSTOM_FIELD_TYPES.BOOLEAN,
  CUSTOM_FIELD_TYPES.DATE,
  CUSTOM_FIELD_TYPES.ENUM,
  CUSTOM_FIELD_TYPES.NUMBER,
  CUSTOM_FIELD_TYPES.INTEGER,
])

/**
 * The JVM uses '_' instead of '-'.
 *
 * This schema supports both as input, validates the locale against the list known to the browser
 * and outputs the JVM format.
 *
 * If you want to use the locale in the browser, you need to replace '_' with '-'.
 */
export const localeSchema = z
  .string()
  .transform((value) => value.replace('_', '-'))
  .refine((value) => {
    try {
      // HINT: the list of supported locales differs between browsers and the JVM
      //       => this check might not be 100% accurate
      new Intl.Locale(value)
      return true
    } catch (e) {
      return false
    }
  })
  .transform((value) => value.replace('-', '_'))

const translationSchema = z.object({
  locale: localeSchema,
  shortText: z.string(),
})

const enumValueSchema = z.object({
  code: z.string(),
  translations: z
    .array(translationSchema)
    .optional()
    .transform((value) => value ?? []),
})

const customFieldConfigValueSchema = z.object({
  key: z.string(),
  type: customFieldTypeSchema,
  display: z.boolean(),
  translations: z
    .array(translationSchema)
    .optional()
    .transform((value) => value ?? []),
  enumValues: z
    .array(enumValueSchema)
    .optional()
    .transform((value) => value ?? []),
})
/** @typedef {import('zod').TypeOf<typeof customFieldConfigValueSchema>} CustomFieldConfigValue */

const customEntityConfigSchema = z.object({
  entityType: z.string(),
  customFieldConfigurations: z.array(customFieldConfigValueSchema),
})

export const customEntityConfigurationsSchema = z.object({
  supportedLocales: z.array(localeSchema),
  defaultLocale: localeSchema,
  customEntityConfigurations: z.array(customEntityConfigSchema),
})
/** @typedef {import('zod').TypeOf<typeof customEntityConfigurationsSchema>} CustomEntityConfigurations */

const customFieldSchema = z.union([
  // STRING, DATE, ENUM
  z.object({ key: z.string(), value: z.string().nullable() }),
  // BOOLEAN
  z.object({ key: z.string(), value: z.boolean().nullable() }),
  // NUMBER
  z.object({ key: z.string(), value: z.number().nullable() }),
  // INTEGER
  z.object({ key: z.string(), value: z.number().int().nullable() }),
])
/** @typedef {import('zod').TypeOf<typeof customFieldSchema>} CustomField */

export const customEntityResponseSchema = z.object({
  entityType: z.string(),
  createdBy: z.string().nullable(),
  lastUpdatedBy: z.string().nullable(),
  createdAt: z.string().nullable(),
  lastUpdatedAt: z.string().nullable(),
  customFields: z.array(customFieldSchema),
})
/** @typedef {import('zod').TypeOf<typeof customEntityResponseSchema>} CustomEntityResponse */

export const customEntityRequestSchema = z.object({
  entityType: z.string(),
  customFields: z.array(customFieldSchema),
})
/** @typedef {import('zod').TypeOf<typeof customEntityRequestSchema>} CustomEntityRequest */

const customFieldEnumMetadataSchema = z.object({
  code: z.string(),
  displayName: z.string(),
})

const customFieldMetadataSchema = z.object({
  key: z.string(),
  type: customFieldTypeSchema,
  display: z.boolean(),
  displayName: z.string(),
  maxLength: z.number().int().min(0).nullish(), // HINT: Could be converted into a union, because maxLength only exists for STRING
  enumValues: z
    .array(customFieldEnumMetadataSchema)
    .optional()
    .transform((value) => value ?? []),
})
/** @typedef {import('zod').TypeOf<typeof customFieldMetadataSchema>} CustomFieldMetadata */

export const customEntityMetadataSchema = z.object({
  entityType: z.string(),
  customFieldMetadata: z.array(customFieldMetadataSchema),
})
/** @typedef {import('zod').TypeOf<typeof customEntityMetadataSchema>} CustomEntityMetadata */

export const customEntitiesMetadataSchema = z.object({
  customEntityMetadata: z.array(customEntityMetadataSchema).nullish(),
})
/** @typedef {import('zod').TypeOf<typeof customEntitiesMetadataSchema>} CustomEntitiesMetadata */
