import { CheckBox } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearTaskSelection, selectAllTasks } from 'redux/slices/tasks/taskTableSlice'

const TasksTableSelectAllCheckbox = ({ tasks }) => {
  const dispatch = useDispatch()
  const selectedTasksCount = useSelector(
    (state) => Object.values(state.tasks.taskTable.selectedRows.selectedTaskIds).length,
  )

  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false)

  useEffect(() => {
    setIsSelectAllChecked(selectedTasksCount === tasks.length && tasks.length !== 0)
  }, [selectedTasksCount, tasks.length])

  const handleSelectAllEventsCheckboxChanged = ({ target: { checked: isCheckboxChecked } }) => {
    setIsSelectAllChecked(isCheckboxChecked)
    isCheckboxChecked ? dispatch(selectAllTasks(tasks)) : dispatch(clearTaskSelection())
  }

  return (
    <CheckBox
      disabled={tasks.length === 0}
      checked={isSelectAllChecked}
      onChange={handleSelectAllEventsCheckboxChanged}
    />
  )
}

TasksTableSelectAllCheckbox.propTypes = {
  tasks: PropTypes.array.isRequired,
}

export default TasksTableSelectAllCheckbox
