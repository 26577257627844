import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  ValueState,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { arrearsInApprovalFilters } from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/shared/toolbar/arrearsInApprovalPropTypes'
import NumberFilterInput from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/shared/toolbar/input/ArrearsInApprovalNumberFilterInput'

const propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.shape({
      lowerBound: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      upperBound: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    PropTypes.number,
    PropTypes.string,
  ]),
  selectedFilters: arrearsInApprovalFilters.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  columnKey: PropTypes.string.isRequired,
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const BetweenNumbersFilter = ({ value, selectedFilters, setSelectedFilters, columnKey }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.arrears-in-approval.components.toolbar.bounds',
  })
  value = typeof value === 'number' ? { lowerBound: value, upperBound: value } : value
  const hasLowerBound = !isNil(value?.lowerBound) || !isEmpty(value?.lowerBound)
  const hasUpperBound = !isNil(value?.upperBound) || !isEmpty(value?.upperBound)
  const upperBoundSmallerThanLowerBound =
    hasLowerBound &&
    hasUpperBound &&
    (value?.upperBound ?? Infinity) < (value?.lowerBound ?? -Infinity)

  return (
    <FlexBox
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      alignItems={FlexBoxAlignItems.Center}
      style={{ width: '100%', gap: '10px' }}
    >
      <NumberFilterInput
        value={value?.lowerBound || undefined}
        targetName={'lowerBound'}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        columnKey={columnKey}
      />
      <span style={{ flex: '0 0 auto' }}>-</span>
      <NumberFilterInput
        value={value?.upperBound || undefined}
        targetName={'upperBound'}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        columnKey={columnKey}
        valueState={upperBoundSmallerThanLowerBound ? ValueState.Warning : ValueState.None}
        valueStateMessage={<Text>{t('warning')}</Text>}
      />
    </FlexBox>
  )
}

BetweenNumbersFilter.propTypes = propTypes

export default BetweenNumbersFilter
