import {
  List,
  ListMode,
  ListSeparators,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  StandardListItem,
  Text,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-process/setups/DecisionProcessSelectSetupList.module.css'

const DecisionProcessSelectSetupList = ({
  onSelectionChange,
  decisionSetupTemplates,
  selectedSetupTemplateId,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.select-setup.dialog',
  })

  const getDecisionSetupDescription = (stageTemplates) =>
    t('setup.description', {
      stageCount: stageTemplates.length,
      stageNames: stageTemplates.map(({ name }) => name).join(', '),
    })

  const handleSelectionChanged = ({ detail: { targetItem: selectedItem } }) => {
    onSelectionChange(selectedItem.getAttribute('data-setup-id'))
  }

  return (
    <List
      onSelectionChange={handleSelectionChanged}
      mode={ListMode.SingleSelectBegin}
      separators={ListSeparators.Inner}
      className={styles.list}
    >
      {decisionSetupTemplates.map(({ id, name, stageTemplates }) => (
        <StandardListItem
          key={id}
          className={styles.listItem}
          data-setup-id={id}
          selected={id === selectedSetupTemplateId}
        >
          <FlexBox
            direction={FlexBoxDirection.Column}
            alignItems={FlexBoxAlignItems.Start}
            className={styles.listItemContainer}
          >
            <Title level={TitleLevel.H6} className={styles.itemHeader}>
              {name}
            </Title>
            <Text className={styles.itemDescription}>
              {getDecisionSetupDescription(stageTemplates)}
            </Text>
          </FlexBox>
        </StandardListItem>
      ))}
    </List>
  )
}

DecisionProcessSelectSetupList.propTypes = {
  onSelectionChange: PropTypes.func.isRequired,
  decisionSetupTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      stageTemplates: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string.isRequired,
          decisionType: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  selectedSetupTemplateId: PropTypes.string,
}

export default DecisionProcessSelectSetupList
