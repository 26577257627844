import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import DecisionProcessLabeledLoadingContent from 'components/domains/business-events-and-tasks/decision-process/alc/helper/DecisionProcessLabeledLoadingContent'
import useDeal from 'hooks/services/deals/useDeal'

const dash = '-'

const DecisionProcessAlcApplicationType = ({ dealUuid }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.overview.alc-info.dialog',
  })
  const dealResponse = useDeal(dealUuid)
  const renderApplicationType = useCallback((deal) => deal.applicationTypeCode || dash, [])
  return (
    <DecisionProcessLabeledLoadingContent
      response={dealResponse}
      renderCallback={renderApplicationType}
      label={t('fields.application-type')}
    />
  )
}

DecisionProcessAlcApplicationType.propTypes = {
  dealUuid: PropTypes.string.isRequired,
}

export default DecisionProcessAlcApplicationType
