import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import useMMyyyy from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/hooks/useMMyyyy'
import CustomFieldsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/CriticalFacilityTileCustomFieldsTile'
import { setTileMetadata } from 'redux/slices/decision-paper/tilesOverviewSlice'

const propTypes = {
  tileId: PropTypes.string.isRequired,
}
/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const CriticalFacilityTile = ({ tileId }) => {
  const {
    reportingSummaryFieldName,
    followUpDateFieldName,
    orderedAndFilteredFieldMetadata,
    orderedAndFilteredCustomFieldData,
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId].data)

  // HINT: Store the parsed config to be used inside the proposal tile
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      setTileMetadata({
        tileCode:
          availableAutomaticTiles.monitoredRiskIndicatorRegulatoryChecksCurrentCriticalFacilityStatus,
        metadata: {
          reportingSummaryFieldName,
          followUpDateFieldName,
          orderedAndFilteredFieldMetadata,
        },
      }),
    )
  }, [dispatch, followUpDateFieldName, orderedAndFilteredFieldMetadata, reportingSummaryFieldName])

  const { format: formatMMyyyy, pattern } = useMMyyyy()

  /** @typedef {NonNullable<Parameters<typeof CustomFieldsTile>[0]['refineFieldDefinitions']>[string]} refineFieldDefinition */

  const refineFollowUpDateField = followUpDateFieldName
    ? {
        [followUpDateFieldName]:
          /** @type {refineFieldDefinition} */
          (fieldDefinition) => ({
            ...fieldDefinition,
            editComponentProps: {
              formatPattern: pattern,
            },
            value: formatMMyyyy(fieldDefinition.rawValue),
            formattedValue: formatMMyyyy(fieldDefinition.rawValue) || '-',
          }),
      }
    : {}

  return (
    <CustomFieldsTile
      customFields={orderedAndFilteredCustomFieldData ?? []}
      config={orderedAndFilteredFieldMetadata ?? []}
      refineFieldDefinitions={{
        ...refineFollowUpDateField,
      }}
      fieldDefinitionOverrides={{
        isShownInDisplay: true,
        isShownInEdit: false,
      }}
      cardHeaderTitle=""
      saveChanges={() => {}}
    />
  )
}

CriticalFacilityTile.propTypes = propTypes

export default CriticalFacilityTile
