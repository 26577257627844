import { FlexBox, FlexBoxDirection, Link } from '@fioneer/ui5-webcomponents-react'
import parse from 'html-react-parser'
import orderBy from 'lodash.orderby'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/custom-assessment/sensitivity-analysis/SensitivityAnalysisTile.module.css'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/custom-assessment/sensitivity-analysis/shared/EmptyCardContent'
import ImageView from 'components/domains/business-events-and-tasks/decision-paper/tiles/custom-assessment/sensitivity-analysis/shared/ImageView'

const characterAmount = 1000
const SensitivityAnalysisTile = ({ tileId, selectedDealIndex }) => {
  const { t } = useTranslation('decisionPaper')
  const { data: { multipleSensitivityAnalysis = [] } = {} } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId],
  )
  const { sensitivityAnalysis } = multipleSensitivityAnalysis?.[selectedDealIndex] ?? {}
  const lastSensitivityAnalysis = orderBy(
    sensitivityAnalysis?.versions,
    (v) => v.updatedAt,
    'desc',
  )[0]?.text

  const [isTextExpanded, setIsTextExpanded] = useState(false)

  const toggleShowMore = () => {
    setIsTextExpanded(!isTextExpanded)
  }
  const renderContent = () =>
    lastSensitivityAnalysis?.length <= characterAmount || isTextExpanded
      ? lastSensitivityAnalysis
      : lastSensitivityAnalysis?.substring(0, characterAmount)

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.commentWrapper}>
      {lastSensitivityAnalysis ? (
        <div className={styles.wrapper}>
          <div className={`ck-content ${styles.contentWrapper}`}>
            {parse(renderContent(), {
              replace: ({ name, attribs }) => {
                if (name === 'img') {
                  return <ImageView {...attribs} />
                }
              },
            })}
          </div>
          {lastSensitivityAnalysis?.length > characterAmount && (
            <Link className={styles.toggleShowMoreLink} onClick={toggleShowMore}>
              {isTextExpanded ? t('buttons.less') : t('buttons.more')}
            </Link>
          )}
        </div>
      ) : (
        <EmptyCardContent />
      )}
    </FlexBox>
  )
}

SensitivityAnalysisTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default SensitivityAnalysisTile
