const isNil = require('lodash.isnil')
const { useEffect } = require('react')

const useTableInputSelectionWorkaround = (ref) => {
  useEffect(() => {
    const htmlElement = ref?.current
    if (isNil(htmlElement)) {
      return
    }

    const keyDownListener = (event) => {
      if (event.key === 'a' && (event.metaKey || event.ctrlKey)) {
        event.stopPropagation()
      }
    }
    htmlElement.addEventListener('keydown', keyDownListener)
    return () => htmlElement.removeEventListener('keydown', keyDownListener)
  }, [ref])
}

export default useTableInputSelectionWorkaround
