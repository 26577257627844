import PropTypes from 'prop-types'
import { useCallback, useMemo, useContext } from 'react'
import { conditionTypes, conditionsStatusTypes } from 'api/conditions/conditions'
import TableStatusCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/view/TableStatusCell'
import useDeprecatedValues from 'hooks/common/useDeprecatedValues'
import useExternalStatus from 'hooks/services/conditions/config/useExternalStatus'
import useInternalStatus from 'hooks/services/conditions/config/useInternalStatus'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const noOp = () => {}

const ConditionsTableStatusCellV1 = ({
  value: {
    status: { type: statusType, code: statusCode, name: statusName },
    requirements,
  },
  conditionType,
}) => {
  const {
    entityRef: { entityType },
  } = useContext(ConditionsContext)

  const { data: { status: availableExternalStatusValues = [] } = {}, ...useExternalStatusResult } =
    useExternalStatus(
      {
        includeDeprecated: false,
        entityType,
      },
      { enabled: conditionType === conditionTypes.external },
    )

  const { data: { status: availableInternalStatusValues = [] } = {}, ...useInternalStatusResult } =
    useInternalStatus(
      {
        includeDeprecated: false,
        entityType,
      },
      { enabled: conditionType === conditionTypes.internal },
    )

  const [availableStatusValues, useStatusResult] = useMemo(
    () =>
      conditionType === conditionTypes.external
        ? [availableExternalStatusValues, useExternalStatusResult]
        : [availableInternalStatusValues, useInternalStatusResult],
    [
      availableExternalStatusValues,
      availableInternalStatusValues,
      conditionType,
      useExternalStatusResult,
      useInternalStatusResult,
    ],
  )

  const { deprecatedValues } = useDeprecatedValues({
    availableValues: availableStatusValues,
    selectedValues: [{ name: statusName, code: statusCode }],
  })
  const statusData = useMemo(
    () => ({
      ...useStatusResult,
      data: {
        status: [...deprecatedValues, ...availableStatusValues],
      },
    }),
    [availableStatusValues, deprecatedValues, useStatusResult],
  )
  const statusLoader = useCallback(() => statusData, [statusData])

  return (
    <TableStatusCell
      showEmptyOption={false}
      statusType={statusType}
      statusName={statusName}
      requirements={requirements}
      loadingHook={statusLoader}
      onChange={noOp}
      isEditMode={false}
    />
  )
}

ConditionsTableStatusCellV1.propTypes = {
  value: PropTypes.shape({
    conditionId: PropTypes.string.isRequired,
    status: PropTypes.shape({
      type: PropTypes.oneOf(Object.values(conditionsStatusTypes)).isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }).isRequired,
    requirements: PropTypes.shape({
      total: PropTypes.number.isRequired,
      done: PropTypes.number.isRequired,
    }),
  }).isRequired,
  conditionType: PropTypes.string.isRequired,
}

export default ConditionsTableStatusCellV1
