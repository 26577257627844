import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import MarketMacroProxiesCard from 'components/domains/markets/detail/cards/MarketMacroProxiesCard'
import MarketMicroProxiesCard from 'components/domains/markets/detail/cards/MarketMicroProxiesCard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'

const MarketDetailManager = ({ marketId, isLoading, isError, error }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.markets.detail' })

  const renderMarketDetailSummary = useCallback(
    () => (
      <CWPLayout>
        <CWPLayout.Full>
          <MarketMicroProxiesCard marketId={marketId} />
        </CWPLayout.Full>
        <CWPLayout.Full>
          <MarketMacroProxiesCard marketId={marketId} />
        </CWPLayout.Full>
      </CWPLayout>
    ),
    [marketId],
  )

  return (
    <LoadingStateWrapper
      isError={isError && !isMissingPermissionError(error)}
      isLoading={isLoading}
      isNotFoundError={isError && isNotFoundError(error)}
      renderContent={renderMarketDetailSummary}
      errorDescription={t('error.description')}
      errorDetails={t('error.details')}
      errorTitle={t('error.title')}
    />
  )
}

MarketDetailManager.propTypes = {
  marketId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  error: PropTypes.shape({ response: PropTypes.shape({ status: PropTypes.number }) }),
}

export default MarketDetailManager
