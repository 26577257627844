import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js'
import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { covenantPermissions } from 'api/deals/covenantPermissions'
import { CardHeaderLastEditedText } from 'components/domains/deals/card/CardHeaderLastEditedText'
import CovenantInformationDisplay from 'components/domains/deals/covenants/general-information/CovenantInformationDisplay'
import CovenantInformationEdit from 'components/domains/deals/covenants/general-information/CovenantInformationEdit'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import Card from 'components/ui/card/Card'
import { ToggleEditMode } from 'components/ui/card/CardHeaderWithEditMode'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import useCovenantById from 'hooks/services/deals/covenants/useCovenantById'
import useUpdateCovenant from 'hooks/services/deals/covenants/useUpdateCovenant'
import usePatchDocumentReferences, {
  patchDocumentReferencesOperations,
} from 'hooks/services/documents/usePatchDocumentReferences'
import { CovenantContext } from 'routes/business-partners/CovenantContext'
import { DealContext } from 'routes/deals/DealContext'

const CovenantGeneralInformationCard = ({ covenantId }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.covenants' })
  const showErrorMessageBox = useShowErrorMessageBox()

  const { deal: { dealUuid } = {} } = useContext(DealContext)
  const { allowedOperations: covenantAllowedOperations = [] } = useContext(CovenantContext)
  const isAllowedToUpdateCovenant = covenantAllowedOperations.includes(
    covenantPermissions.updateContractCovenant,
  )

  const [isEditMode, setIsEditMode] = useState(false)

  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()

  const form = useForm({ mode: 'onBlur' })
  const {
    formState: { isValid },
    handleSubmit,
  } = form

  const [covenantDocumentSaveActions, setCovenantDocumentSaveActions] = useState({
    allDocIDsToBeLinked: [],
    allDocIDsToBeUnlinked: [],
  })
  const {
    isLoading,
    isError,
    data: { lastUpdatedAt, lastUpdatedBy, ...covenant } = {},
  } = useCovenantById(covenantId)

  const onUpdateSuccess = () => {
    showToast({ children: t('update.success') }, document.body)
    queryClient.invalidateQueries(['covenants', covenantId])
    queryClient.invalidateQueries(['documents', DocumentTypes.Deal, dealUuid])
    setIsEditMode(false)
  }
  const onCreationError = async (error) => {
    const { errors: [errorResponse] = [] } = await error.response.json()
    showErrorMessageBox({ message: t('update.error'), error: errorResponse })
  }
  const updateCovenant = useUpdateCovenant({
    onSuccess: onUpdateSuccess,
    onError: onCreationError,
  })
  const onSubmit = async (updatedCovenant) => {
    updateCovenant.mutate({
      covenant: { ...updatedCovenant, mainEntity: { type: mainEntityTypes.DEAL, id: dealUuid } },
      dealUuid,
    })
  }
  const submitForm = handleSubmit(onSubmit)

  const patchDocumentReferences = usePatchDocumentReferences({
    // onSuccess: handlePatchDocumentsSuccess, //no separate success message for covenant document
    onError: () => {
      // documents service does not respond with error information
      showErrorMessageBox({ message: t('update-document.error') })
    },
  })

  const onSaveChanges = () => {
    // Link and/or Unlink documents to covenant if needed
    if (
      covenantDocumentSaveActions?.allDocIDsToBeLinked?.length > 0 ||
      covenantDocumentSaveActions?.allDocIDsToBeUnlinked?.length > 0
    ) {
      covenantDocumentSaveActions?.allDocIDsToBeLinked?.forEach((docId) => {
        patchDocumentReferences.mutate({
          documentId: docId,
          entityRefs: [{ type: DocumentTypes.Covenant, id: covenantId }],
          operation: patchDocumentReferencesOperations.add,
        })
      })
      covenantDocumentSaveActions?.allDocIDsToBeUnlinked?.forEach((docId) => {
        patchDocumentReferences.mutate({
          documentId: docId,
          entityRefs: [{ type: DocumentTypes.Covenant, id: covenantId }],
          operation: patchDocumentReferencesOperations.remove,
        })
      })
    }

    // submit other values
    submitForm()
  }

  const onCancelChanges = () => {
    setIsEditMode(false)
  }

  return (
    <Card
      header={
        <CardHeaderLastEditedText
          title={t('general-information.title')}
          email={lastUpdatedBy}
          timestamp={lastUpdatedAt}
          actions={
            !isLoading &&
            !isError &&
            isAllowedToUpdateCovenant && (
              <ToggleEditMode
                isEditMode={isEditMode}
                onToggle={() => setIsEditMode(true)}
                onSave={onSaveChanges}
                onCancel={onCancelChanges}
                buttonIdPrefix="covenant-general-information"
                checkSaveDisabled={() => !isValid}
              />
            )
          }
        />
      }
    >
      <LoadingStateWrapper isLoading={!!isLoading} isError={!!isError}>
        {isEditMode ? (
          <FormProvider {...form}>
            <CovenantInformationEdit
              covenant={covenant}
              onSelectedDocumentsChanged={(saveActions) => {
                setCovenantDocumentSaveActions(saveActions)
              }}
            />
          </FormProvider>
        ) : (
          <CovenantInformationDisplay covenant={covenant} />
        )}
      </LoadingStateWrapper>
    </Card>
  )
}

CovenantGeneralInformationCard.propTypes = {
  covenantId: PropTypes.string.isRequired,
}

export default CovenantGeneralInformationCard
