import { ComboBox, ComboBoxItem } from '@fioneer/ui5-webcomponents-react'
import find from 'lodash.find'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * Renders a ComboBox with support for a hook using `useQuery` to load the
 * dropdown values. Loading and error messages are rendered based on the
 * `useQuery` return values.
 *
 * @typedef {object} overrides
 * @property {string} overrides.id the id of the `<select>` node
 * @property {Function} overrides.useLoader the hook to load data with `useQuery`. The response must
 * be a list of objects with the `code` and `displayName` keys.
 * @property {any} [overrides.input] parameter used by the given useLoader hook
 * @property {string} [overrides.initialComboBoxValue] initial ComboBox value can be set to empty string
 * @property {object} [overrides.value] The selected value incl. code and displayName
 * @property {string} [overrides.value.code] The code of the selected value.
 * @property {string} [overrides.value.displayName] The displayName of the selected value.
 * @property {(value: {code: string, displayName: string} | undefined) => void} overrides.onChange the change handler
 * @property {string} [overrides.loadingText] the loading message
 * @property {string} [overrides.errorText] the error message
 * @property {Function} [overrides.onBlur] the blur handler
 * @param {Omit<Parameters<typeof ComboBox>[0], keyof overrides> & overrides} props is referenced by the ComboBox component
 */
const LoadingComboBox = ({
  id,
  useLoader,
  input,
  value = { code: '', displayName: '' },
  onChange,
  loadingText,
  errorText,
  onBlur,
  ...props
}) => {
  const { data, isError, isLoading } = useLoader(input)
  const { t } = useTranslation('translation', { keyPrefix: 'components.loading-combo-box' })

  const loadingMessage = loadingText || t('loading')
  const errorMessage = errorText || t('error')

  const options = useMemo(() => {
    if (isLoading) {
      return [<ComboBoxItem key="loading" data-id="" text={loadingMessage} />]
    } else if (isError) {
      return [<ComboBoxItem key="error" data-id="" text={errorMessage} />]
    } else {
      return data.map(({ code, displayName }) => (
        <ComboBoxItem key={code} data-id={code} selected={code === value.code} text={displayName} />
      ))
    }
  }, [isLoading, isError, data, loadingMessage, errorMessage, value.code])

  const displayValue = find(data, { code: value.code })?.displayName ?? ''
  return (
    <ComboBox
      id={id}
      value={displayValue}
      onSelectionChange={(event) => {
        onChange({ code: event.detail.item.dataset.id, displayName: event.detail.item.text })
      }}
      onChange={(e) => {
        if (e.target.value === '') {
          onChange({ code: '' })
        }
      }}
      onBlur={() => onBlur?.(value)}
      {...props}
    >
      {options}
    </ComboBox>
  )
}

LoadingComboBox.propTypes = {
  id: PropTypes.string.isRequired,
  useLoader: PropTypes.func.isRequired,
  input: PropTypes.any,
  initialComboBoxValue: PropTypes.string,
  value: PropTypes.shape({ code: PropTypes.string, displayName: PropTypes.string }),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  loadingText: PropTypes.string,
  errorText: PropTypes.string,
}

export default LoadingComboBox
