import { z } from 'zod'

export const dealMiniSchema = z.object({
  dealId: z.string(),
  dealUuid: z.string().uuid(),
  name: z.string(),
  status: z.string(),
  rank: z.number().int(),
  workingVersion: z.enum(['WORKING_VERSION', 'LIVE']),
  borrowerBpId: z.string(),
  chineseWall: z.boolean(),
  actualSigningDate: z.string().nullish(),
})

/** @typedef {import('zod').TypeOf<typeof dealMiniSchema>} DealMiniDTO */
