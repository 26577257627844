import { FlexBox, FlexBoxDirection, Link, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes, { func, object, string } from 'prop-types'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { FinancedObjectType } from 'components/domains/deals/creation-dialog/property-step/DealCreationPropertyStep'
import { DealCreationReviewRow } from 'components/domains/deals/creation-dialog/review-step/DealCreationReviewRow'
import { LeafletMapWithMarkers } from 'components/ui/map/LeafletMapWithMarkers'
import { useAreaMeasurementUnitFormatter } from 'hooks/i18n/useI18n'
import { useCountries } from 'hooks/services/business-partners/config/useCountries'
import { useTypecodes } from 'hooks/services/properties/useTypecodes'

const DealCreationPropertyLink = ({ property: { description, id } = {}, ...props }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.dialog.property-link',
  })

  if (id) {
    return (
      <FlexBox direction={FlexBoxDirection.Column} {...props}>
        {
          <Link href={`/properties/${id}`} target="_blank">
            {description}
          </Link>
        }
        <Text>{id}</Text>
      </FlexBox>
    )
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column} {...props}>
      <Text>{description}</Text>
      <Text>{t('new-property')}</Text>
    </FlexBox>
  )
}

DealCreationPropertyLink.propTypes = {
  property: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
  }),
}

export const DealCreationReviewProperty = ({ financedObjectType, portfolio = {}, onEdit }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.dialog' })
  const { data: { typecodes: typeCodesConfig = [] } = {} } = useTypecodes()
  const { data: { countries: countryCodesConfig = [] } = {} } = useCountries()
  const { properties = [], countryCodes = [], numberOfProperties, mainPropertyTypeCode } = portfolio
  const {
    description,
    typeCode,
    currencyCode,
    areaMeasureUnitCode,
    address: { countryName, postalCode, houseId, streetName, cityName } = {},
    geoLocation: { longitude, latitude } = {},
  } = properties[0] ?? {}

  const getPropertyTypeName = (propertyTypeCode) =>
    typeCodesConfig.find(({ key }) => propertyTypeCode === key)?.display_name

  const getCountryName = (countryCode) =>
    countryCodesConfig.find(({ id }) => countryCode === id)?.name

  const getCountryNames = (selectedCountryCodes) =>
    selectedCountryCodes.map((countryCode) => getCountryName(countryCode)).join(', ')

  const formatAreaUom = useAreaMeasurementUnitFormatter()
  const getUnitName = (unitCode) => formatAreaUom(unitCode)

  if (financedObjectType === FinancedObjectType.PORTFOLIO) {
    return (
      <Fragment>
        <DealCreationReviewRow
          id="deal-creation-review-portfolio-financed-object"
          label={t('property.financed-object')}
          text={t('property.portfolio')}
        />
        <DealCreationReviewRow
          id="deal-creation-review-portfolio-properties-count"
          label={t('property.input.label.properties-count')}
          text={numberOfProperties}
        />
        <DealCreationReviewRow
          id="deal-creation-review-portfolio-main-property-type"
          label={t('property.input.label.main-property-type')}
          text={getPropertyTypeName(mainPropertyTypeCode)}
        />
        <DealCreationReviewRow
          id="deal-creation-review-portfolio-countries"
          label={t('property.input.label.countries')}
          text={getCountryNames(countryCodes)}
          onEdit={onEdit}
        />
      </Fragment>
    )
  }

  if (financedObjectType === FinancedObjectType.PROPERTY) {
    return (
      <Fragment>
        <DealCreationReviewRow
          id="deal-creation-review-property-financed-object"
          label={t('property.financed-object')}
          text={t('property')}
        />
        <DealCreationReviewRow
          id="deal-creation-review-property-name"
          label={t('property.property-name')}
          text={description}
        >
          <DealCreationPropertyLink
            id="deal-creation-review-property-name-link"
            property={properties[0]}
          />
        </DealCreationReviewRow>
        <DealCreationReviewRow
          id="deal-creation-review-property-type"
          label={t('property.property-type')}
          text={getPropertyTypeName(typeCode)}
        />
        <DealCreationReviewRow
          id="deal-creation-review-property-currency"
          label={t('property.currency')}
          text={currencyCode}
        />
        <DealCreationReviewRow
          id="deal-creation-review-property-unit-area"
          label={t('property.unit-area')}
          text={getUnitName(areaMeasureUnitCode)}
        />
        <DealCreationReviewRow
          id="deal-creation-review-property-address-zip"
          label={t('property.address.country')}
          text={countryName}
        />
        <DealCreationReviewRow
          id="deal-creation-review-property-address-zip"
          label={t('property.address.zip')}
          text={postalCode}
        />
        <DealCreationReviewRow
          id="deal-creation-review-property-address-city"
          label={t('property.address.city')}
          text={cityName}
        />
        <DealCreationReviewRow
          id="deal-creation-review-property-address-street"
          label={t('property.address.street')}
          text={streetName}
        />
        <DealCreationReviewRow
          id="deal-creation-review-property-address-no"
          label={t('property.address.no')}
          text={houseId}
        />
        <DealCreationReviewRow
          id="deal-creation-review-property-address-lat"
          label={t('property.geolocation.lat')}
          text={latitude}
        />
        <DealCreationReviewRow
          id="deal-creation-review-property-address-long"
          label={t('property.geolocation.long')}
          text={longitude}
          onEdit={onEdit}
        />
        {longitude && latitude && (
          <div className="review-property-map-container">
            <LeafletMapWithMarkers
              id="deal-creation-review-property-map"
              className="review-property-map"
              markers={[{ latitude, longitude }]}
              scrollWheelZoom={true}
            />
          </div>
        )}
      </Fragment>
    )
  }

  return (
    <DealCreationReviewRow
      id="deal-creation-review-no-property-financed-object"
      label={t('property.financed-object')}
      text={t('property.no-property')}
      onEdit={onEdit}
    />
  )
}

DealCreationReviewProperty.propTypes = {
  financedObjectType: string,
  onEdit: func.isRequired,
  portfolio: object,
}
