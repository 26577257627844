import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useRequirementComments = ({ requirementId }) =>
  useCamelizedResponse(
    useRequest({
      path: `/conditions/external/requirements/${requirementId}/comments`,
      useCache: true,
      keys: ['conditions', 'external', 'requirements', requirementId, 'comments'],
    }),
  )

export default useRequirementComments
