import { Grid, MessageStrip } from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import uniq from 'lodash.uniq'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropertyPortfolioRentRollAnalysisCard from 'components/domains/properties/portfolio/rent-roll/analysis/PropertyPortfolioRentRollAnalysisCard'
import styles from 'components/domains/properties/portfolio/rent-roll/analysis/PropertyPortfolioRentRollAnalysisTab.module.css'
import PropertyPortfolioRentRollAnalysisTable from 'components/domains/properties/portfolio/rent-roll/analysis/PropertyPortfolioRentRollAnalysisTable'
import { YEAR_UNIT } from 'components/domains/properties/rent-roll/comparison/PropertyRentRollComparisonDeviation'
import CWPLayout from 'components/ui/layout/CWPLayout'
import {
  useAreaMeasurementUnitFormatter,
  useNumberFormatter,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'
import useCurrentMultiPropertyKpis from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpis'
import useCurrentRentRollOverviewMultiPropertyKpis from 'hooks/services/properties/kpis/useCurrentRentRollOverviewMultiPropertyKpis'
import usePortfolioRentRollAnalysisComparisonMetrics from 'hooks/services/properties/portfolio/usePortfolioRentRollAnalysisComparisonMetrics'
import usePortfolioUnits from 'hooks/services/properties/portfolio/usePortfolioUnits'
import { PropertyPortfolioContext } from 'routes/properties/portfolio/PropertyPortfolioContext'

const PropertyPortfolioRentRollAnalysisTab = () => {
  const PERCENTAGE_UNIT = '%'

  const { t: tAnalysis } = useTranslation('translation', {
    keyPrefix: 'pages.properties.portfolio.rent-roll.analysis',
  })

  const rentRollToCompare = useSelector(
    (state) => state.properties.compareRentRoll.rentRollToCompare,
  )
  const comparisonKeyDate = useMemo(() => rentRollToCompare?.keyDate, [rentRollToCompare])
  const comparisonValidFrom = useMemo(() => rentRollToCompare?.validFrom, [rentRollToCompare])

  const formatNumber = useNumberFormatter({
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    notation: 'compact',
  })

  const formatWault = useNumberFormatter({
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  const formatPercentNumber = useNumberFormatter({
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  const { format: formatDate } = useShortDateFormatter()

  const formatUOM = useAreaMeasurementUnitFormatter()

  const { properties } = useContext(PropertyPortfolioContext)

  const initialCurrentKpis = useCurrentRentRollOverviewMultiPropertyKpis()

  const currentKeyDate = useMemo(() => initialCurrentKpis.data?.keyDate, [initialCurrentKpis.data])

  const keyDates = useMemo(
    () => uniq(compact([currentKeyDate, comparisonKeyDate])),
    [comparisonKeyDate, currentKeyDate],
  )

  const { isFetching: isFetchingPortfolioUnits, data: portfolioUnits } = usePortfolioUnits({
    properties,
    keyDatesWithValidFrom: [
      {
        keyDate: comparisonKeyDate,
        validFrom: comparisonValidFrom,
      },
    ],
    includeCurrentRentRoll: true,
  })

  const isInComparison = keyDates.length > 1

  const currentAndComparisonKpis = useCurrentMultiPropertyKpis(
    properties.map((property) => property.uuid),
    {
      currency: portfolioUnits.currency,
      areaUnitOfMeasurement: portfolioUnits.uom,
      keyDates: keyDates,
      withAllKeyDates: true,
    },
    {
      enabled: !isFetchingPortfolioUnits && currentKeyDate,
    },
  )

  const currentKpis = useMemo(() => currentAndComparisonKpis?.data?.[0], [currentAndComparisonKpis])
  const comparisonKpis = useMemo(
    () => currentAndComparisonKpis?.data?.[1],
    [currentAndComparisonKpis],
  )

  const areaMeasureUnitCode = initialCurrentKpis.data?.totalAreaSurface?.measurementUnit

  const commonMetricAttributes = {
    isInComparison,
    keyDates: { current: currentKeyDate, comparison: comparisonKeyDate },
    kpis: { current: currentKpis, comparison: comparisonKpis },
  }

  const annualizedCurrentRentMetrics = usePortfolioRentRollAnalysisComparisonMetrics({
    ...commonMetricAttributes,
    valueAccessor: 'annualizedCurrentRent.number',
    unitAccessor: 'annualizedCurrentRent.currency',
  })

  const annualizedCurrentRentPerUomMetrics = usePortfolioRentRollAnalysisComparisonMetrics({
    ...commonMetricAttributes,
    valueAccessor: 'annualizedCurrentRentPerUom.number',
    unitAccessor: 'annualizedCurrentRentPerUom.currency',
  })

  const vacancyMetrics = usePortfolioRentRollAnalysisComparisonMetrics({
    ...commonMetricAttributes,
    valueAccessor: 'vacancySurface.percent',
    fallbackUnit: PERCENTAGE_UNIT,
    factor: 100,
  })

  const waultToBreakMetrics = usePortfolioRentRollAnalysisComparisonMetrics({
    ...commonMetricAttributes,
    valueAccessor: 'waultToBreakInYears',
    fallbackUnit: YEAR_UNIT,
  })

  const renderComparisonMessageStripContent = () => (
    <>
      {rentRollToCompare?.index &&
        tAnalysis('in-comparison', {
          currentKeyDate: formatDate(currentKeyDate),
          comparisonKeyDate: formatDate(comparisonKeyDate),
        })}
      {!rentRollToCompare?.index && tAnalysis('not-in-comparison')}
    </>
  )

  const isFetching = currentAndComparisonKpis.isFetching || isFetchingPortfolioUnits
  const isError = initialCurrentKpis.isError || currentAndComparisonKpis.isError

  return (
    <CWPLayout>
      <CWPLayout.Full>
        <MessageStrip type="Information" hideCloseButton={true}>
          {renderComparisonMessageStripContent()}
        </MessageStrip>
      </CWPLayout.Full>
      <CWPLayout.Full>
        <Grid defaultSpan="XL3 L3 M12 S12" hSpacing={'16px'} className={styles.grid}>
          <PropertyPortfolioRentRollAnalysisCard
            title={tAnalysis('annualized-current-rent')}
            primaryMetric={annualizedCurrentRentMetrics.primary}
            secondaryMetric={annualizedCurrentRentMetrics.secondary}
            unit={annualizedCurrentRentMetrics.primary.unit}
            isLoading={isFetching}
            isError={isError}
            formatter={formatNumber}
          />
          <PropertyPortfolioRentRollAnalysisCard
            title={tAnalysis('annualized-current-rent-per-uom', {
              uom: areaMeasureUnitCode ? formatUOM(areaMeasureUnitCode) : tAnalysis('uom'),
            })}
            primaryMetric={annualizedCurrentRentPerUomMetrics.primary}
            secondaryMetric={annualizedCurrentRentPerUomMetrics.secondary}
            unit={annualizedCurrentRentPerUomMetrics.primary.unit}
            isLoading={isFetching}
            isError={isError}
            formatter={formatNumber}
          />
          <PropertyPortfolioRentRollAnalysisCard
            title={tAnalysis('vacancy')}
            primaryMetric={vacancyMetrics.primary}
            secondaryMetric={vacancyMetrics.secondary}
            unit={vacancyMetrics.primary.unit}
            isLoading={isFetching}
            isError={isError}
            formatter={formatPercentNumber}
          />
          <PropertyPortfolioRentRollAnalysisCard
            title={tAnalysis('wault-break')}
            primaryMetric={waultToBreakMetrics.primary}
            secondaryMetric={waultToBreakMetrics.secondary}
            unit={YEAR_UNIT}
            isLoading={isFetching}
            isError={isError}
            formatter={formatWault}
          />
        </Grid>
      </CWPLayout.Full>
      <CWPLayout.Full>
        <PropertyPortfolioRentRollAnalysisTable keyDate={currentKeyDate} />
      </CWPLayout.Full>
    </CWPLayout>
  )
}

export default PropertyPortfolioRentRollAnalysisTab
