import PropTypes from 'prop-types'
import DisplayAndEditCard from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/CriticalFacilityProposalDisplayAndEditCard'
import useCustomFieldDefinitions from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/useCriticalFacilityProposalCustomFieldDefinitions'

const propTypes = {
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.bool]),
    }),
  ),
  config: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
  refineFieldDefinitions: PropTypes.objectOf(PropTypes.func),
  fieldDefinitionOverrides: PropTypes.shape({}),
}

/**
 * @typedef {Parameters<typeof DisplayAndEditCard>[0]['fieldDefinitions']} fieldDefinitions
 * @typedef {fieldDefinitions[0]} fieldDefinition
 * @typedef {Omit<Parameters<typeof DisplayAndEditCard>[0], 'fieldDefinitions'>} extend
 * @typedef {PropTypes.InferProps<typeof propTypes>} props
 *
 * @typedef {object} overrides
 * @property {import('components/ui/customfield/customFieldSchema').CustomField[]} overrides.customFields
 * @property {import('components/ui/customfield/customFieldSchema').CustomFieldMetadata[]} overrides.config
 * @property {Partial<fieldDefinition>} [overrides.fieldDefinitionOverrides]
 * Provide overrides for all field definitions.
 * Will be merged into every standard field definition.
 *
 * Use case: ignore the `display` property sent by the backend and always show a field
 * ```json
 * {
 *   isShownInDisplay: true,
 *   isShownInEdit: true,
 * }
 * ```
 * @property {Record<string, (fieldDefinition: fieldDefinition) => fieldDefinition>} [overrides.refineFieldDefinitions]
 * Refine individual field definitions.
 *
 * Will be called with the standard field definition.
 *
 * Should return the refined field definition.
 *
 * @param {Omit<Omit<extend, keyof props> & props, keyof overrides> & overrides} props
 */
const CustomFieldsTile = ({
  customFields,
  config,
  fieldDefinitionOverrides,
  refineFieldDefinitions,
  ...props
}) => {
  const fieldDefinitions = useCustomFieldDefinitions({ data: customFields, config }).map(
    (fieldDefinition) => {
      Object.assign(fieldDefinition, fieldDefinitionOverrides)

      const refineFieldDefinition = refineFieldDefinitions?.[fieldDefinition.name]

      if (refineFieldDefinition) {
        return refineFieldDefinition(fieldDefinition)
      } else {
        return fieldDefinition
      }
    },
  )

  return <DisplayAndEditCard {...props} fieldDefinitions={fieldDefinitions} />
}

CustomFieldsTile.propTypes = propTypes

export default CustomFieldsTile
