import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import useMMyyyy from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/hooks/useMMyyyy'
import { useParseCustomFieldValue } from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/ui/customfield/useCustomFieldDefinitions'
import CustomFieldsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/CriticalFacilityProposalCustomFieldsTile'

const propTypes = {
  currentContent: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
}
/**
 * @typedef {object} overrides
 * @property {(newContent: string) => void} onChange
 * @property {(hasChanges: boolean) => void} setHasContentChanges
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 */
const CriticalFacilityProposalTile = ({
  onChange,
  currentContent,
  setHasContentChanges,
  isEditMode,
}) => {
  const { followUpDateFieldName, orderedAndFilteredFieldMetadata } =
    useSelector(
      (state) =>
        state.decisionPaper.tilesOverview.tileMetadata?.[
          availableAutomaticTiles
            .monitoredRiskIndicatorRegulatoryChecksCurrentCriticalFacilityStatus
        ],
    ) ?? {} // HINT: The returned value is undefined if this tile is rendered before the CriticalFacilityTile

  const initialData = useMemo(
    () => orderedAndFilteredFieldMetadata?.map(({ key }) => ({ key, value: null })) ?? [],
    [orderedAndFilteredFieldMetadata],
  )

  const orderedAndFilteredCustomFieldData = useMemo(
    () => (currentContent ? JSON.parse(currentContent) : initialData),
    [currentContent, initialData],
  )

  const { format: formatMMyyyy, parse: parseMMyyyy, pattern } = useMMyyyy()

  /** @typedef {NonNullable<Parameters<typeof CustomFieldsTile>[0]['refineFieldDefinitions']>[string]} refineFieldDefinition */

  const refineFollowUpDateField = followUpDateFieldName
    ? {
        [followUpDateFieldName]:
          /** @type {refineFieldDefinition} */
          (fieldDefinition) => ({
            ...fieldDefinition,
            editComponentProps: {
              formatPattern: pattern,
            },
            value: formatMMyyyy(fieldDefinition.rawValue),
            formattedValue: formatMMyyyy(fieldDefinition.rawValue) || '-',
          }),
      }
    : {}

  const parseCustomFieldValue = useParseCustomFieldValue()

  const parseValue = (value, key) => {
    const fieldMetadata = orderedAndFilteredFieldMetadata?.find((field) => field.key === key)
    return parseCustomFieldValue(value, fieldMetadata?.type)
  }

  const handleChange = (changes) => {
    const [key, value] = Object.entries(changes)[0]

    const newState = orderedAndFilteredCustomFieldData.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          value: key === followUpDateFieldName ? parseMMyyyy(value) : parseValue(value, key),
        }
      }
      return item
    })

    onChange(JSON.stringify(newState))
    setHasContentChanges(true)
  }

  return (
    <CustomFieldsTile
      customFields={orderedAndFilteredCustomFieldData}
      config={orderedAndFilteredFieldMetadata ?? []}
      refineFieldDefinitions={{
        ...refineFollowUpDateField,
      }}
      fieldDefinitionOverrides={{
        isShownInDisplay: true,
        isShownInEdit: true,
      }}
      cardHeaderTitle=""
      saveChanges={() => {}}
      onEditChanges={handleChange}
      isEditable
      isEditing={isEditMode}
    />
  )
}

CriticalFacilityProposalTile.propTypes = propTypes

export default CriticalFacilityProposalTile
