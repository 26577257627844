import RequestError from 'api/RequestError'

const NO_CONTENT = 204

const doFetch = async ({
  url,
  apiKey,
  params,
  options,
  headers = {},
  contentType = 'application/json',
}) => {
  const requestHeaders = {
    Accept: 'application/json',
    ...headers,
  }
  if (options.method !== 'GET') {
    requestHeaders['Content-Type'] = contentType
  }
  const qs = new URLSearchParams({
    ...params,
    key: apiKey,
  })
  const urlWithQs = `${url}?${qs}`
  const requestOptions = {
    ...options,
    headers: requestHeaders,
    redirect: 'error',
    mode: 'cors',
    referrerPolicy: 'no-referrer',
  }
  const response = await fetch(urlWithQs, requestOptions)
  if (response.ok) {
    if (response.status !== NO_CONTENT) {
      return await response.json()
    }
    return
  }
  throw new RequestError(`Request to '${url}' failed`, response)
}

export const doGet = (url, apiKey, qs) =>
  doFetch({
    url,
    apiKey,
    params: qs,
    options: {
      method: 'GET',
    },
  })
