import { FlexBox } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { conditionTypes } from 'api/conditions/conditions'
import {
  neededOperationsForConditionDelete,
  neededOperationsForConditionsCreate,
  neededOperationsForConditionEdit,
} from 'api/conditions/conditionsAllowedOperations'
import { hasUserRequiredOperations } from 'api/helper'
import ConditionsMassEditButton from 'components/domains/conditions/overview/buttons/generic/ConditionsMassEditButton'
import CopySelectedConditionsButton from 'components/domains/conditions/overview/buttons/generic/CopySelectedConditionsButton'
import DeleteSelectedConditionsButton from 'components/domains/conditions/overview/buttons/generic/DeleteSelectedConditionsButton'
import CreateInternalConditionButton from 'components/domains/conditions/overview/buttons/internal-conditions/CreateInternalConditionButton'
import styles from 'components/domains/conditions/overview/buttons/internal-conditions/InternalConditionsToolbarButtons.module.css'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const InternalConditionsToolbarButtons = ({ disabled }) => {
  const { allowedOperations } = useContext(ConditionsContext)

  const hasUserConditionCreatePermission = useMemo(
    () => hasUserRequiredOperations(neededOperationsForConditionsCreate, allowedOperations),
    [allowedOperations],
  )

  const hasUserConditionDeletePermission = useMemo(
    () => hasUserRequiredOperations(neededOperationsForConditionDelete, allowedOperations),
    [allowedOperations],
  )

  const internalConditionAddButton = useMemo(() => {
    if (!hasUserConditionCreatePermission) {
      return
    }
    return <CreateInternalConditionButton disabled={disabled} />
  }, [hasUserConditionCreatePermission, disabled])

  const internalConditionDeleteButton = useMemo(() => {
    if (!hasUserConditionDeletePermission) {
      return
    }
    return (
      <DeleteSelectedConditionsButton disabled={disabled} conditionType={conditionTypes.internal} />
    )
  }, [hasUserConditionDeletePermission, disabled])

  const internalConditionCopyButton = useMemo(() => {
    if (!hasUserConditionCreatePermission) {
      return
    }
    return (
      <CopySelectedConditionsButton disabled={disabled} conditionType={conditionTypes.internal} />
    )
  }, [hasUserConditionCreatePermission, disabled])

  const hasUserConditionEditPermission = useMemo(
    () => hasUserRequiredOperations(neededOperationsForConditionEdit, allowedOperations),
    [allowedOperations],
  )

  const internalConditionsMassEditButton = useMemo(() => {
    if (!hasUserConditionEditPermission) {
      return
    }
    return <ConditionsMassEditButton disabled={disabled} conditionType={conditionTypes.internal} />
  }, [hasUserConditionEditPermission, disabled])

  return (
    <FlexBox className={styles.buttons}>
      {internalConditionAddButton}
      {internalConditionsMassEditButton}
      {internalConditionCopyButton}
      {internalConditionDeleteButton}
    </FlexBox>
  )
}

InternalConditionsToolbarButtons.propTypes = {
  disabled: PropTypes.bool.isRequired,
}

export default InternalConditionsToolbarButtons
