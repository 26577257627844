import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'
import useDataSourceQueryParams from 'routes/deals/financing/utils/useDataSourceQueryParams'

const REQUEST_RETRIES = 3

const useTrancheByDisplayId = (dealId, displayId) => {
  const { dataSourceQueryKeys, dataSourceQueryParam } = useDataSourceQueryParams()

  const result = useCamelizedResponse(
    useRequest({
      path: `/financing-structures/deals/${dealId}/tranches?displayId=${displayId}&${dataSourceQueryParam}`,
      keys: [
        'deals',
        dealId,
        'tranches',
        'trancheDisplayIdResolution',
        displayId,
        ...dataSourceQueryKeys,
      ],
      options: {
        enabled: !!displayId,
        retry: (failureCount, error) => {
          if (isNotFoundError(error) || isMissingPermissionError(error)) {
            return false
          }
          if (failureCount < REQUEST_RETRIES) {
            return true
          }
          return false
        },
      },
    }),
  )
  return {
    ...result,
    data: { ...result?.data?.tranches[0] },
  }
}

export default useTrancheByDisplayId
