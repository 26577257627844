import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import styles from 'components/ui/page/Section.module.css'

const propTypes = {
  titleText: PropTypes.string.isRequired,
  children: PropTypes.node,
  actions: PropTypes.node,
  className: PropTypes.string,
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const Section = ({ titleText, children, actions, className }) => (
  <section className={compact([styles.section, className]).join(' ')}>
    <header className={styles.header}>
      <span className={styles.title}>{titleText}</span>
      <div className={styles.actions}>{actions}</div>
    </header>
    <div className={styles.container}>{children}</div>
  </section>
)

Section.propTypes = propTypes

export default Section
