import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { eventsAndTasksMassEditOperations } from 'api/events/events'
import { eventStatus, taskStatus } from 'api/events/status'
import LoadingDropdown from 'components/ui/dropdown/LoadingDropdown'

const operations = [eventsAndTasksMassEditOperations.keep, eventsAndTasksMassEditOperations.clear]

const BusinessEventsAndTasksMassEditStatusSelect = ({
  onChange,
  eventsOrTasks,
  isEventList,
  disabled,
}) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.mass-edit-dialog',
  })

  const defaultOptions = useMemo(
    () =>
      operations.map((operation) => ({
        code: operation,
        displayName: t(`operations.${operation}`),
      })),
    [t],
  )

  const allEventsInStatusDraft = useMemo(
    () => eventsOrTasks.every(({ status }) => status === eventStatus.drafted),
    [eventsOrTasks],
  )

  const allEventsInStatusOngoing = useMemo(
    () => eventsOrTasks.every(({ status }) => status === eventStatus.ongoing),
    [eventsOrTasks],
  )

  const allTasksinStatusCreated = useMemo(
    () => eventsOrTasks.every(({ status }) => status === taskStatus.created),
    [eventsOrTasks],
  )

  const allTasksinStatusOngoing = useMemo(
    () => eventsOrTasks.every(({ status }) => status === taskStatus.ongoing),
    [eventsOrTasks],
  )

  const someTasksMandatory = useMemo(
    () => eventsOrTasks.some(({ isMandatory }) => isMandatory),
    [eventsOrTasks],
  )
  const getEventStatusOptions = useCallback(() => {
    if (allEventsInStatusDraft) {
      return [
        ...defaultOptions,
        { code: eventStatus.ongoing, displayName: tNoPrefix('events.status.ongoing') },
        { code: eventStatus.aborted, displayName: tNoPrefix('events.status.aborted') },
      ]
    }

    if (allEventsInStatusOngoing) {
      return [
        ...defaultOptions,
        { code: eventStatus.completed, displayName: tNoPrefix('events.status.completed') },
        { code: eventStatus.aborted, displayName: tNoPrefix('events.status.aborted') },
      ]
    }

    return [
      ...defaultOptions,
      {
        code: eventStatus.aborted,
        displayName: tNoPrefix('events.status.aborted'),
      },
    ]
  }, [allEventsInStatusDraft, allEventsInStatusOngoing, tNoPrefix, defaultOptions])

  const getTaskStatusOptions = useCallback(() => {
    if (allTasksinStatusCreated) {
      if (someTasksMandatory) {
        return [
          ...defaultOptions,
          { code: taskStatus.ongoing, displayName: tNoPrefix('tasks.status.ongoing') },
          { code: taskStatus.completed, displayName: tNoPrefix('tasks.status.completed') },
        ]
      }
      return [
        ...defaultOptions,
        { code: taskStatus.ongoing, displayName: tNoPrefix('tasks.status.ongoing') },
        { code: taskStatus.completed, displayName: tNoPrefix('tasks.status.completed') },
        { code: taskStatus.aborted, displayName: tNoPrefix('tasks.status.aborted') },
      ]
    }
    if (allTasksinStatusOngoing) {
      if (someTasksMandatory) {
        return [
          ...defaultOptions,
          { code: taskStatus.created, displayName: tNoPrefix('tasks.status.created') },
          { code: taskStatus.completed, displayName: tNoPrefix('tasks.status.completed') },
        ]
      }
      return [
        ...defaultOptions,
        { code: taskStatus.created, displayName: tNoPrefix('tasks.status.created') },
        { code: taskStatus.completed, displayName: tNoPrefix('tasks.status.completed') },
        { code: taskStatus.aborted, displayName: tNoPrefix('tasks.status.aborted') },
      ]
    }
    if (someTasksMandatory) {
      return [
        ...defaultOptions,
        { code: taskStatus.created, displayName: tNoPrefix('tasks.status.created') },
        { code: taskStatus.ongoing, displayName: tNoPrefix('tasks.status.ongoing') },
        { code: taskStatus.completed, displayName: tNoPrefix('tasks.status.completed') },
      ]
    }
    return [
      ...defaultOptions,
      { code: taskStatus.created, displayName: tNoPrefix('tasks.status.created') },
      { code: taskStatus.ongoing, displayName: tNoPrefix('tasks.status.ongoing') },
      { code: taskStatus.completed, displayName: tNoPrefix('tasks.status.completed') },
      { code: taskStatus.aborted, displayName: tNoPrefix('tasks.status.aborted') },
    ]
  }, [
    defaultOptions,
    allTasksinStatusCreated,
    someTasksMandatory,
    allTasksinStatusOngoing,
    tNoPrefix,
  ])

  const operationsLoader = useCallback(() => {
    const options = isEventList ? getEventStatusOptions() : getTaskStatusOptions()

    return {
      isError: false,
      isLoading: false,
      data: options,
    }
  }, [isEventList, getEventStatusOptions, getTaskStatusOptions])

  const handleSelectedOperationChanged = useCallback(
    (selection) => {
      switch (selection) {
        case eventsAndTasksMassEditOperations.keep:
          onChange({ operation: eventsAndTasksMassEditOperations.keep })
          return
        case eventsAndTasksMassEditOperations.clear:
          onChange({ operation: eventsAndTasksMassEditOperations.clear })
          return
        default:
          onChange({ operation: eventsAndTasksMassEditOperations.replace, value: selection })
      }
    },
    [onChange],
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label>{t('labels.status')}</Label>
      <LoadingDropdown
        id={'events-mass-edit-status-select'}
        useLoader={operationsLoader}
        onChange={handleSelectedOperationChanged}
        value={operations[0]}
        disabled={disabled}
      />
    </FlexBox>
  )
}

BusinessEventsAndTasksMassEditStatusSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  eventsOrTasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      isMandatory: PropTypes.bool,
    }),
  ).isRequired,
  isEventList: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default BusinessEventsAndTasksMassEditStatusSelect
