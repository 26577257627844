import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TableDescriptionSubcomponent from 'components/domains/conditions/overview/table-cells/view/TableDescriptionSubcomponent'
import useConditionsCellEditMode from 'hooks/services/conditions/edit-mode/useConditionsCellEditMode'
import { conditionDescriptionChanged } from 'redux/slices/conditions/conditionsTableSlice'

const ConditionsTableDescriptionSubcomponent = ({ description, conditionId }) => {
  const dispatch = useDispatch()

  const { isEditModeForCurrentRow } = useConditionsCellEditMode({
    conditionId,
  })

  const onInput = useCallback(
    (newDescription) => {
      dispatch(conditionDescriptionChanged({ description: newDescription }))
    },
    [dispatch],
  )

  const currentEditDescription = useSelector(
    (state) => state.conditions.conditionsTable.editedRow.currentValues?.description,
  )

  return (
    <TableDescriptionSubcomponent
      onInput={onInput}
      description={isEditModeForCurrentRow ? currentEditDescription : description}
      isEditMode={isEditModeForCurrentRow}
    />
  )
}

ConditionsTableDescriptionSubcomponent.propTypes = {
  description: PropTypes.string,
  conditionId: PropTypes.string.isRequired,
}

export default ConditionsTableDescriptionSubcomponent
