export const valuationReasons = (isLoading, isError, valuationReasonsData) => {
  if (isLoading || isError) return { isLoading, isError, data: undefined }

  return {
    data: valuationReasonsData.valuation_reasons.map(({ reason }) => ({
      code: reason,
      displayName: reason,
    })),
    isLoading: false,
    isError: false,
  }
}

export const getValuationReasonsObject = (valuationReasonsData) => {
  const tableEnum = {}
  valuationReasonsData.valuation_reasons.map(({ reason }) => {
    tableEnum[reason] = reason
  })
  return tableEnum
}

// TODO: Refactor me to be better readable
// this filters the request types from the response (see example in testdata)
const mapValuationTypes = (data, requestReason) => {
  const dataMap = []
  let index = 0

  for (const i in data) {
    if (data[i].reason === requestReason) index = i
  }
  for (const j in data[index].types.valuation_types) {
    dataMap.push({
      code: data[index].types.valuation_types[j].type,
      displayName: data[index].types.valuation_types[j].type,
    })
  }
  return dataMap
}

const mapValuationTypesNoRequestReason = (data, returnAsEnumFilter) => {
  const dataMap = []
  const tableEnum = {}

  data.forEach(({ types: { valuation_types: valuationTypes } }) => {
    valuationTypes.forEach(({ type: valuationType }) => {
      if (valuationType in tableEnum) {
        return
      }
      dataMap.push({
        code: valuationType,
        displayName: valuationType,
      })
      tableEnum[valuationType] = valuationType
    })
  })
  if (returnAsEnumFilter) return tableEnum
  return dataMap
}

// this returns all valuationTypes based on the requestReason
export const valuationTypes = (isLoading, isError, valuationReasonsData, requestReason) => {
  if (isLoading || isError) return { isLoading, isError, data: undefined }

  return {
    data: mapValuationTypes(valuationReasonsData.valuation_reasons, requestReason),
    isLoading: false,
    isError: false,
  }
}

// here we return all types if no reason is given. We only want this logic for the filterbar.
export const valuationTypesDefaultAll = (valuationReasons, requestReason, returnAsEnumFilter) => {
  if (requestReason === '') {
    return mapValuationTypesNoRequestReason(valuationReasons, returnAsEnumFilter)
  }

  return mapValuationTypes(valuationReasons, requestReason)
}
