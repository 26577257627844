import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PropertyRentRollWorkingVersionPage from 'components/domains/properties/rent-roll/working-version/PropertyRentRollWorkingVersionPage'
import { initRentRollWorkingVersion } from 'components/domains/properties/rent-roll/working-version/utils/rentRollWorkingVersionMappers'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useValidateRentRollWorkingVersion } from 'hooks/services/properties/rent-roll/working-version/useValidateRentRollWorkingVersion'
import useRentRollWorkingVersions from 'hooks/services/properties/useRentRollWorkingVersions'

const RentRollWorkingVersion = ({ propertyUuids }) => {
  const { t } = useTranslation('translation')
  const {
    data: rentRollWorkingVersions,
    isError,
    isLoading,
  } = useRentRollWorkingVersions(propertyUuids)
  const rentRollWorkingVersion =
    rentRollWorkingVersions?.length === 1 ? rentRollWorkingVersions[0] : undefined
  const multipleWorkingVersions = rentRollWorkingVersions?.length > 1

  const rentRollWorkingVersionWithRowNumberAndValidationFields =
    initRentRollWorkingVersion(rentRollWorkingVersion)

  const { validateRentRoll } = useValidateRentRollWorkingVersion({
    ...rentRollWorkingVersionWithRowNumberAndValidationFields,
    property_uuids: propertyUuids,
  })

  const validatedRentRollWorkingVersion = validateRentRoll(
    rentRollWorkingVersionWithRowNumberAndValidationFields,
  )

  return (
    <LoadingStateWrapper
      isError={isError || multipleWorkingVersions}
      isLoading={isLoading}
      errorTitle={t('pages.rent-roll-working-version.error.title')}
      errorDescription={t('pages.rent-roll-working-version.error.description')}
      errorDetails=" "
      renderContent={() => (
        <PropertyRentRollWorkingVersionPage
          propertyUuids={propertyUuids}
          rentRollWorkingVersion={validatedRentRollWorkingVersion}
        />
      )}
    />
  )
}

RentRollWorkingVersion.propTypes = {
  propertyUuids: PropTypes.arrayOf(PropTypes.string),
}

export default RentRollWorkingVersion
