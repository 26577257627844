import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import DecisionProcessLabeledLoadingContent from 'components/domains/business-events-and-tasks/decision-process/alc/helper/DecisionProcessLabeledLoadingContent'
import useRegulatoryInformation from 'components/domains/business-partners/tile/general-information/regulatoryInformation/useRegulatoryInformations'

const dash = '-'

const DecisionProcessAlcAmlRiskClassification = ({ borrowerBpId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.overview.alc-info.dialog',
  })
  const regulatoryInformationResponse = useRegulatoryInformation({
    businessPartnerId: borrowerBpId,
  })
  const renderAmlRiskClassification = useCallback(
    (regulatoryInformation) => regulatoryInformation.amlRiskClassification?.name || dash,
    [],
  )
  return (
    <DecisionProcessLabeledLoadingContent
      response={regulatoryInformationResponse}
      renderCallback={renderAmlRiskClassification}
      label={t('fields.aml-risk-classification')}
    />
  )
}

DecisionProcessAlcAmlRiskClassification.propTypes = {
  borrowerBpId: PropTypes.string.isRequired,
}

export default DecisionProcessAlcAmlRiskClassification
