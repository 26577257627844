import {
  AnalyticalTableScaleWidthMode,
  FlexBox,
  FlexBoxDirection,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import AnalyticalTableNoDataComponent from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/analytical/AnalyticalTableWithToolbar'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/FinancingTermsTrancheDetailsTile.module.css'
import useAnnualReviewFinancingTermsTrancheDetailsTableColumnDefinitions from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/annual-review/useAnnualReviewFinancingTermsTrancheDetailsTableColumnDefinitions'
import mapTranchesDetailsData from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/utils/mapTranchesDetailsData'
import useExpandTranchesDataWithExistingBusinessInformation from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/utils/useExpandTranchesDataWithExistingBusinessInformation'
import AnalyticalTableScalingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/AnalyticalTableScalingWrapper'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const EXPECTED_SIGNED_DATE_TYPE = 'EXP_SIGN'

// get the latest date value of every milestone with correct type
const expectedSigningDate = (milestonesData) => {
  let latestDate = new Date(null) // 1970-01-01T00:00:00.000Z
  let latestDateString = undefined

  milestonesData?.forEach((milestone) => {
    if (milestone.typeCode === EXPECTED_SIGNED_DATE_TYPE && milestone?.targetDate) {
      const tempDate = new Date(milestone?.targetDate)
      if (tempDate > latestDate) {
        latestDate = tempDate
        latestDateString = milestone?.targetDate
      }
    }
  })

  return latestDateString
}

const AnnualReviewFinancingTermsTrancheDetailsTile = ({
  tileId,
  isPdfView,
  showConfirmationForLinks,
  selectedDealIndex,
}) => {
  const dispatch = useDispatch()

  const tileData = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const { multipleFinancingTermsDataPerDealUuid = {}, dealsData = {} } = tileData.data

  const { dealDisplayId: selectedDealDisplayId, dealUuid: selectedDealUuid } =
    dealsData[selectedDealIndex] ?? {}

  const trancheData = useMemo(
    () =>
      Object.values(multipleFinancingTermsDataPerDealUuid).find(
        ({ sourceRender: { dealId: dealUuid } = {} }) => dealUuid === selectedDealUuid,
      )?.trancheData ?? {},
    [multipleFinancingTermsDataPerDealUuid, selectedDealUuid],
  )
  const milestonesData = useMemo(
    () =>
      Object.values(multipleFinancingTermsDataPerDealUuid).find(
        ({ sourceRender: { dealId: dealUuid } = {} }) => dealUuid === selectedDealUuid,
      )?.milestonesData ?? [],
    [multipleFinancingTermsDataPerDealUuid, selectedDealUuid],
  )
  const syndicationsExistingBusiness = useMemo(
    () =>
      Object.values(multipleFinancingTermsDataPerDealUuid).find(
        ({ sourceRender: { dealId: dealUuid } = {} }) => dealUuid === selectedDealUuid,
      )?.syndicationExistingBusinessData ?? [],
    [multipleFinancingTermsDataPerDealUuid, selectedDealUuid],
  )
  const allBusinessPartnersData = useMemo(
    () =>
      Object.values(multipleFinancingTermsDataPerDealUuid).find(
        ({ sourceRender: { dealId: dealUuid } = {} }) => dealUuid === selectedDealUuid,
      )?.businessPartnersData ?? [],
    [multipleFinancingTermsDataPerDealUuid, selectedDealUuid],
  )

  useEffect(() => {
    dispatch(
      setSourceRender({
        tileId,
        sourceRender: {
          dealDisplayId: selectedDealDisplayId,
        },
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDealDisplayId])

  useEffect(() => {
    if (isNil(tileData.sourceRender)) {
      dispatch(
        setSourceRender({
          tileId,
          sourceRender: {
            dealDisplayId: selectedDealDisplayId,
          },
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDealDisplayId, tileData.sourceRender])

  const [hasLastExtensionOption, setHasLastExtensionOption] = useState(true)
  const tableRef = useRef(null)
  const [tableWidth, setTableWidth] = useState(tableRef?.current?.totalColumnsWidth ?? 0)

  useEffect(() => {
    setTableWidth(tableRef?.current?.totalColumnsWidth ?? 0)
  }, [tableRef?.current?.totalColumnsWidth])

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.financing-terms.tranche-details',
  })

  const columnDefinitions = useAnnualReviewFinancingTermsTrancheDetailsTableColumnDefinitions({
    isPdfView,
    showConfirmationForLinks,
    hasLastExtensionOption,
  })

  const expandedData = useExpandTranchesDataWithExistingBusinessInformation({
    data: trancheData,
    isExistingBusiness: true,
    syndicationsExistingBusiness,
  })

  const tableData = useMemo(() => {
    // sort tranches by rank, exclude last (summary) row
    const sortTranchesByRank = (tranches) => [
      ...tranches.slice(0, -1).sort((a, b) => a?.rank - b?.rank),
      tranches.at(-1),
    ]

    // check whether a lastExtensionOption is present in the mapped tranche data
    const checkLastExtensionOptionExists = (tranches) =>
      tranches.some(
        (tranche) =>
          tranche?.lastExtensionOption?.ultimateMaximumExtensionDate &&
          tranche?.lastExtensionOption?.closingDate,
      )

    if (!isEmpty(expandedData)) {
      let mappedTranchesData = mapTranchesDetailsData(
        expandedData,
        expectedSigningDate(milestonesData),
        isPdfView,
        allBusinessPartnersData,
      )

      setHasLastExtensionOption(checkLastExtensionOptionExists(mappedTranchesData))

      // sort tranches by rank if there is more than one entry present
      if (mappedTranchesData.length > 1) {
        mappedTranchesData = sortTranchesByRank(mappedTranchesData)
      }
      return mappedTranchesData
    }
    return []
  }, [allBusinessPartnersData, expandedData, isPdfView, milestonesData])

  return (
    <AnalyticalTableScalingWrapper isPdfView={isPdfView} tableWidth={tableWidth}>
      <FlexBox direction={FlexBoxDirection.Column} className={styles.tableWrapper}>
        <AnalyticalTableWithToolbar
          nrOfEntries={trancheData?.tranches?.length ?? 0}
          title={t('table.title')}
          id="tranche-details-table"
          className={styles['trancheDetails-table']}
          sortable={false}
          minRows={0}
          columns={columnDefinitions}
          // high number of visible rows fixes re-rendering of height on expand
          visibleRows={99}
          overscanCountHorizontal={99}
          // rowHeight needs to be set to a non-empty string to not show an empty row at the end
          rowHeight={'individual'}
          headerRowHeight={50}
          data={tableData}
          isTreeTable={false}
          scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
          withRowHighlight={false}
          isPdfView={isPdfView}
          NoDataComponent={() => (
            <AnalyticalTableNoDataComponent
              isLoading={false} // loading and error states are handled by the decision paper framework
              isError={false}
              tableId={'tranche-details-table'}
            />
          )}
          ref={tableRef}
        />
      </FlexBox>
    </AnalyticalTableScalingWrapper>
  )
}

AnnualReviewFinancingTermsTrancheDetailsTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}
export default AnnualReviewFinancingTermsTrancheDetailsTile
