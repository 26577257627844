import {
  Button,
  ButtonDesign,
  BusyIndicator,
  BusyIndicatorSize,
  Menu,
  MenuItem,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useState, useRef, useMemo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { conditionTypes } from 'api/conditions/conditions'
import ConditionCreationDialog from 'components/domains/conditions/dialogs/condition-creation/ConditionCreationDialog'
import styles from 'components/domains/conditions/overview/buttons/internal-conditions/CreateInternalConditionButton.module.css'
import { readExcel } from 'components/domains/conditions/overview/buttons/internal-conditions/readInternalConditionsFromExcel'
import Dialog from 'components/ui/dialog/Dialog'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useApprovalLevels from 'hooks/services/conditions/config/useApprovalLevels'
import useInternalTypes from 'hooks/services/conditions/config/useInternalTypes'
import {
  addCondition,
  addConditions,
  openCreationDialog,
  reset,
} from 'redux/slices/conditions/conditionCreationSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const buttonId = 'internal-conditions-add-button'

const manualListIdentifier = 'manual'
const uploadIdentifier = 'upload'

const CreateInternalConditionButton = ({ disabled }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.buttons.create-internal-condition',
  })
  const {
    entityRef: { entityType },
  } = useContext(ConditionsContext)
  const dispatch = useDispatch()
  const showMessageBox = useShowMessageBox()
  const fileInputRef = useRef(null)

  const {
    data: internalTypes,
    isError: isInternalTypesError,
    isLoading: isInternalTypesLoading,
  } = useInternalTypes({
    translated: false,
    entityType,
  })
  const {
    data: approvalLevels,
    isError: isApprovalLevelsError,
    isLoading: isApprovalLevelsLoading,
  } = useApprovalLevels({
    entityType,
    translated: false,
  })

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isLoadingDialogOpen, setIsLoadingDialogOpen] = useState(false)

  const showErrorMessage = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      titleText: t('error.title'),
      children: t('error.description'),
      actions: [
        <Button key="error-buttons-ok" design={ButtonDesign.Emphasized}>
          {tNoPrefix('buttons.ok')}
        </Button>,
      ],
    })
  }, [showMessageBox, t, tNoPrefix])

  const onAddButtonClick = useCallback(() => {
    setIsMenuOpen(true)
  }, [])

  const closeAddButtonMenu = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  const onAddManualClicked = useCallback(() => {
    dispatch(addCondition({}))
    dispatch(openCreationDialog())
  }, [dispatch])

  const onMenuItemClick = useCallback(
    ({ detail: { item: pressedMenuItem } }) => {
      setIsMenuOpen(false)
      const pressedIdentifier = pressedMenuItem.getAttribute('data-button-id')
      switch (pressedIdentifier) {
        case manualListIdentifier:
          onAddManualClicked()
          break
        case uploadIdentifier:
          fileInputRef.current.click()
      }
    },
    [onAddManualClicked],
  )

  const onUploadError = useCallback(() => {
    setIsLoadingDialogOpen(false)
    dispatch(reset())
    showMessageBox({
      type: MessageBoxTypes.Error,
      titleText: t('error.title'),
      children: t('upload.excel.format.error.description'),
      actions: [
        <Button key="error-buttons-ok" design={ButtonDesign.Emphasized}>
          {tNoPrefix('buttons.ok')}
        </Button>,
      ],
    })
  }, [dispatch, showMessageBox, t, tNoPrefix])

  const onUploadSuccess = useCallback(
    (loadedConditions) => {
      setIsLoadingDialogOpen(false)
      dispatch(addConditions(loadedConditions))
      dispatch(openCreationDialog())
    },
    [dispatch],
  )

  const handleUpload = useCallback(
    (event) => {
      if (isInternalTypesError || isApprovalLevelsError) {
        setIsLoadingDialogOpen(false)
        showErrorMessage()
        return
      }
      setIsLoadingDialogOpen(true)
      const file = event.target.files[0]
      const input = event.target
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file)
      fileReader.onload = (loadEvent) =>
        readExcel({ loadEvent, internalTypes, approvalLevels, onUploadSuccess, onUploadError })
      if (input) {
        input.value = null
      }
    },
    [
      isInternalTypesError,
      isApprovalLevelsError,
      internalTypes,
      approvalLevels,
      onUploadError,
      onUploadSuccess,
      showErrorMessage,
    ],
  )

  const loadingDialogContent = useMemo(
    () => (
      <BusyIndicator
        active
        text={t('upload.loading')}
        title={' '}
        size={BusyIndicatorSize.Medium}
        style={{ textAlign: 'center', display: 'block' }}
      />
    ),
    [t],
  )

  return (
    <>
      <Button
        id={buttonId}
        onClick={onAddButtonClick}
        icon="slim-arrow-down"
        iconEnd
        disabled={disabled}
      >
        {tNoPrefix('buttons.add')}
      </Button>
      {isMenuOpen && (
        <Menu
          opener={buttonId}
          open={isMenuOpen}
          onAfterClose={closeAddButtonMenu}
          onItemClick={onMenuItemClick}
        >
          <MenuItem text={t('manual')} data-button-id={manualListIdentifier} />
          <MenuItem
            text={t('upload')}
            data-button-id={uploadIdentifier}
            disabled={isInternalTypesLoading || isApprovalLevelsLoading}
          />
        </Menu>
      )}
      <ConditionCreationDialog conditionType={conditionTypes.internal} />
      <input
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={handleUpload}
        ref={fileInputRef}
        className={styles.fileInput}
      />
      <Dialog open={isLoadingDialogOpen} headerText={''}>
        {loadingDialogContent}
      </Dialog>
    </>
  )
}

CreateInternalConditionButton.propTypes = {
  disabled: PropTypes.bool,
}

export default CreateInternalConditionButton
