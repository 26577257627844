import { t } from 'i18next'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DealChangeOverviewActionCell from 'components/domains/deals/change-overview/DealChangeOverviewActionCell'
import DealChangeOverviewChangedInformationCell from 'components/domains/deals/change-overview/DealChangeOverviewChangedInformationCell'
import ModularTableCell from 'components/domains/properties/modular-table/ModularTableCell'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useDealChangeOverviewUnusedTranslationTemplateRemover from 'hooks/services/deals/change-overview/useDealChangeOverviewTranslationTemplateRemover'
import { UserProfileContext } from 'routes/UserProfileContext'

const useDealChangeOverviewTableData = ({ changeLogs, staffMember }) => {
  const { t: tDealChangeOverview } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.change-overview',
  })

  const { remover: templateTranslationRemover } =
    useDealChangeOverviewUnusedTranslationTemplateRemover()

  const { zoneId } = useContext(UserProfileContext) ?? {}

  const { format: formatDate } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: zoneId,
  })

  return useMemo(
    () =>
      changeLogs.map((changeLog) => {
        const { uuid, cardKey, pageKey, action, changedFields, triggeredAt, triggeredBy } =
          changeLog

        const fullNameOrId = staffMember?.[triggeredBy]?.fullName || triggeredBy

        const row = {
          original: changeLog,
          rowKey: `change-log-${uuid}`,
          changedInformation: {
            cellComponent: <DealChangeOverviewChangedInformationCell changeLog={changeLog} />,
          },
          pageKey: {
            cellComponent: (
              <ModularTableCell wrapText={true}>
                {templateTranslationRemover(t(pageKey))}
              </ModularTableCell>
            ),
          },
          cardKey: {
            cellComponent: (
              <ModularTableCell wrapText={true}>
                {cardKey ? templateTranslationRemover(t(cardKey)) : ''}
              </ModularTableCell>
            ),
          },
          action: {
            cellComponent: (
              <ModularTableCell wrapText={true}>
                <DealChangeOverviewActionCell action={action} />
              </ModularTableCell>
            ),
          },
          changedFields: {
            cellComponent: (
              <ModularTableCell wrapText={true}>{changedFields?.join(', ') ?? ''}</ModularTableCell>
            ),
          },
          triggeredAt: {
            cellComponent: (
              <ModularTableCell wrapText={true}>{formatDate(triggeredAt)}</ModularTableCell>
            ),
          },
          triggeredBy: {
            cellComponent: (
              <ModularTableCell wrapText={true}>
                {triggeredBy && fullNameOrId}
                {!triggeredBy && tDealChangeOverview('anonymous')}
              </ModularTableCell>
            ),
          },
        }

        return row
      }),
    [changeLogs, formatDate, staffMember, tDealChangeOverview, templateTranslationRemover],
  )
}

export default useDealChangeOverviewTableData
