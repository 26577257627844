import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import { useApplicationTypes } from 'hooks/services/deals/configurations/useApplicationTypes'
import { useDealTypes } from 'hooks/services/deals/configurations/useBusinessSegments'
import useTranchesWithSubEntities from 'hooks/services/deals/financing/useTranchesWithSubEntities'
import useDeal from 'hooks/services/deals/useDeal'

const useOpsMemoDealInformation = ({ entityRef: { entityId: dealUuid } }, tileId, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isFetchingDealUuid,
    isError: isErrorDealUuid,
    errors: errorsDealUuid,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    data: dealData,
    isFetching: isFetchingDealData,
    isError: isErrorDealData,
    error: dealError,
  } = useDeal(dealUuidByTileCode)

  const dealDisplayId = dealData?.dealId
  const businessSegmentCode = dealData?.businessSegmentCode

  const {
    data: tranchesWithSubEntitiesData,
    isFetching: isFetchingTranchesWithSubEntitiesData,
    isError: isErrorTranchesWithSubEntitiesData,
    error: tranchesWithSubEntitiesError,
  } = useTranchesWithSubEntities({ dealUuid: dealUuidByTileCode })

  const financialRole = tranchesWithSubEntitiesData?.tranches?.[0]?.financialRole

  const {
    data: applicationTypesData,
    isFetching: isFetchingApplicationTypesData,
    isError: isErrorApplicationTypesData,
    error: applicationTypesError,
  } = useApplicationTypes()

  const {
    data: dealTypes,
    isFetching: isFetchingDealTypes,
    isError: isErrorDealTypes,
    error: dealTypesError,
  } = useDealTypes(businessSegmentCode, { enabled: !!businessSegmentCode })

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isFetchingDealUuid,
      isFetchingDealData,
      isFetchingTranchesWithSubEntitiesData,
      isFetchingApplicationTypesData,
      isFetchingDealTypes,
    ],
    errorValues: [
      isErrorDealUuid,
      isErrorDealData,
      isErrorTranchesWithSubEntitiesData,
      isErrorApplicationTypesData,
      isErrorDealTypes,
    ],
    errorDetails: [
      ...errorsDealUuid,
      dealError,
      tranchesWithSubEntitiesError,
      applicationTypesError,
      dealTypesError,
    ],
    tileId,
  })

  const sourcePathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?working-version=true' : ''),
    [isWorkingVersionDataTile],
  )

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error, data: undefined }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false, data: undefined }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealData: dealData ?? {},
        financialRoleData: financialRole,
        applicationTypesData: applicationTypesData ?? [],
        dealTypes: dealTypes ?? [],
        sourceRender: { dealDisplayId, sourcePathSuffix },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealData,
    financialRole,
    applicationTypesData,
    dealTypes,
    dealDisplayId,
    sourcePathSuffix,
    error,
  ])
}

export default useOpsMemoDealInformation
