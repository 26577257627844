import { useMemo } from 'react'
import { useInfiniteRequest } from 'hooks/services/baseService'
import { useInfiniteRequestsWithTotalMemo } from 'hooks/services/queryHelper'

const defaultEmptyFilter = {}
const defaultEmptySorting = {}

const useRequirements = (
  { entityId, entityType },
  {
    searchFilter,
    documentType,
    externalConditionId,
    conditionType,
    assignee,
    externalAssignee,
    covenantCheck,
    status,
    creationDateFrom,
    creationDateUntil,
    dueDateFrom,
    dueDateUntil,
    references,
    requirementId,
  } = defaultEmptyFilter,
  { sortBy = 'requirement_document_type', orderBy = 'asc' } = defaultEmptySorting,
  options,
) => {
  const queryParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams()

    urlSearchParams.append('entity_type', entityType)
    urlSearchParams.append('entity_id', entityId)
    urlSearchParams.append('sort_by', sortBy)
    urlSearchParams.append('order_by', orderBy)

    if (searchFilter) {
      urlSearchParams.append('search_filter', searchFilter)
    }
    if (conditionType) {
      urlSearchParams.append('condition_type', conditionType)
    }
    if (documentType) {
      urlSearchParams.append('document_type', documentType)
    }
    if (externalConditionId) {
      urlSearchParams.append('condition_uuid', externalConditionId)
    }
    if (assignee) {
      urlSearchParams.append('assignee', assignee)
    }
    if (externalAssignee) {
      urlSearchParams.append('external_assignee', externalAssignee)
    }
    if (covenantCheck) {
      urlSearchParams.append('covenant_check_id', covenantCheck)
    }
    if (status && status.length > 0) {
      urlSearchParams.append('status', status.join(','))
    }
    if (creationDateFrom) {
      urlSearchParams.append('creation_date_from', creationDateFrom)
    }
    if (creationDateUntil) {
      urlSearchParams.append('creation_date_until', creationDateUntil)
    }
    if (dueDateFrom) {
      urlSearchParams.append('due_date_from', dueDateFrom)
    }
    if (dueDateUntil) {
      urlSearchParams.append('due_date_until', dueDateUntil)
    }
    if (references) {
      urlSearchParams.append('references_entity_ids', references.entityIds)
      urlSearchParams.append('references_entity_type', references.entityType)
    }
    if (requirementId) {
      urlSearchParams.append('requirement_id', requirementId)
    }
    return urlSearchParams
  }, [
    entityType,
    entityId,
    sortBy,
    orderBy,
    searchFilter,
    conditionType,
    documentType,
    externalConditionId,
    assignee,
    externalAssignee,
    covenantCheck,
    status,
    creationDateFrom,
    creationDateUntil,
    dueDateFrom,
    dueDateUntil,
    references,
    requirementId,
  ])

  const response = useInfiniteRequest({
    path: '/conditions/external/requirements',
    queryParams,
    translated: true,
    useCache: true,
    keys: ['conditions', 'external', entityType, entityId, 'requirements'],
    options: {
      retry: 1,
      ...options,
    },
  })

  return useInfiniteRequestsWithTotalMemo(response, 'requirements')
}

export default useRequirements
