import { TextAlign } from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import BusinessPartnerCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/cells/BusinessPartnerCell'
import MoneyCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/cells/MoneyCell'
import PercentageCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/cells/PercentageCell'
import TextCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/cells/TextCell'
import {
  NUMBER_SUFFIX_K,
  ROW_TYPE_PRODUCT_CLASS,
  ROW_TYPE_TOTAL,
  ROW_TYPE_UNIT_MEMBER,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/constants'

const useApprovalRequestAuthorityLevelCalculatorTableColumnDefinitions = ({
  showConfirmationForLinks = false,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.authority-level-calculator.table.columns',
  })

  // contrary to the default AuthorityLevelCalculatorTable, the exec summary has the fixed number suffix K as default
  const numberSuffix = NUMBER_SUFFIX_K

  return useMemo(
    () =>
      compact([
        {
          Header: () => (
            <Trans
              ns="decisionPaper"
              i18nKey="tiles.authority-level-calculator.table.columns.businessPartnerProductClass"
            />
          ),
          title: t('businessPartnerProductClass'),
          accessor: 'businessPartnerProductClass',
          minWidth: 380,
          Cell: (props) => {
            const { cell, row } = props
            switch (row.original.rowType) {
              case ROW_TYPE_UNIT_MEMBER:
                return BusinessPartnerCell({
                  hasLeftMargin: isEmpty(row.subRows),
                  value: cell.value,
                  shouldBeMarkedAsBorrower: row.original.isBorrower,
                  showConfirmationForLinks,
                })

              case ROW_TYPE_PRODUCT_CLASS:
                return TextCell({
                  value: cell.value?.name,
                })

              case ROW_TYPE_TOTAL:
                return TextCell({
                  hasLeftMargin: true,
                  value: cell.value,
                  isBold: true,
                })
            }
          },
        },
        {
          Header: t('source'),
          accessor: 'source',
          width: 100,
          Cell: (props) =>
            TextCell({
              value: props.cell.value,
              isBold: props.row.original.isSectionHeader,
            }),
        },
        {
          Header: t('amount'),
          accessor: 'amount',
          width: 180,
          hAlign: TextAlign.Right,
          Cell: (props) =>
            MoneyCell({
              value: props.cell.value,
              isBold: props.row.original.isSectionHeader,
              numberSuffix,
            }),
        },
        {
          Header: () => (
            <Trans
              ns="decisionPaper"
              i18nKey="tiles.authority-level-calculator.table.columns.initialPdRegulatory"
            />
          ),
          title: t('initialPdRegulatory'),
          accessor: 'initialPdRegulatory',
          width: 110,
          hAlign: TextAlign.Right,
          Cell: (props) =>
            PercentageCell({
              value: props.cell.value,
              isBold: props.row.original.isSectionHeader,
            }),
        },
        {
          Header: () => (
            <Trans
              ns="decisionPaper"
              i18nKey="tiles.authority-level-calculator.table.columns.initialPdRegulatoryClass"
            />
          ),
          title: t('initialPdRegulatoryClass'),
          accessor: 'initialPdRegulatoryClass',
          width: 130,
          hAlign: TextAlign.Right,
          Cell: (props) =>
            TextCell({
              value: props.cell.value,
              isBold: props.row.original.isSectionHeader,
            }),
        },
        {
          // In the exec summary, the label should not include "(Calculation)"
          // even though it is in fact the calculated value
          Header: () => (
            <Trans
              ns="decisionPaper"
              i18nKey="tiles.authority-level-calculator.table.columns.pdRegulatoryClass"
            />
          ),
          title: t('pdRegulatoryClass'),
          accessor: 'pdRegulatoryClassCalculation',
          width: 150,
          hAlign: TextAlign.Right,
          Cell: (props) =>
            TextCell({
              value: props.cell.value,
              isBold: props.row.original.isSectionHeader,
            }),
        },
        {
          Header: () => (
            <Trans
              ns="decisionPaper"
              i18nKey="tiles.authority-level-calculator.table.columns.elClass"
            />
          ),
          accessor: 'elClass',
          width: 70,
          hAlign: TextAlign.Right,
          Cell: (props) =>
            TextCell({
              value: props.cell.value,
              isBold: props.row.original.isSectionHeader,
            }),
        },
        {
          Header: () => (
            <Trans
              ns="decisionPaper"
              i18nKey="tiles.authority-level-calculator.table.columns.creditAuthorityLimit"
            />
          ),
          title: t('creditAuthorityLimit'),
          accessor: 'creditAuthorityLimit',
          width: 180,
          hAlign: TextAlign.Right,
          Cell: (props) =>
            MoneyCell({
              value: props.cell.value,
              isBold: props.row.original.isSectionHeader,
              numberSuffix,
            }),
        },
      ]),
    [numberSuffix, showConfirmationForLinks, t],
  )
}

export default useApprovalRequestAuthorityLevelCalculatorTableColumnDefinitions
