import { useMemo } from 'react'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'

const useAnnualReviewDealOverviewSelection = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { selectedDeal, dealsData } = {},
    isLoading,
    isError,
    error: errorDetails,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const selectedDealDisplayId = selectedDeal?.selectedDealDisplayId

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isLoading],
    errorValues: [isError],
    errorDetails: [errorDetails],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        sourceRender: {
          dealDisplayId: selectedDealDisplayId,
        },
      },
    }
  }, [dealsData, error, isSomeValueError, isSomeValueLoading, selectedDealDisplayId])
}

export default useAnnualReviewDealOverviewSelection
