import {
  AnalyticalTableScaleWidthMode,
  CardHeader,
  ToolbarSeparator,
} from '@fioneer/ui5-webcomponents-react'
import debounce from 'lodash.debounce'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/tenancy/as-branding-or-franchise-provider/AsBrandingOrFranchiseProviderCard.module.css'
import { useAsBrandingOrFranchiseProviderCardTableColumns } from 'components/domains/business-partners/tile/tenancy/as-branding-or-franchise-provider/useAsBrandingOrFranchiseProviderCardTableColumns'
import filterRowsBySearchParam from 'components/domains/business-partners/tile/tenancy/filterRowsBySearchParam'
import Card from 'components/ui/card/Card'
import LabelValueList from 'components/ui/data/LabelValueList'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import { useCollapseExpandTableActions } from 'components/ui/tables/analytical/useCollapseExpandTableActions'
import ToolbarSearching from 'components/ui/tables/toolbar/ToolbarSearching'
import {
  useAreaMeasurementUnitFormatter,
  useCustomizableCurrencyFormatter,
  useIntFormatter,
  useNumberFormatter,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'

export const businessPartnerRoleBrandingFranchise = 'branding-franchise'

const AsBrandingOrFranchiseProviderCard = ({
  tableData,
  totalRentInHeadQuarterCurrency,
  totalRentInNonHeadQuarterCurrency,
  totalRentedAreaInHeadQuarterMeasurementUnit,
  totalRentedAreaInNonHeadQuarterMeasurementUnit,
  numberOfRentedProperties,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.tenancy.as-branding-franchise-provider',
  })

  const { format: formatDate } = useShortDateFormatter()
  const formatNumberWithOneDecimalPlace = useNumberFormatter({
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  })
  const formatInt = useIntFormatter()
  const formatCurrency = useCustomizableCurrencyFormatter()
  const formatAreaUnit = useAreaMeasurementUnitFormatter()

  const { columns } = useAsBrandingOrFranchiseProviderCardTableColumns()

  const tableRef = useRef(null)

  const defaultSort = useMemo(() => [{ id: 'description', desc: false }], [])

  const {
    handleOnRowExpand,
    additionalTableActions: collapseExpandActions = [],
    expandOrCollapseSubRows,
  } = useCollapseExpandTableActions(tableRef)

  const [searchParam, setSearchParam] = useState('')

  const toolbarSearching = useMemo(() => {
    const debounceValue = 300
    return {
      searchParam: '',
      onUpdateSearchParam: debounce((updatedValue) => {
        setSearchParam(updatedValue)
        expandOrCollapseSubRows(true)
      }, debounceValue),
    }
  }, [expandOrCollapseSubRows])

  const additionalTableActions = [
    <ToolbarSearching
      searching={toolbarSearching}
      key={'search-branding-or-franchise-pprovider'}
    />,
    <ToolbarSeparator key={'separator-1'} />,
    ...collapseExpandActions,
  ]

  const labelValueMapLeft = [
    {
      label: t('annualized-contracted-rent'),
      value: totalRentInHeadQuarterCurrency
        ? formatCurrency(
            totalRentInHeadQuarterCurrency.number,
            totalRentInHeadQuarterCurrency.currency,
            { currencyDisplay: 'code' },
          )
        : '',
    },
    {
      value: totalRentInNonHeadQuarterCurrency
        ? formatCurrency(
            totalRentInNonHeadQuarterCurrency.number,
            totalRentInNonHeadQuarterCurrency.currency,
            { currencyDisplay: 'code' },
          )
        : '',
      omitMargin: true,
      show: !!totalRentInNonHeadQuarterCurrency,
    },
    {
      label: t('rented-area'),
      value: `${formatNumberWithOneDecimalPlace(
        totalRentedAreaInHeadQuarterMeasurementUnit?.value,
      )} ${formatAreaUnit(totalRentedAreaInHeadQuarterMeasurementUnit?.measurementUnit)}`,
    },
    {
      value: totalRentedAreaInNonHeadQuarterMeasurementUnit
        ? `${formatNumberWithOneDecimalPlace(
            totalRentedAreaInNonHeadQuarterMeasurementUnit?.value,
          )} ${formatAreaUnit(totalRentedAreaInNonHeadQuarterMeasurementUnit?.measurementUnit)}`
        : '',
      omitMargin: true,
      show: !!totalRentedAreaInNonHeadQuarterMeasurementUnit,
    },
  ]

  const labelValueMapRight = [
    {
      label: `${t('properties')}`,
      value: `${formatInt(numberOfRentedProperties)}`,
    },
  ]

  const data = useMemo(
    () =>
      filterRowsBySearchParam({
        rows: tableData,
        searchParam,
        formatDate,
      }),
    [formatDate, searchParam, tableData],
  )

  return (
    <Card header={<CardHeader titleText={t('title')} />} className="cwp-card">
      <div className={[styles.grid, 'sapUiSmallMargin'].join(' ')}>
        <LabelValueList rows={labelValueMapLeft} />
        <LabelValueList rows={labelValueMapRight} />
      </div>
      <div className={styles.table}>
        <AnalyticalTableWithToolbar
          title={t('branding-franchise-taker')}
          columns={columns || []}
          data={data}
          reactTableOptions={{
            autoResetPage: false,
            autoResetExpanded: false,
            autoResetSortBy: false,
            initialState: {},
            autoResetSelectedRows: false,
          }}
          nrOfEntries={numberOfRentedProperties ?? 0}
          additionalActions={additionalTableActions}
          onRowExpandChange={(event) => handleOnRowExpand(event.detail.row)}
          minRows={1}
          headerRowHeight={40}
          overscanCountHorizontal={99}
          rowHeight={70}
          subRowsKey="subRows"
          className={`${styles.brandingFranchiseProviderTable} tree-table`}
          isTreeTable
          scaleWidthMode={AnalyticalTableScaleWidthMode.Smart}
          ref={tableRef}
          defaultSort={defaultSort}
          sortable
          filterable
        />
      </div>
    </Card>
  )
}

export default AsBrandingOrFranchiseProviderCard
