import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { UNIT_TYPE_ID_STANDALONE } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/gcc-involved-parties/constants'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useLeadingAuthorityLevelCalculator from 'hooks/services/business-partners/authority-level-calculator/exec-summary/useLeadingAuthorityLevelCalculator'

const useGccInvolvedPartiesKamGamStatementGcc = (
  { entityRef: { entityId: dealUuid } },
  tileId,
  tileCode,
) => {
  const {
    data: { dealUuid: dealUuidByTileCode } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({
    dealUuid,
    tileCode,
  })
  const {
    data: authorityLevelCalculatorData,
    isFetching: isFetchingAuthorityLevelCalculatorData,
    isError: isErrorAuthorityLevelCalculatorData,
    error: authorityLevelCalculatorError,
  } = useLeadingAuthorityLevelCalculator(dealUuidByTileCode)

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isDealUuidFetching, isFetchingAuthorityLevelCalculatorData],
    errorValues: [isDealUuidError, isErrorAuthorityLevelCalculatorData],
    errorDetails: [authorityLevelCalculatorError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError || isSomeValueLoading) {
      return {
        isLoading: isSomeValueLoading,
        isError: isSomeValueError,
        error,
      }
    }

    const businessPartnerId = authorityLevelCalculatorData?.unitHead?.unitHeadBpId
    return {
      isLoading: isSomeValueLoading,
      isError: isSomeValueError,
      data: {
        sourceRender: { businessPartnerId },
        authorityLevelData: {
          businessPartnerName: authorityLevelCalculatorData?.unitHead?.unitHeadBpFullName,
          businessPartnerId,
          isStandaloneBusinessPartner:
            authorityLevelCalculatorData?.unitHead?.unitTypeId === UNIT_TYPE_ID_STANDALONE,
        },
      },
    }
  }, [authorityLevelCalculatorData, error, isSomeValueError, isSomeValueLoading])
}

export default useGccInvolvedPartiesKamGamStatementGcc
