import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import useApprovalLevels from 'hooks/services/conditions/config/useApprovalLevels'
import useAnalyticalTableIndexHelper from 'hooks/services/conditions/tables/useAnalyticalTableIndexHelper'
import { conditionApprovalLevelChanged } from 'redux/slices/conditions/conditionCreationSlice'

const ConditionCreationApprovalLevelCell = ({
  row: {
    id,
    index,
    depth,
    original: { approvalLevel: approvalLevelCode },
  },
}) => {
  const dispatch = useDispatch()
  const { conditionIndex } = useAnalyticalTableIndexHelper({
    id,
    index,
    depth,
  })

  const onApprovalLevelSelectionChanged = useCallback(
    (_event, newApprovalCode) => {
      dispatch(conditionApprovalLevelChanged({ conditionIndex, approvalLevel: newApprovalCode }))
    },
    [dispatch, conditionIndex],
  )

  return (
    <LoadingSelect
      key={`${conditionIndex}-approval-level-select`}
      id={`${conditionIndex}-approval-level-select`}
      selectedKey={approvalLevelCode}
      loadingHook={useApprovalLevels}
      onChange={onApprovalLevelSelectionChanged}
      optionKeyName="code"
      optionDisplayName="name"
      selectionName="approvalLevels"
    />
  )
}

ConditionCreationApprovalLevelCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    depth: PropTypes.number.isRequired,
    original: PropTypes.shape({
      approvalLevel: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default ConditionCreationApprovalLevelCell
