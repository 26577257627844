import { Icon, TableCell, TableRow } from '@fioneer/ui5-webcomponents-react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import styles from 'components/domains/properties/portfolio/rent-roll/PropertyPortfolioRentRollCardTableRow.module.css'
import PropertyRentRollExcelExporter from 'components/domains/properties/rent-roll/excel-export/PropertyRentRollExcelExporter'
import StaffMemberByUserId from 'components/ui/staff-member/StaffMemberByUserId'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { useRentRoll } from 'hooks/services/properties/useRentRoll'

const NO_DATA_PLACEHOLDER = '-'

const PropertyPortfolioRentRollCardTableRow = ({ property, workingVersions }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.properties.portfolio.rent-roll.properties-card.table',
  })
  const { data: rentRollData, isError, isLoading, isNotFoundError } = useRentRoll(property.uuid)
  const { format: formatDate } = useShortDateFormatter()
  const creator = rentRollData?.system_administrative_data?.creation_user_account_id
  const rentRoll =
    !isError && !isLoading && !isNotFoundError
      ? {
          creator: creator ? <StaffMemberByUserId s4UserId={creator} /> : '',
          keyDate: rentRollData?.key_date ? formatDate(rentRollData.key_date) : '',
          comment: rentRollData?.description ?? '',
        }
      : {}

  const renderPropertyCell = (description, id) => (
    <>
      <Link id={`${id}-property-link`} to={`/properties/${id}`} className={styles.propertyLink}>
        {description}
      </Link>
      <div>{id}</div>
    </>
  )

  const renderRentRollLink = (id) =>
    isEmpty(rentRoll) ? (
      NO_DATA_PLACEHOLDER
    ) : (
      <>
        <Link
          id={`${id}-rent-roll-link`}
          to={`/properties/${id}/rent-roll`}
          className={styles.link}
        >
          {t('show')}
        </Link>
        <PropertyRentRollExcelExporter properties={[property]}>
          {({ onClick }) => (
            <Icon
              className={styles.exportIcon}
              key={'export-rent-roll'}
              name="excel-attachment"
              interactive
              onClick={onClick}
            />
          )}
        </PropertyRentRollExcelExporter>
      </>
    )

  const setValueOrDefault = (value) => (value ? value : NO_DATA_PLACEHOLDER)
  const setRentRollValueOrDefault = (value) =>
    isEmpty(rentRoll) ? NO_DATA_PLACEHOLDER : setValueOrDefault(value)

  const renderRentRollCells = () => (
    <>
      <TableCell className={styles.centerColumn}>
        {setRentRollValueOrDefault(rentRoll.creator)}
      </TableCell>
      <TableCell className={styles.keyDateColumn}>
        {setRentRollValueOrDefault(rentRoll.keyDate)}
      </TableCell>
      <TableCell>{setRentRollValueOrDefault(rentRoll.comment)}</TableCell>
    </>
  )

  const workingVersionsRelatedToProperty = workingVersions.filter((workingVersion) =>
    workingVersion.property_uuids.includes(property.uuid),
  )

  const { pathname, search } = useLocation()
  const isSinglePropertyWorkingVersion =
    workingVersionsRelatedToProperty?.length === 1 &&
    workingVersionsRelatedToProperty?.[0]?.property_uuids?.length === 1

  const multiPropertyWorkingVersionLink = `${pathname}/working-version${search}`
  const singlePropertyWorkingVersionLink = `/properties/${property.id}/rent-roll/working-version`
  const workingVersionLink = () =>
    search && !isSinglePropertyWorkingVersion
      ? multiPropertyWorkingVersionLink
      : singlePropertyWorkingVersionLink

  const renderWorkingVersionLink = (id) =>
    isEmpty(workingVersionsRelatedToProperty) ? (
      NO_DATA_PLACEHOLDER
    ) : (
      <Link
        id={`${id}-working-version-link`}
        to={workingVersionLink()}
        target={isSinglePropertyWorkingVersion ? '_blank' : ''}
        className={styles.link}
      >
        {t('show')}
      </Link>
    )

  return (
    <TableRow id={property.id} key={property.uuid}>
      <TableCell>{renderPropertyCell(property.description, property.id)}</TableCell>
      <TableCell className={styles.centerColumn}>{renderRentRollLink(property.id)}</TableCell>
      {renderRentRollCells()}
      <TableCell className={styles.centerColumn}>{renderWorkingVersionLink(property.id)}</TableCell>
    </TableRow>
  )
}

PropertyPortfolioRentRollCardTableRow.propTypes = {
  property: PropTypes.object.isRequired,
  workingVersions: PropTypes.array,
}

export default PropertyPortfolioRentRollCardTableRow
