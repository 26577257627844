import { FlexBox, FlexBoxDirection, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/deal-selection/AnnualReviewDealOverviewSelectionTile.module.css'
import createDealSelectOptions from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/utils/createDealSelectOptions'
import CloseableMessageStrip from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/feedback/CloseableMessageStrip'
import LoadingSelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/select/LoadingSelect'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import { setTileMetadata } from 'redux/slices/decision-paper/tilesOverviewSlice'

const AnnualReviewDealOverviewSelectionTile = ({ tileId, isPdfView }) => {
  const dispatch = useDispatch()
  const { t: tNoPrefix } = useTranslation('decisionPaper')
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.deal-selection',
  })

  const { dealsData = [] } =
    useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId].data) ?? {}

  const {
    dealUuid: preSelectedDealUuid,
    dealName: preSelectedDealName,
    dealDisplayId: preSelectedDealDisplayId,
  } = dealsData?.[0] ?? {}

  const dealMetadata = useSelector(
    (state) =>
      state.decisionPaper.tilesOverview.tileMetadata?.[
        availableAutomaticTiles.annualReviewDealSelection
      ],
  )

  useEffect(() => {
    if (isNil(dealMetadata) && !isNil(preSelectedDealUuid)) {
      dispatch(
        setTileMetadata({
          tileCode: availableAutomaticTiles.annualReviewDealSelection,
          metadata: {
            selectedDealUuid: preSelectedDealUuid,
            selectedDealName: preSelectedDealName,
            selectedDealDisplayId: preSelectedDealDisplayId,
          },
        }),
      )
    }
  }, [
    dispatch,
    dealsData,
    dealMetadata,
    preSelectedDealUuid,
    preSelectedDealName,
    preSelectedDealDisplayId,
  ])

  const handleOnChange = useCallback(
    (_event, selectedDealUuid) => {
      const { dealName: selectedDealName, dealDisplayId: selectedDealDisplayId } =
        dealsData.find((deal) => deal?.dealUuid === selectedDealUuid) ?? {}

      dispatch(
        setTileMetadata({
          tileCode: availableAutomaticTiles.annualReviewDealSelection,
          metadata: { selectedDealUuid, selectedDealName, selectedDealDisplayId },
        }),
      )
    },
    [dealsData, dispatch],
  )

  const dealSelectData = createDealSelectOptions(tNoPrefix, dealsData)

  const loadingHook = useCallback(
    () => ({
      isLoading: false,
      isError: false,
      data: dealSelectData,
    }),
    [dealSelectData],
  )

  const renderedDealSelect = useMemo(() => {
    if (isEmpty(dealsData)) {
      return (
        <CloseableMessageStrip design={MessageStripDesign.Information} hideCloseButton={true}>
          {t('no-deals')}
        </CloseableMessageStrip>
      )
    }
    if (isPdfView) {
      return (
        <CloseableMessageStrip design={MessageStripDesign.Information} hideCloseButton={true}>
          {t('pdf-view')}
        </CloseableMessageStrip>
      )
    }
    return (
      <LoadingSelect
        id="annualReviewDealSelection-loadingSelect"
        className={styles.annualReviewDealOverviewSelect}
        loadingHook={loadingHook}
        selectedKey={dealMetadata?.selectedDealUuid}
        showEmptyOption={isNil(dealsData)}
        optionDisplayName="name"
        optionKeyName="key"
        onChange={handleOnChange}
      />
    )
  }, [dealMetadata?.selectedDealUuid, dealsData, handleOnChange, isPdfView, loadingHook, t])

  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      className={styles.annualReviewDealOverviewSelectionWrapper}
    >
      {renderedDealSelect}
    </FlexBox>
  )
}

AnnualReviewDealOverviewSelectionTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default AnnualReviewDealOverviewSelectionTile
