import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

import { useCustomizableCurrencyFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/hooks/i18n/useI18n'

const PropertyValuationResultsValueAmountCell = ({ valueAmount, currency }) => {
  const formatCurrency = useCustomizableCurrencyFormatter({
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  if (!valueAmount || !currency) {
    return null
  }
  return <Text>{formatCurrency(valueAmount, currency)}</Text>
}

PropertyValuationResultsValueAmountCell.propTypes = {
  valueAmount: PropTypes.number,
  currency: PropTypes.string,
}

export default PropertyValuationResultsValueAmountCell
