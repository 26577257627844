import {
  Bar,
  Button,
  ButtonDesign,
  Dialog,
  FlexBox,
  FlexBoxDirection,
  Icon,
  Label,
  RadioButton,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/documents/CancelDocumentUploadDialog.module.css'

export const discardDocumentsChoice = {
  current: 'current',
  allSubsequent: 'all-subsequent',
}

const initialChoice = discardDocumentsChoice.current

const CancelDocumentUploadDialog = ({ isOpen, onDiscard, onCancel }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.markets.detail.cancel-upload-dialog',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const [choice, setChoice] = useState(initialChoice)

  const handleOkClick = () => {
    onDiscard({ selection: choice })
    setChoice(initialChoice)
  }

  const handleSelectCurrent = () => setChoice(discardDocumentsChoice.current)
  const handleSelectAllSubsequent = () => setChoice(discardDocumentsChoice.allSubsequent)

  return createPortal(
    <Dialog
      open={isOpen}
      header={
        <>
          <Icon name="sap-icon://sys-help-2" design="Neutral" />
          <h1 className={styles.title}>{t('title')}</h1>
        </>
      }
      headerText={t('title')}
      footer={
        <Bar
          endContent={
            <>
              <Button design={ButtonDesign.Emphasized} onClick={handleOkClick}>
                {tNoPrefix('buttons.ok')}
              </Button>
              <Button design={ButtonDesign.Transparent} onClick={onCancel}>
                {tNoPrefix('buttons.cancel')}
              </Button>
            </>
          }
        />
      }
    >
      <FlexBox direction={FlexBoxDirection.Column} className={styles.content}>
        <Label>
          <span className={styles.description}>{t('what-would-you-want-to-do')}</span>
        </Label>
        <FlexBox className={styles.row} direction={FlexBoxDirection.Row}>
          <RadioButton
            name="chooseDiscardMode"
            id="radio-button-discard-current"
            text=""
            checked={choice === discardDocumentsChoice.current}
            onChange={handleSelectCurrent}
          />
          <Label
            id="radio-button-discard-current-label"
            for="radio-button-discard-current"
            onClick={handleSelectCurrent}
            className={styles.label}
          >
            <h2 className={styles.optionTitle}>{t('discard-current.title')}</h2>
            <span className={styles.description}>{t('discard-current.text')}</span>
          </Label>
        </FlexBox>
        <FlexBox className={styles.row} direction={FlexBoxDirection.Row}>
          <RadioButton
            name="chooseDiscardMode"
            id="radio-button-discard-all-subsequent"
            text=""
            checked={choice === discardDocumentsChoice.allSubsequent}
            onChange={handleSelectAllSubsequent}
          />
          <Label
            id="radio-button-discard-all-subsequent-label"
            for="radio-button-discard-all-subsequent"
            onClick={handleSelectAllSubsequent}
            className={styles.label}
          >
            <h2 className={styles.optionTitle}>{t('discard-all-subsequent.title')}</h2>
            <span className={styles.description}>{t('discard-all-subsequent.text')}</span>
          </Label>
        </FlexBox>
      </FlexBox>
    </Dialog>,
    document.body,
  )
}

CancelDocumentUploadDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDiscard: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default CancelDocumentUploadDialog
