import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateManualTile = (mutationOptions) => {
  const { put } = useAccessTokenRequest()
  return useMutation(
    ({ eventId, tileId, data }) =>
      put({
        path: `/events/${eventId}/decision-paper/versions/current/tiles/${tileId}/manual`,
        body: {
          data,
        },
      }),
    mutationOptions,
  )
}

export default useUpdateManualTile
