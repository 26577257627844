import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { isNotFoundError } from 'api/requests'
import { useRequest, useRequests } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const RETRIES = 3

export const useBusinessPartnerById = (businessPartnerId, options = {}, requestOptions = {}) => {
  const response = useRequest({
    path: `/businesspartners/${businessPartnerId}`,
    translated: true,
    keys: ['businesspartners', businessPartnerId],
    options: {
      retry: (failureCount, error) => {
        if (isNotFoundError(error)) {
          return false
        }
        return failureCount < RETRIES
      },
      enabled: !!businessPartnerId,
      ...options,
    },
    requestOptions,
  })

  const returnValue = {
    ...response,
    data: camelize(response.data),
    isNotFoundError: response.isError && isNotFoundError(response.error),
  }

  if (response.data?._allowed_operations) {
    returnValue.data.allowedOperations = response.data?._allowed_operations
    delete returnValue.data.AllowedOperations
  }

  return returnValue
}

export const useBusinessPartnersByIds = (businessPartnerIds, options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/businesspartners/ids?ids=${businessPartnerIds}`,
      useCache: false,
      options: { enabled: !isEmpty(businessPartnerIds), ...options },
    }),
  )

/**
 * **Always try to use `useBusinessPartnersByIds` instead. This is less performant**
 *
 * Requests multiple BPs by their IDs
 *
 * HINT: There are some fields (e. g. industry) that are returned when requesting a single BP
 *       but not when requesting a list of them (what `useBusinessPartnersByIds` does)
 */
export const useMultipleBusinessPartnersById = (businessPartnerIds = [], options) => {
  const responses = useRequests({
    requests: businessPartnerIds.map((businessPartnerId) => ({
      path: `/businesspartners/${businessPartnerId}`,
    })),
    options,
  })
  return responses.map((result) => ({ ...result, data: camelize(result.data) }))
}
