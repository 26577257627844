import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useEditConditionComment = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(async ({ conditionId, commentId, comment, conditionType }) => {
    const { data } = await patch({
      path: `/conditions/${conditionType}/${conditionId}/comments/${commentId}`,
      body: {
        comment,
      },
    })
    return data
  }, mutationOptions)
}

export default useEditConditionComment
