import isEmpty from 'lodash.isempty'

const nameChanged = (state, { payload: { name: newName } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }
  state.editedRow.currentValues.name = newName
  delete state.editedRow.errorFields.name
  if (newName === state.editedRow.initialValues.name) {
    delete state.editedRow.changedFields.name
    return
  }
  state.editedRow.changedFields.name = true
}

export default nameChanged
