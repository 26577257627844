import { useRequest } from 'hooks/services/baseService'
import { useFrontendPagination } from 'hooks/services/deals/useFrontendPagination'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const initialMonitoringItemSort = [
  { orderField: 'statusOrder', orderDirection: 'asc' },
  { orderField: 'dueDate.targetDeliveryDate', orderDirection: 'asc' },
]

const StatusToValue = {
  ACTIVE: 1,
  POSTPONED: 2,
  PLANNED: 3,
  CLOSED: 4,
  DEFAULT: 5,
}

const extendItemsWithStatusOrder = (items = []) =>
  items.map((item) => ({
    ...item,
    statusOrder: StatusToValue[item.status] ?? StatusToValue.DEFAULT,
  }))

const useCovenantMonitoringItems = (covenantUuid, options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/covenants/${covenantUuid}/monitoring-information/items`,
      keys: ['covenants', covenantUuid, 'monitoring', 'items'],
      options: {
        enabled: !!covenantUuid,
        retry: 1,
        ...options,
      },
    }),
  )

export const useCovenantMonitoringItemsWithFrontendPagination = ({
  covenantUuid,
  dataKey,
  options = {},
  sort = {},
  pagination = {},
}) => {
  const result = useCovenantMonitoringItems(covenantUuid, options)

  const extendedResult = {
    ...result,
    data: {
      ...result.data,
      [dataKey]: extendItemsWithStatusOrder(result?.data?.[dataKey]),
    },
  }

  return useFrontendPagination({
    fetchResult: extendedResult,
    dataKey,
    initialSort: initialMonitoringItemSort,
    sort,
    pagination,
  })
}

export default useCovenantMonitoringItems
