import { combineReducers } from '@reduxjs/toolkit'
import taskCreationReducer from 'redux/slices/tasks/taskCreationSlice'
import taskTableReducer from 'redux/slices/tasks/taskTableSlice'

const tasksReducer = combineReducers({
  taskCreation: taskCreationReducer,
  taskTable: taskTableReducer,
})

export default tasksReducer
