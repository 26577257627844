import {
  Button,
  Link,
  MessageBoxActions,
  MessageBoxTypes,
} from '@fioneer/ui5-webcomponents-react/dist'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import DialogPrimaryButton from 'components/ui/dialog/DialogPrimaryButton'
import MessageBox from 'components/ui/message-box/MessageBox'
import { ConfigContext } from 'hooks/config/useConfig'
import { stringTemplate } from 'utils/stringTemplate'

const TrancheDetailsOpenInCmlButton = ({ externalContractId }) => {
  const config = useContext(ConfigContext)

  const linkTemplate = config.trancheDetails ? config.trancheDetails.linktocml : ''

  const to = stringTemplate(linkTemplate, {
    externalContractId: externalContractId.replace(/-/g, '').toUpperCase(),
  })

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.tranches.details.cml',
  })
  const { t: tNoPrefix } = useTranslation()

  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const handleClick = () => {
    setIsConfirmOpen(true)
  }

  const handleMessageBoxClose = () => {
    setIsConfirmOpen(false)
  }

  return (
    <>
      <Button onClick={handleClick}>{t('button')}</Button>
      {createPortal(
        <MessageBox
          open={isConfirmOpen}
          onClose={handleMessageBoxClose}
          type={MessageBoxTypes.Information}
          actions={[
            <Link key="okay-button" href={to} target="_blank">
              <DialogPrimaryButton>{tNoPrefix('buttons.ok')}</DialogPrimaryButton>
            </Link>,
            MessageBoxActions.Close,
          ]}
        >
          {t('confirmation-message')}
        </MessageBox>,
        document.body,
      )}
    </>
  )
}

TrancheDetailsOpenInCmlButton.propTypes = {
  externalContractId: PropTypes.string,
}

export default TrancheDetailsOpenInCmlButton
