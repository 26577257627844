import {
  Button,
  ButtonDesign,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useRequestConditionDecision from 'hooks/services/business-events-and-tasks/decision-process/stages/conditions/useRequestConditionDecision'

const useRequestConditionDecisionFlow = ({ eventId, decisionStageId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.conditions',
  })
  const showMessageBox = Modals.useShowMessageBox()
  const queryClient = useQueryClient()
  const { mutate: requestDecision } = useRequestConditionDecision()

  const onRequestDecisionSuccess = useCallback(() => {
    queryClient.invalidateQueries(['events', eventId, 'decision-stages'])
    showMessageBox(
      {
        titleText: t('request.success.title'),
        children: <Text wrapping>{t('request.success.text')}</Text>,
        draggable: true,
        resizable: true,
        type: MessageBoxTypes.Success,
        actions: [MessageBoxActions.OK],
      },
      document.body,
    )
  }, [showMessageBox, t, queryClient, eventId])

  const onRequestDecisionError = useCallback(() => {
    showMessageBox(
      {
        type: MessageBoxTypes.Error,
        children: t('request.error'),
        draggable: true,
        resizable: true,
      },
      document.body,
    )
  }, [showMessageBox, t])

  const requestDecisionConfirmed = useCallback(() => {
    requestDecision(
      { eventId, decisionStageId },
      { onSuccess: onRequestDecisionSuccess, onError: onRequestDecisionError },
    )
  }, [requestDecision, eventId, decisionStageId, onRequestDecisionSuccess, onRequestDecisionError])

  const onRequestDecisionButtonClicked = useCallback(() => {
    showMessageBox(
      {
        titleText: t('request.title'),
        children: <Text wrapping>{t('request.text')}</Text>,
        draggable: true,
        resizable: true,
        type: MessageBoxTypes.Confirm,
        actions: [
          <Button
            key="request-condition-decision-confirm"
            onClick={requestDecisionConfirmed}
            design={ButtonDesign.Emphasized}
          >
            {t('buttons.request-decision')}
          </Button>,
          MessageBoxActions.Cancel,
        ],
      },
      document.body,
    )
  }, [t, showMessageBox, requestDecisionConfirmed])

  return useMemo(
    () => ({
      onRequestDecisionButtonClicked,
    }),
    [onRequestDecisionButtonClicked],
  )
}

export default useRequestConditionDecisionFlow
