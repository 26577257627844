import { Icon, TableRowType, Text } from '@fioneer/ui5-webcomponents-react'
import isEqual from 'lodash.isequal'
import uniqBy from 'lodash.uniqby'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/covenants/covenant-checks-table/CovenantChecksTable.module.css'
import useCovenantCheckDealIdsByUuids from 'components/domains/deals/covenants/covenant-checks-table/useCovenantCheckDealIdsByUuids'
import BreachCell from 'components/domains/deals/covenants/shared/BreachCell'
import LoadingEventEntity from 'components/domains/deals/covenants/shared/LoadingEventEntity'
import StatusCell from 'components/domains/deals/covenants/shared/StatusCell'
import Entity from 'components/ui/data/Entity'
import ReferenceEntities from 'components/ui/data/ReferenceEntities'
import ReferenceEntitiesView, { entitiesQuantity } from 'components/ui/data/ReferenceEntitiesView'
import { ReferenceEntityType } from 'components/ui/data/ReferenceEntityType'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { dealDetailPaths } from 'routes/deals/DealRoutes'
import paths from 'routes/paths'

export const covenantChecksBreachMap = new Map()
covenantChecksBreachMap.set(true, 'YES')
covenantChecksBreachMap.set(false, 'NO')

const useCovenantChecksTableData = ({
  data,
  isAllowedToReadCovenants,
  isCovenantCheckClickable,
}) => {
  const { format: formatDate } = useShortDateFormatter()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.covenants.monitoring.covenant-checks',
  })
  const { data: allDeals, isLoading } = useCovenantCheckDealIdsByUuids({ covenantData: data })
  const allDealUuidsToDealIds = useMemo(
    () =>
      allDeals?.reduce(
        (accumulator, { dealUuid, dealId, name }) => ({
          ...accumulator,
          [dealUuid]: { dealId: dealId, name: name },
        }),
        {},
      ) || {},
    [allDeals],
  )

  const getCovenantsReferenceEntitiesProps = (covenants) =>
    covenants.length === 1
      ? {
          quantity: entitiesQuantity.single,
          link:
            isAllowedToReadCovenants &&
            `/${paths.deals}/${allDealUuidsToDealIds[covenants[0].mainEntity.id]?.dealId}/${
              dealDetailPaths.covenants
            }/${covenants[0].covenantUuid}`,
          name: covenants[0].name,
        }
      : {
          quantity: entitiesQuantity.multiple,
          listItems: covenants.map((covenant) => ({
            id: covenant.covenantUuid,
            name: covenant.name,
            individualLinkBasePath: `/${paths.deals}/${
              allDealUuidsToDealIds[covenant.mainEntity.id]?.dealId
            }/${dealDetailPaths.covenants}`,
          })),
          linkBasePath: '',
          linkText: t('link.covenants'),
          disableLink: !isAllowedToReadCovenants,
          shouldDisplayEntityId: false,
        }

  const getDealsReferenceEntitiesProps = (covenantCheckDeals) =>
    covenantCheckDeals.length === 1
      ? {
          quantity: entitiesQuantity.single,
          id: covenantCheckDeals[0].dealId,
          link: isAllowedToReadCovenants && `/${paths.deals}/${covenantCheckDeals[0].dealId}`,
          name: covenantCheckDeals[0].name,
        }
      : {
          quantity: entitiesQuantity.multiple,
          listItems: covenantCheckDeals.map((deal) => ({
            id: deal.dealId,
            name: deal.name,
          })),
          linkBasePath: `/${paths.deals}`,
          linkText: t('link.deals'),
          disableLink: !isAllowedToReadCovenants,
          shouldDisplayEntityId: false,
        }

  if (isLoading) {
    return {
      tableData: [],
      isLoading: isLoading,
    }
  }
  const tableData = data.map(
    ({
      covenantCheckUuid,
      breach,
      covenants,
      keyDate,
      firstTargetDeliveryDate,
      name,
      status,
      monitoringReferenceEntity,
      linkedEventId,
    }) => {
      const covenantsReferenceEntitiesProps = getCovenantsReferenceEntitiesProps(covenants)
      const covenantCheckDeals = uniqBy(
        covenants.map((covenant) => ({
          ...allDealUuidsToDealIds[covenant.mainEntity.id],
        })),
        'dealId',
      ).filter((covenantCheckDeal) => !isEqual(covenantCheckDeal, {}))

      const dealsReferenceEntitiesProps = getDealsReferenceEntitiesProps(covenantCheckDeals)
      const covenantCheckEntityProps = {
        name: name,
        link: undefined,
        openInNewTab: undefined,
      }
      if (isCovenantCheckClickable) {
        covenantCheckEntityProps.openInNewTab = true
        switch (monitoringReferenceEntity.type) {
          case ReferenceEntityType.Deal:
            covenantCheckEntityProps.link = `/${paths.deals}/${
              allDealUuidsToDealIds[monitoringReferenceEntity.id]?.dealId
            }/${dealDetailPaths.covenants}/${dealDetailPaths.covenantChecks}/${covenantCheckUuid}`
            break
          case ReferenceEntityType.BusinessPartner:
            covenantCheckEntityProps.link = `/${paths.businessPartners}/${monitoringReferenceEntity.id}/covenants/covenant-checks/${covenantCheckUuid}`
            break
        }
      }

      return {
        rowKey: covenantCheckUuid,
        rowProperties: {
          className: breach ? styles.markLeftBorder : undefined,
          type:
            isAllowedToReadCovenants && !isCovenantCheckClickable
              ? TableRowType.Active
              : TableRowType.Inactive,
          'covenant-check-uuid': covenantCheckUuid,
          'monitoring-reference-entity-type': monitoringReferenceEntity.type,
          'monitoring-entity-display-id':
            monitoringReferenceEntity.type === ReferenceEntityType.Deal
              ? allDealUuidsToDealIds[monitoringReferenceEntity.id]?.dealId
              : monitoringReferenceEntity.id,
        },
        'covenant-check': {
          value: name,
          cellComponent: <Entity {...covenantCheckEntityProps} />,
        },
        'monitoring-reference-type': {
          value: t(`enum.${monitoringReferenceEntity?.type}`),
          cellComponent: <span>{t(`enum.${monitoringReferenceEntity?.type}`)}</span>,
        },
        'monitoring-reference': {
          value: monitoringReferenceEntity?.id,
          cellComponent: (
            <ReferenceEntities
              type={monitoringReferenceEntity.type}
              ids={[monitoringReferenceEntity.id]}
            />
          ),
        },
        deals: {
          cellComponent: covenantCheckDeals.length ? (
            <ReferenceEntitiesView {...dealsReferenceEntitiesProps} />
          ) : (
            <></>
          ),
        },
        covenants: {
          value: covenants.map(({ name: covenantName }) => covenantName).join(' '),
          cellComponent: covenants.length ? (
            <ReferenceEntitiesView {...covenantsReferenceEntitiesProps} />
          ) : (
            <></>
          ),
        },
        'monitoring-event': {
          value: linkedEventId,
          cellComponent: <LoadingEventEntity eventId={linkedEventId} />,
        },
        'key-date': {
          value: keyDate,
          cellComponent: formatDate(keyDate),
        },
        status: {
          value: t(`enum.${status}`),
          cellComponent: <StatusCell status={status} />,
        },
        'first-target-delivery-date': {
          value: firstTargetDeliveryDate,
          cellComponent: formatDate(firstTargetDeliveryDate),
        },
        breach: {
          value: t(`enum.${covenantChecksBreachMap.get(breach)}`),
          cellComponent: (
            <BreachCell
              breach={covenantChecksBreachMap.get(breach)}
              popoverContent={
                <>
                  <Text className={styles.breachText}>{t('breach.text')}</Text>
                  <br />
                  <br />
                  <Text>{t('breach.details-text')}</Text>
                </>
              }
            />
          ),
        },
        arrow: {
          cellComponent: isAllowedToReadCovenants && <Icon name="slim-arrow-right" />,
        },
      }
    },
  )
  return { tableData, isLoading }
}

export default useCovenantChecksTableData
