import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteTaskComment = (mutationOptions) => {
  const { delete: deleteComment } = useAccessTokenRequest()

  return useMutation(async ({ eventId, taskId, commentId }) => {
    const { data } = await deleteComment({
      path: `/events/${eventId}/tasks/${taskId}/comments/${commentId}`,
      keys: ['events', eventId, 'tasks', taskId, 'comments', commentId],
    })
    return data
  }, mutationOptions)
}

export default useDeleteTaskComment
