import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/table-cells/conditions/TableCell.module.css'

const ConditionsTableNameCell = ({ value: { name } }) => (
  <Text className={styles.title} wrapping>
    {name}
  </Text>
)

ConditionsTableNameCell.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
}

export default ConditionsTableNameCell
