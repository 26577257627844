import { useEffect, useRef } from 'react'
import {
  COMPARABLE_STATUS,
  COMPARABLE_STATUS_COLORS,
} from 'components/domains/properties/rent-roll/comparison/constants'

const useTenantCellBorder = (comparableStatus) => {
  const BIG_BORDER_WIDTH = 4
  const NEUTRAL_BORDER_WIDTH = 1
  const childRef = useRef(null)
  const borderLeft = (width, color) => `${width}px solid ${color}`
  useEffect(() => {
    const isNewOrOld =
      comparableStatus === COMPARABLE_STATUS.NEW || comparableStatus === COMPARABLE_STATUS.OLD
    let parentElement = childRef.current
    while (parentElement && parentElement?.getAttribute('role') !== 'cell') {
      parentElement = parentElement.parentNode
    }
    if (parentElement && isNewOrOld) {
      parentElement.style.borderLeft = borderLeft(
        BIG_BORDER_WIDTH,
        COMPARABLE_STATUS_COLORS[comparableStatus],
      )
    } else if (parentElement) {
      parentElement.style.borderLeft = borderLeft(
        NEUTRAL_BORDER_WIDTH,
        COMPARABLE_STATUS_COLORS.NEUTRAL,
      )
    }
  }, [comparableStatus])
  return childRef
}
export default useTenantCellBorder
