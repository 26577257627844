import {
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
  Option,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { eventStatus } from 'api/events/status'
import EventsSummaryCard from 'components/domains/business-events-and-tasks/events/events-summary-card/EventsSummaryCard'
import EventsSummaryCardHeader from 'components/domains/business-events-and-tasks/events/events-summary-card/EventsSummaryCardHeader'
import styles from 'components/domains/business-events-and-tasks/events/events-summary-card/EventsSummaryCardWrapper.module.css'
import Card from 'components/ui/card/Card'
import CardHeaderWithEditMode from 'components/ui/card/CardHeaderWithEditMode'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useEvents } from 'hooks/services/business-events-and-tasks/events/useEvents'
import paths from 'routes/paths'

/**
 * Wrapper around Card to display events of a given business object id
 * @param entity object that contains the business object data
 *  - entityType: the type of the business object, e.g. PROPERTY
 *  - entityId: the id of the business object
 *  - entityName: the name of the business object
 *  - entityDisplayId: the display id of the business object
 * @param displayCreateEventButton boolean if the user has the rights to create an event for the business object
 */
const EventsSummaryCardWrapper = ({ entity, displayCreateEventButton }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.events.events-summary-card-wrapper',
  })
  const { t: tNoPrefix } = useTranslation()

  const initialFilterStatusArray = [eventStatus.drafted, eventStatus.ongoing]

  const initialFilterParams = {
    entityId: entity.entityId,
    status: initialFilterStatusArray,
  }

  const allStatusFilterParams = {
    entityId: entity.entityId,
    status: undefined,
  }

  const sorting = {
    sortBy: 'event_summary',
    orderBy: 'asc',
  }

  const [filterParams, setFilterParams] = useState(initialFilterParams)

  const { isLoading, isError, data: eventData } = useEvents(sorting, filterParams)

  const {
    isLoading: isTotalCountLoading,
    isError: isTotalCountError,
    data: totalCountEventData,
  } = useEvents(undefined, allStatusFilterParams)

  const eventsLink = `/${paths.businessEventsAndTasks}?entity_id=${entity.entityId}`

  const selectOptions = [
    <Option
      key={'draftAndInProgress'}
      value={'draftAndInProgress'}
      data-id={'draftAndInProgress'}
      selected={!!filterParams.status}
    >
      {t('in-progress-and-draft')}
    </Option>,
    <Option key={'all'} value={'all'} data-id={'all'} selected={!filterParams.status}>
      {t('all')}
    </Option>,
  ]

  const handleFilterChange = (eventStatusString) => {
    const status =
      eventStatusString === 'all' ? undefined : [eventStatus.drafted, eventStatus.ongoing]
    setFilterParams({ ...filterParams, status })
  }

  const queryClient = useQueryClient()
  useEffect(() => {
    const eventCreatedByChildWindowHandler = (event) => {
      if (event.data === 'event-created-by-child-window') {
        queryClient.invalidateQueries(['events'])
      }
    }

    window.addEventListener('message', eventCreatedByChildWindowHandler)

    return () => {
      window.removeEventListener('message', eventCreatedByChildWindowHandler)
    }
    // register this listener on mount only, no need to depend on queryClient
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderCustomErrorScreen = () => (
    <IllustratedMessage
      name={IllustrationMessageType.UnableToLoad}
      size={IllustrationMessageSize.Spot}
      titleText={tNoPrefix('app.loading.error.title')}
      subtitleText={tNoPrefix('app.loading.error.description')}
    />
  )

  const renderHeader = () => {
    if (!isTotalCountLoading && totalCountEventData && totalCountEventData.total === 0)
      return (
        <CardHeaderWithEditMode
          isEditMode={false}
          titleText={t('header-title')}
          showActions={false}
        />
      )

    return (
      <EventsSummaryCardHeader
        titleLinkPath={eventsLink}
        selectOptions={selectOptions}
        handleFilterChange={handleFilterChange}
      />
    )
  }

  const renderContent = () => (
    <EventsSummaryCard
      events={eventData.events}
      entity={entity}
      displayCreateEventButton={displayCreateEventButton}
    />
  )

  return (
    <Card className={styles.eventsCard} header={renderHeader()}>
      <LoadingStateWrapper
        isLoading={isLoading || isTotalCountLoading}
        isError={isError || isTotalCountError}
        renderContent={renderContent}
        renderCustomErrorScreen={renderCustomErrorScreen}
      />
    </Card>
  )
}

EventsSummaryCardWrapper.propTypes = {
  entity: PropTypes.shape({
    entityName: PropTypes.string.isRequired,
    entityId: PropTypes.string.isRequired,
    entityDisplayId: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
  }).isRequired,
  displayCreateEventButton: PropTypes.bool.isRequired,
}

export default EventsSummaryCardWrapper
