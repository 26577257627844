import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAreaMeasurementUnitFormatter } from 'hooks/i18n/useI18n'
import createRentRollExcelExport from 'hooks/services/properties/rent-roll/excel-export/createRentRollExcelExport'
import generateRentRollExcelExportFileName from 'hooks/services/properties/rent-roll/excel-export/generateRentRollExcelExportFileName'
import { useRentRolls } from 'hooks/services/properties/rent-roll/useRentRolls'
import { useExpandRentalUnitsWithBusinessPartners } from 'hooks/services/properties/useExpandRentalUnitsWithBusinessPartners'
import { useUtilizationCodes } from 'hooks/services/properties/useUtilizationCodes'

export default function useRentRollExcelExport(properties, multiPropertyViewId) {
  const propertyIdByUuid = Object.fromEntries(properties.map(({ uuid, id }) => [uuid, id]))

  const { t } = useTranslation('translation')
  const areaMeasurementUnitFormatter = useAreaMeasurementUnitFormatter()
  const { isError: isUtilizationCodesError, data: utilizationCodesResponse } = useUtilizationCodes()

  const [isExcelExportInProgress, setIsExcelExportInProgress] = useState(false)
  const [isExcelExportError, setIsExcelExportError] = useState(false)
  const [excelExportErrors, setExcelExportErrors] = useState()

  const {
    isLoading: isRentRollsLoading,
    isError: isRentRollsError,
    errors: rentRollErrors,
    data: rentRolls,
  } = useRentRolls(
    properties.map(({ uuid }) => uuid),
    { enabled: isExcelExportInProgress },
  )

  const rentalUnits = useMemo(
    () =>
      rentRolls
        ?.map(({ key_date, rental_units }) => ({
          rental_units: rental_units.map((rentalUnit) => ({
            ...rentalUnit,
            rent_roll_key_date: key_date,
            property_id: propertyIdByUuid[rentalUnit.property_uuid],
          })),
        }))
        ?.flatMap(({ rental_units }) => rental_units),
    [rentRolls, propertyIdByUuid],
  )

  const excelFileName = useMemo(
    () => generateRentRollExcelExportFileName({ rentalUnits, multiPropertyViewId }),
    [rentalUnits, multiPropertyViewId],
  )

  const {
    isLoading: isExpandRentalUnitsLoading,
    isError: isExpandRentalUnitsError,
    data: expandedRentalUnits,
  } = useExpandRentalUnitsWithBusinessPartners(rentalUnits)

  useEffect(() => {
    if (isExcelExportInProgress) {
      if (!isRentRollsLoading && !isExpandRentalUnitsLoading) {
        if (isRentRollsError || isExpandRentalUnitsError || isUtilizationCodesError) {
          setIsExcelExportError(true)
          setExcelExportErrors([...(rentRollErrors ?? [])])
          setIsExcelExportInProgress(false)
          return
        }

        if (expandedRentalUnits && utilizationCodesResponse) {
          try {
            createRentRollExcelExport({
              rentalUnits: expandedRentalUnits,
              fileName: excelFileName,
              t,
              areaMeasurementUnitFormatter,
              utilizationCodes: utilizationCodesResponse.utilization_codes,
            })
          } catch {
            setIsExcelExportError(true)
          } finally {
            setIsExcelExportInProgress(false)
          }
        }
      }
    }
  }, [
    t,
    areaMeasurementUnitFormatter,
    isUtilizationCodesError,
    utilizationCodesResponse,
    isExcelExportInProgress,
    isRentRollsLoading,
    isExpandRentalUnitsLoading,
    isRentRollsError,
    isExpandRentalUnitsError,
    rentRollErrors,
    expandedRentalUnits,
    excelFileName,
  ])

  function dismissErrors() {
    setIsExcelExportError(false)
    setExcelExportErrors(undefined)
  }

  return {
    download: () => {
      dismissErrors()
      setIsExcelExportInProgress(true)
    },
    isExportInProgress: isExcelExportInProgress,
    isExportError: isExcelExportError,
    exportErrors: excelExportErrors,
    dismissErrors,
  }
}
