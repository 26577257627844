import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Link,
  Popover,
  LinkDesign,
  ObjectStatus,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useState, useId } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { objectStatusForPropertyLinkedDealByCode } from 'api/property/linked-deal/propertyLinkedDeal'
import styles from 'components/domains/business-partners/tile/tenancy/TenancyHeaderPopover.module.css'
import { useTenancyKpiValueFormatter } from 'components/domains/business-partners/tile/tenancy/useTenancyKpiValueFormatter'

const TenancyHeaderPopover = ({ kpiName, kpiValue, kpiValueType, segmentUsageTypes, status }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.tenancy.as-tenant',
  })
  const formatKpiValue = useTenancyKpiValueFormatter()
  const [popoverIsOpen, setPopoverIsOpen] = useState(false)

  const uniqueId = useId()

  const handleButtonClick = useCallback(() => {
    setPopoverIsOpen(true)
  }, [])

  const handlePopoverClosed = useCallback(() => {
    setPopoverIsOpen(false)
  }, [])

  const formattedKpiValue = formatKpiValue({
    kpiValue,
    kpiValueType,
    unit: segmentUsageTypes?.[0]?.unit,
  })

  return (
    <>
      <Link
        id={`openPopoverLink-${uniqueId}`}
        design={LinkDesign.Default}
        onClick={handleButtonClick}
      >
        {formattedKpiValue}
      </Link>
      {createPortal(
        <Popover
          opener={`openPopoverLink-${uniqueId}`}
          open={popoverIsOpen}
          headerText={kpiName}
          onAfterClose={handlePopoverClosed}
          className={styles.popoverStyle}
        >
          <FlexBox direction={FlexBoxDirection.Column}>
            <FlexBox direction={FlexBoxDirection.Row}>
              <Label className={styles.labelPadding} showColon>
                {t('financing-status')}
              </Label>
              {status && (
                <ObjectStatus
                  inverted
                  state={objectStatusForPropertyLinkedDealByCode[status.code].objectStatus}
                >
                  {status.displayName}
                </ObjectStatus>
              )}
            </FlexBox>
            <FlexBox direction={FlexBoxDirection.Row} className={styles.flexBoxLabelPadding}>
              <Label className={styles.labelPadding} showColon>
                {kpiName}
              </Label>
              <Text>{formattedKpiValue}</Text>
            </FlexBox>
            <Label showColon className={styles.segmentLabelPadding}>
              {t('segment-usage-type')}
            </Label>
            {segmentUsageTypes?.map(({ type, value, unit }, index) => (
              <FlexBox
                key={`popover-segmet-usage-type-${index}`}
                direction={FlexBoxDirection.Row}
                justifyContent={FlexBoxJustifyContent.SpaceBetween}
                className={styles.segmentPadding}
              >
                <Text>{`${type}`}</Text>
                <Text>{`${formatKpiValue({ kpiValue: value, kpiValueType, unit })}`}</Text>
              </FlexBox>
            ))}
          </FlexBox>
        </Popover>,
        document.body,
      )}
    </>
  )
}

TenancyHeaderPopover.propTypes = {
  segmentUsageTypes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      unit: PropTypes.string,
    }),
  ).isRequired,
  kpiValue: PropTypes.number.isRequired,
  kpiValueType: PropTypes.string.isRequired,
  kpiName: PropTypes.string.isRequired,
  status: PropTypes.shape({
    code: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
  }),
}

export default TenancyHeaderPopover
