import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import ValuationRequestTableValuerWithSuggestionsSelect from 'components/domains/properties/valuation/table/table-cells/edit/ValuationRequestTableValuerWithSuggestionsSelect'
import { filterTypesEmptyValues } from 'components/ui/tables/sorted-tables/useFilters'
import { filters } from 'components/ui/tables/toolbar/propTypes'

const ValuationRequestsValuerCustomFilter = ({
  valuationRequests,
  selectedFilters,
  setSelectedFilters,
  columnKey,
}) => {
  const selectedValuer = useMemo(
    () => selectedFilters.find((filter) => filter.columnKey === columnKey)?.value,
    [columnKey, selectedFilters],
  )

  const handleOnEditValuerChange = useCallback(
    (valuerId) => {
      setSelectedFilters(
        selectedFilters.map((filter) =>
          filter.columnKey === columnKey ? { ...filter, value: valuerId } : filter,
        ),
      )
    },
    [columnKey, selectedFilters, setSelectedFilters],
  )

  return (
    <ValuationRequestTableValuerWithSuggestionsSelect
      valuationRequests={valuationRequests}
      valuer={selectedValuer === filterTypesEmptyValues.CUSTOM ? '' : selectedValuer}
      onValuerChange={handleOnEditValuerChange}
      showConfirmationDialog={false}
    />
  )
}

ValuationRequestsValuerCustomFilter.propTypes = {
  valuationRequests: PropTypes.arrayOf(
    PropTypes.shape({
      valuer: PropTypes.string,
      property: PropTypes.shape({ uuid: PropTypes.string }),
    }),
  ),
  selectedFilters: filters.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  columnKey: PropTypes.string.isRequired,
}

export default ValuationRequestsValuerCustomFilter
