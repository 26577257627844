import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { conditionTypes, entityTypes } from 'api/conditions/conditions'
import GenericConditionRequirementChangelogDialog from 'components/domains/conditions/dialogs/changelog/GenericConditionRequirementChangelogDialog'
import { ENTITIES } from 'components/domains/deals/change-overview/constants'
import useChangeLogPageIds from 'hooks/services/traceability-of-changes/useChangeLogPageIds'
import { SORT_DIRECTION } from 'hooks/services/traceability-of-changes/useChangeLogs'
import useIndividualEntityChangeLogs from 'hooks/services/traceability-of-changes/useIndividualEntityChangeLogs'

const entityTypeTranslation = {
  [entityTypes.deal]: ENTITIES.DEAL,
  [entityTypes.businesspartner]: ENTITIES.BUSINESS_PARTNER,
}

const ConditionIndividualChangelogDialog = ({
  entityType,
  entityId,
  relatedEntityType,
  relatedEntityId,
  relatedEntityDisplayId,
  isOpen,
  setIsOpen,
}) => {
  const translatedEntityType = useMemo(() => {
    switch (entityType) {
      case conditionTypes.external:
        return ENTITIES.EXTERNAL_CONDITION
      case conditionTypes.internal:
        return ENTITIES.INTERNAL_CONDITION
      default:
        return entityType
    }
  }, [entityType])

  const {
    isFetching: isPagesFetching,
    isLoading: isPagesLoading,
    isError: isPagesError,
    data: pageIdData,
  } = useChangeLogPageIds({
    entityType: entityTypeTranslation[relatedEntityType],
  })

  const {
    isFetching,
    isLoading,
    isError,
    data: changeLogData,
    fetchNextPage,
  } = useIndividualEntityChangeLogs(
    {
      entityType: translatedEntityType,
      entityId,
      relatedEntityTypes: [
        ENTITIES.EXTERNAL_CONDITION,
        ENTITIES.INTERNAL_CONDITION,
        ENTITIES.REQUIREMENT,
      ],
      pageIds: pageIdData?.pageIds,
      sorting: {
        field: 'triggered_at',
        direction: SORT_DIRECTION.DESC,
      },
    },
    {
      enabled: !isPagesFetching && !isPagesLoading && !isPagesError,
    },
  )

  const handleOnDialogClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  return (
    <GenericConditionRequirementChangelogDialog
      isOpen={isOpen}
      onAfterClose={handleOnDialogClose}
      isFetching={isFetching || isPagesFetching}
      isLoading={isLoading || isPagesLoading}
      isError={isError || isPagesError}
      changeLogData={changeLogData?.changeLogs}
      loadMore={fetchNextPage}
      totalNumberOfItems={changeLogData?.total}
      hideNameForEntityId={entityId}
      relatedEntityType={entityTypeTranslation[relatedEntityType]}
      relatedEntityId={relatedEntityId}
      relatedEntityDisplayId={relatedEntityDisplayId}
    />
  )
}

ConditionIndividualChangelogDialog.propTypes = {
  relatedEntityType: PropTypes.oneOf([entityTypes.deal, entityTypes.businesspartner]),
  relatedEntityId: PropTypes.string.isRequired,
  relatedEntityDisplayId: PropTypes.string.isRequired,
  entityType: PropTypes.oneOf([
    conditionTypes.external,
    conditionTypes.internal,
    ENTITIES.REQUIREMENT,
  ]),
  entityId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default ConditionIndividualChangelogDialog
