import { RadioButton } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { createRef } from 'react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/NewBusinessCheckRadioButtons.module.css'
import { Answer } from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/questionnaireStructure'
import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js'
import useNewBusinessCheckQuestionnaireAnswerFormatter from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/useNewBusinessCheckQuestionnaireAnswerFormatter'

export const NewBusinessCheckRadioButtons = ({
  currentAnswer,
  handleOnChange,
  keyPath,
  proceedWith,
  name,
  hasIndeterminableOption,
  ...props
}) => {
  const formatAnswer = useNewBusinessCheckQuestionnaireAnswerFormatter()
  const radioButtonRefs = {
    [Answer.YES]: createRef(),
    [Answer.NO]: createRef(),
    [Answer.INDETERMINABLE]: createRef(),
  }
  const currentlySelectedRadioButtonRef = currentAnswer ? radioButtonRefs[currentAnswer] : undefined

  return (
    <>
      <RadioButton
        text={formatAnswer(Answer.YES)}
        value={Answer.YES}
        className={styles.radioButtonYes}
        checked={currentAnswer === Answer.YES}
        ref={radioButtonRefs[Answer.YES]}
        onChange={(event) =>
          handleOnChange(keyPath, event.target.value, proceedWith, currentlySelectedRadioButtonRef)
        }
        name={name}
        {...props}
      />
      <RadioButton
        text={formatAnswer(Answer.NO)}
        value={Answer.NO}
        checked={currentAnswer === Answer.NO}
        ref={radioButtonRefs[Answer.NO]}
        onChange={(event) =>
          handleOnChange(keyPath, event.target.value, proceedWith, currentlySelectedRadioButtonRef)
        }
        name={name}
        {...props}
      />
      {hasIndeterminableOption && (
        <RadioButton
          text={formatAnswer(Answer.INDETERMINABLE)}
          value={Answer.INDETERMINABLE}
          className={styles.radioButtonIndeterminable}
          checked={currentAnswer === Answer.INDETERMINABLE}
          ref={radioButtonRefs[Answer.INDETERMINABLE]}
          onChange={(event) =>
            handleOnChange(
              keyPath,
              event.target.value,
              proceedWith,
              currentlySelectedRadioButtonRef,
            )
          }
          name={name}
          {...props}
        />
      )}
    </>
  )
}

NewBusinessCheckRadioButtons.propTypes = {
  currentAnswer: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired,
  keyPath: PropTypes.arrayOf(PropTypes.string).isRequired,
  proceedWith: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.arrayOf(PropTypes.string).isRequired,
      condition: PropTypes.arrayOf(
        PropTypes.shape({
          keys: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
          result: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  hasIndeterminableOption: PropTypes.bool,
}
