import isEmpty from 'lodash.isempty'

export const convertScreamingSnakeCaseToTitle = (string) => {
  if (isEmpty(string)) {
    return null
  }
  return string
    .toLowerCase()
    .replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase()))
}
