import { useContext } from 'react'
import BusinessPartnerTextualAssessment from 'components/domains/business-partners/tile/assessments/BusinessPartnerTextualAssessment'
import { assessmentTypes } from 'components/domains/business-partners/tile/assessments/assessmentTypes'
import CWPLayout from 'components/ui/layout/CWPLayout'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useAssessments } from 'hooks/services/business-partners/assessments/useAssessments'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

const assessmentTypeList = Object.values(assessmentTypes)

const BusinessPartnerTextualAssessments = () => {
  const { id } = useContext(BusinessPartnerContext)

  const { isLoading, isError, data: { allowedOperations = [] } = {} } = useAssessments(id)

  return (
    <LoadingStateWrapper isError={isError} isLoading={isLoading}>
      {assessmentTypeList.map((assessmentType) => (
        <CWPLayout.Full key={`${assessmentType}-layout`}>
          <BusinessPartnerTextualAssessment
            key={assessmentType}
            type={assessmentType}
            businessPartnerId={id}
            allowedOperations={allowedOperations}
          />
        </CWPLayout.Full>
      ))}
    </LoadingStateWrapper>
  )
}

export default BusinessPartnerTextualAssessments
