import requirementRowHasError from 'redux/slices/conditions/actions/condition-creation/requirementRowHasError'
import {
  getConditionIndexesOfSelectedRows,
  increaseIndexForAllRequirementsBelowCondition,
  increaseRequirementIndexOfCurrentErrorRows,
} from 'redux/slices/conditions/actions/helper/conditionsActionHelper'

const newRequirementTemplate = {
  name: '',
  documentType: '',
}

const addRequirement = (state) => {
  if (Object.keys(state.selectedRows).length === 0) {
    return
  }

  const filteredConditionIndexes = getConditionIndexesOfSelectedRows(state.selectedRows)

  filteredConditionIndexes.forEach((conditionIndex) => {
    if (!state.conditions[conditionIndex]) {
      return
    }

    state.conditions[conditionIndex].expanded = true

    state.selectedRows = increaseIndexForAllRequirementsBelowCondition({
      selectedRows: state.selectedRows,
      conditionIndex,
    })

    state.conditions[conditionIndex].requirements = [
      { ...newRequirementTemplate },
      ...state.conditions[conditionIndex].requirements,
    ]

    state.errorRows = increaseRequirementIndexOfCurrentErrorRows({
      errorRows: state.errorRows,
      conditionIndex,
    })
    requirementRowHasError(state, {
      payload: { conditionIndex, requirementIndex: 0, field: 'name', error: { warning: false } },
    })
    requirementRowHasError(state, {
      payload: {
        conditionIndex,
        requirementIndex: 0,
        field: 'documentType',
        error: { warning: false },
      },
    })
  })
}

export default addRequirement
