import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useTileMetaData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/meta-data/useTileMetaData'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useDealProperties from 'hooks/services/deals/properties/useDealProperties'
import useDealMini from 'hooks/services/deals/useDealMini'
import usePropertyUuids from 'hooks/services/properties/usePropertyUuids'
import useMultiPropertyValuationsDecisionPaper from 'hooks/services/properties/valuations/useMultiPropertyValuationsDecisionPaper'

const MARKET_VALUE = 'marketValue'
const MORTGAGE_LENDING_VALUE = 'mortgageLendingValue'

const useCoverPoolAdjustments = ({ id: eventId, entityRef: { entityId } }, tileId, tileCode) => {
  const {
    data: tileMetadata,
    isFetching: isTileMetadataFetching,
    isError: isTileMetadataError,
    error: tileMetadataError,
  } = useTileMetaData({ eventId, tileId })

  const selectedAdjustmentsPropertyUuids = useMemo(
    () => tileMetadata?.meta_data ?? [],
    [tileMetadata?.meta_data],
  )

  const {
    data: { dealUuid: dealUuidByTileCode } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({
    dealUuid: entityId,
    tileCode,
  })

  const {
    data: { dealId: dealDisplayId } = {},
    isFetching: isDealMiniFetching,
    isError: isDealMiniError,
  } = useDealMini(dealUuidByTileCode)

  const {
    data: { dealProperties = [] } = {},
    isFetching: isDealPropertiesFetching,
    isError: isDealPropertiesError,
    error: dealPropertiesError,
  } = useDealProperties({
    dealUuid: dealUuidByTileCode,
  })

  const isPropertyRequestEnabled = useMemo(
    () => !isDealPropertiesFetching && !isDealPropertiesError && !isEmpty(dealProperties),
    [dealProperties, isDealPropertiesError, isDealPropertiesFetching],
  )

  const propertyUuids = useMemo(
    () => dealProperties?.map((property) => property.propertyUuid),
    [dealProperties],
  )

  const {
    data: { data: { properties = [] } = {} } = {},
    isFetching: isPropertiesFetching,
    isError: isPropertiesError,
    error: propertiesError,
  } = usePropertyUuids(
    propertyUuids,
    {
      enabled: isPropertyRequestEnabled,
    },
    true,
  )

  // we need to call this separate valuations endpoint WITH the sums, in order to have the shares calculated for us
  const {
    data: { valuations = [] } = {},
    isFetching: isValuationsFetching,
    isError: isValuationsError,
  } = useMultiPropertyValuationsDecisionPaper(propertyUuids, ['sum'], undefined, {
    enabled: isPropertyRequestEnabled,
  })

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isTileMetadataFetching,
      isDealUuidFetching,
      isDealMiniFetching,
      isDealPropertiesFetching,
      isPropertiesFetching,
      isValuationsFetching,
    ],
    errorValues: [
      isTileMetadataError,
      isDealUuidError,
      isDealMiniError,
      isDealPropertiesError,
      isPropertiesError,
      isValuationsError,
    ],
    errorDetails: [tileMetadataError, dealPropertiesError, propertiesError],
    tileId,
  })

  const tableData = useMemo(
    () =>
      properties?.map((property) => {
        const { uuid: propertyUuid, id: displayId, description: name, address } = property
        const valuation = valuations?.find(({ uuid }) => uuid === propertyUuid)

        const marketValue = valuation?.[MARKET_VALUE]
        const mortgageLendingValue = valuation?.[MORTGAGE_LENDING_VALUE]
        return {
          property: { propertyUuid, displayId, name },
          address,
          marketValue: { ...marketValue?.valueAmount, share: marketValue?.shares?.sum },
          mortgageLendingValue: {
            ...mortgageLendingValue?.valueAmount,
            share: mortgageLendingValue?.shares?.sum,
          },
          coverPoolAdjustmentChecked:
            selectedAdjustmentsPropertyUuids?.some(
              (adjustmentPropertyUuid) => adjustmentPropertyUuid === property?.uuid,
            ) ?? false,
        }
      }),
    [properties, selectedAdjustmentsPropertyUuids, valuations],
  )

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        tableData,
        selectedAdjustments: selectedAdjustmentsPropertyUuids,
        sourceRender: {
          dealDisplayId,
        },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    tableData,
    selectedAdjustmentsPropertyUuids,
    dealDisplayId,
    error,
  ])
}

export default useCoverPoolAdjustments
