import _ from 'lodash'
import { useMemo } from 'react'
import { entityTypes } from 'api/conditions/conditions'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useMultipleCustomAssessmentVersions from 'hooks/services/central-data/custom-assessments/useMultipleCustomAssessmentVersions'

const useMultipleSensitivityAnalysis = ({ entityRef: { entityId: businessPartnerId } }, tileId) => {
  const {
    data: { selectedDeal, dealsData } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const { selectedDealDisplayId } = selectedDeal ?? {}
  const dealUuids = dealsData?.map((dealData) => dealData.dealUuid)

  const {
    data: multipleSensitivityAnalysis,
    isMultipleCustomAssessmentVersionsLoading,
    isMultipleCustomAssessmentVersionsError,
    error: multipleCustomAssessmentsVersionsError,
  } = useMultipleCustomAssessmentVersions({
    uuids: dealUuids,
    assessmentCode: 'DEAL_SENSITIVITY_ANALYSIS',
    entityType: entityTypes.deal,
  })

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isMultipleCustomAssessmentVersionsLoading, isAnnualReviewDealsLoading],
    errorValues: [isMultipleCustomAssessmentVersionsError, isAnnualReviewDealsError],
    errorDetails: [annualReviewDealsError, multipleCustomAssessmentsVersionsError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueLoading || isSomeValueError) {
      return { isLoading: isSomeValueLoading, isError: isSomeValueError, error }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        multipleSensitivityAnalysis: multipleSensitivityAnalysis.map((sensitivity, index) => ({
          sensitivityAnalysis: sensitivity,
          dealUuid: dealUuids[index],
        })),
        sourceRender: {
          dealDisplayId: selectedDealDisplayId,
        },
        dealsData,
      },
    }
  }, [
    isSomeValueLoading,
    isSomeValueError,
    multipleSensitivityAnalysis,
    selectedDealDisplayId,
    dealsData,
    error,
    dealUuids,
  ])
}

export default useMultipleSensitivityAnalysis
