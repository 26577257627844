export const ROW_TYPE_UNIT_MEMBER = 'unitMember'
export const ROW_TYPE_PRODUCT_CLASS = 'productClass'
export const ROW_TYPE_TOTAL = 'total'

export const NUMBER_SUFFIX_NONE = ''
export const NUMBER_SUFFIX_K = 'k'
export const NUMBER_SUFFIX_M = 'M'

const NUMBER_OF_ITEMS_IN_DEFAULT_ROW = 2
const NUMBER_OF_ITEMS_IN_TOTAL_ROW = 3
export const TABLE_CELL_DEFAULT_ITEMS_GAP = 8
export const TABLE_TEXT_DEFAULT_HEIGHT = 16
export const TABLE_TEXT_DEFAULT_MARGIN = 8
export const TABLE_HEADER_ROW_HEIGHT =
  TABLE_TEXT_DEFAULT_HEIGHT * NUMBER_OF_ITEMS_IN_DEFAULT_ROW + TABLE_TEXT_DEFAULT_MARGIN * 2
export const TABLE_ROW_DEFAULT_HEIGHT =
  TABLE_TEXT_DEFAULT_HEIGHT * NUMBER_OF_ITEMS_IN_DEFAULT_ROW +
  TABLE_TEXT_DEFAULT_MARGIN * 2 +
  TABLE_CELL_DEFAULT_ITEMS_GAP
export const TABLE_ROW_TOTAL_ROW_HEIGHT =
  TABLE_TEXT_DEFAULT_HEIGHT * NUMBER_OF_ITEMS_IN_TOTAL_ROW +
  TABLE_TEXT_DEFAULT_MARGIN * 2 +
  TABLE_CELL_DEFAULT_ITEMS_GAP * (NUMBER_OF_ITEMS_IN_TOTAL_ROW - 1)

const TABLE_ROW_DEFAULT_EDIT_INPUT_HEIGHT = 26
export const TABLE_ROW_DEFAULT_EDIT_HEIGHT =
  TABLE_ROW_DEFAULT_EDIT_INPUT_HEIGHT * 2 +
  TABLE_CELL_DEFAULT_ITEMS_GAP +
  TABLE_TEXT_DEFAULT_MARGIN * 2

// column widths of button cell based on their state
export const DEFAULT_BUTTONS_CELL_WIDTH = 100
export const EDIT_BUTTONS_CELL_WIDTH = 185
export const EDIT_BUTTONS_CELL_WIDTH_GERMAN = 245

export const PERMISSION_AUTHORITY_LEVEL_CALCULATOR_UNIT_MEMBER_UPDATE =
  'PBB_AuthorityLevelCalculator_Update'
export const PERMISSION_AUTHORITY_LEVEL_CALCULATOR_RESET =
  'PBB_AuthorityLevelCalculatorReset_Update'

export const PERMISSION_AUTHORITY_LEVEL_CALCULATOR_COMMENT_UPDATE =
  'PBB_AuthorityLevelCalculatorComment_Update'

export const PERMISSION_AUTHORITY_LEVEL_CALCULATOR_LARGE_EXPOSURE_UPDATE =
  'PBB_AuthorityLevelCalculatorLargeExposure_Update'

export const MAX_COLLAPSED_COMMENT_LENGTH = 300
export const MAX_COMMENT_LENGTH = 1500
