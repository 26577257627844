import { FlexBox, FlexBoxDirection, Icon, Input, Label } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { useState } from 'react'
import AssignableMarketsPropertyCreationDialog from 'components/domains/markets/AssignableMarketsPropertyCreationDialog'
import styles from 'components/domains/properties/property-create/PropertyCreateDialogBasics.module.css'

const PropertyCreateAssignMarketInput = ({
  propertyTypeCode,
  countryCode,
  latitude,
  longitude,
  onChange,
  value,
}) => {
  const [isAssignedPropertiesDialogOpen, setIsAssignedPropertiesDialogOpen] = useState(false)

  return (
    <>
      <FlexBox direction={FlexBoxDirection.Column} className={styles.gridColumnSpan4}>
        <Label showColon>{t('pages.property-create.basic-information.market-assignment')}</Label>
        <Input
          id="property-market-id"
          className="property-create-input-full-width"
          value={value?.marketName ?? ''}
          icon={<Icon onClick={() => setIsAssignedPropertiesDialogOpen(true)} name="value-help" />}
          onKeyDown={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
        />
      </FlexBox>

      <AssignableMarketsPropertyCreationDialog
        isOpen={isAssignedPropertiesDialogOpen}
        setIsOpen={setIsAssignedPropertiesDialogOpen}
        propertyTypeCode={propertyTypeCode}
        countryCode={countryCode}
        latitude={latitude}
        longitude={longitude}
        onChange={onChange}
      />
    </>
  )
}

PropertyCreateAssignMarketInput.propTypes = {
  propertyTypeCode: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    marketName: PropTypes.string,
  }),
}

export default PropertyCreateAssignMarketInput
