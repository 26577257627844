import flatten from 'lodash.flatten'

const mapUserUuidsFromForbearanceMeasures = (data) => {
  const { forbearanceMeasures } = data ?? {}

  const createdByUuids = forbearanceMeasures?.map((f) => f.createdOn?.createdBy) ?? []
  const createdByVersionsUuids =
    forbearanceMeasures?.map((f) =>
      f.forbearanceMeasureVersions.map((fv) => fv.createdOn?.createdBy),
    ) ?? []

  return flatten([...createdByUuids, ...createdByVersionsUuids])
}

export default mapUserUuidsFromForbearanceMeasures
