import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const usePropertyEditValuation = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(
    async ({
      propertyId: propertyUuid,
      id: valuationRequestId,
      reason,
      valuationType,
      status,
      note,
      reportDate,
      valuer,
      dueDate,
    }) =>
      patch({
        path: `/properties/${propertyUuid}/valuation-requests/${valuationRequestId}`,
        body: {
          reason: reason,
          type: valuationType,
          info: {
            status: status,
            note: note,
            report_date: reportDate,
            due_date: dueDate,
          },
          valuer: valuer,
        },
      }),
    mutationOptions,
  )
}

export default usePropertyEditValuation
