import {
  Label,
  Toolbar,
  ToolbarDesign,
  ToolbarSpacer,
  ToolbarStyle,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/tables/toolbar/DisplayAndEditTableToolbar.module.css'

const DisplayAndEditTableToolbar = ({
  title,
  nrOfEntries,
  toolbarActions = [],
  messageStrip,
  className,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.ui.tables.toolbar' })

  const toolbarTitle =
    nrOfEntries !== undefined
      ? t('title', { title, nrOfEntries })
      : t('title-no-entry-number', { title })

  return (
    <div className={styles.toolbarWrapper}>
      {!!messageStrip && <div className={styles.messageStripContainer}>{messageStrip}</div>}
      <Toolbar
        design={ToolbarDesign.Auto}
        toolbarStyle={ToolbarStyle.Clear}
        className={[styles.toolbar, className ?? ''].join(' ')}
      >
        <Label className={styles.titleLabel}>{toolbarTitle}</Label>
        {toolbarActions?.length > 0 && (
          <>
            <ToolbarSpacer />
            {toolbarActions}
          </>
        )}
      </Toolbar>
    </div>
  )
}

DisplayAndEditTableToolbar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  nrOfEntries: PropTypes.number,
  toolbarActions: PropTypes.arrayOf(PropTypes.element),
  messageStrip: PropTypes.node,
  className: PropTypes.string,
}
export default DisplayAndEditTableToolbar
