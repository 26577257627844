import { combineReducers } from '@reduxjs/toolkit'
import eventCreationReducer from 'redux/slices/events/eventCreationSlice'
import eventOverviewReducer from 'redux/slices/events/eventOverviewSlice'

const eventsReducer = combineReducers({
  eventCreation: eventCreationReducer,
  eventOverview: eventOverviewReducer,
})

export default eventsReducer
