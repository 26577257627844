import { FlexBox, FlexBoxDirection, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PropertyRentRollStructureOverviewTable from 'components/domains/properties/rent-roll/overview/structure-overview/PropertyRentRollStructureOverviewTable'
import styles from 'components/domains/properties/rent-roll/overview/structure-overview/PropertyRentRollStructureOverviewTable.module.css'
import CloseableMessageStrip from 'components/ui/feedback/CloseableMessageStrip'
import TablesToolbar from 'components/ui/tables/toolbar/TablesToolbar'
import { useSegmentAreaDetailsTableColumns } from 'hooks/services/properties/segments/area-details/useSegmentAreaDetailsTableColumns'
import { useSegmentAreaDetailsTableRows } from 'hooks/services/properties/segments/area-details/useSegmentAreaDetailsTableRows'

const PropertyRentRollOverviewAreaDetailsTable = ({ property, segments, hasPermission }) => {
  const { t: tRentRollOverview } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview',
  })

  const columnDefinitions = useSegmentAreaDetailsTableColumns()
  const {
    isError: isError,
    isErrorAllSegmentsPublished: isErrorAllSegmentsPublished,
    data: tableData,
  } = useSegmentAreaDetailsTableRows(property, segments)
  return (
    <>
      <FlexBox
        direction={FlexBoxDirection.Column}
        className={compact([
          styles.messageStripFlex,
          (isErrorAllSegmentsPublished || isError) && styles.messageStripFlexMargin,
        ]).join(' ')}
      >
        {isErrorAllSegmentsPublished && (
          <CloseableMessageStrip design={MessageStripDesign.Negative}>
            {tRentRollOverview('structure-overview.table.linked.error')}
          </CloseableMessageStrip>
        )}
        {isError && (
          <CloseableMessageStrip design={MessageStripDesign.Information}>
            {tRentRollOverview('structure-overview.table.error')}
          </CloseableMessageStrip>
        )}
      </FlexBox>
      <TablesToolbar title={tRentRollOverview('area-details.title')} />
      <PropertyRentRollStructureOverviewTable
        id={'property-rent-roll-segments-area-details-table'}
        isError={isError}
        isErrorAllSegmentsPublished={isErrorAllSegmentsPublished}
        columnDefinitions={columnDefinitions}
        tableData={tableData}
        hasPermission={hasPermission}
      />
    </>
  )
}
PropertyRentRollOverviewAreaDetailsTable.propTypes = {
  property: PropTypes.object.isRequired,
  segments: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasPermission: PropTypes.bool.isRequired,
}
export default PropertyRentRollOverviewAreaDetailsTable
