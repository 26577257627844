import { DynamicPage, ObjectPage, ObjectPageMode } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import '@ui5/webcomponents-icons/dist/AllIcons.js'
import ErrorBoundary from 'components/ui/errors/ErrorBoundary'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import styles from 'components/ui/page/TabPage.module.css'

/**
 * wrapper around ObjectPage that supports deep-linkable tabs
 *
 * use ObjectPageSections for the tabs
 *
 * **Please use the component `Header` for the `headerTitle` prop**
 * @param {object} props component props
 * @param {import('react').ReactNode} [props.headerTitle] header component
 * @param {string?} props.defaultSectionId selected section when page is called without an url hash
 * @param {boolean?} props.isLoading render loading spinner
 * @param {boolean?} props.isError render error message
 * @param {boolean?} props.isEmpty just render the children, no tabs; use with IllustratedMessage
 * @param {boolean?} props.isStickyTabBar render a sticky tab bar
 * @param {boolean?} props.isStickyHeader render a sticky header
 * @param {boolean?} props.includeUrlQuery preserves search parameter of location in url
 */
const TabPage = ({
  headerTitle,
  headerContent,
  alwaysShowContentHeader = false,
  defaultSectionId,
  isLoading = false,
  isError = false,
  isEmpty = false,
  isStickyTabBar = false,
  isStickyHeader,
  includeUrlQuery = false,
  children,
  className,
  id,
  style,
  selectedSubSectionId,
  onSelectedSectionChange,
}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const headerHasBreadcrumbs = !!headerTitle?.props?.breadcrumbs?.length

  const isErrorOrLoading = isError || isLoading

  const getNavigation = (e) => {
    const selection = encodeURIComponent(e.detail.selectedSectionId)
    return includeUrlQuery ? `${location.search}#${selection}` : `#${selection}`
  }

  const renderContent = useCallback(() => children, [children])

  return isErrorOrLoading || isEmpty ? (
    <DynamicPage headerTitle={headerTitle} showHideHeaderButton={false}>
      <RequestStateResolver
        isError={isError}
        isLoading={isLoading}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        renderContent={renderContent}
      />
    </DynamicPage>
  ) : (
    <ErrorBoundary>
      <ObjectPage
        headerTitle={headerTitle}
        headerContent={headerContent}
        alwaysShowContentHeader={alwaysShowContentHeader}
        selectedSectionId={decodeURIComponent(location.hash.substring(1)) || defaultSectionId}
        selectedSubSectionId={selectedSubSectionId}
        onSelectedSectionChange={function updateUrl(e) {
          navigate(getNavigation(e))
          onSelectedSectionChange?.(e)
        }}
        mode={ObjectPageMode.IconTabBar}
        className={[
          className,
          styles.page,
          isStickyTabBar ? styles.stickyTabBar : '',
          isStickyHeader ? styles.stickyHeader : '',
          headerHasBreadcrumbs ? styles.hasBreadcrumbs : styles.noBreadcrumbs,
          !headerTitle ? styles.noHeader : '',
        ].join(' ')}
        id={id}
        style={style}
      >
        {children}
      </ObjectPage>
    </ErrorBoundary>
  )
}

TabPage.propTypes = {
  headerTitle: PropTypes.instanceOf(Object),
  headerContent: PropTypes.node,
  alwaysShowContentHeader: PropTypes.bool,
  defaultSectionId: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isStickyTabBar: PropTypes.bool,
  isStickyHeader: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  includeUrlQuery: PropTypes.bool,
  selectedSubSectionId: PropTypes.string,
  onSelectedSectionChange: PropTypes.func,
}

export default TabPage
