import { Button, FlexBox, FlexBoxDirection, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import styles from 'components/domains/properties/general-information/image/PropertyImage.module.css'
import { useImageDownload } from 'hooks/services/properties/images/useImageDownload'

const PropertyImage = ({
  imageUuid,
  imageTitle,
  onEnlargeImage,
  isThumbnail,
  imageContainerClassName = 'image-container',
}) => {
  // TODO: this is probably a workaround until it is discussed how to handle and display images correctly
  const {
    isLoading: isLoadingImage,
    isError: isErrorImage,
    data: imageData,
  } = useImageDownload(imageUuid)

  const [imageBase64, setImageBase64] = useState()

  const blobToBase64 = (blob) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = function () {
      const base64data = reader.result
      setImageBase64(base64data)
    }
  }

  useEffect(() => {
    if (!isLoadingImage && !isErrorImage) {
      blobToBase64(imageData)
    }
  }, [imageData, isErrorImage, isLoadingImage])

  if (isThumbnail) {
    return <img className={'property-image-thumbnail'} alt={imageTitle} src={imageBase64} />
  } else {
    return (
      <FlexBox className={styles[imageContainerClassName]} direction={FlexBoxDirection.Column}>
        {onEnlargeImage && (
          <Button
            className={styles['full-screen-button']}
            icon="full-screen"
            onClick={() => onEnlargeImage(imageBase64, imageTitle)}
          />
        )}
        <img className={styles['image']} alt={imageTitle} src={imageBase64} />
        <Text className={styles['image-title']}>{imageTitle}</Text>
      </FlexBox>
    )
  }
}

PropertyImage.propTypes = {
  imageUuid: PropTypes.string.isRequired,
  imageTitle: PropTypes.string.isRequired,
  onEnlargeImage: PropTypes.func,
  isThumbnail: PropTypes.bool,
  imageContainerClassName: PropTypes.string,
}

export default PropertyImage
