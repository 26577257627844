import compact from 'lodash.compact'
import get from 'lodash.get'
import isEmpty from 'lodash.isempty'
import uniq from 'lodash.uniq'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useMultipleDealProperties from 'hooks/services/deals/properties/useMultipleDealProperties'
import useCurrentMultiPropertyKpisList from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpisList'
import { useMultiPropertyViewByPropertyUuidsArrays } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuidsArrays'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

export const MAX_DISPLAYED_TENANTS = 3

const sortTenantsKpisByRelevance = (a, b) =>
  (b.annualizedCurrentRent?.number ?? 0) - (a.annualizedCurrentRent?.number ?? 0) ||
  (b.totalAreaSurface?.value ?? 0) - (a.totalAreaSurface?.value ?? 0) ||
  (a.tenant ?? '').localeCompare(b.tenant ?? '')

const sumBy = (array, getValue) => {
  const sum = array.reduce((sumValue, item) => sumValue + getValue(item), 0)
  return !Number.isNaN(sum) ? sum : undefined
}

export const sumByField = (array, fieldName) => sumBy(array, (item) => get(item, fieldName, 0))

export const weightedAvgByFields = (array, valueFieldName, weightFactorFieldName) => {
  const weightFactorTotal = sumBy(array, (item) => get(item, weightFactorFieldName))
  return sumBy(
    array,
    (item) => (get(item, valueFieldName, 0) * get(item, weightFactorFieldName)) / weightFactorTotal,
  )
}

const useMultipleTenancyOverview = ({ dealUuids }) => {
  const {
    data: dealPropertiesDealUuidPairs,
    isLoading: isLoadingPropertiesDealUuidPairs,
    isError: isErrorPropertiesDealUuidPairs,
  } = useCombinedQueryResults(useMultipleDealProperties({ dealUuids }))

  const dealUuidPropertyUuidsPairs = useMemo(
    () =>
      dealPropertiesDealUuidPairs?.map((pair) => ({
        dealUuid: pair.dealUuid,
        propertyUuids: pair?.dealProperties.flatMap(({ propertyUuid }) => propertyUuid),
      })) ?? [],
    [dealPropertiesDealUuidPairs],
  )

  const isMultiPropertyPerDealUuid = dealUuidPropertyUuidsPairs
    .map(({ dealUuid, propertyUuids }) => ({
      [dealUuid]: propertyUuids.length > 1,
    }))
    .reduce((prev, curr) => Object.assign(prev, curr), {})

  const allPropertyUuids = dealUuidPropertyUuidsPairs.flatMap((pair) => pair.propertyUuids)

  const {
    data: multiPropertyView,
    isLoading: isLoadingMultiPropertyView,
    isError: isErrorMultiPropertyView,
  } = useMultiPropertyViewByPropertyUuidsArrays({
    propertyUuidsArrays: dealUuidPropertyUuidsPairs.map((pair) => pair.propertyUuids),
    options: { enabled: allPropertyUuids.length > 0 },
  })

  const multiPropertyViewPerDealUuid = dealUuidPropertyUuidsPairs
    .map(({ dealUuid }, index) => ({
      [dealUuid]: multiPropertyView?.[index],
    }))
    .reduce((prev, curr) => Object.assign(prev, curr), {})

  const {
    data: multiPropertyKpisData,
    isLoading: isLoadingMultiPropertyKpis,
    isError: isErrorMultiPropertyKpis,
  } = useCurrentMultiPropertyKpisList(
    dealUuidPropertyUuidsPairs.map((pair) => ({
      propertyUuids: pair.propertyUuids,
      withTenantGranularity: true,
    })),
    { enabled: allPropertyUuids.length > 0 },
  )

  const multiPropertyKpisPropertyUuidsDealUuidTriplets = useMemo(
    () =>
      multiPropertyKpisData?.map((kpi, index) => ({
        propertyUuids: kpi?.data?.propertyUuids,
        kpis: {
          ...kpi?.data?.keyDateToMultiPropertyRentRollKpis?.[0]?.kpis,
          keyDate: kpi?.data?.keyDateToMultiPropertyRentRollKpis?.[0]?.keyDate,
        },
        dealUuid: dealUuidPropertyUuidsPairs[index]?.dealUuid,
      })),
    [dealUuidPropertyUuidsPairs, multiPropertyKpisData],
  )

  const dealHasKpisPerDealUuid = useMemo(
    () =>
      multiPropertyKpisPropertyUuidsDealUuidTriplets
        ?.map(({ dealUuid, kpis }) => ({
          [dealUuid]: !!kpis,
        }))
        .reduce((prev, curr) => Object.assign(prev, curr), {}),
    [multiPropertyKpisPropertyUuidsDealUuidTriplets],
  )

  const tenantRentRollKpisPerDealUuid = useMemo(
    () =>
      multiPropertyKpisPropertyUuidsDealUuidTriplets
        ?.map(({ dealUuid, kpis }) => ({
          [dealUuid]: kpis?.tenantRentRollKpis ?? [],
        }))
        .reduce((prev, curr) => Object.assign(prev, curr), {}),
    [multiPropertyKpisPropertyUuidsDealUuidTriplets],
  )

  const tenantsKpisSortedByRelevancePerDealUuid = useMemo(
    () =>
      Object.keys(tenantRentRollKpisPerDealUuid ?? {})
        ?.map((dealUuid) => ({
          [dealUuid]: [...tenantRentRollKpisPerDealUuid[dealUuid]].sort(sortTenantsKpisByRelevance),
        }))
        .reduce((prev, curr) => Object.assign(prev, curr), {}),
    [tenantRentRollKpisPerDealUuid],
  )

  const mostRelevantTenantsKpisPerDealUuid = useMemo(
    () =>
      Object.keys(tenantsKpisSortedByRelevancePerDealUuid ?? {})
        ?.map((dealUuid) => ({
          [dealUuid]: tenantsKpisSortedByRelevancePerDealUuid[dealUuid].slice(
            0,
            MAX_DISPLAYED_TENANTS,
          ),
        }))
        .reduce((prev, curr) => Object.assign(prev, curr), {}),
    [tenantsKpisSortedByRelevancePerDealUuid],
  )

  const mostRelevantTenantIds = useMemo(
    () =>
      uniq(
        compact(
          Object.values(mostRelevantTenantsKpisPerDealUuid ?? {}).flatMap((mostRelevantTenants) =>
            mostRelevantTenants.flatMap(({ tenant: tenantId }) => tenantId),
          ),
        ),
      ),
    [mostRelevantTenantsKpisPerDealUuid],
  )

  const {
    data: businessPartners,
    isLoading: isLoadingBusinessPartners,
    isError: isErrorBusinessPartners,
  } = useBusinessPartnerMiniByIds(mostRelevantTenantIds, {
    enabled: !isLoadingMultiPropertyKpis && !isEmpty(mostRelevantTenantIds),
  })

  const otherTenantsKpisPerDealUuid = Object.keys(tenantsKpisSortedByRelevancePerDealUuid ?? {})
    ?.map((dealUuid) => ({
      [dealUuid]: tenantsKpisSortedByRelevancePerDealUuid[dealUuid].slice(MAX_DISPLAYED_TENANTS),
    }))
    .reduce((prev, curr) => Object.assign(prev, curr), {})

  const keyDatePerDealUuid = multiPropertyKpisPropertyUuidsDealUuidTriplets
    ?.map(({ dealUuid, kpis }) => ({ [dealUuid]: kpis?.keyDate }))
    .reduce((prev, curr) => Object.assign(prev, curr), {})

  const getWaultDate = (keyDate, waultInYears) => {
    if (!keyDate || typeof waultInYears !== 'number') {
      return undefined
    }

    const years = { years: waultInYears }

    return DateTime.fromISO(keyDate).plus(years).toISODate()
  }

  const getRentalUnitsCount = (rentRollKpi) => {
    const rentalUnitsCount =
      get(rentRollKpi, 'letNumberOfRentalUnits') + get(rentRollKpi, 'selfNumberOfRentalUnits')
    return Number.isFinite(rentalUnitsCount) ? rentalUnitsCount : undefined
  }

  const getTenantShare = (multiPropertyKpis, rentRollKpi, fieldName, totalValueFieldName) => {
    const totalValue = get(multiPropertyKpis, totalValueFieldName || fieldName)
    const tenantValue = get(rentRollKpi, fieldName)
    if (tenantValue === undefined || !(totalValue > 0)) return undefined
    return tenantValue / totalValue
  }

  const getTenantAnnualizedCurrentRentShare = (kpis, rentRollKpi) =>
    getTenantShare(kpis, rentRollKpi, 'annualizedCurrentRent.number')
  const getTenantTotalMarketRentShare = (kpis, rentRollKpi) =>
    getTenantShare(kpis, rentRollKpi, 'totalMarketRent.number')
  const getTenantTotalAreaSurfaceShare = (kpis, rentRollKpi) =>
    getTenantShare(kpis, rentRollKpi, 'totalAreaSurface.value')

  const getCurrency = (multiPropertyKpis) =>
    get(multiPropertyKpis, 'annualizedCurrentRent.currency')
  const getAreaUnit = (multiPropertyKpis) =>
    get(multiPropertyKpis, 'totalAreaSurface.measurementUnit')

  const topTenantsPerDealUuid = Object.keys(mostRelevantTenantsKpisPerDealUuid)
    .map((dealUuid) => ({
      [dealUuid]: mostRelevantTenantsKpisPerDealUuid[dealUuid].map((tenantKpi) => {
        const waultToBreakInYears = get(tenantKpi, 'waultToBreakInYears')
        const waultToExpiryInYears = get(tenantKpi, 'waultToExpiryInYears')

        const multiPropertyKpis = multiPropertyKpisPropertyUuidsDealUuidTriplets.find(
          (e) => e.dealUuid === dealUuid,
        )
        const keyDate = keyDatePerDealUuid[dealUuid]

        return {
          tenant: {
            fullName:
              (businessPartners?.businessPartnerMinis ?? []).find(
                (bp) => bp.id === tenantKpi.tenant,
              )?.fullName ?? '',
            id: tenantKpi.tenant,
          },
          rentalUnitsCount: getRentalUnitsCount(tenantKpi),
          usageTypes: get(tenantKpi, 'segmentUsageTypes', []).map(({ displayName }) => displayName),
          areaSurface: get(tenantKpi, 'totalAreaSurface.value'),
          areaSurfaceShare: getTenantTotalAreaSurfaceShare(multiPropertyKpis.kpis, tenantKpi),
          numberOfUnits: get(tenantKpi, 'totalNumberOfUnits'),
          annualizedCurrentRent: get(tenantKpi, 'annualizedCurrentRent.number'),
          annualizedCurrentRentPerUom: get(tenantKpi, 'annualizedCurrentRentPerUom.number'),
          annualizedCurrentRentShare: getTenantAnnualizedCurrentRentShare(
            multiPropertyKpis.kpis,
            tenantKpi,
          ),
          marketRent: get(tenantKpi, 'totalMarketRent.number'),
          marketRentPerUom: get(tenantKpi, 'totalMarketRentPerUom.number'),
          marketRentShare: getTenantTotalMarketRentShare(multiPropertyKpis.kpis, tenantKpi),
          waultToBreakInYears,
          waultToBreakDate: getWaultDate(keyDate, waultToBreakInYears),
          waultToExpiryInYears,
          waultToExpiryDate: getWaultDate(keyDate, waultToExpiryInYears),
        }
      }),
    }))
    .reduce((prev, curr) => Object.assign(prev, curr), {})

  const aggregateTenants = (dealUuid, tenants, { minRequiredTenants = 1 } = {}) => {
    if (!tenants || tenants.length < minRequiredTenants) {
      return undefined
    }

    const multiPropertyKpis = multiPropertyKpisPropertyUuidsDealUuidTriplets.find(
      (e) => e.dealUuid === dealUuid,
    )
    const keyDate = keyDatePerDealUuid[dealUuid]

    const sumAnnualizedCurrentRent = sumByField(tenants, 'annualizedCurrentRent.number')
    const sumTotalAreaSurface = sumByField(tenants, 'totalAreaSurface.value')
    const sumTotalMarketRent = sumByField(tenants, 'totalMarketRent.number')
    const weightedAvgWaultToBreakInYears = weightedAvgByFields(
      tenants,
      'waultToBreakInYears',
      'annualizedCurrentRent.number',
    )
    const weightedAvgWaultToExpiryInYears = weightedAvgByFields(
      tenants,
      'waultToExpiryInYears',
      'annualizedCurrentRent.number',
    )

    return {
      rentalUnitsCount: sumBy(tenants, getRentalUnitsCount),
      areaSurface: sumTotalAreaSurface,
      areaSurfaceShare: sumBy(tenants, (rentRollKpi) =>
        getTenantTotalAreaSurfaceShare(multiPropertyKpis.kpis, rentRollKpi),
      ),
      numberOfUnits: sumByField(tenants, 'totalNumberOfUnits'),
      annualizedCurrentRent: sumAnnualizedCurrentRent,
      annualizedCurrentRentPerUom: sumAnnualizedCurrentRent / sumTotalAreaSurface,
      annualizedCurrentRentShare: sumBy(tenants, (rentRollKpi) =>
        getTenantAnnualizedCurrentRentShare(multiPropertyKpis.kpis, rentRollKpi),
      ),
      marketRent: sumTotalMarketRent,
      marketRentPerUom: sumTotalMarketRent / sumTotalAreaSurface,
      marketRentShare: sumBy(tenants, (rentRollKpi) =>
        getTenantTotalMarketRentShare(multiPropertyKpis.kpis, rentRollKpi),
      ),
      waultToBreakInYears: weightedAvgWaultToBreakInYears,
      waultToBreakDate: getWaultDate(keyDate, weightedAvgWaultToBreakInYears),
      waultToExpiryInYears: weightedAvgWaultToExpiryInYears,
      waultToExpiryDate: getWaultDate(keyDate, weightedAvgWaultToExpiryInYears),
    }
  }

  const topTenantsSubtotalPerDealUuid = Object.keys(mostRelevantTenantsKpisPerDealUuid)
    ?.map((dealUuid) => ({
      [dealUuid]: aggregateTenants(dealUuid, mostRelevantTenantsKpisPerDealUuid[dealUuid], {
        minRequiredTenants: 2,
      }),
    }))
    .reduce((prev, curr) => Object.assign(prev, curr), {})

  const otherTenantsPerDealUuid = Object.keys(otherTenantsKpisPerDealUuid)
    ?.map((dealUuid) => ({
      [dealUuid]: aggregateTenants(dealUuid, otherTenantsKpisPerDealUuid[dealUuid]),
    }))
    .reduce((prev, curr) => Object.assign(prev, curr), {})

  const otherTenantsCountPerDealUuid = Object.keys(otherTenantsKpisPerDealUuid)
    ?.map((dealUuid) => ({
      [dealUuid]: otherTenantsKpisPerDealUuid[dealUuid].length,
    }))
    .reduce((prev, curr) => Object.assign(prev, curr), {})

  const vacancyPerDealUuid = useMemo(
    () =>
      multiPropertyKpisPropertyUuidsDealUuidTriplets
        ?.map(({ dealUuid, kpis }) => {
          const multiPropertyKpis = kpis
          return {
            [dealUuid]: {
              rentalUnitsCount: get(multiPropertyKpis, 'vacancyNumberOfRentalUnits'),
              areaSurface: get(multiPropertyKpis, 'vacancySurface.value'),
              areaSurfaceShare: get(multiPropertyKpis, 'vacancySurface.percent'),
              numberOfUnits: get(multiPropertyKpis, 'vacancyNumberOfUnits'),
              marketRent: get(multiPropertyKpis, 'vacancyMarketRent.number'),
              marketRentPerUom: get(multiPropertyKpis, 'vacancyMarketRentPerUom.number'),
              marketRentShare: getTenantShare(
                // That's correct
                multiPropertyKpis,
                multiPropertyKpis,
                'vacancyMarketRent.number',
                'totalMarketRent.number',
              ),
            },
          }
        })
        .reduce((prev, curr) => Object.assign(prev, curr), {}),
    [multiPropertyKpisPropertyUuidsDealUuidTriplets],
  )

  const totalPerDealUuid = useMemo(
    () =>
      multiPropertyKpisPropertyUuidsDealUuidTriplets
        ?.map(({ dealUuid, kpis }) => {
          const waultToBreakInYears = get(kpis, 'waultToBreakInYears')
          const waultToExpiryInYears = get(kpis, 'waultToExpiryInYears')
          const marketRent = get(kpis, 'totalMarketRent.number')

          return {
            [dealUuid]: {
              rentalUnitsCount: get(kpis, 'totalNumberOfRentalUnits'),
              areaSurface: get(kpis, 'totalAreaSurface.value'),
              areaSurfaceShare: 1,
              numberOfUnits: get(kpis, 'totalNumberOfUnits'),
              annualizedCurrentRent: get(kpis, 'annualizedCurrentRent.number'),
              annualizedCurrentRentPerUom: get(kpis, 'annualizedCurrentRentPerUom.number'),
              annualizedCurrentRentShare: 1,
              marketRent,
              marketRentPerUom: get(kpis, 'totalMarketRentPerUom.number'),
              marketRentShare: marketRent > 0 ? 1 : undefined,
              waultToBreakInYears,
              waultToBreakDate: getWaultDate(kpis.keyDate, waultToBreakInYears),
              waultToExpiryInYears,
              waultToExpiryDate: getWaultDate(kpis.keyDate, waultToExpiryInYears),
            },
          }
        })
        .reduce((prev, curr) => Object.assign(prev, curr), {}),
    [multiPropertyKpisPropertyUuidsDealUuidTriplets],
  )

  const sourcePathPerDealUuid = useMemo(
    () =>
      multiPropertyKpisPropertyUuidsDealUuidTriplets
        ?.map(({ dealUuid }) => ({
          [dealUuid]: isMultiPropertyPerDealUuid?.[dealUuid]
            ? `/properties/portfolio/rent-roll?portfolio-view-id=${multiPropertyViewPerDealUuid[dealUuid]?.data?.uuid}`
            : `/properties/${
                dealUuidPropertyUuidsPairs.find((e) => e.dealUuid === dealUuid).propertyUuids?.[0]
              }/rent-roll`,
        }))
        .reduce((prev, curr) => Object.assign(prev, curr), {}),
    [
      dealUuidPropertyUuidsPairs,
      isMultiPropertyPerDealUuid,
      multiPropertyKpisPropertyUuidsDealUuidTriplets,
      multiPropertyViewPerDealUuid,
    ],
  )

  const tenancyOverviewPerDealUuid = useMemo(
    () =>
      multiPropertyKpisPropertyUuidsDealUuidTriplets
        ?.map(({ dealUuid, kpis, propertyUuids }) => {
          if (!dealHasKpisPerDealUuid[dealUuid] || !propertyUuids) {
            return { [dealUuid]: undefined }
          }

          return {
            [dealUuid]: {
              dealUuid,
              sourceRender: { path: sourcePathPerDealUuid[dealUuid] },
              keyDate: keyDatePerDealUuid[dealUuid],
              topTenants: topTenantsPerDealUuid[dealUuid],
              topTenantsSubtotal: topTenantsSubtotalPerDealUuid[dealUuid],
              otherTenants: otherTenantsPerDealUuid[dealUuid],
              otherTenantsCount: otherTenantsCountPerDealUuid[dealUuid],
              vacancy: vacancyPerDealUuid[dealUuid],
              total: totalPerDealUuid[dealUuid],
              currency: getCurrency(kpis),
              areaUnit: getAreaUnit(kpis),
            },
          }
        })
        .reduce((prev, curr) => Object.assign(prev, curr), {}),
    [
      dealHasKpisPerDealUuid,
      keyDatePerDealUuid,
      multiPropertyKpisPropertyUuidsDealUuidTriplets,
      otherTenantsCountPerDealUuid,
      otherTenantsPerDealUuid,
      sourcePathPerDealUuid,
      topTenantsPerDealUuid,
      topTenantsSubtotalPerDealUuid,
      totalPerDealUuid,
      vacancyPerDealUuid,
    ],
  )

  const isLoading =
    isLoadingPropertiesDealUuidPairs ||
    isLoadingMultiPropertyView ||
    (isLoadingBusinessPartners && !isEmpty(mostRelevantTenantIds)) ||
    isLoadingMultiPropertyKpis

  const isError =
    isErrorPropertiesDealUuidPairs ||
    isErrorMultiPropertyView ||
    isErrorBusinessPartners ||
    isErrorMultiPropertyKpis

  return useMemo(() => {
    const data = {
      tenancyOverviewPerDealUuid,
    }

    return {
      isError,
      isLoading,
      data: !(isError || isLoading) ? data : undefined,
    }
  }, [isError, isLoading, tenancyOverviewPerDealUuid])
}

export default useMultipleTenancyOverview
