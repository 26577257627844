import { CheckBox } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAllEvents, clearEventSelection } from 'redux/slices/events/eventOverviewSlice'

const EventOverviewTableSelectAllCheckbox = ({ events }) => {
  const dispatch = useDispatch()
  const selectedEventCount = useSelector(
    (state) => Object.values(state.events.eventOverview.selectedRows.selectedEventIds).length,
  )

  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false)

  useEffect(() => {
    setIsSelectAllChecked(selectedEventCount === events.length && events.length !== 0)
  }, [selectedEventCount, events.length])

  const handleSelectAllEventsCheckboxChanged = ({ target: { checked: isCheckboxChecked } }) => {
    setIsSelectAllChecked(isCheckboxChecked)
    isCheckboxChecked
      ? dispatch(
          selectAllEvents(
            events.map(({ id, status }) => ({
              eventId: id,
              status,
            })),
          ),
        )
      : dispatch(clearEventSelection())
  }

  return (
    <CheckBox
      disabled={events.length === 0}
      checked={isSelectAllChecked}
      onChange={handleSelectAllEventsCheckboxChanged}
    />
  )
}

EventOverviewTableSelectAllCheckbox.propTypes = {
  events: PropTypes.array.isRequired,
}

export default EventOverviewTableSelectAllCheckbox
