import PropTypes from 'prop-types'
import { FileDrop } from 'react-file-drop'
import styles from 'components/ui/document/DocumentFileDrop.module.css'

const ValuationRequestFileDrop = ({ onDrop, customContainerStyles }) => (
  <FileDrop
    className={customContainerStyles}
    targetClassName={styles.target}
    draggingOverFrameClassName={styles.draggingOverContainer}
    draggingOverTargetClassName={styles.draggingOverTarget}
    onDrop={onDrop}
  />
)

ValuationRequestFileDrop.propTypes = {
  onDrop: PropTypes.func.isRequired,
  customContainerStyles: PropTypes.string,
}

export default ValuationRequestFileDrop
