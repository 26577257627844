import isNil from 'lodash.isnil'
import { useContext, useMemo } from 'react'
import { businessEventsAllowedOperations } from 'api/events/eventAllowedOperations'
import { marketAllowedOperations } from 'api/markets/marketAllowedOperations'
import { propertyMonitoringProxiesAllowedOperations } from 'api/property/monitoring/propertyMonitoringConfiguration'
import useEventsAllowedOperations from 'hooks/services/business-events-and-tasks/events/useEventsAllowedOperations'
import useMarketPropertyAllowedOperations from 'hooks/services/markets/useMarketPropertyAllowedOperations'
import usePropertyIdForAssignedMarket from 'hooks/services/markets/usePropertyIdForAssignedMarket'
import usePropertyMonitoringProxiesAllowedOperations from 'hooks/services/properties/monitoring/proxies/usePropertyMonitoringProxiesAllowedOperations'
import useChargesByUuid from 'hooks/services/properties/useChargesByUuid'
import { PropertyContext } from 'routes/properties/PropertyContext'

export const useOverviewMarketInfoDisplayHook = () => {
  const { property: { uuid: propertyUuid } = {} } = useContext(PropertyContext)

  const {
    isFetching: allowedMarketPropertyOperationsIsFetching,
    isError: allowedMarketPropertyOperationsIsError,
    data: allowedMarketPropertyOperations,
  } = useMarketPropertyAllowedOperations({
    propertyUuid,
  })

  const displayMarket = !!allowedMarketPropertyOperations?.allowedOperations.includes(
    marketAllowedOperations.viewMarket,
  )

  return {
    isLoading: allowedMarketPropertyOperationsIsFetching,
    renderComponent: displayMarket && !allowedMarketPropertyOperationsIsError,
  }
}

export const useOverviewValuationSummaryDisplayHook = () => {
  const { allowedOperations } = useContext(PropertyContext)

  const displayValuationSummaryCard = allowedOperations?.includes('PropertyValuation_Read')

  return {
    isLoading: false,
    renderComponent: displayValuationSummaryCard,
  }
}

export const useManagerEventsSummaryDisplayHook = () => {
  const {
    data: allowedEventsOperationsData,
    isFetching: allowedEventsOperationsIsFetching,
    isError: allowedEventsOperationsIsError,
  } = useEventsAllowedOperations()

  const eventReadAllowed = !!allowedEventsOperationsData?.allowed_operations?.includes(
    businessEventsAllowedOperations.readEvent,
  )

  return {
    isLoading: allowedEventsOperationsIsFetching,
    renderComponent: !allowedEventsOperationsIsError && eventReadAllowed,
  }
}

export const useGeneralInformationPropertyValuationDisplayHook = () => {
  const { allowedOperations } = useContext(PropertyContext)
  const displayValuationCard = allowedOperations?.includes('PropertyValuation_Read') ?? false

  return { renderComponent: displayValuationCard }
}

export const useCollateralAgreementDisplayHook = () => {
  const { property } = useContext(PropertyContext)
  const { isFetching } = useChargesByUuid(property.uuid)

  return {
    renderComponent: !isFetching,
  }
}

export const usePropertyMonitoringProxyMessageDisplayHook = () => {
  const { property: { uuid: propertyUuid } = {} } = useContext(PropertyContext)

  const { data: proxiesAllowedOperationsData, isFetching: isProxiesAllowedOperationsFetching } =
    usePropertyMonitoringProxiesAllowedOperations()

  const { data: assignedMarketData, isFetching: isAssignedMarketFetching } =
    usePropertyIdForAssignedMarket([propertyUuid])

  const assignedMarket = assignedMarketData?.markets?.[0] ?? {}

  const showProxyMessage = useMemo(
    () =>
      (proxiesAllowedOperationsData ?? []).includes(
        propertyMonitoringProxiesAllowedOperations.proxySettingsRead,
      ) && !isNil(assignedMarket.id),
    [assignedMarket.id, proxiesAllowedOperationsData],
  )

  return {
    isLoading: isProxiesAllowedOperationsFetching || isAssignedMarketFetching,
    renderComponent: showProxyMessage,
  }
}
