const DocumentTypes = Object.freeze({
  Market: 'MARKET',
  Property: 'PROPERTY',
  Deal: 'DEAL',
  BusinessPartner: 'BUSINESS_PARTNER',
  Requirement: 'REQUIREMENT',
  ValuationRequest: 'VALUATION_REQUEST',
  Covenant: 'COVENANT',
  CovenantCheck: 'COVENANT_CHECK',
})

export const allowedTypes = [
  DocumentTypes.Requirement,
  DocumentTypes.BusinessPartner,
  DocumentTypes.Property,
]

export default DocumentTypes
