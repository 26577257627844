const COVENANT_STATUS_ACTIVE = 'ACTIVE'
const COVENANT_STATUS_PLANNED = 'PLANNED'

export const mapNextAnnualReview = (businessPartnerId, multipleCovenantMonitoringData) => {
  if (!multipleCovenantMonitoringData || !Array.isArray(multipleCovenantMonitoringData)) {
    return null
  }

  const nextAnnualReviewMonitoringItems = multipleCovenantMonitoringData.find(
    ({ businessPartnerId: responseBusinessPartnerId }) =>
      responseBusinessPartnerId === businessPartnerId,
  )

  if (!nextAnnualReviewMonitoringItems) {
    return null
  }

  const covenantMonitoringItems = nextAnnualReviewMonitoringItems.covenantMonitoringItems || []

  if (!Array.isArray(covenantMonitoringItems)) {
    console.error('covenantMonitoringItems is not an array:', covenantMonitoringItems)
    return null
  }

  const relevantItems = covenantMonitoringItems.filter(
    ({ status } = {}) => status === COVENANT_STATUS_ACTIVE || status === COVENANT_STATUS_PLANNED,
  )

  if (relevantItems.length === 0) {
    return null
  }

  const nextAnnualReview = relevantItems.reduce((a, b) => {
    const dateA = new Date(a?.dueDate?.targetDeliveryDate)
    const dateB = new Date(b?.dueDate?.targetDeliveryDate)
    return dateA < dateB ? a : b
  })

  return nextAnnualReview?.dueDate?.checkDate || null
}
