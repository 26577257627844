import {
  FlexBox,
  Button,
  ButtonDesign,
  FlexBoxJustifyContent,
  FlexBoxAlignItems,
  Icon,
  IconDesign,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingButton from 'components/ui/button/LoadingButton'
import styles from 'components/ui/tables/sorted-tables/EditAndDeleteTableCell.module.css'

const EditAndDeleteTableCell = ({
  onSaveButtonClicked,
  onCancelButtonClicked,
  onEditButtonClicked,
  onDeleteButtonClicked,
  isSaveButtonDisabled,
  isEditModeForCurrentRow,
  isEditModeForAnyRow,
  isDeleteButtonDisabled,
  isEditButtonDisabled,
  onArrowButtonClicked,
  showEditButton = true,
  showDeleteButton = true,
  showArrowButton = false,
  isLoading = false,
  cancelButtonId,
}) => {
  const { t } = useTranslation()

  const iconDesign = isEditModeForAnyRow ? IconDesign.NonInteractive : IconDesign.Default

  const onIconClick = useCallback(
    () => !isEditModeForAnyRow && onArrowButtonClicked(),
    [isEditModeForAnyRow, onArrowButtonClicked],
  )

  if (isEditModeForCurrentRow) {
    return (
      <FlexBox
        className={styles.flexBox}
        fitContainer
        justifyContent={FlexBoxJustifyContent.End}
        alignItems={FlexBoxAlignItems.Center}
      >
        <LoadingButton
          design={ButtonDesign.Emphasized}
          isLoading={isLoading}
          onClick={onSaveButtonClicked}
          disabled={isSaveButtonDisabled}
          renderContent={() => t('buttons.save')}
        />
        <Button
          id={cancelButtonId}
          design={ButtonDesign.Transparent}
          onClick={onCancelButtonClicked}
          disabled={isLoading}
        >
          {t('buttons.cancel')}
        </Button>
        {showArrowButton && <Icon name="navigation-right-arrow" design={iconDesign} />}
      </FlexBox>
    )
  }

  return (
    <FlexBox
      className={styles.flexBox}
      fitContainer
      justifyContent={FlexBoxJustifyContent.End}
      alignItems={FlexBoxAlignItems.Center}
    >
      {showEditButton && (
        <Button
          design={ButtonDesign.Transparent}
          icon="edit"
          onClick={onEditButtonClicked}
          disabled={isEditButtonDisabled || isLoading}
        />
      )}
      {showDeleteButton && (
        <LoadingButton
          design={ButtonDesign.Transparent}
          icon="delete"
          onClick={onDeleteButtonClicked}
          disabled={isDeleteButtonDisabled}
          isLoading={isLoading}
        />
      )}
      {showArrowButton && (
        <Icon name="navigation-right-arrow" onClick={onIconClick} design={iconDesign} />
      )}
    </FlexBox>
  )
}

EditAndDeleteTableCell.propTypes = {
  cancelButtonId: PropTypes.string,
  isEditModeForCurrentRow: PropTypes.bool.isRequired,
  isEditModeForAnyRow: PropTypes.bool,
  isSaveButtonDisabled: PropTypes.bool,
  isEditButtonDisabled: PropTypes.bool,
  isDeleteButtonDisabled: PropTypes.bool,
  onCancelButtonClicked: PropTypes.func,
  onEditButtonClicked: PropTypes.func,
  onDeleteButtonClicked: PropTypes.func,
  onArrowButtonClicked: PropTypes.func,
  onSaveButtonClicked: PropTypes.func,
  showEditButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  showArrowButton: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default EditAndDeleteTableCell
