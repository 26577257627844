import conditionRowHasError from 'redux/slices/conditions/actions/condition-creation/conditionRowHasError'
import requirementRowHasError from 'redux/slices/conditions/actions/condition-creation/requirementRowHasError'
import {
  increaseConditionIndexOfCurrentErrorRows,
  increaseConditionIndexOfCurrentlySelectedRows,
} from 'redux/slices/conditions/actions/helper/conditionsActionHelper'

const addCondition = (state, { payload: condition }) => {
  const newCondition = {
    name: condition.name ?? '',
    type: condition.type,
    category: condition.category,
    refNumber: condition.refNumber,
    approvalLevel: condition.approvalLevel,
    description: condition.description,
    expanded: condition?.expanded ?? false,
    possibleDocumentTypes: [],
    requirements:
      condition.requirements?.map((requirement) => ({
        name: requirement.info?.name ?? '',
        documentType: requirement.info?.documentType ?? '',
        category: requirement.info?.category,
        refNumber: requirement.info?.refNumber,
        description: requirement.info?.description,
      })) ?? [],
  }
  state.conditions = [newCondition, ...state.conditions]

  state.selectedRows = increaseConditionIndexOfCurrentlySelectedRows({
    selectedRows: state.selectedRows,
    conditions: state.conditions,
  })
  state.errorRows = increaseConditionIndexOfCurrentErrorRows({ errorRows: state.errorRows })

  if (newCondition.name === '') {
    conditionRowHasError(state, {
      payload: { conditionIndex: 0, field: 'name', error: { warning: false }, showError: false },
    })
  }
  if (!newCondition.type) {
    conditionRowHasError(state, {
      payload: { conditionIndex: 0, field: 'type', error: { warning: false }, showError: false },
    })
  }

  newCondition.requirements.forEach((requirement, requirementIndex) => {
    if (!requirement.name) {
      requirementRowHasError(state, {
        payload: {
          conditionIndex: '0',
          requirementIndex,
          field: 'name',
          error: { warning: false },
          showError: false,
        },
      })
    }
    if (!requirement.documentType) {
      requirementRowHasError(state, {
        payload: {
          conditionIndex: '0',
          requirementIndex,
          field: 'documentType',
          error: { warning: false },
          showError: false,
        },
      })
    }
  })
}

export default addCondition
