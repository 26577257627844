import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import { DateTime } from 'luxon'
import { isFinancialProduct } from 'components/domains/business-partners/tile/arrears/ArrearsUtil'
import { isInvalidLocalDate } from 'utils/isInvalidLocalDate'

const PLACE_HOLDER = '-'
const ARREAR_TYPES = ['AMORTIZATION', 'INTEREST']

const isDateBeforeToday = (currentDate, localePattern, parse) => {
  const TODAY = new Date().toISOString().substring(0, 10)

  const dateToIsoFormat = (dateString) =>
    DateTime.fromISO(dateString).isValid ? dateString : parse(dateString, localePattern)

  const currentDateTime = new Date(dateToIsoFormat(currentDate)).getTime()
  const todayTime = new Date(TODAY).getTime()

  return todayTime > currentDateTime
}

export const getApprovalAmountValueOrZero = (value, originalValue) => {
  if (value === null || value === 0) {
    return parseFloat(0)
  } else if (value === undefined) {
    return originalValue
  }
  return value
}

export const getApprovalAmountValueStateObj = (
  currentVal,
  headquarterArrearAmount,
  arrearType,
  tValueState,
) => {
  if (currentVal < 0) {
    return {
      valueState: ValueState.Error,
      message: tValueState('approval-amount.invalid-amount'),
    }
  } else if (arrearType === PLACE_HOLDER && currentVal !== 0) {
    return {
      valueState: ValueState.Error,
      message: tValueState('approval-amount.no-arrear-type.invalid-amount'),
    }
  } else if (currentVal < headquarterArrearAmount) {
    return {
      valueState: ValueState.Warning,
      message: tValueState('approval-amount.smaller-than-arrear-amount'),
    }
  }
  return { valueState: ValueState.None }
}

export const getApprovalAmountValueState = (val, arrearAmount, arrearType) => {
  if (val < 0 || (arrearType === PLACE_HOLDER && val !== 0)) {
    return ValueState.Error
  } else if (val < arrearAmount) {
    return ValueState.Warning
  }
  return ValueState.None
}

export const getDateValueStateObj = (currentVal, localePattern, parse, tValueState) => {
  if (isInvalidLocalDate(currentVal, localePattern)) {
    return {
      valueState: ValueState.Error,
      message: tValueState('approved-until.invalid-date'),
    }
  } else if (isDateBeforeToday(currentVal, localePattern, parse)) {
    return {
      valueState: ValueState.Error,
      message: tValueState('approved-until.before-today'),
    }
  }
  return { valueState: ValueState.None }
}

export const getDateValueState = (currentVal, localePattern, parse) => {
  if (isInvalidLocalDate(currentVal, localePattern)) {
    return ValueState.Error
  } else if (isDateBeforeToday(currentVal, localePattern, parse)) {
    return ValueState.Error
  }
  return ValueState.None
}

export const isArrearClassificationTypeDisabled = (row, classificationCode) => {
  const isArrearTypeInRow = row.subRows.find(({ rowData }) =>
    ARREAR_TYPES.includes(rowData?.approvalItem?.arrearType?.code),
  )
  const isPendingInvoicesDisabled =
    isFinancialProduct(row.entityType) &&
    !!isArrearTypeInRow &&
    classificationCode === 'PENDING_INVOICES'
  const isBagatellDisabled =
    row?.rowData?.arrearsAmountByProduct > 100 && classificationCode === 'BAGATELL'
  return isPendingInvoicesDisabled || isBagatellDisabled
}

export const getClassificationValueStateObj = (row, currentVal, tValueState) => {
  if (
    !currentVal ||
    currentVal === 'emptyOption' ||
    isArrearClassificationTypeDisabled(row, currentVal)
  ) {
    return { valueState: ValueState.Error, message: tValueState('classification.empty') }
  }
  return { valueState: ValueState.None }
}

export const isArrearClassificationTypeNotAllowed = (
  arrearsAmountByProduct,
  arrearType,
  currentVal,
) => {
  const isArrearTypeInRow = ARREAR_TYPES.includes(arrearType)
  const isPendingInvoicesOptionNotAllowed = !!isArrearTypeInRow && currentVal === 'PENDING_INVOICES'
  const isBagatellOptionNotAllowed = arrearsAmountByProduct > 100 && currentVal === 'BAGATELL'
  return isPendingInvoicesOptionNotAllowed || isBagatellOptionNotAllowed
}

export const getClassificationValueState = (arrearsAmountByProduct, arrearType, currentVal) => {
  if (
    !currentVal ||
    isArrearClassificationTypeNotAllowed(arrearsAmountByProduct, arrearType, currentVal)
  ) {
    return ValueState.Error
  }
  return ValueState.None
}
