export const decisionPaperSynchronizationStatus = Object.freeze({
  live: 'LIVE',
  stopped: 'STOPPED',
  frozen: 'FROZEN',
})

export const decisionPaperVersion = Object.freeze({
  workingVersion: 'WORKING_VERSION',
})

export const decisionPaperTileTypes = Object.freeze({
  automatic: 'AUTOMATIC',
  automaticWithMetaData: 'AUTOMATIC_WITH_META_DATA',
  manual: 'MANUAL',
})

export const isMajorVersion = (version) => version.split('.')[1] === '0'
