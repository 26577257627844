import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import MarketProxiesTableDirectionCell from 'components/domains/markets/detail/cards/cells/proxies/MarketProxiesTableDirectionCell'
import MarketProxiesTableMonitoringCell from 'components/domains/markets/detail/cards/cells/proxies/MarketProxiesTableMonitoringCell'
import MarketProxiesTableNameCell from 'components/domains/markets/detail/cards/cells/proxies/MarketProxiesTableNameCell'
import MarketProxiesTableSettingsCell from 'components/domains/markets/detail/cards/cells/proxies/MarketProxiesTableSettingsCell'
import MarketProxiesTableThresholdCell from 'components/domains/markets/detail/cards/cells/proxies/MarketProxiesTableThresholdCell'
import MarketProxiesTableThresholdTypeCell from 'components/domains/markets/detail/cards/cells/proxies/MarketProxiesTableThresholdTypeCell'

export const marketProxyColumn = {
  name: 'name',
  setting: 'setting',
  monitoring: 'monitoring',
  thresholdValue: 'thresholdValue',
  thresholdType: 'thresholdType',
  direction: 'direction',
}

export const useMarketProxyColumns = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.markets.detail.manager.market-proxies.table',
  })

  return useMemo(
    () => [
      {
        title: t('name'),
        columnKey: marketProxyColumn.name,
      },
      {
        title: t('setting'),
        columnKey: marketProxyColumn.setting,
      },
      {
        title: t('monitoring'),
        columnKey: marketProxyColumn.monitoring,
      },
      {
        title: t('threshold-value'),
        columnKey: marketProxyColumn.thresholdValue,
      },
      {
        title: t('threshold-type'),
        columnKey: marketProxyColumn.thresholdType,
      },
      {
        title: t('direction'),
        columnKey: marketProxyColumn.direction,
        wrapText: true,
      },
    ],
    [t],
  )
}

export const useMarketProxyTableData = ({ proxiesState, hasProxies, isEditing, onChange }) => {
  const onValueChange = useCallback(
    (column) => (key, value) => {
      onChange({ column, key, value })
    },
    [onChange],
  )
  const onSettingChange = useMemo(() => onValueChange(marketProxyColumn.setting), [onValueChange])
  const onMonitoringChange = useMemo(
    () => onValueChange(marketProxyColumn.monitoring),
    [onValueChange],
  )
  const onThresholdValueChange = useMemo(
    () => onValueChange(marketProxyColumn.thresholdValue),
    [onValueChange],
  )
  const onThresholdTypeChange = useMemo(
    () => onValueChange(marketProxyColumn.thresholdType),
    [onValueChange],
  )
  const onDirectionChange = useMemo(
    () => onValueChange(marketProxyColumn.direction),
    [onValueChange],
  )

  return useMemo(() => {
    if (!hasProxies) {
      return []
    }

    return proxiesState.proxies.map((proxyInfo) => {
      const { key } = proxyInfo
      return {
        rowKey: `proxy-table-row-${key}`,
        [marketProxyColumn.name]: {
          cellComponent: (
            <MarketProxiesTableNameCell isEditing={isEditing} proxySetting={proxyInfo} />
          ),
        },
        [marketProxyColumn.setting]: {
          cellComponent: (
            <MarketProxiesTableSettingsCell
              isEditing={isEditing}
              onChange={onSettingChange}
              proxySetting={proxyInfo}
            />
          ),
        },
        [marketProxyColumn.monitoring]: {
          cellComponent: (
            <MarketProxiesTableMonitoringCell
              isEditing={isEditing}
              onChange={onMonitoringChange}
              proxySetting={proxyInfo}
            />
          ),
        },
        [marketProxyColumn.thresholdValue]: {
          cellComponent: (
            <MarketProxiesTableThresholdCell
              isEditing={isEditing}
              onChange={onThresholdValueChange}
              proxySetting={proxyInfo}
              errorState={proxiesState.errorState[key].threshold}
            />
          ),
        },
        [marketProxyColumn.thresholdType]: {
          cellComponent: (
            <MarketProxiesTableThresholdTypeCell
              isEditing={isEditing}
              onChange={onThresholdTypeChange}
              proxySetting={proxyInfo}
              errorState={proxiesState.errorState[key].thresholdType}
            />
          ),
        },
        [marketProxyColumn.direction]: {
          cellComponent: (
            <MarketProxiesTableDirectionCell
              isEditing={isEditing}
              onChange={onDirectionChange}
              proxySetting={proxyInfo}
              errorState={proxiesState.errorState[key].direction}
            />
          ),
        },
      }
    })
  }, [
    hasProxies,
    isEditing,
    onDirectionChange,
    onMonitoringChange,
    onSettingChange,
    onThresholdTypeChange,
    onThresholdValueChange,
    proxiesState.errorState,
    proxiesState.proxies,
  ])
}
