import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import VersionsHistoryPopover from 'components/ui/button/VersionsHistoryPopover'

const VersionsHistoryButton = ({ buttonId, versions, isLoading, isError, error }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ui.buttons.versions-history',
  })

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <>
      <Button
        id={buttonId}
        design={ButtonDesign.Transparent}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        icon="slim-arrow-down"
        iconEnd
      >
        {t('button.text')}
      </Button>
      <VersionsHistoryPopover
        versions={versions}
        opener={buttonId}
        isOpen={isPopoverOpen}
        setIsOpen={setIsPopoverOpen}
        isLoading={isLoading}
        isError={isError}
        error={error}
      />
    </>
  )
}

VersionsHistoryButton.propTypes = {
  buttonId: PropTypes.string.isRequired,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      version: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      lastUpdatedAt: PropTypes.string.isRequired,
      lastUpdatedBy: PropTypes.string.isRequired,
      onItemClicked: PropTypes.func.isRequired,
      isCurrent: PropTypes.bool,
      customProps: PropTypes.object,
    }),
  ),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}

export default VersionsHistoryButton
