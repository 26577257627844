import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteReservation = ({ onSuccess, onError, onSettled }) => {
  const { delete: deleteReservation } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, reservationUuid }) => {
      await deleteReservation({
        path: `/deals/${dealUuid}/credit-reservations/${reservationUuid}`,
      })
    },
    { onSuccess, onError, onSettled },
  )
}

export default useDeleteReservation
