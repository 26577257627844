import { SuggestionItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import InputWithSearchSuggestions from 'components/ui/input/InputWithSearchSuggestions'
import useStaffMembers from 'hooks/services/business-partners/staff-members/useStaffMembers'

const AssigneeEditField = ({ assignee, onAssigneeChange, onClear }) => {
  const [userSearchKey, setUserSearchKey] = useState('')

  const { data: userData, isError } = useStaffMembers({
    name: userSearchKey,
    minLength: 1,
  })

  /**
   * useEffect needed since AssigneeEditField AND edit dialog are always initialized
   * with an empty value and userSearchKey therefore always empty string.
   */
  useEffect(() => {
    setUserSearchKey(assignee?.fullName ?? '')
  }, [assignee?.fullName])

  const handleAssigneeChange = useCallback(
    (event) => {
      const newAssignee = {
        id: event.detail?.item.getAttribute('data-user-id'),
        fullName: event.detail?.item.getAttribute('data-assignee-full-name'),
      }
      onAssigneeChange(newAssignee)
    },
    [onAssigneeChange],
  )

  const handleOnClear = useCallback(() => {
    setUserSearchKey('')
    onClear()
  }, [onClear])

  const renderSuggestions = useCallback(
    () =>
      userData?.staffMembers.map((user, index) => (
        <SuggestionItem
          key={index}
          text={user.fullName}
          data-assignee-full-name={user.fullName}
          data-user-id={user.id}
        />
      )),
    [userData?.staffMembers],
  )

  return (
    <InputWithSearchSuggestions
      id={'assignee-input'}
      initialValue={userSearchKey}
      renderSuggestions={renderSuggestions}
      handleOnClear={handleOnClear}
      handleOnChange={handleAssigneeChange}
      setSearchKey={setUserSearchKey}
      isSuggestionsError={isError}
    />
  )
}

AssigneeEditField.propTypes = {
  assignee: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string,
  }),
  onAssigneeChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
}

export default AssigneeEditField
