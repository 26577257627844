import { useMemo } from 'react'
import { useMultipleDealTranchesWithSubEntities } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/useMultipleDealTranchesWithSubEntities'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useDeals from 'hooks/services/deals/useDeals'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'

const useBaselTwoCoverPoolInformation = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { deals: dealsData = [] } = {},
    isLoading: isDealsLoading,
    isError: isDealsError,
    error: dealsError,
  } = useDeals({ filter: { borrowerId: businessPartnerId } }) || {}

  const mappedDealUuids = useMemo(() => dealsData?.map((deal) => deal.dealUuid), [dealsData])

  const {
    data: multipleDealTranchesWithSubEntitiesResponses,
    isLoading: isMultipleDealTranchesWithSubEntitiesLoading,
    isError: isMultipleDealTranchesWithSubEntitiesError,
    error: multipleDealTranchesWithSubEntitiesError,
  } = useCombinedQueryResults(
    useMultipleDealTranchesWithSubEntities({
      dealUuids: mappedDealUuids,
      dataSource: DATA_SOURCES.EXISTING_BUSINESS,
    }) ?? {},
  )

  const allDealsTranches = useMemo(
    () =>
      multipleDealTranchesWithSubEntitiesResponses?.flatMap((response) => response?.tranches) ?? [],
    [multipleDealTranchesWithSubEntitiesResponses],
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isDealsLoading, isMultipleDealTranchesWithSubEntitiesLoading],
    errorValues: [isDealsError, isMultipleDealTranchesWithSubEntitiesError],
    errorDetails: [dealsError, multipleDealTranchesWithSubEntitiesError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        tranchesData: allDealsTranches,
        sourceRender: {
          businessPartnerId: businessPartnerId,
          // TODO: should be implemented, currently a TBD in https://fioneer.atlassian.net/browse/CWP-8947
          dealDisplayId: dealsData[0]?.dealId,
        },
      },
    }
  }, [dealsData, isSomeValueError, isSomeValueLoading, error, businessPartnerId, allDealsTranches])
}

export default useBaselTwoCoverPoolInformation
