import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * Returns all external id type config values
 * @returns {{isLoading: boolean, isError: boolean, data: ExternalIdTypeConfigValues}}
 *
 */
export const useExternalIdTypeConfigValues = () =>
  useCamelizedResponse(
    useRequest({
      path: '/properties/external-id-types/config-values',
    }),
  )
