import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 @enum {string}
 */
export const RoleConfig = {
  BORROWER: 'BORROWER',
  BORROWER_GROUP: 'BORROWER_GROUP',
  COVENANT: 'COVENANT',
  SPONSOR: 'SPONSOR',
}
/**
 @param {RoleConfig} roleConfig - The role config path param
 @param {object} options - Request options
 @returns {*|(*&{data: *|[]})}
 */
const useRoleConfig = (roleConfig, options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/configurations/role-configs/${roleConfig}`,
      useCache: true,
      keys: ['deals', 'configurations', 'role-configs', roleConfig],
      options: {
        enabled: !!roleConfig,
        ...options,
      },
    }),
  )

export default useRoleConfig
