import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import uniqBy from 'lodash.uniqby'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import BusinessPartnerTable from 'components/domains/business-partners/BusinessPartnerTable'
import BusinessPartnerFilterBar from 'components/domains/business-partners/filterbar/BusinessPartnerFilterBar'
import { useMatchingBusinessPartners } from 'hooks/services/business-partners/searchBusinessPartners'

const initialSorting = { columnKey: 'score', orderBy: 'desc' }

const removeDuplicateBusinessPartners = (businessPartners) => {
  const result = []
  if (!businessPartners?.length) return result

  return uniqBy(businessPartners, 'id')
}

const BusinessPartnerSearchTable = ({
  initialSearch,
  initialId,
  initialIsBeforeSearch,
  onChange,
  initialRoles = [],
  selectedBusinessPartners,
  lockedSelectedBusinessPartners,
  initialExcludeInactive = false,
  isMultiSelect = false,
}) => {
  const [filterParams, setFilterParams] = useState({
    q: initialSearch || '',
    id: initialId || '',
    street: '',
    city: '',
    countries: [],
    roles: initialRoles,
    excludeInactive: initialExcludeInactive,
    sort: initialSorting,
  })
  const [isFilterTriggered, setIsFilterTriggered] = useState(!initialIsBeforeSearch)
  const [isBeforeSearch, setIsBeforeSearch] = useState(initialIsBeforeSearch)

  const translateSortSettingToSortParameter = (sortSetting) =>
    `${sortSetting?.orderBy === 'asc' ? '+' : '-'}${sortSetting?.columnKey}`

  const {
    data: { businessPartners: suggestions = [], total } = {},
    isFetching: isSuggestionsLoading,
    isError: isSuggestionsError,
    fetchNextPage,
    hasNextPage,
  } = useMatchingBusinessPartners(
    {
      ...filterParams,
      sort: translateSortSettingToSortParameter(filterParams.sort),
    },
    { enabled: isFilterTriggered },
  )

  useEffect(() => {
    if (isBeforeSearch && isFilterTriggered && !isSuggestionsLoading) {
      setIsBeforeSearch(false)
    }
  }, [isBeforeSearch, isFilterTriggered, isSuggestionsLoading])

  const updateFilters = useCallback(
    ({ id, q, street, city, countries, roles, excludeInactive, sort }) => {
      setFilterParams({ id, q, street, city, countries, roles, excludeInactive, sort })
    },
    [],
  )

  const handleFilterChange = useCallback(
    (newFilterParams) => {
      setIsFilterTriggered(true)
      updateFilters(newFilterParams)
    },
    [updateFilters],
  )

  const handleChange = useCallback(
    (selectedBusinessPartnerIds, togglRowSelect) => {
      if (togglRowSelect) {
        // here the selectedBusinessPartnerIds only contains the business partner id with the altered row
        const currentlySelectedBusinessPartnerIds = selectedBusinessPartners.map(({ id }) => id)

        if (currentlySelectedBusinessPartnerIds.includes(selectedBusinessPartnerIds[0])) {
          const newSelectedBusinessPartners = selectedBusinessPartners.filter(
            ({ id }) => id !== selectedBusinessPartnerIds[0],
          )
          onChange(newSelectedBusinessPartners)
        } else {
          const newSelectedBusinessPartners = [
            ...selectedBusinessPartners,
            ...suggestions.filter(({ id }) => selectedBusinessPartnerIds[0] === id),
          ]
          onChange(newSelectedBusinessPartners)
        }
        return
      }

      const newlySelectedBusinesspartners = [
        ...selectedBusinessPartners,
        ...suggestions.filter(({ id }) => selectedBusinessPartnerIds.includes(id)),
      ]

      const businessPartnerIdsToRemoveFromSelection = newlySelectedBusinesspartners
        .map(({ id }) => id)
        .filter(
          (id) =>
            !selectedBusinessPartnerIds.includes(id) &&
            suggestions.some(({ id: bpId }) => id === bpId),
        )
      const newBusinesspartnersSelection = removeDuplicateBusinessPartners(
        newlySelectedBusinesspartners.filter(
          ({ id }) => !businessPartnerIdsToRemoveFromSelection.includes(id),
        ),
      )

      onChange(newBusinesspartnersSelection)
    },
    [suggestions, onChange, selectedBusinessPartners],
  )

  const selectedBusinessPartnerIds = useMemo(
    () => selectedBusinessPartners.map(({ id }) => id),
    [selectedBusinessPartners],
  )

  return (
    <>
      <BusinessPartnerFilterBar
        initialValues={filterParams}
        onGo={handleFilterChange}
        additionalFilterBarProperties={{ hideFilterConfiguration: true }}
      />
      <BusinessPartnerTable
        businessPartners={suggestions ?? []}
        isLoading={isSuggestionsLoading}
        isError={isSuggestionsError}
        onChange={handleChange}
        hasNextPage={!!hasNextPage}
        fetchNextPage={fetchNextPage}
        total={total ?? 0}
        isMultiSelect={isMultiSelect}
        isBeforeSearch={isBeforeSearch}
        selectedBusinessPartnerIds={selectedBusinessPartnerIds}
        lockedSelectedBusinessPartners={lockedSelectedBusinessPartners}
      />
    </>
  )
}

BusinessPartnerSearchTable.propTypes = {
  initialSearch: PropTypes.string,
  initialId: PropTypes.string,
  initialRoles: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  selectedBusinessPartners: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  lockedSelectedBusinessPartners: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  translatedTypeName: PropTypes.string,
  initialExcludeInactive: PropTypes.bool,
  initialIsBeforeSearch: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
}

export default BusinessPartnerSearchTable
