import { Label } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/events/AssigneeTableCell.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'

const AssigneeTableCell = ({ assigneeId, errorCallback, resetErrorCallback, rowId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.event-overview.table.cells.assignee',
  })
  const { isLoading, isError, data: assigneeData } = useStaffMemberById(assigneeId)

  useEffect(() => {
    if (isError) {
      errorCallback?.({ rowId })
    } else {
      resetErrorCallback?.({ rowId })
    }
  }, [errorCallback, isError, resetErrorCallback, rowId])

  const renderContent = () => (
    <Label>{isEmpty(assigneeData.fullName) ? assigneeId : assigneeData.fullName}</Label>
  )

  const errorMessage = <Label className={styles['error-label']}>{t('error.description')}</Label>

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderContent}
      error={errorMessage}
    />
  )
}

AssigneeTableCell.propTypes = {
  rowId: PropTypes.string,
  assigneeId: PropTypes.string.isRequired,
  errorCallback: PropTypes.func,
  resetErrorCallback: PropTypes.func,
}

export default AssigneeTableCell
