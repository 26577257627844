import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import TableInputCell from 'components/domains/conditions/overview/table-cells/view/TableInputCell'
import useConditionsCellEditMode from 'hooks/services/conditions/edit-mode/useConditionsCellEditMode'
import { conditionCategoryChanged } from 'redux/slices/conditions/conditionsTableSlice'

const ConditionsTableCategoryCell = ({ value: { category, conditionId } }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.columns.category',
  })

  const dispatch = useDispatch()
  const editedConditionCategory = useSelector(
    (state) => state.conditions.conditionsTable.editedRow.currentValues?.category,
  )

  const { isEditModeForCurrentRow } = useConditionsCellEditMode({
    conditionId,
  })

  const onInput = useCallback(
    (newCategory) => {
      dispatch(conditionCategoryChanged({ category: newCategory }))
    },
    [dispatch],
  )

  return (
    <TableInputCell
      input={isEditModeForCurrentRow ? editedConditionCategory : category ?? ''}
      isEditMode={isEditModeForCurrentRow}
      onInput={onInput}
      placeholder={t('placeholder')}
    />
  )
}
ConditionsTableCategoryCell.propTypes = {
  value: PropTypes.shape({
    conditionId: PropTypes.string.isRequired,
    category: PropTypes.string,
  }).isRequired,
}
export default ConditionsTableCategoryCell
