import PropTypes from 'prop-types'
import { useMemo } from 'react'
import DecisionPaperTileHeader from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileHeader'
import DecisionPaperSources from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/sources/DecisionPaperSources'

const DecisionPaperAutomaticTileHeader = ({
  titleText,
  tileId,
  tileCode,
  isExpanded,
  isPdfView,
  onCollapse,
  onExpand,
  currentVersion,
}) => {
  const sourceComponent = useMemo(
    () => <DecisionPaperSources tileId={tileId} tileCode={tileCode} isPdfView={isPdfView} />,
    [tileId, tileCode, isPdfView],
  )

  return (
    <DecisionPaperTileHeader
      titleText={titleText}
      tileId={tileId}
      isExpanded={isExpanded}
      isPdfView={isPdfView}
      source={sourceComponent}
      onCollapse={onCollapse}
      onExpand={onExpand}
      currentVersion={currentVersion}
    />
  )
}

DecisionPaperAutomaticTileHeader.propTypes = {
  tileId: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired,
  tileCode: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onCollapse: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  currentVersion: PropTypes.string.isRequired,
}

export default DecisionPaperAutomaticTileHeader
