import {
  FlexBox,
  FlexBoxJustifyContent,
  Label,
  Text,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/critical-facility/shared/DisplayCardViewInfoItemView.module.css'

const propTypes = {
  label: PropTypes.string,
  isFirstItem: PropTypes.bool,
  value: PropTypes.node,
  wrappingType: PropTypes.string,
}

/**
 * @typedef {object} overrides
 * @property {typeof WrappingType[keyof typeof WrappingType]} [wrappingType]
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 */
const DisplayCardViewInfoItemView = ({
  label,
  isFirstItem,
  value,
  wrappingType = WrappingType.None,
}) => (
  <FlexBox
    fitContainer
    justifyContent={FlexBoxJustifyContent.SpaceBetween}
    className={compact([styles.displayCardItem, isFirstItem && styles.firstItem]).join(' ')}
  >
    <Label wrappingType={wrappingType ?? undefined} className={styles.displayCardLabel}>
      {label}
    </Label>
    <div className={styles.displayCardTextBox}>
      <Text>{value}</Text>
    </div>
  </FlexBox>
)

DisplayCardViewInfoItemView.propTypes = propTypes

export default DisplayCardViewInfoItemView
