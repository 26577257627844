import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/deal-uses-and-sources/table-cells/ShareCell.module.css'
import { useNumberFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/deal-uses-and-sources/table-cells/shared/useI18n'

const hyphen = '-'

const ShareCell = ({ value: { share, isEmptyCell, isSectionHeader } }) => {
  const formatPercentage = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: 'percent',
  })
  const className = isSectionHeader ? styles.bold : ''
  if (isEmptyCell) {
    return
  }
  if (Number.isNaN(share)) {
    return <Text className={className}>{hyphen}</Text>
  }
  return <Text className={className}>{formatPercentage(share)}</Text>
}

ShareCell.propTypes = {
  value: PropTypes.shape({
    share: PropTypes.number,
    isEmptyCell: PropTypes.bool,
    isSectionHeader: PropTypes.bool,
  }).isRequired,
}

export default ShareCell
