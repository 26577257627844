import { FlexBox, MessageStrip, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ComplianceFieldMissingMessageStrip.module.css'

const ComplianceFieldMissingMessageStrip = ({ compliance }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.shared.compliance-field-missing-message-strip',
  })
  if (isNil(compliance)) {
    return (
      <FlexBox className={styles.riskConformityMessageStrip}>
        <MessageStrip hideCloseButton design={MessageStripDesign.Warning}>
          {t('message')}
        </MessageStrip>
      </FlexBox>
    )
  }
}

ComplianceFieldMissingMessageStrip.propTypes = {
  compliance: PropTypes.bool,
}

export default ComplianceFieldMissingMessageStrip
