import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useConfig } from 'hooks/config/useConfig'
import LocationiqClient from 'lib/locationiq/LocationiqClient'

const DEFAULT_MIN_LENGTH = 3
const DEFAULT_LIMIT = 10

export const useLocationAutocomplete = (minLength = DEFAULT_MIN_LENGTH, limit = DEFAULT_LIMIT) => {
  const { data: config, isLoading: isConfigLoading, isError: isConfigError } = useConfig()
  const [requestParams, setRequestParams] = useState({ query: '' })
  const client = config?.locationIq && LocationiqClient(config.locationIq)
  const {
    data: autocompleteResults,
    isLoading: isAutocompleteLoading,
    isError,
  } = useQuery(
    [requestParams],
    async () => {
      if (!client || requestParams?.query?.length < minLength) {
        return []
      }
      return await client.getAutocomplete({
        limit,
        includeAddressDetails: true,
        ...requestParams,
      })
    },
    {
      keepPreviousData: true,
      staleTime: 1,
    },
  )
  const setAutocompleteQuery = (query, additionalApiOptions = {}) =>
    setRequestParams({ ...additionalApiOptions, query })
  return {
    autocompleteResults,
    setAutocompleteQuery,
    isAutocompleteLoading: isConfigLoading || isAutocompleteLoading,
    isError: isConfigError || isError,
  }
}

export const useLocationReverseGeocoding = () => {
  const { data: config, isLoading: isConfigLoading, isError: isConfigError } = useConfig()
  const client = config?.locationIq && LocationiqClient(config.locationIq)
  const [reverseGeocodingParams, setReverseGeocodingParams] = useState({ lat: '', lon: '' })
  const {
    data: reverseGeocodingResults,
    isLoading: isReverseGeocodingLoading,
    isError,
  } = useQuery(
    ['reverse-geocoding', reverseGeocodingParams.lat, reverseGeocodingParams.lon],
    () => {
      if (!client || (!reverseGeocodingParams.lat && !reverseGeocodingParams.lon)) {
        return []
      }
      return client.getReverseGeocoding({
        ...reverseGeocodingParams,
      })
    },
  )
  const setReverseGeocodingRequest = (lat, lon, additionalApiOptions = {}) =>
    setReverseGeocodingParams({ ...additionalApiOptions, lat, lon })
  return {
    reverseGeocodingResults,
    setReverseGeocodingRequest,
    isReverseGeocodingLoading: isConfigLoading || isReverseGeocodingLoading,
    isError: isConfigError || isError,
  }
}
