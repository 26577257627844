import isNil from 'lodash.isnil'
import customClickEventToMouseEvent from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/periodical-checks/checks/table/shared/link/customClickEventToMouseEvent'
import useNavigate from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/periodical-checks/checks/table/shared/link/useNavigate'

/** @param {MouseEvent | CustomEvent<{ctrlKey: boolean | undefined, metaKey: boolean | undefined}>} event */
const shouldOpenInNewTab = (event) => {
  // TODO: figure out what OS the user is on (Ctrl on Mac should not open in new tab)
  // Better: create an <a/> element and figure out where it would open the link
  if (event instanceof CustomEvent) {
    event = customClickEventToMouseEvent(event)
  }
  return event.ctrlKey || event.metaKey
}

/**
 * helper hook to easily create a click handler
 *
 * Works with default `MouseEvent`s and UI5 `CustomEvent`s.
 *
 * **Caveat: UI5 click events on arbitrary elements don't support Ctrl- / Cmd-Clicking**
 * If this is needed, you need to create a component to circumvent this (e. g. `ClickableCardHeader`)
 *
 * Example:
 * ```jsx
 * const createClickHandler = useCreateNavigateClickHandler()
 * const link = <a onClick={createClickHandler('https://some.page')}>click me</a>
 * ```
 * @typedef {import("components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/link/useNavigate").options} options
 * @param {options} [defaultOptions]
 */
const useCreateNavigateClickHandler = (defaultOptions) => {
  const navigate = useNavigate(defaultOptions)

  /**
   * @param {import("react-router-dom").To} [to]
   * @param {options} [optionOverrides]
   */
  const createClickHandler =
    (to, optionOverrides) =>
    /** @param {MouseEvent | CustomEvent} event */
    (event) => {
      const options = optionOverrides ?? {}
      if (isNil(to)) return
      if (shouldOpenInNewTab(event)) {
        options.target = '_blank'
      }
      navigate(to, options)
    }

  return createClickHandler
}

export default useCreateNavigateClickHandler
