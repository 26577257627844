import isNil from 'lodash.isnil'

/**
 * @typedef {Record<string, boolean>} RowSelection
 * @typedef {{id: string, subRows: SelectedRow[], original: unknown }} SelectedRow
 * @typedef {{selectedRowIds: RowSelection, selectedFlatRows: SelectedRow[], allRowsSelected: boolean, isSelected: boolean,  row: SelectedRow}} RowSelectionEvent
 * @typedef {(selectedRowIds: RowSelectionEvent) => void} RowSelectionHandler
 *
 * @typedef {object} Tile
 * @property {string} code Tile Name. For example "GENERAL_INFORMATION"
 * @property {string} type Tile Type. For example "MANUAL" or "AUTOMATIC"
 * @property {string} subType Tile Subtype, for example "RICH_TEXT"
 *
 * @typedef {object} Subsection
 * @property {string} code Code of the subsection. For example "DETAILS"
 * @property {string} name Name of the subsection. For example "Details"
 * @property {boolean} hideAssessment whether to hide the assessment of this subsection or not
 * @property {Tile[]} tiles List of Tile config values
 *
 * @typedef {object} Section
 * @property {string} code Code of the section. For example "REQUEST"
 * @property {string} name Name of the section. For example "Request"
 * @property {Subsection[]} subsections List of Subsection config values
 * @property {Tile[]} tiles List of Tile config values
 *
 * @typedef {object} Template
 * @property {string} code Code of the section. For example "DRAWDOWN"
 * @property {string} name Name of the section. For example "Drawdown"
 * @property {Section[]} sections List of Section config values
 */

const ASSESSMENT_TYPE = 'ASSESSMENT'

/**
 * @param {string} rowId
 * @returns {string} the parent row id
 */
const getParentRowId = (rowId) => rowId.slice(0, rowId.lastIndexOf('.'))

/**
 * Checks if an assessment row is a 'standalone' assessment row.
 * Standalone assessment rows are selected assessment rows that
 * have no other selected sibling tile.
 *
 * @param {SelectedRow} row
 * @param {SelectedRow[]} selectedFlatRows
 * @returns {boolean}
 */
const isStandaloneAssessmentRow = (row, selectedFlatRows) => {
  if (row.original?.typecode !== ASSESSMENT_TYPE) {
    return false
  }
  const siblingRowPrefix = `${getParentRowId(row.id)}.`
  const otherSiblingLeafRows = selectedFlatRows.filter(
    (selectedRow) =>
      selectedRow.id.startsWith(siblingRowPrefix) &&
      selectedRow.original?.typecode !== ASSESSMENT_TYPE &&
      selectedRow.subRows.length === 0,
  )
  return otherSiblingLeafRows.length === 0
}

/**
 * Remove unselected entries from the selection object.
 * Reason: Analytical Table state marks the header selection checkbox as
 * 'indeterminate' as long as an entry exists for the selection object, even
 * if all values of this object are 'false' and therefore deselected.
 *
 * @param {RowSelection} rowSelection
 * @returns {RowSelection}
 */
const pruneUnselected = (rowSelection) =>
  Object.fromEntries(Object.entries(rowSelection).filter(([, isSelected]) => isSelected))

/**
 * @param {string} selectedRowId
 * @returns {RowSelection}
 */
const addParentSelection = (selectedRowId) => {
  // get ID parts of parent sections. id '1.3.5.2' => [1, 3, 5]
  const parentIdParts = selectedRowId.split('.').slice(0, -1)
  if (parentIdParts.length <= 0) {
    return {}
  }

  /**
   * Map over each entry in the parent Id array ([1, 3, 5]) and return a
   * string created by joining every part up to the current index.
   * [1, 3, 5] => [[1].join('.'), [1, 3].join('.'), [1, 3, 5].join('.')]
   * which results in a list of all parent IDs of this element
   * ['1', '1.3', '1.3.5']
   */
  return Object.fromEntries(
    parentIdParts.map((_parentIdPart, index) => [
      parentIdParts.slice(0, index + 1).join('.'),
      true,
    ]),
  )
}
/**
 * @param {RowSelection} rowSelection
 * @param {string} deselectedRowId
 */
const pruneEmptyParentSelection = (rowSelection, deselectedRowId) => {
  const selectedRowIds = Array.from(Object.keys(pruneUnselected(rowSelection)))
  const parentRowId = getParentRowId(deselectedRowId)
  const siblingRowPrefix = `${parentRowId}.`
  const isChildSelected =
    selectedRowIds.filter((rowId) => rowId.startsWith(siblingRowPrefix)).length > 0
  if (isChildSelected || !parentRowId) {
    return rowSelection
  }
  const prunedRowSelection = {
    ...rowSelection,
    [parentRowId]: false,
  }
  return {
    ...prunedRowSelection,
    ...pruneEmptyParentSelection(prunedRowSelection, parentRowId),
  }
}

/**
 *
 * @param {SelectedRow[]} selectedFlatRows
 * @param {RowSelection} rowSelection
 * @returns {RowSelection}
 */
const pruneStandaloneAssessmentSelection = (selectedFlatRows, rowSelection) => {
  const standaloneAssessmentRows = selectedFlatRows.filter((row) =>
    isStandaloneAssessmentRow(row, selectedFlatRows),
  )
  const prunedAssessmentSelection = Object.fromEntries(
    standaloneAssessmentRows.map(({ id }) => [id, false]),
  )
  const updatedRowSelection = { ...rowSelection, ...prunedAssessmentSelection }
  const prunedParentSelection = standaloneAssessmentRows
    .map(({ id }) => pruneEmptyParentSelection(updatedRowSelection, id))
    .reduce((acc, current) => ({ ...acc, ...current }), {})

  return {
    ...prunedParentSelection,
    ...prunedAssessmentSelection,
  }
}

/**
 * Normalizes the row selection of the Template customization table.
 * This includes:
 * * checking or unchecking all child checkboxes if parent selection changes
 * * checking or unchecking parent checkboxes is child selection changes
 * * removing checked assessment rows if those rows would be the solidary tile in the section or subsection
 * @param {RowSelectionEvent} rowSelectionEvent
 */
export const normalizeRowSelection = (rowSelectionEvent) => {
  const baseSelection = rowSelectionEvent.selectedRowIds

  /** @param {SelectedRow[]} subRows */
  const flattenRowIds = (subRows) =>
    subRows.flatMap((row) => [row.id, ...flattenRowIds(row.subRows)])
  /**
   * Skip if all rows are selected. Either by manual selection or if
   * the header checkbox was clicked. Either way, nothing to be done!
   */
  if (rowSelectionEvent.allRowsSelected) {
    return baseSelection
  }

  if (isNil(rowSelectionEvent.row)) {
    return baseSelection
  }

  const row = rowSelectionEvent.row
  const subRows = row.subRows
  const isSelected = rowSelectionEvent.isSelected

  /**
   * if an expandable row was checked or unchecked, set all children to
   * the same checked value as the parent
   */
  if (subRows.length > 0) {
    const childSelectionOverride = Object.fromEntries(
      flattenRowIds(subRows).map((rowId) => [rowId, isSelected]),
    )
    const updatedChildSelection = { ...baseSelection, ...childSelectionOverride }
    const parentSelectionOverride = isSelected
      ? addParentSelection(row.id)
      : pruneEmptyParentSelection(updatedChildSelection, row.id)
    return pruneUnselected({
      ...updatedChildSelection,
      ...parentSelectionOverride,
    })
  }
  // when a leaf row was checked or unchecked

  // check or uncheck parent selection checkbox if needed
  const parentSelection = isSelected
    ? addParentSelection(row.id)
    : pruneEmptyParentSelection(baseSelection, row.id)
  const updatedSelection = { ...baseSelection, ...parentSelection }

  /**
   * if a assessment is kept as the last remaining (leaf node) selection,
   * cancel selection of that row
   */
  const prunedAssessmentSelection = pruneStandaloneAssessmentSelection(
    rowSelectionEvent.selectedFlatRows,
    updatedSelection,
  )

  return pruneUnselected({ ...updatedSelection, ...prunedAssessmentSelection })
}
