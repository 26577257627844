import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Icon,
  Text,
  Title,
  Toolbar,
  ToolbarSpacer,
  ToolbarStyle,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionPaperVersion } from 'api/decision-paper/decisionPaperApi'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileHeader.module.css'

const DecisionPaperTileHeader = ({
  titleText,
  isExpanded,
  isPdfView,
  source,
  customSource,
  additionalActions,
  onCollapse,
  onExpand,
  allowCollapse = true,
  currentVersion,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.tiles.header',
  })
  const collapseButton = useMemo(
    () => (
      <Icon className={styles.expansionStateIcon} onClick={onCollapse} name="slim-arrow-down" />
    ),
    [onCollapse],
  )
  const expandButton = useMemo(
    () => <Icon className={styles.expansionStateIcon} onClick={onExpand} name="slim-arrow-right" />,
    [onExpand],
  )

  const displaySource =
    source && currentVersion === decisionPaperVersion.workingVersion && !isPdfView

  const displayCustomSource =
    customSource && currentVersion === decisionPaperVersion.workingVersion && !isPdfView

  return (
    <FlexBox
      direction={FlexBoxDirection.Row}
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      alignItems={FlexBoxAlignItems.Center}
      className={styles.header}
      slot="header"
    >
      <FlexBox direction={FlexBoxDirection.Row}>
        {!isPdfView && allowCollapse && (
          <FlexBox alignItems={FlexBoxAlignItems.Center} className={styles.buttonWrapper}>
            {isExpanded ? collapseButton : expandButton}
          </FlexBox>
        )}
        <FlexBox direction={FlexBoxDirection.Column} className={styles.headerFlexBox}>
          <Title className={styles.headerText} role="heading" part="title">
            {titleText}
          </Title>
          {displaySource && (
            <FlexBox alignItems={FlexBoxAlignItems.Center} className={styles.sourceFlexBox}>
              <Text className={styles.sourceLabel}>{t('source')}</Text>
              {source}
            </FlexBox>
          )}
          {displayCustomSource && customSource}
        </FlexBox>
      </FlexBox>
      {additionalActions && (
        <FlexBox>
          {/* set numberOfAlwaysVisibleItems to 99 to prevent display of overflow button and connected strange rendering behaviour
           (see https://fioneer.atlassian.net/jira/software/c/projects/CWP/boards/75?selectedIssue=CWP-12790&sprints=1101)
           This is a workaround until we migrate to ToolbarV2 as recommended by the official ui5 docs   */}
          <Toolbar toolbarStyle={ToolbarStyle.Clear} numberOfAlwaysVisibleItems={99}>
            <ToolbarSpacer />
            {additionalActions}
          </Toolbar>
        </FlexBox>
      )}
    </FlexBox>
  )
}

DecisionPaperTileHeader.propTypes = {
  titleText: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  source: PropTypes.element,
  additionalActions: PropTypes.any,
  customSource: PropTypes.element,
  onCollapse: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  allowCollapse: PropTypes.bool,
  currentVersion: PropTypes.string.isRequired,
}

export default DecisionPaperTileHeader
