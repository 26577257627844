import PropTypes from 'prop-types'
import { cloneElement } from 'react'
import styles from 'components/ui/tables/analytical/AnalyticalTableCell.module.css'

const AnalyticalTableCell = ({
  className,
  cell,
  data,
  aggregatedValue,
  renderAggregateRegularly,
  children,
  additionalTableData = {},
  additionalData = {},
  isAggregated = false,
  alignRight = false,
}) => {
  const classes = [styles.multilineCell]
  className && classes.push(className)
  alignRight && classes.push(styles.textRight)

  const rowData = data[cell.row.index]
  if (cell.isGrouped || (isAggregated && !renderAggregateRegularly)) {
    return (
      <div className={classes.join(' ')}>
        <b>
          {aggregatedValue
            ? aggregatedValue(cell.value, rowData, additionalData, {
                ...additionalTableData,
                cell,
                data,
              })
            : cell.value}
        </b>
      </div>
    )
  }
  return (
    <div className={classes.join(' ')}>
      {children(rowData, additionalData, { ...additionalTableData, cell, data })}
    </div>
  )
}

AnalyticalTableCell.propTypes = {
  children: PropTypes.func,
  data: PropTypes.array,
  cell: PropTypes.shape({
    isGrouped: PropTypes.bool,
    row: PropTypes.shape({ index: PropTypes.number }),
    value: PropTypes.any,
  }),
  className: PropTypes.string,
  additionalTableData: PropTypes.object,
  additionalData: PropTypes.object,
  isAggregated: PropTypes.bool,
  aggregatedValue: PropTypes.func,
  renderAggregateRegularly: PropTypes.bool,
  alignRight: PropTypes.bool,
}

export default AnalyticalTableCell

export const renderAnalyticalTableCell =
  (content, additionalData, isAggregated) =>
  ({ cell, data, ...rest }) =>
    cloneElement(content, {
      cell,
      data,
      additionalTableData: rest,
      additionalData,
      isAggregated,
    })
