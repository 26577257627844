export const eventErrorCodes = {
  fileSizeTooBigError: 'FILE_SIZE_TOO_BIG_ERROR',
  updateWithOpenMandatoryTasksNotPossibleError: 'EVENT_UPDATE_WITH_OPEN_TASKS_NOT_POSSIBLE_ERROR',
  fileEmptyError: 'FILE_EMPTY_ERROR',
  eventDefaultAssigneeFailedError: 'EVENT_DEFAULT_ASSIGNEE_FAILED_ERROR',
  outdatedTemplateError: 'REFERENCED_DECISION_PAPER_DATA_MISSING_ERROR',
}

const errorForEventRequest = {
  [eventErrorCodes.fileSizeTooBigError]: {
    translationKey: 'events.request-error.file-size-too-big',
  },
  [eventErrorCodes.fileEmptyError]: {
    translationKey: 'events.request-error.file-empty',
  },
  [eventErrorCodes.eventDefaultAssigneeFailedError]: {
    translationKey: 'events.request-error.default-assignee-failed',
  },
  default: {
    translationKey: 'events.request-error.unknown',
  },
}

export const getObjectErrorForEventRequest = (error) =>
  errorForEventRequest[error] !== undefined
    ? errorForEventRequest[error]
    : errorForEventRequest.default
