import camelize from 'camelize'
import { customEntityMetadataSchema } from 'components/ui/customfield/customFieldSchema'
import { useRequest } from 'hooks/services/baseService'

/**
 * @param {string} entityType
 */
const useBusinessPartnerCustomFieldConfig = (entityType) => {
  const response = useRequest({
    path: `/businesspartners/configurations/custom-fields/metadata?entity_type=${entityType}`,
    keys: ['businesspartners', 'configurations', 'custom-fields', entityType],
    translated: true,
  })

  const parsed = customEntityMetadataSchema.optional().safeParse(camelize(response.data))

  if (parsed.success) {
    return { ...response, data: parsed.data }
  }

  // eslint-disable-next-line no-console
  console.error(parsed.error)
  return { ...response, data: undefined, isError: true, error: parsed.error }
}

export default useBusinessPartnerCustomFieldConfig
