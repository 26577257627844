import { FlexBox, FlexBoxAlignItems } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { forwardRef, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionStageVoterStatus } from 'api/decision-process/decisionProcessApi'
import BusinessObjectLink, {
  BusinessObjectLinkCustomRender,
} from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import DecisionStageConditionsApprovalVoterHeaderButtons from 'components/domains/business-events-and-tasks/decision-stage/conditions/approval/DecisionStageConditionsApprovalVoterHeaderButtons'
import Header from 'components/ui/page/Header'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionStageConditionsApprovalHeader = forwardRef(
  ({ hideVoterPage, decisionStageConditionsVoterId, voterStatusCode, ...headerProps }, ref) => {
    const {
      event: {
        entityRef: { entityId, entityType },
      },
    } = useContext(BusinessEventsAndTasksContext)
    const { t } = useTranslation('translation', {
      keyPrefix: 'components.business-events-and-tasks.decision-stage.conditions.approval.header',
    })

    const showVoteButtons = voterStatusCode && voterStatusCode === decisionStageVoterStatus.pending

    const headerActions = (
      <FlexBox alignItems={FlexBoxAlignItems.Center}>
        {showVoteButtons && (
          <DecisionStageConditionsApprovalVoterHeaderButtons
            decisionStageConditionsVoterId={decisionStageConditionsVoterId}
          />
        )}
      </FlexBox>
    )

    const subtitle = useMemo(
      () => (
        <BusinessObjectLink
          entityId={entityId}
          entityType={entityType}
          customRender={BusinessObjectLinkCustomRender.clipboard}
        />
      ),
      [entityId, entityType],
    )

    return (
      <Header
        {...headerProps}
        ref={ref}
        title={t('title')}
        subtitle={subtitle}
        actions={!hideVoterPage && headerActions}
      />
    )
  },
)

DecisionStageConditionsApprovalHeader.displayName = 'DecisionStageConditionsApprovalHeader'

DecisionStageConditionsApprovalHeader.propTypes = {
  decisionStageConditionsVoterId: PropTypes.string,
  hideVoterPage: PropTypes.bool.isRequired,
  voterStatusCode: PropTypes.oneOf(Object.values(decisionStageVoterStatus)),
}

export default DecisionStageConditionsApprovalHeader
