import {
  Card,
  CardHeader,
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { businessEventsAllowedOperations } from 'api/events/eventAllowedOperations'
import { entityTypes } from 'api/events/status'
import EventsSummaryCardWrapper from 'components/domains/business-events-and-tasks/events/events-summary-card/EventsSummaryCardWrapper'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useEventsAllowedOperations from 'hooks/services/business-events-and-tasks/events/useEventsAllowedOperations'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyEventsSummaryCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property-overview.event-management-summary-card',
  })
  const {
    property: { uuid: propertyUuid, type_name: propertyName, id: propertyDisplayId },
  } = useContext(PropertyContext)

  const {
    data: allowedEventsOperationsData,
    isFetching: allowedEventsOperationsIsFetching,
    isError: allowedEventsOperationsIsError,
  } = useEventsAllowedOperations()

  const displayCreateEventButton = !!allowedEventsOperationsData?.allowed_operations.includes(
    businessEventsAllowedOperations.createEvent,
  )

  const CustomErrorScreen = () => (
    <Card header={<CardHeader titleText={t('title')} />}>
      <IllustratedMessage
        name={IllustrationMessageType.UnableToLoad}
        size={IllustrationMessageSize.Scene}
        titleText={t('error.title')}
        subtitleText={t('error.description')}
      />
    </Card>
  )

  const renderContent = () => (
    <EventsSummaryCardWrapper
      entity={{
        entityDisplayId: propertyDisplayId,
        entityType: entityTypes.property,
        entityName: propertyName,
        entityId: propertyUuid,
      }}
      entityType={entityTypes.property}
      displayCreateEventButton={displayCreateEventButton}
    />
  )

  return (
    <RequestStateResolver
      isLoading={allowedEventsOperationsIsFetching}
      isError={allowedEventsOperationsIsError}
      renderContent={renderContent}
      errorToDisplay={<CustomErrorScreen />}
    />
  )
}

export default PropertyEventsSummaryCard
