import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useCovenantLimitsById = (covenantUuid, options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/covenants/${covenantUuid}/limits`,
      translated: true,
      useCache: true,
      keys: ['covenants', covenantUuid, 'limits'],
      options: {
        enabled: !!covenantUuid,
        ...options,
      },
    }),
  )

export default useCovenantLimitsById
