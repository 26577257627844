export const compareNodesByStringAttribute = (node1, node2, getValue) => {
  const value1 = getValue(node1)?.toString() || ''
  const value2 = getValue(node2)?.toString() || ''

  return value1.localeCompare(value2)
}

export const compareNodesByNumberAttribute = (node1, node2, getValue) => {
  const value1 = getValue(node1) || 0
  const value2 = getValue(node2) || 0

  if (value1 < value2) return -1
  if (value1 === value2) return 0
  return 1
}
