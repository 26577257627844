import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useArrearApprovalsByBusinessPartnerId = (
  businessPartnerId,
  options = {},
  requestOptions = {},
) =>
  useCamelizedResponse(
    useRequest({
      path: `/arrears/approvals/${businessPartnerId}`,
      keys: ['arrears', 'approvals', businessPartnerId],
      options,
      requestOptions,
    }),
  )
