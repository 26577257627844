import { useMemo } from 'react'
import { entityTypes } from 'api/conditions/conditions'
import useAnnualReviewBusinessPartnerOverview from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/bp-overview/shared/useAnnualReviewBusinessPartnerOverview'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useMultipleCustomAssessmentVersions from 'hooks/services/central-data/custom-assessments/useMultipleCustomAssessmentVersions'

const useBorrowerEsgStatement = (__, tileId) => {
  const { allBorrowersIds, selectedBorrowerId } = useAnnualReviewBusinessPartnerOverview()

  const {
    data: multipleCustomAssessmentData,
    isMultipleCustomAssessmentVersionsLoading,
    isMultipleCustomAssessmentVersionsError,
    error: multipleCustomAssessmentsVersionsError,
  } = useMultipleCustomAssessmentVersions({
    uuids: allBorrowersIds,
    assessmentCode: 'BUSINESS_PARTNER_ESG_STATEMENT',
    entityType: entityTypes.businesspartner,
  })

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isMultipleCustomAssessmentVersionsLoading],
    errorValues: [isMultipleCustomAssessmentVersionsError],
    errorDetails: [multipleCustomAssessmentsVersionsError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueLoading || isSomeValueError) {
      return { isLoading: isSomeValueLoading, isError: isSomeValueError, error }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        multipleCustomAssessmentData,
        sourceRender: {
          businessPartnerDisplayId: selectedBorrowerId,
        },
      },
    }
  }, [
    isSomeValueLoading,
    isSomeValueError,
    multipleCustomAssessmentData,
    selectedBorrowerId,
    error,
  ])
}

export default useBorrowerEsgStatement
