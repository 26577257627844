import {
  Button,
  ButtonDesign,
  ObjectPageSection,
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { businessEventsAllowedOperations } from 'api/events/eventAllowedOperations'
import EventCreationDialog from 'components/domains/business-events-and-tasks/events/dialog/EventCreationDialog'
import Header from 'components/ui/page/Header'
import TabPage from 'components/ui/page/TabPage'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useEventsAllowedOperations from 'hooks/services/business-events-and-tasks/events/useEventsAllowedOperations'
import EventOverview from 'routes/business-events-and-tasks/events/EventOverview'
import TaskManager from 'routes/business-events-and-tasks/tasks/TaskManager'

const BusinessEventsAndTasks = () => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', { keyPrefix: 'pages.business-events-and-tasks' })
  const [isCreationDialogOpen, setIsCreationDialogOpen] = useState(false)

  const { data: allowedOperationsData, isLoading, isError } = useEventsAllowedOperations()

  const eventReadAllowed = allowedOperationsData?.allowed_operations?.includes(
    businessEventsAllowedOperations.readEvent,
  )
  const taskReadAllowed = allowedOperationsData?.allowed_operations?.includes(
    businessEventsAllowedOperations.readTask,
  )
  const eventCreateAllowed = allowedOperationsData?.allowed_operations?.includes(
    businessEventsAllowedOperations.createEvent,
  )

  const onEventCreationButtonClicked = () => {
    setIsCreationDialogOpen(true)
  }

  const headerButtons = (
    <>
      {eventCreateAllowed && eventReadAllowed && (
        <Button
          key="create-event"
          id="create-event"
          design={ButtonDesign.Emphasized}
          onClick={onEventCreationButtonClicked}
        >
          {t('button.create-event')}
        </Button>
      )}
    </>
  )

  const sections = {
    businessEvents: 'business-events',
    tasks: 'tasks',
  }

  const breadcrumbs = [
    {
      text: t('breadcrumb.title'),
    },
  ]

  const headerTitle = (
    <Header title={t('title')} actions={headerButtons} breadcrumbs={breadcrumbs} />
  )

  const renderEventOverview = () => {
    if (!eventReadAllowed) {
      return (
        <IllustratedMessage
          size={IllustrationMessageSize.Spot}
          name={IllustrationMessageType.UnableToLoad}
          titleText={tNoPrefix('app.permission-error.title')}
          subtitleText={tNoPrefix('app.permission-error.subtitle')}
        />
      )
    }
    return <EventOverview />
  }

  const renderTaskManager = () => {
    if (!taskReadAllowed) {
      return (
        <IllustratedMessage
          size={IllustrationMessageSize.Spot}
          name={IllustrationMessageType.UnableToLoad}
          titleText={tNoPrefix('app.permission-error.title')}
          subtitleText={tNoPrefix('app.permission-error.subtitle')}
        />
      )
    }
    return <TaskManager />
  }

  return (
    <>
      <TabPage headerTitle={headerTitle} defaultSectionId={sections.businessEvents}>
        <ObjectPageSection
          key={sections.businessEvents}
          id={sections.businessEvents}
          titleText={tNoPrefix('pages.event-overview.title')}
        >
          <LoadingStateWrapper
            isError={isError}
            isLoading={isLoading}
            renderContent={renderEventOverview}
          />
        </ObjectPageSection>
        <ObjectPageSection
          key={sections.tasks}
          id={sections.tasks}
          titleText={tNoPrefix('pages.task-manager.title')}
        >
          <LoadingStateWrapper
            isError={isError}
            isLoading={isLoading}
            renderContent={renderTaskManager}
          />
        </ObjectPageSection>
      </TabPage>
      {isCreationDialogOpen && (
        <EventCreationDialog isOpen={isCreationDialogOpen} setIsOpen={setIsCreationDialogOpen} />
      )}
    </>
  )
}

export default BusinessEventsAndTasks
