import {
  Button,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  OverflowToolbarButton,
  TableCell,
  Toolbar,
  ToolbarDesign,
  ToolbarStyle,
} from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import compact from 'lodash.compact'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import LoadingButton from 'components/ui/button/LoadingButton'
import actionStyles from 'components/ui/tables/display-and-edit-table/DisplayAndEditTableActionCell.module.css'

export const DisplayAndEditTableActionCell = ({
  rowKey,
  handleSaveClick,
  handleCancelClick,
  handleEditClick,
  handleDeleteClick,
  isEdit,
  cardType,
  isValid,
  isWritingToBackend = false,
  userIsAllowedToEdit,
  hideEditButton,
  hideDeleteButton,
  rowChanged,
  styles,
  customRowActions = [],
  collapseActionsWhenSize,
}) => {
  const numberOfActions = useMemo(() => {
    let number = 0
    if (!hideEditButton) number++
    if (!hideDeleteButton) number++
    customRowActions.forEach((customRowAction) => {
      if (!customRowAction.hide) number++
    })
    return number
  }, [customRowActions, hideDeleteButton, hideEditButton])

  const renderSaveCancelCell = () => (
    <FlexBox
      id={`${rowKey}-action-cell-save-cancel`}
      direction="Row"
      justifyContent="End"
      alignItems="Center"
      fitContainer
      className={styles?.editModeButtons}
    >
      <LoadingButton
        id={`${rowKey}-save`}
        style={{ marginRight: '8px' }}
        design="Emphasized"
        onClick={() => {
          handleSaveClick()
        }}
        renderContent={() => t('buttons.save')}
        disabled={!isValid || !rowChanged}
        isLoading={!!isWritingToBackend}
      />
      <Button
        id={`${rowKey}-cancel-${cardType}`}
        design="Transparent"
        onClick={() => handleCancelClick()}
        disabled={!!isWritingToBackend}
      >
        {t('buttons.cancel')}
      </Button>
    </FlexBox>
  )

  const renderExpandedButtons = () =>
    userIsAllowedToEdit ? (
      <FlexBox
        id={`${rowKey}-action-cell-expanded`}
        justifyContent={FlexBoxJustifyContent.End}
        className={compact([styles?.readModeButtons, actionStyles.readModeButtonsWrapper]).join(
          ' ',
        )}
        alignItems={FlexBoxAlignItems.Center}
      >
        {!hideEditButton && (
          <Button
            id={`${rowKey}-edit`}
            design="Transparent"
            icon="edit"
            onClick={() => handleEditClick()}
          />
        )}
        {customRowActions.map(
          ({ id, icon, handler, hide, disabled }) =>
            !hide && (
              <Button
                key={`${rowKey}-custom-${id}-${cardType}`}
                id={`${rowKey}-custom-${id}-${cardType}`}
                design="Transparent"
                icon={icon}
                onClick={() => handler()}
                disabled={disabled}
              />
            ),
        )}
        {!hideDeleteButton && (
          <Button
            id={`${rowKey}-delete-${cardType}`}
            design="Transparent"
            icon="delete"
            onClick={() => handleDeleteClick()}
          />
        )}
      </FlexBox>
    ) : (
      <></>
    )

  const renderCollapsedButtons = () => (
    <FlexBox
      id={`${rowKey}-action-cell-collapsed`}
      justifyContent={FlexBoxJustifyContent.End}
      className={compact([styles?.readModeButtons, actionStyles.readModeButtonsWrapper]).join(' ')}
      alignItems={FlexBoxAlignItems.Center}
    >
      <Toolbar
        design={ToolbarDesign.Auto}
        toolbarStyle={ToolbarStyle.Clear}
        style={{
          width: 'var(--sapUiMediumSpace)', // including styles from module.css via className somehow does not work
        }}
      >
        {!hideEditButton && (
          <OverflowToolbarButton
            key={`${rowKey}-edit-${cardType}`}
            id={`${rowKey}-edit-${cardType}`}
            design="Transparent"
            tooltip={t('buttons.edit')}
            onClick={() => handleEditClick()}
          >
            {t('buttons.edit')}
          </OverflowToolbarButton>
        )}
        {customRowActions.map(
          ({ id, text, handler, hide, disabled }) =>
            !hide && (
              <OverflowToolbarButton
                key={`${rowKey}-custom-${id}-${cardType}`}
                id={`${rowKey}-custom-${id}-${cardType}`}
                design="Transparent"
                onClick={() => handler()}
                disabled={disabled}
              >
                {text}
              </OverflowToolbarButton>
            ),
        )}
        {!hideEditButton && (
          <OverflowToolbarButton
            key={`${rowKey}-delete-${cardType}`}
            id={`${rowKey}-delete-${cardType}`}
            design="Transparent"
            tooltip={t('buttons.delete')}
            onClick={() => handleDeleteClick()}
          >
            {t('buttons.delete')}
          </OverflowToolbarButton>
        )}
      </Toolbar>
    </FlexBox>
  )

  const renderExpandedOrCollapsedButtons = () =>
    !!collapseActionsWhenSize && numberOfActions >= collapseActionsWhenSize
      ? renderCollapsedButtons()
      : renderExpandedButtons()

  return (
    <TableCell
      className={isEdit ? styles?.editModeCell : styles?.readModeCell}
      key={rowKey + '-actionButtons'}
    >
      {isEdit ? renderSaveCancelCell() : renderExpandedOrCollapsedButtons()}
    </TableCell>
  )
}

DisplayAndEditTableActionCell.propTypes = {
  rowKey: PropTypes.string,
  handleSaveClick: PropTypes.func.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  userIsAllowedToEdit: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  rowChanged: PropTypes.bool.isRequired,
  styles: PropTypes.shape({
    editModeCell: PropTypes.string,
    readModeCell: PropTypes.string,
    editModeButtons: PropTypes.string,
    readModeButtons: PropTypes.string,
  }),
  isWritingToBackend: PropTypes.bool,
  customRowActions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      handler: PropTypes.func.isRequired,
      hide: PropTypes.bool.isRequired,
      disabled: PropTypes.bool.isRequired,
    }),
  ),
  collapseActionsWhenSize: PropTypes.number,
}
