import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const getUserFavoritesGetKey = (entityType) => ['user-favorites', entityType]

/**
 * Hook that user favorites filtered by given entityType
 *
 * @param entityType {('DEAL'|'MARKET'|'BUSINESS_PARTNER'|'PROPERTY')} should be one of cwpEntityTypes
 * @param queryOptions {Object}  are the react-query query options
 * @returns {{data, isError, isLoading}}
 */
const useUserFavoritesGet = (entityType, queryOptions = {}) => {
  const response = useRequest({
    path: `/user-profile/favorite?entity_type=${entityType}`,
    keys: getUserFavoritesGetKey(entityType),
    options: queryOptions,
  })
  return useCamelizedResponse(response)
}

export default useUserFavoritesGet
