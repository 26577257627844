import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/relationships/UnitMembersTable.module.css'
import mapUnitMembersForTable from 'components/domains/business-partners/tile/relationships/mapUnitMembersForTable'
import sortUnitMembersForTable from 'components/domains/business-partners/tile/relationships/sortUnitMembersForTable'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'
import useRelationshipTypes from 'hooks/services/business-partners/config/useRelationshipTypes'

const UnitMembersTable = ({ members, className }) => {
  const { isLoading, isError, data } = useRelationshipTypes()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-partner.units.table',
  })
  const renderTable = () => {
    const columns = [
      {
        title: t('business-partner'),
        columnKey: 'businessPartner',
        filter: filterTypes.CONTAINS,
      },
      {
        title: t('relationship-type'),
        columnKey: 'relationshipType',
        filter: filterTypes.OF_ENUM_TYPE,
        additionalFilterOptions: {
          enumValues:
            data?.types?.reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {}) || {},
        },
      },
      {
        title: t('country'),
        columnKey: 'country',
        filter: filterTypes.CONTAINS,
      },
      {
        title: t('postal-code'),
        columnKey: 'postalCode',
        filter: filterTypes.CONTAINS,
      },
      {
        title: t('city'),
        columnKey: 'city',
        filter: filterTypes.CONTAINS,
      },
      {
        title: t('street'),
        columnKey: 'streetName',
        filter: filterTypes.CONTAINS,
      },
    ]
    return (
      <div className={styles.tableWrapper}>
        <SortedTable
          columnDefinitions={columns}
          customOrderFunction={sortUnitMembersForTable}
          tableData={mapUnitMembersForTable(members)}
          additionalTableProperties={{ className }}
          renderEmptyTableComponent={() => (
            <>
              {/* if there's no content, the backend doesn't return a unit
        => There's always at least one unit member in this list */}
            </>
          )}
          toolbarConfig={{
            title: t('toolbar.title'),
            className,
            sorting: {
              columnKey: columns[0].columnKey,
            },
            searching: true,
          }}
        />
      </div>
    )
  }

  return <LoadingStateWrapper isLoading={isLoading} isError={isError} renderContent={renderTable} />
}

UnitMembersTable.propTypes = {
  members: PropTypes.any.isRequired,
  className: PropTypes.string,
}

export default UnitMembersTable
