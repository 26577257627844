import { Grid } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import { useArrearsKpiCardsData } from 'components/domains/business-partners/tile/arrears/kpis/useArrearsKpiCardsData'
import Card from 'components/ui/card/Card'
import CardHeaderWithMetrics from 'components/ui/card/CardHeaderWithMetrics'
import {
  useNumberFormatter,
  usePercentageFormatter,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'

export const TODAY = new Date().toISOString().substring(0, 10)

const ArrearsKpiCards = () => {
  const { format: formatDate } = useShortDateFormatter()
  const formatKpiValue = useNumberFormatter({
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    notation: 'compact',
  })
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears.kpi-cards',
  })

  const { isLoading, isError, data: kpisData } = useArrearsKpiCardsData()

  const getValueOrPlaceholder = (kpiValue) => (kpiValue ? formatKpiValue(kpiValue) : '-')
  const getUnitOrPlaceholder = ({ value, isCurrency } = {}) => {
    if (value) {
      return isCurrency ? value : t(value)
    }
    return '-'
  }
  const getPercentageOrPlaceHolder = (percentage) =>
    percentage ? formatPercentage(percentage) : '-'

  const getValueOrUndefined = (kpiValue) =>
    !isLoading && !isError ? getValueOrPlaceholder(kpiValue) : undefined

  const getFormattedKeyDateAndUnit = (keyDate, unit) => {
    if (keyDate === null) {
      return getUnitOrPlaceholder(unit)
    }
    return `${formatDate(keyDate)} | ${getUnitOrPlaceholder(unit)}`
  }

  return (
    <Grid defaultSpan="XL4 L4 M12 S12" hSpacing={'16px'}>
      {kpisData.map(
        ({
          cardTitle,
          unit = undefined,
          kpiValue = undefined,
          secondaryMetrics = [],
          keyDate = null,
        }) => (
          <Card key={`key-${cardTitle}`}>
            <CardHeaderWithMetrics
              title={t(cardTitle)}
              primaryMetric={{
                label: !isLoading && getFormattedKeyDateAndUnit(keyDate, unit),
                // We want undefined to show the loading spinner
                value: getValueOrUndefined(kpiValue),
              }}
              secondaryMetrics={secondaryMetrics.map(({ label, value }) => ({
                label: t(label),
                value: getPercentageOrPlaceHolder(value),
              }))}
              isLoading={isLoading}
              isError={isError}
              showReferenceDate={false}
              isKpisCard={true}
            />
          </Card>
        ),
      )}
    </Grid>
  )
}

export default ArrearsKpiCards
