import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/sponsor/AsSponsorCardCommitmentField.module.css'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'

const AsSponsorCardCommitmentField = ({ commitment, commitmentHeadquarter }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.sponsor.overview',
  })

  const formatMoney = useCustomizableCurrencyFormatter()

  return (
    <FlexBox direction={FlexBoxDirection.Row} justifyContent={FlexBoxJustifyContent.SpaceBetween}>
      <Label>{t('sum-total-deal-commitments')}</Label>
      <FlexBox direction={FlexBoxDirection.Column}>
        <div className={`${styles.commitmentField}`}>
          {!isNil(commitment)
            ? formatMoney(commitment?.number, commitment?.currencyCode, {
                currencyDisplay: 'code',
              })
            : '-'}
        </div>
        {!isNil(commitmentHeadquarter) &&
          commitment?.currencyCode !== commitmentHeadquarter?.currencyCode && (
            <div className={`${styles.convertedAmount} ${styles.commitmentField}`}>
              {formatMoney(commitmentHeadquarter?.number, commitmentHeadquarter?.currencyCode, {
                currencyDisplay: 'code',
              })}
            </div>
          )}
      </FlexBox>
    </FlexBox>
  )
}

AsSponsorCardCommitmentField.propTypes = {
  commitment: propTypes.shape({
    number: propTypes.number,
    currencyCode: propTypes.string,
  }),
  commitmentHeadquarter: propTypes.shape({
    number: propTypes.number,
    currencyCode: propTypes.string,
  }),
}

export default AsSponsorCardCommitmentField
