import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ByProductClassCard from 'components/domains/business-partners/exposure/ByProductClassCard'
import RiskFigurePropTypes from 'components/domains/business-partners/exposure/RiskFigurePropTypes'
import riskFigureTypes from 'components/domains/business-partners/exposure/riskFigureTypes'
import CreditAuthorityLimitOverview from 'components/domains/business-partners/tile/exposure/CreditAuthorityLimitOverview'
import GBAReportingTile from 'components/domains/business-partners/tile/exposure/GBAReportingTile'
import RegulatoryExposureTile from 'components/domains/business-partners/tile/exposure/RegulatoryExposureTile'
import SettlementRiskOverview from 'components/domains/business-partners/tile/exposure/SettlementRiskOverview'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Section from 'components/ui/page/Section'
import useAuthorityLevelCalculator from 'hooks/services/business-partners/authority-level-calculator/useAuthorityLevelCalculator'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

const ExposureOverview = ({
  riskFigures = {},
  riskFigureDisplayNamesMap = {},
  assetClasses = {},
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner.group-exposures' })
  const { id: businessPartnerId } = useContext(BusinessPartnerContext)

  const {
    isLoading,
    isError,
    error,
    data: { totalValues } = {},
  } = useAuthorityLevelCalculator(businessPartnerId, businessPartnerId, 'StandaloneBpUnit')

  return (
    <div>
      <Section titleText={t('overview')}>
        <CWPLayout>
          <CreditAuthorityLimitOverview
            isLoading={isLoading}
            isError={isError}
            errorStatus={error?.response?.status}
            creditAuthorityLimitContribution={{
              value: totalValues?.totalCalculatedValues?.creditAuthorityLimitExisting?.amount,
              unit: totalValues?.totalCalculatedValues?.creditAuthorityLimitExisting?.currency,
              label: t('single-bp.contribution-to-credit-authority-limit-existing'),
            }}
            pipelineExposure={{
              value:
                totalValues?.totalCalculatedValues?.creditAuthorityLimitPipelineLending?.amount,
              unit: totalValues?.totalCalculatedValues?.creditAuthorityLimitPipelineLending
                ?.currency,
              label: t('single-bp.contribution-to-credit-authority-limit-pipeline-lending'),
            }}
            totalLabel={t('single-bp.total-label')}
          />
          <RegulatoryExposureTile
            exposureAtDefault={{
              ...riskFigures[riskFigureTypes.exposureAtDefault],
              label: riskFigureDisplayNamesMap[riskFigureTypes.exposureAtDefault],
            }}
            thresholdForExposure10={{
              ...riskFigures[riskFigureTypes.thresholdForLargeExposure],
              label: riskFigureDisplayNamesMap[riskFigureTypes.thresholdForLargeExposure],
            }}
            exposureBeforeCreditRiskMitigation={{
              ...riskFigures[riskFigureTypes.exposureBeforeCreditRiskMitigation],
              label: riskFigureDisplayNamesMap[riskFigureTypes.exposureBeforeCreditRiskMitigation],
            }}
            maxLimitForExposure25={{
              ...riskFigures[riskFigureTypes.maxLimitForExposure25],
              label: riskFigureDisplayNamesMap[riskFigureTypes.maxLimitForExposure25],
            }}
            exposureAfterCreditRiskMitigation={{
              ...riskFigures[riskFigureTypes.exposureAfterCreditRiskMitigation],
              label: riskFigureDisplayNamesMap[riskFigureTypes.exposureAfterCreditRiskMitigation],
            }}
          />
          <SettlementRiskOverview
            settlementRisk={{
              ...riskFigures[riskFigureTypes.settlementRiskLimit],
              label: riskFigureDisplayNamesMap[riskFigureTypes.settlementRiskLimit],
            }}
            settlementRiskLimit={{
              ...riskFigures[riskFigureTypes.settlementRiskLimitValue],
              label: riskFigureDisplayNamesMap[riskFigureTypes.settlementRiskLimitValue],
            }}
          />
          <GBAReportingTile />
        </CWPLayout>
      </Section>
      <Section titleText={t('by-product-class')}>
        <ByProductClassCard
          riskFigureDisplayNamesMap={riskFigureDisplayNamesMap}
          riskFigures={riskFigures}
          assetClasses={assetClasses}
        />
      </Section>
    </div>
  )
}

ExposureOverview.propTypes = {
  riskFigures: RiskFigurePropTypes.RiskFigures,
  riskFigureDisplayNamesMap: RiskFigurePropTypes.RiskFiguresDisplay,
  assetClasses: RiskFigurePropTypes.AssetClasses,
}

export default ExposureOverview
