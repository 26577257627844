import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const processExcelToTableMappingToJson = (excelToTableMapping, rowNumberHeader) => {
  const mappings = []
  excelToTableMapping.forEach((excelMapping, columnDefKey, _map) => {
    mappings.push({ column_key: columnDefKey, column_title_excel: excelMapping['excelColContent'] })
  })
  return { mappings: mappings, row_number_header: rowNumberHeader }
}
const useCreateOrUpdateRentRollWorkingVersionColumnMapping = ({ onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()

  return useMutation(
    async ({ propertyUuids, columnMapping, rowNumberHeader }) => {
      const processedMapping = processExcelToTableMappingToJson(columnMapping, rowNumberHeader)
      const { data } = await put({
        path: `/properties/rent-roll/working-version/column-mapping`,
        body: {
          property_uuids: propertyUuids,
          rent_roll_working_version_column_mapping_dto: { ...processedMapping },
        },
      })
      return data
    },
    { onSuccess, onError },
  )
}

export default useCreateOrUpdateRentRollWorkingVersionColumnMapping
