import { Icon, Input, Text } from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const ConditionSelectionInput = ({ name, onClick, onClear, hasError, disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.columns.condition',
  })

  const nameOrFallback = useMemo(() => {
    if (name) {
      return name
    }
    return t('fallback')
  }, [t, name])

  const showClearIcon = useMemo(() => !!name, [name])

  const onInput = useCallback(
    ({ target: { value: newInput } }) => {
      if (newInput === '') {
        onClear()
      }
    },
    [onClear],
  )

  const onKeyDown = useCallback((event) => {
    event.stopPropagation()
    event.preventDefault()
  }, [])

  return (
    <Input
      value={nameOrFallback}
      showClearIcon={showClearIcon}
      onInput={onInput}
      onKeyDown={onKeyDown}
      icon={<Icon name="value-help" onClick={onClick} />}
      valueState={hasError ? ValueState.Error : ValueState.None}
      valueStateMessage={<Text wrapping>{t('error')}</Text>}
      disabled={disabled}
    />
  )
}

ConditionSelectionInput.propTypes = {
  name: PropTypes.string,
  hasError: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default ConditionSelectionInput
