import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Input,
  Label,
  Link,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import DealLink from 'components/domains/business-events-and-tasks/DealLink'
import { isFinancialProduct } from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/ArrearsUtil'
import {
  ARREARS_IN_APPROVAL_ARREARS_HEADQUARTER_CURRENCY,
  ARREARS_IN_APPROVAL_ENTITY_TYPES,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/constants'
import AnalyticalTableCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/shared/analytical/ArrearsInApprovalAnalyticalTableCell'
import { NO_DEAL } from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/shared/constants'
import LinkWithId from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/shared/domains/business-events-and-tasks/LinkWithId'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/shared/domains/business-partners/tile/arrears/current-approvals/ArrearApprovalTableCells.module.css'
import { WorkingVersionType } from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-in-approval/shared/domains/deals/WorkingVersionType'
import { TILE_VERSION as tileVersion } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'
import paths from 'routes/paths'

const SPACE = <span>&nbsp;</span>

const LabelWithEntityIdLink = ({ label, entityId, entityLink }) => (
  <FlexBox className={styles.noMarginTopAndFullHeight} alignItems={FlexBoxAlignItems.Center}>
    <Label showColon>{label}</Label>
    {SPACE}
    <Link href={entityLink} target={'_blank'}>
      {entityId}
    </Link>
  </FlexBox>
)
LabelWithEntityIdLink.propTypes = {
  label: PropTypes.string,
  entityId: PropTypes.string,
  entityLink: PropTypes.string,
}

const LabelWithEntityIdInput = ({
  label,
  entityId,
  className = styles.noMarginTopAndFullHeight,
}) => (
  <FlexBox className={className} alignItems={FlexBoxAlignItems.Center}>
    <Label showColon>{label}</Label>
    {SPACE}
    <Input readonly value={entityId} />
  </FlexBox>
)

LabelWithEntityIdInput.propTypes = {
  label: PropTypes.string,
  entityId: PropTypes.string,
  className: PropTypes.string,
}

const BoldLabel = ({ label }) => (
  <FlexBox className={styles.noMarginTopAndFullHeight} alignItems={FlexBoxAlignItems.Center}>
    <Label style={{ fontWeight: 'bold' }}>{label}</Label>
  </FlexBox>
)
BoldLabel.propTypes = {
  label: PropTypes.string,
}

const getNonLoanLink = (businessPartnerId, dealId, dealDisplayId) =>
  dealId === NO_DEAL
    ? `/business-partners/${businessPartnerId}/financial-product-overview`
    : `/deals/${dealDisplayId}/financing?dataSource=existingBusiness`

const getBankCustomerAccountLink = (businessPartnerId, dealId, dealDisplayId, entityId) =>
  dealId === NO_DEAL
    ? `/business-partners/${businessPartnerId}/financial-product-overview`
    : `/deals/${dealDisplayId}/financing/bank-customer-accounts/${entityId}`

const getProductLinkPath = (businessPartnerId, dealId, dealDisplayId, entityType, entityId) => {
  if (entityType === ARREARS_IN_APPROVAL_ENTITY_TYPES.TRANCHE) {
    return `/deals/${dealDisplayId}/financing/tranches/${entityId}/details?dataSource=existingBusiness`
  } else if (entityType === ARREARS_IN_APPROVAL_ENTITY_TYPES.DRAWING) {
    return `/deals/${dealDisplayId}/financing/drawdowns/${entityId}/details?dataSource=existingBusiness`
  } else if (entityType === ARREARS_IN_APPROVAL_ENTITY_TYPES.NON_LOAN) {
    return getNonLoanLink(businessPartnerId, dealId, dealDisplayId)
  } else if (entityType === ARREARS_IN_APPROVAL_ENTITY_TYPES.BCA) {
    return getBankCustomerAccountLink(businessPartnerId, dealId, dealDisplayId, entityId)
  }
}

const ArrearCellContent = ({ businessPartnerId, entityId, entityType, dealId, dealDisplayId }) => {
  const { t: entityTypeTranslate } = useTranslation('decisionPaper', {
    keyPrefix:
      'tiles.arrears-in-approval.pages.business-partner.arrears.current-arrears.table.entity-types',
  })
  const linkPath = getProductLinkPath(
    businessPartnerId,
    dealId,
    dealDisplayId,
    entityType,
    entityId,
  )
  return (
    <LabelWithEntityIdLink
      label={entityTypeTranslate(entityType.toLowerCase().replaceAll('_', '-'))}
      entityLink={linkPath}
      entityId={entityId}
    />
  )
}

ArrearCellContent.propTypes = {
  businessPartnerId: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  dealId: PropTypes.string,
  dealDisplayId: PropTypes.string,
}

export const ArrearsInApprovalArrearCell = (
  <AnalyticalTableCell>
    {(
      _tableData,
      { tileVersion: providedTileVersion },
      {
        cell: {
          row: { original: row },
        },
      },
    ) => {
      const { t: entityTypeTranslate } = useTranslation('decisionPaper', {
        keyPrefix:
          'decision-paper.arrears-in-approval.pages.business-partner.arrears.current-arrears.table.entity-types',
      })
      const { t: t } = useTranslation('decisionPaper', {
        keyPrefix: 'components.business-events-and-tasks.business-object-link',
      })

      const entityId = row.entityId
      const secondaryEntityId = row.secondaryEntityId
      const entityType = row.entityType
      const businessPartnerId = row.businessPartnerId
      const dealId = row.dealId
      const deal = row.deal
      const dealDisplayId = row.rowData?.product?.dealDisplayId

      const renderDeal = useCallback(
        (dealToRender) => {
          if (entityId === NO_DEAL) {
            return <BoldLabel label={entityTypeTranslate('no-deal')} />
          }
          if (providedTileVersion === tileVersion.V1) {
            return <DealLink dealUuid={entityId} customRender={'SINGLE_LINE'} />
          }

          if (!dealToRender) {
            return <></>
          }

          const isWorkingVersion =
            dealToRender.workingVersion === WorkingVersionType.WORKING_VERSION
          const baseLink = `/${paths.deals}/${dealToRender.dealId}`
          const link = isWorkingVersion ? `${baseLink}?working-version=true` : baseLink
          const name = isWorkingVersion
            ? t('deal.working-version', { dealName: dealToRender.name })
            : dealToRender.name

          return <LinkWithId href={link} name={name} id={dealToRender.dealId} />
        },
        [entityId, entityTypeTranslate, providedTileVersion, t],
      )

      const getNonLoanEntityId = useCallback(() => {
        if (providedTileVersion === tileVersion.V1) {
          return entityId
        } else {
          return secondaryEntityId ?? entityId
        }
      }, [entityId, providedTileVersion, secondaryEntityId])

      switch (entityType) {
        case ARREARS_IN_APPROVAL_ENTITY_TYPES.DEAL:
          return renderDeal(deal)
        case ARREARS_IN_APPROVAL_ENTITY_TYPES.TRANCHE:
          return (
            <ArrearCellContent
              entityId={entityId}
              entityType={ARREARS_IN_APPROVAL_ENTITY_TYPES.TRANCHE}
              dealId={dealId}
              dealDisplayId={dealDisplayId}
              businessPartnerId={businessPartnerId}
            />
          )
        case ARREARS_IN_APPROVAL_ENTITY_TYPES.DRAWING:
          return (
            <ArrearCellContent
              entityId={entityId}
              entityType={ARREARS_IN_APPROVAL_ENTITY_TYPES.DRAWING}
              dealId={dealId}
              dealDisplayId={dealDisplayId}
              businessPartnerId={businessPartnerId}
            />
          )
        case ARREARS_IN_APPROVAL_ENTITY_TYPES.NON_LOAN:
          return (
            <ArrearCellContent
              entityId={getNonLoanEntityId()}
              entityType={ARREARS_IN_APPROVAL_ENTITY_TYPES.NON_LOAN}
              dealId={dealId}
              dealDisplayId={dealDisplayId}
              businessPartnerId={businessPartnerId}
            />
          )
        case ARREARS_IN_APPROVAL_ENTITY_TYPES.BCA:
          return (
            <ArrearCellContent
              entityId={secondaryEntityId}
              entityType={ARREARS_IN_APPROVAL_ENTITY_TYPES.BCA}
              dealId={dealId}
              dealDisplayId={dealDisplayId}
              businessPartnerId={businessPartnerId}
            />
          )
        case ARREARS_IN_APPROVAL_ENTITY_TYPES.APPROVALITEM:
          return (
            <FlexBox
              className={styles.noMarginTopAndFullHeight}
              alignItems={FlexBoxAlignItems.Center}
            >
              {row.rowData.approvalItem.arrearType.name}
            </FlexBox>
          )
      }
    }}
  </AnalyticalTableCell>
)

const MoneyCell = ({ value, foreignValue, foreignCurrencies, formatCurrency, isSectionHeader }) => {
  const displayForeignCurrency =
    !isNil(foreignValue) &&
    foreignCurrencies.length === 1 &&
    foreignCurrencies[0] !== ARREARS_IN_APPROVAL_ARREARS_HEADQUARTER_CURRENCY
  return (
    <FlexBox
      className={styles.noMarginTopAndFullHeight}
      direction={FlexBoxDirection.Column}
      justifyContent={FlexBoxJustifyContent.Center}
    >
      {!isNil(value) && (
        <span
          className={
            isSectionHeader
              ? `${styles.textAlignRight} sapFontBoldFamily`
              : `${styles.textAlignRight}`
          }
        >
          {formatCurrency(value, ARREARS_IN_APPROVAL_ARREARS_HEADQUARTER_CURRENCY)}
        </span>
      )}
      {displayForeignCurrency && (
        <span
          className={
            isSectionHeader
              ? `${styles.textAlignRight} sapFontBoldFamily sapNeutralColor ${styles.tinyMarginTop}`
              : `${styles.textAlignRight} sapNeutralColor ${styles.tinyMarginTop}`
          }
        >
          {formatCurrency(foreignValue, foreignCurrencies[0])}
        </span>
      )}
    </FlexBox>
  )
}
MoneyCell.propTypes = {
  value: PropTypes.number,
  formatCurrency: PropTypes.func,
  isSectionHeader: PropTypes.bool,
  foreignValue: PropTypes.number,
  foreignCurrencies: PropTypes.arrayOf(PropTypes.string),
}

export const ArrearsInApprovalApprovalMoneyCell = (
  <AnalyticalTableCell alignRight>
    {(
      _tableData,
      { formatCurrency },
      {
        cell: {
          row: { original: row },
        },
      },
    ) => (
      <MoneyCell
        isSectionHeader={row.entityType === ARREARS_IN_APPROVAL_ENTITY_TYPES.DEAL}
        value={row.sumData.approvedAmount}
        foreignValue={row.sumData.foreignApprovalAmount}
        foreignCurrencies={row.sumData.foreignCurrencies}
        formatCurrency={formatCurrency}
      />
    )}
  </AnalyticalTableCell>
)

export const ArrearsInApprovalApprovalApprovedUntilDateCell = (
  <AnalyticalTableCell alignRight>
    {(
      _tableData,
      { formatDate },
      {
        cell: {
          row: { original: row },
        },
      },
    ) => {
      const approvedUntil = row.rowData.approvedUntil

      return (
        isFinancialProduct(row.entityType) && (
          <FlexBox
            className={styles.noMarginTopAndFullHeight}
            direction={FlexBoxDirection.Column}
            justifyContent={FlexBoxJustifyContent.Center}
          >
            {approvedUntil && (
              <Text className={styles.textAlignRight}>{formatDate(approvedUntil)}</Text>
            )}
          </FlexBox>
        )
      )
    }}
  </AnalyticalTableCell>
)

export const ArrearsInApprovalArrearClassificationCell = (
  <AnalyticalTableCell>
    {(
      _tableData,
      { _ },
      {
        cell: {
          row: { original: row },
        },
      },
    ) =>
      isFinancialProduct(row.entityType) && (
        <FlexBox className={styles.noMarginTopAndFullHeight} alignItems={FlexBoxAlignItems.Center}>
          {row.rowData.classificationName ?? ''}
        </FlexBox>
      )
    }
  </AnalyticalTableCell>
)

export const ArrearsInApprovalApprovalCommentCell = (
  <AnalyticalTableCell>
    {(
      _tableData,
      { _ },
      {
        cell: {
          row: { original: row },
        },
      },
    ) =>
      isFinancialProduct(row.entityType) && (
        <FlexBox className={styles.noMarginTopAndFullHeight} alignItems={FlexBoxAlignItems.Center}>
          {row.rowData.comment}
        </FlexBox>
      )
    }
  </AnalyticalTableCell>
)
