import { FlexBoxAlignItems } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'

const useNonFinancialColumns = ({ isEditMode = false }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.limit-card',
  })

  const columnDefinitions = [
    {
      columnKey: 'covenantHoliday',
      title: isEditMode ? t('covenant-holiday') : '',
    },
    {
      columnKey: 'validFrom',
      title: t('valid-from'),
      alignment: FlexBoxAlignItems.End,
    },
    {
      columnKey: 'validTo',
      title: t('valid-to'),
      alignment: FlexBoxAlignItems.End,
    },
  ]

  return { columnDefinitions }
}

export default useNonFinancialColumns
