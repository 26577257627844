const mapRatingSalesProspects = (data) => {
  if (!data?.salesProspects) return []
  return [
    {
      isSectionHeader: true,
      rootRowId: 'salesProspects',
      criteriaClass: 'salesProspects',
      rating: data.salesProspects,
    },
  ]
}

export default mapRatingSalesProspects
