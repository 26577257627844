import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useEditDecisionPaperSubsectionComment = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()
  return useMutation(async ({ eventId, version, subsectionId, commentId, comment }) => {
    const { data } = await patch({
      path: `/events/${eventId}/decision-paper/versions/${version}/subsections/${subsectionId}/comments/${commentId}`,
      body: {
        comment,
      },
    })
    return data
  }, mutationOptions)
}

export default useEditDecisionPaperSubsectionComment
