import { Text } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import useDeprecatedValues from 'hooks/common/useDeprecatedValues'
import useApprovalLevels from 'hooks/services/conditions/config/useApprovalLevels'
import { conditionApprovalLevelChanged } from 'redux/slices/conditions/conditionsTableSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const ConditionsTableInternalApprovalLevelEditCell = ({ approvalLevelName, approvalLevelCode }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.columns.approval-level',
  })
  const dispatch = useDispatch()
  const {
    entityRef: { entityType },
  } = useContext(ConditionsContext)
  const editedConditionApprovalLevel = useSelector(
    (state) => state.conditions.conditionsTable.editedRow.currentValues?.approvalLevel,
  )

  const { data: { approvalLevels: availableApprovalLevels = [] } = {}, ...useAprovalLevelsResult } =
    useApprovalLevels({
      includeDeprecated: false,
      entityType,
    })
  const { deprecatedValues } = useDeprecatedValues({
    availableValues: availableApprovalLevels,
    selectedValues: isNil(approvalLevelCode)
      ? []
      : [{ name: approvalLevelName, code: approvalLevelCode }],
  })

  const approvalLevelsData = useMemo(
    () => ({
      ...useAprovalLevelsResult,
      data: {
        approvalLevels: [...deprecatedValues, ...availableApprovalLevels],
      },
    }),
    [availableApprovalLevels, deprecatedValues, useAprovalLevelsResult],
  )

  const approvalLevelsLoader = useCallback(() => approvalLevelsData, [approvalLevelsData])

  const hasError = useSelector(
    (state) => !!state.conditions.conditionsTable.editedRow.errorFields?.approvalLevel,
  )

  const onChange = useCallback(
    (_, newCode) => {
      dispatch(conditionApprovalLevelChanged({ approvalLevel: newCode }))
    },
    [dispatch],
  )

  const errorValueStateMessage = useMemo(() => <Text>{t('error')}</Text>, [t])

  return (
    <LoadingSelect
      emptyOptionText={t('fallback')}
      selectedKey={editedConditionApprovalLevel}
      loadingHook={approvalLevelsLoader}
      isErrorState={hasError}
      valueStateMessage={errorValueStateMessage}
      onChange={onChange}
      showEmptyOption
      optionKeyName="code"
      optionDisplayName="name"
      selectionName="approvalLevels"
    />
  )
}

ConditionsTableInternalApprovalLevelEditCell.propTypes = {
  approvalLevelCode: PropTypes.string,
  approvalLevelName: PropTypes.string,
}

export default ConditionsTableInternalApprovalLevelEditCell
