import { DateTime } from 'luxon'

export const isDateValid = (date) => {
  // DateTime.fromISO accepts multiple ISO-formats e.g. 2020-01
  const dateRegex = /^\d{4}-\d{2}-\d{2}/
  if (!dateRegex.test(date)) return false

  const parsedNewDeliveryDate = DateTime.fromISO(date)
  return parsedNewDeliveryDate.isValid
}

const useValidateNextPeriodicalCheck = ({
  monitoringItem,
  covenantDefinition: { withDocumentsRequest } = {},
}) => {
  const isKeyDateValid = isDateValid(monitoringItem?.dueDate?.keyDate)
  const isTargetDeliveryDateValid = withDocumentsRequest
    ? isDateValid(monitoringItem?.dueDate?.targetDeliveryDate)
    : true
  const isCheckDateValid = isDateValid(monitoringItem?.dueDate?.checkDate)

  return isKeyDateValid && isTargetDeliveryDateValid && isCheckDateValid
}

export default useValidateNextPeriodicalCheck
