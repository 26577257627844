import { RadioButton, TextAlign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DocumentDownload from 'components/domains/documents/DocumentDownload'
import DocumentVersionDelete from 'components/domains/documents/DocumentVersionDelete'
import { DocumentIconCell } from 'components/domains/documents/documents-table/DocumentIconCell'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useResolveNameFromEmail from 'routes/deals/syndication/syndicationIntention/utils/useResolveNameFromEmail'

const DownloadCell = ({ cell }) => {
  const versionGuid = cell.row?.original?.guid
  const { documentId, fileName, version, documentName } = cell.row?.original ?? {}

  if (!documentId || !versionGuid) return <div />
  return (
    <DocumentDownload
      type="icon"
      documentId={documentId}
      documentVersionGuid={versionGuid}
      fileName={fileName}
      documentName={documentName}
      version={version}
    />
  )
}

DownloadCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        documentId: PropTypes.string,
        guid: PropTypes.string,
        fileName: PropTypes.string,
        version: PropTypes.string,
        documentName: PropTypes.string,
      }),
    }),
  }),
}

const DeleteCell = ({ cell }) => {
  const documentId = cell.row?.original?.documentId
  const versionGuid = cell.row?.original?.guid
  const isActiveVersion = cell.row?.original?.active
  if (isActiveVersion || !documentId || !versionGuid) return <div />
  return <DocumentVersionDelete documentId={documentId} documentVersionGuid={versionGuid} />
}

DeleteCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        documentId: PropTypes.string,
        guid: PropTypes.string,
        active: PropTypes.bool,
      }),
    }),
  }),
}

const RadioButtonCell = ({ cell }) => <RadioButton checked={cell.value} readonly />

RadioButtonCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.bool,
  }),
}

const FileSizeCell = ({ cell }) => {
  const fileSizeFormatter = Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'byte',
    unitDisplay: 'narrow',
    notation: 'compact',
  })
  return <span>{fileSizeFormatter.format(cell.value)}</span>
}

FileSizeCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.number,
  }),
}

const DateCell = ({ cell: { value } }) => {
  const { format: formatDate } = useShortDateFormatter()
  return formatDate(value)
}
DateCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
  }),
}

const EditorCell = ({ cell }) => {
  const resolvedName = useResolveNameFromEmail(cell.value)
  return resolvedName
}
EditorCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
  }),
}

export const useDocumentVersionsColumns = ({ canDelete = false } = {}) => {
  const { t } = useTranslation('', { keyPrefix: 'pages.documents.detail.versions-table.columns' })

  let columns = [
    {
      Header: t('active'),
      accessor: 'active',
      hAlign: TextAlign.Center,
      Cell: RadioButtonCell,
    },
    {
      Header: t('version'),
      accessor: 'version',
      hAlign: TextAlign.Left,
    },
    {
      Header: t('version-date'),
      accessor: 'versionDate',
      hAlign: TextAlign.Right,
      Cell: DateCell,
    },
    {
      Header: t('format'),
      accessor: 'format',
      hAlign: TextAlign.Center,
      Cell: DocumentIconCell,
    },
    {
      Header: t('size'),
      accessor: 'size',
      hAlign: TextAlign.Right,
      Cell: FileSizeCell,
    },
    {
      Header: t('editor'),
      accessor: 'editor',
      hAlign: TextAlign.Left,
      Cell: EditorCell,
    },
    {
      Header: t('comment'),
      accessor: 'comment',
      hAlign: TextAlign.Left,
    },
    {
      accessor: 'download',
      hAlign: TextAlign.Center,
      maxWidth: 46,
      width: 46,
      Cell: DownloadCell,
    },
    { accessor: 'documentId', hide: true },
  ]
  if (canDelete) {
    columns = [
      ...columns,
      {
        accessor: 'delete',
        hAlign: TextAlign.Center,
        maxWidth: 46,
        width: 46,
        Cell: DeleteCell,
      },
    ]
  }

  return columns
}
