import { FlexBox, FlexBoxDirection, FlexBoxJustifyContent } from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { ErrorDataUnavailableInCell } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/errors/ErrorDataUnavailableInCell'
import SmallLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/loading/SmallLoadingWrapper'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/ActionsCell.module.css'

const propTypes = {
  /**
   * An array of actions (e.g. buttons, links) to display in the cell.
   *
   * Recommended to use the ButtonCell for array entries.
   * */
  actions: PropTypes.arrayOf(PropTypes.node),
  /** Defines whether a loading spinner is shown. */
  isLoading: PropTypes.bool,
  /** Defines whether a loading spinner is shown. */
  isFetching: PropTypes.bool,
  /** Defines whether an error is shown. */
  isError: PropTypes.bool,
  /** Allows to define a custom error component. Defaults to undefined so that `<ErrorDataUnavailableInCell />` is shown. */
  errorToDisplay: PropTypes.node,
  /** Defines additional styling to the outmost div of the cell's content. */
  className: PropTypes.string,
}

/**
 * Shared Actions Cell for usage within the Responsive and the Analytical Table.
 *
 * The actions themselves, most often buttons, can be created using the ButtonCell component.
 *
 * @typedef Props
 * @property {Array.<React.ReactNode>} [actions] An array of actions (e.g. buttons, links) to display in the cell.
 * @property {Boolean} [isLoading] Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} [isFetching] Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} [isError] Defines whether an error is shown.
 * @property {React.ReactNode} [errorToDisplay] Allows to define a custom error component. Defaults to undefined so that <ErrorDataUnavailableInCell /> is shown.
 * @property {String} [className] Defines additional styling to the outmost div of the cell's content.
 */

/**
 * @param {Props} props Note for Storybook: cf. code for complete JSDoc
 */
const ActionsCell = ({
  actions = [],
  isLoading = false,
  isFetching = false,
  isError = false,
  className,
  errorToDisplay,
}) => {
  const renderContent = () => {
    if (isEmpty(actions)) {
      return <div />
    }

    return (
      <FlexBox
        direction={FlexBoxDirection.Row}
        justifyContent={FlexBoxJustifyContent.End}
        className={compact([styles.actionsWrapper, className]).join(' ')}
      >
        {actions.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </FlexBox>
    )
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading || isFetching}
      isError={isError}
      error={errorToDisplay ?? <ErrorDataUnavailableInCell />}
      renderContent={renderContent}
    />
  )
}

ActionsCell.propTypes = propTypes

export default ActionsCell
