import { Title } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/pdf-export/DecisionPaperPdfExportSectionHeader.module.css'

const DecisionPaperPdfExportSectionHeader = ({ titleText }) => (
  <Title className={styles.headerText} role="heading" part="title">
    {titleText}
  </Title>
)

DecisionPaperPdfExportSectionHeader.propTypes = {
  titleText: PropTypes.string.isRequired,
}

export default DecisionPaperPdfExportSectionHeader
