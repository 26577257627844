import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useCovenantComments = ({ linkedEntityType, linkedEntityId }) => {
  const queryParams = new URLSearchParams()

  queryParams.append('linked_entity_type', linkedEntityType)
  queryParams.append('linked_entity_id', linkedEntityId)

  return useCamelizedResponse(
    useRequest({
      path: `/covenants/comments?${queryParams.toString()}`,
      keys: ['covenants', 'comments', linkedEntityType, linkedEntityId],
      options: { enabled: !!linkedEntityType && !!linkedEntityId },
    }),
  )
}

export default useCovenantComments
