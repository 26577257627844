import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  STATUS_TYPE_CODES,
  riskIndicatorStatusMapping,
} from 'components/domains/business-partners/tile/risk-monitoring/utils/typeCodes'
import useRiskIndicators from 'hooks/services/risk-monitoring/useRiskIndicators'

/**
 * @param {string} businessPartnerId
 */
const useBusinessPartnerRiskMonitoringStatus = (businessPartnerId) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner.risk-monitoring' })
  const { data, isLoading, isError, isFetching } = useRiskIndicators({
    type: 'MONITORED',
    businessPartnerId,
  })
  const openGroups =
    data?.riskIndicatorGroups?.filter(
      (group) =>
        group.statusTypeCode === STATUS_TYPE_CODES.OPEN ||
        group.statusTypeCode === STATUS_TYPE_CODES.ACTIVE,
    ) ?? []

  const criticalityMap = riskIndicatorStatusMapping.CRITICALITY

  const getClosestTypeCode = useCallback(
    (currentTypeCode) => {
      // Type codes also need to be covered in between the static values defined in the FE.
      // So if a cell type code 70 is given, and the FE only has 60 and 80 defined, the lower local boundary of 60 is returned.
      // Therefore, the type code object are transformed into an array and sorted by code value.
      const typeCodeArray = Object.values(criticalityMap ?? {})
      typeCodeArray.sort((a, b) => Number(a?.order) - Number(b?.order))

      // Then, the type code that is the next highest to the current cell type code is selected,
      // where, in case of the current cell type code already being the largest type code defined, it is its own upper boundary.
      let upperTypeCodeBoundaryIndex = typeCodeArray.findIndex(
        (status) => Number(status?.order) > Number(currentTypeCode),
      )
      if (!!currentTypeCode && upperTypeCodeBoundaryIndex === -1) {
        upperTypeCodeBoundaryIndex = typeCodeArray.length
      }

      // Finally, either the type code from the BE matches the FE defined one exactly,
      // or the one directly before the upper local boundary is returned,
      // or the lowest boundary is returned, if the upper local boundary equals the lowest absolute boundary.
      return (
        typeCodeArray.find((status) => status?.order === currentTypeCode) ||
        typeCodeArray?.[upperTypeCodeBoundaryIndex - 1] ||
        typeCodeArray?.[0]
      )
    },
    [criticalityMap],
  )

  const mostCriticalGroup = openGroups.length
    ? openGroups.reduce((acc, group) => {
        const currentOrder = group.currentCriticality?.criticalityTypeCode
          ? getClosestTypeCode(group.currentCriticality.criticalityTypeCode)?.order
          : 0
        const accOrder = acc.currentCriticality?.criticalityTypeCode
          ? getClosestTypeCode(acc.currentCriticality.criticalityTypeCode)?.order
          : 0

        if (currentOrder > accOrder) {
          return group
        }
        return acc
      })
    : undefined

  const resolvedTypeCode = getClosestTypeCode(
    mostCriticalGroup?.currentCriticality?.criticalityTypeCode,
  )?.order

  return {
    isLoading,
    isError,
    isFetching,
    data: {
      label: t('status'),
      text: mostCriticalGroup?.currentCriticality?.criticalityShortText ?? t('no-risk-monitoring'),
      valueState: criticalityMap[resolvedTypeCode]?.valueState,
    },
  }
}

export default useBusinessPartnerRiskMonitoringStatus
