import {
  ButtonDesign,
  DynamicPage,
  IllustratedMessage,
  IllustrationMessageSize,
  MessageBoxTypes,
  Modals,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionStageStatus } from 'api/decision-process/decisionProcessApi'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import { eventStatus, getObjectStatusForEventStatus } from 'api/events/status'
import BusinessObjectLink from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import DueDateWarningMessageStrip from 'components/domains/business-events-and-tasks/common/DueDateWarningMessageStrip'
import CommentSectionLoadingWrapper from 'components/domains/business-events-and-tasks/events/comment-section/CommentSectionLoadingWrapper'
import EventDetailTaskTable from 'components/domains/business-events-and-tasks/events/detail/EventDetailTaskTable'
import EventDescriptionTile from 'components/domains/business-events-and-tasks/events/tile/EventDescriptionTile'
import EventGeneralInformationTile from 'components/domains/business-events-and-tasks/events/tile/EventGeneralInformationTile'
import EventWatchersButton from 'components/domains/business-events-and-tasks/events/watchers/EventWatchersButton'
import LoadingButton from 'components/ui/button/LoadingButton'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'
import ErrorDialog from 'components/ui/dialog/ErrorDialog'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Header from 'components/ui/page/Header'
import useDecisionStages from 'hooks/services/business-events-and-tasks/decision-process/stages/useDecisionStages'
import { useEventComments } from 'hooks/services/business-events-and-tasks/events/comments/useEventComments'
import useUpdateEvent from 'hooks/services/business-events-and-tasks/events/useUpdateEvent'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import styles from 'routes/business-events-and-tasks/events/EventDetail.module.css'
import determineEventCompletionNotPossibleMessage from 'routes/business-events-and-tasks/events/determineEventCompletionNotPossibleMessage'
import paths from 'routes/paths'

const isDraftStatus = (status) => status === eventStatus.drafted
const isOngoingStatus = (status) => status === eventStatus.ongoing

const defaultLimit = 10

const CustomObjectReference = ({ entityType, id, name, hasError }) => {
  if (hasError) {
    return null
  }
  return <EntityTypeAndIdWithClipboard entityType={entityType} name={name} id={id} />
}
CustomObjectReference.propTypes = {
  name: PropTypes.string,
  entityType: PropTypes.string,
  id: PropTypes.string,
  hasError: PropTypes.bool,
}

const EventDetail = () => {
  const { event, allowedOperations } = useContext(BusinessEventsAndTasksContext)

  const {
    id: eventId,
    displayId: eventDisplayId,
    info: { name: eventName },
    entityRef: { entityId, entityType },
    info: {
      creationDate,
      originalDueDate,
      currentDueDate,
      assignee,
      closingDate,
      description,
      created: eventCreationAction,
    },
    status,
  } = event

  const { t } = useTranslation('translation', { keyPrefix: 'pages.event-detail' })
  const { t: tCommon } = useTranslation()
  const queryClient = useQueryClient()
  const showMessageBox = Modals.useShowMessageBox()

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
  const [newStatus, setNewStatus] = useState()
  const [confirmationMessageText, setConfirmationMessageText] = useState('')
  const [modalMessageText, setModalMessageText] = useState('')
  const [limit, setLimit] = useState(defaultLimit)
  const [hasOpenMandatoryTasks, setHasOpenMandatoryTasks] = useState(false)
  const { mutate: updateEvent, isLoading: isUpdateEventLoading } = useUpdateEvent()
  const {
    data: comments,
    isLoading: isLoadingComments,
    isError: isErrorComments,
  } = useEventComments(eventId, { limit, offset: 0 })
  const {
    isLoading: isLoadingDecisionStages,
    isError: isErrorDecisionStages,
    data: decisionStageData,
  } = useDecisionStages({ eventId })

  const hasOpenDecisionStage = useMemo(
    () =>
      !isLoadingDecisionStages &&
      !isErrorDecisionStages &&
      decisionStageData?.decisionStages.some(({ info: { decisionStatus } }) =>
        [decisionStageStatus.inProgress, decisionStageStatus.inProgressRequestDiscarded].includes(
          decisionStatus,
        ),
      ),
    [decisionStageData?.decisionStages, isErrorDecisionStages, isLoadingDecisionStages],
  )

  const eventReadAllowed = useMemo(
    () => allowedOperations?.includes(businessEventAllowedOperations.readEvent),
    [allowedOperations],
  )
  const taskReadAllowed = useMemo(
    () => allowedOperations?.includes(businessEventAllowedOperations.readTask),
    [allowedOperations],
  )
  const eventUpdateAllowed = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.updateEvent),
    [allowedOperations],
  )
  const eventAutomaticallyUpdateAllowed = useMemo(
    () =>
      allowedOperations.includes(businessEventAllowedOperations.automaticallyCreatedUpdateEvent),
    [allowedOperations],
  )
  const eventCancelAllowed = useMemo(
    () => allowedOperations?.includes(businessEventAllowedOperations.cancelEvent),
    [allowedOperations],
  )
  const eventCompleteAllowed = useMemo(
    () => allowedOperations?.includes(businessEventAllowedOperations.completeEvent),
    [allowedOperations],
  )
  const eventPublishAllowed = useMemo(
    () => allowedOperations?.includes(businessEventAllowedOperations.publishEvent),
    [allowedOperations],
  )

  const onLoadMoreComments = useCallback(() => {
    setLimit(limit + defaultLimit)
  }, [limit])

  const handleUpdateEvent = useCallback(() => {
    updateEvent(
      { eventId, status: newStatus },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['events'])
          Modals.showToast({ children: <Text>{modalMessageText}</Text> })
        },
        onError: () => {
          setIsErrorDialogOpen(true)
        },
      },
    )
  }, [eventId, modalMessageText, newStatus, queryClient, updateEvent])

  const onPublishEventButtonClicked = useCallback(() => {
    setNewStatus(eventStatus.ongoing)
    setConfirmationMessageText(t('publish.confirmation.description'))
    setModalMessageText(t('publish.success-message'))
    setIsConfirmationDialogOpen(true)
  }, [t])

  const onCompleteEventButtonClicked = useCallback(() => {
    if (hasOpenMandatoryTasks || hasOpenDecisionStage) {
      const message = determineEventCompletionNotPossibleMessage({
        hasOpenMandatoryTasks,
        hasOpenDecisionStage,
      })
      showMessageBox({
        type: MessageBoxTypes.Error,
        children: t(message),
      })
      return
    }
    setNewStatus(eventStatus.completed)
    setConfirmationMessageText(t('complete.confirmation.description'))
    setModalMessageText(t('complete.success-message'))
    setIsConfirmationDialogOpen(true)
  }, [hasOpenDecisionStage, hasOpenMandatoryTasks, showMessageBox, t])

  const onCancelEventButtonClicked = useCallback(() => {
    setNewStatus(eventStatus.aborted)
    setConfirmationMessageText(t('cancel.confirmation.description'))
    setModalMessageText(t('cancel.success-message'))
    setIsConfirmationDialogOpen(true)
  }, [t])

  const handleRetry = useCallback(() => {
    setIsErrorDialogOpen(false)
    handleUpdateEvent()
  }, [handleUpdateEvent])

  const handleConfirmation = useCallback(() => {
    setIsConfirmationDialogOpen(false)
    handleUpdateEvent()
  }, [handleUpdateEvent])

  const handleConfirmationCancel = useCallback(() => {
    setIsConfirmationDialogOpen(false)
  }, [])

  const watcherButton = useMemo(
    () => eventReadAllowed && <EventWatchersButton eventId={eventId} />,
    [eventId, eventReadAllowed],
  )

  const publishEventButton = useMemo(
    () =>
      eventPublishAllowed && (
        <LoadingButton
          key="publish-event"
          id="publish-event"
          design={ButtonDesign.Emphasized}
          onClick={onPublishEventButtonClicked}
          renderContent={() => t('publish.button')}
          isLoading={isUpdateEventLoading && newStatus === eventStatus.ongoing}
        />
      ),
    [eventPublishAllowed, isUpdateEventLoading, newStatus, onPublishEventButtonClicked, t],
  )

  const isEventInfoTileEditable = useMemo(() => {
    if (!eventUpdateAllowed) return false
    return status !== eventStatus.aborted && status !== eventStatus.completed
  }, [eventUpdateAllowed, status])

  const isEventDescriptionTileEditable = useMemo(() => {
    if (!eventUpdateAllowed) return false
    if (event.info.created === 'AUTOMATED' && !eventAutomaticallyUpdateAllowed) return false
    return status !== eventStatus.aborted && status !== eventStatus.completed
  }, [event.info.created, eventAutomaticallyUpdateAllowed, eventUpdateAllowed, status])

  const completeEventButton = useMemo(
    () =>
      eventCompleteAllowed &&
      eventReadAllowed && (
        <LoadingButton
          key="complete-event"
          id="complete-event"
          design={ButtonDesign.Emphasized}
          onClick={onCompleteEventButtonClicked}
          renderContent={() => t('complete.button')}
          isLoading={
            (isUpdateEventLoading && newStatus === eventStatus.completed) || isLoadingDecisionStages
          }
          isError={isErrorDecisionStages}
        />
      ),
    [
      eventCompleteAllowed,
      eventReadAllowed,
      isErrorDecisionStages,
      isLoadingDecisionStages,
      isUpdateEventLoading,
      newStatus,
      onCompleteEventButtonClicked,
      t,
    ],
  )

  const cancelEventButton = useMemo(
    () =>
      eventCancelAllowed &&
      eventReadAllowed && (
        <LoadingButton
          key="cancel-event"
          id="cancel-event"
          design={ButtonDesign.Negative}
          onClick={onCancelEventButtonClicked}
          renderContent={() => t('cancel.button')}
          isLoading={isUpdateEventLoading && newStatus === eventStatus.aborted}
        />
      ),
    [
      eventCancelAllowed,
      eventReadAllowed,
      isUpdateEventLoading,
      newStatus,
      onCancelEventButtonClicked,
      t,
    ],
  )

  const actionButtons = useMemo(
    () => (
      <>
        {isDraftStatus(status) && publishEventButton}
        {isOngoingStatus(status) && completeEventButton}
        {(isDraftStatus(status) || isOngoingStatus(status)) && cancelEventButton}
        {watcherButton}
      </>
    ),
    [cancelEventButton, completeEventButton, publishEventButton, status, watcherButton],
  )

  const breadcrumbs = [
    {
      text: t('navigate-back'),
      href: `/${paths.businessEventsAndTasks}`,
    },
    {
      text: eventName,
    },
  ]

  const { translationKey: statusTranslationKey, objectStatus } =
    getObjectStatusForEventStatus(status)

  const eventStatusForHeader = { text: tCommon(statusTranslationKey), valueState: objectStatus }

  const subtitle = (
    <BusinessObjectLink
      entityId={entityId}
      entityType={entityType}
      CustomComponent={CustomObjectReference}
    />
  )

  const headerTitle = (
    <Header
      title={eventName}
      subtitle={subtitle}
      actions={actionButtons}
      breadcrumbs={breadcrumbs}
      status={eventStatusForHeader}
      className={styles.pageHeader}
    />
  )

  const renderBody = () => {
    if (!eventReadAllowed) {
      return (
        <IllustratedMessage
          size={IllustrationMessageSize.Spot}
          name="UnableToLoad"
          titleText={tCommon('app.permission-error.title')}
          subtitleText={tCommon('app.permission-error.subtitle')}
        />
      )
    }
    return (
      <>
        <DueDateWarningMessageStrip
          dueDate={currentDueDate}
          className={styles['due-date-warning']}
          eventStatus={status}
        />
        <CWPLayout>
          <EventGeneralInformationTile
            disabled={isUpdateEventLoading}
            eventId={eventId}
            eventDisplayId={eventDisplayId}
            entityRef={{
              entityId,
              entityType,
            }}
            info={{
              creationDate: creationDate,
              closingDate: closingDate,
              currentDueDate: currentDueDate,
              originalDueDate: originalDueDate,
              assigneeId: assignee,
              status: status,
              eventCreationAction: eventCreationAction,
            }}
            showEditButton={isEventInfoTileEditable}
          />
          <EventDescriptionTile
            disabled={isUpdateEventLoading}
            eventId={eventId}
            description={description}
            showEditButton={isEventDescriptionTileEditable}
          />
          {taskReadAllowed && (
            <CWPLayout.Full>
              <EventDetailTaskTable
                disabled={isUpdateEventLoading}
                eventId={eventId}
                eventStatus={status}
                hideStatusColumn={isDraftStatus(status)}
                setHasOpenMandatoryTasks={setHasOpenMandatoryTasks}
              />
            </CWPLayout.Full>
          )}
          <CWPLayout.Full>
            <CommentSectionLoadingWrapper
              disabled={isUpdateEventLoading}
              eventId={eventId}
              comments={comments}
              onLoadMoreComments={onLoadMoreComments}
              isLoading={isLoadingComments}
              isError={isErrorComments}
            />
          </CWPLayout.Full>
        </CWPLayout>
        <ErrorDialog
          isOpen={isErrorDialogOpen}
          setIsOpen={setIsErrorDialogOpen}
          texts={{
            retryButton: t('error-dialog.button-try-again'),
            cancelButton: t('error-dialog.button-cancel'),
            title: t('error-dialog.title'),
            description: t('error-dialog.description'),
          }}
          onRetry={handleRetry}
        />
        <ConfirmationDialog
          isOpen={isConfirmationDialogOpen}
          setIsOpen={setIsConfirmationDialogOpen}
          handleConfirmation={handleConfirmation}
          handleCancel={handleConfirmationCancel}
          confirmationMessage={confirmationMessageText}
          confirmationTitle={t('confirm.text')}
          confirmButtonText={t('confirm.text')}
        />
      </>
    )
  }
  return (
    <DynamicPage
      id="event-detail-dynamic-page"
      showHideHeaderButton={false}
      headerContentPinnable={false}
      headerTitle={headerTitle}
    >
      {renderBody()}
    </DynamicPage>
  )
}

export default EventDetail
