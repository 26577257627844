import {
  CustomListItem,
  FlexBox,
  FlexBoxJustifyContent,
  Label,
  ListItemType,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListEditItem.module.css'

const DecisionPaperTileListEditItem = ({ label, node, isFirstItem }) => (
  <CustomListItem type={ListItemType.Inactive} className={styles.customListItem}>
    <FlexBox
      fitContainer
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      className={styles.rowWrapper.concat(isFirstItem ? ` ${styles.firstItem}` : '')}
    >
      <Label wrappingType={WrappingType.Normal} className={styles.rowLabel}>
        {label}
      </Label>
      <div className={styles.rowNode}>{node}</div>
    </FlexBox>
  </CustomListItem>
)

DecisionPaperTileListEditItem.propTypes = {
  label: PropTypes.string,
  isFirstItem: PropTypes.bool,
  node: PropTypes.node.isRequired,
}

export default DecisionPaperTileListEditItem
