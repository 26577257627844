import PropTypes from 'prop-types'
import { DealCashflowVacancyCard } from 'components/domains/deals/deal-cashflow/result-charts/DealCashflowVacancyCard'
import { periodValues } from 'components/domains/deals/deal-cashflow/results/PeriodSelect'
import useCashflowScenarioResultsById from 'hooks/services/deals/cashflow/useCashflowScenarioResultsById'

const DealCashflowVacancyCardWrapper = ({ dealUuid, cashflowScenarioUuid }) => {
  const {
    data: { calculatedKpis = [] } = {},
    isLoading,
    isError,
  } = useCashflowScenarioResultsById({
    dealUuid,
    cashflowId: cashflowScenarioUuid,
    datasetPeriod: periodValues.years,
  })

  return (
    <DealCashflowVacancyCard
      isLoading={isLoading}
      isError={isError}
      calculatedKpis={calculatedKpis}
    />
  )
}

DealCashflowVacancyCardWrapper.propTypes = {
  dealUuid: PropTypes.string.isRequired,
  cashflowScenarioUuid: PropTypes.string.isRequired,
}

export default DealCashflowVacancyCardWrapper
