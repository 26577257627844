import PropTypes from 'prop-types'
import CardSection from 'components/domains/deals/card/CardSection'
import MonitoringItemsForDealTable from 'components/domains/deals/covenants/monitoring-items-card-deal/MonitoringItemsForDealTable'
import Card from 'components/ui/card/Card'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import useDealCovenantMonitoringItems from 'hooks/services/deals/covenants/monitorings/useDealCovenantMonitoringItems'

/**
 * Displays all monitoring items for a deal
 */
const MonitoringItemsForDealCard = ({ dealUuid }) => {
  const {
    data: { covenantMonitoringItems = [] } = {},
    isLoading: isLoadingMonitoringItems,
    isError: isErrorMonitoringItems,
  } = useDealCovenantMonitoringItems(mainEntityTypes.DEAL, dealUuid)
  return (
    <Card>
      <CardSection>
        <MonitoringItemsForDealTable
          isLoading={isLoadingMonitoringItems}
          isError={isErrorMonitoringItems}
          covenantMonitoringItems={covenantMonitoringItems}
        />
      </CardSection>
    </Card>
  )
}

MonitoringItemsForDealCard.propTypes = {
  dealUuid: PropTypes.string.isRequired,
}

export default MonitoringItemsForDealCard
