// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zXHqRTnpK9Fjyo6mq4MU {
  height: 100%;
  flex-direction: row;
  padding-left: 16px;
}

.PNPiqA3HURpTRcAPsvK9 {
  height: 100%;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  padding-right: 16px;
}

.PNPiqA3HURpTRcAPsvK9 > *,
.PNPiqA3HURpTRcAPsvK9 ui5-combobox {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/card/EditCardItem.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,wBAAwB;EACxB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;;EAEE,WAAW;AACb","sourcesContent":[".editModeLabelWrapper {\n  height: 100%;\n  flex-direction: row;\n  padding-left: 16px;\n}\n\n.editModeInputWrapper {\n  height: 100%;\n  flex-direction: row;\n  justify-content: stretch;\n  align-items: center;\n  padding-right: 16px;\n}\n\n.editModeInputWrapper > *,\n.editModeInputWrapper ui5-combobox {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editModeLabelWrapper": `zXHqRTnpK9Fjyo6mq4MU`,
	"editModeInputWrapper": `PNPiqA3HURpTRcAPsvK9`
};
export default ___CSS_LOADER_EXPORT___;
