import { MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import camelize from 'camelize'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import ExposureOverview from 'components/domains/business-partners/exposure/ExposureOverview'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import CloseableMessageStrip from 'components/ui/feedback/CloseableMessageStrip'
import Page from 'components/ui/page/Page'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useRiskFigures } from 'hooks/services/business-partners/risk-figures/useRiskFigures'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import styles from 'routes/business-partners/BusinessPartnerExposure.module.css'
import { BusinessPartnerRoles } from 'routes/business-partners/constants'

const BusinessPartnerExposure = () => {
  const businessPartner = useContext(BusinessPartnerContext)
  const { t } = useTranslation()

  const {
    isLoading,
    isError,
    data: {
      risk_figures: riskFigures = {},
      class_types: classTypes = {},
      asset_classes: assetClasses = {},
    } = {},
  } = useRiskFigures({ businessPartnerId: businessPartner.id })

  // more convenient access for display names
  const riskFigureDisplayNamesMap = Object.entries(classTypes).reduce(
    (dictionary, [key, value]) => ({ ...dictionary, [key]: value.display_name }),
    {},
  )

  const businessPartnerIsBorrower = useMemo(
    () => businessPartner.roles.some(({ id }) => id === BusinessPartnerRoles.BORROWER),
    [businessPartner.roles],
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={businessPartner.id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [businessPartner.id],
  )

  if (!businessPartnerIsBorrower) {
    return (
      <>
        <BusinessPartnerHeader
          breadcrumbs={[{ text: t('navigation.item.title.business-partner-exposure') }]}
        />
        <div className={styles.failureMargin}>
          <CloseableMessageStrip design={MessageStripDesign.Negative} hideCloseButton>
            {t('pages.business-partner.exposures.business-partner-is-no-borrower')}{' '}
            <Link
              to={`/business-partners/${businessPartner.id}/relationships`}
              className={styles.link}
            >
              {t('pages.business-partner.relationships')}
            </Link>
          </CloseableMessageStrip>
        </div>
      </>
    )
  }

  return (
    <Page
      renderHeaderTitle={() => (
        <BusinessPartnerHeader
          breadcrumbs={[{ text: t('navigation.item.title.business-partner-exposure'), href: '' }]}
          actions={
            <>
              <CreateBusinessPartnerEventAction />, {markFavoriteAction}
            </>
          }
        />
      )}
      renderContent={() => (
        <ExposureOverview
          riskFigures={camelize(riskFigures)}
          riskFigureDisplayNamesMap={riskFigureDisplayNamesMap}
          assetClasses={assetClasses}
        />
      )}
      showHideHeaderButton={false}
      isLoading={isLoading}
      isError={isError}
    />
  )
}

export default BusinessPartnerExposure
