import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import { ListItemType, StandardListItem } from '@fioneer/ui5-webcomponents-react'
import uniqBy from 'lodash.uniqby'
import PropTypes from 'prop-types'
import useDeals, { dealFilterPropTypes } from 'hooks/services/deals/useDeals'

const MIN_SEARCH_CHARS = 3

const DealSearchSuggestions = ({
  loadingText,
  errorText,
  emptyResultsText,
  typeMoreText,
  currentSearchValue,
  additionalDealFilterValues = {},
}) => {
  const {
    isLoading: isLoadingDealsById,
    isError: isErrorDealsById,
    data: dealsById,
  } = useDeals({
    filter: { dealId: currentSearchValue, ...additionalDealFilterValues },
  })
  const {
    isLoading: isLoadingDealsByName,
    isError: isErrorDealsByName,
    data: dealsByName,
  } = useDeals({
    filter: { dealName: currentSearchValue, ...additionalDealFilterValues },
  })

  const isLoading = isLoadingDealsById || isLoadingDealsByName
  const isError = isErrorDealsById || isErrorDealsByName
  const dealSuggestions = dealsById?.deals?.concat(dealsByName?.deals)
  const uniqueDealSuggestions = uniqBy(dealSuggestions, 'dealUuid')

  if (isLoading) {
    return (
      <StandardListItem key="suggestion-loading" type={ListItemType.Inactive}>
        {loadingText}
      </StandardListItem>
    )
  }
  if (isError) {
    return (
      <StandardListItem key="suggestion-error" type={ListItemType.Inactive}>
        {errorText}
      </StandardListItem>
    )
  }
  if (uniqueDealSuggestions.length === 0) {
    return (
      <StandardListItem key="suggestion-empty" type={ListItemType.Inactive}>
        {emptyResultsText}
      </StandardListItem>
    )
  }
  if (!currentSearchValue || currentSearchValue.length < MIN_SEARCH_CHARS) {
    return <StandardListItem type={ListItemType.Inactive}>{typeMoreText}</StandardListItem>
  }

  return uniqueDealSuggestions.map(({ dealUuid, dealId, name, status }) => (
    <StandardListItem
      key={dealUuid}
      data-uuid={dealUuid}
      data-id={dealId}
      data-name={name}
      data-status={status}
    >{`${name} - ${dealId}`}</StandardListItem>
  ))
}

DealSearchSuggestions.propTypes = {
  loadingText: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
  emptyResultsText: PropTypes.string.isRequired,
  typeMoreText: PropTypes.string.isRequired,
  currentSearchValue: PropTypes.string.isRequired,
  additionalDealFilterValues: dealFilterPropTypes,
}

export default DealSearchSuggestions
