import { useTranslation } from 'react-i18next'

const useCovenantTypeTranslations = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.covenants' })

  const getTranslationForType = (type) =>
    ({
      FINANCIAL: t('financial'),
      NON_FINANCIAL: t('non-financial'),
    }[type] ?? '')

  return { getTranslationForType }
}

export default useCovenantTypeTranslations
