/* eslint-disable no-magic-numbers */
import {
  IllustratedMessage,
  IllustrationMessageSize,
  IllustrationMessageType,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import CWPLayout from 'components/ui/layout/CWPLayout'

/**
 * @typedef {Object} Params
 * @property {number | string} id
 * @property {unknown} pageData
 * @property {unknown} contextData
 * @property {Record<string, unknown>} staticData
 * @property {Function} Component
 * @property {'Component' | 'Adapter'} componentType
 * @property {number} span
 * @property {import('react').ReactNode} children
 *
 * @param {Params} params
 * @returns {import('react').ReactNode}
 */
export const wrapComponentWithLayout = ({
  id,
  pageData,
  contextData,
  staticData,
  Component,
  componentType,
  span,
  children,
}) => {
  let content
  if (isNil(Component)) {
    content = (
      <IllustratedMessage
        key={id}
        size={IllustrationMessageSize.Auto}
        name={IllustrationMessageType.UnableToLoad}
      />
    )
  } else if (componentType === 'Adapter') {
    content = Component({ pageData, staticData, contextData, children, key: id })
  } else {
    content = (
      <Component pageData={pageData} contextData={contextData} staticData={staticData} key={id}>
        {children}
      </Component>
    )
  }

  // Column span of 1 is always just the component itself without wrapper.
  if (span === 1) {
    return content
  }

  switch (span) {
    case 2:
      return <CWPLayout.OneThird key={id}>{content}</CWPLayout.OneThird>
    case 3:
      return <CWPLayout.Half key={id}>{content}</CWPLayout.Half>
    case 4:
    case 5:
      return <CWPLayout.TwoThirds key={id}>{content}</CWPLayout.TwoThirds>
    default:
      return <CWPLayout.Full key={id}>{content}</CWPLayout.Full>
  }
}
