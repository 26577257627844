import { Table, TableCell, TableColumn, TableRow } from '@fioneer/ui5-webcomponents-react'
import cloneDeep from 'lodash.clonedeep'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Entity from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/data/Entity'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/checksheets/AnnualReviewCheckSheetTable.module.css'
import AnnualReviewCheckSheetTableEditCell from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/checksheets/AnnualReviewCheckSheetTableEditCell'

const AnnualReviewCheckSheetTableEdit = ({
  currentData,
  translationPrefix,
  selectedBorrowerId,
  onChange,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: translationPrefix,
  })

  const currentBorrowerDataIndex = currentData.findIndex(
    (borrower) => borrower.borrowerId === selectedBorrowerId,
  )
  const currentBorrowerData = currentData[currentBorrowerDataIndex]

  const onValuesChange = (questionKey, newValue) => {
    const currentDataCopy = cloneDeep(currentData)
    currentDataCopy[currentBorrowerDataIndex].answers[questionKey] = newValue || null
    onChange(currentDataCopy)
  }

  return (
    <Table
      id="annualReviewCheckSheetTable"
      noDataText={t('no-data')}
      className={styles.annualReviewCheckSheetTable}
      columns={
        <>
          <TableColumn />
          <TableColumn key={currentBorrowerData.borrowerId}>
            <Entity
              id={currentBorrowerData.borrowerId}
              name={currentBorrowerData.borrowerName}
              link={`/business-partners/${currentBorrowerData.borrowerId}/`}
            />
          </TableColumn>
        </>
      }
      hideNoData={true}
    >
      {Object.entries(currentBorrowerData.answers || {}).map(([key, value]) => (
        <TableRow key={key}>
          <TableCell>{t(key)}</TableCell>
          <TableCell>
            <AnnualReviewCheckSheetTableEditCell
              question={key}
              value={value}
              onChange={onValuesChange}
            />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}

AnnualReviewCheckSheetTableEdit.propTypes = {
  id: PropTypes.string.isRequired,
  translationPrefix: PropTypes.string.isRequired,
  selectedBorrowerId: PropTypes.string.isRequired,
  currentData: PropTypes.arrayOf(
    PropTypes.shape({
      borrowerId: PropTypes.string.isRequired,
      borrowerName: PropTypes.string,
      answers: PropTypes.shape({}),
    }),
  ),
  onChange: PropTypes.func.isRequired,
}

export default AnnualReviewCheckSheetTableEdit
