import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultipleCollateralAgreementsByTrancheIds = (
  dealUuidsWithTranches,
  { showNewBusiness = true } = {},
) => {
  const validDealUuids = []
  const requests = []

  dealUuidsWithTranches.forEach((dataset) => {
    const ids =
      dataset?.data?.tranches
        ?.flatMap((tranche) => (showNewBusiness ? tranche?.trancheId : tranche?.receivableId))
        .filter((id) => id) || []

    const keys = [
      'collateral-agreements',
      'tranche-ids',
      showNewBusiness ? 'new-business' : 'existing-business',
      ...ids,
    ]
    const params = new URLSearchParams()
    params.append('tranche_ids', ids.join(','))
    params.append('show_new_business', showNewBusiness ? 'true' : 'false')

    if (ids.length > 0) {
      validDealUuids.push(dataset?.data?.dealUuid)
      requests.push({
        path: `/collateral-agreements?${params.toString()}`,
        translated: true,
        keys,
        options: {
          enabled: ids.length > 0,
        },
      })
    }
  })

  const responses = useRequestsMemo(
    useRequests({
      requests,
    }),
  )

  return useMemo(
    () =>
      responses.map((result, index) => ({
        ...result,
        data: { dealUuid: validDealUuids[index], data: camelize(result.data) },
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [responses],
  )
}

export default useMultipleCollateralAgreementsByTrancheIds
