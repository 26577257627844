import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import { nonLoanProductsBorrowerSchema } from 'hooks/services/deals/financing/nonLoanProductsSchema'

const useNonLoanProductsByBorrower = (businessPartnerId, options = {}) => {
  const result = useRequest({
    path: `/financing-structures/borrowers/${businessPartnerId}/non-loan-products`,
    keys: ['borrowers', businessPartnerId, 'nonLoanProducts'],
    translated: true,
    options: {
      enabled: !!businessPartnerId,
      ...options,
    },
  })

  const parsed = useMemo(
    () => nonLoanProductsBorrowerSchema.optional().safeParse(camelize(result.data)),
    [result.data],
  )

  if (!parsed.success) {
    // eslint-disable-next-line no-console
    console.error('Non Loan Products Borrower parsing error', parsed.error)
    return { ...result, data: undefined, isError: true, error: parsed.error }
  }

  return {
    ...result,
    data: parsed.data,
  }
}

export default useNonLoanProductsByBorrower
