import { shape, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CardHeaderLastEditedText } from 'components/domains/deals/card/CardHeaderLastEditedText'
import { periodValues } from 'components/domains/deals/deal-cashflow/results/PeriodSelect'
import styles from 'components/domains/deals/deal-cashflow/used-parameters/DealCashflowUsedParametersCard.module.css'
import { DealCashflowUsedParametersTable } from 'components/domains/deals/deal-cashflow/used-parameters/DealCashflowUsedParametersTable'
import Card from 'components/ui/card/Card'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useCashflowScenarioResultsById from 'hooks/services/deals/cashflow/useCashflowScenarioResultsById'
import useCashflowsParameterDefinitions from 'hooks/services/deals/cashflow/useCashflowsParameters'

export const DealCashflowUsedParametersCard = ({
  cashFlowScenario: {
    dealUuid,
    cashflowScenarioUuid,
    cashflowResultLastUpdatedBy,
    cashflowResultLastUpdatedAt,
  } = {},
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.cashflow.used-params',
  })

  const {
    data: { usedParameters = [] } = {},
    isLoading,
    isError,
  } = useCashflowScenarioResultsById(
    {
      dealUuid,
      cashflowId: cashflowScenarioUuid,
      datasetPeriod: periodValues.years,
    },
    {
      enabled: !!dealUuid && !!cashflowScenarioUuid,
    },
  )

  const {
    data: { parameters: config = [] } = {},
    isLoading: isLoadingConfig,
    isError: isErrorConfig,
  } = useCashflowsParameterDefinitions()

  return (
    <Card
      header={
        <CardHeaderLastEditedText
          title={t('title')}
          email={cashflowResultLastUpdatedBy}
          timestamp={cashflowResultLastUpdatedAt}
        />
      }
      className={styles['card']}
    >
      <LoadingStateWrapper
        isLoading={isLoading || isLoadingConfig}
        isError={isError || isErrorConfig}
      >
        <DealCashflowUsedParametersTable
          data={usedParameters}
          config={config}
          titleText={t('table.title')}
        />
      </LoadingStateWrapper>
    </Card>
  )
}

DealCashflowUsedParametersCard.propTypes = {
  cashFlowScenario: shape({ lastUpdatedBy: string, lastUpdatedAt: string }),
}
