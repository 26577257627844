const getDocumentsFromRoot = (documents) => {
  let resultDocuments = []
  if (!documents) {
    return {}
  }
  resultDocuments = resultDocuments.concat(...documents.documents)
  resultDocuments = resultDocuments.concat(
    ...(documents.subFolders ?? []).map(getDocumentsFromRoot),
  )
  return resultDocuments
}

export default getDocumentsFromRoot
