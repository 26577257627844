import {
  MessageBoxTypes,
  Text,
  Modals,
  MessageStrip,
  MessageStripDesign,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useContext, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { eventErrorCodes } from 'api/events/eventsRequestErrors'
import { noSelectionValueCode } from 'components/domains/business-events-and-tasks/decision-paper/DecisionPaperTemplateDropdown'
import DecisionPaperTemplateGroup from 'components/domains/business-events-and-tasks/decision-paper/DecisionPaperTemplateGroup'
import LoadingButton from 'components/ui/button/LoadingButton'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'
import Dialog from 'components/ui/dialog/Dialog'
import LabeledSwitch from 'components/ui/input/LabeledSwitch'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useCreateDecisionPaperVersion from 'hooks/services/business-events-and-tasks/decision-papers/useCreateDecisionPaperVersion'
import { resetTiles } from 'redux/slices/decision-paper/tilesOverviewSlice'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import styles from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperChangeTemplateDialog.module.css'

const DecisionPaperChangeTemplateDialog = ({
  isOpen,
  setIsOpen,
  templateCode,
  currentTemplateName,
  setIsPopoverOpen,
  existingDecisionPaperTemplate,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper.change-template',
  })

  const [selectedDecisionPaperObject, setSelectedDecisionPaperObject] = useState({
    templateCode: templateCode,
    customizedTemplateStructure: null,
    hasChangesToExistingTemplate: false,
    hasChangesToBaseTemplate: false,
  })
  const [showWarningDialog, setShowWarningDialog] = useState(false)

  const event = useContext(BusinessEventsAndTasksContext).event
  const [eventIdToImportDataFrom, setEventIdToImportDataFrom] = useState(event.id)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    mutate: mutateCreateDecisionPaperVersion,
    isLoading: isLoadingCreateDecisionPaperVersion,
  } = useCreateDecisionPaperVersion()
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const showMessageBox = useShowMessageBox()

  const handleOnSave = useCallback(() => {
    setShowWarningDialog(true)
  }, [setShowWarningDialog])

  const handleConfirmation = useCallback(() => {
    const includeCustomizedTemplateStructure =
      selectedDecisionPaperObject?.hasChangesToBaseTemplate &&
      selectedDecisionPaperObject?.hasChangesToExistingTemplate
    mutateCreateDecisionPaperVersion(
      {
        eventId: event.id,
        templateCode: selectedDecisionPaperObject?.templateCode,
        eventIdToImportDataFrom: eventIdToImportDataFrom,
        templateStructure: includeCustomizedTemplateStructure
          ? selectedDecisionPaperObject?.customizedTemplateStructure
          : undefined,
      },
      {
        onSuccess: () => {
          dispatch(resetTiles())
          queryClient.invalidateQueries(['events', event.id])
          setIsOpen(false)
          setIsPopoverOpen(false)
          setEventIdToImportDataFrom(event.id)
          showToast({ children: t('success') })
          navigate(0)
        },
        onError: async (error) => {
          const errorJson = await error.response.json()
          const errorText =
            errorJson.code === eventErrorCodes.outdatedTemplateError
              ? t('outdated-template-error-message')
              : t('error-message')
          showMessageBox({
            type: MessageBoxTypes.Error,
            children: errorText,
          })
        },
      },
    )
  }, [
    selectedDecisionPaperObject?.hasChangesToBaseTemplate,
    selectedDecisionPaperObject?.hasChangesToExistingTemplate,
    selectedDecisionPaperObject?.templateCode,
    selectedDecisionPaperObject?.customizedTemplateStructure,
    mutateCreateDecisionPaperVersion,
    event.id,
    eventIdToImportDataFrom,
    dispatch,
    queryClient,
    setIsOpen,
    setIsPopoverOpen,
    showToast,
    t,
    navigate,
    showMessageBox,
  ])

  const handleConfirmationCancel = useCallback(() => {
    setShowWarningDialog(false)
    setIsOpen(true)
  }, [setIsOpen, setShowWarningDialog])

  const handleDialogClose = useCallback(() => {
    setIsPopoverOpen(false)
    setIsOpen(false)
  }, [setIsOpen, setIsPopoverOpen])

  const handleSwitchClicked = useCallback(() => {
    if (eventIdToImportDataFrom === null) {
      setEventIdToImportDataFrom(event.id)
      return
    }
    setEventIdToImportDataFrom(null)
  }, [eventIdToImportDataFrom, event.id])

  const handleTemplateChange = useCallback((templateChangeEvent) => {
    setSelectedDecisionPaperObject(templateChangeEvent)
  }, [])

  const hasTemplateChanged = useMemo(
    () => templateCode !== selectedDecisionPaperObject?.templateCode,
    [selectedDecisionPaperObject?.templateCode, templateCode],
  )

  const hasTemplateCustomizationChanged = useMemo(
    () => selectedDecisionPaperObject?.hasChangesToExistingTemplate,
    [selectedDecisionPaperObject?.hasChangesToExistingTemplate],
  )

  const messageStripText = useMemo(() => {
    if (hasTemplateChanged) {
      return t('warning-message-strip.template-changed')
    }
    if (hasTemplateCustomizationChanged) {
      return t('warning-message-strip.template-customization-changed')
    }
    return ''
  }, [hasTemplateChanged, hasTemplateCustomizationChanged, t])

  const warningDialogText = useMemo(() => {
    if (hasTemplateChanged) {
      return t('confirmation-message.template-changed')
    }
    if (hasTemplateCustomizationChanged) {
      return t('confirmation-message.template-customization-changed')
    }
    return ''
  }, [hasTemplateChanged, hasTemplateCustomizationChanged, t])

  const displaySwitch = hasTemplateChanged || hasTemplateCustomizationChanged

  // adjust to object with null checks
  const saveButtonDisabled = useMemo(() => {
    const isTemplateSelected = templateCode !== noSelectionValueCode

    return !isTemplateSelected || (!hasTemplateChanged && !hasTemplateCustomizationChanged)
  }, [hasTemplateChanged, hasTemplateCustomizationChanged, templateCode])

  return (
    <>
      <Dialog
        open={isOpen}
        headerText={t('dialog.header')}
        primaryButton={
          <LoadingButton
            onClick={handleOnSave}
            disabled={saveButtonDisabled}
            renderContent={() => t('OK')}
            isLoading={isLoadingCreateDecisionPaperVersion}
          />
        }
        onBeforeClose={handleDialogClose}
      >
        <div
          className={isLoadingCreateDecisionPaperVersion ? styles.disabled : ''}
          {...(isLoadingCreateDecisionPaperVersion && { inert: '' })}
        >
          {displaySwitch && (
            <MessageStrip hideCloseButton design={MessageStripDesign.Warning}>
              {messageStripText}
            </MessageStrip>
          )}
          <Text className={styles.textPadding}>{t('template-text')}</Text>
          <DecisionPaperTemplateGroup
            onChange={handleTemplateChange}
            templateCode={selectedDecisionPaperObject?.templateCode}
            currentTemplateCode={templateCode}
            currentTemplateName={currentTemplateName}
            eventCode={event.info.code}
            entityId={event.entityRef.entityId}
            entityType={event.entityRef.entityType}
            existingDecisionPaperTemplate={existingDecisionPaperTemplate}
          />
          {displaySwitch && (
            <>
              <Text className={styles.textPadding}>{t('import-data.switch')}</Text>
              <LabeledSwitch
                onChange={handleSwitchClicked}
                checked={eventIdToImportDataFrom !== null}
                checkedText={t('synchronization.on')}
                uncheckedText={t('synchronization.off')}
                isLoading={false}
              />
            </>
          )}
        </div>
      </Dialog>
      {showWarningDialog && (
        <ConfirmationDialog
          confirmationTitle={t('confirmation-header')}
          confirmationMessage={warningDialogText}
          isOpen={showWarningDialog}
          handleConfirmation={handleConfirmation}
          handleCancel={handleConfirmationCancel}
          messageBoxType={MessageBoxTypes.Warning}
        />
      )}
    </>
  )
}

DecisionPaperChangeTemplateDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  templateCode: PropTypes.string.isRequired,
  currentTemplateName: PropTypes.string.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setIsPopoverOpen: PropTypes.func.isRequired,
  existingDecisionPaperTemplate: PropTypes.shape({
    code: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        subsections: PropTypes.arrayOf(
          PropTypes.shape({
            code: PropTypes.string.isRequired,
            tiles: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string.isRequired })),
          }),
        ),
        tiles: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string.isRequired })),
      }),
    ),
  }),
}

export default DecisionPaperChangeTemplateDialog
