import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedCommonCurrency: undefined,
}

export const commonCurrencySlice = createSlice({
  name: 'properties/common-currencies',
  initialState,
  reducers: {
    setSelectedCommonCurrency: (state, { payload }) => {
      state.selectedCommonCurrency = payload
    },
    resetSelectedCommonCurrency: () => initialState,
  },
})

export const { setSelectedCommonCurrency, resetSelectedCommonCurrency } =
  commonCurrencySlice.actions

export default commonCurrencySlice.reducer
