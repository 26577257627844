import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/table/DecisionPaperConditionsTableEmpty.module.css'

const DecisionPaperConditionsTableEmpty = ({ overrideText }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.conditions.table',
  })

  const calculatedText = useMemo(() => {
    if (overrideText) {
      return overrideText
    }
    return t('empty')
  }, [t, overrideText])

  return (
    <FlexBox
      justifyContent={FlexBoxJustifyContent.Center}
      alignItems={FlexBoxAlignItems.Center}
      className={styles.noData}
    >
      <Text wrapping>{calculatedText}</Text>
    </FlexBox>
  )
}

DecisionPaperConditionsTableEmpty.propTypes = {
  overrideText: PropTypes.string,
}

export default DecisionPaperConditionsTableEmpty
