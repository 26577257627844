import { useMemo } from 'react'
import { entityTypes } from 'api/conditions/conditions'
import { useMatchingBusinessPartnersByNameOrId } from 'hooks/services/business-partners/searchBusinessPartners'
import useDeals from 'hooks/services/deals/useDeals'
import { useProperties } from 'hooks/services/properties/useProperties'
import paths from 'routes/paths'

const minCharacters = 1

const useBusinessObjectSelectionSearch = ({ entityType, searchString, addDealSearch }) => {
  const isPropertySearchEnabled = useMemo(() => {
    if (entityType !== entityTypes.property && entityType !== '') {
      return false
    }
    if (searchString.length < minCharacters) {
      return false
    }
    return true
  }, [searchString, entityType])

  const {
    data: propertyData,
    isLoading: isPropertiesLoading,
    isError: isPropertiesError,
  } = useProperties({
    filter: { propertyName: searchString },
    sort: '+description',
    pagination: { offset: 0, limit: 50 },
    options: { enabled: isPropertySearchEnabled },
  })

  const isBpSearchEnabled = useMemo(() => {
    if (entityType !== entityTypes.businesspartner && entityType !== '') {
      return false
    }
    if (searchString.length < minCharacters) {
      return false
    }
    return true
  }, [searchString, entityType])

  const {
    data: bpData,
    isLoading: isBpLoading,
    isError: isBpError,
  } = useMatchingBusinessPartnersByNameOrId({
    searchKey: searchString,
    sort: '+name',
    minLength: isBpSearchEnabled ? minCharacters : Number.MAX_SAFE_INTEGER,
  })

  const isDealSearchEnabled = useMemo(() => {
    if (!addDealSearch) {
      return false
    }
    if (entityType !== entityTypes.deal && entityType !== '') {
      return false
    }
    if (searchString.length < minCharacters) {
      return false
    }
    return true
  }, [entityType, addDealSearch, searchString])

  const {
    data: dealData,
    isLoading: isDealLoading,
    isError: isDealError,
  } = useDeals({
    filter: { dealName: searchString },
    queryOptions: { enabled: isDealSearchEnabled },
  })

  const reducedData = useMemo(() => {
    const result = []
    if (
      !isBpLoading &&
      !isBpError &&
      isBpSearchEnabled &&
      (entityType === '' || entityType === entityTypes.businesspartner)
    ) {
      bpData.businessPartners.forEach(({ id, name }) => {
        result.push({
          name,
          displayId: id,
          id,
          link: `/${paths.businessPartners}/${id}`,
          entityType: entityTypes.businesspartner,
        })
      })
    }
    if (
      !isPropertiesLoading &&
      !isPropertiesError &&
      isPropertySearchEnabled &&
      (entityType === '' || entityType === entityTypes.property)
    ) {
      propertyData.properties.forEach(({ uuid, id, description }) => {
        result.push({
          name: description,
          displayId: id,
          id: uuid,
          link: `/${paths.properties}/${id}`,
          entityType: entityTypes.property,
        })
      })
    }
    if (
      !isDealLoading &&
      !isDealError &&
      isDealSearchEnabled &&
      (entityType === '' || entityType === entityTypes.deal)
    ) {
      result.push(
        ...dealData.deals.map(({ dealUuid, dealId, name }) => ({
          name: name,
          displayId: dealId,
          id: dealUuid,
          link: `/${paths.deals}/${dealId}`,
          entityType: entityTypes.deal,
        })),
      )
    }
    return result
  }, [
    isBpLoading,
    isBpError,
    isBpSearchEnabled,
    entityType,
    isPropertiesLoading,
    isPropertiesError,
    isPropertySearchEnabled,
    isDealLoading,
    isDealError,
    isDealSearchEnabled,
    bpData,
    propertyData,
    dealData,
  ])

  return useMemo(
    () => ({
      isLoading:
        (isPropertiesLoading && isPropertySearchEnabled) || (isBpLoading && isBpSearchEnabled),
      isError: (isBpError && isBpSearchEnabled) || (isPropertiesError && isPropertySearchEnabled),
      data: reducedData,
    }),
    [
      isPropertiesLoading,
      isBpLoading,
      isBpError,
      isBpSearchEnabled,
      isPropertiesError,
      isPropertySearchEnabled,
      reducedData,
    ],
  )
}

export default useBusinessObjectSelectionSearch
