import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { useSegmentUsageTypes } from 'hooks/services/properties/segments/useSegmentUsageTypes'

const SegmentUsageTypeSelect = ({ usageType, setUsageType, onValidationChange }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.rent-roll-working-version.segment-creation',
  })

  const [isValid, setIsValid] = useState(true)

  const validate = (segmentUsageType) => {
    const newIsValid = !!segmentUsageType
    setIsValid(newIsValid)
    onValidationChange(newIsValid)
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label required>{t('usage-type')}</Label>
      <LoadingSelect
        onChange={(e) => {
          const segmentUsageType = e.detail.selectedOption.dataset.id
          validate(segmentUsageType)
          setUsageType(segmentUsageType)
        }}
        selectedKey={usageType}
        loadingHook={useSegmentUsageTypes}
        isErrorState={!isValid}
      />
    </FlexBox>
  )
}
SegmentUsageTypeSelect.propTypes = {
  usageType: PropTypes.string,
  setUsageType: PropTypes.func.isRequired,
  onValidationChange: PropTypes.func.isRequired,
}
export default SegmentUsageTypeSelect
