import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useCovenantCheckHistoricalEvents = ({ covenantCheckUuid, options = {} } = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/covenants/covenant-checks/${covenantCheckUuid}/historical-events`,
      translated: true,
      useCache: true,
      keys: ['covenants', 'covenant-checks', covenantCheckUuid, 'historical-events'],
      options: { enabled: !!covenantCheckUuid, ...options },
    }),
  )

export default useCovenantCheckHistoricalEvents
