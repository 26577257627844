import camelize from 'camelize'
import { useRequest } from 'hooks/services/baseService'

export const useSapBusinessPartnerRoles = () => {
  const response = useRequest({
    path: `/properties/sap-business-partner-roles`,
    translated: true,
  })

  return {
    ...response,
    data: camelize(response.data),
  }
}
