import { TableRowType, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ConditionsReferencesView from 'components/domains/conditions/shared/ConditionsReferencesView'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'

const ConditionSelectionTable = ({ conditions, isLoading, isError, onSelectTableRow }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.condition-selection.table',
  })

  const columns = useMemo(
    () => [
      {
        title: t('name'),
        columnKey: 'name',
        isSelectableForHiding: false,
      },
      {
        title: t('category'),
        columnKey: 'category',
        isSelectableForHiding: false,
      },
      {
        title: t('type'),
        columnKey: 'type',
        isSelectableForHiding: false,
      },
      {
        title: t('ref-number'),
        columnKey: 'refNumber',
        isSelectableForHiding: false,
      },
      {
        title: t('references'),
        columnKey: 'references',
        isSelectableForHiding: false,
      },
    ],
    [t],
  )

  const onRowClick = useCallback(
    (condition) => () => {
      onSelectTableRow({ condition })
    },
    [onSelectTableRow],
  )

  const tableData = useMemo(
    () =>
      conditions?.map((condition) => ({
        rowKey: `${condition.info.name}-${condition.id}`,
        rowProperties: {
          type: TableRowType.Active,
          onClick: onRowClick(condition),
        },
        name: {
          value: condition.info.name,
          cellComponent: <Text>{condition.info.name || '-'}</Text>,
        },
        category: {
          value: condition.info.category,
          cellComponent: <Text>{condition.info.category || '-'}</Text>,
        },
        type: {
          value: condition.info.type.name,
          cellComponent: <Text>{condition.info.type.name || '-'}</Text>,
        },
        refNumber: {
          value: condition.info.refNumber,
          cellComponent: <Text>{condition.info.refNumber || '-'}</Text>,
        },
        references: {
          value: condition.references?.entityIds,
          cellComponent: <ConditionsReferencesView references={condition.references} />,
        },
      })) ?? [],
    [conditions, onRowClick],
  )

  const additionalTableProperties = useMemo(
    () => ({
      busy: isLoading,
      busyDelay: 0,
    }),
    [isLoading],
  )

  const renderTable = useCallback(
    () => (
      <SortedTable
        toolbarConfig={{
          title: t('title'),
        }}
        noDataText={t('empty')}
        columnDefinitions={columns}
        tableData={tableData}
        additionalTableProperties={additionalTableProperties}
      />
    ),
    [t, columns, tableData, additionalTableProperties],
  )

  return (
    <RequestStateResolver
      isError={isError}
      isLoading={false}
      errorToDisplay={<ErrorDataUnavailableInContent />}
      renderContent={renderTable}
    />
  )
}

ConditionSelectionTable.propTypes = {
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      info: PropTypes.shape({
        name: PropTypes.string.isRequired,
        category: PropTypes.string,
        refNumber: PropTypes.string,
      }),
      references: PropTypes.shape({
        entityIds: PropTypes.arrayOf(PropTypes.string).isRequired,
        entityType: PropTypes.string.isRequired,
      }),
      id: PropTypes.string.isRequired,
    }),
  ),
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  onSelectTableRow: PropTypes.func.isRequired,
}

export default ConditionSelectionTable
