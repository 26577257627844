import { useMemo } from 'react'
import useDecisionStageConditions from 'hooks/services/business-events-and-tasks/decision-process/stages/conditions/useDecisionStageConditions'
import useDecisionStageConditionsVoters from 'hooks/services/business-events-and-tasks/decision-process/stages/conditions/useDecisionStageConditionsVoters'
import useDecisionStage from 'hooks/services/business-events-and-tasks/decision-process/stages/useDecisionStage'
import { useStaffMemberSelf } from 'hooks/services/business-partners/staff-members/useStaffMemberSelf'

const useDecisionStageConditionsApproval = (
  { eventId, decisionStageId, voterId },
  options = { enabled: true },
) => {
  const {
    isLoading: isDecisionStageLoading,
    isError: isDecisionStageError,
    data: decisionStageData,
  } = useDecisionStage({ eventId, decisionStageId }, options)

  const {
    isLoading: isDecisionStageConditionsLoading,
    isError: isDecisionStageConditionsError,
    data: decisionStageConditionsData,
  } = useDecisionStageConditions({ eventId, decisionStageId }, options)

  const {
    isLoading: isStaffMemberSelfLoading,
    isError: isStaffMemberSelfError,
    data: staffMember,
  } = useStaffMemberSelf(options)

  const {
    isLoading: isDecisionStageConditionsVotersLoading,
    isError: isDecisionStageConditionsVotersError,
    data: decisionStageConditionsVoters,
  } = useDecisionStageConditionsVoters({ eventId, decisionStageId }, options)

  return useMemo(() => {
    const isLoading =
      isDecisionStageLoading ||
      isStaffMemberSelfLoading ||
      isDecisionStageConditionsVotersLoading ||
      isDecisionStageConditionsLoading
    const isError =
      isDecisionStageError ||
      isStaffMemberSelfError ||
      isDecisionStageConditionsVotersError ||
      isDecisionStageConditionsError
    return {
      isLoading,
      isError,
      data:
        !isLoading && !isError
          ? {
              decisionStageData,
              decisionStageConditionsData,
              staffMember,
              decisionStageConditionsVoters,
              userVote: decisionStageConditionsVoters.voters.find(
                ({ userId, id }) => userId === staffMember.id && voterId === id,
              ),
            }
          : undefined,
    }
  }, [
    isDecisionStageLoading,
    isStaffMemberSelfLoading,
    isDecisionStageConditionsVotersLoading,
    isDecisionStageConditionsLoading,
    isDecisionStageError,
    isStaffMemberSelfError,
    isDecisionStageConditionsVotersError,
    isDecisionStageConditionsError,
    decisionStageData,
    decisionStageConditionsData,
    staffMember,
    decisionStageConditionsVoters,
    voterId,
  ])
}

export default useDecisionStageConditionsApproval
