import { Link } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import useRequirementsCellAddMode from 'hooks/services/conditions/edit-mode/useRequirementsCellAddMode'
import useRequirementsCellEditMode from 'hooks/services/conditions/edit-mode/useRequirementsCellEditMode'
import { openRequirementCommentDialog } from 'redux/slices/conditions/requirementsTableSlice'

const RequirementsTableCommentSubcomponent = ({ requirementId, conditionId, numberOfComments }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ui.comment.buttons',
  })
  const dispatch = useDispatch()
  const { isRequirementAddMode } = useRequirementsCellAddMode({
    requirementId,
  })
  const { isEditModeForAnyRow } = useRequirementsCellEditMode({
    requirementId,
  })
  const disabled = useMemo(
    () => isRequirementAddMode || isEditModeForAnyRow,
    [isRequirementAddMode, isEditModeForAnyRow],
  )

  const onCommentButtonClicked = useCallback(() => {
    dispatch(openRequirementCommentDialog({ requirementId, conditionId }))
  }, [conditionId, dispatch, requirementId])

  return (
    <Link onClick={onCommentButtonClicked} disabled={disabled}>
      {t('open-dialog', { commentCount: numberOfComments })}
    </Link>
  )
}

RequirementsTableCommentSubcomponent.propTypes = {
  numberOfComments: PropTypes.number.isRequired,
  requirementId: PropTypes.string,
  conditionId: PropTypes.string,
}

export default RequirementsTableCommentSubcomponent
