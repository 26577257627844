import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useUpdateDecisionPaperSubsectionAssessment = () => {
  const { put } = useAccessTokenRequest()

  return useMutation(({ eventId, subsectionId, text }) =>
    put({
      path: `/events/${eventId}/decision-paper/versions/current/subsections/${subsectionId}/assessment`,
      body: {
        text,
      },
    }),
  )
}
