import { ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingButton from 'components/ui/button/LoadingButton'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import useExportMarketProperties from 'hooks/services/markets/useExportMarketProperties'

const MarketDetailExportButton = ({ marketId }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.markets.detail' })
  const [isExportLoading, setIsExportLoading] = useState(false)

  const showMessageBox = useShowMessageBox()

  const onExportError = useCallback(() => {
    setIsExportLoading(false)
    showMessageBox({
      type: MessageBoxTypes.Error,
      titleText: t('export.error.title'),
      children: t('export.error.description'),
      actions: [MessageBoxActions.OK],
    })
  }, [showMessageBox, t])

  const { download: downloadMarketPropertiesExport } = useExportMarketProperties()

  const onExportSuccess = useCallback(() => setIsExportLoading(false), [])
  const downloadMarketExport = useCallback(() => {
    setIsExportLoading(true)
    downloadMarketPropertiesExport({
      marketId,
      onSuccess: onExportSuccess,
      onError: onExportError,
    })
  }, [downloadMarketPropertiesExport, marketId, onExportError, onExportSuccess])

  const renderButtonContent = useCallback(() => t('buttons.export'), [t])

  return (
    <LoadingButton
      isLoading={isExportLoading}
      design={ButtonDesign.Default}
      onClick={downloadMarketExport}
      renderContent={renderButtonContent}
    />
  )
}

MarketDetailExportButton.propTypes = {
  marketId: PropTypes.string.isRequired,
}

export default MarketDetailExportButton
