import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import '@ui5/webcomponents-icons/dist/error.js'
import PropTypes from 'prop-types'
import { useCallback, useId, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import AddNewCommentField from 'components/ui/comment/AddNewCommentField'
import styles from 'components/ui/comment/CommentDialog.module.css'
import Dialog from 'components/ui/dialog/Dialog'
import 'components/ui/dialog/ErrorDialog.css'
import useAnalyticalTableDialogFocusFix from 'hooks/analytical-table/useAnalyticalTableDialogFocusFix'

/**
 * Refactor to use the CWP-Dialog instead of the UI5-Dialog | https://fioneer.atlassian.net/browse/CWP-13200
 */
const CommentDialog = ({
  isOpen,
  setIsOpen,
  showNewCommentCard,
  children,
  onCreateComment,
  isEditMode,
  setIsEditMode,
}) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ui.comment.dialog',
  })

  const closeButtonId = useId()

  const dialogRef = useRef()

  const closeButton = useMemo(
    () => (
      <Button id={closeButtonId} design={ButtonDesign.Transparent} onClick={() => setIsOpen(false)}>
        {tNoPrefix('buttons.close')}
      </Button>
    ),
    [closeButtonId, tNoPrefix, setIsOpen],
  )

  useAnalyticalTableDialogFocusFix({ dialogRef })
  const handleAfterOpen = useCallback(() => {
    const popupRoot = dialogRef.current?.shadowRoot?.children?.[0]
    if (popupRoot?.style) {
      popupRoot.style.overflow = 'visible'
    }
  }, [])

  const handleOnAfterClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  return (
    <Dialog
      ref={dialogRef}
      className={styles.dialog}
      open={isOpen}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleOnAfterClose}
      headerText={t('header')}
      closeButton={closeButton}
    >
      {showNewCommentCard && (
        <AddNewCommentField
          className={styles.textEditor}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          onSave={onCreateComment}
        />
      )}
      {children}
    </Dialog>
  )
}

CommentDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  setIsEditMode: PropTypes.func.isRequired,
  showNewCommentCard: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onCreateComment: PropTypes.func.isRequired,
}

export default CommentDialog
