import { ObjectPageSection, ObjectPageSubSection } from '@fioneer/ui5-webcomponents-react'
import { translateStaticData } from 'components/ui/configurable-components-page/useTranslateStaticData'

export const objectPageSectionAdapter = ({ dynamicData, staticData, children, key }) =>
  dynamicData?.renderSection ?? true ? (
    <ObjectPageSection
      key={key}
      id={staticData.id ?? key}
      titleText={translateStaticData(staticData.titleText)}
      hideTitleText={staticData.hideTitleText}
      wrapTitleText={staticData.wrapTitleText}
      className={staticData.className}
    >
      {children}
    </ObjectPageSection>
  ) : (
    children
  )

export const objectPageSubSectionAdapter = ({ staticData, children, key }) => (
  <ObjectPageSubSection
    key={key}
    id={staticData.id ?? key}
    titleText={translateStaticData(staticData.titleText)}
    hideTitleText={staticData.hideTitleText}
    wrapTitleText={staticData.wrapTitleText}
    className={staticData.className}
  >
    {children}
  </ObjectPageSubSection>
)
