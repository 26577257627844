import { useQuery } from '@tanstack/react-query'
import { createContext } from 'react'
import { getConfig } from 'api/config'
import { RUNTIME_ENV } from 'api/setupEnv'

/**
 * Hook API for accessing the configuration
 */
export const useConfig = () => {
  const destination =
    RUNTIME_ENV === 'production' ? '/config/config.json' : '/config/local.config.json'

  return useQuery(['config'], () => getConfig(destination), {
    staleTime: Infinity,
    refetchInterval: false,
  })
}

const initialConfig = /** @type {config} */ (/** @type {unknown} */ ({}))
export const ConfigContext = createContext(initialConfig)
