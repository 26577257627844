import sortBy from 'lodash.sortby'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @callback TenantKpiToChartDataPointFn
 * @param  {object} element - Value of array element
 * @param  {number} index   - Index of array element
 * @param  {Array}  array   - Array itself
 *
 * @typedef {object} UsePropertyTenantsChartDataOptions
 * @property {object} propertyKpiData the data to process
 * @property {function} getMetricFromTenantKpi
 * @property {TenantKpiToChartDataPointFn} tenantKpiToChartDataPoint
 * @property {function} groupedTenantsKpisToChartDataPoint
 * @property {number} maxDisplayedTenants
 * @property {boolean} [removeTenantsWithMetricValueZero]
 *
 * @param {UsePropertyTenantsChartDataOptions} options
 */
const usePropertyTenantsChartData = ({
  propertyKpiData,
  getMetricFromTenantKpi,
  tenantKpiToChartDataPoint,
  groupedTenantsKpisToChartDataPoint,
  maxDisplayedTenants,
  removeTenantsWithMetricValueZero = false,
  businessPartnerData: tenantsData,
}) => {
  const { t: tCharts } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.property.rent-roll.overview.charts',
  })

  const groupOtherTenantKpisToChartDataPoint = useCallback(
    (tenantKpis) => ({
      name: tCharts('grouped-categories.title', {
        count: tenantKpis.length,
      }),
      ...groupedTenantsKpisToChartDataPoint(tenantKpis),
    }),
    [groupedTenantsKpisToChartDataPoint, tCharts],
  )

  const tenantRentRollKpis = propertyKpiData?.tenantRentRollKpis ?? []

  let tenantDataSortedByMetricDesc = sortBy(tenantRentRollKpis, getMetricFromTenantKpi).reverse()

  if (removeTenantsWithMetricValueZero) {
    tenantDataSortedByMetricDesc = tenantDataSortedByMetricDesc.filter(
      (tenant) => getMetricFromTenantKpi(tenant) > 0,
    )
  }

  const shouldGroupTenants = tenantDataSortedByMetricDesc.length > maxDisplayedTenants

  const tenantsChartDataWithoutTenantNames = (() => {
    if (shouldGroupTenants) {
      const namedTenants = tenantDataSortedByMetricDesc
        .slice(0, maxDisplayedTenants - 1)
        .map(tenantKpiToChartDataPoint)

      const groupedTenants = groupOtherTenantKpisToChartDataPoint(
        tenantDataSortedByMetricDesc.slice(maxDisplayedTenants - 1),
      )

      return [...namedTenants, groupedTenants]
    }

    return tenantDataSortedByMetricDesc.map(tenantKpiToChartDataPoint)
  })()

  /** merged chart data with tenant names from the business partners query */
  const tenantsChartDataWithTenantNames = useMemo(() => {
    if (!tenantsChartDataWithoutTenantNames) return []
    return tenantsChartDataWithoutTenantNames.map((currentRentDataPoint) => ({
      ...currentRentDataPoint,
      name:
        currentRentDataPoint.name ??
        (tenantsData?.businessPartnerMinis ?? []).find(
          (tenant) => tenant.id === currentRentDataPoint.tenantId,
        )?.fullName ??
        tCharts('anonymous-tenant'),
    }))
  }, [tenantsChartDataWithoutTenantNames, tCharts, tenantsData])

  const hasEmptyRentRollData = tenantsChartDataWithoutTenantNames.length === 0

  return {
    data: tenantsChartDataWithTenantNames,
    isLoading: false,
    isError: false,
    isEmpty: hasEmptyRentRollData,
  }
}

export default usePropertyTenantsChartData
