import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import RichTextTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/RichTextTile'
import useTextEditorCurrentContent, {
  getContentFromTileStatus,
} from 'hooks/text-editor/useTextEditorCurrentContent'

const RichTextTileWrapper = ({
  tileId,
  isEditMode,
  isPdfView,
  currentContent,
  onChange,
  setIsSaveEnabled,
  setHasContentChanges,
  currentVersion,
}) => {
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const {
    currentContent: internalCurrentContent,
    setCurrentContent,
    hasChanges,
  } = useTextEditorCurrentContent(
    getContentFromTileStatus({ tileStatus, currentDecisionPaperVersion: currentVersion }),
  )

  useEffect(() => {
    onChange({ text: internalCurrentContent })
  }, [internalCurrentContent]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEditMode && currentContent) {
      setCurrentContent(currentContent.text)
    }
  }, [isEditMode]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setHasContentChanges(hasChanges)
    setIsSaveEnabled(hasChanges)
  }, [hasChanges]) // eslint-disable-line react-hooks/exhaustive-deps

  const setIsDataUploading = useCallback(
    (isLoading) => {
      setIsSaveEnabled(!isLoading)
    },
    [setIsSaveEnabled],
  )

  return (
    <RichTextTile
      isEditMode={isEditMode}
      isPdfView={isPdfView}
      currentContent={internalCurrentContent}
      onChange={setCurrentContent}
      setIsDataUploading={setIsDataUploading}
    />
  )
}

RichTextTileWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  currentContent: PropTypes.shape({
    text: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  setHasContentChanges: PropTypes.func.isRequired,
  setIsSaveEnabled: PropTypes.func.isRequired,
  currentVersion: PropTypes.string.isRequired,
}

export default RichTextTileWrapper
