import { useAuth } from 'oidc-react'
import { useContext } from 'react'
import LandingPageDefault from 'components/domains/home/LandingPageDefault'
import { UserProfileContext } from 'routes/UserProfileContext'

const Home = () => {
  const { userData } = useAuth()
  if (!userData?.profile) throw new Error('User must be logged in')
  const searchParams = new URLSearchParams()
  searchParams.append('userId', userData.profile.preferred_username)
  const userProfile = useContext(UserProfileContext)

  const renderStartPage = (startPageType) => {
    switch (startPageType) {
      default:
        return <LandingPageDefault />
    }
  }

  return <>{renderStartPage(userProfile?.startPageType)}</>
}

export default Home
