import { useBusinessSegments } from 'hooks/services/deals/configurations/useBusinessSegments'
import useRoleConfig, { RoleConfig } from 'hooks/services/deals/configurations/useRoleConfig'
import { useCurrencyCodes as useFinancingCurrencyCodes } from 'hooks/services/deals/financing/configGetters/useCurrencyCodes'
import { useLoanTypeCodes } from 'hooks/services/deals/financing/configGetters/useLoanTypeCodes'
import { useCurrencyCodes as usePropertyCurrencyCodes } from 'hooks/services/properties/useCurrencyCodes'
import { useTypecodes } from 'hooks/services/properties/useTypecodes'

export const useDealCreationDependencies = (enabled) => {
  const options = { enabled }
  const { isLoading: isLoadingLoanTypes, isError: isErrorLoanTypes } = useLoanTypeCodes(options)
  const { isLoading: isLoadingTypeCodes, isError: isErrorTypeCodes } = useTypecodes(options)
  const { isLoading: isLoadingBusinessSegments, isError: isErrorBusinessSegments } =
    useBusinessSegments(options)
  const { isLoading: isLoadingPropertyCurrencyCodes, isError: isErrorPropertyCurrencyCodes } =
    usePropertyCurrencyCodes(options)
  const { isLoading: isLoadingFinancingCurrencyCodes, isError: isErrorFinancingCurrencyCodes } =
    useFinancingCurrencyCodes(options)
  const { isLoading: isLoadingBorrowerRoles, isError: isErrorBorrowerRoles } = useRoleConfig(
    RoleConfig.BORROWER_GROUP,
    options,
  )

  return {
    isLoading:
      isLoadingLoanTypes ||
      isLoadingTypeCodes ||
      isLoadingBusinessSegments ||
      isLoadingPropertyCurrencyCodes ||
      isLoadingFinancingCurrencyCodes ||
      isLoadingBorrowerRoles,
    isError:
      isErrorLoanTypes ||
      isErrorTypeCodes ||
      isErrorBusinessSegments ||
      isErrorPropertyCurrencyCodes ||
      isErrorFinancingCurrencyCodes ||
      isErrorBorrowerRoles,
  }
}
