const moveBorrowerToFrontAndMark = (tableData, borrowerBpId) => {
  const borrowerUnitMemberIndex = tableData.findIndex(
    (rootRow) => rootRow.unitMemberBpId === borrowerBpId,
  )
  if (borrowerUnitMemberIndex >= 0) {
    tableData.unshift(tableData.splice(borrowerUnitMemberIndex, 1)[0])
    tableData[0] = { ...tableData[0], isBorrower: true }
  }
  return tableData
}

export default moveBorrowerToFrontAndMark
