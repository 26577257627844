import merge from 'lodash.merge'
import { CUSTOMER_OPTION_ROW_TYPES } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/customerOptionsConstants'

const setSelectedRowsActive = ({ event, editOptions, setEditOptions }) => {
  const isSelected = event.detail.isSelected
  const originalRow = event.detail.row.original

  let mergedResult

  if (originalRow.rowType === CUSTOMER_OPTION_ROW_TYPES.CLUSTER) {
    originalRow.subRows.forEach((option) => {
      const newOption = {
        [option.id]: {
          isActive: isSelected,
        },
      }
      mergedResult = merge(editOptions, newOption)
    })
  }

  if (originalRow.rowType === CUSTOMER_OPTION_ROW_TYPES.OPTION) {
    const newOption = {
      [originalRow.id]: {
        isActive: isSelected,
      },
    }
    mergedResult = merge(editOptions, newOption)
  }
  setEditOptions({ ...mergedResult })
}

export default setSelectedRowsActive
