const mapDocumentsForTable = (documents) => {
  if (!documents) {
    return {}
  }
  const result = {
    ...documents,
    subFolders: [
      ...(documents.subFolders ?? []).map(mapDocumentsForTable),
      ...(documents.documents ?? []),
    ],
  }
  delete result.documents // merged into subFolders => remove these now duplicates
  return result
}

export default mapDocumentsForTable
