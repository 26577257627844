import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useArrearAllowedOperations = () =>
  useCamelizedResponse(
    useRequest({
      path: '/arrears/allowed-operations',
      keys: ['arrears', 'allowed-operations'],
    }),
  )

export default useArrearAllowedOperations
