import {
  FlexBox,
  FlexBoxJustifyContent,
  Table,
  TableColumn,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DecisionPaperCheckedCovenantDisplayRow from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/DecisionPaperCheckedCovenantDisplayRow'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/DecisionPaperCheckedCovenantsTable.module.css'
import { monitoringItemShape } from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/CheckedCovenantPropTypes'
import { mainEntityTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/MainEntityTypes'
import TablesToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/toolbar/CovenantsOfCheckTablesToolbar'
import { cwpEntityTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/event/shared/constants/cwpEntityTypes'

const DecisionPaperCheckedCovenantsTable = ({ monitoringItems = [], entityType, deals }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.covenants-of-check.components.deals.covenants.checked-covenants',
  })
  const { t: tNoPrefix } = useTranslation('decisionPaper')

  const columnDefinitions = [
    { title: t('covenant'), demandPopin: false, minWidth: 0 },
    { title: t('covenant-type'), demandPopin: true, minWidth: 800 },
    {
      title: t('delivery-date'),
      demandPopin: true,
      minWidth: 700,
      alignment: FlexBoxJustifyContent.End,
    },
    {
      title: t('new-delivery-date'),
      demandPopin: true,
      minWidth: 650,
      alignment: FlexBoxJustifyContent.End,
    },
    {
      title: t('soft-limit'),
      demandPopin: true,
      minWidth: 800,
      alignment: FlexBoxJustifyContent.End,
    },
    {
      title: t('hard-limit'),
      demandPopin: true,
      minWidth: 800,
      alignment: FlexBoxJustifyContent.End,
    },
    { title: t('result'), demandPopin: true, minWidth: 800, alignment: FlexBoxJustifyContent.End },
    { title: t('breach'), demandPopin: true, minWidth: 600 },
    { title: t('waiver'), demandPopin: true, minWidth: 600 },
  ]

  return (
    <div className={styles.defaultCursor}>
      <TablesToolbar title={t('title')} nrOfEntries={monitoringItems.length} />
      <Table
        noDataText={tNoPrefix('tiles.covenants-of-check.components.table.no-data')}
        columns={columnDefinitions.map(({ title, key, demandPopin, minWidth, alignment }) => (
          <TableColumn
            key={key ?? title}
            demandPopin={demandPopin}
            popinText={title}
            minWidth={minWidth}
          >
            <FlexBox justifyContent={alignment}>{title}</FlexBox>
          </TableColumn>
        ))}
      >
        {monitoringItems.map((item) => {
          const { covenantMonitoringItemUuid, covenant } = item
          const isCovenantDealMainEntityType = covenant?.mainEntity?.type === mainEntityTypes.DEAL
          const dealUuid = isCovenantDealMainEntityType ? covenant.mainEntity.id : null
          const { dealId } = deals?.find((localDeal) => localDeal.dealUuid === dealUuid) ?? {}

          return (
            <DecisionPaperCheckedCovenantDisplayRow
              key={covenantMonitoringItemUuid}
              dealId={dealId}
              entityType={entityType}
              monitoringItem={item}
            />
          )
        })}
      </Table>
    </div>
  )
}

DecisionPaperCheckedCovenantsTable.propTypes = {
  entityType: PropTypes.oneOf([cwpEntityTypes.DEAL, cwpEntityTypes.BUSINESS_PARTNER]),
  deals: PropTypes.arrayOf(
    PropTypes.shape({ dealUuid: PropTypes.string, dealId: PropTypes.string }),
  ),
  monitoringItems: PropTypes.arrayOf(monitoringItemShape),
  isAllowedToEdit: PropTypes.bool,
}

export default DecisionPaperCheckedCovenantsTable
