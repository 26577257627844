import isEmpty from 'lodash.isempty'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ClickableCardHeader from 'components/ui/card/ClickableCardHeader'
import DisplayCardView, { hideOptions } from 'components/ui/card/DisplayCardView'
import {
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'
import { useFiscalYears } from 'hooks/services/business-partners/kpis/useFiscalYears'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import { mapFiscalDataForFinancialInformationTable } from 'routes/business-partners/mapFiscalDataForFinancialInformationTable'

const fiscalFigureFormats = {
  money: 'money',
  ratio: 'ratio',
  area: 'area',
}

const minimumImportanceLevel = 8
const noOfYears = 1

const BusinessPartnerOverviewFinancialIndicatorsTile = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.general-information.financial-indicators',
  })
  const formatMoney = useCustomizableCurrencyFormatter()
  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const { format: formatDate } = useShortDateFormatter()
  const { id: businessPartnerId } = useContext(BusinessPartnerContext)
  const {
    data: {
      fiscal_figure_groups: fiscalFigureGroups = {},
      fiscal_figure_types: fiscalFigureTypes = {},
      fiscal_years: fiscalYears = {},
    } = {},
    isLoading,
    isError,
  } = useFiscalYears(businessPartnerId)

  const tableData = useMemo(
    () =>
      mapFiscalDataForFinancialInformationTable({
        fiscalFigureGroups,
        fiscalFigureTypes,
        fiscalYears,
        minimumImportanceLevel,
        noOfYears,
      }),
    [fiscalFigureGroups, fiscalFigureTypes, fiscalYears],
  )

  const fieldDefinitions = useMemo(() => {
    if (isEmpty(fiscalYears)) {
      return []
    }
    const latestYear = Object.keys(fiscalYears).sort((a, b) => b - a)[0]
    const endDate = fiscalYears[latestYear]?.end_date
    return [
      {
        label: t('key-date'),
        value: endDate ? formatDate(endDate) : undefined,
        hideOption: hideOptions.hideWhenEmpty,
      },
      ...tableData.map((row) => {
        if (row.isSectionHeader) {
          return {
            value: { sectionTitle: row.description },
            isSectionTitle: true,
          }
        }
        const fieldDefinition = {
          label: row.description,
          name: row.description,
          value: `${row[latestYear]?.value} ${row[latestYear]?.unit}`,
          hideOption: hideOptions.hideWhenEmpty,
        }
        switch (row[latestYear]?.format) {
          case fiscalFigureFormats.money:
            fieldDefinition.value = formatMoney(row[latestYear].value, row[latestYear].unit)
            break

          default:
            fieldDefinition.value = `${formatNumber(row[latestYear]?.value)} ${
              row[latestYear]?.unit ?? ''
            }`.trim()
            break
        }
        return fieldDefinition
      }),
    ]
  }, [tableData, fiscalYears, formatMoney, formatNumber, t, formatDate])

  const header = <ClickableCardHeader titleText={t('title')} href={'./fiscal-information'} />

  return (
    <DisplayCardView
      cardHeader={header}
      isLoading={isLoading}
      isError={isError}
      cardHeaderTitle={t('title')}
      fieldDefinitions={fieldDefinitions}
      setEditMode={() => {}}
      isEditable={false}
    />
  )
}

export default BusinessPartnerOverviewFinancialIndicatorsTile
