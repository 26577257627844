import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useCovenantDefinitions = (options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/covenants/configurations/definitions`,
      useCache: true,
      translated: true,
      keys: ['covenants', 'definitions'],
      options: {
        ...options,
      },
    }),
  )

export default useCovenantDefinitions
