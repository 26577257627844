import { Link, Text } from '@fioneer/ui5-webcomponents-react'
import parse from 'html-react-parser'
import PropType from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/text/TextEllipsis.module.css'

const htmlEndTagStartTagRegEx = /((<\/)\w+(>))(<)/g
const emptyDescription = '<p><br></p>'
const defaultMaxContentDisplaySize = 400
const dotsSize = 3
const defaultMaxShortenedContentDisplaySize = 100

const TextEllipsis = ({
  content,
  maxContentDisplaySize = defaultMaxContentDisplaySize,
  maxShortenedContentDisplaySize = defaultMaxShortenedContentDisplaySize,
  ...props
}) => {
  const newLineBetweenEndAndStartTagReplacer = (
    _match,
    completeEndTag,
    _endTagFirstPart,
    _endTagSecondPart,
    startTag,
    _offset,
    _string,
  ) => `${completeEndTag}\n${startTag}`

  const splitDescriptionLines = (description) =>
    description
      .replaceAll(htmlEndTagStartTagRegEx, newLineBetweenEndAndStartTagReplacer)
      .split('\n')

  const shortenDescription = (description) =>
    description.substring(0, maxShortenedContentDisplaySize - dotsSize)

  const formatDescriptionOnShowMoreClicked = (description) =>
    description.length > maxContentDisplaySize
      ? parse(description.substring(0, maxContentDisplaySize - dotsSize) + '...')
      : parse(description)

  const { t } = useTranslation('translation')
  const [showMore, setShowMore] = useState(false)

  if (!content || content === emptyDescription) {
    return <Text {...props}>{'-'}</Text>
  }

  const descriptionLines = splitDescriptionLines(content)
  const handleMoreClick = (event) => {
    event.preventDefault()
    setShowMore(true)
  }

  if (
    showMore ||
    (descriptionLines.length < 2 && descriptionLines[0].length <= maxShortenedContentDisplaySize)
  ) {
    const displayedText = formatDescriptionOnShowMoreClicked(content)

    return <Text {...props}>{displayedText}</Text>
  }

  const displayedText = shortenDescription(descriptionLines[0] + descriptionLines[1])

  return (
    <>
      <Text className={styles['descriptionText']} {...props}>
        {parse(displayedText)}
        {'...'}
        <Link className={styles['moreLink']} href="" onClick={handleMoreClick}>
          {t('buttons.more')}
        </Link>
      </Text>
    </>
  )
}

TextEllipsis.propTypes = {
  content: PropType.string,
  maxShortenedContentDisplaySize: PropType.number,
  maxContentDisplaySize: PropType.number,
}

export default TextEllipsis
