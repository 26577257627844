import { FlexBox, FlexBoxDirection, FlexBoxAlignItems } from '@fioneer/ui5-webcomponents-react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/loan-market-statement/shared/ConvertedAmount.module.css'
import { useCustomizableCurrencyFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/loan-market-statement/shared/hooks/useI18n'

const ConvertedAmount = (amount, headquarterCurrency) => {
  const formatMoney = useCustomizableCurrencyFormatter()

  return (
    <FlexBox
      fitContainer
      direction={FlexBoxDirection.Column}
      alignItems={FlexBoxAlignItems.End}
      className={styles.convertedAmountWrapper}
    >
      <div
        id="info-in-headquarter-currency"
        className={styles.textLabelColor}
        data-testid="info-in-headquarter-currency"
      >
        {formatMoney(amount, headquarterCurrency, { currencyDisplay: 'code' })}
      </div>
    </FlexBox>
  )
}

export default ConvertedAmount
