export const PROPERTY_AND_PORTFOLIO_OBJECT_TYPE = ['PORTFOLIO', 'PROPERTY']
export const GLOBAL_OBJECT_TYPE = ['GLOBAL']
export const CASH_FLOW_START_DATE = 'cash_flow_start_date'
export const APPLIED_LEASE_END = 'Applied Lease End'
export const CASH_FLOW_START_DATE_LABEL = 'Cash Flow Start Date'
export const PROPERTY_AND_PORTFOLIO_PARAMETERS = [
  'Re-let/ Reviewed',
  'New Lease Length',
  'Rent-Free Period',
  'Void Period',
  'Void Costs',
  'Empty Rates (UK only)',
]
