import { useQuery } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const usePropertyUuids = (propertyUuids, options = {}, shouldBeCamelized = false) => {
  const { post } = useAccessTokenRequest()

  const result = useQuery({
    queryKey: ['properties', 'uuids', ...propertyUuids],
    refetchOnWindowFocus: false,
    queryFn: () =>
      post({
        path: `/properties/uuids`,
        body: { property_uuids: propertyUuids },
      }),
    retry: false,
    ...options,
  })
  const camelizedResponse = useCamelizedResponse(result)
  return shouldBeCamelized ? camelizedResponse : result
}

export default usePropertyUuids
