import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  tiles: {},
  hiddenTiles: {},
  tileMetadata: {},
  allTilesInitialized: false,
  someTilesAreLoading: false,
  someTilesHaveTechnicalError: false,
  someTilesHavePermissionError: false,
}

// Setting isLoading true to have this as fallback to not have null pointers
// or anything similar
const initialTileState = {
  isLoading: true,
  isError: false,
  error: undefined,
  isExpanded: true,
  data: undefined,
  type: undefined,
  isEditableInMinorVersion: false,
}

const initialTileHiddenState = {
  isHidden: false,
}

const initialTileMetadataState = {}

export const tilesOverviewSlice = createSlice({
  name: 'tiles-overview',
  initialState,
  reducers: {
    initializeTile: (state, { payload: { tileId, type } }) => {
      state.tiles = { ...state.tiles, [tileId]: { ...initialTileState, type } }
    },
    hideTile: (state, { payload: { tileId } }) => {
      state.hiddenTiles = {
        ...state.hiddenTiles,
        [tileId]: { ...initialTileHiddenState, isHidden: true },
      }
    },
    showTile: (state, { payload: { tileId } }) => {
      delete state.hiddenTiles[tileId]
    },
    setTileMetadata: (state, { payload: { tileCode, metadata } }) => {
      state.tileMetadata = {
        ...state.tileMetadata,
        [tileCode]: { ...initialTileMetadataState, ...metadata },
      }
    },
    resetTileMetadata: (state, { payload: { tileCode } }) => {
      delete state.tileMetadata[tileCode]
    },
    tileIsLoading: (state, { payload: tileId }) => {
      state.tiles = {
        ...state.tiles,
        [tileId]: {
          ...state.tiles[tileId],
          isLoading: true,
          isError: false,
          error: undefined,
          data: undefined,
          isEditableInMinorVersion: false,
        },
      }
    },
    tilesAreLoading: (state) => {
      state.someTilesAreLoading = true
    },
    tilesFinishedLoading: (state) => {
      state.someTilesAreLoading = false
    },
    tileHasError: (state, { payload: { id: tileId, error: tileError } }) => {
      state.tiles = {
        ...state.tiles,
        [tileId]: {
          ...state.tiles[tileId],
          isError: true,
          error: {
            ...state.tiles[tileId]?.error,
            ...tileError,
          },
          isLoading: false,
          data: undefined,
          isEditableInMinorVersion: false,
        },
      }
    },
    noTilesHaveTechnicalError: (state) => {
      state.someTilesHaveTechnicalError = false
    },
    someTilesHaveTechnicalError: (state) => {
      state.someTilesHaveTechnicalError = true
    },
    noTilesHavePermissionError: (state) => {
      state.someTilesHavePermissionError = false
    },
    someTilesHavePermissionError: (state) => {
      state.someTilesHavePermissionError = true
    },
    tileDataLoaded: (
      state,
      { payload: { id: tileId, data: tileData, isEditableInMinorVersion, version, ...customData } },
    ) => {
      state.tiles = {
        ...state.tiles,
        [tileId]: {
          ...state.tiles[tileId],
          isError: false,
          error: undefined,
          isLoading: false,
          data: tileData,
          isEditableInMinorVersion,
          version,
          ...customData,
        },
      }
    },
    expandTile: (state, { payload: tileId }) => {
      state.tiles = { ...state.tiles, [tileId]: { ...state.tiles[tileId], isExpanded: true } }
    },
    expandTiles: (state, { payload: tileIds }) => {
      tileIds.forEach((tileId) => {
        state.tiles[tileId] = { ...state.tiles[tileId], isExpanded: true }
      })
    },
    collapseTile: (state, { payload: tileId }) => {
      state.tiles = { ...state.tiles, [tileId]: { ...state.tiles[tileId], isExpanded: false } }
    },
    collapseTiles: (state, { payload: tileIds }) => {
      tileIds.forEach((tileId) => {
        state.tiles[tileId] = { ...state.tiles[tileId], isExpanded: false }
      })
    },
    expandAllTiles: (state) => {
      const tileKeys = Object.keys(state.tiles)
      tileKeys.forEach((tileKey) => {
        state.tiles[tileKey] = { ...state.tiles[tileKey], isExpanded: true }
      })
    },
    collapseAllTiles: (state) => {
      const tiles = { ...state.tiles }
      Object.keys(tiles).forEach((tileKey) => {
        tiles[tileKey] = { ...tiles[tileKey], isExpanded: false }
      })
      state.tiles = tiles
    },
    allTilesInitialized: (state) => {
      state.allTilesInitialized = true
    },
    addVersions: (state, { payload: { tileId, versions } }) => {
      state.tiles[tileId] = {
        ...state.tiles[tileId],
        versions,
      }
    },
    showTileVersion: (state, { payload: { tileId, displayedVersion } }) => {
      state.tiles[tileId] = {
        ...state.tiles[tileId],
        displayedVersion,
      }
    },
    showCurrentTileVersion: (state, { payload: tileId }) => {
      state.tiles[tileId] = {
        ...state.tiles[tileId],
        displayedVersion: 'current',
      }
    },
    setSourceRender: (state, { payload: { tileId, sourceRender } }) => {
      const tileState = state.tiles[tileId]
      state.tiles[tileId] = {
        ...tileState,
        data: {
          ...tileState.data,
          sourceRender,
        },
      }
    },
    resetTiles: () => initialState,
  },
})
export const {
  initializeTile,
  tileIsLoading,
  tilesAreLoading,
  tilesFinishedLoading,
  tileHasError,
  noTilesHaveTechnicalError,
  someTilesHaveTechnicalError,
  noTilesHavePermissionError,
  someTilesHavePermissionError,
  tileDataLoaded,
  expandTile,
  expandTiles,
  collapseTile,
  collapseTiles,
  expandAllTiles,
  collapseAllTiles,
  allTilesInitialized,
  addVersions,
  showTileVersion,
  showCurrentTileVersion,
  setSourceRender,
  resetTiles,
  hideTile,
  showTile,
  setTileMetadata,
  resetTileMetadata,
} = tilesOverviewSlice.actions

export default tilesOverviewSlice.reducer
