import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Icon,
  Link,
  Text,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import getPropertyRentalUnitsTableContentDefinitions, {
  getPropertyRentRollAuxiliaryFieldsContentDefinitions,
  getPropertyRentRollHeaderContentDefinitions,
} from 'components/domains/properties/getPropertyRentRollWorkingVersionReferenceData'
import styles from 'components/domains/properties/rent-roll/working-version/validation-results-summary/PropertyRentRollWorkingVersionValidationResultsEntry.module.css'

const PropertyRentRollWorkingVersionValidationResultsEntry = ({
  validationResultsEntry,
  handleSelectRows,
}) => {
  const { message, state, column, amount, rowNumbers } = validationResultsEntry
  const { t: tRentalUnits } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.table',
  })
  const { t: tHeader } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.header',
  })
  const { t: tAuxiliaryFields } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.auxiliary-fields',
  })
  const contentDefsRentalUnits = getPropertyRentalUnitsTableContentDefinitions(tRentalUnits)
  const contentDefsHeader = getPropertyRentRollHeaderContentDefinitions(tHeader)
  const contentDefsAuxiliaryFields =
    getPropertyRentRollAuxiliaryFieldsContentDefinitions(tAuxiliaryFields)

  const columnDef = [
    ...contentDefsRentalUnits,
    ...contentDefsHeader,
    ...contentDefsAuxiliaryFields,
  ].find((def) => def.contentKey === column)
  const isRentalUnitTableColumn = !['description', 'key_date'].includes(
    validationResultsEntry.column,
  )

  return (
    <FlexBox
      direction={FlexBoxDirection.Row}
      alignItems={FlexBoxAlignItems.Center}
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      className={styles.entry}
      onClick={() => isRentalUnitTableColumn && handleSelectRows(rowNumbers)}
    >
      <Icon name={state === 'error' ? 'error' : 'alert'} className={styles[`icon-${state}`]} />
      <FlexBox
        direction={FlexBoxDirection.Column}
        className={styles['message-and-column-container']}
      >
        <Link className={styles.message} wrappingType={WrappingType.Normal}>
          {message}
        </Link>
        <Text className={styles.column}>{columnDef ? columnDef.title : column}</Text>
      </FlexBox>
      <Text className={styles.amount}>{amount}</Text>
    </FlexBox>
  )
}

PropertyRentRollWorkingVersionValidationResultsEntry.propTypes = {
  validationResultsEntry: PropTypes.shape({
    message: PropTypes.string,
    state: PropTypes.oneOf(['error', 'warning']),
    column: PropTypes.string,
    amount: PropTypes.number,
    rowNumbers: PropTypes.arrayOf(PropTypes.number),
  }),
  handleSelectRows: PropTypes.func,
}

export default PropertyRentRollWorkingVersionValidationResultsEntry
