import { Button, ButtonDesign, Menu, MenuItem } from '@fioneer/ui5-webcomponents-react'
import { useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import { useDeleteWorkingVersion } from 'components/domains/deals/deal-adjustment/delete/useDeleteWorkingVersion'
import EditDealAdjustmentDialog from 'components/domains/deals/deal-adjustment/edit/EditDealAdjustmentDialog'
import PublishWorkingVersionDialog from 'components/domains/deals/deal-adjustment/publish/PublishWorkingVersionDialog'
import { DealContext } from 'routes/deals/DealContext'

const DealManageWorkingVersionButton = () => {
  const { t: tNoPrefix } = useTranslation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const editDialogRef = useRef(null)
  const publishDialogRef = useRef(null)
  const { onDelete } = useDeleteWorkingVersion()
  const {
    deal: { dealUuid },
    allowedOperations,
  } = useContext(DealContext)

  const isAllowedDeleteWorkingVersion = allowedOperations.includes(
    dealsPermissions.deleteDealWorkingVersion,
  )
  const isAllowedPublishWorkingVersion = allowedOperations.includes(
    dealsPermissions.publishDealWorkingVersion,
  )

  const refMenu = useRef()
  const refButton = useRef()
  const onClickMenu = () => {
    if (refMenu.current) {
      setIsMenuOpen((prev) => !prev)
      if (isMenuOpen) {
        refMenu.current?.close()
      } else {
        refMenu.current?.showAt(refButton.current)
      }
    }
  }
  const onClickMenuItem = ({ detail: { item: pressedMenuItem } }) => {
    setIsMenuOpen(false)
    const pressedIdentifier = pressedMenuItem.getAttribute('data-button-id')
    switch (pressedIdentifier) {
      case 'working-version-delete-btn':
        onDelete()
        return
      case 'working-version-details-btn':
        editDialogRef.current?.show?.()
        return
      case 'working-version-publish-btn':
        publishDialogRef.current?.show?.()
        return
    }
  }

  return (
    !!dealUuid && (
      <>
        <Button
          ref={refButton}
          id={'manage-working-version-btn'}
          design={ButtonDesign.Default}
          onClick={onClickMenu}
          icon="slim-arrow-down"
          iconEnd
        >
          {tNoPrefix('components.deals.working-version-types.working-version')}
        </Button>
        <Menu
          ref={refMenu}
          open={isMenuOpen}
          onItemClick={onClickMenuItem}
          onAfterClose={() => setIsMenuOpen(false)}
        >
          <MenuItem
            text={tNoPrefix('buttons.show-details')}
            data-button-id={'working-version-details-btn'}
          />
          {isAllowedPublishWorkingVersion && (
            <MenuItem
              text={tNoPrefix('buttons.publish')}
              data-button-id={'working-version-publish-btn'}
            />
          )}
          {isAllowedDeleteWorkingVersion && (
            <MenuItem
              text={tNoPrefix('buttons.delete')}
              data-button-id={'working-version-delete-btn'}
            />
          )}
        </Menu>
        <EditDealAdjustmentDialog dealUuid={dealUuid} ref={editDialogRef} />
        <PublishWorkingVersionDialog dealUuid={dealUuid} ref={publishDialogRef} />
      </>
    )
  )
}

export default DealManageWorkingVersionButton

DealManageWorkingVersionButton.propTypes = {}
