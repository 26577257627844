import { DatePicker } from '@fioneer/ui5-webcomponents-react'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/edit-mode-cells/CustomerOptionsDatePicker.module.css'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const useDatePickerMinWidth = (datePickerRef) => {
  const [rerender, setRerender] = useState(false)

  useEffect(() => {
    const inputInShadowRoot = datePickerRef.current?.shadowRoot?.querySelector('ui5-input')
    if (inputInShadowRoot) {
      inputInShadowRoot.style.minWidth = 0
    }
    if (!rerender) {
      setRerender(true)
    }
  }, [rerender, datePickerRef])
}
export const CustomerOptionsDatePicker = ({ valueObj, handleOnChange }) => {
  const { localePattern, parse } = useShortDateFormatter()
  const { value } = valueObj
  const { t } = useTranslation('decisionPaper')

  const handleDatePickerUpdate = (event) => {
    const newValue = event.detail.value
    if (newValue === '') {
      handleOnChange({ value: newValue, isValid: true })
    } else if (event.detail.valid) {
      const isoDate = parse(event.detail.value, localePattern)
      handleOnChange({ value: isoDate, isValid: true })
    } else {
      handleOnChange({ value: newValue, isValid: false })
    }
  }
  const ref = useRef(null)
  useDatePickerMinWidth(ref)

  return (
    <DatePicker
      ref={ref}
      id={'customerOptionsTable-datePicker'}
      className={styles.datePicker}
      isValid={(dateValue) => DateTime.fromFormat(dateValue, localePattern).isValid}
      value={value}
      valueStateMessage={
        <span>{t('pages.deals.customerOptions-table.date-label.hint.invalid')}</span>
      }
      formatPattern={localePattern}
      placeholder={localePattern}
      onFocus={(event) => event.stopPropagation()}
      onChange={handleDatePickerUpdate}
    />
  )
}
CustomerOptionsDatePicker.propTypes = {
  valueObj: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func.isRequired,
}
