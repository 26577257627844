import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ValuationRequestMultiEditDateSelect from 'components/domains/properties/valuation/mass-edit/fields/ValuationRequestMultiEditDateSelectField'
import Labeled from 'components/ui/data/Labeled'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const valuationDateFieldId = 'valuationRequestMultiEditValuationDateField'

const ValuationRequestMultiEditValuationDateField = ({ onChange }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.properties.valuation.multi-edit.fields',
  })
  const { format: dateFormat } = useShortDateFormatter()

  const formattedDatePickerMaxDate = useMemo(
    () => dateFormat(new Date().toISOString()),
    [dateFormat],
  )
  return (
    <Labeled id={valuationDateFieldId} label={t('valuation-date.label')} vertical showColon>
      <ValuationRequestMultiEditDateSelect
        id={valuationDateFieldId}
        onChange={onChange}
        maxDate={formattedDatePickerMaxDate}
      />
    </Labeled>
  )
}

ValuationRequestMultiEditValuationDateField.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default ValuationRequestMultiEditValuationDateField
