import { FlexBoxJustifyContent } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { UI5_TABLE_CELL_PADDING } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/display-and-edit-table/constants'

const getDefaultColumnDefinition = (columnKey, t) => ({
  columnKey: columnKey,
  title: t(columnKey),
  popinText: t(columnKey),
  demandPopin: true,
})

const useRiskIndicatorsGroupBusinessEventsColumnDefinitions = () => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix:
      'pages.business-partner.risk-monitoring.risk-indicators.group-details.business-events.label',
  })

  const columns = useMemo(
    () => ({
      businessEvent: {
        minWidth: 200,
        demandPopin: false,
      },
      creationDate: {
        alignment: FlexBoxJustifyContent.End,
        demandPopin: true,
        minWidth: 100,
      },
      dueDate: {
        alignment: FlexBoxJustifyContent.End,
        demandPopin: true,
        minWidth: 100,
      },
      assignee: {
        demandPopin: true,
        minWidth: 150,
      },
      businessEventStatus: {
        demandPopin: true,
        minWidth: 100,
      },
      decisionStatus: {
        demandPopin: true,
        minWidth: 100,
      },
      decisionDate: {
        alignment: FlexBoxJustifyContent.End,
        demandPopin: true,
        minWidth: 100,
      },
      decisionPaperStatus: {
        demandPopin: true,
        minWidth: 100,
      },
    }),
    [],
  )

  return useMemo(() => {
    let minWidthSum = 0
    return Object.keys(columns).map((columnKey) => {
      // we still add each column's minWidth to the sum,
      // because we need the overall width relative to the table width for the breakpoint
      minWidthSum += (columns[columnKey].minWidth ?? 0) + UI5_TABLE_CELL_PADDING
      return {
        ...getDefaultColumnDefinition(columnKey, t),
        ...columns[columnKey],
        minWidth: minWidthSum,
      }
    })
  }, [columns, t])
}

export default useRiskIndicatorsGroupBusinessEventsColumnDefinitions
