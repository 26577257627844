import { MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import DialogPrimaryButton from 'components/ui/dialog/DialogPrimaryButton'
import { MessageBoxActions, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useDeleteDecisionPaper from 'hooks/services/business-events-and-tasks/decision-papers/useDeleteDecisionPaper'
import { resetTiles } from 'redux/slices/decision-paper/tilesOverviewSlice'

const useDeleteDecisionPaperFlow = ({ eventId, onSuccessfulDelete }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper',
  })
  const { t: tNoPrefix } = useTranslation()
  const showMessageBox = useShowMessageBox()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { mutate: deleteDecisionPaper } = useDeleteDecisionPaper()

  const onDeleteSuccess = useCallback(() => {
    queryClient.invalidateQueries(['events', eventId])
    dispatch(resetTiles())
    onSuccessfulDelete()
  }, [dispatch, eventId, onSuccessfulDelete, queryClient])

  const onDeleteError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('delete.error'),
    })
  }, [showMessageBox, t])

  const handleDeleteConfirmation = useCallback(() => {
    deleteDecisionPaper(
      { eventId },
      {
        onSuccess: onDeleteSuccess,
        onError: onDeleteError,
      },
    )
  }, [deleteDecisionPaper, eventId, onDeleteError, onDeleteSuccess])
  const handleDeleteButtonPressed = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Confirm,
      children: t('delete.confirmation'),
      actions: [
        <DialogPrimaryButton key="confirm" onClick={handleDeleteConfirmation}>
          {tNoPrefix('buttons.confirm')}
        </DialogPrimaryButton>,
        MessageBoxActions.Cancel,
      ],
    })
  }, [handleDeleteConfirmation, showMessageBox, t, tNoPrefix])

  return useMemo(
    () => ({
      handleDeleteButtonPressed,
    }),
    [handleDeleteButtonPressed],
  )
}

export default useDeleteDecisionPaperFlow
