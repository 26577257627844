import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useStore } from 'react-redux'
import { decisionPaperSynchronizationStatus } from 'api/decision-paper/decisionPaperApi'
import {
  errorTypes,
  mapTileDataToSaveCall,
} from 'hooks/services/business-events-and-tasks/decision-papers/routes/syncDecisionPaperUtilities'
import useCurrentDecisionPaper from 'hooks/services/business-events-and-tasks/decision-papers/useCurrentDecisionPaper'
import useEditDecisionPaper from 'hooks/services/business-events-and-tasks/decision-papers/useEditDecisionPaper'
import { resetTiles } from 'redux/slices/decision-paper/tilesOverviewSlice'

const tileDataStateSelector = (state) => state.decisionPaper.tilesOverview.tiles
const tileImagesStateSelector = (state) => state.decisionPaper.imagesForTiles.tiles

const useSyncDecisionPaperFlow = ({
  eventId,
  onSuccessfulSynchronization,
  currentSynchronizationStatus,
  onSynchronizationError,
  isMinorVersion,
}) => {
  const [isDecisionPaperSynced, setIsDecisionPaperSynced] = useState(true)
  const [isSynchronizationConfirmationDialogOpen, setIsSynchronizationConfirmationDialogOpen] =
    useState(false)
  const [isEditCallLoading, setIsEditCallLoading] = useState(false)

  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { data: decisionPaper } = useCurrentDecisionPaper(eventId, {
    enabled: currentSynchronizationStatus !== decisionPaperSynchronizationStatus.frozen,
  })
  const { mutate: editDecisionPaper } = useEditDecisionPaper()
  const reduxStore = useStore()

  useEffect(() => {
    if (decisionPaper?.synchronizationStatus) {
      setIsDecisionPaperSynced(
        decisionPaper.synchronizationStatus === decisionPaperSynchronizationStatus.live,
      )
    }
  }, [decisionPaper])

  const onSyncStatusChangedSuccess = useCallback(
    (newStatus) => () => {
      dispatch(resetTiles())
      setIsEditCallLoading(false)
      setIsDecisionPaperSynced((currentState) => !currentState)
      setIsSynchronizationConfirmationDialogOpen(false)
      queryClient.invalidateQueries(['events', eventId])
      onSuccessfulSynchronization(newStatus)
    },
    [onSuccessfulSynchronization, queryClient, eventId, dispatch],
  )

  const onSyncStatusChangedError = useCallback(() => {
    setIsEditCallLoading(false)
    onSynchronizationError({ type: errorTypes.technical, failingTileIds: [] })
  }, [onSynchronizationError])

  const handleSynchronizationClicked = useCallback(() => {
    const currentState = reduxStore.getState()
    const tileDataState = tileDataStateSelector(currentState)
    const imageDataState = tileImagesStateSelector(currentState)
    const {
      data: tileData,
      loadingTileIds,
      errorTileIds,
      hasLoadingTiles,
      hasErrorTiles,
    } = mapTileDataToSaveCall(tileDataState, imageDataState, isMinorVersion)

    if (!isDecisionPaperSynced) {
      setIsSynchronizationConfirmationDialogOpen(true)
      return
    }

    if (hasLoadingTiles) {
      onSynchronizationError({
        type: errorTypes.tilesLoading,
        failingTileIds: loadingTileIds,
      })
      return
    }

    if (hasErrorTiles) {
      onSynchronizationError({
        type: errorTypes.tilesError,
        failingTileIds: errorTileIds,
      })
      return
    }
    setIsEditCallLoading(true)
    editDecisionPaper(
      {
        eventId,
        synchronizationStatus: decisionPaperSynchronizationStatus.stopped,
        tileData,
      },
      {
        onSuccess: onSyncStatusChangedSuccess(decisionPaperSynchronizationStatus.stopped),
        onError: onSyncStatusChangedError,
      },
    )
  }, [
    reduxStore,
    isDecisionPaperSynced,
    editDecisionPaper,
    eventId,
    onSyncStatusChangedSuccess,
    onSyncStatusChangedError,
    onSynchronizationError,
    isMinorVersion,
  ])

  const handleSynchronizationConfirmation = useCallback(() => {
    setIsEditCallLoading(true)
    editDecisionPaper(
      {
        eventId,
        synchronizationStatus: decisionPaperSynchronizationStatus.live,
      },
      {
        onSuccess: onSyncStatusChangedSuccess(decisionPaperSynchronizationStatus.live),
        onError: onSyncStatusChangedError,
      },
    )
    return
  }, [editDecisionPaper, eventId, onSyncStatusChangedSuccess, onSyncStatusChangedError])

  const handleSynchronizationCancel = useCallback(() => {
    setIsSynchronizationConfirmationDialogOpen(false)
  }, [])

  return {
    isDecisionPaperSynced,
    isSynchronizationConfirmationDialogOpen,
    isEditCallLoading,
    handleSynchronizationConfirmation,
    handleSynchronizationClicked,
    handleSynchronizationCancel,
  }
}

export default useSyncDecisionPaperFlow
