import isNil from 'lodash.isnil'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @typedef {Record<string, string>} EnumValue
 * @typedef {{type: string}} ReferenceEntity
 * @typedef {{status: string, breach: string, monitoringReferenceEntity: ReferenceEntity}} CovenantCheck
 *
 * @typedef Props
 * @property {Array<CovenantCheck>} covenantChecks
 *
 * @param {Props} props
 * @returns {{statusEnum: EnumValue, breachEnum: EnumValue, monitoringReferenceTypeEnum: EnumValue}}
 */
const useCovenentCheckSelectionEnums = ({ covenantChecks }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.covenant-check-selection.table.enum',
  })
  return useMemo(() => {
    const statusValues = new Set()
    const breachValues = new Set()
    const referenceTypeVaues = new Set()
    for (const { status, monitoringReferenceEntity, breach } of covenantChecks ?? []) {
      if (!isNil(status)) {
        statusValues.add(status)
      }
      if (!isNil(breach)) {
        breachValues.add(breach)
      }
      if (!isNil(monitoringReferenceEntity?.type)) {
        referenceTypeVaues.add(monitoringReferenceEntity?.type)
      }
    }

    return {
      statusEnum: Object.fromEntries(
        Array.from(statusValues).map((value) => [t(`status.${value}`), t(`status.${value}`)]),
      ),
      breachEnum: Object.fromEntries(
        Array.from(breachValues).map((value) => [t(`breach.${value}`), t(`breach.${value}`)]),
      ),
      monitoringReferenceTypeEnum: Object.fromEntries(
        Array.from(referenceTypeVaues).map((value) => [
          t(`monitoring-reference-type.${value}`),
          t(`monitoring-reference-type.${value}`),
        ]),
      ),
    }
  }, [covenantChecks, t])
}

export default useCovenentCheckSelectionEnums
