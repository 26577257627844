import { useMutation, useQueryClient } from '@tanstack/react-query'
import { limitPostRequest } from 'api/pLimitFactory'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import postPropertyValuationCreation from 'hooks/services/properties/valuations/creation/postPropertyValuationCreation'

export const useMultiplePropertyValuationCreation = ({
  propertyId,
  onSuccess: innerOnSuccess,
  onError,
}) => {
  const { post } = useAccessTokenRequest()
  const queryClient = useQueryClient()
  return useMutation(
    ({ valuationResults }) =>
      Promise.allSettled(
        valuationResults.map(
          ({ calculationMethodCode, value, currency, keyDate, validFrom, validTo }) =>
            limitPostRequest(() =>
              postPropertyValuationCreation({
                propertyId,
                postMethod: post,
                calculationMethodCode,
                value,
                currency,
                keyDate,
                validFrom,
                validTo,
              }),
            ),
        ),
      ),
    {
      onSuccess: (params) => {
        queryClient.invalidateQueries({ queryKey: ['valuations', propertyId] })
        innerOnSuccess(params)
      },
      onError,
    },
  )
}
