import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  useShowMessageBox,
  MessageBoxActions,
  MessageBoxTypes,
} from 'components/ui/message-box/MessageBox'
import useMarketArchiving from 'hooks/services/markets/useMarketArchiving'
import paths from 'routes/paths'

const MarketDetailArchiveButton = ({ marketId }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.markets.detail' })
  const { t: tNoPrefix } = useTranslation()

  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const showMessageBox = useShowMessageBox()

  const showErrorMessage = useCallback(
    () =>
      showMessageBox({
        type: MessageBoxTypes.Error,
        titleText: t('archive.error.title'),
        children: t('archive.error.description'),
        actions: [MessageBoxActions.OK],
      }),
    [showMessageBox, t],
  )

  const { mutate: archiveMarket } = useMarketArchiving({
    onSuccess: () => {
      queryClient.invalidateQueries('markets')
      navigate(`/${paths.markets}`)
    },
    onError: () => {
      showErrorMessage()
    },
  })

  const onArchiveMarketDialogConfirmation = useCallback(() => {
    archiveMarket(marketId)
  }, [archiveMarket, marketId])

  const showConfirmationDialog = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Warning,
      titleText: t('archive.dialog.title'),
      children: t('archive.dialog.message'),
      actions: [
        <Button
          key="button-confirm"
          design={ButtonDesign.Emphasized}
          onClick={onArchiveMarketDialogConfirmation}
        >
          {t('archive.dialog.button.confirm')}
        </Button>,
        <Button key="button-cancel">{tNoPrefix('buttons.cancel')}</Button>,
      ],
    })
  }, [onArchiveMarketDialogConfirmation, showMessageBox, t, tNoPrefix])

  return (
    <Button design={ButtonDesign.Default} onClick={showConfirmationDialog}>
      {t('buttons.archive')}
    </Button>
  )
}

MarketDetailArchiveButton.propTypes = {
  marketId: PropTypes.string.isRequired,
}

export default MarketDetailArchiveButton
