import {
  Popover,
  PopoverPlacementType,
  PopoverHorizontalAlign,
  List,
  StandardListItem,
  Text,
} from '@fioneer/ui5-webcomponents-react/dist'
import PropTypes from 'prop-types'
import { useState, useContext, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { isMajorVersion } from 'api/decision-paper/decisionPaperApi'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import { isEventClosed } from 'api/events/status'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import DecisionPaperChangeTemplateDialog from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperChangeTemplateDialog'
import DecisionPaperChangeVersionDialog from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperChangeVersionDialog'
import DecisionPaperFreezeVersionDialog from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperFreezeVersionDialog'
import styles from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperPopovers.module.css'
import DecisionPaperTemplateInformation from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperTemplateInformation'
import DecisionPaperVersionInformation from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperVersionInformation'

const DecisionPaperWorkingVersionPopover = ({
  isPopoverOpen,
  setIsPopoverOpen,
  headerText,
  lastEditedOn,
  majorVersionLastSyncedOn,
  minorVersionLastSyncedOn,
  openerId,
  synchronizationStatus,
  versions,
  templateCode,
  existingDecisionPaperTemplate,
  templateName,
  templateIsCustomized,
  disabled,
  showFreezeButton: showFreezeButtonParam,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper.working-version-popover',
  })
  const [isChangeVersionDialogOpen, setIsChangeVersionDialogOpen] = useState(false)
  const [isFreezeVersionDialogOpen, setIsFreezeVersionDialogOpen] = useState(false)
  const [isChangeTemplateDialogOpen, setIsChangeTemplateDialogOpen] = useState(false)
  const [isFreezeLoading, setIsFreezeLoading] = useState(false)

  const {
    allowedOperations,
    event: { status: eventStatus },
  } = useContext(BusinessEventsAndTasksContext)

  const isFreezeDisabled = disabled || isFreezeLoading

  const showFreezeButton =
    showFreezeButtonParam &&
    allowedOperations.includes(businessEventAllowedOperations.decisionPaperFreeze) &&
    !isEventClosed(eventStatus)

  const showChangeTemplateButton =
    allowedOperations.includes(businessEventAllowedOperations.decisionPaperTemplateUpdate) &&
    !isEventClosed(eventStatus)

  const isLastVersionMajorVersion = useMemo(
    () => isMajorVersion(versions.slice(-1)[0].version),
    [versions],
  )

  const freezeButtonLabel = useMemo(
    () => (isLastVersionMajorVersion ? t('freeze-major') : t('freeze-minor')),
    [isLastVersionMajorVersion, t],
  )

  return createPortal(
    <>
      <Popover
        opener={openerId}
        open={isPopoverOpen}
        placementType={PopoverPlacementType.Bottom}
        horizontalAlign={PopoverHorizontalAlign.Left}
        onAfterClose={() => setIsPopoverOpen(false)}
        className={styles.popover}
      >
        <DecisionPaperTemplateInformation
          templateName={templateName}
          isCustomized={templateIsCustomized}
          headerText={headerText}
        />
        <List className={styles.popoverBreak}>
          {/* passing 'disabled' as property to StandardListItem does unfortunately not work because it does not rerender itself when 
              the variable changes, therefore this workaround is needed */}
          {showFreezeButton && !isFreezeDisabled && (
            <StandardListItem onClick={() => setIsFreezeVersionDialogOpen(true)}>
              <Text className="sapUiSmallMargin">{freezeButtonLabel}</Text>
            </StandardListItem>
          )}
          {showFreezeButton && isFreezeDisabled && (
            <StandardListItem data-testid={'freeze-button-list-element'} disabled={true}>
              <Text className="sapUiSmallMargin">{freezeButtonLabel}</Text>
            </StandardListItem>
          )}
          <StandardListItem onClick={() => setIsChangeVersionDialogOpen(true)}>
            <Text className="sapUiSmallMargin">{t('change')}</Text>
          </StandardListItem>
          {showChangeTemplateButton && (
            <StandardListItem onClick={() => setIsChangeTemplateDialogOpen(true)}>
              <Text className="sapUiSmallMargin">{t('edit-template')}</Text>
            </StandardListItem>
          )}
        </List>
        <DecisionPaperVersionInformation
          lastEditedOn={lastEditedOn}
          majorVersionLastSyncedOn={majorVersionLastSyncedOn}
          minorVersionLastSyncedOn={minorVersionLastSyncedOn}
          synchronizationStatus={synchronizationStatus}
        />
      </Popover>
      <DecisionPaperChangeVersionDialog
        isOpen={isChangeVersionDialogOpen}
        setIsOpen={setIsChangeVersionDialogOpen}
        versions={versions}
        setIsPopoverOpen={setIsPopoverOpen}
      />
      <DecisionPaperFreezeVersionDialog
        isOpen={isFreezeVersionDialogOpen}
        setIsOpen={setIsFreezeVersionDialogOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        setIsFreezeLoading={setIsFreezeLoading}
        synchronizationStatus={synchronizationStatus}
        isMinorVersion={!isLastVersionMajorVersion}
      />
      {isChangeTemplateDialogOpen && (
        <DecisionPaperChangeTemplateDialog
          isOpen={isChangeTemplateDialogOpen}
          setIsOpen={setIsChangeTemplateDialogOpen}
          templateCode={templateCode}
          currentTemplateName={templateName}
          setIsPopoverOpen={setIsPopoverOpen}
          existingDecisionPaperTemplate={existingDecisionPaperTemplate}
        />
      )}
    </>,
    document.body,
  )
}

DecisionPaperWorkingVersionPopover.propTypes = {
  isPopoverOpen: PropTypes.bool.isRequired,
  setIsPopoverOpen: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  openerId: PropTypes.string.isRequired,
  synchronizationStatus: PropTypes.string.isRequired,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      version: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  ).isRequired,
  templateCode: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired,
  templateIsCustomized: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  showSyncSwitchAndFreezeButton: PropTypes.bool,
  majorVersionLastSyncedOn: PropTypes.string,
  minorVersionLastSyncedOn: PropTypes.string,
  existingDecisionPaperTemplate: PropTypes.shape({
    code: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        subsections: PropTypes.arrayOf(
          PropTypes.shape({
            code: PropTypes.string.isRequired,
            tiles: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string.isRequired })),
          }),
        ),
        tiles: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string.isRequired })),
      }),
    ),
  }),
}

export default DecisionPaperWorkingVersionPopover
