import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Switch,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/input/LabeledSwitch.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'

/**
 * The Switch is sadly not taking the checked property into account once the switch is clicked.
 * If you need to perform async calculations and only then change the state of the switch we need
 * to rerender the Switch. This is why we are using a key so that react thinks that the switch is
 * a new component whenever we change the value so that the Switch takes the checked property as default again.
 */
const LabeledSwitch = ({
  checked,
  onChange,
  checkedText,
  isLoading,
  uncheckedText,
  ...switchProps
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.ui.input.labeled-switch' })
  const [internalKey, setInternalKey] = useState(0)

  const internalOnChange = (event) => {
    setInternalKey((previousKey) => previousKey + 1)
    onChange(event)
  }

  const calculatedCheckedText = useMemo(() => checkedText ?? t('yes'), [checkedText, t])
  const calculatedUncheckedText = useMemo(() => uncheckedText ?? t('no'), [uncheckedText, t])

  return (
    <FlexBox
      direction={FlexBoxDirection.Row}
      alignItems={FlexBoxAlignItems.Center}
      justifyContent={FlexBoxJustifyContent.Start}
    >
      <Text wrapping className={styles.switchText}>
        {checked ? calculatedCheckedText : calculatedUncheckedText}
      </Text>
      <SmallLoadingWrapper
        isLoading={isLoading}
        isError={false}
        error=" "
        renderContent={() => (
          <Switch
            key={internalKey}
            checked={checked}
            onChange={internalOnChange}
            {...switchProps}
          />
        )}
      />
    </FlexBox>
  )
}

LabeledSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  checkedText: PropTypes.string,
  uncheckedText: PropTypes.string,
}

export default LabeledSwitch
