import { MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import DebtPerEBITDACommentDisplay from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/leveraged-transaction/debt-per-ebitda-comment/DebtPerEBITDACommentDisplay'
import DebtPerEBITDACommentEdit from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/leveraged-transaction/debt-per-ebitda-comment/DebtPerEBITDACommentEdit'
import { Answer } from 'components/domains/business-events-and-tasks/decision-paper/tiles/new-business-checks/questionnaireStructure'

const Q1_KEY_PATH = ['Q1']
const Q2_1_KEY_PATH = ['Q2', 'Q2-1']
const Q2_2_KEY_PATH = ['Q2', 'Q2-2']
const Q3_KEY_PATH = ['Q3']
const Q4_KEY_PATH = ['Q4']
const Q5_KEY_PATH = ['Q5']
const Q6_KEY_PATH = ['Q6']
const Q7_1_KEY_PATH = ['Q7', 'Q7-1']
const Q7_2_KEY_PATH = ['Q7', 'Q7-2']
const Q8_1_KEY_PATH = ['Q8', 'Q8-1']
const Q8_2_KEY_PATH = ['Q8', 'Q8-2']
const Q8_3_KEY_PATH = ['Q8', 'Q8-3']
const Q8_4_KEY_PATH = ['Q8', 'Q8-4']
const Q8_5_KEY_PATH = ['Q8', 'Q8-5']
const Q8_6_KEY_PATH = ['Q8', 'Q8-6']
const Q8_7_KEY_PATH = ['Q8', 'Q8-7']
const Q8_8_KEY_PATH = ['Q8', 'Q8-8']
const Q8_9_KEY_PATH = ['Q8', 'Q8-9']
const Q8_10_KEY_PATH = ['Q8', 'Q8-10']
const Q8_11_KEY_PATH = ['Q8', 'Q8-11']
const Q8_12_KEY_PATH = ['Q8', 'Q8-12']
const Q9_1_KEY_PATH = ['Q9', 'Q9-1']
const Q9_2_KEY_PATH = ['Q9', 'Q9-2']
const Q9_3_KEY_PATH = ['Q9', 'Q9-3']
const Q9_4_KEY_PATH = ['Q9', 'Q9-4']
const Q9_5_KEY_PATH = ['Q9', 'Q9-5']
const Q9_6_KEY_PATH = ['Q9', 'Q9-6']
const Q10_1_KEY_PATH = ['Q10', 'Q10-1']
const Q11_KEY_PATH = ['Q11']
const Q11_1_KEY_PATH = ['Q11-1']
const Q12_KEY_PATH = ['Q12']
const Q12_1_KEY_PATH = ['Q12-1']

const PASS_KEY = 'PASS'
const PASS_KEY_PATH = [PASS_KEY]
const FAIL_INFLM_KEY = 'FAIL-INFLM'
const FAIL_INFLM_KEY_PATH = [FAIL_INFLM_KEY]
const FAIL_DONE_KEY = 'FAIL-DONE'
const FAIL_DONE_KEY_PATH = [FAIL_DONE_KEY]

export const questionnaireStructure = [
  {
    key: 'Q1',
    hint: true,
    alwaysVisible: true,
    proceedWith: [
      { key: PASS_KEY_PATH, condition: [{ keys: [Q1_KEY_PATH], result: Answer.YES }] },
      { key: Q2_1_KEY_PATH },
    ],
  },
  {
    key: 'Q2',
    hint: true,
    children: [
      {
        key: 'Q2-1',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q2_1_KEY_PATH], result: Answer.YES }] },
          { key: Q2_2_KEY_PATH },
        ],
      },
      {
        key: 'Q2-2',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q2_2_KEY_PATH], result: Answer.YES }] },
          { key: Q3_KEY_PATH },
        ],
      },
    ],
  },
  {
    key: 'Q3',
    hint: true,
    proceedWith: [
      { key: PASS_KEY_PATH, condition: [{ keys: [Q3_KEY_PATH], result: Answer.YES }] },
      { key: Q4_KEY_PATH },
    ],
  },
  {
    key: 'Q4',
    hint: true,
    proceedWith: [
      { key: PASS_KEY_PATH, condition: [{ keys: [Q4_KEY_PATH], result: Answer.YES }] },
      { key: Q5_KEY_PATH },
    ],
  },
  {
    key: 'Q5',
    hint: true,
    proceedWith: [
      { key: PASS_KEY_PATH, condition: [{ keys: [Q5_KEY_PATH], result: Answer.YES }] },
      { key: Q6_KEY_PATH },
    ],
  },
  {
    key: 'Q6',
    hint: true,
    proceedWith: [
      { key: PASS_KEY_PATH, condition: [{ keys: [Q6_KEY_PATH], result: Answer.YES }] },
      { key: Q7_1_KEY_PATH },
    ],
  },
  {
    key: 'Q7',
    hint: true,
    children: [
      {
        key: 'Q7-1',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q7_1_KEY_PATH], result: Answer.YES }] },
          { key: Q7_2_KEY_PATH },
        ],
      },
      {
        key: 'Q7-2',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q7_2_KEY_PATH], result: Answer.YES }] },
          { key: Q8_1_KEY_PATH },
        ],
      },
    ],
  },
  {
    key: 'Q8',
    hint: true,
    children: [
      {
        key: 'Q8-1',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q8_1_KEY_PATH], result: Answer.YES }] },
          { key: Q8_2_KEY_PATH },
        ],
      },
      {
        key: 'Q8-2',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q8_2_KEY_PATH], result: Answer.YES }] },
          { key: Q8_3_KEY_PATH },
        ],
      },
      {
        key: 'Q8-3',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q8_3_KEY_PATH], result: Answer.YES }] },
          { key: Q8_4_KEY_PATH },
        ],
      },
      {
        key: 'Q8-4',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q8_4_KEY_PATH], result: Answer.YES }] },
          { key: Q8_5_KEY_PATH },
        ],
      },
      {
        key: 'Q8-5',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q8_5_KEY_PATH], result: Answer.YES }] },
          { key: Q8_6_KEY_PATH },
        ],
      },
      {
        key: 'Q8-6',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q8_6_KEY_PATH], result: Answer.YES }] },
          { key: Q8_7_KEY_PATH },
        ],
      },
      {
        key: 'Q8-7',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q8_7_KEY_PATH], result: Answer.YES }] },
          { key: Q8_8_KEY_PATH },
        ],
      },
      {
        key: 'Q8-8',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q8_8_KEY_PATH], result: Answer.YES }] },
          { key: Q8_9_KEY_PATH },
        ],
      },
      {
        key: 'Q8-9',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q8_9_KEY_PATH], result: Answer.YES }] },
          { key: Q8_10_KEY_PATH },
        ],
      },
      {
        key: 'Q8-10',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q8_10_KEY_PATH], result: Answer.YES }] },
          { key: Q8_11_KEY_PATH },
        ],
      },
      {
        key: 'Q8-11',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q8_11_KEY_PATH], result: Answer.YES }] },
          { key: Q8_12_KEY_PATH },
        ],
      },
      {
        key: 'Q8-12',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q8_12_KEY_PATH], result: Answer.YES }] },
          { key: Q9_1_KEY_PATH },
        ],
      },
    ],
  },
  {
    key: 'Q9',
    hint: true,
    children: [
      {
        key: 'Q9-1',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q9_1_KEY_PATH], result: Answer.YES }] },
          { key: Q9_2_KEY_PATH },
        ],
      },
      {
        key: 'Q9-2',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q9_2_KEY_PATH], result: Answer.YES }] },
          { key: Q9_3_KEY_PATH },
        ],
      },
      {
        key: 'Q9-3',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q9_3_KEY_PATH], result: Answer.YES }] },
          { key: Q9_4_KEY_PATH },
        ],
      },
      {
        key: 'Q9-4',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q9_4_KEY_PATH], result: Answer.YES }] },
          { key: Q9_5_KEY_PATH },
        ],
      },
      {
        key: 'Q9-5',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q9_5_KEY_PATH], result: Answer.YES }] },
          { key: Q9_6_KEY_PATH },
        ],
      },
      {
        key: 'Q9-6',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q9_6_KEY_PATH], result: Answer.YES }] },
          { key: Q10_1_KEY_PATH },
        ],
      },
    ],
  },
  {
    key: 'Q10',
    hint: true,
    children: [
      {
        key: 'Q10-1',
        proceedWith: [
          { key: PASS_KEY_PATH, condition: [{ keys: [Q10_1_KEY_PATH], result: Answer.YES }] },
          { key: Q11_KEY_PATH },
        ],
      },
    ],
  },
  {
    key: 'Q11',
    hint: true,
    hasIndeterminableOption: true,
    commentEditComponent: DebtPerEBITDACommentEdit,
    commentDisplayComponent: DebtPerEBITDACommentDisplay,
    proceedWith: [
      { key: Q12_KEY_PATH, condition: [{ keys: [Q11_KEY_PATH], result: Answer.NO }] },
      { key: Q11_1_KEY_PATH },
    ],
    infos: [
      {
        type: 'INF-LTBYQ11',
        condition: [{ keys: [Q11_KEY_PATH], result: Answer.YES }],
      },
      {
        type: 'INF-LTBYQ11',
        condition: [{ keys: [Q11_KEY_PATH], result: Answer.INDETERMINABLE }],
      },
      {
        type: 'INF-CONTQ12',
        condition: [{ keys: [Q11_KEY_PATH], result: Answer.NO }],
      },
    ],
  },
  {
    key: 'Q11-1',
    proceedWith: [
      { key: FAIL_INFLM_KEY_PATH, condition: [{ keys: [Q11_1_KEY_PATH], result: Answer.YES }] },
      { key: FAIL_DONE_KEY_PATH },
    ],
  },
  {
    key: 'Q12',
    hint: true,
    proceedWith: [
      { key: PASS_KEY_PATH, condition: [{ keys: [Q12_KEY_PATH], result: Answer.NO }] },
      { key: Q12_1_KEY_PATH },
    ],
    infos: [
      {
        type: 'INF-LTBYQ12',
        condition: [{ keys: [Q12_KEY_PATH], result: Answer.YES }],
      },
    ],
  },
  {
    key: 'Q12-1',
    proceedWith: [
      { key: FAIL_INFLM_KEY_PATH, condition: [{ keys: [Q12_1_KEY_PATH], result: Answer.YES }] },
      { key: FAIL_DONE_KEY_PATH },
    ],
  },
]

const LEVERAGED_TRANSACTION_RESULT_DESCRIPTION = 'hasLeveragedTransaction'
export const availableEndings = [
  {
    key: PASS_KEY,
    messageStripDesign: MessageStripDesign.Positive,
    checkResultStatus: false,
    checkResultName: LEVERAGED_TRANSACTION_RESULT_DESCRIPTION,
  },
  {
    key: FAIL_INFLM_KEY,
    messageStripDesign: MessageStripDesign.Warning,
    checkResultStatus: true,
    checkResultName: LEVERAGED_TRANSACTION_RESULT_DESCRIPTION,
  },
  {
    key: FAIL_DONE_KEY,
    messageStripDesign: MessageStripDesign.Warning,
    checkResultStatus: true,
    checkResultName: LEVERAGED_TRANSACTION_RESULT_DESCRIPTION,
  },
]
