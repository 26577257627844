import { Link, MessageStrip, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { Trans } from 'react-i18next'
import styles from 'components/domains/deals/deal-adjustment/DealWorkingVersionMessageStrip.module.css'
import EditDealAdjustmentDialog from 'components/domains/deals/deal-adjustment/edit/EditDealAdjustmentDialog'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { DealContext } from 'routes/deals/DealContext'
import useNavigateToWorkingVersion from 'routes/deals/useNavigateToWorkingVersion'

const DealWorkingVersionMessageStrip = ({ ...props }) => {
  const {
    deal,
    dealHeaders: { live: liveDeal = {} },
  } = useContext(DealContext)
  const switchWorkingVersion = useNavigateToWorkingVersion()
  const dealLink = useMemo(
    () => (
      <Link
        onClick={() => switchWorkingVersion(WorkingVersionType.LIVE)}
        className={styles.dealLink}
      />
    ),
    [switchWorkingVersion],
  )

  if (deal.workingVersion !== WorkingVersionType.WORKING_VERSION) {
    return null
  }

  return (
    <MessageStrip hideCloseButton design={MessageStripDesign.Warning} {...props}>
      <Trans
        i18nKey="components.deals.deal-adjustment.working-version-strip.label"
        values={{ deal: liveDeal }}
        components={{ dealLink }}
        defaultTransParent
      />
    </MessageStrip>
  )
}

DealWorkingVersionMessageStrip.propTypes = {
  design: PropTypes.oneOf(Object.values(MessageStripDesign)),
  hideCloseButton: PropTypes.bool,
  className: PropTypes.string,
  dialogProps: PropTypes.shape({
    ...(EditDealAdjustmentDialog.propTypes || {}),
    dealUuid: PropTypes.string,
  }),
}

export default DealWorkingVersionMessageStrip
