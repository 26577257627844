import format from 'string-format'

export const stringTemplate = (string, params) => {
  if (!string) return ''

  format.extend(String.prototype, {})

  // remove $ because, after switching from lodash.template because of vulnerabilities to string-format,
  // the expected format is { ... } and not anymore ${ ... }
  let result = string.replaceAll('${', '{')

  // <%= id%> is converted to {id}
  result = result.replaceAll('<%= ', '{').replaceAll('%>', '}')

  // { id } is converted to {id}
  result = result.replaceAll('{ ', '{').replaceAll(' }', '}')

  return result.format(params)
}
