import { RISK_INDICATOR_TYPE_CODE_CATEGORIES } from 'components/domains/business-partners/tile/risk-monitoring/utils/typeCodes'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useEventsMiniByIdList from 'hooks/services/business-events-and-tasks/events/useEventsMiniByIdList'
import useRiskIndicatorGroupById from 'hooks/services/risk-monitoring/useRiskIndicatorGroupById'
import useRiskIndicators from 'hooks/services/risk-monitoring/useRiskIndicators'
import useTypeCodes from 'hooks/services/risk-monitoring/useTypeCodes'

const useRiskMonitoringCriticality = (
  { id: businessEventId, entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: riskIndicatorsData,
    isLoading: isRiskIndicatorsLoading,
    isError: isRiskIndicatorsError,
    error: riskIndicatorsError,
  } = useRiskIndicators({
    businessEventId,
    businessPartnerId,
  })

  const group = riskIndicatorsData?.riskIndicatorGroups?.[0]

  const {
    data: riskIndicatorGroupData,
    data: {
      events: riskIndicatorGroupEvents = [],
      criticalities: riskIndicatorGroupCriticalities = [],
    } = {},
    isLoading: isRiskIndicatorGroupLoading,
    isError: isRiskIndicatorGroupError,
    error: riskIndicatorGroupError,
  } = useRiskIndicatorGroupById(businessPartnerId, group?.groupId)

  const groupEventIds = riskIndicatorGroupEvents?.map((event) => event.id)

  const {
    data: eventsData,
    isLoading: isEventsLoading,
    isError: isEventsError,
  } = useEventsMiniByIdList({ eventIds: groupEventIds })

  const {
    data: { typeCodes: riskIndicatorCriticalityStatusTypeCodes } = {},
    isLoading: isCriticalityStatusTypeCodesLoading,
    isError: isCriticalityStatusTypeCodesError,
  } = useTypeCodes(RISK_INDICATOR_TYPE_CODE_CATEGORIES.CRITICALITY)

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isRiskIndicatorsLoading,
      isRiskIndicatorGroupLoading,
      isEventsLoading,
      isCriticalityStatusTypeCodesLoading,
    ],
    errorValues: [
      isRiskIndicatorsError,
      isRiskIndicatorGroupError,
      isEventsError,
      isCriticalityStatusTypeCodesError,
    ],
    errorDetails: [riskIndicatorsError, riskIndicatorGroupError],
    tileId,
  })

  return {
    isLoading: isSomeValueLoading,
    isError: isSomeValueError,
    error,
    data: {
      riskIndicatorGroupData,
      criticalities: riskIndicatorGroupCriticalities,
      events: eventsData,
      riskIndicatorCriticalityStatusTypeCodes,
      sourceRender: {
        businessPartnerId,
        riskIndicatorGroupId: group?.groupDisplayId ?? '',
      },
    },
  }
}

export default useRiskMonitoringCriticality
