import PropTypes from 'prop-types'
import { useState } from 'react'
import { isNotFoundError } from 'api/requests'
import CardSection from 'components/domains/deals/card/CardSection'
import MonitoringItemsTable from 'components/domains/deals/covenants/monitoring-items-card/MonitoringItemsTable'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInTable } from 'components/ui/errors/ErrorDataUnavailableInTable'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useEventsByIdList from 'hooks/services/business-events-and-tasks/events/useEventsByIdList'
import { useCovenantMonitoringItemsWithFrontendPagination } from 'hooks/services/deals/covenants/monitorings/useCovenantMonitoringItems'

/**
 * Displays all monitoring items for a covenant
 */
const MonitoringItemsCard = ({
  covenantId,
  covenantMainEntityType,
  covenantHasDocumentRequirements = false,
  covenantCheckName,
}) => {
  const [sorting, setSorting] = useState({})

  const onSortingChanged = (orderField, orderDirection) => {
    setSorting({ orderDirection, orderField })
  }

  const {
    isFetching: isFetchingItems,
    isError: isErrorItems,
    error = {},
    data: {
      covenantMonitoringItems = [],
      pagination = {
        offset: 0,
        limit: 0,
        total: 0,
      },
    },
  } = useCovenantMonitoringItemsWithFrontendPagination({
    covenantUuid: covenantId,
    sort: sorting,
    dataKey: 'covenantMonitoringItems',
    options: {
      enabled: !!covenantId,
    },
  })

  const eventIds = covenantMonitoringItems
    .map((item) => item?.linkedCovenantCheck?.linkedEventId)
    .filter(Boolean)
  const { data: events = [] } = useEventsByIdList(eventIds)
  const isPeriodicalCheck = covenantMainEntityType === cwpEntityTypes.BUSINESS_PARTNER

  return (
    <Card>
      <CardSection>
        <RequestStateResolver
          center
          isLoading={isFetchingItems}
          isError={isErrorItems && !isNotFoundError(error)}
          errorToDisplay={<ErrorDataUnavailableInTable />}
          renderContent={() => (
            <MonitoringItemsTable
              monitoringItems={covenantMonitoringItems}
              linkedEvents={events}
              orderField={sorting.orderField}
              orderDirection={sorting.orderDirection}
              pagination={pagination}
              onSortingChanged={onSortingChanged}
              isPeriodicalCheck={isPeriodicalCheck}
              hasDocumentRequirements={covenantHasDocumentRequirements}
              covenantCheckName={covenantCheckName}
            />
          )}
        />
      </CardSection>
    </Card>
  )
}

MonitoringItemsCard.propTypes = {
  covenantId: PropTypes.string.isRequired,
  covenantMainEntityType: PropTypes.oneOf([cwpEntityTypes.DEAL, cwpEntityTypes.BUSINESS_PARTNER])
    .isRequired,
  covenantHasDocumentRequirements: PropTypes.bool,
  covenantCheckName: PropTypes.string,
}

export default MonitoringItemsCard
