import { Label } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionTypes } from 'api/conditions/conditions'
import ConditionCreationApprovalLevelCell from 'components/domains/conditions/dialogs/condition-creation/table-cells/ConditionCreationApprovalLevelCell'
import ConditionCreationAssigneeCell from 'components/domains/conditions/dialogs/condition-creation/table-cells/ConditionCreationAssigneeCell'
import ConditionCreationCopyCell from 'components/domains/conditions/dialogs/condition-creation/table-cells/ConditionCreationCopyCell'
import ConditionCreationDocumentTypeCell from 'components/domains/conditions/dialogs/condition-creation/table-cells/ConditionCreationDocumentTypeCell'
import ConditionCreationNameCell from 'components/domains/conditions/dialogs/condition-creation/table-cells/ConditionCreationNameCell'
import ConditionCreationRefNrCell from 'components/domains/conditions/dialogs/condition-creation/table-cells/ConditionCreationRefNrCell'
import ConditionCreationReferencesCell from 'components/domains/conditions/dialogs/condition-creation/table-cells/ConditionCreationReferencesCell'
import ConditionCreationTypeCell from 'components/domains/conditions/dialogs/condition-creation/table-cells/ConditionCreationTypeCell'

const cellDefaults = {
  disableDragAndDrop: true,
  disableGlobalFilter: true,
  disableFilters: true,
  disableSortBy: true,
  disableGroupBy: true,
}

const cellButtonWidth = 40

const externalShownColumns = ['name', 'type', 'documentType', 'references', 'assignee', 'copy']
const internalShownColumns = ['name', 'refNumber', 'type', 'approvalLevel', 'assignee', 'copy']

const useConditionCreationTableColumns = ({ conditionType }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.dialog.external-condition-creation',
  })
  const allColumns = useMemo(
    () => ({
      name: {
        ...cellDefaults,
        Header: (
          <Label required>
            {conditionType === conditionTypes.external
              ? t('columns.external-conditions.name')
              : t('columns.internal-conditions.name')}
          </Label>
        ),
        id: 'name',
        Cell: ConditionCreationNameCell,
      },
      refNumber: {
        ...cellDefaults,
        Header: t('columns.ref-number'),
        id: 'refNumber',
        Cell: ConditionCreationRefNrCell,
        maxWidth: 180,
      },
      approvalLevel: {
        ...cellDefaults,
        Header: t('columns.approval-level'),
        id: 'approvalLevel',
        Cell: ConditionCreationApprovalLevelCell,
        maxWidth: 160,
      },
      type: {
        ...cellDefaults,
        Header: <Label required>{t('columns.type')}</Label>,
        id: 'type',
        Cell: ConditionCreationTypeCell,
        maxWidth: 200,
      },
      documentType: {
        ...cellDefaults,
        Header: <Label required>{t('columns.document-type')}</Label>,
        id: 'documentType',
        Cell: ConditionCreationDocumentTypeCell,
      },
      references: {
        ...cellDefaults,
        Header: t('columns.business-object'),
        id: 'references',
        Cell: ConditionCreationReferencesCell,
        minWidth: 300,
      },
      assignee: {
        ...cellDefaults,
        Header: t('columns.assignee'),
        id: 'assignee',
        Cell: ConditionCreationAssigneeCell,
        maxWidth: 230,
      },
      copy: {
        ...cellDefaults,
        Header: '',
        id: 'copy',
        Cell: ConditionCreationCopyCell,
        width: cellButtonWidth,
        disableResizing: true,
      },
    }),
    [t, conditionType],
  )

  const shownColumns =
    conditionType === conditionTypes.external ? externalShownColumns : internalShownColumns

  const columns = useMemo(
    () => shownColumns.map((columnKey) => allColumns[columnKey]),
    [shownColumns, allColumns],
  )

  return useMemo(
    () => ({
      columns,
    }),
    [columns],
  )
}

export default useConditionCreationTableColumns
