import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useCollateralAgreementCreation = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({
      name,
      type,
      value,
      currency,
      collateralProviderId,
      financialProductIds = [],
      propertyUuids = [],
    }) => {
      const body = {
        cag_name: name,
        type_code: type,
        financial_products: financialProductIds,
        properties: propertyUuids,
        assessment_value: value ? Number.parseFloat(value) : null,
        assessment_currency: currency || null,
        collateral_provider: collateralProviderId || null,
      }
      const response = await post({
        path: '/collateral-agreements',
        body,
      })
      return camelize(response)
    },
    {
      onSuccess,
      onError,
    },
  )
}
