import PropTypes from 'prop-types'
import { useCallback } from 'react'
import MultipleCashFlowAssumptionsTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/cashflow-analysis/MultipleCashFlowAssumptionsTile'
import MultipleDealSelectionPdfViewWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/wrapper/MultipleDealSelectionPdfViewWrapper'

const MultipleCashFlowAssumptionsTileSelectionWrapper = ({ tileId, isPdfView }) => {
  const contentCreator = useCallback(
    ({ selectedDealIndex }) => (
      <MultipleCashFlowAssumptionsTile
        tileId={tileId}
        selectedDealIndex={selectedDealIndex}
        isPdfView={isPdfView}
      />
    ),
    [isPdfView, tileId],
  )
  return (
    <MultipleDealSelectionPdfViewWrapper
      tileId={tileId}
      contentCreator={contentCreator}
      isPdfView={isPdfView}
    />
  )
}

MultipleCashFlowAssumptionsTileSelectionWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default MultipleCashFlowAssumptionsTileSelectionWrapper
