import { ValueState } from '@fioneer/ui5-webcomponents-react'
import DocumentTypes from 'components/domains/documents/DocumentTypes'

/**
 * marks documents which are new according to newlyAddedDocumentIds
 * @param documents the object to be processed
 * @param newlyAddedDocumentIds the ids of the documents which will be marked
 * @returns a processed documents object whose individual documents contain the status property
 */
export const labelNewDocuments = (documents, newlyAddedDocumentIds) => {
  const labelNewDocumentsRec = (node) => ({
    ...node,
    documents:
      node.documents?.map((document) => ({
        ...document,
        status: newlyAddedDocumentIds.includes(document.id) ? ValueState.Information : undefined,
      })) ?? [],
    subFolders: node.subFolders?.map((subFolder) => labelNewDocumentsRec(subFolder)) ?? [],
  })

  return labelNewDocumentsRec(documents)
}

export const labelReferencedDocuments = (documents) => {
  const labelReferencedDocumentsRec = (node) => ({
    ...node,
    documents:
      node.documents?.map((document) => ({
        ...document,
        hasReferencedRequirement: document.entityRefs.some(
          (entityRef) => entityRef.type === DocumentTypes.Requirement,
        ),
      })) ?? [],
    subFolders: node.subFolders?.map((subFolder) => labelReferencedDocumentsRec(subFolder)) ?? [],
  })

  return labelReferencedDocumentsRec(documents)
}

/**
 * Sorting function for columns that take the rows' status property into account.
 * Rows tagged with status: ValueState.Information are always sorted to the top
 * Note: only supports sorting by comparison based on operators <, >, ===
 * @param row1 row in analytical table row format
 * @param row2 row in analytical table row format
 * @param columnId the attribute to sort by. Must be a property of row1, row2
 * @param descending the direction of the sorting. required for ensuring tagged rows
 * are sorted to the top
 * @returns -1 | 0 | 1
 */
export const sortColumnWithStatus = (row1, row2, columnId, descending) => {
  const row1Value = row1.original[columnId]
  const row2Value = row2.original[columnId]
  const isRow1New = row1.original.status === ValueState.Information
  const isRow2New = row2.original.status === ValueState.Information

  if (isRow1New === isRow2New) {
    if (row1Value > row2Value) return 1
    else if (row1Value < row2Value) return -1
    else return 0
  } else {
    if (isRow1New) return descending ? 1 : -1
    else return descending ? -1 : 1
  }
}
