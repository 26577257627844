import { MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import * as XLSX from 'xlsx'
import { valuationClassification } from 'api/property/valuation/valuationRequests'
import MultiPropertyUploadButton, {
  MULTI_PROPERTY_FILE_UPLOADER_ID,
} from 'components/domains/properties/multi-property-upload/MultiPropertyUploadButton'
import MultiPropertyUploadRequestErrorTable from 'components/domains/properties/multi-property-upload/MultiPropertyUploadRequestErrorTable'
import MultiPropertyUploadValidationErrorTable from 'components/domains/properties/multi-property-upload/MultiPropertyUploadValidationErrorTable'
import MultiPropertyUploadValuationCreateWarningTable from 'components/domains/properties/multi-property-upload/MultiPropertyUploadValuationCreateWarningTable'
import MultiPropertyUploadingDialog from 'components/domains/properties/multi-property-upload/MultiPropertyUploadingDialog'
import LoadingContent from 'components/ui/content/LoadingContent'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import MessageBoxWithExpandableDetails from 'components/ui/dialog/MessageBoxWithExpandableDetails'
import useAssignProperties from 'hooks/services/deals/properties/useAssignProperties'
import useMultiPropertyUploadMapper from 'hooks/services/properties/multi-property-upload/useMultiPropertyUploadMapper'
import useMultiPropertyUploadValidation from 'hooks/services/properties/multi-property-upload/useMultiPropertyUploadValidation'
import { usePutProperty } from 'hooks/services/properties/usePutProperty'
import { usePutPropertyValuations } from 'hooks/services/properties/valuations/usePutPropertyValuations'
import { useValuationCreateMethodCodes } from 'hooks/services/properties/valuations/useValuationCreateMethodCodes'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'
import { setResetPropertiesPagination } from 'redux/slices/properties/resetPropertiesPaginationSlice'
import {
  addUploadedProperty,
  resetUploadedProperties,
} from 'redux/slices/properties/uploadedPropertiesSlice'

export const DATA_TYPES = {
  BOOLEAN: 'BOOLEAN',
  NUMERIC: 'NUMBERIC',
  DATE_YEAR: 'DATE_YEAR',
  PERCENTAGE: 'PERCENTAGE',
}

export const isEmptyOrNil = (value) => isNil(value) || value === ''

/**
 * A component that allows users to upload multiple properties at once via an Excel file.
 *
 * README: The button is at first placed in the properties table toolbar, since at some points the file uploader is not triggered when the button is placed in the page header actions.
 *
 * @param {boolean} disable - Whether or not the upload button should be disabled.
 * @returns {JSX.Element} The rendered component.
 */
const MultiPropertyUpload = ({ disable, dealUuid }) => {
  const { t: tMultiPropertyUpload } = useTranslation('translation', {
    keyPrefix: 'pages.property-upload',
  })

  const queryClient = useQueryClient()

  const {
    isLoading: isLoadingValidation,
    isError: isErrorValidation,
    validateData,
  } = useMultiPropertyUploadValidation()

  const {
    isLoading: isLoadingMapper,
    isError: isErrorMapper,
    mapRows,
  } = useMultiPropertyUploadMapper()

  const dispatch = useDispatch()

  const showToast = Modals.useShowToast()

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [errorDialogSummary, setErrorDialogSummary] = useState('')

  const [isUploading, setIsUploading] = useState(false)
  const [numberOfUploadingProperties, setNumberOfUploadingProperties] = useState(0)

  const [resolvedResponses, setResolvedPromises] = useState([])

  const [validationErrors, setValidationErrors] = useState({})
  const [requestErrorMessages, setRequestErrorMessages] = useState([])
  const [requestErrorsWithRowIndex, setRequestErrorsWithRowIndex] = useState([])

  const [valuationsToPut, setValuationsToPut] = useState([])
  const [valuationsToPutSettled, setValuationsToPutSettled] = useState(0)
  const [skippedValuationPuts, setSkippedValuationPuts] = useState([])

  const isWarningDialogOpen = skippedValuationPuts.length > 0 && !isUploading && !isErrorDialogOpen

  const getRejectedPromisesIndexes = (promises) => {
    const indexes = []
    for (let i = 0; i < promises.length; i++) if (promises[i].status === 'rejected') indexes.push(i)
    return indexes
  }

  const {
    isLoading: isLoadingValuationCreateMethodCodes,
    isError: isErrorValuationCreateMethodCodes,
    data: valuationCreateMethodCodes,
  } = useValuationCreateMethodCodes()

  const putValuations = usePutPropertyValuations({
    onSuccess: (response) => {
      if (response.skippedValuations.length > 0) {
        setSkippedValuationPuts((prev) => [
          ...prev,
          response.skippedValuations.map((skippedValuation) => ({
            ...skippedValuation,
            propertyUuid: response.propertyUuid,
          })),
        ])
      }
    },
    onSettled: () => setValuationsToPutSettled((prev) => prev + 1),
  })

  const findValuationCreateMethodCodeByType = (type) =>
    valuationCreateMethodCodes.find((code) => code?.createMethodCode === type)?.key

  const valuationCreateMethods = {
    marketValue: {
      code: findValuationCreateMethodCodeByType(valuationClassification.marketValue),
      accessor: 'marketValue',
    },
    mortgageLendingValue: {
      code: findValuationCreateMethodCodeByType(valuationClassification.mortgageLendingValue),
      accessor: 'mortgageLendingValue',
    },
    purchasePrice: {
      code: findValuationCreateMethodCodeByType(valuationClassification.purchasePrice),
      accessor: 'purchasePrice',
    },
  }

  const queueValuationToPutWhenIsSet = (
    propertyCreateRequest,
    valuationCreateMethod,
    valuationsToCreateOrUpdate,
  ) => {
    const valuationToCreate = propertyCreateRequest[valuationCreateMethod.accessor]
    if (valuationToCreate > 0) {
      valuationsToCreateOrUpdate.push({
        calculation_method_code: valuationCreateMethod.code,
        value_amount: {
          number: valuationToCreate,
          currency: propertyCreateRequest.currencyCode,
        },
      })
    }
  }

  const queueAndPutValuations = (propertyCreateRequest, propertyCreateResponse) => {
    const queuedValuationsToPut = []
    queueValuationToPutWhenIsSet(
      propertyCreateRequest,
      valuationCreateMethods.marketValue,
      queuedValuationsToPut,
    )
    queueValuationToPutWhenIsSet(
      propertyCreateRequest,
      valuationCreateMethods.mortgageLendingValue,
      queuedValuationsToPut,
    )
    queueValuationToPutWhenIsSet(
      propertyCreateRequest,
      valuationCreateMethods.purchasePrice,
      queuedValuationsToPut,
    )
    setValuationsToPut((prev) => [
      ...prev,
      {
        propertyUuid: propertyCreateResponse?.uuid,
        valuations: queuedValuationsToPut,
      },
    ])
    putValuations.mutateAsync({
      property_uuid: propertyCreateResponse?.uuid,
      valuations: queuedValuationsToPut,
    })
  }

  const requestErrorHandler = async (error) => {
    const errorString = await formatHookError(error)
    setRequestErrorMessages((prev) => [...prev, errorString])
  }

  const putProperty = usePutProperty({
    onSuccess: (response, request) => {
      queueAndPutValuations(request, response)
      dispatch(addUploadedProperty(response.id))
    },
    onError: requestErrorHandler,
  })

  const showValidationErrors = (errors) => {
    const groupedErrors = errors.reduce((acc, item) => {
      const key = item.type
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(item)
      return acc
    }, {})
    setErrorDialogSummary(tMultiPropertyUpload('validation-error-message'))
    setRequestErrorMessages([])
    setValidationErrors(groupedErrors)
    setIsErrorDialogOpen(true)
  }

  const showUnexpectedError = () => {
    setErrorDialogSummary(tMultiPropertyUpload('unexpected-error-message'))
    setValidationErrors({})
    setRequestErrorMessages([])
    setIsErrorDialogOpen(true)
  }

  const showNoDataError = () => {
    setErrorDialogSummary(tMultiPropertyUpload('no-data-error-message'))
    setValidationErrors({})
    setRequestErrorMessages([])
    setIsErrorDialogOpen(true)
  }

  const showRequestsError = useCallback(() => {
    setIsUploading(false)
    setErrorDialogSummary(
      tMultiPropertyUpload('requests-error', {
        x: requestErrorsWithRowIndex.length,
        y: numberOfUploadingProperties,
      }),
    )
    setValidationErrors({})
    setIsErrorDialogOpen(true)
  }, [numberOfUploadingProperties, requestErrorsWithRowIndex, tMultiPropertyUpload])

  const filterNullRows = (excelContent) =>
    excelContent.filter((row) => Object.entries(row).some(([_, value]) => value))

  const resetValidationAndDialogState = () => {
    setIsErrorDialogOpen(false)
    setErrorDialogSummary('')
    setValidationErrors({})
    setRequestErrorMessages([])
    setResolvedPromises([])
    setRequestErrorsWithRowIndex([])
    setNumberOfUploadingProperties(0)
  }

  const assignExcelIndexToRequestError = () => {
    if (resolvedResponses.length > 0 && requestErrorMessages.length > 0) {
      const requestErrorsWithUpdatedRowIndex = []
      const rejectedPromises = getRejectedPromisesIndexes(resolvedResponses)
      requestErrorMessages.forEach((errorMessage, index) => {
        requestErrorsWithUpdatedRowIndex.push({
          message: errorMessage,
          rowIndex: rejectedPromises[index] + 1,
        })
      })
      setRequestErrorsWithRowIndex([...requestErrorsWithUpdatedRowIndex])
    }
  }
  useEffect(assignExcelIndexToRequestError, [resolvedResponses, requestErrorMessages])

  const showRequestsErrorWhenExists = useCallback(() => {
    if (requestErrorsWithRowIndex.length > 0) {
      showRequestsError()
    }
  }, [requestErrorsWithRowIndex, showRequestsError])

  const refreshProperties = useCallback(() => {
    queryClient.invalidateQueries(['properties'])
    dispatch(setResetPropertiesPagination(true))
  }, [dispatch, queryClient])

  const refreshPropertiesWhenNoValuationsToPut = useCallback(() => {
    if (valuationsToPut.length === 0) {
      refreshProperties()
      setIsUploading(false)
    }
  }, [valuationsToPut, refreshProperties])

  const { mutate: assignProperties } = useAssignProperties({})

  const assignPropertiesToDeal = useCallback(() => {
    const propertyUuids = resolvedResponses
      .filter((res) => res.value?.uuid)
      .map(({ value }) => value.uuid)

    assignProperties(
      {
        dealUuid: dealUuid,
        propertyUuids,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['deals', dealUuid, 'properties'])
          showToast({
            children: tMultiPropertyUpload('x-properties-assigned-to-deal', {
              x: propertyUuids.length - requestErrorsWithRowIndex.length,
            }),
          })
        },
      },
    )
  }, [
    assignProperties,
    dealUuid,
    queryClient,
    requestErrorsWithRowIndex.length,
    resolvedResponses,
    showToast,
    tMultiPropertyUpload,
  ])

  const showPropertiesUploadedFeedback = () => {
    const propertiesUploaded =
      numberOfUploadingProperties > 0 &&
      resolvedResponses.length === numberOfUploadingProperties &&
      requestErrorMessages.length === requestErrorsWithRowIndex.length
    if (!propertiesUploaded) {
      return
    }

    showRequestsErrorWhenExists()

    if (resolvedResponses.length === requestErrorMessages.length) {
      return
    }

    if (dealUuid) {
      assignPropertiesToDeal(resolvedResponses)
    } else {
      showToast({
        children: tMultiPropertyUpload('x-properties-uploaded', {
          x: numberOfUploadingProperties - requestErrorsWithRowIndex.length,
        }),
      })
    }

    refreshPropertiesWhenNoValuationsToPut()
  }
  useEffect(showPropertiesUploadedFeedback, [
    assignPropertiesToDeal,
    dealUuid,
    dispatch,
    isUploading,
    numberOfUploadingProperties,
    queryClient,
    refreshPropertiesWhenNoValuationsToPut,
    requestErrorMessages,
    requestErrorsWithRowIndex,
    resolvedResponses,
    showRequestsError,
    showRequestsErrorWhenExists,
    showToast,
    tMultiPropertyUpload,
    valuationsToPut,
  ])

  const resetValuationsToPutState = () => {
    setValuationsToPut([])
    setValuationsToPutSettled(0)
  }

  const refreshPropertiesAndResetValuationsToPutState = useCallback(() => {
    refreshProperties()
    resetValuationsToPutState()
  }, [refreshProperties])

  const refreshPropertiesWhenValuationPutsSettled = () => {
    const valuationPutsSettled =
      valuationsToPut.length > 0 && valuationsToPut.length === valuationsToPutSettled
    if (valuationPutsSettled) {
      refreshPropertiesAndResetValuationsToPutState()
      setIsUploading(false)
    }
  }

  useEffect(refreshPropertiesWhenValuationPutsSettled, [
    dispatch,
    queryClient,
    refreshPropertiesAndResetValuationsToPutState,
    valuationsToPut,
    valuationsToPutSettled,
  ])

  const beforeUpload = () => {
    dispatch(resetUploadedProperties())
    resetValidationAndDialogState()
  }

  const afterUpload = () => {
    const fileUploader = document.getElementById(MULTI_PROPERTY_FILE_UPLOADER_ID)
    const fileInput = fileUploader.shadowRoot.querySelector('input[type=file]')
    if (fileInput) {
      fileInput.value = ''
    }
  }

  const parseAndFilterExcelContent = (e, uploadInput) => {
    const bufferArray = e?.target.result
    if (uploadInput) {
      uploadInput.value = undefined
    }
    const workBook = XLSX.read(bufferArray, { type: 'buffer' })
    const workSheetName = workBook.SheetNames[0]
    const workSheet = workBook.Sheets[workSheetName]
    const excelContent = XLSX.utils.sheet_to_json(workSheet, {
      header: 'A',
      defval: null,
      blankrows: false,
      range: 0,
    })
    return filterNullRows(excelContent)
  }

  const excelHasNoData = (excelContent) => {
    const minNumberOfRows = 3
    return excelContent.length < minNumberOfRows
  }

  const putProperties = (propertyPutPromises, propertiesToPut) => {
    for (let i = 0; i < propertiesToPut.length; i++) {
      propertyPutPromises.push(putProperty.mutateAsync(propertiesToPut[i]))
    }
  }

  const waitForPropertiesPutToSettle = (propertyPutPromises) =>
    Promise.allSettled(propertyPutPromises).then((results) => setResolvedPromises([...results]))

  const putPropertiesAndWaitToSettle = (propertiesToPut) => {
    const propertyPutPromises = []
    putProperties(propertyPutPromises, propertiesToPut)
    waitForPropertiesPutToSettle(propertyPutPromises)
  }

  const handleOnUpload = (file, uploadInput) => {
    const fileReader = new FileReader()
    fileReader.readAsArrayBuffer(file)
    fileReader.onload = (e) => {
      try {
        beforeUpload()
        const filteredExcelContent = parseAndFilterExcelContent(e, uploadInput)
        if (excelHasNoData(filteredExcelContent)) {
          showNoDataError()
          return
        }
        const rows = filteredExcelContent.map((row) => Object.values(row))
        const errors = validateData(rows)
        if (errors.length) {
          showValidationErrors(errors)
        } else {
          const mappedProperties = mapRows(rows)
          setNumberOfUploadingProperties(mappedProperties.length)
          setIsUploading(true)
          putPropertiesAndWaitToSettle(mappedProperties)
        }
      } catch (error) {
        showUnexpectedError()
      } finally {
        afterUpload()
      }
    }
  }

  const handleOnCloseErrorDialog = () => {
    resetValidationAndDialogState()
  }

  const handleOnCloseWarningDialog = () => {
    setSkippedValuationPuts([])
  }

  const isLoading = isLoadingValidation || isLoadingMapper || isLoadingValuationCreateMethodCodes
  const isError = isErrorValidation || isErrorMapper || isErrorValuationCreateMethodCodes

  const renderValidationOrRequestErrorTable = () => {
    if (!isEqual(validationErrors, {})) {
      return <MultiPropertyUploadValidationErrorTable groupedErrors={validationErrors} />
    } else if (requestErrorsWithRowIndex?.length > 0) {
      return <MultiPropertyUploadRequestErrorTable requestErrors={requestErrorsWithRowIndex} />
    }
    return null
  }

  const renderValuationCreateWarningTable = () => (
    <MultiPropertyUploadValuationCreateWarningTable
      skippedValuations={skippedValuationPuts.flat()}
    />
  )

  return (
    <LoadingContent
      contentKey="multi-property-upload-loading-content"
      isLoading={isLoading}
      isError={isError}
    >
      <MultiPropertyUploadButton
        disable={disable}
        onUpload={handleOnUpload}
        hasDealUuid={!!dealUuid}
      />
      <>
        {createPortal(
          <MultiPropertyUploadingDialog isUploading={isUploading} hasDealUuid={!!dealUuid} />,
          document.body,
        )}
      </>
      <ErrorMessageBoxWithExpandableDetails
        messageSummary={errorDialogSummary}
        messageDetails={renderValidationOrRequestErrorTable()}
        isOpen={isErrorDialogOpen && !isUploading}
        onClose={handleOnCloseErrorDialog}
      />
      <MessageBoxWithExpandableDetails
        messageSummary={tMultiPropertyUpload('valuation-put-warning')}
        messageDetails={renderValuationCreateWarningTable()}
        messageBoxType={MessageBoxTypes.Warning}
        isOpen={isWarningDialogOpen}
        onClose={handleOnCloseWarningDialog}
      />
    </LoadingContent>
  )
}
MultiPropertyUpload.propTypes = {
  disable: PropTypes.bool.isRequired,
  dealUuid: PropTypes.string,
}

export default MultiPropertyUpload
