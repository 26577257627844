import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteTaskWatcher = (mutateOptions) => {
  const { delete: deleteCall } = useAccessTokenRequest()
  return useMutation(
    ({ eventId, taskId, watcherId }) =>
      deleteCall({
        path: `/events/${eventId}/tasks/${taskId}/watchers/${watcherId}`,
      }),
    mutateOptions,
  )
}
export default useDeleteTaskWatcher
