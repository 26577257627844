import { useMemo } from 'react'
import { useAreaMeasurementUnitFormatter, useLanguage } from 'hooks/i18n/useI18n'
import useGetAllRentRollWorkingVersionOptions from 'hooks/services/properties/rent-roll/working-version/useGetAllRentRollWorkingVersionOptions'
import { useSegmentUsageTypesInSpecifiedLanguage } from 'hooks/services/properties/segments/useSegmentUsageTypes'
import { useUtilizationCodesInSpecifiedLanguage } from 'hooks/services/properties/useUtilizationCodes'

const MAPPING_LANGUAGES = ['de-DE', 'en-GB']
const T_LANGUAGE_MAP = new Map()
T_LANGUAGE_MAP.set('de-DE', 'de')
T_LANGUAGE_MAP.set('en-GB', 'en-GB')

export const useGetAllRRWVOptionsInAllMappingLanguages = () => {
  const language = useLanguage()
  const additionalLanguageToQuery = useMemo(() => {
    const languageBase = language.split('-')[0]
    return MAPPING_LANGUAGES.filter(
      (possibleAdditionalLanguage) => possibleAdditionalLanguage.split('-')[0] !== languageBase,
    )[0]
  }, [language])

  const allSelectOptions = useGetAllRentRollWorkingVersionOptions()
  const { optionsAreLoading, occupancyStatusCodes, segmentUsageTypeCodes, areaMeasureUnitCodes } =
    allSelectOptions
  const { isLoading: isLoadingUtilizationCodes, data: translatedUtilizationCodesData } =
    useUtilizationCodesInSpecifiedLanguage(additionalLanguageToQuery)

  const translatedOccupancyStatusCodes = useMemo(
    () =>
      isLoadingUtilizationCodes || !translatedUtilizationCodesData
        ? []
        : translatedUtilizationCodesData?.utilization_codes.filter(
            (utilizationCode) => utilizationCode.is_write_code,
          ),
    [isLoadingUtilizationCodes, translatedUtilizationCodesData],
  )
  const { isLoading: isLoadingSegmentUsageTypes, data: translatedSegmentUsageTypeCodes } =
    useSegmentUsageTypesInSpecifiedLanguage(additionalLanguageToQuery)

  const formatAreaUnit = useAreaMeasurementUnitFormatter({
    overwriteLanguage: T_LANGUAGE_MAP.get(additionalLanguageToQuery),
  })
  const translatedAreaMeasureUnitCodes = useMemo(
    () =>
      areaMeasureUnitCodes.map((measureUnitCode) => ({
        ...measureUnitCode,
        display_name: formatAreaUnit(measureUnitCode.key),
      })),
    [areaMeasureUnitCodes, formatAreaUnit],
  )

  const aggregatedOccupancyStatusCodes = useMemo(
    () => [...occupancyStatusCodes, ...translatedOccupancyStatusCodes],
    [occupancyStatusCodes, translatedOccupancyStatusCodes],
  )
  const aggregatedSegmentUsageTypeCodes = useMemo(
    () => [...segmentUsageTypeCodes, ...translatedSegmentUsageTypeCodes],
    [segmentUsageTypeCodes, translatedSegmentUsageTypeCodes],
  )
  const aggregatedAreaMeasureUnitCodes = useMemo(
    () => [...areaMeasureUnitCodes, ...translatedAreaMeasureUnitCodes],
    [areaMeasureUnitCodes, translatedAreaMeasureUnitCodes],
  )
  const allOptionsAreLoading = useMemo(
    () => isLoadingUtilizationCodes || isLoadingSegmentUsageTypes || optionsAreLoading,
    [isLoadingSegmentUsageTypes, isLoadingUtilizationCodes, optionsAreLoading],
  )

  return {
    ...allSelectOptions,
    optionsAreLoading: allOptionsAreLoading,
    occupancyStatusCodes: aggregatedOccupancyStatusCodes,
    segmentUsageTypeCodes: aggregatedSegmentUsageTypeCodes,
    areaMeasureUnitCodes: aggregatedAreaMeasureUnitCodes,
  }
}
