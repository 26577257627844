import { saveAs } from 'file-saver'
import { useCallback, useMemo } from 'react'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

const useExportMonitoringLog = () => {
  const { get } = useAccessTokenRequest()
  const downloadFunction = useCallback(
    ({ fromDate, toDate, propertyUuid }, { onError, onSuccess }) => {
      const queryParams = new URLSearchParams()
      queryParams.append('property_id', propertyUuid)
      if (fromDate) {
        queryParams.append('from_date', fromDate)
      }
      if (toDate) {
        queryParams.append('to_date', toDate)
      }
      get({
        path: `/property-monitoring/audit-logs?${queryParams.toString()}`,
        headers: { Accept: mimeType },
      })
        .then(({ data, fileName }) => {
          saveAs(data, fileName)
          onSuccess(data, fileName)
        })
        .catch((error) => {
          onError(error)
        })
    },
    [get],
  )
  return useMemo(
    () => ({
      download: downloadFunction,
    }),
    [downloadFunction],
  )
}

export default useExportMonitoringLog
