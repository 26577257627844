import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import BusinessPartnerSearchTable from 'components/domains/business-partners/BusinessPartnerSearchTable'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const emptyArray = []

const BusinessPartnerSelectionTable = ({ selectedReferences, onSelectionChange }) => {
  const { referencesMapping } = useContext(ConditionsContext)
  const mappedReferencesToBp = useMemo(
    () =>
      selectedReferences.map((reference) => ({
        id: reference.entityId,
        name: reference.entityName,
      })),
    [selectedReferences],
  )

  const mapBpToReferences = useCallback(
    (newSelection) =>
      newSelection.map((reference) => ({
        entityId: reference.id,
        entityType: cwpEntityTypes.BUSINESS_PARTNER,
        entityName: reference.name,
      })),
    [],
  )

  const [selectedBusinessPartners, setSelectedBusinessPartners] = useState(mappedReferencesToBp)

  useEffect(() => {
    setSelectedBusinessPartners(mappedReferencesToBp)
  }, [mappedReferencesToBp])

  const handleChange = useCallback(
    (newSelection) => {
      setSelectedBusinessPartners(newSelection)
      onSelectionChange(mapBpToReferences(newSelection))
    },
    [mapBpToReferences, onSelectionChange],
  )

  return (
    <BusinessPartnerSearchTable
      initialSearch={''}
      initialId={referencesMapping?.[cwpEntityTypes.BUSINESS_PARTNER]?.initialId}
      initialIsBeforeSearch={!referencesMapping?.[cwpEntityTypes.BUSINESS_PARTNER]?.initialId}
      onChange={handleChange}
      initialRoles={emptyArray}
      selectedBusinessPartners={selectedBusinessPartners}
      initialExcludeInactive={true}
      isMultiSelect={true}
    />
  )
}

BusinessPartnerSelectionTable.propTypes = {
  selectedReferences: PropTypes.arrayOf(
    PropTypes.shape({
      entityId: PropTypes.string.isRequired,
      entityType: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
}

export default BusinessPartnerSelectionTable
