import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import TableWrappingTextCell from 'components/domains/conditions/overview/table-cells/generic/TableWrappingTextCell'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const TableCreationDateSubcomponent = ({ creationDate }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.subcomponents.creation-date',
  })
  const { format: formatDate } = useShortDateFormatter()

  return (
    <div>
      <Label>{t('label')}</Label>
      <div>
        <TableWrappingTextCell value={formatDate(creationDate)} />
      </div>
    </div>
  )
}

TableCreationDateSubcomponent.propTypes = {
  creationDate: PropTypes.string,
}

export default TableCreationDateSubcomponent
