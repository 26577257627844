import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useCwpPropertyValuations = ({ propertyUuid, options = {} } = {}) =>
  useCamelizedResponse(
    useRequest({
      keys: ['properties', propertyUuid, 'cwp-valuations'],
      path: `/properties/${propertyUuid}/cwp-valuations`,
      options: { enabled: !!propertyUuid, ...options },
    }),
  )

export default useCwpPropertyValuations
