import { Label } from '@fioneer/ui5-webcomponents-react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const DistanceToMarketCell = ({ distance, propertyInMarketArea }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.markets.assignable-markets-dialog.table',
  })

  const calculateDistance = useCallback(() => {
    if (_.isNil(distance)) return '-'
    if (distance < 1000) return _.round(distance, 0) + ' m'
    return _.round(distance / 1000, 1) + ' km'
  }, [distance])

  const isPropertyInMarketAreaText = useCallback(
    () => (propertyInMarketArea ? t('property-in-market') : ''),
    [propertyInMarketArea, t],
  )
  const labelText = useCallback(
    () => calculateDistance() + isPropertyInMarketAreaText(),
    [calculateDistance, isPropertyInMarketAreaText],
  )

  return <Label>{labelText()}</Label>
}

DistanceToMarketCell.propTypes = {
  distance: PropTypes.number,
  propertyInMarketArea: PropTypes.bool,
}

export default DistanceToMarketCell
