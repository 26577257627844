import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  uploadedPropertyIds: [],
}

export const uploadedPropertiesSlice = createSlice({
  name: 'properties/uploaded-properties',
  initialState,
  reducers: {
    addUploadedProperty: (state, { payload }) => {
      state.uploadedPropertyIds = [...state.uploadedPropertyIds, payload]
    },
    resetUploadedProperties: () => initialState,
  },
})

export const { addUploadedProperty, resetUploadedProperties } = uploadedPropertiesSlice.actions

export default uploadedPropertiesSlice.reducer
