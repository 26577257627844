import isEmpty from 'lodash.isempty'
import {
  ACTIVE,
  APPROVED,
  OBSOLETE,
  PROPOSED,
} from 'components/domains/business-partners/tile/risk-monitoring/forbearance/constants'
import resolveLifecycleStatusCodeToValueState from 'components/domains/business-partners/tile/risk-monitoring/forbearance/helper/resolveLifecycleStatusCodeToValueState'
import mapForbearanceStatusVersion from 'components/domains/business-partners/tile/risk-monitoring/forbearance/statuses/mappers/mapForbearanceStatusVersion'
import { TABLE_ROW_HEIGHT } from 'components/domains/business-partners/tile/risk-monitoring/utils/constants'
import paths from 'routes/paths'

/**
 * @typedef {{status: {statusValue: string}, subRows: row[]}} row
 * @param {row[]} rows
 * @returns {boolean}
 */
const atLeastOneProposedOrApprovedForbearanceStatus = (rows) => {
  if (!rows.length) return false

  const isProposedOrApproved = ({ status: { statusValue } }) =>
    statusValue === PROPOSED || statusValue === APPROVED

  return rows.some(
    (row) =>
      isProposedOrApproved(row) || atLeastOneProposedOrApprovedForbearanceStatus(row.subRows ?? []),
  )
}

const isGroupMoreRecentThanLatestActive = (currentRow, rows) => {
  const isCurrentRowActive = currentRow?.status?.statusValue === ACTIVE
  if (isCurrentRowActive) {
    return false
  }

  const isLessRecentThanCurrentRow = ({ createdOn }) =>
    new Date(createdOn) < new Date(currentRow?.createdOn)

  const activeRows = rows.filter(({ status: { statusValue } }) => statusValue === ACTIVE) ?? []
  return activeRows.every((row) => isLessRecentThanCurrentRow(row))
}

const areGroupVersionsObsolete = (row) =>
  !isEmpty(row?.subRows) &&
  row.subRows.every(({ status: { statusValue } }) => statusValue === OBSOLETE)

/**
 * @param {import('hooks/services/risk-monitoring/forbearance/statuses/forbearanceStatusesSchema').ForbearanceStatuses} [data]
 * @param {object} staffMembers
 */
const mapForbearanceStatus = (data, staffMembers) => {
  const { forbearanceStatuses } = data ?? {}

  if (isEmpty(forbearanceStatuses)) {
    return []
  }

  const result = forbearanceStatuses?.map((group) => {
    const {
      forbearanceStatusShortText,
      version,
      lifecycleStatusTypeCode,
      lifecycleStatusShortText,
      prioritizedForbearanceMeasureShortText,
      createdOn,
      currentBusinessEvent,
      id,
      forbearanceStatusTypeCode,
    } = group ?? {}

    return {
      name: `${forbearanceStatusShortText} ${version}`,
      status: {
        statusValue: lifecycleStatusShortText,
        statusState: resolveLifecycleStatusCodeToValueState(lifecycleStatusTypeCode),
      },
      isHeader: true,
      prioritizedForbearanceMeasure: prioritizedForbearanceMeasureShortText,
      createdOn: createdOn?.createdOn,
      createdBy: staffMembers?.[createdOn?.createdBy]?.fullName,
      ...(currentBusinessEvent && {
        currentBusinessEvent: {
          id: currentBusinessEvent?.displayId,
          name: currentBusinessEvent?.name,
          link: `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${currentBusinessEvent?.id}`,
        },
      }),
      rowHeight: TABLE_ROW_HEIGHT,
      subRows: mapForbearanceStatusVersion({
        group,
        forbearanceStatusVersions: group?.forbearanceStatusVersions,
        forbearanceStatusId: id,
        staffMembers,
      }),
      forbearanceStatusId: id,
      forbearanceStatusTypeCode,
    }
  })

  const hasAtLeastOneProposedOrApprovedForbearanceStatus =
    atLeastOneProposedOrApprovedForbearanceStatus(result)

  return result?.map((row) => ({
    ...row,
    buttons: {
      hasAtLeastOneProposedOrApprovedForbearanceStatus,
      areGroupVersionsObsolete: areGroupVersionsObsolete(row),
      isGroupMoreRecentThanLatestActive: isGroupMoreRecentThanLatestActive(row, result),
    },
  }))
}

export default mapForbearanceStatus
