import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import {
  decisionStageStatus as possibleDecisionStageStatus,
  getObjectStatusForDecisionStageVoteStatus,
  decisionStageVoterStatus,
} from 'api/decision-process/decisionProcessApi'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import styles from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageVotersTableLoadingWrapper.module.css'
import VotersTableActionCell, {
  possibleActions,
} from 'components/domains/business-events-and-tasks/decision-stage/table-cells/VotersTableActionCell'
import DecisionStageVotersTable from 'components/domains/business-events-and-tasks/decision-stage/voters-table/DecisionStageVotersTable'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useCreateDecisionStageVoter from 'hooks/services/business-events-and-tasks/decision-process/stages/useCreateDecisionStageVoter'
import useDecisionStageVoters from 'hooks/services/business-events-and-tasks/decision-process/stages/useDecisionStageVoters'
import useDecisionStagesVoteTypes from 'hooks/services/business-events-and-tasks/decision-process/stages/useDecisionStagesVoteTypes'
import useDeleteDecisionStageVoter from 'hooks/services/business-events-and-tasks/decision-process/stages/useDeleteDecisionStageVoter'
import useEditDecisionStageVoter from 'hooks/services/business-events-and-tasks/decision-process/stages/useEditDecisionStageVoter'
import useRemindVoter from 'hooks/services/business-events-and-tasks/decision-process/stages/useRemindVoter'
import { useStaffMemberSelf } from 'hooks/services/business-partners/staff-members/useStaffMemberSelf'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const toApprovalHref = 'approval/voters'

const calculateRenderedAction = ({
  decisionStageStatus,
  isOwnUser,
  voterStatusCode,
  hasVoteUpdatePermission,
  isPdfView,
}) => {
  if (isPdfView) {
    return
  }

  if (
    decisionStageStatus === possibleDecisionStageStatus.planned ||
    decisionStageStatus === possibleDecisionStageStatus.inProgressRequestDiscarded
  ) {
    return possibleActions.showEditCells
  }

  if (
    decisionStageStatus === possibleDecisionStageStatus.inProgress &&
    !isOwnUser &&
    voterStatusCode === decisionStageVoterStatus.pending
  ) {
    return possibleActions.showRemindVoteButton
  }

  if (isOwnUser && hasVoteUpdatePermission) {
    return possibleActions.showToApprovalButton
  }
}

const DecisionStageVotersTableLoadingWrapper = ({
  decisionStageId,
  decisionStageStatus,
  isPdfView = false,
}) => {
  const {
    event: { id: eventId },
    allowedOperations,
  } = useContext(BusinessEventsAndTasksContext)
  const {
    data: votersData,
    isLoading,
    isError,
  } = useDecisionStageVoters({ eventId, decisionStageId })

  const {
    data: staffMember,
    isLoading: isStaffMemberLoading,
    isError: isStaffMemberError,
  } = useStaffMemberSelf()

  const { mutate: deleteVoter } = useDeleteDecisionStageVoter()
  const { mutate: createVoter } = useCreateDecisionStageVoter()
  const { mutate: editVoter } = useEditDecisionStageVoter()

  const hasUpdateDecisionStagePermission = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.updateDecisionStage),
    [allowedOperations],
  )

  const hasVoteUpdatePermission = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.updateDecisionStageVote),
    [allowedOperations],
  )

  const showActionButtons = useMemo(
    () =>
      !isPdfView &&
      hasUpdateDecisionStagePermission &&
      (decisionStageStatus === possibleDecisionStageStatus.planned ||
        decisionStageStatus === possibleDecisionStageStatus.inProgressRequestDiscarded),
    [hasUpdateDecisionStagePermission, decisionStageStatus, isPdfView],
  )

  const renderActionCell = useCallback(
    ({
      voterCancelButtonId,
      isEditModeForCurrentRow,
      isEditButtonDisabled,
      isDeleteButtonDisabled,
      onSaveCancelButtonClicked,
      onSaveButtonClicked,
      isSaveButtonDisabled,
      onEditButtonClicked,
      onDeleteButtonClicked,
      userId,
      voterStatusCode,
      voterId,
    }) => {
      const isOwnUser = userId === staffMember.id

      return (
        <VotersTableActionCell
          voterCancelButtonId={voterCancelButtonId}
          isEditModeForCurrentRow={isEditModeForCurrentRow}
          isEditButtonDisabled={isEditButtonDisabled}
          isDeleteButtonDisabled={isDeleteButtonDisabled}
          onSaveCancelButtonClicked={onSaveCancelButtonClicked}
          onSaveButtonClicked={onSaveButtonClicked}
          isSaveButtonDisabled={isSaveButtonDisabled}
          onEditButtonClicked={onEditButtonClicked}
          onDeleteButtonClicked={onDeleteButtonClicked}
          showActionButtons={showActionButtons}
          voterId={voterId}
          decisionStageId={decisionStageId}
          toApprovalHref={`${toApprovalHref}/${voterId}`}
          useRemindVoterMutationHook={useRemindVoter}
          renderedAction={calculateRenderedAction({
            decisionStageStatus,
            isOwnUser,
            voterStatusCode,
            hasVoteUpdatePermission,
            isPdfView,
          })}
        />
      )
    },
    [
      decisionStageId,
      showActionButtons,
      staffMember,
      decisionStageStatus,
      hasVoteUpdatePermission,
      isPdfView,
    ],
  )

  const renderApproversTable = useCallback(
    () => (
      <DecisionStageVotersTable
        voters={votersData.voters}
        eventId={eventId}
        decisionStageId={decisionStageId}
        deleteVoter={deleteVoter}
        createVoter={createVoter}
        editVoter={editVoter}
        useVoteTypes={useDecisionStagesVoteTypes}
        renderActionCell={renderActionCell}
        getObjectStatusForVoteStatus={getObjectStatusForDecisionStageVoteStatus}
        showAddButton={showActionButtons}
        isPdfView={isPdfView}
      />
    ),
    [
      votersData,
      eventId,
      decisionStageId,
      deleteVoter,
      createVoter,
      editVoter,
      showActionButtons,
      renderActionCell,
      isPdfView,
    ],
  )

  return (
    <div className={styles.tableWrapper}>
      <LoadingStateWrapper
        isLoading={isLoading || isStaffMemberLoading}
        isError={isError || isStaffMemberError}
        renderContent={renderApproversTable}
      />
    </div>
  )
}

DecisionStageVotersTableLoadingWrapper.propTypes = {
  decisionStageId: PropTypes.string.isRequired,
  decisionStageStatus: PropTypes.oneOf(Object.values(possibleDecisionStageStatus)).isRequired,
  isPdfView: PropTypes.bool,
}

export default DecisionStageVotersTableLoadingWrapper
