import { useContext, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import BusinessPartnerTextualAssessments from 'components/domains/business-partners/tile/assessments/BusinessPartnerTextualAssessments'
import OverallAssessmentTile from 'components/domains/business-partners/tile/assessments/OverallAssessmentTile'
import CustomAssessments from 'components/domains/custom-assessments/CustomAssessments'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Page from 'components/ui/page/Page'
import Section from 'components/ui/page/Section'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useCustomAssessmentsHelper from 'hooks/services/central-data/custom-assessments/useCustomAssessmentsHelper'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

const BusinessPartnerAssessments = () => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', { keyPrefix: 'pages.business-partner.assessments' })
  const businessPartner = useContext(BusinessPartnerContext)

  const { isFetching, isError, showCustomAssessments } = useCustomAssessmentsHelper({
    entityType: cwpEntityTypes.BUSINESS_PARTNER,
  })

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={businessPartner.id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [businessPartner.id],
  )

  const showCustomAssessmentsSubsection = useMemo(
    () => isFetching || isError || showCustomAssessments,
    [isFetching, isError, showCustomAssessments],
  )

  const standardAssessments = useMemo(
    () => (
      <CWPLayout>
        <CWPLayout.Half>
          <OverallAssessmentTile />
        </CWPLayout.Half>
        <BusinessPartnerTextualAssessments />
      </CWPLayout>
    ),
    [],
  )

  const renderContent = useCallback(() => {
    if (showCustomAssessmentsSubsection) {
      return (
        <>
          <Section titleText={t('subsections.standard.title')}>{standardAssessments}</Section>
          <Section titleText={t('subsections.custom.title')}>
            <CustomAssessments
              entityType={cwpEntityTypes.BUSINESS_PARTNER}
              entityId={businessPartner.id}
            />
          </Section>
        </>
      )
    }
    return standardAssessments
  }, [businessPartner.id, showCustomAssessmentsSubsection, standardAssessments, t])

  return (
    <Page
      isError={false}
      isLoading={false}
      renderHeaderTitle={() => (
        <BusinessPartnerHeader
          breadcrumbs={[
            { text: tNoPrefix('navigation.item.title.business-partner-assessments'), href: '' },
          ]}
          actions={
            <>
              <CreateBusinessPartnerEventAction />
              {markFavoriteAction}
            </>
          }
        />
      )}
      renderContent={renderContent}
      id="businessPartnerAssessmentsId"
    />
  )
}

export default BusinessPartnerAssessments
