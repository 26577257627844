import { DynamicPage } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import GeneralInformationTile from 'components/domains/business-partners/tile/GeneralInformationTile'
import RolesTile from 'components/domains/business-partners/tile/general-information/RolesTile'
import KeyInternalContactsTileWrapper from 'components/domains/business-partners/tile/general-information/keyInternalContact/KeyInternalContactsTileWrapper'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import CWPLayout from 'components/ui/layout/CWPLayout'
import Header from 'components/ui/page/Header'
import determineValueStateBasedOnStatus from 'routes/business-partners/determineValueState'

const BusinessPartnerWithoutNeed2Know = ({ businessPartner }) => {
  const { t } = useTranslation()

  const subtitle = <EntityTypeAndIdWithClipboard id={businessPartner.id} />

  const generateBreadcrumbs = () => {
    if (businessPartner.id) {
      return [
        { text: t('navigation.item.title.business-partners'), href: '/business-partners' },
        { text: businessPartner.fullName },
      ]
    }
    return []
  }

  return (
    <DynamicPage
      headerTitle={
        <Header
          title={businessPartner.fullName}
          id={businessPartner.id}
          subtitle={subtitle}
          breadcrumbs={generateBreadcrumbs()}
          status={{
            text: businessPartner.status
              ? t(businessPartner.status, {
                  keyPrefix: 'pages.business-partner.general-information.status',
                })
              : '',
            valueState: determineValueStateBasedOnStatus(businessPartner.status),
          }}
        />
      }
      showHideHeaderButton={false}
    >
      <CWPLayout sixColumns>
        <CWPLayout.Half>
          <GeneralInformationTile
            showLink={false}
            streetName={businessPartner.address?.streetName ?? ''}
            postalCode={businessPartner.address?.postalCode ?? ''}
            city={businessPartner.address?.city ?? ''}
            country={businessPartner.address?.country?.name ?? ''}
          />
        </CWPLayout.Half>
        <CWPLayout.Half>
          <KeyInternalContactsTileWrapper />
        </CWPLayout.Half>
        <CWPLayout.Half>
          <RolesTile showLink={false} roles={businessPartner.roles ?? []} />
        </CWPLayout.Half>
      </CWPLayout>
    </DynamicPage>
  )
}

BusinessPartnerWithoutNeed2Know.propTypes = {
  businessPartner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
  }).isRequired,
}

export default BusinessPartnerWithoutNeed2Know
