import { DynamicPageHeader, FlexBox, ObjectStatus, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { forwardRef, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHref, useParams } from 'react-router-dom'
import {
  decisionStageStatus as possibleDecisionStageStatus,
  getObjectStatusForDecisionStageStatus,
  decisionStageVoterStatus as possibleDecisionStageVoterStatus,
  getObjectStatusForDecisionStageVoteStatus,
  decisionStageVoterStatus,
} from 'api/decision-process/decisionProcessApi'
import BusinessObjectLink from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import DecisionStageLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageLeaveConfirmationLink'
import styles from 'components/domains/business-events-and-tasks/decision-stage/approval/DecisionStageApprovalCardView.module.css'
import DisplayCardView from 'components/ui/card/DisplayCardView'
import DisplayCardViewItemView from 'components/ui/card/DisplayCardViewItemView'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import eventsAndTasksPaths from 'routes/business-events-and-tasks/eventsAndTasksPaths'
import paths from 'routes/paths'
const noop = () => {}

const hyphen = '-'

const DecisionStageApprovalCardView = forwardRef(
  (
    {
      decisionPaperVersion,
      decisionStageName,
      decisionStageStatus,
      voterStatus,
      declineReason,
      decisionDate,
      templateName,
      frozenOn,
      majorVersionSyncDate,
      minorVersionSyncDate,
      isTemplateCustomized,
    },
    ref,
  ) => {
    const {
      event: {
        id: eventId,
        info: { name: eventName },
        entityRef: { entityId, entityType },
      },
    } = useContext(BusinessEventsAndTasksContext)
    const { t: tNoPrefix } = useTranslation()
    const { t } = useTranslation('translation', {
      keyPrefix: 'components.business-events-and-tasks.decision-stage.approval-card',
    })
    const { format: formatDate } = useShortDateFormatter()
    const { format: formatDateAndTime } = useShortDateFormatter({
      hour: '2-digit',
      minute: '2-digit',
    })
    const { decisionStageId } = useParams()
    const eventHref = useHref(`/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}`)
    const decisionPaperHref = useHref(
      `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}/${eventsAndTasksPaths.eventDetail.decisionPaper}?version=${decisionPaperVersion}`,
    )
    const decisionStageHref = useHref(
      `/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}/decision-stages/${decisionStageId}`,
    )
    const {
      translationKey: decisionStageStatusTranslationsKey,
      objectStatus: decisionStageStatusObjectStatus,
    } = getObjectStatusForDecisionStageStatus(decisionStageStatus)
    const { translationKey: voterStatusTranslationKey, objectStatus: voterStatusObjectStatus } =
      getObjectStatusForDecisionStageVoteStatus(voterStatus)

    const prefixedTemplateName = useMemo(
      () => (isTemplateCustomized ? t('customized-template-name', { templateName }) : templateName),
      [isTemplateCustomized, t, templateName],
    )

    const showMinorVersionSyncDate = useMemo(
      () => minorVersionSyncDate && minorVersionSyncDate >= majorVersionSyncDate,
      [majorVersionSyncDate, minorVersionSyncDate],
    )

    const fieldDefinitions = useMemo(() => {
      const fields = [
        {
          value: <Text>{tNoPrefix(`events.entity-type.${entityType}`)}</Text>,
          label: t('label.business-object-type'),
        },
        {
          value: (
            <BusinessObjectLink
              entityId={entityId}
              entityType={entityType}
              CustomComponent={DecisionStageLeaveConfirmationLink}
            />
          ),
          label: t('label.business-object'),
        },
        {
          value: <DecisionStageLeaveConfirmationLink name={eventName} link={eventHref} />,
          label: t('label.event-name'),
        },
        {
          value: (
            <DecisionStageLeaveConfirmationLink
              name={t('decision-paper-version', { decisionPaperVersion })}
              link={decisionPaperHref}
            />
          ),
          label: t('label.linked-decision-paper'),
        },
        {
          value: prefixedTemplateName,
          label: t('label.template-name'),
        },
        {
          value: <Text wrapping>{frozenOn ? formatDateAndTime(frozenOn) : hyphen}</Text>,
          label: t('label.frozen-on'),
        },
        {
          value: (
            <Text wrapping>
              {majorVersionSyncDate ? formatDateAndTime(majorVersionSyncDate) : hyphen}
            </Text>
          ),
          label: t('label.last-synced-major'),
        },
        ...(showMinorVersionSyncDate
          ? [
              {
                value: <Text wrapping>{formatDateAndTime(minorVersionSyncDate)}</Text>,
                label: t('label.last-synced-minor'),
              },
            ]
          : []),
        {
          value: (
            <DecisionStageLeaveConfirmationLink name={decisionStageName} link={decisionStageHref} />
          ),
          label: t('label.decision-stage'),
        },
        {
          value: <Text wrapping>{decisionDate ? formatDate(decisionDate) : hyphen}</Text>,
          label: t('label.stage-decision-date'),
        },
        {
          value: (
            <ObjectStatus inverted state={decisionStageStatusObjectStatus}>
              {tNoPrefix(decisionStageStatusTranslationsKey)}
            </ObjectStatus>
          ),
          label: t('label.decision-status'),
        },
        ...(voterStatus === decisionStageVoterStatus.ignored
          ? []
          : [
              {
                value: (
                  <ObjectStatus inverted state={voterStatusObjectStatus}>
                    {tNoPrefix(voterStatusTranslationKey)}
                  </ObjectStatus>
                ),
                label: t('label.vote-status'),
              },
            ]),
      ]

      if (declineReason) {
        fields.push(
          {
            value: { sectionTitle: t('label.decline-reason') },
            isSectionTitle: true,
          },
          {
            customInfoComponent: (
              <DisplayCardViewItemView>
                <Text wrapping className={styles.declineText}>
                  {declineReason}
                </Text>
              </DisplayCardViewItemView>
            ),
          },
        )
      }

      return fields
    }, [
      tNoPrefix,
      entityType,
      t,
      entityId,
      eventName,
      eventHref,
      decisionPaperVersion,
      decisionPaperHref,
      prefixedTemplateName,
      frozenOn,
      formatDateAndTime,
      majorVersionSyncDate,
      showMinorVersionSyncDate,
      minorVersionSyncDate,
      decisionStageName,
      decisionStageHref,
      decisionDate,
      formatDate,
      decisionStageStatusObjectStatus,
      decisionStageStatusTranslationsKey,
      voterStatus,
      voterStatusObjectStatus,
      voterStatusTranslationKey,
      declineReason,
    ])
    return (
      <DynamicPageHeader ref={ref} className={styles.header}>
        <FlexBox fitContainer>
          <DisplayCardView
            cardHeaderTitle={t('title')}
            isEditable={false}
            fieldDefinitions={fieldDefinitions}
            setEditMode={noop}
          />
        </FlexBox>
      </DynamicPageHeader>
    )
  },
)

DecisionStageApprovalCardView.displayName = 'DecisionStageApprovalCardView'

DecisionStageApprovalCardView.propTypes = {
  decisionPaperVersion: PropTypes.string.isRequired,
  decisionStageName: PropTypes.string.isRequired,
  decisionStageStatus: PropTypes.oneOf(Object.values(possibleDecisionStageStatus)).isRequired,
  voterStatus: PropTypes.oneOf(Object.values(possibleDecisionStageVoterStatus)).isRequired,
  declineReason: PropTypes.string,
  decisionDate: PropTypes.string,
  templateName: PropTypes.string,
  frozenOn: PropTypes.string,
  majorVersionSyncDate: PropTypes.string,
  minorVersionSyncDate: PropTypes.string,
  isTemplateCustomized: PropTypes.bool,
}

export default DecisionStageApprovalCardView
