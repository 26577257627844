import PropTypes from 'prop-types'
import useApprovalRequestAuthorityLevelCalculatorTableColumnDefinitions from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/approval-request/authority-level-calculator/useApprovalRequestAuthorityLevelCalculatorTableColumnDefinitions'
import SharedAuthorityLevelCalculatorTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/authority-level-calculator/SharedAuthorityLevelCalculatorTile'

const ApprovalRequestAuthorityLevelCalculatorTile = ({ tileId, showConfirmationForLinks }) => (
  <SharedAuthorityLevelCalculatorTile
    tileId={tileId}
    columnDefinitionsFunction={useApprovalRequestAuthorityLevelCalculatorTableColumnDefinitions}
    showConfirmationForLinks={showConfirmationForLinks}
  />
)

ApprovalRequestAuthorityLevelCalculatorTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool,
}

export default ApprovalRequestAuthorityLevelCalculatorTile
