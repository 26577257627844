import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { usePercentageFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/shared/hooks/i18n/useI18n'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/shared/ui/card/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/financing-terms/shared/ui/card/DisplayCardViewItemView'

const PercentageField = ({ label, value, isFirstItem }) => {
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })

  return (
    <DisplayCardViewItemView>
      <DisplayCardViewInfoItemView
        label={label}
        isFirstItem={isFirstItem}
        value={!isNil(value) && !isNaN(value) ? formatPercentage(value) : '-'}
      />
    </DisplayCardViewItemView>
  )
}

PercentageField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  isFirstItem: PropTypes.bool,
}

export default PercentageField
