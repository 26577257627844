import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { assessmentTypes } from 'components/domains/business-partners/tile/assessments/assessmentTypes'
import { Assessment } from 'components/domains/business-partners/tile/general-information/allowedOperations'
import VersionedDisplayAndEditCard from 'components/ui/card/VersionedDisplayAndEditCard'
import VersionsHistoryButtonLoadingWrapper from 'components/ui/card/VersionsHistoryButtonLoadingWrapper'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useAssessmentVersions } from 'hooks/services/business-partners/assessments/useAssessmentVersions'
import useStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useStaffMemberByObjectIdOrEmail'
import useTextEditorCurrentContent from 'hooks/text-editor/useTextEditorCurrentContent'

const assessmentTypeTitleKeys = {
  [assessmentTypes.BackOffice]: 'back-office',
  [assessmentTypes.FrontOffice]: 'front-office',
  [assessmentTypes.Description]: 'description',
}

const BusinessPartnerTextualAssessment = ({ businessPartnerId, type, allowedOperations }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.assessments.assessment',
  })
  const { t: tNoPrefix } = useTranslation('translation')

  const [displayedVersion, setDisplayedVersion] = useState(0)

  const handleUpdateSuccess = ({ availableVersions = [] } = {}) => {
    const v = availableVersions.slice(-1)[0]?.version
    setDisplayedVersion(v)
  }

  const {
    isLoading,
    isError,
    isUpdateError: isErrorTextualAssessmentUpdate,
    isUpdateSuccess: isSuccessTextualAssessmentUpdate,
    mutate: updateTextualAssessment,
    data: { availableVersions: versionData } = {},
  } = useAssessmentVersions({ businessPartnerId, type, onUpdateSuccess: handleUpdateSuccess })

  const currentVersion = useMemo(() => {
    const currentEntry = versionData.slice(-1)[0]
    return currentEntry?.version
  }, [versionData])

  const lastUpdated = useMemo(() => {
    const currentEntry = versionData.find(({ version }) => version === displayedVersion)
    return {
      name: currentEntry?.createdBy ?? '',
      lastUpdatedOn: currentEntry?.createdAt ?? '',
    }
  }, [displayedVersion, versionData])

  const { data: staffMember } = useStaffMemberByObjectIdOrEmail({
    objectIdOrEmail: lastUpdated?.name,
  })
  const lastUpdatedByName = useMemo(
    () =>
      staffMember?.fullName ??
      `${lastUpdated?.name} (${tNoPrefix('components.ui.user.unknown-user')})`,
    [staffMember?.fullName, lastUpdated?.name, tNoPrefix],
  )

  const getVersionContent = useCallback(
    (versionToShow) => {
      const versionContent = versionData.find(({ version }) => version === versionToShow)
      return versionContent?.content ?? ''
    },
    [versionData],
  )

  const { currentContent, setCurrentContent, hasChanges } = useTextEditorCurrentContent(
    getVersionContent(displayedVersion),
  )

  const hasEditPermission = allowedOperations.includes(Assessment.write)

  useEffect(() => {
    // currentVersion => most recent/most up-to-date version
    // displayedVersion => what the users sees on screen
    // we set the displayedVersion to the currentVersion once the initial loading is finished
    // and only if there is no displayedVersion already (so the users gets to see the current version initially)
    // but no flickering/version switching when a version is already selected
    if (displayedVersion) return
    setDisplayedVersion(currentVersion)
  }, [currentVersion, displayedVersion])

  const handleSaveChanges = () => {
    updateTextualAssessment({
      content: currentContent,
    })
  }

  const handleCancelChanges = () => {
    const staleCurrentContent = getVersionContent(displayedVersion)
    setCurrentContent(staleCurrentContent)
  }

  const handleBackToCurrentVersionButtonClicked = useCallback(() => {
    setDisplayedVersion(currentVersion)
  }, [currentVersion])

  const handleVersionClicked = useCallback((selectedVersion) => {
    setDisplayedVersion(selectedVersion)
  }, [])

  const backToCurrentVersionButton = useMemo(() => {
    const showBackToCurrentVersionButton = !!displayedVersion && currentVersion > displayedVersion
    if (showBackToCurrentVersionButton) {
      return (
        <Button
          design={ButtonDesign.Emphasized}
          key="back-to-current-version-button"
          onClick={handleBackToCurrentVersionButtonClicked}
        >
          {tNoPrefix('components.ui.buttons.versions-history.back-to-current-version.text')}
        </Button>
      )
    }
  }, [handleBackToCurrentVersionButtonClicked, tNoPrefix, currentVersion, displayedVersion])

  const versionsHistoryButton = useMemo(
    () => (
      <VersionsHistoryButtonLoadingWrapper
        key="versions-history-button-wrapper"
        buttonId={`version-history-button-wrapper-${type}`}
        versionData={versionData.map((version) => ({
          ...version,
          lastUpdated: { name: version.createdBy, lastUpdatedOn: version.createdAt },
        }))}
        onItemClicked={handleVersionClicked}
      />
    ),
    [handleVersionClicked, versionData, type],
  )
  const tileVersionActions = useMemo(
    () => [backToCurrentVersionButton, versionsHistoryButton],
    [backToCurrentVersionButton, versionsHistoryButton],
  )

  return (
    <LoadingStateWrapper isLoading={isLoading} isError={isError}>
      <VersionedDisplayAndEditCard
        id={`${type}-${businessPartnerId}`}
        tileText={t(assessmentTypeTitleKeys[type])}
        currentContent={currentContent ?? ''}
        setCurrentContent={setCurrentContent}
        lastUpdatedBy={isEmpty(versionData) ? null : lastUpdatedByName}
        lastUpdatedAt={isEmpty(versionData) ? null : lastUpdated?.lastUpdatedOn}
        hasChanges={hasChanges}
        isEditEnabled={hasEditPermission}
        saveChanges={handleSaveChanges}
        cancelChanges={handleCancelChanges}
        saveHookIsSuccess={isSuccessTextualAssessmentUpdate}
        saveHookIsError={isErrorTextualAssessmentUpdate}
        additionalEditActions={tileVersionActions}
      />
    </LoadingStateWrapper>
  )
}

BusinessPartnerTextualAssessment.propTypes = {
  businessPartnerId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  allowedOperations: PropTypes.array.isRequired,
}

export default BusinessPartnerTextualAssessment
