import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteConditionWatcher = (mutateOptions) => {
  const { delete: deleteCall } = useAccessTokenRequest()
  return useMutation(
    ({ conditionId, conditionType, watcherId }) =>
      deleteCall({
        path: `/conditions/${conditionType}/${conditionId}/watchers/${watcherId}`,
      }),
    mutateOptions,
  )
}
export default useDeleteConditionWatcher
