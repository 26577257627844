import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Entity from 'components/ui/data/Entity'

export const DescriptionCell = ({ cell }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.tenancy.as-branding-or-franchise-provider',
  })
  const { original } = cell.row

  return (
    <>
      {original.description === undefined ? (
        <Entity className="sapUiSmallMargin" id={original.id} name={t('anonymous-tenant')} />
      ) : (
        <Entity
          className="sapUiSmallMargin"
          openInNewTab={true}
          id={original.id}
          name={original.description}
          link={
            original.isBusinessPartner
              ? `/business-partners/${original.id}`
              : `/properties/${original.id}`
          }
        />
      )}
    </>
  )
}

DescriptionCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string,
        description: PropTypes.string,
        isBusinessPartner: PropTypes.bool,
      }),
    }),
  }),
}
