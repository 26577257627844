import { MessageStrip, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BusinessPartnerArrearsContext } from 'routes/business-partners/arrears/BusinessPartnerArrearsContext'

const ArrearsBcaAmountWarningBanner = () => {
  const { products, isLoading } = useContext(BusinessPartnerArrearsContext) ?? {
    products: [],
    isLoading: true,
  }

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.arrears',
  })

  const isErrorBcaAmounts = useMemo(
    () => !isLoading && products.some((product) => !!product.isErrorBcaAmounts),
    [isLoading, products],
  )

  if (isErrorBcaAmounts) {
    return (
      <MessageStrip design={MessageStripDesign.Warning} hideCloseButton>
        {t('bca-amount-warning')}
      </MessageStrip>
    )
  }
}

export default ArrearsBcaAmountWarningBanner
