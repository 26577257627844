import {
  FlexBox,
  FlexBoxJustifyContent,
  Label,
  Text,
  FlexBoxDirection,
  Link,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { multiPropertyValuations } from 'api/property/valuation/valuations'
import { isMissingPermissionError } from 'api/requests'
import PropertyFinancingStatusCell from 'components/domains/properties/common/PropertyFinancingStatusCell'
import PropertyImage from 'components/domains/properties/general-information/image/PropertyImage'
import FormattedAddress from 'components/ui/data/FormattedAddress'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import styles from 'components/ui/map/internal/CustomPropertyMarkerPopup.module.css'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { useShortDateFormatter, useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import { usePropertyImages } from 'hooks/services/properties/images/usePropertyImages'
import useMultiPropertyValuations from 'hooks/services/properties/valuations/useMultiPropertyValuations'
import paths from 'routes/paths'

const CustomPropertyMarkerPopup = ({
  marker,
  propertyData,
  propertyIsLoading,
  propertyIsError,
}) => {
  const { uuid: propertyUuid } = marker
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ui.map.custom-property-marker-popup',
  })
  const { format: formatDate } = useShortDateFormatter()
  const formatCurrency = useCustomizableCurrencyFormatter()
  const { country, street, houseNumber, zipCode, city } = marker.address

  const {
    isLoading: isValuationsDataLoading,
    isError: isValuationsDataError,
    error: valuationsError,
    data: valuationsData,
  } = useMultiPropertyValuations([propertyUuid])

  const {
    isLoading: isLoadingImage,
    isError: isErrorImage,
    data: propertyImages,
    error: propertyImagesError,
  } = usePropertyImages(propertyUuid)

  const property = propertyData?.properties?.find((p) => p.uuid === propertyUuid)
  const linkedDealFallback = property?.financingStatusCode

  const renderValuationsMarketValue = useCallback(() => {
    const marketValueData =
      valuationsData?.valuations?.[propertyUuid]?.[multiPropertyValuations.marketValue]
        ?.value_amount

    if (!marketValueData) {
      return <Text>{'-'}</Text>
    }
    return <Text>{formatCurrency(marketValueData.number, marketValueData.currency)}</Text>
  }, [formatCurrency, propertyUuid, valuationsData])

  const loadingError = useMemo(
    () => <Text className={styles.errorText}>{t('loading.error')}</Text>,
    [t],
  )

  const loadingPermissionsError = useMemo(
    () => <Text className={styles.errorText}>{t('loading.error.missing-permissions')}</Text>,
    [t],
  )

  const isPropertyImagesMissingPermissionError = useMemo(
    () => isErrorImage && isMissingPermissionError(propertyImagesError),
    [isErrorImage, propertyImagesError],
  )

  const marketValue = useMemo(() => {
    if (isValuationsDataError && isMissingPermissionError(valuationsError)) {
      return loadingPermissionsError
    }
    return (
      <SmallLoadingWrapper
        isLoading={isValuationsDataLoading}
        isError={isValuationsDataError}
        error={loadingError}
        renderContent={renderValuationsMarketValue}
      />
    )
  }, [
    isValuationsDataError,
    valuationsError,
    isValuationsDataLoading,
    renderValuationsMarketValue,
    loadingError,
    loadingPermissionsError,
  ])

  const renderLinkedDeal = useCallback(() => {
    const financingStatusCode = {
      financingStatusCode: marker.linkedDeal ?? linkedDealFallback,
    }

    return <PropertyFinancingStatusCell propertyData={financingStatusCode} />
  }, [linkedDealFallback, marker.linkedDeal])

  const linkedDeal = useMemo(
    () => (
      <SmallLoadingWrapper
        isLoading={propertyIsLoading}
        isError={propertyIsError}
        error={loadingError}
        renderContent={renderLinkedDeal}
      />
    ),
    [loadingError, renderLinkedDeal, propertyIsError, propertyIsLoading],
  )

  const propertyImagesLength = propertyImages?.length || 0

  const propertyImageContent = useMemo(() => {
    if (!isPropertyImagesMissingPermissionError && propertyImagesLength !== 0) {
      return (
        <div>
          <FlexBox>
            <PropertyImage
              imageTitle={propertyImages[0].meta.name}
              imageUuid={propertyImages[0].image_uuid}
              isThumbnail={true}
              className={styles.bottomMargin}
            />
            <FlexBox direction={FlexBoxDirection.Column} className={styles.leftMargin}>
              <h3 className={styles.bottomMargin}>
                <Link href={`/${paths.properties}/${marker.id}`} target="_blank" rel="noreferrer">
                  <b>{marker.name}</b>
                </Link>
              </h3>
              <h4>{marker.id}</h4>
            </FlexBox>
          </FlexBox>
          <hr className={styles.hrLine} />
        </div>
      )
    }
    return (
      <div>
        <h3 className={styles.bottomMargin}>
          <Link href={`/${paths.properties}/${marker.id}`} target="_blank" rel="noreferrer">
            <b>{marker.name}</b>
          </Link>
        </h3>
        <h4>{marker.id}</h4>
        <hr className={[styles.hrLine, styles.emptyHrLine].join(' ')} />
      </div>
    )
  }, [
    isPropertyImagesMissingPermissionError,
    marker.id,
    marker.name,
    propertyImages,
    propertyImagesLength,
  ])

  const renderContent = useCallback(
    () => (
      <>
        {propertyImageContent}
        <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween} className={styles.textInfo}>
          <Label>{t('address')}</Label>
          <Text className={styles.textEndAlign}>
            <FormattedAddress
              country={country}
              street={street}
              houseNumber={houseNumber}
              zipCode={zipCode}
              city={city}
            />
          </Text>
        </FlexBox>
        <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween} className={styles.textInfo}>
          <Label>{t('type')}</Label>
          <Text className={styles.textEndAlign}>{marker.propertyType}</Text>
        </FlexBox>
        <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween} className={styles.textInfo}>
          <Label>{t('creation-date')}</Label>
          <Text>{formatDate(marker.creationDate)}</Text>
        </FlexBox>
        <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween} className={styles.textInfo}>
          <Label>{t('market-value')}</Label>
          {marketValue}
        </FlexBox>
        <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween}>
          <Label>{t('linked-deal')}</Label>
          {linkedDeal}
        </FlexBox>
      </>
    ),
    [
      city,
      country,
      formatDate,
      houseNumber,
      linkedDeal,
      marker.creationDate,
      marker.propertyType,
      marketValue,
      propertyImageContent,
      street,
      t,
      zipCode,
    ],
  )

  return (
    <LoadingStateWrapper
      isError={isErrorImage && !isPropertyImagesMissingPermissionError}
      isLoading={isLoadingImage}
      renderContent={renderContent}
      errorTitle={t('error.title')}
      errorDescription={t('error.description')}
      errorDetails={''}
    />
  )
}

CustomPropertyMarkerPopup.propTypes = {
  marker: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    address: PropTypes.exact({
      country: PropTypes.string,
      street: PropTypes.string,
      houseNumber: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
    }).isRequired,
    propertyType: PropTypes.string,
    uuid: PropTypes.string.isRequired,
    linkedDeal: PropTypes.string,
    creationDate: PropTypes.string,
  }),
  propertyData: PropTypes.shape({
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
        financingStatusCode: PropTypes.string,
      }),
    ),
  }),
  propertyIsLoading: PropTypes.bool,
  propertyIsError: PropTypes.bool,
}

export default CustomPropertyMarkerPopup
