import { MessageBoxActions, MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import { useCallback } from 'react'
import useDealErrorCodeTranslation from 'components/domains/deals/creation-dialog/useDealErrorCodeTranslation'
import styles from 'components/domains/deals/creation-dialog/useShowDealErrorCodeMessageBox.module.css'
import TextWithExpandableJson from 'components/domains/deals/message/TextWithExpandableJson'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'

const useShowDealErrorCodeMessageBox = () => {
  const showMessageBox = useShowMessageBox()
  const getTextByCode = useDealErrorCodeTranslation()

  const renderWarnings = useCallback(
    (warnings, fallbackWarningMessage) => {
      if (!warnings?.length) return
      return (
        <div className={styles.warningsContainer}>
          {warnings.map((warning, i) => (
            <TextWithExpandableJson
              key={`${i}-${warning.id}`}
              className={styles.warning}
              text={getTextByCode(warning.code) ?? fallbackWarningMessage}
              json={warning}
            />
          ))}
        </div>
      )
    },
    [getTextByCode],
  )

  return useCallback(
    (
      { warnings, type = MessageBoxTypes.Warning, fallbackWarningMessage, ...messageBoxProps },
      container,
    ) =>
      showMessageBox(
        {
          ...messageBoxProps,
          type,
          actions: [MessageBoxActions.Close],
          children: renderWarnings(warnings, fallbackWarningMessage),
        },
        container,
      ),
    [renderWarnings, showMessageBox],
  )
}

export default useShowDealErrorCodeMessageBox
