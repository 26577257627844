// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VHvMrKs_7O88w4yWszgd {
  gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/standard-component-indicator/StandardComponentIndicator.module.css"],"names":[],"mappings":"AAAA;EACE,QAAQ;AACV","sourcesContent":[".standardComponentWrapper {\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"standardComponentWrapper": `VHvMrKs_7O88w4yWszgd`
};
export default ___CSS_LOADER_EXPORT___;
