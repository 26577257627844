import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const useEditDecisionStage = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()
  const { parse, localePattern } = useShortDateFormatter()
  return useMutation(
    ({ decisionStageId, eventId, decisionDate, voteStatusCode, condition }) =>
      patch({
        path: `/events/${eventId}/decision-stages/${decisionStageId}`,
        body: snakecaseKeys({
          info: {
            decisionDate:
              decisionDate === undefined ? undefined : parse(decisionDate, localePattern),
            overarchingVoteOption: voteStatusCode,
            condition,
          },
        }),
      }),
    mutationOptions,
  )
}

export default useEditDecisionStage
