import { useQuery } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * Returns all costs for given property uuids
 * The Costs structure is as follows:
 *
 * @example
 * {
 *  "costs_with_last_modernization_date": [
 *     {
 *        "property_uuid": "1555ffe2-ef9d-1eed-9db1-77eddbf37d30",
 *        "last_modernization_date": "2023-03-28",
 *        "costs": [
 *          {
 *           "cost_id": "1555ffe2-ef9d-1edd-b0e1-1e6e6b3200c3",
 *           "cost_type_code": "000004",
 *           "cost_type_code_name": "Kosten der Eintragung",
 *           "cost_description": "CostTest1",
 *           "cost_key_date": "2020-01-12",
 *           "actual_cost_amount": {
 *               "number": 109000,
 *               "currency": "EUR"
 *           },
 *          },
 *          {
 *           "cost_id": "1555ffe2-ef9d-1eed-b0d1-90ffc0e46980",
 *           "cost_type_code": "000003",
 *           "cost_type_code_name": "Notarkosten",
 *           "cost_description": "CostTest2",
 *           "cost_key_date": "2020-11-12",
 *           "actual_cost_amount": {
 *               "number": 990000,
 *               "currency": "EUR"
 *           },
 *          },
 *        ],
 *      },
 *   ],
 * }

 * @returns {{isLoading: boolean, isError: boolean, data: {}}}
 */

export const useCosts = (propertyUuids, options = {}) => {
  const { post } = useAccessTokenRequest()
  return useCamelizedResponse(
    useQuery({
      queryKey: ['properties', propertyUuids, 'costs'],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        const { data } = await post({
          path: '/properties/costs',
          body: {
            property_uuids: propertyUuids,
          },
        })
        return data
      },
      ...options,
      enabled: !isNil(options.enabled)
        ? options.enabled && !isEmpty(propertyUuids)
        : !isEmpty(propertyUuids),
    }),
  )
}
