import { TableRowType, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Entity from 'components/ui/data/Entity'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'

const BusinessObjectSelectionTable = ({
  entitySuggestions,
  isLoading,
  isError,
  onSelectTableRow,
  hasFilters,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.business-object-selection.table',
  })

  const columns = useMemo(
    () => [
      {
        title: t('entity-name'),
        columnKey: 'entityName',
        isSelectableForHiding: false,
      },
      {
        title: t('entity-type'),
        columnKey: 'entityType',
        isSelectableForHiding: false,
      },
    ],
    [t],
  )

  const onRowClick = useCallback(
    ({ entityType, entityId, entityName, entityDisplayId }) =>
      ({ target }) => {
        if (target.text === entityName) {
          return
        }
        onSelectTableRow({ entityId, entityType, entityName, entityDisplayId })
      },
    [onSelectTableRow],
  )

  const tableData = useMemo(
    () =>
      entitySuggestions?.map(({ name, displayId, id, link, entityType }) => ({
        rowKey: `${name}-${id}`,
        rowProperties: {
          type: TableRowType.Active,
          onClick: onRowClick({
            entityType,
            entityId: id,
            entityName: name,
            entityDisplayId: displayId,
          }),
        },
        entityName: {
          value: name,
          cellComponent: <Entity name={name} id={displayId} link={link} openInNewTab />,
        },
        entityType: {
          value: entityType,
          cellComponent: <Text>{t(`entity-types.${entityType}`)}</Text>,
        },
      })) ?? [],
    [entitySuggestions, t, onRowClick],
  )

  const additionalTableProperties = useMemo(
    () => ({
      busy: hasFilters ? isLoading : false,
      busyDelay: 0,
    }),
    [hasFilters, isLoading],
  )

  const renderTable = useCallback(
    () => (
      <SortedTable
        toolbarConfig={{ title: t('title') }}
        noDataText={t('empty')}
        columnDefinitions={columns}
        tableData={tableData}
        additionalTableProperties={additionalTableProperties}
      />
    ),
    [columns, additionalTableProperties, t, tableData],
  )

  return (
    <RequestStateResolver
      isError={isError}
      isLoading={false}
      errorToDisplay={<ErrorDataUnavailableInContent />}
      renderContent={renderTable}
    />
  )
}

BusinessObjectSelectionTable.propTypes = {
  entitySuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      entityType: PropTypes.string.isRequired,
    }),
  ),
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  hasFilters: PropTypes.bool.isRequired,
  onSelectTableRow: PropTypes.func.isRequired,
}

export default BusinessObjectSelectionTable
