import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import AnnualReviewDealOverviewSponsorGuarantorOverviewBusinessPartnerSelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/involved-parties/sponsor-guarantor-overview/AnnualReviewDealOverviewSponsorGuarantorOverviewBusinessPartnerSelect'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/involved-parties/sponsor-guarantor-overview/AnnualReviewDealOverviewSponsorGuarantorOverviewTile.module.css'
import useAnnualReviewDealOverviewSelectedDeal from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/utils/useAnnualReviewDealOverviewSelectedDeal'
import MultipleDealSelectionPdfViewWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/wrapper/MultipleDealSelectionPdfViewWrapper'
import SponsorGuarantorOverviewAddress from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/SponsorGuarantorOverviewAddress'
import SponsorGuarantorOverviewAppreciation from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/SponsorGuarantorOverviewAppreciation'
import SponsorGuarantorOverviewFinancialIndicators from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/SponsorGuarantorOverviewFinancialIndicators'
import SponsorGuarantorOverviewInformation from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/SponsorGuarantorOverviewInformation'
import SponsorGuarantorOverviewRatings from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/sponsor-guarantor-overview/SponsorGuarantorOverviewRatings'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const emptyArray = []
const emptyObject = {}

const AnnualReviewDealOverviewSponsorGuarantorOverviewTile = ({
  tileId,
  showConfirmationForLinks,
  isPdfView,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.annual-review.bp-sponsor-guarantor-overview',
  })
  const {
    data: { multipleCategorizedBusinessPartners },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { selectedDealIndex: currentSelectedDealIndex } =
    useAnnualReviewDealOverviewSelectedDeal(tileId)

  const {
    guarantors = emptyArray,
    sponsors = emptyArray,
    guarantorsAndSponsors = emptyArray,
  } = multipleCategorizedBusinessPartners[currentSelectedDealIndex] ?? emptyObject

  const allBusinessPartners = useMemo(
    () => [...sponsors, ...guarantorsAndSponsors, ...guarantors],
    [guarantors, guarantorsAndSponsors, sponsors],
  )
  const [selectedBp, setSelectedBp] = useState(allBusinessPartners[0])

  useEffect(() => {
    dispatch(
      setSourceRender({
        tileId,
        sourceRender: { businessPartnerId: selectedBp?.information?.businessPartnerId },
      }),
    ) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBp, multipleCategorizedBusinessPartners])

  // Reset selection of business partner when the underlying deal selection changes
  useEffect(() => {
    setSelectedBp(allBusinessPartners[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedDealIndex])

  const renderBusinessPartnerInfo = useCallback(
    (businessPartner) => (
      <>
        <SponsorGuarantorOverviewInformation
          businessPartner={businessPartner}
          showConfirmationForLinks={showConfirmationForLinks}
        />
        <SponsorGuarantorOverviewAddress businessPartner={businessPartner} />
        <SponsorGuarantorOverviewRatings businessPartner={businessPartner} />
        <SponsorGuarantorOverviewFinancialIndicators businessPartner={businessPartner} />
        <SponsorGuarantorOverviewAppreciation businessPartner={businessPartner} />
      </>
    ),
    [showConfirmationForLinks],
  )

  const contentCreator = useCallback(
    ({ selectedDealIndex }) => {
      const {
        guarantors: internalGuarantors = [],
        sponsors: internalSponsors = [],
        guarantorsAndSponsors: internalGuarantorsAndSponsors = [],
      } = multipleCategorizedBusinessPartners?.[selectedDealIndex] ?? {}

      const allBusinessPartnersForCurrentDeal = [
        ...internalSponsors,
        ...internalGuarantorsAndSponsors,
        ...internalGuarantors,
      ]

      if (isNil(selectedBp)) {
        return (
          <FlexBox
            className={styles.listCardEmptyContent}
            alignItems={FlexBoxAlignItems.Center}
            justifyContent={FlexBoxJustifyContent.Center}
          >
            {t('empty')}
          </FlexBox>
        )
      }

      if (isPdfView) {
        return (
          <>
            {allBusinessPartnersForCurrentDeal.map((businessPartner) => (
              <Fragment key={businessPartner.information.businessPartnerId}>
                <Title level={TitleLevel.H3} className={styles.pdfViewSeparatorTitle}>
                  {businessPartner.information.name}
                </Title>
                {renderBusinessPartnerInfo(businessPartner)}
              </Fragment>
            ))}
          </>
        )
      }

      return (
        <>
          <AnnualReviewDealOverviewSponsorGuarantorOverviewBusinessPartnerSelect
            tileId={tileId}
            selectedBp={selectedBp}
            setSelectedBp={setSelectedBp}
          />
          {renderBusinessPartnerInfo(selectedBp)}
        </>
      )
    },
    [
      isPdfView,
      multipleCategorizedBusinessPartners,
      renderBusinessPartnerInfo,
      selectedBp,
      t,
      tileId,
    ],
  )

  return (
    <FlexBox className={styles.listCardWrapper} direction={FlexBoxDirection.Column}>
      <MultipleDealSelectionPdfViewWrapper
        tileId={tileId}
        contentCreator={contentCreator}
        isPdfView={isPdfView}
      />
    </FlexBox>
  )
}

AnnualReviewDealOverviewSponsorGuarantorOverviewTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  showConfirmationForLinks: PropTypes.bool.isRequired,
  isPdfView: PropTypes.bool,
}

export default AnnualReviewDealOverviewSponsorGuarantorOverviewTile
