import { useRequest } from 'hooks/services/baseService'

const usePropertyMonitoringProxiesAllowedOperations = () =>
  useRequest({
    path: `/property-monitoring/proxies/allowed-operations`,
    useCache: true,
    keys: ['property-monitoring', 'proxies', 'allowed-operations'],
  })

export default usePropertyMonitoringProxiesAllowedOperations
