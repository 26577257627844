/**
 * This is a simplified implementation of lodash.set with additional
 * safeguards against prototype pollution.
 * This custom implementation can be removed and replaced by lodash.set
 * again once the prototype pollution vulnerability is fixed.
 * One open PR (as of writing this comment) that fixes this is:
 * https://github.com/lodash/lodash/pull/5776.
 */

const attributeNameBanlist = ['__proto__', 'prototype', 'constructor']
const pathSplitRegex = /[.[\]]/g

const pathToSegments = (path) => {
  if (Array.isArray(path)) {
    return path.flatMap((pathPart) => pathToSegments(pathPart))
  }
  if (typeof path === 'string') {
    return path.split(pathSplitRegex).filter((x) => !!x.trim())
  }
  return [String(path)]
}

const toInt = (value, defaultValue) => {
  const result = parseInt(value)
  return isNaN(result) ? defaultValue : result
}

/**
 * Sets the value at path of object. If a portion of path doesn’t exist, it’s created.
 *
 * @example
 * const obj = { a: { b: 2 } };
 * set(obj, 'a.c', 1);
 * // => { a: { b: 2, c: 1 } }
 *
 * // `[number]` can be used to access array elements
 * set(obj, 'a.c[0]', 'hello');
 * // => { a: { b: 2, c: ['hello'] } }
 *
 * // numbers with dots are treated as keys
 * set(obj, 'a.c.0.d', 'world');
 * // => { a: { b: 2, c: { 0: { d: 'world' } } }
 *
 * // supports numbers in keys
 * set(obj, 'a.e0.a', 1);
 * // => { a: { e0: { a: 1 } } }
 *
 * @param obj The object to modify.
 * @param path The path of the property to set.
 * @param value The value to set.
 * @returns The modified object.
 */
const set = (initial, path, value) => {
  const segments = pathToSegments(path)
  if (segments.some((segment) => attributeNameBanlist.includes(segment))) {
    return initial
  }
  if (!initial || segments.length === 0) return initial
  const _set = (node) => {
    if (segments.length > 1) {
      const key = segments.shift()
      const nextIsNum = toInt(segments[0], null) !== null
      if (node[key] === undefined) {
        node[key] = nextIsNum ? [] : {}
      }
      _set(node[key])
    } else {
      node[segments[0]] = value
    }
  }
  _set(initial)
  return initial
}

export default set
