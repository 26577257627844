export const propertyAllowedOperations = {
  propertyRead: 'Property_Read',
  propertyValuationRead: 'PropertyValuation_Read',
  propertyValuationUpdate: 'PropertyValuation_Update',
  propertyValuationRequestCreate: 'PropertyValuationRequest_Create',
  propertyValuationRequestUpdate: 'PropertyValuationRequest_Update',
  propertyValuationRequestDraftCreate: 'PropertyValuationRequestDraft_Create',
  propertyCustomFieldRead: 'PropertyCustomField_Read',
  propertyCustomFieldUpdate: 'PropertyCustomField_Update',
}
