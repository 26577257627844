import {
  Button,
  ButtonDesign,
  CheckBox,
  Dialog,
  Toolbar,
  ToolbarDesign,
  ToolbarStyle,
  Label,
  MultiInput,
  FlexBox,
  FlexBoxDirection,
  TableRowType,
  TableGrowingMode,
  Token,
  Icon,
  Link,
} from '@fioneer/ui5-webcomponents-react'
import camelize from 'camelize'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MarketsOverviewFilterBar from 'components/domains/markets/filterbar/MarketsOverviewFilterBar'
import styles from 'components/domains/markets/market-selection/MarketMultiSelectionDialog.module.css'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import useMarkets from 'hooks/services/markets/useMarkets'
import paths from 'routes/paths'

const minimumRequiredSearchCharacters = 1
const defaultSelectedMarkets = []

const initialFilterOptions = {
  searchFilter: '',
  marketTypes: [],
  marketCountry: '',
  needsFilters: true,
  minLength: minimumRequiredSearchCharacters,
}

const areFiltersApplied = (filter) =>
  filter.searchFilter !== '' || filter.marketTypes.length !== 0 || filter.marketCountry !== ''

const marketArrayToMarketObject = (selectedMarketsArray) =>
  Object.fromEntries(selectedMarketsArray.map(({ name, id }) => [id, name]))

const marketObjectToMarketArray = (selectedMarketsObject) =>
  Object.entries(selectedMarketsObject).map(([id, name]) => ({ id, name }))

// This component is not using the default selection of ui5-table with Mode="MultiSelect" because
// the onSelectionChanged callback just gives us the currently and previously selected cells in each call
// of the whole table. This would infer for each collection we would need to create a diff on what changed from
// previous to current and therefore it is much easier to render the checkboxes and active states ourselves here.
const MarketMultiSelectionDialog = ({
  isOpen,
  onSelectionChange,
  onCancel,
  onClose,
  selectedMarkets = defaultSelectedMarkets,
}) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.markets.market-selection.dialog',
  })
  const [filter, setFilter] = useState(initialFilterOptions)
  const [currentlySelectedMarkets, setCurrentlySelectedMarkets] = useState({})

  useEffect(() => {
    setCurrentlySelectedMarkets(marketArrayToMarketObject(selectedMarkets))
  }, [selectedMarkets])

  const {
    isLoading,
    isError,
    isFetchingNextPage,
    data: marketsData = { total: 0, markets: [] },
    fetchNextPage,
    hasNextPage,
  } = useMarkets(undefined, filter)

  const onFilter = (newFilterValues) => {
    const camelizedFilterValues = camelize(newFilterValues)
    setFilter((currentFilter) => ({ ...currentFilter, ...camelizedFilterValues }))
  }

  const handleOnAddClick = useCallback(() => {
    onSelectionChange(marketObjectToMarketArray(currentlySelectedMarkets))
    onClose?.()
  }, [currentlySelectedMarkets, onClose, onSelectionChange])

  const handleOnCancelClick = useCallback(() => {
    setCurrentlySelectedMarkets(marketArrayToMarketObject(selectedMarkets))
    onCancel()
    onClose?.()
  }, [onCancel, onClose, selectedMarkets])

  const onClearAllSelectedMarkets = () => {
    setCurrentlySelectedMarkets({})
  }

  const getSelectedMarketForMarketId = useCallback(
    (searchMarketId) => currentlySelectedMarkets[searchMarketId],
    [currentlySelectedMarkets],
  )

  const removeCheckboxesFromCurrentlyRenderedMarkets = useCallback(() => {
    setCurrentlySelectedMarkets((currentState) => {
      const newState = { ...currentState }
      marketsData.markets.forEach(({ id: marketId }) => {
        delete newState[marketId]
      })
      return newState
    })
  }, [marketsData])
  const addCheckboxesToCurrentlyRenderedMarkets = useCallback(() => {
    setCurrentlySelectedMarkets((currentState) => {
      const newState = { ...currentState }
      marketsData.markets.forEach(({ id: marketId, info: { name: marketName } }) => {
        newState[marketId] = marketName
      })
      return newState
    })
  }, [marketsData])

  const areCurrentlySelectedAndSelectedMarketsTheSameIgnoringTheOrder = useCallback(
    () =>
      Object.keys(currentlySelectedMarkets).every((searchMarketId) =>
        selectedMarkets.find(({ id: marketId }) => marketId === searchMarketId),
      ) &&
      selectedMarkets.every(({ id: searchMarketId }) => currentlySelectedMarkets[searchMarketId]),
    [currentlySelectedMarkets, selectedMarkets],
  )

  const isAddButtonDisabled = useCallback(
    () => areCurrentlySelectedAndSelectedMarketsTheSameIgnoringTheOrder(),
    [areCurrentlySelectedAndSelectedMarketsTheSameIgnoringTheOrder],
  )

  const areAllTableCheckboxesChecked =
    marketsData.markets.length !== 0 &&
    Object.keys(currentlySelectedMarkets).length !== 0 &&
    marketsData.markets.every(({ id: searchId }) => !!currentlySelectedMarkets[searchId])

  const isAllCheckboxDisabled =
    !areFiltersApplied(filter) || isLoading || marketsData.total === 0 || isFetchingNextPage

  const handleAllCheckboxClicked = useCallback(
    () =>
      areAllTableCheckboxesChecked
        ? removeCheckboxesFromCurrentlyRenderedMarkets()
        : addCheckboxesToCurrentlyRenderedMarkets(),
    [
      addCheckboxesToCurrentlyRenderedMarkets,
      removeCheckboxesFromCurrentlyRenderedMarkets,
      areAllTableCheckboxesChecked,
    ],
  )

  const onRemoveSelectedMarket = (marketId) => {
    setCurrentlySelectedMarkets((currentState) => {
      const newState = { ...currentState }
      delete newState[marketId]
      return newState
    })
  }

  const handleSingleCheckBoxClicked = useCallback(
    ({ marketId, marketName }) =>
      () => {
        const selectedMarket = getSelectedMarketForMarketId(marketId)
        if (selectedMarket) {
          onRemoveSelectedMarket(marketId)
          return
        }
        setCurrentlySelectedMarkets((currentState) => {
          const newState = { ...currentState }
          newState[marketId] = marketName
          return newState
        })
      },
    [getSelectedMarketForMarketId],
  )

  const onTokenDelete = ({ detail: { token } }) => {
    const selectedMarketId = token.getAttribute('data-market-id')
    onRemoveSelectedMarket(selectedMarketId)
  }

  const marketSelectionColumns = useMemo(
    () => [
      {
        columnKey: 'checkbox',
        sortingDisabled: true,
        renderColumnContent: () => (
          <CheckBox
            checked={areAllTableCheckboxesChecked}
            onChange={handleAllCheckboxClicked}
            disabled={isAllCheckboxDisabled}
          />
        ),
        className: styles.tableCheckboxColumn,
      },
      {
        columnKey: 'marketName',
        title: t('table.columns.market-name'),
        sortingDisabled: true,
      },
      {
        columnKey: 'marketType',
        title: t('table.columns.market-type'),
        sortingDisabled: true,
      },
      {
        columnKey: 'marketCountry',
        title: t('table.columns.country'),
        sortingDisabled: true,
      },
    ],
    [t, areAllTableCheckboxesChecked, handleAllCheckboxClicked, isAllCheckboxDisabled],
  )

  const footerButtons = useMemo(
    () => (
      <div className={styles.footerButtons}>
        <Button
          onClick={handleOnAddClick}
          design={ButtonDesign.Emphasized}
          disabled={isAddButtonDisabled()}
        >
          {tNoPrefix('buttons.add')}
        </Button>
        <Button onClick={handleOnCancelClick} design={ButtonDesign.Transparent}>
          {tNoPrefix('buttons.cancel')}
        </Button>
      </div>
    ),
    [handleOnAddClick, handleOnCancelClick, isAddButtonDisabled, tNoPrefix],
  )

  const hasFilters = areFiltersApplied(filter)

  // Case data is cached from markets overview in the hook but no filter are applied
  const marketsTableData = useMemo(
    () =>
      !hasFilters
        ? []
        : marketsData.markets.map(
            ({
              id: marketId,
              info: { name: marketName },
              marketType: { value: marketType },
              area: {
                country: { name: marketCountry },
              },
            }) => {
              const isMarketSelected = !!getSelectedMarketForMarketId(marketId)
              return {
                rowKey: `market-select-${marketId}`,
                rowProperties: {
                  type: TableRowType.Inactive,
                  'data-market-id': marketId,
                  selected: isMarketSelected,
                },
                checkbox: {
                  value: isMarketSelected,
                  cellComponent: (
                    <CheckBox
                      checked={isMarketSelected}
                      onClick={handleSingleCheckBoxClicked({ marketId, marketName })}
                    />
                  ),
                },
                marketName: {
                  value: marketName,
                  cellComponent: (
                    <Link target="_blank" href={`/${paths.markets}/${marketId}`}>
                      {marketName}
                    </Link>
                  ),
                },
                marketType: {
                  value: marketType,
                  cellComponent: <Label>{marketType}</Label>,
                },
                marketCountry: {
                  value: marketCountry,
                  cellComponent: <Label>{marketCountry}</Label>,
                },
              }
            },
          ),
    [getSelectedMarketForMarketId, handleSingleCheckBoxClicked, hasFilters, marketsData],
  )

  const isMarketsQueryCurrentlyRunning = () => {
    if (!hasFilters) {
      return false
    }
    return isLoading || isFetchingNextPage
  }

  const additionalTableProperties = {
    busyDelay: 0,
    busy: isMarketsQueryCurrentlyRunning(),
    stickyColumnHeader: true,
    className: styles.selectedMarketsTable,
  }

  const calculateNoDataText = () => {
    if (isError) {
      return t('table.error')
    }
    if (hasFilters) {
      return t('table.empty.no-results')
    }
    return t('table.empty.select-filter')
  }

  const calculateGrowingButton = () => {
    if (!hasFilters) {
      return undefined
    }
    if (hasNextPage) {
      return TableGrowingMode.Button
    }
    return undefined
  }

  const selectedMarketsTokens = Object.entries(currentlySelectedMarkets).map(
    ([marketId, marketName]) => (
      <Token
        key={`selected-market-token-${marketId}`}
        text={marketName}
        data-market-id={marketId}
        closeIcon={<Icon name="decline" />}
      />
    ),
  )

  return (
    <Dialog
      open={isOpen}
      onAfterClose={() => {
        onClose?.()
      }}
      headerText={t('title')}
      className={styles.dialog}
      footer={footerButtons}
      draggable
      resizable
    >
      <MarketsOverviewFilterBar
        onGo={onFilter}
        searchFilter={filter.searchFilter}
        marketTypes={filter.marketTypes}
        marketCountry={filter.marketCountry}
      />
      <div className={styles.tableWrapper}>
        <Toolbar
          design={ToolbarDesign.Auto}
          toolbarStyle={ToolbarStyle.Clear}
          className={styles.toolbar}
        >
          <Label className="sapFontLargeSize sapTextColor">
            {t('table.title', { numberOfMarkets: hasFilters ? marketsData.total : 0 })}
          </Label>
        </Toolbar>
        <SortedTable
          columnDefinitions={marketSelectionColumns}
          tableData={marketsTableData}
          noDataText={calculateNoDataText()}
          additionalTableProperties={additionalTableProperties}
          paginationConfig={{
            growing: calculateGrowingButton(),
            growingButtonText: tNoPrefix('components.ui.tables.sorted-tables.growing-button-text'),
            growingButtonSubtext: `[${marketsTableData.length} / ${marketsData.total}]`,
            totalNumberOfItems: marketsData.total,
            loadMore: fetchNextPage,
          }}
        />
      </div>
      <div className={styles.selectedMarketsWrapper}>
        <FlexBox direction={FlexBoxDirection.Column} className={styles.selectedMarketsColumns}>
          <Label for="selected-markets" className="sapFontLargeSize sapTextColor">
            {t('input.selected-markets', {
              numberOfSelectedMarkets: Object.keys(currentlySelectedMarkets).length,
            })}
          </Label>
          <FlexBox>
            <MultiInput
              id="selected-markets"
              maxlength={0}
              className={styles.selectedMarkets}
              tokens={selectedMarketsTokens}
              onTokenDelete={onTokenDelete}
            />
            <Button
              icon="decline"
              design={ButtonDesign.Transparent}
              onClick={onClearAllSelectedMarkets}
            />
          </FlexBox>
        </FlexBox>
      </div>
    </Dialog>
  )
}

MarketMultiSelectionDialog.propTypes = {
  selectedMarkets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  isOpen: PropTypes.bool.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func,
}

export default MarketMultiSelectionDialog
