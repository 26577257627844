import { ObjectStatus, ValueState } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import useStatusTranslations from 'components/domains/deals/covenants/shared/useStatusTranslations'

const StatusToBadgeColor = {
  ACTIVE: ValueState.Success,
  PLANNED: ValueState.Warning,
  POSTPONED: ValueState.Information,
  CLOSED: ValueState.None,
}

const StatusCell = ({ status }) => {
  const { getTranslationForStatus } = useStatusTranslations()

  return (
    <ObjectStatus inverted state={StatusToBadgeColor[status]}>
      {getTranslationForStatus(status)}
    </ObjectStatus>
  )
}

StatusCell.propTypes = { status: PropTypes.string }

export default StatusCell
