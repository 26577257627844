import { DatePicker, Option, Select } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import RichTextTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/RichTextTile'
import { minimalToolbarConfig } from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/shared/CWPCKEditorToolbarConfigurations'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/non-ref-credit-decision/involved-parties/manual-checksheet/NonRefCreditDecisionCheckSheetTableEditCell.module.css'
import { questionTypes } from 'components/domains/business-events-and-tasks/decision-paper/tiles/non-ref-credit-decision/involved-parties/manual-checksheet/questions'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const NonRefCreditDecisionCheckSheetTableEditCell = ({ question, value, onChange }) => {
  const { localePattern, parse } = useShortDateFormatter()

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.non-ref-credit-decision.involved-parties.checksheet.table.cell',
  })

  const renderBoolean = (showNotApplicable = false) => (
    <Select
      onChange={(event) => {
        const newValue = event.detail.selectedOption.value
        onChange(question, newValue)
      }}
      className={styles.dropdown}
    >
      <Option selected={isNil(value)} />
      <Option value={'yes'} selected={value === 'yes'}>
        {t('yes')}
      </Option>
      <Option value={'no'} selected={value === 'no'}>
        {t('no')}
      </Option>
      {showNotApplicable && (
        <Option value={'n/a'} selected={value === 'n/a'}>
          {t('n/a')}
        </Option>
      )}
    </Select>
  )

  const renderSize = () => (
    <Select
      onChange={(event) => {
        const newValue = event.detail.selectedOption.value
        onChange(question, newValue)
      }}
      className={styles.dropdown}
    >
      <Option selected={isNil(value)} />
      <Option value={'low'} selected={value === 'low'}>
        {t('low')}
      </Option>
      <Option value={'medium'} selected={value === 'medium'}>
        {t('medium')}
      </Option>
      <Option value={'high'} selected={value === 'high'}>
        {t('high')}
      </Option>
    </Select>
  )

  const renderDatePicker = () => (
    <DatePicker
      value={value}
      formatPattern={localePattern}
      placeholder={localePattern}
      onChange={(event) => {
        const newValue = parse(event?.target?.value, localePattern) ?? null
        onChange(question, newValue)
      }}
      className={styles.datePicker}
    />
  )

  const renderRTE = () => (
    <RichTextTile
      isEditMode={true}
      isPdfView={false}
      currentContent={value}
      onChange={(newValue) => {
        onChange(question, newValue)
      }}
      toolbarConfig={minimalToolbarConfig}
    />
  )

  if (questionTypes.booleanWithNotApplicable.includes(question)) {
    return renderBoolean(true)
  }

  if (questionTypes.booleanWithoutNotApplicable.includes(question)) {
    return renderBoolean(false)
  }

  if (questionTypes.size.includes(question)) {
    return renderSize()
  }

  if (questionTypes.date.includes(question)) {
    return renderDatePicker()
  }

  if (questionTypes.comment.includes(question)) {
    return renderRTE()
  }
}

NonRefCreditDecisionCheckSheetTableEditCell.propTypes = {
  question: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default NonRefCreditDecisionCheckSheetTableEditCell
