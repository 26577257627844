import PropTypes from 'prop-types'

// mapping data to structure required by LoadingMultiComboBox
export const useKeyInternalContactFunctionsMapping = ({ isError, isLoading, data }) => ({
  data: data?.functions
    // filtering out non modifiable functions since ui5 does not support disabling individual MultiComboBoxItems
    .filter(({ modifiable }) => modifiable)
    .map(({ id, name, modifiable }) => ({
      code: id,
      displayName: name,
      modifiable,
    })),
  isLoading,
  isError,
})

useKeyInternalContactFunctionsMapping.propTypes = {
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  data: PropTypes.shape({
    functions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }),
}
