import { uniqWith } from 'lodash'
import get from 'lodash.get'
import isEqual from 'lodash.isequal'
import uniq from 'lodash.uniq'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { CurrentRentRollContext } from 'components/domains/properties/rent-roll/comparison/constants'
import useClassifyRentRollComparison from 'hooks/services/properties/kpis/comparison/useClassifyRentRollComparison'
import useMultiSegmentKpis from 'hooks/services/properties/kpis/useMultiSegmentKpis'
import { useRentRollByKeyDateAndValidFrom } from 'hooks/services/properties/useRentRollByKeyDateAndValidFrom'

export const useRentRollComparisonRows = ({ property, segments }) => {
  const currentRentRoll = useContext(CurrentRentRollContext)
  const {
    rental_units: currentRentalUnits,
    key_date: currentRentRollKeyDate,
    valid_from: currentRentRollValidFrom,
  } = currentRentRoll
  const currentRentRollDateEntry = {
    index: undefined,
    keyDate: currentRentRollKeyDate,
    validFrom: currentRentRollValidFrom,
  }

  const selectedCurrency =
    useSelector((state) => state.properties.commonCurrency.selectedCommonCurrency) ?? undefined

  const rentRollDateEntryToCompare = useSelector(
    (state) => state.properties.compareRentRoll.rentRollToCompare,
  )
  const rentRollKeyDateToCompare =
    rentRollDateEntryToCompare?.keyDate ?? currentRentRollDateEntry?.keyDate
  const rentRollValidFromToCompare =
    rentRollDateEntryToCompare?.validFrom ?? currentRentRollDateEntry?.validFrom

  const combinedRentRollDateEntries = uniq([
    currentRentRollDateEntry,
    rentRollDateEntryToCompare,
  ]).filter((dateEntry) => dateEntry !== undefined && dateEntry !== null && !isEqual(dateEntry, {}))
  const combinedRentRollKeyDates = combinedRentRollDateEntries.map((dateEntry) => dateEntry.keyDate)

  const {
    isLoading: isLoadingKpis,
    isError: isErrorKpis,
    data: segmentKpisPerKeyDate,
  } = useMultiSegmentKpis(
    property.uuid,
    segments.map((segment) => segment.uuid),
    {
      currency: selectedCurrency,
      keyDates: combinedRentRollKeyDates,
      withTenantGranularity: true,
    },
  )

  const {
    isLoading: isLoadingRentRollToCompare,
    isError: isErrorRentRollToCompare,
    data: rentRollToCompare,
  } = useRentRollByKeyDateAndValidFrom(
    property.uuid,
    rentRollKeyDateToCompare,
    rentRollValidFromToCompare,
  )

  const rentalUnitsToCompare = rentRollToCompare?.rental_units

  const tenantIds = uniq([
    ...(currentRentalUnits?.map((unit) => unit?.tenant) ?? []),
    ...(rentalUnitsToCompare?.map((unit) => unit?.tenant) ?? []),
  ]).filter((id) => id)

  const findKpiByKeyDate = (kpisPerKeyDate, keyDate) =>
    kpisPerKeyDate?.find((kpi) => kpi?.key_date === keyDate)

  const currentKpisData = findKpiByKeyDate(segmentKpisPerKeyDate, currentRentRollKeyDate)
  const compareKpisData = findKpiByKeyDate(segmentKpisPerKeyDate, rentRollKeyDateToCompare)

  const findKpiBySegmentAndTenant = (segmentKpis, segmentUuid, tenant) =>
    segmentKpis?.kpis
      ?.find((kpi) => kpi.segment_uuid === segmentUuid)
      ?.tenant_rent_roll_kpis.find((kpi) => kpi.tenant === tenant)

  const findRentalUnitsByTenantAndSegment = (rentalUnits, tenant, segment) =>
    rentalUnits?.filter(
      (unit) =>
        unit?.tenant === tenant &&
        unit?.segment_name === segment.name &&
        unit?.segment_usage_type_code === segment.usage_type_code,
    )

  const findTenantPerSegmentKpis = (kpis, rentalUnits) =>
    uniqWith(
      rentalUnits?.reduce((acc, { tenant, segment_name, segment_usage_type_code }) => {
        const segment = segments.find(
          (s) => s?.name === segment_name && s?.usage_type_code === segment_usage_type_code,
        )
        const anonymousTenant = tenant === ''
        if ((tenant && segment) || (anonymousTenant && segment)) {
          const rentalUnitsByTenantAndSegment = findRentalUnitsByTenantAndSegment(
            rentalUnits,
            tenant,
            { name: segment_name, usage_type_code: segment_usage_type_code },
          )
          const tenantSegmentKpis = findKpiBySegmentAndTenant(kpis, segment.uuid, tenant)
          if (!tenantSegmentKpis) {
            return acc
          }
          acc.push({
            tenant,
            anonymousTenant,
            segment,
            rentalUnits: rentalUnitsByTenantAndSegment,
            kpis: tenantSegmentKpis,
          })
        }
        return acc
      }, []) ?? [],
      (a, b) => a?.tenant === b?.tenant && a?.segment?.uuid === b?.segment?.uuid,
    )

  const currentTenantAndSegmentAndKpis = findTenantPerSegmentKpis(
    currentKpisData,
    currentRentalUnits,
  )

  const comparisonTenantAndSegmentAndKpis = compareKpisData
    ? findTenantPerSegmentKpis(compareKpisData, rentalUnitsToCompare)
    : null

  const calculateSum = (toReduce, key) =>
    toReduce.reduce((acc, { kpis }) => acc + get(kpis, key) || 0, 0)

  const calculateTotalAreaShare = (tenantAndSegmentAndKpis, tenantAndSegmentAndKpi) => {
    const totalAreaSum = calculateSum(tenantAndSegmentAndKpis, 'total_area_surface.value')
    return (tenantAndSegmentAndKpi?.kpis?.total_area_surface?.value || 0) / totalAreaSum || 0
  }

  const calculateAnnualizedCurrentRentShare = (tenantAndSegmentAndKpis, tenantAndSegmentAndKpi) => {
    const annualizedCurrentRentSum = calculateSum(
      tenantAndSegmentAndKpis,
      'annualized_current_rent.number',
    )
    return (
      (tenantAndSegmentAndKpi?.kpis?.annualized_current_rent?.number || 0) /
        annualizedCurrentRentSum || 0
    )
  }

  const convertKpisToRows = (tenantAndSegmentAndKpis) =>
    tenantAndSegmentAndKpis?.map((tenantAndSegmentAndKpi) => ({
      tenant: {
        id: tenantAndSegmentAndKpi?.tenant,
        anonymous: tenantAndSegmentAndKpi?.anonymousTenant,
        segmentUuid: tenantAndSegmentAndKpi?.segment?.uuid,
        segmentName: tenantAndSegmentAndKpi?.segment?.name,
        usageType: tenantAndSegmentAndKpi?.segment?.usage_type_name,
      },
      rentalUnits: {
        count: tenantAndSegmentAndKpi?.rentalUnits?.length,
      },
      totalArea: {
        surface: tenantAndSegmentAndKpi?.kpis?.total_area_surface,
        share: calculateTotalAreaShare(tenantAndSegmentAndKpis, tenantAndSegmentAndKpi),
        number_of_units: tenantAndSegmentAndKpi?.kpis?.total_number_of_units,
      },
      annualizedCurrentRent: {
        value: tenantAndSegmentAndKpi?.kpis?.annualized_current_rent,
        per_uom: tenantAndSegmentAndKpi?.kpis?.annualized_current_rent_per_uom,
        per_pieces: tenantAndSegmentAndKpi?.kpis?.annualized_current_rent_per_pieces,
        share: calculateAnnualizedCurrentRentShare(tenantAndSegmentAndKpis, tenantAndSegmentAndKpi),
      },
      wault: {
        expiry: tenantAndSegmentAndKpi?.kpis?.wault_to_expiry_in_years,
        break: tenantAndSegmentAndKpi?.kpis?.wault_to_break_in_years,
      },
    })) ?? []

  const {
    isLoading: isLoadingClassification,
    isError: isErrorClassification,
    data: classifiedComparisonData,
  } = useClassifyRentRollComparison({
    propertyUuids: [property.uuid],
    tenantIds: tenantIds,
    currentKeyDate: currentRentRollDateEntry?.keyDate,
    comparisonKeyDate: rentRollKeyDateToCompare,
    kpis: {
      current: convertKpisToRows(currentTenantAndSegmentAndKpis),
      comparison: convertKpisToRows(comparisonTenantAndSegmentAndKpis),
    },
  })

  const isLoading = isLoadingKpis || isLoadingRentRollToCompare || isLoadingClassification
  const isError = isErrorKpis || isErrorRentRollToCompare || isErrorClassification

  if (isLoading || isError) {
    return {
      isLoading: isLoading,
      isError: isError,
      data: [],
    }
  } else {
    return {
      isLoading: isLoadingKpis,
      isError: isErrorKpis,
      data: classifiedComparisonData,
    }
  }
}
