import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const useEditDecisionStageConditions = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()
  const { parse, localePattern } = useShortDateFormatter()
  return useMutation(
    ({ decisionStageId, eventId, decisionDate, decisionStatus }) =>
      patch({
        path: `/events/${eventId}/decision-stages/${decisionStageId}/conditions`,
        body: snakecaseKeys({
          info: {
            decisionDate:
              decisionDate === undefined ? undefined : parse(decisionDate, localePattern),
            decision_status: decisionStatus,
          },
        }),
      }),
    mutationOptions,
  )
}

export default useEditDecisionStageConditions
