import camelize from 'camelize'
import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import { staffMembersSchema } from 'components/domains/business-events-and-tasks/decision-paper/tiles/financial-ratio/kpi-chart/shared/staff-member/staffMembersSchema'
import { useRequest } from 'hooks/services/baseService'

const RETRIES = 3

/**
 * Get a staff member by their email
 * @deprecated This hook is deprecated for direct use. Use useStaffMemberByObjectIdOrEmail instead.
 * @param {{email: string | undefined}} data
 * @param {object} [options]
 */
const useStaffMemberByEmail = ({ email }, options) => {
  const params = new URLSearchParams()
  params.append('searchType', 'EMAIL')
  if (email) params.append('q', email)

  const response = useRequest({
    path: `/users?${params.toString()}`,
    useCache: true,
    keys: ['users', 'email', email],
    options: {
      enabled: !!email,
      retryOnMount: false,
      retry: (failureCount, error) => {
        if (isNotFoundError(error) || isMissingPermissionError(error)) {
          return false
        }
        return failureCount < RETRIES
      },
      ...options,
    },
  })

  return { ...response, data: staffMembersSchema.parse(camelize(response.data)) }
}

export default useStaffMemberByEmail
