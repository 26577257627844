import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useChineseWall = (dealUuid) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/chinese-wall`,
      translated: true,
      useCache: true,
      keys: ['deals', dealUuid, 'chinese-wall'],
    }),
  )

export default useChineseWall
