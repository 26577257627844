import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import EmptyTileContent from 'components/domains/business-events-and-tasks/decision-paper/EmptyTileContent'
import FormattedTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review-non-ref/shared/FormattedTextView'

const BusinessPartnerGeneralDescriptionTile = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles',
  })
  const { data } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  if (isEmpty(data?.generalDescription)) {
    return <EmptyTileContent title={t('empty.title')} subtitle={t('empty.subtitle')} />
  }

  return <FormattedTextView formattedText={data?.generalDescription} />
}

BusinessPartnerGeneralDescriptionTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default BusinessPartnerGeneralDescriptionTile
