import { Button, ButtonDesign, ComboBox, ComboBoxItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/conditions/reference-selection/ReferenceTypeSelectionInput.module.css'
import supportedReferenceTypesForConditionReferenceSelection from 'components/domains/conditions/reference-selection/supportedReferenceTypesForConditionReferenceSelection'

const ReferenceTypeSelectionInput = ({
  selectableReferenceTypes,
  onSelectionChange,
  selectedReferenceType,
  disableInput,
}) => {
  const { t: tEntityTypes } = useTranslation('translation', { keyPrefix: 'entity-types' })
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.reference-object-selection.type-selection',
  })

  const options = useMemo(
    () => [
      ...selectableReferenceTypes.map((entityType) => (
        <ComboBoxItem
          key={entityType}
          data-entity-type={entityType}
          text={tEntityTypes(entityType)}
        />
      )),
    ],
    [selectableReferenceTypes, tEntityTypes],
  )

  const onChange = useCallback(
    (event) => {
      onSelectionChange(event, event?.detail?.item?.dataset.entityType)
    },
    [onSelectionChange],
  )
  const onClearButtonClicked = useCallback(
    (event) => {
      onSelectionChange(event, '')
    },
    [onSelectionChange],
  )

  return (
    <ComboBox
      onSelectionChange={onChange}
      value={selectedReferenceType ? tEntityTypes(selectedReferenceType) : ''}
      placeholder={t('placeholder')}
      icon={
        selectedReferenceType && (
          <Button icon="decline" design={ButtonDesign.Transparent} onClick={onClearButtonClicked} />
        )
      }
      className={styles.select}
      disabled={disableInput}
    >
      {options}
    </ComboBox>
  )
}

ReferenceTypeSelectionInput.propTypes = {
  selectableReferenceTypes: PropTypes.arrayOf(
    PropTypes.oneOf(supportedReferenceTypesForConditionReferenceSelection),
  ).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  selectedReferenceType: PropTypes.string,
  disableInput: PropTypes.bool,
}

export default ReferenceTypeSelectionInput
