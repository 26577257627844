import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultiPropertyRentRolls = ({
  propertyUuids = [],
  keyDatesWithValidFrom = [],
  includeCurrentRentRoll = false,
}) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: propertyUuids.flatMap((propertyUuid) => {
        const requestsByKeyDateAndValidFroms = keyDatesWithValidFrom.map(
          ({ keyDate, validFrom }) => ({
            path: `/properties/${propertyUuid}/rent-roll/key-date/${keyDate}/valid-from/${validFrom}`,
            keys: ['properties', propertyUuid, 'rent-roll', keyDate, validFrom],
          }),
        )
        if (includeCurrentRentRoll) {
          const currentRentRollRequest = [
            {
              path: `/properties/${propertyUuid}/rent-roll`,
              keys: ['properties', propertyUuid, 'rent-roll'],
            },
          ]
          return currentRentRollRequest.concat(requestsByKeyDateAndValidFroms)
        } else {
          return requestsByKeyDateAndValidFroms
        }
      }),
      translated: true,
      useCache: true,
      options: { enabled: !isEmpty(propertyUuids) },
    }),
  )

  const isFetching = responses.some((response) => response.isFetching)
  const isError = responses.some((response) => response.isError)

  return useMemo(
    () => ({
      responses: responses.map((result) => ({
        ...result,
        data: camelize(result.data),
      })),
      isFetching,
      isError,
    }),
    [isError, isFetching, responses],
  )
}

export default useMultiPropertyRentRolls
