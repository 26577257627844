import {
  FlexBox,
  FlexBoxDirection,
  ResponsiveGridLayout,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import styles from 'components/domains/properties/rent-roll/overview/PropertyRentRollOverview.module.css' // reuse single property overview styles
import { TenantColorProvider } from 'components/domains/properties/rent-roll/overview/chart-colors/TenantColorContext'
import { UsageTypeColorProvider } from 'components/domains/properties/rent-roll/overview/chart-colors/UsageTypeColorContext'
import PropertyRentRollOverviewCurrentRentCard from 'components/domains/properties/rent-roll/overview/current-rent/PropertyRentRollOverviewCurrentRentCard'
import PropertyRentRollOverviewExpiringLeasesCard from 'components/domains/properties/rent-roll/overview/expiring-leases/PropertyRentRollOverviewExpiringLeasesCard'
import PropertyRentRollOverviewOccupancyCard from 'components/domains/properties/rent-roll/overview/occupancy/PropertyRentRollOverviewOccupancyCard'
import PropertyRentRollOverviewRentalIncomeOverTimeCard from 'components/domains/properties/rent-roll/overview/rental-income-over-time/PropertyRentRollOverviewRentalIncomeOverTimeCard'
import PropertyRentRollOverviewVacancyOverTimeCard from 'components/domains/properties/rent-roll/overview/vacancy-over-time/PropertyRentRollOverviewVacancyOverTimeCard'
import PropertyRentRollOverviewWaultCard from 'components/domains/properties/rent-roll/overview/wault/PropertyRentRollOverviewWaultCard'
import CWPLayout from 'components/ui/layout/CWPLayout'

const PropertyPortfolioRentRollOverviewTab = () => (
  <TenantColorProvider>
    <UsageTypeColorProvider>
      <CWPLayout sixColumns>
        <CWPLayout.Full>
          <ResponsiveGridLayout
            className={styles.grid}
            columnsXL={8}
            columnsL={8}
            columnsM={8}
            columnsS={8}
            columnGap="10px"
            rowGap="10px"
          >
            <FlexBox className={styles.gridColumnSpan8} direction={FlexBoxDirection.Column}>
              <Title level={TitleLevel.H3} className={styles.rowSpace}>
                {t('pages.property.rent-roll.overview.rental-income-overview')}
              </Title>
            </FlexBox>
            <FlexBox className={styles.gridColumnSpan5} direction={FlexBoxDirection.Column}>
              <PropertyRentRollOverviewRentalIncomeOverTimeCard />
            </FlexBox>
            <FlexBox className={styles.gridColumnSpan3} direction={FlexBoxDirection.Column}>
              <PropertyRentRollOverviewCurrentRentCard />
            </FlexBox>
            <FlexBox className={styles.titleColumn} direction={FlexBoxDirection.Column}>
              <Title level={TitleLevel.H3} className={styles.rowSpace}>
                {t('pages.property.rent-roll.overview.occupancy-overview')}
              </Title>
            </FlexBox>
            <FlexBox className={styles.gridColumnSpan5} direction={FlexBoxDirection.Column}>
              <PropertyRentRollOverviewVacancyOverTimeCard />
            </FlexBox>
            <FlexBox className={styles.gridColumnSpan3} direction={FlexBoxDirection.Column}>
              <PropertyRentRollOverviewOccupancyCard />
            </FlexBox>
          </ResponsiveGridLayout>
        </CWPLayout.Full>
        <CWPLayout.Full>
          <Title level={TitleLevel.H3}>
            {t('pages.property.rent-roll.overview.lease-expiry-overview')}
          </Title>
        </CWPLayout.Full>
        <CWPLayout.Half>
          <PropertyRentRollOverviewExpiringLeasesCard />
        </CWPLayout.Half>
        <CWPLayout.Half>
          <PropertyRentRollOverviewWaultCard />
        </CWPLayout.Half>
      </CWPLayout>
    </UsageTypeColorProvider>
  </TenantColorProvider>
)

export default PropertyPortfolioRentRollOverviewTab
