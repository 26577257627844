import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import displayCardStyles from 'components/domains/business-events-and-tasks/decision-paper/tiles/loan-market-statement/shared/ui/card/DisplayCardView.module.css'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/loan-market-statement/shared/ui/card/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/loan-market-statement/shared/ui/card/DisplayCardViewItemView'
import useLoanMarketStatementSyndicationIntentionFields from 'components/domains/business-events-and-tasks/decision-paper/tiles/loan-market-statement/useLoanMarketStatementSyndicationIntentionFields'

const LoanMarketStatementSyndicationIntentionTile = ({ tileId }) => {
  const {
    data: { syndicationIntentionData = {} },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const renderSectionTitle = (infoItem, index) => {
    const subtitle = infoItem.value?.sectionSubtitle && (
      <Label className={displayCardStyles.displayAndEditSubtitle}>
        {infoItem.value.sectionSubtitle}
      </Label>
    )
    const title = infoItem.value?.sectionTitle && (
      <Label
        className={`${displayCardStyles.displayAndEditTitle} ${
          subtitle ? displayCardStyles.displayAndEditSubtitleSeparator : ''
        }`}
      >
        {infoItem.value.sectionTitle}
      </Label>
    )
    const value = infoItem.value?.sectionValue && (
      <div className={displayCardStyles.displayCardTextBox}>
        <Text>{infoItem.value.sectionValue}</Text>
      </div>
    )

    return title && value ? (
      <FlexBox
        fitContainer
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        className={`${index !== 0 ? displayCardStyles.displayAndEditTitleSeparator : ''}`}
      >
        <FlexBox fitContainer direction={FlexBoxDirection.Column}>
          {title}
          {subtitle}
        </FlexBox>
        {value}
      </FlexBox>
    ) : (
      <FlexBox
        fitContainer
        direction={FlexBoxDirection.Column}
        className={`${index !== 0 ? displayCardStyles.displayAndEditTitleSeparator : ''}`}
      >
        {title}
        {subtitle}
      </FlexBox>
    )
  }

  const renderItem = (infoItem, index) => {
    if (infoItem.isSectionTitle) {
      return renderSectionTitle(infoItem, index)
    }
    const isFirstItem = index === 0
    return (
      <DisplayCardViewInfoItemView
        label={infoItem.label}
        isFirstItem={isFirstItem}
        value={infoItem.formattedValue ?? infoItem.value ?? '-'}
      />
    )
  }

  const syndicationIntentionFields =
    useLoanMarketStatementSyndicationIntentionFields(syndicationIntentionData)

  return (
    <div className={displayCardStyles.displayAndEditSubtitleSeparator}>
      {syndicationIntentionFields.map((item, index) => (
        <DisplayCardViewItemView key={`item-${index}`}>
          {item.customInfoComponent ? <>{item.customInfoComponent}</> : renderItem(item, index)}
        </DisplayCardViewItemView>
      ))}
    </div>
  )
}

LoanMarketStatementSyndicationIntentionTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default LoanMarketStatementSyndicationIntentionTile
