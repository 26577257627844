import { useQuery } from '@tanstack/react-query'
import { uniqBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { isNotFoundError } from 'api/requests'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * Request Response Format:
 * {
 *   dates: [
 *     {
 *       key_date: "2023-12-23",
 *       valid_from: "2023-12-23",
 *     },
 *     ...
 *   ]
 * }
 *
 * The hook adds an index (starting with "0") to each dates entry, resulting in
 * {
 *   dates: [
 *     {
 *       index: "0",
 *       key_date: "2023-12-23",
 *       valid_from: "2023-12-23",
 *     },
 *     ...
 *   ]
 * }
 */

const usePortfolioHistoricalRentRollKeyDates = (
  { propertyUuids, deduplicateKeyDates = true },
  queryOptions = {},
) => {
  const { post, get } = useAccessTokenRequest()

  const isMultiProperty = propertyUuids?.length > 1

  const deduplicateByKeyDate = useCallback(
    (data) => {
      const result = data?.data
      if (!result?.dates) {
        return undefined
      }
      const deduplicatedDates = deduplicateKeyDates ? uniqBy(result.dates, 'keyDate') : result.dates
      const indexedDates = deduplicatedDates.map((datesEntry, index) => ({
        index: index.toString(),
        ...datesEntry,
      }))
      return {
        dates: indexedDates,
      }
    },
    [deduplicateKeyDates],
  )
  const { data, isLoading, error, isError } = useCamelizedResponse(
    useQuery({
      queryKey: ['historical-rent-roll-key-dates', ...propertyUuids],
      refetchOnWindowFocus: false,
      queryFn: () =>
        isMultiProperty
          ? post({
              path: `/properties/rent-roll/key-date`,
              body: {
                property_uuids: propertyUuids,
              },
            })
          : get({
              path: `/properties/${propertyUuids[0]}/rent-roll/key-date`,
            }),
      retry: 0,
      ...queryOptions,
    }),
  )

  const result = useMemo(() => deduplicateByKeyDate(data), [data, deduplicateByKeyDate])

  return { isLoading, isError: isError && !isNotFoundError(error), data: result }
}

export default usePortfolioHistoricalRentRollKeyDates
