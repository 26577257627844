import { combineReducers } from '@reduxjs/toolkit'
import commonCurrencyReducer from 'redux/slices/properties/commonCurrencySlice'
import compareRentRollReducer from 'redux/slices/properties/compareRentRollSlice'
import resetPropertiesPaginationReducer from 'redux/slices/properties/resetPropertiesPaginationSlice'
import segmentsRentalIncomeReducer from 'redux/slices/properties/segmentsRentalIncomeSlice'
import segmentsTableReducer from 'redux/slices/properties/segmentsTableSlice'
import uploadedPropertiesReducer from 'redux/slices/properties/uploadedPropertiesSlice'
import valuationResultsReducer from 'redux/slices/properties/valuationResultsTableSlice'

const propertiesReducer = combineReducers({
  commonCurrency: commonCurrencyReducer,
  segmentsTable: segmentsTableReducer,
  segmentsRentalIncome: segmentsRentalIncomeReducer,
  compareRentRoll: compareRentRollReducer,
  uploadedProperties: uploadedPropertiesReducer,
  resetPropertiesPagination: resetPropertiesPaginationReducer,
  valuationResults: valuationResultsReducer,
})

export default propertiesReducer
