// Sadly we have to add all available translations like this.
import '@fioneer/ckeditor5-cwp-build/build/translations/de'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from '@fioneer/ckeditor5-cwp-build'
import { FlexBox, FlexBoxJustifyContent, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/shared/editor/CWPCKEditorCustom.module.css'
import InjectAccessTokenPlugin from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/shared/editor/CWPCKEditorImageConversion'
import { defaultToolbarConfig } from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/shared/editor/CWPCKEditorToolbarConfigurations'
import { getAccessTokenFromLocalStorage } from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/shared/editor/downloadImage'
import { ConfigContext } from 'hooks/config/useConfig'

export const emptyContent = ''

const CWPCKEditor = ({
  setCurrentContent,
  currentContent,
  placeholderText,
  maxCharacterAmount,
  onUsedCharactersChanged,
  toolbarConfig = defaultToolbarConfig,
  setIsDataUploading,
}) => {
  const { baseUrl } = useContext(ConfigContext)
  const [usedCharacters, setUsedCharacters] = useState(0)
  const {
    t,
    i18n: { language },
  } = useTranslation('decisionPaper', {
    keyPrefix: 'components.ckeditor',
  })

  const accessToken = getAccessTokenFromLocalStorage()

  const onChange = useCallback(
    (_event, editor) => {
      const textContent = editor.getData()
      setCurrentContent(textContent, usedCharacters)
    },
    [setCurrentContent, usedCharacters],
  )
  const onBlur = useCallback(
    (_event, editor) => {
      const textContent = editor.getData()
      setCurrentContent(textContent, usedCharacters)
    },
    [setCurrentContent, usedCharacters],
  )

  const onUpdateWordCount = useCallback(
    (stats) => {
      setUsedCharacters(stats.characters)
      onUsedCharactersChanged?.(stats.characters)
    },
    [setUsedCharacters, onUsedCharactersChanged],
  )

  const parsedLanguage = useMemo(() => language.split('-')[0], [language])

  const editorConfig = useMemo(
    () => ({
      placeholder: placeholderText,
      toolbar: toolbarConfig,
      wordCount: {
        onUpdate: onUpdateWordCount,
      },
      language: parsedLanguage,
      simpleUpload: {
        uploadUrl: `${baseUrl}/media`,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      extraPlugins: [InjectAccessTokenPlugin],
    }),
    [onUpdateWordCount, placeholderText, toolbarConfig, accessToken, baseUrl, parsedLanguage],
  )

  const isMaxCharacterAmountExceeded = maxCharacterAmount - usedCharacters < 0

  const onReady = useCallback(
    (editor) => {
      const fileRepoPlugin = editor.plugins.get('FileRepository')
      const imageUploadPlugin = editor.plugins.get('ImageUploadEditing')

      fileRepoPlugin.on('change:uploadedPercent', (_eventInfo, _name, value) => {
        if (value < 100) {
          setIsDataUploading?.(true)
        }
      })

      imageUploadPlugin.on('uploadComplete', () => {
        setIsDataUploading?.(false)
      })
    },
    [setIsDataUploading],
  )

  return (
    <>
      <div className={isMaxCharacterAmountExceeded ? styles.ckeditorInvalidTextLength : ''}>
        <CKEditor
          config={editorConfig}
          editor={Editor}
          data={currentContent}
          onChange={onChange}
          onReady={onReady}
          onBlur={onBlur}
        />
      </div>
      {maxCharacterAmount && (
        <FlexBox justifyContent={FlexBoxJustifyContent.End} style={{ paddingBottom: '5px' }}>
          <Text>
            <span className={isMaxCharacterAmountExceeded ? styles.invalidTextLength : ''}>
              {t('remaining-characters', {
                charactersRemaining: maxCharacterAmount - usedCharacters,
              })}
            </span>
          </Text>
        </FlexBox>
      )}
    </>
  )
}

CWPCKEditor.propTypes = {
  className: PropTypes.string,
  setCurrentContent: PropTypes.func.isRequired,
  currentContent: PropTypes.string,
  placeholderText: PropTypes.string,
  maxCharacterAmount: PropTypes.number,
  toolbarConfig: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.string),
  }),
  onUsedCharactersChanged: PropTypes.func,
  setIsDataUploading: PropTypes.func,
}

export default CWPCKEditor
