import isEmpty from 'lodash.isempty'

const covenantCheckChanged = (state, { payload: { covenantCheckId } }) => {
  if (isEmpty(state.editedRow)) {
    return
  }
  state.editedRow.currentValues.covenantCheckId = covenantCheckId
  if (covenantCheckId === state.editedRow.initialValues.covenantCheckId) {
    delete state.editedRow.changedFields.covenantCheckId
    return
  }
  state.editedRow.changedFields.covenantCheckId = true
}

export default covenantCheckChanged
