const startValuationResultsAddMode = (state, { payload: { currency: newCurrency } }) => {
  const initialValues = {
    valuationId: '-1',
    calculationMethodCode: undefined,
    valueAmount: {
      value: undefined,
      currency: newCurrency,
    },
    keyDate: undefined,
    validFrom: undefined,
    validTo: undefined,
  }
  state.isAddMode = true
  state.editedRow = {
    initialValues: initialValues,
    currentValues: initialValues,
    changedFields: {},
    errorFields: { calculationMethodCode: true, valueAmount: true },
    touchedFields: {},
  }
}

export default startValuationResultsAddMode
