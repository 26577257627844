/**
 * @readonly
 * @enum
 */
export const ReferenceEntityType = Object.freeze({
  TrancheNewBusiness: 'TRANCHE_NEW_BUSINESS',
  TrancheExistingBusiness: 'TRANCHE_EXISTING_BUSINESS',
  BusinessPartner: 'BUSINESSPARTNER',
  Deal: 'DEAL',
  Property: 'PROPERTY',
})
