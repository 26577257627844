import { Table, TableCell, TableColumn, TableRow } from '@fioneer/ui5-webcomponents-react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/exposure/GBAReportingTile.module.css'
import { getMostRecentFinanceReports } from 'components/domains/business-partners/tile/exposure/getMostRecentFinanceReports'
import useFinanceReportsbyBpId from 'components/domains/business-partners/tile/exposure/useFinanceReportsByBPId'
import Card from 'components/ui/card/Card'
import TablesToolbar from 'components/ui/tables/toolbar/TablesToolbar'
import { useCustomizableCurrencyFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

const amountOfDisplayedReports = 5

const Position = Object.freeze({
  RIGHT: 'right',
  LEFT: 'left',
})

const GBAReportingTile = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.business-partner' })
  const { id: businessPartnerId } = useContext(BusinessPartnerContext)
  const { data } = useFinanceReportsbyBpId(businessPartnerId)
  const { format: formatDate } = useShortDateFormatter()

  const formatCurrency = useCustomizableCurrencyFormatter()

  const sortedEvents = useMemo(
    () => getMostRecentFinanceReports(data?.largeExposures, amountOfDisplayedReports),
    [data?.largeExposures],
  )

  const columnDefinitions = [
    {
      title: t('rep-date'),
      columnKey: 'repDateKey',
      align: Position.RIGHT,
    },
    {
      title: t('total-debt'),
      columnKey: 'totalDebtKey',
      align: Position.RIGHT,
    },
    {
      title: t('no-of-creditors'),
      columnKey: 'noOfCreditorsKey',
      align: Position.RIGHT,
    },
  ]

  const tableData =
    sortedEvents?.map((item, index) => ({
      rowKey: index,
      repDateKey: formatDate(item.reportingDate),
      totalDebtKey: formatCurrency(item.totalDebt, item.currency),
      noOfCreditorsKey: item.numberOfCreditors,
    })) || []

  return (
    <Card>
      <div className={styles.tableWrapper}>
        <TablesToolbar nrOfEntries={tableData.length} title={t('gba-reporting.title')} />
        <Table
          noDataText={t('gba-reporting.no-data')}
          columns={columnDefinitions.map((c, index) => {
            const alignOnRight = c.align === Position.RIGHT ? styles.rightAlignColumn : ''

            return (
              <TableColumn key={`column-${index}`} className={alignOnRight}>
                {c.title}
              </TableColumn>
            )
          })}
        >
          {tableData.map((currentRow) => {
            const { rowKey } = currentRow
            return (
              <TableRow key={`row-${rowKey}`}>
                {columnDefinitions.map((column) => {
                  const { columnKey, align } = column
                  const alignOnRight = align === Position.RIGHT ? styles.rightAlignCell : ''

                  return (
                    <TableCell key={`cell-${columnKey}-${rowKey}`} className={alignOnRight}>
                      {currentRow[columnKey]}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </Table>
      </div>
    </Card>
  )
}

export default GBAReportingTile
