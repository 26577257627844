import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultipleMarketArea = ({ marketIds, options = {} }) => {
  const requests = marketIds.map((marketId) => ({
    path: `/markets/${marketId}/area`,
    keys: ['market-area', marketId],
    options: { enabled: !!marketId },
  }))

  const responses = useRequestsMemo(
    useRequests({
      requests: requests.filter(({ options: { enabled } }) => enabled),
      translated: true,
      useCache: true,
      options: { enabled: !isEmpty(marketIds), ...options },
    }),
  )

  return useMemo(
    () =>
      requests.map((request, index) => {
        if (!request.options.enabled) {
          return { isLoading: false, isError: false, isFetching: false, data: undefined }
        }
        return {
          ...responses[index],
          data: {
            marketId: marketIds[index],
            ...camelize(responses[index]?.data),
          },
        }
      }),
    [marketIds, requests, responses],
  )
}

export default useMultipleMarketArea
