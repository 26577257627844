import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { conditionsEntityTypes } from 'api/conditions/conditions'
import { covenantPermissions } from 'api/deals/covenantPermissions'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import CloseCovenantCheckButton from 'components/domains/deals/covenant-check-detail/close-covenant-check/CloseCovenantCheckButton'
import CovenantCheckMessageStrips from 'components/domains/deals/covenant-check-detail/message-stripes/CovenantCheckMessageStrips'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import Page from 'components/ui/page/Page'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useConditionsAllowedOperations from 'hooks/services/conditions/useConditionsAllowedOperations'
import { useCovenantAllowedOperations } from 'hooks/services/deals/covenants/useCovenantAllowedOperations'
import useCovenantById from 'hooks/services/deals/covenants/useCovenantById'
import useCovenantCheckActions from 'hooks/services/deals/covenants/useCovenantCheckActions'
import useCovenantCheckById from 'hooks/services/deals/covenants/useCovenantCheckById'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import { CovenantContext } from 'routes/business-partners/CovenantContext'
import styles from 'routes/business-partners/periodical-checks/PeriodicalCheckDetailPage.module.css'
import PeriodicalChecksLayout, {
  periodicalChecksLayoutPages,
} from 'routes/business-partners/periodical-checks/periodical-checks-layout/PeriodicalChecksLayout'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'
import useCalculateEditButtonState from 'routes/deals/covenant-checks/useCalculateEditButtonState'

/**
 * DISCLAIMER
 * Each Periodical Check is treated as a covenant check because they are technically the same.
 * The same endpoints are used, the only things that differ are the id and the mainEntityType.
 */
const PeriodicalCheckDetailPage = () => {
  const { id } = useContext(BusinessPartnerContext)
  const { t } = useTranslation()
  const { periodicalCheckUuid } = useParams()

  const {
    isLoading: isLoadingCovenantCheck,
    isFetching: isFetchingCovenantCheck,
    isError: isErrorCovenantCheck,
    data: {
      covenantMonitoringItems = [],
      name: covenantCheckName,
      status: covenantCheckStatus,
      linkedEventId: covenantCheckLinkedEventId,
    } = {},
  } = useCovenantCheckById(periodicalCheckUuid)

  const {
    isFetching: isFetchingActions,
    isError: isErrorActions,
    data: { isEditable } = {},
  } = useCovenantCheckActions({ covenantCheckUuid: periodicalCheckUuid })

  const {
    data: { covenantDefinition = {} } = {},
    isLoading: isLoadingCovenant,
    isFetching: isFetchingCovenant,
    isError: isErrorCovenant,
  } = useCovenantById(covenantMonitoringItems?.[0]?.covenant?.covenantUuid) // each covenant check has exactly one monitoring item

  const {
    isLoading: isLoadingAllowedOperationsCovenant,
    isError: isErrorAllowedOperationsCovenant,
    data: { allowedOperations: covenantAllowedOperations = [] } = {},
  } = useCovenantAllowedOperations()

  const {
    isLoading: isConditionsAllowedOperationsLoading,
    data: conditionsAllowedOperationsData = {},
  } = useConditionsAllowedOperations()

  const isLoading =
    (isLoadingCovenant && isFetchingCovenant) ||
    (isLoadingCovenantCheck && isFetchingCovenantCheck) ||
    isConditionsAllowedOperationsLoading ||
    isLoadingAllowedOperationsCovenant ||
    isFetchingActions

  const isError =
    isErrorCovenant || isErrorCovenantCheck || isErrorAllowedOperationsCovenant || isErrorActions

  const isAllowedToUpdate = covenantAllowedOperations?.includes(
    covenantPermissions.updatePeriodicalCheckMonitoring,
  )
  const editButtonState = useCalculateEditButtonState({
    isEditable,
    status: covenantCheckStatus,
    isAllowedToEdit: isAllowedToUpdate,
  })

  const breadcrumbName = covenantCheckName ?? periodicalCheckUuid
  const generateBreadcrumbs = () => {
    if (id) {
      return [
        {
          text: t('navigation.item.title.periodical-checks'),
          href: `periodical-checks`,
        },
        { text: breadcrumbName },
      ]
    }
    return []
  }

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [id],
  )

  const closeButton = useMemo(
    () => (
      <CloseCovenantCheckButton
        key="close-periodical-check"
        buttonText={t('components.business-partners.periodical-checks.close-periodical-check', {
          covenantName: covenantDefinition?.name,
        })}
        confirmationText={t('components.business-partners.periodical-checks.close-confirmation', {
          covenantName: covenantDefinition?.name,
        })}
        successText={t('components.business-partners.periodical-checks.close-success', {
          covenantName: covenantDefinition?.name,
        })}
        covenantCheckUuid={periodicalCheckUuid}
      />
    ),
    [covenantDefinition?.name, periodicalCheckUuid, t],
  )

  return (
    <Page
      isError={false}
      isLoading={false}
      renderHeaderTitle={() => (
        <BusinessPartnerHeader
          actions={[
            isAllowedToUpdate && closeButton,
            <CreateBusinessPartnerEventAction key={'create-event-action'} />,
            markFavoriteAction,
          ]}
          breadcrumbs={generateBreadcrumbs()}
        />
      )}
      renderContent={() => (
        <RequestStateResolver
          isLoading={isLoading}
          isError={isError}
          errorToDisplay={<ErrorDataUnavailableInContent />}
          renderContent={() => (
            <CovenantContext.Provider
              value={{ allowedOperations: covenantAllowedOperations, editButtonState }}
            >
              <ConditionsContext.Provider
                value={{
                  entityRef: {
                    entityId: id,
                    entityDisplayId: id,
                    entityType: conditionsEntityTypes.businessPartner,
                  },
                  allowedOperations: conditionsAllowedOperationsData.allowedOperations,
                }}
              >
                <div className={styles.marginBottom}>
                  <CovenantCheckMessageStrips
                    checkName={covenantDefinition?.name}
                    covenantCheckUuid={periodicalCheckUuid}
                    eventId={covenantCheckLinkedEventId}
                  />
                </div>
                <PeriodicalChecksLayout
                  configDefinitionUuid={covenantDefinition?.configCovenantDefinitionUuid}
                  currentPage={periodicalChecksLayoutPages.DETAIL}
                  covenantCheckUuid={periodicalCheckUuid}
                />
              </ConditionsContext.Provider>
            </CovenantContext.Provider>
          )}
        />
      )}
    />
  )
}

PeriodicalCheckDetailPage.propTypes = {}

export default PeriodicalCheckDetailPage
