import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import MultipleFurtherDetailsGranularityTenantIndustryTileV1 from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/fact-sheet-further-details/MultipleFurtherDetailsGranularityTenantIndustryTileV1'
import MultipleFurtherDetailsGranularityTenantIndustryTileV2 from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-portfolio/fact-sheet-further-details/MultipleFurtherDetailsGranularityTenantIndustryTileV2'
import MultipleDealSelectionPdfViewWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/wrapper/MultipleDealSelectionPdfViewWrapper'
import { TILE_VERSION } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'

const MultipleFurtherDetailsGranularityTenantIndustryTileDealSelectionWrapper = ({
  tileId,
  isPdfView,
}) => {
  const { version } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const contentCreator = useCallback(
    ({ selectedDealIndex }) => {
      switch (version) {
        case TILE_VERSION.V1:
          return (
            <MultipleFurtherDetailsGranularityTenantIndustryTileV1
              tileId={tileId}
              selectedDealIndex={selectedDealIndex}
              isPdfView={isPdfView}
            />
          )
        default:
          return (
            <MultipleFurtherDetailsGranularityTenantIndustryTileV2
              tileId={tileId}
              selectedDealIndex={selectedDealIndex}
              isPdfView={isPdfView}
            />
          )
      }
    },
    [isPdfView, tileId, version],
  )
  return (
    <MultipleDealSelectionPdfViewWrapper
      tileId={tileId}
      contentCreator={contentCreator}
      isPdfView={isPdfView}
    />
  )
}

MultipleFurtherDetailsGranularityTenantIndustryTileDealSelectionWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default MultipleFurtherDetailsGranularityTenantIndustryTileDealSelectionWrapper
