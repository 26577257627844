import { FlexBox, FlexBoxDirection, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/card/DisplayAndEditCard.module.css'
import DisplayCardView from 'components/ui/card/DisplayCardView'
import EditCardView from 'components/ui/card/EditCardView'
import EmptyCard from 'components/ui/card/EmptyCard'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import CWPCKEditor from 'components/ui/rich-text-editor/CWPCKEditor'
import FormattedTextView from 'components/ui/rich-text-editor/FormattedTextView'
import useEditTask from 'hooks/services/business-events-and-tasks/tasks/useEditTask'

const maxCharacterAmount = 2000

const TaskDescriptionCard = ({ taskId, eventId, description, isEditable }) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', { keyPrefix: 'pages.task-detail.description' })
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()

  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false)
  const [editedTaskDescription, setEditedTaskDescription] = useState(description)
  const [usedCharacters, setUsedCharacters] = useState(0)
  const { mutate: editTask } = useEditTask()
  const showMessageBox = useShowMessageBox()

  const handleContentChange = useCallback((content) => {
    setEditedTaskDescription(content)
  }, [])

  const onUsedCharactersChanged = useCallback((characterCount) => {
    setUsedCharacters(characterCount)
  }, [])

  const renderEditView = useCallback(
    () => (
      <FlexBox direction={FlexBoxDirection.Column} className={styles.displayCardItem}>
        <CWPCKEditor
          setCurrentContent={handleContentChange}
          currentContent={editedTaskDescription}
          placeholderText={t('placeholder')}
          maxCharacterAmount={maxCharacterAmount}
          onUsedCharactersChanged={onUsedCharactersChanged}
        />
      </FlexBox>
    ),
    [editedTaskDescription, handleContentChange, onUsedCharactersChanged, t],
  )

  const displayView = useMemo(
    () => (
      <FlexBox direction={FlexBoxDirection.Column} className={styles.displayCardItem}>
        <FormattedTextView formattedText={description} />
      </FlexBox>
    ),
    [description],
  )

  const taskDescriptionFields = useMemo(
    () => [
      {
        customInfoComponent: displayView,
        renderCustomEditComponent: renderEditView,
      },
    ],
    [displayView, renderEditView],
  )

  const onSaveSuccess = useCallback(() => {
    queryClient.invalidateQueries(['events', eventId]) // has to invalidate everything event realated to refelect changes in EventDetail
    setIsEditModeEnabled(false)
    showToast({ children: tNoPrefix('toast.changes-saved') })
  }, [eventId, queryClient, tNoPrefix])

  const onSaveError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: tNoPrefix('components.cards.save-error'),
    })
  }, [showMessageBox, tNoPrefix])

  const onSaveClicked = useCallback(() => {
    editTask(
      {
        taskId,
        eventId,
        description: editedTaskDescription,
      },
      {
        onSuccess: onSaveSuccess,
        onError: onSaveError,
      },
    )
  }, [editTask, editedTaskDescription, eventId, onSaveError, onSaveSuccess, taskId])

  const onCancelClicked = useCallback(() => {
    setEditedTaskDescription(description)
    setIsEditModeEnabled(false)
  }, [description])

  const onEditClicked = useCallback(() => {
    setIsEditModeEnabled(true)
  }, [])

  const isSaveButtonEnabled =
    description !== editedTaskDescription && usedCharacters <= maxCharacterAmount

  if (isEditModeEnabled) {
    return (
      <EditCardView
        cardHeaderTitle={t('title')}
        fieldDefinitions={taskDescriptionFields}
        onSaveClicked={onSaveClicked}
        onCancelClicked={onCancelClicked}
        setEditModeHasChanges={() => {}}
        editModeHasChanges={isSaveButtonEnabled}
      />
    )
  }

  if (isEmpty(description)) {
    return (
      <EmptyCard
        cardHeaderTitle={t('title')}
        onEditClicked={onEditClicked}
        showCardHeaderActions={isEditable}
      />
    )
  }

  return (
    <DisplayCardView
      cardHeaderTitle={t('title')}
      fieldDefinitions={taskDescriptionFields}
      setEditMode={onEditClicked}
      isEditable={isEditable}
    />
  )
}

TaskDescriptionCard.propTypes = {
  taskId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
}

export default TaskDescriptionCard
