import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import DecisionProcessLabeledLoadingContent from 'components/domains/business-events-and-tasks/decision-process/alc/helper/DecisionProcessLabeledLoadingContent'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import { useLargeExposureThresholds } from 'hooks/services/business-partners/risk-figures/useLargeExposureThresholds'

const DecisionProcessAlcLargeExposureReportLimit = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.overview.alc-info.dialog',
  })
  const formatMoney = useCustomizableCurrencyFormatter()
  const thresholdsResponse = useLargeExposureThresholds()
  const renderLargeExposureReportLimit = useCallback(
    ({ largeExposureReportingThreshold: { value, unit } }) => formatMoney(value, unit),
    [formatMoney],
  )
  return (
    <DecisionProcessLabeledLoadingContent
      response={thresholdsResponse}
      renderCallback={renderLargeExposureReportLimit}
      label={t('fields.large-exposure-report-limit')}
    />
  )
}

DecisionProcessAlcLargeExposureReportLimit.propTypes = {}

export default DecisionProcessAlcLargeExposureReportLimit
