import PropTypes from 'prop-types'
import { useContext } from 'react'
import { businessEventsAllowedOperations } from 'api/events/eventAllowedOperations'
import { entityTypes } from 'api/events/status'
import EventsSummaryCardWrapper from 'components/domains/business-events-and-tasks/events/events-summary-card/EventsSummaryCardWrapper'
import KeyInternalContactsTileWrapper from 'components/domains/business-partners/tile/general-information/keyInternalContact/KeyInternalContactsTileWrapper'
import CWPLayout from 'components/ui/layout/CWPLayout'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useEventsAllowedOperations from 'hooks/services/business-events-and-tasks/events/useEventsAllowedOperations'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'

const ManagerTab = () => {
  const { id: businessPartnerId, fullName: businessPartnerFullName } =
    useContext(BusinessPartnerContext)
  const {
    data: allowedEventsOperationsData,
    isLoading: allowedEventsOperationsIsLoading,
    isError: allowedEventsOperationsIsError,
  } = useEventsAllowedOperations()

  const displayCreateEventButton = allowedEventsOperationsData?.allowed_operations.includes(
    businessEventsAllowedOperations.createEvent,
  )

  const eventReadAllowed = allowedEventsOperationsData?.allowed_operations?.includes(
    businessEventsAllowedOperations.readEvent,
  )

  const renderContent = () => (
    <CWPLayout>
      {!allowedEventsOperationsIsError && eventReadAllowed && (
        <EventsSummaryCardWrapper
          entity={{
            entityDisplayId: businessPartnerId,
            entityType: entityTypes.businesspartner,
            entityName: businessPartnerFullName,
            entityId: businessPartnerId,
          }}
          entityType={entityTypes.businesspartner}
          displayCreateEventButton={displayCreateEventButton}
        />
      )}
      <KeyInternalContactsTileWrapper />
    </CWPLayout>
  )

  return (
    <>
      <LoadingStateWrapper
        isError={allowedEventsOperationsIsError}
        isLoading={allowedEventsOperationsIsLoading}
        renderContent={renderContent}
      />
    </>
  )
}

ManagerTab.propTypes = {
  businessPartnerId: PropTypes.string,
  businessPartnerFullName: PropTypes.string,
}

export default ManagerTab
