import { useCallback, useContext, useMemo } from 'react'
import { useAreaMeasurementUnitFormatter } from 'hooks/i18n/useI18n'
import { useSegmentUsageTypes } from 'hooks/services/properties/segments/useSegmentUsageTypes'
import { useAreaMeasureUnitCodes } from 'hooks/services/properties/useAreaUnitOfMeasureCodes'
import { useCurrencyCodes } from 'hooks/services/properties/useCurrencyCodes'
import { useUtilizationCodes } from 'hooks/services/properties/useUtilizationCodes'
import { PropertyPortfolioContext } from 'routes/properties/portfolio/PropertyPortfolioContext'

/**
 * Hook for all options important for the rent roll working version
 * Returned results are memoized.
 * @returns areaMeasureUnitCodes, occupancyStatusCodes, currencyCodes, segmentUsageTypeCodes
 */
const useGetAllRentRollWorkingVersionOptions = () => {
  const {
    isLoading: isLoadingUtilizationCodes,
    isError: isErrorUtilizationCodes,
    data: utilizationCodes,
  } = useUtilizationCodes()

  const occupancyStatusCodes = useMemo(
    () =>
      isLoadingUtilizationCodes || isErrorUtilizationCodes
        ? []
        : utilizationCodes?.utilization_codes.filter(
            (utilizationCode) => utilizationCode.is_write_code,
          ),
    [isErrorUtilizationCodes, isLoadingUtilizationCodes, utilizationCodes?.utilization_codes],
  )

  const {
    isLoading: isLoadingAreaMeasureUnitCodes,
    isError: isErrorAreaMeasureUnitCodes,
    data: areaMeasureUnitCodesData,
  } = useAreaMeasureUnitCodes()

  const formatAreaUnit = useAreaMeasurementUnitFormatter()
  const mapAreaMeasureUnitCodesDisplayName = useCallback(
    (measureUnitCodes) =>
      measureUnitCodes.map((measureUnitCode) => ({
        ...measureUnitCode,
        display_name: formatAreaUnit(measureUnitCode.key),
      })),
    // formatAreaUnit can't be a dependency, because it changes every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const areaMeasureUnitCodes = useMemo(
    () =>
      !isLoadingAreaMeasureUnitCodes && !isErrorAreaMeasureUnitCodes
        ? mapAreaMeasureUnitCodesDisplayName(areaMeasureUnitCodesData?.measure_unit_codes)
        : [],
    [
      areaMeasureUnitCodesData?.measure_unit_codes,
      isErrorAreaMeasureUnitCodes,
      isLoadingAreaMeasureUnitCodes,
      mapAreaMeasureUnitCodesDisplayName,
    ],
  )

  const {
    isLoading: isLoadingCurrencyCodes,
    isError: isErrorCurrencyCodes,
    data: currencyCodesData,
  } = useCurrencyCodes()

  const currencyCodes = useMemo(
    () =>
      !isLoadingCurrencyCodes && !isErrorCurrencyCodes ? currencyCodesData?.currency_codes : [],
    [currencyCodesData?.currency_codes, isErrorCurrencyCodes, isLoadingCurrencyCodes],
  )

  const propertyPortfolioContext = useContext(PropertyPortfolioContext)

  const mappedProperties = useMemo(() => {
    const { properties } = propertyPortfolioContext ?? { properties: [] }
    return (
      properties?.map((property) => ({
        key: property.uuid,
        display_name: property.description,
        id: property.id,
        selectDisplayValue: `${property.description} (${property.id})`,
      })) ?? []
    )
  }, [propertyPortfolioContext])

  const { isLoading: isLoadingSegmentUsageTypes, data: segmentUsageTypeCodes } =
    useSegmentUsageTypes()

  const optionsAreLoading =
    isLoadingAreaMeasureUnitCodes ||
    isLoadingUtilizationCodes ||
    isLoadingCurrencyCodes ||
    isLoadingSegmentUsageTypes

  return {
    areaMeasureUnitCodes,
    occupancyStatusCodes,
    currencyCodes,
    segmentUsageTypeCodes,
    properties: mappedProperties,
    optionsAreLoading,
  }
}

export const getKeyByDisplayName = ({ displayName, options }) => {
  const chosenOption = options?.find((option) => option.display_name === displayName)
  return chosenOption ? chosenOption.key : ''
}

export const getKeyOrReturnDisplayName = ({ displayName, options }) => {
  const chosenOptionKey = getKeyByDisplayName({ displayName: displayName, options: options })
  return chosenOptionKey === '' ? displayName : chosenOptionKey
}

export const getDisplayNameByKey = ({ key, options }) => {
  const chosenOption = options?.find((option) => option.key === key)
  return chosenOption ? chosenOption.display_name : ''
}

export const getDisplayNameOrReturnKey = ({ key, options }) => {
  const chosenOptionDisplayName = getDisplayNameByKey({ key: key, options: options })
  return chosenOptionDisplayName === '' ? key : chosenOptionDisplayName
}
export default useGetAllRentRollWorkingVersionOptions
