import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const getRentalUnitWorkingVersionKey = (rentalUnitName, segmentUuid) =>
  `${rentalUnitName ?? ''}-${segmentUuid ?? 'NO-SEGMENT-UUID'}`

const useValidateRentalUnitIds = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.id-validation',
  })
  const validateAndAdjustRentalUnitIds = useCallback(
    (rentalUnits = []) =>
      rentalUnits.reduce(
        ({ keys, rentalUnitsWithoutSameId }, _rentalUnit) => {
          const rentalUnitNameOrDefault =
            _rentalUnit?.rental_unit_name || t('rental-unit-name-default')
          const rentalUnit = { ..._rentalUnit, rental_unit_name: rentalUnitNameOrDefault }

          const key = getRentalUnitWorkingVersionKey(
            rentalUnitNameOrDefault,
            rentalUnit?.segment_uuid,
          )
          const existingKey = keys.find((preExistingKey) => preExistingKey.key === key)
          if (!existingKey) {
            rentalUnitsWithoutSameId.push(rentalUnit)
            return { keys: [...keys, { key: key, index: 0 }], rentalUnitsWithoutSameId }
          }
          existingKey.index += 1
          rentalUnitsWithoutSameId.push({
            ...rentalUnit,
            rental_unit_name: `${rentalUnitNameOrDefault} (${existingKey.index})`,
          })
          return { keys, rentalUnitsWithoutSameId }
        },
        { keys: [], rentalUnitsWithoutSameId: [] },
      ).rentalUnitsWithoutSameId,
    [t],
  )

  return { validateAndAdjustRentalUnitIds }
}

export default useValidateRentalUnitIds
