import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/handover/monitoring/HandoverInsiderListTileContent.module.css'
import { useBooleanToTextFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'
import DisplayCardViewInfoItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/DisplayCardViewInfoItemView'
import DisplayCardViewItemView from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/ui/DisplayCardViewItemView'

const HandoverInsiderListTileContent = ({ content }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.handover.insider-list',
  })

  const formatBoolean = useBooleanToTextFormatter()

  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      alignItems={FlexBoxAlignItems.Stretch}
      className={styles.displayFlexBox}
    >
      <DisplayCardViewItemView>
        <DisplayCardViewInfoItemView
          data-testid="is-list-necessary"
          label={t('is-list-necessary.label')}
          value={formatBoolean(content.isListNecessary ?? false)}
          isFirstItem
        />
      </DisplayCardViewItemView>
      {content.isListNecessary && (
        <DisplayCardViewItemView>
          <DisplayCardViewInfoItemView
            data-testid="list"
            label={t('list.label')}
            value={content.list || '-'}
          />
        </DisplayCardViewItemView>
      )}
    </FlexBox>
  )
}

HandoverInsiderListTileContent.propTypes = {
  content: PropTypes.shape({
    isListNecessary: PropTypes.bool,
    list: PropTypes.string,
  }),
}
export default HandoverInsiderListTileContent
