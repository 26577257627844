import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteConditions = (mutateOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    ({ conditionIds, conditionType }) =>
      post({
        path: `/conditions/${conditionType}/bulk-delete`,
        body: {
          condition_ids: conditionIds,
        },
      }),
    mutateOptions,
  )
}

export default useDeleteConditions
