import {
  FlexBox,
  Link,
  Label,
  FlexBoxJustifyContent,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  propertyMonitoringStatus,
  monitoringStatusTranslationKeys,
  proxySettingsTranslationKeys,
} from 'api/property/monitoring/propertyMonitoringConfiguration'
import { isNotFoundError } from 'api/requests'
import styles from 'components/domains/properties/overview/PropertyMonitoringSummaryCard.module.css'
import DisplayCardView from 'components/ui/card/DisplayCardView'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { usePropertyMonitoring } from 'hooks/services/properties/monitoring/usePropertyMonitoring'
import paths from 'routes/paths'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyMonitoringSummaryCard = () => {
  const navigate = useNavigate()
  const { format } = useShortDateFormatter()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property-overview.monitoring-summary-tile',
  })
  const { t: tNoPrefix } = useTranslation()

  const {
    property: { id: propertyId, uuid: propertyUuid },
  } = useContext(PropertyContext)
  const {
    isLoading,
    isError,
    error: propertyMonitoringError,
    data: propertyMonitoringData,
  } = usePropertyMonitoring(propertyUuid)

  const emptyContent = useMemo(
    () => ({ title: t('empty.title'), subtitle: t('empty.subtitle') }),
    [t],
  )
  const errorContent = useMemo(() => {
    if (propertyMonitoringError && isNotFoundError(propertyMonitoringError)) {
      return { title: t('error.title.not-found'), subtitle: t('error.subtitle.not-found') }
    }
    return { title: t('error.title'), subtitle: t('error.subtitle') }
  }, [t, propertyMonitoringError])

  const handleHeaderClick = useCallback(() => {
    navigate(`/${paths.properties}/${propertyId}/monitoring`)
  }, [navigate, propertyId])

  const marketValue = useCallback(() => {
    if (propertyMonitoringData?.market?.name) {
      const marketLink = `/${paths.markets}/${propertyMonitoringData.market.id}`

      return (
        <Link
          href={marketLink}
          target="_blank"
          wrappingType={WrappingType.Normal}
          className={styles.linkAllign}
        >
          {propertyMonitoringData.market.name}
        </Link>
      )
    }
    return <Label>-</Label>
  }, [propertyMonitoringData])

  const marketNameDisplay = useMemo(
    () => (
      <FlexBox
        fitContainer
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        className={styles['displayCardItem']}
      >
        <Label>{t('market-name')}</Label>
        {marketValue()}
      </FlexBox>
    ),
    [marketValue, t],
  )

  const fieldDefinitionsAllOtherStatuses = useMemo(
    () => [
      {
        label: t('status'),
        value: tNoPrefix(
          monitoringStatusTranslationKeys[propertyMonitoringData?.monitoring_status],
        ),
      },
      {
        label: t('next-monitoring-date'),
        value: format(propertyMonitoringData?.next_manual_monitoring_date),
      },
      {
        label: t('baseline-date'),
        value: propertyMonitoringData?.baseline_date
          ? format(propertyMonitoringData?.baseline_date)
          : '-',
      },
      {
        customInfoComponent: marketNameDisplay,
      },
      {
        label: t('monitoring-date'),
        value: format(propertyMonitoringData?.monitoring_date),
      },
      {
        label: t('mico-proxy-setting'),
        value: tNoPrefix(
          proxySettingsTranslationKeys[propertyMonitoringData?.proxy_settings.micro_proxies],
        ),
      },
      {
        label: t('macro-proxy-setting'),
        value: propertyMonitoringData?.market?.id
          ? tNoPrefix(
              proxySettingsTranslationKeys[propertyMonitoringData?.proxy_settings.macro_proxies],
            )
          : tNoPrefix('property-monitoring.proxies.no-monitoring'),
      },
    ],
    [t, tNoPrefix, format, marketNameDisplay, propertyMonitoringData],
  )

  const fieldDefinitionsStatusInactive = useMemo(
    () => [
      {
        label: t('status'),
        value: tNoPrefix(
          monitoringStatusTranslationKeys[propertyMonitoringData?.monitoring_status],
        ),
      },
    ],
    [t, tNoPrefix, propertyMonitoringData?.monitoring_status],
  )

  const calculateFieldDefinitions = useCallback(() => {
    if (propertyMonitoringData?.monitoring_status === propertyMonitoringStatus.inactive) {
      return fieldDefinitionsStatusInactive
    }
    return fieldDefinitionsAllOtherStatuses
  }, [
    fieldDefinitionsStatusInactive,
    fieldDefinitionsAllOtherStatuses,
    propertyMonitoringData?.monitoring_status,
  ])

  return (
    <DisplayCardView
      cardHeaderTitle={t('title')}
      isError={isError}
      errorContent={errorContent}
      emptyContent={emptyContent}
      fieldDefinitions={calculateFieldDefinitions()}
      isEditable={false}
      isLoading={isLoading}
      onHeaderClick={handleHeaderClick}
      setEditMode={() => {}}
    />
  )
}

export default PropertyMonitoringSummaryCard
