import uniq from 'lodash.uniq'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import FurtherDetailsPieChartsCard, {
  chartsWithValuations,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/FurtherDetailsPieChartsCard'
import {
  TenantColorProvider,
  useTenantColors,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/TenantColorContext'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/fact-sheet-further-details/shared/ui/EmptyCardContent'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'

const FurtherDetailsGranularityCityOrCountryTile = ({ tileId, isPdfView }) => {
  const {
    data: {
      multiPropertyKpisByGranularity,
      multiPropertyValuationsByGranularity,
      properties,
      isMultiProperty,
      noProperties,
    },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const hasPropertiesInDifferentCountries = useMemo(
    () => uniq(properties?.map((property) => property.address.countryCode)).length > 1,
    [properties],
  )

  const valueCategory = hasPropertiesInDifferentCountries ? 'country' : 'city'
  const nameCategory = hasPropertiesInDifferentCountries ? 'countryName' : 'city'

  const getRentRollKpis = (category) => (multiPropertyKpis) =>
    multiPropertyKpis?.[`${category}RentRollKpis`] ?? []

  const getValuations = (category) => (multiPropertyValuations) =>
    multiPropertyValuations?.granularities?.[category] ?? []

  const getName = (category) => (rentRollKpi) => rentRollKpi?.[category]

  if (noProperties) {
    return <NoPropertyAssignedToDealInfo />
  }
  if (!isMultiProperty) {
    return <EmptyCardContent />
  }
  return (
    <FurtherDetailsPieChartsCard
      colorProvider={TenantColorProvider}
      useColors={useTenantColors}
      getRentRollKpis={getRentRollKpis(valueCategory)}
      getValuations={getValuations(valueCategory)}
      getNameFromKpi={getName(nameCategory)}
      granularityValues={{ multiPropertyKpisByGranularity, multiPropertyValuationsByGranularity }}
      chartOptions={chartsWithValuations}
      isPdfView={isPdfView}
    />
  )
}

FurtherDetailsGranularityCityOrCountryTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
}

export default FurtherDetailsGranularityCityOrCountryTile
