import {
  BusyIndicatorSize,
  CardHeader,
  DatePicker,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  Input,
  SuggestionItem,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import BusinessObjectLink from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import styles from 'components/domains/business-events-and-tasks/events/creation/EventCreationInformationTile.module.css'
import Card from 'components/ui/card/Card'
import EditCardItem from 'components/ui/card/EditCardItem'
import InputWithSearchSuggestions from 'components/ui/input/InputWithSearchSuggestions'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useStaffMembers from 'hooks/services/business-partners/staff-members/useStaffMembers'
import { setAssignee, setEventDueDate } from 'redux/slices/events/eventCreationSlice'

const EventCreationInformationTile = ({
  entityId,
  entityType,
  disabled,
  isDefaultAssigneeLoading,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.events.creation',
  })
  const { t: tNoKeyPrefix } = useTranslation()
  const dispatch = useDispatch()
  const { eventDueDate, assignee } = useSelector((state) => state.events.eventCreation)
  const [userSearchKey, setUserSearchKey] = useState('')
  const { localePattern, format: dateFormat, parse: parseDate } = useShortDateFormatter()

  const { data: userData, isError: isSuggestionsError } = useStaffMembers({
    name: userSearchKey,
    minLength: 1,
  })

  const renderSuggestions = useCallback(
    () =>
      userData?.staffMembers.map((user, index) => (
        <SuggestionItem
          key={index}
          text={user.fullName}
          data-user-id={user.id}
          data-assignee-full-name={user.fullName}
        />
      )),
    [userData?.staffMembers],
  )

  const handleOnAssigneeChange = useCallback(
    (event) => {
      const userId = event.detail.item.getAttribute('data-user-id')
      const fullName = event.detail.item.getAttribute('data-assignee-full-name')

      if (!userId) {
        dispatch(setAssignee(null))
        return
      }

      dispatch(setAssignee({ id: userId, fullName }))
    },
    [dispatch],
  )

  const handleOnInputClear = useCallback(() => {
    dispatch(setAssignee(null))
  }, [dispatch])

  const handleDueDateChange = (event) => {
    if (event.detail.valid) {
      const date = parseDate(event.detail.value, localePattern)
      dispatch(setEventDueDate(date))
    }
  }

  const renderAssigneeWithSuggestionsInput = useCallback(
    () => (
      <InputWithSearchSuggestions
        initialValue={assignee?.fullName ?? ''}
        renderSuggestions={renderSuggestions}
        handleOnChange={handleOnAssigneeChange}
        handleOnClear={handleOnInputClear}
        setSearchKey={setUserSearchKey}
        isSuggestionsError={isSuggestionsError}
      />
    ),
    [
      assignee?.fullName,
      handleOnAssigneeChange,
      handleOnInputClear,
      isSuggestionsError,
      renderSuggestions,
    ],
  )
  const assigneeWithSuggestions = (
    <FlexBox alignItems={FlexBoxAlignItems.Center} className={styles.assigneeCell}>
      <RequestStateResolver
        errorToDisplay={<></>}
        isError={false}
        isLoading={isDefaultAssigneeLoading}
        busyIndicatorSize={BusyIndicatorSize.Small}
        renderContent={renderAssigneeWithSuggestionsInput}
      />
    </FlexBox>
  )

  const dueDatePicker = (
    <DatePicker
      value={dateFormat(eventDueDate)}
      onChange={handleDueDateChange}
      formatPattern={localePattern}
    />
  )
  const header = <CardHeader titleText={t('cards.general-information.title')} />

  return (
    <Card
      header={header}
      className={disabled ? styles.disabled : ''}
      {...(!!disabled && { inert: '' })}
    >
      <FlexBox className="card-edit-mode-wrapper" direction={FlexBoxDirection.Column}>
        <EditCardItem label={t('label.due-date')} editComponent={dueDatePicker} />
        <EditCardItem label={t('label.assignee')} editComponent={assigneeWithSuggestions} />
        <EditCardItem
          label={t('label.business-object-type')}
          editComponent={
            <Input value={tNoKeyPrefix(`events.entity-type.${entityType}`)} readonly />
          }
        />
        <EditCardItem
          label={t('label.business-object')}
          editComponent={<BusinessObjectLink entityId={entityId} entityType={entityType} />}
        />
      </FlexBox>
    </Card>
  )
}

EventCreationInformationTile.propTypes = {
  entityId: PropTypes.string,
  entityType: PropTypes.string,
  disabled: PropTypes.bool,
  isDefaultAssigneeLoading: PropTypes.bool.isRequired,
}

export default EventCreationInformationTile
