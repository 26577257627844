import { useMemo } from 'react'
import { isMissingPermissionError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useSyndication = (dealUuid, options = {}) => {
  const result = useCamelizedResponse(
    useRequest({
      path: `/syndication-structures/deals/${dealUuid}/syndications`,
      translated: true,
      keys: ['deals', dealUuid, 'syndication'],
      options: {
        retry: (_, error) => !isMissingPermissionError(error),
        ...options,
      },
    }),
  )

  return useMemo(
    () => ({
      ...result,
      data: result.data?.syndications[0],
    }),
    [result.data, result.isLoading, result.isFetching, result.isError, result.error], // eslint-disable-line react-hooks/exhaustive-deps
  )
}

export default useSyndication
