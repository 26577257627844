import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * Returns all available entity types in the change log set
 * The response body structure is as follows:
 * {
 *   "entityTypes": ["deal", "property", ...]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: entityTypes}}
 */
const useChangeLogEntityTypes = ({ entityId, entityType }) => {
  const queryParams = new URLSearchParams()
  if (entityId) queryParams.set('entity_id', entityId)
  if (entityType) queryParams.set('entity_type', entityType)
  return useCamelizedResponse(
    useRequest({
      path: `/traceability/configurations/entity-types?${queryParams.toString()}`,
      keys: ['traceability', 'configuration', 'entity-types', queryParams.toString()],
      translated: true,
      useCache: false,
    }),
  )
}

export default useChangeLogEntityTypes
