import { FlexBox } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import {
  neededOperationsForRequirementCreate,
  neededOperationsForRequirementDelete,
  neededOperationsForRequirementEdit,
} from 'api/conditions/conditionsAllowedOperations'
import { hasUserRequiredOperations } from 'api/helper'
import DeleteSelectedRequirementsButton from 'components/domains/conditions/overview/buttons/requirements/DeleteSelectedRequirementsButton'
import RequirementAddButton from 'components/domains/conditions/overview/buttons/requirements/RequirementAddButton'
import RequirementAddDocumentButton from 'components/domains/conditions/overview/buttons/requirements/RequirementAddDocumentButton'
import RequirementsMassEditButton from 'components/domains/conditions/overview/buttons/requirements/RequirementsMassEditButton'
import styles from 'components/domains/conditions/overview/buttons/requirements/RequirementsToolbarButtons.module.css'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const RequirementsToolbarButtons = ({ disabled }) => {
  const { allowedOperations } = useContext(ConditionsContext)

  const hasUserRequirementCreatePermission = useMemo(
    () => hasUserRequiredOperations(neededOperationsForRequirementCreate, allowedOperations),
    [allowedOperations],
  )

  const hasUserRequirementDeletePermission = useMemo(
    () => hasUserRequiredOperations(neededOperationsForRequirementDelete, allowedOperations),
    [allowedOperations],
  )

  const hasUserRequirementEditPermission = useMemo(
    () => hasUserRequiredOperations(neededOperationsForRequirementEdit, allowedOperations),
    [allowedOperations],
  )

  const requirementAddButton = useMemo(() => {
    if (!hasUserRequirementCreatePermission) {
      return
    }
    return <RequirementAddButton disabled={disabled} />
  }, [hasUserRequirementCreatePermission, disabled])

  const requirementAddDocumentButton = useMemo(() => {
    if (!hasUserRequirementEditPermission) {
      return
    }
    return <RequirementAddDocumentButton disabled={disabled} />
  }, [disabled, hasUserRequirementEditPermission])

  const requirementDeleteButton = useMemo(() => {
    if (!hasUserRequirementDeletePermission) {
      return
    }
    return <DeleteSelectedRequirementsButton disabled={disabled} />
  }, [hasUserRequirementDeletePermission, disabled])

  const requirementMassEditButton = useMemo(() => {
    if (!hasUserRequirementEditPermission) {
      return
    }
    return <RequirementsMassEditButton disabled={disabled} />
  }, [hasUserRequirementEditPermission, disabled])

  return (
    <FlexBox className={styles.buttons}>
      {requirementAddDocumentButton}
      {requirementAddButton}
      {requirementMassEditButton}
      {requirementDeleteButton}
    </FlexBox>
  )
}

RequirementsToolbarButtons.propTypes = {
  disabled: PropTypes.bool.isRequired,
}

export default RequirementsToolbarButtons
