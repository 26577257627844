import {
  FlexBox,
  FlexBoxJustifyContent,
  Table,
  TableColumn,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CheckedCovenantDisplayRow from 'components/domains/deals/covenant-check-detail/checked-covenants/CheckedCovenantDisplayRow'
import CheckedCovenantEditRow from 'components/domains/deals/covenant-check-detail/checked-covenants/CheckedCovenantEditRow'
import { monitoringItemShape } from 'components/domains/deals/covenant-check-detail/checked-covenants/CheckedCovenantPropTypes'
import styles from 'components/domains/deals/covenant-check-detail/checked-covenants/CheckedCovenantsTable.module.css'
import TablesToolbar from 'components/ui/tables/toolbar/TablesToolbar'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { editButtonStateEnum } from 'routes/deals/covenant-checks/useCalculateEditButtonState'

const CheckedCovenantsTable = ({
  monitoringItems = [],
  entityType,
  covenantCheckUuid,
  editButtonState,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.checked-covenants',
  })
  const { t: tNoPrefix } = useTranslation('translation')

  const [editRowUuids, setEditRowUuids] = useState([])
  const addToEditIndexes = (covenantMonitoringItemUuid) => {
    if (editRowUuids.includes(covenantMonitoringItemUuid)) return
    const newUuids = [...editRowUuids]
    newUuids.push(covenantMonitoringItemUuid)
    setEditRowUuids(newUuids)
  }
  const removeFromEditIndexes = (covenantMonitoringItemUuid) => {
    const newUuids = [...editRowUuids]
    setEditRowUuids(newUuids.filter((currendUuid) => currendUuid !== covenantMonitoringItemUuid))
  }

  const columnDefinitions = [
    { title: t('covenant'), demandPopin: false, minWidth: 0 },
    { title: t('covenant-type'), demandPopin: true, minWidth: 800 },
    { title: t('status'), demandPopin: true, minWidth: 750 },
    {
      title: t('delivery-date'),
      demandPopin: true,
      minWidth: 700,
      alignment: FlexBoxJustifyContent.End,
    },
    {
      title: t('new-delivery-date'),
      demandPopin: true,
      minWidth: 650,
      alignment: FlexBoxJustifyContent.End,
    },
    {
      title: t('soft-limit'),
      demandPopin: true,
      minWidth: 800,
      alignment: FlexBoxJustifyContent.End,
    },
    {
      title: t('hard-limit'),
      demandPopin: true,
      minWidth: 800,
      alignment: FlexBoxJustifyContent.End,
    },
    { title: t('result'), demandPopin: true, minWidth: 800, alignment: FlexBoxJustifyContent.End },
    { title: t('breach'), demandPopin: true, minWidth: 600 },
    { title: t('waiver'), demandPopin: true, minWidth: 600 },
    { key: 'editDeleteButtons', demandPopin: true, minWidth: 600 },
  ]

  return (
    <div className={styles.defaultCursor}>
      <TablesToolbar title={t('title')} nrOfEntries={monitoringItems.length} />
      <Table
        noDataText={tNoPrefix('components.table.no-data')}
        columns={columnDefinitions.map(({ title, key, demandPopin, minWidth, alignment }) => (
          <TableColumn
            key={key ?? title}
            demandPopin={demandPopin}
            popinText={title}
            minWidth={minWidth}
          >
            <FlexBox justifyContent={alignment}>{title}</FlexBox>
          </TableColumn>
        ))}
      >
        {monitoringItems.map((item) => {
          const { covenantMonitoringItemUuid } = item
          if (editRowUuids.includes(covenantMonitoringItemUuid))
            return (
              <CheckedCovenantEditRow
                key={covenantMonitoringItemUuid}
                monitoringItem={item}
                covenantCheckUuid={covenantCheckUuid}
                onSave={() => removeFromEditIndexes(covenantMonitoringItemUuid)}
                onCancel={() => removeFromEditIndexes(covenantMonitoringItemUuid)}
              />
            )
          return (
            <CheckedCovenantDisplayRow
              key={covenantMonitoringItemUuid}
              entityType={entityType}
              monitoringItem={item}
              editButtonState={editButtonState}
              onEditClick={() => addToEditIndexes(covenantMonitoringItemUuid)}
            />
          )
        })}
      </Table>
    </div>
  )
}

CheckedCovenantsTable.propTypes = {
  entityType: PropTypes.oneOf([cwpEntityTypes.DEAL, cwpEntityTypes.BUSINESS_PARTNER]),
  covenantCheckUuid: PropTypes.string,
  monitoringItems: PropTypes.arrayOf(monitoringItemShape),
  isAllowedToEdit: PropTypes.bool,
  editButtonState: PropTypes.oneOf(Object.values(editButtonStateEnum)),
}

export default CheckedCovenantsTable
