import { BusyIndicator, Icon, MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import useDeleteDocumentVersion from 'hooks/services/documents/useDeleteDocumentVersion'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'

const DocumentVersionDelete = ({ documentId, documentVersionGuid }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.document-version-delete',
  })
  const { t: tNoPrefix } = useTranslation()
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)

  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false)
  const [deletionError, setDeletionError] = useState(undefined)

  const asyncSetSaveErrorDetails = async (error) => setDeletionError(await formatHookError(error))

  const { isLoading, mutate: deleteDocumentVersion } = useDeleteDocumentVersion({
    onSuccess: () => {
      Modals.showToast({ children: <span>{t('success')}</span> })
      setDeletionError(undefined)
    },
    onError: (error) => {
      setIsErrorMessageOpen(true)
      asyncSetSaveErrorDetails(error)
    },
  })

  const handleClick = () => {
    setIsConfirmationDialogOpen(true)
  }

  const handleConfirmation = () => {
    setIsConfirmationDialogOpen(false)
    deleteDocumentVersion({ documentId, versionGuid: documentVersionGuid })
  }

  const handleCancel = () => {
    setIsConfirmationDialogOpen(false)
  }

  return (
    <>
      {isLoading ? (
        <BusyIndicator size="Small" delay={0} active={true} />
      ) : (
        <Icon name="delete" interactive onClick={handleClick} />
      )}
      <ConfirmationDialog
        handleConfirmation={handleConfirmation}
        handleCancel={handleCancel}
        isOpen={isConfirmationDialogOpen}
        confirmationMessage={t('confirmation-dialog.description')}
        confirmationTitle={t('confirmation-dialog.title')}
        confirmButtonText={tNoPrefix('buttons.ok')}
        messageBoxType={MessageBoxTypes.Information}
      />
      {createPortal(
        <>
          {isErrorMessageOpen && (
            <ErrorMessageBoxWithExpandableDetails
              messageSummary={t('error')}
              messageDetails={deletionError}
              isOpen={isErrorMessageOpen}
              onClose={() => {
                setIsErrorMessageOpen(false)
              }}
            />
          )}
        </>,
        document.body,
      )}
    </>
  )
}

DocumentVersionDelete.propTypes = {
  documentId: PropTypes.string.isRequired,
  documentVersionGuid: PropTypes.string,
}

export default DocumentVersionDelete
