import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const processResponse = (response) => {
  // TODO: either flatten all types here or none
  const dataWithFlattenedActionTypes = response.data
    ? {
        ...response.data,
        external: response.data.external.map((externalRating) =>
          externalRating.action_type
            ? { ...externalRating, action_type: externalRating.action_type.name }
            : externalRating,
        ),
      }
    : undefined

  return {
    ...response,
    data: camelize(dataWithFlattenedActionTypes),
  }
}

/**
 * @typedef {{id: string, name: string}} IdNameEntity
 * @typedef {validFrom: string, validTo: string, customAttributes: Record<string, Record<string, any>>} RatingBase
 * @typedef {{ratingClass: string, rate: number, method: IdNameEntity, isActive: boolean} & RatingBase} RatingInternal
 * @typedef {{agency: IdNameEntity, grade: string, outlook: IdNameEntity, actionType: string } & RatingBase} RatingExternal
 * @typedef {{internal: RatingInternal[], external: RatingExternal[]}} RatingResponse
 *
 * @param {string[]} businessPartnerIds
 * @param {import('@tanstack/react-query').QueryOptions} options
 * @returns {import('@tanstack/react-query').UseQueryResult<RatingResponse>[]}
 */
const useMultipleBusinessPartnerRatings = (businessPartnerIds, options) => {
  const responses = useRequestsMemo(
    useRequests({
      requests: businessPartnerIds.map((businessPartnerId) => ({
        path: `/businesspartners/${businessPartnerId}/ratings`,
        keys: ['businesspartners', businessPartnerId, 'ratings'],
      })),
      options,
    }),
  )
  return useMemo(() => responses.map(processResponse), [responses])
}

export default useMultipleBusinessPartnerRatings
