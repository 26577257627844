import { FlexBox } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { getButtonForDecisionStageMinutesVoterVoteOption } from 'api/decision-process/decisionProcessMinutesApi'
import styles from 'components/domains/business-events-and-tasks/decision-stage/minutes/approval/DecisionStageMinutesApprovalVoterHeaderButtons.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useDecisionStageMinutesApprovalVoterVoteOptions from 'hooks/services/business-events-and-tasks/decision-process/stages/minutes/approval/useDecisionStageMinutesApprovalVoterVoteOptions'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionStageMinutesApprovalVoterHeaderButtons = ({
  decisionStageMinutesVoterId: decisionStageMinutesVoterId,
}) => {
  const {
    event: { id: eventId },
  } = useContext(BusinessEventsAndTasksContext)
  const { decisionStageId } = useParams()

  const { isLoading, isError, data } = useDecisionStageMinutesApprovalVoterVoteOptions(
    {
      eventId,
      decisionStageId,
      decisionStageMinutesVoterId,
    },
    { enabled: !!decisionStageMinutesVoterId },
  )

  const renderButtons = useCallback(
    () => (
      <FlexBox className={styles.buttonWrapper}>
        {data.voteOptions.map(({ code }) => {
          const ButtonComponent = getButtonForDecisionStageMinutesVoterVoteOption(code)
          return (
            <ButtonComponent
              key={`vote-option-${code}`}
              eventId={eventId}
              decisionStageId={decisionStageId}
              decisionStageMinutesVoterId={decisionStageMinutesVoterId}
            />
          )
        })}
      </FlexBox>
    ),
    [data, decisionStageId, decisionStageMinutesVoterId, eventId],
  )

  return (
    <SmallLoadingWrapper
      isLoading={!decisionStageMinutesVoterId || isLoading}
      isError={isError}
      error=""
      renderContent={renderButtons}
    />
  )
}

DecisionStageMinutesApprovalVoterHeaderButtons.propTypes = {
  decisionStageMinutesVoterId: PropTypes.string,
}

export default DecisionStageMinutesApprovalVoterHeaderButtons
