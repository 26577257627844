import {
  AnalyticalTable,
  AnalyticalTableScaleWidthMode,
  AnalyticalTableSelectionBehavior,
  AnalyticalTableSelectionMode,
  FlexBox,
  FlexBoxDirection,
  MessageStrip,
  MessageStripDesign,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { conditionTypes, conditionsEntityTypes } from 'api/conditions/conditions'
import ConditionCommentDialog from 'components/domains/conditions/dialogs/comments/ConditionCommentDialog'
import styles from 'components/domains/conditions/overview/ConditionsTable.module.css'
import ConditionsTableEmpty from 'components/domains/conditions/overview/ConditionsTableEmpty'
import getVisibleAndSelectedItemIds from 'components/domains/conditions/overview/getVisibleAndSelectedItemIds'
import ConditionsTableCommentSubcomponent from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableCommentSubcomponent'
import ConditionsTableCovenantCheckSubcomponent from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableCovenantCheckSubcomponent'
import ConditionsTableDealExternalAssigneeSubcomponent from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableDealExternalAssigneeSubcomponent'
import ConditionsTableDescriptionSubcomponent from 'components/domains/conditions/overview/table-cells/conditions/ConditionsTableDescriptionSubcomponent'
import TableCreationDateSubcomponent from 'components/domains/conditions/overview/table-cells/generic/TableCreationDateSubcomponent'
import tableSubComponentStyles from 'components/domains/conditions/overview/table-cells/view/TableSubcomponent.module.css'
import { conditionFilterPrefixes } from 'hooks/services/conditions/tables/useConditionFilterQuery'
import useTableHeightHelper from 'hooks/services/conditions/tables/useTableHeightHelper'
import {
  closeConditionCommentDialog,
  reset as resetConditionErrorState,
  setSelectedConditionRows,
} from 'redux/slices/conditions/conditionsTableSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const noop = () => {}

const rowHeight = 58
const defaultSubcomponentHeight = 172
const maximumHeight = 1200

const defaultShownSubcomponents = {
  description: true,
  externalAssignee: true,
  comment: true,
  creationDate: true,
  covenantCheck: true,
}

const ConditionsTable = ({
  isLoading,
  conditions,
  columns,
  isFilterOrSearchApplied,
  fetchNextPage,
  conditionType,
  shownSubcomponents = defaultShownSubcomponents,
  emptyText,
  selectionMode = AnalyticalTableSelectionMode.MultiSelect,
  selectedConditions = {},
  alwaysShowDescription = false,
  onConditionSelectionChanged = noop,
  onRowClick = noop,
  subcomponentHeight = defaultSubcomponentHeight,
  isPdfView = false,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.conditions.table' })
  const dispatch = useDispatch()
  const tableRef = useRef()
  const navigate = useNavigate()
  const [queryParams, setQueryParams] = useSearchParams()
  const location = useLocation()
  const {
    entityRef: { entityType },
  } = useContext(ConditionsContext)

  const showErrorStrip = useSelector((state) => state.conditions.conditionsTable.rowsHaveAnError)
  const conditionErrorRows = useSelector((state) => state.conditions.conditionsTable.errorRows)
  const conditionHighlightRows = useSelector(
    (state) => state.conditions.conditionsTable.highlightRows,
  )
  const singleEditRowId = useSelector(
    (state) => state.conditions.conditionsTable.editedRow.conditionId,
  )
  const selectedConditionIds = useSelector(
    (state) => state.conditions.conditionsTable.selectedRows.selectedConditionIds,
  )

  const sortedConditions = useMemo(() => {
    const newConditions = []
    const oldConditions = []
    conditions?.forEach((condition) => {
      if (conditionHighlightRows[condition.id]) {
        newConditions.push(condition)
      } else {
        oldConditions.push(condition)
      }
    })
    return [...newConditions, ...oldConditions]
  }, [conditionHighlightRows, conditions])

  const {
    isOpen: isCommentDialogOpen,
    conditionId: conditionIdForCommentDialog,
    conditionType: conditionTypeForCommentDialog,
  } = useSelector((state) => state.conditions.conditionsTable.commentDialog)

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  useEffect(() => {
    const querySearchString = queryParams.toString()

    for (const [key] of urlSearchParams.entries()) {
      if (key.startsWith(conditionFilterPrefixes.requirements)) queryParams.delete(key)
    }

    if (querySearchString !== queryParams.toString()) {
      // navigate because setQueryParams also removes the location hash
      navigate({
        hash: location.hash,
        search: queryParams.toString(),
      })
    }
  }, [queryParams, setQueryParams, urlSearchParams, location, navigate])

  useEffect(() => {
    if (conditions !== undefined) {
      const newSelectedConditionIds = getVisibleAndSelectedItemIds({
        visibleItems: sortedConditions,
        selectedIds: selectedConditionIds,
      })
      if (!isEqual(newSelectedConditionIds, selectedConditionIds)) {
        dispatch(setSelectedConditionRows({ selectedConditionIds: newSelectedConditionIds }))
      }
    }
  }, [dispatch, selectedConditionIds, sortedConditions, conditions])

  const selectedRowIds = useMemo(() => {
    const selectedConditionIdsArray = Object.keys(selectedConditionIds)
    const selectedRowIdsResult = {}
    sortedConditions?.forEach(({ id: conditionId }, index) => {
      if (selectedConditionIdsArray.includes(conditionId)) {
        selectedRowIdsResult[index] = true
      }
    })

    return selectedRowIdsResult
  }, [selectedConditionIds, sortedConditions])

  useEffect(() => {
    dispatch(
      setSelectedConditionRows({
        selectedConditionIds: selectedConditions,
      }),
    )
    return () => {
      dispatch(resetConditionErrorState())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!singleEditRowId) {
      return
    }
    const indexOfSelectedRow = sortedConditions.findIndex(({ id }) => id === singleEditRowId)
    tableRef.current?.toggleRowExpanded(indexOfSelectedRow, true)
  }, [singleEditRowId, sortedConditions])

  // This is a hack for keeping the state of the Analytical Table in Sync with the
  // Redux state. Other solutions did not work as expected.
  useEffect(() => {
    tableRef.current?.toggleAllRowsSelected(false)
    Object.keys(selectedRowIds).forEach((rowId) => tableRef.current.toggleRowSelected(rowId, true))
  }, [selectedRowIds])

  const renderSubRow = ({
    original: {
      id: conditionId,
      info: { description, externalAssignee, creationDate, covenantCheckId },
      numberOfComments,
    },
  }) => (
    <FlexBox
      direction={FlexBoxDirection.Column}
      className={tableSubComponentStyles.tableSubcomponentFlexBox}
    >
      {!!shownSubcomponents.description && (
        <ConditionsTableDescriptionSubcomponent
          description={description}
          conditionId={conditionId}
        />
      )}
      {!!shownSubcomponents.covenantCheck && (
        <ConditionsTableCovenantCheckSubcomponent
          conditionId={conditionId}
          covenantCheckId={covenantCheckId}
        />
      )}
      {!!shownSubcomponents.externalAssignee && entityType === conditionsEntityTypes.deal && (
        <ConditionsTableDealExternalAssigneeSubcomponent
          conditionId={conditionId}
          externalAssignee={externalAssignee}
        />
      )}
      {!!shownSubcomponents.comment && (
        <ConditionsTableCommentSubcomponent
          conditionId={conditionId}
          numberOfComments={numberOfComments}
          conditionType={conditionType}
        />
      )}
      {!!shownSubcomponents.creationDate && (
        <TableCreationDateSubcomponent creationDate={creationDate} />
      )}
    </FlexBox>
  )

  const highlightField = useCallback(
    ({ id: conditionId }) => {
      if (conditionHighlightRows[conditionId]) {
        return ValueState.Information
      }
      if (conditionErrorRows[conditionId]) {
        return ValueState.Error
      }
      return ValueState.None
    },
    [conditionErrorRows, conditionHighlightRows],
  )

  const noDataComponent = useCallback(
    () => ConditionsTableEmpty({ isFilterOrSearchApplied, overrideText: emptyText }),
    [isFilterOrSearchApplied, emptyText],
  )

  const onRowSelect = useCallback(
    (event) => {
      const newSelectedConditionIds = { ...selectedConditionIds }

      if (event.detail.row) {
        const selectedOrUnselectedIndex = event.detail.row.id
        const {
          id: selectedConditionId,
          status: { type: selectedConditionStatus },
          references,
        } = sortedConditions[selectedOrUnselectedIndex]
        const isConditionAlreadySelected = selectedConditionIds[selectedConditionId]

        if (isConditionAlreadySelected) {
          delete newSelectedConditionIds[selectedConditionId]
          dispatch(
            setSelectedConditionRows({
              selectedConditionIds: newSelectedConditionIds,
            }),
          )
          onConditionSelectionChanged(newSelectedConditionIds)
          return
        }

        newSelectedConditionIds[selectedConditionId] = {
          status: selectedConditionStatus,
          references,
        }
        dispatch(
          setSelectedConditionRows({
            selectedConditionIds: newSelectedConditionIds,
          }),
        )
        onConditionSelectionChanged(newSelectedConditionIds)
        return
      }

      if (event.detail.allRowsSelected) {
        sortedConditions.forEach(({ id: conditionId, status: { type: conditionStatusType } }) => {
          newSelectedConditionIds[conditionId] = {
            status: conditionStatusType,
          }
        })
        dispatch(
          setSelectedConditionRows({
            selectedConditionIds: newSelectedConditionIds,
          }),
        )
        onConditionSelectionChanged(newSelectedConditionIds)
        return
      }

      sortedConditions.forEach(({ id: conditionId }) => {
        delete newSelectedConditionIds[conditionId]
      })
      dispatch(
        setSelectedConditionRows({
          selectedConditionIds: newSelectedConditionIds,
        }),
      )
      onConditionSelectionChanged(newSelectedConditionIds)
    },
    [dispatch, sortedConditions, selectedConditionIds, onConditionSelectionChanged],
  )

  const handleRowClick = (event) => {
    if (isNil(event.target.dataset.selectionCell)) {
      return
    }
    const isCheckboxClicked = event.target.dataset.selectionCell === 'true'
    // if any of the rows are in edit mode, do not allow click event
    if (!singleEditRowId && !isCheckboxClicked) {
      onRowClick(event)
    }
  }

  const setIsOpenCommentDialog = useCallback(
    (isOpen) => {
      if (!isOpen) {
        dispatch(closeConditionCommentDialog())
      }
    },
    [dispatch],
  )

  const { onExpandChange, tableHeight } = useTableHeightHelper({
    rowHeight,
    subcomponentHeight,
    maximumHeight,
    dataLength: sortedConditions?.length,
  })

  const onLoadMore = useCallback(() => fetchNextPage(), [fetchNextPage])

  const useCustomTableHooks = useCallback(
    (hooks) => {
      hooks.useFinalInstance.push(onExpandChange)
    },
    [onExpandChange],
  )

  return (
    <div className={styles.tableWrapper}>
      {showErrorStrip && (
        <MessageStrip
          hideCloseButton
          design={MessageStripDesign.Negative}
          className={styles.messageStrip}
        >
          {t('error.rows-have-error')}
        </MessageStrip>
      )}
      <AnalyticalTable
        className={styles.table}
        tableInstance={tableRef}
        onRowSelect={onRowSelect}
        columns={columns}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Smart}
        selectionBehavior={AnalyticalTableSelectionBehavior.RowSelector}
        minRows={1}
        rowHeight={rowHeight}
        tableHeight={tableHeight}
        headerRowHeight={40}
        groupable={false}
        filterable={false}
        sortable={false}
        data={sortedConditions}
        loading={isLoading}
        showOverlay={isLoading}
        isTreeTable
        infiniteScroll
        infiniteScrollThreshold={10}
        onLoadMore={onLoadMore}
        selectionMode={singleEditRowId ? AnalyticalTableSelectionMode.None : selectionMode}
        renderRowSubComponent={renderSubRow}
        NoDataComponent={noDataComponent}
        reactTableOptions={{
          autoResetHiddenColumns: false,
          autoResetSelectedRows: false,
          useControlledState: (state) =>
            useMemo(() => ({ ...state, selectedRowIds }), [state, selectedRowIds]), // eslint-disable-line react-hooks/exhaustive-deps
          conditionType,
          isPdfView,
        }}
        withRowHighlight
        highlightField={highlightField}
        onRowClick={handleRowClick}
        alwaysShowSubComponent={alwaysShowDescription}
        tableHooks={[useCustomTableHooks]}
      />
      {isCommentDialogOpen && (
        <ConditionCommentDialog
          isOpen={isCommentDialogOpen}
          conditionId={conditionIdForCommentDialog}
          conditionType={conditionTypeForCommentDialog}
          setIsOpen={setIsOpenCommentDialog}
        />
      )}
    </div>
  )
}

ConditionsTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.shape({
        type: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      isVisible: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  isFilterOrSearchApplied: PropTypes.bool.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  conditionType: PropTypes.oneOf(Object.values(conditionTypes)).isRequired,
  emptyText: PropTypes.string,
  selectionMode: PropTypes.oneOf(Object.values(AnalyticalTableSelectionMode)),
  selectedConditions: PropTypes.objectOf(PropTypes.shape({ status: PropTypes.string })),
  alwaysShowDescription: PropTypes.bool,
  onConditionSelectionChanged: PropTypes.func,
  onRowClick: PropTypes.func,
  shownSubcomponents: PropTypes.shape({
    description: PropTypes.bool,
    externalAssignee: PropTypes.bool,
    comment: PropTypes.bool,
    creationDate: PropTypes.bool,
    covenantCheck: PropTypes.bool,
  }),
  subcomponentHeight: PropTypes.number,
  isPdfView: PropTypes.bool,
}

export default ConditionsTable
