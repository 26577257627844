import { Table, TableColumn } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/portfolio/rent-roll/PropertyPortfolioRentRollCardTable.module.css'
import PropertyPortfolioRentRollCardTableRow from 'components/domains/properties/portfolio/rent-roll/PropertyPortfolioRentRollCardTableRow'
import useRentRollWorkingVersions from 'hooks/services/properties/useRentRollWorkingVersions'

const PropertyPortfolioRentRollCardTable = ({ properties }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.properties.portfolio.rent-roll.properties-card.table',
  })

  const propertyUuids = properties?.map((property) => property.uuid)
  const { data: rentRollWorkingVersions } = useRentRollWorkingVersions(propertyUuids)

  const renderTableRow = () =>
    properties.map((property) => (
      <PropertyPortfolioRentRollCardTableRow
        key={property.uuid}
        property={property}
        workingVersions={rentRollWorkingVersions}
      />
    ))

  return (
    <Table
      columns={
        <>
          <TableColumn>{t('property')}</TableColumn>
          <TableColumn className={styles.centerColumn}>{t('published-rent-roll')}</TableColumn>
          <TableColumn className={styles.centerColumn}>{t('creator')}</TableColumn>
          <TableColumn className={styles.keyDateColumn}>{t('key-date')}</TableColumn>
          <TableColumn>{t('comment')}</TableColumn>
          <TableColumn className={styles.centerColumn}>{t('working-version')}</TableColumn>
        </>
      }
    >
      {renderTableRow()}
    </Table>
  )
}

PropertyPortfolioRentRollCardTable.propTypes = {
  properties: PropTypes.array.isRequired,
}

export default PropertyPortfolioRentRollCardTable
