const sortUnitMembersForTable = (tableData, sortingKey, order) =>
  [...tableData].sort((rowA, rowB) => {
    if (!!rowA.leadingMemberCategory && !rowB.leadingMemberCategory) return -1
    if (!rowA.leadingMemberCategory && !!rowB.leadingMemberCategory) return 1
    return (
      (rowA[sortingKey].value ?? '').localeCompare(rowB[sortingKey].value ?? '') *
      (order === 'desc' ? -1 : 1)
    )
  })

export default sortUnitMembersForTable
