import { Label, Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import TableWrappingTextCell from 'components/domains/conditions/overview/table-cells/generic/TableWrappingTextCell'
import styles from 'components/domains/conditions/overview/table-cells/requirements/RequirementsTableDocumentTypeSubcomponent.module.css'
import DocumentTypeInput from 'components/domains/documents/DocumentTypeInput'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import useRequirementsCellAddMode from 'hooks/services/conditions/edit-mode/useRequirementsCellAddMode'
import useRequirementsCellEditMode from 'hooks/services/conditions/edit-mode/useRequirementsCellEditMode'
import useRequirementsTableDocumentTypeErrors from 'hooks/services/conditions/tables/useRequirementsTableDocumentTypeErrors'
import {
  requirementDocumentTypeChanged,
  requirementRowHasError,
  resetRequirementRowHasError,
} from 'redux/slices/conditions/requirementsTableSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const RequirementsTableDocumentTypeSubcomponent = ({
  requirementId,
  references,
  documentType: viewDocumentType,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.columns.document-type',
  })
  const {
    entityRef: { entityId, entityType },
  } = useContext(ConditionsContext)
  const dispatch = useDispatch()
  const { isAddModeForCurrentRow } = useRequirementsCellAddMode({
    requirementId,
  })
  const { isEditModeForCurrentRow } = useRequirementsCellEditMode({ requirementId })
  const hasDocumentTypeErrorEditMode = useSelector(
    (state) => !!state.conditions.requirementsTable.editedRow.errorFields?.documentType,
  )
  const currentDocumentType = useSelector(
    (state) => state.conditions.requirementsTable.editedRow.currentValues?.documentType,
  )
  const editedReferences = useSelector(
    (state) => state.conditions.requirementsTable.editedRow.currentValues?.condition?.references,
  )

  const documentTypesEntityRefsForErrorCheck = useMemo(() => {
    switch (entityType) {
      case DocumentTypes.BusinessPartner:
        return [
          { type: DocumentTypes.Requirement, id: 'no-id' },
          { type: entityType, id: entityId },
        ]

      case DocumentTypes.Deal:
        if (editedReferences?.entityIds && editedReferences?.entityIds.length > 0) {
          return editedReferences
        }

        if (references?.entityIds?.length > 0) {
          return references
        }

        return { entityType: entityType, entityIds: [entityId] }

      default:
        break
    }
  }, [editedReferences, entityId, entityType, references])

  const onChangeDocumentType = useCallback(
    (newDocumentType) => {
      if (newDocumentType !== '') {
        dispatch(resetRequirementRowHasError({ id: requirementId }))
        dispatch(requirementDocumentTypeChanged({ documentType: newDocumentType }))
        return
      }
      dispatch(requirementDocumentTypeChanged({ documentType: undefined }))
      dispatch(requirementRowHasError({ id: requirementId, error: { hasError: true } }))
    },
    [dispatch, requirementId],
  )

  const isAddOrEditMode = isAddModeForCurrentRow || isEditModeForCurrentRow

  const { isDocumentTypeInvalid } = useRequirementsTableDocumentTypeErrors({
    references: documentTypesEntityRefsForErrorCheck,
    entityType,
    entityId,
    currentDocumentType: viewDocumentType,
    requirementId,
    isAddOrEditMode: isAddOrEditMode,
  })

  const valueStateErrorMessage = useMemo(() => {
    if (isDocumentTypeInvalid && viewDocumentType === currentDocumentType) {
      return t('error.invalid')
    }
    return hasDocumentTypeErrorEditMode ? t('error.required') : undefined
  }, [
    currentDocumentType,
    hasDocumentTypeErrorEditMode,
    isDocumentTypeInvalid,
    t,
    viewDocumentType,
  ])

  if (isAddOrEditMode) {
    const documentTypesEntityRefsForDocumentTypeInput = () => {
      switch (entityType) {
        case DocumentTypes.BusinessPartner:
          return [
            { type: DocumentTypes.Requirement, id: 'no-id' },
            { type: entityType, id: entityId },
          ]

        case DocumentTypes.Deal:
          if (editedReferences?.entityIds && editedReferences?.entityIds.length > 0) {
            return [
              { type: DocumentTypes.Requirement, id: 'no-id' },
              ...editedReferences.entityIds.map((id) => ({
                type: editedReferences.entityType,
                id,
              })),
            ]
          }

          return [
            { type: DocumentTypes.Requirement, id: 'no-id' },
            { type: entityType, id: entityId },
          ]
        default:
          break
      }
    }

    return (
      <div>
        <Label>{t('label')}</Label>
        <DocumentTypeInput
          value={currentDocumentType}
          valueState={valueStateErrorMessage ? ValueState.Error : undefined}
          valueStateMessage={valueStateErrorMessage}
          onChange={onChangeDocumentType}
          entityRefs={documentTypesEntityRefsForDocumentTypeInput()}
        />
      </div>
    )
  }
  return (
    <div>
      <Label>{t('label')}</Label>
      <div>
        <TableWrappingTextCell value={viewDocumentType} />
      </div>
      {isDocumentTypeInvalid && (
        <div>
          <Text className={styles.invalidErrorMessage}>{t('error.invalid')}</Text>
        </div>
      )}
    </div>
  )
}

RequirementsTableDocumentTypeSubcomponent.propTypes = {
  requirementId: PropTypes.string,
  documentType: PropTypes.string,
  references: PropTypes.shape({
    entityIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    entityType: PropTypes.string.isRequired,
  }),
}

export default RequirementsTableDocumentTypeSubcomponent
