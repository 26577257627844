import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteConditionComment = (mutationOptions) => {
  const { delete: deleteComment } = useAccessTokenRequest()

  return useMutation(async ({ conditionId, commentId, conditionType }) => {
    const { data } = await deleteComment({
      path: `/conditions/${conditionType}/${conditionId}/comments/${commentId}`,
      keys: ['conditions', conditionType, conditionId, 'comments', commentId],
    })
    return data
  }, mutationOptions)
}

export default useDeleteConditionComment
