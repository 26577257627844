import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useDeals from 'hooks/services/deals/useDeals'

const useRiskIndicatorDealSelection = ({ entityRef: { entityId: businessPartnerId } }, tileId) => {
  const {
    data: { deals: dealsData = [] } = {},
    isLoading: isDealsLoading,
    isError: isDealsError,
    error: dealsError,
  } = useDeals({ filter: { borrowerId: businessPartnerId } }) || {}

  // although this is a hook, eslint still recognizes this as a false usage within useMemo below
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedDealId =
    useSelector(
      (state) =>
        state.decisionPaper.tilesOverview.tileMetadata?.[
          availableAutomaticTiles.monitoredRiskIndicatorDealSpecificsDealSelection
        ]?.selectedDealId,
    ) ?? dealsData?.[0]?.dealId

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isDealsLoading],
    errorValues: [isDealsError],
    errorDetails: [dealsError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        sourceRender: {
          businessPartnerId,
          dealDisplayId: selectedDealId,
        },
      },
    }
  }, [businessPartnerId, dealsData, error, isSomeValueError, isSomeValueLoading, selectedDealId])
}

export default useRiskIndicatorDealSelection
