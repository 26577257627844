import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import useUpdateCovenantCheckStatus from 'hooks/services/deals/covenants/checks/useUpdateCovenantCheckStatus'

const useCloseCovenantCheck = ({ covenantCheckUuid, successMessage }) => {
  const { t } = useTranslation('translation')
  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const showErrorMessageBox = useShowErrorMessageBox()

  const onSuccess = () => {
    showToast({ children: successMessage }, document.body)
    queryClient.invalidateQueries('covenants', 'covenant-checks', covenantCheckUuid)
    setIsLoading(false)
  }

  const onError = async (error) => {
    setIsLoading(false)
    const { errors: [errorResponse] = [] } = await error.response.json()
    showErrorMessageBox(
      {
        message: t('pages.deals.covenants.covenant-check.close-error'),
        error: errorResponse,
      },
      document.body,
    )
  }

  const closeCovenantCheck = useUpdateCovenantCheckStatus({ onSuccess, onError })
  const performClosing = () => {
    setIsLoading(true)
    closeCovenantCheck.mutate({ covenantCheckUuid, status: { status: 'CLOSED' } })
  }

  return { isLoading, performClosing }
}

export default useCloseCovenantCheck
