import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { conditionsAllowedOperations } from 'api/conditions/conditionsAllowedOperations'
import { covenantPermissions } from 'api/deals/covenantPermissions'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import NavigationL2 from 'components/ui/navigation/NavigationL2'
import RecentVisitWrapper from 'components/ui/recent-visit/RecentVisitWrapper'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { ConfigContext } from 'hooks/config/useConfig'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationships'
import useConditionsAllowedOperations from 'hooks/services/conditions/useConditionsAllowedOperations'
import { useCovenantAllowedOperations } from 'hooks/services/deals/covenants/useCovenantAllowedOperations'
import useBusinessPartnerNeedToKnow from 'hooks/services/need-to-know/useBusinessPartnerNeedToKnow'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import BusinessPartnerWithoutNeed2Know from 'routes/business-partners/BusinessPartnerWithoutNeed2Know'

const tenantRole = { id: 'Tenant' }
const borrowerRole = { id: 'Borrower' }
const brandOrFranchiseProviderRole = { id: 'Brand-or-Franchise-Provider' }
const sponsorRole = { id: 'Sponsor' }

const BusinessPartnerWrapper = ({ navItems }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner.not-found' })
  const { businessPartnerId } = useParams()

  const {
    businessPartner: {
      sapRelationshipIds: {
        borrower: borrowerRelationshipId,
        reviewUnit: reviewUnitRelationshipId,
      } = {},
    } = {},
  } = useContext(ConfigContext)

  const {
    isLoading: isConditionsAllowedOperationsLoading,
    isError: isConditionsAllowedOperationsError,
    data: conditionsAllowedOperationsData = {},
  } = useConditionsAllowedOperations()

  const {
    data: businessPartnerMiniData,
    isLoading: isLoadingBusinessPartnerMini,
    isError: isErrorBusinessPartnerMini,
  } = useBusinessPartnerMiniByIds([businessPartnerId])

  const {
    data: businessPartnerFull,
    isLoading: isLoadingBusinessPartnerFull,
    isError: isErrorBusinessPartnerFull,
  } = useBusinessPartnerById(businessPartnerId)
  const {
    data: { unitRelationships = [] } = {},
    isLoading: isLoadingBusinessPartnerRelationships,
    isError: isErrorBusinessPartnerRelationships,
  } = useBusinessPartnerRelationships(businessPartnerId)

  const {
    data: needToKnow,
    isLoading: isLoadingNTK,
    isError: isErrorNTK,
  } = useBusinessPartnerNeedToKnow(businessPartnerId)

  const {
    isLoading: isLoadingAllowedOperationsCovenant,
    isError: isErrorAllowedOperationsCovenant,
    data: { allowedOperations: covenantAllowedOperations = [] } = {},
  } = useCovenantAllowedOperations()

  const isNotFoundBP = needToKnow?.allowed
    ? /* full scenario: is full bp found? */
      !isLoadingBusinessPartnerFull && !isErrorBusinessPartnerFull && !businessPartnerFull
    : /* mini scenario: is mini bp found? */
      !isLoadingBusinessPartnerMini &&
      !isErrorBusinessPartnerMini &&
      !businessPartnerMiniData?.businessPartnerMinis?.length

  const businessPartnerMini = businessPartnerMiniData?.businessPartnerMinis?.[0] ?? undefined

  const isLoading =
    isLoadingBusinessPartnerMini ||
    isLoadingBusinessPartnerFull ||
    isLoadingBusinessPartnerRelationships ||
    isLoadingNTK ||
    isConditionsAllowedOperationsLoading ||
    isLoadingAllowedOperationsCovenant

  const isErrorMini = isErrorBusinessPartnerMini || isErrorNTK
  const isErrorOverall =
    isErrorBusinessPartnerFull || isErrorBusinessPartnerRelationships || isErrorMini

  const hasRoleTenant = (bp) => bp?.roles?.some(({ id }) => id === tenantRole.id)
  const hasRoleBorrower = (bp) => bp?.roles?.some(({ id }) => id === borrowerRole.id)
  const hasRoleBrandOrFranchiseProvider = (bp) =>
    bp?.roles?.some(({ id }) => id === brandOrFranchiseProviderRole.id)
  const hasRoleSponsor = (bp) => bp?.roles?.some(({ id }) => id === sponsorRole.id)

  const isBorrowerUnitRelationship = useCallback(
    (relationshipId) => !isNil(borrowerRelationshipId) && relationshipId === borrowerRelationshipId,
    [borrowerRelationshipId],
  )
  const isReviewUnitRelationship = useCallback(
    (relationshipId) =>
      !isNil(reviewUnitRelationshipId) && relationshipId === reviewUnitRelationshipId,
    [reviewUnitRelationshipId],
  )

  const isHeadOfBorrowerUnit = useCallback(
    (bp) =>
      unitRelationships.some(
        ({ id, relationships }) =>
          isBorrowerUnitRelationship(id) && relationships?.some(({ head }) => head.id === bp?.id),
      ),
    [isBorrowerUnitRelationship, unitRelationships],
  )

  const isHeadOfReviewUnit = useCallback(
    (bp) =>
      unitRelationships.some(
        ({ id, relationships }) =>
          isReviewUnitRelationship(id) && relationships?.some(({ head }) => head.id === bp?.id),
      ),
    [isReviewUnitRelationship, unitRelationships],
  )

  const hasConditionsReadPermission = useMemo(
    () =>
      !isConditionsAllowedOperationsError &&
      conditionsAllowedOperationsData.allowedOperations?.includes(
        conditionsAllowedOperations.readCondition,
      ),
    [conditionsAllowedOperationsData, isConditionsAllowedOperationsError],
  )

  const hasPeriodicalCheckReadPermission = useMemo(
    () =>
      !isErrorAllowedOperationsCovenant &&
      covenantAllowedOperations?.includes(covenantPermissions.readPeriodicalCheck),
    [isErrorAllowedOperationsCovenant, covenantAllowedOperations],
  )
  const hasCovenantReadPermission = useMemo(
    () =>
      !isErrorAllowedOperationsCovenant &&
      covenantAllowedOperations?.includes(covenantPermissions.readCovenantContractMonitoring),
    [isErrorAllowedOperationsCovenant, covenantAllowedOperations],
  )

  const filteredNavItems = useMemo(
    () =>
      navItems.filter(({ path }) => {
        switch (path) {
          case 'tenancy':
            return (
              hasRoleTenant(businessPartnerFull) ||
              hasRoleBrandOrFranchiseProvider(businessPartnerFull)
            )
          case 'exposure':
            return hasRoleBorrower(businessPartnerFull)
          case 'group-exposures':
            return (
              isHeadOfBorrowerUnit(businessPartnerFull) || isHeadOfReviewUnit(businessPartnerFull)
            )
          case 'sponsor':
            return hasRoleSponsor(businessPartnerFull)
          case 'financial-product-overview':
            return hasRoleBorrower(businessPartnerFull)
          case 'unit-overview':
            return hasRoleBorrower(businessPartnerFull)
          case 'conditions':
            return hasConditionsReadPermission
          case 'periodical-checks':
            return hasPeriodicalCheckReadPermission
          case 'covenants':
            return hasCovenantReadPermission
          default:
            return true
        }
      }),
    [
      businessPartnerFull,
      hasConditionsReadPermission,
      hasCovenantReadPermission,
      hasPeriodicalCheckReadPermission,
      isHeadOfBorrowerUnit,
      isHeadOfReviewUnit,
      navItems,
    ],
  )

  const renderContent = () =>
    needToKnow?.allowed && !isErrorOverall ? (
      <BusinessPartnerContext.Provider value={businessPartnerFull}>
        <NavigationL2 navItems={filteredNavItems} />
      </BusinessPartnerContext.Provider>
    ) : (
      <BusinessPartnerContext.Provider value={businessPartnerMini}>
        <BusinessPartnerWithoutNeed2Know businessPartner={businessPartnerMini} />
      </BusinessPartnerContext.Provider>
    )

  return (
    <RecentVisitWrapper entityId={businessPartnerId} entityType={cwpEntityTypes.BUSINESS_PARTNER}>
      <LoadingStateWrapper
        isError={(isErrorOverall && isErrorMini) || isNotFoundBP}
        isLoading={isLoading}
        renderContent={renderContent}
        renderCustomErrorScreen={() => (
          <CenteredIllustratedMessage
            name="UnableToLoad"
            size="Spot"
            titleText={t('title')}
            subtitleText={t('description')}
          />
        )}
      />
    </RecentVisitWrapper>
  )
}

BusinessPartnerWrapper.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({ path: PropTypes.string, translationToken: PropTypes.string }),
  ).isRequired,
}

export default BusinessPartnerWrapper
