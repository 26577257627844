import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const TrancheDeleteButton = ({ onShowDeleteTrancheDialog }) => {
  const { t } = useTranslation()

  const handleOnClick = () => {
    onShowDeleteTrancheDialog()
  }

  return (
    <>
      <Button
        id="deleteTrancheButton"
        design={ButtonDesign.Transparent}
        onClick={handleOnClick}
        data-testid={'delete-tranche-button'}
      >
        {t('pages.deals.tranche-delete.delete-button')}
      </Button>
    </>
  )
}
TrancheDeleteButton.propTypes = {
  onShowDeleteTrancheDialog: PropTypes.func.isRequired,
}

export default TrancheDeleteButton
