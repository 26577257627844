import { FilterBar, Icon, Input } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/conditions/condition-selection/ConditionSelectionFilterBar.module.css'

const isEnterButtonEvent = (event) => event.code === 'Enter' || event.code === 'NumpadEnter'

const ConditionSelectionFilterBar = ({ onGo, searchFilter: initialSearchFilter }) => {
  const [filterParams, setFilterParams] = useState(initialSearchFilter)
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.condition-selection.filterbar',
  })

  const onKeyDown = useCallback(
    (event) => {
      if (event.code === 'Space') {
        event.stopPropagation()
        return
      }
      if (isEnterButtonEvent(event)) {
        onGo(filterParams)
      }
    },
    [onGo, filterParams],
  )

  const handleSearchFilterChange = useCallback(({ target: { value: newSearchFilter } }) => {
    setFilterParams(newSearchFilter)
  }, [])

  const onGoInternal = useCallback(() => {
    onGo(filterParams)
  }, [onGo, filterParams])

  const onInputFocus = useCallback((event) => {
    event.stopPropagation()
  }, [])

  return (
    <FilterBar
      filterBarCollapsed
      showGoOnFB
      hideToggleFiltersButton
      hideFilterConfiguration
      onGo={onGoInternal}
      className={styles.filterbar}
      search={
        <Input
          placeholder={t('search')}
          value={filterParams}
          onFocus={onInputFocus}
          onInput={handleSearchFilterChange}
          onKeyDown={onKeyDown}
          icon={<Icon name="search" />}
          showClearIcon
        />
      }
      activeFiltersCount={0}
    />
  )
}

ConditionSelectionFilterBar.propTypes = {
  onGo: PropTypes.func.isRequired,
  searchFilter: PropTypes.string,
}

export default ConditionSelectionFilterBar
