import { DateTime } from 'luxon'
import {
  getShortDatePattern,
  useFormatterCache,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/shared/hooks/useI18n'

const useMMyyyy = () =>
  useFormatterCache({ year: 'numeric', month: '2-digit' }, (locales, options) => {
    const formatter = Intl.DateTimeFormat(locales, options)
    const datePattern = getShortDatePattern(formatter)

    /** @param {string} value */
    const parse = (value) => DateTime.fromFormat(value, datePattern).toISODate()

    /** @param {string?} [isoString] */
    const format = (isoString) => {
      if (!isoString) return ''
      const parsedDate = DateTime.fromISO(isoString)

      if (!parsedDate.isValid) return ''

      return formatter.format(parsedDate.toJSDate())
    }

    return { parse, format, pattern: datePattern }
  })

export default useMMyyyy
