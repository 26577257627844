import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CovenantCell,
  CovenantCheckCell,
  MonitoringItemCell,
  MonitoringItemsBreachCell,
  TargetDeliveryDateCell,
  MonitoringItemsStatusCell,
  SoftLimitCell,
  HardLimitCell,
  ResultCell,
} from 'components/domains/deals/covenants/monitoring-items-card-deal/MonitoringItemsTableCells'
import { useBreachTranslation } from 'components/domains/deals/covenants/shared/useBreachTranslations'
import useStatusTranslations from 'components/domains/deals/covenants/shared/useStatusTranslations'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'

const useMonitoringItemsForDealColumnDefinitions = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.results.monitoring-items-card',
  })
  const { breachEnum } = useBreachTranslation()
  const { statusEnum } = useStatusTranslations()
  const defaultDisables = useMemo(
    () => ({
      disableDragAndDrop: true,
      disableGlobalFilter: true,
      disableGroupBy: true,
    }),
    [],
  )

  return useMemo(
    () => [
      {
        Header: t('table.columns.monitoring-item'),
        sortByLabel: t('table.columns.monitoring-item'),
        groupByLabel: t('table.columns.monitoring-item'),
        id: 'monitoring-item',
        accessor: 'monitoringItem.name',
        filterType: filterTypes.CONTAINS,
        Cell: MonitoringItemCell,
        minWidth: 180,
        dialogOrder: 1,
        ...defaultDisables,
      },
      {
        Header: t('table.columns.covenant-check'),
        sortByLabel: t('table.columns.covenant-check'),
        groupByLabel: t('table.columns.covenant-check'),
        id: 'covenant-check',
        accessor: 'covenantCheck.nameWithId',
        filterType: filterTypes.CONTAINS,
        Cell: CovenantCheckCell,
        minWidth: 180,
        dialogOrder: 2,
        ...defaultDisables,
        disableGroupBy: false,
      },
      {
        Header: t('table.columns.status'),
        sortByLabel: t('table.columns.status'),
        groupByLabel: t('table.columns.status'),
        id: 'status',
        accessor: 'status.value',
        Cell: MonitoringItemsStatusCell,
        filterType: filterTypes.MULTISELECT_ENUM,
        additionalFilterOptions: {
          enumValues: statusEnum,
          allowCustomValues: false,
        },
        width: 100,
        dialogOrder: 3,
        ...defaultDisables,
        disableGroupBy: false,
      },
      {
        Header: t('table.columns.covenant'),
        sortByLabel: t('table.columns.covenant'),
        groupByLabel: t('table.columns.covenant'),
        id: 'covenant',
        accessor: 'covenant.nameWithId',
        filterType: filterTypes.CONTAINS,
        Cell: CovenantCell,
        minWidth: 180,
        dialogOrder: 4,
        ...defaultDisables,
        disableGroupBy: false,
      },
      {
        Header: t('table.columns.target-delivery-date'),
        sortByLabel: t('table.columns.target-delivery-date'),
        groupByLabel: t('table.columns.target-delivery-date'),
        id: 'target-delivery-date',
        accessor: 'targetDeliveryDate',
        filterType: filterTypes.BETWEEN_DATES,
        hAlign: 'right',
        Cell: TargetDeliveryDateCell,
        width: 150,
        dialogOrder: 5,
        ...defaultDisables,
        disableGroupBy: false,
      },
      {
        Header: t('table.columns.soft-limit'),
        sortByLabel: t('table.columns.soft-limit'),
        groupByLabel: t('table.columns.soft-limit'),
        id: 'soft-limit',
        accessor: 'softLimit.value',
        filterType: filterTypes.BETWEEN_NUMBERS,
        hAlign: 'right',
        Cell: SoftLimitCell,
        width: 100,
        dialogOrder: 6,
        ...defaultDisables,
      },
      {
        Header: t('table.columns.hard-limit'),
        sortByLabel: t('table.columns.hard-limit'),
        groupByLabel: t('table.columns.hard-limit'),
        id: 'hard-limit',
        accessor: 'hardLimit.value',
        filterType: filterTypes.BETWEEN_NUMBERS,
        hAlign: 'right',
        Cell: HardLimitCell,
        width: 100,
        dialogOrder: 7,
        ...defaultDisables,
      },
      {
        Header: t('table.columns.result'),
        sortByLabel: t('table.columns.result'),
        groupByLabel: t('table.columns.result'),
        id: 'result',
        accessor: 'result.value',
        filterType: filterTypes.BETWEEN_NUMBERS,
        hAlign: 'right',
        Cell: ResultCell,
        width: 100,
        dialogOrder: 8,
        ...defaultDisables,
      },
      {
        Header: t('table.columns.breach'),
        sortByLabel: t('table.columns.breach'),
        groupByLabel: t('table.columns.breach'),
        id: 'breach',
        accessor: 'breach.value',
        Cell: MonitoringItemsBreachCell,
        filterType: filterTypes.MULTISELECT_ENUM,
        additionalFilterOptions: {
          enumValues: breachEnum,
          allowCustomValues: false,
        },
        width: 100,
        dialogOrder: 9,
        ...defaultDisables,
        disableGroupBy: false,
      },
    ],
    [t, defaultDisables, statusEnum, breachEnum],
  )
}

export default useMonitoringItemsForDealColumnDefinitions
