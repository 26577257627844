import { Card, CardHeader, ObjectStatus, ValueState } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import styles from 'components/domains/properties/general-information/status/PropertyStatusCard.module.css'
import { PropertyContext } from 'routes/properties/PropertyContext'
import { usePropertyStatus } from 'routes/properties/usePropertyStatus'

const EMPTY_STRING = ''

const PropertyStatusCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.property-status-card',
  })
  const { t: tNoPrefix } = useTranslation()
  const { objectStatusForFinancingStatus, objectStatusForCAGStatus } = usePropertyStatus()
  const {
    property: {
      financing_status_code: financingStatusCode,
      cag_status_code: cagStatusCode,
      system_status: systemStatus = [],
      user_status: userStatus = [],
      used_status_indicator: cwpUsedFlag,
    },
  } = useContext(PropertyContext)

  const cwpUsedStatus = !isNil(cwpUsedFlag)
    ? {
        valueState: cwpUsedFlag ? ValueState.Success : ValueState.Error,
        text: cwpUsedFlag ? 'formatters.boolean.yes' : 'formatters.boolean.no',
      }
    : null

  const renderStatusValue = (value) =>
    value ? (
      <ObjectStatus
        inverted
        state={value.valueState ?? ValueState.Information}
        className={styles.objectStatus}
      >
        {value.text ? tNoPrefix(value.text) : value}
      </ObjectStatus>
    ) : (
      EMPTY_STRING
    )

  return (
    <Card header={<CardHeader titleText={t('title')} />}>
      <CardSection>
        <CardShowItem
          label={t('label.financing-status')}
          value={renderStatusValue(objectStatusForFinancingStatus[financingStatusCode])}
        />
        <CardShowItem
          label={t('label.cag-status')}
          value={renderStatusValue(objectStatusForCAGStatus[cagStatusCode])}
        />
        <CardShowItem label={t('label.system-status')} value={renderStatusValue(systemStatus[0])} />
        <CardShowItem label={t('label.user-status')} value={renderStatusValue(userStatus[0])} />
        <CardShowItem label={t('label.cwp-used-status')} value={renderStatusValue(cwpUsedStatus)} />
      </CardSection>
    </Card>
  )
}

export default PropertyStatusCard
