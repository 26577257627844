import { FileUploader, Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

const FileUploaderButton = ({
  id,
  design = ButtonDesign.Default,
  text,
  onChange,
  acceptedFileTypes,
  disabled = false,
}) => (
  <FileUploader
    hideInput
    id={id}
    accept={acceptedFileTypes?.join(',')}
    onChange={onChange}
    disabled={disabled}
  >
    <Button design={design} disabled={disabled}>
      {text}
    </Button>
  </FileUploader>
)

FileUploaderButton.propTypes = {
  id: PropTypes.string,
  design: PropTypes.oneOf(Object.values(ButtonDesign)),
  text: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
}

export default FileUploaderButton
