import { z } from 'zod'

const rightsBaseSchema = z.object({
  id: z.string(),
  displayId: z.string(),
  name: z.string(),
  rightType: z.object({
    id: z.string(),
    name: z.string(),
  }),
  rightValue: z.object({
    value: z.number(),
    currency: z.string(),
  }),
  rightValueHq: z
    .object({
      value: z.number(),
      currency: z.string(),
    })
    .nullable(),
  lastValuationDate: z.coerce.date().nullish(),
  rankingOrder: z.number().int(),
  sequence: z.number().int(),
  bankKey: z.object({
    id: z.string(),
    name: z.string(),
  }),
  bankCountry: z.object({
    id: z.string(),
    name: z.string(),
  }),
  accountNumber: z.string().nullable(),
  businessPartners: z.array(
    z.object({
      id: z.string(),
      displayId: z.string(),
      function: z.object({ id: z.string(), name: z.string() }),
    }),
  ),
  systemStatus: z.object({ id: z.string(), name: z.string() }),
  allowedOperations: z.string().array().nullish(),
})

/** @typedef {import('zod').TypeOf<typeof rightsBaseSchema>} Right */

const rightsSchema = rightsBaseSchema.optional()

export default rightsSchema
