import { useRequest } from 'hooks/services/baseService'

/**
 * Returns all property typeCodesMinor keys and their localized display name
 * The typeCodesMinor structure is as follows:
 * {
 *   "typeCodesMinor": [
 *       {
 *           "key": "000002",
 *           "display_name": "Shop"
 *       },
 *       {
 *           "key": "000003",
 *           "display_name": "Lawyer's office"
 *       },
 *       [...]
 *   ]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: typeCodesMinor}}
 *
 */
export const useTypeCodesMinor = () =>
  useRequest({
    path: `/properties/type_codes_minor`,
    translated: true,
  })
