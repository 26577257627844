import { Bar, Button, ButtonDesign, Dialog, Label } from '@fioneer/ui5-webcomponents-react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'components/domains/conditions/dialogs/ExternalConditionStandardListItemDialog.module.css'
import DialogContent from 'components/domains/properties/monitoring/settings/dialog/DialogContent'
import DialogGroup from 'components/domains/properties/monitoring/settings/dialog/DialogGroup'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import useExternalConditionTemplates from 'hooks/services/conditions/config/useExternalConditionTemplates'
import {
  addConditions,
  closeStandardListItemDialog,
  openCreationDialog,
} from 'redux/slices/conditions/conditionCreationSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const ExternalConditionStandardListItemDialog = () => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.dialog.external-condition-standard-list',
  })

  const {
    entityRef: { entityType },
  } = useContext(ConditionsContext)

  const dispatch = useDispatch()
  const isStandardListItemSelectionDialogOpen = useSelector(
    (state) => state.conditions.conditionsCreation.isStandardListItemSelectionDialogOpen,
  )
  const [selectedTemplateCode, setSelectedTemplateCode] = useState('')
  const [hasError, setHasError] = useState(false)
  const { isLoading, isError, data } = useExternalConditionTemplates({ entityType })
  const [internalKey, setInternalKey] = useState(0)

  useEffect(() => {
    setInternalKey((key) => key + 1)
  }, [selectedTemplateCode])

  useEffect(() => {
    if (!isStandardListItemSelectionDialogOpen) {
      setHasError(false)
      setSelectedTemplateCode('')
    }
  }, [isStandardListItemSelectionDialogOpen])

  const loadingHook = useCallback(() => ({ isLoading, isError, data }), [isLoading, isError, data])

  const isSaveButtonDisabled = useMemo(() => !selectedTemplateCode, [selectedTemplateCode])

  const handleOnNextClicked = useCallback(() => {
    const { conditions } = data.conditionTemplates.find(({ code }) => code === selectedTemplateCode)
    dispatch(
      addConditions(
        conditions.map(
          ({
            info: { name, type, refNumber, description, category },
            requirements,
            references,
          }) => ({
            name,
            type: type?.code,
            refNumber,
            description,
            category,
            references,
            requirements: requirements.map((requirement) => ({
              ...requirement,
              info: {
                ...requirement.info,
                documentType: requirement.info?.documentType,
              },
            })),
          }),
        ),
      ),
    )
    dispatch(closeStandardListItemDialog())
    dispatch(openCreationDialog())
    setSelectedTemplateCode('')
  }, [dispatch, selectedTemplateCode, data])

  const handleOnCancelClicked = useCallback(() => {
    dispatch(closeStandardListItemDialog())
    setSelectedTemplateCode('')
  }, [dispatch])

  const footerButtons = useMemo(
    () => (
      <Bar
        endContent={
          <>
            <Button
              onClick={handleOnNextClicked}
              design={ButtonDesign.Emphasized}
              disabled={isSaveButtonDisabled}
            >
              {tNoPrefix('buttons.next')}
            </Button>
            <Button onClick={handleOnCancelClicked} design={ButtonDesign.Transparent}>
              {tNoPrefix('buttons.cancel')}
            </Button>
          </>
        }
      />
    ),
    [isSaveButtonDisabled, handleOnNextClicked, handleOnCancelClicked, tNoPrefix],
  )

  const onTemplateSelectionChanged = useCallback(
    (_event, selectedCode) => {
      setSelectedTemplateCode(selectedCode)
      if (selectedCode === '') {
        setHasError(true)
        return
      }
      setHasError(false)
    },
    [setHasError],
  )

  return createPortal(
    <Dialog
      open={isStandardListItemSelectionDialogOpen}
      onAfterClose={handleOnCancelClicked}
      headerText={t('title')}
      className={styles.dialog}
      footer={footerButtons}
      draggable
      resizable
    >
      <DialogContent>
        <DialogGroup>
          <div>
            <Label for="decision-paper-template-select" required>
              {t('label')}
            </Label>
            <LoadingSelect
              key={`condition-template-select-${internalKey}`}
              onChange={onTemplateSelectionChanged}
              loadingHook={loadingHook}
              selectionName="conditionTemplates"
              optionKeyName="code"
              optionDisplayName="name"
              selectedKey={selectedTemplateCode}
              isErrorState={hasError}
              valueStateMessage={<span>{t('error')}</span>}
            />
          </div>
        </DialogGroup>
      </DialogContent>
    </Dialog>,
    document.body,
  )
}

ExternalConditionStandardListItemDialog.propTypes = {}

export default ExternalConditionStandardListItemDialog
