import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useCreateImage = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()

  return useMutation(
    async ({ image }) => {
      const { data } = await post({
        path: `/properties/images`,
        body: image.data,
        isJson: false,
        headers: {
          'X-File-Name': image.fileName,
          'Content-Type': image.contentType,
        },
      })
      return data
    },
    { onSuccess, onError },
  )
}
