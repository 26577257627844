import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import DealSourcesAndUsesTiles from 'components/domains/business-events-and-tasks/decision-paper/tiles/deal-uses-and-sources/DealSourcesAndUsesTiles'

const DealUsesTile = ({ tileId }) => {
  const {
    data: { sources, uses, areAllCurrenciesTheSame },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  return (
    <DealSourcesAndUsesTiles
      data={uses}
      dataToFillRows={sources}
      areAllCurrenciesTheSame={areAllCurrenciesTheSame}
    />
  )
}

DealUsesTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default DealUsesTile
