import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { kpisAllowedOperations } from 'api/kpis/kpisAllowedOperations'
import ConfigurableComponentsPage from 'components/ui/configurable-components-page/ConfigurableComponentsPage'
import EntityTypeAndIdWithClipboard from 'components/ui/entity-info/EntityTypeAndIdWithClipboard'
import { availableDynamicPagesEnum } from 'hooks/page-config/useGetPageConfig'
import KpiPermissionWrapper from 'routes/kpis/KpiPermissionWrapper'
import { PropertyContext } from 'routes/properties/PropertyContext'
import PropertyPage from 'routes/properties/PropertyPage'

const PropertyKpiOverviewDynamic = ({
  propertyDescription,
  breadcrumbs,
  status,
  additionalStatuses,
}) => {
  const { property } = useContext(PropertyContext)

  const subtitle = <EntityTypeAndIdWithClipboard id={property.id} />

  return (
    <ConfigurableComponentsPage
      pageCode={availableDynamicPagesEnum.PropertiesKeyPerformanceIndicators}
      renderContent={({ children }) => (
        <PropertyPage
          pageTitle={propertyDescription}
          id={property.id}
          subtitle={subtitle}
          breadcrumbs={breadcrumbs}
          status={status}
          additionalStatuses={additionalStatuses}
        >
          <KpiPermissionWrapper requiredOperation={kpisAllowedOperations.readKpi}>
            {children}
          </KpiPermissionWrapper>
        </PropertyPage>
      )}
    />
  )
}

PropertyKpiOverviewDynamic.propTypes = {
  propertyDescription: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ),
  status: PropTypes.shape({
    text: PropTypes.string.isRequired,
    valueState: PropTypes.string,
  }),
  additionalStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string.isRequired,
      valueState: PropTypes.string,
    }),
  ),
}

export default PropertyKpiOverviewDynamic
