import { MultiComboBox, MultiComboBoxItem } from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { forwardRef, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getObjectStatusForTaskStatus, taskStatus as possibleTaskStatus } from 'api/events/status'

const mappedTaskStatus = [
  possibleTaskStatus.created,
  possibleTaskStatus.ongoing,
  possibleTaskStatus.completed,
  possibleTaskStatus.aborted,
]

const TaskStatusComboBox = forwardRef(
  ({ selected = [], onSelectionChange, onKeyDown, id }, _ref) => {
    const { t } = useTranslation()
    const mappedTaskStatusOptions = useMemo(
      () =>
        mappedTaskStatus.map((taskStatus, index) => (
          <MultiComboBoxItem
            key={`task-status-${taskStatus}-${index}`}
            data-key={taskStatus}
            text={t(getObjectStatusForTaskStatus(taskStatus).translationKey)}
            selected={selected.includes(taskStatus)}
          />
        )),
      [selected, t],
    )
    const onStatusSelectionChange = useCallback(
      (evt) => {
        const {
          detail: { items: selectedStatusOptions },
        } = evt
        const keys = selectedStatusOptions.map((type) => type.dataset.key)
        onSelectionChange?.(keys)
      },
      [onSelectionChange],
    )

    return (
      <MultiComboBox
        id={id}
        onSelectionChange={onStatusSelectionChange}
        onKeyDown={onKeyDown}
        ref={_ref}
      >
        {mappedTaskStatusOptions}
      </MultiComboBox>
    )
  },
)

TaskStatusComboBox.displayName = 'TaskStatusComboBox'

TaskStatusComboBox.propTypes = {
  selected: PropType.arrayOf(
    PropType.oneOf([
      possibleTaskStatus.created,
      possibleTaskStatus.ongoing,
      possibleTaskStatus.completed,
      possibleTaskStatus.aborted,
    ]),
  ).isRequired,
  onSelectionChange: PropType.func.isRequired,
  onKeyDown: PropType.func,
  id: PropType.string.isRequired,
}

export default TaskStatusComboBox
