import { useMutation, useQueryClient } from '@tanstack/react-query'
import camelize from 'camelize'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

/**
 * @typedef RatingCreate
 * @property {string} methodId
 * @property {string} validTo
 * @property {number|undefined} ratingGrade
 * @property {number|undefined} pdPercentage
 * @property {string|undefined} validFrom
 */

/**
 * @param {object} params
 * @param {number} params.businessPartnerId
 * @param {function|undefined} params.onSuccess
 * @param {function|undefined} params.onError
 */
const useCreateBusinessPartnerRating = ({ businessPartnerId, onError, onSuccess }) => {
  const { post } = useAccessTokenRequest()
  const queryClient = useQueryClient()

  /** @param {RatingCreate} param */
  const mutationFn = async ({ ratingGrade, pdPercentage, methodId, validFrom, validTo }) => {
    const response = await post({
      path: `/businesspartners/${businessPartnerId}/ratings`,
      body: {
        rating_class: ratingGrade,
        rating_procedure: methodId,
        rate: pdPercentage,
        valid_from: validFrom,
        valid_to: validTo,
        custom_attributes: {},
      },
    })
    return camelize(response.data)
  }

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(['businesspartners', businessPartnerId, 'ratings'])
      onSuccess?.()
    },
    onError,
  })
}

export default useCreateBusinessPartnerRating
