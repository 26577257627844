import * as UI5React from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

const propTypes = {
  onKeyDownCapture: PropTypes.func,
  children: PropTypes.node,
}

/**
 * A wrapping component for the UI5 TableCell, enabling default ctrl/cmd-a behavior when the cell contains inputs
 *
@param {PropTypes.InferProps<typeof propTypes>} props
 */
const TableCell = ({ children, onKeyDownCapture, ...props }) => (
  <UI5React.TableCell
    {...props}
    onKeyDownCapture={(event) => {
      event.stopPropagation()
      onKeyDownCapture?.(event)
    }}
  >
    {children}
  </UI5React.TableCell>
)

TableCell.propTypes = propTypes

export default TableCell
