import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useMultiPropertyViewById = (multiPropertyViewId, options = {}) => {
  const result = useRequest({
    path: `/properties/multi-property-view/${multiPropertyViewId}`,
    useCache: true,
    keys: ['multiPropertyView', 'id', multiPropertyViewId],
    options,
  })
  return useCamelizedResponse(result)
}
