import { Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

const PropertyValuationResultsMethodCell = ({ calculationMethodName }) => (
  <Label>{calculationMethodName}</Label>
)

PropertyValuationResultsMethodCell.propTypes = {
  calculationMethodName: PropTypes.string,
}

export default PropertyValuationResultsMethodCell
