import { ObjectStatus, ValueState } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import RichTextTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/RichTextTile'
import DealDocumentSelection from 'components/domains/deals/DealDocumentSelection'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import styles from 'components/domains/deals/covenants/general-information/CovenantInformationDisplay.module.css'
import LimitCell from 'components/domains/deals/covenants/shared/LimitCell'
import useReferenceEntityTypeTranslations from 'components/domains/deals/covenants/shared/useReferenceEntityTypeTranslations'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import ReferenceEntities from 'components/ui/data/ReferenceEntities'
import TextEllipsis from 'components/ui/text/TextEllipsis'
import { DealContext } from 'routes/deals/DealContext'

const descriptionMaxLength = 500

const propTypes = {
  covenant: PropTypes.shape({
    covenantDefinition: PropTypes.shape({ unit: PropTypes.string, type: PropTypes.string }),
    covenantUuid: PropTypes.string,
    description: PropTypes.string,
    currencyCode: PropTypes.string,
    status: PropTypes.string,
    referenceEntity: PropTypes.shape({
      ids: PropTypes.arrayOf(PropTypes.string),
      type: PropTypes.string,
    }),
    loanSizing: PropTypes.shape({ value: PropTypes.number }),
    comment: PropTypes.string,
    referenceClause: PropTypes.string,
  }),
}
const CovenantInformationDisplay = ({
  covenant: {
    covenantDefinition = {},
    covenantUuid,
    description,
    currencyCode,
    status,
    referenceEntity = {},
    loanSizing = {},
    comment,
    referenceClause,
  } = {},
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.covenants' })
  const { deal = {} } = useContext(DealContext)
  const isUnitPercent = covenantDefinition.unit === 'PERCENT'
  const isCovenantFinancial = covenantDefinition.type === 'FINANCIAL'
  const isStatusActive = status === 'ACTIVE'

  const { getTranslationForReferenceEntityType } = useReferenceEntityTypeTranslations()

  return (
    <>
      <CardSection>
        <CardShowItem label={t('covenant')} value={covenantDefinition.name} />
        <CardShowItem label={t('covenant-id')} value={covenantUuid} />
        <CardShowItem
          label={t('type')}
          value={isCovenantFinancial ? t('financial') : t('non-financial')}
        />
        <CardShowItem
          label={t('create.description')}
          value={
            <TextEllipsis
              className={styles.textAlignmentRight}
              content={description}
              maxShortenedContentDisplaySize={110}
              maxContentDisplaySize={descriptionMaxLength}
            />
          }
        />
        {isCovenantFinancial && (
          <CardShowItem
            label={t('create.unit')}
            value={isUnitPercent ? t('unit.percent') : currencyCode}
          />
        )}
        <CardShowItem
          id="status"
          label={t('status')}
          value={
            <ObjectStatus inverted state={isStatusActive ? ValueState.Success : ValueState.None}>
              <div className={styles.capitalize}>{status}</div>
            </ObjectStatus>
          }
        />
        <CardShowItem
          label={t('reference-entity-type')}
          value={getTranslationForReferenceEntityType(referenceEntity.type)}
        />
        <CardShowItem
          label={t('reference-entity')}
          value={
            <ReferenceEntities
              ids={referenceEntity.ids}
              type={referenceEntity.type}
              entityClassName={styles.entityAlignmentRight}
            />
          }
        />
        {isCovenantFinancial && loanSizing && (
          <CardShowItem
            label={t('loan-sizing')}
            value={
              loanSizing.value && (
                <LimitCell
                  unit={covenantDefinition.unit}
                  value={loanSizing.value}
                  currencyCode={currencyCode}
                />
              )
            }
          />
        )}
        <CardShowItem
          label={t('document')}
          value={
            <DealDocumentSelection
              dealId={deal.displayId}
              dealUuid={deal.dealUuid}
              dealName={deal.name}
              isReadOnly={true}
              filterForEntityRef={{
                type: DocumentTypes.Covenant,
                id: covenantUuid,
              }}
            />
          }
        />
        <CardShowItem label={t('reference-clause')} value={referenceClause} />
      </CardSection>
      <CardSection title={t('comment')}>
        <div className={styles.richTextWrapper}>
          <RichTextTile
            isEditMode={false}
            currentContent={comment ?? ''}
            isPdfView={true}
            isPdfViewCollapsible={true}
            onChange={() => {}}
          />
        </div>
      </CardSection>
    </>
  )
}

CovenantInformationDisplay.propTypes = propTypes
export default CovenantInformationDisplay
