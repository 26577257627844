import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const getReservationsKey = (dealUuid) => ['deals', dealUuid, 'credit-reservations']

const useReservations = (dealUuid, options = {}) => {
  const response = useRequest({
    path: `/deals/${dealUuid}/credit-reservations`,
    keys: getReservationsKey(dealUuid),
    options: { enabled: !!dealUuid, ...options },
  })

  return useCamelizedResponse(response)
}

export default useReservations
