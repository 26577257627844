import isNil from 'lodash.isnil'
import { useEffect } from 'react'

const KEYDOWN_PROPAGATION_BANLIST = ['Enter', 'Space']

/*
 * This is not pretty, but - like with all bad things in live - the root cause
 * is probably the AnalyticalTable
 *
 * We register two event listener, focusIn and focusOut and stop the propagation
 * of each, because the analytical table captures these events and has its own
 * "oppinions" of how every focus event or 'space' keypress should be handled.
 *
 * If we do not do this, the opened Dialog immediately loses focus and focus
 * capturing mode engages, but now with the analytical table as target.
 * This is slightly inconventient since the purpose of this dialog is to
 * have the user type text in a text editor which needs focus to work...
 */
const useAnalyticalTableDialogFocusFix = ({ dialogRef }) => {
  useEffect(() => {
    const dialog = dialogRef?.current
    if (isNil(dialog)) {
      return
    }
    const focusInHandler = (event) => event.stopPropagation()

    const keyDownHandler = (event) => {
      if (KEYDOWN_PROPAGATION_BANLIST.includes(event.code)) {
        event.stopPropagation()
        return
      }
    }
    dialog.addEventListener('focusin', focusInHandler)
    dialog.addEventListener('keydown', keyDownHandler)

    return () => {
      dialog.removeEventListener('focusin', focusInHandler)
      dialog.removeEventListener('keydown', keyDownHandler)
    }
  }, [dialogRef])
}

export default useAnalyticalTableDialogFocusFix
