import { CheckBox } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { selectTask, deselectTask } from 'redux/slices/tasks/taskTableSlice'

const TasksTableCheckbox = ({ taskId, status, isMandatory, eventStatus }) => {
  const dispatch = useDispatch()

  const selected = !isNil(
    useSelector((state) => state.tasks.taskTable.selectedRows.selectedTaskIds[taskId]),
  )

  return (
    <CheckBox
      checked={selected}
      onChange={({ target: { checked } }) =>
        checked
          ? dispatch(
              selectTask({ id: taskId, status, info: { isMandatory }, eventStatus: eventStatus }),
            )
          : dispatch(deselectTask(taskId))
      }
    />
  )
}

TasksTableCheckbox.propTypes = {
  taskId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isMandatory: PropTypes.bool.isRequired,
  eventStatus: PropTypes.string.isRequired,
}

export default TasksTableCheckbox
