import { Button, ButtonDesign, MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useCloseCovenantCheck from 'components/domains/deals/covenant-check-detail/close-covenant-check/useCloseCovenantCheck'
import LoadingButton from 'components/ui/button/LoadingButton'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import MessageBox from 'components/ui/message-box/MessageBox'
import useCovenantCheckActions from 'hooks/services/deals/covenants/useCovenantCheckActions'
import useCovenantCheckById from 'hooks/services/deals/covenants/useCovenantCheckById'

const CLOSED_STATUS = 'CLOSED'

const CloseCovenantCheckButton = ({
  buttonText,
  covenantCheckUuid,
  confirmationText,
  successText,
}) => {
  const { t } = useTranslation()

  const [isConfirmMessageBoxOpen, setIsConfirmMessageBoxOpen] = useState(false)

  const { isLoading: isUpdating, performClosing } = useCloseCovenantCheck({
    covenantCheckUuid,
    successMessage: successText,
  })

  const {
    isFetching: isFetchingCovenantCheck,
    isError: isErrorCovenantCheck,
    data: { status } = {},
  } = useCovenantCheckById(covenantCheckUuid)

  const {
    isFetching: isFetchingCovenantCheckActions,
    isError: isErrorCovenantCheckActions,
    data: { isClosable } = {},
  } = useCovenantCheckActions({ covenantCheckUuid })

  const isFetching = isFetchingCovenantCheckActions || isFetchingCovenantCheck
  const isError = isErrorCovenantCheckActions || isErrorCovenantCheck

  const confirmClosing = () => performClosing()

  return (
    <SmallLoadingWrapper
      isLoading={isFetching}
      isError={isError}
      error={<ErrorDataUnavailableInCell />}
      renderContent={() => {
        if (!isClosable && status === CLOSED_STATUS) return null
        return (
          <>
            <LoadingButton
              isLoading={isUpdating}
              design={ButtonDesign.Transparent}
              disabled={!isClosable}
              onClick={() => setIsConfirmMessageBoxOpen(true)}
              renderContent={() => buttonText}
            />
            <MessageBox
              open={isConfirmMessageBoxOpen}
              type={MessageBoxTypes.Confirm}
              onClose={() => setIsConfirmMessageBoxOpen(false)}
              actions={[
                <Button
                  id="confirm-button"
                  key="button-confirm"
                  design={ButtonDesign.Emphasized}
                  onClick={confirmClosing}
                >
                  {t('buttons.confirm')}
                </Button>,
                <Button
                  key="cancel"
                  design={ButtonDesign.Transparent}
                  onClick={() => setIsConfirmMessageBoxOpen(false)}
                >
                  {t('buttons.cancel')}
                </Button>,
              ]}
            >
              {confirmationText}
            </MessageBox>
          </>
        )
      }}
    />
  )
}

CloseCovenantCheckButton.propTypes = {
  covenantCheckUuid: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  confirmationText: PropTypes.string.isRequired,
  successText: PropTypes.string.isRequired,
}

export default CloseCovenantCheckButton
