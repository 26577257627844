import { SuggestionItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { forwardRef, useCallback, useState } from 'react'
import InputWithSearchSuggestions from 'components/ui/input/InputWithSearchSuggestions'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'
import useStaffMembers from 'hooks/services/business-partners/staff-members/useStaffMembers'

const AssigneeFilter = forwardRef(
  ({ onAssigneeChange, assigneeId, displayPlaceholderText }, _ref) => {
    const { data: assigneeUser } = useStaffMemberById(assigneeId)
    const [userSearchKey, setUserSearchKey] = useState('')
    const { data: suggestions, isError: isSuggestionsError } = useStaffMembers({
      name: userSearchKey,
      minLength: 1,
    })

    const handleOnAssigneeChange = useCallback(
      (event) => {
        const newAssignee = event.detail.item.getAttribute('data-user-id')
        onAssigneeChange(newAssignee)
      },
      [onAssigneeChange],
    )

    const handleOnClear = useCallback(() => {
      onAssigneeChange('')
    }, [onAssigneeChange])

    const renderSuggestions = useCallback(() => {
      const suggestionsItems = []
      suggestions?.staffMembers.forEach((user, index) => {
        suggestionsItems.push(
          <SuggestionItem key={index} text={user.fullName} data-user-id={user.id} />,
        )
      })
      return suggestionsItems
    }, [suggestions])

    return (
      <InputWithSearchSuggestions
        initialValue={assigneeUser?.fullName ?? ''}
        renderSuggestions={renderSuggestions}
        handleOnChange={handleOnAssigneeChange}
        handleOnClear={handleOnClear}
        setSearchKey={setUserSearchKey}
        isSuggestionsError={isSuggestionsError}
        displayPlaceholderText={displayPlaceholderText}
      />
    )
  },
)

AssigneeFilter.displayName = 'EventAssigneeFilter'

AssigneeFilter.propTypes = {
  onAssigneeChange: PropTypes.func.isRequired,
  assigneeId: PropTypes.string,
  displayPlaceholderText: PropTypes.bool,
}

export default AssigneeFilter
