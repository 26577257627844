import { Label, Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-stage/voters-table/table-cells/VotersTableNameCell.module.css'
import StaffMemberAutocompleteInput from 'components/ui/input/StaffMemberAutocompleteInput'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'

const VotersTableNameCell = ({ userId, isEditMode, onNameSelected }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.voters-table.cells.approver',
  })
  const { data: votingUser, isError, isLoading } = useStaffMemberById(userId)
  const [hasError, setHasError] = useState(false)

  const renderContent = useCallback(
    () => <Label>{votingUser.fullName ?? '-'}</Label>,
    [votingUser?.fullName],
  )

  const errorMessage = <Label className={styles.errorLabel}>{t('loading.error.description')}</Label>

  const onBlur = useCallback(() => {
    setHasError(!userId)
  }, [userId])

  const onStaffMemberSelect = useCallback(
    ({ id = null } = {}) => {
      if (id) {
        setHasError(false)
        onNameSelected(id)
        return
      }
      onNameSelected('')
    },
    [onNameSelected],
  )

  const onSuggestionItemPreview = useCallback(() => {
    onNameSelected('')
  }, [onNameSelected])

  const onInput = useCallback(
    (event) => {
      event.target.value === '' && onNameSelected('')
    },
    [onNameSelected],
  )

  if (isEditMode) {
    return (
      <StaffMemberAutocompleteInput
        onStaffMemberSelect={onStaffMemberSelect}
        staffMemberName={votingUser?.fullName}
        searchMinLength={1}
        suggestionsLimit={null}
        showClearIcon
        onSuggestionItemPreview={onSuggestionItemPreview}
        onInput={onInput}
        placeholder={t('placeholder')}
        onBlur={onBlur}
        valueState={hasError ? ValueState.Error : ValueState.None}
        valueStateMessage={hasError ? <Text wrapping>{t('value-state.error')}</Text> : undefined}
      />
    )
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderContent}
      error={errorMessage}
    />
  )
}

VotersTableNameCell.propTypes = {
  userId: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  onNameSelected: PropTypes.func.isRequired,
}

export default VotersTableNameCell
