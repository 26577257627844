import { ObjectPageSection } from '@fioneer/ui5-webcomponents-react/dist'
import '@ui5/webcomponents-fiori/dist/illustrations/NoData.js'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import OpenInFssButton from 'components/domains/business-partners/OpenInFssButton'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import TabPage from 'components/ui/page/TabPage'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useFiscalYears } from 'hooks/services/business-partners/kpis/useFiscalYears'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import FiscalInformationCard from 'routes/business-partners/FiscalInformationCard'
import { stringTemplate } from 'utils/stringTemplate'

const minimumImportanceLevels = { detail: 1, overview: 5 }

const BusinessPartnerFiscalInformationPage = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.business-partner.fiscal-information',
  })

  const { linkToBpInFiscalInformationSystemTemplate, id: businessPartnerId } =
    useContext(BusinessPartnerContext)

  const {
    data: {
      fiscal_figure_groups: fiscalFigureGroups,
      fiscal_figure_types: fiscalFigureTypes,
      fiscal_years: fiscalYears,
    } = {},
    isLoading,
    isError,
  } = useFiscalYears(businessPartnerId)

  const linkToFss = useMemo(
    () => stringTemplate(linkToBpInFiscalInformationSystemTemplate, { businessPartnerId }),
    [linkToBpInFiscalInformationSystemTemplate, businessPartnerId],
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={businessPartnerId}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [businessPartnerId],
  )

  return (
    <TabPage
      headerTitle={
        <BusinessPartnerHeader
          breadcrumbs={[{ text: t('fiscal-information', { keyPrefix: 'pages.business-partner' }) }]}
          actions={[
            <CreateBusinessPartnerEventAction key="create-event-action" />,
            <OpenInFssButton to={linkToFss} key="open-in-fss" />,
            markFavoriteAction,
          ]}
        />
      }
    >
      <ObjectPageSection id="overview" titleText={t('overview')}>
        <FiscalInformationCard
          fiscalFigureGroups={fiscalFigureGroups}
          fiscalFigureTypes={fiscalFigureTypes}
          fiscalYears={fiscalYears}
          minimumImportanceLevel={minimumImportanceLevels.overview}
          isError={isError}
          isLoading={isLoading}
        />
      </ObjectPageSection>
      <ObjectPageSection id="details" titleText={t('details')}>
        <FiscalInformationCard
          fiscalFigureGroups={fiscalFigureGroups}
          fiscalFigureTypes={fiscalFigureTypes}
          fiscalYears={fiscalYears}
          minimumImportanceLevel={minimumImportanceLevels.detail}
          isError={isError}
          isLoading={isLoading}
        />
      </ObjectPageSection>
    </TabPage>
  )
}

export default BusinessPartnerFiscalInformationPage
