import { useQuery } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import snakeCase from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import useBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationships'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useGroupExposureById = (id) => {
  const { data: businessPartnerRelationships = {} } = useBusinessPartnerRelationships(id)
  const hasRelationship =
    !isEmpty(businessPartnerRelationships.unitRelationships) ||
    !isEmpty(businessPartnerRelationships.otherRelationships)

  const { post } = useAccessTokenRequest()

  const response = useQuery({
    queryKey: ['businesspartners', id, 'unit-exposures'],
    queryFn: async () => {
      const { data } = await post({
        path: `/risk-figures/${id}/unit-exposures`,
        body: snakeCase(businessPartnerRelationships),
      })

      return data
    },
    enabled: !!id && hasRelationship,
  })

  return useCamelizedResponse(response)
}

export default useGroupExposureById
