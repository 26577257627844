import camelize from 'camelize'
import isEmpty from 'lodash.isempty'
import { useMemo } from 'react'
import { useRequests } from 'hooks/services/baseService'
import { useRequestsMemo } from 'hooks/services/queryHelper'

const useMultipleDealsByBorrowerId = ({
  borrowerIds,
  filter: { dealRankFrom, dealRankTo } = {},
  options = {},
}) => {
  const queryParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams()

    if (dealRankFrom) {
      urlSearchParams.append('deal_rank_from', dealRankFrom)
    }
    if (dealRankTo) {
      urlSearchParams.append('deal_rank_to', dealRankTo)
    }

    return urlSearchParams
  }, [dealRankFrom, dealRankTo])

  // HINT: `queryParams.size` seems to return `undefined` even if there are params
  const additionalParamsString = [...queryParams.keys()].length ? `&${queryParams.toString()}` : ''

  const responses = useRequestsMemo(
    useRequests({
      requests: borrowerIds.map((borrowerId) => ({
        path: `/deals?borrower_bp_id=${borrowerId}${additionalParamsString}`,
        keys: ['deals', borrowerId],
      })),
      translated: true,
      useCache: true,
      options: { ...options, enabled: !isEmpty(borrowerIds) },
    }),
  )

  return useMemo(
    () => ({
      isLoading: responses?.some(({ isLoading }) => isLoading),
      isError: responses?.some(({ isError }) => isError),
      isFetching: responses?.some(({ isFetching }) => isFetching),
      data: responses.map((response, index) => ({
        ...response,
        data: {
          businessPartnerId: borrowerIds[index],
          ...camelize(response.data),
        },
      })),
    }),
    [borrowerIds, responses],
  )
}

export default useMultipleDealsByBorrowerId
