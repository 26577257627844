// styling to correctly arrange content of display table cells
export const DEFAULT_DISPLAY_ROW_HEIGHT = 30
export const DEFAULT_DISPLAY_COUNTERPARTY_ROW_HEIGHT = 46

export const DEFAULT_DISPLAY_TEXT_HEIGHT = 16
export const DEFAULT_DISPLAY_HEIGHT_WITHOUT_TEXT =
  DEFAULT_DISPLAY_ROW_HEIGHT - DEFAULT_DISPLAY_TEXT_HEIGHT
export const DEFAULT_DISPLAY_TEXT_GAP = 8

// styling to correctly arrange content of edit table cells
export const DEFAULT_EDIT_ROW_HEIGHT = 40
export const DEFAULT_EDIT_INPUT_HEIGHT = 26
export const DEFAULT_EDIT_HEIGHT_WITHOUT_INPUT = DEFAULT_EDIT_ROW_HEIGHT - DEFAULT_EDIT_INPUT_HEIGHT
export const DEFAULT_EDIT_INPUT_GAP = 8

// rowTypes to facilitate styling of table cells
export const ROW_TYPE_COUNTERPARTY = 'counterparty'
export const ROW_TYPE_ROLE = 'role'
export const ROW_TYPE_OWN_SHARE = 'ownShare'
export const ROW_TYPE_TRANCHE = 'tranche'

// row ids to identify rows
export const NEW_ROOT_ROW_ID_PREFIX = 'new-row-'
export const NEW_ROLE_ROW_ID_PREFIX = 'new-role-'
export const EXISTING_ROLE_ROW_ID_PREFIX = 'existing-role-'
export const ROOT_ROW_ID_OWN_SHARE = 'ownShareRow'
export const ROOT_ROW_ID_TOTAL = 'totalRow'

// identify customerMargin skim / fee
export const CUSTOMER_MARGIN = 'customerMargin'

// subsection type codes to identify subsection headers
export const SUBSECTION_TYPE_CODE_TOTAL = 'total'
export const SUBSECTION_TYPE_CODE_OWN_SHARE = 'ownShare'

export const STATUS_TYPE_CODE_SIGNED = 40

// column widths of button cell based on their state
export const DEFAULT_BUTTONS_CELL_WIDTH = 80
export const EDIT_BUTTONS_CELL_WIDTH = 176
export const EDIT_BUTTONS_CELL_WIDTH_GERMAN = 236

export const SYNDICATION_TYPE_BUTTONS_CELL_WIDTH = 220

export const EUR_CURRENCY = 'EUR'
