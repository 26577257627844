import { useMutation, useQueryClient } from '@tanstack/react-query'
import camelize from 'camelize'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useTextualAssessmentsUpdate = ({ businessPartnerId, type, onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()
  const queryClient = useQueryClient()

  const innerOnSuccess = (data) => {
    queryClient.invalidateQueries([
      'businesspartners',
      businessPartnerId,
      'textual-assessments',
      type,
    ])
    onSuccess?.(camelize(data))
  }

  return useMutation({
    mutationFn: async ({ content }) => {
      const { data } = await put({
        path: `/businesspartners/${businessPartnerId}/textual-assessments/${type}`,
        body: { content },
      })
      return data
    },
    onSuccess: innerOnSuccess,
    onError,
  })
}

export default useTextualAssessmentsUpdate
