import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUserRecentVisitsCreate = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async (userRecentVisit) => {
      await post({
        path: `/user-profile/recent-visit`,
        body: {
          entity_id: userRecentVisit.entityId,
          entity_type: userRecentVisit.entityType,
        },
      })
    },
    { onSuccess, onError },
  )
}

export default useUserRecentVisitsCreate
