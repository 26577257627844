import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ReferenceEntitiesView, { entitiesQuantity } from 'components/ui/data/ReferenceEntitiesView'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import paths from 'routes/paths'

const CovenantCheckSelectionHelperDealsColumn = ({ covenantDeals, isLoading, isError }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.covenant-check-selection.table',
  })
  const dealsReferenceEntitiesProps = useMemo(() => {
    if (isNil(covenantDeals)) {
      return null
    }
    if (covenantDeals.length === 1) {
      return {
        quantity: entitiesQuantity.single,
        id: covenantDeals[0].dealId,
        link: `/${paths.deals}/${covenantDeals[0].dealId}`,
        name: covenantDeals[0].name,
      }
    }
    return {
      quantity: entitiesQuantity.multiple,
      listItems: covenantDeals.map((deal) => ({
        id: deal.dealId,
        name: deal.name,
      })),
      linkBasePath: `/${paths.deals}`,
      linkText: t('link.deals'),
    }
  }, [covenantDeals, t])
  const renderContent = useCallback(
    () => <ReferenceEntitiesView {...dealsReferenceEntitiesProps} />,
    [dealsReferenceEntitiesProps],
  )
  if (covenantDeals?.length === 0) {
    return
  }
  return (
    <RequestStateResolver
      renderContent={renderContent}
      isLoading={isLoading}
      isError={isError}
      errorToDisplay={<ErrorDataUnavailableInCell />}
    />
  )
}

CovenantCheckSelectionHelperDealsColumn.propTypes = {
  covenantDeals: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      dealId: PropTypes.string.isRequired,
    }),
  ),
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
}

export default CovenantCheckSelectionHelperDealsColumn
