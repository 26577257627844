import {
  FlexBox,
  FlexBoxDirection,
  Label,
  MessageStripDesign,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/property-selection/AnnualReviewDealOverviewPropertySelect.module.css'
import CloseableMessageStrip from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/feedback/CloseableMessageStrip'
import LoadingSelect from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/deal-specifics/shared/ui/select/LoadingSelect'
import { setTileMetadata } from 'redux/slices/decision-paper/tilesOverviewSlice'

const AnnualReviewDealOverviewPropertySelect = ({
  propertiesData,
  propertyMetadataTileCode,
  isPdfView = false,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('decisionPaper')

  const {
    selectedPropertyUuid: propertyUuid,
    selectedPropertyName: propertyName,
    selectedPropertyDisplayId: propertyId,
  } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tileMetadata?.[propertyMetadataTileCode],
  ) ?? {}

  const propertySelectData = useMemo(
    () =>
      propertiesData?.map((property) => ({
        key: property?.uuid,
        name: `${property?.description} (${property?.id})`,
      })) ?? [],
    [propertiesData],
  )

  const handleOnChange = useCallback(
    (_event, selectedPropertyUuid) => {
      const { description: selectedPropertyName, id: selectedPropertyDisplayId } =
        propertiesData.find((property) => property?.uuid === selectedPropertyUuid) ?? {}

      dispatch(
        setTileMetadata({
          tileCode: propertyMetadataTileCode,
          metadata: { selectedPropertyUuid, selectedPropertyName, selectedPropertyDisplayId },
        }),
      )
    },
    [propertiesData, dispatch, propertyMetadataTileCode],
  )

  const renderPropertySelect = useMemo(() => {
    if (isEmpty(propertiesData)) {
      return (
        <CloseableMessageStrip design={MessageStripDesign.Information} hideCloseButton={true}>
          {t('tiles.annual-review.deal-overview.property-select.no-properties')}
        </CloseableMessageStrip>
      )
    } else if (isPdfView) {
      return <Text>{`${propertyName} (${propertyId})`}</Text>
    }
    return (
      <LoadingSelect
        id="annualReviewPropertySelection-loadingSelect"
        className={styles.annualReviewPropertySelect}
        loadingHook={() => ({
          isLoading: false,
          isError: false,
          data: propertySelectData,
        })}
        selectedKey={propertyUuid}
        showEmptyOption={isEmpty(propertySelectData)}
        optionDisplayName="name"
        optionKeyName="key"
        onChange={handleOnChange}
      />
    )
  }, [
    handleOnChange,
    isPdfView,
    propertiesData,
    propertyId,
    propertyName,
    propertySelectData,
    propertyUuid,
    t,
  ])

  return useMemo(
    () => (
      <FlexBox direction={FlexBoxDirection.Column} className={styles.annualReviewSelectionWrapper}>
        <Label showColon>{t('app.entity.type.name.property')}</Label>
        {renderPropertySelect}
      </FlexBox>
    ),
    [renderPropertySelect, t],
  )
}

AnnualReviewDealOverviewPropertySelect.propTypes = {
  propertiesData: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  propertyMetadataTileCode: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool,
}

export default AnnualReviewDealOverviewPropertySelect
