import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useCurrencyHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/deal-uses-and-sources/helper/useCurrencyHelper'
import useEquityCalculation from 'hooks/services/business-events-and-tasks/decision-papers/tiles/deal-uses-and-sources/helper/useEquityCalculation'
import useExtractedHeadquarterCurrency from 'hooks/services/business-events-and-tasks/decision-papers/tiles/deal-uses-and-sources/helper/useExtractedHeadquarterCurrency'
import useMappedSources from 'hooks/services/business-events-and-tasks/decision-papers/tiles/deal-uses-and-sources/helper/useMappedSources'
import useMappedUses from 'hooks/services/business-events-and-tasks/decision-papers/tiles/deal-uses-and-sources/helper/useMappedUses'
import useOtherFinancingSourcesCalculation from 'hooks/services/business-events-and-tasks/decision-papers/tiles/deal-uses-and-sources/helper/useOtherFinancingSourcesCalculation'
import usePbbCalculation from 'hooks/services/business-events-and-tasks/decision-papers/tiles/deal-uses-and-sources/helper/usePbbCalculation'
import useSyndicationPartnerCalculation from 'hooks/services/business-events-and-tasks/decision-papers/tiles/deal-uses-and-sources/helper/useSyndicationPartnerCalculation'
import useUsesCalculation from 'hooks/services/business-events-and-tasks/decision-papers/tiles/deal-uses-and-sources/helper/useUsesCalculation'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useDeal from 'hooks/services/deals/useDeal'

const useDealSourcesAndUses = ({ entityRef: { entityId: dealUuid } }, tileId, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode, isWorkingVersionDataTile } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
    errors: dealUuidErrors,
  } = useDealUuidByTileCode({ dealUuid, tileCode, isWorkingVersionInfoInSourcePath: true })

  const {
    data: dealData,
    isFetching: isDealFetching,
    isError: isDealError,
    error: dealError,
  } = useDeal(dealUuidByTileCode)

  const {
    isHeadquarterCurrencyFetching,
    isHeadquarterCurrencyError,
    headquarterCurrency,
    headquarterCurrencyError,
  } = useExtractedHeadquarterCurrency()

  const {
    isDealUsesFetching,
    isDealUsesError,
    dealUsesError,
    totalUsesAmount,
    totalUsesAmountHeadquarter,
    mappedUsesWithShare,
  } = useUsesCalculation({
    dealUuid: dealUuidByTileCode,
    headquarterCurrency,
  })

  const totalSourcesAmount = totalUsesAmount
  const totalSourcesAmountHeadquarter = totalUsesAmountHeadquarter

  const { mappedTranchesValues, isTranchesFetching, isTranchesError, tranchesError } =
    usePbbCalculation({
      dealUuid: dealUuidByTileCode,
    })

  const {
    isSyndicationFetching,
    isSyndicationError,
    mappedSyndicationValues,
    syndicationError,
    resolvedBusinessPartnersForSyndicationError,
  } = useSyndicationPartnerCalculation({ dealUuid: dealUuidByTileCode })

  const {
    isOtherFinancingSourcesFetching,
    isOtherFinancingSourcesError,
    mappedOtherSources,
    otherFinancingSourcesError,
  } = useOtherFinancingSourcesCalculation({ dealUuid: dealUuidByTileCode })

  const { areAllCurrenciesTheSame, availableCurrencies } = useCurrencyHelper({
    mappedTranchesValues,
    mappedSyndicationValues,
    mappedOtherSources,
    mappedUsesWithShare,
  })

  const { mappedEquity } = useEquityCalculation({
    mappedTranchesValues,
    mappedSyndicationValues,
    mappedOtherSources,
    totalSourcesAmount,
    totalSourcesAmountHeadquarter,
    headquarterCurrency,
    availableCurrencies,
    areAllCurrenciesTheSame,
  })

  const mappedSources = useMappedSources({
    mappedTranchesValues,
    mappedSyndicationValues,
    mappedOtherSources,
    mappedEquity,
    headquarterCurrency,
    totalSourcesAmount,
    totalSourcesAmountHeadquarter,
    areAllCurrenciesTheSame,
    availableCurrencies,
  })

  const mappedUses = useMappedUses({
    mappedUsesWithShare,
    totalSourcesAmount,
    totalSourcesAmountHeadquarter,
    availableCurrencies,
    headquarterCurrency,
    areAllCurrenciesTheSame,
  })

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isDealUuidFetching,
      isDealFetching,
      isDealUsesFetching,
      isSyndicationFetching,
      isHeadquarterCurrencyFetching,
      isTranchesFetching,
      isOtherFinancingSourcesFetching,
    ],
    errorValues: [
      isDealUuidError,
      isDealError,
      isDealUsesError,
      isSyndicationError,
      isHeadquarterCurrencyError,
      isTranchesError,
      isOtherFinancingSourcesError,
    ],
    errorDetails: [
      ...dealUuidErrors,
      dealError,
      headquarterCurrencyError,
      dealUsesError,
      tranchesError,
      syndicationError,
      resolvedBusinessPartnersForSyndicationError,
      otherFinancingSourcesError,
    ],
    tileId,
  })

  const financingPathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?dataSource=newBusiness&working-version=true' : ''),
    [isWorkingVersionDataTile],
  )
  const generalInformationPathSuffix = useMemo(
    () => (isWorkingVersionDataTile ? '?working-version=true' : ''),
    [isWorkingVersionDataTile],
  )

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error, data: undefined }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false, data: undefined }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        sourceRender: {
          dealDisplayId: dealData?.dealId,
          financingPathSuffix,
          generalInformationPathSuffix,
        },
        availableCurrencies,
        areAllCurrenciesTheSame,
        headquarterCurrency,
        sources: mappedSources,
        uses: mappedUses,
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealData?.dealId,
    financingPathSuffix,
    generalInformationPathSuffix,
    availableCurrencies,
    areAllCurrenciesTheSame,
    headquarterCurrency,
    mappedSources,
    mappedUses,
    error,
  ])
}

export default useDealSourcesAndUses
