import { EXCEL_FIELDS_TO_OBTAIN_MAPPINGS_FOR } from 'components/domains/properties/rent-roll/working-version/excel-upload/getPropertyRentRollWorkingVersionExcelLocalValueMapping'

const FIELDS_MAP_EMPTY_TO_NULL = ['tenant_name', 'tenant_id']

const applyMappingToExcelValues = ({ rentalUnitsWithOriginalExcelData, valueMappingRules }) => {
  const castToLowerAndReplaceUnwantedChars = (input) => {
    //remove sub- and superscript numbers, hyphen, underscore, space
    if (!input) return input
    const replaceCharsRegex = /[\p{No}_ -]/gu
    return String(input).replaceAll(replaceCharsRegex, '').toLowerCase()
  }

  const determineMappingRuleToApply = (fieldName, fieldValue) => {
    const mappingRulesOnField = valueMappingRules.filter((rule) => rule.field_name === fieldName)
    const filteredRules = mappingRulesOnField.filter((rule) => {
      if (rule.match_type === 'identical') {
        return rule.original_value === fieldValue
      } else if (rule.match_type === 'exact') {
        return rule.original_value.toLowerCase() === castToLowerAndReplaceUnwantedChars(fieldValue)
      } else if (rule.match_type === 'contains') {
        return fieldValue
          ? castToLowerAndReplaceUnwantedChars(fieldValue).search(
              rule.original_value.toLowerCase(),
            ) >= 0
          : false
      }
      return false
    })
    if (filteredRules.length === 1) {
      return filteredRules[0]
    }
    return null
  }

  const applyMappingRule = (fieldName, targetValue) =>
    FIELDS_MAP_EMPTY_TO_NULL.includes(fieldName) && targetValue === '' ? null : targetValue

  const applyMappingRuleOnFieldIfExists = (primaryField, rentalUnitRow, secondaryField = null) => {
    const fieldNameToApplyMappingTo = secondaryField ? secondaryField : primaryField
    const originalExcelValue = rentalUnitRow['original_excel_content'][primaryField]
    const ruleToApply = determineMappingRuleToApply(fieldNameToApplyMappingTo, originalExcelValue)
    if (ruleToApply) {
      return applyMappingRule(fieldNameToApplyMappingTo, ruleToApply['target_value'])
    }
    //if no rule could be applied, keep the value already present in the row
    return rentalUnitRow[fieldNameToApplyMappingTo]
  }

  const mappedRentalUnits = rentalUnitsWithOriginalExcelData.map((rentalUnit) => {
    const mappedRentalUnit = { ...rentalUnit }
    EXCEL_FIELDS_TO_OBTAIN_MAPPINGS_FOR.forEach((field) => {
      const { primary: fieldName, secondary: secondaryField } = field
      mappedRentalUnit[fieldName] = applyMappingRuleOnFieldIfExists(fieldName, mappedRentalUnit)
      if (secondaryField) {
        mappedRentalUnit[secondaryField] = applyMappingRuleOnFieldIfExists(
          fieldName,
          mappedRentalUnit,
          secondaryField,
        )
      }
    })
    return { ...mappedRentalUnit }
  })

  return [...mappedRentalUnits]
}

export default applyMappingToExcelValues
