/* eslint-disable react/forbid-prop-types */
import { FilterBar, FilterGroupItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import styles from 'components/ui/page/DashboardFilterBar.module.css'
import '@fioneer/ui5-webcomponents-react/dist/Assets.js'
import '@ui5/webcomponents/dist/Assets.js'

const propTypes = {
  /** Object containing each filter's initial visibility on bar boolean.
   * Eg: {name: {visibleOnBar: true }, date: {visibleOnBar: false}} */
  filterOptions: PropTypes.objectOf(
    PropTypes.shape({
      visibleOnBar: PropTypes.bool.isRequired,
    }).isRequired,
  ),
  /** Number of active filters. See helper method countActiveFilters */
  activeFiltersCount: PropTypes.number.isRequired,
  /** State setter for a boolean keeping track of whether the 'adapt filters' dialog is open. */
  setDialogOpen: PropTypes.func.isRequired,
  /**Array of FilterGroupItems to render in the filter bar.
   * The key prop of each FilterGroupItem should match the name of the filter in filterParams and filterOptions. */
  children: PropTypes.array.isRequired,
  /** Function to be called when the `Go` button is clicked */
  onGo: PropTypes.func.isRequired,
  additionalProperties: PropTypes.object,
}

/**
 * Generic filter bar for dashboard pages. Consists of bar of variable filters and adapt filters dialog.
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const DashboardFilterBar = ({
  filterOptions = {},
  activeFiltersCount,
  onGo,
  setDialogOpen,
  children,
  additionalProperties,
}) => {
  const [filterBarVisibility, setFilterBarVisibility] = useState(
    Object.fromEntries(
      Object.entries(filterOptions ?? {}).map(([key, { visibleOnBar }]) => [key, visibleOnBar]),
    ),
  )

  /** @param {CustomEvent<{toggledElements?: Record<string, HTMLElement>}>} event */
  const onFilterDialogSave = (event) => {
    const toggledElements = event.detail.toggledElements ?? {}
    // Need to remove first two characters of key because they start with ".$"
    const newVisibility = Object.fromEntries(
      Object.entries(toggledElements).map(([key, value]) => [key.substring(2), !!value]),
    )
    setFilterBarVisibility(newVisibility)
  }

  const initializedChildren = children.map(({ key, props }) => (
    <FilterGroupItem
      {...props}
      className={styles.filterItem}
      visibleInFilterBar={filterBarVisibility[key]}
      key={key}
    />
  ))

  return (
    <FilterBar
      className={styles.filterbar}
      hideToolbar={true}
      hideToggleFiltersButton
      showGoOnFB
      hideFilterConfiguration={false}
      onGo={onGo}
      onFiltersDialogSave={onFilterDialogSave}
      activeFiltersCount={activeFiltersCount}
      onFiltersDialogOpen={() => setDialogOpen(true)}
      onFiltersDialogClose={() => setDialogOpen(false)}
      {...additionalProperties}
    >
      {initializedChildren}
    </FilterBar>
  )
}

DashboardFilterBar.propTypes = propTypes

export default DashboardFilterBar
