import { FlexBox, FlexBoxJustifyContent, Label, Text } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/disbursement/fields/DisbursementTileDisplayOrEditItem.module.css'
import EditCardItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/disbursement/fields/shared/EditCardItem'

const DisbursementTileDisplayOrEditItem = ({ label, value, isEditMode, renderEditComponent }) => {
  if (!isEditMode) {
    return (
      <FlexBox
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        className={styles.disbursementTileItemWrapper}
      >
        <Label>{label}</Label>
        <Text className={styles.textValue}>{isEmpty(value) ? '-' : value}</Text>
      </FlexBox>
    )
  }
  return <EditCardItem label={label} editComponent={renderEditComponent()} />
}

DisbursementTileDisplayOrEditItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isEditMode: PropTypes.bool.isRequired,
  renderEditComponent: PropTypes.func.isRequired,
}

export default DisbursementTileDisplayOrEditItem
