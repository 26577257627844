import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Entity from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/shared/table/table-cells/conditions/Entity'
import ReferencePopover from 'components/domains/business-events-and-tasks/decision-paper/tiles/conditions/shared/table/table-cells/conditions/ReferencePopover'

export const entitiesQuantity = {
  single: 'SINGLE',
  multiple: 'MULTIPLE',
  none: 'NONE',
}

const ReferenceEntitiesView = ({
  quantity,
  entityClassName,
  isPdfView = false,
  shouldDisplayEntityId = true,
  ...referenceEntitiesProps
}) => {
  const { t } = useTranslation('decisionPaper', { keyPrefix: 'pages.deals.covenants' })

  if (quantity === entitiesQuantity.single) {
    const id = referenceEntitiesProps?.displayId || referenceEntitiesProps?.id
    let link = referenceEntitiesProps.link
    if (referenceEntitiesProps.linkSuffix) {
      link = `${link}${referenceEntitiesProps.linkSuffix}`
    }
    return (
      <Entity
        {...referenceEntitiesProps}
        id={id}
        link={link}
        openInNewTab={true}
        className={entityClassName}
      />
    )
  }

  if (quantity === entitiesQuantity.multiple) {
    if (isPdfView) {
      return (
        <FlexBox direction={FlexBoxDirection.Column}>
          {(referenceEntitiesProps.listItems ?? []).map(({ id, name }) => (
            <Entity
              key={id}
              name={name}
              link={`${referenceEntitiesProps.linkBasePath}/${id}`}
              openInNewTab={true}
              className={entityClassName}
            />
          ))}
        </FlexBox>
      )
    }

    return (
      <ReferencePopover
        {...referenceEntitiesProps}
        errorText={t('data-error')}
        shouldDisplayEntityId={shouldDisplayEntityId}
      />
    )
  }

  return '-'
}

ReferenceEntitiesView.propTypes = {
  quantity: PropTypes.oneOf(Object.values(entitiesQuantity)).isRequired,
  entityClassName: PropTypes.string,
  isPdfView: PropTypes.bool,
  shouldDisplayEntityId: PropTypes.bool,
}

export default ReferenceEntitiesView
