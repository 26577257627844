import { ButtonDesign, DynamicPageHeader } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/events/dialog/EventSearchDialog.module.css'
import EventSearchDialogFilterBar from 'components/domains/business-events-and-tasks/events/dialog/EventSearchDialogFilterBar'
import EventSearchTable from 'components/domains/business-events-and-tasks/events/dialog/EventSearchTable'
import Dialog, {
  DialogPrimaryButton,
  DialogSecondaryButton,
  DialogSize,
} from 'components/ui/dialog/Dialog'
import { ErrorDataUnavailableInTable } from 'components/ui/errors/ErrorDataUnavailableInTable'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { useEvents } from 'hooks/services/business-events-and-tasks/events/useEvents'

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  isMultiSelect: PropTypes.bool.isRequired,
  initialFilterValues: PropTypes.shape({
    searchValue: PropTypes.string,
    assigneeId: PropTypes.string,
    currentDueDateRange: PropTypes.string,
    originalDueDateRange: PropTypes.string,
    status: PropTypes.arrayOf(PropTypes.string),
    entityType: PropTypes.string,
    entityId: PropTypes.string,
    eventCode: PropTypes.string,
  }),
  readOnlyFilters: PropTypes.arrayOf(PropTypes.string),
  customEventCodeFilterList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
    }),
  ),
}

/** @param {PropTypes.InferProps<typeof propTypes>} props */
const EventSearchDialog = ({
  isOpen,
  setIsOpen,
  isMultiSelect = false,
  onClose = () => {},
  onSelect,
  initialFilterValues = {},
  readOnlyFilters = [],
  customEventCodeFilterList = [],
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.events.search.dialog',
  })
  const { t: tNoPrefix } = useTranslation('translation')

  const [selectedEvents, setSelectedEvents] = useState([])
  const [filterParams, setFilterParams] = useState(initialFilterValues)

  const selectedEventIds = useMemo(() => selectedEvents.map((event) => event.id), [selectedEvents])

  // Sometimes initialFilterValues is undefined on first render, thus setting an incorrect filterParams state.
  // Set the filter params to the initialFilterValues only if there are no existing filter params yet
  useEffect(() => {
    if (isEmpty(filterParams) && !isNil(initialFilterValues)) {
      setFilterParams(initialFilterValues)
    }
  }, [filterParams, initialFilterValues])

  // Set and reset filter params when dialog opens/closes
  useEffect(() => {
    setFilterParams(initialFilterValues)
    setSelectedEvents([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const {
    isLoading,
    isError,
    isFetching,
    data: eventData,
    fetchNextPage,
  } = useEvents(
    { sortBy: 'info.current_due_date', orderBy: 'asc' },
    {
      ...(customEventCodeFilterList?.length > 0 && {
        eventCode: customEventCodeFilterList[0]?.code,
      }),
      ...filterParams,
    },
    {},
  )

  const onLoadMore = useCallback(() => fetchNextPage(), [fetchNextPage])

  const handleClose = () => {
    setIsOpen(false)
    onClose()
  }
  const handleConfirm = useCallback(() => {
    onSelect(selectedEvents)
    setIsOpen(false)
    onClose()
  }, [onClose, onSelect, selectedEvents, setIsOpen])

  const handleSelect = (selectionEvent) => {
    const selectedTableRows = selectionEvent.detail?.selectedRows
    if (selectedTableRows?.length) {
      const selectedTableEvents = selectedTableRows.map(
        ({ dataset: { eventId, eventDisplayId, eventName } }) => ({
          id: eventId,
          displayId: eventDisplayId,
          name: eventName,
        }),
      )
      setSelectedEvents(selectedTableEvents)
    }
  }

  return (
    <Dialog
      resizable={false}
      open={isOpen}
      size={DialogSize.XL}
      className={styles.searchDialog}
      headerText={t('title')}
      primaryButton={
        <DialogPrimaryButton
          onClick={handleConfirm}
          design={ButtonDesign.Emphasized}
          disabled={!selectedEvents.length}
        >
          {tNoPrefix('buttons.ok')}
        </DialogPrimaryButton>
      }
      onBeforeClose={(e) => e?.detail?.escPressed && handleClose()}
      closeButton={
        <DialogSecondaryButton onClick={handleClose}>
          {tNoPrefix('buttons.cancel')}
        </DialogSecondaryButton>
      }
    >
      <>
        <DynamicPageHeader>
          <EventSearchDialogFilterBar
            isDialogOpen={isOpen}
            onGo={setFilterParams}
            initialFilterValues={initialFilterValues}
            readOnlyFilters={readOnlyFilters}
            customEventCodeFilterList={customEventCodeFilterList}
          />
        </DynamicPageHeader>
        <RequestStateResolver
          isLoading={isLoading}
          isError={isError}
          errorToDisplay={<ErrorDataUnavailableInTable />}
          renderContent={() => (
            <div className={styles['tableWrapper']}>
              <EventSearchTable
                events={eventData.events}
                maximumNumberOfEvents={eventData.total}
                selectedEventIds={selectedEventIds}
                onLoadMore={onLoadMore}
                isFetching={isFetching}
                onSelectionChange={handleSelect}
                isMultiSelect={isMultiSelect}
              />
            </div>
          )}
        />
      </>
    </Dialog>
  )
}

EventSearchDialog.propTypes = propTypes

export default EventSearchDialog
