import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isConflictError } from 'api/requests'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useAuthorityLevelCalculator from 'hooks/services/business-partners/authority-level-calculator/useAuthorityLevelCalculator'
import useAuthorityLevelCalculatorComment from 'hooks/services/business-partners/authority-level-calculator/useAuthorityLevelCalculatorComment'

export const UNIT_TYPE = Object.freeze({
  REVIEW: 'REVIEW',
  GCC: 'GCC',
  STANDALONE: 'STANDALONE',
})

const useBusinessPartnerBaselTwoAuthorityLevelCalculator = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const { selectedUnit } =
    useSelector(
      (state) =>
        state.decisionPaper.tilesOverview.tileMetadata?.[availableAutomaticTiles.unitSelectionTile],
    ) ?? {}

  const {
    data: authorityLevelCalculatorData,
    isLoading: isAuthorityLevelCalculatorLoading,
    isError: isAuthorityLevelCalculatorError,
    error: authorityLevelCalculatorError,
  } = useAuthorityLevelCalculator(businessPartnerId, selectedUnit?.headId, selectedUnit?.typeId)

  const {
    data: authorityLevelCalculatorCommentData,
    isLoading: isAuthorityLevelCalculatorCommentLoading,
    isError: isAuthorityLevelCalculatorCommentError,
    error: authorityLevelCalculatorCommentError,
  } = useAuthorityLevelCalculatorComment(
    businessPartnerId,
    selectedUnit?.headId,
    selectedUnit?.typeId,
  )

  const normalizedAuthorityLevelCalculatorError = useMemo(() => {
    if (isAuthorityLevelCalculatorError) {
      return !isConflictError(authorityLevelCalculatorError)
    }
    return false
  }, [authorityLevelCalculatorError, isAuthorityLevelCalculatorError])

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isAuthorityLevelCalculatorLoading, isAuthorityLevelCalculatorCommentLoading],
    errorValues: [normalizedAuthorityLevelCalculatorError, isAuthorityLevelCalculatorCommentError],
    errorDetails: [authorityLevelCalculatorError, authorityLevelCalculatorCommentError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    const data =
      authorityLevelCalculatorData && authorityLevelCalculatorCommentData
        ? {
            authorityLevelCalculatorTableData: authorityLevelCalculatorData,
            authorityLevelCalculatorHeaderData: {
              businessPartnerName: authorityLevelCalculatorData.unitHead?.unitHeadBpFullName,
              businessPartnerId: authorityLevelCalculatorData.unitHead?.unitHeadBpId,
              businessPartnerType: selectedUnit?.typeId,
            },
            authorityLevelCalculatorCommentData: authorityLevelCalculatorCommentData.comment,
            dealBorrowerBpId: businessPartnerId,
            sourceRender: { businessPartnerId },
          }
        : undefined

    return {
      isLoading: false,
      isError: false,
      data,
    }
  }, [
    authorityLevelCalculatorCommentData,
    authorityLevelCalculatorData,
    businessPartnerId,
    error,
    isSomeValueError,
    isSomeValueLoading,
    selectedUnit?.typeId,
  ])
}

export default useBusinessPartnerBaselTwoAuthorityLevelCalculator
