import { SegmentedButton, SegmentedButtonItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'routes/deals/collaterals/creation/CagDialogBusinessTypeSwitch.module.css'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'

const CagDialogBusinessTypeSwitch = ({ businessType, onBusinessTypeChange }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.detail.collaterals' })

  const businessTypeOrDefault = useMemo(
    () => businessType ?? DATA_SOURCES.NEW_BUSINESS,
    [businessType],
  )

  const isNewBusiness = useMemo(
    () => businessTypeOrDefault === DATA_SOURCES.NEW_BUSINESS,
    [businessTypeOrDefault],
  )
  const isExistingBusiness = useMemo(
    () => businessTypeOrDefault === DATA_SOURCES.EXISTING_BUSINESS,
    [businessTypeOrDefault],
  )

  const handleOnChange = (event) => {
    const selectedItem = event.detail?.selectedItem?.getAttribute('data-key')
    onBusinessTypeChange(selectedItem)
  }

  return (
    <SegmentedButton
      data-testid="cag-dialog-new-and-existing-business-switch"
      className={styles.switchButton}
      onSelectionChange={handleOnChange}
    >
      <SegmentedButtonItem data-key={DATA_SOURCES.NEW_BUSINESS} pressed={isNewBusiness}>
        {t('new-business')}
      </SegmentedButtonItem>

      <SegmentedButtonItem data-key={DATA_SOURCES.EXISTING_BUSINESS} pressed={isExistingBusiness}>
        {t('existing-business')}
      </SegmentedButtonItem>
    </SegmentedButton>
  )
}
CagDialogBusinessTypeSwitch.propTypes = {
  businessType: PropTypes.oneOf([
    DATA_SOURCES.EXISTING_BUSINESS,
    DATA_SOURCES.NEW_BUSINESS,
    undefined,
    null,
  ]),
  onBusinessTypeChange: PropTypes.func.isRequired,
}

export default CagDialogBusinessTypeSwitch
