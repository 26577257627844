import { MessageStrip, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Question from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/default-risk-indicators-forbearance-measures/Question'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/default-risk-indicators-forbearance-measures/RiskIndicatorsForbearanceMeasuresCheckSheet.module.css'
import Section from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/default-risk-indicators-forbearance-measures/Section'
import {
  QUESTIONS,
  checkSheetSchema,
  emptyCheckSheet,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/default-risk-indicators-forbearance-measures/checkSheetSchema'

const propTypes = {
  currentContent: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  setHasContentChanges: PropTypes.func.isRequired,
}

/**
 * @typedef {object} overrides
 * @property {(newContent: string) => void} onChange
 * @property {(hasChanges: boolean) => void} setHasContentChanges
 * @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props
 */
const RiskIndicatorsForbearanceMeasuresCheckSheet = ({
  currentContent,
  onChange,
  isEditMode,
  setHasContentChanges,
}) => {
  const content = useMemo(() => {
    const parsedContent = checkSheetSchema
      .optional()
      .safeParse(currentContent && JSON.parse(currentContent))

    if (!parsedContent.success) {
      // eslint-disable-next-line no-console
      console.error(
        "Couldn't parse the previous check sheet. Resetting to an empty one",
        parsedContent.error,
      )
      return emptyCheckSheet
    }

    return parsedContent.data ?? emptyCheckSheet
  }, [currentContent])

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.risk-monitoring.default-risk-indicators-and-forbearance-measures-checksheet',
  })

  /**
   * @typedef {import('components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/default-risk-indicators-forbearance-measures/checkSheetSchema').CheckSheet} CheckSheet
   */
  /**
   * @template {keyof CheckSheet} Section
   * @param {object} params
   * @param {Section} params.section
   * @param {keyof CheckSheet[Section]} params.label
   * @param {string} params.value
   */
  const handleChange = ({ section, label, value }) => {
    const newContent = { ...content, [section]: { ...content[section], [label]: value } }
    onChange(JSON.stringify(newContent))
    setHasContentChanges(true)
  }

  const result = useMemo(() => {
    const sections = Object.keys(content).filter((key) => key !== QUESTIONS.result)

    const anyQuestionIsTrue = sections.some((section) =>
      Object.values(content[section]).includes('yes'),
    )

    return { [QUESTIONS.minOneIndicatorOrForbearanceApplicable]: anyQuestionIsTrue ? 'yes' : 'no' }
  }, [content])

  return (
    <div className={styles.checkSheet}>
      <Section label={t(QUESTIONS.crrDefault)}>
        {Object.entries(content[QUESTIONS.crrDefault]).map(([key, value]) => (
          <Question
            key={key}
            label={t(key)}
            value={value}
            onChange={(newValue) =>
              handleChange({ section: QUESTIONS.crrDefault, label: key, value: newValue })
            }
            isEditMode={!!isEditMode}
          />
        ))}
      </Section>
      <Section label={t(QUESTIONS.enforcementMeasures)}>
        {Object.entries(content[QUESTIONS.enforcementMeasures]).map(([key, value]) => (
          <Question
            key={key}
            label={t(key)}
            value={value}
            onChange={(newValue) =>
              handleChange({ section: QUESTIONS.enforcementMeasures, label: key, value: newValue })
            }
            isEditMode={!!isEditMode}
          />
        ))}
      </Section>
      <Section label={t(QUESTIONS.insolvency)}>
        {Object.entries(content[QUESTIONS.insolvency]).map(([key, value]) => (
          <Question
            key={key}
            label={t(key)}
            value={value}
            onChange={(newValue) =>
              handleChange({ section: QUESTIONS.insolvency, label: key, value: newValue })
            }
            isEditMode={!!isEditMode}
          />
        ))}
      </Section>
      <Section label={t(QUESTIONS.forbearanceMeasures)}>
        {Object.entries(content[QUESTIONS.forbearanceMeasures]).map(([key, value]) => (
          <Question
            key={key}
            label={t(key)}
            value={value}
            onChange={(newValue) =>
              handleChange({ section: QUESTIONS.forbearanceMeasures, label: key, value: newValue })
            }
            isEditMode={!!isEditMode}
          />
        ))}
      </Section>
      <Section label={t(QUESTIONS.result)} isResult>
        {Object.entries(result).map(([key, value]) => (
          <Question key={key} label={t(key)} value={value} isEditMode={false} />
        ))}
        {result[QUESTIONS.minOneIndicatorOrForbearanceApplicable] === 'yes' && (
          <MessageStrip
            hideCloseButton
            design={MessageStripDesign.Information}
            className={styles.advice}
          >
            {t('positive-result-advice')}
          </MessageStrip>
        )}
      </Section>
    </div>
  )
}

RiskIndicatorsForbearanceMeasuresCheckSheet.propTypes = propTypes

export default RiskIndicatorsForbearanceMeasuresCheckSheet
