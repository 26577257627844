import { Input, Label, ObjectStatus, Option, Select, Text } from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const MarketProxiesTableMonitoringCell = ({ isEditing, onChange, proxySetting }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.markets.detail.manager.market-proxies',
  })

  const isOverride = proxySetting.isOverride
  const isMonitoredDefault = !isNil(proxySetting.defaultProxy)
  const isMonitored =
    isOverride && proxySetting.overrideProxy
      ? proxySetting.overrideProxy.isMonitored
      : isMonitoredDefault
  const monitoredText = t(`table.monitoring.${isMonitored}`)
  const monitoredDefaultText = t(`table.monitoring.${isMonitoredDefault}`)

  const handleSelectionChange = useCallback(
    ({ detail: { selectedOption } }) => {
      onChange(proxySetting.key, selectedOption.getAttribute('data-monitored') === 'true')
    },
    [onChange, proxySetting.key],
  )

  const monitoredOptions = useMemo(
    () =>
      [true, false].map((isMonitoredOption) => (
        <Option
          key={isMonitoredOption}
          selected={isMonitored === isMonitoredOption}
          data-monitored={isMonitoredOption}
        >
          {t(`table.monitoring.${isMonitoredOption}`)}
        </Option>
      )),
    [isMonitored, t],
  )

  if (!isEditing) {
    return (
      <ObjectStatus inverted state={isMonitored ? ValueState.Success : ValueState.None}>
        {monitoredText}
      </ObjectStatus>
    )
  }

  if (!isOverride) {
    return <Input readonly value={monitoredText} />
  }

  return (
    <>
      <Select onChange={handleSelectionChange}>{monitoredOptions}</Select>
      <br />
      <Label showColon>{t('default-value')}</Label> <Text>{monitoredDefaultText}</Text>
    </>
  )
}

MarketProxiesTableMonitoringCell.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  proxySetting: PropTypes.shape({
    key: PropTypes.string.isRequired,
    isOverride: PropTypes.bool.isRequired,
    defaultProxy: PropTypes.shape({}),
    overrideProxy: PropTypes.shape({
      isMonitored: PropTypes.bool,
    }),
  }).isRequired,
}

export default MarketProxiesTableMonitoringCell
