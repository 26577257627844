import { ValueState } from '@fioneer/ui5-webcomponents-react'

const FIRST_CATEGORY = 1
const SECOND_CATEGORY = 2
const THIRD_CATEGORY = 3

const determineValueStateBasedOnAssessmentCategory = (category) => {
  switch (category) {
    case FIRST_CATEGORY:
      return ValueState.Success
    case SECOND_CATEGORY:
      return ValueState.Warning
    case THIRD_CATEGORY:
      return ValueState.Error
    default:
      return ValueState.Information
  }
}

export default determineValueStateBasedOnAssessmentCategory
