import { ObjectPageSection } from '@fioneer/ui5-webcomponents-react'
import { lazy, Suspense, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import BusinessPartnerHeader from 'components/domains/business-partners/BusinessPartnerHeader'
import CreateBusinessPartnerEventAction from 'components/domains/business-partners/header-actions/CreateBusinessPartnerEventAction'
import LazyLoadingFallbackCard from 'components/ui/card/LazyLoadingFallbackCard'
import TabPage from 'components/ui/page/TabPage'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import lazyRetry from 'lazyRetry'
import { BusinessPartnerContext } from 'routes/business-partners/BusinessPartnerContext'
import paths from 'routes/business-partners/paths'

const RiskMonitoringRiskIndicatorsTab = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "RiskMonitoringRiskIndicatorsTab" */ 'components/domains/business-partners/tile/risk-monitoring/RiskMonitoringRiskIndicatorsTab'
      ),
    'RiskMonitoringRiskIndicatorsTab',
  ),
)

const RiskMonitoringForbearanceTab = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "RiskMonitoringForbearanceTab" */ 'components/domains/business-partners/tile/risk-monitoring/RiskMonitoringForbearanceTab'
      ),
    'RiskMonitoringForbearanceTab',
  ),
)

const BusinessPartnerRiskMonitoringPage = () => {
  const businessPartner = useContext(BusinessPartnerContext)
  const { t } = useTranslation()
  const businessPartnerFound = () => Boolean(businessPartner.id)

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={businessPartner?.id}
        entityType={cwpEntityTypes.BUSINESS_PARTNER}
      />
    ),
    [businessPartner?.id],
  )

  const renderContent = () =>
    businessPartnerFound() ? (
      [
        <ObjectPageSection
          id="risk-indicators-tab"
          key="risk-indicators-tab"
          titleText={t('pages.business-partner.risk-monitoring.risk-indicators.title')}
        >
          <Suspense fallback={<LazyLoadingFallbackCard />}>
            <RiskMonitoringRiskIndicatorsTab />
          </Suspense>
        </ObjectPageSection>,
        <ObjectPageSection
          id="forbearance-tab"
          key="forbearance-tab"
          titleText={t('pages.business-partner.risk-monitoring.forbearance.title')}
        >
          <Suspense fallback={<LazyLoadingFallbackCard />}>
            <RiskMonitoringForbearanceTab />
          </Suspense>
        </ObjectPageSection>,
      ]
    ) : (
      <ObjectPageSection
        id="not-found-tab"
        key="not-found-tab"
        titleText={t('pages.business-partner.not-found')}
      >
        {<NavLink to={paths.businessPartners}>{t('pages.business-partner.search')}</NavLink>}
      </ObjectPageSection>
    )

  return (
    <TabPage
      isLoading={false}
      isError={false}
      headerTitle={
        <BusinessPartnerHeader
          breadcrumbs={[{ text: t('navigation.item.title.risk-monitoring') }]}
          actions={
            <>
              (
              <CreateBusinessPartnerEventAction />
              ), {markFavoriteAction}
            </>
          }
        />
      }
      defaultSectionId="risk-indicators-tab"
      className="businessPartnerRiskMonitoring"
      id="businessPartnerRiskMonitoring"
    >
      {renderContent()}
    </TabPage>
  )
}

BusinessPartnerRiskMonitoringPage.propTypes = {}

export default BusinessPartnerRiskMonitoringPage
