import PropTypes from 'prop-types'
import { forwardRef, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionStageVoterStatus } from 'api/decision-process/decisionProcessApi'
import BusinessObjectLink, {
  BusinessObjectLinkCustomRender,
} from 'components/domains/business-events-and-tasks/BusinessObjectLink'
import Header from 'components/ui/page/Header'
import useDecisionPaperButtons from 'hooks/services/business-events-and-tasks/decision-papers/buttons/useDecisionPaperButtons'
import useDecisionStageIndividualVoteButtons from 'hooks/services/business-events-and-tasks/decision-process/stages/buttons/useDecisionStageIndividualVoteButtons'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import paths from 'routes/paths'

const DecisionStageApprovalVoterHeader = forwardRef(
  (
    {
      hideVoterPage,
      decisionStageVoterId,
      voterStatusCode,
      decisionPaperVersion,
      decisionStageId,
      voterId,
      setIsAttachmentsDialogOpen,
      ...headerProps
    },
    ref,
  ) => {
    const {
      event: {
        id: eventId,
        entityRef: { entityId, entityType },
      },
    } = useContext(BusinessEventsAndTasksContext)
    const { t } = useTranslation('translation', {
      keyPrefix: 'components.business-events-and-tasks.decision-stage.approval.header',
    })
    const handleExportClick = useCallback(() => {
      const exportQueryParams = new URLSearchParams()
      exportQueryParams.append('event_id', eventId)
      exportQueryParams.append('stage_id', decisionStageId)
      exportQueryParams.append('voter_id', voterId)
      decisionPaperVersion && exportQueryParams.append('version', decisionPaperVersion)
      window.open(`/${paths.decisionPaperPdfExport}?${exportQueryParams.toString()}`, '_blank')
    }, [eventId, decisionStageId, voterId, decisionPaperVersion])

    const showVoteButtons = voterStatusCode && voterStatusCode === decisionStageVoterStatus.pending

    const handleAttachmentsClicked = useCallback(() => {
      setIsAttachmentsDialogOpen(true)
    }, [setIsAttachmentsDialogOpen])

    const {
      isLoading: isIndividualVotesLoading,
      isError: isIndividualVotesError,
      buttons: individualVoteButtons,
    } = useDecisionStageIndividualVoteButtons({ decisionStageVoterId })

    const {
      isLoading: isDecisionPaperButtonsLoading,
      isError: isDecisionPaperButtonsError,
      buttons: decisionPaperButtons,
    } = useDecisionPaperButtons({
      handleExportClick,
      decisionPaperVersion,
      handleAttachmentsClicked,
    })

    const headerActions = useMemo(() => {
      if (isIndividualVotesLoading || isDecisionPaperButtonsLoading) {
        return []
      }
      const result = []
      if (showVoteButtons && !isIndividualVotesError) {
        result.push(...individualVoteButtons)
      }
      if (decisionPaperVersion && !isDecisionPaperButtonsError) {
        result.push(...decisionPaperButtons)
      }
      return result
    }, [
      decisionPaperButtons,
      decisionPaperVersion,
      individualVoteButtons,
      isDecisionPaperButtonsError,
      isDecisionPaperButtonsLoading,
      isIndividualVotesError,
      isIndividualVotesLoading,
      showVoteButtons,
    ])

    const subtitle = useMemo(
      () => (
        <BusinessObjectLink
          entityId={entityId}
          entityType={entityType}
          customRender={BusinessObjectLinkCustomRender.clipboard}
        />
      ),
      [entityId, entityType],
    )

    return (
      <Header
        {...headerProps}
        ref={ref}
        title={t('title')}
        subtitle={subtitle}
        actions={!hideVoterPage && headerActions}
        navigationActions={decisionPaperButtons}
      />
    )
  },
)

DecisionStageApprovalVoterHeader.displayName = 'DecisionStageApprovalHeader'

DecisionStageApprovalVoterHeader.propTypes = {
  decisionStageVoterId: PropTypes.string,
  hideVoterPage: PropTypes.bool.isRequired,
  voterStatusCode: PropTypes.oneOf(Object.values(decisionStageVoterStatus)),
  decisionPaperVersion: PropTypes.string,
  decisionStageId: PropTypes.string,
  voterId: PropTypes.string,
  setIsAttachmentsDialogOpen: PropTypes.func.isRequired,
}

export default DecisionStageApprovalVoterHeader
