import orderBy from 'lodash.orderby'
import PropTypes from 'prop-types'
import React from 'react'
import styles from 'components/domains/properties/properties-search/filterbar/PropertiesFilterCountryCodeComboBox.module.css'
import LoadingMultiComboBox from 'components/ui/multi-combo-box/LoadingMultiComboBox'
import { useCountryCodes } from 'hooks/services/properties/useCountryCodes'

const PropertiesFilterCountryCodeComboBox = React.forwardRef(
  ({ selectedCountries, handleMultiComboboxChange, disabled }, _ref) => {
    const { data: countryCodesData, isLoading, isError } = useCountryCodes()

    const countryCodeDataMapping = () => {
      if (isLoading || isError) return { isLoading, isError, data: undefined }
      const sortedCountryCodes = orderBy(countryCodesData.country_codes, ['display_name'])

      return {
        data: sortedCountryCodes.map(({ key, display_name }) => ({
          code: key,
          displayName: display_name,
        })),
        isLoading: false,
        isError: false,
      }
    }

    const handleOnChangeFilterCountry = (event) => {
      handleMultiComboboxChange(event, 'country')
    }

    return (
      <LoadingMultiComboBox
        className={styles.maxInputWidth}
        id="properties-filter-country-code"
        useLoader={countryCodeDataMapping}
        selected={selectedCountries}
        disabled={disabled}
        onSelectionChange={handleOnChangeFilterCountry}
      />
    )
  },
)

PropertiesFilterCountryCodeComboBox.displayName = 'PropertiesFilterCountryCodeComboBox'

PropertiesFilterCountryCodeComboBox.propTypes = {
  selectedCountries: PropTypes.array,
  handleMultiComboboxChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export default PropertiesFilterCountryCodeComboBox
