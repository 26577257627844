import '@ui5/webcomponents-fiori/dist/illustrations/UnableToLoad.js'
import { DynamicPageHeader } from '@fioneer/ui5-webcomponents-react'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { eventStatus } from 'api/events/status'
import EventOverviewTable from 'components/domains/business-events-and-tasks/events/EventOverviewTable'
import EventOverviewFilterBar from 'components/domains/business-events-and-tasks/events/filterbar/EventOverviewFilterBar'
import {
  concatIds,
  checkIfAtLeastTwoNotEmpty,
  setEntityTypeIfEmpty,
} from 'components/domains/business-events-and-tasks/events/filterbar/EventsAndTasksFilterUtils'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import { useEvents } from 'hooks/services/business-events-and-tasks/events/useEvents'
import { useStaffMemberSelf } from 'hooks/services/business-partners/staff-members/useStaffMemberSelf'
import styles from 'routes/business-events-and-tasks/events/EventOverview.module.css'
import queryParamNames from 'routes/business-events-and-tasks/queryParamNames.json'

const collectIds = (ids1, ids2, ids3) => concatIds(ids1, ids2, ids3)

const emptyArray = []

const calculateFilterOptions = (queryParams) => {
  const bpIds = queryParams.get(queryParamNames.bpIds)
  const propertyIds = queryParams.get(queryParamNames.propertyIds)
  const dealIds = queryParams.get(queryParamNames.dealIds)

  const ids = collectIds(bpIds, propertyIds, dealIds)

  let entityType = queryParams.get(queryParamNames.entityType)
  entityType = setEntityTypeIfEmpty(bpIds, propertyIds, dealIds, entityType)

  return {
    searchValue: queryParams.get(queryParamNames.searchFilter),
    originalDueDateRange: queryParams.get(queryParamNames.originalDueDateRange),
    currentDueDateRange: queryParams.get(queryParamNames.currentDueDateRange),
    status: queryParams.getAll(queryParamNames.status),
    assigneeId: queryParams.get(queryParamNames.assigneeId),
    entityType: entityType,
    entityId: ids,
  }
}

const defaultQueryParams = {
  status: `${eventStatus.drafted},${eventStatus.ongoing}`,
}

const filterApplied = (queryParams) => {
  const {
    searchValue,
    originalDueDateRange,
    currentDueDateRange,
    status,
    entityType,
    assigneeId,
    bpIds,
    propertyIds,
    dealIds,
  } = calculateFilterOptions(queryParams)

  return (
    searchValue ||
    originalDueDateRange ||
    currentDueDateRange ||
    status.length !== 0 ||
    entityType ||
    assigneeId ||
    bpIds ||
    propertyIds ||
    dealIds
  )
}

const EventOverview = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.event-overview' })
  const { hash } = useLocation()
  const navigate = useNavigate()
  const [queryParams] = useSearchParams()
  const [sorting, setSorting] = useState({ sortBy: 'info.current_due_date', orderBy: 'asc' })
  const showMessageBox = useShowMessageBox()

  const normalizedQueryParams = [...new Set(queryParams.keys())]

  const {
    isLoading: userStaffMemberIsLoading,
    isError: userStaffMemberIsError,
    data: currentUser,
  } = useStaffMemberSelf()

  const currentAssignee = queryParams.get(queryParamNames.assigneeId)
  const showMyEvents = currentAssignee === currentUser?.id

  const enableHooks = useMemo(
    () =>
      normalizedQueryParams.length !== 0 &&
      !checkIfAtLeastTwoNotEmpty(
        queryParams.get(queryParamNames.bpIds),
        queryParams.get(queryParamNames.propertyIds),
        queryParams.get(queryParamNames.dealIds),
      ),
    [normalizedQueryParams.length, queryParams],
  )

  const {
    isLoading,
    isError,
    data: eventData,
    fetchNextPage,
  } = useEvents(
    sorting,
    { ...calculateFilterOptions(queryParams), assigneeId: showMyEvents ? '' : currentAssignee },
    {},
    {
      enabled: enableHooks,
    },
  )

  const enableMyEvents = enableHooks && !userStaffMemberIsLoading && !userStaffMemberIsError
  const {
    isLoading: isLoadingMyEvents,
    isError: isErrorMyEvents,
    isFetching: isFetchingMyEvents,
    data: myEventData,
    fetchNextPage: fetchMyNextPage,
  } = useEvents(
    sorting,
    { ...calculateFilterOptions(queryParams), assigneeId: currentUser?.id },
    {},
    {
      enabled: enableMyEvents,
    },
  )

  // This is a workaround for myEventData sometimes falling back on eventData even though the hook is not enabled.
  const enabledEventData = enableMyEvents ? myEventData : undefined
  const enabledFetchingMyEvents = enableMyEvents ? isFetchingMyEvents : true

  useEffect(() => {
    if (normalizedQueryParams.length === 0) {
      const searchParams = new URLSearchParams(Object.entries(defaultQueryParams))
      if (currentUser?.id) {
        searchParams.append(queryParamNames.assigneeId, currentUser.id)
      }
      const routingURL = `?${searchParams.toString()}${hash}`
      navigate(routingURL)
    } else if (currentUser?.id && !queryParams.has(queryParamNames.assigneeId)) {
      queryParams.append(queryParamNames.assigneeId, currentUser.id)
      const routingURL = `?${queryParams.toString()}${hash}`
      navigate(routingURL)
    }
  }, [currentUser?.id, queryParams]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userStaffMemberIsError) {
      showMessageBox({
        type: MessageBoxTypes.Error,
        children: t('self-information-error'),
        id: 'events-error-message-box',
        open: userStaffMemberIsError,
      })
    }
  }, [userStaffMemberIsError, showMessageBox, t])

  const onSortingChanged = (sortBy, orderBy) => {
    setSorting({ sortBy, orderBy })
  }

  const calculateNoDataText = useCallback(
    () => (filterApplied(queryParams) ? t('filter.empty') : t('filter.no-data')),
    [queryParams, t],
  )

  const onLoadMore = useCallback(() => {
    if (showMyEvents) {
      return fetchMyNextPage()
    }
    return fetchNextPage()
  }, [fetchMyNextPage, fetchNextPage, showMyEvents])

  const renderContent = useCallback(
    () => (
      <EventOverviewTable
        events={
          showMyEvents ? enabledEventData?.events ?? emptyArray : eventData?.events ?? emptyArray
        }
        onSortingChanged={onSortingChanged}
        sortBy={sorting.sortBy}
        orderBy={sorting.orderBy}
        onLoadMore={onLoadMore}
        maximumNumberOfEvents={eventData?.total ?? 0}
        maximumNumberOfMyEvents={enabledEventData?.total ?? 0}
        shownColumns={[
          'checkbox',
          'businessEvent',
          'assignee',
          'originalDueDate',
          'currentDueDate',
          'currentDecisionStage',
          'status',
          'watchers',
          'arrow',
        ]}
        noDataText={calculateNoDataText()}
        className={styles['table-wrapper']}
        myEventsLoading={isErrorMyEvents || isLoadingMyEvents || enabledFetchingMyEvents}
        currentUserId={currentUser?.id}
      />
    ),
    [
      showMyEvents,
      enabledEventData?.events,
      enabledEventData?.total,
      eventData?.events,
      eventData?.total,
      sorting.sortBy,
      sorting.orderBy,
      onLoadMore,
      calculateNoDataText,
      isErrorMyEvents,
      isLoadingMyEvents,
      enabledFetchingMyEvents,
      currentUser?.id,
    ],
  )

  return (
    <Card>
      <DynamicPageHeader className={styles['dynamic-page-header']}>
        <EventOverviewFilterBar
          showFilterFields={[
            'searchFilter',
            'businessPartner',
            'deal',
            'property',
            'businessObjectType',
            'assigneeId',
            'originalDueDateRange',
            'currentDueDateRange',
            'status',
          ]}
        />
      </DynamicPageHeader>
      <RequestStateResolver
        isError={isError || isErrorMyEvents}
        isLoading={isLoading && enableHooks}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        renderContent={renderContent}
      />
    </Card>
  )
}

export default EventOverview
