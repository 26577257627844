import isEmpty from 'lodash.isempty'

const containsSearchParam = ({ key, value, searchParam, formatDate }) => {
  if (key === 'modificationTime') {
    return formatDate(value)?.includes(searchParam)
  }
  return String(value).toLowerCase().includes(searchParam.toLowerCase())
}

const matchingRowOrUndefined = ({ row, searchParam, formatDate }) =>
  Object.entries(row).some(([key, value]) =>
    containsSearchParam({ key, value, searchParam, formatDate }),
  )
    ? row
    : undefined
/**
 *
 * @param {*} rows: the rows to filter. rows can have arbitrary properties and optionally more rows in `subFolders`
 * @param {String} searchParam the param to search for in the properties of the row
 * @param {Function} formatDate the function used to format dates before matching against the search param
 * @returns the filtered rows
 */
const filterRowsBySearchParam = ({ rows, searchParam, formatDate }) => {
  const rowWithNonEmptySubFoldersOrUndefined = (row) => {
    const result = {
      ...row,
      subFolders: filterRowsBySearchParam({
        rows: row.subFolders,
        searchParam,
        formatDate,
      }),
    }
    return result.subFolders.length ? result : undefined
  }

  if (isEmpty(rows) || !searchParam) return rows
  return rows
    .map((row) =>
      isEmpty(row.subFolders)
        ? matchingRowOrUndefined({ row, searchParam, formatDate })
        : rowWithNonEmptySubFoldersOrUndefined(row),
    )
    .filter((value) => !!value)
}

export default filterRowsBySearchParam
