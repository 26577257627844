import { useContext, useMemo } from 'react'
import isGroupCriticalityLowerThanIndicators from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/native/isGroupCriticalityLowerThanIndicators'
import { RISK_INDICATOR_TYPE_CODE_CATEGORIES } from 'components/domains/business-partners/tile/risk-monitoring/utils/typeCodes'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useDetailedRiskIndicatorsByGroupId from 'hooks/services/risk-monitoring/useDetailedRiskIndicatorsByGroupId'
import useRiskIndicatorStatusFieldDecisionPaper from 'hooks/services/risk-monitoring/useRiskIndicatorStatusFieldDecisionPaper'
import useRiskIndicators from 'hooks/services/risk-monitoring/useRiskIndicators'
import useTypeCodes from 'hooks/services/risk-monitoring/useTypeCodes'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

/**
 * @param {object} params
 * @param {object} params.entityRef
 * @param {string} params.entityRef.entityId
 * @param {string} params.tileId
 */
const useRiskMonitoringGeneralInformationDecisionPaper = ({
  entityRef: { entityId: businessPartnerId },
  tileId,
}) => {
  const { event } = useContext(BusinessEventsAndTasksContext)

  // HINT: The search endpoint currently returns the same values as the get by ID endpoint
  //       If this changes, we might have to take the group ID from the search result and use it to fetch the details by ID
  const {
    data: groupData,
    isLoading: isGroupLoading,
    isError: isGroupError,
    isFetching: isGroupFetching,
    error: groupError,
  } = useRiskIndicators({
    businessPartnerId: businessPartnerId,
    businessEventId: event?.id,
  })

  const group = groupData?.riskIndicatorGroups?.[0]
  /** optimistic exists => if we don't know yet if it exists, the group exists */
  const groupExists = groupData?.riskIndicatorGroups?.length !== 0

  const {
    data: detailedRiskIndicatorGroupData,
    isLoading: isDetailedGroupDataLoading,
    isFetching: isDetailedGroupDataFetching,
    isError: isDetailedGroupDataError,
    error: detailedGroupDataError,
  } = useDetailedRiskIndicatorsByGroupId(businessPartnerId, group?.groupId)

  const {
    data: { typeCodes: riskIndicatorCriticalityStatusTypeCodes } = {},
    isFetching: isCriticalityStatusTypeCodesFetching,
    isError: isCriticalityStatusTypeCodesError,
  } = useTypeCodes(RISK_INDICATOR_TYPE_CODE_CATEGORIES.CRITICALITY)

  const statusField = useRiskIndicatorStatusFieldDecisionPaper({
    id: 'status',
    name: 'statusTypeCode',
    shortText: group?.statusShortText,
    typeCode: group?.statusTypeCode,
  })

  const groupCriticality = group?.currentCriticality?.criticalityTypeCode

  const showCriticalityWarningMessage = useMemo(() => {
    if (!groupCriticality) {
      return false
    }

    return isGroupCriticalityLowerThanIndicators({
      selectedCriticality: groupCriticality,
      riskIndicators: detailedRiskIndicatorGroupData?.riskIndicators,
      riskIndicatorCriticalityTypeCodes: riskIndicatorCriticalityStatusTypeCodes,
    })
  }, [
    detailedRiskIndicatorGroupData?.riskIndicators,
    riskIndicatorCriticalityStatusTypeCodes,
    groupCriticality,
  ])

  const criticalityField = useRiskIndicatorStatusFieldDecisionPaper({
    id: 'criticality',
    name: 'criticalityTypeCode',
    shortText: group?.currentCriticality?.criticalityShortText,
    typeCode: group?.currentCriticality?.criticalityTypeCode,
  })

  const { isSomeValueError, isSomeValueLoading, error } = useAutomaticTileHookHelper({
    tileId,
    loadingValues: [
      isGroupFetching,
      isGroupLoading,
      groupExists && isDetailedGroupDataFetching,
      groupExists && isDetailedGroupDataLoading,
      isCriticalityStatusTypeCodesFetching,
    ],
    errorValues: [isGroupError, isDetailedGroupDataError, isCriticalityStatusTypeCodesError],
    errorDetails: [groupError, detailedGroupDataError],
  })

  return {
    isLoading: isSomeValueLoading,
    isError: isSomeValueError,
    data: {
      isNoData: !groupExists,
      isGroupCriticalityLowerThanIndicators: showCriticalityWarningMessage,
      error,
      group,
      statusField,
      criticalityField,
      sourceRender: {
        businessPartnerId,
        riskIndicatorGroupId: group?.groupDisplayId ?? '',
      },
    },
  }
}

export default useRiskMonitoringGeneralInformationDecisionPaper
