import { first } from 'lodash'
import compact from 'lodash.compact'
import pick from 'lodash.pick'
import sortBy from 'lodash.sortby'
import uniq from 'lodash.uniq'
import { useMemo } from 'react'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useMultiplePropertyRentRollOverview from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/backend/useMultiplePropertyRentRollOverview'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useMultipleBusinessPartnerRelationships from 'hooks/services/business-partners/relationships/useMultipleBusinessPartnerRelationships'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const useAnnualReviewTenancyRentRollPropertyTilesOverview = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { dealsData, dealUuids, selectedDeal } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const {
    data: multiplePropertyRentRollOverviewData,
    isLoading: isLoadingMultiplePropertyRentRollOverview,
    isError: isErrorMultiplePropertyRentRollOverview,
    error: multiplePropertyRentRollOverviewError,
  } = useCombinedQueryResults(useMultiplePropertyRentRollOverview({ dealUuids }))

  const getPrimaryTenantId = (propertyKpiData) => {
    const sortedTenantKPIs = sortBy(
      propertyKpiData?.tenantRentRollKpis,
      (item) => -item.annualizedCurrentRent.number,
    )
    return first(sortedTenantKPIs)?.tenant
  }

  const propertyRentRollOverviewByDealUuid = multiplePropertyRentRollOverviewData
    ?.map((data) => ({
      [data.dealUuid]: {
        ...data,
        ...pick(
          dealsData?.find((deal) => deal.dealUuid === data.dealUuid),
          ['dealDisplayId', 'dealName'],
        ),
      },
    }))
    .reduce((prev, curr) => Object.assign(prev, curr), {})

  const primaryTenantIds = propertyRentRollOverviewByDealUuid
    ? uniq(
        compact(
          Object.values(propertyRentRollOverviewByDealUuid)
            .map((dealData) => {
              const propertyKpiDatas = dealData.propertyKpis.map(
                (property) => property.keyDateToRentRollKpis?.[0].kpis,
              )
              return propertyKpiDatas.map((propertyKpiData) => getPrimaryTenantId(propertyKpiData))
            })
            .flat(),
        ),
      )
    : []

  const hasUndefinedPrimaryTenantIds = primaryTenantIds?.some((tenantId) => tenantId === undefined)

  const allTenantIds = propertyRentRollOverviewByDealUuid
    ? uniq(
        Object.values(propertyRentRollOverviewByDealUuid).flatMap((dealData) => {
          const propertyKpiData = dealData.propertyKpis.map(
            (property) => property.keyDateToRentRollKpis?.[0].kpis,
          )
          return propertyKpiData?.flatMap((propertyKpi) =>
            propertyKpi.tenantRentRollKpis?.flatMap((propertyKpiData) => propertyKpiData.tenant),
          )
        }),
      ).filter(Boolean)
    : []

  const {
    isLoading: isLoadingBusinessPartner,
    isError: isErrorBusinessPartner,
    data: businessPartnerData,
  } = useBusinessPartnerMiniByIds(allTenantIds)

  const {
    isLoading: isLoadingMultipleBusinessPartnerRelationships,
    isError: isErrorMultipleBusinessPartnerRelationships,
    data: multipleBusinessPartnerRelationshipsData,
  } = useCombinedQueryResults(useMultipleBusinessPartnerRelationships(primaryTenantIds), {
    forceDataReturn: true,
  })

  const multipleBusinessPartnerRelationshipsDataByPrimaryTenantId = primaryTenantIds
    ?.map((primaryTenantId, index) => ({
      [primaryTenantId]: multipleBusinessPartnerRelationshipsData?.[index],
    }))
    .reduce((prev, curr) => Object.assign(prev, curr), {})

  const firstPropertyIdOfDealByDealUuid = useMemo(
    () =>
      Object.keys(propertyRentRollOverviewByDealUuid ?? {})
        .map((dealUuid) => ({
          [dealUuid]: propertyRentRollOverviewByDealUuid[dealUuid].properties?.[0] ?? {},
        }))
        .reduce((prev, curr) => Object.assign(prev, curr), {}),
    [propertyRentRollOverviewByDealUuid],
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isAnnualReviewDealsLoading,
      isLoadingMultiplePropertyRentRollOverview,
      isLoadingBusinessPartner && allTenantIds?.length > 0,
      isLoadingMultipleBusinessPartnerRelationships,
    ],
    errorValues: [
      isAnnualReviewDealsError,
      isErrorMultiplePropertyRentRollOverview,
      isErrorBusinessPartner,
      isErrorMultipleBusinessPartnerRelationships && !hasUndefinedPrimaryTenantIds,
    ],
    errorDetails: [annualReviewDealsError, multiplePropertyRentRollOverviewError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        propertyRentRollOverviewByDealUuid,
        businessPartnerData,
        multipleBusinessPartnerRelationshipsDataByPrimaryTenantId,
        sourceRender: {
          propertyId: firstPropertyIdOfDealByDealUuid[selectedDeal?.selectedDealUuid]?.id,
        },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealsData,
    propertyRentRollOverviewByDealUuid,
    businessPartnerData,
    multipleBusinessPartnerRelationshipsDataByPrimaryTenantId,
    firstPropertyIdOfDealByDealUuid,
    selectedDeal?.selectedDealUuid,
    error,
  ])
}

export default useAnnualReviewTenancyRentRollPropertyTilesOverview
