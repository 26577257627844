import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const usePublishEventsComment = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ eventIds, comment }) => {
      const { data } = await post({
        path: `/events/comments`,
        body: {
          entity_ids: eventIds,
          comment: comment,
        },
      })
      return data
    },
    { onSuccess, onError },
  )
}
