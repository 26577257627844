import { MessageStrip, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperCheckedCovenantsTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/DecisionPaperCheckedCovenantsTable'
import { CovenantsOfCheckCardShowItem } from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/card/CovenantsOfCheckCardItem'
import CardSection from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/card/CovenantsOfCheckCardSection'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/domains/deals/covenant-check-detail/checked-covenants/CheckedCovenantsCard.module.css'
import { useCovenantsOfCheckShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/covenants/covenants-list/shared/i18n/useCovenantsOfCheckI18n'

const CovenantsOfCheckTile = ({ tileId }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.covenants-of-check.components.deals.covenants',
  })
  const { format: formatDate } = useCovenantsOfCheckShortDateFormatter()

  const { data: { covenantMonitoringItems = [], keyDate, deals, entityType } = {} } = useSelector(
    (state) => state.decisionPaper.tilesOverview.tiles[tileId],
  )

  const showMissingData = covenantMonitoringItems.some(({ breach }) => isNil(breach))

  return (
    <>
      <CardSection>
        <div className={styles.container}>
          <div className={styles.item}>
            <CovenantsOfCheckCardShowItem
              id="key-date"
              label={t('checked-covenants.key-date')}
              value={formatDate(keyDate)}
            />
          </div>
        </div>
      </CardSection>
      <CardSection>
        {showMissingData && (
          <MessageStrip design={MessageStripDesign.Warning} hideCloseButton>
            {t('checked-covenants.missing-data')}
          </MessageStrip>
        )}
        <DecisionPaperCheckedCovenantsTable
          monitoringItems={covenantMonitoringItems}
          deals={deals}
          entityType={entityType}
        />
      </CardSection>
    </>
  )
}

CovenantsOfCheckTile.propTypes = {
  tileId: PropTypes.string,
}

export default CovenantsOfCheckTile
