import { useQueryClient } from '@tanstack/react-query'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessPartnersForEntityCard from 'components/domains/business-partners/entity-management/BusinessPartnersForEntityCard'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import { useMatchingBusinessPartnersByNameOrId } from 'hooks/services/business-partners/searchBusinessPartners'
import useCreatePropertyPartner from 'hooks/services/properties/partners/useCreatePropertyPartner'
import { useDeletePropertyPartner } from 'hooks/services/properties/partners/useDeletePropertyPartner'
import { usePropertyPartnerFunctions } from 'hooks/services/properties/partners/usePropertyPartnerFunctions'
import { usePropertyPartners } from 'hooks/services/properties/partners/usePropertyPartners'
import { useUpdatePropertyPartner } from 'hooks/services/properties/partners/useUpdatePropertyPartner'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyBusinessPartnerCard = () => {
  const { t: tBusinessPartnerCardTable } = useTranslation('translation', {
    keyPrefix: 'pages.properties.business-partner-card.table',
  })
  const { t: tBusinessPartnerCard } = useTranslation('translation', {
    keyPrefix: 'pages.properties.business-partner-card',
  })

  const queryClient = useQueryClient()
  const [searchString, setSearchString] = useState('')

  const initialSorting = { columnKey: 'score', orderBy: 'desc' }

  const translateSortSettingToSortParameter = (sortSetting) =>
    `${sortSetting?.orderBy === 'asc' ? '+' : '-'}${sortSetting?.columnKey}`

  const { data: { businessPartners = [] } = {} } = useMatchingBusinessPartnersByNameOrId({
    searchKey: searchString,
    minLength: 3,
    excludeInactive: false,
    sort: translateSortSettingToSortParameter(initialSorting),
  })

  const getPartnerSuggestions = (searchKey) => {
    setSearchString(searchKey)
    return businessPartners
  }

  const {
    property: {
      uuid: propertyUuid,
      change_request_exist_indicator: changeRequestExistIndicator,
      allowed_operations: allowed_operations,
    },
  } = useContext(PropertyContext)

  const allowedOperations = allowed_operations?.allowed_operations ?? []
  const userIsAllowedToEdit =
    !changeRequestExistIndicator && allowedOperations.includes('PropertyBpExternalRoles_Update')

  const invalidatePartnersOnProperty = () => {
    queryClient.invalidateQueries(['properties', propertyUuid, 'businesspartners', 'external'])
  }

  return (
    <BusinessPartnersForEntityCard
      tTable={tBusinessPartnerCardTable}
      tCard={tBusinessPartnerCard}
      partnerFunctionMode={'external'}
      hooks={{
        getBusinessPartnerSuggestions: getPartnerSuggestions,
        useBusinessPartnersForEntity: usePropertyPartners,
        useLinkBusinessPartnerWithEntity: useCreatePropertyPartner,
        useUnlinkBusinessPartnerFromEntity: useDeletePropertyPartner,
        useUpdateBusinessPartner: useUpdatePropertyPartner,
        useBusinesspartnersById: useBusinessPartnerMiniByIds,
        useBusinessPartnerFunctions: usePropertyPartnerFunctions,
      }}
      entityUuid={propertyUuid}
      isEditable={userIsAllowedToEdit}
      invalidateFunctionMode={invalidatePartnersOnProperty}
    />
  )
}

export default PropertyBusinessPartnerCard
