import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useCreateProperty = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()

  const getStringOrUndefinedIfNotSet = (attribute) => (attribute ? attribute : undefined)
  const getNumberOrUndefinedIfNotSet = (attribute) =>
    attribute && !isNaN(attribute) ? Number(attribute) : undefined
  const getMoneyOrUndefinedIfNotSet = (value, currency) =>
    value && currency && !isNaN(value)
      ? {
          number: value,
          currency: currency,
        }
      : undefined

  return useMutation(
    async (property) => {
      const { data } = await post({
        path: `/properties`,
        body: {
          type_code: getStringOrUndefinedIfNotSet(property.typeCode),
          area_measure_unit_code: getStringOrUndefinedIfNotSet(property.areaMeasureUnitCode),
          description: getStringOrUndefinedIfNotSet(property.description),
          total_area_measure: getNumberOrUndefinedIfNotSet(property.totalArea),
          currency_code: getStringOrUndefinedIfNotSet(property.currencyCode),
          market_value_amount: getMoneyOrUndefinedIfNotSet(
            property.marketValue,
            property.currencyCode,
          ),
          address: {
            street_name: getStringOrUndefinedIfNotSet(property.streetName),
            house_id: getStringOrUndefinedIfNotSet(property.houseNumber),
            postal_code: getStringOrUndefinedIfNotSet(property.postalCode),
            city_name: getStringOrUndefinedIfNotSet(property.cityName),
            country_code: getStringOrUndefinedIfNotSet(property.countryCode),
          },
          geo_location: {
            latitude: getStringOrUndefinedIfNotSet(property.latitude),
            longitude: getStringOrUndefinedIfNotSet(property.longitude),
          },
          usage: {
            usage_code: getStringOrUndefinedIfNotSet(property.typeCodeMinor),
            utilization_code: getStringOrUndefinedIfNotSet(property.utilization),
          },
          construction_completion_date: getStringOrUndefinedIfNotSet(
            property.constructionCompletionDate,
          ),
          construction_completion_percent: getStringOrUndefinedIfNotSet(
            property.constructionCompletionPercent,
          ),
        },
      })
      return data
    },
    { onSuccess, onError },
  )
}
