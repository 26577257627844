import PropTypes from 'prop-types'
import { FileDrop } from 'react-file-drop'
import styles from 'components/domains/properties/rent-roll/working-version/excel-upload/PropertyRentRollExcelFileUploaderDropZone.module.css'

const PropertyRentRollExcelFileUploaderDropZone = ({ onDrop }) => (
  <FileDrop
    className={styles.container}
    targetClassName={styles.target}
    draggingOverFrameClassName={styles.draggingOverContainer}
    draggingOverTargetClassName={styles.draggingOverTarget}
    onDrop={onDrop}
  />
)

PropertyRentRollExcelFileUploaderDropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
}

export default PropertyRentRollExcelFileUploaderDropZone
