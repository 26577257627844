import { Button, ButtonDesign, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { conditionTypes } from 'api/conditions/conditions'
import styles from 'components/domains/conditions/dialogs/condition-creation/ConditionCreationDialog.module.css'
import ConditionCreationTable from 'components/domains/conditions/dialogs/condition-creation/ConditionCreationTable'
import ConditionCreationValidationErrorPopover from 'components/domains/conditions/dialogs/condition-creation/ConditionCreationValidationErrorPopover'
import DialogContent from 'components/domains/properties/monitoring/settings/dialog/DialogContent'
import DialogGroup from 'components/domains/properties/monitoring/settings/dialog/DialogGroup'
import Dialog, {
  DialogPrimaryButton,
  DialogSecondaryButton,
  DialogSize,
} from 'components/ui/dialog/Dialog'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import useCreateConditions from 'hooks/services/conditions/useCreateConditions'
import { getDetailedErrorInformationFromErrorRows } from 'redux/slices/conditions/actions/helper/conditionsActionHelper'
import {
  reset as resetExternalConditionsState,
  setConditionRowsShowErrorToTrue,
  setRequirementRowsShowErrorToTrue,
} from 'redux/slices/conditions/conditionCreationSlice'
import { highlightConditionRow } from 'redux/slices/conditions/conditionsTableSlice'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'
const validationPopoverOpener = 'condition-creation-error-button'

const ConditionCreationDialog = ({ conditionType }) => {
  const {
    entityRef: { entityId: contextEntityId, entityType: contextEntityType },
  } = useContext(ConditionsContext)
  const { t: tNoPrefix } = useTranslation()
  const [isValidationErrorPopoverOpen, setIsValidationErrorPopoverOpen] = useState(false)
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.dialog.external-condition-creation',
  })
  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()
  const dispatch = useDispatch()
  const { mutate: createConditions } = useCreateConditions()
  const isCreationDialogOpen = useSelector(
    (state) => state.conditions.conditionsCreation.isCreationDialogOpen,
  )
  const conditionCreationErrors = useSelector(
    (state) => state.conditions.conditionsCreation.errorRows,
  )
  const currentConditions = useSelector((state) => state.conditions.conditionsCreation.conditions)
  const queryClient = useQueryClient()

  const onSuccessCreationCallback = useCallback(
    (data) => {
      queryClient.invalidateQueries([
        'conditions',
        conditionType,
        contextEntityType,
        contextEntityId,
      ])
      data.forEach(({ id: createdConditionId }) => {
        dispatch(highlightConditionRow(createdConditionId))
      })
      dispatch(resetExternalConditionsState())
      showToast({ children: t('success') })
    },
    [queryClient, conditionType, contextEntityId, contextEntityType, dispatch, showToast, t],
  )

  const onErrorCreationCallback = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('create.error'),
    })
  }, [showMessageBox, t])

  const dispatchResetExternalConditions = useCallback(() => {
    dispatch(resetExternalConditionsState())
  }, [dispatch])

  const handleOnCancelClicked = useCallback(() => {
    showMessageBox({
      titleText: t('message-box.title'),
      type: MessageBoxTypes.Warning,
      children: t('message-box.description'),
      actions: [
        <Button
          key={'button-discard'}
          design={ButtonDesign.Emphasized}
          onClick={dispatchResetExternalConditions}
        >
          {tNoPrefix('buttons.discard')}
        </Button>,
        <Button
          key="discard-cancel-button"
          data-action={MessageBoxActions.Cancel}
          design={ButtonDesign.Transparent}
        >
          {tNoPrefix('buttons.cancel')}
        </Button>,
      ],
    })
  }, [dispatchResetExternalConditions, t, showMessageBox, tNoPrefix])

  const onBeforeClose = useCallback(
    (event) => {
      if (event.detail.escPressed) {
        event.preventDefault()
        handleOnCancelClicked()
      }
    },
    [handleOnCancelClicked],
  )

  const errorRows = useSelector((state) => state.conditions.conditionsCreation.errorRows)

  const detailedErrorInformation = useMemo(
    () => getDetailedErrorInformationFromErrorRows({ errorRows }),
    [errorRows],
  )

  const isOnlyWarningErrors = useMemo(
    () =>
      detailedErrorInformation.every(
        ({
          additionalInformation: {
            error: { warning },
          },
        }) => warning,
      ),
    [detailedErrorInformation],
  )

  const isSaveBlocked = useMemo(
    () =>
      (!isEmpty(conditionCreationErrors) && !isOnlyWarningErrors) || currentConditions.length === 0,
    [conditionCreationErrors, isOnlyWarningErrors, currentConditions.length],
  )

  const saveData = useCallback(() => {
    const mappedConditionsForMutationCall = currentConditions.map(
      ({
        name: conditionName,
        type,
        category,
        refNumber: conditionRefNumber,
        approvalLevel,
        description: conditionDescription,
        requirements,
        references,
        assignee: conditionAssignee,
      }) => ({
        info: {
          name: conditionName,
          description: conditionDescription,
          assignee: conditionAssignee?.id,
          refNumber: isEmpty(conditionRefNumber) ? undefined : conditionRefNumber,
          category,
          type: { code: type },
          approvalLevel: approvalLevel ? { code: approvalLevel } : undefined,
        },
        references: !isNil(references?.entityType)
          ? {
              entityType: references.entityType,
              entityIds: references.entityIds || [],
            }
          : undefined,
        entityRef: { entityId: contextEntityId, entityType: contextEntityType },
        requirements:
          conditionType === conditionTypes.external
            ? requirements.map(
                ({
                  name: requirementName,
                  documentType,
                  assignee: requirementAssignee,
                  refNumber: requirementRefNumber,
                  description: requirementDescription,
                }) => ({
                  info: {
                    name: requirementName,
                    documentType,
                    assignee: requirementAssignee?.id,
                    refNumber: requirementRefNumber,
                    description: requirementDescription,
                  },
                }),
              )
            : undefined,
      }),
    )
    createConditions(
      { conditions: mappedConditionsForMutationCall, conditionType },
      { onSuccess: onSuccessCreationCallback, onError: onErrorCreationCallback },
    )
  }, [
    conditionType,
    createConditions,
    currentConditions,
    contextEntityId,
    contextEntityType,
    onErrorCreationCallback,
    onSuccessCreationCallback,
  ])

  const setConditionsAndRequirementsShowErrorToTrueInRedux = useCallback(() => {
    const mappedConditionErrors = detailedErrorInformation
      .filter((error) => error.isConditionError)
      .map(({ conditionIndex, fieldName }) => ({
        conditionIndex,
        fieldName,
      }))
    dispatch(setConditionRowsShowErrorToTrue({ conditionErrors: mappedConditionErrors }))
    const mappedRequirementErrors = detailedErrorInformation
      .filter((error) => !error.isConditionError)
      .map(({ conditionIndex, requirementIndex, fieldName }) => ({
        conditionIndex,
        requirementIndex,
        fieldName,
      }))
    dispatch(setRequirementRowsShowErrorToTrue({ requirementErrors: mappedRequirementErrors }))
  }, [detailedErrorInformation, dispatch])

  const handleOnSaveClicked = useCallback(() => {
    setConditionsAndRequirementsShowErrorToTrueInRedux()

    if (isSaveBlocked) {
      showMessageBox({
        type: MessageBoxTypes.Error,
        children: t('save.error'),
        actions: [MessageBoxActions.Close],
      })
      return
    }
    if (detailedErrorInformation.length > 0 && isOnlyWarningErrors) {
      showMessageBox({
        type: MessageBoxTypes.Confirm,
        children: t('confirmation.save'),
        actions: [
          <Button key="confirm-save" onClick={saveData} design={ButtonDesign.Emphasized}>
            {tNoPrefix('buttons.confirm')}
          </Button>,
          <Button key="confirm-cancel" design={ButtonDesign.Transparent}>
            {tNoPrefix('buttons.cancel')}
          </Button>,
        ],
      })
      return
    }
    saveData()
  }, [
    setConditionsAndRequirementsShowErrorToTrueInRedux,
    isSaveBlocked,
    detailedErrorInformation.length,
    isOnlyWarningErrors,
    saveData,
    showMessageBox,
    t,
    tNoPrefix,
  ])

  const onErrorButtonClick = useCallback(() => {
    setIsValidationErrorPopoverOpen(true)
  }, [setIsValidationErrorPopoverOpen])

  const errorAmount = useMemo(
    () => detailedErrorInformation.filter((row) => !!row.additionalInformation.showError).length,
    [detailedErrorInformation],
  )

  const leftFooterContent = useMemo(() => {
    if (errorAmount === 0) {
      return <></>
    }

    return (
      <Button
        id={validationPopoverOpener}
        icon="alert"
        className={isOnlyWarningErrors ? styles.warningButton : styles.errorButton}
        design={isOnlyWarningErrors ? ButtonDesign.Attention : ButtonDesign.Negative}
        onClick={onErrorButtonClick}
      >
        {errorAmount}
      </Button>
    )
  }, [errorAmount, isOnlyWarningErrors, onErrorButtonClick])

  return (
    <>
      <Dialog
        open={isCreationDialogOpen}
        onAfterClose={dispatchResetExternalConditions}
        onBeforeClose={onBeforeClose}
        headerText={t('title')}
        size={DialogSize.XL}
        className={styles.dialog}
        primaryButton={
          <DialogPrimaryButton onClick={handleOnSaveClicked}>
            {tNoPrefix('buttons.save')}
          </DialogPrimaryButton>
        }
        closeButton={
          <DialogSecondaryButton onClick={handleOnCancelClicked}>
            {tNoPrefix('buttons.cancel')}
          </DialogSecondaryButton>
        }
        leftFooterContent={leftFooterContent}
      >
        <DialogContent>
          <DialogGroup>
            <ConditionCreationTable conditionType={conditionType} />
          </DialogGroup>
        </DialogContent>
      </Dialog>
      {errorAmount > 0 && (
        <ConditionCreationValidationErrorPopover
          isOpen={isValidationErrorPopoverOpen}
          setIsOpen={setIsValidationErrorPopoverOpen}
          opener={validationPopoverOpener}
          detailedErrorInformation={detailedErrorInformation}
        />
      )}
    </>
  )
}

ConditionCreationDialog.propTypes = {
  conditionType: PropTypes.oneOf(Object.values(conditionTypes)),
}

export default ConditionCreationDialog
