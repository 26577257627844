import {
  Button,
  ButtonDesign,
  Modals,
  MessageBoxTypes,
  MessageBoxActions,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import { useGetAndAppendWorkingVersionSearchParam } from 'components/domains/deals/useGetAndAppendWorkingVersionSearchParam'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useDeleteCashflowScenario from 'hooks/services/deals/cashflow/useDeleteCashflowScenario'
import usePbbDeleteCashflowScenario from 'hooks/services/pbb-custom-service/useDeleteCashflowScenario'

const DealCashflowDetailsDeleteButton = ({
  dealUuid,
  dealDisplayId,
  externalScenarioId,
  cashflowScenarioUuid,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.detail.cashflow' })

  const showToast = Modals.useShowToast()
  const showMessageBox = useShowMessageBox()
  const showErrorMessageBox = useShowErrorMessageBox()

  const queryClient = useQueryClient()

  const navigate = useNavigate()
  const { appendWorkingVersionSearchParam } = useGetAndAppendWorkingVersionSearchParam()

  const onErrorHandler = async (error) => {
    const { errors: [errorResponse] = [] } = await error.response.json()
    showErrorMessageBox({ message: t('dialog.error.delete.text'), error: errorResponse })
  }

  const { mutate: pbbDeleteCashflowScenario } = usePbbDeleteCashflowScenario({
    onSuccess: () => {
      showToast({
        duration: 5000,
        children: t('details.toast.scenario-deletion-triggered'),
      })
      queryClient.invalidateQueries(['deals', dealUuid, 'cashflow-scenarios'])
      navigate(appendWorkingVersionSearchParam(`/deals/${dealDisplayId}/cashflows`))
    },
    onError: onErrorHandler,
  })

  const { mutate: deleteCashflowScenario } = useDeleteCashflowScenario({
    onSuccess: () => {
      showToast({
        duration: 5000,
        children: t('details.toast.scenario-deleted'),
      })
      queryClient.invalidateQueries(['deals', dealUuid, 'cashflow-scenarios'])
      navigate(appendWorkingVersionSearchParam(`/deals/${dealDisplayId}/cashflows`))
    },
    onError: onErrorHandler,
  })

  return (
    <>
      <Button
        id="delete-cashflow-scenario"
        design={ButtonDesign.Default}
        onClick={() =>
          showMessageBox(
            {
              type: MessageBoxTypes.Warning,
              titleText: t('dialog.delete.title'),
              children: (
                <>
                  {t('dialog.delete.text')}
                  {externalScenarioId && (
                    <>
                      <br />
                      <br />
                      {t('dialog.delete.text.info')}
                    </>
                  )}
                </>
              ),
              actions: [
                <Button
                  key="delete"
                  design={ButtonDesign.Emphasized}
                  onClick={() =>
                    externalScenarioId
                      ? pbbDeleteCashflowScenario({
                          dealUuid,
                          cashflowScenarioUuid,
                        })
                      : deleteCashflowScenario({ dealUuid, cashflowScenarioUuid })
                  }
                >
                  {t('dialog.delete.button.delete')}
                </Button>,
                MessageBoxActions.Cancel,
              ],
            },
            document.body,
          )
        }
      >
        {t('button.delete')}
      </Button>
    </>
  )
}

DealCashflowDetailsDeleteButton.propTypes = {
  dealUuid: PropTypes.string.isRequired,
  dealDisplayId: PropTypes.string.isRequired,
  externalScenarioId: PropTypes.string,
  cashflowScenarioUuid: PropTypes.string.isRequired,
}

export default DealCashflowDetailsDeleteButton
