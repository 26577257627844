import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { conditionsMassEditOperations } from 'api/conditions/conditions'
import ConditionSelectionHelperDialog from 'components/domains/conditions/condition-selection/ConditionSelectionHelperDialog'
import ConditionSelectionInput from 'components/domains/conditions/condition-selection/ConditionSelectionInput'
import MassEditOperationSelect from 'components/domains/conditions/dialogs/mass-editing/MassEditOperationSelect'

const RequirementsMassEditConditionSelect = ({ onChange, disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.requirements.table.dialogs.mass-edit',
  })

  const [showInputField, setShowInputField] = useState(false)
  const [isHelperDialogOpen, setIsHelperDialogOpen] = useState(false)
  const [hasConditionError, setHasConditionError] = useState(false)
  const [conditionName, setConditionName] = useState(undefined)

  const handleSelectedOperationChanged = useCallback(
    (selection) => {
      if (selection === conditionsMassEditOperations.keep) {
        setConditionName(undefined)
        setShowInputField(false)
        onChange({ operation: conditionsMassEditOperations.keep })
        return
      }
      onChange({ operation: conditionsMassEditOperations.replace, value: undefined })
      setShowInputField(true)
    },
    [onChange],
  )

  const handleConditionSelectionClicked = () => {
    setIsHelperDialogOpen(true)
  }

  const handleClearSelection = () => {
    setConditionName(undefined)
    setHasConditionError(true)
    onChange({ operation: conditionsMassEditOperations.replace, value: undefined })
  }

  const handleCancelConditionSelection = () => {
    if (isNil(conditionName)) {
      setHasConditionError(true)
    }
    setIsHelperDialogOpen(false)
  }

  const handleSelectionChanged = ({ condition }) => {
    if (isNil(condition)) {
      setHasConditionError(true)
      onChange({ operation: conditionsMassEditOperations.replace, value: undefined })
      return
    }
    setHasConditionError(false)
    setIsHelperDialogOpen(false)
    setConditionName(condition.info.name)
    onChange({
      operation: conditionsMassEditOperations.replace,
      value: {
        id: condition.id,
        businessObjectRef: condition.businessObjectRef,
      },
    })
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label>{t('labels.condition')}</Label>
      <MassEditOperationSelect
        idPrefix={'requirements-condition'}
        selectableOperations={[
          conditionsMassEditOperations.keep,
          conditionsMassEditOperations.replace,
        ]}
        onChange={handleSelectedOperationChanged}
        disabled={disabled}
      />
      {showInputField && (
        <>
          <ConditionSelectionInput
            name={conditionName}
            hasError={hasConditionError}
            onClick={handleConditionSelectionClicked}
            onClear={handleClearSelection}
            disabled={disabled}
          />
          {isHelperDialogOpen && (
            <ConditionSelectionHelperDialog
              isOpen={isHelperDialogOpen}
              onCancel={handleCancelConditionSelection}
              onSelect={handleSelectionChanged}
            />
          )}
        </>
      )}
    </FlexBox>
  )
}

RequirementsMassEditConditionSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default RequirementsMassEditConditionSelect
