import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/appendix/manual-checksheet/AnnualReviewManualCheckSheetTile.module.css'
import AnnualReviewCheckSheetTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/shared/checksheets/AnnualReviewCheckSheetTable'

const AnnualReviewAutomaticCheckSheetTile = ({ tileId, selectedBorrowerId, ...tileProps }) => {
  const translationPrefix = 'tiles.annual-review.appendix.checksheet.automatic'

  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  // includes a failsafe for frozen decision papers where these objects were not stringified before
  const currentData = useMemo(
    () =>
      !isNil(tileStatus?.data?.borrowers) && typeof tileStatus?.data?.borrowers === 'string'
        ? JSON.parse(tileStatus?.data?.borrowers ?? '')
        : [],
    [tileStatus?.data?.borrowers],
  )

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.wrapper}>
      <AnnualReviewCheckSheetTable
        {...tileProps}
        id="annualReviewAutomaticCheckSheetTable"
        translationPrefix={translationPrefix}
        selectedBorrowerId={selectedBorrowerId}
        currentData={currentData}
      />
    </FlexBox>
  )
}

AnnualReviewAutomaticCheckSheetTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  selectedBorrowerId: PropTypes.string.isRequired,
}

export default AnnualReviewAutomaticCheckSheetTile
