import camelize from 'camelize'
import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import regulatoryInformationSchema from 'components/domains/business-partners/tile/general-information/regulatoryInformation/regulatoryInformationSchema'
import { useRequest } from 'hooks/services/baseService'

const RETRIES = 3

/**
 * @param {object} params
 * @param {string} [params.businessPartnerId]
 */
const useRegulatoryInformation = ({ businessPartnerId }, requestOptions) => {
  const response = useRequest({
    path: `/businesspartners/${businessPartnerId}/regulatory-informations`,
    useCache: true,
    keys: ['businesspartners', businessPartnerId, 'regulatory-informations'],
    options: {
      enabled: !!businessPartnerId,
      retry: (failureCount, error) =>
        !(isNotFoundError(error) || isMissingPermissionError(error) || failureCount >= RETRIES),
    },
    requestOptions,
  })
  return {
    ...response,
    data: regulatoryInformationSchema.optional().parse(camelize(response.data)),
  }
}

export default useRegulatoryInformation
