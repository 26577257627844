import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreatePropertyPartner = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ entityUuid, partnerWithFunction }) => {
      const { data } = await post({
        path: `/properties/${entityUuid}/businesspartners`,
        body: {
          ...partnerWithFunction,
        },
      })
      return data
    },
    { onSuccess, onError },
  )
}

export default useCreatePropertyPartner
