import {
  ROW_TYPE_MONETARY_VALUE,
  DEFAULT_DISPLAY_ROW_HEIGHT,
  ROW_TYPE_PROPERTY,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/constants'
import mapNumberOfProperties from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/property-and-tenancy/mapNumberOfProperties'

const rowKeys = ['mainPropertyType', 'numberOfProperties', 'location', 'marketValue']

const mapRowData = ({ rowKey, responses, getCountryName, getPropertyTypeName, deals }) => {
  const createRow = (createObjectFunction) =>
    responses
      ?.map(({ data, isLoading, isFetching, isError }) =>
        createObjectFunction(data, { isLoading, isFetching, isError }),
      )
      .reduce((prev, curr) => Object.assign(prev, curr), {})

  switch (rowKey) {
    case 'mainPropertyType':
      return createRow(({ dealUuid, mainPropertyTypeCode }, requestStates) => ({
        [dealUuid]: {
          value: getPropertyTypeName(mainPropertyTypeCode),
          ...requestStates,
        },
      }))
    case 'numberOfProperties':
      return createRow(({ dealUuid, numberOfProperties }, requestStates) => ({
        [dealUuid]: {
          value: mapNumberOfProperties(deals, dealUuid, numberOfProperties),
          ...requestStates,
        },
      }))
    case 'location':
      return createRow(({ dealUuid, countryCodes }, requestStates) => ({
        [dealUuid]: {
          value: countryCodes?.map((countryCode) => getCountryName(countryCode)).join(', '),
          ...requestStates,
        },
      }))
    case 'marketValue':
      return createRow(({ dealUuid, marketValue }, requestStates) => ({
        [dealUuid]: {
          value: { amount: marketValue?.number, currency: marketValue?.currencyCode },
          ...requestStates,
        },
      }))
  }
}

const mapRowType = (rowKey) => {
  switch (rowKey) {
    case 'marketValue':
      return ROW_TYPE_MONETARY_VALUE

    case 'numberOfProperties':
      return ROW_TYPE_PROPERTY
  }
}

const mapMultipleAggregatedDealPortfolios = ({
  multipleAggregatedDealPortfolioResponses,
  getCountryName,
  getPropertyTypeName,
  deals,
  t,
}) =>
  rowKeys.map((rowKey) => {
    const rowData = mapRowData({
      rowKey,
      responses: multipleAggregatedDealPortfolioResponses,
      getCountryName,
      getPropertyTypeName,
      deals,
    })

    return {
      kpi: t(`rows.section.property-tenancy.${rowKey}`),
      rowHeight: DEFAULT_DISPLAY_ROW_HEIGHT,
      ...rowData,
      rowType: mapRowType(rowKey),
      rowHasError: Object.values(rowData).some((column) => column.isError),
    }
  })
export default mapMultipleAggregatedDealPortfolios
