import { z } from 'zod'

const cagTypeSchema = z.object({
  id: z.string(),
  name: z.string(),
  categoryId: z.string(),
  collateralProviderFunctionId: z.string(),
  isExternal: z.boolean(),
})

export const cagTypesSchema = z.object({
  types: cagTypeSchema.array(),
})
