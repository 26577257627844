import { Popover } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useId, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/tenancy/as-branding-or-franchise-provider/DealCell.module.css'
import Entity from 'components/ui/data/Entity'
import Link from 'components/ui/link/Link'
import useDeals from 'hooks/services/deals/useDeals'

const DealCell = ({ propertyUuid }) => {
  const id = useId()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.tenancy.as-branding-franchise-provider',
  })
  const [isOpen, setOpen] = useState(false)
  const { data } = useDeals({ filter: { propertyUuids: [propertyUuid] } })

  const deals = data?.deals

  if (!propertyUuid || !deals?.length) return null

  const dealsList = (
    <div className={styles.detailListContainer}>
      {deals.map(({ dealId, name }) => (
        <Entity
          className={styles.detailListEntities}
          key={dealId}
          name={name}
          id={dealId}
          link={`/deals/${dealId}`}
          openInNewTab
        />
      ))}
    </div>
  )
  if (deals.length === 1) {
    const dealId = deals[0].dealId
    const name = deals[0].name
    return <Entity key={dealId} name={name} id={dealId} link={`/deals/${dealId}`} openInNewTab />
  }

  const foundXDeals = t('found-x-deals', { numberOfDeals: deals.length })
  const openerId = `multiple-deals-${id}`

  const handleClick = (e) => {
    e.preventDefault()
    setOpen(!isOpen)
  }

  return (
    <>
      <Link id={openerId} onClick={handleClick}>
        {foundXDeals}
      </Link>
      {createPortal(
        <Popover
          className={`${styles.popover} ${styles.popoverLink}`}
          placementType="Right"
          verticalAlign="Center"
          open={isOpen}
          opener={openerId}
          headerText={foundXDeals}
          horizontalAlign="Center"
        >
          <div>{dealsList}</div>
        </Popover>,
        document.body,
      )}
    </>
  )
}

DealCell.propTypes = {
  propertyUuid: PropTypes.string,
}

export default DealCell
