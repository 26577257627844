import { useTranslation } from 'react-i18next'
import {
  AlignedColumn,
  CenterAlignedMultiColumn,
} from 'components/domains/properties/rent-roll/overview/PropertyRentRollOverviewColumns'

export const SEGMENT_AREA_DETAILS_COLUMN_NAMES = {
  NAME_AND_USAGE_TYPE: 'nameAndUsageType',
  LETTABLE_AREA: 'lettableArea',
  RENTED_AREA: 'rentedArea',
}

export const useSegmentAreaDetailsTableColumns = () => {
  const { t: tAreaDetails } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.area-details',
  })

  return [
    {
      columnKey: SEGMENT_AREA_DETAILS_COLUMN_NAMES.NAME_AND_USAGE_TYPE,
      renderColumnContent: () => (
        <AlignedColumn>{tAreaDetails('table.columns.name-and-usage-type')}</AlignedColumn>
      ),
    },
    {
      columnKey: SEGMENT_AREA_DETAILS_COLUMN_NAMES.LETTABLE_AREA,
      renderColumnContent: () => (
        <CenterAlignedMultiColumn
          title={tAreaDetails('table.columns.lettable-area')}
          subTitles={[
            tAreaDetails('table.columns.total'),
            tAreaDetails('table.columns.lettable-share'),
            tAreaDetails('table.columns.number'),
          ]}
        />
      ),
    },
    {
      columnKey: SEGMENT_AREA_DETAILS_COLUMN_NAMES.RENTED_AREA,
      renderColumnContent: () => (
        <CenterAlignedMultiColumn
          title={tAreaDetails('table.columns.rented-area')}
          subTitles={[
            tAreaDetails('table.columns.total'),
            tAreaDetails('table.columns.rented-share'),
            tAreaDetails('table.columns.number'),
          ]}
        />
      ),
    },
  ]
}
