import { Input, InputType } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import DisbursementTileDisplayOrEditItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/request/disbursement/disbursement/fields/DisbursementTileDisplayOrEditItem'

const DisbursementTileInputField = ({ label, value, isEditMode, onChange, isFirstItem }) => {
  const handleOnChange = useCallback(
    ({ target: { value: text } }) => {
      onChange(text)
    },
    [onChange],
  )

  const renderEditComponent = useCallback(
    () => <Input type={InputType.Text} value={value} onInput={handleOnChange} />,
    [value, handleOnChange],
  )

  return (
    <DisbursementTileDisplayOrEditItem
      label={label}
      value={value}
      renderEditComponent={renderEditComponent}
      isEditMode={isEditMode}
      isFirstItem={isFirstItem}
    />
  )
}

DisbursementTileInputField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isEditMode: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isFirstItem: PropTypes.bool,
}

export default DisbursementTileInputField
