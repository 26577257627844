import { MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isEventClosed } from 'api/events/status'
import MultipleCashFlowAssumptionsTileSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/cashflow-analysis/MultipleCashFlowAssumptionsTileSelectionWrapper'
import MultipleCashFlowDebtAndEquityProjectionTileSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/cashflow-analysis/MultipleCashFlowDebtAndEquityProjectionTileSelectionWrapper'
import MultipleCashFlowFinancialRatiosTileSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/cashflow-analysis/MultipleCashFlowFinancialRatiosTileSelectionWrapper'
import MultipleCashFlowVacancyProjectionTileSelectionWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/vacancy-projection/MultipleCashFlowVacancyProjectionTileSelectionWrapper'
import useAnnualReviewDealOverviewSelectedDeal from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/utils/useAnnualReviewDealOverviewSelectedDeal'
import { ErrorDataUnavailableInContent } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/loading/RequestStateResolver'
import DecisionPaperAutomaticTileWithMetaDataRenderer from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/with-meta-data/DecisionPaperAutomaticTileWithMetaDataRenderer'
import CashFlowTileHeaderSelectActions from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/with-meta-data/cash-flow/CashFlowTileHeaderSelectActions'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import { useEditMultiTilesMetaData } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/meta-data/useEditMultiTilesMetaData'
import useTileMetaData from 'hooks/services/business-events-and-tasks/decision-papers/tiles/meta-data/useTileMetaData'
import useIsDecisionPaperTileExpanded from 'hooks/services/business-events-and-tasks/decision-papers/tiles/useIsDecisionPaperTileExpanded'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const TILE_CODES = {
  DEAL_SELECTION: 'DEAL_SELECTION_TILE',
  MULTIPLE_TENANCY_RENT_ROLL_VACANCY_PROJECTION: 'MULTIPLE_TENANCY_RENT_ROLL_VACANCY_PROJECTION',
  MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_FINANCIAL_RATIOS_BASE_CASE:
    'MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_FINANCIAL_RATIOS_BASE_CASE',
  MULTIPLE_CASH_FLOW_ANALYSIS_CASH_CASH_FLOW_DEBT_EQUITY_PROJECTION_BASE_CASE:
    'MULTIPLE_CASH_FLOW_ANALYSIS_CASH_CASH_FLOW_DEBT_EQUITY_PROJECTION_BASE_CASE',
  MULTIPLE_CASH_FLOW_ANALYSIS_CASH_CASH_FLOW_ASSUMPTIONS_BASE_CASE:
    'MULTIPLE_CASH_FLOW_ANALYSIS_CASH_CASH_FLOW_ASSUMPTIONS_BASE_CASE',
  MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_FINANCIAL_RATIOS_STRESS_CASE:
    'MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_FINANCIAL_RATIOS_STRESS_CASE',
  MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_DEBT_EQUITY_PROJECTION_STRESS_CASE:
    'MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_DEBT_EQUITY_PROJECTION_STRESS_CASE',
  MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_ASSUMPTIONS_STRESS_CASE:
    'MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_ASSUMPTIONS_STRESS_CASE',
}

const DealOverviewTileLoadingWrapper = ({
  tileId,
  title: tileTitle,
  tileCode,
  viewConfig,
  isPdfView = false,
  currentVersion,
  synchronizationStatus,
  isEditableInMinorVersion,
  lastAvailableVersion,
  subsectionTilesIds,
  ...tileProps
}) => {
  const {
    event: { id: eventId, status: eventStatus },
  } = useContext(BusinessEventsAndTasksContext)

  const { selectedDealUuid } = useAnnualReviewDealOverviewSelectedDeal(tileId)

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.tiles',
  })
  const queryClient = useQueryClient()
  const showMessageBox = Modals.useShowMessageBox()

  const {
    data: { meta_data } = {},
    isLoading: isTileMetaDataLoading,
    isError: isTileMetaDataError,
  } = useTileMetaData({ eventId, tileId })

  const tileMetaData = useMemo(() => meta_data, [meta_data])

  const { mutate: selectTilesMetaData } = useEditMultiTilesMetaData()

  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const [metaDataContentCashFlow, setMetaDataContentCashFlow] = useState()
  const metaDataContentCashFlowCurrentDeal = useMemo(
    () => metaDataContentCashFlow?.[selectedDealUuid],
    [metaDataContentCashFlow, selectedDealUuid],
  )
  const setMetaDataContentCashFlowCurrentDeal = useCallback(
    (data) => {
      setMetaDataContentCashFlow({
        ...metaDataContentCashFlow,
        [selectedDealUuid]: data,
      })
    },
    [metaDataContentCashFlow, selectedDealUuid],
  )

  useEffect(() => {
    setMetaDataContentCashFlow(tileMetaData)
  }, [tileMetaData])

  const { isExpanded, expand } = useIsDecisionPaperTileExpanded({
    tileId,
    isPdfView,
    isDefaultExpanded: viewConfig.isDefaultExpanded,
  })

  const hasChanges = useMemo(
    () => !isEqual(metaDataContentCashFlowCurrentDeal, tileMetaData?.[selectedDealUuid]),
    [metaDataContentCashFlowCurrentDeal, selectedDealUuid, tileMetaData],
  )

  const onSaveError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('meta-data.save.error.description'),
    })
  }, [showMessageBox, t])

  const onSaveSuccess = useCallback(() => {
    subsectionTilesIds?.subsectionTilesIds?.forEach((subsectionTileId) =>
      queryClient.invalidateQueries([
        'events',
        eventId,
        'decision-paper',
        'versions',
        'current',
        'tiles',
        subsectionTileId,
        'meta-data',
      ]),
    )
  }, [eventId, queryClient, subsectionTilesIds?.subsectionTilesIds])

  const handleSaveSelectedScenario = useCallback(() => {
    const tileIds = subsectionTilesIds?.subsectionTilesIds

    const preparedMetaData = {
      ...metaDataContentCashFlow,
      [selectedDealUuid]: metaDataContentCashFlowCurrentDeal,
    }

    const metaData = tileIds?.map((tileIdToUpdate) => ({
      tile_id: tileIdToUpdate,
      meta_data: preparedMetaData,
    }))

    selectTilesMetaData(
      { eventId, metaData },
      {
        onSuccess: onSaveSuccess,
        onError: onSaveError,
      },
    )
  }, [
    eventId,
    metaDataContentCashFlow,
    metaDataContentCashFlowCurrentDeal,
    onSaveError,
    onSaveSuccess,
    selectTilesMetaData,
    selectedDealUuid,
    subsectionTilesIds?.subsectionTilesIds,
  ])

  const renderContent = useCallback(() => {
    if (!isEmpty(tileStatus.data?.dealsData)) {
      switch (tileCode) {
        case TILE_CODES.MULTIPLE_TENANCY_RENT_ROLL_VACANCY_PROJECTION:
          return (
            <MultipleCashFlowVacancyProjectionTileSelectionWrapper
              tileId={tileId}
              isPdfView={isPdfView}
              {...tileProps}
            />
          )
        case TILE_CODES.MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_FINANCIAL_RATIOS_STRESS_CASE:
        case TILE_CODES.MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_FINANCIAL_RATIOS_BASE_CASE:
          return (
            <MultipleCashFlowFinancialRatiosTileSelectionWrapper
              tileId={tileId}
              isPdfView={isPdfView}
              {...tileProps}
            />
          )
        case TILE_CODES.MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_DEBT_EQUITY_PROJECTION_STRESS_CASE:
        case TILE_CODES.MULTIPLE_CASH_FLOW_ANALYSIS_CASH_CASH_FLOW_DEBT_EQUITY_PROJECTION_BASE_CASE:
          return (
            <MultipleCashFlowDebtAndEquityProjectionTileSelectionWrapper
              tileId={tileId}
              isPdfView={isPdfView}
              {...tileProps}
            />
          )
        case TILE_CODES.MULTIPLE_CASH_FLOW_ANALYSIS_CASH_FLOW_ASSUMPTIONS_STRESS_CASE:
        case TILE_CODES.MULTIPLE_CASH_FLOW_ANALYSIS_CASH_CASH_FLOW_ASSUMPTIONS_BASE_CASE:
          return (
            <MultipleCashFlowAssumptionsTileSelectionWrapper
              tileId={tileId}
              isPdfView={isPdfView}
              {...tileProps}
            />
          )
      }
    } else {
      return (
        <EmptyCardContent
          title={t('annual-review.deal-overview.no-deal.title')}
          subtitle={t('annual-review.deal-overview.no-deal.subtitle')}
        />
      )
    }
  }, [isPdfView, t, tileCode, tileId, tileProps, tileStatus.data?.dealsData])

  const cashFlowSelectAction = useMemo(
    () =>
      !isEventClosed(eventStatus) && (
        <CashFlowTileHeaderSelectActions
          expand={expand}
          isExpanded={isExpanded}
          hasChanges={hasChanges}
          handleSaveSelectedScenario={handleSaveSelectedScenario}
          dealUuid={selectedDealUuid}
          metaDataContent={metaDataContentCashFlowCurrentDeal}
          setMetaDataContent={setMetaDataContentCashFlowCurrentDeal}
          lastSelectedScenario={tileMetaData?.[selectedDealUuid]}
        />
      ),
    [
      eventStatus,
      expand,
      isExpanded,
      hasChanges,
      handleSaveSelectedScenario,
      selectedDealUuid,
      metaDataContentCashFlowCurrentDeal,
      setMetaDataContentCashFlowCurrentDeal,
      tileMetaData,
    ],
  )

  return (
    <DecisionPaperAutomaticTileWithMetaDataRenderer
      tileId={tileId}
      tileCode={tileCode}
      titleText={tileTitle}
      viewConfig={viewConfig}
      isPdfView={isPdfView}
      currentVersion={currentVersion}
      synchronizationStatus={synchronizationStatus}
      isEditableInMinorVersion={isEditableInMinorVersion}
      lastAvailableVersion={lastAvailableVersion}
      actions={cashFlowSelectAction}
    >
      <RequestStateResolver
        isLoading={
          isTileMetaDataLoading ||
          !tileStatus ||
          tileStatus.isLoading ||
          (!tileStatus.data && !tileStatus.isError)
        }
        isError={isTileMetaDataError || (!!tileStatus && tileStatus.isError)}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        renderContent={renderContent}
        center={true}
      />
    </DecisionPaperAutomaticTileWithMetaDataRenderer>
  )
}

DealOverviewTileLoadingWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
  tileCode: PropTypes.string.isRequired,
  viewConfig: PropTypes.shape({
    columnSpan: PropTypes.number.isRequired,
    isDefaultExpanded: PropTypes.bool.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool,
  currentVersion: PropTypes.string.isRequired,
  synchronizationStatus: PropTypes.string.isRequired,
  isEditableInMinorVersion: PropTypes.bool.isRequired,
  lastAvailableVersion: PropTypes.string.isRequired,
  subsectionTilesIds: PropTypes.shape({
    subsectionCode: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    subsectionTilesIds: PropTypes.array,
  }),
}

export default DealOverviewTileLoadingWrapper
