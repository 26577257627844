import camelize from 'camelize'
import { useMemo } from 'react'
import { useInfiniteRequest } from 'hooks/services/baseService'

const defaultSorting = {
  sortBy: 'current_due_date',
  orderBy: 'asc',
}

/*
  useTasks takes 3 arguments:
  - paging: The paging arguments for limiting the number of results. if undefined or empty object, all results will be returned
    - offset: Number where to start, defaults to 0
    - limit: Number of results to receive
  - sorting: The sort arguments for sorting the results based on a specific criteria. If undefined or empty object, sorting will happen
      in ascending order by the sortKey.
    - sortKey: The key of the response on which sorting will happen
    - order: The order of the response. Defaults to ascending.
  - filter: The filter arguments for filtering the response. Per default no filter is happening.
    - searchValue: The searchString to filter specific values.
    - taskStatus: The status of the task to filter for
    - mandatory: Whether to filter for mandatory tasks or not. If mandatory === 'mandatory' will filter for only mandatory tasks. For all other values will filter for non-mandatory
    - dueDateRange: An ISO date range of a start and an end date to filter the dueDates (including) for.
*/
export const useTasks = (
  { sortBy, orderBy } = defaultSorting,
  {
    searchValue,
    taskStatus,
    eventStatus,
    taskAssigneeId,
    mandatory,
    currentDueDateRange,
    originalDueDateRange,
    eventEntityRefType,
    eventEntityRef,
  } = {},
  { limit } = {},
  options,
) => {
  const queryParams = new URLSearchParams()

  const cacheKeys = [
    'events',
    'tasks',
    `sortBy-${sortBy}-orderBy-${orderBy}`,
    searchValue,
    taskStatus,
    eventStatus,
    currentDueDateRange,
    originalDueDateRange,
    eventEntityRefType,
    eventEntityRef,
    limit,
  ]

  if (sortBy) {
    queryParams.append('sort_by', sortBy)
    queryParams.append('order_by', orderBy ? orderBy : 'asc')
  }
  if (searchValue) queryParams.append('search', searchValue)
  if (taskStatus) queryParams.append('task_status', taskStatus)
  if (eventStatus) queryParams.append('event_status', eventStatus)
  if (taskAssigneeId) queryParams.append('task_assignee', taskAssigneeId)
  if (mandatory) queryParams.append('mandatory', mandatory === 'mandatory')
  if (eventEntityRefType) queryParams.append('event_entity_ref_type', eventEntityRefType)
  if (currentDueDateRange) {
    const dueDateFrom = currentDueDateRange.split(' - ')[0]
    const dueDateUntil = currentDueDateRange.split(' - ')[1]
    queryParams.append('current_due_date_from', dueDateFrom)
    queryParams.append('current_due_date_until', dueDateUntil)
  }
  if (originalDueDateRange) {
    const dueDateFrom = originalDueDateRange.split(' - ')[0]
    const dueDateUntil = originalDueDateRange.split(' - ')[1]
    queryParams.append('original_due_date_from', dueDateFrom)
    queryParams.append('original_due_date_until', dueDateUntil)
  }
  if (eventEntityRef) {
    queryParams.append('event_entity_ref_id', eventEntityRef)
  }

  const result = useInfiniteRequest({
    path: '/events/tasks',
    queryParams,
    useCache: true,
    limit,
    keys: cacheKeys,
    options,
  })

  const processedResponse = useMemo(() => {
    if (!result.data) return undefined
    return camelize(
      result.data.pages.reduce((acc, { tasks, total }) => ({
        tasks: [...acc.tasks, ...tasks],
        total,
      })),
    )
  }, [result])

  return { ...result, data: processedResponse }
}
