import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useDecisionStagesVoteTypes = ({ eventId, decisionStageId } = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/events/${eventId}/decision-stages/${decisionStageId}/vote-types`,
      useCache: true,
      keys: ['events', eventId, 'decision-stages', decisionStageId, 'vote-types'],
    }),
  )

export default useDecisionStagesVoteTypes
