import { Modals, Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { conditionsEntityTypes } from 'api/conditions/conditions'
import TransferExternalConditionDialog from 'components/domains/conditions/overview/buttons/external-conditions/dialogs/TransferExternalConditionDialog'
import {
  useShowMessageBox,
  MessageBoxActions,
  MessageBoxTypes,
} from 'components/ui/message-box/MessageBox'
import useTransferExternalConditions from 'hooks/services/conditions/useTransferExternalConditions'
import useDealMini from 'hooks/services/deals/useDealMini'
import { ConditionsContext } from 'routes/conditions/ConditionsContext'

const TransferExternalConditionButton = ({ disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.overview.buttons.transfer-condition',
  })
  const {
    entityRef: { entityId },
  } = useContext(ConditionsContext)

  const { data: dealData } = useDealMini(entityId)

  const [selectedBusinessPartnerId, setSelectedBusinessPartnerId] = useState(undefined)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  useEffect(() => {
    setSelectedBusinessPartnerId(dealData?.borrowerBpId)
  }, [dealData?.borrowerBpId])

  const showToast = Modals.useShowToast()
  const showMessageBox = useShowMessageBox()

  const handleOnTransferSuccess = useCallback(() => {
    setIsDialogOpen(false)
    setSelectedBusinessPartnerId(undefined)
    showToast({ children: t('success.text') })
  }, [setIsDialogOpen, setSelectedBusinessPartnerId, showToast, t])

  const handleOnTransferError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      titleText: t('error.title'),
      children: t('error.text'),
      actions: [MessageBoxActions.Close],
    })
  }, [showMessageBox, t])

  const { mutate: transferConditions } = useTransferExternalConditions({
    onSuccess: handleOnTransferSuccess,
    onError: handleOnTransferError,
  })

  const selectedConditionIds = useSelector(
    (state) => state.conditions.conditionsTable.selectedRows.selectedConditionIds,
  )

  const isTransferButtonDisabled = useMemo(
    () => disabled || isEmpty(selectedConditionIds),
    [disabled, selectedConditionIds],
  )

  const handleOnTransferButtonClick = useCallback(() => setIsDialogOpen(true), [setIsDialogOpen])
  const handleOnBusinessPartnerChanged = useCallback(
    (businessPartnerId) => {
      setSelectedBusinessPartnerId(businessPartnerId)
    },
    [setSelectedBusinessPartnerId],
  )
  const handleOnConfirmButtonClicked = useCallback(() => {
    transferConditions({
      conditionIds: Object.keys(selectedConditionIds),
      entityId: selectedBusinessPartnerId,
      entityType: conditionsEntityTypes.businessPartner,
    })
  }, [selectedBusinessPartnerId, selectedConditionIds, transferConditions])

  return (
    <>
      <Button
        design={ButtonDesign.Transparent}
        disabled={isTransferButtonDisabled}
        onClick={handleOnTransferButtonClick}
      >
        {t('button')}
      </Button>
      <TransferExternalConditionDialog
        selectedBusinessPartnerId={selectedBusinessPartnerId ?? dealData?.borrowerBpId}
        isConfirmDisabled={isNil(selectedBusinessPartnerId)}
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        onChange={handleOnBusinessPartnerChanged}
        onConfirm={handleOnConfirmButtonClicked}
      />
    </>
  )
}

TransferExternalConditionButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
}

export default TransferExternalConditionButton
