import z from 'zod'

const staffMemberSchema = z
  .object({
    searchScore: z.number().min(0).max(1),
    id: z.string(),
    fullName: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    team: z.string(),
    email: z.string(),
    phone: z.string(),
    mobile: z.string(),
    location: z.string(),
  })
  .optional()

/** @typedef {import('zod').TypeOf<typeof staffMemberSchema>} StaffMember */

export const staffMembersSchema = z
  .object({ staffMembers: z.array(staffMemberSchema.unwrap()) })
  .optional()

/** @typedef {import('zod').TypeOf<typeof staffMembersSchema>} StaffMembers */

export default staffMemberSchema
