import { ValueState } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/deals/deal-cashflow/input-parameters/DealCashflowInputParametersValueInput.module.css'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'

const dealCashflowInputParametersValueInputProps = {
  cell: PropTypes.object.isRequired,
  parameter: PropTypes.object.isRequired,
  setEditData: PropTypes.func.isRequired,
  valueState: PropTypes.oneOf(Object.values(ValueState)),
}

const DealCashflowInputParametersValueInput = ({
  cell,
  parameter,
  setEditData = () => {},
  valueState = ValueState.None,
  ...props
}) => {
  if (!parameter.ccpdId || parameter.ccpdId === 'new') return <div className={styles.placeholder} />

  const { invalid, coId } = cell?.row?.original ?? {}
  const onChangeParameterValue = (value) => {
    setEditData({
      [coId]: {
        parameters: {
          [parameter.ccpdId]: { value },
        },
      },
    })
  }

  return (
    <FormattedNumberInput
      {...props}
      value={parameter.value}
      disabled={invalid || parameter.isSaveLoading}
      onChange={onChangeParameterValue}
      valueState={parameter.value < 0 ? valueState : ValueState.None}
      minimumFractionDigits={parameter.decimalPlaces}
      maximumFractionDigits={parameter.decimalPlaces}
    />
  )
}

DealCashflowInputParametersValueInput.propTypes = dealCashflowInputParametersValueInputProps

export default DealCashflowInputParametersValueInput
