import { ObjectStatus } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getObjectStatusForDecisionStageStatus } from 'components/domains/business-events-and-tasks/decision-paper/tiles/check-sheet/decision-overview/shared/decisionProcessApi'

const DecisionProcessOverviewTableStatusCell = ({ stageStatus }) => {
  const { t: tNoPrefix } = useTranslation('decisionPaper')
  const objectStatus = useMemo(
    () => getObjectStatusForDecisionStageStatus(stageStatus),
    [stageStatus],
  )

  return (
    <ObjectStatus inverted state={objectStatus.objectStatus}>
      {tNoPrefix(objectStatus.translationKey)}
    </ObjectStatus>
  )
}

DecisionProcessOverviewTableStatusCell.propTypes = {
  stageStatus: PropTypes.string.isRequired,
}

export default DecisionProcessOverviewTableStatusCell
