import { z } from 'zod'

const entityRefSchema = z.object({
  id: z.string(),
  type: z.string(),
})

const versionSchema = z.object({
  guid: z.string(),
  isWorkVersion: z.boolean(),
  versionExt: z.string(),
  version: z.string(),
})

const documentSchema = z.object({
  fileExtension: z.string(),
  fileName: z.string(),
  id: z.string(),
  link: z.string(),
  keyDate: z.string().optional(),
  modificationTime: z.string().datetime(),
  currentVersion: z.string(),
  name: z.string(),
  type: z.string(),
  /** If exists: Email of the creator, otherwise an username */
  creator: z.string(),
  entityRefs: z.array(entityRefSchema),
  versions: z.array(versionSchema),
})

/** @typedef {import('zod').TypeOf<typeof documentSchema>} Document */

const baseSchema = z.object({
  id: z.string(),
  name: z.string(),
  documents: z.array(documentSchema),
})

// HINT: [source](https://zod.dev/?id=recursive-types)
/** @typedef {import('zod').infer<typeof baseSchema> & {subFolders: Documents[]}} Documents */
/** @type {import('zod').Schema<Documents>} */
const documentsSchema = baseSchema.extend({
  subFolders: z.lazy(() => documentsSchema.array()),
})

export default documentsSchema
