import camelize from 'camelize'
import {
  CHILD_TO_PARENT_ENTITY_MAPPING,
  ENTITIES,
  ENTITY_CHILD_FIELD_MAPPING,
  ENTITY_ID_TYPES,
  ENTITY_LINK_TYPES_MAPPING,
  ENTITY_TYPE_PARENT_ACTIONS,
  LINK_TYPES,
} from 'components/domains/deals/change-overview/constants'
import useChangedInformationName from 'hooks/services/deals/change-overview/useChangedInformationName'
import useFinancingMini from 'hooks/services/deals/financing/useFinancingMini'

const useChangedInformationCellData = ({ dealUuid, dealDisplayId, changeLog }) => {
  const { isLoading: isLoadingTranches, data: tranchesData } = useFinancingMini(dealUuid)

  const tranches =
    tranchesData?.tranchesMinis?.map((tranche) => ({
      technicalId: tranche.trancheId,
      displayId: tranche.displayId,
      name: tranche.trancheName,
    })) ?? []

  const ENTITY_TYPE_DISPLAY_ID_MAPPING = {
    tranche: tranches,
  }

  // 1. Exceptional child entities are change log messages, where the entity type is a child of another entity, but has the
  // same entity id in the message
  const exceptionalChildEntities = [
    ENTITIES.CASHFLOW_SCENARIO_INPUT_PARAMETERS,
    ENTITIES.CASHFLOW_SCENARIO_RESULTS_AND_USED_PARAMETERS,
  ]

  /**
   *  This method is called to get the technical id for the hooks, to resolve the entity name of the changed entity.
   */
  const getTechnicalId = (entityType) => {
    // 2. When the entity type in the change log and the derived one are equal OR the change log is an exceptional child entity,
    // then we want the parent entity id
    // Exception: The field of the changed entity type is marked as child
    const isChangedFieldEntityChild = ENTITY_CHILD_FIELD_MAPPING?.[changeLog?.entityType]?.some(
      (field) => changeLog?.changedFields?.includes(field),
    )
    if (
      !isChangedFieldEntityChild &&
      (changeLog?.entityType === entityType ||
        exceptionalChildEntities.includes(changeLog?.entityType))
    ) {
      return changeLog?.entityId
    }
    // 3. Otherwise, we get the parent entity id from the references object
    const technicalId = changeLog?.references?.[camelize(entityType)]
    const noIdsFound = !technicalId || technicalId.length === 0
    if (noIdsFound) {
      return null
    }
    const multipleIdsFound = technicalId.length > 1
    if (multipleIdsFound) {
      return null
    }
    return technicalId[0]
  }

  const getDisplayIdOrNull = (entityType, technicalId) => {
    const ids = ENTITY_TYPE_DISPLAY_ID_MAPPING[entityType]
    const filteredIds = ids?.filter((id) => id.technicalId === technicalId)
    if (filteredIds?.length === 1) {
      return filteredIds[0]?.displayId
    } else {
      return null
    }
  }

  const getIdsByEntityType = (entityType) => {
    if (entityType === ENTITIES.DEAL) {
      return {
        technicalId: dealUuid,
        displayId: dealDisplayId,
      }
    }
    const technicalId = getTechnicalId(entityType)
    if (ENTITY_LINK_TYPES_MAPPING[entityType]) {
      return {
        technicalId,
        displayId: getDisplayIdOrNull(entityType, technicalId),
      }
    }
  }

  const parentEntityType =
    ENTITY_TYPE_PARENT_ACTIONS[changeLog?.entityType]?.actions?.[changeLog?.action]?.parent ??
    CHILD_TO_PARENT_ENTITY_MAPPING[changeLog?.entityType]

  const entityType = parentEntityType ?? changeLog?.entityType

  const technicalAndDisplayId = getIdsByEntityType(entityType)

  const { isLoading: isLoadingChangedInformationName, data: entityName } =
    useChangedInformationName({
      dealUuid,
      entityType: entityType,
      entityId: technicalAndDisplayId?.technicalId,
      tranches,
    })

  const parsedLink = changeLog?.pageLink.replace(/\{([\w-]+)\}/g, (_, p1) => {
    const entityId = getIdsByEntityType(p1)
    if (entityId === null || entityId === undefined) {
      return '{}'
    } else {
      return ENTITY_LINK_TYPES_MAPPING[p1] === ENTITY_ID_TYPES.DISPLAY
        ? entityId.displayId
        : entityId.technicalId
    }
  })

  const getChangedInformationType = () => {
    if (changeLog?.entityType === 'deal') {
      return LINK_TYPES.PAGE
    }
    return ENTITY_LINK_TYPES_MAPPING[changeLog?.entityType] || parentEntityType
      ? LINK_TYPES.ENTITY
      : LINK_TYPES.PAGE
  }

  const getChangedInformationLink = () => {
    if (!parsedLink || parsedLink.includes('{') || parsedLink.includes('}')) {
      return null
    }
    return {
      type: getChangedInformationType(),
      parsedLink,
    }
  }

  const isLoading = isLoadingTranches || isLoadingChangedInformationName
  if (isLoading) {
    return {
      isLoading: true,
      data: null,
    }
  }

  return {
    isLoading,
    data: {
      link: getChangedInformationLink(),
      entity: {
        type: entityType,
        name: entityName,
        technicalId: technicalAndDisplayId?.technicalId,
        displayId: technicalAndDisplayId?.displayId,
      },
    },
  }
}

export default useChangedInformationCellData
