import { ButtonDesign, FlexBox, FlexBoxDirection, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  valuationRequestMultiEditOperations,
  valuationStatus,
} from 'api/property/valuation/valuationRequests'
import styles from 'components/domains/properties/valuation/mass-edit/ValuationRequestMultiEditDialog.module.css'
import ValuationRequestMultiEditNoteField from 'components/domains/properties/valuation/mass-edit/fields/ValuationRequestMultiEditNoteField'
import ValuationRequestMultiEditReasonField from 'components/domains/properties/valuation/mass-edit/fields/ValuationRequestMultiEditReasonField'
import ValuationRequestMultiEditStatusField from 'components/domains/properties/valuation/mass-edit/fields/ValuationRequestMultiEditStatusField'
import ValuationRequestMultiEditValuationDateField from 'components/domains/properties/valuation/mass-edit/fields/ValuationRequestMultiEditValuationDateField'
import ValuationRequestMultiEditValuationDueDateField from 'components/domains/properties/valuation/mass-edit/fields/ValuationRequestMultiEditValuationDueDateField'
import ValuationRequestMultiEditValuationTypeField from 'components/domains/properties/valuation/mass-edit/fields/ValuationRequestMultiEditValuationTypeField'
import ValuationRequestMultiEditValuerField from 'components/domains/properties/valuation/mass-edit/fields/ValuationRequestMultiEditValuerField'
import calculateIntersectionOfValuationTypesForReasons from 'components/domains/properties/valuation/mass-edit/helper/calculateIntersectionOfValuationTypesForReasons'
import LoadingButton from 'components/ui/button/LoadingButton'
import Dialog, { DialogSecondaryButton, DialogSize } from 'components/ui/dialog/Dialog'
import { useShowMessageBox, MessageBoxTypes } from 'components/ui/message-box/MessageBox'
import usePropertyValuationRequestMassEdit from 'hooks/services/properties/valuations/usePropertyValuationRequestMassEdit'

const initialEditedState = {
  reason: undefined,
  valuationType: undefined,
  valuer: undefined,
  status: undefined,
  note: undefined,
  dueDate: undefined,
  valuationDate: undefined,
}

const ValuationRequestMultiEditDialog = ({
  valuationRequests,
  selectedValuationRequestIds,
  valuationReasons,
  onAfterClose,
  isOpen,
  ...dialogProps
}) => {
  const dialogRef = useRef()
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.properties.valuation.multi-edit',
  })
  const [editedState, setEditedState] = useState(initialEditedState)

  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()
  const { mutate: updateValuationRequests, isLoading } = usePropertyValuationRequestMassEdit()

  const selectedValuationRequests = useMemo(
    () => valuationRequests.filter(({ id }) => selectedValuationRequestIds.includes(id)),
    [selectedValuationRequestIds, valuationRequests],
  )
  const someValuationInCompletedStatus = useMemo(
    () =>
      selectedValuationRequests.some(
        ({ info: { status } }) => status === valuationStatus.completed,
      ),
    [selectedValuationRequests],
  )
  const isAllowOnlyCreatedStatus = useMemo(
    () =>
      selectedValuationRequests.some(
        ({ info: { status } }) =>
          status === valuationStatus.draft || status === valuationStatus.externalTransferFailed,
      ),
    [selectedValuationRequests],
  )

  const isStatusDraftNotAllowed = useMemo(
    () =>
      selectedValuationRequests.some(
        ({ info: { status } }) =>
          status === valuationStatus.created || status === valuationStatus.instructed,
      ),
    [selectedValuationRequests],
  )

  const onAfterCloseInternal = useCallback(() => {
    setEditedState(initialEditedState)
    onAfterClose()
  }, [onAfterClose])

  const onSaveError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('save.error'),
    })
  }, [showMessageBox, t])

  const onSaveSuccess = useCallback(() => {
    queryClient.invalidateQueries(['properties', 'valuation-requests'])
    showToast({ children: tNoPrefix('toast.changes-saved') })
    dialogRef.current?.close()
  }, [queryClient, showToast, tNoPrefix])

  const onSave = useCallback(() => {
    updateValuationRequests(
      {
        valuationRequestIds: selectedValuationRequestIds,
        reason: editedState.reason,
        valuationType: editedState.valuationType,
        status: editedState.status,
        note: editedState.note,
        reportDate: editedState.valuationDate,
        dueDate: editedState.dueDate,
        valuer: editedState.valuer,
      },
      { onSuccess: onSaveSuccess, onError: onSaveError },
    )
  }, [
    editedState,
    onSaveError,
    onSaveSuccess,
    updateValuationRequests,
    selectedValuationRequestIds,
  ])

  const selectedValuationReason = useMemo(
    () => editedState.reason ?? undefined,
    [editedState.reason],
  )

  const possibleValuationTypes = useMemo(() => {
    if (isNil(selectedValuationReason)) {
      const requestReasonValues = new Set(selectedValuationRequests.map(({ reason }) => reason))
      const availableReasons = valuationReasons.valuation_reasons.filter(({ reason }) =>
        requestReasonValues.has(reason),
      )
      return calculateIntersectionOfValuationTypesForReasons(availableReasons)
    }

    const selectedReason = valuationReasons.valuation_reasons.find(
      ({ reason }) => reason === selectedValuationReason,
    )
    return calculateIntersectionOfValuationTypesForReasons(selectedReason ? [selectedReason] : [])
  }, [selectedValuationReason, valuationReasons, selectedValuationRequests])

  const updateEditState = useCallback(
    (key) =>
      ({ operation, value }) => {
        switch (operation) {
          case valuationRequestMultiEditOperations.clear: {
            setEditedState((currentEditedState) => ({ ...currentEditedState, [key]: null }))
            return
          }
          case valuationRequestMultiEditOperations.keep: {
            setEditedState((currentEditedState) => ({ ...currentEditedState, [key]: undefined }))
            return
          }
          case valuationRequestMultiEditOperations.replace: {
            setEditedState((currentEditedState) => ({ ...currentEditedState, [key]: value }))
            return
          }
        }
      },
    [],
  )
  const onReasonChange = useCallback(
    (...args) => {
      updateEditState('reason')(...args)
      setEditedState((currentEditedState) => ({
        ...currentEditedState,
        valuationType: undefined,
      }))
    },
    [updateEditState],
  )
  const onValuationTypeChange = useMemo(() => updateEditState('valuationType'), [updateEditState])
  const onValuerChange = useMemo(() => updateEditState('valuer'), [updateEditState])
  const onStatusChange = useMemo(() => updateEditState('status'), [updateEditState])
  const onNoteChange = useMemo(() => updateEditState('note'), [updateEditState])
  const onValuationDateChange = useMemo(() => updateEditState('valuationDate'), [updateEditState])
  const onValuationDueDateChange = useMemo(() => updateEditState('dueDate'), [updateEditState])

  const onCloseButtonClick = useCallback(() => {
    dialogRef.current?.close()
  }, [dialogRef])

  const classes = isLoading ? `${styles.disabled} ${styles.dialogContent}` : styles.dialogContent

  return (
    <Dialog
      {...dialogProps}
      ref={dialogRef}
      open={isOpen}
      onAfterClose={onAfterCloseInternal}
      headerText={t('dialog.title', { numberOfSelectedRows: selectedValuationRequests.length })}
      size={DialogSize.M}
      closeButton={
        <DialogSecondaryButton onClick={onCloseButtonClick}>
          {tNoPrefix('buttons.close')}
        </DialogSecondaryButton>
      }
      primaryButton={
        <LoadingButton
          design={ButtonDesign.Emphasized}
          onClick={onSave}
          disabled={editedState === initialEditedState}
          renderContent={() => tNoPrefix('buttons.save')}
          isLoading={isLoading}
        />
      }
    >
      <FlexBox
        direction={FlexBoxDirection.Column}
        className={classes}
        {...(isLoading && { inert: '' })}
      >
        <ValuationRequestMultiEditReasonField
          onChange={onReasonChange}
          disabled={someValuationInCompletedStatus}
          valuationReasons={valuationReasons.valuation_reasons}
        />
        <ValuationRequestMultiEditValuationTypeField
          onChange={onValuationTypeChange}
          disabled={someValuationInCompletedStatus}
          availableValuationTypes={possibleValuationTypes}
        />
        <ValuationRequestMultiEditValuerField
          onChange={onValuerChange}
          valuationRequests={valuationRequests}
          selectedValuationRequestIds={selectedValuationRequestIds}
        />
        <ValuationRequestMultiEditValuationDueDateField
          onChange={onValuationDueDateChange}
          disabled={someValuationInCompletedStatus}
        />
        <ValuationRequestMultiEditStatusField
          onChange={onStatusChange}
          allowOnlyCreated={isAllowOnlyCreatedStatus}
          isStatusDraftNotAllowed={isStatusDraftNotAllowed}
          disabled={someValuationInCompletedStatus}
        />
        <ValuationRequestMultiEditNoteField
          onChange={onNoteChange}
          disabled={someValuationInCompletedStatus}
        />
        <ValuationRequestMultiEditValuationDateField onChange={onValuationDateChange} />
      </FlexBox>
    </Dialog>
  )
}

ValuationRequestMultiEditDialog.propTypes = {
  valuationRequests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      businessEvent: PropTypes.string,
      valuer: PropTypes.string,
      info: PropTypes.shape({
        propertyId: PropTypes.string,
        status: PropTypes.string,
        note: PropTypes.string,
        reportDate: PropTypes.string,
      }),
      reason: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  selectedValuationRequestIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  valuationReasons: PropTypes.shape({
    valuation_reasons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        reason: PropTypes.string.isRequired,
        types: PropTypes.shape({
          valuation_types: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              type: PropTypes.string.isRequired,
            }).isRequired,
          ),
        }).isRequired,
      }).isRequired,
    ).isRequired,
  }),
  onAfterClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default ValuationRequestMultiEditDialog
