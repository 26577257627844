import PropTypes from 'prop-types'
import FormattedNumberInput from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/edit-mode-cells/FormattedNumberInput'

const CustomerOptionsFloatInput = ({ handleOnChange, value }) => {
  const handleInputUpdate = (number) => {
    const newValue = number ? Number(number) : ''
    handleOnChange({ value: newValue, isValid: true })
  }

  return <FormattedNumberInput onInput={handleInputUpdate} value={value} />
}

CustomerOptionsFloatInput.propTypes = {
  value: PropTypes.number,
  handleOnChange: PropTypes.func.isRequired,
}

export default CustomerOptionsFloatInput
