import {
  FlexBox,
  FlexBoxDirection,
  Label,
  Table,
  TableCell,
  TableColumn,
  TableRow,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  valuationClassification,
  valuationClassificationAbbreviationsTranslated,
  valuationClassificationTranslated,
} from 'api/property/valuation/valuationRequests'
import styles from 'components/domains/properties/portfolio/overview/PropertyPortfolioValuationsTable.module.css'
import MoneyCell from 'components/ui/tables/cells/MoneyCell'
import DisplayAndEditTableToolbar from 'components/ui/tables/toolbar/DisplayAndEditTableToolbar'

const PropertyPortfolioValuationsTable = ({ preparedValuations, totalNumberOfProperties } = {}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.properties.portfolio.overview.valuation',
  })
  const { t: tNoPrefix } = useTranslation('translation')

  const renderTableRow = ({ number, currency, numberOfProperties, cwpCode } = {}) => {
    const cwpCodeKey = valuationClassification?.[cwpCode]
    const translationKey = valuationClassificationTranslated?.[cwpCodeKey]
    const translation = translationKey ? tNoPrefix(translationKey) : cwpCode
    return (
      <TableRow key={`valuation-row-${cwpCode}`}>
        <TableCell>
          <Label>{translation}</Label>
        </TableCell>
        <TableCell className={styles.alignRight}>
          <Label>
            {!isNil(numberOfProperties) &&
              !isNil(totalNumberOfProperties) &&
              `${numberOfProperties} / ${totalNumberOfProperties}`}
          </Label>
        </TableCell>
        <TableCell>
          <MoneyCell
            className={styles.moneyCell}
            primary={{ amount: number, currency: currency }}
          />
        </TableCell>
      </TableRow>
    )
  }
  const renderSectionHeaderRow = ({ category: calculationMethodCategory }) => {
    const translationKey = valuationClassificationTranslated?.[calculationMethodCategory]
    const translationAbbreviationKey =
      valuationClassificationAbbreviationsTranslated?.[calculationMethodCategory]

    const getTranslation = () => {
      if (translationKey && translationAbbreviationKey) {
        return `${tNoPrefix(translationKey)} (${tNoPrefix(translationAbbreviationKey)})`
      } else if (translationKey) {
        return tNoPrefix(translationKey)
      }
      return calculationMethodCategory
    }

    return (
      <TableRow className={styles.subRow} key={`valuation-sub-row-${calculationMethodCategory}`}>
        <TableCell>
          <Label className={styles.subRowTitle}>{getTranslation()}</Label>
        </TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
    )
  }

  const renderTableRows = () =>
    preparedValuations?.map((valuation) =>
      valuation.isSectionHeader ? renderSectionHeaderRow(valuation) : renderTableRow(valuation),
    )

  // do not count the section headers as valid row
  const nrOfEntries = preparedValuations?.reduce(
    (acc, cur) => (cur.isSectionHeader ? acc : acc + 1),
    0,
  )

  return (
    <>
      <DisplayAndEditTableToolbar title={t('title')} nrOfEntries={nrOfEntries} />
      <FlexBox className={styles.tableWrapper} direction={FlexBoxDirection.Column}>
        <Table
          columns={
            <>
              <TableColumn minWidth={200} demandPopin={false}>
                {t('table.column.valuation')}
              </TableColumn>
              <TableColumn
                demandPopin
                popinText={t('table.column.properties-included')}
                minWidth={300}
              >
                <div className={styles.valueColumn}>{t('table.column.properties-included')}</div>
              </TableColumn>
              <TableColumn demandPopin popinText={t('table.column.value')} minWidth={450}>
                <div className={styles.valueColumn}>{t('table.column.value')}</div>
              </TableColumn>
            </>
          }
          noDataText={tNoPrefix('components.table.no-data')}
        >
          {renderTableRows()}
        </Table>
      </FlexBox>
    </>
  )
}

PropertyPortfolioValuationsTable.propTypes = {
  preparedValuations: PropTypes.arrayOf(
    PropTypes.shape({
      isSectionHeader: PropTypes.bool,
      category: PropTypes.string,
      number: PropTypes.number,
      currency: PropTypes.string,
      numberOfProperties: PropTypes.number,
      cwpCode: PropTypes.string,
    }),
  ),
  totalNumberOfProperties: PropTypes.number,
}

export default PropertyPortfolioValuationsTable
