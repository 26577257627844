import { ObjectStatus, ValueState } from '@fioneer/ui5-webcomponents-react'
import { capitalize } from 'lodash'
import PropTypes from 'prop-types'

const DealChangeOverviewActionCell = ({ action }) => {
  const ACTION_KEYWORD_TO_VALUE_STATE = {
    NEW_VERSION: ValueState.Success,
    ADDED: ValueState.Success,
    LINKED: ValueState.Success,
    UPDATED: ValueState.Success,
    DELETED: ValueState.Error,
    DISCARDED: ValueState.Error,
    REMOVED: ValueState.Error,
    UNLINKED: ValueState.Error,
  }
  const NEUTRAL_VALUE_STATE = ValueState.Information

  const getValueStateByAction = () => {
    let valueState = NEUTRAL_VALUE_STATE
    Object.keys(ACTION_KEYWORD_TO_VALUE_STATE).forEach((keyword) => {
      if (action.toLowerCase().includes(keyword.toLowerCase())) {
        valueState = ACTION_KEYWORD_TO_VALUE_STATE[keyword]
      }
    })
    return valueState
  }

  return (
    <ObjectStatus inverted state={getValueStateByAction()}>
      {capitalize(action.replaceAll('_', ' '))}
    </ObjectStatus>
  )
}
DealChangeOverviewActionCell.propTypes = {
  action: PropTypes.string.isRequired,
}

export default DealChangeOverviewActionCell
