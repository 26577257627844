import PropTypes from 'prop-types'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import DealCashflowChartWrapper from 'components/domains/deals/deal-cashflow/result-charts/chart-components/DealCashflowChartWrapper'
import { DealCashflowLineChart } from 'components/domains/deals/deal-cashflow/result-charts/chart-components/DealCashflowLineChart'
import useGetMaturityData from 'components/domains/deals/deal-cashflow/result-charts/useGetMaturityData'

export const CONFIG = [
  {
    type: 'line',
    legend: 'legend.actual',
    legendType: 'plainline',
    color: '#772ACB',
    key: 'value',
  },
  {
    type: 'line',
    legend: 'legend.soft-covenant',
    legendType: 'plainline',
    color: '#C52C4A',
    key: 'softLimit',
  },
  {
    type: 'line',
    legend: 'legend.hard-covenant',
    legendType: 'plainline',
    color: '#9B3285',
    key: 'hardLimit',
  },
]

export const DealCashflowFinancialRatioCard = ({
  defaultKpiCode,
  defaultCategory,
  isLoading,
  isError,
  calculatedKpis,
  groups,
  isCard = true,
  metaData = undefined,
  showTitle,
  isOnDecisionPaper = false,
  isPdfViewOnDecisionPaper = false,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.cashflow.financial-ratios',
  })
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState()
  const [cashflowKpis, setCashflowKpis] = useState([])
  const [selectedCashflowKpi, setSelectedCashflowKpi] = useState()

  const cashflowKpiMap = useMemo(() => {
    const result = {}

    groups?.forEach((group) => {
      group.kpis?.forEach((kpi) => (result[kpi.ckpiId] = kpi))
    })

    return result
  }, [groups])

  const selectedCashflowKpiName = (selectedCashFlow, kpis) =>
    kpis.find(({ ckpiId }) => ckpiId === selectedCashFlow)?.name

  const reduceCashflowKpisByCategory = useCallback(
    (category) =>
      calculatedKpis.reduce((result, current) => {
        if (category !== current.category || !cashflowKpiMap[current.cashflowKpi.ckpiId]) {
          return result
        }
        return [...result, cashflowKpiMap[current.cashflowKpi.ckpiId]]
      }, []),
    [calculatedKpis, cashflowKpiMap],
  )

  useEffect(() => {
    if (!isLoading && categories?.length === 0 && calculatedKpis?.length > 0) {
      const reducedCategories = calculatedKpis.reduce(
        (result, current) =>
          !result.includes(current.category) && cashflowKpiMap[current.cashflowKpi.ckpiId]
            ? [...result, current.category]
            : result,
        [],
      )
      setCategories(reducedCategories)

      // because category can be null a explicit check for null is needed
      const preSelectedCategory =
        defaultCategory === null ? null : defaultCategory || reducedCategories.slice(-1)[0]
      setSelectedCategory(preSelectedCategory)

      const reducedCashflowKpis = reduceCashflowKpisByCategory(preSelectedCategory)
      setCashflowKpis(reducedCashflowKpis)

      const defaultKpi = reducedCashflowKpis?.find((kpi) => kpi.code === defaultKpiCode)
      setSelectedCashflowKpi(defaultKpi?.ckpiId ?? reducedCashflowKpis?.[0]?.ckpiId)
    }
  }, [
    calculatedKpis,
    cashflowKpiMap,
    categories,
    defaultKpiCode,
    groups,
    isLoading,
    reduceCashflowKpisByCategory,
    defaultCategory,
  ])

  const handleOnChangeSelectedCategory = (category) => {
    const newKpis = reduceCashflowKpisByCategory(category || null)

    // category can be null
    const newSelectedCategory = category === '' ? null : category

    setSelectedCategory(newSelectedCategory)
    setCashflowKpis(newKpis)
    setSelectedCashflowKpi(newKpis[0]?.ckpiId)
  }

  const selectedData = calculatedKpis?.find(
    (data) => data.category === selectedCategory && data.cashflowKpi.ckpiId === selectedCashflowKpi,
  )

  const allDataCombinations = useMemo(() => {
    const combinations = []
    categories.forEach((category) => {
      const newKpis = reduceCashflowKpisByCategory(category || null)
      newKpis.forEach((kpi) => combinations.push({ category, kpi }))
    })

    const kpis = combinations.map((data) =>
      calculatedKpis?.find(
        (calculatedKpisElement) =>
          calculatedKpisElement?.category === data?.category &&
          calculatedKpisElement?.cashflowKpi?.ckpiId === data?.kpi?.ckpiId,
      ),
    )

    return kpis
  }, [calculatedKpis, categories, reduceCashflowKpisByCategory])

  const { maturityData = {} } = useGetMaturityData(selectedData)

  const headerProps = {
    selectedData,
    isLoading,
    isError,
    maturityData,
    handleOnChangeSelectedCategory,
    categories,
    cashflowKpis,
    setSelectedCashflowKpi,
    selectedCashflowKpi,
    selectedCategory,
    isFixed: false,
    title: t('title.financial-ratios'),
    subtitlePrefix: t('header.subtitle.prefix', {
      name: selectedCashflowKpiName(selectedCashflowKpi, cashflowKpis),
    }),
    showTitle: showTitle,
    isOnDecisionPaper: isOnDecisionPaper,
    isPdfViewOnDecisionPaper: isPdfViewOnDecisionPaper,
  }

  return (
    <>
      {isPdfViewOnDecisionPaper ? (
        allDataCombinations.map((data, index) => (
          <React.Fragment key={index}>
            <DealCashflowChartWrapper
              isCard={isCard}
              isLoading={isLoading}
              isError={isError}
              isSelectedData={!!data}
              isMetaData={!!metaData}
              headerProps={{
                ...headerProps,
                cashflowKpis: reduceCashflowKpisByCategory(data?.category || null),
                selectedData: data,
                selectedCashflowKpi: data?.cashflowKpi?.ckpiId,
                selectedCategory: data?.category,
                subtitlePrefix: t('header.subtitle.prefix', {
                  name: selectedCashflowKpiName(
                    data?.cashflowKpi?.ckpiId,
                    reduceCashflowKpisByCategory(data?.category || null),
                  ),
                }),
              }}
            >
              <DealCashflowLineChart data={data} maturityData={maturityData} config={CONFIG} />
            </DealCashflowChartWrapper>
            <DecisionPaperTileListSeparator />
          </React.Fragment>
        ))
      ) : (
        <DealCashflowChartWrapper
          isCard={isCard}
          isLoading={isLoading}
          isError={isError}
          isSelectedData={!!selectedData}
          isMetaData={!!metaData}
          headerProps={headerProps}
        >
          <DealCashflowLineChart data={selectedData} maturityData={maturityData} config={CONFIG} />
        </DealCashflowChartWrapper>
      )}
    </>
  )
}

DealCashflowFinancialRatioCard.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  defaultKpiCode: PropTypes.string,
  defaultCategory: PropTypes.string,
  calculatedKpis: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  isCard: PropTypes.bool,
  metaData: PropTypes.object,
  showTitle: PropTypes.bool,
  isOnDecisionPaper: PropTypes.bool,
  isPdfViewOnDecisionPaper: PropTypes.bool,
}
