import {
  DEFAULT_DISPLAY_ROW_HEIGHT,
  DEFAULT_DISPLAY_HEIGHT_WITHOUT_TEXT,
  ROW_TYPE_ROLE,
  DEFAULT_DISPLAY_TEXT_HEIGHT,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/constants'
import { convertDecimalToBps } from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/convertBps'
import mapCounterpartySyndicationShares from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/mapCounterpartySyndicationShares'

const mapCounterpartyRoles = (t, roles, rootRowId, tranchesData = [], isOpsMemo = false) =>
  roles.map((role) => {
    const syndicationFee = role.syndicationFee ?? {}
    const calculatedRowHeight =
      DEFAULT_DISPLAY_HEIGHT_WITHOUT_TEXT +
      (syndicationFee.amountPerAnnumHeadquarter?.currency !==
        syndicationFee.amountPerAnnum?.currency ||
      syndicationFee.amountTermHeadquarter?.currency !== syndicationFee.amountTerm?.currency
        ? DEFAULT_DISPLAY_TEXT_HEIGHT * 2
        : DEFAULT_DISPLAY_TEXT_HEIGHT)

    return {
      rootRowId,
      rowType: ROW_TYPE_ROLE,
      partyRoleTranche: {
        value: role.roleTypeShortText,
        code: role.roleTypeCode,
      },
      type: {
        value: syndicationFee.feeTypeShortText,
        code: syndicationFee.feeTypeCode,
      },
      rate: {
        value: isOpsMemo ? syndicationFee.rate : convertDecimalToBps(syndicationFee.rate),
      },
      amountPA: {
        value: syndicationFee.amountPerAnnum,
        valueHeadquarter: syndicationFee.amountPerAnnumHeadquarter,
      },
      amountTerm: {
        value: syndicationFee.amountTerm,
        valueHeadquarter: syndicationFee.amountTermHeadquarter,
      },
      rowHeight: Math.max(calculatedRowHeight, DEFAULT_DISPLAY_ROW_HEIGHT),
      subRows: mapCounterpartySyndicationShares(
        t,
        role.syndicationShares ?? [],
        rootRowId,
        tranchesData,
        isOpsMemo,
      ),
    }
  })

export default mapCounterpartyRoles
