import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState.js'
import { isNull, isUndefined } from 'lodash'
import isEmpty from 'lodash.isempty'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  AlignedMultiCell,
  TitleAndSubTitleCell,
} from 'components/domains/properties/rent-roll/overview/PropertyRentRollOverviewCells'
import StructureOverviewNoDataAvailableCell from 'components/domains/properties/rent-roll/overview/structure-overview/StructureOverviewNoDataAvailableCell'
import { CELLS_IN_MULTI_COLUMN } from 'components/domains/properties/rent-roll/overview/structure-overview/constants'
import { PERIODS } from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsUtils'
import LoadingContent from 'components/ui/content/LoadingContent'
import {
  usePercentageFormatter,
  useNumberFormatter,
  useCustomizableCurrencyFormatter,
  useAreaMeasurementUnitFormatter,
  useAreaPiecesUnit,
} from 'hooks/i18n/useI18n'
import useCurrentRentRollOverviewPropertyKpis from 'hooks/services/properties/kpis/useCurrentRentRollOverviewPropertyKpis'
import useCurrentSegmentKpis from 'hooks/services/properties/kpis/useCurrentSegmentKpis'
import { SEGMENT_RENTAL_INCOME_TABLE_COLUMNS } from 'hooks/services/properties/segments/rental-income/useSegmentRentalIncomeTableColumns'
import { usePerUomLabelFormatter } from 'hooks/services/properties/segments/shared/usePerUomLabelFormatter'

export const useSegmentRentalIncomeTableRows = (property, segments) => {
  const { NAME_AND_USAGE_TYPE, MARKET_RENT, CONTRACTED_VALUES, CURRENT_VALUES, WAULT } =
    SEGMENT_RENTAL_INCOME_TABLE_COLUMNS
  const MONTHLY_DIVISOR = 12

  const { getPerUomLabel } = usePerUomLabelFormatter()
  const areaPiecesUnit = useAreaPiecesUnit({ capitalizeFirstLetter: true })
  const formatArea = useAreaMeasurementUnitFormatter({ capitalizeFirstLetter: true })

  const segmentsUomOnly = segments.filter(
    (segment) => !(segment?.area_measure_unit_is_pieces ?? false),
  )
  const segmentsPiecesOnly = segments.filter(
    (segment) => segment?.area_measure_unit_is_pieces ?? false,
  )

  const { t: tRentalIncome } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.rental-income',
  })

  const selectedCurrency = useSelector(
    (state) => state.properties.commonCurrency.selectedCommonCurrency,
  )
  const selectedPeriod = useSelector((state) => state.properties.segmentsRentalIncome.period)

  const formatCurrency = useCustomizableCurrencyFormatter()
  const formatWault = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formatPercent = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const formatPercentDifference = (number) => {
    if (number > 1) return `+${formatPercent(number - 1)}`
    else if (number < 1) return `-${formatPercent(1 - number)}`
    else return `${formatPercent(1 - number)}`
  }

  const getValueState = (number) => {
    if (number > 1) return ValueState.Success
    if (number === 0) return undefined
    if (number < 1) return ValueState.Error
  }

  const {
    isLoading: isLoadingCurrentSegmentKpis,
    isError: isErrorCurrentSegmentKpis,
    data: currentSegmentKpisData,
  } = useCurrentSegmentKpis(
    property?.uuid,
    segments.map((segment) => segment.uuid),
    {
      currency: selectedCurrency,
      areaUnitOfMeasurement: property?.area_measure_unit_code,
    },
  )
  const currentSegmentKpis = currentSegmentKpisData?.kpis
  const {
    isLoading: isLoadingPropertyKpis,
    isError: isErrorPropertyKpis,
    data: propertyKpiData,
  } = useCurrentRentRollOverviewPropertyKpis()

  const isLoading = isLoadingPropertyKpis || isLoadingCurrentSegmentKpis

  const isMonthly = selectedPeriod === PERIODS.MONTHLY

  const [faultySegments, setFaultySegments] = useState([])

  useEffect(() => {
    if (isLoading && !isEmpty(faultySegments)) {
      setFaultySegments([])
    }
  }, [faultySegments, isLoading])

  const sumBySegmentAccessor = (segments, valueFn) =>
    segments.reduce((acc, segment) => acc + (valueFn(segment) || 0), 0)

  const weightedSumBySegmentAccessor = (segments, valueFn, weightFn, sumFn) =>
    segments.reduce(
      (acc, segment) => acc + (valueFn(segment) * (weightFn(segment) / sumFn()) || 0),
      0,
    )

  const divideByPeriod = (value) => (isMonthly ? (value || 0) / MONTHLY_DIVISOR : value) || 0

  const multiplyByPeriod = (value) => (isMonthly ? (value || 0) * MONTHLY_DIVISOR : value) || 0

  const findKpi = (segment) => currentSegmentKpis?.find((kpi) => kpi.segment_uuid === segment.uuid)

  const isSegmentContainedInRentRoll = (segment) => {
    const kpi = findKpi(segment)
    return !!kpi && kpi.segment_usage_types?.length > 0
  }

  const findLetArea = (segment) => findKpi(segment)?.let_surface?.value
  const findLetAreaSum = () => sumBySegmentAccessor(segments, findLetArea)

  const findLetNumberOfPieces = (segment) => findKpi(segment)?.let_number_of_units
  const findTotalLetNumberOfPieces = () => propertyKpiData?.letNumberOfUnits

  /**
   * Rows
   */
  const findMarketRent = (segment) => divideByPeriod(segment?.market_rent_amount?.number)

  const findWAULT = (segment) => multiplyByPeriod(findKpi(segment)?.wault_to_break_in_years)

  /**
   *  Rows, Contracted
   **/
  const findContractedRent = (segment) =>
    divideByPeriod(findKpi(segment)?.annualized_contracted_rent?.number)

  const findContractedRentPerAreaOrPieces = (segment) => {
    const kpiValues = findKpi(segment)
    const annualizedCurrentRentPerUOM = segment.area_measure_unit_is_pieces
      ? kpiValues?.annualized_contracted_rent_per_pieces?.number
      : kpiValues?.annualized_contracted_rent_per_uom?.number
    return divideByPeriod(annualizedCurrentRentPerUOM)
  }

  const calculateOverUnderRented = (segment, value) => {
    const marketRent = findMarketRent(segment)
    if (
      isNull(value) ||
      isUndefined(value) ||
      isNull(marketRent) ||
      isUndefined(marketRent) ||
      marketRent <= 0
    )
      return 0
    return value / marketRent
  }

  /**
   *  Rows, Current
   **/
  const findCurrentRent = (segment) =>
    divideByPeriod(findKpi(segment)?.annualized_current_rent?.number)

  const findCurrentRentPerAreaOrPieces = (segment) => {
    const kpiValues = findKpi(segment)
    const annualizedCurrentRentPerUOM = segment.area_measure_unit_is_pieces
      ? kpiValues?.annualized_current_rent_per_pieces?.number
      : kpiValues?.annualized_current_rent_per_uom?.number
    return divideByPeriod(annualizedCurrentRentPerUOM)
  }
  const findCurrentOverUnderRented = (segment) =>
    calculateOverUnderRented(segment, findCurrentRentPerAreaOrPieces(segment))

  /**
   *  Totals
   **/
  const findTotalMarketRentUomOnly = () => sumBySegmentAccessor(segmentsUomOnly, findMarketRent)

  const findTotalMarketRentPiecesOnly = () =>
    sumBySegmentAccessor(segmentsPiecesOnly, findMarketRent)

  const findTotalContractedRentUomOnly = () =>
    divideByPeriod(propertyKpiData?.annualizedContractedRentUomOnly?.number)

  const findTotalContractedRentPiecesOnly = () =>
    divideByPeriod(propertyKpiData?.annualizedContractedRentPiecesOnly?.number)

  const findWeightedContractedRentPerUom = () =>
    weightedSumBySegmentAccessor(
      segmentsUomOnly,
      findContractedRentPerAreaOrPieces,
      findLetArea,
      findLetAreaSum,
    )
  const findWeightedContractedRentPerPieces = () =>
    weightedSumBySegmentAccessor(
      segmentsPiecesOnly,
      findContractedRentPerAreaOrPieces,
      findLetNumberOfPieces,
      findTotalLetNumberOfPieces,
    )

  const findTotalCurrentRentUomOnly = () =>
    divideByPeriod(propertyKpiData?.annualizedCurrentRentUomOnly?.number)

  const findTotalCurrentRentPiecesOnly = () =>
    divideByPeriod(propertyKpiData?.annualizedCurrentRentPiecesOnly?.number)

  const findWeightedCurrentRentPerUom = () =>
    weightedSumBySegmentAccessor(
      segmentsUomOnly,
      findCurrentRentPerAreaOrPieces,
      findLetArea,
      findLetAreaSum,
    )
  const findWeightedCurrentRentPerPieces = () =>
    weightedSumBySegmentAccessor(
      segmentsPiecesOnly,
      findCurrentRentPerAreaOrPieces,
      findLetNumberOfPieces,
      findTotalLetNumberOfPieces,
    )

  const findContractedOverUnderRented = (segment) =>
    calculateOverUnderRented(segment, findContractedRentPerAreaOrPieces(segment))

  const findWeightedTotalWAULT = () => propertyKpiData?.waultToBreakInYears

  /**
   * Formatters
   */
  /* format money */
  const formattedMoney = (number) =>
    number ?? null
      ? `${formatCurrency(number, selectedCurrency, {
          currencyDisplay: 'code',
        })}`
      : null
  const formattedMoneyPerUom = (number, segment) => {
    if (isNull(segment ?? null)) return formattedMoney(number)
    return (
      <>
        <Label>{getPerUomLabel(segment)}</Label>
        <span>&nbsp;</span>
        <Text>{formattedMoney(number)}</Text>
      </>
    )
  }
  const formattedTotalMoneyPieces = (number) =>
    number ?? null ? (
      <FlexBox direction={FlexBoxDirection.Row} justifyContent={FlexBoxJustifyContent.End}>
        <Label>
          <b>{areaPiecesUnit}: </b>
        </Label>
        <span>&nbsp;</span>
        <Text>
          <b>{formattedMoney(number)}</b>
        </Text>
      </FlexBox>
    ) : null
  const formattedTotalMoneyArea = (number) =>
    number ?? null ? (
      <FlexBox direction={FlexBoxDirection.Row} justifyContent={FlexBoxJustifyContent.End}>
        <Label>
          <b>{formatArea(property?.area_measure_unit_code)}: </b>
        </Label>
        <span>&nbsp;</span>
        <Text>
          <b>{formattedMoney(number)}</b>
        </Text>
      </FlexBox>
    ) : null

  const setFaultySegmentsInitially = (segment) => {
    if (!isLoading && segment && !faultySegments.includes(segment.uuid)) {
      setFaultySegments((prev) => [...prev, segment.uuid])
    }
  }

  const formatOrPlaceholder = ({
    formatter,
    kpiMethod,
    segment,
    isPublishedSegment,
    noDataAlignment = FlexBoxJustifyContent.Center,
  }) => {
    const value = kpiMethod(segment)
    if (value) {
      return formatter(value, segment)
    } else {
      setFaultySegmentsInitially(segment)
      return isPublishedSegment ? (
        <StructureOverviewNoDataAvailableCell times={1} alignment={noDataAlignment} />
      ) : (
        <></>
      )
    }
  }

  const segmentRows = segments.map((segment) => ({
    rowKey: segment.uuid,
    isFaulty: faultySegments.includes(segment.uuid),
    cells: [
      {
        columnKey: NAME_AND_USAGE_TYPE,
        renderCell: () => (
          <TitleAndSubTitleCell title={segment.name} subTitle={segment.usage_type_name} />
        ),
      },
      {
        columnKey: MARKET_RENT,
        renderCell: () => (
          <AlignedMultiCell
            alignment={FlexBoxJustifyContent.End}
            values={[
              formatOrPlaceholder({
                formatter: formattedMoneyPerUom,
                kpiMethod: findMarketRent,
                segment: segment,
                isPublishedSegment: isSegmentContainedInRentRoll(segment),
                noDataAlignment: FlexBoxJustifyContent.End,
              }),
            ]}
          />
        ),
      },
      {
        columnKey: CONTRACTED_VALUES,
        renderCell: () => (
          <LoadingContent
            contentKey={CONTRACTED_VALUES}
            isLoading={isLoadingCurrentSegmentKpis}
            isError={isErrorCurrentSegmentKpis && !isSegmentContainedInRentRoll(segment)}
            errorContent={
              <StructureOverviewNoDataAvailableCell
                times={CELLS_IN_MULTI_COLUMN}
                alignment={FlexBoxJustifyContent.End}
              />
            }
          >
            <AlignedMultiCell
              alignment={FlexBoxJustifyContent.End}
              values={[
                formatOrPlaceholder({
                  formatter: formattedMoney,
                  kpiMethod: findContractedRent,
                  isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  segment: segment,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
                formatOrPlaceholder({
                  formatter: formattedMoneyPerUom,
                  kpiMethod: findContractedRentPerAreaOrPieces,
                  isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  segment: segment,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
                formatOrPlaceholder({
                  formatter: formatPercentDifference,
                  kpiMethod: findContractedOverUnderRented,
                  isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  segment: segment,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
              ]}
              valueStates={[
                undefined,
                undefined,
                getValueState(findContractedOverUnderRented(segment)),
              ]}
            />
          </LoadingContent>
        ),
      },
      {
        columnKey: CURRENT_VALUES,
        renderCell: () => (
          <LoadingContent
            contentKey={CURRENT_VALUES}
            isLoading={isLoadingCurrentSegmentKpis}
            isError={isErrorCurrentSegmentKpis && !isSegmentContainedInRentRoll(segment)}
            errorContent={
              <StructureOverviewNoDataAvailableCell
                times={CELLS_IN_MULTI_COLUMN}
                alignment={FlexBoxJustifyContent.End}
              />
            }
          >
            <AlignedMultiCell
              alignment={FlexBoxJustifyContent.End}
              values={[
                formatOrPlaceholder({
                  formatter: formattedMoney,
                  kpiMethod: findCurrentRent,
                  isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  segment: segment,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
                formatOrPlaceholder({
                  formatter: formattedMoneyPerUom,
                  kpiMethod: findCurrentRentPerAreaOrPieces,
                  isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  segment: segment,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
                formatOrPlaceholder({
                  formatter: formatPercentDifference,
                  kpiMethod: findCurrentOverUnderRented,
                  isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  segment: segment,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
              ]}
              valueStates={[
                undefined,
                undefined,
                getValueState(findCurrentOverUnderRented(segment)),
              ]}
            />
          </LoadingContent>
        ),
      },
      {
        columnKey: WAULT,
        renderCell: () => (
          <LoadingContent
            contentKey={WAULT}
            isLoading={isLoadingCurrentSegmentKpis}
            isError={isErrorCurrentSegmentKpis && !isSegmentContainedInRentRoll(segment)}
            errorContent={
              <StructureOverviewNoDataAvailableCell
                times={1}
                alignment={FlexBoxJustifyContent.End}
              />
            }
          >
            <AlignedMultiCell
              alignment={FlexBoxJustifyContent.End}
              values={[
                formatOrPlaceholder({
                  formatter: formatWault,
                  kpiMethod: findWAULT,
                  segment: segment,
                  isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
              ]}
            />
          </LoadingContent>
        ),
      },
    ],
  }))

  const totalRow = {
    rowKey: 'total',
    aggregate: true,
    cells: [
      {
        columnKey: NAME_AND_USAGE_TYPE,
        renderCell: () => <b>{tRentalIncome('table.columns.total')}</b>,
      },
      {
        columnKey: MARKET_RENT,
        renderCell: () => (
          <>
            {formattedTotalMoneyArea(findTotalMarketRentUomOnly())}
            {formattedTotalMoneyPieces(findTotalMarketRentPiecesOnly())}
          </>
        ),
      },
      {
        columnKey: CONTRACTED_VALUES,
        renderCell: () => (
          <LoadingContent
            contentKey={CONTRACTED_VALUES}
            isLoading={isLoadingCurrentSegmentKpis}
            isError={isErrorCurrentSegmentKpis}
            errorContent={
              <StructureOverviewNoDataAvailableCell
                times={CELLS_IN_MULTI_COLUMN}
                showIteration={[true, true, false]}
                alignment={FlexBoxJustifyContent.End}
              />
            }
          >
            <AlignedMultiCell
              alignment={FlexBoxJustifyContent.End}
              values={[
                <>
                  {formattedTotalMoneyArea(findTotalContractedRentUomOnly())}
                  {formattedTotalMoneyPieces(findTotalContractedRentPiecesOnly())}
                </>,
                <>
                  {formattedTotalMoneyArea(findWeightedContractedRentPerUom())}
                  {formattedTotalMoneyPieces(findWeightedContractedRentPerPieces())}
                </>,
                '', // Empty Cell (Important, to match number of columns)
              ]}
            />
          </LoadingContent>
        ),
      },
      {
        columnKey: CURRENT_VALUES,
        renderCell: () => (
          <LoadingContent
            contentKey={CURRENT_VALUES}
            isLoading={isLoadingCurrentSegmentKpis}
            isError={isErrorCurrentSegmentKpis}
            errorContent={
              <StructureOverviewNoDataAvailableCell
                times={CELLS_IN_MULTI_COLUMN}
                showIteration={[true, true, false]}
                alignment={FlexBoxJustifyContent.End}
              />
            }
          >
            <AlignedMultiCell
              alignment={FlexBoxJustifyContent.End}
              values={[
                <>
                  {formattedTotalMoneyArea(findTotalCurrentRentUomOnly())}
                  {formattedTotalMoneyPieces(findTotalCurrentRentPiecesOnly())}
                </>,
                <>
                  {formattedTotalMoneyArea(findWeightedCurrentRentPerUom())}
                  {formattedTotalMoneyPieces(findWeightedCurrentRentPerPieces())}
                </>,
                '', // Empty Cell (Important, to match number of columns)
              ]}
            />
          </LoadingContent>
        ),
      },
      {
        columnKey: WAULT,
        renderCell: () => (
          <LoadingContent
            contentKey={WAULT}
            isLoading={isLoadingCurrentSegmentKpis}
            isError={isErrorCurrentSegmentKpis}
            errorContent={
              <StructureOverviewNoDataAvailableCell
                times={1}
                alignment={FlexBoxJustifyContent.End}
              />
            }
          >
            <AlignedMultiCell
              alignment={FlexBoxJustifyContent.End}
              values={[
                formatOrPlaceholder({
                  formatter: formatWault,
                  kpiMethod: findWeightedTotalWAULT,
                  noDataAlignment: FlexBoxJustifyContent.End,
                }),
              ]}
            />
          </LoadingContent>
        ),
      },
    ],
  }

  const publishedSegments = currentSegmentKpis?.filter((kpi) => !isEmpty(kpi.segment_usage_types))

  if (segmentRows?.length > 0) {
    return {
      isError: isErrorCurrentSegmentKpis || isErrorPropertyKpis || faultySegments.length > 0,
      isErrorAllSegmentsPublished: publishedSegments?.length !== segmentRows.length && !isLoading,
      data: segmentRows.concat([totalRow]),
    }
  } else {
    return {
      isError: false,
      isErrorAllSegmentsPublished: false,
      data: [],
    }
  }
}
