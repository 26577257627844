import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Label,
} from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/events/decision-stage/UserDecisionStageVotesCardHeader.module.css'

const UserDecisionStageVotesCardHeader = ({ titleText }) => (
  <FlexBox
    className={styles.header}
    justifyContent={FlexBoxJustifyContent.SpaceBetween}
    alignItems={FlexBoxAlignItems.Center}
  >
    <Label className={styles.titleLabel}>{titleText}</Label>
  </FlexBox>
)

UserDecisionStageVotesCardHeader.propTypes = {
  titleText: PropType.string.isRequired,
}

export default UserDecisionStageVotesCardHeader
