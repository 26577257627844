import { useMemo } from 'react'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { useGetBusinessObjectsName } from 'hooks/services/conditions/business-objects/useGetBusinessObjectsName'

const mapEntityId = ({ entityId }) => entityId

const useReferenceNames = ({ references, disabled = false }) => {
  const dealReferences = useMemo(
    () =>
      references.filter(({ entityType }) => entityType === cwpEntityTypes.DEAL).map(mapEntityId),
    [references],
  )

  const propertyReferences = useMemo(
    () =>
      references
        .filter(({ entityType }) => entityType === cwpEntityTypes.PROPERTY)
        .map(mapEntityId),
    [references],
  )

  const businessPartnerReferences = useMemo(
    () =>
      references
        .filter(({ entityType }) => entityType === cwpEntityTypes.BUSINESS_PARTNER)
        .map(mapEntityId),
    [references],
  )

  const {
    data: dealResolvedNames,
    isLoading: isDealsLoading,
    isError: isDealsError,
  } = useGetBusinessObjectsName({
    entityType: cwpEntityTypes.DEAL,
    entityIds: dealReferences,
    disabled,
  })

  const {
    data: propertyResolvedNames,
    isLoading: isPropertiesLoading,
    isError: isPropertiesError,
  } = useGetBusinessObjectsName({
    entityType: cwpEntityTypes.PROPERTY,
    entityIds: propertyReferences,
    disabled,
  })

  const {
    data: businessPartnerResolvedNames,
    isLoading: isBusinessPartnerLoader,
    isError: isBusinessPartnerError,
  } = useGetBusinessObjectsName({
    entityType: cwpEntityTypes.BUSINESS_PARTNER,
    entityIds: businessPartnerReferences,
    disabled,
  })

  const resolvedReferences = useMemo(
    () => [...dealResolvedNames, ...propertyResolvedNames, ...businessPartnerResolvedNames],
    [businessPartnerResolvedNames, dealResolvedNames, propertyResolvedNames],
  )

  const isLoading = !disabled && (isDealsLoading || isPropertiesLoading || isBusinessPartnerLoader)
  const isError = isDealsError || isPropertiesError || isBusinessPartnerError

  return useMemo(
    () => ({
      dealReferences,
      propertyReferences,
      businessPartnerReferences,
      resolvedReferences,
      isLoading,
      isError,
    }),
    [
      businessPartnerReferences,
      dealReferences,
      isError,
      isLoading,
      propertyReferences,
      resolvedReferences,
    ],
  )
}

export default useReferenceNames
