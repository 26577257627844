import {
  AnalyticalTable,
  AnalyticalTableHooks,
  AnalyticalTableSelectionBehavior,
  AnalyticalTableSelectionMode,
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxDirection,
  Label,
  Toolbar,
  ToolbarDesign,
  ToolbarSpacer,
  ToolbarStyle,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/dialogs/customize-decision-paper/CustomizeDecisionPaperTemplateTable.module.css'
import { normalizeRowSelection } from 'components/domains/business-events-and-tasks/decision-paper/dialogs/customize-decision-paper/customizeDecisionPaperTemplateTableSelectionHelper'
import useCustomizeDecisionPaperTemplateColumns from 'components/domains/business-events-and-tasks/decision-paper/dialogs/customize-decision-paper/useCustomizeDecisionPaperTemplateColumns'
import useMapTemplateDataToTableData from 'components/domains/business-events-and-tasks/decision-paper/dialogs/customize-decision-paper/useMapTemplateDataToTableData'
import tableToolBarStyles from 'components/ui/tables/toolbar/TablesToolbar.module.css'

/**
 * @typedef {import('components/domains/business-events-and-tasks/decision-paper/dialogs/customize-decision-paper/customizeDecisionPaperTemplateTableSelectionHelper').Template} Template
 * @typedef {import('components/domains/business-events-and-tasks/decision-paper/dialogs/customize-decision-paper/customizeDecisionPaperTemplateTableSelectionHelper').RowSelection} RowSelection
 * @typedef {import('components/domains/business-events-and-tasks/decision-paper/dialogs/customize-decision-paper/customizeDecisionPaperTemplateTableSelectionHelper').RowSelectionHandler} RowSelectionHandler
 */

const reactTableOptions = { autoResetExpanded: false }

/**
 * @typedef {object} Props
 * @property {Template} templateData
 * @property {bool} isFetching
 * @property {RowSelection} selectedRowIds
 * @property {RowSelectionHandler} onRowSelect
 * @param {Props} props
 * @returns {import('react').ReactElement}
 */
const CustomizeDecisionPaperTemplateTable = ({
  templateData,
  isFetching,
  selectedRowIds,
  onRowSelect,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix:
      'components.business-events-and-tasks.decision-papers.template-customization-dialog.table',
  })
  const tableData = useMapTemplateDataToTableData(templateData)
  const tableColumns = useCustomizeDecisionPaperTemplateColumns()
  const tableRef = useRef()

  const handleRowSelect = useCallback(
    (event) => {
      onRowSelect(normalizeRowSelection(event.detail))
    },
    [onRowSelect],
  )

  const handleExpandAllClicked = useCallback(() => {
    tableRef.current.toggleAllRowsExpanded(true)
  }, [])
  const handleCollapseAllClicked = useCallback(() => {
    tableRef.current.toggleAllRowsExpanded(false)
  }, [])

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.tableWrapper}>
      <Toolbar
        design={ToolbarDesign.Auto}
        toolbarStyle={ToolbarStyle.Clear}
        className={tableToolBarStyles.toolbar}
      >
        <Label className={tableToolBarStyles.titleLabel}>{t('title')}</Label>
        <ToolbarSpacer />
        <Button
          onClick={handleCollapseAllClicked}
          design={ButtonDesign.Transparent}
          icon="collapse-all"
        />
        <Button
          onClick={handleExpandAllClicked}
          design={ButtonDesign.Transparent}
          icon="expand-all"
        />
      </Toolbar>
      <AnalyticalTable
        tableInstance={tableRef}
        className={styles.templateCustomizationTable}
        loading={isFetching}
        columns={tableColumns}
        isTreeTable
        minRows={1}
        data={tableData}
        selectionMode={AnalyticalTableSelectionMode.MultiSelect}
        selectionBehavior={AnalyticalTableSelectionBehavior.RowSelector}
        selectedRowIds={selectedRowIds}
        onRowSelect={handleRowSelect}
        reactTableOptions={reactTableOptions}
        tableHooks={[AnalyticalTableHooks.useIndeterminateRowSelection()]}
      />
    </FlexBox>
  )
}

CustomizeDecisionPaperTemplateTable.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  selectedRowIds: PropTypes.objectOf(PropTypes.bool).isRequired,
  onRowSelect: PropTypes.func.isRequired,
  templateData: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        subsections: PropTypes.arrayOf(
          PropTypes.shape({
            code: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            hideAssessment: PropTypes.bool,
            tiles: PropTypes.arrayOf(
              PropTypes.shape({
                code: PropTypes.string.isRequired,
                subType: PropTypes.string.isRequired,
              }),
            ),
          }),
        ),
      }),
    ),
  }),
}

export default CustomizeDecisionPaperTemplateTable
