import { MessageBox, MessageBoxActions, MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'

const DealCreationCancelMessageBox = ({ isOpen, setIsOpen, handleConfirmation }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.dialog.cancel-dialog' })

  const onClose = (event) => {
    setIsOpen(false)
    if (event.detail.action === MessageBoxActions.OK) {
      handleConfirmation()
    }
  }

  return createPortal(
    <MessageBox
      type={MessageBoxTypes.Warning}
      open={isOpen}
      onClose={onClose}
      actions={[MessageBoxActions.OK, MessageBoxActions.Cancel]}
      emphasizedAction={MessageBoxActions.OK}
      titleText={t('title')}
      id="cancel-deal-creation-message-box"
    >
      {t('content')}
    </MessageBox>,
    document.body,
  )
}

export default DealCreationCancelMessageBox

DealCreationCancelMessageBox.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
}
