import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import AnnualReviewUnitOverviewTable from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/AnnualReviewUnitOverviewTable'

const AnnualReviewUnitOverviewTile = ({ tileId }) => {
  const { data } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId]) ?? {}

  const typeCodesConfig = data?.typeCodesConfig ?? []
  const countryCodesConfig = data?.countryCodesConfig ?? []
  const unitOverviewData = data?.unitOverviewData ?? {}
  const multipleDealDetailsResponses = data?.multipleDealDetailsResponses ?? []

  const multipleAggregatedDealPortfolioResponses =
    data?.multipleAggregatedDealPortfolioResponses ?? []

  const multiPropertyKpisResponses = data?.multiPropertyKpisResponses ?? []
  const businessPartnersData = data?.businessPartnersData ?? []
  const lendersResponses = data?.lendersResponses ?? []
  const multipleSyndicationsExistingBusinessResponses =
    data?.multipleSyndicationsExistingBusinessResponses ?? []
  const customerBusinessPartnerIdResponse = data?.customerBusinessPartnerIdResponse ?? {}
  const businessPartnersSyndicationData = data?.businessPartnersSyndicationData ?? {}
  const complianceWithRiskStrategyResponses = data?.complianceWithRiskStrategyResponses ?? []
  const multipleDealTrancheResponses = data?.multipleDealTrancheResponses ?? []
  const multipleDealsPricingResponses = data?.multipleDealsPricingResponses ?? []
  const multipleKpisResponses = data?.multipleKpisResponses ?? []
  const multipleForbearanceStatusesResponses = data?.multipleForbearanceStatuses ?? []
  const multipleCovenantMonitoringItems = data?.multipleCovenantMonitoringItems ?? []
  const multipleBusinessPartnerCustomFieldsResponses =
    data?.multipleBusinessPartnerCustomFieldsResponses ?? []
  const multiplePropertyCustomFieldsByPropertyUuidsData =
    data?.multiplePropertyCustomFieldsByPropertyUuidsData ?? []
  const businessPartnerCustomFieldConfigMetadata = data?.businessPartnerCustomFieldConfigMetadata

  return (
    <AnnualReviewUnitOverviewTable
      unitOverviewData={unitOverviewData}
      typeCodesConfig={typeCodesConfig}
      countryCodesConfig={countryCodesConfig}
      multipleDealDetailsResponses={multipleDealDetailsResponses}
      multipleAggregatedDealPortfolioResponses={multipleAggregatedDealPortfolioResponses}
      multiPropertyKpisResponses={multiPropertyKpisResponses}
      businessPartnersData={businessPartnersData}
      lendersResponses={lendersResponses}
      multipleSyndicationsExistingBusinessResponses={multipleSyndicationsExistingBusinessResponses}
      customerBusinessPartnerIdResponse={customerBusinessPartnerIdResponse}
      businessPartnersSyndicationData={businessPartnersSyndicationData}
      complianceWithRiskStrategyResponses={complianceWithRiskStrategyResponses}
      multipleDealTrancheResponses={multipleDealTrancheResponses}
      multipleDealsPricingResponses={multipleDealsPricingResponses}
      multipleKpisResponses={multipleKpisResponses}
      multipleForbearanceStatusesResponses={multipleForbearanceStatusesResponses}
      multipleCovenantMonitoringItems={multipleCovenantMonitoringItems}
      multipleBusinessPartnerCustomFields={multipleBusinessPartnerCustomFieldsResponses}
      multiplePropertyCustomFieldsByPropertyUuidsData={
        multiplePropertyCustomFieldsByPropertyUuidsData
      }
      businessPartnerCustomFieldConfigMetadata={businessPartnerCustomFieldConfigMetadata}
      businessPartnerId={data?.sourceRender?.businessPartnerId}
    />
  )
}

AnnualReviewUnitOverviewTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default AnnualReviewUnitOverviewTile
