const RISK_MONITORING_STATUS_KEY = 'STATUS'

export const mapRiskMonitoringStatus = (
  dealUuid,
  rowResponses,
  businessPartnerCustomFieldConfigMetadata,
) => {
  const response = rowResponses?.find(({ data }) => data?.dealUuid === dealUuid)
  const result = response?.data?.customFields?.find(
    (field) => field.key === RISK_MONITORING_STATUS_KEY,
  )

  const riskMonitoringResultEnums = businessPartnerCustomFieldConfigMetadata?.find(
    (field) => field.key === RISK_MONITORING_STATUS_KEY,
  )

  const displayValue = riskMonitoringResultEnums?.enumValues?.find(
    (enumValue) => enumValue.code === result?.value,
  )

  return result?.value ? displayValue?.displayName : null
}
