import { MessageStrip } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

/**
 * @typedef {import('@fioneer/ui5-webcomponents-react').Ui5CustomEvent} Ui5CustomEvent
 * @typedef {import('@fioneer/ui5-webcomponents-react').MessageStripDomRef} MessageStripDomRef
 * @typedef {import('@fioneer/ui5-webcomponents-react').MessageStripPropTypes} MessageStripPropTypes
 *
 * @typedef {object} CustomProps
 * @property {(event: Ui5CustomEvent<MessageStripDomRef, never>) => boolean | undefined} [onClose]
 **/
/**
 * Closeable wrapper around MessageStrip.
 *
 * In addition to the props, you can also pass every prop of the [MessageStrip](https://sap.github.io/ui5-webcomponents-react/?path=/docs/data-display-messagestrip--docs)
 * @param {Omit<MessageStripPropTypes, keyof CustomProps> & CustomProps} props
 */
const CloseableMessageStrip = ({ onClose, children, ...messageStripProps }) => {
  const [closed, setClosed] = useState(false)
  return (
    !closed && (
      <MessageStrip
        {...messageStripProps}
        onClose={(e) => {
          if (!(onClose && onClose(e))) setClosed(true)
        }}
      >
        {children}
      </MessageStrip>
    )
  )
}

CloseableMessageStrip.propTypes = {
  /** The message strip's content */
  children: PropTypes.node,
  /** Callback that's run before closing. Return a truthy value to keep the message strip open */
  onClose: PropTypes.func,
}

export default CloseableMessageStrip
