import { z } from 'zod'

export const QUESTIONS = Object.freeze({
  crrDefault: 'crrDefault',
  signsOfDefault: 'signsOfDefault',
  financialMaterial: 'financialMaterial',
  enforcementMeasures: 'enforcementMeasures',
  enforcementMeasuresIntendedOrCarriedOut: 'enforcementMeasuresIntendedOrCarriedOut',
  insolvency: 'insolvency',
  applicationInsolvencyProceedingsHasBeenMade: 'applicationInsolvencyProceedingsHasBeenMade',
  bankruptcy: 'bankruptcy',
  forbearanceMeasures: 'forbearanceMeasures',
  forborneDealOrChangedForbearanceStatus: 'forborneDealOrChangedForbearanceStatus',
  result: 'result',
  minOneIndicatorOrForbearanceApplicable: 'minOneIndicatorOrForbearanceApplicable',
})

export const checkSheetSchema = z.object({
  [QUESTIONS.crrDefault]: z.object({
    [QUESTIONS.signsOfDefault]: z.string(),
    [QUESTIONS.financialMaterial]: z.string(),
  }),
  [QUESTIONS.enforcementMeasures]: z.object({
    [QUESTIONS.enforcementMeasuresIntendedOrCarriedOut]: z.string(),
  }),
  [QUESTIONS.insolvency]: z.object({
    [QUESTIONS.applicationInsolvencyProceedingsHasBeenMade]: z.string(),
    [QUESTIONS.bankruptcy]: z.string(),
  }),
  [QUESTIONS.forbearanceMeasures]: z.object({
    [QUESTIONS.forborneDealOrChangedForbearanceStatus]: z.string(),
  }),
  [QUESTIONS.result]: z.object({
    [QUESTIONS.minOneIndicatorOrForbearanceApplicable]: z.string(),
  }),
})

/** @typedef {import('zod').TypeOf<typeof checkSheetSchema>} CheckSheet */

/** @satisfies {CheckSheet} */
export const emptyCheckSheet = {
  [QUESTIONS.crrDefault]: {
    [QUESTIONS.signsOfDefault]: '',
    [QUESTIONS.financialMaterial]: '',
  },
  [QUESTIONS.enforcementMeasures]: {
    [QUESTIONS.enforcementMeasuresIntendedOrCarriedOut]: '',
  },
  [QUESTIONS.insolvency]: {
    [QUESTIONS.applicationInsolvencyProceedingsHasBeenMade]: '',
    [QUESTIONS.bankruptcy]: '',
  },
  [QUESTIONS.forbearanceMeasures]: {
    [QUESTIONS.forborneDealOrChangedForbearanceStatus]: '',
  },
  [QUESTIONS.result]: {
    [QUESTIONS.minOneIndicatorOrForbearanceApplicable]: '',
  },
}
