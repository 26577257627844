import { useMemo } from 'react'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import { mainEntityTypes } from 'hooks/services/deals/covenants/MainEntityTypes'
import useMultipleCovenantMonitoringItemsByEntityId from 'hooks/services/deals/covenants/monitorings/useMultipleCovenantMonitoringItemsByEntityId'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const useAnnualReviewMultipleCovenantChecksResults = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { dealsData, dealUuids, selectedDeal } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  const { selectedDealDisplayId } = selectedDeal ?? {}

  const {
    data: multipleCovenantMonitoringItemsData = [],
    isLoading: isMultipleCovenantMonitoringItemsLoading,
    isError: isMultipleCovenantMonitoringItemsError,
  } = useCombinedQueryResults(
    useMultipleCovenantMonitoringItemsByEntityId({
      mainEntityIds: dealUuids,
      mainEntityType: mainEntityTypes.DEAL,
    }),
    { forceDataReturn: false },
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isAnnualReviewDealsLoading, isMultipleCovenantMonitoringItemsLoading],
    errorValues: [isAnnualReviewDealsError, isMultipleCovenantMonitoringItemsError],
    errorDetails: [annualReviewDealsError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        multipleCovenantMonitoringItemsData,
        sourceRender: {
          dealDisplayId: selectedDealDisplayId,
        },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealsData,
    multipleCovenantMonitoringItemsData,
    selectedDealDisplayId,
    error,
  ])
}

export default useAnnualReviewMultipleCovenantChecksResults
