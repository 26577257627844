import camelize from 'camelize'
import { isNotFoundError } from 'api/requests'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import { useRequest } from 'hooks/services/baseService'

const RETRIES = 2

const useMarketReports = ({ marketId }) => {
  const searchParams = new URLSearchParams()

  if (marketId) {
    searchParams.append('entity_id', marketId)
  }

  searchParams.append('entity_type', 'MARKET')

  const result = useRequest({
    path: `/documents?${searchParams.toString()}`,
    keys: ['documents', DocumentTypes.Market, marketId],
    useCache: true,
    options: {
      retry: (failureCount, error) => {
        if (isNotFoundError(error)) {
          return false
        }
        return failureCount < RETRIES
      },
    },
  })
  if (result.isError && isNotFoundError(result.error)) {
    return { ...result, data: {}, isError: false }
  }
  return { ...result, data: camelize(result.data) }
}

export default useMarketReports
