import PropTypes from 'prop-types'
import styles from 'components/domains/business-partners/tile/general-information/keyInternalContact/KeyInternalContactEditField.module.css'

export const KeyInternalContactEditField = ({ children }) => (
  <div className={styles.field}>{children}</div>
)

KeyInternalContactEditField.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}
