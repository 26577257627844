import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/properties/rent-roll/overview/current-rent/PropertyRentRollOverviewCurrentRentText.module.css'

const PropertyRentRollOverviewCurrentRentText = ({ text }) => (
  <Text className={styles.italic}>{text}</Text>
)
PropertyRentRollOverviewCurrentRentText.propTypes = {
  text: PropTypes.string,
}
export default PropertyRentRollOverviewCurrentRentText
