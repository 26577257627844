import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useDeletePropertyPartner = ({ onSuccess, onError }) => {
  const { delete: deletePropertyPartner } = useAccessTokenRequest()

  return useMutation(
    async ({ entityUuid, partnerId, functionCode }) => {
      await deletePropertyPartner({
        path: `/properties/${entityUuid}/businesspartners?businesspartner_id=${partnerId}&businesspartner_function_code=${functionCode}`,
      })
    },
    {
      onSuccess,
      onError,
    },
  )
}
