import { FlexBox, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { TenancyTableRowType } from 'components/domains/business-partners/tile/tenancy/tenant-unit-table/AsTenantTableConstants'
import TenancyDealCell from 'components/domains/business-partners/tile/tenancy/tenant-unit-table/table-cells/TenancyDealCell'
import styles from 'components/domains/business-partners/tile/tenancy/tenant-unit-table/table-cells/TenancyPropertySegmentCell.module.css'
import PropertyFinancingStatusCell from 'components/domains/properties/common/PropertyFinancingStatusCell'
import EntityCell from 'components/ui/tables/cells/EntityCell'

const TenancyPropertySegmentCellPropTypes = {
  cell: PropTypes.shape({
    value: PropTypes.shape({
      rowType: PropTypes.oneOf(Object.values(TenancyTableRowType)),
      financingStatusCode: PropTypes.string,
      deal: PropTypes.shape({
        dealUuid: PropTypes.string.isRequired,
        dealId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      }),
      segmentUsageType: PropTypes.shape({
        name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
      }),
      property: PropTypes.shape({
        description: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
}
/** @param {PropTypes.InferProps<typeof TenancyPropertySegmentCellPropTypes>} props */
const TenancyPropertySegmentCell = ({
  cell: {
    value: { segmentUsageType, property, deal, rowType, financingStatusCode },
  },
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-partner.tenancy.as-tenant',
  })
  switch (rowType) {
    case TenancyTableRowType.deal:
      return (
        <FlexBox className={styles.groupWrapper}>
          <Label className={styles.groupLabel} showColon>
            {t('table-grouping.deal.label')}
          </Label>
          <TenancyDealCell cell={{ value: [deal] }} />
        </FlexBox>
      )
    case TenancyTableRowType.segment:
      return segmentUsageType?.name
    case TenancyTableRowType.property:
      return (
        <EntityCell
          name={property?.description}
          id={property?.id}
          link={`/properties/${property?.id}`}
          options={{ openInNewTab: true }}
        />
      )
    case TenancyTableRowType.financingStatus:
      return (
        <FlexBox className={styles.groupWrapper}>
          <Label className={styles.groupLabel} showColon>
            {t('table-grouping.financing-status.label')}
          </Label>
          <PropertyFinancingStatusCell
            isLoading={false}
            isError={false}
            propertyData={{ financingStatusCode }}
          />
        </FlexBox>
      )
    default:
      return null
  }
}

TenancyPropertySegmentCell.propTypes = TenancyPropertySegmentCellPropTypes

export default TenancyPropertySegmentCell
