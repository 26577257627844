import { MessageStrip, MessageStripDesign, Link } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/covenant-check-detail/message-stripes/CovenantCheckMessageStrips.module.css'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useCovenantCheckActions from 'hooks/services/deals/covenants/useCovenantCheckActions'
import paths from 'routes/paths'

const possibleErrorCodes = {
  COVENANT_CHECK_NOT_ACTIVE_ERROR: 'COVENANT_CHECK_NOT_ACTIVE_ERROR',
  COVENANT_CHECK_EVENT_NOT_COMPLETED_ERROR: 'COVENANT_CHECK_EVENT_NOT_COMPLETED_ERROR',
  NOT_ALL_MONITORING_ITEMS_HAVE_RESULT_ERROR: 'NOT_ALL_MONITORING_ITEMS_HAVE_RESULT_ERROR',
  DECISION_STATUS_NOT_APPROVED_ERROR: 'DECISION_STATUS_NOT_APPROVED_ERROR',
  DECISION_PAPER_NOT_IN_SYNC_ERROR: 'DECISION_PAPER_NOT_IN_SYNC_ERROR',
  NO_DECISION_STAGE_FOUND_ERROR: 'NO_DECISION_STAGE_FOUND_ERROR',
  NO_LINKED_EVENT_ID_ERROR: 'NO_LINKED_EVENT_ID_ERROR',
}

const CovenantCheckMessageStrips = ({ covenantCheckUuid, checkName, eventId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.covenants.covenant-checks.message-strips',
  })

  const {
    isFetching: isFetchingActions,
    isError: isErrorActions,
    data: { isClosable, errorCodes = [] },
  } = useCovenantCheckActions({ covenantCheckUuid })
  const hasErrorCode = (errorCode) => errorCodes?.includes(errorCode)

  if (hasErrorCode(possibleErrorCodes.COVENANT_CHECK_NOT_ACTIVE_ERROR)) return null

  const decisionPaperNotSynced =
    hasErrorCode(possibleErrorCodes.DECISION_PAPER_NOT_IN_SYNC_ERROR) &&
    hasErrorCode(possibleErrorCodes.NO_DECISION_STAGE_FOUND_ERROR)

  const eventNeedsToBeClosed = hasErrorCode(
    possibleErrorCodes.COVENANT_CHECK_EVENT_NOT_COMPLETED_ERROR,
  )

  return (
    <RequestStateResolver
      center
      isLoading={isFetchingActions}
      isError={isErrorActions}
      errorToDisplay={<ErrorDataUnavailableInContent />}
      renderContent={() => (
        <div className={styles.container}>
          {decisionPaperNotSynced && (
            <MessageStrip
              design={MessageStripDesign.Warning}
              hideCloseButton
              id="decision-paper-not-in-sync"
            >
              {t('decision-paper-not-in-sync', { checkName })}
              <Link
                href={`/${paths.businessEventsAndTasks}/${paths.businessEvents}/${eventId}/decision-paper`}
                target="_blank"
              >
                {t('open-decision-paper')}
              </Link>
            </MessageStrip>
          )}
          {eventNeedsToBeClosed && (
            <MessageStrip
              design={MessageStripDesign.Warning}
              hideCloseButton
              id="event-needs-closing"
            >
              {t('event-needs-closing', { checkName })}
            </MessageStrip>
          )}
          {isClosable && (
            <MessageStrip
              design={MessageStripDesign.Warning}
              hideCloseButton
              id="check-needs-closing"
            >
              {t('check-needs-closing', { checkName })}
            </MessageStrip>
          )}
        </div>
      )}
    />
  )
}

CovenantCheckMessageStrips.propTypes = {
  covenantCheckUuid: PropTypes.string.isRequired,
  checkName: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
}

export default CovenantCheckMessageStrips
