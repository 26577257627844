import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Table,
  TableCell,
  TableColumn,
  TableRow,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/generic/DecisionPaperManualTileTable.module.css'

const DecisionPaperManualTileTable = ({
  columnLabelsAndAlignments,
  rowDataView,
  rowDataEdit,
  isEditMode,
}) => {
  const renderedColumns = useMemo(() => {
    const numberOfColumns = columnLabelsAndAlignments?.length
    return columnLabelsAndAlignments.map((column, index) => {
      const displayValue = column.alignRight ? (
        <FlexBox
          direction={FlexBoxDirection.Row}
          justifyContent={FlexBoxJustifyContent.End}
          alignItems={FlexBoxAlignItems.End}
        >
          {column.label}
        </FlexBox>
      ) : (
        <>{column.label}</>
      )

      return (
        <TableColumn
          key={`dp-column-${column.label}-${index}`}
          style={{ width: `${100 / numberOfColumns}%` }}
          className={index ? styles.columnSeparator : ''}
        >
          {displayValue}
        </TableColumn>
      )
    })
  }, [columnLabelsAndAlignments])

  const renderedRows = useMemo(() => {
    const rowData = isEditMode ? rowDataEdit : rowDataView
    return rowData?.map((row, idx) => (
      <TableRow key={`dp-row-${String(row)}-${idx}`} className={styles.tableRow}>
        {row.map((cell, colIndex) => {
          const alignRight = columnLabelsAndAlignments[colIndex]?.alignRight
          const content = alignRight ? (
            <FlexBox
              direction={FlexBoxDirection.Row}
              justifyContent={FlexBoxJustifyContent.End}
              alignItems={FlexBoxAlignItems.End}
            >
              {cell}
            </FlexBox>
          ) : (
            <>{cell}</>
          )
          return (
            <TableCell
              key={`dp-row-${idx}-cell-${String(cell)}-${colIndex}`}
              className={colIndex ? styles.columnSeparator : ''}
            >
              {content}
            </TableCell>
          )
        })}
      </TableRow>
    ))
  }, [columnLabelsAndAlignments, isEditMode, rowDataEdit, rowDataView])

  return (
    <div className={styles.tableWrapper}>
      <Table columns={renderedColumns} className={styles.table}>
        {renderedRows}
      </Table>
    </div>
  )
}

DecisionPaperManualTileTable.propTypes = {
  columnLabelsAndAlignments: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, alignRight: PropTypes.bool }),
  ).isRequired,
  rowDataView: PropTypes.array,
  rowDataEdit: PropTypes.array,
  isEditMode: PropTypes.bool,
}

export default DecisionPaperManualTileTable
