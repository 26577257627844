import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import { Input, SuggestionItem } from '@fioneer/ui5-webcomponents-react'
import throttle from 'lodash.throttle'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useLocationAutocomplete, useLocationReverseGeocoding } from 'lib/locationiq/useLocationIq'

const defaultFormatAddress = ({ city, country, name, road, house_number, postcode }) => {
  const line1 = [road, house_number].filter((it) => it?.length > 0).join(' ') || name
  const line2 = [postcode, city].filter((it) => it?.length > 0).join(' ')
  return [line1, line2, country].filter((it) => it?.length > 0).join(', ')
}

const parseCountryCode = (input) => {
  if (!input) {
    return null
  }
  return input.toUpperCase()
}

const renderSuggestionItem = (
  { displayName, displayPlace, address, latitude, longitude, type },
  key,
  props,
  formatAddress = defaultFormatAddress,
) => (
  <SuggestionItem
    key={key}
    text={formatAddress(address)}
    description={displayName}
    data-city={type === 'city' ? address.name : address.city}
    data-country={address.country}
    data-countryCode={address.country_code}
    data-road={address.road}
    data-houseNumber={address.house_number}
    data-postcode={address.postcode}
    data-name={displayPlace}
    data-latitude={latitude}
    data-longitude={longitude}
    {...props}
  />
)

const getDataFromSuggestionItem = (suggestionItem) => ({
  city: suggestionItem.getAttribute('data-city'),
  country: suggestionItem.getAttribute('data-country'),
  countryCode: parseCountryCode(suggestionItem.getAttribute('data-countryCode')),
  road: suggestionItem.getAttribute('data-road'),
  houseNumber: suggestionItem.getAttribute('data-houseNumber'),
  postcode: suggestionItem.getAttribute('data-postcode'),
  name: suggestionItem.getAttribute('data-name'),
  latitude: suggestionItem.getAttribute('data-latitude'),
  longitude: suggestionItem.getAttribute('data-longitude'),
})

const autocompleteHandler = (query, apiParams, handler) => handler(query, apiParams)

const DEFAULT_DEBOUNCE_TIMEOUT = 500
const DEFAULT_MIN_INPUT_LENGTH = 3
const DEFAULT_SUGGESTION_LIMIT = 5

const AddressAutocompleteInput = ({
  onAddressSelected = (_0, _1) => {},
  suggestionItemProps = {},
  debounceTimeout = DEFAULT_DEBOUNCE_TIMEOUT,
  minInputLengthForSuggestions = DEFAULT_MIN_INPUT_LENGTH,
  suggestionLimit = DEFAULT_SUGGESTION_LIMIT,
  apiParams = {},
  formatAddress = defaultFormatAddress,
  onInput = (_) => {},
  ...props
}) => {
  const [isReverseGeocoding, setIsReverseGeocoding] = useState(false)
  const [intermediateAddress, setIntermediateAddress] = useState()
  const { autocompleteResults, setAutocompleteQuery } = useLocationAutocomplete(
    minInputLengthForSuggestions,
    suggestionLimit,
  )
  const { reverseGeocodingResults, isReverseGeocodingLoading, setReverseGeocodingRequest } =
    useLocationReverseGeocoding()
  const setAutocompleteQueryThrottled = useMemo(
    () => throttle(autocompleteHandler, debounceTimeout, { trailing: true }),
    [debounceTimeout],
  )

  const suggestions = autocompleteResults?.map((data, key) =>
    renderSuggestionItem(data, key, suggestionItemProps, formatAddress),
  )

  const onSuggestionItemSelect = (event) => {
    const address = getDataFromSuggestionItem(event.detail.item)
    if (address.postcode) {
      setReverseGeocodingRequest(address.latitude, address.longitude)
      setIsReverseGeocoding(true)
      setIntermediateAddress(address)
    } else {
      onAddressSelected(address)
    }
    onAddressSelected(getDataFromSuggestionItem(event.detail.item), event)
  }

  useEffect(() => {
    if (isReverseGeocoding && !isReverseGeocodingLoading) {
      const overwritePostcode =
        reverseGeocodingResults?.address?.postcode || intermediateAddress?.postcode
      onAddressSelected({
        ...intermediateAddress,
        postcode: overwritePostcode,
      })
      setIsReverseGeocoding(false)
      setIntermediateAddress()
    }
  }, [
    intermediateAddress,
    isReverseGeocoding,
    isReverseGeocodingLoading,
    onAddressSelected,
    reverseGeocodingResults,
  ])

  return (
    <>
      <Input
        showSuggestions
        onInput={(event) => {
          setAutocompleteQueryThrottled(
            event.target.value,
            { language: 'de', ...apiParams }, // See CWP-9000, the language should be german by default
            setAutocompleteQuery,
          )
          return onInput(event)
        }}
        onSuggestionItemSelect={onSuggestionItemSelect}
        maxlength={200}
        {...props}
      >
        {suggestions}
      </Input>
    </>
  )
}

AddressAutocompleteInput.propTypes = {
  ...Input.propTypes,
  onAddressSelected: PropTypes.func,
  suggestionItemProps: PropTypes.object,
  debounceTimeout: PropTypes.number,
  minInputLengthForSuggestions: PropTypes.number,
  suggestionLimit: PropTypes.number,
  apiParams: PropTypes.object,
  formatAddress: PropTypes.func,
  onInput: PropTypes.func,
}

export default AddressAutocompleteInput
