import usePageConfig from 'hooks/services/page-config/usePageConfig'

/**
 * @enum
 * @readonly
 */
export const availableDynamicPagesEnum = {
  PropertiesRentRoll: 'PROPERTIES_RENT_ROLL',
  PropertiesOverview: 'PROPERTIES_OVERVIEW',
  PropertiesGeneralInformation: 'PROPERTIES_GENERAL_INFORMATION',
  PropertiesValuations: 'PROPERTIES_VALUATIONS',
  PropertiesInsurancesCollaterals: 'PROPERTIES_INSURANCES_COLLATERALS',
  PropertiesKeyPerformanceIndicators: 'PROPERTIES_KEY_PERFORMANCE_INDICATORS',
  PropertiesKeyPerformanceIndicatorsDetails: 'PROPERTIES_KEY_PERFORMANCE_INDICATORS_DETAILS',
  PropertiesMonitoring: 'PROPERTIES_MONITORING',
  PropertiesDocuments: 'PROPERTIES_DOCUMENTS',
}

/**
 * Hook API for accessing the configuration
 * of a specific page.
 * @param {Object} props
 * @param {string} props.code
 * @returns {Object}
 */
export const useGetPageConfig = ({ code }) => {
  const { data: pageConfigData = {} } = usePageConfig(code)
  return pageConfigData
}
