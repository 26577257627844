import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared//PropertyRentRollOverviewVacancyOverTimeCardContent.module.css'
import PropertyRentRollOverviewVacancyOverTimeChart from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared//PropertyRentRollOverviewVacancyOverTimeChart'
import useHistoricalUsageTypeChartData from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/tenancy-rent-roll/shared//useHistoricalUsageTypeChartData'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/tegovaRating/shared/EmptyCardContent'

const getVacancyForUsageType = (usageTypeKpi) => usageTypeKpi?.vacancySurface?.percent ?? 0

const getTotalPropertyVacancy = (propertyKpis) => ({
  total: propertyKpis?.vacancySurface?.percent ?? 0,
})

const aggregateGroupedUsageTypeValues = (otherValues) =>
  otherValues?.length ? otherValues.reduce((acc, curr) => acc + curr) / otherValues.length : 0

const TenancyOverviewVacancyOverTimeChartWrapper = ({ historicalPropertyKpiData }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.property.rent-roll.overview.vacancy-over-time.chart',
  })

  const { chartData, usageTypes } = useHistoricalUsageTypeChartData(historicalPropertyKpiData, {
    getMetricValueForUsageTypeKpi: getVacancyForUsageType,
    getPropertyMetrics: getTotalPropertyVacancy,
    aggregateGroupedUsageTypeValues: aggregateGroupedUsageTypeValues,
  })

  const isEmpty = chartData.length === 0

  return (
    <div className={styles.chartContainer}>
      {isEmpty ? (
        <EmptyCardContent title={t('empty.title')} subtitle={t('empty.subtitle')} />
      ) : (
        <PropertyRentRollOverviewVacancyOverTimeChart data={chartData} usageTypes={usageTypes} />
      )}
    </div>
  )
}
TenancyOverviewVacancyOverTimeChartWrapper.propTypes = {
  historicalPropertyKpiData: PropTypes.object.isRequired,
}

export default TenancyOverviewVacancyOverTimeChartWrapper
