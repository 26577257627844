import { FlexBox, FlexBoxDirection, Link } from '@fioneer/ui5-webcomponents-react'
import parse from 'html-react-parser'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/custom-assessment/borrower-esg-statement/BorrowerEsgStatementTile.module.css'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/custom-assessment/borrower-esg-statement/shared/EmptyCardContent'
import ImageView from 'components/domains/business-events-and-tasks/decision-paper/tiles/custom-assessment/borrower-esg-statement/shared/ImageView'

const characterAmount = 1000

const BorrowerEsgStatementTile = ({ tileId, selectedBorrowerId }) => {
  const { t } = useTranslation('decisionPaper')
  const tileStatus = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const [isTextExpanded, setIsTextExpanded] = useState(false)
  const borrowerEsgStatement = tileStatus?.data?.multipleCustomAssessmentData?.find(
    (mp) => mp.uuid === selectedBorrowerId,
  )
  const lastBorrowerEsgStatement = orderBy(
    borrowerEsgStatement?.versions,
    (v) => v.updatedAt,
    'desc',
  )[0]?.text

  const toggleShowMore = () => {
    setIsTextExpanded(!isTextExpanded)
  }
  const renderContent = () =>
    lastBorrowerEsgStatement?.length <= characterAmount || isTextExpanded
      ? lastBorrowerEsgStatement
      : lastBorrowerEsgStatement?.substring(0, characterAmount)

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.commentWrapper}>
      {lastBorrowerEsgStatement ? (
        <div className={styles.wrapper}>
          <div className={`ck-content ${styles.contentWrapper}`}>
            {parse(renderContent(), {
              replace: ({ name, attribs }) => {
                if (name === 'img') {
                  return <ImageView {...attribs} />
                }
              },
            })}
          </div>
          {lastBorrowerEsgStatement?.length > characterAmount && (
            <Link className={styles.toggleShowMoreLink} onClick={toggleShowMore}>
              {isTextExpanded ? t('buttons.less') : t('buttons.more')}
            </Link>
          )}
        </div>
      ) : (
        <EmptyCardContent />
      )}
    </FlexBox>
  )
}

BorrowerEsgStatementTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  selectedBorrowerId: PropTypes.string.isRequired,
}
export default BorrowerEsgStatementTile
