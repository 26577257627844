import { useMutation, useQueryClient } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

/**
 * @param {string} propertyUuid
 * @param {string} entityType
 * @param {import('@tanstack/react-query').UseMutationOptions} [options]
 */
const useUpdatePropertyCustomFields = (propertyUuid, entityType, options) => {
  const { patch } = useAccessTokenRequest()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ customFields }) =>
      await patch({
        path: `/properties/${propertyUuid}/custom-fields`,
        body: snakecaseKeys({
          entityType,
          customFields,
        }),
        headers: { 'Content-Type': 'application/json' },
      }),
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['properties', propertyUuid, 'custom-fields'])
      if (options.onSuccess) {
        options.onSuccess(data, variables, context)
      }
    },
  })
}

export default useUpdatePropertyCustomFields
