import {
  Button,
  MessageBox,
  MessageBoxActions,
  MessageBoxTypes,
} from '@fioneer/ui5-webcomponents-react/dist'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/OpenInFssButton.module.css'

/**
 * @typedef Props
 * @property {string} to The link to FSS
 */
/**
 * @type {React.FC<Props>}
 */
const OpenInFssButton = ({ to }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.business-partner.fiscal-information',
  })
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const handleClick = () => {
    setIsConfirmOpen(true)
  }

  const handleMessageBoxClose = (e) => {
    setIsConfirmOpen(false)
    if (e.detail.action === MessageBoxActions.OK) {
      window.open(to, '_blank')
    }
  }

  return (
    <>
      <Button onClick={handleClick} className={styles.button}>
        {t('button')}
      </Button>
      {createPortal(
        <MessageBox
          open={isConfirmOpen}
          onClose={handleMessageBoxClose}
          type={MessageBoxTypes.Information}
          actions={[MessageBoxActions.OK, MessageBoxActions.Close]}
        >
          {t('confirmation-message')}
        </MessageBox>,
        document.body,
      )}
    </>
  )
}

OpenInFssButton.propTypes = {
  to: PropTypes.string,
}

export default OpenInFssButton
