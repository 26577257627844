import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ShortenedRichText from 'components/ui/rich-text/ShortenedRichText'
import CWPCKEditor from 'components/ui/rich-text-editor/CWPCKEditor'
import { toolbarConfigWithImages } from 'components/ui/rich-text-editor/CWPCKEditorToolbarConfigurations'
import FormattedTextView from 'components/ui/rich-text-editor/FormattedTextView'

const ConditionsTableDescriptionCell = ({
  description,
  isEditMode,
  onConditionChanged,
  showMoreButtons = true,
  setIsDataUploading,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.conditions.table.description',
  })

  if (isEditMode) {
    return (
      <CWPCKEditor
        setCurrentContent={onConditionChanged}
        currentContent={description}
        placeholderText={t('placeholder')}
        toolbarConfig={toolbarConfigWithImages}
        setIsDataUploading={setIsDataUploading}
      />
    )
  }
  return showMoreButtons ? (
    <ShortenedRichText description={description} />
  ) : (
    <FormattedTextView formattedText={description} />
  )
}

ConditionsTableDescriptionCell.propTypes = {
  description: PropTypes.string,
  isEditMode: PropTypes.bool.isRequired,
  onConditionChanged: PropTypes.func.isRequired,
  showMoreButtons: PropTypes.bool,
  setIsDataUploading: PropTypes.func.isRequired,
}

export default ConditionsTableDescriptionCell
