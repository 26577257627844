import isNil from 'lodash.isnil'

const mergeBackendAndEditData = (backendTableData, editOptions) => {
  let mergedTableData = [...backendTableData]

  for (const [editedOptionId, editedOption] of Object.entries(editOptions)) {
    mergedTableData = mergedTableData.map((backendCluster) => {
      const mergedOptions = backendCluster.subRows.map((backendOption) => {
        if (backendOption.id === editedOptionId) {
          let mergedOptionAttributes = [...backendOption.subRows]

          for (const [editedAttributeId, editedAttribute] of Object.entries(editedOption)) {
            mergedOptionAttributes = mergedOptionAttributes.map((backendOptionAttribute) => {
              if (backendOptionAttribute.id === editedAttributeId) {
                return {
                  ...backendOptionAttribute,
                  value: editedAttribute.value,
                }
              } else {
                return backendOptionAttribute
              }
            })
          }

          return {
            ...backendOption,
            subRows: mergedOptionAttributes,
            isActive: isNil(editedOption.isActive) ? backendOption.isActive : editedOption.isActive,
          }
        } else {
          return backendOption
        }
      })

      return { ...backendCluster, subRows: mergedOptions }
    })
  }

  return mergedTableData
}

export default mergeBackendAndEditData
