import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useCustomizableCurrencyFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/i18n/useI18n'
import useTableInputSelectionWorkaround from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/valuation-results/shared/valuation-results/cells/shared/useTableInputSelectionWorkaround'

const PropertyValuationResultsValueAmountCell = ({ valueAmount, currency }) => {
  const inputRef = useRef()
  const formatCurrency = useCustomizableCurrencyFormatter({
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  useTableInputSelectionWorkaround(inputRef)

  if (!valueAmount || !currency) {
    return null
  }
  return <Text>{formatCurrency(valueAmount, currency)}</Text>
}

PropertyValuationResultsValueAmountCell.propTypes = {
  valueAmount: PropTypes.number,
  currency: PropTypes.string,
  isAddMode: PropTypes.bool.isRequired,
}

export default PropertyValuationResultsValueAmountCell
