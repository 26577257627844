import { TableRowType } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import ConditionRequirementChangelogEntryCell from 'components/domains/conditions/dialogs/changelog/table/table-cells/ConditionRequirementChangelogEntryCell'
import ConditionRequirementChangelogTriggeredByCell from 'components/domains/conditions/dialogs/changelog/table/table-cells/ConditionRequirementChangelogTriggeredByCell'
import { ConditionsChangelogColumn } from 'components/domains/conditions/dialogs/changelog/table/useConditionsChangelogColumns'
import DealChangeOverviewActionCell from 'components/domains/deals/change-overview/DealChangeOverviewActionCell'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const useMapConditionsChangelogDataToTableData = ({ changeLogData }) => {
  const { format: formatDate } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
  })
  const tableData = useMemo(
    () =>
      (changeLogData ?? []).map(
        ({ uuid, entityType, entityId, action, triggeredAt, triggeredBy, changedFields }) => ({
          rowKey: `changelog-entry-${uuid}`,
          rowProperties: { type: TableRowType.Inactive },
          [ConditionsChangelogColumn.changedInformation]: {
            cellComponent: (
              <ConditionRequirementChangelogEntryCell
                action={action}
                entityType={entityType}
                entityId={entityId}
                changedFields={changedFields}
              />
            ),
          },
          [ConditionsChangelogColumn.action]: {
            cellComponent: <DealChangeOverviewActionCell action={action} />,
          },
          [ConditionsChangelogColumn.changedAt]: {
            cellComponent: formatDate(triggeredAt),
          },
          [ConditionsChangelogColumn.changedBy]: {
            cellComponent: (
              <ConditionRequirementChangelogTriggeredByCell triggeredBy={triggeredBy} />
            ),
          },
        }),
      ),
    [changeLogData, formatDate],
  )
  return useMemo(() => ({ tableData }), [tableData])
}

export default useMapConditionsChangelogDataToTableData
