import { Text } from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/shared-components/MoneyWithShareCell.module.css'
import SmallLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/ui/loading/SmallLoadingWrapper'

const TextCell = ({ value, isLoading = false, isError = false }) => {
  const { t } = useTranslation('decisionPaper')
  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      error={
        <div className={styles.errorText}>
          {t('decision-paper.unit-overview.components.error.data-unavailable.cell.title-text')}
        </div>
      }
      renderContent={() => <Text>{value && typeof value === 'object' ? value.value : value}</Text>} // TODO: CWP-11196: can be simplified when mappers work analogous
    />
  )
}

TextCell.propTypes = {
  value: PropType.oneOfType([PropType.string, PropType.object]),
  isLoading: PropType.bool,
  isError: PropType.bool,
}
export default TextCell
