import {
  BusyIndicatorSize,
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  Label,
  TableRowType,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import get from 'lodash.get'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import EventCreationEditTaskDialog from 'components/domains/business-events-and-tasks/events/creation/EventCreationEditTaskDialog'
import styles from 'components/domains/business-events-and-tasks/events/creation/EventCreationTasksTile.module.css'
import TasksTableAssigneeCustomFilter from 'components/domains/business-events-and-tasks/tasks/table/TasksTableAssigneeCustomFilter'
import TasksTableDescriptionCell from 'components/domains/business-events-and-tasks/tasks/table/TasksTableDescriptionCell'
import TasksTableIsMandatoryCustomFilter from 'components/domains/business-events-and-tasks/tasks/table/TasksTableIsMandatoryCustomFilter'
import Card from 'components/ui/card/Card'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import { filterTypes } from 'components/ui/tables/sorted-tables/useFilters'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { deleteTask } from 'redux/slices/events/eventCreationSlice'

const columnKeyToTaskDataMap = {
  name: 'info.name',
  assignee: 'info.assignee.fullName',
  isMandatory: 'info.isMandatory',
  description: 'info.description',
  comment: 'info.comment',
  dueDate: 'info.dueDate',
}

const EventCreationTasksTile = ({ disabled, isDefaultAssigneesLoading }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.events.creation.table',
  })
  const { t: tCommon } = useTranslation('translation')
  const { format } = useShortDateFormatter()
  const dispatch = useDispatch()
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [editedTaskId, setEditedTaskId] = useState(undefined)
  const [isEditedTaskStandardTask, setIsEditedTaskStandardTask] = useState(true)

  const { tasks } = useSelector((state) => state.events.eventCreation)

  const handleEditButtonClicked = useCallback((task) => {
    setEditedTaskId(task.id)
    setIsEditedTaskStandardTask(task.isDefaultTask)
    setIsEditDialogOpen(true)
  }, [])

  const handleDeleteButtonClicked = useCallback(
    (task) => {
      dispatch(deleteTask(task))
    },
    [dispatch],
  )

  const addTaskButton = useMemo(
    () => (
      <Button
        key="add-task-button"
        design={ButtonDesign.Transparent}
        onClick={() => {
          setEditedTaskId(-1)
          setIsEditedTaskStandardTask(false)
          setIsEditDialogOpen(true)
        }}
      >
        {tCommon('buttons.add')}
      </Button>
    ),
    [tCommon],
  )

  const tasksColumns = [
    {
      title: t('name'),
      columnKey: 'name',
      minWidth: 700,
      demandPopin: true,
      popinText: t('name'),
      style: { width: '450px' },
      filter: filterTypes.CUSTOM,
      additionalFilterOptions: {
        CustomComponent: TasksTableIsMandatoryCustomFilter,
        customFilterFunction: ({ searchValue, cell }) =>
          // needed because searchValue is a string and not a boolean
          searchValue === cell.isMandatory.toString(),
      },
      filterLabelText: t('mandatory'),
    },
    {
      title: t('assignee'),
      columnKey: 'assignee',
      minWidth: 700,
      demandPopin: true,
      sortingDisabled: true,
      popinText: t('assignee'),
      style: { width: '250px' },
      filter: filterTypes.CUSTOM,
      additionalFilterOptions: {
        CustomComponent: TasksTableAssigneeCustomFilter,
        customFilterFunction: ({ searchValue, cell }) => searchValue === cell.value,
      },
    },
    {
      title: t('description'),
      columnKey: 'description',
      minWidth: 700,
      demandPopin: true,
      sortingDisabled: true,
      popinText: t('description'),
      style: { width: '600px' },
    },
    {
      title: t('due-date'),
      columnKey: 'dueDate',
      minWidth: 700,
      demandPopin: true,
      popinText: t('due-date'),
      alignment: FlexBoxAlignItems.End,
      filter: filterTypes.BETWEEN_DATES,
    },
    {
      title: '',
      columnKey: 'actions',
      sortingDisabled: true,
      minWidth: 700,
      demandPopin: true,
      style: { width: '70px' },
      alignment: FlexBoxAlignItems.Start,
    },
  ]

  const tableData = useMemo(
    () =>
      tasks.map((task, index) => {
        const assignee = get(task, columnKeyToTaskDataMap.assignee)
        return {
          rowKey: `tasks-table-${task.id}_${index}`,
          rowProperties: {
            type: TableRowType.Active,
            'data-event-id': task.event?.id,
            'data-task-id': task.id,
          },
          name: {
            cellComponent: (
              <FlexBox direction={FlexBoxDirection.Column}>
                <Label className={styles.columnTaskName} wrappingType={WrappingType.Normal}>
                  {`${get(task, columnKeyToTaskDataMap.name)}`}
                </Label>
                {get(task, columnKeyToTaskDataMap.isMandatory) ? (
                  <Label className={styles.mandatoryLabel}>{t('mandatory')}</Label>
                ) : (
                  ''
                )}
              </FlexBox>
            ),
            value: `${get(task, columnKeyToTaskDataMap.name)}`,
            isMandatory: get(task, columnKeyToTaskDataMap.isMandatory),
          },
          assignee: {
            cellComponent: (
              <RequestStateResolver
                errorToDisplay={<></>}
                isError={false}
                isLoading={isDefaultAssigneesLoading}
                busyIndicatorSize={BusyIndicatorSize.Small}
                renderContent={() => {
                  if (assignee) {
                    return <Label>{assignee}</Label>
                  }
                  return <Label>{'-'}</Label>
                }}
              />
            ),
            value: get(task, columnKeyToTaskDataMap.assignee),
          },
          description: {
            cellComponent: (
              <TasksTableDescriptionCell
                description={get(task, columnKeyToTaskDataMap.description)}
              />
            ),
          },
          dueDate: {
            cellComponent: (
              <Label>
                {format(
                  get(task, columnKeyToTaskDataMap.dueDate)
                    ? get(task, columnKeyToTaskDataMap.dueDate)
                    : '-',
                )}
              </Label>
            ),
            value: get(task, columnKeyToTaskDataMap.dueDate),
          },
          actions: {
            cellComponent: (
              <FlexBox>
                <Button
                  design={ButtonDesign.Transparent}
                  icon="edit"
                  onClick={() => handleEditButtonClicked(task)}
                />
                {!get(task, columnKeyToTaskDataMap.isMandatory) || !get(task, 'isDefaultTask') ? (
                  <Button
                    design={ButtonDesign.Transparent}
                    icon="delete"
                    onClick={() => handleDeleteButtonClicked(task)}
                  />
                ) : (
                  <></>
                )}
              </FlexBox>
            ),
          },
        }
      }),
    [
      format,
      handleDeleteButtonClicked,
      handleEditButtonClicked,
      isDefaultAssigneesLoading,
      t,
      tasks,
    ],
  )

  return (
    <Card className={disabled ? styles.disabled : undefined} {...(!!disabled && { inert: '' })}>
      <div className={styles.tableWrapper}>
        <SortedTable
          columnDefinitions={tasksColumns}
          tableData={tableData}
          toolbarConfig={{
            title: t('toolbar.title'),
            sorting: {
              columnKey: 'name',
              isSortingAscending: true,
            },
            additionalActions: [addTaskButton],
          }}
          noDataText={t('empty')}
        />
        {isEditDialogOpen && (
          <EventCreationEditTaskDialog
            taskId={editedTaskId}
            isDefaultTask={isEditedTaskStandardTask}
            isOpen={isEditDialogOpen}
            setIsOpen={setIsEditDialogOpen}
          />
        )}
      </div>
    </Card>
  )
}

EventCreationTasksTile.propTypes = {
  disabled: PropTypes.bool,
  isDefaultAssigneesLoading: PropTypes.bool.isRequired,
}

export default EventCreationTasksTile
