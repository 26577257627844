// HINT: cache key is stored in `public/service-worker.js`

import isNil from 'lodash.isnil'

// TODO: import this into the service worker
export const CACHE_NAME_DOCUMENTS = 'documents'

// HINT: duplicated from `public/service-worker.js`
// TODO: import this into the service worker
/**
 * @param {string} guid
 * @param {string} [version]
 */
export const createCachedRequest = (guid, version = '') =>
  new Request(`/caches/documents/${guid}/${version}`)

/**
 * @param {string} guid
 * @param {string} [versionGuid]
 */
const isDocumentCached = async (guid, versionGuid) => {
  const documentCache = await caches.open(CACHE_NAME_DOCUMENTS)
  const isCached = !!(await documentCache.match(createCachedRequest(guid, versionGuid), {
    ignoreVary: true,
  }))
  if (!isCached) throw new Error("Document isn't cached")
}

// WARN: This is just a best guess that the service worker will be active in the new tab
//       If it is active in this tab, the chances are good that it'll be able to run in
//       the new tab as well.
// Better Solution: render the document in an iframe and add some JS to the opened page
const isServiceWorkerActive = async () => {
  if (!navigator.serviceWorker) throw new Error('Service Worker is not supported')
  const registration = await navigator.serviceWorker.getRegistration()
  if (!registration) throw new Error('Service worker is inactive')
}

/**
 * @param {string} guid
 * @param {string} [versionGuid]
 */
const canUseCachedDocument = async (guid, versionGuid) => {
  const isCached = isDocumentCached(guid, versionGuid)
  const isSWActive = isServiceWorkerActive()
  const useCachedDocument = await Promise.all([isCached, isSWActive])
    .then(() => true)
    .catch(() => false)
  return useCachedDocument
}

const versionPrefix = '_Version '
const extensionPrefix = '.'

/**
 * Try building a document name like '<documentName>_Version <version>.<extension>'
 * * _If the document name is not provided, the file name will be used instead._
 * * _If the version is not provided, the version part will be omitted._
 * * _If the file name does not contain an extension, the extension will be omitted._
 * @param {Object} params
 * @param {string} params.fileName
 * @param {string} [params.documentName]
 * @param {string} [params.version]
 */
const buildDocumentName = ({ fileName, documentName, version: documentVersion }) => {
  if (!documentName) {
    return fileName
  }

  const hasFileExtension = fileName.includes('.')
  const fileExtension = fileName.split('.').pop()
  const hasVersion = !isNil(documentVersion) && documentVersion !== ''
  const version = hasVersion ? `${versionPrefix}${documentVersion}` : ''
  const extension = hasFileExtension ? `${extensionPrefix}${fileExtension}` : ''

  const finalName = `${documentName}${version}${extension}`
  return finalName
}

/**
 * @param {Object} params
 * @param {string} params.guid
 * @param {string} params.fileName
 * @param {Blob} params.body
 * @param {string} [params.versionGuid]
 * @param {string} [params.documentName]
 * @param {string} [params.version]
 */
const downloadDocument = async ({ guid, fileName, body, versionGuid, documentName, version }) => {
  const useCachedDocument = await canUseCachedDocument(guid, versionGuid)
  if (useCachedDocument) {
    const finalName = buildDocumentName({ fileName, documentName, version })
    window.open(`/documents/${guid}/${versionGuid}/raw/${finalName}`, '_blank')
    // WARN: there is a small window in which deleting the value from the cache would break this
    //       Can happen anytime, since the browser is allowed to delete the cache
    //       this is tough to handle, we might need to create some kind of channel over which
    //       the information would be sent to the top scope, and then display an error message
    //       Or resend the request to fetch the document, but the service worker has no access to the JWT
  } else {
    // HINT: fallback if we can't use the cache / service worker
    // eslint-disable-next-line no-console
    console.info(
      `Didn't find the cached response for document ${guid} in version ${versionGuid}` +
        '\nFalling back to `createObjectURL`',
    )
    const eloObjectLink = window.URL.createObjectURL(body)
    window.open(eloObjectLink, '_blank')
  }
}

export default downloadDocument
