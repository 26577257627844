import { sumBy, isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useDealUuidByTileCode } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/working-version/useDealUuidByTileCode'
import useDealProperties from 'hooks/services/deals/properties/useDealProperties'
import { useTegovaRating } from 'hooks/services/properties/rating/useTegovaRating'
import { useMultiPropertyViewByPropertyUuids } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuids'
import { useProperty } from 'hooks/services/properties/useProperty'
import useMultiPropertyValuations from 'hooks/services/properties/valuations/useMultiPropertyValuations'

const getRatingClasses = (tegovaRating) => ({
  market: tegovaRating?.market?.market,
  location: tegovaRating?.location?.location,
  property: tegovaRating?.property?.property,
  cashflow: tegovaRating?.cashflow?.cashflow,
  relett: tegovaRating?.cashflow?.lettingProspects,
  thirdParty: tegovaRating?.cashflow?.usabilityByThirdParties,
  total: tegovaRating?.total,
})

const checkAllRatingsZero = (tegovaRating) => Object.values(tegovaRating).every((rating) => !rating)

const getWeightedRating = (rating, marketValue, marketValueSum) => {
  if (!rating || !marketValue || !marketValueSum) {
    return 0
  }
  return (marketValue / marketValueSum) * rating
}

const getRatings = (tegovaRatingResult, valuations, isMultiProperty) => {
  if (!isMultiProperty) {
    return getRatingClasses(tegovaRatingResult?.[0])
  }
  const weightedTegovaRatingsList = []
  const tegovaRatingNotEmptyList = []

  const getMarketValue = (singleTegovaRating) => {
    const propertyUuid = singleTegovaRating.propertyUuid
    return valuations?.[propertyUuid]?.MARKET_VALUE?.value_amount?.number
  }

  let marketValueSum = 0

  tegovaRatingResult.map((tegovaRating) => {
    if (!isEmpty(tegovaRating)) {
      const tegovaRatingClass = getRatingClasses(tegovaRating)
      if (!checkAllRatingsZero(tegovaRatingClass)) {
        const marketValue = getMarketValue(tegovaRating)
        marketValueSum = marketValue ? marketValueSum + marketValue : marketValueSum
        tegovaRatingNotEmptyList.push(tegovaRating)
      }
    }
  })
  if (isEmpty(tegovaRatingNotEmptyList)) {
    return undefined
  }

  tegovaRatingNotEmptyList.forEach((tegovaRating) => {
    const singleWeightedTegovaRating = []
    const ratingClass = getRatingClasses(tegovaRating)
    const marketValue = getMarketValue(tegovaRating)

    Object.entries(ratingClass).forEach(([ratingLabel, rating]) => {
      const weightedRating = getWeightedRating(rating, marketValue, marketValueSum)

      singleWeightedTegovaRating.push({
        [ratingLabel]: parseFloat(weightedRating),
      })
    })
    weightedTegovaRatingsList.push(...singleWeightedTegovaRating)
  })

  const getWeightedRatings = (ratingClass) => sumBy(weightedTegovaRatingsList, ratingClass)

  return {
    market: getWeightedRatings('market'),
    location: getWeightedRatings('location'),
    property: getWeightedRatings('property'),
    cashflow: getWeightedRatings('cashflow'),
    relett: getWeightedRatings('relett'),
    thirdParty: getWeightedRatings('thirdParty'),
    total: getWeightedRatings('total'),
  }
}

export const useFactSheetTegovaRatings = ({ entityRef: { entityId: dealUuid } }, _, tileCode) => {
  const {
    data: { dealUuid: dealUuidByTileCode } = {},
    isFetching: isDealUuidFetching,
    isError: isDealUuidError,
  } = useDealUuidByTileCode({
    dealUuid,
    tileCode,
  })

  const dealPropertiesResult = useDealProperties({ dealUuid: dealUuidByTileCode })
  const isPropertyRequestEnabled = useMemo(
    () =>
      !dealPropertiesResult.isFetching &&
      !dealPropertiesResult.isError &&
      !!dealPropertiesResult?.data &&
      dealPropertiesResult?.data?.dealProperties?.length > 0,
    [dealPropertiesResult],
  )

  const propertyUuidList = useMemo(
    () =>
      isPropertyRequestEnabled
        ? dealPropertiesResult.data.dealProperties?.map((property) => property.propertyUuid)
        : [],
    [dealPropertiesResult, isPropertyRequestEnabled],
  )
  const tegovaRatingResult = useTegovaRating(propertyUuidList, {
    enabled: isPropertyRequestEnabled,
  })

  const tegovaRatingData = useMemo(
    () => (tegovaRatingResult?.data?.tegovaRatings ? tegovaRatingResult.data.tegovaRatings : []),
    [tegovaRatingResult],
  )
  const checkIsMultiProperty = propertyUuidList?.length > 1

  const {
    isFetching: isLoadingValuation,
    isError: isErrorValuation,
    data: valuations,
  } = useMultiPropertyValuations(propertyUuidList, ['SUM'], undefined, {
    enabled: isPropertyRequestEnabled && checkIsMultiProperty,
  })

  const weightedTegovaRating = useMemo(
    () => getRatings(tegovaRatingData, valuations?.valuations, checkIsMultiProperty),
    [checkIsMultiProperty, tegovaRatingData, valuations?.valuations],
  )

  const multiPropertyViewResponse = useMultiPropertyViewByPropertyUuids(propertyUuidList, {
    enabled: isPropertyRequestEnabled && checkIsMultiProperty,
  })
  const multiPropertyViewId = useMemo(
    () => multiPropertyViewResponse?.data?.data?.uuid,
    [multiPropertyViewResponse],
  )

  const propertyResults = useProperty(propertyUuidList[0], true, {
    enabled: isPropertyRequestEnabled && !checkIsMultiProperty,
  })

  const propertyId = useMemo(() => propertyResults?.data?.id, [propertyResults])

  const sourcePath = useMemo(
    () =>
      checkIsMultiProperty
        ? `/properties/portfolio/overview?portfolio-view-id=${multiPropertyViewId}`
        : `/properties/${propertyId}/general-information#rating-tab`,
    [checkIsMultiProperty, multiPropertyViewId, propertyId],
  )
  return useMemo(() => {
    const isLoading =
      isDealUuidFetching ||
      dealPropertiesResult.isFetching ||
      isLoadingValuation ||
      multiPropertyViewResponse.isFetching ||
      propertyResults.isFetching ||
      tegovaRatingResult.isFetching
    const isError =
      isDealUuidError ||
      dealPropertiesResult.isError ||
      isErrorValuation ||
      multiPropertyViewResponse.isError ||
      propertyResults.isError ||
      tegovaRatingResult.isError
    if (
      !isDealUuidFetching &&
      !isDealUuidError &&
      !dealPropertiesResult.isFetching &&
      !dealPropertiesResult.isError &&
      (!dealPropertiesResult?.data?.dealProperties ||
        dealPropertiesResult?.data?.dealProperties?.length === 0)
    ) {
      return {
        isLoading: false,
        isError: false,
        data: {
          noProperties: true,
        },
      }
    }
    return {
      isLoading,
      isError,
      data: {
        weightedTegovaRating,
        emptyTegovaRating: weightedTegovaRating ? checkAllRatingsZero(weightedTegovaRating) : true,
        sourceRender: { path: `${sourcePath}` },
        noProperties: false,
      },
    }
  }, [
    dealPropertiesResult?.data?.dealProperties,
    dealPropertiesResult.isError,
    dealPropertiesResult.isFetching,
    isDealUuidFetching,
    isDealUuidError,
    isErrorValuation,
    isLoadingValuation,
    multiPropertyViewResponse,
    propertyResults,
    sourcePath,
    tegovaRatingResult.isError,
    tegovaRatingResult.isFetching,
    weightedTegovaRating,
  ])
}
