import { Link } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-reporting-summary/shared/rich-text/DecisionPaperCollapsibleRichTextView.module.css'
import DecisionPaperFormattedTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/arrears/arrears-reporting-summary/shared/rich-text/DecisionPaperFormattedTextView'

const DecisionPaperCollapsibleRichTextView = ({ text, characterLimit, t, ...otherProps }) => {
  const [isTextExpanded, setIsTextExpanded] = useState(false)

  const toggleShowMore = () => {
    setIsTextExpanded(!isTextExpanded)
  }

  const renderContent = () =>
    text?.length <= characterLimit || isTextExpanded ? text : text?.substring(0, characterLimit)

  return (
    <div className={styles.wrapper}>
      <DecisionPaperFormattedTextView formattedText={renderContent()} {...otherProps} />
      {text?.length > characterLimit && (
        <Link className={styles.toggleShowMoreLink} onClick={toggleShowMore}>
          {isTextExpanded ? t('buttons.less') : t('buttons.more')}
        </Link>
      )}
    </div>
  )
}

DecisionPaperCollapsibleRichTextView.propTypes = {
  text: PropTypes.string,
  characterLimit: PropTypes.number,
  t: PropTypes.func,
}

export default DecisionPaperCollapsibleRichTextView
