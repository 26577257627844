import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/shared-components/DateCell.module.css'
import SmallLoadingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/gcc-involved-parties/unit-overview/shared/ui/loading/SmallLoadingWrapper'
import { useShortDateFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/hooks/i18n/useI18n'

const DateCell = ({ date, isBold = false, isLoading = false, isError = false }) => {
  const { t } = useTranslation('decisionPaper')
  const { format: formatDate } = useShortDateFormatter()

  if (isNil(date)) {
    return ''
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      error={
        <div className={styles.errorText}>
          {t('decision-paper.unit-overview.components.error.data-unavailable.cell.title-text')}
        </div>
      }
      renderContent={() => <div className={isBold ? styles.textBold : ''}>{formatDate(date)}</div>}
    />
  )
}

DateCell.propTypes = {
  date: PropTypes.string,
  isBold: PropTypes.bool,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
}

export default DateCell
