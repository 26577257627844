import compact from 'lodash.compact'
import uniq from 'lodash.uniq'
import { useMemo } from 'react'
import useAnnualReviewDealOverviewDeals from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/annual-review/deal-overview/shared/useAnnualReviewDealOverviewDeals'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useMultipleDealProperties from 'hooks/services/deals/properties/useMultipleDealProperties'
import usePropertyIdForAssignedMarket from 'hooks/services/markets/usePropertyIdForAssignedMarket'
import { useMultiPropertyViewByPropertyUuidsArrays } from 'hooks/services/properties/useMultiPropertyViewByPropertyUuidsArrays'
import useMultipleArrayPropertyUuids from 'hooks/services/properties/useMultipleArrayPropertyUuids'
import { useCamelizedResponse, useCombinedQueryResults } from 'hooks/services/queryHelper'

const getPropertyInformationData = (propertyInformationResult, assignedMarketData) => {
  if (!propertyInformationResult?.properties) {
    return undefined
  }
  return propertyInformationResult.properties.map((property = {}) => {
    const propertyMarket =
      assignedMarketData?.markets?.find((market) =>
        market?.properties?.find(
          ({ id: marketPropertyUuid }) => marketPropertyUuid === property.uuid,
        ),
      ) ?? {}

    return {
      uuid: property.uuid,
      name: property.description,
      id: property.id,
      type: property.typeName,
      address: {
        country: property.address?.countryName,
        postalCode: property.address?.postalCode,
        city: property.address?.cityName,
        street: property.address?.streetName,
        houseId: property.address?.houseId,
      },
      market: {
        name: propertyMarket?.info?.name,
        id: propertyMarket?.id,
      },
    }
  })
}

const useMultiplePortfolioFinancedAssetsInformation = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { dealUuids, dealsData } = {},
    isLoading: isAnnualReviewDealsLoading,
    isError: isAnnualReviewDealsError,
    error: annualReviewDealsError,
  } = useAnnualReviewDealOverviewDeals({
    businessPartnerId,
  })

  // get an array of property uuids for each deal
  const {
    data: multipleDealPropertiesData,
    isLoading: isMultipleDealPropertiesLoading,
    isError: isMultipleDealPropertiesError,
  } = useCombinedQueryResults(
    useMultipleDealProperties({
      dealUuids,
    }) ?? {},
  )

  const isFollowUpRequestEnabled = useMemo(
    () =>
      !isMultipleDealPropertiesLoading &&
      !isMultipleDealPropertiesError &&
      multipleDealPropertiesData &&
      multipleDealPropertiesData.some((property) => property.dealProperties?.length > 0),
    [isMultipleDealPropertiesError, isMultipleDealPropertiesLoading, multipleDealPropertiesData],
  )

  // create an array of arrays of property uuids related to a deal id
  const multiplePropertyUuidLists = useMemo(
    () =>
      isFollowUpRequestEnabled
        ? multipleDealPropertiesData?.map((dealProperty) =>
            dealProperty.dealProperties.map((property) => property.propertyUuid),
          )
        : [],
    [isFollowUpRequestEnabled, multipleDealPropertiesData],
  )

  // array version of usePropertyUuids:
  // get an array of responses for each properties array (related to a deal id),
  // so one entry per deal on the highest array level
  const {
    data: multipleArrayPropertyUuidsData = [],
    isLoading: isMultipleArrayPropertyUuidsLoading,
    isError: isMultipleArrayPropertyUuidsError,
  } = useMultipleArrayPropertyUuids({
    propertyUuidsArrays: multiplePropertyUuidLists,
    options: { enabled: isFollowUpRequestEnabled },
    shouldBeCamelized: true,
  })

  const flatMultiplePropertyUuidList = uniq(compact(multiplePropertyUuidLists?.flat()))

  const {
    data: assignedMarketData,
    isFetching: isAssignedMarketLoading,
    isError: isAssignedMarketError,
  } = useCamelizedResponse(
    usePropertyIdForAssignedMarket(flatMultiplePropertyUuidList, {
      enabled: isFollowUpRequestEnabled,
    }),
  )

  const multiplePropertyInformation = useMemo(
    () =>
      multipleArrayPropertyUuidsData?.map((propertyUuidsData) =>
        getPropertyInformationData(propertyUuidsData?.data, assignedMarketData),
      ),
    [multipleArrayPropertyUuidsData, assignedMarketData],
  )

  // array version of useMultiPropertyViewByPropertyUuids:
  // in case of multiple properties per array, fetch also the portfolio uuid used in the source link
  const {
    data: multiplePropertyViewByPropertyUuidsArraysData,
    isLoading: isMultiPropertyViewByPropertyUuidsArraysLoading,
    isError: isMultiPropertyViewByPropertyUuidsArraysError,
  } = useMultiPropertyViewByPropertyUuidsArrays({
    propertyUuidsArrays: multiplePropertyUuidLists,
    options: { enabled: isFollowUpRequestEnabled },
  })

  const sourcePaths = useMemo(
    () =>
      dealUuids?.map((dealUuid, index) => {
        const propertiesArrayData = multipleDealPropertiesData?.[index]?.dealProperties
        const multiPropertiesArrayViewUuid =
          multiplePropertyViewByPropertyUuidsArraysData?.[index]?.data?.uuid

        return propertiesArrayData?.length > 1
          ? `/properties/portfolio/overview?portfolio-view-id=${multiPropertiesArrayViewUuid}`
          : `/properties/${multipleArrayPropertyUuidsData?.[index]?.data?.properties?.[0]?.id}`
      }),
    [
      dealUuids,
      multipleDealPropertiesData,
      multiplePropertyViewByPropertyUuidsArraysData,
      multipleArrayPropertyUuidsData,
    ],
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [
      isAnnualReviewDealsLoading,
      isMultipleDealPropertiesLoading,
      isMultipleArrayPropertyUuidsLoading,
      isMultiPropertyViewByPropertyUuidsArraysLoading,
      isAssignedMarketLoading,
    ],
    errorValues: [
      isAnnualReviewDealsError,
      isMultipleDealPropertiesError,
      isMultipleArrayPropertyUuidsError,
      isMultiPropertyViewByPropertyUuidsArraysError,
      isAssignedMarketError,
    ],
    errorDetails: [annualReviewDealsError],
    tileId,
  })

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        multiplePropertyInformation,
        noProperties: !isFollowUpRequestEnabled,
        sourcePaths,
        sourceRender: { path: `${sourcePaths[0]}` },
      },
    }
  }, [
    isSomeValueError,
    isSomeValueLoading,
    dealsData,
    multiplePropertyInformation,
    isFollowUpRequestEnabled,
    sourcePaths,
    error,
  ])
}
export default useMultiplePortfolioFinancedAssetsInformation
