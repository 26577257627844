import { useRequest } from 'hooks/services/baseService'

const useMarketKpis = ({
  marketId,
  sortBy = undefined,
  filterDuplicateValidityDate = false,
  filterKpisForMonitoring = false,
}) => {
  const queryParams = new URLSearchParams()
  if (sortBy) {
    queryParams.append('sort_by', sortBy)
  }
  if (filterKpisForMonitoring) {
    queryParams.append('filter_kpis_for_monitoring', true)
  }
  if (filterDuplicateValidityDate) {
    queryParams.append('filter_duplicate_validity_date', true)
  }
  return useRequest({
    path: `/markets/${marketId}/kpis?${queryParams.toString()}`,
    translated: true,
    useCache: true,
    keys: ['market-kpis', marketId],
  })
}

export default useMarketKpis
