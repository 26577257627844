import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CashFlowScenarioSelectDialog from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/with-meta-data/cash-flow/CashFlowScenarioSelectDialog'
import { hasDecisionPaperUpdatePermissions } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/with-meta-data/shared/actions/TileHeaderEditActions'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const CashFlowTileHeaderSelectActions = ({
  isExpanded,
  expand,
  hasChanges,
  handleSaveSelectedScenario,
  dealUuid,
  metaDataContent,
  setMetaDataContent,
  lastSelectedScenario,
}) => {
  const { t: tCashFlow } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-paper.tiles.cash-flow',
  })

  const { allowedOperations } = useContext(BusinessEventsAndTasksContext)
  const showSelectButton = useMemo(
    () => hasDecisionPaperUpdatePermissions(allowedOperations),
    [allowedOperations],
  )

  const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false)

  const handleSelectScenarioClicked = useCallback(() => {
    setIsSelectDialogOpen(true)
    if (!isExpanded) expand()
  }, [setIsSelectDialogOpen, expand, isExpanded])

  const selectButton = useMemo(
    () =>
      showSelectButton && (
        <Button
          key="tile-select-button"
          onClick={handleSelectScenarioClicked}
          design={ButtonDesign.Transparent}
        >
          {!metaDataContent ? tCashFlow('button.select') : tCashFlow('button.edit')}
        </Button>
      ),
    [handleSelectScenarioClicked, metaDataContent, showSelectButton, tCashFlow],
  )

  if (isSelectDialogOpen) {
    return (
      <CashFlowScenarioSelectDialog
        isSelectDialogOpen={isSelectDialogOpen}
        setIsSelectDialogOpen={setIsSelectDialogOpen}
        handleSaveSelectedScenario={handleSaveSelectedScenario}
        dealUuid={dealUuid}
        cashFlowScenarioContent={metaDataContent}
        setCashFlowScenarioContent={setMetaDataContent}
        hasChanges={hasChanges}
        lastSelectedScenario={lastSelectedScenario}
        tCashFlow={tCashFlow}
      />
    )
  }
  return selectButton
}

CashFlowTileHeaderSelectActions.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  expand: PropTypes.func.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  handleSaveSelectedScenario: PropTypes.func,
  dealUuid: PropTypes.string,
  metaDataContent: PropTypes.object,
  setMetaDataContent: PropTypes.func,
  lastSelectedScenario: PropTypes.object,
}

export default CashFlowTileHeaderSelectActions
