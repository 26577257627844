import {
  ComboBoxFilter,
  MultiComboBox,
  MultiComboBoxGroupItem,
  MultiComboBoxItem,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import debounce from 'lodash.debounce'
import PropTypes from 'prop-types'
import { forwardRef, useCallback, useDeferredValue, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const INPUT_HANDLER_DEBOUNCE_DELAY = 300

const SearchMultiComboBox = forwardRef(
  (
    {
      useLoader,
      selectedItems,
      onSelectionChange,
      filter = ComboBoxFilter.Contains,
      ...otherProps
    },
    ref,
  ) => {
    const { t } = useTranslation('translation', {
      keyPrefix: 'components.search-multi-combo-box',
    })

    const [searchText, setSearchText] = useState('')
    const { data: searchResults, isError, isLoading } = useLoader(useDeferredValue(searchText))

    // the linter can't check for dependencies properly if the callback is wrapped in debounce
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleInput = useCallback(
      // we debounce the input event, so that not on every keystroke a request
      // is sent to the backend, but only after the user has briefly stopped typing
      debounce((event) => {
        setSearchText(event.target.value)
        otherProps.onInput?.(event)
      }, INPUT_HANDLER_DEBOUNCE_DELAY),
      [otherProps],
    )

    const searchResultItems = useMemo(
      () =>
        (searchResults ?? []).filter(
          ({ value }) => !selectedItems.find((item) => item.value === value),
        ),
      [searchResults, selectedItems],
    )

    const handleSelectionChange = useCallback(
      (event) => {
        const nextSelectedItems = (event?.detail?.items ?? []).map((itemElement) => {
          const itemValue = itemElement.getAttribute('value')
          return (
            selectedItems.find(({ value }) => value === itemValue) ??
            searchResultItems.find(({ value }) => value === itemValue)
          )
        })

        onSelectionChange(nextSelectedItems)
      },
      [onSelectionChange, searchResultItems, selectedItems],
    )

    const hasResults = searchResultItems.length > 0
    const isEmpty = !isError && !isLoading && searchText.length > 0 && !hasResults

    const valueState = useMemo(() => {
      if (isError) return ValueState.Error
      if (isEmpty || isLoading) return ValueState.Information
      return ValueState.None
    }, [isEmpty, isError, isLoading])

    const valueStateMessage = useMemo(() => {
      if (isError) return <Text>{t('error')}</Text>
      if (isLoading) return <Text>{t('loading')}</Text>
      if (isEmpty) return <Text>{t('empty')}</Text>
      return null
    }, [isEmpty, isError, isLoading, t])

    return (
      <MultiComboBox
        {...otherProps}
        ref={ref}
        allowCustomValues
        filter={filter}
        onInput={handleInput}
        onSelectionChange={handleSelectionChange}
        valueState={valueState}
        valueStateMessage={valueStateMessage}
      >
        {selectedItems.map((item) => (
          <MultiComboBoxItem selected key={item.value} {...item} />
        ))}
        {!otherProps?.hideResultsGroupItem && hasResults && (
          <MultiComboBoxGroupItem text={t('results')} />
        )}
        {searchResultItems.map((item) => (
          <MultiComboBoxItem key={item.value} {...item} />
        ))}
      </MultiComboBox>
    )
  },
)

SearchMultiComboBox.displayName = 'SearchMultiComboBox'

SearchMultiComboBox.propTypes = {
  useLoader: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      additionalText: PropTypes.string,
    }),
  ).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  filter: PropTypes.oneOf(Object.values(ComboBoxFilter)),
}

export default SearchMultiComboBox
