import { Button } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import 'components/domains/properties/property-create/PropertyCreateButton.css'

const PropertyCreateButton = ({ onShowDialog }) => {
  const { t } = useTranslation()

  const handleOnClick = () => {
    onShowDialog()
  }

  return (
    <>
      <Button id="property-create-button" design="Emphasized" onClick={handleOnClick}>
        {t('pages.property.button.create')}
      </Button>
    </>
  )
}
PropertyCreateButton.propTypes = {
  onShowDialog: PropTypes.func.isRequired,
}

export default PropertyCreateButton
