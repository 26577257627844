import { useMemo } from 'react'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import { useMultipleDealsByUuidMini } from 'hooks/services/deals/useMultipleDealsByUuidMini'
import usePropertyUuids from 'hooks/services/properties/usePropertyUuids'

const emptyArray = []

export const useGetBusinessObjectsName = ({ entityType, entityIds = [], disabled = false }) => {
  const isGenerallyEnabled = !disabled && entityIds.length > 0
  const {
    data: businessPartnerData,
    isLoading: isBusinessPartnerLoading,
    isError: isBusinessPartnerError,
  } = useBusinessPartnerMiniByIds(entityIds, {
    enabled: isGenerallyEnabled && entityType === cwpEntityTypes.BUSINESS_PARTNER,
  })

  const fallBackArray = useMemo(
    () => entityIds.map((entityId) => ({ entityId, entityName: '', displayId: '', entityType })),
    [entityIds, entityType],
  )

  const businessPartners = useMemo(
    () =>
      businessPartnerData?.businessPartnerMinis.map(({ id, fullName }) => ({
        displayId: id,
        entityId: id,
        entityName: fullName,
        entityType: cwpEntityTypes.BUSINESS_PARTNER,
      })) || fallBackArray,
    [businessPartnerData, fallBackArray],
  )

  const dealResponses = useMultipleDealsByUuidMini(entityIds, {
    enabled: isGenerallyEnabled && entityType === cwpEntityTypes.DEAL,
  })

  const deals = useMemo(
    () =>
      dealResponses?.map(({ data: deal }, index) => ({
        displayId: deal?.dealId || '',
        entityId: deal?.dealUuid || entityIds[index],
        entityName: deal?.name || '',
        entityType: cwpEntityTypes.DEAL,
      })) || fallBackArray,
    [dealResponses, entityIds, fallBackArray],
  )
  const isDealsLoading = dealResponses?.some((response) => response.isLoading)
  const isDealsError = dealResponses?.some((response) => response.isError)

  const {
    isLoading: isPropertyLoading,
    isError: isPropertyError,
    data: propertiesData,
  } = usePropertyUuids(
    entityIds,
    {
      enabled: isGenerallyEnabled && entityType === cwpEntityTypes.PROPERTY,
    },
    true,
  )

  const properties = useMemo(
    () =>
      propertiesData?.data?.properties?.map((property) => ({
        displayId: property.id,
        entityId: property.uuid,
        entityName: property.description,
        entityType: cwpEntityTypes.PROPERTY,
      })) || fallBackArray,
    [fallBackArray, propertiesData],
  )

  if (entityType === cwpEntityTypes.BUSINESS_PARTNER) {
    return {
      data: businessPartners,
      isLoading: isGenerallyEnabled && isBusinessPartnerLoading,
      isError: isBusinessPartnerError,
    }
  }
  if (entityType === cwpEntityTypes.PROPERTY) {
    return {
      data: properties,
      isLoading: isGenerallyEnabled && isPropertyLoading,
      isError: isPropertyError,
    }
  }
  if (entityType === cwpEntityTypes.DEAL) {
    return {
      data: deals,
      isLoading: isGenerallyEnabled && isDealsLoading,
      isError: isDealsError,
    }
  }

  return { data: emptyArray, isLoading: false, isError: false }
}
