import { useMemo } from 'react'
import { useRequest, useRequests } from 'hooks/services/baseService'
import { useArrayMemo } from 'hooks/services/queryHelper'

/**
 * Returns all images belonging to a property
 * example data: 
 * [
    {
      uuid: '2667cc26-83d1-435d-96f3-49717526b58f',
      url: 'https://sap.github.io/ui5-webcomponents/assets/images/sample1.jpg',
      property_image_order: 0,
      meta: {
        format: 'image/jpeg',
        name: 'foo.jpg',
        size: 1337000,
        uploaded_by: '1000000', // Business Partner ID
        uploaded_at: '2023-01-04T12:00:00',
      },
    },
    {
      uuid: '2667cc26-83d1-435d-96f3-49717526b58f',
      url: 'https://sap.github.io/ui5-webcomponents/assets/images/sample3.jpg',
      property_image_order: 1,
      meta: {
        format: 'image/jpeg',
        name: 'bar.jpg',
        size: 1234567,
        uploaded_by: '1000000', // Business Partner ID
        uploaded_at: '2023-01-04T12:00:00',
      },
    },
  ]
 * @returns {{isLoading: boolean, isError: boolean, data: Images}}
 */

export const PROPERTY_IMAGES_KEY = 'images'
export const PROPERTIES_KEY = 'properties'

export const usePropertyImages = (propertyUuid, options = {}) =>
  useRequest({
    path: `/properties/${propertyUuid}/images`,
    keys: ['properties', propertyUuid, 'images'],
    options,
  })

export const usePropertiesImages = (propertyUuids = [], options = {}) => {
  const responses = useRequests({
    requests: propertyUuids.map((propertyUuid) => ({
      path: `/properties/${propertyUuid}/images`,
      keys: ['properties', propertyUuid, 'images'],
    })),
    options,
  })

  const isLoading = useMemo(
    () => responses.some(({ isLoading: isPropertyLoading }) => isPropertyLoading),
    [responses],
  )
  const isFetching = useMemo(
    () => responses.some(({ isFetching: isPropertyFetching }) => isPropertyFetching),
    [responses],
  )
  const isError = useMemo(
    () => responses.some(({ isError: isPropertyError }) => isPropertyError),
    [responses],
  )
  const imageData = useArrayMemo(responses.map(({ data }) => data).filter(Boolean))

  return useMemo(
    () => ({
      data: imageData,
      isLoading,
      isFetching,
      isError,
    }),
    [isError, isLoading, isFetching, imageData],
  )
}
