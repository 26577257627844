import isNil from 'lodash.isnil'
import { stringTemplate } from 'utils/stringTemplate'

/**
 * @param {string} templateStr
 * @returns {string[]} the requested variable names
 */
function getRequestedVariables(templateStr) {
  // disable needed because regex needs the escaping but linter does not recognize that.
  // eslint-disable-next-line no-useless-escape
  const matches = templateStr.matchAll(/\$\{\s*([^\}]+)\s*\}/g)
  return [...matches].map(({ 1: variable }) => variable.trim())
}

/**
 * Applies the `data` to the `template` if all variables requested for interpolation are provided.
 * Returns `''` otherwise.
 *
 * **Warning** Only supports interpolation using `'${ someVariable }'`
 * @param {string} templateStr
 * @param {object} data
 * @returns {string}
 */
export default function applyTemplate(templateStr, data) {
  const requestedVariables = getRequestedVariables(templateStr)

  if (requestedVariables.some((variable) => isNil(data?.[variable]))) return ''

  return stringTemplate(templateStr, data)
}
