import { FlexBox, FlexBoxDirection, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/deal-uses-and-sources/table-cells/CurrencyValueCell.module.css'
import { useCustomizableCurrencyFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/deal-uses-and-sources/table-cells/shared/useI18n'

const CurrencyValueCell = ({
  value: { original, headquarter, isEmptyCell, isSectionHeader },
  areAllCurrenciesTheSame,
}) => {
  const formatMoney = useCustomizableCurrencyFormatter()
  const className = isSectionHeader ? styles.bold : ''

  if (isEmptyCell) {
    return
  }

  if (!original && !headquarter) {
    return '-'
  }

  if (original.currency === headquarter.currency || areAllCurrenciesTheSame) {
    return <Text className={className}>{formatMoney(original.value, original.currency)}</Text>
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.currencyGap}>
      <Text>{formatMoney(original.value, original.currency)}</Text>
      <Text className={styles.labelColor}>
        {formatMoney(headquarter.value, headquarter.currency)}
      </Text>
    </FlexBox>
  )
}

CurrencyValueCell.propTypes = {
  value: PropTypes.shape({
    isEmptyCell: PropTypes.bool,
    isSectionHeader: PropTypes.bool,
    original: PropTypes.shape({
      currency: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
    headquarter: PropTypes.shape({
      currency: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  }).isRequired,
  areAllCurrenciesTheSame: PropTypes.bool,
}

export default CurrencyValueCell
