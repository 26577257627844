import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useFinancingAllowedOperations = (dealId) =>
  useCamelizedResponse(
    useRequest({
      path: `/financing-structures/deals/${dealId}/allowed-operations`,
      useCache: true,
      keys: ['deals', dealId, 'allowedOperations'],
      options: {
        enabled: !!dealId,
      },
    }),
  )
