import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-process/alc/DecisionProcessAlcDialog.module.css'
import DecisionProcessAlcAmlRiskClassification from 'components/domains/business-events-and-tasks/decision-process/alc/fields/DecisionProcessAlcAmlRiskClassification'
import DecisionProcessAlcApplicationType from 'components/domains/business-events-and-tasks/decision-process/alc/fields/DecisionProcessAlcApplicationType'
import DecisionProcessAlcBorrower from 'components/domains/business-events-and-tasks/decision-process/alc/fields/DecisionProcessAlcBorrower'
import DecisionProcessAlcBorrowerElClass from 'components/domains/business-events-and-tasks/decision-process/alc/fields/DecisionProcessAlcBorrowerElClass'
import DecisionProcessAlcBorrowerLimit from 'components/domains/business-events-and-tasks/decision-process/alc/fields/DecisionProcessAlcBorrowerLimit'
import DecisionProcessAlcComplianceRiskWithStrategy from 'components/domains/business-events-and-tasks/decision-process/alc/fields/DecisionProcessAlcComplianceRiskWithStrategy'
import DecisionProcessAlcEpDirectCost from 'components/domains/business-events-and-tasks/decision-process/alc/fields/DecisionProcessAlcEpDirectCost'
import DecisionProcessAlcEpFullCost from 'components/domains/business-events-and-tasks/decision-process/alc/fields/DecisionProcessAlcEpFullCost'
import DecisionProcessAlcEpLowerCost from 'components/domains/business-events-and-tasks/decision-process/alc/fields/DecisionProcessAlcEpLowerCost'
import DecisionProcessAlcLargeExposure from 'components/domains/business-events-and-tasks/decision-process/alc/fields/DecisionProcessAlcLargeExposure'
import DecisionProcessAlcLargeExposureMaxLimit from 'components/domains/business-events-and-tasks/decision-process/alc/fields/DecisionProcessAlcLargeExposureMaxLimit'
import DecisionProcessAlcLargeExposureReportLimit from 'components/domains/business-events-and-tasks/decision-process/alc/fields/DecisionProcessAlcLargeExposureReportLimit'
import DecisionProcessAlcOrganCredit from 'components/domains/business-events-and-tasks/decision-process/alc/fields/DecisionProcessAlcOrganCredit'
import DecisionProcessAlcReviewUnit from 'components/domains/business-events-and-tasks/decision-process/alc/fields/DecisionProcessAlcReviewUnit'
import DecisionProcessAlcTotalCreditAuthorityLimit from 'components/domains/business-events-and-tasks/decision-process/alc/fields/DecisionProcessAlcTotalCreditAuthorityLimit'
import DecisionProcessAlcTotalElClass from 'components/domains/business-events-and-tasks/decision-process/alc/fields/DecisionProcessAlcTotalElClass'
import Dialog, { DialogSecondaryButton, DialogSize } from 'components/ui/dialog/Dialog'

const DecisionProcessAlcDialog = ({ isOpen, setIsOpen, dealUuid, borrowerBpId, eventId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-process.overview.alc-info.dialog',
  })
  const { t: tNoPrefix } = useTranslation('translation')

  const closeDialog = useCallback(() => setIsOpen(false), [setIsOpen])

  return (
    <Dialog
      open={isOpen}
      headerText={t('title')}
      onAfterClose={closeDialog}
      size={DialogSize.L}
      closeButton={
        <DialogSecondaryButton id="submit-subportfolio-btn" onClick={closeDialog}>
          {tNoPrefix('buttons.close')}
        </DialogSecondaryButton>
      }
    >
      <div className={styles.fieldsWrapper}>
        <DecisionProcessAlcApplicationType dealUuid={dealUuid} />
        <DecisionProcessAlcReviewUnit eventId={eventId} borrowerBpId={borrowerBpId} />
        <DecisionProcessAlcTotalCreditAuthorityLimit eventId={eventId} />
        <DecisionProcessAlcTotalElClass eventId={eventId} />
        <DecisionProcessAlcBorrower borrowerBpId={borrowerBpId} />
        <DecisionProcessAlcBorrowerLimit eventId={eventId} />
        <DecisionProcessAlcBorrowerElClass eventId={eventId} />
        <DecisionProcessAlcLargeExposure eventId={eventId} />
        <DecisionProcessAlcLargeExposureReportLimit />
        <DecisionProcessAlcLargeExposureMaxLimit />
        <DecisionProcessAlcComplianceRiskWithStrategy dealUuid={dealUuid} />
        <DecisionProcessAlcAmlRiskClassification borrowerBpId={borrowerBpId} />
        <DecisionProcessAlcOrganCredit eventId={eventId} />
        <DecisionProcessAlcEpFullCost dealUuid={dealUuid} />
        <DecisionProcessAlcEpDirectCost dealUuid={dealUuid} />
        <DecisionProcessAlcEpLowerCost dealUuid={dealUuid} />
      </div>
    </Dialog>
  )
}

DecisionProcessAlcDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  dealUuid: PropTypes.string.isRequired,
  borrowerBpId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
}

export default DecisionProcessAlcDialog
