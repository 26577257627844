import {
  FilterGroupItem,
  Input,
  MultiComboBox,
  MultiComboBoxItem,
} from '@fioneer/ui5-webcomponents-react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import styles from 'components/domains/properties/properties-search/filterbar/PropertiesFilterBar.module.css'
import PropertiesFilterCountryCodeComboBox from 'components/domains/properties/properties-search/filterbar/PropertiesFilterCountryCodeComboBox'
import PropertiesFilterStatusComboBox from 'components/domains/properties/properties-search/filterbar/PropertiesFilterStatusComboBox'
import DealSearchMultiComboBox from 'components/domains/properties/properties-search/filterbar/properties-search-multi-combo-box/DealSearchMultiComboBox'
import MarketSearchMultiComboBox from 'components/domains/properties/properties-search/filterbar/properties-search-multi-combo-box/MarketSearchMultiComboBox'
import DashboardFilterBar from 'components/ui/page/DashboardFilterBar'
import { useExternalIdTypeConfigValuesWithTranslation } from 'hooks/services/properties/external-ids/useExternalIdTypeConfigValuesWithTranslation'
import { toSnakeFromSentenceCase, toKebapFromSentenceCase } from 'lib/format/caseConversion'
import { usePropertyStatus } from 'routes/properties/usePropertyStatus'

const getStaticFilterOptions = () => ({
  property_name: { visibleOnBar: true },
  property_id: { visibleOnBar: true },
  city: { visibleOnBar: true },
  country: { visibleOnBar: true },
  zip_code: { visibleOnBar: false },
  street: { visibleOnBar: false },
  property_types: { visibleOnBar: true },
  construction_year: { visibleOnBar: false },
  deal_id: { visibleOnBar: true },
  market_id: { visibleOnBar: true },
  financing_status_code: { visibleOnBar: true },
  cag_status_code: { visibleOnBar: false },
})

const getOtherExtIdFilterOptions = (externalIdTypeConfigValues) =>
  externalIdTypeConfigValues
    .filter(({ display }) => display)
    .reduce((curr, { type }) => {
      curr[toSnakeFromSentenceCase(type)] = { visibleOnBar: true }
      return curr
    }, {})

const getOtherExtIdFilterValue = (queryParams) => {
  const filterValue = queryParams.get('other_external_id')
  return JSON.parse(filterValue)
}

const findOtherExtIdFilterByType = (extIdFilter, extIdType) =>
  _.find(extIdFilter, ({ type }) => type === extIdType)

const updateOtherExtIdFilter = (currExtIdFilter, changedExtIdFilter) =>
  currExtIdFilter.map((extIdFilter) =>
    extIdFilter.type === changedExtIdFilter.type ? changedExtIdFilter : extIdFilter,
  )

const setFilterStateFromQueryParams = (queryParams) => ({
  property_name: queryParams.get('property_name') || '',
  property_id: queryParams.get('property_id') || '',
  city: queryParams.get('city') || '',
  street: queryParams.get('street') || '',
  country: queryParams.get('country') || [],
  zip_code: queryParams.get('zip_code') || '',
  property_types: queryParams.get('property_types') || '',
  construction_year: queryParams.get('construction_year') || '',
  deal_id: queryParams.get('deal_id') || '',
  market_id: queryParams.get('market_id') || '',
  other_external_id: getOtherExtIdFilterValue(queryParams) || [],
  financing_status_code: queryParams.get('financing_status_code') || [],
  cag_status_code: queryParams.get('cag_status_code') || [],
})

const initialInputValueStates = {
  construction_year: '',
}

const isEnterButtonEvent = (event) => event.code === 'Enter' || event.code === 'NumpadEnter'

const PropertiesFilterBar = ({ typecodes, setSelectedProperties }) => {
  const { t } = useTranslation()
  const [queryParams, setQueryParams] = useSearchParams()
  const [filterParams, setFilterParams] = useState(setFilterStateFromQueryParams(queryParams))
  const { objectStatusForFinancingStatus, objectStatusForCAGStatus } = usePropertyStatus()

  useEffect(() => {
    setFilterParams(setFilterStateFromQueryParams(queryParams))
  }, [queryParams])

  const [inputValueStates, setInputValueStates] = useState(initialInputValueStates)
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false)
  const [filterOptions, setFilterOptions] = useState({})

  const externalIdTypeConfigValues = useExternalIdTypeConfigValuesWithTranslation()

  useEffect(() => {
    if (externalIdTypeConfigValues.length) {
      setFilterOptions({
        ...getStaticFilterOptions(),
        ...getOtherExtIdFilterOptions(externalIdTypeConfigValues),
      })
    } else
      setFilterOptions({
        ...getStaticFilterOptions(),
      })
  }, [externalIdTypeConfigValues])

  const onFilter = () => {
    Object.entries(filterParams).forEach(([key, value]) =>
      key === 'other_external_id'
        ? // as other external id is an array of objects,
          // it must be serialized
          queryParams.set(key, JSON.stringify(value))
        : queryParams.set(key, value),
    )
    setQueryParams(queryParams)
    setSelectedProperties([])
  }

  const onKeyDown = (event) => {
    if (isEnterButtonEvent(event) && !isFilterDialogOpen) {
      onFilter()
    }
  }

  const textInputChange = (key) => (event) => {
    setFilterParams({
      ...filterParams,
      [key]: event.target.value.trim(),
    })
  }

  const onChangeOtherExternalId = (extIdType, event) => {
    const changedExtIdFilter = {
      type: extIdType,
      filterValue: event.target.value.trim(),
    }
    const extIdFilter = filterParams['other_external_id']
    const filterExist = findOtherExtIdFilterByType(extIdFilter, extIdType)

    const updatedExtIdFilter = filterExist
      ? updateOtherExtIdFilter(extIdFilter, changedExtIdFilter)
      : [...extIdFilter, changedExtIdFilter]

    setFilterParams({
      ...filterParams,
      other_external_id: updatedExtIdFilter,
    })
  }

  const validateYear = (year) => /^[0-9]{4}$/.test(year) || year.length === 0

  const setConstructionYearInputState = (event) => {
    const year = event.target.value.trim()
    let valueState = 'Error'
    if (validateYear(year)) {
      valueState = ''
    }

    if (valueState !== inputValueStates.constructionYear) {
      setInputValueStates({
        ...inputValueStates,
        construction_year: valueState,
      })
    }
  }

  const onChangeConstructionYear = (event) => {
    const year = event.target.value.trim()
    if (validateYear(year)) {
      setFilterParams({
        ...filterParams,
        construction_year: year,
      })
    }
  }

  const calculateActiveFilterCount = (currQueryParams) => {
    const staticActiveFilterCount = [...currQueryParams.entries()].filter(
      ([fieldName, fieldValue]) =>
        fieldName !== 'sort' && fieldName !== 'other_external_id' && Boolean(fieldValue),
    ).length

    const dynamicActiveFilterCount =
      getOtherExtIdFilterValue(currQueryParams)?.filter(({ filterValue }) => Boolean(filterValue))
        ?.length ?? 0

    return staticActiveFilterCount + dynamicActiveFilterCount
  }

  const handleMultiComboboxChange = ({ detail: { items } }, fiterFieldName) => {
    const filteredItems = items.map((element) => element.dataset.value).filter(_.negate(_.isNil))
    setFilterParams({ ...filterParams, [fiterFieldName]: filteredItems })
  }

  const isFieldActive = (fieldName) =>
    [...queryParams.entries()].filter(
      ([filterFieldName, filterFieldValue]) =>
        filterFieldName === fieldName && Boolean(filterFieldValue),
    ).length > 0

  const isOtherExternalIdFieldActive = (extIdType) => {
    const currExtIdFilter = getOtherExtIdFilterValue(queryParams)
    const extIdFilterEntry = _.find(currExtIdFilter, ({ type }) => type === extIdType)
    return currExtIdFilter && extIdFilterEntry?.type && extIdFilterEntry?.filterValue
  }

  const filters = [
    <FilterGroupItem
      label={`${t('pages.properties.table.property-name.label')}`}
      key="property_name"
      active={isFieldActive('property_name')}
    >
      <Input
        id="properties-table-property-name-filter"
        value={filterParams.property_name}
        onInput={textInputChange('property_name')}
        onKeyDown={onKeyDown}
        showClearIcon
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${t('pages.properties.table.property-id.label')}`}
      key="property_id"
      active={isFieldActive('property_id')}
    >
      <Input
        id="properties-table-property-id-filter"
        value={filterParams.property_id}
        onInput={textInputChange('property_id')}
        onKeyDown={onKeyDown}
        showClearIcon
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${t('pages.properties.table.city.label')}`}
      key="city"
      active={isFieldActive('city')}
    >
      <Input
        id="properties-table-city-filter"
        value={filterParams.city}
        onInput={textInputChange('city')}
        onKeyDown={onKeyDown}
        showClearIcon
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${t('pages.properties.table.country.label')}`}
      key="country"
      active={isFieldActive('country')}
    >
      <PropertiesFilterCountryCodeComboBox
        selectedCountries={filterParams.country}
        onKeyDown={onKeyDown}
        handleMultiComboboxChange={handleMultiComboboxChange}
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${t('pages.properties.table.zip-code.label')}`}
      key="zip_code"
      active={isFieldActive('zip_code')}
    >
      <Input
        id="properties-table-zip-code-filter"
        value={filterParams.zip_code}
        onInput={textInputChange('zip_code')}
        onKeyDown={onKeyDown}
        showClearIcon
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${t('pages.properties.table.street.label')}`}
      key="street"
      active={isFieldActive('street')}
    >
      <Input
        id="properties-table-street-filter"
        value={filterParams.street}
        onInput={textInputChange('street')}
        onKeyDown={onKeyDown}
        showClearIcon
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${t('pages.properties.table.property-type.label')}`}
      key="property_types"
      active={isFieldActive('property_types')}
    >
      <MultiComboBox
        id="properties-table-property-type-filter"
        className={styles.maxInputWidth}
        onSelectionChange={({ detail: { items: selectedPropertyTypes } }) => {
          const keys = selectedPropertyTypes.map((type) => type.dataset.key)
          setFilterParams({
            ...filterParams,
            property_types: keys.join(','),
          })
        }}
        onKeyDown={onKeyDown}
      >
        {typecodes.map(({ key, display_name }) => (
          <MultiComboBoxItem
            key={key}
            text={display_name}
            selected={filterParams.property_types.split(',').includes(key)}
            data-key={key}
          />
        ))}
      </MultiComboBox>
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${t('pages.properties.header.status.label.financing-status')}`}
      key="financing_status_code"
      active={isFieldActive('financing_status_code')}
    >
      <PropertiesFilterStatusComboBox
        id="properties-table-financing-status-filter"
        selectionName={'financing_status_code'}
        selectedValues={filterParams.financing_status_code}
        values={objectStatusForFinancingStatus}
        onKeyDown={onKeyDown}
        handleMultiComboboxChange={handleMultiComboboxChange}
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${t('pages.properties.header.status.label.cag-status')}`}
      key="cag_status_code"
      active={isFieldActive('cag_status_code')}
    >
      <PropertiesFilterStatusComboBox
        id="properties-table-cag-status-filter"
        selectionName={'cag_status_code'}
        selectedValues={filterParams.cag_status_code}
        values={objectStatusForCAGStatus}
        onKeyDown={onKeyDown}
        handleMultiComboboxChange={handleMultiComboboxChange}
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${t('pages.properties.table.construction-year.label')}`}
      key="construction_year"
      active={isFieldActive('construction_year')}
    >
      <Input
        id="properties-table-construction-year-filter"
        className={styles.constructionYearFilter}
        valueState={inputValueStates.construction_year}
        valueStateMessage={
          <span>{t('pages.properties.table.construction-year.error-message')}</span>
        }
        value={filterParams.construction_year}
        onChange={setConstructionYearInputState}
        onInput={onChangeConstructionYear}
        onKeyDown={onKeyDown}
        showClearIcon
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${t('pages.properties.table.deal-id.label')}`}
      key="deal_id"
      active={isFieldActive('deal_id')}
    >
      <DealSearchMultiComboBox
        id="properties-table-deal-id-filter"
        value={filterParams.deal_id}
        onChange={(newValue) => textInputChange('deal_id')({ target: { value: newValue } })}
      />
    </FilterGroupItem>,
    <FilterGroupItem
      label={`${t('pages.properties.table.market-id.label')}`}
      key="market_id"
      active={isFieldActive('market_id')}
    >
      <MarketSearchMultiComboBox
        id="properties-table-market-id-filter"
        value={filterParams.market_id}
        onChange={(newValue) => textInputChange('market_id')({ target: { value: newValue } })}
      />
    </FilterGroupItem>,
    ...externalIdTypeConfigValues
      .filter(({ display }) => display)
      .map(({ type, description, translation }) => {
        const extIdFilter = filterParams?.other_external_id
        const currIdValue =
          _.find(extIdFilter, ({ type: extIdType }) => extIdType === type)?.filterValue ?? ''

        return (
          <FilterGroupItem
            label={translation ?? description}
            key={toSnakeFromSentenceCase(type)}
            active={isOtherExternalIdFieldActive(type)}
          >
            <Input
              id={`properties-table-${toKebapFromSentenceCase(type)}-filter`}
              value={currIdValue}
              onInput={(event) => onChangeOtherExternalId(type, event)}
              onKeyDown={onKeyDown}
              showClearIcon
            />
          </FilterGroupItem>
        )
      }),
  ]

  const filterOptionsLength = Object.keys(filterOptions).length

  return (
    !!filterOptionsLength && (
      <DashboardFilterBar
        // key triggers a rerender of the filterbar once the filter options change
        key={`properties-search-dashboard-filterbar-${filterOptionsLength}`}
        filterOptions={filterOptions}
        activeFiltersCount={calculateActiveFilterCount(queryParams)}
        onGo={onFilter}
        setDialogOpen={setIsFilterDialogOpen}
        searchInputField={<Input placeholder="Search" />}
      >
        {filters}
      </DashboardFilterBar>
    )
  )
}

PropertiesFilterBar.propTypes = {
  typecodes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      display_name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setSelectedProperties: PropTypes.func.isRequired,
}

export default PropertiesFilterBar
