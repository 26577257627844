import {
  Button,
  ButtonDesign,
  Modals,
  MessageBoxTypes,
  MessageBoxActions,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import CreateCashflowScenarioDialog from 'components/domains/deals/deal-cashflow/CreateCashflowScenarioDialog'

const DealCashflowDetailsDuplicateButton = ({ dealId, originalCashflowScenarioUuid }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.detail.cashflow' })

  const showMessageBox = Modals.useShowMessageBox()

  const [isCreationDialogOpen, setIsCreationDialogOpen] = useState(false)

  return (
    <>
      <Button
        id="duplicate-cashflow-scenario"
        design={ButtonDesign.Transparent}
        onClick={() =>
          showMessageBox(
            {
              type: MessageBoxTypes.Information,
              titleText: t('details.dialog.duplicate-scenario.title'),
              children: (
                <Trans
                  i18nKey="pages.deals.detail.cashflow.details.dialog.duplicate-scenario.text"
                  components={{ ul: <ul />, li: <li />, p: <p /> }}
                />
              ),
              actions: [MessageBoxActions.OK, MessageBoxActions.Cancel],
              onClose: (event) => {
                if (event.detail.action === MessageBoxActions.OK) {
                  setIsCreationDialogOpen(true)
                }
              },
            },
            document.body,
          )
        }
      >
        {t('details.button.duplicate-scenario')}
      </Button>
      <CreateCashflowScenarioDialog
        isOpen={isCreationDialogOpen}
        setIsOpen={setIsCreationDialogOpen}
        dealId={dealId}
        existingScenarioUuidForCopy={originalCashflowScenarioUuid}
      />
    </>
  )
}

DealCashflowDetailsDuplicateButton.propTypes = {
  dealId: PropTypes.string.isRequired,
  originalCashflowScenarioUuid: PropTypes.string.isRequired,
}

export default DealCashflowDetailsDuplicateButton
