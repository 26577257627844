import { combineReducers } from '@reduxjs/toolkit'
import amortizationsTableReducer from 'redux/slices/deals/financing/tranches/amortizations/amortizationsTableSlice'
import interestConditionsTableReducer from 'redux/slices/deals/financing/tranches/interestConditions/interestConditionsTableSlice'

const dealsReducer = combineReducers({
  amortizationsTable: amortizationsTableReducer,
  interestConditionsTable: interestConditionsTableReducer,
})

export default dealsReducer
