export const Status = Object.freeze({
  write: 'STATUS_WRITE',
})

export const Address = Object.freeze({
  write: 'ADDRESS_WRITE',
})

export const Information = Object.freeze({
  write: 'INFORMATION_WRITE',
})

export const Appreciation = Object.freeze({
  write: 'APPRECIATION_WRITE',
})

export const Assessment = Object.freeze({
  write: 'ASSESSMENT_WRITE',
})

export const Contact = Object.freeze({
  write: 'CONTACT_WRITE',
})

export const BusinessPartnerCustomFields = Object.freeze({
  write: 'CUSTOM_FIELD_WRITE',
})

export const Rating = Object.freeze({
  create: 'RATING_CREATE',
  write: 'RATING_WRITE',
  delete: 'RATING_DELETE',
})
