import { useMemo } from 'react'
import useAutomaticTileHookHelper from 'hooks/services/business-events-and-tasks/decision-papers/tiles/automatic/useAutomaticTileHookHelper'
import useMultipleDealCollateralInformation from 'hooks/services/deals/collateral/useMultipleDealCollateralInformation'
import useDeals from 'hooks/services/deals/useDeals'
import { useCombinedQueryResults } from 'hooks/services/queryHelper'

const useRiskIndicatorDealStandardCollateralPackage = (
  { entityRef: { entityId: businessPartnerId } },
  tileId,
) => {
  const {
    data: { deals: dealsData = [] } = {},
    isLoading: isDealsLoading,
    isError: isDealsError,
    error: dealsError,
  } = useDeals({ filter: { borrowerId: businessPartnerId } }) || {}

  const mappedDealUuids = useMemo(() => dealsData?.map((deal) => deal.dealUuid), [dealsData])

  // get an array of collateral info uuids for each deal
  const {
    data: multiDealCollateralInformation,
    isLoading: multiCollateralIsLoading,
    error: multiCollateralError,
    isError: multiCollateralIsError,
  } = useCombinedQueryResults(
    useMultipleDealCollateralInformation({
      dealUuids: mappedDealUuids,
    }),
  )

  const { isSomeValueLoading, isSomeValueError, error } = useAutomaticTileHookHelper({
    loadingValues: [isDealsLoading, multiCollateralIsLoading],
    errorValues: [isDealsError, multiCollateralIsError],
    errorDetails: [dealsError, multiCollateralError],
    tileId,
  })

  const sourcePaths = useMemo(
    () => mappedDealUuids.map((_, index) => `/deals/${dealsData?.[index]?.dealId}/collaterals`),
    [dealsData, mappedDealUuids],
  )

  return useMemo(() => {
    if (isSomeValueError) {
      return { isLoading: false, isError: true, error }
    }
    if (isSomeValueLoading) {
      return { isLoading: true, isError: false }
    }

    return {
      isLoading: false,
      isError: false,
      data: {
        dealsData,
        dealCollateralInformationData: multiDealCollateralInformation,
        sourcePaths,
        sourceRender: { path: `${sourcePaths[0]}` },
      },
    }
  }, [
    dealsData,
    isSomeValueError,
    isSomeValueLoading,
    error,
    sourcePaths,
    multiDealCollateralInformation,
  ])
}

export default useRiskIndicatorDealStandardCollateralPackage
