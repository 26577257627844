import { CardHeader } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/events/creation/EventCreationDescriptionTile.module.css'
import Card from 'components/ui/card/Card'
import CWPCKEditor from 'components/ui/rich-text-editor/CWPCKEditor'
import { setEventDescription } from 'redux/slices/events/eventCreationSlice'

const maximumDescriptionLength = 1000

const EventCreationDescriptionTile = ({ disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.events.creation',
  })
  const { eventDescription } = useSelector((state) => state.events.eventCreation)
  const dispatch = useDispatch()

  const handleContentChanged = (newDescription) => {
    dispatch(setEventDescription(newDescription))
  }

  const header = <CardHeader titleText={t('cards.description.title')} />

  // TODO: Remove inline style when there is a solution regarding to ui5 components override styling
  return (
    <Card
      header={header}
      className={disabled ? styles.disabled : ''}
      {...(!!disabled && { inert: '' })}
    >
      <div style={{ padding: '1rem' }}>
        <CWPCKEditor
          setCurrentContent={handleContentChanged}
          currentContent={eventDescription}
          maxCharacterAmount={maximumDescriptionLength}
        />
      </div>
    </Card>
  )
}

EventCreationDescriptionTile.propTypes = {
  disabled: PropTypes.bool,
}

export default EventCreationDescriptionTile
