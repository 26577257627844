import {
  FlexBox,
  FlexBoxDirection,
  Link,
  LinkDesign,
  Popover,
  PopoverPlacementType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/sources/DecisionPaperMoreSourcesLinkWithPopover.module.css'
import DecisionPaperSourceLink from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/sources/DecisionPaperSourceLink'

const DecisionPaperMoreSourcesLinkWithPopover = ({ tileId, sources, isPdfView }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-papers.tiles.header',
  })
  const [isMoreSourcesOpen, setIsMoreSourcesOpen] = useState(false)
  const openMoreSourcesPopover = useCallback(() => setIsMoreSourcesOpen(true), [])
  const closeMoreSourcesPopover = useCallback(() => setIsMoreSourcesOpen(false), [])

  const tileState = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const renderedSources = useMemo(
    () =>
      sources.map((source) => (
        <DecisionPaperSourceLink
          key={`source-tile-${tileId}-${source.nameTranslationKey}-${source.path}`}
          sourceRender={tileState?.data?.sourceRender}
          source={source}
          isPdfView={isPdfView}
          className={styles.popoverLink}
        />
      )),
    [sources, tileId, isPdfView, tileState?.data?.sourceRender],
  )
  const moreButtonId = useMemo(() => `source-tile-${tileId}-sources-more`, [tileId])

  if (sources.length === 0) {
    return
  }

  return (
    <>
      <Link
        design={LinkDesign.Default}
        accessibleRole="button"
        id={moreButtonId}
        onClick={openMoreSourcesPopover}
      >
        {t('source.more', { sourcesCount: sources.length })}
      </Link>
      {createPortal(
        <Popover
          open={isMoreSourcesOpen}
          opener={moreButtonId}
          className={styles.sourcesPopover}
          placementType={PopoverPlacementType.Bottom}
          onAfterClose={closeMoreSourcesPopover}
        >
          <FlexBox direction={FlexBoxDirection.Column}>{renderedSources}</FlexBox>
        </Popover>,
        document.body,
      )}
    </>
  )
}

DecisionPaperMoreSourcesLinkWithPopover.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      nameTranslationKey: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ),
}

export default DecisionPaperMoreSourcesLinkWithPopover
