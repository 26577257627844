export const QuestionLayout = Object.freeze({
  COLUMN: 0,
  ROW: 1,
})

export const BooleanQuestion = Object.freeze({
  YES: 'yes',
  NO: 'no',
})

export const ColorQuestion = Object.freeze({
  RED: 'red',
  YELLOW: 'yellow',
  GREEN: 'green',
})

export const ShrinkValue = Object.freeze({
  NONE: 0,
  MEDIUM: 1,
  LARGE: 2,
})

export const QuestionKeys = Object.freeze({
  Q11: 'Q11',
  Q11_comment: 'Q11_comment',
  Q12: 'Q12',
  Q13: 'Q13',
  Q12_comment: 'Q12_comment',
  Q211: 'Q211',
  Q211_comment: 'Q211_comment',
  Q212: 'Q212',
  Q212_comment: 'Q212_comment',
  Q213: 'Q213',
  Q213_comment: 'Q213_comment',
  Q214: 'Q214',
  Q214_comment: 'Q214_comment',
  Q215: 'Q215',
  Q215_comment: 'Q215_comment',
  Q221: 'Q221',
  Q221_comment: 'Q221_comment',
  Q222: 'Q222',
  Q222_comment: 'Q222_comment',
  Q223: 'Q223',
  Q223_comment: 'Q223_comment',
  Q224: 'Q224',
  Q224_comment: 'Q224_comment',
  Q231: 'Q231',
  Q231_comment: 'Q231_comment',
})

export const internalInstructionForbearanceLink =
  'https://atlas.hre.loc:9093/ix-DMS/ix?cmd=readdoc1&downloadid=(4E07F702-04D1-E995-8FFD-5F8400644DE2)&acode=attachment&fname=Forbearance.pdf'

export const triggerEventOverviewLink =
  'https://atlas.hre.loc:9093/ix-DMS/ix?cmd=readdoc1&downloadid=(AA51B031-4545-7F88-DFB2-890484C7A1A2)&acode=attachment&fname=Trigger+Event+overview.xlsx'

export const internalInstructionLink =
  'https://atlas.hre.loc:9093/ix-DMS/ix?cmd=readdoc1&downloadid=(F7B36D53-42A5-2899-B71A-A5B68FEF9DEB)&fname=Critical+Facilities.pdf'

export const definitionOfDefaultLink =
  'https://atlas.hre.loc:9093/ix-DMS/ix?cmd=readdoc1&downloadid=(710149EA-2635-5E59-E029-233E05F48FC6)&acode=attachment&fname=Definition+of+Default.pdf'

export const marketReportLink =
  'https://intranet.hre.loc/marktdaten/marktberichte/marktberichte-europa.html'

export const refCreditAuthorities =
  'https://atlas.hre.loc:9093/ix-DMS/ix?cmd=readdoc1&downloadid=(00C23727-84F2-C7EA-0F64-AF820B1C031C)&acode=attachment&fname=REF+Credit+Authorities.pdf'

export const pscCreditAuthorities =
  'https://ATLAS.hre.loc:9093/ix-DMS/ix?cmd=readdoc1&downloadid=(0EDADE45-5806-8115-7A64-FD8A934A7FB9)&acode=attachment&fname=PIF+Kompetenzordnung.pdf'
