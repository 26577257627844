const mapNumberOfProperties = (deals, dealUuid, count) => {
  const dealDisplayId = deals?.find(({ kpiDeal }) => kpiDeal?.dealUuid === dealUuid)?.kpiDeal
    ?.dealDisplayId

  const displayData = {
    dealDisplayId,
    count,
  }

  return dealDisplayId && count ? displayData : null
}

export default mapNumberOfProperties
