import PropTypes from 'prop-types'
import { forwardRef, useMemo } from 'react'
import PropertiesSearchMultiComboBox from 'components/domains/properties/properties-search/filterbar/properties-search-multi-combo-box/PropertiesSearchMultiComboBox'
import { useRequests } from 'hooks/services/baseService'
import useDeals from 'hooks/services/deals/useDeals'
import { useCamelizedResponse, useCombinedQueryResults } from 'hooks/services/queryHelper'

const dealToComboBoxItem = ({ dealUuid, dealId, name }) => ({
  value: dealUuid,
  text: name,
  additionalText: dealId,
})

const useMultipleDealsByUuid = (dealUuids = []) => {
  const requestsConfig = useMemo(
    () => ({
      requests: dealUuids.map((dealUuid) => ({
        path: `/deals/${dealUuid}`,
        keys: ['deals', dealUuid],
      })),
      translated: true,
    }),
    [dealUuids],
  )

  const results = useCamelizedResponse(useCombinedQueryResults(useRequests(requestsConfig)))

  return useMemo(
    () => ({
      ...results,
      data: results.data?.map(dealToComboBoxItem),
    }),
    [results],
  )
}

/** checks if string contains only digits or minus sign */
const isPotentialDealIdRegex = /^[\d-]+$/

const useDealSearchByNameOrId = (inputString = '') => {
  const searchString = inputString.trim()
  const hasSearchString = searchString.length > 0
  const isSearchingForId = isPotentialDealIdRegex.test(searchString)

  const dealQuery = useMemo(
    () => ({
      filter: {
        [isSearchingForId ? 'dealId' : 'dealName']: searchString,
      },
      pagination: {
        offset: 0,
        limit: 25,
      },
      queryOptions: {
        keepPreviousData: true,
        enabled: hasSearchString,
      },
    }),
    [hasSearchString, isSearchingForId, searchString],
  )

  const dealsResult = useDeals(dealQuery)

  return useMemo(
    () => ({
      isLoading: hasSearchString && (dealsResult.isLoading || dealsResult.isFetching),
      isError: dealsResult.isError,
      data: (dealsResult.data?.deals ?? []).map(dealToComboBoxItem),
    }),
    [dealsResult, hasSearchString],
  )
}

const DealSearchMultiComboBox = forwardRef(({ value = '', onChange, ...otherProps }, ref) => (
  <PropertiesSearchMultiComboBox
    {...otherProps}
    ref={ref}
    value={value}
    onChange={onChange}
    useInitialDataLoader={useMultipleDealsByUuid}
    useSearchResultsLoader={useDealSearchByNameOrId}
  />
))

DealSearchMultiComboBox.displayName = 'DealSearchMultiComboBox'

DealSearchMultiComboBox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default DealSearchMultiComboBox
