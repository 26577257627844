import {
  BusyIndicator,
  BusyIndicatorSize,
  Title,
  TitleLevel,
  WrappingType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import styles from 'components/ui/need-to-know/NeedToKnowWrapper.module.css'
import { useDelay } from 'hooks/common/useDelay'

const DEFAULT_DELAY = 1000

const BusyIndicatorWithText = ({
  delay = DEFAULT_DELAY,
  isLoading = false,
  isError = false,
  title = '',
  text = '',
  errorToDisplay,
  renderContent = () => {},
}) => {
  const [loadingVisible, setLoadingVisible] = useState(false)
  const delayedSetLoadingVisible = useDelay(setLoadingVisible, delay)
  useEffect(() => {
    if (isLoading) {
      delayedSetLoadingVisible(true)
    }
    if (!isLoading) {
      setLoadingVisible(false)
    }
  }, [isLoading, delayedSetLoadingVisible])

  if (isLoading) {
    return (
      <div className={loadingVisible ? styles.centered : styles.hidden}>
        <div className={styles.indicator}>
          <BusyIndicator active size={BusyIndicatorSize.Large} delay={delay} />
        </div>
        <div>
          {title && (
            <Title
              level={TitleLevel.H4}
              className={styles.title}
              wrappingType={WrappingType.Normal}
            >
              {title}
            </Title>
          )}
        </div>
        <div>
          {text && (
            <Title
              level={TitleLevel.H5}
              className={styles.subtitle}
              wrappingType={WrappingType.Normal}
            >
              {text}
            </Title>
          )}
        </div>
      </div>
    )
  }

  if (isError) {
    return <div className={styles.center}>{errorToDisplay}</div>
  }

  return renderContent()
}

BusyIndicatorWithText.propTypes = {
  renderContent: PropTypes.func,
  delay: PropTypes.number,
  errorToDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
}

export default BusyIndicatorWithText
