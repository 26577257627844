import merge from 'lodash.merge'
import { useCallback } from 'react'
import cells from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/cells/cells.js'

/**
 * @typedef {import('./cells.js')['default']} cells
 */

/**
 * @template {keyof cells} T
 * @param {T} type
 * @param {Parameters<cells[T]>[0]} props
 */
const renderCell = (type, props) => {
  const Cell = cells[type]

  return <Cell {...props} />
}

/**
 * Creates a Cell of the given type and initializes the Cell's `props` with the `defaults`
 *
 * Providing `defaults` is useful for values that change between tables, but not between cells
 * e. g. `className`
 * @template {keyof cells} T
 * @param {T} type
 * @template {import('./helpers.js').DeepPartial<Parameters<cells[T]>[0]>} U
 * @param {U} [defaults]
 * @returns {(props?: Omit<Parameters<cells[T]>[0], keyof U> & import('./helpers.js').DeepPartial<U>) => JSX.Element}
 */
const useCreateCell = (type, defaults) =>
  useCallback((props) => renderCell(type, merge(defaults ?? {}, props ?? {})), [defaults, type])

export default useCreateCell
