import {
  AnalyticalTableScaleWidthMode,
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Text,
  Title,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import {
  MAX_COLLAPSED_COMMENT_LENGTH,
  TABLE_HEADER_ROW_HEIGHT,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/constants'
import mapAuthorityLevelCalculatorDataToTableStructure from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/utils/mapper/mapAuthorityLevelCalculatorDataToTableStructure'
import CollapsibleRichTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/rich-text/CollapsibleRichTextView'
import FormattedTextView from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/rich-text-editor/FormattedTextView'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/analytical/AnalyticalTableWithToolbar'
import { useCollapseExpandTableActions } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/ui/tables/analytical/useCollapseExpandTableActions'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/authority-level-calculator/BaselTwoAuthorityLevelCalculatorTile.module.css'
import useBaselTwoAuthorityLevelCalculatorTableColumnDefinitions from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/basel-two/authority-level-calculator/useBaselTwoAuthorityLevelCalculatorTableColumnDefinitions'
import AnalyticalTableScalingWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/AnalyticalTableScalingWrapper'
import filterUnitMembers from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/authority-level-calculator/utils/filterUnitMembers'
import moveBorrowerToFrontAndMark from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/authority-level-calculator/utils/moveBorrowerToFrontAndMark'
import DecisionStageLeaveConfirmationLink from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/decision-stage-leave-confirmation/DecisionStageLeaveConfirmationLink'
import { UNIT_TYPE } from 'hooks/services/business-events-and-tasks/decision-papers/tiles/annual-review-basel-two-confirmation/basel-two/useBusinessPartnerBaselTwoAuthorityLevelCalculator'

const getUnitLabels = (type, t) => {
  const labelsByType = {
    [UNIT_TYPE.REVIEW]: [t('header.reviewUnitLabel'), t('header.reviewUnitId')],
    [UNIT_TYPE.GCC]: [t('header.gccUnitLabel'), t('header.gccUnitId')],
    default: [t('header.standaloneBpLabel'), t('header.standaloneBpId')],
  }

  return labelsByType[type] || labelsByType.default
}

const BaselTwoAuthorityLevelCalculatorTile = ({
  tileId,
  isPdfView = false,
  showConfirmationForLinks = false,
}) => {
  const tileState = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const {
    data: {
      authorityLevelCalculatorTableData,
      authorityLevelCalculatorHeaderData,
      authorityLevelCalculatorCommentData,
      dealBorrowerBpId,
    },
  } = tileState

  const { businessPartnerId, businessPartnerName, businessPartnerType } =
    authorityLevelCalculatorHeaderData ?? {}

  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.basel-two.authority-level-calculator',
  })
  const { t: tNoPrefix } = useTranslation('decisionPaper')

  const tableRef = useRef(null)
  const [tableWidth, setTableWidth] = useState(tableRef?.current?.totalColumnsWidth ?? 0)

  const { backendTableData, containsGuarantor } = useMemo(
    () =>
      mapAuthorityLevelCalculatorDataToTableStructure(tNoPrefix, authorityLevelCalculatorTableData),
    [authorityLevelCalculatorTableData, tNoPrefix],
  )

  const columns = useBaselTwoAuthorityLevelCalculatorTableColumnDefinitions({
    showConfirmationForLinks,
    showGuarantorColumn: containsGuarantor,
  })

  const {
    handleOnRowExpand,
    additionalTableActions: collapseExpandActions = [],
    expandOrCollapseSubRows,
  } = useCollapseExpandTableActions(tableRef)

  useEffect(() => {
    setTableWidth(tableRef?.current?.totalColumnsWidth ?? 0)
  }, [tableRef?.current?.totalColumnsWidth])

  useEffect(() => {
    if (isPdfView) {
      expandOrCollapseSubRows(true)
    }
  }, [expandOrCollapseSubRows, isPdfView])

  const tableData = useMemo(() => {
    let mergedData = backendTableData
    mergedData = filterUnitMembers(mergedData, dealBorrowerBpId)
    mergedData = moveBorrowerToFrontAndMark(mergedData, dealBorrowerBpId)
    return mergedData
  }, [backendTableData, dealBorrowerBpId])

  const [nameLabel, idLabel] = getUnitLabels(businessPartnerType, t)

  const renderHeader = () => (
    <FlexBox className={styles.headerWrapper} direction={FlexBoxDirection.Column}>
      <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween}>
        <Label>{nameLabel}</Label>
        <DecisionStageLeaveConfirmationLink
          name={businessPartnerName}
          link={`/business-partners/${businessPartnerId}`}
          showConfirmationForLinks={showConfirmationForLinks}
        />
      </FlexBox>
      <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween}>
        <Label>{idLabel}</Label>
        <Text>{businessPartnerId}</Text>
      </FlexBox>
    </FlexBox>
  )

  const renderComment = () => (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.commentWrapper}>
      <Title className={styles.headerText} role="heading" part="title">
        {t('comment.title')}
      </Title>
      {isPdfView ? (
        <FormattedTextView formattedText={authorityLevelCalculatorCommentData} />
      ) : (
        <CollapsibleRichTextView
          text={authorityLevelCalculatorCommentData}
          characterLimit={MAX_COLLAPSED_COMMENT_LENGTH}
        />
      )}
    </FlexBox>
  )

  const renderTable = () => (
    <AnalyticalTableScalingWrapper isPdfView={isPdfView} tableWidth={tableWidth}>
      <AnalyticalTableWithToolbar
        title={t('table.title')}
        nrOfEntries={
          tableData.reduce((acc, cur) => (cur.subRows ? cur.subRows.length : 0) + acc, 0) ?? 0
        }
        additionalActions={!isPdfView ? collapseExpandActions : []}
        id="authorityLevelCalculatorTable"
        sortable={false}
        minRows={0}
        className={styles['authorityLevelCalculator-table']}
        columns={columns}
        overscanCountHorizontal={99}
        // high number of visible rows fixes re-rendering of height on expand
        visibleRows={99}
        // rowHeight needs to be set to a non-empty string to not show an empty row at the end
        rowHeight={'individual'}
        headerRowHeight={TABLE_HEADER_ROW_HEIGHT}
        onRowExpandChange={(event) => handleOnRowExpand(event.detail.row)}
        data={tableData}
        isPdfView={isPdfView}
        isTreeTable={true}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
        withRowHighlight={false}
        reactTableOptions={{
          autoResetPage: false,
          autoResetExpanded: false,
          autoResetSelectedRows: false,
          autoResetSortBy: false,
          autoResetFilters: false,
          selectSubRows: false,
        }}
        ref={tableRef}
      />
    </AnalyticalTableScalingWrapper>
  )

  return (
    <>
      {renderHeader()}
      <DecisionPaperTileListSeparator />
      <div className={styles.tableWrapper}>{renderTable()}</div>
      {authorityLevelCalculatorCommentData && (
        <>
          <DecisionPaperTileListSeparator />
          {renderComment()}
        </>
      )}
    </>
  )
}

BaselTwoAuthorityLevelCalculatorTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool,
  showConfirmationForLinks: PropTypes.bool,
}

export default BaselTwoAuthorityLevelCalculatorTile
