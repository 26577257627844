import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const usePropertyValuationRequestMassEdit = (mutationOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    ({ valuationRequestIds, reason, valuationType, status, note, reportDate, valuer, dueDate }) =>
      post({
        path: `/properties/valuation-requests/bulk-edit`,
        body: snakecaseKeys({
          valuationRequestIds,
          editedFields: {
            reason: reason,
            type: valuationType,
            info: {
              status,
              note,
              reportDate,
              dueDate,
            },
            valuer: valuer,
          },
        }),
      }),
    mutationOptions,
  )
}

export default usePropertyValuationRequestMassEdit
