import {
  MessageBoxTypes,
  Modals,
  PopoverPlacementType,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useMemo, useState, useCallback, useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionProcessConditionsDecisionStatus } from 'api/decision-process/decisionProcessConditionsApi'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import styles from 'components/domains/business-events-and-tasks/decision-stage/conditions/DecisionStageConditionsTable.module.css'
import ConditionsTableDescriptionCell from 'components/domains/business-events-and-tasks/decision-stage/conditions/table-cells/ConditionsTableDescriptionCell'
import ConditionsTableWrittenByCell from 'components/domains/business-events-and-tasks/decision-stage/conditions/table-cells/ConditionsTableWrittenByCell'
import { CancelPopoverContent } from 'components/ui/button/CancelButtonWithPopover'
import Card from 'components/ui/card/Card'
import StaffMemberByObjectIdOrEmail from 'components/ui/staff-member/StaffMemberByObjectIdOrEmail'
import EditAndDeleteTableCell from 'components/ui/tables/sorted-tables/EditAndDeleteTableCell'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import LastEditedText from 'components/ui/text/LastEditedText'
import useEditDecisionStageVoterCondition from 'hooks/services/business-events-and-tasks/decision-process/stages/conditions/useEditDecisionStageVoterCondition'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const conditionCancelButton = 'conditions-cancel-button'

const DecisionStageConditionsTable = ({
  eventId,
  decisionStageId,
  decisionStageVoterConditions,
  decisionStatus,
  showEditButtons = true,
  showMoreButtons = true,
  isPdfView = false,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.conditions.table',
  })
  const { mutate: editVoterCondition } = useEditDecisionStageVoterCondition()
  const queryClient = useQueryClient()
  const popoverRef = useRef()
  const showToast = Modals.useShowToast()
  const showMessageBox = Modals.useShowMessageBox()
  const showPopover = Modals.useShowPopover()
  const [highlightRow, setHighlightRow] = useState([])
  const [editedRow, setEditedRow] = useState()
  const [isDataUploading, setIsDataUploading] = useState(false)

  const { allowedOperations } = useContext(BusinessEventsAndTasksContext)

  const hasUpdateDecisionConditionsPermission = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.updateDecisionStageConditions),
    [allowedOperations],
  )

  const showActionButtons =
    showEditButtons &&
    hasUpdateDecisionConditionsPermission &&
    (decisionStatus === decisionProcessConditionsDecisionStatus.pending ||
      decisionStatus === decisionProcessConditionsDecisionStatus.inProgressRequestDiscarded)

  const columnDefinitions = useMemo(
    () => [
      {
        title: t('columns.description'),
        columnKey: 'description',
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
      {
        title: t('columns.written-by'),
        columnKey: 'writtenBy',
        sortingDisabled: true,
      },
      {
        title: t('columns.last-changed'),
        columnKey: 'lastChanged',
        sortingDisabled: true,
      },
      {
        title: '',
        columnKey: 'actions',
        sortingDisabled: true,
        isSelectableForHiding: false,
      },
    ],
    [t],
  )
  const isSaveButtonDisabled = useMemo(() => {
    if (!editedRow) {
      return true
    }

    return (
      editedRow.condition === decisionStageVoterConditions[editedRow.index].condition ||
      isDataUploading
    )
  }, [editedRow, decisionStageVoterConditions, isDataUploading])

  const onCancelButtonConfirmed = useCallback(() => {
    setEditedRow()
  }, [])

  const onSaveCancelButtonClicked = useCallback(() => {
    if (!isSaveButtonDisabled) {
      popoverRef.current = showPopover({
        opener: conditionCancelButton,
        placementType: PopoverPlacementType.Top,
        children: (
          <CancelPopoverContent
            onCancelClicked={() => {
              popoverRef.current.close()
              onCancelButtonConfirmed()
            }}
          />
        ),
      })
      return
    }
    onCancelButtonConfirmed()
  }, [isSaveButtonDisabled, showPopover, onCancelButtonConfirmed])

  const onEditButtonClicked = useCallback(
    ({ index, id, condition }) =>
      () => {
        setEditedRow({ index, id, condition })
      },
    [],
  )

  const onSaveError = useCallback(() => {
    showMessageBox({
      children: t('error.text'),
      type: MessageBoxTypes.Error,
      draggable: true,
      resizable: true,
    })
  }, [showMessageBox, t])

  const onSaveSuccess = useCallback(() => {
    setHighlightRow((currentHighlightRow) => [...currentHighlightRow, editedRow.id]) // NOSONAR
    setEditedRow()
    queryClient.invalidateQueries([
      'events',
      eventId,
      'decision-stages',
      decisionStageId,
      'conditions',
    ])
    showToast({ children: t('update.success-notification') })
  }, [decisionStageId, eventId, queryClient, showToast, t, editedRow])

  const onSaveButtonClicked = useCallback(() => {
    editVoterCondition(
      {
        decisionStageId,
        eventId,
        voterConditionId: editedRow.id, // NOSONAR
        condition: editedRow.condition, // NOSONAR
      },
      {
        onSuccess: onSaveSuccess,
        onError: onSaveError,
      },
    )
  }, [decisionStageId, editedRow, eventId, onSaveError, onSaveSuccess, editVoterCondition])

  const onConditionChanged = useCallback((newCondition) => {
    setEditedRow((currentEditedRow) => ({ ...currentEditedRow, condition: newCondition }))
  }, [])

  const tableData = useMemo(
    () =>
      decisionStageVoterConditions.map(
        ({ id, userId, condition, lastUpdatedAt, lastUpdatedBy }, index) => {
          const isEditModeForCurrentRow = id === editedRow?.id
          return {
            rowKey: `condition-${id}-${userId}`,
            rowProperties: highlightRow.includes(id)
              ? { className: styles.hightlightNewOrEditedRow }
              : {},
            description: {
              cellComponent: (
                <ConditionsTableDescriptionCell
                  description={isEditModeForCurrentRow ? editedRow.condition : condition} // NOSONAR
                  isEditMode={isEditModeForCurrentRow}
                  onConditionChanged={onConditionChanged}
                  showMoreButtons={showMoreButtons}
                  setIsDataUploading={setIsDataUploading}
                />
              ),
            },
            writtenBy: {
              cellComponent: <ConditionsTableWrittenByCell userId={userId} />,
            },
            lastChanged: {
              cellComponent:
                !lastUpdatedBy || !lastUpdatedAt ? (
                  <Text>{'-'}</Text>
                ) : (
                  <LastEditedText lastUpdatedAt={lastUpdatedAt} wrapping>
                    <StaffMemberByObjectIdOrEmail objectIdOrEmail={lastUpdatedBy} />
                  </LastEditedText>
                ),
            },
            actions: {
              cellComponent: (
                <EditAndDeleteTableCell
                  onSaveButtonClicked={onSaveButtonClicked}
                  onCancelButtonClicked={onSaveCancelButtonClicked}
                  onEditButtonClicked={onEditButtonClicked({
                    index,
                    id,
                    condition,
                  })}
                  isSaveButtonDisabled={isSaveButtonDisabled}
                  isEditModeForCurrentRow={isEditModeForCurrentRow}
                  showEditButton={showActionButtons}
                  showDeleteButton={false}
                  cancelButtonId={conditionCancelButton}
                />
              ),
            },
          }
        },
      ),
    [
      decisionStageVoterConditions,
      highlightRow,
      editedRow,
      onSaveButtonClicked,
      onSaveCancelButtonClicked,
      onEditButtonClicked,
      isSaveButtonDisabled,
      showActionButtons,
      onConditionChanged,
      showMoreButtons,
      isPdfView,
    ],
  )
  return (
    <Card>
      <div className={styles.tableWrapper}>
        <SortedTable
          tableData={tableData}
          columnDefinitions={columnDefinitions}
          toolbarConfig={{
            title: t('title'),
            showColumnSelection: !isPdfView,
          }}
        />
      </div>
    </Card>
  )
}

DecisionStageConditionsTable.propTypes = {
  eventId: PropTypes.string.isRequired,
  decisionStageId: PropTypes.string.isRequired,
  decisionStageVoterConditions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      condition: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      creationTime: PropTypes.string.isRequired,
      lastUpdatedAt: PropTypes.string,
      lastUpdatedBy: PropTypes.string,
    }),
  ).isRequired,
  decisionStatus: PropTypes.oneOf(Object.values(decisionProcessConditionsDecisionStatus))
    .isRequired,
  showEditButtons: PropTypes.bool,
  showMoreButtons: PropTypes.bool,
  isPdfView: PropTypes.bool,
}

export default DecisionStageConditionsTable
