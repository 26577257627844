import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { decisionPaperSynchronizationStatus } from 'api/decision-paper/decisionPaperApi'
import calculateDecisionStageToDisplay from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageToDisplayCalculator'
import useCurrentDecisionPaper from 'hooks/services/business-events-and-tasks/decision-papers/useCurrentDecisionPaper'
import useDecisionStages from 'hooks/services/business-events-and-tasks/decision-process/stages/useDecisionStages'

export const NO_INFORMATION = 'NO_INFORMATION'
export const LOCKED = 'LOCKED'
export const ACTIVE = 'ACTIVE'
export const IN_SYNC = 'IN_SYNC'
export const OUT_OF_SYNC = 'OUT_OF_SYNC'
export const DECISION_PAPER_MISSING = 'DECISION_PAPER_MISSING'
export const ERROR = 'ERROR'
export const EVENT_MISSING = 'EVENT_MISSING'

const useApprovalAndDecisionPaperStatus = ({ arrearApprovalLocked = false, event }) => {
  const {
    data: decisionPaper,
    isFetching: isLoadingDecisionPaper,
    isError: isErrorDecisionPaper,
  } = useCurrentDecisionPaper(event?.id, {
    enabled: !!event,
  })
  const { data: decisionStagesHookData } = useDecisionStages({
    eventId: event?.id,
  })

  const decisionStages = event ? decisionStagesHookData : undefined

  const approvalStatus = useCallback(() => {
    if (arrearApprovalLocked) {
      return LOCKED
    }
    if (!event) {
      return EVENT_MISSING
    }
    return ACTIVE
  }, [arrearApprovalLocked, event])

  const decisionPaperStatus = useCallback(() => {
    if (!event?.id) {
      return EVENT_MISSING
    }
    return decisionStages?.decisionStages?.length > 0
      ? calculateDecisionStageToDisplay(decisionStages.decisionStages)?.info?.decisionStatus
      : NO_INFORMATION
  }, [decisionStages?.decisionStages, event])

  const decisionPaperSyncStatus = useCallback(() => {
    if (!event?.id) {
      return EVENT_MISSING
    }
    if (isLoadingDecisionPaper) {
      return NO_INFORMATION
    }
    if (isErrorDecisionPaper && event) {
      return DECISION_PAPER_MISSING
    }
    if (decisionPaper.synchronizationStatus === decisionPaperSynchronizationStatus.live) {
      return IN_SYNC
    }
    return OUT_OF_SYNC
  }, [decisionPaper?.synchronizationStatus, event, isErrorDecisionPaper, isLoadingDecisionPaper])

  return {
    approvalStatus,
    decisionPaperStatus,
    decisionPaperSyncStatus,
  }
}

useApprovalAndDecisionPaperStatus.propTypes = {
  arrearApprovalLocked: PropTypes.bool,
  event: PropTypes.shape({
    id: PropTypes.string,
  }),
}

export default useApprovalAndDecisionPaperStatus
