import { includes } from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DecisionPaperTileListItem from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListItem'
import DecisionPaperTileListSeparator from 'components/domains/business-events-and-tasks/decision-paper/tiles/DecisionPaperTileListSeparator'
import NoPropertyAssignedToDealInfo from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/NoPropertyAssignedToDealInfo'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/tegovaRating/FactSheetTegovaRatingTile.module.css'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/tegovaRating/shared/EmptyCardContent'
import { useNumberFormatter } from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/tegovaRating/shared/i18n/useI18n'
import LoadingStateWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/property/fact-sheet/tegovaRating/shared/screens/LoadingStateWrapper'

const NO_DATA_PLACEHOLDER = '-'
const FactSheetTegovaRatingTile = ({ tileId }) => {
  const { t: tTegovaRating } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.tegova-rating',
  })

  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  })

  const tileState = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])
  const {
    isLoading,
    isError,
    data: { weightedTegovaRating, emptyTegovaRating, noProperties },
  } = tileState

  const renderContent = () => {
    if (noProperties) {
      return <NoPropertyAssignedToDealInfo />
    }
    if (!weightedTegovaRating || emptyTegovaRating) {
      return <EmptyCardContent />
    }

    const ratingNoSeparator = ['cashflow', 'relett', 'total']
    const checkIsSeparator = (ratingLabel) => !includes(ratingNoSeparator, ratingLabel)

    return (
      weightedTegovaRating && (
        <>
          {Object.entries(weightedTegovaRating).map(([ratingLabel, rating], index) => (
            <div
              key={`rating-list-${ratingLabel}`}
              className={checkIsSeparator(ratingLabel) ? styles.itemWithSeparator : ''}
            >
              <DecisionPaperTileListItem
                key={`rating-row-${ratingLabel}-${rating}`}
                label={tTegovaRating(ratingLabel)}
                value={
                  rating
                    ? `${formatNumber(rating)} - ${tTegovaRating(Math.round(rating))}`
                    : NO_DATA_PLACEHOLDER
                }
                isFirstItem={index === 0}
                textValueIsBold={ratingLabel === 'total'}
              />
              {checkIsSeparator(ratingLabel) && (
                <DecisionPaperTileListSeparator key={`rating-separator-${ratingLabel}-${rating}`} />
              )}
            </div>
          ))}
        </>
      )
    )
  }

  return (
    <LoadingStateWrapper
      isLoading={isLoading}
      isError={isError}
      renderContent={renderContent}
      loadingTitle={tTegovaRating('loading')}
      errorTitle={tTegovaRating('loading.error')}
      errorDescription={tTegovaRating('loading.error.description')}
    />
  )
}

FactSheetTegovaRatingTile.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default FactSheetTegovaRatingTile
