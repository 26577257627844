import { useMutation, useQueryClient } from '@tanstack/react-query'
import camelize from 'camelize'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCovenantCommentsUpdate = ({ linkedEntityType, linkedEntityId, onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()
  const queryClient = useQueryClient()

  const innerOnSuccess = (data) => {
    queryClient.invalidateQueries(['covenants', 'comments', linkedEntityType, linkedEntityId])
    onSuccess?.(camelize(data))
  }

  return useMutation({
    mutationFn: async ({ content }) => {
      const { data } = await put({
        path: `/covenants/comments`,
        body: { content, linked_entity: { id: linkedEntityId, type: linkedEntityType } },
      })
      return data
    },
    onSuccess: innerOnSuccess,
    onError,
  })
}

export default useCovenantCommentsUpdate
