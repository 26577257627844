import { FlexBoxJustifyContent } from '@fioneer/ui5-webcomponents-react'
import { isNil } from 'lodash'
import isEmpty from 'lodash.isempty'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AlignedMultiCell,
  TitleAndSubTitleCell,
} from 'components/domains/properties/rent-roll/overview/PropertyRentRollOverviewCells'
import StructureOverviewNoDataAvailableCell from 'components/domains/properties/rent-roll/overview/structure-overview/StructureOverviewNoDataAvailableCell'
import { CELLS_IN_MULTI_COLUMN } from 'components/domains/properties/rent-roll/overview/structure-overview/constants'
import LoadingContent from 'components/ui/content/LoadingContent'
import {
  useAreaMeasurementUnitFormatter,
  usePercentageFormatter,
  useNumberFormatter,
} from 'hooks/i18n/useI18n'
import useCurrentSegmentKpis from 'hooks/services/properties/kpis/useCurrentSegmentKpis'
import { SEGMENT_AREA_DETAILS_COLUMN_NAMES } from 'hooks/services/properties/segments/area-details/useSegmentAreaDetailsTableColumns'

export const useSegmentAreaDetailsTableRows = (property, segments) => {
  const NO_DATA_PLACEHOLDER = <StructureOverviewNoDataAvailableCell times={1} />
  const EMPTY_CONTENT = <></>
  const { NAME_AND_USAGE_TYPE, LETTABLE_AREA, RENTED_AREA } = SEGMENT_AREA_DETAILS_COLUMN_NAMES

  const { t: tAreaDetails } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.overview.area-details',
  })

  const formatAreaUnit = useAreaMeasurementUnitFormatter()
  const formatPercent = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formatNumber = useNumberFormatter({ maximumFractionDigits: 2, minimumFractionDigits: 2 })

  const {
    isLoading: isLoadingKpis,
    isError: isErrorKpis,
    data: kpisData,
  } = useCurrentSegmentKpis(
    property?.uuid,
    segments.map((segment) => segment.uuid),
    {
      areaUnitOfMeasurement: property?.area_measure_unit_code,
    },
  )

  const [faultySegments, setFaultySegments] = useState([])

  useEffect(() => {
    if (isLoadingKpis && !isEmpty(faultySegments)) {
      setFaultySegments([])
    }
  }, [faultySegments, isLoadingKpis])

  const propertyAreaMeasureUnit = property?.area_measure_unit_code
  const kpis = kpisData?.kpis

  const findKpi = (segment) => kpis?.find((kpi) => kpi.segment_uuid === segment.uuid)

  const isSegmentContainedInRentRoll = (segment) => {
    const kpi = findKpi(segment)
    return !!kpi && kpi.segment_usage_types?.length > 0
  }

  const findLettableNumberOfUnits = (segment) => findKpi(segment)?.total_number_of_units

  const findLettableAreaTotalArea = (segment) => findKpi(segment)?.total_area_surface?.value

  const findLettableAreaSum = () =>
    segments.reduce((sum, segment) => sum + (findLettableAreaTotalArea(segment) || 0), 0)

  const findLettableAreaShare = (segment) =>
    findLettableAreaTotalArea(segment) / findLettableAreaSum() || 0

  const findVacancyNumberOfUnits = (segment) => findKpi(segment)?.vacancy_number_of_units

  const findLettableNumberOfUnitsSum = () =>
    segments.reduce((sum, segment) => sum + (findLettableNumberOfUnits(segment) || 0), 0)

  const findRentedAreaTotalArea = (segment) => findKpi(segment)?.let_surface?.value

  const findRentedAreaShare = (segment) => {
    if (findLettableAreaTotalArea(segment) === 0) return 0
    return findRentedAreaTotalArea(segment) / findLettableAreaTotalArea(segment) || 0
  }

  const findRentedNumberOfUnits = (segment) => {
    const lettableNumberOfUnits = findLettableNumberOfUnits(segment)
    const vacancyNumberOfUnits = findVacancyNumberOfUnits(segment)
    if (isNil(lettableNumberOfUnits) || isNil(vacancyNumberOfUnits)) {
      return 0
    }
    return lettableNumberOfUnits - vacancyNumberOfUnits
  }

  const findRentedAreaSum = () =>
    segments.reduce((sum, segment) => sum + (findRentedAreaTotalArea(segment) || 0), 0)

  const findRentedNumberOfUnitsSum = () =>
    segments.reduce((sum, segment) => sum + (findRentedNumberOfUnits(segment) || 0), 0)

  const setFaultySegmentsInitially = (segment) => {
    if (!isLoadingKpis && segment && !faultySegments.includes(segment.uuid)) {
      setFaultySegments((prev) => [...prev, segment.uuid])
    }
  }

  const formatOrPlaceholder = ({
    formatter,
    value,
    oppositeValue,
    segment,
    isPublishedSegment,
  }) => {
    if (!isNil(value)) {
      return formatter ? formatter(value) : value
    } else if (!isNil(oppositeValue)) {
      return EMPTY_CONTENT
    } else {
      setFaultySegmentsInitially(segment)
      return isPublishedSegment ? NO_DATA_PLACEHOLDER : EMPTY_CONTENT
    }
  }

  const valueOrPlaceholder = ({ value, oppositeValue, segment, isPublishedSegment }) =>
    formatOrPlaceholder({ value, oppositeValue, segment, isPublishedSegment })

  const formatedArea = (totalArea) =>
    totalArea
      ? `${formatNumber(totalArea)} ${formatAreaUnit(propertyAreaMeasureUnit)}`
      : `${formatNumber(0)} ${formatAreaUnit(propertyAreaMeasureUnit)}`

  const formatedPercent = (percent) =>
    percent ? `${formatPercent(percent)}` : `${formatPercent(0)}`

  const sums = {
    lettable: {
      totalArea: findLettableAreaSum(),
      numberOfUnits: findLettableNumberOfUnitsSum(),
    },
    rented: {
      totalArea: findRentedAreaSum(),
      numberOfUnits: findRentedNumberOfUnitsSum(),
    },
  }

  const segmentRows = segments.map((segment) => {
    const lettable = {
      totalArea: findLettableAreaTotalArea(segment),
      areaShare: findLettableAreaShare(segment),
      numberOfUnits: findLettableNumberOfUnits(segment),
    }
    const rented = {
      totalArea: findRentedAreaTotalArea(segment),
      areaShare: findRentedAreaShare(segment),
      numberOfUnits: findRentedNumberOfUnits(segment),
    }
    return {
      rowKey: segment.uuid,
      isFaulty: faultySegments.includes(segment.uuid),
      cells: [
        {
          columnKey: NAME_AND_USAGE_TYPE,
          renderCell: () => (
            <TitleAndSubTitleCell title={segment.name} subTitle={segment.usage_type_name} />
          ),
        },
        {
          columnKey: LETTABLE_AREA,
          renderCell: () => (
            <LoadingContent
              contentKey={LETTABLE_AREA}
              isLoading={isLoadingKpis}
              isError={isErrorKpis}
              errorContent={
                <StructureOverviewNoDataAvailableCell
                  times={CELLS_IN_MULTI_COLUMN}
                  alignment={FlexBoxJustifyContent.End}
                />
              }
            >
              <AlignedMultiCell
                alignment={FlexBoxJustifyContent.End}
                values={[
                  formatOrPlaceholder({
                    formatter: formatedArea,
                    value: lettable.totalArea,
                    oppositeValue: lettable.numberOfUnits,
                    segment: segment,
                    isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  }),
                  formatOrPlaceholder({
                    formatter: formatedPercent,
                    value: lettable.areaShare,
                    oppositeValue: lettable.numberOfUnits,
                    segment: segment,
                    isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  }),
                  valueOrPlaceholder({
                    value: lettable.numberOfUnits,
                    oppositeValue: lettable.totalArea,
                    segment: segment,
                    isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  }),
                ]}
              />
            </LoadingContent>
          ),
        },
        {
          columnKey: RENTED_AREA,
          renderCell: () => (
            <LoadingContent
              contentKey={RENTED_AREA}
              isLoading={isLoadingKpis}
              isError={isErrorKpis}
              errorContent={<StructureOverviewNoDataAvailableCell times={CELLS_IN_MULTI_COLUMN} />}
            >
              <AlignedMultiCell
                alignment={FlexBoxJustifyContent.End}
                values={[
                  formatOrPlaceholder({
                    formatter: formatedArea,
                    value: rented.totalArea,
                    oppositeValue: rented.numberOfUnits,
                    segment: segment,
                    isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  }),
                  formatOrPlaceholder({
                    formatter: formatedPercent,
                    value: rented.areaShare,
                    oppositeValue: rented.numberOfUnits,
                    segment: segment,
                    isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  }),
                  valueOrPlaceholder({
                    value: rented.numberOfUnits,
                    oppositeValue: rented.totalArea,
                    segment: segment,
                    isPublishedSegment: isSegmentContainedInRentRoll(segment),
                  }),
                ]}
              />
            </LoadingContent>
          ),
        },
      ],
    }
  })

  const totalRow = {
    rowKey: 'total',
    aggregate: true,
    cells: [
      {
        columnKey: NAME_AND_USAGE_TYPE,
        renderCell: () => <b>{tAreaDetails('table.columns.total')}</b>,
      },
      {
        columnKey: LETTABLE_AREA,
        renderCell: () => (
          <LoadingContent
            contentKey={LETTABLE_AREA}
            isLoading={isLoadingKpis}
            isError={isErrorKpis}
            errorContent={
              <StructureOverviewNoDataAvailableCell
                times={CELLS_IN_MULTI_COLUMN}
                showIteration={[true, false, true]}
                alignment={FlexBoxJustifyContent.End}
              />
            }
          >
            <AlignedMultiCell
              alignment={FlexBoxJustifyContent.End}
              values={[
                formatOrPlaceholder({
                  formatter: formatedArea,
                  value: sums.lettable.totalArea,
                  oppositeValue: sums.lettable.numberOfUnits,
                }),
                '',
                valueOrPlaceholder({
                  value: sums.lettable.numberOfUnits,
                  oppositeValue: sums.lettable.totalArea,
                }),
              ]}
            />
          </LoadingContent>
        ),
      },
      {
        columnKey: RENTED_AREA,
        renderCell: () => (
          <LoadingContent
            contentKey={RENTED_AREA}
            isLoading={isLoadingKpis}
            isError={isErrorKpis}
            errorContent={
              <StructureOverviewNoDataAvailableCell
                times={CELLS_IN_MULTI_COLUMN}
                showIteration={[true, false, true]}
                alignment={FlexBoxJustifyContent.End}
              />
            }
          >
            <AlignedMultiCell
              alignment={FlexBoxJustifyContent.End}
              values={[
                formatOrPlaceholder({
                  formatter: formatedArea,
                  value: sums.rented.totalArea,
                  oppositeValue: sums.rented.numberOfUnits,
                }),
                '',
                valueOrPlaceholder({
                  value: sums.rented.numberOfUnits,
                  oppositeValue: sums.rented.totalArea,
                }),
              ]}
            />
          </LoadingContent>
        ),
      },
    ],
  }

  const publishedSegments = kpis?.filter((kpi) => !isEmpty(kpi.segment_usage_types))

  if (segmentRows?.length > 0) {
    return {
      isError: isErrorKpis || faultySegments.length > 0,
      isErrorAllSegmentsPublished:
        publishedSegments?.length !== segmentRows.length && !isLoadingKpis,
      data: segmentRows.concat([totalRow]),
    }
  } else {
    return {
      isError: false,
      isErrorAllSegmentsPublished: false,
      data: [],
    }
  }
}
