import { useSelector } from 'react-redux'
import { availableAutomaticTiles } from 'components/domains/business-events-and-tasks/decision-paper/tiles/automatic/availableTiles'

const useAnnualReviewBusinessPartnerOverview = () => {
  const borrowersMetadata = useSelector(
    (state) =>
      state.decisionPaper.tilesOverview.tileMetadata?.[
        availableAutomaticTiles.multipleAppendixBusinessPartnerDetailsBorrowerSelection
      ],
  ) ?? {
    allBorrowers: [],
    selectedBorrowerId: '',
    selectedBorrowerName: '',
  }

  const { selectedBorrowerId, allBorrowers } = borrowersMetadata

  return {
    selectedBorrowerId,
    allBorrowersIds: allBorrowers?.map((b) => b.id),
  }
}

export default useAnnualReviewBusinessPartnerOverview
