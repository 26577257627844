import isEmpty from 'lodash.isempty'
import { ROW_TYPE_TOTAL } from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/constants'

// In the exec summary, unit members without product classes (expect for the borrower) should be hidden by default
const filterUnitMembers = (tableData, borrowerBpId) =>
  tableData.filter(
    (rootRow) =>
      !isEmpty(rootRow.subRows) ||
      rootRow.rowType === ROW_TYPE_TOTAL ||
      rootRow.unitMemberBpId === borrowerBpId,
  )

export default filterUnitMembers
