import { Button, ButtonDesign, CardHeader, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { propertyMonitoringProxiesAllowedOperations } from 'api/property/monitoring/propertyMonitoringConfiguration'
import styles from 'components/domains/markets/detail/cards/MarketMacroProxiesCard.module.css'
import MarketMacroProxiesTable from 'components/domains/markets/detail/cards/MarketMacroProxiesTable'
import { sortMarketProxies } from 'components/domains/markets/detail/cards/helper/marketProxiesHelper.js'
import CancelButtonWithPopover from 'components/ui/button/CancelButtonWithPopover'
import LoadingButton from 'components/ui/button/LoadingButton'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInTable } from 'components/ui/errors/ErrorDataUnavailableInTable'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import useMarketKpis from 'hooks/services/markets/useMarketKpis'
import usePropertyMonitoringProxiesAllowedOperations from 'hooks/services/properties/monitoring/proxies/usePropertyMonitoringProxiesAllowedOperations'
import { useMarketMacroProxies } from 'hooks/services/properties/monitoring/useMarketMacroProxies'
import { useUpdateMarketMacroProxies } from 'hooks/services/properties/monitoring/useUpdateMarketMacroProxies'
import {
  cancelEditing,
  endEditing,
  setProxies,
  startEditing,
} from 'redux/slices/markets/marketMacroProxiesSlice'

const MarketMacroProxiesCard = ({ marketId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.markets.detail.manager.market-proxies',
  })
  const { t: tNoPrefix } = useTranslation()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()
  const { mutate: updateMacroProxies, isLoading: isSaveLoading } = useUpdateMarketMacroProxies()
  const {
    data: marketKpiData,
    isLoading: isMarketKpisLoading,
    isError: isMarketKpisError,
  } = useMarketKpis({ marketId })
  const {
    data: marketMacroProxiesData,
    isLoading: isMarketMacroProxiesDataLoading,
    isError: isMarketMacroProxiesDataError,
  } = useMarketMacroProxies({ marketId })
  const {
    data: proxiesAllowedOperations,
    isLoading: isProxiesAllowedOperationsLoading,
    isError: isProxiesAllowedOperationsError,
  } = usePropertyMonitoringProxiesAllowedOperations()
  const hasEditPermissions = (proxiesAllowedOperations ?? []).includes(
    propertyMonitoringProxiesAllowedOperations.proxySettingsUpdate,
  )

  const isEditing = useSelector((state) => state.markets.marketMacroProxies.isEditing)
  const hasUnsavedChanges = useSelector(
    (state) => state.markets.marketMacroProxies.hasUnsavedChanges,
  )
  const hasErrors = useSelector((state) => state.markets.marketMacroProxies.hasErrors)

  const macroProxies = useSelector((state) => state.markets.marketMacroProxies.proxies)

  const handleSaveSuccess = useCallback(() => {
    queryClient.invalidateQueries(['property-monitoring', 'proxies', 'macros', marketId])
    dispatch(endEditing())
    showToast({ children: t('save.success.text') })
  }, [queryClient, marketId, dispatch, showToast, t])

  const handleSaveError = useCallback(() => {
    showMessageBox({
      type: MessageBoxTypes.Error,
      titleText: t('save.error.title'),
      children: t('save.error.text'),
      actions: [MessageBoxActions.OK],
    })
  }, [showMessageBox, t])

  const handleEditButtonClicked = useCallback(() => {
    dispatch(startEditing())
  }, [dispatch])
  const handleCancelButtonClicked = useCallback(() => {
    dispatch(cancelEditing())
  }, [dispatch])
  const saveProxies = useCallback(() => {
    const editedProxies = macroProxies
      .filter(({ overrideProxy, isOverride }) => isOverride && !isNil(overrideProxy))
      .map(
        ({ key: type, overrideProxy: { threshold, thresholdType, direction, isMonitored } }) => ({
          type,
          threshold,
          thresholdType,
          direction,
          isMonitored,
        }),
      )
    updateMacroProxies(
      { marketId, macroProxies: editedProxies },
      {
        onSuccess: handleSaveSuccess,
        onError: handleSaveError,
      },
    )
  }, [macroProxies, updateMacroProxies, marketId, handleSaveSuccess, handleSaveError])

  const showSaveDialog = useCallback(
    () =>
      showMessageBox({
        type: MessageBoxTypes.Confirm,
        titleText: t('save.confirmation.title'),
        children: t('save.confirmation.text'),
        actions: [
          <Button key="button-confirm" design={ButtonDesign.Emphasized} onClick={saveProxies}>
            {tNoPrefix('buttons.save')}
          </Button>,
          MessageBoxActions.Cancel,
        ],
      }),
    [saveProxies, showMessageBox, t, tNoPrefix],
  )

  useEffect(() => {
    if (isNil(marketKpiData?.kpis) || isNil(marketMacroProxiesData)) {
      return
    }
    const defaultProxies = Object.fromEntries(
      marketMacroProxiesData.defaultMacroProxySettings.map(({ type, ...proxy }) => [type, proxy]),
    )
    const overrideProxies = Object.fromEntries(
      marketMacroProxiesData.overrideMacroProxySettings.map(({ type, ...proxy }) => [type, proxy]),
    )
    const macroProxiesData = Object.entries(marketKpiData?.kpis)
      .map(([kpiKey, kpiValue]) => ({
        key: kpiKey,
        name: kpiValue.name,
        unit: kpiValue.unit,
        isOverride: !isNil(overrideProxies[kpiKey]),
        defaultProxy: defaultProxies[kpiKey],
        overrideProxy: overrideProxies[kpiKey],
      }))
      .sort(sortMarketProxies)
    dispatch(setProxies(macroProxiesData))
  }, [dispatch, marketKpiData?.kpis, marketMacroProxiesData])

  const saveButtonContent = useCallback(() => tNoPrefix('buttons.save'), [tNoPrefix])
  const headerActions = useMemo(
    () =>
      isEditing ? (
        [
          <LoadingButton
            renderContent={saveButtonContent}
            design={ButtonDesign.Emphasized}
            key="saveProxies"
            onClick={showSaveDialog}
            isLoading={isSaveLoading}
            disabled={hasErrors || !hasUnsavedChanges}
          />,
          <CancelButtonWithPopover
            key="cancelEditing"
            onCancelClicked={handleCancelButtonClicked}
            showPopover={hasUnsavedChanges}
            disabled={isSaveLoading}
          />,
        ]
      ) : (
        <Button design={ButtonDesign.Transparent} onClick={handleEditButtonClicked}>
          {tNoPrefix('buttons.edit')}
        </Button>
      ),
    [
      handleCancelButtonClicked,
      handleEditButtonClicked,
      hasErrors,
      hasUnsavedChanges,
      isEditing,
      isSaveLoading,
      saveButtonContent,
      showSaveDialog,
      tNoPrefix,
    ],
  )

  return (
    <Card
      header={
        <CardHeader titleText={t('title.macro')} action={hasEditPermissions && headerActions} />
      }
    >
      <RequestStateResolver
        isLoading={
          isMarketKpisLoading ||
          isMarketMacroProxiesDataLoading ||
          isProxiesAllowedOperationsLoading
        }
        isError={
          isMarketKpisError || isMarketMacroProxiesDataError || isProxiesAllowedOperationsError
        }
        center
        errorToDisplay={<ErrorDataUnavailableInTable />}
        renderContent={() => (
          <div className={styles.tableWrapper} {...(isSaveLoading && { inert: '' })}>
            <MarketMacroProxiesTable />
          </div>
        )}
      />
    </Card>
  )
}

MarketMacroProxiesCard.propTypes = {
  marketId: PropTypes.string.isRequired,
}

export default MarketMacroProxiesCard
