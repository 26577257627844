import { useMutation } from '@tanstack/react-query'
import camelize from 'camelize'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useTransferExternalConditions = (mutationOptions) => {
  const { post } = useAccessTokenRequest()
  return useMutation(async ({ conditionIds, entityType, entityId }) => {
    const { data } = await post({
      path: '/conditions/external/transfers',
      body: snakecaseKeys({ conditionIds, destinationEntity: { entityType, entityId } }),
    })
    return camelize(data)
  }, mutationOptions)
}

export default useTransferExternalConditions
