import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useExternalDealTeam = ({ dealUuid, options = {} }) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/external-deal-team`,
      useCache: true,
      translated: true,
      keys: ['deals', dealUuid, 'external-deal-team'],
      options,
    }),
  )
