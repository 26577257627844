import PropTypes from 'prop-types'
import { createContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getPropertyRentalUnitsTableContentDefinitionsWithoutIDFields } from 'components/domains/properties/getPropertyRentRollWorkingVersionReferenceData'

export const ExcelUploadContext = createContext({})

const ExcelUploadContextProvider = ({ children, ...props }) => {
  const { t: tRentRollTable } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.table',
  })
  const contentDefinitions =
    getPropertyRentalUnitsTableContentDefinitionsWithoutIDFields(tRentRollTable)

  const getInitialMapping = () => {
    const mapping = new Map()
    contentDefinitions.forEach((conDef) => {
      mapping.set(conDef.contentKey, '')
    })
    return mapping
  }

  const [droppedFileToUpload, setDroppedFileToUpload] = useState(null)
  const [inputData, setInputData] = useState([])
  const [isConfirmOverwriteDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [isAdjustMappingDialogOpen, setIsAdjustMappingDialogOpen] = useState(false)
  const [isMappingExistsDialogOpen, setIsMappingExistsDialogOpen] = useState(false)
  const [isChooseHeaderRowDialogOpen, setIsChooseHeaderRowDialogOpen] = useState(false)
  const [isApplyLocalValueMappingDialogOpen, setIsApplyLocalValueMappingDialogOpen] =
    useState(false)
  const [mapping, setMapping] = useState(new Map(getInitialMapping()))
  const [mappedContent, setMappedContent] = useState(new Map(getInitialMapping()))
  const [rowNumberHeader, setRowNumberHeader] = useState(0)
  const [defaultRowNumberHeader, setDefaultRowNumberHeader] = useState(0)

  const excelUploadContext = {
    isConfirmOverwriteDialogOpen: isConfirmOverwriteDialogOpen,
    isAdjustMappingDialogOpen: isAdjustMappingDialogOpen,
    isMappingExistsDialogOpen: isMappingExistsDialogOpen,
    isChooseHeaderRowDialogOpen: isChooseHeaderRowDialogOpen,
    isApplyLocalValueMappingDialogOpen: isApplyLocalValueMappingDialogOpen,
    inputExcelData: inputData,
    mappedExcelData: mappedContent,
    excelToTableMapping: mapping,
    rowNumberHeader: rowNumberHeader,
    defaultRowNumberHeader,
    setInputExcelData: (array) => setInputData([...array]),
    setExcelToTableMapping: (newMapping) => setMapping(new Map(newMapping)),
    setMappedExcelData: (newData) => setMappedContent(new Map(newData)),
    setRowNumberHeader,
    setDefaultRowNumberHeader,
    closeConfirmOverwriteDialog: () => setIsConfirmDialogOpen(false),
    openConfirmOverwriteDialog: () => setIsConfirmDialogOpen(true),
    closeAdjustMappingDialog: () => setIsAdjustMappingDialogOpen(false),
    openAdjustMappingDialog: () => setIsAdjustMappingDialogOpen(true),
    closeMappingExistsDialog: () => setIsMappingExistsDialogOpen(false),
    openMappingExistsDialog: () => setIsMappingExistsDialogOpen(true),
    openChooseHeaderRowDialog: () => setIsChooseHeaderRowDialogOpen(true),
    closeChooseHeaderRowDialog: () => setIsChooseHeaderRowDialogOpen(false),
    openApplyLocalValueMappingDialog: () => setIsApplyLocalValueMappingDialogOpen(true),
    closeApplyLocalValueMappingDialog: () => setIsApplyLocalValueMappingDialogOpen(false),
    droppedFileToUpload,
    setDroppedFileToUpload,
  }

  return (
    <ExcelUploadContext.Provider value={excelUploadContext} {...props}>
      {children}
    </ExcelUploadContext.Provider>
  )
}

ExcelUploadContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
export default ExcelUploadContextProvider
