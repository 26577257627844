import { Avatar, AvatarShape, CardHeader, Label, Link } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-partners/tile/general-information/keyInternalContact/KeyInternalContact.module.css'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useStaffMemberById from 'hooks/services/business-partners/staff-members/useStaffMemberById'

function Field({ label, children }) {
  if (!children) return

  return (
    <div className={styles['field-flexbox']}>
      <Label>{label}</Label>
      {children}
    </div>
  )
}

Field.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  children: PropTypes.node,
}

function KeyInternalContact({ id, functions }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-partners.general-information.key-internal-contacts',
  })
  const { data: keyInternalContact = {}, isLoading, isError } = useStaffMemberById(id)
  const { fullName = '', email, phone, mobile, location, team } = keyInternalContact

  const initials = fullName
    .split(' ')
    .map((word) => word[0] ?? '')
    .join('')

  const firstAndLastInitial =
    (initials.length ? initials.at(0) : '') + (initials.length > 1 ? initials.at(-1) : '')

  const getPhoneLinkComponent = (phoneNumber) => (
    <Link href={'CISCOTEL:' + phoneNumber}>{phoneNumber}</Link>
  )

  const getEmailLinkComponent = (emailAdress) => (
    <Link href={'mailto:' + emailAdress}>{emailAdress}</Link>
  )

  const getValueComponent = (value) => (
    <div>
      {value.split('\n').map((part) => (
        <div key={part}>{part}</div>
      ))}
    </div>
  )

  return (
    <LoadingStateWrapper
      isError={isError}
      errorTitle={t('error.title')}
      errorDescription={t('error.subtitle', { id })}
      errorDetails=""
      isLoading={isLoading}
      renderContent={() => (
        <div className={styles['key-internal-contact']}>
          <CardHeader
            titleText={fullName}
            subtitleText={functions.map((fn) => fn.name).join(' & ')}
            avatar={<Avatar shape={AvatarShape.Circle} initials={firstAndLastInitial} />}
          />
          {team && <Field label={t('department')}>{getValueComponent(team)}</Field>}
          {email && <Field label={t('email')}> {getEmailLinkComponent(email)}</Field>}
          {phone && <Field label={t('phone')}>{getPhoneLinkComponent(phone)}</Field>}
          {mobile && <Field label={t('mobile')}>{getPhoneLinkComponent(mobile)}</Field>}
          {location && <Field label={t('location')}>{getValueComponent(location)}</Field>}
        </div>
      )}
    />
  )
}

KeyInternalContact.propTypes = {
  id: PropTypes.string.isRequired,
  functions: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired, name: PropTypes.string.isRequired }),
  ),
}

export default KeyInternalContact
