import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  requestReason: '',
  requestType: '',
  requestNote: '',
  requestDueDate: '',
  submitClicked: false,
}

export const requestCreationSlice = createSlice({
  name: 'properties/creation',
  initialState,
  reducers: {
    setRequestReason: (state, { payload }) => {
      state.requestReason = payload
    },
    setRequestType: (state, { payload }) => {
      state.requestType = payload
    },
    setRequestNote: (state, { payload }) => {
      state.requestNote = payload
    },
    setRequestDueDate: (state, { payload }) => {
      state.requestDueDate = payload
    },
    setSubmitClicked: (state, { payload }) => {
      state.submitClicked = payload
    },
    resetRequestCreation: () => initialState,
  },
})

export const {
  setRequestReason,
  setRequestType,
  setRequestNote,
  setRequestDueDate,
  setSubmitClicked,
  resetRequestCreation,
} = requestCreationSlice.actions

export default requestCreationSlice.reducer
