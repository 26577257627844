import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { conditionTypes, conditionsStatusTypes } from 'api/conditions/conditions'
import ConditionsMassEditDialog from 'components/domains/conditions/dialogs/mass-editing/ConditionsMassEditDialog'

const atLeastOneConditionInStatusDone = (conditions) =>
  Object.values(conditions).some(({ status }) => status === conditionsStatusTypes.done)

const ConditionsMassEditButton = ({ conditionType, disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.toolbar',
  })
  const selectedConditionIds = useSelector(
    (state) => state.conditions.conditionsTable.selectedRows.selectedConditionIds,
  )
  const selectedConditionIdList = useMemo(
    () => Object.keys(selectedConditionIds),
    [selectedConditionIds],
  )

  const allReferencesEntityType = useMemo(() => {
    const entityTypes = Object.values(selectedConditionIds).map(
      ({ references }) => references?.entityType,
    )
    if (entityTypes.length === 0) {
      return
    }

    const firstEntityType = entityTypes[0]

    if (entityTypes.every((entityType) => entityType === firstEntityType)) {
      return firstEntityType
    }
  }, [selectedConditionIds])

  const [isMassEditDialogOpen, setIsMassEditDialogOpen] = useState(false)

  const isButtonDisabled = useMemo(
    () =>
      isEmpty(selectedConditionIdList) ||
      atLeastOneConditionInStatusDone(selectedConditionIds) ||
      disabled,
    [selectedConditionIds, selectedConditionIdList, disabled],
  )

  const openMassEditButton = useCallback(() => {
    setIsMassEditDialogOpen(true)
  }, [setIsMassEditDialogOpen])

  return (
    <>
      <Button
        design={ButtonDesign.Transparent}
        onClick={openMassEditButton}
        disabled={isButtonDisabled}
      >
        {t('buttons.edit-multiple')}
      </Button>
      {isMassEditDialogOpen && (
        <ConditionsMassEditDialog
          conditionType={conditionType}
          selectedConditionIds={selectedConditionIdList}
          open={isMassEditDialogOpen}
          setIsOpen={setIsMassEditDialogOpen}
          allReferencesEntityType={allReferencesEntityType}
        />
      )}
    </>
  )
}

ConditionsMassEditButton.propTypes = {
  conditionType: PropTypes.oneOf(Object.values(conditionTypes)).isRequired,
  disabled: PropTypes.bool,
}

export default ConditionsMassEditButton
