import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ExternalRatingsOverview from 'components/domains/business-partners/tile/ratings/ExternalRatingsOverview'
import InternalRatingsOverview from 'components/domains/business-partners/tile/ratings/InternalRatingsOverview'
import styles from 'components/domains/business-partners/tile/ratings/RatingsOverviewTile.module.css'
import {
  externalRatingsForOverview as externalRatings,
  internalRatings,
} from 'components/domains/business-partners/tile/ratings/propTypes'
import Card from 'components/ui/card/Card'
import ClickableCardHeader from 'components/ui/card/ClickableCardHeader'

const RatingsOverviewTile = ({ ratings }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.business-partner' })
  return (
    <Card header={<ClickableCardHeader titleText={t('ratings')} href="ratings" />}>
      <div className={styles.card}>
        <InternalRatingsOverview internalRatings={ratings.internal} />
        <div className={styles.separator} />
        <ExternalRatingsOverview externalRatings={ratings.external} />
      </div>
    </Card>
  )
}

RatingsOverviewTile.propTypes = {
  ratings: PropTypes.shape({
    internal: internalRatings,
    external: externalRatings,
  }),
}

export default RatingsOverviewTile
