import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { conditionsMassEditOperations } from 'api/conditions/conditions'
import ConditionsMassEditSelectWithInput from 'components/domains/conditions/dialogs/mass-editing/ConditionsMassEditSelectWithInput'

const operations = [conditionsMassEditOperations.keep, conditionsMassEditOperations.clear]

const ConditionsMassEditCategorySelect = ({ onChange, disabled }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.table.dialogs.mass-edit',
  })

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label>{t('labels.category')}</Label>
      <ConditionsMassEditSelectWithInput
        selectableOperations={operations}
        preselectedOperation={conditionsMassEditOperations.keep}
        onChange={onChange}
        disabled={disabled}
      />
    </FlexBox>
  )
}

ConditionsMassEditCategorySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default ConditionsMassEditCategorySelect
