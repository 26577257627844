import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  valuationRequestMultiEditOperations,
  valuationStatus,
} from 'api/property/valuation/valuationRequests'
import Labeled from 'components/ui/data/Labeled'

const statusFieldId = 'ValuationRequestMultiEditStatusField'

const selectableStatusValues = [
  valuationStatus.draft,
  valuationStatus.created,
  valuationStatus.instructed,
  valuationStatus.completed,
  valuationStatus.cancelled,
]

const ValuationRequestMultiEditStatusField = ({
  disabled,
  onChange,
  allowOnlyCreated,
  isStatusDraftNotAllowed,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.properties.valuation.multi-edit.fields',
  })
  const { t: tOperations } = useTranslation('translation', {
    keyPrefix: 'components.properties.valuation.multi-edit.operations',
  })
  const { t: tValuationStatus } = useTranslation('translation', {
    keyPrefix: 'valuation-requests.status',
  })

  const onChangeInternal = useCallback(
    ({
      detail: {
        selectedOption: { value: newStatus },
      },
    }) => {
      if (newStatus === valuationRequestMultiEditOperations.keep) {
        onChange({ operation: valuationRequestMultiEditOperations.keep })
        return
      }

      onChange({ operation: valuationRequestMultiEditOperations.replace, value: newStatus })
    },
    [onChange],
  )

  const statusOptions = useMemo(
    () =>
      selectableStatusValues.map((value) => (
        <Option
          key={value}
          value={value}
          disabled={
            (allowOnlyCreated && value !== valuationStatus.created) ||
            (isStatusDraftNotAllowed && value === valuationStatus.draft)
          }
        >
          {tValuationStatus(value.toLowerCase())}
        </Option>
      )),
    [allowOnlyCreated, isStatusDraftNotAllowed, tValuationStatus],
  )

  return (
    <Labeled vertical showColon id={statusFieldId} label={t('status.label')}>
      <Select disabled={disabled} onChange={onChangeInternal}>
        <Option value={valuationRequestMultiEditOperations.keep} selected>
          {tOperations(valuationRequestMultiEditOperations.keep)}
        </Option>
        {statusOptions}
      </Select>
    </Labeled>
  )
}

ValuationRequestMultiEditStatusField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  allowOnlyCreated: PropTypes.bool.isRequired,
  isStatusDraftNotAllowed: PropTypes.bool.isRequired,
}

export default ValuationRequestMultiEditStatusField
