import { Button } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { propertyMonitoringStatus } from 'api/property/monitoring/propertyMonitoringConfiguration'
import AssignableMarketsDialog from 'components/domains/markets/AssignableMarketsDialog'

const PropertyAssignMarketButton = ({ propertyId, monitoringStatus, design, style, label }) => {
  const [isAssignedPropertiesDialogOpen, setIsAssignedPropertiesDialogOpen] = useState(false)

  const onAssignPropertyButtonClicked = () => {
    setIsAssignedPropertiesDialogOpen(true)
  }

  return (
    <>
      <Button
        key="assign-property-to-market"
        id="assign-property-to-market"
        design={design}
        onClick={onAssignPropertyButtonClicked}
        style={style}
      >
        {label}
      </Button>
      {isAssignedPropertiesDialogOpen && (
        <AssignableMarketsDialog
          isOpen={isAssignedPropertiesDialogOpen}
          setIsOpen={setIsAssignedPropertiesDialogOpen}
          propertyId={propertyId}
          monitoringStatus={monitoringStatus}
        />
      )}
    </>
  )
}

PropertyAssignMarketButton.propTypes = {
  propertyId: PropTypes.string.isRequired,
  monitoringStatus: PropTypes.oneOf(Object.values(propertyMonitoringStatus)),
  label: PropTypes.string.isRequired,
  design: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
}

export default PropertyAssignMarketButton
