import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxAlignItems,
  Link,
  ListItemType,
  StandardListItem,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/events/dialog/EventCreationDialogEventTemplatesListItem.module.css'

const Description = ({ description }) => {
  const maxDescriptionSize = 400
  const maxDescriptionSizeWithoutDots = 397
  const maxShortenedDescriptionSize = 100
  const { t } = useTranslation('translation')
  const [showMore, setShowMore] = useState(false)
  const textArray = description.split('<br>')
  const handleMoreClick = (event) => {
    event.preventDefault()
    setShowMore(true)
  }

  if (!description || description === '<br>') {
    return <Text>{'-'}</Text>
  }

  if (showMore || (textArray.length < 2 && textArray[0].length <= maxShortenedDescriptionSize)) {
    const displayedText =
      description.length > maxDescriptionSize
        ? parse(description.substring(0, maxDescriptionSizeWithoutDots) + '...')
        : parse(description)

    return <Text>{displayedText}</Text>
  }
  const displayedText = (textArray[0] + '<br>' + textArray[1]).substring(
    0,
    maxShortenedDescriptionSize,
  )

  return (
    <div>
      <Text>{parse(displayedText)}</Text>
      {'... '}
      <Link className={styles['more-link']} onClick={handleMoreClick}>
        {t('buttons.more')}
      </Link>
    </div>
  )
}

Description.propTypes = {
  description: PropTypes.string.isRequired,
}

const EventCreationDialogEventTemplatesListItem = ({ eventType, selectedEventTypeCode }) => (
  <StandardListItem
    type={ListItemType.Active}
    selected={eventType.code === selectedEventTypeCode}
    className={styles['event-template-list-item']}
    data-event-code={eventType.code}
  >
    <FlexBox
      direction={FlexBoxDirection.Column}
      alignItems={FlexBoxAlignItems.Start}
      className={styles['flexBox-padding']}
    >
      <div className={styles['event-name']}>{eventType.name}</div>
      <Description description={eventType.description} />
    </FlexBox>
  </StandardListItem>
)

EventCreationDialogEventTemplatesListItem.propTypes = {
  eventType: PropTypes.shape({
    code: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  selectedEventTypeCode: PropTypes.string,
}

export default EventCreationDialogEventTemplatesListItem
