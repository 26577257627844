import { FlexBoxAlignItems } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/change-overview/adjustments-history-card/DealAdjustmentHistoryTable.module.css'
import useDealAdjustmentHistoryTableData from 'components/domains/deals/change-overview/adjustments-history-card/useDealAdjustmentHistoryTableData'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'

const DealAdjustmentHistoryTable = ({ adjustments }) => {
  const { t: tDealChangeOverview } = useTranslation('translation', {
    keyPrefix: 'pages.deals.detail.change-overview.adjustment-history',
  })
  const { t } = useTranslation()
  const columnDefinitions = [
    {
      title: tDealChangeOverview('column.index'),
      columnKey: 'index',
      sortingDisabled: true,
    },
    {
      title: tDealChangeOverview('column.event'),
      columnKey: 'event',
      sortingDisabled: true,
    },
    {
      title: tDealChangeOverview('column.further-events'),
      popinText: tDealChangeOverview('column.further-events'),
      columnKey: 'further-events',
      demandPopin: true,
      sortingDisabled: true,
      minWidth: 700,
    },
    {
      title: tDealChangeOverview('column.status'),
      popinText: tDealChangeOverview('column.status'),
      columnKey: 'status',
      demandPopin: true,
      minWidth: 700,
    },
    {
      title: tDealChangeOverview('column.deal-status'),
      popinText: tDealChangeOverview('column.deal-status'),
      columnKey: 'deal-status',
      demandPopin: true,
      minWidth: 700,
    },
    {
      title: tDealChangeOverview('column.adjustment-type'),
      popinText: tDealChangeOverview('column.adjustment-type'),
      columnKey: 'adjustment-type',
      sortingDisabled: true,
      demandPopin: true,
      minWidth: 700,
    },
    {
      title: tDealChangeOverview('column.comment'),
      popinText: tDealChangeOverview('column.comment'),
      columnKey: 'comment',
      demandPopin: true,
      minWidth: 900,
    },
    {
      title: tDealChangeOverview('column.last-updated-at'),
      popinText: tDealChangeOverview('column.last-updated-at'),
      columnKey: 'last-updated-at',
      alignment: FlexBoxAlignItems.End,
      demandPopin: true,
      minWidth: 800,
    },
    {
      title: tDealChangeOverview('column.last-updated-by'),
      popinText: tDealChangeOverview('column.last-updated-by'),
      columnKey: 'last-updated-by',
      demandPopin: true,
      minWidth: 800,
      additionalColumnCellProperties: { className: styles.lastUpdatedByCell },
    },
  ]
  const tableData = useDealAdjustmentHistoryTableData({ data: adjustments })
  return (
    <div className={styles['default-cursor']}>
      <SortedTable
        columnDefinitions={columnDefinitions}
        tableData={tableData}
        toolbarConfig={{
          title: tDealChangeOverview('table-title'),
          showColumnSelection: false,
        }}
        noDataText={t('components.table.no-data')}
        additionalTableProperties={{
          onRowClick: () => {},
        }}
      />
    </div>
  )
}

DealAdjustmentHistoryTable.propTypes = {
  adjustments: PropTypes.arrayOf(
    PropTypes.shape({
      adjustmentUuid: PropTypes.string.isRequired,
      events: PropTypes.shape({
        primary: PropTypes.shape({}),
        other: PropTypes.shape({}),
      }),
    }),
  ),
}

export default DealAdjustmentHistoryTable
