import { Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/risk-monitoring/businesspartner/shared/ErrorDataUnavailableInCell.module.css'

const propTypes = {
  /** is the translated text to display, defaults to "Data unavailable." / "Daten nicht verfügbar." */
  titleText: PropTypes.string,
}

/**
 * Error component when data is unvailable or could not be loaded in a cell, e.g., of a table or a list.
 *
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const ErrorDataUnavailableInCell = ({ titleText }) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.business-partner.arrears.current-arrears.table',
  })

  const titleTextToDisplay = titleText ?? t('data-unavailable')

  return (
    <Text className={styles.title} wrapping>
      {titleTextToDisplay}
    </Text>
  )
}

ErrorDataUnavailableInCell.propTypes = propTypes

export { ErrorDataUnavailableInCell }
