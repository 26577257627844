import { useCallback, useMemo, useState } from 'react'
import CollapseExpandAction, {
  collapseExpandActionType,
} from 'components/ui/tables/analytical/CollapseExpandAction'

export const useCollapseExpandTableActions = (tableRef) => {
  const [isCollapseIconDisabled, setIsCollapseIconDisabled] = useState(true)

  const expandOrCollapseSubRows = useCallback(
    (isExpanded) => {
      tableRef?.current?.toggleAllRowsExpanded(isExpanded)
      setIsCollapseIconDisabled(!isExpanded)
    },
    [tableRef],
  )

  const handleOnRowExpand = useCallback(
    (row) => {
      const tableState = tableRef?.current?.state
      const expandedRowCountBeforeStateUpdate =
        tableState?.expanded && Object.keys(tableState.expanded).length
      if (expandedRowCountBeforeStateUpdate > 1 || !row.isExpanded) {
        setIsCollapseIconDisabled(false)
      } else {
        setIsCollapseIconDisabled(true)
      }
    },
    [tableRef],
  )

  const additionalTableActions = useMemo(
    () => [
      <CollapseExpandAction
        key="collapse"
        type={collapseExpandActionType.collapseAll}
        onClick={() => expandOrCollapseSubRows(false)}
        disabled={isCollapseIconDisabled}
      />,
      <CollapseExpandAction
        key="expand"
        type={collapseExpandActionType.expandAll}
        onClick={() => expandOrCollapseSubRows(true)}
      />,
    ],
    [expandOrCollapseSubRows, isCollapseIconDisabled],
  )

  return useMemo(
    () => ({
      additionalTableActions,
      handleOnRowExpand,
      expandOrCollapseSubRows,
    }),
    [additionalTableActions, expandOrCollapseSubRows, handleOnRowExpand],
  )
}
