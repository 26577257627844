import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateCashflowScenarioSession = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ dealUuid, cashflowScenarioUuid }) => {
      await post({
        path: `/pbb-custom-service/deals/${dealUuid}/cashflow-scenarios/${cashflowScenarioUuid}/sessions`,
      })
    },
    { onSuccess, onError },
  )
}

export default useCreateCashflowScenarioSession
