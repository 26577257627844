import isNil from 'lodash.isnil'
import {
  DEFAULT_DISPLAY_ROW_HEIGHT,
  DEFAULT_DISPLAY_TEXT_HEIGHT,
  ROOT_ROW_ID_TOTAL,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/constants'
import createSectionHeaderRow from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/createSectionHeaderRow'
import mapTotalTranches from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/syndication/shared/mapTotalTranches'

const mapTotal = (t, data) => {
  if (!data) {
    return []
  }

  const isConvertedCurrencyAvailable =
    !isNil(data.totalCommitmentHeadquarter) &&
    data.totalCommitment?.currency !== data.totalCommitmentHeadquarter?.currency

  const calculatedRowHeight =
    DEFAULT_DISPLAY_ROW_HEIGHT + (isConvertedCurrencyAvailable ? DEFAULT_DISPLAY_TEXT_HEIGHT : 0)
  const rootRowId = ROOT_ROW_ID_TOTAL

  const content = {
    rootRowId,
    partyRoleTranche: {
      value: t('pages.deals.syndicationStructure-table.subSection.deal-label'),
      isBold: true,
    },
    commitment: {
      value: data.totalCommitment,
      valueHeadquarter: data.totalCommitmentHeadquarter,
      isBold: true,
    },
    amountPA: {
      value: data.amountPerAnnum,
      valueHeadquarter: data.amountPerAnnumHeadquarter,
      isBold: true,
    },
    amountTerm: {
      value: data.amountTerm,
      valueHeadquarter: data.amountTermHeadquarter,
      isBold: true,
    },
    signingDate: {
      value: data.signingDate,
      isBold: true,
    },
    subRows: mapTotalTranches(data.tranches, rootRowId),
    rowHeight: calculatedRowHeight,
  }

  return [createSectionHeaderRow(t, 'total'), content]
}

export default mapTotal
