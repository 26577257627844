import { MessageBoxActions, MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CommentEntry from 'components/ui/comment/CommentEntry'
import useDeleteEventComment from 'hooks/services/business-events-and-tasks/events/comments/useDeleteEventComment'
import { useEditEventComment } from 'hooks/services/business-events-and-tasks/events/comments/useEditEventComment'
import useDeleteTaskComment from 'hooks/services/business-events-and-tasks/tasks/useDeleteTaskComment'
import { useEditTaskComment } from 'hooks/services/business-events-and-tasks/tasks/useEditTaskComment'
import { useStaffMemberSelf } from 'hooks/services/business-partners/staff-members/useStaffMemberSelf'

const CommentField = ({
  eventId,
  taskId,
  commentId,
  ownerId,
  commentText,
  creationTime,
  lastModifiedAt,
  hasUpdateCommentPermission,
}) => {
  const [isCommentEditMode, setIsCommentEditMode] = useState(false)
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.comment-section.edit',
  })
  const queryClient = useQueryClient()
  const showMessageBox = Modals.useShowMessageBox()

  const onEventMutationSuccess = useCallback(() => {
    queryClient.invalidateQueries(['events', eventId, 'comments'])
    setIsCommentEditMode(false)
  }, [eventId, queryClient])

  const onTaskMutationSuccess = useCallback(() => {
    queryClient.invalidateQueries(['events', eventId, 'tasks', taskId, 'comments'])
    setIsCommentEditMode(false)
  }, [eventId, queryClient, taskId])

  const onMutationError = useCallback(() => {
    showMessageBox(
      {
        titleText: t('error.title'),
        children: t('error.description'),
        actions: [MessageBoxActions.Cancel],
        type: MessageBoxTypes.Error,
        draggable: true,
        resizable: true,
      },
      document.body,
    )
  }, [showMessageBox, t])

  const { mutate: mutateEditEventComment } = useEditEventComment({
    onSuccess: onEventMutationSuccess,
    onError: onMutationError,
  })
  const { mutate: mutateDeleteEventComment } = useDeleteEventComment({
    onSuccess: onEventMutationSuccess,
    onError: onMutationError,
  })

  const { mutate: mutateEditTaskComment } = useEditTaskComment({
    onSuccess: onTaskMutationSuccess,
    onError: onMutationError,
  })

  const { mutate: mutateDeleteTaskComment } = useDeleteTaskComment({
    onSuccess: onTaskMutationSuccess,
    onError: onMutationError,
  })

  const { data: userData } = useStaffMemberSelf()

  const handleUpdateComment = useCallback(
    (newComment) => {
      if (taskId) {
        mutateEditTaskComment({ eventId, taskId, commentId, comment: newComment })
        return
      }
      mutateEditEventComment({ eventId, commentId, comment: newComment })
    },
    [commentId, eventId, mutateEditEventComment, mutateEditTaskComment, taskId],
  )
  const handleDeleteComment = useCallback(() => {
    if (taskId) {
      mutateDeleteTaskComment({ eventId, taskId, commentId })
      return
    }
    mutateDeleteEventComment({ eventId, commentId })
  }, [commentId, eventId, mutateDeleteEventComment, mutateDeleteTaskComment, taskId])

  const showCommentActions = useMemo(
    () => userData?.email === ownerId && hasUpdateCommentPermission,
    [hasUpdateCommentPermission, ownerId, userData?.email],
  )

  return (
    <CommentEntry
      ownerId={ownerId}
      commentText={commentText}
      creationTime={creationTime}
      lastModifiedAt={lastModifiedAt}
      showCommentActions={showCommentActions}
      onDeleteComment={handleDeleteComment}
      onUpdateComment={handleUpdateComment}
      isEditMode={isCommentEditMode}
      setIsEditMode={setIsCommentEditMode}
    />
  )
}

CommentField.propTypes = {
  eventId: PropTypes.string.isRequired,
  taskId: PropTypes.string,
  commentId: PropTypes.string.isRequired,
  ownerId: PropTypes.string.isRequired,
  commentText: PropTypes.string.isRequired,
  creationTime: PropTypes.string.isRequired,
  lastModifiedAt: PropTypes.string,
  hasUpdateCommentPermission: PropTypes.bool.isRequired,
}

export default CommentField
