import { Bar, Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessObjectSelectionFilterBar from 'components/domains/conditions/business-object/BusinessObjectSelectionFilterBar'
import BusinessObjectSelectionTable from 'components/domains/conditions/business-object/BusinessObjectSelectionTable'
import Dialog, { DialogSize } from 'components/ui/dialog/Dialog'
import useBusinessObjectSelectionSearch from 'hooks/services/conditions/business-objects/useBusinessObjectSelectionSearch'

const BusinessObjectSelectionHelperDialog = ({
  isOpen,
  onCancel,
  onSelect,
  addDealSearch = false,
  initialSearchFilter = { searchFilter: '', entityType: '' },
}) => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.conditions.business-object-selection',
  })

  const [currentSearchFilter, setCurrentSearchFilter] = useState(initialSearchFilter.searchFilter)
  const [currentEntityType, setCurrentEntityType] = useState(initialSearchFilter.entityType)

  useEffect(() => {
    if (!isOpen) {
      setCurrentSearchFilter(initialSearchFilter.searchFilter ?? '')
      setCurrentEntityType(initialSearchFilter.entityType)
    }
  }, [initialSearchFilter, isOpen])

  const areFiltersApplied = useMemo(
    () => currentSearchFilter !== '' || currentEntityType !== '',
    [currentSearchFilter, currentEntityType],
  )

  const {
    data: entitySuggestions,
    isLoading,
    isError,
  } = useBusinessObjectSelectionSearch({
    entityType: currentEntityType,
    searchString: currentSearchFilter,
    addDealSearch,
  })

  const footer = useMemo(
    () => (
      <Bar
        endContent={
          <Button design={ButtonDesign.Transparent} onClick={onCancel}>
            {tNoPrefix('buttons.cancel')}
          </Button>
        }
      />
    ),
    [tNoPrefix, onCancel],
  )

  const onFilterChanged = useCallback((filters) => {
    setCurrentSearchFilter(filters.searchFilter)
    setCurrentEntityType(filters.entityType)
  }, [])

  const filter = { searchFilter: currentSearchFilter, entityType: currentEntityType }

  return (
    <Dialog
      size={DialogSize.XL}
      footer={footer}
      open={isOpen}
      onAfterClose={onCancel}
      headerText={t('title')}
    >
      {isOpen && (
        <BusinessObjectSelectionFilterBar
          onGo={onFilterChanged}
          addDealSearch={addDealSearch}
          initialSearchFilter={filter}
        />
      )}
      <BusinessObjectSelectionTable
        entitySuggestions={entitySuggestions}
        isLoading={isLoading}
        isError={isError}
        onSelectTableRow={onSelect}
        hasFilters={areFiltersApplied}
      />
    </Dialog>
  )
}

BusinessObjectSelectionHelperDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  addDealSearch: PropTypes.bool,
  initialSearchFilter: PropTypes.shape({
    searchFilter: PropTypes.string,
    entityType: PropTypes.string,
  }),
}

export default BusinessObjectSelectionHelperDialog
