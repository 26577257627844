import PropTypes from 'prop-types'
import Card from 'components/ui/card/Card'
import CollateralAgreementTable from 'components/ui/tables/collateral-agreement/CollateralAgreementTable'
/**
 * @param {import('prop-types').InferProps<typeof propTypes>} props
 */
const CollateralAgreementTile = ({ id, hooks, translationKey, className }) => (
  <Card className={className}>
    <CollateralAgreementTable id={id} hooks={hooks} translationKey={translationKey} />
  </Card>
)

CollateralAgreementTile.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  hooks: PropTypes.object.isRequired,
  translationKey: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default CollateralAgreementTile
