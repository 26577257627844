import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteCovenant = ({ onSuccess, onError }) => {
  const { delete: deleteCovenant } = useAccessTokenRequest()

  return useMutation(
    async ({ covenantUuid }) => {
      await deleteCovenant({
        path: `/covenants/${covenantUuid}`,
        options: { enabled: !!covenantUuid },
      })
    },
    { onSuccess, onError },
  )
}

export default useDeleteCovenant
