import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

/**
 * Triggers the refreshment of the initial segment parameters market rent and vacancy costs.
 * Updates the information stored in the cashflow scenario with the up-to-date information from the properties.
 */
const useRefreshInitialSegmentInputParameters = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, cashflowScenarioUuid }) => {
      await post({
        path: `/deals/${dealUuid}/cashflow-scenarios/${cashflowScenarioUuid}/cashflow/refresh-initial-segment-input-parameters`,
      })
    },
    { onSuccess, onError },
  )
}

export default useRefreshInitialSegmentInputParameters
