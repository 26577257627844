import isNil from 'lodash.isnil'
import mapTotalValues from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/utils/mapper/mapTotalValues'
import mapUnitMembers from 'components/domains/business-events-and-tasks/decision-paper/tiles/annual-review-basel-two-confirmation/annual-review/shared/domains/business-partners/tile/authority-level-calculator/utils/mapper/mapUnitMembers'

const mapAuthorityLevelCalculatorDataToTableStructure = (t, data) => {
  if (!data) {
    return {
      backendTableData: [],
      headquarterCurrency: undefined,
      hasProductClassWithGuarantor: false,
      showAdjustedAmountColumn: false,
      showAdjustedPdRegulatoryColumn: false,
      showAdjustedLgdRegulatoryColumn: false,
      showAdjustedAdditionalInformationColumn: false,
      showAdditionalInformationCalculationColumn: false,
      showPdCalculationColumn: false,
      showLgdCalculationColumn: false,
      hasFailedCalculation: false,
    }
  }

  const tableData = [
    ...mapUnitMembers(t, data.unitMembers, data?.currency),
    data.totalValues ? mapTotalValues(t, data.totalValues) : null,
  ].filter((e) => !isNil(e))

  const hasProductClassWithGuarantor = data?.unitMembers?.some((unitMember) =>
    unitMember?.productClasses?.some((productClass) => productClass?.guarantorBpId),
  )
  const hasProductClassWithAdjustedAmount = data?.unitMembers?.some((unitMember) =>
    unitMember?.productClasses?.some((productClass) => productClass.adjustedValues?.adjustedAmount),
  )
  const hasProductClassWithAdjustedPdRegulatory = data?.unitMembers?.some((unitMember) =>
    unitMember?.productClasses?.some(
      (productClass) => productClass.adjustedValues.adjustedPdRegulatory,
    ),
  )
  const hasProductClassWithAdjustedLgdRegulatory = data?.unitMembers?.some((unitMember) =>
    unitMember?.productClasses?.some(
      (productClass) => productClass.adjustedValues.adjustedLgdRegulatory,
    ),
  )

  const hasProductClassWithEmptyPdCalculationAndExistingAmountGreaterZero = data?.unitMembers?.some(
    (unitMember) =>
      unitMember?.productClasses?.some((productClass) => {
        const isExistingAmountGreaterZero = productClass.importedExistingValues?.amount?.amount > 0
        return isNil(productClass?.calculatedValues?.pdCalculation) && isExistingAmountGreaterZero
      }),
  )

  const hasProductClassWithEmptyPdCalculationAndPipelineAmountGreaterZero = data?.unitMembers?.some(
    (unitMember) =>
      unitMember?.productClasses?.some((productClass) => {
        const isPipelineAmountGreaterZero = productClass.importedPipelineValues?.amount?.amount > 0
        return isNil(productClass?.calculatedValues?.pdCalculation) && isPipelineAmountGreaterZero
      }),
  )

  const hasProductClassWithEmptyLgdCalculationAndExistingAmountGreaterZero =
    data?.unitMembers?.some((unitMember) =>
      unitMember?.productClasses?.some((productClass) => {
        const isExistingAmountGreaterZero = productClass.importedExistingValues?.amount?.amount > 0
        return isNil(productClass?.calculatedValues?.lgdCalculation) && isExistingAmountGreaterZero
      }),
    )

  const hasProductClassWithEmptyLgdCalculationAndPipelineAmountGreaterZero =
    data?.unitMembers?.some((unitMember) =>
      unitMember?.productClasses?.some((productClass) => {
        const isPipelineAmountGreaterZero = productClass.importedPipelineValues?.amount?.amount > 0
        return isNil(productClass?.calculatedValues?.lgdCalculation) && isPipelineAmountGreaterZero
      }),
    )

  // as the whole ALC is customer-PBB-logic,
  // the default value for adjusted additional information is hardcoded here
  const hasUnitMemberWithNonDefaultAdjustedAdditionalInformation = data?.unitMembers?.some(
    (unitMember) =>
      unitMember.totalValues?.adjustedValues?.isOrgankredit !== false ||
      !isNil(unitMember.totalValues?.adjustedValues?.isHighRisk),
  )

  return {
    backendTableData: tableData,
    headquarterCurrency: data?.currency,
    hasProductClassWithGuarantor: hasProductClassWithGuarantor,
    showPdCalculationColumn:
      hasProductClassWithEmptyPdCalculationAndExistingAmountGreaterZero ||
      hasProductClassWithEmptyPdCalculationAndPipelineAmountGreaterZero,
    showLgdCalculationColumn:
      hasProductClassWithEmptyLgdCalculationAndExistingAmountGreaterZero ||
      hasProductClassWithEmptyLgdCalculationAndPipelineAmountGreaterZero,
    showAdditionalInformationCalculationColumn:
      hasProductClassWithEmptyPdCalculationAndExistingAmountGreaterZero ||
      hasProductClassWithEmptyLgdCalculationAndExistingAmountGreaterZero ||
      hasProductClassWithEmptyPdCalculationAndPipelineAmountGreaterZero ||
      hasProductClassWithEmptyLgdCalculationAndPipelineAmountGreaterZero,
    showAdjustedAdditionalInformationColumn:
      hasUnitMemberWithNonDefaultAdjustedAdditionalInformation,
    showAdjustedAmountColumn:
      hasProductClassWithAdjustedAmount ||
      hasProductClassWithEmptyPdCalculationAndExistingAmountGreaterZero ||
      hasProductClassWithEmptyLgdCalculationAndExistingAmountGreaterZero ||
      hasProductClassWithEmptyPdCalculationAndPipelineAmountGreaterZero ||
      hasProductClassWithEmptyLgdCalculationAndPipelineAmountGreaterZero,
    showAdjustedPdRegulatoryColumn:
      hasProductClassWithAdjustedPdRegulatory ||
      hasProductClassWithEmptyPdCalculationAndExistingAmountGreaterZero ||
      hasProductClassWithEmptyLgdCalculationAndExistingAmountGreaterZero ||
      hasProductClassWithEmptyPdCalculationAndPipelineAmountGreaterZero ||
      hasProductClassWithEmptyLgdCalculationAndPipelineAmountGreaterZero,
    showAdjustedLgdRegulatoryColumn:
      hasProductClassWithAdjustedLgdRegulatory ||
      hasProductClassWithEmptyPdCalculationAndExistingAmountGreaterZero ||
      hasProductClassWithEmptyLgdCalculationAndExistingAmountGreaterZero ||
      hasProductClassWithEmptyPdCalculationAndPipelineAmountGreaterZero ||
      hasProductClassWithEmptyLgdCalculationAndPipelineAmountGreaterZero,
    hasFailedCalculation:
      hasProductClassWithEmptyPdCalculationAndExistingAmountGreaterZero ||
      hasProductClassWithEmptyLgdCalculationAndExistingAmountGreaterZero ||
      hasProductClassWithEmptyPdCalculationAndPipelineAmountGreaterZero ||
      hasProductClassWithEmptyLgdCalculationAndPipelineAmountGreaterZero,
  }
}

export default mapAuthorityLevelCalculatorDataToTableStructure
