import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { eventStatus } from 'api/events/status'

const EventOverviewEditMultipleButton = ({ onClick }) => {
  const { t: tNoPrefix } = useTranslation()

  const selectedEvents = useSelector(
    (state) => state.events.eventOverview.selectedRows.selectedEventIds,
  )

  const selectedEventList = useMemo(() => Object.values(selectedEvents), [selectedEvents])

  const isButtonDisabled =
    isEmpty(selectedEventList) ||
    selectedEventList.some(({ status }) =>
      [eventStatus.completed, eventStatus.aborted].includes(status),
    )

  return (
    <Button design={ButtonDesign.Transparent} onClick={onClick} disabled={isButtonDisabled}>
      {tNoPrefix('buttons.edit-multiple')}
    </Button>
  )
}

EventOverviewEditMultipleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default EventOverviewEditMultipleButton
