import {
  AnalyticalTableHooks,
  AnalyticalTableScaleWidthMode,
  AnalyticalTableSelectionBehavior,
  AnalyticalTableSelectionMode,
  Icon,
  IllustratedMessage,
} from '@fioneer/ui5-webcomponents-react'
import merge from 'lodash.merge'
import PropTypes from 'prop-types'
import { useMemo, useRef, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import AnalyticalTableWithToolbar from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche/shared/ui/tables/analytical/AnalyticalTableWithToolbar'
import styles from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/CustomerOptionsTable.module.css'
import {
  CUSTOMER_OPTION_ROW_TYPES,
  TABLE_HEADER_ROW_HEIGHT,
} from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/customerOptionsConstants'
import mapActiveCustomerOptionsBackendData from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/mapActiveCustomerOptionsBackendData'
import mapSelectedRowIds from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/mapSelectedRowIds'
import setSelectedRowsActive from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/setSelectedRowsActive'
import useCustomerOptionsTableColumnDefinitions from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/useCustomerOptionsTableColumnDefinitions'
import useExpandSelectedRows from 'components/domains/business-events-and-tasks/decision-paper/tiles/ops-memo/tranche-details/cards/shared/embedded/useExpandSelectedRows'
import LoadingStateWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/shared/screens/LoadingStateWrapper'

const CustomerOptionsTable = ({
  isEditMode,
  editOptions,
  setEditOptions,
  backendTableData,
  mergedTableData,
  isFetching = false,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'pages.deals.customerOptions-table',
  })

  const displayModeTableData = useMemo(
    () => mapActiveCustomerOptionsBackendData(backendTableData),
    [backendTableData],
  )

  const tableRef = useRef(null)
  const onRowSelect = (e) => {
    setSelectedRowsActive({ event: e, editOptions, setEditOptions })
  }

  const selectedRowIds = mapSelectedRowIds(isEditMode ? mergedTableData : displayModeTableData)

  useExpandSelectedRows({ tableRef, selectedRowIds, mergedTableData })

  const updateOptionAttributeState = useCallback(
    (optionId, optionAttributeId, value) => {
      const newAttribute = {
        [optionId]: {
          [optionAttributeId]: {
            value: value.value,
            isValid: value.isValid,
          },
        },
      }

      const mergedResult = merge(editOptions, newAttribute)
      setEditOptions({ ...mergedResult })
    },
    [editOptions, setEditOptions],
  )

  const [isCollapseIconDisabled, setIsCollapseIconDisabled] = useState(true)

  const expandOrCollapseSubRows = (isExpanded) => {
    tableRef?.current?.toggleAllRowsExpanded(isExpanded)
    setIsCollapseIconDisabled(!isExpanded)
  }

  const additionalTableActions = []

  additionalTableActions.push(
    ...[
      <Icon
        className={styles.collapseIcon}
        key="collapse-subrows"
        name="collapse-all"
        interactive
        onClick={() => expandOrCollapseSubRows(false)}
        disabled={isCollapseIconDisabled}
      />,
      <Icon
        className={styles.expandIcon}
        key="expand-subrows"
        name="expand-all"
        interactive
        onClick={() => expandOrCollapseSubRows(true)}
      />,
    ],
  )

  const countOptions = () => {
    let optionCount = 0

    if (isEditMode) {
      for (const [, cluster] of Object.entries(backendTableData)) {
        optionCount += cluster.subRows.length
      }
    } else {
      for (const [, cluster] of Object.entries(displayModeTableData)) {
        optionCount += cluster.subRows.length
      }
    }

    return optionCount
  }

  const disableAttributeCheckboxes = (row) =>
    row.original.rowType === CUSTOMER_OPTION_ROW_TYPES.OPTION_ATTRIBUTE

  return (
    <AnalyticalTableWithToolbar
      title={t('title')}
      nrOfEntries={countOptions()}
      additionalActions={additionalTableActions}
      id="customerOptionsTable"
      sortable={false}
      className={styles['customerOptions-table']}
      minRows={0}
      selectionMode={
        isEditMode ? AnalyticalTableSelectionMode.MultiSelect : AnalyticalTableSelectionMode.None
      }
      onRowSelect={onRowSelect}
      tableHooks={[
        AnalyticalTableHooks.useRowDisableSelection(disableAttributeCheckboxes),
        AnalyticalTableHooks.useIndeterminateRowSelection(),
      ]}
      selectedRowIds={selectedRowIds}
      selectionBehavior={AnalyticalTableSelectionBehavior.RowSelector}
      columns={useCustomerOptionsTableColumnDefinitions({ isEditMode })}
      overscanCountHorizontal={99}
      // high number of visible rows fixes re-rendering of height on expand
      visibleRows={9999}
      // rowHeight needs to be set to a non-empty string to not show an empty row at the end
      rowHeight={'individual'}
      headerRowHeight={TABLE_HEADER_ROW_HEIGHT}
      data={isEditMode ? mergedTableData : displayModeTableData}
      isTreeTable={true}
      scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
      withRowHighlight={false}
      loading={!!isFetching}
      NoDataComponent={() => (
        <LoadingStateWrapper
          isError={false}
          isLoading={false}
          renderContent={() => (
            <IllustratedMessage
              name="NoData"
              // Unfortunately, the library's default title contains two apostrophes:
              // "There''s no data yet
              // Therefore a custom title is used
              titleText={t('no-data-title')}
              subtitleText={t('no-data-text')}
            />
          )}
        />
      )}
      reactTableOptions={{
        autoResetPage: false,
        autoResetExpanded: false,
        autoResetSelectedRows: false,
        autoResetSortBy: false,
        autoResetFilters: false,
        selectSubRows: true,

        updateOptionAttributeState,
      }}
      ref={tableRef}
    />
  )
}

CustomerOptionsTable.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  editOptions: PropTypes.object.isRequired,
  setEditOptions: PropTypes.func.isRequired,
  backendTableData: PropTypes.arrayOf(PropTypes.object),
  mergedTableData: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool,
}

export default CustomerOptionsTable
