import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import {
  ComboBox,
  ComboBoxItem,
  Icon,
  Input,
  Label,
  Option,
  Select,
  SuggestionItem,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import {
  brandingFranchisePartnerExpandedContentKey,
  tenantExpandedContentKey,
} from 'components/domains/properties/getPropertyRentRollWorkingVersionReferenceData'
import { getRolesForType } from 'components/domains/properties/rent-roll/working-version/PropertyRentalUnitBusinessPartnerInput'
import { openInputPicker } from 'components/domains/properties/rent-roll/working-version/openInputPicker'
import styles from 'components/domains/properties/rent-roll/working-version/rental-units-table/PropertyRentalUnitsWorkingVersionTableEditDialog.module.css'
import RentRollBusinessPartnerSearchDialog from 'components/domains/rentroll/RentRollBusinessPartnerSearchDialog'
import DatePickerWithoutMinWidth from 'components/ui/date-picker/DatePickerWithoutMinWidth'
import {
  useNumberFormatter,
  useFormattedNumberParser,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'
import { useMatchingBusinessPartnersByNameOrId } from 'hooks/services/business-partners/searchBusinessPartners'
import { useCwpBusinessPartnerRoles } from 'hooks/services/properties/rent-roll/working-version/useCwpBusinessPartnerRoles'
import { getDisplayNameOrReturnKey } from 'hooks/services/properties/rent-roll/working-version/useGetAllRentRollWorkingVersionOptions'
import { useMassEditDefaultOptions } from 'hooks/services/properties/rent-roll/working-version/useMassEditDefaultOptions'

const KeepValuesComboBoxItem = () => <ComboBoxItem text={useMassEditDefaultOptions().KEEP_VALUES} />

const ClearValuesComboBoxItem = () => (
  <ComboBoxItem text={useMassEditDefaultOptions().CLEAR_VALUES} />
)

const renderOriginalValueComboBoxItems = (contentKey, originalValues, format = (value) => value) =>
  _.uniq(originalValues).map((originalValue) => {
    if (originalValue) {
      return <ComboBoxItem key={`${contentKey}-${originalValue}`} text={format(originalValue)} />
    }
  })

export const MassEditSelect = ({
  contentKey,
  contentTitle,
  massEditChoices,
  options,
  handleOnChange,
}) => {
  const { KEEP_VALUES, CLEAR_VALUES } = useMassEditDefaultOptions()
  return (
    <>
      <Label>{contentTitle}</Label>
      <Select
        className={styles['mass-edit-select']}
        id={`${contentKey}-select`}
        value={getDisplayNameOrReturnKey({
          key: massEditChoices[contentKey],
          options: options,
        })}
        onChange={(event) =>
          handleOnChange({
            contentKey: contentKey,
            choice: event.detail.selectedOption.value,
          })
        }
      >
        {[
          <Option
            key={`${contentKey}-keep-values`}
            value={KEEP_VALUES}
            selected={massEditChoices[contentKey] === KEEP_VALUES}
          >
            {KEEP_VALUES}
          </Option>,

          <Option
            key={`${contentKey}-clear-values`}
            value={CLEAR_VALUES}
            selected={massEditChoices[contentKey] === CLEAR_VALUES}
          >
            {CLEAR_VALUES}
          </Option>,
          options.map((option) => (
            <Option
              key={`${contentKey}-${option.key}`}
              selected={option.key === massEditChoices[contentKey]}
              value={option.key}
            >
              {option.display_name}
            </Option>
          )),
        ]}
      </Select>
    </>
  )
}

MassEditSelect.propTypes = {
  contentKey: PropTypes.string.isRequired,
  contentTitle: PropTypes.string.isRequired,
  massEditChoices: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      display_name: PropTypes.string.isRequired,
    }),
  ),
  handleOnChange: PropTypes.func.isRequired,
}

export const MassEditBooleanSelect = ({
  contentKey,
  contentTitle,
  massEditChoices,
  handleOnChange,
}) => {
  const { KEEP_VALUES } = useMassEditDefaultOptions()

  return (
    <>
      <Label>{contentTitle}</Label>
      <Select
        className={styles['mass-edit-boolean-select']}
        id={`${contentKey}-boolean-select`}
        value={
          massEditChoices[contentKey] === KEEP_VALUES ? KEEP_VALUES : !!massEditChoices[contentKey]
        }
        onChange={(event) =>
          handleOnChange({
            contentKey: contentKey,
            choice:
              event.detail.selectedOption.value === KEEP_VALUES
                ? KEEP_VALUES
                : !!event.detail.selectedOption.value,
          })
        }
      >
        {[
          <Option
            key={`${contentKey}-keep-values`}
            value={KEEP_VALUES}
            selected={massEditChoices[contentKey] === KEEP_VALUES}
          >
            {KEEP_VALUES}
          </Option>,
          <Option
            key={`${contentKey}-yes`}
            value={'true'}
            selected={massEditChoices[contentKey] === true}
          >
            {'Yes'}
          </Option>,
          <Option
            key={`${contentKey}-no`}
            value={''}
            selected={massEditChoices[contentKey] === false}
          >
            {'No'}
          </Option>,
        ]}
      </Select>
    </>
  )
}

MassEditBooleanSelect.propTypes = {
  contentKey: PropTypes.string.isRequired,
  contentTitle: PropTypes.string.isRequired,
  massEditChoices: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func.isRequired,
}

export const MassEditBusinessPartnerComboBox = ({
  contentKeyName,
  contentKeyId,
  contentTitle,
  massEditChoices,
  handleOnChange,
}) => {
  const { t } = useTranslation()
  const inputRef = useRef()
  const { KEEP_VALUES, CLEAR_VALUES } = useMassEditDefaultOptions()
  const [currentValueState, setCurrentValueState] = useState(ValueState.None)
  const [tenantInput, setTenantInput] = useState(massEditChoices[contentKeyName])
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false)

  const initialSorting = { columnKey: 'id', orderBy: 'asc' }

  const translateSortSettingToSortParameter = (sortSetting) =>
    `${sortSetting?.orderBy === 'asc' ? '+' : '-'}${sortSetting?.columnKey}`

  const getBusinessPartnerType = () =>
    contentKeyId === 'branding_franchise_partner_id' ? 'brand-franchise-provider' : 'tenant'
  const getBusinessPartnerExpandedKey = () =>
    contentKeyId === 'branding_franchise_partner_id'
      ? brandingFranchisePartnerExpandedContentKey
      : tenantExpandedContentKey

  const { data: { configSapBusinessPartnerRoles = [] } = {} } = useCwpBusinessPartnerRoles()

  const preselectedRoles = getRolesForType({
    type: getBusinessPartnerType(),
    availableRoles: configSapBusinessPartnerRoles,
  })

  const { data: { businessPartners = [] } = {} } = useMatchingBusinessPartnersByNameOrId({
    searchKey: tenantInput,
    minLength: 3,
    excludeInactive: true,
    roles: preselectedRoles,
    sort: translateSortSettingToSortParameter(initialSorting),
  })

  const renderAdditionalComboBoxItems = () =>
    businessPartners.map((businessPartner) => (
      <SuggestionItem
        key={`${contentKeyName}-${businessPartner.id}`}
        id={`${contentKeyName}-suggestion-${businessPartner.id}`}
        text={businessPartner.name}
        additionalText={businessPartner.id}
        data-user-id={businessPartner.id}
        data-user={JSON.stringify(businessPartner)}
      />
    ))

  const handleFocus = (e) => {
    e.stopPropagation()
    if (!tenantInput?.length) return
    openInputPicker(inputRef)
  }

  const handleOnChangeBusinessPartner = (name, id, businessPartner) => {
    const isValidInput = !!name
    handleOnChange({
      contentKey: contentKeyName,
      choice: name,
      isValidInput: isValidInput,
    })
    const getIdOrClearField = () => (name ? id || CLEAR_VALUES : '')
    const getBusinessPartnerOrUndefined = () => {
      if (id === KEEP_VALUES) return KEEP_VALUES
      return name && businessPartner ? JSON.parse(businessPartner) : undefined
    }
    handleOnChange({
      contentKey: contentKeyId,
      choice: getIdOrClearField(),
      isValidInput: isValidInput,
    })

    handleOnChange({
      contentKey: getBusinessPartnerExpandedKey(),
      choice: getBusinessPartnerOrUndefined(),
    })
    setCurrentValueState(isValidInput ? ValueState.None : ValueState.Error)
  }

  const handleSearchDialogChange = (businessPartner = {}) => {
    const { name, orgName1, id } = businessPartner
    if (orgName1 && !name) {
      businessPartner.name = orgName1
      return handleOnChangeBusinessPartner(orgName1, id ?? '', JSON.stringify(businessPartner))
    }
    return handleOnChangeBusinessPartner(name ?? '', id ?? '', JSON.stringify(businessPartner))
  }

  return (
    <>
      <Label>{contentTitle}</Label>
      <Input
        ref={inputRef}
        showSuggestions
        showClearIcon
        noTypeahead
        className={styles['input']}
        value={massEditChoices[contentKeyName]}
        onInput={(event) => setTenantInput(event.target.value)}
        onChange={(event) => handleOnChangeBusinessPartner(event.target.value, '')}
        onFocus={handleFocus}
        onSuggestionItemSelect={(event) =>
          handleOnChangeBusinessPartner(
            event.target.value,
            event.detail?.item.getAttribute('data-user-id'),
            event.detail?.item.getAttribute('data-user'),
          )
        }
        icon={
          <Icon
            id={`${contentKeyName}-bp-search`}
            name="value-help"
            onClick={() => setIsSearchDialogOpen(true)}
          />
        }
        valueState={currentValueState}
        valueStateMessage={<Text>{t('form.validation.select', { label: contentTitle })}</Text>}
      >
        {[
          <SuggestionItem
            key={`${contentKeyName}-keep-values`}
            id={`${contentKeyName}-suggestion-keep-values`}
            text={KEEP_VALUES}
            data-user-id={KEEP_VALUES}
          />,
          <SuggestionItem
            key={`${contentKeyName}-clear-values`}
            id={`${contentKeyName}-suggestion-clear-values`}
            text={CLEAR_VALUES}
            data-user-id={CLEAR_VALUES}
          />,
          ...renderAdditionalComboBoxItems(),
        ]}
      </Input>
      {isSearchDialogOpen &&
        createPortal(
          <RentRollBusinessPartnerSearchDialog
            open={isSearchDialogOpen}
            initialSearch={tenantInput}
            onClose={() => setIsSearchDialogOpen(false)}
            initialExcludeInactive={true}
            initialRoles={preselectedRoles}
            type={getBusinessPartnerType()}
            onChange={handleSearchDialogChange}
          />,
          document.body,
        )}
    </>
  )
}

MassEditBusinessPartnerComboBox.propTypes = {
  contentKeyName: PropTypes.string.isRequired,
  contentKeyId: PropTypes.string.isRequired,
  contentTitle: PropTypes.string.isRequired,
  massEditChoices: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func.isRequired,
}

export const MassEditDatePickerComboBox = ({
  contentKey,
  contentTitle,
  massEditChoices,
  handleOnChange,
  originalValues,
}) => {
  const { t } = useTranslation()
  const [currentValueState, setCurrentValueState] = useState(ValueState.None)
  const [selectDateOpen, setSelectDateOpen] = useState(false)
  const datePickerRef = useRef(null)
  const massEditDefaultOptions = useMassEditDefaultOptions()
  const renderDatePickerIcon = () => (
    <Icon
      id={`${contentKey}-value-help-icon`}
      name="appointment-2"
      onClick={async () => {
        await setSelectDateOpen(true)
        datePickerRef?.current.openPicker()
      }}
    />
  )
  const { localePattern, format, parse } = useShortDateFormatter()

  const handleDatePickerUpdate = (dateValue) => {
    const isoDateValue = dateValue ? parse(dateValue, localePattern) : dateValue
    handleOnChange({
      contentKey: contentKey,
      choice: isoDateValue ?? '',
      isValidInput: !!isoDateValue,
    })
    setCurrentValueState(isoDateValue ? ValueState.None : ValueState.Error)
  }
  const handleComboBoxChange = (value) => {
    if (
      value === massEditDefaultOptions.CLEAR_VALUES ||
      value === massEditDefaultOptions.KEEP_VALUES
    ) {
      handleOnChange({ contentKey: contentKey, choice: value, isValidInput: true })
      setCurrentValueState(ValueState.None)
    } else {
      handleDatePickerUpdate(value)
    }
  }

  return (
    <>
      <Label>{contentTitle}</Label>
      {!selectDateOpen && (
        <ComboBox
          id={`${contentKey}-combobox`}
          value={format(massEditChoices[contentKey]) || ''}
          icon={renderDatePickerIcon()}
          onChange={(event) => {
            handleComboBoxChange(event.target.value)
          }}
          valueState={currentValueState}
          valueStateMessage={<Text>{t('form.validation.select', { label: contentTitle })}</Text>}
        >
          {[
            <KeepValuesComboBoxItem key={`${contentKey}-keep-values`} />,
            <ClearValuesComboBoxItem key={`${contentKey}-clear-values`} />,
            renderOriginalValueComboBoxItems(contentKey, originalValues, format),
          ]}
        </ComboBox>
      )}
      {selectDateOpen && (
        <DatePickerWithoutMinWidth
          id={`${contentKey}-datepicker`}
          ref={datePickerRef}
          className={styles['date-picker']}
          placeholder={format(massEditChoices[contentKey])}
          formatPattern={localePattern}
          onBlur={() => setSelectDateOpen(false)}
          onChange={(event) => {
            handleDatePickerUpdate(event.detail.value)
            setSelectDateOpen(false)
          }}
        />
      )}
    </>
  )
}

MassEditDatePickerComboBox.propTypes = {
  contentKey: PropTypes.string.isRequired,
  contentTitle: PropTypes.string.isRequired,
  massEditChoices: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  originalValues: PropTypes.array,
}

export const MassEditDefaultComboBox = ({
  contentKey,
  contentTitle,
  massEditChoices,
  handleOnChange,
  originalValues = [],
  additionalProperties = {},
}) => {
  const { t } = useTranslation()
  const [currentValueState, setCurrentValueState] = useState(ValueState.None)

  return (
    <>
      <Label>{contentTitle}</Label>
      <ComboBox
        value={massEditChoices[contentKey]}
        onChange={(event) => {
          const isValidInput = !!event.target.value
          handleOnChange({
            contentKey: contentKey,
            choice: event.target.value,
            isValidInput: isValidInput,
          })
          setCurrentValueState(isValidInput ? ValueState.None : ValueState.Error)
        }}
        valueState={currentValueState}
        valueStateMessage={<Text>{t('form.validation.select', { label: contentTitle })}</Text>}
        {...additionalProperties}
      >
        {[
          <KeepValuesComboBoxItem key={`${contentKey}-keep-values`} />,
          <ClearValuesComboBoxItem key={`${contentKey}-clear-values`} />,
          renderOriginalValueComboBoxItems(contentKey, originalValues),
        ]}
      </ComboBox>
    </>
  )
}

MassEditDefaultComboBox.propTypes = {
  contentKey: PropTypes.string.isRequired,
  contentTitle: PropTypes.string.isRequired,
  massEditChoices: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  originalValues: PropTypes.array,
  additionalProperties: PropTypes.object,
}

export const MassEditNumberComboBox = ({
  contentKey,
  contentTitle,
  massEditChoices,
  handleOnChange,
  originalValues = [],
  additionalProperties = {},
  decimalPlaces = 2,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [currentValueState, setCurrentValueState] = useState(ValueState.None)
  const [currentValueStateMessage, setCurrentValueStateMessage] = useState()
  const { t } = useTranslation()
  const { KEEP_VALUES, CLEAR_VALUES } = useMassEditDefaultOptions()
  const formatNumber = useNumberFormatter({
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces,
  })
  const formatNumberEditMode = useNumberFormatter({
    useGrouping: false,
    maximumFractionDigits: decimalPlaces,
  })
  const parseNumber = useFormattedNumberParser()
  const parseChoice = (choice) => _.round(parseNumber(choice), decimalPlaces)

  const [parsedValue, setParsedValue] = useState(
    _.isNumber(massEditChoices[contentKey])
      ? parseChoice(massEditChoices[contentKey])
      : massEditChoices[contentKey],
  )

  const renderOriginalNumberValueComboBoxItems = () =>
    _.uniq(originalValues).map((originalValue) => {
      if (_.isNumber(originalValue)) {
        return (
          <ComboBoxItem key={`${contentKey}-${originalValue}`} text={formatNumber(originalValue)} />
        )
      }
    })
  const updateValueState = (choice, isValidInput) => {
    if (!choice) {
      setCurrentValueState(ValueState.Error)
      setCurrentValueStateMessage(
        <Text>{t('form.validation.select', { label: contentTitle })}</Text>,
      )
    } else if (!isValidInput) {
      setCurrentValueState(ValueState.Error)
      setCurrentValueStateMessage(<Text>{t('form.validation.number')}</Text>)
    } else {
      setCurrentValueState(ValueState.None)
    }
  }
  const getCurrentValue = () => {
    if (typeof parsedValue === 'number') {
      return isFocused ? formatNumberEditMode(parsedValue) : formatNumber(parsedValue)
    } else {
      return parsedValue
    }
  }

  return (
    <>
      <Label>{contentTitle}</Label>
      <ComboBox
        value={getCurrentValue()}
        onChange={(event) => {
          const choice = event.target.value
          const isValidNumber = choice.match(/[^\d.,-]/g) === null && !_.isNaN(parseNumber(choice))
          const isValidInput =
            choice && (isValidNumber || [KEEP_VALUES, CLEAR_VALUES].includes(choice))
          handleOnChange({
            contentKey: contentKey,
            choice: isValidInput && isValidNumber ? parseChoice(choice) : choice,
            isValidInput: Boolean(isValidInput),
          })
          setParsedValue(isValidInput && isValidNumber ? parseChoice(choice) : choice)
          updateValueState(choice, isValidInput)
        }}
        onFocus={() => {
          setIsFocused(true)
        }}
        onBlur={() => {
          setIsFocused(false)
        }}
        valueState={currentValueState}
        valueStateMessage={currentValueStateMessage}
        {...additionalProperties}
      >
        {[
          <KeepValuesComboBoxItem key={`${contentKey}-keep-values`} />,
          <ClearValuesComboBoxItem key={`${contentKey}-clear-values`} />,
          renderOriginalNumberValueComboBoxItems(),
        ]}
      </ComboBox>
    </>
  )
}

MassEditNumberComboBox.propTypes = {
  contentKey: PropTypes.string.isRequired,
  contentTitle: PropTypes.string.isRequired,
  massEditChoices: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  originalValues: PropTypes.arrayOf(PropTypes.number),
  additionalProperties: PropTypes.object,
  decimalPlaces: PropTypes.number,
}
